const UnitsKošiceOkolie = {
  Kysak: [
    { lat: 48.8362596, lng: 21.1657254 },
    { lat: 48.8362509, lng: 21.1657329 },
    { lat: 48.835857, lng: 21.1664773 },
    { lat: 48.8345643, lng: 21.1680943 },
    { lat: 48.8341835, lng: 21.1702524 },
    { lat: 48.83408, lng: 21.17047 },
    { lat: 48.8340647, lng: 21.1708744 },
    { lat: 48.8338708, lng: 21.1712624 },
    { lat: 48.8336514, lng: 21.1715145 },
    { lat: 48.8327495, lng: 21.1709261 },
    { lat: 48.8325515, lng: 21.1706396 },
    { lat: 48.8316589, lng: 21.17046 },
    { lat: 48.8312626, lng: 21.169776 },
    { lat: 48.830987, lng: 21.1709216 },
    { lat: 48.8306403, lng: 21.1713103 },
    { lat: 48.830703, lng: 21.1714797 },
    { lat: 48.8303782, lng: 21.1755235 },
    { lat: 48.8302366, lng: 21.1766032 },
    { lat: 48.8301109, lng: 21.1787219 },
    { lat: 48.8300205, lng: 21.17932 },
    { lat: 48.8298332, lng: 21.1818721 },
    { lat: 48.8298794, lng: 21.1820881 },
    { lat: 48.8298108, lng: 21.1824095 },
    { lat: 48.8294279, lng: 21.1883454 },
    { lat: 48.8292282, lng: 21.1905833 },
    { lat: 48.8343991, lng: 21.191799 },
    { lat: 48.834247, lng: 21.1934078 },
    { lat: 48.8342599, lng: 21.1942478 },
    { lat: 48.834818, lng: 21.1940258 },
    { lat: 48.8373352, lng: 21.1997298 },
    { lat: 48.8393825, lng: 21.2042345 },
    { lat: 48.8401737, lng: 21.2071823 },
    { lat: 48.8407398, lng: 21.2088773 },
    { lat: 48.8413587, lng: 21.2112237 },
    { lat: 48.8415713, lng: 21.2117237 },
    { lat: 48.8420005, lng: 21.2122461 },
    { lat: 48.8424256, lng: 21.2127786 },
    { lat: 48.8426868, lng: 21.2131011 },
    { lat: 48.8427019, lng: 21.2141233 },
    { lat: 48.8428233, lng: 21.2144454 },
    { lat: 48.8427191, lng: 21.2153993 },
    { lat: 48.8427118, lng: 21.2155443 },
    { lat: 48.8426984, lng: 21.2165387 },
    { lat: 48.8427495, lng: 21.2165569 },
    { lat: 48.8427379, lng: 21.2166831 },
    { lat: 48.8426423, lng: 21.2167264 },
    { lat: 48.8426061, lng: 21.2170102 },
    { lat: 48.8426927, lng: 21.2171479 },
    { lat: 48.8426726, lng: 21.2173373 },
    { lat: 48.8415829, lng: 21.2179719 },
    { lat: 48.8405497, lng: 21.2184395 },
    { lat: 48.8408026, lng: 21.2194431 },
    { lat: 48.841268, lng: 21.2216642 },
    { lat: 48.8420876, lng: 21.2239951 },
    { lat: 48.842316, lng: 21.2248573 },
    { lat: 48.8427855, lng: 21.2273669 },
    { lat: 48.8428727, lng: 21.2275094 },
    { lat: 48.8431198, lng: 21.2279129 },
    { lat: 48.8435694, lng: 21.2280741 },
    { lat: 48.8436723, lng: 21.2280754 },
    { lat: 48.8440884, lng: 21.2280807 },
    { lat: 48.8463433, lng: 21.2270581 },
    { lat: 48.848156, lng: 21.2258583 },
    { lat: 48.8494029, lng: 21.2256021 },
    { lat: 48.8500574, lng: 21.2257283 },
    { lat: 48.8501834, lng: 21.2257914 },
    { lat: 48.852123, lng: 21.2267619 },
    { lat: 48.8526428, lng: 21.2271583 },
    { lat: 48.8533494, lng: 21.2273997 },
    { lat: 48.8544841, lng: 21.2278192 },
    { lat: 48.8558565, lng: 21.2280526 },
    { lat: 48.8569462, lng: 21.2279923 },
    { lat: 48.8571262, lng: 21.2277737 },
    { lat: 48.8573479, lng: 21.2277357 },
    { lat: 48.8575074, lng: 21.2279448 },
    { lat: 48.858365, lng: 21.2282021 },
    { lat: 48.8583525, lng: 21.2280482 },
    { lat: 48.858331, lng: 21.2277978 },
    { lat: 48.858843, lng: 21.227313 },
    { lat: 48.8606289, lng: 21.226448 },
    { lat: 48.8616264, lng: 21.2246587 },
    { lat: 48.862284, lng: 21.2233658 },
    { lat: 48.8623685, lng: 21.2228977 },
    { lat: 48.8621299, lng: 21.2211637 },
    { lat: 48.862092, lng: 21.2192564 },
    { lat: 48.8621447, lng: 21.218095 },
    { lat: 48.8620968, lng: 21.2169182 },
    { lat: 48.8617447, lng: 21.2150034 },
    { lat: 48.8617775, lng: 21.2145421 },
    { lat: 48.8625293, lng: 21.2130608 },
    { lat: 48.8638818, lng: 21.2111417 },
    { lat: 48.8645327, lng: 21.2099352 },
    { lat: 48.8652122, lng: 21.2091366 },
    { lat: 48.8658787, lng: 21.2080994 },
    { lat: 48.8663177, lng: 21.2070538 },
    { lat: 48.866531, lng: 21.2064609 },
    { lat: 48.8667336, lng: 21.2056696 },
    { lat: 48.8670887, lng: 21.2034568 },
    { lat: 48.8670354, lng: 21.2012173 },
    { lat: 48.8673567, lng: 21.1998352 },
    { lat: 48.867706, lng: 21.1987291 },
    { lat: 48.8677889, lng: 21.1980018 },
    { lat: 48.8684863, lng: 21.1980284 },
    { lat: 48.8687236, lng: 21.1979163 },
    { lat: 48.8689931, lng: 21.1978912 },
    { lat: 48.8693219, lng: 21.1979326 },
    { lat: 48.8696703, lng: 21.1981771 },
    { lat: 48.869927, lng: 21.1982469 },
    { lat: 48.8702157, lng: 21.1982354 },
    { lat: 48.8705005, lng: 21.1980201 },
    { lat: 48.8708836, lng: 21.1978896 },
    { lat: 48.8709788, lng: 21.1976925 },
    { lat: 48.8711284, lng: 21.1976189 },
    { lat: 48.8719371, lng: 21.1968521 },
    { lat: 48.8729151, lng: 21.1958469 },
    { lat: 48.8733217, lng: 21.1957497 },
    { lat: 48.873647, lng: 21.1958582 },
    { lat: 48.874367, lng: 21.195288 },
    { lat: 48.8753435, lng: 21.1951662 },
    { lat: 48.8746056, lng: 21.1944593 },
    { lat: 48.8739003, lng: 21.1943174 },
    { lat: 48.8732827, lng: 21.193896 },
    { lat: 48.8727562, lng: 21.1937538 },
    { lat: 48.8720116, lng: 21.1929845 },
    { lat: 48.8717669, lng: 21.1931068 },
    { lat: 48.8715024, lng: 21.1931391 },
    { lat: 48.8707911, lng: 21.1928536 },
    { lat: 48.8694716, lng: 21.192766 },
    { lat: 48.8688864, lng: 21.1925444 },
    { lat: 48.8687586, lng: 21.1923516 },
    { lat: 48.8687075, lng: 21.1921204 },
    { lat: 48.8689975, lng: 21.1913875 },
    { lat: 48.868856, lng: 21.1908607 },
    { lat: 48.8687634, lng: 21.1902797 },
    { lat: 48.8688372, lng: 21.1899784 },
    { lat: 48.868829, lng: 21.1892862 },
    { lat: 48.8683337, lng: 21.1873463 },
    { lat: 48.8681021, lng: 21.1873537 },
    { lat: 48.8676351, lng: 21.187496 },
    { lat: 48.8673153, lng: 21.1877046 },
    { lat: 48.8670446, lng: 21.1881195 },
    { lat: 48.866658, lng: 21.1889206 },
    { lat: 48.8662219, lng: 21.1892641 },
    { lat: 48.8650788, lng: 21.1890573 },
    { lat: 48.8644833, lng: 21.1887646 },
    { lat: 48.8641516, lng: 21.1884504 },
    { lat: 48.8635966, lng: 21.1881237 },
    { lat: 48.8630348, lng: 21.1882965 },
    { lat: 48.862671, lng: 21.1881783 },
    { lat: 48.8624154, lng: 21.1880656 },
    { lat: 48.8620036, lng: 21.1877118 },
    { lat: 48.8616798, lng: 21.1875393 },
    { lat: 48.8612162, lng: 21.1877579 },
    { lat: 48.8604691, lng: 21.1878675 },
    { lat: 48.8600982, lng: 21.1880969 },
    { lat: 48.8594599, lng: 21.1886694 },
    { lat: 48.8593848, lng: 21.1888907 },
    { lat: 48.8593141, lng: 21.1894326 },
    { lat: 48.8590966, lng: 21.189602 },
    { lat: 48.8590576, lng: 21.189692 },
    { lat: 48.8588591, lng: 21.189677 },
    { lat: 48.8588654, lng: 21.1892337 },
    { lat: 48.8588176, lng: 21.1888898 },
    { lat: 48.8585586, lng: 21.1881672 },
    { lat: 48.8582539, lng: 21.1876785 },
    { lat: 48.8576581, lng: 21.1869474 },
    { lat: 48.857462, lng: 21.1867918 },
    { lat: 48.8574204, lng: 21.1870428 },
    { lat: 48.8574571, lng: 21.1870623 },
    { lat: 48.857434, lng: 21.1880461 },
    { lat: 48.857153, lng: 21.1888451 },
    { lat: 48.8569577, lng: 21.1891154 },
    { lat: 48.8568028, lng: 21.189628 },
    { lat: 48.8564625, lng: 21.1902976 },
    { lat: 48.8563377, lng: 21.1915192 },
    { lat: 48.8564042, lng: 21.1921745 },
    { lat: 48.856384, lng: 21.1923503 },
    { lat: 48.8564415, lng: 21.1926785 },
    { lat: 48.8564217, lng: 21.1929123 },
    { lat: 48.8562177, lng: 21.1928973 },
    { lat: 48.8545188, lng: 21.1922638 },
    { lat: 48.8533974, lng: 21.1915709 },
    { lat: 48.853067, lng: 21.191187 },
    { lat: 48.8526991, lng: 21.1904734 },
    { lat: 48.8524964, lng: 21.1902211 },
    { lat: 48.8512553, lng: 21.1911596 },
    { lat: 48.8509432, lng: 21.1912914 },
    { lat: 48.8509636, lng: 21.1910795 },
    { lat: 48.8504536, lng: 21.189743 },
    { lat: 48.8504528, lng: 21.1889658 },
    { lat: 48.8502353, lng: 21.1881307 },
    { lat: 48.8499951, lng: 21.1868273 },
    { lat: 48.8496167, lng: 21.1858848 },
    { lat: 48.8491704, lng: 21.1845477 },
    { lat: 48.8489087, lng: 21.1841657 },
    { lat: 48.8487031, lng: 21.1836045 },
    { lat: 48.8483571, lng: 21.1830492 },
    { lat: 48.848002, lng: 21.182069 },
    { lat: 48.8480812, lng: 21.181761 },
    { lat: 48.8482114, lng: 21.1801722 },
    { lat: 48.8477519, lng: 21.1794482 },
    { lat: 48.8473802, lng: 21.1777249 },
    { lat: 48.847171, lng: 21.1772237 },
    { lat: 48.8469814, lng: 21.176962 },
    { lat: 48.8466458, lng: 21.1766697 },
    { lat: 48.8465382, lng: 21.1763219 },
    { lat: 48.8463413, lng: 21.1759653 },
    { lat: 48.8461952, lng: 21.1753995 },
    { lat: 48.8457483, lng: 21.174575 },
    { lat: 48.8453488, lng: 21.1749348 },
    { lat: 48.8450136, lng: 21.1748913 },
    { lat: 48.8448605, lng: 21.1748715 },
    { lat: 48.8435072, lng: 21.1727817 },
    { lat: 48.8432858, lng: 21.1723633 },
    { lat: 48.8429053, lng: 21.1718217 },
    { lat: 48.8423289, lng: 21.17114 },
    { lat: 48.8421629, lng: 21.1710859 },
    { lat: 48.8420434, lng: 21.171047 },
    { lat: 48.8415405, lng: 21.1711899 },
    { lat: 48.8410851, lng: 21.1711682 },
    { lat: 48.8408374, lng: 21.1714113 },
    { lat: 48.840233, lng: 21.1711278 },
    { lat: 48.8399504, lng: 21.17112 },
    { lat: 48.8392065, lng: 21.1719467 },
    { lat: 48.8387686, lng: 21.1719005 },
    { lat: 48.8384353, lng: 21.1717857 },
    { lat: 48.8374657, lng: 21.1700568 },
    { lat: 48.8371566, lng: 21.1692546 },
    { lat: 48.836984, lng: 21.1682015 },
    { lat: 48.8367619, lng: 21.1677635 },
    { lat: 48.836561, lng: 21.1670683 },
    { lat: 48.8365158, lng: 21.1659973 },
    { lat: 48.8362596, lng: 21.1657254 },
  ],
  Geča: [
    { lat: 48.6219276, lng: 21.2782278 },
    { lat: 48.6218905, lng: 21.2782251 },
    { lat: 48.6187593, lng: 21.2780669 },
    { lat: 48.6178478, lng: 21.2781431 },
    { lat: 48.6161214, lng: 21.2785432 },
    { lat: 48.613992, lng: 21.2794914 },
    { lat: 48.6141376, lng: 21.2837857 },
    { lat: 48.6141793, lng: 21.2948576 },
    { lat: 48.6146569, lng: 21.2949805 },
    { lat: 48.6147343, lng: 21.29773 },
    { lat: 48.614885, lng: 21.2993114 },
    { lat: 48.614951, lng: 21.2993702 },
    { lat: 48.6152574, lng: 21.3008461 },
    { lat: 48.6157793, lng: 21.3037842 },
    { lat: 48.6158784, lng: 21.3038638 },
    { lat: 48.6162169, lng: 21.305286 },
    { lat: 48.6183953, lng: 21.3129489 },
    { lat: 48.6192914, lng: 21.3176167 },
    { lat: 48.6195587, lng: 21.3194815 },
    { lat: 48.619873, lng: 21.3207139 },
    { lat: 48.6200134, lng: 21.3220039 },
    { lat: 48.6199336, lng: 21.3228239 },
    { lat: 48.6203792, lng: 21.3252641 },
    { lat: 48.6214092, lng: 21.3289835 },
    { lat: 48.6217475, lng: 21.3305285 },
    { lat: 48.6218533, lng: 21.3308989 },
    { lat: 48.6221906, lng: 21.3311122 },
    { lat: 48.6228389, lng: 21.33193 },
    { lat: 48.6244311, lng: 21.3297752 },
    { lat: 48.6245376, lng: 21.3297953 },
    { lat: 48.6244746, lng: 21.3303133 },
    { lat: 48.6242184, lng: 21.3314788 },
    { lat: 48.6240495, lng: 21.3318121 },
    { lat: 48.6233427, lng: 21.3325103 },
    { lat: 48.6232671, lng: 21.333096 },
    { lat: 48.6232994, lng: 21.3333147 },
    { lat: 48.6234564, lng: 21.3335166 },
    { lat: 48.6237957, lng: 21.333723 },
    { lat: 48.6242307, lng: 21.3341092 },
    { lat: 48.6246186, lng: 21.3339925 },
    { lat: 48.6246672, lng: 21.3339244 },
    { lat: 48.6246926, lng: 21.3338682 },
    { lat: 48.6247566, lng: 21.3336746 },
    { lat: 48.6247611, lng: 21.3332317 },
    { lat: 48.6249513, lng: 21.3328227 },
    { lat: 48.6249531, lng: 21.3326956 },
    { lat: 48.6248196, lng: 21.3324142 },
    { lat: 48.6248735, lng: 21.3321878 },
    { lat: 48.62497, lng: 21.3322203 },
    { lat: 48.6255752, lng: 21.332768 },
    { lat: 48.6258475, lng: 21.332725 },
    { lat: 48.6260198, lng: 21.3326203 },
    { lat: 48.6260358, lng: 21.3323723 },
    { lat: 48.6262635, lng: 21.332082 },
    { lat: 48.6263604, lng: 21.3317534 },
    { lat: 48.6264747, lng: 21.3315495 },
    { lat: 48.6267382, lng: 21.3312958 },
    { lat: 48.6269275, lng: 21.33134 },
    { lat: 48.6271889, lng: 21.3317243 },
    { lat: 48.6272519, lng: 21.3330938 },
    { lat: 48.6271986, lng: 21.3334209 },
    { lat: 48.6271065, lng: 21.3336118 },
    { lat: 48.626951, lng: 21.3337876 },
    { lat: 48.6267912, lng: 21.3338616 },
    { lat: 48.6265937, lng: 21.3341441 },
    { lat: 48.6265432, lng: 21.3344795 },
    { lat: 48.6266671, lng: 21.334747 },
    { lat: 48.6268125, lng: 21.3348288 },
    { lat: 48.6272446, lng: 21.3348228 },
    { lat: 48.6275094, lng: 21.3349487 },
    { lat: 48.6280279, lng: 21.3355003 },
    { lat: 48.628614, lng: 21.3362738 },
    { lat: 48.6292417, lng: 21.3376041 },
    { lat: 48.6295043, lng: 21.3383189 },
    { lat: 48.6298592, lng: 21.3386683 },
    { lat: 48.6299898, lng: 21.3386668 },
    { lat: 48.6302336, lng: 21.338512 },
    { lat: 48.6303141, lng: 21.3381251 },
    { lat: 48.630287, lng: 21.3379694 },
    { lat: 48.6301579, lng: 21.337831 },
    { lat: 48.629673, lng: 21.3377996 },
    { lat: 48.6295699, lng: 21.3376267 },
    { lat: 48.6294123, lng: 21.3371029 },
    { lat: 48.6293374, lng: 21.3366772 },
    { lat: 48.6294436, lng: 21.3365393 },
    { lat: 48.6295961, lng: 21.3365587 },
    { lat: 48.6299384, lng: 21.3371253 },
    { lat: 48.6302802, lng: 21.3375162 },
    { lat: 48.6305723, lng: 21.3375064 },
    { lat: 48.6306961, lng: 21.3373915 },
    { lat: 48.6307828, lng: 21.3370594 },
    { lat: 48.6307726, lng: 21.3369103 },
    { lat: 48.6306639, lng: 21.3366623 },
    { lat: 48.630644, lng: 21.3363378 },
    { lat: 48.6306746, lng: 21.3361963 },
    { lat: 48.6308958, lng: 21.3358477 },
    { lat: 48.6310404, lng: 21.3357552 },
    { lat: 48.6312016, lng: 21.3358746 },
    { lat: 48.6315387, lng: 21.3363377 },
    { lat: 48.6316333, lng: 21.3366436 },
    { lat: 48.6316699, lng: 21.3372523 },
    { lat: 48.6318506, lng: 21.3381378 },
    { lat: 48.6317782, lng: 21.3388319 },
    { lat: 48.6318533, lng: 21.3390775 },
    { lat: 48.6320748, lng: 21.339148 },
    { lat: 48.6321724, lng: 21.3391022 },
    { lat: 48.6323202, lng: 21.3388099 },
    { lat: 48.6324763, lng: 21.3388397 },
    { lat: 48.6325667, lng: 21.339482 },
    { lat: 48.6326774, lng: 21.3397597 },
    { lat: 48.6328971, lng: 21.3399908 },
    { lat: 48.6332129, lng: 21.3399765 },
    { lat: 48.6332731, lng: 21.3396209 },
    { lat: 48.6331188, lng: 21.3389876 },
    { lat: 48.6329432, lng: 21.3386528 },
    { lat: 48.6324506, lng: 21.3380605 },
    { lat: 48.6326312, lng: 21.3377087 },
    { lat: 48.6332953, lng: 21.337415 },
    { lat: 48.6334647, lng: 21.3375118 },
    { lat: 48.6339555, lng: 21.3383006 },
    { lat: 48.6341864, lng: 21.3384484 },
    { lat: 48.6346102, lng: 21.3385846 },
    { lat: 48.6349455, lng: 21.3388106 },
    { lat: 48.6350617, lng: 21.338783 },
    { lat: 48.6351596, lng: 21.3386685 },
    { lat: 48.6351951, lng: 21.3380939 },
    { lat: 48.6353629, lng: 21.337875 },
    { lat: 48.6359786, lng: 21.337654 },
    { lat: 48.6362314, lng: 21.3376785 },
    { lat: 48.6363322, lng: 21.3375873 },
    { lat: 48.636168, lng: 21.336291 },
    { lat: 48.6360631, lng: 21.3359901 },
    { lat: 48.6357343, lng: 21.3355353 },
    { lat: 48.6346825, lng: 21.3347685 },
    { lat: 48.6344473, lng: 21.3341763 },
    { lat: 48.6345019, lng: 21.3334848 },
    { lat: 48.6346717, lng: 21.333033 },
    { lat: 48.6356897, lng: 21.332575 },
    { lat: 48.6356625, lng: 21.3323075 },
    { lat: 48.6351954, lng: 21.3300047 },
    { lat: 48.6349377, lng: 21.3291504 },
    { lat: 48.6345274, lng: 21.3268971 },
    { lat: 48.6329673, lng: 21.3206265 },
    { lat: 48.6324457, lng: 21.318317 },
    { lat: 48.6321596, lng: 21.3165316 },
    { lat: 48.631786, lng: 21.315609 },
    { lat: 48.6317729, lng: 21.3154737 },
    { lat: 48.6307152, lng: 21.3103784 },
    { lat: 48.630063, lng: 21.3081073 },
    { lat: 48.6297541, lng: 21.3072056 },
    { lat: 48.6290243, lng: 21.3053469 },
    { lat: 48.6281313, lng: 21.3034862 },
    { lat: 48.6265995, lng: 21.3006045 },
    { lat: 48.6258502, lng: 21.2984415 },
    { lat: 48.6247424, lng: 21.294389 },
    { lat: 48.6242914, lng: 21.2923761 },
    { lat: 48.6228457, lng: 21.2846227 },
    { lat: 48.6223416, lng: 21.2815493 },
    { lat: 48.6219276, lng: 21.2782278 },
  ],
  Kráľovce: [
    { lat: 48.8064051, lng: 21.3687121 },
    { lat: 48.806414, lng: 21.3687483 },
    { lat: 48.8064523, lng: 21.3689031 },
    { lat: 48.808262, lng: 21.3689866 },
    { lat: 48.8088217, lng: 21.3688108 },
    { lat: 48.8096459, lng: 21.3687897 },
    { lat: 48.810168, lng: 21.3692802 },
    { lat: 48.8103602, lng: 21.3693438 },
    { lat: 48.8111276, lng: 21.369007 },
    { lat: 48.8115587, lng: 21.3690479 },
    { lat: 48.8115926, lng: 21.3692362 },
    { lat: 48.8115601, lng: 21.369925 },
    { lat: 48.8111993, lng: 21.3714316 },
    { lat: 48.8118386, lng: 21.3713711 },
    { lat: 48.812204, lng: 21.3713414 },
    { lat: 48.8130714, lng: 21.3710961 },
    { lat: 48.8135914, lng: 21.3709863 },
    { lat: 48.8135956, lng: 21.3709378 },
    { lat: 48.8144465, lng: 21.3639029 },
    { lat: 48.8148961, lng: 21.3611223 },
    { lat: 48.8153919, lng: 21.3574059 },
    { lat: 48.815904, lng: 21.3525306 },
    { lat: 48.8165632, lng: 21.3469775 },
    { lat: 48.8167228, lng: 21.345846 },
    { lat: 48.8168507, lng: 21.345237 },
    { lat: 48.8169254, lng: 21.3451036 },
    { lat: 48.816714, lng: 21.3448366 },
    { lat: 48.8164825, lng: 21.3445125 },
    { lat: 48.8162731, lng: 21.3441765 },
    { lat: 48.8160642, lng: 21.3435022 },
    { lat: 48.8158706, lng: 21.3429126 },
    { lat: 48.8155568, lng: 21.3418368 },
    { lat: 48.815412, lng: 21.3411341 },
    { lat: 48.8151879, lng: 21.3403114 },
    { lat: 48.8150974, lng: 21.3395366 },
    { lat: 48.8150327, lng: 21.3386663 },
    { lat: 48.8150065, lng: 21.3383042 },
    { lat: 48.8148831, lng: 21.3372459 },
    { lat: 48.814805, lng: 21.3366067 },
    { lat: 48.8147282, lng: 21.3362205 },
    { lat: 48.814679, lng: 21.3357951 },
    { lat: 48.8146912, lng: 21.335292 },
    { lat: 48.8145405, lng: 21.3346398 },
    { lat: 48.8143593, lng: 21.3340609 },
    { lat: 48.8142344, lng: 21.3335021 },
    { lat: 48.8140102, lng: 21.3327537 },
    { lat: 48.8139522, lng: 21.3319899 },
    { lat: 48.813922, lng: 21.3318824 },
    { lat: 48.8136543, lng: 21.3313175 },
    { lat: 48.8134987, lng: 21.3309388 },
    { lat: 48.8134601, lng: 21.3302945 },
    { lat: 48.8133621, lng: 21.3299921 },
    { lat: 48.8131903, lng: 21.3300422 },
    { lat: 48.8131023, lng: 21.3299626 },
    { lat: 48.8130377, lng: 21.3298011 },
    { lat: 48.8129894, lng: 21.3290593 },
    { lat: 48.8129127, lng: 21.3288639 },
    { lat: 48.8128391, lng: 21.3287766 },
    { lat: 48.8127488, lng: 21.3287703 },
    { lat: 48.8126609, lng: 21.3289506 },
    { lat: 48.8125275, lng: 21.3289688 },
    { lat: 48.8123507, lng: 21.3284405 },
    { lat: 48.8122018, lng: 21.3274979 },
    { lat: 48.8122424, lng: 21.3272059 },
    { lat: 48.8121124, lng: 21.3267325 },
    { lat: 48.8121567, lng: 21.3265598 },
    { lat: 48.8119022, lng: 21.3234727 },
    { lat: 48.8116906, lng: 21.322061 },
    { lat: 48.8114321, lng: 21.321287 },
    { lat: 48.8111188, lng: 21.3200824 },
    { lat: 48.8110768, lng: 21.3201049 },
    { lat: 48.8107965, lng: 21.318918 },
    { lat: 48.8059184, lng: 21.311948 },
    { lat: 48.8057733, lng: 21.3116504 },
    { lat: 48.8062331, lng: 21.3115627 },
    { lat: 48.8077374, lng: 21.3116658 },
    { lat: 48.8076914, lng: 21.3078961 },
    { lat: 48.8072274, lng: 21.3077342 },
    { lat: 48.8065997, lng: 21.3072942 },
    { lat: 48.8059444, lng: 21.3074341 },
    { lat: 48.8058952, lng: 21.3074447 },
    { lat: 48.8058299, lng: 21.3074749 },
    { lat: 48.8055202, lng: 21.3076896 },
    { lat: 48.8049897, lng: 21.3084217 },
    { lat: 48.8046888, lng: 21.3086218 },
    { lat: 48.8042977, lng: 21.3088283 },
    { lat: 48.8036045, lng: 21.308978 },
    { lat: 48.8034333, lng: 21.3091184 },
    { lat: 48.8027601, lng: 21.3093295 },
    { lat: 48.8023302, lng: 21.3093655 },
    { lat: 48.8015219, lng: 21.3099887 },
    { lat: 48.8013906, lng: 21.310222 },
    { lat: 48.8012318, lng: 21.3109181 },
    { lat: 48.8009337, lng: 21.3112979 },
    { lat: 48.8006653, lng: 21.3120573 },
    { lat: 48.8004883, lng: 21.3123409 },
    { lat: 48.8002646, lng: 21.3123804 },
    { lat: 48.7998857, lng: 21.3119877 },
    { lat: 48.7998303, lng: 21.3120172 },
    { lat: 48.7992598, lng: 21.3125353 },
    { lat: 48.7986535, lng: 21.312914 },
    { lat: 48.7985661, lng: 21.313407 },
    { lat: 48.7983793, lng: 21.313591 },
    { lat: 48.7980565, lng: 21.3135213 },
    { lat: 48.7978279, lng: 21.3133727 },
    { lat: 48.797692, lng: 21.3131219 },
    { lat: 48.797394, lng: 21.3129435 },
    { lat: 48.7971552, lng: 21.3126378 },
    { lat: 48.7968704, lng: 21.3121176 },
    { lat: 48.7966854, lng: 21.3118824 },
    { lat: 48.7961264, lng: 21.3115911 },
    { lat: 48.7958566, lng: 21.3115649 },
    { lat: 48.7951998, lng: 21.3113536 },
    { lat: 48.794473, lng: 21.3109888 },
    { lat: 48.7943026, lng: 21.3109749 },
    { lat: 48.7937963, lng: 21.3115341 },
    { lat: 48.7938033, lng: 21.3116846 },
    { lat: 48.7943193, lng: 21.3115937 },
    { lat: 48.7945628, lng: 21.3122046 },
    { lat: 48.7946425, lng: 21.3121966 },
    { lat: 48.7947638, lng: 21.3125258 },
    { lat: 48.794738, lng: 21.3128552 },
    { lat: 48.7940792, lng: 21.3208956 },
    { lat: 48.7938099, lng: 21.3217741 },
    { lat: 48.7934632, lng: 21.3222557 },
    { lat: 48.7929091, lng: 21.3227956 },
    { lat: 48.7931433, lng: 21.3239507 },
    { lat: 48.7935133, lng: 21.3240596 },
    { lat: 48.7938818, lng: 21.324336 },
    { lat: 48.7940275, lng: 21.3242802 },
    { lat: 48.7938793, lng: 21.323466 },
    { lat: 48.7939633, lng: 21.3233834 },
    { lat: 48.7941968, lng: 21.32351 },
    { lat: 48.794311, lng: 21.3237152 },
    { lat: 48.794587, lng: 21.3236864 },
    { lat: 48.7946387, lng: 21.3238745 },
    { lat: 48.7951961, lng: 21.32333 },
    { lat: 48.7954086, lng: 21.3229866 },
    { lat: 48.7957761, lng: 21.3239412 },
    { lat: 48.7959984, lng: 21.3251065 },
    { lat: 48.7963852, lng: 21.3283788 },
    { lat: 48.7965294, lng: 21.3301288 },
    { lat: 48.7968289, lng: 21.330995 },
    { lat: 48.7969587, lng: 21.3311559 },
    { lat: 48.7970127, lng: 21.3314138 },
    { lat: 48.7969323, lng: 21.3320312 },
    { lat: 48.796729, lng: 21.3327566 },
    { lat: 48.7964743, lng: 21.3333007 },
    { lat: 48.7959743, lng: 21.3340327 },
    { lat: 48.7951937, lng: 21.3347563 },
    { lat: 48.7952279, lng: 21.335139 },
    { lat: 48.7954146, lng: 21.3358821 },
    { lat: 48.7956329, lng: 21.3374117 },
    { lat: 48.7959409, lng: 21.3387814 },
    { lat: 48.7953796, lng: 21.3395368 },
    { lat: 48.7951548, lng: 21.3401012 },
    { lat: 48.7956364, lng: 21.3422392 },
    { lat: 48.795914, lng: 21.3431184 },
    { lat: 48.7960541, lng: 21.3433607 },
    { lat: 48.796128, lng: 21.343467 },
    { lat: 48.7964702, lng: 21.3435783 },
    { lat: 48.797569, lng: 21.3439676 },
    { lat: 48.7983986, lng: 21.3444237 },
    { lat: 48.7986856, lng: 21.3443525 },
    { lat: 48.7991911, lng: 21.34397 },
    { lat: 48.7998162, lng: 21.3468917 },
    { lat: 48.8001771, lng: 21.3467294 },
    { lat: 48.8002576, lng: 21.347008 },
    { lat: 48.80148, lng: 21.3466068 },
    { lat: 48.8024471, lng: 21.3465162 },
    { lat: 48.8025269, lng: 21.3467309 },
    { lat: 48.8026429, lng: 21.3466608 },
    { lat: 48.8030225, lng: 21.3473108 },
    { lat: 48.8033957, lng: 21.3471102 },
    { lat: 48.8038854, lng: 21.3470079 },
    { lat: 48.8038728, lng: 21.3478194 },
    { lat: 48.8042687, lng: 21.3475508 },
    { lat: 48.8044187, lng: 21.347976 },
    { lat: 48.8045059, lng: 21.3479204 },
    { lat: 48.8045363, lng: 21.3480293 },
    { lat: 48.8047422, lng: 21.3483537 },
    { lat: 48.8048685, lng: 21.3483024 },
    { lat: 48.8052984, lng: 21.3480432 },
    { lat: 48.8058414, lng: 21.3501938 },
    { lat: 48.806364, lng: 21.3499825 },
    { lat: 48.8067391, lng: 21.3509692 },
    { lat: 48.806405, lng: 21.3511292 },
    { lat: 48.8067917, lng: 21.352501 },
    { lat: 48.8070052, lng: 21.3523795 },
    { lat: 48.8071595, lng: 21.354371 },
    { lat: 48.8074614, lng: 21.3562198 },
    { lat: 48.8069988, lng: 21.3565147 },
    { lat: 48.8071506, lng: 21.356998 },
    { lat: 48.8074263, lng: 21.3568516 },
    { lat: 48.8081685, lng: 21.3593486 },
    { lat: 48.8084477, lng: 21.3597688 },
    { lat: 48.8088731, lng: 21.3595472 },
    { lat: 48.809291, lng: 21.3604221 },
    { lat: 48.8088676, lng: 21.3607482 },
    { lat: 48.8086508, lng: 21.3605735 },
    { lat: 48.8085207, lng: 21.3611508 },
    { lat: 48.8084193, lng: 21.362167 },
    { lat: 48.8082726, lng: 21.3630153 },
    { lat: 48.8079341, lng: 21.3646047 },
    { lat: 48.806828, lng: 21.3663323 },
    { lat: 48.8065707, lng: 21.3675141 },
    { lat: 48.8062545, lng: 21.3681152 },
    { lat: 48.8064051, lng: 21.3687121 },
  ],
  Slančík: [
    { lat: 48.672452, lng: 21.4827473 },
    { lat: 48.6723697, lng: 21.4826235 },
    { lat: 48.6723311, lng: 21.4824163 },
    { lat: 48.6720814, lng: 21.4823413 },
    { lat: 48.6720146, lng: 21.4822492 },
    { lat: 48.6720163, lng: 21.4821149 },
    { lat: 48.6719366, lng: 21.4820401 },
    { lat: 48.6719801, lng: 21.4816449 },
    { lat: 48.6719462, lng: 21.4812882 },
    { lat: 48.6718303, lng: 21.4812535 },
    { lat: 48.6716923, lng: 21.4810701 },
    { lat: 48.6716308, lng: 21.4808523 },
    { lat: 48.6715005, lng: 21.4807007 },
    { lat: 48.6715355, lng: 21.4804371 },
    { lat: 48.6714401, lng: 21.479668 },
    { lat: 48.6713663, lng: 21.4794486 },
    { lat: 48.6711176, lng: 21.4791411 },
    { lat: 48.6705623, lng: 21.47893 },
    { lat: 48.6704225, lng: 21.4789563 },
    { lat: 48.6702963, lng: 21.4787582 },
    { lat: 48.6700602, lng: 21.4786417 },
    { lat: 48.6699527, lng: 21.4784313 },
    { lat: 48.6696384, lng: 21.4781463 },
    { lat: 48.6695335, lng: 21.4782134 },
    { lat: 48.6693095, lng: 21.4779035 },
    { lat: 48.6692226, lng: 21.4776654 },
    { lat: 48.669115, lng: 21.4777202 },
    { lat: 48.6686617, lng: 21.4773743 },
    { lat: 48.6682381, lng: 21.4773122 },
    { lat: 48.6681252, lng: 21.4775337 },
    { lat: 48.6678959, lng: 21.4777008 },
    { lat: 48.6673146, lng: 21.4777542 },
    { lat: 48.6669904, lng: 21.4779038 },
    { lat: 48.6669903, lng: 21.4778995 },
    { lat: 48.6668897, lng: 21.4779261 },
    { lat: 48.6668524, lng: 21.4782251 },
    { lat: 48.6668972, lng: 21.4783806 },
    { lat: 48.6668843, lng: 21.4785947 },
    { lat: 48.6667274, lng: 21.4785751 },
    { lat: 48.6666248, lng: 21.4786463 },
    { lat: 48.6666617, lng: 21.4787902 },
    { lat: 48.6665971, lng: 21.4788536 },
    { lat: 48.6664646, lng: 21.4787096 },
    { lat: 48.6663813, lng: 21.4787055 },
    { lat: 48.6663192, lng: 21.478972 },
    { lat: 48.6661559, lng: 21.4788863 },
    { lat: 48.6659249, lng: 21.4788802 },
    { lat: 48.6658575, lng: 21.4789968 },
    { lat: 48.6657229, lng: 21.4790534 },
    { lat: 48.6656315, lng: 21.4792342 },
    { lat: 48.6654157, lng: 21.4790887 },
    { lat: 48.6653091, lng: 21.4792979 },
    { lat: 48.6653555, lng: 21.4793774 },
    { lat: 48.6652483, lng: 21.4796419 },
    { lat: 48.6653087, lng: 21.4798226 },
    { lat: 48.6649771, lng: 21.4806145 },
    { lat: 48.6649779, lng: 21.480717 },
    { lat: 48.6648916, lng: 21.4807661 },
    { lat: 48.6649142, lng: 21.4808529 },
    { lat: 48.6648713, lng: 21.4809208 },
    { lat: 48.6646209, lng: 21.4809755 },
    { lat: 48.6646462, lng: 21.4811497 },
    { lat: 48.6646065, lng: 21.4812626 },
    { lat: 48.6645128, lng: 21.481324 },
    { lat: 48.6643208, lng: 21.4812238 },
    { lat: 48.664124, lng: 21.4813821 },
    { lat: 48.6640096, lng: 21.481395 },
    { lat: 48.6640442, lng: 21.4816198 },
    { lat: 48.6640229, lng: 21.4818134 },
    { lat: 48.6638599, lng: 21.4819747 },
    { lat: 48.6639091, lng: 21.4821591 },
    { lat: 48.6638213, lng: 21.4824746 },
    { lat: 48.6637159, lng: 21.4824399 },
    { lat: 48.6636895, lng: 21.4825053 },
    { lat: 48.6637464, lng: 21.4826059 },
    { lat: 48.6637068, lng: 21.4826726 },
    { lat: 48.6634717, lng: 21.4826379 },
    { lat: 48.6634285, lng: 21.4827009 },
    { lat: 48.6634652, lng: 21.4828053 },
    { lat: 48.6634394, lng: 21.4829223 },
    { lat: 48.6632959, lng: 21.4831322 },
    { lat: 48.663298, lng: 21.4832844 },
    { lat: 48.6632377, lng: 21.4834183 },
    { lat: 48.6632955, lng: 21.4835487 },
    { lat: 48.6632897, lng: 21.483658 },
    { lat: 48.6629014, lng: 21.4838132 },
    { lat: 48.6624258, lng: 21.48413 },
    { lat: 48.6623701, lng: 21.4843494 },
    { lat: 48.6619562, lng: 21.484859 },
    { lat: 48.661892, lng: 21.4851669 },
    { lat: 48.6617024, lng: 21.4853058 },
    { lat: 48.6616064, lng: 21.4855225 },
    { lat: 48.6613584, lng: 21.4855897 },
    { lat: 48.6611836, lng: 21.4853848 },
    { lat: 48.6608139, lng: 21.4857456 },
    { lat: 48.6608104, lng: 21.4862502 },
    { lat: 48.6607203, lng: 21.4863223 },
    { lat: 48.6605785, lng: 21.4863196 },
    { lat: 48.66049, lng: 21.4864158 },
    { lat: 48.660563, lng: 21.4868346 },
    { lat: 48.6603958, lng: 21.4871677 },
    { lat: 48.6601961, lng: 21.4871837 },
    { lat: 48.6601217, lng: 21.4869121 },
    { lat: 48.6600588, lng: 21.4869148 },
    { lat: 48.6599994, lng: 21.4871921 },
    { lat: 48.6599141, lng: 21.4872418 },
    { lat: 48.6596604, lng: 21.4871877 },
    { lat: 48.6596412, lng: 21.4873687 },
    { lat: 48.6595066, lng: 21.4874705 },
    { lat: 48.6591926, lng: 21.4875439 },
    { lat: 48.6590561, lng: 21.4877255 },
    { lat: 48.658869, lng: 21.4877133 },
    { lat: 48.6585693, lng: 21.4878601 },
    { lat: 48.6584822, lng: 21.488036 },
    { lat: 48.658315, lng: 21.4881349 },
    { lat: 48.6581066, lng: 21.4880465 },
    { lat: 48.6578122, lng: 21.4880969 },
    { lat: 48.6575352, lng: 21.4879379 },
    { lat: 48.6573613, lng: 21.4879896 },
    { lat: 48.6570578, lng: 21.4883541 },
    { lat: 48.6569632, lng: 21.4883123 },
    { lat: 48.6567395, lng: 21.4884694 },
    { lat: 48.6566532, lng: 21.4885996 },
    { lat: 48.6563187, lng: 21.4883583 },
    { lat: 48.6561817, lng: 21.4883677 },
    { lat: 48.6560705, lng: 21.4887299 },
    { lat: 48.6561719, lng: 21.4888449 },
    { lat: 48.6561841, lng: 21.4891121 },
    { lat: 48.6561156, lng: 21.4892376 },
    { lat: 48.6558117, lng: 21.4891893 },
    { lat: 48.6556971, lng: 21.4893118 },
    { lat: 48.6556442, lng: 21.4895139 },
    { lat: 48.6554599, lng: 21.4895164 },
    { lat: 48.6553719, lng: 21.4896699 },
    { lat: 48.6550104, lng: 21.4897336 },
    { lat: 48.6549684, lng: 21.4899542 },
    { lat: 48.6548568, lng: 21.4899843 },
    { lat: 48.6547681, lng: 21.4901136 },
    { lat: 48.654658, lng: 21.490113 },
    { lat: 48.6545695, lng: 21.4902822 },
    { lat: 48.654383, lng: 21.4904113 },
    { lat: 48.6541416, lng: 21.4904307 },
    { lat: 48.6540649, lng: 21.4905425 },
    { lat: 48.65393, lng: 21.4905817 },
    { lat: 48.6538397, lng: 21.4904606 },
    { lat: 48.6535927, lng: 21.4904398 },
    { lat: 48.6533247, lng: 21.4905881 },
    { lat: 48.6532256, lng: 21.4905451 },
    { lat: 48.6530777, lng: 21.4902168 },
    { lat: 48.6527041, lng: 21.4904097 },
    { lat: 48.652538, lng: 21.4906616 },
    { lat: 48.6523254, lng: 21.4908589 },
    { lat: 48.6522855, lng: 21.4909946 },
    { lat: 48.652216, lng: 21.491038 },
    { lat: 48.6519863, lng: 21.4909969 },
    { lat: 48.6519124, lng: 21.4910515 },
    { lat: 48.6518619, lng: 21.4912513 },
    { lat: 48.6519712, lng: 21.4913722 },
    { lat: 48.6519708, lng: 21.4914395 },
    { lat: 48.65187, lng: 21.4915319 },
    { lat: 48.6514521, lng: 21.4914726 },
    { lat: 48.6512303, lng: 21.4920153 },
    { lat: 48.6513507, lng: 21.492559 },
    { lat: 48.6511868, lng: 21.4931612 },
    { lat: 48.6511978, lng: 21.4937705 },
    { lat: 48.6508786, lng: 21.4937883 },
    { lat: 48.6506619, lng: 21.4936815 },
    { lat: 48.6505302, lng: 21.4937179 },
    { lat: 48.6503954, lng: 21.4938453 },
    { lat: 48.6500176, lng: 21.4937787 },
    { lat: 48.6499435, lng: 21.4940192 },
    { lat: 48.6500079, lng: 21.4942989 },
    { lat: 48.6497841, lng: 21.4944606 },
    { lat: 48.6497302, lng: 21.4945449 },
    { lat: 48.6497608, lng: 21.4946258 },
    { lat: 48.6496246, lng: 21.4947031 },
    { lat: 48.6495238, lng: 21.494877 },
    { lat: 48.6495381, lng: 21.4950618 },
    { lat: 48.6494974, lng: 21.495247 },
    { lat: 48.6494564, lng: 21.49527 },
    { lat: 48.6492795, lng: 21.4951076 },
    { lat: 48.6490473, lng: 21.4952891 },
    { lat: 48.6489892, lng: 21.4954139 },
    { lat: 48.6488711, lng: 21.4954565 },
    { lat: 48.6487886, lng: 21.4960303 },
    { lat: 48.6486913, lng: 21.4961729 },
    { lat: 48.6485893, lng: 21.4961843 },
    { lat: 48.648569, lng: 21.4960258 },
    { lat: 48.6484159, lng: 21.495942 },
    { lat: 48.6482363, lng: 21.4961175 },
    { lat: 48.6483374, lng: 21.4963859 },
    { lat: 48.6482895, lng: 21.4964403 },
    { lat: 48.6481172, lng: 21.4963947 },
    { lat: 48.6480194, lng: 21.4964353 },
    { lat: 48.6478458, lng: 21.496779 },
    { lat: 48.647743, lng: 21.4967616 },
    { lat: 48.6475788, lng: 21.4968503 },
    { lat: 48.647607, lng: 21.4973103 },
    { lat: 48.6475278, lng: 21.4973358 },
    { lat: 48.6474926, lng: 21.4974484 },
    { lat: 48.6473728, lng: 21.4978961 },
    { lat: 48.647401, lng: 21.4979765 },
    { lat: 48.6488846, lng: 21.5001746 },
    { lat: 48.649829, lng: 21.5013221 },
    { lat: 48.6500293, lng: 21.5012431 },
    { lat: 48.6504109, lng: 21.50078 },
    { lat: 48.6507746, lng: 21.5011761 },
    { lat: 48.6511729, lng: 21.5007542 },
    { lat: 48.6522254, lng: 21.5021317 },
    { lat: 48.6526094, lng: 21.5017126 },
    { lat: 48.6533399, lng: 21.5027347 },
    { lat: 48.6537208, lng: 21.5022806 },
    { lat: 48.6546839, lng: 21.5036523 },
    { lat: 48.6536879, lng: 21.5051654 },
    { lat: 48.6525226, lng: 21.5065923 },
    { lat: 48.6525329, lng: 21.50664 },
    { lat: 48.6530764, lng: 21.5092223 },
    { lat: 48.6534928, lng: 21.5091391 },
    { lat: 48.6538382, lng: 21.5099261 },
    { lat: 48.65418, lng: 21.5096432 },
    { lat: 48.6547036, lng: 21.5095317 },
    { lat: 48.6552648, lng: 21.5096811 },
    { lat: 48.6554894, lng: 21.5104497 },
    { lat: 48.6562028, lng: 21.5101756 },
    { lat: 48.6562523, lng: 21.5103709 },
    { lat: 48.6562764, lng: 21.511521 },
    { lat: 48.6568861, lng: 21.5113203 },
    { lat: 48.6571663, lng: 21.5111589 },
    { lat: 48.6573954, lng: 21.5109535 },
    { lat: 48.6574051, lng: 21.5105845 },
    { lat: 48.657752, lng: 21.510397 },
    { lat: 48.6580019, lng: 21.5106732 },
    { lat: 48.6580891, lng: 21.5106575 },
    { lat: 48.658166, lng: 21.5105176 },
    { lat: 48.6581148, lng: 21.5104177 },
    { lat: 48.6587016, lng: 21.5095393 },
    { lat: 48.6589051, lng: 21.5091359 },
    { lat: 48.6590372, lng: 21.509113 },
    { lat: 48.659344, lng: 21.5086715 },
    { lat: 48.6594901, lng: 21.5085879 },
    { lat: 48.6595737, lng: 21.5083621 },
    { lat: 48.6596694, lng: 21.5082473 },
    { lat: 48.6598084, lng: 21.508309 },
    { lat: 48.6600077, lng: 21.508155 },
    { lat: 48.6600985, lng: 21.5081875 },
    { lat: 48.6602224, lng: 21.5081103 },
    { lat: 48.6603273, lng: 21.5079194 },
    { lat: 48.6603509, lng: 21.5077228 },
    { lat: 48.6603148, lng: 21.507649 },
    { lat: 48.6605497, lng: 21.5075934 },
    { lat: 48.6606047, lng: 21.5072421 },
    { lat: 48.6608472, lng: 21.5072942 },
    { lat: 48.6608824, lng: 21.5071925 },
    { lat: 48.6610467, lng: 21.5072312 },
    { lat: 48.6612657, lng: 21.5069432 },
    { lat: 48.6614738, lng: 21.5069783 },
    { lat: 48.6616595, lng: 21.5068611 },
    { lat: 48.6622055, lng: 21.5067591 },
    { lat: 48.6625179, lng: 21.5061912 },
    { lat: 48.662763, lng: 21.5061481 },
    { lat: 48.6630715, lng: 21.5062307 },
    { lat: 48.663073, lng: 21.5063588 },
    { lat: 48.6633419, lng: 21.506207 },
    { lat: 48.6634108, lng: 21.5062897 },
    { lat: 48.6637797, lng: 21.5061718 },
    { lat: 48.6639853, lng: 21.5058843 },
    { lat: 48.6641795, lng: 21.5057915 },
    { lat: 48.6644045, lng: 21.5057828 },
    { lat: 48.6644695, lng: 21.505855 },
    { lat: 48.664649, lng: 21.5056457 },
    { lat: 48.6647487, lng: 21.5057072 },
    { lat: 48.6649522, lng: 21.5054635 },
    { lat: 48.6650366, lng: 21.5051931 },
    { lat: 48.6654411, lng: 21.5055646 },
    { lat: 48.6656072, lng: 21.5055117 },
    { lat: 48.6657163, lng: 21.5053863 },
    { lat: 48.6658654, lng: 21.5054463 },
    { lat: 48.6659124, lng: 21.505398 },
    { lat: 48.6659155, lng: 21.5051551 },
    { lat: 48.6659751, lng: 21.5050394 },
    { lat: 48.6661187, lng: 21.5050228 },
    { lat: 48.6660949, lng: 21.5048186 },
    { lat: 48.6663251, lng: 21.5043778 },
    { lat: 48.6661941, lng: 21.5040433 },
    { lat: 48.6661956, lng: 21.5029247 },
    { lat: 48.6663087, lng: 21.5016137 },
    { lat: 48.666875, lng: 21.5003438 },
    { lat: 48.6674897, lng: 21.4991393 },
    { lat: 48.6674124, lng: 21.4986809 },
    { lat: 48.6670008, lng: 21.497949 },
    { lat: 48.6669305, lng: 21.4971474 },
    { lat: 48.6688319, lng: 21.4937487 },
    { lat: 48.6694974, lng: 21.4920103 },
    { lat: 48.6690156, lng: 21.4906053 },
    { lat: 48.6695408, lng: 21.4889608 },
    { lat: 48.6696481, lng: 21.4885034 },
    { lat: 48.6701814, lng: 21.4871397 },
    { lat: 48.6706411, lng: 21.4863874 },
    { lat: 48.670786, lng: 21.4860653 },
    { lat: 48.6709479, lng: 21.4856729 },
    { lat: 48.670891, lng: 21.4856119 },
    { lat: 48.6713446, lng: 21.4844865 },
    { lat: 48.6718283, lng: 21.4837059 },
    { lat: 48.6722031, lng: 21.4832044 },
    { lat: 48.6721762, lng: 21.4830898 },
    { lat: 48.6723343, lng: 21.4827607 },
    { lat: 48.6723957, lng: 21.4827389 },
    { lat: 48.672452, lng: 21.4827473 },
  ],
  Baška: [
    { lat: 48.697232, lng: 21.178464 },
    { lat: 48.697241, lng: 21.178497 },
    { lat: 48.697337, lng: 21.178854 },
    { lat: 48.697513, lng: 21.179182 },
    { lat: 48.69766, lng: 21.17943 },
    { lat: 48.697832, lng: 21.179741 },
    { lat: 48.697704, lng: 21.180098 },
    { lat: 48.697626, lng: 21.180475 },
    { lat: 48.697628, lng: 21.180632 },
    { lat: 48.697637, lng: 21.181021 },
    { lat: 48.697541, lng: 21.181475 },
    { lat: 48.697466, lng: 21.181834 },
    { lat: 48.697474, lng: 21.182115 },
    { lat: 48.697497, lng: 21.18219 },
    { lat: 48.697617, lng: 21.182562 },
    { lat: 48.697704, lng: 21.182955 },
    { lat: 48.697758, lng: 21.183334 },
    { lat: 48.697842, lng: 21.183918 },
    { lat: 48.697889, lng: 21.184801 },
    { lat: 48.697927, lng: 21.1850392 },
    { lat: 48.697987, lng: 21.185415 },
    { lat: 48.698043, lng: 21.185752 },
    { lat: 48.698085, lng: 21.185926 },
    { lat: 48.698192, lng: 21.186339 },
    { lat: 48.698211, lng: 21.186478 },
    { lat: 48.6982258, lng: 21.1865845 },
    { lat: 48.698279, lng: 21.186968 },
    { lat: 48.6983197, lng: 21.187415 },
    { lat: 48.6982924, lng: 21.1877044 },
    { lat: 48.698203, lng: 21.187908 },
    { lat: 48.698198, lng: 21.187919 },
    { lat: 48.698129, lng: 21.18808 },
    { lat: 48.697773, lng: 21.188903 },
    { lat: 48.697504, lng: 21.189551 },
    { lat: 48.697293, lng: 21.190096 },
    { lat: 48.697286, lng: 21.190163 },
    { lat: 48.697244, lng: 21.190607 },
    { lat: 48.697224, lng: 21.190807 },
    { lat: 48.6971297, lng: 21.1915522 },
    { lat: 48.6970967, lng: 21.1921573 },
    { lat: 48.697014, lng: 21.192579 },
    { lat: 48.697022, lng: 21.192633 },
    { lat: 48.697022, lng: 21.19275 },
    { lat: 48.697022, lng: 21.193062 },
    { lat: 48.696742, lng: 21.193735 },
    { lat: 48.696714, lng: 21.193802 },
    { lat: 48.696584, lng: 21.193958 },
    { lat: 48.696556, lng: 21.19399 },
    { lat: 48.69642, lng: 21.194139 },
    { lat: 48.69632, lng: 21.19426 },
    { lat: 48.696227, lng: 21.194384 },
    { lat: 48.696107, lng: 21.194544 },
    { lat: 48.696067, lng: 21.194598 },
    { lat: 48.6959308, lng: 21.1946775 },
    { lat: 48.695839, lng: 21.19528 },
    { lat: 48.695856, lng: 21.195326 },
    { lat: 48.6959495, lng: 21.1955633 },
    { lat: 48.696059, lng: 21.195844 },
    { lat: 48.696029, lng: 21.196162 },
    { lat: 48.696001, lng: 21.196455 },
    { lat: 48.695989, lng: 21.196584 },
    { lat: 48.695892, lng: 21.196732 },
    { lat: 48.695797, lng: 21.196879 },
    { lat: 48.695715, lng: 21.197005 },
    { lat: 48.695677, lng: 21.197064 },
    { lat: 48.695676, lng: 21.197082 },
    { lat: 48.69567, lng: 21.197431 },
    { lat: 48.695665, lng: 21.197736 },
    { lat: 48.695662, lng: 21.197956 },
    { lat: 48.695632, lng: 21.198077 },
    { lat: 48.695545, lng: 21.198436 },
    { lat: 48.695446, lng: 21.19862 },
    { lat: 48.695389, lng: 21.198727 },
    { lat: 48.695352, lng: 21.198896 },
    { lat: 48.6952932, lng: 21.1992017 },
    { lat: 48.695119, lng: 21.199524 },
    { lat: 48.6951125, lng: 21.1995466 },
    { lat: 48.695191, lng: 21.19961 },
    { lat: 48.695403, lng: 21.199682 },
    { lat: 48.695586, lng: 21.199747 },
    { lat: 48.695866, lng: 21.199752 },
    { lat: 48.696174, lng: 21.199671 },
    { lat: 48.696326, lng: 21.199632 },
    { lat: 48.696735, lng: 21.199454 },
    { lat: 48.69715, lng: 21.199098 },
    { lat: 48.697426, lng: 21.198934 },
    { lat: 48.697589, lng: 21.198802 },
    { lat: 48.697913, lng: 21.198564 },
    { lat: 48.698285, lng: 21.198335 },
    { lat: 48.698582, lng: 21.198161 },
    { lat: 48.699016, lng: 21.19798 },
    { lat: 48.699376, lng: 21.197897 },
    { lat: 48.69972, lng: 21.197808 },
    { lat: 48.69998, lng: 21.197667 },
    { lat: 48.70025, lng: 21.197433 },
    { lat: 48.700556, lng: 21.197145 },
    { lat: 48.700979, lng: 21.196587 },
    { lat: 48.701263, lng: 21.196087 },
    { lat: 48.701492, lng: 21.195721 },
    { lat: 48.701664, lng: 21.195454 },
    { lat: 48.701788, lng: 21.195253 },
    { lat: 48.702024, lng: 21.194858 },
    { lat: 48.702186, lng: 21.194619 },
    { lat: 48.702294, lng: 21.194492 },
    { lat: 48.702613, lng: 21.19405 },
    { lat: 48.70275, lng: 21.193796 },
    { lat: 48.70276, lng: 21.193834 },
    { lat: 48.702773, lng: 21.19387 },
    { lat: 48.702774, lng: 21.193871 },
    { lat: 48.702803, lng: 21.193919 },
    { lat: 48.703003, lng: 21.194258 },
    { lat: 48.703015, lng: 21.194276 },
    { lat: 48.703032, lng: 21.194308 },
    { lat: 48.703252, lng: 21.19421 },
    { lat: 48.703419, lng: 21.194117 },
    { lat: 48.703467, lng: 21.194062 },
    { lat: 48.703479, lng: 21.193932 },
    { lat: 48.70351, lng: 21.193931 },
    { lat: 48.703661, lng: 21.19393 },
    { lat: 48.703882, lng: 21.194099 },
    { lat: 48.703952, lng: 21.194094 },
    { lat: 48.704018, lng: 21.194043 },
    { lat: 48.704172, lng: 21.193805 },
    { lat: 48.704217, lng: 21.193799 },
    { lat: 48.704429, lng: 21.193772 },
    { lat: 48.70499, lng: 21.193475 },
    { lat: 48.705304, lng: 21.193109 },
    { lat: 48.705803, lng: 21.192768 },
    { lat: 48.706291, lng: 21.19221 },
    { lat: 48.706554, lng: 21.191808 },
    { lat: 48.706664, lng: 21.191686 },
    { lat: 48.706942, lng: 21.191376 },
    { lat: 48.707246, lng: 21.190181 },
    { lat: 48.707541, lng: 21.19001 },
    { lat: 48.707688, lng: 21.189826 },
    { lat: 48.707993, lng: 21.189671 },
    { lat: 48.708016, lng: 21.189659 },
    { lat: 48.708025, lng: 21.18965 },
    { lat: 48.708202, lng: 21.189452 },
    { lat: 48.708312, lng: 21.18933 },
    { lat: 48.708398, lng: 21.189235 },
    { lat: 48.708565, lng: 21.189047 },
    { lat: 48.70858, lng: 21.18903 },
    { lat: 48.7086, lng: 21.189006 },
    { lat: 48.708639, lng: 21.188959 },
    { lat: 48.708658, lng: 21.188936 },
    { lat: 48.708679, lng: 21.188911 },
    { lat: 48.709231, lng: 21.188764 },
    { lat: 48.709635, lng: 21.188761 },
    { lat: 48.710052, lng: 21.18818 },
    { lat: 48.710192, lng: 21.187988 },
    { lat: 48.710221, lng: 21.187952 },
    { lat: 48.710437, lng: 21.187699 },
    { lat: 48.710547, lng: 21.187634 },
    { lat: 48.710836, lng: 21.187464 },
    { lat: 48.710871, lng: 21.187444 },
    { lat: 48.711068, lng: 21.187328 },
    { lat: 48.711101, lng: 21.187096 },
    { lat: 48.711143, lng: 21.187043 },
    { lat: 48.711153, lng: 21.187032 },
    { lat: 48.711191, lng: 21.186984 },
    { lat: 48.711229, lng: 21.186938 },
    { lat: 48.711242, lng: 21.186921 },
    { lat: 48.711287, lng: 21.186866 },
    { lat: 48.711321, lng: 21.186823 },
    { lat: 48.711581, lng: 21.186497 },
    { lat: 48.711853, lng: 21.186239 },
    { lat: 48.712432, lng: 21.185822 },
    { lat: 48.712668, lng: 21.185424 },
    { lat: 48.712836, lng: 21.185344 },
    { lat: 48.712958, lng: 21.185025 },
    { lat: 48.71299, lng: 21.184935 },
    { lat: 48.713115, lng: 21.184967 },
    { lat: 48.713224, lng: 21.185014 },
    { lat: 48.713309, lng: 21.185091 },
    { lat: 48.71339, lng: 21.185105 },
    { lat: 48.713473, lng: 21.185095 },
    { lat: 48.713565, lng: 21.185166 },
    { lat: 48.71363, lng: 21.185216 },
    { lat: 48.713792, lng: 21.185335 },
    { lat: 48.713882, lng: 21.185328 },
    { lat: 48.714015, lng: 21.185375 },
    { lat: 48.714227, lng: 21.185435 },
    { lat: 48.714272, lng: 21.185426 },
    { lat: 48.714341, lng: 21.185492 },
    { lat: 48.714394, lng: 21.18552 },
    { lat: 48.714586, lng: 21.185623 },
    { lat: 48.714761, lng: 21.185638 },
    { lat: 48.715012, lng: 21.185646 },
    { lat: 48.715196, lng: 21.18572 },
    { lat: 48.715245, lng: 21.185712 },
    { lat: 48.715308, lng: 21.185774 },
    { lat: 48.715738, lng: 21.18595 },
    { lat: 48.7159, lng: 21.185974 },
    { lat: 48.716062, lng: 21.18609 },
    { lat: 48.716131, lng: 21.186076 },
    { lat: 48.71616, lng: 21.186143 },
    { lat: 48.716376, lng: 21.186211 },
    { lat: 48.71672, lng: 21.185916 },
    { lat: 48.7169, lng: 21.186129 },
    { lat: 48.717035, lng: 21.186183 },
    { lat: 48.717112, lng: 21.186175 },
    { lat: 48.717163, lng: 21.186256 },
    { lat: 48.71725, lng: 21.186183 },
    { lat: 48.717281, lng: 21.186237 },
    { lat: 48.717331, lng: 21.186195 },
    { lat: 48.71736, lng: 21.18598 },
    { lat: 48.717413, lng: 21.185836 },
    { lat: 48.717426, lng: 21.185771 },
    { lat: 48.717444, lng: 21.185708 },
    { lat: 48.717463, lng: 21.185573 },
    { lat: 48.717412, lng: 21.185497 },
    { lat: 48.717441, lng: 21.185395 },
    { lat: 48.717486, lng: 21.18528 },
    { lat: 48.717494, lng: 21.185287 },
    { lat: 48.717554, lng: 21.185138 },
    { lat: 48.717588, lng: 21.185057 },
    { lat: 48.717576, lng: 21.185008 },
    { lat: 48.717644, lng: 21.184959 },
    { lat: 48.717699, lng: 21.184767 },
    { lat: 48.717684, lng: 21.184737 },
    { lat: 48.717685, lng: 21.184667 },
    { lat: 48.717689, lng: 21.184537 },
    { lat: 48.717674, lng: 21.184521 },
    { lat: 48.717665, lng: 21.18451 },
    { lat: 48.7177, lng: 21.184335 },
    { lat: 48.717723, lng: 21.184259 },
    { lat: 48.717752, lng: 21.184185 },
    { lat: 48.7178, lng: 21.184053 },
    { lat: 48.717816, lng: 21.184061 },
    { lat: 48.71789, lng: 21.183887 },
    { lat: 48.71786, lng: 21.18384 },
    { lat: 48.717907, lng: 21.18373 },
    { lat: 48.71797, lng: 21.183609 },
    { lat: 48.718013, lng: 21.183444 },
    { lat: 48.718112, lng: 21.183271 },
    { lat: 48.718159, lng: 21.183228 },
    { lat: 48.718199, lng: 21.183162 },
    { lat: 48.718179, lng: 21.183134 },
    { lat: 48.718223, lng: 21.183057 },
    { lat: 48.718242, lng: 21.183086 },
    { lat: 48.718254, lng: 21.183064 },
    { lat: 48.718241, lng: 21.18305 },
    { lat: 48.718283, lng: 21.182976 },
    { lat: 48.718274, lng: 21.182964 },
    { lat: 48.718334, lng: 21.182855 },
    { lat: 48.718352, lng: 21.182853 },
    { lat: 48.718397, lng: 21.182745 },
    { lat: 48.718414, lng: 21.182706 },
    { lat: 48.718386, lng: 21.182658 },
    { lat: 48.71848, lng: 21.182444 },
    { lat: 48.718533, lng: 21.182508 },
    { lat: 48.718579, lng: 21.182558 },
    { lat: 48.718662, lng: 21.182645 },
    { lat: 48.718807, lng: 21.182804 },
    { lat: 48.7189, lng: 21.182633 },
    { lat: 48.718937, lng: 21.182662 },
    { lat: 48.718987, lng: 21.182702 },
    { lat: 48.719018, lng: 21.182727 },
    { lat: 48.719565, lng: 21.183164 },
    { lat: 48.719576, lng: 21.183169 },
    { lat: 48.719597, lng: 21.183179 },
    { lat: 48.719659, lng: 21.183086 },
    { lat: 48.719696, lng: 21.18302 },
    { lat: 48.719738, lng: 21.182893 },
    { lat: 48.719748, lng: 21.182827 },
    { lat: 48.719796, lng: 21.18272 },
    { lat: 48.719985, lng: 21.18255 },
    { lat: 48.720053, lng: 21.182524 },
    { lat: 48.720174, lng: 21.182503 },
    { lat: 48.720249, lng: 21.182388 },
    { lat: 48.720325, lng: 21.182219 },
    { lat: 48.720325, lng: 21.182192 },
    { lat: 48.720324, lng: 21.182177 },
    { lat: 48.720319, lng: 21.181954 },
    { lat: 48.72032, lng: 21.181951 },
    { lat: 48.720332, lng: 21.181923 },
    { lat: 48.720393, lng: 21.181773 },
    { lat: 48.720445, lng: 21.181613 },
    { lat: 48.720464, lng: 21.18157 },
    { lat: 48.72048, lng: 21.18153 },
    { lat: 48.720582, lng: 21.181288 },
    { lat: 48.720593, lng: 21.181265 },
    { lat: 48.720646, lng: 21.181137 },
    { lat: 48.720795, lng: 21.181035 },
    { lat: 48.720963, lng: 21.180858 },
    { lat: 48.721068, lng: 21.180619 },
    { lat: 48.721127, lng: 21.180528 },
    { lat: 48.721208, lng: 21.180434 },
    { lat: 48.721257, lng: 21.180376 },
    { lat: 48.721408, lng: 21.180308 },
    { lat: 48.721499, lng: 21.180182 },
    { lat: 48.721621, lng: 21.180177 },
    { lat: 48.721663, lng: 21.180179 },
    { lat: 48.721695, lng: 21.180135 },
    { lat: 48.721724, lng: 21.180083 },
    { lat: 48.721763, lng: 21.179981 },
    { lat: 48.721781, lng: 21.179997 },
    { lat: 48.721817, lng: 21.179942 },
    { lat: 48.721847, lng: 21.179973 },
    { lat: 48.721863, lng: 21.179989 },
    { lat: 48.721921, lng: 21.179875 },
    { lat: 48.721902, lng: 21.17986 },
    { lat: 48.721937, lng: 21.179766 },
    { lat: 48.721998, lng: 21.179606 },
    { lat: 48.72204, lng: 21.179527 },
    { lat: 48.722139, lng: 21.179122 },
    { lat: 48.72215, lng: 21.179043 },
    { lat: 48.722132, lng: 21.179016 },
    { lat: 48.722125, lng: 21.179006 },
    { lat: 48.722224, lng: 21.178814 },
    { lat: 48.722354, lng: 21.178568 },
    { lat: 48.722565, lng: 21.178133 },
    { lat: 48.722641, lng: 21.177999 },
    { lat: 48.722799, lng: 21.17778 },
    { lat: 48.72289, lng: 21.177633 },
    { lat: 48.723009, lng: 21.177473 },
    { lat: 48.723046, lng: 21.177421 },
    { lat: 48.723115, lng: 21.177328 },
    { lat: 48.723227, lng: 21.177183 },
    { lat: 48.723442, lng: 21.1769 },
    { lat: 48.723488, lng: 21.176797 },
    { lat: 48.723527, lng: 21.176743 },
    { lat: 48.723646, lng: 21.17649 },
    { lat: 48.723698, lng: 21.176361 },
    { lat: 48.723769, lng: 21.176186 },
    { lat: 48.723889, lng: 21.17589 },
    { lat: 48.723975, lng: 21.175711 },
    { lat: 48.724091, lng: 21.175548 },
    { lat: 48.724146, lng: 21.175472 },
    { lat: 48.724303, lng: 21.174842 },
    { lat: 48.724342, lng: 21.174281 },
    { lat: 48.724326, lng: 21.173694 },
    { lat: 48.724062, lng: 21.172789 },
    { lat: 48.724021, lng: 21.172828 },
    { lat: 48.723995, lng: 21.172852 },
    { lat: 48.723975, lng: 21.172804 },
    { lat: 48.723968, lng: 21.172784 },
    { lat: 48.72396, lng: 21.172767 },
    { lat: 48.723894, lng: 21.172598 },
    { lat: 48.723804, lng: 21.17237 },
    { lat: 48.723798, lng: 21.172357 },
    { lat: 48.723676, lng: 21.171708 },
    { lat: 48.723627, lng: 21.171454 },
    { lat: 48.723519, lng: 21.170882 },
    { lat: 48.72345, lng: 21.169976 },
    { lat: 48.7232672, lng: 21.1699585 },
    { lat: 48.7231511, lng: 21.1697042 },
    { lat: 48.7229323, lng: 21.1694744 },
    { lat: 48.7228954, lng: 21.1695234 },
    { lat: 48.7228082, lng: 21.1693611 },
    { lat: 48.722741, lng: 21.1693321 },
    { lat: 48.722734, lng: 21.1693259 },
    { lat: 48.7226117, lng: 21.1693736 },
    { lat: 48.7226123, lng: 21.1693183 },
    { lat: 48.7224919, lng: 21.1693961 },
    { lat: 48.7223763, lng: 21.1693646 },
    { lat: 48.7221998, lng: 21.1695199 },
    { lat: 48.7219019, lng: 21.1693836 },
    { lat: 48.7218173, lng: 21.169416 },
    { lat: 48.7216972, lng: 21.16928 },
    { lat: 48.7216143, lng: 21.1693284 },
    { lat: 48.7215487, lng: 21.1692138 },
    { lat: 48.7214223, lng: 21.1692368 },
    { lat: 48.72112, lng: 21.168858 },
    { lat: 48.7210612, lng: 21.1689592 },
    { lat: 48.7210187, lng: 21.1689302 },
    { lat: 48.720928, lng: 21.1686937 },
    { lat: 48.7208159, lng: 21.1686024 },
    { lat: 48.7206221, lng: 21.1682746 },
    { lat: 48.7205721, lng: 21.1681203 },
    { lat: 48.7206241, lng: 21.1676696 },
    { lat: 48.72053, lng: 21.1675334 },
    { lat: 48.7205621, lng: 21.1671251 },
    { lat: 48.720451, lng: 21.1667824 },
    { lat: 48.7203265, lng: 21.1665142 },
    { lat: 48.7200822, lng: 21.1663654 },
    { lat: 48.7197913, lng: 21.1658211 },
    { lat: 48.7196833, lng: 21.1657149 },
    { lat: 48.7196632, lng: 21.1654305 },
    { lat: 48.719878, lng: 21.1650072 },
    { lat: 48.7198817, lng: 21.1648024 },
    { lat: 48.7197646, lng: 21.1642341 },
    { lat: 48.7196916, lng: 21.1640206 },
    { lat: 48.719347, lng: 21.1635194 },
    { lat: 48.7189245, lng: 21.1626184 },
    { lat: 48.718796, lng: 21.1622174 },
    { lat: 48.7185126, lng: 21.1621232 },
    { lat: 48.7182588, lng: 21.1622399 },
    { lat: 48.7178103, lng: 21.1622956 },
    { lat: 48.7177203, lng: 21.1621546 },
    { lat: 48.7164545, lng: 21.1623778 },
    { lat: 48.7136513, lng: 21.1617141 },
    { lat: 48.7134638, lng: 21.161728 },
    { lat: 48.7133028, lng: 21.162079 },
    { lat: 48.7114005, lng: 21.1641312 },
    { lat: 48.7110371, lng: 21.1644536 },
    { lat: 48.7097535, lng: 21.1662492 },
    { lat: 48.7091551, lng: 21.167286 },
    { lat: 48.7086328, lng: 21.1679587 },
    { lat: 48.7075993, lng: 21.1687982 },
    { lat: 48.7060481, lng: 21.1698913 },
    { lat: 48.7050048, lng: 21.1707784 },
    { lat: 48.7038132, lng: 21.1716099 },
    { lat: 48.7033163, lng: 21.1718713 },
    { lat: 48.7027551, lng: 21.1724923 },
    { lat: 48.7023027, lng: 21.1728477 },
    { lat: 48.7019489, lng: 21.1730489 },
    { lat: 48.701648, lng: 21.1731754 },
    { lat: 48.7007956, lng: 21.1739467 },
    { lat: 48.7003014, lng: 21.1745111 },
    { lat: 48.7001114, lng: 21.1748426 },
    { lat: 48.6986877, lng: 21.1758342 },
    { lat: 48.6981503, lng: 21.1763543 },
    { lat: 48.697232, lng: 21.178464 },
  ],
  TurnianskaNováVes: [
    { lat: 48.5950986, lng: 20.9097387 },
    { lat: 48.5950382, lng: 20.9096808 },
    { lat: 48.5946726, lng: 20.9091532 },
    { lat: 48.5945821, lng: 20.9088807 },
    { lat: 48.5942829, lng: 20.9083228 },
    { lat: 48.5942963, lng: 20.9081884 },
    { lat: 48.5941864, lng: 20.9080717 },
    { lat: 48.5940137, lng: 20.9076541 },
    { lat: 48.593888, lng: 20.9075358 },
    { lat: 48.5937265, lng: 20.9072204 },
    { lat: 48.5937547, lng: 20.9071553 },
    { lat: 48.5933252, lng: 20.9063802 },
    { lat: 48.5932824, lng: 20.9061122 },
    { lat: 48.5932972, lng: 20.9058547 },
    { lat: 48.5931526, lng: 20.9056397 },
    { lat: 48.5931137, lng: 20.9050933 },
    { lat: 48.5930189, lng: 20.905016 },
    { lat: 48.5930861, lng: 20.9047459 },
    { lat: 48.5928338, lng: 20.9042441 },
    { lat: 48.592744, lng: 20.9042184 },
    { lat: 48.592718, lng: 20.9040163 },
    { lat: 48.5925664, lng: 20.9036283 },
    { lat: 48.5924231, lng: 20.9035569 },
    { lat: 48.5924255, lng: 20.9033917 },
    { lat: 48.5922832, lng: 20.9031485 },
    { lat: 48.5921538, lng: 20.9028175 },
    { lat: 48.5921633, lng: 20.9027364 },
    { lat: 48.5920708, lng: 20.9027283 },
    { lat: 48.5919597, lng: 20.902446 },
    { lat: 48.5918698, lng: 20.9023484 },
    { lat: 48.5915812, lng: 20.9021713 },
    { lat: 48.5923385, lng: 20.8991912 },
    { lat: 48.5867574, lng: 20.8925931 },
    { lat: 48.5864551, lng: 20.8923407 },
    { lat: 48.5863934, lng: 20.8923518 },
    { lat: 48.5849635, lng: 20.8906278 },
    { lat: 48.5840768, lng: 20.8894807 },
    { lat: 48.5827418, lng: 20.8872134 },
    { lat: 48.5814031, lng: 20.8854609 },
    { lat: 48.5811955, lng: 20.8850885 },
    { lat: 48.5799532, lng: 20.8837214 },
    { lat: 48.5786749, lng: 20.8824194 },
    { lat: 48.5781957, lng: 20.8820354 },
    { lat: 48.57794, lng: 20.8817339 },
    { lat: 48.5776906, lng: 20.8816837 },
    { lat: 48.5775462, lng: 20.8815737 },
    { lat: 48.577213, lng: 20.8815747 },
    { lat: 48.5768086, lng: 20.8812706 },
    { lat: 48.5806024, lng: 20.875661 },
    { lat: 48.5805464, lng: 20.8756999 },
    { lat: 48.5802761, lng: 20.8758663 },
    { lat: 48.5798784, lng: 20.8759232 },
    { lat: 48.5797201, lng: 20.8762235 },
    { lat: 48.5795015, lng: 20.8762199 },
    { lat: 48.5794824, lng: 20.8760266 },
    { lat: 48.5793869, lng: 20.8759651 },
    { lat: 48.5791158, lng: 20.8761718 },
    { lat: 48.5786782, lng: 20.8754895 },
    { lat: 48.5786839, lng: 20.8752292 },
    { lat: 48.5787415, lng: 20.8751326 },
    { lat: 48.578852, lng: 20.8751375 },
    { lat: 48.5789369, lng: 20.8750527 },
    { lat: 48.578781, lng: 20.8743812 },
    { lat: 48.5786907, lng: 20.8743414 },
    { lat: 48.5783676, lng: 20.8744035 },
    { lat: 48.5782381, lng: 20.8741448 },
    { lat: 48.5779796, lng: 20.8739933 },
    { lat: 48.577504, lng: 20.8741377 },
    { lat: 48.5774744, lng: 20.8739957 },
    { lat: 48.5775368, lng: 20.8737512 },
    { lat: 48.5773721, lng: 20.873737 },
    { lat: 48.5770042, lng: 20.8738421 },
    { lat: 48.5769496, lng: 20.8733704 },
    { lat: 48.5768624, lng: 20.8732229 },
    { lat: 48.5766862, lng: 20.8732373 },
    { lat: 48.5761591, lng: 20.8729038 },
    { lat: 48.5757596, lng: 20.8729399 },
    { lat: 48.5756172, lng: 20.8728525 },
    { lat: 48.5753818, lng: 20.8730468 },
    { lat: 48.575446, lng: 20.8732483 },
    { lat: 48.5754249, lng: 20.8733879 },
    { lat: 48.5753252, lng: 20.8734056 },
    { lat: 48.5751329, lng: 20.873272 },
    { lat: 48.57504, lng: 20.8734527 },
    { lat: 48.5750758, lng: 20.8737453 },
    { lat: 48.5750004, lng: 20.8738451 },
    { lat: 48.5748754, lng: 20.8738715 },
    { lat: 48.5749079, lng: 20.8740033 },
    { lat: 48.575062, lng: 20.8742295 },
    { lat: 48.5749715, lng: 20.874838 },
    { lat: 48.575017, lng: 20.8750776 },
    { lat: 48.5749754, lng: 20.8753076 },
    { lat: 48.5748256, lng: 20.8754725 },
    { lat: 48.5745072, lng: 20.8754482 },
    { lat: 48.5743104, lng: 20.8755137 },
    { lat: 48.5740448, lng: 20.8753925 },
    { lat: 48.5739523, lng: 20.8752878 },
    { lat: 48.5739803, lng: 20.8749209 },
    { lat: 48.5738886, lng: 20.8748043 },
    { lat: 48.5735105, lng: 20.8747104 },
    { lat: 48.5732873, lng: 20.8745025 },
    { lat: 48.5730388, lng: 20.8743839 },
    { lat: 48.572918, lng: 20.8744026 },
    { lat: 48.5726589, lng: 20.8745998 },
    { lat: 48.5723926, lng: 20.8749369 },
    { lat: 48.572323, lng: 20.8747425 },
    { lat: 48.5717586, lng: 20.8740098 },
    { lat: 48.5716924, lng: 20.8740555 },
    { lat: 48.5706898, lng: 20.8747891 },
    { lat: 48.5705309, lng: 20.8747492 },
    { lat: 48.5704507, lng: 20.8745788 },
    { lat: 48.5704512, lng: 20.8743573 },
    { lat: 48.5706415, lng: 20.8735459 },
    { lat: 48.5697331, lng: 20.8744257 },
    { lat: 48.5699514, lng: 20.8749514 },
    { lat: 48.5698335, lng: 20.8751938 },
    { lat: 48.5695811, lng: 20.8745943 },
    { lat: 48.5682713, lng: 20.8756911 },
    { lat: 48.5675395, lng: 20.876541 },
    { lat: 48.5678292, lng: 20.8770086 },
    { lat: 48.56766, lng: 20.877229 },
    { lat: 48.5679755, lng: 20.8777326 },
    { lat: 48.5672845, lng: 20.8783916 },
    { lat: 48.5673332, lng: 20.878497 },
    { lat: 48.5671698, lng: 20.8786593 },
    { lat: 48.5671996, lng: 20.8787423 },
    { lat: 48.5670804, lng: 20.8788436 },
    { lat: 48.5671665, lng: 20.8789595 },
    { lat: 48.5650379, lng: 20.88067 },
    { lat: 48.5651608, lng: 20.8809022 },
    { lat: 48.564981, lng: 20.8810455 },
    { lat: 48.5650994, lng: 20.8813491 },
    { lat: 48.5635859, lng: 20.8826089 },
    { lat: 48.5636294, lng: 20.8827953 },
    { lat: 48.5634142, lng: 20.8827247 },
    { lat: 48.5628856, lng: 20.8829335 },
    { lat: 48.5615123, lng: 20.8843817 },
    { lat: 48.5608612, lng: 20.8847308 },
    { lat: 48.5587074, lng: 20.8855129 },
    { lat: 48.5573223, lng: 20.8851702 },
    { lat: 48.5563531, lng: 20.8848415 },
    { lat: 48.5552644, lng: 20.8841159 },
    { lat: 48.5542051, lng: 20.8840152 },
    { lat: 48.5530015, lng: 20.8843692 },
    { lat: 48.552563, lng: 20.88438 },
    { lat: 48.552527, lng: 20.884394 },
    { lat: 48.552646, lng: 20.884781 },
    { lat: 48.552931, lng: 20.885981 },
    { lat: 48.553113, lng: 20.88674 },
    { lat: 48.553362, lng: 20.887778 },
    { lat: 48.553559, lng: 20.888588 },
    { lat: 48.553967, lng: 20.890306 },
    { lat: 48.554268, lng: 20.89155 },
    { lat: 48.554609, lng: 20.892992 },
    { lat: 48.554909, lng: 20.894261 },
    { lat: 48.554994, lng: 20.894606 },
    { lat: 48.55514, lng: 20.895203 },
    { lat: 48.555221, lng: 20.89556 },
    { lat: 48.555371, lng: 20.896164 },
    { lat: 48.555824, lng: 20.898092 },
    { lat: 48.55617, lng: 20.89956 },
    { lat: 48.556261, lng: 20.899938 },
    { lat: 48.556619, lng: 20.901395 },
    { lat: 48.55683, lng: 20.90227 },
    { lat: 48.557009, lng: 20.902979 },
    { lat: 48.557289, lng: 20.904153 },
    { lat: 48.557555, lng: 20.905267 },
    { lat: 48.557615, lng: 20.905513 },
    { lat: 48.557908, lng: 20.906755 },
    { lat: 48.558367, lng: 20.908696 },
    { lat: 48.558989, lng: 20.911292 },
    { lat: 48.5598494, lng: 20.9147517 },
    { lat: 48.560069, lng: 20.915838 },
    { lat: 48.5600383, lng: 20.915625 },
    { lat: 48.5601609, lng: 20.9150608 },
    { lat: 48.5598874, lng: 20.9148637 },
    { lat: 48.5599072, lng: 20.9144539 },
    { lat: 48.5601082, lng: 20.9143076 },
    { lat: 48.5602382, lng: 20.9143861 },
    { lat: 48.5603788, lng: 20.914253 },
    { lat: 48.560562, lng: 20.9136124 },
    { lat: 48.5604848, lng: 20.9136304 },
    { lat: 48.5604794, lng: 20.9134041 },
    { lat: 48.5605965, lng: 20.913109 },
    { lat: 48.5605521, lng: 20.9129959 },
    { lat: 48.5605648, lng: 20.9127721 },
    { lat: 48.5606545, lng: 20.9127257 },
    { lat: 48.5607604, lng: 20.9124617 },
    { lat: 48.5607028, lng: 20.9124604 },
    { lat: 48.5606789, lng: 20.9123525 },
    { lat: 48.560787, lng: 20.9121544 },
    { lat: 48.5607438, lng: 20.9120085 },
    { lat: 48.5608357, lng: 20.9117977 },
    { lat: 48.5609006, lng: 20.9113272 },
    { lat: 48.5609094, lng: 20.9110849 },
    { lat: 48.5608408, lng: 20.9109534 },
    { lat: 48.5609116, lng: 20.9106235 },
    { lat: 48.5609503, lng: 20.9101024 },
    { lat: 48.5610003, lng: 20.9100196 },
    { lat: 48.5610952, lng: 20.910029 },
    { lat: 48.5616152, lng: 20.9097621 },
    { lat: 48.5616973, lng: 20.9094288 },
    { lat: 48.5619568, lng: 20.9094088 },
    { lat: 48.5621187, lng: 20.9091415 },
    { lat: 48.5620149, lng: 20.9087732 },
    { lat: 48.5621043, lng: 20.9084543 },
    { lat: 48.5621882, lng: 20.9074481 },
    { lat: 48.5623385, lng: 20.9069699 },
    { lat: 48.5621186, lng: 20.9068241 },
    { lat: 48.5620277, lng: 20.9066695 },
    { lat: 48.5621167, lng: 20.9061704 },
    { lat: 48.5622551, lng: 20.9061363 },
    { lat: 48.5623628, lng: 20.9059598 },
    { lat: 48.5623706, lng: 20.9057314 },
    { lat: 48.5639053, lng: 20.9060372 },
    { lat: 48.5639653, lng: 20.9062106 },
    { lat: 48.5648794, lng: 20.9060465 },
    { lat: 48.5656758, lng: 20.9057024 },
    { lat: 48.5689142, lng: 20.9040084 },
    { lat: 48.5692106, lng: 20.9042499 },
    { lat: 48.5697873, lng: 20.9036047 },
    { lat: 48.5712061, lng: 20.9022591 },
    { lat: 48.5713751, lng: 20.9026559 },
    { lat: 48.5719676, lng: 20.9022041 },
    { lat: 48.5720321, lng: 20.9023811 },
    { lat: 48.5723439, lng: 20.9021519 },
    { lat: 48.5722726, lng: 20.9019406 },
    { lat: 48.5741668, lng: 20.9005443 },
    { lat: 48.5742134, lng: 20.9007219 },
    { lat: 48.5743729, lng: 20.9006122 },
    { lat: 48.5745175, lng: 20.9010908 },
    { lat: 48.5749343, lng: 20.9008684 },
    { lat: 48.5754305, lng: 20.9006988 },
    { lat: 48.5770738, lng: 20.9003623 },
    { lat: 48.5772191, lng: 20.9010556 },
    { lat: 48.5774574, lng: 20.9009817 },
    { lat: 48.5778489, lng: 20.9022297 },
    { lat: 48.5781945, lng: 20.9020658 },
    { lat: 48.5783995, lng: 20.9026631 },
    { lat: 48.5788683, lng: 20.9024143 },
    { lat: 48.5790453, lng: 20.9038105 },
    { lat: 48.5797065, lng: 20.9034568 },
    { lat: 48.5800279, lng: 20.9044373 },
    { lat: 48.5806373, lng: 20.903949 },
    { lat: 48.58071, lng: 20.9041705 },
    { lat: 48.5818094, lng: 20.903366 },
    { lat: 48.5817198, lng: 20.9030771 },
    { lat: 48.583049, lng: 20.9019493 },
    { lat: 48.5829451, lng: 20.9016737 },
    { lat: 48.5834671, lng: 20.9012724 },
    { lat: 48.583461, lng: 20.9010951 },
    { lat: 48.5839295, lng: 20.9006369 },
    { lat: 48.5845734, lng: 20.9004359 },
    { lat: 48.5845055, lng: 20.9007889 },
    { lat: 48.5846495, lng: 20.9008201 },
    { lat: 48.5846311, lng: 20.9009107 },
    { lat: 48.5849665, lng: 20.9010398 },
    { lat: 48.5849488, lng: 20.9011442 },
    { lat: 48.5851148, lng: 20.901259 },
    { lat: 48.5850798, lng: 20.9014246 },
    { lat: 48.5854125, lng: 20.9016758 },
    { lat: 48.5853136, lng: 20.9022061 },
    { lat: 48.5856165, lng: 20.9023739 },
    { lat: 48.5856578, lng: 20.9022022 },
    { lat: 48.5866723, lng: 20.9026723 },
    { lat: 48.5866454, lng: 20.9028383 },
    { lat: 48.5874006, lng: 20.9029655 },
    { lat: 48.587451, lng: 20.9028588 },
    { lat: 48.5879775, lng: 20.9031153 },
    { lat: 48.5882317, lng: 20.9024838 },
    { lat: 48.5888875, lng: 20.9031648 },
    { lat: 48.5892506, lng: 20.9037066 },
    { lat: 48.5896814, lng: 20.904857 },
    { lat: 48.5908715, lng: 20.9050622 },
    { lat: 48.5913507, lng: 20.9054177 },
    { lat: 48.5917753, lng: 20.9063187 },
    { lat: 48.5924043, lng: 20.9066093 },
    { lat: 48.5925762, lng: 20.9067645 },
    { lat: 48.5929377, lng: 20.9073311 },
    { lat: 48.593013, lng: 20.9072286 },
    { lat: 48.593267, lng: 20.9073789 },
    { lat: 48.5933648, lng: 20.9075428 },
    { lat: 48.5933153, lng: 20.9079754 },
    { lat: 48.5933567, lng: 20.9081987 },
    { lat: 48.5933446, lng: 20.9087032 },
    { lat: 48.5933975, lng: 20.9088648 },
    { lat: 48.5934679, lng: 20.9089515 },
    { lat: 48.5937292, lng: 20.9088257 },
    { lat: 48.5938737, lng: 20.9091349 },
    { lat: 48.5938797, lng: 20.909277 },
    { lat: 48.5936424, lng: 20.9098444 },
    { lat: 48.5936108, lng: 20.9102053 },
    { lat: 48.5936545, lng: 20.9102719 },
    { lat: 48.5937784, lng: 20.9103075 },
    { lat: 48.5942608, lng: 20.9101554 },
    { lat: 48.5950986, lng: 20.9097387 },
  ],
  PerínChym: [
    { lat: 48.5464284, lng: 21.0959126 },
    { lat: 48.5464681, lng: 21.0960976 },
    { lat: 48.5465168, lng: 21.0964866 },
    { lat: 48.5471164, lng: 21.0980765 },
    { lat: 48.5485465, lng: 21.0987289 },
    { lat: 48.5496507, lng: 21.0999067 },
    { lat: 48.5499837, lng: 21.1003921 },
    { lat: 48.5504862, lng: 21.1012788 },
    { lat: 48.5508405, lng: 21.1019658 },
    { lat: 48.5512741, lng: 21.1030204 },
    { lat: 48.5513604, lng: 21.1050255 },
    { lat: 48.551254, lng: 21.108491 },
    { lat: 48.5512906, lng: 21.1111623 },
    { lat: 48.5512593, lng: 21.1129469 },
    { lat: 48.5509153, lng: 21.1168728 },
    { lat: 48.5504509, lng: 21.1203437 },
    { lat: 48.5504221, lng: 21.1204975 },
    { lat: 48.55032, lng: 21.120543 },
    { lat: 48.54966, lng: 21.1205333 },
    { lat: 48.548433, lng: 21.1203177 },
    { lat: 48.5473009, lng: 21.1202519 },
    { lat: 48.5431212, lng: 21.1194641 },
    { lat: 48.5352943, lng: 21.1179978 },
    { lat: 48.528652, lng: 21.1172828 },
    { lat: 48.5269515, lng: 21.1178093 },
    { lat: 48.5253535, lng: 21.1180275 },
    { lat: 48.5229264, lng: 21.1176637 },
    { lat: 48.5198982, lng: 21.1165655 },
    { lat: 48.5191385, lng: 21.1161833 },
    { lat: 48.5176615, lng: 21.1159706 },
    { lat: 48.5155915, lng: 21.1153664 },
    { lat: 48.5145297, lng: 21.1149339 },
    { lat: 48.5140405, lng: 21.1148113 },
    { lat: 48.5140235, lng: 21.1148107 },
    { lat: 48.5140227, lng: 21.1148495 },
    { lat: 48.5140774, lng: 21.1149729 },
    { lat: 48.5140259, lng: 21.1151982 },
    { lat: 48.5140166, lng: 21.1159927 },
    { lat: 48.5139293, lng: 21.1167726 },
    { lat: 48.5138002, lng: 21.1174061 },
    { lat: 48.5136007, lng: 21.1177888 },
    { lat: 48.5135374, lng: 21.1182971 },
    { lat: 48.5132413, lng: 21.1190706 },
    { lat: 48.5132529, lng: 21.1192561 },
    { lat: 48.5131824, lng: 21.1194015 },
    { lat: 48.5130049, lng: 21.1194858 },
    { lat: 48.5127668, lng: 21.1200229 },
    { lat: 48.5125545, lng: 21.1202127 },
    { lat: 48.5125405, lng: 21.1203074 },
    { lat: 48.5124342, lng: 21.1204259 },
    { lat: 48.5124937, lng: 21.1212245 },
    { lat: 48.5126586, lng: 21.1214897 },
    { lat: 48.5127556, lng: 21.1233179 },
    { lat: 48.5091569, lng: 21.1273929 },
    { lat: 48.5086141, lng: 21.1279489 },
    { lat: 48.5081944, lng: 21.1281924 },
    { lat: 48.5084587, lng: 21.128926 },
    { lat: 48.5081076, lng: 21.129802 },
    { lat: 48.5049227, lng: 21.1347916 },
    { lat: 48.502963, lng: 21.138418 },
    { lat: 48.503783, lng: 21.139055 },
    { lat: 48.504578, lng: 21.139903 },
    { lat: 48.505065, lng: 21.14017 },
    { lat: 48.505107, lng: 21.140194 },
    { lat: 48.505994, lng: 21.140704 },
    { lat: 48.506071, lng: 21.140746 },
    { lat: 48.5062542, lng: 21.14089 },
    { lat: 48.506848, lng: 21.141357 },
    { lat: 48.507475, lng: 21.141659 },
    { lat: 48.508037, lng: 21.14213 },
    { lat: 48.508495, lng: 21.142697 },
    { lat: 48.508509, lng: 21.142679 },
    { lat: 48.508887, lng: 21.143725 },
    { lat: 48.5092311, lng: 21.1446887 },
    { lat: 48.509238, lng: 21.144708 },
    { lat: 48.509509, lng: 21.145192 },
    { lat: 48.509756, lng: 21.145556 },
    { lat: 48.510098, lng: 21.145915 },
    { lat: 48.510391, lng: 21.146244 },
    { lat: 48.510706, lng: 21.14651 },
    { lat: 48.511115, lng: 21.146948 },
    { lat: 48.511714, lng: 21.147747 },
    { lat: 48.511823, lng: 21.147842 },
    { lat: 48.512041, lng: 21.148065 },
    { lat: 48.512296, lng: 21.148222 },
    { lat: 48.512638, lng: 21.148218 },
    { lat: 48.512993, lng: 21.148299 },
    { lat: 48.51332, lng: 21.14843 },
    { lat: 48.513531, lng: 21.14848 },
    { lat: 48.51353, lng: 21.148511 },
    { lat: 48.513679, lng: 21.149248 },
    { lat: 48.513786, lng: 21.149767 },
    { lat: 48.513915, lng: 21.150393 },
    { lat: 48.515381, lng: 21.150321 },
    { lat: 48.51548, lng: 21.150548 },
    { lat: 48.515734, lng: 21.151126 },
    { lat: 48.515779, lng: 21.15123 },
    { lat: 48.516189, lng: 21.151695 },
    { lat: 48.516613, lng: 21.152175 },
    { lat: 48.516625, lng: 21.152189 },
    { lat: 48.516908, lng: 21.152784 },
    { lat: 48.516997, lng: 21.153888 },
    { lat: 48.516554, lng: 21.155537 },
    { lat: 48.516485, lng: 21.155794 },
    { lat: 48.517223, lng: 21.157401 },
    { lat: 48.517739, lng: 21.158951 },
    { lat: 48.517947, lng: 21.159477 },
    { lat: 48.518038, lng: 21.159495 },
    { lat: 48.518351, lng: 21.159527 },
    { lat: 48.518613, lng: 21.159564 },
    { lat: 48.518882, lng: 21.159635 },
    { lat: 48.519029, lng: 21.159698 },
    { lat: 48.519262, lng: 21.159717 },
    { lat: 48.519604, lng: 21.159748 },
    { lat: 48.519597, lng: 21.159777 },
    { lat: 48.519717, lng: 21.159847 },
    { lat: 48.520146, lng: 21.160165 },
    { lat: 48.520383, lng: 21.160332 },
    { lat: 48.5205, lng: 21.16039 },
    { lat: 48.521085, lng: 21.160504 },
    { lat: 48.521217, lng: 21.160577 },
    { lat: 48.521331, lng: 21.160669 },
    { lat: 48.521415, lng: 21.160758 },
    { lat: 48.521499, lng: 21.160875 },
    { lat: 48.521581, lng: 21.161094 },
    { lat: 48.521624, lng: 21.161293 },
    { lat: 48.521651, lng: 21.161522 },
    { lat: 48.52167, lng: 21.161817 },
    { lat: 48.521678, lng: 21.162001 },
    { lat: 48.521653, lng: 21.163056 },
    { lat: 48.521556, lng: 21.16403 },
    { lat: 48.521482, lng: 21.164459 },
    { lat: 48.521445, lng: 21.164714 },
    { lat: 48.521391, lng: 21.1649 },
    { lat: 48.521304, lng: 21.165385 },
    { lat: 48.521215, lng: 21.165894 },
    { lat: 48.5211, lng: 21.1669 },
    { lat: 48.521031, lng: 21.16725 },
    { lat: 48.5210766, lng: 21.1674527 },
    { lat: 48.521116, lng: 21.168207 },
    { lat: 48.521167, lng: 21.16839 },
    { lat: 48.521367, lng: 21.168762 },
    { lat: 48.521523, lng: 21.168995 },
    { lat: 48.521826, lng: 21.16939 },
    { lat: 48.521859, lng: 21.169568 },
    { lat: 48.521878, lng: 21.169701 },
    { lat: 48.521918, lng: 21.169881 },
    { lat: 48.52194, lng: 21.169975 },
    { lat: 48.522002, lng: 21.170391 },
    { lat: 48.522008, lng: 21.170515 },
    { lat: 48.522015, lng: 21.170711 },
    { lat: 48.522001, lng: 21.170956 },
    { lat: 48.521999, lng: 21.171 },
    { lat: 48.522037, lng: 21.17153 },
    { lat: 48.522041, lng: 21.171807 },
    { lat: 48.522031, lng: 21.17206 },
    { lat: 48.521976, lng: 21.17258 },
    { lat: 48.521938, lng: 21.173449 },
    { lat: 48.521907, lng: 21.17358 },
    { lat: 48.521827, lng: 21.17377 },
    { lat: 48.521816, lng: 21.173784 },
    { lat: 48.521802, lng: 21.173802 },
    { lat: 48.521724, lng: 21.173992 },
    { lat: 48.521631, lng: 21.174189 },
    { lat: 48.52154, lng: 21.174342 },
    { lat: 48.521438, lng: 21.174485 },
    { lat: 48.521354, lng: 21.174579 },
    { lat: 48.521171, lng: 21.174741 },
    { lat: 48.52104, lng: 21.174832 },
    { lat: 48.52062, lng: 21.175124 },
    { lat: 48.520062, lng: 21.175813 },
    { lat: 48.5197016, lng: 21.1763747 },
    { lat: 48.519498, lng: 21.176692 },
    { lat: 48.51949, lng: 21.176705 },
    { lat: 48.519481, lng: 21.176722 },
    { lat: 48.518278, lng: 21.178829 },
    { lat: 48.518232, lng: 21.179634 },
    { lat: 48.518454, lng: 21.180144 },
    { lat: 48.518485, lng: 21.180214 },
    { lat: 48.519643, lng: 21.181609 },
    { lat: 48.519929, lng: 21.181951 },
    { lat: 48.52003, lng: 21.183025 },
    { lat: 48.520149, lng: 21.184263 },
    { lat: 48.520132, lng: 21.184289 },
    { lat: 48.520266, lng: 21.184599 },
    { lat: 48.520428, lng: 21.185142 },
    { lat: 48.52054, lng: 21.18536 },
    { lat: 48.520628, lng: 21.185448 },
    { lat: 48.520752, lng: 21.185516 },
    { lat: 48.520941, lng: 21.18554 },
    { lat: 48.521085, lng: 21.185598 },
    { lat: 48.521169, lng: 21.185639 },
    { lat: 48.52145, lng: 21.185741 },
    { lat: 48.521768, lng: 21.185835 },
    { lat: 48.5218819, lng: 21.185915 },
    { lat: 48.52207, lng: 21.186047 },
    { lat: 48.522319, lng: 21.186298 },
    { lat: 48.52268, lng: 21.186964 },
    { lat: 48.52281, lng: 21.187183 },
    { lat: 48.523009, lng: 21.187359 },
    { lat: 48.5230745, lng: 21.1874077 },
    { lat: 48.523258, lng: 21.187544 },
    { lat: 48.523602, lng: 21.187741 },
    { lat: 48.523825, lng: 21.187823 },
    { lat: 48.524321, lng: 21.188034 },
    { lat: 48.524827, lng: 21.188016 },
    { lat: 48.524793, lng: 21.188075 },
    { lat: 48.524766, lng: 21.188119 },
    { lat: 48.524591, lng: 21.188303 },
    { lat: 48.524321, lng: 21.188654 },
    { lat: 48.524099, lng: 21.189075 },
    { lat: 48.523892, lng: 21.189369 },
    { lat: 48.523736, lng: 21.189587 },
    { lat: 48.523472, lng: 21.189824 },
    { lat: 48.52327, lng: 21.190043 },
    { lat: 48.523113, lng: 21.190328 },
    { lat: 48.523006, lng: 21.190489 },
    { lat: 48.52285, lng: 21.190836 },
    { lat: 48.522648, lng: 21.191231 },
    { lat: 48.522667, lng: 21.191255 },
    { lat: 48.523082, lng: 21.191385 },
    { lat: 48.524061, lng: 21.191688 },
    { lat: 48.525284, lng: 21.192081 },
    { lat: 48.526278, lng: 21.192404 },
    { lat: 48.526529, lng: 21.192485 },
    { lat: 48.526537, lng: 21.192502 },
    { lat: 48.5265767, lng: 21.1925913 },
    { lat: 48.526433, lng: 21.192742 },
    { lat: 48.52578, lng: 21.193466 },
    { lat: 48.525451, lng: 21.193899 },
    { lat: 48.525258, lng: 21.194177 },
    { lat: 48.525104, lng: 21.194433 },
    { lat: 48.524993, lng: 21.194678 },
    { lat: 48.524872, lng: 21.194978 },
    { lat: 48.524651, lng: 21.195534 },
    { lat: 48.524709, lng: 21.195678 },
    { lat: 48.52491, lng: 21.196549 },
    { lat: 48.524969, lng: 21.197075 },
    { lat: 48.524752, lng: 21.197746 },
    { lat: 48.524578, lng: 21.198311 },
    { lat: 48.524493, lng: 21.198638 },
    { lat: 48.524459, lng: 21.19882 },
    { lat: 48.524455, lng: 21.1989 },
    { lat: 48.524455, lng: 21.199046 },
    { lat: 48.524481, lng: 21.199182 },
    { lat: 48.524522, lng: 21.199336 },
    { lat: 48.524636, lng: 21.199642 },
    { lat: 48.52475, lng: 21.19989 },
    { lat: 48.524728, lng: 21.199913 },
    { lat: 48.52501, lng: 21.200537 },
    { lat: 48.525522, lng: 21.201673 },
    { lat: 48.526397, lng: 21.203587 },
    { lat: 48.527294, lng: 21.205566 },
    { lat: 48.527335, lng: 21.20714 },
    { lat: 48.527372, lng: 21.209071 },
    { lat: 48.527372, lng: 21.209127 },
    { lat: 48.527554, lng: 21.209133 },
    { lat: 48.527771, lng: 21.209089 },
    { lat: 48.528018, lng: 21.209019 },
    { lat: 48.528124, lng: 21.208986 },
    { lat: 48.528356, lng: 21.208944 },
    { lat: 48.528661, lng: 21.208979 },
    { lat: 48.529013, lng: 21.209101 },
    { lat: 48.529106, lng: 21.209101 },
    { lat: 48.529503, lng: 21.209047 },
    { lat: 48.529633, lng: 21.209013 },
    { lat: 48.53013, lng: 21.208755 },
    { lat: 48.530244, lng: 21.208707 },
    { lat: 48.530273, lng: 21.208702 },
    { lat: 48.530308, lng: 21.208695 },
    { lat: 48.530343, lng: 21.208687 },
    { lat: 48.53076, lng: 21.208609 },
    { lat: 48.531247, lng: 21.208584 },
    { lat: 48.532257, lng: 21.209083 },
    { lat: 48.532326, lng: 21.209103 },
    { lat: 48.532543, lng: 21.209107 },
    { lat: 48.532764, lng: 21.209146 },
    { lat: 48.532899, lng: 21.209153 },
    { lat: 48.533114, lng: 21.209158 },
    { lat: 48.533293, lng: 21.20918 },
    { lat: 48.533512, lng: 21.209267 },
    { lat: 48.533373, lng: 21.209765 },
    { lat: 48.532826, lng: 21.211772 },
    { lat: 48.532503, lng: 21.212944 },
    { lat: 48.532175, lng: 21.214154 },
    { lat: 48.531525, lng: 21.216554 },
    { lat: 48.531868, lng: 21.21675 },
    { lat: 48.532708, lng: 21.216792 },
    { lat: 48.53313, lng: 21.217403 },
    { lat: 48.533522, lng: 21.217602 },
    { lat: 48.5338, lng: 21.217748 },
    { lat: 48.53405, lng: 21.217885 },
    { lat: 48.535065, lng: 21.218299 },
    { lat: 48.535147, lng: 21.218337 },
    { lat: 48.535216, lng: 21.218383 },
    { lat: 48.535336, lng: 21.218507 },
    { lat: 48.535406, lng: 21.218604 },
    { lat: 48.535488, lng: 21.218763 },
    { lat: 48.535644, lng: 21.219098 },
    { lat: 48.535727, lng: 21.219236 },
    { lat: 48.536358, lng: 21.219821 },
    { lat: 48.537497, lng: 21.221061 },
    { lat: 48.537115, lng: 21.222496 },
    { lat: 48.53674, lng: 21.223904 },
    { lat: 48.536153, lng: 21.226035 },
    { lat: 48.53615, lng: 21.226044 },
    { lat: 48.535983, lng: 21.226657 },
    { lat: 48.535754, lng: 21.227488 },
    { lat: 48.535493, lng: 21.228443 },
    { lat: 48.535271, lng: 21.229251 },
    { lat: 48.535096, lng: 21.229893 },
    { lat: 48.53498, lng: 21.230312 },
    { lat: 48.534836, lng: 21.23084 },
    { lat: 48.534583, lng: 21.23176 },
    { lat: 48.534309, lng: 21.232753 },
    { lat: 48.534176, lng: 21.233238 },
    { lat: 48.534157, lng: 21.233312 },
    { lat: 48.53448, lng: 21.233431 },
    { lat: 48.53503, lng: 21.233632 },
    { lat: 48.535476, lng: 21.233796 },
    { lat: 48.535669, lng: 21.233783 },
    { lat: 48.535779, lng: 21.233719 },
    { lat: 48.535966, lng: 21.233576 },
    { lat: 48.536161, lng: 21.23341 },
    { lat: 48.536317, lng: 21.233232 },
    { lat: 48.536446, lng: 21.23311 },
    { lat: 48.53657, lng: 21.233011 },
    { lat: 48.536702, lng: 21.232945 },
    { lat: 48.536953, lng: 21.232861 },
    { lat: 48.537633, lng: 21.232874 },
    { lat: 48.537765, lng: 21.232877 },
    { lat: 48.538253, lng: 21.232896 },
    { lat: 48.538893, lng: 21.232919 },
    { lat: 48.539064, lng: 21.232925 },
    { lat: 48.539064, lng: 21.232959 },
    { lat: 48.539065, lng: 21.232977 },
    { lat: 48.539098, lng: 21.233253 },
    { lat: 48.539123, lng: 21.23346 },
    { lat: 48.53917, lng: 21.233909 },
    { lat: 48.539206, lng: 21.234259 },
    { lat: 48.53924, lng: 21.234627 },
    { lat: 48.5392868, lng: 21.2351292 },
    { lat: 48.539339, lng: 21.235727 },
    { lat: 48.539311, lng: 21.235938 },
    { lat: 48.53942, lng: 21.235919 },
    { lat: 48.539448, lng: 21.236261 },
    { lat: 48.53949, lng: 21.236753 },
    { lat: 48.539526, lng: 21.237205 },
    { lat: 48.539551, lng: 21.237544 },
    { lat: 48.539587, lng: 21.238046 },
    { lat: 48.539641, lng: 21.238808 },
    { lat: 48.539644, lng: 21.238838 },
    { lat: 48.5428183, lng: 21.238737 },
    { lat: 48.5429337, lng: 21.2386511 },
    { lat: 48.5425888, lng: 21.2379822 },
    { lat: 48.5435569, lng: 21.2378456 },
    { lat: 48.5435485, lng: 21.2374991 },
    { lat: 48.5435421, lng: 21.2339752 },
    { lat: 48.5434089, lng: 21.2332437 },
    { lat: 48.5434429, lng: 21.2325654 },
    { lat: 48.5437396, lng: 21.2319002 },
    { lat: 48.5439205, lng: 21.2312047 },
    { lat: 48.5440855, lng: 21.2291401 },
    { lat: 48.5443399, lng: 21.2278782 },
    { lat: 48.5443674, lng: 21.2268501 },
    { lat: 48.5445359, lng: 21.2257602 },
    { lat: 48.5448178, lng: 21.2245557 },
    { lat: 48.545913, lng: 21.2206728 },
    { lat: 48.5466984, lng: 21.2176195 },
    { lat: 48.5469331, lng: 21.2165986 },
    { lat: 48.5470669, lng: 21.2156599 },
    { lat: 48.5485291, lng: 21.2157345 },
    { lat: 48.5488731, lng: 21.2156575 },
    { lat: 48.5501914, lng: 21.2146632 },
    { lat: 48.5508572, lng: 21.21404 },
    { lat: 48.5513481, lng: 21.2138637 },
    { lat: 48.5524893, lng: 21.2140062 },
    { lat: 48.5534924, lng: 21.2140137 },
    { lat: 48.5536334, lng: 21.2139927 },
    { lat: 48.5544323, lng: 21.2135223 },
    { lat: 48.5545066, lng: 21.2128015 },
    { lat: 48.5548077, lng: 21.2108151 },
    { lat: 48.5549148, lng: 21.2097794 },
    { lat: 48.5556198, lng: 21.2098631 },
    { lat: 48.5574535, lng: 21.2093764 },
    { lat: 48.5595847, lng: 21.2070324 },
    { lat: 48.5696078, lng: 21.1963988 },
    { lat: 48.5698358, lng: 21.1967801 },
    { lat: 48.5700345, lng: 21.1969393 },
    { lat: 48.5703276, lng: 21.1968124 },
    { lat: 48.5704167, lng: 21.1967123 },
    { lat: 48.57051, lng: 21.1965968 },
    { lat: 48.5709224, lng: 21.1956121 },
    { lat: 48.570766, lng: 21.1954417 },
    { lat: 48.5714316, lng: 21.1930553 },
    { lat: 48.5713705, lng: 21.1928678 },
    { lat: 48.5713964, lng: 21.1921552 },
    { lat: 48.5715278, lng: 21.1912239 },
    { lat: 48.5717877, lng: 21.1899657 },
    { lat: 48.5717944, lng: 21.1898956 },
    { lat: 48.5715923, lng: 21.1898425 },
    { lat: 48.571822, lng: 21.1884159 },
    { lat: 48.5719626, lng: 21.1882378 },
    { lat: 48.5723092, lng: 21.1873102 },
    { lat: 48.5724451, lng: 21.1864451 },
    { lat: 48.5726986, lng: 21.1858831 },
    { lat: 48.5730253, lng: 21.1843933 },
    { lat: 48.5734492, lng: 21.1829275 },
    { lat: 48.5738009, lng: 21.1814149 },
    { lat: 48.5739998, lng: 21.1807992 },
    { lat: 48.5744354, lng: 21.1786675 },
    { lat: 48.5745663, lng: 21.1776747 },
    { lat: 48.5747994, lng: 21.1766124 },
    { lat: 48.575101, lng: 21.1738441 },
    { lat: 48.5751034, lng: 21.1732806 },
    { lat: 48.5751972, lng: 21.1730844 },
    { lat: 48.5763184, lng: 21.165638 },
    { lat: 48.5770823, lng: 21.159117 },
    { lat: 48.5707851, lng: 21.1579136 },
    { lat: 48.5703112, lng: 21.1567787 },
    { lat: 48.5702385, lng: 21.1491359 },
    { lat: 48.5630088, lng: 21.1404213 },
    { lat: 48.5627692, lng: 21.1397881 },
    { lat: 48.5614605, lng: 21.1331555 },
    { lat: 48.5609611, lng: 21.1308205 },
    { lat: 48.5605577, lng: 21.1291863 },
    { lat: 48.5574546, lng: 21.1186889 },
    { lat: 48.5563726, lng: 21.1154265 },
    { lat: 48.5559593, lng: 21.1139545 },
    { lat: 48.5559317, lng: 21.113376 },
    { lat: 48.5557196, lng: 21.1123864 },
    { lat: 48.5543507, lng: 21.1079142 },
    { lat: 48.5541832, lng: 21.1074302 },
    { lat: 48.5538503, lng: 21.1067035 },
    { lat: 48.5527847, lng: 21.1049726 },
    { lat: 48.5533496, lng: 21.1020407 },
    { lat: 48.5534273, lng: 21.1014157 },
    { lat: 48.5535369, lng: 21.1011896 },
    { lat: 48.5534567, lng: 21.1012459 },
    { lat: 48.553274, lng: 21.1013744 },
    { lat: 48.553126, lng: 21.1013632 },
    { lat: 48.5529956, lng: 21.1011884 },
    { lat: 48.5528221, lng: 21.1006668 },
    { lat: 48.5526568, lng: 21.1005284 },
    { lat: 48.5525742, lng: 21.1005493 },
    { lat: 48.5523527, lng: 21.1004533 },
    { lat: 48.5520566, lng: 21.100101 },
    { lat: 48.5519144, lng: 21.1002606 },
    { lat: 48.5517623, lng: 21.1001535 },
    { lat: 48.5514785, lng: 21.0994869 },
    { lat: 48.5516254, lng: 21.0989848 },
    { lat: 48.5515331, lng: 21.0989156 },
    { lat: 48.5509768, lng: 21.0990122 },
    { lat: 48.5508847, lng: 21.0989511 },
    { lat: 48.5506321, lng: 21.0979959 },
    { lat: 48.5506183, lng: 21.0976377 },
    { lat: 48.5505739, lng: 21.097554 },
    { lat: 48.5502634, lng: 21.0978727 },
    { lat: 48.5501703, lng: 21.0971538 },
    { lat: 48.550076, lng: 21.0970222 },
    { lat: 48.5499312, lng: 21.0974154 },
    { lat: 48.5498169, lng: 21.0975364 },
    { lat: 48.5497031, lng: 21.0974606 },
    { lat: 48.5497334, lng: 21.0970054 },
    { lat: 48.5496654, lng: 21.0968207 },
    { lat: 48.5497183, lng: 21.0966459 },
    { lat: 48.5499478, lng: 21.0965396 },
    { lat: 48.5500268, lng: 21.0964076 },
    { lat: 48.5500255, lng: 21.0962948 },
    { lat: 48.5496624, lng: 21.0957889 },
    { lat: 48.5495986, lng: 21.0956252 },
    { lat: 48.5493588, lng: 21.0957285 },
    { lat: 48.5493081, lng: 21.0956196 },
    { lat: 48.5493319, lng: 21.0952459 },
    { lat: 48.549238, lng: 21.095196 },
    { lat: 48.5491094, lng: 21.0952516 },
    { lat: 48.5487841, lng: 21.0955995 },
    { lat: 48.5486629, lng: 21.0955423 },
    { lat: 48.5486617, lng: 21.0951922 },
    { lat: 48.5488866, lng: 21.0948392 },
    { lat: 48.548881, lng: 21.0947028 },
    { lat: 48.5487411, lng: 21.0945856 },
    { lat: 48.5486584, lng: 21.0943991 },
    { lat: 48.5482295, lng: 21.094009 },
    { lat: 48.5483022, lng: 21.0937582 },
    { lat: 48.548651, lng: 21.0935453 },
    { lat: 48.5486788, lng: 21.0933927 },
    { lat: 48.5486371, lng: 21.0931826 },
    { lat: 48.5481389, lng: 21.0928803 },
    { lat: 48.5479688, lng: 21.0921536 },
    { lat: 48.5480197, lng: 21.0916412 },
    { lat: 48.5479559, lng: 21.0913396 },
    { lat: 48.54764, lng: 21.0909661 },
    { lat: 48.5474906, lng: 21.090943 },
    { lat: 48.5473505, lng: 21.090856 },
    { lat: 48.547214, lng: 21.0906738 },
    { lat: 48.5469271, lng: 21.0922732 },
    { lat: 48.5464284, lng: 21.0959126 },
  ],
  NižnáHutka: [
    { lat: 48.6737857, lng: 21.3739597 },
    { lat: 48.6735945, lng: 21.373401 },
    { lat: 48.6735881, lng: 21.3728103 },
    { lat: 48.6732206, lng: 21.3722216 },
    { lat: 48.6730845, lng: 21.3714418 },
    { lat: 48.6732085, lng: 21.3710307 },
    { lat: 48.673118, lng: 21.3703401 },
    { lat: 48.6729075, lng: 21.3694757 },
    { lat: 48.672776, lng: 21.3691012 },
    { lat: 48.6725261, lng: 21.3686469 },
    { lat: 48.6723758, lng: 21.36806 },
    { lat: 48.6718402, lng: 21.3665229 },
    { lat: 48.6715979, lng: 21.3660631 },
    { lat: 48.6715281, lng: 21.3653472 },
    { lat: 48.6712883, lng: 21.3648619 },
    { lat: 48.6712533, lng: 21.36463 },
    { lat: 48.6713576, lng: 21.3641686 },
    { lat: 48.6713639, lng: 21.3632923 },
    { lat: 48.6709509, lng: 21.3618693 },
    { lat: 48.670875, lng: 21.361249 },
    { lat: 48.6706052, lng: 21.3608092 },
    { lat: 48.6705277, lng: 21.3605518 },
    { lat: 48.6704456, lng: 21.3605175 },
    { lat: 48.669891, lng: 21.358802 },
    { lat: 48.6694646, lng: 21.3582811 },
    { lat: 48.6692197, lng: 21.3578439 },
    { lat: 48.6692249, lng: 21.3574124 },
    { lat: 48.6688915, lng: 21.3563555 },
    { lat: 48.6693375, lng: 21.354987 },
    { lat: 48.669378, lng: 21.3546851 },
    { lat: 48.670175, lng: 21.3540957 },
    { lat: 48.6706361, lng: 21.3535974 },
    { lat: 48.6710937, lng: 21.3534763 },
    { lat: 48.6710873, lng: 21.3526606 },
    { lat: 48.6710181, lng: 21.3520053 },
    { lat: 48.6710838, lng: 21.3503445 },
    { lat: 48.670695, lng: 21.349037 },
    { lat: 48.670644, lng: 21.34904 },
    { lat: 48.668977, lng: 21.349142 },
    { lat: 48.668424, lng: 21.349173 },
    { lat: 48.668134, lng: 21.349207 },
    { lat: 48.666584, lng: 21.349259 },
    { lat: 48.664081, lng: 21.349342 },
    { lat: 48.661898, lng: 21.349408 },
    { lat: 48.660793, lng: 21.349439 },
    { lat: 48.659368, lng: 21.349479 },
    { lat: 48.659283, lng: 21.349457 },
    { lat: 48.659208, lng: 21.349437 },
    { lat: 48.659156, lng: 21.349423 },
    { lat: 48.658345, lng: 21.351827 },
    { lat: 48.658283, lng: 21.352009 },
    { lat: 48.658113, lng: 21.352634 },
    { lat: 48.657418, lng: 21.355175 },
    { lat: 48.65739, lng: 21.355278 },
    { lat: 48.65621, lng: 21.355093 },
    { lat: 48.655628, lng: 21.355097 },
    { lat: 48.654754, lng: 21.35489 },
    { lat: 48.654443, lng: 21.354975 },
    { lat: 48.65406, lng: 21.354904 },
    { lat: 48.653886, lng: 21.354926 },
    { lat: 48.653225, lng: 21.355008 },
    { lat: 48.652947, lng: 21.355092 },
    { lat: 48.652015, lng: 21.355374 },
    { lat: 48.651445, lng: 21.355547 },
    { lat: 48.650735, lng: 21.355761 },
    { lat: 48.6505016, lng: 21.3563292 },
    { lat: 48.6503348, lng: 21.3560933 },
    { lat: 48.6495195, lng: 21.3564093 },
    { lat: 48.649841, lng: 21.3579357 },
    { lat: 48.649929, lng: 21.3590668 },
    { lat: 48.6498243, lng: 21.359632 },
    { lat: 48.6495067, lng: 21.3601025 },
    { lat: 48.6496537, lng: 21.3605771 },
    { lat: 48.6497556, lng: 21.3605228 },
    { lat: 48.6498779, lng: 21.3602853 },
    { lat: 48.6500416, lng: 21.3601506 },
    { lat: 48.6504698, lng: 21.3605501 },
    { lat: 48.650673, lng: 21.360832 },
    { lat: 48.6510647, lng: 21.3608005 },
    { lat: 48.6512004, lng: 21.3607105 },
    { lat: 48.6513696, lng: 21.3607932 },
    { lat: 48.6514547, lng: 21.3607433 },
    { lat: 48.6516828, lng: 21.360055 },
    { lat: 48.6519664, lng: 21.359911 },
    { lat: 48.6523888, lng: 21.3599632 },
    { lat: 48.6523845, lng: 21.3601173 },
    { lat: 48.6526856, lng: 21.360101 },
    { lat: 48.6530502, lng: 21.362834 },
    { lat: 48.6531554, lng: 21.3643907 },
    { lat: 48.6533288, lng: 21.3659736 },
    { lat: 48.6529623, lng: 21.3663179 },
    { lat: 48.6529086, lng: 21.3668936 },
    { lat: 48.6530649, lng: 21.3674103 },
    { lat: 48.6531804, lng: 21.3675519 },
    { lat: 48.6533952, lng: 21.3684865 },
    { lat: 48.6535773, lng: 21.368888 },
    { lat: 48.6538721, lng: 21.3692693 },
    { lat: 48.6541725, lng: 21.3694869 },
    { lat: 48.6542515, lng: 21.369983 },
    { lat: 48.6543289, lng: 21.3701675 },
    { lat: 48.6540408, lng: 21.3711363 },
    { lat: 48.6542204, lng: 21.3712757 },
    { lat: 48.6541086, lng: 21.3718261 },
    { lat: 48.6542341, lng: 21.371961 },
    { lat: 48.6542035, lng: 21.372067 },
    { lat: 48.6543725, lng: 21.3723705 },
    { lat: 48.6542872, lng: 21.3727895 },
    { lat: 48.6545077, lng: 21.3732389 },
    { lat: 48.6546811, lng: 21.3733926 },
    { lat: 48.6541099, lng: 21.3751012 },
    { lat: 48.6529172, lng: 21.3780041 },
    { lat: 48.6529012, lng: 21.3781465 },
    { lat: 48.6530591, lng: 21.3785368 },
    { lat: 48.6531725, lng: 21.3791281 },
    { lat: 48.6532738, lng: 21.3807128 },
    { lat: 48.6536163, lng: 21.3809504 },
    { lat: 48.6538192, lng: 21.3809238 },
    { lat: 48.6543204, lng: 21.3803366 },
    { lat: 48.6545477, lng: 21.3802243 },
    { lat: 48.6547153, lng: 21.3802617 },
    { lat: 48.6550171, lng: 21.3810569 },
    { lat: 48.6554972, lng: 21.3815304 },
    { lat: 48.656059, lng: 21.3824831 },
    { lat: 48.6560895, lng: 21.3827551 },
    { lat: 48.6560418, lng: 21.3830363 },
    { lat: 48.656057, lng: 21.3837041 },
    { lat: 48.6563748, lng: 21.3831077 },
    { lat: 48.656352, lng: 21.3830654 },
    { lat: 48.6565503, lng: 21.3830313 },
    { lat: 48.6566067, lng: 21.3827004 },
    { lat: 48.6572704, lng: 21.3819797 },
    { lat: 48.6573632, lng: 21.3817313 },
    { lat: 48.6578241, lng: 21.3816416 },
    { lat: 48.6580429, lng: 21.3813569 },
    { lat: 48.6582693, lng: 21.3813251 },
    { lat: 48.6585361, lng: 21.3808382 },
    { lat: 48.6587152, lng: 21.3807723 },
    { lat: 48.6589003, lng: 21.3805575 },
    { lat: 48.6595992, lng: 21.3795848 },
    { lat: 48.6601166, lng: 21.3791599 },
    { lat: 48.6604184, lng: 21.3790585 },
    { lat: 48.6605342, lng: 21.379129 },
    { lat: 48.6607246, lng: 21.3791306 },
    { lat: 48.6609292, lng: 21.3789807 },
    { lat: 48.6615171, lng: 21.3788068 },
    { lat: 48.6618658, lng: 21.3786144 },
    { lat: 48.6624783, lng: 21.3785485 },
    { lat: 48.6627772, lng: 21.3784185 },
    { lat: 48.6632097, lng: 21.3780559 },
    { lat: 48.6637485, lng: 21.3777871 },
    { lat: 48.6638243, lng: 21.3777994 },
    { lat: 48.6639869, lng: 21.3776299 },
    { lat: 48.664467, lng: 21.3778421 },
    { lat: 48.6646087, lng: 21.3778019 },
    { lat: 48.6650277, lng: 21.3778175 },
    { lat: 48.6651496, lng: 21.3778224 },
    { lat: 48.6656401, lng: 21.3782728 },
    { lat: 48.6666375, lng: 21.378777 },
    { lat: 48.6667234, lng: 21.3790168 },
    { lat: 48.6668632, lng: 21.3792123 },
    { lat: 48.6670063, lng: 21.3792891 },
    { lat: 48.6675154, lng: 21.3793428 },
    { lat: 48.6679918, lng: 21.3791345 },
    { lat: 48.6702109, lng: 21.3792811 },
    { lat: 48.670198, lng: 21.3787365 },
    { lat: 48.6700181, lng: 21.3779876 },
    { lat: 48.6701489, lng: 21.3774336 },
    { lat: 48.6714967, lng: 21.3764428 },
    { lat: 48.6721028, lng: 21.3761221 },
    { lat: 48.6723051, lng: 21.3758153 },
    { lat: 48.6727547, lng: 21.375346 },
    { lat: 48.6729955, lng: 21.3751985 },
    { lat: 48.6733799, lng: 21.3747262 },
    { lat: 48.6737569, lng: 21.3740137 },
    { lat: 48.6737857, lng: 21.3739597 },
  ],
  SadynadTorysou: [
    { lat: 48.7168433, lng: 21.3356726 },
    { lat: 48.7171106, lng: 21.3354346 },
    { lat: 48.7174802, lng: 21.3349284 },
    { lat: 48.7185346, lng: 21.3339956 },
    { lat: 48.7190116, lng: 21.3333615 },
    { lat: 48.7195884, lng: 21.3328943 },
    { lat: 48.7198257, lng: 21.3328188 },
    { lat: 48.7204051, lng: 21.3324042 },
    { lat: 48.7207271, lng: 21.3320338 },
    { lat: 48.720628, lng: 21.3317744 },
    { lat: 48.7204368, lng: 21.3303253 },
    { lat: 48.7203952, lng: 21.3297711 },
    { lat: 48.7204032, lng: 21.3288415 },
    { lat: 48.7203702, lng: 21.3287974 },
    { lat: 48.7212213, lng: 21.3280653 },
    { lat: 48.7214997, lng: 21.3267765 },
    { lat: 48.7216942, lng: 21.3260973 },
    { lat: 48.7225514, lng: 21.3238258 },
    { lat: 48.723773, lng: 21.320955 },
    { lat: 48.723503, lng: 21.320885 },
    { lat: 48.722955, lng: 21.320719 },
    { lat: 48.721306, lng: 21.32024 },
    { lat: 48.720913, lng: 21.320182 },
    { lat: 48.72085, lng: 21.320172 },
    { lat: 48.720845, lng: 21.320399 },
    { lat: 48.720897, lng: 21.32055 },
    { lat: 48.720799, lng: 21.321162 },
    { lat: 48.720852, lng: 21.321424 },
    { lat: 48.720752, lng: 21.321314 },
    { lat: 48.720538, lng: 21.321424 },
    { lat: 48.720558, lng: 21.321623 },
    { lat: 48.720525, lng: 21.321853 },
    { lat: 48.720384, lng: 21.322293 },
    { lat: 48.720107, lng: 21.322348 },
    { lat: 48.719891, lng: 21.32239 },
    { lat: 48.719909, lng: 21.322715 },
    { lat: 48.719916, lng: 21.322848 },
    { lat: 48.719236, lng: 21.324091 },
    { lat: 48.719167, lng: 21.324091 },
    { lat: 48.71909, lng: 21.324092 },
    { lat: 48.719055, lng: 21.324279 },
    { lat: 48.719027, lng: 21.324433 },
    { lat: 48.71875, lng: 21.32493 },
    { lat: 48.718645, lng: 21.325025 },
    { lat: 48.718365, lng: 21.32549 },
    { lat: 48.718121, lng: 21.326186 },
    { lat: 48.718157, lng: 21.326483 },
    { lat: 48.718137, lng: 21.326624 },
    { lat: 48.7179, lng: 21.327099 },
    { lat: 48.717696, lng: 21.327154 },
    { lat: 48.717587, lng: 21.327668 },
    { lat: 48.717614, lng: 21.32804 },
    { lat: 48.717456, lng: 21.328441 },
    { lat: 48.717596, lng: 21.328596 },
    { lat: 48.717513, lng: 21.329172 },
    { lat: 48.717407, lng: 21.329537 },
    { lat: 48.717387, lng: 21.329603 },
    { lat: 48.717369, lng: 21.329668 },
    { lat: 48.717294, lng: 21.329761 },
    { lat: 48.717199, lng: 21.329737 },
    { lat: 48.717097, lng: 21.329861 },
    { lat: 48.717066, lng: 21.329906 },
    { lat: 48.716983, lng: 21.330024 },
    { lat: 48.71665, lng: 21.330013 },
    { lat: 48.716509, lng: 21.329927 },
    { lat: 48.716245, lng: 21.329584 },
    { lat: 48.716126, lng: 21.329451 },
    { lat: 48.71584, lng: 21.329287 },
    { lat: 48.715499, lng: 21.329095 },
    { lat: 48.715343, lng: 21.328859 },
    { lat: 48.715208, lng: 21.328651 },
    { lat: 48.714978, lng: 21.328385 },
    { lat: 48.714826, lng: 21.328032 },
    { lat: 48.714305, lng: 21.326927 },
    { lat: 48.714177, lng: 21.326622 },
    { lat: 48.714203, lng: 21.32653 },
    { lat: 48.714734, lng: 21.324663 },
    { lat: 48.714813, lng: 21.324716 },
    { lat: 48.715441, lng: 21.324163 },
    { lat: 48.715888, lng: 21.323991 },
    { lat: 48.716341, lng: 21.32383 },
    { lat: 48.716585, lng: 21.323697 },
    { lat: 48.716581, lng: 21.323667 },
    { lat: 48.71654, lng: 21.323398 },
    { lat: 48.716474, lng: 21.322951 },
    { lat: 48.716085, lng: 21.321656 },
    { lat: 48.716269, lng: 21.321478 },
    { lat: 48.716359, lng: 21.321323 },
    { lat: 48.716368, lng: 21.321308 },
    { lat: 48.716238, lng: 21.321263 },
    { lat: 48.715922, lng: 21.321154 },
    { lat: 48.715888, lng: 21.321142 },
    { lat: 48.713618, lng: 21.320355 },
    { lat: 48.713619, lng: 21.320346 },
    { lat: 48.713623, lng: 21.320326 },
    { lat: 48.713772, lng: 21.319579 },
    { lat: 48.713832, lng: 21.319277 },
    { lat: 48.713824, lng: 21.319275 },
    { lat: 48.713606, lng: 21.319225 },
    { lat: 48.713281, lng: 21.320462 },
    { lat: 48.712784, lng: 21.320183 },
    { lat: 48.712605, lng: 21.320064 },
    { lat: 48.712698, lng: 21.319637 },
    { lat: 48.712629, lng: 21.319608 },
    { lat: 48.712576, lng: 21.319585 },
    { lat: 48.712549, lng: 21.319574 },
    { lat: 48.712537, lng: 21.319564 },
    { lat: 48.712462, lng: 21.319511 },
    { lat: 48.712255, lng: 21.319361 },
    { lat: 48.712063, lng: 21.319933 },
    { lat: 48.712047, lng: 21.320271 },
    { lat: 48.711823, lng: 21.320915 },
    { lat: 48.711761, lng: 21.321169 },
    { lat: 48.71169, lng: 21.321189 },
    { lat: 48.7116, lng: 21.321213 },
    { lat: 48.711542, lng: 21.321537 },
    { lat: 48.711283, lng: 21.321828 },
    { lat: 48.711076, lng: 21.322566 },
    { lat: 48.710737, lng: 21.323196 },
    { lat: 48.710728, lng: 21.323254 },
    { lat: 48.710727, lng: 21.323259 },
    { lat: 48.710723, lng: 21.323279 },
    { lat: 48.710713, lng: 21.323344 },
    { lat: 48.710703, lng: 21.323401 },
    { lat: 48.710697, lng: 21.323436 },
    { lat: 48.710824, lng: 21.324249 },
    { lat: 48.710812, lng: 21.324846 },
    { lat: 48.710636, lng: 21.325175 },
    { lat: 48.710393, lng: 21.325489 },
    { lat: 48.709964, lng: 21.325828 },
    { lat: 48.709398, lng: 21.326285 },
    { lat: 48.708578, lng: 21.326499 },
    { lat: 48.708557, lng: 21.326504 },
    { lat: 48.708534, lng: 21.32651 },
    { lat: 48.708491, lng: 21.326521 },
    { lat: 48.708464, lng: 21.326641 },
    { lat: 48.708235, lng: 21.327685 },
    { lat: 48.708225, lng: 21.327681 },
    { lat: 48.707666, lng: 21.327475 },
    { lat: 48.70766, lng: 21.327495 },
    { lat: 48.707483, lng: 21.328082 },
    { lat: 48.707524, lng: 21.328107 },
    { lat: 48.7075, lng: 21.32818 },
    { lat: 48.707412, lng: 21.328445 },
    { lat: 48.707353, lng: 21.328629 },
    { lat: 48.707293, lng: 21.328813 },
    { lat: 48.707206, lng: 21.329078 },
    { lat: 48.70712, lng: 21.329341 },
    { lat: 48.707035, lng: 21.329598 },
    { lat: 48.706922, lng: 21.329948 },
    { lat: 48.706818, lng: 21.330264 },
    { lat: 48.705919, lng: 21.329801 },
    { lat: 48.705827, lng: 21.329754 },
    { lat: 48.705798, lng: 21.329895 },
    { lat: 48.705527, lng: 21.329754 },
    { lat: 48.704514, lng: 21.329228 },
    { lat: 48.70417, lng: 21.329055 },
    { lat: 48.70355, lng: 21.328724 },
    { lat: 48.70193, lng: 21.327851 },
    { lat: 48.701855, lng: 21.327799 },
    { lat: 48.701618, lng: 21.328547 },
    { lat: 48.701196, lng: 21.329519 },
    { lat: 48.700941, lng: 21.330087 },
    { lat: 48.700912, lng: 21.330152 },
    { lat: 48.7016675, lng: 21.3304482 },
    { lat: 48.7026161, lng: 21.3338684 },
    { lat: 48.7027765, lng: 21.3349304 },
    { lat: 48.7031185, lng: 21.3354851 },
    { lat: 48.7035999, lng: 21.336107 },
    { lat: 48.7036979, lng: 21.3371969 },
    { lat: 48.7036562, lng: 21.3383046 },
    { lat: 48.70343, lng: 21.3396473 },
    { lat: 48.704138, lng: 21.3402106 },
    { lat: 48.7043835, lng: 21.3404787 },
    { lat: 48.704294, lng: 21.3408732 },
    { lat: 48.7043103, lng: 21.3411867 },
    { lat: 48.7044453, lng: 21.3418233 },
    { lat: 48.7047593, lng: 21.3449965 },
    { lat: 48.7043159, lng: 21.3452187 },
    { lat: 48.7041521, lng: 21.3452 },
    { lat: 48.7041488, lng: 21.3453855 },
    { lat: 48.7042508, lng: 21.3454455 },
    { lat: 48.7043193, lng: 21.3455918 },
    { lat: 48.7042777, lng: 21.3458929 },
    { lat: 48.7043242, lng: 21.3462791 },
    { lat: 48.7041969, lng: 21.3464201 },
    { lat: 48.7042013, lng: 21.3467543 },
    { lat: 48.7042339, lng: 21.346974 },
    { lat: 48.7043964, lng: 21.3472896 },
    { lat: 48.7043317, lng: 21.3474582 },
    { lat: 48.7043918, lng: 21.3477705 },
    { lat: 48.7034293, lng: 21.3479453 },
    { lat: 48.7035009, lng: 21.3489465 },
    { lat: 48.7037947, lng: 21.3495876 },
    { lat: 48.703851, lng: 21.3500315 },
    { lat: 48.7041644, lng: 21.3509887 },
    { lat: 48.7040903, lng: 21.3515507 },
    { lat: 48.7041004, lng: 21.3526769 },
    { lat: 48.7043362, lng: 21.3528096 },
    { lat: 48.7045289, lng: 21.3530394 },
    { lat: 48.7042129, lng: 21.3539094 },
    { lat: 48.7049421, lng: 21.3539822 },
    { lat: 48.705114, lng: 21.3553955 },
    { lat: 48.7050404, lng: 21.3560533 },
    { lat: 48.7050522, lng: 21.3564141 },
    { lat: 48.7052742, lng: 21.3572422 },
    { lat: 48.7053685, lng: 21.3577976 },
    { lat: 48.7060336, lng: 21.3583606 },
    { lat: 48.7062066, lng: 21.3585771 },
    { lat: 48.7062955, lng: 21.3591724 },
    { lat: 48.7061084, lng: 21.3602145 },
    { lat: 48.7059324, lng: 21.3604247 },
    { lat: 48.7056611, lng: 21.3610956 },
    { lat: 48.7056378, lng: 21.361295 },
    { lat: 48.7053383, lng: 21.3619315 },
    { lat: 48.7052299, lng: 21.3625535 },
    { lat: 48.7050065, lng: 21.3626468 },
    { lat: 48.7049317, lng: 21.3627777 },
    { lat: 48.7049069, lng: 21.3630053 },
    { lat: 48.7047153, lng: 21.3634103 },
    { lat: 48.7046312, lng: 21.3638703 },
    { lat: 48.7045375, lng: 21.3641261 },
    { lat: 48.7045129, lng: 21.3648788 },
    { lat: 48.7045461, lng: 21.3657975 },
    { lat: 48.7044561, lng: 21.3665465 },
    { lat: 48.7044642, lng: 21.3678737 },
    { lat: 48.7046317, lng: 21.3686554 },
    { lat: 48.7045984, lng: 21.3691586 },
    { lat: 48.7046543, lng: 21.370127 },
    { lat: 48.7046521, lng: 21.3713705 },
    { lat: 48.7046447, lng: 21.3717552 },
    { lat: 48.7045301, lng: 21.3725599 },
    { lat: 48.7045658, lng: 21.3728154 },
    { lat: 48.7045121, lng: 21.3736848 },
    { lat: 48.7045264, lng: 21.3743522 },
    { lat: 48.7044774, lng: 21.3747482 },
    { lat: 48.7040283, lng: 21.3759516 },
    { lat: 48.7035071, lng: 21.3767296 },
    { lat: 48.7032317, lng: 21.3778361 },
    { lat: 48.7030336, lng: 21.378286 },
    { lat: 48.7029428, lng: 21.3786424 },
    { lat: 48.7029393, lng: 21.3788628 },
    { lat: 48.7028214, lng: 21.3793222 },
    { lat: 48.7027922, lng: 21.3796245 },
    { lat: 48.7027937, lng: 21.3809222 },
    { lat: 48.702562, lng: 21.3824663 },
    { lat: 48.7028472, lng: 21.3821484 },
    { lat: 48.7029793, lng: 21.3817468 },
    { lat: 48.7032986, lng: 21.3810995 },
    { lat: 48.7036465, lng: 21.3802004 },
    { lat: 48.7041273, lng: 21.3797573 },
    { lat: 48.7043091, lng: 21.3796619 },
    { lat: 48.7046749, lng: 21.3798543 },
    { lat: 48.705092, lng: 21.379893 },
    { lat: 48.7052855, lng: 21.379911 },
    { lat: 48.7054457, lng: 21.3798951 },
    { lat: 48.7064253, lng: 21.3797981 },
    { lat: 48.7068434, lng: 21.3799649 },
    { lat: 48.7073414, lng: 21.3808558 },
    { lat: 48.7081591, lng: 21.3817889 },
    { lat: 48.7084331, lng: 21.3818831 },
    { lat: 48.7090433, lng: 21.3823051 },
    { lat: 48.7093249, lng: 21.3823868 },
    { lat: 48.7098452, lng: 21.3827084 },
    { lat: 48.7102937, lng: 21.3828802 },
    { lat: 48.7105479, lng: 21.3831729 },
    { lat: 48.7109234, lng: 21.3833897 },
    { lat: 48.7112872, lng: 21.3834062 },
    { lat: 48.7115662, lng: 21.3835087 },
    { lat: 48.7119416, lng: 21.3834212 },
    { lat: 48.7123842, lng: 21.3836026 },
    { lat: 48.7136135, lng: 21.3835657 },
    { lat: 48.7137824, lng: 21.3836401 },
    { lat: 48.7141581, lng: 21.3836316 },
    { lat: 48.7150766, lng: 21.3848516 },
    { lat: 48.7154419, lng: 21.3851011 },
    { lat: 48.7160154, lng: 21.386377 },
    { lat: 48.7162102, lng: 21.3866456 },
    { lat: 48.7166895, lng: 21.3870531 },
    { lat: 48.7172722, lng: 21.3876877 },
    { lat: 48.7176131, lng: 21.3878755 },
    { lat: 48.7181044, lng: 21.3879426 },
    { lat: 48.718572, lng: 21.387888 },
    { lat: 48.7194768, lng: 21.387218 },
    { lat: 48.7204064, lng: 21.3865295 },
    { lat: 48.7205898, lng: 21.3864565 },
    { lat: 48.721365, lng: 21.3863699 },
    { lat: 48.7225134, lng: 21.3863577 },
    { lat: 48.7230488, lng: 21.3865733 },
    { lat: 48.7231594, lng: 21.3864328 },
    { lat: 48.7240987, lng: 21.3863486 },
    { lat: 48.7248617, lng: 21.3863476 },
    { lat: 48.7251615, lng: 21.3864046 },
    { lat: 48.7256195, lng: 21.3862717 },
    { lat: 48.7260038, lng: 21.3861602 },
    { lat: 48.7275208, lng: 21.385291 },
    { lat: 48.727971, lng: 21.3851018 },
    { lat: 48.7284784, lng: 21.3846477 },
    { lat: 48.7288649, lng: 21.3844054 },
    { lat: 48.7295571, lng: 21.3836885 },
    { lat: 48.7296137, lng: 21.3837036 },
    { lat: 48.7294416, lng: 21.3811731 },
    { lat: 48.7292008, lng: 21.3800782 },
    { lat: 48.728717, lng: 21.3789103 },
    { lat: 48.7278835, lng: 21.3775225 },
    { lat: 48.7270109, lng: 21.3765327 },
    { lat: 48.7264836, lng: 21.3758287 },
    { lat: 48.7261107, lng: 21.3742706 },
    { lat: 48.7261203, lng: 21.3731622 },
    { lat: 48.7260136, lng: 21.3725668 },
    { lat: 48.7258977, lng: 21.3714348 },
    { lat: 48.725415, lng: 21.3697474 },
    { lat: 48.7250177, lng: 21.3686141 },
    { lat: 48.7238497, lng: 21.3665487 },
    { lat: 48.7234012, lng: 21.3654767 },
    { lat: 48.7227441, lng: 21.3641638 },
    { lat: 48.7220991, lng: 21.3635953 },
    { lat: 48.7211488, lng: 21.3623759 },
    { lat: 48.7202534, lng: 21.360692 },
    { lat: 48.7191626, lng: 21.359038 },
    { lat: 48.7189607, lng: 21.3584927 },
    { lat: 48.7178281, lng: 21.3565597 },
    { lat: 48.7176736, lng: 21.3534809 },
    { lat: 48.7175437, lng: 21.3487177 },
    { lat: 48.7176861, lng: 21.3487307 },
    { lat: 48.7175181, lng: 21.3456974 },
    { lat: 48.7171541, lng: 21.3417957 },
    { lat: 48.7170763, lng: 21.3404849 },
    { lat: 48.7169603, lng: 21.3404855 },
    { lat: 48.7167778, lng: 21.3368207 },
    { lat: 48.7169536, lng: 21.3367922 },
    { lat: 48.7168651, lng: 21.3359098 },
    { lat: 48.7168433, lng: 21.3356726 },
  ],
  Štós: [
    { lat: 48.7019067, lng: 20.8410998 },
    { lat: 48.7019752, lng: 20.8410331 },
    { lat: 48.7026956, lng: 20.8403092 },
    { lat: 48.7038672, lng: 20.8400765 },
    { lat: 48.7049435, lng: 20.840764 },
    { lat: 48.7055326, lng: 20.8407739 },
    { lat: 48.7058775, lng: 20.8410817 },
    { lat: 48.7062395, lng: 20.8412025 },
    { lat: 48.7067789, lng: 20.8410788 },
    { lat: 48.7072854, lng: 20.8411689 },
    { lat: 48.7078662, lng: 20.8411353 },
    { lat: 48.7082264, lng: 20.8410389 },
    { lat: 48.7087565, lng: 20.8411639 },
    { lat: 48.7092517, lng: 20.8409673 },
    { lat: 48.7097352, lng: 20.8405903 },
    { lat: 48.7100747, lng: 20.8404128 },
    { lat: 48.7106468, lng: 20.8397728 },
    { lat: 48.7108355, lng: 20.839692 },
    { lat: 48.7118863, lng: 20.838794 },
    { lat: 48.7125551, lng: 20.8380177 },
    { lat: 48.7127018, lng: 20.8377705 },
    { lat: 48.7132886, lng: 20.8374544 },
    { lat: 48.7135115, lng: 20.8374176 },
    { lat: 48.7138493, lng: 20.837063 },
    { lat: 48.7144901, lng: 20.8369369 },
    { lat: 48.7147778, lng: 20.8373519 },
    { lat: 48.7154421, lng: 20.8377761 },
    { lat: 48.7156778, lng: 20.8378256 },
    { lat: 48.7163939, lng: 20.8375652 },
    { lat: 48.7166922, lng: 20.8377324 },
    { lat: 48.7168121, lng: 20.8379729 },
    { lat: 48.7170992, lng: 20.8380707 },
    { lat: 48.7171866, lng: 20.8382239 },
    { lat: 48.7177364, lng: 20.8380715 },
    { lat: 48.7183147, lng: 20.8380954 },
    { lat: 48.7189681, lng: 20.8379807 },
    { lat: 48.7197879, lng: 20.8386455 },
    { lat: 48.7207896, lng: 20.8392902 },
    { lat: 48.7212285, lng: 20.8394788 },
    { lat: 48.7216638, lng: 20.83977 },
    { lat: 48.721759, lng: 20.8399496 },
    { lat: 48.7222512, lng: 20.8400855 },
    { lat: 48.7228379, lng: 20.8398942 },
    { lat: 48.7231691, lng: 20.8396082 },
    { lat: 48.7236752, lng: 20.8387493 },
    { lat: 48.7239463, lng: 20.8387461 },
    { lat: 48.7241628, lng: 20.8388877 },
    { lat: 48.7243139, lng: 20.8392541 },
    { lat: 48.724621, lng: 20.8396702 },
    { lat: 48.7249145, lng: 20.8403747 },
    { lat: 48.7250601, lng: 20.8403551 },
    { lat: 48.725389, lng: 20.8399081 },
    { lat: 48.7257927, lng: 20.8396021 },
    { lat: 48.7261452, lng: 20.8388883 },
    { lat: 48.7262788, lng: 20.8386766 },
    { lat: 48.7264104, lng: 20.8385828 },
    { lat: 48.7268412, lng: 20.8385692 },
    { lat: 48.7273453, lng: 20.8386596 },
    { lat: 48.7276869, lng: 20.8384483 },
    { lat: 48.7286802, lng: 20.8382969 },
    { lat: 48.7290835, lng: 20.8380155 },
    { lat: 48.7293876, lng: 20.8378859 },
    { lat: 48.7300679, lng: 20.8377713 },
    { lat: 48.7305625, lng: 20.837421 },
    { lat: 48.7311687, lng: 20.8368522 },
    { lat: 48.7312732, lng: 20.8368454 },
    { lat: 48.7317288, lng: 20.8365399 },
    { lat: 48.7320762, lng: 20.8361712 },
    { lat: 48.7331419, lng: 20.8358346 },
    { lat: 48.7334859, lng: 20.8356259 },
    { lat: 48.73368, lng: 20.8350921 },
    { lat: 48.734104, lng: 20.8348825 },
    { lat: 48.7353192, lng: 20.8345063 },
    { lat: 48.73588, lng: 20.8345479 },
    { lat: 48.7364767, lng: 20.834743 },
    { lat: 48.7369114, lng: 20.8345517 },
    { lat: 48.7380625, lng: 20.8337237 },
    { lat: 48.7381837, lng: 20.833778 },
    { lat: 48.7385193, lng: 20.834663 },
    { lat: 48.7389146, lng: 20.8348668 },
    { lat: 48.7397222, lng: 20.8343851 },
    { lat: 48.7405973, lng: 20.8343983 },
    { lat: 48.7411683, lng: 20.8338222 },
    { lat: 48.7412544, lng: 20.8330575 },
    { lat: 48.7414643, lng: 20.8324842 },
    { lat: 48.7422028, lng: 20.8313837 },
    { lat: 48.7423443, lng: 20.8311096 },
    { lat: 48.7424838, lng: 20.830682 },
    { lat: 48.742964, lng: 20.829867 },
    { lat: 48.743193, lng: 20.829364 },
    { lat: 48.743328, lng: 20.828992 },
    { lat: 48.743287, lng: 20.828732 },
    { lat: 48.743212, lng: 20.828456 },
    { lat: 48.743196, lng: 20.828306 },
    { lat: 48.743318, lng: 20.827726 },
    { lat: 48.743244, lng: 20.827404 },
    { lat: 48.743166, lng: 20.827069 },
    { lat: 48.743179, lng: 20.82675 },
    { lat: 48.743172, lng: 20.826492 },
    { lat: 48.743142, lng: 20.825725 },
    { lat: 48.743315, lng: 20.824996 },
    { lat: 48.743316, lng: 20.824456 },
    { lat: 48.74329, lng: 20.824314 },
    { lat: 48.743273, lng: 20.824326 },
    { lat: 48.742941, lng: 20.824552 },
    { lat: 48.742917, lng: 20.824701 },
    { lat: 48.742639, lng: 20.8248 },
    { lat: 48.742413, lng: 20.824339 },
    { lat: 48.742262, lng: 20.824365 },
    { lat: 48.742166, lng: 20.824587 },
    { lat: 48.742031, lng: 20.825002 },
    { lat: 48.742026, lng: 20.825125 },
    { lat: 48.742049, lng: 20.825367 },
    { lat: 48.741796, lng: 20.824989 },
    { lat: 48.74155, lng: 20.824887 },
    { lat: 48.741241, lng: 20.824545 },
    { lat: 48.741035, lng: 20.824498 },
    { lat: 48.74086, lng: 20.824589 },
    { lat: 48.741186, lng: 20.824706 },
    { lat: 48.741319, lng: 20.824901 },
    { lat: 48.741156, lng: 20.825296 },
    { lat: 48.740995, lng: 20.825308 },
    { lat: 48.740628, lng: 20.82517 },
    { lat: 48.740598, lng: 20.825006 },
    { lat: 48.740467, lng: 20.824981 },
    { lat: 48.740183, lng: 20.825256 },
    { lat: 48.740014, lng: 20.825257 },
    { lat: 48.739701, lng: 20.824919 },
    { lat: 48.739506, lng: 20.824951 },
    { lat: 48.739266, lng: 20.824763 },
    { lat: 48.739267, lng: 20.824611 },
    { lat: 48.739433, lng: 20.824608 },
    { lat: 48.739422, lng: 20.824396 },
    { lat: 48.739699, lng: 20.824268 },
    { lat: 48.739708, lng: 20.824148 },
    { lat: 48.739686, lng: 20.823976 },
    { lat: 48.739526, lng: 20.823914 },
    { lat: 48.739376, lng: 20.824045 },
    { lat: 48.739258, lng: 20.823728 },
    { lat: 48.739216, lng: 20.8237 },
    { lat: 48.739156, lng: 20.823864 },
    { lat: 48.739081, lng: 20.823985 },
    { lat: 48.73893, lng: 20.823796 },
    { lat: 48.738938, lng: 20.823532 },
    { lat: 48.738882, lng: 20.823511 },
    { lat: 48.738672, lng: 20.823433 },
    { lat: 48.738656, lng: 20.823321 },
    { lat: 48.738546, lng: 20.823292 },
    { lat: 48.738387, lng: 20.822385 },
    { lat: 48.738595, lng: 20.822314 },
    { lat: 48.738656, lng: 20.822533 },
    { lat: 48.738962, lng: 20.822735 },
    { lat: 48.739057, lng: 20.822566 },
    { lat: 48.739094, lng: 20.822571 },
    { lat: 48.739104, lng: 20.822596 },
    { lat: 48.739205, lng: 20.822847 },
    { lat: 48.739387, lng: 20.822907 },
    { lat: 48.739378, lng: 20.823051 },
    { lat: 48.739497, lng: 20.823216 },
    { lat: 48.739653, lng: 20.822937 },
    { lat: 48.739808, lng: 20.822588 },
    { lat: 48.739847, lng: 20.822532 },
    { lat: 48.739779, lng: 20.822345 },
    { lat: 48.739523, lng: 20.822159 },
    { lat: 48.739327, lng: 20.821765 },
    { lat: 48.739429, lng: 20.821645 },
    { lat: 48.739195, lng: 20.82153 },
    { lat: 48.739064, lng: 20.821653 },
    { lat: 48.738994, lng: 20.821791 },
    { lat: 48.738791, lng: 20.821945 },
    { lat: 48.738702, lng: 20.821925 },
    { lat: 48.738671, lng: 20.821769 },
    { lat: 48.738934, lng: 20.821252 },
    { lat: 48.738916, lng: 20.821147 },
    { lat: 48.739051, lng: 20.820953 },
    { lat: 48.73906, lng: 20.820631 },
    { lat: 48.738974, lng: 20.820353 },
    { lat: 48.739114, lng: 20.820391 },
    { lat: 48.739139, lng: 20.820594 },
    { lat: 48.739398, lng: 20.820604 },
    { lat: 48.739518, lng: 20.820694 },
    { lat: 48.739653, lng: 20.820708 },
    { lat: 48.739842, lng: 20.820294 },
    { lat: 48.739935, lng: 20.820015 },
    { lat: 48.739925, lng: 20.819901 },
    { lat: 48.740024, lng: 20.819409 },
    { lat: 48.740019, lng: 20.819195 },
    { lat: 48.739878, lng: 20.819079 },
    { lat: 48.739839, lng: 20.818668 },
    { lat: 48.740018, lng: 20.818612 },
    { lat: 48.739889, lng: 20.818016 },
    { lat: 48.739877, lng: 20.817962 },
    { lat: 48.739983, lng: 20.817624 },
    { lat: 48.740142, lng: 20.816635 },
    { lat: 48.740146, lng: 20.816613 },
    { lat: 48.73982, lng: 20.816485 },
    { lat: 48.739571, lng: 20.816368 },
    { lat: 48.739289, lng: 20.816165 },
    { lat: 48.73855, lng: 20.81581 },
    { lat: 48.73825, lng: 20.815793 },
    { lat: 48.737655, lng: 20.815238 },
    { lat: 48.737571, lng: 20.815047 },
    { lat: 48.737575, lng: 20.814164 },
    { lat: 48.737599, lng: 20.813493 },
    { lat: 48.737582, lng: 20.813339 },
    { lat: 48.737636, lng: 20.81173 },
    { lat: 48.737634, lng: 20.811063 },
    { lat: 48.737656, lng: 20.81049 },
    { lat: 48.737638, lng: 20.809858 },
    { lat: 48.737722, lng: 20.809399 },
    { lat: 48.737704, lng: 20.809225 },
    { lat: 48.737705, lng: 20.808532 },
    { lat: 48.737631, lng: 20.808119 },
    { lat: 48.737623, lng: 20.807767 },
    { lat: 48.737663, lng: 20.80739 },
    { lat: 48.737643, lng: 20.807075 },
    { lat: 48.737573, lng: 20.806803 },
    { lat: 48.73735, lng: 20.806527 },
    { lat: 48.736935, lng: 20.805569 },
    { lat: 48.736571, lng: 20.804711 },
    { lat: 48.736473, lng: 20.804412 },
    { lat: 48.73624, lng: 20.803877 },
    { lat: 48.73611, lng: 20.803616 },
    { lat: 48.735765, lng: 20.802788 },
    { lat: 48.735611, lng: 20.802587 },
    { lat: 48.73515, lng: 20.801709 },
    { lat: 48.73484, lng: 20.801201 },
    { lat: 48.734106, lng: 20.800704 },
    { lat: 48.733698, lng: 20.800645 },
    { lat: 48.733397, lng: 20.800185 },
    { lat: 48.73327, lng: 20.799727 },
    { lat: 48.733224, lng: 20.799181 },
    { lat: 48.733137, lng: 20.798848 },
    { lat: 48.733016, lng: 20.798568 },
    { lat: 48.732979, lng: 20.798374 },
    { lat: 48.732984, lng: 20.797973 },
    { lat: 48.732967, lng: 20.797693 },
    { lat: 48.733008, lng: 20.797318 },
    { lat: 48.733004, lng: 20.796679 },
    { lat: 48.732977, lng: 20.796044 },
    { lat: 48.732753, lng: 20.795166 },
    { lat: 48.732597, lng: 20.794932 },
    { lat: 48.73242, lng: 20.79456 },
    { lat: 48.732281, lng: 20.794435 },
    { lat: 48.731622, lng: 20.794391 },
    { lat: 48.731291, lng: 20.794334 },
    { lat: 48.73118, lng: 20.794219 },
    { lat: 48.730968, lng: 20.793645 },
    { lat: 48.730638, lng: 20.792692 },
    { lat: 48.730459, lng: 20.792348 },
    { lat: 48.730258, lng: 20.79182 },
    { lat: 48.729986, lng: 20.791258 },
    { lat: 48.729602, lng: 20.790578 },
    { lat: 48.729376, lng: 20.789948 },
    { lat: 48.729275, lng: 20.789338 },
    { lat: 48.729182, lng: 20.788205 },
    { lat: 48.729191, lng: 20.787742 },
    { lat: 48.728867, lng: 20.786758 },
    { lat: 48.728811, lng: 20.786495 },
    { lat: 48.728745, lng: 20.786277 },
    { lat: 48.728652, lng: 20.785693 },
    { lat: 48.728689, lng: 20.784723 },
    { lat: 48.728601, lng: 20.784043 },
    { lat: 48.728567, lng: 20.783701 },
    { lat: 48.728556, lng: 20.782921 },
    { lat: 48.728546, lng: 20.782498 },
    { lat: 48.728435, lng: 20.782165 },
    { lat: 48.728063, lng: 20.780801 },
    { lat: 48.728072, lng: 20.779128 },
    { lat: 48.728031, lng: 20.778375 },
    { lat: 48.727949, lng: 20.777336 },
    { lat: 48.728063, lng: 20.776448 },
    { lat: 48.727979, lng: 20.776169 },
    { lat: 48.727792, lng: 20.775104 },
    { lat: 48.727581, lng: 20.774697 },
    { lat: 48.727278, lng: 20.774014 },
    { lat: 48.727086, lng: 20.773136 },
    { lat: 48.727018, lng: 20.772506 },
    { lat: 48.72679, lng: 20.771585 },
    { lat: 48.726798, lng: 20.770978 },
    { lat: 48.726694, lng: 20.770644 },
    { lat: 48.726646, lng: 20.770239 },
    { lat: 48.726674, lng: 20.769746 },
    { lat: 48.726677, lng: 20.76975 },
    { lat: 48.726681, lng: 20.769654 },
    { lat: 48.726615, lng: 20.769313 },
    { lat: 48.726545, lng: 20.768956 },
    { lat: 48.726518, lng: 20.768483 },
    { lat: 48.726594, lng: 20.767769 },
    { lat: 48.726473, lng: 20.767395 },
    { lat: 48.726373, lng: 20.766819 },
    { lat: 48.726016, lng: 20.766505 },
    { lat: 48.725941, lng: 20.766346 },
    { lat: 48.725783, lng: 20.765454 },
    { lat: 48.725549, lng: 20.765045 },
    { lat: 48.725287, lng: 20.764393 },
    { lat: 48.725014, lng: 20.764005 },
    { lat: 48.724789, lng: 20.763899 },
    { lat: 48.724127, lng: 20.76342 },
    { lat: 48.723767, lng: 20.763278 },
    { lat: 48.723348, lng: 20.762864 },
    { lat: 48.72317, lng: 20.762569 },
    { lat: 48.722666, lng: 20.762065 },
    { lat: 48.722455, lng: 20.761972 },
    { lat: 48.722425, lng: 20.761959 },
    { lat: 48.722407, lng: 20.761956 },
    { lat: 48.722296, lng: 20.761941 },
    { lat: 48.722272, lng: 20.761938 },
    { lat: 48.722222, lng: 20.761931 },
    { lat: 48.722207, lng: 20.761929 },
    { lat: 48.722042, lng: 20.761938 },
    { lat: 48.7217558, lng: 20.761766 },
    { lat: 48.721723, lng: 20.761749 },
    { lat: 48.7215984, lng: 20.7615756 },
    { lat: 48.721557, lng: 20.761518 },
    { lat: 48.721335, lng: 20.761418 },
    { lat: 48.721208, lng: 20.761312 },
    { lat: 48.720603, lng: 20.760471 },
    { lat: 48.720218, lng: 20.760583 },
    { lat: 48.720044, lng: 20.760557 },
    { lat: 48.719908, lng: 20.760376 },
    { lat: 48.719857, lng: 20.760183 },
    { lat: 48.719773, lng: 20.759405 },
    { lat: 48.719516, lng: 20.75888 },
    { lat: 48.719389, lng: 20.758491 },
    { lat: 48.719311, lng: 20.757986 },
    { lat: 48.719204, lng: 20.757669 },
    { lat: 48.719194, lng: 20.757174 },
    { lat: 48.719226, lng: 20.756805 },
    { lat: 48.719165, lng: 20.756368 },
    { lat: 48.718983, lng: 20.755821 },
    { lat: 48.718842, lng: 20.755465 },
    { lat: 48.718817, lng: 20.754769 },
    { lat: 48.718515, lng: 20.754089 },
    { lat: 48.718387, lng: 20.753485 },
    { lat: 48.718371, lng: 20.752791 },
    { lat: 48.718209, lng: 20.752192 },
    { lat: 48.718085, lng: 20.751604 },
    { lat: 48.717893, lng: 20.751098 },
    { lat: 48.717742, lng: 20.750644 },
    { lat: 48.717697, lng: 20.750408 },
    { lat: 48.71759, lng: 20.750129 },
    { lat: 48.716908, lng: 20.7495 },
    { lat: 48.716524, lng: 20.749083 },
    { lat: 48.716106, lng: 20.748777 },
    { lat: 48.715042, lng: 20.747862 },
    { lat: 48.714472, lng: 20.74774 },
    { lat: 48.713846, lng: 20.747857 },
    { lat: 48.71301, lng: 20.748223 },
    { lat: 48.712264, lng: 20.748854 },
    { lat: 48.712065, lng: 20.748937 },
    { lat: 48.711871, lng: 20.748828 },
    { lat: 48.711518, lng: 20.748547 },
    { lat: 48.711258, lng: 20.748411 },
    { lat: 48.710844, lng: 20.748325 },
    { lat: 48.710535, lng: 20.748225 },
    { lat: 48.709735, lng: 20.748124 },
    { lat: 48.709171, lng: 20.748617 },
    { lat: 48.709046, lng: 20.748659 },
    { lat: 48.708872, lng: 20.748659 },
    { lat: 48.708715, lng: 20.74862 },
    { lat: 48.708566, lng: 20.748548 },
    { lat: 48.70839, lng: 20.748507 },
    { lat: 48.708025, lng: 20.7483 },
    { lat: 48.707707, lng: 20.748082 },
    { lat: 48.707084, lng: 20.747769 },
    { lat: 48.706654, lng: 20.747487 },
    { lat: 48.706507, lng: 20.74747 },
    { lat: 48.706118, lng: 20.747678 },
    { lat: 48.705783, lng: 20.747965 },
    { lat: 48.70569, lng: 20.748008 },
    { lat: 48.705587, lng: 20.748007 },
    { lat: 48.705157, lng: 20.747939 },
    { lat: 48.704619, lng: 20.748384 },
    { lat: 48.703867, lng: 20.749 },
    { lat: 48.703445, lng: 20.749264 },
    { lat: 48.703077, lng: 20.749604 },
    { lat: 48.702809, lng: 20.749898 },
    { lat: 48.702606, lng: 20.749976 },
    { lat: 48.702099, lng: 20.749997 },
    { lat: 48.701136, lng: 20.750234 },
    { lat: 48.700986, lng: 20.750306 },
    { lat: 48.700892, lng: 20.750255 },
    { lat: 48.700763, lng: 20.750254 },
    { lat: 48.700466, lng: 20.75039 },
    { lat: 48.700301, lng: 20.750389 },
    { lat: 48.699803, lng: 20.750243 },
    { lat: 48.699634, lng: 20.750166 },
    { lat: 48.698918, lng: 20.749661 },
    { lat: 48.698354, lng: 20.749522 },
    { lat: 48.698021, lng: 20.749387 },
    { lat: 48.697348, lng: 20.749116 },
    { lat: 48.696754, lng: 20.748826 },
    { lat: 48.696327, lng: 20.748481 },
    { lat: 48.696052, lng: 20.74818 },
    { lat: 48.695927, lng: 20.748017 },
    { lat: 48.695167, lng: 20.746865 },
    { lat: 48.694766, lng: 20.746528 },
    { lat: 48.694325, lng: 20.746299 },
    { lat: 48.693926, lng: 20.74626 },
    { lat: 48.693419, lng: 20.746347 },
    { lat: 48.692705, lng: 20.746196 },
    { lat: 48.692233, lng: 20.745915 },
    { lat: 48.692064, lng: 20.745799 },
    { lat: 48.691496, lng: 20.745391 },
    { lat: 48.691101, lng: 20.745092 },
    { lat: 48.690929, lng: 20.744788 },
    { lat: 48.690757, lng: 20.744576 },
    { lat: 48.690386, lng: 20.743838 },
    { lat: 48.690166, lng: 20.743216 },
    { lat: 48.689946, lng: 20.742792 },
    { lat: 48.689743, lng: 20.742364 },
    { lat: 48.68947, lng: 20.741958 },
    { lat: 48.688655, lng: 20.740913 },
    { lat: 48.688219, lng: 20.740603 },
    { lat: 48.687659, lng: 20.739943 },
    { lat: 48.687189, lng: 20.739552 },
    { lat: 48.686549, lng: 20.739172 },
    { lat: 48.685856, lng: 20.738915 },
    { lat: 48.685598, lng: 20.738909 },
    { lat: 48.685229, lng: 20.739095 },
    { lat: 48.684898, lng: 20.739425 },
    { lat: 48.684311, lng: 20.73992 },
    { lat: 48.684033, lng: 20.740193 },
    { lat: 48.683663, lng: 20.740646 },
    { lat: 48.682987, lng: 20.741218 },
    { lat: 48.682441, lng: 20.741608 },
    { lat: 48.681945, lng: 20.741763 },
    { lat: 48.68163, lng: 20.742164 },
    { lat: 48.681438, lng: 20.742302 },
    { lat: 48.681097, lng: 20.742486 },
    { lat: 48.680225, lng: 20.742663 },
    { lat: 48.679631, lng: 20.742626 },
    { lat: 48.679021, lng: 20.742625 },
    { lat: 48.678543, lng: 20.74254 },
    { lat: 48.678312, lng: 20.742576 },
    { lat: 48.677923, lng: 20.742993 },
    { lat: 48.677563, lng: 20.743219 },
    { lat: 48.677228, lng: 20.743275 },
    { lat: 48.676819, lng: 20.74329 },
    { lat: 48.676465, lng: 20.743275 },
    { lat: 48.675878, lng: 20.743227 },
    { lat: 48.675558, lng: 20.742993 },
    { lat: 48.674766, lng: 20.742075 },
    { lat: 48.674488, lng: 20.741813 },
    { lat: 48.673862, lng: 20.74107 },
    { lat: 48.673596, lng: 20.740879 },
    { lat: 48.673437, lng: 20.740807 },
    { lat: 48.673364, lng: 20.740802 },
    { lat: 48.673676, lng: 20.7417938 },
    { lat: 48.6738379, lng: 20.7436297 },
    { lat: 48.6741907, lng: 20.745647 },
    { lat: 48.6748155, lng: 20.7469541 },
    { lat: 48.6749597, lng: 20.7476617 },
    { lat: 48.6753871, lng: 20.7490503 },
    { lat: 48.6755341, lng: 20.7498036 },
    { lat: 48.6764188, lng: 20.7516758 },
    { lat: 48.6765749, lng: 20.751923 },
    { lat: 48.676831, lng: 20.7521261 },
    { lat: 48.6769047, lng: 20.7523691 },
    { lat: 48.6772007, lng: 20.7526217 },
    { lat: 48.6773003, lng: 20.7528581 },
    { lat: 48.6774462, lng: 20.7529735 },
    { lat: 48.6775593, lng: 20.7533712 },
    { lat: 48.6779616, lng: 20.7541165 },
    { lat: 48.6781573, lng: 20.7542746 },
    { lat: 48.6784299, lng: 20.755005 },
    { lat: 48.6786561, lng: 20.7551649 },
    { lat: 48.6792779, lng: 20.755879 },
    { lat: 48.6809825, lng: 20.7572574 },
    { lat: 48.6810542, lng: 20.757599 },
    { lat: 48.681552, lng: 20.7578286 },
    { lat: 48.682129, lng: 20.75839 },
    { lat: 48.6821879, lng: 20.7585606 },
    { lat: 48.6825493, lng: 20.758814 },
    { lat: 48.682665, lng: 20.7589996 },
    { lat: 48.6829394, lng: 20.7597574 },
    { lat: 48.6830435, lng: 20.7604142 },
    { lat: 48.6831145, lng: 20.7605082 },
    { lat: 48.6830967, lng: 20.7606451 },
    { lat: 48.6831612, lng: 20.7608757 },
    { lat: 48.6831062, lng: 20.7610764 },
    { lat: 48.6832156, lng: 20.7613975 },
    { lat: 48.6830554, lng: 20.7626352 },
    { lat: 48.682821, lng: 20.7630452 },
    { lat: 48.6828737, lng: 20.7631375 },
    { lat: 48.682645, lng: 20.7639646 },
    { lat: 48.6823689, lng: 20.7646587 },
    { lat: 48.6823894, lng: 20.765113 },
    { lat: 48.6821406, lng: 20.7656253 },
    { lat: 48.682054, lng: 20.7662351 },
    { lat: 48.6819924, lng: 20.7662756 },
    { lat: 48.6819889, lng: 20.7664979 },
    { lat: 48.6817917, lng: 20.7669394 },
    { lat: 48.6817996, lng: 20.7670385 },
    { lat: 48.6816942, lng: 20.7671453 },
    { lat: 48.6816114, lng: 20.7673465 },
    { lat: 48.6815063, lng: 20.7673706 },
    { lat: 48.6815008, lng: 20.7675374 },
    { lat: 48.681118, lng: 20.7682036 },
    { lat: 48.6810358, lng: 20.7693609 },
    { lat: 48.6807628, lng: 20.7698893 },
    { lat: 48.6807972, lng: 20.7703166 },
    { lat: 48.6808794, lng: 20.7705422 },
    { lat: 48.680744, lng: 20.7711783 },
    { lat: 48.6809184, lng: 20.771719 },
    { lat: 48.6809233, lng: 20.7719519 },
    { lat: 48.6808493, lng: 20.7720619 },
    { lat: 48.6809181, lng: 20.772195 },
    { lat: 48.6809167, lng: 20.7724078 },
    { lat: 48.6806061, lng: 20.7723172 },
    { lat: 48.6804809, lng: 20.7727882 },
    { lat: 48.6805938, lng: 20.7730214 },
    { lat: 48.6805178, lng: 20.7732961 },
    { lat: 48.6806262, lng: 20.7737502 },
    { lat: 48.6805488, lng: 20.7738893 },
    { lat: 48.6807226, lng: 20.77528 },
    { lat: 48.6808993, lng: 20.7756616 },
    { lat: 48.6810866, lng: 20.776798 },
    { lat: 48.6810677, lng: 20.7773154 },
    { lat: 48.6811391, lng: 20.7781333 },
    { lat: 48.6809323, lng: 20.7785602 },
    { lat: 48.6809238, lng: 20.7790924 },
    { lat: 48.6809981, lng: 20.7793483 },
    { lat: 48.6810122, lng: 20.7803892 },
    { lat: 48.6810873, lng: 20.7808 },
    { lat: 48.6810603, lng: 20.7812824 },
    { lat: 48.6812007, lng: 20.7817225 },
    { lat: 48.6811899, lng: 20.7825032 },
    { lat: 48.6813325, lng: 20.7839431 },
    { lat: 48.6821911, lng: 20.786021 },
    { lat: 48.6825441, lng: 20.7871374 },
    { lat: 48.6829379, lng: 20.7875777 },
    { lat: 48.6832735, lng: 20.7887087 },
    { lat: 48.683551, lng: 20.789108 },
    { lat: 48.6838945, lng: 20.7899866 },
    { lat: 48.6841147, lng: 20.7902319 },
    { lat: 48.6845122, lng: 20.7915872 },
    { lat: 48.6846995, lng: 20.7918587 },
    { lat: 48.6849613, lng: 20.7926469 },
    { lat: 48.685527, lng: 20.7934967 },
    { lat: 48.6860077, lng: 20.7940506 },
    { lat: 48.6861432, lng: 20.7943825 },
    { lat: 48.686811, lng: 20.7949692 },
    { lat: 48.6871635, lng: 20.7953904 },
    { lat: 48.6876885, lng: 20.7962132 },
    { lat: 48.6887197, lng: 20.7974722 },
    { lat: 48.6892182, lng: 20.7976901 },
    { lat: 48.6896944, lng: 20.79827 },
    { lat: 48.6897086, lng: 20.79842 },
    { lat: 48.6901583, lng: 20.7989813 },
    { lat: 48.6905501, lng: 20.798886 },
    { lat: 48.6908186, lng: 20.7990043 },
    { lat: 48.6913153, lng: 20.7993646 },
    { lat: 48.6922593, lng: 20.8003527 },
    { lat: 48.6925991, lng: 20.8006046 },
    { lat: 48.6928206, lng: 20.8012401 },
    { lat: 48.6934454, lng: 20.8017584 },
    { lat: 48.6941774, lng: 20.8024784 },
    { lat: 48.6946692, lng: 20.802827 },
    { lat: 48.6950012, lng: 20.803277 },
    { lat: 48.6954889, lng: 20.8036045 },
    { lat: 48.6958777, lng: 20.8040725 },
    { lat: 48.6959854, lng: 20.8043554 },
    { lat: 48.6964781, lng: 20.8047585 },
    { lat: 48.6965965, lng: 20.804929 },
    { lat: 48.6966267, lng: 20.8052117 },
    { lat: 48.6974072, lng: 20.8059358 },
    { lat: 48.6975121, lng: 20.8062998 },
    { lat: 48.6976936, lng: 20.8064372 },
    { lat: 48.6979613, lng: 20.807139 },
    { lat: 48.6982353, lng: 20.8074077 },
    { lat: 48.6985684, lng: 20.808121 },
    { lat: 48.6988459, lng: 20.8085241 },
    { lat: 48.6992174, lng: 20.809234 },
    { lat: 48.6993343, lng: 20.8092633 },
    { lat: 48.6994298, lng: 20.8095133 },
    { lat: 48.6997822, lng: 20.8101047 },
    { lat: 48.7000394, lng: 20.8107297 },
    { lat: 48.7001579, lng: 20.8107719 },
    { lat: 48.7001991, lng: 20.810955 },
    { lat: 48.7000765, lng: 20.8111519 },
    { lat: 48.7002552, lng: 20.811596 },
    { lat: 48.7002884, lng: 20.8122255 },
    { lat: 48.7003638, lng: 20.8122923 },
    { lat: 48.7005614, lng: 20.8130228 },
    { lat: 48.7008431, lng: 20.8144206 },
    { lat: 48.7011838, lng: 20.815219 },
    { lat: 48.7013439, lng: 20.8158748 },
    { lat: 48.7015906, lng: 20.8163685 },
    { lat: 48.7018306, lng: 20.8172817 },
    { lat: 48.7019225, lng: 20.8173942 },
    { lat: 48.7020787, lng: 20.8178102 },
    { lat: 48.7022348, lng: 20.8185776 },
    { lat: 48.7022797, lng: 20.8185783 },
    { lat: 48.7026451, lng: 20.8209909 },
    { lat: 48.7028477, lng: 20.8211267 },
    { lat: 48.7028991, lng: 20.8215011 },
    { lat: 48.7032051, lng: 20.8218572 },
    { lat: 48.7032219, lng: 20.8221099 },
    { lat: 48.7034363, lng: 20.8222248 },
    { lat: 48.7034946, lng: 20.8223809 },
    { lat: 48.7034366, lng: 20.8225848 },
    { lat: 48.7035768, lng: 20.8226089 },
    { lat: 48.7039121, lng: 20.8230261 },
    { lat: 48.7038652, lng: 20.8233955 },
    { lat: 48.7041682, lng: 20.8240782 },
    { lat: 48.7042969, lng: 20.8249009 },
    { lat: 48.7043762, lng: 20.8249132 },
    { lat: 48.7051507, lng: 20.8273211 },
    { lat: 48.7051882, lng: 20.8274625 },
    { lat: 48.7050843, lng: 20.827841 },
    { lat: 48.7045847, lng: 20.8285972 },
    { lat: 48.704333, lng: 20.8291965 },
    { lat: 48.7043304, lng: 20.8294776 },
    { lat: 48.7041765, lng: 20.830168 },
    { lat: 48.7039283, lng: 20.8309299 },
    { lat: 48.7038798, lng: 20.8318404 },
    { lat: 48.7037412, lng: 20.8325859 },
    { lat: 48.7038589, lng: 20.8328332 },
    { lat: 48.7041678, lng: 20.8331695 },
    { lat: 48.7042524, lng: 20.8334404 },
    { lat: 48.704168, lng: 20.8337956 },
    { lat: 48.7041805, lng: 20.8341229 },
    { lat: 48.7040793, lng: 20.8343907 },
    { lat: 48.7040592, lng: 20.8348181 },
    { lat: 48.7038672, lng: 20.8347374 },
    { lat: 48.7037198, lng: 20.8347997 },
    { lat: 48.7035229, lng: 20.8352931 },
    { lat: 48.7035798, lng: 20.8354379 },
    { lat: 48.7038672, lng: 20.8355147 },
    { lat: 48.7040255, lng: 20.8356316 },
    { lat: 48.7040052, lng: 20.8361838 },
    { lat: 48.7029311, lng: 20.8376135 },
    { lat: 48.7024852, lng: 20.8379334 },
    { lat: 48.7022262, lng: 20.8387179 },
    { lat: 48.7018764, lng: 20.8406471 },
    { lat: 48.7018942, lng: 20.8409714 },
    { lat: 48.7019067, lng: 20.8410998 },
  ],
  MaláLodina: [
    { lat: 48.862621, lng: 21.041679 },
    { lat: 48.862427, lng: 21.041774 },
    { lat: 48.861983, lng: 21.042375 },
    { lat: 48.861849, lng: 21.042695 },
    { lat: 48.861796, lng: 21.043096 },
    { lat: 48.861887, lng: 21.043735 },
    { lat: 48.861896, lng: 21.044209 },
    { lat: 48.861796, lng: 21.044919 },
    { lat: 48.861768, lng: 21.045361 },
    { lat: 48.861813, lng: 21.047051 },
    { lat: 48.861727, lng: 21.047809 },
    { lat: 48.861631, lng: 21.048359 },
    { lat: 48.861507, lng: 21.048927 },
    { lat: 48.861429, lng: 21.049239 },
    { lat: 48.861173, lng: 21.049765 },
    { lat: 48.860923, lng: 21.050668 },
    { lat: 48.86071, lng: 21.051604 },
    { lat: 48.860434, lng: 21.052323 },
    { lat: 48.860337, lng: 21.053071 },
    { lat: 48.860088, lng: 21.054209 },
    { lat: 48.859815, lng: 21.054923 },
    { lat: 48.859726, lng: 21.05529 },
    { lat: 48.859424, lng: 21.056111 },
    { lat: 48.859227, lng: 21.056777 },
    { lat: 48.85909, lng: 21.057848 },
    { lat: 48.858888, lng: 21.058571 },
    { lat: 48.858687, lng: 21.059023 },
    { lat: 48.858386, lng: 21.061106 },
    { lat: 48.857069, lng: 21.061788 },
    { lat: 48.856847, lng: 21.061724 },
    { lat: 48.85647, lng: 21.061839 },
    { lat: 48.856061, lng: 21.06186 },
    { lat: 48.855563, lng: 21.061405 },
    { lat: 48.855159, lng: 21.061205 },
    { lat: 48.854217, lng: 21.061043 },
    { lat: 48.853435, lng: 21.060992 },
    { lat: 48.853063, lng: 21.061045 },
    { lat: 48.852688, lng: 21.06115 },
    { lat: 48.852223, lng: 21.061187 },
    { lat: 48.850749, lng: 21.06088 },
    { lat: 48.850434, lng: 21.061065 },
    { lat: 48.849066, lng: 21.062419 },
    { lat: 48.848829, lng: 21.062649 },
    { lat: 48.848455, lng: 21.063286 },
    { lat: 48.848374, lng: 21.063615 },
    { lat: 48.848362, lng: 21.063953 },
    { lat: 48.848457, lng: 21.064403 },
    { lat: 48.848681, lng: 21.06495 },
    { lat: 48.848888, lng: 21.065512 },
    { lat: 48.849196, lng: 21.06625 },
    { lat: 48.850294, lng: 21.069095 },
    { lat: 48.8512115, lng: 21.070548 },
    { lat: 48.8522603, lng: 21.0726963 },
    { lat: 48.8526492, lng: 21.0740994 },
    { lat: 48.8528468, lng: 21.0756518 },
    { lat: 48.8526365, lng: 21.0768039 },
    { lat: 48.8527439, lng: 21.0776894 },
    { lat: 48.8527391, lng: 21.0783777 },
    { lat: 48.8529165, lng: 21.0798973 },
    { lat: 48.852996, lng: 21.0816179 },
    { lat: 48.8530986, lng: 21.0825674 },
    { lat: 48.8536188, lng: 21.0840408 },
    { lat: 48.8549241, lng: 21.0864343 },
    { lat: 48.8541636, lng: 21.0873488 },
    { lat: 48.8538036, lng: 21.0876385 },
    { lat: 48.8529793, lng: 21.0884898 },
    { lat: 48.852859, lng: 21.0888132 },
    { lat: 48.8527336, lng: 21.0896701 },
    { lat: 48.8525111, lng: 21.0899943 },
    { lat: 48.8508094, lng: 21.0907604 },
    { lat: 48.8501812, lng: 21.0912722 },
    { lat: 48.8498214, lng: 21.0914307 },
    { lat: 48.8490841, lng: 21.0912812 },
    { lat: 48.8485731, lng: 21.0914588 },
    { lat: 48.8477917, lng: 21.0922831 },
    { lat: 48.8465432, lng: 21.0925545 },
    { lat: 48.846353, lng: 21.0926352 },
    { lat: 48.8461914, lng: 21.0927038 },
    { lat: 48.8459047, lng: 21.0930652 },
    { lat: 48.845606, lng: 21.093886 },
    { lat: 48.8455073, lng: 21.0944257 },
    { lat: 48.8450229, lng: 21.0959129 },
    { lat: 48.8446479, lng: 21.0964595 },
    { lat: 48.8444027, lng: 21.0967109 },
    { lat: 48.8444347, lng: 21.0968708 },
    { lat: 48.8441633, lng: 21.0971417 },
    { lat: 48.8442569, lng: 21.0973559 },
    { lat: 48.8443309, lng: 21.0978007 },
    { lat: 48.8436716, lng: 21.0980888 },
    { lat: 48.8435699, lng: 21.0988141 },
    { lat: 48.8430395, lng: 21.0991856 },
    { lat: 48.8430488, lng: 21.1001071 },
    { lat: 48.8428173, lng: 21.1007088 },
    { lat: 48.8426154, lng: 21.101011 },
    { lat: 48.8424983, lng: 21.1013566 },
    { lat: 48.8427369, lng: 21.1017066 },
    { lat: 48.8426442, lng: 21.1020443 },
    { lat: 48.8425027, lng: 21.1021609 },
    { lat: 48.8422682, lng: 21.1021517 },
    { lat: 48.8422126, lng: 21.1027622 },
    { lat: 48.8419517, lng: 21.1033999 },
    { lat: 48.8416499, lng: 21.1034661 },
    { lat: 48.8416327, lng: 21.1036289 },
    { lat: 48.8419386, lng: 21.1044302 },
    { lat: 48.8416958, lng: 21.1045807 },
    { lat: 48.8414325, lng: 21.1049299 },
    { lat: 48.8413701, lng: 21.1051582 },
    { lat: 48.8403317, lng: 21.1054513 },
    { lat: 48.8401905, lng: 21.1058204 },
    { lat: 48.839854, lng: 21.1063454 },
    { lat: 48.8395634, lng: 21.1064833 },
    { lat: 48.8391791, lng: 21.1068818 },
    { lat: 48.8390092, lng: 21.1075167 },
    { lat: 48.8388094, lng: 21.1074656 },
    { lat: 48.8386551, lng: 21.1076031 },
    { lat: 48.8386327, lng: 21.1075651 },
    { lat: 48.8386401, lng: 21.1072395 },
    { lat: 48.839025, lng: 21.1059396 },
    { lat: 48.8390159, lng: 21.1051632 },
    { lat: 48.8391866, lng: 21.1043181 },
    { lat: 48.8393202, lng: 21.1031485 },
    { lat: 48.8393001, lng: 21.1028385 },
    { lat: 48.8397111, lng: 21.1027049 },
    { lat: 48.839868, lng: 21.1023703 },
    { lat: 48.8396695, lng: 21.1019384 },
    { lat: 48.8393423, lng: 21.1019139 },
    { lat: 48.8388588, lng: 21.1030146 },
    { lat: 48.8382682, lng: 21.1044566 },
    { lat: 48.8377683, lng: 21.1050093 },
    { lat: 48.8374304, lng: 21.1057759 },
    { lat: 48.8368473, lng: 21.1073777 },
    { lat: 48.8364988, lng: 21.1078799 },
    { lat: 48.8360231, lng: 21.1102093 },
    { lat: 48.8354472, lng: 21.1112012 },
    { lat: 48.8351874, lng: 21.1117991 },
    { lat: 48.8347698, lng: 21.11236 },
    { lat: 48.8345257, lng: 21.1129938 },
    { lat: 48.8342523, lng: 21.1134176 },
    { lat: 48.8338807, lng: 21.1141494 },
    { lat: 48.833573, lng: 21.1151873 },
    { lat: 48.8331023, lng: 21.1159164 },
    { lat: 48.8330146, lng: 21.1162051 },
    { lat: 48.832859, lng: 21.1172453 },
    { lat: 48.8328656, lng: 21.1177367 },
    { lat: 48.8323351, lng: 21.1202913 },
    { lat: 48.8327818, lng: 21.1208808 },
    { lat: 48.8331772, lng: 21.1215994 },
    { lat: 48.8332756, lng: 21.1218966 },
    { lat: 48.8332911, lng: 21.1224463 },
    { lat: 48.8336455, lng: 21.123184 },
    { lat: 48.8337802, lng: 21.1233464 },
    { lat: 48.833927, lng: 21.1234034 },
    { lat: 48.834148, lng: 21.1238363 },
    { lat: 48.8342187, lng: 21.1246767 },
    { lat: 48.8349342, lng: 21.125548 },
    { lat: 48.8357616, lng: 21.1258024 },
    { lat: 48.8357183, lng: 21.1256409 },
    { lat: 48.8357841, lng: 21.1256568 },
    { lat: 48.8363897, lng: 21.1267556 },
    { lat: 48.8384918, lng: 21.1298834 },
    { lat: 48.8394365, lng: 21.130673 },
    { lat: 48.8397633, lng: 21.1311432 },
    { lat: 48.8402841, lng: 21.1315231 },
    { lat: 48.8405133, lng: 21.1318979 },
    { lat: 48.8407774, lng: 21.1321054 },
    { lat: 48.8411518, lng: 21.1326204 },
    { lat: 48.8413639, lng: 21.1333759 },
    { lat: 48.8414588, lng: 21.1340953 },
    { lat: 48.8415487, lng: 21.1341941 },
    { lat: 48.8415773, lng: 21.1344918 },
    { lat: 48.8417385, lng: 21.1347855 },
    { lat: 48.8418422, lng: 21.1348258 },
    { lat: 48.841867, lng: 21.1349979 },
    { lat: 48.842143, lng: 21.135081 },
    { lat: 48.8422739, lng: 21.1353412 },
    { lat: 48.842823, lng: 21.1357216 },
    { lat: 48.8429597, lng: 21.1359477 },
    { lat: 48.8430471, lng: 21.1359371 },
    { lat: 48.8431647, lng: 21.1360621 },
    { lat: 48.8432474, lng: 21.1360064 },
    { lat: 48.8433103, lng: 21.1362186 },
    { lat: 48.8439626, lng: 21.1355689 },
    { lat: 48.8442072, lng: 21.135119 },
    { lat: 48.8442966, lng: 21.1347473 },
    { lat: 48.8445452, lng: 21.1343196 },
    { lat: 48.8445554, lng: 21.1340034 },
    { lat: 48.8447036, lng: 21.1337489 },
    { lat: 48.8453858, lng: 21.1332223 },
    { lat: 48.845511, lng: 21.1330573 },
    { lat: 48.8457067, lng: 21.1330205 },
    { lat: 48.8457234, lng: 21.1329364 },
    { lat: 48.845818, lng: 21.1328712 },
    { lat: 48.845823, lng: 21.1327111 },
    { lat: 48.8460505, lng: 21.1324697 },
    { lat: 48.8462199, lng: 21.1324144 },
    { lat: 48.8462447, lng: 21.1322071 },
    { lat: 48.8466943, lng: 21.131377 },
    { lat: 48.8475505, lng: 21.1323928 },
    { lat: 48.8481462, lng: 21.1324002 },
    { lat: 48.8491252, lng: 21.1328596 },
    { lat: 48.8496908, lng: 21.1337951 },
    { lat: 48.8502037, lng: 21.1345255 },
    { lat: 48.8518755, lng: 21.1393541 },
    { lat: 48.8524634, lng: 21.1392568 },
    { lat: 48.8529428, lng: 21.1389058 },
    { lat: 48.8530904, lng: 21.1386241 },
    { lat: 48.8532294, lng: 21.1385003 },
    { lat: 48.853286, lng: 21.1381621 },
    { lat: 48.8534923, lng: 21.1376409 },
    { lat: 48.8538397, lng: 21.137028 },
    { lat: 48.8540041, lng: 21.136397 },
    { lat: 48.8541981, lng: 21.1359508 },
    { lat: 48.854419, lng: 21.1356458 },
    { lat: 48.8546555, lng: 21.1354724 },
    { lat: 48.8548375, lng: 21.1351385 },
    { lat: 48.8549798, lng: 21.1347353 },
    { lat: 48.8552618, lng: 21.1342955 },
    { lat: 48.8562584, lng: 21.1335049 },
    { lat: 48.8566491, lng: 21.1333683 },
    { lat: 48.856888, lng: 21.1331629 },
    { lat: 48.8576588, lng: 21.1322769 },
    { lat: 48.8583304, lng: 21.1313145 },
    { lat: 48.8585787, lng: 21.1310551 },
    { lat: 48.8587523, lng: 21.1310613 },
    { lat: 48.8589718, lng: 21.1308919 },
    { lat: 48.85933, lng: 21.1308682 },
    { lat: 48.860975, lng: 21.130995 },
    { lat: 48.8615551, lng: 21.1308317 },
    { lat: 48.8617485, lng: 21.1306849 },
    { lat: 48.8620236, lng: 21.1305615 },
    { lat: 48.8623521, lng: 21.1307325 },
    { lat: 48.8629077, lng: 21.1307598 },
    { lat: 48.8637723, lng: 21.1311543 },
    { lat: 48.865744, lng: 21.1323871 },
    { lat: 48.8662034, lng: 21.132504 },
    { lat: 48.8666446, lng: 21.1328874 },
    { lat: 48.8671257, lng: 21.1328747 },
    { lat: 48.8698299, lng: 21.1406796 },
    { lat: 48.8702149, lng: 21.1409354 },
    { lat: 48.8703565, lng: 21.1411395 },
    { lat: 48.870432, lng: 21.1413556 },
    { lat: 48.8703928, lng: 21.1418575 },
    { lat: 48.8706368, lng: 21.1424485 },
    { lat: 48.8704688, lng: 21.1430103 },
    { lat: 48.8705077, lng: 21.1434832 },
    { lat: 48.8708454, lng: 21.1436674 },
    { lat: 48.8709237, lng: 21.1437964 },
    { lat: 48.8710342, lng: 21.1445037 },
    { lat: 48.8711268, lng: 21.144621 },
    { lat: 48.8711705, lng: 21.1449724 },
    { lat: 48.8712399, lng: 21.1451379 },
    { lat: 48.8712711, lng: 21.1456597 },
    { lat: 48.8715072, lng: 21.1458963 },
    { lat: 48.8716572, lng: 21.1459429 },
    { lat: 48.8715407, lng: 21.1454316 },
    { lat: 48.8715483, lng: 21.1451708 },
    { lat: 48.8717382, lng: 21.1447347 },
    { lat: 48.8722606, lng: 21.1442175 },
    { lat: 48.8731079, lng: 21.1436523 },
    { lat: 48.873441, lng: 21.1432626 },
    { lat: 48.8740685, lng: 21.1444126 },
    { lat: 48.8747119, lng: 21.144932 },
    { lat: 48.8752429, lng: 21.1451102 },
    { lat: 48.87648, lng: 21.1459992 },
    { lat: 48.8768017, lng: 21.1459621 },
    { lat: 48.8773246, lng: 21.1461263 },
    { lat: 48.8780101, lng: 21.1464442 },
    { lat: 48.8783761, lng: 21.1467436 },
    { lat: 48.8785992, lng: 21.1467784 },
    { lat: 48.878752, lng: 21.1470233 },
    { lat: 48.8789502, lng: 21.14717 },
    { lat: 48.8793442, lng: 21.147634 },
    { lat: 48.8795527, lng: 21.1481036 },
    { lat: 48.8795501, lng: 21.1482379 },
    { lat: 48.879629, lng: 21.1483444 },
    { lat: 48.8796256, lng: 21.1484551 },
    { lat: 48.880019, lng: 21.1489671 },
    { lat: 48.8835947, lng: 21.1515669 },
    { lat: 48.8839653, lng: 21.1510107 },
    { lat: 48.8847353, lng: 21.1502397 },
    { lat: 48.8851011, lng: 21.1496373 },
    { lat: 48.8896842, lng: 21.1504639 },
    { lat: 48.8899992, lng: 21.1502475 },
    { lat: 48.8912197, lng: 21.1510829 },
    { lat: 48.8914538, lng: 21.1511177 },
    { lat: 48.892353, lng: 21.1520117 },
    { lat: 48.8935348, lng: 21.1527582 },
    { lat: 48.8939782, lng: 21.1529383 },
    { lat: 48.8950424, lng: 21.153634 },
    { lat: 48.8949768, lng: 21.1540795 },
    { lat: 48.8949906, lng: 21.1542684 },
    { lat: 48.8947949, lng: 21.1547315 },
    { lat: 48.8950962, lng: 21.1552213 },
    { lat: 48.895331, lng: 21.155471 },
    { lat: 48.895361, lng: 21.155341 },
    { lat: 48.895436, lng: 21.155148 },
    { lat: 48.895568, lng: 21.154775 },
    { lat: 48.895534, lng: 21.154613 },
    { lat: 48.89547, lng: 21.154505 },
    { lat: 48.89552, lng: 21.154283 },
    { lat: 48.895463, lng: 21.154185 },
    { lat: 48.895331, lng: 21.154173 },
    { lat: 48.89532, lng: 21.154063 },
    { lat: 48.895326, lng: 21.154014 },
    { lat: 48.895446, lng: 21.153926 },
    { lat: 48.895464, lng: 21.153897 },
    { lat: 48.895472, lng: 21.153866 },
    { lat: 48.895445, lng: 21.153637 },
    { lat: 48.895442, lng: 21.153557 },
    { lat: 48.895516, lng: 21.153197 },
    { lat: 48.895542, lng: 21.153048 },
    { lat: 48.895577, lng: 21.152821 },
    { lat: 48.895587, lng: 21.152791 },
    { lat: 48.895697, lng: 21.152636 },
    { lat: 48.895786, lng: 21.152509 },
    { lat: 48.895976, lng: 21.152342 },
    { lat: 48.8960331, lng: 21.1521125 },
    { lat: 48.8961141, lng: 21.1517922 },
    { lat: 48.89611, lng: 21.151777 },
    { lat: 48.896009, lng: 21.151744 },
    { lat: 48.895932, lng: 21.151887 },
    { lat: 48.89586, lng: 21.151895 },
    { lat: 48.895803, lng: 21.151885 },
    { lat: 48.895687, lng: 21.151633 },
    { lat: 48.8957338, lng: 21.1514393 },
    { lat: 48.895904, lng: 21.151297 },
    { lat: 48.896026, lng: 21.15136 },
    { lat: 48.896082, lng: 21.151351 },
    { lat: 48.8961547, lng: 21.1512631 },
    { lat: 48.896181, lng: 21.151126 },
    { lat: 48.896192, lng: 21.151104 },
    { lat: 48.8962817, lng: 21.1510431 },
    { lat: 48.896402, lng: 21.150986 },
    { lat: 48.8964917, lng: 21.1508873 },
    { lat: 48.896634, lng: 21.1507 },
    { lat: 48.896659, lng: 21.150448 },
    { lat: 48.896596, lng: 21.15029 },
    { lat: 48.896388, lng: 21.149982 },
    { lat: 48.896309, lng: 21.149747 },
    { lat: 48.896342, lng: 21.149729 },
    { lat: 48.896484, lng: 21.149699 },
    { lat: 48.896661, lng: 21.14971 },
    { lat: 48.89676, lng: 21.149568 },
    { lat: 48.89665, lng: 21.149317 },
    { lat: 48.896552, lng: 21.149181 },
    { lat: 48.89642, lng: 21.14906 },
    { lat: 48.89638, lng: 21.149051 },
    { lat: 48.896357, lng: 21.148687 },
    { lat: 48.896361, lng: 21.148504 },
    { lat: 48.896347, lng: 21.148371 },
    { lat: 48.896361, lng: 21.148187 },
    { lat: 48.896371, lng: 21.148027 },
    { lat: 48.896375, lng: 21.147866 },
    { lat: 48.896387, lng: 21.147708 },
    { lat: 48.896459, lng: 21.147415 },
    { lat: 48.896501, lng: 21.147402 },
    { lat: 48.896561, lng: 21.147392 },
    { lat: 48.896585, lng: 21.147408 },
    { lat: 48.896648, lng: 21.147512 },
    { lat: 48.896796, lng: 21.147578 },
    { lat: 48.896888, lng: 21.147501 },
    { lat: 48.896915, lng: 21.147443 },
    { lat: 48.896877, lng: 21.14725 },
    { lat: 48.896966, lng: 21.146962 },
    { lat: 48.896897, lng: 21.146888 },
    { lat: 48.896858, lng: 21.146692 },
    { lat: 48.896865, lng: 21.146667 },
    { lat: 48.897101, lng: 21.146325 },
    { lat: 48.897093, lng: 21.146255 },
    { lat: 48.897041, lng: 21.146022 },
    { lat: 48.897047, lng: 21.14599 },
    { lat: 48.897199, lng: 21.145822 },
    { lat: 48.897272, lng: 21.14579 },
    { lat: 48.897261, lng: 21.145736 },
    { lat: 48.897462, lng: 21.14557 },
    { lat: 48.897518, lng: 21.145525 },
    { lat: 48.897723, lng: 21.145355 },
    { lat: 48.89763, lng: 21.145121 },
    { lat: 48.897665, lng: 21.144966 },
    { lat: 48.897724, lng: 21.144909 },
    { lat: 48.897751, lng: 21.144926 },
    { lat: 48.89794, lng: 21.145047 },
    { lat: 48.898045, lng: 21.144669 },
    { lat: 48.89799, lng: 21.14463 },
    { lat: 48.897955, lng: 21.14456 },
    { lat: 48.898008, lng: 21.144443 },
    { lat: 48.898041, lng: 21.144144 },
    { lat: 48.89806, lng: 21.144116 },
    { lat: 48.89801, lng: 21.144047 },
    { lat: 48.897973, lng: 21.143959 },
    { lat: 48.897941, lng: 21.14389 },
    { lat: 48.897847, lng: 21.143516 },
    { lat: 48.897803, lng: 21.143408 },
    { lat: 48.897779, lng: 21.143329 },
    { lat: 48.897769, lng: 21.14329 },
    { lat: 48.897766, lng: 21.142465 },
    { lat: 48.897983, lng: 21.142408 },
    { lat: 48.8981, lng: 21.142418 },
    { lat: 48.898113, lng: 21.142389 },
    { lat: 48.898073, lng: 21.142207 },
    { lat: 48.898023, lng: 21.142188 },
    { lat: 48.897841, lng: 21.141978 },
    { lat: 48.897785, lng: 21.141867 },
    { lat: 48.897798, lng: 21.1418 },
    { lat: 48.897833, lng: 21.141656 },
    { lat: 48.897947, lng: 21.141704 },
    { lat: 48.898037, lng: 21.141714 },
    { lat: 48.89811, lng: 21.141554 },
    { lat: 48.898149, lng: 21.141383 },
    { lat: 48.898107, lng: 21.141218 },
    { lat: 48.898278, lng: 21.141049 },
    { lat: 48.898416, lng: 21.140808 },
    { lat: 48.898447, lng: 21.140578 },
    { lat: 48.898479, lng: 21.140496 },
    { lat: 48.898625, lng: 21.140627 },
    { lat: 48.898653, lng: 21.14052 },
    { lat: 48.898846, lng: 21.140277 },
    { lat: 48.898894, lng: 21.140132 },
    { lat: 48.898912, lng: 21.140064 },
    { lat: 48.898932, lng: 21.140068 },
    { lat: 48.89904, lng: 21.140068 },
    { lat: 48.898946, lng: 21.139854 },
    { lat: 48.899117, lng: 21.139574 },
    { lat: 48.899152, lng: 21.139483 },
    { lat: 48.899198, lng: 21.139405 },
    { lat: 48.899237, lng: 21.139316 },
    { lat: 48.899242, lng: 21.139202 },
    { lat: 48.899393, lng: 21.13917 },
    { lat: 48.899487, lng: 21.13918 },
    { lat: 48.899694, lng: 21.138817 },
    { lat: 48.899662, lng: 21.138728 },
    { lat: 48.899552, lng: 21.138641 },
    { lat: 48.899413, lng: 21.138514 },
    { lat: 48.899511, lng: 21.138218 },
    { lat: 48.899629, lng: 21.138055 },
    { lat: 48.899687, lng: 21.137986 },
    { lat: 48.899839, lng: 21.138036 },
    { lat: 48.899882, lng: 21.137931 },
    { lat: 48.899912, lng: 21.137794 },
    { lat: 48.89998, lng: 21.137563 },
    { lat: 48.900177, lng: 21.137715 },
    { lat: 48.900287, lng: 21.13759 },
    { lat: 48.900316, lng: 21.137313 },
    { lat: 48.900397, lng: 21.137195 },
    { lat: 48.900572, lng: 21.13727 },
    { lat: 48.900594, lng: 21.13719 },
    { lat: 48.900697, lng: 21.136829 },
    { lat: 48.9006, lng: 21.136486 },
    { lat: 48.900725, lng: 21.136347 },
    { lat: 48.900886, lng: 21.136365 },
    { lat: 48.90094, lng: 21.136044 },
    { lat: 48.900931, lng: 21.135871 },
    { lat: 48.901128, lng: 21.13588 },
    { lat: 48.90125, lng: 21.135691 },
    { lat: 48.901271, lng: 21.135538 },
    { lat: 48.901366, lng: 21.13541 },
    { lat: 48.901383, lng: 21.135309 },
    { lat: 48.901324, lng: 21.135196 },
    { lat: 48.901394, lng: 21.135073 },
    { lat: 48.901436, lng: 21.134934 },
    { lat: 48.901412, lng: 21.134764 },
    { lat: 48.901397, lng: 21.134706 },
    { lat: 48.901324, lng: 21.134683 },
    { lat: 48.901235, lng: 21.134703 },
    { lat: 48.901183, lng: 21.134527 },
    { lat: 48.901098, lng: 21.13446 },
    { lat: 48.901223, lng: 21.134347 },
    { lat: 48.901428, lng: 21.133649 },
    { lat: 48.9014, lng: 21.1334 },
    { lat: 48.901478, lng: 21.133242 },
    { lat: 48.901589, lng: 21.133073 },
    { lat: 48.901593, lng: 21.132965 },
    { lat: 48.901617, lng: 21.132496 },
    { lat: 48.901632, lng: 21.132202 },
    { lat: 48.901584, lng: 21.131976 },
    { lat: 48.901579, lng: 21.131954 },
    { lat: 48.901572, lng: 21.131943 },
    { lat: 48.901478, lng: 21.131807 },
    { lat: 48.901384, lng: 21.131657 },
    { lat: 48.901296, lng: 21.131552 },
    { lat: 48.9012, lng: 21.131568 },
    { lat: 48.901108, lng: 21.131515 },
    { lat: 48.901044, lng: 21.131451 },
    { lat: 48.900983, lng: 21.131433 },
    { lat: 48.900771, lng: 21.13137 },
    { lat: 48.900689, lng: 21.131341 },
    { lat: 48.900627, lng: 21.13129 },
    { lat: 48.900561, lng: 21.131342 },
    { lat: 48.900588, lng: 21.130577 },
    { lat: 48.900416, lng: 21.129221 },
    { lat: 48.900282, lng: 21.127675 },
    { lat: 48.90022, lng: 21.126053 },
    { lat: 48.900074, lng: 21.125537 },
    { lat: 48.899894, lng: 21.124476 },
    { lat: 48.89988, lng: 21.124395 },
    { lat: 48.899671, lng: 21.123157 },
    { lat: 48.899438, lng: 21.122402 },
    { lat: 48.899219, lng: 21.12142 },
    { lat: 48.898546, lng: 21.120646 },
    { lat: 48.898277, lng: 21.120138 },
    { lat: 48.89811, lng: 21.119758 },
    { lat: 48.898031, lng: 21.119447 },
    { lat: 48.897701, lng: 21.11877 },
    { lat: 48.897282, lng: 21.118276 },
    { lat: 48.895961, lng: 21.118258 },
    { lat: 48.895542, lng: 21.118427 },
    { lat: 48.895043, lng: 21.118328 },
    { lat: 48.894922, lng: 21.118161 },
    { lat: 48.894886, lng: 21.11813 },
    { lat: 48.894866, lng: 21.118104 },
    { lat: 48.89491, lng: 21.117893 },
    { lat: 48.8953, lng: 21.117204 },
    { lat: 48.895452, lng: 21.116613 },
    { lat: 48.89552, lng: 21.114798 },
    { lat: 48.895587, lng: 21.114555 },
    { lat: 48.895894, lng: 21.113481 },
    { lat: 48.895981, lng: 21.113092 },
    { lat: 48.895982, lng: 21.112832 },
    { lat: 48.896003, lng: 21.112821 },
    { lat: 48.896021, lng: 21.112807 },
    { lat: 48.896071, lng: 21.112765 },
    { lat: 48.896753, lng: 21.11229 },
    { lat: 48.897043, lng: 21.109925 },
    { lat: 48.896695, lng: 21.109606 },
    { lat: 48.896824, lng: 21.107989 },
    { lat: 48.897232, lng: 21.106164 },
    { lat: 48.897273, lng: 21.105791 },
    { lat: 48.896359, lng: 21.104108 },
    { lat: 48.896135, lng: 21.103353 },
    { lat: 48.895991, lng: 21.102993 },
    { lat: 48.895731, lng: 21.102152 },
    { lat: 48.895613, lng: 21.101962 },
    { lat: 48.89529, lng: 21.101438 },
    { lat: 48.895107, lng: 21.101042 },
    { lat: 48.894937, lng: 21.1006 },
    { lat: 48.894733, lng: 21.100166 },
    { lat: 48.894549, lng: 21.099845 },
    { lat: 48.894212, lng: 21.099252 },
    { lat: 48.893963, lng: 21.098703 },
    { lat: 48.893938, lng: 21.098667 },
    { lat: 48.893926, lng: 21.098652 },
    { lat: 48.894049, lng: 21.09838 },
    { lat: 48.894217, lng: 21.098143 },
    { lat: 48.894245, lng: 21.098088 },
    { lat: 48.894344, lng: 21.097733 },
    { lat: 48.894511, lng: 21.097356 },
    { lat: 48.894658, lng: 21.096964 },
    { lat: 48.894952, lng: 21.096037 },
    { lat: 48.895149, lng: 21.095356 },
    { lat: 48.895234, lng: 21.095134 },
    { lat: 48.895372, lng: 21.094868 },
    { lat: 48.895353, lng: 21.094837 },
    { lat: 48.895358, lng: 21.094825 },
    { lat: 48.895427, lng: 21.094689 },
    { lat: 48.895518, lng: 21.094508 },
    { lat: 48.895764, lng: 21.094037 },
    { lat: 48.895938, lng: 21.093612 },
    { lat: 48.896066, lng: 21.093195 },
    { lat: 48.896285, lng: 21.092762 },
    { lat: 48.896588, lng: 21.092415 },
    { lat: 48.896603, lng: 21.09244 },
    { lat: 48.89682, lng: 21.09206 },
    { lat: 48.897004, lng: 21.091878 },
    { lat: 48.896997, lng: 21.091851 },
    { lat: 48.897193, lng: 21.091693 },
    { lat: 48.897331, lng: 21.091506 },
    { lat: 48.897402, lng: 21.091271 },
    { lat: 48.897557, lng: 21.090961 },
    { lat: 48.897754, lng: 21.090635 },
    { lat: 48.897913, lng: 21.090475 },
    { lat: 48.897929, lng: 21.09048 },
    { lat: 48.898145, lng: 21.089772 },
    { lat: 48.898343, lng: 21.089184 },
    { lat: 48.898462, lng: 21.08872 },
    { lat: 48.898455, lng: 21.088506 },
    { lat: 48.898385, lng: 21.08823 },
    { lat: 48.898362, lng: 21.087991 },
    { lat: 48.898382, lng: 21.087367 },
    { lat: 48.898425, lng: 21.087038 },
    { lat: 48.898377, lng: 21.086533 },
    { lat: 48.898379, lng: 21.08586 },
    { lat: 48.898326, lng: 21.085598 },
    { lat: 48.898465, lng: 21.084834 },
    { lat: 48.898443, lng: 21.084829 },
    { lat: 48.898392, lng: 21.084488 },
    { lat: 48.898288, lng: 21.084026 },
    { lat: 48.898259, lng: 21.083745 },
    { lat: 48.898225, lng: 21.083347 },
    { lat: 48.898148, lng: 21.082926 },
    { lat: 48.897813, lng: 21.082191 },
    { lat: 48.897684, lng: 21.082012 },
    { lat: 48.897602, lng: 21.081945 },
    { lat: 48.897426, lng: 21.08192 },
    { lat: 48.897253, lng: 21.081825 },
    { lat: 48.897198, lng: 21.081217 },
    { lat: 48.897212, lng: 21.081191 },
    { lat: 48.897141, lng: 21.081075 },
    { lat: 48.897055, lng: 21.080881 },
    { lat: 48.896975, lng: 21.080584 },
    { lat: 48.896896, lng: 21.080089 },
    { lat: 48.896779, lng: 21.079773 },
    { lat: 48.896693, lng: 21.079448 },
    { lat: 48.896577, lng: 21.07884 },
    { lat: 48.896519, lng: 21.078303 },
    { lat: 48.896548, lng: 21.078091 },
    { lat: 48.896579, lng: 21.077828 },
    { lat: 48.896657, lng: 21.077534 },
    { lat: 48.896925, lng: 21.076752 },
    { lat: 48.897063, lng: 21.076395 },
    { lat: 48.89713, lng: 21.076289 },
    { lat: 48.89723, lng: 21.076143 },
    { lat: 48.897447, lng: 21.075921 },
    { lat: 48.897566, lng: 21.075725 },
    { lat: 48.897672, lng: 21.075663 },
    { lat: 48.897794, lng: 21.075551 },
    { lat: 48.89804, lng: 21.075147 },
    { lat: 48.898179, lng: 21.074891 },
    { lat: 48.898577, lng: 21.073872 },
    { lat: 48.89868, lng: 21.073523 },
    { lat: 48.898767, lng: 21.073137 },
    { lat: 48.898865, lng: 21.072754 },
    { lat: 48.89891, lng: 21.072581 },
    { lat: 48.899089, lng: 21.071985 },
    { lat: 48.899234, lng: 21.071529 },
    { lat: 48.899432, lng: 21.070989 },
    { lat: 48.89965, lng: 21.070525 },
    { lat: 48.899952, lng: 21.070096 },
    { lat: 48.90016, lng: 21.069838 },
    { lat: 48.900558, lng: 21.069453 },
    { lat: 48.900987, lng: 21.069093 },
    { lat: 48.901322, lng: 21.068734 },
    { lat: 48.901809, lng: 21.068485 },
    { lat: 48.901998, lng: 21.068328 },
    { lat: 48.902075, lng: 21.068221 },
    { lat: 48.902303, lng: 21.067876 },
    { lat: 48.902684, lng: 21.067154 },
    { lat: 48.902981, lng: 21.066452 },
    { lat: 48.903155, lng: 21.066008 },
    { lat: 48.903232, lng: 21.065665 },
    { lat: 48.903284, lng: 21.065129 },
    { lat: 48.903283, lng: 21.064535 },
    { lat: 48.903388, lng: 21.063819 },
    { lat: 48.903518, lng: 21.063074 },
    { lat: 48.90386, lng: 21.062201 },
    { lat: 48.903968, lng: 21.06168 },
    { lat: 48.904113, lng: 21.061393 },
    { lat: 48.904225, lng: 21.061001 },
    { lat: 48.904367, lng: 21.060758 },
    { lat: 48.904645, lng: 21.060408 },
    { lat: 48.904887, lng: 21.060007 },
    { lat: 48.905069, lng: 21.05942 },
    { lat: 48.90538, lng: 21.058996 },
    { lat: 48.905519, lng: 21.058622 },
    { lat: 48.905532, lng: 21.058663 },
    { lat: 48.905665, lng: 21.058278 },
    { lat: 48.905685, lng: 21.058221 },
    { lat: 48.905686, lng: 21.058186 },
    { lat: 48.904855, lng: 21.056319 },
    { lat: 48.903756, lng: 21.055672 },
    { lat: 48.903456, lng: 21.055557 },
    { lat: 48.903156, lng: 21.055535 },
    { lat: 48.902954, lng: 21.055531 },
    { lat: 48.90254, lng: 21.055597 },
    { lat: 48.902418, lng: 21.055688 },
    { lat: 48.902421, lng: 21.05576 },
    { lat: 48.902181, lng: 21.05584 },
    { lat: 48.90191, lng: 21.056098 },
    { lat: 48.90175, lng: 21.056177 },
    { lat: 48.901531, lng: 21.05635 },
    { lat: 48.901411, lng: 21.056597 },
    { lat: 48.901264, lng: 21.056752 },
    { lat: 48.900859, lng: 21.057017 },
    { lat: 48.900569, lng: 21.057389 },
    { lat: 48.900426, lng: 21.057479 },
    { lat: 48.900114, lng: 21.057448 },
    { lat: 48.899582, lng: 21.057558 },
    { lat: 48.899205, lng: 21.057537 },
    { lat: 48.898986, lng: 21.057508 },
    { lat: 48.898702, lng: 21.05754 },
    { lat: 48.898426, lng: 21.057626 },
    { lat: 48.898063, lng: 21.057796 },
    { lat: 48.897883, lng: 21.057949 },
    { lat: 48.897707, lng: 21.058134 },
    { lat: 48.897447, lng: 21.058561 },
    { lat: 48.897294, lng: 21.058728 },
    { lat: 48.897081, lng: 21.05901 },
    { lat: 48.896826, lng: 21.059242 },
    { lat: 48.89662, lng: 21.059401 },
    { lat: 48.896258, lng: 21.059883 },
    { lat: 48.896164, lng: 21.059961 },
    { lat: 48.896121, lng: 21.06004 },
    { lat: 48.896101, lng: 21.060072 },
    { lat: 48.895887, lng: 21.060352 },
    { lat: 48.895768, lng: 21.060362 },
    { lat: 48.895584, lng: 21.060301 },
    { lat: 48.89515, lng: 21.060332 },
    { lat: 48.895163, lng: 21.06027 },
    { lat: 48.895048, lng: 21.060266 },
    { lat: 48.894881, lng: 21.060342 },
    { lat: 48.894463, lng: 21.060807 },
    { lat: 48.894298, lng: 21.060934 },
    { lat: 48.894061, lng: 21.061085 },
    { lat: 48.893924, lng: 21.061154 },
    { lat: 48.893586, lng: 21.061426 },
    { lat: 48.893208, lng: 21.0617 },
    { lat: 48.892892, lng: 21.061908 },
    { lat: 48.892911, lng: 21.061956 },
    { lat: 48.892801, lng: 21.062068 },
    { lat: 48.89254, lng: 21.062318 },
    { lat: 48.892112, lng: 21.062632 },
    { lat: 48.891924, lng: 21.062847 },
    { lat: 48.891608, lng: 21.063064 },
    { lat: 48.891604, lng: 21.063011 },
    { lat: 48.891321, lng: 21.063177 },
    { lat: 48.891055, lng: 21.063251 },
    { lat: 48.890922, lng: 21.06335 },
    { lat: 48.890654, lng: 21.063679 },
    { lat: 48.8905, lng: 21.063955 },
    { lat: 48.890079, lng: 21.064396 },
    { lat: 48.889882, lng: 21.064484 },
    { lat: 48.889502, lng: 21.064823 },
    { lat: 48.889277, lng: 21.064945 },
    { lat: 48.889091, lng: 21.064946 },
    { lat: 48.888646, lng: 21.064815 },
    { lat: 48.888272, lng: 21.064718 },
    { lat: 48.887854, lng: 21.064545 },
    { lat: 48.887608, lng: 21.064486 },
    { lat: 48.887432, lng: 21.064396 },
    { lat: 48.887218, lng: 21.06424 },
    { lat: 48.887065, lng: 21.064136 },
    { lat: 48.88688, lng: 21.064114 },
    { lat: 48.886671, lng: 21.064193 },
    { lat: 48.886297, lng: 21.064408 },
    { lat: 48.886082, lng: 21.064481 },
    { lat: 48.885946, lng: 21.064532 },
    { lat: 48.88579, lng: 21.064602 },
    { lat: 48.885599, lng: 21.064766 },
    { lat: 48.885462, lng: 21.064829 },
    { lat: 48.885269, lng: 21.064959 },
    { lat: 48.884986, lng: 21.0652 },
    { lat: 48.884503, lng: 21.065447 },
    { lat: 48.884384, lng: 21.065499 },
    { lat: 48.883976, lng: 21.065518 },
    { lat: 48.883455, lng: 21.065566 },
    { lat: 48.883269, lng: 21.065565 },
    { lat: 48.882884, lng: 21.065428 },
    { lat: 48.882762, lng: 21.065294 },
    { lat: 48.882622, lng: 21.065148 },
    { lat: 48.882472, lng: 21.065065 },
    { lat: 48.88232, lng: 21.065028 },
    { lat: 48.881705, lng: 21.065026 },
    { lat: 48.881552, lng: 21.065015 },
    { lat: 48.881447, lng: 21.064984 },
    { lat: 48.881296, lng: 21.065002 },
    { lat: 48.880695, lng: 21.065191 },
    { lat: 48.88036, lng: 21.065296 },
    { lat: 48.880218, lng: 21.065368 },
    { lat: 48.880122, lng: 21.065415 },
    { lat: 48.880061, lng: 21.065438 },
    { lat: 48.879984, lng: 21.065449 },
    { lat: 48.87976, lng: 21.06535 },
    { lat: 48.879556, lng: 21.065378 },
    { lat: 48.879296, lng: 21.065573 },
    { lat: 48.87915, lng: 21.065592 },
    { lat: 48.878893, lng: 21.065536 },
    { lat: 48.878771, lng: 21.065655 },
    { lat: 48.878589, lng: 21.065675 },
    { lat: 48.878471, lng: 21.065697 },
    { lat: 48.878244, lng: 21.065802 },
    { lat: 48.87794, lng: 21.06559 },
    { lat: 48.877622, lng: 21.065389 },
    { lat: 48.87695, lng: 21.064936 },
    { lat: 48.875572, lng: 21.063956 },
    { lat: 48.875324, lng: 21.063784 },
    { lat: 48.87507, lng: 21.063612 },
    { lat: 48.87501, lng: 21.063494 },
    { lat: 48.874904, lng: 21.063368 },
    { lat: 48.87486, lng: 21.063316 },
    { lat: 48.874796, lng: 21.063088 },
    { lat: 48.874811, lng: 21.062892 },
    { lat: 48.87485, lng: 21.062664 },
    { lat: 48.874861, lng: 21.062306 },
    { lat: 48.874859, lng: 21.061983 },
    { lat: 48.874929, lng: 21.061719 },
    { lat: 48.875007, lng: 21.061595 },
    { lat: 48.875236, lng: 21.061463 },
    { lat: 48.875361, lng: 21.061276 },
    { lat: 48.875507, lng: 21.061145 },
    { lat: 48.875648, lng: 21.060923 },
    { lat: 48.876247, lng: 21.060845 },
    { lat: 48.876605, lng: 21.060598 },
    { lat: 48.87669, lng: 21.060536 },
    { lat: 48.876829, lng: 21.060493 },
    { lat: 48.877183, lng: 21.06023 },
    { lat: 48.877317, lng: 21.060061 },
    { lat: 48.877603, lng: 21.059796 },
    { lat: 48.877837, lng: 21.059759 },
    { lat: 48.878474, lng: 21.05909 },
    { lat: 48.878668, lng: 21.058987 },
    { lat: 48.878789, lng: 21.058598 },
    { lat: 48.878924, lng: 21.05853 },
    { lat: 48.879082, lng: 21.058313 },
    { lat: 48.879186, lng: 21.058144 },
    { lat: 48.879482, lng: 21.057821 },
    { lat: 48.879807, lng: 21.05735 },
    { lat: 48.880109, lng: 21.05715 },
    { lat: 48.880247, lng: 21.05693 },
    { lat: 48.88039, lng: 21.056609 },
    { lat: 48.880548, lng: 21.056339 },
    { lat: 48.88088, lng: 21.055578 },
    { lat: 48.881021, lng: 21.055333 },
    { lat: 48.881095, lng: 21.055095 },
    { lat: 48.881226, lng: 21.054887 },
    { lat: 48.881414, lng: 21.054705 },
    { lat: 48.881598, lng: 21.054362 },
    { lat: 48.881774, lng: 21.053975 },
    { lat: 48.881987, lng: 21.053632 },
    { lat: 48.882205, lng: 21.053501 },
    { lat: 48.882324, lng: 21.053246 },
    { lat: 48.882369, lng: 21.053092 },
    { lat: 48.882391, lng: 21.052905 },
    { lat: 48.882399, lng: 21.052591 },
    { lat: 48.882403, lng: 21.052232 },
    { lat: 48.88253, lng: 21.051717 },
    { lat: 48.882529, lng: 21.051387 },
    { lat: 48.882526, lng: 21.051339 },
    { lat: 48.88251, lng: 21.051159 },
    { lat: 48.882495, lng: 21.050986 },
    { lat: 48.8824095, lng: 21.0507301 },
    { lat: 48.882334, lng: 21.050491 },
    { lat: 48.88223, lng: 21.050199 },
    { lat: 48.881932, lng: 21.050176 },
    { lat: 48.881909, lng: 21.050809 },
    { lat: 48.881806, lng: 21.051058 },
    { lat: 48.881595, lng: 21.051367 },
    { lat: 48.881368, lng: 21.051639 },
    { lat: 48.880546, lng: 21.052045 },
    { lat: 48.880353, lng: 21.05216 },
    { lat: 48.879947, lng: 21.052325 },
    { lat: 48.879779, lng: 21.052317 },
    { lat: 48.879333, lng: 21.052205 },
    { lat: 48.878804, lng: 21.051797 },
    { lat: 48.878652, lng: 21.051651 },
    { lat: 48.878365, lng: 21.051291 },
    { lat: 48.878238, lng: 21.051062 },
    { lat: 48.87808, lng: 21.05062 },
    { lat: 48.877911, lng: 21.049968 },
    { lat: 48.877869, lng: 21.049787 },
    { lat: 48.877811, lng: 21.049623 },
    { lat: 48.877704, lng: 21.04908 },
    { lat: 48.877694, lng: 21.048805 },
    { lat: 48.87762, lng: 21.048031 },
    { lat: 48.877619, lng: 21.047673 },
    { lat: 48.87753, lng: 21.046873 },
    { lat: 48.877467, lng: 21.046422 },
    { lat: 48.877384, lng: 21.046014 },
    { lat: 48.877336, lng: 21.045704 },
    { lat: 48.877305, lng: 21.045421 },
    { lat: 48.877277, lng: 21.045108 },
    { lat: 48.877267, lng: 21.045032 },
    { lat: 48.87709, lng: 21.044513 },
    { lat: 48.876575, lng: 21.043764 },
    { lat: 48.876258, lng: 21.043236 },
    { lat: 48.875728, lng: 21.042525 },
    { lat: 48.875641, lng: 21.042335 },
    { lat: 48.875558, lng: 21.042233 },
    { lat: 48.875509, lng: 21.042149 },
    { lat: 48.875279, lng: 21.041976 },
    { lat: 48.874976, lng: 21.041983 },
    { lat: 48.874793, lng: 21.042116 },
    { lat: 48.874568, lng: 21.042459 },
    { lat: 48.874162, lng: 21.042915 },
    { lat: 48.873967, lng: 21.043006 },
    { lat: 48.873883, lng: 21.04313 },
    { lat: 48.873358, lng: 21.043115 },
    { lat: 48.8731, lng: 21.04291 },
    { lat: 48.87295, lng: 21.042854 },
    { lat: 48.872826, lng: 21.042872 },
    { lat: 48.872631, lng: 21.042803 },
    { lat: 48.872294, lng: 21.042896 },
    { lat: 48.87209, lng: 21.042888 },
    { lat: 48.871242, lng: 21.043005 },
    { lat: 48.870998, lng: 21.043094 },
    { lat: 48.87068, lng: 21.043159 },
    { lat: 48.870316, lng: 21.043194 },
    { lat: 48.869815, lng: 21.043167 },
    { lat: 48.869354, lng: 21.043096 },
    { lat: 48.869015, lng: 21.042885 },
    { lat: 48.868818, lng: 21.04267 },
    { lat: 48.868513, lng: 21.042162 },
    { lat: 48.868387, lng: 21.0418 },
    { lat: 48.868291, lng: 21.04139 },
    { lat: 48.867934, lng: 21.041031 },
    { lat: 48.867871, lng: 21.040941 },
    { lat: 48.86745, lng: 21.04059 },
    { lat: 48.866965, lng: 21.040415 },
    { lat: 48.866773, lng: 21.040159 },
    { lat: 48.866009, lng: 21.040038 },
    { lat: 48.865555, lng: 21.040002 },
    { lat: 48.865503, lng: 21.039956 },
    { lat: 48.865295, lng: 21.039973 },
    { lat: 48.864087, lng: 21.040736 },
    { lat: 48.86378, lng: 21.041124 },
    { lat: 48.863646, lng: 21.041228 },
    { lat: 48.862621, lng: 21.041679 },
  ],
  Seňa: [
    { lat: 48.5963446, lng: 21.2519025 },
    { lat: 48.597165, lng: 21.25121 },
    { lat: 48.5973414, lng: 21.2511638 },
    { lat: 48.598103, lng: 21.2506813 },
    { lat: 48.5995323, lng: 21.2503984 },
    { lat: 48.5997257, lng: 21.2503122 },
    { lat: 48.6002029, lng: 21.2503156 },
    { lat: 48.5997252, lng: 21.2481625 },
    { lat: 48.5991762, lng: 21.2433507 },
    { lat: 48.5987937, lng: 21.2406769 },
    { lat: 48.5984041, lng: 21.2385768 },
    { lat: 48.5980184, lng: 21.2370674 },
    { lat: 48.5976383, lng: 21.2356284 },
    { lat: 48.5976026, lng: 21.2354976 },
    { lat: 48.5974131, lng: 21.2354097 },
    { lat: 48.5973701, lng: 21.235294 },
    { lat: 48.597318, lng: 21.235282 },
    { lat: 48.5969568, lng: 21.235752 },
    { lat: 48.5967329, lng: 21.2355375 },
    { lat: 48.5966492, lng: 21.235638 },
    { lat: 48.5962875, lng: 21.2356938 },
    { lat: 48.5962153, lng: 21.2358369 },
    { lat: 48.5960586, lng: 21.2358245 },
    { lat: 48.5959562, lng: 21.2359672 },
    { lat: 48.5958096, lng: 21.2357806 },
    { lat: 48.5957175, lng: 21.2359283 },
    { lat: 48.5956269, lng: 21.2358729 },
    { lat: 48.5955936, lng: 21.2360011 },
    { lat: 48.5954518, lng: 21.236143 },
    { lat: 48.5955427, lng: 21.2363883 },
    { lat: 48.595607, lng: 21.2362504 },
    { lat: 48.5957014, lng: 21.2363188 },
    { lat: 48.5955888, lng: 21.236641 },
    { lat: 48.5954456, lng: 21.2369214 },
    { lat: 48.5953434, lng: 21.2370008 },
    { lat: 48.5952234, lng: 21.2373054 },
    { lat: 48.5952255, lng: 21.2375175 },
    { lat: 48.59546, lng: 21.237748 },
    { lat: 48.5954863, lng: 21.2379019 },
    { lat: 48.5951924, lng: 21.2383998 },
    { lat: 48.5949868, lng: 21.2384851 },
    { lat: 48.5945813, lng: 21.2385501 },
    { lat: 48.5945114, lng: 21.2385102 },
    { lat: 48.5944548, lng: 21.2383187 },
    { lat: 48.5943283, lng: 21.2381968 },
    { lat: 48.5942826, lng: 21.2380305 },
    { lat: 48.5940941, lng: 21.2380804 },
    { lat: 48.5936778, lng: 21.2375794 },
    { lat: 48.5931229, lng: 21.2374673 },
    { lat: 48.5927117, lng: 21.2372745 },
    { lat: 48.5926443, lng: 21.2373515 },
    { lat: 48.5926372, lng: 21.2374867 },
    { lat: 48.5924651, lng: 21.2378835 },
    { lat: 48.5924736, lng: 21.2380479 },
    { lat: 48.5925443, lng: 21.2381524 },
    { lat: 48.592529, lng: 21.2382761 },
    { lat: 48.5923554, lng: 21.2383353 },
    { lat: 48.5922768, lng: 21.2385233 },
    { lat: 48.5923021, lng: 21.2386548 },
    { lat: 48.5922147, lng: 21.2386276 },
    { lat: 48.5921986, lng: 21.2385405 },
    { lat: 48.5919999, lng: 21.2384536 },
    { lat: 48.5918494, lng: 21.2382105 },
    { lat: 48.5916053, lng: 21.2383543 },
    { lat: 48.5914079, lng: 21.2380501 },
    { lat: 48.5912845, lng: 21.2379952 },
    { lat: 48.5911956, lng: 21.2380345 },
    { lat: 48.5911058, lng: 21.2382567 },
    { lat: 48.5912045, lng: 21.2387901 },
    { lat: 48.5910631, lng: 21.2390198 },
    { lat: 48.5909133, lng: 21.2388654 },
    { lat: 48.5907486, lng: 21.2390676 },
    { lat: 48.5906594, lng: 21.23906 },
    { lat: 48.5905271, lng: 21.2388611 },
    { lat: 48.5903083, lng: 21.2388115 },
    { lat: 48.5896465, lng: 21.2383763 },
    { lat: 48.5892327, lng: 21.2384571 },
    { lat: 48.5891737, lng: 21.2388043 },
    { lat: 48.5890056, lng: 21.2389723 },
    { lat: 48.5887932, lng: 21.238769 },
    { lat: 48.5886915, lng: 21.2385338 },
    { lat: 48.5883087, lng: 21.2385335 },
    { lat: 48.5881622, lng: 21.2387792 },
    { lat: 48.5882755, lng: 21.2390552 },
    { lat: 48.5881519, lng: 21.2393896 },
    { lat: 48.5880517, lng: 21.239419 },
    { lat: 48.5879036, lng: 21.2393286 },
    { lat: 48.5878365, lng: 21.2393401 },
    { lat: 48.5877746, lng: 21.2394504 },
    { lat: 48.5877739, lng: 21.2397554 },
    { lat: 48.5876564, lng: 21.2404281 },
    { lat: 48.5874558, lng: 21.2402315 },
    { lat: 48.5874424, lng: 21.2399854 },
    { lat: 48.5873833, lng: 21.2399715 },
    { lat: 48.5870367, lng: 21.2403301 },
    { lat: 48.5867612, lng: 21.2403382 },
    { lat: 48.5867325, lng: 21.2404288 },
    { lat: 48.5867973, lng: 21.2407754 },
    { lat: 48.5867248, lng: 21.2409028 },
    { lat: 48.5865775, lng: 21.2407991 },
    { lat: 48.5863964, lng: 21.2405122 },
    { lat: 48.586113, lng: 21.2405614 },
    { lat: 48.5860558, lng: 21.2406394 },
    { lat: 48.585207, lng: 21.236642 },
    { lat: 48.58447, lng: 21.236934 },
    { lat: 48.584317, lng: 21.236946 },
    { lat: 48.584047, lng: 21.236967 },
    { lat: 48.583885, lng: 21.236979 },
    { lat: 48.583797, lng: 21.236985 },
    { lat: 48.583686, lng: 21.237034 },
    { lat: 48.583648, lng: 21.23705 },
    { lat: 48.583612, lng: 21.237066 },
    { lat: 48.581711, lng: 21.237895 },
    { lat: 48.580273, lng: 21.238438 },
    { lat: 48.579781, lng: 21.238623 },
    { lat: 48.577331, lng: 21.240354 },
    { lat: 48.577311, lng: 21.240298 },
    { lat: 48.577294, lng: 21.240253 },
    { lat: 48.574755, lng: 21.233358 },
    { lat: 48.574689, lng: 21.233178 },
    { lat: 48.574659, lng: 21.233099 },
    { lat: 48.574433, lng: 21.232484 },
    { lat: 48.574258, lng: 21.232008 },
    { lat: 48.574198, lng: 21.231846 },
    { lat: 48.573739, lng: 21.230598 },
    { lat: 48.573723, lng: 21.230554 },
    { lat: 48.573467, lng: 21.229862 },
    { lat: 48.573309, lng: 21.229433 },
    { lat: 48.573051, lng: 21.228734 },
    { lat: 48.57303, lng: 21.228674 },
    { lat: 48.572479, lng: 21.22718 },
    { lat: 48.572398, lng: 21.226961 },
    { lat: 48.570039, lng: 21.220558 },
    { lat: 48.57128, lng: 21.216296 },
    { lat: 48.571357, lng: 21.21603 },
    { lat: 48.572813, lng: 21.211022 },
    { lat: 48.573605, lng: 21.20827 },
    { lat: 48.574192, lng: 21.206234 },
    { lat: 48.574224, lng: 21.206122 },
    { lat: 48.575172, lng: 21.202726 },
    { lat: 48.575192, lng: 21.202654 },
    { lat: 48.5745474, lng: 21.2017221 },
    { lat: 48.5743117, lng: 21.201563 },
    { lat: 48.5741913, lng: 21.2012056 },
    { lat: 48.5738785, lng: 21.2006724 },
    { lat: 48.5734598, lng: 21.2001728 },
    { lat: 48.5727784, lng: 21.1991522 },
    { lat: 48.5708765, lng: 21.1972995 },
    { lat: 48.570907, lng: 21.1972348 },
    { lat: 48.5704167, lng: 21.1967123 },
    { lat: 48.5703276, lng: 21.1968124 },
    { lat: 48.5700345, lng: 21.1969393 },
    { lat: 48.5698358, lng: 21.1967801 },
    { lat: 48.5696078, lng: 21.1963988 },
    { lat: 48.5595847, lng: 21.2070324 },
    { lat: 48.5574535, lng: 21.2093764 },
    { lat: 48.5556198, lng: 21.2098631 },
    { lat: 48.5549148, lng: 21.2097794 },
    { lat: 48.5548077, lng: 21.2108151 },
    { lat: 48.5545066, lng: 21.2128015 },
    { lat: 48.5544323, lng: 21.2135223 },
    { lat: 48.5545809, lng: 21.2134467 },
    { lat: 48.5544856, lng: 21.2150035 },
    { lat: 48.5544908, lng: 21.2165015 },
    { lat: 48.5544081, lng: 21.2165479 },
    { lat: 48.5543989, lng: 21.2183781 },
    { lat: 48.5545321, lng: 21.218353 },
    { lat: 48.5545521, lng: 21.2184873 },
    { lat: 48.5546381, lng: 21.220946 },
    { lat: 48.5545945, lng: 21.2221821 },
    { lat: 48.55448, lng: 21.2233022 },
    { lat: 48.5545384, lng: 21.2236984 },
    { lat: 48.5553133, lng: 21.2261626 },
    { lat: 48.5555481, lng: 21.2272618 },
    { lat: 48.5557237, lng: 21.2288132 },
    { lat: 48.556068, lng: 21.2311545 },
    { lat: 48.5564168, lng: 21.2360619 },
    { lat: 48.5565363, lng: 21.2383736 },
    { lat: 48.5565169, lng: 21.2421603 },
    { lat: 48.5565414, lng: 21.2446909 },
    { lat: 48.5566088, lng: 21.246417 },
    { lat: 48.5565061, lng: 21.250178 },
    { lat: 48.5564216, lng: 21.2517311 },
    { lat: 48.5565387, lng: 21.25387 },
    { lat: 48.556438, lng: 21.2547613 },
    { lat: 48.5555, lng: 21.2550391 },
    { lat: 48.5550007, lng: 21.2552671 },
    { lat: 48.5552805, lng: 21.2563796 },
    { lat: 48.5550227, lng: 21.2565914 },
    { lat: 48.5554433, lng: 21.2579633 },
    { lat: 48.5553205, lng: 21.2580259 },
    { lat: 48.5550748, lng: 21.2583093 },
    { lat: 48.5552756, lng: 21.2591275 },
    { lat: 48.5556275, lng: 21.2589706 },
    { lat: 48.5556453, lng: 21.2591008 },
    { lat: 48.5556852, lng: 21.2590895 },
    { lat: 48.5556861, lng: 21.2596205 },
    { lat: 48.5558022, lng: 21.2608642 },
    { lat: 48.5558209, lng: 21.2626307 },
    { lat: 48.5559649, lng: 21.2634657 },
    { lat: 48.5560622, lng: 21.2636994 },
    { lat: 48.5563694, lng: 21.2649028 },
    { lat: 48.5565256, lng: 21.2664585 },
    { lat: 48.5568155, lng: 21.2702998 },
    { lat: 48.5570325, lng: 21.2721551 },
    { lat: 48.5567115, lng: 21.2723999 },
    { lat: 48.556553, lng: 21.2722984 },
    { lat: 48.556535, lng: 21.2725196 },
    { lat: 48.5563499, lng: 21.2724597 },
    { lat: 48.5562236, lng: 21.2722032 },
    { lat: 48.5562352, lng: 21.2724258 },
    { lat: 48.5561971, lng: 21.2725038 },
    { lat: 48.5559998, lng: 21.2724935 },
    { lat: 48.5560488, lng: 21.2726598 },
    { lat: 48.555953, lng: 21.2726957 },
    { lat: 48.5558825, lng: 21.27249 },
    { lat: 48.5558469, lng: 21.272496 },
    { lat: 48.5556028, lng: 21.2728719 },
    { lat: 48.555543, lng: 21.2727919 },
    { lat: 48.5555259, lng: 21.272602 },
    { lat: 48.555476, lng: 21.2725902 },
    { lat: 48.5553804, lng: 21.2727319 },
    { lat: 48.5554466, lng: 21.2729528 },
    { lat: 48.5552054, lng: 21.2731214 },
    { lat: 48.5551039, lng: 21.2730429 },
    { lat: 48.5551995, lng: 21.2742071 },
    { lat: 48.5552842, lng: 21.2767327 },
    { lat: 48.555383, lng: 21.2777091 },
    { lat: 48.5553878, lng: 21.2790672 },
    { lat: 48.5551696, lng: 21.2807184 },
    { lat: 48.5550549, lng: 21.2828767 },
    { lat: 48.5550009, lng: 21.2831742 },
    { lat: 48.5550273, lng: 21.2833337 },
    { lat: 48.5551441, lng: 21.2834761 },
    { lat: 48.5546368, lng: 21.2856728 },
    { lat: 48.5540806, lng: 21.28541 },
    { lat: 48.5535154, lng: 21.2866086 },
    { lat: 48.552761, lng: 21.2877663 },
    { lat: 48.5516681, lng: 21.287656 },
    { lat: 48.5514426, lng: 21.2880472 },
    { lat: 48.5514543, lng: 21.2890858 },
    { lat: 48.5511994, lng: 21.2918525 },
    { lat: 48.5509802, lng: 21.2932141 },
    { lat: 48.550601, lng: 21.2950119 },
    { lat: 48.5504154, lng: 21.2954205 },
    { lat: 48.5500145, lng: 21.2959983 },
    { lat: 48.5493355, lng: 21.2959485 },
    { lat: 48.5486434, lng: 21.2955125 },
    { lat: 48.5484902, lng: 21.296348 },
    { lat: 48.5479321, lng: 21.2987026 },
    { lat: 48.5472544, lng: 21.3007917 },
    { lat: 48.5468391, lng: 21.3025861 },
    { lat: 48.5464188, lng: 21.30397 },
    { lat: 48.5463361, lng: 21.3047603 },
    { lat: 48.546404, lng: 21.3058389 },
    { lat: 48.5466186, lng: 21.3061912 },
    { lat: 48.546942, lng: 21.3064267 },
    { lat: 48.5467619, lng: 21.3070156 },
    { lat: 48.5465814, lng: 21.308029 },
    { lat: 48.5465362, lng: 21.308804 },
    { lat: 48.5463146, lng: 21.3102798 },
    { lat: 48.5458453, lng: 21.3116773 },
    { lat: 48.5462122, lng: 21.3119019 },
    { lat: 48.5460397, lng: 21.312924 },
    { lat: 48.5463651, lng: 21.3129625 },
    { lat: 48.5465845, lng: 21.3132951 },
    { lat: 48.5463789, lng: 21.3142619 },
    { lat: 48.5463763, lng: 21.3152129 },
    { lat: 48.5480756, lng: 21.3166116 },
    { lat: 48.547223, lng: 21.321157 },
    { lat: 48.547326, lng: 21.321153 },
    { lat: 48.547459, lng: 21.321244 },
    { lat: 48.547671, lng: 21.321444 },
    { lat: 48.547877, lng: 21.321487 },
    { lat: 48.547999, lng: 21.321459 },
    { lat: 48.548293, lng: 21.321224 },
    { lat: 48.548366, lng: 21.321135 },
    { lat: 48.548463, lng: 21.320988 },
    { lat: 48.548538, lng: 21.320802 },
    { lat: 48.548609, lng: 21.320592 },
    { lat: 48.548723, lng: 21.320285 },
    { lat: 48.548894, lng: 21.320068 },
    { lat: 48.549031, lng: 21.320037 },
    { lat: 48.549153, lng: 21.319956 },
    { lat: 48.549285, lng: 21.31977 },
    { lat: 48.549366, lng: 21.319606 },
    { lat: 48.549409, lng: 21.319371 },
    { lat: 48.549575, lng: 21.319096 },
    { lat: 48.549644, lng: 21.318889 },
    { lat: 48.549699, lng: 21.318679 },
    { lat: 48.5495661, lng: 21.3183419 },
    { lat: 48.5496229, lng: 21.3181616 },
    { lat: 48.549552, lng: 21.318014 },
    { lat: 48.549455, lng: 21.317793 },
    { lat: 48.549315, lng: 21.317639 },
    { lat: 48.549207, lng: 21.317484 },
    { lat: 48.549078, lng: 21.317114 },
    { lat: 48.549059, lng: 21.317063 },
    { lat: 48.549016, lng: 21.316943 },
    { lat: 48.548991, lng: 21.31684 },
    { lat: 48.548984, lng: 21.316597 },
    { lat: 48.549004, lng: 21.316332 },
    { lat: 48.549034, lng: 21.316113 },
    { lat: 48.549067, lng: 21.315918 },
    { lat: 48.549102, lng: 21.315557 },
    { lat: 48.549088, lng: 21.315268 },
    { lat: 48.549061, lng: 21.315025 },
    { lat: 48.549054, lng: 21.314752 },
    { lat: 48.549046, lng: 21.314679 },
    { lat: 48.549106, lng: 21.314458 },
    { lat: 48.549127, lng: 21.314167 },
    { lat: 48.549143, lng: 21.314112 },
    { lat: 48.549194, lng: 21.314009 },
    { lat: 48.549362, lng: 21.313742 },
    { lat: 48.549432, lng: 21.313644 },
    { lat: 48.549539, lng: 21.313516 },
    { lat: 48.549579, lng: 21.313455 },
    { lat: 48.549689, lng: 21.313333 },
    { lat: 48.549735, lng: 21.313277 },
    { lat: 48.549848, lng: 21.313196 },
    { lat: 48.549919, lng: 21.313165 },
    { lat: 48.549952, lng: 21.313151 },
    { lat: 48.550013, lng: 21.31316 },
    { lat: 48.550088, lng: 21.31317 },
    { lat: 48.550229, lng: 21.313246 },
    { lat: 48.550265, lng: 21.313308 },
    { lat: 48.550309, lng: 21.313386 },
    { lat: 48.550511, lng: 21.313367 },
    { lat: 48.550695, lng: 21.313334 },
    { lat: 48.550841, lng: 21.313377 },
    { lat: 48.550944, lng: 21.313462 },
    { lat: 48.55114, lng: 21.313691 },
    { lat: 48.551248, lng: 21.313959 },
    { lat: 48.551295, lng: 21.314223 },
    { lat: 48.551407, lng: 21.31445 },
    { lat: 48.551527, lng: 21.314716 },
    { lat: 48.5517, lng: 21.314983 },
    { lat: 48.551822, lng: 21.315132 },
    { lat: 48.551991, lng: 21.315293 },
    { lat: 48.552126, lng: 21.315437 },
    { lat: 48.552291, lng: 21.315587 },
    { lat: 48.552386, lng: 21.315661 },
    { lat: 48.552502, lng: 21.315862 },
    { lat: 48.552671, lng: 21.316156 },
    { lat: 48.552811, lng: 21.316354 },
    { lat: 48.552877, lng: 21.316508 },
    { lat: 48.552983, lng: 21.316829 },
    { lat: 48.552992, lng: 21.317084 },
    { lat: 48.552995, lng: 21.317372 },
    { lat: 48.5531228, lng: 21.317527 },
    { lat: 48.552958, lng: 21.317893 },
    { lat: 48.552886, lng: 21.318207 },
    { lat: 48.552816, lng: 21.318441 },
    { lat: 48.552741, lng: 21.318685 },
    { lat: 48.552681, lng: 21.318798 },
    { lat: 48.552557, lng: 21.31902 },
    { lat: 48.552418, lng: 21.319252 },
    { lat: 48.552328, lng: 21.319452 },
    { lat: 48.552196, lng: 21.31968 },
    { lat: 48.552067, lng: 21.320015 },
    { lat: 48.552032, lng: 21.320091 },
    { lat: 48.552004, lng: 21.320342 },
    { lat: 48.551993, lng: 21.320527 },
    { lat: 48.551975, lng: 21.320705 },
    { lat: 48.551949, lng: 21.320819 },
    { lat: 48.551892, lng: 21.320956 },
    { lat: 48.551763, lng: 21.321189 },
    { lat: 48.551663, lng: 21.321321 },
    { lat: 48.551566, lng: 21.321501 },
    { lat: 48.551538, lng: 21.321689 },
    { lat: 48.551508, lng: 21.322045 },
    { lat: 48.551525, lng: 21.322307 },
    { lat: 48.551549, lng: 21.322569 },
    { lat: 48.551573, lng: 21.322841 },
    { lat: 48.551617, lng: 21.323041 },
    { lat: 48.551657, lng: 21.323202 },
    { lat: 48.55172, lng: 21.323383 },
    { lat: 48.551805, lng: 21.323642 },
    { lat: 48.551876, lng: 21.323818 },
    { lat: 48.551933, lng: 21.323974 },
    { lat: 48.552046, lng: 21.324228 },
    { lat: 48.552117, lng: 21.324424 },
    { lat: 48.552173, lng: 21.32456 },
    { lat: 48.552238, lng: 21.324721 },
    { lat: 48.552351, lng: 21.324934 },
    { lat: 48.552437, lng: 21.325111 },
    { lat: 48.552593, lng: 21.325367 },
    { lat: 48.552683, lng: 21.325524 },
    { lat: 48.552784, lng: 21.325675 },
    { lat: 48.552925, lng: 21.325862 },
    { lat: 48.553021, lng: 21.325963 },
    { lat: 48.553135, lng: 21.326092 },
    { lat: 48.553261, lng: 21.326252 },
    { lat: 48.553347, lng: 21.326373 },
    { lat: 48.553512, lng: 21.326545 },
    { lat: 48.553683, lng: 21.326697 },
    { lat: 48.553913, lng: 21.326793 },
    { lat: 48.554129, lng: 21.326875 },
    { lat: 48.554281, lng: 21.326842 },
    { lat: 48.554446, lng: 21.326714 },
    { lat: 48.554587, lng: 21.326601 },
    { lat: 48.554682, lng: 21.326559 },
    { lat: 48.554827, lng: 21.326541 },
    { lat: 48.554875, lng: 21.326532 },
    { lat: 48.555005, lng: 21.326565 },
    { lat: 48.555121, lng: 21.326642 },
    { lat: 48.555265, lng: 21.326787 },
    { lat: 48.555414, lng: 21.326838 },
    { lat: 48.555588, lng: 21.326821 },
    { lat: 48.5557184, lng: 21.3266471 },
    { lat: 48.555801, lng: 21.32667 },
    { lat: 48.555895, lng: 21.326572 },
    { lat: 48.556003, lng: 21.326423 },
    { lat: 48.5561, lng: 21.326205 },
    { lat: 48.556139, lng: 21.325934 },
    { lat: 48.556182, lng: 21.325656 },
    { lat: 48.556209, lng: 21.325373 },
    { lat: 48.556211, lng: 21.325093 },
    { lat: 48.556199, lng: 21.324802 },
    { lat: 48.556201, lng: 21.324452 },
    { lat: 48.556192, lng: 21.32418 },
    { lat: 48.556187, lng: 21.323878 },
    { lat: 48.556209, lng: 21.323542 },
    { lat: 48.556226, lng: 21.323344 },
    { lat: 48.556271, lng: 21.323144 },
    { lat: 48.556261, lng: 21.322998 },
    { lat: 48.556268, lng: 21.322723 },
    { lat: 48.556296, lng: 21.322412 },
    { lat: 48.556318, lng: 21.322229 },
    { lat: 48.556339, lng: 21.32199 },
    { lat: 48.55633, lng: 21.321673 },
    { lat: 48.556299, lng: 21.321361 },
    { lat: 48.556265, lng: 21.32104 },
    { lat: 48.55625, lng: 21.320707 },
    { lat: 48.556256, lng: 21.320388 },
    { lat: 48.556213, lng: 21.320022 },
    { lat: 48.556217, lng: 21.319723 },
    { lat: 48.556131, lng: 21.319492 },
    { lat: 48.556058, lng: 21.319304 },
    { lat: 48.555999, lng: 21.319079 },
    { lat: 48.555875, lng: 21.318778 },
    { lat: 48.555853, lng: 21.318576 },
    { lat: 48.555818, lng: 21.318392 },
    { lat: 48.555802, lng: 21.318163 },
    { lat: 48.555808, lng: 21.317964 },
    { lat: 48.555816, lng: 21.317697 },
    { lat: 48.555845, lng: 21.317512 },
    { lat: 48.555853, lng: 21.317355 },
    { lat: 48.555751, lng: 21.317147 },
    { lat: 48.555692, lng: 21.316865 },
    { lat: 48.55563, lng: 21.31671 },
    { lat: 48.555522, lng: 21.316575 },
    { lat: 48.555469, lng: 21.316565 },
    { lat: 48.555378, lng: 21.316547 },
    { lat: 48.555294, lng: 21.316598 },
    { lat: 48.555255, lng: 21.316479 },
    { lat: 48.555202, lng: 21.316352 },
    { lat: 48.55518, lng: 21.316265 },
    { lat: 48.555176, lng: 21.316214 },
    { lat: 48.555192, lng: 21.316198 },
    { lat: 48.555294, lng: 21.316078 },
    { lat: 48.55543, lng: 21.31595 },
    { lat: 48.555501, lng: 21.315923 },
    { lat: 48.555603, lng: 21.315884 },
    { lat: 48.555735, lng: 21.315905 },
    { lat: 48.555847, lng: 21.31583 },
    { lat: 48.555895, lng: 21.31578 },
    { lat: 48.55593, lng: 21.315753 },
    { lat: 48.556012, lng: 21.315709 },
    { lat: 48.55612, lng: 21.315683 },
    { lat: 48.556399, lng: 21.315584 },
    { lat: 48.556518, lng: 21.315548 },
    { lat: 48.55661, lng: 21.315516 },
    { lat: 48.556648, lng: 21.315502 },
    { lat: 48.556749, lng: 21.315485 },
    { lat: 48.556862, lng: 21.31548 },
    { lat: 48.55691, lng: 21.315486 },
    { lat: 48.557039, lng: 21.315504 },
    { lat: 48.557172, lng: 21.315566 },
    { lat: 48.557355, lng: 21.315672 },
    { lat: 48.557543, lng: 21.315786 },
    { lat: 48.557704, lng: 21.315892 },
    { lat: 48.557861, lng: 21.316041 },
    { lat: 48.558048, lng: 21.316222 },
    { lat: 48.55828, lng: 21.316437 },
    { lat: 48.55845, lng: 21.316603 },
    { lat: 48.558665, lng: 21.316813 },
    { lat: 48.558734, lng: 21.316881 },
    { lat: 48.558884, lng: 21.317053 },
    { lat: 48.558997, lng: 21.317239 },
    { lat: 48.559063, lng: 21.317345 },
    { lat: 48.559235, lng: 21.31761 },
    { lat: 48.559341, lng: 21.317756 },
    { lat: 48.55941, lng: 21.317894 },
    { lat: 48.559555, lng: 21.318057 },
    { lat: 48.5595469, lng: 21.316582 },
    { lat: 48.5593565, lng: 21.3159465 },
    { lat: 48.5593354, lng: 21.3156619 },
    { lat: 48.558934, lng: 21.3154538 },
    { lat: 48.5590146, lng: 21.3146628 },
    { lat: 48.5595249, lng: 21.3125765 },
    { lat: 48.5599988, lng: 21.3123561 },
    { lat: 48.560317, lng: 21.3120868 },
    { lat: 48.5606238, lng: 21.312078 },
    { lat: 48.5609664, lng: 21.3121622 },
    { lat: 48.5619002, lng: 21.3125619 },
    { lat: 48.5634555, lng: 21.3135581 },
    { lat: 48.564264, lng: 21.313796 },
    { lat: 48.5659846, lng: 21.3140883 },
    { lat: 48.567047, lng: 21.3141601 },
    { lat: 48.569816, lng: 21.3138465 },
    { lat: 48.5708394, lng: 21.3140074 },
    { lat: 48.5708587, lng: 21.3136819 },
    { lat: 48.571714, lng: 21.3136806 },
    { lat: 48.5717369, lng: 21.313032 },
    { lat: 48.5725692, lng: 21.313012 },
    { lat: 48.5725998, lng: 21.3123215 },
    { lat: 48.5730535, lng: 21.3122507 },
    { lat: 48.5730891, lng: 21.3117958 },
    { lat: 48.5736065, lng: 21.3123047 },
    { lat: 48.5744134, lng: 21.3127105 },
    { lat: 48.5746092, lng: 21.3127452 },
    { lat: 48.574793, lng: 21.3122769 },
    { lat: 48.5749172, lng: 21.312403 },
    { lat: 48.5765654, lng: 21.3127914 },
    { lat: 48.5765949, lng: 21.3127993 },
    { lat: 48.5766125, lng: 21.3125642 },
    { lat: 48.5772646, lng: 21.305285 },
    { lat: 48.5775733, lng: 21.3034902 },
    { lat: 48.5776073, lng: 21.3030961 },
    { lat: 48.5775672, lng: 21.3018163 },
    { lat: 48.5780915, lng: 21.2999094 },
    { lat: 48.5780066, lng: 21.2989308 },
    { lat: 48.5780492, lng: 21.297974 },
    { lat: 48.5782243, lng: 21.2973697 },
    { lat: 48.578423, lng: 21.295259 },
    { lat: 48.578668, lng: 21.2938598 },
    { lat: 48.5788899, lng: 21.2931651 },
    { lat: 48.5792934, lng: 21.2914296 },
    { lat: 48.5797623, lng: 21.2898043 },
    { lat: 48.5799349, lng: 21.2894127 },
    { lat: 48.579434, lng: 21.2891878 },
    { lat: 48.5794357, lng: 21.2889137 },
    { lat: 48.577834, lng: 21.2885794 },
    { lat: 48.5777286, lng: 21.287781 },
    { lat: 48.5776229, lng: 21.2875339 },
    { lat: 48.5771833, lng: 21.286889 },
    { lat: 48.5769698, lng: 21.2859806 },
    { lat: 48.5762659, lng: 21.2860347 },
    { lat: 48.5762509, lng: 21.2859152 },
    { lat: 48.5757197, lng: 21.2858443 },
    { lat: 48.5756076, lng: 21.2826365 },
    { lat: 48.5752152, lng: 21.2826437 },
    { lat: 48.5749754, lng: 21.2804546 },
    { lat: 48.5734619, lng: 21.2801279 },
    { lat: 48.5731514, lng: 21.2778347 },
    { lat: 48.5729458, lng: 21.2768935 },
    { lat: 48.572624, lng: 21.2748502 },
    { lat: 48.5724583, lng: 21.2739649 },
    { lat: 48.5721051, lng: 21.2726996 },
    { lat: 48.5721711, lng: 21.2726517 },
    { lat: 48.5723492, lng: 21.2727298 },
    { lat: 48.5724372, lng: 21.2727005 },
    { lat: 48.5724297, lng: 21.2724957 },
    { lat: 48.5725272, lng: 21.2723684 },
    { lat: 48.5727174, lng: 21.2724801 },
    { lat: 48.5729375, lng: 21.2723595 },
    { lat: 48.5731283, lng: 21.2726294 },
    { lat: 48.5731775, lng: 21.2730236 },
    { lat: 48.5732212, lng: 21.2730866 },
    { lat: 48.5733679, lng: 21.2731027 },
    { lat: 48.5735693, lng: 21.2729859 },
    { lat: 48.573797, lng: 21.2730762 },
    { lat: 48.5741166, lng: 21.2729301 },
    { lat: 48.5742849, lng: 21.2727683 },
    { lat: 48.5741987, lng: 21.2723063 },
    { lat: 48.5746062, lng: 21.2721978 },
    { lat: 48.5750355, lng: 21.2715611 },
    { lat: 48.5751917, lng: 21.2715175 },
    { lat: 48.5753706, lng: 21.2719584 },
    { lat: 48.575528, lng: 21.2718386 },
    { lat: 48.5756177, lng: 21.2715345 },
    { lat: 48.5757303, lng: 21.2713951 },
    { lat: 48.5760886, lng: 21.2713722 },
    { lat: 48.5758819, lng: 21.2689419 },
    { lat: 48.575925, lng: 21.2626168 },
    { lat: 48.5758845, lng: 21.2615982 },
    { lat: 48.5758101, lng: 21.2609111 },
    { lat: 48.5761935, lng: 21.2608231 },
    { lat: 48.5761406, lng: 21.2599163 },
    { lat: 48.577098, lng: 21.2597985 },
    { lat: 48.5770374, lng: 21.2588176 },
    { lat: 48.5772109, lng: 21.2587922 },
    { lat: 48.5771376, lng: 21.2576988 },
    { lat: 48.5772556, lng: 21.2576926 },
    { lat: 48.577099, lng: 21.2563094 },
    { lat: 48.5774646, lng: 21.2562272 },
    { lat: 48.5771419, lng: 21.2535172 },
    { lat: 48.5798496, lng: 21.2528196 },
    { lat: 48.581015, lng: 21.2527339 },
    { lat: 48.5813946, lng: 21.2520532 },
    { lat: 48.5815285, lng: 21.2519844 },
    { lat: 48.5820182, lng: 21.251989 },
    { lat: 48.5834252, lng: 21.2522851 },
    { lat: 48.5844802, lng: 21.2526984 },
    { lat: 48.5856437, lng: 21.253305 },
    { lat: 48.5860811, lng: 21.2533307 },
    { lat: 48.5863758, lng: 21.2534358 },
    { lat: 48.5875338, lng: 21.2541231 },
    { lat: 48.5879851, lng: 21.2541567 },
    { lat: 48.5884598, lng: 21.2538968 },
    { lat: 48.5887943, lng: 21.2535053 },
    { lat: 48.5892044, lng: 21.2531544 },
    { lat: 48.5902013, lng: 21.2525697 },
    { lat: 48.5928333, lng: 21.2517592 },
    { lat: 48.592904, lng: 21.2518261 },
    { lat: 48.5929121, lng: 21.2518667 },
    { lat: 48.5945897, lng: 21.2521822 },
    { lat: 48.595105, lng: 21.2521761 },
    { lat: 48.5956515, lng: 21.2520473 },
    { lat: 48.5958361, lng: 21.2520912 },
    { lat: 48.5961908, lng: 21.2519855 },
    { lat: 48.5963446, lng: 21.2519025 },
  ],
  MoldavanadBodvou: [
    { lat: 48.6403943, lng: 21.0225631 },
    { lat: 48.6408736, lng: 21.022458 },
    { lat: 48.6412301, lng: 21.0225473 },
    { lat: 48.6415048, lng: 21.0224142 },
    { lat: 48.6416966, lng: 21.0222229 },
    { lat: 48.6420838, lng: 21.0220853 },
    { lat: 48.6429148, lng: 21.0223002 },
    { lat: 48.6432464, lng: 21.0222511 },
    { lat: 48.6435824, lng: 21.0220966 },
    { lat: 48.6435114, lng: 21.0220269 },
    { lat: 48.6434228, lng: 21.0214278 },
    { lat: 48.6433178, lng: 21.0199098 },
    { lat: 48.6433023, lng: 21.0192063 },
    { lat: 48.6433834, lng: 21.0159698 },
    { lat: 48.6417368, lng: 21.0148484 },
    { lat: 48.6400611, lng: 21.0140384 },
    { lat: 48.6395297, lng: 21.0049857 },
    { lat: 48.6395471, lng: 21.0029358 },
    { lat: 48.6396181, lng: 21.0016468 },
    { lat: 48.6393188, lng: 21.0016124 },
    { lat: 48.6391046, lng: 21.001435 },
    { lat: 48.6384693, lng: 21.0011811 },
    { lat: 48.6379296, lng: 21.0011308 },
    { lat: 48.6377846, lng: 21.0010392 },
    { lat: 48.6376554, lng: 21.0008782 },
    { lat: 48.637541, lng: 21.0005167 },
    { lat: 48.6377106, lng: 21.0004014 },
    { lat: 48.6377185, lng: 21.0002979 },
    { lat: 48.6376174, lng: 21.0002372 },
    { lat: 48.6378424, lng: 21.000101 },
    { lat: 48.6377796, lng: 21.000009 },
    { lat: 48.6376441, lng: 20.9999907 },
    { lat: 48.6375381, lng: 20.9997824 },
    { lat: 48.6374418, lng: 20.9998633 },
    { lat: 48.6373758, lng: 20.9997092 },
    { lat: 48.6372822, lng: 20.9996309 },
    { lat: 48.637337, lng: 20.9993872 },
    { lat: 48.6374591, lng: 20.9993328 },
    { lat: 48.6374743, lng: 20.9992516 },
    { lat: 48.6373081, lng: 20.999162 },
    { lat: 48.6372779, lng: 20.9989991 },
    { lat: 48.6372424, lng: 20.9989787 },
    { lat: 48.637172, lng: 20.9990979 },
    { lat: 48.637104, lng: 20.9990806 },
    { lat: 48.6369334, lng: 20.99886 },
    { lat: 48.6368483, lng: 20.998838 },
    { lat: 48.6366093, lng: 20.9989927 },
    { lat: 48.6365314, lng: 20.9988847 },
    { lat: 48.6363849, lng: 20.9989467 },
    { lat: 48.6362935, lng: 20.9988738 },
    { lat: 48.6361914, lng: 20.9988876 },
    { lat: 48.636133, lng: 20.9987212 },
    { lat: 48.6360569, lng: 20.9987665 },
    { lat: 48.6357006, lng: 20.9986112 },
    { lat: 48.6356258, lng: 20.9986965 },
    { lat: 48.6356345, lng: 20.9989582 },
    { lat: 48.6354897, lng: 20.9989744 },
    { lat: 48.635335, lng: 20.9988893 },
    { lat: 48.6352946, lng: 20.9989667 },
    { lat: 48.6353473, lng: 20.9991617 },
    { lat: 48.6351027, lng: 20.9991464 },
    { lat: 48.6350925, lng: 20.9993863 },
    { lat: 48.6349934, lng: 20.9994849 },
    { lat: 48.634686, lng: 20.9993825 },
    { lat: 48.6345787, lng: 20.9992713 },
    { lat: 48.6345399, lng: 20.9990462 },
    { lat: 48.6344504, lng: 20.9988939 },
    { lat: 48.634295, lng: 20.9990309 },
    { lat: 48.6341541, lng: 20.9989561 },
    { lat: 48.6340753, lng: 20.999019 },
    { lat: 48.6334976, lng: 20.9988744 },
    { lat: 48.6334511, lng: 20.9992081 },
    { lat: 48.6333874, lng: 20.9990877 },
    { lat: 48.6332422, lng: 20.9990819 },
    { lat: 48.6331871, lng: 20.9991199 },
    { lat: 48.6331238, lng: 20.9993528 },
    { lat: 48.6330054, lng: 20.9993101 },
    { lat: 48.6328645, lng: 20.9994346 },
    { lat: 48.6327564, lng: 20.9993691 },
    { lat: 48.632641, lng: 20.9990134 },
    { lat: 48.6328778, lng: 20.9989895 },
    { lat: 48.6328653, lng: 20.99882 },
    { lat: 48.6327553, lng: 20.9987255 },
    { lat: 48.6325576, lng: 20.9988372 },
    { lat: 48.6325808, lng: 20.9988984 },
    { lat: 48.6325429, lng: 20.9989411 },
    { lat: 48.6324234, lng: 20.9988302 },
    { lat: 48.6323035, lng: 20.9988507 },
    { lat: 48.632201, lng: 20.9987449 },
    { lat: 48.6321459, lng: 20.9985779 },
    { lat: 48.6320471, lng: 20.9985854 },
    { lat: 48.6319863, lng: 20.9984821 },
    { lat: 48.6319954, lng: 20.9984131 },
    { lat: 48.6320753, lng: 20.998384 },
    { lat: 48.6320218, lng: 20.9981578 },
    { lat: 48.6320403, lng: 20.9980763 },
    { lat: 48.6322303, lng: 20.997931 },
    { lat: 48.632224, lng: 20.9978465 },
    { lat: 48.6320728, lng: 20.9978689 },
    { lat: 48.6319844, lng: 20.9977788 },
    { lat: 48.6320366, lng: 20.9975243 },
    { lat: 48.6321746, lng: 20.9974402 },
    { lat: 48.6321838, lng: 20.9973766 },
    { lat: 48.6319396, lng: 20.997173 },
    { lat: 48.6319311, lng: 20.9970259 },
    { lat: 48.631847, lng: 20.9969295 },
    { lat: 48.6317593, lng: 20.9969646 },
    { lat: 48.6316479, lng: 20.9974455 },
    { lat: 48.6315499, lng: 20.9975782 },
    { lat: 48.6313712, lng: 20.9975518 },
    { lat: 48.6313137, lng: 20.9976188 },
    { lat: 48.6312678, lng: 20.9975202 },
    { lat: 48.631298, lng: 20.9970393 },
    { lat: 48.6312465, lng: 20.9966731 },
    { lat: 48.6311929, lng: 20.9965519 },
    { lat: 48.6311116, lng: 20.9965466 },
    { lat: 48.6309531, lng: 20.9961487 },
    { lat: 48.6308906, lng: 20.9960623 },
    { lat: 48.6307876, lng: 20.9961093 },
    { lat: 48.6307592, lng: 20.9959698 },
    { lat: 48.6307401, lng: 20.9953962 },
    { lat: 48.6308161, lng: 20.9949008 },
    { lat: 48.6308282, lng: 20.9938808 },
    { lat: 48.631349, lng: 20.992617 },
    { lat: 48.6318102, lng: 20.9902022 },
    { lat: 48.631844, lng: 20.9872618 },
    { lat: 48.6332138, lng: 20.9807049 },
    { lat: 48.6332157, lng: 20.9806635 },
    { lat: 48.6330871, lng: 20.9805822 },
    { lat: 48.6329247, lng: 20.9806429 },
    { lat: 48.6324941, lng: 20.980624 },
    { lat: 48.6321453, lng: 20.9803576 },
    { lat: 48.6314739, lng: 20.980348 },
    { lat: 48.6313133, lng: 20.980425 },
    { lat: 48.6309762, lng: 20.9801353 },
    { lat: 48.63044, lng: 20.9801874 },
    { lat: 48.6300781, lng: 20.9801099 },
    { lat: 48.6298549, lng: 20.9799575 },
    { lat: 48.6297143, lng: 20.9800293 },
    { lat: 48.6294583, lng: 20.9797516 },
    { lat: 48.6289864, lng: 20.9796401 },
    { lat: 48.6287271, lng: 20.9798637 },
    { lat: 48.6283336, lng: 20.9803501 },
    { lat: 48.6281618, lng: 20.9802265 },
    { lat: 48.6278625, lng: 20.980362 },
    { lat: 48.6275014, lng: 20.9803684 },
    { lat: 48.6273928, lng: 20.9802536 },
    { lat: 48.627221, lng: 20.980263 },
    { lat: 48.6268587, lng: 20.9804699 },
    { lat: 48.6267273, lng: 20.980652 },
    { lat: 48.6263665, lng: 20.9802222 },
    { lat: 48.6262966, lng: 20.9802197 },
    { lat: 48.6261447, lng: 20.9803611 },
    { lat: 48.6258525, lng: 20.9802722 },
    { lat: 48.625812, lng: 20.9801035 },
    { lat: 48.6257603, lng: 20.9801113 },
    { lat: 48.6256791, lng: 20.9802719 },
    { lat: 48.6255232, lng: 20.9802222 },
    { lat: 48.6253417, lng: 20.9799769 },
    { lat: 48.6250909, lng: 20.9801514 },
    { lat: 48.6249674, lng: 20.9799214 },
    { lat: 48.6248759, lng: 20.9799148 },
    { lat: 48.6246316, lng: 20.9801155 },
    { lat: 48.6243167, lng: 20.980022 },
    { lat: 48.6241277, lng: 20.979894 },
    { lat: 48.6235904, lng: 20.9798081 },
    { lat: 48.6235496, lng: 20.9797664 },
    { lat: 48.6234838, lng: 20.9793149 },
    { lat: 48.6234241, lng: 20.9792792 },
    { lat: 48.6230279, lng: 20.9794498 },
    { lat: 48.6228702, lng: 20.9793127 },
    { lat: 48.622784, lng: 20.9790884 },
    { lat: 48.6224982, lng: 20.9788219 },
    { lat: 48.6224692, lng: 20.97876 },
    { lat: 48.6225198, lng: 20.9783538 },
    { lat: 48.622613, lng: 20.9781496 },
    { lat: 48.6225716, lng: 20.9777863 },
    { lat: 48.6224459, lng: 20.9776941 },
    { lat: 48.6217139, lng: 20.9775853 },
    { lat: 48.6214444, lng: 20.9773632 },
    { lat: 48.6210571, lng: 20.9772293 },
    { lat: 48.6206981, lng: 20.9773611 },
    { lat: 48.6206209, lng: 20.9773111 },
    { lat: 48.6205184, lng: 20.9767703 },
    { lat: 48.6201302, lng: 20.9767062 },
    { lat: 48.6198539, lng: 20.9764586 },
    { lat: 48.6194653, lng: 20.9762761 },
    { lat: 48.6194071, lng: 20.976151 },
    { lat: 48.6194074, lng: 20.9760276 },
    { lat: 48.6193049, lng: 20.9758877 },
    { lat: 48.6192133, lng: 20.9758804 },
    { lat: 48.6187974, lng: 20.9752912 },
    { lat: 48.6185546, lng: 20.9751983 },
    { lat: 48.618319, lng: 20.9748523 },
    { lat: 48.6180774, lng: 20.9748287 },
    { lat: 48.6174156, lng: 20.9740899 },
    { lat: 48.6171195, lng: 20.9737186 },
    { lat: 48.6169642, lng: 20.9732801 },
    { lat: 48.6166659, lng: 20.9732458 },
    { lat: 48.6167081, lng: 20.9728929 },
    { lat: 48.6166755, lng: 20.9727919 },
    { lat: 48.61646, lng: 20.9728083 },
    { lat: 48.61631, lng: 20.9727311 },
    { lat: 48.6162929, lng: 20.9723894 },
    { lat: 48.6161987, lng: 20.9722675 },
    { lat: 48.6158082, lng: 20.9721723 },
    { lat: 48.6157266, lng: 20.9719544 },
    { lat: 48.6155584, lng: 20.971806 },
    { lat: 48.6154592, lng: 20.9715976 },
    { lat: 48.615277, lng: 20.971529 },
    { lat: 48.6150071, lng: 20.9713023 },
    { lat: 48.6148991, lng: 20.9711401 },
    { lat: 48.6148585, lng: 20.970971 },
    { lat: 48.6147547, lng: 20.9709924 },
    { lat: 48.6146498, lng: 20.9707535 },
    { lat: 48.6131223, lng: 20.9722645 },
    { lat: 48.611227, lng: 20.9738918 },
    { lat: 48.6051252, lng: 20.9759143 },
    { lat: 48.6034274, lng: 20.9765436 },
    { lat: 48.6033532, lng: 20.9766453 },
    { lat: 48.6032437, lng: 20.9765032 },
    { lat: 48.603002, lng: 20.9755033 },
    { lat: 48.6024365, lng: 20.9717804 },
    { lat: 48.5966556, lng: 20.9735419 },
    { lat: 48.5936532, lng: 20.9741704 },
    { lat: 48.5934241, lng: 20.9740552 },
    { lat: 48.5933683, lng: 20.9740025 },
    { lat: 48.5930363, lng: 20.9737988 },
    { lat: 48.5921472, lng: 20.9731975 },
    { lat: 48.5912141, lng: 20.9724225 },
    { lat: 48.5898505, lng: 20.9724771 },
    { lat: 48.5838583, lng: 20.9719618 },
    { lat: 48.5831637, lng: 20.9719922 },
    { lat: 48.579143, lng: 20.9718587 },
    { lat: 48.5780021, lng: 20.9719372 },
    { lat: 48.5770951, lng: 20.9717431 },
    { lat: 48.5770926, lng: 20.9718019 },
    { lat: 48.5753957, lng: 20.9716195 },
    { lat: 48.573692, lng: 20.971599 },
    { lat: 48.5735124, lng: 20.9711801 },
    { lat: 48.5733667, lng: 20.9710635 },
    { lat: 48.5714838, lng: 20.9707005 },
    { lat: 48.5714821, lng: 20.9707529 },
    { lat: 48.5714451, lng: 20.971362 },
    { lat: 48.5712641, lng: 20.9724076 },
    { lat: 48.5711513, lng: 20.9736818 },
    { lat: 48.5711469, lng: 20.9748284 },
    { lat: 48.5708111, lng: 20.9760641 },
    { lat: 48.5706075, lng: 20.9765362 },
    { lat: 48.570216, lng: 20.9770525 },
    { lat: 48.5701017, lng: 20.9774027 },
    { lat: 48.5700833, lng: 20.977558 },
    { lat: 48.5701573, lng: 20.9781189 },
    { lat: 48.5700412, lng: 20.9793315 },
    { lat: 48.5684344, lng: 20.9847457 },
    { lat: 48.567189, lng: 20.9847664 },
    { lat: 48.5669064, lng: 20.9848998 },
    { lat: 48.564337, lng: 20.9854852 },
    { lat: 48.563464, lng: 20.9879266 },
    { lat: 48.5633326, lng: 20.9884788 },
    { lat: 48.5622735, lng: 20.9897291 },
    { lat: 48.561565, lng: 20.9903914 },
    { lat: 48.5610668, lng: 20.9924627 },
    { lat: 48.5610164, lng: 20.992985 },
    { lat: 48.560105, lng: 20.996038 },
    { lat: 48.5600164, lng: 20.9962199 },
    { lat: 48.5598691, lng: 20.9971378 },
    { lat: 48.5602836, lng: 20.9971741 },
    { lat: 48.5627972, lng: 20.9972516 },
    { lat: 48.5649916, lng: 20.9976365 },
    { lat: 48.5666422, lng: 20.9980535 },
    { lat: 48.5671931, lng: 20.9981374 },
    { lat: 48.567856, lng: 20.9985085 },
    { lat: 48.5684619, lng: 20.9987205 },
    { lat: 48.5691989, lng: 20.9991578 },
    { lat: 48.5700281, lng: 20.999516 },
    { lat: 48.5708874, lng: 20.9997359 },
    { lat: 48.5722936, lng: 20.9999831 },
    { lat: 48.5728866, lng: 21.000281 },
    { lat: 48.5732719, lng: 21.0005903 },
    { lat: 48.5746424, lng: 21.0021042 },
    { lat: 48.5754649, lng: 21.0031089 },
    { lat: 48.5758233, lng: 21.0033602 },
    { lat: 48.5762494, lng: 21.0034127 },
    { lat: 48.5771704, lng: 21.0037308 },
    { lat: 48.5774279, lng: 21.003893 },
    { lat: 48.5776843, lng: 21.0043905 },
    { lat: 48.5778898, lng: 21.0045755 },
    { lat: 48.5800629, lng: 21.0056524 },
    { lat: 48.5806175, lng: 21.0058104 },
    { lat: 48.5817117, lng: 21.0059893 },
    { lat: 48.583124, lng: 21.0060436 },
    { lat: 48.5843539, lng: 21.0058962 },
    { lat: 48.5873203, lng: 21.0058761 },
    { lat: 48.5874435, lng: 21.0057875 },
    { lat: 48.58794, lng: 21.0060989 },
    { lat: 48.5884117, lng: 21.006169 },
    { lat: 48.5889366, lng: 21.0060803 },
    { lat: 48.5897386, lng: 21.0055495 },
    { lat: 48.5899496, lng: 21.0054672 },
    { lat: 48.5926266, lng: 21.0045611 },
    { lat: 48.5934918, lng: 21.0038985 },
    { lat: 48.596081, lng: 21.0023887 },
    { lat: 48.5964681, lng: 21.0022105 },
    { lat: 48.5971052, lng: 21.0020409 },
    { lat: 48.5987139, lng: 21.0012321 },
    { lat: 48.6012776, lng: 21.0005465 },
    { lat: 48.6020163, lng: 21.0004527 },
    { lat: 48.602081, lng: 20.9996864 },
    { lat: 48.6022675, lng: 20.9986613 },
    { lat: 48.6023252, lng: 20.9980616 },
    { lat: 48.6031208, lng: 20.998424 },
    { lat: 48.6037563, lng: 20.9989533 },
    { lat: 48.6044888, lng: 20.9992184 },
    { lat: 48.6044433, lng: 20.9993776 },
    { lat: 48.6046364, lng: 21.0000694 },
    { lat: 48.605544, lng: 21.0014047 },
    { lat: 48.606281, lng: 21.0045169 },
    { lat: 48.6067374, lng: 21.0081324 },
    { lat: 48.6068377, lng: 21.0085833 },
    { lat: 48.607227, lng: 21.0092898 },
    { lat: 48.6073336, lng: 21.0099604 },
    { lat: 48.6075381, lng: 21.0104863 },
    { lat: 48.6077135, lng: 21.0116376 },
    { lat: 48.6083121, lng: 21.0116296 },
    { lat: 48.6084225, lng: 21.013713 },
    { lat: 48.6096071, lng: 21.0136591 },
    { lat: 48.6112851, lng: 21.0137161 },
    { lat: 48.6121114, lng: 21.0136198 },
    { lat: 48.6125313, lng: 21.0136558 },
    { lat: 48.61384, lng: 21.0133745 },
    { lat: 48.6160497, lng: 21.0135477 },
    { lat: 48.6169054, lng: 21.0137255 },
    { lat: 48.6176746, lng: 21.0140775 },
    { lat: 48.6185711, lng: 21.0146404 },
    { lat: 48.619742, lng: 21.015027 },
    { lat: 48.6204881, lng: 21.015565 },
    { lat: 48.6211593, lng: 21.0159464 },
    { lat: 48.6222035, lng: 21.0162486 },
    { lat: 48.6222535, lng: 21.0161907 },
    { lat: 48.6231097, lng: 21.0172695 },
    { lat: 48.623376, lng: 21.0175256 },
    { lat: 48.6244011, lng: 21.0178271 },
    { lat: 48.6244136, lng: 21.017761 },
    { lat: 48.625489, lng: 21.0177436 },
    { lat: 48.6258997, lng: 21.0175323 },
    { lat: 48.6268974, lng: 21.0177893 },
    { lat: 48.6270541, lng: 21.0178971 },
    { lat: 48.6274804, lng: 21.018429 },
    { lat: 48.6278019, lng: 21.0186948 },
    { lat: 48.6284417, lng: 21.0190492 },
    { lat: 48.628747, lng: 21.0192924 },
    { lat: 48.6302632, lng: 21.0200449 },
    { lat: 48.630711, lng: 21.0207557 },
    { lat: 48.630896, lng: 21.021173 },
    { lat: 48.6313257, lng: 21.0214742 },
    { lat: 48.6320418, lng: 21.0217517 },
    { lat: 48.6323921, lng: 21.0217325 },
    { lat: 48.6327903, lng: 21.0215482 },
    { lat: 48.6333087, lng: 21.0216417 },
    { lat: 48.6345971, lng: 21.0216574 },
    { lat: 48.6351781, lng: 21.0217312 },
    { lat: 48.6354095, lng: 21.021653 },
    { lat: 48.6357559, lng: 21.0214092 },
    { lat: 48.6360719, lng: 21.0213627 },
    { lat: 48.6374999, lng: 21.0215867 },
    { lat: 48.6380959, lng: 21.0217797 },
    { lat: 48.6386289, lng: 21.0218646 },
    { lat: 48.6396407, lng: 21.0222043 },
    { lat: 48.640134, lng: 21.0222452 },
    { lat: 48.6402639, lng: 21.0223287 },
    { lat: 48.6403568, lng: 21.0224858 },
    { lat: 48.6403943, lng: 21.0225631 },
  ],
  Milhosť: [
    { lat: 48.5435569, lng: 21.2378456 },
    { lat: 48.5425888, lng: 21.2379822 },
    { lat: 48.5429337, lng: 21.2386511 },
    { lat: 48.5428183, lng: 21.238737 },
    { lat: 48.539644, lng: 21.238838 },
    { lat: 48.5397227, lng: 21.2390407 },
    { lat: 48.538946, lng: 21.240088 },
    { lat: 48.538922, lng: 21.240233 },
    { lat: 48.538682, lng: 21.240638 },
    { lat: 48.538085, lng: 21.24174 },
    { lat: 48.537709, lng: 21.242444 },
    { lat: 48.537314, lng: 21.243182 },
    { lat: 48.53672, lng: 21.244258 },
    { lat: 48.536468, lng: 21.244698 },
    { lat: 48.536045, lng: 21.245479 },
    { lat: 48.5358, lng: 21.245837 },
    { lat: 48.535303, lng: 21.246399 },
    { lat: 48.535196, lng: 21.2465 },
    { lat: 48.534703, lng: 21.24701 },
    { lat: 48.534281, lng: 21.247533 },
    { lat: 48.534155, lng: 21.247746 },
    { lat: 48.533716, lng: 21.248583 },
    { lat: 48.533117, lng: 21.249695 },
    { lat: 48.532627, lng: 21.250642 },
    { lat: 48.5322739, lng: 21.2513101 },
    { lat: 48.532008, lng: 21.251813 },
    { lat: 48.5319625, lng: 21.2522759 },
    { lat: 48.5319533, lng: 21.2523913 },
    { lat: 48.531937, lng: 21.2525341 },
    { lat: 48.531554, lng: 21.253312 },
    { lat: 48.5312233, lng: 21.2539757 },
    { lat: 48.5311927, lng: 21.2540667 },
    { lat: 48.5311515, lng: 21.2541434 },
    { lat: 48.5311413, lng: 21.2542723 },
    { lat: 48.531067, lng: 21.254535 },
    { lat: 48.530839, lng: 21.255222 },
    { lat: 48.530421, lng: 21.256303 },
    { lat: 48.530373, lng: 21.256441 },
    { lat: 48.530302, lng: 21.256608 },
    { lat: 48.53013, lng: 21.257052 },
    { lat: 48.529967, lng: 21.257487 },
    { lat: 48.529793, lng: 21.257946 },
    { lat: 48.52949, lng: 21.25883 },
    { lat: 48.52938, lng: 21.25914 },
    { lat: 48.529157, lng: 21.259862 },
    { lat: 48.529098, lng: 21.260055 },
    { lat: 48.528963, lng: 21.260513 },
    { lat: 48.528914, lng: 21.260634 },
    { lat: 48.528808, lng: 21.260783 },
    { lat: 48.528712, lng: 21.260994 },
    { lat: 48.528524, lng: 21.261377 },
    { lat: 48.5285157, lng: 21.2613951 },
    { lat: 48.528497, lng: 21.261436 },
    { lat: 48.528377, lng: 21.261964 },
    { lat: 48.528307, lng: 21.262066 },
    { lat: 48.527891, lng: 21.262658 },
    { lat: 48.527417, lng: 21.263354 },
    { lat: 48.527273, lng: 21.263527 },
    { lat: 48.527272, lng: 21.263556 },
    { lat: 48.527339, lng: 21.263656 },
    { lat: 48.526719, lng: 21.265336 },
    { lat: 48.526275, lng: 21.266077 },
    { lat: 48.526147, lng: 21.266144 },
    { lat: 48.525589, lng: 21.268224 },
    { lat: 48.525354, lng: 21.269109 },
    { lat: 48.52533, lng: 21.269192 },
    { lat: 48.525405, lng: 21.269244 },
    { lat: 48.525425, lng: 21.26929 },
    { lat: 48.525504, lng: 21.269391 },
    { lat: 48.525602, lng: 21.269485 },
    { lat: 48.525695, lng: 21.269526 },
    { lat: 48.525719, lng: 21.269554 },
    { lat: 48.525785, lng: 21.269682 },
    { lat: 48.525796, lng: 21.269722 },
    { lat: 48.525832, lng: 21.269764 },
    { lat: 48.52599, lng: 21.269801 },
    { lat: 48.526183, lng: 21.26988 },
    { lat: 48.526393, lng: 21.269913 },
    { lat: 48.526596, lng: 21.270013 },
    { lat: 48.526763, lng: 21.270057 },
    { lat: 48.52686, lng: 21.270129 },
    { lat: 48.526911, lng: 21.270196 },
    { lat: 48.526949, lng: 21.270215 },
    { lat: 48.526982, lng: 21.270254 },
    { lat: 48.527119, lng: 21.270351 },
    { lat: 48.527168, lng: 21.270412 },
    { lat: 48.527272, lng: 21.270498 },
    { lat: 48.527319, lng: 21.270503 },
    { lat: 48.527367, lng: 21.270516 },
    { lat: 48.527423, lng: 21.270564 },
    { lat: 48.527374, lng: 21.270781 },
    { lat: 48.527081, lng: 21.272103 },
    { lat: 48.526767, lng: 21.273518 },
    { lat: 48.526334, lng: 21.275465 },
    { lat: 48.525949, lng: 21.277196 },
    { lat: 48.525925, lng: 21.277306 },
    { lat: 48.5259041, lng: 21.27752 },
    { lat: 48.5258266, lng: 21.2783125 },
    { lat: 48.525604, lng: 21.278719 },
    { lat: 48.52556, lng: 21.278916 },
    { lat: 48.525024, lng: 21.28128 },
    { lat: 48.524485, lng: 21.283656 },
    { lat: 48.5242297, lng: 21.2847815 },
    { lat: 48.523932, lng: 21.286094 },
    { lat: 48.524353, lng: 21.286925 },
    { lat: 48.5242737, lng: 21.2872106 },
    { lat: 48.523935, lng: 21.288431 },
    { lat: 48.523656, lng: 21.288611 },
    { lat: 48.523538, lng: 21.289042 },
    { lat: 48.523716, lng: 21.28919 },
    { lat: 48.5233526, lng: 21.2902638 },
    { lat: 48.52337, lng: 21.290448 },
    { lat: 48.523118, lng: 21.291362 },
    { lat: 48.522482, lng: 21.293667 },
    { lat: 48.522468, lng: 21.293719 },
    { lat: 48.5219866, lng: 21.2955372 },
    { lat: 48.521889, lng: 21.295818 },
    { lat: 48.521658, lng: 21.296663 },
    { lat: 48.521745, lng: 21.296684 },
    { lat: 48.521918, lng: 21.29668 },
    { lat: 48.52198, lng: 21.296674 },
    { lat: 48.5219897, lng: 21.2966677 },
    { lat: 48.522181, lng: 21.296633 },
    { lat: 48.522294, lng: 21.296641 },
    { lat: 48.52243, lng: 21.296511 },
    { lat: 48.522542, lng: 21.296516 },
    { lat: 48.522699, lng: 21.296605 },
    { lat: 48.522868, lng: 21.296765 },
    { lat: 48.522931, lng: 21.296871 },
    { lat: 48.522976, lng: 21.296963 },
    { lat: 48.523037, lng: 21.297088 },
    { lat: 48.523072, lng: 21.29722 },
    { lat: 48.523098, lng: 21.297428 },
    { lat: 48.52312, lng: 21.297825 },
    { lat: 48.523114, lng: 21.298043 },
    { lat: 48.52311, lng: 21.298173 },
    { lat: 48.523075, lng: 21.298395 },
    { lat: 48.523081, lng: 21.2985 },
    { lat: 48.523055, lng: 21.298726 },
    { lat: 48.522999, lng: 21.298979 },
    { lat: 48.522912, lng: 21.299298 },
    { lat: 48.522835, lng: 21.299537 },
    { lat: 48.52278, lng: 21.299767 },
    { lat: 48.52272, lng: 21.299963 },
    { lat: 48.522753, lng: 21.300199 },
    { lat: 48.522772, lng: 21.300651 },
    { lat: 48.522737, lng: 21.301145 },
    { lat: 48.522709, lng: 21.301397 },
    { lat: 48.522689, lng: 21.301625 },
    { lat: 48.522644, lng: 21.301921 },
    { lat: 48.522576, lng: 21.30201 },
    { lat: 48.522485, lng: 21.302111 },
    { lat: 48.522322, lng: 21.30233 },
    { lat: 48.522116, lng: 21.302457 },
    { lat: 48.522078, lng: 21.30255 },
    { lat: 48.522072, lng: 21.302694 },
    { lat: 48.522063, lng: 21.302952 },
    { lat: 48.522063, lng: 21.303174 },
    { lat: 48.522064, lng: 21.303306 },
    { lat: 48.52205, lng: 21.30354 },
    { lat: 48.52205, lng: 21.30371 },
    { lat: 48.52205, lng: 21.303961 },
    { lat: 48.522051, lng: 21.30414 },
    { lat: 48.522062, lng: 21.304361 },
    { lat: 48.522065, lng: 21.304639 },
    { lat: 48.5220581, lng: 21.3046888 },
    { lat: 48.522099, lng: 21.304847 },
    { lat: 48.52218, lng: 21.305214 },
    { lat: 48.522249, lng: 21.305488 },
    { lat: 48.522391, lng: 21.305857 },
    { lat: 48.522469, lng: 21.305925 },
    { lat: 48.522596, lng: 21.306141 },
    { lat: 48.522761, lng: 21.306345 },
    { lat: 48.522853, lng: 21.306592 },
    { lat: 48.523076, lng: 21.30685 },
    { lat: 48.523297, lng: 21.307053 },
    { lat: 48.523376, lng: 21.307092 },
    { lat: 48.523408, lng: 21.307169 },
    { lat: 48.523636, lng: 21.307424 },
    { lat: 48.523876, lng: 21.30761 },
    { lat: 48.524125, lng: 21.307671 },
    { lat: 48.524291, lng: 21.307661 },
    { lat: 48.524417, lng: 21.307724 },
    { lat: 48.524533, lng: 21.307819 },
    { lat: 48.524798, lng: 21.307966 },
    { lat: 48.524857, lng: 21.308118 },
    { lat: 48.525001, lng: 21.308296 },
    { lat: 48.525166, lng: 21.308395 },
    { lat: 48.525247, lng: 21.308434 },
    { lat: 48.525324, lng: 21.308386 },
    { lat: 48.525529, lng: 21.308478 },
    { lat: 48.525586, lng: 21.308551 },
    { lat: 48.525679, lng: 21.308591 },
    { lat: 48.525803, lng: 21.308628 },
    { lat: 48.525925, lng: 21.308677 },
    { lat: 48.526058, lng: 21.308673 },
    { lat: 48.526156, lng: 21.308651 },
    { lat: 48.526253, lng: 21.308617 },
    { lat: 48.526321, lng: 21.308552 },
    { lat: 48.52633, lng: 21.308512 },
    { lat: 48.52641, lng: 21.30831 },
    { lat: 48.526465, lng: 21.308119 },
    { lat: 48.526446, lng: 21.308016 },
    { lat: 48.526471, lng: 21.307938 },
    { lat: 48.526495, lng: 21.307769 },
    { lat: 48.5265, lng: 21.307712 },
    { lat: 48.526505, lng: 21.307481 },
    { lat: 48.526503, lng: 21.307405 },
    { lat: 48.526508, lng: 21.307317 },
    { lat: 48.526509, lng: 21.307226 },
    { lat: 48.526469, lng: 21.307094 },
    { lat: 48.526391, lng: 21.306931 },
    { lat: 48.526276, lng: 21.3067 },
    { lat: 48.526201, lng: 21.30658 },
    { lat: 48.526055, lng: 21.306559 },
    { lat: 48.52583, lng: 21.306557 },
    { lat: 48.525654, lng: 21.30651 },
    { lat: 48.525501, lng: 21.306425 },
    { lat: 48.525465, lng: 21.306371 },
    { lat: 48.525383, lng: 21.306215 },
    { lat: 48.5253, lng: 21.306051 },
    { lat: 48.525231, lng: 21.305881 },
    { lat: 48.525168, lng: 21.305705 },
    { lat: 48.525138, lng: 21.30557 },
    { lat: 48.525112, lng: 21.305284 },
    { lat: 48.525089, lng: 21.304999 },
    { lat: 48.525084, lng: 21.304722 },
    { lat: 48.525094, lng: 21.304597 },
    { lat: 48.525051, lng: 21.304282 },
    { lat: 48.524976, lng: 21.304022 },
    { lat: 48.524851, lng: 21.303801 },
    { lat: 48.524794, lng: 21.303619 },
    { lat: 48.524739, lng: 21.30341 },
    { lat: 48.524733, lng: 21.303324 },
    { lat: 48.524733, lng: 21.303091 },
    { lat: 48.524782, lng: 21.302789 },
    { lat: 48.524814, lng: 21.302569 },
    { lat: 48.524865, lng: 21.302265 },
    { lat: 48.524899, lng: 21.302108 },
    { lat: 48.525055, lng: 21.301863 },
    { lat: 48.525127, lng: 21.301774 },
    { lat: 48.525167, lng: 21.301735 },
    { lat: 48.525217, lng: 21.301721 },
    { lat: 48.525342, lng: 21.301686 },
    { lat: 48.525392, lng: 21.301688 },
    { lat: 48.525476, lng: 21.301736 },
    { lat: 48.525632, lng: 21.301879 },
    { lat: 48.52579, lng: 21.302108 },
    { lat: 48.525974, lng: 21.302181 },
    { lat: 48.52614, lng: 21.302299 },
    { lat: 48.526297, lng: 21.302392 },
    { lat: 48.526507, lng: 21.302483 },
    { lat: 48.526662, lng: 21.302583 },
    { lat: 48.526766, lng: 21.302647 },
    { lat: 48.526893, lng: 21.302762 },
    { lat: 48.526998, lng: 21.302868 },
    { lat: 48.527144, lng: 21.303028 },
    { lat: 48.527251, lng: 21.303122 },
    { lat: 48.527374, lng: 21.303288 },
    { lat: 48.527547, lng: 21.303556 },
    { lat: 48.527647, lng: 21.30378 },
    { lat: 48.527758, lng: 21.30399 },
    { lat: 48.527876, lng: 21.304213 },
    { lat: 48.528023, lng: 21.304443 },
    { lat: 48.528152, lng: 21.304642 },
    { lat: 48.528326, lng: 21.30491 },
    { lat: 48.528409, lng: 21.305016 },
    { lat: 48.528558, lng: 21.305248 },
    { lat: 48.52858, lng: 21.305246 },
    { lat: 48.528712, lng: 21.305376 },
    { lat: 48.528819, lng: 21.305531 },
    { lat: 48.528928, lng: 21.305668 },
    { lat: 48.529078, lng: 21.305864 },
    { lat: 48.529178, lng: 21.305997 },
    { lat: 48.529245, lng: 21.306063 },
    { lat: 48.529267, lng: 21.30612 },
    { lat: 48.529374, lng: 21.306355 },
    { lat: 48.529449, lng: 21.306498 },
    { lat: 48.529483, lng: 21.306611 },
    { lat: 48.529534, lng: 21.306879 },
    { lat: 48.52959, lng: 21.307141 },
    { lat: 48.529612, lng: 21.307258 },
    { lat: 48.529624, lng: 21.307426 },
    { lat: 48.529636, lng: 21.307563 },
    { lat: 48.529656, lng: 21.307802 },
    { lat: 48.529672, lng: 21.307918 },
    { lat: 48.529663, lng: 21.308228 },
    { lat: 48.529654, lng: 21.308365 },
    { lat: 48.529636, lng: 21.308481 },
    { lat: 48.529569, lng: 21.30882 },
    { lat: 48.529541, lng: 21.309039 },
    { lat: 48.52953, lng: 21.30928 },
    { lat: 48.529539, lng: 21.309451 },
    { lat: 48.529572, lng: 21.30966 },
    { lat: 48.529597, lng: 21.30984 },
    { lat: 48.5296123, lng: 21.3099514 },
    { lat: 48.529635, lng: 21.310117 },
    { lat: 48.529688, lng: 21.310304 },
    { lat: 48.529722, lng: 21.310408 },
    { lat: 48.529795, lng: 21.310646 },
    { lat: 48.529929, lng: 21.310899 },
    { lat: 48.530049, lng: 21.311003 },
    { lat: 48.530145, lng: 21.31111 },
    { lat: 48.530262, lng: 21.311232 },
    { lat: 48.53038, lng: 21.311338 },
    { lat: 48.530418, lng: 21.311375 },
    { lat: 48.534422, lng: 21.3081256 },
    { lat: 48.5346269, lng: 21.3080532 },
    { lat: 48.534821, lng: 21.3080685 },
    { lat: 48.5351003, lng: 21.3082654 },
    { lat: 48.5353087, lng: 21.3086029 },
    { lat: 48.5354837, lng: 21.3081415 },
    { lat: 48.5356705, lng: 21.3074017 },
    { lat: 48.5356918, lng: 21.307032 },
    { lat: 48.5356241, lng: 21.3063985 },
    { lat: 48.5354966, lng: 21.3060264 },
    { lat: 48.5352648, lng: 21.305693 },
    { lat: 48.5373754, lng: 21.2980629 },
    { lat: 48.5375091, lng: 21.2978761 },
    { lat: 48.5377563, lng: 21.2978241 },
    { lat: 48.5392741, lng: 21.2983252 },
    { lat: 48.5420303, lng: 21.291764 },
    { lat: 48.5410318, lng: 21.2914635 },
    { lat: 48.5410153, lng: 21.2892375 },
    { lat: 48.5410437, lng: 21.2865027 },
    { lat: 48.5411163, lng: 21.2853526 },
    { lat: 48.5412259, lng: 21.2844152 },
    { lat: 48.5413201, lng: 21.2841121 },
    { lat: 48.5414566, lng: 21.2833159 },
    { lat: 48.5415231, lng: 21.282297 },
    { lat: 48.5415742, lng: 21.2820952 },
    { lat: 48.5415443, lng: 21.2814538 },
    { lat: 48.5417971, lng: 21.2767634 },
    { lat: 48.5419939, lng: 21.2751156 },
    { lat: 48.5422092, lng: 21.2723569 },
    { lat: 48.5421278, lng: 21.2685912 },
    { lat: 48.5419522, lng: 21.2677547 },
    { lat: 48.5420623, lng: 21.2674195 },
    { lat: 48.5419324, lng: 21.266751 },
    { lat: 48.5418482, lng: 21.2667967 },
    { lat: 48.5418642, lng: 21.2650556 },
    { lat: 48.5418031, lng: 21.2643535 },
    { lat: 48.5418124, lng: 21.2632764 },
    { lat: 48.5418969, lng: 21.2622178 },
    { lat: 48.5419318, lng: 21.2609467 },
    { lat: 48.541952, lng: 21.2572861 },
    { lat: 48.5421201, lng: 21.2565302 },
    { lat: 48.5421816, lng: 21.2553187 },
    { lat: 48.5421718, lng: 21.2549358 },
    { lat: 48.5420968, lng: 21.2546907 },
    { lat: 48.5421429, lng: 21.2546186 },
    { lat: 48.5421039, lng: 21.2539117 },
    { lat: 48.5421245, lng: 21.2528256 },
    { lat: 48.5421864, lng: 21.2518886 },
    { lat: 48.5422439, lng: 21.2515297 },
    { lat: 48.5423819, lng: 21.2510818 },
    { lat: 48.5423839, lng: 21.2508891 },
    { lat: 48.5422445, lng: 21.2504642 },
    { lat: 48.5427049, lng: 21.2499172 },
    { lat: 48.5426267, lng: 21.249792 },
    { lat: 48.5428355, lng: 21.2494537 },
    { lat: 48.5429564, lng: 21.2491404 },
    { lat: 48.5429139, lng: 21.2490774 },
    { lat: 48.5436848, lng: 21.247992 },
    { lat: 48.5438655, lng: 21.2477573 },
    { lat: 48.5448751, lng: 21.2474012 },
    { lat: 48.5451162, lng: 21.2471164 },
    { lat: 48.5452557, lng: 21.246496 },
    { lat: 48.5447013, lng: 21.2441927 },
    { lat: 48.544216, lng: 21.2413319 },
    { lat: 48.5441668, lng: 21.2408346 },
    { lat: 48.5439978, lng: 21.2399969 },
    { lat: 48.5437365, lng: 21.2388018 },
    { lat: 48.5435597, lng: 21.2385475 },
    { lat: 48.5435553, lng: 21.2380037 },
    { lat: 48.5435569, lng: 21.2378456 },
  ],
  KošickáBelá: [
    { lat: 48.836654, lng: 21.072918 },
    { lat: 48.836507, lng: 21.072143 },
    { lat: 48.836451, lng: 21.071548 },
    { lat: 48.836182, lng: 21.070938 },
    { lat: 48.835975, lng: 21.070577 },
    { lat: 48.835613, lng: 21.06974 },
    { lat: 48.835444, lng: 21.069132 },
    { lat: 48.835384, lng: 21.068813 },
    { lat: 48.835275, lng: 21.068231 },
    { lat: 48.835144, lng: 21.068174 },
    { lat: 48.835077, lng: 21.068049 },
    { lat: 48.835036, lng: 21.067956 },
    { lat: 48.835006, lng: 21.067883 },
    { lat: 48.834999, lng: 21.067711 },
    { lat: 48.834815, lng: 21.067362 },
    { lat: 48.834729, lng: 21.067119 },
    { lat: 48.834649, lng: 21.066934 },
    { lat: 48.834578, lng: 21.066814 },
    { lat: 48.834484, lng: 21.066544 },
    { lat: 48.834444, lng: 21.066092 },
    { lat: 48.834396, lng: 21.065867 },
    { lat: 48.834427, lng: 21.065552 },
    { lat: 48.834452, lng: 21.06538 },
    { lat: 48.834431, lng: 21.065262 },
    { lat: 48.834458, lng: 21.065123 },
    { lat: 48.834418, lng: 21.064723 },
    { lat: 48.83435, lng: 21.064569 },
    { lat: 48.834318, lng: 21.064347 },
    { lat: 48.834364, lng: 21.064225 },
    { lat: 48.834353, lng: 21.064056 },
    { lat: 48.834302, lng: 21.063919 },
    { lat: 48.834187, lng: 21.063795 },
    { lat: 48.834155, lng: 21.063728 },
    { lat: 48.834156, lng: 21.063592 },
    { lat: 48.834114, lng: 21.063396 },
    { lat: 48.834034, lng: 21.06322 },
    { lat: 48.834054, lng: 21.063081 },
    { lat: 48.834022, lng: 21.063023 },
    { lat: 48.834012, lng: 21.062933 },
    { lat: 48.834006, lng: 21.062762 },
    { lat: 48.834044, lng: 21.062286 },
    { lat: 48.834121, lng: 21.062127 },
    { lat: 48.83414, lng: 21.06202 },
    { lat: 48.834119, lng: 21.061911 },
    { lat: 48.834149, lng: 21.061756 },
    { lat: 48.834231, lng: 21.061686 },
    { lat: 48.834272, lng: 21.06158 },
    { lat: 48.834242, lng: 21.061439 },
    { lat: 48.834215, lng: 21.061319 },
    { lat: 48.834224, lng: 21.061178 },
    { lat: 48.834184, lng: 21.061016 },
    { lat: 48.834176, lng: 21.060848 },
    { lat: 48.834201, lng: 21.060745 },
    { lat: 48.834172, lng: 21.060483 },
    { lat: 48.83412, lng: 21.060437 },
    { lat: 48.83407, lng: 21.060288 },
    { lat: 48.834131, lng: 21.060194 },
    { lat: 48.834091, lng: 21.059745 },
    { lat: 48.834102, lng: 21.059599 },
    { lat: 48.834095, lng: 21.059343 },
    { lat: 48.834119, lng: 21.05913 },
    { lat: 48.834035, lng: 21.059034 },
    { lat: 48.83394, lng: 21.058935 },
    { lat: 48.833846, lng: 21.05882 },
    { lat: 48.833768, lng: 21.058668 },
    { lat: 48.833713, lng: 21.058648 },
    { lat: 48.83363, lng: 21.058456 },
    { lat: 48.833633, lng: 21.058359 },
    { lat: 48.833567, lng: 21.058221 },
    { lat: 48.833553, lng: 21.058146 },
    { lat: 48.833541, lng: 21.058154 },
    { lat: 48.833279, lng: 21.058255 },
    { lat: 48.83312, lng: 21.05823 },
    { lat: 48.832993, lng: 21.058244 },
    { lat: 48.832899, lng: 21.058189 },
    { lat: 48.832896, lng: 21.058131 },
    { lat: 48.83274, lng: 21.058156 },
    { lat: 48.832782, lng: 21.057841 },
    { lat: 48.832817, lng: 21.057559 },
    { lat: 48.832833, lng: 21.057295 },
    { lat: 48.832856, lng: 21.056889 },
    { lat: 48.832905, lng: 21.056466 },
    { lat: 48.83296, lng: 21.056058 },
    { lat: 48.83298, lng: 21.055702 },
    { lat: 48.833019, lng: 21.055218 },
    { lat: 48.833039, lng: 21.054904 },
    { lat: 48.833068, lng: 21.054635 },
    { lat: 48.833069, lng: 21.054616 },
    { lat: 48.832937, lng: 21.054551 },
    { lat: 48.832814, lng: 21.054512 },
    { lat: 48.832741, lng: 21.054371 },
    { lat: 48.832707, lng: 21.054253 },
    { lat: 48.832608, lng: 21.054101 },
    { lat: 48.832533, lng: 21.054022 },
    { lat: 48.832355, lng: 21.053885 },
    { lat: 48.832265, lng: 21.053737 },
    { lat: 48.832095, lng: 21.053563 },
    { lat: 48.832054, lng: 21.053452 },
    { lat: 48.831974, lng: 21.053325 },
    { lat: 48.831854, lng: 21.053089 },
    { lat: 48.831834, lng: 21.053086 },
    { lat: 48.831642, lng: 21.052853 },
    { lat: 48.831551, lng: 21.053016 },
    { lat: 48.831446, lng: 21.053211 },
    { lat: 48.831388, lng: 21.053437 },
    { lat: 48.831171, lng: 21.05437 },
    { lat: 48.83099, lng: 21.055158 },
    { lat: 48.830865, lng: 21.055672 },
    { lat: 48.830795, lng: 21.055935 },
    { lat: 48.830645, lng: 21.056335 },
    { lat: 48.830548, lng: 21.056399 },
    { lat: 48.830401, lng: 21.057609 },
    { lat: 48.830373, lng: 21.057784 },
    { lat: 48.830326, lng: 21.058321 },
    { lat: 48.830304, lng: 21.058685 },
    { lat: 48.830269, lng: 21.05904 },
    { lat: 48.830261, lng: 21.059546 },
    { lat: 48.830299, lng: 21.060311 },
    { lat: 48.83023, lng: 21.060346 },
    { lat: 48.82941, lng: 21.060774 },
    { lat: 48.828743, lng: 21.061108 },
    { lat: 48.82878, lng: 21.060936 },
    { lat: 48.828795, lng: 21.060869 },
    { lat: 48.828816, lng: 21.060778 },
    { lat: 48.828847, lng: 21.060633 },
    { lat: 48.828915, lng: 21.060304 },
    { lat: 48.828919, lng: 21.060282 },
    { lat: 48.82863, lng: 21.060292 },
    { lat: 48.828215, lng: 21.060226 },
    { lat: 48.827968, lng: 21.060213 },
    { lat: 48.827513, lng: 21.060275 },
    { lat: 48.827247, lng: 21.060303 },
    { lat: 48.827192, lng: 21.06029 },
    { lat: 48.826909, lng: 21.060126 },
    { lat: 48.826629, lng: 21.060034 },
    { lat: 48.826434, lng: 21.059981 },
    { lat: 48.826152, lng: 21.059876 },
    { lat: 48.826021, lng: 21.059802 },
    { lat: 48.826013, lng: 21.059817 },
    { lat: 48.825602, lng: 21.06021 },
    { lat: 48.825289, lng: 21.060686 },
    { lat: 48.824839, lng: 21.061286 },
    { lat: 48.823887, lng: 21.061856 },
    { lat: 48.823656, lng: 21.06201 },
    { lat: 48.823321, lng: 21.062239 },
    { lat: 48.823167, lng: 21.062346 },
    { lat: 48.822973, lng: 21.062524 },
    { lat: 48.822843, lng: 21.062645 },
    { lat: 48.822572, lng: 21.062996 },
    { lat: 48.822545, lng: 21.063045 },
    { lat: 48.822503, lng: 21.063097 },
    { lat: 48.822491, lng: 21.06311 },
    { lat: 48.822474, lng: 21.063188 },
    { lat: 48.822489, lng: 21.063269 },
    { lat: 48.822449, lng: 21.063388 },
    { lat: 48.822421, lng: 21.063567 },
    { lat: 48.822336, lng: 21.063788 },
    { lat: 48.822179, lng: 21.064 },
    { lat: 48.822092, lng: 21.064073 },
    { lat: 48.82201, lng: 21.064062 },
    { lat: 48.821934, lng: 21.064152 },
    { lat: 48.82192, lng: 21.064173 },
    { lat: 48.8220848, lng: 21.0647404 },
    { lat: 48.8221754, lng: 21.064895 },
    { lat: 48.8222335, lng: 21.0655804 },
    { lat: 48.8223871, lng: 21.065835 },
    { lat: 48.8221601, lng: 21.065673 },
    { lat: 48.8218233, lng: 21.0658385 },
    { lat: 48.8216617, lng: 21.0658152 },
    { lat: 48.8214998, lng: 21.0656168 },
    { lat: 48.821292, lng: 21.0656288 },
    { lat: 48.8211234, lng: 21.0657777 },
    { lat: 48.8208838, lng: 21.0656752 },
    { lat: 48.8206843, lng: 21.0656701 },
    { lat: 48.8205731, lng: 21.0657441 },
    { lat: 48.820175, lng: 21.0655788 },
    { lat: 48.8198565, lng: 21.0653343 },
    { lat: 48.8195795, lng: 21.0649553 },
    { lat: 48.8194808, lng: 21.0649651 },
    { lat: 48.8192937, lng: 21.0647073 },
    { lat: 48.8189076, lng: 21.064396 },
    { lat: 48.8187935, lng: 21.064181 },
    { lat: 48.8186407, lng: 21.0640413 },
    { lat: 48.8183799, lng: 21.0640593 },
    { lat: 48.818191, lng: 21.0642344 },
    { lat: 48.8177814, lng: 21.0638568 },
    { lat: 48.8177225, lng: 21.0633604 },
    { lat: 48.8174753, lng: 21.0633007 },
    { lat: 48.8174045, lng: 21.062891 },
    { lat: 48.8173158, lng: 21.0626519 },
    { lat: 48.817213, lng: 21.0626459 },
    { lat: 48.817102, lng: 21.0627278 },
    { lat: 48.8165636, lng: 21.062264 },
    { lat: 48.8164655, lng: 21.0621192 },
    { lat: 48.8163242, lng: 21.0621701 },
    { lat: 48.8161031, lng: 21.0619477 },
    { lat: 48.8157246, lng: 21.061898 },
    { lat: 48.8155509, lng: 21.0616482 },
    { lat: 48.8153076, lng: 21.0614352 },
    { lat: 48.8151859, lng: 21.0614993 },
    { lat: 48.8147804, lng: 21.0612442 },
    { lat: 48.8143266, lng: 21.0613077 },
    { lat: 48.8140627, lng: 21.0610478 },
    { lat: 48.8136831, lng: 21.0610082 },
    { lat: 48.8135165, lng: 21.0609024 },
    { lat: 48.8134734, lng: 21.0605132 },
    { lat: 48.8132255, lng: 21.0602935 },
    { lat: 48.8133052, lng: 21.0596287 },
    { lat: 48.8129774, lng: 21.0591004 },
    { lat: 48.8122624, lng: 21.0584002 },
    { lat: 48.8121469, lng: 21.0581678 },
    { lat: 48.8120436, lng: 21.0580738 },
    { lat: 48.8117394, lng: 21.0580693 },
    { lat: 48.8113788, lng: 21.0581548 },
    { lat: 48.8111283, lng: 21.0581046 },
    { lat: 48.8110323, lng: 21.0584024 },
    { lat: 48.8106645, lng: 21.0584495 },
    { lat: 48.810386, lng: 21.0578757 },
    { lat: 48.8102998, lng: 21.0578215 },
    { lat: 48.8099853, lng: 21.0577046 },
    { lat: 48.8098039, lng: 21.0577314 },
    { lat: 48.8095377, lng: 21.0575727 },
    { lat: 48.8091847, lng: 21.0571713 },
    { lat: 48.8088247, lng: 21.057176 },
    { lat: 48.8087258, lng: 21.0571148 },
    { lat: 48.8086454, lng: 21.0568248 },
    { lat: 48.8082458, lng: 21.0568532 },
    { lat: 48.8077423, lng: 21.056773 },
    { lat: 48.80758, lng: 21.0568747 },
    { lat: 48.8074604, lng: 21.056483 },
    { lat: 48.8073613, lng: 21.0564447 },
    { lat: 48.8070936, lng: 21.056624 },
    { lat: 48.806931, lng: 21.0565361 },
    { lat: 48.8067671, lng: 21.0566876 },
    { lat: 48.8064288, lng: 21.0567703 },
    { lat: 48.8062177, lng: 21.0567479 },
    { lat: 48.8059728, lng: 21.0566305 },
    { lat: 48.8058208, lng: 21.0564887 },
    { lat: 48.8056384, lng: 21.0564495 },
    { lat: 48.8054245, lng: 21.0562408 },
    { lat: 48.8052811, lng: 21.0562972 },
    { lat: 48.8052239, lng: 21.0564383 },
    { lat: 48.8051049, lng: 21.0564511 },
    { lat: 48.8050154, lng: 21.056331 },
    { lat: 48.804895, lng: 21.0562975 },
    { lat: 48.8047062, lng: 21.0564087 },
    { lat: 48.8046327, lng: 21.0565717 },
    { lat: 48.804488, lng: 21.0565839 },
    { lat: 48.8042723, lng: 21.0564261 },
    { lat: 48.8041414, lng: 21.056448 },
    { lat: 48.8038301, lng: 21.056809 },
    { lat: 48.8036064, lng: 21.0569214 },
    { lat: 48.8031629, lng: 21.056846 },
    { lat: 48.802996, lng: 21.0569074 },
    { lat: 48.8029291, lng: 21.0569966 },
    { lat: 48.8023713, lng: 21.0571104 },
    { lat: 48.8021539, lng: 21.0572805 },
    { lat: 48.8019751, lng: 21.0575612 },
    { lat: 48.8016133, lng: 21.0575836 },
    { lat: 48.8015137, lng: 21.0577388 },
    { lat: 48.8014234, lng: 21.0580369 },
    { lat: 48.8010427, lng: 21.058 },
    { lat: 48.8002276, lng: 21.0580565 },
    { lat: 48.7997317, lng: 21.0582117 },
    { lat: 48.7993633, lng: 21.0582334 },
    { lat: 48.7990876, lng: 21.0578563 },
    { lat: 48.7987959, lng: 21.057952 },
    { lat: 48.7985734, lng: 21.0578272 },
    { lat: 48.7983721, lng: 21.0578297 },
    { lat: 48.7982733, lng: 21.0579114 },
    { lat: 48.7978941, lng: 21.0578393 },
    { lat: 48.7977626, lng: 21.0578799 },
    { lat: 48.7975248, lng: 21.0578048 },
    { lat: 48.7972907, lng: 21.0577991 },
    { lat: 48.7971254, lng: 21.0578746 },
    { lat: 48.7968401, lng: 21.0576892 },
    { lat: 48.7966928, lng: 21.0573785 },
    { lat: 48.7958254, lng: 21.056766 },
    { lat: 48.7955099, lng: 21.0567607 },
    { lat: 48.7952518, lng: 21.0566796 },
    { lat: 48.7951221, lng: 21.0565615 },
    { lat: 48.7948323, lng: 21.0565499 },
    { lat: 48.7941465, lng: 21.0560889 },
    { lat: 48.793938, lng: 21.0560795 },
    { lat: 48.7935025, lng: 21.0562569 },
    { lat: 48.7932604, lng: 21.0564577 },
    { lat: 48.7930836, lng: 21.0564517 },
    { lat: 48.7924702, lng: 21.0569386 },
    { lat: 48.7921269, lng: 21.0570785 },
    { lat: 48.7918256, lng: 21.0570542 },
    { lat: 48.7914351, lng: 21.0571572 },
    { lat: 48.7900253, lng: 21.0582754 },
    { lat: 48.7900131, lng: 21.0584584 },
    { lat: 48.7899629, lng: 21.0585389 },
    { lat: 48.7896253, lng: 21.058816 },
    { lat: 48.7891548, lng: 21.0590917 },
    { lat: 48.7889124, lng: 21.0591451 },
    { lat: 48.7887404, lng: 21.0593607 },
    { lat: 48.787908, lng: 21.0597575 },
    { lat: 48.787709, lng: 21.0596275 },
    { lat: 48.7875453, lng: 21.05964 },
    { lat: 48.7872056, lng: 21.0598574 },
    { lat: 48.7868818, lng: 21.0602467 },
    { lat: 48.7864612, lng: 21.0604322 },
    { lat: 48.7861642, lng: 21.0607788 },
    { lat: 48.7860875, lng: 21.0609512 },
    { lat: 48.7857623, lng: 21.0615765 },
    { lat: 48.7857342, lng: 21.0617773 },
    { lat: 48.785558, lng: 21.0622851 },
    { lat: 48.7856029, lng: 21.0624827 },
    { lat: 48.7853735, lng: 21.0635663 },
    { lat: 48.7852922, lng: 21.0638643 },
    { lat: 48.7852149, lng: 21.0639478 },
    { lat: 48.7852303, lng: 21.064522 },
    { lat: 48.7851026, lng: 21.0648517 },
    { lat: 48.7851344, lng: 21.0649657 },
    { lat: 48.7849052, lng: 21.0655323 },
    { lat: 48.7848065, lng: 21.0662696 },
    { lat: 48.7846799, lng: 21.0665681 },
    { lat: 48.7847161, lng: 21.0670229 },
    { lat: 48.7846525, lng: 21.0672147 },
    { lat: 48.7846661, lng: 21.0673561 },
    { lat: 48.7845223, lng: 21.0676698 },
    { lat: 48.7844468, lng: 21.0682568 },
    { lat: 48.7843783, lng: 21.0683982 },
    { lat: 48.7843694, lng: 21.0686847 },
    { lat: 48.7844598, lng: 21.0691448 },
    { lat: 48.7844244, lng: 21.0695443 },
    { lat: 48.7845197, lng: 21.0696743 },
    { lat: 48.7845371, lng: 21.06986 },
    { lat: 48.7846379, lng: 21.0699528 },
    { lat: 48.7846534, lng: 21.070154 },
    { lat: 48.784717, lng: 21.0702735 },
    { lat: 48.7848434, lng: 21.0711827 },
    { lat: 48.7848121, lng: 21.0713296 },
    { lat: 48.784886, lng: 21.0714537 },
    { lat: 48.7849546, lng: 21.0719036 },
    { lat: 48.7850617, lng: 21.0722526 },
    { lat: 48.7852207, lng: 21.0724363 },
    { lat: 48.7853471, lng: 21.0728288 },
    { lat: 48.7855521, lng: 21.0730763 },
    { lat: 48.7856065, lng: 21.0733371 },
    { lat: 48.7855497, lng: 21.0735633 },
    { lat: 48.7856252, lng: 21.0738774 },
    { lat: 48.785479, lng: 21.0743005 },
    { lat: 48.7849181, lng: 21.0747715 },
    { lat: 48.7847538, lng: 21.0748034 },
    { lat: 48.7846427, lng: 21.0751953 },
    { lat: 48.7843111, lng: 21.0754201 },
    { lat: 48.7841395, lng: 21.0757487 },
    { lat: 48.7840231, lng: 21.0757327 },
    { lat: 48.7837463, lng: 21.0760088 },
    { lat: 48.7836524, lng: 21.0766932 },
    { lat: 48.7837035, lng: 21.0780284 },
    { lat: 48.7836775, lng: 21.0786092 },
    { lat: 48.7828833, lng: 21.0800496 },
    { lat: 48.7827599, lng: 21.0800973 },
    { lat: 48.7826749, lng: 21.0806343 },
    { lat: 48.7826369, lng: 21.0811268 },
    { lat: 48.7826836, lng: 21.081739 },
    { lat: 48.7826167, lng: 21.0821447 },
    { lat: 48.7826515, lng: 21.082656 },
    { lat: 48.7825289, lng: 21.0833218 },
    { lat: 48.7828935, lng: 21.0848154 },
    { lat: 48.7827938, lng: 21.0851094 },
    { lat: 48.7827244, lng: 21.0851866 },
    { lat: 48.7824494, lng: 21.0850721 },
    { lat: 48.7821839, lng: 21.0848676 },
    { lat: 48.7819907, lng: 21.0846339 },
    { lat: 48.7816138, lng: 21.0840127 },
    { lat: 48.7811912, lng: 21.0837224 },
    { lat: 48.780904, lng: 21.0834318 },
    { lat: 48.7806358, lng: 21.0827979 },
    { lat: 48.7802877, lng: 21.0826506 },
    { lat: 48.7800489, lng: 21.082749 },
    { lat: 48.7799761, lng: 21.0831105 },
    { lat: 48.7797075, lng: 21.0837795 },
    { lat: 48.7796587, lng: 21.0841818 },
    { lat: 48.7794507, lng: 21.0845776 },
    { lat: 48.77951, lng: 21.085294 },
    { lat: 48.7796414, lng: 21.0858781 },
    { lat: 48.7783103, lng: 21.087833 },
    { lat: 48.7780894, lng: 21.0879668 },
    { lat: 48.7777154, lng: 21.0884437 },
    { lat: 48.7776138, lng: 21.0885047 },
    { lat: 48.7773719, lng: 21.0884027 },
    { lat: 48.7772346, lng: 21.0885767 },
    { lat: 48.7769897, lng: 21.08915 },
    { lat: 48.776106, lng: 21.0903626 },
    { lat: 48.7758052, lng: 21.0909087 },
    { lat: 48.7754391, lng: 21.091314 },
    { lat: 48.7751576, lng: 21.0917616 },
    { lat: 48.7746069, lng: 21.0928367 },
    { lat: 48.774403, lng: 21.0934255 },
    { lat: 48.7742905, lng: 21.0939498 },
    { lat: 48.7742523, lng: 21.0946149 },
    { lat: 48.7745339, lng: 21.0961573 },
    { lat: 48.7744734, lng: 21.0967259 },
    { lat: 48.7740159, lng: 21.097889 },
    { lat: 48.7735076, lng: 21.1011147 },
    { lat: 48.7732184, lng: 21.1018154 },
    { lat: 48.7726566, lng: 21.1028262 },
    { lat: 48.7722078, lng: 21.1040567 },
    { lat: 48.7721084, lng: 21.1046018 },
    { lat: 48.7720534, lng: 21.104573 },
    { lat: 48.7718791, lng: 21.104827 },
    { lat: 48.7718299, lng: 21.10508 },
    { lat: 48.7718246, lng: 21.1054715 },
    { lat: 48.7720382, lng: 21.106027 },
    { lat: 48.7719967, lng: 21.1067312 },
    { lat: 48.7717666, lng: 21.1071663 },
    { lat: 48.7716245, lng: 21.1076531 },
    { lat: 48.771508, lng: 21.1077355 },
    { lat: 48.7714147, lng: 21.1079871 },
    { lat: 48.7713869, lng: 21.108209 },
    { lat: 48.7712468, lng: 21.1084358 },
    { lat: 48.7709259, lng: 21.1095894 },
    { lat: 48.7707369, lng: 21.1100804 },
    { lat: 48.7710734, lng: 21.1110985 },
    { lat: 48.7712877, lng: 21.1119844 },
    { lat: 48.7712387, lng: 21.1122509 },
    { lat: 48.7716103, lng: 21.1127096 },
    { lat: 48.7717591, lng: 21.1127543 },
    { lat: 48.7721878, lng: 21.1131321 },
    { lat: 48.7724359, lng: 21.1131834 },
    { lat: 48.7727641, lng: 21.1134836 },
    { lat: 48.7728476, lng: 21.113769 },
    { lat: 48.7740849, lng: 21.1141387 },
    { lat: 48.774282, lng: 21.1144904 },
    { lat: 48.774497, lng: 21.1146644 },
    { lat: 48.7751744, lng: 21.1154685 },
    { lat: 48.7753718, lng: 21.1155197 },
    { lat: 48.7756373, lng: 21.1156946 },
    { lat: 48.7765432, lng: 21.11674 },
    { lat: 48.7771225, lng: 21.1169023 },
    { lat: 48.7772028, lng: 21.1169503 },
    { lat: 48.7772145, lng: 21.1171114 },
    { lat: 48.7775254, lng: 21.1171871 },
    { lat: 48.7778114, lng: 21.117367 },
    { lat: 48.7780646, lng: 21.1175764 },
    { lat: 48.7781094, lng: 21.1177451 },
    { lat: 48.778321, lng: 21.117888 },
    { lat: 48.7785229, lng: 21.1178679 },
    { lat: 48.7786278, lng: 21.1180129 },
    { lat: 48.7788495, lng: 21.1185712 },
    { lat: 48.7789682, lng: 21.1187002 },
    { lat: 48.7794564, lng: 21.1198109 },
    { lat: 48.780739, lng: 21.122256 },
    { lat: 48.780981, lng: 21.122353 },
    { lat: 48.781211, lng: 21.122661 },
    { lat: 48.781159, lng: 21.122907 },
    { lat: 48.781149, lng: 21.122956 },
    { lat: 48.781254, lng: 21.12306 },
    { lat: 48.781317, lng: 21.123124 },
    { lat: 48.781429, lng: 21.123134 },
    { lat: 48.781508, lng: 21.12326 },
    { lat: 48.781527, lng: 21.12329 },
    { lat: 48.781685, lng: 21.123538 },
    { lat: 48.781882, lng: 21.12393 },
    { lat: 48.781932, lng: 21.12403 },
    { lat: 48.781988, lng: 21.12414 },
    { lat: 48.782088, lng: 21.124337 },
    { lat: 48.782229, lng: 21.124582 },
    { lat: 48.782404, lng: 21.124761 },
    { lat: 48.782562, lng: 21.124923 },
    { lat: 48.782578, lng: 21.124939 },
    { lat: 48.782785, lng: 21.125138 },
    { lat: 48.782972, lng: 21.125286 },
    { lat: 48.783037, lng: 21.125315 },
    { lat: 48.783357, lng: 21.125456 },
    { lat: 48.783425, lng: 21.125485 },
    { lat: 48.783534, lng: 21.125506 },
    { lat: 48.783878, lng: 21.12557 },
    { lat: 48.784098, lng: 21.125515 },
    { lat: 48.784264, lng: 21.125779 },
    { lat: 48.784388, lng: 21.125797 },
    { lat: 48.784416, lng: 21.125799 },
    { lat: 48.7846668, lng: 21.1258242 },
    { lat: 48.784693, lng: 21.12594 },
    { lat: 48.784698, lng: 21.125959 },
    { lat: 48.784718, lng: 21.126051 },
    { lat: 48.784556, lng: 21.126305 },
    { lat: 48.784521, lng: 21.126355 },
    { lat: 48.784446, lng: 21.12646 },
    { lat: 48.784103, lng: 21.126961 },
    { lat: 48.784209, lng: 21.127289 },
    { lat: 48.784081, lng: 21.127406 },
    { lat: 48.783322, lng: 21.128098 },
    { lat: 48.783339, lng: 21.12813 },
    { lat: 48.783392, lng: 21.128096 },
    { lat: 48.783472, lng: 21.12826 },
    { lat: 48.783503, lng: 21.128252 },
    { lat: 48.783448, lng: 21.128473 },
    { lat: 48.783475, lng: 21.128645 },
    { lat: 48.783567, lng: 21.128765 },
    { lat: 48.783626, lng: 21.129038 },
    { lat: 48.783712, lng: 21.129177 },
    { lat: 48.783805, lng: 21.129395 },
    { lat: 48.783888, lng: 21.129488 },
    { lat: 48.783906, lng: 21.129599 },
    { lat: 48.784149, lng: 21.129925 },
    { lat: 48.78362, lng: 21.130448 },
    { lat: 48.783478, lng: 21.130539 },
    { lat: 48.783384, lng: 21.130753 },
    { lat: 48.783324, lng: 21.130807 },
    { lat: 48.783308, lng: 21.131015 },
    { lat: 48.783372, lng: 21.131154 },
    { lat: 48.783506, lng: 21.131268 },
    { lat: 48.783924, lng: 21.131435 },
    { lat: 48.783946, lng: 21.131578 },
    { lat: 48.783617, lng: 21.131988 },
    { lat: 48.783392, lng: 21.132285 },
    { lat: 48.783264, lng: 21.132574 },
    { lat: 48.783247, lng: 21.132858 },
    { lat: 48.78332, lng: 21.132913 },
    { lat: 48.783386, lng: 21.132842 },
    { lat: 48.784268, lng: 21.132583 },
    { lat: 48.784301, lng: 21.132629 },
    { lat: 48.783233, lng: 21.134211 },
    { lat: 48.783109, lng: 21.134505 },
    { lat: 48.783099, lng: 21.134812 },
    { lat: 48.783091, lng: 21.135031 },
    { lat: 48.783414, lng: 21.137018 },
    { lat: 48.783414, lng: 21.13702 },
    { lat: 48.783419, lng: 21.137272 },
    { lat: 48.783396, lng: 21.137539 },
    { lat: 48.783412, lng: 21.137915 },
    { lat: 48.783437, lng: 21.138337 },
    { lat: 48.783294, lng: 21.13881 },
    { lat: 48.783243, lng: 21.138883 },
    { lat: 48.782691, lng: 21.139208 },
    { lat: 48.781648, lng: 21.140036 },
    { lat: 48.780904, lng: 21.140754 },
    { lat: 48.780743, lng: 21.14091 },
    { lat: 48.78118, lng: 21.141049 },
    { lat: 48.781448, lng: 21.14095 },
    { lat: 48.781543, lng: 21.140981 },
    { lat: 48.7815384, lng: 21.1410518 },
    { lat: 48.781655, lng: 21.141114 },
    { lat: 48.781882, lng: 21.141251 },
    { lat: 48.782084, lng: 21.141452 },
    { lat: 48.782107, lng: 21.141647 },
    { lat: 48.782234, lng: 21.141559 },
    { lat: 48.782179, lng: 21.141844 },
    { lat: 48.782423, lng: 21.14228 },
    { lat: 48.782541, lng: 21.142491 },
    { lat: 48.783919, lng: 21.142165 },
    { lat: 48.787077, lng: 21.141415 },
    { lat: 48.787983, lng: 21.142616 },
    { lat: 48.78856, lng: 21.142566 },
    { lat: 48.788722, lng: 21.143079 },
    { lat: 48.78898, lng: 21.143425 },
    { lat: 48.789213, lng: 21.14392 },
    { lat: 48.789425, lng: 21.144124 },
    { lat: 48.789609, lng: 21.144185 },
    { lat: 48.789965, lng: 21.143858 },
    { lat: 48.790303, lng: 21.143701 },
    { lat: 48.791097, lng: 21.142942 },
    { lat: 48.791477, lng: 21.142543 },
    { lat: 48.791643, lng: 21.142367 },
    { lat: 48.792347, lng: 21.141821 },
    { lat: 48.792941, lng: 21.141426 },
    { lat: 48.792991, lng: 21.141411 },
    { lat: 48.793542, lng: 21.140568 },
    { lat: 48.794009, lng: 21.14037 },
    { lat: 48.794355, lng: 21.139862 },
    { lat: 48.794517, lng: 21.139625 },
    { lat: 48.79481, lng: 21.139402 },
    { lat: 48.795315, lng: 21.139559 },
    { lat: 48.795402, lng: 21.139586 },
    { lat: 48.795914, lng: 21.139553 },
    { lat: 48.796436, lng: 21.139203 },
    { lat: 48.796461, lng: 21.139195 },
    { lat: 48.796791, lng: 21.139099 },
    { lat: 48.796948, lng: 21.139052 },
    { lat: 48.797259, lng: 21.138911 },
    { lat: 48.797446, lng: 21.138782 },
    { lat: 48.797597, lng: 21.138512 },
    { lat: 48.798023, lng: 21.137706 },
    { lat: 48.798163, lng: 21.137586 },
    { lat: 48.798496, lng: 21.137302 },
    { lat: 48.798944, lng: 21.136304 },
    { lat: 48.79902, lng: 21.136135 },
    { lat: 48.799091, lng: 21.135375 },
    { lat: 48.799431, lng: 21.134957 },
    { lat: 48.800259, lng: 21.134534 },
    { lat: 48.800676, lng: 21.134296 },
    { lat: 48.801209, lng: 21.13385 },
    { lat: 48.801811, lng: 21.133568 },
    { lat: 48.802272, lng: 21.133526 },
    { lat: 48.80261, lng: 21.133575 },
    { lat: 48.802875, lng: 21.13353 },
    { lat: 48.803143, lng: 21.133493 },
    { lat: 48.803657, lng: 21.133317 },
    { lat: 48.804059, lng: 21.132699 },
    { lat: 48.804555, lng: 21.132654 },
    { lat: 48.804806, lng: 21.132423 },
    { lat: 48.804959, lng: 21.132147 },
    { lat: 48.805098, lng: 21.131933 },
    { lat: 48.805202, lng: 21.131709 },
    { lat: 48.805608, lng: 21.131734 },
    { lat: 48.806053, lng: 21.131708 },
    { lat: 48.80627, lng: 21.131386 },
    { lat: 48.80663, lng: 21.131443 },
    { lat: 48.806778, lng: 21.131282 },
    { lat: 48.807004, lng: 21.131048 },
    { lat: 48.80731, lng: 21.130857 },
    { lat: 48.807497, lng: 21.130892 },
    { lat: 48.807665, lng: 21.13078 },
    { lat: 48.80795, lng: 21.130746 },
    { lat: 48.808309, lng: 21.130638 },
    { lat: 48.808649, lng: 21.13049 },
    { lat: 48.808906, lng: 21.130755 },
    { lat: 48.809123, lng: 21.130827 },
    { lat: 48.809447, lng: 21.13086 },
    { lat: 48.809787, lng: 21.131042 },
    { lat: 48.809887, lng: 21.131017 },
    { lat: 48.810207, lng: 21.131163 },
    { lat: 48.811017, lng: 21.132346 },
    { lat: 48.811224, lng: 21.132439 },
    { lat: 48.81172, lng: 21.132405 },
    { lat: 48.811867, lng: 21.132439 },
    { lat: 48.812046, lng: 21.132654 },
    { lat: 48.812363, lng: 21.133285 },
    { lat: 48.812527, lng: 21.133895 },
    { lat: 48.812762, lng: 21.134104 },
    { lat: 48.813635, lng: 21.134409 },
    { lat: 48.814068, lng: 21.134914 },
    { lat: 48.814344, lng: 21.135308 },
    { lat: 48.814493, lng: 21.135375 },
    { lat: 48.814711, lng: 21.135601 },
    { lat: 48.815435, lng: 21.135755 },
    { lat: 48.815756, lng: 21.135878 },
    { lat: 48.81595, lng: 21.13616 },
    { lat: 48.816147, lng: 21.136443 },
    { lat: 48.816362, lng: 21.136632 },
    { lat: 48.81652, lng: 21.136828 },
    { lat: 48.816718, lng: 21.137086 },
    { lat: 48.816931, lng: 21.137434 },
    { lat: 48.817567, lng: 21.138331 },
    { lat: 48.817614, lng: 21.138698 },
    { lat: 48.817661, lng: 21.139065 },
    { lat: 48.817794, lng: 21.139356 },
    { lat: 48.818008, lng: 21.139468 },
    { lat: 48.817945, lng: 21.139755 },
    { lat: 48.81807, lng: 21.140266 },
    { lat: 48.818154, lng: 21.140611 },
    { lat: 48.818197, lng: 21.140789 },
    { lat: 48.81858, lng: 21.14124 },
    { lat: 48.818663, lng: 21.141483 },
    { lat: 48.819125, lng: 21.142015 },
    { lat: 48.819206, lng: 21.142235 },
    { lat: 48.819361, lng: 21.142651 },
    { lat: 48.820172, lng: 21.144835 },
    { lat: 48.821189, lng: 21.147605 },
    { lat: 48.821561, lng: 21.1477 },
    { lat: 48.822067, lng: 21.147828 },
    { lat: 48.822877, lng: 21.148034 },
    { lat: 48.823586, lng: 21.148214 },
    { lat: 48.823637, lng: 21.148288 },
    { lat: 48.823862, lng: 21.148674 },
    { lat: 48.824241, lng: 21.149718 },
    { lat: 48.824281, lng: 21.150203 },
    { lat: 48.8243215, lng: 21.1499615 },
    { lat: 48.8243189, lng: 21.1496666 },
    { lat: 48.8242547, lng: 21.1493431 },
    { lat: 48.8243315, lng: 21.1490668 },
    { lat: 48.8243973, lng: 21.1484512 },
    { lat: 48.8245448, lng: 21.1478151 },
    { lat: 48.8246655, lng: 21.1475027 },
    { lat: 48.8246921, lng: 21.1471729 },
    { lat: 48.8253911, lng: 21.1453704 },
    { lat: 48.8253731, lng: 21.1452645 },
    { lat: 48.8255243, lng: 21.1442516 },
    { lat: 48.8259148, lng: 21.1433291 },
    { lat: 48.8262677, lng: 21.141864 },
    { lat: 48.8263022, lng: 21.1411482 },
    { lat: 48.8265148, lng: 21.1409283 },
    { lat: 48.8266822, lng: 21.1402107 },
    { lat: 48.8269403, lng: 21.1398722 },
    { lat: 48.8273485, lng: 21.1395084 },
    { lat: 48.8275588, lng: 21.1392183 },
    { lat: 48.8279838, lng: 21.139033 },
    { lat: 48.8285926, lng: 21.1380373 },
    { lat: 48.8287349, lng: 21.1375593 },
    { lat: 48.8290948, lng: 21.1373751 },
    { lat: 48.8299155, lng: 21.1366036 },
    { lat: 48.8302108, lng: 21.1359851 },
    { lat: 48.8312776, lng: 21.134923 },
    { lat: 48.8320663, lng: 21.1325895 },
    { lat: 48.8318275, lng: 21.1320536 },
    { lat: 48.8318679, lng: 21.1272168 },
    { lat: 48.8322352, lng: 21.1260296 },
    { lat: 48.8329743, lng: 21.1247526 },
    { lat: 48.8332591, lng: 21.124442 },
    { lat: 48.8337685, lng: 21.1233708 },
    { lat: 48.8337802, lng: 21.1233464 },
    { lat: 48.8336455, lng: 21.123184 },
    { lat: 48.8332911, lng: 21.1224463 },
    { lat: 48.8332756, lng: 21.1218966 },
    { lat: 48.8331772, lng: 21.1215994 },
    { lat: 48.8327818, lng: 21.1208808 },
    { lat: 48.8323351, lng: 21.1202913 },
    { lat: 48.8328656, lng: 21.1177367 },
    { lat: 48.832859, lng: 21.1172453 },
    { lat: 48.8330146, lng: 21.1162051 },
    { lat: 48.8331023, lng: 21.1159164 },
    { lat: 48.833573, lng: 21.1151873 },
    { lat: 48.8338807, lng: 21.1141494 },
    { lat: 48.8342523, lng: 21.1134176 },
    { lat: 48.8345257, lng: 21.1129938 },
    { lat: 48.8347698, lng: 21.11236 },
    { lat: 48.8351874, lng: 21.1117991 },
    { lat: 48.8354472, lng: 21.1112012 },
    { lat: 48.8360231, lng: 21.1102093 },
    { lat: 48.8364988, lng: 21.1078799 },
    { lat: 48.8368473, lng: 21.1073777 },
    { lat: 48.8374304, lng: 21.1057759 },
    { lat: 48.8377683, lng: 21.1050093 },
    { lat: 48.8382682, lng: 21.1044566 },
    { lat: 48.8388588, lng: 21.1030146 },
    { lat: 48.8393423, lng: 21.1019139 },
    { lat: 48.8396695, lng: 21.1019384 },
    { lat: 48.839868, lng: 21.1023703 },
    { lat: 48.8397111, lng: 21.1027049 },
    { lat: 48.8393001, lng: 21.1028385 },
    { lat: 48.8393202, lng: 21.1031485 },
    { lat: 48.8391866, lng: 21.1043181 },
    { lat: 48.8390159, lng: 21.1051632 },
    { lat: 48.839025, lng: 21.1059396 },
    { lat: 48.8386401, lng: 21.1072395 },
    { lat: 48.8386327, lng: 21.1075651 },
    { lat: 48.8386551, lng: 21.1076031 },
    { lat: 48.8388094, lng: 21.1074656 },
    { lat: 48.8390092, lng: 21.1075167 },
    { lat: 48.8391791, lng: 21.1068818 },
    { lat: 48.8395634, lng: 21.1064833 },
    { lat: 48.839854, lng: 21.1063454 },
    { lat: 48.8401905, lng: 21.1058204 },
    { lat: 48.8403317, lng: 21.1054513 },
    { lat: 48.8413701, lng: 21.1051582 },
    { lat: 48.8414325, lng: 21.1049299 },
    { lat: 48.8416958, lng: 21.1045807 },
    { lat: 48.8419386, lng: 21.1044302 },
    { lat: 48.8416327, lng: 21.1036289 },
    { lat: 48.8416499, lng: 21.1034661 },
    { lat: 48.8419517, lng: 21.1033999 },
    { lat: 48.8422126, lng: 21.1027622 },
    { lat: 48.8422682, lng: 21.1021517 },
    { lat: 48.8425027, lng: 21.1021609 },
    { lat: 48.8426442, lng: 21.1020443 },
    { lat: 48.8427369, lng: 21.1017066 },
    { lat: 48.8424983, lng: 21.1013566 },
    { lat: 48.8426154, lng: 21.101011 },
    { lat: 48.8428173, lng: 21.1007088 },
    { lat: 48.8430488, lng: 21.1001071 },
    { lat: 48.8430395, lng: 21.0991856 },
    { lat: 48.8435699, lng: 21.0988141 },
    { lat: 48.8436716, lng: 21.0980888 },
    { lat: 48.8443309, lng: 21.0978007 },
    { lat: 48.8442569, lng: 21.0973559 },
    { lat: 48.8441633, lng: 21.0971417 },
    { lat: 48.8444347, lng: 21.0968708 },
    { lat: 48.8444027, lng: 21.0967109 },
    { lat: 48.8446479, lng: 21.0964595 },
    { lat: 48.8450229, lng: 21.0959129 },
    { lat: 48.8455073, lng: 21.0944257 },
    { lat: 48.845606, lng: 21.093886 },
    { lat: 48.8459047, lng: 21.0930652 },
    { lat: 48.8461914, lng: 21.0927038 },
    { lat: 48.846353, lng: 21.0926352 },
    { lat: 48.8465432, lng: 21.0925545 },
    { lat: 48.8477917, lng: 21.0922831 },
    { lat: 48.8485731, lng: 21.0914588 },
    { lat: 48.8490841, lng: 21.0912812 },
    { lat: 48.8498214, lng: 21.0914307 },
    { lat: 48.8501812, lng: 21.0912722 },
    { lat: 48.8508094, lng: 21.0907604 },
    { lat: 48.8525111, lng: 21.0899943 },
    { lat: 48.8527336, lng: 21.0896701 },
    { lat: 48.852859, lng: 21.0888132 },
    { lat: 48.8529793, lng: 21.0884898 },
    { lat: 48.8538036, lng: 21.0876385 },
    { lat: 48.8541636, lng: 21.0873488 },
    { lat: 48.8549241, lng: 21.0864343 },
    { lat: 48.8536188, lng: 21.0840408 },
    { lat: 48.8530986, lng: 21.0825674 },
    { lat: 48.852996, lng: 21.0816179 },
    { lat: 48.8529165, lng: 21.0798973 },
    { lat: 48.8527391, lng: 21.0783777 },
    { lat: 48.8527439, lng: 21.0776894 },
    { lat: 48.8526365, lng: 21.0768039 },
    { lat: 48.8528468, lng: 21.0756518 },
    { lat: 48.8526492, lng: 21.0740994 },
    { lat: 48.8522603, lng: 21.0726963 },
    { lat: 48.8512115, lng: 21.070548 },
    { lat: 48.850294, lng: 21.069095 },
    { lat: 48.850163, lng: 21.069344 },
    { lat: 48.849747, lng: 21.069576 },
    { lat: 48.849301, lng: 21.069623 },
    { lat: 48.849013, lng: 21.069749 },
    { lat: 48.848666, lng: 21.06993 },
    { lat: 48.847672, lng: 21.070368 },
    { lat: 48.847176, lng: 21.071078 },
    { lat: 48.846404, lng: 21.071184 },
    { lat: 48.846036, lng: 21.071189 },
    { lat: 48.845109, lng: 21.071281 },
    { lat: 48.844337, lng: 21.071288 },
    { lat: 48.843048, lng: 21.070496 },
    { lat: 48.842348, lng: 21.071131 },
    { lat: 48.841885, lng: 21.071797 },
    { lat: 48.84168, lng: 21.071848 },
    { lat: 48.841476, lng: 21.072011 },
    { lat: 48.841159, lng: 21.072164 },
    { lat: 48.841053, lng: 21.072256 },
    { lat: 48.840834, lng: 21.072297 },
    { lat: 48.840544, lng: 21.072192 },
    { lat: 48.839983, lng: 21.072389 },
    { lat: 48.83978, lng: 21.07245 },
    { lat: 48.839548, lng: 21.072555 },
    { lat: 48.839207, lng: 21.07288 },
    { lat: 48.838959, lng: 21.072836 },
    { lat: 48.838711, lng: 21.072716 },
    { lat: 48.838489, lng: 21.072794 },
    { lat: 48.838273, lng: 21.072982 },
    { lat: 48.838197, lng: 21.073069 },
    { lat: 48.837931, lng: 21.073262 },
    { lat: 48.837423, lng: 21.073279 },
    { lat: 48.837143, lng: 21.073338 },
    { lat: 48.836934, lng: 21.073207 },
    { lat: 48.836654, lng: 21.072918 },
  ],
  KošickéOľšany: [
    { lat: 48.7287725, lng: 21.3261876 },
    { lat: 48.728477, lng: 21.326066 },
    { lat: 48.728272, lng: 21.325982 },
    { lat: 48.728106, lng: 21.325914 },
    { lat: 48.727777, lng: 21.325828 },
    { lat: 48.727589, lng: 21.324759 },
    { lat: 48.727532, lng: 21.324633 },
    { lat: 48.727403, lng: 21.32386 },
    { lat: 48.727206, lng: 21.322902 },
    { lat: 48.727098, lng: 21.322418 },
    { lat: 48.726895, lng: 21.321405 },
    { lat: 48.72675, lng: 21.320624 },
    { lat: 48.726074, lng: 21.320948 },
    { lat: 48.724701, lng: 21.321106 },
    { lat: 48.724312, lng: 21.321064 },
    { lat: 48.723773, lng: 21.320955 },
    { lat: 48.7225514, lng: 21.3238258 },
    { lat: 48.7216942, lng: 21.3260973 },
    { lat: 48.7214997, lng: 21.3267765 },
    { lat: 48.7212213, lng: 21.3280653 },
    { lat: 48.7203702, lng: 21.3287974 },
    { lat: 48.7204032, lng: 21.3288415 },
    { lat: 48.7203952, lng: 21.3297711 },
    { lat: 48.7204368, lng: 21.3303253 },
    { lat: 48.720628, lng: 21.3317744 },
    { lat: 48.7207271, lng: 21.3320338 },
    { lat: 48.7204051, lng: 21.3324042 },
    { lat: 48.7198257, lng: 21.3328188 },
    { lat: 48.7195884, lng: 21.3328943 },
    { lat: 48.7190116, lng: 21.3333615 },
    { lat: 48.7185346, lng: 21.3339956 },
    { lat: 48.7174802, lng: 21.3349284 },
    { lat: 48.7171106, lng: 21.3354346 },
    { lat: 48.7168433, lng: 21.3356726 },
    { lat: 48.7168651, lng: 21.3359098 },
    { lat: 48.7169536, lng: 21.3367922 },
    { lat: 48.7167778, lng: 21.3368207 },
    { lat: 48.7169603, lng: 21.3404855 },
    { lat: 48.7170763, lng: 21.3404849 },
    { lat: 48.7171541, lng: 21.3417957 },
    { lat: 48.7175181, lng: 21.3456974 },
    { lat: 48.7176861, lng: 21.3487307 },
    { lat: 48.7175437, lng: 21.3487177 },
    { lat: 48.7176736, lng: 21.3534809 },
    { lat: 48.7178281, lng: 21.3565597 },
    { lat: 48.7189607, lng: 21.3584927 },
    { lat: 48.7191626, lng: 21.359038 },
    { lat: 48.7202534, lng: 21.360692 },
    { lat: 48.7211488, lng: 21.3623759 },
    { lat: 48.7220991, lng: 21.3635953 },
    { lat: 48.7227441, lng: 21.3641638 },
    { lat: 48.7234012, lng: 21.3654767 },
    { lat: 48.7238497, lng: 21.3665487 },
    { lat: 48.7250177, lng: 21.3686141 },
    { lat: 48.725415, lng: 21.3697474 },
    { lat: 48.7258977, lng: 21.3714348 },
    { lat: 48.7260136, lng: 21.3725668 },
    { lat: 48.7261203, lng: 21.3731622 },
    { lat: 48.7261107, lng: 21.3742706 },
    { lat: 48.7264836, lng: 21.3758287 },
    { lat: 48.7270109, lng: 21.3765327 },
    { lat: 48.7278835, lng: 21.3775225 },
    { lat: 48.728717, lng: 21.3789103 },
    { lat: 48.7292008, lng: 21.3800782 },
    { lat: 48.7294416, lng: 21.3811731 },
    { lat: 48.7296137, lng: 21.3837036 },
    { lat: 48.7299242, lng: 21.3848327 },
    { lat: 48.7310362, lng: 21.388047 },
    { lat: 48.7310519, lng: 21.3880859 },
    { lat: 48.7311097, lng: 21.3880631 },
    { lat: 48.732028, lng: 21.3881425 },
    { lat: 48.73251, lng: 21.3881913 },
    { lat: 48.7325411, lng: 21.3881443 },
    { lat: 48.7325648, lng: 21.3881081 },
    { lat: 48.7329782, lng: 21.3877364 },
    { lat: 48.7331862, lng: 21.3873558 },
    { lat: 48.7335598, lng: 21.3869383 },
    { lat: 48.734135, lng: 21.3860397 },
    { lat: 48.734375, lng: 21.3853336 },
    { lat: 48.7343709, lng: 21.3851934 },
    { lat: 48.7345511, lng: 21.384855 },
    { lat: 48.7349275, lng: 21.3836284 },
    { lat: 48.7350897, lng: 21.3832958 },
    { lat: 48.7354821, lng: 21.3830184 },
    { lat: 48.7356426, lng: 21.3825123 },
    { lat: 48.7357112, lng: 21.3825091 },
    { lat: 48.7358764, lng: 21.3821271 },
    { lat: 48.7360913, lng: 21.3819007 },
    { lat: 48.7362284, lng: 21.3815937 },
    { lat: 48.7361897, lng: 21.3813881 },
    { lat: 48.7361385, lng: 21.381372 },
    { lat: 48.7362097, lng: 21.3809683 },
    { lat: 48.7363447, lng: 21.3807747 },
    { lat: 48.7364607, lng: 21.3801729 },
    { lat: 48.7365945, lng: 21.3798291 },
    { lat: 48.7367267, lng: 21.3797359 },
    { lat: 48.7367771, lng: 21.3795378 },
    { lat: 48.7367431, lng: 21.3794193 },
    { lat: 48.7370623, lng: 21.3789484 },
    { lat: 48.7370714, lng: 21.3787648 },
    { lat: 48.7372424, lng: 21.3785033 },
    { lat: 48.7373683, lng: 21.3781157 },
    { lat: 48.737483, lng: 21.3779279 },
    { lat: 48.7376237, lng: 21.3778548 },
    { lat: 48.7377782, lng: 21.3776361 },
    { lat: 48.7377999, lng: 21.3774631 },
    { lat: 48.7380037, lng: 21.3773138 },
    { lat: 48.7379933, lng: 21.3771159 },
    { lat: 48.7380601, lng: 21.377096 },
    { lat: 48.7380749, lng: 21.3770201 },
    { lat: 48.7379843, lng: 21.3766249 },
    { lat: 48.7381299, lng: 21.3764552 },
    { lat: 48.7381301, lng: 21.3763419 },
    { lat: 48.7382576, lng: 21.3762451 },
    { lat: 48.7382651, lng: 21.3761184 },
    { lat: 48.738183, lng: 21.3759671 },
    { lat: 48.7382548, lng: 21.3758841 },
    { lat: 48.7384465, lng: 21.3758974 },
    { lat: 48.7384766, lng: 21.3758155 },
    { lat: 48.7384511, lng: 21.3757139 },
    { lat: 48.7385234, lng: 21.3757243 },
    { lat: 48.7385566, lng: 21.375629 },
    { lat: 48.7384945, lng: 21.3754357 },
    { lat: 48.7385928, lng: 21.3752639 },
    { lat: 48.7385205, lng: 21.3751698 },
    { lat: 48.7385399, lng: 21.3747299 },
    { lat: 48.7386524, lng: 21.3746926 },
    { lat: 48.7386878, lng: 21.3744874 },
    { lat: 48.7387557, lng: 21.3744228 },
    { lat: 48.7386022, lng: 21.3741791 },
    { lat: 48.7386161, lng: 21.3738962 },
    { lat: 48.7385713, lng: 21.3736336 },
    { lat: 48.7386851, lng: 21.373449 },
    { lat: 48.7387751, lng: 21.3734871 },
    { lat: 48.738897, lng: 21.3733533 },
    { lat: 48.7388464, lng: 21.3731667 },
    { lat: 48.7386088, lng: 21.3729708 },
    { lat: 48.7386227, lng: 21.372828 },
    { lat: 48.7385622, lng: 21.3725009 },
    { lat: 48.738613, lng: 21.3722835 },
    { lat: 48.7384955, lng: 21.3721536 },
    { lat: 48.7384309, lng: 21.3721705 },
    { lat: 48.7385556, lng: 21.3717192 },
    { lat: 48.7384748, lng: 21.3716117 },
    { lat: 48.7383923, lng: 21.3716373 },
    { lat: 48.7383623, lng: 21.3715021 },
    { lat: 48.7384398, lng: 21.3714897 },
    { lat: 48.7385213, lng: 21.3713201 },
    { lat: 48.7386609, lng: 21.3713608 },
    { lat: 48.7387267, lng: 21.3712676 },
    { lat: 48.7387878, lng: 21.3709771 },
    { lat: 48.7386966, lng: 21.3706382 },
    { lat: 48.7386645, lng: 21.3702426 },
    { lat: 48.7384183, lng: 21.3700997 },
    { lat: 48.7384619, lng: 21.3698646 },
    { lat: 48.7386723, lng: 21.370006 },
    { lat: 48.7386188, lng: 21.3693523 },
    { lat: 48.738787, lng: 21.3692142 },
    { lat: 48.7389126, lng: 21.3687133 },
    { lat: 48.7390487, lng: 21.3686366 },
    { lat: 48.739135, lng: 21.3680998 },
    { lat: 48.7392583, lng: 21.3681221 },
    { lat: 48.7394411, lng: 21.367953 },
    { lat: 48.7394462, lng: 21.3677056 },
    { lat: 48.7396696, lng: 21.3675014 },
    { lat: 48.739934, lng: 21.3670077 },
    { lat: 48.7400607, lng: 21.3669566 },
    { lat: 48.7401271, lng: 21.3668422 },
    { lat: 48.7401155, lng: 21.3666437 },
    { lat: 48.7401678, lng: 21.366509 },
    { lat: 48.7405003, lng: 21.3660053 },
    { lat: 48.7405104, lng: 21.3658545 },
    { lat: 48.7406498, lng: 21.36562 },
    { lat: 48.740732, lng: 21.3650942 },
    { lat: 48.7408218, lng: 21.3648645 },
    { lat: 48.7408844, lng: 21.3643247 },
    { lat: 48.7410715, lng: 21.3639208 },
    { lat: 48.7410529, lng: 21.3637491 },
    { lat: 48.7411327, lng: 21.3635215 },
    { lat: 48.7411288, lng: 21.3632437 },
    { lat: 48.7412079, lng: 21.3630661 },
    { lat: 48.7414801, lng: 21.3627604 },
    { lat: 48.7414786, lng: 21.362563 },
    { lat: 48.7415842, lng: 21.362293 },
    { lat: 48.7415942, lng: 21.3620994 },
    { lat: 48.7415345, lng: 21.3619862 },
    { lat: 48.7414907, lng: 21.3616523 },
    { lat: 48.7415123, lng: 21.3614358 },
    { lat: 48.7414441, lng: 21.3613321 },
    { lat: 48.7414203, lng: 21.361174 },
    { lat: 48.7412246, lng: 21.3608837 },
    { lat: 48.7413315, lng: 21.3605098 },
    { lat: 48.7414427, lng: 21.3603874 },
    { lat: 48.7414883, lng: 21.3600281 },
    { lat: 48.7411934, lng: 21.3600248 },
    { lat: 48.7411725, lng: 21.3595182 },
    { lat: 48.7410165, lng: 21.3594971 },
    { lat: 48.7408794, lng: 21.3593504 },
    { lat: 48.7408537, lng: 21.3592117 },
    { lat: 48.7410462, lng: 21.3589058 },
    { lat: 48.7409904, lng: 21.3587759 },
    { lat: 48.7409525, lng: 21.3583756 },
    { lat: 48.7411228, lng: 21.3582762 },
    { lat: 48.7409788, lng: 21.357972 },
    { lat: 48.7410795, lng: 21.3577686 },
    { lat: 48.7410218, lng: 21.3575751 },
    { lat: 48.7408892, lng: 21.3575389 },
    { lat: 48.7407472, lng: 21.3573415 },
    { lat: 48.7407027, lng: 21.357172 },
    { lat: 48.7407007, lng: 21.356924 },
    { lat: 48.7407444, lng: 21.356839 },
    { lat: 48.7407195, lng: 21.3567573 },
    { lat: 48.7404179, lng: 21.3564589 },
    { lat: 48.7403692, lng: 21.3563364 },
    { lat: 48.7404468, lng: 21.3560012 },
    { lat: 48.7402035, lng: 21.3556889 },
    { lat: 48.7398644, lng: 21.3555995 },
    { lat: 48.739813, lng: 21.3555003 },
    { lat: 48.7398598, lng: 21.3551502 },
    { lat: 48.7396965, lng: 21.3549554 },
    { lat: 48.7396749, lng: 21.3548039 },
    { lat: 48.7394897, lng: 21.3544373 },
    { lat: 48.7394812, lng: 21.3542724 },
    { lat: 48.7396022, lng: 21.3541404 },
    { lat: 48.7395763, lng: 21.3539925 },
    { lat: 48.7392965, lng: 21.3538926 },
    { lat: 48.7390126, lng: 21.3532832 },
    { lat: 48.7388629, lng: 21.3528272 },
    { lat: 48.738757, lng: 21.3526786 },
    { lat: 48.7387487, lng: 21.352323 },
    { lat: 48.7386072, lng: 21.3520019 },
    { lat: 48.7384894, lng: 21.3513398 },
    { lat: 48.7384127, lng: 21.3512192 },
    { lat: 48.7383873, lng: 21.3509396 },
    { lat: 48.7384836, lng: 21.3506596 },
    { lat: 48.7387187, lng: 21.3495314 },
    { lat: 48.7384468, lng: 21.3488328 },
    { lat: 48.7384662, lng: 21.3483174 },
    { lat: 48.7383821, lng: 21.3479185 },
    { lat: 48.7383358, lng: 21.3472433 },
    { lat: 48.7381874, lng: 21.3466471 },
    { lat: 48.7383102, lng: 21.3461669 },
    { lat: 48.7371876, lng: 21.3439048 },
    { lat: 48.7371081, lng: 21.3436162 },
    { lat: 48.7373368, lng: 21.3421673 },
    { lat: 48.7380664, lng: 21.3401255 },
    { lat: 48.7381708, lng: 21.3397486 },
    { lat: 48.7381624, lng: 21.3396176 },
    { lat: 48.7383999, lng: 21.3414498 },
    { lat: 48.7396735, lng: 21.3416975 },
    { lat: 48.7397953, lng: 21.338199 },
    { lat: 48.7398843, lng: 21.3380952 },
    { lat: 48.7401732, lng: 21.338009 },
    { lat: 48.7404575, lng: 21.3382567 },
    { lat: 48.7413671, lng: 21.3380093 },
    { lat: 48.7426976, lng: 21.3372393 },
    { lat: 48.7425012, lng: 21.3368486 },
    { lat: 48.7417412, lng: 21.3367948 },
    { lat: 48.7417809, lng: 21.3360558 },
    { lat: 48.7418188, lng: 21.3355201 },
    { lat: 48.7418878, lng: 21.3347926 },
    { lat: 48.7408771, lng: 21.3350115 },
    { lat: 48.7400902, lng: 21.3349843 },
    { lat: 48.7388707, lng: 21.3346808 },
    { lat: 48.7381359, lng: 21.3322599 },
    { lat: 48.7374465, lng: 21.3316009 },
    { lat: 48.7374937, lng: 21.3314119 },
    { lat: 48.7376462, lng: 21.3312488 },
    { lat: 48.7376384, lng: 21.3308732 },
    { lat: 48.7376998, lng: 21.3305638 },
    { lat: 48.7376241, lng: 21.3300331 },
    { lat: 48.7378024, lng: 21.3293003 },
    { lat: 48.7376535, lng: 21.3289552 },
    { lat: 48.7376399, lng: 21.3280602 },
    { lat: 48.7374837, lng: 21.3277708 },
    { lat: 48.7372609, lng: 21.3275131 },
    { lat: 48.73714, lng: 21.3269407 },
    { lat: 48.7369308, lng: 21.3267509 },
    { lat: 48.7368138, lng: 21.3261604 },
    { lat: 48.7366481, lng: 21.3260069 },
    { lat: 48.736338, lng: 21.3259813 },
    { lat: 48.7361113, lng: 21.3255984 },
    { lat: 48.7360323, lng: 21.325216 },
    { lat: 48.736055, lng: 21.324816 },
    { lat: 48.735857, lng: 21.324853 },
    { lat: 48.735568, lng: 21.324711 },
    { lat: 48.735202, lng: 21.324851 },
    { lat: 48.734951, lng: 21.324933 },
    { lat: 48.734734, lng: 21.325077 },
    { lat: 48.734467, lng: 21.325188 },
    { lat: 48.734147, lng: 21.325298 },
    { lat: 48.733996, lng: 21.325323 },
    { lat: 48.733833, lng: 21.325351 },
    { lat: 48.73354, lng: 21.325153 },
    { lat: 48.733193, lng: 21.324776 },
    { lat: 48.732922, lng: 21.324441 },
    { lat: 48.732578, lng: 21.324466 },
    { lat: 48.732304, lng: 21.324662 },
    { lat: 48.731816, lng: 21.325009 },
    { lat: 48.73159, lng: 21.324091 },
    { lat: 48.730835, lng: 21.324017 },
    { lat: 48.730724, lng: 21.324338 },
    { lat: 48.73037, lng: 21.324822 },
    { lat: 48.730249, lng: 21.325364 },
    { lat: 48.73021, lng: 21.32657 },
    { lat: 48.730208, lng: 21.326627 },
    { lat: 48.729425, lng: 21.326658 },
    { lat: 48.7287725, lng: 21.3261876 },
  ],
  Nováčany: [
    { lat: 48.6695996, lng: 21.0802743 },
    { lat: 48.6696224, lng: 21.0802765 },
    { lat: 48.6697035, lng: 21.0805234 },
    { lat: 48.6697493, lng: 21.080547 },
    { lat: 48.6699854, lng: 21.0804391 },
    { lat: 48.670069, lng: 21.0804863 },
    { lat: 48.6702532, lng: 21.0804346 },
    { lat: 48.6703393, lng: 21.0802103 },
    { lat: 48.6704293, lng: 21.0801702 },
    { lat: 48.6706304, lng: 21.0802985 },
    { lat: 48.6707045, lng: 21.0804308 },
    { lat: 48.670852, lng: 21.080406 },
    { lat: 48.670967, lng: 21.0805097 },
    { lat: 48.6717486, lng: 21.0808001 },
    { lat: 48.6718877, lng: 21.0807204 },
    { lat: 48.6721268, lng: 21.0809801 },
    { lat: 48.6722087, lng: 21.080937 },
    { lat: 48.6722585, lng: 21.0807713 },
    { lat: 48.6723868, lng: 21.0807344 },
    { lat: 48.6724734, lng: 21.0808387 },
    { lat: 48.6723617, lng: 21.0811742 },
    { lat: 48.672402, lng: 21.0812756 },
    { lat: 48.6727274, lng: 21.0813829 },
    { lat: 48.6727742, lng: 21.0815753 },
    { lat: 48.6728554, lng: 21.0815764 },
    { lat: 48.6729818, lng: 21.0818666 },
    { lat: 48.6733579, lng: 21.0822575 },
    { lat: 48.6736316, lng: 21.0824357 },
    { lat: 48.6737165, lng: 21.0825861 },
    { lat: 48.6737931, lng: 21.0825566 },
    { lat: 48.6738172, lng: 21.0824333 },
    { lat: 48.6737754, lng: 21.0822146 },
    { lat: 48.673838, lng: 21.0821376 },
    { lat: 48.6739955, lng: 21.082303 },
    { lat: 48.6739885, lng: 21.0824014 },
    { lat: 48.6740355, lng: 21.0824688 },
    { lat: 48.6742483, lng: 21.0824698 },
    { lat: 48.6744153, lng: 21.0823396 },
    { lat: 48.6744993, lng: 21.0825738 },
    { lat: 48.6746186, lng: 21.0825703 },
    { lat: 48.6748607, lng: 21.082713 },
    { lat: 48.6749019, lng: 21.0823563 },
    { lat: 48.6749754, lng: 21.0822202 },
    { lat: 48.6749516, lng: 21.0820489 },
    { lat: 48.6749883, lng: 21.0819643 },
    { lat: 48.6751516, lng: 21.0821067 },
    { lat: 48.6753, lng: 21.0821007 },
    { lat: 48.6753753, lng: 21.0819556 },
    { lat: 48.6754761, lng: 21.0821127 },
    { lat: 48.6754697, lng: 21.0821972 },
    { lat: 48.6755724, lng: 21.0822391 },
    { lat: 48.6756266, lng: 21.0821414 },
    { lat: 48.675617, lng: 21.0820196 },
    { lat: 48.6757043, lng: 21.0819986 },
    { lat: 48.6757634, lng: 21.0821559 },
    { lat: 48.6759075, lng: 21.0821926 },
    { lat: 48.6759448, lng: 21.0819241 },
    { lat: 48.6761749, lng: 21.0818704 },
    { lat: 48.6762333, lng: 21.0817223 },
    { lat: 48.6764295, lng: 21.0817377 },
    { lat: 48.6767009, lng: 21.0818789 },
    { lat: 48.6768433, lng: 21.0817249 },
    { lat: 48.677052, lng: 21.0817767 },
    { lat: 48.6770696, lng: 21.0816558 },
    { lat: 48.677403, lng: 21.0814199 },
    { lat: 48.6774744, lng: 21.0815072 },
    { lat: 48.6774379, lng: 21.0817688 },
    { lat: 48.6776569, lng: 21.0817234 },
    { lat: 48.6777607, lng: 21.0819693 },
    { lat: 48.6778915, lng: 21.0821142 },
    { lat: 48.6779442, lng: 21.0818995 },
    { lat: 48.6780624, lng: 21.081973 },
    { lat: 48.6781295, lng: 21.0817868 },
    { lat: 48.6783221, lng: 21.0818555 },
    { lat: 48.6783767, lng: 21.0820135 },
    { lat: 48.6786364, lng: 21.081729 },
    { lat: 48.6788593, lng: 21.0817296 },
    { lat: 48.6788921, lng: 21.0816306 },
    { lat: 48.6788186, lng: 21.0815175 },
    { lat: 48.6788303, lng: 21.0814265 },
    { lat: 48.679088, lng: 21.0813872 },
    { lat: 48.6791867, lng: 21.0812392 },
    { lat: 48.6793269, lng: 21.0813282 },
    { lat: 48.6794553, lng: 21.0816086 },
    { lat: 48.6796218, lng: 21.0817804 },
    { lat: 48.6797526, lng: 21.0816468 },
    { lat: 48.6799157, lng: 21.0817829 },
    { lat: 48.6802517, lng: 21.0814225 },
    { lat: 48.6803224, lng: 21.0814504 },
    { lat: 48.6803138, lng: 21.0815643 },
    { lat: 48.6803824, lng: 21.081634 },
    { lat: 48.6804527, lng: 21.0816132 },
    { lat: 48.6806068, lng: 21.0813336 },
    { lat: 48.6806723, lng: 21.081338 },
    { lat: 48.6808129, lng: 21.0817906 },
    { lat: 48.6809523, lng: 21.0817828 },
    { lat: 48.6810015, lng: 21.0818464 },
    { lat: 48.6810001, lng: 21.0820417 },
    { lat: 48.6810964, lng: 21.08203 },
    { lat: 48.6812054, lng: 21.0819215 },
    { lat: 48.6813194, lng: 21.0822749 },
    { lat: 48.6814366, lng: 21.0822434 },
    { lat: 48.6815717, lng: 21.0823887 },
    { lat: 48.6816998, lng: 21.0823109 },
    { lat: 48.681967, lng: 21.0824268 },
    { lat: 48.6820118, lng: 21.0823879 },
    { lat: 48.6822548, lng: 21.0825227 },
    { lat: 48.6825622, lng: 21.0822859 },
    { lat: 48.6829253, lng: 21.0823043 },
    { lat: 48.6830242, lng: 21.0822322 },
    { lat: 48.6832453, lng: 21.0822422 },
    { lat: 48.6834584, lng: 21.0824009 },
    { lat: 48.6839255, lng: 21.0826082 },
    { lat: 48.6840032, lng: 21.0827482 },
    { lat: 48.6842094, lng: 21.0826488 },
    { lat: 48.6843858, lng: 21.0827776 },
    { lat: 48.6846978, lng: 21.0828225 },
    { lat: 48.6848101, lng: 21.0827074 },
    { lat: 48.6849203, lng: 21.0827373 },
    { lat: 48.6852313, lng: 21.0826481 },
    { lat: 48.685341, lng: 21.0827999 },
    { lat: 48.6858866, lng: 21.0826883 },
    { lat: 48.6861615, lng: 21.082523 },
    { lat: 48.6863273, lng: 21.0825025 },
    { lat: 48.6866559, lng: 21.082745 },
    { lat: 48.6870117, lng: 21.0828131 },
    { lat: 48.6871379, lng: 21.0827112 },
    { lat: 48.6876096, lng: 21.0828925 },
    { lat: 48.6876109, lng: 21.0827218 },
    { lat: 48.6876598, lng: 21.0826398 },
    { lat: 48.6877917, lng: 21.0827486 },
    { lat: 48.6878317, lng: 21.0829078 },
    { lat: 48.6878785, lng: 21.0828502 },
    { lat: 48.6879392, lng: 21.0828834 },
    { lat: 48.6879628, lng: 21.0830947 },
    { lat: 48.6880089, lng: 21.0830895 },
    { lat: 48.6880147, lng: 21.0828921 },
    { lat: 48.6880781, lng: 21.0828674 },
    { lat: 48.688499, lng: 21.08293 },
    { lat: 48.6886867, lng: 21.0832706 },
    { lat: 48.6887827, lng: 21.0832863 },
    { lat: 48.6889034, lng: 21.0832919 },
    { lat: 48.6889981, lng: 21.08312 },
    { lat: 48.689078, lng: 21.0831561 },
    { lat: 48.6891046, lng: 21.0832838 },
    { lat: 48.6891829, lng: 21.0831982 },
    { lat: 48.6894367, lng: 21.0831481 },
    { lat: 48.6895886, lng: 21.0832865 },
    { lat: 48.6897236, lng: 21.0831807 },
    { lat: 48.6898698, lng: 21.0831731 },
    { lat: 48.6899622, lng: 21.0830747 },
    { lat: 48.6904642, lng: 21.0835595 },
    { lat: 48.6906822, lng: 21.0840706 },
    { lat: 48.6908018, lng: 21.0841508 },
    { lat: 48.6908965, lng: 21.0840829 },
    { lat: 48.6910871, lng: 21.0843024 },
    { lat: 48.6911347, lng: 21.0844535 },
    { lat: 48.6912281, lng: 21.0844541 },
    { lat: 48.6912067, lng: 21.0846295 },
    { lat: 48.691166, lng: 21.0846536 },
    { lat: 48.6912201, lng: 21.0848697 },
    { lat: 48.6916627, lng: 21.0853603 },
    { lat: 48.6920985, lng: 21.0861311 },
    { lat: 48.6923394, lng: 21.0861984 },
    { lat: 48.6923844, lng: 21.0862717 },
    { lat: 48.6923819, lng: 21.0865077 },
    { lat: 48.6926025, lng: 21.0867867 },
    { lat: 48.6926587, lng: 21.087028 },
    { lat: 48.6928436, lng: 21.0870721 },
    { lat: 48.6930289, lng: 21.0874047 },
    { lat: 48.6934967, lng: 21.0874934 },
    { lat: 48.6936892, lng: 21.0877731 },
    { lat: 48.6937659, lng: 21.0880218 },
    { lat: 48.6942069, lng: 21.0885392 },
    { lat: 48.6942863, lng: 21.0884935 },
    { lat: 48.694419, lng: 21.0885556 },
    { lat: 48.6946307, lng: 21.0887338 },
    { lat: 48.6947138, lng: 21.0888962 },
    { lat: 48.6950554, lng: 21.0890224 },
    { lat: 48.6950774, lng: 21.0891412 },
    { lat: 48.6951519, lng: 21.0891848 },
    { lat: 48.6952581, lng: 21.0894417 },
    { lat: 48.6954598, lng: 21.0896259 },
    { lat: 48.6955289, lng: 21.0898816 },
    { lat: 48.6956385, lng: 21.0898938 },
    { lat: 48.6956792, lng: 21.0899706 },
    { lat: 48.6962015, lng: 21.0903158 },
    { lat: 48.6963169, lng: 21.0906176 },
    { lat: 48.6966963, lng: 21.0907898 },
    { lat: 48.697039, lng: 21.0908133 },
    { lat: 48.6972021, lng: 21.0909103 },
    { lat: 48.6973076, lng: 21.0908687 },
    { lat: 48.697523, lng: 21.0909522 },
    { lat: 48.6978827, lng: 21.0907286 },
    { lat: 48.6979916, lng: 21.0908207 },
    { lat: 48.6981825, lng: 21.0906715 },
    { lat: 48.6983569, lng: 21.0906376 },
    { lat: 48.6985981, lng: 21.0907157 },
    { lat: 48.6990161, lng: 21.0906581 },
    { lat: 48.6991829, lng: 21.0908421 },
    { lat: 48.6993441, lng: 21.0908829 },
    { lat: 48.6999985, lng: 21.0914186 },
    { lat: 48.7001463, lng: 21.0916065 },
    { lat: 48.7002153, lng: 21.0916121 },
    { lat: 48.7003405, lng: 21.0917639 },
    { lat: 48.700434, lng: 21.0920431 },
    { lat: 48.7007012, lng: 21.0922336 },
    { lat: 48.7009935, lng: 21.0926079 },
    { lat: 48.7012447, lng: 21.0932368 },
    { lat: 48.7014678, lng: 21.0933507 },
    { lat: 48.7015302, lng: 21.0932966 },
    { lat: 48.7016311, lng: 21.093385 },
    { lat: 48.7017288, lng: 21.0933788 },
    { lat: 48.7018055, lng: 21.0934883 },
    { lat: 48.7019653, lng: 21.0934868 },
    { lat: 48.7020519, lng: 21.0935617 },
    { lat: 48.7021337, lng: 21.0934773 },
    { lat: 48.7022196, lng: 21.0934869 },
    { lat: 48.7025963, lng: 21.0936501 },
    { lat: 48.7028341, lng: 21.0939084 },
    { lat: 48.7032371, lng: 21.0941819 },
    { lat: 48.7040011, lng: 21.094427 },
    { lat: 48.7054732, lng: 21.095644 },
    { lat: 48.7058985, lng: 21.0960922 },
    { lat: 48.7064846, lng: 21.0974416 },
    { lat: 48.7064932, lng: 21.0974602 },
    { lat: 48.7066818, lng: 21.0972746 },
    { lat: 48.7072063, lng: 21.097165 },
    { lat: 48.7079428, lng: 21.0963827 },
    { lat: 48.7079858, lng: 21.0960691 },
    { lat: 48.7079136, lng: 21.0956839 },
    { lat: 48.7079188, lng: 21.0948365 },
    { lat: 48.708187, lng: 21.0937273 },
    { lat: 48.7081816, lng: 21.0932188 },
    { lat: 48.7082832, lng: 21.0930189 },
    { lat: 48.7083593, lng: 21.0925126 },
    { lat: 48.7086603, lng: 21.09218 },
    { lat: 48.7087492, lng: 21.0919012 },
    { lat: 48.7090392, lng: 21.0913367 },
    { lat: 48.709161, lng: 21.091203 },
    { lat: 48.7094751, lng: 21.0904061 },
    { lat: 48.7097693, lng: 21.0899995 },
    { lat: 48.7102062, lng: 21.0892092 },
    { lat: 48.7107834, lng: 21.0884039 },
    { lat: 48.7109817, lng: 21.0880304 },
    { lat: 48.7113126, lng: 21.0866036 },
    { lat: 48.711566, lng: 21.0857748 },
    { lat: 48.7115018, lng: 21.0848997 },
    { lat: 48.7118325, lng: 21.0835751 },
    { lat: 48.712458, lng: 21.0825591 },
    { lat: 48.7125175, lng: 21.0825537 },
    { lat: 48.7126282, lng: 21.0823231 },
    { lat: 48.7128825, lng: 21.0820758 },
    { lat: 48.713151, lng: 21.0814782 },
    { lat: 48.7134766, lng: 21.0810671 },
    { lat: 48.713762, lng: 21.0799108 },
    { lat: 48.7144944, lng: 21.0782692 },
    { lat: 48.7149246, lng: 21.0769902 },
    { lat: 48.715555, lng: 21.0759231 },
    { lat: 48.7158749, lng: 21.075482 },
    { lat: 48.7160566, lng: 21.0749774 },
    { lat: 48.7166162, lng: 21.073908 },
    { lat: 48.7169011, lng: 21.072943 },
    { lat: 48.7171288, lng: 21.0726414 },
    { lat: 48.7175034, lng: 21.0716348 },
    { lat: 48.7174946, lng: 21.0713275 },
    { lat: 48.7173577, lng: 21.0705033 },
    { lat: 48.7172789, lng: 21.0702992 },
    { lat: 48.7174465, lng: 21.0690126 },
    { lat: 48.7176752, lng: 21.0682941 },
    { lat: 48.718049, lng: 21.0673649 },
    { lat: 48.7186951, lng: 21.0663595 },
    { lat: 48.7193877, lng: 21.0647212 },
    { lat: 48.7194551, lng: 21.0644004 },
    { lat: 48.7195276, lng: 21.0638681 },
    { lat: 48.719559, lng: 21.0627893 },
    { lat: 48.719664, lng: 21.0625595 },
    { lat: 48.7197198, lng: 21.0619176 },
    { lat: 48.7195913, lng: 21.0607406 },
    { lat: 48.7197002, lng: 21.0604933 },
    { lat: 48.7196535, lng: 21.0600594 },
    { lat: 48.719791, lng: 21.0597174 },
    { lat: 48.7197446, lng: 21.05923 },
    { lat: 48.7198256, lng: 21.0585364 },
    { lat: 48.7199818, lng: 21.0577733 },
    { lat: 48.720064, lng: 21.0576685 },
    { lat: 48.7201933, lng: 21.0569777 },
    { lat: 48.7202371, lng: 21.0555572 },
    { lat: 48.720341, lng: 21.0542609 },
    { lat: 48.7205125, lng: 21.0537865 },
    { lat: 48.720571, lng: 21.053447 },
    { lat: 48.7206022, lng: 21.0527108 },
    { lat: 48.7206738, lng: 21.0522481 },
    { lat: 48.7206765, lng: 21.0519528 },
    { lat: 48.7204401, lng: 21.0516068 },
    { lat: 48.720362, lng: 21.0506296 },
    { lat: 48.719324, lng: 21.0493825 },
    { lat: 48.7192233, lng: 21.049123 },
    { lat: 48.7190448, lng: 21.0488648 },
    { lat: 48.7192833, lng: 21.0486858 },
    { lat: 48.7194062, lng: 21.048692 },
    { lat: 48.7198138, lng: 21.0484936 },
    { lat: 48.7202012, lng: 21.0484326 },
    { lat: 48.7205011, lng: 21.0481404 },
    { lat: 48.7209189, lng: 21.0478686 },
    { lat: 48.7212176, lng: 21.0477789 },
    { lat: 48.7218927, lng: 21.0473253 },
    { lat: 48.7219118, lng: 21.0469813 },
    { lat: 48.7220078, lng: 21.046751 },
    { lat: 48.7221242, lng: 21.0457677 },
    { lat: 48.7222612, lng: 21.0450307 },
    { lat: 48.7224661, lng: 21.0444165 },
    { lat: 48.7223281, lng: 21.0442568 },
    { lat: 48.722263, lng: 21.0438171 },
    { lat: 48.7221989, lng: 21.0418952 },
    { lat: 48.7225197, lng: 21.039012 },
    { lat: 48.7224909, lng: 21.0380246 },
    { lat: 48.7224082, lng: 21.0372936 },
    { lat: 48.7227275, lng: 21.0365243 },
    { lat: 48.7230547, lng: 21.0359012 },
    { lat: 48.7230693, lng: 21.035764 },
    { lat: 48.7228514, lng: 21.0357415 },
    { lat: 48.7226922, lng: 21.0358582 },
    { lat: 48.7223158, lng: 21.0358796 },
    { lat: 48.7218096, lng: 21.0362305 },
    { lat: 48.7215273, lng: 21.0362021 },
    { lat: 48.721439, lng: 21.0362925 },
    { lat: 48.7213605, lng: 21.0362642 },
    { lat: 48.7211383, lng: 21.0359778 },
    { lat: 48.7203068, lng: 21.0356776 },
    { lat: 48.720073, lng: 21.0358577 },
    { lat: 48.7199447, lng: 21.0358285 },
    { lat: 48.7196954, lng: 21.0359801 },
    { lat: 48.7190562, lng: 21.0359849 },
    { lat: 48.7186862, lng: 21.036132 },
    { lat: 48.7182496, lng: 21.03603 },
    { lat: 48.7174448, lng: 21.0360618 },
    { lat: 48.717284, lng: 21.0361376 },
    { lat: 48.7172462, lng: 21.0360831 },
    { lat: 48.717118, lng: 21.0360877 },
    { lat: 48.716855, lng: 21.0362025 },
    { lat: 48.7166072, lng: 21.0361245 },
    { lat: 48.7163251, lng: 21.0361692 },
    { lat: 48.7157928, lng: 21.0363762 },
    { lat: 48.7155577, lng: 21.036658 },
    { lat: 48.7152406, lng: 21.0366995 },
    { lat: 48.7151429, lng: 21.0368138 },
    { lat: 48.7149562, lng: 21.0368506 },
    { lat: 48.7149255, lng: 21.0369067 },
    { lat: 48.7148323, lng: 21.0368132 },
    { lat: 48.7146294, lng: 21.036839 },
    { lat: 48.7145918, lng: 21.0367578 },
    { lat: 48.7144951, lng: 21.0367575 },
    { lat: 48.7143984, lng: 21.036867 },
    { lat: 48.714228, lng: 21.0368484 },
    { lat: 48.7139124, lng: 21.0370424 },
    { lat: 48.7138898, lng: 21.0369714 },
    { lat: 48.7138181, lng: 21.0369501 },
    { lat: 48.7136917, lng: 21.037008 },
    { lat: 48.7135567, lng: 21.0369383 },
    { lat: 48.7128922, lng: 21.0373031 },
    { lat: 48.7124425, lng: 21.0374209 },
    { lat: 48.7118836, lng: 21.0373366 },
    { lat: 48.7118505, lng: 21.0370825 },
    { lat: 48.7115203, lng: 21.0371682 },
    { lat: 48.7109815, lng: 21.037108 },
    { lat: 48.7106941, lng: 21.0373056 },
    { lat: 48.7083723, lng: 21.0377567 },
    { lat: 48.7076734, lng: 21.0380664 },
    { lat: 48.7076559, lng: 21.0381514 },
    { lat: 48.7072764, lng: 21.0385961 },
    { lat: 48.7071527, lng: 21.0388726 },
    { lat: 48.706757, lng: 21.039162 },
    { lat: 48.7067524, lng: 21.0392607 },
    { lat: 48.7065827, lng: 21.0393707 },
    { lat: 48.7063807, lng: 21.0397001 },
    { lat: 48.7062517, lng: 21.0397771 },
    { lat: 48.7061729, lng: 21.0399865 },
    { lat: 48.7060275, lng: 21.0399824 },
    { lat: 48.7059745, lng: 21.0400769 },
    { lat: 48.7059264, lng: 21.0400566 },
    { lat: 48.7057328, lng: 21.0402247 },
    { lat: 48.7055074, lng: 21.0401842 },
    { lat: 48.7051546, lng: 21.0404786 },
    { lat: 48.7049579, lng: 21.0403796 },
    { lat: 48.704918, lng: 21.0405365 },
    { lat: 48.7047919, lng: 21.0405395 },
    { lat: 48.7047356, lng: 21.0404315 },
    { lat: 48.704548, lng: 21.0404085 },
    { lat: 48.7042803, lng: 21.040415 },
    { lat: 48.7039494, lng: 21.0405167 },
    { lat: 48.7039204, lng: 21.040422 },
    { lat: 48.7037573, lng: 21.0405642 },
    { lat: 48.7037006, lng: 21.0405154 },
    { lat: 48.7035586, lng: 21.0406165 },
    { lat: 48.7035636, lng: 21.0404903 },
    { lat: 48.7034272, lng: 21.0404499 },
    { lat: 48.7033809, lng: 21.0405849 },
    { lat: 48.7032283, lng: 21.0404623 },
    { lat: 48.7028551, lng: 21.0405102 },
    { lat: 48.7026561, lng: 21.0406562 },
    { lat: 48.702428, lng: 21.0406619 },
    { lat: 48.7023702, lng: 21.0407931 },
    { lat: 48.7022345, lng: 21.0407683 },
    { lat: 48.7020244, lng: 21.0406116 },
    { lat: 48.7019974, lng: 21.0408872 },
    { lat: 48.7018292, lng: 21.0407373 },
    { lat: 48.7016447, lng: 21.0409058 },
    { lat: 48.7015027, lng: 21.0408722 },
    { lat: 48.7013965, lng: 21.0409272 },
    { lat: 48.7013479, lng: 21.0408172 },
    { lat: 48.7013078, lng: 21.0408241 },
    { lat: 48.7012451, lng: 21.040868 },
    { lat: 48.7011894, lng: 21.0410264 },
    { lat: 48.7010675, lng: 21.040949 },
    { lat: 48.7007813, lng: 21.0410085 },
    { lat: 48.7007316, lng: 21.0409691 },
    { lat: 48.7001191, lng: 21.0412365 },
    { lat: 48.7000612, lng: 21.0411529 },
    { lat: 48.6998443, lng: 21.0413362 },
    { lat: 48.6997964, lng: 21.0414207 },
    { lat: 48.6998841, lng: 21.0415851 },
    { lat: 48.6997158, lng: 21.0416401 },
    { lat: 48.6994662, lng: 21.0420906 },
    { lat: 48.6992733, lng: 21.0419045 },
    { lat: 48.6991955, lng: 21.0420047 },
    { lat: 48.6991199, lng: 21.041865 },
    { lat: 48.6990622, lng: 21.041953 },
    { lat: 48.6988771, lng: 21.041804 },
    { lat: 48.6988151, lng: 21.0418343 },
    { lat: 48.6986847, lng: 21.0416332 },
    { lat: 48.698561, lng: 21.0417375 },
    { lat: 48.6984773, lng: 21.0416964 },
    { lat: 48.6983748, lng: 21.0414543 },
    { lat: 48.6982341, lng: 21.0415233 },
    { lat: 48.6981492, lng: 21.0414377 },
    { lat: 48.6979406, lng: 21.0414611 },
    { lat: 48.6979148, lng: 21.041323 },
    { lat: 48.6975387, lng: 21.0414901 },
    { lat: 48.6972901, lng: 21.0415262 },
    { lat: 48.6972918, lng: 21.0416497 },
    { lat: 48.6972192, lng: 21.0418026 },
    { lat: 48.6971437, lng: 21.0417017 },
    { lat: 48.6969953, lng: 21.0416711 },
    { lat: 48.6970181, lng: 21.0417849 },
    { lat: 48.6969673, lng: 21.0419465 },
    { lat: 48.6966336, lng: 21.0424428 },
    { lat: 48.6963587, lng: 21.0424762 },
    { lat: 48.6963479, lng: 21.042621 },
    { lat: 48.6962939, lng: 21.0426574 },
    { lat: 48.6963035, lng: 21.0427782 },
    { lat: 48.6960319, lng: 21.0432863 },
    { lat: 48.6958221, lng: 21.0434106 },
    { lat: 48.6956746, lng: 21.0432709 },
    { lat: 48.6952578, lng: 21.0435654 },
    { lat: 48.6944401, lng: 21.0438239 },
    { lat: 48.6942279, lng: 21.0438426 },
    { lat: 48.6940532, lng: 21.0437655 },
    { lat: 48.6938789, lng: 21.0439075 },
    { lat: 48.6935671, lng: 21.0438717 },
    { lat: 48.6935019, lng: 21.0437761 },
    { lat: 48.6923978, lng: 21.0438746 },
    { lat: 48.6922312, lng: 21.0439152 },
    { lat: 48.6919742, lng: 21.0442771 },
    { lat: 48.6917597, lng: 21.0443242 },
    { lat: 48.6917455, lng: 21.04441 },
    { lat: 48.6916795, lng: 21.0444548 },
    { lat: 48.6916299, lng: 21.0443506 },
    { lat: 48.6916421, lng: 21.0442432 },
    { lat: 48.6915931, lng: 21.0442233 },
    { lat: 48.6915236, lng: 21.0442666 },
    { lat: 48.6914867, lng: 21.0443791 },
    { lat: 48.6913473, lng: 21.0444159 },
    { lat: 48.6912833, lng: 21.044146 },
    { lat: 48.6909905, lng: 21.0442448 },
    { lat: 48.6909308, lng: 21.0444167 },
    { lat: 48.6906173, lng: 21.0443646 },
    { lat: 48.690529, lng: 21.0444849 },
    { lat: 48.6904983, lng: 21.0444394 },
    { lat: 48.6898137, lng: 21.0448084 },
    { lat: 48.6895952, lng: 21.0449444 },
    { lat: 48.6895774, lng: 21.0451555 },
    { lat: 48.6894978, lng: 21.0453688 },
    { lat: 48.6893908, lng: 21.0454349 },
    { lat: 48.689041, lng: 21.0453762 },
    { lat: 48.6888516, lng: 21.0454356 },
    { lat: 48.6887518, lng: 21.0456554 },
    { lat: 48.6886978, lng: 21.0459658 },
    { lat: 48.6887163, lng: 21.0461126 },
    { lat: 48.6886585, lng: 21.0462077 },
    { lat: 48.6885812, lng: 21.0467724 },
    { lat: 48.6885002, lng: 21.046668 },
    { lat: 48.6883755, lng: 21.0466414 },
    { lat: 48.68793, lng: 21.0467131 },
    { lat: 48.6877541, lng: 21.0466358 },
    { lat: 48.687581, lng: 21.0467431 },
    { lat: 48.6875071, lng: 21.0466564 },
    { lat: 48.6874672, lng: 21.0467124 },
    { lat: 48.6874808, lng: 21.0468186 },
    { lat: 48.6874035, lng: 21.0467546 },
    { lat: 48.6873211, lng: 21.0468167 },
    { lat: 48.6871797, lng: 21.0465522 },
    { lat: 48.6870161, lng: 21.0466132 },
    { lat: 48.6869113, lng: 21.0464757 },
    { lat: 48.6868717, lng: 21.0465688 },
    { lat: 48.686795, lng: 21.0465966 },
    { lat: 48.6867042, lng: 21.0464392 },
    { lat: 48.6865537, lng: 21.0464433 },
    { lat: 48.6865112, lng: 21.0463489 },
    { lat: 48.6862486, lng: 21.0464483 },
    { lat: 48.6859453, lng: 21.0464624 },
    { lat: 48.685776, lng: 21.0463112 },
    { lat: 48.6857631, lng: 21.0463641 },
    { lat: 48.6855004, lng: 21.0464158 },
    { lat: 48.6850831, lng: 21.0463576 },
    { lat: 48.6844838, lng: 21.046553 },
    { lat: 48.6839066, lng: 21.0465216 },
    { lat: 48.6838649, lng: 21.046451 },
    { lat: 48.6837817, lng: 21.0464156 },
    { lat: 48.6834348, lng: 21.0463467 },
    { lat: 48.6833312, lng: 21.0464435 },
    { lat: 48.6831827, lng: 21.0463477 },
    { lat: 48.6828427, lng: 21.0463072 },
    { lat: 48.682462, lng: 21.0466096 },
    { lat: 48.6823411, lng: 21.0465616 },
    { lat: 48.6823234, lng: 21.0464311 },
    { lat: 48.6821348, lng: 21.0462404 },
    { lat: 48.6819182, lng: 21.0463962 },
    { lat: 48.6817827, lng: 21.0465919 },
    { lat: 48.6813625, lng: 21.0468202 },
    { lat: 48.6812034, lng: 21.0472179 },
    { lat: 48.6810614, lng: 21.0472121 },
    { lat: 48.6810528, lng: 21.0472936 },
    { lat: 48.6808934, lng: 21.0474458 },
    { lat: 48.6807699, lng: 21.0474208 },
    { lat: 48.6805762, lng: 21.0478292 },
    { lat: 48.6805123, lng: 21.0478742 },
    { lat: 48.680378, lng: 21.0477254 },
    { lat: 48.6802927, lng: 21.0477282 },
    { lat: 48.6802304, lng: 21.0480962 },
    { lat: 48.6799253, lng: 21.0481253 },
    { lat: 48.6797838, lng: 21.0480347 },
    { lat: 48.6796238, lng: 21.0481325 },
    { lat: 48.6794217, lng: 21.0480831 },
    { lat: 48.6792367, lng: 21.048202 },
    { lat: 48.6789743, lng: 21.0481689 },
    { lat: 48.6786936, lng: 21.0485271 },
    { lat: 48.6785194, lng: 21.0485754 },
    { lat: 48.6782957, lng: 21.0484421 },
    { lat: 48.6780371, lng: 21.0484889 },
    { lat: 48.6779893, lng: 21.0486431 },
    { lat: 48.6778437, lng: 21.0486604 },
    { lat: 48.6776765, lng: 21.0488862 },
    { lat: 48.6773856, lng: 21.0489763 },
    { lat: 48.6772838, lng: 21.0488937 },
    { lat: 48.6771202, lng: 21.0491179 },
    { lat: 48.6770296, lng: 21.049515 },
    { lat: 48.6768553, lng: 21.0497249 },
    { lat: 48.6765828, lng: 21.0497563 },
    { lat: 48.6762715, lng: 21.0501474 },
    { lat: 48.6762423, lng: 21.0503579 },
    { lat: 48.6761487, lng: 21.0505618 },
    { lat: 48.6760248, lng: 21.050658 },
    { lat: 48.6760132, lng: 21.0507507 },
    { lat: 48.6759501, lng: 21.0508192 },
    { lat: 48.6757669, lng: 21.0508289 },
    { lat: 48.6757862, lng: 21.0509358 },
    { lat: 48.6756446, lng: 21.0511846 },
    { lat: 48.6754913, lng: 21.051243 },
    { lat: 48.6754527, lng: 21.051096 },
    { lat: 48.6753417, lng: 21.0510757 },
    { lat: 48.6753294, lng: 21.0509082 },
    { lat: 48.6751048, lng: 21.0508865 },
    { lat: 48.6750541, lng: 21.051022 },
    { lat: 48.6749216, lng: 21.0511331 },
    { lat: 48.6748923, lng: 21.0512756 },
    { lat: 48.6747651, lng: 21.0513079 },
    { lat: 48.674635, lng: 21.0514924 },
    { lat: 48.674439, lng: 21.0515932 },
    { lat: 48.6745116, lng: 21.0518869 },
    { lat: 48.6744025, lng: 21.0519895 },
    { lat: 48.6745235, lng: 21.0520784 },
    { lat: 48.6744743, lng: 21.0522893 },
    { lat: 48.6743161, lng: 21.0525144 },
    { lat: 48.6743978, lng: 21.0526689 },
    { lat: 48.6744033, lng: 21.0528089 },
    { lat: 48.6742449, lng: 21.0528491 },
    { lat: 48.6742922, lng: 21.053091 },
    { lat: 48.6741111, lng: 21.0531618 },
    { lat: 48.6740957, lng: 21.0532392 },
    { lat: 48.6737884, lng: 21.0534861 },
    { lat: 48.6734862, lng: 21.0539167 },
    { lat: 48.6731063, lng: 21.0540693 },
    { lat: 48.672951, lng: 21.054552 },
    { lat: 48.6728338, lng: 21.0546815 },
    { lat: 48.6725135, lng: 21.0547321 },
    { lat: 48.672445, lng: 21.0549822 },
    { lat: 48.6723676, lng: 21.0549883 },
    { lat: 48.6723627, lng: 21.0551482 },
    { lat: 48.6721991, lng: 21.055202 },
    { lat: 48.6719236, lng: 21.0555894 },
    { lat: 48.671705, lng: 21.055549 },
    { lat: 48.6715476, lng: 21.0556889 },
    { lat: 48.6715682, lng: 21.0558373 },
    { lat: 48.6714663, lng: 21.0560967 },
    { lat: 48.67133, lng: 21.0561603 },
    { lat: 48.671133, lng: 21.0563694 },
    { lat: 48.6711, lng: 21.0565282 },
    { lat: 48.6706252, lng: 21.0567747 },
    { lat: 48.6700436, lng: 21.0577488 },
    { lat: 48.669934, lng: 21.0580109 },
    { lat: 48.6690783, lng: 21.0590712 },
    { lat: 48.6685122, lng: 21.0592163 },
    { lat: 48.6683832, lng: 21.0593605 },
    { lat: 48.6683051, lng: 21.063286 },
    { lat: 48.668138, lng: 21.0664797 },
    { lat: 48.668126, lng: 21.0665233 },
    { lat: 48.6675605, lng: 21.0662662 },
    { lat: 48.6676056, lng: 21.067064 },
    { lat: 48.667741, lng: 21.0686296 },
    { lat: 48.6682715, lng: 21.0724484 },
    { lat: 48.6684161, lng: 21.0739859 },
    { lat: 48.66861, lng: 21.0754447 },
    { lat: 48.6685906, lng: 21.0756124 },
    { lat: 48.6686397, lng: 21.0758438 },
    { lat: 48.6688997, lng: 21.0763252 },
    { lat: 48.6692004, lng: 21.0790355 },
    { lat: 48.6693621, lng: 21.0796844 },
    { lat: 48.669566, lng: 21.0800671 },
    { lat: 48.6695996, lng: 21.0802743 },
  ],
  Cestice: [
    { lat: 48.5623299, lng: 21.0678667 },
    { lat: 48.5617396, lng: 21.0689548 },
    { lat: 48.5608576, lng: 21.0700701 },
    { lat: 48.5601659, lng: 21.0711571 },
    { lat: 48.5582766, lng: 21.073109 },
    { lat: 48.557692, lng: 21.0731629 },
    { lat: 48.5573717, lng: 21.0733877 },
    { lat: 48.5565292, lng: 21.0742334 },
    { lat: 48.5565551, lng: 21.0744067 },
    { lat: 48.5565102, lng: 21.0745486 },
    { lat: 48.5563664, lng: 21.0746956 },
    { lat: 48.5563983, lng: 21.0749157 },
    { lat: 48.5565178, lng: 21.0752323 },
    { lat: 48.5564784, lng: 21.0754715 },
    { lat: 48.5565749, lng: 21.0759778 },
    { lat: 48.5565212, lng: 21.0762713 },
    { lat: 48.5565441, lng: 21.0764878 },
    { lat: 48.5564471, lng: 21.0765873 },
    { lat: 48.5561299, lng: 21.0766246 },
    { lat: 48.5558769, lng: 21.0771496 },
    { lat: 48.5557374, lng: 21.0776023 },
    { lat: 48.5555512, lng: 21.0779308 },
    { lat: 48.5555842, lng: 21.0790553 },
    { lat: 48.5554386, lng: 21.0794885 },
    { lat: 48.5549176, lng: 21.0799455 },
    { lat: 48.5549298, lng: 21.0807678 },
    { lat: 48.5546658, lng: 21.0812666 },
    { lat: 48.5536922, lng: 21.0821009 },
    { lat: 48.5536054, lng: 21.0829571 },
    { lat: 48.5536202, lng: 21.083139 },
    { lat: 48.5532609, lng: 21.0835122 },
    { lat: 48.5532475, lng: 21.0840717 },
    { lat: 48.5531343, lng: 21.0844659 },
    { lat: 48.5528068, lng: 21.0847792 },
    { lat: 48.5527794, lng: 21.0848722 },
    { lat: 48.5527925, lng: 21.0850663 },
    { lat: 48.5531666, lng: 21.085632 },
    { lat: 48.5531991, lng: 21.0857977 },
    { lat: 48.5528921, lng: 21.086221 },
    { lat: 48.5529744, lng: 21.086405 },
    { lat: 48.5529829, lng: 21.0865636 },
    { lat: 48.5526764, lng: 21.088252 },
    { lat: 48.551568, lng: 21.087735 },
    { lat: 48.5512403, lng: 21.0876658 },
    { lat: 48.5505973, lng: 21.0876552 },
    { lat: 48.5502202, lng: 21.0881022 },
    { lat: 48.5498197, lng: 21.0882827 },
    { lat: 48.5495106, lng: 21.0886053 },
    { lat: 48.5488347, lng: 21.0887613 },
    { lat: 48.5484764, lng: 21.0889276 },
    { lat: 48.548303, lng: 21.0891642 },
    { lat: 48.5476626, lng: 21.0909005 },
    { lat: 48.54764, lng: 21.0909661 },
    { lat: 48.5479559, lng: 21.0913396 },
    { lat: 48.5480197, lng: 21.0916412 },
    { lat: 48.5479688, lng: 21.0921536 },
    { lat: 48.5481389, lng: 21.0928803 },
    { lat: 48.5486371, lng: 21.0931826 },
    { lat: 48.5486788, lng: 21.0933927 },
    { lat: 48.548651, lng: 21.0935453 },
    { lat: 48.5483022, lng: 21.0937582 },
    { lat: 48.5482295, lng: 21.094009 },
    { lat: 48.5486584, lng: 21.0943991 },
    { lat: 48.5487411, lng: 21.0945856 },
    { lat: 48.548881, lng: 21.0947028 },
    { lat: 48.5488866, lng: 21.0948392 },
    { lat: 48.5486617, lng: 21.0951922 },
    { lat: 48.5486629, lng: 21.0955423 },
    { lat: 48.5487841, lng: 21.0955995 },
    { lat: 48.5491094, lng: 21.0952516 },
    { lat: 48.549238, lng: 21.095196 },
    { lat: 48.5493319, lng: 21.0952459 },
    { lat: 48.5493081, lng: 21.0956196 },
    { lat: 48.5493588, lng: 21.0957285 },
    { lat: 48.5495986, lng: 21.0956252 },
    { lat: 48.5496624, lng: 21.0957889 },
    { lat: 48.5500255, lng: 21.0962948 },
    { lat: 48.5500268, lng: 21.0964076 },
    { lat: 48.5499478, lng: 21.0965396 },
    { lat: 48.5497183, lng: 21.0966459 },
    { lat: 48.5496654, lng: 21.0968207 },
    { lat: 48.5497334, lng: 21.0970054 },
    { lat: 48.5497031, lng: 21.0974606 },
    { lat: 48.5498169, lng: 21.0975364 },
    { lat: 48.5499312, lng: 21.0974154 },
    { lat: 48.550076, lng: 21.0970222 },
    { lat: 48.5501703, lng: 21.0971538 },
    { lat: 48.5502634, lng: 21.0978727 },
    { lat: 48.5505739, lng: 21.097554 },
    { lat: 48.5506183, lng: 21.0976377 },
    { lat: 48.5506321, lng: 21.0979959 },
    { lat: 48.5508847, lng: 21.0989511 },
    { lat: 48.5509768, lng: 21.0990122 },
    { lat: 48.5515331, lng: 21.0989156 },
    { lat: 48.5516254, lng: 21.0989848 },
    { lat: 48.5514785, lng: 21.0994869 },
    { lat: 48.5517623, lng: 21.1001535 },
    { lat: 48.5519144, lng: 21.1002606 },
    { lat: 48.5520566, lng: 21.100101 },
    { lat: 48.5523527, lng: 21.1004533 },
    { lat: 48.5525742, lng: 21.1005493 },
    { lat: 48.5526568, lng: 21.1005284 },
    { lat: 48.5528221, lng: 21.1006668 },
    { lat: 48.5529956, lng: 21.1011884 },
    { lat: 48.553126, lng: 21.1013632 },
    { lat: 48.553274, lng: 21.1013744 },
    { lat: 48.5534567, lng: 21.1012459 },
    { lat: 48.5535369, lng: 21.1011896 },
    { lat: 48.5536304, lng: 21.1009805 },
    { lat: 48.5544209, lng: 21.0961984 },
    { lat: 48.5545456, lng: 21.0962258 },
    { lat: 48.5546095, lng: 21.0963543 },
    { lat: 48.5547459, lng: 21.0963564 },
    { lat: 48.5550002, lng: 21.096148 },
    { lat: 48.5556191, lng: 21.0937085 },
    { lat: 48.5548778, lng: 21.092581 },
    { lat: 48.5551852, lng: 21.0918588 },
    { lat: 48.555757, lng: 21.0909516 },
    { lat: 48.5559491, lng: 21.09025 },
    { lat: 48.5561993, lng: 21.0900877 },
    { lat: 48.5562553, lng: 21.0897986 },
    { lat: 48.5565608, lng: 21.089817 },
    { lat: 48.5568142, lng: 21.0895716 },
    { lat: 48.5570704, lng: 21.0894199 },
    { lat: 48.5574675, lng: 21.0889956 },
    { lat: 48.5578608, lng: 21.0887744 },
    { lat: 48.5581864, lng: 21.0886768 },
    { lat: 48.5606467, lng: 21.0882952 },
    { lat: 48.5623475, lng: 21.0883309 },
    { lat: 48.5641109, lng: 21.0890126 },
    { lat: 48.5645203, lng: 21.0892507 },
    { lat: 48.5650691, lng: 21.0891985 },
    { lat: 48.5656738, lng: 21.0894863 },
    { lat: 48.5659815, lng: 21.0894636 },
    { lat: 48.5664353, lng: 21.0896743 },
    { lat: 48.5675352, lng: 21.0905862 },
    { lat: 48.5685327, lng: 21.0912552 },
    { lat: 48.5698034, lng: 21.0919456 },
    { lat: 48.5700572, lng: 21.0922798 },
    { lat: 48.5701441, lng: 21.0925311 },
    { lat: 48.5704314, lng: 21.0928504 },
    { lat: 48.5716982, lng: 21.0939432 },
    { lat: 48.5722178, lng: 21.0941765 },
    { lat: 48.5735999, lng: 21.0951819 },
    { lat: 48.5737738, lng: 21.0951595 },
    { lat: 48.5737406, lng: 21.0953532 },
    { lat: 48.5738634, lng: 21.0953566 },
    { lat: 48.573854, lng: 21.0954806 },
    { lat: 48.5744256, lng: 21.0954794 },
    { lat: 48.5747298, lng: 21.0963211 },
    { lat: 48.5752099, lng: 21.0964774 },
    { lat: 48.5752074, lng: 21.0966474 },
    { lat: 48.5757005, lng: 21.0968227 },
    { lat: 48.5756357, lng: 21.0976033 },
    { lat: 48.5762078, lng: 21.0983432 },
    { lat: 48.5769444, lng: 21.099022 },
    { lat: 48.5768934, lng: 21.1006757 },
    { lat: 48.577456, lng: 21.1009194 },
    { lat: 48.5773928, lng: 21.1015383 },
    { lat: 48.5776299, lng: 21.1019143 },
    { lat: 48.5778782, lng: 21.1020361 },
    { lat: 48.5782222, lng: 21.102441 },
    { lat: 48.5781694, lng: 21.103107 },
    { lat: 48.579027, lng: 21.1037998 },
    { lat: 48.5789926, lng: 21.1044809 },
    { lat: 48.5793658, lng: 21.1046369 },
    { lat: 48.5793247, lng: 21.1051759 },
    { lat: 48.5796709, lng: 21.1054747 },
    { lat: 48.5796112, lng: 21.1065174 },
    { lat: 48.5793525, lng: 21.1092449 },
    { lat: 48.581258, lng: 21.110921 },
    { lat: 48.5812533, lng: 21.1114033 },
    { lat: 48.5817191, lng: 21.111468 },
    { lat: 48.5817127, lng: 21.1117589 },
    { lat: 48.5822267, lng: 21.1118123 },
    { lat: 48.5822129, lng: 21.1120873 },
    { lat: 48.582368, lng: 21.112114 },
    { lat: 48.5823524, lng: 21.1125005 },
    { lat: 48.5824335, lng: 21.1125351 },
    { lat: 48.5824538, lng: 21.1128894 },
    { lat: 48.5828335, lng: 21.1129025 },
    { lat: 48.5828506, lng: 21.11371 },
    { lat: 48.583151, lng: 21.1137463 },
    { lat: 48.5831497, lng: 21.114057 },
    { lat: 48.5833354, lng: 21.1142646 },
    { lat: 48.5844642, lng: 21.1150628 },
    { lat: 48.5854002, lng: 21.1155429 },
    { lat: 48.5858322, lng: 21.1158828 },
    { lat: 48.5872164, lng: 21.1165704 },
    { lat: 48.5878968, lng: 21.1167677 },
    { lat: 48.5887749, lng: 21.1168435 },
    { lat: 48.588813, lng: 21.1173306 },
    { lat: 48.5887768, lng: 21.120017 },
    { lat: 48.5888808, lng: 21.1201701 },
    { lat: 48.5890203, lng: 21.120064 },
    { lat: 48.5891819, lng: 21.1200518 },
    { lat: 48.5895824, lng: 21.1202486 },
    { lat: 48.5895917, lng: 21.1202597 },
    { lat: 48.5898562, lng: 21.1204028 },
    { lat: 48.5901115, lng: 21.1203248 },
    { lat: 48.5901756, lng: 21.1203958 },
    { lat: 48.5901865, lng: 21.1205604 },
    { lat: 48.5902489, lng: 21.1206827 },
    { lat: 48.5906717, lng: 21.1207738 },
    { lat: 48.5908509, lng: 21.1206768 },
    { lat: 48.591287, lng: 21.1208984 },
    { lat: 48.5914963, lng: 21.1208646 },
    { lat: 48.5916622, lng: 21.120706 },
    { lat: 48.5918148, lng: 21.1206917 },
    { lat: 48.5919418, lng: 21.1207908 },
    { lat: 48.5921414, lng: 21.1207247 },
    { lat: 48.5924434, lng: 21.1204946 },
    { lat: 48.5925453, lng: 21.1205174 },
    { lat: 48.5928045, lng: 21.1208767 },
    { lat: 48.5930351, lng: 21.1210803 },
    { lat: 48.5932563, lng: 21.1214158 },
    { lat: 48.5936643, lng: 21.121504 },
    { lat: 48.5938647, lng: 21.1218172 },
    { lat: 48.5941103, lng: 21.121928 },
    { lat: 48.5943641, lng: 21.1223688 },
    { lat: 48.5945764, lng: 21.1226001 },
    { lat: 48.5951135, lng: 21.1230622 },
    { lat: 48.5957483, lng: 21.1233472 },
    { lat: 48.5963155, lng: 21.1233762 },
    { lat: 48.5965035, lng: 21.123308 },
    { lat: 48.596855, lng: 21.1234221 },
    { lat: 48.5970704, lng: 21.1232272 },
    { lat: 48.5973284, lng: 21.123373 },
    { lat: 48.5975999, lng: 21.1236593 },
    { lat: 48.5977379, lng: 21.1236442 },
    { lat: 48.597911, lng: 21.1233965 },
    { lat: 48.5984817, lng: 21.1231525 },
    { lat: 48.5987008, lng: 21.1232823 },
    { lat: 48.5989073, lng: 21.1231919 },
    { lat: 48.5990618, lng: 21.1231994 },
    { lat: 48.5999175, lng: 21.1234939 },
    { lat: 48.6006304, lng: 21.12347 },
    { lat: 48.6009556, lng: 21.1233947 },
    { lat: 48.601498, lng: 21.1235335 },
    { lat: 48.6024223, lng: 21.1239674 },
    { lat: 48.603004, lng: 21.1240281 },
    { lat: 48.6040211, lng: 21.1245075 },
    { lat: 48.6049006, lng: 21.1248011 },
    { lat: 48.6055755, lng: 21.1251366 },
    { lat: 48.6067436, lng: 21.1254869 },
    { lat: 48.6075929, lng: 21.1253343 },
    { lat: 48.6083481, lng: 21.1252969 },
    { lat: 48.6085715, lng: 21.1253791 },
    { lat: 48.6098024, lng: 21.1255112 },
    { lat: 48.6112319, lng: 21.1255117 },
    { lat: 48.6124272, lng: 21.1257908 },
    { lat: 48.613525, lng: 21.1255225 },
    { lat: 48.6144464, lng: 21.1222543 },
    { lat: 48.6142352, lng: 21.1220603 },
    { lat: 48.6138483, lng: 21.1213274 },
    { lat: 48.6135789, lng: 21.1206876 },
    { lat: 48.612887, lng: 21.1195392 },
    { lat: 48.6105519, lng: 21.1166071 },
    { lat: 48.6102716, lng: 21.1160845 },
    { lat: 48.6100212, lng: 21.115405 },
    { lat: 48.609569, lng: 21.1148157 },
    { lat: 48.6094416, lng: 21.114707 },
    { lat: 48.6086911, lng: 21.1146118 },
    { lat: 48.6074454, lng: 21.1148983 },
    { lat: 48.6073641, lng: 21.1130342 },
    { lat: 48.6074728, lng: 21.1128129 },
    { lat: 48.6071191, lng: 21.1109838 },
    { lat: 48.6069066, lng: 21.1089654 },
    { lat: 48.6066821, lng: 21.1073434 },
    { lat: 48.6064334, lng: 21.1064413 },
    { lat: 48.6069223, lng: 21.1064559 },
    { lat: 48.6083786, lng: 21.1067303 },
    { lat: 48.6085402, lng: 21.1059495 },
    { lat: 48.6087544, lng: 21.1054672 },
    { lat: 48.6089096, lng: 21.1045549 },
    { lat: 48.6089449, lng: 21.1042614 },
    { lat: 48.6088882, lng: 21.1036502 },
    { lat: 48.6090992, lng: 21.1021648 },
    { lat: 48.6096098, lng: 21.1023328 },
    { lat: 48.6097733, lng: 21.1023059 },
    { lat: 48.609912, lng: 21.1005398 },
    { lat: 48.6108737, lng: 21.100829 },
    { lat: 48.6110267, lng: 21.0996057 },
    { lat: 48.6118855, lng: 21.0997168 },
    { lat: 48.6125815, lng: 21.1000395 },
    { lat: 48.613032, lng: 21.1003976 },
    { lat: 48.6130155, lng: 21.0977621 },
    { lat: 48.6131041, lng: 21.0959828 },
    { lat: 48.6128951, lng: 21.0959591 },
    { lat: 48.6129174, lng: 21.0944909 },
    { lat: 48.6129179, lng: 21.0944717 },
    { lat: 48.6125873, lng: 21.0943036 },
    { lat: 48.610844, lng: 21.0936836 },
    { lat: 48.6094305, lng: 21.0930639 },
    { lat: 48.6084723, lng: 21.0925629 },
    { lat: 48.6083911, lng: 21.0921467 },
    { lat: 48.6082601, lng: 21.0920977 },
    { lat: 48.6080321, lng: 21.0921824 },
    { lat: 48.6079739, lng: 21.0919509 },
    { lat: 48.6073343, lng: 21.0918351 },
    { lat: 48.6072679, lng: 21.091553 },
    { lat: 48.6066183, lng: 21.091757 },
    { lat: 48.6066111, lng: 21.0916056 },
    { lat: 48.6058916, lng: 21.0916679 },
    { lat: 48.6058848, lng: 21.0915958 },
    { lat: 48.6054354, lng: 21.0914479 },
    { lat: 48.6048782, lng: 21.0911305 },
    { lat: 48.6030046, lng: 21.089829 },
    { lat: 48.6022823, lng: 21.0896585 },
    { lat: 48.6021204, lng: 21.0894973 },
    { lat: 48.6017762, lng: 21.0894298 },
    { lat: 48.6012222, lng: 21.0890076 },
    { lat: 48.6009805, lng: 21.0889134 },
    { lat: 48.6008324, lng: 21.0886537 },
    { lat: 48.6005244, lng: 21.0883841 },
    { lat: 48.6001958, lng: 21.0881786 },
    { lat: 48.6000478, lng: 21.0879883 },
    { lat: 48.5990959, lng: 21.0873405 },
    { lat: 48.598896, lng: 21.0871113 },
    { lat: 48.5985679, lng: 21.0869151 },
    { lat: 48.598386, lng: 21.0868616 },
    { lat: 48.5981778, lng: 21.0868997 },
    { lat: 48.5980394, lng: 21.0864786 },
    { lat: 48.5973951, lng: 21.0862278 },
    { lat: 48.5972752, lng: 21.0860364 },
    { lat: 48.5971964, lng: 21.0859978 },
    { lat: 48.5970518, lng: 21.0857077 },
    { lat: 48.5966267, lng: 21.0854357 },
    { lat: 48.5956631, lng: 21.0852583 },
    { lat: 48.5953906, lng: 21.0852905 },
    { lat: 48.5950669, lng: 21.0851639 },
    { lat: 48.5937083, lng: 21.0849573 },
    { lat: 48.5933278, lng: 21.0848119 },
    { lat: 48.5921173, lng: 21.0845553 },
    { lat: 48.5919183, lng: 21.0845991 },
    { lat: 48.5915601, lng: 21.0848356 },
    { lat: 48.5912974, lng: 21.0849233 },
    { lat: 48.5908515, lng: 21.0848826 },
    { lat: 48.5907513, lng: 21.0848477 },
    { lat: 48.5904248, lng: 21.0843946 },
    { lat: 48.5901841, lng: 21.0843271 },
    { lat: 48.5900664, lng: 21.0841377 },
    { lat: 48.5897494, lng: 21.0838379 },
    { lat: 48.5893968, lng: 21.0836492 },
    { lat: 48.5885877, lng: 21.0827917 },
    { lat: 48.5876174, lng: 21.082131 },
    { lat: 48.5873209, lng: 21.0820145 },
    { lat: 48.586761, lng: 21.0819258 },
    { lat: 48.5851582, lng: 21.0817558 },
    { lat: 48.5827311, lng: 21.0812589 },
    { lat: 48.582339, lng: 21.0811399 },
    { lat: 48.5823384, lng: 21.0806068 },
    { lat: 48.5805022, lng: 21.0802771 },
    { lat: 48.5804771, lng: 21.0810961 },
    { lat: 48.5796186, lng: 21.0810753 },
    { lat: 48.578283, lng: 21.0809155 },
    { lat: 48.5782924, lng: 21.0806091 },
    { lat: 48.578107, lng: 21.0804863 },
    { lat: 48.5774903, lng: 21.0802784 },
    { lat: 48.5767013, lng: 21.0802773 },
    { lat: 48.5765045, lng: 21.080391 },
    { lat: 48.5757549, lng: 21.0802861 },
    { lat: 48.5745271, lng: 21.0794633 },
    { lat: 48.5735357, lng: 21.0791193 },
    { lat: 48.5732529, lng: 21.0789376 },
    { lat: 48.5730084, lng: 21.0786589 },
    { lat: 48.5721033, lng: 21.0770596 },
    { lat: 48.5715674, lng: 21.0762914 },
    { lat: 48.5703093, lng: 21.0748856 },
    { lat: 48.5691425, lng: 21.0738645 },
    { lat: 48.5661674, lng: 21.071607 },
    { lat: 48.5627078, lng: 21.0682378 },
    { lat: 48.5623299, lng: 21.0678667 },
  ],
  Medzev: [
    { lat: 48.754427, lng: 20.842549 },
    { lat: 48.754232, lng: 20.842531 },
    { lat: 48.753875, lng: 20.84264 },
    { lat: 48.753728, lng: 20.842648 },
    { lat: 48.753583, lng: 20.842591 },
    { lat: 48.753371, lng: 20.842438 },
    { lat: 48.753223, lng: 20.84212 },
    { lat: 48.753097, lng: 20.84195 },
    { lat: 48.752966, lng: 20.841837 },
    { lat: 48.752843, lng: 20.841627 },
    { lat: 48.752504, lng: 20.841419 },
    { lat: 48.752424, lng: 20.8414 },
    { lat: 48.752305, lng: 20.841266 },
    { lat: 48.751741, lng: 20.840872 },
    { lat: 48.751497, lng: 20.840664 },
    { lat: 48.751318, lng: 20.84061 },
    { lat: 48.751031, lng: 20.840401 },
    { lat: 48.750906, lng: 20.840213 },
    { lat: 48.750784, lng: 20.839904 },
    { lat: 48.75069, lng: 20.839738 },
    { lat: 48.750531, lng: 20.839621 },
    { lat: 48.750338, lng: 20.839624 },
    { lat: 48.75026, lng: 20.839609 },
    { lat: 48.750157, lng: 20.839573 },
    { lat: 48.749901, lng: 20.839257 },
    { lat: 48.749659, lng: 20.839102 },
    { lat: 48.749358, lng: 20.838962 },
    { lat: 48.749165, lng: 20.838835 },
    { lat: 48.748936, lng: 20.83866 },
    { lat: 48.748725, lng: 20.838409 },
    { lat: 48.748494, lng: 20.83802 },
    { lat: 48.748494, lng: 20.837662 },
    { lat: 48.748428, lng: 20.837213 },
    { lat: 48.748418, lng: 20.836574 },
    { lat: 48.74788, lng: 20.835707 },
    { lat: 48.747801, lng: 20.835518 },
    { lat: 48.7476, lng: 20.83516 },
    { lat: 48.747371, lng: 20.834815 },
    { lat: 48.747187, lng: 20.834645 },
    { lat: 48.746745, lng: 20.834505 },
    { lat: 48.746692, lng: 20.834664 },
    { lat: 48.746699, lng: 20.835022 },
    { lat: 48.746682, lng: 20.835092 },
    { lat: 48.746265, lng: 20.83506 },
    { lat: 48.745928, lng: 20.834873 },
    { lat: 48.745643, lng: 20.83467 },
    { lat: 48.745502, lng: 20.834487 },
    { lat: 48.745014, lng: 20.834256 },
    { lat: 48.744561, lng: 20.833951 },
    { lat: 48.744228, lng: 20.833743 },
    { lat: 48.744084, lng: 20.833568 },
    { lat: 48.743818, lng: 20.833067 },
    { lat: 48.743422, lng: 20.832348 },
    { lat: 48.743257, lng: 20.831941 },
    { lat: 48.74309, lng: 20.831562 },
    { lat: 48.743006, lng: 20.831215 },
    { lat: 48.743011, lng: 20.831021 },
    { lat: 48.743033, lng: 20.830741 },
    { lat: 48.743079, lng: 20.830415 },
    { lat: 48.743124, lng: 20.829969 },
    { lat: 48.743097, lng: 20.829952 },
    { lat: 48.742988, lng: 20.829882 },
    { lat: 48.742964, lng: 20.829867 },
    { lat: 48.7424838, lng: 20.830682 },
    { lat: 48.7423443, lng: 20.8311096 },
    { lat: 48.7422028, lng: 20.8313837 },
    { lat: 48.7414643, lng: 20.8324842 },
    { lat: 48.7412544, lng: 20.8330575 },
    { lat: 48.7411683, lng: 20.8338222 },
    { lat: 48.7405973, lng: 20.8343983 },
    { lat: 48.7397222, lng: 20.8343851 },
    { lat: 48.7389146, lng: 20.8348668 },
    { lat: 48.7385193, lng: 20.834663 },
    { lat: 48.7381837, lng: 20.833778 },
    { lat: 48.7380625, lng: 20.8337237 },
    { lat: 48.7369114, lng: 20.8345517 },
    { lat: 48.7364767, lng: 20.834743 },
    { lat: 48.73588, lng: 20.8345479 },
    { lat: 48.7353192, lng: 20.8345063 },
    { lat: 48.734104, lng: 20.8348825 },
    { lat: 48.73368, lng: 20.8350921 },
    { lat: 48.7334859, lng: 20.8356259 },
    { lat: 48.7331419, lng: 20.8358346 },
    { lat: 48.7320762, lng: 20.8361712 },
    { lat: 48.7317288, lng: 20.8365399 },
    { lat: 48.7312732, lng: 20.8368454 },
    { lat: 48.7311687, lng: 20.8368522 },
    { lat: 48.7305625, lng: 20.837421 },
    { lat: 48.7300679, lng: 20.8377713 },
    { lat: 48.7293876, lng: 20.8378859 },
    { lat: 48.7290835, lng: 20.8380155 },
    { lat: 48.7286802, lng: 20.8382969 },
    { lat: 48.7276869, lng: 20.8384483 },
    { lat: 48.7273453, lng: 20.8386596 },
    { lat: 48.7268412, lng: 20.8385692 },
    { lat: 48.7264104, lng: 20.8385828 },
    { lat: 48.7262788, lng: 20.8386766 },
    { lat: 48.7261452, lng: 20.8388883 },
    { lat: 48.7257927, lng: 20.8396021 },
    { lat: 48.725389, lng: 20.8399081 },
    { lat: 48.7250601, lng: 20.8403551 },
    { lat: 48.7249145, lng: 20.8403747 },
    { lat: 48.724621, lng: 20.8396702 },
    { lat: 48.7243139, lng: 20.8392541 },
    { lat: 48.7241628, lng: 20.8388877 },
    { lat: 48.7239463, lng: 20.8387461 },
    { lat: 48.7236752, lng: 20.8387493 },
    { lat: 48.7231691, lng: 20.8396082 },
    { lat: 48.7228379, lng: 20.8398942 },
    { lat: 48.7222512, lng: 20.8400855 },
    { lat: 48.721759, lng: 20.8399496 },
    { lat: 48.7216638, lng: 20.83977 },
    { lat: 48.7212285, lng: 20.8394788 },
    { lat: 48.7207896, lng: 20.8392902 },
    { lat: 48.7197879, lng: 20.8386455 },
    { lat: 48.7189681, lng: 20.8379807 },
    { lat: 48.7183147, lng: 20.8380954 },
    { lat: 48.7177364, lng: 20.8380715 },
    { lat: 48.7171866, lng: 20.8382239 },
    { lat: 48.7170992, lng: 20.8380707 },
    { lat: 48.7168121, lng: 20.8379729 },
    { lat: 48.7166922, lng: 20.8377324 },
    { lat: 48.7163939, lng: 20.8375652 },
    { lat: 48.7156778, lng: 20.8378256 },
    { lat: 48.7154421, lng: 20.8377761 },
    { lat: 48.7147778, lng: 20.8373519 },
    { lat: 48.7144901, lng: 20.8369369 },
    { lat: 48.7138493, lng: 20.837063 },
    { lat: 48.7135115, lng: 20.8374176 },
    { lat: 48.7132886, lng: 20.8374544 },
    { lat: 48.7127018, lng: 20.8377705 },
    { lat: 48.7125551, lng: 20.8380177 },
    { lat: 48.7118863, lng: 20.838794 },
    { lat: 48.7108355, lng: 20.839692 },
    { lat: 48.7106468, lng: 20.8397728 },
    { lat: 48.7100747, lng: 20.8404128 },
    { lat: 48.7097352, lng: 20.8405903 },
    { lat: 48.7092517, lng: 20.8409673 },
    { lat: 48.7087565, lng: 20.8411639 },
    { lat: 48.7082264, lng: 20.8410389 },
    { lat: 48.7078662, lng: 20.8411353 },
    { lat: 48.7072854, lng: 20.8411689 },
    { lat: 48.7067789, lng: 20.8410788 },
    { lat: 48.7062395, lng: 20.8412025 },
    { lat: 48.7058775, lng: 20.8410817 },
    { lat: 48.7055326, lng: 20.8407739 },
    { lat: 48.7049435, lng: 20.840764 },
    { lat: 48.7038672, lng: 20.8400765 },
    { lat: 48.7026956, lng: 20.8403092 },
    { lat: 48.7019752, lng: 20.8410331 },
    { lat: 48.7019067, lng: 20.8410998 },
    { lat: 48.7017652, lng: 20.8412118 },
    { lat: 48.7015957, lng: 20.8410619 },
    { lat: 48.7010471, lng: 20.8408207 },
    { lat: 48.6998962, lng: 20.8412638 },
    { lat: 48.6996912, lng: 20.8412892 },
    { lat: 48.6991398, lng: 20.8411709 },
    { lat: 48.6989994, lng: 20.841209 },
    { lat: 48.6983425, lng: 20.8411303 },
    { lat: 48.697893, lng: 20.8411807 },
    { lat: 48.6975373, lng: 20.8413463 },
    { lat: 48.6965797, lng: 20.8420378 },
    { lat: 48.6957503, lng: 20.842442 },
    { lat: 48.6953354, lng: 20.8427508 },
    { lat: 48.6942137, lng: 20.8440402 },
    { lat: 48.6940798, lng: 20.8443308 },
    { lat: 48.6929041, lng: 20.8452995 },
    { lat: 48.692275, lng: 20.8463135 },
    { lat: 48.6921214, lng: 20.8467225 },
    { lat: 48.6919805, lng: 20.8474384 },
    { lat: 48.6917895, lng: 20.8476736 },
    { lat: 48.6912678, lng: 20.8480873 },
    { lat: 48.6908542, lng: 20.8481405 },
    { lat: 48.6907379, lng: 20.8482273 },
    { lat: 48.6906049, lng: 20.848552 },
    { lat: 48.6904156, lng: 20.8488016 },
    { lat: 48.6901293, lng: 20.8493782 },
    { lat: 48.6899357, lng: 20.8500383 },
    { lat: 48.6897619, lng: 20.8503195 },
    { lat: 48.689457, lng: 20.8512046 },
    { lat: 48.6892457, lng: 20.8519665 },
    { lat: 48.6891919, lng: 20.8527542 },
    { lat: 48.689059, lng: 20.8530803 },
    { lat: 48.6891148, lng: 20.8533944 },
    { lat: 48.6888572, lng: 20.854052 },
    { lat: 48.6887935, lng: 20.8543944 },
    { lat: 48.6888067, lng: 20.8547429 },
    { lat: 48.6881639, lng: 20.8540234 },
    { lat: 48.6878706, lng: 20.8544658 },
    { lat: 48.6878097, lng: 20.854726 },
    { lat: 48.6877841, lng: 20.8551595 },
    { lat: 48.6877909, lng: 20.8559139 },
    { lat: 48.6876248, lng: 20.8565511 },
    { lat: 48.6876472, lng: 20.8571311 },
    { lat: 48.6873818, lng: 20.857764 },
    { lat: 48.6873276, lng: 20.858148 },
    { lat: 48.6871814, lng: 20.8586108 },
    { lat: 48.6870757, lng: 20.8586494 },
    { lat: 48.6870486, lng: 20.8589123 },
    { lat: 48.6859343, lng: 20.8607097 },
    { lat: 48.6857575, lng: 20.8613053 },
    { lat: 48.6853672, lng: 20.8616024 },
    { lat: 48.6852305, lng: 20.8618881 },
    { lat: 48.6848328, lng: 20.8623353 },
    { lat: 48.6845721, lng: 20.8621541 },
    { lat: 48.6845673, lng: 20.8619468 },
    { lat: 48.6844317, lng: 20.8619328 },
    { lat: 48.6841807, lng: 20.8616711 },
    { lat: 48.6840422, lng: 20.8614358 },
    { lat: 48.6839907, lng: 20.8612221 },
    { lat: 48.6835399, lng: 20.8616942 },
    { lat: 48.6834401, lng: 20.8617088 },
    { lat: 48.6830711, lng: 20.8615471 },
    { lat: 48.6829415, lng: 20.8618415 },
    { lat: 48.6829906, lng: 20.8624183 },
    { lat: 48.6827512, lng: 20.863608 },
    { lat: 48.6826512, lng: 20.8638403 },
    { lat: 48.6825147, lng: 20.8639678 },
    { lat: 48.6822286, lng: 20.8646496 },
    { lat: 48.6820396, lng: 20.8648468 },
    { lat: 48.681544, lng: 20.8656582 },
    { lat: 48.6812638, lng: 20.8658582 },
    { lat: 48.6810203, lng: 20.8658149 },
    { lat: 48.680749, lng: 20.8659163 },
    { lat: 48.6809997, lng: 20.8668214 },
    { lat: 48.6810761, lng: 20.8675104 },
    { lat: 48.6812175, lng: 20.868152 },
    { lat: 48.6808762, lng: 20.8679817 },
    { lat: 48.6806606, lng: 20.8680295 },
    { lat: 48.6803507, lng: 20.8678853 },
    { lat: 48.6798625, lng: 20.868129 },
    { lat: 48.6795459, lng: 20.8681195 },
    { lat: 48.6789168, lng: 20.8672672 },
    { lat: 48.6787172, lng: 20.8672596 },
    { lat: 48.6782645, lng: 20.8678785 },
    { lat: 48.6782082, lng: 20.8678106 },
    { lat: 48.6781117, lng: 20.8679556 },
    { lat: 48.6780093, lng: 20.8683455 },
    { lat: 48.6777475, lng: 20.8686584 },
    { lat: 48.6777337, lng: 20.8690741 },
    { lat: 48.6775135, lng: 20.8695786 },
    { lat: 48.6772966, lng: 20.8697544 },
    { lat: 48.6770746, lng: 20.8701105 },
    { lat: 48.6768348, lng: 20.8706649 },
    { lat: 48.6767178, lng: 20.8706986 },
    { lat: 48.6764092, lng: 20.8711241 },
    { lat: 48.6762233, lng: 20.8712202 },
    { lat: 48.676183, lng: 20.8713562 },
    { lat: 48.6758382, lng: 20.8714419 },
    { lat: 48.6756719, lng: 20.871388 },
    { lat: 48.6756187, lng: 20.8714456 },
    { lat: 48.6757596, lng: 20.871743 },
    { lat: 48.6754217, lng: 20.8721327 },
    { lat: 48.6755224, lng: 20.872179 },
    { lat: 48.6753984, lng: 20.8724715 },
    { lat: 48.6746407, lng: 20.8727671 },
    { lat: 48.6737866, lng: 20.8737232 },
    { lat: 48.6735112, lng: 20.8754785 },
    { lat: 48.6729728, lng: 20.8778715 },
    { lat: 48.6724187, lng: 20.8807262 },
    { lat: 48.6723468, lng: 20.8813209 },
    { lat: 48.6720996, lng: 20.8842687 },
    { lat: 48.6719186, lng: 20.8874358 },
    { lat: 48.6718433, lng: 20.887968 },
    { lat: 48.6717468, lng: 20.8898589 },
    { lat: 48.6717669, lng: 20.8900827 },
    { lat: 48.6714836, lng: 20.8920108 },
    { lat: 48.6715096, lng: 20.8924768 },
    { lat: 48.6714529, lng: 20.8938516 },
    { lat: 48.6712987, lng: 20.894213 },
    { lat: 48.6713888, lng: 20.8946764 },
    { lat: 48.6712793, lng: 20.8965857 },
    { lat: 48.6710885, lng: 20.8976611 },
    { lat: 48.6707222, lng: 20.8989634 },
    { lat: 48.6707168, lng: 20.9001006 },
    { lat: 48.670104, lng: 20.9041877 },
    { lat: 48.6700427, lng: 20.9050012 },
    { lat: 48.6700859, lng: 20.9055769 },
    { lat: 48.6699144, lng: 20.9076187 },
    { lat: 48.6698725, lng: 20.9087737 },
    { lat: 48.6695965, lng: 20.9103926 },
    { lat: 48.6696302, lng: 20.9107857 },
    { lat: 48.6696052, lng: 20.9112103 },
    { lat: 48.6694441, lng: 20.9117053 },
    { lat: 48.6691855, lng: 20.9140736 },
    { lat: 48.6691869, lng: 20.9148456 },
    { lat: 48.6691291, lng: 20.9156588 },
    { lat: 48.6687246, lng: 20.9172399 },
    { lat: 48.6685966, lng: 20.9184203 },
    { lat: 48.6684968, lng: 20.9190248 },
    { lat: 48.6684133, lng: 20.9192556 },
    { lat: 48.6682999, lng: 20.9203929 },
    { lat: 48.6682923, lng: 20.9219312 },
    { lat: 48.6682334, lng: 20.9222189 },
    { lat: 48.6682261, lng: 20.9222686 },
    { lat: 48.6694485, lng: 20.9218656 },
    { lat: 48.6697272, lng: 20.92152 },
    { lat: 48.6701306, lng: 20.920699 },
    { lat: 48.6704076, lng: 20.9195394 },
    { lat: 48.670438, lng: 20.9184099 },
    { lat: 48.6705614, lng: 20.9171372 },
    { lat: 48.6708514, lng: 20.9161291 },
    { lat: 48.6711685, lng: 20.9157217 },
    { lat: 48.6713168, lng: 20.9153794 },
    { lat: 48.6714582, lng: 20.9149372 },
    { lat: 48.6715559, lng: 20.9144307 },
    { lat: 48.671894, lng: 20.9136136 },
    { lat: 48.672051, lng: 20.9130957 },
    { lat: 48.6729914, lng: 20.9114528 },
    { lat: 48.6731815, lng: 20.9112511 },
    { lat: 48.6740833, lng: 20.9107237 },
    { lat: 48.6744395, lng: 20.910439 },
    { lat: 48.6746385, lng: 20.910263 },
    { lat: 48.6749045, lng: 20.9098083 },
    { lat: 48.6749302, lng: 20.9096061 },
    { lat: 48.6747942, lng: 20.9089852 },
    { lat: 48.6746571, lng: 20.9087892 },
    { lat: 48.6745591, lng: 20.9084916 },
    { lat: 48.6741765, lng: 20.9078947 },
    { lat: 48.6740815, lng: 20.9074515 },
    { lat: 48.6740886, lng: 20.9071329 },
    { lat: 48.6746363, lng: 20.906849 },
    { lat: 48.6754793, lng: 20.9066189 },
    { lat: 48.6759754, lng: 20.9061416 },
    { lat: 48.6761423, lng: 20.9057918 },
    { lat: 48.67652, lng: 20.9052425 },
    { lat: 48.6766789, lng: 20.9047821 },
    { lat: 48.6770478, lng: 20.9043461 },
    { lat: 48.6771659, lng: 20.9042404 },
    { lat: 48.6780949, lng: 20.9040213 },
    { lat: 48.6782251, lng: 20.9038748 },
    { lat: 48.6781704, lng: 20.9035275 },
    { lat: 48.6782791, lng: 20.9035764 },
    { lat: 48.679538, lng: 20.9026322 },
    { lat: 48.6799203, lng: 20.9022841 },
    { lat: 48.6800619, lng: 20.9022155 },
    { lat: 48.6804844, lng: 20.9021909 },
    { lat: 48.6806917, lng: 20.9019312 },
    { lat: 48.6807343, lng: 20.9016646 },
    { lat: 48.6816809, lng: 20.9017287 },
    { lat: 48.6821698, lng: 20.9018989 },
    { lat: 48.6823257, lng: 20.9022184 },
    { lat: 48.6826363, lng: 20.9026464 },
    { lat: 48.6826963, lng: 20.902884 },
    { lat: 48.6831492, lng: 20.9036628 },
    { lat: 48.683435, lng: 20.90389 },
    { lat: 48.6833049, lng: 20.904266 },
    { lat: 48.6833366, lng: 20.9044356 },
    { lat: 48.6838463, lng: 20.9050939 },
    { lat: 48.6840461, lng: 20.9055743 },
    { lat: 48.6843918, lng: 20.9057802 },
    { lat: 48.6846277, lng: 20.906059 },
    { lat: 48.6851853, lng: 20.9065366 },
    { lat: 48.6860238, lng: 20.9070638 },
    { lat: 48.6864001, lng: 20.9072053 },
    { lat: 48.6870742, lng: 20.9076671 },
    { lat: 48.6884338, lng: 20.9099125 },
    { lat: 48.6886818, lng: 20.9106881 },
    { lat: 48.6888518, lng: 20.9109902 },
    { lat: 48.688994, lng: 20.9119283 },
    { lat: 48.6890607, lng: 20.9129 },
    { lat: 48.6891382, lng: 20.9132596 },
    { lat: 48.6891546, lng: 20.9135148 },
    { lat: 48.6888645, lng: 20.9136537 },
    { lat: 48.6891761, lng: 20.9152759 },
    { lat: 48.6887967, lng: 20.9153719 },
    { lat: 48.6889091, lng: 20.9168292 },
    { lat: 48.6891359, lng: 20.916812 },
    { lat: 48.6891229, lng: 20.9173902 },
    { lat: 48.6890345, lng: 20.9180725 },
    { lat: 48.6889725, lng: 20.918172 },
    { lat: 48.6889958, lng: 20.9183227 },
    { lat: 48.6893579, lng: 20.918547 },
    { lat: 48.6894678, lng: 20.9187348 },
    { lat: 48.6898358, lng: 20.919037 },
    { lat: 48.6904077, lng: 20.919834 },
    { lat: 48.6908751, lng: 20.9198104 },
    { lat: 48.6912572, lng: 20.9193904 },
    { lat: 48.6913559, lng: 20.9191445 },
    { lat: 48.6916391, lng: 20.9188686 },
    { lat: 48.6918754, lng: 20.9187014 },
    { lat: 48.692013, lng: 20.9188069 },
    { lat: 48.6919857, lng: 20.9193328 },
    { lat: 48.6918257, lng: 20.9200883 },
    { lat: 48.691868, lng: 20.9203085 },
    { lat: 48.6916798, lng: 20.9209009 },
    { lat: 48.6915204, lng: 20.9226464 },
    { lat: 48.6913976, lng: 20.9230382 },
    { lat: 48.6910803, lng: 20.9247411 },
    { lat: 48.6911345, lng: 20.9252489 },
    { lat: 48.6909608, lng: 20.9257357 },
    { lat: 48.691, lng: 20.9264328 },
    { lat: 48.6909443, lng: 20.9271176 },
    { lat: 48.6911371, lng: 20.9279243 },
    { lat: 48.6913054, lng: 20.9281429 },
    { lat: 48.6914648, lng: 20.9285966 },
    { lat: 48.6916391, lng: 20.928694 },
    { lat: 48.6917599, lng: 20.9293998 },
    { lat: 48.6919701, lng: 20.9300584 },
    { lat: 48.6919904, lng: 20.9307284 },
    { lat: 48.6921043, lng: 20.9311347 },
    { lat: 48.692184, lng: 20.9317969 },
    { lat: 48.692414, lng: 20.9323703 },
    { lat: 48.6926, lng: 20.9333814 },
    { lat: 48.692597, lng: 20.9337272 },
    { lat: 48.6924874, lng: 20.934114 },
    { lat: 48.6926324, lng: 20.9349091 },
    { lat: 48.6926728, lng: 20.9358067 },
    { lat: 48.6927915, lng: 20.9366541 },
    { lat: 48.6927555, lng: 20.937058 },
    { lat: 48.6928093, lng: 20.9377857 },
    { lat: 48.6929608, lng: 20.9388098 },
    { lat: 48.6932226, lng: 20.9389984 },
    { lat: 48.6934785, lng: 20.9392788 },
    { lat: 48.6935507, lng: 20.9391159 },
    { lat: 48.6937034, lng: 20.9387716 },
    { lat: 48.6937015, lng: 20.9384119 },
    { lat: 48.6934162, lng: 20.9380234 },
    { lat: 48.6933288, lng: 20.9376688 },
    { lat: 48.6935129, lng: 20.9372521 },
    { lat: 48.6935454, lng: 20.9370208 },
    { lat: 48.6935109, lng: 20.936493 },
    { lat: 48.6937541, lng: 20.9360592 },
    { lat: 48.6938119, lng: 20.9356048 },
    { lat: 48.6942523, lng: 20.9351166 },
    { lat: 48.6943891, lng: 20.9350313 },
    { lat: 48.6946173, lng: 20.9346849 },
    { lat: 48.6945941, lng: 20.9342003 },
    { lat: 48.6946705, lng: 20.9339276 },
    { lat: 48.6950378, lng: 20.9330766 },
    { lat: 48.6954573, lng: 20.9324924 },
    { lat: 48.6955986, lng: 20.9320797 },
    { lat: 48.6956854, lng: 20.9316447 },
    { lat: 48.6955598, lng: 20.9298597 },
    { lat: 48.6954757, lng: 20.9296028 },
    { lat: 48.6954574, lng: 20.9290634 },
    { lat: 48.6954785, lng: 20.9288205 },
    { lat: 48.6958405, lng: 20.9275752 },
    { lat: 48.6959488, lng: 20.9264113 },
    { lat: 48.6959544, lng: 20.9235811 },
    { lat: 48.6957902, lng: 20.9209581 },
    { lat: 48.6958954, lng: 20.9200728 },
    { lat: 48.6958387, lng: 20.9192252 },
    { lat: 48.6959664, lng: 20.9179466 },
    { lat: 48.695999, lng: 20.9178217 },
    { lat: 48.6963206, lng: 20.9161957 },
    { lat: 48.6963325, lng: 20.9161574 },
    { lat: 48.6971121, lng: 20.9146469 },
    { lat: 48.6976113, lng: 20.9139254 },
    { lat: 48.6976674, lng: 20.9138629 },
    { lat: 48.6976956, lng: 20.9137755 },
    { lat: 48.69786, lng: 20.9132668 },
    { lat: 48.6979633, lng: 20.9131968 },
    { lat: 48.6981032, lng: 20.9132737 },
    { lat: 48.6983458, lng: 20.9132221 },
    { lat: 48.6987757, lng: 20.9132876 },
    { lat: 48.6994136, lng: 20.9136402 },
    { lat: 48.6995355, lng: 20.9135834 },
    { lat: 48.6998277, lng: 20.9131695 },
    { lat: 48.7002633, lng: 20.9133076 },
    { lat: 48.7004425, lng: 20.9131109 },
    { lat: 48.7005562, lng: 20.9131093 },
    { lat: 48.7012272, lng: 20.9136168 },
    { lat: 48.7017791, lng: 20.9137538 },
    { lat: 48.7019153, lng: 20.9136591 },
    { lat: 48.7023766, lng: 20.911722 },
    { lat: 48.702448, lng: 20.9108106 },
    { lat: 48.7027621, lng: 20.9082543 },
    { lat: 48.7035615, lng: 20.9047122 },
    { lat: 48.7035898, lng: 20.9031925 },
    { lat: 48.7036722, lng: 20.9021744 },
    { lat: 48.7037691, lng: 20.9016452 },
    { lat: 48.7040672, lng: 20.9005458 },
    { lat: 48.7045053, lng: 20.8990307 },
    { lat: 48.7053038, lng: 20.8966316 },
    { lat: 48.7061553, lng: 20.8950587 },
    { lat: 48.7070452, lng: 20.8932293 },
    { lat: 48.7086832, lng: 20.8890689 },
    { lat: 48.7090208, lng: 20.8887047 },
    { lat: 48.7092296, lng: 20.888687 },
    { lat: 48.7095929, lng: 20.8890729 },
    { lat: 48.7096762, lng: 20.8895037 },
    { lat: 48.7103107, lng: 20.8897256 },
    { lat: 48.7107218, lng: 20.8889686 },
    { lat: 48.7109927, lng: 20.8887298 },
    { lat: 48.7116549, lng: 20.8884084 },
    { lat: 48.7116458, lng: 20.8879073 },
    { lat: 48.7121591, lng: 20.8877768 },
    { lat: 48.7122339, lng: 20.8873283 },
    { lat: 48.7124173, lng: 20.8874273 },
    { lat: 48.7124606, lng: 20.8873078 },
    { lat: 48.7123502, lng: 20.8870444 },
    { lat: 48.7121935, lng: 20.8868673 },
    { lat: 48.7122836, lng: 20.8866753 },
    { lat: 48.7124609, lng: 20.8865261 },
    { lat: 48.7130487, lng: 20.8863046 },
    { lat: 48.7130737, lng: 20.885815 },
    { lat: 48.7133907, lng: 20.8858705 },
    { lat: 48.7137849, lng: 20.8857126 },
    { lat: 48.7137795, lng: 20.8856157 },
    { lat: 48.7145183, lng: 20.8853617 },
    { lat: 48.7150349, lng: 20.8848966 },
    { lat: 48.715657, lng: 20.8848866 },
    { lat: 48.7156318, lng: 20.8848119 },
    { lat: 48.71577, lng: 20.8846368 },
    { lat: 48.7160052, lng: 20.8845019 },
    { lat: 48.71732, lng: 20.8842098 },
    { lat: 48.7176092, lng: 20.884003 },
    { lat: 48.7176612, lng: 20.8838824 },
    { lat: 48.7182803, lng: 20.8835204 },
    { lat: 48.7194389, lng: 20.8830344 },
    { lat: 48.7199646, lng: 20.8829007 },
    { lat: 48.7200042, lng: 20.8829704 },
    { lat: 48.7200901, lng: 20.8829491 },
    { lat: 48.7201624, lng: 20.8828924 },
    { lat: 48.7201857, lng: 20.8826842 },
    { lat: 48.7203518, lng: 20.8827682 },
    { lat: 48.720806, lng: 20.8827164 },
    { lat: 48.720939, lng: 20.8827553 },
    { lat: 48.7210145, lng: 20.8825287 },
    { lat: 48.7210642, lng: 20.8825337 },
    { lat: 48.7210658, lng: 20.8826776 },
    { lat: 48.7214356, lng: 20.8827654 },
    { lat: 48.7217039, lng: 20.8829052 },
    { lat: 48.7220979, lng: 20.8829365 },
    { lat: 48.7225198, lng: 20.8827685 },
    { lat: 48.7226333, lng: 20.8827978 },
    { lat: 48.7228664, lng: 20.8827287 },
    { lat: 48.7229214, lng: 20.8825985 },
    { lat: 48.7232342, lng: 20.8825232 },
    { lat: 48.7237651, lng: 20.8825149 },
    { lat: 48.72425, lng: 20.8823402 },
    { lat: 48.7245321, lng: 20.8820301 },
    { lat: 48.7249017, lng: 20.8820084 },
    { lat: 48.7254056, lng: 20.881732 },
    { lat: 48.7258253, lng: 20.8815956 },
    { lat: 48.7264205, lng: 20.8815563 },
    { lat: 48.7267465, lng: 20.8813793 },
    { lat: 48.7278286, lng: 20.8806021 },
    { lat: 48.7282732, lng: 20.8800675 },
    { lat: 48.7286354, lng: 20.8799626 },
    { lat: 48.7287308, lng: 20.8797912 },
    { lat: 48.7289168, lng: 20.8796362 },
    { lat: 48.7292126, lng: 20.8796587 },
    { lat: 48.7294072, lng: 20.8797894 },
    { lat: 48.7294497, lng: 20.8798809 },
    { lat: 48.7296542, lng: 20.8797355 },
    { lat: 48.7300367, lng: 20.8797602 },
    { lat: 48.730468, lng: 20.8796666 },
    { lat: 48.7307714, lng: 20.879752 },
    { lat: 48.7314257, lng: 20.880192 },
    { lat: 48.7317377, lng: 20.8805214 },
    { lat: 48.7317357, lng: 20.8802352 },
    { lat: 48.7318812, lng: 20.8801087 },
    { lat: 48.7318383, lng: 20.8798752 },
    { lat: 48.732321, lng: 20.8797409 },
    { lat: 48.7327317, lng: 20.879404 },
    { lat: 48.7328314, lng: 20.8792614 },
    { lat: 48.7328939, lng: 20.8793735 },
    { lat: 48.73253, lng: 20.8806182 },
    { lat: 48.7325007, lng: 20.8808505 },
    { lat: 48.7325994, lng: 20.8809722 },
    { lat: 48.7329685, lng: 20.8811003 },
    { lat: 48.7331148, lng: 20.8810597 },
    { lat: 48.7336002, lng: 20.8806442 },
    { lat: 48.734036, lng: 20.8804825 },
    { lat: 48.7343438, lng: 20.880462 },
    { lat: 48.734851, lng: 20.8801529 },
    { lat: 48.7359393, lng: 20.8796536 },
    { lat: 48.7365983, lng: 20.8794381 },
    { lat: 48.7368159, lng: 20.879282 },
    { lat: 48.7368854, lng: 20.8791439 },
    { lat: 48.7374311, lng: 20.8788695 },
    { lat: 48.7375946, lng: 20.8788767 },
    { lat: 48.7378374, lng: 20.8786734 },
    { lat: 48.7384247, lng: 20.8785965 },
    { lat: 48.7386976, lng: 20.878643 },
    { lat: 48.7387313, lng: 20.8787734 },
    { lat: 48.7394304, lng: 20.8785458 },
    { lat: 48.740702, lng: 20.8780392 },
    { lat: 48.7411093, lng: 20.8777688 },
    { lat: 48.7415883, lng: 20.8775941 },
    { lat: 48.741731, lng: 20.877421 },
    { lat: 48.7419135, lng: 20.8773918 },
    { lat: 48.7421485, lng: 20.8771181 },
    { lat: 48.7422494, lng: 20.8771444 },
    { lat: 48.7428332, lng: 20.8762036 },
    { lat: 48.7432719, lng: 20.8753063 },
    { lat: 48.7432089, lng: 20.8751764 },
    { lat: 48.7432884, lng: 20.8749697 },
    { lat: 48.7437943, lng: 20.8743609 },
    { lat: 48.7437432, lng: 20.8741752 },
    { lat: 48.7437566, lng: 20.8740079 },
    { lat: 48.7434841, lng: 20.8737814 },
    { lat: 48.7436355, lng: 20.8734699 },
    { lat: 48.7437756, lng: 20.8736469 },
    { lat: 48.7440724, lng: 20.8738631 },
    { lat: 48.7442051, lng: 20.873827 },
    { lat: 48.7445868, lng: 20.8733017 },
    { lat: 48.7448461, lng: 20.8726912 },
    { lat: 48.7447894, lng: 20.872483 },
    { lat: 48.7456097, lng: 20.8713892 },
    { lat: 48.745371, lng: 20.8709916 },
    { lat: 48.7455997, lng: 20.870306 },
    { lat: 48.7465068, lng: 20.8695798 },
    { lat: 48.7467291, lng: 20.8692649 },
    { lat: 48.7473237, lng: 20.867659 },
    { lat: 48.7475812, lng: 20.8665077 },
    { lat: 48.7476454, lng: 20.8658813 },
    { lat: 48.7473338, lng: 20.8649998 },
    { lat: 48.7479102, lng: 20.8626714 },
    { lat: 48.7478195, lng: 20.8626539 },
    { lat: 48.7477901, lng: 20.8625214 },
    { lat: 48.7478814, lng: 20.8616346 },
    { lat: 48.7479822, lng: 20.8614569 },
    { lat: 48.7480012, lng: 20.8609921 },
    { lat: 48.7477274, lng: 20.8608231 },
    { lat: 48.7475762, lng: 20.8606456 },
    { lat: 48.7475953, lng: 20.8603487 },
    { lat: 48.747548, lng: 20.8602843 },
    { lat: 48.7478739, lng: 20.8586579 },
    { lat: 48.7479788, lng: 20.8584357 },
    { lat: 48.7479713, lng: 20.8579576 },
    { lat: 48.7479257, lng: 20.8577432 },
    { lat: 48.7480197, lng: 20.8575942 },
    { lat: 48.7481101, lng: 20.8570474 },
    { lat: 48.7480835, lng: 20.8562381 },
    { lat: 48.7482404, lng: 20.8558612 },
    { lat: 48.748374, lng: 20.8550959 },
    { lat: 48.7485123, lng: 20.8547954 },
    { lat: 48.7486641, lng: 20.8541693 },
    { lat: 48.7485394, lng: 20.8541149 },
    { lat: 48.74859, lng: 20.8537492 },
    { lat: 48.7484504, lng: 20.8526997 },
    { lat: 48.748183, lng: 20.8517016 },
    { lat: 48.7488899, lng: 20.8516385 },
    { lat: 48.7491831, lng: 20.8519804 },
    { lat: 48.7496868, lng: 20.8516676 },
    { lat: 48.7496604, lng: 20.8510928 },
    { lat: 48.7498312, lng: 20.850957 },
    { lat: 48.7499645, lng: 20.850744 },
    { lat: 48.7502229, lng: 20.8507896 },
    { lat: 48.7503071, lng: 20.8508832 },
    { lat: 48.7504998, lng: 20.8503954 },
    { lat: 48.750751, lng: 20.8492916 },
    { lat: 48.7504699, lng: 20.8488432 },
    { lat: 48.7506109, lng: 20.8486224 },
    { lat: 48.7506915, lng: 20.8483168 },
    { lat: 48.7508734, lng: 20.8481676 },
    { lat: 48.7509139, lng: 20.8479379 },
    { lat: 48.7508811, lng: 20.8476722 },
    { lat: 48.7509254, lng: 20.8471982 },
    { lat: 48.7510116, lng: 20.8476384 },
    { lat: 48.7512237, lng: 20.8474205 },
    { lat: 48.7512629, lng: 20.8472844 },
    { lat: 48.7513121, lng: 20.8473437 },
    { lat: 48.751746, lng: 20.8467024 },
    { lat: 48.7519189, lng: 20.8461686 },
    { lat: 48.7520404, lng: 20.8459354 },
    { lat: 48.7523762, lng: 20.8457202 },
    { lat: 48.7526316, lng: 20.8450273 },
    { lat: 48.7525683, lng: 20.844992 },
    { lat: 48.7526105, lng: 20.844841 },
    { lat: 48.7527759, lng: 20.8450338 },
    { lat: 48.7532301, lng: 20.8443998 },
    { lat: 48.7537725, lng: 20.8440667 },
    { lat: 48.7540046, lng: 20.8438126 },
    { lat: 48.7543404, lng: 20.8431661 },
    { lat: 48.754427, lng: 20.842549 },
  ],
  Mudrovce: [
    { lat: 48.8339863, lng: 21.4434107 },
    { lat: 48.8338599, lng: 21.4435953 },
    { lat: 48.833592, lng: 21.4438208 },
    { lat: 48.8331678, lng: 21.4440535 },
    { lat: 48.8320867, lng: 21.442407 },
    { lat: 48.8320867, lng: 21.442253 },
    { lat: 48.8319728, lng: 21.4420539 },
    { lat: 48.8318695, lng: 21.4416718 },
    { lat: 48.831893, lng: 21.4415874 },
    { lat: 48.8316798, lng: 21.4413042 },
    { lat: 48.8317497, lng: 21.4411138 },
    { lat: 48.8316368, lng: 21.4410585 },
    { lat: 48.831496, lng: 21.4407912 },
    { lat: 48.8314654, lng: 21.4405943 },
    { lat: 48.8313324, lng: 21.4403911 },
    { lat: 48.8312053, lng: 21.4403167 },
    { lat: 48.8310596, lng: 21.4399486 },
    { lat: 48.8301962, lng: 21.4403212 },
    { lat: 48.8301887, lng: 21.4402171 },
    { lat: 48.83029, lng: 21.4401028 },
    { lat: 48.8302137, lng: 21.4398043 },
    { lat: 48.8302418, lng: 21.4396864 },
    { lat: 48.8299521, lng: 21.4391733 },
    { lat: 48.8297963, lng: 21.4391505 },
    { lat: 48.8296464, lng: 21.4386867 },
    { lat: 48.8280589, lng: 21.4391886 },
    { lat: 48.8273014, lng: 21.4395608 },
    { lat: 48.82601, lng: 21.4400785 },
    { lat: 48.8253516, lng: 21.440228 },
    { lat: 48.8253217, lng: 21.4404732 },
    { lat: 48.8253547, lng: 21.440561 },
    { lat: 48.8241653, lng: 21.4411799 },
    { lat: 48.8241241, lng: 21.4410352 },
    { lat: 48.8235199, lng: 21.4412753 },
    { lat: 48.8222256, lng: 21.4419517 },
    { lat: 48.8210387, lng: 21.4428868 },
    { lat: 48.8201974, lng: 21.4436723 },
    { lat: 48.8196904, lng: 21.4425305 },
    { lat: 48.8195986, lng: 21.4424316 },
    { lat: 48.8192517, lng: 21.4426477 },
    { lat: 48.8165965, lng: 21.4433926 },
    { lat: 48.8166391, lng: 21.4436144 },
    { lat: 48.8164133, lng: 21.4439408 },
    { lat: 48.8164737, lng: 21.4445066 },
    { lat: 48.8167146, lng: 21.4448522 },
    { lat: 48.8168149, lng: 21.44521 },
    { lat: 48.8167416, lng: 21.4454698 },
    { lat: 48.8167833, lng: 21.4457427 },
    { lat: 48.816745, lng: 21.4461684 },
    { lat: 48.8167932, lng: 21.446274 },
    { lat: 48.8167126, lng: 21.4467529 },
    { lat: 48.8167679, lng: 21.447179 },
    { lat: 48.81682, lng: 21.4472645 },
    { lat: 48.8170649, lng: 21.4473684 },
    { lat: 48.8171903, lng: 21.447586 },
    { lat: 48.8171787, lng: 21.4478021 },
    { lat: 48.8174313, lng: 21.4478594 },
    { lat: 48.8176605, lng: 21.4481526 },
    { lat: 48.817686, lng: 21.4482584 },
    { lat: 48.8176251, lng: 21.4490226 },
    { lat: 48.8177788, lng: 21.4493929 },
    { lat: 48.817844, lng: 21.4498513 },
    { lat: 48.8177097, lng: 21.4501531 },
    { lat: 48.8177816, lng: 21.4508402 },
    { lat: 48.8180012, lng: 21.4510041 },
    { lat: 48.818211, lng: 21.4510597 },
    { lat: 48.8182493, lng: 21.4513306 },
    { lat: 48.8184444, lng: 21.451464 },
    { lat: 48.8185755, lng: 21.4514795 },
    { lat: 48.8187766, lng: 21.4512347 },
    { lat: 48.8189109, lng: 21.4512878 },
    { lat: 48.818967, lng: 21.4515144 },
    { lat: 48.8190896, lng: 21.451629 },
    { lat: 48.8191832, lng: 21.4518709 },
    { lat: 48.8193429, lng: 21.4519466 },
    { lat: 48.8193932, lng: 21.45232 },
    { lat: 48.8194638, lng: 21.4523495 },
    { lat: 48.8195774, lng: 21.4522262 },
    { lat: 48.819785, lng: 21.452327 },
    { lat: 48.8204891, lng: 21.4532422 },
    { lat: 48.8205503, lng: 21.4533461 },
    { lat: 48.8210113, lng: 21.453778 },
    { lat: 48.8211527, lng: 21.4539238 },
    { lat: 48.8214172, lng: 21.4541247 },
    { lat: 48.821499, lng: 21.454302 },
    { lat: 48.8216875, lng: 21.4543234 },
    { lat: 48.8220696, lng: 21.4546174 },
    { lat: 48.8222193, lng: 21.4546182 },
    { lat: 48.822397, lng: 21.4548502 },
    { lat: 48.8225159, lng: 21.4549169 },
    { lat: 48.8226281, lng: 21.4552865 },
    { lat: 48.8227789, lng: 21.4554464 },
    { lat: 48.8230172, lng: 21.4554347 },
    { lat: 48.8232609, lng: 21.4556091 },
    { lat: 48.8234478, lng: 21.4559216 },
    { lat: 48.8236971, lng: 21.4561442 },
    { lat: 48.8238182, lng: 21.4564725 },
    { lat: 48.823939, lng: 21.4563698 },
    { lat: 48.8243194, lng: 21.4566794 },
    { lat: 48.8244212, lng: 21.4568579 },
    { lat: 48.8246768, lng: 21.456938 },
    { lat: 48.8248896, lng: 21.4572923 },
    { lat: 48.8249066, lng: 21.4574947 },
    { lat: 48.8252027, lng: 21.4580013 },
    { lat: 48.8252103, lng: 21.4582637 },
    { lat: 48.8253072, lng: 21.4584597 },
    { lat: 48.8258409, lng: 21.4591268 },
    { lat: 48.8261976, lng: 21.4594755 },
    { lat: 48.8264542, lng: 21.4601333 },
    { lat: 48.8265563, lng: 21.4601244 },
    { lat: 48.8270432, lng: 21.4605405 },
    { lat: 48.8270866, lng: 21.4607172 },
    { lat: 48.8270563, lng: 21.4608778 },
    { lat: 48.8272488, lng: 21.4609942 },
    { lat: 48.8273016, lng: 21.4611867 },
    { lat: 48.8274095, lng: 21.4613403 },
    { lat: 48.8274591, lng: 21.4617626 },
    { lat: 48.8276302, lng: 21.4620062 },
    { lat: 48.8276835, lng: 21.4622476 },
    { lat: 48.8278047, lng: 21.4623556 },
    { lat: 48.82798, lng: 21.46227 },
    { lat: 48.8281459, lng: 21.4627104 },
    { lat: 48.8283205, lng: 21.4628309 },
    { lat: 48.8285117, lng: 21.4636084 },
    { lat: 48.8286487, lng: 21.4637882 },
    { lat: 48.8287286, lng: 21.4642568 },
    { lat: 48.8290422, lng: 21.4648655 },
    { lat: 48.8290642, lng: 21.4653887 },
    { lat: 48.8292157, lng: 21.465604 },
    { lat: 48.8291965, lng: 21.4658359 },
    { lat: 48.8292542, lng: 21.4662407 },
    { lat: 48.8294076, lng: 21.4664801 },
    { lat: 48.8293359, lng: 21.4671545 },
    { lat: 48.829389, lng: 21.4675081 },
    { lat: 48.8292333, lng: 21.4677634 },
    { lat: 48.8292635, lng: 21.4680321 },
    { lat: 48.8292298, lng: 21.4683411 },
    { lat: 48.8294269, lng: 21.468628 },
    { lat: 48.8293678, lng: 21.469068 },
    { lat: 48.8295104, lng: 21.4693685 },
    { lat: 48.8298335, lng: 21.4695666 },
    { lat: 48.8297878, lng: 21.4702822 },
    { lat: 48.8296223, lng: 21.4706664 },
    { lat: 48.8296035, lng: 21.4710262 },
    { lat: 48.8294521, lng: 21.4712784 },
    { lat: 48.8294245, lng: 21.471417 },
    { lat: 48.8295939, lng: 21.4718253 },
    { lat: 48.8296543, lng: 21.4721213 },
    { lat: 48.8295935, lng: 21.4723164 },
    { lat: 48.8296021, lng: 21.472883 },
    { lat: 48.829759, lng: 21.4730965 },
    { lat: 48.8296879, lng: 21.4736726 },
    { lat: 48.8297149, lng: 21.4741315 },
    { lat: 48.8297834, lng: 21.4743241 },
    { lat: 48.8297187, lng: 21.4750862 },
    { lat: 48.8298629, lng: 21.4754023 },
    { lat: 48.8297394, lng: 21.4759566 },
    { lat: 48.8299613, lng: 21.4768536 },
    { lat: 48.8299931, lng: 21.4771394 },
    { lat: 48.8298405, lng: 21.4775031 },
    { lat: 48.829931, lng: 21.4777567 },
    { lat: 48.8299664, lng: 21.478124 },
    { lat: 48.8298845, lng: 21.4786033 },
    { lat: 48.8296815, lng: 21.4789368 },
    { lat: 48.8295805, lng: 21.4792192 },
    { lat: 48.8293506, lng: 21.4793183 },
    { lat: 48.8291025, lng: 21.4796494 },
    { lat: 48.8290613, lng: 21.4795868 },
    { lat: 48.8285388, lng: 21.4803953 },
    { lat: 48.8287457, lng: 21.4811237 },
    { lat: 48.828825, lng: 21.4812652 },
    { lat: 48.8287954, lng: 21.4816386 },
    { lat: 48.8287119, lng: 21.4817905 },
    { lat: 48.8287326, lng: 21.4821556 },
    { lat: 48.8288231, lng: 21.4824403 },
    { lat: 48.828826, lng: 21.4828201 },
    { lat: 48.8286638, lng: 21.483204 },
    { lat: 48.8286806, lng: 21.4842092 },
    { lat: 48.8286339, lng: 21.4845041 },
    { lat: 48.8287397, lng: 21.485482 },
    { lat: 48.8286253, lng: 21.4861181 },
    { lat: 48.8285367, lng: 21.4863561 },
    { lat: 48.8285994, lng: 21.4866605 },
    { lat: 48.8285275, lng: 21.4868967 },
    { lat: 48.8286309, lng: 21.4872866 },
    { lat: 48.8287541, lng: 21.4873795 },
    { lat: 48.8288291, lng: 21.4876828 },
    { lat: 48.8291401, lng: 21.488084 },
    { lat: 48.829392, lng: 21.4882416 },
    { lat: 48.8298563, lng: 21.4889623 },
    { lat: 48.8302587, lng: 21.4894203 },
    { lat: 48.8305109, lng: 21.4895072 },
    { lat: 48.8312789, lng: 21.4901135 },
    { lat: 48.8319412, lng: 21.4908751 },
    { lat: 48.8322055, lng: 21.4910316 },
    { lat: 48.8334508, lng: 21.4914107 },
    { lat: 48.8338488, lng: 21.4917894 },
    { lat: 48.8344165, lng: 21.4922052 },
    { lat: 48.8352336, lng: 21.4924462 },
    { lat: 48.8356853, lng: 21.492811 },
    { lat: 48.8360041, lng: 21.4932975 },
    { lat: 48.8364606, lng: 21.4934778 },
    { lat: 48.8374036, lng: 21.4944656 },
    { lat: 48.8380189, lng: 21.4955112 },
    { lat: 48.8385535, lng: 21.4958334 },
    { lat: 48.8395896, lng: 21.4973556 },
    { lat: 48.8403908, lng: 21.4981765 },
    { lat: 48.8412555, lng: 21.4993679 },
    { lat: 48.8416805, lng: 21.4999078 },
    { lat: 48.841965, lng: 21.500153 },
    { lat: 48.842429, lng: 21.499443 },
    { lat: 48.843711, lng: 21.497715 },
    { lat: 48.84412, lng: 21.497303 },
    { lat: 48.844675, lng: 21.496509 },
    { lat: 48.844791, lng: 21.496368 },
    { lat: 48.844895, lng: 21.496286 },
    { lat: 48.845588, lng: 21.495825 },
    { lat: 48.84618, lng: 21.495513 },
    { lat: 48.846566, lng: 21.495138 },
    { lat: 48.8454936, lng: 21.4904723 },
    { lat: 48.8443148, lng: 21.4841983 },
    { lat: 48.8442381, lng: 21.4840015 },
    { lat: 48.8442114, lng: 21.4834289 },
    { lat: 48.8442821, lng: 21.4828701 },
    { lat: 48.843954, lng: 21.4824564 },
    { lat: 48.8436124, lng: 21.4822808 },
    { lat: 48.8434098, lng: 21.482082 },
    { lat: 48.8433043, lng: 21.4817004 },
    { lat: 48.8430812, lng: 21.4814219 },
    { lat: 48.8430022, lng: 21.4811785 },
    { lat: 48.842603, lng: 21.4807191 },
    { lat: 48.8422998, lng: 21.480158 },
    { lat: 48.8420403, lng: 21.4798566 },
    { lat: 48.841807, lng: 21.4794534 },
    { lat: 48.84103, lng: 21.4786519 },
    { lat: 48.8407529, lng: 21.4781745 },
    { lat: 48.8406676, lng: 21.4771743 },
    { lat: 48.8404982, lng: 21.4766072 },
    { lat: 48.8402585, lng: 21.4763345 },
    { lat: 48.8400665, lng: 21.4758768 },
    { lat: 48.8396828, lng: 21.4754151 },
    { lat: 48.839618, lng: 21.4746921 },
    { lat: 48.8395094, lng: 21.4743596 },
    { lat: 48.8394083, lng: 21.4736223 },
    { lat: 48.8395146, lng: 21.4730994 },
    { lat: 48.8394442, lng: 21.4726144 },
    { lat: 48.8393486, lng: 21.4724539 },
    { lat: 48.8392456, lng: 21.4724722 },
    { lat: 48.8389449, lng: 21.4723482 },
    { lat: 48.8389388, lng: 21.4720229 },
    { lat: 48.8388255, lng: 21.4718804 },
    { lat: 48.8386907, lng: 21.4708328 },
    { lat: 48.8385635, lng: 21.4705283 },
    { lat: 48.8384871, lng: 21.470487 },
    { lat: 48.8384853, lng: 21.4700413 },
    { lat: 48.8384185, lng: 21.4696336 },
    { lat: 48.83818, lng: 21.469563 },
    { lat: 48.8379997, lng: 21.4691256 },
    { lat: 48.8378365, lng: 21.4688897 },
    { lat: 48.83778, lng: 21.4686491 },
    { lat: 48.837852, lng: 21.467636 },
    { lat: 48.8376652, lng: 21.467293 },
    { lat: 48.8375726, lng: 21.4665369 },
    { lat: 48.83741, lng: 21.4661359 },
    { lat: 48.8373476, lng: 21.4657215 },
    { lat: 48.8372114, lng: 21.4653759 },
    { lat: 48.8372523, lng: 21.4649263 },
    { lat: 48.8373727, lng: 21.464769 },
    { lat: 48.8373286, lng: 21.4644912 },
    { lat: 48.8374012, lng: 21.4640038 },
    { lat: 48.8376288, lng: 21.4637064 },
    { lat: 48.8378635, lng: 21.4635383 },
    { lat: 48.8379565, lng: 21.4631359 },
    { lat: 48.8381355, lng: 21.4629404 },
    { lat: 48.8381215, lng: 21.4625406 },
    { lat: 48.8383423, lng: 21.4623573 },
    { lat: 48.8383145, lng: 21.4607418 },
    { lat: 48.8384111, lng: 21.4605785 },
    { lat: 48.8380942, lng: 21.4588501 },
    { lat: 48.837058, lng: 21.4556035 },
    { lat: 48.8365033, lng: 21.45414 },
    { lat: 48.8360464, lng: 21.4517782 },
    { lat: 48.83492, lng: 21.4482239 },
    { lat: 48.8345813, lng: 21.4467172 },
    { lat: 48.8343848, lng: 21.4454649 },
    { lat: 48.8339887, lng: 21.443422 },
    { lat: 48.8339863, lng: 21.4434107 },
  ],
  Rákoš: [
    { lat: 48.6591215, lng: 21.4302697 },
    { lat: 48.6580818, lng: 21.4304216 },
    { lat: 48.6570218, lng: 21.4302357 },
    { lat: 48.655703, lng: 21.43027 },
    { lat: 48.6553983, lng: 21.4304364 },
    { lat: 48.6548855, lng: 21.4301398 },
    { lat: 48.6537673, lng: 21.4299557 },
    { lat: 48.6537381, lng: 21.4296544 },
    { lat: 48.6537801, lng: 21.4293971 },
    { lat: 48.653705, lng: 21.4293295 },
    { lat: 48.6537124, lng: 21.4287493 },
    { lat: 48.6535659, lng: 21.4283537 },
    { lat: 48.6532841, lng: 21.4279196 },
    { lat: 48.6533473, lng: 21.4276182 },
    { lat: 48.6530571, lng: 21.427243 },
    { lat: 48.6530516, lng: 21.4269369 },
    { lat: 48.6531607, lng: 21.4268598 },
    { lat: 48.6531924, lng: 21.4263694 },
    { lat: 48.6532981, lng: 21.4261385 },
    { lat: 48.6531905, lng: 21.4259613 },
    { lat: 48.653283, lng: 21.4257387 },
    { lat: 48.6532354, lng: 21.4254262 },
    { lat: 48.6533465, lng: 21.4253415 },
    { lat: 48.6532494, lng: 21.4250929 },
    { lat: 48.6532526, lng: 21.4249784 },
    { lat: 48.6533601, lng: 21.424841 },
    { lat: 48.6533008, lng: 21.4247809 },
    { lat: 48.6533442, lng: 21.424616 },
    { lat: 48.6532514, lng: 21.4244408 },
    { lat: 48.6530924, lng: 21.4236226 },
    { lat: 48.6530021, lng: 21.4237354 },
    { lat: 48.65285, lng: 21.4236072 },
    { lat: 48.6526994, lng: 21.4237692 },
    { lat: 48.6525373, lng: 21.4238061 },
    { lat: 48.6523874, lng: 21.4234194 },
    { lat: 48.6525913, lng: 21.4227031 },
    { lat: 48.6528452, lng: 21.4223028 },
    { lat: 48.6529282, lng: 21.4218391 },
    { lat: 48.6531341, lng: 21.4215299 },
    { lat: 48.6533443, lng: 21.4214503 },
    { lat: 48.6532219, lng: 21.4213781 },
    { lat: 48.6531147, lng: 21.4211419 },
    { lat: 48.652921, lng: 21.42102 },
    { lat: 48.6529017, lng: 21.4208291 },
    { lat: 48.6525799, lng: 21.4203667 },
    { lat: 48.6525985, lng: 21.4199349 },
    { lat: 48.6525448, lng: 21.4193687 },
    { lat: 48.652631, lng: 21.4190867 },
    { lat: 48.6526623, lng: 21.4186212 },
    { lat: 48.6524301, lng: 21.4179222 },
    { lat: 48.6523838, lng: 21.41792 },
    { lat: 48.6523011, lng: 21.4177131 },
    { lat: 48.6521157, lng: 21.4177199 },
    { lat: 48.6520431, lng: 21.4176248 },
    { lat: 48.6521489, lng: 21.4173977 },
    { lat: 48.6521043, lng: 21.4173345 },
    { lat: 48.6519568, lng: 21.4174818 },
    { lat: 48.651647, lng: 21.4172428 },
    { lat: 48.6516454, lng: 21.4170369 },
    { lat: 48.6513509, lng: 21.4167837 },
    { lat: 48.6512241, lng: 21.4165307 },
    { lat: 48.6511035, lng: 21.4165577 },
    { lat: 48.6508602, lng: 21.4164716 },
    { lat: 48.6505517, lng: 21.4163197 },
    { lat: 48.6504139, lng: 21.4161069 },
    { lat: 48.6502707, lng: 21.4161362 },
    { lat: 48.6502032, lng: 21.4160243 },
    { lat: 48.6499713, lng: 21.4159097 },
    { lat: 48.6492852, lng: 21.4151943 },
    { lat: 48.648672, lng: 21.414742 },
    { lat: 48.6480514, lng: 21.4144877 },
    { lat: 48.6469183, lng: 21.4141918 },
    { lat: 48.646287, lng: 21.4137696 },
    { lat: 48.6461498, lng: 21.4137365 },
    { lat: 48.6460789, lng: 21.4133573 },
    { lat: 48.6458957, lng: 21.4132138 },
    { lat: 48.6453694, lng: 21.4130304 },
    { lat: 48.6450305, lng: 21.4129888 },
    { lat: 48.6448035, lng: 21.4128504 },
    { lat: 48.6442862, lng: 21.4128194 },
    { lat: 48.6440763, lng: 21.4126844 },
    { lat: 48.6441119, lng: 21.4125536 },
    { lat: 48.6443264, lng: 21.4123918 },
    { lat: 48.6443567, lng: 21.412199 },
    { lat: 48.6440733, lng: 21.4116716 },
    { lat: 48.6440858, lng: 21.4109174 },
    { lat: 48.6439037, lng: 21.4105415 },
    { lat: 48.643869, lng: 21.410357 },
    { lat: 48.6439985, lng: 21.4100953 },
    { lat: 48.6442174, lng: 21.4098506 },
    { lat: 48.6442822, lng: 21.4095712 },
    { lat: 48.6442699, lng: 21.4094973 },
    { lat: 48.6442078, lng: 21.4094866 },
    { lat: 48.644006, lng: 21.4096614 },
    { lat: 48.6439415, lng: 21.4095799 },
    { lat: 48.6438149, lng: 21.4089519 },
    { lat: 48.6437189, lng: 21.4087114 },
    { lat: 48.6435454, lng: 21.4084737 },
    { lat: 48.6434939, lng: 21.4081751 },
    { lat: 48.6436131, lng: 21.4079087 },
    { lat: 48.6436083, lng: 21.4078289 },
    { lat: 48.6434503, lng: 21.4078837 },
    { lat: 48.6433251, lng: 21.4076851 },
    { lat: 48.6433249, lng: 21.4072253 },
    { lat: 48.6432337, lng: 21.4071867 },
    { lat: 48.6432055, lng: 21.4069907 },
    { lat: 48.643231, lng: 21.4063695 },
    { lat: 48.6430978, lng: 21.4062056 },
    { lat: 48.6431025, lng: 21.4059818 },
    { lat: 48.642967, lng: 21.4054358 },
    { lat: 48.6429494, lng: 21.4054854 },
    { lat: 48.642903, lng: 21.4055924 },
    { lat: 48.6428463, lng: 21.4058105 },
    { lat: 48.6427414, lng: 21.4058364 },
    { lat: 48.6426698, lng: 21.4059654 },
    { lat: 48.6425532, lng: 21.4060164 },
    { lat: 48.6425149, lng: 21.4061666 },
    { lat: 48.6424356, lng: 21.4062261 },
    { lat: 48.6423597, lng: 21.4061739 },
    { lat: 48.6422833, lng: 21.4062926 },
    { lat: 48.6420514, lng: 21.406399 },
    { lat: 48.6418259, lng: 21.4062715 },
    { lat: 48.6416716, lng: 21.4062659 },
    { lat: 48.6416276, lng: 21.4063735 },
    { lat: 48.6414569, lng: 21.4065031 },
    { lat: 48.6413234, lng: 21.4064783 },
    { lat: 48.6412165, lng: 21.4065539 },
    { lat: 48.6411466, lng: 21.4067401 },
    { lat: 48.6410782, lng: 21.4067077 },
    { lat: 48.6408244, lng: 21.4068027 },
    { lat: 48.6403128, lng: 21.4073859 },
    { lat: 48.6401697, lng: 21.4073393 },
    { lat: 48.6401064, lng: 21.407408 },
    { lat: 48.6400599, lng: 21.4073176 },
    { lat: 48.6397891, lng: 21.4076347 },
    { lat: 48.63966, lng: 21.4075741 },
    { lat: 48.639614, lng: 21.407654 },
    { lat: 48.6395742, lng: 21.4075976 },
    { lat: 48.6391922, lng: 21.4079967 },
    { lat: 48.6391142, lng: 21.4079525 },
    { lat: 48.638891, lng: 21.4084735 },
    { lat: 48.6387396, lng: 21.4085595 },
    { lat: 48.6386453, lng: 21.4085356 },
    { lat: 48.6386124, lng: 21.4084136 },
    { lat: 48.6381114, lng: 21.4085513 },
    { lat: 48.6380461, lng: 21.4087429 },
    { lat: 48.6379063, lng: 21.4088068 },
    { lat: 48.6379143, lng: 21.4089663 },
    { lat: 48.6377904, lng: 21.4091138 },
    { lat: 48.6374753, lng: 21.409261 },
    { lat: 48.6370952, lng: 21.4091589 },
    { lat: 48.6370096, lng: 21.4092718 },
    { lat: 48.6369372, lng: 21.4092542 },
    { lat: 48.6366851, lng: 21.4097868 },
    { lat: 48.6365693, lng: 21.4098266 },
    { lat: 48.6364707, lng: 21.4103744 },
    { lat: 48.6365089, lng: 21.4106369 },
    { lat: 48.636322, lng: 21.4107929 },
    { lat: 48.6362639, lng: 21.4110284 },
    { lat: 48.6361365, lng: 21.4110256 },
    { lat: 48.6360624, lng: 21.4111448 },
    { lat: 48.6357947, lng: 21.4112995 },
    { lat: 48.6354831, lng: 21.4112643 },
    { lat: 48.6353291, lng: 21.4113828 },
    { lat: 48.6352424, lng: 21.411267 },
    { lat: 48.6351471, lng: 21.4113154 },
    { lat: 48.6351415, lng: 21.4113168 },
    { lat: 48.6348663, lng: 21.4113731 },
    { lat: 48.6345437, lng: 21.4115375 },
    { lat: 48.634512, lng: 21.4114951 },
    { lat: 48.6342669, lng: 21.4116924 },
    { lat: 48.634223, lng: 21.4118807 },
    { lat: 48.634051, lng: 21.4118468 },
    { lat: 48.6339241, lng: 21.4116308 },
    { lat: 48.6335588, lng: 21.4119468 },
    { lat: 48.6331586, lng: 21.4119994 },
    { lat: 48.6330179, lng: 21.4121882 },
    { lat: 48.633002, lng: 21.4123337 },
    { lat: 48.6326717, lng: 21.412431 },
    { lat: 48.6324241, lng: 21.412617 },
    { lat: 48.6322435, lng: 21.4126399 },
    { lat: 48.6319627, lng: 21.4128477 },
    { lat: 48.6315453, lng: 21.4128491 },
    { lat: 48.6310796, lng: 21.4130086 },
    { lat: 48.6306498, lng: 21.4130101 },
    { lat: 48.6306428, lng: 21.4130757 },
    { lat: 48.6303031, lng: 21.4131587 },
    { lat: 48.6302222, lng: 21.4134303 },
    { lat: 48.6299475, lng: 21.4136533 },
    { lat: 48.6299158, lng: 21.4137931 },
    { lat: 48.6297797, lng: 21.4138724 },
    { lat: 48.6297003, lng: 21.4140424 },
    { lat: 48.6291795, lng: 21.4145083 },
    { lat: 48.6289221, lng: 21.4146323 },
    { lat: 48.6286432, lng: 21.4153579 },
    { lat: 48.6285603, lng: 21.4157918 },
    { lat: 48.6284709, lng: 21.4160077 },
    { lat: 48.6284573, lng: 21.416232 },
    { lat: 48.6280429, lng: 21.416907 },
    { lat: 48.6273827, lng: 21.4183216 },
    { lat: 48.6247622, lng: 21.4203596 },
    { lat: 48.623818, lng: 21.421611 },
    { lat: 48.6222996, lng: 21.4223604 },
    { lat: 48.6209283, lng: 21.423363 },
    { lat: 48.6204529, lng: 21.4244915 },
    { lat: 48.6198912, lng: 21.4260486 },
    { lat: 48.6193414, lng: 21.4295816 },
    { lat: 48.6193727, lng: 21.4306458 },
    { lat: 48.618914, lng: 21.4328357 },
    { lat: 48.6183291, lng: 21.4366341 },
    { lat: 48.618555, lng: 21.4368192 },
    { lat: 48.6196186, lng: 21.4390069 },
    { lat: 48.6294766, lng: 21.4446549 },
    { lat: 48.6307388, lng: 21.4459161 },
    { lat: 48.632331, lng: 21.4472151 },
    { lat: 48.6328216, lng: 21.4465982 },
    { lat: 48.6342207, lng: 21.4444205 },
    { lat: 48.6346534, lng: 21.4447524 },
    { lat: 48.6348792, lng: 21.4446597 },
    { lat: 48.6351938, lng: 21.4446846 },
    { lat: 48.635394, lng: 21.444604 },
    { lat: 48.6354705, lng: 21.4447608 },
    { lat: 48.6355189, lng: 21.4447537 },
    { lat: 48.6355406, lng: 21.4448853 },
    { lat: 48.6354857, lng: 21.4450056 },
    { lat: 48.6357977, lng: 21.4459824 },
    { lat: 48.6393296, lng: 21.4513854 },
    { lat: 48.6396908, lng: 21.4516096 },
    { lat: 48.6398343, lng: 21.4515599 },
    { lat: 48.6400261, lng: 21.4513852 },
    { lat: 48.6402831, lng: 21.4513194 },
    { lat: 48.6407012, lng: 21.4514584 },
    { lat: 48.6408912, lng: 21.4516097 },
    { lat: 48.6416166, lng: 21.4514746 },
    { lat: 48.641994, lng: 21.451648 },
    { lat: 48.6424834, lng: 21.4521018 },
    { lat: 48.642676, lng: 21.4524569 },
    { lat: 48.6427899, lng: 21.4525041 },
    { lat: 48.642981, lng: 21.4524611 },
    { lat: 48.6434119, lng: 21.4528729 },
    { lat: 48.6436551, lng: 21.4536107 },
    { lat: 48.6459475, lng: 21.4578228 },
    { lat: 48.6464028, lng: 21.4573442 },
    { lat: 48.6470482, lng: 21.4570547 },
    { lat: 48.6489575, lng: 21.4558117 },
    { lat: 48.649521, lng: 21.45565 },
    { lat: 48.6496485, lng: 21.4555109 },
    { lat: 48.6503694, lng: 21.4554566 },
    { lat: 48.6506507, lng: 21.4555667 },
    { lat: 48.6520235, lng: 21.4558013 },
    { lat: 48.6529681, lng: 21.4563569 },
    { lat: 48.6531204, lng: 21.4564052 },
    { lat: 48.6563078, lng: 21.4508103 },
    { lat: 48.6599602, lng: 21.4473819 },
    { lat: 48.659069, lng: 21.4444227 },
    { lat: 48.660228, lng: 21.438741 },
    { lat: 48.6595614, lng: 21.4336341 },
    { lat: 48.6591215, lng: 21.4302697 },
  ],
  TurňanadBodvou: [
    { lat: 48.6642236, lng: 20.8958524 },
    { lat: 48.6639257, lng: 20.8962618 },
    { lat: 48.6635248, lng: 20.8967213 },
    { lat: 48.6632218, lng: 20.896982 },
    { lat: 48.6623041, lng: 20.8973662 },
    { lat: 48.6619211, lng: 20.8979225 },
    { lat: 48.6614955, lng: 20.8980841 },
    { lat: 48.6608565, lng: 20.8986599 },
    { lat: 48.660451, lng: 20.8986173 },
    { lat: 48.659836, lng: 20.8991371 },
    { lat: 48.6588976, lng: 20.9002709 },
    { lat: 48.6575391, lng: 20.9031534 },
    { lat: 48.6571621, lng: 20.9031591 },
    { lat: 48.6568985, lng: 20.9028836 },
    { lat: 48.6567099, lng: 20.9029983 },
    { lat: 48.6562184, lng: 20.9027166 },
    { lat: 48.6558107, lng: 20.9011892 },
    { lat: 48.6559388, lng: 20.8995241 },
    { lat: 48.6557084, lng: 20.8986458 },
    { lat: 48.6556094, lng: 20.8952438 },
    { lat: 48.6555016, lng: 20.8946889 },
    { lat: 48.6544169, lng: 20.893218 },
    { lat: 48.6542495, lng: 20.893194 },
    { lat: 48.6539501, lng: 20.8935241 },
    { lat: 48.653453, lng: 20.8937973 },
    { lat: 48.6526241, lng: 20.8941162 },
    { lat: 48.6517983, lng: 20.8943302 },
    { lat: 48.6513577, lng: 20.8942839 },
    { lat: 48.6510756, lng: 20.8940332 },
    { lat: 48.650581, lng: 20.8941808 },
    { lat: 48.650326, lng: 20.8941316 },
    { lat: 48.6498664, lng: 20.8938928 },
    { lat: 48.648749, lng: 20.89361 },
    { lat: 48.6483734, lng: 20.8932892 },
    { lat: 48.6479349, lng: 20.8931015 },
    { lat: 48.6472117, lng: 20.8931598 },
    { lat: 48.6469852, lng: 20.8927837 },
    { lat: 48.6464913, lng: 20.8925622 },
    { lat: 48.6463349, lng: 20.892396 },
    { lat: 48.6461235, lng: 20.8923736 },
    { lat: 48.6460383, lng: 20.8921172 },
    { lat: 48.6456028, lng: 20.8920898 },
    { lat: 48.6451818, lng: 20.8924208 },
    { lat: 48.6447718, lng: 20.892213 },
    { lat: 48.6445105, lng: 20.8919813 },
    { lat: 48.6444106, lng: 20.8917545 },
    { lat: 48.64425, lng: 20.889785 },
    { lat: 48.6441398, lng: 20.8893633 },
    { lat: 48.6440532, lng: 20.8894309 },
    { lat: 48.6441029, lng: 20.8887802 },
    { lat: 48.6440078, lng: 20.8886004 },
    { lat: 48.6438631, lng: 20.8884803 },
    { lat: 48.6438363, lng: 20.8882553 },
    { lat: 48.6439282, lng: 20.8877815 },
    { lat: 48.6439421, lng: 20.887401 },
    { lat: 48.6437377, lng: 20.8870516 },
    { lat: 48.6435628, lng: 20.8871674 },
    { lat: 48.641728, lng: 20.8873983 },
    { lat: 48.6320398, lng: 20.8919311 },
    { lat: 48.6250948, lng: 20.8925706 },
    { lat: 48.6251309, lng: 20.894021 },
    { lat: 48.6249831, lng: 20.8941765 },
    { lat: 48.6251199, lng: 20.8943563 },
    { lat: 48.6251726, lng: 20.89485 },
    { lat: 48.6252946, lng: 20.8953554 },
    { lat: 48.6251461, lng: 20.8963493 },
    { lat: 48.6248285, lng: 20.8967404 },
    { lat: 48.6244237, lng: 20.8966216 },
    { lat: 48.6237872, lng: 20.8962451 },
    { lat: 48.6229286, lng: 20.8961213 },
    { lat: 48.6227118, lng: 20.8960036 },
    { lat: 48.6205059, lng: 20.8942261 },
    { lat: 48.6202301, lng: 20.8938999 },
    { lat: 48.6194155, lng: 20.8921988 },
    { lat: 48.6192381, lng: 20.8919148 },
    { lat: 48.6165866, lng: 20.885178 },
    { lat: 48.6146666, lng: 20.8807812 },
    { lat: 48.6136921, lng: 20.8783224 },
    { lat: 48.6135181, lng: 20.8781122 },
    { lat: 48.6130287, lng: 20.8797593 },
    { lat: 48.611931, lng: 20.879493 },
    { lat: 48.6108941, lng: 20.8753798 },
    { lat: 48.6109359, lng: 20.8752833 },
    { lat: 48.6108783, lng: 20.8750224 },
    { lat: 48.6111908, lng: 20.8745808 },
    { lat: 48.6113689, lng: 20.8735677 },
    { lat: 48.6123951, lng: 20.871132 },
    { lat: 48.6129027, lng: 20.8687328 },
    { lat: 48.6149737, lng: 20.8628001 },
    { lat: 48.6152628, lng: 20.8617405 },
    { lat: 48.6186577, lng: 20.8512822 },
    { lat: 48.6176714, lng: 20.8449122 },
    { lat: 48.6169545, lng: 20.8454719 },
    { lat: 48.6143241, lng: 20.847506 },
    { lat: 48.6140123, lng: 20.8475355 },
    { lat: 48.6137085, lng: 20.847708 },
    { lat: 48.6119394, lng: 20.8476903 },
    { lat: 48.6116556, lng: 20.8473256 },
    { lat: 48.6115682, lng: 20.8470429 },
    { lat: 48.6112745, lng: 20.8470082 },
    { lat: 48.6106662, lng: 20.8469362 },
    { lat: 48.6094537, lng: 20.8469001 },
    { lat: 48.6094266, lng: 20.8471957 },
    { lat: 48.6084576, lng: 20.8472291 },
    { lat: 48.6075116, lng: 20.8473503 },
    { lat: 48.6072325, lng: 20.8470296 },
    { lat: 48.6070506, lng: 20.8469123 },
    { lat: 48.6069771, lng: 20.8467701 },
    { lat: 48.6067582, lng: 20.846655 },
    { lat: 48.6017782, lng: 20.8482918 },
    { lat: 48.6017051, lng: 20.8482932 },
    { lat: 48.601767, lng: 20.846923 },
    { lat: 48.6012528, lng: 20.8468961 },
    { lat: 48.6007585, lng: 20.8470903 },
    { lat: 48.5990063, lng: 20.8473662 },
    { lat: 48.5970036, lng: 20.8475649 },
    { lat: 48.5952212, lng: 20.8479747 },
    { lat: 48.5940486, lng: 20.8480834 },
    { lat: 48.5935845, lng: 20.8483336 },
    { lat: 48.5923227, lng: 20.8486335 },
    { lat: 48.5906489, lng: 20.8492515 },
    { lat: 48.5893685, lng: 20.8496012 },
    { lat: 48.5886346, lng: 20.8498657 },
    { lat: 48.5883656, lng: 20.850028 },
    { lat: 48.588036, lng: 20.8501 },
    { lat: 48.5877954, lng: 20.8500393 },
    { lat: 48.587702, lng: 20.8500732 },
    { lat: 48.5875329, lng: 20.850787 },
    { lat: 48.5875274, lng: 20.8509901 },
    { lat: 48.5875814, lng: 20.851213 },
    { lat: 48.5873554, lng: 20.8517111 },
    { lat: 48.5872263, lng: 20.8520451 },
    { lat: 48.586978, lng: 20.8524159 },
    { lat: 48.5868418, lng: 20.8529425 },
    { lat: 48.586951, lng: 20.8530701 },
    { lat: 48.5869713, lng: 20.8532011 },
    { lat: 48.5868458, lng: 20.8539733 },
    { lat: 48.5869535, lng: 20.8550031 },
    { lat: 48.5870547, lng: 20.8550993 },
    { lat: 48.5872037, lng: 20.8554742 },
    { lat: 48.5872367, lng: 20.8556782 },
    { lat: 48.5875802, lng: 20.8560769 },
    { lat: 48.5877405, lng: 20.8560512 },
    { lat: 48.5880903, lng: 20.8567968 },
    { lat: 48.5885348, lng: 20.857088 },
    { lat: 48.5887186, lng: 20.8574232 },
    { lat: 48.5888189, lng: 20.857815 },
    { lat: 48.5885811, lng: 20.8582336 },
    { lat: 48.5885482, lng: 20.858388 },
    { lat: 48.5886448, lng: 20.858453 },
    { lat: 48.5893875, lng: 20.8605322 },
    { lat: 48.5903056, lng: 20.8628752 },
    { lat: 48.5905769, lng: 20.8639854 },
    { lat: 48.591103, lng: 20.8669462 },
    { lat: 48.5905171, lng: 20.8674197 },
    { lat: 48.5901778, lng: 20.8678373 },
    { lat: 48.5898658, lng: 20.8683545 },
    { lat: 48.5897061, lng: 20.8689426 },
    { lat: 48.5896358, lng: 20.8698843 },
    { lat: 48.5896637, lng: 20.8701321 },
    { lat: 48.5897499, lng: 20.8703224 },
    { lat: 48.589602, lng: 20.8705341 },
    { lat: 48.5893179, lng: 20.8707863 },
    { lat: 48.5890077, lng: 20.871032 },
    { lat: 48.5887672, lng: 20.8711397 },
    { lat: 48.5887091, lng: 20.8712555 },
    { lat: 48.5886989, lng: 20.8715123 },
    { lat: 48.5881774, lng: 20.8719612 },
    { lat: 48.587842, lng: 20.8721633 },
    { lat: 48.5877529, lng: 20.8724181 },
    { lat: 48.5877504, lng: 20.8725722 },
    { lat: 48.5876742, lng: 20.8726806 },
    { lat: 48.5875542, lng: 20.8726963 },
    { lat: 48.5874607, lng: 20.8729227 },
    { lat: 48.5873568, lng: 20.8730417 },
    { lat: 48.5872787, lng: 20.873035 },
    { lat: 48.5872261, lng: 20.8732062 },
    { lat: 48.5869796, lng: 20.8733381 },
    { lat: 48.5868239, lng: 20.8737566 },
    { lat: 48.5865535, lng: 20.8737461 },
    { lat: 48.586395, lng: 20.8735558 },
    { lat: 48.5863139, lng: 20.8735834 },
    { lat: 48.5860774, lng: 20.8739105 },
    { lat: 48.5853425, lng: 20.8737818 },
    { lat: 48.5853037, lng: 20.8735398 },
    { lat: 48.5852444, lng: 20.8733855 },
    { lat: 48.5851977, lng: 20.8733728 },
    { lat: 48.5851976, lng: 20.8732711 },
    { lat: 48.5851441, lng: 20.8732536 },
    { lat: 48.5852516, lng: 20.8730028 },
    { lat: 48.5850953, lng: 20.8729435 },
    { lat: 48.585023, lng: 20.8731063 },
    { lat: 48.584937, lng: 20.8729587 },
    { lat: 48.5850564, lng: 20.8728281 },
    { lat: 48.5850707, lng: 20.8727235 },
    { lat: 48.5849844, lng: 20.8726346 },
    { lat: 48.5847718, lng: 20.8726754 },
    { lat: 48.584744, lng: 20.8726132 },
    { lat: 48.5846921, lng: 20.8726816 },
    { lat: 48.5847334, lng: 20.8728345 },
    { lat: 48.5846202, lng: 20.8729087 },
    { lat: 48.5847455, lng: 20.8730202 },
    { lat: 48.5846945, lng: 20.8730743 },
    { lat: 48.5845575, lng: 20.8729264 },
    { lat: 48.5842691, lng: 20.873314 },
    { lat: 48.5839653, lng: 20.8749838 },
    { lat: 48.5821574, lng: 20.875408 },
    { lat: 48.5808278, lng: 20.8756399 },
    { lat: 48.5806247, lng: 20.8756251 },
    { lat: 48.5806024, lng: 20.875661 },
    { lat: 48.5768086, lng: 20.8812706 },
    { lat: 48.577213, lng: 20.8815747 },
    { lat: 48.5775462, lng: 20.8815737 },
    { lat: 48.5776906, lng: 20.8816837 },
    { lat: 48.57794, lng: 20.8817339 },
    { lat: 48.5781957, lng: 20.8820354 },
    { lat: 48.5786749, lng: 20.8824194 },
    { lat: 48.5799532, lng: 20.8837214 },
    { lat: 48.5811955, lng: 20.8850885 },
    { lat: 48.5814031, lng: 20.8854609 },
    { lat: 48.5827418, lng: 20.8872134 },
    { lat: 48.5840768, lng: 20.8894807 },
    { lat: 48.5849635, lng: 20.8906278 },
    { lat: 48.5863934, lng: 20.8923518 },
    { lat: 48.5864551, lng: 20.8923407 },
    { lat: 48.5867574, lng: 20.8925931 },
    { lat: 48.5923385, lng: 20.8991912 },
    { lat: 48.5915812, lng: 20.9021713 },
    { lat: 48.5918698, lng: 20.9023484 },
    { lat: 48.5919597, lng: 20.902446 },
    { lat: 48.5920708, lng: 20.9027283 },
    { lat: 48.5921633, lng: 20.9027364 },
    { lat: 48.5921538, lng: 20.9028175 },
    { lat: 48.5922832, lng: 20.9031485 },
    { lat: 48.5924255, lng: 20.9033917 },
    { lat: 48.5924231, lng: 20.9035569 },
    { lat: 48.5925664, lng: 20.9036283 },
    { lat: 48.592718, lng: 20.9040163 },
    { lat: 48.592744, lng: 20.9042184 },
    { lat: 48.5928338, lng: 20.9042441 },
    { lat: 48.5930861, lng: 20.9047459 },
    { lat: 48.5930189, lng: 20.905016 },
    { lat: 48.5931137, lng: 20.9050933 },
    { lat: 48.5931526, lng: 20.9056397 },
    { lat: 48.5932972, lng: 20.9058547 },
    { lat: 48.5932824, lng: 20.9061122 },
    { lat: 48.5933252, lng: 20.9063802 },
    { lat: 48.5937547, lng: 20.9071553 },
    { lat: 48.5937265, lng: 20.9072204 },
    { lat: 48.593888, lng: 20.9075358 },
    { lat: 48.5940137, lng: 20.9076541 },
    { lat: 48.5941864, lng: 20.9080717 },
    { lat: 48.5942963, lng: 20.9081884 },
    { lat: 48.5942829, lng: 20.9083228 },
    { lat: 48.5945821, lng: 20.9088807 },
    { lat: 48.5946726, lng: 20.9091532 },
    { lat: 48.5950382, lng: 20.9096808 },
    { lat: 48.5950986, lng: 20.9097387 },
    { lat: 48.5954013, lng: 20.9096975 },
    { lat: 48.595592, lng: 20.9097429 },
    { lat: 48.5964042, lng: 20.9095639 },
    { lat: 48.5971561, lng: 20.9092985 },
    { lat: 48.5977813, lng: 20.9088842 },
    { lat: 48.598265, lng: 20.9088746 },
    { lat: 48.5984422, lng: 20.9087517 },
    { lat: 48.5985226, lng: 20.9088323 },
    { lat: 48.5992108, lng: 20.9085462 },
    { lat: 48.5994161, lng: 20.9083621 },
    { lat: 48.599727, lng: 20.9082698 },
    { lat: 48.6000225, lng: 20.9082836 },
    { lat: 48.6001654, lng: 20.9081833 },
    { lat: 48.6007831, lng: 20.9082736 },
    { lat: 48.6009889, lng: 20.9081726 },
    { lat: 48.6018798, lng: 20.9080195 },
    { lat: 48.6021391, lng: 20.9078949 },
    { lat: 48.6026196, lng: 20.9080264 },
    { lat: 48.6027541, lng: 20.9080089 },
    { lat: 48.6028164, lng: 20.907918 },
    { lat: 48.6030304, lng: 20.9078929 },
    { lat: 48.6032482, lng: 20.9079707 },
    { lat: 48.6036358, lng: 20.9079507 },
    { lat: 48.6037137, lng: 20.9078284 },
    { lat: 48.6039803, lng: 20.9078874 },
    { lat: 48.6043217, lng: 20.9078326 },
    { lat: 48.6044389, lng: 20.9076987 },
    { lat: 48.6047202, lng: 20.9076961 },
    { lat: 48.60478, lng: 20.9077687 },
    { lat: 48.6051885, lng: 20.9077969 },
    { lat: 48.6052479, lng: 20.9078929 },
    { lat: 48.6061192, lng: 20.9082869 },
    { lat: 48.6070723, lng: 20.9090047 },
    { lat: 48.6071802, lng: 20.9092361 },
    { lat: 48.6074507, lng: 20.9095399 },
    { lat: 48.6081727, lng: 20.9097545 },
    { lat: 48.6082709, lng: 20.9098955 },
    { lat: 48.6088133, lng: 20.9098231 },
    { lat: 48.6090446, lng: 20.9098418 },
    { lat: 48.6092223, lng: 20.910002 },
    { lat: 48.6093309, lng: 20.91002 },
    { lat: 48.6095794, lng: 20.9099392 },
    { lat: 48.6099362, lng: 20.9102435 },
    { lat: 48.6102272, lng: 20.910358 },
    { lat: 48.6104316, lng: 20.9099118 },
    { lat: 48.6105133, lng: 20.9098649 },
    { lat: 48.6106564, lng: 20.9100712 },
    { lat: 48.6108868, lng: 20.910026 },
    { lat: 48.6109027, lng: 20.9097666 },
    { lat: 48.6109707, lng: 20.9096425 },
    { lat: 48.6110648, lng: 20.9095933 },
    { lat: 48.61123, lng: 20.9096861 },
    { lat: 48.6114011, lng: 20.9095221 },
    { lat: 48.611632, lng: 20.9095651 },
    { lat: 48.6117638, lng: 20.9091327 },
    { lat: 48.6119539, lng: 20.9091603 },
    { lat: 48.6122211, lng: 20.9087131 },
    { lat: 48.6127301, lng: 20.908614 },
    { lat: 48.6127289, lng: 20.9082193 },
    { lat: 48.612834, lng: 20.9080301 },
    { lat: 48.6129536, lng: 20.9082016 },
    { lat: 48.6131287, lng: 20.9080585 },
    { lat: 48.6132019, lng: 20.9077277 },
    { lat: 48.6133694, lng: 20.9079247 },
    { lat: 48.6137679, lng: 20.9076545 },
    { lat: 48.6137664, lng: 20.9073134 },
    { lat: 48.6138656, lng: 20.9071172 },
    { lat: 48.614145, lng: 20.907028 },
    { lat: 48.6143631, lng: 20.9065202 },
    { lat: 48.6146421, lng: 20.9063873 },
    { lat: 48.6147855, lng: 20.9056025 },
    { lat: 48.6150534, lng: 20.9052068 },
    { lat: 48.6152362, lng: 20.9051507 },
    { lat: 48.6168833, lng: 20.9072144 },
    { lat: 48.619772, lng: 20.9099664 },
    { lat: 48.6217555, lng: 20.9113626 },
    { lat: 48.6268006, lng: 20.9160337 },
    { lat: 48.6279031, lng: 20.9164511 },
    { lat: 48.6317005, lng: 20.9151713 },
    { lat: 48.6331414, lng: 20.9146134 },
    { lat: 48.6349437, lng: 20.9157096 },
    { lat: 48.6362642, lng: 20.9153155 },
    { lat: 48.638971, lng: 20.9138089 },
    { lat: 48.640491, lng: 20.9140517 },
    { lat: 48.6420146, lng: 20.9135746 },
    { lat: 48.6428162, lng: 20.9137785 },
    { lat: 48.6435206, lng: 20.9140366 },
    { lat: 48.6439229, lng: 20.9143459 },
    { lat: 48.6447676, lng: 20.9147234 },
    { lat: 48.6463844, lng: 20.9145982 },
    { lat: 48.6472391, lng: 20.9149089 },
    { lat: 48.6476937, lng: 20.9153703 },
    { lat: 48.6491605, lng: 20.9164023 },
    { lat: 48.6495822, lng: 20.9161223 },
    { lat: 48.6500743, lng: 20.9160957 },
    { lat: 48.6502669, lng: 20.9161684 },
    { lat: 48.6506254, lng: 20.9165149 },
    { lat: 48.6507658, lng: 20.9163371 },
    { lat: 48.6510017, lng: 20.9162249 },
    { lat: 48.6513966, lng: 20.9164216 },
    { lat: 48.651833, lng: 20.916311 },
    { lat: 48.6533368, lng: 20.9162081 },
    { lat: 48.6536701, lng: 20.9156807 },
    { lat: 48.6540045, lng: 20.9154132 },
    { lat: 48.6543725, lng: 20.9153178 },
    { lat: 48.6549352, lng: 20.9153769 },
    { lat: 48.6563219, lng: 20.915675 },
    { lat: 48.6575653, lng: 20.9161237 },
    { lat: 48.6584322, lng: 20.9160956 },
    { lat: 48.6590075, lng: 20.9163688 },
    { lat: 48.6595655, lng: 20.9169207 },
    { lat: 48.6597513, lng: 20.9173222 },
    { lat: 48.660323, lng: 20.9181775 },
    { lat: 48.6606405, lng: 20.9187483 },
    { lat: 48.6616949, lng: 20.9200998 },
    { lat: 48.6620332, lng: 20.9206526 },
    { lat: 48.662402, lng: 20.9211028 },
    { lat: 48.6626027, lng: 20.921213 },
    { lat: 48.6628524, lng: 20.9215722 },
    { lat: 48.6634641, lng: 20.9219513 },
    { lat: 48.6639267, lng: 20.9219167 },
    { lat: 48.6643268, lng: 20.922003 },
    { lat: 48.6649194, lng: 20.9223102 },
    { lat: 48.6654008, lng: 20.9227691 },
    { lat: 48.6657604, lng: 20.9232139 },
    { lat: 48.6664896, lng: 20.923147 },
    { lat: 48.6670131, lng: 20.9229468 },
    { lat: 48.6678486, lng: 20.9224533 },
    { lat: 48.6682058, lng: 20.9223011 },
    { lat: 48.6681972, lng: 20.9222779 },
    { lat: 48.6682261, lng: 20.9222686 },
    { lat: 48.6681651, lng: 20.9166769 },
    { lat: 48.6684843, lng: 20.9159671 },
    { lat: 48.6684454, lng: 20.9123944 },
    { lat: 48.6680043, lng: 20.9112739 },
    { lat: 48.6663241, lng: 20.9091255 },
    { lat: 48.6660788, lng: 20.9060503 },
    { lat: 48.666019, lng: 20.9041858 },
    { lat: 48.6653429, lng: 20.9003634 },
    { lat: 48.6642324, lng: 20.8958834 },
    { lat: 48.6642236, lng: 20.8958524 },
  ],
  Sokoľ: [
    { lat: 48.8292282, lng: 21.1905833 },
    { lat: 48.8294279, lng: 21.1883454 },
    { lat: 48.8298108, lng: 21.1824095 },
    { lat: 48.8298794, lng: 21.1820881 },
    { lat: 48.8298332, lng: 21.1818721 },
    { lat: 48.8300205, lng: 21.17932 },
    { lat: 48.8301109, lng: 21.1787219 },
    { lat: 48.8302366, lng: 21.1766032 },
    { lat: 48.8303782, lng: 21.1755235 },
    { lat: 48.830703, lng: 21.1714797 },
    { lat: 48.8306403, lng: 21.1713103 },
    { lat: 48.830987, lng: 21.1709216 },
    { lat: 48.8312626, lng: 21.169776 },
    { lat: 48.8316589, lng: 21.17046 },
    { lat: 48.8325515, lng: 21.1706396 },
    { lat: 48.8327495, lng: 21.1709261 },
    { lat: 48.8336514, lng: 21.1715145 },
    { lat: 48.8338708, lng: 21.1712624 },
    { lat: 48.8340647, lng: 21.1708744 },
    { lat: 48.83408, lng: 21.17047 },
    { lat: 48.8341835, lng: 21.1702524 },
    { lat: 48.8345643, lng: 21.1680943 },
    { lat: 48.835857, lng: 21.1664773 },
    { lat: 48.8362509, lng: 21.1657329 },
    { lat: 48.8362596, lng: 21.1657254 },
    { lat: 48.8361992, lng: 21.1655916 },
    { lat: 48.836267, lng: 21.1648179 },
    { lat: 48.8360645, lng: 21.1639634 },
    { lat: 48.8362917, lng: 21.1635696 },
    { lat: 48.8363409, lng: 21.163062 },
    { lat: 48.8362732, lng: 21.1626279 },
    { lat: 48.8361572, lng: 21.1623744 },
    { lat: 48.836075, lng: 21.1618806 },
    { lat: 48.8360533, lng: 21.1611566 },
    { lat: 48.8362128, lng: 21.160332 },
    { lat: 48.8362557, lng: 21.1594064 },
    { lat: 48.8363851, lng: 21.1586325 },
    { lat: 48.836657, lng: 21.1577229 },
    { lat: 48.8367568, lng: 21.1568221 },
    { lat: 48.8364747, lng: 21.1560553 },
    { lat: 48.8357495, lng: 21.1557565 },
    { lat: 48.8354278, lng: 21.1563675 },
    { lat: 48.8346427, lng: 21.1569162 },
    { lat: 48.8335717, lng: 21.1571362 },
    { lat: 48.8328518, lng: 21.1574383 },
    { lat: 48.8322189, lng: 21.1576036 },
    { lat: 48.8319516, lng: 21.1574734 },
    { lat: 48.8309925, lng: 21.1582107 },
    { lat: 48.8307036, lng: 21.1581499 },
    { lat: 48.8304568, lng: 21.1578189 },
    { lat: 48.830011, lng: 21.1577784 },
    { lat: 48.8294287, lng: 21.1579136 },
    { lat: 48.8291733, lng: 21.1577714 },
    { lat: 48.8284031, lng: 21.1577199 },
    { lat: 48.8280963, lng: 21.1575204 },
    { lat: 48.8274833, lng: 21.1573895 },
    { lat: 48.8268687, lng: 21.1569588 },
    { lat: 48.8263583, lng: 21.1562723 },
    { lat: 48.8261138, lng: 21.1561151 },
    { lat: 48.8259462, lng: 21.1556572 },
    { lat: 48.8255395, lng: 21.1550246 },
    { lat: 48.824995, lng: 21.1540545 },
    { lat: 48.824195, lng: 21.153923 },
    { lat: 48.824125, lng: 21.15431 },
    { lat: 48.823578, lng: 21.155522 },
    { lat: 48.823291, lng: 21.156553 },
    { lat: 48.82331, lng: 21.157303 },
    { lat: 48.823071, lng: 21.159144 },
    { lat: 48.822815, lng: 21.159866 },
    { lat: 48.822602, lng: 21.160042 },
    { lat: 48.822229, lng: 21.160436 },
    { lat: 48.821964, lng: 21.160848 },
    { lat: 48.821731, lng: 21.161114 },
    { lat: 48.821649, lng: 21.161369 },
    { lat: 48.821449, lng: 21.161728 },
    { lat: 48.821131, lng: 21.162022 },
    { lat: 48.820562, lng: 21.162379 },
    { lat: 48.820274, lng: 21.162539 },
    { lat: 48.820159, lng: 21.162647 },
    { lat: 48.819844, lng: 21.162891 },
    { lat: 48.819278, lng: 21.163336 },
    { lat: 48.818733, lng: 21.163617 },
    { lat: 48.818585, lng: 21.163735 },
    { lat: 48.818177, lng: 21.163734 },
    { lat: 48.818045, lng: 21.163844 },
    { lat: 48.817938, lng: 21.164184 },
    { lat: 48.817615, lng: 21.164408 },
    { lat: 48.817429, lng: 21.163907 },
    { lat: 48.816905, lng: 21.164106 },
    { lat: 48.816395, lng: 21.164492 },
    { lat: 48.815701, lng: 21.164845 },
    { lat: 48.815233, lng: 21.165059 },
    { lat: 48.814471, lng: 21.165374 },
    { lat: 48.814279, lng: 21.165657 },
    { lat: 48.814227, lng: 21.16592 },
    { lat: 48.814165, lng: 21.166398 },
    { lat: 48.813918, lng: 21.167101 },
    { lat: 48.813685, lng: 21.168025 },
    { lat: 48.81352, lng: 21.168571 },
    { lat: 48.81335, lng: 21.168787 },
    { lat: 48.813132, lng: 21.168795 },
    { lat: 48.812902, lng: 21.168887 },
    { lat: 48.812719, lng: 21.168998 },
    { lat: 48.8126568, lng: 21.1691486 },
    { lat: 48.812482, lng: 21.169572 },
    { lat: 48.812214, lng: 21.170894 },
    { lat: 48.811578, lng: 21.171703 },
    { lat: 48.811237, lng: 21.172115 },
    { lat: 48.810919, lng: 21.172473 },
    { lat: 48.810007, lng: 21.172581 },
    { lat: 48.809683, lng: 21.17252 },
    { lat: 48.808783, lng: 21.172575 },
    { lat: 48.807693, lng: 21.17188 },
    { lat: 48.807587, lng: 21.1719649 },
    { lat: 48.807452, lng: 21.172073 },
    { lat: 48.807399, lng: 21.17218 },
    { lat: 48.807074, lng: 21.173438 },
    { lat: 48.806754, lng: 21.173942 },
    { lat: 48.806437, lng: 21.174205 },
    { lat: 48.806118, lng: 21.174469 },
    { lat: 48.805977, lng: 21.174668 },
    { lat: 48.805418, lng: 21.175409 },
    { lat: 48.805282, lng: 21.175549 },
    { lat: 48.804857, lng: 21.175991 },
    { lat: 48.804425, lng: 21.176837 },
    { lat: 48.804145, lng: 21.177123 },
    { lat: 48.804008, lng: 21.177462 },
    { lat: 48.803961, lng: 21.178213 },
    { lat: 48.80375, lng: 21.179134 },
    { lat: 48.803211, lng: 21.179952 },
    { lat: 48.803077, lng: 21.180012 },
    { lat: 48.802936, lng: 21.18027 },
    { lat: 48.802926, lng: 21.181024 },
    { lat: 48.802275, lng: 21.181335 },
    { lat: 48.80211, lng: 21.181335 },
    { lat: 48.801885, lng: 21.181751 },
    { lat: 48.801257, lng: 21.182977 },
    { lat: 48.800681, lng: 21.184092 },
    { lat: 48.800463, lng: 21.184774 },
    { lat: 48.800344, lng: 21.185017 },
    { lat: 48.799999, lng: 21.186049 },
    { lat: 48.799773, lng: 21.186681 },
    { lat: 48.799415, lng: 21.18761 },
    { lat: 48.799109, lng: 21.188261 },
    { lat: 48.798919, lng: 21.188609 },
    { lat: 48.798799, lng: 21.188752 },
    { lat: 48.798796, lng: 21.188928 },
    { lat: 48.798769, lng: 21.189037 },
    { lat: 48.798603, lng: 21.189243 },
    { lat: 48.79853, lng: 21.189519 },
    { lat: 48.7985, lng: 21.189714 },
    { lat: 48.79827, lng: 21.191391 },
    { lat: 48.79822, lng: 21.192527 },
    { lat: 48.798155, lng: 21.192904 },
    { lat: 48.798142, lng: 21.193079 },
    { lat: 48.798067, lng: 21.193275 },
    { lat: 48.798033, lng: 21.193526 },
    { lat: 48.798029, lng: 21.193766 },
    { lat: 48.797962, lng: 21.193919 },
    { lat: 48.79792, lng: 21.194198 },
    { lat: 48.79796, lng: 21.194417 },
    { lat: 48.79805, lng: 21.194547 },
    { lat: 48.798188, lng: 21.195112 },
    { lat: 48.798178, lng: 21.195233 },
    { lat: 48.798199, lng: 21.195404 },
    { lat: 48.798256, lng: 21.195498 },
    { lat: 48.798355, lng: 21.195925 },
    { lat: 48.79836, lng: 21.196103 },
    { lat: 48.798462, lng: 21.19625 },
    { lat: 48.798629, lng: 21.197141 },
    { lat: 48.798693, lng: 21.197682 },
    { lat: 48.798726, lng: 21.198268 },
    { lat: 48.798765, lng: 21.198582 },
    { lat: 48.798731, lng: 21.198677 },
    { lat: 48.798696, lng: 21.198939 },
    { lat: 48.798701, lng: 21.199134 },
    { lat: 48.798782, lng: 21.199306 },
    { lat: 48.798789, lng: 21.199452 },
    { lat: 48.798854, lng: 21.199571 },
    { lat: 48.798845, lng: 21.199932 },
    { lat: 48.798902, lng: 21.200882 },
    { lat: 48.798883, lng: 21.201113 },
    { lat: 48.798895, lng: 21.201374 },
    { lat: 48.798795, lng: 21.201639 },
    { lat: 48.798804, lng: 21.201817 },
    { lat: 48.798781, lng: 21.201943 },
    { lat: 48.798787, lng: 21.202067 },
    { lat: 48.798755, lng: 21.202188 },
    { lat: 48.798786, lng: 21.202314 },
    { lat: 48.798768, lng: 21.20242 },
    { lat: 48.798765, lng: 21.202515 },
    { lat: 48.798493, lng: 21.202598 },
    { lat: 48.798453, lng: 21.202689 },
    { lat: 48.798475, lng: 21.202812 },
    { lat: 48.7986677, lng: 21.2039969 },
    { lat: 48.7985614, lng: 21.2044422 },
    { lat: 48.7986514, lng: 21.2049437 },
    { lat: 48.7988934, lng: 21.2056332 },
    { lat: 48.7990532, lng: 21.2057424 },
    { lat: 48.7994349, lng: 21.206359 },
    { lat: 48.7996711, lng: 21.2073958 },
    { lat: 48.7996305, lng: 21.2077175 },
    { lat: 48.7998089, lng: 21.2084915 },
    { lat: 48.7997049, lng: 21.2090555 },
    { lat: 48.7995506, lng: 21.2093414 },
    { lat: 48.799465, lng: 21.209772 },
    { lat: 48.7993741, lng: 21.209776 },
    { lat: 48.7992706, lng: 21.2100322 },
    { lat: 48.7992508, lng: 21.2102607 },
    { lat: 48.799079, lng: 21.2106324 },
    { lat: 48.7991437, lng: 21.2115195 },
    { lat: 48.7990833, lng: 21.2122517 },
    { lat: 48.799121, lng: 21.2124825 },
    { lat: 48.7994005, lng: 21.2131414 },
    { lat: 48.7997129, lng: 21.213526 },
    { lat: 48.7999952, lng: 21.213775 },
    { lat: 48.8001991, lng: 21.213855 },
    { lat: 48.800559, lng: 21.2142416 },
    { lat: 48.80177, lng: 21.2159342 },
    { lat: 48.8021676, lng: 21.21599 },
    { lat: 48.8023195, lng: 21.2162019 },
    { lat: 48.8025689, lng: 21.2164018 },
    { lat: 48.8027161, lng: 21.2166451 },
    { lat: 48.8029018, lng: 21.2171188 },
    { lat: 48.8031646, lng: 21.2190874 },
    { lat: 48.8030801, lng: 21.2205289 },
    { lat: 48.8032849, lng: 21.2211769 },
    { lat: 48.8032571, lng: 21.2212624 },
    { lat: 48.8033573, lng: 21.2214798 },
    { lat: 48.8034114, lng: 21.2219161 },
    { lat: 48.8034669, lng: 21.2220296 },
    { lat: 48.8034274, lng: 21.2222091 },
    { lat: 48.8033377, lng: 21.2222605 },
    { lat: 48.8033493, lng: 21.222632 },
    { lat: 48.8036548, lng: 21.2231504 },
    { lat: 48.8039254, lng: 21.224251 },
    { lat: 48.8039612, lng: 21.2246079 },
    { lat: 48.8039538, lng: 21.2252393 },
    { lat: 48.8038903, lng: 21.2254419 },
    { lat: 48.8038066, lng: 21.2256118 },
    { lat: 48.8035687, lng: 21.2256255 },
    { lat: 48.8033201, lng: 21.2258517 },
    { lat: 48.803462, lng: 21.2264342 },
    { lat: 48.8032676, lng: 21.2265523 },
    { lat: 48.803185, lng: 21.2267856 },
    { lat: 48.8032297, lng: 21.227032 },
    { lat: 48.8029509, lng: 21.2274781 },
    { lat: 48.8029945, lng: 21.227646 },
    { lat: 48.8027812, lng: 21.2277711 },
    { lat: 48.8027439, lng: 21.2279509 },
    { lat: 48.8031337, lng: 21.2282911 },
    { lat: 48.8029216, lng: 21.2285979 },
    { lat: 48.8027531, lng: 21.2286778 },
    { lat: 48.8026041, lng: 21.2288847 },
    { lat: 48.8024106, lng: 21.2291762 },
    { lat: 48.8022548, lng: 21.2295879 },
    { lat: 48.8019469, lng: 21.2300089 },
    { lat: 48.801896, lng: 21.2310489 },
    { lat: 48.8016521, lng: 21.2318516 },
    { lat: 48.801613, lng: 21.2329157 },
    { lat: 48.8013742, lng: 21.2350835 },
    { lat: 48.8014008, lng: 21.2351048 },
    { lat: 48.8012494, lng: 21.2354476 },
    { lat: 48.8014331, lng: 21.2354923 },
    { lat: 48.8014761, lng: 21.2355028 },
    { lat: 48.8015181, lng: 21.235513 },
    { lat: 48.8020884, lng: 21.2347089 },
    { lat: 48.8034836, lng: 21.2332852 },
    { lat: 48.8042272, lng: 21.2327325 },
    { lat: 48.8048862, lng: 21.2323186 },
    { lat: 48.8061046, lng: 21.2319056 },
    { lat: 48.8075482, lng: 21.2312498 },
    { lat: 48.8077386, lng: 21.2315093 },
    { lat: 48.8083359, lng: 21.2326568 },
    { lat: 48.8081234, lng: 21.2338244 },
    { lat: 48.8090411, lng: 21.2340262 },
    { lat: 48.8090921, lng: 21.2343228 },
    { lat: 48.8103542, lng: 21.2347432 },
    { lat: 48.8107531, lng: 21.235992 },
    { lat: 48.8110849, lng: 21.235917 },
    { lat: 48.8113683, lng: 21.2356562 },
    { lat: 48.8117777, lng: 21.2358252 },
    { lat: 48.8120419, lng: 21.23582 },
    { lat: 48.8124149, lng: 21.2356178 },
    { lat: 48.8127198, lng: 21.2355415 },
    { lat: 48.8138075, lng: 21.2346882 },
    { lat: 48.8141952, lng: 21.2342757 },
    { lat: 48.8146981, lng: 21.2339404 },
    { lat: 48.8149854, lng: 21.2352575 },
    { lat: 48.81516, lng: 21.2356622 },
    { lat: 48.8154518, lng: 21.2359992 },
    { lat: 48.8155814, lng: 21.2363692 },
    { lat: 48.8155722, lng: 21.2366205 },
    { lat: 48.8156981, lng: 21.2369012 },
    { lat: 48.8157539, lng: 21.2371793 },
    { lat: 48.8159362, lng: 21.2373249 },
    { lat: 48.8161198, lng: 21.2373654 },
    { lat: 48.8162993, lng: 21.2375269 },
    { lat: 48.8163995, lng: 21.23822 },
    { lat: 48.8164769, lng: 21.2383206 },
    { lat: 48.8166827, lng: 21.2384995 },
    { lat: 48.8169556, lng: 21.2385844 },
    { lat: 48.8177875, lng: 21.2380507 },
    { lat: 48.8179806, lng: 21.2377459 },
    { lat: 48.8181891, lng: 21.2375415 },
    { lat: 48.8182706, lng: 21.2375924 },
    { lat: 48.8187591, lng: 21.2375192 },
    { lat: 48.8196158, lng: 21.2367662 },
    { lat: 48.819824, lng: 21.2364432 },
    { lat: 48.8199276, lng: 21.2360451 },
    { lat: 48.821029, lng: 21.2345133 },
    { lat: 48.8217666, lng: 21.2336209 },
    { lat: 48.8224508, lng: 21.2329637 },
    { lat: 48.8229377, lng: 21.2324076 },
    { lat: 48.8232277, lng: 21.2318594 },
    { lat: 48.8230819, lng: 21.2315063 },
    { lat: 48.8233309, lng: 21.2312246 },
    { lat: 48.8235844, lng: 21.2313855 },
    { lat: 48.8237184, lng: 21.231022 },
    { lat: 48.8240516, lng: 21.2303372 },
    { lat: 48.8242057, lng: 21.2296127 },
    { lat: 48.8243331, lng: 21.229365 },
    { lat: 48.8226188, lng: 21.2268183 },
    { lat: 48.8235786, lng: 21.2248235 },
    { lat: 48.824624, lng: 21.2222939 },
    { lat: 48.8255981, lng: 21.2204282 },
    { lat: 48.8258788, lng: 21.2201206 },
    { lat: 48.8254763, lng: 21.2194083 },
    { lat: 48.8253712, lng: 21.2190719 },
    { lat: 48.8253456, lng: 21.2186133 },
    { lat: 48.825186, lng: 21.2180379 },
    { lat: 48.8252385, lng: 21.2172738 },
    { lat: 48.8253764, lng: 21.2168532 },
    { lat: 48.8251904, lng: 21.2162279 },
    { lat: 48.8251856, lng: 21.2160454 },
    { lat: 48.8254976, lng: 21.2150763 },
    { lat: 48.8258289, lng: 21.2144408 },
    { lat: 48.8258266, lng: 21.2142902 },
    { lat: 48.8261342, lng: 21.2138042 },
    { lat: 48.8263313, lng: 21.2132655 },
    { lat: 48.8267593, lng: 21.2128402 },
    { lat: 48.8268164, lng: 21.2125493 },
    { lat: 48.8276138, lng: 21.2117353 },
    { lat: 48.8290571, lng: 21.193421 },
    { lat: 48.8292282, lng: 21.1905833 },
  ],
  Debraď: [
    { lat: 48.6548725, lng: 21.0342209 },
    { lat: 48.655246, lng: 21.0341816 },
    { lat: 48.6576771, lng: 21.035649 },
    { lat: 48.6581512, lng: 21.0357655 },
    { lat: 48.6583967, lng: 21.0360078 },
    { lat: 48.6584655, lng: 21.0360147 },
    { lat: 48.6585828, lng: 21.0356076 },
    { lat: 48.6588987, lng: 21.0352874 },
    { lat: 48.6596856, lng: 21.0346727 },
    { lat: 48.6599209, lng: 21.0344027 },
    { lat: 48.6603878, lng: 21.0336749 },
    { lat: 48.6610694, lng: 21.0329769 },
    { lat: 48.6612906, lng: 21.0328279 },
    { lat: 48.6615541, lng: 21.0324834 },
    { lat: 48.6620629, lng: 21.0320045 },
    { lat: 48.6624267, lng: 21.0315703 },
    { lat: 48.6639896, lng: 21.0300827 },
    { lat: 48.6646292, lng: 21.0295786 },
    { lat: 48.6653767, lng: 21.0288631 },
    { lat: 48.6661544, lng: 21.0284488 },
    { lat: 48.6667423, lng: 21.0278435 },
    { lat: 48.667671, lng: 21.0267237 },
    { lat: 48.668187, lng: 21.0260187 },
    { lat: 48.6685975, lng: 21.0253274 },
    { lat: 48.6687551, lng: 21.024848 },
    { lat: 48.6691509, lng: 21.0242197 },
    { lat: 48.6699627, lng: 21.0231032 },
    { lat: 48.670893, lng: 21.0213447 },
    { lat: 48.6716207, lng: 21.0197216 },
    { lat: 48.6716783, lng: 21.0192529 },
    { lat: 48.6716384, lng: 21.0192374 },
    { lat: 48.6714839, lng: 21.018785 },
    { lat: 48.6707629, lng: 21.0180138 },
    { lat: 48.6705062, lng: 21.0174708 },
    { lat: 48.668477, lng: 21.0148181 },
    { lat: 48.6674302, lng: 21.0136523 },
    { lat: 48.668546, lng: 21.0117932 },
    { lat: 48.6685386, lng: 21.0115719 },
    { lat: 48.6686798, lng: 21.0106984 },
    { lat: 48.6686023, lng: 21.0101929 },
    { lat: 48.6678644, lng: 21.0083014 },
    { lat: 48.6677304, lng: 21.0078219 },
    { lat: 48.6674082, lng: 21.0074075 },
    { lat: 48.6664278, lng: 21.0066906 },
    { lat: 48.6659686, lng: 21.0064574 },
    { lat: 48.6656271, lng: 21.0061371 },
    { lat: 48.6652184, lng: 21.0059251 },
    { lat: 48.6642291, lng: 21.0056586 },
    { lat: 48.6632035, lng: 21.0055875 },
    { lat: 48.6629709, lng: 21.0054911 },
    { lat: 48.6621262, lng: 21.004628 },
    { lat: 48.6615961, lng: 21.0042557 },
    { lat: 48.6611714, lng: 21.0038719 },
    { lat: 48.6605798, lng: 21.0035801 },
    { lat: 48.6602876, lng: 21.0035227 },
    { lat: 48.6597221, lng: 21.003648 },
    { lat: 48.6590634, lng: 21.0040851 },
    { lat: 48.6583723, lng: 21.0041821 },
    { lat: 48.6579167, lng: 21.0040228 },
    { lat: 48.6576964, lng: 21.0038668 },
    { lat: 48.6571105, lng: 21.0037088 },
    { lat: 48.6563958, lng: 21.0033743 },
    { lat: 48.65591, lng: 21.00304 },
    { lat: 48.65436, lng: 21.0038925 },
    { lat: 48.6541875, lng: 21.0035132 },
    { lat: 48.6541638, lng: 21.0033373 },
    { lat: 48.65372, lng: 21.0021736 },
    { lat: 48.6540648, lng: 21.0020152 },
    { lat: 48.6542088, lng: 21.0020135 },
    { lat: 48.6539216, lng: 21.0011609 },
    { lat: 48.6537809, lng: 21.000922 },
    { lat: 48.6536247, lng: 21.0007917 },
    { lat: 48.6540903, lng: 21.0005088 },
    { lat: 48.6544224, lng: 21.0001394 },
    { lat: 48.6548155, lng: 20.9994665 },
    { lat: 48.6549116, lng: 20.9991078 },
    { lat: 48.6551651, lng: 20.9986017 },
    { lat: 48.6552588, lng: 20.9982752 },
    { lat: 48.6558325, lng: 20.9973119 },
    { lat: 48.6560826, lng: 20.9971517 },
    { lat: 48.6563131, lng: 20.9968391 },
    { lat: 48.6566053, lng: 20.9966892 },
    { lat: 48.6571673, lng: 20.9961209 },
    { lat: 48.6576426, lng: 20.9958948 },
    { lat: 48.6580625, lng: 20.9954903 },
    { lat: 48.6582367, lng: 20.9954448 },
    { lat: 48.6584505, lng: 20.9952762 },
    { lat: 48.6585974, lng: 20.9952889 },
    { lat: 48.6587832, lng: 20.995161 },
    { lat: 48.6591155, lng: 20.9951031 },
    { lat: 48.6594456, lng: 20.9949061 },
    { lat: 48.6599233, lng: 20.9949181 },
    { lat: 48.6601512, lng: 20.9946986 },
    { lat: 48.6608929, lng: 20.9942893 },
    { lat: 48.6612474, lng: 20.9938786 },
    { lat: 48.6615505, lng: 20.9936862 },
    { lat: 48.6620147, lng: 20.9929194 },
    { lat: 48.6619707, lng: 20.9928522 },
    { lat: 48.6621776, lng: 20.9923363 },
    { lat: 48.6621896, lng: 20.9921577 },
    { lat: 48.6623374, lng: 20.9921327 },
    { lat: 48.6625318, lng: 20.9918149 },
    { lat: 48.662735, lng: 20.9916001 },
    { lat: 48.6629484, lng: 20.9911069 },
    { lat: 48.6629718, lng: 20.9909025 },
    { lat: 48.6633188, lng: 20.9906412 },
    { lat: 48.6634988, lng: 20.9898556 },
    { lat: 48.6637449, lng: 20.989539 },
    { lat: 48.6659722, lng: 20.9884825 },
    { lat: 48.6661055, lng: 20.9878517 },
    { lat: 48.6664563, lng: 20.9872679 },
    { lat: 48.6665858, lng: 20.9866303 },
    { lat: 48.6668417, lng: 20.9863014 },
    { lat: 48.6672462, lng: 20.9854653 },
    { lat: 48.6673705, lng: 20.9850132 },
    { lat: 48.6674457, lng: 20.9841603 },
    { lat: 48.6675093, lng: 20.983975 },
    { lat: 48.667635, lng: 20.9838964 },
    { lat: 48.6677136, lng: 20.9831472 },
    { lat: 48.6677387, lng: 20.9820738 },
    { lat: 48.6676411, lng: 20.9815453 },
    { lat: 48.6674325, lng: 20.9810943 },
    { lat: 48.667448, lng: 20.9812562 },
    { lat: 48.6666236, lng: 20.9812754 },
    { lat: 48.6664666, lng: 20.9813906 },
    { lat: 48.666251, lng: 20.9814404 },
    { lat: 48.6653755, lng: 20.9812074 },
    { lat: 48.6648777, lng: 20.9812046 },
    { lat: 48.664449, lng: 20.9801248 },
    { lat: 48.6657281, lng: 20.9794312 },
    { lat: 48.6659598, lng: 20.9800994 },
    { lat: 48.6670289, lng: 20.9790774 },
    { lat: 48.6670389, lng: 20.9787754 },
    { lat: 48.667162, lng: 20.9781468 },
    { lat: 48.6670853, lng: 20.9765527 },
    { lat: 48.6673916, lng: 20.9757442 },
    { lat: 48.6672951, lng: 20.9754564 },
    { lat: 48.6671908, lng: 20.9753347 },
    { lat: 48.6669247, lng: 20.9753189 },
    { lat: 48.6667683, lng: 20.9751464 },
    { lat: 48.6664685, lng: 20.9744282 },
    { lat: 48.6662115, lng: 20.974416 },
    { lat: 48.6661454, lng: 20.9742597 },
    { lat: 48.6660068, lng: 20.9734762 },
    { lat: 48.6659903, lng: 20.972177 },
    { lat: 48.6662384, lng: 20.9707438 },
    { lat: 48.6664041, lng: 20.9700767 },
    { lat: 48.6664027, lng: 20.9699375 },
    { lat: 48.666479, lng: 20.9697551 },
    { lat: 48.6664025, lng: 20.9683077 },
    { lat: 48.6662564, lng: 20.9678107 },
    { lat: 48.6652849, lng: 20.9661275 },
    { lat: 48.6661271, lng: 20.9651599 },
    { lat: 48.6670257, lng: 20.9639593 },
    { lat: 48.6664553, lng: 20.9629912 },
    { lat: 48.6667988, lng: 20.9624301 },
    { lat: 48.6658937, lng: 20.961047 },
    { lat: 48.6668922, lng: 20.9597401 },
    { lat: 48.667243, lng: 20.9591593 },
    { lat: 48.6677616, lng: 20.9576714 },
    { lat: 48.6682192, lng: 20.9560101 },
    { lat: 48.6678041, lng: 20.9558777 },
    { lat: 48.6677706, lng: 20.9552521 },
    { lat: 48.6678421, lng: 20.9545308 },
    { lat: 48.6678247, lng: 20.9542776 },
    { lat: 48.6676763, lng: 20.9540507 },
    { lat: 48.6677116, lng: 20.9534583 },
    { lat: 48.6678534, lng: 20.9529884 },
    { lat: 48.6682726, lng: 20.9520013 },
    { lat: 48.6690914, lng: 20.9497973 },
    { lat: 48.6693062, lng: 20.948491 },
    { lat: 48.6692834, lng: 20.9481762 },
    { lat: 48.6691704, lng: 20.9477283 },
    { lat: 48.6691962, lng: 20.9474297 },
    { lat: 48.669723, lng: 20.9457188 },
    { lat: 48.6697343, lng: 20.9438465 },
    { lat: 48.6697884, lng: 20.9434787 },
    { lat: 48.6699047, lng: 20.9430934 },
    { lat: 48.6700342, lng: 20.9420197 },
    { lat: 48.6701243, lng: 20.9415865 },
    { lat: 48.6702434, lng: 20.9413124 },
    { lat: 48.6703835, lng: 20.9405627 },
    { lat: 48.6700666, lng: 20.9392694 },
    { lat: 48.6699987, lng: 20.9391315 },
    { lat: 48.6703323, lng: 20.9379367 },
    { lat: 48.6701552, lng: 20.9373289 },
    { lat: 48.6701778, lng: 20.9370967 },
    { lat: 48.6701227, lng: 20.9369681 },
    { lat: 48.6699775, lng: 20.9368991 },
    { lat: 48.6697724, lng: 20.9370221 },
    { lat: 48.6695795, lng: 20.9375757 },
    { lat: 48.6688222, lng: 20.9382848 },
    { lat: 48.6687239, lng: 20.9381759 },
    { lat: 48.6687643, lng: 20.9379755 },
    { lat: 48.6685574, lng: 20.9373775 },
    { lat: 48.6690017, lng: 20.9366696 },
    { lat: 48.6690605, lng: 20.9362381 },
    { lat: 48.6690137, lng: 20.9358838 },
    { lat: 48.669437, lng: 20.9352526 },
    { lat: 48.669551, lng: 20.9347991 },
    { lat: 48.6698847, lng: 20.9342115 },
    { lat: 48.6699525, lng: 20.9338231 },
    { lat: 48.6699193, lng: 20.9335009 },
    { lat: 48.6699841, lng: 20.9329888 },
    { lat: 48.6702838, lng: 20.9322592 },
    { lat: 48.670464, lng: 20.9315957 },
    { lat: 48.6705179, lng: 20.9311607 },
    { lat: 48.6705118, lng: 20.931013 },
    { lat: 48.6703853, lng: 20.9308646 },
    { lat: 48.6692073, lng: 20.9263527 },
    { lat: 48.6690004, lng: 20.9256892 },
    { lat: 48.6689125, lng: 20.9251554 },
    { lat: 48.6687056, lng: 20.9244972 },
    { lat: 48.6683394, lng: 20.9230226 },
    { lat: 48.6682058, lng: 20.9223011 },
    { lat: 48.6678486, lng: 20.9224533 },
    { lat: 48.6670131, lng: 20.9229468 },
    { lat: 48.6664896, lng: 20.923147 },
    { lat: 48.6657604, lng: 20.9232139 },
    { lat: 48.6654008, lng: 20.9227691 },
    { lat: 48.6649194, lng: 20.9223102 },
    { lat: 48.6643268, lng: 20.922003 },
    { lat: 48.6639267, lng: 20.9219167 },
    { lat: 48.6634641, lng: 20.9219513 },
    { lat: 48.6628524, lng: 20.9215722 },
    { lat: 48.6626027, lng: 20.921213 },
    { lat: 48.662402, lng: 20.9211028 },
    { lat: 48.6620332, lng: 20.9206526 },
    { lat: 48.6616949, lng: 20.9200998 },
    { lat: 48.6606405, lng: 20.9187483 },
    { lat: 48.660323, lng: 20.9181775 },
    { lat: 48.6597513, lng: 20.9173222 },
    { lat: 48.6595655, lng: 20.9169207 },
    { lat: 48.6590075, lng: 20.9163688 },
    { lat: 48.6584322, lng: 20.9160956 },
    { lat: 48.6575653, lng: 20.9161237 },
    { lat: 48.6563219, lng: 20.915675 },
    { lat: 48.6549352, lng: 20.9153769 },
    { lat: 48.6543725, lng: 20.9153178 },
    { lat: 48.6540045, lng: 20.9154132 },
    { lat: 48.6536701, lng: 20.9156807 },
    { lat: 48.6533368, lng: 20.9162081 },
    { lat: 48.651833, lng: 20.916311 },
    { lat: 48.6513966, lng: 20.9164216 },
    { lat: 48.6510017, lng: 20.9162249 },
    { lat: 48.6507658, lng: 20.9163371 },
    { lat: 48.6506254, lng: 20.9165149 },
    { lat: 48.6506128, lng: 20.9165775 },
    { lat: 48.6507456, lng: 20.9174034 },
    { lat: 48.6508892, lng: 20.9177569 },
    { lat: 48.6510559, lng: 20.9187241 },
    { lat: 48.6512331, lng: 20.9190657 },
    { lat: 48.6515003, lng: 20.9193445 },
    { lat: 48.6516765, lng: 20.9196624 },
    { lat: 48.6518286, lng: 20.9201358 },
    { lat: 48.6517712, lng: 20.9208298 },
    { lat: 48.6513401, lng: 20.9216957 },
    { lat: 48.6511275, lng: 20.9224319 },
    { lat: 48.6511174, lng: 20.9229977 },
    { lat: 48.651251, lng: 20.9245826 },
    { lat: 48.6511357, lng: 20.9253661 },
    { lat: 48.6509157, lng: 20.9260153 },
    { lat: 48.6507171, lng: 20.9269383 },
    { lat: 48.6497883, lng: 20.9287609 },
    { lat: 48.6491227, lng: 20.9304525 },
    { lat: 48.6490516, lng: 20.9310763 },
    { lat: 48.6490779, lng: 20.9315637 },
    { lat: 48.6491543, lng: 20.9319896 },
    { lat: 48.6490743, lng: 20.9328893 },
    { lat: 48.6487085, lng: 20.9336521 },
    { lat: 48.6485193, lng: 20.9339162 },
    { lat: 48.648151, lng: 20.9348416 },
    { lat: 48.6477504, lng: 20.9365532 },
    { lat: 48.6468812, lng: 20.9387838 },
    { lat: 48.6467067, lng: 20.93945 },
    { lat: 48.6463145, lng: 20.9414414 },
    { lat: 48.6461727, lng: 20.9430449 },
    { lat: 48.6460086, lng: 20.9434885 },
    { lat: 48.6452081, lng: 20.9450523 },
    { lat: 48.645049, lng: 20.945476 },
    { lat: 48.644287, lng: 20.9464448 },
    { lat: 48.6441001, lng: 20.9484207 },
    { lat: 48.6438205, lng: 20.9495117 },
    { lat: 48.6435309, lng: 20.9502358 },
    { lat: 48.6432108, lng: 20.9508276 },
    { lat: 48.6428052, lng: 20.9512512 },
    { lat: 48.6423493, lng: 20.952256 },
    { lat: 48.6422853, lng: 20.9527296 },
    { lat: 48.6424052, lng: 20.9534569 },
    { lat: 48.6423821, lng: 20.9537025 },
    { lat: 48.6422606, lng: 20.9540381 },
    { lat: 48.6419403, lng: 20.9545253 },
    { lat: 48.6418528, lng: 20.9550712 },
    { lat: 48.6416415, lng: 20.9555782 },
    { lat: 48.6415765, lng: 20.9561313 },
    { lat: 48.6414735, lng: 20.9564776 },
    { lat: 48.6414009, lng: 20.9570704 },
    { lat: 48.641279, lng: 20.9574563 },
    { lat: 48.6412475, lng: 20.9577616 },
    { lat: 48.6404349, lng: 20.9591408 },
    { lat: 48.6399163, lng: 20.9595235 },
    { lat: 48.6389703, lng: 20.9621571 },
    { lat: 48.6388472, lng: 20.9630523 },
    { lat: 48.638457, lng: 20.9636344 },
    { lat: 48.6381979, lng: 20.9651749 },
    { lat: 48.6382453, lng: 20.9662568 },
    { lat: 48.6381446, lng: 20.9666734 },
    { lat: 48.6380441, lng: 20.967506 },
    { lat: 48.6380311, lng: 20.9680974 },
    { lat: 48.637948, lng: 20.9683685 },
    { lat: 48.6374692, lng: 20.9688993 },
    { lat: 48.6373886, lng: 20.9695496 },
    { lat: 48.6371452, lng: 20.9700795 },
    { lat: 48.636997, lng: 20.9704922 },
    { lat: 48.6369155, lng: 20.9709561 },
    { lat: 48.6363476, lng: 20.9724467 },
    { lat: 48.6363015, lng: 20.972958 },
    { lat: 48.6364156, lng: 20.9737124 },
    { lat: 48.6364325, lng: 20.9741125 },
    { lat: 48.6365298, lng: 20.9743638 },
    { lat: 48.6364375, lng: 20.974868 },
    { lat: 48.6363962, lng: 20.9755523 },
    { lat: 48.6363095, lng: 20.9757272 },
    { lat: 48.6361009, lng: 20.9758787 },
    { lat: 48.6357113, lng: 20.9763826 },
    { lat: 48.6351948, lng: 20.9768934 },
    { lat: 48.6348413, lng: 20.9773309 },
    { lat: 48.6345948, lng: 20.9779034 },
    { lat: 48.6344022, lng: 20.9786789 },
    { lat: 48.6334362, lng: 20.9799465 },
    { lat: 48.6333498, lng: 20.9803612 },
    { lat: 48.6333692, lng: 20.9807088 },
    { lat: 48.6332157, lng: 20.9806635 },
    { lat: 48.6332138, lng: 20.9807049 },
    { lat: 48.631844, lng: 20.9872618 },
    { lat: 48.6318102, lng: 20.9902022 },
    { lat: 48.631349, lng: 20.992617 },
    { lat: 48.6308282, lng: 20.9938808 },
    { lat: 48.6308161, lng: 20.9949008 },
    { lat: 48.6307401, lng: 20.9953962 },
    { lat: 48.6307592, lng: 20.9959698 },
    { lat: 48.6307876, lng: 20.9961093 },
    { lat: 48.6308906, lng: 20.9960623 },
    { lat: 48.6309531, lng: 20.9961487 },
    { lat: 48.6311116, lng: 20.9965466 },
    { lat: 48.6311929, lng: 20.9965519 },
    { lat: 48.6312465, lng: 20.9966731 },
    { lat: 48.631298, lng: 20.9970393 },
    { lat: 48.6312678, lng: 20.9975202 },
    { lat: 48.6313137, lng: 20.9976188 },
    { lat: 48.6313712, lng: 20.9975518 },
    { lat: 48.6315499, lng: 20.9975782 },
    { lat: 48.6316479, lng: 20.9974455 },
    { lat: 48.6317593, lng: 20.9969646 },
    { lat: 48.631847, lng: 20.9969295 },
    { lat: 48.6319311, lng: 20.9970259 },
    { lat: 48.6319396, lng: 20.997173 },
    { lat: 48.6321838, lng: 20.9973766 },
    { lat: 48.6321746, lng: 20.9974402 },
    { lat: 48.6320366, lng: 20.9975243 },
    { lat: 48.6319844, lng: 20.9977788 },
    { lat: 48.6320728, lng: 20.9978689 },
    { lat: 48.632224, lng: 20.9978465 },
    { lat: 48.6322303, lng: 20.997931 },
    { lat: 48.6320403, lng: 20.9980763 },
    { lat: 48.6320218, lng: 20.9981578 },
    { lat: 48.6320753, lng: 20.998384 },
    { lat: 48.6319954, lng: 20.9984131 },
    { lat: 48.6319863, lng: 20.9984821 },
    { lat: 48.6320471, lng: 20.9985854 },
    { lat: 48.6321459, lng: 20.9985779 },
    { lat: 48.632201, lng: 20.9987449 },
    { lat: 48.6323035, lng: 20.9988507 },
    { lat: 48.6324234, lng: 20.9988302 },
    { lat: 48.6325429, lng: 20.9989411 },
    { lat: 48.6325808, lng: 20.9988984 },
    { lat: 48.6325576, lng: 20.9988372 },
    { lat: 48.6327553, lng: 20.9987255 },
    { lat: 48.6328653, lng: 20.99882 },
    { lat: 48.6328778, lng: 20.9989895 },
    { lat: 48.632641, lng: 20.9990134 },
    { lat: 48.6327564, lng: 20.9993691 },
    { lat: 48.6328645, lng: 20.9994346 },
    { lat: 48.6330054, lng: 20.9993101 },
    { lat: 48.6331238, lng: 20.9993528 },
    { lat: 48.6331871, lng: 20.9991199 },
    { lat: 48.6332422, lng: 20.9990819 },
    { lat: 48.6333874, lng: 20.9990877 },
    { lat: 48.6334511, lng: 20.9992081 },
    { lat: 48.6334976, lng: 20.9988744 },
    { lat: 48.6340753, lng: 20.999019 },
    { lat: 48.6341541, lng: 20.9989561 },
    { lat: 48.634295, lng: 20.9990309 },
    { lat: 48.6344504, lng: 20.9988939 },
    { lat: 48.6345399, lng: 20.9990462 },
    { lat: 48.6345787, lng: 20.9992713 },
    { lat: 48.634686, lng: 20.9993825 },
    { lat: 48.6349934, lng: 20.9994849 },
    { lat: 48.6350925, lng: 20.9993863 },
    { lat: 48.6351027, lng: 20.9991464 },
    { lat: 48.6353473, lng: 20.9991617 },
    { lat: 48.6352946, lng: 20.9989667 },
    { lat: 48.635335, lng: 20.9988893 },
    { lat: 48.6354897, lng: 20.9989744 },
    { lat: 48.6356345, lng: 20.9989582 },
    { lat: 48.6356258, lng: 20.9986965 },
    { lat: 48.6357006, lng: 20.9986112 },
    { lat: 48.6360569, lng: 20.9987665 },
    { lat: 48.636133, lng: 20.9987212 },
    { lat: 48.6361914, lng: 20.9988876 },
    { lat: 48.6362935, lng: 20.9988738 },
    { lat: 48.6363849, lng: 20.9989467 },
    { lat: 48.6365314, lng: 20.9988847 },
    { lat: 48.6366093, lng: 20.9989927 },
    { lat: 48.6368483, lng: 20.998838 },
    { lat: 48.6369334, lng: 20.99886 },
    { lat: 48.637104, lng: 20.9990806 },
    { lat: 48.637172, lng: 20.9990979 },
    { lat: 48.6372424, lng: 20.9989787 },
    { lat: 48.6372779, lng: 20.9989991 },
    { lat: 48.6373081, lng: 20.999162 },
    { lat: 48.6374743, lng: 20.9992516 },
    { lat: 48.6374591, lng: 20.9993328 },
    { lat: 48.637337, lng: 20.9993872 },
    { lat: 48.6372822, lng: 20.9996309 },
    { lat: 48.6373758, lng: 20.9997092 },
    { lat: 48.6374418, lng: 20.9998633 },
    { lat: 48.6375381, lng: 20.9997824 },
    { lat: 48.6376441, lng: 20.9999907 },
    { lat: 48.6377796, lng: 21.000009 },
    { lat: 48.6378424, lng: 21.000101 },
    { lat: 48.6376174, lng: 21.0002372 },
    { lat: 48.6377185, lng: 21.0002979 },
    { lat: 48.6377106, lng: 21.0004014 },
    { lat: 48.637541, lng: 21.0005167 },
    { lat: 48.6376554, lng: 21.0008782 },
    { lat: 48.6377846, lng: 21.0010392 },
    { lat: 48.6379296, lng: 21.0011308 },
    { lat: 48.6384693, lng: 21.0011811 },
    { lat: 48.6391046, lng: 21.001435 },
    { lat: 48.6393188, lng: 21.0016124 },
    { lat: 48.6396181, lng: 21.0016468 },
    { lat: 48.6395471, lng: 21.0029358 },
    { lat: 48.6395297, lng: 21.0049857 },
    { lat: 48.6400611, lng: 21.0140384 },
    { lat: 48.6417368, lng: 21.0148484 },
    { lat: 48.6433834, lng: 21.0159698 },
    { lat: 48.6433023, lng: 21.0192063 },
    { lat: 48.6433178, lng: 21.0199098 },
    { lat: 48.6434228, lng: 21.0214278 },
    { lat: 48.6435114, lng: 21.0220269 },
    { lat: 48.6435824, lng: 21.0220966 },
    { lat: 48.6432464, lng: 21.0222511 },
    { lat: 48.6429148, lng: 21.0223002 },
    { lat: 48.6420838, lng: 21.0220853 },
    { lat: 48.6416966, lng: 21.0222229 },
    { lat: 48.6415048, lng: 21.0224142 },
    { lat: 48.6412301, lng: 21.0225473 },
    { lat: 48.6408736, lng: 21.022458 },
    { lat: 48.6403943, lng: 21.0225631 },
    { lat: 48.6406996, lng: 21.0233548 },
    { lat: 48.6408244, lng: 21.0240327 },
    { lat: 48.6410142, lng: 21.0245341 },
    { lat: 48.6412291, lng: 21.0249761 },
    { lat: 48.6414738, lng: 21.025294 },
    { lat: 48.6415961, lng: 21.0256586 },
    { lat: 48.6416668, lng: 21.0255839 },
    { lat: 48.6417309, lng: 21.0256538 },
    { lat: 48.6418334, lng: 21.025623 },
    { lat: 48.6418285, lng: 21.0255077 },
    { lat: 48.6419592, lng: 21.0254737 },
    { lat: 48.6421224, lng: 21.0257468 },
    { lat: 48.6424037, lng: 21.0258154 },
    { lat: 48.6425181, lng: 21.0259371 },
    { lat: 48.642606, lng: 21.0258408 },
    { lat: 48.6427513, lng: 21.0259766 },
    { lat: 48.6427934, lng: 21.0258239 },
    { lat: 48.6435238, lng: 21.0258514 },
    { lat: 48.6439036, lng: 21.0257677 },
    { lat: 48.6451682, lng: 21.0251325 },
    { lat: 48.645275, lng: 21.0252331 },
    { lat: 48.6458548, lng: 21.0253416 },
    { lat: 48.6460649, lng: 21.0254628 },
    { lat: 48.6464326, lng: 21.0257264 },
    { lat: 48.6470158, lng: 21.0262749 },
    { lat: 48.6477409, lng: 21.0271069 },
    { lat: 48.6478583, lng: 21.0274551 },
    { lat: 48.6481897, lng: 21.0279496 },
    { lat: 48.6482742, lng: 21.0281621 },
    { lat: 48.6484173, lng: 21.0281945 },
    { lat: 48.6490718, lng: 21.029203 },
    { lat: 48.6494671, lng: 21.0294844 },
    { lat: 48.6502971, lng: 21.0297405 },
    { lat: 48.6525968, lng: 21.0310127 },
    { lat: 48.6528904, lng: 21.0313848 },
    { lat: 48.6533813, lng: 21.032411 },
    { lat: 48.6535788, lng: 21.0326664 },
    { lat: 48.6538002, lng: 21.0332087 },
    { lat: 48.6539797, lng: 21.0334953 },
    { lat: 48.6548592, lng: 21.0341974 },
    { lat: 48.6548725, lng: 21.0342209 },
  ],
  Čakanovce: [
    { lat: 48.779264, lng: 21.4697142 },
    { lat: 48.7791669, lng: 21.4693899 },
    { lat: 48.7790674, lng: 21.4683273 },
    { lat: 48.7793035, lng: 21.4669326 },
    { lat: 48.7792332, lng: 21.4668317 },
    { lat: 48.7791982, lng: 21.4664819 },
    { lat: 48.7790365, lng: 21.4659047 },
    { lat: 48.7793217, lng: 21.4643373 },
    { lat: 48.7803673, lng: 21.4623771 },
    { lat: 48.7807725, lng: 21.4610978 },
    { lat: 48.7809187, lng: 21.4594715 },
    { lat: 48.780938, lng: 21.4573559 },
    { lat: 48.781032, lng: 21.4568344 },
    { lat: 48.7812702, lng: 21.4557763 },
    { lat: 48.7818407, lng: 21.4553494 },
    { lat: 48.7815425, lng: 21.4537676 },
    { lat: 48.7815802, lng: 21.4537087 },
    { lat: 48.7815315, lng: 21.4533919 },
    { lat: 48.7814912, lng: 21.4517405 },
    { lat: 48.78137, lng: 21.4512873 },
    { lat: 48.7815181, lng: 21.4509263 },
    { lat: 48.7816983, lng: 21.4486186 },
    { lat: 48.7819426, lng: 21.4469689 },
    { lat: 48.7814534, lng: 21.4469406 },
    { lat: 48.7810286, lng: 21.4466144 },
    { lat: 48.7808513, lng: 21.4463957 },
    { lat: 48.7806689, lng: 21.4457375 },
    { lat: 48.7803343, lng: 21.4454952 },
    { lat: 48.7780945, lng: 21.4453176 },
    { lat: 48.7760878, lng: 21.4441122 },
    { lat: 48.7749342, lng: 21.4437949 },
    { lat: 48.7744422, lng: 21.4435974 },
    { lat: 48.7741552, lng: 21.4425953 },
    { lat: 48.7737943, lng: 21.4399867 },
    { lat: 48.7729379, lng: 21.4359537 },
    { lat: 48.7726923, lng: 21.4341723 },
    { lat: 48.7722849, lng: 21.4341761 },
    { lat: 48.7721278, lng: 21.4341081 },
    { lat: 48.7720075, lng: 21.4341558 },
    { lat: 48.7718369, lng: 21.4339429 },
    { lat: 48.7718754, lng: 21.4337184 },
    { lat: 48.7717967, lng: 21.433449 },
    { lat: 48.7718389, lng: 21.4332379 },
    { lat: 48.7717848, lng: 21.4331238 },
    { lat: 48.7718573, lng: 21.4329106 },
    { lat: 48.7717714, lng: 21.4326663 },
    { lat: 48.7718597, lng: 21.4324545 },
    { lat: 48.7717606, lng: 21.4314145 },
    { lat: 48.7718246, lng: 21.4310228 },
    { lat: 48.7718196, lng: 21.4307816 },
    { lat: 48.7719402, lng: 21.4306316 },
    { lat: 48.771941, lng: 21.4305074 },
    { lat: 48.7720384, lng: 21.4305315 },
    { lat: 48.7721562, lng: 21.4304012 },
    { lat: 48.7721744, lng: 21.4301046 },
    { lat: 48.7720352, lng: 21.4284634 },
    { lat: 48.7725112, lng: 21.4281545 },
    { lat: 48.7731052, lng: 21.4275377 },
    { lat: 48.7731933, lng: 21.4270944 },
    { lat: 48.7733114, lng: 21.4266859 },
    { lat: 48.7734752, lng: 21.4262782 },
    { lat: 48.7738454, lng: 21.4259302 },
    { lat: 48.7740948, lng: 21.4257548 },
    { lat: 48.7744236, lng: 21.4255822 },
    { lat: 48.7746258, lng: 21.4256156 },
    { lat: 48.7748026, lng: 21.425334 },
    { lat: 48.775409, lng: 21.4248046 },
    { lat: 48.7762349, lng: 21.4242385 },
    { lat: 48.7764561, lng: 21.4241511 },
    { lat: 48.7768142, lng: 21.4239595 },
    { lat: 48.7772952, lng: 21.4238229 },
    { lat: 48.7783087, lng: 21.4239621 },
    { lat: 48.7793015, lng: 21.422665 },
    { lat: 48.7802853, lng: 21.4221795 },
    { lat: 48.7808496, lng: 21.4216983 },
    { lat: 48.7812675, lng: 21.4217886 },
    { lat: 48.7813509, lng: 21.421875 },
    { lat: 48.7816371, lng: 21.4215849 },
    { lat: 48.7817256, lng: 21.4214123 },
    { lat: 48.7816939, lng: 21.4212157 },
    { lat: 48.7829297, lng: 21.419541 },
    { lat: 48.7832068, lng: 21.4194781 },
    { lat: 48.7833488, lng: 21.419562 },
    { lat: 48.7842093, lng: 21.419667 },
    { lat: 48.7848499, lng: 21.4194105 },
    { lat: 48.7851327, lng: 21.4194992 },
    { lat: 48.7854285, lng: 21.4193739 },
    { lat: 48.7855546, lng: 21.4194218 },
    { lat: 48.7856123, lng: 21.4199632 },
    { lat: 48.7858388, lng: 21.4204717 },
    { lat: 48.7860559, lng: 21.4205834 },
    { lat: 48.7862131, lng: 21.420531 },
    { lat: 48.7869451, lng: 21.4198319 },
    { lat: 48.7875134, lng: 21.4196783 },
    { lat: 48.7877532, lng: 21.4194537 },
    { lat: 48.7882487, lng: 21.419658 },
    { lat: 48.7887095, lng: 21.4200194 },
    { lat: 48.7889786, lng: 21.4199913 },
    { lat: 48.7893405, lng: 21.4198198 },
    { lat: 48.789968, lng: 21.4198881 },
    { lat: 48.79039, lng: 21.4197319 },
    { lat: 48.7915041, lng: 21.4195058 },
    { lat: 48.7918836, lng: 21.4195184 },
    { lat: 48.7920752, lng: 21.4196425 },
    { lat: 48.7920141, lng: 21.419283 },
    { lat: 48.7915591, lng: 21.4185493 },
    { lat: 48.7913661, lng: 21.4174945 },
    { lat: 48.7911906, lng: 21.4161952 },
    { lat: 48.7917532, lng: 21.4159328 },
    { lat: 48.7923151, lng: 21.4149488 },
    { lat: 48.7921675, lng: 21.4139502 },
    { lat: 48.7924493, lng: 21.4137734 },
    { lat: 48.7927511, lng: 21.413788 },
    { lat: 48.7932081, lng: 21.4136772 },
    { lat: 48.793542, lng: 21.4135111 },
    { lat: 48.7927189, lng: 21.410979 },
    { lat: 48.7918383, lng: 21.4114136 },
    { lat: 48.7919582, lng: 21.409109 },
    { lat: 48.7919585, lng: 21.4076772 },
    { lat: 48.7916199, lng: 21.4070995 },
    { lat: 48.7913695, lng: 21.4061379 },
    { lat: 48.7911702, lng: 21.4051118 },
    { lat: 48.7910869, lng: 21.4050247 },
    { lat: 48.7909621, lng: 21.404541 },
    { lat: 48.7906222, lng: 21.4041084 },
    { lat: 48.7900354, lng: 21.4033694 },
    { lat: 48.7889493, lng: 21.4023728 },
    { lat: 48.7878929, lng: 21.4018068 },
    { lat: 48.7874367, lng: 21.4018744 },
    { lat: 48.7871218, lng: 21.4020307 },
    { lat: 48.7869034, lng: 21.4023299 },
    { lat: 48.7852806, lng: 21.4032378 },
    { lat: 48.7850029, lng: 21.4036209 },
    { lat: 48.7834525, lng: 21.4048096 },
    { lat: 48.7820162, lng: 21.4051756 },
    { lat: 48.7813936, lng: 21.4054654 },
    { lat: 48.7798142, lng: 21.4064365 },
    { lat: 48.779146, lng: 21.407054 },
    { lat: 48.7788365, lng: 21.4072027 },
    { lat: 48.777497, lng: 21.4081036 },
    { lat: 48.776308, lng: 21.4087627 },
    { lat: 48.7745665, lng: 21.4090068 },
    { lat: 48.7736522, lng: 21.409369 },
    { lat: 48.7731456, lng: 21.409358 },
    { lat: 48.7724236, lng: 21.4100218 },
    { lat: 48.7720505, lng: 21.4100752 },
    { lat: 48.7705376, lng: 21.4097548 },
    { lat: 48.7695728, lng: 21.4101168 },
    { lat: 48.7680884, lng: 21.4109449 },
    { lat: 48.7678724, lng: 21.4109951 },
    { lat: 48.7667463, lng: 21.4115699 },
    { lat: 48.7642991, lng: 21.4126056 },
    { lat: 48.7639455, lng: 21.4129059 },
    { lat: 48.7636471, lng: 21.4130527 },
    { lat: 48.7631713, lng: 21.4131351 },
    { lat: 48.7625212, lng: 21.4133738 },
    { lat: 48.7623232, lng: 21.4133764 },
    { lat: 48.7618895, lng: 21.4137021 },
    { lat: 48.7620855, lng: 21.4158046 },
    { lat: 48.7619246, lng: 21.4166426 },
    { lat: 48.7620432, lng: 21.4196744 },
    { lat: 48.762329, lng: 21.4207473 },
    { lat: 48.7610485, lng: 21.4225056 },
    { lat: 48.7605332, lng: 21.4239223 },
    { lat: 48.7604108, lng: 21.4250818 },
    { lat: 48.7603637, lng: 21.4251631 },
    { lat: 48.7574997, lng: 21.4256129 },
    { lat: 48.7577137, lng: 21.4278407 },
    { lat: 48.7583668, lng: 21.4275075 },
    { lat: 48.7586757, lng: 21.4275328 },
    { lat: 48.7587403, lng: 21.4276607 },
    { lat: 48.7587665, lng: 21.4279732 },
    { lat: 48.7587056, lng: 21.4281652 },
    { lat: 48.7585723, lng: 21.4283375 },
    { lat: 48.7585602, lng: 21.428497 },
    { lat: 48.758667, lng: 21.4287627 },
    { lat: 48.7589687, lng: 21.4287741 },
    { lat: 48.7592351, lng: 21.4286022 },
    { lat: 48.7593806, lng: 21.4286198 },
    { lat: 48.7595093, lng: 21.4286757 },
    { lat: 48.7598638, lng: 21.4290949 },
    { lat: 48.7599768, lng: 21.4284617 },
    { lat: 48.7600697, lng: 21.4282853 },
    { lat: 48.760473, lng: 21.4278484 },
    { lat: 48.7607559, lng: 21.4279036 },
    { lat: 48.7609787, lng: 21.4286643 },
    { lat: 48.7612459, lng: 21.4290647 },
    { lat: 48.7610041, lng: 21.4291423 },
    { lat: 48.7609366, lng: 21.4291893 },
    { lat: 48.7609083, lng: 21.4292936 },
    { lat: 48.7609414, lng: 21.4294229 },
    { lat: 48.7612137, lng: 21.4296946 },
    { lat: 48.7616759, lng: 21.4347841 },
    { lat: 48.7612668, lng: 21.4347573 },
    { lat: 48.7610641, lng: 21.4381317 },
    { lat: 48.7609912, lng: 21.438138 },
    { lat: 48.7609324, lng: 21.4380142 },
    { lat: 48.7608325, lng: 21.4375252 },
    { lat: 48.7607482, lng: 21.4372555 },
    { lat: 48.7606815, lng: 21.4372108 },
    { lat: 48.7604918, lng: 21.4371889 },
    { lat: 48.7604018, lng: 21.4373864 },
    { lat: 48.7598884, lng: 21.4375275 },
    { lat: 48.7596533, lng: 21.4379545 },
    { lat: 48.7586386, lng: 21.4371538 },
    { lat: 48.7579958, lng: 21.4370649 },
    { lat: 48.7573703, lng: 21.4371882 },
    { lat: 48.7573664, lng: 21.4372897 },
    { lat: 48.7574276, lng: 21.4373006 },
    { lat: 48.7574666, lng: 21.4374 },
    { lat: 48.7574014, lng: 21.43767 },
    { lat: 48.7575517, lng: 21.4380044 },
    { lat: 48.7574627, lng: 21.4382262 },
    { lat: 48.7574742, lng: 21.4383506 },
    { lat: 48.7576854, lng: 21.438728 },
    { lat: 48.7579363, lng: 21.4387212 },
    { lat: 48.7580213, lng: 21.4387789 },
    { lat: 48.7580101, lng: 21.4391324 },
    { lat: 48.7580469, lng: 21.4393097 },
    { lat: 48.7582814, lng: 21.4392883 },
    { lat: 48.7583742, lng: 21.4393106 },
    { lat: 48.7584262, lng: 21.4393952 },
    { lat: 48.7584622, lng: 21.4396253 },
    { lat: 48.7587407, lng: 21.4401822 },
    { lat: 48.7587258, lng: 21.4405192 },
    { lat: 48.7587677, lng: 21.4406376 },
    { lat: 48.7589262, lng: 21.4407105 },
    { lat: 48.7589617, lng: 21.4408126 },
    { lat: 48.7589652, lng: 21.441929 },
    { lat: 48.7588731, lng: 21.4425122 },
    { lat: 48.7587773, lng: 21.4427379 },
    { lat: 48.7586553, lng: 21.4434102 },
    { lat: 48.7585041, lng: 21.4436664 },
    { lat: 48.7582441, lng: 21.4445885 },
    { lat: 48.758211, lng: 21.4449955 },
    { lat: 48.757954, lng: 21.4455202 },
    { lat: 48.7578192, lng: 21.4460624 },
    { lat: 48.7576908, lng: 21.4462122 },
    { lat: 48.7573808, lng: 21.4463848 },
    { lat: 48.7574786, lng: 21.4473833 },
    { lat: 48.7575471, lng: 21.449542 },
    { lat: 48.7563511, lng: 21.4495424 },
    { lat: 48.7566237, lng: 21.4518664 },
    { lat: 48.7563683, lng: 21.4519063 },
    { lat: 48.7562047, lng: 21.4520039 },
    { lat: 48.7562592, lng: 21.4529128 },
    { lat: 48.7563046, lng: 21.4553587 },
    { lat: 48.7567015, lng: 21.4557316 },
    { lat: 48.7568434, lng: 21.4558905 },
    { lat: 48.7575016, lng: 21.4561536 },
    { lat: 48.7598143, lng: 21.4576367 },
    { lat: 48.7605918, lng: 21.4579783 },
    { lat: 48.7616916, lng: 21.4581914 },
    { lat: 48.7621086, lng: 21.4583683 },
    { lat: 48.7620342, lng: 21.4589443 },
    { lat: 48.7629405, lng: 21.4593882 },
    { lat: 48.7633433, lng: 21.4603853 },
    { lat: 48.7643891, lng: 21.4605579 },
    { lat: 48.766184, lng: 21.4620687 },
    { lat: 48.7674296, lng: 21.4629966 },
    { lat: 48.7689744, lng: 21.4645839 },
    { lat: 48.7705314, lng: 21.4658525 },
    { lat: 48.7708531, lng: 21.4661964 },
    { lat: 48.7714327, lng: 21.4670882 },
    { lat: 48.7721005, lng: 21.4674576 },
    { lat: 48.7725591, lng: 21.4679843 },
    { lat: 48.77288, lng: 21.4682987 },
    { lat: 48.7730121, lng: 21.4683512 },
    { lat: 48.773015, lng: 21.4683386 },
    { lat: 48.773499, lng: 21.466639 },
    { lat: 48.7738605, lng: 21.4679606 },
    { lat: 48.7747505, lng: 21.468306 },
    { lat: 48.7756397, lng: 21.4683564 },
    { lat: 48.7767453, lng: 21.4687969 },
    { lat: 48.7776077, lng: 21.4692959 },
    { lat: 48.7782264, lng: 21.4695955 },
    { lat: 48.779264, lng: 21.4697142 },
  ],
  Beniakovce: [
    { lat: 48.7813091, lng: 21.3134381 },
    { lat: 48.7812368, lng: 21.3131298 },
    { lat: 48.7806048, lng: 21.3109943 },
    { lat: 48.7805539, lng: 21.3105072 },
    { lat: 48.7801828, lng: 21.3099208 },
    { lat: 48.7793498, lng: 21.3095438 },
    { lat: 48.7784804, lng: 21.3068648 },
    { lat: 48.7783948, lng: 21.3067073 },
    { lat: 48.7782667, lng: 21.3060487 },
    { lat: 48.7779299, lng: 21.3056365 },
    { lat: 48.7777188, lng: 21.3055013 },
    { lat: 48.7776814, lng: 21.3054497 },
    { lat: 48.7776926, lng: 21.3052306 },
    { lat: 48.7775243, lng: 21.3050243 },
    { lat: 48.7773234, lng: 21.3049295 },
    { lat: 48.776904, lng: 21.304923 },
    { lat: 48.776468, lng: 21.304816 },
    { lat: 48.77638, lng: 21.304795 },
    { lat: 48.775522, lng: 21.304874 },
    { lat: 48.774849, lng: 21.304465 },
    { lat: 48.774326, lng: 21.302698 },
    { lat: 48.77421, lng: 21.301548 },
    { lat: 48.774193, lng: 21.301278 },
    { lat: 48.77244, lng: 21.303213 },
    { lat: 48.772163, lng: 21.302619 },
    { lat: 48.772068, lng: 21.302416 },
    { lat: 48.772057, lng: 21.302395 },
    { lat: 48.771525, lng: 21.301253 },
    { lat: 48.771491, lng: 21.301183 },
    { lat: 48.771426, lng: 21.301043 },
    { lat: 48.771409, lng: 21.301005 },
    { lat: 48.771014, lng: 21.300161 },
    { lat: 48.770695, lng: 21.30064 },
    { lat: 48.770151, lng: 21.301455 },
    { lat: 48.76992, lng: 21.30143 },
    { lat: 48.76969, lng: 21.300718 },
    { lat: 48.769613, lng: 21.300319 },
    { lat: 48.769027, lng: 21.300604 },
    { lat: 48.768914, lng: 21.300033 },
    { lat: 48.768891, lng: 21.299923 },
    { lat: 48.76806, lng: 21.300095 },
    { lat: 48.767529, lng: 21.300205 },
    { lat: 48.767302, lng: 21.300313 },
    { lat: 48.766696, lng: 21.300601 },
    { lat: 48.766346, lng: 21.300524 },
    { lat: 48.765941, lng: 21.300708 },
    { lat: 48.765435, lng: 21.300466 },
    { lat: 48.76488, lng: 21.30073 },
    { lat: 48.76448, lng: 21.301029 },
    { lat: 48.764374, lng: 21.301618 },
    { lat: 48.763588, lng: 21.301843 },
    { lat: 48.763236, lng: 21.301944 },
    { lat: 48.762478, lng: 21.301864 },
    { lat: 48.761298, lng: 21.302979 },
    { lat: 48.760345, lng: 21.303942 },
    { lat: 48.759727, lng: 21.304243 },
    { lat: 48.759275, lng: 21.304659 },
    { lat: 48.758255, lng: 21.30517 },
    { lat: 48.757499, lng: 21.305597 },
    { lat: 48.756828, lng: 21.306233 },
    { lat: 48.755631, lng: 21.307228 },
    { lat: 48.75548, lng: 21.307164 },
    { lat: 48.755257, lng: 21.307248 },
    { lat: 48.754838, lng: 21.307985 },
    { lat: 48.754725, lng: 21.308135 },
    { lat: 48.754405, lng: 21.308525 },
    { lat: 48.753692, lng: 21.307683 },
    { lat: 48.753659, lng: 21.307644 },
    { lat: 48.753164, lng: 21.307835 },
    { lat: 48.752892, lng: 21.308062 },
    { lat: 48.752613, lng: 21.308565 },
    { lat: 48.75242, lng: 21.309011 },
    { lat: 48.752063, lng: 21.309527 },
    { lat: 48.751991, lng: 21.310013 },
    { lat: 48.751483, lng: 21.310861 },
    { lat: 48.751212, lng: 21.310972 },
    { lat: 48.750843, lng: 21.311035 },
    { lat: 48.750738, lng: 21.31112 },
    { lat: 48.750344, lng: 21.311744 },
    { lat: 48.750184, lng: 21.31185 },
    { lat: 48.749511, lng: 21.312166 },
    { lat: 48.749185, lng: 21.312474 },
    { lat: 48.749136, lng: 21.312518 },
    { lat: 48.7492272, lng: 21.3128763 },
    { lat: 48.7495661, lng: 21.3133666 },
    { lat: 48.7501653, lng: 21.3140499 },
    { lat: 48.7503388, lng: 21.3143495 },
    { lat: 48.7508016, lng: 21.3139187 },
    { lat: 48.7518624, lng: 21.3152743 },
    { lat: 48.7519962, lng: 21.3159369 },
    { lat: 48.7522138, lng: 21.3164328 },
    { lat: 48.7524439, lng: 21.3174193 },
    { lat: 48.7530483, lng: 21.3207916 },
    { lat: 48.7531178, lng: 21.3207893 },
    { lat: 48.7535695, lng: 21.3231424 },
    { lat: 48.75369, lng: 21.3234793 },
    { lat: 48.7539669, lng: 21.3233709 },
    { lat: 48.7546574, lng: 21.3233502 },
    { lat: 48.7550369, lng: 21.3232115 },
    { lat: 48.7554677, lng: 21.3227986 },
    { lat: 48.7556218, lng: 21.3225379 },
    { lat: 48.7561546, lng: 21.3218883 },
    { lat: 48.7567844, lng: 21.3213877 },
    { lat: 48.757021, lng: 21.3213603 },
    { lat: 48.7573006, lng: 21.3212321 },
    { lat: 48.7581, lng: 21.3206099 },
    { lat: 48.7585699, lng: 21.3204546 },
    { lat: 48.759581, lng: 21.320693 },
    { lat: 48.7597866, lng: 21.320677 },
    { lat: 48.7601703, lng: 21.3205291 },
    { lat: 48.7604598, lng: 21.3202069 },
    { lat: 48.7605528, lng: 21.3205618 },
    { lat: 48.7608625, lng: 21.3225387 },
    { lat: 48.7610977, lng: 21.3225046 },
    { lat: 48.7619274, lng: 21.3220351 },
    { lat: 48.7631118, lng: 21.3221631 },
    { lat: 48.7637141, lng: 21.3219865 },
    { lat: 48.7637958, lng: 21.3224491 },
    { lat: 48.7643254, lng: 21.3229521 },
    { lat: 48.7655945, lng: 21.3243605 },
    { lat: 48.7669176, lng: 21.3245 },
    { lat: 48.7683432, lng: 21.3247547 },
    { lat: 48.7684229, lng: 21.3246365 },
    { lat: 48.7692861, lng: 21.3216998 },
    { lat: 48.7691832, lng: 21.3205756 },
    { lat: 48.7700465, lng: 21.3204516 },
    { lat: 48.7700212, lng: 21.3200312 },
    { lat: 48.7707286, lng: 21.3194835 },
    { lat: 48.7709292, lng: 21.3197614 },
    { lat: 48.7712472, lng: 21.3201051 },
    { lat: 48.7716406, lng: 21.3202309 },
    { lat: 48.7718901, lng: 21.3201194 },
    { lat: 48.7720291, lng: 21.3192349 },
    { lat: 48.7720479, lng: 21.3187436 },
    { lat: 48.7722016, lng: 21.318357 },
    { lat: 48.7724048, lng: 21.3183365 },
    { lat: 48.7727269, lng: 21.3184016 },
    { lat: 48.7728744, lng: 21.3183097 },
    { lat: 48.7729603, lng: 21.3181074 },
    { lat: 48.7729613, lng: 21.3177277 },
    { lat: 48.7729236, lng: 21.317554 },
    { lat: 48.7725956, lng: 21.3168895 },
    { lat: 48.7731282, lng: 21.3161078 },
    { lat: 48.7734322, lng: 21.3157846 },
    { lat: 48.7738367, lng: 21.3155419 },
    { lat: 48.7745671, lng: 21.3156922 },
    { lat: 48.7749184, lng: 21.3156963 },
    { lat: 48.7753959, lng: 21.3155964 },
    { lat: 48.7765404, lng: 21.3151555 },
    { lat: 48.7770821, lng: 21.3148017 },
    { lat: 48.7781142, lng: 21.3142872 },
    { lat: 48.7783904, lng: 21.3140806 },
    { lat: 48.7789527, lng: 21.31344 },
    { lat: 48.7795562, lng: 21.3131843 },
    { lat: 48.7797531, lng: 21.313223 },
    { lat: 48.7801584, lng: 21.3134956 },
    { lat: 48.7806098, lng: 21.3136427 },
    { lat: 48.7813053, lng: 21.3136075 },
    { lat: 48.7813091, lng: 21.3134381 },
  ],
  Kechnec: [
    { lat: 48.547223, lng: 21.321157 },
    { lat: 48.5480756, lng: 21.3166116 },
    { lat: 48.5463763, lng: 21.3152129 },
    { lat: 48.5463789, lng: 21.3142619 },
    { lat: 48.5465845, lng: 21.3132951 },
    { lat: 48.5463651, lng: 21.3129625 },
    { lat: 48.5460397, lng: 21.312924 },
    { lat: 48.5462122, lng: 21.3119019 },
    { lat: 48.5458453, lng: 21.3116773 },
    { lat: 48.5463146, lng: 21.3102798 },
    { lat: 48.5465362, lng: 21.308804 },
    { lat: 48.5465814, lng: 21.308029 },
    { lat: 48.5467619, lng: 21.3070156 },
    { lat: 48.546942, lng: 21.3064267 },
    { lat: 48.5466186, lng: 21.3061912 },
    { lat: 48.546404, lng: 21.3058389 },
    { lat: 48.5463361, lng: 21.3047603 },
    { lat: 48.5464188, lng: 21.30397 },
    { lat: 48.5468391, lng: 21.3025861 },
    { lat: 48.5472544, lng: 21.3007917 },
    { lat: 48.5479321, lng: 21.2987026 },
    { lat: 48.5484902, lng: 21.296348 },
    { lat: 48.5486434, lng: 21.2955125 },
    { lat: 48.5493355, lng: 21.2959485 },
    { lat: 48.5500145, lng: 21.2959983 },
    { lat: 48.5504154, lng: 21.2954205 },
    { lat: 48.550601, lng: 21.2950119 },
    { lat: 48.5509802, lng: 21.2932141 },
    { lat: 48.5511994, lng: 21.2918525 },
    { lat: 48.5514543, lng: 21.2890858 },
    { lat: 48.5514426, lng: 21.2880472 },
    { lat: 48.5516681, lng: 21.287656 },
    { lat: 48.552761, lng: 21.2877663 },
    { lat: 48.5535154, lng: 21.2866086 },
    { lat: 48.5540806, lng: 21.28541 },
    { lat: 48.5546368, lng: 21.2856728 },
    { lat: 48.5551441, lng: 21.2834761 },
    { lat: 48.5550273, lng: 21.2833337 },
    { lat: 48.5550009, lng: 21.2831742 },
    { lat: 48.5550549, lng: 21.2828767 },
    { lat: 48.5551696, lng: 21.2807184 },
    { lat: 48.5553878, lng: 21.2790672 },
    { lat: 48.555383, lng: 21.2777091 },
    { lat: 48.5552842, lng: 21.2767327 },
    { lat: 48.5551995, lng: 21.2742071 },
    { lat: 48.5551039, lng: 21.2730429 },
    { lat: 48.5552054, lng: 21.2731214 },
    { lat: 48.5554466, lng: 21.2729528 },
    { lat: 48.5553804, lng: 21.2727319 },
    { lat: 48.555476, lng: 21.2725902 },
    { lat: 48.5555259, lng: 21.272602 },
    { lat: 48.555543, lng: 21.2727919 },
    { lat: 48.5556028, lng: 21.2728719 },
    { lat: 48.5558469, lng: 21.272496 },
    { lat: 48.5558825, lng: 21.27249 },
    { lat: 48.555953, lng: 21.2726957 },
    { lat: 48.5560488, lng: 21.2726598 },
    { lat: 48.5559998, lng: 21.2724935 },
    { lat: 48.5561971, lng: 21.2725038 },
    { lat: 48.5562352, lng: 21.2724258 },
    { lat: 48.5562236, lng: 21.2722032 },
    { lat: 48.5563499, lng: 21.2724597 },
    { lat: 48.556535, lng: 21.2725196 },
    { lat: 48.556553, lng: 21.2722984 },
    { lat: 48.5567115, lng: 21.2723999 },
    { lat: 48.5570325, lng: 21.2721551 },
    { lat: 48.5568155, lng: 21.2702998 },
    { lat: 48.5565256, lng: 21.2664585 },
    { lat: 48.5563694, lng: 21.2649028 },
    { lat: 48.5560622, lng: 21.2636994 },
    { lat: 48.5559649, lng: 21.2634657 },
    { lat: 48.5558209, lng: 21.2626307 },
    { lat: 48.5558022, lng: 21.2608642 },
    { lat: 48.5556861, lng: 21.2596205 },
    { lat: 48.5556852, lng: 21.2590895 },
    { lat: 48.5556453, lng: 21.2591008 },
    { lat: 48.5556275, lng: 21.2589706 },
    { lat: 48.5552756, lng: 21.2591275 },
    { lat: 48.5550748, lng: 21.2583093 },
    { lat: 48.5553205, lng: 21.2580259 },
    { lat: 48.5554433, lng: 21.2579633 },
    { lat: 48.5550227, lng: 21.2565914 },
    { lat: 48.5552805, lng: 21.2563796 },
    { lat: 48.5550007, lng: 21.2552671 },
    { lat: 48.5555, lng: 21.2550391 },
    { lat: 48.556438, lng: 21.2547613 },
    { lat: 48.5565387, lng: 21.25387 },
    { lat: 48.5564216, lng: 21.2517311 },
    { lat: 48.5565061, lng: 21.250178 },
    { lat: 48.5566088, lng: 21.246417 },
    { lat: 48.5565414, lng: 21.2446909 },
    { lat: 48.5565169, lng: 21.2421603 },
    { lat: 48.5565363, lng: 21.2383736 },
    { lat: 48.5564168, lng: 21.2360619 },
    { lat: 48.556068, lng: 21.2311545 },
    { lat: 48.5557237, lng: 21.2288132 },
    { lat: 48.5555481, lng: 21.2272618 },
    { lat: 48.5553133, lng: 21.2261626 },
    { lat: 48.5545384, lng: 21.2236984 },
    { lat: 48.55448, lng: 21.2233022 },
    { lat: 48.5545945, lng: 21.2221821 },
    { lat: 48.5546381, lng: 21.220946 },
    { lat: 48.5545521, lng: 21.2184873 },
    { lat: 48.5545321, lng: 21.218353 },
    { lat: 48.5543989, lng: 21.2183781 },
    { lat: 48.5544081, lng: 21.2165479 },
    { lat: 48.5544908, lng: 21.2165015 },
    { lat: 48.5544856, lng: 21.2150035 },
    { lat: 48.5545809, lng: 21.2134467 },
    { lat: 48.5544323, lng: 21.2135223 },
    { lat: 48.5536334, lng: 21.2139927 },
    { lat: 48.5534924, lng: 21.2140137 },
    { lat: 48.5524893, lng: 21.2140062 },
    { lat: 48.5513481, lng: 21.2138637 },
    { lat: 48.5508572, lng: 21.21404 },
    { lat: 48.5501914, lng: 21.2146632 },
    { lat: 48.5488731, lng: 21.2156575 },
    { lat: 48.5485291, lng: 21.2157345 },
    { lat: 48.5470669, lng: 21.2156599 },
    { lat: 48.5469331, lng: 21.2165986 },
    { lat: 48.5466984, lng: 21.2176195 },
    { lat: 48.545913, lng: 21.2206728 },
    { lat: 48.5448178, lng: 21.2245557 },
    { lat: 48.5445359, lng: 21.2257602 },
    { lat: 48.5443674, lng: 21.2268501 },
    { lat: 48.5443399, lng: 21.2278782 },
    { lat: 48.5440855, lng: 21.2291401 },
    { lat: 48.5439205, lng: 21.2312047 },
    { lat: 48.5437396, lng: 21.2319002 },
    { lat: 48.5434429, lng: 21.2325654 },
    { lat: 48.5434089, lng: 21.2332437 },
    { lat: 48.5435421, lng: 21.2339752 },
    { lat: 48.5435485, lng: 21.2374991 },
    { lat: 48.5435569, lng: 21.2378456 },
    { lat: 48.5435553, lng: 21.2380037 },
    { lat: 48.5435597, lng: 21.2385475 },
    { lat: 48.5437365, lng: 21.2388018 },
    { lat: 48.5439978, lng: 21.2399969 },
    { lat: 48.5441668, lng: 21.2408346 },
    { lat: 48.544216, lng: 21.2413319 },
    { lat: 48.5447013, lng: 21.2441927 },
    { lat: 48.5452557, lng: 21.246496 },
    { lat: 48.5451162, lng: 21.2471164 },
    { lat: 48.5448751, lng: 21.2474012 },
    { lat: 48.5438655, lng: 21.2477573 },
    { lat: 48.5436848, lng: 21.247992 },
    { lat: 48.5429139, lng: 21.2490774 },
    { lat: 48.5429564, lng: 21.2491404 },
    { lat: 48.5428355, lng: 21.2494537 },
    { lat: 48.5426267, lng: 21.249792 },
    { lat: 48.5427049, lng: 21.2499172 },
    { lat: 48.5422445, lng: 21.2504642 },
    { lat: 48.5423839, lng: 21.2508891 },
    { lat: 48.5423819, lng: 21.2510818 },
    { lat: 48.5422439, lng: 21.2515297 },
    { lat: 48.5421864, lng: 21.2518886 },
    { lat: 48.5421245, lng: 21.2528256 },
    { lat: 48.5421039, lng: 21.2539117 },
    { lat: 48.5421429, lng: 21.2546186 },
    { lat: 48.5420968, lng: 21.2546907 },
    { lat: 48.5421718, lng: 21.2549358 },
    { lat: 48.5421816, lng: 21.2553187 },
    { lat: 48.5421201, lng: 21.2565302 },
    { lat: 48.541952, lng: 21.2572861 },
    { lat: 48.5419318, lng: 21.2609467 },
    { lat: 48.5418969, lng: 21.2622178 },
    { lat: 48.5418124, lng: 21.2632764 },
    { lat: 48.5418031, lng: 21.2643535 },
    { lat: 48.5418642, lng: 21.2650556 },
    { lat: 48.5418482, lng: 21.2667967 },
    { lat: 48.5419324, lng: 21.266751 },
    { lat: 48.5420623, lng: 21.2674195 },
    { lat: 48.5419522, lng: 21.2677547 },
    { lat: 48.5421278, lng: 21.2685912 },
    { lat: 48.5422092, lng: 21.2723569 },
    { lat: 48.5419939, lng: 21.2751156 },
    { lat: 48.5417971, lng: 21.2767634 },
    { lat: 48.5415443, lng: 21.2814538 },
    { lat: 48.5415742, lng: 21.2820952 },
    { lat: 48.5415231, lng: 21.282297 },
    { lat: 48.5414566, lng: 21.2833159 },
    { lat: 48.5413201, lng: 21.2841121 },
    { lat: 48.5412259, lng: 21.2844152 },
    { lat: 48.5411163, lng: 21.2853526 },
    { lat: 48.5410437, lng: 21.2865027 },
    { lat: 48.5410153, lng: 21.2892375 },
    { lat: 48.5410318, lng: 21.2914635 },
    { lat: 48.5420303, lng: 21.291764 },
    { lat: 48.5392741, lng: 21.2983252 },
    { lat: 48.5377563, lng: 21.2978241 },
    { lat: 48.5375091, lng: 21.2978761 },
    { lat: 48.5373754, lng: 21.2980629 },
    { lat: 48.5352648, lng: 21.305693 },
    { lat: 48.5354966, lng: 21.3060264 },
    { lat: 48.5356241, lng: 21.3063985 },
    { lat: 48.5356918, lng: 21.307032 },
    { lat: 48.5356705, lng: 21.3074017 },
    { lat: 48.5354837, lng: 21.3081415 },
    { lat: 48.5353087, lng: 21.3086029 },
    { lat: 48.5351003, lng: 21.3082654 },
    { lat: 48.534821, lng: 21.3080685 },
    { lat: 48.5346269, lng: 21.3080532 },
    { lat: 48.534422, lng: 21.3081256 },
    { lat: 48.530418, lng: 21.311375 },
    { lat: 48.530467, lng: 21.311422 },
    { lat: 48.530593, lng: 21.311512 },
    { lat: 48.530663, lng: 21.311574 },
    { lat: 48.53071, lng: 21.311637 },
    { lat: 48.530824, lng: 21.311876 },
    { lat: 48.530871, lng: 21.312139 },
    { lat: 48.53093, lng: 21.312429 },
    { lat: 48.530928, lng: 21.312506 },
    { lat: 48.530883, lng: 21.312674 },
    { lat: 48.530858, lng: 21.312925 },
    { lat: 48.530853, lng: 21.313223 },
    { lat: 48.530861, lng: 21.313501 },
    { lat: 48.530919, lng: 21.313824 },
    { lat: 48.530949, lng: 21.313999 },
    { lat: 48.531011, lng: 21.314229 },
    { lat: 48.531065, lng: 21.314287 },
    { lat: 48.5312, lng: 21.314563 },
    { lat: 48.531314, lng: 21.314747 },
    { lat: 48.531527, lng: 21.314845 },
    { lat: 48.531664, lng: 21.314982 },
    { lat: 48.531916, lng: 21.315123 },
    { lat: 48.532078, lng: 21.315357 },
    { lat: 48.532263, lng: 21.315504 },
    { lat: 48.532431, lng: 21.315638 },
    { lat: 48.532628, lng: 21.315835 },
    { lat: 48.532866, lng: 21.315907 },
    { lat: 48.532988, lng: 21.315958 },
    { lat: 48.533156, lng: 21.316035 },
    { lat: 48.533319, lng: 21.316021 },
    { lat: 48.533504, lng: 21.315845 },
    { lat: 48.533574, lng: 21.315739 },
    { lat: 48.533597, lng: 21.315631 },
    { lat: 48.533538, lng: 21.315442 },
    { lat: 48.533371, lng: 21.315201 },
    { lat: 48.533362, lng: 21.315093 },
    { lat: 48.533407, lng: 21.314988 },
    { lat: 48.533475, lng: 21.314788 },
    { lat: 48.533561, lng: 21.314603 },
    { lat: 48.533621, lng: 21.314516 },
    { lat: 48.533676, lng: 21.314437 },
    { lat: 48.533789, lng: 21.314321 },
    { lat: 48.533888, lng: 21.314255 },
    { lat: 48.533954, lng: 21.314242 },
    { lat: 48.534071, lng: 21.314234 },
    { lat: 48.534155, lng: 21.314225 },
    { lat: 48.534314, lng: 21.314311 },
    { lat: 48.534449, lng: 21.314414 },
    { lat: 48.534607, lng: 21.31455 },
    { lat: 48.534807, lng: 21.314622 },
    { lat: 48.534863, lng: 21.314635 },
    { lat: 48.535031, lng: 21.314567 },
    { lat: 48.535216, lng: 21.314419 },
    { lat: 48.535409, lng: 21.314223 },
    { lat: 48.535573, lng: 21.314085 },
    { lat: 48.535749, lng: 21.31392 },
    { lat: 48.535854, lng: 21.313786 },
    { lat: 48.535953, lng: 21.313679 },
    { lat: 48.536223, lng: 21.313544 },
    { lat: 48.536336, lng: 21.313464 },
    { lat: 48.536476, lng: 21.31341 },
    { lat: 48.536556, lng: 21.31336 },
    { lat: 48.536697, lng: 21.313194 },
    { lat: 48.536779, lng: 21.312883 },
    { lat: 48.536808, lng: 21.312697 },
    { lat: 48.536873, lng: 21.312356 },
    { lat: 48.536902, lng: 21.312305 },
    { lat: 48.536976, lng: 21.312088 },
    { lat: 48.537049, lng: 21.311994 },
    { lat: 48.537096, lng: 21.311967 },
    { lat: 48.537145, lng: 21.311785 },
    { lat: 48.537071, lng: 21.311622 },
    { lat: 48.53709, lng: 21.311476 },
    { lat: 48.537099, lng: 21.31141 },
    { lat: 48.53715, lng: 21.311128 },
    { lat: 48.537202, lng: 21.310896 },
    { lat: 48.53723, lng: 21.310751 },
    { lat: 48.537262, lng: 21.310445 },
    { lat: 48.537303, lng: 21.31021 },
    { lat: 48.537383, lng: 21.310001 },
    { lat: 48.537427, lng: 21.309904 },
    { lat: 48.53746, lng: 21.309849 },
    { lat: 48.537659, lng: 21.309718 },
    { lat: 48.537786, lng: 21.309656 },
    { lat: 48.537939, lng: 21.309562 },
    { lat: 48.538156, lng: 21.309515 },
    { lat: 48.538259, lng: 21.309489 },
    { lat: 48.538435, lng: 21.30947 },
    { lat: 48.538556, lng: 21.30949 },
    { lat: 48.53876, lng: 21.309466 },
    { lat: 48.538906, lng: 21.309424 },
    { lat: 48.539075, lng: 21.309375 },
    { lat: 48.53914, lng: 21.30938 },
    { lat: 48.539263, lng: 21.309506 },
    { lat: 48.539404, lng: 21.309527 },
    { lat: 48.539478, lng: 21.309555 },
    { lat: 48.539682, lng: 21.3096 },
    { lat: 48.539835, lng: 21.309633 },
    { lat: 48.539995, lng: 21.309727 },
    { lat: 48.540179, lng: 21.309829 },
    { lat: 48.540252, lng: 21.309884 },
    { lat: 48.540346, lng: 21.310004 },
    { lat: 48.540396, lng: 21.310084 },
    { lat: 48.540464, lng: 21.310223 },
    { lat: 48.540513, lng: 21.310335 },
    { lat: 48.540576, lng: 21.310631 },
    { lat: 48.540584, lng: 21.31079 },
    { lat: 48.540565, lng: 21.311119 },
    { lat: 48.54053, lng: 21.311259 },
    { lat: 48.540469, lng: 21.311553 },
    { lat: 48.5404381, lng: 21.3116383 },
    { lat: 48.540304, lng: 21.311885 },
    { lat: 48.540194, lng: 21.312135 },
    { lat: 48.540083, lng: 21.312375 },
    { lat: 48.539955, lng: 21.312621 },
    { lat: 48.539867, lng: 21.312896 },
    { lat: 48.539752, lng: 21.313152 },
    { lat: 48.539618, lng: 21.313527 },
    { lat: 48.539597, lng: 21.313743 },
    { lat: 48.539567, lng: 21.314013 },
    { lat: 48.539539, lng: 21.314391 },
    { lat: 48.539497, lng: 21.314627 },
    { lat: 48.539477, lng: 21.314958 },
    { lat: 48.53944, lng: 21.315139 },
    { lat: 48.539386, lng: 21.315329 },
    { lat: 48.539353, lng: 21.315498 },
    { lat: 48.539312, lng: 21.315714 },
    { lat: 48.539292, lng: 21.315947 },
    { lat: 48.539286, lng: 21.316085 },
    { lat: 48.53927, lng: 21.316209 },
    { lat: 48.539241, lng: 21.316539 },
    { lat: 48.539207, lng: 21.31679 },
    { lat: 48.539149, lng: 21.317091 },
    { lat: 48.539151, lng: 21.317179 },
    { lat: 48.539217, lng: 21.317519 },
    { lat: 48.53926, lng: 21.317739 },
    { lat: 48.539437, lng: 21.317967 },
    { lat: 48.539526, lng: 21.318068 },
    { lat: 48.539726, lng: 21.318303 },
    { lat: 48.539904, lng: 21.31839 },
    { lat: 48.53999, lng: 21.31834 },
    { lat: 48.540131, lng: 21.318221 },
    { lat: 48.540329, lng: 21.318017 },
    { lat: 48.540543, lng: 21.317783 },
    { lat: 48.540771, lng: 21.317593 },
    { lat: 48.540898, lng: 21.317478 },
    { lat: 48.540988, lng: 21.317281 },
    { lat: 48.541188, lng: 21.317117 },
    { lat: 48.54134, lng: 21.317056 },
    { lat: 48.541531, lng: 21.316962 },
    { lat: 48.541705, lng: 21.3168 },
    { lat: 48.541862, lng: 21.316746 },
    { lat: 48.542008, lng: 21.316647 },
    { lat: 48.542181, lng: 21.316407 },
    { lat: 48.542383, lng: 21.316355 },
    { lat: 48.542644, lng: 21.3164 },
    { lat: 48.542802, lng: 21.316366 },
    { lat: 48.542909, lng: 21.316363 },
    { lat: 48.543081, lng: 21.316358 },
    { lat: 48.543234, lng: 21.316362 },
    { lat: 48.543407, lng: 21.316318 },
    { lat: 48.543582, lng: 21.31628 },
    { lat: 48.543715, lng: 21.316332 },
    { lat: 48.543848, lng: 21.316421 },
    { lat: 48.543931, lng: 21.316503 },
    { lat: 48.544013, lng: 21.316622 },
    { lat: 48.544138, lng: 21.316851 },
    { lat: 48.544278, lng: 21.31701 },
    { lat: 48.544336, lng: 21.317154 },
    { lat: 48.544325, lng: 21.317285 },
    { lat: 48.544323, lng: 21.317415 },
    { lat: 48.544296, lng: 21.317669 },
    { lat: 48.54422, lng: 21.317806 },
    { lat: 48.544267, lng: 21.318093 },
    { lat: 48.544322, lng: 21.318419 },
    { lat: 48.544346, lng: 21.318689 },
    { lat: 48.54441, lng: 21.318935 },
    { lat: 48.544548, lng: 21.319221 },
    { lat: 48.544769, lng: 21.319368 },
    { lat: 48.544862, lng: 21.319686 },
    { lat: 48.544959, lng: 21.319924 },
    { lat: 48.54506, lng: 21.320115 },
    { lat: 48.545219, lng: 21.320379 },
    { lat: 48.545345, lng: 21.320509 },
    { lat: 48.545509, lng: 21.320655 },
    { lat: 48.545678, lng: 21.320773 },
    { lat: 48.545851, lng: 21.320849 },
    { lat: 48.546039, lng: 21.320902 },
    { lat: 48.546234, lng: 21.32093 },
    { lat: 48.546457, lng: 21.320904 },
    { lat: 48.546624, lng: 21.320911 },
    { lat: 48.54682, lng: 21.320944 },
    { lat: 48.546992, lng: 21.321007 },
    { lat: 48.547101, lng: 21.321072 },
    { lat: 48.547223, lng: 21.321157 },
  ],
  Slanec: [
    { lat: 48.6210072, lng: 21.5004314 },
    { lat: 48.6211367, lng: 21.5005912 },
    { lat: 48.6215509, lng: 21.5009481 },
    { lat: 48.6221751, lng: 21.5016352 },
    { lat: 48.6223538, lng: 21.5022555 },
    { lat: 48.622514, lng: 21.5023069 },
    { lat: 48.6227092, lng: 21.5021749 },
    { lat: 48.6227842, lng: 21.5020359 },
    { lat: 48.6228625, lng: 21.5015951 },
    { lat: 48.622953, lng: 21.501693 },
    { lat: 48.6230633, lng: 21.5019614 },
    { lat: 48.6240152, lng: 21.5026633 },
    { lat: 48.6248866, lng: 21.5034608 },
    { lat: 48.6252561, lng: 21.503708 },
    { lat: 48.6245309, lng: 21.5065867 },
    { lat: 48.625637, lng: 21.5071673 },
    { lat: 48.6260443, lng: 21.5075533 },
    { lat: 48.6264169, lng: 21.5063938 },
    { lat: 48.6274013, lng: 21.5067761 },
    { lat: 48.6275625, lng: 21.5058066 },
    { lat: 48.6279098, lng: 21.5046609 },
    { lat: 48.6277691, lng: 21.5044239 },
    { lat: 48.6280107, lng: 21.5039051 },
    { lat: 48.627868, lng: 21.5036772 },
    { lat: 48.6280316, lng: 21.503266 },
    { lat: 48.6295545, lng: 21.5061684 },
    { lat: 48.6292504, lng: 21.5073749 },
    { lat: 48.6297123, lng: 21.507938 },
    { lat: 48.6293918, lng: 21.50869 },
    { lat: 48.6298472, lng: 21.5090714 },
    { lat: 48.629661, lng: 21.5093624 },
    { lat: 48.629448, lng: 21.5092369 },
    { lat: 48.6293134, lng: 21.5101471 },
    { lat: 48.6306604, lng: 21.5116369 },
    { lat: 48.6313425, lng: 21.5121604 },
    { lat: 48.6326696, lng: 21.5135392 },
    { lat: 48.6333061, lng: 21.5114098 },
    { lat: 48.6336351, lng: 21.5120539 },
    { lat: 48.63378, lng: 21.5120476 },
    { lat: 48.6338669, lng: 21.511938 },
    { lat: 48.6338861, lng: 21.5115476 },
    { lat: 48.6340618, lng: 21.5114027 },
    { lat: 48.6341379, lng: 21.5110306 },
    { lat: 48.6341318, lng: 21.5106621 },
    { lat: 48.6342085, lng: 21.5105101 },
    { lat: 48.6342012, lng: 21.510417 },
    { lat: 48.6340853, lng: 21.5102983 },
    { lat: 48.6340529, lng: 21.5101902 },
    { lat: 48.6340795, lng: 21.5099405 },
    { lat: 48.634156, lng: 21.5097391 },
    { lat: 48.6343466, lng: 21.5096359 },
    { lat: 48.6345751, lng: 21.5093342 },
    { lat: 48.6344998, lng: 21.5089781 },
    { lat: 48.634852, lng: 21.5086313 },
    { lat: 48.6350053, lng: 21.5084025 },
    { lat: 48.6350643, lng: 21.5086935 },
    { lat: 48.6352849, lng: 21.5089679 },
    { lat: 48.6352757, lng: 21.509124 },
    { lat: 48.6354333, lng: 21.509283 },
    { lat: 48.635527, lng: 21.5092118 },
    { lat: 48.6360459, lng: 21.5092981 },
    { lat: 48.6360985, lng: 21.5092818 },
    { lat: 48.6360026, lng: 21.5091632 },
    { lat: 48.6366969, lng: 21.509042 },
    { lat: 48.6372624, lng: 21.5092191 },
    { lat: 48.6384505, lng: 21.5093722 },
    { lat: 48.6399394, lng: 21.510081 },
    { lat: 48.6404823, lng: 21.509944 },
    { lat: 48.6414587, lng: 21.5101341 },
    { lat: 48.6418498, lng: 21.5100708 },
    { lat: 48.6418492, lng: 21.5103644 },
    { lat: 48.6420204, lng: 21.5103304 },
    { lat: 48.6424444, lng: 21.5100945 },
    { lat: 48.6435229, lng: 21.5099151 },
    { lat: 48.6439946, lng: 21.51004 },
    { lat: 48.6442428, lng: 21.5108414 },
    { lat: 48.6443423, lng: 21.5108588 },
    { lat: 48.6456148, lng: 21.5125365 },
    { lat: 48.6464182, lng: 21.5111268 },
    { lat: 48.6472026, lng: 21.5103531 },
    { lat: 48.6479531, lng: 21.5099581 },
    { lat: 48.6485141, lng: 21.5093132 },
    { lat: 48.6494174, lng: 21.508442 },
    { lat: 48.6502645, lng: 21.5079648 },
    { lat: 48.6519384, lng: 21.5071673 },
    { lat: 48.6525329, lng: 21.50664 },
    { lat: 48.6525226, lng: 21.5065923 },
    { lat: 48.6536879, lng: 21.5051654 },
    { lat: 48.6546839, lng: 21.5036523 },
    { lat: 48.6537208, lng: 21.5022806 },
    { lat: 48.6533399, lng: 21.5027347 },
    { lat: 48.6526094, lng: 21.5017126 },
    { lat: 48.6522254, lng: 21.5021317 },
    { lat: 48.6511729, lng: 21.5007542 },
    { lat: 48.6507746, lng: 21.5011761 },
    { lat: 48.6504109, lng: 21.50078 },
    { lat: 48.6500293, lng: 21.5012431 },
    { lat: 48.649829, lng: 21.5013221 },
    { lat: 48.6488846, lng: 21.5001746 },
    { lat: 48.647401, lng: 21.4979765 },
    { lat: 48.6473728, lng: 21.4978961 },
    { lat: 48.6474926, lng: 21.4974484 },
    { lat: 48.6475278, lng: 21.4973358 },
    { lat: 48.647607, lng: 21.4973103 },
    { lat: 48.6475788, lng: 21.4968503 },
    { lat: 48.647743, lng: 21.4967616 },
    { lat: 48.6478458, lng: 21.496779 },
    { lat: 48.6480194, lng: 21.4964353 },
    { lat: 48.6481172, lng: 21.4963947 },
    { lat: 48.6482895, lng: 21.4964403 },
    { lat: 48.6483374, lng: 21.4963859 },
    { lat: 48.6482363, lng: 21.4961175 },
    { lat: 48.6484159, lng: 21.495942 },
    { lat: 48.648569, lng: 21.4960258 },
    { lat: 48.6485893, lng: 21.4961843 },
    { lat: 48.6486913, lng: 21.4961729 },
    { lat: 48.6487886, lng: 21.4960303 },
    { lat: 48.6488711, lng: 21.4954565 },
    { lat: 48.6489892, lng: 21.4954139 },
    { lat: 48.6490473, lng: 21.4952891 },
    { lat: 48.6492795, lng: 21.4951076 },
    { lat: 48.6494564, lng: 21.49527 },
    { lat: 48.6494974, lng: 21.495247 },
    { lat: 48.6495381, lng: 21.4950618 },
    { lat: 48.6495238, lng: 21.494877 },
    { lat: 48.6496246, lng: 21.4947031 },
    { lat: 48.6497608, lng: 21.4946258 },
    { lat: 48.6497302, lng: 21.4945449 },
    { lat: 48.6497841, lng: 21.4944606 },
    { lat: 48.6500079, lng: 21.4942989 },
    { lat: 48.6499435, lng: 21.4940192 },
    { lat: 48.6500176, lng: 21.4937787 },
    { lat: 48.6503954, lng: 21.4938453 },
    { lat: 48.6505302, lng: 21.4937179 },
    { lat: 48.6506619, lng: 21.4936815 },
    { lat: 48.6508786, lng: 21.4937883 },
    { lat: 48.6511978, lng: 21.4937705 },
    { lat: 48.6511868, lng: 21.4931612 },
    { lat: 48.6513507, lng: 21.492559 },
    { lat: 48.6512303, lng: 21.4920153 },
    { lat: 48.6514521, lng: 21.4914726 },
    { lat: 48.65187, lng: 21.4915319 },
    { lat: 48.6519708, lng: 21.4914395 },
    { lat: 48.6519712, lng: 21.4913722 },
    { lat: 48.6518619, lng: 21.4912513 },
    { lat: 48.6519124, lng: 21.4910515 },
    { lat: 48.6519863, lng: 21.4909969 },
    { lat: 48.652216, lng: 21.491038 },
    { lat: 48.6522855, lng: 21.4909946 },
    { lat: 48.6523254, lng: 21.4908589 },
    { lat: 48.652538, lng: 21.4906616 },
    { lat: 48.6527041, lng: 21.4904097 },
    { lat: 48.6530777, lng: 21.4902168 },
    { lat: 48.6532256, lng: 21.4905451 },
    { lat: 48.6533247, lng: 21.4905881 },
    { lat: 48.6535927, lng: 21.4904398 },
    { lat: 48.6538397, lng: 21.4904606 },
    { lat: 48.65393, lng: 21.4905817 },
    { lat: 48.6540649, lng: 21.4905425 },
    { lat: 48.6541416, lng: 21.4904307 },
    { lat: 48.654383, lng: 21.4904113 },
    { lat: 48.6545695, lng: 21.4902822 },
    { lat: 48.654658, lng: 21.490113 },
    { lat: 48.6547681, lng: 21.4901136 },
    { lat: 48.6548568, lng: 21.4899843 },
    { lat: 48.6549684, lng: 21.4899542 },
    { lat: 48.6550104, lng: 21.4897336 },
    { lat: 48.6553719, lng: 21.4896699 },
    { lat: 48.6554599, lng: 21.4895164 },
    { lat: 48.6556442, lng: 21.4895139 },
    { lat: 48.6556971, lng: 21.4893118 },
    { lat: 48.6558117, lng: 21.4891893 },
    { lat: 48.6561156, lng: 21.4892376 },
    { lat: 48.6561841, lng: 21.4891121 },
    { lat: 48.6561719, lng: 21.4888449 },
    { lat: 48.6560705, lng: 21.4887299 },
    { lat: 48.6561817, lng: 21.4883677 },
    { lat: 48.6563187, lng: 21.4883583 },
    { lat: 48.6566532, lng: 21.4885996 },
    { lat: 48.6567395, lng: 21.4884694 },
    { lat: 48.6569632, lng: 21.4883123 },
    { lat: 48.6570578, lng: 21.4883541 },
    { lat: 48.6573613, lng: 21.4879896 },
    { lat: 48.6575352, lng: 21.4879379 },
    { lat: 48.6578122, lng: 21.4880969 },
    { lat: 48.6581066, lng: 21.4880465 },
    { lat: 48.658315, lng: 21.4881349 },
    { lat: 48.6584822, lng: 21.488036 },
    { lat: 48.6585693, lng: 21.4878601 },
    { lat: 48.658869, lng: 21.4877133 },
    { lat: 48.6590561, lng: 21.4877255 },
    { lat: 48.6591926, lng: 21.4875439 },
    { lat: 48.6595066, lng: 21.4874705 },
    { lat: 48.6596412, lng: 21.4873687 },
    { lat: 48.6596604, lng: 21.4871877 },
    { lat: 48.6599141, lng: 21.4872418 },
    { lat: 48.6599994, lng: 21.4871921 },
    { lat: 48.6600588, lng: 21.4869148 },
    { lat: 48.6601217, lng: 21.4869121 },
    { lat: 48.6601961, lng: 21.4871837 },
    { lat: 48.6603958, lng: 21.4871677 },
    { lat: 48.660563, lng: 21.4868346 },
    { lat: 48.66049, lng: 21.4864158 },
    { lat: 48.6605785, lng: 21.4863196 },
    { lat: 48.6607203, lng: 21.4863223 },
    { lat: 48.6608104, lng: 21.4862502 },
    { lat: 48.6608139, lng: 21.4857456 },
    { lat: 48.6611836, lng: 21.4853848 },
    { lat: 48.6613584, lng: 21.4855897 },
    { lat: 48.6616064, lng: 21.4855225 },
    { lat: 48.6617024, lng: 21.4853058 },
    { lat: 48.661892, lng: 21.4851669 },
    { lat: 48.6619562, lng: 21.484859 },
    { lat: 48.6623701, lng: 21.4843494 },
    { lat: 48.6624258, lng: 21.48413 },
    { lat: 48.6629014, lng: 21.4838132 },
    { lat: 48.6632897, lng: 21.483658 },
    { lat: 48.6632955, lng: 21.4835487 },
    { lat: 48.6632377, lng: 21.4834183 },
    { lat: 48.663298, lng: 21.4832844 },
    { lat: 48.6632959, lng: 21.4831322 },
    { lat: 48.6634394, lng: 21.4829223 },
    { lat: 48.6634652, lng: 21.4828053 },
    { lat: 48.6634285, lng: 21.4827009 },
    { lat: 48.6634717, lng: 21.4826379 },
    { lat: 48.6637068, lng: 21.4826726 },
    { lat: 48.6637464, lng: 21.4826059 },
    { lat: 48.6636895, lng: 21.4825053 },
    { lat: 48.6637159, lng: 21.4824399 },
    { lat: 48.6638213, lng: 21.4824746 },
    { lat: 48.6639091, lng: 21.4821591 },
    { lat: 48.6638599, lng: 21.4819747 },
    { lat: 48.6640229, lng: 21.4818134 },
    { lat: 48.6640442, lng: 21.4816198 },
    { lat: 48.6640096, lng: 21.481395 },
    { lat: 48.664124, lng: 21.4813821 },
    { lat: 48.6643208, lng: 21.4812238 },
    { lat: 48.6645128, lng: 21.481324 },
    { lat: 48.6646065, lng: 21.4812626 },
    { lat: 48.6646462, lng: 21.4811497 },
    { lat: 48.6646209, lng: 21.4809755 },
    { lat: 48.6648713, lng: 21.4809208 },
    { lat: 48.6649142, lng: 21.4808529 },
    { lat: 48.6648916, lng: 21.4807661 },
    { lat: 48.6649779, lng: 21.480717 },
    { lat: 48.6649771, lng: 21.4806145 },
    { lat: 48.6653087, lng: 21.4798226 },
    { lat: 48.6652483, lng: 21.4796419 },
    { lat: 48.6653555, lng: 21.4793774 },
    { lat: 48.6653091, lng: 21.4792979 },
    { lat: 48.6654157, lng: 21.4790887 },
    { lat: 48.6656315, lng: 21.4792342 },
    { lat: 48.6657229, lng: 21.4790534 },
    { lat: 48.6658575, lng: 21.4789968 },
    { lat: 48.6659249, lng: 21.4788802 },
    { lat: 48.6661559, lng: 21.4788863 },
    { lat: 48.6663192, lng: 21.478972 },
    { lat: 48.6663813, lng: 21.4787055 },
    { lat: 48.6664646, lng: 21.4787096 },
    { lat: 48.6665971, lng: 21.4788536 },
    { lat: 48.6666617, lng: 21.4787902 },
    { lat: 48.6666248, lng: 21.4786463 },
    { lat: 48.6667274, lng: 21.4785751 },
    { lat: 48.6668843, lng: 21.4785947 },
    { lat: 48.6668972, lng: 21.4783806 },
    { lat: 48.6668524, lng: 21.4782251 },
    { lat: 48.6668897, lng: 21.4779261 },
    { lat: 48.6669903, lng: 21.4778995 },
    { lat: 48.6669443, lng: 21.477631 },
    { lat: 48.6671409, lng: 21.4765739 },
    { lat: 48.6672478, lng: 21.4762663 },
    { lat: 48.6677388, lng: 21.475854 },
    { lat: 48.66957, lng: 21.4723387 },
    { lat: 48.6704527, lng: 21.4705419 },
    { lat: 48.6703963, lng: 21.4703067 },
    { lat: 48.6703302, lng: 21.4686902 },
    { lat: 48.6700077, lng: 21.4674264 },
    { lat: 48.6662785, lng: 21.466073 },
    { lat: 48.6657147, lng: 21.4654885 },
    { lat: 48.6653583, lng: 21.4652249 },
    { lat: 48.6646591, lng: 21.4645601 },
    { lat: 48.6640056, lng: 21.4645629 },
    { lat: 48.6634135, lng: 21.463981 },
    { lat: 48.6614011, lng: 21.4628435 },
    { lat: 48.6604469, lng: 21.4621096 },
    { lat: 48.6600838, lng: 21.4613514 },
    { lat: 48.6597562, lng: 21.4608079 },
    { lat: 48.6588794, lng: 21.4601828 },
    { lat: 48.6585771, lng: 21.4595843 },
    { lat: 48.6581409, lng: 21.4592036 },
    { lat: 48.6571938, lng: 21.4574881 },
    { lat: 48.6567401, lng: 21.4575595 },
    { lat: 48.6562467, lng: 21.4573048 },
    { lat: 48.6557542, lng: 21.4571621 },
    { lat: 48.6554525, lng: 21.4571195 },
    { lat: 48.6550628, lng: 21.4571856 },
    { lat: 48.6546197, lng: 21.4569261 },
    { lat: 48.6542049, lng: 21.4565932 },
    { lat: 48.6536212, lng: 21.4564544 },
    { lat: 48.6531204, lng: 21.4564052 },
    { lat: 48.6529681, lng: 21.4563569 },
    { lat: 48.6520235, lng: 21.4558013 },
    { lat: 48.6506507, lng: 21.4555667 },
    { lat: 48.6503694, lng: 21.4554566 },
    { lat: 48.6496485, lng: 21.4555109 },
    { lat: 48.649521, lng: 21.45565 },
    { lat: 48.6489575, lng: 21.4558117 },
    { lat: 48.6470482, lng: 21.4570547 },
    { lat: 48.6464028, lng: 21.4573442 },
    { lat: 48.6459475, lng: 21.4578228 },
    { lat: 48.6436551, lng: 21.4536107 },
    { lat: 48.6434119, lng: 21.4528729 },
    { lat: 48.642981, lng: 21.4524611 },
    { lat: 48.6427899, lng: 21.4525041 },
    { lat: 48.642676, lng: 21.4524569 },
    { lat: 48.6424834, lng: 21.4521018 },
    { lat: 48.641994, lng: 21.451648 },
    { lat: 48.6416166, lng: 21.4514746 },
    { lat: 48.6408912, lng: 21.4516097 },
    { lat: 48.6407012, lng: 21.4514584 },
    { lat: 48.6402831, lng: 21.4513194 },
    { lat: 48.6400261, lng: 21.4513852 },
    { lat: 48.6398343, lng: 21.4515599 },
    { lat: 48.6396908, lng: 21.4516096 },
    { lat: 48.6393296, lng: 21.4513854 },
    { lat: 48.6357977, lng: 21.4459824 },
    { lat: 48.6354857, lng: 21.4450056 },
    { lat: 48.6355406, lng: 21.4448853 },
    { lat: 48.6355189, lng: 21.4447537 },
    { lat: 48.6354705, lng: 21.4447608 },
    { lat: 48.635394, lng: 21.444604 },
    { lat: 48.6351938, lng: 21.4446846 },
    { lat: 48.6348792, lng: 21.4446597 },
    { lat: 48.6346534, lng: 21.4447524 },
    { lat: 48.6342207, lng: 21.4444205 },
    { lat: 48.6328216, lng: 21.4465982 },
    { lat: 48.632331, lng: 21.4472151 },
    { lat: 48.6307388, lng: 21.4459161 },
    { lat: 48.6294766, lng: 21.4446549 },
    { lat: 48.6196186, lng: 21.4390069 },
    { lat: 48.618555, lng: 21.4368192 },
    { lat: 48.6183291, lng: 21.4366341 },
    { lat: 48.6179012, lng: 21.439303 },
    { lat: 48.6165908, lng: 21.4474913 },
    { lat: 48.6140949, lng: 21.4625036 },
    { lat: 48.6078153, lng: 21.4587061 },
    { lat: 48.599167, lng: 21.4537612 },
    { lat: 48.5989344, lng: 21.4551212 },
    { lat: 48.5989178, lng: 21.4554404 },
    { lat: 48.5992826, lng: 21.4559836 },
    { lat: 48.599184, lng: 21.4563855 },
    { lat: 48.5986629, lng: 21.4560383 },
    { lat: 48.5987959, lng: 21.456466 },
    { lat: 48.5993746, lng: 21.457097 },
    { lat: 48.5995614, lng: 21.4565161 },
    { lat: 48.5997176, lng: 21.4566248 },
    { lat: 48.5999129, lng: 21.4569514 },
    { lat: 48.5999437, lng: 21.4571253 },
    { lat: 48.600136, lng: 21.4572334 },
    { lat: 48.6003109, lng: 21.4574415 },
    { lat: 48.6003155, lng: 21.457678 },
    { lat: 48.6013589, lng: 21.4589536 },
    { lat: 48.6015161, lng: 21.4588695 },
    { lat: 48.6017499, lng: 21.4589379 },
    { lat: 48.6019669, lng: 21.4587721 },
    { lat: 48.6021137, lng: 21.4588323 },
    { lat: 48.6021134, lng: 21.4588956 },
    { lat: 48.6028915, lng: 21.4586829 },
    { lat: 48.6038565, lng: 21.4587845 },
    { lat: 48.6038965, lng: 21.4588451 },
    { lat: 48.6040248, lng: 21.4588469 },
    { lat: 48.6041487, lng: 21.4590012 },
    { lat: 48.6043081, lng: 21.4589863 },
    { lat: 48.6044758, lng: 21.4587601 },
    { lat: 48.6046128, lng: 21.458827 },
    { lat: 48.6046687, lng: 21.4590043 },
    { lat: 48.6051009, lng: 21.4592789 },
    { lat: 48.6054794, lng: 21.4597146 },
    { lat: 48.6053379, lng: 21.4598744 },
    { lat: 48.6057726, lng: 21.4598871 },
    { lat: 48.6059535, lng: 21.4601161 },
    { lat: 48.6059707, lng: 21.4603966 },
    { lat: 48.6060202, lng: 21.4604683 },
    { lat: 48.6064765, lng: 21.4606084 },
    { lat: 48.6066557, lng: 21.4614633 },
    { lat: 48.606924, lng: 21.4617524 },
    { lat: 48.6069577, lng: 21.4620204 },
    { lat: 48.6069112, lng: 21.462235 },
    { lat: 48.6069544, lng: 21.462406 },
    { lat: 48.607161, lng: 21.462739 },
    { lat: 48.6074208, lng: 21.4634254 },
    { lat: 48.6074845, lng: 21.4634912 },
    { lat: 48.6077105, lng: 21.4642524 },
    { lat: 48.6080169, lng: 21.4649614 },
    { lat: 48.608116, lng: 21.4648819 },
    { lat: 48.6082576, lng: 21.464763 },
    { lat: 48.608473, lng: 21.4647761 },
    { lat: 48.6086412, lng: 21.4646807 },
    { lat: 48.6088126, lng: 21.4644215 },
    { lat: 48.6090488, lng: 21.4642675 },
    { lat: 48.6093254, lng: 21.4637983 },
    { lat: 48.609042, lng: 21.4634188 },
    { lat: 48.6092021, lng: 21.4628844 },
    { lat: 48.6093237, lng: 21.4618868 },
    { lat: 48.6094801, lng: 21.4620364 },
    { lat: 48.6097119, lng: 21.4618084 },
    { lat: 48.6097724, lng: 21.4616049 },
    { lat: 48.6101842, lng: 21.4609859 },
    { lat: 48.6103983, lng: 21.460807 },
    { lat: 48.6104937, lng: 21.4609091 },
    { lat: 48.6105314, lng: 21.4612402 },
    { lat: 48.6103784, lng: 21.4618936 },
    { lat: 48.6104079, lng: 21.4621395 },
    { lat: 48.6103242, lng: 21.4624704 },
    { lat: 48.609749, lng: 21.4621519 },
    { lat: 48.6096083, lng: 21.463004 },
    { lat: 48.6097137, lng: 21.4631819 },
    { lat: 48.6100871, lng: 21.4634032 },
    { lat: 48.6103385, lng: 21.4633495 },
    { lat: 48.6110196, lng: 21.4637407 },
    { lat: 48.6112399, lng: 21.4638886 },
    { lat: 48.6114675, lng: 21.464244 },
    { lat: 48.6117566, lng: 21.464206 },
    { lat: 48.6118356, lng: 21.4640172 },
    { lat: 48.6119685, lng: 21.4640554 },
    { lat: 48.6120121, lng: 21.4642015 },
    { lat: 48.6123342, lng: 21.4642911 },
    { lat: 48.613524, lng: 21.4643035 },
    { lat: 48.614133, lng: 21.4638499 },
    { lat: 48.6142311, lng: 21.4628551 },
    { lat: 48.6146025, lng: 21.4625791 },
    { lat: 48.6150003, lng: 21.4627579 },
    { lat: 48.6151682, lng: 21.4625739 },
    { lat: 48.6153219, lng: 21.4629414 },
    { lat: 48.6152119, lng: 21.4633356 },
    { lat: 48.6152085, lng: 21.4637185 },
    { lat: 48.6153418, lng: 21.4637783 },
    { lat: 48.615416, lng: 21.4645838 },
    { lat: 48.6153207, lng: 21.4649866 },
    { lat: 48.6152254, lng: 21.4649225 },
    { lat: 48.614993, lng: 21.4656389 },
    { lat: 48.6147338, lng: 21.4662354 },
    { lat: 48.61503, lng: 21.4666325 },
    { lat: 48.6155242, lng: 21.4667207 },
    { lat: 48.6160611, lng: 21.467189 },
    { lat: 48.6159279, lng: 21.4675545 },
    { lat: 48.6158416, lng: 21.4681118 },
    { lat: 48.6158724, lng: 21.4683602 },
    { lat: 48.6162861, lng: 21.4687698 },
    { lat: 48.6168879, lng: 21.4688032 },
    { lat: 48.6171136, lng: 21.4696286 },
    { lat: 48.6170138, lng: 21.4701473 },
    { lat: 48.6168832, lng: 21.4702609 },
    { lat: 48.6166237, lng: 21.4701582 },
    { lat: 48.6166424, lng: 21.4706876 },
    { lat: 48.6162979, lng: 21.4709145 },
    { lat: 48.616154, lng: 21.4706876 },
    { lat: 48.6159251, lng: 21.4706736 },
    { lat: 48.6156528, lng: 21.4700092 },
    { lat: 48.6150586, lng: 21.4691182 },
    { lat: 48.6146002, lng: 21.4690696 },
    { lat: 48.6143456, lng: 21.4692525 },
    { lat: 48.6144413, lng: 21.4694432 },
    { lat: 48.6143598, lng: 21.4696243 },
    { lat: 48.6139378, lng: 21.4698539 },
    { lat: 48.6136227, lng: 21.4698523 },
    { lat: 48.6135885, lng: 21.4697212 },
    { lat: 48.6133811, lng: 21.4704064 },
    { lat: 48.6138314, lng: 21.4706465 },
    { lat: 48.613791, lng: 21.4708785 },
    { lat: 48.6143878, lng: 21.4717458 },
    { lat: 48.6145574, lng: 21.4723573 },
    { lat: 48.6148299, lng: 21.4728638 },
    { lat: 48.6151352, lng: 21.4731448 },
    { lat: 48.6154795, lng: 21.4729911 },
    { lat: 48.6158865, lng: 21.4724724 },
    { lat: 48.6164424, lng: 21.4723961 },
    { lat: 48.6169381, lng: 21.4726496 },
    { lat: 48.6173539, lng: 21.4727505 },
    { lat: 48.6177354, lng: 21.4730571 },
    { lat: 48.6177964, lng: 21.4731839 },
    { lat: 48.6187, lng: 21.4730327 },
    { lat: 48.6190682, lng: 21.473189 },
    { lat: 48.6191636, lng: 21.4733712 },
    { lat: 48.6192179, lng: 21.4740803 },
    { lat: 48.6193519, lng: 21.4742756 },
    { lat: 48.619425, lng: 21.4745436 },
    { lat: 48.6192057, lng: 21.4754063 },
    { lat: 48.6191804, lng: 21.4763934 },
    { lat: 48.6189356, lng: 21.4768766 },
    { lat: 48.6189066, lng: 21.4772356 },
    { lat: 48.6190077, lng: 21.4775351 },
    { lat: 48.6190376, lng: 21.4779069 },
    { lat: 48.6188475, lng: 21.4782598 },
    { lat: 48.6188353, lng: 21.4784594 },
    { lat: 48.6189232, lng: 21.4789274 },
    { lat: 48.6189033, lng: 21.4792914 },
    { lat: 48.6188181, lng: 21.4796111 },
    { lat: 48.6187878, lng: 21.4801537 },
    { lat: 48.6189191, lng: 21.4811899 },
    { lat: 48.6192303, lng: 21.4816352 },
    { lat: 48.6193801, lng: 21.4835409 },
    { lat: 48.6192394, lng: 21.4837397 },
    { lat: 48.6190603, lng: 21.484354 },
    { lat: 48.6190621, lng: 21.4849183 },
    { lat: 48.6189746, lng: 21.485083 },
    { lat: 48.6188038, lng: 21.4851675 },
    { lat: 48.6187019, lng: 21.4855747 },
    { lat: 48.6190147, lng: 21.486467 },
    { lat: 48.6191615, lng: 21.4883526 },
    { lat: 48.6191077, lng: 21.488593 },
    { lat: 48.6191095, lng: 21.4890413 },
    { lat: 48.6193195, lng: 21.4893775 },
    { lat: 48.6193283, lng: 21.4898367 },
    { lat: 48.6195316, lng: 21.4899436 },
    { lat: 48.6194971, lng: 21.4901468 },
    { lat: 48.6192908, lng: 21.4901699 },
    { lat: 48.6192292, lng: 21.490259 },
    { lat: 48.6194442, lng: 21.4906976 },
    { lat: 48.6194952, lng: 21.490903 },
    { lat: 48.619484, lng: 21.4912181 },
    { lat: 48.6193339, lng: 21.4917021 },
    { lat: 48.6193734, lng: 21.4920156 },
    { lat: 48.6192357, lng: 21.4922776 },
    { lat: 48.6192202, lng: 21.4924443 },
    { lat: 48.6194026, lng: 21.4927979 },
    { lat: 48.6194181, lng: 21.4931774 },
    { lat: 48.6197021, lng: 21.4937797 },
    { lat: 48.6199271, lng: 21.49408 },
    { lat: 48.6201344, lng: 21.4942122 },
    { lat: 48.6205173, lng: 21.4938705 },
    { lat: 48.620683, lng: 21.4938842 },
    { lat: 48.6213814, lng: 21.4946079 },
    { lat: 48.6215053, lng: 21.4950038 },
    { lat: 48.6217517, lng: 21.4950032 },
    { lat: 48.6219563, lng: 21.4951061 },
    { lat: 48.6223384, lng: 21.4957962 },
    { lat: 48.6227026, lng: 21.4960554 },
    { lat: 48.6228644, lng: 21.4963193 },
    { lat: 48.6231807, lng: 21.4971365 },
    { lat: 48.6232889, lng: 21.4980424 },
    { lat: 48.6231485, lng: 21.4983984 },
    { lat: 48.6230624, lng: 21.4983806 },
    { lat: 48.6230406, lng: 21.4989486 },
    { lat: 48.6228747, lng: 21.4993311 },
    { lat: 48.6224594, lng: 21.5000612 },
    { lat: 48.6223816, lng: 21.5000233 },
    { lat: 48.6221882, lng: 21.5004958 },
    { lat: 48.6221058, lng: 21.5004813 },
    { lat: 48.6219982, lng: 21.5000705 },
    { lat: 48.6215697, lng: 21.5002743 },
    { lat: 48.6210072, lng: 21.5004314 },
  ],
  Drienovec: [
    { lat: 48.6506254, lng: 20.9165149 },
    { lat: 48.6502669, lng: 20.9161684 },
    { lat: 48.6500743, lng: 20.9160957 },
    { lat: 48.6495822, lng: 20.9161223 },
    { lat: 48.6491605, lng: 20.9164023 },
    { lat: 48.6476937, lng: 20.9153703 },
    { lat: 48.6472391, lng: 20.9149089 },
    { lat: 48.6463844, lng: 20.9145982 },
    { lat: 48.6447676, lng: 20.9147234 },
    { lat: 48.6439229, lng: 20.9143459 },
    { lat: 48.6435206, lng: 20.9140366 },
    { lat: 48.6428162, lng: 20.9137785 },
    { lat: 48.6420146, lng: 20.9135746 },
    { lat: 48.640491, lng: 20.9140517 },
    { lat: 48.638971, lng: 20.9138089 },
    { lat: 48.6362642, lng: 20.9153155 },
    { lat: 48.6349437, lng: 20.9157096 },
    { lat: 48.6331414, lng: 20.9146134 },
    { lat: 48.6317005, lng: 20.9151713 },
    { lat: 48.6279031, lng: 20.9164511 },
    { lat: 48.6268006, lng: 20.9160337 },
    { lat: 48.6217555, lng: 20.9113626 },
    { lat: 48.619772, lng: 20.9099664 },
    { lat: 48.6168833, lng: 20.9072144 },
    { lat: 48.6152362, lng: 20.9051507 },
    { lat: 48.6150534, lng: 20.9052068 },
    { lat: 48.6147855, lng: 20.9056025 },
    { lat: 48.6146421, lng: 20.9063873 },
    { lat: 48.6143631, lng: 20.9065202 },
    { lat: 48.614145, lng: 20.907028 },
    { lat: 48.6138656, lng: 20.9071172 },
    { lat: 48.6137664, lng: 20.9073134 },
    { lat: 48.6137679, lng: 20.9076545 },
    { lat: 48.6133694, lng: 20.9079247 },
    { lat: 48.6132019, lng: 20.9077277 },
    { lat: 48.6131287, lng: 20.9080585 },
    { lat: 48.6129536, lng: 20.9082016 },
    { lat: 48.612834, lng: 20.9080301 },
    { lat: 48.6127289, lng: 20.9082193 },
    { lat: 48.6127301, lng: 20.908614 },
    { lat: 48.6122211, lng: 20.9087131 },
    { lat: 48.6119539, lng: 20.9091603 },
    { lat: 48.6117638, lng: 20.9091327 },
    { lat: 48.611632, lng: 20.9095651 },
    { lat: 48.6114011, lng: 20.9095221 },
    { lat: 48.61123, lng: 20.9096861 },
    { lat: 48.6110648, lng: 20.9095933 },
    { lat: 48.6109707, lng: 20.9096425 },
    { lat: 48.6109027, lng: 20.9097666 },
    { lat: 48.6108868, lng: 20.910026 },
    { lat: 48.6106564, lng: 20.9100712 },
    { lat: 48.6105133, lng: 20.9098649 },
    { lat: 48.6104316, lng: 20.9099118 },
    { lat: 48.6102272, lng: 20.910358 },
    { lat: 48.6099362, lng: 20.9102435 },
    { lat: 48.6095794, lng: 20.9099392 },
    { lat: 48.6093309, lng: 20.91002 },
    { lat: 48.6092223, lng: 20.910002 },
    { lat: 48.6090446, lng: 20.9098418 },
    { lat: 48.6088133, lng: 20.9098231 },
    { lat: 48.6082709, lng: 20.9098955 },
    { lat: 48.6081727, lng: 20.9097545 },
    { lat: 48.6074507, lng: 20.9095399 },
    { lat: 48.6071802, lng: 20.9092361 },
    { lat: 48.6070723, lng: 20.9090047 },
    { lat: 48.6061192, lng: 20.9082869 },
    { lat: 48.6052479, lng: 20.9078929 },
    { lat: 48.6051885, lng: 20.9077969 },
    { lat: 48.60478, lng: 20.9077687 },
    { lat: 48.6047202, lng: 20.9076961 },
    { lat: 48.6044389, lng: 20.9076987 },
    { lat: 48.6043217, lng: 20.9078326 },
    { lat: 48.6039803, lng: 20.9078874 },
    { lat: 48.6037137, lng: 20.9078284 },
    { lat: 48.6036358, lng: 20.9079507 },
    { lat: 48.6032482, lng: 20.9079707 },
    { lat: 48.6030304, lng: 20.9078929 },
    { lat: 48.6028164, lng: 20.907918 },
    { lat: 48.6027541, lng: 20.9080089 },
    { lat: 48.6026196, lng: 20.9080264 },
    { lat: 48.6021391, lng: 20.9078949 },
    { lat: 48.6018798, lng: 20.9080195 },
    { lat: 48.6009889, lng: 20.9081726 },
    { lat: 48.6007831, lng: 20.9082736 },
    { lat: 48.6001654, lng: 20.9081833 },
    { lat: 48.6000225, lng: 20.9082836 },
    { lat: 48.599727, lng: 20.9082698 },
    { lat: 48.5994161, lng: 20.9083621 },
    { lat: 48.5992108, lng: 20.9085462 },
    { lat: 48.5985226, lng: 20.9088323 },
    { lat: 48.5984422, lng: 20.9087517 },
    { lat: 48.598265, lng: 20.9088746 },
    { lat: 48.5977813, lng: 20.9088842 },
    { lat: 48.5971561, lng: 20.9092985 },
    { lat: 48.5964042, lng: 20.9095639 },
    { lat: 48.595592, lng: 20.9097429 },
    { lat: 48.5954013, lng: 20.9096975 },
    { lat: 48.5950986, lng: 20.9097387 },
    { lat: 48.5942608, lng: 20.9101554 },
    { lat: 48.5937784, lng: 20.9103075 },
    { lat: 48.5936545, lng: 20.9102719 },
    { lat: 48.5936108, lng: 20.9102053 },
    { lat: 48.5936399, lng: 20.9103687 },
    { lat: 48.5937389, lng: 20.9104016 },
    { lat: 48.593967, lng: 20.9111155 },
    { lat: 48.5938367, lng: 20.9112603 },
    { lat: 48.5937294, lng: 20.911185 },
    { lat: 48.5936685, lng: 20.9112459 },
    { lat: 48.5934562, lng: 20.9117509 },
    { lat: 48.5935526, lng: 20.912104 },
    { lat: 48.5935303, lng: 20.9123415 },
    { lat: 48.5934286, lng: 20.912669 },
    { lat: 48.5934003, lng: 20.913222 },
    { lat: 48.5934938, lng: 20.9133922 },
    { lat: 48.5935846, lng: 20.9133443 },
    { lat: 48.5936708, lng: 20.9131037 },
    { lat: 48.5940162, lng: 20.9130632 },
    { lat: 48.5941486, lng: 20.9131493 },
    { lat: 48.5941914, lng: 20.9138465 },
    { lat: 48.5939962, lng: 20.9143648 },
    { lat: 48.593972, lng: 20.9147763 },
    { lat: 48.5941455, lng: 20.9150794 },
    { lat: 48.594248, lng: 20.9159455 },
    { lat: 48.5943217, lng: 20.9158929 },
    { lat: 48.5944126, lng: 20.9155545 },
    { lat: 48.5944828, lng: 20.9155316 },
    { lat: 48.5946684, lng: 20.9156617 },
    { lat: 48.5946948, lng: 20.9159402 },
    { lat: 48.5944625, lng: 20.916525 },
    { lat: 48.5943338, lng: 20.9164161 },
    { lat: 48.5941987, lng: 20.9165499 },
    { lat: 48.5941963, lng: 20.9167076 },
    { lat: 48.5942502, lng: 20.916802 },
    { lat: 48.5942151, lng: 20.917034 },
    { lat: 48.5941473, lng: 20.9170919 },
    { lat: 48.5942825, lng: 20.9174285 },
    { lat: 48.5942386, lng: 20.9177256 },
    { lat: 48.5942989, lng: 20.9179119 },
    { lat: 48.5942237, lng: 20.9182104 },
    { lat: 48.5941331, lng: 20.9182661 },
    { lat: 48.5939308, lng: 20.918734 },
    { lat: 48.5938253, lng: 20.9187809 },
    { lat: 48.5936778, lng: 20.9187453 },
    { lat: 48.5936157, lng: 20.9184706 },
    { lat: 48.5933572, lng: 20.9183533 },
    { lat: 48.5932496, lng: 20.9184359 },
    { lat: 48.5930283, lng: 20.9190738 },
    { lat: 48.5929201, lng: 20.9195369 },
    { lat: 48.5929622, lng: 20.9196503 },
    { lat: 48.5930691, lng: 20.919748 },
    { lat: 48.5931811, lng: 20.9196004 },
    { lat: 48.5933421, lng: 20.9195368 },
    { lat: 48.5934864, lng: 20.9197074 },
    { lat: 48.5934841, lng: 20.9201065 },
    { lat: 48.5934198, lng: 20.9203323 },
    { lat: 48.5932994, lng: 20.9204319 },
    { lat: 48.5931078, lng: 20.9208552 },
    { lat: 48.5929898, lng: 20.92086 },
    { lat: 48.5928491, lng: 20.9206614 },
    { lat: 48.5927138, lng: 20.9206231 },
    { lat: 48.5924796, lng: 20.9209228 },
    { lat: 48.5924931, lng: 20.9210853 },
    { lat: 48.5925989, lng: 20.9212834 },
    { lat: 48.5926853, lng: 20.9213432 },
    { lat: 48.5928556, lng: 20.9212874 },
    { lat: 48.5932384, lng: 20.9217249 },
    { lat: 48.5931948, lng: 20.9219341 },
    { lat: 48.5930181, lng: 20.9221273 },
    { lat: 48.5928558, lng: 20.922191 },
    { lat: 48.5925602, lng: 20.9219389 },
    { lat: 48.5924232, lng: 20.9219452 },
    { lat: 48.5924212, lng: 20.9221526 },
    { lat: 48.5923137, lng: 20.9223685 },
    { lat: 48.59209, lng: 20.9222499 },
    { lat: 48.591853, lng: 20.922292 },
    { lat: 48.591765, lng: 20.922425 },
    { lat: 48.5915086, lng: 20.9225973 },
    { lat: 48.5913663, lng: 20.9228817 },
    { lat: 48.5913337, lng: 20.923046 },
    { lat: 48.5913553, lng: 20.9234198 },
    { lat: 48.5915137, lng: 20.9236739 },
    { lat: 48.5915293, lng: 20.9238363 },
    { lat: 48.5915708, lng: 20.9243014 },
    { lat: 48.5915066, lng: 20.924892 },
    { lat: 48.5916849, lng: 20.9263418 },
    { lat: 48.5915103, lng: 20.9264985 },
    { lat: 48.5912731, lng: 20.9266067 },
    { lat: 48.5912525, lng: 20.9267637 },
    { lat: 48.5913865, lng: 20.927302 },
    { lat: 48.5915059, lng: 20.9273391 },
    { lat: 48.5918048, lng: 20.9270882 },
    { lat: 48.5919799, lng: 20.9270687 },
    { lat: 48.5921194, lng: 20.927373 },
    { lat: 48.5921202, lng: 20.9277943 },
    { lat: 48.591804, lng: 20.9283278 },
    { lat: 48.5916849, lng: 20.9287049 },
    { lat: 48.5914307, lng: 20.9290751 },
    { lat: 48.5912945, lng: 20.929135 },
    { lat: 48.5910404, lng: 20.9291129 },
    { lat: 48.5907726, lng: 20.9294179 },
    { lat: 48.5906521, lng: 20.9297451 },
    { lat: 48.5903693, lng: 20.9300036 },
    { lat: 48.5902629, lng: 20.9306536 },
    { lat: 48.5904846, lng: 20.9315187 },
    { lat: 48.5911053, lng: 20.9316673 },
    { lat: 48.591226, lng: 20.9318045 },
    { lat: 48.5912314, lng: 20.9319019 },
    { lat: 48.5911384, lng: 20.932277 },
    { lat: 48.5909308, lng: 20.932693 },
    { lat: 48.5906475, lng: 20.9335749 },
    { lat: 48.5905594, lng: 20.9343319 },
    { lat: 48.5904097, lng: 20.9346339 },
    { lat: 48.5901408, lng: 20.934705 },
    { lat: 48.5897591, lng: 20.9345988 },
    { lat: 48.5893097, lng: 20.9348612 },
    { lat: 48.5892079, lng: 20.9354784 },
    { lat: 48.5890072, lng: 20.9361648 },
    { lat: 48.5886902, lng: 20.9363814 },
    { lat: 48.5885299, lng: 20.9363998 },
    { lat: 48.5883798, lng: 20.9362911 },
    { lat: 48.5883519, lng: 20.9357934 },
    { lat: 48.5884618, lng: 20.93538 },
    { lat: 48.5884191, lng: 20.9352819 },
    { lat: 48.5883097, lng: 20.9352483 },
    { lat: 48.5875166, lng: 20.935226 },
    { lat: 48.5868543, lng: 20.9353813 },
    { lat: 48.5865131, lng: 20.9355099 },
    { lat: 48.5862482, lng: 20.9357336 },
    { lat: 48.5862457, lng: 20.9357917 },
    { lat: 48.5862532, lng: 20.9358815 },
    { lat: 48.5863539, lng: 20.9371323 },
    { lat: 48.5872277, lng: 20.9397418 },
    { lat: 48.5875175, lng: 20.9395838 },
    { lat: 48.5876802, lng: 20.9404723 },
    { lat: 48.5879202, lng: 20.941314 },
    { lat: 48.5882064, lng: 20.9419594 },
    { lat: 48.5888837, lng: 20.943916 },
    { lat: 48.5892486, lng: 20.94563 },
    { lat: 48.5899223, lng: 20.9501224 },
    { lat: 48.5905181, lng: 20.9524794 },
    { lat: 48.590785, lng: 20.9532175 },
    { lat: 48.5911554, lng: 20.9540194 },
    { lat: 48.5919553, lng: 20.9564569 },
    { lat: 48.5920814, lng: 20.9563915 },
    { lat: 48.5924509, lng: 20.9573728 },
    { lat: 48.5930237, lng: 20.9596147 },
    { lat: 48.5933427, lng: 20.9595264 },
    { lat: 48.5935955, lng: 20.9640411 },
    { lat: 48.5936001, lng: 20.9652551 },
    { lat: 48.5937377, lng: 20.9666083 },
    { lat: 48.5927038, lng: 20.9670287 },
    { lat: 48.5930419, lng: 20.9694898 },
    { lat: 48.5932362, lng: 20.9704543 },
    { lat: 48.5931806, lng: 20.9704778 },
    { lat: 48.5932296, lng: 20.9708986 },
    { lat: 48.5931889, lng: 20.9710905 },
    { lat: 48.5934869, lng: 20.9740501 },
    { lat: 48.5933756, lng: 20.9739511 },
    { lat: 48.5933683, lng: 20.9740025 },
    { lat: 48.5934241, lng: 20.9740552 },
    { lat: 48.5936532, lng: 20.9741704 },
    { lat: 48.5966556, lng: 20.9735419 },
    { lat: 48.6024365, lng: 20.9717804 },
    { lat: 48.603002, lng: 20.9755033 },
    { lat: 48.6032437, lng: 20.9765032 },
    { lat: 48.6033532, lng: 20.9766453 },
    { lat: 48.6034274, lng: 20.9765436 },
    { lat: 48.6051252, lng: 20.9759143 },
    { lat: 48.611227, lng: 20.9738918 },
    { lat: 48.6131223, lng: 20.9722645 },
    { lat: 48.6146498, lng: 20.9707535 },
    { lat: 48.6147547, lng: 20.9709924 },
    { lat: 48.6148585, lng: 20.970971 },
    { lat: 48.6148991, lng: 20.9711401 },
    { lat: 48.6150071, lng: 20.9713023 },
    { lat: 48.615277, lng: 20.971529 },
    { lat: 48.6154592, lng: 20.9715976 },
    { lat: 48.6155584, lng: 20.971806 },
    { lat: 48.6157266, lng: 20.9719544 },
    { lat: 48.6158082, lng: 20.9721723 },
    { lat: 48.6161987, lng: 20.9722675 },
    { lat: 48.6162929, lng: 20.9723894 },
    { lat: 48.61631, lng: 20.9727311 },
    { lat: 48.61646, lng: 20.9728083 },
    { lat: 48.6166755, lng: 20.9727919 },
    { lat: 48.6167081, lng: 20.9728929 },
    { lat: 48.6166659, lng: 20.9732458 },
    { lat: 48.6169642, lng: 20.9732801 },
    { lat: 48.6171195, lng: 20.9737186 },
    { lat: 48.6174156, lng: 20.9740899 },
    { lat: 48.6180774, lng: 20.9748287 },
    { lat: 48.618319, lng: 20.9748523 },
    { lat: 48.6185546, lng: 20.9751983 },
    { lat: 48.6187974, lng: 20.9752912 },
    { lat: 48.6192133, lng: 20.9758804 },
    { lat: 48.6193049, lng: 20.9758877 },
    { lat: 48.6194074, lng: 20.9760276 },
    { lat: 48.6194071, lng: 20.976151 },
    { lat: 48.6194653, lng: 20.9762761 },
    { lat: 48.6198539, lng: 20.9764586 },
    { lat: 48.6201302, lng: 20.9767062 },
    { lat: 48.6205184, lng: 20.9767703 },
    { lat: 48.6206209, lng: 20.9773111 },
    { lat: 48.6206981, lng: 20.9773611 },
    { lat: 48.6210571, lng: 20.9772293 },
    { lat: 48.6214444, lng: 20.9773632 },
    { lat: 48.6217139, lng: 20.9775853 },
    { lat: 48.6224459, lng: 20.9776941 },
    { lat: 48.6225716, lng: 20.9777863 },
    { lat: 48.622613, lng: 20.9781496 },
    { lat: 48.6225198, lng: 20.9783538 },
    { lat: 48.6224692, lng: 20.97876 },
    { lat: 48.6224982, lng: 20.9788219 },
    { lat: 48.622784, lng: 20.9790884 },
    { lat: 48.6228702, lng: 20.9793127 },
    { lat: 48.6230279, lng: 20.9794498 },
    { lat: 48.6234241, lng: 20.9792792 },
    { lat: 48.6234838, lng: 20.9793149 },
    { lat: 48.6235496, lng: 20.9797664 },
    { lat: 48.6235904, lng: 20.9798081 },
    { lat: 48.6241277, lng: 20.979894 },
    { lat: 48.6243167, lng: 20.980022 },
    { lat: 48.6246316, lng: 20.9801155 },
    { lat: 48.6248759, lng: 20.9799148 },
    { lat: 48.6249674, lng: 20.9799214 },
    { lat: 48.6250909, lng: 20.9801514 },
    { lat: 48.6253417, lng: 20.9799769 },
    { lat: 48.6255232, lng: 20.9802222 },
    { lat: 48.6256791, lng: 20.9802719 },
    { lat: 48.6257603, lng: 20.9801113 },
    { lat: 48.625812, lng: 20.9801035 },
    { lat: 48.6258525, lng: 20.9802722 },
    { lat: 48.6261447, lng: 20.9803611 },
    { lat: 48.6262966, lng: 20.9802197 },
    { lat: 48.6263665, lng: 20.9802222 },
    { lat: 48.6267273, lng: 20.980652 },
    { lat: 48.6268587, lng: 20.9804699 },
    { lat: 48.627221, lng: 20.980263 },
    { lat: 48.6273928, lng: 20.9802536 },
    { lat: 48.6275014, lng: 20.9803684 },
    { lat: 48.6278625, lng: 20.980362 },
    { lat: 48.6281618, lng: 20.9802265 },
    { lat: 48.6283336, lng: 20.9803501 },
    { lat: 48.6287271, lng: 20.9798637 },
    { lat: 48.6289864, lng: 20.9796401 },
    { lat: 48.6294583, lng: 20.9797516 },
    { lat: 48.6297143, lng: 20.9800293 },
    { lat: 48.6298549, lng: 20.9799575 },
    { lat: 48.6300781, lng: 20.9801099 },
    { lat: 48.63044, lng: 20.9801874 },
    { lat: 48.6309762, lng: 20.9801353 },
    { lat: 48.6313133, lng: 20.980425 },
    { lat: 48.6314739, lng: 20.980348 },
    { lat: 48.6321453, lng: 20.9803576 },
    { lat: 48.6324941, lng: 20.980624 },
    { lat: 48.6329247, lng: 20.9806429 },
    { lat: 48.6330871, lng: 20.9805822 },
    { lat: 48.6332157, lng: 20.9806635 },
    { lat: 48.6333692, lng: 20.9807088 },
    { lat: 48.6333498, lng: 20.9803612 },
    { lat: 48.6334362, lng: 20.9799465 },
    { lat: 48.6344022, lng: 20.9786789 },
    { lat: 48.6345948, lng: 20.9779034 },
    { lat: 48.6348413, lng: 20.9773309 },
    { lat: 48.6351948, lng: 20.9768934 },
    { lat: 48.6357113, lng: 20.9763826 },
    { lat: 48.6361009, lng: 20.9758787 },
    { lat: 48.6363095, lng: 20.9757272 },
    { lat: 48.6363962, lng: 20.9755523 },
    { lat: 48.6364375, lng: 20.974868 },
    { lat: 48.6365298, lng: 20.9743638 },
    { lat: 48.6364325, lng: 20.9741125 },
    { lat: 48.6364156, lng: 20.9737124 },
    { lat: 48.6363015, lng: 20.972958 },
    { lat: 48.6363476, lng: 20.9724467 },
    { lat: 48.6369155, lng: 20.9709561 },
    { lat: 48.636997, lng: 20.9704922 },
    { lat: 48.6371452, lng: 20.9700795 },
    { lat: 48.6373886, lng: 20.9695496 },
    { lat: 48.6374692, lng: 20.9688993 },
    { lat: 48.637948, lng: 20.9683685 },
    { lat: 48.6380311, lng: 20.9680974 },
    { lat: 48.6380441, lng: 20.967506 },
    { lat: 48.6381446, lng: 20.9666734 },
    { lat: 48.6382453, lng: 20.9662568 },
    { lat: 48.6381979, lng: 20.9651749 },
    { lat: 48.638457, lng: 20.9636344 },
    { lat: 48.6388472, lng: 20.9630523 },
    { lat: 48.6389703, lng: 20.9621571 },
    { lat: 48.6399163, lng: 20.9595235 },
    { lat: 48.6404349, lng: 20.9591408 },
    { lat: 48.6412475, lng: 20.9577616 },
    { lat: 48.641279, lng: 20.9574563 },
    { lat: 48.6414009, lng: 20.9570704 },
    { lat: 48.6414735, lng: 20.9564776 },
    { lat: 48.6415765, lng: 20.9561313 },
    { lat: 48.6416415, lng: 20.9555782 },
    { lat: 48.6418528, lng: 20.9550712 },
    { lat: 48.6419403, lng: 20.9545253 },
    { lat: 48.6422606, lng: 20.9540381 },
    { lat: 48.6423821, lng: 20.9537025 },
    { lat: 48.6424052, lng: 20.9534569 },
    { lat: 48.6422853, lng: 20.9527296 },
    { lat: 48.6423493, lng: 20.952256 },
    { lat: 48.6428052, lng: 20.9512512 },
    { lat: 48.6432108, lng: 20.9508276 },
    { lat: 48.6435309, lng: 20.9502358 },
    { lat: 48.6438205, lng: 20.9495117 },
    { lat: 48.6441001, lng: 20.9484207 },
    { lat: 48.644287, lng: 20.9464448 },
    { lat: 48.645049, lng: 20.945476 },
    { lat: 48.6452081, lng: 20.9450523 },
    { lat: 48.6460086, lng: 20.9434885 },
    { lat: 48.6461727, lng: 20.9430449 },
    { lat: 48.6463145, lng: 20.9414414 },
    { lat: 48.6467067, lng: 20.93945 },
    { lat: 48.6468812, lng: 20.9387838 },
    { lat: 48.6477504, lng: 20.9365532 },
    { lat: 48.648151, lng: 20.9348416 },
    { lat: 48.6485193, lng: 20.9339162 },
    { lat: 48.6487085, lng: 20.9336521 },
    { lat: 48.6490743, lng: 20.9328893 },
    { lat: 48.6491543, lng: 20.9319896 },
    { lat: 48.6490779, lng: 20.9315637 },
    { lat: 48.6490516, lng: 20.9310763 },
    { lat: 48.6491227, lng: 20.9304525 },
    { lat: 48.6497883, lng: 20.9287609 },
    { lat: 48.6507171, lng: 20.9269383 },
    { lat: 48.6509157, lng: 20.9260153 },
    { lat: 48.6511357, lng: 20.9253661 },
    { lat: 48.651251, lng: 20.9245826 },
    { lat: 48.6511174, lng: 20.9229977 },
    { lat: 48.6511275, lng: 20.9224319 },
    { lat: 48.6513401, lng: 20.9216957 },
    { lat: 48.6517712, lng: 20.9208298 },
    { lat: 48.6518286, lng: 20.9201358 },
    { lat: 48.6516765, lng: 20.9196624 },
    { lat: 48.6515003, lng: 20.9193445 },
    { lat: 48.6512331, lng: 20.9190657 },
    { lat: 48.6510559, lng: 20.9187241 },
    { lat: 48.6508892, lng: 20.9177569 },
    { lat: 48.6507456, lng: 20.9174034 },
    { lat: 48.6506128, lng: 20.9165775 },
    { lat: 48.6506254, lng: 20.9165149 },
  ],
  Janík: [
    { lat: 48.5557205, lng: 21.0065372 },
    { lat: 48.5558232, lng: 21.0063707 },
    { lat: 48.5559923, lng: 21.0062496 },
    { lat: 48.5563191, lng: 21.0061518 },
    { lat: 48.5564641, lng: 21.005879 },
    { lat: 48.5564704, lng: 21.0057282 },
    { lat: 48.5563397, lng: 21.0055562 },
    { lat: 48.5566187, lng: 21.0046668 },
    { lat: 48.5567002, lng: 21.003832 },
    { lat: 48.5568157, lng: 21.0035465 },
    { lat: 48.5568868, lng: 21.0009776 },
    { lat: 48.5571906, lng: 21.000028 },
    { lat: 48.5573335, lng: 20.99982 },
    { lat: 48.5579244, lng: 20.9995807 },
    { lat: 48.5592318, lng: 20.998856 },
    { lat: 48.559504, lng: 20.998139 },
    { lat: 48.5598691, lng: 20.9971378 },
    { lat: 48.5600164, lng: 20.9962199 },
    { lat: 48.560105, lng: 20.996038 },
    { lat: 48.5610164, lng: 20.992985 },
    { lat: 48.5610668, lng: 20.9924627 },
    { lat: 48.561565, lng: 20.9903914 },
    { lat: 48.5622735, lng: 20.9897291 },
    { lat: 48.5633326, lng: 20.9884788 },
    { lat: 48.563464, lng: 20.9879266 },
    { lat: 48.564337, lng: 20.9854852 },
    { lat: 48.5669064, lng: 20.9848998 },
    { lat: 48.567189, lng: 20.9847664 },
    { lat: 48.5684344, lng: 20.9847457 },
    { lat: 48.5700412, lng: 20.9793315 },
    { lat: 48.5701573, lng: 20.9781189 },
    { lat: 48.5700833, lng: 20.977558 },
    { lat: 48.5701017, lng: 20.9774027 },
    { lat: 48.570216, lng: 20.9770525 },
    { lat: 48.5706075, lng: 20.9765362 },
    { lat: 48.5708111, lng: 20.9760641 },
    { lat: 48.5711469, lng: 20.9748284 },
    { lat: 48.5711513, lng: 20.9736818 },
    { lat: 48.5712641, lng: 20.9724076 },
    { lat: 48.5714451, lng: 20.971362 },
    { lat: 48.5714821, lng: 20.9707529 },
    { lat: 48.5714838, lng: 20.9707005 },
    { lat: 48.5713102, lng: 20.9706572 },
    { lat: 48.5711726, lng: 20.9705482 },
    { lat: 48.5708679, lng: 20.9700581 },
    { lat: 48.5704122, lng: 20.969157 },
    { lat: 48.5692471, lng: 20.9674052 },
    { lat: 48.5676741, lng: 20.9647702 },
    { lat: 48.5677035, lng: 20.9647111 },
    { lat: 48.5673276, lng: 20.964439 },
    { lat: 48.5675033, lng: 20.9538174 },
    { lat: 48.5673506, lng: 20.95239 },
    { lat: 48.567277, lng: 20.9508695 },
    { lat: 48.567201, lng: 20.9505534 },
    { lat: 48.5671743, lng: 20.9495901 },
    { lat: 48.567264, lng: 20.9482488 },
    { lat: 48.5671211, lng: 20.9480479 },
    { lat: 48.5669588, lng: 20.9474383 },
    { lat: 48.5670577, lng: 20.9471297 },
    { lat: 48.5669545, lng: 20.9465754 },
    { lat: 48.5666729, lng: 20.9460006 },
    { lat: 48.566478, lng: 20.9453985 },
    { lat: 48.5661488, lng: 20.9450847 },
    { lat: 48.5658452, lng: 20.9450222 },
    { lat: 48.5656888, lng: 20.9448963 },
    { lat: 48.5655925, lng: 20.9441385 },
    { lat: 48.565587, lng: 20.9437789 },
    { lat: 48.5657286, lng: 20.9435701 },
    { lat: 48.5652644, lng: 20.9433267 },
    { lat: 48.5650281, lng: 20.943692 },
    { lat: 48.5648882, lng: 20.9435861 },
    { lat: 48.5646544, lng: 20.9435283 },
    { lat: 48.5641352, lng: 20.9437511 },
    { lat: 48.5638846, lng: 20.9437283 },
    { lat: 48.5635933, lng: 20.9438679 },
    { lat: 48.5632384, lng: 20.9438279 },
    { lat: 48.5622533, lng: 20.9439868 },
    { lat: 48.5613712, lng: 20.9442369 },
    { lat: 48.5597951, lng: 20.9441336 },
    { lat: 48.5593263, lng: 20.9441862 },
    { lat: 48.5589373, lng: 20.9445624 },
    { lat: 48.558346, lng: 20.9452913 },
    { lat: 48.5579022, lng: 20.9456788 },
    { lat: 48.5574543, lng: 20.94568 },
    { lat: 48.5562126, lng: 20.9453788 },
    { lat: 48.5549079, lng: 20.9456428 },
    { lat: 48.5525085, lng: 20.9467991 },
    { lat: 48.552011, lng: 20.9471327 },
    { lat: 48.551944, lng: 20.9471115 },
    { lat: 48.5518339, lng: 20.947353 },
    { lat: 48.5506568, lng: 20.9475318 },
    { lat: 48.5503759, lng: 20.9477146 },
    { lat: 48.5494283, lng: 20.947684 },
    { lat: 48.547575, lng: 20.9470057 },
    { lat: 48.5453811, lng: 20.9457325 },
    { lat: 48.5444244, lng: 20.9450067 },
    { lat: 48.5440893, lng: 20.9445481 },
    { lat: 48.5439959, lng: 20.9441148 },
    { lat: 48.5429258, lng: 20.9417992 },
    { lat: 48.542892, lng: 20.941726 },
    { lat: 48.542599, lng: 20.94166 },
    { lat: 48.542114, lng: 20.941538 },
    { lat: 48.541823, lng: 20.941496 },
    { lat: 48.541427, lng: 20.941445 },
    { lat: 48.540383, lng: 20.941395 },
    { lat: 48.539359, lng: 20.94131 },
    { lat: 48.53795, lng: 20.94117 },
    { lat: 48.536703, lng: 20.941085 },
    { lat: 48.535691, lng: 20.941012 },
    { lat: 48.53502, lng: 20.940934 },
    { lat: 48.534563, lng: 20.940939 },
    { lat: 48.534535, lng: 20.940982 },
    { lat: 48.53479, lng: 20.941438 },
    { lat: 48.534863, lng: 20.941578 },
    { lat: 48.535031, lng: 20.941846 },
    { lat: 48.535116, lng: 20.942059 },
    { lat: 48.535264, lng: 20.942398 },
    { lat: 48.535339, lng: 20.942643 },
    { lat: 48.535375, lng: 20.942795 },
    { lat: 48.535556, lng: 20.943133 },
    { lat: 48.535627, lng: 20.943362 },
    { lat: 48.53581, lng: 20.943843 },
    { lat: 48.535781, lng: 20.943862 },
    { lat: 48.535925, lng: 20.944761 },
    { lat: 48.536139, lng: 20.945219 },
    { lat: 48.536399, lng: 20.945638 },
    { lat: 48.535856, lng: 20.947894 },
    { lat: 48.535506, lng: 20.949387 },
    { lat: 48.534727, lng: 20.9525666 },
    { lat: 48.5346139, lng: 20.9531013 },
    { lat: 48.534472, lng: 20.953755 },
    { lat: 48.533963, lng: 20.955882 },
    { lat: 48.532074, lng: 20.955859 },
    { lat: 48.5313339, lng: 20.9557979 },
    { lat: 48.5312316, lng: 20.9558335 },
    { lat: 48.5309959, lng: 20.9558639 },
    { lat: 48.5294469, lng: 20.9561229 },
    { lat: 48.5280819, lng: 20.9559109 },
    { lat: 48.5275169, lng: 20.9558829 },
    { lat: 48.5270439, lng: 20.9558589 },
    { lat: 48.5264849, lng: 20.9558779 },
    { lat: 48.5260989, lng: 20.9558189 },
    { lat: 48.5249579, lng: 20.9560179 },
    { lat: 48.5235199, lng: 20.9561619 },
    { lat: 48.5216109, lng: 20.9561819 },
    { lat: 48.5210049, lng: 20.9584069 },
    { lat: 48.5208589, lng: 20.9602079 },
    { lat: 48.5205899, lng: 20.9630149 },
    { lat: 48.5203729, lng: 20.9653749 },
    { lat: 48.521449, lng: 20.9683585 },
    { lat: 48.5220539, lng: 20.9698839 },
    { lat: 48.5225359, lng: 20.9711969 },
    { lat: 48.5228299, lng: 20.9719389 },
    { lat: 48.5230109, lng: 20.9724319 },
    { lat: 48.5233199, lng: 20.9732129 },
    { lat: 48.5240699, lng: 20.9751909 },
    { lat: 48.5244429, lng: 20.9760869 },
    { lat: 48.5234539, lng: 20.9769109 },
    { lat: 48.5226889, lng: 20.9775559 },
    { lat: 48.5220999, lng: 20.9780419 },
    { lat: 48.5217619, lng: 20.9783069 },
    { lat: 48.5214919, lng: 20.9785439 },
    { lat: 48.5204559, lng: 20.9794259 },
    { lat: 48.5191529, lng: 20.9804149 },
    { lat: 48.5184939, lng: 20.9809509 },
    { lat: 48.5177119, lng: 20.9815689 },
    { lat: 48.5181479, lng: 20.9831849 },
    { lat: 48.5189159, lng: 20.9848789 },
    { lat: 48.5190989, lng: 20.9850799 },
    { lat: 48.5200519, lng: 20.9869059 },
    { lat: 48.5201579, lng: 20.9872189 },
    { lat: 48.520351, lng: 20.987444 },
    { lat: 48.520738, lng: 20.987991 },
    { lat: 48.521198, lng: 20.988651 },
    { lat: 48.521741, lng: 20.989327 },
    { lat: 48.523375, lng: 20.99014 },
    { lat: 48.524387, lng: 20.989621 },
    { lat: 48.525154, lng: 20.989135 },
    { lat: 48.526378, lng: 20.990586 },
    { lat: 48.526742, lng: 20.991526 },
    { lat: 48.52685, lng: 20.991716 },
    { lat: 48.526983, lng: 20.992166 },
    { lat: 48.52702, lng: 20.992244 },
    { lat: 48.527042, lng: 20.992405 },
    { lat: 48.527227, lng: 20.99473 },
    { lat: 48.527396, lng: 20.996909 },
    { lat: 48.527402, lng: 20.998306 },
    { lat: 48.527407, lng: 20.999641 },
    { lat: 48.527351, lng: 21.001981 },
    { lat: 48.527465, lng: 21.002766 },
    { lat: 48.5283282, lng: 21.0029284 },
    { lat: 48.5304903, lng: 21.0047852 },
    { lat: 48.5318119, lng: 21.00526 },
    { lat: 48.5327753, lng: 21.0053984 },
    { lat: 48.535833, lng: 21.0061272 },
    { lat: 48.5363955, lng: 21.0061825 },
    { lat: 48.5368047, lng: 21.0061287 },
    { lat: 48.5375381, lng: 21.0056767 },
    { lat: 48.5378758, lng: 21.0056115 },
    { lat: 48.53832, lng: 21.0056264 },
    { lat: 48.5386722, lng: 21.004946 },
    { lat: 48.5389764, lng: 21.0045185 },
    { lat: 48.5392336, lng: 21.0043301 },
    { lat: 48.5393407, lng: 21.0041313 },
    { lat: 48.5397469, lng: 21.0037917 },
    { lat: 48.5398115, lng: 21.0035978 },
    { lat: 48.5402314, lng: 21.002989 },
    { lat: 48.5403684, lng: 21.0029453 },
    { lat: 48.5406569, lng: 21.0022782 },
    { lat: 48.5410798, lng: 21.0016872 },
    { lat: 48.5419836, lng: 21.0008064 },
    { lat: 48.542109, lng: 21.000825 },
    { lat: 48.5426023, lng: 21.0002884 },
    { lat: 48.5428434, lng: 20.9998553 },
    { lat: 48.5432169, lng: 20.9994424 },
    { lat: 48.5432591, lng: 20.9994609 },
    { lat: 48.5433645, lng: 20.9992771 },
    { lat: 48.545045, lng: 20.9999199 },
    { lat: 48.5461134, lng: 20.999894 },
    { lat: 48.5464513, lng: 20.999962 },
    { lat: 48.5463561, lng: 21.0003208 },
    { lat: 48.5463068, lng: 21.0008652 },
    { lat: 48.5470329, lng: 21.0010748 },
    { lat: 48.5471492, lng: 21.000742 },
    { lat: 48.5474912, lng: 21.0017916 },
    { lat: 48.5475015, lng: 21.0020303 },
    { lat: 48.5475935, lng: 21.002429 },
    { lat: 48.5477705, lng: 21.0028779 },
    { lat: 48.5479064, lng: 21.0031725 },
    { lat: 48.5484843, lng: 21.0037631 },
    { lat: 48.5488907, lng: 21.0038685 },
    { lat: 48.5498119, lng: 21.0039157 },
    { lat: 48.5499264, lng: 21.0039745 },
    { lat: 48.550063, lng: 21.0043931 },
    { lat: 48.5502944, lng: 21.0046151 },
    { lat: 48.551363, lng: 21.0049315 },
    { lat: 48.5529262, lng: 21.0051594 },
    { lat: 48.5543583, lng: 21.0056815 },
    { lat: 48.5556446, lng: 21.0065163 },
    { lat: 48.5557205, lng: 21.0065372 },
  ],
  DvorníkyVčeláre: [
    { lat: 48.600322, lng: 20.810557 },
    { lat: 48.600316, lng: 20.810532 },
    { lat: 48.600273, lng: 20.810333 },
    { lat: 48.600238, lng: 20.810049 },
    { lat: 48.600057, lng: 20.80938 },
    { lat: 48.600025, lng: 20.80922 },
    { lat: 48.600039, lng: 20.8088 },
    { lat: 48.600045, lng: 20.808689 },
    { lat: 48.600051, lng: 20.808517 },
    { lat: 48.60006, lng: 20.808359 },
    { lat: 48.600071, lng: 20.808257 },
    { lat: 48.600093, lng: 20.808165 },
    { lat: 48.600164, lng: 20.807918 },
    { lat: 48.600196, lng: 20.807853 },
    { lat: 48.600284, lng: 20.807678 },
    { lat: 48.600325, lng: 20.807605 },
    { lat: 48.600362, lng: 20.807534 },
    { lat: 48.600413, lng: 20.807432 },
    { lat: 48.600612, lng: 20.807124 },
    { lat: 48.600732, lng: 20.806943 },
    { lat: 48.60084, lng: 20.806773 },
    { lat: 48.600957, lng: 20.806589 },
    { lat: 48.601026, lng: 20.806512 },
    { lat: 48.601077, lng: 20.806455 },
    { lat: 48.601171, lng: 20.806353 },
    { lat: 48.601214, lng: 20.806308 },
    { lat: 48.601261, lng: 20.806263 },
    { lat: 48.601374, lng: 20.80615 },
    { lat: 48.601398, lng: 20.806127 },
    { lat: 48.601618, lng: 20.805948 },
    { lat: 48.601788, lng: 20.805792 },
    { lat: 48.601908, lng: 20.80568 },
    { lat: 48.602127, lng: 20.805304 },
    { lat: 48.602158, lng: 20.805294 },
    { lat: 48.602295, lng: 20.805014 },
    { lat: 48.602477, lng: 20.804573 },
    { lat: 48.602624, lng: 20.803823 },
    { lat: 48.602741, lng: 20.803143 },
    { lat: 48.602857, lng: 20.802482 },
    { lat: 48.603009, lng: 20.801585 },
    { lat: 48.603124, lng: 20.800993 },
    { lat: 48.603217, lng: 20.800236 },
    { lat: 48.603227, lng: 20.799689 },
    { lat: 48.603193, lng: 20.798649 },
    { lat: 48.603201, lng: 20.798098 },
    { lat: 48.603202, lng: 20.798028 },
    { lat: 48.603203, lng: 20.79798 },
    { lat: 48.603202, lng: 20.797941 },
    { lat: 48.603208, lng: 20.797486 },
    { lat: 48.603213, lng: 20.797269 },
    { lat: 48.603221, lng: 20.796867 },
    { lat: 48.603242, lng: 20.796168 },
    { lat: 48.603304, lng: 20.795049 },
    { lat: 48.603239, lng: 20.794422 },
    { lat: 48.603097, lng: 20.793045 },
    { lat: 48.601885, lng: 20.793063 },
    { lat: 48.60148, lng: 20.793061 },
    { lat: 48.601422, lng: 20.793061 },
    { lat: 48.600762, lng: 20.793059 },
    { lat: 48.600453, lng: 20.793069 },
    { lat: 48.600236, lng: 20.793093 },
    { lat: 48.600218, lng: 20.793096 },
    { lat: 48.600127, lng: 20.793109 },
    { lat: 48.600071, lng: 20.793117 },
    { lat: 48.598932, lng: 20.793238 },
    { lat: 48.598545, lng: 20.793246 },
    { lat: 48.598383, lng: 20.793249 },
    { lat: 48.598282, lng: 20.793251 },
    { lat: 48.598112, lng: 20.793255 },
    { lat: 48.597593, lng: 20.793239 },
    { lat: 48.597442, lng: 20.793231 },
    { lat: 48.597303, lng: 20.793218 },
    { lat: 48.597058, lng: 20.793179 },
    { lat: 48.596015, lng: 20.793068 },
    { lat: 48.595474, lng: 20.793054 },
    { lat: 48.594872, lng: 20.793071 },
    { lat: 48.594538, lng: 20.793072 },
    { lat: 48.594156, lng: 20.793029 },
    { lat: 48.593595, lng: 20.792981 },
    { lat: 48.593569, lng: 20.792979 },
    { lat: 48.593258, lng: 20.792944 },
    { lat: 48.592846, lng: 20.792897 },
    { lat: 48.592338, lng: 20.792878 },
    { lat: 48.591715, lng: 20.792916 },
    { lat: 48.590825, lng: 20.793008 },
    { lat: 48.58977, lng: 20.793117 },
    { lat: 48.589308, lng: 20.793162 },
    { lat: 48.588366, lng: 20.793258 },
    { lat: 48.58784, lng: 20.793321 },
    { lat: 48.587717, lng: 20.793338 },
    { lat: 48.587711, lng: 20.793339 },
    { lat: 48.58769, lng: 20.793342 },
    { lat: 48.58755, lng: 20.79336 },
    { lat: 48.585986, lng: 20.793562 },
    { lat: 48.583684, lng: 20.79379 },
    { lat: 48.583109, lng: 20.793873 },
    { lat: 48.582995, lng: 20.793863 },
    { lat: 48.582857, lng: 20.79377 },
    { lat: 48.582811, lng: 20.793804 },
    { lat: 48.582762, lng: 20.793842 },
    { lat: 48.582713, lng: 20.793845 },
    { lat: 48.582629, lng: 20.79385 },
    { lat: 48.582363, lng: 20.79387 },
    { lat: 48.581712, lng: 20.793939 },
    { lat: 48.580333, lng: 20.794208 },
    { lat: 48.580253, lng: 20.794258 },
    { lat: 48.580043, lng: 20.794282 },
    { lat: 48.579891, lng: 20.794299 },
    { lat: 48.579761, lng: 20.794313 },
    { lat: 48.57815, lng: 20.794493 },
    { lat: 48.577943, lng: 20.794516 },
    { lat: 48.57721, lng: 20.794597 },
    { lat: 48.576744, lng: 20.794676 },
    { lat: 48.576694, lng: 20.794723 },
    { lat: 48.57665, lng: 20.794764 },
    { lat: 48.575869, lng: 20.795498 },
    { lat: 48.575645, lng: 20.795709 },
    { lat: 48.575396, lng: 20.795942 },
    { lat: 48.575227, lng: 20.796101 },
    { lat: 48.574974, lng: 20.797232 },
    { lat: 48.574652, lng: 20.798287 },
    { lat: 48.574587, lng: 20.798872 },
    { lat: 48.57457, lng: 20.799187 },
    { lat: 48.574579, lng: 20.799421 },
    { lat: 48.574652, lng: 20.799784 },
    { lat: 48.574832, lng: 20.800664 },
    { lat: 48.5748997, lng: 20.8009495 },
    { lat: 48.574996, lng: 20.801475 },
    { lat: 48.575176, lng: 20.8023188 },
    { lat: 48.575289, lng: 20.803051 },
    { lat: 48.575419, lng: 20.803761 },
    { lat: 48.57559, lng: 20.804615 },
    { lat: 48.575676, lng: 20.805101 },
    { lat: 48.575938, lng: 20.806358 },
    { lat: 48.576042, lng: 20.807048 },
    { lat: 48.5762377, lng: 20.8080885 },
    { lat: 48.576376, lng: 20.808787 },
    { lat: 48.576471, lng: 20.809313 },
    { lat: 48.576665, lng: 20.810355 },
    { lat: 48.576864, lng: 20.811262 },
    { lat: 48.577162, lng: 20.812678 },
    { lat: 48.577324, lng: 20.81372 },
    { lat: 48.577509, lng: 20.814669 },
    { lat: 48.57769, lng: 20.815544 },
    { lat: 48.577729, lng: 20.815774 },
    { lat: 48.578422, lng: 20.815754 },
    { lat: 48.578892, lng: 20.815722 },
    { lat: 48.578805, lng: 20.816244 },
    { lat: 48.578647, lng: 20.817536 },
    { lat: 48.578602, lng: 20.817966 },
    { lat: 48.578858, lng: 20.819347 },
    { lat: 48.578953, lng: 20.819761 },
    { lat: 48.579035, lng: 20.820116 },
    { lat: 48.579135, lng: 20.820694 },
    { lat: 48.579207, lng: 20.821054 },
    { lat: 48.579444, lng: 20.822086 },
    { lat: 48.579919, lng: 20.823884 },
    { lat: 48.580369, lng: 20.825477 },
    { lat: 48.580447, lng: 20.825828 },
    { lat: 48.580851, lng: 20.827212 },
    { lat: 48.58117, lng: 20.828393 },
    { lat: 48.581352, lng: 20.829438 },
    { lat: 48.581491, lng: 20.830233 },
    { lat: 48.581733, lng: 20.831185 },
    { lat: 48.581847, lng: 20.831745 },
    { lat: 48.581996, lng: 20.832828 },
    { lat: 48.582039, lng: 20.833096 },
    { lat: 48.582193, lng: 20.833681 },
    { lat: 48.582577, lng: 20.834824 },
    { lat: 48.5827549, lng: 20.8355837 },
    { lat: 48.582788, lng: 20.835792 },
    { lat: 48.58284, lng: 20.836359 },
    { lat: 48.5829963, lng: 20.8389681 },
    { lat: 48.5841058, lng: 20.8444194 },
    { lat: 48.5846602, lng: 20.8467499 },
    { lat: 48.585368, lng: 20.8485635 },
    { lat: 48.5872263, lng: 20.8520451 },
    { lat: 48.5873554, lng: 20.8517111 },
    { lat: 48.5875814, lng: 20.851213 },
    { lat: 48.5875274, lng: 20.8509901 },
    { lat: 48.5875329, lng: 20.850787 },
    { lat: 48.587702, lng: 20.8500732 },
    { lat: 48.5877954, lng: 20.8500393 },
    { lat: 48.588036, lng: 20.8501 },
    { lat: 48.5883656, lng: 20.850028 },
    { lat: 48.5886346, lng: 20.8498657 },
    { lat: 48.5893685, lng: 20.8496012 },
    { lat: 48.5906489, lng: 20.8492515 },
    { lat: 48.5923227, lng: 20.8486335 },
    { lat: 48.5935845, lng: 20.8483336 },
    { lat: 48.5940486, lng: 20.8480834 },
    { lat: 48.5952212, lng: 20.8479747 },
    { lat: 48.5970036, lng: 20.8475649 },
    { lat: 48.5990063, lng: 20.8473662 },
    { lat: 48.6007585, lng: 20.8470903 },
    { lat: 48.6012528, lng: 20.8468961 },
    { lat: 48.601767, lng: 20.846923 },
    { lat: 48.6017051, lng: 20.8482932 },
    { lat: 48.6017782, lng: 20.8482918 },
    { lat: 48.6067582, lng: 20.846655 },
    { lat: 48.6062641, lng: 20.8461997 },
    { lat: 48.6052375, lng: 20.8451958 },
    { lat: 48.6053099, lng: 20.8422738 },
    { lat: 48.6053649, lng: 20.8418121 },
    { lat: 48.6057853, lng: 20.8393849 },
    { lat: 48.6059582, lng: 20.8393104 },
    { lat: 48.6059652, lng: 20.8392202 },
    { lat: 48.605752, lng: 20.8381977 },
    { lat: 48.605997, lng: 20.8379244 },
    { lat: 48.6062396, lng: 20.8378435 },
    { lat: 48.6064651, lng: 20.8376248 },
    { lat: 48.6066235, lng: 20.8375892 },
    { lat: 48.6071186, lng: 20.8371906 },
    { lat: 48.6073714, lng: 20.8368836 },
    { lat: 48.6079125, lng: 20.8366088 },
    { lat: 48.6084522, lng: 20.8360078 },
    { lat: 48.6089372, lng: 20.8352877 },
    { lat: 48.6091791, lng: 20.8351535 },
    { lat: 48.6094307, lng: 20.8351713 },
    { lat: 48.60959, lng: 20.8349283 },
    { lat: 48.609941, lng: 20.8348192 },
    { lat: 48.6097919, lng: 20.8338993 },
    { lat: 48.608764, lng: 20.8289276 },
    { lat: 48.6081603, lng: 20.8255419 },
    { lat: 48.6082447, lng: 20.8251525 },
    { lat: 48.6084035, lng: 20.8247351 },
    { lat: 48.6084057, lng: 20.8244091 },
    { lat: 48.6087041, lng: 20.8236016 },
    { lat: 48.6087867, lng: 20.8231895 },
    { lat: 48.6088389, lng: 20.8225849 },
    { lat: 48.6089599, lng: 20.8219191 },
    { lat: 48.6089578, lng: 20.821413 },
    { lat: 48.6090306, lng: 20.8211373 },
    { lat: 48.6096091, lng: 20.8195453 },
    { lat: 48.6100107, lng: 20.8189255 },
    { lat: 48.6100113, lng: 20.8186153 },
    { lat: 48.6102008, lng: 20.8183996 },
    { lat: 48.6106676, lng: 20.8182638 },
    { lat: 48.6108286, lng: 20.8180971 },
    { lat: 48.610905, lng: 20.8179007 },
    { lat: 48.6111591, lng: 20.817664 },
    { lat: 48.6116075, lng: 20.8176772 },
    { lat: 48.6119499, lng: 20.817489 },
    { lat: 48.6128766, lng: 20.8175943 },
    { lat: 48.6133284, lng: 20.8175365 },
    { lat: 48.6145702, lng: 20.8171224 },
    { lat: 48.6148977, lng: 20.816861 },
    { lat: 48.6154856, lng: 20.8157354 },
    { lat: 48.6168527, lng: 20.8145521 },
    { lat: 48.617415, lng: 20.8142101 },
    { lat: 48.6189478, lng: 20.812936 },
    { lat: 48.619231, lng: 20.8128929 },
    { lat: 48.6208571, lng: 20.8137469 },
    { lat: 48.6210261, lng: 20.8135592 },
    { lat: 48.621142, lng: 20.8131682 },
    { lat: 48.6223382, lng: 20.8121181 },
    { lat: 48.623453, lng: 20.8114818 },
    { lat: 48.624311, lng: 20.811069 },
    { lat: 48.624975, lng: 20.807727 },
    { lat: 48.625379, lng: 20.806243 },
    { lat: 48.625366, lng: 20.806086 },
    { lat: 48.625309, lng: 20.805409 },
    { lat: 48.625233, lng: 20.804511 },
    { lat: 48.625225, lng: 20.804412 },
    { lat: 48.625075, lng: 20.802626 },
    { lat: 48.624516, lng: 20.797561 },
    { lat: 48.624371, lng: 20.796263 },
    { lat: 48.624353, lng: 20.796107 },
    { lat: 48.624295, lng: 20.795999 },
    { lat: 48.621956, lng: 20.797663 },
    { lat: 48.621777, lng: 20.797989 },
    { lat: 48.621736, lng: 20.798062 },
    { lat: 48.621314, lng: 20.798417 },
    { lat: 48.620364, lng: 20.798861 },
    { lat: 48.617752, lng: 20.800502 },
    { lat: 48.61766, lng: 20.80056 },
    { lat: 48.617409, lng: 20.800792 },
    { lat: 48.617323, lng: 20.800872 },
    { lat: 48.617196, lng: 20.800989 },
    { lat: 48.617074, lng: 20.801094 },
    { lat: 48.617005, lng: 20.801673 },
    { lat: 48.617005, lng: 20.80187 },
    { lat: 48.617006, lng: 20.80188 },
    { lat: 48.616839, lng: 20.80283 },
    { lat: 48.615832, lng: 20.803783 },
    { lat: 48.615789, lng: 20.803833 },
    { lat: 48.6155, lng: 20.804178 },
    { lat: 48.614857, lng: 20.804941 },
    { lat: 48.614833, lng: 20.804986 },
    { lat: 48.614252, lng: 20.806063 },
    { lat: 48.613962, lng: 20.806505 },
    { lat: 48.612969, lng: 20.80755 },
    { lat: 48.61294, lng: 20.807566 },
    { lat: 48.612662, lng: 20.807753 },
    { lat: 48.611794, lng: 20.808516 },
    { lat: 48.611613, lng: 20.808524 },
    { lat: 48.61162, lng: 20.809357 },
    { lat: 48.611419, lng: 20.809498 },
    { lat: 48.610268, lng: 20.809806 },
    { lat: 48.609709, lng: 20.810023 },
    { lat: 48.609379, lng: 20.8102 },
    { lat: 48.608973, lng: 20.810198 },
    { lat: 48.608595, lng: 20.810392 },
    { lat: 48.608339, lng: 20.810458 },
    { lat: 48.607676, lng: 20.810824 },
    { lat: 48.606838, lng: 20.811158 },
    { lat: 48.60641, lng: 20.811259 },
    { lat: 48.605781, lng: 20.811244 },
    { lat: 48.605644, lng: 20.811315 },
    { lat: 48.605424, lng: 20.811641 },
    { lat: 48.60497, lng: 20.811824 },
    { lat: 48.60463, lng: 20.812085 },
    { lat: 48.604543, lng: 20.812157 },
    { lat: 48.603589, lng: 20.811451 },
    { lat: 48.601893, lng: 20.810286 },
    { lat: 48.601614, lng: 20.810284 },
    { lat: 48.601146, lng: 20.810279 },
    { lat: 48.600872, lng: 20.810276 },
    { lat: 48.600666, lng: 20.810362 },
    { lat: 48.600368, lng: 20.810531 },
    { lat: 48.600342, lng: 20.810545 },
    { lat: 48.600322, lng: 20.810557 },
  ],
  NižnýČaj: [
    { lat: 48.6737857, lng: 21.3739597 },
    { lat: 48.6737569, lng: 21.3740137 },
    { lat: 48.6733799, lng: 21.3747262 },
    { lat: 48.6729955, lng: 21.3751985 },
    { lat: 48.6727547, lng: 21.375346 },
    { lat: 48.6723051, lng: 21.3758153 },
    { lat: 48.6721028, lng: 21.3761221 },
    { lat: 48.6714967, lng: 21.3764428 },
    { lat: 48.6701489, lng: 21.3774336 },
    { lat: 48.6700181, lng: 21.3779876 },
    { lat: 48.670198, lng: 21.3787365 },
    { lat: 48.6702109, lng: 21.3792811 },
    { lat: 48.6679918, lng: 21.3791345 },
    { lat: 48.6675154, lng: 21.3793428 },
    { lat: 48.6670063, lng: 21.3792891 },
    { lat: 48.6668632, lng: 21.3792123 },
    { lat: 48.6667234, lng: 21.3790168 },
    { lat: 48.6666375, lng: 21.378777 },
    { lat: 48.6656401, lng: 21.3782728 },
    { lat: 48.6651496, lng: 21.3778224 },
    { lat: 48.6650277, lng: 21.3778175 },
    { lat: 48.6648687, lng: 21.3789344 },
    { lat: 48.6648663, lng: 21.3799029 },
    { lat: 48.6649238, lng: 21.3802912 },
    { lat: 48.6652275, lng: 21.380921 },
    { lat: 48.6653579, lng: 21.3816707 },
    { lat: 48.6649538, lng: 21.3820065 },
    { lat: 48.6652635, lng: 21.3829566 },
    { lat: 48.6652861, lng: 21.3832245 },
    { lat: 48.6652185, lng: 21.3835588 },
    { lat: 48.6647523, lng: 21.3849105 },
    { lat: 48.6640451, lng: 21.3873052 },
    { lat: 48.6635613, lng: 21.38871 },
    { lat: 48.6632087, lng: 21.3895608 },
    { lat: 48.663017, lng: 21.3901943 },
    { lat: 48.6616289, lng: 21.3934641 },
    { lat: 48.6608087, lng: 21.394904 },
    { lat: 48.6609702, lng: 21.3965539 },
    { lat: 48.6607977, lng: 21.3969223 },
    { lat: 48.6607875, lng: 21.3973309 },
    { lat: 48.6609884, lng: 21.3976975 },
    { lat: 48.6611358, lng: 21.3981104 },
    { lat: 48.6614137, lng: 21.3980357 },
    { lat: 48.661515, lng: 21.3978395 },
    { lat: 48.6615108, lng: 21.3972481 },
    { lat: 48.6616995, lng: 21.397503 },
    { lat: 48.6619739, lng: 21.3977226 },
    { lat: 48.6618812, lng: 21.3981653 },
    { lat: 48.6619635, lng: 21.398436 },
    { lat: 48.6620819, lng: 21.3986365 },
    { lat: 48.6620615, lng: 21.3987412 },
    { lat: 48.6622402, lng: 21.3989647 },
    { lat: 48.6624175, lng: 21.3993977 },
    { lat: 48.662217, lng: 21.4006041 },
    { lat: 48.6632068, lng: 21.4010455 },
    { lat: 48.6639348, lng: 21.4014611 },
    { lat: 48.6644777, lng: 21.4007178 },
    { lat: 48.6648573, lng: 21.4009221 },
    { lat: 48.6650731, lng: 21.400874 },
    { lat: 48.6655816, lng: 21.4011721 },
    { lat: 48.6658117, lng: 21.4016087 },
    { lat: 48.6659447, lng: 21.4020297 },
    { lat: 48.6659209, lng: 21.4029373 },
    { lat: 48.6657765, lng: 21.4031881 },
    { lat: 48.665516, lng: 21.4032068 },
    { lat: 48.6653383, lng: 21.4033598 },
    { lat: 48.6652634, lng: 21.4037617 },
    { lat: 48.6653885, lng: 21.4041068 },
    { lat: 48.665659, lng: 21.4044666 },
    { lat: 48.6657792, lng: 21.4044937 },
    { lat: 48.665856, lng: 21.4045394 },
    { lat: 48.6660846, lng: 21.4046226 },
    { lat: 48.6663746, lng: 21.4050676 },
    { lat: 48.6668904, lng: 21.4056525 },
    { lat: 48.6672641, lng: 21.4066447 },
    { lat: 48.6681067, lng: 21.4084942 },
    { lat: 48.6683319, lng: 21.4077773 },
    { lat: 48.6685918, lng: 21.4076985 },
    { lat: 48.6688044, lng: 21.4078857 },
    { lat: 48.6687998, lng: 21.408001 },
    { lat: 48.6688869, lng: 21.4080126 },
    { lat: 48.6690479, lng: 21.407557 },
    { lat: 48.6692109, lng: 21.4074816 },
    { lat: 48.6693202, lng: 21.4076369 },
    { lat: 48.669413, lng: 21.408222 },
    { lat: 48.6696192, lng: 21.40804 },
    { lat: 48.6698654, lng: 21.4081146 },
    { lat: 48.6700552, lng: 21.408368 },
    { lat: 48.6708794, lng: 21.4089105 },
    { lat: 48.6709277, lng: 21.4091412 },
    { lat: 48.671138, lng: 21.4092815 },
    { lat: 48.6712386, lng: 21.4093046 },
    { lat: 48.6714839, lng: 21.4090748 },
    { lat: 48.6717256, lng: 21.4090998 },
    { lat: 48.6718032, lng: 21.4089895 },
    { lat: 48.6722425, lng: 21.4088125 },
    { lat: 48.6724067, lng: 21.4074382 },
    { lat: 48.6726477, lng: 21.4055135 },
    { lat: 48.6729808, lng: 21.4056993 },
    { lat: 48.6734821, lng: 21.4062091 },
    { lat: 48.6736907, lng: 21.4063026 },
    { lat: 48.6752167, lng: 21.4065363 },
    { lat: 48.6764206, lng: 21.4065252 },
    { lat: 48.6765139, lng: 21.4055732 },
    { lat: 48.676691, lng: 21.4046757 },
    { lat: 48.6768527, lng: 21.4042517 },
    { lat: 48.6771789, lng: 21.403865 },
    { lat: 48.6773358, lng: 21.4034239 },
    { lat: 48.6774639, lng: 21.4022402 },
    { lat: 48.6777907, lng: 21.4015388 },
    { lat: 48.6736485, lng: 21.4011239 },
    { lat: 48.6746911, lng: 21.3946669 },
    { lat: 48.6755145, lng: 21.3886015 },
    { lat: 48.6758962, lng: 21.3884588 },
    { lat: 48.6761104, lng: 21.382656 },
    { lat: 48.6762737, lng: 21.3822087 },
    { lat: 48.6765848, lng: 21.3818137 },
    { lat: 48.6767668, lng: 21.3814591 },
    { lat: 48.6773829, lng: 21.3805391 },
    { lat: 48.6777454, lng: 21.3796403 },
    { lat: 48.6778965, lng: 21.3790881 },
    { lat: 48.6777083, lng: 21.3787697 },
    { lat: 48.6770936, lng: 21.3774076 },
    { lat: 48.6767895, lng: 21.3772841 },
    { lat: 48.6766102, lng: 21.3767835 },
    { lat: 48.676316, lng: 21.3763909 },
    { lat: 48.6760415, lng: 21.3760898 },
    { lat: 48.6753983, lng: 21.3760318 },
    { lat: 48.6750311, lng: 21.3756819 },
    { lat: 48.6748751, lng: 21.3753931 },
    { lat: 48.6746487, lng: 21.3751244 },
    { lat: 48.6743782, lng: 21.3749192 },
    { lat: 48.6742407, lng: 21.3745415 },
    { lat: 48.6737857, lng: 21.3739597 },
  ],
  DružstevnápriHornáde: [
    { lat: 48.811822, lng: 21.275061 },
    { lat: 48.812347, lng: 21.274451 },
    { lat: 48.812631, lng: 21.274209 },
    { lat: 48.812673, lng: 21.27417 },
    { lat: 48.812763, lng: 21.274099 },
    { lat: 48.813054, lng: 21.273957 },
    { lat: 48.813627, lng: 21.273864 },
    { lat: 48.814437, lng: 21.273739 },
    { lat: 48.814768, lng: 21.273582 },
    { lat: 48.814939, lng: 21.273441 },
    { lat: 48.815002, lng: 21.273388 },
    { lat: 48.815171, lng: 21.273412 },
    { lat: 48.815225, lng: 21.273431 },
    { lat: 48.81559, lng: 21.273555 },
    { lat: 48.816011, lng: 21.273865 },
    { lat: 48.81661, lng: 21.274061 },
    { lat: 48.81669, lng: 21.274226 },
    { lat: 48.81681, lng: 21.275592 },
    { lat: 48.81739, lng: 21.275632 },
    { lat: 48.817356, lng: 21.275301 },
    { lat: 48.817288, lng: 21.274493 },
    { lat: 48.817162, lng: 21.272876 },
    { lat: 48.817067, lng: 21.272625 },
    { lat: 48.816893, lng: 21.272165 },
    { lat: 48.816798, lng: 21.271913 },
    { lat: 48.816909, lng: 21.271564 },
    { lat: 48.81679, lng: 21.271071 },
    { lat: 48.816942, lng: 21.27066 },
    { lat: 48.816834, lng: 21.269488 },
    { lat: 48.816849, lng: 21.267706 },
    { lat: 48.816879, lng: 21.267417 },
    { lat: 48.816932, lng: 21.267139 },
    { lat: 48.817025, lng: 21.26666 },
    { lat: 48.817233, lng: 21.265968 },
    { lat: 48.817239, lng: 21.265807 },
    { lat: 48.81708, lng: 21.265084 },
    { lat: 48.817403, lng: 21.26391 },
    { lat: 48.8174949, lng: 21.2636841 },
    { lat: 48.817625, lng: 21.26304 },
    { lat: 48.817716, lng: 21.263057 },
    { lat: 48.817837, lng: 21.262439 },
    { lat: 48.818414, lng: 21.261806 },
    { lat: 48.81878, lng: 21.261491 },
    { lat: 48.818746, lng: 21.261033 },
    { lat: 48.818835, lng: 21.260411 },
    { lat: 48.819133, lng: 21.259622 },
    { lat: 48.819344, lng: 21.259074 },
    { lat: 48.819858, lng: 21.258391 },
    { lat: 48.820178, lng: 21.257842 },
    { lat: 48.820708, lng: 21.257466 },
    { lat: 48.821155, lng: 21.25725 },
    { lat: 48.821703, lng: 21.257177 },
    { lat: 48.822647, lng: 21.256479 },
    { lat: 48.823127, lng: 21.256179 },
    { lat: 48.823179, lng: 21.256137 },
    { lat: 48.823158, lng: 21.255171 },
    { lat: 48.823113, lng: 21.255017 },
    { lat: 48.823147, lng: 21.254945 },
    { lat: 48.823158, lng: 21.254915 },
    { lat: 48.823322, lng: 21.254481 },
    { lat: 48.823494, lng: 21.254372 },
    { lat: 48.823577, lng: 21.254273 },
    { lat: 48.823618, lng: 21.254215 },
    { lat: 48.823698, lng: 21.254065 },
    { lat: 48.823869, lng: 21.253568 },
    { lat: 48.823923, lng: 21.253357 },
    { lat: 48.82399, lng: 21.252978 },
    { lat: 48.823842, lng: 21.252822 },
    { lat: 48.823985, lng: 21.252561 },
    { lat: 48.824086, lng: 21.252376 },
    { lat: 48.824414, lng: 21.251532 },
    { lat: 48.824835, lng: 21.250888 },
    { lat: 48.825473, lng: 21.250427 },
    { lat: 48.825905, lng: 21.249932 },
    { lat: 48.82617, lng: 21.249749 },
    { lat: 48.82642, lng: 21.249502 },
    { lat: 48.826526, lng: 21.2493 },
    { lat: 48.826998, lng: 21.249045 },
    { lat: 48.827164, lng: 21.24888 },
    { lat: 48.827501, lng: 21.248373 },
    { lat: 48.827672, lng: 21.248208 },
    { lat: 48.82808, lng: 21.247474 },
    { lat: 48.828548, lng: 21.246798 },
    { lat: 48.828852, lng: 21.246059 },
    { lat: 48.8280446, lng: 21.2449781 },
    { lat: 48.8279116, lng: 21.2438824 },
    { lat: 48.8280259, lng: 21.2421185 },
    { lat: 48.8279213, lng: 21.2410641 },
    { lat: 48.8279174, lng: 21.2386467 },
    { lat: 48.8276058, lng: 21.2384756 },
    { lat: 48.8271966, lng: 21.2384897 },
    { lat: 48.8269212, lng: 21.2379882 },
    { lat: 48.826665, lng: 21.2382221 },
    { lat: 48.8263561, lng: 21.2383823 },
    { lat: 48.8258625, lng: 21.2376348 },
    { lat: 48.8257369, lng: 21.2372506 },
    { lat: 48.825474, lng: 21.2367889 },
    { lat: 48.8249029, lng: 21.2361563 },
    { lat: 48.8236624, lng: 21.2329917 },
    { lat: 48.8236838, lng: 21.232298 },
    { lat: 48.8235304, lng: 21.2317779 },
    { lat: 48.8235844, lng: 21.2313855 },
    { lat: 48.8233309, lng: 21.2312246 },
    { lat: 48.8230819, lng: 21.2315063 },
    { lat: 48.8232277, lng: 21.2318594 },
    { lat: 48.8229377, lng: 21.2324076 },
    { lat: 48.8224508, lng: 21.2329637 },
    { lat: 48.8217666, lng: 21.2336209 },
    { lat: 48.821029, lng: 21.2345133 },
    { lat: 48.8199276, lng: 21.2360451 },
    { lat: 48.819824, lng: 21.2364432 },
    { lat: 48.8196158, lng: 21.2367662 },
    { lat: 48.8187591, lng: 21.2375192 },
    { lat: 48.8182706, lng: 21.2375924 },
    { lat: 48.8181891, lng: 21.2375415 },
    { lat: 48.8179806, lng: 21.2377459 },
    { lat: 48.8177875, lng: 21.2380507 },
    { lat: 48.8169556, lng: 21.2385844 },
    { lat: 48.8166827, lng: 21.2384995 },
    { lat: 48.8164769, lng: 21.2383206 },
    { lat: 48.8163995, lng: 21.23822 },
    { lat: 48.8162993, lng: 21.2375269 },
    { lat: 48.8161198, lng: 21.2373654 },
    { lat: 48.8159362, lng: 21.2373249 },
    { lat: 48.8157539, lng: 21.2371793 },
    { lat: 48.8156981, lng: 21.2369012 },
    { lat: 48.8155722, lng: 21.2366205 },
    { lat: 48.8155814, lng: 21.2363692 },
    { lat: 48.8154518, lng: 21.2359992 },
    { lat: 48.81516, lng: 21.2356622 },
    { lat: 48.8149854, lng: 21.2352575 },
    { lat: 48.8146981, lng: 21.2339404 },
    { lat: 48.8141952, lng: 21.2342757 },
    { lat: 48.8138075, lng: 21.2346882 },
    { lat: 48.8127198, lng: 21.2355415 },
    { lat: 48.8124149, lng: 21.2356178 },
    { lat: 48.8120419, lng: 21.23582 },
    { lat: 48.8117777, lng: 21.2358252 },
    { lat: 48.8113683, lng: 21.2356562 },
    { lat: 48.8110849, lng: 21.235917 },
    { lat: 48.8107531, lng: 21.235992 },
    { lat: 48.8103542, lng: 21.2347432 },
    { lat: 48.8090921, lng: 21.2343228 },
    { lat: 48.8090411, lng: 21.2340262 },
    { lat: 48.8081234, lng: 21.2338244 },
    { lat: 48.8083359, lng: 21.2326568 },
    { lat: 48.8077386, lng: 21.2315093 },
    { lat: 48.8075482, lng: 21.2312498 },
    { lat: 48.8061046, lng: 21.2319056 },
    { lat: 48.8048862, lng: 21.2323186 },
    { lat: 48.8042272, lng: 21.2327325 },
    { lat: 48.8034836, lng: 21.2332852 },
    { lat: 48.8020884, lng: 21.2347089 },
    { lat: 48.8015181, lng: 21.235513 },
    { lat: 48.8014761, lng: 21.2355028 },
    { lat: 48.8009571, lng: 21.2369409 },
    { lat: 48.8006277, lng: 21.2383711 },
    { lat: 48.800124, lng: 21.2398708 },
    { lat: 48.7999343, lng: 21.2409788 },
    { lat: 48.7997662, lng: 21.2413302 },
    { lat: 48.7998333, lng: 21.2414246 },
    { lat: 48.7998256, lng: 21.2416868 },
    { lat: 48.7991458, lng: 21.2428096 },
    { lat: 48.7998476, lng: 21.2439984 },
    { lat: 48.7997251, lng: 21.2446568 },
    { lat: 48.7996677, lng: 21.2447972 },
    { lat: 48.7994815, lng: 21.245001 },
    { lat: 48.7990121, lng: 21.2460542 },
    { lat: 48.7985521, lng: 21.2466754 },
    { lat: 48.7973632, lng: 21.2478994 },
    { lat: 48.796497, lng: 21.2486365 },
    { lat: 48.7963701, lng: 21.2488636 },
    { lat: 48.7964652, lng: 21.2498659 },
    { lat: 48.7965177, lng: 21.2523661 },
    { lat: 48.7965479, lng: 21.2524668 },
    { lat: 48.7965619, lng: 21.2524833 },
    { lat: 48.7965151, lng: 21.2524655 },
    { lat: 48.7963006, lng: 21.2526606 },
    { lat: 48.7957504, lng: 21.2529555 },
    { lat: 48.7949795, lng: 21.2531403 },
    { lat: 48.7935816, lng: 21.2529108 },
    { lat: 48.7928072, lng: 21.2528721 },
    { lat: 48.7923619, lng: 21.2530614 },
    { lat: 48.7911314, lng: 21.2532673 },
    { lat: 48.7906619, lng: 21.253551 },
    { lat: 48.7895359, lng: 21.2533841 },
    { lat: 48.7878077, lng: 21.2522287 },
    { lat: 48.7872874, lng: 21.2522159 },
    { lat: 48.7869699, lng: 21.2520678 },
    { lat: 48.7867896, lng: 21.251911 },
    { lat: 48.7862534, lng: 21.2513484 },
    { lat: 48.7858771, lng: 21.2507173 },
    { lat: 48.7857525, lng: 21.2502554 },
    { lat: 48.7854182, lng: 21.2497791 },
    { lat: 48.7854391, lng: 21.2492502 },
    { lat: 48.7850513, lng: 21.2492327 },
    { lat: 48.7850021, lng: 21.2488772 },
    { lat: 48.7848907, lng: 21.2487666 },
    { lat: 48.7845449, lng: 21.2484656 },
    { lat: 48.7840256, lng: 21.2481962 },
    { lat: 48.7835229, lng: 21.2478411 },
    { lat: 48.7828484, lng: 21.2472437 },
    { lat: 48.7821665, lng: 21.2463806 },
    { lat: 48.7820218, lng: 21.2457036 },
    { lat: 48.7817428, lng: 21.2451642 },
    { lat: 48.7814546, lng: 21.2449042 },
    { lat: 48.780702, lng: 21.244561 },
    { lat: 48.780672, lng: 21.24464 },
    { lat: 48.780357, lng: 21.245646 },
    { lat: 48.780271, lng: 21.245928 },
    { lat: 48.780225, lng: 21.246081 },
    { lat: 48.780171, lng: 21.246313 },
    { lat: 48.779927, lng: 21.247362 },
    { lat: 48.780477, lng: 21.247301 },
    { lat: 48.780448, lng: 21.247811 },
    { lat: 48.78017, lng: 21.249604 },
    { lat: 48.780312, lng: 21.249793 },
    { lat: 48.780208, lng: 21.250322 },
    { lat: 48.779784, lng: 21.251823 },
    { lat: 48.780518, lng: 21.25198 },
    { lat: 48.780739, lng: 21.251174 },
    { lat: 48.781746, lng: 21.251243 },
    { lat: 48.782241, lng: 21.25138 },
    { lat: 48.782443, lng: 21.251426 },
    { lat: 48.782465, lng: 21.251432 },
    { lat: 48.782734, lng: 21.251493 },
    { lat: 48.783755, lng: 21.255204 },
    { lat: 48.783763, lng: 21.255233 },
    { lat: 48.783769, lng: 21.255257 },
    { lat: 48.784072, lng: 21.256357 },
    { lat: 48.784133, lng: 21.256577 },
    { lat: 48.784438, lng: 21.258227 },
    { lat: 48.784556, lng: 21.258767 },
    { lat: 48.784725, lng: 21.259082 },
    { lat: 48.784988, lng: 21.25957 },
    { lat: 48.785632, lng: 21.260024 },
    { lat: 48.785726, lng: 21.260232 },
    { lat: 48.785506, lng: 21.261069 },
    { lat: 48.784763, lng: 21.261714 },
    { lat: 48.783829, lng: 21.262351 },
    { lat: 48.784031, lng: 21.263374 },
    { lat: 48.784059, lng: 21.263515 },
    { lat: 48.785033, lng: 21.263703 },
    { lat: 48.785691, lng: 21.264286 },
    { lat: 48.786308, lng: 21.26448 },
    { lat: 48.786433, lng: 21.264133 },
    { lat: 48.786754, lng: 21.262869 },
    { lat: 48.787447, lng: 21.262795 },
    { lat: 48.788059, lng: 21.264017 },
    { lat: 48.78937, lng: 21.266149 },
    { lat: 48.791303, lng: 21.266002 },
    { lat: 48.791357, lng: 21.265998 },
    { lat: 48.791593, lng: 21.26598 },
    { lat: 48.792682, lng: 21.265569 },
    { lat: 48.79318, lng: 21.265381 },
    { lat: 48.793151, lng: 21.26562 },
    { lat: 48.793874, lng: 21.266566 },
    { lat: 48.794301, lng: 21.267907 },
    { lat: 48.794661, lng: 21.269474 },
    { lat: 48.79496, lng: 21.270719 },
    { lat: 48.795302, lng: 21.270093 },
    { lat: 48.795911, lng: 21.26979 },
    { lat: 48.79608, lng: 21.269707 },
    { lat: 48.79633, lng: 21.269685 },
    { lat: 48.796387, lng: 21.26968 },
    { lat: 48.796472, lng: 21.269686 },
    { lat: 48.797976, lng: 21.269799 },
    { lat: 48.798502, lng: 21.269961 },
    { lat: 48.798544, lng: 21.269966 },
    { lat: 48.799312, lng: 21.270367 },
    { lat: 48.79906, lng: 21.270933 },
    { lat: 48.800665, lng: 21.271941 },
    { lat: 48.800703, lng: 21.272698 },
    { lat: 48.800937, lng: 21.273453 },
    { lat: 48.801016, lng: 21.274586 },
    { lat: 48.800474, lng: 21.274545 },
    { lat: 48.799907, lng: 21.274113 },
    { lat: 48.799257, lng: 21.273863 },
    { lat: 48.799137, lng: 21.273775 },
    { lat: 48.799106, lng: 21.273752 },
    { lat: 48.798994, lng: 21.27367 },
    { lat: 48.79867, lng: 21.273288 },
    { lat: 48.798575, lng: 21.273241 },
    { lat: 48.797845, lng: 21.272882 },
    { lat: 48.797282, lng: 21.272793 },
    { lat: 48.796085, lng: 21.273265 },
    { lat: 48.79563, lng: 21.273178 },
    { lat: 48.795258, lng: 21.273299 },
    { lat: 48.794792, lng: 21.273545 },
    { lat: 48.794144, lng: 21.27347 },
    { lat: 48.7941417, lng: 21.2734816 },
    { lat: 48.794133, lng: 21.273521 },
    { lat: 48.794118, lng: 21.273589 },
    { lat: 48.794111, lng: 21.273621 },
    { lat: 48.794104, lng: 21.273653 },
    { lat: 48.7945663, lng: 21.2737077 },
    { lat: 48.7947983, lng: 21.273675 },
    { lat: 48.7951019, lng: 21.2734848 },
    { lat: 48.7955478, lng: 21.2733457 },
    { lat: 48.795895, lng: 21.2734736 },
    { lat: 48.7961192, lng: 21.2734854 },
    { lat: 48.7967502, lng: 21.2731479 },
    { lat: 48.7970223, lng: 21.2731009 },
    { lat: 48.7973498, lng: 21.2729508 },
    { lat: 48.7979785, lng: 21.2731222 },
    { lat: 48.7986788, lng: 21.2735416 },
    { lat: 48.7989497, lng: 21.2737845 },
    { lat: 48.8004772, lng: 21.2758188 },
    { lat: 48.8006774, lng: 21.2759176 },
    { lat: 48.8019408, lng: 21.2756906 },
    { lat: 48.8021667, lng: 21.2757158 },
    { lat: 48.8027677, lng: 21.2756125 },
    { lat: 48.8031541, lng: 21.2754779 },
    { lat: 48.8033094, lng: 21.2752895 },
    { lat: 48.8039918, lng: 21.2753033 },
    { lat: 48.8043243, lng: 21.2751043 },
    { lat: 48.8047414, lng: 21.2752409 },
    { lat: 48.8051125, lng: 21.2751938 },
    { lat: 48.8057907, lng: 21.2749626 },
    { lat: 48.8069499, lng: 21.2736762 },
    { lat: 48.8076528, lng: 21.2731529 },
    { lat: 48.8079722, lng: 21.2730326 },
    { lat: 48.8083072, lng: 21.2726913 },
    { lat: 48.8086524, lng: 21.2724692 },
    { lat: 48.8089752, lng: 21.2719452 },
    { lat: 48.8090593, lng: 21.2719329 },
    { lat: 48.8095956, lng: 21.2721065 },
    { lat: 48.8110759, lng: 21.2753439 },
    { lat: 48.8112725, lng: 21.2754079 },
    { lat: 48.811411, lng: 21.2753746 },
    { lat: 48.811822, lng: 21.275061 },
  ],
  Kecerovce: [
    { lat: 48.8135956, lng: 21.3709378 },
    { lat: 48.8135914, lng: 21.3709863 },
    { lat: 48.8130714, lng: 21.3710961 },
    { lat: 48.812204, lng: 21.3713414 },
    { lat: 48.8118386, lng: 21.3713711 },
    { lat: 48.8111993, lng: 21.3714316 },
    { lat: 48.810884, lng: 21.3716379 },
    { lat: 48.8099792, lng: 21.3735032 },
    { lat: 48.809643, lng: 21.3739979 },
    { lat: 48.8095741, lng: 21.3741937 },
    { lat: 48.8096261, lng: 21.3744158 },
    { lat: 48.8101079, lng: 21.3754784 },
    { lat: 48.8102821, lng: 21.3758554 },
    { lat: 48.8104758, lng: 21.3762778 },
    { lat: 48.8106671, lng: 21.3770487 },
    { lat: 48.8110134, lng: 21.3783144 },
    { lat: 48.8111534, lng: 21.3788134 },
    { lat: 48.8114479, lng: 21.3797213 },
    { lat: 48.811684, lng: 21.3804486 },
    { lat: 48.8125142, lng: 21.3823109 },
    { lat: 48.8120461, lng: 21.3834391 },
    { lat: 48.8119852, lng: 21.3842319 },
    { lat: 48.8103761, lng: 21.3858542 },
    { lat: 48.8097727, lng: 21.3866008 },
    { lat: 48.8092933, lng: 21.3870936 },
    { lat: 48.8093832, lng: 21.388301 },
    { lat: 48.8092878, lng: 21.3894892 },
    { lat: 48.8091849, lng: 21.3895094 },
    { lat: 48.8087704, lng: 21.3907814 },
    { lat: 48.808354, lng: 21.3916533 },
    { lat: 48.8081636, lng: 21.3929322 },
    { lat: 48.8082543, lng: 21.3933733 },
    { lat: 48.8094474, lng: 21.3954724 },
    { lat: 48.8096403, lng: 21.3959402 },
    { lat: 48.8096158, lng: 21.3961803 },
    { lat: 48.8093518, lng: 21.3965346 },
    { lat: 48.8090721, lng: 21.3967396 },
    { lat: 48.8089085, lng: 21.397037 },
    { lat: 48.8087002, lng: 21.3975981 },
    { lat: 48.8085311, lng: 21.3976988 },
    { lat: 48.808431, lng: 21.3980055 },
    { lat: 48.8081974, lng: 21.3984383 },
    { lat: 48.8079034, lng: 21.3994921 },
    { lat: 48.8079661, lng: 21.4002077 },
    { lat: 48.8080297, lng: 21.4010312 },
    { lat: 48.8073444, lng: 21.4017798 },
    { lat: 48.8069374, lng: 21.4020587 },
    { lat: 48.8068352, lng: 21.402222 },
    { lat: 48.806904, lng: 21.4026839 },
    { lat: 48.8071447, lng: 21.4029856 },
    { lat: 48.8073381, lng: 21.4036427 },
    { lat: 48.8073572, lng: 21.4045818 },
    { lat: 48.8074523, lng: 21.4049719 },
    { lat: 48.807651, lng: 21.4054513 },
    { lat: 48.8078741, lng: 21.40569 },
    { lat: 48.8086364, lng: 21.4083387 },
    { lat: 48.8084112, lng: 21.411574 },
    { lat: 48.8085955, lng: 21.4117557 },
    { lat: 48.8086433, lng: 21.4119992 },
    { lat: 48.8086563, lng: 21.4120961 },
    { lat: 48.8085258, lng: 21.412407 },
    { lat: 48.8086379, lng: 21.4124336 },
    { lat: 48.8088731, lng: 21.4126623 },
    { lat: 48.8090247, lng: 21.4131468 },
    { lat: 48.8091917, lng: 21.4131263 },
    { lat: 48.8092946, lng: 21.4131043 },
    { lat: 48.8111809, lng: 21.4163195 },
    { lat: 48.8114477, lng: 21.4166274 },
    { lat: 48.8118633, lng: 21.4173266 },
    { lat: 48.8124841, lng: 21.4185021 },
    { lat: 48.8130782, lng: 21.4198783 },
    { lat: 48.813059, lng: 21.4201488 },
    { lat: 48.8131422, lng: 21.4202192 },
    { lat: 48.8131985, lng: 21.4206017 },
    { lat: 48.8133729, lng: 21.4207199 },
    { lat: 48.8136181, lng: 21.420717 },
    { lat: 48.8137184, lng: 21.420833 },
    { lat: 48.8136575, lng: 21.4215183 },
    { lat: 48.8138363, lng: 21.4217455 },
    { lat: 48.8138933, lng: 21.4220333 },
    { lat: 48.8137753, lng: 21.4223501 },
    { lat: 48.8138453, lng: 21.4226624 },
    { lat: 48.8139331, lng: 21.4228158 },
    { lat: 48.8140822, lng: 21.4228368 },
    { lat: 48.8141696, lng: 21.4230162 },
    { lat: 48.8141935, lng: 21.4232116 },
    { lat: 48.8140748, lng: 21.4234267 },
    { lat: 48.8139279, lng: 21.4235212 },
    { lat: 48.8139505, lng: 21.4237525 },
    { lat: 48.8140814, lng: 21.4240361 },
    { lat: 48.8141023, lng: 21.4242807 },
    { lat: 48.8140318, lng: 21.4246042 },
    { lat: 48.8141548, lng: 21.4249219 },
    { lat: 48.8140364, lng: 21.4252562 },
    { lat: 48.814067, lng: 21.4254562 },
    { lat: 48.8142839, lng: 21.4258736 },
    { lat: 48.8144823, lng: 21.4268807 },
    { lat: 48.8147067, lng: 21.4274297 },
    { lat: 48.8147548, lng: 21.4276549 },
    { lat: 48.814755, lng: 21.4286171 },
    { lat: 48.8146866, lng: 21.4290844 },
    { lat: 48.8148889, lng: 21.4296149 },
    { lat: 48.8148319, lng: 21.4300886 },
    { lat: 48.814901, lng: 21.4303321 },
    { lat: 48.8149086, lng: 21.4312099 },
    { lat: 48.8150192, lng: 21.4317873 },
    { lat: 48.8153347, lng: 21.4318864 },
    { lat: 48.8154172, lng: 21.432084 },
    { lat: 48.815438, lng: 21.432335 },
    { lat: 48.8152396, lng: 21.4332834 },
    { lat: 48.8152377, lng: 21.4336424 },
    { lat: 48.8151342, lng: 21.4337579 },
    { lat: 48.8150381, lng: 21.4341235 },
    { lat: 48.8150683, lng: 21.4343082 },
    { lat: 48.81535, lng: 21.4345535 },
    { lat: 48.815329, lng: 21.4347252 },
    { lat: 48.8150912, lng: 21.4348633 },
    { lat: 48.815082, lng: 21.4350499 },
    { lat: 48.8154186, lng: 21.4359399 },
    { lat: 48.8153641, lng: 21.4362292 },
    { lat: 48.8155816, lng: 21.4360868 },
    { lat: 48.815642, lng: 21.4361102 },
    { lat: 48.8157103, lng: 21.4362959 },
    { lat: 48.8157158, lng: 21.4364837 },
    { lat: 48.8156109, lng: 21.4369324 },
    { lat: 48.8157102, lng: 21.4372092 },
    { lat: 48.8157015, lng: 21.4372984 },
    { lat: 48.8154319, lng: 21.4373115 },
    { lat: 48.8153894, lng: 21.4374393 },
    { lat: 48.8154029, lng: 21.437819 },
    { lat: 48.8155357, lng: 21.4378625 },
    { lat: 48.8155732, lng: 21.4379903 },
    { lat: 48.8155228, lng: 21.4386128 },
    { lat: 48.8159264, lng: 21.4387946 },
    { lat: 48.8160296, lng: 21.4390108 },
    { lat: 48.8160403, lng: 21.4391515 },
    { lat: 48.81591, lng: 21.4395823 },
    { lat: 48.8160595, lng: 21.4397675 },
    { lat: 48.8159919, lng: 21.4401081 },
    { lat: 48.8160257, lng: 21.4404153 },
    { lat: 48.8157249, lng: 21.4410243 },
    { lat: 48.8157693, lng: 21.4412266 },
    { lat: 48.8160963, lng: 21.4416385 },
    { lat: 48.816166, lng: 21.4418673 },
    { lat: 48.81611, lng: 21.4421456 },
    { lat: 48.8161288, lng: 21.4430223 },
    { lat: 48.8163421, lng: 21.4433508 },
    { lat: 48.8165593, lng: 21.443353 },
    { lat: 48.8165965, lng: 21.4433926 },
    { lat: 48.8192517, lng: 21.4426477 },
    { lat: 48.8195986, lng: 21.4424316 },
    { lat: 48.8196904, lng: 21.4425305 },
    { lat: 48.8201974, lng: 21.4436723 },
    { lat: 48.8210387, lng: 21.4428868 },
    { lat: 48.8222256, lng: 21.4419517 },
    { lat: 48.8235199, lng: 21.4412753 },
    { lat: 48.8241241, lng: 21.4410352 },
    { lat: 48.8241653, lng: 21.4411799 },
    { lat: 48.8253547, lng: 21.440561 },
    { lat: 48.8253217, lng: 21.4404732 },
    { lat: 48.8253516, lng: 21.440228 },
    { lat: 48.82601, lng: 21.4400785 },
    { lat: 48.8273014, lng: 21.4395608 },
    { lat: 48.8280589, lng: 21.4391886 },
    { lat: 48.8296464, lng: 21.4386867 },
    { lat: 48.8297963, lng: 21.4391505 },
    { lat: 48.8299521, lng: 21.4391733 },
    { lat: 48.8302418, lng: 21.4396864 },
    { lat: 48.8302137, lng: 21.4398043 },
    { lat: 48.83029, lng: 21.4401028 },
    { lat: 48.8301887, lng: 21.4402171 },
    { lat: 48.8301962, lng: 21.4403212 },
    { lat: 48.8310596, lng: 21.4399486 },
    { lat: 48.8312053, lng: 21.4403167 },
    { lat: 48.8313324, lng: 21.4403911 },
    { lat: 48.8314654, lng: 21.4405943 },
    { lat: 48.831496, lng: 21.4407912 },
    { lat: 48.8316368, lng: 21.4410585 },
    { lat: 48.8317497, lng: 21.4411138 },
    { lat: 48.8316798, lng: 21.4413042 },
    { lat: 48.831893, lng: 21.4415874 },
    { lat: 48.8318695, lng: 21.4416718 },
    { lat: 48.8319728, lng: 21.4420539 },
    { lat: 48.8320867, lng: 21.442253 },
    { lat: 48.8320867, lng: 21.442407 },
    { lat: 48.8331678, lng: 21.4440535 },
    { lat: 48.833592, lng: 21.4438208 },
    { lat: 48.8338599, lng: 21.4435953 },
    { lat: 48.8339863, lng: 21.4434107 },
    { lat: 48.8334549, lng: 21.4400583 },
    { lat: 48.8333232, lng: 21.4396246 },
    { lat: 48.8332954, lng: 21.4391022 },
    { lat: 48.8329501, lng: 21.4382189 },
    { lat: 48.8318945, lng: 21.43434 },
    { lat: 48.8316194, lng: 21.4321695 },
    { lat: 48.8316519, lng: 21.4313141 },
    { lat: 48.8315705, lng: 21.4308063 },
    { lat: 48.8315654, lng: 21.4282934 },
    { lat: 48.8319292, lng: 21.4252391 },
    { lat: 48.8319703, lng: 21.4243767 },
    { lat: 48.8319096, lng: 21.4229193 },
    { lat: 48.8320167, lng: 21.4216692 },
    { lat: 48.8320197, lng: 21.4216518 },
    { lat: 48.8320688, lng: 21.4216764 },
    { lat: 48.8325032, lng: 21.4219128 },
    { lat: 48.8325615, lng: 21.4221317 },
    { lat: 48.8327967, lng: 21.4223991 },
    { lat: 48.833083, lng: 21.4225712 },
    { lat: 48.8333538, lng: 21.4225987 },
    { lat: 48.833387, lng: 21.4225004 },
    { lat: 48.8334701, lng: 21.4224566 },
    { lat: 48.8336632, lng: 21.4224385 },
    { lat: 48.8338267, lng: 21.4225693 },
    { lat: 48.8342419, lng: 21.4226403 },
    { lat: 48.8345906, lng: 21.4224867 },
    { lat: 48.8349627, lng: 21.4219048 },
    { lat: 48.8350875, lng: 21.4217864 },
    { lat: 48.8354963, lng: 21.4216381 },
    { lat: 48.8358485, lng: 21.4216457 },
    { lat: 48.83596, lng: 21.4212592 },
    { lat: 48.836328, lng: 21.4210004 },
    { lat: 48.8364723, lng: 21.4210068 },
    { lat: 48.8367884, lng: 21.4213484 },
    { lat: 48.8368908, lng: 21.4211175 },
    { lat: 48.8371142, lng: 21.4213703 },
    { lat: 48.8373458, lng: 21.4213972 },
    { lat: 48.8375338, lng: 21.4211727 },
    { lat: 48.8378654, lng: 21.4203595 },
    { lat: 48.8381901, lng: 21.4203047 },
    { lat: 48.8384138, lng: 21.4205271 },
    { lat: 48.8384935, lng: 21.4209765 },
    { lat: 48.8384995, lng: 21.421868 },
    { lat: 48.8387936, lng: 21.4225687 },
    { lat: 48.8393094, lng: 21.4227418 },
    { lat: 48.839447, lng: 21.4230586 },
    { lat: 48.8396138, lng: 21.4232213 },
    { lat: 48.8398459, lng: 21.4232675 },
    { lat: 48.8399304, lng: 21.4232375 },
    { lat: 48.8402261, lng: 21.422805 },
    { lat: 48.8413418, lng: 21.4227472 },
    { lat: 48.8417223, lng: 21.4225655 },
    { lat: 48.842058, lng: 21.4218502 },
    { lat: 48.8421553, lng: 21.4212159 },
    { lat: 48.8421767, lng: 21.4203789 },
    { lat: 48.8422519, lng: 21.4200259 },
    { lat: 48.8423977, lng: 21.4197003 },
    { lat: 48.8426304, lng: 21.4193849 },
    { lat: 48.8432193, lng: 21.4189825 },
    { lat: 48.8437291, lng: 21.4190617 },
    { lat: 48.8440243, lng: 21.4190371 },
    { lat: 48.844401, lng: 21.4187681 },
    { lat: 48.844138, lng: 21.4181174 },
    { lat: 48.8439412, lng: 21.4180067 },
    { lat: 48.8439116, lng: 21.4176901 },
    { lat: 48.8440566, lng: 21.4168815 },
    { lat: 48.8440402, lng: 21.4165879 },
    { lat: 48.8442319, lng: 21.4159969 },
    { lat: 48.8442868, lng: 21.4154498 },
    { lat: 48.8444348, lng: 21.4150165 },
    { lat: 48.8449967, lng: 21.4141907 },
    { lat: 48.8444983, lng: 21.4128887 },
    { lat: 48.8442572, lng: 21.4125017 },
    { lat: 48.8439492, lng: 21.4117476 },
    { lat: 48.8444804, lng: 21.411179 },
    { lat: 48.8450792, lng: 21.4107262 },
    { lat: 48.8452265, lng: 21.4101075 },
    { lat: 48.8449205, lng: 21.4099271 },
    { lat: 48.8447124, lng: 21.4104192 },
    { lat: 48.8446505, lng: 21.410308 },
    { lat: 48.8437213, lng: 21.4106545 },
    { lat: 48.8437207, lng: 21.4101787 },
    { lat: 48.843863, lng: 21.4095103 },
    { lat: 48.8438358, lng: 21.4091256 },
    { lat: 48.8433656, lng: 21.4089721 },
    { lat: 48.8426379, lng: 21.4090585 },
    { lat: 48.8422101, lng: 21.4082187 },
    { lat: 48.8420673, lng: 21.4083334 },
    { lat: 48.8416738, lng: 21.4076617 },
    { lat: 48.840598, lng: 21.4048382 },
    { lat: 48.8404912, lng: 21.4041142 },
    { lat: 48.8399892, lng: 21.40236 },
    { lat: 48.8400208, lng: 21.4013369 },
    { lat: 48.8400092, lng: 21.4009076 },
    { lat: 48.8400851, lng: 21.4001652 },
    { lat: 48.8403334, lng: 21.3993517 },
    { lat: 48.840746, lng: 21.398536 },
    { lat: 48.8410007, lng: 21.3974888 },
    { lat: 48.8410196, lng: 21.3967567 },
    { lat: 48.8411287, lng: 21.3963774 },
    { lat: 48.8412082, lng: 21.3961023 },
    { lat: 48.841232, lng: 21.3959485 },
    { lat: 48.8414057, lng: 21.3946031 },
    { lat: 48.841095, lng: 21.3930757 },
    { lat: 48.8410366, lng: 21.3908387 },
    { lat: 48.8411612, lng: 21.3895771 },
    { lat: 48.8405962, lng: 21.3892351 },
    { lat: 48.8403546, lng: 21.3889022 },
    { lat: 48.8401204, lng: 21.3887431 },
    { lat: 48.8397691, lng: 21.3883627 },
    { lat: 48.8397358, lng: 21.3881078 },
    { lat: 48.8394764, lng: 21.3876675 },
    { lat: 48.8391031, lng: 21.3871914 },
    { lat: 48.8385146, lng: 21.3868511 },
    { lat: 48.8377377, lng: 21.3868304 },
    { lat: 48.8365404, lng: 21.3870624 },
    { lat: 48.8361193, lng: 21.3872383 },
    { lat: 48.8355316, lng: 21.3876076 },
    { lat: 48.8350542, lng: 21.3877541 },
    { lat: 48.8348095, lng: 21.3879612 },
    { lat: 48.83453, lng: 21.3883235 },
    { lat: 48.8343633, lng: 21.3883577 },
    { lat: 48.8340348, lng: 21.3882458 },
    { lat: 48.8339076, lng: 21.3880912 },
    { lat: 48.8335778, lng: 21.3879336 },
    { lat: 48.8333924, lng: 21.3876759 },
    { lat: 48.8324876, lng: 21.3872214 },
    { lat: 48.8322032, lng: 21.3869279 },
    { lat: 48.8319977, lng: 21.3866073 },
    { lat: 48.8315055, lng: 21.3862069 },
    { lat: 48.8311856, lng: 21.3860433 },
    { lat: 48.8310063, lng: 21.3859598 },
    { lat: 48.8308503, lng: 21.3860111 },
    { lat: 48.8305595, lng: 21.3858017 },
    { lat: 48.8298215, lng: 21.3856623 },
    { lat: 48.8297062, lng: 21.3857881 },
    { lat: 48.8295034, lng: 21.3858597 },
    { lat: 48.82891, lng: 21.3856963 },
    { lat: 48.8289047, lng: 21.3854763 },
    { lat: 48.8283865, lng: 21.3852677 },
    { lat: 48.8281404, lng: 21.3850559 },
    { lat: 48.8279151, lng: 21.3850037 },
    { lat: 48.827665, lng: 21.3850693 },
    { lat: 48.8274173, lng: 21.3850344 },
    { lat: 48.8274832, lng: 21.3847521 },
    { lat: 48.8272515, lng: 21.384264 },
    { lat: 48.8271458, lng: 21.3841505 },
    { lat: 48.8268098, lng: 21.3840484 },
    { lat: 48.8265157, lng: 21.3837742 },
    { lat: 48.8260906, lng: 21.3835943 },
    { lat: 48.8258203, lng: 21.3831634 },
    { lat: 48.8252644, lng: 21.3830882 },
    { lat: 48.8251511, lng: 21.3833291 },
    { lat: 48.8250445, lng: 21.3833381 },
    { lat: 48.8246515, lng: 21.3830325 },
    { lat: 48.8247173, lng: 21.3827444 },
    { lat: 48.8235817, lng: 21.3826187 },
    { lat: 48.8236797, lng: 21.3812293 },
    { lat: 48.8233148, lng: 21.379323 },
    { lat: 48.8230019, lng: 21.377512 },
    { lat: 48.822887, lng: 21.3764489 },
    { lat: 48.8222657, lng: 21.3730479 },
    { lat: 48.8222505, lng: 21.3729111 },
    { lat: 48.8219563, lng: 21.3725566 },
    { lat: 48.8205542, lng: 21.3718348 },
    { lat: 48.8195003, lng: 21.3706465 },
    { lat: 48.8192463, lng: 21.3705514 },
    { lat: 48.8187721, lng: 21.3703567 },
    { lat: 48.8181621, lng: 21.3702146 },
    { lat: 48.8157711, lng: 21.370275 },
    { lat: 48.8150982, lng: 21.3702697 },
    { lat: 48.8146178, lng: 21.3703555 },
    { lat: 48.8145679, lng: 21.3706734 },
    { lat: 48.8135956, lng: 21.3709378 },
  ],
  Herľany: [
    { lat: 48.779264, lng: 21.4697142 },
    { lat: 48.779499, lng: 21.4699048 },
    { lat: 48.7799595, lng: 21.4701835 },
    { lat: 48.7805946, lng: 21.4704318 },
    { lat: 48.7819573, lng: 21.4707408 },
    { lat: 48.7831217, lng: 21.4710208 },
    { lat: 48.7836062, lng: 21.4711059 },
    { lat: 48.7842262, lng: 21.4721215 },
    { lat: 48.7847037, lng: 21.4727578 },
    { lat: 48.7852103, lng: 21.473654 },
    { lat: 48.7854796, lng: 21.4747569 },
    { lat: 48.7906611, lng: 21.4779816 },
    { lat: 48.7918845, lng: 21.4788829 },
    { lat: 48.7923413, lng: 21.4797338 },
    { lat: 48.7926942, lng: 21.4801572 },
    { lat: 48.7928545, lng: 21.4807158 },
    { lat: 48.7931563, lng: 21.4813032 },
    { lat: 48.7940783, lng: 21.484752 },
    { lat: 48.7941562, lng: 21.4854252 },
    { lat: 48.7943832, lng: 21.4863442 },
    { lat: 48.7953695, lng: 21.488477 },
    { lat: 48.7952412, lng: 21.4890561 },
    { lat: 48.7953019, lng: 21.4899518 },
    { lat: 48.7955647, lng: 21.4905233 },
    { lat: 48.795976, lng: 21.4923409 },
    { lat: 48.7958858, lng: 21.4927223 },
    { lat: 48.7958471, lng: 21.4927116 },
    { lat: 48.7958314, lng: 21.4930999 },
    { lat: 48.7960635, lng: 21.4937027 },
    { lat: 48.7962274, lng: 21.4945045 },
    { lat: 48.7965793, lng: 21.4951216 },
    { lat: 48.7966467, lng: 21.4954323 },
    { lat: 48.7963101, lng: 21.4960494 },
    { lat: 48.7965252, lng: 21.4962918 },
    { lat: 48.7965307, lng: 21.4967522 },
    { lat: 48.797018, lng: 21.4979321 },
    { lat: 48.7970241, lng: 21.4981112 },
    { lat: 48.7971167, lng: 21.49824 },
    { lat: 48.7972197, lng: 21.4985669 },
    { lat: 48.7972254, lng: 21.4987632 },
    { lat: 48.797387, lng: 21.4992986 },
    { lat: 48.7972666, lng: 21.4995314 },
    { lat: 48.7974005, lng: 21.4997688 },
    { lat: 48.7973928, lng: 21.4999681 },
    { lat: 48.7974775, lng: 21.5001353 },
    { lat: 48.7974909, lng: 21.500331 },
    { lat: 48.7976705, lng: 21.5004755 },
    { lat: 48.7977956, lng: 21.5006771 },
    { lat: 48.7978655, lng: 21.5009181 },
    { lat: 48.7980541, lng: 21.5010977 },
    { lat: 48.7981595, lng: 21.5011239 },
    { lat: 48.7982346, lng: 21.5013906 },
    { lat: 48.7984035, lng: 21.5013934 },
    { lat: 48.7986248, lng: 21.5016929 },
    { lat: 48.7987174, lng: 21.5017421 },
    { lat: 48.798807, lng: 21.5020781 },
    { lat: 48.79889, lng: 21.5022658 },
    { lat: 48.7989594, lng: 21.502294 },
    { lat: 48.7990523, lng: 21.502547 },
    { lat: 48.7993191, lng: 21.5026744 },
    { lat: 48.7994977, lng: 21.5032126 },
    { lat: 48.799607, lng: 21.503259 },
    { lat: 48.7996766, lng: 21.5033686 },
    { lat: 48.7996997, lng: 21.5035487 },
    { lat: 48.7996547, lng: 21.5039018 },
    { lat: 48.7997543, lng: 21.5041929 },
    { lat: 48.8000632, lng: 21.5043329 },
    { lat: 48.8001256, lng: 21.5045443 },
    { lat: 48.8000984, lng: 21.5047317 },
    { lat: 48.8002303, lng: 21.5052909 },
    { lat: 48.8003391, lng: 21.5054761 },
    { lat: 48.8004864, lng: 21.5055892 },
    { lat: 48.8006205, lng: 21.5060323 },
    { lat: 48.8009476, lng: 21.5061335 },
    { lat: 48.8015896, lng: 21.5064682 },
    { lat: 48.8017016, lng: 21.5066497 },
    { lat: 48.8018579, lng: 21.5067219 },
    { lat: 48.8019435, lng: 21.5069211 },
    { lat: 48.8015984, lng: 21.5102703 },
    { lat: 48.8010062, lng: 21.5178459 },
    { lat: 48.8005917, lng: 21.5222031 },
    { lat: 48.80028, lng: 21.5265025 },
    { lat: 48.7999992, lng: 21.5292077 },
    { lat: 48.7998604, lng: 21.5301779 },
    { lat: 48.8001954, lng: 21.5302435 },
    { lat: 48.7996556, lng: 21.5350794 },
    { lat: 48.799242, lng: 21.53937 },
    { lat: 48.799568, lng: 21.539285 },
    { lat: 48.800196, lng: 21.539131 },
    { lat: 48.800923, lng: 21.538782 },
    { lat: 48.801962, lng: 21.538344 },
    { lat: 48.802366, lng: 21.537978 },
    { lat: 48.802788, lng: 21.537065 },
    { lat: 48.803281, lng: 21.536237 },
    { lat: 48.803465, lng: 21.535245 },
    { lat: 48.804208, lng: 21.534417 },
    { lat: 48.805066, lng: 21.532952 },
    { lat: 48.806084, lng: 21.531313 },
    { lat: 48.806176, lng: 21.531217 },
    { lat: 48.806624, lng: 21.530505 },
    { lat: 48.806919, lng: 21.530393 },
    { lat: 48.808043, lng: 21.530123 },
    { lat: 48.808499, lng: 21.530035 },
    { lat: 48.809193, lng: 21.5297 },
    { lat: 48.809701, lng: 21.52932 },
    { lat: 48.810299, lng: 21.528321 },
    { lat: 48.810804, lng: 21.527795 },
    { lat: 48.811644, lng: 21.526833 },
    { lat: 48.812011, lng: 21.526413 },
    { lat: 48.812012, lng: 21.526412 },
    { lat: 48.812237, lng: 21.526306 },
    { lat: 48.812651, lng: 21.525905 },
    { lat: 48.812715, lng: 21.525809 },
    { lat: 48.812861, lng: 21.525804 },
    { lat: 48.81295, lng: 21.525781 },
    { lat: 48.812987, lng: 21.525784 },
    { lat: 48.813034, lng: 21.525771 },
    { lat: 48.813145, lng: 21.525762 },
    { lat: 48.813314, lng: 21.525727 },
    { lat: 48.813592, lng: 21.525856 },
    { lat: 48.813675, lng: 21.525849 },
    { lat: 48.813776, lng: 21.52583 },
    { lat: 48.813853, lng: 21.525798 },
    { lat: 48.81386, lng: 21.525814 },
    { lat: 48.813881, lng: 21.525835 },
    { lat: 48.813988, lng: 21.525863 },
    { lat: 48.814222, lng: 21.525878 },
    { lat: 48.81442, lng: 21.525938 },
    { lat: 48.814552, lng: 21.525946 },
    { lat: 48.814677, lng: 21.526001 },
    { lat: 48.814743, lng: 21.525991 },
    { lat: 48.814913, lng: 21.526006 },
    { lat: 48.815667, lng: 21.526209 },
    { lat: 48.81569, lng: 21.526222 },
    { lat: 48.81603, lng: 21.526508 },
    { lat: 48.816265, lng: 21.526822 },
    { lat: 48.816384, lng: 21.527073 },
    { lat: 48.816479, lng: 21.52728 },
    { lat: 48.81659, lng: 21.527587 },
    { lat: 48.816868, lng: 21.527881 },
    { lat: 48.816952, lng: 21.527981 },
    { lat: 48.816973, lng: 21.527998 },
    { lat: 48.817136, lng: 21.528114 },
    { lat: 48.817315, lng: 21.528233 },
    { lat: 48.817662, lng: 21.528342 },
    { lat: 48.817809, lng: 21.528356 },
    { lat: 48.817884, lng: 21.52837 },
    { lat: 48.817986, lng: 21.528433 },
    { lat: 48.818166, lng: 21.528538 },
    { lat: 48.818265, lng: 21.528571 },
    { lat: 48.818442, lng: 21.528658 },
    { lat: 48.818967, lng: 21.528859 },
    { lat: 48.819129, lng: 21.528892 },
    { lat: 48.819632, lng: 21.529113 },
    { lat: 48.819813, lng: 21.529208 },
    { lat: 48.8200106, lng: 21.5281438 },
    { lat: 48.819658, lng: 21.5265523 },
    { lat: 48.8192181, lng: 21.5264725 },
    { lat: 48.8187105, lng: 21.5261505 },
    { lat: 48.8185804, lng: 21.5261279 },
    { lat: 48.8183838, lng: 21.5259469 },
    { lat: 48.8173711, lng: 21.5236567 },
    { lat: 48.8171673, lng: 21.5235265 },
    { lat: 48.8169689, lng: 21.5230875 },
    { lat: 48.8169581, lng: 21.5228647 },
    { lat: 48.8168037, lng: 21.522617 },
    { lat: 48.816723, lng: 21.5223506 },
    { lat: 48.8162987, lng: 21.5216779 },
    { lat: 48.8158245, lng: 21.5211887 },
    { lat: 48.8157111, lng: 21.5208848 },
    { lat: 48.8154804, lng: 21.519856 },
    { lat: 48.8156266, lng: 21.5191042 },
    { lat: 48.8156265, lng: 21.5185901 },
    { lat: 48.8156702, lng: 21.5185008 },
    { lat: 48.8155988, lng: 21.5183641 },
    { lat: 48.8155106, lng: 21.5176759 },
    { lat: 48.8153931, lng: 21.5174672 },
    { lat: 48.8153382, lng: 21.5171245 },
    { lat: 48.8154194, lng: 21.5170531 },
    { lat: 48.8147581, lng: 21.5158001 },
    { lat: 48.8142855, lng: 21.5142235 },
    { lat: 48.8144649, lng: 21.5136948 },
    { lat: 48.8141572, lng: 21.5128439 },
    { lat: 48.8138482, lng: 21.5123861 },
    { lat: 48.8132493, lng: 21.5108464 },
    { lat: 48.8131654, lng: 21.5099605 },
    { lat: 48.8134474, lng: 21.5089637 },
    { lat: 48.8135834, lng: 21.5075061 },
    { lat: 48.8135057, lng: 21.5062524 },
    { lat: 48.813326, lng: 21.5049693 },
    { lat: 48.8130499, lng: 21.5047561 },
    { lat: 48.8128903, lng: 21.5043679 },
    { lat: 48.8127169, lng: 21.5031894 },
    { lat: 48.8127751, lng: 21.5014891 },
    { lat: 48.8128723, lng: 21.5006466 },
    { lat: 48.8125834, lng: 21.499521 },
    { lat: 48.8122735, lng: 21.4973937 },
    { lat: 48.8119164, lng: 21.4953392 },
    { lat: 48.8115354, lng: 21.494027 },
    { lat: 48.8116078, lng: 21.4932179 },
    { lat: 48.8114847, lng: 21.492889 },
    { lat: 48.8113237, lng: 21.4920325 },
    { lat: 48.8112894, lng: 21.491193 },
    { lat: 48.8114739, lng: 21.490485 },
    { lat: 48.8114094, lng: 21.4895714 },
    { lat: 48.8112162, lng: 21.4891926 },
    { lat: 48.8112078, lng: 21.489055 },
    { lat: 48.8111222, lng: 21.4889814 },
    { lat: 48.8110595, lng: 21.4890309 },
    { lat: 48.8107955, lng: 21.4890012 },
    { lat: 48.8107292, lng: 21.4888938 },
    { lat: 48.8105447, lng: 21.48889 },
    { lat: 48.8100124, lng: 21.4886581 },
    { lat: 48.8098615, lng: 21.4888041 },
    { lat: 48.8096752, lng: 21.4888286 },
    { lat: 48.8095938, lng: 21.488857 },
    { lat: 48.8095517, lng: 21.4888703 },
    { lat: 48.8091624, lng: 21.4888685 },
    { lat: 48.8083642, lng: 21.488549 },
    { lat: 48.8077551, lng: 21.4881055 },
    { lat: 48.8075334, lng: 21.4881006 },
    { lat: 48.8072489, lng: 21.4875664 },
    { lat: 48.8067795, lng: 21.4873633 },
    { lat: 48.8066911, lng: 21.4871428 },
    { lat: 48.8066913, lng: 21.4869954 },
    { lat: 48.8062945, lng: 21.4868164 },
    { lat: 48.8062206, lng: 21.4868277 },
    { lat: 48.8061821, lng: 21.4869734 },
    { lat: 48.8059816, lng: 21.4868488 },
    { lat: 48.8059924, lng: 21.4866022 },
    { lat: 48.8057876, lng: 21.4862127 },
    { lat: 48.805527, lng: 21.4862582 },
    { lat: 48.8054696, lng: 21.4862227 },
    { lat: 48.8054154, lng: 21.4860207 },
    { lat: 48.805263, lng: 21.4861235 },
    { lat: 48.8050995, lng: 21.4860705 },
    { lat: 48.8049931, lng: 21.4861243 },
    { lat: 48.8048466, lng: 21.4858056 },
    { lat: 48.8045897, lng: 21.4857919 },
    { lat: 48.8044542, lng: 21.4858908 },
    { lat: 48.8043775, lng: 21.4855789 },
    { lat: 48.8041807, lng: 21.4852321 },
    { lat: 48.8039569, lng: 21.4850399 },
    { lat: 48.8035983, lng: 21.4848555 },
    { lat: 48.8035088, lng: 21.484482 },
    { lat: 48.8033833, lng: 21.4845767 },
    { lat: 48.8032217, lng: 21.4845192 },
    { lat: 48.8032174, lng: 21.4842864 },
    { lat: 48.802888, lng: 21.4834055 },
    { lat: 48.8023953, lng: 21.4831726 },
    { lat: 48.8023707, lng: 21.4830232 },
    { lat: 48.802254, lng: 21.4828428 },
    { lat: 48.8021676, lng: 21.4824552 },
    { lat: 48.8020971, lng: 21.4823567 },
    { lat: 48.8020093, lng: 21.482435 },
    { lat: 48.8019264, lng: 21.4823673 },
    { lat: 48.8019028, lng: 21.4820951 },
    { lat: 48.8016442, lng: 21.4817571 },
    { lat: 48.8015656, lng: 21.4814818 },
    { lat: 48.8015764, lng: 21.4809581 },
    { lat: 48.8015147, lng: 21.4808569 },
    { lat: 48.8014647, lng: 21.479984 },
    { lat: 48.8014107, lng: 21.4798591 },
    { lat: 48.8014753, lng: 21.479062 },
    { lat: 48.8014369, lng: 21.4789004 },
    { lat: 48.8027273, lng: 21.4777135 },
    { lat: 48.8030019, lng: 21.477461 },
    { lat: 48.8031441, lng: 21.4775897 },
    { lat: 48.8035365, lng: 21.4773074 },
    { lat: 48.80347, lng: 21.4770687 },
    { lat: 48.8032864, lng: 21.4768278 },
    { lat: 48.8029338, lng: 21.4755716 },
    { lat: 48.8019559, lng: 21.4764217 },
    { lat: 48.8013268, lng: 21.4755272 },
    { lat: 48.8019532, lng: 21.4744252 },
    { lat: 48.8026581, lng: 21.473435 },
    { lat: 48.8025515, lng: 21.4732069 },
    { lat: 48.802124, lng: 21.4729365 },
    { lat: 48.8015537, lng: 21.4736458 },
    { lat: 48.8010225, lng: 21.4741443 },
    { lat: 48.8005149, lng: 21.4748059 },
    { lat: 48.8003244, lng: 21.4747896 },
    { lat: 48.8002915, lng: 21.474901 },
    { lat: 48.8000332, lng: 21.4746026 },
    { lat: 48.7999549, lng: 21.4746596 },
    { lat: 48.7998161, lng: 21.4746049 },
    { lat: 48.7995906, lng: 21.4742044 },
    { lat: 48.7993758, lng: 21.4741305 },
    { lat: 48.7992596, lng: 21.474271 },
    { lat: 48.7991662, lng: 21.4742782 },
    { lat: 48.7989546, lng: 21.4741517 },
    { lat: 48.7987739, lng: 21.4742069 },
    { lat: 48.7987863, lng: 21.4735921 },
    { lat: 48.7982645, lng: 21.4735197 },
    { lat: 48.798025, lng: 21.4730959 },
    { lat: 48.79797, lng: 21.4730635 },
    { lat: 48.797908, lng: 21.4727121 },
    { lat: 48.7976944, lng: 21.4726796 },
    { lat: 48.797628, lng: 21.4726026 },
    { lat: 48.7982531, lng: 21.4712277 },
    { lat: 48.7987115, lng: 21.4705377 },
    { lat: 48.8000674, lng: 21.4682496 },
    { lat: 48.8001671, lng: 21.4681591 },
    { lat: 48.8001862, lng: 21.4680454 },
    { lat: 48.8003431, lng: 21.4677876 },
    { lat: 48.8003354, lng: 21.4677178 },
    { lat: 48.8011476, lng: 21.4663185 },
    { lat: 48.800541, lng: 21.4655333 },
    { lat: 48.8000283, lng: 21.4646612 },
    { lat: 48.7995031, lng: 21.4635876 },
    { lat: 48.7994373, lng: 21.4631808 },
    { lat: 48.7994232, lng: 21.462727 },
    { lat: 48.7995534, lng: 21.4614813 },
    { lat: 48.7993887, lng: 21.4613541 },
    { lat: 48.7981032, lng: 21.4610233 },
    { lat: 48.7983455, lng: 21.4591369 },
    { lat: 48.7988793, lng: 21.4557902 },
    { lat: 48.7982197, lng: 21.4554741 },
    { lat: 48.7981131, lng: 21.4557136 },
    { lat: 48.797267, lng: 21.4555659 },
    { lat: 48.7961788, lng: 21.4551771 },
    { lat: 48.7963347, lng: 21.454425 },
    { lat: 48.793224, lng: 21.452479 },
    { lat: 48.7915388, lng: 21.4519438 },
    { lat: 48.791012, lng: 21.4517092 },
    { lat: 48.7913295, lng: 21.4501389 },
    { lat: 48.790108, lng: 21.4496478 },
    { lat: 48.7897564, lng: 21.4495171 },
    { lat: 48.7874066, lng: 21.4483497 },
    { lat: 48.7868817, lng: 21.4480279 },
    { lat: 48.7855517, lng: 21.4476437 },
    { lat: 48.7851821, lng: 21.4473495 },
    { lat: 48.7841529, lng: 21.4467832 },
    { lat: 48.7834367, lng: 21.446612 },
    { lat: 48.7830738, lng: 21.4465938 },
    { lat: 48.782694, lng: 21.4466542 },
    { lat: 48.7819426, lng: 21.4469689 },
    { lat: 48.7816983, lng: 21.4486186 },
    { lat: 48.7815181, lng: 21.4509263 },
    { lat: 48.78137, lng: 21.4512873 },
    { lat: 48.7814912, lng: 21.4517405 },
    { lat: 48.7815315, lng: 21.4533919 },
    { lat: 48.7815802, lng: 21.4537087 },
    { lat: 48.7815425, lng: 21.4537676 },
    { lat: 48.7818407, lng: 21.4553494 },
    { lat: 48.7812702, lng: 21.4557763 },
    { lat: 48.781032, lng: 21.4568344 },
    { lat: 48.780938, lng: 21.4573559 },
    { lat: 48.7809187, lng: 21.4594715 },
    { lat: 48.7807725, lng: 21.4610978 },
    { lat: 48.7803673, lng: 21.4623771 },
    { lat: 48.7793217, lng: 21.4643373 },
    { lat: 48.7790365, lng: 21.4659047 },
    { lat: 48.7791982, lng: 21.4664819 },
    { lat: 48.7792332, lng: 21.4668317 },
    { lat: 48.7793035, lng: 21.4669326 },
    { lat: 48.7790674, lng: 21.4683273 },
    { lat: 48.7791669, lng: 21.4693899 },
    { lat: 48.779264, lng: 21.4697142 },
  ],
  Čečejovce: [
    { lat: 48.5607374, lng: 21.0326484 },
    { lat: 48.560562, lng: 21.0329689 },
    { lat: 48.5599656, lng: 21.0331784 },
    { lat: 48.5594949, lng: 21.0332018 },
    { lat: 48.559246, lng: 21.0331284 },
    { lat: 48.5591143, lng: 21.0339139 },
    { lat: 48.5589392, lng: 21.0341693 },
    { lat: 48.5587293, lng: 21.0350087 },
    { lat: 48.5588834, lng: 21.0358919 },
    { lat: 48.5586837, lng: 21.0376575 },
    { lat: 48.5582754, lng: 21.0385876 },
    { lat: 48.5582189, lng: 21.0397473 },
    { lat: 48.5582528, lng: 21.0399257 },
    { lat: 48.5585283, lng: 21.0403613 },
    { lat: 48.5585359, lng: 21.0405583 },
    { lat: 48.5584251, lng: 21.040745 },
    { lat: 48.5580446, lng: 21.0409517 },
    { lat: 48.5577229, lng: 21.0409249 },
    { lat: 48.5567721, lng: 21.0416194 },
    { lat: 48.5562274, lng: 21.0506425 },
    { lat: 48.5562075, lng: 21.052574 },
    { lat: 48.5562269, lng: 21.0540239 },
    { lat: 48.5567112, lng: 21.0539954 },
    { lat: 48.5569843, lng: 21.054189 },
    { lat: 48.5579206, lng: 21.0561098 },
    { lat: 48.5586159, lng: 21.057715 },
    { lat: 48.5588476, lng: 21.0584319 },
    { lat: 48.5593953, lng: 21.0597319 },
    { lat: 48.5597283, lng: 21.0609632 },
    { lat: 48.559892, lng: 21.0621823 },
    { lat: 48.5605911, lng: 21.063286 },
    { lat: 48.5613256, lng: 21.065549 },
    { lat: 48.5620934, lng: 21.0672777 },
    { lat: 48.5623299, lng: 21.0678667 },
    { lat: 48.5627078, lng: 21.0682378 },
    { lat: 48.5661674, lng: 21.071607 },
    { lat: 48.5691425, lng: 21.0738645 },
    { lat: 48.5703093, lng: 21.0748856 },
    { lat: 48.5715674, lng: 21.0762914 },
    { lat: 48.5721033, lng: 21.0770596 },
    { lat: 48.5730084, lng: 21.0786589 },
    { lat: 48.5732529, lng: 21.0789376 },
    { lat: 48.5735357, lng: 21.0791193 },
    { lat: 48.5745271, lng: 21.0794633 },
    { lat: 48.5757549, lng: 21.0802861 },
    { lat: 48.5765045, lng: 21.080391 },
    { lat: 48.5767013, lng: 21.0802773 },
    { lat: 48.5774903, lng: 21.0802784 },
    { lat: 48.578107, lng: 21.0804863 },
    { lat: 48.5782924, lng: 21.0806091 },
    { lat: 48.578283, lng: 21.0809155 },
    { lat: 48.5796186, lng: 21.0810753 },
    { lat: 48.5804771, lng: 21.0810961 },
    { lat: 48.5805022, lng: 21.0802771 },
    { lat: 48.5823384, lng: 21.0806068 },
    { lat: 48.582339, lng: 21.0811399 },
    { lat: 48.5827311, lng: 21.0812589 },
    { lat: 48.5851582, lng: 21.0817558 },
    { lat: 48.586761, lng: 21.0819258 },
    { lat: 48.5873209, lng: 21.0820145 },
    { lat: 48.5876174, lng: 21.082131 },
    { lat: 48.5885877, lng: 21.0827917 },
    { lat: 48.5893968, lng: 21.0836492 },
    { lat: 48.5897494, lng: 21.0838379 },
    { lat: 48.5900664, lng: 21.0841377 },
    { lat: 48.5901841, lng: 21.0843271 },
    { lat: 48.5904248, lng: 21.0843946 },
    { lat: 48.5907513, lng: 21.0848477 },
    { lat: 48.5908515, lng: 21.0848826 },
    { lat: 48.5912974, lng: 21.0849233 },
    { lat: 48.5915601, lng: 21.0848356 },
    { lat: 48.5919183, lng: 21.0845991 },
    { lat: 48.5921173, lng: 21.0845553 },
    { lat: 48.5933278, lng: 21.0848119 },
    { lat: 48.5937083, lng: 21.0849573 },
    { lat: 48.5950669, lng: 21.0851639 },
    { lat: 48.5953906, lng: 21.0852905 },
    { lat: 48.5956631, lng: 21.0852583 },
    { lat: 48.5966267, lng: 21.0854357 },
    { lat: 48.5970518, lng: 21.0857077 },
    { lat: 48.5971964, lng: 21.0859978 },
    { lat: 48.5972752, lng: 21.0860364 },
    { lat: 48.5973951, lng: 21.0862278 },
    { lat: 48.5980394, lng: 21.0864786 },
    { lat: 48.5981778, lng: 21.0868997 },
    { lat: 48.598386, lng: 21.0868616 },
    { lat: 48.5985679, lng: 21.0869151 },
    { lat: 48.598896, lng: 21.0871113 },
    { lat: 48.5990959, lng: 21.0873405 },
    { lat: 48.6000478, lng: 21.0879883 },
    { lat: 48.6001958, lng: 21.0881786 },
    { lat: 48.6005244, lng: 21.0883841 },
    { lat: 48.6008324, lng: 21.0886537 },
    { lat: 48.6009805, lng: 21.0889134 },
    { lat: 48.6012222, lng: 21.0890076 },
    { lat: 48.6017762, lng: 21.0894298 },
    { lat: 48.6021204, lng: 21.0894973 },
    { lat: 48.6022823, lng: 21.0896585 },
    { lat: 48.6030046, lng: 21.089829 },
    { lat: 48.6048782, lng: 21.0911305 },
    { lat: 48.6054354, lng: 21.0914479 },
    { lat: 48.6058848, lng: 21.0915958 },
    { lat: 48.6058916, lng: 21.0916679 },
    { lat: 48.6066111, lng: 21.0916056 },
    { lat: 48.6066183, lng: 21.091757 },
    { lat: 48.6072679, lng: 21.091553 },
    { lat: 48.6073343, lng: 21.0918351 },
    { lat: 48.6079739, lng: 21.0919509 },
    { lat: 48.6080321, lng: 21.0921824 },
    { lat: 48.6082601, lng: 21.0920977 },
    { lat: 48.6083911, lng: 21.0921467 },
    { lat: 48.6084723, lng: 21.0925629 },
    { lat: 48.6094305, lng: 21.0930639 },
    { lat: 48.610844, lng: 21.0936836 },
    { lat: 48.6125873, lng: 21.0943036 },
    { lat: 48.6129179, lng: 21.0944717 },
    { lat: 48.6131909, lng: 21.0944878 },
    { lat: 48.6146502, lng: 21.0946141 },
    { lat: 48.6152189, lng: 21.0945488 },
    { lat: 48.6169738, lng: 21.0949417 },
    { lat: 48.6180872, lng: 21.0946026 },
    { lat: 48.6194117, lng: 21.0946609 },
    { lat: 48.6194075, lng: 21.0946021 },
    { lat: 48.620271, lng: 21.0944734 },
    { lat: 48.6202169, lng: 21.094158 },
    { lat: 48.6239514, lng: 21.0942224 },
    { lat: 48.6239556, lng: 21.0930697 },
    { lat: 48.6239864, lng: 21.0909915 },
    { lat: 48.6238183, lng: 21.0888316 },
    { lat: 48.6232572, lng: 21.0850272 },
    { lat: 48.6230969, lng: 21.0836622 },
    { lat: 48.6228145, lng: 21.080298 },
    { lat: 48.6226967, lng: 21.0802444 },
    { lat: 48.6226782, lng: 21.0786004 },
    { lat: 48.6225845, lng: 21.0778641 },
    { lat: 48.6223958, lng: 21.0751099 },
    { lat: 48.6225509, lng: 21.0719183 },
    { lat: 48.6229782, lng: 21.0719724 },
    { lat: 48.6226661, lng: 21.0705155 },
    { lat: 48.6231173, lng: 21.0688882 },
    { lat: 48.6232578, lng: 21.0688109 },
    { lat: 48.6233811, lng: 21.0688623 },
    { lat: 48.6235809, lng: 21.0687763 },
    { lat: 48.6236823, lng: 21.0688552 },
    { lat: 48.6237501, lng: 21.0688207 },
    { lat: 48.6237909, lng: 21.0689027 },
    { lat: 48.6240472, lng: 21.0690641 },
    { lat: 48.6243661, lng: 21.06877 },
    { lat: 48.6243828, lng: 21.0689719 },
    { lat: 48.6245103, lng: 21.0690475 },
    { lat: 48.6244013, lng: 21.0691559 },
    { lat: 48.6244792, lng: 21.069301 },
    { lat: 48.6246275, lng: 21.0692203 },
    { lat: 48.624765, lng: 21.0694011 },
    { lat: 48.6248016, lng: 21.069387 },
    { lat: 48.6248252, lng: 21.0692134 },
    { lat: 48.6249636, lng: 21.0692779 },
    { lat: 48.6250115, lng: 21.0695021 },
    { lat: 48.6250696, lng: 21.069528 },
    { lat: 48.6251864, lng: 21.0694507 },
    { lat: 48.625279, lng: 21.0692873 },
    { lat: 48.6254792, lng: 21.0694887 },
    { lat: 48.6254267, lng: 21.0697086 },
    { lat: 48.6255435, lng: 21.0697327 },
    { lat: 48.6256349, lng: 21.0696704 },
    { lat: 48.6260449, lng: 21.0680937 },
    { lat: 48.6263306, lng: 21.0672951 },
    { lat: 48.6264164, lng: 21.0660282 },
    { lat: 48.6269218, lng: 21.0661256 },
    { lat: 48.6275028, lng: 21.066065 },
    { lat: 48.6284586, lng: 21.0664506 },
    { lat: 48.6286176, lng: 21.0663584 },
    { lat: 48.6289746, lng: 21.0658775 },
    { lat: 48.6297251, lng: 21.0638385 },
    { lat: 48.6297553, lng: 21.0632471 },
    { lat: 48.6296422, lng: 21.0631274 },
    { lat: 48.6296942, lng: 21.0629573 },
    { lat: 48.6294832, lng: 21.0618027 },
    { lat: 48.6296527, lng: 21.0614461 },
    { lat: 48.6295594, lng: 21.0612477 },
    { lat: 48.629751, lng: 21.0609755 },
    { lat: 48.6297045, lng: 21.0603107 },
    { lat: 48.6298102, lng: 21.060167 },
    { lat: 48.6300098, lng: 21.060045 },
    { lat: 48.6303481, lng: 21.0600256 },
    { lat: 48.630387, lng: 21.0598373 },
    { lat: 48.630196, lng: 21.0597088 },
    { lat: 48.6301408, lng: 21.0593354 },
    { lat: 48.6302342, lng: 21.0589876 },
    { lat: 48.6302084, lng: 21.0585074 },
    { lat: 48.6304092, lng: 21.05828 },
    { lat: 48.6301205, lng: 21.0579894 },
    { lat: 48.6303821, lng: 21.0576974 },
    { lat: 48.6308536, lng: 21.0573421 },
    { lat: 48.6302989, lng: 21.0549794 },
    { lat: 48.6311373, lng: 21.0545199 },
    { lat: 48.6308393, lng: 21.0528082 },
    { lat: 48.6309684, lng: 21.0523204 },
    { lat: 48.630753, lng: 21.0501086 },
    { lat: 48.6307739, lng: 21.0486859 },
    { lat: 48.6308414, lng: 21.0482366 },
    { lat: 48.6309358, lng: 21.0477839 },
    { lat: 48.6312541, lng: 21.046778 },
    { lat: 48.6318247, lng: 21.0453356 },
    { lat: 48.6320601, lng: 21.0448591 },
    { lat: 48.6321744, lng: 21.0443958 },
    { lat: 48.6322266, lng: 21.0436233 },
    { lat: 48.631666, lng: 21.0435865 },
    { lat: 48.6305906, lng: 21.0433319 },
    { lat: 48.6300106, lng: 21.0429792 },
    { lat: 48.6294821, lng: 21.0425144 },
    { lat: 48.6278462, lng: 21.04202 },
    { lat: 48.6272919, lng: 21.0419051 },
    { lat: 48.626533, lng: 21.0420276 },
    { lat: 48.6262766, lng: 21.0421734 },
    { lat: 48.6257321, lng: 21.0431103 },
    { lat: 48.6256503, lng: 21.0433264 },
    { lat: 48.6255833, lng: 21.0437487 },
    { lat: 48.6251874, lng: 21.0448605 },
    { lat: 48.6247031, lng: 21.0458804 },
    { lat: 48.6229357, lng: 21.0467985 },
    { lat: 48.6225916, lng: 21.0468121 },
    { lat: 48.6202319, lng: 21.0476223 },
    { lat: 48.6197073, lng: 21.047411 },
    { lat: 48.6195265, lng: 21.0472483 },
    { lat: 48.6193549, lng: 21.0468567 },
    { lat: 48.6192509, lng: 21.0464695 },
    { lat: 48.6178058, lng: 21.0449573 },
    { lat: 48.6175551, lng: 21.044865 },
    { lat: 48.6159217, lng: 21.0447909 },
    { lat: 48.6149769, lng: 21.0446415 },
    { lat: 48.6112775, lng: 21.0446543 },
    { lat: 48.6097933, lng: 21.0445918 },
    { lat: 48.6045185, lng: 21.043174 },
    { lat: 48.6044771, lng: 21.0423225 },
    { lat: 48.6020243, lng: 21.0414301 },
    { lat: 48.6016043, lng: 21.0412189 },
    { lat: 48.6015771, lng: 21.0407715 },
    { lat: 48.6008812, lng: 21.0407249 },
    { lat: 48.6006797, lng: 21.0396959 },
    { lat: 48.6005811, lng: 21.0398469 },
    { lat: 48.5998264, lng: 21.0404804 },
    { lat: 48.599393, lng: 21.0405128 },
    { lat: 48.5980155, lng: 21.0408628 },
    { lat: 48.5969834, lng: 21.0408986 },
    { lat: 48.587217, lng: 21.041998 },
    { lat: 48.5762766, lng: 21.0389591 },
    { lat: 48.5758582, lng: 21.038931 },
    { lat: 48.5734509, lng: 21.0378173 },
    { lat: 48.5729703, lng: 21.0375408 },
    { lat: 48.5710982, lng: 21.036302 },
    { lat: 48.5700039, lng: 21.0354437 },
    { lat: 48.5670393, lng: 21.0341105 },
    { lat: 48.5644333, lng: 21.0327018 },
    { lat: 48.5630011, lng: 21.0325531 },
    { lat: 48.5623532, lng: 21.032394 },
    { lat: 48.5616013, lng: 21.0324669 },
    { lat: 48.5611729, lng: 21.0323099 },
    { lat: 48.5610425, lng: 21.032174 },
    { lat: 48.5607374, lng: 21.0326484 },
  ],
  Ďurkov: [
    { lat: 48.7025933, lng: 21.4710878 },
    { lat: 48.7027436, lng: 21.4712602 },
    { lat: 48.7027748, lng: 21.4712493 },
    { lat: 48.702793, lng: 21.470868 },
    { lat: 48.7028433, lng: 21.470782 },
    { lat: 48.7031616, lng: 21.470815 },
    { lat: 48.7034567, lng: 21.4704773 },
    { lat: 48.7035182, lng: 21.4705388 },
    { lat: 48.7035644, lng: 21.4704659 },
    { lat: 48.7037248, lng: 21.4704129 },
    { lat: 48.7039285, lng: 21.4701422 },
    { lat: 48.704268, lng: 21.469938 },
    { lat: 48.7044885, lng: 21.4699732 },
    { lat: 48.7046628, lng: 21.4698932 },
    { lat: 48.7047581, lng: 21.4699179 },
    { lat: 48.7053205, lng: 21.4694798 },
    { lat: 48.7054459, lng: 21.4696892 },
    { lat: 48.7055881, lng: 21.4696341 },
    { lat: 48.7060149, lng: 21.4696821 },
    { lat: 48.7061348, lng: 21.4698982 },
    { lat: 48.7062554, lng: 21.469985 },
    { lat: 48.706336, lng: 21.4699244 },
    { lat: 48.7064505, lng: 21.4699538 },
    { lat: 48.7065149, lng: 21.4700044 },
    { lat: 48.7064812, lng: 21.4701994 },
    { lat: 48.7065261, lng: 21.4703475 },
    { lat: 48.7069805, lng: 21.4704214 },
    { lat: 48.7070306, lng: 21.4705157 },
    { lat: 48.7073485, lng: 21.4704625 },
    { lat: 48.707494, lng: 21.4705501 },
    { lat: 48.7076989, lng: 21.4712167 },
    { lat: 48.7078223, lng: 21.4710816 },
    { lat: 48.7080349, lng: 21.4711609 },
    { lat: 48.7081932, lng: 21.4710335 },
    { lat: 48.7085608, lng: 21.4712507 },
    { lat: 48.7090818, lng: 21.4714114 },
    { lat: 48.709126, lng: 21.4718456 },
    { lat: 48.7092028, lng: 21.4719317 },
    { lat: 48.7092697, lng: 21.4721407 },
    { lat: 48.7096116, lng: 21.4725206 },
    { lat: 48.7099069, lng: 21.4723849 },
    { lat: 48.7101063, lng: 21.4723906 },
    { lat: 48.7104782, lng: 21.4720702 },
    { lat: 48.7105561, lng: 21.471765 },
    { lat: 48.7111575, lng: 21.4715474 },
    { lat: 48.7112455, lng: 21.4714054 },
    { lat: 48.7115361, lng: 21.4711792 },
    { lat: 48.7117188, lng: 21.4706914 },
    { lat: 48.7122035, lng: 21.4704116 },
    { lat: 48.7127585, lng: 21.4704927 },
    { lat: 48.7128627, lng: 21.4701338 },
    { lat: 48.712978, lng: 21.4701507 },
    { lat: 48.7132031, lng: 21.4708858 },
    { lat: 48.7133338, lng: 21.4710101 },
    { lat: 48.7134858, lng: 21.4708596 },
    { lat: 48.7140841, lng: 21.4706136 },
    { lat: 48.7144414, lng: 21.4709084 },
    { lat: 48.714834, lng: 21.4708988 },
    { lat: 48.7151051, lng: 21.4710201 },
    { lat: 48.715362, lng: 21.4709094 },
    { lat: 48.7154462, lng: 21.4707474 },
    { lat: 48.715575, lng: 21.4706936 },
    { lat: 48.7156728, lng: 21.4705706 },
    { lat: 48.715786, lng: 21.4700492 },
    { lat: 48.7160255, lng: 21.4700476 },
    { lat: 48.7162911, lng: 21.4698889 },
    { lat: 48.7166943, lng: 21.4695158 },
    { lat: 48.716693, lng: 21.4692041 },
    { lat: 48.7167787, lng: 21.4690533 },
    { lat: 48.7169396, lng: 21.4690566 },
    { lat: 48.717136, lng: 21.4685268 },
    { lat: 48.7171325, lng: 21.4683738 },
    { lat: 48.7169709, lng: 21.4680788 },
    { lat: 48.7171407, lng: 21.4674554 },
    { lat: 48.7172477, lng: 21.4672284 },
    { lat: 48.7174575, lng: 21.4670466 },
    { lat: 48.7175112, lng: 21.4669244 },
    { lat: 48.7174493, lng: 21.4665394 },
    { lat: 48.7173374, lng: 21.4663216 },
    { lat: 48.7173176, lng: 21.4660325 },
    { lat: 48.71747, lng: 21.4656611 },
    { lat: 48.7176875, lng: 21.4655928 },
    { lat: 48.7180113, lng: 21.4635355 },
    { lat: 48.7182171, lng: 21.4634516 },
    { lat: 48.7181339, lng: 21.4629512 },
    { lat: 48.7182238, lng: 21.4627186 },
    { lat: 48.7182511, lng: 21.4623853 },
    { lat: 48.718316, lng: 21.4621765 },
    { lat: 48.7181275, lng: 21.4617671 },
    { lat: 48.7181106, lng: 21.4616171 },
    { lat: 48.7182955, lng: 21.4613893 },
    { lat: 48.7182663, lng: 21.4608157 },
    { lat: 48.7183831, lng: 21.460691 },
    { lat: 48.7185402, lng: 21.460676 },
    { lat: 48.7191691, lng: 21.4600942 },
    { lat: 48.7193936, lng: 21.4600397 },
    { lat: 48.7198481, lng: 21.4598 },
    { lat: 48.7201195, lng: 21.459268 },
    { lat: 48.7205565, lng: 21.459204 },
    { lat: 48.7205983, lng: 21.4590933 },
    { lat: 48.7204675, lng: 21.4588126 },
    { lat: 48.7206494, lng: 21.4586469 },
    { lat: 48.7208981, lng: 21.4586824 },
    { lat: 48.7210115, lng: 21.4584799 },
    { lat: 48.7211956, lng: 21.4583511 },
    { lat: 48.7213382, lng: 21.4584221 },
    { lat: 48.721648, lng: 21.4587433 },
    { lat: 48.7218586, lng: 21.4587852 },
    { lat: 48.7222347, lng: 21.4584854 },
    { lat: 48.7223576, lng: 21.458227 },
    { lat: 48.7224561, lng: 21.4577804 },
    { lat: 48.7226837, lng: 21.4576387 },
    { lat: 48.7229132, lng: 21.4577545 },
    { lat: 48.7231542, lng: 21.4576102 },
    { lat: 48.7238052, lng: 21.4568968 },
    { lat: 48.7243886, lng: 21.4568344 },
    { lat: 48.7247553, lng: 21.456908 },
    { lat: 48.7249893, lng: 21.4567071 },
    { lat: 48.725088, lng: 21.4565434 },
    { lat: 48.725157, lng: 21.456177 },
    { lat: 48.7252647, lng: 21.4560031 },
    { lat: 48.725302, lng: 21.4556271 },
    { lat: 48.725525, lng: 21.4554796 },
    { lat: 48.7257859, lng: 21.4551668 },
    { lat: 48.7259077, lng: 21.4550977 },
    { lat: 48.7260691, lng: 21.4547302 },
    { lat: 48.7263007, lng: 21.4547964 },
    { lat: 48.7267939, lng: 21.4539634 },
    { lat: 48.7268056, lng: 21.453748 },
    { lat: 48.7267691, lng: 21.4536941 },
    { lat: 48.7267891, lng: 21.4536422 },
    { lat: 48.7268181, lng: 21.4536763 },
    { lat: 48.7275019, lng: 21.4515449 },
    { lat: 48.726994, lng: 21.4495669 },
    { lat: 48.7263094, lng: 21.4483934 },
    { lat: 48.7255338, lng: 21.4474975 },
    { lat: 48.7248116, lng: 21.4460068 },
    { lat: 48.7246434, lng: 21.4452536 },
    { lat: 48.7246559, lng: 21.4451081 },
    { lat: 48.7244916, lng: 21.4446821 },
    { lat: 48.7243733, lng: 21.4441383 },
    { lat: 48.7241654, lng: 21.4435357 },
    { lat: 48.7240265, lng: 21.4432845 },
    { lat: 48.7237473, lng: 21.4440505 },
    { lat: 48.7228212, lng: 21.4427008 },
    { lat: 48.7233208, lng: 21.4414343 },
    { lat: 48.7225998, lng: 21.4411393 },
    { lat: 48.7222636, lng: 21.4412634 },
    { lat: 48.7221373, lng: 21.4414436 },
    { lat: 48.7218771, lng: 21.4414714 },
    { lat: 48.721673, lng: 21.4412641 },
    { lat: 48.721004, lng: 21.4410954 },
    { lat: 48.7209833, lng: 21.4409582 },
    { lat: 48.7207735, lng: 21.4406401 },
    { lat: 48.7205489, lng: 21.4400796 },
    { lat: 48.7200536, lng: 21.4395675 },
    { lat: 48.7199907, lng: 21.4394267 },
    { lat: 48.7199781, lng: 21.4386831 },
    { lat: 48.7200237, lng: 21.4385478 },
    { lat: 48.7200708, lng: 21.4385457 },
    { lat: 48.7203666, lng: 21.4381553 },
    { lat: 48.7210226, lng: 21.4376181 },
    { lat: 48.7210488, lng: 21.4372889 },
    { lat: 48.7206391, lng: 21.4365131 },
    { lat: 48.7203203, lng: 21.435347 },
    { lat: 48.720386, lng: 21.4344863 },
    { lat: 48.7201832, lng: 21.4323635 },
    { lat: 48.7200641, lng: 21.4316812 },
    { lat: 48.7200117, lng: 21.4316658 },
    { lat: 48.7199476, lng: 21.4311267 },
    { lat: 48.7199362, lng: 21.430821 },
    { lat: 48.7200142, lng: 21.4304807 },
    { lat: 48.7199954, lng: 21.4299606 },
    { lat: 48.7197087, lng: 21.4290095 },
    { lat: 48.7197734, lng: 21.4283769 },
    { lat: 48.7195883, lng: 21.4273295 },
    { lat: 48.7196099, lng: 21.4266129 },
    { lat: 48.7193847, lng: 21.4258009 },
    { lat: 48.7193027, lng: 21.4250434 },
    { lat: 48.7193058, lng: 21.4248023 },
    { lat: 48.7193876, lng: 21.4244119 },
    { lat: 48.7193349, lng: 21.4239971 },
    { lat: 48.7193207, lng: 21.4230926 },
    { lat: 48.7192164, lng: 21.4226823 },
    { lat: 48.7193671, lng: 21.4220339 },
    { lat: 48.7193494, lng: 21.4217778 },
    { lat: 48.7195018, lng: 21.4211799 },
    { lat: 48.7194061, lng: 21.4211001 },
    { lat: 48.7144155, lng: 21.4197994 },
    { lat: 48.7141815, lng: 21.4179737 },
    { lat: 48.7095939, lng: 21.4152326 },
    { lat: 48.7088098, lng: 21.4158127 },
    { lat: 48.7086595, lng: 21.4140361 },
    { lat: 48.7082933, lng: 21.4141003 },
    { lat: 48.707951, lng: 21.41436 },
    { lat: 48.7078303, lng: 21.4146959 },
    { lat: 48.7078158, lng: 21.414962 },
    { lat: 48.7076945, lng: 21.4151201 },
    { lat: 48.7075689, lng: 21.4151393 },
    { lat: 48.7075168, lng: 21.4151211 },
    { lat: 48.7072865, lng: 21.4146446 },
    { lat: 48.7070843, lng: 21.4144662 },
    { lat: 48.7067645, lng: 21.4144172 },
    { lat: 48.7063682, lng: 21.4139159 },
    { lat: 48.7060978, lng: 21.413678 },
    { lat: 48.7058092, lng: 21.4135457 },
    { lat: 48.7054629, lng: 21.4136343 },
    { lat: 48.7053472, lng: 21.4139011 },
    { lat: 48.7052062, lng: 21.4146933 },
    { lat: 48.7048929, lng: 21.4155174 },
    { lat: 48.704772, lng: 21.4164123 },
    { lat: 48.7044293, lng: 21.4171126 },
    { lat: 48.7043212, lng: 21.4175724 },
    { lat: 48.7041692, lng: 21.4177611 },
    { lat: 48.7040017, lng: 21.4175725 },
    { lat: 48.7036831, lng: 21.4177956 },
    { lat: 48.7034637, lng: 21.418105 },
    { lat: 48.7032112, lng: 21.4183208 },
    { lat: 48.7028795, lng: 21.4187863 },
    { lat: 48.7025678, lng: 21.4190535 },
    { lat: 48.7022732, lng: 21.4189444 },
    { lat: 48.7021903, lng: 21.4187266 },
    { lat: 48.7022081, lng: 21.4184896 },
    { lat: 48.7020637, lng: 21.4182549 },
    { lat: 48.7017936, lng: 21.4179892 },
    { lat: 48.7011202, lng: 21.417549 },
    { lat: 48.7008912, lng: 21.4174942 },
    { lat: 48.7006478, lng: 21.4172901 },
    { lat: 48.7003871, lng: 21.4169205 },
    { lat: 48.7001439, lng: 21.4168036 },
    { lat: 48.7001272, lng: 21.4165408 },
    { lat: 48.700194, lng: 21.4160963 },
    { lat: 48.7004353, lng: 21.4158852 },
    { lat: 48.7004388, lng: 21.4156192 },
    { lat: 48.7002403, lng: 21.4153031 },
    { lat: 48.699882, lng: 21.414908 },
    { lat: 48.6996539, lng: 21.4148796 },
    { lat: 48.6994117, lng: 21.4151338 },
    { lat: 48.6985021, lng: 21.4156579 },
    { lat: 48.6982963, lng: 21.4163044 },
    { lat: 48.6982496, lng: 21.4165921 },
    { lat: 48.6981809, lng: 21.4174677 },
    { lat: 48.6981508, lng: 21.4176332 },
    { lat: 48.6980566, lng: 21.4219515 },
    { lat: 48.6978468, lng: 21.4286253 },
    { lat: 48.6977734, lng: 21.4297192 },
    { lat: 48.6978533, lng: 21.430033 },
    { lat: 48.6980402, lng: 21.4315685 },
    { lat: 48.698044, lng: 21.4324189 },
    { lat: 48.6981085, lng: 21.4325132 },
    { lat: 48.6981387, lng: 21.4327395 },
    { lat: 48.6981173, lng: 21.4359511 },
    { lat: 48.6982018, lng: 21.4390252 },
    { lat: 48.6984217, lng: 21.440726 },
    { lat: 48.6980317, lng: 21.4425513 },
    { lat: 48.6976543, lng: 21.4440705 },
    { lat: 48.6975728, lng: 21.4452097 },
    { lat: 48.6975797, lng: 21.4464445 },
    { lat: 48.6978319, lng: 21.4484064 },
    { lat: 48.697899, lng: 21.4501673 },
    { lat: 48.6984078, lng: 21.4526357 },
    { lat: 48.6983971, lng: 21.4537317 },
    { lat: 48.698888, lng: 21.4545938 },
    { lat: 48.6986657, lng: 21.4551465 },
    { lat: 48.6984075, lng: 21.4565198 },
    { lat: 48.6983348, lng: 21.457729 },
    { lat: 48.698339, lng: 21.458486 },
    { lat: 48.6972494, lng: 21.4604855 },
    { lat: 48.6968134, lng: 21.4614705 },
    { lat: 48.6965308, lng: 21.4626259 },
    { lat: 48.69703, lng: 21.4629992 },
    { lat: 48.6979793, lng: 21.4618981 },
    { lat: 48.6991676, lng: 21.4624845 },
    { lat: 48.7005243, lng: 21.4634373 },
    { lat: 48.700793, lng: 21.4640831 },
    { lat: 48.7011772, lng: 21.4654946 },
    { lat: 48.7017086, lng: 21.4665034 },
    { lat: 48.7019289, lng: 21.4671143 },
    { lat: 48.7023033, lng: 21.4685821 },
    { lat: 48.702256, lng: 21.4695844 },
    { lat: 48.7019861, lng: 21.470826 },
    { lat: 48.7023288, lng: 21.4707737 },
    { lat: 48.7024423, lng: 21.4709229 },
    { lat: 48.7024689, lng: 21.4710668 },
    { lat: 48.7025933, lng: 21.4710878 },
  ],
  VyšnýKlátov: [
    { lat: 48.7582989, lng: 21.0451195 },
    { lat: 48.7577166, lng: 21.04628 },
    { lat: 48.7574409, lng: 21.0468292 },
    { lat: 48.7580487, lng: 21.0479684 },
    { lat: 48.7585901, lng: 21.0497262 },
    { lat: 48.7588987, lng: 21.0530092 },
    { lat: 48.7615575, lng: 21.0575736 },
    { lat: 48.7623344, lng: 21.0584781 },
    { lat: 48.7625753, lng: 21.0588601 },
    { lat: 48.7629095, lng: 21.0590981 },
    { lat: 48.7633082, lng: 21.0597308 },
    { lat: 48.7633565, lng: 21.0599017 },
    { lat: 48.7634427, lng: 21.0607179 },
    { lat: 48.7633584, lng: 21.0626928 },
    { lat: 48.763276, lng: 21.0635448 },
    { lat: 48.7629739, lng: 21.0643293 },
    { lat: 48.7627199, lng: 21.0657206 },
    { lat: 48.7625891, lng: 21.0659551 },
    { lat: 48.7625662, lng: 21.0665347 },
    { lat: 48.7625218, lng: 21.06662 },
    { lat: 48.7621602, lng: 21.0667483 },
    { lat: 48.7620584, lng: 21.0668764 },
    { lat: 48.7619575, lng: 21.0670573 },
    { lat: 48.7617756, lng: 21.0676587 },
    { lat: 48.7614612, lng: 21.0680635 },
    { lat: 48.7610994, lng: 21.0688844 },
    { lat: 48.7609681, lng: 21.0689573 },
    { lat: 48.7607882, lng: 21.0689293 },
    { lat: 48.7601232, lng: 21.0693904 },
    { lat: 48.7596868, lng: 21.0701206 },
    { lat: 48.7593142, lng: 21.0709499 },
    { lat: 48.7590472, lng: 21.0712241 },
    { lat: 48.7585208, lng: 21.0719587 },
    { lat: 48.7583888, lng: 21.0720927 },
    { lat: 48.7574851, lng: 21.0726474 },
    { lat: 48.7569284, lng: 21.0727961 },
    { lat: 48.7564313, lng: 21.0732254 },
    { lat: 48.7561709, lng: 21.0735954 },
    { lat: 48.7558008, lng: 21.0738779 },
    { lat: 48.7549482, lng: 21.0747644 },
    { lat: 48.7545505, lng: 21.0757873 },
    { lat: 48.7543458, lng: 21.0761713 },
    { lat: 48.7540802, lng: 21.0764564 },
    { lat: 48.7540732, lng: 21.0767259 },
    { lat: 48.7542034, lng: 21.0775137 },
    { lat: 48.7539106, lng: 21.0780965 },
    { lat: 48.7535492, lng: 21.0785388 },
    { lat: 48.753325, lng: 21.0791607 },
    { lat: 48.7532496, lng: 21.0794752 },
    { lat: 48.7532394, lng: 21.079924 },
    { lat: 48.7529497, lng: 21.0809181 },
    { lat: 48.7529487, lng: 21.0812687 },
    { lat: 48.7528445, lng: 21.0815229 },
    { lat: 48.7526699, lng: 21.0827382 },
    { lat: 48.7525537, lng: 21.0831459 },
    { lat: 48.7525509, lng: 21.083374 },
    { lat: 48.7526429, lng: 21.0839218 },
    { lat: 48.7526368, lng: 21.0842508 },
    { lat: 48.7524835, lng: 21.0848692 },
    { lat: 48.7525406, lng: 21.0852769 },
    { lat: 48.7525268, lng: 21.0856906 },
    { lat: 48.7524403, lng: 21.0861803 },
    { lat: 48.7524523, lng: 21.0865885 },
    { lat: 48.7523858, lng: 21.0867172 },
    { lat: 48.7524431, lng: 21.0868941 },
    { lat: 48.7523997, lng: 21.086973 },
    { lat: 48.7524292, lng: 21.0879937 },
    { lat: 48.7520458, lng: 21.0885581 },
    { lat: 48.7516658, lng: 21.0887471 },
    { lat: 48.7510163, lng: 21.0896536 },
    { lat: 48.7506886, lng: 21.0897159 },
    { lat: 48.7504662, lng: 21.0900052 },
    { lat: 48.7502917, lng: 21.0903562 },
    { lat: 48.750234, lng: 21.0905866 },
    { lat: 48.7496197, lng: 21.0911213 },
    { lat: 48.7494988, lng: 21.091591 },
    { lat: 48.7492401, lng: 21.0919789 },
    { lat: 48.7491083, lng: 21.0919715 },
    { lat: 48.7490757, lng: 21.0920431 },
    { lat: 48.7491164, lng: 21.0921197 },
    { lat: 48.7489883, lng: 21.092301 },
    { lat: 48.7485152, lng: 21.0925007 },
    { lat: 48.7481354, lng: 21.0928326 },
    { lat: 48.74794, lng: 21.0930917 },
    { lat: 48.7479551, lng: 21.0932736 },
    { lat: 48.7474656, lng: 21.0936642 },
    { lat: 48.7472721, lng: 21.0939463 },
    { lat: 48.7468968, lng: 21.0940332 },
    { lat: 48.7466226, lng: 21.0941893 },
    { lat: 48.7464606, lng: 21.094503 },
    { lat: 48.7463341, lng: 21.0944856 },
    { lat: 48.746253, lng: 21.0947287 },
    { lat: 48.7461706, lng: 21.0947908 },
    { lat: 48.7459765, lng: 21.0951275 },
    { lat: 48.7456602, lng: 21.0953666 },
    { lat: 48.7453602, lng: 21.095418 },
    { lat: 48.7452681, lng: 21.0955963 },
    { lat: 48.7452281, lng: 21.0961039 },
    { lat: 48.7451577, lng: 21.096328 },
    { lat: 48.7450289, lng: 21.0963843 },
    { lat: 48.744942, lng: 21.0965861 },
    { lat: 48.7448656, lng: 21.0969751 },
    { lat: 48.7446688, lng: 21.0973298 },
    { lat: 48.7445893, lng: 21.0976509 },
    { lat: 48.7444631, lng: 21.097928 },
    { lat: 48.744311, lng: 21.0986575 },
    { lat: 48.7440996, lng: 21.0989059 },
    { lat: 48.7440658, lng: 21.0992518 },
    { lat: 48.7438968, lng: 21.099388 },
    { lat: 48.7436304, lng: 21.0997799 },
    { lat: 48.7436331, lng: 21.1001128 },
    { lat: 48.7434989, lng: 21.1002425 },
    { lat: 48.7434778, lng: 21.1003917 },
    { lat: 48.7433027, lng: 21.1006161 },
    { lat: 48.743311, lng: 21.1009396 },
    { lat: 48.742924, lng: 21.1013301 },
    { lat: 48.7424746, lng: 21.1015675 },
    { lat: 48.7423566, lng: 21.1018508 },
    { lat: 48.7421727, lng: 21.1020494 },
    { lat: 48.7415968, lng: 21.1029295 },
    { lat: 48.7415334, lng: 21.1035136 },
    { lat: 48.7413792, lng: 21.1036469 },
    { lat: 48.7413644, lng: 21.1038912 },
    { lat: 48.7411803, lng: 21.1040762 },
    { lat: 48.7411358, lng: 21.1043427 },
    { lat: 48.7408064, lng: 21.1044568 },
    { lat: 48.7406167, lng: 21.1048915 },
    { lat: 48.7401843, lng: 21.1054111 },
    { lat: 48.7398035, lng: 21.1062415 },
    { lat: 48.7396872, lng: 21.1062611 },
    { lat: 48.7396836, lng: 21.1067434 },
    { lat: 48.7394967, lng: 21.1069162 },
    { lat: 48.7393348, lng: 21.1072358 },
    { lat: 48.7392217, lng: 21.1073262 },
    { lat: 48.7391192, lng: 21.1075323 },
    { lat: 48.739075, lng: 21.1077659 },
    { lat: 48.7389079, lng: 21.1078859 },
    { lat: 48.7387311, lng: 21.1083442 },
    { lat: 48.7385348, lng: 21.1086767 },
    { lat: 48.738293, lng: 21.1088236 },
    { lat: 48.7382085, lng: 21.1091001 },
    { lat: 48.7384003, lng: 21.1105821 },
    { lat: 48.7383269, lng: 21.1110646 },
    { lat: 48.7383521, lng: 21.1116069 },
    { lat: 48.7383013, lng: 21.1122642 },
    { lat: 48.7383471, lng: 21.1125364 },
    { lat: 48.7385161, lng: 21.1127547 },
    { lat: 48.7384894, lng: 21.1131482 },
    { lat: 48.7383385, lng: 21.1133561 },
    { lat: 48.7382103, lng: 21.1141289 },
    { lat: 48.737964, lng: 21.1144123 },
    { lat: 48.7378558, lng: 21.1147597 },
    { lat: 48.7378842, lng: 21.1150139 },
    { lat: 48.7375802, lng: 21.1160934 },
    { lat: 48.7373052, lng: 21.1180087 },
    { lat: 48.7371229, lng: 21.1185402 },
    { lat: 48.7365828, lng: 21.1189656 },
    { lat: 48.7364788, lng: 21.1189889 },
    { lat: 48.7363594, lng: 21.1195456 },
    { lat: 48.7361268, lng: 21.1197638 },
    { lat: 48.7361355, lng: 21.1197902 },
    { lat: 48.7360359, lng: 21.1210697 },
    { lat: 48.7359454, lng: 21.121092 },
    { lat: 48.7358014, lng: 21.1213764 },
    { lat: 48.7357238, lng: 21.1223248 },
    { lat: 48.735909, lng: 21.1252257 },
    { lat: 48.7361326, lng: 21.1270394 },
    { lat: 48.7363809, lng: 21.1284685 },
    { lat: 48.7367252, lng: 21.128254 },
    { lat: 48.7368899, lng: 21.1279553 },
    { lat: 48.7371149, lng: 21.1278451 },
    { lat: 48.7375264, lng: 21.1280424 },
    { lat: 48.7376984, lng: 21.1281768 },
    { lat: 48.7378698, lng: 21.1286078 },
    { lat: 48.7388264, lng: 21.1301894 },
    { lat: 48.7396566, lng: 21.1301466 },
    { lat: 48.7397416, lng: 21.1305454 },
    { lat: 48.7400426, lng: 21.1312359 },
    { lat: 48.7402095, lng: 21.1317717 },
    { lat: 48.7404714, lng: 21.1315169 },
    { lat: 48.7408927, lng: 21.1314522 },
    { lat: 48.7410091, lng: 21.1320314 },
    { lat: 48.7412096, lng: 21.1327035 },
    { lat: 48.7414558, lng: 21.1330507 },
    { lat: 48.7417731, lng: 21.133225 },
    { lat: 48.7426304, lng: 21.1345902 },
    { lat: 48.7440772, lng: 21.1373962 },
    { lat: 48.7449834, lng: 21.1390247 },
    { lat: 48.7454254, lng: 21.140038 },
    { lat: 48.7469394, lng: 21.1421597 },
    { lat: 48.747888, lng: 21.1438491 },
    { lat: 48.7481137, lng: 21.1438726 },
    { lat: 48.7484832, lng: 21.1437737 },
    { lat: 48.7488104, lng: 21.1441532 },
    { lat: 48.7498291, lng: 21.1460697 },
    { lat: 48.750157, lng: 21.146537 },
    { lat: 48.750212, lng: 21.146187 },
    { lat: 48.750397, lng: 21.145964 },
    { lat: 48.750469, lng: 21.145661 },
    { lat: 48.750685, lng: 21.145246 },
    { lat: 48.750846, lng: 21.144906 },
    { lat: 48.751079, lng: 21.144509 },
    { lat: 48.751227, lng: 21.143847 },
    { lat: 48.751436, lng: 21.143725 },
    { lat: 48.751962, lng: 21.142965 },
    { lat: 48.752521, lng: 21.142383 },
    { lat: 48.752825, lng: 21.141868 },
    { lat: 48.753566, lng: 21.140344 },
    { lat: 48.753936, lng: 21.140256 },
    { lat: 48.754437, lng: 21.140737 },
    { lat: 48.754847, lng: 21.140358 },
    { lat: 48.755606, lng: 21.13951 },
    { lat: 48.756223, lng: 21.138915 },
    { lat: 48.756659, lng: 21.138649 },
    { lat: 48.757147, lng: 21.138233 },
    { lat: 48.756634, lng: 21.137494 },
    { lat: 48.756372, lng: 21.137103 },
    { lat: 48.756161, lng: 21.136787 },
    { lat: 48.756112, lng: 21.136705 },
    { lat: 48.755913, lng: 21.136257 },
    { lat: 48.755963, lng: 21.13559 },
    { lat: 48.756295, lng: 21.135002 },
    { lat: 48.756063, lng: 21.13479 },
    { lat: 48.755963, lng: 21.134521 },
    { lat: 48.755873, lng: 21.134086 },
    { lat: 48.755957, lng: 21.133683 },
    { lat: 48.755896, lng: 21.133146 },
    { lat: 48.755954, lng: 21.132888 },
    { lat: 48.755779, lng: 21.132454 },
    { lat: 48.755713, lng: 21.131939 },
    { lat: 48.755745, lng: 21.131458 },
    { lat: 48.75581, lng: 21.131307 },
    { lat: 48.755911, lng: 21.131262 },
    { lat: 48.7561, lng: 21.13089 },
    { lat: 48.756249, lng: 21.130636 },
    { lat: 48.756398, lng: 21.130468 },
    { lat: 48.756572, lng: 21.130391 },
    { lat: 48.756922, lng: 21.129982 },
    { lat: 48.757024, lng: 21.129796 },
    { lat: 48.757211, lng: 21.129706 },
    { lat: 48.757466, lng: 21.129301 },
    { lat: 48.757491, lng: 21.129097 },
    { lat: 48.757577, lng: 21.128508 },
    { lat: 48.757833, lng: 21.128189 },
    { lat: 48.757852, lng: 21.1281 },
    { lat: 48.758069, lng: 21.127686 },
    { lat: 48.758369, lng: 21.127211 },
    { lat: 48.758661, lng: 21.126996 },
    { lat: 48.75895, lng: 21.126852 },
    { lat: 48.75918, lng: 21.126484 },
    { lat: 48.759456, lng: 21.125914 },
    { lat: 48.759573, lng: 21.125364 },
    { lat: 48.75973, lng: 21.124901 },
    { lat: 48.7598, lng: 21.124497 },
    { lat: 48.759789, lng: 21.124038 },
    { lat: 48.759864, lng: 21.123638 },
    { lat: 48.76071, lng: 21.122183 },
    { lat: 48.761148, lng: 21.121818 },
    { lat: 48.761287, lng: 21.121579 },
    { lat: 48.761307, lng: 21.121546 },
    { lat: 48.761407, lng: 21.121375 },
    { lat: 48.761786, lng: 21.121009 },
    { lat: 48.763439, lng: 21.119956 },
    { lat: 48.76378, lng: 21.12056 },
    { lat: 48.764453, lng: 21.121754 },
    { lat: 48.765491, lng: 21.123595 },
    { lat: 48.765585, lng: 21.123905 },
    { lat: 48.765612, lng: 21.124 },
    { lat: 48.7656888, lng: 21.1242551 },
    { lat: 48.765808, lng: 21.124651 },
    { lat: 48.765854, lng: 21.124805 },
    { lat: 48.765937, lng: 21.125082 },
    { lat: 48.765969, lng: 21.125197 },
    { lat: 48.766128, lng: 21.125766 },
    { lat: 48.766153, lng: 21.125778 },
    { lat: 48.766413, lng: 21.125908 },
    { lat: 48.766512, lng: 21.125843 },
    { lat: 48.766638, lng: 21.125761 },
    { lat: 48.766776, lng: 21.125672 },
    { lat: 48.766664, lng: 21.125324 },
    { lat: 48.766959, lng: 21.125063 },
    { lat: 48.767262, lng: 21.125144 },
    { lat: 48.767496, lng: 21.125518 },
    { lat: 48.767548, lng: 21.125493 },
    { lat: 48.768096, lng: 21.125233 },
    { lat: 48.768552, lng: 21.124894 },
    { lat: 48.768791, lng: 21.124826 },
    { lat: 48.769451, lng: 21.124848 },
    { lat: 48.770063, lng: 21.12457 },
    { lat: 48.770527, lng: 21.124287 },
    { lat: 48.771007, lng: 21.123708 },
    { lat: 48.771119, lng: 21.123672 },
    { lat: 48.771492, lng: 21.123341 },
    { lat: 48.773086, lng: 21.123331 },
    { lat: 48.773826, lng: 21.122732 },
    { lat: 48.774068, lng: 21.122708 },
    { lat: 48.774245, lng: 21.123022 },
    { lat: 48.774172, lng: 21.123157 },
    { lat: 48.774338, lng: 21.123117 },
    { lat: 48.774923, lng: 21.123224 },
    { lat: 48.776287, lng: 21.123249 },
    { lat: 48.777247, lng: 21.122844 },
    { lat: 48.778154, lng: 21.123016 },
    { lat: 48.778775, lng: 21.12295 },
    { lat: 48.779078, lng: 21.122867 },
    { lat: 48.779329, lng: 21.122694 },
    { lat: 48.77944, lng: 21.122435 },
    { lat: 48.779553, lng: 21.122279 },
    { lat: 48.779747, lng: 21.122289 },
    { lat: 48.779978, lng: 21.122866 },
    { lat: 48.780044, lng: 21.122591 },
    { lat: 48.780533, lng: 21.122514 },
    { lat: 48.780739, lng: 21.122256 },
    { lat: 48.7794564, lng: 21.1198109 },
    { lat: 48.7789682, lng: 21.1187002 },
    { lat: 48.7788495, lng: 21.1185712 },
    { lat: 48.7786278, lng: 21.1180129 },
    { lat: 48.7785229, lng: 21.1178679 },
    { lat: 48.778321, lng: 21.117888 },
    { lat: 48.7781094, lng: 21.1177451 },
    { lat: 48.7780646, lng: 21.1175764 },
    { lat: 48.7778114, lng: 21.117367 },
    { lat: 48.7775254, lng: 21.1171871 },
    { lat: 48.7772145, lng: 21.1171114 },
    { lat: 48.7772028, lng: 21.1169503 },
    { lat: 48.7771225, lng: 21.1169023 },
    { lat: 48.7765432, lng: 21.11674 },
    { lat: 48.7756373, lng: 21.1156946 },
    { lat: 48.7753718, lng: 21.1155197 },
    { lat: 48.7751744, lng: 21.1154685 },
    { lat: 48.774497, lng: 21.1146644 },
    { lat: 48.774282, lng: 21.1144904 },
    { lat: 48.7740849, lng: 21.1141387 },
    { lat: 48.7728476, lng: 21.113769 },
    { lat: 48.7727641, lng: 21.1134836 },
    { lat: 48.7724359, lng: 21.1131834 },
    { lat: 48.7721878, lng: 21.1131321 },
    { lat: 48.7717591, lng: 21.1127543 },
    { lat: 48.7716103, lng: 21.1127096 },
    { lat: 48.7712387, lng: 21.1122509 },
    { lat: 48.7712877, lng: 21.1119844 },
    { lat: 48.7710734, lng: 21.1110985 },
    { lat: 48.7707369, lng: 21.1100804 },
    { lat: 48.7709259, lng: 21.1095894 },
    { lat: 48.7712468, lng: 21.1084358 },
    { lat: 48.7713869, lng: 21.108209 },
    { lat: 48.7714147, lng: 21.1079871 },
    { lat: 48.771508, lng: 21.1077355 },
    { lat: 48.7716245, lng: 21.1076531 },
    { lat: 48.7717666, lng: 21.1071663 },
    { lat: 48.7719967, lng: 21.1067312 },
    { lat: 48.7720382, lng: 21.106027 },
    { lat: 48.7718246, lng: 21.1054715 },
    { lat: 48.7718299, lng: 21.10508 },
    { lat: 48.7718791, lng: 21.104827 },
    { lat: 48.7720534, lng: 21.104573 },
    { lat: 48.7721084, lng: 21.1046018 },
    { lat: 48.7722078, lng: 21.1040567 },
    { lat: 48.7726566, lng: 21.1028262 },
    { lat: 48.7732184, lng: 21.1018154 },
    { lat: 48.7735076, lng: 21.1011147 },
    { lat: 48.7740159, lng: 21.097889 },
    { lat: 48.7744734, lng: 21.0967259 },
    { lat: 48.7745339, lng: 21.0961573 },
    { lat: 48.7742523, lng: 21.0946149 },
    { lat: 48.7742905, lng: 21.0939498 },
    { lat: 48.774403, lng: 21.0934255 },
    { lat: 48.7746069, lng: 21.0928367 },
    { lat: 48.7751576, lng: 21.0917616 },
    { lat: 48.7754391, lng: 21.091314 },
    { lat: 48.7758052, lng: 21.0909087 },
    { lat: 48.776106, lng: 21.0903626 },
    { lat: 48.7769897, lng: 21.08915 },
    { lat: 48.7772346, lng: 21.0885767 },
    { lat: 48.7773719, lng: 21.0884027 },
    { lat: 48.7776138, lng: 21.0885047 },
    { lat: 48.7777154, lng: 21.0884437 },
    { lat: 48.7780894, lng: 21.0879668 },
    { lat: 48.7783103, lng: 21.087833 },
    { lat: 48.7796414, lng: 21.0858781 },
    { lat: 48.77951, lng: 21.085294 },
    { lat: 48.7794507, lng: 21.0845776 },
    { lat: 48.7796587, lng: 21.0841818 },
    { lat: 48.7797075, lng: 21.0837795 },
    { lat: 48.7799761, lng: 21.0831105 },
    { lat: 48.7800489, lng: 21.082749 },
    { lat: 48.7802877, lng: 21.0826506 },
    { lat: 48.7806358, lng: 21.0827979 },
    { lat: 48.780904, lng: 21.0834318 },
    { lat: 48.7811912, lng: 21.0837224 },
    { lat: 48.7816138, lng: 21.0840127 },
    { lat: 48.7819907, lng: 21.0846339 },
    { lat: 48.7821839, lng: 21.0848676 },
    { lat: 48.7824494, lng: 21.0850721 },
    { lat: 48.7827244, lng: 21.0851866 },
    { lat: 48.7827938, lng: 21.0851094 },
    { lat: 48.7828935, lng: 21.0848154 },
    { lat: 48.7825289, lng: 21.0833218 },
    { lat: 48.7826515, lng: 21.082656 },
    { lat: 48.7826167, lng: 21.0821447 },
    { lat: 48.7826836, lng: 21.081739 },
    { lat: 48.7826369, lng: 21.0811268 },
    { lat: 48.7826749, lng: 21.0806343 },
    { lat: 48.7827599, lng: 21.0800973 },
    { lat: 48.7828833, lng: 21.0800496 },
    { lat: 48.7836775, lng: 21.0786092 },
    { lat: 48.7837035, lng: 21.0780284 },
    { lat: 48.7836524, lng: 21.0766932 },
    { lat: 48.7837463, lng: 21.0760088 },
    { lat: 48.7840231, lng: 21.0757327 },
    { lat: 48.7841395, lng: 21.0757487 },
    { lat: 48.7843111, lng: 21.0754201 },
    { lat: 48.7846427, lng: 21.0751953 },
    { lat: 48.7847538, lng: 21.0748034 },
    { lat: 48.7849181, lng: 21.0747715 },
    { lat: 48.785479, lng: 21.0743005 },
    { lat: 48.7856252, lng: 21.0738774 },
    { lat: 48.7855497, lng: 21.0735633 },
    { lat: 48.7856065, lng: 21.0733371 },
    { lat: 48.7855521, lng: 21.0730763 },
    { lat: 48.7853471, lng: 21.0728288 },
    { lat: 48.7852207, lng: 21.0724363 },
    { lat: 48.7850617, lng: 21.0722526 },
    { lat: 48.7849546, lng: 21.0719036 },
    { lat: 48.784886, lng: 21.0714537 },
    { lat: 48.7848121, lng: 21.0713296 },
    { lat: 48.7848434, lng: 21.0711827 },
    { lat: 48.784717, lng: 21.0702735 },
    { lat: 48.7846534, lng: 21.070154 },
    { lat: 48.7846379, lng: 21.0699528 },
    { lat: 48.7845371, lng: 21.06986 },
    { lat: 48.7845197, lng: 21.0696743 },
    { lat: 48.7844244, lng: 21.0695443 },
    { lat: 48.7844598, lng: 21.0691448 },
    { lat: 48.7843694, lng: 21.0686847 },
    { lat: 48.7843783, lng: 21.0683982 },
    { lat: 48.7844468, lng: 21.0682568 },
    { lat: 48.7845223, lng: 21.0676698 },
    { lat: 48.7846661, lng: 21.0673561 },
    { lat: 48.7846525, lng: 21.0672147 },
    { lat: 48.7847161, lng: 21.0670229 },
    { lat: 48.7846799, lng: 21.0665681 },
    { lat: 48.7848065, lng: 21.0662696 },
    { lat: 48.7849052, lng: 21.0655323 },
    { lat: 48.7851344, lng: 21.0649657 },
    { lat: 48.7851026, lng: 21.0648517 },
    { lat: 48.7852303, lng: 21.064522 },
    { lat: 48.7852149, lng: 21.0639478 },
    { lat: 48.7852922, lng: 21.0638643 },
    { lat: 48.7853735, lng: 21.0635663 },
    { lat: 48.7853633, lng: 21.0635627 },
    { lat: 48.7846431, lng: 21.0636307 },
    { lat: 48.7837717, lng: 21.0633126 },
    { lat: 48.7831828, lng: 21.0632694 },
    { lat: 48.7828381, lng: 21.0634036 },
    { lat: 48.7827725, lng: 21.0633232 },
    { lat: 48.7822967, lng: 21.0631912 },
    { lat: 48.7815582, lng: 21.06305 },
    { lat: 48.7796333, lng: 21.0629724 },
    { lat: 48.7795505, lng: 21.0628783 },
    { lat: 48.778716, lng: 21.0629719 },
    { lat: 48.7785292, lng: 21.0629334 },
    { lat: 48.7777699, lng: 21.0631187 },
    { lat: 48.7759601, lng: 21.0632607 },
    { lat: 48.7754904, lng: 21.0633881 },
    { lat: 48.7747561, lng: 21.0634784 },
    { lat: 48.7732355, lng: 21.0634397 },
    { lat: 48.7728841, lng: 21.0635707 },
    { lat: 48.7725582, lng: 21.0635857 },
    { lat: 48.7712019, lng: 21.0632393 },
    { lat: 48.7700113, lng: 21.0634835 },
    { lat: 48.769312, lng: 21.0632302 },
    { lat: 48.7681583, lng: 21.0633343 },
    { lat: 48.7675622, lng: 21.0636202 },
    { lat: 48.7669511, lng: 21.0628309 },
    { lat: 48.7658089, lng: 21.0633537 },
    { lat: 48.7656028, lng: 21.0622445 },
    { lat: 48.7654577, lng: 21.0603805 },
    { lat: 48.7655193, lng: 21.0578857 },
    { lat: 48.7639776, lng: 21.0548847 },
    { lat: 48.7633791, lng: 21.0544456 },
    { lat: 48.761185, lng: 21.0520908 },
    { lat: 48.7601838, lng: 21.0493827 },
    { lat: 48.7593583, lng: 21.0468496 },
    { lat: 48.7582989, lng: 21.0451195 },
  ],
  Šemša: [
    { lat: 48.7068467, lng: 21.1167209 },
    { lat: 48.7066493, lng: 21.1156934 },
    { lat: 48.7067143, lng: 21.1147729 },
    { lat: 48.7065629, lng: 21.1142655 },
    { lat: 48.7063369, lng: 21.1137422 },
    { lat: 48.7063251, lng: 21.1127154 },
    { lat: 48.7061543, lng: 21.1119508 },
    { lat: 48.7060018, lng: 21.1115162 },
    { lat: 48.7060293, lng: 21.1098476 },
    { lat: 48.7057247, lng: 21.1093394 },
    { lat: 48.7050117, lng: 21.1069114 },
    { lat: 48.7048048, lng: 21.1057614 },
    { lat: 48.704963, lng: 21.1053278 },
    { lat: 48.7049097, lng: 21.1045151 },
    { lat: 48.7047305, lng: 21.1035294 },
    { lat: 48.7044157, lng: 21.1025267 },
    { lat: 48.7041706, lng: 21.1025715 },
    { lat: 48.703657, lng: 21.1027625 },
    { lat: 48.7012274, lng: 21.1033717 },
    { lat: 48.696811, lng: 21.1043105 },
    { lat: 48.6892935, lng: 21.1060855 },
    { lat: 48.686881, lng: 21.1065225 },
    { lat: 48.6867995, lng: 21.1070966 },
    { lat: 48.6842618, lng: 21.1072524 },
    { lat: 48.6829315, lng: 21.1072102 },
    { lat: 48.6806548, lng: 21.1072874 },
    { lat: 48.6789206, lng: 21.1072982 },
    { lat: 48.678869, lng: 21.1072985 },
    { lat: 48.6762526, lng: 21.1071406 },
    { lat: 48.6760123, lng: 21.1071911 },
    { lat: 48.6756402, lng: 21.1070998 },
    { lat: 48.6755677, lng: 21.1069776 },
    { lat: 48.6686388, lng: 21.1042157 },
    { lat: 48.6610207, lng: 21.1009815 },
    { lat: 48.6569087, lng: 21.0994349 },
    { lat: 48.6561423, lng: 21.0969282 },
    { lat: 48.6558598, lng: 21.0956468 },
    { lat: 48.6557677, lng: 21.0957141 },
    { lat: 48.6553467, lng: 21.0957526 },
    { lat: 48.6550183, lng: 21.0962096 },
    { lat: 48.654533, lng: 21.0962414 },
    { lat: 48.6539692, lng: 21.0961039 },
    { lat: 48.6537396, lng: 21.0961749 },
    { lat: 48.6533631, lng: 21.0960186 },
    { lat: 48.6527063, lng: 21.0964144 },
    { lat: 48.6524619, lng: 21.0968268 },
    { lat: 48.6523496, lng: 21.0969369 },
    { lat: 48.652262, lng: 21.0969106 },
    { lat: 48.6521557, lng: 21.097038 },
    { lat: 48.6519151, lng: 21.0969336 },
    { lat: 48.6516719, lng: 21.0970078 },
    { lat: 48.6515147, lng: 21.0969806 },
    { lat: 48.6510436, lng: 21.0972395 },
    { lat: 48.6504056, lng: 21.0971733 },
    { lat: 48.6503146, lng: 21.0971109 },
    { lat: 48.6500677, lng: 21.0974473 },
    { lat: 48.6497641, lng: 21.0974586 },
    { lat: 48.6496124, lng: 21.0975321 },
    { lat: 48.6493707, lng: 21.0977866 },
    { lat: 48.6492633, lng: 21.098223 },
    { lat: 48.6492912, lng: 21.0984573 },
    { lat: 48.6491891, lng: 21.0988155 },
    { lat: 48.6490408, lng: 21.0990381 },
    { lat: 48.6489511, lng: 21.0991152 },
    { lat: 48.6487601, lng: 21.0990581 },
    { lat: 48.6486231, lng: 21.0992792 },
    { lat: 48.6484521, lng: 21.0992117 },
    { lat: 48.6483933, lng: 21.0990623 },
    { lat: 48.6483042, lng: 21.0991244 },
    { lat: 48.6481265, lng: 21.0990243 },
    { lat: 48.6480205, lng: 21.0994022 },
    { lat: 48.6468836, lng: 21.1001301 },
    { lat: 48.6468672, lng: 21.1002851 },
    { lat: 48.6469756, lng: 21.1007519 },
    { lat: 48.6469394, lng: 21.1008781 },
    { lat: 48.6469711, lng: 21.1012673 },
    { lat: 48.6471066, lng: 21.1014249 },
    { lat: 48.6471001, lng: 21.1016037 },
    { lat: 48.6471689, lng: 21.1018866 },
    { lat: 48.6472788, lng: 21.1019455 },
    { lat: 48.6473125, lng: 21.1021184 },
    { lat: 48.6473702, lng: 21.1020597 },
    { lat: 48.6474781, lng: 21.102091 },
    { lat: 48.6475362, lng: 21.1022116 },
    { lat: 48.6474814, lng: 21.1023623 },
    { lat: 48.6473751, lng: 21.1024217 },
    { lat: 48.6474266, lng: 21.1029362 },
    { lat: 48.6476034, lng: 21.1031467 },
    { lat: 48.6475902, lng: 21.1028398 },
    { lat: 48.6476786, lng: 21.1029319 },
    { lat: 48.6477286, lng: 21.1033268 },
    { lat: 48.6479916, lng: 21.1034876 },
    { lat: 48.6480266, lng: 21.1033897 },
    { lat: 48.6481228, lng: 21.1035769 },
    { lat: 48.6482999, lng: 21.1034158 },
    { lat: 48.6484294, lng: 21.1031394 },
    { lat: 48.6485663, lng: 21.1032663 },
    { lat: 48.6485504, lng: 21.1034038 },
    { lat: 48.6487081, lng: 21.1035823 },
    { lat: 48.6487508, lng: 21.1036112 },
    { lat: 48.6487974, lng: 21.1034558 },
    { lat: 48.6488353, lng: 21.1035851 },
    { lat: 48.6491438, lng: 21.1037596 },
    { lat: 48.6491607, lng: 21.103869 },
    { lat: 48.6493329, lng: 21.1039358 },
    { lat: 48.6493527, lng: 21.1039923 },
    { lat: 48.6492969, lng: 21.1041406 },
    { lat: 48.649343, lng: 21.1042482 },
    { lat: 48.6493242, lng: 21.1043589 },
    { lat: 48.6492202, lng: 21.1043829 },
    { lat: 48.649191, lng: 21.1045595 },
    { lat: 48.6492266, lng: 21.1047544 },
    { lat: 48.6493594, lng: 21.1048279 },
    { lat: 48.649386, lng: 21.1049139 },
    { lat: 48.6493418, lng: 21.1050428 },
    { lat: 48.6496038, lng: 21.1052837 },
    { lat: 48.6495969, lng: 21.1053473 },
    { lat: 48.6495033, lng: 21.1053776 },
    { lat: 48.6494188, lng: 21.1056903 },
    { lat: 48.6494279, lng: 21.1057975 },
    { lat: 48.6496711, lng: 21.1058707 },
    { lat: 48.6496587, lng: 21.1060788 },
    { lat: 48.6499693, lng: 21.1062543 },
    { lat: 48.6501448, lng: 21.1062474 },
    { lat: 48.6501999, lng: 21.1066705 },
    { lat: 48.6500761, lng: 21.1068391 },
    { lat: 48.650046, lng: 21.1070605 },
    { lat: 48.6501425, lng: 21.1071196 },
    { lat: 48.6501674, lng: 21.107226 },
    { lat: 48.6502587, lng: 21.1070947 },
    { lat: 48.6507323, lng: 21.1074323 },
    { lat: 48.6507581, lng: 21.1075386 },
    { lat: 48.6506309, lng: 21.1075725 },
    { lat: 48.6506562, lng: 21.1078009 },
    { lat: 48.6507105, lng: 21.1077735 },
    { lat: 48.6510085, lng: 21.1079734 },
    { lat: 48.6509973, lng: 21.1081472 },
    { lat: 48.6510665, lng: 21.1082005 },
    { lat: 48.6510794, lng: 21.1083597 },
    { lat: 48.6511815, lng: 21.1084825 },
    { lat: 48.6511052, lng: 21.1086703 },
    { lat: 48.6510072, lng: 21.1087017 },
    { lat: 48.6510904, lng: 21.1089793 },
    { lat: 48.6511818, lng: 21.1089544 },
    { lat: 48.6511918, lng: 21.1090512 },
    { lat: 48.651303, lng: 21.1089737 },
    { lat: 48.6513784, lng: 21.1091105 },
    { lat: 48.6515637, lng: 21.1091026 },
    { lat: 48.6515228, lng: 21.1092302 },
    { lat: 48.6516107, lng: 21.1096167 },
    { lat: 48.6516695, lng: 21.109627 },
    { lat: 48.6517394, lng: 21.1095272 },
    { lat: 48.6518891, lng: 21.1096632 },
    { lat: 48.6519173, lng: 21.1098053 },
    { lat: 48.6521006, lng: 21.1097637 },
    { lat: 48.652121, lng: 21.1098039 },
    { lat: 48.6520513, lng: 21.1100212 },
    { lat: 48.6522194, lng: 21.1099953 },
    { lat: 48.6522629, lng: 21.1101231 },
    { lat: 48.6524294, lng: 21.1099475 },
    { lat: 48.6525215, lng: 21.1100457 },
    { lat: 48.6525569, lng: 21.1103411 },
    { lat: 48.6525199, lng: 21.1104517 },
    { lat: 48.6526603, lng: 21.1105125 },
    { lat: 48.6526493, lng: 21.110865 },
    { lat: 48.6527144, lng: 21.1109692 },
    { lat: 48.6527188, lng: 21.1111721 },
    { lat: 48.6527825, lng: 21.1112593 },
    { lat: 48.6528407, lng: 21.1112508 },
    { lat: 48.6528866, lng: 21.1110712 },
    { lat: 48.652945, lng: 21.1110687 },
    { lat: 48.6529919, lng: 21.1112646 },
    { lat: 48.6530634, lng: 21.1112148 },
    { lat: 48.6531777, lng: 21.1113011 },
    { lat: 48.6532725, lng: 21.1112771 },
    { lat: 48.6532733, lng: 21.1111275 },
    { lat: 48.6533386, lng: 21.1110533 },
    { lat: 48.6533785, lng: 21.1108635 },
    { lat: 48.6534533, lng: 21.1108078 },
    { lat: 48.6535295, lng: 21.1108674 },
    { lat: 48.6535409, lng: 21.1108021 },
    { lat: 48.653634, lng: 21.1107554 },
    { lat: 48.6537033, lng: 21.1108789 },
    { lat: 48.6538312, lng: 21.1108677 },
    { lat: 48.6537576, lng: 21.1110639 },
    { lat: 48.6538626, lng: 21.1113591 },
    { lat: 48.6538702, lng: 21.1115579 },
    { lat: 48.6540356, lng: 21.1116259 },
    { lat: 48.6540963, lng: 21.1119749 },
    { lat: 48.6541693, lng: 21.1121454 },
    { lat: 48.6543122, lng: 21.1120712 },
    { lat: 48.654378, lng: 21.112124 },
    { lat: 48.6543782, lng: 21.1124455 },
    { lat: 48.6543323, lng: 21.1125563 },
    { lat: 48.6544247, lng: 21.1127012 },
    { lat: 48.6546339, lng: 21.1126211 },
    { lat: 48.6546151, lng: 21.1129834 },
    { lat: 48.6545011, lng: 21.1130074 },
    { lat: 48.654498, lng: 21.1131547 },
    { lat: 48.6544345, lng: 21.113285 },
    { lat: 48.6544817, lng: 21.1134545 },
    { lat: 48.6546579, lng: 21.1134391 },
    { lat: 48.6547694, lng: 21.1135125 },
    { lat: 48.6548148, lng: 21.1136975 },
    { lat: 48.6547975, lng: 21.1139308 },
    { lat: 48.6549178, lng: 21.1142089 },
    { lat: 48.6551508, lng: 21.1142744 },
    { lat: 48.6552608, lng: 21.1145853 },
    { lat: 48.6556389, lng: 21.1147555 },
    { lat: 48.6558264, lng: 21.1150181 },
    { lat: 48.6559377, lng: 21.1150537 },
    { lat: 48.6559986, lng: 21.1152628 },
    { lat: 48.6561339, lng: 21.1154145 },
    { lat: 48.6562467, lng: 21.1154237 },
    { lat: 48.656549, lng: 21.1158252 },
    { lat: 48.6566357, lng: 21.1161072 },
    { lat: 48.6565933, lng: 21.1162932 },
    { lat: 48.6566837, lng: 21.1163372 },
    { lat: 48.6567022, lng: 21.1166349 },
    { lat: 48.6566159, lng: 21.1169281 },
    { lat: 48.6563561, lng: 21.1173223 },
    { lat: 48.6562339, lng: 21.1177175 },
    { lat: 48.656079, lng: 21.1177324 },
    { lat: 48.6559173, lng: 21.1181255 },
    { lat: 48.6559421, lng: 21.1183387 },
    { lat: 48.6560933, lng: 21.1184184 },
    { lat: 48.6559591, lng: 21.1188316 },
    { lat: 48.65597, lng: 21.1190302 },
    { lat: 48.6558725, lng: 21.1191145 },
    { lat: 48.6558156, lng: 21.1193789 },
    { lat: 48.6556463, lng: 21.1193653 },
    { lat: 48.6556112, lng: 21.1194626 },
    { lat: 48.6554701, lng: 21.1194163 },
    { lat: 48.6553066, lng: 21.1198612 },
    { lat: 48.6553118, lng: 21.1200245 },
    { lat: 48.6552086, lng: 21.1201357 },
    { lat: 48.655163, lng: 21.1207517 },
    { lat: 48.6550124, lng: 21.1209321 },
    { lat: 48.6551547, lng: 21.1210869 },
    { lat: 48.6551945, lng: 21.1214467 },
    { lat: 48.655136, lng: 21.121685 },
    { lat: 48.655192, lng: 21.121659 },
    { lat: 48.655449, lng: 21.122337 },
    { lat: 48.655572, lng: 21.122608 },
    { lat: 48.655656, lng: 21.122881 },
    { lat: 48.655872, lng: 21.123356 },
    { lat: 48.65598, lng: 21.123602 },
    { lat: 48.656165, lng: 21.124032 },
    { lat: 48.656302, lng: 21.124362 },
    { lat: 48.656484, lng: 21.124789 },
    { lat: 48.656671, lng: 21.125211 },
    { lat: 48.656747, lng: 21.125353 },
    { lat: 48.657097, lng: 21.126201 },
    { lat: 48.657225, lng: 21.126477 },
    { lat: 48.658374, lng: 21.12895 },
    { lat: 48.658773, lng: 21.129914 },
    { lat: 48.659466, lng: 21.131412 },
    { lat: 48.659483, lng: 21.131447 },
    { lat: 48.660002, lng: 21.132507 },
    { lat: 48.660149, lng: 21.132826 },
    { lat: 48.660725, lng: 21.134084 },
    { lat: 48.660827, lng: 21.1343 },
    { lat: 48.660869, lng: 21.134383 },
    { lat: 48.661655, lng: 21.135949 },
    { lat: 48.661878, lng: 21.136421 },
    { lat: 48.663604, lng: 21.140077 },
    { lat: 48.663911, lng: 21.140703 },
    { lat: 48.664294, lng: 21.141486 },
    { lat: 48.664614, lng: 21.142453 },
    { lat: 48.665077, lng: 21.143381 },
    { lat: 48.6654, lng: 21.143962 },
    { lat: 48.665754, lng: 21.144613 },
    { lat: 48.666003, lng: 21.145226 },
    { lat: 48.666189, lng: 21.145685 },
    { lat: 48.666255, lng: 21.145816 },
    { lat: 48.666567, lng: 21.14638 },
    { lat: 48.666815, lng: 21.146887 },
    { lat: 48.666914, lng: 21.147105 },
    { lat: 48.667168, lng: 21.147675 },
    { lat: 48.667496, lng: 21.148354 },
    { lat: 48.667765, lng: 21.148894 },
    { lat: 48.667876, lng: 21.149233 },
    { lat: 48.668036, lng: 21.149576 },
    { lat: 48.668273, lng: 21.150093 },
    { lat: 48.668434, lng: 21.150836 },
    { lat: 48.6695197, lng: 21.1512993 },
    { lat: 48.6701134, lng: 21.1514275 },
    { lat: 48.671379, lng: 21.1516586 },
    { lat: 48.6736213, lng: 21.1519171 },
    { lat: 48.6744881, lng: 21.1522734 },
    { lat: 48.6747621, lng: 21.1526431 },
    { lat: 48.674862, lng: 21.15267 },
    { lat: 48.6748925, lng: 21.1531692 },
    { lat: 48.6763138, lng: 21.1535212 },
    { lat: 48.6769623, lng: 21.1535917 },
    { lat: 48.677764, lng: 21.1534691 },
    { lat: 48.679439, lng: 21.153084 },
    { lat: 48.6801866, lng: 21.1527726 },
    { lat: 48.6809021, lng: 21.1523047 },
    { lat: 48.6815267, lng: 21.1521305 },
    { lat: 48.6818268, lng: 21.1522223 },
    { lat: 48.6824884, lng: 21.1526772 },
    { lat: 48.6828477, lng: 21.1530358 },
    { lat: 48.6832814, lng: 21.1532297 },
    { lat: 48.6843137, lng: 21.1530576 },
    { lat: 48.6846115, lng: 21.1531172 },
    { lat: 48.6853954, lng: 21.1529962 },
    { lat: 48.6870878, lng: 21.1524541 },
    { lat: 48.6875337, lng: 21.1521415 },
    { lat: 48.6878707, lng: 21.1520144 },
    { lat: 48.6888952, lng: 21.1520964 },
    { lat: 48.6900004, lng: 21.1524853 },
    { lat: 48.691533, lng: 21.1522294 },
    { lat: 48.6918985, lng: 21.1520408 },
    { lat: 48.6929476, lng: 21.1522575 },
    { lat: 48.693348, lng: 21.1520432 },
    { lat: 48.6935695, lng: 21.1520367 },
    { lat: 48.6941552, lng: 21.1518011 },
    { lat: 48.6955879, lng: 21.1514445 },
    { lat: 48.6958782, lng: 21.1509873 },
    { lat: 48.6960649, lng: 21.1503468 },
    { lat: 48.6963999, lng: 21.1476448 },
    { lat: 48.6969227, lng: 21.1462159 },
    { lat: 48.6971853, lng: 21.1456627 },
    { lat: 48.6973409, lng: 21.1446125 },
    { lat: 48.6975717, lng: 21.1437642 },
    { lat: 48.6978613, lng: 21.1422635 },
    { lat: 48.6981563, lng: 21.1416631 },
    { lat: 48.698354, lng: 21.1413701 },
    { lat: 48.6987896, lng: 21.1404177 },
    { lat: 48.6984215, lng: 21.1374938 },
    { lat: 48.6979852, lng: 21.136518 },
    { lat: 48.6975846, lng: 21.135813 },
    { lat: 48.6975004, lng: 21.1355027 },
    { lat: 48.6974933, lng: 21.1351028 },
    { lat: 48.6976665, lng: 21.1347809 },
    { lat: 48.6979278, lng: 21.1338063 },
    { lat: 48.6986217, lng: 21.1332255 },
    { lat: 48.6992131, lng: 21.1330327 },
    { lat: 48.6996189, lng: 21.1326967 },
    { lat: 48.7003605, lng: 21.1322724 },
    { lat: 48.7013138, lng: 21.1312875 },
    { lat: 48.7018609, lng: 21.1305912 },
    { lat: 48.7024547, lng: 21.1292416 },
    { lat: 48.7025557, lng: 21.128528 },
    { lat: 48.7025161, lng: 21.1277876 },
    { lat: 48.7023613, lng: 21.1266064 },
    { lat: 48.7025168, lng: 21.1256654 },
    { lat: 48.7024887, lng: 21.1247143 },
    { lat: 48.7027179, lng: 21.1243177 },
    { lat: 48.7035528, lng: 21.1225221 },
    { lat: 48.7041349, lng: 21.1218605 },
    { lat: 48.7043938, lng: 21.1214036 },
    { lat: 48.7048275, lng: 21.1211025 },
    { lat: 48.7054315, lng: 21.1208437 },
    { lat: 48.7058964, lng: 21.1200756 },
    { lat: 48.7065793, lng: 21.1193657 },
    { lat: 48.7065201, lng: 21.1184318 },
    { lat: 48.7065974, lng: 21.1176151 },
    { lat: 48.7068467, lng: 21.1167209 },
  ],
  Bohdanovce: [
    { lat: 48.6529012, lng: 21.3781465 },
    { lat: 48.6528915, lng: 21.3783101 },
    { lat: 48.65279, lng: 21.3799243 },
    { lat: 48.6526468, lng: 21.3801433 },
    { lat: 48.6525699, lng: 21.3800859 },
    { lat: 48.6524949, lng: 21.3801423 },
    { lat: 48.6524317, lng: 21.3803619 },
    { lat: 48.6524441, lng: 21.3805493 },
    { lat: 48.6523679, lng: 21.3805655 },
    { lat: 48.6522887, lng: 21.3808087 },
    { lat: 48.6523705, lng: 21.3809117 },
    { lat: 48.6521903, lng: 21.3813599 },
    { lat: 48.6520975, lng: 21.381268 },
    { lat: 48.6518371, lng: 21.3819288 },
    { lat: 48.6512538, lng: 21.3842152 },
    { lat: 48.6510226, lng: 21.3843171 },
    { lat: 48.6509482, lng: 21.3849129 },
    { lat: 48.650702, lng: 21.3849919 },
    { lat: 48.6506158, lng: 21.385391 },
    { lat: 48.6507579, lng: 21.3858543 },
    { lat: 48.6507356, lng: 21.3864979 },
    { lat: 48.6506864, lng: 21.3866991 },
    { lat: 48.6509511, lng: 21.3870089 },
    { lat: 48.6506496, lng: 21.3873145 },
    { lat: 48.6505103, lng: 21.3875439 },
    { lat: 48.6504746, lng: 21.3878595 },
    { lat: 48.650626, lng: 21.3884047 },
    { lat: 48.6502157, lng: 21.3889504 },
    { lat: 48.6499778, lng: 21.3894281 },
    { lat: 48.6501476, lng: 21.3900704 },
    { lat: 48.6501371, lng: 21.3903971 },
    { lat: 48.6495445, lng: 21.3915764 },
    { lat: 48.6492714, lng: 21.3915117 },
    { lat: 48.6490767, lng: 21.3917035 },
    { lat: 48.6482092, lng: 21.3930687 },
    { lat: 48.6473248, lng: 21.3942443 },
    { lat: 48.6471385, lng: 21.3943708 },
    { lat: 48.6469684, lng: 21.3947097 },
    { lat: 48.6466502, lng: 21.3947928 },
    { lat: 48.6465111, lng: 21.3951813 },
    { lat: 48.646144, lng: 21.3950271 },
    { lat: 48.6460716, lng: 21.3951694 },
    { lat: 48.6460505, lng: 21.3955124 },
    { lat: 48.6459933, lng: 21.3956348 },
    { lat: 48.6456567, lng: 21.3956316 },
    { lat: 48.6455795, lng: 21.3958361 },
    { lat: 48.6449869, lng: 21.3963826 },
    { lat: 48.6452142, lng: 21.3965711 },
    { lat: 48.6447034, lng: 21.3974434 },
    { lat: 48.6447014, lng: 21.397567 },
    { lat: 48.6444563, lng: 21.3978017 },
    { lat: 48.6443033, lng: 21.3978743 },
    { lat: 48.6442414, lng: 21.3978387 },
    { lat: 48.6440752, lng: 21.3979567 },
    { lat: 48.6436514, lng: 21.398354 },
    { lat: 48.6436545, lng: 21.3984968 },
    { lat: 48.6437993, lng: 21.3990116 },
    { lat: 48.6437411, lng: 21.3996336 },
    { lat: 48.6433201, lng: 21.3999713 },
    { lat: 48.6432453, lng: 21.4000664 },
    { lat: 48.643236, lng: 21.4001645 },
    { lat: 48.6433649, lng: 21.4004162 },
    { lat: 48.6433535, lng: 21.4007871 },
    { lat: 48.6432513, lng: 21.401174 },
    { lat: 48.6434608, lng: 21.4014075 },
    { lat: 48.6434357, lng: 21.4015868 },
    { lat: 48.6432791, lng: 21.401919 },
    { lat: 48.6432932, lng: 21.4022814 },
    { lat: 48.6434559, lng: 21.4024971 },
    { lat: 48.6434852, lng: 21.4026122 },
    { lat: 48.6431368, lng: 21.4030058 },
    { lat: 48.643275, lng: 21.4036416 },
    { lat: 48.6431369, lng: 21.4040341 },
    { lat: 48.6431477, lng: 21.4045074 },
    { lat: 48.6430198, lng: 21.4048629 },
    { lat: 48.6428996, lng: 21.4054361 },
    { lat: 48.6429494, lng: 21.4054854 },
    { lat: 48.642967, lng: 21.4054358 },
    { lat: 48.6431025, lng: 21.4059818 },
    { lat: 48.6430978, lng: 21.4062056 },
    { lat: 48.643231, lng: 21.4063695 },
    { lat: 48.6432055, lng: 21.4069907 },
    { lat: 48.6432337, lng: 21.4071867 },
    { lat: 48.6433249, lng: 21.4072253 },
    { lat: 48.6433251, lng: 21.4076851 },
    { lat: 48.6434503, lng: 21.4078837 },
    { lat: 48.6436083, lng: 21.4078289 },
    { lat: 48.6436131, lng: 21.4079087 },
    { lat: 48.6434939, lng: 21.4081751 },
    { lat: 48.6435454, lng: 21.4084737 },
    { lat: 48.6437189, lng: 21.4087114 },
    { lat: 48.6438149, lng: 21.4089519 },
    { lat: 48.6439415, lng: 21.4095799 },
    { lat: 48.644006, lng: 21.4096614 },
    { lat: 48.6442078, lng: 21.4094866 },
    { lat: 48.6442699, lng: 21.4094973 },
    { lat: 48.6442822, lng: 21.4095712 },
    { lat: 48.6442174, lng: 21.4098506 },
    { lat: 48.6439985, lng: 21.4100953 },
    { lat: 48.643869, lng: 21.410357 },
    { lat: 48.6439037, lng: 21.4105415 },
    { lat: 48.6440858, lng: 21.4109174 },
    { lat: 48.6440733, lng: 21.4116716 },
    { lat: 48.6443567, lng: 21.412199 },
    { lat: 48.6443264, lng: 21.4123918 },
    { lat: 48.6441119, lng: 21.4125536 },
    { lat: 48.6440763, lng: 21.4126844 },
    { lat: 48.6442862, lng: 21.4128194 },
    { lat: 48.6448035, lng: 21.4128504 },
    { lat: 48.6450305, lng: 21.4129888 },
    { lat: 48.6453694, lng: 21.4130304 },
    { lat: 48.6458957, lng: 21.4132138 },
    { lat: 48.6460789, lng: 21.4133573 },
    { lat: 48.6461498, lng: 21.4137365 },
    { lat: 48.646287, lng: 21.4137696 },
    { lat: 48.6469183, lng: 21.4141918 },
    { lat: 48.6480514, lng: 21.4144877 },
    { lat: 48.648672, lng: 21.414742 },
    { lat: 48.6492852, lng: 21.4151943 },
    { lat: 48.6499713, lng: 21.4159097 },
    { lat: 48.6502032, lng: 21.4160243 },
    { lat: 48.6502707, lng: 21.4161362 },
    { lat: 48.6504139, lng: 21.4161069 },
    { lat: 48.6505517, lng: 21.4163197 },
    { lat: 48.6508602, lng: 21.4164716 },
    { lat: 48.6511035, lng: 21.4165577 },
    { lat: 48.6512241, lng: 21.4165307 },
    { lat: 48.6513509, lng: 21.4167837 },
    { lat: 48.6516454, lng: 21.4170369 },
    { lat: 48.651647, lng: 21.4172428 },
    { lat: 48.6519568, lng: 21.4174818 },
    { lat: 48.6521043, lng: 21.4173345 },
    { lat: 48.6521489, lng: 21.4173977 },
    { lat: 48.6520431, lng: 21.4176248 },
    { lat: 48.6521157, lng: 21.4177199 },
    { lat: 48.6523011, lng: 21.4177131 },
    { lat: 48.6523838, lng: 21.41792 },
    { lat: 48.6524301, lng: 21.4179222 },
    { lat: 48.6526623, lng: 21.4186212 },
    { lat: 48.652631, lng: 21.4190867 },
    { lat: 48.6525448, lng: 21.4193687 },
    { lat: 48.6525985, lng: 21.4199349 },
    { lat: 48.6525799, lng: 21.4203667 },
    { lat: 48.6529017, lng: 21.4208291 },
    { lat: 48.652921, lng: 21.42102 },
    { lat: 48.6531147, lng: 21.4211419 },
    { lat: 48.6532219, lng: 21.4213781 },
    { lat: 48.6533443, lng: 21.4214503 },
    { lat: 48.6531341, lng: 21.4215299 },
    { lat: 48.6529282, lng: 21.4218391 },
    { lat: 48.6528452, lng: 21.4223028 },
    { lat: 48.6525913, lng: 21.4227031 },
    { lat: 48.6523874, lng: 21.4234194 },
    { lat: 48.6525373, lng: 21.4238061 },
    { lat: 48.6526994, lng: 21.4237692 },
    { lat: 48.65285, lng: 21.4236072 },
    { lat: 48.6530021, lng: 21.4237354 },
    { lat: 48.6530924, lng: 21.4236226 },
    { lat: 48.6532514, lng: 21.4244408 },
    { lat: 48.6533442, lng: 21.424616 },
    { lat: 48.6533008, lng: 21.4247809 },
    { lat: 48.6533601, lng: 21.424841 },
    { lat: 48.6532526, lng: 21.4249784 },
    { lat: 48.6532494, lng: 21.4250929 },
    { lat: 48.6533465, lng: 21.4253415 },
    { lat: 48.6532354, lng: 21.4254262 },
    { lat: 48.653283, lng: 21.4257387 },
    { lat: 48.6531905, lng: 21.4259613 },
    { lat: 48.6532981, lng: 21.4261385 },
    { lat: 48.6531924, lng: 21.4263694 },
    { lat: 48.6531607, lng: 21.4268598 },
    { lat: 48.6530516, lng: 21.4269369 },
    { lat: 48.6530571, lng: 21.427243 },
    { lat: 48.6533473, lng: 21.4276182 },
    { lat: 48.6532841, lng: 21.4279196 },
    { lat: 48.6535659, lng: 21.4283537 },
    { lat: 48.6537124, lng: 21.4287493 },
    { lat: 48.653705, lng: 21.4293295 },
    { lat: 48.6537801, lng: 21.4293971 },
    { lat: 48.6537381, lng: 21.4296544 },
    { lat: 48.6537673, lng: 21.4299557 },
    { lat: 48.6548855, lng: 21.4301398 },
    { lat: 48.6553983, lng: 21.4304364 },
    { lat: 48.655703, lng: 21.43027 },
    { lat: 48.6570218, lng: 21.4302357 },
    { lat: 48.6580818, lng: 21.4304216 },
    { lat: 48.6591215, lng: 21.4302697 },
    { lat: 48.6591404, lng: 21.4294224 },
    { lat: 48.6591849, lng: 21.428909 },
    { lat: 48.6593439, lng: 21.4283416 },
    { lat: 48.659329, lng: 21.4279892 },
    { lat: 48.6594215, lng: 21.4274234 },
    { lat: 48.6595485, lng: 21.4270337 },
    { lat: 48.6597416, lng: 21.4261462 },
    { lat: 48.6604228, lng: 21.4239448 },
    { lat: 48.6605272, lng: 21.4237 },
    { lat: 48.6610757, lng: 21.4229182 },
    { lat: 48.6617313, lng: 21.421761 },
    { lat: 48.6618655, lng: 21.4209707 },
    { lat: 48.6619991, lng: 21.4205367 },
    { lat: 48.6628339, lng: 21.4184757 },
    { lat: 48.6631131, lng: 21.4171826 },
    { lat: 48.6634282, lng: 21.4175291 },
    { lat: 48.6637189, lng: 21.417727 },
    { lat: 48.6642401, lng: 21.4154888 },
    { lat: 48.6633798, lng: 21.4151644 },
    { lat: 48.6639585, lng: 21.4118654 },
    { lat: 48.664435, lng: 21.4097135 },
    { lat: 48.6635944, lng: 21.4091098 },
    { lat: 48.663893, lng: 21.4081713 },
    { lat: 48.6652141, lng: 21.4064969 },
    { lat: 48.6657792, lng: 21.4044937 },
    { lat: 48.665659, lng: 21.4044666 },
    { lat: 48.6653885, lng: 21.4041068 },
    { lat: 48.6652634, lng: 21.4037617 },
    { lat: 48.6653383, lng: 21.4033598 },
    { lat: 48.665516, lng: 21.4032068 },
    { lat: 48.6657765, lng: 21.4031881 },
    { lat: 48.6659209, lng: 21.4029373 },
    { lat: 48.6659447, lng: 21.4020297 },
    { lat: 48.6658117, lng: 21.4016087 },
    { lat: 48.6655816, lng: 21.4011721 },
    { lat: 48.6650731, lng: 21.400874 },
    { lat: 48.6648573, lng: 21.4009221 },
    { lat: 48.6644777, lng: 21.4007178 },
    { lat: 48.6639348, lng: 21.4014611 },
    { lat: 48.6632068, lng: 21.4010455 },
    { lat: 48.662217, lng: 21.4006041 },
    { lat: 48.6624175, lng: 21.3993977 },
    { lat: 48.6622402, lng: 21.3989647 },
    { lat: 48.6620615, lng: 21.3987412 },
    { lat: 48.6620819, lng: 21.3986365 },
    { lat: 48.6619635, lng: 21.398436 },
    { lat: 48.6618812, lng: 21.3981653 },
    { lat: 48.6619739, lng: 21.3977226 },
    { lat: 48.6616995, lng: 21.397503 },
    { lat: 48.6615108, lng: 21.3972481 },
    { lat: 48.661515, lng: 21.3978395 },
    { lat: 48.6614137, lng: 21.3980357 },
    { lat: 48.6611358, lng: 21.3981104 },
    { lat: 48.6609884, lng: 21.3976975 },
    { lat: 48.6607875, lng: 21.3973309 },
    { lat: 48.6607977, lng: 21.3969223 },
    { lat: 48.6609702, lng: 21.3965539 },
    { lat: 48.6608087, lng: 21.394904 },
    { lat: 48.6616289, lng: 21.3934641 },
    { lat: 48.663017, lng: 21.3901943 },
    { lat: 48.6632087, lng: 21.3895608 },
    { lat: 48.6635613, lng: 21.38871 },
    { lat: 48.6640451, lng: 21.3873052 },
    { lat: 48.6647523, lng: 21.3849105 },
    { lat: 48.6652185, lng: 21.3835588 },
    { lat: 48.6652861, lng: 21.3832245 },
    { lat: 48.6652635, lng: 21.3829566 },
    { lat: 48.6649538, lng: 21.3820065 },
    { lat: 48.6653579, lng: 21.3816707 },
    { lat: 48.6652275, lng: 21.380921 },
    { lat: 48.6649238, lng: 21.3802912 },
    { lat: 48.6648663, lng: 21.3799029 },
    { lat: 48.6648687, lng: 21.3789344 },
    { lat: 48.6650277, lng: 21.3778175 },
    { lat: 48.6646087, lng: 21.3778019 },
    { lat: 48.664467, lng: 21.3778421 },
    { lat: 48.6639869, lng: 21.3776299 },
    { lat: 48.6638243, lng: 21.3777994 },
    { lat: 48.6637485, lng: 21.3777871 },
    { lat: 48.6632097, lng: 21.3780559 },
    { lat: 48.6627772, lng: 21.3784185 },
    { lat: 48.6624783, lng: 21.3785485 },
    { lat: 48.6618658, lng: 21.3786144 },
    { lat: 48.6615171, lng: 21.3788068 },
    { lat: 48.6609292, lng: 21.3789807 },
    { lat: 48.6607246, lng: 21.3791306 },
    { lat: 48.6605342, lng: 21.379129 },
    { lat: 48.6604184, lng: 21.3790585 },
    { lat: 48.6601166, lng: 21.3791599 },
    { lat: 48.6595992, lng: 21.3795848 },
    { lat: 48.6589003, lng: 21.3805575 },
    { lat: 48.6587152, lng: 21.3807723 },
    { lat: 48.6585361, lng: 21.3808382 },
    { lat: 48.6582693, lng: 21.3813251 },
    { lat: 48.6580429, lng: 21.3813569 },
    { lat: 48.6578241, lng: 21.3816416 },
    { lat: 48.6573632, lng: 21.3817313 },
    { lat: 48.6572704, lng: 21.3819797 },
    { lat: 48.6566067, lng: 21.3827004 },
    { lat: 48.6565503, lng: 21.3830313 },
    { lat: 48.656352, lng: 21.3830654 },
    { lat: 48.6563748, lng: 21.3831077 },
    { lat: 48.656057, lng: 21.3837041 },
    { lat: 48.6560418, lng: 21.3830363 },
    { lat: 48.6560895, lng: 21.3827551 },
    { lat: 48.656059, lng: 21.3824831 },
    { lat: 48.6554972, lng: 21.3815304 },
    { lat: 48.6550171, lng: 21.3810569 },
    { lat: 48.6547153, lng: 21.3802617 },
    { lat: 48.6545477, lng: 21.3802243 },
    { lat: 48.6543204, lng: 21.3803366 },
    { lat: 48.6538192, lng: 21.3809238 },
    { lat: 48.6536163, lng: 21.3809504 },
    { lat: 48.6532738, lng: 21.3807128 },
    { lat: 48.6531725, lng: 21.3791281 },
    { lat: 48.6530591, lng: 21.3785368 },
    { lat: 48.6529012, lng: 21.3781465 },
  ],
  KokšovBakša: [
    { lat: 48.646638, lng: 21.333052 },
    { lat: 48.646732, lng: 21.332811 },
    { lat: 48.647315, lng: 21.331669 },
    { lat: 48.647599, lng: 21.331256 },
    { lat: 48.648717, lng: 21.330314 },
    { lat: 48.650313, lng: 21.329962 },
    { lat: 48.651156, lng: 21.330035 },
    { lat: 48.651296, lng: 21.330048 },
    { lat: 48.651506, lng: 21.330066 },
    { lat: 48.651503, lng: 21.329986 },
    { lat: 48.651477, lng: 21.329279 },
    { lat: 48.651472, lng: 21.329125 },
    { lat: 48.651616, lng: 21.328979 },
    { lat: 48.651628, lng: 21.328795 },
    { lat: 48.651494, lng: 21.328682 },
    { lat: 48.651435, lng: 21.328159 },
    { lat: 48.651431, lng: 21.328071 },
    { lat: 48.651141, lng: 21.321906 },
    { lat: 48.651132, lng: 21.321789 },
    { lat: 48.65112, lng: 21.321663 },
    { lat: 48.651113, lng: 21.321571 },
    { lat: 48.651103, lng: 21.321448 },
    { lat: 48.652416, lng: 21.318895 },
    { lat: 48.652913, lng: 21.317926 },
    { lat: 48.652761, lng: 21.317311 },
    { lat: 48.651945, lng: 21.317567 },
    { lat: 48.651111, lng: 21.318773 },
    { lat: 48.651001, lng: 21.318967 },
    { lat: 48.650992, lng: 21.318725 },
    { lat: 48.650845, lng: 21.315057 },
    { lat: 48.650812, lng: 21.314231 },
    { lat: 48.6506915, lng: 21.3142569 },
    { lat: 48.6483458, lng: 21.3079256 },
    { lat: 48.6481968, lng: 21.3082445 },
    { lat: 48.6475459, lng: 21.3078655 },
    { lat: 48.647016, lng: 21.3078639 },
    { lat: 48.6461639, lng: 21.3082619 },
    { lat: 48.6451767, lng: 21.3077753 },
    { lat: 48.6444655, lng: 21.3077756 },
    { lat: 48.6443448, lng: 21.3075445 },
    { lat: 48.6435953, lng: 21.3077286 },
    { lat: 48.6434349, lng: 21.3077116 },
    { lat: 48.6432589, lng: 21.3075167 },
    { lat: 48.6407531, lng: 21.3082277 },
    { lat: 48.6396359, lng: 21.3088654 },
    { lat: 48.6384757, lng: 21.3098647 },
    { lat: 48.6382064, lng: 21.3093009 },
    { lat: 48.6368928, lng: 21.3094352 },
    { lat: 48.636601, lng: 21.3095721 },
    { lat: 48.6369029, lng: 21.3107257 },
    { lat: 48.6363765, lng: 21.3109949 },
    { lat: 48.6360758, lng: 21.3112417 },
    { lat: 48.6353861, lng: 21.312321 },
    { lat: 48.6345378, lng: 21.312219 },
    { lat: 48.6342144, lng: 21.3114651 },
    { lat: 48.6340512, lng: 21.3115017 },
    { lat: 48.6337302, lng: 21.3110131 },
    { lat: 48.6328084, lng: 21.3109682 },
    { lat: 48.632625, lng: 21.3114505 },
    { lat: 48.6326473, lng: 21.312739 },
    { lat: 48.6320827, lng: 21.3143013 },
    { lat: 48.6319389, lng: 21.3143802 },
    { lat: 48.6320473, lng: 21.3155016 },
    { lat: 48.6317927, lng: 21.3154968 },
    { lat: 48.6317729, lng: 21.3154737 },
    { lat: 48.631786, lng: 21.315609 },
    { lat: 48.6321596, lng: 21.3165316 },
    { lat: 48.6324457, lng: 21.318317 },
    { lat: 48.6329673, lng: 21.3206265 },
    { lat: 48.6345274, lng: 21.3268971 },
    { lat: 48.6349377, lng: 21.3291504 },
    { lat: 48.6351954, lng: 21.3300047 },
    { lat: 48.6356625, lng: 21.3323075 },
    { lat: 48.6356897, lng: 21.332575 },
    { lat: 48.6360363, lng: 21.3323148 },
    { lat: 48.6364826, lng: 21.3321118 },
    { lat: 48.6368658, lng: 21.332095 },
    { lat: 48.6373492, lng: 21.332452 },
    { lat: 48.6374787, lng: 21.332827 },
    { lat: 48.637603, lng: 21.3330315 },
    { lat: 48.6376885, lng: 21.3334697 },
    { lat: 48.6371805, lng: 21.3345654 },
    { lat: 48.6371084, lng: 21.3346383 },
    { lat: 48.6370737, lng: 21.3353153 },
    { lat: 48.6370974, lng: 21.3357335 },
    { lat: 48.6371793, lng: 21.3361338 },
    { lat: 48.6374781, lng: 21.3365932 },
    { lat: 48.638199, lng: 21.3370616 },
    { lat: 48.6382356, lng: 21.3372331 },
    { lat: 48.6384792, lng: 21.3371711 },
    { lat: 48.6386584, lng: 21.3375217 },
    { lat: 48.6388703, lng: 21.3376818 },
    { lat: 48.6392355, lng: 21.3376652 },
    { lat: 48.6395186, lng: 21.3378709 },
    { lat: 48.6398743, lng: 21.3382791 },
    { lat: 48.6402201, lng: 21.3381432 },
    { lat: 48.640398, lng: 21.3379581 },
    { lat: 48.6409364, lng: 21.3378278 },
    { lat: 48.6416636, lng: 21.3382776 },
    { lat: 48.6420078, lng: 21.3383891 },
    { lat: 48.6423155, lng: 21.3382883 },
    { lat: 48.6424596, lng: 21.3381388 },
    { lat: 48.6432387, lng: 21.3377503 },
    { lat: 48.6432945, lng: 21.3376615 },
    { lat: 48.6433992, lng: 21.3369525 },
    { lat: 48.6436665, lng: 21.3361149 },
    { lat: 48.6434023, lng: 21.3359794 },
    { lat: 48.6431975, lng: 21.3357548 },
    { lat: 48.6429006, lng: 21.3347194 },
    { lat: 48.6428707, lng: 21.3342521 },
    { lat: 48.6429473, lng: 21.333179 },
    { lat: 48.6433296, lng: 21.3324245 },
    { lat: 48.6438105, lng: 21.3321003 },
    { lat: 48.6442155, lng: 21.3320232 },
    { lat: 48.6443966, lng: 21.3320556 },
    { lat: 48.6452797, lng: 21.332569 },
    { lat: 48.646464, lng: 21.3329525 },
    { lat: 48.646638, lng: 21.333052 },
  ],
  MaláIda: [
    { lat: 48.697232, lng: 21.178464 },
    { lat: 48.6981503, lng: 21.1763543 },
    { lat: 48.6986877, lng: 21.1758342 },
    { lat: 48.7001114, lng: 21.1748426 },
    { lat: 48.7003014, lng: 21.1745111 },
    { lat: 48.7007956, lng: 21.1739467 },
    { lat: 48.701648, lng: 21.1731754 },
    { lat: 48.7019489, lng: 21.1730489 },
    { lat: 48.7009295, lng: 21.1718826 },
    { lat: 48.7007334, lng: 21.1717646 },
    { lat: 48.7002456, lng: 21.1711443 },
    { lat: 48.699938, lng: 21.1703528 },
    { lat: 48.6994673, lng: 21.1697804 },
    { lat: 48.6992862, lng: 21.169424 },
    { lat: 48.6993058, lng: 21.1686577 },
    { lat: 48.6990545, lng: 21.1675813 },
    { lat: 48.6988983, lng: 21.166595 },
    { lat: 48.69879, lng: 21.1652034 },
    { lat: 48.6983254, lng: 21.1632223 },
    { lat: 48.6978288, lng: 21.1620444 },
    { lat: 48.6969617, lng: 21.159043 },
    { lat: 48.6963389, lng: 21.156486 },
    { lat: 48.696078, lng: 21.1559555 },
    { lat: 48.6959839, lng: 21.1553688 },
    { lat: 48.6958977, lng: 21.1537933 },
    { lat: 48.6958793, lng: 21.1510211 },
    { lat: 48.6958782, lng: 21.1509873 },
    { lat: 48.6955879, lng: 21.1514445 },
    { lat: 48.6941552, lng: 21.1518011 },
    { lat: 48.6935695, lng: 21.1520367 },
    { lat: 48.693348, lng: 21.1520432 },
    { lat: 48.6929476, lng: 21.1522575 },
    { lat: 48.6918985, lng: 21.1520408 },
    { lat: 48.691533, lng: 21.1522294 },
    { lat: 48.6900004, lng: 21.1524853 },
    { lat: 48.6888952, lng: 21.1520964 },
    { lat: 48.6878707, lng: 21.1520144 },
    { lat: 48.6875337, lng: 21.1521415 },
    { lat: 48.6870878, lng: 21.1524541 },
    { lat: 48.6853954, lng: 21.1529962 },
    { lat: 48.6846115, lng: 21.1531172 },
    { lat: 48.6843137, lng: 21.1530576 },
    { lat: 48.6832814, lng: 21.1532297 },
    { lat: 48.6828477, lng: 21.1530358 },
    { lat: 48.6824884, lng: 21.1526772 },
    { lat: 48.6818268, lng: 21.1522223 },
    { lat: 48.6815267, lng: 21.1521305 },
    { lat: 48.6809021, lng: 21.1523047 },
    { lat: 48.6801866, lng: 21.1527726 },
    { lat: 48.679439, lng: 21.153084 },
    { lat: 48.677764, lng: 21.1534691 },
    { lat: 48.6769623, lng: 21.1535917 },
    { lat: 48.6763138, lng: 21.1535212 },
    { lat: 48.6748925, lng: 21.1531692 },
    { lat: 48.674862, lng: 21.15267 },
    { lat: 48.6747621, lng: 21.1526431 },
    { lat: 48.6744881, lng: 21.1522734 },
    { lat: 48.6736213, lng: 21.1519171 },
    { lat: 48.671379, lng: 21.1516586 },
    { lat: 48.6701134, lng: 21.1514275 },
    { lat: 48.6695197, lng: 21.1512993 },
    { lat: 48.668434, lng: 21.150836 },
    { lat: 48.668112, lng: 21.150801 },
    { lat: 48.6679, lng: 21.150778 },
    { lat: 48.666983, lng: 21.150678 },
    { lat: 48.666618, lng: 21.150659 },
    { lat: 48.666014, lng: 21.150012 },
    { lat: 48.66587, lng: 21.149771 },
    { lat: 48.665555, lng: 21.149298 },
    { lat: 48.665232, lng: 21.148935 },
    { lat: 48.665003, lng: 21.148717 },
    { lat: 48.664521, lng: 21.148412 },
    { lat: 48.664003, lng: 21.148236 },
    { lat: 48.663193, lng: 21.148088 },
    { lat: 48.662865, lng: 21.147971 },
    { lat: 48.662218, lng: 21.147728 },
    { lat: 48.661639, lng: 21.147574 },
    { lat: 48.661211, lng: 21.147553 },
    { lat: 48.660777, lng: 21.147576 },
    { lat: 48.65995, lng: 21.14771 },
    { lat: 48.659284, lng: 21.147906 },
    { lat: 48.65866, lng: 21.148477 },
    { lat: 48.658365, lng: 21.149023 },
    { lat: 48.658148, lng: 21.149352 },
    { lat: 48.657917, lng: 21.149809 },
    { lat: 48.657375, lng: 21.151227 },
    { lat: 48.657149, lng: 21.152019 },
    { lat: 48.657037, lng: 21.152182 },
    { lat: 48.656865, lng: 21.152479 },
    { lat: 48.656831, lng: 21.152538 },
    { lat: 48.65684, lng: 21.152984 },
    { lat: 48.656817, lng: 21.15413 },
    { lat: 48.656841, lng: 21.154667 },
    { lat: 48.656784, lng: 21.15502 },
    { lat: 48.656671, lng: 21.155108 },
    { lat: 48.65642, lng: 21.155967 },
    { lat: 48.656288, lng: 21.1569 },
    { lat: 48.656257, lng: 21.157271 },
    { lat: 48.65625, lng: 21.157348 },
    { lat: 48.656225, lng: 21.157645 },
    { lat: 48.656191, lng: 21.157753 },
    { lat: 48.656148, lng: 21.158184 },
    { lat: 48.656118, lng: 21.158408 },
    { lat: 48.65609, lng: 21.158614 },
    { lat: 48.655922, lng: 21.159352 },
    { lat: 48.655876, lng: 21.159676 },
    { lat: 48.655825, lng: 21.160827 },
    { lat: 48.655369, lng: 21.1608 },
    { lat: 48.655352, lng: 21.160797 },
    { lat: 48.655297, lng: 21.165819 },
    { lat: 48.655315, lng: 21.165826 },
    { lat: 48.655281, lng: 21.166824 },
    { lat: 48.655302, lng: 21.167301 },
    { lat: 48.655303, lng: 21.167335 },
    { lat: 48.65531, lng: 21.167477 },
    { lat: 48.655315, lng: 21.167594 },
    { lat: 48.655317, lng: 21.167678 },
    { lat: 48.655322, lng: 21.16778 },
    { lat: 48.65533, lng: 21.167852 },
    { lat: 48.655419, lng: 21.168793 },
    { lat: 48.655556, lng: 21.168994 },
    { lat: 48.655542, lng: 21.16911 },
    { lat: 48.655497, lng: 21.169128 },
    { lat: 48.655528, lng: 21.16936 },
    { lat: 48.655497, lng: 21.169372 },
    { lat: 48.655505, lng: 21.169474 },
    { lat: 48.65553, lng: 21.169851 },
    { lat: 48.65555, lng: 21.170602 },
    { lat: 48.655551, lng: 21.170627 },
    { lat: 48.655552, lng: 21.170678 },
    { lat: 48.655556, lng: 21.170833 },
    { lat: 48.655557, lng: 21.170874 },
    { lat: 48.655558, lng: 21.17094 },
    { lat: 48.655578, lng: 21.171856 },
    { lat: 48.655578, lng: 21.172418 },
    { lat: 48.655577, lng: 21.172889 },
    { lat: 48.655576, lng: 21.173687 },
    { lat: 48.655557, lng: 21.173925 },
    { lat: 48.655573, lng: 21.174422 },
    { lat: 48.65557, lng: 21.175067 },
    { lat: 48.655567, lng: 21.175287 },
    { lat: 48.655537, lng: 21.175546 },
    { lat: 48.6554956, lng: 21.1756138 },
    { lat: 48.655552, lng: 21.175888 },
    { lat: 48.655819, lng: 21.17617 },
    { lat: 48.656016, lng: 21.17637 },
    { lat: 48.656504, lng: 21.176801 },
    { lat: 48.656752, lng: 21.177252 },
    { lat: 48.65703, lng: 21.17796 },
    { lat: 48.657159, lng: 21.178011 },
    { lat: 48.657875, lng: 21.178348 },
    { lat: 48.658251, lng: 21.178477 },
    { lat: 48.658577, lng: 21.178813 },
    { lat: 48.658863, lng: 21.178999 },
    { lat: 48.659228, lng: 21.179384 },
    { lat: 48.659433, lng: 21.179774 },
    { lat: 48.659628, lng: 21.179914 },
    { lat: 48.659642, lng: 21.179922 },
    { lat: 48.659787, lng: 21.180006 },
    { lat: 48.660221, lng: 21.180332 },
    { lat: 48.660586, lng: 21.180852 },
    { lat: 48.660952, lng: 21.181094 },
    { lat: 48.661463, lng: 21.181143 },
    { lat: 48.662135, lng: 21.181079 },
    { lat: 48.662719, lng: 21.180631 },
    { lat: 48.663529, lng: 21.180404 },
    { lat: 48.664212, lng: 21.180519 },
    { lat: 48.664543, lng: 21.180578 },
    { lat: 48.66493, lng: 21.180542 },
    { lat: 48.665268, lng: 21.1806635 },
    { lat: 48.665934, lng: 21.180903 },
    { lat: 48.666406, lng: 21.180988 },
    { lat: 48.666583, lng: 21.180955 },
    { lat: 48.666729, lng: 21.181003 },
    { lat: 48.666986, lng: 21.181202 },
    { lat: 48.667411, lng: 21.181531 },
    { lat: 48.667691, lng: 21.181884 },
    { lat: 48.667903, lng: 21.181988 },
    { lat: 48.667904, lng: 21.181988 },
    { lat: 48.66847, lng: 21.181901 },
    { lat: 48.668527, lng: 21.181909 },
    { lat: 48.669007, lng: 21.181979 },
    { lat: 48.669359, lng: 21.182161 },
    { lat: 48.669827, lng: 21.182407 },
    { lat: 48.670471, lng: 21.182513 },
    { lat: 48.671038, lng: 21.182447 },
    { lat: 48.671432, lng: 21.182228 },
    { lat: 48.671678, lng: 21.182121 },
    { lat: 48.672413, lng: 21.181571 },
    { lat: 48.673069, lng: 21.18145 },
    { lat: 48.674374, lng: 21.181521 },
    { lat: 48.675004, lng: 21.1814 },
    { lat: 48.675415, lng: 21.181275 },
    { lat: 48.675886, lng: 21.180814 },
    { lat: 48.675939, lng: 21.180816 },
    { lat: 48.675995, lng: 21.180785 },
    { lat: 48.676068, lng: 21.180745 },
    { lat: 48.6761109, lng: 21.1806769 },
    { lat: 48.6763444, lng: 21.1805767 },
    { lat: 48.676376, lng: 21.180563 },
    { lat: 48.676576, lng: 21.18051 },
    { lat: 48.676784, lng: 21.180537 },
    { lat: 48.67717, lng: 21.180589 },
    { lat: 48.677513, lng: 21.180634 },
    { lat: 48.677912, lng: 21.18079 },
    { lat: 48.678012, lng: 21.18083 },
    { lat: 48.678158, lng: 21.180865 },
    { lat: 48.678503, lng: 21.180949 },
    { lat: 48.679135, lng: 21.18124 },
    { lat: 48.679169, lng: 21.181255 },
    { lat: 48.679193, lng: 21.181258 },
    { lat: 48.679619, lng: 21.1813 },
    { lat: 48.679885, lng: 21.181302 },
    { lat: 48.680317, lng: 21.181316 },
    { lat: 48.680392, lng: 21.181288 },
    { lat: 48.680628, lng: 21.181202 },
    { lat: 48.681082, lng: 21.180974 },
    { lat: 48.681355, lng: 21.180883 },
    { lat: 48.681626, lng: 21.180792 },
    { lat: 48.682817, lng: 21.180651 },
    { lat: 48.683574, lng: 21.18059 },
    { lat: 48.683784, lng: 21.180594 },
    { lat: 48.684222, lng: 21.180552 },
    { lat: 48.684712, lng: 21.180481 },
    { lat: 48.685139, lng: 21.180488 },
    { lat: 48.685339, lng: 21.180594 },
    { lat: 48.685422, lng: 21.180637 },
    { lat: 48.685447, lng: 21.180651 },
    { lat: 48.685698, lng: 21.180783 },
    { lat: 48.686334, lng: 21.18112 },
    { lat: 48.686585, lng: 21.181192 },
    { lat: 48.686838, lng: 21.181197 },
    { lat: 48.686972, lng: 21.1812 },
    { lat: 48.687051, lng: 21.181201 },
    { lat: 48.687386, lng: 21.181332 },
    { lat: 48.688941, lng: 21.181953 },
    { lat: 48.689323, lng: 21.182016 },
    { lat: 48.689705, lng: 21.181974 },
    { lat: 48.690037, lng: 21.181887 },
    { lat: 48.69032, lng: 21.181746 },
    { lat: 48.690639, lng: 21.181705 },
    { lat: 48.691057, lng: 21.181593 },
    { lat: 48.691261, lng: 21.181498 },
    { lat: 48.691467, lng: 21.181401 },
    { lat: 48.691689, lng: 21.181422 },
    { lat: 48.691919, lng: 21.181417 },
    { lat: 48.692109, lng: 21.181362 },
    { lat: 48.692388, lng: 21.181236 },
    { lat: 48.692919, lng: 21.180907 },
    { lat: 48.69312, lng: 21.180893 },
    { lat: 48.693269, lng: 21.180851 },
    { lat: 48.693532, lng: 21.180776 },
    { lat: 48.693591, lng: 21.18076 },
    { lat: 48.694257, lng: 21.180459 },
    { lat: 48.695192, lng: 21.180023 },
    { lat: 48.695494, lng: 21.180051 },
    { lat: 48.695688, lng: 21.180028 },
    { lat: 48.695996, lng: 21.179876 },
    { lat: 48.695999, lng: 21.179874 },
    { lat: 48.696341, lng: 21.17947 },
    { lat: 48.696478, lng: 21.17926 },
    { lat: 48.696539, lng: 21.179169 },
    { lat: 48.696772, lng: 21.178941 },
    { lat: 48.696873, lng: 21.178746 },
    { lat: 48.697232, lng: 21.178464 },
  ],
  Bukovec: [
    { lat: 48.6958782, lng: 21.1509873 },
    { lat: 48.6958793, lng: 21.1510211 },
    { lat: 48.6958977, lng: 21.1537933 },
    { lat: 48.6959839, lng: 21.1553688 },
    { lat: 48.696078, lng: 21.1559555 },
    { lat: 48.6963389, lng: 21.156486 },
    { lat: 48.6969617, lng: 21.159043 },
    { lat: 48.6978288, lng: 21.1620444 },
    { lat: 48.6983254, lng: 21.1632223 },
    { lat: 48.69879, lng: 21.1652034 },
    { lat: 48.6988983, lng: 21.166595 },
    { lat: 48.6990545, lng: 21.1675813 },
    { lat: 48.6993058, lng: 21.1686577 },
    { lat: 48.6992862, lng: 21.169424 },
    { lat: 48.6994673, lng: 21.1697804 },
    { lat: 48.699938, lng: 21.1703528 },
    { lat: 48.7002456, lng: 21.1711443 },
    { lat: 48.7007334, lng: 21.1717646 },
    { lat: 48.7009295, lng: 21.1718826 },
    { lat: 48.7019489, lng: 21.1730489 },
    { lat: 48.7023027, lng: 21.1728477 },
    { lat: 48.7027551, lng: 21.1724923 },
    { lat: 48.7033163, lng: 21.1718713 },
    { lat: 48.7038132, lng: 21.1716099 },
    { lat: 48.7050048, lng: 21.1707784 },
    { lat: 48.7060481, lng: 21.1698913 },
    { lat: 48.7075993, lng: 21.1687982 },
    { lat: 48.7086328, lng: 21.1679587 },
    { lat: 48.7091551, lng: 21.167286 },
    { lat: 48.7097535, lng: 21.1662492 },
    { lat: 48.7110371, lng: 21.1644536 },
    { lat: 48.7114005, lng: 21.1641312 },
    { lat: 48.7133028, lng: 21.162079 },
    { lat: 48.7134638, lng: 21.161728 },
    { lat: 48.7136513, lng: 21.1617141 },
    { lat: 48.7164545, lng: 21.1623778 },
    { lat: 48.7177203, lng: 21.1621546 },
    { lat: 48.7178103, lng: 21.1622956 },
    { lat: 48.7182588, lng: 21.1622399 },
    { lat: 48.7185126, lng: 21.1621232 },
    { lat: 48.718796, lng: 21.1622174 },
    { lat: 48.7189245, lng: 21.1626184 },
    { lat: 48.719347, lng: 21.1635194 },
    { lat: 48.7196916, lng: 21.1640206 },
    { lat: 48.7197646, lng: 21.1642341 },
    { lat: 48.7198817, lng: 21.1648024 },
    { lat: 48.719878, lng: 21.1650072 },
    { lat: 48.7196632, lng: 21.1654305 },
    { lat: 48.7196833, lng: 21.1657149 },
    { lat: 48.7197913, lng: 21.1658211 },
    { lat: 48.7200822, lng: 21.1663654 },
    { lat: 48.7203265, lng: 21.1665142 },
    { lat: 48.720451, lng: 21.1667824 },
    { lat: 48.7205621, lng: 21.1671251 },
    { lat: 48.72053, lng: 21.1675334 },
    { lat: 48.7206241, lng: 21.1676696 },
    { lat: 48.7205721, lng: 21.1681203 },
    { lat: 48.7206221, lng: 21.1682746 },
    { lat: 48.7208159, lng: 21.1686024 },
    { lat: 48.720928, lng: 21.1686937 },
    { lat: 48.7210187, lng: 21.1689302 },
    { lat: 48.7210612, lng: 21.1689592 },
    { lat: 48.72112, lng: 21.168858 },
    { lat: 48.7214223, lng: 21.1692368 },
    { lat: 48.7215487, lng: 21.1692138 },
    { lat: 48.7216143, lng: 21.1693284 },
    { lat: 48.7216972, lng: 21.16928 },
    { lat: 48.7218173, lng: 21.169416 },
    { lat: 48.7219019, lng: 21.1693836 },
    { lat: 48.7221998, lng: 21.1695199 },
    { lat: 48.7223763, lng: 21.1693646 },
    { lat: 48.7224919, lng: 21.1693961 },
    { lat: 48.7226123, lng: 21.1693183 },
    { lat: 48.7226117, lng: 21.1693736 },
    { lat: 48.722734, lng: 21.1693259 },
    { lat: 48.7226779, lng: 21.1692216 },
    { lat: 48.7227228, lng: 21.1692176 },
    { lat: 48.7227001, lng: 21.1689993 },
    { lat: 48.7228107, lng: 21.1685839 },
    { lat: 48.7231341, lng: 21.1677418 },
    { lat: 48.7231322, lng: 21.1675426 },
    { lat: 48.7234067, lng: 21.1669371 },
    { lat: 48.7234453, lng: 21.1666973 },
    { lat: 48.7233651, lng: 21.1666144 },
    { lat: 48.7233901, lng: 21.1662657 },
    { lat: 48.7235847, lng: 21.1652668 },
    { lat: 48.7237005, lng: 21.1650527 },
    { lat: 48.7238762, lng: 21.1644882 },
    { lat: 48.7239002, lng: 21.1642188 },
    { lat: 48.7237799, lng: 21.1638685 },
    { lat: 48.7237177, lng: 21.1628646 },
    { lat: 48.7235685, lng: 21.1626302 },
    { lat: 48.7235223, lng: 21.1623773 },
    { lat: 48.7235328, lng: 21.1617859 },
    { lat: 48.7234877, lng: 21.1612477 },
    { lat: 48.7235451, lng: 21.1607548 },
    { lat: 48.7235477, lng: 21.1600546 },
    { lat: 48.7234097, lng: 21.1593638 },
    { lat: 48.7233683, lng: 21.1594416 },
    { lat: 48.7233084, lng: 21.1593975 },
    { lat: 48.723302, lng: 21.1587703 },
    { lat: 48.7232375, lng: 21.1587592 },
    { lat: 48.7232667, lng: 21.158616 },
    { lat: 48.7233282, lng: 21.1586372 },
    { lat: 48.7234358, lng: 21.1574527 },
    { lat: 48.7235497, lng: 21.1571083 },
    { lat: 48.7237954, lng: 21.1567968 },
    { lat: 48.7237981, lng: 21.1566362 },
    { lat: 48.7239393, lng: 21.1562267 },
    { lat: 48.7240134, lng: 21.1558321 },
    { lat: 48.7240587, lng: 21.1551002 },
    { lat: 48.7242042, lng: 21.1550679 },
    { lat: 48.72421, lng: 21.1546875 },
    { lat: 48.7240913, lng: 21.1542783 },
    { lat: 48.7240024, lng: 21.1533273 },
    { lat: 48.7239916, lng: 21.1525296 },
    { lat: 48.7240903, lng: 21.152161 },
    { lat: 48.724104, lng: 21.1517394 },
    { lat: 48.7241347, lng: 21.1515771 },
    { lat: 48.7242461, lng: 21.151609 },
    { lat: 48.7243599, lng: 21.1511152 },
    { lat: 48.7245559, lng: 21.1507684 },
    { lat: 48.7247856, lng: 21.1497842 },
    { lat: 48.7248895, lng: 21.1495111 },
    { lat: 48.7248625, lng: 21.1488066 },
    { lat: 48.7247542, lng: 21.1487952 },
    { lat: 48.7246474, lng: 21.148449 },
    { lat: 48.7246455, lng: 21.1481372 },
    { lat: 48.7247108, lng: 21.1476787 },
    { lat: 48.7247385, lng: 21.1469256 },
    { lat: 48.7248124, lng: 21.1465203 },
    { lat: 48.724926, lng: 21.1462354 },
    { lat: 48.7247892, lng: 21.145968 },
    { lat: 48.7247295, lng: 21.1456123 },
    { lat: 48.7248432, lng: 21.1448084 },
    { lat: 48.7248052, lng: 21.1443171 },
    { lat: 48.7248867, lng: 21.1437006 },
    { lat: 48.7252421, lng: 21.1429824 },
    { lat: 48.7258446, lng: 21.1423233 },
    { lat: 48.7263952, lng: 21.1413424 },
    { lat: 48.7267567, lng: 21.1408234 },
    { lat: 48.7271679, lng: 21.1404127 },
    { lat: 48.7272226, lng: 21.140259 },
    { lat: 48.7272524, lng: 21.1397817 },
    { lat: 48.7273539, lng: 21.1394345 },
    { lat: 48.7276011, lng: 21.1391032 },
    { lat: 48.7276905, lng: 21.1388678 },
    { lat: 48.7276228, lng: 21.1384114 },
    { lat: 48.7276679, lng: 21.138376 },
    { lat: 48.7277173, lng: 21.1379864 },
    { lat: 48.7276806, lng: 21.1378557 },
    { lat: 48.7277099, lng: 21.1376426 },
    { lat: 48.7280839, lng: 21.1356475 },
    { lat: 48.7281941, lng: 21.1352958 },
    { lat: 48.7287212, lng: 21.1344919 },
    { lat: 48.7291159, lng: 21.1337073 },
    { lat: 48.7297089, lng: 21.132859 },
    { lat: 48.7303466, lng: 21.1321451 },
    { lat: 48.7308378, lng: 21.1310286 },
    { lat: 48.7309549, lng: 21.1308931 },
    { lat: 48.7310375, lng: 21.1305188 },
    { lat: 48.7312822, lng: 21.1300442 },
    { lat: 48.7316636, lng: 21.1297932 },
    { lat: 48.731681, lng: 21.1296297 },
    { lat: 48.7317734, lng: 21.1294974 },
    { lat: 48.7318211, lng: 21.1295039 },
    { lat: 48.7319382, lng: 21.1292672 },
    { lat: 48.7321844, lng: 21.1289697 },
    { lat: 48.7323059, lng: 21.128897 },
    { lat: 48.7331103, lng: 21.1266794 },
    { lat: 48.7333484, lng: 21.1263796 },
    { lat: 48.7335505, lng: 21.1263281 },
    { lat: 48.7337912, lng: 21.1260752 },
    { lat: 48.733767, lng: 21.1255703 },
    { lat: 48.733937, lng: 21.1250473 },
    { lat: 48.733912, lng: 21.1250381 },
    { lat: 48.731505, lng: 21.1240966 },
    { lat: 48.7315307, lng: 21.1244124 },
    { lat: 48.7314918, lng: 21.1249857 },
    { lat: 48.7314224, lng: 21.1250656 },
    { lat: 48.7309837, lng: 21.1254624 },
    { lat: 48.7303658, lng: 21.1256011 },
    { lat: 48.7300814, lng: 21.1255816 },
    { lat: 48.7299672, lng: 21.1255279 },
    { lat: 48.7297584, lng: 21.1252612 },
    { lat: 48.7295361, lng: 21.1244005 },
    { lat: 48.7293861, lng: 21.1244239 },
    { lat: 48.7290073, lng: 21.1246524 },
    { lat: 48.7289568, lng: 21.1247591 },
    { lat: 48.7286338, lng: 21.1248002 },
    { lat: 48.7284438, lng: 21.1250455 },
    { lat: 48.7282328, lng: 21.1254534 },
    { lat: 48.7280828, lng: 21.1255867 },
    { lat: 48.7278114, lng: 21.1256536 },
    { lat: 48.7276673, lng: 21.1259999 },
    { lat: 48.7280176, lng: 21.126082 },
    { lat: 48.7283443, lng: 21.1262987 },
    { lat: 48.7283822, lng: 21.1264285 },
    { lat: 48.7283489, lng: 21.1265828 },
    { lat: 48.7289436, lng: 21.1267718 },
    { lat: 48.7289438, lng: 21.1269243 },
    { lat: 48.7283899, lng: 21.1274062 },
    { lat: 48.7284986, lng: 21.1277063 },
    { lat: 48.7282679, lng: 21.1278547 },
    { lat: 48.7280966, lng: 21.1280565 },
    { lat: 48.7266113, lng: 21.1287002 },
    { lat: 48.7260942, lng: 21.1287915 },
    { lat: 48.7259584, lng: 21.1287248 },
    { lat: 48.7255339, lng: 21.1279805 },
    { lat: 48.7253477, lng: 21.1275424 },
    { lat: 48.7249708, lng: 21.1271975 },
    { lat: 48.7245023, lng: 21.1272207 },
    { lat: 48.7240117, lng: 21.1271258 },
    { lat: 48.7231064, lng: 21.1267923 },
    { lat: 48.7226063, lng: 21.1266081 },
    { lat: 48.7223232, lng: 21.1265039 },
    { lat: 48.7218832, lng: 21.1263418 },
    { lat: 48.7218886, lng: 21.1261582 },
    { lat: 48.7218149, lng: 21.1258952 },
    { lat: 48.7218807, lng: 21.1255942 },
    { lat: 48.7221277, lng: 21.1254717 },
    { lat: 48.7222588, lng: 21.1250301 },
    { lat: 48.722138, lng: 21.1249455 },
    { lat: 48.7221877, lng: 21.1247818 },
    { lat: 48.7223431, lng: 21.1247455 },
    { lat: 48.7230726, lng: 21.1234877 },
    { lat: 48.7234297, lng: 21.122621 },
    { lat: 48.7237325, lng: 21.1216788 },
    { lat: 48.7237226, lng: 21.1213712 },
    { lat: 48.7230741, lng: 21.1213278 },
    { lat: 48.7217409, lng: 21.1209539 },
    { lat: 48.7196693, lng: 21.1234566 },
    { lat: 48.7194153, lng: 21.1242793 },
    { lat: 48.7186647, lng: 21.1262486 },
    { lat: 48.7177477, lng: 21.1257601 },
    { lat: 48.7172004, lng: 21.1255781 },
    { lat: 48.7172245, lng: 21.1251033 },
    { lat: 48.717316, lng: 21.1245859 },
    { lat: 48.7173098, lng: 21.1238632 },
    { lat: 48.7175213, lng: 21.1230525 },
    { lat: 48.7175169, lng: 21.1227738 },
    { lat: 48.7176391, lng: 21.1219907 },
    { lat: 48.7177796, lng: 21.12181 },
    { lat: 48.7177561, lng: 21.1207236 },
    { lat: 48.717948, lng: 21.1194802 },
    { lat: 48.7178694, lng: 21.1191695 },
    { lat: 48.7180864, lng: 21.1178626 },
    { lat: 48.7182088, lng: 21.1173614 },
    { lat: 48.7182713, lng: 21.1166151 },
    { lat: 48.7183751, lng: 21.1163709 },
    { lat: 48.7184092, lng: 21.1161413 },
    { lat: 48.7185266, lng: 21.115942 },
    { lat: 48.7185543, lng: 21.1155408 },
    { lat: 48.7187059, lng: 21.1148649 },
    { lat: 48.7188184, lng: 21.1146881 },
    { lat: 48.718568, lng: 21.1144263 },
    { lat: 48.7185045, lng: 21.1138885 },
    { lat: 48.7183334, lng: 21.1135026 },
    { lat: 48.7179459, lng: 21.1127929 },
    { lat: 48.7170503, lng: 21.1114298 },
    { lat: 48.7162619, lng: 21.1116965 },
    { lat: 48.7149361, lng: 21.112295 },
    { lat: 48.7135642, lng: 21.1132033 },
    { lat: 48.7068467, lng: 21.1167209 },
    { lat: 48.7065974, lng: 21.1176151 },
    { lat: 48.7065201, lng: 21.1184318 },
    { lat: 48.7065793, lng: 21.1193657 },
    { lat: 48.7058964, lng: 21.1200756 },
    { lat: 48.7054315, lng: 21.1208437 },
    { lat: 48.7048275, lng: 21.1211025 },
    { lat: 48.7043938, lng: 21.1214036 },
    { lat: 48.7041349, lng: 21.1218605 },
    { lat: 48.7035528, lng: 21.1225221 },
    { lat: 48.7027179, lng: 21.1243177 },
    { lat: 48.7024887, lng: 21.1247143 },
    { lat: 48.7025168, lng: 21.1256654 },
    { lat: 48.7023613, lng: 21.1266064 },
    { lat: 48.7025161, lng: 21.1277876 },
    { lat: 48.7025557, lng: 21.128528 },
    { lat: 48.7024547, lng: 21.1292416 },
    { lat: 48.7018609, lng: 21.1305912 },
    { lat: 48.7013138, lng: 21.1312875 },
    { lat: 48.7003605, lng: 21.1322724 },
    { lat: 48.6996189, lng: 21.1326967 },
    { lat: 48.6992131, lng: 21.1330327 },
    { lat: 48.6986217, lng: 21.1332255 },
    { lat: 48.6979278, lng: 21.1338063 },
    { lat: 48.6976665, lng: 21.1347809 },
    { lat: 48.6974933, lng: 21.1351028 },
    { lat: 48.6975004, lng: 21.1355027 },
    { lat: 48.6975846, lng: 21.135813 },
    { lat: 48.6979852, lng: 21.136518 },
    { lat: 48.6984215, lng: 21.1374938 },
    { lat: 48.6987896, lng: 21.1404177 },
    { lat: 48.698354, lng: 21.1413701 },
    { lat: 48.6981563, lng: 21.1416631 },
    { lat: 48.6978613, lng: 21.1422635 },
    { lat: 48.6975717, lng: 21.1437642 },
    { lat: 48.6973409, lng: 21.1446125 },
    { lat: 48.6971853, lng: 21.1456627 },
    { lat: 48.6969227, lng: 21.1462159 },
    { lat: 48.6963999, lng: 21.1476448 },
    { lat: 48.6960649, lng: 21.1503468 },
    { lat: 48.6958782, lng: 21.1509873 },
  ],
  Háj: [
    { lat: 48.6176714, lng: 20.8449122 },
    { lat: 48.6186577, lng: 20.8512822 },
    { lat: 48.6152628, lng: 20.8617405 },
    { lat: 48.6149737, lng: 20.8628001 },
    { lat: 48.6129027, lng: 20.8687328 },
    { lat: 48.6123951, lng: 20.871132 },
    { lat: 48.6113689, lng: 20.8735677 },
    { lat: 48.6111908, lng: 20.8745808 },
    { lat: 48.6108783, lng: 20.8750224 },
    { lat: 48.6109359, lng: 20.8752833 },
    { lat: 48.6108941, lng: 20.8753798 },
    { lat: 48.611931, lng: 20.879493 },
    { lat: 48.6130287, lng: 20.8797593 },
    { lat: 48.6135181, lng: 20.8781122 },
    { lat: 48.6136921, lng: 20.8783224 },
    { lat: 48.6146666, lng: 20.8807812 },
    { lat: 48.6165866, lng: 20.885178 },
    { lat: 48.6192381, lng: 20.8919148 },
    { lat: 48.6194155, lng: 20.8921988 },
    { lat: 48.6202301, lng: 20.8938999 },
    { lat: 48.6205059, lng: 20.8942261 },
    { lat: 48.6227118, lng: 20.8960036 },
    { lat: 48.6229286, lng: 20.8961213 },
    { lat: 48.6237872, lng: 20.8962451 },
    { lat: 48.6244237, lng: 20.8966216 },
    { lat: 48.6248285, lng: 20.8967404 },
    { lat: 48.6251461, lng: 20.8963493 },
    { lat: 48.6252946, lng: 20.8953554 },
    { lat: 48.6251726, lng: 20.89485 },
    { lat: 48.6251199, lng: 20.8943563 },
    { lat: 48.6249831, lng: 20.8941765 },
    { lat: 48.6251309, lng: 20.894021 },
    { lat: 48.6250948, lng: 20.8925706 },
    { lat: 48.6320398, lng: 20.8919311 },
    { lat: 48.641728, lng: 20.8873983 },
    { lat: 48.6435628, lng: 20.8871674 },
    { lat: 48.6437377, lng: 20.8870516 },
    { lat: 48.6439421, lng: 20.887401 },
    { lat: 48.6439282, lng: 20.8877815 },
    { lat: 48.6438363, lng: 20.8882553 },
    { lat: 48.6438631, lng: 20.8884803 },
    { lat: 48.6440078, lng: 20.8886004 },
    { lat: 48.6441029, lng: 20.8887802 },
    { lat: 48.6440532, lng: 20.8894309 },
    { lat: 48.6441398, lng: 20.8893633 },
    { lat: 48.64425, lng: 20.889785 },
    { lat: 48.6444106, lng: 20.8917545 },
    { lat: 48.6445105, lng: 20.8919813 },
    { lat: 48.6447718, lng: 20.892213 },
    { lat: 48.6451818, lng: 20.8924208 },
    { lat: 48.6456028, lng: 20.8920898 },
    { lat: 48.6460383, lng: 20.8921172 },
    { lat: 48.6461235, lng: 20.8923736 },
    { lat: 48.6463349, lng: 20.892396 },
    { lat: 48.6464913, lng: 20.8925622 },
    { lat: 48.6469852, lng: 20.8927837 },
    { lat: 48.6472117, lng: 20.8931598 },
    { lat: 48.6479349, lng: 20.8931015 },
    { lat: 48.6483734, lng: 20.8932892 },
    { lat: 48.648749, lng: 20.89361 },
    { lat: 48.6498664, lng: 20.8938928 },
    { lat: 48.650326, lng: 20.8941316 },
    { lat: 48.650581, lng: 20.8941808 },
    { lat: 48.6510756, lng: 20.8940332 },
    { lat: 48.6513577, lng: 20.8942839 },
    { lat: 48.6517983, lng: 20.8943302 },
    { lat: 48.6526241, lng: 20.8941162 },
    { lat: 48.653453, lng: 20.8937973 },
    { lat: 48.6539501, lng: 20.8935241 },
    { lat: 48.6542495, lng: 20.893194 },
    { lat: 48.6544169, lng: 20.893218 },
    { lat: 48.6555016, lng: 20.8946889 },
    { lat: 48.6556094, lng: 20.8952438 },
    { lat: 48.6557084, lng: 20.8986458 },
    { lat: 48.6559388, lng: 20.8995241 },
    { lat: 48.6558107, lng: 20.9011892 },
    { lat: 48.6562184, lng: 20.9027166 },
    { lat: 48.6567099, lng: 20.9029983 },
    { lat: 48.6568985, lng: 20.9028836 },
    { lat: 48.6571621, lng: 20.9031591 },
    { lat: 48.6575391, lng: 20.9031534 },
    { lat: 48.6588976, lng: 20.9002709 },
    { lat: 48.659836, lng: 20.8991371 },
    { lat: 48.660451, lng: 20.8986173 },
    { lat: 48.6608565, lng: 20.8986599 },
    { lat: 48.6614955, lng: 20.8980841 },
    { lat: 48.6619211, lng: 20.8979225 },
    { lat: 48.6623041, lng: 20.8973662 },
    { lat: 48.6632218, lng: 20.896982 },
    { lat: 48.6635248, lng: 20.8967213 },
    { lat: 48.6639257, lng: 20.8962618 },
    { lat: 48.6642236, lng: 20.8958524 },
    { lat: 48.6637914, lng: 20.8943348 },
    { lat: 48.6627206, lng: 20.8934042 },
    { lat: 48.6612761, lng: 20.8924357 },
    { lat: 48.6603745, lng: 20.8913204 },
    { lat: 48.6600812, lng: 20.8885961 },
    { lat: 48.6603305, lng: 20.887061 },
    { lat: 48.65926, lng: 20.8852493 },
    { lat: 48.659217, lng: 20.8838594 },
    { lat: 48.6590269, lng: 20.88126 },
    { lat: 48.6579858, lng: 20.8815312 },
    { lat: 48.6591508, lng: 20.8788972 },
    { lat: 48.6594246, lng: 20.8764688 },
    { lat: 48.6593927, lng: 20.8754405 },
    { lat: 48.6578116, lng: 20.874464 },
    { lat: 48.6564738, lng: 20.8733657 },
    { lat: 48.6562367, lng: 20.8735106 },
    { lat: 48.6538155, lng: 20.8703288 },
    { lat: 48.651512, lng: 20.8677987 },
    { lat: 48.6508063, lng: 20.8656698 },
    { lat: 48.6499263, lng: 20.8641701 },
    { lat: 48.6491185, lng: 20.8604441 },
    { lat: 48.6493574, lng: 20.8558099 },
    { lat: 48.6496716, lng: 20.8556525 },
    { lat: 48.6501283, lng: 20.8547278 },
    { lat: 48.6498556, lng: 20.8539948 },
    { lat: 48.6501778, lng: 20.8540343 },
    { lat: 48.6507421, lng: 20.8535582 },
    { lat: 48.6510227, lng: 20.8529776 },
    { lat: 48.6511487, lng: 20.8521575 },
    { lat: 48.6511126, lng: 20.8516302 },
    { lat: 48.6513767, lng: 20.8509384 },
    { lat: 48.6513855, lng: 20.8496874 },
    { lat: 48.6514986, lng: 20.8493424 },
    { lat: 48.6512879, lng: 20.847376 },
    { lat: 48.6512503, lng: 20.8415955 },
    { lat: 48.6511756, lng: 20.8403472 },
    { lat: 48.6515185, lng: 20.829896 },
    { lat: 48.6517105, lng: 20.827341 },
    { lat: 48.6485814, lng: 20.8161679 },
    { lat: 48.6487414, lng: 20.8151032 },
    { lat: 48.6487157, lng: 20.8139796 },
    { lat: 48.6488479, lng: 20.8127338 },
    { lat: 48.6487199, lng: 20.8122886 },
    { lat: 48.6489365, lng: 20.8114914 },
    { lat: 48.6487552, lng: 20.8108778 },
    { lat: 48.6483855, lng: 20.810346 },
    { lat: 48.6480286, lng: 20.8101501 },
    { lat: 48.6478813, lng: 20.8099919 },
    { lat: 48.647609, lng: 20.80944 },
    { lat: 48.647517, lng: 20.809565 },
    { lat: 48.647383, lng: 20.809752 },
    { lat: 48.647335, lng: 20.809909 },
    { lat: 48.646995, lng: 20.810321 },
    { lat: 48.646984, lng: 20.810498 },
    { lat: 48.646719, lng: 20.810522 },
    { lat: 48.646591, lng: 20.810672 },
    { lat: 48.645954, lng: 20.810854 },
    { lat: 48.645709, lng: 20.810856 },
    { lat: 48.64542, lng: 20.810909 },
    { lat: 48.645257, lng: 20.81083 },
    { lat: 48.645242, lng: 20.810949 },
    { lat: 48.64507, lng: 20.810988 },
    { lat: 48.644984, lng: 20.811061 },
    { lat: 48.644845, lng: 20.810961 },
    { lat: 48.644673, lng: 20.810997 },
    { lat: 48.644311, lng: 20.810896 },
    { lat: 48.644198, lng: 20.810826 },
    { lat: 48.643806, lng: 20.810988 },
    { lat: 48.643697, lng: 20.810833 },
    { lat: 48.643632, lng: 20.810913 },
    { lat: 48.643297, lng: 20.81094 },
    { lat: 48.643146, lng: 20.810854 },
    { lat: 48.642972, lng: 20.810979 },
    { lat: 48.642969, lng: 20.811087 },
    { lat: 48.642793, lng: 20.811198 },
    { lat: 48.642635, lng: 20.811433 },
    { lat: 48.64259, lng: 20.81144 },
    { lat: 48.642382, lng: 20.811316 },
    { lat: 48.642134, lng: 20.811407 },
    { lat: 48.641819, lng: 20.811651 },
    { lat: 48.641637, lng: 20.811946 },
    { lat: 48.641438, lng: 20.812209 },
    { lat: 48.641237, lng: 20.812242 },
    { lat: 48.641039, lng: 20.812342 },
    { lat: 48.64092, lng: 20.81228 },
    { lat: 48.640796, lng: 20.812241 },
    { lat: 48.640653, lng: 20.812327 },
    { lat: 48.640549, lng: 20.812237 },
    { lat: 48.64041, lng: 20.812297 },
    { lat: 48.640305, lng: 20.812364 },
    { lat: 48.640127, lng: 20.8126 },
    { lat: 48.63987, lng: 20.813103 },
    { lat: 48.639544, lng: 20.813413 },
    { lat: 48.639404, lng: 20.813806 },
    { lat: 48.639301, lng: 20.813905 },
    { lat: 48.63925, lng: 20.814078 },
    { lat: 48.638854, lng: 20.81485 },
    { lat: 48.638802, lng: 20.815259 },
    { lat: 48.638682, lng: 20.815777 },
    { lat: 48.638314, lng: 20.815813 },
    { lat: 48.638009, lng: 20.816218 },
    { lat: 48.637898, lng: 20.816794 },
    { lat: 48.637958, lng: 20.817185 },
    { lat: 48.637845, lng: 20.817924 },
    { lat: 48.637906, lng: 20.818206 },
    { lat: 48.637747, lng: 20.818946 },
    { lat: 48.637469, lng: 20.819327 },
    { lat: 48.637197, lng: 20.819749 },
    { lat: 48.63683, lng: 20.819964 },
    { lat: 48.6366765, lng: 20.8200534 },
    { lat: 48.636639, lng: 20.820439 },
    { lat: 48.636435, lng: 20.821104 },
    { lat: 48.636307, lng: 20.821392 },
    { lat: 48.636095, lng: 20.821939 },
    { lat: 48.636021, lng: 20.822356 },
    { lat: 48.636097, lng: 20.822576 },
    { lat: 48.635807, lng: 20.82281 },
    { lat: 48.6353201, lng: 20.823762 },
    { lat: 48.635213, lng: 20.824054 },
    { lat: 48.635095, lng: 20.824393 },
    { lat: 48.6350356, lng: 20.8244458 },
    { lat: 48.634942, lng: 20.824529 },
    { lat: 48.634835, lng: 20.824563 },
    { lat: 48.634702, lng: 20.824789 },
    { lat: 48.634691, lng: 20.824917 },
    { lat: 48.634504, lng: 20.825496 },
    { lat: 48.634486, lng: 20.825781 },
    { lat: 48.634179, lng: 20.82635 },
    { lat: 48.633908, lng: 20.826691 },
    { lat: 48.6338, lng: 20.82699 },
    { lat: 48.633746, lng: 20.827265 },
    { lat: 48.633308, lng: 20.828072 },
    { lat: 48.632945, lng: 20.828424 },
    { lat: 48.6327, lng: 20.828489 },
    { lat: 48.632379, lng: 20.828426 },
    { lat: 48.632086, lng: 20.828511 },
    { lat: 48.631875, lng: 20.828815 },
    { lat: 48.631593, lng: 20.829012 },
    { lat: 48.631256, lng: 20.829626 },
    { lat: 48.631032, lng: 20.830165 },
    { lat: 48.630736, lng: 20.830231 },
    { lat: 48.63039, lng: 20.830216 },
    { lat: 48.630332, lng: 20.830237 },
    { lat: 48.630177, lng: 20.830445 },
    { lat: 48.6297883, lng: 20.8305298 },
    { lat: 48.629077, lng: 20.830548 },
    { lat: 48.628991, lng: 20.830613 },
    { lat: 48.628854, lng: 20.830576 },
    { lat: 48.628717, lng: 20.830625 },
    { lat: 48.628601, lng: 20.830605 },
    { lat: 48.628453, lng: 20.830462 },
    { lat: 48.6280935, lng: 20.8308993 },
    { lat: 48.628009, lng: 20.830923 },
    { lat: 48.627841, lng: 20.83089 },
    { lat: 48.627605, lng: 20.830925 },
    { lat: 48.627376, lng: 20.831034 },
    { lat: 48.627226, lng: 20.831148 },
    { lat: 48.6268592, lng: 20.8310787 },
    { lat: 48.626725, lng: 20.831112 },
    { lat: 48.626687, lng: 20.830958 },
    { lat: 48.62664, lng: 20.83089 },
    { lat: 48.626516, lng: 20.830855 },
    { lat: 48.626428, lng: 20.830881 },
    { lat: 48.626301, lng: 20.830881 },
    { lat: 48.626152, lng: 20.831049 },
    { lat: 48.625972, lng: 20.831148 },
    { lat: 48.62561, lng: 20.831173 },
    { lat: 48.625411, lng: 20.831228 },
    { lat: 48.62494, lng: 20.831412 },
    { lat: 48.624794, lng: 20.831375 },
    { lat: 48.624649, lng: 20.831246 },
    { lat: 48.624255, lng: 20.831173 },
    { lat: 48.624119, lng: 20.831232 },
    { lat: 48.624066, lng: 20.83139 },
    { lat: 48.623968, lng: 20.831427 },
    { lat: 48.623866, lng: 20.83151 },
    { lat: 48.623446, lng: 20.831664 },
    { lat: 48.622954, lng: 20.831784 },
    { lat: 48.622156, lng: 20.831713 },
    { lat: 48.621684, lng: 20.831763 },
    { lat: 48.621499, lng: 20.831798 },
    { lat: 48.620869, lng: 20.831759 },
    { lat: 48.620515, lng: 20.831833 },
    { lat: 48.620018, lng: 20.832047 },
    { lat: 48.6199145, lng: 20.8322444 },
    { lat: 48.6212299, lng: 20.8367477 },
    { lat: 48.620338, lng: 20.8395366 },
    { lat: 48.6195139, lng: 20.8407663 },
    { lat: 48.618878, lng: 20.8418804 },
    { lat: 48.6182439, lng: 20.8433068 },
    { lat: 48.6178044, lng: 20.843743 },
    { lat: 48.6174116, lng: 20.8442406 },
    { lat: 48.6176714, lng: 20.8449122 },
  ],
  Ďurďošík: [
    { lat: 48.7458555, lng: 21.389387 },
    { lat: 48.7456713, lng: 21.3892847 },
    { lat: 48.7438553, lng: 21.3880822 },
    { lat: 48.7432059, lng: 21.3878529 },
    { lat: 48.7423371, lng: 21.3878891 },
    { lat: 48.7406871, lng: 21.3885205 },
    { lat: 48.740039, lng: 21.3886348 },
    { lat: 48.7395344, lng: 21.388883 },
    { lat: 48.738903, lng: 21.3890682 },
    { lat: 48.7380346, lng: 21.3891582 },
    { lat: 48.7353368, lng: 21.3892536 },
    { lat: 48.7345704, lng: 21.3891713 },
    { lat: 48.7329706, lng: 21.3887739 },
    { lat: 48.732901, lng: 21.38881 },
    { lat: 48.7325648, lng: 21.3881081 },
    { lat: 48.7325411, lng: 21.3881443 },
    { lat: 48.73251, lng: 21.3881913 },
    { lat: 48.732028, lng: 21.3881425 },
    { lat: 48.7311097, lng: 21.3880631 },
    { lat: 48.7310519, lng: 21.3880859 },
    { lat: 48.7313783, lng: 21.3894107 },
    { lat: 48.7316893, lng: 21.3909395 },
    { lat: 48.7318605, lng: 21.392081 },
    { lat: 48.7325165, lng: 21.3940893 },
    { lat: 48.7321647, lng: 21.3947113 },
    { lat: 48.7321862, lng: 21.3948631 },
    { lat: 48.731533, lng: 21.3951873 },
    { lat: 48.7312635, lng: 21.3958885 },
    { lat: 48.7308485, lng: 21.3964267 },
    { lat: 48.7306586, lng: 21.3973061 },
    { lat: 48.7302186, lng: 21.3987475 },
    { lat: 48.7300626, lng: 21.3996741 },
    { lat: 48.7300936, lng: 21.4000737 },
    { lat: 48.7299818, lng: 21.4002478 },
    { lat: 48.7299537, lng: 21.4004757 },
    { lat: 48.7300136, lng: 21.4007942 },
    { lat: 48.729975, lng: 21.4012352 },
    { lat: 48.7299136, lng: 21.4014461 },
    { lat: 48.7297177, lng: 21.4017053 },
    { lat: 48.7297667, lng: 21.4020292 },
    { lat: 48.7296744, lng: 21.4022151 },
    { lat: 48.729629, lng: 21.402776 },
    { lat: 48.7295498, lng: 21.4029132 },
    { lat: 48.7296225, lng: 21.4030922 },
    { lat: 48.7295471, lng: 21.403426 },
    { lat: 48.7294613, lng: 21.4036524 },
    { lat: 48.7294031, lng: 21.403661 },
    { lat: 48.7293086, lng: 21.4040046 },
    { lat: 48.7293656, lng: 21.4043689 },
    { lat: 48.7293362, lng: 21.4046657 },
    { lat: 48.7293785, lng: 21.4047241 },
    { lat: 48.7292021, lng: 21.404853 },
    { lat: 48.7291845, lng: 21.4049436 },
    { lat: 48.7290256, lng: 21.405088 },
    { lat: 48.7290624, lng: 21.4051522 },
    { lat: 48.7289336, lng: 21.4054361 },
    { lat: 48.7289576, lng: 21.4055624 },
    { lat: 48.7288703, lng: 21.4056959 },
    { lat: 48.7288376, lng: 21.4059213 },
    { lat: 48.7286906, lng: 21.4063144 },
    { lat: 48.7285704, lng: 21.4070838 },
    { lat: 48.7285985, lng: 21.407346 },
    { lat: 48.7283123, lng: 21.4081706 },
    { lat: 48.7282756, lng: 21.4086767 },
    { lat: 48.7281043, lng: 21.4090158 },
    { lat: 48.7280157, lng: 21.4095187 },
    { lat: 48.7279916, lng: 21.4099555 },
    { lat: 48.7278334, lng: 21.4101629 },
    { lat: 48.7277054, lng: 21.4106271 },
    { lat: 48.7277149, lng: 21.4107252 },
    { lat: 48.7276565, lng: 21.4108802 },
    { lat: 48.7275319, lng: 21.4110055 },
    { lat: 48.7275173, lng: 21.4111983 },
    { lat: 48.7274562, lng: 21.4112612 },
    { lat: 48.7273697, lng: 21.4116841 },
    { lat: 48.7272031, lng: 21.4119505 },
    { lat: 48.7272507, lng: 21.4123397 },
    { lat: 48.7271789, lng: 21.4126172 },
    { lat: 48.7271472, lng: 21.4133311 },
    { lat: 48.7270815, lng: 21.413471 },
    { lat: 48.7270652, lng: 21.413717 },
    { lat: 48.7269845, lng: 21.4137317 },
    { lat: 48.7268446, lng: 21.4141353 },
    { lat: 48.7269155, lng: 21.4143258 },
    { lat: 48.7269979, lng: 21.4144087 },
    { lat: 48.726972, lng: 21.4145608 },
    { lat: 48.7270097, lng: 21.4147372 },
    { lat: 48.7269462, lng: 21.4150626 },
    { lat: 48.7270122, lng: 21.415164 },
    { lat: 48.7270185, lng: 21.4153389 },
    { lat: 48.7271017, lng: 21.4155989 },
    { lat: 48.727073, lng: 21.4156992 },
    { lat: 48.7271465, lng: 21.4166241 },
    { lat: 48.7270974, lng: 21.4171332 },
    { lat: 48.7271291, lng: 21.4172565 },
    { lat: 48.7270985, lng: 21.4174008 },
    { lat: 48.7271074, lng: 21.4185033 },
    { lat: 48.7270555, lng: 21.4189573 },
    { lat: 48.7270748, lng: 21.4193808 },
    { lat: 48.7270755, lng: 21.4194032 },
    { lat: 48.7277745, lng: 21.4192624 },
    { lat: 48.7310096, lng: 21.4178867 },
    { lat: 48.7321897, lng: 21.4174603 },
    { lat: 48.7345397, lng: 21.4168217 },
    { lat: 48.7351424, lng: 21.417077 },
    { lat: 48.7359799, lng: 21.4170405 },
    { lat: 48.7366209, lng: 21.4192459 },
    { lat: 48.7366416, lng: 21.4194891 },
    { lat: 48.7367945, lng: 21.4193754 },
    { lat: 48.7368197, lng: 21.419198 },
    { lat: 48.737308, lng: 21.4188503 },
    { lat: 48.7374529, lng: 21.4186862 },
    { lat: 48.7375779, lng: 21.4186503 },
    { lat: 48.7377221, lng: 21.419078 },
    { lat: 48.7379363, lng: 21.4194731 },
    { lat: 48.7384202, lng: 21.4206583 },
    { lat: 48.7391588, lng: 21.4207886 },
    { lat: 48.73986, lng: 21.421212 },
    { lat: 48.7403218, lng: 21.4212294 },
    { lat: 48.7418089, lng: 21.4203306 },
    { lat: 48.7418978, lng: 21.4214451 },
    { lat: 48.7441852, lng: 21.4208147 },
    { lat: 48.7448526, lng: 21.4205145 },
    { lat: 48.746943, lng: 21.4186354 },
    { lat: 48.7474331, lng: 21.4184252 },
    { lat: 48.7487254, lng: 21.418179 },
    { lat: 48.7506684, lng: 21.4174358 },
    { lat: 48.7506037, lng: 21.4171128 },
    { lat: 48.7503349, lng: 21.4158993 },
    { lat: 48.7497099, lng: 21.4135934 },
    { lat: 48.7487433, lng: 21.4104281 },
    { lat: 48.7483544, lng: 21.4093426 },
    { lat: 48.7479369, lng: 21.4069444 },
    { lat: 48.7475759, lng: 21.4052798 },
    { lat: 48.7467212, lng: 21.3998609 },
    { lat: 48.7466442, lng: 21.399163 },
    { lat: 48.7465659, lng: 21.3980006 },
    { lat: 48.7465735, lng: 21.3959448 },
    { lat: 48.746437, lng: 21.39389 },
    { lat: 48.7463051, lng: 21.3928931 },
    { lat: 48.7463041, lng: 21.3916456 },
    { lat: 48.7458555, lng: 21.389387 },
  ],
  TrstenépriHornáde: [
    { lat: 48.558407, lng: 21.379894 },
    { lat: 48.55851, lng: 21.380101 },
    { lat: 48.558683, lng: 21.380384 },
    { lat: 48.558771, lng: 21.380521 },
    { lat: 48.55899, lng: 21.380772 },
    { lat: 48.559128, lng: 21.381006 },
    { lat: 48.559376, lng: 21.38126 },
    { lat: 48.559745, lng: 21.381614 },
    { lat: 48.559954, lng: 21.381955 },
    { lat: 48.56, lng: 21.382062 },
    { lat: 48.560097, lng: 21.382543 },
    { lat: 48.56011, lng: 21.382995 },
    { lat: 48.56012, lng: 21.383377 },
    { lat: 48.560237, lng: 21.383931 },
    { lat: 48.56038, lng: 21.384402 },
    { lat: 48.560589, lng: 21.385106 },
    { lat: 48.560619, lng: 21.385217 },
    { lat: 48.560753, lng: 21.385621 },
    { lat: 48.560987, lng: 21.386373 },
    { lat: 48.561167, lng: 21.387086 },
    { lat: 48.561342, lng: 21.387782 },
    { lat: 48.561406, lng: 21.387988 },
    { lat: 48.561585, lng: 21.38854 },
    { lat: 48.561788, lng: 21.389329 },
    { lat: 48.562066, lng: 21.390192 },
    { lat: 48.562321, lng: 21.391159 },
    { lat: 48.562474, lng: 21.391351 },
    { lat: 48.562748, lng: 21.392375 },
    { lat: 48.562803, lng: 21.392638 },
    { lat: 48.562896, lng: 21.393031 },
    { lat: 48.563091, lng: 21.393756 },
    { lat: 48.563317, lng: 21.394551 },
    { lat: 48.563431, lng: 21.39504 },
    { lat: 48.563476, lng: 21.395499 },
    { lat: 48.5641238, lng: 21.3942034 },
    { lat: 48.5646477, lng: 21.3934248 },
    { lat: 48.5712125, lng: 21.3817621 },
    { lat: 48.5765478, lng: 21.3715088 },
    { lat: 48.5761653, lng: 21.3709097 },
    { lat: 48.5756425, lng: 21.3704802 },
    { lat: 48.5755963, lng: 21.3703216 },
    { lat: 48.5753482, lng: 21.36997 },
    { lat: 48.5751394, lng: 21.3697206 },
    { lat: 48.5749968, lng: 21.3696599 },
    { lat: 48.5748364, lng: 21.369189 },
    { lat: 48.5747222, lng: 21.3690216 },
    { lat: 48.5746981, lng: 21.3687038 },
    { lat: 48.5746439, lng: 21.3686975 },
    { lat: 48.5744824, lng: 21.3681197 },
    { lat: 48.5743824, lng: 21.3681574 },
    { lat: 48.5742787, lng: 21.3680422 },
    { lat: 48.5742764, lng: 21.3678949 },
    { lat: 48.5742253, lng: 21.3679116 },
    { lat: 48.5739873, lng: 21.3674871 },
    { lat: 48.5739717, lng: 21.3673756 },
    { lat: 48.5740765, lng: 21.3672682 },
    { lat: 48.5757881, lng: 21.366289 },
    { lat: 48.5764449, lng: 21.3660552 },
    { lat: 48.5764054, lng: 21.3654132 },
    { lat: 48.5765627, lng: 21.3653684 },
    { lat: 48.5774703, lng: 21.3657247 },
    { lat: 48.5772955, lng: 21.3642896 },
    { lat: 48.5781568, lng: 21.3637041 },
    { lat: 48.5788571, lng: 21.3633794 },
    { lat: 48.5786645, lng: 21.3617244 },
    { lat: 48.5784068, lng: 21.3586949 },
    { lat: 48.5783062, lng: 21.3568502 },
    { lat: 48.5784187, lng: 21.356812 },
    { lat: 48.5783615, lng: 21.3546462 },
    { lat: 48.5789303, lng: 21.3546297 },
    { lat: 48.5789635, lng: 21.3538612 },
    { lat: 48.5790087, lng: 21.3536488 },
    { lat: 48.5795628, lng: 21.3539197 },
    { lat: 48.5806412, lng: 21.3541945 },
    { lat: 48.5810423, lng: 21.3541308 },
    { lat: 48.5816515, lng: 21.3542589 },
    { lat: 48.5825001, lng: 21.3543011 },
    { lat: 48.5827182, lng: 21.3542534 },
    { lat: 48.5833037, lng: 21.3547856 },
    { lat: 48.583665, lng: 21.3549346 },
    { lat: 48.5837562, lng: 21.3550503 },
    { lat: 48.5839976, lng: 21.3557422 },
    { lat: 48.5843563, lng: 21.3563304 },
    { lat: 48.5857021, lng: 21.3561036 },
    { lat: 48.5867505, lng: 21.3557876 },
    { lat: 48.5872061, lng: 21.3501485 },
    { lat: 48.5875201, lng: 21.3496599 },
    { lat: 48.5904787, lng: 21.336972 },
    { lat: 48.5926321, lng: 21.3298963 },
    { lat: 48.5933281, lng: 21.3273975 },
    { lat: 48.5930622, lng: 21.3272772 },
    { lat: 48.5921584, lng: 21.3266449 },
    { lat: 48.5917886, lng: 21.3261002 },
    { lat: 48.5916217, lng: 21.3253112 },
    { lat: 48.5917399, lng: 21.3244982 },
    { lat: 48.5919938, lng: 21.3236637 },
    { lat: 48.5921427, lng: 21.3233804 },
    { lat: 48.592221, lng: 21.3225883 },
    { lat: 48.592143, lng: 21.3220637 },
    { lat: 48.5919917, lng: 21.321858 },
    { lat: 48.5917672, lng: 21.3215806 },
    { lat: 48.5910359, lng: 21.3213698 },
    { lat: 48.5905151, lng: 21.3213742 },
    { lat: 48.5898499, lng: 21.3217014 },
    { lat: 48.5893275, lng: 21.3220659 },
    { lat: 48.5887305, lng: 21.3221207 },
    { lat: 48.5887094, lng: 21.3222807 },
    { lat: 48.5889401, lng: 21.3233539 },
    { lat: 48.5889904, lng: 21.3239369 },
    { lat: 48.5889353, lng: 21.3264141 },
    { lat: 48.5888192, lng: 21.3272973 },
    { lat: 48.5885105, lng: 21.3280288 },
    { lat: 48.588248, lng: 21.3285001 },
    { lat: 48.5877116, lng: 21.3290707 },
    { lat: 48.5864547, lng: 21.3299255 },
    { lat: 48.5839274, lng: 21.3314952 },
    { lat: 48.5836775, lng: 21.3317548 },
    { lat: 48.5833454, lng: 21.3322355 },
    { lat: 48.5833365, lng: 21.3326796 },
    { lat: 48.582449, lng: 21.333291 },
    { lat: 48.5819471, lng: 21.3333984 },
    { lat: 48.5817791, lng: 21.3333549 },
    { lat: 48.5815996, lng: 21.3332219 },
    { lat: 48.5813513, lng: 21.3329045 },
    { lat: 48.5809115, lng: 21.3330219 },
    { lat: 48.580753, lng: 21.3335471 },
    { lat: 48.5803581, lng: 21.3332871 },
    { lat: 48.5795159, lng: 21.3332367 },
    { lat: 48.5790458, lng: 21.3330637 },
    { lat: 48.5787389, lng: 21.3328183 },
    { lat: 48.5785756, lng: 21.3321795 },
    { lat: 48.5783737, lng: 21.3317193 },
    { lat: 48.5786593, lng: 21.3318589 },
    { lat: 48.5795793, lng: 21.324655 },
    { lat: 48.5794142, lng: 21.3246309 },
    { lat: 48.5781309, lng: 21.3251385 },
    { lat: 48.5781456, lng: 21.3242092 },
    { lat: 48.5787528, lng: 21.3234568 },
    { lat: 48.5790253, lng: 21.3228328 },
    { lat: 48.5790792, lng: 21.32227 },
    { lat: 48.5789572, lng: 21.3216302 },
    { lat: 48.5780749, lng: 21.3211858 },
    { lat: 48.5773001, lng: 21.3209056 },
    { lat: 48.576827, lng: 21.3208576 },
    { lat: 48.5765434, lng: 21.3194116 },
    { lat: 48.576911, lng: 21.3195863 },
    { lat: 48.5775526, lng: 21.3200348 },
    { lat: 48.5777311, lng: 21.3180593 },
    { lat: 48.5780004, lng: 21.3179956 },
    { lat: 48.5782741, lng: 21.3169726 },
    { lat: 48.5784916, lng: 21.3155438 },
    { lat: 48.5790055, lng: 21.3156753 },
    { lat: 48.5796779, lng: 21.3159884 },
    { lat: 48.5799282, lng: 21.3144894 },
    { lat: 48.5797018, lng: 21.3141184 },
    { lat: 48.5795709, lng: 21.3141416 },
    { lat: 48.5793017, lng: 21.3143536 },
    { lat: 48.5785144, lng: 21.3140417 },
    { lat: 48.5785111, lng: 21.3138536 },
    { lat: 48.5779697, lng: 21.3134077 },
    { lat: 48.5773417, lng: 21.3127443 },
    { lat: 48.5765949, lng: 21.3127993 },
    { lat: 48.5765654, lng: 21.3127914 },
    { lat: 48.5749172, lng: 21.312403 },
    { lat: 48.574793, lng: 21.3122769 },
    { lat: 48.5746092, lng: 21.3127452 },
    { lat: 48.5744134, lng: 21.3127105 },
    { lat: 48.5736065, lng: 21.3123047 },
    { lat: 48.5730891, lng: 21.3117958 },
    { lat: 48.5730535, lng: 21.3122507 },
    { lat: 48.5725998, lng: 21.3123215 },
    { lat: 48.5725692, lng: 21.313012 },
    { lat: 48.5717369, lng: 21.313032 },
    { lat: 48.571714, lng: 21.3136806 },
    { lat: 48.5708587, lng: 21.3136819 },
    { lat: 48.5708394, lng: 21.3140074 },
    { lat: 48.569816, lng: 21.3138465 },
    { lat: 48.567047, lng: 21.3141601 },
    { lat: 48.5659846, lng: 21.3140883 },
    { lat: 48.564264, lng: 21.313796 },
    { lat: 48.5634555, lng: 21.3135581 },
    { lat: 48.5619002, lng: 21.3125619 },
    { lat: 48.5609664, lng: 21.3121622 },
    { lat: 48.5606238, lng: 21.312078 },
    { lat: 48.560317, lng: 21.3120868 },
    { lat: 48.5599988, lng: 21.3123561 },
    { lat: 48.5595249, lng: 21.3125765 },
    { lat: 48.5590146, lng: 21.3146628 },
    { lat: 48.558934, lng: 21.3154538 },
    { lat: 48.5593354, lng: 21.3156619 },
    { lat: 48.5593565, lng: 21.3159465 },
    { lat: 48.5595469, lng: 21.316582 },
    { lat: 48.559555, lng: 21.318057 },
    { lat: 48.55973, lng: 21.318225 },
    { lat: 48.559896, lng: 21.318344 },
    { lat: 48.560116, lng: 21.318359 },
    { lat: 48.560259, lng: 21.318358 },
    { lat: 48.560282, lng: 21.318384 },
    { lat: 48.560398, lng: 21.318439 },
    { lat: 48.560498, lng: 21.318508 },
    { lat: 48.560579, lng: 21.318562 },
    { lat: 48.560729, lng: 21.318696 },
    { lat: 48.560835, lng: 21.318833 },
    { lat: 48.560928, lng: 21.318955 },
    { lat: 48.560989, lng: 21.319069 },
    { lat: 48.561118, lng: 21.319245 },
    { lat: 48.561201, lng: 21.319384 },
    { lat: 48.561277, lng: 21.319608 },
    { lat: 48.561284, lng: 21.319754 },
    { lat: 48.56126, lng: 21.319954 },
    { lat: 48.561256, lng: 21.320184 },
    { lat: 48.561291, lng: 21.320344 },
    { lat: 48.561343, lng: 21.320392 },
    { lat: 48.561468, lng: 21.320677 },
    { lat: 48.561554, lng: 21.320919 },
    { lat: 48.561663, lng: 21.321133 },
    { lat: 48.561716, lng: 21.321205 },
    { lat: 48.561761, lng: 21.321351 },
    { lat: 48.561815, lng: 21.32152 },
    { lat: 48.561929, lng: 21.321598 },
    { lat: 48.56199, lng: 21.321727 },
    { lat: 48.562044, lng: 21.321856 },
    { lat: 48.5621024, lng: 21.3219725 },
    { lat: 48.562119, lng: 21.32204 },
    { lat: 48.562184, lng: 21.322253 },
    { lat: 48.562219, lng: 21.322378 },
    { lat: 48.562289, lng: 21.322562 },
    { lat: 48.562339, lng: 21.322752 },
    { lat: 48.56233, lng: 21.322934 },
    { lat: 48.562339, lng: 21.323077 },
    { lat: 48.562354, lng: 21.323276 },
    { lat: 48.562328, lng: 21.323407 },
    { lat: 48.562292, lng: 21.323515 },
    { lat: 48.562263, lng: 21.323656 },
    { lat: 48.562249, lng: 21.323863 },
    { lat: 48.56221, lng: 21.324033 },
    { lat: 48.562167, lng: 21.324156 },
    { lat: 48.56212, lng: 21.324253 },
    { lat: 48.56206, lng: 21.324518 },
    { lat: 48.562021, lng: 21.32472 },
    { lat: 48.562006, lng: 21.324958 },
    { lat: 48.562003, lng: 21.325232 },
    { lat: 48.562034, lng: 21.325502 },
    { lat: 48.562052, lng: 21.325631 },
    { lat: 48.561697, lng: 21.325894 },
    { lat: 48.560988, lng: 21.325436 },
    { lat: 48.560147, lng: 21.324835 },
    { lat: 48.559453, lng: 21.324433 },
    { lat: 48.558736, lng: 21.323962 },
    { lat: 48.5577, lng: 21.323379 },
    { lat: 48.557993, lng: 21.324641 },
    { lat: 48.558088, lng: 21.325185 },
    { lat: 48.558104, lng: 21.32564 },
    { lat: 48.558738, lng: 21.326039 },
    { lat: 48.55887, lng: 21.326247 },
    { lat: 48.559246, lng: 21.327121 },
    { lat: 48.559507, lng: 21.3276871 },
    { lat: 48.55949, lng: 21.3285442 },
    { lat: 48.5594383, lng: 21.3298171 },
    { lat: 48.5593991, lng: 21.330624 },
    { lat: 48.5593838, lng: 21.3311814 },
    { lat: 48.5593546, lng: 21.3316811 },
    { lat: 48.5593522, lng: 21.331844 },
    { lat: 48.5593462, lng: 21.332032 },
    { lat: 48.5592494, lng: 21.3321361 },
    { lat: 48.5593209, lng: 21.3324791 },
    { lat: 48.5592316, lng: 21.3334993 },
    { lat: 48.5592097, lng: 21.3337499 },
    { lat: 48.5591409, lng: 21.3348472 },
    { lat: 48.5590772, lng: 21.335725 },
    { lat: 48.5590161, lng: 21.3368933 },
    { lat: 48.5590124, lng: 21.3374332 },
    { lat: 48.558985, lng: 21.338493 },
    { lat: 48.558993, lng: 21.339249 },
    { lat: 48.559019, lng: 21.339576 },
    { lat: 48.5591975, lng: 21.3404387 },
    { lat: 48.559635, lng: 21.340368 },
    { lat: 48.559882, lng: 21.340365 },
    { lat: 48.559938, lng: 21.341473 },
    { lat: 48.559969, lng: 21.341927 },
    { lat: 48.560037, lng: 21.342621 },
    { lat: 48.560092, lng: 21.342993 },
    { lat: 48.560257, lng: 21.344539 },
    { lat: 48.560254, lng: 21.345141 },
    { lat: 48.560396, lng: 21.346338 },
    { lat: 48.560504, lng: 21.347203 },
    { lat: 48.560562, lng: 21.348466 },
    { lat: 48.560574, lng: 21.349454 },
    { lat: 48.56053, lng: 21.350769 },
    { lat: 48.560513, lng: 21.352084 },
    { lat: 48.560549, lng: 21.352809 },
    { lat: 48.560504, lng: 21.35372 },
    { lat: 48.560479, lng: 21.356265 },
    { lat: 48.560458, lng: 21.356898 },
    { lat: 48.560464, lng: 21.357572 },
    { lat: 48.560462, lng: 21.357615 },
    { lat: 48.560488, lng: 21.358444 },
    { lat: 48.560555, lng: 21.359585 },
    { lat: 48.560561, lng: 21.360754 },
    { lat: 48.560577, lng: 21.361878 },
    { lat: 48.560611, lng: 21.362725 },
    { lat: 48.560475, lng: 21.363753 },
    { lat: 48.560472, lng: 21.364411 },
    { lat: 48.560359, lng: 21.36466 },
    { lat: 48.560426, lng: 21.365291 },
    { lat: 48.560321, lng: 21.365843 },
    { lat: 48.560105, lng: 21.366327 },
    { lat: 48.559792, lng: 21.366706 },
    { lat: 48.559723, lng: 21.36692 },
    { lat: 48.559554, lng: 21.367717 },
    { lat: 48.559387, lng: 21.368469 },
    { lat: 48.559341, lng: 21.368735 },
    { lat: 48.559205, lng: 21.369472 },
    { lat: 48.559147, lng: 21.370153 },
    { lat: 48.559147, lng: 21.370669 },
    { lat: 48.55921, lng: 21.371149 },
    { lat: 48.559341, lng: 21.371487 },
    { lat: 48.559367, lng: 21.371826 },
    { lat: 48.559188, lng: 21.372211 },
    { lat: 48.559041, lng: 21.372623 },
    { lat: 48.558971, lng: 21.373081 },
    { lat: 48.558996, lng: 21.37334 },
    { lat: 48.559207, lng: 21.373804 },
    { lat: 48.559539, lng: 21.374128 },
    { lat: 48.55967, lng: 21.374275 },
    { lat: 48.559689, lng: 21.374475 },
    { lat: 48.559437, lng: 21.374859 },
    { lat: 48.559274, lng: 21.375102 },
    { lat: 48.559114, lng: 21.375359 },
    { lat: 48.558999, lng: 21.375564 },
    { lat: 48.558893, lng: 21.375904 },
    { lat: 48.55883, lng: 21.376192 },
    { lat: 48.558837, lng: 21.376575 },
    { lat: 48.558762, lng: 21.377066 },
    { lat: 48.558761, lng: 21.377564 },
    { lat: 48.558638, lng: 21.378137 },
    { lat: 48.558496, lng: 21.378718 },
    { lat: 48.558231, lng: 21.3791 },
    { lat: 48.55821, lng: 21.37936 },
    { lat: 48.558194, lng: 21.379621 },
    { lat: 48.558407, lng: 21.379894 },
  ],
  VeľkáLodina: [
    { lat: 48.8337802, lng: 21.1233464 },
    { lat: 48.8337685, lng: 21.1233708 },
    { lat: 48.8332591, lng: 21.124442 },
    { lat: 48.8329743, lng: 21.1247526 },
    { lat: 48.8322352, lng: 21.1260296 },
    { lat: 48.8318679, lng: 21.1272168 },
    { lat: 48.8318275, lng: 21.1320536 },
    { lat: 48.8320663, lng: 21.1325895 },
    { lat: 48.8312776, lng: 21.134923 },
    { lat: 48.8302108, lng: 21.1359851 },
    { lat: 48.8299155, lng: 21.1366036 },
    { lat: 48.8290948, lng: 21.1373751 },
    { lat: 48.8287349, lng: 21.1375593 },
    { lat: 48.8285926, lng: 21.1380373 },
    { lat: 48.8279838, lng: 21.139033 },
    { lat: 48.8275588, lng: 21.1392183 },
    { lat: 48.8273485, lng: 21.1395084 },
    { lat: 48.8269403, lng: 21.1398722 },
    { lat: 48.8266822, lng: 21.1402107 },
    { lat: 48.8265148, lng: 21.1409283 },
    { lat: 48.8263022, lng: 21.1411482 },
    { lat: 48.8262677, lng: 21.141864 },
    { lat: 48.8259148, lng: 21.1433291 },
    { lat: 48.8255243, lng: 21.1442516 },
    { lat: 48.8253731, lng: 21.1452645 },
    { lat: 48.8253911, lng: 21.1453704 },
    { lat: 48.8246921, lng: 21.1471729 },
    { lat: 48.8246655, lng: 21.1475027 },
    { lat: 48.8245448, lng: 21.1478151 },
    { lat: 48.8243973, lng: 21.1484512 },
    { lat: 48.8243315, lng: 21.1490668 },
    { lat: 48.8242547, lng: 21.1493431 },
    { lat: 48.8243189, lng: 21.1496666 },
    { lat: 48.8243215, lng: 21.1499615 },
    { lat: 48.824281, lng: 21.150203 },
    { lat: 48.82442, lng: 21.151106 },
    { lat: 48.824497, lng: 21.151748 },
    { lat: 48.824512, lng: 21.152288 },
    { lat: 48.824417, lng: 21.152963 },
    { lat: 48.824241, lng: 21.153428 },
    { lat: 48.824195, lng: 21.153923 },
    { lat: 48.824995, lng: 21.1540545 },
    { lat: 48.8255395, lng: 21.1550246 },
    { lat: 48.8259462, lng: 21.1556572 },
    { lat: 48.8261138, lng: 21.1561151 },
    { lat: 48.8263583, lng: 21.1562723 },
    { lat: 48.8268687, lng: 21.1569588 },
    { lat: 48.8274833, lng: 21.1573895 },
    { lat: 48.8280963, lng: 21.1575204 },
    { lat: 48.8284031, lng: 21.1577199 },
    { lat: 48.8291733, lng: 21.1577714 },
    { lat: 48.8294287, lng: 21.1579136 },
    { lat: 48.830011, lng: 21.1577784 },
    { lat: 48.8304568, lng: 21.1578189 },
    { lat: 48.8307036, lng: 21.1581499 },
    { lat: 48.8309925, lng: 21.1582107 },
    { lat: 48.8319516, lng: 21.1574734 },
    { lat: 48.8322189, lng: 21.1576036 },
    { lat: 48.8328518, lng: 21.1574383 },
    { lat: 48.8335717, lng: 21.1571362 },
    { lat: 48.8346427, lng: 21.1569162 },
    { lat: 48.8354278, lng: 21.1563675 },
    { lat: 48.8357495, lng: 21.1557565 },
    { lat: 48.8364747, lng: 21.1560553 },
    { lat: 48.8367568, lng: 21.1568221 },
    { lat: 48.836657, lng: 21.1577229 },
    { lat: 48.8363851, lng: 21.1586325 },
    { lat: 48.8362557, lng: 21.1594064 },
    { lat: 48.8362128, lng: 21.160332 },
    { lat: 48.8360533, lng: 21.1611566 },
    { lat: 48.836075, lng: 21.1618806 },
    { lat: 48.8361572, lng: 21.1623744 },
    { lat: 48.8362732, lng: 21.1626279 },
    { lat: 48.8363409, lng: 21.163062 },
    { lat: 48.8362917, lng: 21.1635696 },
    { lat: 48.8360645, lng: 21.1639634 },
    { lat: 48.836267, lng: 21.1648179 },
    { lat: 48.8361992, lng: 21.1655916 },
    { lat: 48.8362596, lng: 21.1657254 },
    { lat: 48.8365158, lng: 21.1659973 },
    { lat: 48.836561, lng: 21.1670683 },
    { lat: 48.8367619, lng: 21.1677635 },
    { lat: 48.836984, lng: 21.1682015 },
    { lat: 48.8371566, lng: 21.1692546 },
    { lat: 48.8374657, lng: 21.1700568 },
    { lat: 48.8384353, lng: 21.1717857 },
    { lat: 48.8387686, lng: 21.1719005 },
    { lat: 48.8392065, lng: 21.1719467 },
    { lat: 48.8399504, lng: 21.17112 },
    { lat: 48.840233, lng: 21.1711278 },
    { lat: 48.8408374, lng: 21.1714113 },
    { lat: 48.8410851, lng: 21.1711682 },
    { lat: 48.8415405, lng: 21.1711899 },
    { lat: 48.8420434, lng: 21.171047 },
    { lat: 48.8421629, lng: 21.1710859 },
    { lat: 48.8423289, lng: 21.17114 },
    { lat: 48.8429053, lng: 21.1718217 },
    { lat: 48.8432858, lng: 21.1723633 },
    { lat: 48.8435072, lng: 21.1727817 },
    { lat: 48.8448605, lng: 21.1748715 },
    { lat: 48.8450136, lng: 21.1748913 },
    { lat: 48.8453488, lng: 21.1749348 },
    { lat: 48.8457483, lng: 21.174575 },
    { lat: 48.8461952, lng: 21.1753995 },
    { lat: 48.8463413, lng: 21.1759653 },
    { lat: 48.8465382, lng: 21.1763219 },
    { lat: 48.8466458, lng: 21.1766697 },
    { lat: 48.8469814, lng: 21.176962 },
    { lat: 48.847171, lng: 21.1772237 },
    { lat: 48.8473802, lng: 21.1777249 },
    { lat: 48.8477519, lng: 21.1794482 },
    { lat: 48.8482114, lng: 21.1801722 },
    { lat: 48.8480812, lng: 21.181761 },
    { lat: 48.848002, lng: 21.182069 },
    { lat: 48.8483571, lng: 21.1830492 },
    { lat: 48.8487031, lng: 21.1836045 },
    { lat: 48.8489087, lng: 21.1841657 },
    { lat: 48.8491704, lng: 21.1845477 },
    { lat: 48.8496167, lng: 21.1858848 },
    { lat: 48.8499951, lng: 21.1868273 },
    { lat: 48.8502353, lng: 21.1881307 },
    { lat: 48.8504528, lng: 21.1889658 },
    { lat: 48.8504536, lng: 21.189743 },
    { lat: 48.8509636, lng: 21.1910795 },
    { lat: 48.8509432, lng: 21.1912914 },
    { lat: 48.8512553, lng: 21.1911596 },
    { lat: 48.8524964, lng: 21.1902211 },
    { lat: 48.8526991, lng: 21.1904734 },
    { lat: 48.853067, lng: 21.191187 },
    { lat: 48.8533974, lng: 21.1915709 },
    { lat: 48.8545188, lng: 21.1922638 },
    { lat: 48.8562177, lng: 21.1928973 },
    { lat: 48.8564217, lng: 21.1929123 },
    { lat: 48.8564415, lng: 21.1926785 },
    { lat: 48.856384, lng: 21.1923503 },
    { lat: 48.8564042, lng: 21.1921745 },
    { lat: 48.8563377, lng: 21.1915192 },
    { lat: 48.8564625, lng: 21.1902976 },
    { lat: 48.8568028, lng: 21.189628 },
    { lat: 48.8569577, lng: 21.1891154 },
    { lat: 48.857153, lng: 21.1888451 },
    { lat: 48.857434, lng: 21.1880461 },
    { lat: 48.8574571, lng: 21.1870623 },
    { lat: 48.8574204, lng: 21.1870428 },
    { lat: 48.857462, lng: 21.1867918 },
    { lat: 48.8576581, lng: 21.1869474 },
    { lat: 48.8582539, lng: 21.1876785 },
    { lat: 48.8585586, lng: 21.1881672 },
    { lat: 48.8588176, lng: 21.1888898 },
    { lat: 48.8588654, lng: 21.1892337 },
    { lat: 48.8588591, lng: 21.189677 },
    { lat: 48.8590576, lng: 21.189692 },
    { lat: 48.8590966, lng: 21.189602 },
    { lat: 48.8593141, lng: 21.1894326 },
    { lat: 48.8593848, lng: 21.1888907 },
    { lat: 48.8594599, lng: 21.1886694 },
    { lat: 48.8600982, lng: 21.1880969 },
    { lat: 48.8604691, lng: 21.1878675 },
    { lat: 48.8612162, lng: 21.1877579 },
    { lat: 48.8616798, lng: 21.1875393 },
    { lat: 48.8620036, lng: 21.1877118 },
    { lat: 48.8624154, lng: 21.1880656 },
    { lat: 48.862671, lng: 21.1881783 },
    { lat: 48.8630348, lng: 21.1882965 },
    { lat: 48.8635966, lng: 21.1881237 },
    { lat: 48.8641516, lng: 21.1884504 },
    { lat: 48.8644833, lng: 21.1887646 },
    { lat: 48.8650788, lng: 21.1890573 },
    { lat: 48.8662219, lng: 21.1892641 },
    { lat: 48.866658, lng: 21.1889206 },
    { lat: 48.8670446, lng: 21.1881195 },
    { lat: 48.8673153, lng: 21.1877046 },
    { lat: 48.8676351, lng: 21.187496 },
    { lat: 48.8681021, lng: 21.1873537 },
    { lat: 48.8683337, lng: 21.1873463 },
    { lat: 48.8688324, lng: 21.1872792 },
    { lat: 48.8689859, lng: 21.1870432 },
    { lat: 48.8693503, lng: 21.1857783 },
    { lat: 48.869448, lng: 21.1848776 },
    { lat: 48.8693171, lng: 21.1841896 },
    { lat: 48.8690808, lng: 21.1834756 },
    { lat: 48.8691532, lng: 21.1830539 },
    { lat: 48.8694312, lng: 21.1827708 },
    { lat: 48.8698059, lng: 21.1825128 },
    { lat: 48.8700876, lng: 21.1819927 },
    { lat: 48.8703225, lng: 21.1817308 },
    { lat: 48.870619, lng: 21.181519 },
    { lat: 48.871025, lng: 21.181415 },
    { lat: 48.8713699, lng: 21.1811811 },
    { lat: 48.871667, lng: 21.180857 },
    { lat: 48.87192, lng: 21.180496 },
    { lat: 48.872197, lng: 21.1800773 },
    { lat: 48.872339, lng: 21.179872 },
    { lat: 48.872508, lng: 21.179781 },
    { lat: 48.872665, lng: 21.179674 },
    { lat: 48.872796, lng: 21.179627 },
    { lat: 48.872872, lng: 21.179565 },
    { lat: 48.873022, lng: 21.179299 },
    { lat: 48.873034, lng: 21.179164 },
    { lat: 48.872992, lng: 21.178886 },
    { lat: 48.872961, lng: 21.178674 },
    { lat: 48.872961, lng: 21.178578 },
    { lat: 48.872992, lng: 21.1784103 },
    { lat: 48.873074, lng: 21.178018 },
    { lat: 48.873243, lng: 21.177622 },
    { lat: 48.874234, lng: 21.175836 },
    { lat: 48.874799, lng: 21.175249 },
    { lat: 48.8749, lng: 21.175012 },
    { lat: 48.874907, lng: 21.174608 },
    { lat: 48.874956, lng: 21.174342 },
    { lat: 48.874955, lng: 21.174024 },
    { lat: 48.875045, lng: 21.173431 },
    { lat: 48.875571, lng: 21.172688 },
    { lat: 48.875862, lng: 21.172415 },
    { lat: 48.876448, lng: 21.171767 },
    { lat: 48.876495, lng: 21.171666 },
    { lat: 48.876586, lng: 21.171301 },
    { lat: 48.876782, lng: 21.170872 },
    { lat: 48.876934, lng: 21.170643 },
    { lat: 48.877175, lng: 21.170476 },
    { lat: 48.877889, lng: 21.170281 },
    { lat: 48.878671, lng: 21.170279 },
    { lat: 48.8789463, lng: 21.1699799 },
    { lat: 48.87904, lng: 21.16979 },
    { lat: 48.879206, lng: 21.169025 },
    { lat: 48.879257, lng: 21.168756 },
    { lat: 48.879321, lng: 21.168366 },
    { lat: 48.879471, lng: 21.16783 },
    { lat: 48.879567, lng: 21.167559 },
    { lat: 48.879616, lng: 21.167445 },
    { lat: 48.879717, lng: 21.167271 },
    { lat: 48.87987, lng: 21.166953 },
    { lat: 48.880135, lng: 21.166788 },
    { lat: 48.88038, lng: 21.166706 },
    { lat: 48.880555, lng: 21.16675 },
    { lat: 48.880608, lng: 21.166715 },
    { lat: 48.880733, lng: 21.166504 },
    { lat: 48.880932, lng: 21.166091 },
    { lat: 48.881028, lng: 21.165921 },
    { lat: 48.881175, lng: 21.165769 },
    { lat: 48.88137, lng: 21.165616 },
    { lat: 48.881718, lng: 21.165142 },
    { lat: 48.881844, lng: 21.164897 },
    { lat: 48.881883, lng: 21.164834 },
    { lat: 48.88197, lng: 21.164728 },
    { lat: 48.882234, lng: 21.164586 },
    { lat: 48.882586, lng: 21.16443 },
    { lat: 48.882736, lng: 21.164468 },
    { lat: 48.883064, lng: 21.164425 },
    { lat: 48.883478, lng: 21.164269 },
    { lat: 48.883887, lng: 21.163901 },
    { lat: 48.88496, lng: 21.163423 },
    { lat: 48.88507, lng: 21.163393 },
    { lat: 48.885296, lng: 21.163333 },
    { lat: 48.885643, lng: 21.163208 },
    { lat: 48.885873, lng: 21.163138 },
    { lat: 48.886186, lng: 21.163071 },
    { lat: 48.886349, lng: 21.163115 },
    { lat: 48.886733, lng: 21.163228 },
    { lat: 48.886842, lng: 21.163292 },
    { lat: 48.886972, lng: 21.163198 },
    { lat: 48.887041, lng: 21.163034 },
    { lat: 48.887094, lng: 21.162935 },
    { lat: 48.887303, lng: 21.162914 },
    { lat: 48.887405, lng: 21.162919 },
    { lat: 48.887477, lng: 21.162879 },
    { lat: 48.887611, lng: 21.162718 },
    { lat: 48.887655, lng: 21.162636 },
    { lat: 48.887677, lng: 21.162519 },
    { lat: 48.887686, lng: 21.162341 },
    { lat: 48.887704, lng: 21.162149 },
    { lat: 48.887755, lng: 21.161998 },
    { lat: 48.887829, lng: 21.161906 },
    { lat: 48.887907, lng: 21.161881 },
    { lat: 48.888722, lng: 21.162341 },
    { lat: 48.889304, lng: 21.162821 },
    { lat: 48.889449, lng: 21.16287 },
    { lat: 48.889565, lng: 21.162874 },
    { lat: 48.889724, lng: 21.162854 },
    { lat: 48.889857, lng: 21.162648 },
    { lat: 48.88992, lng: 21.162464 },
    { lat: 48.890004, lng: 21.162316 },
    { lat: 48.890077, lng: 21.162285 },
    { lat: 48.890407, lng: 21.162376 },
    { lat: 48.890526, lng: 21.162378 },
    { lat: 48.890558, lng: 21.162349 },
    { lat: 48.890602, lng: 21.162229 },
    { lat: 48.890639, lng: 21.162103 },
    { lat: 48.890724, lng: 21.161841 },
    { lat: 48.890847, lng: 21.161533 },
    { lat: 48.890877, lng: 21.161444 },
    { lat: 48.890946, lng: 21.161096 },
    { lat: 48.890962, lng: 21.160763 },
    { lat: 48.891037, lng: 21.160777 },
    { lat: 48.891089, lng: 21.160845 },
    { lat: 48.891196, lng: 21.160954 },
    { lat: 48.891232, lng: 21.160962 },
    { lat: 48.891333, lng: 21.160956 },
    { lat: 48.89142, lng: 21.161009 },
    { lat: 48.891447, lng: 21.160961 },
    { lat: 48.89144, lng: 21.160753 },
    { lat: 48.891405, lng: 21.160515 },
    { lat: 48.891383, lng: 21.160436 },
    { lat: 48.891214, lng: 21.160126 },
    { lat: 48.891189, lng: 21.159875 },
    { lat: 48.891179, lng: 21.1597 },
    { lat: 48.891174, lng: 21.159562 },
    { lat: 48.891172, lng: 21.159468 },
    { lat: 48.891176, lng: 21.159337 },
    { lat: 48.891242, lng: 21.159082 },
    { lat: 48.891255, lng: 21.159046 },
    { lat: 48.8912991, lng: 21.1590237 },
    { lat: 48.89137, lng: 21.159111 },
    { lat: 48.8914213, lng: 21.1591185 },
    { lat: 48.8915437, lng: 21.1591071 },
    { lat: 48.89167, lng: 21.159046 },
    { lat: 48.891763, lng: 21.158782 },
    { lat: 48.891806, lng: 21.158577 },
    { lat: 48.891808, lng: 21.158399 },
    { lat: 48.891782, lng: 21.158224 },
    { lat: 48.891765, lng: 21.158097 },
    { lat: 48.891759, lng: 21.157943 },
    { lat: 48.891804, lng: 21.157754 },
    { lat: 48.891911, lng: 21.15757 },
    { lat: 48.892031, lng: 21.157464 },
    { lat: 48.892237, lng: 21.157153 },
    { lat: 48.892366, lng: 21.156989 },
    { lat: 48.892438, lng: 21.156953 },
    { lat: 48.892685, lng: 21.157024 },
    { lat: 48.893387, lng: 21.156719 },
    { lat: 48.893564, lng: 21.156819 },
    { lat: 48.893615, lng: 21.156925 },
    { lat: 48.893654, lng: 21.156954 },
    { lat: 48.893781, lng: 21.156951 },
    { lat: 48.893975, lng: 21.156787 },
    { lat: 48.894022, lng: 21.156783 },
    { lat: 48.894148, lng: 21.156758 },
    { lat: 48.89424, lng: 21.156692 },
    { lat: 48.894362, lng: 21.156662 },
    { lat: 48.894521, lng: 21.156699 },
    { lat: 48.894622, lng: 21.156619 },
    { lat: 48.894839, lng: 21.156476 },
    { lat: 48.894939, lng: 21.156378 },
    { lat: 48.895053, lng: 21.156347 },
    { lat: 48.89516, lng: 21.156307 },
    { lat: 48.895189, lng: 21.156241 },
    { lat: 48.895211, lng: 21.155912 },
    { lat: 48.895259, lng: 21.155712 },
    { lat: 48.895331, lng: 21.155471 },
    { lat: 48.8950962, lng: 21.1552213 },
    { lat: 48.8947949, lng: 21.1547315 },
    { lat: 48.8949906, lng: 21.1542684 },
    { lat: 48.8949768, lng: 21.1540795 },
    { lat: 48.8950424, lng: 21.153634 },
    { lat: 48.8939782, lng: 21.1529383 },
    { lat: 48.8935348, lng: 21.1527582 },
    { lat: 48.892353, lng: 21.1520117 },
    { lat: 48.8914538, lng: 21.1511177 },
    { lat: 48.8912197, lng: 21.1510829 },
    { lat: 48.8899992, lng: 21.1502475 },
    { lat: 48.8896842, lng: 21.1504639 },
    { lat: 48.8851011, lng: 21.1496373 },
    { lat: 48.8847353, lng: 21.1502397 },
    { lat: 48.8839653, lng: 21.1510107 },
    { lat: 48.8835947, lng: 21.1515669 },
    { lat: 48.880019, lng: 21.1489671 },
    { lat: 48.8796256, lng: 21.1484551 },
    { lat: 48.879629, lng: 21.1483444 },
    { lat: 48.8795501, lng: 21.1482379 },
    { lat: 48.8795527, lng: 21.1481036 },
    { lat: 48.8793442, lng: 21.147634 },
    { lat: 48.8789502, lng: 21.14717 },
    { lat: 48.878752, lng: 21.1470233 },
    { lat: 48.8785992, lng: 21.1467784 },
    { lat: 48.8783761, lng: 21.1467436 },
    { lat: 48.8780101, lng: 21.1464442 },
    { lat: 48.8773246, lng: 21.1461263 },
    { lat: 48.8768017, lng: 21.1459621 },
    { lat: 48.87648, lng: 21.1459992 },
    { lat: 48.8752429, lng: 21.1451102 },
    { lat: 48.8747119, lng: 21.144932 },
    { lat: 48.8740685, lng: 21.1444126 },
    { lat: 48.873441, lng: 21.1432626 },
    { lat: 48.8731079, lng: 21.1436523 },
    { lat: 48.8722606, lng: 21.1442175 },
    { lat: 48.8717382, lng: 21.1447347 },
    { lat: 48.8715483, lng: 21.1451708 },
    { lat: 48.8715407, lng: 21.1454316 },
    { lat: 48.8716572, lng: 21.1459429 },
    { lat: 48.8715072, lng: 21.1458963 },
    { lat: 48.8712711, lng: 21.1456597 },
    { lat: 48.8712399, lng: 21.1451379 },
    { lat: 48.8711705, lng: 21.1449724 },
    { lat: 48.8711268, lng: 21.144621 },
    { lat: 48.8710342, lng: 21.1445037 },
    { lat: 48.8709237, lng: 21.1437964 },
    { lat: 48.8708454, lng: 21.1436674 },
    { lat: 48.8705077, lng: 21.1434832 },
    { lat: 48.8704688, lng: 21.1430103 },
    { lat: 48.8706368, lng: 21.1424485 },
    { lat: 48.8703928, lng: 21.1418575 },
    { lat: 48.870432, lng: 21.1413556 },
    { lat: 48.8703565, lng: 21.1411395 },
    { lat: 48.8702149, lng: 21.1409354 },
    { lat: 48.8698299, lng: 21.1406796 },
    { lat: 48.8671257, lng: 21.1328747 },
    { lat: 48.8666446, lng: 21.1328874 },
    { lat: 48.8662034, lng: 21.132504 },
    { lat: 48.865744, lng: 21.1323871 },
    { lat: 48.8637723, lng: 21.1311543 },
    { lat: 48.8629077, lng: 21.1307598 },
    { lat: 48.8623521, lng: 21.1307325 },
    { lat: 48.8620236, lng: 21.1305615 },
    { lat: 48.8617485, lng: 21.1306849 },
    { lat: 48.8615551, lng: 21.1308317 },
    { lat: 48.860975, lng: 21.130995 },
    { lat: 48.85933, lng: 21.1308682 },
    { lat: 48.8589718, lng: 21.1308919 },
    { lat: 48.8587523, lng: 21.1310613 },
    { lat: 48.8585787, lng: 21.1310551 },
    { lat: 48.8583304, lng: 21.1313145 },
    { lat: 48.8576588, lng: 21.1322769 },
    { lat: 48.856888, lng: 21.1331629 },
    { lat: 48.8566491, lng: 21.1333683 },
    { lat: 48.8562584, lng: 21.1335049 },
    { lat: 48.8552618, lng: 21.1342955 },
    { lat: 48.8549798, lng: 21.1347353 },
    { lat: 48.8548375, lng: 21.1351385 },
    { lat: 48.8546555, lng: 21.1354724 },
    { lat: 48.854419, lng: 21.1356458 },
    { lat: 48.8541981, lng: 21.1359508 },
    { lat: 48.8540041, lng: 21.136397 },
    { lat: 48.8538397, lng: 21.137028 },
    { lat: 48.8534923, lng: 21.1376409 },
    { lat: 48.853286, lng: 21.1381621 },
    { lat: 48.8532294, lng: 21.1385003 },
    { lat: 48.8530904, lng: 21.1386241 },
    { lat: 48.8529428, lng: 21.1389058 },
    { lat: 48.8524634, lng: 21.1392568 },
    { lat: 48.8518755, lng: 21.1393541 },
    { lat: 48.8502037, lng: 21.1345255 },
    { lat: 48.8496908, lng: 21.1337951 },
    { lat: 48.8491252, lng: 21.1328596 },
    { lat: 48.8481462, lng: 21.1324002 },
    { lat: 48.8475505, lng: 21.1323928 },
    { lat: 48.8466943, lng: 21.131377 },
    { lat: 48.8462447, lng: 21.1322071 },
    { lat: 48.8462199, lng: 21.1324144 },
    { lat: 48.8460505, lng: 21.1324697 },
    { lat: 48.845823, lng: 21.1327111 },
    { lat: 48.845818, lng: 21.1328712 },
    { lat: 48.8457234, lng: 21.1329364 },
    { lat: 48.8457067, lng: 21.1330205 },
    { lat: 48.845511, lng: 21.1330573 },
    { lat: 48.8453858, lng: 21.1332223 },
    { lat: 48.8447036, lng: 21.1337489 },
    { lat: 48.8445554, lng: 21.1340034 },
    { lat: 48.8445452, lng: 21.1343196 },
    { lat: 48.8442966, lng: 21.1347473 },
    { lat: 48.8442072, lng: 21.135119 },
    { lat: 48.8439626, lng: 21.1355689 },
    { lat: 48.8433103, lng: 21.1362186 },
    { lat: 48.8432474, lng: 21.1360064 },
    { lat: 48.8431647, lng: 21.1360621 },
    { lat: 48.8430471, lng: 21.1359371 },
    { lat: 48.8429597, lng: 21.1359477 },
    { lat: 48.842823, lng: 21.1357216 },
    { lat: 48.8422739, lng: 21.1353412 },
    { lat: 48.842143, lng: 21.135081 },
    { lat: 48.841867, lng: 21.1349979 },
    { lat: 48.8418422, lng: 21.1348258 },
    { lat: 48.8417385, lng: 21.1347855 },
    { lat: 48.8415773, lng: 21.1344918 },
    { lat: 48.8415487, lng: 21.1341941 },
    { lat: 48.8414588, lng: 21.1340953 },
    { lat: 48.8413639, lng: 21.1333759 },
    { lat: 48.8411518, lng: 21.1326204 },
    { lat: 48.8407774, lng: 21.1321054 },
    { lat: 48.8405133, lng: 21.1318979 },
    { lat: 48.8402841, lng: 21.1315231 },
    { lat: 48.8397633, lng: 21.1311432 },
    { lat: 48.8394365, lng: 21.130673 },
    { lat: 48.8384918, lng: 21.1298834 },
    { lat: 48.8363897, lng: 21.1267556 },
    { lat: 48.8357841, lng: 21.1256568 },
    { lat: 48.8357183, lng: 21.1256409 },
    { lat: 48.8357616, lng: 21.1258024 },
    { lat: 48.8349342, lng: 21.125548 },
    { lat: 48.8342187, lng: 21.1246767 },
    { lat: 48.834148, lng: 21.1238363 },
    { lat: 48.833927, lng: 21.1234034 },
    { lat: 48.8337802, lng: 21.1233464 },
  ],
  Haniska: [
    { lat: 48.6270008, lng: 21.2214569 },
    { lat: 48.626893, lng: 21.2215842 },
    { lat: 48.6238872, lng: 21.2252274 },
    { lat: 48.623772, lng: 21.2253615 },
    { lat: 48.6223971, lng: 21.2269589 },
    { lat: 48.6223721, lng: 21.2269877 },
    { lat: 48.6223046, lng: 21.2270637 },
    { lat: 48.6218306, lng: 21.227596 },
    { lat: 48.6217165, lng: 21.2277244 },
    { lat: 48.6209901, lng: 21.2285415 },
    { lat: 48.620976, lng: 21.2285567 },
    { lat: 48.6209575, lng: 21.2285776 },
    { lat: 48.6209477, lng: 21.2285886 },
    { lat: 48.6208767, lng: 21.2286936 },
    { lat: 48.6208767, lng: 21.2286939 },
    { lat: 48.6208713, lng: 21.2287021 },
    { lat: 48.6208123, lng: 21.2287899 },
    { lat: 48.6208112, lng: 21.2287918 },
    { lat: 48.6208057, lng: 21.2287986 },
    { lat: 48.6207294, lng: 21.2289128 },
    { lat: 48.6202079, lng: 21.2296908 },
    { lat: 48.6197643, lng: 21.2303736 },
    { lat: 48.6196741, lng: 21.2305123 },
    { lat: 48.6195689, lng: 21.2306745 },
    { lat: 48.6194744, lng: 21.230821 },
    { lat: 48.6186884, lng: 21.2323682 },
    { lat: 48.6181952, lng: 21.2336296 },
    { lat: 48.6180373, lng: 21.2341548 },
    { lat: 48.6171489, lng: 21.2347243 },
    { lat: 48.6166135, lng: 21.2352004 },
    { lat: 48.6162907, lng: 21.2357543 },
    { lat: 48.6165162, lng: 21.2380425 },
    { lat: 48.6158781, lng: 21.2383334 },
    { lat: 48.6159546, lng: 21.2388736 },
    { lat: 48.6159052, lng: 21.2388412 },
    { lat: 48.6154679, lng: 21.2391097 },
    { lat: 48.6153561, lng: 21.239421 },
    { lat: 48.6134808, lng: 21.2404617 },
    { lat: 48.6137357, lng: 21.2422195 },
    { lat: 48.6123136, lng: 21.2432803 },
    { lat: 48.6126334, lng: 21.244738 },
    { lat: 48.6125992, lng: 21.2448677 },
    { lat: 48.6128102, lng: 21.2469448 },
    { lat: 48.6098757, lng: 21.2488199 },
    { lat: 48.6101634, lng: 21.2513061 },
    { lat: 48.6083758, lng: 21.2516562 },
    { lat: 48.6084686, lng: 21.2523613 },
    { lat: 48.6047405, lng: 21.2528161 },
    { lat: 48.6032966, lng: 21.2528489 },
    { lat: 48.5995151, lng: 21.2536516 },
    { lat: 48.5967152, lng: 21.2535811 },
    { lat: 48.5963549, lng: 21.2519483 },
    { lat: 48.5963446, lng: 21.2519025 },
    { lat: 48.5961908, lng: 21.2519855 },
    { lat: 48.5958361, lng: 21.2520912 },
    { lat: 48.5956515, lng: 21.2520473 },
    { lat: 48.595105, lng: 21.2521761 },
    { lat: 48.5945897, lng: 21.2521822 },
    { lat: 48.5929121, lng: 21.2518667 },
    { lat: 48.5929882, lng: 21.2534857 },
    { lat: 48.5932081, lng: 21.255208 },
    { lat: 48.5929026, lng: 21.2561322 },
    { lat: 48.5928539, lng: 21.2576522 },
    { lat: 48.5923423, lng: 21.2576267 },
    { lat: 48.5921559, lng: 21.2605163 },
    { lat: 48.5927789, lng: 21.2606826 },
    { lat: 48.5926621, lng: 21.2636668 },
    { lat: 48.5932542, lng: 21.2693845 },
    { lat: 48.5932258, lng: 21.2699635 },
    { lat: 48.5935807, lng: 21.272711 },
    { lat: 48.5945329, lng: 21.2789597 },
    { lat: 48.5944632, lng: 21.2800198 },
    { lat: 48.5945028, lng: 21.2812279 },
    { lat: 48.5958247, lng: 21.2819834 },
    { lat: 48.5963552, lng: 21.2821469 },
    { lat: 48.5964088, lng: 21.2822786 },
    { lat: 48.5978383, lng: 21.2838167 },
    { lat: 48.5979271, lng: 21.2849507 },
    { lat: 48.5979277, lng: 21.2849673 },
    { lat: 48.6015445, lng: 21.284266 },
    { lat: 48.603508, lng: 21.2841153 },
    { lat: 48.6064592, lng: 21.2833931 },
    { lat: 48.6089469, lng: 21.2822043 },
    { lat: 48.6109596, lng: 21.280858 },
    { lat: 48.6117879, lng: 21.2804921 },
    { lat: 48.6127502, lng: 21.2798393 },
    { lat: 48.6129219, lng: 21.2798988 },
    { lat: 48.6130612, lng: 21.2798514 },
    { lat: 48.613992, lng: 21.2794914 },
    { lat: 48.6161214, lng: 21.2785432 },
    { lat: 48.6178478, lng: 21.2781431 },
    { lat: 48.6187593, lng: 21.2780669 },
    { lat: 48.6218905, lng: 21.2782251 },
    { lat: 48.6219276, lng: 21.2782278 },
    { lat: 48.6231593, lng: 21.2782035 },
    { lat: 48.6252417, lng: 21.2780367 },
    { lat: 48.6282036, lng: 21.27755 },
    { lat: 48.629288, lng: 21.2773132 },
    { lat: 48.6288022, lng: 21.2757616 },
    { lat: 48.6286726, lng: 21.2751293 },
    { lat: 48.6288867, lng: 21.2750648 },
    { lat: 48.6291457, lng: 21.2715698 },
    { lat: 48.6292007, lng: 21.2715947 },
    { lat: 48.6292464, lng: 21.2710728 },
    { lat: 48.6292742, lng: 21.2701181 },
    { lat: 48.6291936, lng: 21.2644746 },
    { lat: 48.629146, lng: 21.2629051 },
    { lat: 48.6290486, lng: 21.2622246 },
    { lat: 48.6323594, lng: 21.2625517 },
    { lat: 48.6322883, lng: 21.2609397 },
    { lat: 48.6333673, lng: 21.2610039 },
    { lat: 48.6404601, lng: 21.2621452 },
    { lat: 48.6443621, lng: 21.2626653 },
    { lat: 48.6445177, lng: 21.2627088 },
    { lat: 48.644692, lng: 21.2628531 },
    { lat: 48.645372, lng: 21.263498 },
    { lat: 48.64537, lng: 21.263472 },
    { lat: 48.645249, lng: 21.261041 },
    { lat: 48.645249, lng: 21.261037 },
    { lat: 48.645258, lng: 21.26073 },
    { lat: 48.645259, lng: 21.260691 },
    { lat: 48.645258, lng: 21.260684 },
    { lat: 48.645229, lng: 21.260381 },
    { lat: 48.64523, lng: 21.260371 },
    { lat: 48.645228, lng: 21.259948 },
    { lat: 48.645227, lng: 21.25994 },
    { lat: 48.644877, lng: 21.252382 },
    { lat: 48.644877, lng: 21.25238 },
    { lat: 48.64489, lng: 21.25226 },
    { lat: 48.644892, lng: 21.25222 },
    { lat: 48.6450071, lng: 21.2487645 },
    { lat: 48.645066, lng: 21.247639 },
    { lat: 48.645069, lng: 21.247386 },
    { lat: 48.645086, lng: 21.247221 },
    { lat: 48.645768, lng: 21.243828 },
    { lat: 48.646014, lng: 21.242636 },
    { lat: 48.64649, lng: 21.241818 },
    { lat: 48.646901, lng: 21.240729 },
    { lat: 48.64721, lng: 21.239949 },
    { lat: 48.647234, lng: 21.23989 },
    { lat: 48.647243, lng: 21.239868 },
    { lat: 48.647253, lng: 21.239842 },
    { lat: 48.647256, lng: 21.239835 },
    { lat: 48.647274, lng: 21.239789 },
    { lat: 48.647287, lng: 21.239756 },
    { lat: 48.647292, lng: 21.239751 },
    { lat: 48.647411, lng: 21.239615 },
    { lat: 48.647545, lng: 21.239475 },
    { lat: 48.647769, lng: 21.23925 },
    { lat: 48.647829, lng: 21.239154 },
    { lat: 48.648031, lng: 21.238698 },
    { lat: 48.648196, lng: 21.238379 },
    { lat: 48.648207, lng: 21.238356 },
    { lat: 48.648412, lng: 21.237959 },
    { lat: 48.648525, lng: 21.237635 },
    { lat: 48.648566, lng: 21.237515 },
    { lat: 48.648595, lng: 21.237433 },
    { lat: 48.648774, lng: 21.236919 },
    { lat: 48.648816, lng: 21.236617 },
    { lat: 48.648819, lng: 21.2365 },
    { lat: 48.64882, lng: 21.236458 },
    { lat: 48.648822, lng: 21.236379 },
    { lat: 48.648757, lng: 21.235597 },
    { lat: 48.649042, lng: 21.234683 },
    { lat: 48.649118, lng: 21.234433 },
    { lat: 48.649166, lng: 21.234229 },
    { lat: 48.649102, lng: 21.233869 },
    { lat: 48.649092, lng: 21.233811 },
    { lat: 48.648594, lng: 21.230979 },
    { lat: 48.648322, lng: 21.229554 },
    { lat: 48.648261, lng: 21.229359 },
    { lat: 48.64823, lng: 21.229167 },
    { lat: 48.648207, lng: 21.229131 },
    { lat: 48.647811, lng: 21.228485 },
    { lat: 48.64714, lng: 21.227391 },
    { lat: 48.646624, lng: 21.226126 },
    { lat: 48.646616, lng: 21.226105 },
    { lat: 48.646602, lng: 21.226073 },
    { lat: 48.646582, lng: 21.226022 },
    { lat: 48.646578, lng: 21.226013 },
    { lat: 48.646577, lng: 21.226011 },
    { lat: 48.646545, lng: 21.225933 },
    { lat: 48.646529, lng: 21.225894 },
    { lat: 48.646524, lng: 21.225882 },
    { lat: 48.6465, lng: 21.225834 },
    { lat: 48.645527, lng: 21.223912 },
    { lat: 48.645474, lng: 21.223849 },
    { lat: 48.645471, lng: 21.223756 },
    { lat: 48.645271, lng: 21.222897 },
    { lat: 48.6448875, lng: 21.2221935 },
    { lat: 48.644623, lng: 21.221707 },
    { lat: 48.64376, lng: 21.220332 },
    { lat: 48.643698, lng: 21.220271 },
    { lat: 48.643697, lng: 21.220192 },
    { lat: 48.64253, lng: 21.217243 },
    { lat: 48.642439, lng: 21.217012 },
    { lat: 48.637355, lng: 21.219546 },
    { lat: 48.637341, lng: 21.219461 },
    { lat: 48.637317, lng: 21.219334 },
    { lat: 48.6371789, lng: 21.2193359 },
    { lat: 48.6365898, lng: 21.2204396 },
    { lat: 48.6349576, lng: 21.2203555 },
    { lat: 48.6345013, lng: 21.2204499 },
    { lat: 48.633119, lng: 21.2210869 },
    { lat: 48.632942, lng: 21.2212287 },
    { lat: 48.6319967, lng: 21.2223053 },
    { lat: 48.6298976, lng: 21.2237037 },
    { lat: 48.6295647, lng: 21.2236402 },
    { lat: 48.6276028, lng: 21.2218728 },
    { lat: 48.6270419, lng: 21.221529 },
    { lat: 48.6270008, lng: 21.2214569 },
  ],
  Kalša: [
    { lat: 48.6245309, lng: 21.5065867 },
    { lat: 48.6252561, lng: 21.503708 },
    { lat: 48.6248866, lng: 21.5034608 },
    { lat: 48.6240152, lng: 21.5026633 },
    { lat: 48.6230633, lng: 21.5019614 },
    { lat: 48.622953, lng: 21.501693 },
    { lat: 48.6228625, lng: 21.5015951 },
    { lat: 48.6227842, lng: 21.5020359 },
    { lat: 48.6227092, lng: 21.5021749 },
    { lat: 48.622514, lng: 21.5023069 },
    { lat: 48.6223538, lng: 21.5022555 },
    { lat: 48.6221751, lng: 21.5016352 },
    { lat: 48.6215509, lng: 21.5009481 },
    { lat: 48.6211367, lng: 21.5005912 },
    { lat: 48.6210072, lng: 21.5004314 },
    { lat: 48.6195416, lng: 21.4986214 },
    { lat: 48.6185027, lng: 21.498026 },
    { lat: 48.6178817, lng: 21.4996297 },
    { lat: 48.6175696, lng: 21.5000019 },
    { lat: 48.617277, lng: 21.5001983 },
    { lat: 48.6169099, lng: 21.5008539 },
    { lat: 48.6161614, lng: 21.5014735 },
    { lat: 48.6160694, lng: 21.5013636 },
    { lat: 48.6157069, lng: 21.5015918 },
    { lat: 48.6148988, lng: 21.5017035 },
    { lat: 48.614712, lng: 21.5027161 },
    { lat: 48.6141875, lng: 21.5034908 },
    { lat: 48.6138119, lng: 21.5045556 },
    { lat: 48.6137237, lng: 21.5049323 },
    { lat: 48.6135595, lng: 21.5052485 },
    { lat: 48.6133758, lng: 21.5054341 },
    { lat: 48.6131484, lng: 21.5055052 },
    { lat: 48.6128897, lng: 21.5058642 },
    { lat: 48.6128031, lng: 21.5061743 },
    { lat: 48.611783, lng: 21.5072844 },
    { lat: 48.6116587, lng: 21.5095786 },
    { lat: 48.6120381, lng: 21.5109153 },
    { lat: 48.6117367, lng: 21.5120103 },
    { lat: 48.6116024, lng: 21.5122787 },
    { lat: 48.6112978, lng: 21.5126403 },
    { lat: 48.609769, lng: 21.5135122 },
    { lat: 48.6093816, lng: 21.5131101 },
    { lat: 48.6094374, lng: 21.5126247 },
    { lat: 48.6091696, lng: 21.5124704 },
    { lat: 48.6085265, lng: 21.5117878 },
    { lat: 48.6082218, lng: 21.5115542 },
    { lat: 48.6079735, lng: 21.5114147 },
    { lat: 48.6077829, lng: 21.5115534 },
    { lat: 48.6073657, lng: 21.512188 },
    { lat: 48.6072663, lng: 21.5125686 },
    { lat: 48.6071691, lng: 21.5126346 },
    { lat: 48.6068978, lng: 21.5131789 },
    { lat: 48.6067801, lng: 21.5136215 },
    { lat: 48.6060191, lng: 21.5153736 },
    { lat: 48.605396, lng: 21.5162889 },
    { lat: 48.6044033, lng: 21.5175707 },
    { lat: 48.603483, lng: 21.5162403 },
    { lat: 48.6030152, lng: 21.5152384 },
    { lat: 48.602708, lng: 21.514845 },
    { lat: 48.6025057, lng: 21.5149688 },
    { lat: 48.6020415, lng: 21.5161627 },
    { lat: 48.6013459, lng: 21.5164644 },
    { lat: 48.601081, lng: 21.5166822 },
    { lat: 48.6008565, lng: 21.5163126 },
    { lat: 48.6006195, lng: 21.5163302 },
    { lat: 48.6004545, lng: 21.5160676 },
    { lat: 48.6002757, lng: 21.5159594 },
    { lat: 48.6002802, lng: 21.5157942 },
    { lat: 48.5999643, lng: 21.5155358 },
    { lat: 48.5999515, lng: 21.5153603 },
    { lat: 48.5997859, lng: 21.5151183 },
    { lat: 48.5994139, lng: 21.51486 },
    { lat: 48.5992933, lng: 21.5146994 },
    { lat: 48.5992308, lng: 21.5144873 },
    { lat: 48.5991083, lng: 21.5144498 },
    { lat: 48.5989886, lng: 21.5142784 },
    { lat: 48.5989773, lng: 21.513886 },
    { lat: 48.5989048, lng: 21.5138669 },
    { lat: 48.598588, lng: 21.5142396 },
    { lat: 48.5983508, lng: 21.5143613 },
    { lat: 48.5973857, lng: 21.5143792 },
    { lat: 48.5970444, lng: 21.5144819 },
    { lat: 48.5964557, lng: 21.5145104 },
    { lat: 48.596072, lng: 21.514477 },
    { lat: 48.596253, lng: 21.514611 },
    { lat: 48.596655, lng: 21.514973 },
    { lat: 48.597908, lng: 21.515795 },
    { lat: 48.598204, lng: 21.515965 },
    { lat: 48.598501, lng: 21.516197 },
    { lat: 48.598721, lng: 21.516433 },
    { lat: 48.598915, lng: 21.516796 },
    { lat: 48.59906, lng: 21.517156 },
    { lat: 48.599322, lng: 21.517484 },
    { lat: 48.599576, lng: 21.517943 },
    { lat: 48.599996, lng: 21.518398 },
    { lat: 48.600199, lng: 21.518689 },
    { lat: 48.600354, lng: 21.518992 },
    { lat: 48.600642, lng: 21.519377 },
    { lat: 48.600823, lng: 21.519668 },
    { lat: 48.600995, lng: 21.520028 },
    { lat: 48.601144, lng: 21.520442 },
    { lat: 48.601199, lng: 21.520692 },
    { lat: 48.601212, lng: 21.520919 },
    { lat: 48.601183, lng: 21.521149 },
    { lat: 48.601097, lng: 21.52145 },
    { lat: 48.600733, lng: 21.522363 },
    { lat: 48.60064, lng: 21.522698 },
    { lat: 48.600611, lng: 21.522979 },
    { lat: 48.600652, lng: 21.523299 },
    { lat: 48.600662, lng: 21.523392 },
    { lat: 48.600767, lng: 21.524348 },
    { lat: 48.601832, lng: 21.526633 },
    { lat: 48.60191, lng: 21.526801 },
    { lat: 48.602132, lng: 21.527274 },
    { lat: 48.602201, lng: 21.527421 },
    { lat: 48.602334, lng: 21.527704 },
    { lat: 48.602523, lng: 21.528102 },
    { lat: 48.602837, lng: 21.528315 },
    { lat: 48.603335, lng: 21.528654 },
    { lat: 48.603513, lng: 21.528768 },
    { lat: 48.604289, lng: 21.529283 },
    { lat: 48.604462, lng: 21.529402 },
    { lat: 48.604619, lng: 21.529516 },
    { lat: 48.604743, lng: 21.529605 },
    { lat: 48.604851, lng: 21.52977 },
    { lat: 48.605256, lng: 21.52999 },
    { lat: 48.605478, lng: 21.53023 },
    { lat: 48.605963, lng: 21.530459 },
    { lat: 48.606244, lng: 21.530744 },
    { lat: 48.606362, lng: 21.530783 },
    { lat: 48.606498, lng: 21.53098 },
    { lat: 48.606751, lng: 21.53115 },
    { lat: 48.606854, lng: 21.531329 },
    { lat: 48.606984, lng: 21.531348 },
    { lat: 48.607068, lng: 21.531396 },
    { lat: 48.607055, lng: 21.531482 },
    { lat: 48.607034, lng: 21.531591 },
    { lat: 48.606948, lng: 21.531966 },
    { lat: 48.606826, lng: 21.5325 },
    { lat: 48.60647, lng: 21.534258 },
    { lat: 48.606306, lng: 21.535107 },
    { lat: 48.606119, lng: 21.536071 },
    { lat: 48.606174, lng: 21.536431 },
    { lat: 48.60626, lng: 21.536687 },
    { lat: 48.606784, lng: 21.537516 },
    { lat: 48.607172, lng: 21.537946 },
    { lat: 48.607445, lng: 21.538056 },
    { lat: 48.607678, lng: 21.538211 },
    { lat: 48.607872, lng: 21.538403 },
    { lat: 48.607996, lng: 21.53864 },
    { lat: 48.608202, lng: 21.538818 },
    { lat: 48.608451, lng: 21.539146 },
    { lat: 48.608581, lng: 21.539363 },
    { lat: 48.608484, lng: 21.539493 },
    { lat: 48.608329, lng: 21.539577 },
    { lat: 48.608212, lng: 21.53976 },
    { lat: 48.608717, lng: 21.540561 },
    { lat: 48.608499, lng: 21.540934 },
    { lat: 48.608972, lng: 21.541625 },
    { lat: 48.609893, lng: 21.542937 },
    { lat: 48.610119, lng: 21.543166 },
    { lat: 48.610137, lng: 21.54319 },
    { lat: 48.610036, lng: 21.543658 },
    { lat: 48.610055, lng: 21.543678 },
    { lat: 48.610078, lng: 21.543707 },
    { lat: 48.610127, lng: 21.54378 },
    { lat: 48.610381, lng: 21.543942 },
    { lat: 48.610533, lng: 21.54411 },
    { lat: 48.610569, lng: 21.544225 },
    { lat: 48.610782, lng: 21.544277 },
    { lat: 48.610997, lng: 21.544373 },
    { lat: 48.611022, lng: 21.544393 },
    { lat: 48.611008, lng: 21.5445 },
    { lat: 48.610949, lng: 21.544619 },
    { lat: 48.610918, lng: 21.5448 },
    { lat: 48.610913, lng: 21.545028 },
    { lat: 48.610989, lng: 21.545139 },
    { lat: 48.611041, lng: 21.545345 },
    { lat: 48.611033, lng: 21.54537 },
    { lat: 48.61085, lng: 21.545584 },
    { lat: 48.610839, lng: 21.545696 },
    { lat: 48.610773, lng: 21.545839 },
    { lat: 48.6106, lng: 21.545884 },
    { lat: 48.610539, lng: 21.545933 },
    { lat: 48.610448, lng: 21.546306 },
    { lat: 48.610561, lng: 21.546764 },
    { lat: 48.61066, lng: 21.546873 },
    { lat: 48.611002, lng: 21.547222 },
    { lat: 48.61113, lng: 21.547406 },
    { lat: 48.611227, lng: 21.547552 },
    { lat: 48.611301, lng: 21.547661 },
    { lat: 48.611302, lng: 21.547661 },
    { lat: 48.61132, lng: 21.547706 },
    { lat: 48.61137, lng: 21.547822 },
    { lat: 48.611444, lng: 21.547894 },
    { lat: 48.611535, lng: 21.547983 },
    { lat: 48.611539, lng: 21.547986 },
    { lat: 48.611598, lng: 21.548043 },
    { lat: 48.611979, lng: 21.548415 },
    { lat: 48.612449, lng: 21.548851 },
    { lat: 48.6130532, lng: 21.5461177 },
    { lat: 48.613332, lng: 21.5445745 },
    { lat: 48.6132184, lng: 21.5444919 },
    { lat: 48.614045, lng: 21.5423866 },
    { lat: 48.6137699, lng: 21.5422479 },
    { lat: 48.6140958, lng: 21.541324 },
    { lat: 48.6144584, lng: 21.539558 },
    { lat: 48.6148559, lng: 21.5372009 },
    { lat: 48.6149919, lng: 21.5366793 },
    { lat: 48.6154285, lng: 21.5351478 },
    { lat: 48.6155181, lng: 21.5351708 },
    { lat: 48.6157917, lng: 21.5343913 },
    { lat: 48.6162772, lng: 21.5343662 },
    { lat: 48.6166467, lng: 21.5335563 },
    { lat: 48.616781, lng: 21.5330166 },
    { lat: 48.6170883, lng: 21.532152 },
    { lat: 48.617271, lng: 21.5314161 },
    { lat: 48.6169535, lng: 21.5308592 },
    { lat: 48.6168292, lng: 21.529936 },
    { lat: 48.6170041, lng: 21.52925 },
    { lat: 48.6171135, lng: 21.5285899 },
    { lat: 48.6176105, lng: 21.5273168 },
    { lat: 48.6179805, lng: 21.5265612 },
    { lat: 48.6180985, lng: 21.5261613 },
    { lat: 48.6181825, lng: 21.5257191 },
    { lat: 48.6181786, lng: 21.5249131 },
    { lat: 48.6182691, lng: 21.5245025 },
    { lat: 48.6184969, lng: 21.5241253 },
    { lat: 48.6190588, lng: 21.5236299 },
    { lat: 48.6191886, lng: 21.5234458 },
    { lat: 48.6197045, lng: 21.5225547 },
    { lat: 48.6202143, lng: 21.5214197 },
    { lat: 48.62134, lng: 21.5178299 },
    { lat: 48.6218847, lng: 21.5163821 },
    { lat: 48.6229351, lng: 21.5119733 },
    { lat: 48.6242582, lng: 21.5075055 },
    { lat: 48.6245309, lng: 21.5065867 },
  ],
  VyšnáKamenica: [
    { lat: 48.787868, lng: 21.540086 },
    { lat: 48.788579, lng: 21.540253 },
    { lat: 48.788995, lng: 21.540062 },
    { lat: 48.789874, lng: 21.53963 },
    { lat: 48.790841, lng: 21.538951 },
    { lat: 48.792046, lng: 21.539724 },
    { lat: 48.792724, lng: 21.540307 },
    { lat: 48.793033, lng: 21.540915 },
    { lat: 48.794077, lng: 21.541568 },
    { lat: 48.794341, lng: 21.54148 },
    { lat: 48.79456, lng: 21.541426 },
    { lat: 48.794757, lng: 21.541362 },
    { lat: 48.794894, lng: 21.541298 },
    { lat: 48.795097, lng: 21.541175 },
    { lat: 48.795299, lng: 21.541073 },
    { lat: 48.795463, lng: 21.540967 },
    { lat: 48.796806, lng: 21.540379 },
    { lat: 48.798066, lng: 21.539909 },
    { lat: 48.798613, lng: 21.539681 },
    { lat: 48.799062, lng: 21.539446 },
    { lat: 48.799242, lng: 21.53937 },
    { lat: 48.7996556, lng: 21.5350794 },
    { lat: 48.8001954, lng: 21.5302435 },
    { lat: 48.7998604, lng: 21.5301779 },
    { lat: 48.7999992, lng: 21.5292077 },
    { lat: 48.80028, lng: 21.5265025 },
    { lat: 48.8005917, lng: 21.5222031 },
    { lat: 48.8010062, lng: 21.5178459 },
    { lat: 48.8015984, lng: 21.5102703 },
    { lat: 48.8019435, lng: 21.5069211 },
    { lat: 48.8018579, lng: 21.5067219 },
    { lat: 48.8017016, lng: 21.5066497 },
    { lat: 48.8015896, lng: 21.5064682 },
    { lat: 48.8009476, lng: 21.5061335 },
    { lat: 48.8006205, lng: 21.5060323 },
    { lat: 48.8004864, lng: 21.5055892 },
    { lat: 48.8003391, lng: 21.5054761 },
    { lat: 48.8002303, lng: 21.5052909 },
    { lat: 48.8000984, lng: 21.5047317 },
    { lat: 48.8001256, lng: 21.5045443 },
    { lat: 48.8000632, lng: 21.5043329 },
    { lat: 48.7997543, lng: 21.5041929 },
    { lat: 48.7996547, lng: 21.5039018 },
    { lat: 48.7996997, lng: 21.5035487 },
    { lat: 48.7996766, lng: 21.5033686 },
    { lat: 48.799607, lng: 21.503259 },
    { lat: 48.7994977, lng: 21.5032126 },
    { lat: 48.7993191, lng: 21.5026744 },
    { lat: 48.7990523, lng: 21.502547 },
    { lat: 48.7989594, lng: 21.502294 },
    { lat: 48.79889, lng: 21.5022658 },
    { lat: 48.798807, lng: 21.5020781 },
    { lat: 48.7987174, lng: 21.5017421 },
    { lat: 48.7986248, lng: 21.5016929 },
    { lat: 48.7984035, lng: 21.5013934 },
    { lat: 48.7982346, lng: 21.5013906 },
    { lat: 48.7981595, lng: 21.5011239 },
    { lat: 48.7980541, lng: 21.5010977 },
    { lat: 48.7978655, lng: 21.5009181 },
    { lat: 48.7977956, lng: 21.5006771 },
    { lat: 48.7976705, lng: 21.5004755 },
    { lat: 48.7974909, lng: 21.500331 },
    { lat: 48.7974775, lng: 21.5001353 },
    { lat: 48.7973928, lng: 21.4999681 },
    { lat: 48.7974005, lng: 21.4997688 },
    { lat: 48.7972666, lng: 21.4995314 },
    { lat: 48.797387, lng: 21.4992986 },
    { lat: 48.7972254, lng: 21.4987632 },
    { lat: 48.7972197, lng: 21.4985669 },
    { lat: 48.7971167, lng: 21.49824 },
    { lat: 48.7970241, lng: 21.4981112 },
    { lat: 48.797018, lng: 21.4979321 },
    { lat: 48.7965307, lng: 21.4967522 },
    { lat: 48.7965252, lng: 21.4962918 },
    { lat: 48.7963101, lng: 21.4960494 },
    { lat: 48.7966467, lng: 21.4954323 },
    { lat: 48.7965793, lng: 21.4951216 },
    { lat: 48.7962274, lng: 21.4945045 },
    { lat: 48.7960635, lng: 21.4937027 },
    { lat: 48.7958314, lng: 21.4930999 },
    { lat: 48.7958471, lng: 21.4927116 },
    { lat: 48.7958858, lng: 21.4927223 },
    { lat: 48.795976, lng: 21.4923409 },
    { lat: 48.7955647, lng: 21.4905233 },
    { lat: 48.7953019, lng: 21.4899518 },
    { lat: 48.7952412, lng: 21.4890561 },
    { lat: 48.7953695, lng: 21.488477 },
    { lat: 48.7943832, lng: 21.4863442 },
    { lat: 48.7941562, lng: 21.4854252 },
    { lat: 48.7940783, lng: 21.484752 },
    { lat: 48.7931563, lng: 21.4813032 },
    { lat: 48.7928545, lng: 21.4807158 },
    { lat: 48.7926942, lng: 21.4801572 },
    { lat: 48.7923413, lng: 21.4797338 },
    { lat: 48.7918845, lng: 21.4788829 },
    { lat: 48.7906611, lng: 21.4779816 },
    { lat: 48.7854796, lng: 21.4747569 },
    { lat: 48.7852103, lng: 21.473654 },
    { lat: 48.7847037, lng: 21.4727578 },
    { lat: 48.7842262, lng: 21.4721215 },
    { lat: 48.7836062, lng: 21.4711059 },
    { lat: 48.7831217, lng: 21.4710208 },
    { lat: 48.7819573, lng: 21.4707408 },
    { lat: 48.7805946, lng: 21.4704318 },
    { lat: 48.7799595, lng: 21.4701835 },
    { lat: 48.779499, lng: 21.4699048 },
    { lat: 48.779264, lng: 21.4697142 },
    { lat: 48.7782264, lng: 21.4695955 },
    { lat: 48.7776077, lng: 21.4692959 },
    { lat: 48.7767453, lng: 21.4687969 },
    { lat: 48.7756397, lng: 21.4683564 },
    { lat: 48.7747505, lng: 21.468306 },
    { lat: 48.7738605, lng: 21.4679606 },
    { lat: 48.773499, lng: 21.466639 },
    { lat: 48.773015, lng: 21.4683386 },
    { lat: 48.7730121, lng: 21.4683512 },
    { lat: 48.7729621, lng: 21.4685297 },
    { lat: 48.7723076, lng: 21.4710674 },
    { lat: 48.7716388, lng: 21.474195 },
    { lat: 48.7707922, lng: 21.4812919 },
    { lat: 48.7706963, lng: 21.4827212 },
    { lat: 48.7705598, lng: 21.4830284 },
    { lat: 48.7705003, lng: 21.483103 },
    { lat: 48.7704668, lng: 21.483265 },
    { lat: 48.770139, lng: 21.4871502 },
    { lat: 48.7700446, lng: 21.4889856 },
    { lat: 48.7701643, lng: 21.4894717 },
    { lat: 48.7701398, lng: 21.4896346 },
    { lat: 48.7701894, lng: 21.4896388 },
    { lat: 48.7702416, lng: 21.4900003 },
    { lat: 48.7703287, lng: 21.4902182 },
    { lat: 48.770312, lng: 21.4905692 },
    { lat: 48.7704373, lng: 21.491015 },
    { lat: 48.7706004, lng: 21.4910531 },
    { lat: 48.770721, lng: 21.4912146 },
    { lat: 48.7708725, lng: 21.4918634 },
    { lat: 48.7710218, lng: 21.4918168 },
    { lat: 48.7712495, lng: 21.4920604 },
    { lat: 48.7711831, lng: 21.492612 },
    { lat: 48.7713486, lng: 21.4928472 },
    { lat: 48.7715925, lng: 21.4928336 },
    { lat: 48.7717491, lng: 21.492575 },
    { lat: 48.7718492, lng: 21.4925695 },
    { lat: 48.7721318, lng: 21.4930413 },
    { lat: 48.7721622, lng: 21.4934335 },
    { lat: 48.7724263, lng: 21.4938509 },
    { lat: 48.7725172, lng: 21.4938785 },
    { lat: 48.7726262, lng: 21.4941941 },
    { lat: 48.7728152, lng: 21.4944264 },
    { lat: 48.7728126, lng: 21.4945253 },
    { lat: 48.7728703, lng: 21.4946189 },
    { lat: 48.7729815, lng: 21.494567 },
    { lat: 48.7730908, lng: 21.4950902 },
    { lat: 48.7731552, lng: 21.4952126 },
    { lat: 48.7731758, lng: 21.4954941 },
    { lat: 48.7734002, lng: 21.4957851 },
    { lat: 48.7734543, lng: 21.496059 },
    { lat: 48.7735977, lng: 21.4962023 },
    { lat: 48.7737352, lng: 21.4966766 },
    { lat: 48.773727, lng: 21.4969418 },
    { lat: 48.7739738, lng: 21.4986361 },
    { lat: 48.7740907, lng: 21.4987054 },
    { lat: 48.7740006, lng: 21.4989043 },
    { lat: 48.7741383, lng: 21.4994207 },
    { lat: 48.7741413, lng: 21.4996881 },
    { lat: 48.774542, lng: 21.5005525 },
    { lat: 48.774698, lng: 21.5007709 },
    { lat: 48.7746615, lng: 21.5009128 },
    { lat: 48.77487, lng: 21.5013153 },
    { lat: 48.7749776, lng: 21.5017688 },
    { lat: 48.7751316, lng: 21.5018771 },
    { lat: 48.7751717, lng: 21.5021395 },
    { lat: 48.7752994, lng: 21.5022781 },
    { lat: 48.7754052, lng: 21.5025958 },
    { lat: 48.7753914, lng: 21.5026951 },
    { lat: 48.7757685, lng: 21.5034059 },
    { lat: 48.7758278, lng: 21.5036717 },
    { lat: 48.7759674, lng: 21.5039116 },
    { lat: 48.7761205, lng: 21.5040281 },
    { lat: 48.7761439, lng: 21.5042538 },
    { lat: 48.7763393, lng: 21.5045143 },
    { lat: 48.7763074, lng: 21.5048128 },
    { lat: 48.7764477, lng: 21.5052749 },
    { lat: 48.7764211, lng: 21.5054486 },
    { lat: 48.7764754, lng: 21.5055324 },
    { lat: 48.7765137, lng: 21.5060033 },
    { lat: 48.7765934, lng: 21.5062339 },
    { lat: 48.7766385, lng: 21.5063194 },
    { lat: 48.7767346, lng: 21.506332 },
    { lat: 48.7768987, lng: 21.5067179 },
    { lat: 48.7770227, lng: 21.5068078 },
    { lat: 48.7770959, lng: 21.5070841 },
    { lat: 48.777412, lng: 21.5075878 },
    { lat: 48.7774795, lng: 21.5079786 },
    { lat: 48.7777023, lng: 21.5082583 },
    { lat: 48.777668, lng: 21.5085089 },
    { lat: 48.7777792, lng: 21.5087435 },
    { lat: 48.7778382, lng: 21.5090746 },
    { lat: 48.7778046, lng: 21.5096622 },
    { lat: 48.7778562, lng: 21.5098918 },
    { lat: 48.7783453, lng: 21.5105104 },
    { lat: 48.7784381, lng: 21.5110423 },
    { lat: 48.778649, lng: 21.5113681 },
    { lat: 48.778689, lng: 21.511627 },
    { lat: 48.7790307, lng: 21.5120498 },
    { lat: 48.7790413, lng: 21.5121808 },
    { lat: 48.7793315, lng: 21.5125324 },
    { lat: 48.7793715, lng: 21.5127856 },
    { lat: 48.7796063, lng: 21.5132868 },
    { lat: 48.7796318, lng: 21.5135092 },
    { lat: 48.7797102, lng: 21.5135793 },
    { lat: 48.7797751, lng: 21.5140742 },
    { lat: 48.7799033, lng: 21.5141136 },
    { lat: 48.7800174, lng: 21.5142452 },
    { lat: 48.7802925, lng: 21.5146971 },
    { lat: 48.7803753, lng: 21.5149942 },
    { lat: 48.7806055, lng: 21.5151792 },
    { lat: 48.7808645, lng: 21.5154962 },
    { lat: 48.7810644, lng: 21.5159116 },
    { lat: 48.7814016, lng: 21.5163344 },
    { lat: 48.7815772, lng: 21.5166547 },
    { lat: 48.7817301, lng: 21.5167246 },
    { lat: 48.781889, lng: 21.5170546 },
    { lat: 48.7820833, lng: 21.517308 },
    { lat: 48.7823497, lng: 21.519072 },
    { lat: 48.7823133, lng: 21.5191768 },
    { lat: 48.7823558, lng: 21.5193998 },
    { lat: 48.7824531, lng: 21.5196222 },
    { lat: 48.782584, lng: 21.5202651 },
    { lat: 48.7828067, lng: 21.5208488 },
    { lat: 48.7828378, lng: 21.5211108 },
    { lat: 48.7833617, lng: 21.5219282 },
    { lat: 48.7834077, lng: 21.5221189 },
    { lat: 48.7836384, lng: 21.5224703 },
    { lat: 48.783827, lng: 21.522889 },
    { lat: 48.7840274, lng: 21.5238802 },
    { lat: 48.7841472, lng: 21.5240958 },
    { lat: 48.7841349, lng: 21.524417 },
    { lat: 48.7842603, lng: 21.5253821 },
    { lat: 48.784382, lng: 21.5256657 },
    { lat: 48.7844188, lng: 21.5262787 },
    { lat: 48.7843625, lng: 21.5265532 },
    { lat: 48.7843935, lng: 21.5271278 },
    { lat: 48.7845391, lng: 21.5276588 },
    { lat: 48.7845743, lng: 21.527943 },
    { lat: 48.7845489, lng: 21.5282034 },
    { lat: 48.7846191, lng: 21.5286897 },
    { lat: 48.7847293, lng: 21.5289237 },
    { lat: 48.7847423, lng: 21.529619 },
    { lat: 48.7848456, lng: 21.5298845 },
    { lat: 48.7848582, lng: 21.5302127 },
    { lat: 48.7847924, lng: 21.530866 },
    { lat: 48.7848526, lng: 21.5310836 },
    { lat: 48.784743, lng: 21.5319371 },
    { lat: 48.7846492, lng: 21.5321958 },
    { lat: 48.7845912, lng: 21.5331223 },
    { lat: 48.7846278, lng: 21.533497 },
    { lat: 48.7849605, lng: 21.5340762 },
    { lat: 48.7853009, lng: 21.5344147 },
    { lat: 48.7854653, lng: 21.5348975 },
    { lat: 48.7854658, lng: 21.535387 },
    { lat: 48.7857587, lng: 21.5359945 },
    { lat: 48.7858828, lng: 21.5361634 },
    { lat: 48.7860212, lng: 21.5361226 },
    { lat: 48.7861445, lng: 21.5363033 },
    { lat: 48.7862908, lng: 21.5366659 },
    { lat: 48.7865522, lng: 21.5369515 },
    { lat: 48.7868035, lng: 21.5377137 },
    { lat: 48.7869851, lng: 21.5378164 },
    { lat: 48.787868, lng: 21.540086 },
  ],
  Chrastné: [
    { lat: 48.796128, lng: 21.343467 },
    { lat: 48.7952114, lng: 21.3437783 },
    { lat: 48.7946929, lng: 21.3442038 },
    { lat: 48.7942615, lng: 21.344373 },
    { lat: 48.7938913, lng: 21.344326 },
    { lat: 48.7936461, lng: 21.3444897 },
    { lat: 48.7931762, lng: 21.3447659 },
    { lat: 48.7930283, lng: 21.3443749 },
    { lat: 48.7920235, lng: 21.3450937 },
    { lat: 48.7906549, lng: 21.3457519 },
    { lat: 48.7900066, lng: 21.3461653 },
    { lat: 48.7896877, lng: 21.3456616 },
    { lat: 48.7890205, lng: 21.3466368 },
    { lat: 48.7883272, lng: 21.3463709 },
    { lat: 48.7881925, lng: 21.3462332 },
    { lat: 48.7880162, lng: 21.3456883 },
    { lat: 48.7878583, lng: 21.3457866 },
    { lat: 48.7875091, lng: 21.3457717 },
    { lat: 48.7874042, lng: 21.3456533 },
    { lat: 48.7873758, lng: 21.3455315 },
    { lat: 48.7869952, lng: 21.3451084 },
    { lat: 48.7867305, lng: 21.3450674 },
    { lat: 48.786591, lng: 21.3446938 },
    { lat: 48.7866254, lng: 21.3444502 },
    { lat: 48.7867491, lng: 21.344296 },
    { lat: 48.7867984, lng: 21.3440321 },
    { lat: 48.7867178, lng: 21.3438611 },
    { lat: 48.7865697, lng: 21.3437592 },
    { lat: 48.7864684, lng: 21.3435357 },
    { lat: 48.7864549, lng: 21.3434228 },
    { lat: 48.7863927, lng: 21.3434474 },
    { lat: 48.7862362, lng: 21.3444923 },
    { lat: 48.7861661, lng: 21.3447091 },
    { lat: 48.7861154, lng: 21.3453842 },
    { lat: 48.7860234, lng: 21.3457282 },
    { lat: 48.785522, lng: 21.3464543 },
    { lat: 48.7851785, lng: 21.3465878 },
    { lat: 48.7850572, lng: 21.3465616 },
    { lat: 48.7847034, lng: 21.3466616 },
    { lat: 48.7846899, lng: 21.3470765 },
    { lat: 48.7845562, lng: 21.3473009 },
    { lat: 48.7841387, lng: 21.3474549 },
    { lat: 48.7837541, lng: 21.3476837 },
    { lat: 48.7835536, lng: 21.3479787 },
    { lat: 48.7834202, lng: 21.3484817 },
    { lat: 48.7839204, lng: 21.3496602 },
    { lat: 48.7844224, lng: 21.3514658 },
    { lat: 48.7849757, lng: 21.3526471 },
    { lat: 48.7849552, lng: 21.3530549 },
    { lat: 48.7857531, lng: 21.3543354 },
    { lat: 48.7860727, lng: 21.355233 },
    { lat: 48.7861078, lng: 21.3555364 },
    { lat: 48.7863764, lng: 21.3557107 },
    { lat: 48.7862048, lng: 21.3562253 },
    { lat: 48.7857555, lng: 21.3570783 },
    { lat: 48.7855758, lng: 21.3576527 },
    { lat: 48.7854802, lng: 21.3585132 },
    { lat: 48.7856723, lng: 21.3585828 },
    { lat: 48.7856722, lng: 21.3587976 },
    { lat: 48.7854908, lng: 21.3595833 },
    { lat: 48.7852437, lng: 21.3601209 },
    { lat: 48.7850509, lng: 21.3607822 },
    { lat: 48.7850233, lng: 21.360955 },
    { lat: 48.7852099, lng: 21.3610614 },
    { lat: 48.785169, lng: 21.3615388 },
    { lat: 48.7846359, lng: 21.3652647 },
    { lat: 48.785018, lng: 21.3653641 },
    { lat: 48.7851235, lng: 21.3651613 },
    { lat: 48.7853498, lng: 21.3653164 },
    { lat: 48.7850225, lng: 21.366075 },
    { lat: 48.7855293, lng: 21.3671793 },
    { lat: 48.78586, lng: 21.3672526 },
    { lat: 48.78627, lng: 21.3675272 },
    { lat: 48.787181, lng: 21.3679272 },
    { lat: 48.7870634, lng: 21.368173 },
    { lat: 48.787242, lng: 21.3683495 },
    { lat: 48.7875692, lng: 21.3677786 },
    { lat: 48.7875558, lng: 21.3676707 },
    { lat: 48.7880428, lng: 21.3665279 },
    { lat: 48.7882078, lng: 21.366928 },
    { lat: 48.7886147, lng: 21.3674758 },
    { lat: 48.7890674, lng: 21.3678687 },
    { lat: 48.7893205, lng: 21.3683931 },
    { lat: 48.7894153, lng: 21.3683638 },
    { lat: 48.7895234, lng: 21.3685562 },
    { lat: 48.7897663, lng: 21.368697 },
    { lat: 48.7897801, lng: 21.3687803 },
    { lat: 48.7899934, lng: 21.3686922 },
    { lat: 48.790522, lng: 21.3690578 },
    { lat: 48.7909843, lng: 21.3698834 },
    { lat: 48.7910382, lng: 21.3698405 },
    { lat: 48.7912, lng: 21.3713754 },
    { lat: 48.7913419, lng: 21.3718303 },
    { lat: 48.7910726, lng: 21.3734023 },
    { lat: 48.7910142, lng: 21.3737149 },
    { lat: 48.7911408, lng: 21.373808 },
    { lat: 48.7922494, lng: 21.3738111 },
    { lat: 48.793231, lng: 21.3735955 },
    { lat: 48.7936557, lng: 21.3734234 },
    { lat: 48.7937745, lng: 21.3734463 },
    { lat: 48.7948927, lng: 21.374039 },
    { lat: 48.7961106, lng: 21.3749166 },
    { lat: 48.7967906, lng: 21.3755704 },
    { lat: 48.7973871, lng: 21.3755777 },
    { lat: 48.7980169, lng: 21.3754074 },
    { lat: 48.798853, lng: 21.3750721 },
    { lat: 48.79909, lng: 21.3750917 },
    { lat: 48.7995881, lng: 21.3749691 },
    { lat: 48.800426, lng: 21.3745385 },
    { lat: 48.8009512, lng: 21.3740407 },
    { lat: 48.8013395, lng: 21.3738899 },
    { lat: 48.8020433, lng: 21.3733799 },
    { lat: 48.8025792, lng: 21.373087 },
    { lat: 48.8032782, lng: 21.3723388 },
    { lat: 48.803548, lng: 21.3719116 },
    { lat: 48.8043627, lng: 21.3708621 },
    { lat: 48.8047253, lng: 21.3704885 },
    { lat: 48.8054027, lng: 21.369927 },
    { lat: 48.8061971, lng: 21.3689091 },
    { lat: 48.8064051, lng: 21.3687121 },
    { lat: 48.8062545, lng: 21.3681152 },
    { lat: 48.8065707, lng: 21.3675141 },
    { lat: 48.806828, lng: 21.3663323 },
    { lat: 48.8079341, lng: 21.3646047 },
    { lat: 48.8082726, lng: 21.3630153 },
    { lat: 48.8084193, lng: 21.362167 },
    { lat: 48.8085207, lng: 21.3611508 },
    { lat: 48.8086508, lng: 21.3605735 },
    { lat: 48.8088676, lng: 21.3607482 },
    { lat: 48.809291, lng: 21.3604221 },
    { lat: 48.8088731, lng: 21.3595472 },
    { lat: 48.8084477, lng: 21.3597688 },
    { lat: 48.8081685, lng: 21.3593486 },
    { lat: 48.8074263, lng: 21.3568516 },
    { lat: 48.8071506, lng: 21.356998 },
    { lat: 48.8069988, lng: 21.3565147 },
    { lat: 48.8074614, lng: 21.3562198 },
    { lat: 48.8071595, lng: 21.354371 },
    { lat: 48.8070052, lng: 21.3523795 },
    { lat: 48.8067917, lng: 21.352501 },
    { lat: 48.806405, lng: 21.3511292 },
    { lat: 48.8067391, lng: 21.3509692 },
    { lat: 48.806364, lng: 21.3499825 },
    { lat: 48.8058414, lng: 21.3501938 },
    { lat: 48.8052984, lng: 21.3480432 },
    { lat: 48.8048685, lng: 21.3483024 },
    { lat: 48.8047422, lng: 21.3483537 },
    { lat: 48.8045363, lng: 21.3480293 },
    { lat: 48.8045059, lng: 21.3479204 },
    { lat: 48.8044187, lng: 21.347976 },
    { lat: 48.8042687, lng: 21.3475508 },
    { lat: 48.8038728, lng: 21.3478194 },
    { lat: 48.8038854, lng: 21.3470079 },
    { lat: 48.8033957, lng: 21.3471102 },
    { lat: 48.8030225, lng: 21.3473108 },
    { lat: 48.8026429, lng: 21.3466608 },
    { lat: 48.8025269, lng: 21.3467309 },
    { lat: 48.8024471, lng: 21.3465162 },
    { lat: 48.80148, lng: 21.3466068 },
    { lat: 48.8002576, lng: 21.347008 },
    { lat: 48.8001771, lng: 21.3467294 },
    { lat: 48.7998162, lng: 21.3468917 },
    { lat: 48.7991911, lng: 21.34397 },
    { lat: 48.7986856, lng: 21.3443525 },
    { lat: 48.7983986, lng: 21.3444237 },
    { lat: 48.797569, lng: 21.3439676 },
    { lat: 48.7964702, lng: 21.3435783 },
    { lat: 48.796128, lng: 21.343467 },
  ],
  Buzica: [
    { lat: 48.4911119, lng: 21.1175446 },
    { lat: 48.491461, lng: 21.118386 },
    { lat: 48.491685, lng: 21.119153 },
    { lat: 48.491882, lng: 21.119977 },
    { lat: 48.491983, lng: 21.120872 },
    { lat: 48.491975, lng: 21.121827 },
    { lat: 48.491978, lng: 21.122475 },
    { lat: 48.491982, lng: 21.12347 },
    { lat: 48.491985, lng: 21.124432 },
    { lat: 48.492006, lng: 21.124781 },
    { lat: 48.492032, lng: 21.125209 },
    { lat: 48.492241, lng: 21.126578 },
    { lat: 48.492318, lng: 21.127561 },
    { lat: 48.492399, lng: 21.128311 },
    { lat: 48.492922, lng: 21.129258 },
    { lat: 48.493271, lng: 21.129889 },
    { lat: 48.493305, lng: 21.129951 },
    { lat: 48.493362, lng: 21.130053 },
    { lat: 48.493664, lng: 21.130618 },
    { lat: 48.494094, lng: 21.131587 },
    { lat: 48.494667, lng: 21.132694 },
    { lat: 48.495422, lng: 21.133938 },
    { lat: 48.496026, lng: 21.134287 },
    { lat: 48.49687, lng: 21.134598 },
    { lat: 48.497238, lng: 21.134705 },
    { lat: 48.497287, lng: 21.134718 },
    { lat: 48.497372, lng: 21.134743 },
    { lat: 48.497427, lng: 21.134758 },
    { lat: 48.497581, lng: 21.134803 },
    { lat: 48.49806, lng: 21.134938 },
    { lat: 48.498996, lng: 21.135064 },
    { lat: 48.499008, lng: 21.135017 },
    { lat: 48.499257, lng: 21.135293 },
    { lat: 48.499691, lng: 21.135665 },
    { lat: 48.499871, lng: 21.135881 },
    { lat: 48.500037, lng: 21.136043 },
    { lat: 48.500277, lng: 21.136233 },
    { lat: 48.500593, lng: 21.136423 },
    { lat: 48.500674, lng: 21.136476 },
    { lat: 48.501095, lng: 21.136768 },
    { lat: 48.501513, lng: 21.137058 },
    { lat: 48.501506, lng: 21.137084 },
    { lat: 48.502564, lng: 21.138104 },
    { lat: 48.502963, lng: 21.138418 },
    { lat: 48.5049227, lng: 21.1347916 },
    { lat: 48.5081076, lng: 21.129802 },
    { lat: 48.5084587, lng: 21.128926 },
    { lat: 48.5081944, lng: 21.1281924 },
    { lat: 48.5086141, lng: 21.1279489 },
    { lat: 48.5091569, lng: 21.1273929 },
    { lat: 48.5127556, lng: 21.1233179 },
    { lat: 48.5126586, lng: 21.1214897 },
    { lat: 48.5124937, lng: 21.1212245 },
    { lat: 48.5124342, lng: 21.1204259 },
    { lat: 48.5125405, lng: 21.1203074 },
    { lat: 48.5125545, lng: 21.1202127 },
    { lat: 48.5127668, lng: 21.1200229 },
    { lat: 48.5130049, lng: 21.1194858 },
    { lat: 48.5131824, lng: 21.1194015 },
    { lat: 48.5132529, lng: 21.1192561 },
    { lat: 48.5132413, lng: 21.1190706 },
    { lat: 48.5135374, lng: 21.1182971 },
    { lat: 48.5136007, lng: 21.1177888 },
    { lat: 48.5138002, lng: 21.1174061 },
    { lat: 48.5139293, lng: 21.1167726 },
    { lat: 48.5140166, lng: 21.1159927 },
    { lat: 48.5140259, lng: 21.1151982 },
    { lat: 48.5140774, lng: 21.1149729 },
    { lat: 48.5140227, lng: 21.1148495 },
    { lat: 48.5140235, lng: 21.1148107 },
    { lat: 48.5140274, lng: 21.114682 },
    { lat: 48.5141483, lng: 21.1145893 },
    { lat: 48.5140958, lng: 21.1143223 },
    { lat: 48.5139939, lng: 21.1141707 },
    { lat: 48.5140129, lng: 21.1140561 },
    { lat: 48.5139751, lng: 21.1139378 },
    { lat: 48.5140099, lng: 21.1137595 },
    { lat: 48.5131344, lng: 21.1135555 },
    { lat: 48.5131569, lng: 21.1131332 },
    { lat: 48.5133414, lng: 21.1124708 },
    { lat: 48.5135536, lng: 21.1121756 },
    { lat: 48.5136066, lng: 21.1121745 },
    { lat: 48.5137916, lng: 21.1119132 },
    { lat: 48.5139568, lng: 21.1118656 },
    { lat: 48.5145783, lng: 21.111976 },
    { lat: 48.5146043, lng: 21.111874 },
    { lat: 48.5150022, lng: 21.1114065 },
    { lat: 48.5152129, lng: 21.1108905 },
    { lat: 48.5155057, lng: 21.1104779 },
    { lat: 48.5159371, lng: 21.1101424 },
    { lat: 48.5166125, lng: 21.1094469 },
    { lat: 48.5170621, lng: 21.1091108 },
    { lat: 48.5174631, lng: 21.1085304 },
    { lat: 48.5178534, lng: 21.1091132 },
    { lat: 48.518651, lng: 21.1083363 },
    { lat: 48.5189514, lng: 21.1078808 },
    { lat: 48.519522, lng: 21.1073199 },
    { lat: 48.5197383, lng: 21.1073201 },
    { lat: 48.5197851, lng: 21.1070976 },
    { lat: 48.5197335, lng: 21.1070357 },
    { lat: 48.5197718, lng: 21.1067592 },
    { lat: 48.5200812, lng: 21.1063729 },
    { lat: 48.520064, lng: 21.1056645 },
    { lat: 48.5201624, lng: 21.1053303 },
    { lat: 48.5203161, lng: 21.1052484 },
    { lat: 48.5205001, lng: 21.104987 },
    { lat: 48.5206912, lng: 21.1050455 },
    { lat: 48.5207334, lng: 21.105134 },
    { lat: 48.5210234, lng: 21.1051585 },
    { lat: 48.5211902, lng: 21.1049187 },
    { lat: 48.5212619, lng: 21.1049131 },
    { lat: 48.5214816, lng: 21.1050905 },
    { lat: 48.5217029, lng: 21.1051812 },
    { lat: 48.5223467, lng: 21.1051837 },
    { lat: 48.5224729, lng: 21.1048343 },
    { lat: 48.5228108, lng: 21.1044232 },
    { lat: 48.522806, lng: 21.1043497 },
    { lat: 48.5232229, lng: 21.1037032 },
    { lat: 48.5235523, lng: 21.1038626 },
    { lat: 48.5248202, lng: 21.103846 },
    { lat: 48.5264979, lng: 21.1035199 },
    { lat: 48.5265326, lng: 21.1035777 },
    { lat: 48.5268343, lng: 21.1036166 },
    { lat: 48.5269818, lng: 21.1034421 },
    { lat: 48.5275955, lng: 21.1034855 },
    { lat: 48.5276786, lng: 21.1035558 },
    { lat: 48.5279081, lng: 21.1040006 },
    { lat: 48.527991, lng: 21.1043034 },
    { lat: 48.5284999, lng: 21.1043066 },
    { lat: 48.528652, lng: 21.1044901 },
    { lat: 48.5289802, lng: 21.1050988 },
    { lat: 48.5290467, lng: 21.1051326 },
    { lat: 48.5297107, lng: 21.1051351 },
    { lat: 48.5297902, lng: 21.1052227 },
    { lat: 48.5298265, lng: 21.1054064 },
    { lat: 48.530058, lng: 21.1052194 },
    { lat: 48.5302939, lng: 21.1052433 },
    { lat: 48.5304013, lng: 21.1050124 },
    { lat: 48.5305687, lng: 21.1051042 },
    { lat: 48.5306225, lng: 21.105347 },
    { lat: 48.5307193, lng: 21.1053133 },
    { lat: 48.5308533, lng: 21.1050666 },
    { lat: 48.5309084, lng: 21.1050718 },
    { lat: 48.5311215, lng: 21.1053215 },
    { lat: 48.5315576, lng: 21.1063137 },
    { lat: 48.5318344, lng: 21.1064514 },
    { lat: 48.5321765, lng: 21.1065208 },
    { lat: 48.5325595, lng: 21.1069789 },
    { lat: 48.5325342, lng: 21.1072109 },
    { lat: 48.5326589, lng: 21.1074134 },
    { lat: 48.532738, lng: 21.1076647 },
    { lat: 48.5329504, lng: 21.1078935 },
    { lat: 48.5330109, lng: 21.1080648 },
    { lat: 48.533269, lng: 21.1081393 },
    { lat: 48.533455, lng: 21.1080124 },
    { lat: 48.5343255, lng: 21.1079245 },
    { lat: 48.5353159, lng: 21.1071879 },
    { lat: 48.5357475, lng: 21.1069609 },
    { lat: 48.5360276, lng: 21.1068867 },
    { lat: 48.5362375, lng: 21.1064171 },
    { lat: 48.5367707, lng: 21.1060946 },
    { lat: 48.5368953, lng: 21.1061178 },
    { lat: 48.5369639, lng: 21.105769 },
    { lat: 48.5371027, lng: 21.1055294 },
    { lat: 48.5370836, lng: 21.1052886 },
    { lat: 48.5371167, lng: 21.1052322 },
    { lat: 48.5373238, lng: 21.1053736 },
    { lat: 48.537488, lng: 21.1054039 },
    { lat: 48.5376123, lng: 21.1053166 },
    { lat: 48.5377248, lng: 21.1050029 },
    { lat: 48.5378168, lng: 21.1049596 },
    { lat: 48.5390671, lng: 21.1047411 },
    { lat: 48.5397532, lng: 21.1046972 },
    { lat: 48.5399626, lng: 21.1045934 },
    { lat: 48.5403823, lng: 21.1042398 },
    { lat: 48.5421869, lng: 21.1038692 },
    { lat: 48.5427256, lng: 21.1036798 },
    { lat: 48.5430006, lng: 21.1035175 },
    { lat: 48.5434448, lng: 21.1035716 },
    { lat: 48.5443896, lng: 21.1033446 },
    { lat: 48.5445847, lng: 21.1031772 },
    { lat: 48.5453224, lng: 21.1022201 },
    { lat: 48.5451662, lng: 21.1009432 },
    { lat: 48.5451526, lng: 21.1000704 },
    { lat: 48.5451862, lng: 21.100069 },
    { lat: 48.5453189, lng: 21.0976981 },
    { lat: 48.5453377, lng: 21.0964002 },
    { lat: 48.5455911, lng: 21.0951557 },
    { lat: 48.5457847, lng: 21.0954331 },
    { lat: 48.5463882, lng: 21.0958903 },
    { lat: 48.5464284, lng: 21.0959126 },
    { lat: 48.5469271, lng: 21.0922732 },
    { lat: 48.547214, lng: 21.0906738 },
    { lat: 48.5473505, lng: 21.090856 },
    { lat: 48.5474906, lng: 21.090943 },
    { lat: 48.54764, lng: 21.0909661 },
    { lat: 48.5476626, lng: 21.0909005 },
    { lat: 48.548303, lng: 21.0891642 },
    { lat: 48.5484764, lng: 21.0889276 },
    { lat: 48.5488347, lng: 21.0887613 },
    { lat: 48.5495106, lng: 21.0886053 },
    { lat: 48.5498197, lng: 21.0882827 },
    { lat: 48.5502202, lng: 21.0881022 },
    { lat: 48.5505973, lng: 21.0876552 },
    { lat: 48.5512403, lng: 21.0876658 },
    { lat: 48.551568, lng: 21.087735 },
    { lat: 48.5526764, lng: 21.088252 },
    { lat: 48.5529829, lng: 21.0865636 },
    { lat: 48.5529744, lng: 21.086405 },
    { lat: 48.5528921, lng: 21.086221 },
    { lat: 48.5531991, lng: 21.0857977 },
    { lat: 48.5531666, lng: 21.085632 },
    { lat: 48.5527925, lng: 21.0850663 },
    { lat: 48.5527794, lng: 21.0848722 },
    { lat: 48.5528068, lng: 21.0847792 },
    { lat: 48.5531343, lng: 21.0844659 },
    { lat: 48.5532475, lng: 21.0840717 },
    { lat: 48.5532609, lng: 21.0835122 },
    { lat: 48.5536202, lng: 21.083139 },
    { lat: 48.5536054, lng: 21.0829571 },
    { lat: 48.5536922, lng: 21.0821009 },
    { lat: 48.5546658, lng: 21.0812666 },
    { lat: 48.5549298, lng: 21.0807678 },
    { lat: 48.5549176, lng: 21.0799455 },
    { lat: 48.5554386, lng: 21.0794885 },
    { lat: 48.5555842, lng: 21.0790553 },
    { lat: 48.5555512, lng: 21.0779308 },
    { lat: 48.5557374, lng: 21.0776023 },
    { lat: 48.5558769, lng: 21.0771496 },
    { lat: 48.5561299, lng: 21.0766246 },
    { lat: 48.5564471, lng: 21.0765873 },
    { lat: 48.5565441, lng: 21.0764878 },
    { lat: 48.5565212, lng: 21.0762713 },
    { lat: 48.5565749, lng: 21.0759778 },
    { lat: 48.5564784, lng: 21.0754715 },
    { lat: 48.5565178, lng: 21.0752323 },
    { lat: 48.5563983, lng: 21.0749157 },
    { lat: 48.5563664, lng: 21.0746956 },
    { lat: 48.5565102, lng: 21.0745486 },
    { lat: 48.5565551, lng: 21.0744067 },
    { lat: 48.5565292, lng: 21.0742334 },
    { lat: 48.5573717, lng: 21.0733877 },
    { lat: 48.557692, lng: 21.0731629 },
    { lat: 48.5582766, lng: 21.073109 },
    { lat: 48.5601659, lng: 21.0711571 },
    { lat: 48.5608576, lng: 21.0700701 },
    { lat: 48.5617396, lng: 21.0689548 },
    { lat: 48.5623299, lng: 21.0678667 },
    { lat: 48.5620934, lng: 21.0672777 },
    { lat: 48.5613256, lng: 21.065549 },
    { lat: 48.5605911, lng: 21.063286 },
    { lat: 48.559892, lng: 21.0621823 },
    { lat: 48.5597283, lng: 21.0609632 },
    { lat: 48.5593953, lng: 21.0597319 },
    { lat: 48.5588476, lng: 21.0584319 },
    { lat: 48.5586159, lng: 21.057715 },
    { lat: 48.5579206, lng: 21.0561098 },
    { lat: 48.5569843, lng: 21.054189 },
    { lat: 48.5567112, lng: 21.0539954 },
    { lat: 48.5562269, lng: 21.0540239 },
    { lat: 48.5562275, lng: 21.0540424 },
    { lat: 48.5551849, lng: 21.0535841 },
    { lat: 48.5549744, lng: 21.0535543 },
    { lat: 48.554679, lng: 21.0529893 },
    { lat: 48.5540555, lng: 21.0519925 },
    { lat: 48.5531289, lng: 21.0520518 },
    { lat: 48.5507364, lng: 21.0524809 },
    { lat: 48.5501193, lng: 21.0525062 },
    { lat: 48.5498209, lng: 21.052808 },
    { lat: 48.5495979, lng: 21.0531497 },
    { lat: 48.5489888, lng: 21.0542792 },
    { lat: 48.5482395, lng: 21.0554599 },
    { lat: 48.548335, lng: 21.0563456 },
    { lat: 48.5480489, lng: 21.056819 },
    { lat: 48.5480514, lng: 21.0571045 },
    { lat: 48.5482173, lng: 21.0573607 },
    { lat: 48.5482045, lng: 21.0575887 },
    { lat: 48.5480563, lng: 21.0577677 },
    { lat: 48.5475965, lng: 21.0579219 },
    { lat: 48.5475896, lng: 21.0580588 },
    { lat: 48.5463108, lng: 21.0581232 },
    { lat: 48.5457755, lng: 21.0583434 },
    { lat: 48.5454825, lng: 21.0584998 },
    { lat: 48.5453485, lng: 21.0587083 },
    { lat: 48.5450275, lng: 21.0589762 },
    { lat: 48.5440782, lng: 21.0596551 },
    { lat: 48.5426471, lng: 21.0599102 },
    { lat: 48.5413373, lng: 21.0599232 },
    { lat: 48.5385736, lng: 21.0597393 },
    { lat: 48.5381911, lng: 21.0595425 },
    { lat: 48.5378835, lng: 21.0591502 },
    { lat: 48.5373456, lng: 21.0582972 },
    { lat: 48.5362388, lng: 21.0579766 },
    { lat: 48.5357924, lng: 21.0577476 },
    { lat: 48.535424, lng: 21.0577714 },
    { lat: 48.5352306, lng: 21.0579143 },
    { lat: 48.5349589, lng: 21.0579381 },
    { lat: 48.5345905, lng: 21.0577857 },
    { lat: 48.5345389, lng: 21.0578964 },
    { lat: 48.5339952, lng: 21.0580689 },
    { lat: 48.5337627, lng: 21.0580837 },
    { lat: 48.5332008, lng: 21.0584553 },
    { lat: 48.5322283, lng: 21.0589682 },
    { lat: 48.530326, lng: 21.0582084 },
    { lat: 48.5301395, lng: 21.058218 },
    { lat: 48.5299744, lng: 21.0583702 },
    { lat: 48.5296269, lng: 21.0584826 },
    { lat: 48.5295112, lng: 21.0584869 },
    { lat: 48.5293151, lng: 21.0583754 },
    { lat: 48.5290661, lng: 21.0583701 },
    { lat: 48.5289441, lng: 21.0582883 },
    { lat: 48.5279217, lng: 21.0584511 },
    { lat: 48.5276449, lng: 21.0585537 },
    { lat: 48.5271246, lng: 21.0571709 },
    { lat: 48.5269655, lng: 21.0562683 },
    { lat: 48.526156, lng: 21.053209 },
    { lat: 48.525723, lng: 21.053081 },
    { lat: 48.525405, lng: 21.052852 },
    { lat: 48.525204, lng: 21.052743 },
    { lat: 48.52513, lng: 21.05276 },
    { lat: 48.524649, lng: 21.052854 },
    { lat: 48.523645, lng: 21.053054 },
    { lat: 48.5222175, lng: 21.0533376 },
    { lat: 48.52214, lng: 21.053353 },
    { lat: 48.522152, lng: 21.053396 },
    { lat: 48.522857, lng: 21.055829 },
    { lat: 48.52303, lng: 21.056421 },
    { lat: 48.523154, lng: 21.056854 },
    { lat: 48.524091, lng: 21.060082 },
    { lat: 48.524644, lng: 21.062001 },
    { lat: 48.525102, lng: 21.063585 },
    { lat: 48.525642, lng: 21.065452 },
    { lat: 48.525894, lng: 21.066321 },
    { lat: 48.525877, lng: 21.066329 },
    { lat: 48.524066, lng: 21.067838 },
    { lat: 48.52361, lng: 21.068221 },
    { lat: 48.522724, lng: 21.068965 },
    { lat: 48.522726, lng: 21.068997 },
    { lat: 48.522768, lng: 21.069132 },
    { lat: 48.522801, lng: 21.069201 },
    { lat: 48.523061, lng: 21.069618 },
    { lat: 48.52315, lng: 21.069784 },
    { lat: 48.52324, lng: 21.069888 },
    { lat: 48.523275, lng: 21.069914 },
    { lat: 48.523365, lng: 21.069983 },
    { lat: 48.523342, lng: 21.070037 },
    { lat: 48.5233192, lng: 21.0700955 },
    { lat: 48.523278, lng: 21.070192 },
    { lat: 48.523106, lng: 21.070466 },
    { lat: 48.522954, lng: 21.070695 },
    { lat: 48.522945, lng: 21.070768 },
    { lat: 48.522836, lng: 21.071006 },
    { lat: 48.522685, lng: 21.07132 },
    { lat: 48.522578, lng: 21.071545 },
    { lat: 48.522439, lng: 21.071794 },
    { lat: 48.522309, lng: 21.071982 },
    { lat: 48.522191, lng: 21.072086 },
    { lat: 48.521718, lng: 21.07266 },
    { lat: 48.521693, lng: 21.072689 },
    { lat: 48.52145, lng: 21.07303 },
    { lat: 48.521322, lng: 21.073178 },
    { lat: 48.521232, lng: 21.073302 },
    { lat: 48.520963, lng: 21.073772 },
    { lat: 48.520844, lng: 21.074068 },
    { lat: 48.520613, lng: 21.074704 },
    { lat: 48.520565, lng: 21.074855 },
    { lat: 48.520541, lng: 21.074986 },
    { lat: 48.520498, lng: 21.075248 },
    { lat: 48.520408, lng: 21.075675 },
    { lat: 48.520282, lng: 21.076207 },
    { lat: 48.520135, lng: 21.07676 },
    { lat: 48.520079, lng: 21.076962 },
    { lat: 48.519957, lng: 21.0774 },
    { lat: 48.519788, lng: 21.07795 },
    { lat: 48.519657, lng: 21.078391 },
    { lat: 48.519564, lng: 21.0787 },
    { lat: 48.519497, lng: 21.07893 },
    { lat: 48.519437, lng: 21.079153 },
    { lat: 48.519356, lng: 21.07947 },
    { lat: 48.51932, lng: 21.079607 },
    { lat: 48.519276, lng: 21.079766 },
    { lat: 48.518983, lng: 21.080804 },
    { lat: 48.518724, lng: 21.081696 },
    { lat: 48.518645, lng: 21.08205 },
    { lat: 48.518606, lng: 21.08228 },
    { lat: 48.518589, lng: 21.082562 },
    { lat: 48.51858, lng: 21.082843 },
    { lat: 48.518586, lng: 21.083113 },
    { lat: 48.518613, lng: 21.083827 },
    { lat: 48.518617, lng: 21.083971 },
    { lat: 48.51862, lng: 21.084041 },
    { lat: 48.518626, lng: 21.084235 },
    { lat: 48.518627, lng: 21.084623 },
    { lat: 48.518615, lng: 21.085096 },
    { lat: 48.518595, lng: 21.085427 },
    { lat: 48.51854, lng: 21.086284 },
    { lat: 48.518424, lng: 21.087077 },
    { lat: 48.518362, lng: 21.087426 },
    { lat: 48.518169, lng: 21.088487 },
    { lat: 48.518116, lng: 21.088745 },
    { lat: 48.518052, lng: 21.089005 },
    { lat: 48.517984, lng: 21.089239 },
    { lat: 48.517923, lng: 21.089419 },
    { lat: 48.517829, lng: 21.089594 },
    { lat: 48.51777, lng: 21.089698 },
    { lat: 48.517676, lng: 21.089857 },
    { lat: 48.51765, lng: 21.089902 },
    { lat: 48.517478, lng: 21.090169 },
    { lat: 48.517295, lng: 21.090429 },
    { lat: 48.516983, lng: 21.090831 },
    { lat: 48.516835, lng: 21.091014 },
    { lat: 48.516669, lng: 21.091243 },
    { lat: 48.516629, lng: 21.091306 },
    { lat: 48.51645, lng: 21.091593 },
    { lat: 48.515849, lng: 21.09249 },
    { lat: 48.515729, lng: 21.092656 },
    { lat: 48.515571, lng: 21.092835 },
    { lat: 48.515284, lng: 21.093142 },
    { lat: 48.514994, lng: 21.093463 },
    { lat: 48.514635, lng: 21.093893 },
    { lat: 48.514308, lng: 21.094295 },
    { lat: 48.513938, lng: 21.094756 },
    { lat: 48.51373, lng: 21.095013 },
    { lat: 48.513444, lng: 21.095372 },
    { lat: 48.512908, lng: 21.096073 },
    { lat: 48.512814, lng: 21.096232 },
    { lat: 48.512608, lng: 21.096431 },
    { lat: 48.512153, lng: 21.09681 },
    { lat: 48.511978, lng: 21.096952 },
    { lat: 48.51168, lng: 21.097195 },
    { lat: 48.510973, lng: 21.097777 },
    { lat: 48.510881, lng: 21.09784 },
    { lat: 48.510781, lng: 21.097899 },
    { lat: 48.510618, lng: 21.097973 },
    { lat: 48.510465, lng: 21.098018 },
    { lat: 48.510309, lng: 21.098047 },
    { lat: 48.510185, lng: 21.098063 },
    { lat: 48.509969, lng: 21.098058 },
    { lat: 48.50974, lng: 21.098039 },
    { lat: 48.509467, lng: 21.098 },
    { lat: 48.509052, lng: 21.097937 },
    { lat: 48.508783, lng: 21.097896 },
    { lat: 48.508481, lng: 21.097862 },
    { lat: 48.508335, lng: 21.097853 },
    { lat: 48.508009, lng: 21.097882 },
    { lat: 48.50718, lng: 21.097995 },
    { lat: 48.507034, lng: 21.098013 },
    { lat: 48.506647, lng: 21.098039 },
    { lat: 48.506359, lng: 21.09805 },
    { lat: 48.505971, lng: 21.098068 },
    { lat: 48.505791, lng: 21.098071 },
    { lat: 48.505562, lng: 21.098065 },
    { lat: 48.505226, lng: 21.098023 },
    { lat: 48.504841, lng: 21.097961 },
    { lat: 48.504774, lng: 21.09795 },
    { lat: 48.50423, lng: 21.097858 },
    { lat: 48.504157, lng: 21.097844 },
    { lat: 48.50405, lng: 21.09781 },
    { lat: 48.504005, lng: 21.097789 },
    { lat: 48.503909, lng: 21.097683 },
    { lat: 48.503374, lng: 21.096863 },
    { lat: 48.502757, lng: 21.095937 },
    { lat: 48.502547, lng: 21.09562 },
    { lat: 48.502284, lng: 21.095244 },
    { lat: 48.502212, lng: 21.095153 },
    { lat: 48.502137, lng: 21.095071 },
    { lat: 48.502037, lng: 21.094968 },
    { lat: 48.50188, lng: 21.094836 },
    { lat: 48.501718, lng: 21.094703 },
    { lat: 48.501521, lng: 21.094549 },
    { lat: 48.501396, lng: 21.094459 },
    { lat: 48.501236, lng: 21.094357 },
    { lat: 48.501101, lng: 21.094284 },
    { lat: 48.500952, lng: 21.094222 },
    { lat: 48.500839, lng: 21.094201 },
    { lat: 48.5007889, lng: 21.0941131 },
    { lat: 48.500755, lng: 21.094189 },
    { lat: 48.500698, lng: 21.094203 },
    { lat: 48.500564, lng: 21.094289 },
    { lat: 48.500154, lng: 21.094604 },
    { lat: 48.499713, lng: 21.094978 },
    { lat: 48.499565, lng: 21.09515 },
    { lat: 48.499411, lng: 21.095363 },
    { lat: 48.499245, lng: 21.095571 },
    { lat: 48.499045, lng: 21.09576 },
    { lat: 48.49896, lng: 21.095846 },
    { lat: 48.498737, lng: 21.09613 },
    { lat: 48.498472, lng: 21.096498 },
    { lat: 48.498317, lng: 21.09671 },
    { lat: 48.497913, lng: 21.097281 },
    { lat: 48.497653, lng: 21.097621 },
    { lat: 48.497456, lng: 21.097866 },
    { lat: 48.49733, lng: 21.097949 },
    { lat: 48.497221, lng: 21.098022 },
    { lat: 48.497156, lng: 21.098085 },
    { lat: 48.497034, lng: 21.098202 },
    { lat: 48.49674, lng: 21.09852 },
    { lat: 48.496309, lng: 21.099008 },
    { lat: 48.495905, lng: 21.099462 },
    { lat: 48.49569, lng: 21.09975 },
    { lat: 48.495573, lng: 21.099887 },
    { lat: 48.495469, lng: 21.100009 },
    { lat: 48.495269, lng: 21.100239 },
    { lat: 48.495005, lng: 21.100559 },
    { lat: 48.494712, lng: 21.10095 },
    { lat: 48.494654, lng: 21.101029 },
    { lat: 48.494565, lng: 21.101149 },
    { lat: 48.494066, lng: 21.102632 },
    { lat: 48.494031, lng: 21.102785 },
    { lat: 48.493935, lng: 21.103223 },
    { lat: 48.493826, lng: 21.103695 },
    { lat: 48.493785, lng: 21.103856 },
    { lat: 48.493699, lng: 21.104244 },
    { lat: 48.493664, lng: 21.104401 },
    { lat: 48.493372, lng: 21.105719 },
    { lat: 48.492974, lng: 21.106825 },
    { lat: 48.492298, lng: 21.10866 },
    { lat: 48.492392, lng: 21.109213 },
    { lat: 48.492478, lng: 21.109714 },
    { lat: 48.492251, lng: 21.111655 },
    { lat: 48.491868, lng: 21.112849 },
    { lat: 48.491819, lng: 21.113003 },
    { lat: 48.491638, lng: 21.113748 },
    { lat: 48.4915, lng: 21.114687 },
    { lat: 48.491342, lng: 21.11559 },
    { lat: 48.491185, lng: 21.116472 },
    { lat: 48.491115, lng: 21.117103 },
    { lat: 48.49109, lng: 21.117331 },
    { lat: 48.4911119, lng: 21.1175446 },
  ],
  Hodkovce: [
    { lat: 48.7064846, lng: 21.0974416 },
    { lat: 48.7058985, lng: 21.0960922 },
    { lat: 48.7054732, lng: 21.095644 },
    { lat: 48.7040011, lng: 21.094427 },
    { lat: 48.7032371, lng: 21.0941819 },
    { lat: 48.7028341, lng: 21.0939084 },
    { lat: 48.7025963, lng: 21.0936501 },
    { lat: 48.7022196, lng: 21.0934869 },
    { lat: 48.7021337, lng: 21.0934773 },
    { lat: 48.7020519, lng: 21.0935617 },
    { lat: 48.7019653, lng: 21.0934868 },
    { lat: 48.7018055, lng: 21.0934883 },
    { lat: 48.7017288, lng: 21.0933788 },
    { lat: 48.7016311, lng: 21.093385 },
    { lat: 48.7015302, lng: 21.0932966 },
    { lat: 48.7014678, lng: 21.0933507 },
    { lat: 48.7012447, lng: 21.0932368 },
    { lat: 48.7009935, lng: 21.0926079 },
    { lat: 48.7007012, lng: 21.0922336 },
    { lat: 48.700434, lng: 21.0920431 },
    { lat: 48.7003405, lng: 21.0917639 },
    { lat: 48.7002153, lng: 21.0916121 },
    { lat: 48.7001463, lng: 21.0916065 },
    { lat: 48.6999985, lng: 21.0914186 },
    { lat: 48.6993441, lng: 21.0908829 },
    { lat: 48.6991829, lng: 21.0908421 },
    { lat: 48.6990161, lng: 21.0906581 },
    { lat: 48.6985981, lng: 21.0907157 },
    { lat: 48.6983569, lng: 21.0906376 },
    { lat: 48.6981825, lng: 21.0906715 },
    { lat: 48.6979916, lng: 21.0908207 },
    { lat: 48.6978827, lng: 21.0907286 },
    { lat: 48.697523, lng: 21.0909522 },
    { lat: 48.6973076, lng: 21.0908687 },
    { lat: 48.6972021, lng: 21.0909103 },
    { lat: 48.697039, lng: 21.0908133 },
    { lat: 48.6966963, lng: 21.0907898 },
    { lat: 48.6963169, lng: 21.0906176 },
    { lat: 48.6962015, lng: 21.0903158 },
    { lat: 48.6956792, lng: 21.0899706 },
    { lat: 48.6956385, lng: 21.0898938 },
    { lat: 48.6955289, lng: 21.0898816 },
    { lat: 48.6954598, lng: 21.0896259 },
    { lat: 48.6952581, lng: 21.0894417 },
    { lat: 48.6951519, lng: 21.0891848 },
    { lat: 48.6950774, lng: 21.0891412 },
    { lat: 48.6950554, lng: 21.0890224 },
    { lat: 48.6947138, lng: 21.0888962 },
    { lat: 48.6946307, lng: 21.0887338 },
    { lat: 48.694419, lng: 21.0885556 },
    { lat: 48.6942863, lng: 21.0884935 },
    { lat: 48.6942069, lng: 21.0885392 },
    { lat: 48.6937659, lng: 21.0880218 },
    { lat: 48.6936892, lng: 21.0877731 },
    { lat: 48.6934967, lng: 21.0874934 },
    { lat: 48.6930289, lng: 21.0874047 },
    { lat: 48.6928436, lng: 21.0870721 },
    { lat: 48.6926587, lng: 21.087028 },
    { lat: 48.6926025, lng: 21.0867867 },
    { lat: 48.6923819, lng: 21.0865077 },
    { lat: 48.6923844, lng: 21.0862717 },
    { lat: 48.6923394, lng: 21.0861984 },
    { lat: 48.6920985, lng: 21.0861311 },
    { lat: 48.6916627, lng: 21.0853603 },
    { lat: 48.6912201, lng: 21.0848697 },
    { lat: 48.691166, lng: 21.0846536 },
    { lat: 48.6912067, lng: 21.0846295 },
    { lat: 48.6912281, lng: 21.0844541 },
    { lat: 48.6911347, lng: 21.0844535 },
    { lat: 48.6910871, lng: 21.0843024 },
    { lat: 48.6908965, lng: 21.0840829 },
    { lat: 48.6908018, lng: 21.0841508 },
    { lat: 48.6906822, lng: 21.0840706 },
    { lat: 48.6904642, lng: 21.0835595 },
    { lat: 48.6899622, lng: 21.0830747 },
    { lat: 48.6898698, lng: 21.0831731 },
    { lat: 48.6897236, lng: 21.0831807 },
    { lat: 48.6895886, lng: 21.0832865 },
    { lat: 48.6894367, lng: 21.0831481 },
    { lat: 48.6891829, lng: 21.0831982 },
    { lat: 48.6891046, lng: 21.0832838 },
    { lat: 48.689078, lng: 21.0831561 },
    { lat: 48.6889981, lng: 21.08312 },
    { lat: 48.6889034, lng: 21.0832919 },
    { lat: 48.6887827, lng: 21.0832863 },
    { lat: 48.6886867, lng: 21.0832706 },
    { lat: 48.688499, lng: 21.08293 },
    { lat: 48.6880781, lng: 21.0828674 },
    { lat: 48.6880147, lng: 21.0828921 },
    { lat: 48.6880089, lng: 21.0830895 },
    { lat: 48.6879628, lng: 21.0830947 },
    { lat: 48.6879392, lng: 21.0828834 },
    { lat: 48.6878785, lng: 21.0828502 },
    { lat: 48.6878317, lng: 21.0829078 },
    { lat: 48.6877917, lng: 21.0827486 },
    { lat: 48.6876598, lng: 21.0826398 },
    { lat: 48.6876109, lng: 21.0827218 },
    { lat: 48.6876096, lng: 21.0828925 },
    { lat: 48.6871379, lng: 21.0827112 },
    { lat: 48.6870117, lng: 21.0828131 },
    { lat: 48.6866559, lng: 21.082745 },
    { lat: 48.6863273, lng: 21.0825025 },
    { lat: 48.6861615, lng: 21.082523 },
    { lat: 48.6858866, lng: 21.0826883 },
    { lat: 48.685341, lng: 21.0827999 },
    { lat: 48.6852313, lng: 21.0826481 },
    { lat: 48.6849203, lng: 21.0827373 },
    { lat: 48.6848101, lng: 21.0827074 },
    { lat: 48.6846978, lng: 21.0828225 },
    { lat: 48.6843858, lng: 21.0827776 },
    { lat: 48.6842094, lng: 21.0826488 },
    { lat: 48.6840032, lng: 21.0827482 },
    { lat: 48.6839255, lng: 21.0826082 },
    { lat: 48.6834584, lng: 21.0824009 },
    { lat: 48.6832453, lng: 21.0822422 },
    { lat: 48.6830242, lng: 21.0822322 },
    { lat: 48.6829253, lng: 21.0823043 },
    { lat: 48.6825622, lng: 21.0822859 },
    { lat: 48.6822548, lng: 21.0825227 },
    { lat: 48.6820118, lng: 21.0823879 },
    { lat: 48.681967, lng: 21.0824268 },
    { lat: 48.6816998, lng: 21.0823109 },
    { lat: 48.6815717, lng: 21.0823887 },
    { lat: 48.6814366, lng: 21.0822434 },
    { lat: 48.6813194, lng: 21.0822749 },
    { lat: 48.6812054, lng: 21.0819215 },
    { lat: 48.6810964, lng: 21.08203 },
    { lat: 48.6810001, lng: 21.0820417 },
    { lat: 48.6810015, lng: 21.0818464 },
    { lat: 48.6809523, lng: 21.0817828 },
    { lat: 48.6808129, lng: 21.0817906 },
    { lat: 48.6806723, lng: 21.081338 },
    { lat: 48.6806068, lng: 21.0813336 },
    { lat: 48.6804527, lng: 21.0816132 },
    { lat: 48.6803824, lng: 21.081634 },
    { lat: 48.6803138, lng: 21.0815643 },
    { lat: 48.6803224, lng: 21.0814504 },
    { lat: 48.6802517, lng: 21.0814225 },
    { lat: 48.6799157, lng: 21.0817829 },
    { lat: 48.6797526, lng: 21.0816468 },
    { lat: 48.6796218, lng: 21.0817804 },
    { lat: 48.6794553, lng: 21.0816086 },
    { lat: 48.6793269, lng: 21.0813282 },
    { lat: 48.6791867, lng: 21.0812392 },
    { lat: 48.679088, lng: 21.0813872 },
    { lat: 48.6788303, lng: 21.0814265 },
    { lat: 48.6788186, lng: 21.0815175 },
    { lat: 48.6788921, lng: 21.0816306 },
    { lat: 48.6788593, lng: 21.0817296 },
    { lat: 48.6786364, lng: 21.081729 },
    { lat: 48.6783767, lng: 21.0820135 },
    { lat: 48.6783221, lng: 21.0818555 },
    { lat: 48.6781295, lng: 21.0817868 },
    { lat: 48.6780624, lng: 21.081973 },
    { lat: 48.6779442, lng: 21.0818995 },
    { lat: 48.6778915, lng: 21.0821142 },
    { lat: 48.6777607, lng: 21.0819693 },
    { lat: 48.6776569, lng: 21.0817234 },
    { lat: 48.6774379, lng: 21.0817688 },
    { lat: 48.6774744, lng: 21.0815072 },
    { lat: 48.677403, lng: 21.0814199 },
    { lat: 48.6770696, lng: 21.0816558 },
    { lat: 48.677052, lng: 21.0817767 },
    { lat: 48.6768433, lng: 21.0817249 },
    { lat: 48.6767009, lng: 21.0818789 },
    { lat: 48.6764295, lng: 21.0817377 },
    { lat: 48.6762333, lng: 21.0817223 },
    { lat: 48.6761749, lng: 21.0818704 },
    { lat: 48.6759448, lng: 21.0819241 },
    { lat: 48.6759075, lng: 21.0821926 },
    { lat: 48.6757634, lng: 21.0821559 },
    { lat: 48.6757043, lng: 21.0819986 },
    { lat: 48.675617, lng: 21.0820196 },
    { lat: 48.6756266, lng: 21.0821414 },
    { lat: 48.6755724, lng: 21.0822391 },
    { lat: 48.6754697, lng: 21.0821972 },
    { lat: 48.6754761, lng: 21.0821127 },
    { lat: 48.6753753, lng: 21.0819556 },
    { lat: 48.6753, lng: 21.0821007 },
    { lat: 48.6751516, lng: 21.0821067 },
    { lat: 48.6749883, lng: 21.0819643 },
    { lat: 48.6749516, lng: 21.0820489 },
    { lat: 48.6749754, lng: 21.0822202 },
    { lat: 48.6749019, lng: 21.0823563 },
    { lat: 48.6748607, lng: 21.082713 },
    { lat: 48.6746186, lng: 21.0825703 },
    { lat: 48.6744993, lng: 21.0825738 },
    { lat: 48.6744153, lng: 21.0823396 },
    { lat: 48.6742483, lng: 21.0824698 },
    { lat: 48.6740355, lng: 21.0824688 },
    { lat: 48.6739885, lng: 21.0824014 },
    { lat: 48.6739955, lng: 21.082303 },
    { lat: 48.673838, lng: 21.0821376 },
    { lat: 48.6737754, lng: 21.0822146 },
    { lat: 48.6738172, lng: 21.0824333 },
    { lat: 48.6737931, lng: 21.0825566 },
    { lat: 48.6737165, lng: 21.0825861 },
    { lat: 48.6736316, lng: 21.0824357 },
    { lat: 48.6733579, lng: 21.0822575 },
    { lat: 48.6729818, lng: 21.0818666 },
    { lat: 48.6728554, lng: 21.0815764 },
    { lat: 48.6727742, lng: 21.0815753 },
    { lat: 48.6727274, lng: 21.0813829 },
    { lat: 48.672402, lng: 21.0812756 },
    { lat: 48.6723617, lng: 21.0811742 },
    { lat: 48.6724734, lng: 21.0808387 },
    { lat: 48.6723868, lng: 21.0807344 },
    { lat: 48.6722585, lng: 21.0807713 },
    { lat: 48.6722087, lng: 21.080937 },
    { lat: 48.6721268, lng: 21.0809801 },
    { lat: 48.6718877, lng: 21.0807204 },
    { lat: 48.6717486, lng: 21.0808001 },
    { lat: 48.670967, lng: 21.0805097 },
    { lat: 48.670852, lng: 21.080406 },
    { lat: 48.6707045, lng: 21.0804308 },
    { lat: 48.6706304, lng: 21.0802985 },
    { lat: 48.6704293, lng: 21.0801702 },
    { lat: 48.6703393, lng: 21.0802103 },
    { lat: 48.6702532, lng: 21.0804346 },
    { lat: 48.670069, lng: 21.0804863 },
    { lat: 48.6699854, lng: 21.0804391 },
    { lat: 48.6697493, lng: 21.080547 },
    { lat: 48.6697035, lng: 21.0805234 },
    { lat: 48.6696224, lng: 21.0802765 },
    { lat: 48.6695996, lng: 21.0802743 },
    { lat: 48.6694783, lng: 21.080279 },
    { lat: 48.6692625, lng: 21.0804303 },
    { lat: 48.669038, lng: 21.0804469 },
    { lat: 48.6685167, lng: 21.0808946 },
    { lat: 48.6684016, lng: 21.0809166 },
    { lat: 48.6681286, lng: 21.0806206 },
    { lat: 48.6678099, lng: 21.080787 },
    { lat: 48.6677534, lng: 21.0811615 },
    { lat: 48.6674124, lng: 21.0817208 },
    { lat: 48.6672694, lng: 21.0817211 },
    { lat: 48.6671755, lng: 21.0818046 },
    { lat: 48.6668404, lng: 21.0822299 },
    { lat: 48.6665987, lng: 21.0821356 },
    { lat: 48.6665785, lng: 21.0822108 },
    { lat: 48.6666696, lng: 21.0823803 },
    { lat: 48.6663219, lng: 21.0828362 },
    { lat: 48.6663486, lng: 21.0833768 },
    { lat: 48.66625, lng: 21.0834621 },
    { lat: 48.6661821, lng: 21.0834134 },
    { lat: 48.6659083, lng: 21.0837541 },
    { lat: 48.6656052, lng: 21.0836002 },
    { lat: 48.6654754, lng: 21.0836297 },
    { lat: 48.6654471, lng: 21.0837579 },
    { lat: 48.6651961, lng: 21.0838302 },
    { lat: 48.6651194, lng: 21.0841036 },
    { lat: 48.6651501, lng: 21.0842512 },
    { lat: 48.665069, lng: 21.0844577 },
    { lat: 48.6647465, lng: 21.0845759 },
    { lat: 48.6645804, lng: 21.0845604 },
    { lat: 48.6644255, lng: 21.0847452 },
    { lat: 48.6639837, lng: 21.0849673 },
    { lat: 48.664, lng: 21.0851227 },
    { lat: 48.6637697, lng: 21.0853818 },
    { lat: 48.6636808, lng: 21.0853817 },
    { lat: 48.6636478, lng: 21.0853004 },
    { lat: 48.6635281, lng: 21.0853573 },
    { lat: 48.6633675, lng: 21.085262 },
    { lat: 48.6631798, lng: 21.0850313 },
    { lat: 48.6631185, lng: 21.0850803 },
    { lat: 48.6631125, lng: 21.0852463 },
    { lat: 48.6630095, lng: 21.0853654 },
    { lat: 48.6628671, lng: 21.0853164 },
    { lat: 48.6626976, lng: 21.0853973 },
    { lat: 48.6625322, lng: 21.0850891 },
    { lat: 48.6622729, lng: 21.0849338 },
    { lat: 48.6622549, lng: 21.0850053 },
    { lat: 48.6623318, lng: 21.0851876 },
    { lat: 48.6620969, lng: 21.085128 },
    { lat: 48.6619181, lng: 21.0852723 },
    { lat: 48.661789, lng: 21.0851469 },
    { lat: 48.6616222, lng: 21.0852404 },
    { lat: 48.6616249, lng: 21.0853961 },
    { lat: 48.6614904, lng: 21.0859675 },
    { lat: 48.6604143, lng: 21.0875298 },
    { lat: 48.6598525, lng: 21.0881106 },
    { lat: 48.6595107, lng: 21.0886111 },
    { lat: 48.6581689, lng: 21.0908974 },
    { lat: 48.6574397, lng: 21.0924642 },
    { lat: 48.6571044, lng: 21.0936847 },
    { lat: 48.6567028, lng: 21.0949115 },
    { lat: 48.6559116, lng: 21.0955059 },
    { lat: 48.6558598, lng: 21.0956468 },
    { lat: 48.6561423, lng: 21.0969282 },
    { lat: 48.6569087, lng: 21.0994349 },
    { lat: 48.6610207, lng: 21.1009815 },
    { lat: 48.6686388, lng: 21.1042157 },
    { lat: 48.6755677, lng: 21.1069776 },
    { lat: 48.6756402, lng: 21.1070998 },
    { lat: 48.6760123, lng: 21.1071911 },
    { lat: 48.6762526, lng: 21.1071406 },
    { lat: 48.678869, lng: 21.1072985 },
    { lat: 48.6789206, lng: 21.1072982 },
    { lat: 48.6806548, lng: 21.1072874 },
    { lat: 48.6829315, lng: 21.1072102 },
    { lat: 48.6842618, lng: 21.1072524 },
    { lat: 48.6867995, lng: 21.1070966 },
    { lat: 48.686881, lng: 21.1065225 },
    { lat: 48.6892935, lng: 21.1060855 },
    { lat: 48.696811, lng: 21.1043105 },
    { lat: 48.7012274, lng: 21.1033717 },
    { lat: 48.703657, lng: 21.1027625 },
    { lat: 48.7041706, lng: 21.1025715 },
    { lat: 48.7044157, lng: 21.1025267 },
    { lat: 48.7042763, lng: 21.1020748 },
    { lat: 48.7043424, lng: 21.1005987 },
    { lat: 48.7043989, lng: 21.1003608 },
    { lat: 48.7047485, lng: 21.0997948 },
    { lat: 48.7049092, lng: 21.0996433 },
    { lat: 48.7055563, lng: 21.0985961 },
    { lat: 48.7062865, lng: 21.0977203 },
    { lat: 48.7064846, lng: 21.0974416 },
  ],
  Ždaňa: [
    { lat: 48.6083954, lng: 21.3448185 },
    { lat: 48.6084463, lng: 21.3446383 },
    { lat: 48.6086704, lng: 21.3443028 },
    { lat: 48.6059643, lng: 21.3424985 },
    { lat: 48.6058277, lng: 21.3433709 },
    { lat: 48.6044988, lng: 21.3424865 },
    { lat: 48.6037806, lng: 21.3428865 },
    { lat: 48.6037251, lng: 21.3420981 },
    { lat: 48.6028829, lng: 21.3402914 },
    { lat: 48.6026805, lng: 21.3402975 },
    { lat: 48.6007782, lng: 21.3396536 },
    { lat: 48.5984164, lng: 21.3400005 },
    { lat: 48.5984741, lng: 21.3406767 },
    { lat: 48.5976118, lng: 21.3408206 },
    { lat: 48.5969949, lng: 21.3404374 },
    { lat: 48.5968347, lng: 21.3401313 },
    { lat: 48.5966655, lng: 21.3395183 },
    { lat: 48.5966047, lng: 21.3383294 },
    { lat: 48.5967199, lng: 21.3376385 },
    { lat: 48.5968804, lng: 21.3371767 },
    { lat: 48.5965246, lng: 21.3367976 },
    { lat: 48.5961311, lng: 21.3358122 },
    { lat: 48.5958644, lng: 21.3338045 },
    { lat: 48.5962044, lng: 21.3338422 },
    { lat: 48.5965912, lng: 21.3332752 },
    { lat: 48.5967473, lng: 21.3332271 },
    { lat: 48.5970386, lng: 21.3329258 },
    { lat: 48.5971989, lng: 21.3324575 },
    { lat: 48.5971735, lng: 21.3316967 },
    { lat: 48.5972452, lng: 21.3312384 },
    { lat: 48.5980771, lng: 21.3291005 },
    { lat: 48.5983, lng: 21.3287673 },
    { lat: 48.5984888, lng: 21.328274 },
    { lat: 48.5985003, lng: 21.3279454 },
    { lat: 48.598263, lng: 21.3278814 },
    { lat: 48.5973601, lng: 21.3279071 },
    { lat: 48.5955094, lng: 21.3272181 },
    { lat: 48.5942993, lng: 21.3271772 },
    { lat: 48.5934183, lng: 21.3269607 },
    { lat: 48.5933889, lng: 21.3271032 },
    { lat: 48.5933281, lng: 21.3273975 },
    { lat: 48.5926321, lng: 21.3298963 },
    { lat: 48.5904787, lng: 21.336972 },
    { lat: 48.5875201, lng: 21.3496599 },
    { lat: 48.5872061, lng: 21.3501485 },
    { lat: 48.5867505, lng: 21.3557876 },
    { lat: 48.5871176, lng: 21.3556445 },
    { lat: 48.5873341, lng: 21.355554 },
    { lat: 48.5873036, lng: 21.3553243 },
    { lat: 48.5875, lng: 21.3553066 },
    { lat: 48.5874977, lng: 21.3552282 },
    { lat: 48.5881383, lng: 21.3553816 },
    { lat: 48.5881791, lng: 21.3549846 },
    { lat: 48.5908099, lng: 21.3557206 },
    { lat: 48.5924623, lng: 21.3557756 },
    { lat: 48.5921235, lng: 21.3576029 },
    { lat: 48.5929401, lng: 21.3580696 },
    { lat: 48.5931604, lng: 21.3582906 },
    { lat: 48.593399, lng: 21.3586962 },
    { lat: 48.5938491, lng: 21.3597845 },
    { lat: 48.5938975, lng: 21.3602714 },
    { lat: 48.5940354, lng: 21.3604732 },
    { lat: 48.5946419, lng: 21.3610791 },
    { lat: 48.5948398, lng: 21.361479 },
    { lat: 48.5949254, lng: 21.3615585 },
    { lat: 48.5944411, lng: 21.3631198 },
    { lat: 48.5966728, lng: 21.3641303 },
    { lat: 48.5964475, lng: 21.3669403 },
    { lat: 48.5969859, lng: 21.3670657 },
    { lat: 48.5969599, lng: 21.3706959 },
    { lat: 48.596863, lng: 21.3708834 },
    { lat: 48.5968472, lng: 21.3711451 },
    { lat: 48.5980479, lng: 21.3711823 },
    { lat: 48.5980859, lng: 21.3736119 },
    { lat: 48.5981269, lng: 21.3740069 },
    { lat: 48.5982383, lng: 21.3740008 },
    { lat: 48.5984092, lng: 21.3764785 },
    { lat: 48.5992415, lng: 21.3762771 },
    { lat: 48.6002436, lng: 21.3761421 },
    { lat: 48.6001494, lng: 21.3779571 },
    { lat: 48.6001509, lng: 21.3795512 },
    { lat: 48.6005431, lng: 21.3817164 },
    { lat: 48.6005995, lng: 21.3817229 },
    { lat: 48.6006659, lng: 21.3821006 },
    { lat: 48.6007025, lng: 21.3827968 },
    { lat: 48.6006553, lng: 21.3830225 },
    { lat: 48.6006368, lng: 21.3837238 },
    { lat: 48.6007798, lng: 21.3836871 },
    { lat: 48.6008212, lng: 21.383983 },
    { lat: 48.6007822, lng: 21.3842185 },
    { lat: 48.6008209, lng: 21.3847637 },
    { lat: 48.600728, lng: 21.3852681 },
    { lat: 48.600763, lng: 21.3856519 },
    { lat: 48.6004494, lng: 21.3868692 },
    { lat: 48.6001562, lng: 21.3869609 },
    { lat: 48.5999742, lng: 21.3893503 },
    { lat: 48.5999961, lng: 21.389666 },
    { lat: 48.6000946, lng: 21.3896479 },
    { lat: 48.6001454, lng: 21.3905987 },
    { lat: 48.6003794, lng: 21.3910532 },
    { lat: 48.6008502, lng: 21.3916272 },
    { lat: 48.6010006, lng: 21.3917283 },
    { lat: 48.6019838, lng: 21.3918757 },
    { lat: 48.60289, lng: 21.3921101 },
    { lat: 48.6037232, lng: 21.3927299 },
    { lat: 48.6044581, lng: 21.3929962 },
    { lat: 48.6042745, lng: 21.3926837 },
    { lat: 48.6042873, lng: 21.3918288 },
    { lat: 48.6044189, lng: 21.3915308 },
    { lat: 48.6043739, lng: 21.3912629 },
    { lat: 48.6047218, lng: 21.3906644 },
    { lat: 48.6046945, lng: 21.3901997 },
    { lat: 48.6049043, lng: 21.3893499 },
    { lat: 48.6048082, lng: 21.389259 },
    { lat: 48.604744, lng: 21.3892548 },
    { lat: 48.603323, lng: 21.389684 },
    { lat: 48.6030623, lng: 21.3890542 },
    { lat: 48.602678, lng: 21.3871112 },
    { lat: 48.6027231, lng: 21.3860952 },
    { lat: 48.6029298, lng: 21.3859724 },
    { lat: 48.6030038, lng: 21.3841842 },
    { lat: 48.6031136, lng: 21.383455 },
    { lat: 48.6030846, lng: 21.3826346 },
    { lat: 48.6032039, lng: 21.3817347 },
    { lat: 48.6030862, lng: 21.381149 },
    { lat: 48.6030774, lng: 21.380628 },
    { lat: 48.6027871, lng: 21.3799483 },
    { lat: 48.6027706, lng: 21.3796248 },
    { lat: 48.6025483, lng: 21.3788151 },
    { lat: 48.6024862, lng: 21.3787343 },
    { lat: 48.6023548, lng: 21.3780225 },
    { lat: 48.602495, lng: 21.3770736 },
    { lat: 48.6026322, lng: 21.3768417 },
    { lat: 48.6028664, lng: 21.3766157 },
    { lat: 48.6029647, lng: 21.37629 },
    { lat: 48.6032995, lng: 21.3759366 },
    { lat: 48.6037941, lng: 21.3756953 },
    { lat: 48.6045632, lng: 21.3754065 },
    { lat: 48.6046849, lng: 21.3754142 },
    { lat: 48.6048637, lng: 21.3754479 },
    { lat: 48.6051554, lng: 21.3757146 },
    { lat: 48.6054613, lng: 21.375788 },
    { lat: 48.6055429, lng: 21.375733 },
    { lat: 48.6059954, lng: 21.3760709 },
    { lat: 48.6061569, lng: 21.3760169 },
    { lat: 48.6064559, lng: 21.3758575 },
    { lat: 48.6067555, lng: 21.3753717 },
    { lat: 48.6070225, lng: 21.3753823 },
    { lat: 48.6070859, lng: 21.3752468 },
    { lat: 48.6072851, lng: 21.3751369 },
    { lat: 48.6074108, lng: 21.3749779 },
    { lat: 48.6076217, lng: 21.3749482 },
    { lat: 48.6078687, lng: 21.3750089 },
    { lat: 48.6079545, lng: 21.3748641 },
    { lat: 48.6078718, lng: 21.3746236 },
    { lat: 48.6082244, lng: 21.3742234 },
    { lat: 48.6085005, lng: 21.3743497 },
    { lat: 48.6085935, lng: 21.3740354 },
    { lat: 48.608763, lng: 21.3742066 },
    { lat: 48.6091101, lng: 21.3740329 },
    { lat: 48.6093336, lng: 21.3738349 },
    { lat: 48.6095385, lng: 21.3739138 },
    { lat: 48.6096624, lng: 21.3736184 },
    { lat: 48.6099108, lng: 21.3734937 },
    { lat: 48.6099914, lng: 21.3732183 },
    { lat: 48.6103511, lng: 21.3735042 },
    { lat: 48.6106913, lng: 21.3732496 },
    { lat: 48.6107506, lng: 21.3731282 },
    { lat: 48.6109461, lng: 21.3735295 },
    { lat: 48.6109963, lng: 21.3735513 },
    { lat: 48.6112856, lng: 21.3732905 },
    { lat: 48.6114074, lng: 21.373492 },
    { lat: 48.6115449, lng: 21.373122 },
    { lat: 48.6116123, lng: 21.3730801 },
    { lat: 48.6117431, lng: 21.373206 },
    { lat: 48.6117999, lng: 21.3731808 },
    { lat: 48.6118816, lng: 21.3729398 },
    { lat: 48.6120728, lng: 21.3729773 },
    { lat: 48.6120796, lng: 21.3728982 },
    { lat: 48.612575, lng: 21.3726123 },
    { lat: 48.6119004, lng: 21.3715381 },
    { lat: 48.6119658, lng: 21.371359 },
    { lat: 48.6115231, lng: 21.370812 },
    { lat: 48.6114266, lng: 21.3703382 },
    { lat: 48.6113581, lng: 21.3689915 },
    { lat: 48.6114473, lng: 21.3686241 },
    { lat: 48.6119567, lng: 21.3677931 },
    { lat: 48.6121755, lng: 21.3677274 },
    { lat: 48.6122217, lng: 21.3678455 },
    { lat: 48.6132295, lng: 21.367787 },
    { lat: 48.6138352, lng: 21.3677197 },
    { lat: 48.6140322, lng: 21.3676142 },
    { lat: 48.6140376, lng: 21.3670803 },
    { lat: 48.6139512, lng: 21.3668636 },
    { lat: 48.6137622, lng: 21.3665692 },
    { lat: 48.6136356, lng: 21.3662489 },
    { lat: 48.6127731, lng: 21.3654024 },
    { lat: 48.6123913, lng: 21.3653961 },
    { lat: 48.6122857, lng: 21.3655139 },
    { lat: 48.6116027, lng: 21.365589 },
    { lat: 48.6108477, lng: 21.3654108 },
    { lat: 48.6105799, lng: 21.3657061 },
    { lat: 48.6103676, lng: 21.3656499 },
    { lat: 48.6102972, lng: 21.3655099 },
    { lat: 48.6102501, lng: 21.3651451 },
    { lat: 48.6100305, lng: 21.3646142 },
    { lat: 48.6101073, lng: 21.3642822 },
    { lat: 48.6102129, lng: 21.3641722 },
    { lat: 48.6103755, lng: 21.3643023 },
    { lat: 48.6104576, lng: 21.3642636 },
    { lat: 48.6106289, lng: 21.3637103 },
    { lat: 48.6106443, lng: 21.3627559 },
    { lat: 48.6104497, lng: 21.362016 },
    { lat: 48.6103507, lng: 21.3614132 },
    { lat: 48.6101043, lng: 21.3614912 },
    { lat: 48.609903, lng: 21.362059 },
    { lat: 48.6097654, lng: 21.3622662 },
    { lat: 48.6097053, lng: 21.362777 },
    { lat: 48.6095563, lng: 21.3629134 },
    { lat: 48.6095263, lng: 21.3631167 },
    { lat: 48.6094576, lng: 21.3632221 },
    { lat: 48.6092661, lng: 21.3632913 },
    { lat: 48.6088265, lng: 21.3627425 },
    { lat: 48.6081622, lng: 21.3616109 },
    { lat: 48.6077633, lng: 21.3611051 },
    { lat: 48.6076524, lng: 21.3608598 },
    { lat: 48.6076509, lng: 21.3604019 },
    { lat: 48.6074481, lng: 21.3601683 },
    { lat: 48.6070426, lng: 21.360083 },
    { lat: 48.606351, lng: 21.3592001 },
    { lat: 48.6061027, lng: 21.358572 },
    { lat: 48.6061256, lng: 21.3580247 },
    { lat: 48.6057489, lng: 21.3576316 },
    { lat: 48.6051972, lng: 21.3575075 },
    { lat: 48.6051226, lng: 21.3573796 },
    { lat: 48.6050702, lng: 21.3570981 },
    { lat: 48.6050556, lng: 21.3566528 },
    { lat: 48.6051969, lng: 21.3562232 },
    { lat: 48.6049687, lng: 21.3558957 },
    { lat: 48.6052144, lng: 21.3554941 },
    { lat: 48.6056257, lng: 21.3554795 },
    { lat: 48.6057668, lng: 21.3555708 },
    { lat: 48.605849, lng: 21.3555353 },
    { lat: 48.6060733, lng: 21.3549812 },
    { lat: 48.6063101, lng: 21.3548473 },
    { lat: 48.6063811, lng: 21.3547017 },
    { lat: 48.6063784, lng: 21.3545422 },
    { lat: 48.6065566, lng: 21.3541061 },
    { lat: 48.6064922, lng: 21.3537617 },
    { lat: 48.6068815, lng: 21.353308 },
    { lat: 48.6071057, lng: 21.3532428 },
    { lat: 48.6070224, lng: 21.3529427 },
    { lat: 48.6069823, lng: 21.3521749 },
    { lat: 48.6070188, lng: 21.3519249 },
    { lat: 48.6068689, lng: 21.3510573 },
    { lat: 48.6066331, lng: 21.3512299 },
    { lat: 48.6064233, lng: 21.3512876 },
    { lat: 48.6062491, lng: 21.3512192 },
    { lat: 48.6060675, lng: 21.3508288 },
    { lat: 48.6060016, lng: 21.3504016 },
    { lat: 48.6060147, lng: 21.3496419 },
    { lat: 48.6059533, lng: 21.3491 },
    { lat: 48.6059166, lng: 21.347922 },
    { lat: 48.6056534, lng: 21.3470994 },
    { lat: 48.6055989, lng: 21.3466765 },
    { lat: 48.6054825, lng: 21.3463282 },
    { lat: 48.6055123, lng: 21.3462298 },
    { lat: 48.605722, lng: 21.3456877 },
    { lat: 48.6060607, lng: 21.3456875 },
    { lat: 48.6068443, lng: 21.3459609 },
    { lat: 48.6072055, lng: 21.3462945 },
    { lat: 48.6073283, lng: 21.3455521 },
    { lat: 48.6076867, lng: 21.3450798 },
    { lat: 48.6077699, lng: 21.3454948 },
    { lat: 48.6082997, lng: 21.3459662 },
    { lat: 48.6088259, lng: 21.3455396 },
    { lat: 48.6083954, lng: 21.3448185 },
  ],
  Čaňa: [
    { lat: 48.613992, lng: 21.2794914 },
    { lat: 48.6130612, lng: 21.2798514 },
    { lat: 48.6129219, lng: 21.2798988 },
    { lat: 48.6127502, lng: 21.2798393 },
    { lat: 48.6117879, lng: 21.2804921 },
    { lat: 48.6109596, lng: 21.280858 },
    { lat: 48.6089469, lng: 21.2822043 },
    { lat: 48.6064592, lng: 21.2833931 },
    { lat: 48.603508, lng: 21.2841153 },
    { lat: 48.6015445, lng: 21.284266 },
    { lat: 48.5979277, lng: 21.2849673 },
    { lat: 48.5976081, lng: 21.2869469 },
    { lat: 48.5971009, lng: 21.2895582 },
    { lat: 48.596475, lng: 21.2892282 },
    { lat: 48.5961449, lng: 21.2908653 },
    { lat: 48.5961592, lng: 21.291147 },
    { lat: 48.5962024, lng: 21.2921487 },
    { lat: 48.5960409, lng: 21.2937491 },
    { lat: 48.5959536, lng: 21.2959616 },
    { lat: 48.595993, lng: 21.2969167 },
    { lat: 48.5962631, lng: 21.2981657 },
    { lat: 48.5963466, lng: 21.2990608 },
    { lat: 48.596264, lng: 21.3002896 },
    { lat: 48.5961139, lng: 21.3016051 },
    { lat: 48.5961632, lng: 21.3022608 },
    { lat: 48.5961465, lng: 21.3029627 },
    { lat: 48.5960247, lng: 21.3038778 },
    { lat: 48.5958304, lng: 21.3041864 },
    { lat: 48.5956858, lng: 21.3047873 },
    { lat: 48.5951225, lng: 21.3081256 },
    { lat: 48.5933401, lng: 21.3158375 },
    { lat: 48.5925633, lng: 21.3185643 },
    { lat: 48.5919466, lng: 21.3210435 },
    { lat: 48.5917672, lng: 21.3215806 },
    { lat: 48.5919917, lng: 21.321858 },
    { lat: 48.592143, lng: 21.3220637 },
    { lat: 48.592221, lng: 21.3225883 },
    { lat: 48.5921427, lng: 21.3233804 },
    { lat: 48.5919938, lng: 21.3236637 },
    { lat: 48.5917399, lng: 21.3244982 },
    { lat: 48.5916217, lng: 21.3253112 },
    { lat: 48.5917886, lng: 21.3261002 },
    { lat: 48.5921584, lng: 21.3266449 },
    { lat: 48.5930622, lng: 21.3272772 },
    { lat: 48.5933281, lng: 21.3273975 },
    { lat: 48.5933889, lng: 21.3271032 },
    { lat: 48.5934183, lng: 21.3269607 },
    { lat: 48.5942993, lng: 21.3271772 },
    { lat: 48.5955094, lng: 21.3272181 },
    { lat: 48.5973601, lng: 21.3279071 },
    { lat: 48.598263, lng: 21.3278814 },
    { lat: 48.5985003, lng: 21.3279454 },
    { lat: 48.5984888, lng: 21.328274 },
    { lat: 48.5983, lng: 21.3287673 },
    { lat: 48.5980771, lng: 21.3291005 },
    { lat: 48.5972452, lng: 21.3312384 },
    { lat: 48.5971735, lng: 21.3316967 },
    { lat: 48.5971989, lng: 21.3324575 },
    { lat: 48.5970386, lng: 21.3329258 },
    { lat: 48.5967473, lng: 21.3332271 },
    { lat: 48.5965912, lng: 21.3332752 },
    { lat: 48.5962044, lng: 21.3338422 },
    { lat: 48.5958644, lng: 21.3338045 },
    { lat: 48.5961311, lng: 21.3358122 },
    { lat: 48.5965246, lng: 21.3367976 },
    { lat: 48.5968804, lng: 21.3371767 },
    { lat: 48.5967199, lng: 21.3376385 },
    { lat: 48.5966047, lng: 21.3383294 },
    { lat: 48.5966655, lng: 21.3395183 },
    { lat: 48.5968347, lng: 21.3401313 },
    { lat: 48.5969949, lng: 21.3404374 },
    { lat: 48.5976118, lng: 21.3408206 },
    { lat: 48.5984741, lng: 21.3406767 },
    { lat: 48.5984164, lng: 21.3400005 },
    { lat: 48.6007782, lng: 21.3396536 },
    { lat: 48.6026805, lng: 21.3402975 },
    { lat: 48.6028829, lng: 21.3402914 },
    { lat: 48.6037251, lng: 21.3420981 },
    { lat: 48.6037806, lng: 21.3428865 },
    { lat: 48.6044988, lng: 21.3424865 },
    { lat: 48.6058277, lng: 21.3433709 },
    { lat: 48.6059643, lng: 21.3424985 },
    { lat: 48.6086704, lng: 21.3443028 },
    { lat: 48.6084463, lng: 21.3446383 },
    { lat: 48.6083954, lng: 21.3448185 },
    { lat: 48.6084113, lng: 21.3448217 },
    { lat: 48.6097225, lng: 21.3450876 },
    { lat: 48.6103851, lng: 21.3455246 },
    { lat: 48.6113061, lng: 21.3462846 },
    { lat: 48.6126946, lng: 21.3467231 },
    { lat: 48.6128405, lng: 21.3468233 },
    { lat: 48.6129493, lng: 21.3471839 },
    { lat: 48.6131721, lng: 21.347036 },
    { lat: 48.6136567, lng: 21.347098 },
    { lat: 48.6137366, lng: 21.3468696 },
    { lat: 48.6139715, lng: 21.3467151 },
    { lat: 48.6143596, lng: 21.3470797 },
    { lat: 48.6143934, lng: 21.3473099 },
    { lat: 48.6143267, lng: 21.3475994 },
    { lat: 48.6143895, lng: 21.3478508 },
    { lat: 48.6147212, lng: 21.3480279 },
    { lat: 48.6148546, lng: 21.3483064 },
    { lat: 48.6149581, lng: 21.3481197 },
    { lat: 48.6151812, lng: 21.348056 },
    { lat: 48.615295, lng: 21.3472423 },
    { lat: 48.6155934, lng: 21.3472432 },
    { lat: 48.6158132, lng: 21.3469543 },
    { lat: 48.6158616, lng: 21.3466227 },
    { lat: 48.6158306, lng: 21.3464541 },
    { lat: 48.6157487, lng: 21.3463886 },
    { lat: 48.6157406, lng: 21.346191 },
    { lat: 48.6157675, lng: 21.3459648 },
    { lat: 48.6158939, lng: 21.3457157 },
    { lat: 48.6160952, lng: 21.3457499 },
    { lat: 48.6162267, lng: 21.346195 },
    { lat: 48.6161039, lng: 21.3465235 },
    { lat: 48.6161543, lng: 21.3467416 },
    { lat: 48.6162905, lng: 21.3467746 },
    { lat: 48.6164386, lng: 21.346689 },
    { lat: 48.6164747, lng: 21.3465802 },
    { lat: 48.6173224, lng: 21.3474062 },
    { lat: 48.6172692, lng: 21.3475514 },
    { lat: 48.6171306, lng: 21.3476146 },
    { lat: 48.617011, lng: 21.3475653 },
    { lat: 48.6168281, lng: 21.3474376 },
    { lat: 48.6165865, lng: 21.3474873 },
    { lat: 48.6164207, lng: 21.347769 },
    { lat: 48.6165194, lng: 21.3479855 },
    { lat: 48.6169442, lng: 21.3483778 },
    { lat: 48.6171245, lng: 21.3484594 },
    { lat: 48.617581, lng: 21.3483352 },
    { lat: 48.6177919, lng: 21.3480486 },
    { lat: 48.6178189, lng: 21.3478259 },
    { lat: 48.6180246, lng: 21.3475927 },
    { lat: 48.6182822, lng: 21.3467677 },
    { lat: 48.6180939, lng: 21.3462709 },
    { lat: 48.6180679, lng: 21.3460426 },
    { lat: 48.6180917, lng: 21.3457522 },
    { lat: 48.6181733, lng: 21.3455125 },
    { lat: 48.6183199, lng: 21.3455967 },
    { lat: 48.6184913, lng: 21.346165 },
    { lat: 48.6186202, lng: 21.3463027 },
    { lat: 48.6185132, lng: 21.3467418 },
    { lat: 48.6186552, lng: 21.3470465 },
    { lat: 48.6193509, lng: 21.3474316 },
    { lat: 48.6194296, lng: 21.3473899 },
    { lat: 48.6195076, lng: 21.3471388 },
    { lat: 48.6197807, lng: 21.3474284 },
    { lat: 48.6198724, lng: 21.3473716 },
    { lat: 48.6200209, lng: 21.3469936 },
    { lat: 48.6200225, lng: 21.346789 },
    { lat: 48.6199585, lng: 21.3466771 },
    { lat: 48.6193131, lng: 21.3465525 },
    { lat: 48.6192647, lng: 21.3464747 },
    { lat: 48.6192871, lng: 21.3463266 },
    { lat: 48.6195286, lng: 21.3459362 },
    { lat: 48.6195498, lng: 21.3458185 },
    { lat: 48.6195119, lng: 21.3450869 },
    { lat: 48.619622, lng: 21.3442169 },
    { lat: 48.6197256, lng: 21.3442994 },
    { lat: 48.6202182, lng: 21.3442911 },
    { lat: 48.6203648, lng: 21.3448196 },
    { lat: 48.6205258, lng: 21.3448234 },
    { lat: 48.6207794, lng: 21.3451333 },
    { lat: 48.6208082, lng: 21.3455225 },
    { lat: 48.620717, lng: 21.3459393 },
    { lat: 48.6209583, lng: 21.3465839 },
    { lat: 48.6210294, lng: 21.3466658 },
    { lat: 48.6212213, lng: 21.3466864 },
    { lat: 48.6215162, lng: 21.3463549 },
    { lat: 48.6218364, lng: 21.3455885 },
    { lat: 48.6220377, lng: 21.3453957 },
    { lat: 48.6222157, lng: 21.3453334 },
    { lat: 48.6222214, lng: 21.3455521 },
    { lat: 48.6224582, lng: 21.3456102 },
    { lat: 48.6225734, lng: 21.3455108 },
    { lat: 48.6226528, lng: 21.3451579 },
    { lat: 48.622568, lng: 21.3450363 },
    { lat: 48.6223246, lng: 21.3451356 },
    { lat: 48.6220775, lng: 21.345074 },
    { lat: 48.6219564, lng: 21.3449471 },
    { lat: 48.6221357, lng: 21.3443251 },
    { lat: 48.6222366, lng: 21.3442684 },
    { lat: 48.6224679, lng: 21.3444407 },
    { lat: 48.6225597, lng: 21.3444272 },
    { lat: 48.6226243, lng: 21.3443332 },
    { lat: 48.6226168, lng: 21.3440843 },
    { lat: 48.6227244, lng: 21.3437276 },
    { lat: 48.6228699, lng: 21.3436647 },
    { lat: 48.6231013, lng: 21.3434304 },
    { lat: 48.6234936, lng: 21.3437086 },
    { lat: 48.623835, lng: 21.3437242 },
    { lat: 48.6239456, lng: 21.3433243 },
    { lat: 48.6240646, lng: 21.3432841 },
    { lat: 48.6241758, lng: 21.3431242 },
    { lat: 48.6241662, lng: 21.3429157 },
    { lat: 48.6240716, lng: 21.3428763 },
    { lat: 48.6239555, lng: 21.3425756 },
    { lat: 48.6239722, lng: 21.3422326 },
    { lat: 48.6241883, lng: 21.3421256 },
    { lat: 48.6243654, lng: 21.3421728 },
    { lat: 48.6245947, lng: 21.3426106 },
    { lat: 48.6245446, lng: 21.3429978 },
    { lat: 48.6246256, lng: 21.3430414 },
    { lat: 48.6248035, lng: 21.3429669 },
    { lat: 48.624947, lng: 21.3426116 },
    { lat: 48.6251363, lng: 21.341689 },
    { lat: 48.6253097, lng: 21.3413548 },
    { lat: 48.6254987, lng: 21.3411736 },
    { lat: 48.6254653, lng: 21.3407006 },
    { lat: 48.625265, lng: 21.340845 },
    { lat: 48.6249625, lng: 21.3406363 },
    { lat: 48.6250486, lng: 21.3402381 },
    { lat: 48.6254988, lng: 21.3402074 },
    { lat: 48.6255477, lng: 21.3401098 },
    { lat: 48.6254556, lng: 21.3388889 },
    { lat: 48.6253684, lng: 21.338541 },
    { lat: 48.6249873, lng: 21.3383609 },
    { lat: 48.6247501, lng: 21.338782 },
    { lat: 48.6247906, lng: 21.3392249 },
    { lat: 48.6244723, lng: 21.3394592 },
    { lat: 48.6243494, lng: 21.3394513 },
    { lat: 48.624239, lng: 21.3392529 },
    { lat: 48.6243677, lng: 21.3390107 },
    { lat: 48.6243456, lng: 21.3386946 },
    { lat: 48.6242121, lng: 21.338591 },
    { lat: 48.6241528, lng: 21.3386826 },
    { lat: 48.6239606, lng: 21.3387211 },
    { lat: 48.623813, lng: 21.3383475 },
    { lat: 48.6235495, lng: 21.337222 },
    { lat: 48.6239588, lng: 21.3371806 },
    { lat: 48.6241461, lng: 21.3368234 },
    { lat: 48.624188, lng: 21.3369372 },
    { lat: 48.6244565, lng: 21.3369276 },
    { lat: 48.6246496, lng: 21.3366824 },
    { lat: 48.6250113, lng: 21.3365497 },
    { lat: 48.6249036, lng: 21.3359271 },
    { lat: 48.6246765, lng: 21.3358645 },
    { lat: 48.6242475, lng: 21.3358962 },
    { lat: 48.6240324, lng: 21.3360039 },
    { lat: 48.6238397, lng: 21.3357692 },
    { lat: 48.6235943, lng: 21.334799 },
    { lat: 48.6234182, lng: 21.3345209 },
    { lat: 48.6232947, lng: 21.3340848 },
    { lat: 48.6233556, lng: 21.3337523 },
    { lat: 48.6234393, lng: 21.3337305 },
    { lat: 48.6235317, lng: 21.3338451 },
    { lat: 48.6235469, lng: 21.3340891 },
    { lat: 48.6236102, lng: 21.3342487 },
    { lat: 48.6238552, lng: 21.3346441 },
    { lat: 48.6238508, lng: 21.3348738 },
    { lat: 48.6239309, lng: 21.3351049 },
    { lat: 48.624169, lng: 21.3351258 },
    { lat: 48.6242608, lng: 21.3350725 },
    { lat: 48.6242419, lng: 21.3349326 },
    { lat: 48.6244089, lng: 21.3344267 },
    { lat: 48.6245647, lng: 21.3344028 },
    { lat: 48.6246297, lng: 21.3342126 },
    { lat: 48.62474, lng: 21.3341015 },
    { lat: 48.6247423, lng: 21.3340209 },
    { lat: 48.6246672, lng: 21.3339244 },
    { lat: 48.6246186, lng: 21.3339925 },
    { lat: 48.6242307, lng: 21.3341092 },
    { lat: 48.6237957, lng: 21.333723 },
    { lat: 48.6234564, lng: 21.3335166 },
    { lat: 48.6232994, lng: 21.3333147 },
    { lat: 48.6232671, lng: 21.333096 },
    { lat: 48.6233427, lng: 21.3325103 },
    { lat: 48.6240495, lng: 21.3318121 },
    { lat: 48.6242184, lng: 21.3314788 },
    { lat: 48.6244746, lng: 21.3303133 },
    { lat: 48.6245376, lng: 21.3297953 },
    { lat: 48.6244311, lng: 21.3297752 },
    { lat: 48.6228389, lng: 21.33193 },
    { lat: 48.6221906, lng: 21.3311122 },
    { lat: 48.6218533, lng: 21.3308989 },
    { lat: 48.6217475, lng: 21.3305285 },
    { lat: 48.6214092, lng: 21.3289835 },
    { lat: 48.6203792, lng: 21.3252641 },
    { lat: 48.6199336, lng: 21.3228239 },
    { lat: 48.6200134, lng: 21.3220039 },
    { lat: 48.619873, lng: 21.3207139 },
    { lat: 48.6195587, lng: 21.3194815 },
    { lat: 48.6192914, lng: 21.3176167 },
    { lat: 48.6183953, lng: 21.3129489 },
    { lat: 48.6162169, lng: 21.305286 },
    { lat: 48.6158784, lng: 21.3038638 },
    { lat: 48.6157793, lng: 21.3037842 },
    { lat: 48.6152574, lng: 21.3008461 },
    { lat: 48.614951, lng: 21.2993702 },
    { lat: 48.614885, lng: 21.2993114 },
    { lat: 48.6147343, lng: 21.29773 },
    { lat: 48.6146569, lng: 21.2949805 },
    { lat: 48.6141793, lng: 21.2948576 },
    { lat: 48.6141376, lng: 21.2837857 },
    { lat: 48.613992, lng: 21.2794914 },
  ],
  VyšnáMyšľa: [
    { lat: 48.612575, lng: 21.3726123 },
    { lat: 48.6120796, lng: 21.3728982 },
    { lat: 48.6120728, lng: 21.3729773 },
    { lat: 48.6118816, lng: 21.3729398 },
    { lat: 48.6117999, lng: 21.3731808 },
    { lat: 48.6117431, lng: 21.373206 },
    { lat: 48.6116123, lng: 21.3730801 },
    { lat: 48.6115449, lng: 21.373122 },
    { lat: 48.6114074, lng: 21.373492 },
    { lat: 48.6112856, lng: 21.3732905 },
    { lat: 48.6109963, lng: 21.3735513 },
    { lat: 48.6109461, lng: 21.3735295 },
    { lat: 48.6107506, lng: 21.3731282 },
    { lat: 48.6106913, lng: 21.3732496 },
    { lat: 48.6103511, lng: 21.3735042 },
    { lat: 48.6099914, lng: 21.3732183 },
    { lat: 48.6099108, lng: 21.3734937 },
    { lat: 48.6096624, lng: 21.3736184 },
    { lat: 48.6095385, lng: 21.3739138 },
    { lat: 48.6093336, lng: 21.3738349 },
    { lat: 48.6091101, lng: 21.3740329 },
    { lat: 48.608763, lng: 21.3742066 },
    { lat: 48.6085935, lng: 21.3740354 },
    { lat: 48.6085005, lng: 21.3743497 },
    { lat: 48.6082244, lng: 21.3742234 },
    { lat: 48.6078718, lng: 21.3746236 },
    { lat: 48.6079545, lng: 21.3748641 },
    { lat: 48.6078687, lng: 21.3750089 },
    { lat: 48.6076217, lng: 21.3749482 },
    { lat: 48.6074108, lng: 21.3749779 },
    { lat: 48.6072851, lng: 21.3751369 },
    { lat: 48.6070859, lng: 21.3752468 },
    { lat: 48.6070225, lng: 21.3753823 },
    { lat: 48.6067555, lng: 21.3753717 },
    { lat: 48.6064559, lng: 21.3758575 },
    { lat: 48.6061569, lng: 21.3760169 },
    { lat: 48.6059954, lng: 21.3760709 },
    { lat: 48.6055429, lng: 21.375733 },
    { lat: 48.6054613, lng: 21.375788 },
    { lat: 48.6051554, lng: 21.3757146 },
    { lat: 48.6048637, lng: 21.3754479 },
    { lat: 48.6046849, lng: 21.3754142 },
    { lat: 48.6045632, lng: 21.3754065 },
    { lat: 48.6037941, lng: 21.3756953 },
    { lat: 48.6032995, lng: 21.3759366 },
    { lat: 48.6029647, lng: 21.37629 },
    { lat: 48.6028664, lng: 21.3766157 },
    { lat: 48.6026322, lng: 21.3768417 },
    { lat: 48.602495, lng: 21.3770736 },
    { lat: 48.6023548, lng: 21.3780225 },
    { lat: 48.6024862, lng: 21.3787343 },
    { lat: 48.6025483, lng: 21.3788151 },
    { lat: 48.6027706, lng: 21.3796248 },
    { lat: 48.6027871, lng: 21.3799483 },
    { lat: 48.6030774, lng: 21.380628 },
    { lat: 48.6030862, lng: 21.381149 },
    { lat: 48.6032039, lng: 21.3817347 },
    { lat: 48.6030846, lng: 21.3826346 },
    { lat: 48.6031136, lng: 21.383455 },
    { lat: 48.6030038, lng: 21.3841842 },
    { lat: 48.6029298, lng: 21.3859724 },
    { lat: 48.6027231, lng: 21.3860952 },
    { lat: 48.602678, lng: 21.3871112 },
    { lat: 48.6030623, lng: 21.3890542 },
    { lat: 48.603323, lng: 21.389684 },
    { lat: 48.604744, lng: 21.3892548 },
    { lat: 48.6048082, lng: 21.389259 },
    { lat: 48.6052654, lng: 21.389382 },
    { lat: 48.6064922, lng: 21.3903687 },
    { lat: 48.6070342, lng: 21.3914865 },
    { lat: 48.6072201, lng: 21.3916489 },
    { lat: 48.6078348, lng: 21.392525 },
    { lat: 48.6087186, lng: 21.3931418 },
    { lat: 48.6091703, lng: 21.3935731 },
    { lat: 48.6094652, lng: 21.3946004 },
    { lat: 48.6106197, lng: 21.3964148 },
    { lat: 48.611435, lng: 21.3962828 },
    { lat: 48.612143, lng: 21.3966972 },
    { lat: 48.6134873, lng: 21.3968377 },
    { lat: 48.6139247, lng: 21.3970503 },
    { lat: 48.6142422, lng: 21.3973652 },
    { lat: 48.6154085, lng: 21.3994956 },
    { lat: 48.6159664, lng: 21.3993359 },
    { lat: 48.6165455, lng: 21.398977 },
    { lat: 48.6171685, lng: 21.3990799 },
    { lat: 48.6175563, lng: 21.3988709 },
    { lat: 48.6186157, lng: 21.3989333 },
    { lat: 48.6189055, lng: 21.3990581 },
    { lat: 48.619575, lng: 21.4001556 },
    { lat: 48.6200528, lng: 21.4005206 },
    { lat: 48.6204113, lng: 21.4006578 },
    { lat: 48.6205693, lng: 21.4005568 },
    { lat: 48.621567, lng: 21.4006989 },
    { lat: 48.621899, lng: 21.4006195 },
    { lat: 48.6225835, lng: 21.4006327 },
    { lat: 48.6244975, lng: 21.4020701 },
    { lat: 48.6264669, lng: 21.403965 },
    { lat: 48.6295522, lng: 21.4058491 },
    { lat: 48.6301529, lng: 21.4065282 },
    { lat: 48.6324054, lng: 21.407352 },
    { lat: 48.6334489, lng: 21.4075129 },
    { lat: 48.633744, lng: 21.4084692 },
    { lat: 48.634724, lng: 21.4099112 },
    { lat: 48.6351382, lng: 21.4112857 },
    { lat: 48.6351471, lng: 21.4113154 },
    { lat: 48.6352424, lng: 21.411267 },
    { lat: 48.6353291, lng: 21.4113828 },
    { lat: 48.6354831, lng: 21.4112643 },
    { lat: 48.6357947, lng: 21.4112995 },
    { lat: 48.6360624, lng: 21.4111448 },
    { lat: 48.6361365, lng: 21.4110256 },
    { lat: 48.6362639, lng: 21.4110284 },
    { lat: 48.636322, lng: 21.4107929 },
    { lat: 48.6365089, lng: 21.4106369 },
    { lat: 48.6364707, lng: 21.4103744 },
    { lat: 48.6365693, lng: 21.4098266 },
    { lat: 48.6366851, lng: 21.4097868 },
    { lat: 48.6369372, lng: 21.4092542 },
    { lat: 48.6370096, lng: 21.4092718 },
    { lat: 48.6370952, lng: 21.4091589 },
    { lat: 48.6374753, lng: 21.409261 },
    { lat: 48.6377904, lng: 21.4091138 },
    { lat: 48.6379143, lng: 21.4089663 },
    { lat: 48.6379063, lng: 21.4088068 },
    { lat: 48.6380461, lng: 21.4087429 },
    { lat: 48.6381114, lng: 21.4085513 },
    { lat: 48.6386124, lng: 21.4084136 },
    { lat: 48.6386453, lng: 21.4085356 },
    { lat: 48.6387396, lng: 21.4085595 },
    { lat: 48.638891, lng: 21.4084735 },
    { lat: 48.6391142, lng: 21.4079525 },
    { lat: 48.6391922, lng: 21.4079967 },
    { lat: 48.6395742, lng: 21.4075976 },
    { lat: 48.639614, lng: 21.407654 },
    { lat: 48.63966, lng: 21.4075741 },
    { lat: 48.6397891, lng: 21.4076347 },
    { lat: 48.6400599, lng: 21.4073176 },
    { lat: 48.6401064, lng: 21.407408 },
    { lat: 48.6401697, lng: 21.4073393 },
    { lat: 48.6403128, lng: 21.4073859 },
    { lat: 48.6408244, lng: 21.4068027 },
    { lat: 48.6410782, lng: 21.4067077 },
    { lat: 48.6411466, lng: 21.4067401 },
    { lat: 48.6412165, lng: 21.4065539 },
    { lat: 48.6413234, lng: 21.4064783 },
    { lat: 48.6414569, lng: 21.4065031 },
    { lat: 48.6416276, lng: 21.4063735 },
    { lat: 48.6416716, lng: 21.4062659 },
    { lat: 48.6418259, lng: 21.4062715 },
    { lat: 48.6420514, lng: 21.406399 },
    { lat: 48.6422833, lng: 21.4062926 },
    { lat: 48.6423597, lng: 21.4061739 },
    { lat: 48.6424356, lng: 21.4062261 },
    { lat: 48.6425149, lng: 21.4061666 },
    { lat: 48.6425532, lng: 21.4060164 },
    { lat: 48.6426698, lng: 21.4059654 },
    { lat: 48.6427414, lng: 21.4058364 },
    { lat: 48.6428463, lng: 21.4058105 },
    { lat: 48.642903, lng: 21.4055924 },
    { lat: 48.6429494, lng: 21.4054854 },
    { lat: 48.6428996, lng: 21.4054361 },
    { lat: 48.6430198, lng: 21.4048629 },
    { lat: 48.6431477, lng: 21.4045074 },
    { lat: 48.6431369, lng: 21.4040341 },
    { lat: 48.643275, lng: 21.4036416 },
    { lat: 48.6431368, lng: 21.4030058 },
    { lat: 48.6434852, lng: 21.4026122 },
    { lat: 48.6434559, lng: 21.4024971 },
    { lat: 48.6432932, lng: 21.4022814 },
    { lat: 48.6432791, lng: 21.401919 },
    { lat: 48.6434357, lng: 21.4015868 },
    { lat: 48.6434608, lng: 21.4014075 },
    { lat: 48.6432513, lng: 21.401174 },
    { lat: 48.6433535, lng: 21.4007871 },
    { lat: 48.6433649, lng: 21.4004162 },
    { lat: 48.643236, lng: 21.4001645 },
    { lat: 48.6432453, lng: 21.4000664 },
    { lat: 48.6433201, lng: 21.3999713 },
    { lat: 48.6437411, lng: 21.3996336 },
    { lat: 48.6437993, lng: 21.3990116 },
    { lat: 48.6436545, lng: 21.3984968 },
    { lat: 48.6436514, lng: 21.398354 },
    { lat: 48.6440752, lng: 21.3979567 },
    { lat: 48.6442414, lng: 21.3978387 },
    { lat: 48.6443033, lng: 21.3978743 },
    { lat: 48.6444563, lng: 21.3978017 },
    { lat: 48.6447014, lng: 21.397567 },
    { lat: 48.6447034, lng: 21.3974434 },
    { lat: 48.6452142, lng: 21.3965711 },
    { lat: 48.6449869, lng: 21.3963826 },
    { lat: 48.6455795, lng: 21.3958361 },
    { lat: 48.6456567, lng: 21.3956316 },
    { lat: 48.6459933, lng: 21.3956348 },
    { lat: 48.6460505, lng: 21.3955124 },
    { lat: 48.6460716, lng: 21.3951694 },
    { lat: 48.646144, lng: 21.3950271 },
    { lat: 48.6465111, lng: 21.3951813 },
    { lat: 48.6466502, lng: 21.3947928 },
    { lat: 48.6469684, lng: 21.3947097 },
    { lat: 48.6471385, lng: 21.3943708 },
    { lat: 48.6473248, lng: 21.3942443 },
    { lat: 48.6482092, lng: 21.3930687 },
    { lat: 48.6490767, lng: 21.3917035 },
    { lat: 48.6492714, lng: 21.3915117 },
    { lat: 48.6495445, lng: 21.3915764 },
    { lat: 48.6501371, lng: 21.3903971 },
    { lat: 48.6501476, lng: 21.3900704 },
    { lat: 48.6499778, lng: 21.3894281 },
    { lat: 48.6502157, lng: 21.3889504 },
    { lat: 48.650626, lng: 21.3884047 },
    { lat: 48.6504746, lng: 21.3878595 },
    { lat: 48.6505103, lng: 21.3875439 },
    { lat: 48.6506496, lng: 21.3873145 },
    { lat: 48.6509511, lng: 21.3870089 },
    { lat: 48.6506864, lng: 21.3866991 },
    { lat: 48.6507356, lng: 21.3864979 },
    { lat: 48.6507579, lng: 21.3858543 },
    { lat: 48.6506158, lng: 21.385391 },
    { lat: 48.650702, lng: 21.3849919 },
    { lat: 48.6509482, lng: 21.3849129 },
    { lat: 48.6510226, lng: 21.3843171 },
    { lat: 48.6512538, lng: 21.3842152 },
    { lat: 48.6518371, lng: 21.3819288 },
    { lat: 48.6520975, lng: 21.381268 },
    { lat: 48.6521903, lng: 21.3813599 },
    { lat: 48.6523705, lng: 21.3809117 },
    { lat: 48.6522887, lng: 21.3808087 },
    { lat: 48.6523679, lng: 21.3805655 },
    { lat: 48.6524441, lng: 21.3805493 },
    { lat: 48.6524317, lng: 21.3803619 },
    { lat: 48.6524949, lng: 21.3801423 },
    { lat: 48.6525699, lng: 21.3800859 },
    { lat: 48.6526468, lng: 21.3801433 },
    { lat: 48.65279, lng: 21.3799243 },
    { lat: 48.6528915, lng: 21.3783101 },
    { lat: 48.6529012, lng: 21.3781465 },
    { lat: 48.6526581, lng: 21.3781064 },
    { lat: 48.6523814, lng: 21.3781523 },
    { lat: 48.6519543, lng: 21.3786227 },
    { lat: 48.651387, lng: 21.3786549 },
    { lat: 48.6506777, lng: 21.3785612 },
    { lat: 48.6496416, lng: 21.37801 },
    { lat: 48.6489094, lng: 21.3780982 },
    { lat: 48.6487149, lng: 21.3782577 },
    { lat: 48.6486267, lng: 21.3785086 },
    { lat: 48.648558, lng: 21.3791044 },
    { lat: 48.6482516, lng: 21.3793946 },
    { lat: 48.647843, lng: 21.379658 },
    { lat: 48.6476542, lng: 21.3796993 },
    { lat: 48.6473669, lng: 21.3795395 },
    { lat: 48.6471709, lng: 21.3792674 },
    { lat: 48.6471066, lng: 21.3788515 },
    { lat: 48.6471307, lng: 21.3781919 },
    { lat: 48.6473256, lng: 21.3773676 },
    { lat: 48.6466089, lng: 21.3775242 },
    { lat: 48.6462901, lng: 21.3778868 },
    { lat: 48.645527, lng: 21.3780352 },
    { lat: 48.6448848, lng: 21.3778985 },
    { lat: 48.6445485, lng: 21.377488 },
    { lat: 48.6439268, lng: 21.3764179 },
    { lat: 48.6437591, lng: 21.376376 },
    { lat: 48.6435435, lng: 21.3764686 },
    { lat: 48.643332, lng: 21.3767712 },
    { lat: 48.6432561, lng: 21.3772763 },
    { lat: 48.6427029, lng: 21.3780502 },
    { lat: 48.6422172, lng: 21.378548 },
    { lat: 48.6419706, lng: 21.3786199 },
    { lat: 48.6415881, lng: 21.3786264 },
    { lat: 48.641183, lng: 21.3782497 },
    { lat: 48.6410221, lng: 21.3779098 },
    { lat: 48.6410622, lng: 21.3775922 },
    { lat: 48.6413451, lng: 21.3773456 },
    { lat: 48.6413713, lng: 21.3772463 },
    { lat: 48.6411971, lng: 21.3764658 },
    { lat: 48.6406166, lng: 21.3764284 },
    { lat: 48.6400277, lng: 21.3767585 },
    { lat: 48.6395813, lng: 21.3768433 },
    { lat: 48.6391346, lng: 21.3765414 },
    { lat: 48.6385574, lng: 21.3756852 },
    { lat: 48.638215, lng: 21.3755975 },
    { lat: 48.6376004, lng: 21.3760044 },
    { lat: 48.6370444, lng: 21.3767535 },
    { lat: 48.6366957, lng: 21.3770562 },
    { lat: 48.636404, lng: 21.3772045 },
    { lat: 48.6361465, lng: 21.3772455 },
    { lat: 48.635865, lng: 21.3772006 },
    { lat: 48.6355365, lng: 21.3770184 },
    { lat: 48.6350721, lng: 21.377103 },
    { lat: 48.6346443, lng: 21.3769496 },
    { lat: 48.6343375, lng: 21.3769282 },
    { lat: 48.6340492, lng: 21.3770318 },
    { lat: 48.633206, lng: 21.3770582 },
    { lat: 48.6326307, lng: 21.376975 },
    { lat: 48.6318239, lng: 21.3769777 },
    { lat: 48.630819, lng: 21.3773125 },
    { lat: 48.6302919, lng: 21.3771844 },
    { lat: 48.6299374, lng: 21.376845 },
    { lat: 48.6294645, lng: 21.3768395 },
    { lat: 48.6278786, lng: 21.3770904 },
    { lat: 48.6276151, lng: 21.3770084 },
    { lat: 48.6273696, lng: 21.3768177 },
    { lat: 48.6272308, lng: 21.3766148 },
    { lat: 48.627175, lng: 21.3762184 },
    { lat: 48.626876, lng: 21.376036 },
    { lat: 48.6266383, lng: 21.3759956 },
    { lat: 48.6265604, lng: 21.3760554 },
    { lat: 48.6264349, lng: 21.3768671 },
    { lat: 48.6264978, lng: 21.3773153 },
    { lat: 48.6264505, lng: 21.3777265 },
    { lat: 48.6262566, lng: 21.3781659 },
    { lat: 48.6261288, lng: 21.3786729 },
    { lat: 48.6258166, lng: 21.3794458 },
    { lat: 48.625721, lng: 21.3794886 },
    { lat: 48.6255505, lng: 21.3793551 },
    { lat: 48.6254513, lng: 21.3792398 },
    { lat: 48.6253652, lng: 21.3788771 },
    { lat: 48.6253169, lng: 21.3788769 },
    { lat: 48.6245987, lng: 21.3797392 },
    { lat: 48.6243594, lng: 21.3805057 },
    { lat: 48.6243802, lng: 21.3833492 },
    { lat: 48.6243526, lng: 21.3834454 },
    { lat: 48.6241449, lng: 21.3837928 },
    { lat: 48.6237776, lng: 21.3840105 },
    { lat: 48.6234902, lng: 21.3839959 },
    { lat: 48.6232421, lng: 21.3837883 },
    { lat: 48.6223094, lng: 21.3827358 },
    { lat: 48.621891, lng: 21.3824833 },
    { lat: 48.6215759, lng: 21.3823695 },
    { lat: 48.6209096, lng: 21.3824049 },
    { lat: 48.6185039, lng: 21.3818851 },
    { lat: 48.617915, lng: 21.3816072 },
    { lat: 48.6174704, lng: 21.3807728 },
    { lat: 48.617367, lng: 21.3802172 },
    { lat: 48.6169895, lng: 21.3798257 },
    { lat: 48.6164033, lng: 21.3795981 },
    { lat: 48.6161223, lng: 21.3799138 },
    { lat: 48.6155788, lng: 21.3800984 },
    { lat: 48.615112, lng: 21.3804498 },
    { lat: 48.6145498, lng: 21.3805763 },
    { lat: 48.614274, lng: 21.380419 },
    { lat: 48.6139692, lng: 21.3797853 },
    { lat: 48.614011, lng: 21.3789261 },
    { lat: 48.6141614, lng: 21.3775222 },
    { lat: 48.6137343, lng: 21.3758812 },
    { lat: 48.6136667, lng: 21.3756963 },
    { lat: 48.6133843, lng: 21.3752802 },
    { lat: 48.6129936, lng: 21.3742591 },
    { lat: 48.6130231, lng: 21.3737073 },
    { lat: 48.6129598, lng: 21.3727614 },
    { lat: 48.6128935, lng: 21.3724594 },
    { lat: 48.6126817, lng: 21.3725599 },
    { lat: 48.612575, lng: 21.3726123 },
  ],
  Hosťovce: [
    { lat: 48.58284, lng: 20.836359 },
    { lat: 48.582651, lng: 20.836772 },
    { lat: 48.582426, lng: 20.837161 },
    { lat: 48.581441, lng: 20.838462 },
    { lat: 48.580843, lng: 20.838977 },
    { lat: 48.580309, lng: 20.839522 },
    { lat: 48.580243, lng: 20.839589 },
    { lat: 48.58018, lng: 20.839485 },
    { lat: 48.579298, lng: 20.838044 },
    { lat: 48.578589, lng: 20.837213 },
    { lat: 48.577732, lng: 20.836637 },
    { lat: 48.577656, lng: 20.836563 },
    { lat: 48.577049, lng: 20.835982 },
    { lat: 48.5767553, lng: 20.8356403 },
    { lat: 48.576499, lng: 20.835387 },
    { lat: 48.576264, lng: 20.835738 },
    { lat: 48.57624, lng: 20.835775 },
    { lat: 48.57619, lng: 20.835681 },
    { lat: 48.5758205, lng: 20.8360881 },
    { lat: 48.5751163, lng: 20.8368326 },
    { lat: 48.5750796, lng: 20.8368715 },
    { lat: 48.5742512, lng: 20.8377557 },
    { lat: 48.5742099, lng: 20.8377998 },
    { lat: 48.5750721, lng: 20.8397412 },
    { lat: 48.5760192, lng: 20.8418683 },
    { lat: 48.5761564, lng: 20.8421909 },
    { lat: 48.5762066, lng: 20.8423062 },
    { lat: 48.576256, lng: 20.8424 },
    { lat: 48.5763227, lng: 20.8425081 },
    { lat: 48.5767525, lng: 20.8431533 },
    { lat: 48.5772979, lng: 20.8439832 },
    { lat: 48.5779412, lng: 20.8449444 },
    { lat: 48.57858, lng: 20.8458996 },
    { lat: 48.5789138, lng: 20.8464045 },
    { lat: 48.5795772, lng: 20.8473927 },
    { lat: 48.5796412, lng: 20.8474872 },
    { lat: 48.5803795, lng: 20.8485966 },
    { lat: 48.5816331, lng: 20.8504362 },
    { lat: 48.5814413, lng: 20.8504051 },
    { lat: 48.580018, lng: 20.8500509 },
    { lat: 48.5799567, lng: 20.8500351 },
    { lat: 48.5798011, lng: 20.8499968 },
    { lat: 48.5787474, lng: 20.849736 },
    { lat: 48.5780039, lng: 20.849556 },
    { lat: 48.577123, lng: 20.8493418 },
    { lat: 48.5753347, lng: 20.8488998 },
    { lat: 48.5744402, lng: 20.8486824 },
    { lat: 48.57353, lng: 20.848457 },
    { lat: 48.5726607, lng: 20.8482419 },
    { lat: 48.5690688, lng: 20.8473542 },
    { lat: 48.5685924, lng: 20.8472335 },
    { lat: 48.5683926, lng: 20.8471909 },
    { lat: 48.5682922, lng: 20.8471675 },
    { lat: 48.568205, lng: 20.8471391 },
    { lat: 48.568151, lng: 20.847122 },
    { lat: 48.5681187, lng: 20.8471017 },
    { lat: 48.5680919, lng: 20.847078 },
    { lat: 48.5680255, lng: 20.8470148 },
    { lat: 48.5679143, lng: 20.8468772 },
    { lat: 48.5678139, lng: 20.8467526 },
    { lat: 48.5677405, lng: 20.8466493 },
    { lat: 48.5676417, lng: 20.8464642 },
    { lat: 48.5674319, lng: 20.8460257 },
    { lat: 48.5670621, lng: 20.8453266 },
    { lat: 48.5669951, lng: 20.8452153 },
    { lat: 48.5669254, lng: 20.8451327 },
    { lat: 48.5668688, lng: 20.8450773 },
    { lat: 48.5668046, lng: 20.8450414 },
    { lat: 48.5667464, lng: 20.8450201 },
    { lat: 48.5666597, lng: 20.8450198 },
    { lat: 48.5665895, lng: 20.8450399 },
    { lat: 48.5665165, lng: 20.8450791 },
    { lat: 48.5662871, lng: 20.8452811 },
    { lat: 48.5660178, lng: 20.8454999 },
    { lat: 48.5657856, lng: 20.8456865 },
    { lat: 48.5657834, lng: 20.8456908 },
    { lat: 48.5657797, lng: 20.8456965 },
    { lat: 48.5657464, lng: 20.8457271 },
    { lat: 48.5657191, lng: 20.8458162 },
    { lat: 48.5657196, lng: 20.8458943 },
    { lat: 48.565727, lng: 20.8459801 },
    { lat: 48.565878, lng: 20.846316 },
    { lat: 48.566041, lng: 20.846748 },
    { lat: 48.566103, lng: 20.846952 },
    { lat: 48.566162, lng: 20.847167 },
    { lat: 48.56624, lng: 20.847562 },
    { lat: 48.566449, lng: 20.848145 },
    { lat: 48.566499, lng: 20.848293 },
    { lat: 48.566612, lng: 20.848653 },
    { lat: 48.566661, lng: 20.84882 },
    { lat: 48.566722, lng: 20.84902 },
    { lat: 48.566805, lng: 20.849257 },
    { lat: 48.5669714, lng: 20.8497382 },
    { lat: 48.566976, lng: 20.851204 },
    { lat: 48.566984, lng: 20.85235 },
    { lat: 48.5669302, lng: 20.8532647 },
    { lat: 48.566976, lng: 20.85408 },
    { lat: 48.566889, lng: 20.854863 },
    { lat: 48.5701194, lng: 20.8614687 },
    { lat: 48.5701852, lng: 20.8614828 },
    { lat: 48.5700281, lng: 20.8632637 },
    { lat: 48.5699893, lng: 20.8643288 },
    { lat: 48.5698603, lng: 20.8649602 },
    { lat: 48.5698563, lng: 20.8652075 },
    { lat: 48.5698927, lng: 20.8655729 },
    { lat: 48.5701064, lng: 20.8662262 },
    { lat: 48.5701725, lng: 20.8667059 },
    { lat: 48.5702615, lng: 20.8677229 },
    { lat: 48.5702294, lng: 20.868265 },
    { lat: 48.5702759, lng: 20.8690284 },
    { lat: 48.5704612, lng: 20.8704865 },
    { lat: 48.5705028, lng: 20.8712364 },
    { lat: 48.5705416, lng: 20.8712555 },
    { lat: 48.5706333, lng: 20.8717008 },
    { lat: 48.5710292, lng: 20.8725411 },
    { lat: 48.5717586, lng: 20.8740098 },
    { lat: 48.572323, lng: 20.8747425 },
    { lat: 48.5723926, lng: 20.8749369 },
    { lat: 48.5726589, lng: 20.8745998 },
    { lat: 48.572918, lng: 20.8744026 },
    { lat: 48.5730388, lng: 20.8743839 },
    { lat: 48.5732873, lng: 20.8745025 },
    { lat: 48.5735105, lng: 20.8747104 },
    { lat: 48.5738886, lng: 20.8748043 },
    { lat: 48.5739803, lng: 20.8749209 },
    { lat: 48.5739523, lng: 20.8752878 },
    { lat: 48.5740448, lng: 20.8753925 },
    { lat: 48.5743104, lng: 20.8755137 },
    { lat: 48.5745072, lng: 20.8754482 },
    { lat: 48.5748256, lng: 20.8754725 },
    { lat: 48.5749754, lng: 20.8753076 },
    { lat: 48.575017, lng: 20.8750776 },
    { lat: 48.5749715, lng: 20.874838 },
    { lat: 48.575062, lng: 20.8742295 },
    { lat: 48.5749079, lng: 20.8740033 },
    { lat: 48.5748754, lng: 20.8738715 },
    { lat: 48.5750004, lng: 20.8738451 },
    { lat: 48.5750758, lng: 20.8737453 },
    { lat: 48.57504, lng: 20.8734527 },
    { lat: 48.5751329, lng: 20.873272 },
    { lat: 48.5753252, lng: 20.8734056 },
    { lat: 48.5754249, lng: 20.8733879 },
    { lat: 48.575446, lng: 20.8732483 },
    { lat: 48.5753818, lng: 20.8730468 },
    { lat: 48.5756172, lng: 20.8728525 },
    { lat: 48.5757596, lng: 20.8729399 },
    { lat: 48.5761591, lng: 20.8729038 },
    { lat: 48.5766862, lng: 20.8732373 },
    { lat: 48.5768624, lng: 20.8732229 },
    { lat: 48.5769496, lng: 20.8733704 },
    { lat: 48.5770042, lng: 20.8738421 },
    { lat: 48.5773721, lng: 20.873737 },
    { lat: 48.5775368, lng: 20.8737512 },
    { lat: 48.5774744, lng: 20.8739957 },
    { lat: 48.577504, lng: 20.8741377 },
    { lat: 48.5779796, lng: 20.8739933 },
    { lat: 48.5782381, lng: 20.8741448 },
    { lat: 48.5783676, lng: 20.8744035 },
    { lat: 48.5786907, lng: 20.8743414 },
    { lat: 48.578781, lng: 20.8743812 },
    { lat: 48.5789369, lng: 20.8750527 },
    { lat: 48.578852, lng: 20.8751375 },
    { lat: 48.5787415, lng: 20.8751326 },
    { lat: 48.5786839, lng: 20.8752292 },
    { lat: 48.5786782, lng: 20.8754895 },
    { lat: 48.5791158, lng: 20.8761718 },
    { lat: 48.5793869, lng: 20.8759651 },
    { lat: 48.5794824, lng: 20.8760266 },
    { lat: 48.5795015, lng: 20.8762199 },
    { lat: 48.5797201, lng: 20.8762235 },
    { lat: 48.5798784, lng: 20.8759232 },
    { lat: 48.5802761, lng: 20.8758663 },
    { lat: 48.5805464, lng: 20.8756999 },
    { lat: 48.5806024, lng: 20.875661 },
    { lat: 48.5806247, lng: 20.8756251 },
    { lat: 48.5808278, lng: 20.8756399 },
    { lat: 48.5821574, lng: 20.875408 },
    { lat: 48.5839653, lng: 20.8749838 },
    { lat: 48.5842691, lng: 20.873314 },
    { lat: 48.5845575, lng: 20.8729264 },
    { lat: 48.5846945, lng: 20.8730743 },
    { lat: 48.5847455, lng: 20.8730202 },
    { lat: 48.5846202, lng: 20.8729087 },
    { lat: 48.5847334, lng: 20.8728345 },
    { lat: 48.5846921, lng: 20.8726816 },
    { lat: 48.584744, lng: 20.8726132 },
    { lat: 48.5847718, lng: 20.8726754 },
    { lat: 48.5849844, lng: 20.8726346 },
    { lat: 48.5850707, lng: 20.8727235 },
    { lat: 48.5850564, lng: 20.8728281 },
    { lat: 48.584937, lng: 20.8729587 },
    { lat: 48.585023, lng: 20.8731063 },
    { lat: 48.5850953, lng: 20.8729435 },
    { lat: 48.5852516, lng: 20.8730028 },
    { lat: 48.5851441, lng: 20.8732536 },
    { lat: 48.5851976, lng: 20.8732711 },
    { lat: 48.5851977, lng: 20.8733728 },
    { lat: 48.5852444, lng: 20.8733855 },
    { lat: 48.5853037, lng: 20.8735398 },
    { lat: 48.5853425, lng: 20.8737818 },
    { lat: 48.5860774, lng: 20.8739105 },
    { lat: 48.5863139, lng: 20.8735834 },
    { lat: 48.586395, lng: 20.8735558 },
    { lat: 48.5865535, lng: 20.8737461 },
    { lat: 48.5868239, lng: 20.8737566 },
    { lat: 48.5869796, lng: 20.8733381 },
    { lat: 48.5872261, lng: 20.8732062 },
    { lat: 48.5872787, lng: 20.873035 },
    { lat: 48.5873568, lng: 20.8730417 },
    { lat: 48.5874607, lng: 20.8729227 },
    { lat: 48.5875542, lng: 20.8726963 },
    { lat: 48.5876742, lng: 20.8726806 },
    { lat: 48.5877504, lng: 20.8725722 },
    { lat: 48.5877529, lng: 20.8724181 },
    { lat: 48.587842, lng: 20.8721633 },
    { lat: 48.5881774, lng: 20.8719612 },
    { lat: 48.5886989, lng: 20.8715123 },
    { lat: 48.5887091, lng: 20.8712555 },
    { lat: 48.5887672, lng: 20.8711397 },
    { lat: 48.5890077, lng: 20.871032 },
    { lat: 48.5893179, lng: 20.8707863 },
    { lat: 48.589602, lng: 20.8705341 },
    { lat: 48.5897499, lng: 20.8703224 },
    { lat: 48.5896637, lng: 20.8701321 },
    { lat: 48.5896358, lng: 20.8698843 },
    { lat: 48.5897061, lng: 20.8689426 },
    { lat: 48.5898658, lng: 20.8683545 },
    { lat: 48.5901778, lng: 20.8678373 },
    { lat: 48.5905171, lng: 20.8674197 },
    { lat: 48.591103, lng: 20.8669462 },
    { lat: 48.5905769, lng: 20.8639854 },
    { lat: 48.5903056, lng: 20.8628752 },
    { lat: 48.5893875, lng: 20.8605322 },
    { lat: 48.5886448, lng: 20.858453 },
    { lat: 48.5885482, lng: 20.858388 },
    { lat: 48.5885811, lng: 20.8582336 },
    { lat: 48.5888189, lng: 20.857815 },
    { lat: 48.5887186, lng: 20.8574232 },
    { lat: 48.5885348, lng: 20.857088 },
    { lat: 48.5880903, lng: 20.8567968 },
    { lat: 48.5877405, lng: 20.8560512 },
    { lat: 48.5875802, lng: 20.8560769 },
    { lat: 48.5872367, lng: 20.8556782 },
    { lat: 48.5872037, lng: 20.8554742 },
    { lat: 48.5870547, lng: 20.8550993 },
    { lat: 48.5869535, lng: 20.8550031 },
    { lat: 48.5868458, lng: 20.8539733 },
    { lat: 48.5869713, lng: 20.8532011 },
    { lat: 48.586951, lng: 20.8530701 },
    { lat: 48.5868418, lng: 20.8529425 },
    { lat: 48.586978, lng: 20.8524159 },
    { lat: 48.5872263, lng: 20.8520451 },
    { lat: 48.585368, lng: 20.8485635 },
    { lat: 48.5846602, lng: 20.8467499 },
    { lat: 48.5841058, lng: 20.8444194 },
    { lat: 48.5829963, lng: 20.8389681 },
    { lat: 48.58284, lng: 20.836359 },
  ],
  NižnýKlátov: [
    { lat: 48.750157, lng: 21.146537 },
    { lat: 48.7498291, lng: 21.1460697 },
    { lat: 48.7488104, lng: 21.1441532 },
    { lat: 48.7484832, lng: 21.1437737 },
    { lat: 48.7481137, lng: 21.1438726 },
    { lat: 48.747888, lng: 21.1438491 },
    { lat: 48.7469394, lng: 21.1421597 },
    { lat: 48.7454254, lng: 21.140038 },
    { lat: 48.7449834, lng: 21.1390247 },
    { lat: 48.7440772, lng: 21.1373962 },
    { lat: 48.7426304, lng: 21.1345902 },
    { lat: 48.7417731, lng: 21.133225 },
    { lat: 48.7414558, lng: 21.1330507 },
    { lat: 48.7412096, lng: 21.1327035 },
    { lat: 48.7410091, lng: 21.1320314 },
    { lat: 48.7408927, lng: 21.1314522 },
    { lat: 48.7404714, lng: 21.1315169 },
    { lat: 48.7402095, lng: 21.1317717 },
    { lat: 48.7400426, lng: 21.1312359 },
    { lat: 48.7397416, lng: 21.1305454 },
    { lat: 48.7396566, lng: 21.1301466 },
    { lat: 48.7388264, lng: 21.1301894 },
    { lat: 48.7378698, lng: 21.1286078 },
    { lat: 48.7376984, lng: 21.1281768 },
    { lat: 48.7375264, lng: 21.1280424 },
    { lat: 48.7371149, lng: 21.1278451 },
    { lat: 48.7368899, lng: 21.1279553 },
    { lat: 48.7367252, lng: 21.128254 },
    { lat: 48.7363809, lng: 21.1284685 },
    { lat: 48.7361326, lng: 21.1270394 },
    { lat: 48.735909, lng: 21.1252257 },
    { lat: 48.7357238, lng: 21.1223248 },
    { lat: 48.7358014, lng: 21.1213764 },
    { lat: 48.7359454, lng: 21.121092 },
    { lat: 48.7360359, lng: 21.1210697 },
    { lat: 48.7361355, lng: 21.1197902 },
    { lat: 48.7361268, lng: 21.1197638 },
    { lat: 48.7358596, lng: 21.1196818 },
    { lat: 48.7358019, lng: 21.1196707 },
    { lat: 48.7350568, lng: 21.1203378 },
    { lat: 48.7349738, lng: 21.1204844 },
    { lat: 48.7348295, lng: 21.1210729 },
    { lat: 48.7344096, lng: 21.1213857 },
    { lat: 48.7340815, lng: 21.1217916 },
    { lat: 48.7343127, lng: 21.1237367 },
    { lat: 48.7341777, lng: 21.1243723 },
    { lat: 48.733937, lng: 21.1250473 },
    { lat: 48.733767, lng: 21.1255703 },
    { lat: 48.7337912, lng: 21.1260752 },
    { lat: 48.7335505, lng: 21.1263281 },
    { lat: 48.7333484, lng: 21.1263796 },
    { lat: 48.7331103, lng: 21.1266794 },
    { lat: 48.7323059, lng: 21.128897 },
    { lat: 48.7321844, lng: 21.1289697 },
    { lat: 48.7319382, lng: 21.1292672 },
    { lat: 48.7318211, lng: 21.1295039 },
    { lat: 48.7317734, lng: 21.1294974 },
    { lat: 48.731681, lng: 21.1296297 },
    { lat: 48.7316636, lng: 21.1297932 },
    { lat: 48.7312822, lng: 21.1300442 },
    { lat: 48.7310375, lng: 21.1305188 },
    { lat: 48.7309549, lng: 21.1308931 },
    { lat: 48.7308378, lng: 21.1310286 },
    { lat: 48.7303466, lng: 21.1321451 },
    { lat: 48.7297089, lng: 21.132859 },
    { lat: 48.7291159, lng: 21.1337073 },
    { lat: 48.7287212, lng: 21.1344919 },
    { lat: 48.7281941, lng: 21.1352958 },
    { lat: 48.7280839, lng: 21.1356475 },
    { lat: 48.7277099, lng: 21.1376426 },
    { lat: 48.7276806, lng: 21.1378557 },
    { lat: 48.7277173, lng: 21.1379864 },
    { lat: 48.7276679, lng: 21.138376 },
    { lat: 48.7276228, lng: 21.1384114 },
    { lat: 48.7276905, lng: 21.1388678 },
    { lat: 48.7276011, lng: 21.1391032 },
    { lat: 48.7273539, lng: 21.1394345 },
    { lat: 48.7272524, lng: 21.1397817 },
    { lat: 48.7272226, lng: 21.140259 },
    { lat: 48.7271679, lng: 21.1404127 },
    { lat: 48.7267567, lng: 21.1408234 },
    { lat: 48.7263952, lng: 21.1413424 },
    { lat: 48.7258446, lng: 21.1423233 },
    { lat: 48.7252421, lng: 21.1429824 },
    { lat: 48.7248867, lng: 21.1437006 },
    { lat: 48.7248052, lng: 21.1443171 },
    { lat: 48.7248432, lng: 21.1448084 },
    { lat: 48.7247295, lng: 21.1456123 },
    { lat: 48.7247892, lng: 21.145968 },
    { lat: 48.724926, lng: 21.1462354 },
    { lat: 48.7248124, lng: 21.1465203 },
    { lat: 48.7247385, lng: 21.1469256 },
    { lat: 48.7247108, lng: 21.1476787 },
    { lat: 48.7246455, lng: 21.1481372 },
    { lat: 48.7246474, lng: 21.148449 },
    { lat: 48.7247542, lng: 21.1487952 },
    { lat: 48.7248625, lng: 21.1488066 },
    { lat: 48.7248895, lng: 21.1495111 },
    { lat: 48.7247856, lng: 21.1497842 },
    { lat: 48.7245559, lng: 21.1507684 },
    { lat: 48.7243599, lng: 21.1511152 },
    { lat: 48.7242461, lng: 21.151609 },
    { lat: 48.7241347, lng: 21.1515771 },
    { lat: 48.724104, lng: 21.1517394 },
    { lat: 48.7240903, lng: 21.152161 },
    { lat: 48.7239916, lng: 21.1525296 },
    { lat: 48.7240024, lng: 21.1533273 },
    { lat: 48.7240913, lng: 21.1542783 },
    { lat: 48.72421, lng: 21.1546875 },
    { lat: 48.7242042, lng: 21.1550679 },
    { lat: 48.7240587, lng: 21.1551002 },
    { lat: 48.7240134, lng: 21.1558321 },
    { lat: 48.7239393, lng: 21.1562267 },
    { lat: 48.7237981, lng: 21.1566362 },
    { lat: 48.7237954, lng: 21.1567968 },
    { lat: 48.7235497, lng: 21.1571083 },
    { lat: 48.7234358, lng: 21.1574527 },
    { lat: 48.7233282, lng: 21.1586372 },
    { lat: 48.7232667, lng: 21.158616 },
    { lat: 48.7232375, lng: 21.1587592 },
    { lat: 48.723302, lng: 21.1587703 },
    { lat: 48.7233084, lng: 21.1593975 },
    { lat: 48.7233683, lng: 21.1594416 },
    { lat: 48.7234097, lng: 21.1593638 },
    { lat: 48.7235477, lng: 21.1600546 },
    { lat: 48.7235451, lng: 21.1607548 },
    { lat: 48.7234877, lng: 21.1612477 },
    { lat: 48.7235328, lng: 21.1617859 },
    { lat: 48.7235223, lng: 21.1623773 },
    { lat: 48.7235685, lng: 21.1626302 },
    { lat: 48.7237177, lng: 21.1628646 },
    { lat: 48.7237799, lng: 21.1638685 },
    { lat: 48.7239002, lng: 21.1642188 },
    { lat: 48.7238762, lng: 21.1644882 },
    { lat: 48.7237005, lng: 21.1650527 },
    { lat: 48.7235847, lng: 21.1652668 },
    { lat: 48.7233901, lng: 21.1662657 },
    { lat: 48.7233651, lng: 21.1666144 },
    { lat: 48.7234453, lng: 21.1666973 },
    { lat: 48.7234067, lng: 21.1669371 },
    { lat: 48.7231322, lng: 21.1675426 },
    { lat: 48.7231341, lng: 21.1677418 },
    { lat: 48.7228107, lng: 21.1685839 },
    { lat: 48.7227001, lng: 21.1689993 },
    { lat: 48.7227228, lng: 21.1692176 },
    { lat: 48.7226779, lng: 21.1692216 },
    { lat: 48.722734, lng: 21.1693259 },
    { lat: 48.722741, lng: 21.1693321 },
    { lat: 48.7228082, lng: 21.1693611 },
    { lat: 48.7228954, lng: 21.1695234 },
    { lat: 48.7229323, lng: 21.1694744 },
    { lat: 48.7231511, lng: 21.1697042 },
    { lat: 48.7232672, lng: 21.1699585 },
    { lat: 48.72345, lng: 21.169976 },
    { lat: 48.723475, lng: 21.169981 },
    { lat: 48.723538, lng: 21.169994 },
    { lat: 48.723564, lng: 21.169998 },
    { lat: 48.723586, lng: 21.170003 },
    { lat: 48.723776, lng: 21.169606 },
    { lat: 48.723782, lng: 21.16961 },
    { lat: 48.723798, lng: 21.169618 },
    { lat: 48.723811, lng: 21.169562 },
    { lat: 48.723876, lng: 21.169307 },
    { lat: 48.723864, lng: 21.169266 },
    { lat: 48.723907, lng: 21.16919 },
    { lat: 48.723953, lng: 21.169107 },
    { lat: 48.724029, lng: 21.16894 },
    { lat: 48.724035, lng: 21.168801 },
    { lat: 48.72517, lng: 21.169113 },
    { lat: 48.725538, lng: 21.16926 },
    { lat: 48.725979, lng: 21.169155 },
    { lat: 48.726243, lng: 21.169462 },
    { lat: 48.726368, lng: 21.169585 },
    { lat: 48.726449, lng: 21.169681 },
    { lat: 48.726492, lng: 21.169822 },
    { lat: 48.726642, lng: 21.169818 },
    { lat: 48.727119, lng: 21.170269 },
    { lat: 48.727222, lng: 21.170238 },
    { lat: 48.727292, lng: 21.170344 },
    { lat: 48.727983, lng: 21.170428 },
    { lat: 48.728282, lng: 21.17036 },
    { lat: 48.728395, lng: 21.170395 },
    { lat: 48.728456, lng: 21.170337 },
    { lat: 48.728649, lng: 21.170358 },
    { lat: 48.728747, lng: 21.170419 },
    { lat: 48.728877, lng: 21.170399 },
    { lat: 48.729568, lng: 21.171035 },
    { lat: 48.730233, lng: 21.171769 },
    { lat: 48.730334, lng: 21.171795 },
    { lat: 48.7303, lng: 21.172265 },
    { lat: 48.730314, lng: 21.172401 },
    { lat: 48.730197, lng: 21.173274 },
    { lat: 48.730203, lng: 21.174057 },
    { lat: 48.730917, lng: 21.174404 },
    { lat: 48.730897, lng: 21.175178 },
    { lat: 48.730871, lng: 21.175738 },
    { lat: 48.730869, lng: 21.176006 },
    { lat: 48.731047, lng: 21.17637 },
    { lat: 48.731124, lng: 21.176336 },
    { lat: 48.731196, lng: 21.176303 },
    { lat: 48.731534, lng: 21.174794 },
    { lat: 48.731774, lng: 21.173483 },
    { lat: 48.731968, lng: 21.173573 },
    { lat: 48.732162, lng: 21.173669 },
    { lat: 48.732606, lng: 21.173881 },
    { lat: 48.732798, lng: 21.173956 },
    { lat: 48.733049, lng: 21.173185 },
    { lat: 48.733237, lng: 21.172529 },
    { lat: 48.733037, lng: 21.172479 },
    { lat: 48.732872, lng: 21.172387 },
    { lat: 48.732519, lng: 21.17229 },
    { lat: 48.732262, lng: 21.172162 },
    { lat: 48.732239, lng: 21.171835 },
    { lat: 48.731956, lng: 21.171192 },
    { lat: 48.73182, lng: 21.170463 },
    { lat: 48.731745, lng: 21.169879 },
    { lat: 48.731713, lng: 21.169709 },
    { lat: 48.731825, lng: 21.16932 },
    { lat: 48.732084, lng: 21.1689 },
    { lat: 48.73215, lng: 21.168675 },
    { lat: 48.732247, lng: 21.168477 },
    { lat: 48.732818, lng: 21.167319 },
    { lat: 48.73338, lng: 21.167232 },
    { lat: 48.734257, lng: 21.167097 },
    { lat: 48.735221, lng: 21.165978 },
    { lat: 48.735596, lng: 21.165542 },
    { lat: 48.735364, lng: 21.165107 },
    { lat: 48.735217, lng: 21.164786 },
    { lat: 48.73514, lng: 21.164621 },
    { lat: 48.735449, lng: 21.164277 },
    { lat: 48.735752, lng: 21.163936 },
    { lat: 48.736067, lng: 21.163574 },
    { lat: 48.736194, lng: 21.163338 },
    { lat: 48.736335, lng: 21.16321 },
    { lat: 48.736851, lng: 21.162928 },
    { lat: 48.737005, lng: 21.162851 },
    { lat: 48.737221, lng: 21.16267 },
    { lat: 48.73739, lng: 21.162552 },
    { lat: 48.737589, lng: 21.162515 },
    { lat: 48.737748, lng: 21.162528 },
    { lat: 48.737962, lng: 21.162506 },
    { lat: 48.738157, lng: 21.162462 },
    { lat: 48.73856, lng: 21.162269 },
    { lat: 48.738855, lng: 21.162155 },
    { lat: 48.73902, lng: 21.162041 },
    { lat: 48.739196, lng: 21.161887 },
    { lat: 48.739223, lng: 21.161879 },
    { lat: 48.739258, lng: 21.16184 },
    { lat: 48.739647, lng: 21.161461 },
    { lat: 48.739798, lng: 21.161294 },
    { lat: 48.740011, lng: 21.161061 },
    { lat: 48.740371, lng: 21.160556 },
    { lat: 48.740587, lng: 21.160226 },
    { lat: 48.741026, lng: 21.159503 },
    { lat: 48.741385, lng: 21.158643 },
    { lat: 48.741634, lng: 21.158258 },
    { lat: 48.741904, lng: 21.158 },
    { lat: 48.741997, lng: 21.157855 },
    { lat: 48.742081, lng: 21.157459 },
    { lat: 48.742165, lng: 21.157048 },
    { lat: 48.742134, lng: 21.156771 },
    { lat: 48.742146, lng: 21.156551 },
    { lat: 48.742556, lng: 21.156402 },
    { lat: 48.74285, lng: 21.156195 },
    { lat: 48.743185, lng: 21.155959 },
    { lat: 48.743351, lng: 21.155874 },
    { lat: 48.743719, lng: 21.155686 },
    { lat: 48.743981, lng: 21.155365 },
    { lat: 48.744302, lng: 21.154813 },
    { lat: 48.744557, lng: 21.15451 },
    { lat: 48.744948, lng: 21.154168 },
    { lat: 48.745446, lng: 21.153713 },
    { lat: 48.745788, lng: 21.153395 },
    { lat: 48.74604, lng: 21.15313 },
    { lat: 48.746279, lng: 21.152718 },
    { lat: 48.746496, lng: 21.152558 },
    { lat: 48.746685, lng: 21.152414 },
    { lat: 48.746826, lng: 21.152249 },
    { lat: 48.746988, lng: 21.151976 },
    { lat: 48.747141, lng: 21.151731 },
    { lat: 48.747249, lng: 21.151509 },
    { lat: 48.747471, lng: 21.151301 },
    { lat: 48.747495, lng: 21.151227 },
    { lat: 48.747593, lng: 21.150715 },
    { lat: 48.747774, lng: 21.150138 },
    { lat: 48.748048, lng: 21.149562 },
    { lat: 48.748241, lng: 21.14932 },
    { lat: 48.748507, lng: 21.149003 },
    { lat: 48.748694, lng: 21.148768 },
    { lat: 48.748795, lng: 21.148532 },
    { lat: 48.748981, lng: 21.148389 },
    { lat: 48.74903, lng: 21.148322 },
    { lat: 48.749055, lng: 21.148227 },
    { lat: 48.749174, lng: 21.148193 },
    { lat: 48.749255, lng: 21.147979 },
    { lat: 48.749304, lng: 21.147738 },
    { lat: 48.749356, lng: 21.147637 },
    { lat: 48.749554, lng: 21.14753 },
    { lat: 48.749739, lng: 21.147214 },
    { lat: 48.749784, lng: 21.147017 },
    { lat: 48.749864, lng: 21.146877 },
    { lat: 48.750008, lng: 21.146778 },
    { lat: 48.749995, lng: 21.146601 },
    { lat: 48.750157, lng: 21.146537 },
  ],
  Žarnov: [
    { lat: 48.5862457, lng: 20.9357917 },
    { lat: 48.5862482, lng: 20.9357336 },
    { lat: 48.5865131, lng: 20.9355099 },
    { lat: 48.5868543, lng: 20.9353813 },
    { lat: 48.5875166, lng: 20.935226 },
    { lat: 48.5883097, lng: 20.9352483 },
    { lat: 48.5884191, lng: 20.9352819 },
    { lat: 48.5884618, lng: 20.93538 },
    { lat: 48.5883519, lng: 20.9357934 },
    { lat: 48.5883798, lng: 20.9362911 },
    { lat: 48.5885299, lng: 20.9363998 },
    { lat: 48.5886902, lng: 20.9363814 },
    { lat: 48.5890072, lng: 20.9361648 },
    { lat: 48.5892079, lng: 20.9354784 },
    { lat: 48.5893097, lng: 20.9348612 },
    { lat: 48.5897591, lng: 20.9345988 },
    { lat: 48.5901408, lng: 20.934705 },
    { lat: 48.5904097, lng: 20.9346339 },
    { lat: 48.5905594, lng: 20.9343319 },
    { lat: 48.5906475, lng: 20.9335749 },
    { lat: 48.5909308, lng: 20.932693 },
    { lat: 48.5911384, lng: 20.932277 },
    { lat: 48.5912314, lng: 20.9319019 },
    { lat: 48.591226, lng: 20.9318045 },
    { lat: 48.5911053, lng: 20.9316673 },
    { lat: 48.5904846, lng: 20.9315187 },
    { lat: 48.5902629, lng: 20.9306536 },
    { lat: 48.5903693, lng: 20.9300036 },
    { lat: 48.5906521, lng: 20.9297451 },
    { lat: 48.5907726, lng: 20.9294179 },
    { lat: 48.5910404, lng: 20.9291129 },
    { lat: 48.5912945, lng: 20.929135 },
    { lat: 48.5914307, lng: 20.9290751 },
    { lat: 48.5916849, lng: 20.9287049 },
    { lat: 48.591804, lng: 20.9283278 },
    { lat: 48.5921202, lng: 20.9277943 },
    { lat: 48.5921194, lng: 20.927373 },
    { lat: 48.5919799, lng: 20.9270687 },
    { lat: 48.5918048, lng: 20.9270882 },
    { lat: 48.5915059, lng: 20.9273391 },
    { lat: 48.5913865, lng: 20.927302 },
    { lat: 48.5912525, lng: 20.9267637 },
    { lat: 48.5912731, lng: 20.9266067 },
    { lat: 48.5915103, lng: 20.9264985 },
    { lat: 48.5916849, lng: 20.9263418 },
    { lat: 48.5915066, lng: 20.924892 },
    { lat: 48.5915708, lng: 20.9243014 },
    { lat: 48.5915293, lng: 20.9238363 },
    { lat: 48.5915137, lng: 20.9236739 },
    { lat: 48.5913553, lng: 20.9234198 },
    { lat: 48.5913337, lng: 20.923046 },
    { lat: 48.5913663, lng: 20.9228817 },
    { lat: 48.5915086, lng: 20.9225973 },
    { lat: 48.591765, lng: 20.922425 },
    { lat: 48.591853, lng: 20.922292 },
    { lat: 48.59209, lng: 20.9222499 },
    { lat: 48.5923137, lng: 20.9223685 },
    { lat: 48.5924212, lng: 20.9221526 },
    { lat: 48.5924232, lng: 20.9219452 },
    { lat: 48.5925602, lng: 20.9219389 },
    { lat: 48.5928558, lng: 20.922191 },
    { lat: 48.5930181, lng: 20.9221273 },
    { lat: 48.5931948, lng: 20.9219341 },
    { lat: 48.5932384, lng: 20.9217249 },
    { lat: 48.5928556, lng: 20.9212874 },
    { lat: 48.5926853, lng: 20.9213432 },
    { lat: 48.5925989, lng: 20.9212834 },
    { lat: 48.5924931, lng: 20.9210853 },
    { lat: 48.5924796, lng: 20.9209228 },
    { lat: 48.5927138, lng: 20.9206231 },
    { lat: 48.5928491, lng: 20.9206614 },
    { lat: 48.5929898, lng: 20.92086 },
    { lat: 48.5931078, lng: 20.9208552 },
    { lat: 48.5932994, lng: 20.9204319 },
    { lat: 48.5934198, lng: 20.9203323 },
    { lat: 48.5934841, lng: 20.9201065 },
    { lat: 48.5934864, lng: 20.9197074 },
    { lat: 48.5933421, lng: 20.9195368 },
    { lat: 48.5931811, lng: 20.9196004 },
    { lat: 48.5930691, lng: 20.919748 },
    { lat: 48.5929622, lng: 20.9196503 },
    { lat: 48.5929201, lng: 20.9195369 },
    { lat: 48.5930283, lng: 20.9190738 },
    { lat: 48.5932496, lng: 20.9184359 },
    { lat: 48.5933572, lng: 20.9183533 },
    { lat: 48.5936157, lng: 20.9184706 },
    { lat: 48.5936778, lng: 20.9187453 },
    { lat: 48.5938253, lng: 20.9187809 },
    { lat: 48.5939308, lng: 20.918734 },
    { lat: 48.5941331, lng: 20.9182661 },
    { lat: 48.5942237, lng: 20.9182104 },
    { lat: 48.5942989, lng: 20.9179119 },
    { lat: 48.5942386, lng: 20.9177256 },
    { lat: 48.5942825, lng: 20.9174285 },
    { lat: 48.5941473, lng: 20.9170919 },
    { lat: 48.5942151, lng: 20.917034 },
    { lat: 48.5942502, lng: 20.916802 },
    { lat: 48.5941963, lng: 20.9167076 },
    { lat: 48.5941987, lng: 20.9165499 },
    { lat: 48.5943338, lng: 20.9164161 },
    { lat: 48.5944625, lng: 20.916525 },
    { lat: 48.5946948, lng: 20.9159402 },
    { lat: 48.5946684, lng: 20.9156617 },
    { lat: 48.5944828, lng: 20.9155316 },
    { lat: 48.5944126, lng: 20.9155545 },
    { lat: 48.5943217, lng: 20.9158929 },
    { lat: 48.594248, lng: 20.9159455 },
    { lat: 48.5941455, lng: 20.9150794 },
    { lat: 48.593972, lng: 20.9147763 },
    { lat: 48.5939962, lng: 20.9143648 },
    { lat: 48.5941914, lng: 20.9138465 },
    { lat: 48.5941486, lng: 20.9131493 },
    { lat: 48.5940162, lng: 20.9130632 },
    { lat: 48.5936708, lng: 20.9131037 },
    { lat: 48.5935846, lng: 20.9133443 },
    { lat: 48.5934938, lng: 20.9133922 },
    { lat: 48.5934003, lng: 20.913222 },
    { lat: 48.5934286, lng: 20.912669 },
    { lat: 48.5935303, lng: 20.9123415 },
    { lat: 48.5935526, lng: 20.912104 },
    { lat: 48.5934562, lng: 20.9117509 },
    { lat: 48.5936685, lng: 20.9112459 },
    { lat: 48.5937294, lng: 20.911185 },
    { lat: 48.5938367, lng: 20.9112603 },
    { lat: 48.593967, lng: 20.9111155 },
    { lat: 48.5937389, lng: 20.9104016 },
    { lat: 48.5936399, lng: 20.9103687 },
    { lat: 48.5936108, lng: 20.9102053 },
    { lat: 48.5936424, lng: 20.9098444 },
    { lat: 48.5938797, lng: 20.909277 },
    { lat: 48.5938737, lng: 20.9091349 },
    { lat: 48.5937292, lng: 20.9088257 },
    { lat: 48.5934679, lng: 20.9089515 },
    { lat: 48.5933975, lng: 20.9088648 },
    { lat: 48.5933446, lng: 20.9087032 },
    { lat: 48.5933567, lng: 20.9081987 },
    { lat: 48.5933153, lng: 20.9079754 },
    { lat: 48.5933648, lng: 20.9075428 },
    { lat: 48.593267, lng: 20.9073789 },
    { lat: 48.593013, lng: 20.9072286 },
    { lat: 48.5929377, lng: 20.9073311 },
    { lat: 48.5925762, lng: 20.9067645 },
    { lat: 48.5924043, lng: 20.9066093 },
    { lat: 48.5917753, lng: 20.9063187 },
    { lat: 48.5913507, lng: 20.9054177 },
    { lat: 48.5908715, lng: 20.9050622 },
    { lat: 48.5896814, lng: 20.904857 },
    { lat: 48.5892506, lng: 20.9037066 },
    { lat: 48.5888875, lng: 20.9031648 },
    { lat: 48.5882317, lng: 20.9024838 },
    { lat: 48.5879775, lng: 20.9031153 },
    { lat: 48.587451, lng: 20.9028588 },
    { lat: 48.5874006, lng: 20.9029655 },
    { lat: 48.5866454, lng: 20.9028383 },
    { lat: 48.5866723, lng: 20.9026723 },
    { lat: 48.5856578, lng: 20.9022022 },
    { lat: 48.5856165, lng: 20.9023739 },
    { lat: 48.5853136, lng: 20.9022061 },
    { lat: 48.5854125, lng: 20.9016758 },
    { lat: 48.5850798, lng: 20.9014246 },
    { lat: 48.5851148, lng: 20.901259 },
    { lat: 48.5849488, lng: 20.9011442 },
    { lat: 48.5849665, lng: 20.9010398 },
    { lat: 48.5846311, lng: 20.9009107 },
    { lat: 48.5846495, lng: 20.9008201 },
    { lat: 48.5845055, lng: 20.9007889 },
    { lat: 48.5845734, lng: 20.9004359 },
    { lat: 48.5839295, lng: 20.9006369 },
    { lat: 48.583461, lng: 20.9010951 },
    { lat: 48.5834671, lng: 20.9012724 },
    { lat: 48.5829451, lng: 20.9016737 },
    { lat: 48.583049, lng: 20.9019493 },
    { lat: 48.5817198, lng: 20.9030771 },
    { lat: 48.5818094, lng: 20.903366 },
    { lat: 48.58071, lng: 20.9041705 },
    { lat: 48.5806373, lng: 20.903949 },
    { lat: 48.5800279, lng: 20.9044373 },
    { lat: 48.5797065, lng: 20.9034568 },
    { lat: 48.5790453, lng: 20.9038105 },
    { lat: 48.5788683, lng: 20.9024143 },
    { lat: 48.5783995, lng: 20.9026631 },
    { lat: 48.5781945, lng: 20.9020658 },
    { lat: 48.5778489, lng: 20.9022297 },
    { lat: 48.5774574, lng: 20.9009817 },
    { lat: 48.5772191, lng: 20.9010556 },
    { lat: 48.5770738, lng: 20.9003623 },
    { lat: 48.5754305, lng: 20.9006988 },
    { lat: 48.5749343, lng: 20.9008684 },
    { lat: 48.5745175, lng: 20.9010908 },
    { lat: 48.5743729, lng: 20.9006122 },
    { lat: 48.5742134, lng: 20.9007219 },
    { lat: 48.5741668, lng: 20.9005443 },
    { lat: 48.5722726, lng: 20.9019406 },
    { lat: 48.5723439, lng: 20.9021519 },
    { lat: 48.5720321, lng: 20.9023811 },
    { lat: 48.5719676, lng: 20.9022041 },
    { lat: 48.5713751, lng: 20.9026559 },
    { lat: 48.5712061, lng: 20.9022591 },
    { lat: 48.5697873, lng: 20.9036047 },
    { lat: 48.5692106, lng: 20.9042499 },
    { lat: 48.5689142, lng: 20.9040084 },
    { lat: 48.5656758, lng: 20.9057024 },
    { lat: 48.5648794, lng: 20.9060465 },
    { lat: 48.5639653, lng: 20.9062106 },
    { lat: 48.5639053, lng: 20.9060372 },
    { lat: 48.5623706, lng: 20.9057314 },
    { lat: 48.5623628, lng: 20.9059598 },
    { lat: 48.5622551, lng: 20.9061363 },
    { lat: 48.5621167, lng: 20.9061704 },
    { lat: 48.5620277, lng: 20.9066695 },
    { lat: 48.5621186, lng: 20.9068241 },
    { lat: 48.5623385, lng: 20.9069699 },
    { lat: 48.5621882, lng: 20.9074481 },
    { lat: 48.5621043, lng: 20.9084543 },
    { lat: 48.5620149, lng: 20.9087732 },
    { lat: 48.5621187, lng: 20.9091415 },
    { lat: 48.5619568, lng: 20.9094088 },
    { lat: 48.5616973, lng: 20.9094288 },
    { lat: 48.5616152, lng: 20.9097621 },
    { lat: 48.5610952, lng: 20.910029 },
    { lat: 48.5610003, lng: 20.9100196 },
    { lat: 48.5609503, lng: 20.9101024 },
    { lat: 48.5609116, lng: 20.9106235 },
    { lat: 48.5608408, lng: 20.9109534 },
    { lat: 48.5609094, lng: 20.9110849 },
    { lat: 48.5609006, lng: 20.9113272 },
    { lat: 48.5608357, lng: 20.9117977 },
    { lat: 48.5607438, lng: 20.9120085 },
    { lat: 48.560787, lng: 20.9121544 },
    { lat: 48.5606789, lng: 20.9123525 },
    { lat: 48.5607028, lng: 20.9124604 },
    { lat: 48.5607604, lng: 20.9124617 },
    { lat: 48.5606545, lng: 20.9127257 },
    { lat: 48.5605648, lng: 20.9127721 },
    { lat: 48.5605521, lng: 20.9129959 },
    { lat: 48.5605965, lng: 20.913109 },
    { lat: 48.5604794, lng: 20.9134041 },
    { lat: 48.5604848, lng: 20.9136304 },
    { lat: 48.560562, lng: 20.9136124 },
    { lat: 48.5603788, lng: 20.914253 },
    { lat: 48.5602382, lng: 20.9143861 },
    { lat: 48.5601082, lng: 20.9143076 },
    { lat: 48.5599072, lng: 20.9144539 },
    { lat: 48.5598874, lng: 20.9148637 },
    { lat: 48.5601609, lng: 20.9150608 },
    { lat: 48.5600383, lng: 20.915625 },
    { lat: 48.560069, lng: 20.915838 },
    { lat: 48.560131, lng: 20.915841 },
    { lat: 48.5622909, lng: 20.915629 },
    { lat: 48.5678608, lng: 20.9219311 },
    { lat: 48.5733005, lng: 20.9284646 },
    { lat: 48.5751165, lng: 20.9307362 },
    { lat: 48.575148, lng: 20.9308368 },
    { lat: 48.5735211, lng: 20.9321888 },
    { lat: 48.5735886, lng: 20.9326855 },
    { lat: 48.5735285, lng: 20.9326741 },
    { lat: 48.5734033, lng: 20.9327989 },
    { lat: 48.5749012, lng: 20.937956 },
    { lat: 48.5753344, lng: 20.9379527 },
    { lat: 48.5756086, lng: 20.9398358 },
    { lat: 48.5757714, lng: 20.9399268 },
    { lat: 48.5758874, lng: 20.9400914 },
    { lat: 48.5761563, lng: 20.939988 },
    { lat: 48.5765371, lng: 20.9396968 },
    { lat: 48.5778008, lng: 20.9395538 },
    { lat: 48.5778719, lng: 20.9394939 },
    { lat: 48.5782571, lng: 20.9395636 },
    { lat: 48.5789011, lng: 20.9398018 },
    { lat: 48.5794326, lng: 20.9405727 },
    { lat: 48.5796607, lng: 20.9406284 },
    { lat: 48.5798422, lng: 20.9410027 },
    { lat: 48.5799453, lng: 20.9410599 },
    { lat: 48.5800354, lng: 20.9410245 },
    { lat: 48.5801481, lng: 20.9411286 },
    { lat: 48.5802904, lng: 20.9411433 },
    { lat: 48.580314, lng: 20.9410736 },
    { lat: 48.5811281, lng: 20.9413493 },
    { lat: 48.5815036, lng: 20.9413355 },
    { lat: 48.5817868, lng: 20.9412206 },
    { lat: 48.5821991, lng: 20.9412335 },
    { lat: 48.5822551, lng: 20.9410557 },
    { lat: 48.5827802, lng: 20.9407421 },
    { lat: 48.5831643, lng: 20.940045 },
    { lat: 48.5836936, lng: 20.9387164 },
    { lat: 48.5837324, lng: 20.9386715 },
    { lat: 48.5838173, lng: 20.9388168 },
    { lat: 48.5838927, lng: 20.9387449 },
    { lat: 48.5839217, lng: 20.9384355 },
    { lat: 48.5840983, lng: 20.938433 },
    { lat: 48.5842565, lng: 20.9381891 },
    { lat: 48.5843941, lng: 20.9381266 },
    { lat: 48.5847756, lng: 20.9376252 },
    { lat: 48.584937, lng: 20.9374742 },
    { lat: 48.5852109, lng: 20.937349 },
    { lat: 48.5852874, lng: 20.937116 },
    { lat: 48.5855033, lng: 20.9369071 },
    { lat: 48.5856464, lng: 20.9365498 },
    { lat: 48.5858571, lng: 20.9363833 },
    { lat: 48.5860528, lng: 20.936011 },
    { lat: 48.5862457, lng: 20.9357917 },
  ],
  Gyňov: [
    { lat: 48.5961449, lng: 21.2908653 },
    { lat: 48.5950665, lng: 21.2907453 },
    { lat: 48.5946433, lng: 21.290633 },
    { lat: 48.5943842, lng: 21.2904804 },
    { lat: 48.593181, lng: 21.2901839 },
    { lat: 48.5914633, lng: 21.2895841 },
    { lat: 48.5904063, lng: 21.2890254 },
    { lat: 48.5901146, lng: 21.288947 },
    { lat: 48.59013, lng: 21.2884994 },
    { lat: 48.5881323, lng: 21.2875012 },
    { lat: 48.5881112, lng: 21.2876584 },
    { lat: 48.5875741, lng: 21.2875779 },
    { lat: 48.5848579, lng: 21.2878868 },
    { lat: 48.5833429, lng: 21.2882466 },
    { lat: 48.5803934, lng: 21.2884755 },
    { lat: 48.5801137, lng: 21.2891898 },
    { lat: 48.5799349, lng: 21.2894127 },
    { lat: 48.5797623, lng: 21.2898043 },
    { lat: 48.5792934, lng: 21.2914296 },
    { lat: 48.5788899, lng: 21.2931651 },
    { lat: 48.578668, lng: 21.2938598 },
    { lat: 48.578423, lng: 21.295259 },
    { lat: 48.5782243, lng: 21.2973697 },
    { lat: 48.5780492, lng: 21.297974 },
    { lat: 48.5780066, lng: 21.2989308 },
    { lat: 48.5780915, lng: 21.2999094 },
    { lat: 48.5775672, lng: 21.3018163 },
    { lat: 48.5776073, lng: 21.3030961 },
    { lat: 48.5775733, lng: 21.3034902 },
    { lat: 48.5772646, lng: 21.305285 },
    { lat: 48.5766125, lng: 21.3125642 },
    { lat: 48.5765949, lng: 21.3127993 },
    { lat: 48.5773417, lng: 21.3127443 },
    { lat: 48.5779697, lng: 21.3134077 },
    { lat: 48.5785111, lng: 21.3138536 },
    { lat: 48.5785144, lng: 21.3140417 },
    { lat: 48.5793017, lng: 21.3143536 },
    { lat: 48.5795709, lng: 21.3141416 },
    { lat: 48.5797018, lng: 21.3141184 },
    { lat: 48.5799282, lng: 21.3144894 },
    { lat: 48.5796779, lng: 21.3159884 },
    { lat: 48.5790055, lng: 21.3156753 },
    { lat: 48.5784916, lng: 21.3155438 },
    { lat: 48.5782741, lng: 21.3169726 },
    { lat: 48.5780004, lng: 21.3179956 },
    { lat: 48.5777311, lng: 21.3180593 },
    { lat: 48.5775526, lng: 21.3200348 },
    { lat: 48.576911, lng: 21.3195863 },
    { lat: 48.5765434, lng: 21.3194116 },
    { lat: 48.576827, lng: 21.3208576 },
    { lat: 48.5773001, lng: 21.3209056 },
    { lat: 48.5780749, lng: 21.3211858 },
    { lat: 48.5789572, lng: 21.3216302 },
    { lat: 48.5790792, lng: 21.32227 },
    { lat: 48.5790253, lng: 21.3228328 },
    { lat: 48.5787528, lng: 21.3234568 },
    { lat: 48.5781456, lng: 21.3242092 },
    { lat: 48.5781309, lng: 21.3251385 },
    { lat: 48.5794142, lng: 21.3246309 },
    { lat: 48.5795793, lng: 21.324655 },
    { lat: 48.5786593, lng: 21.3318589 },
    { lat: 48.5783737, lng: 21.3317193 },
    { lat: 48.5785756, lng: 21.3321795 },
    { lat: 48.5787389, lng: 21.3328183 },
    { lat: 48.5790458, lng: 21.3330637 },
    { lat: 48.5795159, lng: 21.3332367 },
    { lat: 48.5803581, lng: 21.3332871 },
    { lat: 48.580753, lng: 21.3335471 },
    { lat: 48.5809115, lng: 21.3330219 },
    { lat: 48.5813513, lng: 21.3329045 },
    { lat: 48.5815996, lng: 21.3332219 },
    { lat: 48.5817791, lng: 21.3333549 },
    { lat: 48.5819471, lng: 21.3333984 },
    { lat: 48.582449, lng: 21.333291 },
    { lat: 48.5833365, lng: 21.3326796 },
    { lat: 48.5833454, lng: 21.3322355 },
    { lat: 48.5836775, lng: 21.3317548 },
    { lat: 48.5839274, lng: 21.3314952 },
    { lat: 48.5864547, lng: 21.3299255 },
    { lat: 48.5877116, lng: 21.3290707 },
    { lat: 48.588248, lng: 21.3285001 },
    { lat: 48.5885105, lng: 21.3280288 },
    { lat: 48.5888192, lng: 21.3272973 },
    { lat: 48.5889353, lng: 21.3264141 },
    { lat: 48.5889904, lng: 21.3239369 },
    { lat: 48.5889401, lng: 21.3233539 },
    { lat: 48.5887094, lng: 21.3222807 },
    { lat: 48.5887305, lng: 21.3221207 },
    { lat: 48.5893275, lng: 21.3220659 },
    { lat: 48.5898499, lng: 21.3217014 },
    { lat: 48.5905151, lng: 21.3213742 },
    { lat: 48.5910359, lng: 21.3213698 },
    { lat: 48.5917672, lng: 21.3215806 },
    { lat: 48.5919466, lng: 21.3210435 },
    { lat: 48.5925633, lng: 21.3185643 },
    { lat: 48.5933401, lng: 21.3158375 },
    { lat: 48.5951225, lng: 21.3081256 },
    { lat: 48.5956858, lng: 21.3047873 },
    { lat: 48.5958304, lng: 21.3041864 },
    { lat: 48.5960247, lng: 21.3038778 },
    { lat: 48.5961465, lng: 21.3029627 },
    { lat: 48.5961632, lng: 21.3022608 },
    { lat: 48.5961139, lng: 21.3016051 },
    { lat: 48.596264, lng: 21.3002896 },
    { lat: 48.5963466, lng: 21.2990608 },
    { lat: 48.5962631, lng: 21.2981657 },
    { lat: 48.595993, lng: 21.2969167 },
    { lat: 48.5959536, lng: 21.2959616 },
    { lat: 48.5960409, lng: 21.2937491 },
    { lat: 48.5962024, lng: 21.2921487 },
    { lat: 48.5961592, lng: 21.291147 },
    { lat: 48.5961449, lng: 21.2908653 },
  ],
  Boliarov: [
    { lat: 48.8092946, lng: 21.4131043 },
    { lat: 48.8091917, lng: 21.4131263 },
    { lat: 48.8090247, lng: 21.4131468 },
    { lat: 48.8088731, lng: 21.4126623 },
    { lat: 48.8086379, lng: 21.4124336 },
    { lat: 48.8085258, lng: 21.412407 },
    { lat: 48.8086563, lng: 21.4120961 },
    { lat: 48.8086433, lng: 21.4119992 },
    { lat: 48.8085955, lng: 21.4117557 },
    { lat: 48.8084112, lng: 21.411574 },
    { lat: 48.8086364, lng: 21.4083387 },
    { lat: 48.8078741, lng: 21.40569 },
    { lat: 48.807651, lng: 21.4054513 },
    { lat: 48.8074523, lng: 21.4049719 },
    { lat: 48.8073572, lng: 21.4045818 },
    { lat: 48.8073381, lng: 21.4036427 },
    { lat: 48.8071447, lng: 21.4029856 },
    { lat: 48.806904, lng: 21.4026839 },
    { lat: 48.8068352, lng: 21.402222 },
    { lat: 48.8069374, lng: 21.4020587 },
    { lat: 48.8073444, lng: 21.4017798 },
    { lat: 48.8080297, lng: 21.4010312 },
    { lat: 48.8079661, lng: 21.4002077 },
    { lat: 48.8079034, lng: 21.3994921 },
    { lat: 48.8081974, lng: 21.3984383 },
    { lat: 48.808431, lng: 21.3980055 },
    { lat: 48.8085311, lng: 21.3976988 },
    { lat: 48.8087002, lng: 21.3975981 },
    { lat: 48.8089085, lng: 21.397037 },
    { lat: 48.8090721, lng: 21.3967396 },
    { lat: 48.8093518, lng: 21.3965346 },
    { lat: 48.8096158, lng: 21.3961803 },
    { lat: 48.8096403, lng: 21.3959402 },
    { lat: 48.8094474, lng: 21.3954724 },
    { lat: 48.8082543, lng: 21.3933733 },
    { lat: 48.8081636, lng: 21.3929322 },
    { lat: 48.808354, lng: 21.3916533 },
    { lat: 48.8087704, lng: 21.3907814 },
    { lat: 48.8091849, lng: 21.3895094 },
    { lat: 48.8092878, lng: 21.3894892 },
    { lat: 48.8093832, lng: 21.388301 },
    { lat: 48.8077893, lng: 21.3891535 },
    { lat: 48.8001674, lng: 21.3932306 },
    { lat: 48.8015032, lng: 21.3973494 },
    { lat: 48.801552, lng: 21.3974998 },
    { lat: 48.8017418, lng: 21.3978339 },
    { lat: 48.8021583, lng: 21.3991267 },
    { lat: 48.8000579, lng: 21.4005153 },
    { lat: 48.7979101, lng: 21.4013962 },
    { lat: 48.7967522, lng: 21.4021878 },
    { lat: 48.7961176, lng: 21.402546 },
    { lat: 48.7958022, lng: 21.4027137 },
    { lat: 48.7951614, lng: 21.4030364 },
    { lat: 48.7927315, lng: 21.4040554 },
    { lat: 48.7918321, lng: 21.4047537 },
    { lat: 48.7911702, lng: 21.4051118 },
    { lat: 48.7913695, lng: 21.4061379 },
    { lat: 48.7916199, lng: 21.4070995 },
    { lat: 48.7919585, lng: 21.4076772 },
    { lat: 48.7919582, lng: 21.409109 },
    { lat: 48.7918383, lng: 21.4114136 },
    { lat: 48.7927189, lng: 21.410979 },
    { lat: 48.793542, lng: 21.4135111 },
    { lat: 48.7932081, lng: 21.4136772 },
    { lat: 48.7927511, lng: 21.413788 },
    { lat: 48.7924493, lng: 21.4137734 },
    { lat: 48.7921675, lng: 21.4139502 },
    { lat: 48.7923151, lng: 21.4149488 },
    { lat: 48.7917532, lng: 21.4159328 },
    { lat: 48.7911906, lng: 21.4161952 },
    { lat: 48.7913661, lng: 21.4174945 },
    { lat: 48.7915591, lng: 21.4185493 },
    { lat: 48.7920141, lng: 21.419283 },
    { lat: 48.7920752, lng: 21.4196425 },
    { lat: 48.7920862, lng: 21.4197121 },
    { lat: 48.7922323, lng: 21.4205823 },
    { lat: 48.792544, lng: 21.4206205 },
    { lat: 48.7928349, lng: 21.4205634 },
    { lat: 48.7934442, lng: 21.4243306 },
    { lat: 48.7935157, lng: 21.424807 },
    { lat: 48.7943983, lng: 21.4278523 },
    { lat: 48.7947201, lng: 21.4290556 },
    { lat: 48.7952123, lng: 21.430313 },
    { lat: 48.795385, lng: 21.4307346 },
    { lat: 48.7957998, lng: 21.4315641 },
    { lat: 48.7961974, lng: 21.4321783 },
    { lat: 48.7964242, lng: 21.432853 },
    { lat: 48.7967212, lng: 21.4329615 },
    { lat: 48.7974338, lng: 21.4328176 },
    { lat: 48.7975725, lng: 21.4331006 },
    { lat: 48.7977004, lng: 21.4331291 },
    { lat: 48.7977113, lng: 21.4333803 },
    { lat: 48.7978859, lng: 21.4335435 },
    { lat: 48.7980646, lng: 21.4341043 },
    { lat: 48.7982699, lng: 21.4344335 },
    { lat: 48.7984916, lng: 21.4353199 },
    { lat: 48.7985301, lng: 21.4358616 },
    { lat: 48.79881, lng: 21.4363114 },
    { lat: 48.7991247, lng: 21.4376052 },
    { lat: 48.7991291, lng: 21.4379097 },
    { lat: 48.7991921, lng: 21.4381853 },
    { lat: 48.8013037, lng: 21.4433937 },
    { lat: 48.8023127, lng: 21.4434607 },
    { lat: 48.8028704, lng: 21.4444139 },
    { lat: 48.8031419, lng: 21.4446124 },
    { lat: 48.80507, lng: 21.4447499 },
    { lat: 48.8056645, lng: 21.445198 },
    { lat: 48.8060734, lng: 21.4455117 },
    { lat: 48.8062604, lng: 21.4456771 },
    { lat: 48.806768, lng: 21.4477565 },
    { lat: 48.8078812, lng: 21.4483074 },
    { lat: 48.8100074, lng: 21.4500372 },
    { lat: 48.810255, lng: 21.4503282 },
    { lat: 48.8105437, lng: 21.4505544 },
    { lat: 48.811078, lng: 21.4506979 },
    { lat: 48.8116431, lng: 21.4513485 },
    { lat: 48.8122051, lng: 21.4521141 },
    { lat: 48.8127965, lng: 21.4532238 },
    { lat: 48.8133863, lng: 21.4540773 },
    { lat: 48.8138596, lng: 21.4551093 },
    { lat: 48.8142381, lng: 21.4555474 },
    { lat: 48.8151194, lng: 21.4562487 },
    { lat: 48.8157567, lng: 21.4570352 },
    { lat: 48.8172699, lng: 21.4604354 },
    { lat: 48.8175126, lng: 21.4610805 },
    { lat: 48.8175843, lng: 21.4613003 },
    { lat: 48.8175672, lng: 21.4621869 },
    { lat: 48.8177948, lng: 21.4627434 },
    { lat: 48.8184394, lng: 21.4638941 },
    { lat: 48.8185454, lng: 21.4641751 },
    { lat: 48.8188142, lng: 21.4651409 },
    { lat: 48.8188332, lng: 21.465868 },
    { lat: 48.8190381, lng: 21.4678525 },
    { lat: 48.8195086, lng: 21.4688717 },
    { lat: 48.8201574, lng: 21.470014 },
    { lat: 48.8205639, lng: 21.4705686 },
    { lat: 48.8208851, lng: 21.4711736 },
    { lat: 48.8209982, lng: 21.471502 },
    { lat: 48.8214961, lng: 21.4721438 },
    { lat: 48.8223227, lng: 21.4713926 },
    { lat: 48.82216, lng: 21.4721815 },
    { lat: 48.8223754, lng: 21.472741 },
    { lat: 48.8226873, lng: 21.4727538 },
    { lat: 48.8227996, lng: 21.4734427 },
    { lat: 48.8228961, lng: 21.4747543 },
    { lat: 48.8229564, lng: 21.4749727 },
    { lat: 48.8234307, lng: 21.475343 },
    { lat: 48.8233474, lng: 21.4760012 },
    { lat: 48.8234094, lng: 21.4768252 },
    { lat: 48.8235461, lng: 21.4774268 },
    { lat: 48.8238377, lng: 21.4782055 },
    { lat: 48.8245293, lng: 21.4808028 },
    { lat: 48.8252874, lng: 21.4806015 },
    { lat: 48.8265268, lng: 21.480774 },
    { lat: 48.8271492, lng: 21.4810499 },
    { lat: 48.8279086, lng: 21.4808938 },
    { lat: 48.8285388, lng: 21.4803953 },
    { lat: 48.8290613, lng: 21.4795868 },
    { lat: 48.8291025, lng: 21.4796494 },
    { lat: 48.8293506, lng: 21.4793183 },
    { lat: 48.8295805, lng: 21.4792192 },
    { lat: 48.8296815, lng: 21.4789368 },
    { lat: 48.8298845, lng: 21.4786033 },
    { lat: 48.8299664, lng: 21.478124 },
    { lat: 48.829931, lng: 21.4777567 },
    { lat: 48.8298405, lng: 21.4775031 },
    { lat: 48.8299931, lng: 21.4771394 },
    { lat: 48.8299613, lng: 21.4768536 },
    { lat: 48.8297394, lng: 21.4759566 },
    { lat: 48.8298629, lng: 21.4754023 },
    { lat: 48.8297187, lng: 21.4750862 },
    { lat: 48.8297834, lng: 21.4743241 },
    { lat: 48.8297149, lng: 21.4741315 },
    { lat: 48.8296879, lng: 21.4736726 },
    { lat: 48.829759, lng: 21.4730965 },
    { lat: 48.8296021, lng: 21.472883 },
    { lat: 48.8295935, lng: 21.4723164 },
    { lat: 48.8296543, lng: 21.4721213 },
    { lat: 48.8295939, lng: 21.4718253 },
    { lat: 48.8294245, lng: 21.471417 },
    { lat: 48.8294521, lng: 21.4712784 },
    { lat: 48.8296035, lng: 21.4710262 },
    { lat: 48.8296223, lng: 21.4706664 },
    { lat: 48.8297878, lng: 21.4702822 },
    { lat: 48.8298335, lng: 21.4695666 },
    { lat: 48.8295104, lng: 21.4693685 },
    { lat: 48.8293678, lng: 21.469068 },
    { lat: 48.8294269, lng: 21.468628 },
    { lat: 48.8292298, lng: 21.4683411 },
    { lat: 48.8292635, lng: 21.4680321 },
    { lat: 48.8292333, lng: 21.4677634 },
    { lat: 48.829389, lng: 21.4675081 },
    { lat: 48.8293359, lng: 21.4671545 },
    { lat: 48.8294076, lng: 21.4664801 },
    { lat: 48.8292542, lng: 21.4662407 },
    { lat: 48.8291965, lng: 21.4658359 },
    { lat: 48.8292157, lng: 21.465604 },
    { lat: 48.8290642, lng: 21.4653887 },
    { lat: 48.8290422, lng: 21.4648655 },
    { lat: 48.8287286, lng: 21.4642568 },
    { lat: 48.8286487, lng: 21.4637882 },
    { lat: 48.8285117, lng: 21.4636084 },
    { lat: 48.8283205, lng: 21.4628309 },
    { lat: 48.8281459, lng: 21.4627104 },
    { lat: 48.82798, lng: 21.46227 },
    { lat: 48.8278047, lng: 21.4623556 },
    { lat: 48.8276835, lng: 21.4622476 },
    { lat: 48.8276302, lng: 21.4620062 },
    { lat: 48.8274591, lng: 21.4617626 },
    { lat: 48.8274095, lng: 21.4613403 },
    { lat: 48.8273016, lng: 21.4611867 },
    { lat: 48.8272488, lng: 21.4609942 },
    { lat: 48.8270563, lng: 21.4608778 },
    { lat: 48.8270866, lng: 21.4607172 },
    { lat: 48.8270432, lng: 21.4605405 },
    { lat: 48.8265563, lng: 21.4601244 },
    { lat: 48.8264542, lng: 21.4601333 },
    { lat: 48.8261976, lng: 21.4594755 },
    { lat: 48.8258409, lng: 21.4591268 },
    { lat: 48.8253072, lng: 21.4584597 },
    { lat: 48.8252103, lng: 21.4582637 },
    { lat: 48.8252027, lng: 21.4580013 },
    { lat: 48.8249066, lng: 21.4574947 },
    { lat: 48.8248896, lng: 21.4572923 },
    { lat: 48.8246768, lng: 21.456938 },
    { lat: 48.8244212, lng: 21.4568579 },
    { lat: 48.8243194, lng: 21.4566794 },
    { lat: 48.823939, lng: 21.4563698 },
    { lat: 48.8238182, lng: 21.4564725 },
    { lat: 48.8236971, lng: 21.4561442 },
    { lat: 48.8234478, lng: 21.4559216 },
    { lat: 48.8232609, lng: 21.4556091 },
    { lat: 48.8230172, lng: 21.4554347 },
    { lat: 48.8227789, lng: 21.4554464 },
    { lat: 48.8226281, lng: 21.4552865 },
    { lat: 48.8225159, lng: 21.4549169 },
    { lat: 48.822397, lng: 21.4548502 },
    { lat: 48.8222193, lng: 21.4546182 },
    { lat: 48.8220696, lng: 21.4546174 },
    { lat: 48.8216875, lng: 21.4543234 },
    { lat: 48.821499, lng: 21.454302 },
    { lat: 48.8214172, lng: 21.4541247 },
    { lat: 48.8211527, lng: 21.4539238 },
    { lat: 48.8210113, lng: 21.453778 },
    { lat: 48.8205503, lng: 21.4533461 },
    { lat: 48.8204891, lng: 21.4532422 },
    { lat: 48.819785, lng: 21.452327 },
    { lat: 48.8195774, lng: 21.4522262 },
    { lat: 48.8194638, lng: 21.4523495 },
    { lat: 48.8193932, lng: 21.45232 },
    { lat: 48.8193429, lng: 21.4519466 },
    { lat: 48.8191832, lng: 21.4518709 },
    { lat: 48.8190896, lng: 21.451629 },
    { lat: 48.818967, lng: 21.4515144 },
    { lat: 48.8189109, lng: 21.4512878 },
    { lat: 48.8187766, lng: 21.4512347 },
    { lat: 48.8185755, lng: 21.4514795 },
    { lat: 48.8184444, lng: 21.451464 },
    { lat: 48.8182493, lng: 21.4513306 },
    { lat: 48.818211, lng: 21.4510597 },
    { lat: 48.8180012, lng: 21.4510041 },
    { lat: 48.8177816, lng: 21.4508402 },
    { lat: 48.8177097, lng: 21.4501531 },
    { lat: 48.817844, lng: 21.4498513 },
    { lat: 48.8177788, lng: 21.4493929 },
    { lat: 48.8176251, lng: 21.4490226 },
    { lat: 48.817686, lng: 21.4482584 },
    { lat: 48.8176605, lng: 21.4481526 },
    { lat: 48.8174313, lng: 21.4478594 },
    { lat: 48.8171787, lng: 21.4478021 },
    { lat: 48.8171903, lng: 21.447586 },
    { lat: 48.8170649, lng: 21.4473684 },
    { lat: 48.81682, lng: 21.4472645 },
    { lat: 48.8167679, lng: 21.447179 },
    { lat: 48.8167126, lng: 21.4467529 },
    { lat: 48.8167932, lng: 21.446274 },
    { lat: 48.816745, lng: 21.4461684 },
    { lat: 48.8167833, lng: 21.4457427 },
    { lat: 48.8167416, lng: 21.4454698 },
    { lat: 48.8168149, lng: 21.44521 },
    { lat: 48.8167146, lng: 21.4448522 },
    { lat: 48.8164737, lng: 21.4445066 },
    { lat: 48.8164133, lng: 21.4439408 },
    { lat: 48.8166391, lng: 21.4436144 },
    { lat: 48.8165965, lng: 21.4433926 },
    { lat: 48.8165593, lng: 21.443353 },
    { lat: 48.8163421, lng: 21.4433508 },
    { lat: 48.8161288, lng: 21.4430223 },
    { lat: 48.81611, lng: 21.4421456 },
    { lat: 48.816166, lng: 21.4418673 },
    { lat: 48.8160963, lng: 21.4416385 },
    { lat: 48.8157693, lng: 21.4412266 },
    { lat: 48.8157249, lng: 21.4410243 },
    { lat: 48.8160257, lng: 21.4404153 },
    { lat: 48.8159919, lng: 21.4401081 },
    { lat: 48.8160595, lng: 21.4397675 },
    { lat: 48.81591, lng: 21.4395823 },
    { lat: 48.8160403, lng: 21.4391515 },
    { lat: 48.8160296, lng: 21.4390108 },
    { lat: 48.8159264, lng: 21.4387946 },
    { lat: 48.8155228, lng: 21.4386128 },
    { lat: 48.8155732, lng: 21.4379903 },
    { lat: 48.8155357, lng: 21.4378625 },
    { lat: 48.8154029, lng: 21.437819 },
    { lat: 48.8153894, lng: 21.4374393 },
    { lat: 48.8154319, lng: 21.4373115 },
    { lat: 48.8157015, lng: 21.4372984 },
    { lat: 48.8157102, lng: 21.4372092 },
    { lat: 48.8156109, lng: 21.4369324 },
    { lat: 48.8157158, lng: 21.4364837 },
    { lat: 48.8157103, lng: 21.4362959 },
    { lat: 48.815642, lng: 21.4361102 },
    { lat: 48.8155816, lng: 21.4360868 },
    { lat: 48.8153641, lng: 21.4362292 },
    { lat: 48.8154186, lng: 21.4359399 },
    { lat: 48.815082, lng: 21.4350499 },
    { lat: 48.8150912, lng: 21.4348633 },
    { lat: 48.815329, lng: 21.4347252 },
    { lat: 48.81535, lng: 21.4345535 },
    { lat: 48.8150683, lng: 21.4343082 },
    { lat: 48.8150381, lng: 21.4341235 },
    { lat: 48.8151342, lng: 21.4337579 },
    { lat: 48.8152377, lng: 21.4336424 },
    { lat: 48.8152396, lng: 21.4332834 },
    { lat: 48.815438, lng: 21.432335 },
    { lat: 48.8154172, lng: 21.432084 },
    { lat: 48.8153347, lng: 21.4318864 },
    { lat: 48.8150192, lng: 21.4317873 },
    { lat: 48.8149086, lng: 21.4312099 },
    { lat: 48.814901, lng: 21.4303321 },
    { lat: 48.8148319, lng: 21.4300886 },
    { lat: 48.8148889, lng: 21.4296149 },
    { lat: 48.8146866, lng: 21.4290844 },
    { lat: 48.814755, lng: 21.4286171 },
    { lat: 48.8147548, lng: 21.4276549 },
    { lat: 48.8147067, lng: 21.4274297 },
    { lat: 48.8144823, lng: 21.4268807 },
    { lat: 48.8142839, lng: 21.4258736 },
    { lat: 48.814067, lng: 21.4254562 },
    { lat: 48.8140364, lng: 21.4252562 },
    { lat: 48.8141548, lng: 21.4249219 },
    { lat: 48.8140318, lng: 21.4246042 },
    { lat: 48.8141023, lng: 21.4242807 },
    { lat: 48.8140814, lng: 21.4240361 },
    { lat: 48.8139505, lng: 21.4237525 },
    { lat: 48.8139279, lng: 21.4235212 },
    { lat: 48.8140748, lng: 21.4234267 },
    { lat: 48.8141935, lng: 21.4232116 },
    { lat: 48.8141696, lng: 21.4230162 },
    { lat: 48.8140822, lng: 21.4228368 },
    { lat: 48.8139331, lng: 21.4228158 },
    { lat: 48.8138453, lng: 21.4226624 },
    { lat: 48.8137753, lng: 21.4223501 },
    { lat: 48.8138933, lng: 21.4220333 },
    { lat: 48.8138363, lng: 21.4217455 },
    { lat: 48.8136575, lng: 21.4215183 },
    { lat: 48.8137184, lng: 21.420833 },
    { lat: 48.8136181, lng: 21.420717 },
    { lat: 48.8133729, lng: 21.4207199 },
    { lat: 48.8131985, lng: 21.4206017 },
    { lat: 48.8131422, lng: 21.4202192 },
    { lat: 48.813059, lng: 21.4201488 },
    { lat: 48.8130782, lng: 21.4198783 },
    { lat: 48.8124841, lng: 21.4185021 },
    { lat: 48.8118633, lng: 21.4173266 },
    { lat: 48.8114477, lng: 21.4166274 },
    { lat: 48.8111809, lng: 21.4163195 },
    { lat: 48.8092946, lng: 21.4131043 },
  ],
  SlanskéNovéMesto: [
    { lat: 48.672452, lng: 21.4827473 },
    { lat: 48.6723957, lng: 21.4827389 },
    { lat: 48.6723343, lng: 21.4827607 },
    { lat: 48.6721762, lng: 21.4830898 },
    { lat: 48.6722031, lng: 21.4832044 },
    { lat: 48.6718283, lng: 21.4837059 },
    { lat: 48.6713446, lng: 21.4844865 },
    { lat: 48.670891, lng: 21.4856119 },
    { lat: 48.6709479, lng: 21.4856729 },
    { lat: 48.670786, lng: 21.4860653 },
    { lat: 48.6706411, lng: 21.4863874 },
    { lat: 48.6701814, lng: 21.4871397 },
    { lat: 48.6696481, lng: 21.4885034 },
    { lat: 48.6695408, lng: 21.4889608 },
    { lat: 48.6690156, lng: 21.4906053 },
    { lat: 48.6694974, lng: 21.4920103 },
    { lat: 48.6688319, lng: 21.4937487 },
    { lat: 48.6669305, lng: 21.4971474 },
    { lat: 48.6670008, lng: 21.497949 },
    { lat: 48.6674124, lng: 21.4986809 },
    { lat: 48.6674897, lng: 21.4991393 },
    { lat: 48.666875, lng: 21.5003438 },
    { lat: 48.6663087, lng: 21.5016137 },
    { lat: 48.6661956, lng: 21.5029247 },
    { lat: 48.6661941, lng: 21.5040433 },
    { lat: 48.6663251, lng: 21.5043778 },
    { lat: 48.6660949, lng: 21.5048186 },
    { lat: 48.6661187, lng: 21.5050228 },
    { lat: 48.6659751, lng: 21.5050394 },
    { lat: 48.6659155, lng: 21.5051551 },
    { lat: 48.6659124, lng: 21.505398 },
    { lat: 48.6658654, lng: 21.5054463 },
    { lat: 48.6657163, lng: 21.5053863 },
    { lat: 48.6656072, lng: 21.5055117 },
    { lat: 48.6654411, lng: 21.5055646 },
    { lat: 48.6650366, lng: 21.5051931 },
    { lat: 48.6649522, lng: 21.5054635 },
    { lat: 48.6647487, lng: 21.5057072 },
    { lat: 48.664649, lng: 21.5056457 },
    { lat: 48.6644695, lng: 21.505855 },
    { lat: 48.6644045, lng: 21.5057828 },
    { lat: 48.6641795, lng: 21.5057915 },
    { lat: 48.6639853, lng: 21.5058843 },
    { lat: 48.6637797, lng: 21.5061718 },
    { lat: 48.6634108, lng: 21.5062897 },
    { lat: 48.6633419, lng: 21.506207 },
    { lat: 48.663073, lng: 21.5063588 },
    { lat: 48.6630715, lng: 21.5062307 },
    { lat: 48.662763, lng: 21.5061481 },
    { lat: 48.6625179, lng: 21.5061912 },
    { lat: 48.6622055, lng: 21.5067591 },
    { lat: 48.6616595, lng: 21.5068611 },
    { lat: 48.6614738, lng: 21.5069783 },
    { lat: 48.6612657, lng: 21.5069432 },
    { lat: 48.6610467, lng: 21.5072312 },
    { lat: 48.6608824, lng: 21.5071925 },
    { lat: 48.6608472, lng: 21.5072942 },
    { lat: 48.6606047, lng: 21.5072421 },
    { lat: 48.6605497, lng: 21.5075934 },
    { lat: 48.6603148, lng: 21.507649 },
    { lat: 48.6603509, lng: 21.5077228 },
    { lat: 48.6603273, lng: 21.5079194 },
    { lat: 48.6602224, lng: 21.5081103 },
    { lat: 48.6600985, lng: 21.5081875 },
    { lat: 48.6600077, lng: 21.508155 },
    { lat: 48.6598084, lng: 21.508309 },
    { lat: 48.6596694, lng: 21.5082473 },
    { lat: 48.6595737, lng: 21.5083621 },
    { lat: 48.6594901, lng: 21.5085879 },
    { lat: 48.659344, lng: 21.5086715 },
    { lat: 48.6590372, lng: 21.509113 },
    { lat: 48.6589051, lng: 21.5091359 },
    { lat: 48.6587016, lng: 21.5095393 },
    { lat: 48.6581148, lng: 21.5104177 },
    { lat: 48.658166, lng: 21.5105176 },
    { lat: 48.6580891, lng: 21.5106575 },
    { lat: 48.6580019, lng: 21.5106732 },
    { lat: 48.657752, lng: 21.510397 },
    { lat: 48.6574051, lng: 21.5105845 },
    { lat: 48.6573954, lng: 21.5109535 },
    { lat: 48.6571663, lng: 21.5111589 },
    { lat: 48.6568861, lng: 21.5113203 },
    { lat: 48.6562764, lng: 21.511521 },
    { lat: 48.6562523, lng: 21.5103709 },
    { lat: 48.6562028, lng: 21.5101756 },
    { lat: 48.6554894, lng: 21.5104497 },
    { lat: 48.6552648, lng: 21.5096811 },
    { lat: 48.6547036, lng: 21.5095317 },
    { lat: 48.65418, lng: 21.5096432 },
    { lat: 48.6538382, lng: 21.5099261 },
    { lat: 48.6534928, lng: 21.5091391 },
    { lat: 48.6530764, lng: 21.5092223 },
    { lat: 48.6525329, lng: 21.50664 },
    { lat: 48.6519384, lng: 21.5071673 },
    { lat: 48.6502645, lng: 21.5079648 },
    { lat: 48.6494174, lng: 21.508442 },
    { lat: 48.6485141, lng: 21.5093132 },
    { lat: 48.6479531, lng: 21.5099581 },
    { lat: 48.6472026, lng: 21.5103531 },
    { lat: 48.6464182, lng: 21.5111268 },
    { lat: 48.6456148, lng: 21.5125365 },
    { lat: 48.6443423, lng: 21.5108588 },
    { lat: 48.6442428, lng: 21.5108414 },
    { lat: 48.6439946, lng: 21.51004 },
    { lat: 48.6435229, lng: 21.5099151 },
    { lat: 48.6424444, lng: 21.5100945 },
    { lat: 48.6420204, lng: 21.5103304 },
    { lat: 48.6418492, lng: 21.5103644 },
    { lat: 48.6418498, lng: 21.5100708 },
    { lat: 48.6414587, lng: 21.5101341 },
    { lat: 48.6404823, lng: 21.509944 },
    { lat: 48.6399394, lng: 21.510081 },
    { lat: 48.6384505, lng: 21.5093722 },
    { lat: 48.6372624, lng: 21.5092191 },
    { lat: 48.6366969, lng: 21.509042 },
    { lat: 48.6360026, lng: 21.5091632 },
    { lat: 48.6360985, lng: 21.5092818 },
    { lat: 48.6360459, lng: 21.5092981 },
    { lat: 48.635527, lng: 21.5092118 },
    { lat: 48.6354333, lng: 21.509283 },
    { lat: 48.6352757, lng: 21.509124 },
    { lat: 48.6352849, lng: 21.5089679 },
    { lat: 48.6350643, lng: 21.5086935 },
    { lat: 48.6350053, lng: 21.5084025 },
    { lat: 48.634852, lng: 21.5086313 },
    { lat: 48.6344998, lng: 21.5089781 },
    { lat: 48.6345751, lng: 21.5093342 },
    { lat: 48.6343466, lng: 21.5096359 },
    { lat: 48.634156, lng: 21.5097391 },
    { lat: 48.6340795, lng: 21.5099405 },
    { lat: 48.6340529, lng: 21.5101902 },
    { lat: 48.6340853, lng: 21.5102983 },
    { lat: 48.6342012, lng: 21.510417 },
    { lat: 48.6342085, lng: 21.5105101 },
    { lat: 48.6341318, lng: 21.5106621 },
    { lat: 48.6341379, lng: 21.5110306 },
    { lat: 48.6340618, lng: 21.5114027 },
    { lat: 48.6338861, lng: 21.5115476 },
    { lat: 48.6338669, lng: 21.511938 },
    { lat: 48.63378, lng: 21.5120476 },
    { lat: 48.6336351, lng: 21.5120539 },
    { lat: 48.6333061, lng: 21.5114098 },
    { lat: 48.6326696, lng: 21.5135392 },
    { lat: 48.6313425, lng: 21.5121604 },
    { lat: 48.6306604, lng: 21.5116369 },
    { lat: 48.6293134, lng: 21.5101471 },
    { lat: 48.629448, lng: 21.5092369 },
    { lat: 48.629661, lng: 21.5093624 },
    { lat: 48.6298472, lng: 21.5090714 },
    { lat: 48.6293918, lng: 21.50869 },
    { lat: 48.6297123, lng: 21.507938 },
    { lat: 48.6292504, lng: 21.5073749 },
    { lat: 48.6295545, lng: 21.5061684 },
    { lat: 48.6280316, lng: 21.503266 },
    { lat: 48.627868, lng: 21.5036772 },
    { lat: 48.6280107, lng: 21.5039051 },
    { lat: 48.6277691, lng: 21.5044239 },
    { lat: 48.6279098, lng: 21.5046609 },
    { lat: 48.6275625, lng: 21.5058066 },
    { lat: 48.6274013, lng: 21.5067761 },
    { lat: 48.6264169, lng: 21.5063938 },
    { lat: 48.6260443, lng: 21.5075533 },
    { lat: 48.625637, lng: 21.5071673 },
    { lat: 48.6245309, lng: 21.5065867 },
    { lat: 48.6242582, lng: 21.5075055 },
    { lat: 48.6229351, lng: 21.5119733 },
    { lat: 48.6218847, lng: 21.5163821 },
    { lat: 48.62134, lng: 21.5178299 },
    { lat: 48.6202143, lng: 21.5214197 },
    { lat: 48.6197045, lng: 21.5225547 },
    { lat: 48.6191886, lng: 21.5234458 },
    { lat: 48.6190588, lng: 21.5236299 },
    { lat: 48.6184969, lng: 21.5241253 },
    { lat: 48.6182691, lng: 21.5245025 },
    { lat: 48.6181786, lng: 21.5249131 },
    { lat: 48.6181825, lng: 21.5257191 },
    { lat: 48.6180985, lng: 21.5261613 },
    { lat: 48.6179805, lng: 21.5265612 },
    { lat: 48.6176105, lng: 21.5273168 },
    { lat: 48.6171135, lng: 21.5285899 },
    { lat: 48.6170041, lng: 21.52925 },
    { lat: 48.6168292, lng: 21.529936 },
    { lat: 48.6169535, lng: 21.5308592 },
    { lat: 48.617271, lng: 21.5314161 },
    { lat: 48.6170883, lng: 21.532152 },
    { lat: 48.616781, lng: 21.5330166 },
    { lat: 48.6166467, lng: 21.5335563 },
    { lat: 48.6162772, lng: 21.5343662 },
    { lat: 48.6157917, lng: 21.5343913 },
    { lat: 48.6155181, lng: 21.5351708 },
    { lat: 48.6154285, lng: 21.5351478 },
    { lat: 48.6149919, lng: 21.5366793 },
    { lat: 48.6148559, lng: 21.5372009 },
    { lat: 48.6144584, lng: 21.539558 },
    { lat: 48.6140958, lng: 21.541324 },
    { lat: 48.6137699, lng: 21.5422479 },
    { lat: 48.614045, lng: 21.5423866 },
    { lat: 48.6132184, lng: 21.5444919 },
    { lat: 48.613332, lng: 21.5445745 },
    { lat: 48.6130532, lng: 21.5461177 },
    { lat: 48.612449, lng: 21.548851 },
    { lat: 48.612584, lng: 21.54905 },
    { lat: 48.61287, lng: 21.549266 },
    { lat: 48.613224, lng: 21.549488 },
    { lat: 48.613676, lng: 21.549772 },
    { lat: 48.613914, lng: 21.550034 },
    { lat: 48.614057, lng: 21.550191 },
    { lat: 48.61418, lng: 21.550323 },
    { lat: 48.614576, lng: 21.551899 },
    { lat: 48.614589, lng: 21.551949 },
    { lat: 48.614513, lng: 21.552069 },
    { lat: 48.61449, lng: 21.55207 },
    { lat: 48.614348, lng: 21.551819 },
    { lat: 48.614361, lng: 21.551683 },
    { lat: 48.6142601, lng: 21.5515357 },
    { lat: 48.614144, lng: 21.551598 },
    { lat: 48.614027, lng: 21.551832 },
    { lat: 48.613752, lng: 21.552055 },
    { lat: 48.613626, lng: 21.552136 },
    { lat: 48.613521, lng: 21.552265 },
    { lat: 48.613541, lng: 21.552394 },
    { lat: 48.613526, lng: 21.552426 },
    { lat: 48.613349, lng: 21.552484 },
    { lat: 48.613303, lng: 21.552568 },
    { lat: 48.613349, lng: 21.552831 },
    { lat: 48.6133656, lng: 21.5529653 },
    { lat: 48.613378, lng: 21.553066 },
    { lat: 48.613378, lng: 21.553328 },
    { lat: 48.61337, lng: 21.553355 },
    { lat: 48.613168, lng: 21.553443 },
    { lat: 48.613157, lng: 21.553606 },
    { lat: 48.613102, lng: 21.553705 },
    { lat: 48.61308, lng: 21.553712 },
    { lat: 48.612985, lng: 21.553609 },
    { lat: 48.612877, lng: 21.553657 },
    { lat: 48.612831, lng: 21.553811 },
    { lat: 48.612857, lng: 21.55395 },
    { lat: 48.6128841, lng: 21.5540031 },
    { lat: 48.612907, lng: 21.554048 },
    { lat: 48.612971, lng: 21.554112 },
    { lat: 48.613023, lng: 21.554164 },
    { lat: 48.613173, lng: 21.554177 },
    { lat: 48.613184, lng: 21.554208 },
    { lat: 48.6131943, lng: 21.5544715 },
    { lat: 48.613075, lng: 21.554665 },
    { lat: 48.613065, lng: 21.554671 },
    { lat: 48.612947, lng: 21.554666 },
    { lat: 48.612896, lng: 21.55458 },
    { lat: 48.612719, lng: 21.554581 },
    { lat: 48.612591, lng: 21.554621 },
    { lat: 48.612458, lng: 21.554518 },
    { lat: 48.612347, lng: 21.554592 },
    { lat: 48.612291, lng: 21.554721 },
    { lat: 48.612233, lng: 21.554855 },
    { lat: 48.612224, lng: 21.554952 },
    { lat: 48.61222, lng: 21.555056 },
    { lat: 48.612118, lng: 21.555257 },
    { lat: 48.612046, lng: 21.55534 },
    { lat: 48.611807, lng: 21.555447 },
    { lat: 48.611765, lng: 21.555559 },
    { lat: 48.6118309, lng: 21.5557118 },
    { lat: 48.61176, lng: 21.555949 },
    { lat: 48.611761, lng: 21.556103 },
    { lat: 48.611852, lng: 21.556162 },
    { lat: 48.611949, lng: 21.556304 },
    { lat: 48.611993, lng: 21.556476 },
    { lat: 48.611984, lng: 21.556523 },
    { lat: 48.611906, lng: 21.556588 },
    { lat: 48.611862, lng: 21.556846 },
    { lat: 48.611886, lng: 21.557003 },
    { lat: 48.611795, lng: 21.557152 },
    { lat: 48.611501, lng: 21.557291 },
    { lat: 48.611234, lng: 21.557057 },
    { lat: 48.611143, lng: 21.556907 },
    { lat: 48.611074, lng: 21.556854 },
    { lat: 48.61097, lng: 21.556902 },
    { lat: 48.610875, lng: 21.556843 },
    { lat: 48.610781, lng: 21.556891 },
    { lat: 48.610734, lng: 21.556998 },
    { lat: 48.610732, lng: 21.557071 },
    { lat: 48.610801, lng: 21.557225 },
    { lat: 48.611058, lng: 21.557409 },
    { lat: 48.611102, lng: 21.557612 },
    { lat: 48.611048, lng: 21.557735 },
    { lat: 48.611065, lng: 21.557874 },
    { lat: 48.611154, lng: 21.557843 },
    { lat: 48.6112062, lng: 21.5578759 },
    { lat: 48.611322, lng: 21.557949 },
    { lat: 48.611333, lng: 21.557969 },
    { lat: 48.611327, lng: 21.558006 },
    { lat: 48.611167, lng: 21.558118 },
    { lat: 48.611088, lng: 21.558154 },
    { lat: 48.611017, lng: 21.558224 },
    { lat: 48.6108774, lng: 21.5582199 },
    { lat: 48.610848, lng: 21.558219 },
    { lat: 48.610734, lng: 21.558364 },
    { lat: 48.610756, lng: 21.558446 },
    { lat: 48.610824, lng: 21.558536 },
    { lat: 48.610902, lng: 21.558674 },
    { lat: 48.611005, lng: 21.558711 },
    { lat: 48.611019, lng: 21.558741 },
    { lat: 48.611005, lng: 21.559044 },
    { lat: 48.611025, lng: 21.55917 },
    { lat: 48.611019, lng: 21.559199 },
    { lat: 48.610932, lng: 21.55927 },
    { lat: 48.610801, lng: 21.559302 },
    { lat: 48.610769, lng: 21.559601 },
    { lat: 48.61077, lng: 21.559616 },
    { lat: 48.61055, lng: 21.560011 },
    { lat: 48.610443, lng: 21.559973 },
    { lat: 48.610325, lng: 21.559749 },
    { lat: 48.610291, lng: 21.559783 },
    { lat: 48.610218, lng: 21.560131 },
    { lat: 48.61003, lng: 21.560255 },
    { lat: 48.609903, lng: 21.560516 },
    { lat: 48.609863, lng: 21.560781 },
    { lat: 48.609883, lng: 21.560808 },
    { lat: 48.610221, lng: 21.560667 },
    { lat: 48.610341, lng: 21.560724 },
    { lat: 48.610413, lng: 21.560815 },
    { lat: 48.61042, lng: 21.560927 },
    { lat: 48.610248, lng: 21.561313 },
    { lat: 48.610277, lng: 21.561532 },
    { lat: 48.610257, lng: 21.561755 },
    { lat: 48.610056, lng: 21.56184 },
    { lat: 48.609822, lng: 21.562157 },
    { lat: 48.609648, lng: 21.561903 },
    { lat: 48.609538, lng: 21.561746 },
    { lat: 48.60933, lng: 21.561585 },
    { lat: 48.609305, lng: 21.561613 },
    { lat: 48.609357, lng: 21.561982 },
    { lat: 48.609331, lng: 21.562103 },
    { lat: 48.609202, lng: 21.562268 },
    { lat: 48.609158, lng: 21.562385 },
    { lat: 48.609096, lng: 21.56248 },
    { lat: 48.609036, lng: 21.562619 },
    { lat: 48.608886, lng: 21.562509 },
    { lat: 48.60878, lng: 21.562261 },
    { lat: 48.608752, lng: 21.562255 },
    { lat: 48.608618, lng: 21.562409 },
    { lat: 48.608464, lng: 21.562377 },
    { lat: 48.608315, lng: 21.562606 },
    { lat: 48.608311, lng: 21.562675 },
    { lat: 48.608306, lng: 21.5627 },
    { lat: 48.608165, lng: 21.563405 },
    { lat: 48.608053, lng: 21.564058 },
    { lat: 48.607461, lng: 21.564575 },
    { lat: 48.607426, lng: 21.564608 },
    { lat: 48.607473, lng: 21.564725 },
    { lat: 48.607593, lng: 21.564948 },
    { lat: 48.60776, lng: 21.565329 },
    { lat: 48.607709, lng: 21.565509 },
    { lat: 48.607656, lng: 21.565815 },
    { lat: 48.607629, lng: 21.566032 },
    { lat: 48.6076, lng: 21.566208 },
    { lat: 48.607594, lng: 21.566246 },
    { lat: 48.607592, lng: 21.566287 },
    { lat: 48.607575, lng: 21.566657 },
    { lat: 48.607566, lng: 21.56688 },
    { lat: 48.607554, lng: 21.567184 },
    { lat: 48.607517, lng: 21.567231 },
    { lat: 48.608339, lng: 21.567839 },
    { lat: 48.608443, lng: 21.567911 },
    { lat: 48.608588, lng: 21.568068 },
    { lat: 48.608643, lng: 21.568174 },
    { lat: 48.609096, lng: 21.568628 },
    { lat: 48.609937, lng: 21.569198 },
    { lat: 48.610022, lng: 21.569256 },
    { lat: 48.610112, lng: 21.569323 },
    { lat: 48.610627, lng: 21.569655 },
    { lat: 48.610943, lng: 21.569926 },
    { lat: 48.611108, lng: 21.570199 },
    { lat: 48.611147, lng: 21.570328 },
    { lat: 48.611261, lng: 21.570535 },
    { lat: 48.611479, lng: 21.570809 },
    { lat: 48.612231, lng: 21.572098 },
    { lat: 48.61258, lng: 21.572582 },
    { lat: 48.612862, lng: 21.573249 },
    { lat: 48.613068, lng: 21.57356 },
    { lat: 48.613182, lng: 21.573669 },
    { lat: 48.613221, lng: 21.573706 },
    { lat: 48.613261, lng: 21.573744 },
    { lat: 48.613542, lng: 21.574007 },
    { lat: 48.614148, lng: 21.574908 },
    { lat: 48.614339, lng: 21.575362 },
    { lat: 48.614632, lng: 21.576352 },
    { lat: 48.614644, lng: 21.576944 },
    { lat: 48.614646, lng: 21.577036 },
    { lat: 48.61466, lng: 21.57702 },
    { lat: 48.614763, lng: 21.577883 },
    { lat: 48.615118, lng: 21.578111 },
    { lat: 48.615419, lng: 21.578474 },
    { lat: 48.615669, lng: 21.578822 },
    { lat: 48.616181, lng: 21.578478 },
    { lat: 48.616433, lng: 21.578158 },
    { lat: 48.616564, lng: 21.577896 },
    { lat: 48.616592, lng: 21.577842 },
    { lat: 48.617126, lng: 21.577272 },
    { lat: 48.617789, lng: 21.576862 },
    { lat: 48.618144, lng: 21.576502 },
    { lat: 48.618483, lng: 21.576187 },
    { lat: 48.618907, lng: 21.576046 },
    { lat: 48.619082, lng: 21.575666 },
    { lat: 48.619158, lng: 21.57557 },
    { lat: 48.619701, lng: 21.574909 },
    { lat: 48.619923, lng: 21.574627 },
    { lat: 48.620009, lng: 21.574518 },
    { lat: 48.620346, lng: 21.573862 },
    { lat: 48.620555, lng: 21.573438 },
    { lat: 48.620666, lng: 21.573194 },
    { lat: 48.620908, lng: 21.572495 },
    { lat: 48.621001, lng: 21.572202 },
    { lat: 48.621298, lng: 21.571238 },
    { lat: 48.621601, lng: 21.570906 },
    { lat: 48.622016, lng: 21.570449 },
    { lat: 48.622403, lng: 21.570205 },
    { lat: 48.622539, lng: 21.569954 },
    { lat: 48.622714, lng: 21.56965 },
    { lat: 48.622857, lng: 21.569411 },
    { lat: 48.623014, lng: 21.569154 },
    { lat: 48.623465, lng: 21.568205 },
    { lat: 48.623653, lng: 21.567824 },
    { lat: 48.623878, lng: 21.567389 },
    { lat: 48.624103, lng: 21.566602 },
    { lat: 48.624476, lng: 21.566531 },
    { lat: 48.624782, lng: 21.566415 },
    { lat: 48.625299, lng: 21.565649 },
    { lat: 48.625523, lng: 21.565561 },
    { lat: 48.62587, lng: 21.565309 },
    { lat: 48.626283, lng: 21.564937 },
    { lat: 48.62676, lng: 21.564384 },
    { lat: 48.627068, lng: 21.563672 },
    { lat: 48.627648, lng: 21.562924 },
    { lat: 48.627835, lng: 21.562686 },
    { lat: 48.628204, lng: 21.56205 },
    { lat: 48.628424, lng: 21.561522 },
    { lat: 48.628489, lng: 21.561398 },
    { lat: 48.628892, lng: 21.560635 },
    { lat: 48.629052, lng: 21.560407 },
    { lat: 48.629363, lng: 21.559993 },
    { lat: 48.62956, lng: 21.559673 },
    { lat: 48.629949, lng: 21.558954 },
    { lat: 48.630406, lng: 21.558519 },
    { lat: 48.630511, lng: 21.558431 },
    { lat: 48.631045, lng: 21.557838 },
    { lat: 48.631547, lng: 21.557367 },
    { lat: 48.631824, lng: 21.557038 },
    { lat: 48.632195, lng: 21.556435 },
    { lat: 48.632447, lng: 21.555942 },
    { lat: 48.632751, lng: 21.555574 },
    { lat: 48.633086, lng: 21.555345 },
    { lat: 48.633367, lng: 21.555127 },
    { lat: 48.633712, lng: 21.554839 },
    { lat: 48.633991, lng: 21.554599 },
    { lat: 48.634435, lng: 21.554216 },
    { lat: 48.635232, lng: 21.553508 },
    { lat: 48.635876, lng: 21.553133 },
    { lat: 48.63637, lng: 21.552383 },
    { lat: 48.637188, lng: 21.552138 },
    { lat: 48.637266, lng: 21.551969 },
    { lat: 48.637549, lng: 21.551354 },
    { lat: 48.63771, lng: 21.549702 },
    { lat: 48.637755, lng: 21.549247 },
    { lat: 48.638061, lng: 21.549473 },
    { lat: 48.638243, lng: 21.549706 },
    { lat: 48.638524, lng: 21.549752 },
    { lat: 48.638651, lng: 21.549815 },
    { lat: 48.638699, lng: 21.549839 },
    { lat: 48.638823, lng: 21.549728 },
    { lat: 48.638916, lng: 21.549544 },
    { lat: 48.639045, lng: 21.549502 },
    { lat: 48.639122, lng: 21.549456 },
    { lat: 48.639095, lng: 21.549088 },
    { lat: 48.639208, lng: 21.549009 },
    { lat: 48.639197, lng: 21.548902 },
    { lat: 48.638996, lng: 21.548654 },
    { lat: 48.639145, lng: 21.54842 },
    { lat: 48.639111, lng: 21.548127 },
    { lat: 48.639243, lng: 21.548038 },
    { lat: 48.639288, lng: 21.547651 },
    { lat: 48.639263, lng: 21.547421 },
    { lat: 48.639352, lng: 21.54715 },
    { lat: 48.63953, lng: 21.546681 },
    { lat: 48.639388, lng: 21.546453 },
    { lat: 48.639442, lng: 21.546212 },
    { lat: 48.639558, lng: 21.546135 },
    { lat: 48.639572, lng: 21.545906 },
    { lat: 48.639861, lng: 21.545478 },
    { lat: 48.640065, lng: 21.545121 },
    { lat: 48.640036, lng: 21.544732 },
    { lat: 48.640072, lng: 21.544595 },
    { lat: 48.640005, lng: 21.544433 },
    { lat: 48.640114, lng: 21.544226 },
    { lat: 48.640088, lng: 21.543928 },
    { lat: 48.640198, lng: 21.543469 },
    { lat: 48.640369, lng: 21.543327 },
    { lat: 48.640385, lng: 21.543288 },
    { lat: 48.640393, lng: 21.542577 },
    { lat: 48.640536, lng: 21.542104 },
    { lat: 48.640689, lng: 21.541954 },
    { lat: 48.640847, lng: 21.541783 },
    { lat: 48.641004, lng: 21.54179 },
    { lat: 48.641076, lng: 21.541704 },
    { lat: 48.64114, lng: 21.541493 },
    { lat: 48.641338, lng: 21.541393 },
    { lat: 48.64145, lng: 21.541223 },
    { lat: 48.641574, lng: 21.541302 },
    { lat: 48.64168, lng: 21.541206 },
    { lat: 48.641798, lng: 21.541182 },
    { lat: 48.641804, lng: 21.540997 },
    { lat: 48.641858, lng: 21.540918 },
    { lat: 48.641885, lng: 21.540912 },
    { lat: 48.642042, lng: 21.541045 },
    { lat: 48.642116, lng: 21.541058 },
    { lat: 48.642213, lng: 21.541119 },
    { lat: 48.642357, lng: 21.541127 },
    { lat: 48.642428, lng: 21.541045 },
    { lat: 48.642629, lng: 21.540968 },
    { lat: 48.643027, lng: 21.540952 },
    { lat: 48.643415, lng: 21.540967 },
    { lat: 48.643471, lng: 21.540794 },
    { lat: 48.64359, lng: 21.540489 },
    { lat: 48.643676, lng: 21.540497 },
    { lat: 48.643716, lng: 21.540576 },
    { lat: 48.643806, lng: 21.540578 },
    { lat: 48.643903, lng: 21.540432 },
    { lat: 48.644017, lng: 21.54013 },
    { lat: 48.644119, lng: 21.540163 },
    { lat: 48.644175, lng: 21.540356 },
    { lat: 48.644371, lng: 21.540193 },
    { lat: 48.644418, lng: 21.540158 },
    { lat: 48.644425, lng: 21.540137 },
    { lat: 48.644519, lng: 21.539877 },
    { lat: 48.64458, lng: 21.539704 },
    { lat: 48.644797, lng: 21.539546 },
    { lat: 48.644892, lng: 21.539352 },
    { lat: 48.644941, lng: 21.539337 },
    { lat: 48.645102, lng: 21.539392 },
    { lat: 48.645476, lng: 21.538916 },
    { lat: 48.6457, lng: 21.53838 },
    { lat: 48.645787, lng: 21.538049 },
    { lat: 48.645969, lng: 21.537941 },
    { lat: 48.646112, lng: 21.537802 },
    { lat: 48.646235, lng: 21.537729 },
    { lat: 48.646207, lng: 21.537575 },
    { lat: 48.646235, lng: 21.537521 },
    { lat: 48.64646, lng: 21.537506 },
    { lat: 48.646521, lng: 21.537569 },
    { lat: 48.646703, lng: 21.537546 },
    { lat: 48.646883, lng: 21.537422 },
    { lat: 48.646972, lng: 21.537522 },
    { lat: 48.64737, lng: 21.537378 },
    { lat: 48.647547, lng: 21.537285 },
    { lat: 48.647861, lng: 21.53731 },
    { lat: 48.647861, lng: 21.537237 },
    { lat: 48.647471, lng: 21.536674 },
    { lat: 48.647492, lng: 21.536447 },
    { lat: 48.647575, lng: 21.536509 },
    { lat: 48.647653, lng: 21.536339 },
    { lat: 48.647575, lng: 21.535999 },
    { lat: 48.648004, lng: 21.535932 },
    { lat: 48.648439, lng: 21.535646 },
    { lat: 48.649083, lng: 21.535035 },
    { lat: 48.649609, lng: 21.534311 },
    { lat: 48.649645, lng: 21.534266 },
    { lat: 48.649647, lng: 21.5343 },
    { lat: 48.649779, lng: 21.534162 },
    { lat: 48.649944, lng: 21.534121 },
    { lat: 48.650096, lng: 21.533856 },
    { lat: 48.650287, lng: 21.533662 },
    { lat: 48.650519, lng: 21.533389 },
    { lat: 48.650727, lng: 21.533395 },
    { lat: 48.650869, lng: 21.533259 },
    { lat: 48.651046, lng: 21.533315 },
    { lat: 48.651171, lng: 21.53339 },
    { lat: 48.65131, lng: 21.533405 },
    { lat: 48.651639, lng: 21.533542 },
    { lat: 48.65172, lng: 21.533518 },
    { lat: 48.651845, lng: 21.533317 },
    { lat: 48.652026, lng: 21.533293 },
    { lat: 48.652162, lng: 21.533167 },
    { lat: 48.652214, lng: 21.533163 },
    { lat: 48.652307, lng: 21.532943 },
    { lat: 48.652457, lng: 21.532834 },
    { lat: 48.652567, lng: 21.532526 },
    { lat: 48.652724, lng: 21.532429 },
    { lat: 48.652843, lng: 21.532231 },
    { lat: 48.652965, lng: 21.532215 },
    { lat: 48.653046, lng: 21.532229 },
    { lat: 48.653035, lng: 21.532063 },
    { lat: 48.653114, lng: 21.532059 },
    { lat: 48.653205, lng: 21.531994 },
    { lat: 48.653188, lng: 21.531911 },
    { lat: 48.653211, lng: 21.531822 },
    { lat: 48.653284, lng: 21.531813 },
    { lat: 48.65343, lng: 21.53175 },
    { lat: 48.653497, lng: 21.531511 },
    { lat: 48.653731, lng: 21.53118 },
    { lat: 48.653846, lng: 21.531296 },
    { lat: 48.653966, lng: 21.53135 },
    { lat: 48.654187, lng: 21.530844 },
    { lat: 48.654349, lng: 21.530532 },
    { lat: 48.654628, lng: 21.530792 },
    { lat: 48.655028, lng: 21.530817 },
    { lat: 48.65519, lng: 21.530509 },
    { lat: 48.655331, lng: 21.530345 },
    { lat: 48.655485, lng: 21.530341 },
    { lat: 48.655713, lng: 21.530437 },
    { lat: 48.656111, lng: 21.530783 },
    { lat: 48.65658, lng: 21.531011 },
    { lat: 48.656776, lng: 21.530983 },
    { lat: 48.656916, lng: 21.531113 },
    { lat: 48.65697, lng: 21.531137 },
    { lat: 48.657137, lng: 21.531167 },
    { lat: 48.657299, lng: 21.531154 },
    { lat: 48.6574, lng: 21.531152 },
    { lat: 48.657517, lng: 21.531234 },
    { lat: 48.657635, lng: 21.531278 },
    { lat: 48.657808, lng: 21.531535 },
    { lat: 48.65795, lng: 21.531504 },
    { lat: 48.658119, lng: 21.53151 },
    { lat: 48.658186, lng: 21.531617 },
    { lat: 48.658488, lng: 21.531507 },
    { lat: 48.658656, lng: 21.531618 },
    { lat: 48.658819, lng: 21.531632 },
    { lat: 48.65896, lng: 21.531779 },
    { lat: 48.659062, lng: 21.531805 },
    { lat: 48.659199, lng: 21.531797 },
    { lat: 48.659323, lng: 21.531856 },
    { lat: 48.659454, lng: 21.531988 },
    { lat: 48.659575, lng: 21.532127 },
    { lat: 48.659533, lng: 21.532202 },
    { lat: 48.659562, lng: 21.532218 },
    { lat: 48.659549, lng: 21.532238 },
    { lat: 48.659884, lng: 21.532473 },
    { lat: 48.660466, lng: 21.532393 },
    { lat: 48.660697, lng: 21.53248 },
    { lat: 48.660951, lng: 21.53248 },
    { lat: 48.661354, lng: 21.532591 },
    { lat: 48.661512, lng: 21.532676 },
    { lat: 48.66188, lng: 21.532602 },
    { lat: 48.662135, lng: 21.532647 },
    { lat: 48.662348, lng: 21.532492 },
    { lat: 48.662644, lng: 21.532379 },
    { lat: 48.662672, lng: 21.532395 },
    { lat: 48.662811, lng: 21.532744 },
    { lat: 48.663106, lng: 21.532759 },
    { lat: 48.66313, lng: 21.532776 },
    { lat: 48.663144, lng: 21.532823 },
    { lat: 48.663198, lng: 21.533163 },
    { lat: 48.663316, lng: 21.533349 },
    { lat: 48.663607, lng: 21.533486 },
    { lat: 48.664096, lng: 21.533617 },
    { lat: 48.664263, lng: 21.533431 },
    { lat: 48.664381, lng: 21.533348 },
    { lat: 48.664534, lng: 21.533277 },
    { lat: 48.664753, lng: 21.533306 },
    { lat: 48.665053, lng: 21.533319 },
    { lat: 48.665166, lng: 21.533269 },
    { lat: 48.665209, lng: 21.533272 },
    { lat: 48.665309, lng: 21.533548 },
    { lat: 48.665415, lng: 21.533675 },
    { lat: 48.665778, lng: 21.533827 },
    { lat: 48.665867, lng: 21.533897 },
    { lat: 48.665957, lng: 21.534077 },
    { lat: 48.666205, lng: 21.534488 },
    { lat: 48.666577, lng: 21.534495 },
    { lat: 48.666865, lng: 21.53469 },
    { lat: 48.666996, lng: 21.534872 },
    { lat: 48.667071, lng: 21.535294 },
    { lat: 48.667405, lng: 21.535402 },
    { lat: 48.667523, lng: 21.535556 },
    { lat: 48.667604, lng: 21.535597 },
    { lat: 48.667751, lng: 21.535455 },
    { lat: 48.667858, lng: 21.535741 },
    { lat: 48.66809, lng: 21.535886 },
    { lat: 48.66819, lng: 21.536017 },
    { lat: 48.668515, lng: 21.536265 },
    { lat: 48.6687, lng: 21.536151 },
    { lat: 48.668911, lng: 21.536285 },
    { lat: 48.668955, lng: 21.536351 },
    { lat: 48.66897, lng: 21.536544 },
    { lat: 48.669118, lng: 21.536705 },
    { lat: 48.669232, lng: 21.536786 },
    { lat: 48.66934, lng: 21.536796 },
    { lat: 48.669483, lng: 21.536868 },
    { lat: 48.669579, lng: 21.537052 },
    { lat: 48.670094, lng: 21.537192 },
    { lat: 48.670342, lng: 21.537051 },
    { lat: 48.670982, lng: 21.537435 },
    { lat: 48.671213, lng: 21.537435 },
    { lat: 48.671283, lng: 21.537471 },
    { lat: 48.671438, lng: 21.537721 },
    { lat: 48.671627, lng: 21.537647 },
    { lat: 48.67177, lng: 21.537666 },
    { lat: 48.672137, lng: 21.538009 },
    { lat: 48.67252, lng: 21.537912 },
    { lat: 48.672637, lng: 21.537835 },
    { lat: 48.672735, lng: 21.537874 },
    { lat: 48.672841, lng: 21.537979 },
    { lat: 48.672945, lng: 21.53794 },
    { lat: 48.673097, lng: 21.53801 },
    { lat: 48.673243, lng: 21.538095 },
    { lat: 48.67338, lng: 21.538148 },
    { lat: 48.673681, lng: 21.538345 },
    { lat: 48.673684, lng: 21.538455 },
    { lat: 48.673588, lng: 21.538519 },
    { lat: 48.673631, lng: 21.538706 },
    { lat: 48.673872, lng: 21.538673 },
    { lat: 48.674149, lng: 21.539034 },
    { lat: 48.674393, lng: 21.539128 },
    { lat: 48.674513, lng: 21.538917 },
    { lat: 48.674688, lng: 21.538854 },
    { lat: 48.674792, lng: 21.538896 },
    { lat: 48.674872, lng: 21.539065 },
    { lat: 48.675038, lng: 21.538949 },
    { lat: 48.675963, lng: 21.538702 },
    { lat: 48.676137, lng: 21.539006 },
    { lat: 48.676356, lng: 21.538972 },
    { lat: 48.676523, lng: 21.538857 },
    { lat: 48.676778, lng: 21.539059 },
    { lat: 48.676997, lng: 21.539222 },
    { lat: 48.677231, lng: 21.539275 },
    { lat: 48.677447, lng: 21.539192 },
    { lat: 48.677631, lng: 21.539357 },
    { lat: 48.677823, lng: 21.539401 },
    { lat: 48.677934, lng: 21.539502 },
    { lat: 48.67809, lng: 21.539745 },
    { lat: 48.67822, lng: 21.53972 },
    { lat: 48.679026, lng: 21.5404 },
    { lat: 48.679169, lng: 21.540602 },
    { lat: 48.679495, lng: 21.540547 },
    { lat: 48.680539, lng: 21.541333 },
    { lat: 48.680629, lng: 21.541389 },
    { lat: 48.680787, lng: 21.541439 },
    { lat: 48.680931, lng: 21.541392 },
    { lat: 48.68115, lng: 21.541595 },
    { lat: 48.681384, lng: 21.541428 },
    { lat: 48.681433, lng: 21.541404 },
    { lat: 48.681493, lng: 21.541426 },
    { lat: 48.681673, lng: 21.541557 },
    { lat: 48.68175, lng: 21.541545 },
    { lat: 48.681893, lng: 21.541341 },
    { lat: 48.681925, lng: 21.541339 },
    { lat: 48.682087, lng: 21.541538 },
    { lat: 48.682162, lng: 21.541602 },
    { lat: 48.682257, lng: 21.541581 },
    { lat: 48.682487, lng: 21.541397 },
    { lat: 48.682637, lng: 21.541478 },
    { lat: 48.682737, lng: 21.541314 },
    { lat: 48.682813, lng: 21.541238 },
    { lat: 48.683051, lng: 21.541344 },
    { lat: 48.683365, lng: 21.5414 },
    { lat: 48.683772, lng: 21.5411 },
    { lat: 48.683863, lng: 21.54116 },
    { lat: 48.68426, lng: 21.541167 },
    { lat: 48.684873, lng: 21.541264 },
    { lat: 48.685177, lng: 21.541396 },
    { lat: 48.685371, lng: 21.5416 },
    { lat: 48.685518, lng: 21.541456 },
    { lat: 48.685641, lng: 21.541276 },
    { lat: 48.685882, lng: 21.54152 },
    { lat: 48.686144, lng: 21.541478 },
    { lat: 48.686323, lng: 21.541581 },
    { lat: 48.686519, lng: 21.541493 },
    { lat: 48.686787, lng: 21.541497 },
    { lat: 48.686996, lng: 21.541376 },
    { lat: 48.687081, lng: 21.541273 },
    { lat: 48.68725, lng: 21.541388 },
    { lat: 48.687415, lng: 21.541436 },
    { lat: 48.687514, lng: 21.541391 },
    { lat: 48.687526, lng: 21.5413 },
    { lat: 48.688033, lng: 21.540874 },
    { lat: 48.688415, lng: 21.540674 },
    { lat: 48.688435, lng: 21.540598 },
    { lat: 48.688579, lng: 21.540599 },
    { lat: 48.688853, lng: 21.540538 },
    { lat: 48.688884, lng: 21.540319 },
    { lat: 48.688931, lng: 21.540221 },
    { lat: 48.689111, lng: 21.540206 },
    { lat: 48.689375, lng: 21.540098 },
    { lat: 48.689511, lng: 21.540368 },
    { lat: 48.689591, lng: 21.540137 },
    { lat: 48.689798, lng: 21.540058 },
    { lat: 48.689918, lng: 21.539907 },
    { lat: 48.690008, lng: 21.539814 },
    { lat: 48.690121, lng: 21.539744 },
    { lat: 48.690232, lng: 21.539413 },
    { lat: 48.690369, lng: 21.539423 },
    { lat: 48.690453, lng: 21.539274 },
    { lat: 48.690509, lng: 21.539114 },
    { lat: 48.690983, lng: 21.538847 },
    { lat: 48.691224, lng: 21.538687 },
    { lat: 48.6915, lng: 21.538603 },
    { lat: 48.691812, lng: 21.538294 },
    { lat: 48.691977, lng: 21.538103 },
    { lat: 48.692105, lng: 21.537896 },
    { lat: 48.692214, lng: 21.537661 },
    { lat: 48.692479, lng: 21.537447 },
    { lat: 48.692524, lng: 21.537469 },
    { lat: 48.692735, lng: 21.537539 },
    { lat: 48.692812, lng: 21.53735 },
    { lat: 48.692859, lng: 21.537275 },
    { lat: 48.692901, lng: 21.537278 },
    { lat: 48.693068, lng: 21.537391 },
    { lat: 48.693154, lng: 21.5373 },
    { lat: 48.693497, lng: 21.537081 },
    { lat: 48.693629, lng: 21.537141 },
    { lat: 48.693697, lng: 21.537125 },
    { lat: 48.693734, lng: 21.537097 },
    { lat: 48.693847, lng: 21.53687 },
    { lat: 48.694027, lng: 21.536873 },
    { lat: 48.6941, lng: 21.536828 },
    { lat: 48.694802, lng: 21.536791 },
    { lat: 48.694929, lng: 21.537039 },
    { lat: 48.69502, lng: 21.537003 },
    { lat: 48.695232, lng: 21.537244 },
    { lat: 48.695367, lng: 21.537237 },
    { lat: 48.695579, lng: 21.537389 },
    { lat: 48.695689, lng: 21.537386 },
    { lat: 48.695892, lng: 21.537489 },
    { lat: 48.69592, lng: 21.537725 },
    { lat: 48.696035, lng: 21.537821 },
    { lat: 48.696111, lng: 21.537927 },
    { lat: 48.696235, lng: 21.538002 },
    { lat: 48.696427, lng: 21.538069 },
    { lat: 48.696814, lng: 21.538187 },
    { lat: 48.697187, lng: 21.538319 },
    { lat: 48.697519, lng: 21.538391 },
    { lat: 48.697619, lng: 21.538301 },
    { lat: 48.697783, lng: 21.538224 },
    { lat: 48.697931, lng: 21.538328 },
    { lat: 48.698141, lng: 21.538366 },
    { lat: 48.698252, lng: 21.538217 },
    { lat: 48.698355, lng: 21.538234 },
    { lat: 48.698431, lng: 21.538219 },
    { lat: 48.698654, lng: 21.537957 },
    { lat: 48.698873, lng: 21.538096 },
    { lat: 48.699119, lng: 21.538011 },
    { lat: 48.699263, lng: 21.537869 },
    { lat: 48.699277, lng: 21.537809 },
    { lat: 48.699356, lng: 21.537788 },
    { lat: 48.699429, lng: 21.537666 },
    { lat: 48.699501, lng: 21.537586 },
    { lat: 48.699635, lng: 21.537552 },
    { lat: 48.699839, lng: 21.537576 },
    { lat: 48.70001, lng: 21.537486 },
    { lat: 48.700252, lng: 21.537432 },
    { lat: 48.700451, lng: 21.537422 },
    { lat: 48.700719, lng: 21.537473 },
    { lat: 48.700971, lng: 21.537452 },
    { lat: 48.701162, lng: 21.537383 },
    { lat: 48.701398, lng: 21.537232 },
    { lat: 48.701546, lng: 21.536974 },
    { lat: 48.701814, lng: 21.536866 },
    { lat: 48.701799, lng: 21.536557 },
    { lat: 48.701865, lng: 21.536268 },
    { lat: 48.70201, lng: 21.535847 },
    { lat: 48.70226, lng: 21.535684 },
    { lat: 48.702435, lng: 21.535644 },
    { lat: 48.703236, lng: 21.535309 },
    { lat: 48.7034362, lng: 21.5350725 },
    { lat: 48.703646, lng: 21.5346926 },
    { lat: 48.7039603, lng: 21.5339558 },
    { lat: 48.7043168, lng: 21.5319801 },
    { lat: 48.704349, lng: 21.5312894 },
    { lat: 48.7044203, lng: 21.5308302 },
    { lat: 48.7045634, lng: 21.5302089 },
    { lat: 48.7048945, lng: 21.5300626 },
    { lat: 48.705019, lng: 21.5294525 },
    { lat: 48.7051704, lng: 21.5290461 },
    { lat: 48.7052627, lng: 21.5278624 },
    { lat: 48.7051619, lng: 21.5272049 },
    { lat: 48.7051577, lng: 21.5268287 },
    { lat: 48.7052391, lng: 21.5262095 },
    { lat: 48.7052046, lng: 21.5252735 },
    { lat: 48.7047423, lng: 21.5239421 },
    { lat: 48.7046296, lng: 21.5233877 },
    { lat: 48.7046911, lng: 21.5227456 },
    { lat: 48.7044909, lng: 21.5219208 },
    { lat: 48.7039635, lng: 21.5218566 },
    { lat: 48.7036167, lng: 21.521922 },
    { lat: 48.7034284, lng: 21.5218274 },
    { lat: 48.7029505, lng: 21.5219609 },
    { lat: 48.7020558, lng: 21.521917 },
    { lat: 48.7017842, lng: 21.5216267 },
    { lat: 48.7017777, lng: 21.5213603 },
    { lat: 48.7015497, lng: 21.5205844 },
    { lat: 48.7016273, lng: 21.5205846 },
    { lat: 48.7016669, lng: 21.520322 },
    { lat: 48.7015176, lng: 21.5198195 },
    { lat: 48.7015509, lng: 21.5194501 },
    { lat: 48.7015192, lng: 21.5190518 },
    { lat: 48.7016399, lng: 21.5184337 },
    { lat: 48.7019019, lng: 21.5179574 },
    { lat: 48.7019677, lng: 21.5169097 },
    { lat: 48.7019418, lng: 21.5158499 },
    { lat: 48.7018617, lng: 21.5155723 },
    { lat: 48.7020174, lng: 21.5146395 },
    { lat: 48.7019253, lng: 21.5140574 },
    { lat: 48.7019969, lng: 21.5125929 },
    { lat: 48.7018482, lng: 21.5117663 },
    { lat: 48.7019409, lng: 21.5113481 },
    { lat: 48.7018371, lng: 21.509728 },
    { lat: 48.7017384, lng: 21.5092286 },
    { lat: 48.7014879, lng: 21.5087759 },
    { lat: 48.7014359, lng: 21.5083765 },
    { lat: 48.7013139, lng: 21.5079716 },
    { lat: 48.701211, lng: 21.5067797 },
    { lat: 48.7005774, lng: 21.5063012 },
    { lat: 48.7008145, lng: 21.5055112 },
    { lat: 48.700333, lng: 21.5046501 },
    { lat: 48.6998684, lng: 21.5039497 },
    { lat: 48.6994005, lng: 21.5034864 },
    { lat: 48.6988456, lng: 21.5028197 },
    { lat: 48.6988123, lng: 21.5028742 },
    { lat: 48.6986866, lng: 21.5031194 },
    { lat: 48.69811, lng: 21.5034201 },
    { lat: 48.6977818, lng: 21.5034285 },
    { lat: 48.6965328, lng: 21.5031913 },
    { lat: 48.6962881, lng: 21.5032569 },
    { lat: 48.6958908, lng: 21.503055 },
    { lat: 48.6955894, lng: 21.5027489 },
    { lat: 48.695281, lng: 21.5025854 },
    { lat: 48.6945567, lng: 21.5027986 },
    { lat: 48.6937736, lng: 21.5024454 },
    { lat: 48.6929519, lng: 21.5022002 },
    { lat: 48.6925446, lng: 21.5018131 },
    { lat: 48.6921161, lng: 21.5017444 },
    { lat: 48.6917174, lng: 21.5018432 },
    { lat: 48.6914008, lng: 21.5016738 },
    { lat: 48.6910161, lng: 21.5016364 },
    { lat: 48.6902806, lng: 21.5010298 },
    { lat: 48.6896965, lng: 21.5006333 },
    { lat: 48.6894255, lng: 21.5005236 },
    { lat: 48.6888372, lng: 21.4999868 },
    { lat: 48.6885235, lng: 21.4995234 },
    { lat: 48.6884341, lng: 21.4987615 },
    { lat: 48.6881676, lng: 21.4980322 },
    { lat: 48.688099, lng: 21.4972475 },
    { lat: 48.6880684, lng: 21.4957585 },
    { lat: 48.6879289, lng: 21.4952573 },
    { lat: 48.6877446, lng: 21.4949906 },
    { lat: 48.6876397, lng: 21.4947037 },
    { lat: 48.6874204, lng: 21.4944332 },
    { lat: 48.6871348, lng: 21.4937772 },
    { lat: 48.6866339, lng: 21.493661 },
    { lat: 48.6861642, lng: 21.4933269 },
    { lat: 48.685714, lng: 21.4926204 },
    { lat: 48.6850294, lng: 21.4921057 },
    { lat: 48.6849954, lng: 21.4912355 },
    { lat: 48.6850648, lng: 21.4897855 },
    { lat: 48.6848945, lng: 21.4883641 },
    { lat: 48.6846528, lng: 21.4874026 },
    { lat: 48.6842348, lng: 21.4866481 },
    { lat: 48.6838436, lng: 21.4863118 },
    { lat: 48.6832765, lng: 21.4861167 },
    { lat: 48.6829648, lng: 21.4861583 },
    { lat: 48.6819211, lng: 21.4866356 },
    { lat: 48.6807521, lng: 21.4861714 },
    { lat: 48.6804669, lng: 21.4860892 },
    { lat: 48.6800031, lng: 21.4859556 },
    { lat: 48.6799704, lng: 21.485836 },
    { lat: 48.6799123, lng: 21.4858092 },
    { lat: 48.6790343, lng: 21.4861555 },
    { lat: 48.6787156, lng: 21.4861021 },
    { lat: 48.6785961, lng: 21.4862168 },
    { lat: 48.6783629, lng: 21.4862023 },
    { lat: 48.6781958, lng: 21.4861062 },
    { lat: 48.6781455, lng: 21.4862743 },
    { lat: 48.6780606, lng: 21.4862963 },
    { lat: 48.6775652, lng: 21.4861285 },
    { lat: 48.6774735, lng: 21.486151 },
    { lat: 48.6773506, lng: 21.4862157 },
    { lat: 48.6771599, lng: 21.486469 },
    { lat: 48.6770013, lng: 21.4864744 },
    { lat: 48.6767853, lng: 21.4866261 },
    { lat: 48.6766922, lng: 21.4864503 },
    { lat: 48.676568, lng: 21.4865105 },
    { lat: 48.6763216, lng: 21.4863957 },
    { lat: 48.6756905, lng: 21.4863598 },
    { lat: 48.6754529, lng: 21.4864765 },
    { lat: 48.675304, lng: 21.4863425 },
    { lat: 48.6751335, lng: 21.4863637 },
    { lat: 48.6750662, lng: 21.4862863 },
    { lat: 48.6750067, lng: 21.4863746 },
    { lat: 48.6745167, lng: 21.4863165 },
    { lat: 48.6743507, lng: 21.4860654 },
    { lat: 48.6742415, lng: 21.4860224 },
    { lat: 48.6736078, lng: 21.485391 },
    { lat: 48.6732529, lng: 21.484797 },
    { lat: 48.6730751, lng: 21.4846795 },
    { lat: 48.6729535, lng: 21.4839734 },
    { lat: 48.6728373, lng: 21.4840003 },
    { lat: 48.672839, lng: 21.4837912 },
    { lat: 48.6726985, lng: 21.4835641 },
    { lat: 48.6726503, lng: 21.4831037 },
    { lat: 48.672452, lng: 21.4827473 },
  ],
  Vajkovce: [
    { lat: 48.7813091, lng: 21.3134381 },
    { lat: 48.7813053, lng: 21.3136075 },
    { lat: 48.7806098, lng: 21.3136427 },
    { lat: 48.7801584, lng: 21.3134956 },
    { lat: 48.7797531, lng: 21.313223 },
    { lat: 48.7795562, lng: 21.3131843 },
    { lat: 48.7789527, lng: 21.31344 },
    { lat: 48.7783904, lng: 21.3140806 },
    { lat: 48.7781142, lng: 21.3142872 },
    { lat: 48.7770821, lng: 21.3148017 },
    { lat: 48.7771469, lng: 21.3150002 },
    { lat: 48.77722, lng: 21.315227 },
    { lat: 48.779314, lng: 21.3273986 },
    { lat: 48.7772936, lng: 21.3279823 },
    { lat: 48.777463, lng: 21.328782 },
    { lat: 48.7779283, lng: 21.3286894 },
    { lat: 48.7781107, lng: 21.329059 },
    { lat: 48.7791652, lng: 21.3288022 },
    { lat: 48.7793263, lng: 21.3293712 },
    { lat: 48.7796587, lng: 21.3297603 },
    { lat: 48.7789132, lng: 21.330442 },
    { lat: 48.7787732, lng: 21.3306562 },
    { lat: 48.7788612, lng: 21.3308442 },
    { lat: 48.779182, lng: 21.3314464 },
    { lat: 48.7796387, lng: 21.3320688 },
    { lat: 48.7798162, lng: 21.3324747 },
    { lat: 48.7800737, lng: 21.3325764 },
    { lat: 48.7803149, lng: 21.332888 },
    { lat: 48.7809098, lng: 21.3340647 },
    { lat: 48.7810646, lng: 21.3346135 },
    { lat: 48.7812228, lng: 21.3348577 },
    { lat: 48.7813765, lng: 21.3353627 },
    { lat: 48.7815486, lng: 21.3356577 },
    { lat: 48.7817039, lng: 21.3358118 },
    { lat: 48.7820417, lng: 21.335924 },
    { lat: 48.7822169, lng: 21.3360657 },
    { lat: 48.782942, lng: 21.3375354 },
    { lat: 48.783354, lng: 21.3379119 },
    { lat: 48.7837072, lng: 21.3385762 },
    { lat: 48.7838941, lng: 21.3388049 },
    { lat: 48.7842449, lng: 21.3390551 },
    { lat: 48.7843969, lng: 21.340442 },
    { lat: 48.784554, lng: 21.3406866 },
    { lat: 48.7849797, lng: 21.3409263 },
    { lat: 48.7854147, lng: 21.3417984 },
    { lat: 48.7854649, lng: 21.3417503 },
    { lat: 48.7854839, lng: 21.3418582 },
    { lat: 48.785714, lng: 21.3421392 },
    { lat: 48.7859962, lng: 21.342396 },
    { lat: 48.786374, lng: 21.3430918 },
    { lat: 48.7864549, lng: 21.3434228 },
    { lat: 48.7864684, lng: 21.3435357 },
    { lat: 48.7865697, lng: 21.3437592 },
    { lat: 48.7867178, lng: 21.3438611 },
    { lat: 48.7867984, lng: 21.3440321 },
    { lat: 48.7867491, lng: 21.344296 },
    { lat: 48.7866254, lng: 21.3444502 },
    { lat: 48.786591, lng: 21.3446938 },
    { lat: 48.7867305, lng: 21.3450674 },
    { lat: 48.7869952, lng: 21.3451084 },
    { lat: 48.7873758, lng: 21.3455315 },
    { lat: 48.7874042, lng: 21.3456533 },
    { lat: 48.7875091, lng: 21.3457717 },
    { lat: 48.7878583, lng: 21.3457866 },
    { lat: 48.7880162, lng: 21.3456883 },
    { lat: 48.7881925, lng: 21.3462332 },
    { lat: 48.7883272, lng: 21.3463709 },
    { lat: 48.7890205, lng: 21.3466368 },
    { lat: 48.7896877, lng: 21.3456616 },
    { lat: 48.7900066, lng: 21.3461653 },
    { lat: 48.7906549, lng: 21.3457519 },
    { lat: 48.7920235, lng: 21.3450937 },
    { lat: 48.7930283, lng: 21.3443749 },
    { lat: 48.7931762, lng: 21.3447659 },
    { lat: 48.7936461, lng: 21.3444897 },
    { lat: 48.7938913, lng: 21.344326 },
    { lat: 48.7942615, lng: 21.344373 },
    { lat: 48.7946929, lng: 21.3442038 },
    { lat: 48.7952114, lng: 21.3437783 },
    { lat: 48.796128, lng: 21.343467 },
    { lat: 48.7960541, lng: 21.3433607 },
    { lat: 48.795914, lng: 21.3431184 },
    { lat: 48.7956364, lng: 21.3422392 },
    { lat: 48.7951548, lng: 21.3401012 },
    { lat: 48.7953796, lng: 21.3395368 },
    { lat: 48.7959409, lng: 21.3387814 },
    { lat: 48.7956329, lng: 21.3374117 },
    { lat: 48.7954146, lng: 21.3358821 },
    { lat: 48.7952279, lng: 21.335139 },
    { lat: 48.7951937, lng: 21.3347563 },
    { lat: 48.7959743, lng: 21.3340327 },
    { lat: 48.7964743, lng: 21.3333007 },
    { lat: 48.796729, lng: 21.3327566 },
    { lat: 48.7969323, lng: 21.3320312 },
    { lat: 48.7970127, lng: 21.3314138 },
    { lat: 48.7969587, lng: 21.3311559 },
    { lat: 48.7968289, lng: 21.330995 },
    { lat: 48.7965294, lng: 21.3301288 },
    { lat: 48.7963852, lng: 21.3283788 },
    { lat: 48.7959984, lng: 21.3251065 },
    { lat: 48.7957761, lng: 21.3239412 },
    { lat: 48.7954086, lng: 21.3229866 },
    { lat: 48.7951961, lng: 21.32333 },
    { lat: 48.7946387, lng: 21.3238745 },
    { lat: 48.794587, lng: 21.3236864 },
    { lat: 48.794311, lng: 21.3237152 },
    { lat: 48.7941968, lng: 21.32351 },
    { lat: 48.7939633, lng: 21.3233834 },
    { lat: 48.7938793, lng: 21.323466 },
    { lat: 48.7940275, lng: 21.3242802 },
    { lat: 48.7938818, lng: 21.324336 },
    { lat: 48.7935133, lng: 21.3240596 },
    { lat: 48.7931433, lng: 21.3239507 },
    { lat: 48.7929091, lng: 21.3227956 },
    { lat: 48.7934632, lng: 21.3222557 },
    { lat: 48.7938099, lng: 21.3217741 },
    { lat: 48.7940792, lng: 21.3208956 },
    { lat: 48.794738, lng: 21.3128552 },
    { lat: 48.7947638, lng: 21.3125258 },
    { lat: 48.7946425, lng: 21.3121966 },
    { lat: 48.7945628, lng: 21.3122046 },
    { lat: 48.7943193, lng: 21.3115937 },
    { lat: 48.7938033, lng: 21.3116846 },
    { lat: 48.7937963, lng: 21.3115341 },
    { lat: 48.7934729, lng: 21.3114067 },
    { lat: 48.793232, lng: 21.3114781 },
    { lat: 48.7929361, lng: 21.3118121 },
    { lat: 48.7928097, lng: 21.3118069 },
    { lat: 48.7923363, lng: 21.3122214 },
    { lat: 48.7922959, lng: 21.3123741 },
    { lat: 48.7916572, lng: 21.3132769 },
    { lat: 48.7915237, lng: 21.313627 },
    { lat: 48.7813707, lng: 21.3133931 },
    { lat: 48.7813091, lng: 21.3134381 },
  ],
  Bačkovík: [
    { lat: 48.7974338, lng: 21.4328176 },
    { lat: 48.7967212, lng: 21.4329615 },
    { lat: 48.7964242, lng: 21.432853 },
    { lat: 48.7961974, lng: 21.4321783 },
    { lat: 48.7957998, lng: 21.4315641 },
    { lat: 48.795385, lng: 21.4307346 },
    { lat: 48.7952123, lng: 21.430313 },
    { lat: 48.7947201, lng: 21.4290556 },
    { lat: 48.7943983, lng: 21.4278523 },
    { lat: 48.7935157, lng: 21.424807 },
    { lat: 48.7934442, lng: 21.4243306 },
    { lat: 48.7928349, lng: 21.4205634 },
    { lat: 48.792544, lng: 21.4206205 },
    { lat: 48.7922323, lng: 21.4205823 },
    { lat: 48.7920862, lng: 21.4197121 },
    { lat: 48.7920752, lng: 21.4196425 },
    { lat: 48.7918836, lng: 21.4195184 },
    { lat: 48.7915041, lng: 21.4195058 },
    { lat: 48.79039, lng: 21.4197319 },
    { lat: 48.789968, lng: 21.4198881 },
    { lat: 48.7893405, lng: 21.4198198 },
    { lat: 48.7889786, lng: 21.4199913 },
    { lat: 48.7887095, lng: 21.4200194 },
    { lat: 48.7882487, lng: 21.419658 },
    { lat: 48.7877532, lng: 21.4194537 },
    { lat: 48.7875134, lng: 21.4196783 },
    { lat: 48.7869451, lng: 21.4198319 },
    { lat: 48.7862131, lng: 21.420531 },
    { lat: 48.7860559, lng: 21.4205834 },
    { lat: 48.7858388, lng: 21.4204717 },
    { lat: 48.7856123, lng: 21.4199632 },
    { lat: 48.7855546, lng: 21.4194218 },
    { lat: 48.7854285, lng: 21.4193739 },
    { lat: 48.7851327, lng: 21.4194992 },
    { lat: 48.7848499, lng: 21.4194105 },
    { lat: 48.7842093, lng: 21.419667 },
    { lat: 48.7833488, lng: 21.419562 },
    { lat: 48.7832068, lng: 21.4194781 },
    { lat: 48.7829297, lng: 21.419541 },
    { lat: 48.7816939, lng: 21.4212157 },
    { lat: 48.7817256, lng: 21.4214123 },
    { lat: 48.7816371, lng: 21.4215849 },
    { lat: 48.7813509, lng: 21.421875 },
    { lat: 48.7812675, lng: 21.4217886 },
    { lat: 48.7808496, lng: 21.4216983 },
    { lat: 48.7802853, lng: 21.4221795 },
    { lat: 48.7793015, lng: 21.422665 },
    { lat: 48.7783087, lng: 21.4239621 },
    { lat: 48.7772952, lng: 21.4238229 },
    { lat: 48.7768142, lng: 21.4239595 },
    { lat: 48.7764561, lng: 21.4241511 },
    { lat: 48.7762349, lng: 21.4242385 },
    { lat: 48.775409, lng: 21.4248046 },
    { lat: 48.7748026, lng: 21.425334 },
    { lat: 48.7746258, lng: 21.4256156 },
    { lat: 48.7744236, lng: 21.4255822 },
    { lat: 48.7740948, lng: 21.4257548 },
    { lat: 48.7738454, lng: 21.4259302 },
    { lat: 48.7734752, lng: 21.4262782 },
    { lat: 48.7733114, lng: 21.4266859 },
    { lat: 48.7731933, lng: 21.4270944 },
    { lat: 48.7731052, lng: 21.4275377 },
    { lat: 48.7725112, lng: 21.4281545 },
    { lat: 48.7720352, lng: 21.4284634 },
    { lat: 48.7721744, lng: 21.4301046 },
    { lat: 48.7721562, lng: 21.4304012 },
    { lat: 48.7720384, lng: 21.4305315 },
    { lat: 48.771941, lng: 21.4305074 },
    { lat: 48.7719402, lng: 21.4306316 },
    { lat: 48.7718196, lng: 21.4307816 },
    { lat: 48.7718246, lng: 21.4310228 },
    { lat: 48.7717606, lng: 21.4314145 },
    { lat: 48.7718597, lng: 21.4324545 },
    { lat: 48.7717714, lng: 21.4326663 },
    { lat: 48.7718573, lng: 21.4329106 },
    { lat: 48.7717848, lng: 21.4331238 },
    { lat: 48.7718389, lng: 21.4332379 },
    { lat: 48.7717967, lng: 21.433449 },
    { lat: 48.7718754, lng: 21.4337184 },
    { lat: 48.7718369, lng: 21.4339429 },
    { lat: 48.7720075, lng: 21.4341558 },
    { lat: 48.7721278, lng: 21.4341081 },
    { lat: 48.7722849, lng: 21.4341761 },
    { lat: 48.7726923, lng: 21.4341723 },
    { lat: 48.7729379, lng: 21.4359537 },
    { lat: 48.7737943, lng: 21.4399867 },
    { lat: 48.7741552, lng: 21.4425953 },
    { lat: 48.7744422, lng: 21.4435974 },
    { lat: 48.7749342, lng: 21.4437949 },
    { lat: 48.7760878, lng: 21.4441122 },
    { lat: 48.7780945, lng: 21.4453176 },
    { lat: 48.7803343, lng: 21.4454952 },
    { lat: 48.7806689, lng: 21.4457375 },
    { lat: 48.7808513, lng: 21.4463957 },
    { lat: 48.7810286, lng: 21.4466144 },
    { lat: 48.7814534, lng: 21.4469406 },
    { lat: 48.7819426, lng: 21.4469689 },
    { lat: 48.782694, lng: 21.4466542 },
    { lat: 48.7830738, lng: 21.4465938 },
    { lat: 48.7834367, lng: 21.446612 },
    { lat: 48.7841529, lng: 21.4467832 },
    { lat: 48.7851821, lng: 21.4473495 },
    { lat: 48.7855517, lng: 21.4476437 },
    { lat: 48.7868817, lng: 21.4480279 },
    { lat: 48.7874066, lng: 21.4483497 },
    { lat: 48.7897564, lng: 21.4495171 },
    { lat: 48.790108, lng: 21.4496478 },
    { lat: 48.7915545, lng: 21.4425023 },
    { lat: 48.7917869, lng: 21.442103 },
    { lat: 48.7950947, lng: 21.4326789 },
    { lat: 48.7961815, lng: 21.4328664 },
    { lat: 48.7967061, lng: 21.4330985 },
    { lat: 48.797145, lng: 21.4330287 },
    { lat: 48.7973902, lng: 21.4328669 },
    { lat: 48.7974338, lng: 21.4328176 },
  ],
  Peder: [
    { lat: 48.542892, lng: 20.941726 },
    { lat: 48.5429258, lng: 20.9417992 },
    { lat: 48.5439959, lng: 20.9441148 },
    { lat: 48.5440893, lng: 20.9445481 },
    { lat: 48.5444244, lng: 20.9450067 },
    { lat: 48.5453811, lng: 20.9457325 },
    { lat: 48.547575, lng: 20.9470057 },
    { lat: 48.5494283, lng: 20.947684 },
    { lat: 48.5503759, lng: 20.9477146 },
    { lat: 48.5506568, lng: 20.9475318 },
    { lat: 48.5518339, lng: 20.947353 },
    { lat: 48.551944, lng: 20.9471115 },
    { lat: 48.552011, lng: 20.9471327 },
    { lat: 48.5525085, lng: 20.9467991 },
    { lat: 48.5549079, lng: 20.9456428 },
    { lat: 48.5562126, lng: 20.9453788 },
    { lat: 48.5574543, lng: 20.94568 },
    { lat: 48.5579022, lng: 20.9456788 },
    { lat: 48.558346, lng: 20.9452913 },
    { lat: 48.5589373, lng: 20.9445624 },
    { lat: 48.5593263, lng: 20.9441862 },
    { lat: 48.5597951, lng: 20.9441336 },
    { lat: 48.5613712, lng: 20.9442369 },
    { lat: 48.5622533, lng: 20.9439868 },
    { lat: 48.5632384, lng: 20.9438279 },
    { lat: 48.5635933, lng: 20.9438679 },
    { lat: 48.5638846, lng: 20.9437283 },
    { lat: 48.5641352, lng: 20.9437511 },
    { lat: 48.5646544, lng: 20.9435283 },
    { lat: 48.5648882, lng: 20.9435861 },
    { lat: 48.5650281, lng: 20.943692 },
    { lat: 48.5652644, lng: 20.9433267 },
    { lat: 48.5657286, lng: 20.9435701 },
    { lat: 48.565587, lng: 20.9437789 },
    { lat: 48.5655925, lng: 20.9441385 },
    { lat: 48.5656888, lng: 20.9448963 },
    { lat: 48.5658452, lng: 20.9450222 },
    { lat: 48.5661488, lng: 20.9450847 },
    { lat: 48.566478, lng: 20.9453985 },
    { lat: 48.5666729, lng: 20.9460006 },
    { lat: 48.5669545, lng: 20.9465754 },
    { lat: 48.5670577, lng: 20.9471297 },
    { lat: 48.5669588, lng: 20.9474383 },
    { lat: 48.5671211, lng: 20.9480479 },
    { lat: 48.567264, lng: 20.9482488 },
    { lat: 48.5671743, lng: 20.9495901 },
    { lat: 48.567201, lng: 20.9505534 },
    { lat: 48.567277, lng: 20.9508695 },
    { lat: 48.5673506, lng: 20.95239 },
    { lat: 48.5675033, lng: 20.9538174 },
    { lat: 48.5673276, lng: 20.964439 },
    { lat: 48.5677035, lng: 20.9647111 },
    { lat: 48.5676741, lng: 20.9647702 },
    { lat: 48.5692471, lng: 20.9674052 },
    { lat: 48.5704122, lng: 20.969157 },
    { lat: 48.5708679, lng: 20.9700581 },
    { lat: 48.5711726, lng: 20.9705482 },
    { lat: 48.5713102, lng: 20.9706572 },
    { lat: 48.5714838, lng: 20.9707005 },
    { lat: 48.5733667, lng: 20.9710635 },
    { lat: 48.5735124, lng: 20.9711801 },
    { lat: 48.573692, lng: 20.971599 },
    { lat: 48.5753957, lng: 20.9716195 },
    { lat: 48.5770926, lng: 20.9718019 },
    { lat: 48.5770951, lng: 20.9717431 },
    { lat: 48.5780021, lng: 20.9719372 },
    { lat: 48.579143, lng: 20.9718587 },
    { lat: 48.5831637, lng: 20.9719922 },
    { lat: 48.5838583, lng: 20.9719618 },
    { lat: 48.5898505, lng: 20.9724771 },
    { lat: 48.5912141, lng: 20.9724225 },
    { lat: 48.5921472, lng: 20.9731975 },
    { lat: 48.5930363, lng: 20.9737988 },
    { lat: 48.5933683, lng: 20.9740025 },
    { lat: 48.5933756, lng: 20.9739511 },
    { lat: 48.5934869, lng: 20.9740501 },
    { lat: 48.5931889, lng: 20.9710905 },
    { lat: 48.5932296, lng: 20.9708986 },
    { lat: 48.5931806, lng: 20.9704778 },
    { lat: 48.5932362, lng: 20.9704543 },
    { lat: 48.5930419, lng: 20.9694898 },
    { lat: 48.5927038, lng: 20.9670287 },
    { lat: 48.5937377, lng: 20.9666083 },
    { lat: 48.5936001, lng: 20.9652551 },
    { lat: 48.5935955, lng: 20.9640411 },
    { lat: 48.5933427, lng: 20.9595264 },
    { lat: 48.5930237, lng: 20.9596147 },
    { lat: 48.5924509, lng: 20.9573728 },
    { lat: 48.5920814, lng: 20.9563915 },
    { lat: 48.5919553, lng: 20.9564569 },
    { lat: 48.5911554, lng: 20.9540194 },
    { lat: 48.590785, lng: 20.9532175 },
    { lat: 48.5905181, lng: 20.9524794 },
    { lat: 48.5899223, lng: 20.9501224 },
    { lat: 48.5892486, lng: 20.94563 },
    { lat: 48.5888837, lng: 20.943916 },
    { lat: 48.5882064, lng: 20.9419594 },
    { lat: 48.5879202, lng: 20.941314 },
    { lat: 48.5876802, lng: 20.9404723 },
    { lat: 48.5875175, lng: 20.9395838 },
    { lat: 48.5872277, lng: 20.9397418 },
    { lat: 48.5863539, lng: 20.9371323 },
    { lat: 48.5862532, lng: 20.9358815 },
    { lat: 48.5862457, lng: 20.9357917 },
    { lat: 48.5860528, lng: 20.936011 },
    { lat: 48.5858571, lng: 20.9363833 },
    { lat: 48.5856464, lng: 20.9365498 },
    { lat: 48.5855033, lng: 20.9369071 },
    { lat: 48.5852874, lng: 20.937116 },
    { lat: 48.5852109, lng: 20.937349 },
    { lat: 48.584937, lng: 20.9374742 },
    { lat: 48.5847756, lng: 20.9376252 },
    { lat: 48.5843941, lng: 20.9381266 },
    { lat: 48.5842565, lng: 20.9381891 },
    { lat: 48.5840983, lng: 20.938433 },
    { lat: 48.5839217, lng: 20.9384355 },
    { lat: 48.5838927, lng: 20.9387449 },
    { lat: 48.5838173, lng: 20.9388168 },
    { lat: 48.5837324, lng: 20.9386715 },
    { lat: 48.5836936, lng: 20.9387164 },
    { lat: 48.5831643, lng: 20.940045 },
    { lat: 48.5827802, lng: 20.9407421 },
    { lat: 48.5822551, lng: 20.9410557 },
    { lat: 48.5821991, lng: 20.9412335 },
    { lat: 48.5817868, lng: 20.9412206 },
    { lat: 48.5815036, lng: 20.9413355 },
    { lat: 48.5811281, lng: 20.9413493 },
    { lat: 48.580314, lng: 20.9410736 },
    { lat: 48.5802904, lng: 20.9411433 },
    { lat: 48.5801481, lng: 20.9411286 },
    { lat: 48.5800354, lng: 20.9410245 },
    { lat: 48.5799453, lng: 20.9410599 },
    { lat: 48.5798422, lng: 20.9410027 },
    { lat: 48.5796607, lng: 20.9406284 },
    { lat: 48.5794326, lng: 20.9405727 },
    { lat: 48.5789011, lng: 20.9398018 },
    { lat: 48.5782571, lng: 20.9395636 },
    { lat: 48.5778719, lng: 20.9394939 },
    { lat: 48.5778008, lng: 20.9395538 },
    { lat: 48.5765371, lng: 20.9396968 },
    { lat: 48.5761563, lng: 20.939988 },
    { lat: 48.5758874, lng: 20.9400914 },
    { lat: 48.5757714, lng: 20.9399268 },
    { lat: 48.5756086, lng: 20.9398358 },
    { lat: 48.5753344, lng: 20.9379527 },
    { lat: 48.5749012, lng: 20.937956 },
    { lat: 48.5734033, lng: 20.9327989 },
    { lat: 48.5735285, lng: 20.9326741 },
    { lat: 48.5735886, lng: 20.9326855 },
    { lat: 48.5735211, lng: 20.9321888 },
    { lat: 48.575148, lng: 20.9308368 },
    { lat: 48.5751165, lng: 20.9307362 },
    { lat: 48.5733005, lng: 20.9284646 },
    { lat: 48.5678608, lng: 20.9219311 },
    { lat: 48.5622909, lng: 20.915629 },
    { lat: 48.560131, lng: 20.915841 },
    { lat: 48.560069, lng: 20.915838 },
    { lat: 48.560156, lng: 20.916217 },
    { lat: 48.560172, lng: 20.916282 },
    { lat: 48.560019, lng: 20.916387 },
    { lat: 48.560009, lng: 20.916458 },
    { lat: 48.560079, lng: 20.916652 },
    { lat: 48.560029, lng: 20.916922 },
    { lat: 48.559938, lng: 20.917481 },
    { lat: 48.559875, lng: 20.917898 },
    { lat: 48.559843, lng: 20.918145 },
    { lat: 48.55979, lng: 20.918497 },
    { lat: 48.559758, lng: 20.918807 },
    { lat: 48.559771, lng: 20.919093 },
    { lat: 48.559753, lng: 20.919195 },
    { lat: 48.559638, lng: 20.91933 },
    { lat: 48.5596, lng: 20.919418 },
    { lat: 48.5596, lng: 20.919529 },
    { lat: 48.559602, lng: 20.919735 },
    { lat: 48.559539, lng: 20.919878 },
    { lat: 48.559452, lng: 20.92006 },
    { lat: 48.559416, lng: 20.920167 },
    { lat: 48.559518, lng: 20.920546 },
    { lat: 48.559531, lng: 20.920679 },
    { lat: 48.559492, lng: 20.920836 },
    { lat: 48.559484, lng: 20.921024 },
    { lat: 48.559481, lng: 20.921066 },
    { lat: 48.559455, lng: 20.921228 },
    { lat: 48.559508, lng: 20.921323 },
    { lat: 48.559565, lng: 20.921491 },
    { lat: 48.559585, lng: 20.921721 },
    { lat: 48.559503, lng: 20.921983 },
    { lat: 48.559497, lng: 20.922083 },
    { lat: 48.559486, lng: 20.922147 },
    { lat: 48.559453, lng: 20.922323 },
    { lat: 48.559366, lng: 20.922362 },
    { lat: 48.559088, lng: 20.922331 },
    { lat: 48.558938, lng: 20.922325 },
    { lat: 48.558904, lng: 20.922319 },
    { lat: 48.558844, lng: 20.922323 },
    { lat: 48.558813, lng: 20.922274 },
    { lat: 48.558666, lng: 20.92217 },
    { lat: 48.55854, lng: 20.92204 },
    { lat: 48.558458, lng: 20.921965 },
    { lat: 48.558445, lng: 20.921988 },
    { lat: 48.557986, lng: 20.922637 },
    { lat: 48.557019, lng: 20.923762 },
    { lat: 48.556229, lng: 20.925457 },
    { lat: 48.555695, lng: 20.927103 },
    { lat: 48.555295, lng: 20.927451 },
    { lat: 48.554876, lng: 20.927446 },
    { lat: 48.554533, lng: 20.92819 },
    { lat: 48.554543, lng: 20.928215 },
    { lat: 48.554466, lng: 20.928298 },
    { lat: 48.554354, lng: 20.92836 },
    { lat: 48.554231, lng: 20.928519 },
    { lat: 48.554105, lng: 20.928554 },
    { lat: 48.554022, lng: 20.928595 },
    { lat: 48.55391, lng: 20.928747 },
    { lat: 48.553738, lng: 20.928883 },
    { lat: 48.553624, lng: 20.929052 },
    { lat: 48.55353, lng: 20.929149 },
    { lat: 48.553427, lng: 20.929173 },
    { lat: 48.553323, lng: 20.928962 },
    { lat: 48.553252, lng: 20.928954 },
    { lat: 48.553091, lng: 20.929048 },
    { lat: 48.552904, lng: 20.929044 },
    { lat: 48.552838, lng: 20.929073 },
    { lat: 48.552753, lng: 20.92917 },
    { lat: 48.5527, lng: 20.92926 },
    { lat: 48.552578, lng: 20.929237 },
    { lat: 48.552491, lng: 20.929087 },
    { lat: 48.55247, lng: 20.92909 },
    { lat: 48.552354, lng: 20.92903 },
    { lat: 48.552305, lng: 20.929024 },
    { lat: 48.552153, lng: 20.929121 },
    { lat: 48.552087, lng: 20.929206 },
    { lat: 48.552053, lng: 20.929206 },
    { lat: 48.552028, lng: 20.929187 },
    { lat: 48.551995, lng: 20.929166 },
    { lat: 48.551927, lng: 20.929189 },
    { lat: 48.551833, lng: 20.929228 },
    { lat: 48.551769, lng: 20.929167 },
    { lat: 48.551639, lng: 20.9292 },
    { lat: 48.551477, lng: 20.929127 },
    { lat: 48.551393, lng: 20.929158 },
    { lat: 48.549669, lng: 20.929619 },
    { lat: 48.54884, lng: 20.92986 },
    { lat: 48.547899, lng: 20.930219 },
    { lat: 48.546572, lng: 20.93073 },
    { lat: 48.544838, lng: 20.931887 },
    { lat: 48.543492, lng: 20.932826 },
    { lat: 48.5432219, lng: 20.9330775 },
    { lat: 48.543028, lng: 20.933258 },
    { lat: 48.542959, lng: 20.933324 },
    { lat: 48.542523, lng: 20.933837 },
    { lat: 48.542015, lng: 20.934151 },
    { lat: 48.541186, lng: 20.934682 },
    { lat: 48.539737, lng: 20.934357 },
    { lat: 48.5386606, lng: 20.9345797 },
    { lat: 48.5383552, lng: 20.9346323 },
    { lat: 48.538642, lng: 20.935048 },
    { lat: 48.538992, lng: 20.935398 },
    { lat: 48.539552, lng: 20.936305 },
    { lat: 48.540219, lng: 20.937375 },
    { lat: 48.540718, lng: 20.93787 },
    { lat: 48.54115, lng: 20.938304 },
    { lat: 48.541777, lng: 20.938888 },
    { lat: 48.542139, lng: 20.939263 },
    { lat: 48.542264, lng: 20.939469 },
    { lat: 48.542361, lng: 20.939727 },
    { lat: 48.542536, lng: 20.940375 },
    { lat: 48.542752, lng: 20.941246 },
    { lat: 48.542892, lng: 20.941726 },
  ],
  Trebejov: [
    { lat: 48.858365, lng: 21.2282021 },
    { lat: 48.8575074, lng: 21.2279448 },
    { lat: 48.8573479, lng: 21.2277357 },
    { lat: 48.8571262, lng: 21.2277737 },
    { lat: 48.8569462, lng: 21.2279923 },
    { lat: 48.8558565, lng: 21.2280526 },
    { lat: 48.8544841, lng: 21.2278192 },
    { lat: 48.8533494, lng: 21.2273997 },
    { lat: 48.8526428, lng: 21.2271583 },
    { lat: 48.852123, lng: 21.2267619 },
    { lat: 48.8501834, lng: 21.2257914 },
    { lat: 48.8500574, lng: 21.2257283 },
    { lat: 48.8494029, lng: 21.2256021 },
    { lat: 48.848156, lng: 21.2258583 },
    { lat: 48.8463433, lng: 21.2270581 },
    { lat: 48.8440884, lng: 21.2280807 },
    { lat: 48.8436723, lng: 21.2280754 },
    { lat: 48.8435694, lng: 21.2280741 },
    { lat: 48.8431198, lng: 21.2279129 },
    { lat: 48.8428727, lng: 21.2275094 },
    { lat: 48.8427855, lng: 21.2273669 },
    { lat: 48.842316, lng: 21.2248573 },
    { lat: 48.8420876, lng: 21.2239951 },
    { lat: 48.841268, lng: 21.2216642 },
    { lat: 48.8408026, lng: 21.2194431 },
    { lat: 48.8405497, lng: 21.2184395 },
    { lat: 48.8415829, lng: 21.2179719 },
    { lat: 48.8426726, lng: 21.2173373 },
    { lat: 48.8426927, lng: 21.2171479 },
    { lat: 48.8426061, lng: 21.2170102 },
    { lat: 48.8426423, lng: 21.2167264 },
    { lat: 48.8427379, lng: 21.2166831 },
    { lat: 48.8427495, lng: 21.2165569 },
    { lat: 48.8426984, lng: 21.2165387 },
    { lat: 48.8427118, lng: 21.2155443 },
    { lat: 48.8427191, lng: 21.2153993 },
    { lat: 48.8428233, lng: 21.2144454 },
    { lat: 48.8427019, lng: 21.2141233 },
    { lat: 48.8426868, lng: 21.2131011 },
    { lat: 48.8424256, lng: 21.2127786 },
    { lat: 48.8420005, lng: 21.2122461 },
    { lat: 48.8415713, lng: 21.2117237 },
    { lat: 48.8413587, lng: 21.2112237 },
    { lat: 48.8407398, lng: 21.2088773 },
    { lat: 48.8401737, lng: 21.2071823 },
    { lat: 48.8393825, lng: 21.2042345 },
    { lat: 48.8373352, lng: 21.1997298 },
    { lat: 48.834818, lng: 21.1940258 },
    { lat: 48.8342599, lng: 21.1942478 },
    { lat: 48.834247, lng: 21.1934078 },
    { lat: 48.8343991, lng: 21.191799 },
    { lat: 48.8292282, lng: 21.1905833 },
    { lat: 48.8290571, lng: 21.193421 },
    { lat: 48.8276138, lng: 21.2117353 },
    { lat: 48.8268164, lng: 21.2125493 },
    { lat: 48.8267593, lng: 21.2128402 },
    { lat: 48.8263313, lng: 21.2132655 },
    { lat: 48.8261342, lng: 21.2138042 },
    { lat: 48.8258266, lng: 21.2142902 },
    { lat: 48.8258289, lng: 21.2144408 },
    { lat: 48.8254976, lng: 21.2150763 },
    { lat: 48.8251856, lng: 21.2160454 },
    { lat: 48.8251904, lng: 21.2162279 },
    { lat: 48.8253764, lng: 21.2168532 },
    { lat: 48.8252385, lng: 21.2172738 },
    { lat: 48.825186, lng: 21.2180379 },
    { lat: 48.8253456, lng: 21.2186133 },
    { lat: 48.8253712, lng: 21.2190719 },
    { lat: 48.8254763, lng: 21.2194083 },
    { lat: 48.8258788, lng: 21.2201206 },
    { lat: 48.8255981, lng: 21.2204282 },
    { lat: 48.824624, lng: 21.2222939 },
    { lat: 48.8235786, lng: 21.2248235 },
    { lat: 48.8226188, lng: 21.2268183 },
    { lat: 48.8243331, lng: 21.229365 },
    { lat: 48.8242057, lng: 21.2296127 },
    { lat: 48.8240516, lng: 21.2303372 },
    { lat: 48.8237184, lng: 21.231022 },
    { lat: 48.8235844, lng: 21.2313855 },
    { lat: 48.8235304, lng: 21.2317779 },
    { lat: 48.8236838, lng: 21.232298 },
    { lat: 48.8236624, lng: 21.2329917 },
    { lat: 48.8249029, lng: 21.2361563 },
    { lat: 48.825474, lng: 21.2367889 },
    { lat: 48.8257369, lng: 21.2372506 },
    { lat: 48.8258625, lng: 21.2376348 },
    { lat: 48.8263561, lng: 21.2383823 },
    { lat: 48.826665, lng: 21.2382221 },
    { lat: 48.8269212, lng: 21.2379882 },
    { lat: 48.8271966, lng: 21.2384897 },
    { lat: 48.8276058, lng: 21.2384756 },
    { lat: 48.8279174, lng: 21.2386467 },
    { lat: 48.8279213, lng: 21.2410641 },
    { lat: 48.8280259, lng: 21.2421185 },
    { lat: 48.8279116, lng: 21.2438824 },
    { lat: 48.8280446, lng: 21.2449781 },
    { lat: 48.828852, lng: 21.246059 },
    { lat: 48.829559, lng: 21.246761 },
    { lat: 48.829935, lng: 21.247153 },
    { lat: 48.83123, lng: 21.248193 },
    { lat: 48.831728, lng: 21.248429 },
    { lat: 48.832141, lng: 21.248852 },
    { lat: 48.832209, lng: 21.248921 },
    { lat: 48.833102, lng: 21.250057 },
    { lat: 48.833415, lng: 21.250647 },
    { lat: 48.833543, lng: 21.250703 },
    { lat: 48.833846, lng: 21.249631 },
    { lat: 48.834399, lng: 21.248887 },
    { lat: 48.835098, lng: 21.247887 },
    { lat: 48.83552, lng: 21.247382 },
    { lat: 48.835978, lng: 21.247264 },
    { lat: 48.836862, lng: 21.247176 },
    { lat: 48.837642, lng: 21.247225 },
    { lat: 48.837644, lng: 21.247177 },
    { lat: 48.83768, lng: 21.245166 },
    { lat: 48.838732, lng: 21.244902 },
    { lat: 48.841358, lng: 21.24473 },
    { lat: 48.843007, lng: 21.244026 },
    { lat: 48.843263, lng: 21.244557 },
    { lat: 48.843621, lng: 21.24481 },
    { lat: 48.843774, lng: 21.244945 },
    { lat: 48.843984, lng: 21.24513 },
    { lat: 48.844354, lng: 21.245517 },
    { lat: 48.845336, lng: 21.245116 },
    { lat: 48.845471, lng: 21.244999 },
    { lat: 48.845957, lng: 21.24432 },
    { lat: 48.846298, lng: 21.244007 },
    { lat: 48.846644, lng: 21.243262 },
    { lat: 48.846903, lng: 21.243077 },
    { lat: 48.847176, lng: 21.242758 },
    { lat: 48.847465, lng: 21.242554 },
    { lat: 48.84786, lng: 21.242131 },
    { lat: 48.848097, lng: 21.242062 },
    { lat: 48.8482819, lng: 21.2417772 },
    { lat: 48.8485732, lng: 21.2406042 },
    { lat: 48.8486528, lng: 21.2399775 },
    { lat: 48.8483871, lng: 21.2378697 },
    { lat: 48.8481481, lng: 21.2374547 },
    { lat: 48.8482662, lng: 21.2374149 },
    { lat: 48.8482848, lng: 21.2372545 },
    { lat: 48.8489243, lng: 21.2371569 },
    { lat: 48.8495139, lng: 21.2366468 },
    { lat: 48.8495487, lng: 21.2359887 },
    { lat: 48.8499367, lng: 21.2352929 },
    { lat: 48.850121, lng: 21.2352115 },
    { lat: 48.8503591, lng: 21.2348319 },
    { lat: 48.8506702, lng: 21.2348104 },
    { lat: 48.8507925, lng: 21.234617 },
    { lat: 48.8509048, lng: 21.234649 },
    { lat: 48.8511494, lng: 21.2344054 },
    { lat: 48.8511659, lng: 21.2339569 },
    { lat: 48.8512673, lng: 21.2336706 },
    { lat: 48.8513462, lng: 21.2336049 },
    { lat: 48.8516096, lng: 21.2336423 },
    { lat: 48.8517632, lng: 21.2334784 },
    { lat: 48.8519713, lng: 21.2328961 },
    { lat: 48.8519477, lng: 21.2325001 },
    { lat: 48.8521224, lng: 21.2323611 },
    { lat: 48.8525762, lng: 21.2317864 },
    { lat: 48.8528939, lng: 21.2317676 },
    { lat: 48.8537308, lng: 21.2312792 },
    { lat: 48.8539785, lng: 21.2312519 },
    { lat: 48.8542389, lng: 21.2309735 },
    { lat: 48.8546536, lng: 21.2311741 },
    { lat: 48.8551287, lng: 21.2308926 },
    { lat: 48.8554291, lng: 21.2305928 },
    { lat: 48.8560532, lng: 21.2305812 },
    { lat: 48.8562953, lng: 21.2309156 },
    { lat: 48.8566107, lng: 21.2311765 },
    { lat: 48.8569484, lng: 21.231175 },
    { lat: 48.8575228, lng: 21.2309403 },
    { lat: 48.8579017, lng: 21.2306045 },
    { lat: 48.8580756, lng: 21.2299671 },
    { lat: 48.8580751, lng: 21.2295197 },
    { lat: 48.8586612, lng: 21.2293993 },
    { lat: 48.8585736, lng: 21.2292293 },
    { lat: 48.8583767, lng: 21.2291244 },
    { lat: 48.8584272, lng: 21.2289735 },
    { lat: 48.8584167, lng: 21.2288163 },
    { lat: 48.858365, lng: 21.2282021 },
  ],
  Chorváty: [
    { lat: 48.5717586, lng: 20.8740098 },
    { lat: 48.5710292, lng: 20.8725411 },
    { lat: 48.5706333, lng: 20.8717008 },
    { lat: 48.5705416, lng: 20.8712555 },
    { lat: 48.5705028, lng: 20.8712364 },
    { lat: 48.5704612, lng: 20.8704865 },
    { lat: 48.5702759, lng: 20.8690284 },
    { lat: 48.5702294, lng: 20.868265 },
    { lat: 48.5702615, lng: 20.8677229 },
    { lat: 48.5701725, lng: 20.8667059 },
    { lat: 48.5701064, lng: 20.8662262 },
    { lat: 48.5698927, lng: 20.8655729 },
    { lat: 48.5698563, lng: 20.8652075 },
    { lat: 48.5698603, lng: 20.8649602 },
    { lat: 48.5699893, lng: 20.8643288 },
    { lat: 48.5700281, lng: 20.8632637 },
    { lat: 48.5701852, lng: 20.8614828 },
    { lat: 48.5701194, lng: 20.8614687 },
    { lat: 48.566889, lng: 20.854863 },
    { lat: 48.5668545, lng: 20.8550513 },
    { lat: 48.566815, lng: 20.855241 },
    { lat: 48.566768, lng: 20.855232 },
    { lat: 48.566313, lng: 20.855145 },
    { lat: 48.566134, lng: 20.855111 },
    { lat: 48.565925, lng: 20.855055 },
    { lat: 48.565552, lng: 20.85523 },
    { lat: 48.565172, lng: 20.855527 },
    { lat: 48.564528, lng: 20.856107 },
    { lat: 48.564272, lng: 20.856306 },
    { lat: 48.563995, lng: 20.856465 },
    { lat: 48.562995, lng: 20.857288 },
    { lat: 48.562608, lng: 20.857501 },
    { lat: 48.562097, lng: 20.857702 },
    { lat: 48.561387, lng: 20.858068 },
    { lat: 48.560558, lng: 20.85852 },
    { lat: 48.560171, lng: 20.858869 },
    { lat: 48.559688, lng: 20.859197 },
    { lat: 48.559048, lng: 20.860011 },
    { lat: 48.558601, lng: 20.86079 },
    { lat: 48.55825, lng: 20.860888 },
    { lat: 48.557866, lng: 20.86099 },
    { lat: 48.5573, lng: 20.861129 },
    { lat: 48.557441, lng: 20.861911 },
    { lat: 48.557548, lng: 20.86246 },
    { lat: 48.557062, lng: 20.863023 },
    { lat: 48.556497, lng: 20.863421 },
    { lat: 48.55644, lng: 20.863284 },
    { lat: 48.556142, lng: 20.863512 },
    { lat: 48.555881, lng: 20.863703 },
    { lat: 48.555523, lng: 20.863967 },
    { lat: 48.555183, lng: 20.863637 },
    { lat: 48.554668, lng: 20.864636 },
    { lat: 48.553857, lng: 20.865904 },
    { lat: 48.553493, lng: 20.866323 },
    { lat: 48.553392, lng: 20.866416 },
    { lat: 48.552781, lng: 20.867051 },
    { lat: 48.552323, lng: 20.867536 },
    { lat: 48.551818, lng: 20.868155 },
    { lat: 48.552029, lng: 20.868388 },
    { lat: 48.552204, lng: 20.868639 },
    { lat: 48.552439, lng: 20.869059 },
    { lat: 48.552669, lng: 20.869468 },
    { lat: 48.552779, lng: 20.869784 },
    { lat: 48.553004, lng: 20.870141 },
    { lat: 48.553193, lng: 20.870567 },
    { lat: 48.553286, lng: 20.871236 },
    { lat: 48.553316, lng: 20.871368 },
    { lat: 48.553411, lng: 20.872197 },
    { lat: 48.553426, lng: 20.872317 },
    { lat: 48.553506, lng: 20.872684 },
    { lat: 48.553527, lng: 20.872877 },
    { lat: 48.553512, lng: 20.87307 },
    { lat: 48.553482, lng: 20.873269 },
    { lat: 48.5535254, lng: 20.8733956 },
    { lat: 48.553508, lng: 20.873515 },
    { lat: 48.553506, lng: 20.873649 },
    { lat: 48.553522, lng: 20.873796 },
    { lat: 48.553571, lng: 20.873983 },
    { lat: 48.55359, lng: 20.874206 },
    { lat: 48.553583, lng: 20.874382 },
    { lat: 48.553637, lng: 20.874795 },
    { lat: 48.553622, lng: 20.874833 },
    { lat: 48.5536, lng: 20.874874 },
    { lat: 48.553684, lng: 20.875195 },
    { lat: 48.553456, lng: 20.875355 },
    { lat: 48.552862, lng: 20.87574 },
    { lat: 48.552451, lng: 20.876007 },
    { lat: 48.551869, lng: 20.876392 },
    { lat: 48.551676, lng: 20.87652 },
    { lat: 48.550778, lng: 20.877106 },
    { lat: 48.551116, lng: 20.878482 },
    { lat: 48.551375, lng: 20.879539 },
    { lat: 48.551502, lng: 20.880077 },
    { lat: 48.551683, lng: 20.880812 },
    { lat: 48.55184, lng: 20.881457 },
    { lat: 48.552091, lng: 20.882471 },
    { lat: 48.552448, lng: 20.883916 },
    { lat: 48.552563, lng: 20.88438 },
    { lat: 48.5530015, lng: 20.8843692 },
    { lat: 48.5542051, lng: 20.8840152 },
    { lat: 48.5552644, lng: 20.8841159 },
    { lat: 48.5563531, lng: 20.8848415 },
    { lat: 48.5573223, lng: 20.8851702 },
    { lat: 48.5587074, lng: 20.8855129 },
    { lat: 48.5608612, lng: 20.8847308 },
    { lat: 48.5615123, lng: 20.8843817 },
    { lat: 48.5628856, lng: 20.8829335 },
    { lat: 48.5634142, lng: 20.8827247 },
    { lat: 48.5636294, lng: 20.8827953 },
    { lat: 48.5635859, lng: 20.8826089 },
    { lat: 48.5650994, lng: 20.8813491 },
    { lat: 48.564981, lng: 20.8810455 },
    { lat: 48.5651608, lng: 20.8809022 },
    { lat: 48.5650379, lng: 20.88067 },
    { lat: 48.5671665, lng: 20.8789595 },
    { lat: 48.5670804, lng: 20.8788436 },
    { lat: 48.5671996, lng: 20.8787423 },
    { lat: 48.5671698, lng: 20.8786593 },
    { lat: 48.5673332, lng: 20.878497 },
    { lat: 48.5672845, lng: 20.8783916 },
    { lat: 48.5679755, lng: 20.8777326 },
    { lat: 48.56766, lng: 20.877229 },
    { lat: 48.5678292, lng: 20.8770086 },
    { lat: 48.5675395, lng: 20.876541 },
    { lat: 48.5682713, lng: 20.8756911 },
    { lat: 48.5695811, lng: 20.8745943 },
    { lat: 48.5698335, lng: 20.8751938 },
    { lat: 48.5699514, lng: 20.8749514 },
    { lat: 48.5697331, lng: 20.8744257 },
    { lat: 48.5706415, lng: 20.8735459 },
    { lat: 48.5704512, lng: 20.8743573 },
    { lat: 48.5704507, lng: 20.8745788 },
    { lat: 48.5705309, lng: 20.8747492 },
    { lat: 48.5706898, lng: 20.8747891 },
    { lat: 48.5716924, lng: 20.8740555 },
    { lat: 48.5717586, lng: 20.8740098 },
  ],
  Obišovce: [
    { lat: 48.8683337, lng: 21.1873463 },
    { lat: 48.868829, lng: 21.1892862 },
    { lat: 48.8688372, lng: 21.1899784 },
    { lat: 48.8687634, lng: 21.1902797 },
    { lat: 48.868856, lng: 21.1908607 },
    { lat: 48.8689975, lng: 21.1913875 },
    { lat: 48.8687075, lng: 21.1921204 },
    { lat: 48.8687586, lng: 21.1923516 },
    { lat: 48.8688864, lng: 21.1925444 },
    { lat: 48.8694716, lng: 21.192766 },
    { lat: 48.8707911, lng: 21.1928536 },
    { lat: 48.8715024, lng: 21.1931391 },
    { lat: 48.8717669, lng: 21.1931068 },
    { lat: 48.8720116, lng: 21.1929845 },
    { lat: 48.8727562, lng: 21.1937538 },
    { lat: 48.8732827, lng: 21.193896 },
    { lat: 48.8739003, lng: 21.1943174 },
    { lat: 48.8746056, lng: 21.1944593 },
    { lat: 48.8753435, lng: 21.1951662 },
    { lat: 48.874367, lng: 21.195288 },
    { lat: 48.873647, lng: 21.1958582 },
    { lat: 48.8733217, lng: 21.1957497 },
    { lat: 48.8729151, lng: 21.1958469 },
    { lat: 48.8719371, lng: 21.1968521 },
    { lat: 48.8711284, lng: 21.1976189 },
    { lat: 48.8709788, lng: 21.1976925 },
    { lat: 48.8708836, lng: 21.1978896 },
    { lat: 48.8705005, lng: 21.1980201 },
    { lat: 48.8702157, lng: 21.1982354 },
    { lat: 48.869927, lng: 21.1982469 },
    { lat: 48.8696703, lng: 21.1981771 },
    { lat: 48.8693219, lng: 21.1979326 },
    { lat: 48.8689931, lng: 21.1978912 },
    { lat: 48.8687236, lng: 21.1979163 },
    { lat: 48.8684863, lng: 21.1980284 },
    { lat: 48.8677889, lng: 21.1980018 },
    { lat: 48.867706, lng: 21.1987291 },
    { lat: 48.8673567, lng: 21.1998352 },
    { lat: 48.8670354, lng: 21.2012173 },
    { lat: 48.8670887, lng: 21.2034568 },
    { lat: 48.8667336, lng: 21.2056696 },
    { lat: 48.866531, lng: 21.2064609 },
    { lat: 48.8663177, lng: 21.2070538 },
    { lat: 48.8658787, lng: 21.2080994 },
    { lat: 48.8652122, lng: 21.2091366 },
    { lat: 48.8645327, lng: 21.2099352 },
    { lat: 48.8638818, lng: 21.2111417 },
    { lat: 48.8625293, lng: 21.2130608 },
    { lat: 48.8617775, lng: 21.2145421 },
    { lat: 48.8617447, lng: 21.2150034 },
    { lat: 48.8620968, lng: 21.2169182 },
    { lat: 48.8621447, lng: 21.218095 },
    { lat: 48.862092, lng: 21.2192564 },
    { lat: 48.8621299, lng: 21.2211637 },
    { lat: 48.8623685, lng: 21.2228977 },
    { lat: 48.862284, lng: 21.2233658 },
    { lat: 48.8616264, lng: 21.2246587 },
    { lat: 48.8606289, lng: 21.226448 },
    { lat: 48.858843, lng: 21.227313 },
    { lat: 48.858331, lng: 21.2277978 },
    { lat: 48.8583525, lng: 21.2280482 },
    { lat: 48.858365, lng: 21.2282021 },
    { lat: 48.8584167, lng: 21.2288163 },
    { lat: 48.8584272, lng: 21.2289735 },
    { lat: 48.8583767, lng: 21.2291244 },
    { lat: 48.8585736, lng: 21.2292293 },
    { lat: 48.8586612, lng: 21.2293993 },
    { lat: 48.8580751, lng: 21.2295197 },
    { lat: 48.8580756, lng: 21.2299671 },
    { lat: 48.8579017, lng: 21.2306045 },
    { lat: 48.8575228, lng: 21.2309403 },
    { lat: 48.8569484, lng: 21.231175 },
    { lat: 48.8566107, lng: 21.2311765 },
    { lat: 48.8562953, lng: 21.2309156 },
    { lat: 48.8560532, lng: 21.2305812 },
    { lat: 48.8554291, lng: 21.2305928 },
    { lat: 48.8551287, lng: 21.2308926 },
    { lat: 48.8546536, lng: 21.2311741 },
    { lat: 48.8542389, lng: 21.2309735 },
    { lat: 48.8539785, lng: 21.2312519 },
    { lat: 48.8537308, lng: 21.2312792 },
    { lat: 48.8528939, lng: 21.2317676 },
    { lat: 48.8525762, lng: 21.2317864 },
    { lat: 48.8521224, lng: 21.2323611 },
    { lat: 48.8519477, lng: 21.2325001 },
    { lat: 48.8519713, lng: 21.2328961 },
    { lat: 48.8517632, lng: 21.2334784 },
    { lat: 48.8516096, lng: 21.2336423 },
    { lat: 48.8513462, lng: 21.2336049 },
    { lat: 48.8512673, lng: 21.2336706 },
    { lat: 48.8511659, lng: 21.2339569 },
    { lat: 48.8511494, lng: 21.2344054 },
    { lat: 48.8509048, lng: 21.234649 },
    { lat: 48.8507925, lng: 21.234617 },
    { lat: 48.8506702, lng: 21.2348104 },
    { lat: 48.8503591, lng: 21.2348319 },
    { lat: 48.850121, lng: 21.2352115 },
    { lat: 48.8499367, lng: 21.2352929 },
    { lat: 48.8495487, lng: 21.2359887 },
    { lat: 48.8495139, lng: 21.2366468 },
    { lat: 48.8489243, lng: 21.2371569 },
    { lat: 48.8482848, lng: 21.2372545 },
    { lat: 48.8482662, lng: 21.2374149 },
    { lat: 48.8481481, lng: 21.2374547 },
    { lat: 48.8483871, lng: 21.2378697 },
    { lat: 48.8486528, lng: 21.2399775 },
    { lat: 48.8485732, lng: 21.2406042 },
    { lat: 48.8482819, lng: 21.2417772 },
    { lat: 48.848097, lng: 21.242062 },
    { lat: 48.848101, lng: 21.242073 },
    { lat: 48.848629, lng: 21.243518 },
    { lat: 48.848629, lng: 21.243543 },
    { lat: 48.848685, lng: 21.243694 },
    { lat: 48.849005, lng: 21.244781 },
    { lat: 48.849418, lng: 21.244504 },
    { lat: 48.849626, lng: 21.244394 },
    { lat: 48.849669, lng: 21.244508 },
    { lat: 48.851408, lng: 21.243735 },
    { lat: 48.851462, lng: 21.243959 },
    { lat: 48.851816, lng: 21.24384 },
    { lat: 48.851833, lng: 21.244043 },
    { lat: 48.851884, lng: 21.244651 },
    { lat: 48.851907, lng: 21.244921 },
    { lat: 48.851925, lng: 21.245158 },
    { lat: 48.85214, lng: 21.245089 },
    { lat: 48.85235, lng: 21.24552 },
    { lat: 48.852407, lng: 21.245444 },
    { lat: 48.852461, lng: 21.245781 },
    { lat: 48.852401, lng: 21.246058 },
    { lat: 48.852398, lng: 21.246417 },
    { lat: 48.85265, lng: 21.247199 },
    { lat: 48.852674, lng: 21.247558 },
    { lat: 48.852527, lng: 21.248294 },
    { lat: 48.852435, lng: 21.249315 },
    { lat: 48.852451, lng: 21.249962 },
    { lat: 48.852911, lng: 21.250985 },
    { lat: 48.853273, lng: 21.251611 },
    { lat: 48.853357, lng: 21.251618 },
    { lat: 48.85419, lng: 21.25167 },
    { lat: 48.854217, lng: 21.252426 },
    { lat: 48.854201, lng: 21.252932 },
    { lat: 48.854132, lng: 21.253387 },
    { lat: 48.854161, lng: 21.254144 },
    { lat: 48.854112, lng: 21.254196 },
    { lat: 48.854251, lng: 21.254313 },
    { lat: 48.854582, lng: 21.254921 },
    { lat: 48.855021, lng: 21.255238 },
    { lat: 48.855197, lng: 21.255355 },
    { lat: 48.855499, lng: 21.255687 },
    { lat: 48.855542, lng: 21.255771 },
    { lat: 48.855866, lng: 21.256402 },
    { lat: 48.856053, lng: 21.256766 },
    { lat: 48.856141, lng: 21.256929 },
    { lat: 48.856567, lng: 21.257501 },
    { lat: 48.856971, lng: 21.258042 },
    { lat: 48.856996, lng: 21.258139 },
    { lat: 48.85705, lng: 21.258382 },
    { lat: 48.857122, lng: 21.258634 },
    { lat: 48.857834, lng: 21.261376 },
    { lat: 48.85792, lng: 21.261703 },
    { lat: 48.858043, lng: 21.26182 },
    { lat: 48.858132, lng: 21.261838 },
    { lat: 48.858212, lng: 21.261892 },
    { lat: 48.858357, lng: 21.262201 },
    { lat: 48.85885, lng: 21.263371 },
    { lat: 48.859446, lng: 21.265194 },
    { lat: 48.85946, lng: 21.265236 },
    { lat: 48.859599, lng: 21.265661 },
    { lat: 48.85953, lng: 21.265835 },
    { lat: 48.859417, lng: 21.265889 },
    { lat: 48.859465, lng: 21.266042 },
    { lat: 48.859479, lng: 21.266083 },
    { lat: 48.859499, lng: 21.266145 },
    { lat: 48.859541, lng: 21.266274 },
    { lat: 48.859716, lng: 21.266667 },
    { lat: 48.860329, lng: 21.26663 },
    { lat: 48.860386, lng: 21.266626 },
    { lat: 48.860633, lng: 21.266611 },
    { lat: 48.860692, lng: 21.266363 },
    { lat: 48.860662, lng: 21.266057 },
    { lat: 48.860569, lng: 21.265121 },
    { lat: 48.860552, lng: 21.264594 },
    { lat: 48.860678, lng: 21.264051 },
    { lat: 48.860861, lng: 21.263652 },
    { lat: 48.861131, lng: 21.263391 },
    { lat: 48.861697, lng: 21.263314 },
    { lat: 48.862005, lng: 21.263272 },
    { lat: 48.862297, lng: 21.263849 },
    { lat: 48.862624, lng: 21.264821 },
    { lat: 48.863069, lng: 21.265457 },
    { lat: 48.863196, lng: 21.265385 },
    { lat: 48.863576, lng: 21.265449 },
    { lat: 48.864144, lng: 21.265744 },
    { lat: 48.864445, lng: 21.265773 },
    { lat: 48.864739, lng: 21.266014 },
    { lat: 48.86469, lng: 21.265384 },
    { lat: 48.865181, lng: 21.264652 },
    { lat: 48.865371, lng: 21.26456 },
    { lat: 48.866027, lng: 21.265184 },
    { lat: 48.866657, lng: 21.265284 },
    { lat: 48.867089, lng: 21.265197 },
    { lat: 48.867565, lng: 21.264866 },
    { lat: 48.867803, lng: 21.264391 },
    { lat: 48.867776, lng: 21.264139 },
    { lat: 48.867778, lng: 21.263838 },
    { lat: 48.867943, lng: 21.262948 },
    { lat: 48.867928, lng: 21.262273 },
    { lat: 48.867986, lng: 21.26218 },
    { lat: 48.868233, lng: 21.26179 },
    { lat: 48.868688, lng: 21.261497 },
    { lat: 48.869015, lng: 21.261426 },
    { lat: 48.869882, lng: 21.261451 },
    { lat: 48.870027, lng: 21.26147 },
    { lat: 48.870132, lng: 21.261483 },
    { lat: 48.870298, lng: 21.261504 },
    { lat: 48.870593, lng: 21.261723 },
    { lat: 48.87167, lng: 21.261412 },
    { lat: 48.871877, lng: 21.261306 },
    { lat: 48.872566, lng: 21.260853 },
    { lat: 48.872588, lng: 21.260841 },
    { lat: 48.873688, lng: 21.260105 },
    { lat: 48.873657, lng: 21.25956 },
    { lat: 48.872924, lng: 21.25785 },
    { lat: 48.872219, lng: 21.256347 },
    { lat: 48.871688, lng: 21.255218 },
    { lat: 48.871684, lng: 21.255211 },
    { lat: 48.871627, lng: 21.25509 },
    { lat: 48.871593, lng: 21.255016 },
    { lat: 48.871577, lng: 21.254982 },
    { lat: 48.871399, lng: 21.254593 },
    { lat: 48.871347, lng: 21.25448 },
    { lat: 48.871285, lng: 21.254341 },
    { lat: 48.871121, lng: 21.253982 },
    { lat: 48.871104, lng: 21.253944 },
    { lat: 48.870721, lng: 21.253104 },
    { lat: 48.870665, lng: 21.25298 },
    { lat: 48.87021, lng: 21.251981 },
    { lat: 48.869976, lng: 21.252235 },
    { lat: 48.869504, lng: 21.25305 },
    { lat: 48.869376, lng: 21.253271 },
    { lat: 48.869272, lng: 21.253414 },
    { lat: 48.868968, lng: 21.253835 },
    { lat: 48.868852, lng: 21.253936 },
    { lat: 48.868772, lng: 21.254001 },
    { lat: 48.868718, lng: 21.254044 },
    { lat: 48.868623, lng: 21.25412 },
    { lat: 48.868318, lng: 21.254258 },
    { lat: 48.868253, lng: 21.254259 },
    { lat: 48.868268, lng: 21.253977 },
    { lat: 48.868268, lng: 21.253966 },
    { lat: 48.868194, lng: 21.253969 },
    { lat: 48.868157, lng: 21.25373 },
    { lat: 48.868106, lng: 21.253437 },
    { lat: 48.867902, lng: 21.252762 },
    { lat: 48.867894, lng: 21.251483 },
    { lat: 48.867967, lng: 21.250776 },
    { lat: 48.868058, lng: 21.250324 },
    { lat: 48.86824, lng: 21.249552 },
    { lat: 48.868322, lng: 21.249297 },
    { lat: 48.868367, lng: 21.249015 },
    { lat: 48.868669, lng: 21.247974 },
    { lat: 48.869035, lng: 21.2468 },
    { lat: 48.868965, lng: 21.246112 },
    { lat: 48.869062, lng: 21.245923 },
    { lat: 48.869109, lng: 21.245588 },
    { lat: 48.869207, lng: 21.244632 },
    { lat: 48.869028, lng: 21.243818 },
    { lat: 48.868969, lng: 21.24376 },
    { lat: 48.869087, lng: 21.24367 },
    { lat: 48.869503, lng: 21.243131 },
    { lat: 48.869725, lng: 21.242907 },
    { lat: 48.869799, lng: 21.242813 },
    { lat: 48.870219, lng: 21.24254 },
    { lat: 48.870166, lng: 21.242226 },
    { lat: 48.870138, lng: 21.242059 },
    { lat: 48.868942, lng: 21.236349 },
    { lat: 48.869666, lng: 21.236037 },
    { lat: 48.87001, lng: 21.23589 },
    { lat: 48.870372, lng: 21.235648 },
    { lat: 48.871086, lng: 21.235359 },
    { lat: 48.871899, lng: 21.234939 },
    { lat: 48.872497, lng: 21.234675 },
    { lat: 48.872939, lng: 21.234458 },
    { lat: 48.873245, lng: 21.234261 },
    { lat: 48.873731, lng: 21.234835 },
    { lat: 48.874481, lng: 21.235359 },
    { lat: 48.875113, lng: 21.235519 },
    { lat: 48.875726, lng: 21.235735 },
    { lat: 48.876196, lng: 21.236004 },
    { lat: 48.876502, lng: 21.234688 },
    { lat: 48.876779, lng: 21.23347 },
    { lat: 48.877046, lng: 21.232305 },
    { lat: 48.877143, lng: 21.231824 },
    { lat: 48.877241, lng: 21.23137 },
    { lat: 48.877398, lng: 21.230528 },
    { lat: 48.877636, lng: 21.230482 },
    { lat: 48.877574, lng: 21.230385 },
    { lat: 48.877935, lng: 21.230163 },
    { lat: 48.878258, lng: 21.229983 },
    { lat: 48.878337, lng: 21.229884 },
    { lat: 48.878495, lng: 21.229523 },
    { lat: 48.878565, lng: 21.229099 },
    { lat: 48.878582, lng: 21.228669 },
    { lat: 48.878575, lng: 21.228389 },
    { lat: 48.878575, lng: 21.228121 },
    { lat: 48.878596, lng: 21.227614 },
    { lat: 48.878607, lng: 21.227184 },
    { lat: 48.87862, lng: 21.226849 },
    { lat: 48.878702, lng: 21.226438 },
    { lat: 48.878834, lng: 21.225877 },
    { lat: 48.878858, lng: 21.225854 },
    { lat: 48.878701, lng: 21.225737 },
    { lat: 48.878677, lng: 21.22576 },
    { lat: 48.878492, lng: 21.225499 },
    { lat: 48.878434, lng: 21.225416 },
    { lat: 48.87836, lng: 21.225311 },
    { lat: 48.878328, lng: 21.225052 },
    { lat: 48.878293, lng: 21.224747 },
    { lat: 48.878271, lng: 21.224751 },
    { lat: 48.878174, lng: 21.224773 },
    { lat: 48.878106, lng: 21.224789 },
    { lat: 48.877792, lng: 21.224889 },
    { lat: 48.877556, lng: 21.224834 },
    { lat: 48.877475, lng: 21.224785 },
    { lat: 48.877297, lng: 21.224659 },
    { lat: 48.876791, lng: 21.224325 },
    { lat: 48.876794, lng: 21.224315 },
    { lat: 48.876693, lng: 21.224293 },
    { lat: 48.876613, lng: 21.224275 },
    { lat: 48.876242, lng: 21.224193 },
    { lat: 48.875895, lng: 21.224282 },
    { lat: 48.875702, lng: 21.224255 },
    { lat: 48.875539, lng: 21.224232 },
    { lat: 48.875497, lng: 21.224266 },
    { lat: 48.875504, lng: 21.224285 },
    { lat: 48.875509, lng: 21.2243 },
    { lat: 48.875519, lng: 21.22433 },
    { lat: 48.875575, lng: 21.224469 },
    { lat: 48.875272, lng: 21.224607 },
    { lat: 48.874831, lng: 21.224874 },
    { lat: 48.874692, lng: 21.224903 },
    { lat: 48.874463, lng: 21.224832 },
    { lat: 48.87401, lng: 21.224219 },
    { lat: 48.873779, lng: 21.224125 },
    { lat: 48.873381, lng: 21.224153 },
    { lat: 48.873013, lng: 21.22438 },
    { lat: 48.872936, lng: 21.224445 },
    { lat: 48.872679, lng: 21.224485 },
    { lat: 48.872009, lng: 21.224122 },
    { lat: 48.871277, lng: 21.223883 },
    { lat: 48.871201, lng: 21.223831 },
    { lat: 48.871111, lng: 21.223772 },
    { lat: 48.871003, lng: 21.223719 },
    { lat: 48.871078, lng: 21.223526 },
    { lat: 48.872443, lng: 21.220876 },
    { lat: 48.872575, lng: 21.220614 },
    { lat: 48.872708, lng: 21.220342 },
    { lat: 48.872735, lng: 21.220288 },
    { lat: 48.87339, lng: 21.218947 },
    { lat: 48.873496, lng: 21.21874 },
    { lat: 48.873888, lng: 21.217958 },
    { lat: 48.874164, lng: 21.21738 },
    { lat: 48.874829, lng: 21.216047 },
    { lat: 48.875448, lng: 21.214802 },
    { lat: 48.876092, lng: 21.213527 },
    { lat: 48.876664, lng: 21.212399 },
    { lat: 48.877103, lng: 21.211558 },
    { lat: 48.877467, lng: 21.210835 },
    { lat: 48.87755, lng: 21.210584 },
    { lat: 48.878226, lng: 21.209372 },
    { lat: 48.878882, lng: 21.208072 },
    { lat: 48.879487, lng: 21.206833 },
    { lat: 48.880141, lng: 21.205575 },
    { lat: 48.880649, lng: 21.204575 },
    { lat: 48.881352, lng: 21.203194 },
    { lat: 48.881827, lng: 21.202257 },
    { lat: 48.882445, lng: 21.201017 },
    { lat: 48.882821, lng: 21.200282 },
    { lat: 48.883087, lng: 21.199771 },
    { lat: 48.883398, lng: 21.199191 },
    { lat: 48.88363, lng: 21.198737 },
    { lat: 48.884117, lng: 21.197773 },
    { lat: 48.884433, lng: 21.197176 },
    { lat: 48.884485, lng: 21.197083 },
    { lat: 48.884542, lng: 21.196982 },
    { lat: 48.88453, lng: 21.196968 },
    { lat: 48.882813, lng: 21.19506 },
    { lat: 48.882775, lng: 21.19502 },
    { lat: 48.882315, lng: 21.194512 },
    { lat: 48.882115, lng: 21.194293 },
    { lat: 48.876534, lng: 21.188147 },
    { lat: 48.876035, lng: 21.187835 },
    { lat: 48.87378, lng: 21.185129 },
    { lat: 48.873714, lng: 21.185051 },
    { lat: 48.873599, lng: 21.184913 },
    { lat: 48.873529, lng: 21.184835 },
    { lat: 48.872706, lng: 21.183932 },
    { lat: 48.870621, lng: 21.181595 },
    { lat: 48.870619, lng: 21.181519 },
    { lat: 48.8703225, lng: 21.1817308 },
    { lat: 48.8700876, lng: 21.1819927 },
    { lat: 48.8698059, lng: 21.1825128 },
    { lat: 48.8694312, lng: 21.1827708 },
    { lat: 48.8691532, lng: 21.1830539 },
    { lat: 48.8690808, lng: 21.1834756 },
    { lat: 48.8693171, lng: 21.1841896 },
    { lat: 48.869448, lng: 21.1848776 },
    { lat: 48.8693503, lng: 21.1857783 },
    { lat: 48.8689859, lng: 21.1870432 },
    { lat: 48.8688324, lng: 21.1872792 },
    { lat: 48.8683337, lng: 21.1873463 },
  ],
  VyšnáHutka: [
    { lat: 48.6778965, lng: 21.3790881 },
    { lat: 48.6780002, lng: 21.3791234 },
    { lat: 48.6792288, lng: 21.3788977 },
    { lat: 48.6799003, lng: 21.3792586 },
    { lat: 48.6799774, lng: 21.3792423 },
    { lat: 48.6802913, lng: 21.3789019 },
    { lat: 48.681643, lng: 21.3784523 },
    { lat: 48.6822986, lng: 21.3783625 },
    { lat: 48.6839615, lng: 21.3792905 },
    { lat: 48.6853509, lng: 21.3796247 },
    { lat: 48.685974, lng: 21.3794608 },
    { lat: 48.6867014, lng: 21.3782677 },
    { lat: 48.6871216, lng: 21.3773423 },
    { lat: 48.6875309, lng: 21.3755957 },
    { lat: 48.6876998, lng: 21.3743593 },
    { lat: 48.6877497, lng: 21.3739951 },
    { lat: 48.6876044, lng: 21.3738815 },
    { lat: 48.6871836, lng: 21.3737282 },
    { lat: 48.6867642, lng: 21.3729519 },
    { lat: 48.6865852, lng: 21.3721232 },
    { lat: 48.6865498, lng: 21.3717681 },
    { lat: 48.6866234, lng: 21.3712556 },
    { lat: 48.6866089, lng: 21.3708495 },
    { lat: 48.686726, lng: 21.3702488 },
    { lat: 48.6870401, lng: 21.3696095 },
    { lat: 48.6869915, lng: 21.3685924 },
    { lat: 48.6870519, lng: 21.3677524 },
    { lat: 48.6869558, lng: 21.3672144 },
    { lat: 48.6868907, lng: 21.3669518 },
    { lat: 48.6864316, lng: 21.3662776 },
    { lat: 48.6862886, lng: 21.3659348 },
    { lat: 48.6857919, lng: 21.3640837 },
    { lat: 48.6852925, lng: 21.362741 },
    { lat: 48.685067, lng: 21.3619053 },
    { lat: 48.6848165, lng: 21.3612027 },
    { lat: 48.6846906, lng: 21.3605692 },
    { lat: 48.6847578, lng: 21.3600745 },
    { lat: 48.6847616, lng: 21.3595639 },
    { lat: 48.6846584, lng: 21.3586418 },
    { lat: 48.684562, lng: 21.3582428 },
    { lat: 48.6832939, lng: 21.3552068 },
    { lat: 48.6828411, lng: 21.3530672 },
    { lat: 48.6825791, lng: 21.3522127 },
    { lat: 48.6824984, lng: 21.351474 },
    { lat: 48.6821509, lng: 21.3508087 },
    { lat: 48.6819405, lng: 21.3502445 },
    { lat: 48.6818425, lng: 21.3497929 },
    { lat: 48.6815704, lng: 21.3469683 },
    { lat: 48.6816501, lng: 21.3468432 },
    { lat: 48.681542, lng: 21.3460956 },
    { lat: 48.6817278, lng: 21.3408034 },
    { lat: 48.681162, lng: 21.338922 },
    { lat: 48.680807, lng: 21.339096 },
    { lat: 48.680757, lng: 21.339351 },
    { lat: 48.680822, lng: 21.339713 },
    { lat: 48.680491, lng: 21.339954 },
    { lat: 48.680374, lng: 21.340336 },
    { lat: 48.680067, lng: 21.34067 },
    { lat: 48.679892, lng: 21.340678 },
    { lat: 48.679781, lng: 21.34058 },
    { lat: 48.679644, lng: 21.340515 },
    { lat: 48.679395, lng: 21.340692 },
    { lat: 48.679263, lng: 21.341024 },
    { lat: 48.679143, lng: 21.341528 },
    { lat: 48.678428, lng: 21.342434 },
    { lat: 48.678425, lng: 21.342346 },
    { lat: 48.678384, lng: 21.342331 },
    { lat: 48.678138, lng: 21.34224 },
    { lat: 48.677784, lng: 21.342036 },
    { lat: 48.677384, lng: 21.34204 },
    { lat: 48.677331, lng: 21.342041 },
    { lat: 48.677142, lng: 21.341816 },
    { lat: 48.676734, lng: 21.342245 },
    { lat: 48.676575, lng: 21.34265 },
    { lat: 48.67663, lng: 21.343564 },
    { lat: 48.676765, lng: 21.344221 },
    { lat: 48.676605, lng: 21.344816 },
    { lat: 48.675943, lng: 21.345555 },
    { lat: 48.675976, lng: 21.34643 },
    { lat: 48.675798, lng: 21.347033 },
    { lat: 48.675446, lng: 21.34737 },
    { lat: 48.675103, lng: 21.347421 },
    { lat: 48.674243, lng: 21.34695 },
    { lat: 48.674158, lng: 21.34677 },
    { lat: 48.674099, lng: 21.346644 },
    { lat: 48.67414, lng: 21.347207 },
    { lat: 48.674188, lng: 21.349933 },
    { lat: 48.67394, lng: 21.3499 },
    { lat: 48.670695, lng: 21.349037 },
    { lat: 48.6710838, lng: 21.3503445 },
    { lat: 48.6710181, lng: 21.3520053 },
    { lat: 48.6710873, lng: 21.3526606 },
    { lat: 48.6710937, lng: 21.3534763 },
    { lat: 48.6706361, lng: 21.3535974 },
    { lat: 48.670175, lng: 21.3540957 },
    { lat: 48.669378, lng: 21.3546851 },
    { lat: 48.6693375, lng: 21.354987 },
    { lat: 48.6688915, lng: 21.3563555 },
    { lat: 48.6692249, lng: 21.3574124 },
    { lat: 48.6692197, lng: 21.3578439 },
    { lat: 48.6694646, lng: 21.3582811 },
    { lat: 48.669891, lng: 21.358802 },
    { lat: 48.6704456, lng: 21.3605175 },
    { lat: 48.6705277, lng: 21.3605518 },
    { lat: 48.6706052, lng: 21.3608092 },
    { lat: 48.670875, lng: 21.361249 },
    { lat: 48.6709509, lng: 21.3618693 },
    { lat: 48.6713639, lng: 21.3632923 },
    { lat: 48.6713576, lng: 21.3641686 },
    { lat: 48.6712533, lng: 21.36463 },
    { lat: 48.6712883, lng: 21.3648619 },
    { lat: 48.6715281, lng: 21.3653472 },
    { lat: 48.6715979, lng: 21.3660631 },
    { lat: 48.6718402, lng: 21.3665229 },
    { lat: 48.6723758, lng: 21.36806 },
    { lat: 48.6725261, lng: 21.3686469 },
    { lat: 48.672776, lng: 21.3691012 },
    { lat: 48.6729075, lng: 21.3694757 },
    { lat: 48.673118, lng: 21.3703401 },
    { lat: 48.6732085, lng: 21.3710307 },
    { lat: 48.6730845, lng: 21.3714418 },
    { lat: 48.6732206, lng: 21.3722216 },
    { lat: 48.6735881, lng: 21.3728103 },
    { lat: 48.6735945, lng: 21.373401 },
    { lat: 48.6737857, lng: 21.3739597 },
    { lat: 48.6742407, lng: 21.3745415 },
    { lat: 48.6743782, lng: 21.3749192 },
    { lat: 48.6746487, lng: 21.3751244 },
    { lat: 48.6748751, lng: 21.3753931 },
    { lat: 48.6750311, lng: 21.3756819 },
    { lat: 48.6753983, lng: 21.3760318 },
    { lat: 48.6760415, lng: 21.3760898 },
    { lat: 48.676316, lng: 21.3763909 },
    { lat: 48.6766102, lng: 21.3767835 },
    { lat: 48.6767895, lng: 21.3772841 },
    { lat: 48.6770936, lng: 21.3774076 },
    { lat: 48.6777083, lng: 21.3787697 },
    { lat: 48.6778965, lng: 21.3790881 },
  ],
  Blažice: [
    { lat: 48.6845147, lng: 21.4123778 },
    { lat: 48.6831934, lng: 21.4119506 },
    { lat: 48.6823034, lng: 21.4113101 },
    { lat: 48.68195, lng: 21.4111207 },
    { lat: 48.6818366, lng: 21.41116 },
    { lat: 48.6815279, lng: 21.4109601 },
    { lat: 48.6814219, lng: 21.4106855 },
    { lat: 48.6814884, lng: 21.4103506 },
    { lat: 48.6814266, lng: 21.4099691 },
    { lat: 48.6813066, lng: 21.4097223 },
    { lat: 48.6811904, lng: 21.4092134 },
    { lat: 48.6808513, lng: 21.4090867 },
    { lat: 48.6804179, lng: 21.4093104 },
    { lat: 48.6802373, lng: 21.4092549 },
    { lat: 48.6799874, lng: 21.4088726 },
    { lat: 48.6795156, lng: 21.4087093 },
    { lat: 48.6795127, lng: 21.4083795 },
    { lat: 48.6791278, lng: 21.4079244 },
    { lat: 48.6788035, lng: 21.4079221 },
    { lat: 48.6786254, lng: 21.4082143 },
    { lat: 48.6785246, lng: 21.4089493 },
    { lat: 48.6784296, lng: 21.4090472 },
    { lat: 48.6783327, lng: 21.409047 },
    { lat: 48.6781484, lng: 21.4089405 },
    { lat: 48.6778499, lng: 21.4087018 },
    { lat: 48.6777777, lng: 21.4083175 },
    { lat: 48.67714, lng: 21.4070737 },
    { lat: 48.6769523, lng: 21.4068108 },
    { lat: 48.6763984, lng: 21.4067681 },
    { lat: 48.6760642, lng: 21.407031 },
    { lat: 48.6758659, lng: 21.4073235 },
    { lat: 48.6756296, lng: 21.4083898 },
    { lat: 48.6756317, lng: 21.4086542 },
    { lat: 48.6756962, lng: 21.4088568 },
    { lat: 48.6760577, lng: 21.4090545 },
    { lat: 48.6765353, lng: 21.4089575 },
    { lat: 48.6767225, lng: 21.4090431 },
    { lat: 48.6768468, lng: 21.4092509 },
    { lat: 48.6768858, lng: 21.4095071 },
    { lat: 48.676927, lng: 21.4094502 },
    { lat: 48.6769783, lng: 21.409592 },
    { lat: 48.6766002, lng: 21.4102399 },
    { lat: 48.6765319, lng: 21.4108157 },
    { lat: 48.6764179, lng: 21.410844 },
    { lat: 48.6760904, lng: 21.411794 },
    { lat: 48.6755664, lng: 21.4122253 },
    { lat: 48.6753663, lng: 21.4119308 },
    { lat: 48.6754214, lng: 21.4118113 },
    { lat: 48.6753731, lng: 21.4112866 },
    { lat: 48.6752151, lng: 21.4108856 },
    { lat: 48.6752685, lng: 21.4102607 },
    { lat: 48.6751316, lng: 21.409968 },
    { lat: 48.6744386, lng: 21.4107735 },
    { lat: 48.6743069, lng: 21.411033 },
    { lat: 48.6742463, lng: 21.4113451 },
    { lat: 48.6738125, lng: 21.4112512 },
    { lat: 48.6739825, lng: 21.4098098 },
    { lat: 48.6741683, lng: 21.4092031 },
    { lat: 48.6736507, lng: 21.4089396 },
    { lat: 48.6730794, lng: 21.4089191 },
    { lat: 48.6730711, lng: 21.4085981 },
    { lat: 48.6727513, lng: 21.4083566 },
    { lat: 48.6726858, lng: 21.408883 },
    { lat: 48.6723444, lng: 21.4088288 },
    { lat: 48.6722425, lng: 21.4088125 },
    { lat: 48.6718032, lng: 21.4089895 },
    { lat: 48.6717256, lng: 21.4090998 },
    { lat: 48.6714839, lng: 21.4090748 },
    { lat: 48.6712386, lng: 21.4093046 },
    { lat: 48.671138, lng: 21.4092815 },
    { lat: 48.6709277, lng: 21.4091412 },
    { lat: 48.6708794, lng: 21.4089105 },
    { lat: 48.6700552, lng: 21.408368 },
    { lat: 48.6698654, lng: 21.4081146 },
    { lat: 48.6696192, lng: 21.40804 },
    { lat: 48.669413, lng: 21.408222 },
    { lat: 48.6693202, lng: 21.4076369 },
    { lat: 48.6692109, lng: 21.4074816 },
    { lat: 48.6690479, lng: 21.407557 },
    { lat: 48.6688869, lng: 21.4080126 },
    { lat: 48.6687998, lng: 21.408001 },
    { lat: 48.6688044, lng: 21.4078857 },
    { lat: 48.6685918, lng: 21.4076985 },
    { lat: 48.6683319, lng: 21.4077773 },
    { lat: 48.6681067, lng: 21.4084942 },
    { lat: 48.6672641, lng: 21.4066447 },
    { lat: 48.6668904, lng: 21.4056525 },
    { lat: 48.6663746, lng: 21.4050676 },
    { lat: 48.6660846, lng: 21.4046226 },
    { lat: 48.665856, lng: 21.4045394 },
    { lat: 48.6657792, lng: 21.4044937 },
    { lat: 48.6652141, lng: 21.4064969 },
    { lat: 48.663893, lng: 21.4081713 },
    { lat: 48.6635944, lng: 21.4091098 },
    { lat: 48.664435, lng: 21.4097135 },
    { lat: 48.6639585, lng: 21.4118654 },
    { lat: 48.6633798, lng: 21.4151644 },
    { lat: 48.6642401, lng: 21.4154888 },
    { lat: 48.6637189, lng: 21.417727 },
    { lat: 48.6634282, lng: 21.4175291 },
    { lat: 48.6631131, lng: 21.4171826 },
    { lat: 48.6628339, lng: 21.4184757 },
    { lat: 48.6619991, lng: 21.4205367 },
    { lat: 48.6618655, lng: 21.4209707 },
    { lat: 48.6617313, lng: 21.421761 },
    { lat: 48.6610757, lng: 21.4229182 },
    { lat: 48.6605272, lng: 21.4237 },
    { lat: 48.6604228, lng: 21.4239448 },
    { lat: 48.6597416, lng: 21.4261462 },
    { lat: 48.6595485, lng: 21.4270337 },
    { lat: 48.6594215, lng: 21.4274234 },
    { lat: 48.659329, lng: 21.4279892 },
    { lat: 48.6593439, lng: 21.4283416 },
    { lat: 48.6591849, lng: 21.428909 },
    { lat: 48.6591404, lng: 21.4294224 },
    { lat: 48.6591215, lng: 21.4302697 },
    { lat: 48.6595364, lng: 21.4302184 },
    { lat: 48.6612246, lng: 21.4307986 },
    { lat: 48.6648109, lng: 21.4309604 },
    { lat: 48.6677728, lng: 21.4302708 },
    { lat: 48.6712403, lng: 21.4302871 },
    { lat: 48.6725641, lng: 21.4297761 },
    { lat: 48.6732551, lng: 21.4298508 },
    { lat: 48.6737072, lng: 21.4301089 },
    { lat: 48.6715565, lng: 21.4274597 },
    { lat: 48.6721977, lng: 21.4263087 },
    { lat: 48.672385, lng: 21.426247 },
    { lat: 48.6727586, lng: 21.4251446 },
    { lat: 48.6736083, lng: 21.425565 },
    { lat: 48.674263, lng: 21.4260956 },
    { lat: 48.6737606, lng: 21.4272616 },
    { lat: 48.6741551, lng: 21.427286 },
    { lat: 48.674636, lng: 21.4274481 },
    { lat: 48.6747516, lng: 21.4275543 },
    { lat: 48.6753592, lng: 21.4286575 },
    { lat: 48.6756452, lng: 21.4288961 },
    { lat: 48.6761618, lng: 21.4292011 },
    { lat: 48.6767099, lng: 21.4281777 },
    { lat: 48.6768689, lng: 21.4279947 },
    { lat: 48.6768351, lng: 21.4278414 },
    { lat: 48.6770541, lng: 21.4270633 },
    { lat: 48.6772588, lng: 21.4270937 },
    { lat: 48.6773528, lng: 21.4272675 },
    { lat: 48.6773743, lng: 21.4281506 },
    { lat: 48.677331, lng: 21.428292 },
    { lat: 48.6792052, lng: 21.4284614 },
    { lat: 48.6795831, lng: 21.4261991 },
    { lat: 48.6803894, lng: 21.4266826 },
    { lat: 48.6802881, lng: 21.4259876 },
    { lat: 48.6802607, lng: 21.4253318 },
    { lat: 48.6803019, lng: 21.4245107 },
    { lat: 48.6805968, lng: 21.4236381 },
    { lat: 48.6811243, lng: 21.4225571 },
    { lat: 48.6817219, lng: 21.420193 },
    { lat: 48.6819019, lng: 21.4199235 },
    { lat: 48.682141, lng: 21.4194124 },
    { lat: 48.6823352, lng: 21.4188581 },
    { lat: 48.6823903, lng: 21.4185538 },
    { lat: 48.6825672, lng: 21.4183685 },
    { lat: 48.6826396, lng: 21.4181133 },
    { lat: 48.6828709, lng: 21.417753 },
    { lat: 48.6829387, lng: 21.4174639 },
    { lat: 48.6830638, lng: 21.4173568 },
    { lat: 48.6830793, lng: 21.417235 },
    { lat: 48.6832467, lng: 21.4168808 },
    { lat: 48.6835206, lng: 21.4165113 },
    { lat: 48.6836365, lng: 21.4161734 },
    { lat: 48.6838228, lng: 21.4158838 },
    { lat: 48.6838617, lng: 21.4154869 },
    { lat: 48.6839991, lng: 21.4152277 },
    { lat: 48.6841888, lng: 21.4145215 },
    { lat: 48.6843011, lng: 21.4142917 },
    { lat: 48.6844007, lng: 21.4136259 },
    { lat: 48.6845419, lng: 21.413225 },
    { lat: 48.6845361, lng: 21.4128778 },
    { lat: 48.6846013, lng: 21.4128328 },
    { lat: 48.6845477, lng: 21.412543 },
    { lat: 48.6845147, lng: 21.4123778 },
  ],
  Rozhanovce: [
    { lat: 48.7418188, lng: 21.3355201 },
    { lat: 48.7417809, lng: 21.3360558 },
    { lat: 48.7417412, lng: 21.3367948 },
    { lat: 48.7425012, lng: 21.3368486 },
    { lat: 48.7426976, lng: 21.3372393 },
    { lat: 48.7413671, lng: 21.3380093 },
    { lat: 48.7404575, lng: 21.3382567 },
    { lat: 48.7401732, lng: 21.338009 },
    { lat: 48.7398843, lng: 21.3380952 },
    { lat: 48.7397953, lng: 21.338199 },
    { lat: 48.7396735, lng: 21.3416975 },
    { lat: 48.7383999, lng: 21.3414498 },
    { lat: 48.7381624, lng: 21.3396176 },
    { lat: 48.7381708, lng: 21.3397486 },
    { lat: 48.7380664, lng: 21.3401255 },
    { lat: 48.7373368, lng: 21.3421673 },
    { lat: 48.7371081, lng: 21.3436162 },
    { lat: 48.7371876, lng: 21.3439048 },
    { lat: 48.7383102, lng: 21.3461669 },
    { lat: 48.7381874, lng: 21.3466471 },
    { lat: 48.7383358, lng: 21.3472433 },
    { lat: 48.7383821, lng: 21.3479185 },
    { lat: 48.7384662, lng: 21.3483174 },
    { lat: 48.7384468, lng: 21.3488328 },
    { lat: 48.7387187, lng: 21.3495314 },
    { lat: 48.7384836, lng: 21.3506596 },
    { lat: 48.7383873, lng: 21.3509396 },
    { lat: 48.7384127, lng: 21.3512192 },
    { lat: 48.7384894, lng: 21.3513398 },
    { lat: 48.7386072, lng: 21.3520019 },
    { lat: 48.7387487, lng: 21.352323 },
    { lat: 48.738757, lng: 21.3526786 },
    { lat: 48.7388629, lng: 21.3528272 },
    { lat: 48.7390126, lng: 21.3532832 },
    { lat: 48.7392965, lng: 21.3538926 },
    { lat: 48.7395763, lng: 21.3539925 },
    { lat: 48.7396022, lng: 21.3541404 },
    { lat: 48.7394812, lng: 21.3542724 },
    { lat: 48.7394897, lng: 21.3544373 },
    { lat: 48.7396749, lng: 21.3548039 },
    { lat: 48.7396965, lng: 21.3549554 },
    { lat: 48.7398598, lng: 21.3551502 },
    { lat: 48.739813, lng: 21.3555003 },
    { lat: 48.7398644, lng: 21.3555995 },
    { lat: 48.7402035, lng: 21.3556889 },
    { lat: 48.7404468, lng: 21.3560012 },
    { lat: 48.7403692, lng: 21.3563364 },
    { lat: 48.7404179, lng: 21.3564589 },
    { lat: 48.7407195, lng: 21.3567573 },
    { lat: 48.7407444, lng: 21.356839 },
    { lat: 48.7407007, lng: 21.356924 },
    { lat: 48.7407027, lng: 21.357172 },
    { lat: 48.7407472, lng: 21.3573415 },
    { lat: 48.7408892, lng: 21.3575389 },
    { lat: 48.7410218, lng: 21.3575751 },
    { lat: 48.7410795, lng: 21.3577686 },
    { lat: 48.7409788, lng: 21.357972 },
    { lat: 48.7411228, lng: 21.3582762 },
    { lat: 48.7409525, lng: 21.3583756 },
    { lat: 48.7409904, lng: 21.3587759 },
    { lat: 48.7410462, lng: 21.3589058 },
    { lat: 48.7408537, lng: 21.3592117 },
    { lat: 48.7408794, lng: 21.3593504 },
    { lat: 48.7410165, lng: 21.3594971 },
    { lat: 48.7411725, lng: 21.3595182 },
    { lat: 48.7411934, lng: 21.3600248 },
    { lat: 48.7414883, lng: 21.3600281 },
    { lat: 48.7414427, lng: 21.3603874 },
    { lat: 48.7413315, lng: 21.3605098 },
    { lat: 48.7412246, lng: 21.3608837 },
    { lat: 48.7414203, lng: 21.361174 },
    { lat: 48.7414441, lng: 21.3613321 },
    { lat: 48.7415123, lng: 21.3614358 },
    { lat: 48.7414907, lng: 21.3616523 },
    { lat: 48.7415345, lng: 21.3619862 },
    { lat: 48.7415942, lng: 21.3620994 },
    { lat: 48.7415842, lng: 21.362293 },
    { lat: 48.7414786, lng: 21.362563 },
    { lat: 48.7414801, lng: 21.3627604 },
    { lat: 48.7412079, lng: 21.3630661 },
    { lat: 48.7411288, lng: 21.3632437 },
    { lat: 48.7411327, lng: 21.3635215 },
    { lat: 48.7410529, lng: 21.3637491 },
    { lat: 48.7410715, lng: 21.3639208 },
    { lat: 48.7408844, lng: 21.3643247 },
    { lat: 48.7408218, lng: 21.3648645 },
    { lat: 48.740732, lng: 21.3650942 },
    { lat: 48.7406498, lng: 21.36562 },
    { lat: 48.7405104, lng: 21.3658545 },
    { lat: 48.7405003, lng: 21.3660053 },
    { lat: 48.7401678, lng: 21.366509 },
    { lat: 48.7401155, lng: 21.3666437 },
    { lat: 48.7401271, lng: 21.3668422 },
    { lat: 48.7400607, lng: 21.3669566 },
    { lat: 48.739934, lng: 21.3670077 },
    { lat: 48.7396696, lng: 21.3675014 },
    { lat: 48.7394462, lng: 21.3677056 },
    { lat: 48.7394411, lng: 21.367953 },
    { lat: 48.7392583, lng: 21.3681221 },
    { lat: 48.739135, lng: 21.3680998 },
    { lat: 48.7390487, lng: 21.3686366 },
    { lat: 48.7389126, lng: 21.3687133 },
    { lat: 48.738787, lng: 21.3692142 },
    { lat: 48.7386188, lng: 21.3693523 },
    { lat: 48.7386723, lng: 21.370006 },
    { lat: 48.7384619, lng: 21.3698646 },
    { lat: 48.7384183, lng: 21.3700997 },
    { lat: 48.7386645, lng: 21.3702426 },
    { lat: 48.7386966, lng: 21.3706382 },
    { lat: 48.7387878, lng: 21.3709771 },
    { lat: 48.7387267, lng: 21.3712676 },
    { lat: 48.7386609, lng: 21.3713608 },
    { lat: 48.7385213, lng: 21.3713201 },
    { lat: 48.7384398, lng: 21.3714897 },
    { lat: 48.7383623, lng: 21.3715021 },
    { lat: 48.7383923, lng: 21.3716373 },
    { lat: 48.7384748, lng: 21.3716117 },
    { lat: 48.7385556, lng: 21.3717192 },
    { lat: 48.7384309, lng: 21.3721705 },
    { lat: 48.7384955, lng: 21.3721536 },
    { lat: 48.738613, lng: 21.3722835 },
    { lat: 48.7385622, lng: 21.3725009 },
    { lat: 48.7386227, lng: 21.372828 },
    { lat: 48.7386088, lng: 21.3729708 },
    { lat: 48.7388464, lng: 21.3731667 },
    { lat: 48.738897, lng: 21.3733533 },
    { lat: 48.7387751, lng: 21.3734871 },
    { lat: 48.7386851, lng: 21.373449 },
    { lat: 48.7385713, lng: 21.3736336 },
    { lat: 48.7386161, lng: 21.3738962 },
    { lat: 48.7386022, lng: 21.3741791 },
    { lat: 48.7387557, lng: 21.3744228 },
    { lat: 48.7386878, lng: 21.3744874 },
    { lat: 48.7386524, lng: 21.3746926 },
    { lat: 48.7385399, lng: 21.3747299 },
    { lat: 48.7385205, lng: 21.3751698 },
    { lat: 48.7385928, lng: 21.3752639 },
    { lat: 48.7384945, lng: 21.3754357 },
    { lat: 48.7385566, lng: 21.375629 },
    { lat: 48.7385234, lng: 21.3757243 },
    { lat: 48.7384511, lng: 21.3757139 },
    { lat: 48.7384766, lng: 21.3758155 },
    { lat: 48.7384465, lng: 21.3758974 },
    { lat: 48.7382548, lng: 21.3758841 },
    { lat: 48.738183, lng: 21.3759671 },
    { lat: 48.7382651, lng: 21.3761184 },
    { lat: 48.7382576, lng: 21.3762451 },
    { lat: 48.7381301, lng: 21.3763419 },
    { lat: 48.7381299, lng: 21.3764552 },
    { lat: 48.7379843, lng: 21.3766249 },
    { lat: 48.7380749, lng: 21.3770201 },
    { lat: 48.7380601, lng: 21.377096 },
    { lat: 48.7379933, lng: 21.3771159 },
    { lat: 48.7380037, lng: 21.3773138 },
    { lat: 48.7377999, lng: 21.3774631 },
    { lat: 48.7377782, lng: 21.3776361 },
    { lat: 48.7376237, lng: 21.3778548 },
    { lat: 48.737483, lng: 21.3779279 },
    { lat: 48.7373683, lng: 21.3781157 },
    { lat: 48.7372424, lng: 21.3785033 },
    { lat: 48.7370714, lng: 21.3787648 },
    { lat: 48.7370623, lng: 21.3789484 },
    { lat: 48.7367431, lng: 21.3794193 },
    { lat: 48.7367771, lng: 21.3795378 },
    { lat: 48.7367267, lng: 21.3797359 },
    { lat: 48.7365945, lng: 21.3798291 },
    { lat: 48.7364607, lng: 21.3801729 },
    { lat: 48.7363447, lng: 21.3807747 },
    { lat: 48.7362097, lng: 21.3809683 },
    { lat: 48.7361385, lng: 21.381372 },
    { lat: 48.7361897, lng: 21.3813881 },
    { lat: 48.7362284, lng: 21.3815937 },
    { lat: 48.7360913, lng: 21.3819007 },
    { lat: 48.7358764, lng: 21.3821271 },
    { lat: 48.7357112, lng: 21.3825091 },
    { lat: 48.7356426, lng: 21.3825123 },
    { lat: 48.7354821, lng: 21.3830184 },
    { lat: 48.7350897, lng: 21.3832958 },
    { lat: 48.7349275, lng: 21.3836284 },
    { lat: 48.7345511, lng: 21.384855 },
    { lat: 48.7343709, lng: 21.3851934 },
    { lat: 48.734375, lng: 21.3853336 },
    { lat: 48.734135, lng: 21.3860397 },
    { lat: 48.7335598, lng: 21.3869383 },
    { lat: 48.7331862, lng: 21.3873558 },
    { lat: 48.7329782, lng: 21.3877364 },
    { lat: 48.7325648, lng: 21.3881081 },
    { lat: 48.732901, lng: 21.38881 },
    { lat: 48.7329706, lng: 21.3887739 },
    { lat: 48.7345704, lng: 21.3891713 },
    { lat: 48.7353368, lng: 21.3892536 },
    { lat: 48.7380346, lng: 21.3891582 },
    { lat: 48.738903, lng: 21.3890682 },
    { lat: 48.7395344, lng: 21.388883 },
    { lat: 48.740039, lng: 21.3886348 },
    { lat: 48.7406871, lng: 21.3885205 },
    { lat: 48.7423371, lng: 21.3878891 },
    { lat: 48.7432059, lng: 21.3878529 },
    { lat: 48.7438553, lng: 21.3880822 },
    { lat: 48.7456713, lng: 21.3892847 },
    { lat: 48.7458555, lng: 21.389387 },
    { lat: 48.745883, lng: 21.3894018 },
    { lat: 48.7466845, lng: 21.3892254 },
    { lat: 48.74762, lng: 21.388823 },
    { lat: 48.7483778, lng: 21.3886071 },
    { lat: 48.7495879, lng: 21.3883615 },
    { lat: 48.7508699, lng: 21.3875729 },
    { lat: 48.7519757, lng: 21.387069 },
    { lat: 48.7528958, lng: 21.386301 },
    { lat: 48.7534225, lng: 21.3860063 },
    { lat: 48.7538898, lng: 21.3858604 },
    { lat: 48.7552211, lng: 21.3849577 },
    { lat: 48.7558124, lng: 21.3845187 },
    { lat: 48.7561354, lng: 21.384143 },
    { lat: 48.7569161, lng: 21.3837214 },
    { lat: 48.7572804, lng: 21.3835949 },
    { lat: 48.7580406, lng: 21.3830856 },
    { lat: 48.7591469, lng: 21.3829816 },
    { lat: 48.7595232, lng: 21.3830766 },
    { lat: 48.7599674, lng: 21.3830638 },
    { lat: 48.7603531, lng: 21.3832461 },
    { lat: 48.7613549, lng: 21.3834375 },
    { lat: 48.7622363, lng: 21.3837135 },
    { lat: 48.762819, lng: 21.3836691 },
    { lat: 48.7640389, lng: 21.3834105 },
    { lat: 48.7643571, lng: 21.3832094 },
    { lat: 48.764706, lng: 21.3831008 },
    { lat: 48.7651018, lng: 21.38268 },
    { lat: 48.7653202, lng: 21.3823016 },
    { lat: 48.7654537, lng: 21.3821763 },
    { lat: 48.765679, lng: 21.3819961 },
    { lat: 48.7661295, lng: 21.3818167 },
    { lat: 48.7662593, lng: 21.3818314 },
    { lat: 48.7666464, lng: 21.3820561 },
    { lat: 48.7673576, lng: 21.3828473 },
    { lat: 48.7683336, lng: 21.3835074 },
    { lat: 48.7690244, lng: 21.3838449 },
    { lat: 48.7695075, lng: 21.3837378 },
    { lat: 48.7702967, lng: 21.3839444 },
    { lat: 48.7709661, lng: 21.3838253 },
    { lat: 48.7716876, lng: 21.3838703 },
    { lat: 48.7720091, lng: 21.3837061 },
    { lat: 48.7725619, lng: 21.3839381 },
    { lat: 48.7728172, lng: 21.3844277 },
    { lat: 48.7728404, lng: 21.384415 },
    { lat: 48.7735195, lng: 21.3842086 },
    { lat: 48.7741324, lng: 21.3834736 },
    { lat: 48.7742311, lng: 21.3832422 },
    { lat: 48.7744474, lng: 21.3829813 },
    { lat: 48.7747649, lng: 21.3821537 },
    { lat: 48.7751105, lng: 21.3815957 },
    { lat: 48.7756222, lng: 21.3811701 },
    { lat: 48.7762632, lng: 21.3804364 },
    { lat: 48.7767625, lng: 21.3800834 },
    { lat: 48.7771042, lng: 21.3797361 },
    { lat: 48.777582, lng: 21.3787038 },
    { lat: 48.7783922, lng: 21.3774141 },
    { lat: 48.7791915, lng: 21.3766348 },
    { lat: 48.7797673, lng: 21.375868 },
    { lat: 48.7801438, lng: 21.3751783 },
    { lat: 48.7805709, lng: 21.3741772 },
    { lat: 48.7807845, lng: 21.3738668 },
    { lat: 48.7809545, lng: 21.3737249 },
    { lat: 48.7813759, lng: 21.3735891 },
    { lat: 48.7821021, lng: 21.3736019 },
    { lat: 48.7826662, lng: 21.373954 },
    { lat: 48.7828944, lng: 21.3741729 },
    { lat: 48.7833205, lng: 21.3743475 },
    { lat: 48.784653, lng: 21.3745041 },
    { lat: 48.7849335, lng: 21.3744382 },
    { lat: 48.7853321, lng: 21.3742204 },
    { lat: 48.7857545, lng: 21.374118 },
    { lat: 48.7879287, lng: 21.3739319 },
    { lat: 48.789227, lng: 21.3741151 },
    { lat: 48.7895991, lng: 21.3740262 },
    { lat: 48.7897312, lng: 21.3739276 },
    { lat: 48.7903618, lng: 21.3738659 },
    { lat: 48.7910142, lng: 21.3737149 },
    { lat: 48.7910726, lng: 21.3734023 },
    { lat: 48.7913419, lng: 21.3718303 },
    { lat: 48.7912, lng: 21.3713754 },
    { lat: 48.7910382, lng: 21.3698405 },
    { lat: 48.7909843, lng: 21.3698834 },
    { lat: 48.790522, lng: 21.3690578 },
    { lat: 48.7899934, lng: 21.3686922 },
    { lat: 48.7897801, lng: 21.3687803 },
    { lat: 48.7897663, lng: 21.368697 },
    { lat: 48.7895234, lng: 21.3685562 },
    { lat: 48.7894153, lng: 21.3683638 },
    { lat: 48.7893205, lng: 21.3683931 },
    { lat: 48.7890674, lng: 21.3678687 },
    { lat: 48.7886147, lng: 21.3674758 },
    { lat: 48.7882078, lng: 21.366928 },
    { lat: 48.7880428, lng: 21.3665279 },
    { lat: 48.7875558, lng: 21.3676707 },
    { lat: 48.7875692, lng: 21.3677786 },
    { lat: 48.787242, lng: 21.3683495 },
    { lat: 48.7870634, lng: 21.368173 },
    { lat: 48.787181, lng: 21.3679272 },
    { lat: 48.78627, lng: 21.3675272 },
    { lat: 48.78586, lng: 21.3672526 },
    { lat: 48.7855293, lng: 21.3671793 },
    { lat: 48.7850225, lng: 21.366075 },
    { lat: 48.7853498, lng: 21.3653164 },
    { lat: 48.7851235, lng: 21.3651613 },
    { lat: 48.785018, lng: 21.3653641 },
    { lat: 48.7846359, lng: 21.3652647 },
    { lat: 48.785169, lng: 21.3615388 },
    { lat: 48.7852099, lng: 21.3610614 },
    { lat: 48.7850233, lng: 21.360955 },
    { lat: 48.7850509, lng: 21.3607822 },
    { lat: 48.7852437, lng: 21.3601209 },
    { lat: 48.7854908, lng: 21.3595833 },
    { lat: 48.7856722, lng: 21.3587976 },
    { lat: 48.7856723, lng: 21.3585828 },
    { lat: 48.7854802, lng: 21.3585132 },
    { lat: 48.7855758, lng: 21.3576527 },
    { lat: 48.7857555, lng: 21.3570783 },
    { lat: 48.7862048, lng: 21.3562253 },
    { lat: 48.7863764, lng: 21.3557107 },
    { lat: 48.7861078, lng: 21.3555364 },
    { lat: 48.7860727, lng: 21.355233 },
    { lat: 48.7857531, lng: 21.3543354 },
    { lat: 48.7849552, lng: 21.3530549 },
    { lat: 48.7849757, lng: 21.3526471 },
    { lat: 48.7844224, lng: 21.3514658 },
    { lat: 48.7839204, lng: 21.3496602 },
    { lat: 48.7834202, lng: 21.3484817 },
    { lat: 48.7835536, lng: 21.3479787 },
    { lat: 48.7837541, lng: 21.3476837 },
    { lat: 48.7841387, lng: 21.3474549 },
    { lat: 48.7845562, lng: 21.3473009 },
    { lat: 48.7846899, lng: 21.3470765 },
    { lat: 48.7847034, lng: 21.3466616 },
    { lat: 48.7850572, lng: 21.3465616 },
    { lat: 48.7851785, lng: 21.3465878 },
    { lat: 48.785522, lng: 21.3464543 },
    { lat: 48.7860234, lng: 21.3457282 },
    { lat: 48.7861154, lng: 21.3453842 },
    { lat: 48.7861661, lng: 21.3447091 },
    { lat: 48.7862362, lng: 21.3444923 },
    { lat: 48.7863927, lng: 21.3434474 },
    { lat: 48.7864549, lng: 21.3434228 },
    { lat: 48.786374, lng: 21.3430918 },
    { lat: 48.7859962, lng: 21.342396 },
    { lat: 48.785714, lng: 21.3421392 },
    { lat: 48.7854839, lng: 21.3418582 },
    { lat: 48.7854649, lng: 21.3417503 },
    { lat: 48.7854147, lng: 21.3417984 },
    { lat: 48.7849797, lng: 21.3409263 },
    { lat: 48.784554, lng: 21.3406866 },
    { lat: 48.7843969, lng: 21.340442 },
    { lat: 48.7842449, lng: 21.3390551 },
    { lat: 48.7838941, lng: 21.3388049 },
    { lat: 48.7837072, lng: 21.3385762 },
    { lat: 48.783354, lng: 21.3379119 },
    { lat: 48.782942, lng: 21.3375354 },
    { lat: 48.7822169, lng: 21.3360657 },
    { lat: 48.7820417, lng: 21.335924 },
    { lat: 48.7817039, lng: 21.3358118 },
    { lat: 48.7815486, lng: 21.3356577 },
    { lat: 48.7813765, lng: 21.3353627 },
    { lat: 48.7812228, lng: 21.3348577 },
    { lat: 48.7810646, lng: 21.3346135 },
    { lat: 48.7809098, lng: 21.3340647 },
    { lat: 48.7803149, lng: 21.332888 },
    { lat: 48.7800737, lng: 21.3325764 },
    { lat: 48.7798162, lng: 21.3324747 },
    { lat: 48.7796387, lng: 21.3320688 },
    { lat: 48.779182, lng: 21.3314464 },
    { lat: 48.7788612, lng: 21.3308442 },
    { lat: 48.7787732, lng: 21.3306562 },
    { lat: 48.7789132, lng: 21.330442 },
    { lat: 48.7796587, lng: 21.3297603 },
    { lat: 48.7793263, lng: 21.3293712 },
    { lat: 48.7791652, lng: 21.3288022 },
    { lat: 48.7781107, lng: 21.329059 },
    { lat: 48.7779283, lng: 21.3286894 },
    { lat: 48.777463, lng: 21.328782 },
    { lat: 48.7772936, lng: 21.3279823 },
    { lat: 48.779314, lng: 21.3273986 },
    { lat: 48.77722, lng: 21.315227 },
    { lat: 48.7771469, lng: 21.3150002 },
    { lat: 48.7770821, lng: 21.3148017 },
    { lat: 48.7765404, lng: 21.3151555 },
    { lat: 48.7753959, lng: 21.3155964 },
    { lat: 48.7749184, lng: 21.3156963 },
    { lat: 48.7745671, lng: 21.3156922 },
    { lat: 48.7738367, lng: 21.3155419 },
    { lat: 48.7734322, lng: 21.3157846 },
    { lat: 48.7731282, lng: 21.3161078 },
    { lat: 48.7725956, lng: 21.3168895 },
    { lat: 48.7729236, lng: 21.317554 },
    { lat: 48.7729613, lng: 21.3177277 },
    { lat: 48.7729603, lng: 21.3181074 },
    { lat: 48.7728744, lng: 21.3183097 },
    { lat: 48.7727269, lng: 21.3184016 },
    { lat: 48.7724048, lng: 21.3183365 },
    { lat: 48.7722016, lng: 21.318357 },
    { lat: 48.7720479, lng: 21.3187436 },
    { lat: 48.7720291, lng: 21.3192349 },
    { lat: 48.7718901, lng: 21.3201194 },
    { lat: 48.7716406, lng: 21.3202309 },
    { lat: 48.7712472, lng: 21.3201051 },
    { lat: 48.7709292, lng: 21.3197614 },
    { lat: 48.7707286, lng: 21.3194835 },
    { lat: 48.7700212, lng: 21.3200312 },
    { lat: 48.7700465, lng: 21.3204516 },
    { lat: 48.7691832, lng: 21.3205756 },
    { lat: 48.7692861, lng: 21.3216998 },
    { lat: 48.7684229, lng: 21.3246365 },
    { lat: 48.7683432, lng: 21.3247547 },
    { lat: 48.7669176, lng: 21.3245 },
    { lat: 48.7655945, lng: 21.3243605 },
    { lat: 48.7643254, lng: 21.3229521 },
    { lat: 48.7637958, lng: 21.3224491 },
    { lat: 48.7637141, lng: 21.3219865 },
    { lat: 48.7631118, lng: 21.3221631 },
    { lat: 48.7619274, lng: 21.3220351 },
    { lat: 48.7610977, lng: 21.3225046 },
    { lat: 48.7608625, lng: 21.3225387 },
    { lat: 48.7605528, lng: 21.3205618 },
    { lat: 48.7604598, lng: 21.3202069 },
    { lat: 48.7601703, lng: 21.3205291 },
    { lat: 48.7597866, lng: 21.320677 },
    { lat: 48.759581, lng: 21.320693 },
    { lat: 48.7585699, lng: 21.3204546 },
    { lat: 48.7581, lng: 21.3206099 },
    { lat: 48.7573006, lng: 21.3212321 },
    { lat: 48.757021, lng: 21.3213603 },
    { lat: 48.7567844, lng: 21.3213877 },
    { lat: 48.7561546, lng: 21.3218883 },
    { lat: 48.7556218, lng: 21.3225379 },
    { lat: 48.7554677, lng: 21.3227986 },
    { lat: 48.7550369, lng: 21.3232115 },
    { lat: 48.7546574, lng: 21.3233502 },
    { lat: 48.7539669, lng: 21.3233709 },
    { lat: 48.75369, lng: 21.3234793 },
    { lat: 48.7534346, lng: 21.3237437 },
    { lat: 48.7531734, lng: 21.3239238 },
    { lat: 48.7527947, lng: 21.3239015 },
    { lat: 48.7523027, lng: 21.3239688 },
    { lat: 48.7519443, lng: 21.3243203 },
    { lat: 48.7517332, lng: 21.3246676 },
    { lat: 48.7514363, lng: 21.3253443 },
    { lat: 48.7511263, lng: 21.3258462 },
    { lat: 48.7507242, lng: 21.3260933 },
    { lat: 48.7504789, lng: 21.3263186 },
    { lat: 48.7489543, lng: 21.3271109 },
    { lat: 48.748183, lng: 21.3276468 },
    { lat: 48.7482854, lng: 21.3280227 },
    { lat: 48.7487204, lng: 21.3284109 },
    { lat: 48.7482697, lng: 21.3292519 },
    { lat: 48.7477749, lng: 21.3299347 },
    { lat: 48.747397, lng: 21.3306084 },
    { lat: 48.7471059, lng: 21.3309948 },
    { lat: 48.7467497, lng: 21.3317151 },
    { lat: 48.746588, lng: 21.3317259 },
    { lat: 48.7464704, lng: 21.3307082 },
    { lat: 48.7460734, lng: 21.3302271 },
    { lat: 48.745752, lng: 21.3303916 },
    { lat: 48.7456809, lng: 21.3309093 },
    { lat: 48.7460314, lng: 21.3315223 },
    { lat: 48.7459807, lng: 21.3322243 },
    { lat: 48.7458682, lng: 21.3322599 },
    { lat: 48.745341, lng: 21.3318742 },
    { lat: 48.7452211, lng: 21.3315901 },
    { lat: 48.7452074, lng: 21.3313225 },
    { lat: 48.745441, lng: 21.3303468 },
    { lat: 48.7452231, lng: 21.3301658 },
    { lat: 48.7450699, lng: 21.3299036 },
    { lat: 48.7448486, lng: 21.3303589 },
    { lat: 48.7446768, lng: 21.3313068 },
    { lat: 48.7447458, lng: 21.3315759 },
    { lat: 48.7450031, lng: 21.3318255 },
    { lat: 48.7451761, lng: 21.3321918 },
    { lat: 48.7450235, lng: 21.332503 },
    { lat: 48.7449063, lng: 21.332939 },
    { lat: 48.7449604, lng: 21.3334647 },
    { lat: 48.7447975, lng: 21.3335523 },
    { lat: 48.7444347, lng: 21.3340159 },
    { lat: 48.7440938, lng: 21.3341375 },
    { lat: 48.7438719, lng: 21.3331919 },
    { lat: 48.7435426, lng: 21.3333202 },
    { lat: 48.7436885, lng: 21.334199 },
    { lat: 48.7435854, lng: 21.3342924 },
    { lat: 48.743066, lng: 21.3344503 },
    { lat: 48.7430167, lng: 21.3346155 },
    { lat: 48.7430202, lng: 21.334692 },
    { lat: 48.7433865, lng: 21.3350457 },
    { lat: 48.7438833, lng: 21.3350602 },
    { lat: 48.7442864, lng: 21.3354449 },
    { lat: 48.7438538, lng: 21.3360193 },
    { lat: 48.7439214, lng: 21.3363622 },
    { lat: 48.7433885, lng: 21.3367538 },
    { lat: 48.7428019, lng: 21.3359988 },
    { lat: 48.7425722, lng: 21.3358036 },
    { lat: 48.7422783, lng: 21.3351996 },
    { lat: 48.7418188, lng: 21.3355201 },
  ],
  Ploské: [
    { lat: 48.8135956, lng: 21.3709378 },
    { lat: 48.8145679, lng: 21.3706734 },
    { lat: 48.8146178, lng: 21.3703555 },
    { lat: 48.8150982, lng: 21.3702697 },
    { lat: 48.8157711, lng: 21.370275 },
    { lat: 48.8181621, lng: 21.3702146 },
    { lat: 48.8187721, lng: 21.3703567 },
    { lat: 48.8192463, lng: 21.3705514 },
    { lat: 48.8195003, lng: 21.3706465 },
    { lat: 48.8205542, lng: 21.3718348 },
    { lat: 48.8219563, lng: 21.3725566 },
    { lat: 48.8222505, lng: 21.3729111 },
    { lat: 48.8235618, lng: 21.3725475 },
    { lat: 48.8247583, lng: 21.372407 },
    { lat: 48.8255154, lng: 21.3725149 },
    { lat: 48.8263543, lng: 21.3724196 },
    { lat: 48.8273376, lng: 21.3721088 },
    { lat: 48.8293925, lng: 21.3711367 },
    { lat: 48.8306114, lng: 21.3702046 },
    { lat: 48.8315089, lng: 21.3693914 },
    { lat: 48.8324834, lng: 21.368342 },
    { lat: 48.8334721, lng: 21.3676051 },
    { lat: 48.8337036, lng: 21.3673635 },
    { lat: 48.833919, lng: 21.3670389 },
    { lat: 48.8343239, lng: 21.3664496 },
    { lat: 48.8347508, lng: 21.366238 },
    { lat: 48.835515, lng: 21.3667984 },
    { lat: 48.8357613, lng: 21.3665427 },
    { lat: 48.8366574, lng: 21.3665073 },
    { lat: 48.838087, lng: 21.367257 },
    { lat: 48.838126, lng: 21.366195 },
    { lat: 48.838128, lng: 21.366094 },
    { lat: 48.838145, lng: 21.365633 },
    { lat: 48.838154, lng: 21.365378 },
    { lat: 48.838157, lng: 21.36529 },
    { lat: 48.838178, lng: 21.364717 },
    { lat: 48.838365, lng: 21.357431 },
    { lat: 48.837358, lng: 21.356893 },
    { lat: 48.837139, lng: 21.355658 },
    { lat: 48.83724, lng: 21.354508 },
    { lat: 48.837558, lng: 21.350913 },
    { lat: 48.837599, lng: 21.350431 },
    { lat: 48.837607, lng: 21.350376 },
    { lat: 48.837619, lng: 21.35029 },
    { lat: 48.837647, lng: 21.350094 },
    { lat: 48.838659, lng: 21.342802 },
    { lat: 48.838677, lng: 21.342652 },
    { lat: 48.839138, lng: 21.339471 },
    { lat: 48.839396, lng: 21.338722 },
    { lat: 48.839565, lng: 21.337876 },
    { lat: 48.83775, lng: 21.337883 },
    { lat: 48.837474, lng: 21.337882 },
    { lat: 48.836754, lng: 21.33788 },
    { lat: 48.83626, lng: 21.337878 },
    { lat: 48.835864, lng: 21.336987 },
    { lat: 48.835753, lng: 21.336731 },
    { lat: 48.835464, lng: 21.336076 },
    { lat: 48.83511, lng: 21.335273 },
    { lat: 48.834746, lng: 21.33444 },
    { lat: 48.834324, lng: 21.333465 },
    { lat: 48.833542, lng: 21.3328953 },
    { lat: 48.8274132, lng: 21.3287492 },
    { lat: 48.8242088, lng: 21.3313396 },
    { lat: 48.8232021, lng: 21.3220082 },
    { lat: 48.8226459, lng: 21.3163523 },
    { lat: 48.8269453, lng: 21.3136687 },
    { lat: 48.827222, lng: 21.306324 },
    { lat: 48.827021, lng: 21.306577 },
    { lat: 48.826833, lng: 21.307207 },
    { lat: 48.826698, lng: 21.307549 },
    { lat: 48.826537, lng: 21.307728 },
    { lat: 48.826339, lng: 21.307833 },
    { lat: 48.826186, lng: 21.307976 },
    { lat: 48.825922, lng: 21.308128 },
    { lat: 48.825594, lng: 21.308129 },
    { lat: 48.825459, lng: 21.308071 },
    { lat: 48.825065, lng: 21.307712 },
    { lat: 48.824756, lng: 21.307102 },
    { lat: 48.82466, lng: 21.306853 },
    { lat: 48.824503, lng: 21.306582 },
    { lat: 48.824176, lng: 21.306192 },
    { lat: 48.823604, lng: 21.305869 },
    { lat: 48.822127, lng: 21.307301 },
    { lat: 48.821864, lng: 21.307629 },
    { lat: 48.821934, lng: 21.307741 },
    { lat: 48.822308, lng: 21.308403 },
    { lat: 48.822606, lng: 21.308971 },
    { lat: 48.822834, lng: 21.309509 },
    { lat: 48.822873, lng: 21.30968 },
    { lat: 48.82289, lng: 21.309755 },
    { lat: 48.822932, lng: 21.309938 },
    { lat: 48.822946, lng: 21.310289 },
    { lat: 48.822949, lng: 21.310351 },
    { lat: 48.822882, lng: 21.31068 },
    { lat: 48.822516, lng: 21.310903 },
    { lat: 48.822294, lng: 21.31089 },
    { lat: 48.821956, lng: 21.310706 },
    { lat: 48.821819, lng: 21.310531 },
    { lat: 48.821782, lng: 21.310482 },
    { lat: 48.821361, lng: 21.30992 },
    { lat: 48.821358, lng: 21.309915 },
    { lat: 48.821286, lng: 21.309828 },
    { lat: 48.821194, lng: 21.309791 },
    { lat: 48.821108, lng: 21.309756 },
    { lat: 48.82105, lng: 21.309869 },
    { lat: 48.821001, lng: 21.309948 },
    { lat: 48.820944, lng: 21.310039 },
    { lat: 48.820937, lng: 21.310049 },
    { lat: 48.820815, lng: 21.31017 },
    { lat: 48.820627, lng: 21.310272 },
    { lat: 48.820513, lng: 21.310286 },
    { lat: 48.820338, lng: 21.310199 },
    { lat: 48.820214, lng: 21.310038 },
    { lat: 48.820194, lng: 21.310014 },
    { lat: 48.8201, lng: 21.309897 },
    { lat: 48.820094, lng: 21.30989 },
    { lat: 48.820168, lng: 21.309605 },
    { lat: 48.820202, lng: 21.309119 },
    { lat: 48.820268, lng: 21.308793 },
    { lat: 48.820401, lng: 21.308475 },
    { lat: 48.820372, lng: 21.30814 },
    { lat: 48.820254, lng: 21.307726 },
    { lat: 48.820179, lng: 21.307457 },
    { lat: 48.820088, lng: 21.307303 },
    { lat: 48.819992, lng: 21.307345 },
    { lat: 48.81978, lng: 21.307078 },
    { lat: 48.819741, lng: 21.306996 },
    { lat: 48.81961, lng: 21.306922 },
    { lat: 48.819369, lng: 21.306712 },
    { lat: 48.818946, lng: 21.30672 },
    { lat: 48.818656, lng: 21.306804 },
    { lat: 48.818331, lng: 21.306703 },
    { lat: 48.818142, lng: 21.307434 },
    { lat: 48.81819, lng: 21.308486 },
    { lat: 48.818228, lng: 21.309235 },
    { lat: 48.818272, lng: 21.309339 },
    { lat: 48.818364, lng: 21.309556 },
    { lat: 48.81849, lng: 21.309859 },
    { lat: 48.818539, lng: 21.309989 },
    { lat: 48.818551, lng: 21.310019 },
    { lat: 48.818569, lng: 21.310069 },
    { lat: 48.818605, lng: 21.310158 },
    { lat: 48.818636, lng: 21.310241 },
    { lat: 48.818671, lng: 21.310327 },
    { lat: 48.818708, lng: 21.310423 },
    { lat: 48.818713, lng: 21.310437 },
    { lat: 48.818737, lng: 21.310511 },
    { lat: 48.818748, lng: 21.310548 },
    { lat: 48.818759, lng: 21.310582 },
    { lat: 48.818788, lng: 21.310703 },
    { lat: 48.81879, lng: 21.310716 },
    { lat: 48.818803, lng: 21.310769 },
    { lat: 48.818855, lng: 21.310936 },
    { lat: 48.81894, lng: 21.311453 },
    { lat: 48.818395, lng: 21.311762 },
    { lat: 48.818027, lng: 21.31197 },
    { lat: 48.817404, lng: 21.312329 },
    { lat: 48.816816, lng: 21.312666 },
    { lat: 48.816768, lng: 21.312694 },
    { lat: 48.816663, lng: 21.312755 },
    { lat: 48.816003, lng: 21.313133 },
    { lat: 48.815845, lng: 21.313224 },
    { lat: 48.815768, lng: 21.313267 },
    { lat: 48.815707, lng: 21.313303 },
    { lat: 48.815692, lng: 21.31331 },
    { lat: 48.815676, lng: 21.313319 },
    { lat: 48.815572, lng: 21.313374 },
    { lat: 48.815505, lng: 21.313409 },
    { lat: 48.815415, lng: 21.313457 },
    { lat: 48.815382, lng: 21.313474 },
    { lat: 48.815212, lng: 21.313564 },
    { lat: 48.815173, lng: 21.313584 },
    { lat: 48.815064, lng: 21.313646 },
    { lat: 48.815092, lng: 21.31348 },
    { lat: 48.815103, lng: 21.313419 },
    { lat: 48.815135, lng: 21.313223 },
    { lat: 48.815275, lng: 21.311901 },
    { lat: 48.815297, lng: 21.311679 },
    { lat: 48.815307, lng: 21.311526 },
    { lat: 48.815314, lng: 21.311416 },
    { lat: 48.815322, lng: 21.311334 },
    { lat: 48.815354, lng: 21.310993 },
    { lat: 48.815427, lng: 21.310427 },
    { lat: 48.815434, lng: 21.310382 },
    { lat: 48.815436, lng: 21.310366 },
    { lat: 48.815505, lng: 21.309884 },
    { lat: 48.815511, lng: 21.309847 },
    { lat: 48.815512, lng: 21.309817 },
    { lat: 48.815537, lng: 21.309707 },
    { lat: 48.815672, lng: 21.309264 },
    { lat: 48.815791, lng: 21.308747 },
    { lat: 48.816154, lng: 21.307616 },
    { lat: 48.816345, lng: 21.306932 },
    { lat: 48.816515, lng: 21.305766 },
    { lat: 48.81652, lng: 21.305522 },
    { lat: 48.816519, lng: 21.3055 },
    { lat: 48.816489, lng: 21.305254 },
    { lat: 48.816157, lng: 21.303388 },
    { lat: 48.816116, lng: 21.303395 },
    { lat: 48.80894, lng: 21.3061046 },
    { lat: 48.8070575, lng: 21.3067393 },
    { lat: 48.8060838, lng: 21.3071334 },
    { lat: 48.8058952, lng: 21.3074447 },
    { lat: 48.8059444, lng: 21.3074341 },
    { lat: 48.8065997, lng: 21.3072942 },
    { lat: 48.8072274, lng: 21.3077342 },
    { lat: 48.8076914, lng: 21.3078961 },
    { lat: 48.8077374, lng: 21.3116658 },
    { lat: 48.8062331, lng: 21.3115627 },
    { lat: 48.8057733, lng: 21.3116504 },
    { lat: 48.8059184, lng: 21.311948 },
    { lat: 48.8107965, lng: 21.318918 },
    { lat: 48.8110768, lng: 21.3201049 },
    { lat: 48.8111188, lng: 21.3200824 },
    { lat: 48.8114321, lng: 21.321287 },
    { lat: 48.8116906, lng: 21.322061 },
    { lat: 48.8119022, lng: 21.3234727 },
    { lat: 48.8121567, lng: 21.3265598 },
    { lat: 48.8121124, lng: 21.3267325 },
    { lat: 48.8122424, lng: 21.3272059 },
    { lat: 48.8122018, lng: 21.3274979 },
    { lat: 48.8123507, lng: 21.3284405 },
    { lat: 48.8125275, lng: 21.3289688 },
    { lat: 48.8126609, lng: 21.3289506 },
    { lat: 48.8127488, lng: 21.3287703 },
    { lat: 48.8128391, lng: 21.3287766 },
    { lat: 48.8129127, lng: 21.3288639 },
    { lat: 48.8129894, lng: 21.3290593 },
    { lat: 48.8130377, lng: 21.3298011 },
    { lat: 48.8131023, lng: 21.3299626 },
    { lat: 48.8131903, lng: 21.3300422 },
    { lat: 48.8133621, lng: 21.3299921 },
    { lat: 48.8134601, lng: 21.3302945 },
    { lat: 48.8134987, lng: 21.3309388 },
    { lat: 48.8136543, lng: 21.3313175 },
    { lat: 48.813922, lng: 21.3318824 },
    { lat: 48.8139522, lng: 21.3319899 },
    { lat: 48.8140102, lng: 21.3327537 },
    { lat: 48.8142344, lng: 21.3335021 },
    { lat: 48.8143593, lng: 21.3340609 },
    { lat: 48.8145405, lng: 21.3346398 },
    { lat: 48.8146912, lng: 21.335292 },
    { lat: 48.814679, lng: 21.3357951 },
    { lat: 48.8147282, lng: 21.3362205 },
    { lat: 48.814805, lng: 21.3366067 },
    { lat: 48.8148831, lng: 21.3372459 },
    { lat: 48.8150065, lng: 21.3383042 },
    { lat: 48.8150327, lng: 21.3386663 },
    { lat: 48.8150974, lng: 21.3395366 },
    { lat: 48.8151879, lng: 21.3403114 },
    { lat: 48.815412, lng: 21.3411341 },
    { lat: 48.8155568, lng: 21.3418368 },
    { lat: 48.8158706, lng: 21.3429126 },
    { lat: 48.8160642, lng: 21.3435022 },
    { lat: 48.8162731, lng: 21.3441765 },
    { lat: 48.8164825, lng: 21.3445125 },
    { lat: 48.816714, lng: 21.3448366 },
    { lat: 48.8169254, lng: 21.3451036 },
    { lat: 48.8168507, lng: 21.345237 },
    { lat: 48.8167228, lng: 21.345846 },
    { lat: 48.8165632, lng: 21.3469775 },
    { lat: 48.815904, lng: 21.3525306 },
    { lat: 48.8153919, lng: 21.3574059 },
    { lat: 48.8148961, lng: 21.3611223 },
    { lat: 48.8144465, lng: 21.3639029 },
    { lat: 48.8135956, lng: 21.3709378 },
  ],
  Olšovany: [
    { lat: 48.7195018, lng: 21.4211799 },
    { lat: 48.7196037, lng: 21.4209731 },
    { lat: 48.7196828, lng: 21.4204464 },
    { lat: 48.7196294, lng: 21.420243 },
    { lat: 48.719439, lng: 21.4201208 },
    { lat: 48.7194196, lng: 21.420302 },
    { lat: 48.7192419, lng: 21.4203756 },
    { lat: 48.71875, lng: 21.4201461 },
    { lat: 48.7184956, lng: 21.4199142 },
    { lat: 48.7180153, lng: 21.4192345 },
    { lat: 48.7180239, lng: 21.419142 },
    { lat: 48.7181983, lng: 21.418987 },
    { lat: 48.7182189, lng: 21.4188139 },
    { lat: 48.7184136, lng: 21.4187334 },
    { lat: 48.7189367, lng: 21.4186942 },
    { lat: 48.7190071, lng: 21.4187861 },
    { lat: 48.7193844, lng: 21.4189153 },
    { lat: 48.719489, lng: 21.4188405 },
    { lat: 48.7198079, lng: 21.4189648 },
    { lat: 48.71996, lng: 21.4190832 },
    { lat: 48.7200246, lng: 21.4192207 },
    { lat: 48.7200202, lng: 21.419335 },
    { lat: 48.7198247, lng: 21.4196155 },
    { lat: 48.719836, lng: 21.4198439 },
    { lat: 48.7200166, lng: 21.4201335 },
    { lat: 48.7202755, lng: 21.4201738 },
    { lat: 48.7206548, lng: 21.4198635 },
    { lat: 48.7206248, lng: 21.4195758 },
    { lat: 48.7207556, lng: 21.4193561 },
    { lat: 48.721095, lng: 21.4194159 },
    { lat: 48.721276, lng: 21.4192989 },
    { lat: 48.7213072, lng: 21.4189818 },
    { lat: 48.7214373, lng: 21.418741 },
    { lat: 48.7218408, lng: 21.4184551 },
    { lat: 48.7221559, lng: 21.4178811 },
    { lat: 48.7222077, lng: 21.417585 },
    { lat: 48.722313, lng: 21.4174831 },
    { lat: 48.7225146, lng: 21.4175704 },
    { lat: 48.722675, lng: 21.4180446 },
    { lat: 48.722666, lng: 21.4181643 },
    { lat: 48.7224366, lng: 21.4183162 },
    { lat: 48.722339, lng: 21.4187084 },
    { lat: 48.7223924, lng: 21.4188809 },
    { lat: 48.7226588, lng: 21.4189841 },
    { lat: 48.7229759, lng: 21.4189018 },
    { lat: 48.7232201, lng: 21.4189766 },
    { lat: 48.7235262, lng: 21.4189388 },
    { lat: 48.7236824, lng: 21.419197 },
    { lat: 48.7237714, lng: 21.4198176 },
    { lat: 48.7269058, lng: 21.4198003 },
    { lat: 48.7270668, lng: 21.419453 },
    { lat: 48.7270755, lng: 21.4194032 },
    { lat: 48.7270748, lng: 21.4193808 },
    { lat: 48.7270555, lng: 21.4189573 },
    { lat: 48.7271074, lng: 21.4185033 },
    { lat: 48.7270985, lng: 21.4174008 },
    { lat: 48.7271291, lng: 21.4172565 },
    { lat: 48.7270974, lng: 21.4171332 },
    { lat: 48.7271465, lng: 21.4166241 },
    { lat: 48.727073, lng: 21.4156992 },
    { lat: 48.7271017, lng: 21.4155989 },
    { lat: 48.7270185, lng: 21.4153389 },
    { lat: 48.7270122, lng: 21.415164 },
    { lat: 48.7269462, lng: 21.4150626 },
    { lat: 48.7270097, lng: 21.4147372 },
    { lat: 48.726972, lng: 21.4145608 },
    { lat: 48.7269979, lng: 21.4144087 },
    { lat: 48.7269155, lng: 21.4143258 },
    { lat: 48.7268446, lng: 21.4141353 },
    { lat: 48.7269845, lng: 21.4137317 },
    { lat: 48.7270652, lng: 21.413717 },
    { lat: 48.7270815, lng: 21.413471 },
    { lat: 48.7271472, lng: 21.4133311 },
    { lat: 48.7271789, lng: 21.4126172 },
    { lat: 48.7272507, lng: 21.4123397 },
    { lat: 48.7272031, lng: 21.4119505 },
    { lat: 48.7273697, lng: 21.4116841 },
    { lat: 48.7274562, lng: 21.4112612 },
    { lat: 48.7275173, lng: 21.4111983 },
    { lat: 48.7275319, lng: 21.4110055 },
    { lat: 48.7276565, lng: 21.4108802 },
    { lat: 48.7277149, lng: 21.4107252 },
    { lat: 48.7277054, lng: 21.4106271 },
    { lat: 48.7278334, lng: 21.4101629 },
    { lat: 48.7279916, lng: 21.4099555 },
    { lat: 48.7280157, lng: 21.4095187 },
    { lat: 48.7281043, lng: 21.4090158 },
    { lat: 48.7282756, lng: 21.4086767 },
    { lat: 48.7283123, lng: 21.4081706 },
    { lat: 48.7285985, lng: 21.407346 },
    { lat: 48.7285704, lng: 21.4070838 },
    { lat: 48.7286906, lng: 21.4063144 },
    { lat: 48.7288376, lng: 21.4059213 },
    { lat: 48.7288703, lng: 21.4056959 },
    { lat: 48.7289576, lng: 21.4055624 },
    { lat: 48.7289336, lng: 21.4054361 },
    { lat: 48.7290624, lng: 21.4051522 },
    { lat: 48.7290256, lng: 21.405088 },
    { lat: 48.7291845, lng: 21.4049436 },
    { lat: 48.7292021, lng: 21.404853 },
    { lat: 48.7293785, lng: 21.4047241 },
    { lat: 48.7293362, lng: 21.4046657 },
    { lat: 48.7293656, lng: 21.4043689 },
    { lat: 48.7293086, lng: 21.4040046 },
    { lat: 48.7294031, lng: 21.403661 },
    { lat: 48.7294613, lng: 21.4036524 },
    { lat: 48.7295471, lng: 21.403426 },
    { lat: 48.7296225, lng: 21.4030922 },
    { lat: 48.7295498, lng: 21.4029132 },
    { lat: 48.729629, lng: 21.402776 },
    { lat: 48.7296744, lng: 21.4022151 },
    { lat: 48.7297667, lng: 21.4020292 },
    { lat: 48.7297177, lng: 21.4017053 },
    { lat: 48.7299136, lng: 21.4014461 },
    { lat: 48.729975, lng: 21.4012352 },
    { lat: 48.7300136, lng: 21.4007942 },
    { lat: 48.7299537, lng: 21.4004757 },
    { lat: 48.7299818, lng: 21.4002478 },
    { lat: 48.7300936, lng: 21.4000737 },
    { lat: 48.7300626, lng: 21.3996741 },
    { lat: 48.7302186, lng: 21.3987475 },
    { lat: 48.7306586, lng: 21.3973061 },
    { lat: 48.7308485, lng: 21.3964267 },
    { lat: 48.7312635, lng: 21.3958885 },
    { lat: 48.731533, lng: 21.3951873 },
    { lat: 48.7321862, lng: 21.3948631 },
    { lat: 48.7321647, lng: 21.3947113 },
    { lat: 48.7325165, lng: 21.3940893 },
    { lat: 48.7318605, lng: 21.392081 },
    { lat: 48.7316893, lng: 21.3909395 },
    { lat: 48.7313783, lng: 21.3894107 },
    { lat: 48.7310519, lng: 21.3880859 },
    { lat: 48.7310362, lng: 21.388047 },
    { lat: 48.7299242, lng: 21.3848327 },
    { lat: 48.7296137, lng: 21.3837036 },
    { lat: 48.7295571, lng: 21.3836885 },
    { lat: 48.7288649, lng: 21.3844054 },
    { lat: 48.7284784, lng: 21.3846477 },
    { lat: 48.727971, lng: 21.3851018 },
    { lat: 48.7275208, lng: 21.385291 },
    { lat: 48.7260038, lng: 21.3861602 },
    { lat: 48.7256195, lng: 21.3862717 },
    { lat: 48.7251615, lng: 21.3864046 },
    { lat: 48.7248617, lng: 21.3863476 },
    { lat: 48.7240987, lng: 21.3863486 },
    { lat: 48.7231594, lng: 21.3864328 },
    { lat: 48.7230488, lng: 21.3865733 },
    { lat: 48.7225134, lng: 21.3863577 },
    { lat: 48.721365, lng: 21.3863699 },
    { lat: 48.7205898, lng: 21.3864565 },
    { lat: 48.7204064, lng: 21.3865295 },
    { lat: 48.7194768, lng: 21.387218 },
    { lat: 48.718572, lng: 21.387888 },
    { lat: 48.7181044, lng: 21.3879426 },
    { lat: 48.7176131, lng: 21.3878755 },
    { lat: 48.7172722, lng: 21.3876877 },
    { lat: 48.7166895, lng: 21.3870531 },
    { lat: 48.7162102, lng: 21.3866456 },
    { lat: 48.7160154, lng: 21.386377 },
    { lat: 48.7154419, lng: 21.3851011 },
    { lat: 48.7150766, lng: 21.3848516 },
    { lat: 48.7141581, lng: 21.3836316 },
    { lat: 48.7137824, lng: 21.3836401 },
    { lat: 48.7136135, lng: 21.3835657 },
    { lat: 48.7123842, lng: 21.3836026 },
    { lat: 48.7119416, lng: 21.3834212 },
    { lat: 48.7115662, lng: 21.3835087 },
    { lat: 48.7112872, lng: 21.3834062 },
    { lat: 48.7109234, lng: 21.3833897 },
    { lat: 48.7105479, lng: 21.3831729 },
    { lat: 48.7102937, lng: 21.3828802 },
    { lat: 48.7098452, lng: 21.3827084 },
    { lat: 48.7093249, lng: 21.3823868 },
    { lat: 48.7090433, lng: 21.3823051 },
    { lat: 48.7084331, lng: 21.3818831 },
    { lat: 48.7081591, lng: 21.3817889 },
    { lat: 48.7073414, lng: 21.3808558 },
    { lat: 48.7068434, lng: 21.3799649 },
    { lat: 48.7064253, lng: 21.3797981 },
    { lat: 48.7054457, lng: 21.3798951 },
    { lat: 48.7052855, lng: 21.379911 },
    { lat: 48.705092, lng: 21.379893 },
    { lat: 48.7046749, lng: 21.3798543 },
    { lat: 48.7043091, lng: 21.3796619 },
    { lat: 48.7041273, lng: 21.3797573 },
    { lat: 48.7036465, lng: 21.3802004 },
    { lat: 48.7032986, lng: 21.3810995 },
    { lat: 48.7029793, lng: 21.3817468 },
    { lat: 48.7028472, lng: 21.3821484 },
    { lat: 48.702562, lng: 21.3824663 },
    { lat: 48.7025164, lng: 21.382567 },
    { lat: 48.7024044, lng: 21.383145 },
    { lat: 48.7022653, lng: 21.3833519 },
    { lat: 48.701761, lng: 21.3837976 },
    { lat: 48.7012613, lng: 21.3839862 },
    { lat: 48.7003484, lng: 21.3837146 },
    { lat: 48.7001268, lng: 21.3836046 },
    { lat: 48.6999249, lng: 21.3833996 },
    { lat: 48.6998453, lng: 21.3834827 },
    { lat: 48.6987949, lng: 21.3844818 },
    { lat: 48.6976442, lng: 21.3849173 },
    { lat: 48.6972474, lng: 21.3851979 },
    { lat: 48.6963169, lng: 21.3863675 },
    { lat: 48.6958629, lng: 21.3870317 },
    { lat: 48.695518, lng: 21.3877006 },
    { lat: 48.6954362, lng: 21.3883106 },
    { lat: 48.6952432, lng: 21.3886721 },
    { lat: 48.6950906, lng: 21.3891765 },
    { lat: 48.6950788, lng: 21.3905219 },
    { lat: 48.6949947, lng: 21.3913284 },
    { lat: 48.6950351, lng: 21.3915116 },
    { lat: 48.6952708, lng: 21.3920224 },
    { lat: 48.6956023, lng: 21.3932113 },
    { lat: 48.6954377, lng: 21.3945678 },
    { lat: 48.6952463, lng: 21.3951351 },
    { lat: 48.6944186, lng: 21.3971559 },
    { lat: 48.6935767, lng: 21.3997675 },
    { lat: 48.693441, lng: 21.4003131 },
    { lat: 48.6933548, lng: 21.4009362 },
    { lat: 48.6922096, lng: 21.4040957 },
    { lat: 48.6918432, lng: 21.4046803 },
    { lat: 48.6917334, lng: 21.4049606 },
    { lat: 48.6911093, lng: 21.406915 },
    { lat: 48.6909804, lng: 21.4074647 },
    { lat: 48.6887266, lng: 21.4076957 },
    { lat: 48.6886455, lng: 21.4091788 },
    { lat: 48.6892911, lng: 21.4091708 },
    { lat: 48.689219, lng: 21.4106455 },
    { lat: 48.6886505, lng: 21.4106002 },
    { lat: 48.6886444, lng: 21.4107032 },
    { lat: 48.6885382, lng: 21.4124402 },
    { lat: 48.6901952, lng: 21.4124965 },
    { lat: 48.6904849, lng: 21.4126294 },
    { lat: 48.6908321, lng: 21.4132531 },
    { lat: 48.6909194, lng: 21.413087 },
    { lat: 48.6911294, lng: 21.4131893 },
    { lat: 48.6914682, lng: 21.4135308 },
    { lat: 48.691935, lng: 21.4141312 },
    { lat: 48.6918625, lng: 21.4144975 },
    { lat: 48.6919434, lng: 21.4150303 },
    { lat: 48.6919251, lng: 21.4154432 },
    { lat: 48.6921859, lng: 21.4157373 },
    { lat: 48.6926785, lng: 21.4159517 },
    { lat: 48.6927724, lng: 21.4159304 },
    { lat: 48.6928596, lng: 21.4158347 },
    { lat: 48.6929274, lng: 21.4151207 },
    { lat: 48.6930805, lng: 21.4150879 },
    { lat: 48.693339, lng: 21.4151873 },
    { lat: 48.693404, lng: 21.416286 },
    { lat: 48.6941027, lng: 21.4168131 },
    { lat: 48.6943602, lng: 21.4168848 },
    { lat: 48.6944647, lng: 21.4169917 },
    { lat: 48.6945967, lng: 21.4173893 },
    { lat: 48.6945751, lng: 21.4175371 },
    { lat: 48.6946333, lng: 21.4177893 },
    { lat: 48.6947399, lng: 21.4177746 },
    { lat: 48.694744, lng: 21.4176896 },
    { lat: 48.6948821, lng: 21.4176393 },
    { lat: 48.6949011, lng: 21.4174845 },
    { lat: 48.695039, lng: 21.417467 },
    { lat: 48.6954857, lng: 21.4180378 },
    { lat: 48.6956395, lng: 21.4181026 },
    { lat: 48.6956196, lng: 21.4186415 },
    { lat: 48.6954164, lng: 21.4188539 },
    { lat: 48.6954146, lng: 21.4193278 },
    { lat: 48.6955534, lng: 21.4194937 },
    { lat: 48.6958608, lng: 21.4195774 },
    { lat: 48.6959241, lng: 21.4194701 },
    { lat: 48.6959442, lng: 21.4191578 },
    { lat: 48.6961571, lng: 21.4187496 },
    { lat: 48.6962851, lng: 21.4187737 },
    { lat: 48.6963186, lng: 21.4185007 },
    { lat: 48.6968765, lng: 21.4179574 },
    { lat: 48.6968142, lng: 21.4175665 },
    { lat: 48.6968723, lng: 21.4174739 },
    { lat: 48.6970682, lng: 21.4177026 },
    { lat: 48.6972051, lng: 21.4176916 },
    { lat: 48.6977019, lng: 21.417443 },
    { lat: 48.6981809, lng: 21.4174677 },
    { lat: 48.6982496, lng: 21.4165921 },
    { lat: 48.6982963, lng: 21.4163044 },
    { lat: 48.6985021, lng: 21.4156579 },
    { lat: 48.6994117, lng: 21.4151338 },
    { lat: 48.6996539, lng: 21.4148796 },
    { lat: 48.699882, lng: 21.414908 },
    { lat: 48.7002403, lng: 21.4153031 },
    { lat: 48.7004388, lng: 21.4156192 },
    { lat: 48.7004353, lng: 21.4158852 },
    { lat: 48.700194, lng: 21.4160963 },
    { lat: 48.7001272, lng: 21.4165408 },
    { lat: 48.7001439, lng: 21.4168036 },
    { lat: 48.7003871, lng: 21.4169205 },
    { lat: 48.7006478, lng: 21.4172901 },
    { lat: 48.7008912, lng: 21.4174942 },
    { lat: 48.7011202, lng: 21.417549 },
    { lat: 48.7017936, lng: 21.4179892 },
    { lat: 48.7020637, lng: 21.4182549 },
    { lat: 48.7022081, lng: 21.4184896 },
    { lat: 48.7021903, lng: 21.4187266 },
    { lat: 48.7022732, lng: 21.4189444 },
    { lat: 48.7025678, lng: 21.4190535 },
    { lat: 48.7028795, lng: 21.4187863 },
    { lat: 48.7032112, lng: 21.4183208 },
    { lat: 48.7034637, lng: 21.418105 },
    { lat: 48.7036831, lng: 21.4177956 },
    { lat: 48.7040017, lng: 21.4175725 },
    { lat: 48.7041692, lng: 21.4177611 },
    { lat: 48.7043212, lng: 21.4175724 },
    { lat: 48.7044293, lng: 21.4171126 },
    { lat: 48.704772, lng: 21.4164123 },
    { lat: 48.7048929, lng: 21.4155174 },
    { lat: 48.7052062, lng: 21.4146933 },
    { lat: 48.7053472, lng: 21.4139011 },
    { lat: 48.7054629, lng: 21.4136343 },
    { lat: 48.7058092, lng: 21.4135457 },
    { lat: 48.7060978, lng: 21.413678 },
    { lat: 48.7063682, lng: 21.4139159 },
    { lat: 48.7067645, lng: 21.4144172 },
    { lat: 48.7070843, lng: 21.4144662 },
    { lat: 48.7072865, lng: 21.4146446 },
    { lat: 48.7075168, lng: 21.4151211 },
    { lat: 48.7075689, lng: 21.4151393 },
    { lat: 48.7076945, lng: 21.4151201 },
    { lat: 48.7078158, lng: 21.414962 },
    { lat: 48.7078303, lng: 21.4146959 },
    { lat: 48.707951, lng: 21.41436 },
    { lat: 48.7082933, lng: 21.4141003 },
    { lat: 48.7086595, lng: 21.4140361 },
    { lat: 48.7088098, lng: 21.4158127 },
    { lat: 48.7095939, lng: 21.4152326 },
    { lat: 48.7141815, lng: 21.4179737 },
    { lat: 48.7144155, lng: 21.4197994 },
    { lat: 48.7194061, lng: 21.4211001 },
    { lat: 48.7195018, lng: 21.4211799 },
  ],
  NižnýLánec: [
    { lat: 48.5464284, lng: 21.0959126 },
    { lat: 48.5463882, lng: 21.0958903 },
    { lat: 48.5457847, lng: 21.0954331 },
    { lat: 48.5455911, lng: 21.0951557 },
    { lat: 48.5453377, lng: 21.0964002 },
    { lat: 48.5453189, lng: 21.0976981 },
    { lat: 48.5451862, lng: 21.100069 },
    { lat: 48.5451526, lng: 21.1000704 },
    { lat: 48.5451662, lng: 21.1009432 },
    { lat: 48.5453224, lng: 21.1022201 },
    { lat: 48.5445847, lng: 21.1031772 },
    { lat: 48.5443896, lng: 21.1033446 },
    { lat: 48.5434448, lng: 21.1035716 },
    { lat: 48.5430006, lng: 21.1035175 },
    { lat: 48.5427256, lng: 21.1036798 },
    { lat: 48.5421869, lng: 21.1038692 },
    { lat: 48.5403823, lng: 21.1042398 },
    { lat: 48.5399626, lng: 21.1045934 },
    { lat: 48.5397532, lng: 21.1046972 },
    { lat: 48.5390671, lng: 21.1047411 },
    { lat: 48.5378168, lng: 21.1049596 },
    { lat: 48.5377248, lng: 21.1050029 },
    { lat: 48.5376123, lng: 21.1053166 },
    { lat: 48.537488, lng: 21.1054039 },
    { lat: 48.5373238, lng: 21.1053736 },
    { lat: 48.5371167, lng: 21.1052322 },
    { lat: 48.5370836, lng: 21.1052886 },
    { lat: 48.5371027, lng: 21.1055294 },
    { lat: 48.5369639, lng: 21.105769 },
    { lat: 48.5368953, lng: 21.1061178 },
    { lat: 48.5367707, lng: 21.1060946 },
    { lat: 48.5362375, lng: 21.1064171 },
    { lat: 48.5360276, lng: 21.1068867 },
    { lat: 48.5357475, lng: 21.1069609 },
    { lat: 48.5353159, lng: 21.1071879 },
    { lat: 48.5343255, lng: 21.1079245 },
    { lat: 48.533455, lng: 21.1080124 },
    { lat: 48.533269, lng: 21.1081393 },
    { lat: 48.5330109, lng: 21.1080648 },
    { lat: 48.5329504, lng: 21.1078935 },
    { lat: 48.532738, lng: 21.1076647 },
    { lat: 48.5326589, lng: 21.1074134 },
    { lat: 48.5325342, lng: 21.1072109 },
    { lat: 48.5325595, lng: 21.1069789 },
    { lat: 48.5321765, lng: 21.1065208 },
    { lat: 48.5318344, lng: 21.1064514 },
    { lat: 48.5315576, lng: 21.1063137 },
    { lat: 48.5311215, lng: 21.1053215 },
    { lat: 48.5309084, lng: 21.1050718 },
    { lat: 48.5308533, lng: 21.1050666 },
    { lat: 48.5307193, lng: 21.1053133 },
    { lat: 48.5306225, lng: 21.105347 },
    { lat: 48.5305687, lng: 21.1051042 },
    { lat: 48.5304013, lng: 21.1050124 },
    { lat: 48.5302939, lng: 21.1052433 },
    { lat: 48.530058, lng: 21.1052194 },
    { lat: 48.5298265, lng: 21.1054064 },
    { lat: 48.5297902, lng: 21.1052227 },
    { lat: 48.5297107, lng: 21.1051351 },
    { lat: 48.5290467, lng: 21.1051326 },
    { lat: 48.5289802, lng: 21.1050988 },
    { lat: 48.528652, lng: 21.1044901 },
    { lat: 48.5284999, lng: 21.1043066 },
    { lat: 48.527991, lng: 21.1043034 },
    { lat: 48.5279081, lng: 21.1040006 },
    { lat: 48.5276786, lng: 21.1035558 },
    { lat: 48.5275955, lng: 21.1034855 },
    { lat: 48.5269818, lng: 21.1034421 },
    { lat: 48.5268343, lng: 21.1036166 },
    { lat: 48.5265326, lng: 21.1035777 },
    { lat: 48.5264979, lng: 21.1035199 },
    { lat: 48.5248202, lng: 21.103846 },
    { lat: 48.5235523, lng: 21.1038626 },
    { lat: 48.5232229, lng: 21.1037032 },
    { lat: 48.522806, lng: 21.1043497 },
    { lat: 48.5228108, lng: 21.1044232 },
    { lat: 48.5224729, lng: 21.1048343 },
    { lat: 48.5223467, lng: 21.1051837 },
    { lat: 48.5217029, lng: 21.1051812 },
    { lat: 48.5214816, lng: 21.1050905 },
    { lat: 48.5212619, lng: 21.1049131 },
    { lat: 48.5211902, lng: 21.1049187 },
    { lat: 48.5210234, lng: 21.1051585 },
    { lat: 48.5207334, lng: 21.105134 },
    { lat: 48.5206912, lng: 21.1050455 },
    { lat: 48.5205001, lng: 21.104987 },
    { lat: 48.5203161, lng: 21.1052484 },
    { lat: 48.5201624, lng: 21.1053303 },
    { lat: 48.520064, lng: 21.1056645 },
    { lat: 48.5200812, lng: 21.1063729 },
    { lat: 48.5197718, lng: 21.1067592 },
    { lat: 48.5197335, lng: 21.1070357 },
    { lat: 48.5197851, lng: 21.1070976 },
    { lat: 48.5197383, lng: 21.1073201 },
    { lat: 48.519522, lng: 21.1073199 },
    { lat: 48.5189514, lng: 21.1078808 },
    { lat: 48.518651, lng: 21.1083363 },
    { lat: 48.5178534, lng: 21.1091132 },
    { lat: 48.5174631, lng: 21.1085304 },
    { lat: 48.5170621, lng: 21.1091108 },
    { lat: 48.5166125, lng: 21.1094469 },
    { lat: 48.5159371, lng: 21.1101424 },
    { lat: 48.5155057, lng: 21.1104779 },
    { lat: 48.5152129, lng: 21.1108905 },
    { lat: 48.5150022, lng: 21.1114065 },
    { lat: 48.5146043, lng: 21.111874 },
    { lat: 48.5145783, lng: 21.111976 },
    { lat: 48.5139568, lng: 21.1118656 },
    { lat: 48.5137916, lng: 21.1119132 },
    { lat: 48.5136066, lng: 21.1121745 },
    { lat: 48.5135536, lng: 21.1121756 },
    { lat: 48.5133414, lng: 21.1124708 },
    { lat: 48.5131569, lng: 21.1131332 },
    { lat: 48.5131344, lng: 21.1135555 },
    { lat: 48.5140099, lng: 21.1137595 },
    { lat: 48.5139751, lng: 21.1139378 },
    { lat: 48.5140129, lng: 21.1140561 },
    { lat: 48.5139939, lng: 21.1141707 },
    { lat: 48.5140958, lng: 21.1143223 },
    { lat: 48.5141483, lng: 21.1145893 },
    { lat: 48.5140274, lng: 21.114682 },
    { lat: 48.5140235, lng: 21.1148107 },
    { lat: 48.5140405, lng: 21.1148113 },
    { lat: 48.5145297, lng: 21.1149339 },
    { lat: 48.5155915, lng: 21.1153664 },
    { lat: 48.5176615, lng: 21.1159706 },
    { lat: 48.5191385, lng: 21.1161833 },
    { lat: 48.5198982, lng: 21.1165655 },
    { lat: 48.5229264, lng: 21.1176637 },
    { lat: 48.5253535, lng: 21.1180275 },
    { lat: 48.5269515, lng: 21.1178093 },
    { lat: 48.528652, lng: 21.1172828 },
    { lat: 48.5352943, lng: 21.1179978 },
    { lat: 48.5431212, lng: 21.1194641 },
    { lat: 48.5473009, lng: 21.1202519 },
    { lat: 48.548433, lng: 21.1203177 },
    { lat: 48.54966, lng: 21.1205333 },
    { lat: 48.55032, lng: 21.120543 },
    { lat: 48.5504221, lng: 21.1204975 },
    { lat: 48.5504509, lng: 21.1203437 },
    { lat: 48.5509153, lng: 21.1168728 },
    { lat: 48.5512593, lng: 21.1129469 },
    { lat: 48.5512906, lng: 21.1111623 },
    { lat: 48.551254, lng: 21.108491 },
    { lat: 48.5513604, lng: 21.1050255 },
    { lat: 48.5512741, lng: 21.1030204 },
    { lat: 48.5508405, lng: 21.1019658 },
    { lat: 48.5504862, lng: 21.1012788 },
    { lat: 48.5499837, lng: 21.1003921 },
    { lat: 48.5496507, lng: 21.0999067 },
    { lat: 48.5485465, lng: 21.0987289 },
    { lat: 48.5471164, lng: 21.0980765 },
    { lat: 48.5465168, lng: 21.0964866 },
    { lat: 48.5464681, lng: 21.0960976 },
    { lat: 48.5464284, lng: 21.0959126 },
  ],
  Rankovce: [
    { lat: 48.798025, lng: 21.4730959 },
    { lat: 48.7982645, lng: 21.4735197 },
    { lat: 48.7987863, lng: 21.4735921 },
    { lat: 48.7987739, lng: 21.4742069 },
    { lat: 48.7989546, lng: 21.4741517 },
    { lat: 48.7991662, lng: 21.4742782 },
    { lat: 48.7992596, lng: 21.474271 },
    { lat: 48.7993758, lng: 21.4741305 },
    { lat: 48.7995906, lng: 21.4742044 },
    { lat: 48.7998161, lng: 21.4746049 },
    { lat: 48.7999549, lng: 21.4746596 },
    { lat: 48.8000332, lng: 21.4746026 },
    { lat: 48.8002915, lng: 21.474901 },
    { lat: 48.8003244, lng: 21.4747896 },
    { lat: 48.8005149, lng: 21.4748059 },
    { lat: 48.8010225, lng: 21.4741443 },
    { lat: 48.8015537, lng: 21.4736458 },
    { lat: 48.802124, lng: 21.4729365 },
    { lat: 48.8025515, lng: 21.4732069 },
    { lat: 48.8026581, lng: 21.473435 },
    { lat: 48.8019532, lng: 21.4744252 },
    { lat: 48.8013268, lng: 21.4755272 },
    { lat: 48.8019559, lng: 21.4764217 },
    { lat: 48.8029338, lng: 21.4755716 },
    { lat: 48.8032864, lng: 21.4768278 },
    { lat: 48.80347, lng: 21.4770687 },
    { lat: 48.8035365, lng: 21.4773074 },
    { lat: 48.8031441, lng: 21.4775897 },
    { lat: 48.8030019, lng: 21.477461 },
    { lat: 48.8027273, lng: 21.4777135 },
    { lat: 48.8014369, lng: 21.4789004 },
    { lat: 48.8014753, lng: 21.479062 },
    { lat: 48.8014107, lng: 21.4798591 },
    { lat: 48.8014647, lng: 21.479984 },
    { lat: 48.8015147, lng: 21.4808569 },
    { lat: 48.8015764, lng: 21.4809581 },
    { lat: 48.8015656, lng: 21.4814818 },
    { lat: 48.8016442, lng: 21.4817571 },
    { lat: 48.8019028, lng: 21.4820951 },
    { lat: 48.8019264, lng: 21.4823673 },
    { lat: 48.8020093, lng: 21.482435 },
    { lat: 48.8020971, lng: 21.4823567 },
    { lat: 48.8021676, lng: 21.4824552 },
    { lat: 48.802254, lng: 21.4828428 },
    { lat: 48.8023707, lng: 21.4830232 },
    { lat: 48.8023953, lng: 21.4831726 },
    { lat: 48.802888, lng: 21.4834055 },
    { lat: 48.8032174, lng: 21.4842864 },
    { lat: 48.8032217, lng: 21.4845192 },
    { lat: 48.8033833, lng: 21.4845767 },
    { lat: 48.8035088, lng: 21.484482 },
    { lat: 48.8035983, lng: 21.4848555 },
    { lat: 48.8039569, lng: 21.4850399 },
    { lat: 48.8041807, lng: 21.4852321 },
    { lat: 48.8043775, lng: 21.4855789 },
    { lat: 48.8044542, lng: 21.4858908 },
    { lat: 48.8045897, lng: 21.4857919 },
    { lat: 48.8048466, lng: 21.4858056 },
    { lat: 48.8049931, lng: 21.4861243 },
    { lat: 48.8050995, lng: 21.4860705 },
    { lat: 48.805263, lng: 21.4861235 },
    { lat: 48.8054154, lng: 21.4860207 },
    { lat: 48.8054696, lng: 21.4862227 },
    { lat: 48.805527, lng: 21.4862582 },
    { lat: 48.8057876, lng: 21.4862127 },
    { lat: 48.8059924, lng: 21.4866022 },
    { lat: 48.8059816, lng: 21.4868488 },
    { lat: 48.8061821, lng: 21.4869734 },
    { lat: 48.8062206, lng: 21.4868277 },
    { lat: 48.8062945, lng: 21.4868164 },
    { lat: 48.8066913, lng: 21.4869954 },
    { lat: 48.8066911, lng: 21.4871428 },
    { lat: 48.8067795, lng: 21.4873633 },
    { lat: 48.8072489, lng: 21.4875664 },
    { lat: 48.8075334, lng: 21.4881006 },
    { lat: 48.8077551, lng: 21.4881055 },
    { lat: 48.8083642, lng: 21.488549 },
    { lat: 48.8091624, lng: 21.4888685 },
    { lat: 48.8095517, lng: 21.4888703 },
    { lat: 48.8095938, lng: 21.488857 },
    { lat: 48.8096752, lng: 21.4888286 },
    { lat: 48.8098615, lng: 21.4888041 },
    { lat: 48.8100124, lng: 21.4886581 },
    { lat: 48.8105447, lng: 21.48889 },
    { lat: 48.8107292, lng: 21.4888938 },
    { lat: 48.8107955, lng: 21.4890012 },
    { lat: 48.8110595, lng: 21.4890309 },
    { lat: 48.8111222, lng: 21.4889814 },
    { lat: 48.8112078, lng: 21.489055 },
    { lat: 48.8112162, lng: 21.4891926 },
    { lat: 48.8114094, lng: 21.4895714 },
    { lat: 48.8114739, lng: 21.490485 },
    { lat: 48.8112894, lng: 21.491193 },
    { lat: 48.8113237, lng: 21.4920325 },
    { lat: 48.8114847, lng: 21.492889 },
    { lat: 48.8116078, lng: 21.4932179 },
    { lat: 48.8115354, lng: 21.494027 },
    { lat: 48.8119164, lng: 21.4953392 },
    { lat: 48.8122735, lng: 21.4973937 },
    { lat: 48.8125834, lng: 21.499521 },
    { lat: 48.8128723, lng: 21.5006466 },
    { lat: 48.8127751, lng: 21.5014891 },
    { lat: 48.8127169, lng: 21.5031894 },
    { lat: 48.8128903, lng: 21.5043679 },
    { lat: 48.8130499, lng: 21.5047561 },
    { lat: 48.813326, lng: 21.5049693 },
    { lat: 48.8135057, lng: 21.5062524 },
    { lat: 48.8135834, lng: 21.5075061 },
    { lat: 48.8134474, lng: 21.5089637 },
    { lat: 48.8131654, lng: 21.5099605 },
    { lat: 48.8132493, lng: 21.5108464 },
    { lat: 48.8138482, lng: 21.5123861 },
    { lat: 48.8141572, lng: 21.5128439 },
    { lat: 48.8144649, lng: 21.5136948 },
    { lat: 48.8142855, lng: 21.5142235 },
    { lat: 48.8147581, lng: 21.5158001 },
    { lat: 48.8154194, lng: 21.5170531 },
    { lat: 48.8153382, lng: 21.5171245 },
    { lat: 48.8153931, lng: 21.5174672 },
    { lat: 48.8155106, lng: 21.5176759 },
    { lat: 48.8155988, lng: 21.5183641 },
    { lat: 48.8156702, lng: 21.5185008 },
    { lat: 48.8156265, lng: 21.5185901 },
    { lat: 48.8156266, lng: 21.5191042 },
    { lat: 48.8154804, lng: 21.519856 },
    { lat: 48.8157111, lng: 21.5208848 },
    { lat: 48.8158245, lng: 21.5211887 },
    { lat: 48.8162987, lng: 21.5216779 },
    { lat: 48.816723, lng: 21.5223506 },
    { lat: 48.8168037, lng: 21.522617 },
    { lat: 48.8169581, lng: 21.5228647 },
    { lat: 48.8169689, lng: 21.5230875 },
    { lat: 48.8171673, lng: 21.5235265 },
    { lat: 48.8173711, lng: 21.5236567 },
    { lat: 48.8183838, lng: 21.5259469 },
    { lat: 48.8185804, lng: 21.5261279 },
    { lat: 48.8187105, lng: 21.5261505 },
    { lat: 48.8192181, lng: 21.5264725 },
    { lat: 48.819658, lng: 21.5265523 },
    { lat: 48.8200106, lng: 21.5281438 },
    { lat: 48.819813, lng: 21.529208 },
    { lat: 48.819809, lng: 21.529234 },
    { lat: 48.819981, lng: 21.529347 },
    { lat: 48.820093, lng: 21.52941 },
    { lat: 48.820528, lng: 21.529537 },
    { lat: 48.820726, lng: 21.52971 },
    { lat: 48.820995, lng: 21.530031 },
    { lat: 48.821165, lng: 21.530138 },
    { lat: 48.821491, lng: 21.53009 },
    { lat: 48.82177, lng: 21.529913 },
    { lat: 48.822103, lng: 21.529904 },
    { lat: 48.822214, lng: 21.529932 },
    { lat: 48.822305, lng: 21.529943 },
    { lat: 48.822385, lng: 21.529898 },
    { lat: 48.822377, lng: 21.529842 },
    { lat: 48.822415, lng: 21.529822 },
    { lat: 48.822478, lng: 21.529791 },
    { lat: 48.822985, lng: 21.529533 },
    { lat: 48.823434, lng: 21.529376 },
    { lat: 48.823907, lng: 21.528945 },
    { lat: 48.824543, lng: 21.528822 },
    { lat: 48.824853, lng: 21.528582 },
    { lat: 48.825099, lng: 21.528234 },
    { lat: 48.825536, lng: 21.527701 },
    { lat: 48.82614, lng: 21.527258 },
    { lat: 48.826592, lng: 21.526775 },
    { lat: 48.827006, lng: 21.526971 },
    { lat: 48.827592, lng: 21.526663 },
    { lat: 48.827883, lng: 21.526249 },
    { lat: 48.828036, lng: 21.526003 },
    { lat: 48.82831, lng: 21.525405 },
    { lat: 48.829088, lng: 21.52466 },
    { lat: 48.829733, lng: 21.524661 },
    { lat: 48.830007, lng: 21.524402 },
    { lat: 48.830351, lng: 21.523802 },
    { lat: 48.83054, lng: 21.523575 },
    { lat: 48.830953, lng: 21.522578 },
    { lat: 48.831386, lng: 21.521323 },
    { lat: 48.83172, lng: 21.521158 },
    { lat: 48.831903, lng: 21.521028 },
    { lat: 48.832124, lng: 21.520954 },
    { lat: 48.833794, lng: 21.520415 },
    { lat: 48.834613, lng: 21.519541 },
    { lat: 48.83482, lng: 21.519154 },
    { lat: 48.835223, lng: 21.518364 },
    { lat: 48.835644, lng: 21.517561 },
    { lat: 48.83564, lng: 21.517172 },
    { lat: 48.835665, lng: 21.516069 },
    { lat: 48.836235, lng: 21.515221 },
    { lat: 48.836323, lng: 21.514587 },
    { lat: 48.836127, lng: 21.51359 },
    { lat: 48.835733, lng: 21.512892 },
    { lat: 48.835531, lng: 21.511979 },
    { lat: 48.835505, lng: 21.510948 },
    { lat: 48.835644, lng: 21.509672 },
    { lat: 48.835854, lng: 21.508101 },
    { lat: 48.836026, lng: 21.507713 },
    { lat: 48.836351, lng: 21.507138 },
    { lat: 48.836741, lng: 21.506352 },
    { lat: 48.837175, lng: 21.505966 },
    { lat: 48.837543, lng: 21.505373 },
    { lat: 48.837831, lng: 21.505035 },
    { lat: 48.838401, lng: 21.504587 },
    { lat: 48.838545, lng: 21.50407 },
    { lat: 48.838768, lng: 21.503743 },
    { lat: 48.838993, lng: 21.503272 },
    { lat: 48.839135, lng: 21.502797 },
    { lat: 48.839587, lng: 21.501834 },
    { lat: 48.840277, lng: 21.501329 },
    { lat: 48.840845, lng: 21.500723 },
    { lat: 48.841193, lng: 21.500435 },
    { lat: 48.841965, lng: 21.500153 },
    { lat: 48.8416805, lng: 21.4999078 },
    { lat: 48.8412555, lng: 21.4993679 },
    { lat: 48.8403908, lng: 21.4981765 },
    { lat: 48.8395896, lng: 21.4973556 },
    { lat: 48.8385535, lng: 21.4958334 },
    { lat: 48.8380189, lng: 21.4955112 },
    { lat: 48.8374036, lng: 21.4944656 },
    { lat: 48.8364606, lng: 21.4934778 },
    { lat: 48.8360041, lng: 21.4932975 },
    { lat: 48.8356853, lng: 21.492811 },
    { lat: 48.8352336, lng: 21.4924462 },
    { lat: 48.8344165, lng: 21.4922052 },
    { lat: 48.8338488, lng: 21.4917894 },
    { lat: 48.8334508, lng: 21.4914107 },
    { lat: 48.8322055, lng: 21.4910316 },
    { lat: 48.8319412, lng: 21.4908751 },
    { lat: 48.8312789, lng: 21.4901135 },
    { lat: 48.8305109, lng: 21.4895072 },
    { lat: 48.8302587, lng: 21.4894203 },
    { lat: 48.8298563, lng: 21.4889623 },
    { lat: 48.829392, lng: 21.4882416 },
    { lat: 48.8291401, lng: 21.488084 },
    { lat: 48.8288291, lng: 21.4876828 },
    { lat: 48.8287541, lng: 21.4873795 },
    { lat: 48.8286309, lng: 21.4872866 },
    { lat: 48.8285275, lng: 21.4868967 },
    { lat: 48.8285994, lng: 21.4866605 },
    { lat: 48.8285367, lng: 21.4863561 },
    { lat: 48.8286253, lng: 21.4861181 },
    { lat: 48.8287397, lng: 21.485482 },
    { lat: 48.8286339, lng: 21.4845041 },
    { lat: 48.8286806, lng: 21.4842092 },
    { lat: 48.8286638, lng: 21.483204 },
    { lat: 48.828826, lng: 21.4828201 },
    { lat: 48.8288231, lng: 21.4824403 },
    { lat: 48.8287326, lng: 21.4821556 },
    { lat: 48.8287119, lng: 21.4817905 },
    { lat: 48.8287954, lng: 21.4816386 },
    { lat: 48.828825, lng: 21.4812652 },
    { lat: 48.8287457, lng: 21.4811237 },
    { lat: 48.8285388, lng: 21.4803953 },
    { lat: 48.8279086, lng: 21.4808938 },
    { lat: 48.8271492, lng: 21.4810499 },
    { lat: 48.8265268, lng: 21.480774 },
    { lat: 48.8252874, lng: 21.4806015 },
    { lat: 48.8245293, lng: 21.4808028 },
    { lat: 48.8238377, lng: 21.4782055 },
    { lat: 48.8235461, lng: 21.4774268 },
    { lat: 48.8234094, lng: 21.4768252 },
    { lat: 48.8233474, lng: 21.4760012 },
    { lat: 48.8234307, lng: 21.475343 },
    { lat: 48.8229564, lng: 21.4749727 },
    { lat: 48.8228961, lng: 21.4747543 },
    { lat: 48.8227996, lng: 21.4734427 },
    { lat: 48.8226873, lng: 21.4727538 },
    { lat: 48.8223754, lng: 21.472741 },
    { lat: 48.82216, lng: 21.4721815 },
    { lat: 48.8223227, lng: 21.4713926 },
    { lat: 48.8214961, lng: 21.4721438 },
    { lat: 48.8209982, lng: 21.471502 },
    { lat: 48.8208851, lng: 21.4711736 },
    { lat: 48.8205639, lng: 21.4705686 },
    { lat: 48.8201574, lng: 21.470014 },
    { lat: 48.8195086, lng: 21.4688717 },
    { lat: 48.8190381, lng: 21.4678525 },
    { lat: 48.8188332, lng: 21.465868 },
    { lat: 48.8188142, lng: 21.4651409 },
    { lat: 48.8185454, lng: 21.4641751 },
    { lat: 48.8184394, lng: 21.4638941 },
    { lat: 48.8177948, lng: 21.4627434 },
    { lat: 48.8175672, lng: 21.4621869 },
    { lat: 48.8175843, lng: 21.4613003 },
    { lat: 48.8175126, lng: 21.4610805 },
    { lat: 48.8172699, lng: 21.4604354 },
    { lat: 48.8157567, lng: 21.4570352 },
    { lat: 48.8151194, lng: 21.4562487 },
    { lat: 48.8142381, lng: 21.4555474 },
    { lat: 48.8138596, lng: 21.4551093 },
    { lat: 48.8133863, lng: 21.4540773 },
    { lat: 48.8127965, lng: 21.4532238 },
    { lat: 48.8122051, lng: 21.4521141 },
    { lat: 48.8116431, lng: 21.4513485 },
    { lat: 48.811078, lng: 21.4506979 },
    { lat: 48.8105437, lng: 21.4505544 },
    { lat: 48.810255, lng: 21.4503282 },
    { lat: 48.8100074, lng: 21.4500372 },
    { lat: 48.8078812, lng: 21.4483074 },
    { lat: 48.806768, lng: 21.4477565 },
    { lat: 48.8062604, lng: 21.4456771 },
    { lat: 48.8060734, lng: 21.4455117 },
    { lat: 48.8056645, lng: 21.445198 },
    { lat: 48.80507, lng: 21.4447499 },
    { lat: 48.8031419, lng: 21.4446124 },
    { lat: 48.8028704, lng: 21.4444139 },
    { lat: 48.8023127, lng: 21.4434607 },
    { lat: 48.8013037, lng: 21.4433937 },
    { lat: 48.7991921, lng: 21.4381853 },
    { lat: 48.7991291, lng: 21.4379097 },
    { lat: 48.7991247, lng: 21.4376052 },
    { lat: 48.79881, lng: 21.4363114 },
    { lat: 48.7985301, lng: 21.4358616 },
    { lat: 48.7984916, lng: 21.4353199 },
    { lat: 48.7982699, lng: 21.4344335 },
    { lat: 48.7980646, lng: 21.4341043 },
    { lat: 48.7978859, lng: 21.4335435 },
    { lat: 48.7977113, lng: 21.4333803 },
    { lat: 48.7977004, lng: 21.4331291 },
    { lat: 48.7975725, lng: 21.4331006 },
    { lat: 48.7974338, lng: 21.4328176 },
    { lat: 48.7973902, lng: 21.4328669 },
    { lat: 48.797145, lng: 21.4330287 },
    { lat: 48.7967061, lng: 21.4330985 },
    { lat: 48.7961815, lng: 21.4328664 },
    { lat: 48.7950947, lng: 21.4326789 },
    { lat: 48.7917869, lng: 21.442103 },
    { lat: 48.7915545, lng: 21.4425023 },
    { lat: 48.790108, lng: 21.4496478 },
    { lat: 48.7913295, lng: 21.4501389 },
    { lat: 48.791012, lng: 21.4517092 },
    { lat: 48.7915388, lng: 21.4519438 },
    { lat: 48.793224, lng: 21.452479 },
    { lat: 48.7963347, lng: 21.454425 },
    { lat: 48.7961788, lng: 21.4551771 },
    { lat: 48.797267, lng: 21.4555659 },
    { lat: 48.7981131, lng: 21.4557136 },
    { lat: 48.7982197, lng: 21.4554741 },
    { lat: 48.7988793, lng: 21.4557902 },
    { lat: 48.7983455, lng: 21.4591369 },
    { lat: 48.7981032, lng: 21.4610233 },
    { lat: 48.7993887, lng: 21.4613541 },
    { lat: 48.7995534, lng: 21.4614813 },
    { lat: 48.7994232, lng: 21.462727 },
    { lat: 48.7994373, lng: 21.4631808 },
    { lat: 48.7995031, lng: 21.4635876 },
    { lat: 48.8000283, lng: 21.4646612 },
    { lat: 48.800541, lng: 21.4655333 },
    { lat: 48.8011476, lng: 21.4663185 },
    { lat: 48.8003354, lng: 21.4677178 },
    { lat: 48.8003431, lng: 21.4677876 },
    { lat: 48.8001862, lng: 21.4680454 },
    { lat: 48.8001671, lng: 21.4681591 },
    { lat: 48.8000674, lng: 21.4682496 },
    { lat: 48.7987115, lng: 21.4705377 },
    { lat: 48.7982531, lng: 21.4712277 },
    { lat: 48.797628, lng: 21.4726026 },
    { lat: 48.7976944, lng: 21.4726796 },
    { lat: 48.797908, lng: 21.4727121 },
    { lat: 48.79797, lng: 21.4730635 },
    { lat: 48.798025, lng: 21.4730959 },
  ],
  Hýľov: [
    { lat: 48.7044157, lng: 21.1025267 },
    { lat: 48.7047305, lng: 21.1035294 },
    { lat: 48.7049097, lng: 21.1045151 },
    { lat: 48.704963, lng: 21.1053278 },
    { lat: 48.7048048, lng: 21.1057614 },
    { lat: 48.7050117, lng: 21.1069114 },
    { lat: 48.7057247, lng: 21.1093394 },
    { lat: 48.7060293, lng: 21.1098476 },
    { lat: 48.7060018, lng: 21.1115162 },
    { lat: 48.7061543, lng: 21.1119508 },
    { lat: 48.7063251, lng: 21.1127154 },
    { lat: 48.7063369, lng: 21.1137422 },
    { lat: 48.7065629, lng: 21.1142655 },
    { lat: 48.7067143, lng: 21.1147729 },
    { lat: 48.7066493, lng: 21.1156934 },
    { lat: 48.7068467, lng: 21.1167209 },
    { lat: 48.7135642, lng: 21.1132033 },
    { lat: 48.7149361, lng: 21.112295 },
    { lat: 48.7162619, lng: 21.1116965 },
    { lat: 48.7170503, lng: 21.1114298 },
    { lat: 48.7179459, lng: 21.1127929 },
    { lat: 48.7183334, lng: 21.1135026 },
    { lat: 48.7185045, lng: 21.1138885 },
    { lat: 48.718568, lng: 21.1144263 },
    { lat: 48.7188184, lng: 21.1146881 },
    { lat: 48.7187059, lng: 21.1148649 },
    { lat: 48.7185543, lng: 21.1155408 },
    { lat: 48.7185266, lng: 21.115942 },
    { lat: 48.7184092, lng: 21.1161413 },
    { lat: 48.7183751, lng: 21.1163709 },
    { lat: 48.7182713, lng: 21.1166151 },
    { lat: 48.7182088, lng: 21.1173614 },
    { lat: 48.7180864, lng: 21.1178626 },
    { lat: 48.7178694, lng: 21.1191695 },
    { lat: 48.717948, lng: 21.1194802 },
    { lat: 48.7177561, lng: 21.1207236 },
    { lat: 48.7177796, lng: 21.12181 },
    { lat: 48.7176391, lng: 21.1219907 },
    { lat: 48.7175169, lng: 21.1227738 },
    { lat: 48.7175213, lng: 21.1230525 },
    { lat: 48.7173098, lng: 21.1238632 },
    { lat: 48.717316, lng: 21.1245859 },
    { lat: 48.7172245, lng: 21.1251033 },
    { lat: 48.7172004, lng: 21.1255781 },
    { lat: 48.7177477, lng: 21.1257601 },
    { lat: 48.7186647, lng: 21.1262486 },
    { lat: 48.7194153, lng: 21.1242793 },
    { lat: 48.7196693, lng: 21.1234566 },
    { lat: 48.7217409, lng: 21.1209539 },
    { lat: 48.7230741, lng: 21.1213278 },
    { lat: 48.7237226, lng: 21.1213712 },
    { lat: 48.7237325, lng: 21.1216788 },
    { lat: 48.7234297, lng: 21.122621 },
    { lat: 48.7230726, lng: 21.1234877 },
    { lat: 48.7223431, lng: 21.1247455 },
    { lat: 48.7221877, lng: 21.1247818 },
    { lat: 48.722138, lng: 21.1249455 },
    { lat: 48.7222588, lng: 21.1250301 },
    { lat: 48.7221277, lng: 21.1254717 },
    { lat: 48.7218807, lng: 21.1255942 },
    { lat: 48.7218149, lng: 21.1258952 },
    { lat: 48.7218886, lng: 21.1261582 },
    { lat: 48.7218832, lng: 21.1263418 },
    { lat: 48.7223232, lng: 21.1265039 },
    { lat: 48.7226063, lng: 21.1266081 },
    { lat: 48.7231064, lng: 21.1267923 },
    { lat: 48.7240117, lng: 21.1271258 },
    { lat: 48.7245023, lng: 21.1272207 },
    { lat: 48.7249708, lng: 21.1271975 },
    { lat: 48.7253477, lng: 21.1275424 },
    { lat: 48.7255339, lng: 21.1279805 },
    { lat: 48.7259584, lng: 21.1287248 },
    { lat: 48.7260942, lng: 21.1287915 },
    { lat: 48.7266113, lng: 21.1287002 },
    { lat: 48.7280966, lng: 21.1280565 },
    { lat: 48.7282679, lng: 21.1278547 },
    { lat: 48.7284986, lng: 21.1277063 },
    { lat: 48.7283899, lng: 21.1274062 },
    { lat: 48.7289438, lng: 21.1269243 },
    { lat: 48.7289436, lng: 21.1267718 },
    { lat: 48.7283489, lng: 21.1265828 },
    { lat: 48.7283822, lng: 21.1264285 },
    { lat: 48.7283443, lng: 21.1262987 },
    { lat: 48.7280176, lng: 21.126082 },
    { lat: 48.7276673, lng: 21.1259999 },
    { lat: 48.7278114, lng: 21.1256536 },
    { lat: 48.7280828, lng: 21.1255867 },
    { lat: 48.7282328, lng: 21.1254534 },
    { lat: 48.7284438, lng: 21.1250455 },
    { lat: 48.7286338, lng: 21.1248002 },
    { lat: 48.7289568, lng: 21.1247591 },
    { lat: 48.7290073, lng: 21.1246524 },
    { lat: 48.7293861, lng: 21.1244239 },
    { lat: 48.7295361, lng: 21.1244005 },
    { lat: 48.7297584, lng: 21.1252612 },
    { lat: 48.7299672, lng: 21.1255279 },
    { lat: 48.7300814, lng: 21.1255816 },
    { lat: 48.7303658, lng: 21.1256011 },
    { lat: 48.7309837, lng: 21.1254624 },
    { lat: 48.7314224, lng: 21.1250656 },
    { lat: 48.7314918, lng: 21.1249857 },
    { lat: 48.7315307, lng: 21.1244124 },
    { lat: 48.731505, lng: 21.1240966 },
    { lat: 48.733912, lng: 21.1250381 },
    { lat: 48.733937, lng: 21.1250473 },
    { lat: 48.7341777, lng: 21.1243723 },
    { lat: 48.7343127, lng: 21.1237367 },
    { lat: 48.7340815, lng: 21.1217916 },
    { lat: 48.7344096, lng: 21.1213857 },
    { lat: 48.7348295, lng: 21.1210729 },
    { lat: 48.7349738, lng: 21.1204844 },
    { lat: 48.7350568, lng: 21.1203378 },
    { lat: 48.7358019, lng: 21.1196707 },
    { lat: 48.7358596, lng: 21.1196818 },
    { lat: 48.7361268, lng: 21.1197638 },
    { lat: 48.7363594, lng: 21.1195456 },
    { lat: 48.7364788, lng: 21.1189889 },
    { lat: 48.7365828, lng: 21.1189656 },
    { lat: 48.7371229, lng: 21.1185402 },
    { lat: 48.7373052, lng: 21.1180087 },
    { lat: 48.7375802, lng: 21.1160934 },
    { lat: 48.7378842, lng: 21.1150139 },
    { lat: 48.7378558, lng: 21.1147597 },
    { lat: 48.737964, lng: 21.1144123 },
    { lat: 48.7382103, lng: 21.1141289 },
    { lat: 48.7383385, lng: 21.1133561 },
    { lat: 48.7384894, lng: 21.1131482 },
    { lat: 48.7385161, lng: 21.1127547 },
    { lat: 48.7383471, lng: 21.1125364 },
    { lat: 48.7383013, lng: 21.1122642 },
    { lat: 48.7383521, lng: 21.1116069 },
    { lat: 48.7383269, lng: 21.1110646 },
    { lat: 48.7384003, lng: 21.1105821 },
    { lat: 48.7382085, lng: 21.1091001 },
    { lat: 48.738293, lng: 21.1088236 },
    { lat: 48.7385348, lng: 21.1086767 },
    { lat: 48.7387311, lng: 21.1083442 },
    { lat: 48.7389079, lng: 21.1078859 },
    { lat: 48.739075, lng: 21.1077659 },
    { lat: 48.7391192, lng: 21.1075323 },
    { lat: 48.7392217, lng: 21.1073262 },
    { lat: 48.7393348, lng: 21.1072358 },
    { lat: 48.7394967, lng: 21.1069162 },
    { lat: 48.7396836, lng: 21.1067434 },
    { lat: 48.7396872, lng: 21.1062611 },
    { lat: 48.7398035, lng: 21.1062415 },
    { lat: 48.7401843, lng: 21.1054111 },
    { lat: 48.7406167, lng: 21.1048915 },
    { lat: 48.7408064, lng: 21.1044568 },
    { lat: 48.7411358, lng: 21.1043427 },
    { lat: 48.7411803, lng: 21.1040762 },
    { lat: 48.7413644, lng: 21.1038912 },
    { lat: 48.7413792, lng: 21.1036469 },
    { lat: 48.7415334, lng: 21.1035136 },
    { lat: 48.7415968, lng: 21.1029295 },
    { lat: 48.7421727, lng: 21.1020494 },
    { lat: 48.7423566, lng: 21.1018508 },
    { lat: 48.7424746, lng: 21.1015675 },
    { lat: 48.742924, lng: 21.1013301 },
    { lat: 48.743311, lng: 21.1009396 },
    { lat: 48.7433027, lng: 21.1006161 },
    { lat: 48.7434778, lng: 21.1003917 },
    { lat: 48.7434989, lng: 21.1002425 },
    { lat: 48.7436331, lng: 21.1001128 },
    { lat: 48.7436304, lng: 21.0997799 },
    { lat: 48.7438968, lng: 21.099388 },
    { lat: 48.7440658, lng: 21.0992518 },
    { lat: 48.7440996, lng: 21.0989059 },
    { lat: 48.744311, lng: 21.0986575 },
    { lat: 48.7444631, lng: 21.097928 },
    { lat: 48.7445893, lng: 21.0976509 },
    { lat: 48.7446688, lng: 21.0973298 },
    { lat: 48.7448656, lng: 21.0969751 },
    { lat: 48.744942, lng: 21.0965861 },
    { lat: 48.7450289, lng: 21.0963843 },
    { lat: 48.7451577, lng: 21.096328 },
    { lat: 48.7452281, lng: 21.0961039 },
    { lat: 48.7452681, lng: 21.0955963 },
    { lat: 48.7453602, lng: 21.095418 },
    { lat: 48.7456602, lng: 21.0953666 },
    { lat: 48.7459765, lng: 21.0951275 },
    { lat: 48.7461706, lng: 21.0947908 },
    { lat: 48.746253, lng: 21.0947287 },
    { lat: 48.7463341, lng: 21.0944856 },
    { lat: 48.7464606, lng: 21.094503 },
    { lat: 48.7466226, lng: 21.0941893 },
    { lat: 48.7468968, lng: 21.0940332 },
    { lat: 48.7472721, lng: 21.0939463 },
    { lat: 48.7474656, lng: 21.0936642 },
    { lat: 48.7479551, lng: 21.0932736 },
    { lat: 48.74794, lng: 21.0930917 },
    { lat: 48.7481354, lng: 21.0928326 },
    { lat: 48.7485152, lng: 21.0925007 },
    { lat: 48.7489883, lng: 21.092301 },
    { lat: 48.7491164, lng: 21.0921197 },
    { lat: 48.7490757, lng: 21.0920431 },
    { lat: 48.7491083, lng: 21.0919715 },
    { lat: 48.7492401, lng: 21.0919789 },
    { lat: 48.7494988, lng: 21.091591 },
    { lat: 48.7496197, lng: 21.0911213 },
    { lat: 48.750234, lng: 21.0905866 },
    { lat: 48.7502917, lng: 21.0903562 },
    { lat: 48.7504662, lng: 21.0900052 },
    { lat: 48.7506886, lng: 21.0897159 },
    { lat: 48.7510163, lng: 21.0896536 },
    { lat: 48.7516658, lng: 21.0887471 },
    { lat: 48.7520458, lng: 21.0885581 },
    { lat: 48.7524292, lng: 21.0879937 },
    { lat: 48.7523997, lng: 21.086973 },
    { lat: 48.7524431, lng: 21.0868941 },
    { lat: 48.7523858, lng: 21.0867172 },
    { lat: 48.7524523, lng: 21.0865885 },
    { lat: 48.7524403, lng: 21.0861803 },
    { lat: 48.7525268, lng: 21.0856906 },
    { lat: 48.7525406, lng: 21.0852769 },
    { lat: 48.7524835, lng: 21.0848692 },
    { lat: 48.7526368, lng: 21.0842508 },
    { lat: 48.7526429, lng: 21.0839218 },
    { lat: 48.7525509, lng: 21.083374 },
    { lat: 48.7525537, lng: 21.0831459 },
    { lat: 48.7526699, lng: 21.0827382 },
    { lat: 48.7528445, lng: 21.0815229 },
    { lat: 48.7529487, lng: 21.0812687 },
    { lat: 48.7529497, lng: 21.0809181 },
    { lat: 48.7532394, lng: 21.079924 },
    { lat: 48.7532496, lng: 21.0794752 },
    { lat: 48.753325, lng: 21.0791607 },
    { lat: 48.7535492, lng: 21.0785388 },
    { lat: 48.7539106, lng: 21.0780965 },
    { lat: 48.7542034, lng: 21.0775137 },
    { lat: 48.7540732, lng: 21.0767259 },
    { lat: 48.7540802, lng: 21.0764564 },
    { lat: 48.7543458, lng: 21.0761713 },
    { lat: 48.7545505, lng: 21.0757873 },
    { lat: 48.7549482, lng: 21.0747644 },
    { lat: 48.7558008, lng: 21.0738779 },
    { lat: 48.7561709, lng: 21.0735954 },
    { lat: 48.7564313, lng: 21.0732254 },
    { lat: 48.7569284, lng: 21.0727961 },
    { lat: 48.7574851, lng: 21.0726474 },
    { lat: 48.7583888, lng: 21.0720927 },
    { lat: 48.7585208, lng: 21.0719587 },
    { lat: 48.7590472, lng: 21.0712241 },
    { lat: 48.7593142, lng: 21.0709499 },
    { lat: 48.7596868, lng: 21.0701206 },
    { lat: 48.7601232, lng: 21.0693904 },
    { lat: 48.7607882, lng: 21.0689293 },
    { lat: 48.7609681, lng: 21.0689573 },
    { lat: 48.7610994, lng: 21.0688844 },
    { lat: 48.7614612, lng: 21.0680635 },
    { lat: 48.7617756, lng: 21.0676587 },
    { lat: 48.7619575, lng: 21.0670573 },
    { lat: 48.7620584, lng: 21.0668764 },
    { lat: 48.7621602, lng: 21.0667483 },
    { lat: 48.7625218, lng: 21.06662 },
    { lat: 48.7625662, lng: 21.0665347 },
    { lat: 48.7625891, lng: 21.0659551 },
    { lat: 48.7627199, lng: 21.0657206 },
    { lat: 48.7629739, lng: 21.0643293 },
    { lat: 48.763276, lng: 21.0635448 },
    { lat: 48.7633584, lng: 21.0626928 },
    { lat: 48.7634427, lng: 21.0607179 },
    { lat: 48.7633565, lng: 21.0599017 },
    { lat: 48.7633082, lng: 21.0597308 },
    { lat: 48.7629095, lng: 21.0590981 },
    { lat: 48.7625753, lng: 21.0588601 },
    { lat: 48.7623344, lng: 21.0584781 },
    { lat: 48.7615575, lng: 21.0575736 },
    { lat: 48.7588987, lng: 21.0530092 },
    { lat: 48.7585901, lng: 21.0497262 },
    { lat: 48.7580487, lng: 21.0479684 },
    { lat: 48.7574409, lng: 21.0468292 },
    { lat: 48.7555173, lng: 21.0473119 },
    { lat: 48.7550858, lng: 21.0473663 },
    { lat: 48.7539639, lng: 21.0455134 },
    { lat: 48.7525454, lng: 21.0443361 },
    { lat: 48.7520513, lng: 21.0442001 },
    { lat: 48.7516152, lng: 21.0441842 },
    { lat: 48.7510295, lng: 21.0440665 },
    { lat: 48.7505573, lng: 21.0437701 },
    { lat: 48.7499933, lng: 21.0436975 },
    { lat: 48.7493229, lng: 21.0435069 },
    { lat: 48.748631, lng: 21.0435169 },
    { lat: 48.7479657, lng: 21.0433745 },
    { lat: 48.7473761, lng: 21.0433819 },
    { lat: 48.7466525, lng: 21.0436561 },
    { lat: 48.7452703, lng: 21.0445169 },
    { lat: 48.7418207, lng: 21.0464132 },
    { lat: 48.741231, lng: 21.0465828 },
    { lat: 48.7396182, lng: 21.047347 },
    { lat: 48.7379674, lng: 21.0480088 },
    { lat: 48.7362934, lng: 21.0479299 },
    { lat: 48.7358909, lng: 21.0474669 },
    { lat: 48.7352867, lng: 21.0469551 },
    { lat: 48.7342717, lng: 21.0464747 },
    { lat: 48.733268, lng: 21.0461397 },
    { lat: 48.730283, lng: 21.0440062 },
    { lat: 48.7297809, lng: 21.0437611 },
    { lat: 48.7293041, lng: 21.0433953 },
    { lat: 48.7286982, lng: 21.040914 },
    { lat: 48.7275615, lng: 21.0396786 },
    { lat: 48.7273344, lng: 21.0393405 },
    { lat: 48.726991, lng: 21.0388939 },
    { lat: 48.7268875, lng: 21.0386161 },
    { lat: 48.7265852, lng: 21.0381485 },
    { lat: 48.7265035, lng: 21.0377504 },
    { lat: 48.7263447, lng: 21.0376839 },
    { lat: 48.72629, lng: 21.037591 },
    { lat: 48.7260409, lng: 21.0379291 },
    { lat: 48.7256894, lng: 21.0382584 },
    { lat: 48.7256437, lng: 21.0383449 },
    { lat: 48.7257174, lng: 21.0384285 },
    { lat: 48.725791, lng: 21.0386786 },
    { lat: 48.7257535, lng: 21.038911 },
    { lat: 48.7258246, lng: 21.0392237 },
    { lat: 48.7257859, lng: 21.0396911 },
    { lat: 48.7255634, lng: 21.0402189 },
    { lat: 48.7250806, lng: 21.0405998 },
    { lat: 48.724733, lng: 21.0410119 },
    { lat: 48.7246308, lng: 21.0410245 },
    { lat: 48.7244657, lng: 21.0408597 },
    { lat: 48.7242585, lng: 21.040964 },
    { lat: 48.7238867, lng: 21.041811 },
    { lat: 48.7237977, lng: 21.0423185 },
    { lat: 48.7234537, lng: 21.0433306 },
    { lat: 48.7232082, lng: 21.0434324 },
    { lat: 48.7231541, lng: 21.0435316 },
    { lat: 48.7228957, lng: 21.0433763 },
    { lat: 48.7225876, lng: 21.0440342 },
    { lat: 48.7224661, lng: 21.0444165 },
    { lat: 48.7222612, lng: 21.0450307 },
    { lat: 48.7221242, lng: 21.0457677 },
    { lat: 48.7220078, lng: 21.046751 },
    { lat: 48.7219118, lng: 21.0469813 },
    { lat: 48.7218927, lng: 21.0473253 },
    { lat: 48.7212176, lng: 21.0477789 },
    { lat: 48.7209189, lng: 21.0478686 },
    { lat: 48.7205011, lng: 21.0481404 },
    { lat: 48.7202012, lng: 21.0484326 },
    { lat: 48.7198138, lng: 21.0484936 },
    { lat: 48.7194062, lng: 21.048692 },
    { lat: 48.7192833, lng: 21.0486858 },
    { lat: 48.7190448, lng: 21.0488648 },
    { lat: 48.7192233, lng: 21.049123 },
    { lat: 48.719324, lng: 21.0493825 },
    { lat: 48.720362, lng: 21.0506296 },
    { lat: 48.7204401, lng: 21.0516068 },
    { lat: 48.7206765, lng: 21.0519528 },
    { lat: 48.7206738, lng: 21.0522481 },
    { lat: 48.7206022, lng: 21.0527108 },
    { lat: 48.720571, lng: 21.053447 },
    { lat: 48.7205125, lng: 21.0537865 },
    { lat: 48.720341, lng: 21.0542609 },
    { lat: 48.7202371, lng: 21.0555572 },
    { lat: 48.7201933, lng: 21.0569777 },
    { lat: 48.720064, lng: 21.0576685 },
    { lat: 48.7199818, lng: 21.0577733 },
    { lat: 48.7198256, lng: 21.0585364 },
    { lat: 48.7197446, lng: 21.05923 },
    { lat: 48.719791, lng: 21.0597174 },
    { lat: 48.7196535, lng: 21.0600594 },
    { lat: 48.7197002, lng: 21.0604933 },
    { lat: 48.7195913, lng: 21.0607406 },
    { lat: 48.7197198, lng: 21.0619176 },
    { lat: 48.719664, lng: 21.0625595 },
    { lat: 48.719559, lng: 21.0627893 },
    { lat: 48.7195276, lng: 21.0638681 },
    { lat: 48.7194551, lng: 21.0644004 },
    { lat: 48.7193877, lng: 21.0647212 },
    { lat: 48.7186951, lng: 21.0663595 },
    { lat: 48.718049, lng: 21.0673649 },
    { lat: 48.7176752, lng: 21.0682941 },
    { lat: 48.7174465, lng: 21.0690126 },
    { lat: 48.7172789, lng: 21.0702992 },
    { lat: 48.7173577, lng: 21.0705033 },
    { lat: 48.7174946, lng: 21.0713275 },
    { lat: 48.7175034, lng: 21.0716348 },
    { lat: 48.7171288, lng: 21.0726414 },
    { lat: 48.7169011, lng: 21.072943 },
    { lat: 48.7166162, lng: 21.073908 },
    { lat: 48.7160566, lng: 21.0749774 },
    { lat: 48.7158749, lng: 21.075482 },
    { lat: 48.715555, lng: 21.0759231 },
    { lat: 48.7149246, lng: 21.0769902 },
    { lat: 48.7144944, lng: 21.0782692 },
    { lat: 48.713762, lng: 21.0799108 },
    { lat: 48.7134766, lng: 21.0810671 },
    { lat: 48.713151, lng: 21.0814782 },
    { lat: 48.7128825, lng: 21.0820758 },
    { lat: 48.7126282, lng: 21.0823231 },
    { lat: 48.7125175, lng: 21.0825537 },
    { lat: 48.712458, lng: 21.0825591 },
    { lat: 48.7118325, lng: 21.0835751 },
    { lat: 48.7115018, lng: 21.0848997 },
    { lat: 48.711566, lng: 21.0857748 },
    { lat: 48.7113126, lng: 21.0866036 },
    { lat: 48.7109817, lng: 21.0880304 },
    { lat: 48.7107834, lng: 21.0884039 },
    { lat: 48.7102062, lng: 21.0892092 },
    { lat: 48.7097693, lng: 21.0899995 },
    { lat: 48.7094751, lng: 21.0904061 },
    { lat: 48.709161, lng: 21.091203 },
    { lat: 48.7090392, lng: 21.0913367 },
    { lat: 48.7087492, lng: 21.0919012 },
    { lat: 48.7086603, lng: 21.09218 },
    { lat: 48.7083593, lng: 21.0925126 },
    { lat: 48.7082832, lng: 21.0930189 },
    { lat: 48.7081816, lng: 21.0932188 },
    { lat: 48.708187, lng: 21.0937273 },
    { lat: 48.7079188, lng: 21.0948365 },
    { lat: 48.7079136, lng: 21.0956839 },
    { lat: 48.7079858, lng: 21.0960691 },
    { lat: 48.7079428, lng: 21.0963827 },
    { lat: 48.7072063, lng: 21.097165 },
    { lat: 48.7066818, lng: 21.0972746 },
    { lat: 48.7064932, lng: 21.0974602 },
    { lat: 48.7064846, lng: 21.0974416 },
    { lat: 48.7062865, lng: 21.0977203 },
    { lat: 48.7055563, lng: 21.0985961 },
    { lat: 48.7049092, lng: 21.0996433 },
    { lat: 48.7047485, lng: 21.0997948 },
    { lat: 48.7043989, lng: 21.1003608 },
    { lat: 48.7043424, lng: 21.1005987 },
    { lat: 48.7042763, lng: 21.1020748 },
    { lat: 48.7044157, lng: 21.1025267 },
  ],
  Opátka: [
    { lat: 48.8223871, lng: 21.065835 },
    { lat: 48.8222335, lng: 21.0655804 },
    { lat: 48.8221754, lng: 21.064895 },
    { lat: 48.8220848, lng: 21.0647404 },
    { lat: 48.82192, lng: 21.064173 },
    { lat: 48.821756, lng: 21.063997 },
    { lat: 48.821723, lng: 21.063923 },
    { lat: 48.821635, lng: 21.063847 },
    { lat: 48.821469, lng: 21.063507 },
    { lat: 48.821497, lng: 21.063393 },
    { lat: 48.821467, lng: 21.063232 },
    { lat: 48.821416, lng: 21.063149 },
    { lat: 48.821328, lng: 21.063079 },
    { lat: 48.821212, lng: 21.062852 },
    { lat: 48.82107, lng: 21.062701 },
    { lat: 48.821017, lng: 21.062697 },
    { lat: 48.820976, lng: 21.062639 },
    { lat: 48.820846, lng: 21.062493 },
    { lat: 48.820799, lng: 21.062375 },
    { lat: 48.820721, lng: 21.062279 },
    { lat: 48.820613, lng: 21.062193 },
    { lat: 48.820184, lng: 21.06197 },
    { lat: 48.81991, lng: 21.06151 },
    { lat: 48.819858, lng: 21.06147 },
    { lat: 48.819586, lng: 21.061249 },
    { lat: 48.81952, lng: 21.061187 },
    { lat: 48.819408, lng: 21.061065 },
    { lat: 48.819374, lng: 21.060982 },
    { lat: 48.819234, lng: 21.060843 },
    { lat: 48.81918, lng: 21.060812 },
    { lat: 48.819077, lng: 21.060737 },
    { lat: 48.819001, lng: 21.060663 },
    { lat: 48.818775, lng: 21.060331 },
    { lat: 48.81866, lng: 21.060257 },
    { lat: 48.818581, lng: 21.060157 },
    { lat: 48.818432, lng: 21.059876 },
    { lat: 48.818403, lng: 21.059726 },
    { lat: 48.818305, lng: 21.059465 },
    { lat: 48.818114, lng: 21.059416 },
    { lat: 48.818149, lng: 21.059262 },
    { lat: 48.818127, lng: 21.059213 },
    { lat: 48.818074, lng: 21.059193 },
    { lat: 48.818007, lng: 21.059144 },
    { lat: 48.817913, lng: 21.059138 },
    { lat: 48.817804, lng: 21.059078 },
    { lat: 48.817658, lng: 21.058881 },
    { lat: 48.817599, lng: 21.058585 },
    { lat: 48.817633, lng: 21.058432 },
    { lat: 48.817604, lng: 21.058258 },
    { lat: 48.817561, lng: 21.058144 },
    { lat: 48.817512, lng: 21.058066 },
    { lat: 48.817505, lng: 21.057991 },
    { lat: 48.81757, lng: 21.057947 },
    { lat: 48.817606, lng: 21.057925 },
    { lat: 48.817661, lng: 21.05789 },
    { lat: 48.817659, lng: 21.057844 },
    { lat: 48.817636, lng: 21.057773 },
    { lat: 48.817622, lng: 21.057616 },
    { lat: 48.817634, lng: 21.057524 },
    { lat: 48.81759, lng: 21.057498 },
    { lat: 48.817573, lng: 21.057485 },
    { lat: 48.81754, lng: 21.057443 },
    { lat: 48.817541, lng: 21.057299 },
    { lat: 48.817589, lng: 21.057147 },
    { lat: 48.817609, lng: 21.057034 },
    { lat: 48.817565, lng: 21.056888 },
    { lat: 48.817587, lng: 21.056769 },
    { lat: 48.817525, lng: 21.056698 },
    { lat: 48.817543, lng: 21.056595 },
    { lat: 48.817567, lng: 21.056525 },
    { lat: 48.817501, lng: 21.056346 },
    { lat: 48.817526, lng: 21.056027 },
    { lat: 48.817451, lng: 21.055916 },
    { lat: 48.817427, lng: 21.055806 },
    { lat: 48.817403, lng: 21.055693 },
    { lat: 48.817369, lng: 21.055447 },
    { lat: 48.817373, lng: 21.05533 },
    { lat: 48.8174, lng: 21.055072 },
    { lat: 48.817275, lng: 21.054915 },
    { lat: 48.817213, lng: 21.05478 },
    { lat: 48.817076, lng: 21.054426 },
    { lat: 48.817033, lng: 21.054264 },
    { lat: 48.816984, lng: 21.054158 },
    { lat: 48.81694, lng: 21.053883 },
    { lat: 48.816946, lng: 21.053681 },
    { lat: 48.816982, lng: 21.05359 },
    { lat: 48.817039, lng: 21.053036 },
    { lat: 48.817128, lng: 21.052732 },
    { lat: 48.81713, lng: 21.052599 },
    { lat: 48.81715, lng: 21.052359 },
    { lat: 48.817204, lng: 21.052134 },
    { lat: 48.817144, lng: 21.052019 },
    { lat: 48.817141, lng: 21.051721 },
    { lat: 48.81718, lng: 21.051507 },
    { lat: 48.817192, lng: 21.051448 },
    { lat: 48.817193, lng: 21.051312 },
    { lat: 48.81719, lng: 21.051235 },
    { lat: 48.817138, lng: 21.050938 },
    { lat: 48.817113, lng: 21.050759 },
    { lat: 48.817109, lng: 21.050698 },
    { lat: 48.817111, lng: 21.050605 },
    { lat: 48.817105, lng: 21.05046 },
    { lat: 48.817087, lng: 21.050348 },
    { lat: 48.817067, lng: 21.050211 },
    { lat: 48.817078, lng: 21.049935 },
    { lat: 48.817109, lng: 21.04977 },
    { lat: 48.81713, lng: 21.049686 },
    { lat: 48.817227, lng: 21.049471 },
    { lat: 48.817256, lng: 21.049289 },
    { lat: 48.817317, lng: 21.049173 },
    { lat: 48.81749, lng: 21.049012 },
    { lat: 48.817565, lng: 21.048985 },
    { lat: 48.817702, lng: 21.048881 },
    { lat: 48.817751, lng: 21.048789 },
    { lat: 48.817797, lng: 21.048672 },
    { lat: 48.817809, lng: 21.048422 },
    { lat: 48.81783, lng: 21.048328 },
    { lat: 48.817848, lng: 21.048171 },
    { lat: 48.817888, lng: 21.047825 },
    { lat: 48.817916, lng: 21.047753 },
    { lat: 48.817915, lng: 21.047485 },
    { lat: 48.817942, lng: 21.047337 },
    { lat: 48.817961, lng: 21.047149 },
    { lat: 48.818003, lng: 21.047048 },
    { lat: 48.818025, lng: 21.046908 },
    { lat: 48.818085, lng: 21.046679 },
    { lat: 48.81811, lng: 21.046609 },
    { lat: 48.818159, lng: 21.046512 },
    { lat: 48.818324, lng: 21.045947 },
    { lat: 48.818447, lng: 21.045724 },
    { lat: 48.818481, lng: 21.045546 },
    { lat: 48.818437, lng: 21.045466 },
    { lat: 48.818444, lng: 21.045117 },
    { lat: 48.818505, lng: 21.044968 },
    { lat: 48.818515, lng: 21.044856 },
    { lat: 48.818496, lng: 21.044759 },
    { lat: 48.818519, lng: 21.044541 },
    { lat: 48.818524, lng: 21.044441 },
    { lat: 48.818544, lng: 21.044362 },
    { lat: 48.81856, lng: 21.044232 },
    { lat: 48.818603, lng: 21.044145 },
    { lat: 48.818519, lng: 21.043967 },
    { lat: 48.818477, lng: 21.043806 },
    { lat: 48.818322, lng: 21.043559 },
    { lat: 48.818202, lng: 21.043442 },
    { lat: 48.817827, lng: 21.043269 },
    { lat: 48.817738, lng: 21.04316 },
    { lat: 48.817681, lng: 21.043022 },
    { lat: 48.817655, lng: 21.043027 },
    { lat: 48.817566, lng: 21.043027 },
    { lat: 48.817553, lng: 21.043026 },
    { lat: 48.817483, lng: 21.043023 },
    { lat: 48.817476, lng: 21.043023 },
    { lat: 48.817406, lng: 21.043019 },
    { lat: 48.817397, lng: 21.043019 },
    { lat: 48.817003, lng: 21.043033 },
    { lat: 48.816559, lng: 21.042389 },
    { lat: 48.816258, lng: 21.042603 },
    { lat: 48.816012, lng: 21.042905 },
    { lat: 48.815824, lng: 21.043134 },
    { lat: 48.815716, lng: 21.043695 },
    { lat: 48.81553, lng: 21.044374 },
    { lat: 48.815194, lng: 21.045198 },
    { lat: 48.814856, lng: 21.045958 },
    { lat: 48.814563, lng: 21.046426 },
    { lat: 48.814468, lng: 21.047509 },
    { lat: 48.813998, lng: 21.047919 },
    { lat: 48.813571, lng: 21.048305 },
    { lat: 48.81316, lng: 21.048661 },
    { lat: 48.812247, lng: 21.04847 },
    { lat: 48.811687, lng: 21.047792 },
    { lat: 48.810953, lng: 21.047962 },
    { lat: 48.810119, lng: 21.047851 },
    { lat: 48.809555, lng: 21.047785 },
    { lat: 48.809166, lng: 21.047584 },
    { lat: 48.808823, lng: 21.046991 },
    { lat: 48.80862, lng: 21.046331 },
    { lat: 48.808316, lng: 21.045336 },
    { lat: 48.807876, lng: 21.044578 },
    { lat: 48.807545, lng: 21.044035 },
    { lat: 48.807579, lng: 21.043526 },
    { lat: 48.807629, lng: 21.042987 },
    { lat: 48.807771, lng: 21.042249 },
    { lat: 48.807556, lng: 21.0412 },
    { lat: 48.807557, lng: 21.040254 },
    { lat: 48.807426, lng: 21.039146 },
    { lat: 48.807303, lng: 21.038188 },
    { lat: 48.80691, lng: 21.037762 },
    { lat: 48.806487, lng: 21.037178 },
    { lat: 48.805964, lng: 21.03688 },
    { lat: 48.805381, lng: 21.036625 },
    { lat: 48.804881, lng: 21.03575 },
    { lat: 48.804951, lng: 21.034787 },
    { lat: 48.805383, lng: 21.033343 },
    { lat: 48.805107, lng: 21.032535 },
    { lat: 48.805107, lng: 21.03125 },
    { lat: 48.805371, lng: 21.030289 },
    { lat: 48.805611, lng: 21.029376 },
    { lat: 48.805607, lng: 21.028646 },
    { lat: 48.805447, lng: 21.027862 },
    { lat: 48.805436, lng: 21.027802 },
    { lat: 48.805423, lng: 21.027731 },
    { lat: 48.805302, lng: 21.02708 },
    { lat: 48.805081, lng: 21.026644 },
    { lat: 48.804675, lng: 21.026272 },
    { lat: 48.804656, lng: 21.026211 },
    { lat: 48.804547, lng: 21.02628 },
    { lat: 48.80417, lng: 21.026288 },
    { lat: 48.803953, lng: 21.026341 },
    { lat: 48.803719, lng: 21.026413 },
    { lat: 48.803609, lng: 21.026483 },
    { lat: 48.803331, lng: 21.026757 },
    { lat: 48.802851, lng: 21.02736 },
    { lat: 48.802659, lng: 21.02767 },
    { lat: 48.802479, lng: 21.027915 },
    { lat: 48.801869, lng: 21.0285 },
    { lat: 48.801565, lng: 21.028812 },
    { lat: 48.801275, lng: 21.029332 },
    { lat: 48.800958, lng: 21.030004 },
    { lat: 48.800747, lng: 21.030373 },
    { lat: 48.800675, lng: 21.030438 },
    { lat: 48.800573, lng: 21.030445 },
    { lat: 48.800451, lng: 21.030366 },
    { lat: 48.800348, lng: 21.030273 },
    { lat: 48.799893, lng: 21.029673 },
    { lat: 48.79947, lng: 21.029222 },
    { lat: 48.799182, lng: 21.028912 },
    { lat: 48.799034, lng: 21.028788 },
    { lat: 48.798945, lng: 21.028764 },
    { lat: 48.798609, lng: 21.028744 },
    { lat: 48.798467, lng: 21.028695 },
    { lat: 48.798322, lng: 21.028672 },
    { lat: 48.798053, lng: 21.028663 },
    { lat: 48.797868, lng: 21.028623 },
    { lat: 48.797634, lng: 21.028574 },
    { lat: 48.7969, lng: 21.02846 },
    { lat: 48.796526, lng: 21.028561 },
    { lat: 48.796386, lng: 21.02863 },
    { lat: 48.796304, lng: 21.02867 },
    { lat: 48.796139, lng: 21.028675 },
    { lat: 48.795986, lng: 21.028668 },
    { lat: 48.795374, lng: 21.028592 },
    { lat: 48.794894, lng: 21.028581 },
    { lat: 48.794465, lng: 21.028598 },
    { lat: 48.794305, lng: 21.028626 },
    { lat: 48.793969, lng: 21.028731 },
    { lat: 48.79375, lng: 21.028749 },
    { lat: 48.793387, lng: 21.028876 },
    { lat: 48.793192, lng: 21.028857 },
    { lat: 48.792879, lng: 21.028734 },
    { lat: 48.792731, lng: 21.028784 },
    { lat: 48.792509, lng: 21.028927 },
    { lat: 48.792287, lng: 21.029042 },
    { lat: 48.792113, lng: 21.029033 },
    { lat: 48.791919, lng: 21.028986 },
    { lat: 48.791675, lng: 21.028979 },
    { lat: 48.791525, lng: 21.028974 },
    { lat: 48.79129, lng: 21.029003 },
    { lat: 48.791141, lng: 21.029042 },
    { lat: 48.791021, lng: 21.029042 },
    { lat: 48.79064, lng: 21.028996 },
    { lat: 48.790341, lng: 21.02889 },
    { lat: 48.790218, lng: 21.028845 },
    { lat: 48.790071, lng: 21.028734 },
    { lat: 48.789984, lng: 21.02861 },
    { lat: 48.789922, lng: 21.028448 },
    { lat: 48.789869, lng: 21.028258 },
    { lat: 48.789598, lng: 21.02699 },
    { lat: 48.789546, lng: 21.02666 },
    { lat: 48.788953, lng: 21.025008 },
    { lat: 48.788759, lng: 21.024429 },
    { lat: 48.788673, lng: 21.024255 },
    { lat: 48.788469, lng: 21.024016 },
    { lat: 48.788379, lng: 21.023894 },
    { lat: 48.788238, lng: 21.023608 },
    { lat: 48.7882031, lng: 21.0235371 },
    { lat: 48.788147, lng: 21.023503 },
    { lat: 48.7878372, lng: 21.0236179 },
    { lat: 48.78757, lng: 21.023722 },
    { lat: 48.787303, lng: 21.023835 },
    { lat: 48.786473, lng: 21.024267 },
    { lat: 48.785997, lng: 21.024432 },
    { lat: 48.7857323, lng: 21.0245512 },
    { lat: 48.785501, lng: 21.024672 },
    { lat: 48.78517, lng: 21.024924 },
    { lat: 48.784895, lng: 21.025214 },
    { lat: 48.784253, lng: 21.026004 },
    { lat: 48.783754, lng: 21.026444 },
    { lat: 48.78352, lng: 21.026693 },
    { lat: 48.782507, lng: 21.028062 },
    { lat: 48.782303, lng: 21.028253 },
    { lat: 48.781832, lng: 21.028566 },
    { lat: 48.781611, lng: 21.028719 },
    { lat: 48.781409, lng: 21.028835 },
    { lat: 48.781094, lng: 21.028906 },
    { lat: 48.780546, lng: 21.028973 },
    { lat: 48.780202, lng: 21.029077 },
    { lat: 48.780024, lng: 21.029159 },
    { lat: 48.779671, lng: 21.029305 },
    { lat: 48.779525, lng: 21.029355 },
    { lat: 48.779492, lng: 21.029406 },
    { lat: 48.779361, lng: 21.030044 },
    { lat: 48.779277, lng: 21.030223 },
    { lat: 48.779055, lng: 21.030529 },
    { lat: 48.77873, lng: 21.030783 },
    { lat: 48.778643, lng: 21.030818 },
    { lat: 48.778612, lng: 21.030807 },
    { lat: 48.778501, lng: 21.030768 },
    { lat: 48.778415, lng: 21.03072 },
    { lat: 48.778344, lng: 21.030655 },
    { lat: 48.77824, lng: 21.030508 },
    { lat: 48.778044, lng: 21.030272 },
    { lat: 48.777938, lng: 21.030167 },
    { lat: 48.777722, lng: 21.030026 },
    { lat: 48.777458, lng: 21.029787 },
    { lat: 48.777267, lng: 21.029598 },
    { lat: 48.776968, lng: 21.029324 },
    { lat: 48.776547, lng: 21.028859 },
    { lat: 48.776439, lng: 21.028727 },
    { lat: 48.776173, lng: 21.028485 },
    { lat: 48.776076, lng: 21.028329 },
    { lat: 48.776002, lng: 21.028118 },
    { lat: 48.775971, lng: 21.027948 },
    { lat: 48.775941, lng: 21.027012 },
    { lat: 48.775882, lng: 21.026679 },
    { lat: 48.775831, lng: 21.026443 },
    { lat: 48.775806, lng: 21.026202 },
    { lat: 48.775725, lng: 21.025368 },
    { lat: 48.77567, lng: 21.025141 },
    { lat: 48.775468, lng: 21.024546 },
    { lat: 48.775343, lng: 21.024012 },
    { lat: 48.775247, lng: 21.02381 },
    { lat: 48.775131, lng: 21.023642 },
    { lat: 48.774903, lng: 21.023461 },
    { lat: 48.774764, lng: 21.023303 },
    { lat: 48.774682, lng: 21.023202 },
    { lat: 48.77455, lng: 21.022985 },
    { lat: 48.774391, lng: 21.02257 },
    { lat: 48.774236, lng: 21.022353 },
    { lat: 48.774113, lng: 21.022182 },
    { lat: 48.774017, lng: 21.021958 },
    { lat: 48.773766, lng: 21.021039 },
    { lat: 48.773668, lng: 21.020487 },
    { lat: 48.77357, lng: 21.020218 },
    { lat: 48.773439, lng: 21.019984 },
    { lat: 48.773296, lng: 21.019805 },
    { lat: 48.773025, lng: 21.019532 },
    { lat: 48.772715, lng: 21.019246 },
    { lat: 48.772497, lng: 21.018908 },
    { lat: 48.772456, lng: 21.018862 },
    { lat: 48.772365, lng: 21.01871 },
    { lat: 48.772344, lng: 21.018234 },
    { lat: 48.772376, lng: 21.018077 },
    { lat: 48.772482, lng: 21.017668 },
    { lat: 48.772519, lng: 21.01739 },
    { lat: 48.772539, lng: 21.017286 },
    { lat: 48.772663, lng: 21.01677 },
    { lat: 48.772662, lng: 21.016768 },
    { lat: 48.77266, lng: 21.016271 },
    { lat: 48.772689, lng: 21.015646 },
    { lat: 48.7716668, lng: 21.0159778 },
    { lat: 48.7712802, lng: 21.0162047 },
    { lat: 48.7708655, lng: 21.0168322 },
    { lat: 48.7703378, lng: 21.0172901 },
    { lat: 48.7699108, lng: 21.0175425 },
    { lat: 48.7693922, lng: 21.0175873 },
    { lat: 48.7685342, lng: 21.0183973 },
    { lat: 48.7672981, lng: 21.0193153 },
    { lat: 48.7667987, lng: 21.0200062 },
    { lat: 48.7661865, lng: 21.0211355 },
    { lat: 48.7658161, lng: 21.0220564 },
    { lat: 48.7656381, lng: 21.0223235 },
    { lat: 48.7655318, lng: 21.0229626 },
    { lat: 48.765509, lng: 21.0236062 },
    { lat: 48.7653877, lng: 21.0242971 },
    { lat: 48.7651748, lng: 21.02494 },
    { lat: 48.7651607, lng: 21.0258155 },
    { lat: 48.7640319, lng: 21.0285939 },
    { lat: 48.7624066, lng: 21.0332186 },
    { lat: 48.7616312, lng: 21.034631 },
    { lat: 48.761611, lng: 21.0350717 },
    { lat: 48.7616812, lng: 21.0355027 },
    { lat: 48.7609933, lng: 21.0363887 },
    { lat: 48.7608127, lng: 21.0370886 },
    { lat: 48.7603675, lng: 21.0402655 },
    { lat: 48.7597206, lng: 21.0421701 },
    { lat: 48.7590974, lng: 21.043314 },
    { lat: 48.7587082, lng: 21.044149 },
    { lat: 48.7582989, lng: 21.0451195 },
    { lat: 48.7593583, lng: 21.0468496 },
    { lat: 48.7601838, lng: 21.0493827 },
    { lat: 48.761185, lng: 21.0520908 },
    { lat: 48.7633791, lng: 21.0544456 },
    { lat: 48.7639776, lng: 21.0548847 },
    { lat: 48.7655193, lng: 21.0578857 },
    { lat: 48.7654577, lng: 21.0603805 },
    { lat: 48.7656028, lng: 21.0622445 },
    { lat: 48.7658089, lng: 21.0633537 },
    { lat: 48.7669511, lng: 21.0628309 },
    { lat: 48.7675622, lng: 21.0636202 },
    { lat: 48.7681583, lng: 21.0633343 },
    { lat: 48.769312, lng: 21.0632302 },
    { lat: 48.7700113, lng: 21.0634835 },
    { lat: 48.7712019, lng: 21.0632393 },
    { lat: 48.7725582, lng: 21.0635857 },
    { lat: 48.7728841, lng: 21.0635707 },
    { lat: 48.7732355, lng: 21.0634397 },
    { lat: 48.7747561, lng: 21.0634784 },
    { lat: 48.7754904, lng: 21.0633881 },
    { lat: 48.7759601, lng: 21.0632607 },
    { lat: 48.7777699, lng: 21.0631187 },
    { lat: 48.7785292, lng: 21.0629334 },
    { lat: 48.778716, lng: 21.0629719 },
    { lat: 48.7795505, lng: 21.0628783 },
    { lat: 48.7796333, lng: 21.0629724 },
    { lat: 48.7815582, lng: 21.06305 },
    { lat: 48.7822967, lng: 21.0631912 },
    { lat: 48.7827725, lng: 21.0633232 },
    { lat: 48.7828381, lng: 21.0634036 },
    { lat: 48.7831828, lng: 21.0632694 },
    { lat: 48.7837717, lng: 21.0633126 },
    { lat: 48.7846431, lng: 21.0636307 },
    { lat: 48.7853633, lng: 21.0635627 },
    { lat: 48.7853735, lng: 21.0635663 },
    { lat: 48.7856029, lng: 21.0624827 },
    { lat: 48.785558, lng: 21.0622851 },
    { lat: 48.7857342, lng: 21.0617773 },
    { lat: 48.7857623, lng: 21.0615765 },
    { lat: 48.7860875, lng: 21.0609512 },
    { lat: 48.7861642, lng: 21.0607788 },
    { lat: 48.7864612, lng: 21.0604322 },
    { lat: 48.7868818, lng: 21.0602467 },
    { lat: 48.7872056, lng: 21.0598574 },
    { lat: 48.7875453, lng: 21.05964 },
    { lat: 48.787709, lng: 21.0596275 },
    { lat: 48.787908, lng: 21.0597575 },
    { lat: 48.7887404, lng: 21.0593607 },
    { lat: 48.7889124, lng: 21.0591451 },
    { lat: 48.7891548, lng: 21.0590917 },
    { lat: 48.7896253, lng: 21.058816 },
    { lat: 48.7899629, lng: 21.0585389 },
    { lat: 48.7900131, lng: 21.0584584 },
    { lat: 48.7900253, lng: 21.0582754 },
    { lat: 48.7914351, lng: 21.0571572 },
    { lat: 48.7918256, lng: 21.0570542 },
    { lat: 48.7921269, lng: 21.0570785 },
    { lat: 48.7924702, lng: 21.0569386 },
    { lat: 48.7930836, lng: 21.0564517 },
    { lat: 48.7932604, lng: 21.0564577 },
    { lat: 48.7935025, lng: 21.0562569 },
    { lat: 48.793938, lng: 21.0560795 },
    { lat: 48.7941465, lng: 21.0560889 },
    { lat: 48.7948323, lng: 21.0565499 },
    { lat: 48.7951221, lng: 21.0565615 },
    { lat: 48.7952518, lng: 21.0566796 },
    { lat: 48.7955099, lng: 21.0567607 },
    { lat: 48.7958254, lng: 21.056766 },
    { lat: 48.7966928, lng: 21.0573785 },
    { lat: 48.7968401, lng: 21.0576892 },
    { lat: 48.7971254, lng: 21.0578746 },
    { lat: 48.7972907, lng: 21.0577991 },
    { lat: 48.7975248, lng: 21.0578048 },
    { lat: 48.7977626, lng: 21.0578799 },
    { lat: 48.7978941, lng: 21.0578393 },
    { lat: 48.7982733, lng: 21.0579114 },
    { lat: 48.7983721, lng: 21.0578297 },
    { lat: 48.7985734, lng: 21.0578272 },
    { lat: 48.7987959, lng: 21.057952 },
    { lat: 48.7990876, lng: 21.0578563 },
    { lat: 48.7993633, lng: 21.0582334 },
    { lat: 48.7997317, lng: 21.0582117 },
    { lat: 48.8002276, lng: 21.0580565 },
    { lat: 48.8010427, lng: 21.058 },
    { lat: 48.8014234, lng: 21.0580369 },
    { lat: 48.8015137, lng: 21.0577388 },
    { lat: 48.8016133, lng: 21.0575836 },
    { lat: 48.8019751, lng: 21.0575612 },
    { lat: 48.8021539, lng: 21.0572805 },
    { lat: 48.8023713, lng: 21.0571104 },
    { lat: 48.8029291, lng: 21.0569966 },
    { lat: 48.802996, lng: 21.0569074 },
    { lat: 48.8031629, lng: 21.056846 },
    { lat: 48.8036064, lng: 21.0569214 },
    { lat: 48.8038301, lng: 21.056809 },
    { lat: 48.8041414, lng: 21.056448 },
    { lat: 48.8042723, lng: 21.0564261 },
    { lat: 48.804488, lng: 21.0565839 },
    { lat: 48.8046327, lng: 21.0565717 },
    { lat: 48.8047062, lng: 21.0564087 },
    { lat: 48.804895, lng: 21.0562975 },
    { lat: 48.8050154, lng: 21.056331 },
    { lat: 48.8051049, lng: 21.0564511 },
    { lat: 48.8052239, lng: 21.0564383 },
    { lat: 48.8052811, lng: 21.0562972 },
    { lat: 48.8054245, lng: 21.0562408 },
    { lat: 48.8056384, lng: 21.0564495 },
    { lat: 48.8058208, lng: 21.0564887 },
    { lat: 48.8059728, lng: 21.0566305 },
    { lat: 48.8062177, lng: 21.0567479 },
    { lat: 48.8064288, lng: 21.0567703 },
    { lat: 48.8067671, lng: 21.0566876 },
    { lat: 48.806931, lng: 21.0565361 },
    { lat: 48.8070936, lng: 21.056624 },
    { lat: 48.8073613, lng: 21.0564447 },
    { lat: 48.8074604, lng: 21.056483 },
    { lat: 48.80758, lng: 21.0568747 },
    { lat: 48.8077423, lng: 21.056773 },
    { lat: 48.8082458, lng: 21.0568532 },
    { lat: 48.8086454, lng: 21.0568248 },
    { lat: 48.8087258, lng: 21.0571148 },
    { lat: 48.8088247, lng: 21.057176 },
    { lat: 48.8091847, lng: 21.0571713 },
    { lat: 48.8095377, lng: 21.0575727 },
    { lat: 48.8098039, lng: 21.0577314 },
    { lat: 48.8099853, lng: 21.0577046 },
    { lat: 48.8102998, lng: 21.0578215 },
    { lat: 48.810386, lng: 21.0578757 },
    { lat: 48.8106645, lng: 21.0584495 },
    { lat: 48.8110323, lng: 21.0584024 },
    { lat: 48.8111283, lng: 21.0581046 },
    { lat: 48.8113788, lng: 21.0581548 },
    { lat: 48.8117394, lng: 21.0580693 },
    { lat: 48.8120436, lng: 21.0580738 },
    { lat: 48.8121469, lng: 21.0581678 },
    { lat: 48.8122624, lng: 21.0584002 },
    { lat: 48.8129774, lng: 21.0591004 },
    { lat: 48.8133052, lng: 21.0596287 },
    { lat: 48.8132255, lng: 21.0602935 },
    { lat: 48.8134734, lng: 21.0605132 },
    { lat: 48.8135165, lng: 21.0609024 },
    { lat: 48.8136831, lng: 21.0610082 },
    { lat: 48.8140627, lng: 21.0610478 },
    { lat: 48.8143266, lng: 21.0613077 },
    { lat: 48.8147804, lng: 21.0612442 },
    { lat: 48.8151859, lng: 21.0614993 },
    { lat: 48.8153076, lng: 21.0614352 },
    { lat: 48.8155509, lng: 21.0616482 },
    { lat: 48.8157246, lng: 21.061898 },
    { lat: 48.8161031, lng: 21.0619477 },
    { lat: 48.8163242, lng: 21.0621701 },
    { lat: 48.8164655, lng: 21.0621192 },
    { lat: 48.8165636, lng: 21.062264 },
    { lat: 48.817102, lng: 21.0627278 },
    { lat: 48.817213, lng: 21.0626459 },
    { lat: 48.8173158, lng: 21.0626519 },
    { lat: 48.8174045, lng: 21.062891 },
    { lat: 48.8174753, lng: 21.0633007 },
    { lat: 48.8177225, lng: 21.0633604 },
    { lat: 48.8177814, lng: 21.0638568 },
    { lat: 48.818191, lng: 21.0642344 },
    { lat: 48.8183799, lng: 21.0640593 },
    { lat: 48.8186407, lng: 21.0640413 },
    { lat: 48.8187935, lng: 21.064181 },
    { lat: 48.8189076, lng: 21.064396 },
    { lat: 48.8192937, lng: 21.0647073 },
    { lat: 48.8194808, lng: 21.0649651 },
    { lat: 48.8195795, lng: 21.0649553 },
    { lat: 48.8198565, lng: 21.0653343 },
    { lat: 48.820175, lng: 21.0655788 },
    { lat: 48.8205731, lng: 21.0657441 },
    { lat: 48.8206843, lng: 21.0656701 },
    { lat: 48.8208838, lng: 21.0656752 },
    { lat: 48.8211234, lng: 21.0657777 },
    { lat: 48.821292, lng: 21.0656288 },
    { lat: 48.8214998, lng: 21.0656168 },
    { lat: 48.8216617, lng: 21.0658152 },
    { lat: 48.8218233, lng: 21.0658385 },
    { lat: 48.8221601, lng: 21.065673 },
    { lat: 48.8223871, lng: 21.065835 },
  ],
  Paňovce: [
    { lat: 48.6239514, lng: 21.0942224 },
    { lat: 48.624219, lng: 21.094213 },
    { lat: 48.6268901, lng: 21.0941191 },
    { lat: 48.6322675, lng: 21.0953559 },
    { lat: 48.6326324, lng: 21.0955375 },
    { lat: 48.6337309, lng: 21.0965117 },
    { lat: 48.6341358, lng: 21.0967449 },
    { lat: 48.6343182, lng: 21.0969588 },
    { lat: 48.635378, lng: 21.0976409 },
    { lat: 48.6356414, lng: 21.0978863 },
    { lat: 48.6361859, lng: 21.0980156 },
    { lat: 48.6368144, lng: 21.0984142 },
    { lat: 48.6379208, lng: 21.0989061 },
    { lat: 48.6381855, lng: 21.0992207 },
    { lat: 48.6384841, lng: 21.0994397 },
    { lat: 48.639016, lng: 21.0996329 },
    { lat: 48.6396835, lng: 21.1001223 },
    { lat: 48.6406258, lng: 21.1005188 },
    { lat: 48.6412295, lng: 21.1005289 },
    { lat: 48.6416078, lng: 21.1004263 },
    { lat: 48.6417764, lng: 21.1002812 },
    { lat: 48.6421218, lng: 21.1001732 },
    { lat: 48.6428443, lng: 21.1003464 },
    { lat: 48.6438316, lng: 21.1003546 },
    { lat: 48.6444639, lng: 21.1002063 },
    { lat: 48.6447964, lng: 21.1000089 },
    { lat: 48.6454083, lng: 21.0999966 },
    { lat: 48.6462159, lng: 21.0998073 },
    { lat: 48.6465132, lng: 21.0998121 },
    { lat: 48.6466761, lng: 21.0997368 },
    { lat: 48.6468409, lng: 21.0999247 },
    { lat: 48.6468079, lng: 21.0999823 },
    { lat: 48.6468836, lng: 21.1001301 },
    { lat: 48.6480205, lng: 21.0994022 },
    { lat: 48.6481265, lng: 21.0990243 },
    { lat: 48.6483042, lng: 21.0991244 },
    { lat: 48.6483933, lng: 21.0990623 },
    { lat: 48.6484521, lng: 21.0992117 },
    { lat: 48.6486231, lng: 21.0992792 },
    { lat: 48.6487601, lng: 21.0990581 },
    { lat: 48.6489511, lng: 21.0991152 },
    { lat: 48.6490408, lng: 21.0990381 },
    { lat: 48.6491891, lng: 21.0988155 },
    { lat: 48.6492912, lng: 21.0984573 },
    { lat: 48.6492633, lng: 21.098223 },
    { lat: 48.6493707, lng: 21.0977866 },
    { lat: 48.6496124, lng: 21.0975321 },
    { lat: 48.6497641, lng: 21.0974586 },
    { lat: 48.6500677, lng: 21.0974473 },
    { lat: 48.6503146, lng: 21.0971109 },
    { lat: 48.6504056, lng: 21.0971733 },
    { lat: 48.6510436, lng: 21.0972395 },
    { lat: 48.6515147, lng: 21.0969806 },
    { lat: 48.6516719, lng: 21.0970078 },
    { lat: 48.6519151, lng: 21.0969336 },
    { lat: 48.6521557, lng: 21.097038 },
    { lat: 48.652262, lng: 21.0969106 },
    { lat: 48.6523496, lng: 21.0969369 },
    { lat: 48.6524619, lng: 21.0968268 },
    { lat: 48.6527063, lng: 21.0964144 },
    { lat: 48.6533631, lng: 21.0960186 },
    { lat: 48.6537396, lng: 21.0961749 },
    { lat: 48.6539692, lng: 21.0961039 },
    { lat: 48.654533, lng: 21.0962414 },
    { lat: 48.6550183, lng: 21.0962096 },
    { lat: 48.6553467, lng: 21.0957526 },
    { lat: 48.6557677, lng: 21.0957141 },
    { lat: 48.6558598, lng: 21.0956468 },
    { lat: 48.6559116, lng: 21.0955059 },
    { lat: 48.6567028, lng: 21.0949115 },
    { lat: 48.6571044, lng: 21.0936847 },
    { lat: 48.6574397, lng: 21.0924642 },
    { lat: 48.6581689, lng: 21.0908974 },
    { lat: 48.6595107, lng: 21.0886111 },
    { lat: 48.6598525, lng: 21.0881106 },
    { lat: 48.6604143, lng: 21.0875298 },
    { lat: 48.6614904, lng: 21.0859675 },
    { lat: 48.6616249, lng: 21.0853961 },
    { lat: 48.6616222, lng: 21.0852404 },
    { lat: 48.661789, lng: 21.0851469 },
    { lat: 48.6619181, lng: 21.0852723 },
    { lat: 48.6620969, lng: 21.085128 },
    { lat: 48.6623318, lng: 21.0851876 },
    { lat: 48.6622549, lng: 21.0850053 },
    { lat: 48.6622729, lng: 21.0849338 },
    { lat: 48.6625322, lng: 21.0850891 },
    { lat: 48.6626976, lng: 21.0853973 },
    { lat: 48.6628671, lng: 21.0853164 },
    { lat: 48.6630095, lng: 21.0853654 },
    { lat: 48.6631125, lng: 21.0852463 },
    { lat: 48.6631185, lng: 21.0850803 },
    { lat: 48.6631798, lng: 21.0850313 },
    { lat: 48.6633675, lng: 21.085262 },
    { lat: 48.6635281, lng: 21.0853573 },
    { lat: 48.6636478, lng: 21.0853004 },
    { lat: 48.6636808, lng: 21.0853817 },
    { lat: 48.6637697, lng: 21.0853818 },
    { lat: 48.664, lng: 21.0851227 },
    { lat: 48.6639837, lng: 21.0849673 },
    { lat: 48.6644255, lng: 21.0847452 },
    { lat: 48.6645804, lng: 21.0845604 },
    { lat: 48.6647465, lng: 21.0845759 },
    { lat: 48.665069, lng: 21.0844577 },
    { lat: 48.6651501, lng: 21.0842512 },
    { lat: 48.6651194, lng: 21.0841036 },
    { lat: 48.6651961, lng: 21.0838302 },
    { lat: 48.6654471, lng: 21.0837579 },
    { lat: 48.6654754, lng: 21.0836297 },
    { lat: 48.6656052, lng: 21.0836002 },
    { lat: 48.6659083, lng: 21.0837541 },
    { lat: 48.6661821, lng: 21.0834134 },
    { lat: 48.66625, lng: 21.0834621 },
    { lat: 48.6663486, lng: 21.0833768 },
    { lat: 48.6663219, lng: 21.0828362 },
    { lat: 48.6666696, lng: 21.0823803 },
    { lat: 48.6665785, lng: 21.0822108 },
    { lat: 48.6665987, lng: 21.0821356 },
    { lat: 48.6668404, lng: 21.0822299 },
    { lat: 48.6671755, lng: 21.0818046 },
    { lat: 48.6672694, lng: 21.0817211 },
    { lat: 48.6674124, lng: 21.0817208 },
    { lat: 48.6677534, lng: 21.0811615 },
    { lat: 48.6678099, lng: 21.080787 },
    { lat: 48.6681286, lng: 21.0806206 },
    { lat: 48.6684016, lng: 21.0809166 },
    { lat: 48.6685167, lng: 21.0808946 },
    { lat: 48.669038, lng: 21.0804469 },
    { lat: 48.6692625, lng: 21.0804303 },
    { lat: 48.6694783, lng: 21.080279 },
    { lat: 48.6695996, lng: 21.0802743 },
    { lat: 48.669566, lng: 21.0800671 },
    { lat: 48.6693621, lng: 21.0796844 },
    { lat: 48.6692004, lng: 21.0790355 },
    { lat: 48.6688997, lng: 21.0763252 },
    { lat: 48.6686397, lng: 21.0758438 },
    { lat: 48.6685906, lng: 21.0756124 },
    { lat: 48.66861, lng: 21.0754447 },
    { lat: 48.6684161, lng: 21.0739859 },
    { lat: 48.6682715, lng: 21.0724484 },
    { lat: 48.667741, lng: 21.0686296 },
    { lat: 48.6676056, lng: 21.067064 },
    { lat: 48.6675605, lng: 21.0662662 },
    { lat: 48.668126, lng: 21.0665233 },
    { lat: 48.668138, lng: 21.0664797 },
    { lat: 48.6683051, lng: 21.063286 },
    { lat: 48.6683832, lng: 21.0593605 },
    { lat: 48.6685122, lng: 21.0592163 },
    { lat: 48.6690783, lng: 21.0590712 },
    { lat: 48.669934, lng: 21.0580109 },
    { lat: 48.6700436, lng: 21.0577488 },
    { lat: 48.6706252, lng: 21.0567747 },
    { lat: 48.6711, lng: 21.0565282 },
    { lat: 48.671133, lng: 21.0563694 },
    { lat: 48.67133, lng: 21.0561603 },
    { lat: 48.6714663, lng: 21.0560967 },
    { lat: 48.6715682, lng: 21.0558373 },
    { lat: 48.6715476, lng: 21.0556889 },
    { lat: 48.671705, lng: 21.055549 },
    { lat: 48.6719236, lng: 21.0555894 },
    { lat: 48.6721991, lng: 21.055202 },
    { lat: 48.6723627, lng: 21.0551482 },
    { lat: 48.6723676, lng: 21.0549883 },
    { lat: 48.672445, lng: 21.0549822 },
    { lat: 48.6725135, lng: 21.0547321 },
    { lat: 48.6728338, lng: 21.0546815 },
    { lat: 48.672951, lng: 21.054552 },
    { lat: 48.6731063, lng: 21.0540693 },
    { lat: 48.6734862, lng: 21.0539167 },
    { lat: 48.6737884, lng: 21.0534861 },
    { lat: 48.6740957, lng: 21.0532392 },
    { lat: 48.6741111, lng: 21.0531618 },
    { lat: 48.6742922, lng: 21.053091 },
    { lat: 48.6742449, lng: 21.0528491 },
    { lat: 48.6744033, lng: 21.0528089 },
    { lat: 48.6743978, lng: 21.0526689 },
    { lat: 48.6743161, lng: 21.0525144 },
    { lat: 48.6744743, lng: 21.0522893 },
    { lat: 48.6745235, lng: 21.0520784 },
    { lat: 48.6744025, lng: 21.0519895 },
    { lat: 48.6745116, lng: 21.0518869 },
    { lat: 48.674439, lng: 21.0515932 },
    { lat: 48.674635, lng: 21.0514924 },
    { lat: 48.6747651, lng: 21.0513079 },
    { lat: 48.6748923, lng: 21.0512756 },
    { lat: 48.6749216, lng: 21.0511331 },
    { lat: 48.6750541, lng: 21.051022 },
    { lat: 48.6751048, lng: 21.0508865 },
    { lat: 48.6753294, lng: 21.0509082 },
    { lat: 48.6753417, lng: 21.0510757 },
    { lat: 48.6754527, lng: 21.051096 },
    { lat: 48.6754913, lng: 21.051243 },
    { lat: 48.6756446, lng: 21.0511846 },
    { lat: 48.6757862, lng: 21.0509358 },
    { lat: 48.6757669, lng: 21.0508289 },
    { lat: 48.6759501, lng: 21.0508192 },
    { lat: 48.6760132, lng: 21.0507507 },
    { lat: 48.6760248, lng: 21.050658 },
    { lat: 48.6761487, lng: 21.0505618 },
    { lat: 48.6762423, lng: 21.0503579 },
    { lat: 48.6762715, lng: 21.0501474 },
    { lat: 48.6765828, lng: 21.0497563 },
    { lat: 48.6768553, lng: 21.0497249 },
    { lat: 48.6770296, lng: 21.049515 },
    { lat: 48.6771202, lng: 21.0491179 },
    { lat: 48.6772838, lng: 21.0488937 },
    { lat: 48.6773856, lng: 21.0489763 },
    { lat: 48.6776765, lng: 21.0488862 },
    { lat: 48.6778437, lng: 21.0486604 },
    { lat: 48.6779893, lng: 21.0486431 },
    { lat: 48.6780371, lng: 21.0484889 },
    { lat: 48.6782957, lng: 21.0484421 },
    { lat: 48.6785194, lng: 21.0485754 },
    { lat: 48.6786936, lng: 21.0485271 },
    { lat: 48.6789743, lng: 21.0481689 },
    { lat: 48.6792367, lng: 21.048202 },
    { lat: 48.6794217, lng: 21.0480831 },
    { lat: 48.6796238, lng: 21.0481325 },
    { lat: 48.6797838, lng: 21.0480347 },
    { lat: 48.6799253, lng: 21.0481253 },
    { lat: 48.6802304, lng: 21.0480962 },
    { lat: 48.6802927, lng: 21.0477282 },
    { lat: 48.680378, lng: 21.0477254 },
    { lat: 48.6805123, lng: 21.0478742 },
    { lat: 48.6805762, lng: 21.0478292 },
    { lat: 48.6807699, lng: 21.0474208 },
    { lat: 48.6808934, lng: 21.0474458 },
    { lat: 48.6810528, lng: 21.0472936 },
    { lat: 48.6810614, lng: 21.0472121 },
    { lat: 48.6812034, lng: 21.0472179 },
    { lat: 48.6813625, lng: 21.0468202 },
    { lat: 48.6817827, lng: 21.0465919 },
    { lat: 48.6819182, lng: 21.0463962 },
    { lat: 48.6821348, lng: 21.0462404 },
    { lat: 48.6823234, lng: 21.0464311 },
    { lat: 48.6823411, lng: 21.0465616 },
    { lat: 48.682462, lng: 21.0466096 },
    { lat: 48.6828427, lng: 21.0463072 },
    { lat: 48.6831827, lng: 21.0463477 },
    { lat: 48.6833312, lng: 21.0464435 },
    { lat: 48.6834348, lng: 21.0463467 },
    { lat: 48.6837817, lng: 21.0464156 },
    { lat: 48.6838649, lng: 21.046451 },
    { lat: 48.6837652, lng: 21.0454358 },
    { lat: 48.6834849, lng: 21.0442956 },
    { lat: 48.6833951, lng: 21.0441341 },
    { lat: 48.683096, lng: 21.043837 },
    { lat: 48.6829279, lng: 21.0437149 },
    { lat: 48.6825501, lng: 21.043632 },
    { lat: 48.6821008, lng: 21.0432075 },
    { lat: 48.6818536, lng: 21.0430493 },
    { lat: 48.6815242, lng: 21.0430284 },
    { lat: 48.6812662, lng: 21.043089 },
    { lat: 48.6804849, lng: 21.0430466 },
    { lat: 48.6800947, lng: 21.0428221 },
    { lat: 48.6797375, lng: 21.0430134 },
    { lat: 48.6795041, lng: 21.0429015 },
    { lat: 48.6792813, lng: 21.0424851 },
    { lat: 48.6791172, lng: 21.0420482 },
    { lat: 48.678848, lng: 21.0418001 },
    { lat: 48.6782829, lng: 21.0410827 },
    { lat: 48.6775501, lng: 21.0408725 },
    { lat: 48.6768475, lng: 21.0402505 },
    { lat: 48.6766361, lng: 21.039945 },
    { lat: 48.6765466, lng: 21.0399638 },
    { lat: 48.6764536, lng: 21.039766 },
    { lat: 48.6762181, lng: 21.039654 },
    { lat: 48.6761643, lng: 21.0395266 },
    { lat: 48.6761995, lng: 21.0394331 },
    { lat: 48.6761659, lng: 21.0390553 },
    { lat: 48.676095, lng: 21.0390236 },
    { lat: 48.6760559, lng: 21.0388676 },
    { lat: 48.6759775, lng: 21.0388429 },
    { lat: 48.6759118, lng: 21.0386889 },
    { lat: 48.6759625, lng: 21.038558 },
    { lat: 48.6759247, lng: 21.0383621 },
    { lat: 48.6745846, lng: 21.0378591 },
    { lat: 48.6739025, lng: 21.037278 },
    { lat: 48.6730286, lng: 21.0368526 },
    { lat: 48.6722013, lng: 21.0366838 },
    { lat: 48.6714398, lng: 21.0363558 },
    { lat: 48.6696667, lng: 21.036688 },
    { lat: 48.6680874, lng: 21.0374005 },
    { lat: 48.6667058, lng: 21.0373172 },
    { lat: 48.6662897, lng: 21.0374655 },
    { lat: 48.6662129, lng: 21.0374186 },
    { lat: 48.6661724, lng: 21.0375562 },
    { lat: 48.6658714, lng: 21.0375766 },
    { lat: 48.6657806, lng: 21.0375193 },
    { lat: 48.6657888, lng: 21.0373955 },
    { lat: 48.6657008, lng: 21.0373529 },
    { lat: 48.6654669, lng: 21.0375687 },
    { lat: 48.6652996, lng: 21.037583 },
    { lat: 48.6652879, lng: 21.0374977 },
    { lat: 48.6635941, lng: 21.0373649 },
    { lat: 48.6626322, lng: 21.036525 },
    { lat: 48.6620997, lng: 21.0363811 },
    { lat: 48.6617833, lng: 21.0366145 },
    { lat: 48.6616402, lng: 21.0366473 },
    { lat: 48.660393, lng: 21.0361653 },
    { lat: 48.6600508, lng: 21.0361592 },
    { lat: 48.6599015, lng: 21.0362462 },
    { lat: 48.6595658, lng: 21.036166 },
    { lat: 48.659402, lng: 21.0362466 },
    { lat: 48.6590233, lng: 21.0360641 },
    { lat: 48.6584894, lng: 21.0360172 },
    { lat: 48.6584655, lng: 21.0360147 },
    { lat: 48.6583967, lng: 21.0360078 },
    { lat: 48.6581512, lng: 21.0357655 },
    { lat: 48.6576771, lng: 21.035649 },
    { lat: 48.655246, lng: 21.0341816 },
    { lat: 48.6548725, lng: 21.0342209 },
    { lat: 48.6537479, lng: 21.0342144 },
    { lat: 48.6531628, lng: 21.0343244 },
    { lat: 48.6520548, lng: 21.0347202 },
    { lat: 48.6512817, lng: 21.0347645 },
    { lat: 48.6509913, lng: 21.0348635 },
    { lat: 48.6499939, lng: 21.0348235 },
    { lat: 48.6496773, lng: 21.0348843 },
    { lat: 48.6489876, lng: 21.0352342 },
    { lat: 48.6478912, lng: 21.0360555 },
    { lat: 48.6473422, lng: 21.0362674 },
    { lat: 48.6464418, lng: 21.0368876 },
    { lat: 48.6460391, lng: 21.0373021 },
    { lat: 48.6452077, lng: 21.0379701 },
    { lat: 48.6437626, lng: 21.0386896 },
    { lat: 48.6426117, lng: 21.0389134 },
    { lat: 48.6421252, lng: 21.0391117 },
    { lat: 48.6409237, lng: 21.0393691 },
    { lat: 48.6401622, lng: 21.0393143 },
    { lat: 48.6388404, lng: 21.03937 },
    { lat: 48.6386618, lng: 21.0394819 },
    { lat: 48.6378961, lng: 21.040226 },
    { lat: 48.6370563, lng: 21.0405026 },
    { lat: 48.6365909, lng: 21.0407609 },
    { lat: 48.6357265, lng: 21.0417169 },
    { lat: 48.6352645, lng: 21.0424595 },
    { lat: 48.6348599, lng: 21.0428235 },
    { lat: 48.633894, lng: 21.0429504 },
    { lat: 48.6334272, lng: 21.0433725 },
    { lat: 48.6328042, lng: 21.0435923 },
    { lat: 48.6322266, lng: 21.0436233 },
    { lat: 48.6321744, lng: 21.0443958 },
    { lat: 48.6320601, lng: 21.0448591 },
    { lat: 48.6318247, lng: 21.0453356 },
    { lat: 48.6312541, lng: 21.046778 },
    { lat: 48.6309358, lng: 21.0477839 },
    { lat: 48.6308414, lng: 21.0482366 },
    { lat: 48.6307739, lng: 21.0486859 },
    { lat: 48.630753, lng: 21.0501086 },
    { lat: 48.6309684, lng: 21.0523204 },
    { lat: 48.6308393, lng: 21.0528082 },
    { lat: 48.6311373, lng: 21.0545199 },
    { lat: 48.6302989, lng: 21.0549794 },
    { lat: 48.6308536, lng: 21.0573421 },
    { lat: 48.6303821, lng: 21.0576974 },
    { lat: 48.6301205, lng: 21.0579894 },
    { lat: 48.6304092, lng: 21.05828 },
    { lat: 48.6302084, lng: 21.0585074 },
    { lat: 48.6302342, lng: 21.0589876 },
    { lat: 48.6301408, lng: 21.0593354 },
    { lat: 48.630196, lng: 21.0597088 },
    { lat: 48.630387, lng: 21.0598373 },
    { lat: 48.6303481, lng: 21.0600256 },
    { lat: 48.6300098, lng: 21.060045 },
    { lat: 48.6298102, lng: 21.060167 },
    { lat: 48.6297045, lng: 21.0603107 },
    { lat: 48.629751, lng: 21.0609755 },
    { lat: 48.6295594, lng: 21.0612477 },
    { lat: 48.6296527, lng: 21.0614461 },
    { lat: 48.6294832, lng: 21.0618027 },
    { lat: 48.6296942, lng: 21.0629573 },
    { lat: 48.6296422, lng: 21.0631274 },
    { lat: 48.6297553, lng: 21.0632471 },
    { lat: 48.6297251, lng: 21.0638385 },
    { lat: 48.6289746, lng: 21.0658775 },
    { lat: 48.6286176, lng: 21.0663584 },
    { lat: 48.6284586, lng: 21.0664506 },
    { lat: 48.6275028, lng: 21.066065 },
    { lat: 48.6269218, lng: 21.0661256 },
    { lat: 48.6264164, lng: 21.0660282 },
    { lat: 48.6263306, lng: 21.0672951 },
    { lat: 48.6260449, lng: 21.0680937 },
    { lat: 48.6256349, lng: 21.0696704 },
    { lat: 48.6255435, lng: 21.0697327 },
    { lat: 48.6254267, lng: 21.0697086 },
    { lat: 48.6254792, lng: 21.0694887 },
    { lat: 48.625279, lng: 21.0692873 },
    { lat: 48.6251864, lng: 21.0694507 },
    { lat: 48.6250696, lng: 21.069528 },
    { lat: 48.6250115, lng: 21.0695021 },
    { lat: 48.6249636, lng: 21.0692779 },
    { lat: 48.6248252, lng: 21.0692134 },
    { lat: 48.6248016, lng: 21.069387 },
    { lat: 48.624765, lng: 21.0694011 },
    { lat: 48.6246275, lng: 21.0692203 },
    { lat: 48.6244792, lng: 21.069301 },
    { lat: 48.6244013, lng: 21.0691559 },
    { lat: 48.6245103, lng: 21.0690475 },
    { lat: 48.6243828, lng: 21.0689719 },
    { lat: 48.6243661, lng: 21.06877 },
    { lat: 48.6240472, lng: 21.0690641 },
    { lat: 48.6237909, lng: 21.0689027 },
    { lat: 48.6237501, lng: 21.0688207 },
    { lat: 48.6236823, lng: 21.0688552 },
    { lat: 48.6235809, lng: 21.0687763 },
    { lat: 48.6233811, lng: 21.0688623 },
    { lat: 48.6232578, lng: 21.0688109 },
    { lat: 48.6231173, lng: 21.0688882 },
    { lat: 48.6226661, lng: 21.0705155 },
    { lat: 48.6229782, lng: 21.0719724 },
    { lat: 48.6225509, lng: 21.0719183 },
    { lat: 48.6223958, lng: 21.0751099 },
    { lat: 48.6225845, lng: 21.0778641 },
    { lat: 48.6226782, lng: 21.0786004 },
    { lat: 48.6226967, lng: 21.0802444 },
    { lat: 48.6228145, lng: 21.080298 },
    { lat: 48.6230969, lng: 21.0836622 },
    { lat: 48.6232572, lng: 21.0850272 },
    { lat: 48.6238183, lng: 21.0888316 },
    { lat: 48.6239864, lng: 21.0909915 },
    { lat: 48.6239556, lng: 21.0930697 },
    { lat: 48.6239514, lng: 21.0942224 },
  ],
  Mokrance: [
    { lat: 48.6403943, lng: 21.0225631 },
    { lat: 48.6403568, lng: 21.0224858 },
    { lat: 48.6402639, lng: 21.0223287 },
    { lat: 48.640134, lng: 21.0222452 },
    { lat: 48.6396407, lng: 21.0222043 },
    { lat: 48.6386289, lng: 21.0218646 },
    { lat: 48.6380959, lng: 21.0217797 },
    { lat: 48.6374999, lng: 21.0215867 },
    { lat: 48.6360719, lng: 21.0213627 },
    { lat: 48.6357559, lng: 21.0214092 },
    { lat: 48.6354095, lng: 21.021653 },
    { lat: 48.6351781, lng: 21.0217312 },
    { lat: 48.6345971, lng: 21.0216574 },
    { lat: 48.6333087, lng: 21.0216417 },
    { lat: 48.6327903, lng: 21.0215482 },
    { lat: 48.6323921, lng: 21.0217325 },
    { lat: 48.6320418, lng: 21.0217517 },
    { lat: 48.6313257, lng: 21.0214742 },
    { lat: 48.630896, lng: 21.021173 },
    { lat: 48.630711, lng: 21.0207557 },
    { lat: 48.6302632, lng: 21.0200449 },
    { lat: 48.628747, lng: 21.0192924 },
    { lat: 48.6284417, lng: 21.0190492 },
    { lat: 48.6278019, lng: 21.0186948 },
    { lat: 48.6274804, lng: 21.018429 },
    { lat: 48.6270541, lng: 21.0178971 },
    { lat: 48.6268974, lng: 21.0177893 },
    { lat: 48.6258997, lng: 21.0175323 },
    { lat: 48.625489, lng: 21.0177436 },
    { lat: 48.6244136, lng: 21.017761 },
    { lat: 48.6244011, lng: 21.0178271 },
    { lat: 48.623376, lng: 21.0175256 },
    { lat: 48.6231097, lng: 21.0172695 },
    { lat: 48.6222535, lng: 21.0161907 },
    { lat: 48.6222035, lng: 21.0162486 },
    { lat: 48.6211593, lng: 21.0159464 },
    { lat: 48.6204881, lng: 21.015565 },
    { lat: 48.619742, lng: 21.015027 },
    { lat: 48.6185711, lng: 21.0146404 },
    { lat: 48.6176746, lng: 21.0140775 },
    { lat: 48.6169054, lng: 21.0137255 },
    { lat: 48.6160497, lng: 21.0135477 },
    { lat: 48.61384, lng: 21.0133745 },
    { lat: 48.6125313, lng: 21.0136558 },
    { lat: 48.6121114, lng: 21.0136198 },
    { lat: 48.6112851, lng: 21.0137161 },
    { lat: 48.6096071, lng: 21.0136591 },
    { lat: 48.6084225, lng: 21.013713 },
    { lat: 48.6083121, lng: 21.0116296 },
    { lat: 48.6077135, lng: 21.0116376 },
    { lat: 48.6075381, lng: 21.0104863 },
    { lat: 48.6073336, lng: 21.0099604 },
    { lat: 48.607227, lng: 21.0092898 },
    { lat: 48.6068377, lng: 21.0085833 },
    { lat: 48.6067374, lng: 21.0081324 },
    { lat: 48.606281, lng: 21.0045169 },
    { lat: 48.605544, lng: 21.0014047 },
    { lat: 48.6046364, lng: 21.0000694 },
    { lat: 48.6044433, lng: 20.9993776 },
    { lat: 48.6044888, lng: 20.9992184 },
    { lat: 48.6037563, lng: 20.9989533 },
    { lat: 48.6031208, lng: 20.998424 },
    { lat: 48.6023252, lng: 20.9980616 },
    { lat: 48.6022675, lng: 20.9986613 },
    { lat: 48.602081, lng: 20.9996864 },
    { lat: 48.6020163, lng: 21.0004527 },
    { lat: 48.6012776, lng: 21.0005465 },
    { lat: 48.5987139, lng: 21.0012321 },
    { lat: 48.5971052, lng: 21.0020409 },
    { lat: 48.5964681, lng: 21.0022105 },
    { lat: 48.596081, lng: 21.0023887 },
    { lat: 48.5934918, lng: 21.0038985 },
    { lat: 48.5926266, lng: 21.0045611 },
    { lat: 48.5899496, lng: 21.0054672 },
    { lat: 48.5897386, lng: 21.0055495 },
    { lat: 48.5889366, lng: 21.0060803 },
    { lat: 48.5884117, lng: 21.006169 },
    { lat: 48.58794, lng: 21.0060989 },
    { lat: 48.5874435, lng: 21.0057875 },
    { lat: 48.5873203, lng: 21.0058761 },
    { lat: 48.5843539, lng: 21.0058962 },
    { lat: 48.583124, lng: 21.0060436 },
    { lat: 48.5817117, lng: 21.0059893 },
    { lat: 48.5806175, lng: 21.0058104 },
    { lat: 48.5800629, lng: 21.0056524 },
    { lat: 48.5778898, lng: 21.0045755 },
    { lat: 48.5776843, lng: 21.0043905 },
    { lat: 48.5774279, lng: 21.003893 },
    { lat: 48.5771704, lng: 21.0037308 },
    { lat: 48.5762494, lng: 21.0034127 },
    { lat: 48.5758233, lng: 21.0033602 },
    { lat: 48.5754649, lng: 21.0031089 },
    { lat: 48.5746424, lng: 21.0021042 },
    { lat: 48.5732719, lng: 21.0005903 },
    { lat: 48.5728866, lng: 21.000281 },
    { lat: 48.5722936, lng: 20.9999831 },
    { lat: 48.5708874, lng: 20.9997359 },
    { lat: 48.5700281, lng: 20.999516 },
    { lat: 48.5691989, lng: 20.9991578 },
    { lat: 48.5684619, lng: 20.9987205 },
    { lat: 48.567856, lng: 20.9985085 },
    { lat: 48.5671931, lng: 20.9981374 },
    { lat: 48.5666422, lng: 20.9980535 },
    { lat: 48.5649916, lng: 20.9976365 },
    { lat: 48.5627972, lng: 20.9972516 },
    { lat: 48.5602836, lng: 20.9971741 },
    { lat: 48.5598691, lng: 20.9971378 },
    { lat: 48.559504, lng: 20.998139 },
    { lat: 48.5592318, lng: 20.998856 },
    { lat: 48.5579244, lng: 20.9995807 },
    { lat: 48.5573335, lng: 20.99982 },
    { lat: 48.5571906, lng: 21.000028 },
    { lat: 48.5568868, lng: 21.0009776 },
    { lat: 48.5568157, lng: 21.0035465 },
    { lat: 48.5567002, lng: 21.003832 },
    { lat: 48.5566187, lng: 21.0046668 },
    { lat: 48.5563397, lng: 21.0055562 },
    { lat: 48.5564704, lng: 21.0057282 },
    { lat: 48.5564641, lng: 21.005879 },
    { lat: 48.5563191, lng: 21.0061518 },
    { lat: 48.5559923, lng: 21.0062496 },
    { lat: 48.5558232, lng: 21.0063707 },
    { lat: 48.5557205, lng: 21.0065372 },
    { lat: 48.5557281, lng: 21.0072714 },
    { lat: 48.555655, lng: 21.0095481 },
    { lat: 48.5560777, lng: 21.0110924 },
    { lat: 48.5558926, lng: 21.0112883 },
    { lat: 48.5553394, lng: 21.0114414 },
    { lat: 48.5552382, lng: 21.0115421 },
    { lat: 48.5550322, lng: 21.0119499 },
    { lat: 48.5550702, lng: 21.0122142 },
    { lat: 48.5550256, lng: 21.0124009 },
    { lat: 48.5551001, lng: 21.0125413 },
    { lat: 48.555564, lng: 21.0128827 },
    { lat: 48.5558502, lng: 21.0133668 },
    { lat: 48.5559048, lng: 21.0138644 },
    { lat: 48.5557402, lng: 21.0140224 },
    { lat: 48.556113, lng: 21.0146436 },
    { lat: 48.5562061, lng: 21.0149407 },
    { lat: 48.5561828, lng: 21.0151489 },
    { lat: 48.5558552, lng: 21.015321 },
    { lat: 48.5558349, lng: 21.0153854 },
    { lat: 48.5559609, lng: 21.0161338 },
    { lat: 48.5559448, lng: 21.0162963 },
    { lat: 48.5554413, lng: 21.0170341 },
    { lat: 48.5554591, lng: 21.0171589 },
    { lat: 48.5555575, lng: 21.0173103 },
    { lat: 48.5557458, lng: 21.017455 },
    { lat: 48.5557662, lng: 21.0175295 },
    { lat: 48.555681, lng: 21.0181541 },
    { lat: 48.5559502, lng: 21.0193128 },
    { lat: 48.5560719, lng: 21.0193556 },
    { lat: 48.5563761, lng: 21.0190545 },
    { lat: 48.5565021, lng: 21.0190909 },
    { lat: 48.5565828, lng: 21.0192856 },
    { lat: 48.5566203, lng: 21.0195326 },
    { lat: 48.556518, lng: 21.0205209 },
    { lat: 48.5565797, lng: 21.020889 },
    { lat: 48.5566755, lng: 21.0211686 },
    { lat: 48.5568553, lng: 21.022042 },
    { lat: 48.5570079, lng: 21.0222696 },
    { lat: 48.5571275, lng: 21.0223133 },
    { lat: 48.5572091, lng: 21.0224969 },
    { lat: 48.5571938, lng: 21.0232268 },
    { lat: 48.5572754, lng: 21.0244046 },
    { lat: 48.5571553, lng: 21.025541 },
    { lat: 48.557245, lng: 21.0256007 },
    { lat: 48.5573435, lng: 21.0257831 },
    { lat: 48.5573624, lng: 21.0259853 },
    { lat: 48.5573018, lng: 21.026192 },
    { lat: 48.5569375, lng: 21.0266815 },
    { lat: 48.5569127, lng: 21.026817 },
    { lat: 48.5571614, lng: 21.0270183 },
    { lat: 48.5572296, lng: 21.0269713 },
    { lat: 48.5576054, lng: 21.0271371 },
    { lat: 48.5576105, lng: 21.0273617 },
    { lat: 48.5577105, lng: 21.0275905 },
    { lat: 48.5578222, lng: 21.0276745 },
    { lat: 48.5582004, lng: 21.0276689 },
    { lat: 48.558353, lng: 21.0277555 },
    { lat: 48.5586022, lng: 21.0281814 },
    { lat: 48.558842, lng: 21.0282139 },
    { lat: 48.5588664, lng: 21.0290261 },
    { lat: 48.5589337, lng: 21.0292584 },
    { lat: 48.5597422, lng: 21.029742 },
    { lat: 48.5599703, lng: 21.029764 },
    { lat: 48.5601264, lng: 21.0302988 },
    { lat: 48.5600794, lng: 21.0305479 },
    { lat: 48.5601125, lng: 21.0308005 },
    { lat: 48.5602947, lng: 21.0308331 },
    { lat: 48.5603242, lng: 21.0309407 },
    { lat: 48.5602254, lng: 21.0313553 },
    { lat: 48.5605023, lng: 21.0315989 },
    { lat: 48.5605605, lng: 21.0320626 },
    { lat: 48.5605398, lng: 21.0322936 },
    { lat: 48.5607374, lng: 21.0326484 },
    { lat: 48.5610425, lng: 21.032174 },
    { lat: 48.5611729, lng: 21.0323099 },
    { lat: 48.5616013, lng: 21.0324669 },
    { lat: 48.5623532, lng: 21.032394 },
    { lat: 48.5630011, lng: 21.0325531 },
    { lat: 48.5644333, lng: 21.0327018 },
    { lat: 48.5670393, lng: 21.0341105 },
    { lat: 48.5700039, lng: 21.0354437 },
    { lat: 48.5710982, lng: 21.036302 },
    { lat: 48.5729703, lng: 21.0375408 },
    { lat: 48.5734509, lng: 21.0378173 },
    { lat: 48.5758582, lng: 21.038931 },
    { lat: 48.5762766, lng: 21.0389591 },
    { lat: 48.587217, lng: 21.041998 },
    { lat: 48.5969834, lng: 21.0408986 },
    { lat: 48.5980155, lng: 21.0408628 },
    { lat: 48.599393, lng: 21.0405128 },
    { lat: 48.5998264, lng: 21.0404804 },
    { lat: 48.6005811, lng: 21.0398469 },
    { lat: 48.6006797, lng: 21.0396959 },
    { lat: 48.6008812, lng: 21.0407249 },
    { lat: 48.6015771, lng: 21.0407715 },
    { lat: 48.6016043, lng: 21.0412189 },
    { lat: 48.6020243, lng: 21.0414301 },
    { lat: 48.6044771, lng: 21.0423225 },
    { lat: 48.6045185, lng: 21.043174 },
    { lat: 48.6097933, lng: 21.0445918 },
    { lat: 48.6112775, lng: 21.0446543 },
    { lat: 48.6149769, lng: 21.0446415 },
    { lat: 48.6159217, lng: 21.0447909 },
    { lat: 48.6175551, lng: 21.044865 },
    { lat: 48.6178058, lng: 21.0449573 },
    { lat: 48.6192509, lng: 21.0464695 },
    { lat: 48.6193549, lng: 21.0468567 },
    { lat: 48.6195265, lng: 21.0472483 },
    { lat: 48.6197073, lng: 21.047411 },
    { lat: 48.6202319, lng: 21.0476223 },
    { lat: 48.6225916, lng: 21.0468121 },
    { lat: 48.6229357, lng: 21.0467985 },
    { lat: 48.6247031, lng: 21.0458804 },
    { lat: 48.6251874, lng: 21.0448605 },
    { lat: 48.6255833, lng: 21.0437487 },
    { lat: 48.6256503, lng: 21.0433264 },
    { lat: 48.6257321, lng: 21.0431103 },
    { lat: 48.6262766, lng: 21.0421734 },
    { lat: 48.626533, lng: 21.0420276 },
    { lat: 48.6272919, lng: 21.0419051 },
    { lat: 48.6278462, lng: 21.04202 },
    { lat: 48.6294821, lng: 21.0425144 },
    { lat: 48.6300106, lng: 21.0429792 },
    { lat: 48.6305906, lng: 21.0433319 },
    { lat: 48.631666, lng: 21.0435865 },
    { lat: 48.6322266, lng: 21.0436233 },
    { lat: 48.6328042, lng: 21.0435923 },
    { lat: 48.6334272, lng: 21.0433725 },
    { lat: 48.633894, lng: 21.0429504 },
    { lat: 48.6348599, lng: 21.0428235 },
    { lat: 48.6352645, lng: 21.0424595 },
    { lat: 48.6357265, lng: 21.0417169 },
    { lat: 48.6365909, lng: 21.0407609 },
    { lat: 48.6370563, lng: 21.0405026 },
    { lat: 48.6378961, lng: 21.040226 },
    { lat: 48.6386618, lng: 21.0394819 },
    { lat: 48.6388404, lng: 21.03937 },
    { lat: 48.6401622, lng: 21.0393143 },
    { lat: 48.6409237, lng: 21.0393691 },
    { lat: 48.6421252, lng: 21.0391117 },
    { lat: 48.6426117, lng: 21.0389134 },
    { lat: 48.6437626, lng: 21.0386896 },
    { lat: 48.6452077, lng: 21.0379701 },
    { lat: 48.6460391, lng: 21.0373021 },
    { lat: 48.6464418, lng: 21.0368876 },
    { lat: 48.6473422, lng: 21.0362674 },
    { lat: 48.6478912, lng: 21.0360555 },
    { lat: 48.6489876, lng: 21.0352342 },
    { lat: 48.6496773, lng: 21.0348843 },
    { lat: 48.6499939, lng: 21.0348235 },
    { lat: 48.6509913, lng: 21.0348635 },
    { lat: 48.6512817, lng: 21.0347645 },
    { lat: 48.6520548, lng: 21.0347202 },
    { lat: 48.6531628, lng: 21.0343244 },
    { lat: 48.6537479, lng: 21.0342144 },
    { lat: 48.6548725, lng: 21.0342209 },
    { lat: 48.6548592, lng: 21.0341974 },
    { lat: 48.6539797, lng: 21.0334953 },
    { lat: 48.6538002, lng: 21.0332087 },
    { lat: 48.6535788, lng: 21.0326664 },
    { lat: 48.6533813, lng: 21.032411 },
    { lat: 48.6528904, lng: 21.0313848 },
    { lat: 48.6525968, lng: 21.0310127 },
    { lat: 48.6502971, lng: 21.0297405 },
    { lat: 48.6494671, lng: 21.0294844 },
    { lat: 48.6490718, lng: 21.029203 },
    { lat: 48.6484173, lng: 21.0281945 },
    { lat: 48.6482742, lng: 21.0281621 },
    { lat: 48.6481897, lng: 21.0279496 },
    { lat: 48.6478583, lng: 21.0274551 },
    { lat: 48.6477409, lng: 21.0271069 },
    { lat: 48.6470158, lng: 21.0262749 },
    { lat: 48.6464326, lng: 21.0257264 },
    { lat: 48.6460649, lng: 21.0254628 },
    { lat: 48.6458548, lng: 21.0253416 },
    { lat: 48.645275, lng: 21.0252331 },
    { lat: 48.6451682, lng: 21.0251325 },
    { lat: 48.6439036, lng: 21.0257677 },
    { lat: 48.6435238, lng: 21.0258514 },
    { lat: 48.6427934, lng: 21.0258239 },
    { lat: 48.6427513, lng: 21.0259766 },
    { lat: 48.642606, lng: 21.0258408 },
    { lat: 48.6425181, lng: 21.0259371 },
    { lat: 48.6424037, lng: 21.0258154 },
    { lat: 48.6421224, lng: 21.0257468 },
    { lat: 48.6419592, lng: 21.0254737 },
    { lat: 48.6418285, lng: 21.0255077 },
    { lat: 48.6418334, lng: 21.025623 },
    { lat: 48.6417309, lng: 21.0256538 },
    { lat: 48.6416668, lng: 21.0255839 },
    { lat: 48.6415961, lng: 21.0256586 },
    { lat: 48.6414738, lng: 21.025294 },
    { lat: 48.6412291, lng: 21.0249761 },
    { lat: 48.6410142, lng: 21.0245341 },
    { lat: 48.6408244, lng: 21.0240327 },
    { lat: 48.6406996, lng: 21.0233548 },
    { lat: 48.6403943, lng: 21.0225631 },
  ],
  VyšnýČaj: [
    { lat: 48.6722425, lng: 21.4088125 },
    { lat: 48.6723444, lng: 21.4088288 },
    { lat: 48.6726858, lng: 21.408883 },
    { lat: 48.6727513, lng: 21.4083566 },
    { lat: 48.6730711, lng: 21.4085981 },
    { lat: 48.6730794, lng: 21.4089191 },
    { lat: 48.6736507, lng: 21.4089396 },
    { lat: 48.6741683, lng: 21.4092031 },
    { lat: 48.6739825, lng: 21.4098098 },
    { lat: 48.6738125, lng: 21.4112512 },
    { lat: 48.6742463, lng: 21.4113451 },
    { lat: 48.6743069, lng: 21.411033 },
    { lat: 48.6744386, lng: 21.4107735 },
    { lat: 48.6751316, lng: 21.409968 },
    { lat: 48.6752685, lng: 21.4102607 },
    { lat: 48.6752151, lng: 21.4108856 },
    { lat: 48.6753731, lng: 21.4112866 },
    { lat: 48.6754214, lng: 21.4118113 },
    { lat: 48.6753663, lng: 21.4119308 },
    { lat: 48.6755664, lng: 21.4122253 },
    { lat: 48.6760904, lng: 21.411794 },
    { lat: 48.6764179, lng: 21.410844 },
    { lat: 48.6765319, lng: 21.4108157 },
    { lat: 48.6766002, lng: 21.4102399 },
    { lat: 48.6769783, lng: 21.409592 },
    { lat: 48.676927, lng: 21.4094502 },
    { lat: 48.6768858, lng: 21.4095071 },
    { lat: 48.6768468, lng: 21.4092509 },
    { lat: 48.6767225, lng: 21.4090431 },
    { lat: 48.6765353, lng: 21.4089575 },
    { lat: 48.6760577, lng: 21.4090545 },
    { lat: 48.6756962, lng: 21.4088568 },
    { lat: 48.6756317, lng: 21.4086542 },
    { lat: 48.6756296, lng: 21.4083898 },
    { lat: 48.6758659, lng: 21.4073235 },
    { lat: 48.6760642, lng: 21.407031 },
    { lat: 48.6763984, lng: 21.4067681 },
    { lat: 48.6769523, lng: 21.4068108 },
    { lat: 48.67714, lng: 21.4070737 },
    { lat: 48.6777777, lng: 21.4083175 },
    { lat: 48.6778499, lng: 21.4087018 },
    { lat: 48.6781484, lng: 21.4089405 },
    { lat: 48.6783327, lng: 21.409047 },
    { lat: 48.6784296, lng: 21.4090472 },
    { lat: 48.6785246, lng: 21.4089493 },
    { lat: 48.6786254, lng: 21.4082143 },
    { lat: 48.6788035, lng: 21.4079221 },
    { lat: 48.6791278, lng: 21.4079244 },
    { lat: 48.6795127, lng: 21.4083795 },
    { lat: 48.6795156, lng: 21.4087093 },
    { lat: 48.6799874, lng: 21.4088726 },
    { lat: 48.6802373, lng: 21.4092549 },
    { lat: 48.6804179, lng: 21.4093104 },
    { lat: 48.6808513, lng: 21.4090867 },
    { lat: 48.6811904, lng: 21.4092134 },
    { lat: 48.6813066, lng: 21.4097223 },
    { lat: 48.6814266, lng: 21.4099691 },
    { lat: 48.6814884, lng: 21.4103506 },
    { lat: 48.6814219, lng: 21.4106855 },
    { lat: 48.6815279, lng: 21.4109601 },
    { lat: 48.6818366, lng: 21.41116 },
    { lat: 48.68195, lng: 21.4111207 },
    { lat: 48.6823034, lng: 21.4113101 },
    { lat: 48.6831934, lng: 21.4119506 },
    { lat: 48.6845147, lng: 21.4123778 },
    { lat: 48.6844816, lng: 21.4122123 },
    { lat: 48.6845227, lng: 21.4118093 },
    { lat: 48.684789, lng: 21.411343 },
    { lat: 48.6848425, lng: 21.4109804 },
    { lat: 48.684945, lng: 21.410867 },
    { lat: 48.6854345, lng: 21.4107969 },
    { lat: 48.6859746, lng: 21.4109503 },
    { lat: 48.6861035, lng: 21.4110826 },
    { lat: 48.687805, lng: 21.4100223 },
    { lat: 48.6879874, lng: 21.4103273 },
    { lat: 48.6882128, lng: 21.4110988 },
    { lat: 48.6882356, lng: 21.4113384 },
    { lat: 48.6879679, lng: 21.4116841 },
    { lat: 48.6882293, lng: 21.4123039 },
    { lat: 48.6883225, lng: 21.412371 },
    { lat: 48.6883943, lng: 21.4120634 },
    { lat: 48.6883933, lng: 21.4117992 },
    { lat: 48.6882989, lng: 21.4110791 },
    { lat: 48.6884182, lng: 21.4105831 },
    { lat: 48.6886505, lng: 21.4106002 },
    { lat: 48.689219, lng: 21.4106455 },
    { lat: 48.6892911, lng: 21.4091708 },
    { lat: 48.6886455, lng: 21.4091788 },
    { lat: 48.6887266, lng: 21.4076957 },
    { lat: 48.6909804, lng: 21.4074647 },
    { lat: 48.6911093, lng: 21.406915 },
    { lat: 48.6917334, lng: 21.4049606 },
    { lat: 48.6918432, lng: 21.4046803 },
    { lat: 48.6922096, lng: 21.4040957 },
    { lat: 48.6933548, lng: 21.4009362 },
    { lat: 48.693441, lng: 21.4003131 },
    { lat: 48.6935767, lng: 21.3997675 },
    { lat: 48.6944186, lng: 21.3971559 },
    { lat: 48.6952463, lng: 21.3951351 },
    { lat: 48.6954377, lng: 21.3945678 },
    { lat: 48.6956023, lng: 21.3932113 },
    { lat: 48.6952708, lng: 21.3920224 },
    { lat: 48.6950351, lng: 21.3915116 },
    { lat: 48.6949947, lng: 21.3913284 },
    { lat: 48.6950788, lng: 21.3905219 },
    { lat: 48.6950906, lng: 21.3891765 },
    { lat: 48.6952432, lng: 21.3886721 },
    { lat: 48.6954362, lng: 21.3883106 },
    { lat: 48.695518, lng: 21.3877006 },
    { lat: 48.6958629, lng: 21.3870317 },
    { lat: 48.6963169, lng: 21.3863675 },
    { lat: 48.6972474, lng: 21.3851979 },
    { lat: 48.6976442, lng: 21.3849173 },
    { lat: 48.6987949, lng: 21.3844818 },
    { lat: 48.6998453, lng: 21.3834827 },
    { lat: 48.6999249, lng: 21.3833996 },
    { lat: 48.6986585, lng: 21.3820669 },
    { lat: 48.698097, lng: 21.3817265 },
    { lat: 48.6973119, lng: 21.3817379 },
    { lat: 48.6971216, lng: 21.3818474 },
    { lat: 48.6962543, lng: 21.3819652 },
    { lat: 48.6953391, lng: 21.3815459 },
    { lat: 48.695149, lng: 21.3813957 },
    { lat: 48.6938616, lng: 21.3812817 },
    { lat: 48.6929754, lng: 21.3817844 },
    { lat: 48.6916698, lng: 21.3821186 },
    { lat: 48.6912258, lng: 21.3819868 },
    { lat: 48.6905998, lng: 21.3809196 },
    { lat: 48.6901314, lng: 21.379931 },
    { lat: 48.689732, lng: 21.3793339 },
    { lat: 48.6892346, lng: 21.3779719 },
    { lat: 48.6890988, lng: 21.3760604 },
    { lat: 48.6892891, lng: 21.3739226 },
    { lat: 48.6887676, lng: 21.3735653 },
    { lat: 48.6879867, lng: 21.373907 },
    { lat: 48.6877497, lng: 21.3739951 },
    { lat: 48.6876998, lng: 21.3743593 },
    { lat: 48.6875309, lng: 21.3755957 },
    { lat: 48.6871216, lng: 21.3773423 },
    { lat: 48.6867014, lng: 21.3782677 },
    { lat: 48.685974, lng: 21.3794608 },
    { lat: 48.6853509, lng: 21.3796247 },
    { lat: 48.6839615, lng: 21.3792905 },
    { lat: 48.6822986, lng: 21.3783625 },
    { lat: 48.681643, lng: 21.3784523 },
    { lat: 48.6802913, lng: 21.3789019 },
    { lat: 48.6799774, lng: 21.3792423 },
    { lat: 48.6799003, lng: 21.3792586 },
    { lat: 48.6792288, lng: 21.3788977 },
    { lat: 48.6780002, lng: 21.3791234 },
    { lat: 48.6778965, lng: 21.3790881 },
    { lat: 48.6777454, lng: 21.3796403 },
    { lat: 48.6773829, lng: 21.3805391 },
    { lat: 48.6767668, lng: 21.3814591 },
    { lat: 48.6765848, lng: 21.3818137 },
    { lat: 48.6762737, lng: 21.3822087 },
    { lat: 48.6761104, lng: 21.382656 },
    { lat: 48.6758962, lng: 21.3884588 },
    { lat: 48.6755145, lng: 21.3886015 },
    { lat: 48.6746911, lng: 21.3946669 },
    { lat: 48.6736485, lng: 21.4011239 },
    { lat: 48.6777907, lng: 21.4015388 },
    { lat: 48.6774639, lng: 21.4022402 },
    { lat: 48.6773358, lng: 21.4034239 },
    { lat: 48.6771789, lng: 21.403865 },
    { lat: 48.6768527, lng: 21.4042517 },
    { lat: 48.676691, lng: 21.4046757 },
    { lat: 48.6765139, lng: 21.4055732 },
    { lat: 48.6764206, lng: 21.4065252 },
    { lat: 48.6752167, lng: 21.4065363 },
    { lat: 48.6736907, lng: 21.4063026 },
    { lat: 48.6734821, lng: 21.4062091 },
    { lat: 48.6729808, lng: 21.4056993 },
    { lat: 48.6726477, lng: 21.4055135 },
    { lat: 48.6724067, lng: 21.4074382 },
    { lat: 48.6722425, lng: 21.4088125 },
  ],
  VeľkáIda: [
    { lat: 48.6468836, lng: 21.1001301 },
    { lat: 48.6468079, lng: 21.0999823 },
    { lat: 48.6468409, lng: 21.0999247 },
    { lat: 48.6466761, lng: 21.0997368 },
    { lat: 48.6465132, lng: 21.0998121 },
    { lat: 48.6462159, lng: 21.0998073 },
    { lat: 48.6454083, lng: 21.0999966 },
    { lat: 48.6447964, lng: 21.1000089 },
    { lat: 48.6444639, lng: 21.1002063 },
    { lat: 48.6438316, lng: 21.1003546 },
    { lat: 48.6428443, lng: 21.1003464 },
    { lat: 48.6421218, lng: 21.1001732 },
    { lat: 48.6417764, lng: 21.1002812 },
    { lat: 48.6416078, lng: 21.1004263 },
    { lat: 48.6412295, lng: 21.1005289 },
    { lat: 48.6406258, lng: 21.1005188 },
    { lat: 48.6396835, lng: 21.1001223 },
    { lat: 48.639016, lng: 21.0996329 },
    { lat: 48.6384841, lng: 21.0994397 },
    { lat: 48.6381855, lng: 21.0992207 },
    { lat: 48.6379208, lng: 21.0989061 },
    { lat: 48.6368144, lng: 21.0984142 },
    { lat: 48.6361859, lng: 21.0980156 },
    { lat: 48.6356414, lng: 21.0978863 },
    { lat: 48.635378, lng: 21.0976409 },
    { lat: 48.6343182, lng: 21.0969588 },
    { lat: 48.6341358, lng: 21.0967449 },
    { lat: 48.6337309, lng: 21.0965117 },
    { lat: 48.6326324, lng: 21.0955375 },
    { lat: 48.6322675, lng: 21.0953559 },
    { lat: 48.6268901, lng: 21.0941191 },
    { lat: 48.624219, lng: 21.094213 },
    { lat: 48.6239514, lng: 21.0942224 },
    { lat: 48.6202169, lng: 21.094158 },
    { lat: 48.620271, lng: 21.0944734 },
    { lat: 48.6194075, lng: 21.0946021 },
    { lat: 48.6194117, lng: 21.0946609 },
    { lat: 48.6180872, lng: 21.0946026 },
    { lat: 48.6169738, lng: 21.0949417 },
    { lat: 48.6152189, lng: 21.0945488 },
    { lat: 48.6146502, lng: 21.0946141 },
    { lat: 48.6131909, lng: 21.0944878 },
    { lat: 48.6129179, lng: 21.0944717 },
    { lat: 48.6129174, lng: 21.0944909 },
    { lat: 48.6128951, lng: 21.0959591 },
    { lat: 48.6131041, lng: 21.0959828 },
    { lat: 48.6130155, lng: 21.0977621 },
    { lat: 48.613032, lng: 21.1003976 },
    { lat: 48.6125815, lng: 21.1000395 },
    { lat: 48.6118855, lng: 21.0997168 },
    { lat: 48.6110267, lng: 21.0996057 },
    { lat: 48.6108737, lng: 21.100829 },
    { lat: 48.609912, lng: 21.1005398 },
    { lat: 48.6097733, lng: 21.1023059 },
    { lat: 48.6096098, lng: 21.1023328 },
    { lat: 48.6090992, lng: 21.1021648 },
    { lat: 48.6088882, lng: 21.1036502 },
    { lat: 48.6089449, lng: 21.1042614 },
    { lat: 48.6089096, lng: 21.1045549 },
    { lat: 48.6087544, lng: 21.1054672 },
    { lat: 48.6085402, lng: 21.1059495 },
    { lat: 48.6083786, lng: 21.1067303 },
    { lat: 48.6069223, lng: 21.1064559 },
    { lat: 48.6064334, lng: 21.1064413 },
    { lat: 48.6066821, lng: 21.1073434 },
    { lat: 48.6069066, lng: 21.1089654 },
    { lat: 48.6071191, lng: 21.1109838 },
    { lat: 48.6074728, lng: 21.1128129 },
    { lat: 48.6073641, lng: 21.1130342 },
    { lat: 48.6074454, lng: 21.1148983 },
    { lat: 48.6086911, lng: 21.1146118 },
    { lat: 48.6094416, lng: 21.114707 },
    { lat: 48.609569, lng: 21.1148157 },
    { lat: 48.6100212, lng: 21.115405 },
    { lat: 48.6102716, lng: 21.1160845 },
    { lat: 48.6105519, lng: 21.1166071 },
    { lat: 48.612887, lng: 21.1195392 },
    { lat: 48.6135789, lng: 21.1206876 },
    { lat: 48.6138483, lng: 21.1213274 },
    { lat: 48.6142352, lng: 21.1220603 },
    { lat: 48.6144464, lng: 21.1222543 },
    { lat: 48.613525, lng: 21.1255225 },
    { lat: 48.6124272, lng: 21.1257908 },
    { lat: 48.6112319, lng: 21.1255117 },
    { lat: 48.6098024, lng: 21.1255112 },
    { lat: 48.6085715, lng: 21.1253791 },
    { lat: 48.6083481, lng: 21.1252969 },
    { lat: 48.6075929, lng: 21.1253343 },
    { lat: 48.6067436, lng: 21.1254869 },
    { lat: 48.6055755, lng: 21.1251366 },
    { lat: 48.6049006, lng: 21.1248011 },
    { lat: 48.6040211, lng: 21.1245075 },
    { lat: 48.603004, lng: 21.1240281 },
    { lat: 48.6024223, lng: 21.1239674 },
    { lat: 48.601498, lng: 21.1235335 },
    { lat: 48.6009556, lng: 21.1233947 },
    { lat: 48.6006304, lng: 21.12347 },
    { lat: 48.5999175, lng: 21.1234939 },
    { lat: 48.5990618, lng: 21.1231994 },
    { lat: 48.5989073, lng: 21.1231919 },
    { lat: 48.5987008, lng: 21.1232823 },
    { lat: 48.5984817, lng: 21.1231525 },
    { lat: 48.597911, lng: 21.1233965 },
    { lat: 48.5977379, lng: 21.1236442 },
    { lat: 48.5975999, lng: 21.1236593 },
    { lat: 48.5973284, lng: 21.123373 },
    { lat: 48.5970704, lng: 21.1232272 },
    { lat: 48.596855, lng: 21.1234221 },
    { lat: 48.5965035, lng: 21.123308 },
    { lat: 48.5963155, lng: 21.1233762 },
    { lat: 48.5957483, lng: 21.1233472 },
    { lat: 48.5951135, lng: 21.1230622 },
    { lat: 48.5945764, lng: 21.1226001 },
    { lat: 48.5943641, lng: 21.1223688 },
    { lat: 48.5941103, lng: 21.121928 },
    { lat: 48.5938647, lng: 21.1218172 },
    { lat: 48.5936643, lng: 21.121504 },
    { lat: 48.5932563, lng: 21.1214158 },
    { lat: 48.5930351, lng: 21.1210803 },
    { lat: 48.5928045, lng: 21.1208767 },
    { lat: 48.5925453, lng: 21.1205174 },
    { lat: 48.5924434, lng: 21.1204946 },
    { lat: 48.5921414, lng: 21.1207247 },
    { lat: 48.5919418, lng: 21.1207908 },
    { lat: 48.5918148, lng: 21.1206917 },
    { lat: 48.5916622, lng: 21.120706 },
    { lat: 48.5914963, lng: 21.1208646 },
    { lat: 48.591287, lng: 21.1208984 },
    { lat: 48.5908509, lng: 21.1206768 },
    { lat: 48.5906717, lng: 21.1207738 },
    { lat: 48.5902489, lng: 21.1206827 },
    { lat: 48.5901865, lng: 21.1205604 },
    { lat: 48.5901756, lng: 21.1203958 },
    { lat: 48.5901115, lng: 21.1203248 },
    { lat: 48.5898562, lng: 21.1204028 },
    { lat: 48.5895917, lng: 21.1202597 },
    { lat: 48.5893894, lng: 21.1262568 },
    { lat: 48.5894498, lng: 21.1288099 },
    { lat: 48.5893874, lng: 21.1297748 },
    { lat: 48.5892915, lng: 21.1305066 },
    { lat: 48.5864331, lng: 21.1306193 },
    { lat: 48.5853902, lng: 21.1308126 },
    { lat: 48.5842785, lng: 21.1308208 },
    { lat: 48.582492, lng: 21.130706 },
    { lat: 48.5806759, lng: 21.1304765 },
    { lat: 48.5798308, lng: 21.1305203 },
    { lat: 48.5786377, lng: 21.1308301 },
    { lat: 48.5780213, lng: 21.1308039 },
    { lat: 48.5764708, lng: 21.1305774 },
    { lat: 48.5745176, lng: 21.13014 },
    { lat: 48.5725033, lng: 21.1298692 },
    { lat: 48.5714635, lng: 21.1303345 },
    { lat: 48.5706869, lng: 21.1302627 },
    { lat: 48.5704341, lng: 21.1299272 },
    { lat: 48.5685549, lng: 21.1294131 },
    { lat: 48.5648023, lng: 21.1294452 },
    { lat: 48.5615975, lng: 21.1292893 },
    { lat: 48.5605708, lng: 21.1291775 },
    { lat: 48.5605577, lng: 21.1291863 },
    { lat: 48.5609611, lng: 21.1308205 },
    { lat: 48.5614605, lng: 21.1331555 },
    { lat: 48.5627692, lng: 21.1397881 },
    { lat: 48.5630088, lng: 21.1404213 },
    { lat: 48.5702385, lng: 21.1491359 },
    { lat: 48.5703112, lng: 21.1567787 },
    { lat: 48.5707851, lng: 21.1579136 },
    { lat: 48.5770823, lng: 21.159117 },
    { lat: 48.5763184, lng: 21.165638 },
    { lat: 48.5751972, lng: 21.1730844 },
    { lat: 48.5751034, lng: 21.1732806 },
    { lat: 48.575101, lng: 21.1738441 },
    { lat: 48.5747994, lng: 21.1766124 },
    { lat: 48.5745663, lng: 21.1776747 },
    { lat: 48.5744354, lng: 21.1786675 },
    { lat: 48.5739998, lng: 21.1807992 },
    { lat: 48.5738009, lng: 21.1814149 },
    { lat: 48.5734492, lng: 21.1829275 },
    { lat: 48.5730253, lng: 21.1843933 },
    { lat: 48.5726986, lng: 21.1858831 },
    { lat: 48.5724451, lng: 21.1864451 },
    { lat: 48.5723092, lng: 21.1873102 },
    { lat: 48.5719626, lng: 21.1882378 },
    { lat: 48.571822, lng: 21.1884159 },
    { lat: 48.5715923, lng: 21.1898425 },
    { lat: 48.5717944, lng: 21.1898956 },
    { lat: 48.5717877, lng: 21.1899657 },
    { lat: 48.5715278, lng: 21.1912239 },
    { lat: 48.5713964, lng: 21.1921552 },
    { lat: 48.5713705, lng: 21.1928678 },
    { lat: 48.5714316, lng: 21.1930553 },
    { lat: 48.570766, lng: 21.1954417 },
    { lat: 48.5709224, lng: 21.1956121 },
    { lat: 48.57051, lng: 21.1965968 },
    { lat: 48.5704167, lng: 21.1967123 },
    { lat: 48.570907, lng: 21.1972348 },
    { lat: 48.5708765, lng: 21.1972995 },
    { lat: 48.5727784, lng: 21.1991522 },
    { lat: 48.5734598, lng: 21.2001728 },
    { lat: 48.5738785, lng: 21.2006724 },
    { lat: 48.5741913, lng: 21.2012056 },
    { lat: 48.5743117, lng: 21.201563 },
    { lat: 48.5745474, lng: 21.2017221 },
    { lat: 48.575192, lng: 21.202654 },
    { lat: 48.575689, lng: 21.201092 },
    { lat: 48.57578, lng: 21.200781 },
    { lat: 48.57597, lng: 21.200111 },
    { lat: 48.576569, lng: 21.197984 },
    { lat: 48.576575, lng: 21.197968 },
    { lat: 48.577261, lng: 21.195543 },
    { lat: 48.577598, lng: 21.194359 },
    { lat: 48.578668, lng: 21.190625 },
    { lat: 48.5788701, lng: 21.1899424 },
    { lat: 48.579689, lng: 21.187176 },
    { lat: 48.579697, lng: 21.187153 },
    { lat: 48.57974, lng: 21.1869 },
    { lat: 48.580812, lng: 21.18318 },
    { lat: 48.580906, lng: 21.182895 },
    { lat: 48.581028, lng: 21.182644 },
    { lat: 48.581207, lng: 21.182395 },
    { lat: 48.581376, lng: 21.182209 },
    { lat: 48.582876, lng: 21.180714 },
    { lat: 48.582965, lng: 21.180613 },
    { lat: 48.585005, lng: 21.178569 },
    { lat: 48.585829, lng: 21.177684 },
    { lat: 48.587331, lng: 21.176164 },
    { lat: 48.589069, lng: 21.174432 },
    { lat: 48.58932, lng: 21.174183 },
    { lat: 48.589368, lng: 21.174416 },
    { lat: 48.589503, lng: 21.17466 },
    { lat: 48.590819, lng: 21.177089 },
    { lat: 48.59124, lng: 21.177908 },
    { lat: 48.59143, lng: 21.177707 },
    { lat: 48.591849, lng: 21.177234 },
    { lat: 48.59192, lng: 21.17731 },
    { lat: 48.592375, lng: 21.17692 },
    { lat: 48.593099, lng: 21.176031 },
    { lat: 48.593128, lng: 21.175983 },
    { lat: 48.593403, lng: 21.176603 },
    { lat: 48.593611, lng: 21.176652 },
    { lat: 48.59378, lng: 21.176964 },
    { lat: 48.593878, lng: 21.176856 },
    { lat: 48.59474, lng: 21.175851 },
    { lat: 48.594491, lng: 21.175427 },
    { lat: 48.594372, lng: 21.174904 },
    { lat: 48.593821, lng: 21.174851 },
    { lat: 48.594005, lng: 21.173198 },
    { lat: 48.594159, lng: 21.171821 },
    { lat: 48.594589, lng: 21.171936 },
    { lat: 48.595292, lng: 21.172022 },
    { lat: 48.595796, lng: 21.171928 },
    { lat: 48.595919, lng: 21.171949 },
    { lat: 48.598002, lng: 21.17166 },
    { lat: 48.598089, lng: 21.171613 },
    { lat: 48.598191, lng: 21.171496 },
    { lat: 48.598306, lng: 21.171452 },
    { lat: 48.598613, lng: 21.171493 },
    { lat: 48.599766, lng: 21.171844 },
    { lat: 48.600016, lng: 21.171866 },
    { lat: 48.600077, lng: 21.171204 },
    { lat: 48.60037, lng: 21.171386 },
    { lat: 48.60053, lng: 21.171233 },
    { lat: 48.600825, lng: 21.171304 },
    { lat: 48.601604, lng: 21.1717 },
    { lat: 48.601697, lng: 21.171235 },
    { lat: 48.602032, lng: 21.171445 },
    { lat: 48.602504, lng: 21.171708 },
    { lat: 48.602559, lng: 21.171539 },
    { lat: 48.603376, lng: 21.171926 },
    { lat: 48.603978, lng: 21.172282 },
    { lat: 48.604447, lng: 21.172528 },
    { lat: 48.604809, lng: 21.172785 },
    { lat: 48.605595, lng: 21.173217 },
    { lat: 48.606228, lng: 21.170487 },
    { lat: 48.607733, lng: 21.170166 },
    { lat: 48.608376, lng: 21.170028 },
    { lat: 48.609018, lng: 21.169992 },
    { lat: 48.609819, lng: 21.170174 },
    { lat: 48.610292, lng: 21.169793 },
    { lat: 48.610483, lng: 21.169783 },
    { lat: 48.61077, lng: 21.170201 },
    { lat: 48.611479, lng: 21.170593 },
    { lat: 48.612106, lng: 21.171339 },
    { lat: 48.612454, lng: 21.171187 },
    { lat: 48.612668, lng: 21.170787 },
    { lat: 48.612897, lng: 21.170859 },
    { lat: 48.613777, lng: 21.170323 },
    { lat: 48.613951, lng: 21.171068 },
    { lat: 48.613905, lng: 21.171253 },
    { lat: 48.613965, lng: 21.171348 },
    { lat: 48.614324, lng: 21.171534 },
    { lat: 48.61524, lng: 21.1717 },
    { lat: 48.6158, lng: 21.171243 },
    { lat: 48.615673, lng: 21.170695 },
    { lat: 48.61567, lng: 21.170644 },
    { lat: 48.615953, lng: 21.170706 },
    { lat: 48.616212, lng: 21.170748 },
    { lat: 48.616197, lng: 21.170537 },
    { lat: 48.616321, lng: 21.170399 },
    { lat: 48.616283, lng: 21.170319 },
    { lat: 48.616315, lng: 21.17019 },
    { lat: 48.616729, lng: 21.169987 },
    { lat: 48.616912, lng: 21.170141 },
    { lat: 48.617164, lng: 21.170011 },
    { lat: 48.617305, lng: 21.170131 },
    { lat: 48.617332, lng: 21.170155 },
    { lat: 48.617374, lng: 21.170191 },
    { lat: 48.617521, lng: 21.16993 },
    { lat: 48.617514, lng: 21.169884 },
    { lat: 48.617486, lng: 21.169699 },
    { lat: 48.617469, lng: 21.169594 },
    { lat: 48.617367, lng: 21.168536 },
    { lat: 48.617355, lng: 21.168421 },
    { lat: 48.617349, lng: 21.168361 },
    { lat: 48.6173, lng: 21.167775 },
    { lat: 48.617296, lng: 21.167649 },
    { lat: 48.617343, lng: 21.167534 },
    { lat: 48.617806, lng: 21.166848 },
    { lat: 48.618212, lng: 21.1663 },
    { lat: 48.618296, lng: 21.166205 },
    { lat: 48.618462, lng: 21.166101 },
    { lat: 48.618798, lng: 21.165946 },
    { lat: 48.619754, lng: 21.165505 },
    { lat: 48.619848, lng: 21.165462 },
    { lat: 48.619765, lng: 21.164765 },
    { lat: 48.619781, lng: 21.164045 },
    { lat: 48.619841, lng: 21.161563 },
    { lat: 48.61984, lng: 21.16136 },
    { lat: 48.620133, lng: 21.159244 },
    { lat: 48.620195, lng: 21.158498 },
    { lat: 48.620201, lng: 21.158409 },
    { lat: 48.620209, lng: 21.158313 },
    { lat: 48.620306, lng: 21.157136 },
    { lat: 48.620315, lng: 21.156914 },
    { lat: 48.620481, lng: 21.15458 },
    { lat: 48.620658, lng: 21.152074 },
    { lat: 48.620663, lng: 21.151997 },
    { lat: 48.620664, lng: 21.151992 },
    { lat: 48.620962, lng: 21.147712 },
    { lat: 48.620971, lng: 21.14758 },
    { lat: 48.62098, lng: 21.147452 },
    { lat: 48.620993, lng: 21.147264 },
    { lat: 48.620996, lng: 21.147222 },
    { lat: 48.620999, lng: 21.147173 },
    { lat: 48.621099, lng: 21.145719 },
    { lat: 48.62091, lng: 21.141279 },
    { lat: 48.620728, lng: 21.137437 },
    { lat: 48.620707, lng: 21.136975 },
    { lat: 48.6207, lng: 21.136819 },
    { lat: 48.620685, lng: 21.136492 },
    { lat: 48.620536, lng: 21.133262 },
    { lat: 48.62053, lng: 21.133136 },
    { lat: 48.620523, lng: 21.132995 },
    { lat: 48.620435, lng: 21.131182 },
    { lat: 48.620405, lng: 21.130601 },
    { lat: 48.620401, lng: 21.130536 },
    { lat: 48.620401, lng: 21.130517 },
    { lat: 48.620266, lng: 21.127696 },
    { lat: 48.620263, lng: 21.127625 },
    { lat: 48.620258, lng: 21.127527 },
    { lat: 48.620257, lng: 21.127496 },
    { lat: 48.620253, lng: 21.127421 },
    { lat: 48.62025, lng: 21.127374 },
    { lat: 48.620226, lng: 21.126882 },
    { lat: 48.619962, lng: 21.12149 },
    { lat: 48.621308, lng: 21.12135 },
    { lat: 48.621333, lng: 21.121347 },
    { lat: 48.621741, lng: 21.121305 },
    { lat: 48.622324, lng: 21.121244 },
    { lat: 48.622374, lng: 21.121239 },
    { lat: 48.623099, lng: 21.120836 },
    { lat: 48.623431, lng: 21.120801 },
    { lat: 48.623778, lng: 21.120852 },
    { lat: 48.624273, lng: 21.120791 },
    { lat: 48.624654, lng: 21.120902 },
    { lat: 48.625072, lng: 21.121052 },
    { lat: 48.625728, lng: 21.121239 },
    { lat: 48.625994, lng: 21.121315 },
    { lat: 48.626811, lng: 21.12175 },
    { lat: 48.62744, lng: 21.1222 },
    { lat: 48.627728, lng: 21.122373 },
    { lat: 48.628196, lng: 21.122715 },
    { lat: 48.628347, lng: 21.122731 },
    { lat: 48.628571, lng: 21.123189 },
    { lat: 48.628656, lng: 21.123456 },
    { lat: 48.62947, lng: 21.124123 },
    { lat: 48.62957, lng: 21.12422 },
    { lat: 48.629984, lng: 21.124508 },
    { lat: 48.630409, lng: 21.124866 },
    { lat: 48.630599, lng: 21.125068 },
    { lat: 48.630659, lng: 21.12526 },
    { lat: 48.630895, lng: 21.125624 },
    { lat: 48.631223, lng: 21.125815 },
    { lat: 48.631563, lng: 21.126358 },
    { lat: 48.631801, lng: 21.126648 },
    { lat: 48.632384, lng: 21.127124 },
    { lat: 48.633328, lng: 21.127704 },
    { lat: 48.633678, lng: 21.127801 },
    { lat: 48.634019, lng: 21.127949 },
    { lat: 48.634517, lng: 21.128178 },
    { lat: 48.635126, lng: 21.128491 },
    { lat: 48.635304, lng: 21.128658 },
    { lat: 48.636226, lng: 21.129035 },
    { lat: 48.636606, lng: 21.129248 },
    { lat: 48.636885, lng: 21.129383 },
    { lat: 48.637068, lng: 21.129369 },
    { lat: 48.637466, lng: 21.129504 },
    { lat: 48.637978, lng: 21.129643 },
    { lat: 48.638677, lng: 21.12963 },
    { lat: 48.639242, lng: 21.129329 },
    { lat: 48.639939, lng: 21.128878 },
    { lat: 48.640505, lng: 21.128606 },
    { lat: 48.640704, lng: 21.128556 },
    { lat: 48.642053, lng: 21.128216 },
    { lat: 48.642377, lng: 21.128178 },
    { lat: 48.642535, lng: 21.128159 },
    { lat: 48.642586, lng: 21.128146 },
    { lat: 48.642692, lng: 21.12812 },
    { lat: 48.642709, lng: 21.128121 },
    { lat: 48.643049, lng: 21.128092 },
    { lat: 48.643391, lng: 21.12805 },
    { lat: 48.643584, lng: 21.127974 },
    { lat: 48.64389, lng: 21.127854 },
    { lat: 48.644247, lng: 21.127634 },
    { lat: 48.644371, lng: 21.127557 },
    { lat: 48.644557, lng: 21.127469 },
    { lat: 48.645325, lng: 21.126891 },
    { lat: 48.645972, lng: 21.1266 },
    { lat: 48.646062, lng: 21.126559 },
    { lat: 48.646784, lng: 21.126235 },
    { lat: 48.647253, lng: 21.125876 },
    { lat: 48.647384, lng: 21.125777 },
    { lat: 48.647508, lng: 21.125683 },
    { lat: 48.647746, lng: 21.125501 },
    { lat: 48.648101, lng: 21.125264 },
    { lat: 48.648366, lng: 21.125165 },
    { lat: 48.648642, lng: 21.125089 },
    { lat: 48.648855, lng: 21.124958 },
    { lat: 48.649101, lng: 21.124837 },
    { lat: 48.649405, lng: 21.124757 },
    { lat: 48.649584, lng: 21.124761 },
    { lat: 48.649973, lng: 21.124632 },
    { lat: 48.650115, lng: 21.124616 },
    { lat: 48.650337, lng: 21.124716 },
    { lat: 48.65048, lng: 21.124836 },
    { lat: 48.65092, lng: 21.124872 },
    { lat: 48.65125, lng: 21.1249 },
    { lat: 48.65135, lng: 21.124977 },
    { lat: 48.651513, lng: 21.124912 },
    { lat: 48.651812, lng: 21.124964 },
    { lat: 48.652835, lng: 21.124676 },
    { lat: 48.653031, lng: 21.124794 },
    { lat: 48.653197, lng: 21.124803 },
    { lat: 48.653548, lng: 21.124492 },
    { lat: 48.653691, lng: 21.124614 },
    { lat: 48.653851, lng: 21.124503 },
    { lat: 48.653991, lng: 21.124525 },
    { lat: 48.654113, lng: 21.124492 },
    { lat: 48.654225, lng: 21.124291 },
    { lat: 48.654285, lng: 21.124097 },
    { lat: 48.654374, lng: 21.123946 },
    { lat: 48.654573, lng: 21.123884 },
    { lat: 48.65462, lng: 21.123659 },
    { lat: 48.654757, lng: 21.123339 },
    { lat: 48.655005, lng: 21.122909 },
    { lat: 48.655201, lng: 21.122505 },
    { lat: 48.655136, lng: 21.121685 },
    { lat: 48.6551945, lng: 21.1214467 },
    { lat: 48.6551547, lng: 21.1210869 },
    { lat: 48.6550124, lng: 21.1209321 },
    { lat: 48.655163, lng: 21.1207517 },
    { lat: 48.6552086, lng: 21.1201357 },
    { lat: 48.6553118, lng: 21.1200245 },
    { lat: 48.6553066, lng: 21.1198612 },
    { lat: 48.6554701, lng: 21.1194163 },
    { lat: 48.6556112, lng: 21.1194626 },
    { lat: 48.6556463, lng: 21.1193653 },
    { lat: 48.6558156, lng: 21.1193789 },
    { lat: 48.6558725, lng: 21.1191145 },
    { lat: 48.65597, lng: 21.1190302 },
    { lat: 48.6559591, lng: 21.1188316 },
    { lat: 48.6560933, lng: 21.1184184 },
    { lat: 48.6559421, lng: 21.1183387 },
    { lat: 48.6559173, lng: 21.1181255 },
    { lat: 48.656079, lng: 21.1177324 },
    { lat: 48.6562339, lng: 21.1177175 },
    { lat: 48.6563561, lng: 21.1173223 },
    { lat: 48.6566159, lng: 21.1169281 },
    { lat: 48.6567022, lng: 21.1166349 },
    { lat: 48.6566837, lng: 21.1163372 },
    { lat: 48.6565933, lng: 21.1162932 },
    { lat: 48.6566357, lng: 21.1161072 },
    { lat: 48.656549, lng: 21.1158252 },
    { lat: 48.6562467, lng: 21.1154237 },
    { lat: 48.6561339, lng: 21.1154145 },
    { lat: 48.6559986, lng: 21.1152628 },
    { lat: 48.6559377, lng: 21.1150537 },
    { lat: 48.6558264, lng: 21.1150181 },
    { lat: 48.6556389, lng: 21.1147555 },
    { lat: 48.6552608, lng: 21.1145853 },
    { lat: 48.6551508, lng: 21.1142744 },
    { lat: 48.6549178, lng: 21.1142089 },
    { lat: 48.6547975, lng: 21.1139308 },
    { lat: 48.6548148, lng: 21.1136975 },
    { lat: 48.6547694, lng: 21.1135125 },
    { lat: 48.6546579, lng: 21.1134391 },
    { lat: 48.6544817, lng: 21.1134545 },
    { lat: 48.6544345, lng: 21.113285 },
    { lat: 48.654498, lng: 21.1131547 },
    { lat: 48.6545011, lng: 21.1130074 },
    { lat: 48.6546151, lng: 21.1129834 },
    { lat: 48.6546339, lng: 21.1126211 },
    { lat: 48.6544247, lng: 21.1127012 },
    { lat: 48.6543323, lng: 21.1125563 },
    { lat: 48.6543782, lng: 21.1124455 },
    { lat: 48.654378, lng: 21.112124 },
    { lat: 48.6543122, lng: 21.1120712 },
    { lat: 48.6541693, lng: 21.1121454 },
    { lat: 48.6540963, lng: 21.1119749 },
    { lat: 48.6540356, lng: 21.1116259 },
    { lat: 48.6538702, lng: 21.1115579 },
    { lat: 48.6538626, lng: 21.1113591 },
    { lat: 48.6537576, lng: 21.1110639 },
    { lat: 48.6538312, lng: 21.1108677 },
    { lat: 48.6537033, lng: 21.1108789 },
    { lat: 48.653634, lng: 21.1107554 },
    { lat: 48.6535409, lng: 21.1108021 },
    { lat: 48.6535295, lng: 21.1108674 },
    { lat: 48.6534533, lng: 21.1108078 },
    { lat: 48.6533785, lng: 21.1108635 },
    { lat: 48.6533386, lng: 21.1110533 },
    { lat: 48.6532733, lng: 21.1111275 },
    { lat: 48.6532725, lng: 21.1112771 },
    { lat: 48.6531777, lng: 21.1113011 },
    { lat: 48.6530634, lng: 21.1112148 },
    { lat: 48.6529919, lng: 21.1112646 },
    { lat: 48.652945, lng: 21.1110687 },
    { lat: 48.6528866, lng: 21.1110712 },
    { lat: 48.6528407, lng: 21.1112508 },
    { lat: 48.6527825, lng: 21.1112593 },
    { lat: 48.6527188, lng: 21.1111721 },
    { lat: 48.6527144, lng: 21.1109692 },
    { lat: 48.6526493, lng: 21.110865 },
    { lat: 48.6526603, lng: 21.1105125 },
    { lat: 48.6525199, lng: 21.1104517 },
    { lat: 48.6525569, lng: 21.1103411 },
    { lat: 48.6525215, lng: 21.1100457 },
    { lat: 48.6524294, lng: 21.1099475 },
    { lat: 48.6522629, lng: 21.1101231 },
    { lat: 48.6522194, lng: 21.1099953 },
    { lat: 48.6520513, lng: 21.1100212 },
    { lat: 48.652121, lng: 21.1098039 },
    { lat: 48.6521006, lng: 21.1097637 },
    { lat: 48.6519173, lng: 21.1098053 },
    { lat: 48.6518891, lng: 21.1096632 },
    { lat: 48.6517394, lng: 21.1095272 },
    { lat: 48.6516695, lng: 21.109627 },
    { lat: 48.6516107, lng: 21.1096167 },
    { lat: 48.6515228, lng: 21.1092302 },
    { lat: 48.6515637, lng: 21.1091026 },
    { lat: 48.6513784, lng: 21.1091105 },
    { lat: 48.651303, lng: 21.1089737 },
    { lat: 48.6511918, lng: 21.1090512 },
    { lat: 48.6511818, lng: 21.1089544 },
    { lat: 48.6510904, lng: 21.1089793 },
    { lat: 48.6510072, lng: 21.1087017 },
    { lat: 48.6511052, lng: 21.1086703 },
    { lat: 48.6511815, lng: 21.1084825 },
    { lat: 48.6510794, lng: 21.1083597 },
    { lat: 48.6510665, lng: 21.1082005 },
    { lat: 48.6509973, lng: 21.1081472 },
    { lat: 48.6510085, lng: 21.1079734 },
    { lat: 48.6507105, lng: 21.1077735 },
    { lat: 48.6506562, lng: 21.1078009 },
    { lat: 48.6506309, lng: 21.1075725 },
    { lat: 48.6507581, lng: 21.1075386 },
    { lat: 48.6507323, lng: 21.1074323 },
    { lat: 48.6502587, lng: 21.1070947 },
    { lat: 48.6501674, lng: 21.107226 },
    { lat: 48.6501425, lng: 21.1071196 },
    { lat: 48.650046, lng: 21.1070605 },
    { lat: 48.6500761, lng: 21.1068391 },
    { lat: 48.6501999, lng: 21.1066705 },
    { lat: 48.6501448, lng: 21.1062474 },
    { lat: 48.6499693, lng: 21.1062543 },
    { lat: 48.6496587, lng: 21.1060788 },
    { lat: 48.6496711, lng: 21.1058707 },
    { lat: 48.6494279, lng: 21.1057975 },
    { lat: 48.6494188, lng: 21.1056903 },
    { lat: 48.6495033, lng: 21.1053776 },
    { lat: 48.6495969, lng: 21.1053473 },
    { lat: 48.6496038, lng: 21.1052837 },
    { lat: 48.6493418, lng: 21.1050428 },
    { lat: 48.649386, lng: 21.1049139 },
    { lat: 48.6493594, lng: 21.1048279 },
    { lat: 48.6492266, lng: 21.1047544 },
    { lat: 48.649191, lng: 21.1045595 },
    { lat: 48.6492202, lng: 21.1043829 },
    { lat: 48.6493242, lng: 21.1043589 },
    { lat: 48.649343, lng: 21.1042482 },
    { lat: 48.6492969, lng: 21.1041406 },
    { lat: 48.6493527, lng: 21.1039923 },
    { lat: 48.6493329, lng: 21.1039358 },
    { lat: 48.6491607, lng: 21.103869 },
    { lat: 48.6491438, lng: 21.1037596 },
    { lat: 48.6488353, lng: 21.1035851 },
    { lat: 48.6487974, lng: 21.1034558 },
    { lat: 48.6487508, lng: 21.1036112 },
    { lat: 48.6487081, lng: 21.1035823 },
    { lat: 48.6485504, lng: 21.1034038 },
    { lat: 48.6485663, lng: 21.1032663 },
    { lat: 48.6484294, lng: 21.1031394 },
    { lat: 48.6482999, lng: 21.1034158 },
    { lat: 48.6481228, lng: 21.1035769 },
    { lat: 48.6480266, lng: 21.1033897 },
    { lat: 48.6479916, lng: 21.1034876 },
    { lat: 48.6477286, lng: 21.1033268 },
    { lat: 48.6476786, lng: 21.1029319 },
    { lat: 48.6475902, lng: 21.1028398 },
    { lat: 48.6476034, lng: 21.1031467 },
    { lat: 48.6474266, lng: 21.1029362 },
    { lat: 48.6473751, lng: 21.1024217 },
    { lat: 48.6474814, lng: 21.1023623 },
    { lat: 48.6475362, lng: 21.1022116 },
    { lat: 48.6474781, lng: 21.102091 },
    { lat: 48.6473702, lng: 21.1020597 },
    { lat: 48.6473125, lng: 21.1021184 },
    { lat: 48.6472788, lng: 21.1019455 },
    { lat: 48.6471689, lng: 21.1018866 },
    { lat: 48.6471001, lng: 21.1016037 },
    { lat: 48.6471066, lng: 21.1014249 },
    { lat: 48.6469711, lng: 21.1012673 },
    { lat: 48.6469394, lng: 21.1008781 },
    { lat: 48.6469756, lng: 21.1007519 },
    { lat: 48.6468672, lng: 21.1002851 },
    { lat: 48.6468836, lng: 21.1001301 },
  ],
  Budimír: [
    { lat: 48.7813091, lng: 21.3134381 },
    { lat: 48.7813707, lng: 21.3133931 },
    { lat: 48.7915237, lng: 21.313627 },
    { lat: 48.7916572, lng: 21.3132769 },
    { lat: 48.7922959, lng: 21.3123741 },
    { lat: 48.7923363, lng: 21.3122214 },
    { lat: 48.7928097, lng: 21.3118069 },
    { lat: 48.7929361, lng: 21.3118121 },
    { lat: 48.793232, lng: 21.3114781 },
    { lat: 48.7934729, lng: 21.3114067 },
    { lat: 48.7937963, lng: 21.3115341 },
    { lat: 48.7943026, lng: 21.3109749 },
    { lat: 48.794473, lng: 21.3109888 },
    { lat: 48.7951998, lng: 21.3113536 },
    { lat: 48.7958566, lng: 21.3115649 },
    { lat: 48.7961264, lng: 21.3115911 },
    { lat: 48.7966854, lng: 21.3118824 },
    { lat: 48.7968704, lng: 21.3121176 },
    { lat: 48.7971552, lng: 21.3126378 },
    { lat: 48.797394, lng: 21.3129435 },
    { lat: 48.797692, lng: 21.3131219 },
    { lat: 48.7978279, lng: 21.3133727 },
    { lat: 48.7980565, lng: 21.3135213 },
    { lat: 48.7983793, lng: 21.313591 },
    { lat: 48.7985661, lng: 21.313407 },
    { lat: 48.7986535, lng: 21.312914 },
    { lat: 48.7992598, lng: 21.3125353 },
    { lat: 48.7998303, lng: 21.3120172 },
    { lat: 48.7998857, lng: 21.3119877 },
    { lat: 48.8002646, lng: 21.3123804 },
    { lat: 48.8004883, lng: 21.3123409 },
    { lat: 48.8006653, lng: 21.3120573 },
    { lat: 48.8009337, lng: 21.3112979 },
    { lat: 48.8012318, lng: 21.3109181 },
    { lat: 48.8013906, lng: 21.310222 },
    { lat: 48.8015219, lng: 21.3099887 },
    { lat: 48.8023302, lng: 21.3093655 },
    { lat: 48.8027601, lng: 21.3093295 },
    { lat: 48.8034333, lng: 21.3091184 },
    { lat: 48.8036045, lng: 21.308978 },
    { lat: 48.8042977, lng: 21.3088283 },
    { lat: 48.8046888, lng: 21.3086218 },
    { lat: 48.8049897, lng: 21.3084217 },
    { lat: 48.8055202, lng: 21.3076896 },
    { lat: 48.8058299, lng: 21.3074749 },
    { lat: 48.8058952, lng: 21.3074447 },
    { lat: 48.8060838, lng: 21.3071334 },
    { lat: 48.8070575, lng: 21.3067393 },
    { lat: 48.80894, lng: 21.3061046 },
    { lat: 48.816116, lng: 21.303395 },
    { lat: 48.815869, lng: 21.302018 },
    { lat: 48.815812, lng: 21.301489 },
    { lat: 48.815769, lng: 21.301056 },
    { lat: 48.815685, lng: 21.300225 },
    { lat: 48.815661, lng: 21.299842 },
    { lat: 48.815654, lng: 21.299811 },
    { lat: 48.81563, lng: 21.299673 },
    { lat: 48.815625, lng: 21.299646 },
    { lat: 48.815534, lng: 21.298977 },
    { lat: 48.815111, lng: 21.296635 },
    { lat: 48.81477, lng: 21.294689 },
    { lat: 48.814576, lng: 21.293713 },
    { lat: 48.814314, lng: 21.292508 },
    { lat: 48.814178, lng: 21.292089 },
    { lat: 48.814102, lng: 21.291954 },
    { lat: 48.814003, lng: 21.291809 },
    { lat: 48.813729, lng: 21.291375 },
    { lat: 48.813591, lng: 21.291092 },
    { lat: 48.813522, lng: 21.290893 },
    { lat: 48.813447, lng: 21.29039 },
    { lat: 48.813398, lng: 21.289696 },
    { lat: 48.813356, lng: 21.28939 },
    { lat: 48.813311, lng: 21.289204 },
    { lat: 48.813164, lng: 21.288944 },
    { lat: 48.813047, lng: 21.288756 },
    { lat: 48.812799, lng: 21.288393 },
    { lat: 48.812365, lng: 21.28773 },
    { lat: 48.812171, lng: 21.287348 },
    { lat: 48.811973, lng: 21.286885 },
    { lat: 48.811825, lng: 21.286489 },
    { lat: 48.811752, lng: 21.286291 },
    { lat: 48.811492, lng: 21.285492 },
    { lat: 48.8114, lng: 21.285314 },
    { lat: 48.810921, lng: 21.281639 },
    { lat: 48.810875, lng: 21.281254 },
    { lat: 48.810744, lng: 21.280341 },
    { lat: 48.810739, lng: 21.280298 },
    { lat: 48.810789, lng: 21.279754 },
    { lat: 48.810912, lng: 21.279384 },
    { lat: 48.811108, lng: 21.279013 },
    { lat: 48.81128, lng: 21.278762 },
    { lat: 48.811527, lng: 21.27843 },
    { lat: 48.812046, lng: 21.277909 },
    { lat: 48.8121, lng: 21.277761 },
    { lat: 48.812236, lng: 21.27702 },
    { lat: 48.81204, lng: 21.276536 },
    { lat: 48.811894, lng: 21.276005 },
    { lat: 48.811822, lng: 21.275061 },
    { lat: 48.811411, lng: 21.2753746 },
    { lat: 48.8112725, lng: 21.2754079 },
    { lat: 48.8110759, lng: 21.2753439 },
    { lat: 48.8095956, lng: 21.2721065 },
    { lat: 48.8090593, lng: 21.2719329 },
    { lat: 48.8089752, lng: 21.2719452 },
    { lat: 48.8086524, lng: 21.2724692 },
    { lat: 48.8083072, lng: 21.2726913 },
    { lat: 48.8079722, lng: 21.2730326 },
    { lat: 48.8076528, lng: 21.2731529 },
    { lat: 48.8069499, lng: 21.2736762 },
    { lat: 48.8057907, lng: 21.2749626 },
    { lat: 48.8051125, lng: 21.2751938 },
    { lat: 48.8047414, lng: 21.2752409 },
    { lat: 48.8043243, lng: 21.2751043 },
    { lat: 48.8039918, lng: 21.2753033 },
    { lat: 48.8033094, lng: 21.2752895 },
    { lat: 48.8031541, lng: 21.2754779 },
    { lat: 48.8027677, lng: 21.2756125 },
    { lat: 48.8021667, lng: 21.2757158 },
    { lat: 48.8019408, lng: 21.2756906 },
    { lat: 48.8006774, lng: 21.2759176 },
    { lat: 48.8004772, lng: 21.2758188 },
    { lat: 48.7989497, lng: 21.2737845 },
    { lat: 48.7986788, lng: 21.2735416 },
    { lat: 48.7979785, lng: 21.2731222 },
    { lat: 48.7973498, lng: 21.2729508 },
    { lat: 48.7970223, lng: 21.2731009 },
    { lat: 48.7967502, lng: 21.2731479 },
    { lat: 48.7961192, lng: 21.2734854 },
    { lat: 48.795895, lng: 21.2734736 },
    { lat: 48.7955478, lng: 21.2733457 },
    { lat: 48.7951019, lng: 21.2734848 },
    { lat: 48.7947983, lng: 21.273675 },
    { lat: 48.7945663, lng: 21.2737077 },
    { lat: 48.794104, lng: 21.273653 },
    { lat: 48.794078, lng: 21.273773 },
    { lat: 48.793699, lng: 21.274519 },
    { lat: 48.793632, lng: 21.27465 },
    { lat: 48.793056, lng: 21.274911 },
    { lat: 48.792182, lng: 21.275224 },
    { lat: 48.792541, lng: 21.275995 },
    { lat: 48.792932, lng: 21.276925 },
    { lat: 48.793431, lng: 21.278077 },
    { lat: 48.793985, lng: 21.279275 },
    { lat: 48.794539, lng: 21.2805 },
    { lat: 48.795243, lng: 21.282098 },
    { lat: 48.795979, lng: 21.283151 },
    { lat: 48.796213, lng: 21.283769 },
    { lat: 48.796469, lng: 21.284607 },
    { lat: 48.796514, lng: 21.284756 },
    { lat: 48.796525, lng: 21.28479 },
    { lat: 48.796553, lng: 21.284881 },
    { lat: 48.796904, lng: 21.286027 },
    { lat: 48.796899, lng: 21.286061 },
    { lat: 48.796886, lng: 21.286138 },
    { lat: 48.796879, lng: 21.286189 },
    { lat: 48.796631, lng: 21.286349 },
    { lat: 48.796389, lng: 21.286439 },
    { lat: 48.796349, lng: 21.286454 },
    { lat: 48.796269, lng: 21.286484 },
    { lat: 48.796257, lng: 21.286488 },
    { lat: 48.795433, lng: 21.286797 },
    { lat: 48.795414, lng: 21.286804 },
    { lat: 48.795398, lng: 21.28681 },
    { lat: 48.7954, lng: 21.286956 },
    { lat: 48.7954, lng: 21.286983 },
    { lat: 48.795404, lng: 21.287392 },
    { lat: 48.795405, lng: 21.287472 },
    { lat: 48.795406, lng: 21.287679 },
    { lat: 48.795407, lng: 21.287711 },
    { lat: 48.79541, lng: 21.287929 },
    { lat: 48.795409, lng: 21.287957 },
    { lat: 48.795419, lng: 21.288951 },
    { lat: 48.79542, lng: 21.289005 },
    { lat: 48.795421, lng: 21.289164 },
    { lat: 48.795424, lng: 21.289391 },
    { lat: 48.795437, lng: 21.290721 },
    { lat: 48.794638, lng: 21.292451 },
    { lat: 48.794601, lng: 21.292554 },
    { lat: 48.79458, lng: 21.292612 },
    { lat: 48.7945242, lng: 21.2927693 },
    { lat: 48.794502, lng: 21.292832 },
    { lat: 48.794497, lng: 21.292842 },
    { lat: 48.794487, lng: 21.292872 },
    { lat: 48.793403, lng: 21.295877 },
    { lat: 48.793247, lng: 21.29602 },
    { lat: 48.793218, lng: 21.296046 },
    { lat: 48.793146, lng: 21.296113 },
    { lat: 48.792087, lng: 21.297083 },
    { lat: 48.791758, lng: 21.297384 },
    { lat: 48.791091, lng: 21.297995 },
    { lat: 48.789213, lng: 21.298623 },
    { lat: 48.788864, lng: 21.29874 },
    { lat: 48.788569, lng: 21.298838 },
    { lat: 48.788528, lng: 21.298913 },
    { lat: 48.788126, lng: 21.299643 },
    { lat: 48.787671, lng: 21.298556 },
    { lat: 48.786585, lng: 21.299091 },
    { lat: 48.785927, lng: 21.299415 },
    { lat: 48.78583, lng: 21.299463 },
    { lat: 48.785836, lng: 21.299724 },
    { lat: 48.785756, lng: 21.300091 },
    { lat: 48.782874, lng: 21.302047 },
    { lat: 48.781446, lng: 21.303015 },
    { lat: 48.77954, lng: 21.304308 },
    { lat: 48.779533, lng: 21.304314 },
    { lat: 48.779529, lng: 21.304316 },
    { lat: 48.77949, lng: 21.304343 },
    { lat: 48.779438, lng: 21.304378 },
    { lat: 48.77931, lng: 21.304177 },
    { lat: 48.779296, lng: 21.304154 },
    { lat: 48.779293, lng: 21.30415 },
    { lat: 48.779217, lng: 21.304029 },
    { lat: 48.779147, lng: 21.303918 },
    { lat: 48.779124, lng: 21.303882 },
    { lat: 48.778311, lng: 21.30438 },
    { lat: 48.778213, lng: 21.304441 },
    { lat: 48.777479, lng: 21.3047 },
    { lat: 48.77736, lng: 21.304746 },
    { lat: 48.777107, lng: 21.304845 },
    { lat: 48.777039, lng: 21.304871 },
    { lat: 48.776904, lng: 21.304923 },
    { lat: 48.7773234, lng: 21.3049295 },
    { lat: 48.7775243, lng: 21.3050243 },
    { lat: 48.7776926, lng: 21.3052306 },
    { lat: 48.7776814, lng: 21.3054497 },
    { lat: 48.7777188, lng: 21.3055013 },
    { lat: 48.7779299, lng: 21.3056365 },
    { lat: 48.7782667, lng: 21.3060487 },
    { lat: 48.7783948, lng: 21.3067073 },
    { lat: 48.7784804, lng: 21.3068648 },
    { lat: 48.7793498, lng: 21.3095438 },
    { lat: 48.7801828, lng: 21.3099208 },
    { lat: 48.7805539, lng: 21.3105072 },
    { lat: 48.7806048, lng: 21.3109943 },
    { lat: 48.7812368, lng: 21.3131298 },
    { lat: 48.7813091, lng: 21.3134381 },
  ],
  Bunetice: [
    { lat: 48.8412082, lng: 21.3961023 },
    { lat: 48.8423959, lng: 21.3975598 },
    { lat: 48.8426934, lng: 21.3981069 },
    { lat: 48.8446011, lng: 21.4006681 },
    { lat: 48.8451559, lng: 21.4015121 },
    { lat: 48.8469073, lng: 21.4039291 },
    { lat: 48.8530694, lng: 21.4113312 },
    { lat: 48.855659, lng: 21.4147049 },
    { lat: 48.8560566, lng: 21.4150293 },
    { lat: 48.856295, lng: 21.41514 },
    { lat: 48.8567654, lng: 21.4152522 },
    { lat: 48.8574213, lng: 21.4155548 },
    { lat: 48.857811, lng: 21.4156071 },
    { lat: 48.8581652, lng: 21.415522 },
    { lat: 48.8587177, lng: 21.4151398 },
    { lat: 48.8598947, lng: 21.4133096 },
    { lat: 48.8615962, lng: 21.4114001 },
    { lat: 48.8624722, lng: 21.4105832 },
    { lat: 48.8633856, lng: 21.4095042 },
    { lat: 48.8639623, lng: 21.4085352 },
    { lat: 48.8646041, lng: 21.4077101 },
    { lat: 48.8661959, lng: 21.4052159 },
    { lat: 48.8664042, lng: 21.404726 },
    { lat: 48.8665691, lng: 21.4040576 },
    { lat: 48.8666566, lng: 21.4038593 },
    { lat: 48.8667837, lng: 21.4030584 },
    { lat: 48.8668678, lng: 21.4023628 },
    { lat: 48.8668777, lng: 21.4018186 },
    { lat: 48.8669953, lng: 21.4010772 },
    { lat: 48.8675058, lng: 21.398898 },
    { lat: 48.8678459, lng: 21.3977308 },
    { lat: 48.8684789, lng: 21.3951633 },
    { lat: 48.8689995, lng: 21.3962917 },
    { lat: 48.8700723, lng: 21.3991597 },
    { lat: 48.8704338, lng: 21.4004294 },
    { lat: 48.8737591, lng: 21.4106516 },
    { lat: 48.8744444, lng: 21.412182 },
    { lat: 48.874669, lng: 21.412254 },
    { lat: 48.874951, lng: 21.412003 },
    { lat: 48.875131, lng: 21.411643 },
    { lat: 48.875281, lng: 21.411361 },
    { lat: 48.875853, lng: 21.410873 },
    { lat: 48.876231, lng: 21.410706 },
    { lat: 48.876575, lng: 21.410485 },
    { lat: 48.877012, lng: 21.410009 },
    { lat: 48.877405, lng: 21.409613 },
    { lat: 48.877515, lng: 21.409423 },
    { lat: 48.877515, lng: 21.409294 },
    { lat: 48.877492, lng: 21.409069 },
    { lat: 48.87741, lng: 21.409038 },
    { lat: 48.877451, lng: 21.40866 },
    { lat: 48.877595, lng: 21.408217 },
    { lat: 48.877737, lng: 21.407887 },
    { lat: 48.87802, lng: 21.407817 },
    { lat: 48.878177, lng: 21.407651 },
    { lat: 48.878306, lng: 21.407287 },
    { lat: 48.879012, lng: 21.406931 },
    { lat: 48.879456, lng: 21.406655 },
    { lat: 48.880047, lng: 21.405285 },
    { lat: 48.880325, lng: 21.403885 },
    { lat: 48.880519, lng: 21.403132 },
    { lat: 48.880432, lng: 21.402929 },
    { lat: 48.880698, lng: 21.401726 },
    { lat: 48.881051, lng: 21.399729 },
    { lat: 48.881109, lng: 21.399388 },
    { lat: 48.881058, lng: 21.399189 },
    { lat: 48.881145, lng: 21.398915 },
    { lat: 48.881101, lng: 21.398328 },
    { lat: 48.881023, lng: 21.398213 },
    { lat: 48.88119, lng: 21.397932 },
    { lat: 48.881401, lng: 21.397778 },
    { lat: 48.881494, lng: 21.397353 },
    { lat: 48.881655, lng: 21.397173 },
    { lat: 48.881859, lng: 21.397015 },
    { lat: 48.881998, lng: 21.396773 },
    { lat: 48.882151, lng: 21.396704 },
    { lat: 48.88227, lng: 21.396459 },
    { lat: 48.882748, lng: 21.396346 },
    { lat: 48.883145, lng: 21.396204 },
    { lat: 48.883499, lng: 21.396221 },
    { lat: 48.883511, lng: 21.396029 },
    { lat: 48.883518, lng: 21.395924 },
    { lat: 48.883582, lng: 21.394943 },
    { lat: 48.88399, lng: 21.394735 },
    { lat: 48.884183, lng: 21.394806 },
    { lat: 48.884389, lng: 21.3947 },
    { lat: 48.884519, lng: 21.394513 },
    { lat: 48.885109, lng: 21.394337 },
    { lat: 48.884916, lng: 21.393231 },
    { lat: 48.8848, lng: 21.392376 },
    { lat: 48.884401, lng: 21.389707 },
    { lat: 48.884269, lng: 21.389078 },
    { lat: 48.884065, lng: 21.388014 },
    { lat: 48.883834, lng: 21.386877 },
    { lat: 48.883664, lng: 21.385986 },
    { lat: 48.883469, lng: 21.385115 },
    { lat: 48.883179, lng: 21.383715 },
    { lat: 48.882911, lng: 21.382425 },
    { lat: 48.882644, lng: 21.381213 },
    { lat: 48.882583, lng: 21.380838 },
    { lat: 48.881906, lng: 21.377199 },
    { lat: 48.881405, lng: 21.376388 },
    { lat: 48.881014, lng: 21.37554 },
    { lat: 48.880513, lng: 21.37386 },
    { lat: 48.880155, lng: 21.373978 },
    { lat: 48.880049, lng: 21.374013 },
    { lat: 48.879428, lng: 21.374416 },
    { lat: 48.879413, lng: 21.374426 },
    { lat: 48.879207, lng: 21.374554 },
    { lat: 48.878556, lng: 21.375079 },
    { lat: 48.877754, lng: 21.375587 },
    { lat: 48.877414, lng: 21.375834 },
    { lat: 48.877336, lng: 21.375923 },
    { lat: 48.87635, lng: 21.376472 },
    { lat: 48.875919, lng: 21.376764 },
    { lat: 48.875494, lng: 21.37699 },
    { lat: 48.874834, lng: 21.377315 },
    { lat: 48.874754, lng: 21.377169 },
    { lat: 48.87453, lng: 21.37763 },
    { lat: 48.874223, lng: 21.378299 },
    { lat: 48.873992, lng: 21.37868 },
    { lat: 48.873717, lng: 21.378964 },
    { lat: 48.873465, lng: 21.379331 },
    { lat: 48.873218, lng: 21.379731 },
    { lat: 48.873001, lng: 21.379988 },
    { lat: 48.872418, lng: 21.380599 },
    { lat: 48.871241, lng: 21.381032 },
    { lat: 48.87087, lng: 21.380984 },
    { lat: 48.870798, lng: 21.380825 },
    { lat: 48.870158, lng: 21.380765 },
    { lat: 48.869696, lng: 21.38105 },
    { lat: 48.869561, lng: 21.381187 },
    { lat: 48.869543, lng: 21.381152 },
    { lat: 48.869365, lng: 21.381304 },
    { lat: 48.868959, lng: 21.381739 },
    { lat: 48.868723, lng: 21.382009 },
    { lat: 48.86832, lng: 21.382286 },
    { lat: 48.867905, lng: 21.382357 },
    { lat: 48.866959, lng: 21.382446 },
    { lat: 48.866659, lng: 21.382413 },
    { lat: 48.866552, lng: 21.382399 },
    { lat: 48.866118, lng: 21.382346 },
    { lat: 48.865907, lng: 21.38232 },
    { lat: 48.865796, lng: 21.382326 },
    { lat: 48.865386, lng: 21.382357 },
    { lat: 48.865082, lng: 21.382375 },
    { lat: 48.864619, lng: 21.382496 },
    { lat: 48.86435, lng: 21.382603 },
    { lat: 48.863684, lng: 21.38301 },
    { lat: 48.863391, lng: 21.38315 },
    { lat: 48.863243, lng: 21.383223 },
    { lat: 48.86317, lng: 21.3833334 },
    { lat: 48.863006, lng: 21.383377 },
    { lat: 48.8626604, lng: 21.3833641 },
    { lat: 48.8623583, lng: 21.3830187 },
    { lat: 48.8621086, lng: 21.3828371 },
    { lat: 48.8618531, lng: 21.3827326 },
    { lat: 48.8614896, lng: 21.3826813 },
    { lat: 48.8611272, lng: 21.3826889 },
    { lat: 48.8609907, lng: 21.3827017 },
    { lat: 48.8605381, lng: 21.3827875 },
    { lat: 48.8601011, lng: 21.3829566 },
    { lat: 48.8599874, lng: 21.3831019 },
    { lat: 48.8597574, lng: 21.3833721 },
    { lat: 48.8595676, lng: 21.3835385 },
    { lat: 48.8592849, lng: 21.38362 },
    { lat: 48.8580693, lng: 21.3835874 },
    { lat: 48.8575812, lng: 21.3833494 },
    { lat: 48.8570542, lng: 21.3829749 },
    { lat: 48.8565179, lng: 21.3826016 },
    { lat: 48.8561904, lng: 21.3823632 },
    { lat: 48.8559067, lng: 21.3822565 },
    { lat: 48.8557911, lng: 21.3822669 },
    { lat: 48.8548355, lng: 21.382176 },
    { lat: 48.854178, lng: 21.3818486 },
    { lat: 48.8537845, lng: 21.38161 },
    { lat: 48.8534831, lng: 21.3814971 },
    { lat: 48.8531657, lng: 21.381315 },
    { lat: 48.8527554, lng: 21.380845 },
    { lat: 48.852243, lng: 21.380188 },
    { lat: 48.851927, lng: 21.3798867 },
    { lat: 48.8515837, lng: 21.3796668 },
    { lat: 48.850894, lng: 21.379254 },
    { lat: 48.850051, lng: 21.378272 },
    { lat: 48.849946, lng: 21.378113 },
    { lat: 48.84988, lng: 21.377966 },
    { lat: 48.849738, lng: 21.37765 },
    { lat: 48.849665, lng: 21.37749 },
    { lat: 48.849346, lng: 21.377055 },
    { lat: 48.848522, lng: 21.376455 },
    { lat: 48.848357, lng: 21.376179 },
    { lat: 48.847978, lng: 21.375551 },
    { lat: 48.847939, lng: 21.375485 },
    { lat: 48.847901, lng: 21.3755236 },
    { lat: 48.8479773, lng: 21.3757417 },
    { lat: 48.8479377, lng: 21.3761488 },
    { lat: 48.8480171, lng: 21.3764689 },
    { lat: 48.8484722, lng: 21.3773178 },
    { lat: 48.8490071, lng: 21.3785817 },
    { lat: 48.8491737, lng: 21.3787758 },
    { lat: 48.849527, lng: 21.378697 },
    { lat: 48.8501237, lng: 21.3797249 },
    { lat: 48.8498516, lng: 21.3801132 },
    { lat: 48.8494203, lng: 21.3804778 },
    { lat: 48.8489798, lng: 21.3806891 },
    { lat: 48.8490787, lng: 21.3815166 },
    { lat: 48.8491959, lng: 21.3820166 },
    { lat: 48.8493561, lng: 21.3819493 },
    { lat: 48.8494519, lng: 21.3823715 },
    { lat: 48.8497299, lng: 21.3821496 },
    { lat: 48.8503871, lng: 21.3827939 },
    { lat: 48.8501609, lng: 21.3831738 },
    { lat: 48.8494685, lng: 21.3836534 },
    { lat: 48.8494481, lng: 21.3840617 },
    { lat: 48.8491554, lng: 21.3841392 },
    { lat: 48.8491552, lng: 21.3844315 },
    { lat: 48.8488621, lng: 21.3843779 },
    { lat: 48.8488578, lng: 21.3844953 },
    { lat: 48.8482004, lng: 21.3846384 },
    { lat: 48.8478733, lng: 21.3861685 },
    { lat: 48.8477149, lng: 21.3871232 },
    { lat: 48.8494303, lng: 21.3875997 },
    { lat: 48.8497812, lng: 21.3881146 },
    { lat: 48.849345, lng: 21.3888175 },
    { lat: 48.8488321, lng: 21.3900372 },
    { lat: 48.8484902, lng: 21.390348 },
    { lat: 48.8481809, lng: 21.3907325 },
    { lat: 48.8476264, lng: 21.3916091 },
    { lat: 48.8476017, lng: 21.3917615 },
    { lat: 48.8474528, lng: 21.3920539 },
    { lat: 48.8474368, lng: 21.3922783 },
    { lat: 48.8472533, lng: 21.392537 },
    { lat: 48.8471416, lng: 21.3930244 },
    { lat: 48.8466505, lng: 21.3938377 },
    { lat: 48.8466924, lng: 21.3943468 },
    { lat: 48.8466179, lng: 21.3945205 },
    { lat: 48.8465276, lng: 21.3942501 },
    { lat: 48.8463689, lng: 21.3942509 },
    { lat: 48.8460795, lng: 21.3939769 },
    { lat: 48.8460545, lng: 21.3938893 },
    { lat: 48.8459816, lng: 21.3939765 },
    { lat: 48.8459177, lng: 21.3939043 },
    { lat: 48.8458444, lng: 21.3940894 },
    { lat: 48.8456711, lng: 21.394088 },
    { lat: 48.8456556, lng: 21.3938675 },
    { lat: 48.8455734, lng: 21.3936412 },
    { lat: 48.8455182, lng: 21.3936393 },
    { lat: 48.8455021, lng: 21.3935178 },
    { lat: 48.8452954, lng: 21.3934894 },
    { lat: 48.8452107, lng: 21.3936302 },
    { lat: 48.8451156, lng: 21.3935316 },
    { lat: 48.8449966, lng: 21.3935426 },
    { lat: 48.8444091, lng: 21.3927426 },
    { lat: 48.8442494, lng: 21.3926699 },
    { lat: 48.8440707, lng: 21.3922977 },
    { lat: 48.8439882, lng: 21.3919789 },
    { lat: 48.8435076, lng: 21.3908402 },
    { lat: 48.843444, lng: 21.3904373 },
    { lat: 48.8433287, lng: 21.3902297 },
    { lat: 48.8432777, lng: 21.3899899 },
    { lat: 48.842956, lng: 21.3899571 },
    { lat: 48.8427774, lng: 21.3900072 },
    { lat: 48.8425531, lng: 21.3898388 },
    { lat: 48.8423584, lng: 21.3898414 },
    { lat: 48.842094, lng: 21.3896865 },
    { lat: 48.8417485, lng: 21.3897668 },
    { lat: 48.8413102, lng: 21.3895916 },
    { lat: 48.8411612, lng: 21.3895771 },
    { lat: 48.8410366, lng: 21.3908387 },
    { lat: 48.841095, lng: 21.3930757 },
    { lat: 48.8414057, lng: 21.3946031 },
    { lat: 48.841232, lng: 21.3959485 },
    { lat: 48.8412082, lng: 21.3961023 },
  ],
  Čižatice: [
    { lat: 48.7728172, lng: 21.3844277 },
    { lat: 48.7728259, lng: 21.3844595 },
    { lat: 48.7730135, lng: 21.3852794 },
    { lat: 48.7729719, lng: 21.3859993 },
    { lat: 48.773247, lng: 21.3876844 },
    { lat: 48.7742422, lng: 21.3895146 },
    { lat: 48.7750183, lng: 21.3913594 },
    { lat: 48.7751434, lng: 21.3921237 },
    { lat: 48.7751328, lng: 21.3932464 },
    { lat: 48.7753744, lng: 21.3950531 },
    { lat: 48.7754618, lng: 21.3954952 },
    { lat: 48.7762007, lng: 21.3981793 },
    { lat: 48.7766004, lng: 21.4007691 },
    { lat: 48.7767053, lng: 21.4031716 },
    { lat: 48.7770869, lng: 21.405727 },
    { lat: 48.777497, lng: 21.4081036 },
    { lat: 48.7788365, lng: 21.4072027 },
    { lat: 48.779146, lng: 21.407054 },
    { lat: 48.7798142, lng: 21.4064365 },
    { lat: 48.7813936, lng: 21.4054654 },
    { lat: 48.7820162, lng: 21.4051756 },
    { lat: 48.7834525, lng: 21.4048096 },
    { lat: 48.7850029, lng: 21.4036209 },
    { lat: 48.7852806, lng: 21.4032378 },
    { lat: 48.7869034, lng: 21.4023299 },
    { lat: 48.7871218, lng: 21.4020307 },
    { lat: 48.7874367, lng: 21.4018744 },
    { lat: 48.7878929, lng: 21.4018068 },
    { lat: 48.7889493, lng: 21.4023728 },
    { lat: 48.7900354, lng: 21.4033694 },
    { lat: 48.7906222, lng: 21.4041084 },
    { lat: 48.7909621, lng: 21.404541 },
    { lat: 48.7910869, lng: 21.4050247 },
    { lat: 48.7911702, lng: 21.4051118 },
    { lat: 48.7918321, lng: 21.4047537 },
    { lat: 48.7927315, lng: 21.4040554 },
    { lat: 48.7951614, lng: 21.4030364 },
    { lat: 48.7958022, lng: 21.4027137 },
    { lat: 48.7961176, lng: 21.402546 },
    { lat: 48.7967522, lng: 21.4021878 },
    { lat: 48.7979101, lng: 21.4013962 },
    { lat: 48.8000579, lng: 21.4005153 },
    { lat: 48.8021583, lng: 21.3991267 },
    { lat: 48.8017418, lng: 21.3978339 },
    { lat: 48.801552, lng: 21.3974998 },
    { lat: 48.8015032, lng: 21.3973494 },
    { lat: 48.8001674, lng: 21.3932306 },
    { lat: 48.8077893, lng: 21.3891535 },
    { lat: 48.8093832, lng: 21.388301 },
    { lat: 48.8092933, lng: 21.3870936 },
    { lat: 48.8097727, lng: 21.3866008 },
    { lat: 48.8103761, lng: 21.3858542 },
    { lat: 48.8119852, lng: 21.3842319 },
    { lat: 48.8120461, lng: 21.3834391 },
    { lat: 48.8125142, lng: 21.3823109 },
    { lat: 48.811684, lng: 21.3804486 },
    { lat: 48.8114479, lng: 21.3797213 },
    { lat: 48.8111534, lng: 21.3788134 },
    { lat: 48.8110134, lng: 21.3783144 },
    { lat: 48.8106671, lng: 21.3770487 },
    { lat: 48.8104758, lng: 21.3762778 },
    { lat: 48.8102821, lng: 21.3758554 },
    { lat: 48.8101079, lng: 21.3754784 },
    { lat: 48.8096261, lng: 21.3744158 },
    { lat: 48.8095741, lng: 21.3741937 },
    { lat: 48.809643, lng: 21.3739979 },
    { lat: 48.8099792, lng: 21.3735032 },
    { lat: 48.810884, lng: 21.3716379 },
    { lat: 48.8111993, lng: 21.3714316 },
    { lat: 48.8115601, lng: 21.369925 },
    { lat: 48.8115926, lng: 21.3692362 },
    { lat: 48.8115587, lng: 21.3690479 },
    { lat: 48.8111276, lng: 21.369007 },
    { lat: 48.8103602, lng: 21.3693438 },
    { lat: 48.810168, lng: 21.3692802 },
    { lat: 48.8096459, lng: 21.3687897 },
    { lat: 48.8088217, lng: 21.3688108 },
    { lat: 48.808262, lng: 21.3689866 },
    { lat: 48.8064523, lng: 21.3689031 },
    { lat: 48.806414, lng: 21.3687483 },
    { lat: 48.8064051, lng: 21.3687121 },
    { lat: 48.8061971, lng: 21.3689091 },
    { lat: 48.8054027, lng: 21.369927 },
    { lat: 48.8047253, lng: 21.3704885 },
    { lat: 48.8043627, lng: 21.3708621 },
    { lat: 48.803548, lng: 21.3719116 },
    { lat: 48.8032782, lng: 21.3723388 },
    { lat: 48.8025792, lng: 21.373087 },
    { lat: 48.8020433, lng: 21.3733799 },
    { lat: 48.8013395, lng: 21.3738899 },
    { lat: 48.8009512, lng: 21.3740407 },
    { lat: 48.800426, lng: 21.3745385 },
    { lat: 48.7995881, lng: 21.3749691 },
    { lat: 48.79909, lng: 21.3750917 },
    { lat: 48.798853, lng: 21.3750721 },
    { lat: 48.7980169, lng: 21.3754074 },
    { lat: 48.7973871, lng: 21.3755777 },
    { lat: 48.7967906, lng: 21.3755704 },
    { lat: 48.7961106, lng: 21.3749166 },
    { lat: 48.7948927, lng: 21.374039 },
    { lat: 48.7937745, lng: 21.3734463 },
    { lat: 48.7936557, lng: 21.3734234 },
    { lat: 48.793231, lng: 21.3735955 },
    { lat: 48.7922494, lng: 21.3738111 },
    { lat: 48.7911408, lng: 21.373808 },
    { lat: 48.7910142, lng: 21.3737149 },
    { lat: 48.7903618, lng: 21.3738659 },
    { lat: 48.7897312, lng: 21.3739276 },
    { lat: 48.7895991, lng: 21.3740262 },
    { lat: 48.789227, lng: 21.3741151 },
    { lat: 48.7879287, lng: 21.3739319 },
    { lat: 48.7857545, lng: 21.374118 },
    { lat: 48.7853321, lng: 21.3742204 },
    { lat: 48.7849335, lng: 21.3744382 },
    { lat: 48.784653, lng: 21.3745041 },
    { lat: 48.7833205, lng: 21.3743475 },
    { lat: 48.7828944, lng: 21.3741729 },
    { lat: 48.7826662, lng: 21.373954 },
    { lat: 48.7821021, lng: 21.3736019 },
    { lat: 48.7813759, lng: 21.3735891 },
    { lat: 48.7809545, lng: 21.3737249 },
    { lat: 48.7807845, lng: 21.3738668 },
    { lat: 48.7805709, lng: 21.3741772 },
    { lat: 48.7801438, lng: 21.3751783 },
    { lat: 48.7797673, lng: 21.375868 },
    { lat: 48.7791915, lng: 21.3766348 },
    { lat: 48.7783922, lng: 21.3774141 },
    { lat: 48.777582, lng: 21.3787038 },
    { lat: 48.7771042, lng: 21.3797361 },
    { lat: 48.7767625, lng: 21.3800834 },
    { lat: 48.7762632, lng: 21.3804364 },
    { lat: 48.7756222, lng: 21.3811701 },
    { lat: 48.7751105, lng: 21.3815957 },
    { lat: 48.7747649, lng: 21.3821537 },
    { lat: 48.7744474, lng: 21.3829813 },
    { lat: 48.7742311, lng: 21.3832422 },
    { lat: 48.7741324, lng: 21.3834736 },
    { lat: 48.7735195, lng: 21.3842086 },
    { lat: 48.7728404, lng: 21.384415 },
    { lat: 48.7728172, lng: 21.3844277 },
  ],
  Sokoľany: [],
  Rešica: [
    { lat: 48.5557205, lng: 21.0065372 },
    { lat: 48.5556446, lng: 21.0065163 },
    { lat: 48.5543583, lng: 21.0056815 },
    { lat: 48.5529262, lng: 21.0051594 },
    { lat: 48.551363, lng: 21.0049315 },
    { lat: 48.5502944, lng: 21.0046151 },
    { lat: 48.550063, lng: 21.0043931 },
    { lat: 48.5499264, lng: 21.0039745 },
    { lat: 48.5498119, lng: 21.0039157 },
    { lat: 48.5488907, lng: 21.0038685 },
    { lat: 48.5484843, lng: 21.0037631 },
    { lat: 48.5479064, lng: 21.0031725 },
    { lat: 48.5477705, lng: 21.0028779 },
    { lat: 48.5475935, lng: 21.002429 },
    { lat: 48.5475015, lng: 21.0020303 },
    { lat: 48.5474912, lng: 21.0017916 },
    { lat: 48.5471492, lng: 21.000742 },
    { lat: 48.5470329, lng: 21.0010748 },
    { lat: 48.5463068, lng: 21.0008652 },
    { lat: 48.5463561, lng: 21.0003208 },
    { lat: 48.5464513, lng: 20.999962 },
    { lat: 48.5461134, lng: 20.999894 },
    { lat: 48.545045, lng: 20.9999199 },
    { lat: 48.5433645, lng: 20.9992771 },
    { lat: 48.5432591, lng: 20.9994609 },
    { lat: 48.5432169, lng: 20.9994424 },
    { lat: 48.5428434, lng: 20.9998553 },
    { lat: 48.5426023, lng: 21.0002884 },
    { lat: 48.542109, lng: 21.000825 },
    { lat: 48.5419836, lng: 21.0008064 },
    { lat: 48.5410798, lng: 21.0016872 },
    { lat: 48.5406569, lng: 21.0022782 },
    { lat: 48.5403684, lng: 21.0029453 },
    { lat: 48.5402314, lng: 21.002989 },
    { lat: 48.5398115, lng: 21.0035978 },
    { lat: 48.5397469, lng: 21.0037917 },
    { lat: 48.5393407, lng: 21.0041313 },
    { lat: 48.5392336, lng: 21.0043301 },
    { lat: 48.5389764, lng: 21.0045185 },
    { lat: 48.5386722, lng: 21.004946 },
    { lat: 48.53832, lng: 21.0056264 },
    { lat: 48.5378758, lng: 21.0056115 },
    { lat: 48.5375381, lng: 21.0056767 },
    { lat: 48.5368047, lng: 21.0061287 },
    { lat: 48.5363955, lng: 21.0061825 },
    { lat: 48.535833, lng: 21.0061272 },
    { lat: 48.5327753, lng: 21.0053984 },
    { lat: 48.5318119, lng: 21.00526 },
    { lat: 48.5304903, lng: 21.0047852 },
    { lat: 48.5283282, lng: 21.0029284 },
    { lat: 48.527465, lng: 21.002766 },
    { lat: 48.527374, lng: 21.004087 },
    { lat: 48.527347, lng: 21.004084 },
    { lat: 48.528038, lng: 21.005749 },
    { lat: 48.528427, lng: 21.00678 },
    { lat: 48.528866, lng: 21.00794 },
    { lat: 48.529638, lng: 21.009644 },
    { lat: 48.530434, lng: 21.0114 },
    { lat: 48.530787, lng: 21.011867 },
    { lat: 48.531193, lng: 21.012404 },
    { lat: 48.531806, lng: 21.013713 },
    { lat: 48.532006, lng: 21.014288 },
    { lat: 48.532313, lng: 21.015114 },
    { lat: 48.532085, lng: 21.015654 },
    { lat: 48.531881, lng: 21.016127 },
    { lat: 48.531305, lng: 21.017535 },
    { lat: 48.530874, lng: 21.018495 },
    { lat: 48.530632, lng: 21.019089 },
    { lat: 48.530609, lng: 21.019668 },
    { lat: 48.530515, lng: 21.020279 },
    { lat: 48.530278, lng: 21.02079 },
    { lat: 48.529876, lng: 21.021743 },
    { lat: 48.529458, lng: 21.023265 },
    { lat: 48.530628, lng: 21.025044 },
    { lat: 48.531112, lng: 21.02595 },
    { lat: 48.531465, lng: 21.026511 },
    { lat: 48.531168, lng: 21.028145 },
    { lat: 48.530988, lng: 21.029137 },
    { lat: 48.530779, lng: 21.0302833 },
    { lat: 48.530587, lng: 21.030159 },
    { lat: 48.529519, lng: 21.031315 },
    { lat: 48.528773, lng: 21.032115 },
    { lat: 48.527917, lng: 21.033028 },
    { lat: 48.527087, lng: 21.033939 },
    { lat: 48.52711, lng: 21.033999 },
    { lat: 48.527082, lng: 21.034 },
    { lat: 48.527124, lng: 21.034192 },
    { lat: 48.527173, lng: 21.034524 },
    { lat: 48.5272, lng: 21.034702 },
    { lat: 48.527256, lng: 21.034966 },
    { lat: 48.527322, lng: 21.03535 },
    { lat: 48.527386, lng: 21.035846 },
    { lat: 48.527416, lng: 21.036084 },
    { lat: 48.527442, lng: 21.036372 },
    { lat: 48.527486, lng: 21.036849 },
    { lat: 48.527504, lng: 21.03707 },
    { lat: 48.527517, lng: 21.037326 },
    { lat: 48.527516, lng: 21.037594 },
    { lat: 48.527492, lng: 21.037929 },
    { lat: 48.527456, lng: 21.038153 },
    { lat: 48.527358, lng: 21.038492 },
    { lat: 48.527272, lng: 21.038719 },
    { lat: 48.52686, lng: 21.039576 },
    { lat: 48.526718, lng: 21.039903 },
    { lat: 48.526568, lng: 21.040295 },
    { lat: 48.526398, lng: 21.040702 },
    { lat: 48.526258, lng: 21.041091 },
    { lat: 48.525931, lng: 21.042037 },
    { lat: 48.525964, lng: 21.042049 },
    { lat: 48.525861, lng: 21.042488 },
    { lat: 48.525823, lng: 21.042721 },
    { lat: 48.525773, lng: 21.04323 },
    { lat: 48.525759, lng: 21.043312 },
    { lat: 48.525706, lng: 21.043677 },
    { lat: 48.52566, lng: 21.044141 },
    { lat: 48.525657, lng: 21.044605 },
    { lat: 48.525674, lng: 21.045024 },
    { lat: 48.525715, lng: 21.045716 },
    { lat: 48.525748, lng: 21.046492 },
    { lat: 48.52577, lng: 21.047267 },
    { lat: 48.525807, lng: 21.047898 },
    { lat: 48.52583, lng: 21.048238 },
    { lat: 48.525845, lng: 21.048941 },
    { lat: 48.525914, lng: 21.050041 },
    { lat: 48.525947, lng: 21.05058 },
    { lat: 48.525975, lng: 21.051439 },
    { lat: 48.526042, lng: 21.05228 },
    { lat: 48.526082, lng: 21.052852 },
    { lat: 48.526156, lng: 21.053209 },
    { lat: 48.5269655, lng: 21.0562683 },
    { lat: 48.5271246, lng: 21.0571709 },
    { lat: 48.5276449, lng: 21.0585537 },
    { lat: 48.5279217, lng: 21.0584511 },
    { lat: 48.5289441, lng: 21.0582883 },
    { lat: 48.5290661, lng: 21.0583701 },
    { lat: 48.5293151, lng: 21.0583754 },
    { lat: 48.5295112, lng: 21.0584869 },
    { lat: 48.5296269, lng: 21.0584826 },
    { lat: 48.5299744, lng: 21.0583702 },
    { lat: 48.5301395, lng: 21.058218 },
    { lat: 48.530326, lng: 21.0582084 },
    { lat: 48.5322283, lng: 21.0589682 },
    { lat: 48.5332008, lng: 21.0584553 },
    { lat: 48.5337627, lng: 21.0580837 },
    { lat: 48.5339952, lng: 21.0580689 },
    { lat: 48.5345389, lng: 21.0578964 },
    { lat: 48.5345905, lng: 21.0577857 },
    { lat: 48.5349589, lng: 21.0579381 },
    { lat: 48.5352306, lng: 21.0579143 },
    { lat: 48.535424, lng: 21.0577714 },
    { lat: 48.5357924, lng: 21.0577476 },
    { lat: 48.5362388, lng: 21.0579766 },
    { lat: 48.5373456, lng: 21.0582972 },
    { lat: 48.5378835, lng: 21.0591502 },
    { lat: 48.5381911, lng: 21.0595425 },
    { lat: 48.5385736, lng: 21.0597393 },
    { lat: 48.5413373, lng: 21.0599232 },
    { lat: 48.5426471, lng: 21.0599102 },
    { lat: 48.5440782, lng: 21.0596551 },
    { lat: 48.5450275, lng: 21.0589762 },
    { lat: 48.5453485, lng: 21.0587083 },
    { lat: 48.5454825, lng: 21.0584998 },
    { lat: 48.5457755, lng: 21.0583434 },
    { lat: 48.5463108, lng: 21.0581232 },
    { lat: 48.5475896, lng: 21.0580588 },
    { lat: 48.5475965, lng: 21.0579219 },
    { lat: 48.5480563, lng: 21.0577677 },
    { lat: 48.5482045, lng: 21.0575887 },
    { lat: 48.5482173, lng: 21.0573607 },
    { lat: 48.5480514, lng: 21.0571045 },
    { lat: 48.5480489, lng: 21.056819 },
    { lat: 48.548335, lng: 21.0563456 },
    { lat: 48.5482395, lng: 21.0554599 },
    { lat: 48.5489888, lng: 21.0542792 },
    { lat: 48.5495979, lng: 21.0531497 },
    { lat: 48.5498209, lng: 21.052808 },
    { lat: 48.5501193, lng: 21.0525062 },
    { lat: 48.5507364, lng: 21.0524809 },
    { lat: 48.5531289, lng: 21.0520518 },
    { lat: 48.5540555, lng: 21.0519925 },
    { lat: 48.554679, lng: 21.0529893 },
    { lat: 48.5549744, lng: 21.0535543 },
    { lat: 48.5551849, lng: 21.0535841 },
    { lat: 48.5562275, lng: 21.0540424 },
    { lat: 48.5562269, lng: 21.0540239 },
    { lat: 48.5562075, lng: 21.052574 },
    { lat: 48.5562274, lng: 21.0506425 },
    { lat: 48.5567721, lng: 21.0416194 },
    { lat: 48.5577229, lng: 21.0409249 },
    { lat: 48.5580446, lng: 21.0409517 },
    { lat: 48.5584251, lng: 21.040745 },
    { lat: 48.5585359, lng: 21.0405583 },
    { lat: 48.5585283, lng: 21.0403613 },
    { lat: 48.5582528, lng: 21.0399257 },
    { lat: 48.5582189, lng: 21.0397473 },
    { lat: 48.5582754, lng: 21.0385876 },
    { lat: 48.5586837, lng: 21.0376575 },
    { lat: 48.5588834, lng: 21.0358919 },
    { lat: 48.5587293, lng: 21.0350087 },
    { lat: 48.5589392, lng: 21.0341693 },
    { lat: 48.5591143, lng: 21.0339139 },
    { lat: 48.559246, lng: 21.0331284 },
    { lat: 48.5594949, lng: 21.0332018 },
    { lat: 48.5599656, lng: 21.0331784 },
    { lat: 48.560562, lng: 21.0329689 },
    { lat: 48.5607374, lng: 21.0326484 },
    { lat: 48.5605398, lng: 21.0322936 },
    { lat: 48.5605605, lng: 21.0320626 },
    { lat: 48.5605023, lng: 21.0315989 },
    { lat: 48.5602254, lng: 21.0313553 },
    { lat: 48.5603242, lng: 21.0309407 },
    { lat: 48.5602947, lng: 21.0308331 },
    { lat: 48.5601125, lng: 21.0308005 },
    { lat: 48.5600794, lng: 21.0305479 },
    { lat: 48.5601264, lng: 21.0302988 },
    { lat: 48.5599703, lng: 21.029764 },
    { lat: 48.5597422, lng: 21.029742 },
    { lat: 48.5589337, lng: 21.0292584 },
    { lat: 48.5588664, lng: 21.0290261 },
    { lat: 48.558842, lng: 21.0282139 },
    { lat: 48.5586022, lng: 21.0281814 },
    { lat: 48.558353, lng: 21.0277555 },
    { lat: 48.5582004, lng: 21.0276689 },
    { lat: 48.5578222, lng: 21.0276745 },
    { lat: 48.5577105, lng: 21.0275905 },
    { lat: 48.5576105, lng: 21.0273617 },
    { lat: 48.5576054, lng: 21.0271371 },
    { lat: 48.5572296, lng: 21.0269713 },
    { lat: 48.5571614, lng: 21.0270183 },
    { lat: 48.5569127, lng: 21.026817 },
    { lat: 48.5569375, lng: 21.0266815 },
    { lat: 48.5573018, lng: 21.026192 },
    { lat: 48.5573624, lng: 21.0259853 },
    { lat: 48.5573435, lng: 21.0257831 },
    { lat: 48.557245, lng: 21.0256007 },
    { lat: 48.5571553, lng: 21.025541 },
    { lat: 48.5572754, lng: 21.0244046 },
    { lat: 48.5571938, lng: 21.0232268 },
    { lat: 48.5572091, lng: 21.0224969 },
    { lat: 48.5571275, lng: 21.0223133 },
    { lat: 48.5570079, lng: 21.0222696 },
    { lat: 48.5568553, lng: 21.022042 },
    { lat: 48.5566755, lng: 21.0211686 },
    { lat: 48.5565797, lng: 21.020889 },
    { lat: 48.556518, lng: 21.0205209 },
    { lat: 48.5566203, lng: 21.0195326 },
    { lat: 48.5565828, lng: 21.0192856 },
    { lat: 48.5565021, lng: 21.0190909 },
    { lat: 48.5563761, lng: 21.0190545 },
    { lat: 48.5560719, lng: 21.0193556 },
    { lat: 48.5559502, lng: 21.0193128 },
    { lat: 48.555681, lng: 21.0181541 },
    { lat: 48.5557662, lng: 21.0175295 },
    { lat: 48.5557458, lng: 21.017455 },
    { lat: 48.5555575, lng: 21.0173103 },
    { lat: 48.5554591, lng: 21.0171589 },
    { lat: 48.5554413, lng: 21.0170341 },
    { lat: 48.5559448, lng: 21.0162963 },
    { lat: 48.5559609, lng: 21.0161338 },
    { lat: 48.5558349, lng: 21.0153854 },
    { lat: 48.5558552, lng: 21.015321 },
    { lat: 48.5561828, lng: 21.0151489 },
    { lat: 48.5562061, lng: 21.0149407 },
    { lat: 48.556113, lng: 21.0146436 },
    { lat: 48.5557402, lng: 21.0140224 },
    { lat: 48.5559048, lng: 21.0138644 },
    { lat: 48.5558502, lng: 21.0133668 },
    { lat: 48.555564, lng: 21.0128827 },
    { lat: 48.5551001, lng: 21.0125413 },
    { lat: 48.5550256, lng: 21.0124009 },
    { lat: 48.5550702, lng: 21.0122142 },
    { lat: 48.5550322, lng: 21.0119499 },
    { lat: 48.5552382, lng: 21.0115421 },
    { lat: 48.5553394, lng: 21.0114414 },
    { lat: 48.5558926, lng: 21.0112883 },
    { lat: 48.5560777, lng: 21.0110924 },
    { lat: 48.555655, lng: 21.0095481 },
    { lat: 48.5557281, lng: 21.0072714 },
    { lat: 48.5557205, lng: 21.0065372 },
  ],
  ZlatáIdka: [
    { lat: 48.7582989, lng: 21.0451195 },
    { lat: 48.7587082, lng: 21.044149 },
    { lat: 48.7590974, lng: 21.043314 },
    { lat: 48.7597206, lng: 21.0421701 },
    { lat: 48.7603675, lng: 21.0402655 },
    { lat: 48.7608127, lng: 21.0370886 },
    { lat: 48.7609933, lng: 21.0363887 },
    { lat: 48.7616812, lng: 21.0355027 },
    { lat: 48.761611, lng: 21.0350717 },
    { lat: 48.7616312, lng: 21.034631 },
    { lat: 48.7624066, lng: 21.0332186 },
    { lat: 48.7640319, lng: 21.0285939 },
    { lat: 48.7651607, lng: 21.0258155 },
    { lat: 48.7651748, lng: 21.02494 },
    { lat: 48.7653877, lng: 21.0242971 },
    { lat: 48.765509, lng: 21.0236062 },
    { lat: 48.7655318, lng: 21.0229626 },
    { lat: 48.7656381, lng: 21.0223235 },
    { lat: 48.7658161, lng: 21.0220564 },
    { lat: 48.7661865, lng: 21.0211355 },
    { lat: 48.7667987, lng: 21.0200062 },
    { lat: 48.7672981, lng: 21.0193153 },
    { lat: 48.7685342, lng: 21.0183973 },
    { lat: 48.7693922, lng: 21.0175873 },
    { lat: 48.7699108, lng: 21.0175425 },
    { lat: 48.7703378, lng: 21.0172901 },
    { lat: 48.7708655, lng: 21.0168322 },
    { lat: 48.7712802, lng: 21.0162047 },
    { lat: 48.7716668, lng: 21.0159778 },
    { lat: 48.772689, lng: 21.015646 },
    { lat: 48.772592, lng: 21.014544 },
    { lat: 48.77222, lng: 21.013179 },
    { lat: 48.772099, lng: 21.012728 },
    { lat: 48.771671, lng: 21.011456 },
    { lat: 48.771314, lng: 21.009691 },
    { lat: 48.771221, lng: 21.009259 },
    { lat: 48.771142, lng: 21.008884 },
    { lat: 48.770804, lng: 21.007296 },
    { lat: 48.7707634, lng: 21.0066261 },
    { lat: 48.770754, lng: 21.006471 },
    { lat: 48.770655, lng: 21.005714 },
    { lat: 48.770567, lng: 21.004966 },
    { lat: 48.770426, lng: 21.003906 },
    { lat: 48.77038, lng: 21.002764 },
    { lat: 48.770845, lng: 21.001973 },
    { lat: 48.771043, lng: 21.001241 },
    { lat: 48.77122, lng: 21.00058 },
    { lat: 48.771361, lng: 21.000244 },
    { lat: 48.771605, lng: 20.999884 },
    { lat: 48.771633, lng: 20.999462 },
    { lat: 48.771925, lng: 20.998947 },
    { lat: 48.772391, lng: 20.998041 },
    { lat: 48.77243, lng: 20.997945 },
    { lat: 48.77277, lng: 20.996879 },
    { lat: 48.772979, lng: 20.99608 },
    { lat: 48.77311, lng: 20.994871 },
    { lat: 48.773375, lng: 20.993342 },
    { lat: 48.773549, lng: 20.992118 },
    { lat: 48.773628, lng: 20.991543 },
    { lat: 48.773864, lng: 20.990991 },
    { lat: 48.774286, lng: 20.98978 },
    { lat: 48.774658, lng: 20.988588 },
    { lat: 48.774804, lng: 20.987677 },
    { lat: 48.774989, lng: 20.98636 },
    { lat: 48.775095, lng: 20.986168 },
    { lat: 48.775919, lng: 20.983397 },
    { lat: 48.776059, lng: 20.983069 },
    { lat: 48.776641, lng: 20.981614 },
    { lat: 48.7774195, lng: 20.9806071 },
    { lat: 48.777477, lng: 20.980531 },
    { lat: 48.777186, lng: 20.978866 },
    { lat: 48.778371, lng: 20.973792 },
    { lat: 48.778513, lng: 20.973262 },
    { lat: 48.778684, lng: 20.972263 },
    { lat: 48.778769, lng: 20.971431 },
    { lat: 48.778812, lng: 20.970666 },
    { lat: 48.778828, lng: 20.969906 },
    { lat: 48.778799, lng: 20.96917 },
    { lat: 48.778721, lng: 20.968433 },
    { lat: 48.7787114, lng: 20.9683728 },
    { lat: 48.77853, lng: 20.9672407 },
    { lat: 48.778437, lng: 20.96666 },
    { lat: 48.778066, lng: 20.964444 },
    { lat: 48.777837, lng: 20.962735 },
    { lat: 48.777399, lng: 20.960304 },
    { lat: 48.777016, lng: 20.957798 },
    { lat: 48.776788, lng: 20.956316 },
    { lat: 48.776636, lng: 20.954953 },
    { lat: 48.776592, lng: 20.954964 },
    { lat: 48.776572, lng: 20.954969 },
    { lat: 48.776443, lng: 20.95439 },
    { lat: 48.776096, lng: 20.953302 },
    { lat: 48.775953, lng: 20.952723 },
    { lat: 48.7758817, lng: 20.9521459 },
    { lat: 48.775885, lng: 20.952091 },
    { lat: 48.775756, lng: 20.951449 },
    { lat: 48.775697, lng: 20.950732 },
    { lat: 48.77554, lng: 20.950011 },
    { lat: 48.775473, lng: 20.94952 },
    { lat: 48.775486, lng: 20.949088 },
    { lat: 48.775488, lng: 20.948698 },
    { lat: 48.775418, lng: 20.948247 },
    { lat: 48.775264, lng: 20.947649 },
    { lat: 48.775111, lng: 20.946699 },
    { lat: 48.775097, lng: 20.9467 },
    { lat: 48.774206, lng: 20.946725 },
    { lat: 48.773123, lng: 20.945773 },
    { lat: 48.7727062, lng: 20.9472095 },
    { lat: 48.7710412, lng: 20.9521318 },
    { lat: 48.7708502, lng: 20.9528522 },
    { lat: 48.7702708, lng: 20.953398 },
    { lat: 48.7692782, lng: 20.9549795 },
    { lat: 48.7679479, lng: 20.957441 },
    { lat: 48.7674718, lng: 20.9581133 },
    { lat: 48.7666625, lng: 20.9594586 },
    { lat: 48.7664361, lng: 20.9601289 },
    { lat: 48.7663165, lng: 20.9601841 },
    { lat: 48.7660619, lng: 20.9606203 },
    { lat: 48.7655874, lng: 20.9615735 },
    { lat: 48.7651645, lng: 20.9626252 },
    { lat: 48.7647539, lng: 20.9631058 },
    { lat: 48.7644544, lng: 20.9641462 },
    { lat: 48.7641603, lng: 20.9645408 },
    { lat: 48.7641452, lng: 20.9646979 },
    { lat: 48.76386, lng: 20.9651553 },
    { lat: 48.7636852, lng: 20.965889 },
    { lat: 48.7637285, lng: 20.9660691 },
    { lat: 48.7635595, lng: 20.9665743 },
    { lat: 48.7632759, lng: 20.9670077 },
    { lat: 48.7632044, lng: 20.9672272 },
    { lat: 48.7632056, lng: 20.9675029 },
    { lat: 48.7630646, lng: 20.9676236 },
    { lat: 48.7630081, lng: 20.967995 },
    { lat: 48.76291, lng: 20.9680892 },
    { lat: 48.7628985, lng: 20.968317 },
    { lat: 48.7628114, lng: 20.9685049 },
    { lat: 48.7627243, lng: 20.9689693 },
    { lat: 48.7624517, lng: 20.969162 },
    { lat: 48.7623447, lng: 20.9694606 },
    { lat: 48.7622257, lng: 20.9701144 },
    { lat: 48.7622573, lng: 20.970249 },
    { lat: 48.7620734, lng: 20.9707468 },
    { lat: 48.7615792, lng: 20.9717902 },
    { lat: 48.7613926, lng: 20.9718625 },
    { lat: 48.761313, lng: 20.9722125 },
    { lat: 48.7612154, lng: 20.9723891 },
    { lat: 48.7610738, lng: 20.9728679 },
    { lat: 48.7610464, lng: 20.9731654 },
    { lat: 48.7610001, lng: 20.9745432 },
    { lat: 48.7610908, lng: 20.9754028 },
    { lat: 48.7610228, lng: 20.9755297 },
    { lat: 48.7610334, lng: 20.9758103 },
    { lat: 48.7611531, lng: 20.9759272 },
    { lat: 48.7611256, lng: 20.9763852 },
    { lat: 48.7611749, lng: 20.9768805 },
    { lat: 48.7610642, lng: 20.977209 },
    { lat: 48.7611125, lng: 20.9775424 },
    { lat: 48.7609294, lng: 20.9780651 },
    { lat: 48.7610816, lng: 20.9784413 },
    { lat: 48.760975, lng: 20.9788247 },
    { lat: 48.7610188, lng: 20.9790904 },
    { lat: 48.760988, lng: 20.9793849 },
    { lat: 48.7610815, lng: 20.9796579 },
    { lat: 48.7610216, lng: 20.9799567 },
    { lat: 48.7608016, lng: 20.9801429 },
    { lat: 48.7603008, lng: 20.9827864 },
    { lat: 48.7600913, lng: 20.9834933 },
    { lat: 48.7601142, lng: 20.9837078 },
    { lat: 48.7599851, lng: 20.9841856 },
    { lat: 48.7598036, lng: 20.9843532 },
    { lat: 48.7595634, lng: 20.9849829 },
    { lat: 48.7596004, lng: 20.9852448 },
    { lat: 48.7594111, lng: 20.9856791 },
    { lat: 48.7593601, lng: 20.986111 },
    { lat: 48.759266, lng: 20.9862595 },
    { lat: 48.7590885, lng: 20.9862722 },
    { lat: 48.7589852, lng: 20.9864834 },
    { lat: 48.7588391, lng: 20.9865234 },
    { lat: 48.7588021, lng: 20.9865944 },
    { lat: 48.758508, lng: 20.9865528 },
    { lat: 48.7582351, lng: 20.9868715 },
    { lat: 48.757582, lng: 20.9869025 },
    { lat: 48.7571584, lng: 20.9871559 },
    { lat: 48.7570141, lng: 20.987358 },
    { lat: 48.7568001, lng: 20.9874185 },
    { lat: 48.7563353, lng: 20.9879264 },
    { lat: 48.7560203, lng: 20.988647 },
    { lat: 48.7559194, lng: 20.988725 },
    { lat: 48.7556756, lng: 20.9892818 },
    { lat: 48.7556443, lng: 20.9895617 },
    { lat: 48.7555364, lng: 20.9896662 },
    { lat: 48.7554869, lng: 20.9898354 },
    { lat: 48.7554354, lng: 20.989911 },
    { lat: 48.7551754, lng: 20.9900153 },
    { lat: 48.7550685, lng: 20.9903562 },
    { lat: 48.7549763, lng: 20.9903582 },
    { lat: 48.7547944, lng: 20.990504 },
    { lat: 48.7545109, lng: 20.9909154 },
    { lat: 48.7544057, lng: 20.9914438 },
    { lat: 48.7542305, lng: 20.9919005 },
    { lat: 48.7541135, lng: 20.9920706 },
    { lat: 48.7540874, lng: 20.992435 },
    { lat: 48.7540139, lng: 20.9925576 },
    { lat: 48.7539953, lng: 20.9928165 },
    { lat: 48.7534344, lng: 20.9930413 },
    { lat: 48.7531925, lng: 20.9933853 },
    { lat: 48.7529762, lng: 20.9935144 },
    { lat: 48.7526653, lng: 20.9939108 },
    { lat: 48.7526385, lng: 20.9940551 },
    { lat: 48.7524679, lng: 20.9943095 },
    { lat: 48.7524896, lng: 20.9944177 },
    { lat: 48.7522815, lng: 20.994762 },
    { lat: 48.7522641, lng: 20.9949848 },
    { lat: 48.7523272, lng: 20.9951513 },
    { lat: 48.7522768, lng: 20.9953673 },
    { lat: 48.7520027, lng: 20.9956531 },
    { lat: 48.7519234, lng: 20.9958071 },
    { lat: 48.7519165, lng: 20.995905 },
    { lat: 48.752045, lng: 20.9962891 },
    { lat: 48.7520172, lng: 20.9963682 },
    { lat: 48.7518625, lng: 20.9964813 },
    { lat: 48.7518068, lng: 20.9966364 },
    { lat: 48.7518046, lng: 20.9968117 },
    { lat: 48.7516292, lng: 20.997193 },
    { lat: 48.7516493, lng: 20.9974565 },
    { lat: 48.7513684, lng: 20.997913 },
    { lat: 48.7510594, lng: 20.9986123 },
    { lat: 48.7505636, lng: 20.9992009 },
    { lat: 48.7503031, lng: 20.9992894 },
    { lat: 48.7497909, lng: 21.0000045 },
    { lat: 48.7494295, lng: 21.000437 },
    { lat: 48.7492795, lng: 21.0005307 },
    { lat: 48.7490938, lng: 21.0008422 },
    { lat: 48.7487581, lng: 21.0011761 },
    { lat: 48.7484395, lng: 21.0013754 },
    { lat: 48.7482627, lng: 21.0013723 },
    { lat: 48.7482341, lng: 21.0015275 },
    { lat: 48.7479394, lng: 21.0020081 },
    { lat: 48.7476305, lng: 21.0023025 },
    { lat: 48.7474558, lng: 21.0026342 },
    { lat: 48.7472647, lng: 21.002674 },
    { lat: 48.7470575, lng: 21.0029422 },
    { lat: 48.7469289, lng: 21.0028692 },
    { lat: 48.746869, lng: 21.0028976 },
    { lat: 48.746693, lng: 21.0032586 },
    { lat: 48.7462786, lng: 21.0038303 },
    { lat: 48.7462865, lng: 21.0041335 },
    { lat: 48.7461321, lng: 21.0045051 },
    { lat: 48.745871, lng: 21.004814 },
    { lat: 48.7458851, lng: 21.0051051 },
    { lat: 48.7459575, lng: 21.0052191 },
    { lat: 48.7458527, lng: 21.0056496 },
    { lat: 48.7457211, lng: 21.005829 },
    { lat: 48.7455441, lng: 21.0063962 },
    { lat: 48.7451544, lng: 21.0069021 },
    { lat: 48.7449103, lng: 21.0074519 },
    { lat: 48.7448871, lng: 21.0077005 },
    { lat: 48.7449418, lng: 21.0080626 },
    { lat: 48.7447862, lng: 21.0081571 },
    { lat: 48.7447764, lng: 21.0086104 },
    { lat: 48.7447254, lng: 21.008703 },
    { lat: 48.7446317, lng: 21.0095429 },
    { lat: 48.7445208, lng: 21.0097324 },
    { lat: 48.7444931, lng: 21.0109367 },
    { lat: 48.7442686, lng: 21.0116038 },
    { lat: 48.7441768, lng: 21.0117241 },
    { lat: 48.7441152, lng: 21.0120365 },
    { lat: 48.7441072, lng: 21.0125799 },
    { lat: 48.7439629, lng: 21.0131521 },
    { lat: 48.7439501, lng: 21.0134095 },
    { lat: 48.7437507, lng: 21.0138147 },
    { lat: 48.7435256, lng: 21.0144975 },
    { lat: 48.7433071, lng: 21.0148014 },
    { lat: 48.7432732, lng: 21.0149327 },
    { lat: 48.7433131, lng: 21.0152542 },
    { lat: 48.7430744, lng: 21.0159344 },
    { lat: 48.7429836, lng: 21.016494 },
    { lat: 48.7429803, lng: 21.016501 },
    { lat: 48.7424905, lng: 21.0171378 },
    { lat: 48.7424227, lng: 21.0173358 },
    { lat: 48.7420109, lng: 21.0178889 },
    { lat: 48.741891, lng: 21.0181429 },
    { lat: 48.7412816, lng: 21.0189177 },
    { lat: 48.7411461, lng: 21.0192785 },
    { lat: 48.7408387, lng: 21.019686 },
    { lat: 48.7407526, lng: 21.0199432 },
    { lat: 48.7407189, lng: 21.0203166 },
    { lat: 48.7403561, lng: 21.0208517 },
    { lat: 48.7399234, lng: 21.0211753 },
    { lat: 48.7397713, lng: 21.0218946 },
    { lat: 48.7396394, lng: 21.0221919 },
    { lat: 48.7392014, lng: 21.0228721 },
    { lat: 48.7392295, lng: 21.0231467 },
    { lat: 48.7388975, lng: 21.0235225 },
    { lat: 48.7389216, lng: 21.0237076 },
    { lat: 48.7387167, lng: 21.0240831 },
    { lat: 48.7384764, lng: 21.0242709 },
    { lat: 48.738347, lng: 21.0245092 },
    { lat: 48.7383014, lng: 21.0247669 },
    { lat: 48.7381234, lng: 21.0248969 },
    { lat: 48.7377878, lng: 21.0253357 },
    { lat: 48.7376676, lng: 21.0253808 },
    { lat: 48.7372503, lng: 21.0261389 },
    { lat: 48.7370204, lng: 21.0259971 },
    { lat: 48.7367608, lng: 21.0262134 },
    { lat: 48.7366462, lng: 21.0261504 },
    { lat: 48.736228, lng: 21.0263722 },
    { lat: 48.7361643, lng: 21.0264868 },
    { lat: 48.7361347, lng: 21.0267493 },
    { lat: 48.7359942, lng: 21.0268569 },
    { lat: 48.73581, lng: 21.0271446 },
    { lat: 48.7353854, lng: 21.0276172 },
    { lat: 48.7352691, lng: 21.027637 },
    { lat: 48.7350528, lng: 21.0275306 },
    { lat: 48.7346526, lng: 21.0278537 },
    { lat: 48.7344675, lng: 21.0281482 },
    { lat: 48.7343622, lng: 21.0281601 },
    { lat: 48.7342368, lng: 21.0283506 },
    { lat: 48.733956, lng: 21.0284027 },
    { lat: 48.7338168, lng: 21.0287619 },
    { lat: 48.7333466, lng: 21.0294878 },
    { lat: 48.7332565, lng: 21.0297263 },
    { lat: 48.7328155, lng: 21.0297327 },
    { lat: 48.7326486, lng: 21.0301317 },
    { lat: 48.732298, lng: 21.0299423 },
    { lat: 48.7313436, lng: 21.0309699 },
    { lat: 48.731222, lng: 21.0311765 },
    { lat: 48.7307769, lng: 21.0312958 },
    { lat: 48.7306572, lng: 21.0316289 },
    { lat: 48.7302223, lng: 21.0319592 },
    { lat: 48.7300185, lng: 21.0329161 },
    { lat: 48.7297099, lng: 21.0328779 },
    { lat: 48.7295986, lng: 21.0332225 },
    { lat: 48.7296212, lng: 21.0335359 },
    { lat: 48.7294672, lng: 21.0338808 },
    { lat: 48.7290374, lng: 21.0341961 },
    { lat: 48.7290366, lng: 21.0345538 },
    { lat: 48.728877, lng: 21.0345896 },
    { lat: 48.728811, lng: 21.0347425 },
    { lat: 48.7286452, lng: 21.0349008 },
    { lat: 48.7284571, lng: 21.0349634 },
    { lat: 48.7283857, lng: 21.0350849 },
    { lat: 48.7283609, lng: 21.0352572 },
    { lat: 48.7280878, lng: 21.0355098 },
    { lat: 48.7279773, lng: 21.0361234 },
    { lat: 48.7277451, lng: 21.0369347 },
    { lat: 48.7275752, lng: 21.0377296 },
    { lat: 48.7273683, lng: 21.0380439 },
    { lat: 48.726991, lng: 21.0388939 },
    { lat: 48.7273344, lng: 21.0393405 },
    { lat: 48.7275615, lng: 21.0396786 },
    { lat: 48.7286982, lng: 21.040914 },
    { lat: 48.7293041, lng: 21.0433953 },
    { lat: 48.7297809, lng: 21.0437611 },
    { lat: 48.730283, lng: 21.0440062 },
    { lat: 48.733268, lng: 21.0461397 },
    { lat: 48.7342717, lng: 21.0464747 },
    { lat: 48.7352867, lng: 21.0469551 },
    { lat: 48.7358909, lng: 21.0474669 },
    { lat: 48.7362934, lng: 21.0479299 },
    { lat: 48.7379674, lng: 21.0480088 },
    { lat: 48.7396182, lng: 21.047347 },
    { lat: 48.741231, lng: 21.0465828 },
    { lat: 48.7418207, lng: 21.0464132 },
    { lat: 48.7452703, lng: 21.0445169 },
    { lat: 48.7466525, lng: 21.0436561 },
    { lat: 48.7473761, lng: 21.0433819 },
    { lat: 48.7479657, lng: 21.0433745 },
    { lat: 48.748631, lng: 21.0435169 },
    { lat: 48.7493229, lng: 21.0435069 },
    { lat: 48.7499933, lng: 21.0436975 },
    { lat: 48.7505573, lng: 21.0437701 },
    { lat: 48.7510295, lng: 21.0440665 },
    { lat: 48.7516152, lng: 21.0441842 },
    { lat: 48.7520513, lng: 21.0442001 },
    { lat: 48.7525454, lng: 21.0443361 },
    { lat: 48.7539639, lng: 21.0455134 },
    { lat: 48.7550858, lng: 21.0473663 },
    { lat: 48.7555173, lng: 21.0473119 },
    { lat: 48.7574409, lng: 21.0468292 },
    { lat: 48.7577166, lng: 21.04628 },
    { lat: 48.7582989, lng: 21.0451195 },
  ],
  Hačava: [
    { lat: 48.7019067, lng: 20.8410998 },
    { lat: 48.7018942, lng: 20.8409714 },
    { lat: 48.7018764, lng: 20.8406471 },
    { lat: 48.7022262, lng: 20.8387179 },
    { lat: 48.7024852, lng: 20.8379334 },
    { lat: 48.7029311, lng: 20.8376135 },
    { lat: 48.7040052, lng: 20.8361838 },
    { lat: 48.7040255, lng: 20.8356316 },
    { lat: 48.7038672, lng: 20.8355147 },
    { lat: 48.7035798, lng: 20.8354379 },
    { lat: 48.7035229, lng: 20.8352931 },
    { lat: 48.7037198, lng: 20.8347997 },
    { lat: 48.7038672, lng: 20.8347374 },
    { lat: 48.7040592, lng: 20.8348181 },
    { lat: 48.7040793, lng: 20.8343907 },
    { lat: 48.7041805, lng: 20.8341229 },
    { lat: 48.704168, lng: 20.8337956 },
    { lat: 48.7042524, lng: 20.8334404 },
    { lat: 48.7041678, lng: 20.8331695 },
    { lat: 48.7038589, lng: 20.8328332 },
    { lat: 48.7037412, lng: 20.8325859 },
    { lat: 48.7038798, lng: 20.8318404 },
    { lat: 48.7039283, lng: 20.8309299 },
    { lat: 48.7041765, lng: 20.830168 },
    { lat: 48.7043304, lng: 20.8294776 },
    { lat: 48.704333, lng: 20.8291965 },
    { lat: 48.7045847, lng: 20.8285972 },
    { lat: 48.7050843, lng: 20.827841 },
    { lat: 48.7051882, lng: 20.8274625 },
    { lat: 48.7051507, lng: 20.8273211 },
    { lat: 48.7043762, lng: 20.8249132 },
    { lat: 48.7042969, lng: 20.8249009 },
    { lat: 48.7041682, lng: 20.8240782 },
    { lat: 48.7038652, lng: 20.8233955 },
    { lat: 48.7039121, lng: 20.8230261 },
    { lat: 48.7035768, lng: 20.8226089 },
    { lat: 48.7034366, lng: 20.8225848 },
    { lat: 48.7034946, lng: 20.8223809 },
    { lat: 48.7034363, lng: 20.8222248 },
    { lat: 48.7032219, lng: 20.8221099 },
    { lat: 48.7032051, lng: 20.8218572 },
    { lat: 48.7028991, lng: 20.8215011 },
    { lat: 48.7028477, lng: 20.8211267 },
    { lat: 48.7026451, lng: 20.8209909 },
    { lat: 48.7022797, lng: 20.8185783 },
    { lat: 48.7022348, lng: 20.8185776 },
    { lat: 48.7020787, lng: 20.8178102 },
    { lat: 48.7019225, lng: 20.8173942 },
    { lat: 48.7018306, lng: 20.8172817 },
    { lat: 48.7015906, lng: 20.8163685 },
    { lat: 48.7013439, lng: 20.8158748 },
    { lat: 48.7011838, lng: 20.815219 },
    { lat: 48.7008431, lng: 20.8144206 },
    { lat: 48.7005614, lng: 20.8130228 },
    { lat: 48.7003638, lng: 20.8122923 },
    { lat: 48.7002884, lng: 20.8122255 },
    { lat: 48.7002552, lng: 20.811596 },
    { lat: 48.7000765, lng: 20.8111519 },
    { lat: 48.7001991, lng: 20.810955 },
    { lat: 48.7001579, lng: 20.8107719 },
    { lat: 48.7000394, lng: 20.8107297 },
    { lat: 48.6997822, lng: 20.8101047 },
    { lat: 48.6994298, lng: 20.8095133 },
    { lat: 48.6993343, lng: 20.8092633 },
    { lat: 48.6992174, lng: 20.809234 },
    { lat: 48.6988459, lng: 20.8085241 },
    { lat: 48.6985684, lng: 20.808121 },
    { lat: 48.6982353, lng: 20.8074077 },
    { lat: 48.6979613, lng: 20.807139 },
    { lat: 48.6976936, lng: 20.8064372 },
    { lat: 48.6975121, lng: 20.8062998 },
    { lat: 48.6974072, lng: 20.8059358 },
    { lat: 48.6966267, lng: 20.8052117 },
    { lat: 48.6965965, lng: 20.804929 },
    { lat: 48.6964781, lng: 20.8047585 },
    { lat: 48.6959854, lng: 20.8043554 },
    { lat: 48.6958777, lng: 20.8040725 },
    { lat: 48.6954889, lng: 20.8036045 },
    { lat: 48.6950012, lng: 20.803277 },
    { lat: 48.6946692, lng: 20.802827 },
    { lat: 48.6941774, lng: 20.8024784 },
    { lat: 48.6934454, lng: 20.8017584 },
    { lat: 48.6928206, lng: 20.8012401 },
    { lat: 48.6925991, lng: 20.8006046 },
    { lat: 48.6922593, lng: 20.8003527 },
    { lat: 48.6913153, lng: 20.7993646 },
    { lat: 48.6908186, lng: 20.7990043 },
    { lat: 48.6905501, lng: 20.798886 },
    { lat: 48.6901583, lng: 20.7989813 },
    { lat: 48.6897086, lng: 20.79842 },
    { lat: 48.6896944, lng: 20.79827 },
    { lat: 48.6892182, lng: 20.7976901 },
    { lat: 48.6887197, lng: 20.7974722 },
    { lat: 48.6876885, lng: 20.7962132 },
    { lat: 48.6871635, lng: 20.7953904 },
    { lat: 48.686811, lng: 20.7949692 },
    { lat: 48.6861432, lng: 20.7943825 },
    { lat: 48.6860077, lng: 20.7940506 },
    { lat: 48.685527, lng: 20.7934967 },
    { lat: 48.6849613, lng: 20.7926469 },
    { lat: 48.6846995, lng: 20.7918587 },
    { lat: 48.6845122, lng: 20.7915872 },
    { lat: 48.6841147, lng: 20.7902319 },
    { lat: 48.6838945, lng: 20.7899866 },
    { lat: 48.683551, lng: 20.789108 },
    { lat: 48.6832735, lng: 20.7887087 },
    { lat: 48.6829379, lng: 20.7875777 },
    { lat: 48.6825441, lng: 20.7871374 },
    { lat: 48.6821911, lng: 20.786021 },
    { lat: 48.6813325, lng: 20.7839431 },
    { lat: 48.6811899, lng: 20.7825032 },
    { lat: 48.6812007, lng: 20.7817225 },
    { lat: 48.6810603, lng: 20.7812824 },
    { lat: 48.6810873, lng: 20.7808 },
    { lat: 48.6810122, lng: 20.7803892 },
    { lat: 48.6809981, lng: 20.7793483 },
    { lat: 48.6809238, lng: 20.7790924 },
    { lat: 48.6809323, lng: 20.7785602 },
    { lat: 48.6811391, lng: 20.7781333 },
    { lat: 48.6810677, lng: 20.7773154 },
    { lat: 48.6810866, lng: 20.776798 },
    { lat: 48.6808993, lng: 20.7756616 },
    { lat: 48.6807226, lng: 20.77528 },
    { lat: 48.6805488, lng: 20.7738893 },
    { lat: 48.6806262, lng: 20.7737502 },
    { lat: 48.6805178, lng: 20.7732961 },
    { lat: 48.6805938, lng: 20.7730214 },
    { lat: 48.6804809, lng: 20.7727882 },
    { lat: 48.6806061, lng: 20.7723172 },
    { lat: 48.6809167, lng: 20.7724078 },
    { lat: 48.6809181, lng: 20.772195 },
    { lat: 48.6808493, lng: 20.7720619 },
    { lat: 48.6809233, lng: 20.7719519 },
    { lat: 48.6809184, lng: 20.771719 },
    { lat: 48.680744, lng: 20.7711783 },
    { lat: 48.6808794, lng: 20.7705422 },
    { lat: 48.6807972, lng: 20.7703166 },
    { lat: 48.6807628, lng: 20.7698893 },
    { lat: 48.6810358, lng: 20.7693609 },
    { lat: 48.681118, lng: 20.7682036 },
    { lat: 48.6815008, lng: 20.7675374 },
    { lat: 48.6815063, lng: 20.7673706 },
    { lat: 48.6816114, lng: 20.7673465 },
    { lat: 48.6816942, lng: 20.7671453 },
    { lat: 48.6817996, lng: 20.7670385 },
    { lat: 48.6817917, lng: 20.7669394 },
    { lat: 48.6819889, lng: 20.7664979 },
    { lat: 48.6819924, lng: 20.7662756 },
    { lat: 48.682054, lng: 20.7662351 },
    { lat: 48.6821406, lng: 20.7656253 },
    { lat: 48.6823894, lng: 20.765113 },
    { lat: 48.6823689, lng: 20.7646587 },
    { lat: 48.682645, lng: 20.7639646 },
    { lat: 48.6828737, lng: 20.7631375 },
    { lat: 48.682821, lng: 20.7630452 },
    { lat: 48.6830554, lng: 20.7626352 },
    { lat: 48.6832156, lng: 20.7613975 },
    { lat: 48.6831062, lng: 20.7610764 },
    { lat: 48.6831612, lng: 20.7608757 },
    { lat: 48.6830967, lng: 20.7606451 },
    { lat: 48.6831145, lng: 20.7605082 },
    { lat: 48.6830435, lng: 20.7604142 },
    { lat: 48.6829394, lng: 20.7597574 },
    { lat: 48.682665, lng: 20.7589996 },
    { lat: 48.6825493, lng: 20.758814 },
    { lat: 48.6821879, lng: 20.7585606 },
    { lat: 48.682129, lng: 20.75839 },
    { lat: 48.681552, lng: 20.7578286 },
    { lat: 48.6810542, lng: 20.757599 },
    { lat: 48.6809825, lng: 20.7572574 },
    { lat: 48.6792779, lng: 20.755879 },
    { lat: 48.6786561, lng: 20.7551649 },
    { lat: 48.6784299, lng: 20.755005 },
    { lat: 48.6781573, lng: 20.7542746 },
    { lat: 48.6779616, lng: 20.7541165 },
    { lat: 48.6775593, lng: 20.7533712 },
    { lat: 48.6774462, lng: 20.7529735 },
    { lat: 48.6773003, lng: 20.7528581 },
    { lat: 48.6772007, lng: 20.7526217 },
    { lat: 48.6769047, lng: 20.7523691 },
    { lat: 48.676831, lng: 20.7521261 },
    { lat: 48.6765749, lng: 20.751923 },
    { lat: 48.6764188, lng: 20.7516758 },
    { lat: 48.6755341, lng: 20.7498036 },
    { lat: 48.6753871, lng: 20.7490503 },
    { lat: 48.6749597, lng: 20.7476617 },
    { lat: 48.6748155, lng: 20.7469541 },
    { lat: 48.6741907, lng: 20.745647 },
    { lat: 48.6738379, lng: 20.7436297 },
    { lat: 48.673676, lng: 20.7417938 },
    { lat: 48.673364, lng: 20.740802 },
    { lat: 48.673355, lng: 20.740777 },
    { lat: 48.673219, lng: 20.7405849 },
    { lat: 48.673214, lng: 20.740575 },
    { lat: 48.671772, lng: 20.737731 },
    { lat: 48.671337, lng: 20.736732 },
    { lat: 48.67077, lng: 20.737587 },
    { lat: 48.670413, lng: 20.738127 },
    { lat: 48.670363, lng: 20.738553 },
    { lat: 48.670109, lng: 20.739267 },
    { lat: 48.669892, lng: 20.73994 },
    { lat: 48.669758, lng: 20.740254 },
    { lat: 48.66969, lng: 20.740665 },
    { lat: 48.669672, lng: 20.741129 },
    { lat: 48.669735, lng: 20.741546 },
    { lat: 48.669773, lng: 20.741961 },
    { lat: 48.669784, lng: 20.742565 },
    { lat: 48.669629, lng: 20.743906 },
    { lat: 48.669511, lng: 20.74431 },
    { lat: 48.669505, lng: 20.744536 },
    { lat: 48.668597, lng: 20.747891 },
    { lat: 48.668577, lng: 20.747961 },
    { lat: 48.667914, lng: 20.75041 },
    { lat: 48.667896, lng: 20.750634 },
    { lat: 48.667656, lng: 20.751333 },
    { lat: 48.667523, lng: 20.751929 },
    { lat: 48.667332, lng: 20.752436 },
    { lat: 48.667192, lng: 20.752729 },
    { lat: 48.666802, lng: 20.753317 },
    { lat: 48.666545, lng: 20.753813 },
    { lat: 48.66655, lng: 20.753868 },
    { lat: 48.666553, lng: 20.753895 },
    { lat: 48.666373, lng: 20.754511 },
    { lat: 48.666272, lng: 20.755174 },
    { lat: 48.666123, lng: 20.755822 },
    { lat: 48.666147, lng: 20.756291 },
    { lat: 48.666122, lng: 20.756404 },
    { lat: 48.66589, lng: 20.756615 },
    { lat: 48.665648, lng: 20.756767 },
    { lat: 48.665515, lng: 20.756959 },
    { lat: 48.665149, lng: 20.757153 },
    { lat: 48.665038, lng: 20.757345 },
    { lat: 48.664775, lng: 20.758132 },
    { lat: 48.664722, lng: 20.758481 },
    { lat: 48.664726, lng: 20.758866 },
    { lat: 48.664708, lng: 20.75913 },
    { lat: 48.664386, lng: 20.760706 },
    { lat: 48.664354, lng: 20.760936 },
    { lat: 48.664343, lng: 20.761224 },
    { lat: 48.664429, lng: 20.761652 },
    { lat: 48.664616, lng: 20.762292 },
    { lat: 48.665046, lng: 20.76383 },
    { lat: 48.665218, lng: 20.764102 },
    { lat: 48.665477, lng: 20.764879 },
    { lat: 48.665527, lng: 20.765476 },
    { lat: 48.665491, lng: 20.765722 },
    { lat: 48.665493, lng: 20.766436 },
    { lat: 48.665524, lng: 20.766923 },
    { lat: 48.665448, lng: 20.76803 },
    { lat: 48.66544, lng: 20.768333 },
    { lat: 48.665445, lng: 20.768722 },
    { lat: 48.665344, lng: 20.769492 },
    { lat: 48.665184, lng: 20.769797 },
    { lat: 48.665025, lng: 20.77128 },
    { lat: 48.664752, lng: 20.77203 },
    { lat: 48.664744, lng: 20.772456 },
    { lat: 48.664697, lng: 20.772744 },
    { lat: 48.664596, lng: 20.773007 },
    { lat: 48.664451, lng: 20.773291 },
    { lat: 48.66429, lng: 20.774341 },
    { lat: 48.664277, lng: 20.774517 },
    { lat: 48.6643, lng: 20.77487 },
    { lat: 48.664283, lng: 20.775298 },
    { lat: 48.664151, lng: 20.775984 },
    { lat: 48.664004, lng: 20.777707 },
    { lat: 48.663932, lng: 20.778234 },
    { lat: 48.663834, lng: 20.778624 },
    { lat: 48.663781, lng: 20.778896 },
    { lat: 48.663816, lng: 20.779198 },
    { lat: 48.663889, lng: 20.779549 },
    { lat: 48.663907, lng: 20.779821 },
    { lat: 48.663877, lng: 20.780204 },
    { lat: 48.663904, lng: 20.78054 },
    { lat: 48.663986, lng: 20.780891 },
    { lat: 48.664121, lng: 20.781333 },
    { lat: 48.664162, lng: 20.781861 },
    { lat: 48.664276, lng: 20.782441 },
    { lat: 48.664284, lng: 20.782942 },
    { lat: 48.664249, lng: 20.783351 },
    { lat: 48.664265, lng: 20.783771 },
    { lat: 48.664305, lng: 20.78406 },
    { lat: 48.664438, lng: 20.784372 },
    { lat: 48.664504, lng: 20.784583 },
    { lat: 48.664502, lng: 20.784829 },
    { lat: 48.664605, lng: 20.785549 },
    { lat: 48.664953, lng: 20.786514 },
    { lat: 48.665229, lng: 20.787561 },
    { lat: 48.665309, lng: 20.787851 },
    { lat: 48.665496, lng: 20.788402 },
    { lat: 48.665481, lng: 20.788411 },
    { lat: 48.664431, lng: 20.788976 },
    { lat: 48.663448, lng: 20.789431 },
    { lat: 48.662146, lng: 20.789782 },
    { lat: 48.662128, lng: 20.789786 },
    { lat: 48.662144, lng: 20.790209 },
    { lat: 48.662187, lng: 20.790413 },
    { lat: 48.66217, lng: 20.790703 },
    { lat: 48.66222, lng: 20.790789 },
    { lat: 48.662219, lng: 20.791073 },
    { lat: 48.66231, lng: 20.791336 },
    { lat: 48.662342, lng: 20.791721 },
    { lat: 48.662444, lng: 20.791983 },
    { lat: 48.662454, lng: 20.792332 },
    { lat: 48.662504, lng: 20.792894 },
    { lat: 48.662603, lng: 20.793051 },
    { lat: 48.662587, lng: 20.793528 },
    { lat: 48.662477, lng: 20.793775 },
    { lat: 48.662578, lng: 20.793938 },
    { lat: 48.662477, lng: 20.794329 },
    { lat: 48.662439, lng: 20.794616 },
    { lat: 48.662505, lng: 20.794639 },
    { lat: 48.662506, lng: 20.794823 },
    { lat: 48.662433, lng: 20.794875 },
    { lat: 48.6625, lng: 20.795555 },
    { lat: 48.66244, lng: 20.795611 },
    { lat: 48.662384, lng: 20.795742 },
    { lat: 48.662286, lng: 20.795828 },
    { lat: 48.662272, lng: 20.796159 },
    { lat: 48.662167, lng: 20.79634 },
    { lat: 48.662163, lng: 20.796474 },
    { lat: 48.66223, lng: 20.796803 },
    { lat: 48.662056, lng: 20.797483 },
    { lat: 48.662001, lng: 20.797864 },
    { lat: 48.662003, lng: 20.798055 },
    { lat: 48.661848, lng: 20.798361 },
    { lat: 48.661857, lng: 20.798498 },
    { lat: 48.661794, lng: 20.798614 },
    { lat: 48.661725, lng: 20.798823 },
    { lat: 48.66112, lng: 20.799312 },
    { lat: 48.660991, lng: 20.799654 },
    { lat: 48.660406, lng: 20.800137 },
    { lat: 48.660196, lng: 20.800188 },
    { lat: 48.660009, lng: 20.800359 },
    { lat: 48.659782, lng: 20.800517 },
    { lat: 48.659707, lng: 20.800621 },
    { lat: 48.659496, lng: 20.800805 },
    { lat: 48.659437, lng: 20.800891 },
    { lat: 48.659086, lng: 20.801127 },
    { lat: 48.658965, lng: 20.801089 },
    { lat: 48.658862, lng: 20.801191 },
    { lat: 48.658539, lng: 20.801166 },
    { lat: 48.658336, lng: 20.801249 },
    { lat: 48.658283, lng: 20.801399 },
    { lat: 48.658154, lng: 20.801484 },
    { lat: 48.657897, lng: 20.801581 },
    { lat: 48.65775, lng: 20.80156 },
    { lat: 48.657657, lng: 20.801609 },
    { lat: 48.657574, lng: 20.801584 },
    { lat: 48.657504, lng: 20.801658 },
    { lat: 48.657176, lng: 20.801779 },
    { lat: 48.657008, lng: 20.801742 },
    { lat: 48.656746, lng: 20.801849 },
    { lat: 48.656585, lng: 20.802 },
    { lat: 48.656467, lng: 20.802068 },
    { lat: 48.655941, lng: 20.802099 },
    { lat: 48.655771, lng: 20.801886 },
    { lat: 48.655572, lng: 20.801866 },
    { lat: 48.655306, lng: 20.801971 },
    { lat: 48.655179, lng: 20.802105 },
    { lat: 48.65505, lng: 20.802081 },
    { lat: 48.654911, lng: 20.802151 },
    { lat: 48.654827, lng: 20.802264 },
    { lat: 48.654717, lng: 20.802516 },
    { lat: 48.654594, lng: 20.802506 },
    { lat: 48.654497, lng: 20.802583 },
    { lat: 48.654413, lng: 20.802722 },
    { lat: 48.654282, lng: 20.802758 },
    { lat: 48.654212, lng: 20.802864 },
    { lat: 48.654098, lng: 20.802925 },
    { lat: 48.65391, lng: 20.802801 },
    { lat: 48.653773, lng: 20.80286 },
    { lat: 48.653709, lng: 20.802991 },
    { lat: 48.653639, lng: 20.803031 },
    { lat: 48.653448, lng: 20.803086 },
    { lat: 48.653368, lng: 20.803169 },
    { lat: 48.653059, lng: 20.803273 },
    { lat: 48.652983, lng: 20.803436 },
    { lat: 48.652816, lng: 20.803505 },
    { lat: 48.652711, lng: 20.803667 },
    { lat: 48.652577, lng: 20.803732 },
    { lat: 48.65249, lng: 20.803618 },
    { lat: 48.652383, lng: 20.803572 },
    { lat: 48.652292, lng: 20.803654 },
    { lat: 48.652288, lng: 20.803764 },
    { lat: 48.652137, lng: 20.803947 },
    { lat: 48.652028, lng: 20.803877 },
    { lat: 48.651844, lng: 20.804058 },
    { lat: 48.651645, lng: 20.804184 },
    { lat: 48.651518, lng: 20.80443 },
    { lat: 48.65132, lng: 20.804672 },
    { lat: 48.651318, lng: 20.804724 },
    { lat: 48.651255, lng: 20.804794 },
    { lat: 48.651106, lng: 20.804795 },
    { lat: 48.650938, lng: 20.804881 },
    { lat: 48.650628, lng: 20.805502 },
    { lat: 48.650253, lng: 20.805874 },
    { lat: 48.649444, lng: 20.806808 },
    { lat: 48.649398, lng: 20.806804 },
    { lat: 48.649286, lng: 20.806977 },
    { lat: 48.649147, lng: 20.807067 },
    { lat: 48.649063, lng: 20.807327 },
    { lat: 48.649052, lng: 20.807548 },
    { lat: 48.648964, lng: 20.807704 },
    { lat: 48.648768, lng: 20.807777 },
    { lat: 48.648626, lng: 20.808024 },
    { lat: 48.648479, lng: 20.808123 },
    { lat: 48.648424, lng: 20.80828 },
    { lat: 48.648395, lng: 20.808586 },
    { lat: 48.648272, lng: 20.808818 },
    { lat: 48.648116, lng: 20.808883 },
    { lat: 48.648037, lng: 20.809139 },
    { lat: 48.647864, lng: 20.809279 },
    { lat: 48.64773, lng: 20.809296 },
    { lat: 48.647609, lng: 20.80944 },
    { lat: 48.6478813, lng: 20.8099919 },
    { lat: 48.6480286, lng: 20.8101501 },
    { lat: 48.6483855, lng: 20.810346 },
    { lat: 48.6487552, lng: 20.8108778 },
    { lat: 48.6489365, lng: 20.8114914 },
    { lat: 48.6487199, lng: 20.8122886 },
    { lat: 48.6488479, lng: 20.8127338 },
    { lat: 48.6487157, lng: 20.8139796 },
    { lat: 48.6487414, lng: 20.8151032 },
    { lat: 48.6485814, lng: 20.8161679 },
    { lat: 48.6517105, lng: 20.827341 },
    { lat: 48.6515185, lng: 20.829896 },
    { lat: 48.6511756, lng: 20.8403472 },
    { lat: 48.6512503, lng: 20.8415955 },
    { lat: 48.6512879, lng: 20.847376 },
    { lat: 48.6514986, lng: 20.8493424 },
    { lat: 48.6513855, lng: 20.8496874 },
    { lat: 48.6513767, lng: 20.8509384 },
    { lat: 48.6511126, lng: 20.8516302 },
    { lat: 48.6511487, lng: 20.8521575 },
    { lat: 48.6510227, lng: 20.8529776 },
    { lat: 48.6507421, lng: 20.8535582 },
    { lat: 48.6501778, lng: 20.8540343 },
    { lat: 48.6498556, lng: 20.8539948 },
    { lat: 48.6501283, lng: 20.8547278 },
    { lat: 48.6496716, lng: 20.8556525 },
    { lat: 48.6493574, lng: 20.8558099 },
    { lat: 48.6491185, lng: 20.8604441 },
    { lat: 48.6499263, lng: 20.8641701 },
    { lat: 48.6508063, lng: 20.8656698 },
    { lat: 48.651512, lng: 20.8677987 },
    { lat: 48.6538155, lng: 20.8703288 },
    { lat: 48.6562367, lng: 20.8735106 },
    { lat: 48.6564738, lng: 20.8733657 },
    { lat: 48.6578116, lng: 20.874464 },
    { lat: 48.6593927, lng: 20.8754405 },
    { lat: 48.6594246, lng: 20.8764688 },
    { lat: 48.6591508, lng: 20.8788972 },
    { lat: 48.6579858, lng: 20.8815312 },
    { lat: 48.6590269, lng: 20.88126 },
    { lat: 48.659217, lng: 20.8838594 },
    { lat: 48.65926, lng: 20.8852493 },
    { lat: 48.6603305, lng: 20.887061 },
    { lat: 48.6600812, lng: 20.8885961 },
    { lat: 48.6603745, lng: 20.8913204 },
    { lat: 48.6612761, lng: 20.8924357 },
    { lat: 48.6627206, lng: 20.8934042 },
    { lat: 48.6637914, lng: 20.8943348 },
    { lat: 48.6642236, lng: 20.8958524 },
    { lat: 48.6642324, lng: 20.8958834 },
    { lat: 48.6653429, lng: 20.9003634 },
    { lat: 48.666019, lng: 20.9041858 },
    { lat: 48.6660788, lng: 20.9060503 },
    { lat: 48.6663241, lng: 20.9091255 },
    { lat: 48.6680043, lng: 20.9112739 },
    { lat: 48.6684454, lng: 20.9123944 },
    { lat: 48.6684843, lng: 20.9159671 },
    { lat: 48.6681651, lng: 20.9166769 },
    { lat: 48.6682261, lng: 20.9222686 },
    { lat: 48.6682334, lng: 20.9222189 },
    { lat: 48.6682923, lng: 20.9219312 },
    { lat: 48.6682999, lng: 20.9203929 },
    { lat: 48.6684133, lng: 20.9192556 },
    { lat: 48.6684968, lng: 20.9190248 },
    { lat: 48.6685966, lng: 20.9184203 },
    { lat: 48.6687246, lng: 20.9172399 },
    { lat: 48.6691291, lng: 20.9156588 },
    { lat: 48.6691869, lng: 20.9148456 },
    { lat: 48.6691855, lng: 20.9140736 },
    { lat: 48.6694441, lng: 20.9117053 },
    { lat: 48.6696052, lng: 20.9112103 },
    { lat: 48.6696302, lng: 20.9107857 },
    { lat: 48.6695965, lng: 20.9103926 },
    { lat: 48.6698725, lng: 20.9087737 },
    { lat: 48.6699144, lng: 20.9076187 },
    { lat: 48.6700859, lng: 20.9055769 },
    { lat: 48.6700427, lng: 20.9050012 },
    { lat: 48.670104, lng: 20.9041877 },
    { lat: 48.6707168, lng: 20.9001006 },
    { lat: 48.6707222, lng: 20.8989634 },
    { lat: 48.6710885, lng: 20.8976611 },
    { lat: 48.6712793, lng: 20.8965857 },
    { lat: 48.6713888, lng: 20.8946764 },
    { lat: 48.6712987, lng: 20.894213 },
    { lat: 48.6714529, lng: 20.8938516 },
    { lat: 48.6715096, lng: 20.8924768 },
    { lat: 48.6714836, lng: 20.8920108 },
    { lat: 48.6717669, lng: 20.8900827 },
    { lat: 48.6717468, lng: 20.8898589 },
    { lat: 48.6718433, lng: 20.887968 },
    { lat: 48.6719186, lng: 20.8874358 },
    { lat: 48.6720996, lng: 20.8842687 },
    { lat: 48.6723468, lng: 20.8813209 },
    { lat: 48.6724187, lng: 20.8807262 },
    { lat: 48.6729728, lng: 20.8778715 },
    { lat: 48.6735112, lng: 20.8754785 },
    { lat: 48.6737866, lng: 20.8737232 },
    { lat: 48.6746407, lng: 20.8727671 },
    { lat: 48.6753984, lng: 20.8724715 },
    { lat: 48.6755224, lng: 20.872179 },
    { lat: 48.6754217, lng: 20.8721327 },
    { lat: 48.6757596, lng: 20.871743 },
    { lat: 48.6756187, lng: 20.8714456 },
    { lat: 48.6756719, lng: 20.871388 },
    { lat: 48.6758382, lng: 20.8714419 },
    { lat: 48.676183, lng: 20.8713562 },
    { lat: 48.6762233, lng: 20.8712202 },
    { lat: 48.6764092, lng: 20.8711241 },
    { lat: 48.6767178, lng: 20.8706986 },
    { lat: 48.6768348, lng: 20.8706649 },
    { lat: 48.6770746, lng: 20.8701105 },
    { lat: 48.6772966, lng: 20.8697544 },
    { lat: 48.6775135, lng: 20.8695786 },
    { lat: 48.6777337, lng: 20.8690741 },
    { lat: 48.6777475, lng: 20.8686584 },
    { lat: 48.6780093, lng: 20.8683455 },
    { lat: 48.6781117, lng: 20.8679556 },
    { lat: 48.6782082, lng: 20.8678106 },
    { lat: 48.6782645, lng: 20.8678785 },
    { lat: 48.6787172, lng: 20.8672596 },
    { lat: 48.6789168, lng: 20.8672672 },
    { lat: 48.6795459, lng: 20.8681195 },
    { lat: 48.6798625, lng: 20.868129 },
    { lat: 48.6803507, lng: 20.8678853 },
    { lat: 48.6806606, lng: 20.8680295 },
    { lat: 48.6808762, lng: 20.8679817 },
    { lat: 48.6812175, lng: 20.868152 },
    { lat: 48.6810761, lng: 20.8675104 },
    { lat: 48.6809997, lng: 20.8668214 },
    { lat: 48.680749, lng: 20.8659163 },
    { lat: 48.6810203, lng: 20.8658149 },
    { lat: 48.6812638, lng: 20.8658582 },
    { lat: 48.681544, lng: 20.8656582 },
    { lat: 48.6820396, lng: 20.8648468 },
    { lat: 48.6822286, lng: 20.8646496 },
    { lat: 48.6825147, lng: 20.8639678 },
    { lat: 48.6826512, lng: 20.8638403 },
    { lat: 48.6827512, lng: 20.863608 },
    { lat: 48.6829906, lng: 20.8624183 },
    { lat: 48.6829415, lng: 20.8618415 },
    { lat: 48.6830711, lng: 20.8615471 },
    { lat: 48.6834401, lng: 20.8617088 },
    { lat: 48.6835399, lng: 20.8616942 },
    { lat: 48.6839907, lng: 20.8612221 },
    { lat: 48.6840422, lng: 20.8614358 },
    { lat: 48.6841807, lng: 20.8616711 },
    { lat: 48.6844317, lng: 20.8619328 },
    { lat: 48.6845673, lng: 20.8619468 },
    { lat: 48.6845721, lng: 20.8621541 },
    { lat: 48.6848328, lng: 20.8623353 },
    { lat: 48.6852305, lng: 20.8618881 },
    { lat: 48.6853672, lng: 20.8616024 },
    { lat: 48.6857575, lng: 20.8613053 },
    { lat: 48.6859343, lng: 20.8607097 },
    { lat: 48.6870486, lng: 20.8589123 },
    { lat: 48.6870757, lng: 20.8586494 },
    { lat: 48.6871814, lng: 20.8586108 },
    { lat: 48.6873276, lng: 20.858148 },
    { lat: 48.6873818, lng: 20.857764 },
    { lat: 48.6876472, lng: 20.8571311 },
    { lat: 48.6876248, lng: 20.8565511 },
    { lat: 48.6877909, lng: 20.8559139 },
    { lat: 48.6877841, lng: 20.8551595 },
    { lat: 48.6878097, lng: 20.854726 },
    { lat: 48.6878706, lng: 20.8544658 },
    { lat: 48.6881639, lng: 20.8540234 },
    { lat: 48.6888067, lng: 20.8547429 },
    { lat: 48.6887935, lng: 20.8543944 },
    { lat: 48.6888572, lng: 20.854052 },
    { lat: 48.6891148, lng: 20.8533944 },
    { lat: 48.689059, lng: 20.8530803 },
    { lat: 48.6891919, lng: 20.8527542 },
    { lat: 48.6892457, lng: 20.8519665 },
    { lat: 48.689457, lng: 20.8512046 },
    { lat: 48.6897619, lng: 20.8503195 },
    { lat: 48.6899357, lng: 20.8500383 },
    { lat: 48.6901293, lng: 20.8493782 },
    { lat: 48.6904156, lng: 20.8488016 },
    { lat: 48.6906049, lng: 20.848552 },
    { lat: 48.6907379, lng: 20.8482273 },
    { lat: 48.6908542, lng: 20.8481405 },
    { lat: 48.6912678, lng: 20.8480873 },
    { lat: 48.6917895, lng: 20.8476736 },
    { lat: 48.6919805, lng: 20.8474384 },
    { lat: 48.6921214, lng: 20.8467225 },
    { lat: 48.692275, lng: 20.8463135 },
    { lat: 48.6929041, lng: 20.8452995 },
    { lat: 48.6940798, lng: 20.8443308 },
    { lat: 48.6942137, lng: 20.8440402 },
    { lat: 48.6953354, lng: 20.8427508 },
    { lat: 48.6957503, lng: 20.842442 },
    { lat: 48.6965797, lng: 20.8420378 },
    { lat: 48.6975373, lng: 20.8413463 },
    { lat: 48.697893, lng: 20.8411807 },
    { lat: 48.6983425, lng: 20.8411303 },
    { lat: 48.6989994, lng: 20.841209 },
    { lat: 48.6991398, lng: 20.8411709 },
    { lat: 48.6996912, lng: 20.8412892 },
    { lat: 48.6998962, lng: 20.8412638 },
    { lat: 48.7010471, lng: 20.8408207 },
    { lat: 48.7015957, lng: 20.8410619 },
    { lat: 48.7017652, lng: 20.8412118 },
    { lat: 48.7019067, lng: 20.8410998 },
  ],
  Komárovce: [
    { lat: 48.5895917, lng: 21.1202597 },
    { lat: 48.5895824, lng: 21.1202486 },
    { lat: 48.5891819, lng: 21.1200518 },
    { lat: 48.5890203, lng: 21.120064 },
    { lat: 48.5888808, lng: 21.1201701 },
    { lat: 48.5887768, lng: 21.120017 },
    { lat: 48.588813, lng: 21.1173306 },
    { lat: 48.5887749, lng: 21.1168435 },
    { lat: 48.5878968, lng: 21.1167677 },
    { lat: 48.5872164, lng: 21.1165704 },
    { lat: 48.5858322, lng: 21.1158828 },
    { lat: 48.5854002, lng: 21.1155429 },
    { lat: 48.5844642, lng: 21.1150628 },
    { lat: 48.5833354, lng: 21.1142646 },
    { lat: 48.5831497, lng: 21.114057 },
    { lat: 48.583151, lng: 21.1137463 },
    { lat: 48.5828506, lng: 21.11371 },
    { lat: 48.5828335, lng: 21.1129025 },
    { lat: 48.5824538, lng: 21.1128894 },
    { lat: 48.5824335, lng: 21.1125351 },
    { lat: 48.5823524, lng: 21.1125005 },
    { lat: 48.582368, lng: 21.112114 },
    { lat: 48.5822129, lng: 21.1120873 },
    { lat: 48.5822267, lng: 21.1118123 },
    { lat: 48.5817127, lng: 21.1117589 },
    { lat: 48.5817191, lng: 21.111468 },
    { lat: 48.5812533, lng: 21.1114033 },
    { lat: 48.581258, lng: 21.110921 },
    { lat: 48.5793525, lng: 21.1092449 },
    { lat: 48.5796112, lng: 21.1065174 },
    { lat: 48.5796709, lng: 21.1054747 },
    { lat: 48.5793247, lng: 21.1051759 },
    { lat: 48.5793658, lng: 21.1046369 },
    { lat: 48.5789926, lng: 21.1044809 },
    { lat: 48.579027, lng: 21.1037998 },
    { lat: 48.5781694, lng: 21.103107 },
    { lat: 48.5782222, lng: 21.102441 },
    { lat: 48.5778782, lng: 21.1020361 },
    { lat: 48.5776299, lng: 21.1019143 },
    { lat: 48.5773928, lng: 21.1015383 },
    { lat: 48.577456, lng: 21.1009194 },
    { lat: 48.5768934, lng: 21.1006757 },
    { lat: 48.5769444, lng: 21.099022 },
    { lat: 48.5762078, lng: 21.0983432 },
    { lat: 48.5756357, lng: 21.0976033 },
    { lat: 48.5757005, lng: 21.0968227 },
    { lat: 48.5752074, lng: 21.0966474 },
    { lat: 48.5752099, lng: 21.0964774 },
    { lat: 48.5747298, lng: 21.0963211 },
    { lat: 48.5744256, lng: 21.0954794 },
    { lat: 48.573854, lng: 21.0954806 },
    { lat: 48.5738634, lng: 21.0953566 },
    { lat: 48.5737406, lng: 21.0953532 },
    { lat: 48.5737738, lng: 21.0951595 },
    { lat: 48.5735999, lng: 21.0951819 },
    { lat: 48.5722178, lng: 21.0941765 },
    { lat: 48.5716982, lng: 21.0939432 },
    { lat: 48.5704314, lng: 21.0928504 },
    { lat: 48.5701441, lng: 21.0925311 },
    { lat: 48.5700572, lng: 21.0922798 },
    { lat: 48.5698034, lng: 21.0919456 },
    { lat: 48.5685327, lng: 21.0912552 },
    { lat: 48.5675352, lng: 21.0905862 },
    { lat: 48.5664353, lng: 21.0896743 },
    { lat: 48.5659815, lng: 21.0894636 },
    { lat: 48.5656738, lng: 21.0894863 },
    { lat: 48.5650691, lng: 21.0891985 },
    { lat: 48.5645203, lng: 21.0892507 },
    { lat: 48.5641109, lng: 21.0890126 },
    { lat: 48.5623475, lng: 21.0883309 },
    { lat: 48.5606467, lng: 21.0882952 },
    { lat: 48.5581864, lng: 21.0886768 },
    { lat: 48.5578608, lng: 21.0887744 },
    { lat: 48.5574675, lng: 21.0889956 },
    { lat: 48.5570704, lng: 21.0894199 },
    { lat: 48.5568142, lng: 21.0895716 },
    { lat: 48.5565608, lng: 21.089817 },
    { lat: 48.5562553, lng: 21.0897986 },
    { lat: 48.5561993, lng: 21.0900877 },
    { lat: 48.5559491, lng: 21.09025 },
    { lat: 48.555757, lng: 21.0909516 },
    { lat: 48.5551852, lng: 21.0918588 },
    { lat: 48.5548778, lng: 21.092581 },
    { lat: 48.5556191, lng: 21.0937085 },
    { lat: 48.5550002, lng: 21.096148 },
    { lat: 48.5547459, lng: 21.0963564 },
    { lat: 48.5546095, lng: 21.0963543 },
    { lat: 48.5545456, lng: 21.0962258 },
    { lat: 48.5544209, lng: 21.0961984 },
    { lat: 48.5536304, lng: 21.1009805 },
    { lat: 48.5535369, lng: 21.1011896 },
    { lat: 48.5534273, lng: 21.1014157 },
    { lat: 48.5533496, lng: 21.1020407 },
    { lat: 48.5527847, lng: 21.1049726 },
    { lat: 48.5538503, lng: 21.1067035 },
    { lat: 48.5541832, lng: 21.1074302 },
    { lat: 48.5543507, lng: 21.1079142 },
    { lat: 48.5557196, lng: 21.1123864 },
    { lat: 48.5559317, lng: 21.113376 },
    { lat: 48.5559593, lng: 21.1139545 },
    { lat: 48.5563726, lng: 21.1154265 },
    { lat: 48.5574546, lng: 21.1186889 },
    { lat: 48.5605577, lng: 21.1291863 },
    { lat: 48.5605708, lng: 21.1291775 },
    { lat: 48.5615975, lng: 21.1292893 },
    { lat: 48.5648023, lng: 21.1294452 },
    { lat: 48.5685549, lng: 21.1294131 },
    { lat: 48.5704341, lng: 21.1299272 },
    { lat: 48.5706869, lng: 21.1302627 },
    { lat: 48.5714635, lng: 21.1303345 },
    { lat: 48.5725033, lng: 21.1298692 },
    { lat: 48.5745176, lng: 21.13014 },
    { lat: 48.5764708, lng: 21.1305774 },
    { lat: 48.5780213, lng: 21.1308039 },
    { lat: 48.5786377, lng: 21.1308301 },
    { lat: 48.5798308, lng: 21.1305203 },
    { lat: 48.5806759, lng: 21.1304765 },
    { lat: 48.582492, lng: 21.130706 },
    { lat: 48.5842785, lng: 21.1308208 },
    { lat: 48.5853902, lng: 21.1308126 },
    { lat: 48.5864331, lng: 21.1306193 },
    { lat: 48.5892915, lng: 21.1305066 },
    { lat: 48.5893874, lng: 21.1297748 },
    { lat: 48.5894498, lng: 21.1288099 },
    { lat: 48.5893894, lng: 21.1262568 },
    { lat: 48.5895917, lng: 21.1202597 },
  ],
  Trsťany: [
    { lat: 48.777497, lng: 21.4081036 },
    { lat: 48.7770869, lng: 21.405727 },
    { lat: 48.7767053, lng: 21.4031716 },
    { lat: 48.7766004, lng: 21.4007691 },
    { lat: 48.7762007, lng: 21.3981793 },
    { lat: 48.7754618, lng: 21.3954952 },
    { lat: 48.7753744, lng: 21.3950531 },
    { lat: 48.7751328, lng: 21.3932464 },
    { lat: 48.7751434, lng: 21.3921237 },
    { lat: 48.7750183, lng: 21.3913594 },
    { lat: 48.7742422, lng: 21.3895146 },
    { lat: 48.773247, lng: 21.3876844 },
    { lat: 48.7729719, lng: 21.3859993 },
    { lat: 48.7730135, lng: 21.3852794 },
    { lat: 48.7728259, lng: 21.3844595 },
    { lat: 48.7728172, lng: 21.3844277 },
    { lat: 48.7725619, lng: 21.3839381 },
    { lat: 48.7720091, lng: 21.3837061 },
    { lat: 48.7716876, lng: 21.3838703 },
    { lat: 48.7709661, lng: 21.3838253 },
    { lat: 48.7702967, lng: 21.3839444 },
    { lat: 48.7695075, lng: 21.3837378 },
    { lat: 48.7690244, lng: 21.3838449 },
    { lat: 48.7683336, lng: 21.3835074 },
    { lat: 48.7673576, lng: 21.3828473 },
    { lat: 48.7666464, lng: 21.3820561 },
    { lat: 48.7662593, lng: 21.3818314 },
    { lat: 48.7661295, lng: 21.3818167 },
    { lat: 48.765679, lng: 21.3819961 },
    { lat: 48.7654537, lng: 21.3821763 },
    { lat: 48.7653202, lng: 21.3823016 },
    { lat: 48.7651018, lng: 21.38268 },
    { lat: 48.764706, lng: 21.3831008 },
    { lat: 48.7643571, lng: 21.3832094 },
    { lat: 48.7640389, lng: 21.3834105 },
    { lat: 48.762819, lng: 21.3836691 },
    { lat: 48.7622363, lng: 21.3837135 },
    { lat: 48.7613549, lng: 21.3834375 },
    { lat: 48.7603531, lng: 21.3832461 },
    { lat: 48.7599674, lng: 21.3830638 },
    { lat: 48.7595232, lng: 21.3830766 },
    { lat: 48.7591469, lng: 21.3829816 },
    { lat: 48.7580406, lng: 21.3830856 },
    { lat: 48.7572804, lng: 21.3835949 },
    { lat: 48.7569161, lng: 21.3837214 },
    { lat: 48.7561354, lng: 21.384143 },
    { lat: 48.7558124, lng: 21.3845187 },
    { lat: 48.7552211, lng: 21.3849577 },
    { lat: 48.7538898, lng: 21.3858604 },
    { lat: 48.7534225, lng: 21.3860063 },
    { lat: 48.7528958, lng: 21.386301 },
    { lat: 48.7519757, lng: 21.387069 },
    { lat: 48.7508699, lng: 21.3875729 },
    { lat: 48.7495879, lng: 21.3883615 },
    { lat: 48.7483778, lng: 21.3886071 },
    { lat: 48.74762, lng: 21.388823 },
    { lat: 48.7466845, lng: 21.3892254 },
    { lat: 48.745883, lng: 21.3894018 },
    { lat: 48.7458555, lng: 21.389387 },
    { lat: 48.7463041, lng: 21.3916456 },
    { lat: 48.7463051, lng: 21.3928931 },
    { lat: 48.746437, lng: 21.39389 },
    { lat: 48.7465735, lng: 21.3959448 },
    { lat: 48.7465659, lng: 21.3980006 },
    { lat: 48.7466442, lng: 21.399163 },
    { lat: 48.7467212, lng: 21.3998609 },
    { lat: 48.7475759, lng: 21.4052798 },
    { lat: 48.7479369, lng: 21.4069444 },
    { lat: 48.7483544, lng: 21.4093426 },
    { lat: 48.7487433, lng: 21.4104281 },
    { lat: 48.7497099, lng: 21.4135934 },
    { lat: 48.7503349, lng: 21.4158993 },
    { lat: 48.7506037, lng: 21.4171128 },
    { lat: 48.7506684, lng: 21.4174358 },
    { lat: 48.7517407, lng: 21.4169384 },
    { lat: 48.7528796, lng: 21.4166027 },
    { lat: 48.7543404, lng: 21.4160352 },
    { lat: 48.7550623, lng: 21.4158317 },
    { lat: 48.756035, lng: 21.4157682 },
    { lat: 48.7565497, lng: 21.4156315 },
    { lat: 48.75718, lng: 21.415329 },
    { lat: 48.7583619, lng: 21.4149239 },
    { lat: 48.7584909, lng: 21.4149855 },
    { lat: 48.7613463, lng: 21.4139342 },
    { lat: 48.7618895, lng: 21.4137021 },
    { lat: 48.7623232, lng: 21.4133764 },
    { lat: 48.7625212, lng: 21.4133738 },
    { lat: 48.7631713, lng: 21.4131351 },
    { lat: 48.7636471, lng: 21.4130527 },
    { lat: 48.7639455, lng: 21.4129059 },
    { lat: 48.7642991, lng: 21.4126056 },
    { lat: 48.7667463, lng: 21.4115699 },
    { lat: 48.7678724, lng: 21.4109951 },
    { lat: 48.7680884, lng: 21.4109449 },
    { lat: 48.7695728, lng: 21.4101168 },
    { lat: 48.7705376, lng: 21.4097548 },
    { lat: 48.7720505, lng: 21.4100752 },
    { lat: 48.7724236, lng: 21.4100218 },
    { lat: 48.7731456, lng: 21.409358 },
    { lat: 48.7736522, lng: 21.409369 },
    { lat: 48.7745665, lng: 21.4090068 },
    { lat: 48.776308, lng: 21.4087627 },
    { lat: 48.777497, lng: 21.4081036 },
  ],
  NižnáKamenica: [
    { lat: 48.7730121, lng: 21.4683512 },
    { lat: 48.77288, lng: 21.4682987 },
    { lat: 48.7725591, lng: 21.4679843 },
    { lat: 48.7721005, lng: 21.4674576 },
    { lat: 48.7714327, lng: 21.4670882 },
    { lat: 48.7708531, lng: 21.4661964 },
    { lat: 48.7705314, lng: 21.4658525 },
    { lat: 48.7689744, lng: 21.4645839 },
    { lat: 48.7674296, lng: 21.4629966 },
    { lat: 48.766184, lng: 21.4620687 },
    { lat: 48.7643891, lng: 21.4605579 },
    { lat: 48.7633433, lng: 21.4603853 },
    { lat: 48.7629405, lng: 21.4593882 },
    { lat: 48.7620342, lng: 21.4589443 },
    { lat: 48.7621086, lng: 21.4583683 },
    { lat: 48.7616916, lng: 21.4581914 },
    { lat: 48.7605918, lng: 21.4579783 },
    { lat: 48.7598143, lng: 21.4576367 },
    { lat: 48.7575016, lng: 21.4561536 },
    { lat: 48.7568434, lng: 21.4558905 },
    { lat: 48.7549139, lng: 21.4583271 },
    { lat: 48.7545031, lng: 21.458634 },
    { lat: 48.753684, lng: 21.4589832 },
    { lat: 48.75356, lng: 21.4590955 },
    { lat: 48.7528832, lng: 21.460123 },
    { lat: 48.7524584, lng: 21.4603706 },
    { lat: 48.7520308, lng: 21.4607942 },
    { lat: 48.751757, lng: 21.4610344 },
    { lat: 48.75114, lng: 21.4615406 },
    { lat: 48.7510814, lng: 21.4616893 },
    { lat: 48.7522253, lng: 21.463267 },
    { lat: 48.7531152, lng: 21.4647664 },
    { lat: 48.753134, lng: 21.4649492 },
    { lat: 48.7533791, lng: 21.4653276 },
    { lat: 48.7539309, lng: 21.4666592 },
    { lat: 48.754937, lng: 21.4676746 },
    { lat: 48.755134, lng: 21.4677906 },
    { lat: 48.7547234, lng: 21.4728755 },
    { lat: 48.7546886, lng: 21.4729189 },
    { lat: 48.7557925, lng: 21.4735806 },
    { lat: 48.7556385, lng: 21.4749388 },
    { lat: 48.755288, lng: 21.4771401 },
    { lat: 48.7551165, lng: 21.4780501 },
    { lat: 48.7544899, lng: 21.4805079 },
    { lat: 48.7544725, lng: 21.4808429 },
    { lat: 48.7541737, lng: 21.4824962 },
    { lat: 48.7540676, lng: 21.4834903 },
    { lat: 48.7540633, lng: 21.4858387 },
    { lat: 48.753905, lng: 21.4912676 },
    { lat: 48.7538459, lng: 21.4914009 },
    { lat: 48.7538207, lng: 21.491736 },
    { lat: 48.7537322, lng: 21.4929193 },
    { lat: 48.7536889, lng: 21.4939955 },
    { lat: 48.7539794, lng: 21.4972042 },
    { lat: 48.7541669, lng: 21.4982027 },
    { lat: 48.7543041, lng: 21.4993409 },
    { lat: 48.7544963, lng: 21.5018347 },
    { lat: 48.7545022, lng: 21.5024673 },
    { lat: 48.7544348, lng: 21.5030569 },
    { lat: 48.7541305, lng: 21.5048113 },
    { lat: 48.7541895, lng: 21.5050581 },
    { lat: 48.7543912, lng: 21.5050295 },
    { lat: 48.7544433, lng: 21.5053567 },
    { lat: 48.7549671, lng: 21.5057641 },
    { lat: 48.7553096, lng: 21.5063271 },
    { lat: 48.7553063, lng: 21.5065324 },
    { lat: 48.755359, lng: 21.5067158 },
    { lat: 48.7554598, lng: 21.5066639 },
    { lat: 48.7555227, lng: 21.5067357 },
    { lat: 48.7555303, lng: 21.5070419 },
    { lat: 48.7556679, lng: 21.5075638 },
    { lat: 48.7557952, lng: 21.5077255 },
    { lat: 48.7557857, lng: 21.5079052 },
    { lat: 48.7563317, lng: 21.5086168 },
    { lat: 48.7565806, lng: 21.5096096 },
    { lat: 48.7565484, lng: 21.509933 },
    { lat: 48.7568455, lng: 21.5104524 },
    { lat: 48.7570609, lng: 21.510578 },
    { lat: 48.7570489, lng: 21.5106694 },
    { lat: 48.7573945, lng: 21.511111 },
    { lat: 48.757645, lng: 21.51133 },
    { lat: 48.757665, lng: 21.5114777 },
    { lat: 48.7577245, lng: 21.5114792 },
    { lat: 48.7577162, lng: 21.5125217 },
    { lat: 48.7576337, lng: 21.5132147 },
    { lat: 48.7572574, lng: 21.5144586 },
    { lat: 48.7571939, lng: 21.515146 },
    { lat: 48.7573199, lng: 21.5156185 },
    { lat: 48.7570798, lng: 21.5169333 },
    { lat: 48.7568578, lng: 21.5174314 },
    { lat: 48.7563764, lng: 21.5189778 },
    { lat: 48.7572873, lng: 21.5206952 },
    { lat: 48.7578654, lng: 21.5220624 },
    { lat: 48.7580608, lng: 21.5223659 },
    { lat: 48.7586375, lng: 21.5240386 },
    { lat: 48.759116, lng: 21.5248782 },
    { lat: 48.7597932, lng: 21.5253414 },
    { lat: 48.7600195, lng: 21.5258184 },
    { lat: 48.7601474, lng: 21.5258468 },
    { lat: 48.7602793, lng: 21.5259723 },
    { lat: 48.7607112, lng: 21.5260061 },
    { lat: 48.7610863, lng: 21.5264189 },
    { lat: 48.7617427, lng: 21.5264666 },
    { lat: 48.7624679, lng: 21.5274728 },
    { lat: 48.7626128, lng: 21.528024 },
    { lat: 48.7627628, lng: 21.5293305 },
    { lat: 48.7629117, lng: 21.5298972 },
    { lat: 48.7635444, lng: 21.5310164 },
    { lat: 48.7636254, lng: 21.531453 },
    { lat: 48.7638728, lng: 21.532075 },
    { lat: 48.7645563, lng: 21.532569 },
    { lat: 48.7643982, lng: 21.5330215 },
    { lat: 48.7636924, lng: 21.5342762 },
    { lat: 48.7636543, lng: 21.5345629 },
    { lat: 48.76384, lng: 21.5347142 },
    { lat: 48.7640682, lng: 21.5346326 },
    { lat: 48.7646227, lng: 21.5346614 },
    { lat: 48.7656331, lng: 21.5366803 },
    { lat: 48.7662356, lng: 21.5369382 },
    { lat: 48.766482, lng: 21.5373694 },
    { lat: 48.7668135, lng: 21.537677 },
    { lat: 48.767176, lng: 21.537715 },
    { lat: 48.768138, lng: 21.538167 },
    { lat: 48.768615, lng: 21.53853 },
    { lat: 48.768815, lng: 21.538893 },
    { lat: 48.769307, lng: 21.53995 },
    { lat: 48.769957, lng: 21.540756 },
    { lat: 48.77017, lng: 21.541801 },
    { lat: 48.770757, lng: 21.542497 },
    { lat: 48.77097, lng: 21.542682 },
    { lat: 48.771704, lng: 21.54317 },
    { lat: 48.772596, lng: 21.543796 },
    { lat: 48.772966, lng: 21.543974 },
    { lat: 48.773518, lng: 21.544213 },
    { lat: 48.773992, lng: 21.544606 },
    { lat: 48.774647, lng: 21.54521 },
    { lat: 48.774794, lng: 21.545643 },
    { lat: 48.775238, lng: 21.546114 },
    { lat: 48.775678, lng: 21.545618 },
    { lat: 48.776288, lng: 21.545568 },
    { lat: 48.776704, lng: 21.545541 },
    { lat: 48.777225, lng: 21.545056 },
    { lat: 48.77731, lng: 21.544363 },
    { lat: 48.777784, lng: 21.543671 },
    { lat: 48.778273, lng: 21.543683 },
    { lat: 48.7784, lng: 21.542711 },
    { lat: 48.778758, lng: 21.541974 },
    { lat: 48.778804, lng: 21.541394 },
    { lat: 48.779304, lng: 21.540819 },
    { lat: 48.779777, lng: 21.540347 },
    { lat: 48.7805293, lng: 21.5396937 },
    { lat: 48.781536, lng: 21.540475 },
    { lat: 48.782066, lng: 21.540762 },
    { lat: 48.782279, lng: 21.540877 },
    { lat: 48.782326, lng: 21.540902 },
    { lat: 48.782717, lng: 21.541115 },
    { lat: 48.782817, lng: 21.541122 },
    { lat: 48.782888, lng: 21.541126 },
    { lat: 48.783023, lng: 21.541135 },
    { lat: 48.783422, lng: 21.541164 },
    { lat: 48.783798, lng: 21.541251 },
    { lat: 48.78427, lng: 21.541428 },
    { lat: 48.784481, lng: 21.541448 },
    { lat: 48.785569, lng: 21.541405 },
    { lat: 48.786164, lng: 21.541437 },
    { lat: 48.78644, lng: 21.541023 },
    { lat: 48.786727, lng: 21.540406 },
    { lat: 48.786918, lng: 21.540134 },
    { lat: 48.787178, lng: 21.540009 },
    { lat: 48.787868, lng: 21.540086 },
    { lat: 48.7869851, lng: 21.5378164 },
    { lat: 48.7868035, lng: 21.5377137 },
    { lat: 48.7865522, lng: 21.5369515 },
    { lat: 48.7862908, lng: 21.5366659 },
    { lat: 48.7861445, lng: 21.5363033 },
    { lat: 48.7860212, lng: 21.5361226 },
    { lat: 48.7858828, lng: 21.5361634 },
    { lat: 48.7857587, lng: 21.5359945 },
    { lat: 48.7854658, lng: 21.535387 },
    { lat: 48.7854653, lng: 21.5348975 },
    { lat: 48.7853009, lng: 21.5344147 },
    { lat: 48.7849605, lng: 21.5340762 },
    { lat: 48.7846278, lng: 21.533497 },
    { lat: 48.7845912, lng: 21.5331223 },
    { lat: 48.7846492, lng: 21.5321958 },
    { lat: 48.784743, lng: 21.5319371 },
    { lat: 48.7848526, lng: 21.5310836 },
    { lat: 48.7847924, lng: 21.530866 },
    { lat: 48.7848582, lng: 21.5302127 },
    { lat: 48.7848456, lng: 21.5298845 },
    { lat: 48.7847423, lng: 21.529619 },
    { lat: 48.7847293, lng: 21.5289237 },
    { lat: 48.7846191, lng: 21.5286897 },
    { lat: 48.7845489, lng: 21.5282034 },
    { lat: 48.7845743, lng: 21.527943 },
    { lat: 48.7845391, lng: 21.5276588 },
    { lat: 48.7843935, lng: 21.5271278 },
    { lat: 48.7843625, lng: 21.5265532 },
    { lat: 48.7844188, lng: 21.5262787 },
    { lat: 48.784382, lng: 21.5256657 },
    { lat: 48.7842603, lng: 21.5253821 },
    { lat: 48.7841349, lng: 21.524417 },
    { lat: 48.7841472, lng: 21.5240958 },
    { lat: 48.7840274, lng: 21.5238802 },
    { lat: 48.783827, lng: 21.522889 },
    { lat: 48.7836384, lng: 21.5224703 },
    { lat: 48.7834077, lng: 21.5221189 },
    { lat: 48.7833617, lng: 21.5219282 },
    { lat: 48.7828378, lng: 21.5211108 },
    { lat: 48.7828067, lng: 21.5208488 },
    { lat: 48.782584, lng: 21.5202651 },
    { lat: 48.7824531, lng: 21.5196222 },
    { lat: 48.7823558, lng: 21.5193998 },
    { lat: 48.7823133, lng: 21.5191768 },
    { lat: 48.7823497, lng: 21.519072 },
    { lat: 48.7820833, lng: 21.517308 },
    { lat: 48.781889, lng: 21.5170546 },
    { lat: 48.7817301, lng: 21.5167246 },
    { lat: 48.7815772, lng: 21.5166547 },
    { lat: 48.7814016, lng: 21.5163344 },
    { lat: 48.7810644, lng: 21.5159116 },
    { lat: 48.7808645, lng: 21.5154962 },
    { lat: 48.7806055, lng: 21.5151792 },
    { lat: 48.7803753, lng: 21.5149942 },
    { lat: 48.7802925, lng: 21.5146971 },
    { lat: 48.7800174, lng: 21.5142452 },
    { lat: 48.7799033, lng: 21.5141136 },
    { lat: 48.7797751, lng: 21.5140742 },
    { lat: 48.7797102, lng: 21.5135793 },
    { lat: 48.7796318, lng: 21.5135092 },
    { lat: 48.7796063, lng: 21.5132868 },
    { lat: 48.7793715, lng: 21.5127856 },
    { lat: 48.7793315, lng: 21.5125324 },
    { lat: 48.7790413, lng: 21.5121808 },
    { lat: 48.7790307, lng: 21.5120498 },
    { lat: 48.778689, lng: 21.511627 },
    { lat: 48.778649, lng: 21.5113681 },
    { lat: 48.7784381, lng: 21.5110423 },
    { lat: 48.7783453, lng: 21.5105104 },
    { lat: 48.7778562, lng: 21.5098918 },
    { lat: 48.7778046, lng: 21.5096622 },
    { lat: 48.7778382, lng: 21.5090746 },
    { lat: 48.7777792, lng: 21.5087435 },
    { lat: 48.777668, lng: 21.5085089 },
    { lat: 48.7777023, lng: 21.5082583 },
    { lat: 48.7774795, lng: 21.5079786 },
    { lat: 48.777412, lng: 21.5075878 },
    { lat: 48.7770959, lng: 21.5070841 },
    { lat: 48.7770227, lng: 21.5068078 },
    { lat: 48.7768987, lng: 21.5067179 },
    { lat: 48.7767346, lng: 21.506332 },
    { lat: 48.7766385, lng: 21.5063194 },
    { lat: 48.7765934, lng: 21.5062339 },
    { lat: 48.7765137, lng: 21.5060033 },
    { lat: 48.7764754, lng: 21.5055324 },
    { lat: 48.7764211, lng: 21.5054486 },
    { lat: 48.7764477, lng: 21.5052749 },
    { lat: 48.7763074, lng: 21.5048128 },
    { lat: 48.7763393, lng: 21.5045143 },
    { lat: 48.7761439, lng: 21.5042538 },
    { lat: 48.7761205, lng: 21.5040281 },
    { lat: 48.7759674, lng: 21.5039116 },
    { lat: 48.7758278, lng: 21.5036717 },
    { lat: 48.7757685, lng: 21.5034059 },
    { lat: 48.7753914, lng: 21.5026951 },
    { lat: 48.7754052, lng: 21.5025958 },
    { lat: 48.7752994, lng: 21.5022781 },
    { lat: 48.7751717, lng: 21.5021395 },
    { lat: 48.7751316, lng: 21.5018771 },
    { lat: 48.7749776, lng: 21.5017688 },
    { lat: 48.77487, lng: 21.5013153 },
    { lat: 48.7746615, lng: 21.5009128 },
    { lat: 48.774698, lng: 21.5007709 },
    { lat: 48.774542, lng: 21.5005525 },
    { lat: 48.7741413, lng: 21.4996881 },
    { lat: 48.7741383, lng: 21.4994207 },
    { lat: 48.7740006, lng: 21.4989043 },
    { lat: 48.7740907, lng: 21.4987054 },
    { lat: 48.7739738, lng: 21.4986361 },
    { lat: 48.773727, lng: 21.4969418 },
    { lat: 48.7737352, lng: 21.4966766 },
    { lat: 48.7735977, lng: 21.4962023 },
    { lat: 48.7734543, lng: 21.496059 },
    { lat: 48.7734002, lng: 21.4957851 },
    { lat: 48.7731758, lng: 21.4954941 },
    { lat: 48.7731552, lng: 21.4952126 },
    { lat: 48.7730908, lng: 21.4950902 },
    { lat: 48.7729815, lng: 21.494567 },
    { lat: 48.7728703, lng: 21.4946189 },
    { lat: 48.7728126, lng: 21.4945253 },
    { lat: 48.7728152, lng: 21.4944264 },
    { lat: 48.7726262, lng: 21.4941941 },
    { lat: 48.7725172, lng: 21.4938785 },
    { lat: 48.7724263, lng: 21.4938509 },
    { lat: 48.7721622, lng: 21.4934335 },
    { lat: 48.7721318, lng: 21.4930413 },
    { lat: 48.7718492, lng: 21.4925695 },
    { lat: 48.7717491, lng: 21.492575 },
    { lat: 48.7715925, lng: 21.4928336 },
    { lat: 48.7713486, lng: 21.4928472 },
    { lat: 48.7711831, lng: 21.492612 },
    { lat: 48.7712495, lng: 21.4920604 },
    { lat: 48.7710218, lng: 21.4918168 },
    { lat: 48.7708725, lng: 21.4918634 },
    { lat: 48.770721, lng: 21.4912146 },
    { lat: 48.7706004, lng: 21.4910531 },
    { lat: 48.7704373, lng: 21.491015 },
    { lat: 48.770312, lng: 21.4905692 },
    { lat: 48.7703287, lng: 21.4902182 },
    { lat: 48.7702416, lng: 21.4900003 },
    { lat: 48.7701894, lng: 21.4896388 },
    { lat: 48.7701398, lng: 21.4896346 },
    { lat: 48.7701643, lng: 21.4894717 },
    { lat: 48.7700446, lng: 21.4889856 },
    { lat: 48.770139, lng: 21.4871502 },
    { lat: 48.7704668, lng: 21.483265 },
    { lat: 48.7705003, lng: 21.483103 },
    { lat: 48.7705598, lng: 21.4830284 },
    { lat: 48.7706963, lng: 21.4827212 },
    { lat: 48.7707922, lng: 21.4812919 },
    { lat: 48.7716388, lng: 21.474195 },
    { lat: 48.7723076, lng: 21.4710674 },
    { lat: 48.7729621, lng: 21.4685297 },
    { lat: 48.7730121, lng: 21.4683512 },
  ],
  Poproč: [
    { lat: 48.6917019, lng: 20.9903802 },
    { lat: 48.692063, lng: 20.9906411 },
    { lat: 48.6926393, lng: 20.9911489 },
    { lat: 48.6933939, lng: 20.9914704 },
    { lat: 48.694744, lng: 20.9915555 },
    { lat: 48.6950956, lng: 20.9916301 },
    { lat: 48.6951087, lng: 20.9915222 },
    { lat: 48.6963064, lng: 20.9919602 },
    { lat: 48.6963387, lng: 20.9919898 },
    { lat: 48.6961283, lng: 20.9924287 },
    { lat: 48.6971513, lng: 20.9932052 },
    { lat: 48.6977375, lng: 20.9935072 },
    { lat: 48.6991521, lng: 20.9937656 },
    { lat: 48.6992064, lng: 20.9948272 },
    { lat: 48.699582, lng: 20.9956682 },
    { lat: 48.6996413, lng: 20.9957207 },
    { lat: 48.700062, lng: 20.9957459 },
    { lat: 48.7000649, lng: 20.993937 },
    { lat: 48.701015, lng: 20.994107 },
    { lat: 48.7010186, lng: 20.9943886 },
    { lat: 48.702202, lng: 20.9943674 },
    { lat: 48.7022778, lng: 20.9958703 },
    { lat: 48.7043424, lng: 20.995502 },
    { lat: 48.70443, lng: 20.9981794 },
    { lat: 48.7047301, lng: 20.9980983 },
    { lat: 48.7046156, lng: 20.9945819 },
    { lat: 48.7059498, lng: 20.9944944 },
    { lat: 48.7062526, lng: 20.997136 },
    { lat: 48.7068256, lng: 20.9969348 },
    { lat: 48.7068728, lng: 20.9972416 },
    { lat: 48.7073173, lng: 20.9970658 },
    { lat: 48.7074804, lng: 20.9979818 },
    { lat: 48.7081735, lng: 20.9980416 },
    { lat: 48.7086258, lng: 20.9981738 },
    { lat: 48.7085132, lng: 20.9992882 },
    { lat: 48.7087872, lng: 20.9991727 },
    { lat: 48.7094816, lng: 20.9986896 },
    { lat: 48.7094534, lng: 20.9983158 },
    { lat: 48.7110959, lng: 20.99805 },
    { lat: 48.7111094, lng: 20.9975753 },
    { lat: 48.7111892, lng: 20.9976438 },
    { lat: 48.7113055, lng: 20.9975501 },
    { lat: 48.7116422, lng: 20.9975549 },
    { lat: 48.711719, lng: 20.9974946 },
    { lat: 48.7117377, lng: 20.9973128 },
    { lat: 48.7119281, lng: 20.9971142 },
    { lat: 48.7124162, lng: 20.9969318 },
    { lat: 48.7125936, lng: 20.9969893 },
    { lat: 48.7126833, lng: 20.997113 },
    { lat: 48.712787, lng: 20.9970816 },
    { lat: 48.7128733, lng: 20.9971382 },
    { lat: 48.7129222, lng: 20.997256 },
    { lat: 48.7130467, lng: 20.9972761 },
    { lat: 48.7130477, lng: 20.9973762 },
    { lat: 48.7131258, lng: 20.9973215 },
    { lat: 48.7132904, lng: 20.9974338 },
    { lat: 48.7133654, lng: 20.9973835 },
    { lat: 48.7134807, lng: 20.9976389 },
    { lat: 48.7135559, lng: 20.9975313 },
    { lat: 48.7136394, lng: 20.9975734 },
    { lat: 48.7137172, lng: 20.9974353 },
    { lat: 48.714047, lng: 20.9976452 },
    { lat: 48.7140902, lng: 20.9975169 },
    { lat: 48.7142465, lng: 20.997581 },
    { lat: 48.7143175, lng: 20.9974512 },
    { lat: 48.7144106, lng: 20.9974424 },
    { lat: 48.7144629, lng: 20.9975902 },
    { lat: 48.7146081, lng: 20.997658 },
    { lat: 48.7146545, lng: 20.9979069 },
    { lat: 48.7146224, lng: 20.9979542 },
    { lat: 48.7147548, lng: 20.9980793 },
    { lat: 48.7148987, lng: 20.9980363 },
    { lat: 48.7148828, lng: 20.9979709 },
    { lat: 48.7149646, lng: 20.9979903 },
    { lat: 48.7149871, lng: 20.9979205 },
    { lat: 48.7150358, lng: 20.997996 },
    { lat: 48.7151812, lng: 20.9980046 },
    { lat: 48.7152112, lng: 20.9979232 },
    { lat: 48.7154015, lng: 20.9978935 },
    { lat: 48.7156823, lng: 20.9977396 },
    { lat: 48.7158412, lng: 20.9978171 },
    { lat: 48.7160712, lng: 20.9980937 },
    { lat: 48.7164183, lng: 20.9981405 },
    { lat: 48.7165443, lng: 20.9982436 },
    { lat: 48.7166216, lng: 20.9980638 },
    { lat: 48.7167204, lng: 20.998056 },
    { lat: 48.7167751, lng: 20.9981395 },
    { lat: 48.717056, lng: 20.9981236 },
    { lat: 48.717216, lng: 20.998027 },
    { lat: 48.7178392, lng: 20.998157 },
    { lat: 48.7181058, lng: 20.9982876 },
    { lat: 48.7183889, lng: 20.9980976 },
    { lat: 48.7186563, lng: 20.9980535 },
    { lat: 48.7190625, lng: 20.9982184 },
    { lat: 48.7191367, lng: 20.9983155 },
    { lat: 48.7193985, lng: 20.9984432 },
    { lat: 48.7194273, lng: 20.9983947 },
    { lat: 48.7198822, lng: 20.9983617 },
    { lat: 48.7200014, lng: 20.9981862 },
    { lat: 48.7211153, lng: 20.9987543 },
    { lat: 48.7226513, lng: 20.9993539 },
    { lat: 48.7236582, lng: 21.000017 },
    { lat: 48.7237943, lng: 21.0000174 },
    { lat: 48.7245594, lng: 21.0003146 },
    { lat: 48.7250031, lng: 21.0002533 },
    { lat: 48.7252385, lng: 21.0003602 },
    { lat: 48.7257528, lng: 21.0008693 },
    { lat: 48.7336707, lng: 21.0048984 },
    { lat: 48.7338814, lng: 21.0049453 },
    { lat: 48.7344624, lng: 21.0053228 },
    { lat: 48.734652, lng: 21.0055449 },
    { lat: 48.7348331, lng: 21.0054014 },
    { lat: 48.735292, lng: 21.0061401 },
    { lat: 48.7354649, lng: 21.0066748 },
    { lat: 48.7358909, lng: 21.006925 },
    { lat: 48.7363308, lng: 21.0070262 },
    { lat: 48.73666, lng: 21.0075519 },
    { lat: 48.7369743, lng: 21.0079008 },
    { lat: 48.7375031, lng: 21.0090839 },
    { lat: 48.7375452, lng: 21.0093735 },
    { lat: 48.7377345, lng: 21.009821 },
    { lat: 48.7377988, lng: 21.0098611 },
    { lat: 48.7382078, lng: 21.0109987 },
    { lat: 48.7382663, lng: 21.0114753 },
    { lat: 48.738363, lng: 21.0116403 },
    { lat: 48.7384535, lng: 21.0117237 },
    { lat: 48.7385695, lng: 21.0117238 },
    { lat: 48.7386323, lng: 21.0118528 },
    { lat: 48.7388472, lng: 21.0119539 },
    { lat: 48.7388723, lng: 21.0120358 },
    { lat: 48.7390358, lng: 21.0120435 },
    { lat: 48.7391209, lng: 21.0121308 },
    { lat: 48.7392127, lng: 21.0120811 },
    { lat: 48.7392564, lng: 21.0121474 },
    { lat: 48.7394866, lng: 21.0121617 },
    { lat: 48.739663, lng: 21.0123595 },
    { lat: 48.739994, lng: 21.0124273 },
    { lat: 48.7403378, lng: 21.0127825 },
    { lat: 48.7409477, lng: 21.0131894 },
    { lat: 48.7412827, lng: 21.0136927 },
    { lat: 48.7412754, lng: 21.0137774 },
    { lat: 48.7413509, lng: 21.0137799 },
    { lat: 48.7415046, lng: 21.014035 },
    { lat: 48.7415387, lng: 21.0142161 },
    { lat: 48.7417713, lng: 21.014512 },
    { lat: 48.7419071, lng: 21.0149476 },
    { lat: 48.7420358, lng: 21.0151586 },
    { lat: 48.7420843, lng: 21.0151624 },
    { lat: 48.74245, lng: 21.0157023 },
    { lat: 48.7425713, lng: 21.0158343 },
    { lat: 48.7426325, lng: 21.0158155 },
    { lat: 48.7428266, lng: 21.0161371 },
    { lat: 48.7429223, lng: 21.016172 },
    { lat: 48.7429048, lng: 21.0163926 },
    { lat: 48.7429836, lng: 21.016494 },
    { lat: 48.7430744, lng: 21.0159344 },
    { lat: 48.7433131, lng: 21.0152542 },
    { lat: 48.7432732, lng: 21.0149327 },
    { lat: 48.7433071, lng: 21.0148014 },
    { lat: 48.7435256, lng: 21.0144975 },
    { lat: 48.7437507, lng: 21.0138147 },
    { lat: 48.7439501, lng: 21.0134095 },
    { lat: 48.7439629, lng: 21.0131521 },
    { lat: 48.7441072, lng: 21.0125799 },
    { lat: 48.7441152, lng: 21.0120365 },
    { lat: 48.7441768, lng: 21.0117241 },
    { lat: 48.7442686, lng: 21.0116038 },
    { lat: 48.7444931, lng: 21.0109367 },
    { lat: 48.7445208, lng: 21.0097324 },
    { lat: 48.7446317, lng: 21.0095429 },
    { lat: 48.7447254, lng: 21.008703 },
    { lat: 48.7447764, lng: 21.0086104 },
    { lat: 48.7447862, lng: 21.0081571 },
    { lat: 48.7449418, lng: 21.0080626 },
    { lat: 48.7448871, lng: 21.0077005 },
    { lat: 48.7449103, lng: 21.0074519 },
    { lat: 48.7451544, lng: 21.0069021 },
    { lat: 48.7455441, lng: 21.0063962 },
    { lat: 48.7457211, lng: 21.005829 },
    { lat: 48.7458527, lng: 21.0056496 },
    { lat: 48.7459575, lng: 21.0052191 },
    { lat: 48.7458851, lng: 21.0051051 },
    { lat: 48.745871, lng: 21.004814 },
    { lat: 48.7461321, lng: 21.0045051 },
    { lat: 48.7462865, lng: 21.0041335 },
    { lat: 48.7462786, lng: 21.0038303 },
    { lat: 48.746693, lng: 21.0032586 },
    { lat: 48.746869, lng: 21.0028976 },
    { lat: 48.7469289, lng: 21.0028692 },
    { lat: 48.7470575, lng: 21.0029422 },
    { lat: 48.7472647, lng: 21.002674 },
    { lat: 48.7474558, lng: 21.0026342 },
    { lat: 48.7476305, lng: 21.0023025 },
    { lat: 48.7479394, lng: 21.0020081 },
    { lat: 48.7482341, lng: 21.0015275 },
    { lat: 48.7482627, lng: 21.0013723 },
    { lat: 48.7484395, lng: 21.0013754 },
    { lat: 48.7487581, lng: 21.0011761 },
    { lat: 48.7490938, lng: 21.0008422 },
    { lat: 48.7492795, lng: 21.0005307 },
    { lat: 48.7494295, lng: 21.000437 },
    { lat: 48.7497909, lng: 21.0000045 },
    { lat: 48.7503031, lng: 20.9992894 },
    { lat: 48.7505636, lng: 20.9992009 },
    { lat: 48.7510594, lng: 20.9986123 },
    { lat: 48.7513684, lng: 20.997913 },
    { lat: 48.7516493, lng: 20.9974565 },
    { lat: 48.7516292, lng: 20.997193 },
    { lat: 48.7518046, lng: 20.9968117 },
    { lat: 48.7518068, lng: 20.9966364 },
    { lat: 48.7518625, lng: 20.9964813 },
    { lat: 48.7520172, lng: 20.9963682 },
    { lat: 48.752045, lng: 20.9962891 },
    { lat: 48.7519165, lng: 20.995905 },
    { lat: 48.7519234, lng: 20.9958071 },
    { lat: 48.7520027, lng: 20.9956531 },
    { lat: 48.7522768, lng: 20.9953673 },
    { lat: 48.7523272, lng: 20.9951513 },
    { lat: 48.7522641, lng: 20.9949848 },
    { lat: 48.7522815, lng: 20.994762 },
    { lat: 48.7524896, lng: 20.9944177 },
    { lat: 48.7524679, lng: 20.9943095 },
    { lat: 48.7526385, lng: 20.9940551 },
    { lat: 48.7526653, lng: 20.9939108 },
    { lat: 48.7529762, lng: 20.9935144 },
    { lat: 48.7531925, lng: 20.9933853 },
    { lat: 48.7534344, lng: 20.9930413 },
    { lat: 48.7539953, lng: 20.9928165 },
    { lat: 48.7540139, lng: 20.9925576 },
    { lat: 48.7540874, lng: 20.992435 },
    { lat: 48.7541135, lng: 20.9920706 },
    { lat: 48.7542305, lng: 20.9919005 },
    { lat: 48.7544057, lng: 20.9914438 },
    { lat: 48.7545109, lng: 20.9909154 },
    { lat: 48.7547944, lng: 20.990504 },
    { lat: 48.7549763, lng: 20.9903582 },
    { lat: 48.7550685, lng: 20.9903562 },
    { lat: 48.7551754, lng: 20.9900153 },
    { lat: 48.7554354, lng: 20.989911 },
    { lat: 48.7554869, lng: 20.9898354 },
    { lat: 48.7555364, lng: 20.9896662 },
    { lat: 48.7556443, lng: 20.9895617 },
    { lat: 48.7556756, lng: 20.9892818 },
    { lat: 48.7559194, lng: 20.988725 },
    { lat: 48.7560203, lng: 20.988647 },
    { lat: 48.7563353, lng: 20.9879264 },
    { lat: 48.7568001, lng: 20.9874185 },
    { lat: 48.7570141, lng: 20.987358 },
    { lat: 48.7571584, lng: 20.9871559 },
    { lat: 48.757582, lng: 20.9869025 },
    { lat: 48.7582351, lng: 20.9868715 },
    { lat: 48.758508, lng: 20.9865528 },
    { lat: 48.7588021, lng: 20.9865944 },
    { lat: 48.7588391, lng: 20.9865234 },
    { lat: 48.7589852, lng: 20.9864834 },
    { lat: 48.7590885, lng: 20.9862722 },
    { lat: 48.759266, lng: 20.9862595 },
    { lat: 48.7593601, lng: 20.986111 },
    { lat: 48.7594111, lng: 20.9856791 },
    { lat: 48.7596004, lng: 20.9852448 },
    { lat: 48.7595634, lng: 20.9849829 },
    { lat: 48.7598036, lng: 20.9843532 },
    { lat: 48.7599851, lng: 20.9841856 },
    { lat: 48.7601142, lng: 20.9837078 },
    { lat: 48.7600913, lng: 20.9834933 },
    { lat: 48.7603008, lng: 20.9827864 },
    { lat: 48.7608016, lng: 20.9801429 },
    { lat: 48.7610216, lng: 20.9799567 },
    { lat: 48.7610815, lng: 20.9796579 },
    { lat: 48.760988, lng: 20.9793849 },
    { lat: 48.7610188, lng: 20.9790904 },
    { lat: 48.760975, lng: 20.9788247 },
    { lat: 48.7610816, lng: 20.9784413 },
    { lat: 48.7609294, lng: 20.9780651 },
    { lat: 48.7611125, lng: 20.9775424 },
    { lat: 48.7610642, lng: 20.977209 },
    { lat: 48.7611749, lng: 20.9768805 },
    { lat: 48.7611256, lng: 20.9763852 },
    { lat: 48.7611531, lng: 20.9759272 },
    { lat: 48.7610334, lng: 20.9758103 },
    { lat: 48.7610228, lng: 20.9755297 },
    { lat: 48.7610908, lng: 20.9754028 },
    { lat: 48.7610001, lng: 20.9745432 },
    { lat: 48.7610464, lng: 20.9731654 },
    { lat: 48.7610738, lng: 20.9728679 },
    { lat: 48.7612154, lng: 20.9723891 },
    { lat: 48.761313, lng: 20.9722125 },
    { lat: 48.7613926, lng: 20.9718625 },
    { lat: 48.7615792, lng: 20.9717902 },
    { lat: 48.7620734, lng: 20.9707468 },
    { lat: 48.7622573, lng: 20.970249 },
    { lat: 48.7622257, lng: 20.9701144 },
    { lat: 48.7623447, lng: 20.9694606 },
    { lat: 48.7624517, lng: 20.969162 },
    { lat: 48.7627243, lng: 20.9689693 },
    { lat: 48.7628114, lng: 20.9685049 },
    { lat: 48.7628985, lng: 20.968317 },
    { lat: 48.76291, lng: 20.9680892 },
    { lat: 48.7630081, lng: 20.967995 },
    { lat: 48.7630646, lng: 20.9676236 },
    { lat: 48.7632056, lng: 20.9675029 },
    { lat: 48.7632044, lng: 20.9672272 },
    { lat: 48.7632759, lng: 20.9670077 },
    { lat: 48.7635595, lng: 20.9665743 },
    { lat: 48.7637285, lng: 20.9660691 },
    { lat: 48.7636852, lng: 20.965889 },
    { lat: 48.76386, lng: 20.9651553 },
    { lat: 48.7641452, lng: 20.9646979 },
    { lat: 48.7641603, lng: 20.9645408 },
    { lat: 48.7644544, lng: 20.9641462 },
    { lat: 48.7647539, lng: 20.9631058 },
    { lat: 48.7651645, lng: 20.9626252 },
    { lat: 48.7655874, lng: 20.9615735 },
    { lat: 48.7660619, lng: 20.9606203 },
    { lat: 48.7663165, lng: 20.9601841 },
    { lat: 48.7664361, lng: 20.9601289 },
    { lat: 48.7666625, lng: 20.9594586 },
    { lat: 48.7674718, lng: 20.9581133 },
    { lat: 48.7679479, lng: 20.957441 },
    { lat: 48.7692782, lng: 20.9549795 },
    { lat: 48.7702708, lng: 20.953398 },
    { lat: 48.7708502, lng: 20.9528522 },
    { lat: 48.7710412, lng: 20.9521318 },
    { lat: 48.7727062, lng: 20.9472095 },
    { lat: 48.773123, lng: 20.945773 },
    { lat: 48.77204, lng: 20.944424 },
    { lat: 48.771803, lng: 20.944217 },
    { lat: 48.7710701, lng: 20.9451738 },
    { lat: 48.7645719, lng: 20.9490959 },
    { lat: 48.7643546, lng: 20.9493305 },
    { lat: 48.7643075, lng: 20.9494662 },
    { lat: 48.7639002, lng: 20.9494061 },
    { lat: 48.7638005, lng: 20.9494858 },
    { lat: 48.7631187, lng: 20.9496002 },
    { lat: 48.7629553, lng: 20.9497297 },
    { lat: 48.762935, lng: 20.9498255 },
    { lat: 48.7626064, lng: 20.9497622 },
    { lat: 48.7622567, lng: 20.9499399 },
    { lat: 48.7615946, lng: 20.9498973 },
    { lat: 48.7614242, lng: 20.9497198 },
    { lat: 48.7611835, lng: 20.9497233 },
    { lat: 48.7609143, lng: 20.949481 },
    { lat: 48.760625, lng: 20.9493155 },
    { lat: 48.760471, lng: 20.9490826 },
    { lat: 48.7597035, lng: 20.9488165 },
    { lat: 48.7590982, lng: 20.9484295 },
    { lat: 48.7589199, lng: 20.948176 },
    { lat: 48.7588605, lng: 20.9479176 },
    { lat: 48.758668, lng: 20.9477811 },
    { lat: 48.7579978, lng: 20.947835 },
    { lat: 48.757752, lng: 20.9477226 },
    { lat: 48.7575746, lng: 20.9473984 },
    { lat: 48.7573516, lng: 20.9471636 },
    { lat: 48.7567186, lng: 20.9469821 },
    { lat: 48.756622, lng: 20.9468155 },
    { lat: 48.7561019, lng: 20.94685 },
    { lat: 48.7558023, lng: 20.9470464 },
    { lat: 48.7540674, lng: 20.9471717 },
    { lat: 48.7538169, lng: 20.9469187 },
    { lat: 48.7533843, lng: 20.9468749 },
    { lat: 48.7532586, lng: 20.9469801 },
    { lat: 48.7531793, lng: 20.9469648 },
    { lat: 48.7528153, lng: 20.9473933 },
    { lat: 48.7526151, lng: 20.9473573 },
    { lat: 48.752595, lng: 20.9474973 },
    { lat: 48.7525122, lng: 20.9476174 },
    { lat: 48.7523814, lng: 20.9476417 },
    { lat: 48.7519087, lng: 20.9480472 },
    { lat: 48.7517738, lng: 20.9484207 },
    { lat: 48.7516387, lng: 20.9484499 },
    { lat: 48.7515451, lng: 20.9486137 },
    { lat: 48.7513384, lng: 20.9487312 },
    { lat: 48.7510748, lng: 20.9487539 },
    { lat: 48.7509395, lng: 20.9489812 },
    { lat: 48.750583, lng: 20.9492247 },
    { lat: 48.7505773, lng: 20.94933 },
    { lat: 48.7502178, lng: 20.9496518 },
    { lat: 48.750072, lng: 20.9500029 },
    { lat: 48.7497365, lng: 20.9501674 },
    { lat: 48.7495712, lng: 20.9501344 },
    { lat: 48.7494085, lng: 20.9502193 },
    { lat: 48.7492933, lng: 20.950176 },
    { lat: 48.7492275, lng: 20.9502599 },
    { lat: 48.7490869, lng: 20.9502668 },
    { lat: 48.7490361, lng: 20.9503616 },
    { lat: 48.7485916, lng: 20.9503928 },
    { lat: 48.748359, lng: 20.9506725 },
    { lat: 48.7476244, lng: 20.9511279 },
    { lat: 48.7469058, lng: 20.9513759 },
    { lat: 48.7463379, lng: 20.9513282 },
    { lat: 48.7462564, lng: 20.9513833 },
    { lat: 48.7461792, lng: 20.9512611 },
    { lat: 48.7460175, lng: 20.9513064 },
    { lat: 48.7457068, lng: 20.9511361 },
    { lat: 48.7455782, lng: 20.9511617 },
    { lat: 48.7454577, lng: 20.9510301 },
    { lat: 48.7453776, lng: 20.9510522 },
    { lat: 48.7449726, lng: 20.9508283 },
    { lat: 48.7448797, lng: 20.9506392 },
    { lat: 48.7445212, lng: 20.9503864 },
    { lat: 48.7444431, lng: 20.9504773 },
    { lat: 48.7442723, lng: 20.950383 },
    { lat: 48.7441241, lng: 20.9500937 },
    { lat: 48.7439794, lng: 20.9501109 },
    { lat: 48.7438931, lng: 20.9500201 },
    { lat: 48.7436391, lng: 20.9500642 },
    { lat: 48.7434286, lng: 20.9498296 },
    { lat: 48.7432028, lng: 20.9498405 },
    { lat: 48.7426563, lng: 20.9493553 },
    { lat: 48.7424541, lng: 20.9492984 },
    { lat: 48.7420963, lng: 20.9490723 },
    { lat: 48.7417693, lng: 20.9490828 },
    { lat: 48.7416365, lng: 20.948951 },
    { lat: 48.7412949, lng: 20.9488003 },
    { lat: 48.7409086, lng: 20.9489287 },
    { lat: 48.7407458, lng: 20.9488075 },
    { lat: 48.7404535, lng: 20.948786 },
    { lat: 48.7403585, lng: 20.9485697 },
    { lat: 48.7402141, lng: 20.9484906 },
    { lat: 48.740125, lng: 20.9484839 },
    { lat: 48.7399398, lng: 20.9486372 },
    { lat: 48.7395533, lng: 20.9483587 },
    { lat: 48.7392709, lng: 20.9484987 },
    { lat: 48.7388922, lng: 20.948444 },
    { lat: 48.7386818, lng: 20.948514 },
    { lat: 48.7382687, lng: 20.9482818 },
    { lat: 48.738171, lng: 20.9480539 },
    { lat: 48.7379879, lng: 20.9479617 },
    { lat: 48.7377932, lng: 20.9479998 },
    { lat: 48.7375906, lng: 20.9482328 },
    { lat: 48.7374096, lng: 20.9481722 },
    { lat: 48.7372064, lng: 20.9482901 },
    { lat: 48.7371958, lng: 20.9483744 },
    { lat: 48.7370978, lng: 20.9484404 },
    { lat: 48.7368102, lng: 20.9484585 },
    { lat: 48.7366273, lng: 20.9485406 },
    { lat: 48.736478, lng: 20.9484843 },
    { lat: 48.7363495, lng: 20.9484806 },
    { lat: 48.7362752, lng: 20.948548 },
    { lat: 48.7360897, lng: 20.948518 },
    { lat: 48.7359614, lng: 20.9484213 },
    { lat: 48.7356137, lng: 20.9483196 },
    { lat: 48.7351571, lng: 20.9484701 },
    { lat: 48.7348619, lng: 20.9483248 },
    { lat: 48.734758, lng: 20.9481185 },
    { lat: 48.7347049, lng: 20.9481097 },
    { lat: 48.7339717, lng: 20.9482975 },
    { lat: 48.7338746, lng: 20.9482215 },
    { lat: 48.7333952, lng: 20.9482593 },
    { lat: 48.7329978, lng: 20.9484931 },
    { lat: 48.7326965, lng: 20.9483981 },
    { lat: 48.7326032, lng: 20.948473 },
    { lat: 48.7318945, lng: 20.9483844 },
    { lat: 48.7315707, lng: 20.9487629 },
    { lat: 48.7313971, lng: 20.9487916 },
    { lat: 48.7311258, lng: 20.9485881 },
    { lat: 48.7309662, lng: 20.9485968 },
    { lat: 48.7306762, lng: 20.9485081 },
    { lat: 48.7306712, lng: 20.9484593 },
    { lat: 48.7301097, lng: 20.9483619 },
    { lat: 48.7299578, lng: 20.9481667 },
    { lat: 48.7298138, lng: 20.9481001 },
    { lat: 48.7297353, lng: 20.9485076 },
    { lat: 48.7295399, lng: 20.9483856 },
    { lat: 48.7291046, lng: 20.9479609 },
    { lat: 48.7288625, lng: 20.9478582 },
    { lat: 48.7286014, lng: 20.9478946 },
    { lat: 48.7282566, lng: 20.9477125 },
    { lat: 48.7277226, lng: 20.947664 },
    { lat: 48.7275901, lng: 20.9476103 },
    { lat: 48.7274357, lng: 20.9473669 },
    { lat: 48.7270463, lng: 20.9473408 },
    { lat: 48.7267173, lng: 20.9475293 },
    { lat: 48.7267358, lng: 20.9477806 },
    { lat: 48.726543, lng: 20.947973 },
    { lat: 48.7264982, lng: 20.947909 },
    { lat: 48.7262566, lng: 20.947852 },
    { lat: 48.7260335, lng: 20.9479507 },
    { lat: 48.7256499, lng: 20.9486222 },
    { lat: 48.7254117, lng: 20.950417 },
    { lat: 48.7253916, lng: 20.9510902 },
    { lat: 48.725226, lng: 20.9517567 },
    { lat: 48.725108, lng: 20.9517329 },
    { lat: 48.724924, lng: 20.9512483 },
    { lat: 48.7247614, lng: 20.9511274 },
    { lat: 48.7242827, lng: 20.9513184 },
    { lat: 48.7240347, lng: 20.9515499 },
    { lat: 48.7233608, lng: 20.9516265 },
    { lat: 48.7229976, lng: 20.9519003 },
    { lat: 48.7226305, lng: 20.9522981 },
    { lat: 48.7218153, lng: 20.9527609 },
    { lat: 48.7214894, lng: 20.9530108 },
    { lat: 48.7207352, lng: 20.9537522 },
    { lat: 48.7181348, lng: 20.9534081 },
    { lat: 48.7173407, lng: 20.953559 },
    { lat: 48.717099, lng: 20.9534706 },
    { lat: 48.7168519, lng: 20.9534792 },
    { lat: 48.7166899, lng: 20.9533832 },
    { lat: 48.7160449, lng: 20.9534123 },
    { lat: 48.7157118, lng: 20.953518 },
    { lat: 48.7148572, lng: 20.9539438 },
    { lat: 48.714389, lng: 20.9539803 },
    { lat: 48.7141583, lng: 20.9538162 },
    { lat: 48.7139552, lng: 20.9527985 },
    { lat: 48.7138525, lng: 20.952554 },
    { lat: 48.7137547, lng: 20.9519185 },
    { lat: 48.7136565, lng: 20.9517118 },
    { lat: 48.713546, lng: 20.9516379 },
    { lat: 48.7129302, lng: 20.9523366 },
    { lat: 48.7125035, lng: 20.9516971 },
    { lat: 48.7122341, lng: 20.9518207 },
    { lat: 48.7121313, lng: 20.9520107 },
    { lat: 48.7121565, lng: 20.9526304 },
    { lat: 48.7121218, lng: 20.9528333 },
    { lat: 48.7117785, lng: 20.9537086 },
    { lat: 48.7115554, lng: 20.9541049 },
    { lat: 48.7112038, lng: 20.9540919 },
    { lat: 48.7109823, lng: 20.954266 },
    { lat: 48.7102961, lng: 20.9541806 },
    { lat: 48.7099133, lng: 20.9542737 },
    { lat: 48.709495, lng: 20.9544889 },
    { lat: 48.7091476, lng: 20.9548412 },
    { lat: 48.7083382, lng: 20.9549709 },
    { lat: 48.7062677, lng: 20.9557294 },
    { lat: 48.705915, lng: 20.9556509 },
    { lat: 48.7057532, lng: 20.9556929 },
    { lat: 48.7050548, lng: 20.9564444 },
    { lat: 48.7043697, lng: 20.9567295 },
    { lat: 48.7036787, lng: 20.9573386 },
    { lat: 48.7021081, lng: 20.9585659 },
    { lat: 48.701826, lng: 20.9590867 },
    { lat: 48.7016073, lng: 20.9596465 },
    { lat: 48.701096, lng: 20.9605447 },
    { lat: 48.7014405, lng: 20.9625637 },
    { lat: 48.7013668, lng: 20.9627864 },
    { lat: 48.7010566, lng: 20.963031 },
    { lat: 48.6999818, lng: 20.9636122 },
    { lat: 48.6993263, lng: 20.9641034 },
    { lat: 48.6992941, lng: 20.9643834 },
    { lat: 48.6988624, lng: 20.9647677 },
    { lat: 48.6986656, lng: 20.9651123 },
    { lat: 48.6987643, lng: 20.9653678 },
    { lat: 48.6986723, lng: 20.965482 },
    { lat: 48.6985322, lng: 20.9658708 },
    { lat: 48.697009, lng: 20.9676498 },
    { lat: 48.6975151, lng: 20.9682384 },
    { lat: 48.697155, lng: 20.9685811 },
    { lat: 48.6967582, lng: 20.9687963 },
    { lat: 48.696763, lng: 20.9691733 },
    { lat: 48.6968573, lng: 20.9696378 },
    { lat: 48.6965598, lng: 20.9698971 },
    { lat: 48.6964448, lng: 20.9699276 },
    { lat: 48.6966782, lng: 20.9709484 },
    { lat: 48.6962139, lng: 20.9715115 },
    { lat: 48.6958892, lng: 20.9715914 },
    { lat: 48.695449, lng: 20.9718284 },
    { lat: 48.6947206, lng: 20.972393 },
    { lat: 48.6938469, lng: 20.9728935 },
    { lat: 48.6932751, lng: 20.9731333 },
    { lat: 48.6931607, lng: 20.9733074 },
    { lat: 48.6932754, lng: 20.9738469 },
    { lat: 48.6925985, lng: 20.9740395 },
    { lat: 48.692606, lng: 20.9743639 },
    { lat: 48.6927748, lng: 20.9743974 },
    { lat: 48.69342, lng: 20.974266 },
    { lat: 48.693579, lng: 20.974712 },
    { lat: 48.6937306, lng: 20.9753468 },
    { lat: 48.6945077, lng: 20.9750525 },
    { lat: 48.6947337, lng: 20.9770426 },
    { lat: 48.6944989, lng: 20.9770883 },
    { lat: 48.6944943, lng: 20.9772825 },
    { lat: 48.6962064, lng: 20.9774946 },
    { lat: 48.6962258, lng: 20.9793573 },
    { lat: 48.6961625, lng: 20.9805279 },
    { lat: 48.6969357, lng: 20.9807004 },
    { lat: 48.6971913, lng: 20.9806988 },
    { lat: 48.697916, lng: 20.9804243 },
    { lat: 48.6979819, lng: 20.9816239 },
    { lat: 48.6979657, lng: 20.9820876 },
    { lat: 48.6977363, lng: 20.9849666 },
    { lat: 48.6970526, lng: 20.9848842 },
    { lat: 48.6968742, lng: 20.9864554 },
    { lat: 48.6966282, lng: 20.9864413 },
    { lat: 48.6965979, lng: 20.988135 },
    { lat: 48.6957465, lng: 20.9890657 },
    { lat: 48.6948324, lng: 20.9893046 },
    { lat: 48.6943545, lng: 20.9893529 },
    { lat: 48.6939682, lng: 20.989312 },
    { lat: 48.6939172, lng: 20.9903167 },
    { lat: 48.6931972, lng: 20.9902284 },
    { lat: 48.6930944, lng: 20.9909591 },
    { lat: 48.6917019, lng: 20.9903802 },
  ],
  Bočiar: [
    { lat: 48.585207, lng: 21.236642 },
    { lat: 48.5860558, lng: 21.2406394 },
    { lat: 48.586113, lng: 21.2405614 },
    { lat: 48.5863964, lng: 21.2405122 },
    { lat: 48.5865775, lng: 21.2407991 },
    { lat: 48.5867248, lng: 21.2409028 },
    { lat: 48.5867973, lng: 21.2407754 },
    { lat: 48.5867325, lng: 21.2404288 },
    { lat: 48.5867612, lng: 21.2403382 },
    { lat: 48.5870367, lng: 21.2403301 },
    { lat: 48.5873833, lng: 21.2399715 },
    { lat: 48.5874424, lng: 21.2399854 },
    { lat: 48.5874558, lng: 21.2402315 },
    { lat: 48.5876564, lng: 21.2404281 },
    { lat: 48.5877739, lng: 21.2397554 },
    { lat: 48.5877746, lng: 21.2394504 },
    { lat: 48.5878365, lng: 21.2393401 },
    { lat: 48.5879036, lng: 21.2393286 },
    { lat: 48.5880517, lng: 21.239419 },
    { lat: 48.5881519, lng: 21.2393896 },
    { lat: 48.5882755, lng: 21.2390552 },
    { lat: 48.5881622, lng: 21.2387792 },
    { lat: 48.5883087, lng: 21.2385335 },
    { lat: 48.5886915, lng: 21.2385338 },
    { lat: 48.5887932, lng: 21.238769 },
    { lat: 48.5890056, lng: 21.2389723 },
    { lat: 48.5891737, lng: 21.2388043 },
    { lat: 48.5892327, lng: 21.2384571 },
    { lat: 48.5896465, lng: 21.2383763 },
    { lat: 48.5903083, lng: 21.2388115 },
    { lat: 48.5905271, lng: 21.2388611 },
    { lat: 48.5906594, lng: 21.23906 },
    { lat: 48.5907486, lng: 21.2390676 },
    { lat: 48.5909133, lng: 21.2388654 },
    { lat: 48.5910631, lng: 21.2390198 },
    { lat: 48.5912045, lng: 21.2387901 },
    { lat: 48.5911058, lng: 21.2382567 },
    { lat: 48.5911956, lng: 21.2380345 },
    { lat: 48.5912845, lng: 21.2379952 },
    { lat: 48.5914079, lng: 21.2380501 },
    { lat: 48.5916053, lng: 21.2383543 },
    { lat: 48.5918494, lng: 21.2382105 },
    { lat: 48.5919999, lng: 21.2384536 },
    { lat: 48.5921986, lng: 21.2385405 },
    { lat: 48.5922147, lng: 21.2386276 },
    { lat: 48.5923021, lng: 21.2386548 },
    { lat: 48.5922768, lng: 21.2385233 },
    { lat: 48.5923554, lng: 21.2383353 },
    { lat: 48.592529, lng: 21.2382761 },
    { lat: 48.5925443, lng: 21.2381524 },
    { lat: 48.5924736, lng: 21.2380479 },
    { lat: 48.5924651, lng: 21.2378835 },
    { lat: 48.5926372, lng: 21.2374867 },
    { lat: 48.5926443, lng: 21.2373515 },
    { lat: 48.5927117, lng: 21.2372745 },
    { lat: 48.5931229, lng: 21.2374673 },
    { lat: 48.5936778, lng: 21.2375794 },
    { lat: 48.5940941, lng: 21.2380804 },
    { lat: 48.5942826, lng: 21.2380305 },
    { lat: 48.5943283, lng: 21.2381968 },
    { lat: 48.5944548, lng: 21.2383187 },
    { lat: 48.5945114, lng: 21.2385102 },
    { lat: 48.5945813, lng: 21.2385501 },
    { lat: 48.5949868, lng: 21.2384851 },
    { lat: 48.5951924, lng: 21.2383998 },
    { lat: 48.5954863, lng: 21.2379019 },
    { lat: 48.59546, lng: 21.237748 },
    { lat: 48.5952255, lng: 21.2375175 },
    { lat: 48.5952234, lng: 21.2373054 },
    { lat: 48.5953434, lng: 21.2370008 },
    { lat: 48.5954456, lng: 21.2369214 },
    { lat: 48.5955888, lng: 21.236641 },
    { lat: 48.5957014, lng: 21.2363188 },
    { lat: 48.595607, lng: 21.2362504 },
    { lat: 48.5955427, lng: 21.2363883 },
    { lat: 48.5954518, lng: 21.236143 },
    { lat: 48.5955936, lng: 21.2360011 },
    { lat: 48.5956269, lng: 21.2358729 },
    { lat: 48.5957175, lng: 21.2359283 },
    { lat: 48.5958096, lng: 21.2357806 },
    { lat: 48.5959562, lng: 21.2359672 },
    { lat: 48.5960586, lng: 21.2358245 },
    { lat: 48.5962153, lng: 21.2358369 },
    { lat: 48.5962875, lng: 21.2356938 },
    { lat: 48.5966492, lng: 21.235638 },
    { lat: 48.5967329, lng: 21.2355375 },
    { lat: 48.5969568, lng: 21.235752 },
    { lat: 48.597318, lng: 21.235282 },
    { lat: 48.5973701, lng: 21.235294 },
    { lat: 48.5974131, lng: 21.2354097 },
    { lat: 48.5976026, lng: 21.2354976 },
    { lat: 48.5976383, lng: 21.2356284 },
    { lat: 48.5977869, lng: 21.2356691 },
    { lat: 48.5979112, lng: 21.2357904 },
    { lat: 48.5983334, lng: 21.2359066 },
    { lat: 48.5986859, lng: 21.2361313 },
    { lat: 48.5988397, lng: 21.2361545 },
    { lat: 48.598863, lng: 21.236032 },
    { lat: 48.5992439, lng: 21.2359798 },
    { lat: 48.5988676, lng: 21.2348075 },
    { lat: 48.5986552, lng: 21.2335944 },
    { lat: 48.5986759, lng: 21.2332815 },
    { lat: 48.5984086, lng: 21.2330128 },
    { lat: 48.598444, lng: 21.2329897 },
    { lat: 48.5983293, lng: 21.2327537 },
    { lat: 48.5978882, lng: 21.2313126 },
    { lat: 48.5978675, lng: 21.2313241 },
    { lat: 48.5978345, lng: 21.231215 },
    { lat: 48.597396, lng: 21.231518 },
    { lat: 48.596558, lng: 21.232066 },
    { lat: 48.595421, lng: 21.232831 },
    { lat: 48.595373, lng: 21.232435 },
    { lat: 48.595317, lng: 21.232011 },
    { lat: 48.595314, lng: 21.231995 },
    { lat: 48.595288, lng: 21.231858 },
    { lat: 48.595282, lng: 21.231828 },
    { lat: 48.595052, lng: 21.231902 },
    { lat: 48.594085, lng: 21.232214 },
    { lat: 48.592165, lng: 21.232833 },
    { lat: 48.591073, lng: 21.233196 },
    { lat: 48.590907, lng: 21.23319 },
    { lat: 48.590765, lng: 21.233185 },
    { lat: 48.59063, lng: 21.23318 },
    { lat: 48.590589, lng: 21.23318 },
    { lat: 48.590767, lng: 21.234381 },
    { lat: 48.590685, lng: 21.234421 },
    { lat: 48.589558, lng: 21.234972 },
    { lat: 48.589127, lng: 21.235182 },
    { lat: 48.589063, lng: 21.235215 },
    { lat: 48.589163, lng: 21.235878 },
    { lat: 48.589196, lng: 21.236091 },
    { lat: 48.588769, lng: 21.236167 },
    { lat: 48.58767, lng: 21.236364 },
    { lat: 48.586606, lng: 21.236567 },
    { lat: 48.585511, lng: 21.23675 },
    { lat: 48.585207, lng: 21.236642 },
  ],
  Zádiel: [
    { lat: 48.6176714, lng: 20.8449122 },
    { lat: 48.6174116, lng: 20.8442406 },
    { lat: 48.6178044, lng: 20.843743 },
    { lat: 48.6182439, lng: 20.8433068 },
    { lat: 48.618878, lng: 20.8418804 },
    { lat: 48.6195139, lng: 20.8407663 },
    { lat: 48.620338, lng: 20.8395366 },
    { lat: 48.6212299, lng: 20.8367477 },
    { lat: 48.6199145, lng: 20.8322444 },
    { lat: 48.620018, lng: 20.832047 },
    { lat: 48.620024, lng: 20.832035 },
    { lat: 48.620148, lng: 20.831789 },
    { lat: 48.622789, lng: 20.826532 },
    { lat: 48.623234, lng: 20.826037 },
    { lat: 48.624004, lng: 20.823019 },
    { lat: 48.623939, lng: 20.822389 },
    { lat: 48.623981, lng: 20.821531 },
    { lat: 48.624112, lng: 20.820885 },
    { lat: 48.62409, lng: 20.819563 },
    { lat: 48.623841, lng: 20.818207 },
    { lat: 48.623898, lng: 20.816957 },
    { lat: 48.624128, lng: 20.815804 },
    { lat: 48.624304, lng: 20.812752 },
    { lat: 48.624311, lng: 20.811069 },
    { lat: 48.623453, lng: 20.8114818 },
    { lat: 48.6223382, lng: 20.8121181 },
    { lat: 48.621142, lng: 20.8131682 },
    { lat: 48.6210261, lng: 20.8135592 },
    { lat: 48.6208571, lng: 20.8137469 },
    { lat: 48.619231, lng: 20.8128929 },
    { lat: 48.6189478, lng: 20.812936 },
    { lat: 48.617415, lng: 20.8142101 },
    { lat: 48.6168527, lng: 20.8145521 },
    { lat: 48.6154856, lng: 20.8157354 },
    { lat: 48.6148977, lng: 20.816861 },
    { lat: 48.6145702, lng: 20.8171224 },
    { lat: 48.6133284, lng: 20.8175365 },
    { lat: 48.6128766, lng: 20.8175943 },
    { lat: 48.6119499, lng: 20.817489 },
    { lat: 48.6116075, lng: 20.8176772 },
    { lat: 48.6111591, lng: 20.817664 },
    { lat: 48.610905, lng: 20.8179007 },
    { lat: 48.6108286, lng: 20.8180971 },
    { lat: 48.6106676, lng: 20.8182638 },
    { lat: 48.6102008, lng: 20.8183996 },
    { lat: 48.6100113, lng: 20.8186153 },
    { lat: 48.6100107, lng: 20.8189255 },
    { lat: 48.6096091, lng: 20.8195453 },
    { lat: 48.6090306, lng: 20.8211373 },
    { lat: 48.6089578, lng: 20.821413 },
    { lat: 48.6089599, lng: 20.8219191 },
    { lat: 48.6088389, lng: 20.8225849 },
    { lat: 48.6087867, lng: 20.8231895 },
    { lat: 48.6087041, lng: 20.8236016 },
    { lat: 48.6084057, lng: 20.8244091 },
    { lat: 48.6084035, lng: 20.8247351 },
    { lat: 48.6082447, lng: 20.8251525 },
    { lat: 48.6081603, lng: 20.8255419 },
    { lat: 48.608764, lng: 20.8289276 },
    { lat: 48.6097919, lng: 20.8338993 },
    { lat: 48.609941, lng: 20.8348192 },
    { lat: 48.60959, lng: 20.8349283 },
    { lat: 48.6094307, lng: 20.8351713 },
    { lat: 48.6091791, lng: 20.8351535 },
    { lat: 48.6089372, lng: 20.8352877 },
    { lat: 48.6084522, lng: 20.8360078 },
    { lat: 48.6079125, lng: 20.8366088 },
    { lat: 48.6073714, lng: 20.8368836 },
    { lat: 48.6071186, lng: 20.8371906 },
    { lat: 48.6066235, lng: 20.8375892 },
    { lat: 48.6064651, lng: 20.8376248 },
    { lat: 48.6062396, lng: 20.8378435 },
    { lat: 48.605997, lng: 20.8379244 },
    { lat: 48.605752, lng: 20.8381977 },
    { lat: 48.6059652, lng: 20.8392202 },
    { lat: 48.6059582, lng: 20.8393104 },
    { lat: 48.6057853, lng: 20.8393849 },
    { lat: 48.6053649, lng: 20.8418121 },
    { lat: 48.6053099, lng: 20.8422738 },
    { lat: 48.6052375, lng: 20.8451958 },
    { lat: 48.6062641, lng: 20.8461997 },
    { lat: 48.6067582, lng: 20.846655 },
    { lat: 48.6069771, lng: 20.8467701 },
    { lat: 48.6070506, lng: 20.8469123 },
    { lat: 48.6072325, lng: 20.8470296 },
    { lat: 48.6075116, lng: 20.8473503 },
    { lat: 48.6084576, lng: 20.8472291 },
    { lat: 48.6094266, lng: 20.8471957 },
    { lat: 48.6094537, lng: 20.8469001 },
    { lat: 48.6106662, lng: 20.8469362 },
    { lat: 48.6112745, lng: 20.8470082 },
    { lat: 48.6115682, lng: 20.8470429 },
    { lat: 48.6116556, lng: 20.8473256 },
    { lat: 48.6119394, lng: 20.8476903 },
    { lat: 48.6137085, lng: 20.847708 },
    { lat: 48.6140123, lng: 20.8475355 },
    { lat: 48.6143241, lng: 20.847506 },
    { lat: 48.6169545, lng: 20.8454719 },
    { lat: 48.6176714, lng: 20.8449122 },
  ],
  Bidovce: [
    { lat: 48.7269058, lng: 21.4198003 },
    { lat: 48.7269602, lng: 21.4200358 },
    { lat: 48.7268731, lng: 21.4221634 },
    { lat: 48.7267231, lng: 21.4223767 },
    { lat: 48.7265345, lng: 21.4230042 },
    { lat: 48.7265006, lng: 21.4255986 },
    { lat: 48.7257433, lng: 21.4256734 },
    { lat: 48.7258862, lng: 21.4271668 },
    { lat: 48.7244318, lng: 21.4269167 },
    { lat: 48.7241049, lng: 21.4269793 },
    { lat: 48.723089, lng: 21.427368 },
    { lat: 48.7230851, lng: 21.4275418 },
    { lat: 48.7235381, lng: 21.4279444 },
    { lat: 48.724267, lng: 21.4375952 },
    { lat: 48.7295423, lng: 21.4391248 },
    { lat: 48.729733, lng: 21.439188 },
    { lat: 48.7298813, lng: 21.439331 },
    { lat: 48.7301133, lng: 21.4440663 },
    { lat: 48.7300203, lng: 21.4446172 },
    { lat: 48.7322149, lng: 21.4487467 },
    { lat: 48.731942, lng: 21.4497615 },
    { lat: 48.7336768, lng: 21.4502153 },
    { lat: 48.7342638, lng: 21.450277 },
    { lat: 48.7345421, lng: 21.4527989 },
    { lat: 48.7346308, lng: 21.4529452 },
    { lat: 48.7344585, lng: 21.4562198 },
    { lat: 48.7346831, lng: 21.4561271 },
    { lat: 48.734815, lng: 21.4561318 },
    { lat: 48.7349029, lng: 21.4562194 },
    { lat: 48.7352368, lng: 21.4561305 },
    { lat: 48.7353732, lng: 21.4560194 },
    { lat: 48.7355462, lng: 21.4560097 },
    { lat: 48.7357392, lng: 21.4559152 },
    { lat: 48.7357815, lng: 21.4557808 },
    { lat: 48.7359243, lng: 21.4557382 },
    { lat: 48.73602, lng: 21.455592 },
    { lat: 48.7361362, lng: 21.4555244 },
    { lat: 48.7363374, lng: 21.4555944 },
    { lat: 48.7366081, lng: 21.4555354 },
    { lat: 48.7370216, lng: 21.4551706 },
    { lat: 48.7371195, lng: 21.4549787 },
    { lat: 48.7372591, lng: 21.4548646 },
    { lat: 48.7378894, lng: 21.4546379 },
    { lat: 48.738019, lng: 21.4546831 },
    { lat: 48.7381899, lng: 21.4545223 },
    { lat: 48.7385858, lng: 21.4545547 },
    { lat: 48.738775, lng: 21.4541767 },
    { lat: 48.7390648, lng: 21.4541214 },
    { lat: 48.7392682, lng: 21.4539959 },
    { lat: 48.7396061, lng: 21.4540054 },
    { lat: 48.7401559, lng: 21.4533327 },
    { lat: 48.7404678, lng: 21.4531526 },
    { lat: 48.7406522, lng: 21.4531834 },
    { lat: 48.7412093, lng: 21.4528742 },
    { lat: 48.7412668, lng: 21.4527695 },
    { lat: 48.7416223, lng: 21.4526452 },
    { lat: 48.7426767, lng: 21.4523838 },
    { lat: 48.7437, lng: 21.4558244 },
    { lat: 48.7440279, lng: 21.4560393 },
    { lat: 48.7444295, lng: 21.456496 },
    { lat: 48.7444217, lng: 21.4570038 },
    { lat: 48.7450453, lng: 21.4572051 },
    { lat: 48.7450368, lng: 21.4572916 },
    { lat: 48.7457639, lng: 21.4573387 },
    { lat: 48.7459096, lng: 21.4574359 },
    { lat: 48.7459502, lng: 21.4575584 },
    { lat: 48.7458545, lng: 21.4582126 },
    { lat: 48.7457927, lng: 21.4583279 },
    { lat: 48.7458123, lng: 21.4586931 },
    { lat: 48.7479657, lng: 21.4584859 },
    { lat: 48.748925, lng: 21.4582776 },
    { lat: 48.7490293, lng: 21.458307 },
    { lat: 48.7494993, lng: 21.4580649 },
    { lat: 48.7500417, lng: 21.4596455 },
    { lat: 48.7505374, lng: 21.4604426 },
    { lat: 48.7507221, lng: 21.4605986 },
    { lat: 48.7509155, lng: 21.4608695 },
    { lat: 48.75114, lng: 21.4615406 },
    { lat: 48.751757, lng: 21.4610344 },
    { lat: 48.7520308, lng: 21.4607942 },
    { lat: 48.7524584, lng: 21.4603706 },
    { lat: 48.7528832, lng: 21.460123 },
    { lat: 48.75356, lng: 21.4590955 },
    { lat: 48.753684, lng: 21.4589832 },
    { lat: 48.7545031, lng: 21.458634 },
    { lat: 48.7549139, lng: 21.4583271 },
    { lat: 48.7568434, lng: 21.4558905 },
    { lat: 48.7567015, lng: 21.4557316 },
    { lat: 48.7563046, lng: 21.4553587 },
    { lat: 48.7562592, lng: 21.4529128 },
    { lat: 48.7562047, lng: 21.4520039 },
    { lat: 48.7563683, lng: 21.4519063 },
    { lat: 48.7566237, lng: 21.4518664 },
    { lat: 48.7563511, lng: 21.4495424 },
    { lat: 48.7575471, lng: 21.449542 },
    { lat: 48.7574786, lng: 21.4473833 },
    { lat: 48.7573808, lng: 21.4463848 },
    { lat: 48.7576908, lng: 21.4462122 },
    { lat: 48.7578192, lng: 21.4460624 },
    { lat: 48.757954, lng: 21.4455202 },
    { lat: 48.758211, lng: 21.4449955 },
    { lat: 48.7582441, lng: 21.4445885 },
    { lat: 48.7585041, lng: 21.4436664 },
    { lat: 48.7586553, lng: 21.4434102 },
    { lat: 48.7587773, lng: 21.4427379 },
    { lat: 48.7588731, lng: 21.4425122 },
    { lat: 48.7589652, lng: 21.441929 },
    { lat: 48.7589617, lng: 21.4408126 },
    { lat: 48.7589262, lng: 21.4407105 },
    { lat: 48.7587677, lng: 21.4406376 },
    { lat: 48.7587258, lng: 21.4405192 },
    { lat: 48.7587407, lng: 21.4401822 },
    { lat: 48.7584622, lng: 21.4396253 },
    { lat: 48.7584262, lng: 21.4393952 },
    { lat: 48.7583742, lng: 21.4393106 },
    { lat: 48.7582814, lng: 21.4392883 },
    { lat: 48.7580469, lng: 21.4393097 },
    { lat: 48.7580101, lng: 21.4391324 },
    { lat: 48.7580213, lng: 21.4387789 },
    { lat: 48.7579363, lng: 21.4387212 },
    { lat: 48.7576854, lng: 21.438728 },
    { lat: 48.7574742, lng: 21.4383506 },
    { lat: 48.7574627, lng: 21.4382262 },
    { lat: 48.7575517, lng: 21.4380044 },
    { lat: 48.7574014, lng: 21.43767 },
    { lat: 48.7574666, lng: 21.4374 },
    { lat: 48.7574276, lng: 21.4373006 },
    { lat: 48.7573664, lng: 21.4372897 },
    { lat: 48.7573703, lng: 21.4371882 },
    { lat: 48.7579958, lng: 21.4370649 },
    { lat: 48.7586386, lng: 21.4371538 },
    { lat: 48.7596533, lng: 21.4379545 },
    { lat: 48.7598884, lng: 21.4375275 },
    { lat: 48.7604018, lng: 21.4373864 },
    { lat: 48.7604918, lng: 21.4371889 },
    { lat: 48.7606815, lng: 21.4372108 },
    { lat: 48.7607482, lng: 21.4372555 },
    { lat: 48.7608325, lng: 21.4375252 },
    { lat: 48.7609324, lng: 21.4380142 },
    { lat: 48.7609912, lng: 21.438138 },
    { lat: 48.7610641, lng: 21.4381317 },
    { lat: 48.7612668, lng: 21.4347573 },
    { lat: 48.7616759, lng: 21.4347841 },
    { lat: 48.7612137, lng: 21.4296946 },
    { lat: 48.7609414, lng: 21.4294229 },
    { lat: 48.7609083, lng: 21.4292936 },
    { lat: 48.7609366, lng: 21.4291893 },
    { lat: 48.7610041, lng: 21.4291423 },
    { lat: 48.7612459, lng: 21.4290647 },
    { lat: 48.7609787, lng: 21.4286643 },
    { lat: 48.7607559, lng: 21.4279036 },
    { lat: 48.760473, lng: 21.4278484 },
    { lat: 48.7600697, lng: 21.4282853 },
    { lat: 48.7599768, lng: 21.4284617 },
    { lat: 48.7598638, lng: 21.4290949 },
    { lat: 48.7595093, lng: 21.4286757 },
    { lat: 48.7593806, lng: 21.4286198 },
    { lat: 48.7592351, lng: 21.4286022 },
    { lat: 48.7589687, lng: 21.4287741 },
    { lat: 48.758667, lng: 21.4287627 },
    { lat: 48.7585602, lng: 21.428497 },
    { lat: 48.7585723, lng: 21.4283375 },
    { lat: 48.7587056, lng: 21.4281652 },
    { lat: 48.7587665, lng: 21.4279732 },
    { lat: 48.7587403, lng: 21.4276607 },
    { lat: 48.7586757, lng: 21.4275328 },
    { lat: 48.7583668, lng: 21.4275075 },
    { lat: 48.7577137, lng: 21.4278407 },
    { lat: 48.7574997, lng: 21.4256129 },
    { lat: 48.7603637, lng: 21.4251631 },
    { lat: 48.7604108, lng: 21.4250818 },
    { lat: 48.7605332, lng: 21.4239223 },
    { lat: 48.7610485, lng: 21.4225056 },
    { lat: 48.762329, lng: 21.4207473 },
    { lat: 48.7620432, lng: 21.4196744 },
    { lat: 48.7619246, lng: 21.4166426 },
    { lat: 48.7620855, lng: 21.4158046 },
    { lat: 48.7618895, lng: 21.4137021 },
    { lat: 48.7613463, lng: 21.4139342 },
    { lat: 48.7584909, lng: 21.4149855 },
    { lat: 48.7583619, lng: 21.4149239 },
    { lat: 48.75718, lng: 21.415329 },
    { lat: 48.7565497, lng: 21.4156315 },
    { lat: 48.756035, lng: 21.4157682 },
    { lat: 48.7550623, lng: 21.4158317 },
    { lat: 48.7543404, lng: 21.4160352 },
    { lat: 48.7528796, lng: 21.4166027 },
    { lat: 48.7517407, lng: 21.4169384 },
    { lat: 48.7506684, lng: 21.4174358 },
    { lat: 48.7487254, lng: 21.418179 },
    { lat: 48.7474331, lng: 21.4184252 },
    { lat: 48.746943, lng: 21.4186354 },
    { lat: 48.7448526, lng: 21.4205145 },
    { lat: 48.7441852, lng: 21.4208147 },
    { lat: 48.7418978, lng: 21.4214451 },
    { lat: 48.7418089, lng: 21.4203306 },
    { lat: 48.7403218, lng: 21.4212294 },
    { lat: 48.73986, lng: 21.421212 },
    { lat: 48.7391588, lng: 21.4207886 },
    { lat: 48.7384202, lng: 21.4206583 },
    { lat: 48.7379363, lng: 21.4194731 },
    { lat: 48.7377221, lng: 21.419078 },
    { lat: 48.7375779, lng: 21.4186503 },
    { lat: 48.7374529, lng: 21.4186862 },
    { lat: 48.737308, lng: 21.4188503 },
    { lat: 48.7368197, lng: 21.419198 },
    { lat: 48.7367945, lng: 21.4193754 },
    { lat: 48.7366416, lng: 21.4194891 },
    { lat: 48.7366209, lng: 21.4192459 },
    { lat: 48.7359799, lng: 21.4170405 },
    { lat: 48.7351424, lng: 21.417077 },
    { lat: 48.7345397, lng: 21.4168217 },
    { lat: 48.7321897, lng: 21.4174603 },
    { lat: 48.7310096, lng: 21.4178867 },
    { lat: 48.7277745, lng: 21.4192624 },
    { lat: 48.7270755, lng: 21.4194032 },
    { lat: 48.7270668, lng: 21.419453 },
    { lat: 48.7269058, lng: 21.4198003 },
  ],
  NižnáMyšľa: [
    { lat: 48.612575, lng: 21.3726123 },
    { lat: 48.6126817, lng: 21.3725599 },
    { lat: 48.6128935, lng: 21.3724594 },
    { lat: 48.6129598, lng: 21.3727614 },
    { lat: 48.6130231, lng: 21.3737073 },
    { lat: 48.6129936, lng: 21.3742591 },
    { lat: 48.6133843, lng: 21.3752802 },
    { lat: 48.6136667, lng: 21.3756963 },
    { lat: 48.6137343, lng: 21.3758812 },
    { lat: 48.6141614, lng: 21.3775222 },
    { lat: 48.614011, lng: 21.3789261 },
    { lat: 48.6139692, lng: 21.3797853 },
    { lat: 48.614274, lng: 21.380419 },
    { lat: 48.6145498, lng: 21.3805763 },
    { lat: 48.615112, lng: 21.3804498 },
    { lat: 48.6155788, lng: 21.3800984 },
    { lat: 48.6161223, lng: 21.3799138 },
    { lat: 48.6164033, lng: 21.3795981 },
    { lat: 48.6169895, lng: 21.3798257 },
    { lat: 48.617367, lng: 21.3802172 },
    { lat: 48.6174704, lng: 21.3807728 },
    { lat: 48.617915, lng: 21.3816072 },
    { lat: 48.6185039, lng: 21.3818851 },
    { lat: 48.6209096, lng: 21.3824049 },
    { lat: 48.6215759, lng: 21.3823695 },
    { lat: 48.621891, lng: 21.3824833 },
    { lat: 48.6223094, lng: 21.3827358 },
    { lat: 48.6232421, lng: 21.3837883 },
    { lat: 48.6234902, lng: 21.3839959 },
    { lat: 48.6237776, lng: 21.3840105 },
    { lat: 48.6241449, lng: 21.3837928 },
    { lat: 48.6243526, lng: 21.3834454 },
    { lat: 48.6243802, lng: 21.3833492 },
    { lat: 48.6243594, lng: 21.3805057 },
    { lat: 48.6245987, lng: 21.3797392 },
    { lat: 48.6253169, lng: 21.3788769 },
    { lat: 48.6253652, lng: 21.3788771 },
    { lat: 48.6254513, lng: 21.3792398 },
    { lat: 48.6255505, lng: 21.3793551 },
    { lat: 48.625721, lng: 21.3794886 },
    { lat: 48.6258166, lng: 21.3794458 },
    { lat: 48.6261288, lng: 21.3786729 },
    { lat: 48.6262566, lng: 21.3781659 },
    { lat: 48.6264505, lng: 21.3777265 },
    { lat: 48.6264978, lng: 21.3773153 },
    { lat: 48.6264349, lng: 21.3768671 },
    { lat: 48.6265604, lng: 21.3760554 },
    { lat: 48.6266383, lng: 21.3759956 },
    { lat: 48.626876, lng: 21.376036 },
    { lat: 48.627175, lng: 21.3762184 },
    { lat: 48.6272308, lng: 21.3766148 },
    { lat: 48.6273696, lng: 21.3768177 },
    { lat: 48.6276151, lng: 21.3770084 },
    { lat: 48.6278786, lng: 21.3770904 },
    { lat: 48.6294645, lng: 21.3768395 },
    { lat: 48.6299374, lng: 21.376845 },
    { lat: 48.6302919, lng: 21.3771844 },
    { lat: 48.630819, lng: 21.3773125 },
    { lat: 48.6318239, lng: 21.3769777 },
    { lat: 48.6326307, lng: 21.376975 },
    { lat: 48.633206, lng: 21.3770582 },
    { lat: 48.6340492, lng: 21.3770318 },
    { lat: 48.6343375, lng: 21.3769282 },
    { lat: 48.6346443, lng: 21.3769496 },
    { lat: 48.6350721, lng: 21.377103 },
    { lat: 48.6355365, lng: 21.3770184 },
    { lat: 48.635865, lng: 21.3772006 },
    { lat: 48.6361465, lng: 21.3772455 },
    { lat: 48.636404, lng: 21.3772045 },
    { lat: 48.6366957, lng: 21.3770562 },
    { lat: 48.6370444, lng: 21.3767535 },
    { lat: 48.6376004, lng: 21.3760044 },
    { lat: 48.638215, lng: 21.3755975 },
    { lat: 48.6385574, lng: 21.3756852 },
    { lat: 48.6391346, lng: 21.3765414 },
    { lat: 48.6395813, lng: 21.3768433 },
    { lat: 48.6400277, lng: 21.3767585 },
    { lat: 48.6406166, lng: 21.3764284 },
    { lat: 48.6411971, lng: 21.3764658 },
    { lat: 48.6413713, lng: 21.3772463 },
    { lat: 48.6413451, lng: 21.3773456 },
    { lat: 48.6410622, lng: 21.3775922 },
    { lat: 48.6410221, lng: 21.3779098 },
    { lat: 48.641183, lng: 21.3782497 },
    { lat: 48.6415881, lng: 21.3786264 },
    { lat: 48.6419706, lng: 21.3786199 },
    { lat: 48.6422172, lng: 21.378548 },
    { lat: 48.6427029, lng: 21.3780502 },
    { lat: 48.6432561, lng: 21.3772763 },
    { lat: 48.643332, lng: 21.3767712 },
    { lat: 48.6435435, lng: 21.3764686 },
    { lat: 48.6437591, lng: 21.376376 },
    { lat: 48.6439268, lng: 21.3764179 },
    { lat: 48.6445485, lng: 21.377488 },
    { lat: 48.6448848, lng: 21.3778985 },
    { lat: 48.645527, lng: 21.3780352 },
    { lat: 48.6462901, lng: 21.3778868 },
    { lat: 48.6466089, lng: 21.3775242 },
    { lat: 48.6473256, lng: 21.3773676 },
    { lat: 48.6471307, lng: 21.3781919 },
    { lat: 48.6471066, lng: 21.3788515 },
    { lat: 48.6471709, lng: 21.3792674 },
    { lat: 48.6473669, lng: 21.3795395 },
    { lat: 48.6476542, lng: 21.3796993 },
    { lat: 48.647843, lng: 21.379658 },
    { lat: 48.6482516, lng: 21.3793946 },
    { lat: 48.648558, lng: 21.3791044 },
    { lat: 48.6486267, lng: 21.3785086 },
    { lat: 48.6487149, lng: 21.3782577 },
    { lat: 48.6489094, lng: 21.3780982 },
    { lat: 48.6496416, lng: 21.37801 },
    { lat: 48.6506777, lng: 21.3785612 },
    { lat: 48.651387, lng: 21.3786549 },
    { lat: 48.6519543, lng: 21.3786227 },
    { lat: 48.6523814, lng: 21.3781523 },
    { lat: 48.6526581, lng: 21.3781064 },
    { lat: 48.6529012, lng: 21.3781465 },
    { lat: 48.6529172, lng: 21.3780041 },
    { lat: 48.6541099, lng: 21.3751012 },
    { lat: 48.6546811, lng: 21.3733926 },
    { lat: 48.6545077, lng: 21.3732389 },
    { lat: 48.6542872, lng: 21.3727895 },
    { lat: 48.6543725, lng: 21.3723705 },
    { lat: 48.6542035, lng: 21.372067 },
    { lat: 48.6542341, lng: 21.371961 },
    { lat: 48.6541086, lng: 21.3718261 },
    { lat: 48.6542204, lng: 21.3712757 },
    { lat: 48.6540408, lng: 21.3711363 },
    { lat: 48.6543289, lng: 21.3701675 },
    { lat: 48.6542515, lng: 21.369983 },
    { lat: 48.6541725, lng: 21.3694869 },
    { lat: 48.6538721, lng: 21.3692693 },
    { lat: 48.6535773, lng: 21.368888 },
    { lat: 48.6533952, lng: 21.3684865 },
    { lat: 48.6531804, lng: 21.3675519 },
    { lat: 48.6530649, lng: 21.3674103 },
    { lat: 48.6529086, lng: 21.3668936 },
    { lat: 48.6529623, lng: 21.3663179 },
    { lat: 48.6533288, lng: 21.3659736 },
    { lat: 48.6531554, lng: 21.3643907 },
    { lat: 48.6530502, lng: 21.362834 },
    { lat: 48.6526856, lng: 21.360101 },
    { lat: 48.6523845, lng: 21.3601173 },
    { lat: 48.6523888, lng: 21.3599632 },
    { lat: 48.6519664, lng: 21.359911 },
    { lat: 48.6516828, lng: 21.360055 },
    { lat: 48.6514547, lng: 21.3607433 },
    { lat: 48.6513696, lng: 21.3607932 },
    { lat: 48.6512004, lng: 21.3607105 },
    { lat: 48.6510647, lng: 21.3608005 },
    { lat: 48.650673, lng: 21.360832 },
    { lat: 48.6504698, lng: 21.3605501 },
    { lat: 48.6500416, lng: 21.3601506 },
    { lat: 48.6498779, lng: 21.3602853 },
    { lat: 48.6497556, lng: 21.3605228 },
    { lat: 48.6496537, lng: 21.3605771 },
    { lat: 48.6495067, lng: 21.3601025 },
    { lat: 48.6498243, lng: 21.359632 },
    { lat: 48.649929, lng: 21.3590668 },
    { lat: 48.649841, lng: 21.3579357 },
    { lat: 48.6495195, lng: 21.3564093 },
    { lat: 48.6503348, lng: 21.3560933 },
    { lat: 48.6505016, lng: 21.3563292 },
    { lat: 48.650735, lng: 21.355761 },
    { lat: 48.650488, lng: 21.355541 },
    { lat: 48.650398, lng: 21.355562 },
    { lat: 48.65009, lng: 21.355633 },
    { lat: 48.64998, lng: 21.355677 },
    { lat: 48.649788, lng: 21.355754 },
    { lat: 48.64849, lng: 21.35627 },
    { lat: 48.648408, lng: 21.356303 },
    { lat: 48.648364, lng: 21.356323 },
    { lat: 48.648356, lng: 21.356328 },
    { lat: 48.647625, lng: 21.356844 },
    { lat: 48.647314, lng: 21.357063 },
    { lat: 48.647218, lng: 21.357163 },
    { lat: 48.646697, lng: 21.357706 },
    { lat: 48.646374, lng: 21.357769 },
    { lat: 48.646186, lng: 21.357868 },
    { lat: 48.645757, lng: 21.358041 },
    { lat: 48.645734, lng: 21.35805 },
    { lat: 48.645427, lng: 21.358062 },
    { lat: 48.645335, lng: 21.358066 },
    { lat: 48.641721, lng: 21.358213 },
    { lat: 48.64286, lng: 21.356648 },
    { lat: 48.643259, lng: 21.356033 },
    { lat: 48.643528, lng: 21.355583 },
    { lat: 48.643835, lng: 21.355067 },
    { lat: 48.644353, lng: 21.354199 },
    { lat: 48.644728, lng: 21.352817 },
    { lat: 48.644739, lng: 21.352776 },
    { lat: 48.644688, lng: 21.351725 },
    { lat: 48.644441, lng: 21.350673 },
    { lat: 48.644381, lng: 21.350412 },
    { lat: 48.643834, lng: 21.348839 },
    { lat: 48.643708, lng: 21.348227 },
    { lat: 48.643634, lng: 21.347863 },
    { lat: 48.643574, lng: 21.347311 },
    { lat: 48.643566, lng: 21.345974 },
    { lat: 48.643861, lng: 21.344452 },
    { lat: 48.643989, lng: 21.344105 },
    { lat: 48.644331, lng: 21.343176 },
    { lat: 48.644599, lng: 21.342626 },
    { lat: 48.644943, lng: 21.341921 },
    { lat: 48.646186, lng: 21.33937 },
    { lat: 48.646441, lng: 21.338183 },
    { lat: 48.646409, lng: 21.335792 },
    { lat: 48.64647, lng: 21.33446 },
    { lat: 48.646493, lng: 21.333954 },
    { lat: 48.646591, lng: 21.33332 },
    { lat: 48.646598, lng: 21.333281 },
    { lat: 48.646638, lng: 21.333052 },
    { lat: 48.646464, lng: 21.3329525 },
    { lat: 48.6452797, lng: 21.332569 },
    { lat: 48.6443966, lng: 21.3320556 },
    { lat: 48.6442155, lng: 21.3320232 },
    { lat: 48.6438105, lng: 21.3321003 },
    { lat: 48.6433296, lng: 21.3324245 },
    { lat: 48.6429473, lng: 21.333179 },
    { lat: 48.6428707, lng: 21.3342521 },
    { lat: 48.6429006, lng: 21.3347194 },
    { lat: 48.6431975, lng: 21.3357548 },
    { lat: 48.6434023, lng: 21.3359794 },
    { lat: 48.6436665, lng: 21.3361149 },
    { lat: 48.6433992, lng: 21.3369525 },
    { lat: 48.6432945, lng: 21.3376615 },
    { lat: 48.6432387, lng: 21.3377503 },
    { lat: 48.6424596, lng: 21.3381388 },
    { lat: 48.6423155, lng: 21.3382883 },
    { lat: 48.6420078, lng: 21.3383891 },
    { lat: 48.6416636, lng: 21.3382776 },
    { lat: 48.6409364, lng: 21.3378278 },
    { lat: 48.640398, lng: 21.3379581 },
    { lat: 48.6402201, lng: 21.3381432 },
    { lat: 48.6398743, lng: 21.3382791 },
    { lat: 48.6395186, lng: 21.3378709 },
    { lat: 48.6392355, lng: 21.3376652 },
    { lat: 48.6388703, lng: 21.3376818 },
    { lat: 48.6386584, lng: 21.3375217 },
    { lat: 48.6384792, lng: 21.3371711 },
    { lat: 48.6382356, lng: 21.3372331 },
    { lat: 48.638199, lng: 21.3370616 },
    { lat: 48.6374781, lng: 21.3365932 },
    { lat: 48.6371793, lng: 21.3361338 },
    { lat: 48.6370974, lng: 21.3357335 },
    { lat: 48.6370737, lng: 21.3353153 },
    { lat: 48.6371084, lng: 21.3346383 },
    { lat: 48.6371805, lng: 21.3345654 },
    { lat: 48.6376885, lng: 21.3334697 },
    { lat: 48.637603, lng: 21.3330315 },
    { lat: 48.6374787, lng: 21.332827 },
    { lat: 48.6373492, lng: 21.332452 },
    { lat: 48.6368658, lng: 21.332095 },
    { lat: 48.6364826, lng: 21.3321118 },
    { lat: 48.6360363, lng: 21.3323148 },
    { lat: 48.6356897, lng: 21.332575 },
    { lat: 48.6346717, lng: 21.333033 },
    { lat: 48.6345019, lng: 21.3334848 },
    { lat: 48.6344473, lng: 21.3341763 },
    { lat: 48.6346825, lng: 21.3347685 },
    { lat: 48.6357343, lng: 21.3355353 },
    { lat: 48.6360631, lng: 21.3359901 },
    { lat: 48.636168, lng: 21.336291 },
    { lat: 48.6363322, lng: 21.3375873 },
    { lat: 48.6362314, lng: 21.3376785 },
    { lat: 48.6359786, lng: 21.337654 },
    { lat: 48.6353629, lng: 21.337875 },
    { lat: 48.6351951, lng: 21.3380939 },
    { lat: 48.6351596, lng: 21.3386685 },
    { lat: 48.6350617, lng: 21.338783 },
    { lat: 48.6349455, lng: 21.3388106 },
    { lat: 48.6346102, lng: 21.3385846 },
    { lat: 48.6341864, lng: 21.3384484 },
    { lat: 48.6339555, lng: 21.3383006 },
    { lat: 48.6334647, lng: 21.3375118 },
    { lat: 48.6332953, lng: 21.337415 },
    { lat: 48.6326312, lng: 21.3377087 },
    { lat: 48.6324506, lng: 21.3380605 },
    { lat: 48.6329432, lng: 21.3386528 },
    { lat: 48.6331188, lng: 21.3389876 },
    { lat: 48.6332731, lng: 21.3396209 },
    { lat: 48.6332129, lng: 21.3399765 },
    { lat: 48.6328971, lng: 21.3399908 },
    { lat: 48.6326774, lng: 21.3397597 },
    { lat: 48.6325667, lng: 21.339482 },
    { lat: 48.6324763, lng: 21.3388397 },
    { lat: 48.6323202, lng: 21.3388099 },
    { lat: 48.6321724, lng: 21.3391022 },
    { lat: 48.6320748, lng: 21.339148 },
    { lat: 48.6318533, lng: 21.3390775 },
    { lat: 48.6317782, lng: 21.3388319 },
    { lat: 48.6318506, lng: 21.3381378 },
    { lat: 48.6316699, lng: 21.3372523 },
    { lat: 48.6316333, lng: 21.3366436 },
    { lat: 48.6315387, lng: 21.3363377 },
    { lat: 48.6312016, lng: 21.3358746 },
    { lat: 48.6310404, lng: 21.3357552 },
    { lat: 48.6308958, lng: 21.3358477 },
    { lat: 48.6306746, lng: 21.3361963 },
    { lat: 48.630644, lng: 21.3363378 },
    { lat: 48.6306639, lng: 21.3366623 },
    { lat: 48.6307726, lng: 21.3369103 },
    { lat: 48.6307828, lng: 21.3370594 },
    { lat: 48.6306961, lng: 21.3373915 },
    { lat: 48.6305723, lng: 21.3375064 },
    { lat: 48.6302802, lng: 21.3375162 },
    { lat: 48.6299384, lng: 21.3371253 },
    { lat: 48.6295961, lng: 21.3365587 },
    { lat: 48.6294436, lng: 21.3365393 },
    { lat: 48.6293374, lng: 21.3366772 },
    { lat: 48.6294123, lng: 21.3371029 },
    { lat: 48.6295699, lng: 21.3376267 },
    { lat: 48.629673, lng: 21.3377996 },
    { lat: 48.6301579, lng: 21.337831 },
    { lat: 48.630287, lng: 21.3379694 },
    { lat: 48.6303141, lng: 21.3381251 },
    { lat: 48.6302336, lng: 21.338512 },
    { lat: 48.6299898, lng: 21.3386668 },
    { lat: 48.6298592, lng: 21.3386683 },
    { lat: 48.6295043, lng: 21.3383189 },
    { lat: 48.6292417, lng: 21.3376041 },
    { lat: 48.628614, lng: 21.3362738 },
    { lat: 48.6280279, lng: 21.3355003 },
    { lat: 48.6275094, lng: 21.3349487 },
    { lat: 48.6272446, lng: 21.3348228 },
    { lat: 48.6268125, lng: 21.3348288 },
    { lat: 48.6266671, lng: 21.334747 },
    { lat: 48.6265432, lng: 21.3344795 },
    { lat: 48.6265937, lng: 21.3341441 },
    { lat: 48.6267912, lng: 21.3338616 },
    { lat: 48.626951, lng: 21.3337876 },
    { lat: 48.6271065, lng: 21.3336118 },
    { lat: 48.6271986, lng: 21.3334209 },
    { lat: 48.6272519, lng: 21.3330938 },
    { lat: 48.6271889, lng: 21.3317243 },
    { lat: 48.6269275, lng: 21.33134 },
    { lat: 48.6267382, lng: 21.3312958 },
    { lat: 48.6264747, lng: 21.3315495 },
    { lat: 48.6263604, lng: 21.3317534 },
    { lat: 48.6262635, lng: 21.332082 },
    { lat: 48.6260358, lng: 21.3323723 },
    { lat: 48.6260198, lng: 21.3326203 },
    { lat: 48.6258475, lng: 21.332725 },
    { lat: 48.6255752, lng: 21.332768 },
    { lat: 48.62497, lng: 21.3322203 },
    { lat: 48.6248735, lng: 21.3321878 },
    { lat: 48.6248196, lng: 21.3324142 },
    { lat: 48.6249531, lng: 21.3326956 },
    { lat: 48.6249513, lng: 21.3328227 },
    { lat: 48.6247611, lng: 21.3332317 },
    { lat: 48.6247566, lng: 21.3336746 },
    { lat: 48.6246926, lng: 21.3338682 },
    { lat: 48.6246672, lng: 21.3339244 },
    { lat: 48.6247423, lng: 21.3340209 },
    { lat: 48.62474, lng: 21.3341015 },
    { lat: 48.6246297, lng: 21.3342126 },
    { lat: 48.6245647, lng: 21.3344028 },
    { lat: 48.6244089, lng: 21.3344267 },
    { lat: 48.6242419, lng: 21.3349326 },
    { lat: 48.6242608, lng: 21.3350725 },
    { lat: 48.624169, lng: 21.3351258 },
    { lat: 48.6239309, lng: 21.3351049 },
    { lat: 48.6238508, lng: 21.3348738 },
    { lat: 48.6238552, lng: 21.3346441 },
    { lat: 48.6236102, lng: 21.3342487 },
    { lat: 48.6235469, lng: 21.3340891 },
    { lat: 48.6235317, lng: 21.3338451 },
    { lat: 48.6234393, lng: 21.3337305 },
    { lat: 48.6233556, lng: 21.3337523 },
    { lat: 48.6232947, lng: 21.3340848 },
    { lat: 48.6234182, lng: 21.3345209 },
    { lat: 48.6235943, lng: 21.334799 },
    { lat: 48.6238397, lng: 21.3357692 },
    { lat: 48.6240324, lng: 21.3360039 },
    { lat: 48.6242475, lng: 21.3358962 },
    { lat: 48.6246765, lng: 21.3358645 },
    { lat: 48.6249036, lng: 21.3359271 },
    { lat: 48.6250113, lng: 21.3365497 },
    { lat: 48.6246496, lng: 21.3366824 },
    { lat: 48.6244565, lng: 21.3369276 },
    { lat: 48.624188, lng: 21.3369372 },
    { lat: 48.6241461, lng: 21.3368234 },
    { lat: 48.6239588, lng: 21.3371806 },
    { lat: 48.6235495, lng: 21.337222 },
    { lat: 48.623813, lng: 21.3383475 },
    { lat: 48.6239606, lng: 21.3387211 },
    { lat: 48.6241528, lng: 21.3386826 },
    { lat: 48.6242121, lng: 21.338591 },
    { lat: 48.6243456, lng: 21.3386946 },
    { lat: 48.6243677, lng: 21.3390107 },
    { lat: 48.624239, lng: 21.3392529 },
    { lat: 48.6243494, lng: 21.3394513 },
    { lat: 48.6244723, lng: 21.3394592 },
    { lat: 48.6247906, lng: 21.3392249 },
    { lat: 48.6247501, lng: 21.338782 },
    { lat: 48.6249873, lng: 21.3383609 },
    { lat: 48.6253684, lng: 21.338541 },
    { lat: 48.6254556, lng: 21.3388889 },
    { lat: 48.6255477, lng: 21.3401098 },
    { lat: 48.6254988, lng: 21.3402074 },
    { lat: 48.6250486, lng: 21.3402381 },
    { lat: 48.6249625, lng: 21.3406363 },
    { lat: 48.625265, lng: 21.340845 },
    { lat: 48.6254653, lng: 21.3407006 },
    { lat: 48.6254987, lng: 21.3411736 },
    { lat: 48.6253097, lng: 21.3413548 },
    { lat: 48.6251363, lng: 21.341689 },
    { lat: 48.624947, lng: 21.3426116 },
    { lat: 48.6248035, lng: 21.3429669 },
    { lat: 48.6246256, lng: 21.3430414 },
    { lat: 48.6245446, lng: 21.3429978 },
    { lat: 48.6245947, lng: 21.3426106 },
    { lat: 48.6243654, lng: 21.3421728 },
    { lat: 48.6241883, lng: 21.3421256 },
    { lat: 48.6239722, lng: 21.3422326 },
    { lat: 48.6239555, lng: 21.3425756 },
    { lat: 48.6240716, lng: 21.3428763 },
    { lat: 48.6241662, lng: 21.3429157 },
    { lat: 48.6241758, lng: 21.3431242 },
    { lat: 48.6240646, lng: 21.3432841 },
    { lat: 48.6239456, lng: 21.3433243 },
    { lat: 48.623835, lng: 21.3437242 },
    { lat: 48.6234936, lng: 21.3437086 },
    { lat: 48.6231013, lng: 21.3434304 },
    { lat: 48.6228699, lng: 21.3436647 },
    { lat: 48.6227244, lng: 21.3437276 },
    { lat: 48.6226168, lng: 21.3440843 },
    { lat: 48.6226243, lng: 21.3443332 },
    { lat: 48.6225597, lng: 21.3444272 },
    { lat: 48.6224679, lng: 21.3444407 },
    { lat: 48.6222366, lng: 21.3442684 },
    { lat: 48.6221357, lng: 21.3443251 },
    { lat: 48.6219564, lng: 21.3449471 },
    { lat: 48.6220775, lng: 21.345074 },
    { lat: 48.6223246, lng: 21.3451356 },
    { lat: 48.622568, lng: 21.3450363 },
    { lat: 48.6226528, lng: 21.3451579 },
    { lat: 48.6225734, lng: 21.3455108 },
    { lat: 48.6224582, lng: 21.3456102 },
    { lat: 48.6222214, lng: 21.3455521 },
    { lat: 48.6222157, lng: 21.3453334 },
    { lat: 48.6220377, lng: 21.3453957 },
    { lat: 48.6218364, lng: 21.3455885 },
    { lat: 48.6215162, lng: 21.3463549 },
    { lat: 48.6212213, lng: 21.3466864 },
    { lat: 48.6210294, lng: 21.3466658 },
    { lat: 48.6209583, lng: 21.3465839 },
    { lat: 48.620717, lng: 21.3459393 },
    { lat: 48.6208082, lng: 21.3455225 },
    { lat: 48.6207794, lng: 21.3451333 },
    { lat: 48.6205258, lng: 21.3448234 },
    { lat: 48.6203648, lng: 21.3448196 },
    { lat: 48.6202182, lng: 21.3442911 },
    { lat: 48.6197256, lng: 21.3442994 },
    { lat: 48.619622, lng: 21.3442169 },
    { lat: 48.6195119, lng: 21.3450869 },
    { lat: 48.6195498, lng: 21.3458185 },
    { lat: 48.6195286, lng: 21.3459362 },
    { lat: 48.6192871, lng: 21.3463266 },
    { lat: 48.6192647, lng: 21.3464747 },
    { lat: 48.6193131, lng: 21.3465525 },
    { lat: 48.6199585, lng: 21.3466771 },
    { lat: 48.6200225, lng: 21.346789 },
    { lat: 48.6200209, lng: 21.3469936 },
    { lat: 48.6198724, lng: 21.3473716 },
    { lat: 48.6197807, lng: 21.3474284 },
    { lat: 48.6195076, lng: 21.3471388 },
    { lat: 48.6194296, lng: 21.3473899 },
    { lat: 48.6193509, lng: 21.3474316 },
    { lat: 48.6186552, lng: 21.3470465 },
    { lat: 48.6185132, lng: 21.3467418 },
    { lat: 48.6186202, lng: 21.3463027 },
    { lat: 48.6184913, lng: 21.346165 },
    { lat: 48.6183199, lng: 21.3455967 },
    { lat: 48.6181733, lng: 21.3455125 },
    { lat: 48.6180917, lng: 21.3457522 },
    { lat: 48.6180679, lng: 21.3460426 },
    { lat: 48.6180939, lng: 21.3462709 },
    { lat: 48.6182822, lng: 21.3467677 },
    { lat: 48.6180246, lng: 21.3475927 },
    { lat: 48.6178189, lng: 21.3478259 },
    { lat: 48.6177919, lng: 21.3480486 },
    { lat: 48.617581, lng: 21.3483352 },
    { lat: 48.6171245, lng: 21.3484594 },
    { lat: 48.6169442, lng: 21.3483778 },
    { lat: 48.6165194, lng: 21.3479855 },
    { lat: 48.6164207, lng: 21.347769 },
    { lat: 48.6165865, lng: 21.3474873 },
    { lat: 48.6168281, lng: 21.3474376 },
    { lat: 48.617011, lng: 21.3475653 },
    { lat: 48.6171306, lng: 21.3476146 },
    { lat: 48.6172692, lng: 21.3475514 },
    { lat: 48.6173224, lng: 21.3474062 },
    { lat: 48.6164747, lng: 21.3465802 },
    { lat: 48.6164386, lng: 21.346689 },
    { lat: 48.6162905, lng: 21.3467746 },
    { lat: 48.6161543, lng: 21.3467416 },
    { lat: 48.6161039, lng: 21.3465235 },
    { lat: 48.6162267, lng: 21.346195 },
    { lat: 48.6160952, lng: 21.3457499 },
    { lat: 48.6158939, lng: 21.3457157 },
    { lat: 48.6157675, lng: 21.3459648 },
    { lat: 48.6157406, lng: 21.346191 },
    { lat: 48.6157487, lng: 21.3463886 },
    { lat: 48.6158306, lng: 21.3464541 },
    { lat: 48.6158616, lng: 21.3466227 },
    { lat: 48.6158132, lng: 21.3469543 },
    { lat: 48.6155934, lng: 21.3472432 },
    { lat: 48.615295, lng: 21.3472423 },
    { lat: 48.6151812, lng: 21.348056 },
    { lat: 48.6149581, lng: 21.3481197 },
    { lat: 48.6148546, lng: 21.3483064 },
    { lat: 48.6147212, lng: 21.3480279 },
    { lat: 48.6143895, lng: 21.3478508 },
    { lat: 48.6143267, lng: 21.3475994 },
    { lat: 48.6143934, lng: 21.3473099 },
    { lat: 48.6143596, lng: 21.3470797 },
    { lat: 48.6139715, lng: 21.3467151 },
    { lat: 48.6137366, lng: 21.3468696 },
    { lat: 48.6136567, lng: 21.347098 },
    { lat: 48.6131721, lng: 21.347036 },
    { lat: 48.6129493, lng: 21.3471839 },
    { lat: 48.6128405, lng: 21.3468233 },
    { lat: 48.6126946, lng: 21.3467231 },
    { lat: 48.6113061, lng: 21.3462846 },
    { lat: 48.6103851, lng: 21.3455246 },
    { lat: 48.6097225, lng: 21.3450876 },
    { lat: 48.6084113, lng: 21.3448217 },
    { lat: 48.6083954, lng: 21.3448185 },
    { lat: 48.6088259, lng: 21.3455396 },
    { lat: 48.6082997, lng: 21.3459662 },
    { lat: 48.6077699, lng: 21.3454948 },
    { lat: 48.6076867, lng: 21.3450798 },
    { lat: 48.6073283, lng: 21.3455521 },
    { lat: 48.6072055, lng: 21.3462945 },
    { lat: 48.6068443, lng: 21.3459609 },
    { lat: 48.6060607, lng: 21.3456875 },
    { lat: 48.605722, lng: 21.3456877 },
    { lat: 48.6055123, lng: 21.3462298 },
    { lat: 48.6054825, lng: 21.3463282 },
    { lat: 48.6055989, lng: 21.3466765 },
    { lat: 48.6056534, lng: 21.3470994 },
    { lat: 48.6059166, lng: 21.347922 },
    { lat: 48.6059533, lng: 21.3491 },
    { lat: 48.6060147, lng: 21.3496419 },
    { lat: 48.6060016, lng: 21.3504016 },
    { lat: 48.6060675, lng: 21.3508288 },
    { lat: 48.6062491, lng: 21.3512192 },
    { lat: 48.6064233, lng: 21.3512876 },
    { lat: 48.6066331, lng: 21.3512299 },
    { lat: 48.6068689, lng: 21.3510573 },
    { lat: 48.6070188, lng: 21.3519249 },
    { lat: 48.6069823, lng: 21.3521749 },
    { lat: 48.6070224, lng: 21.3529427 },
    { lat: 48.6071057, lng: 21.3532428 },
    { lat: 48.6068815, lng: 21.353308 },
    { lat: 48.6064922, lng: 21.3537617 },
    { lat: 48.6065566, lng: 21.3541061 },
    { lat: 48.6063784, lng: 21.3545422 },
    { lat: 48.6063811, lng: 21.3547017 },
    { lat: 48.6063101, lng: 21.3548473 },
    { lat: 48.6060733, lng: 21.3549812 },
    { lat: 48.605849, lng: 21.3555353 },
    { lat: 48.6057668, lng: 21.3555708 },
    { lat: 48.6056257, lng: 21.3554795 },
    { lat: 48.6052144, lng: 21.3554941 },
    { lat: 48.6049687, lng: 21.3558957 },
    { lat: 48.6051969, lng: 21.3562232 },
    { lat: 48.6050556, lng: 21.3566528 },
    { lat: 48.6050702, lng: 21.3570981 },
    { lat: 48.6051226, lng: 21.3573796 },
    { lat: 48.6051972, lng: 21.3575075 },
    { lat: 48.6057489, lng: 21.3576316 },
    { lat: 48.6061256, lng: 21.3580247 },
    { lat: 48.6061027, lng: 21.358572 },
    { lat: 48.606351, lng: 21.3592001 },
    { lat: 48.6070426, lng: 21.360083 },
    { lat: 48.6074481, lng: 21.3601683 },
    { lat: 48.6076509, lng: 21.3604019 },
    { lat: 48.6076524, lng: 21.3608598 },
    { lat: 48.6077633, lng: 21.3611051 },
    { lat: 48.6081622, lng: 21.3616109 },
    { lat: 48.6088265, lng: 21.3627425 },
    { lat: 48.6092661, lng: 21.3632913 },
    { lat: 48.6094576, lng: 21.3632221 },
    { lat: 48.6095263, lng: 21.3631167 },
    { lat: 48.6095563, lng: 21.3629134 },
    { lat: 48.6097053, lng: 21.362777 },
    { lat: 48.6097654, lng: 21.3622662 },
    { lat: 48.609903, lng: 21.362059 },
    { lat: 48.6101043, lng: 21.3614912 },
    { lat: 48.6103507, lng: 21.3614132 },
    { lat: 48.6104497, lng: 21.362016 },
    { lat: 48.6106443, lng: 21.3627559 },
    { lat: 48.6106289, lng: 21.3637103 },
    { lat: 48.6104576, lng: 21.3642636 },
    { lat: 48.6103755, lng: 21.3643023 },
    { lat: 48.6102129, lng: 21.3641722 },
    { lat: 48.6101073, lng: 21.3642822 },
    { lat: 48.6100305, lng: 21.3646142 },
    { lat: 48.6102501, lng: 21.3651451 },
    { lat: 48.6102972, lng: 21.3655099 },
    { lat: 48.6103676, lng: 21.3656499 },
    { lat: 48.6105799, lng: 21.3657061 },
    { lat: 48.6108477, lng: 21.3654108 },
    { lat: 48.6116027, lng: 21.365589 },
    { lat: 48.6122857, lng: 21.3655139 },
    { lat: 48.6123913, lng: 21.3653961 },
    { lat: 48.6127731, lng: 21.3654024 },
    { lat: 48.6136356, lng: 21.3662489 },
    { lat: 48.6137622, lng: 21.3665692 },
    { lat: 48.6139512, lng: 21.3668636 },
    { lat: 48.6140376, lng: 21.3670803 },
    { lat: 48.6140322, lng: 21.3676142 },
    { lat: 48.6138352, lng: 21.3677197 },
    { lat: 48.6132295, lng: 21.367787 },
    { lat: 48.6122217, lng: 21.3678455 },
    { lat: 48.6121755, lng: 21.3677274 },
    { lat: 48.6119567, lng: 21.3677931 },
    { lat: 48.6114473, lng: 21.3686241 },
    { lat: 48.6113581, lng: 21.3689915 },
    { lat: 48.6114266, lng: 21.3703382 },
    { lat: 48.6115231, lng: 21.370812 },
    { lat: 48.6119658, lng: 21.371359 },
    { lat: 48.6119004, lng: 21.3715381 },
    { lat: 48.612575, lng: 21.3726123 },
  ],
  Valaliky: [
    { lat: 48.6317729, lng: 21.3154737 },
    { lat: 48.6317927, lng: 21.3154968 },
    { lat: 48.6320473, lng: 21.3155016 },
    { lat: 48.6319389, lng: 21.3143802 },
    { lat: 48.6320827, lng: 21.3143013 },
    { lat: 48.6326473, lng: 21.312739 },
    { lat: 48.632625, lng: 21.3114505 },
    { lat: 48.6328084, lng: 21.3109682 },
    { lat: 48.6337302, lng: 21.3110131 },
    { lat: 48.6340512, lng: 21.3115017 },
    { lat: 48.6342144, lng: 21.3114651 },
    { lat: 48.6345378, lng: 21.312219 },
    { lat: 48.6353861, lng: 21.312321 },
    { lat: 48.6360758, lng: 21.3112417 },
    { lat: 48.6363765, lng: 21.3109949 },
    { lat: 48.6369029, lng: 21.3107257 },
    { lat: 48.636601, lng: 21.3095721 },
    { lat: 48.6368928, lng: 21.3094352 },
    { lat: 48.6382064, lng: 21.3093009 },
    { lat: 48.6384757, lng: 21.3098647 },
    { lat: 48.6396359, lng: 21.3088654 },
    { lat: 48.6407531, lng: 21.3082277 },
    { lat: 48.6432589, lng: 21.3075167 },
    { lat: 48.6434349, lng: 21.3077116 },
    { lat: 48.6435953, lng: 21.3077286 },
    { lat: 48.6443448, lng: 21.3075445 },
    { lat: 48.6444655, lng: 21.3077756 },
    { lat: 48.6451767, lng: 21.3077753 },
    { lat: 48.6461639, lng: 21.3082619 },
    { lat: 48.647016, lng: 21.3078639 },
    { lat: 48.6475459, lng: 21.3078655 },
    { lat: 48.6481968, lng: 21.3082445 },
    { lat: 48.6483458, lng: 21.3079256 },
    { lat: 48.6506915, lng: 21.3142569 },
    { lat: 48.650812, lng: 21.314231 },
    { lat: 48.650515, lng: 21.305838 },
    { lat: 48.650507, lng: 21.305633 },
    { lat: 48.650392, lng: 21.302241 },
    { lat: 48.650345, lng: 21.300825 },
    { lat: 48.650165, lng: 21.299176 },
    { lat: 48.649969, lng: 21.297985 },
    { lat: 48.649821, lng: 21.297324 },
    { lat: 48.64943, lng: 21.295902 },
    { lat: 48.649128, lng: 21.294939 },
    { lat: 48.647057, lng: 21.288595 },
    { lat: 48.646958, lng: 21.288223 },
    { lat: 48.647018, lng: 21.288128 },
    { lat: 48.647092, lng: 21.288011 },
    { lat: 48.647129, lng: 21.287776 },
    { lat: 48.647182, lng: 21.287434 },
    { lat: 48.647703, lng: 21.287211 },
    { lat: 48.648394, lng: 21.286914 },
    { lat: 48.649416, lng: 21.286297 },
    { lat: 48.649754, lng: 21.286081 },
    { lat: 48.651285, lng: 21.284669 },
    { lat: 48.651254, lng: 21.284599 },
    { lat: 48.651229, lng: 21.284538 },
    { lat: 48.651199, lng: 21.284467 },
    { lat: 48.651196, lng: 21.284462 },
    { lat: 48.650882, lng: 21.283724 },
    { lat: 48.650727, lng: 21.283358 },
    { lat: 48.650334, lng: 21.282435 },
    { lat: 48.65033, lng: 21.282426 },
    { lat: 48.650325, lng: 21.282414 },
    { lat: 48.649251, lng: 21.279889 },
    { lat: 48.649006, lng: 21.279316 },
    { lat: 48.648969, lng: 21.279231 },
    { lat: 48.6474, lng: 21.275543 },
    { lat: 48.644573, lng: 21.268903 },
    { lat: 48.644654, lng: 21.268917 },
    { lat: 48.644753, lng: 21.268934 },
    { lat: 48.645545, lng: 21.269066 },
    { lat: 48.645552, lng: 21.268973 },
    { lat: 48.645553, lng: 21.268968 },
    { lat: 48.645564, lng: 21.268837 },
    { lat: 48.645564, lng: 21.268827 },
    { lat: 48.645569, lng: 21.268768 },
    { lat: 48.645411, lng: 21.264345 },
    { lat: 48.645373, lng: 21.263524 },
    { lat: 48.645372, lng: 21.263498 },
    { lat: 48.644692, lng: 21.2628531 },
    { lat: 48.6445177, lng: 21.2627088 },
    { lat: 48.6443621, lng: 21.2626653 },
    { lat: 48.6404601, lng: 21.2621452 },
    { lat: 48.6333673, lng: 21.2610039 },
    { lat: 48.6322883, lng: 21.2609397 },
    { lat: 48.6323594, lng: 21.2625517 },
    { lat: 48.6290486, lng: 21.2622246 },
    { lat: 48.629146, lng: 21.2629051 },
    { lat: 48.6291936, lng: 21.2644746 },
    { lat: 48.6292742, lng: 21.2701181 },
    { lat: 48.6292464, lng: 21.2710728 },
    { lat: 48.6292007, lng: 21.2715947 },
    { lat: 48.6291457, lng: 21.2715698 },
    { lat: 48.6288867, lng: 21.2750648 },
    { lat: 48.6286726, lng: 21.2751293 },
    { lat: 48.6288022, lng: 21.2757616 },
    { lat: 48.629288, lng: 21.2773132 },
    { lat: 48.6282036, lng: 21.27755 },
    { lat: 48.6252417, lng: 21.2780367 },
    { lat: 48.6231593, lng: 21.2782035 },
    { lat: 48.6219276, lng: 21.2782278 },
    { lat: 48.6223416, lng: 21.2815493 },
    { lat: 48.6228457, lng: 21.2846227 },
    { lat: 48.6242914, lng: 21.2923761 },
    { lat: 48.6247424, lng: 21.294389 },
    { lat: 48.6258502, lng: 21.2984415 },
    { lat: 48.6265995, lng: 21.3006045 },
    { lat: 48.6281313, lng: 21.3034862 },
    { lat: 48.6290243, lng: 21.3053469 },
    { lat: 48.6297541, lng: 21.3072056 },
    { lat: 48.630063, lng: 21.3081073 },
    { lat: 48.6307152, lng: 21.3103784 },
    { lat: 48.6317729, lng: 21.3154737 },
  ],
  NováPolhora: [
    { lat: 48.827222, lng: 21.306324 },
    { lat: 48.8269453, lng: 21.3136687 },
    { lat: 48.8226459, lng: 21.3163523 },
    { lat: 48.8232021, lng: 21.3220082 },
    { lat: 48.8242088, lng: 21.3313396 },
    { lat: 48.8274132, lng: 21.3287492 },
    { lat: 48.833542, lng: 21.3328953 },
    { lat: 48.834324, lng: 21.333465 },
    { lat: 48.83418, lng: 21.333146 },
    { lat: 48.833997, lng: 21.331295 },
    { lat: 48.83374, lng: 21.328511 },
    { lat: 48.833523, lng: 21.326015 },
    { lat: 48.833721, lng: 21.325564 },
    { lat: 48.833855, lng: 21.325343 },
    { lat: 48.833994, lng: 21.325064 },
    { lat: 48.834114, lng: 21.324826 },
    { lat: 48.834529, lng: 21.324152 },
    { lat: 48.834971, lng: 21.323034 },
    { lat: 48.835264, lng: 21.322433 },
    { lat: 48.835657, lng: 21.3217263 },
    { lat: 48.835617, lng: 21.321596 },
    { lat: 48.835431, lng: 21.321133 },
    { lat: 48.835348, lng: 21.320948 },
    { lat: 48.835382, lng: 21.320912 },
    { lat: 48.835428, lng: 21.320865 },
    { lat: 48.836881, lng: 21.319355 },
    { lat: 48.836762, lng: 21.319293 },
    { lat: 48.836719, lng: 21.319226 },
    { lat: 48.836688, lng: 21.319136 },
    { lat: 48.83669, lng: 21.318804 },
    { lat: 48.836818, lng: 21.318655 },
    { lat: 48.836365, lng: 21.317496 },
    { lat: 48.837121, lng: 21.316829 },
    { lat: 48.837154, lng: 21.316799 },
    { lat: 48.837027, lng: 21.316574 },
    { lat: 48.836958, lng: 21.316577 },
    { lat: 48.836796, lng: 21.316824 },
    { lat: 48.836769, lng: 21.316904 },
    { lat: 48.836693, lng: 21.316938 },
    { lat: 48.836647, lng: 21.316939 },
    { lat: 48.836548, lng: 21.316844 },
    { lat: 48.836534, lng: 21.316785 },
    { lat: 48.836555, lng: 21.316561 },
    { lat: 48.836628, lng: 21.316225 },
    { lat: 48.836659, lng: 21.316142 },
    { lat: 48.836639, lng: 21.316104 },
    { lat: 48.836575, lng: 21.316074 },
    { lat: 48.836359, lng: 21.31607 },
    { lat: 48.836227, lng: 21.315974 },
    { lat: 48.836207, lng: 21.315844 },
    { lat: 48.836194, lng: 21.315593 },
    { lat: 48.836194, lng: 21.31547 },
    { lat: 48.836147, lng: 21.31533 },
    { lat: 48.835947, lng: 21.315109 },
    { lat: 48.835824, lng: 21.315091 },
    { lat: 48.835787, lng: 21.315002 },
    { lat: 48.835742, lng: 21.314691 },
    { lat: 48.835699, lng: 21.314574 },
    { lat: 48.835602, lng: 21.314284 },
    { lat: 48.835566, lng: 21.314124 },
    { lat: 48.835272, lng: 21.31392 },
    { lat: 48.834835, lng: 21.313337 },
    { lat: 48.834796, lng: 21.313212 },
    { lat: 48.834779, lng: 21.313164 },
    { lat: 48.834749, lng: 21.312841 },
    { lat: 48.834039, lng: 21.311604 },
    { lat: 48.833582, lng: 21.310362 },
    { lat: 48.833181, lng: 21.309783 },
    { lat: 48.832743, lng: 21.308813 },
    { lat: 48.83245, lng: 21.308026 },
    { lat: 48.832244, lng: 21.307751 },
    { lat: 48.83171, lng: 21.30704 },
    { lat: 48.831353, lng: 21.306565 },
    { lat: 48.831153, lng: 21.306299 },
    { lat: 48.831057, lng: 21.306163 },
    { lat: 48.830947, lng: 21.305961 },
    { lat: 48.830638, lng: 21.305403 },
    { lat: 48.830507, lng: 21.305195 },
    { lat: 48.830104, lng: 21.304351 },
    { lat: 48.829756, lng: 21.303997 },
    { lat: 48.829372, lng: 21.30327 },
    { lat: 48.829358, lng: 21.303144 },
    { lat: 48.829204, lng: 21.303211 },
    { lat: 48.829116, lng: 21.303265 },
    { lat: 48.828996, lng: 21.303383 },
    { lat: 48.828798, lng: 21.303601 },
    { lat: 48.828705, lng: 21.303662 },
    { lat: 48.828653, lng: 21.303666 },
    { lat: 48.82857, lng: 21.303658 },
    { lat: 48.828499, lng: 21.303632 },
    { lat: 48.828314, lng: 21.303519 },
    { lat: 48.828348, lng: 21.30341 },
    { lat: 48.828224, lng: 21.303176 },
    { lat: 48.828174, lng: 21.303024 },
    { lat: 48.828155, lng: 21.302883 },
    { lat: 48.828157, lng: 21.302487 },
    { lat: 48.828686, lng: 21.301569 },
    { lat: 48.828703, lng: 21.301448 },
    { lat: 48.828689, lng: 21.301397 },
    { lat: 48.828624, lng: 21.30132 },
    { lat: 48.828577, lng: 21.301307 },
    { lat: 48.828214, lng: 21.301204 },
    { lat: 48.828099, lng: 21.30123 },
    { lat: 48.827775, lng: 21.30152 },
    { lat: 48.82788, lng: 21.302237 },
    { lat: 48.827905, lng: 21.302584 },
    { lat: 48.827931, lng: 21.302973 },
    { lat: 48.827947, lng: 21.303204 },
    { lat: 48.827963, lng: 21.30344 },
    { lat: 48.827776, lng: 21.304121 },
    { lat: 48.827575, lng: 21.304571 },
    { lat: 48.827504, lng: 21.304673 },
    { lat: 48.827431, lng: 21.304763 },
    { lat: 48.827318, lng: 21.304989 },
    { lat: 48.827235, lng: 21.305104 },
    { lat: 48.827223, lng: 21.30627 },
    { lat: 48.827222, lng: 21.306324 },
  ],
  Skároš: [
    { lat: 48.599167, lng: 21.4537612 },
    { lat: 48.6078153, lng: 21.4587061 },
    { lat: 48.6140949, lng: 21.4625036 },
    { lat: 48.6165908, lng: 21.4474913 },
    { lat: 48.6179012, lng: 21.439303 },
    { lat: 48.6183291, lng: 21.4366341 },
    { lat: 48.618914, lng: 21.4328357 },
    { lat: 48.6193727, lng: 21.4306458 },
    { lat: 48.6193414, lng: 21.4295816 },
    { lat: 48.6198912, lng: 21.4260486 },
    { lat: 48.6204529, lng: 21.4244915 },
    { lat: 48.6209283, lng: 21.423363 },
    { lat: 48.6222996, lng: 21.4223604 },
    { lat: 48.623818, lng: 21.421611 },
    { lat: 48.6247622, lng: 21.4203596 },
    { lat: 48.6273827, lng: 21.4183216 },
    { lat: 48.6280429, lng: 21.416907 },
    { lat: 48.6284573, lng: 21.416232 },
    { lat: 48.6284709, lng: 21.4160077 },
    { lat: 48.6285603, lng: 21.4157918 },
    { lat: 48.6286432, lng: 21.4153579 },
    { lat: 48.6289221, lng: 21.4146323 },
    { lat: 48.6291795, lng: 21.4145083 },
    { lat: 48.6297003, lng: 21.4140424 },
    { lat: 48.6297797, lng: 21.4138724 },
    { lat: 48.6299158, lng: 21.4137931 },
    { lat: 48.6299475, lng: 21.4136533 },
    { lat: 48.6302222, lng: 21.4134303 },
    { lat: 48.6303031, lng: 21.4131587 },
    { lat: 48.6306428, lng: 21.4130757 },
    { lat: 48.6306498, lng: 21.4130101 },
    { lat: 48.6310796, lng: 21.4130086 },
    { lat: 48.6315453, lng: 21.4128491 },
    { lat: 48.6319627, lng: 21.4128477 },
    { lat: 48.6322435, lng: 21.4126399 },
    { lat: 48.6324241, lng: 21.412617 },
    { lat: 48.6326717, lng: 21.412431 },
    { lat: 48.633002, lng: 21.4123337 },
    { lat: 48.6330179, lng: 21.4121882 },
    { lat: 48.6331586, lng: 21.4119994 },
    { lat: 48.6335588, lng: 21.4119468 },
    { lat: 48.6339241, lng: 21.4116308 },
    { lat: 48.634051, lng: 21.4118468 },
    { lat: 48.634223, lng: 21.4118807 },
    { lat: 48.6342669, lng: 21.4116924 },
    { lat: 48.634512, lng: 21.4114951 },
    { lat: 48.6345437, lng: 21.4115375 },
    { lat: 48.6348663, lng: 21.4113731 },
    { lat: 48.6351415, lng: 21.4113168 },
    { lat: 48.6351471, lng: 21.4113154 },
    { lat: 48.6351382, lng: 21.4112857 },
    { lat: 48.634724, lng: 21.4099112 },
    { lat: 48.633744, lng: 21.4084692 },
    { lat: 48.6334489, lng: 21.4075129 },
    { lat: 48.6324054, lng: 21.407352 },
    { lat: 48.6301529, lng: 21.4065282 },
    { lat: 48.6295522, lng: 21.4058491 },
    { lat: 48.6264669, lng: 21.403965 },
    { lat: 48.6244975, lng: 21.4020701 },
    { lat: 48.6225835, lng: 21.4006327 },
    { lat: 48.621899, lng: 21.4006195 },
    { lat: 48.621567, lng: 21.4006989 },
    { lat: 48.6205693, lng: 21.4005568 },
    { lat: 48.6204113, lng: 21.4006578 },
    { lat: 48.6200528, lng: 21.4005206 },
    { lat: 48.619575, lng: 21.4001556 },
    { lat: 48.6189055, lng: 21.3990581 },
    { lat: 48.6186157, lng: 21.3989333 },
    { lat: 48.6175563, lng: 21.3988709 },
    { lat: 48.6171685, lng: 21.3990799 },
    { lat: 48.6165455, lng: 21.398977 },
    { lat: 48.6159664, lng: 21.3993359 },
    { lat: 48.6154085, lng: 21.3994956 },
    { lat: 48.6142422, lng: 21.3973652 },
    { lat: 48.6139247, lng: 21.3970503 },
    { lat: 48.6134873, lng: 21.3968377 },
    { lat: 48.612143, lng: 21.3966972 },
    { lat: 48.611435, lng: 21.3962828 },
    { lat: 48.6106197, lng: 21.3964148 },
    { lat: 48.6094652, lng: 21.3946004 },
    { lat: 48.6091703, lng: 21.3935731 },
    { lat: 48.6087186, lng: 21.3931418 },
    { lat: 48.6078348, lng: 21.392525 },
    { lat: 48.6072201, lng: 21.3916489 },
    { lat: 48.6070342, lng: 21.3914865 },
    { lat: 48.6064922, lng: 21.3903687 },
    { lat: 48.6052654, lng: 21.389382 },
    { lat: 48.6048082, lng: 21.389259 },
    { lat: 48.6049043, lng: 21.3893499 },
    { lat: 48.6046945, lng: 21.3901997 },
    { lat: 48.6047218, lng: 21.3906644 },
    { lat: 48.6043739, lng: 21.3912629 },
    { lat: 48.6044189, lng: 21.3915308 },
    { lat: 48.6042873, lng: 21.3918288 },
    { lat: 48.6042745, lng: 21.3926837 },
    { lat: 48.6044581, lng: 21.3929962 },
    { lat: 48.6037232, lng: 21.3927299 },
    { lat: 48.60289, lng: 21.3921101 },
    { lat: 48.6019838, lng: 21.3918757 },
    { lat: 48.6010006, lng: 21.3917283 },
    { lat: 48.6008502, lng: 21.3916272 },
    { lat: 48.6003794, lng: 21.3910532 },
    { lat: 48.6001454, lng: 21.3905987 },
    { lat: 48.6000946, lng: 21.3896479 },
    { lat: 48.5999961, lng: 21.389666 },
    { lat: 48.5999742, lng: 21.3893503 },
    { lat: 48.6001562, lng: 21.3869609 },
    { lat: 48.6004494, lng: 21.3868692 },
    { lat: 48.600763, lng: 21.3856519 },
    { lat: 48.600728, lng: 21.3852681 },
    { lat: 48.6008209, lng: 21.3847637 },
    { lat: 48.6007822, lng: 21.3842185 },
    { lat: 48.6008212, lng: 21.383983 },
    { lat: 48.6007798, lng: 21.3836871 },
    { lat: 48.6006368, lng: 21.3837238 },
    { lat: 48.6006553, lng: 21.3830225 },
    { lat: 48.6007025, lng: 21.3827968 },
    { lat: 48.6006659, lng: 21.3821006 },
    { lat: 48.6005995, lng: 21.3817229 },
    { lat: 48.6005431, lng: 21.3817164 },
    { lat: 48.6001509, lng: 21.3795512 },
    { lat: 48.6001494, lng: 21.3779571 },
    { lat: 48.6002436, lng: 21.3761421 },
    { lat: 48.5992415, lng: 21.3762771 },
    { lat: 48.5984092, lng: 21.3764785 },
    { lat: 48.5982383, lng: 21.3740008 },
    { lat: 48.5981269, lng: 21.3740069 },
    { lat: 48.5980859, lng: 21.3736119 },
    { lat: 48.5980479, lng: 21.3711823 },
    { lat: 48.5968472, lng: 21.3711451 },
    { lat: 48.596863, lng: 21.3708834 },
    { lat: 48.5969599, lng: 21.3706959 },
    { lat: 48.5969859, lng: 21.3670657 },
    { lat: 48.5964475, lng: 21.3669403 },
    { lat: 48.5966728, lng: 21.3641303 },
    { lat: 48.5944411, lng: 21.3631198 },
    { lat: 48.5949254, lng: 21.3615585 },
    { lat: 48.5948398, lng: 21.361479 },
    { lat: 48.5946419, lng: 21.3610791 },
    { lat: 48.5940354, lng: 21.3604732 },
    { lat: 48.5938975, lng: 21.3602714 },
    { lat: 48.5938491, lng: 21.3597845 },
    { lat: 48.593399, lng: 21.3586962 },
    { lat: 48.5931604, lng: 21.3582906 },
    { lat: 48.5929401, lng: 21.3580696 },
    { lat: 48.5921235, lng: 21.3576029 },
    { lat: 48.5924623, lng: 21.3557756 },
    { lat: 48.5908099, lng: 21.3557206 },
    { lat: 48.5881791, lng: 21.3549846 },
    { lat: 48.5881383, lng: 21.3553816 },
    { lat: 48.5874977, lng: 21.3552282 },
    { lat: 48.5875, lng: 21.3553066 },
    { lat: 48.5873036, lng: 21.3553243 },
    { lat: 48.5873341, lng: 21.355554 },
    { lat: 48.5871176, lng: 21.3556445 },
    { lat: 48.5867505, lng: 21.3557876 },
    { lat: 48.5857021, lng: 21.3561036 },
    { lat: 48.5843563, lng: 21.3563304 },
    { lat: 48.5839976, lng: 21.3557422 },
    { lat: 48.5837562, lng: 21.3550503 },
    { lat: 48.583665, lng: 21.3549346 },
    { lat: 48.5833037, lng: 21.3547856 },
    { lat: 48.5827182, lng: 21.3542534 },
    { lat: 48.5825001, lng: 21.3543011 },
    { lat: 48.5816515, lng: 21.3542589 },
    { lat: 48.5810423, lng: 21.3541308 },
    { lat: 48.5806412, lng: 21.3541945 },
    { lat: 48.5795628, lng: 21.3539197 },
    { lat: 48.5790087, lng: 21.3536488 },
    { lat: 48.5789635, lng: 21.3538612 },
    { lat: 48.5789303, lng: 21.3546297 },
    { lat: 48.5783615, lng: 21.3546462 },
    { lat: 48.5784187, lng: 21.356812 },
    { lat: 48.5783062, lng: 21.3568502 },
    { lat: 48.5784068, lng: 21.3586949 },
    { lat: 48.5786645, lng: 21.3617244 },
    { lat: 48.5788571, lng: 21.3633794 },
    { lat: 48.5781568, lng: 21.3637041 },
    { lat: 48.5772955, lng: 21.3642896 },
    { lat: 48.5774703, lng: 21.3657247 },
    { lat: 48.5765627, lng: 21.3653684 },
    { lat: 48.5764054, lng: 21.3654132 },
    { lat: 48.5764449, lng: 21.3660552 },
    { lat: 48.5757881, lng: 21.366289 },
    { lat: 48.5740765, lng: 21.3672682 },
    { lat: 48.5739717, lng: 21.3673756 },
    { lat: 48.5739873, lng: 21.3674871 },
    { lat: 48.5742253, lng: 21.3679116 },
    { lat: 48.5742764, lng: 21.3678949 },
    { lat: 48.5742787, lng: 21.3680422 },
    { lat: 48.5743824, lng: 21.3681574 },
    { lat: 48.5744824, lng: 21.3681197 },
    { lat: 48.5746439, lng: 21.3686975 },
    { lat: 48.5746981, lng: 21.3687038 },
    { lat: 48.5747222, lng: 21.3690216 },
    { lat: 48.5748364, lng: 21.369189 },
    { lat: 48.5749968, lng: 21.3696599 },
    { lat: 48.5751394, lng: 21.3697206 },
    { lat: 48.5753482, lng: 21.36997 },
    { lat: 48.5755963, lng: 21.3703216 },
    { lat: 48.5756425, lng: 21.3704802 },
    { lat: 48.5761653, lng: 21.3709097 },
    { lat: 48.5765478, lng: 21.3715088 },
    { lat: 48.5712125, lng: 21.3817621 },
    { lat: 48.5646477, lng: 21.3934248 },
    { lat: 48.5641238, lng: 21.3942034 },
    { lat: 48.563476, lng: 21.395499 },
    { lat: 48.5634948, lng: 21.3956476 },
    { lat: 48.563507, lng: 21.395776 },
    { lat: 48.563519, lng: 21.396243 },
    { lat: 48.563456, lng: 21.397073 },
    { lat: 48.563417, lng: 21.39796 },
    { lat: 48.563459, lng: 21.398757 },
    { lat: 48.563669, lng: 21.399701 },
    { lat: 48.563752, lng: 21.399914 },
    { lat: 48.563852, lng: 21.400192 },
    { lat: 48.563845, lng: 21.400417 },
    { lat: 48.563656, lng: 21.400739 },
    { lat: 48.563536, lng: 21.401412 },
    { lat: 48.56356, lng: 21.401909 },
    { lat: 48.563365, lng: 21.402315 },
    { lat: 48.563196, lng: 21.403003 },
    { lat: 48.56318, lng: 21.403416 },
    { lat: 48.563138, lng: 21.403741 },
    { lat: 48.563107, lng: 21.40428 },
    { lat: 48.562664, lng: 21.404934 },
    { lat: 48.562292, lng: 21.405261 },
    { lat: 48.561964, lng: 21.405142 },
    { lat: 48.561604, lng: 21.405411 },
    { lat: 48.56129, lng: 21.405571 },
    { lat: 48.561055, lng: 21.405789 },
    { lat: 48.560567, lng: 21.405826 },
    { lat: 48.560251, lng: 21.406187 },
    { lat: 48.559985, lng: 21.406189 },
    { lat: 48.55965, lng: 21.406774 },
    { lat: 48.559462, lng: 21.407356 },
    { lat: 48.559386, lng: 21.407871 },
    { lat: 48.559327, lng: 21.408789 },
    { lat: 48.559111, lng: 21.409935 },
    { lat: 48.558933, lng: 21.410463 },
    { lat: 48.559137, lng: 21.411631 },
    { lat: 48.559229, lng: 21.41226 },
    { lat: 48.5591, lng: 21.412742 },
    { lat: 48.558924, lng: 21.413462 },
    { lat: 48.558838, lng: 21.414118 },
    { lat: 48.558868, lng: 21.414666 },
    { lat: 48.558951, lng: 21.41545 },
    { lat: 48.559109, lng: 21.415985 },
    { lat: 48.559293, lng: 21.416553 },
    { lat: 48.559701, lng: 21.416981 },
    { lat: 48.559886, lng: 21.417265 },
    { lat: 48.560141, lng: 21.4177 },
    { lat: 48.560324, lng: 21.417818 },
    { lat: 48.560588, lng: 21.41793 },
    { lat: 48.56101, lng: 21.418432 },
    { lat: 48.561323, lng: 21.419119 },
    { lat: 48.561511, lng: 21.419684 },
    { lat: 48.5616294, lng: 21.42 },
    { lat: 48.561704, lng: 21.420199 },
    { lat: 48.562217, lng: 21.421015 },
    { lat: 48.562828, lng: 21.421397 },
    { lat: 48.563479, lng: 21.421758 },
    { lat: 48.563862, lng: 21.421771 },
    { lat: 48.56448, lng: 21.421617 },
    { lat: 48.565314, lng: 21.421757 },
    { lat: 48.56595, lng: 21.421437 },
    { lat: 48.566638, lng: 21.421438 },
    { lat: 48.567429, lng: 21.421483 },
    { lat: 48.567914, lng: 21.421518 },
    { lat: 48.568355, lng: 21.421535 },
    { lat: 48.569068, lng: 21.421479 },
    { lat: 48.5697884, lng: 21.421419 },
    { lat: 48.569873, lng: 21.421412 },
    { lat: 48.570316, lng: 21.42137 },
    { lat: 48.570953, lng: 21.421313 },
    { lat: 48.571228, lng: 21.421349 },
    { lat: 48.571429, lng: 21.421387 },
    { lat: 48.571693, lng: 21.421444 },
    { lat: 48.572024, lng: 21.421324 },
    { lat: 48.572471, lng: 21.421227 },
    { lat: 48.57292, lng: 21.4214 },
    { lat: 48.573061, lng: 21.421418 },
    { lat: 48.573602, lng: 21.421318 },
    { lat: 48.573913, lng: 21.421347 },
    { lat: 48.574176, lng: 21.421269 },
    { lat: 48.574324, lng: 21.42122 },
    { lat: 48.57491, lng: 21.421241 },
    { lat: 48.575225, lng: 21.421376 },
    { lat: 48.575328, lng: 21.42142 },
    { lat: 48.575732, lng: 21.421607 },
    { lat: 48.576135, lng: 21.421938 },
    { lat: 48.576678, lng: 21.422154 },
    { lat: 48.576876, lng: 21.4222 },
    { lat: 48.57713, lng: 21.422298 },
    { lat: 48.577569, lng: 21.422616 },
    { lat: 48.577834, lng: 21.422671 },
    { lat: 48.57802, lng: 21.422702 },
    { lat: 48.578603, lng: 21.422674 },
    { lat: 48.578821, lng: 21.422665 },
    { lat: 48.579117, lng: 21.423651 },
    { lat: 48.579392, lng: 21.424616 },
    { lat: 48.579539, lng: 21.425161 },
    { lat: 48.579593, lng: 21.425368 },
    { lat: 48.579685, lng: 21.425641 },
    { lat: 48.57997, lng: 21.426504 },
    { lat: 48.580518, lng: 21.426677 },
    { lat: 48.581161, lng: 21.426873 },
    { lat: 48.581829, lng: 21.426862 },
    { lat: 48.582454, lng: 21.427272 },
    { lat: 48.582808, lng: 21.427772 },
    { lat: 48.583243, lng: 21.428403 },
    { lat: 48.58346, lng: 21.428725 },
    { lat: 48.583549, lng: 21.428858 },
    { lat: 48.583534, lng: 21.428905 },
    { lat: 48.583533, lng: 21.429055 },
    { lat: 48.583511, lng: 21.4292 },
    { lat: 48.583387, lng: 21.429406 },
    { lat: 48.583381, lng: 21.429555 },
    { lat: 48.583415, lng: 21.429665 },
    { lat: 48.583384, lng: 21.429763 },
    { lat: 48.583359, lng: 21.430062 },
    { lat: 48.583442, lng: 21.430201 },
    { lat: 48.583468, lng: 21.430363 },
    { lat: 48.583404, lng: 21.430488 },
    { lat: 48.583382, lng: 21.430569 },
    { lat: 48.583404, lng: 21.430674 },
    { lat: 48.583509, lng: 21.430836 },
    { lat: 48.583466, lng: 21.430965 },
    { lat: 48.583467, lng: 21.431051 },
    { lat: 48.583515, lng: 21.431154 },
    { lat: 48.583513, lng: 21.431476 },
    { lat: 48.583471, lng: 21.431615 },
    { lat: 48.583521, lng: 21.431972 },
    { lat: 48.583569, lng: 21.432048 },
    { lat: 48.583584, lng: 21.432147 },
    { lat: 48.583538, lng: 21.432263 },
    { lat: 48.583566, lng: 21.432531 },
    { lat: 48.583546, lng: 21.432919 },
    { lat: 48.583564, lng: 21.433181 },
    { lat: 48.583641, lng: 21.433386 },
    { lat: 48.583671, lng: 21.433734 },
    { lat: 48.583713, lng: 21.43419 },
    { lat: 48.583789, lng: 21.434385 },
    { lat: 48.583779, lng: 21.434546 },
    { lat: 48.583812, lng: 21.4347 },
    { lat: 48.583794, lng: 21.434821 },
    { lat: 48.58382, lng: 21.434925 },
    { lat: 48.583819, lng: 21.435012 },
    { lat: 48.583872, lng: 21.435133 },
    { lat: 48.583917, lng: 21.435298 },
    { lat: 48.583956, lng: 21.435452 },
    { lat: 48.583968, lng: 21.435605 },
    { lat: 48.584076, lng: 21.435741 },
    { lat: 48.584103, lng: 21.435906 },
    { lat: 48.584197, lng: 21.436182 },
    { lat: 48.584334, lng: 21.436476 },
    { lat: 48.584302, lng: 21.436865 },
    { lat: 48.584382, lng: 21.437134 },
    { lat: 48.584495, lng: 21.437409 },
    { lat: 48.584666, lng: 21.437665 },
    { lat: 48.584791, lng: 21.437859 },
    { lat: 48.584888, lng: 21.438302 },
    { lat: 48.584908, lng: 21.438356 },
    { lat: 48.585054, lng: 21.438604 },
    { lat: 48.58515, lng: 21.438933 },
    { lat: 48.585166, lng: 21.439019 },
    { lat: 48.585257, lng: 21.439482 },
    { lat: 48.585237, lng: 21.440068 },
    { lat: 48.585104, lng: 21.44061 },
    { lat: 48.584898, lng: 21.441013 },
    { lat: 48.584488, lng: 21.441656 },
    { lat: 48.58429, lng: 21.441963 },
    { lat: 48.58414, lng: 21.44203 },
    { lat: 48.583729, lng: 21.442221 },
    { lat: 48.583321, lng: 21.442373 },
    { lat: 48.583211, lng: 21.442357 },
    { lat: 48.582921, lng: 21.442548 },
    { lat: 48.582525, lng: 21.442805 },
    { lat: 48.58214, lng: 21.443448 },
    { lat: 48.581578, lng: 21.44398 },
    { lat: 48.58125, lng: 21.444438 },
    { lat: 48.581088, lng: 21.444841 },
    { lat: 48.580982, lng: 21.445123 },
    { lat: 48.580895, lng: 21.445765 },
    { lat: 48.580701, lng: 21.446278 },
    { lat: 48.580347, lng: 21.446259 },
    { lat: 48.579934, lng: 21.446571 },
    { lat: 48.579459, lng: 21.447037 },
    { lat: 48.579235, lng: 21.447169 },
    { lat: 48.57874, lng: 21.44794 },
    { lat: 48.578256, lng: 21.447868 },
    { lat: 48.577734, lng: 21.44771 },
    { lat: 48.577008, lng: 21.447987 },
    { lat: 48.576748, lng: 21.448082 },
    { lat: 48.576381, lng: 21.448921 },
    { lat: 48.576134, lng: 21.449841 },
    { lat: 48.575992, lng: 21.450179 },
    { lat: 48.575838, lng: 21.450297 },
    { lat: 48.575363, lng: 21.450615 },
    { lat: 48.575023, lng: 21.450704 },
    { lat: 48.574907, lng: 21.450738 },
    { lat: 48.574837, lng: 21.45094 },
    { lat: 48.574922, lng: 21.451462 },
    { lat: 48.5747441, lng: 21.4528518 },
    { lat: 48.574487, lng: 21.453431 },
    { lat: 48.574598, lng: 21.453819 },
    { lat: 48.574605, lng: 21.453971 },
    { lat: 48.574563, lng: 21.454503 },
    { lat: 48.57485, lng: 21.455388 },
    { lat: 48.57523, lng: 21.455748 },
    { lat: 48.575617, lng: 21.456541 },
    { lat: 48.576065, lng: 21.457219 },
    { lat: 48.576425, lng: 21.457751 },
    { lat: 48.576771, lng: 21.4581222 },
    { lat: 48.5762093, lng: 21.458195 },
    { lat: 48.5758114, lng: 21.4580969 },
    { lat: 48.5756693, lng: 21.4580463 },
    { lat: 48.575577, lng: 21.4580778 },
    { lat: 48.5754369, lng: 21.4581758 },
    { lat: 48.5752028, lng: 21.4584758 },
    { lat: 48.5750661, lng: 21.4587841 },
    { lat: 48.574638, lng: 21.459479 },
    { lat: 48.574327, lng: 21.460007 },
    { lat: 48.57397, lng: 21.46063 },
    { lat: 48.573692, lng: 21.460435 },
    { lat: 48.5733084, lng: 21.4607785 },
    { lat: 48.5733306, lng: 21.4609335 },
    { lat: 48.5735658, lng: 21.4615372 },
    { lat: 48.5734099, lng: 21.4621659 },
    { lat: 48.5732932, lng: 21.4626518 },
    { lat: 48.573205, lng: 21.4629919 },
    { lat: 48.5727673, lng: 21.4638847 },
    { lat: 48.572282, lng: 21.464497 },
    { lat: 48.5719758, lng: 21.4653076 },
    { lat: 48.5716055, lng: 21.465669 },
    { lat: 48.571122, lng: 21.4659054 },
    { lat: 48.5708113, lng: 21.4665362 },
    { lat: 48.570607, lng: 21.466827 },
    { lat: 48.5701871, lng: 21.4671712 },
    { lat: 48.5699092, lng: 21.4678407 },
    { lat: 48.56957, lng: 21.468291 },
    { lat: 48.5693797, lng: 21.4685293 },
    { lat: 48.5692431, lng: 21.4689854 },
    { lat: 48.5688419, lng: 21.4693498 },
    { lat: 48.568411, lng: 21.4697105 },
    { lat: 48.5682038, lng: 21.4698479 },
    { lat: 48.5682282, lng: 21.4698526 },
    { lat: 48.5679725, lng: 21.4699784 },
    { lat: 48.567581, lng: 21.4701849 },
    { lat: 48.5675311, lng: 21.4702117 },
    { lat: 48.5673738, lng: 21.47032 },
    { lat: 48.5671541, lng: 21.4706498 },
    { lat: 48.566793, lng: 21.470564 },
    { lat: 48.5665147, lng: 21.4708143 },
    { lat: 48.5661728, lng: 21.4711707 },
    { lat: 48.5658755, lng: 21.4719105 },
    { lat: 48.5656, lng: 21.472556 },
    { lat: 48.5654864, lng: 21.4730195 },
    { lat: 48.56531, lng: 21.473329 },
    { lat: 48.5650264, lng: 21.4740515 },
    { lat: 48.564829, lng: 21.474384 },
    { lat: 48.5645853, lng: 21.4749484 },
    { lat: 48.5643421, lng: 21.4756784 },
    { lat: 48.5642771, lng: 21.4764249 },
    { lat: 48.5641936, lng: 21.4769925 },
    { lat: 48.5641567, lng: 21.4777015 },
    { lat: 48.5645282, lng: 21.4784056 },
    { lat: 48.5649434, lng: 21.4787867 },
    { lat: 48.5651746, lng: 21.4791167 },
    { lat: 48.5650866, lng: 21.4799146 },
    { lat: 48.5648111, lng: 21.4810411 },
    { lat: 48.5647028, lng: 21.4814647 },
    { lat: 48.5642627, lng: 21.4818254 },
    { lat: 48.563572, lng: 21.4820491 },
    { lat: 48.563379, lng: 21.482336 },
    { lat: 48.563078, lng: 21.483113 },
    { lat: 48.5630361, lng: 21.4833403 },
    { lat: 48.562854, lng: 21.4837047 },
    { lat: 48.5626921, lng: 21.4844546 },
    { lat: 48.5626126, lng: 21.4847076 },
    { lat: 48.5623036, lng: 21.4848566 },
    { lat: 48.5619532, lng: 21.4849658 },
    { lat: 48.561454, lng: 21.485076 },
    { lat: 48.560973, lng: 21.485271 },
    { lat: 48.560452, lng: 21.485514 },
    { lat: 48.559971, lng: 21.485686 },
    { lat: 48.559667, lng: 21.486205 },
    { lat: 48.559361, lng: 21.486816 },
    { lat: 48.558972, lng: 21.487497 },
    { lat: 48.558655, lng: 21.488129 },
    { lat: 48.565757, lng: 21.4897725 },
    { lat: 48.5669652, lng: 21.4901461 },
    { lat: 48.5673269, lng: 21.4901997 },
    { lat: 48.5676086, lng: 21.4901411 },
    { lat: 48.568349, lng: 21.4895953 },
    { lat: 48.5706824, lng: 21.4874059 },
    { lat: 48.5714217, lng: 21.4865113 },
    { lat: 48.5727686, lng: 21.4851889 },
    { lat: 48.5733976, lng: 21.4843775 },
    { lat: 48.5740276, lng: 21.483792 },
    { lat: 48.5755464, lng: 21.4819152 },
    { lat: 48.5758759, lng: 21.4814081 },
    { lat: 48.5775907, lng: 21.4783406 },
    { lat: 48.5780322, lng: 21.4776633 },
    { lat: 48.5787192, lng: 21.4764019 },
    { lat: 48.5835138, lng: 21.4672343 },
    { lat: 48.5961565, lng: 21.4520943 },
    { lat: 48.5968773, lng: 21.4524239 },
    { lat: 48.5969536, lng: 21.452534 },
    { lat: 48.5991206, lng: 21.4537148 },
    { lat: 48.599167, lng: 21.4537612 },
  ],
  SlanskáHuta: [
    { lat: 48.589002, lng: 21.539948 },
    { lat: 48.589094, lng: 21.539952 },
    { lat: 48.589139, lng: 21.539737 },
    { lat: 48.589159, lng: 21.539487 },
    { lat: 48.589136, lng: 21.539144 },
    { lat: 48.589001, lng: 21.539182 },
    { lat: 48.589072, lng: 21.538766 },
    { lat: 48.58913, lng: 21.538554 },
    { lat: 48.589208, lng: 21.538338 },
    { lat: 48.58906, lng: 21.538207 },
    { lat: 48.588979, lng: 21.538003 },
    { lat: 48.589025, lng: 21.537667 },
    { lat: 48.588976, lng: 21.53751 },
    { lat: 48.588769, lng: 21.537425 },
    { lat: 48.588651, lng: 21.537245 },
    { lat: 48.588559, lng: 21.536926 },
    { lat: 48.588538, lng: 21.536656 },
    { lat: 48.588469, lng: 21.536625 },
    { lat: 48.588335, lng: 21.536458 },
    { lat: 48.588381, lng: 21.536131 },
    { lat: 48.588353, lng: 21.535873 },
    { lat: 48.588295, lng: 21.535612 },
    { lat: 48.588328, lng: 21.535418 },
    { lat: 48.588311, lng: 21.535211 },
    { lat: 48.588491, lng: 21.535028 },
    { lat: 48.588447, lng: 21.534831 },
    { lat: 48.588496, lng: 21.534622 },
    { lat: 48.588617, lng: 21.534458 },
    { lat: 48.58873, lng: 21.534181 },
    { lat: 48.58872, lng: 21.533919 },
    { lat: 48.588833, lng: 21.533711 },
    { lat: 48.588889, lng: 21.533485 },
    { lat: 48.589007, lng: 21.533249 },
    { lat: 48.588975, lng: 21.53282 },
    { lat: 48.589161, lng: 21.532398 },
    { lat: 48.589294, lng: 21.532359 },
    { lat: 48.589389, lng: 21.532077 },
    { lat: 48.589601, lng: 21.532027 },
    { lat: 48.58967, lng: 21.531843 },
    { lat: 48.589853, lng: 21.531734 },
    { lat: 48.590032, lng: 21.531312 },
    { lat: 48.590089, lng: 21.530918 },
    { lat: 48.590185, lng: 21.530742 },
    { lat: 48.590392, lng: 21.530642 },
    { lat: 48.590372, lng: 21.530435 },
    { lat: 48.590509, lng: 21.530215 },
    { lat: 48.590475, lng: 21.529974 },
    { lat: 48.590445, lng: 21.529771 },
    { lat: 48.590258, lng: 21.529588 },
    { lat: 48.590263, lng: 21.529425 },
    { lat: 48.590156, lng: 21.529248 },
    { lat: 48.590027, lng: 21.52882 },
    { lat: 48.590037, lng: 21.528708 },
    { lat: 48.590052, lng: 21.528606 },
    { lat: 48.590185, lng: 21.528526 },
    { lat: 48.590204, lng: 21.528381 },
    { lat: 48.590307, lng: 21.528142 },
    { lat: 48.59035, lng: 21.528115 },
    { lat: 48.590611, lng: 21.527826 },
    { lat: 48.59065, lng: 21.527661 },
    { lat: 48.590526, lng: 21.527522 },
    { lat: 48.590553, lng: 21.527191 },
    { lat: 48.590603, lng: 21.52701 },
    { lat: 48.590497, lng: 21.52695 },
    { lat: 48.590678, lng: 21.526733 },
    { lat: 48.590815, lng: 21.526414 },
    { lat: 48.590804, lng: 21.526018 },
    { lat: 48.590941, lng: 21.525965 },
    { lat: 48.590961, lng: 21.525719 },
    { lat: 48.59094, lng: 21.525462 },
    { lat: 48.591183, lng: 21.52532 },
    { lat: 48.59104, lng: 21.525088 },
    { lat: 48.591038, lng: 21.524887 },
    { lat: 48.591095, lng: 21.524595 },
    { lat: 48.59098, lng: 21.524332 },
    { lat: 48.591001, lng: 21.524177 },
    { lat: 48.590914, lng: 21.52404 },
    { lat: 48.590842, lng: 21.523892 },
    { lat: 48.59104, lng: 21.523736 },
    { lat: 48.59094, lng: 21.523456 },
    { lat: 48.590959, lng: 21.522842 },
    { lat: 48.590938, lng: 21.522487 },
    { lat: 48.590991, lng: 21.522264 },
    { lat: 48.590981, lng: 21.521969 },
    { lat: 48.590944, lng: 21.521763 },
    { lat: 48.590979, lng: 21.52152 },
    { lat: 48.59091, lng: 21.521188 },
    { lat: 48.591078, lng: 21.520731 },
    { lat: 48.591044, lng: 21.520429 },
    { lat: 48.591109, lng: 21.520134 },
    { lat: 48.591123, lng: 21.520086 },
    { lat: 48.59121, lng: 21.519778 },
    { lat: 48.591316, lng: 21.519709 },
    { lat: 48.591422, lng: 21.519199 },
    { lat: 48.591531, lng: 21.518883 },
    { lat: 48.591551, lng: 21.518404 },
    { lat: 48.591703, lng: 21.518212 },
    { lat: 48.591801, lng: 21.517906 },
    { lat: 48.591791, lng: 21.517868 },
    { lat: 48.591869, lng: 21.517719 },
    { lat: 48.592034, lng: 21.517813 },
    { lat: 48.592099, lng: 21.517553 },
    { lat: 48.592206, lng: 21.517331 },
    { lat: 48.592412, lng: 21.517069 },
    { lat: 48.592421, lng: 21.516754 },
    { lat: 48.592559, lng: 21.516643 },
    { lat: 48.592546, lng: 21.516473 },
    { lat: 48.592789, lng: 21.516118 },
    { lat: 48.592757, lng: 21.516018 },
    { lat: 48.592842, lng: 21.51587 },
    { lat: 48.592851, lng: 21.51579 },
    { lat: 48.592886, lng: 21.515754 },
    { lat: 48.593258, lng: 21.515434 },
    { lat: 48.593334, lng: 21.515375 },
    { lat: 48.594053, lng: 21.514985 },
    { lat: 48.594109, lng: 21.514955 },
    { lat: 48.594744, lng: 21.514739 },
    { lat: 48.59509, lng: 21.514503 },
    { lat: 48.595991, lng: 21.514467 },
    { lat: 48.596072, lng: 21.514477 },
    { lat: 48.595768, lng: 21.5141522 },
    { lat: 48.5940182, lng: 21.5131365 },
    { lat: 48.5934372, lng: 21.5129651 },
    { lat: 48.592769, lng: 21.5125402 },
    { lat: 48.5916619, lng: 21.5123897 },
    { lat: 48.589895, lng: 21.5114438 },
    { lat: 48.5892756, lng: 21.5096276 },
    { lat: 48.5885846, lng: 21.5090003 },
    { lat: 48.5881624, lng: 21.5090706 },
    { lat: 48.5877085, lng: 21.5092513 },
    { lat: 48.5862682, lng: 21.5060459 },
    { lat: 48.5856954, lng: 21.5041738 },
    { lat: 48.5856149, lng: 21.5025513 },
    { lat: 48.5849045, lng: 21.4992592 },
    { lat: 48.5844315, lng: 21.4985499 },
    { lat: 48.5841697, lng: 21.4977074 },
    { lat: 48.5839993, lng: 21.4960518 },
    { lat: 48.5840817, lng: 21.4952069 },
    { lat: 48.5839777, lng: 21.4942961 },
    { lat: 48.5836389, lng: 21.492927 },
    { lat: 48.5832346, lng: 21.492058 },
    { lat: 48.5831713, lng: 21.4913126 },
    { lat: 48.583365, lng: 21.4907398 },
    { lat: 48.5837397, lng: 21.4891006 },
    { lat: 48.5836346, lng: 21.4871418 },
    { lat: 48.5832675, lng: 21.4860141 },
    { lat: 48.5869953, lng: 21.4832849 },
    { lat: 48.5889968, lng: 21.4816104 },
    { lat: 48.5932245, lng: 21.4774634 },
    { lat: 48.5956328, lng: 21.474571 },
    { lat: 48.5962967, lng: 21.4736743 },
    { lat: 48.5990169, lng: 21.4695909 },
    { lat: 48.5990626, lng: 21.4694198 },
    { lat: 48.5992323, lng: 21.4693776 },
    { lat: 48.5995832, lng: 21.469103 },
    { lat: 48.6002133, lng: 21.4701392 },
    { lat: 48.6001379, lng: 21.4704485 },
    { lat: 48.6008205, lng: 21.470782 },
    { lat: 48.6011268, lng: 21.4707169 },
    { lat: 48.6013637, lng: 21.4704602 },
    { lat: 48.6013739, lng: 21.4706988 },
    { lat: 48.6014651, lng: 21.4708168 },
    { lat: 48.6015887, lng: 21.4708122 },
    { lat: 48.6016683, lng: 21.4708819 },
    { lat: 48.601639, lng: 21.4710311 },
    { lat: 48.6019358, lng: 21.4713282 },
    { lat: 48.6022798, lng: 21.4708913 },
    { lat: 48.6024093, lng: 21.471016 },
    { lat: 48.6029558, lng: 21.4707267 },
    { lat: 48.6035071, lng: 21.4701836 },
    { lat: 48.6037424, lng: 21.4701506 },
    { lat: 48.6047285, lng: 21.4696234 },
    { lat: 48.6048628, lng: 21.4698656 },
    { lat: 48.6052568, lng: 21.4696357 },
    { lat: 48.6057172, lng: 21.4689933 },
    { lat: 48.6059596, lng: 21.4690832 },
    { lat: 48.6060232, lng: 21.468828 },
    { lat: 48.6061415, lng: 21.4688013 },
    { lat: 48.6063926, lng: 21.4682997 },
    { lat: 48.6067505, lng: 21.4679578 },
    { lat: 48.6069024, lng: 21.4676846 },
    { lat: 48.6072688, lng: 21.4675522 },
    { lat: 48.6074833, lng: 21.4677695 },
    { lat: 48.6077862, lng: 21.4683144 },
    { lat: 48.6081374, lng: 21.4685127 },
    { lat: 48.609004, lng: 21.4698321 },
    { lat: 48.6090711, lng: 21.4700901 },
    { lat: 48.6096263, lng: 21.4703356 },
    { lat: 48.6093771, lng: 21.4695848 },
    { lat: 48.6086147, lng: 21.4678257 },
    { lat: 48.6079687, lng: 21.4674415 },
    { lat: 48.6074943, lng: 21.4665282 },
    { lat: 48.6073641, lng: 21.4659583 },
    { lat: 48.6078744, lng: 21.4657431 },
    { lat: 48.6079751, lng: 21.4649915 },
    { lat: 48.6080169, lng: 21.4649614 },
    { lat: 48.6077105, lng: 21.4642524 },
    { lat: 48.6074845, lng: 21.4634912 },
    { lat: 48.6074208, lng: 21.4634254 },
    { lat: 48.607161, lng: 21.462739 },
    { lat: 48.6069544, lng: 21.462406 },
    { lat: 48.6069112, lng: 21.462235 },
    { lat: 48.6069577, lng: 21.4620204 },
    { lat: 48.606924, lng: 21.4617524 },
    { lat: 48.6066557, lng: 21.4614633 },
    { lat: 48.6064765, lng: 21.4606084 },
    { lat: 48.6060202, lng: 21.4604683 },
    { lat: 48.6059707, lng: 21.4603966 },
    { lat: 48.6059535, lng: 21.4601161 },
    { lat: 48.6057726, lng: 21.4598871 },
    { lat: 48.6053379, lng: 21.4598744 },
    { lat: 48.6054794, lng: 21.4597146 },
    { lat: 48.6051009, lng: 21.4592789 },
    { lat: 48.6046687, lng: 21.4590043 },
    { lat: 48.6046128, lng: 21.458827 },
    { lat: 48.6044758, lng: 21.4587601 },
    { lat: 48.6043081, lng: 21.4589863 },
    { lat: 48.6041487, lng: 21.4590012 },
    { lat: 48.6040248, lng: 21.4588469 },
    { lat: 48.6038965, lng: 21.4588451 },
    { lat: 48.6038565, lng: 21.4587845 },
    { lat: 48.6028915, lng: 21.4586829 },
    { lat: 48.6021134, lng: 21.4588956 },
    { lat: 48.6021137, lng: 21.4588323 },
    { lat: 48.6019669, lng: 21.4587721 },
    { lat: 48.6017499, lng: 21.4589379 },
    { lat: 48.6015161, lng: 21.4588695 },
    { lat: 48.6013589, lng: 21.4589536 },
    { lat: 48.6003155, lng: 21.457678 },
    { lat: 48.6003109, lng: 21.4574415 },
    { lat: 48.600136, lng: 21.4572334 },
    { lat: 48.5999437, lng: 21.4571253 },
    { lat: 48.5999129, lng: 21.4569514 },
    { lat: 48.5997176, lng: 21.4566248 },
    { lat: 48.5995614, lng: 21.4565161 },
    { lat: 48.5993746, lng: 21.457097 },
    { lat: 48.5987959, lng: 21.456466 },
    { lat: 48.5986629, lng: 21.4560383 },
    { lat: 48.599184, lng: 21.4563855 },
    { lat: 48.5992826, lng: 21.4559836 },
    { lat: 48.5989178, lng: 21.4554404 },
    { lat: 48.5989344, lng: 21.4551212 },
    { lat: 48.599167, lng: 21.4537612 },
    { lat: 48.5991206, lng: 21.4537148 },
    { lat: 48.5969536, lng: 21.452534 },
    { lat: 48.5968773, lng: 21.4524239 },
    { lat: 48.5961565, lng: 21.4520943 },
    { lat: 48.5835138, lng: 21.4672343 },
    { lat: 48.5787192, lng: 21.4764019 },
    { lat: 48.5780322, lng: 21.4776633 },
    { lat: 48.5775907, lng: 21.4783406 },
    { lat: 48.5758759, lng: 21.4814081 },
    { lat: 48.5755464, lng: 21.4819152 },
    { lat: 48.5740276, lng: 21.483792 },
    { lat: 48.5733976, lng: 21.4843775 },
    { lat: 48.5727686, lng: 21.4851889 },
    { lat: 48.5714217, lng: 21.4865113 },
    { lat: 48.5706824, lng: 21.4874059 },
    { lat: 48.568349, lng: 21.4895953 },
    { lat: 48.5676086, lng: 21.4901411 },
    { lat: 48.5673269, lng: 21.4901997 },
    { lat: 48.5669652, lng: 21.4901461 },
    { lat: 48.565757, lng: 21.4897725 },
    { lat: 48.558655, lng: 21.488129 },
    { lat: 48.5585718, lng: 21.4882131 },
    { lat: 48.558376, lng: 21.488411 },
    { lat: 48.558606, lng: 21.488887 },
    { lat: 48.558667, lng: 21.489095 },
    { lat: 48.558806, lng: 21.489555 },
    { lat: 48.559098, lng: 21.490168 },
    { lat: 48.559421, lng: 21.490593 },
    { lat: 48.559398, lng: 21.491079 },
    { lat: 48.559342, lng: 21.491797 },
    { lat: 48.559233, lng: 21.492436 },
    { lat: 48.559144, lng: 21.493192 },
    { lat: 48.559071, lng: 21.493824 },
    { lat: 48.5589, lng: 21.494863 },
    { lat: 48.558815, lng: 21.495755 },
    { lat: 48.558678, lng: 21.497046 },
    { lat: 48.558671, lng: 21.497098 },
    { lat: 48.558843, lng: 21.497125 },
    { lat: 48.559243, lng: 21.496978 },
    { lat: 48.559515, lng: 21.49701 },
    { lat: 48.559809, lng: 21.497117 },
    { lat: 48.560263, lng: 21.49727 },
    { lat: 48.560538, lng: 21.497234 },
    { lat: 48.560541, lng: 21.497268 },
    { lat: 48.560554, lng: 21.497838 },
    { lat: 48.560313, lng: 21.49812 },
    { lat: 48.560358, lng: 21.498522 },
    { lat: 48.560366, lng: 21.499243 },
    { lat: 48.560152, lng: 21.499615 },
    { lat: 48.559956, lng: 21.499675 },
    { lat: 48.559792, lng: 21.499411 },
    { lat: 48.559638, lng: 21.499163 },
    { lat: 48.55936, lng: 21.499296 },
    { lat: 48.559064, lng: 21.499433 },
    { lat: 48.558503, lng: 21.499695 },
    { lat: 48.557905, lng: 21.499754 },
    { lat: 48.557535, lng: 21.499516 },
    { lat: 48.55701, lng: 21.499556 },
    { lat: 48.5567, lng: 21.499595 },
    { lat: 48.556444, lng: 21.499835 },
    { lat: 48.555948, lng: 21.500299 },
    { lat: 48.55572, lng: 21.500455 },
    { lat: 48.555557, lng: 21.500434 },
    { lat: 48.555247, lng: 21.500225 },
    { lat: 48.554935, lng: 21.500617 },
    { lat: 48.554904, lng: 21.500809 },
    { lat: 48.554894, lng: 21.500872 },
    { lat: 48.554812, lng: 21.501368 },
    { lat: 48.554963, lng: 21.501694 },
    { lat: 48.55492, lng: 21.502446 },
    { lat: 48.554958, lng: 21.502677 },
    { lat: 48.554923, lng: 21.503007 },
    { lat: 48.554872, lng: 21.503094 },
    { lat: 48.554803, lng: 21.503187 },
    { lat: 48.554714, lng: 21.503517 },
    { lat: 48.554927, lng: 21.504002 },
    { lat: 48.554934, lng: 21.50449 },
    { lat: 48.554878, lng: 21.504657 },
    { lat: 48.554936, lng: 21.505068 },
    { lat: 48.554875, lng: 21.505154 },
    { lat: 48.55502, lng: 21.505739 },
    { lat: 48.555267, lng: 21.506153 },
    { lat: 48.555343, lng: 21.506607 },
    { lat: 48.555513, lng: 21.506828 },
    { lat: 48.555539, lng: 21.507257 },
    { lat: 48.555375, lng: 21.507397 },
    { lat: 48.555223, lng: 21.507672 },
    { lat: 48.55534, lng: 21.50795 },
    { lat: 48.554972, lng: 21.508265 },
    { lat: 48.554711, lng: 21.508714 },
    { lat: 48.554281, lng: 21.508395 },
    { lat: 48.553824, lng: 21.508103 },
    { lat: 48.553768, lng: 21.507933 },
    { lat: 48.553608, lng: 21.507619 },
    { lat: 48.553421, lng: 21.507564 },
    { lat: 48.553273, lng: 21.507314 },
    { lat: 48.553081, lng: 21.507114 },
    { lat: 48.552919, lng: 21.506668 },
    { lat: 48.552723, lng: 21.506485 },
    { lat: 48.552629, lng: 21.506809 },
    { lat: 48.552609, lng: 21.506877 },
    { lat: 48.552309, lng: 21.507907 },
    { lat: 48.55212, lng: 21.508539 },
    { lat: 48.552264, lng: 21.508859 },
    { lat: 48.552559, lng: 21.509524 },
    { lat: 48.552656, lng: 21.509879 },
    { lat: 48.552786, lng: 21.51031 },
    { lat: 48.552843, lng: 21.51059 },
    { lat: 48.552555, lng: 21.510621 },
    { lat: 48.552082, lng: 21.510865 },
    { lat: 48.551801, lng: 21.511353 },
    { lat: 48.55146, lng: 21.511595 },
    { lat: 48.551126, lng: 21.511836 },
    { lat: 48.551182, lng: 21.512479 },
    { lat: 48.551224, lng: 21.512972 },
    { lat: 48.551202, lng: 21.513232 },
    { lat: 48.551141, lng: 21.513912 },
    { lat: 48.551065, lng: 21.514091 },
    { lat: 48.550667, lng: 21.514159 },
    { lat: 48.550497, lng: 21.51429 },
    { lat: 48.550231, lng: 21.514655 },
    { lat: 48.550059, lng: 21.515194 },
    { lat: 48.549907, lng: 21.515244 },
    { lat: 48.549817, lng: 21.515493 },
    { lat: 48.549611, lng: 21.515617 },
    { lat: 48.549429, lng: 21.515965 },
    { lat: 48.549139, lng: 21.516181 },
    { lat: 48.548746, lng: 21.516474 },
    { lat: 48.548241, lng: 21.516852 },
    { lat: 48.547908, lng: 21.517305 },
    { lat: 48.547379, lng: 21.51789 },
    { lat: 48.547128, lng: 21.518161 },
    { lat: 48.546913, lng: 21.518374 },
    { lat: 48.546312, lng: 21.518117 },
    { lat: 48.54569, lng: 21.517917 },
    { lat: 48.545194, lng: 21.517968 },
    { lat: 48.544574, lng: 21.51751 },
    { lat: 48.544098, lng: 21.517136 },
    { lat: 48.544331, lng: 21.517778 },
    { lat: 48.544464, lng: 21.519548 },
    { lat: 48.544628, lng: 21.519646 },
    { lat: 48.544802, lng: 21.519595 },
    { lat: 48.545045, lng: 21.519666 },
    { lat: 48.545327, lng: 21.520191 },
    { lat: 48.545725, lng: 21.52082 },
    { lat: 48.545888, lng: 21.520924 },
    { lat: 48.545942, lng: 21.520939 },
    { lat: 48.546065, lng: 21.520973 },
    { lat: 48.546287, lng: 21.520923 },
    { lat: 48.546639, lng: 21.521462 },
    { lat: 48.547037, lng: 21.521805 },
    { lat: 48.547167, lng: 21.521804 },
    { lat: 48.547186, lng: 21.521734 },
    { lat: 48.548406, lng: 21.522497 },
    { lat: 48.548877, lng: 21.523245 },
    { lat: 48.549011, lng: 21.523328 },
    { lat: 48.549046, lng: 21.52335 },
    { lat: 48.549428, lng: 21.523586 },
    { lat: 48.550042, lng: 21.524011 },
    { lat: 48.550411, lng: 21.524236 },
    { lat: 48.550466, lng: 21.523735 },
    { lat: 48.55059, lng: 21.523278 },
    { lat: 48.550681, lng: 21.522469 },
    { lat: 48.550856, lng: 21.521843 },
    { lat: 48.550949, lng: 21.521513 },
    { lat: 48.551305, lng: 21.521133 },
    { lat: 48.551465, lng: 21.521064 },
    { lat: 48.551888, lng: 21.52085 },
    { lat: 48.552144, lng: 21.520144 },
    { lat: 48.552276, lng: 21.519615 },
    { lat: 48.552778, lng: 21.519595 },
    { lat: 48.552821, lng: 21.519274 },
    { lat: 48.55314, lng: 21.51872 },
    { lat: 48.553246, lng: 21.518335 },
    { lat: 48.553824, lng: 21.517944 },
    { lat: 48.554031, lng: 21.517897 },
    { lat: 48.554346, lng: 21.517697 },
    { lat: 48.555172, lng: 21.517509 },
    { lat: 48.555764, lng: 21.516932 },
    { lat: 48.556004, lng: 21.516303 },
    { lat: 48.556032, lng: 21.516226 },
    { lat: 48.556055, lng: 21.516167 },
    { lat: 48.556656, lng: 21.515352 },
    { lat: 48.557104, lng: 21.515071 },
    { lat: 48.55732, lng: 21.514937 },
    { lat: 48.557789, lng: 21.514878 },
    { lat: 48.558275, lng: 21.514555 },
    { lat: 48.558584, lng: 21.514335 },
    { lat: 48.559082, lng: 21.513833 },
    { lat: 48.55978, lng: 21.513616 },
    { lat: 48.560278, lng: 21.513522 },
    { lat: 48.56033, lng: 21.513519 },
    { lat: 48.560345, lng: 21.513514 },
    { lat: 48.560351, lng: 21.514125 },
    { lat: 48.56058, lng: 21.514459 },
    { lat: 48.560755, lng: 21.515015 },
    { lat: 48.560771, lng: 21.51497 },
    { lat: 48.560813, lng: 21.514879 },
    { lat: 48.561131, lng: 21.51485 },
    { lat: 48.561278, lng: 21.514631 },
    { lat: 48.561465, lng: 21.514458 },
    { lat: 48.561909, lng: 21.514299 },
    { lat: 48.561963, lng: 21.5144 },
    { lat: 48.561965, lng: 21.51446 },
    { lat: 48.562069, lng: 21.514571 },
    { lat: 48.562181, lng: 21.514497 },
    { lat: 48.562171, lng: 21.514281 },
    { lat: 48.56219, lng: 21.514148 },
    { lat: 48.562284, lng: 21.514073 },
    { lat: 48.562378, lng: 21.513732 },
    { lat: 48.562285, lng: 21.513601 },
    { lat: 48.562359, lng: 21.513451 },
    { lat: 48.562428, lng: 21.513178 },
    { lat: 48.562553, lng: 21.513111 },
    { lat: 48.562632, lng: 21.512935 },
    { lat: 48.562843, lng: 21.512584 },
    { lat: 48.562924, lng: 21.512321 },
    { lat: 48.562935, lng: 21.512125 },
    { lat: 48.562937, lng: 21.512125 },
    { lat: 48.56295, lng: 21.512133 },
    { lat: 48.563031, lng: 21.512159 },
    { lat: 48.563219, lng: 21.512244 },
    { lat: 48.563821, lng: 21.512393 },
    { lat: 48.563847, lng: 21.512433 },
    { lat: 48.564078, lng: 21.513117 },
    { lat: 48.564442, lng: 21.513594 },
    { lat: 48.565148, lng: 21.514474 },
    { lat: 48.565137, lng: 21.51449 },
    { lat: 48.565423, lng: 21.514973 },
    { lat: 48.565737, lng: 21.515384 },
    { lat: 48.56739, lng: 21.516606 },
    { lat: 48.56779, lng: 21.517158 },
    { lat: 48.567779, lng: 21.517174 },
    { lat: 48.567791, lng: 21.51719 },
    { lat: 48.568067, lng: 21.517632 },
    { lat: 48.568363, lng: 21.517959 },
    { lat: 48.568506, lng: 21.518144 },
    { lat: 48.568885, lng: 21.518513 },
    { lat: 48.569142, lng: 21.518984 },
    { lat: 48.569559, lng: 21.519997 },
    { lat: 48.569941, lng: 21.520597 },
    { lat: 48.570122, lng: 21.520771 },
    { lat: 48.570386, lng: 21.521141 },
    { lat: 48.57104, lng: 21.52232 },
    { lat: 48.571731, lng: 21.523224 },
    { lat: 48.572112, lng: 21.523634 },
    { lat: 48.572968, lng: 21.524434 },
    { lat: 48.573419, lng: 21.525063 },
    { lat: 48.573697, lng: 21.525371 },
    { lat: 48.573876, lng: 21.525651 },
    { lat: 48.574094, lng: 21.525851 },
    { lat: 48.574289, lng: 21.526014 },
    { lat: 48.574807, lng: 21.526321 },
    { lat: 48.575446, lng: 21.526844 },
    { lat: 48.575554, lng: 21.526863 },
    { lat: 48.575643, lng: 21.527243 },
    { lat: 48.576303, lng: 21.527639 },
    { lat: 48.576561, lng: 21.527657 },
    { lat: 48.576845, lng: 21.527734 },
    { lat: 48.576926, lng: 21.527843 },
    { lat: 48.576988, lng: 21.52805 },
    { lat: 48.577551, lng: 21.528472 },
    { lat: 48.577604, lng: 21.528655 },
    { lat: 48.577684, lng: 21.528938 },
    { lat: 48.577945, lng: 21.529673 },
    { lat: 48.578188, lng: 21.530226 },
    { lat: 48.57856, lng: 21.53031 },
    { lat: 48.578781, lng: 21.530508 },
    { lat: 48.579806, lng: 21.531058 },
    { lat: 48.580043, lng: 21.531081 },
    { lat: 48.580687, lng: 21.531452 },
    { lat: 48.581044, lng: 21.531793 },
    { lat: 48.581468, lng: 21.53236 },
    { lat: 48.582167, lng: 21.532898 },
    { lat: 48.582511, lng: 21.533322 },
    { lat: 48.582537, lng: 21.533356 },
    { lat: 48.582782, lng: 21.533657 },
    { lat: 48.583145, lng: 21.534023 },
    { lat: 48.583164, lng: 21.534057 },
    { lat: 48.583656, lng: 21.534888 },
    { lat: 48.58381, lng: 21.53512 },
    { lat: 48.583914, lng: 21.535383 },
    { lat: 48.584249, lng: 21.53553 },
    { lat: 48.584626, lng: 21.535499 },
    { lat: 48.584934, lng: 21.53555 },
    { lat: 48.585003, lng: 21.535662 },
    { lat: 48.585136, lng: 21.535771 },
    { lat: 48.585808, lng: 21.536386 },
    { lat: 48.585891, lng: 21.536281 },
    { lat: 48.5861, lng: 21.536455 },
    { lat: 48.58631, lng: 21.536671 },
    { lat: 48.586421, lng: 21.536689 },
    { lat: 48.586584, lng: 21.536787 },
    { lat: 48.586666, lng: 21.536994 },
    { lat: 48.5867, lng: 21.537182 },
    { lat: 48.586827, lng: 21.537437 },
    { lat: 48.586936, lng: 21.537612 },
    { lat: 48.587029, lng: 21.537729 },
    { lat: 48.587181, lng: 21.5381 },
    { lat: 48.587339, lng: 21.538364 },
    { lat: 48.58747, lng: 21.538517 },
    { lat: 48.587704, lng: 21.538819 },
    { lat: 48.587839, lng: 21.539031 },
    { lat: 48.588006, lng: 21.539342 },
    { lat: 48.588148, lng: 21.539349 },
    { lat: 48.588235, lng: 21.539373 },
    { lat: 48.588307, lng: 21.539551 },
    { lat: 48.5884, lng: 21.539528 },
    { lat: 48.588464, lng: 21.539647 },
    { lat: 48.588627, lng: 21.539741 },
    { lat: 48.588911, lng: 21.539949 },
    { lat: 48.58895, lng: 21.539945 },
    { lat: 48.589002, lng: 21.539948 },
  ],
  KecerovskýLipovec: [
    { lat: 48.8688883, lng: 21.484229 },
    { lat: 48.8687704, lng: 21.4840858 },
    { lat: 48.8686901, lng: 21.4834102 },
    { lat: 48.8688086, lng: 21.4819792 },
    { lat: 48.8692059, lng: 21.4799203 },
    { lat: 48.8694191, lng: 21.4791531 },
    { lat: 48.869867, lng: 21.4765886 },
    { lat: 48.8700508, lng: 21.4758998 },
    { lat: 48.8698328, lng: 21.4757963 },
    { lat: 48.8697599, lng: 21.475299 },
    { lat: 48.870074, lng: 21.4740183 },
    { lat: 48.8700948, lng: 21.4735326 },
    { lat: 48.8699816, lng: 21.4735072 },
    { lat: 48.8697603, lng: 21.4735984 },
    { lat: 48.8690421, lng: 21.4736633 },
    { lat: 48.8687533, lng: 21.4737547 },
    { lat: 48.8683706, lng: 21.4736708 },
    { lat: 48.8680815, lng: 21.4732865 },
    { lat: 48.8679846, lng: 21.4729305 },
    { lat: 48.8679207, lng: 21.4724737 },
    { lat: 48.8679517, lng: 21.4720682 },
    { lat: 48.867838, lng: 21.4716368 },
    { lat: 48.8678454, lng: 21.4714291 },
    { lat: 48.8676257, lng: 21.4709894 },
    { lat: 48.8674708, lng: 21.4702639 },
    { lat: 48.8674547, lng: 21.4697499 },
    { lat: 48.8672549, lng: 21.4679798 },
    { lat: 48.8674734, lng: 21.4672054 },
    { lat: 48.8678325, lng: 21.4664742 },
    { lat: 48.8679526, lng: 21.4660788 },
    { lat: 48.867888, lng: 21.4656692 },
    { lat: 48.8679446, lng: 21.465411 },
    { lat: 48.8682239, lng: 21.4648073 },
    { lat: 48.8681944, lng: 21.4646763 },
    { lat: 48.8682397, lng: 21.4645304 },
    { lat: 48.8682669, lng: 21.464036 },
    { lat: 48.8680944, lng: 21.4637137 },
    { lat: 48.8681137, lng: 21.4628697 },
    { lat: 48.8679746, lng: 21.4618354 },
    { lat: 48.867922, lng: 21.4612581 },
    { lat: 48.8679498, lng: 21.460941 },
    { lat: 48.8678319, lng: 21.4602119 },
    { lat: 48.8678297, lng: 21.4597131 },
    { lat: 48.8677269, lng: 21.4589166 },
    { lat: 48.8676026, lng: 21.4586321 },
    { lat: 48.8673472, lng: 21.4577053 },
    { lat: 48.8670394, lng: 21.457491 },
    { lat: 48.866878, lng: 21.4571627 },
    { lat: 48.8664713, lng: 21.4568721 },
    { lat: 48.8663333, lng: 21.4565765 },
    { lat: 48.8659757, lng: 21.456273 },
    { lat: 48.865803, lng: 21.4554407 },
    { lat: 48.8653255, lng: 21.4550831 },
    { lat: 48.8652543, lng: 21.4548413 },
    { lat: 48.8652902, lng: 21.4545998 },
    { lat: 48.8650398, lng: 21.4537749 },
    { lat: 48.8648877, lng: 21.4536521 },
    { lat: 48.8647623, lng: 21.4532084 },
    { lat: 48.8644831, lng: 21.4529715 },
    { lat: 48.8642719, lng: 21.4523245 },
    { lat: 48.8642013, lng: 21.4518675 },
    { lat: 48.8639932, lng: 21.4516759 },
    { lat: 48.8637919, lng: 21.4511373 },
    { lat: 48.8638095, lng: 21.4509724 },
    { lat: 48.8637413, lng: 21.4507904 },
    { lat: 48.8637893, lng: 21.4506671 },
    { lat: 48.8637343, lng: 21.4505189 },
    { lat: 48.8636519, lng: 21.4503926 },
    { lat: 48.8633584, lng: 21.4502417 },
    { lat: 48.8632766, lng: 21.450065 },
    { lat: 48.8632715, lng: 21.4497272 },
    { lat: 48.863204, lng: 21.449521 },
    { lat: 48.8631505, lng: 21.4492085 },
    { lat: 48.8632203, lng: 21.4482595 },
    { lat: 48.8634219, lng: 21.4478667 },
    { lat: 48.8634575, lng: 21.4472335 },
    { lat: 48.8632146, lng: 21.4467741 },
    { lat: 48.8629773, lng: 21.4466196 },
    { lat: 48.862775, lng: 21.4460874 },
    { lat: 48.8626172, lng: 21.4459247 },
    { lat: 48.8625729, lng: 21.445755 },
    { lat: 48.8624743, lng: 21.4457356 },
    { lat: 48.8622578, lng: 21.4448288 },
    { lat: 48.8621415, lng: 21.4447394 },
    { lat: 48.8619196, lng: 21.444884 },
    { lat: 48.8618165, lng: 21.4448648 },
    { lat: 48.8616999, lng: 21.4446435 },
    { lat: 48.8615515, lng: 21.444493 },
    { lat: 48.8610797, lng: 21.444445 },
    { lat: 48.8608886, lng: 21.444258 },
    { lat: 48.8607487, lng: 21.4434694 },
    { lat: 48.8606321, lng: 21.4434894 },
    { lat: 48.8604641, lng: 21.4433259 },
    { lat: 48.8603122, lng: 21.4430885 },
    { lat: 48.8602371, lng: 21.4428337 },
    { lat: 48.8600768, lng: 21.4427791 },
    { lat: 48.8599812, lng: 21.4426218 },
    { lat: 48.8599753, lng: 21.4425021 },
    { lat: 48.8598787, lng: 21.4424311 },
    { lat: 48.8597618, lng: 21.4425458 },
    { lat: 48.8596437, lng: 21.4423911 },
    { lat: 48.8596474, lng: 21.4422132 },
    { lat: 48.859522, lng: 21.4419636 },
    { lat: 48.8590139, lng: 21.4416343 },
    { lat: 48.8585186, lng: 21.4409747 },
    { lat: 48.8582565, lng: 21.4408965 },
    { lat: 48.857926, lng: 21.440641 },
    { lat: 48.8576446, lng: 21.4402518 },
    { lat: 48.8575064, lng: 21.4396054 },
    { lat: 48.8573132, lng: 21.4393814 },
    { lat: 48.8570979, lng: 21.4391404 },
    { lat: 48.8566278, lng: 21.439183 },
    { lat: 48.8563372, lng: 21.4389056 },
    { lat: 48.8562285, lng: 21.4387275 },
    { lat: 48.8560899, lng: 21.4386081 },
    { lat: 48.8559583, lng: 21.4386048 },
    { lat: 48.8559634, lng: 21.4382389 },
    { lat: 48.8558919, lng: 21.4379579 },
    { lat: 48.8557376, lng: 21.4377585 },
    { lat: 48.855655, lng: 21.4377826 },
    { lat: 48.8554906, lng: 21.437699 },
    { lat: 48.8553547, lng: 21.4373236 },
    { lat: 48.8550188, lng: 21.4369567 },
    { lat: 48.8549076, lng: 21.4363868 },
    { lat: 48.854808, lng: 21.4364013 },
    { lat: 48.8546531, lng: 21.4362227 },
    { lat: 48.8544504, lng: 21.4361381 },
    { lat: 48.8543239, lng: 21.435934 },
    { lat: 48.8541575, lng: 21.4354049 },
    { lat: 48.8539667, lng: 21.4351833 },
    { lat: 48.8539655, lng: 21.4347979 },
    { lat: 48.8537279, lng: 21.434374 },
    { lat: 48.853636, lng: 21.4340772 },
    { lat: 48.8534297, lng: 21.4341018 },
    { lat: 48.852808, lng: 21.4322359 },
    { lat: 48.8528145, lng: 21.4320379 },
    { lat: 48.8514704, lng: 21.4303608 },
    { lat: 48.851265, lng: 21.4280114 },
    { lat: 48.8505659, lng: 21.4281983 },
    { lat: 48.8505331, lng: 21.4265259 },
    { lat: 48.8505779, lng: 21.4264443 },
    { lat: 48.8499883, lng: 21.4258316 },
    { lat: 48.8477276, lng: 21.4239543 },
    { lat: 48.8465752, lng: 21.4230409 },
    { lat: 48.8465823, lng: 21.4217368 },
    { lat: 48.8470016, lng: 21.4216421 },
    { lat: 48.8469779, lng: 21.4198296 },
    { lat: 48.8466289, lng: 21.4196166 },
    { lat: 48.8463575, lng: 21.4190644 },
    { lat: 48.8462113, lng: 21.4185351 },
    { lat: 48.8460388, lng: 21.4184074 },
    { lat: 48.8458279, lng: 21.4182758 },
    { lat: 48.8454105, lng: 21.418206 },
    { lat: 48.8451462, lng: 21.4183148 },
    { lat: 48.8448195, lng: 21.4184548 },
    { lat: 48.8445551, lng: 21.4186538 },
    { lat: 48.844401, lng: 21.4187681 },
    { lat: 48.8440243, lng: 21.4190371 },
    { lat: 48.8437291, lng: 21.4190617 },
    { lat: 48.8432193, lng: 21.4189825 },
    { lat: 48.8426304, lng: 21.4193849 },
    { lat: 48.8423977, lng: 21.4197003 },
    { lat: 48.8422519, lng: 21.4200259 },
    { lat: 48.8421767, lng: 21.4203789 },
    { lat: 48.8421553, lng: 21.4212159 },
    { lat: 48.842058, lng: 21.4218502 },
    { lat: 48.8417223, lng: 21.4225655 },
    { lat: 48.8413418, lng: 21.4227472 },
    { lat: 48.8402261, lng: 21.422805 },
    { lat: 48.8399304, lng: 21.4232375 },
    { lat: 48.8398459, lng: 21.4232675 },
    { lat: 48.8396138, lng: 21.4232213 },
    { lat: 48.839447, lng: 21.4230586 },
    { lat: 48.8393094, lng: 21.4227418 },
    { lat: 48.8387936, lng: 21.4225687 },
    { lat: 48.8384995, lng: 21.421868 },
    { lat: 48.8384935, lng: 21.4209765 },
    { lat: 48.8384138, lng: 21.4205271 },
    { lat: 48.8381901, lng: 21.4203047 },
    { lat: 48.8378654, lng: 21.4203595 },
    { lat: 48.8375338, lng: 21.4211727 },
    { lat: 48.8373458, lng: 21.4213972 },
    { lat: 48.8371142, lng: 21.4213703 },
    { lat: 48.8368908, lng: 21.4211175 },
    { lat: 48.8367884, lng: 21.4213484 },
    { lat: 48.8364723, lng: 21.4210068 },
    { lat: 48.836328, lng: 21.4210004 },
    { lat: 48.83596, lng: 21.4212592 },
    { lat: 48.8358485, lng: 21.4216457 },
    { lat: 48.8354963, lng: 21.4216381 },
    { lat: 48.8350875, lng: 21.4217864 },
    { lat: 48.8349627, lng: 21.4219048 },
    { lat: 48.8345906, lng: 21.4224867 },
    { lat: 48.8342419, lng: 21.4226403 },
    { lat: 48.8338267, lng: 21.4225693 },
    { lat: 48.8336632, lng: 21.4224385 },
    { lat: 48.8334701, lng: 21.4224566 },
    { lat: 48.833387, lng: 21.4225004 },
    { lat: 48.8333538, lng: 21.4225987 },
    { lat: 48.833083, lng: 21.4225712 },
    { lat: 48.8327967, lng: 21.4223991 },
    { lat: 48.8325615, lng: 21.4221317 },
    { lat: 48.8325032, lng: 21.4219128 },
    { lat: 48.8320688, lng: 21.4216764 },
    { lat: 48.8320197, lng: 21.4216518 },
    { lat: 48.8320167, lng: 21.4216692 },
    { lat: 48.8319096, lng: 21.4229193 },
    { lat: 48.8319703, lng: 21.4243767 },
    { lat: 48.8319292, lng: 21.4252391 },
    { lat: 48.8315654, lng: 21.4282934 },
    { lat: 48.8315705, lng: 21.4308063 },
    { lat: 48.8316519, lng: 21.4313141 },
    { lat: 48.8316194, lng: 21.4321695 },
    { lat: 48.8318945, lng: 21.43434 },
    { lat: 48.8329501, lng: 21.4382189 },
    { lat: 48.8332954, lng: 21.4391022 },
    { lat: 48.8333232, lng: 21.4396246 },
    { lat: 48.8334549, lng: 21.4400583 },
    { lat: 48.8339863, lng: 21.4434107 },
    { lat: 48.8339887, lng: 21.443422 },
    { lat: 48.8343848, lng: 21.4454649 },
    { lat: 48.8345813, lng: 21.4467172 },
    { lat: 48.83492, lng: 21.4482239 },
    { lat: 48.8360464, lng: 21.4517782 },
    { lat: 48.8365033, lng: 21.45414 },
    { lat: 48.837058, lng: 21.4556035 },
    { lat: 48.8380942, lng: 21.4588501 },
    { lat: 48.8384111, lng: 21.4605785 },
    { lat: 48.8383145, lng: 21.4607418 },
    { lat: 48.8383423, lng: 21.4623573 },
    { lat: 48.8381215, lng: 21.4625406 },
    { lat: 48.8381355, lng: 21.4629404 },
    { lat: 48.8379565, lng: 21.4631359 },
    { lat: 48.8378635, lng: 21.4635383 },
    { lat: 48.8376288, lng: 21.4637064 },
    { lat: 48.8374012, lng: 21.4640038 },
    { lat: 48.8373286, lng: 21.4644912 },
    { lat: 48.8373727, lng: 21.464769 },
    { lat: 48.8372523, lng: 21.4649263 },
    { lat: 48.8372114, lng: 21.4653759 },
    { lat: 48.8373476, lng: 21.4657215 },
    { lat: 48.83741, lng: 21.4661359 },
    { lat: 48.8375726, lng: 21.4665369 },
    { lat: 48.8376652, lng: 21.467293 },
    { lat: 48.837852, lng: 21.467636 },
    { lat: 48.83778, lng: 21.4686491 },
    { lat: 48.8378365, lng: 21.4688897 },
    { lat: 48.8379997, lng: 21.4691256 },
    { lat: 48.83818, lng: 21.469563 },
    { lat: 48.8384185, lng: 21.4696336 },
    { lat: 48.8384853, lng: 21.4700413 },
    { lat: 48.8384871, lng: 21.470487 },
    { lat: 48.8385635, lng: 21.4705283 },
    { lat: 48.8386907, lng: 21.4708328 },
    { lat: 48.8388255, lng: 21.4718804 },
    { lat: 48.8389388, lng: 21.4720229 },
    { lat: 48.8389449, lng: 21.4723482 },
    { lat: 48.8392456, lng: 21.4724722 },
    { lat: 48.8393486, lng: 21.4724539 },
    { lat: 48.8394442, lng: 21.4726144 },
    { lat: 48.8395146, lng: 21.4730994 },
    { lat: 48.8394083, lng: 21.4736223 },
    { lat: 48.8395094, lng: 21.4743596 },
    { lat: 48.839618, lng: 21.4746921 },
    { lat: 48.8396828, lng: 21.4754151 },
    { lat: 48.8400665, lng: 21.4758768 },
    { lat: 48.8402585, lng: 21.4763345 },
    { lat: 48.8404982, lng: 21.4766072 },
    { lat: 48.8406676, lng: 21.4771743 },
    { lat: 48.8407529, lng: 21.4781745 },
    { lat: 48.84103, lng: 21.4786519 },
    { lat: 48.841807, lng: 21.4794534 },
    { lat: 48.8420403, lng: 21.4798566 },
    { lat: 48.8422998, lng: 21.480158 },
    { lat: 48.842603, lng: 21.4807191 },
    { lat: 48.8430022, lng: 21.4811785 },
    { lat: 48.8430812, lng: 21.4814219 },
    { lat: 48.8433043, lng: 21.4817004 },
    { lat: 48.8434098, lng: 21.482082 },
    { lat: 48.8436124, lng: 21.4822808 },
    { lat: 48.843954, lng: 21.4824564 },
    { lat: 48.8442821, lng: 21.4828701 },
    { lat: 48.8442114, lng: 21.4834289 },
    { lat: 48.8442381, lng: 21.4840015 },
    { lat: 48.8443148, lng: 21.4841983 },
    { lat: 48.8454936, lng: 21.4904723 },
    { lat: 48.846566, lng: 21.495138 },
    { lat: 48.846923, lng: 21.494816 },
    { lat: 48.847232, lng: 21.494522 },
    { lat: 48.847384, lng: 21.494397 },
    { lat: 48.84768, lng: 21.494285 },
    { lat: 48.84794, lng: 21.494099 },
    { lat: 48.848318, lng: 21.493953 },
    { lat: 48.848499, lng: 21.493934 },
    { lat: 48.848631, lng: 21.493862 },
    { lat: 48.848995, lng: 21.493729 },
    { lat: 48.849605, lng: 21.493737 },
    { lat: 48.849977, lng: 21.493489 },
    { lat: 48.850329, lng: 21.493348 },
    { lat: 48.851176, lng: 21.493127 },
    { lat: 48.851849, lng: 21.493417 },
    { lat: 48.85265, lng: 21.493835 },
    { lat: 48.853292, lng: 21.494016 },
    { lat: 48.853912, lng: 21.494219 },
    { lat: 48.854252, lng: 21.494362 },
    { lat: 48.854637, lng: 21.494534 },
    { lat: 48.855028, lng: 21.494826 },
    { lat: 48.85526, lng: 21.495351 },
    { lat: 48.855596, lng: 21.495539 },
    { lat: 48.855901, lng: 21.49573 },
    { lat: 48.856096, lng: 21.495828 },
    { lat: 48.856334, lng: 21.495768 },
    { lat: 48.856487, lng: 21.4957 },
    { lat: 48.857129, lng: 21.495528 },
    { lat: 48.857485, lng: 21.495415 },
    { lat: 48.857703, lng: 21.495534 },
    { lat: 48.858348, lng: 21.496018 },
    { lat: 48.85868, lng: 21.496184 },
    { lat: 48.859007, lng: 21.496262 },
    { lat: 48.859213, lng: 21.496353 },
    { lat: 48.859547, lng: 21.496596 },
    { lat: 48.859741, lng: 21.496796 },
    { lat: 48.860525, lng: 21.497851 },
    { lat: 48.86106, lng: 21.499565 },
    { lat: 48.861309, lng: 21.500201 },
    { lat: 48.861476, lng: 21.501153 },
    { lat: 48.861667, lng: 21.501765 },
    { lat: 48.862091, lng: 21.501966 },
    { lat: 48.862516, lng: 21.501587 },
    { lat: 48.86267, lng: 21.501456 },
    { lat: 48.862839, lng: 21.501324 },
    { lat: 48.862943, lng: 21.501213 },
    { lat: 48.863071, lng: 21.501129 },
    { lat: 48.863252, lng: 21.500999 },
    { lat: 48.863469, lng: 21.500906 },
    { lat: 48.863957, lng: 21.500601 },
    { lat: 48.864331, lng: 21.500211 },
    { lat: 48.864547, lng: 21.4999 },
    { lat: 48.865027, lng: 21.499378 },
    { lat: 48.865318, lng: 21.499035 },
    { lat: 48.865764, lng: 21.498746 },
    { lat: 48.866105, lng: 21.498555 },
    { lat: 48.866444, lng: 21.498439 },
    { lat: 48.866561, lng: 21.498286 },
    { lat: 48.867083, lng: 21.497828 },
    { lat: 48.867949, lng: 21.49594 },
    { lat: 48.868017, lng: 21.495442 },
    { lat: 48.868035, lng: 21.495454 },
    { lat: 48.868054, lng: 21.495044 },
    { lat: 48.868132, lng: 21.49474 },
    { lat: 48.868203, lng: 21.494262 },
    { lat: 48.868247, lng: 21.493618 },
    { lat: 48.868248, lng: 21.493155 },
    { lat: 48.868166, lng: 21.49266 },
    { lat: 48.86815, lng: 21.492109 },
    { lat: 48.868178, lng: 21.491794 },
    { lat: 48.868062, lng: 21.491214 },
    { lat: 48.868029, lng: 21.490745 },
    { lat: 48.867973, lng: 21.490353 },
    { lat: 48.867987, lng: 21.490343 },
    { lat: 48.867974, lng: 21.490182 },
    { lat: 48.867967, lng: 21.489543 },
    { lat: 48.867941, lng: 21.489025 },
    { lat: 48.868154, lng: 21.488828 },
    { lat: 48.86821, lng: 21.48776 },
    { lat: 48.868352, lng: 21.486779 },
    { lat: 48.868475, lng: 21.485963 },
    { lat: 48.868807, lng: 21.485291 },
    { lat: 48.8688883, lng: 21.484229 },
  ],
  VyšnýMedzev: [
    { lat: 48.766155, lng: 20.931622 },
    { lat: 48.76608, lng: 20.930444 },
    { lat: 48.766026, lng: 20.928973 },
    { lat: 48.7658702, lng: 20.9279658 },
    { lat: 48.76563, lng: 20.926413 },
    { lat: 48.764386, lng: 20.923509 },
    { lat: 48.763953, lng: 20.922609 },
    { lat: 48.763715, lng: 20.920535 },
    { lat: 48.763661, lng: 20.918822 },
    { lat: 48.764236, lng: 20.917592 },
    { lat: 48.764626, lng: 20.917136 },
    { lat: 48.764684, lng: 20.917068 },
    { lat: 48.764745, lng: 20.916996 },
    { lat: 48.765111, lng: 20.915481 },
    { lat: 48.765381, lng: 20.914561 },
    { lat: 48.765787, lng: 20.913699 },
    { lat: 48.765934, lng: 20.91226 },
    { lat: 48.766098, lng: 20.911114 },
    { lat: 48.766177, lng: 20.910385 },
    { lat: 48.766439, lng: 20.909539 },
    { lat: 48.766483, lng: 20.908989 },
    { lat: 48.766602, lng: 20.908026 },
    { lat: 48.766611, lng: 20.907294 },
    { lat: 48.766833, lng: 20.906432 },
    { lat: 48.766934, lng: 20.905947 },
    { lat: 48.767287, lng: 20.904144 },
    { lat: 48.767407, lng: 20.903772 },
    { lat: 48.767838, lng: 20.903132 },
    { lat: 48.767927, lng: 20.902961 },
    { lat: 48.768298, lng: 20.902217 },
    { lat: 48.768988, lng: 20.900981 },
    { lat: 48.770204, lng: 20.899145 },
    { lat: 48.770655, lng: 20.898461 },
    { lat: 48.770878, lng: 20.897143 },
    { lat: 48.770906, lng: 20.896979 },
    { lat: 48.770454, lng: 20.89639 },
    { lat: 48.77043, lng: 20.896359 },
    { lat: 48.770232, lng: 20.89574 },
    { lat: 48.769937, lng: 20.894819 },
    { lat: 48.769202, lng: 20.893481 },
    { lat: 48.768713, lng: 20.892593 },
    { lat: 48.768498, lng: 20.891506 },
    { lat: 48.768574, lng: 20.890294 },
    { lat: 48.768582, lng: 20.890179 },
    { lat: 48.768711, lng: 20.889689 },
    { lat: 48.769044, lng: 20.888439 },
    { lat: 48.769146, lng: 20.887358 },
    { lat: 48.769318, lng: 20.885568 },
    { lat: 48.769265, lng: 20.88452 },
    { lat: 48.769292, lng: 20.883112 },
    { lat: 48.76932, lng: 20.882636 },
    { lat: 48.769352, lng: 20.882082 },
    { lat: 48.769397, lng: 20.881431 },
    { lat: 48.769441, lng: 20.880763 },
    { lat: 48.769897, lng: 20.879099 },
    { lat: 48.769934, lng: 20.878378 },
    { lat: 48.77002, lng: 20.876763 },
    { lat: 48.7709608, lng: 20.872832 },
    { lat: 48.77192, lng: 20.870278 },
    { lat: 48.77286, lng: 20.868475 },
    { lat: 48.773089, lng: 20.868022 },
    { lat: 48.7735143, lng: 20.8660196 },
    { lat: 48.774139, lng: 20.864987 },
    { lat: 48.775454, lng: 20.86439 },
    { lat: 48.775971, lng: 20.863789 },
    { lat: 48.776277, lng: 20.863149 },
    { lat: 48.776817, lng: 20.862278 },
    { lat: 48.777145, lng: 20.862108 },
    { lat: 48.778958, lng: 20.859774 },
    { lat: 48.779453, lng: 20.859598 },
    { lat: 48.777474, lng: 20.8565 },
    { lat: 48.777224, lng: 20.85607 },
    { lat: 48.776906, lng: 20.855264 },
    { lat: 48.776665, lng: 20.8548 },
    { lat: 48.776446, lng: 20.854357 },
    { lat: 48.775889, lng: 20.853752 },
    { lat: 48.775815, lng: 20.853379 },
    { lat: 48.775357, lng: 20.852687 },
    { lat: 48.774178, lng: 20.851342 },
    { lat: 48.773269, lng: 20.850304 },
    { lat: 48.772822, lng: 20.849814 },
    { lat: 48.772378, lng: 20.849003 },
    { lat: 48.771696, lng: 20.848395 },
    { lat: 48.771066, lng: 20.847603 },
    { lat: 48.770621, lng: 20.846726 },
    { lat: 48.770323, lng: 20.846137 },
    { lat: 48.769748, lng: 20.844488 },
    { lat: 48.768954, lng: 20.843055 },
    { lat: 48.768018, lng: 20.842169 },
    { lat: 48.765666, lng: 20.841995 },
    { lat: 48.764485, lng: 20.841709 },
    { lat: 48.763835, lng: 20.841196 },
    { lat: 48.762687, lng: 20.841461 },
    { lat: 48.7618472, lng: 20.8417255 },
    { lat: 48.761671, lng: 20.841781 },
    { lat: 48.760262, lng: 20.84277 },
    { lat: 48.759839, lng: 20.842971 },
    { lat: 48.758987, lng: 20.843132 },
    { lat: 48.757631, lng: 20.843536 },
    { lat: 48.756539, lng: 20.843739 },
    { lat: 48.754427, lng: 20.842549 },
    { lat: 48.7543404, lng: 20.8431661 },
    { lat: 48.7540046, lng: 20.8438126 },
    { lat: 48.7537725, lng: 20.8440667 },
    { lat: 48.7532301, lng: 20.8443998 },
    { lat: 48.7527759, lng: 20.8450338 },
    { lat: 48.7526105, lng: 20.844841 },
    { lat: 48.7525683, lng: 20.844992 },
    { lat: 48.7526316, lng: 20.8450273 },
    { lat: 48.7523762, lng: 20.8457202 },
    { lat: 48.7520404, lng: 20.8459354 },
    { lat: 48.7519189, lng: 20.8461686 },
    { lat: 48.751746, lng: 20.8467024 },
    { lat: 48.7513121, lng: 20.8473437 },
    { lat: 48.7512629, lng: 20.8472844 },
    { lat: 48.7512237, lng: 20.8474205 },
    { lat: 48.7510116, lng: 20.8476384 },
    { lat: 48.7509254, lng: 20.8471982 },
    { lat: 48.7508811, lng: 20.8476722 },
    { lat: 48.7509139, lng: 20.8479379 },
    { lat: 48.7508734, lng: 20.8481676 },
    { lat: 48.7506915, lng: 20.8483168 },
    { lat: 48.7506109, lng: 20.8486224 },
    { lat: 48.7504699, lng: 20.8488432 },
    { lat: 48.750751, lng: 20.8492916 },
    { lat: 48.7504998, lng: 20.8503954 },
    { lat: 48.7503071, lng: 20.8508832 },
    { lat: 48.7502229, lng: 20.8507896 },
    { lat: 48.7499645, lng: 20.850744 },
    { lat: 48.7498312, lng: 20.850957 },
    { lat: 48.7496604, lng: 20.8510928 },
    { lat: 48.7496868, lng: 20.8516676 },
    { lat: 48.7491831, lng: 20.8519804 },
    { lat: 48.7488899, lng: 20.8516385 },
    { lat: 48.748183, lng: 20.8517016 },
    { lat: 48.7484504, lng: 20.8526997 },
    { lat: 48.74859, lng: 20.8537492 },
    { lat: 48.7485394, lng: 20.8541149 },
    { lat: 48.7486641, lng: 20.8541693 },
    { lat: 48.7485123, lng: 20.8547954 },
    { lat: 48.748374, lng: 20.8550959 },
    { lat: 48.7482404, lng: 20.8558612 },
    { lat: 48.7480835, lng: 20.8562381 },
    { lat: 48.7481101, lng: 20.8570474 },
    { lat: 48.7480197, lng: 20.8575942 },
    { lat: 48.7479257, lng: 20.8577432 },
    { lat: 48.7479713, lng: 20.8579576 },
    { lat: 48.7479788, lng: 20.8584357 },
    { lat: 48.7478739, lng: 20.8586579 },
    { lat: 48.747548, lng: 20.8602843 },
    { lat: 48.7475953, lng: 20.8603487 },
    { lat: 48.7475762, lng: 20.8606456 },
    { lat: 48.7477274, lng: 20.8608231 },
    { lat: 48.7480012, lng: 20.8609921 },
    { lat: 48.7479822, lng: 20.8614569 },
    { lat: 48.7478814, lng: 20.8616346 },
    { lat: 48.7477901, lng: 20.8625214 },
    { lat: 48.7478195, lng: 20.8626539 },
    { lat: 48.7479102, lng: 20.8626714 },
    { lat: 48.7473338, lng: 20.8649998 },
    { lat: 48.7476454, lng: 20.8658813 },
    { lat: 48.7475812, lng: 20.8665077 },
    { lat: 48.7473237, lng: 20.867659 },
    { lat: 48.7467291, lng: 20.8692649 },
    { lat: 48.7465068, lng: 20.8695798 },
    { lat: 48.7455997, lng: 20.870306 },
    { lat: 48.745371, lng: 20.8709916 },
    { lat: 48.7456097, lng: 20.8713892 },
    { lat: 48.7447894, lng: 20.872483 },
    { lat: 48.7448461, lng: 20.8726912 },
    { lat: 48.7445868, lng: 20.8733017 },
    { lat: 48.7442051, lng: 20.873827 },
    { lat: 48.7440724, lng: 20.8738631 },
    { lat: 48.7437756, lng: 20.8736469 },
    { lat: 48.7436355, lng: 20.8734699 },
    { lat: 48.7434841, lng: 20.8737814 },
    { lat: 48.7437566, lng: 20.8740079 },
    { lat: 48.7437432, lng: 20.8741752 },
    { lat: 48.7437943, lng: 20.8743609 },
    { lat: 48.7432884, lng: 20.8749697 },
    { lat: 48.7432089, lng: 20.8751764 },
    { lat: 48.7432719, lng: 20.8753063 },
    { lat: 48.7428332, lng: 20.8762036 },
    { lat: 48.7422494, lng: 20.8771444 },
    { lat: 48.7421485, lng: 20.8771181 },
    { lat: 48.7419135, lng: 20.8773918 },
    { lat: 48.741731, lng: 20.877421 },
    { lat: 48.7415883, lng: 20.8775941 },
    { lat: 48.7411093, lng: 20.8777688 },
    { lat: 48.740702, lng: 20.8780392 },
    { lat: 48.7394304, lng: 20.8785458 },
    { lat: 48.7387313, lng: 20.8787734 },
    { lat: 48.7386976, lng: 20.878643 },
    { lat: 48.7384247, lng: 20.8785965 },
    { lat: 48.7378374, lng: 20.8786734 },
    { lat: 48.7375946, lng: 20.8788767 },
    { lat: 48.7374311, lng: 20.8788695 },
    { lat: 48.7368854, lng: 20.8791439 },
    { lat: 48.7368159, lng: 20.879282 },
    { lat: 48.7365983, lng: 20.8794381 },
    { lat: 48.7359393, lng: 20.8796536 },
    { lat: 48.734851, lng: 20.8801529 },
    { lat: 48.7343438, lng: 20.880462 },
    { lat: 48.734036, lng: 20.8804825 },
    { lat: 48.7336002, lng: 20.8806442 },
    { lat: 48.7331148, lng: 20.8810597 },
    { lat: 48.7329685, lng: 20.8811003 },
    { lat: 48.7325994, lng: 20.8809722 },
    { lat: 48.7325007, lng: 20.8808505 },
    { lat: 48.73253, lng: 20.8806182 },
    { lat: 48.7328939, lng: 20.8793735 },
    { lat: 48.7328314, lng: 20.8792614 },
    { lat: 48.7327317, lng: 20.879404 },
    { lat: 48.732321, lng: 20.8797409 },
    { lat: 48.7318383, lng: 20.8798752 },
    { lat: 48.7318812, lng: 20.8801087 },
    { lat: 48.7317357, lng: 20.8802352 },
    { lat: 48.7317377, lng: 20.8805214 },
    { lat: 48.7314257, lng: 20.880192 },
    { lat: 48.7307714, lng: 20.879752 },
    { lat: 48.730468, lng: 20.8796666 },
    { lat: 48.7300367, lng: 20.8797602 },
    { lat: 48.7296542, lng: 20.8797355 },
    { lat: 48.7294497, lng: 20.8798809 },
    { lat: 48.7294072, lng: 20.8797894 },
    { lat: 48.7292126, lng: 20.8796587 },
    { lat: 48.7289168, lng: 20.8796362 },
    { lat: 48.7287308, lng: 20.8797912 },
    { lat: 48.7286354, lng: 20.8799626 },
    { lat: 48.7282732, lng: 20.8800675 },
    { lat: 48.7278286, lng: 20.8806021 },
    { lat: 48.7267465, lng: 20.8813793 },
    { lat: 48.7264205, lng: 20.8815563 },
    { lat: 48.7258253, lng: 20.8815956 },
    { lat: 48.7254056, lng: 20.881732 },
    { lat: 48.7249017, lng: 20.8820084 },
    { lat: 48.7245321, lng: 20.8820301 },
    { lat: 48.72425, lng: 20.8823402 },
    { lat: 48.7237651, lng: 20.8825149 },
    { lat: 48.7232342, lng: 20.8825232 },
    { lat: 48.7229214, lng: 20.8825985 },
    { lat: 48.7228664, lng: 20.8827287 },
    { lat: 48.7226333, lng: 20.8827978 },
    { lat: 48.7225198, lng: 20.8827685 },
    { lat: 48.7220979, lng: 20.8829365 },
    { lat: 48.7217039, lng: 20.8829052 },
    { lat: 48.7214356, lng: 20.8827654 },
    { lat: 48.7210658, lng: 20.8826776 },
    { lat: 48.7210642, lng: 20.8825337 },
    { lat: 48.7210145, lng: 20.8825287 },
    { lat: 48.720939, lng: 20.8827553 },
    { lat: 48.720806, lng: 20.8827164 },
    { lat: 48.7203518, lng: 20.8827682 },
    { lat: 48.7201857, lng: 20.8826842 },
    { lat: 48.7201624, lng: 20.8828924 },
    { lat: 48.7200901, lng: 20.8829491 },
    { lat: 48.7200042, lng: 20.8829704 },
    { lat: 48.7199646, lng: 20.8829007 },
    { lat: 48.7194389, lng: 20.8830344 },
    { lat: 48.7182803, lng: 20.8835204 },
    { lat: 48.7176612, lng: 20.8838824 },
    { lat: 48.7176092, lng: 20.884003 },
    { lat: 48.71732, lng: 20.8842098 },
    { lat: 48.7160052, lng: 20.8845019 },
    { lat: 48.71577, lng: 20.8846368 },
    { lat: 48.7156318, lng: 20.8848119 },
    { lat: 48.715657, lng: 20.8848866 },
    { lat: 48.7150349, lng: 20.8848966 },
    { lat: 48.7145183, lng: 20.8853617 },
    { lat: 48.7137795, lng: 20.8856157 },
    { lat: 48.7137849, lng: 20.8857126 },
    { lat: 48.7133907, lng: 20.8858705 },
    { lat: 48.7130737, lng: 20.885815 },
    { lat: 48.7130487, lng: 20.8863046 },
    { lat: 48.7124609, lng: 20.8865261 },
    { lat: 48.7122836, lng: 20.8866753 },
    { lat: 48.7121935, lng: 20.8868673 },
    { lat: 48.7123502, lng: 20.8870444 },
    { lat: 48.7124606, lng: 20.8873078 },
    { lat: 48.7124173, lng: 20.8874273 },
    { lat: 48.7122339, lng: 20.8873283 },
    { lat: 48.7121591, lng: 20.8877768 },
    { lat: 48.7116458, lng: 20.8879073 },
    { lat: 48.7116549, lng: 20.8884084 },
    { lat: 48.7109927, lng: 20.8887298 },
    { lat: 48.7107218, lng: 20.8889686 },
    { lat: 48.7103107, lng: 20.8897256 },
    { lat: 48.7096762, lng: 20.8895037 },
    { lat: 48.7095929, lng: 20.8890729 },
    { lat: 48.7092296, lng: 20.888687 },
    { lat: 48.7090208, lng: 20.8887047 },
    { lat: 48.7086832, lng: 20.8890689 },
    { lat: 48.7070452, lng: 20.8932293 },
    { lat: 48.7061553, lng: 20.8950587 },
    { lat: 48.7053038, lng: 20.8966316 },
    { lat: 48.7045053, lng: 20.8990307 },
    { lat: 48.7040672, lng: 20.9005458 },
    { lat: 48.7037691, lng: 20.9016452 },
    { lat: 48.7036722, lng: 20.9021744 },
    { lat: 48.7035898, lng: 20.9031925 },
    { lat: 48.7035615, lng: 20.9047122 },
    { lat: 48.7027621, lng: 20.9082543 },
    { lat: 48.702448, lng: 20.9108106 },
    { lat: 48.7023766, lng: 20.911722 },
    { lat: 48.7019153, lng: 20.9136591 },
    { lat: 48.7017791, lng: 20.9137538 },
    { lat: 48.7012272, lng: 20.9136168 },
    { lat: 48.7005562, lng: 20.9131093 },
    { lat: 48.7004425, lng: 20.9131109 },
    { lat: 48.7002633, lng: 20.9133076 },
    { lat: 48.6998277, lng: 20.9131695 },
    { lat: 48.6995355, lng: 20.9135834 },
    { lat: 48.6994136, lng: 20.9136402 },
    { lat: 48.6987757, lng: 20.9132876 },
    { lat: 48.6983458, lng: 20.9132221 },
    { lat: 48.6981032, lng: 20.9132737 },
    { lat: 48.6979633, lng: 20.9131968 },
    { lat: 48.69786, lng: 20.9132668 },
    { lat: 48.6976956, lng: 20.9137755 },
    { lat: 48.6976674, lng: 20.9138629 },
    { lat: 48.6976113, lng: 20.9139254 },
    { lat: 48.6971121, lng: 20.9146469 },
    { lat: 48.6963325, lng: 20.9161574 },
    { lat: 48.6963206, lng: 20.9161957 },
    { lat: 48.695999, lng: 20.9178217 },
    { lat: 48.6959664, lng: 20.9179466 },
    { lat: 48.6958387, lng: 20.9192252 },
    { lat: 48.6958954, lng: 20.9200728 },
    { lat: 48.6957902, lng: 20.9209581 },
    { lat: 48.6959544, lng: 20.9235811 },
    { lat: 48.6959488, lng: 20.9264113 },
    { lat: 48.6958405, lng: 20.9275752 },
    { lat: 48.6954785, lng: 20.9288205 },
    { lat: 48.6954574, lng: 20.9290634 },
    { lat: 48.6954757, lng: 20.9296028 },
    { lat: 48.6955598, lng: 20.9298597 },
    { lat: 48.6956854, lng: 20.9316447 },
    { lat: 48.6955986, lng: 20.9320797 },
    { lat: 48.6954573, lng: 20.9324924 },
    { lat: 48.6950378, lng: 20.9330766 },
    { lat: 48.6946705, lng: 20.9339276 },
    { lat: 48.6945941, lng: 20.9342003 },
    { lat: 48.6946173, lng: 20.9346849 },
    { lat: 48.6943891, lng: 20.9350313 },
    { lat: 48.6942523, lng: 20.9351166 },
    { lat: 48.6938119, lng: 20.9356048 },
    { lat: 48.6937541, lng: 20.9360592 },
    { lat: 48.6935109, lng: 20.936493 },
    { lat: 48.6935454, lng: 20.9370208 },
    { lat: 48.6935129, lng: 20.9372521 },
    { lat: 48.6933288, lng: 20.9376688 },
    { lat: 48.6934162, lng: 20.9380234 },
    { lat: 48.6937015, lng: 20.9384119 },
    { lat: 48.6937349, lng: 20.9383673 },
    { lat: 48.6942981, lng: 20.9384786 },
    { lat: 48.6944154, lng: 20.9370794 },
    { lat: 48.6946701, lng: 20.9359521 },
    { lat: 48.6946604, lng: 20.9352655 },
    { lat: 48.6947126, lng: 20.9352458 },
    { lat: 48.6949063, lng: 20.9348843 },
    { lat: 48.6951344, lng: 20.934799 },
    { lat: 48.6953539, lng: 20.9342017 },
    { lat: 48.6955526, lng: 20.9340518 },
    { lat: 48.6959529, lng: 20.9339038 },
    { lat: 48.6965624, lng: 20.9339189 },
    { lat: 48.6967581, lng: 20.933818 },
    { lat: 48.6972235, lng: 20.9337291 },
    { lat: 48.6975175, lng: 20.9338365 },
    { lat: 48.697781, lng: 20.9337379 },
    { lat: 48.6982972, lng: 20.9338275 },
    { lat: 48.6984711, lng: 20.933673 },
    { lat: 48.6991157, lng: 20.933596 },
    { lat: 48.6994011, lng: 20.9335829 },
    { lat: 48.6997721, lng: 20.9337358 },
    { lat: 48.7004537, lng: 20.9337186 },
    { lat: 48.7005896, lng: 20.9336162 },
    { lat: 48.7009073, lng: 20.9335872 },
    { lat: 48.7009464, lng: 20.9335161 },
    { lat: 48.7010455, lng: 20.9336143 },
    { lat: 48.7015417, lng: 20.9335401 },
    { lat: 48.7016378, lng: 20.9335862 },
    { lat: 48.7019013, lng: 20.9334252 },
    { lat: 48.7028157, lng: 20.9332704 },
    { lat: 48.7029973, lng: 20.9330112 },
    { lat: 48.7032407, lng: 20.9331208 },
    { lat: 48.7036491, lng: 20.9331795 },
    { lat: 48.7038035, lng: 20.9330162 },
    { lat: 48.7044481, lng: 20.9328411 },
    { lat: 48.7047913, lng: 20.9328678 },
    { lat: 48.7052128, lng: 20.9327217 },
    { lat: 48.7053037, lng: 20.9327792 },
    { lat: 48.7058761, lng: 20.9326611 },
    { lat: 48.7059681, lng: 20.9327224 },
    { lat: 48.7061254, lng: 20.9326796 },
    { lat: 48.7063872, lng: 20.932729 },
    { lat: 48.7068154, lng: 20.9321091 },
    { lat: 48.7068931, lng: 20.9320764 },
    { lat: 48.7071698, lng: 20.9321766 },
    { lat: 48.707383, lng: 20.9317543 },
    { lat: 48.7078732, lng: 20.9314699 },
    { lat: 48.7083205, lng: 20.9313802 },
    { lat: 48.7085064, lng: 20.9311168 },
    { lat: 48.7084351, lng: 20.9310385 },
    { lat: 48.7087389, lng: 20.9308708 },
    { lat: 48.7090141, lng: 20.93082 },
    { lat: 48.7091883, lng: 20.9306477 },
    { lat: 48.709648, lng: 20.9299273 },
    { lat: 48.7097315, lng: 20.9296977 },
    { lat: 48.7098962, lng: 20.9295738 },
    { lat: 48.7100255, lng: 20.9292046 },
    { lat: 48.7103217, lng: 20.9290065 },
    { lat: 48.7105845, lng: 20.9290557 },
    { lat: 48.7108362, lng: 20.9293079 },
    { lat: 48.7109032, lng: 20.9296292 },
    { lat: 48.7111418, lng: 20.9297944 },
    { lat: 48.7113652, lng: 20.929712 },
    { lat: 48.7111867, lng: 20.9293558 },
    { lat: 48.7111315, lng: 20.9288532 },
    { lat: 48.7109268, lng: 20.9285603 },
    { lat: 48.7114102, lng: 20.9280383 },
    { lat: 48.7116213, lng: 20.9275234 },
    { lat: 48.7118869, lng: 20.927292 },
    { lat: 48.7122719, lng: 20.9271611 },
    { lat: 48.712776, lng: 20.9268824 },
    { lat: 48.7131328, lng: 20.9265166 },
    { lat: 48.7133978, lng: 20.9265682 },
    { lat: 48.7141915, lng: 20.9265063 },
    { lat: 48.7143726, lng: 20.9266035 },
    { lat: 48.7145773, lng: 20.9264263 },
    { lat: 48.7148175, lng: 20.9263375 },
    { lat: 48.7151029, lng: 20.9264275 },
    { lat: 48.7166643, lng: 20.9261948 },
    { lat: 48.7169103, lng: 20.9260821 },
    { lat: 48.7171479, lng: 20.9261846 },
    { lat: 48.7177232, lng: 20.9261909 },
    { lat: 48.7183217, lng: 20.9260474 },
    { lat: 48.7184685, lng: 20.9260564 },
    { lat: 48.7185036, lng: 20.9261321 },
    { lat: 48.718838, lng: 20.9261352 },
    { lat: 48.7191472, lng: 20.9261231 },
    { lat: 48.7193764, lng: 20.9260163 },
    { lat: 48.7200771, lng: 20.9260607 },
    { lat: 48.7208335, lng: 20.925996 },
    { lat: 48.722321, lng: 20.9262085 },
    { lat: 48.7225438, lng: 20.9261093 },
    { lat: 48.7225852, lng: 20.9260348 },
    { lat: 48.723394, lng: 20.9263853 },
    { lat: 48.7239924, lng: 20.9265381 },
    { lat: 48.7242442, lng: 20.9264292 },
    { lat: 48.7247151, lng: 20.9268456 },
    { lat: 48.7247644, lng: 20.9266982 },
    { lat: 48.7247116, lng: 20.9266702 },
    { lat: 48.7247236, lng: 20.9266275 },
    { lat: 48.7248206, lng: 20.9265334 },
    { lat: 48.7254472, lng: 20.9267525 },
    { lat: 48.7256532, lng: 20.9267558 },
    { lat: 48.7261285, lng: 20.927064 },
    { lat: 48.726316, lng: 20.926985 },
    { lat: 48.7268769, lng: 20.9271264 },
    { lat: 48.7270062, lng: 20.9273567 },
    { lat: 48.7271979, lng: 20.9275027 },
    { lat: 48.7272949, lng: 20.9275038 },
    { lat: 48.7275276, lng: 20.9278296 },
    { lat: 48.727903, lng: 20.9274837 },
    { lat: 48.7283916, lng: 20.9268492 },
    { lat: 48.7286625, lng: 20.9268062 },
    { lat: 48.7288116, lng: 20.9268824 },
    { lat: 48.7289549, lng: 20.9268653 },
    { lat: 48.7293464, lng: 20.9270604 },
    { lat: 48.729459, lng: 20.9272931 },
    { lat: 48.7296154, lng: 20.927357 },
    { lat: 48.7298238, lng: 20.9273595 },
    { lat: 48.7302102, lng: 20.9275358 },
    { lat: 48.7306903, lng: 20.9276571 },
    { lat: 48.7311922, lng: 20.9276141 },
    { lat: 48.7316148, lng: 20.9279334 },
    { lat: 48.7320982, lng: 20.9278459 },
    { lat: 48.7325158, lng: 20.9280808 },
    { lat: 48.7326879, lng: 20.9280076 },
    { lat: 48.733265, lng: 20.9275356 },
    { lat: 48.733555, lng: 20.9275532 },
    { lat: 48.7337599, lng: 20.9274183 },
    { lat: 48.7342535, lng: 20.9272683 },
    { lat: 48.7345155, lng: 20.9269688 },
    { lat: 48.7345909, lng: 20.9266985 },
    { lat: 48.7347192, lng: 20.9265673 },
    { lat: 48.7350621, lng: 20.9265865 },
    { lat: 48.7352071, lng: 20.9262765 },
    { lat: 48.7356277, lng: 20.9269961 },
    { lat: 48.7358234, lng: 20.92673 },
    { lat: 48.7359793, lng: 20.9266466 },
    { lat: 48.7359755, lng: 20.9261695 },
    { lat: 48.7360524, lng: 20.9258123 },
    { lat: 48.7362485, lng: 20.9255151 },
    { lat: 48.7364935, lng: 20.925375 },
    { lat: 48.7366471, lng: 20.9250176 },
    { lat: 48.7367419, lng: 20.9250238 },
    { lat: 48.7368458, lng: 20.924938 },
    { lat: 48.7375232, lng: 20.9248662 },
    { lat: 48.7377624, lng: 20.9246166 },
    { lat: 48.7380386, lng: 20.9247266 },
    { lat: 48.7382513, lng: 20.9249294 },
    { lat: 48.7383417, lng: 20.9249021 },
    { lat: 48.7385324, lng: 20.9250529 },
    { lat: 48.7387052, lng: 20.9249661 },
    { lat: 48.7386385, lng: 20.9246949 },
    { lat: 48.7383726, lng: 20.9245524 },
    { lat: 48.7383234, lng: 20.9241943 },
    { lat: 48.7386781, lng: 20.9231028 },
    { lat: 48.738845, lng: 20.9226463 },
    { lat: 48.7391139, lng: 20.9222396 },
    { lat: 48.7393572, lng: 20.9220425 },
    { lat: 48.7394832, lng: 20.9220435 },
    { lat: 48.7394834, lng: 20.9219797 },
    { lat: 48.7397693, lng: 20.9219455 },
    { lat: 48.7404238, lng: 20.9222284 },
    { lat: 48.7414132, lng: 20.92303 },
    { lat: 48.7418079, lng: 20.9230459 },
    { lat: 48.7426346, lng: 20.9235637 },
    { lat: 48.743258, lng: 20.9236943 },
    { lat: 48.7433589, lng: 20.9239804 },
    { lat: 48.7437495, lng: 20.924013 },
    { lat: 48.7441578, lng: 20.9239041 },
    { lat: 48.7443573, lng: 20.9238448 },
    { lat: 48.7448719, lng: 20.923523 },
    { lat: 48.745143, lng: 20.923511 },
    { lat: 48.7452889, lng: 20.9234369 },
    { lat: 48.7454027, lng: 20.9234403 },
    { lat: 48.7454497, lng: 20.9235276 },
    { lat: 48.7462655, lng: 20.9235252 },
    { lat: 48.746853, lng: 20.9232498 },
    { lat: 48.7467766, lng: 20.9230553 },
    { lat: 48.7469043, lng: 20.9229388 },
    { lat: 48.7474708, lng: 20.9228126 },
    { lat: 48.7478999, lng: 20.9229878 },
    { lat: 48.7477372, lng: 20.9231009 },
    { lat: 48.7478383, lng: 20.9234327 },
    { lat: 48.7483927, lng: 20.9233792 },
    { lat: 48.7483879, lng: 20.9231395 },
    { lat: 48.7493119, lng: 20.9237716 },
    { lat: 48.749568, lng: 20.9238534 },
    { lat: 48.7497255, lng: 20.9237814 },
    { lat: 48.7499588, lng: 20.9240915 },
    { lat: 48.7508163, lng: 20.9240856 },
    { lat: 48.7509161, lng: 20.9238763 },
    { lat: 48.7511386, lng: 20.923734 },
    { lat: 48.7517982, lng: 20.9237301 },
    { lat: 48.7519961, lng: 20.9238557 },
    { lat: 48.7524907, lng: 20.9244339 },
    { lat: 48.7531838, lng: 20.9256311 },
    { lat: 48.7536333, lng: 20.9260437 },
    { lat: 48.753963, lng: 20.9269368 },
    { lat: 48.7543319, lng: 20.9275305 },
    { lat: 48.754385, lng: 20.9275039 },
    { lat: 48.7544411, lng: 20.9267608 },
    { lat: 48.7546401, lng: 20.9266267 },
    { lat: 48.7552692, lng: 20.9264508 },
    { lat: 48.755613, lng: 20.9266661 },
    { lat: 48.7562904, lng: 20.9268964 },
    { lat: 48.7565435, lng: 20.9270559 },
    { lat: 48.7566933, lng: 20.9270294 },
    { lat: 48.7568711, lng: 20.927091 },
    { lat: 48.7571327, lng: 20.9272399 },
    { lat: 48.7575369, lng: 20.9276432 },
    { lat: 48.757489, lng: 20.9278235 },
    { lat: 48.7580568, lng: 20.9281753 },
    { lat: 48.7581019, lng: 20.9283723 },
    { lat: 48.7593669, lng: 20.9287378 },
    { lat: 48.7600091, lng: 20.9292245 },
    { lat: 48.7606372, lng: 20.9292874 },
    { lat: 48.7609204, lng: 20.9295442 },
    { lat: 48.7608044, lng: 20.929739 },
    { lat: 48.7608849, lng: 20.9299247 },
    { lat: 48.7610092, lng: 20.9297703 },
    { lat: 48.7611955, lng: 20.9293564 },
    { lat: 48.7613954, lng: 20.9291087 },
    { lat: 48.7616283, lng: 20.9290743 },
    { lat: 48.7626914, lng: 20.9285521 },
    { lat: 48.7631829, lng: 20.9284093 },
    { lat: 48.7638123, lng: 20.9285466 },
    { lat: 48.7644002, lng: 20.9284581 },
    { lat: 48.766155, lng: 20.931622 },
  ],
  KostoľanynadHornádom: [
    { lat: 48.8014761, lng: 21.2355028 },
    { lat: 48.8014331, lng: 21.2354923 },
    { lat: 48.8012494, lng: 21.2354476 },
    { lat: 48.8014008, lng: 21.2351048 },
    { lat: 48.8013742, lng: 21.2350835 },
    { lat: 48.801613, lng: 21.2329157 },
    { lat: 48.8016521, lng: 21.2318516 },
    { lat: 48.801896, lng: 21.2310489 },
    { lat: 48.8019469, lng: 21.2300089 },
    { lat: 48.8022548, lng: 21.2295879 },
    { lat: 48.8024106, lng: 21.2291762 },
    { lat: 48.8026041, lng: 21.2288847 },
    { lat: 48.8027531, lng: 21.2286778 },
    { lat: 48.8029216, lng: 21.2285979 },
    { lat: 48.8031337, lng: 21.2282911 },
    { lat: 48.8027439, lng: 21.2279509 },
    { lat: 48.8027812, lng: 21.2277711 },
    { lat: 48.8029945, lng: 21.227646 },
    { lat: 48.8029509, lng: 21.2274781 },
    { lat: 48.8032297, lng: 21.227032 },
    { lat: 48.803185, lng: 21.2267856 },
    { lat: 48.8032676, lng: 21.2265523 },
    { lat: 48.803462, lng: 21.2264342 },
    { lat: 48.8033201, lng: 21.2258517 },
    { lat: 48.8035687, lng: 21.2256255 },
    { lat: 48.8038066, lng: 21.2256118 },
    { lat: 48.8038903, lng: 21.2254419 },
    { lat: 48.8039538, lng: 21.2252393 },
    { lat: 48.8039612, lng: 21.2246079 },
    { lat: 48.8039254, lng: 21.224251 },
    { lat: 48.8036548, lng: 21.2231504 },
    { lat: 48.8033493, lng: 21.222632 },
    { lat: 48.8033377, lng: 21.2222605 },
    { lat: 48.8034274, lng: 21.2222091 },
    { lat: 48.8034669, lng: 21.2220296 },
    { lat: 48.8034114, lng: 21.2219161 },
    { lat: 48.8033573, lng: 21.2214798 },
    { lat: 48.8032571, lng: 21.2212624 },
    { lat: 48.8032849, lng: 21.2211769 },
    { lat: 48.8030801, lng: 21.2205289 },
    { lat: 48.8031646, lng: 21.2190874 },
    { lat: 48.8029018, lng: 21.2171188 },
    { lat: 48.8027161, lng: 21.2166451 },
    { lat: 48.8025689, lng: 21.2164018 },
    { lat: 48.8023195, lng: 21.2162019 },
    { lat: 48.8021676, lng: 21.21599 },
    { lat: 48.80177, lng: 21.2159342 },
    { lat: 48.800559, lng: 21.2142416 },
    { lat: 48.8001991, lng: 21.213855 },
    { lat: 48.7999952, lng: 21.213775 },
    { lat: 48.7997129, lng: 21.213526 },
    { lat: 48.7994005, lng: 21.2131414 },
    { lat: 48.799121, lng: 21.2124825 },
    { lat: 48.7990833, lng: 21.2122517 },
    { lat: 48.7991437, lng: 21.2115195 },
    { lat: 48.799079, lng: 21.2106324 },
    { lat: 48.7992508, lng: 21.2102607 },
    { lat: 48.7992706, lng: 21.2100322 },
    { lat: 48.7993741, lng: 21.209776 },
    { lat: 48.799465, lng: 21.209772 },
    { lat: 48.7995506, lng: 21.2093414 },
    { lat: 48.7997049, lng: 21.2090555 },
    { lat: 48.7998089, lng: 21.2084915 },
    { lat: 48.7996305, lng: 21.2077175 },
    { lat: 48.7996711, lng: 21.2073958 },
    { lat: 48.7994349, lng: 21.206359 },
    { lat: 48.7990532, lng: 21.2057424 },
    { lat: 48.7988934, lng: 21.2056332 },
    { lat: 48.7986514, lng: 21.2049437 },
    { lat: 48.7985614, lng: 21.2044422 },
    { lat: 48.7986677, lng: 21.2039969 },
    { lat: 48.798475, lng: 21.202812 },
    { lat: 48.79819, lng: 21.203396 },
    { lat: 48.797878, lng: 21.203747 },
    { lat: 48.797758, lng: 21.203977 },
    { lat: 48.797311, lng: 21.204324 },
    { lat: 48.797083, lng: 21.204605 },
    { lat: 48.796866, lng: 21.205334 },
    { lat: 48.796371, lng: 21.206046 },
    { lat: 48.796154, lng: 21.206258 },
    { lat: 48.795938, lng: 21.206355 },
    { lat: 48.795608, lng: 21.206751 },
    { lat: 48.795041, lng: 21.20765 },
    { lat: 48.795007, lng: 21.207638 },
    { lat: 48.794674, lng: 21.207528 },
    { lat: 48.794587, lng: 21.207462 },
    { lat: 48.794286, lng: 21.207219 },
    { lat: 48.794197, lng: 21.207147 },
    { lat: 48.79418, lng: 21.207134 },
    { lat: 48.794029, lng: 21.207085 },
    { lat: 48.793198, lng: 21.207074 },
    { lat: 48.792667, lng: 21.207372 },
    { lat: 48.79212, lng: 21.209153 },
    { lat: 48.792025, lng: 21.209669 },
    { lat: 48.79185, lng: 21.210233 },
    { lat: 48.791732, lng: 21.211123 },
    { lat: 48.791805, lng: 21.211472 },
    { lat: 48.79148, lng: 21.21234 },
    { lat: 48.791418, lng: 21.212599 },
    { lat: 48.791316, lng: 21.212679 },
    { lat: 48.791184, lng: 21.212877 },
    { lat: 48.791156, lng: 21.212919 },
    { lat: 48.790283, lng: 21.214221 },
    { lat: 48.790195, lng: 21.214333 },
    { lat: 48.78977, lng: 21.214688 },
    { lat: 48.789474, lng: 21.215174 },
    { lat: 48.789012, lng: 21.215998 },
    { lat: 48.788991, lng: 21.216101 },
    { lat: 48.789433, lng: 21.216307 },
    { lat: 48.789726, lng: 21.216559 },
    { lat: 48.789953, lng: 21.216554 },
    { lat: 48.790259, lng: 21.21709 },
    { lat: 48.790009, lng: 21.217222 },
    { lat: 48.789839, lng: 21.217248 },
    { lat: 48.78995, lng: 21.217569 },
    { lat: 48.789897, lng: 21.217812 },
    { lat: 48.7898, lng: 21.217986 },
    { lat: 48.78971, lng: 21.218088 },
    { lat: 48.78944, lng: 21.217743 },
    { lat: 48.78906, lng: 21.217584 },
    { lat: 48.788925, lng: 21.217551 },
    { lat: 48.78883, lng: 21.217675 },
    { lat: 48.788877, lng: 21.217916 },
    { lat: 48.78928, lng: 21.219094 },
    { lat: 48.789129, lng: 21.219568 },
    { lat: 48.788933, lng: 21.219641 },
    { lat: 48.788723, lng: 21.219368 },
    { lat: 48.788633, lng: 21.218931 },
    { lat: 48.788475, lng: 21.218638 },
    { lat: 48.788361, lng: 21.217757 },
    { lat: 48.78825, lng: 21.217315 },
    { lat: 48.788191, lng: 21.217019 },
    { lat: 48.787797, lng: 21.216992 },
    { lat: 48.787482, lng: 21.217264 },
    { lat: 48.787341, lng: 21.21734 },
    { lat: 48.787195, lng: 21.217417 },
    { lat: 48.787046, lng: 21.217473 },
    { lat: 48.786834, lng: 21.217454 },
    { lat: 48.786822, lng: 21.217436 },
    { lat: 48.786795, lng: 21.217392 },
    { lat: 48.786731, lng: 21.217293 },
    { lat: 48.786679, lng: 21.21721 },
    { lat: 48.786601, lng: 21.21718 },
    { lat: 48.786507, lng: 21.217142 },
    { lat: 48.786401, lng: 21.217005 },
    { lat: 48.786379, lng: 21.216977 },
    { lat: 48.786377, lng: 21.216984 },
    { lat: 48.786326, lng: 21.217104 },
    { lat: 48.786038, lng: 21.217782 },
    { lat: 48.786124, lng: 21.218314 },
    { lat: 48.786099, lng: 21.218857 },
    { lat: 48.785979, lng: 21.219244 },
    { lat: 48.785762, lng: 21.219374 },
    { lat: 48.785817, lng: 21.219497 },
    { lat: 48.785584, lng: 21.21946 },
    { lat: 48.785579, lng: 21.219679 },
    { lat: 48.785356, lng: 21.220213 },
    { lat: 48.785254, lng: 21.220321 },
    { lat: 48.785059, lng: 21.220527 },
    { lat: 48.785159, lng: 21.220898 },
    { lat: 48.785194, lng: 21.221034 },
    { lat: 48.785202, lng: 21.221062 },
    { lat: 48.785097, lng: 21.221662 },
    { lat: 48.785162, lng: 21.221715 },
    { lat: 48.784882, lng: 21.222521 },
    { lat: 48.785082, lng: 21.222689 },
    { lat: 48.784686, lng: 21.223148 },
    { lat: 48.784894, lng: 21.223549 },
    { lat: 48.784607, lng: 21.224115 },
    { lat: 48.784597, lng: 21.224136 },
    { lat: 48.784599, lng: 21.22414 },
    { lat: 48.784804, lng: 21.224395 },
    { lat: 48.784682, lng: 21.224654 },
    { lat: 48.78467, lng: 21.224679 },
    { lat: 48.784733, lng: 21.224785 },
    { lat: 48.784788, lng: 21.224879 },
    { lat: 48.784664, lng: 21.224994 },
    { lat: 48.78418, lng: 21.22544 },
    { lat: 48.784222, lng: 21.225745 },
    { lat: 48.784228, lng: 21.225778 },
    { lat: 48.784, lng: 21.22615 },
    { lat: 48.783828, lng: 21.226429 },
    { lat: 48.78381, lng: 21.226456 },
    { lat: 48.783245, lng: 21.227315 },
    { lat: 48.78322, lng: 21.227353 },
    { lat: 48.78323, lng: 21.227382 },
    { lat: 48.78334, lng: 21.227699 },
    { lat: 48.78331, lng: 21.22775 },
    { lat: 48.783298, lng: 21.227768 },
    { lat: 48.783277, lng: 21.227804 },
    { lat: 48.78325, lng: 21.227848 },
    { lat: 48.783205, lng: 21.227922 },
    { lat: 48.782905, lng: 21.228419 },
    { lat: 48.782578, lng: 21.228695 },
    { lat: 48.781785, lng: 21.229111 },
    { lat: 48.781766, lng: 21.229049 },
    { lat: 48.781616, lng: 21.228556 },
    { lat: 48.781664, lng: 21.228511 },
    { lat: 48.781448, lng: 21.228479 },
    { lat: 48.78107, lng: 21.228423 },
    { lat: 48.780481, lng: 21.227321 },
    { lat: 48.78051, lng: 21.227275 },
    { lat: 48.780315, lng: 21.226758 },
    { lat: 48.780616, lng: 21.226367 },
    { lat: 48.780643, lng: 21.226333 },
    { lat: 48.780654, lng: 21.226208 },
    { lat: 48.780694, lng: 21.225757 },
    { lat: 48.780746, lng: 21.22552 },
    { lat: 48.780769, lng: 21.225456 },
    { lat: 48.780821, lng: 21.225303 },
    { lat: 48.780944, lng: 21.225071 },
    { lat: 48.78107, lng: 21.224831 },
    { lat: 48.781202, lng: 21.22458 },
    { lat: 48.781415, lng: 21.224437 },
    { lat: 48.781473, lng: 21.224398 },
    { lat: 48.781667, lng: 21.223923 },
    { lat: 48.781695, lng: 21.223857 },
    { lat: 48.781645, lng: 21.22385 },
    { lat: 48.781427, lng: 21.223821 },
    { lat: 48.781336, lng: 21.2233 },
    { lat: 48.781242, lng: 21.223554 },
    { lat: 48.781078, lng: 21.223708 },
    { lat: 48.780992, lng: 21.223537 },
    { lat: 48.780977, lng: 21.223506 },
    { lat: 48.780811, lng: 21.223624 },
    { lat: 48.780294, lng: 21.224132 },
    { lat: 48.779953, lng: 21.224492 },
    { lat: 48.779648, lng: 21.224881 },
    { lat: 48.779535, lng: 21.225074 },
    { lat: 48.779488, lng: 21.225155 },
    { lat: 48.779546, lng: 21.225777 },
    { lat: 48.77931, lng: 21.225622 },
    { lat: 48.778944, lng: 21.226055 },
    { lat: 48.778709, lng: 21.226368 },
    { lat: 48.778607, lng: 21.226408 },
    { lat: 48.778485, lng: 21.226457 },
    { lat: 48.778412, lng: 21.226499 },
    { lat: 48.778337, lng: 21.226542 },
    { lat: 48.778187, lng: 21.225997 },
    { lat: 48.778177, lng: 21.225961 },
    { lat: 48.778107, lng: 21.226005 },
    { lat: 48.778058, lng: 21.226036 },
    { lat: 48.778025, lng: 21.226129 },
    { lat: 48.777861, lng: 21.225591 },
    { lat: 48.777858, lng: 21.225586 },
    { lat: 48.777719, lng: 21.225204 },
    { lat: 48.777689, lng: 21.225122 },
    { lat: 48.777485, lng: 21.224985 },
    { lat: 48.777419, lng: 21.22503 },
    { lat: 48.77735, lng: 21.225075 },
    { lat: 48.777373, lng: 21.225363 },
    { lat: 48.777474, lng: 21.225629 },
    { lat: 48.777543, lng: 21.22594 },
    { lat: 48.777581, lng: 21.22609 },
    { lat: 48.777615, lng: 21.226274 },
    { lat: 48.77761, lng: 21.226418 },
    { lat: 48.77761, lng: 21.226419 },
    { lat: 48.777613, lng: 21.226422 },
    { lat: 48.777706, lng: 21.226572 },
    { lat: 48.777756, lng: 21.226705 },
    { lat: 48.777857, lng: 21.226992 },
    { lat: 48.77787, lng: 21.227586 },
    { lat: 48.777814, lng: 21.227704 },
    { lat: 48.777709, lng: 21.227767 },
    { lat: 48.777698, lng: 21.227773 },
    { lat: 48.777691, lng: 21.227786 },
    { lat: 48.777634, lng: 21.227905 },
    { lat: 48.777606, lng: 21.227964 },
    { lat: 48.777026, lng: 21.228519 },
    { lat: 48.776848, lng: 21.22869 },
    { lat: 48.776624, lng: 21.228863 },
    { lat: 48.776812, lng: 21.229493 },
    { lat: 48.776542, lng: 21.229546 },
    { lat: 48.776504, lng: 21.229553 },
    { lat: 48.776363, lng: 21.229105 },
    { lat: 48.776315, lng: 21.228833 },
    { lat: 48.776096, lng: 21.228444 },
    { lat: 48.775966, lng: 21.228343 },
    { lat: 48.775909, lng: 21.228227 },
    { lat: 48.775492, lng: 21.227384 },
    { lat: 48.77527, lng: 21.226765 },
    { lat: 48.775225, lng: 21.226431 },
    { lat: 48.775249, lng: 21.226142 },
    { lat: 48.775038, lng: 21.225465 },
    { lat: 48.775011, lng: 21.225227 },
    { lat: 48.775115, lng: 21.224816 },
    { lat: 48.77486, lng: 21.224116 },
    { lat: 48.774328, lng: 21.223794 },
    { lat: 48.774208, lng: 21.223968 },
    { lat: 48.774247, lng: 21.224148 },
    { lat: 48.774209, lng: 21.224978 },
    { lat: 48.774427, lng: 21.225222 },
    { lat: 48.77418, lng: 21.225985 },
    { lat: 48.774247, lng: 21.22619 },
    { lat: 48.774207, lng: 21.226378 },
    { lat: 48.774401, lng: 21.226708 },
    { lat: 48.774373, lng: 21.22687 },
    { lat: 48.774263, lng: 21.227514 },
    { lat: 48.774523, lng: 21.227608 },
    { lat: 48.774531, lng: 21.22774 },
    { lat: 48.774508, lng: 21.227886 },
    { lat: 48.774689, lng: 21.22811 },
    { lat: 48.774759, lng: 21.228165 },
    { lat: 48.774736, lng: 21.22837 },
    { lat: 48.774799, lng: 21.228413 },
    { lat: 48.774874, lng: 21.228423 },
    { lat: 48.774864, lng: 21.22853 },
    { lat: 48.775045, lng: 21.228668 },
    { lat: 48.7755, lng: 21.229417 },
    { lat: 48.775658, lng: 21.229889 },
    { lat: 48.775845, lng: 21.230625 },
    { lat: 48.77584, lng: 21.230812 },
    { lat: 48.775694, lng: 21.231267 },
    { lat: 48.775581, lng: 21.231708 },
    { lat: 48.775547, lng: 21.232267 },
    { lat: 48.775608, lng: 21.232998 },
    { lat: 48.775712, lng: 21.233508 },
    { lat: 48.775882, lng: 21.233983 },
    { lat: 48.775884, lng: 21.234336 },
    { lat: 48.775584, lng: 21.234723 },
    { lat: 48.77585, lng: 21.235242 },
    { lat: 48.775709, lng: 21.235715 },
    { lat: 48.775819, lng: 21.236037 },
    { lat: 48.775581, lng: 21.236434 },
    { lat: 48.775148, lng: 21.236709 },
    { lat: 48.775144, lng: 21.236685 },
    { lat: 48.775171, lng: 21.236386 },
    { lat: 48.775055, lng: 21.236396 },
    { lat: 48.775007, lng: 21.236871 },
    { lat: 48.774959, lng: 21.236847 },
    { lat: 48.774824, lng: 21.236941 },
    { lat: 48.774829, lng: 21.237066 },
    { lat: 48.774831, lng: 21.237119 },
    { lat: 48.774795, lng: 21.237073 },
    { lat: 48.774709, lng: 21.237054 },
    { lat: 48.774596, lng: 21.237209 },
    { lat: 48.774547, lng: 21.237275 },
    { lat: 48.774501, lng: 21.237336 },
    { lat: 48.774454, lng: 21.2374 },
    { lat: 48.774362, lng: 21.237523 },
    { lat: 48.774328, lng: 21.237517 },
    { lat: 48.774139, lng: 21.237701 },
    { lat: 48.774117, lng: 21.237697 },
    { lat: 48.773981, lng: 21.237762 },
    { lat: 48.773941, lng: 21.237771 },
    { lat: 48.773857, lng: 21.237815 },
    { lat: 48.773768, lng: 21.237874 },
    { lat: 48.773698, lng: 21.237951 },
    { lat: 48.773635, lng: 21.23787 },
    { lat: 48.773475, lng: 21.237863 },
    { lat: 48.773429, lng: 21.237902 },
    { lat: 48.773389, lng: 21.237905 },
    { lat: 48.773265, lng: 21.238022 },
    { lat: 48.773233, lng: 21.23806 },
    { lat: 48.773266, lng: 21.238159 },
    { lat: 48.773294, lng: 21.238195 },
    { lat: 48.773255, lng: 21.238221 },
    { lat: 48.773046, lng: 21.238366 },
    { lat: 48.773027, lng: 21.238378 },
    { lat: 48.772992, lng: 21.238296 },
    { lat: 48.772813, lng: 21.238462 },
    { lat: 48.772532, lng: 21.238588 },
    { lat: 48.772318, lng: 21.239022 },
    { lat: 48.772214, lng: 21.239413 },
    { lat: 48.772202, lng: 21.239429 },
    { lat: 48.772197, lng: 21.239452 },
    { lat: 48.772199, lng: 21.239466 },
    { lat: 48.77225, lng: 21.239769 },
    { lat: 48.772382, lng: 21.240104 },
    { lat: 48.772478, lng: 21.240053 },
    { lat: 48.772498, lng: 21.24007 },
    { lat: 48.772539, lng: 21.240246 },
    { lat: 48.772505, lng: 21.24032 },
    { lat: 48.772529, lng: 21.240446 },
    { lat: 48.772513, lng: 21.240769 },
    { lat: 48.772427, lng: 21.241007 },
    { lat: 48.77231, lng: 21.241169 },
    { lat: 48.772168, lng: 21.241366 },
    { lat: 48.772063, lng: 21.241507 },
    { lat: 48.771933, lng: 21.241735 },
    { lat: 48.771734, lng: 21.24209 },
    { lat: 48.771752, lng: 21.24215 },
    { lat: 48.77175, lng: 21.242177 },
    { lat: 48.771749, lng: 21.242202 },
    { lat: 48.771747, lng: 21.242241 },
    { lat: 48.771746, lng: 21.242262 },
    { lat: 48.771716, lng: 21.242359 },
    { lat: 48.771679, lng: 21.242473 },
    { lat: 48.771567, lng: 21.242529 },
    { lat: 48.771493, lng: 21.242566 },
    { lat: 48.771484, lng: 21.242588 },
    { lat: 48.771479, lng: 21.242569 },
    { lat: 48.771349, lng: 21.242541 },
    { lat: 48.77133, lng: 21.242743 },
    { lat: 48.77132, lng: 21.242843 },
    { lat: 48.771312, lng: 21.242928 },
    { lat: 48.771305, lng: 21.242996 },
    { lat: 48.771284, lng: 21.243211 },
    { lat: 48.771271, lng: 21.243336 },
    { lat: 48.771266, lng: 21.243391 },
    { lat: 48.77125, lng: 21.243552 },
    { lat: 48.771226, lng: 21.243789 },
    { lat: 48.771269, lng: 21.24379 },
    { lat: 48.771261, lng: 21.243871 },
    { lat: 48.771267, lng: 21.244 },
    { lat: 48.771275, lng: 21.244144 },
    { lat: 48.771283, lng: 21.244303 },
    { lat: 48.7712901, lng: 21.2444317 },
    { lat: 48.771585, lng: 21.24438 },
    { lat: 48.771952, lng: 21.244262 },
    { lat: 48.772044, lng: 21.244135 },
    { lat: 48.772708, lng: 21.243379 },
    { lat: 48.773229, lng: 21.242866 },
    { lat: 48.773249, lng: 21.242853 },
    { lat: 48.773336, lng: 21.242797 },
    { lat: 48.773956, lng: 21.2424 },
    { lat: 48.774887, lng: 21.241924 },
    { lat: 48.775278, lng: 21.241795 },
    { lat: 48.775915, lng: 21.241532 },
    { lat: 48.776436, lng: 21.241347 },
    { lat: 48.777237, lng: 21.240959 },
    { lat: 48.77745, lng: 21.240799 },
    { lat: 48.777801, lng: 21.240551 },
    { lat: 48.778057, lng: 21.240406 },
    { lat: 48.77874, lng: 21.240488 },
    { lat: 48.779558, lng: 21.240895 },
    { lat: 48.779547, lng: 21.241088 },
    { lat: 48.779537, lng: 21.241266 },
    { lat: 48.779541, lng: 21.241321 },
    { lat: 48.779542, lng: 21.241337 },
    { lat: 48.779546, lng: 21.241386 },
    { lat: 48.779642, lng: 21.242555 },
    { lat: 48.779681, lng: 21.243037 },
    { lat: 48.779683, lng: 21.243067 },
    { lat: 48.779684, lng: 21.243085 },
    { lat: 48.779686, lng: 21.243112 },
    { lat: 48.779686, lng: 21.243193 },
    { lat: 48.779689, lng: 21.243346 },
    { lat: 48.779669, lng: 21.243448 },
    { lat: 48.779674, lng: 21.243737 },
    { lat: 48.779723, lng: 21.243744 },
    { lat: 48.779789, lng: 21.243783 },
    { lat: 48.780165, lng: 21.243883 },
    { lat: 48.780538, lng: 21.244354 },
    { lat: 48.780702, lng: 21.244561 },
    { lat: 48.7814546, lng: 21.2449042 },
    { lat: 48.7817428, lng: 21.2451642 },
    { lat: 48.7820218, lng: 21.2457036 },
    { lat: 48.7821665, lng: 21.2463806 },
    { lat: 48.7828484, lng: 21.2472437 },
    { lat: 48.7835229, lng: 21.2478411 },
    { lat: 48.7840256, lng: 21.2481962 },
    { lat: 48.7845449, lng: 21.2484656 },
    { lat: 48.7848907, lng: 21.2487666 },
    { lat: 48.7850021, lng: 21.2488772 },
    { lat: 48.7850513, lng: 21.2492327 },
    { lat: 48.7854391, lng: 21.2492502 },
    { lat: 48.7854182, lng: 21.2497791 },
    { lat: 48.7857525, lng: 21.2502554 },
    { lat: 48.7858771, lng: 21.2507173 },
    { lat: 48.7862534, lng: 21.2513484 },
    { lat: 48.7867896, lng: 21.251911 },
    { lat: 48.7869699, lng: 21.2520678 },
    { lat: 48.7872874, lng: 21.2522159 },
    { lat: 48.7878077, lng: 21.2522287 },
    { lat: 48.7895359, lng: 21.2533841 },
    { lat: 48.7906619, lng: 21.253551 },
    { lat: 48.7911314, lng: 21.2532673 },
    { lat: 48.7923619, lng: 21.2530614 },
    { lat: 48.7928072, lng: 21.2528721 },
    { lat: 48.7935816, lng: 21.2529108 },
    { lat: 48.7949795, lng: 21.2531403 },
    { lat: 48.7957504, lng: 21.2529555 },
    { lat: 48.7963006, lng: 21.2526606 },
    { lat: 48.7965151, lng: 21.2524655 },
    { lat: 48.7965619, lng: 21.2524833 },
    { lat: 48.7965479, lng: 21.2524668 },
    { lat: 48.7965177, lng: 21.2523661 },
    { lat: 48.7964652, lng: 21.2498659 },
    { lat: 48.7963701, lng: 21.2488636 },
    { lat: 48.796497, lng: 21.2486365 },
    { lat: 48.7973632, lng: 21.2478994 },
    { lat: 48.7985521, lng: 21.2466754 },
    { lat: 48.7990121, lng: 21.2460542 },
    { lat: 48.7994815, lng: 21.245001 },
    { lat: 48.7996677, lng: 21.2447972 },
    { lat: 48.7997251, lng: 21.2446568 },
    { lat: 48.7998476, lng: 21.2439984 },
    { lat: 48.7991458, lng: 21.2428096 },
    { lat: 48.7998256, lng: 21.2416868 },
    { lat: 48.7998333, lng: 21.2414246 },
    { lat: 48.7997662, lng: 21.2413302 },
    { lat: 48.7999343, lng: 21.2409788 },
    { lat: 48.800124, lng: 21.2398708 },
    { lat: 48.8006277, lng: 21.2383711 },
    { lat: 48.8009571, lng: 21.2369409 },
    { lat: 48.8014761, lng: 21.2355028 },
  ],
  Hrašovík: [
    { lat: 48.7418188, lng: 21.3355201 },
    { lat: 48.7422783, lng: 21.3351996 },
    { lat: 48.7425722, lng: 21.3358036 },
    { lat: 48.7428019, lng: 21.3359988 },
    { lat: 48.7433885, lng: 21.3367538 },
    { lat: 48.7439214, lng: 21.3363622 },
    { lat: 48.7438538, lng: 21.3360193 },
    { lat: 48.7442864, lng: 21.3354449 },
    { lat: 48.7438833, lng: 21.3350602 },
    { lat: 48.7433865, lng: 21.3350457 },
    { lat: 48.7430202, lng: 21.334692 },
    { lat: 48.7430167, lng: 21.3346155 },
    { lat: 48.743066, lng: 21.3344503 },
    { lat: 48.7435854, lng: 21.3342924 },
    { lat: 48.7436885, lng: 21.334199 },
    { lat: 48.7435426, lng: 21.3333202 },
    { lat: 48.7438719, lng: 21.3331919 },
    { lat: 48.7440938, lng: 21.3341375 },
    { lat: 48.7444347, lng: 21.3340159 },
    { lat: 48.7447975, lng: 21.3335523 },
    { lat: 48.7449604, lng: 21.3334647 },
    { lat: 48.7449063, lng: 21.332939 },
    { lat: 48.7450235, lng: 21.332503 },
    { lat: 48.7451761, lng: 21.3321918 },
    { lat: 48.7450031, lng: 21.3318255 },
    { lat: 48.7447458, lng: 21.3315759 },
    { lat: 48.7446768, lng: 21.3313068 },
    { lat: 48.7448486, lng: 21.3303589 },
    { lat: 48.7450699, lng: 21.3299036 },
    { lat: 48.7452231, lng: 21.3301658 },
    { lat: 48.745441, lng: 21.3303468 },
    { lat: 48.7452074, lng: 21.3313225 },
    { lat: 48.7452211, lng: 21.3315901 },
    { lat: 48.745341, lng: 21.3318742 },
    { lat: 48.7458682, lng: 21.3322599 },
    { lat: 48.7459807, lng: 21.3322243 },
    { lat: 48.7460314, lng: 21.3315223 },
    { lat: 48.7456809, lng: 21.3309093 },
    { lat: 48.745752, lng: 21.3303916 },
    { lat: 48.7460734, lng: 21.3302271 },
    { lat: 48.7464704, lng: 21.3307082 },
    { lat: 48.746588, lng: 21.3317259 },
    { lat: 48.7467497, lng: 21.3317151 },
    { lat: 48.7471059, lng: 21.3309948 },
    { lat: 48.747397, lng: 21.3306084 },
    { lat: 48.7477749, lng: 21.3299347 },
    { lat: 48.7482697, lng: 21.3292519 },
    { lat: 48.7487204, lng: 21.3284109 },
    { lat: 48.7482854, lng: 21.3280227 },
    { lat: 48.748183, lng: 21.3276468 },
    { lat: 48.7489543, lng: 21.3271109 },
    { lat: 48.7504789, lng: 21.3263186 },
    { lat: 48.7507242, lng: 21.3260933 },
    { lat: 48.7511263, lng: 21.3258462 },
    { lat: 48.7514363, lng: 21.3253443 },
    { lat: 48.7517332, lng: 21.3246676 },
    { lat: 48.7519443, lng: 21.3243203 },
    { lat: 48.7523027, lng: 21.3239688 },
    { lat: 48.7527947, lng: 21.3239015 },
    { lat: 48.7531734, lng: 21.3239238 },
    { lat: 48.7534346, lng: 21.3237437 },
    { lat: 48.75369, lng: 21.3234793 },
    { lat: 48.7535695, lng: 21.3231424 },
    { lat: 48.7531178, lng: 21.3207893 },
    { lat: 48.7530483, lng: 21.3207916 },
    { lat: 48.7524439, lng: 21.3174193 },
    { lat: 48.7522138, lng: 21.3164328 },
    { lat: 48.7519962, lng: 21.3159369 },
    { lat: 48.7518624, lng: 21.3152743 },
    { lat: 48.7508016, lng: 21.3139187 },
    { lat: 48.7503388, lng: 21.3143495 },
    { lat: 48.7501653, lng: 21.3140499 },
    { lat: 48.7495661, lng: 21.3133666 },
    { lat: 48.7492272, lng: 21.3128763 },
    { lat: 48.749136, lng: 21.312518 },
    { lat: 48.748833, lng: 21.312782 },
    { lat: 48.74876, lng: 21.311057 },
    { lat: 48.748308, lng: 21.311494 },
    { lat: 48.748146, lng: 21.311505 },
    { lat: 48.747681, lng: 21.311914 },
    { lat: 48.747418, lng: 21.312087 },
    { lat: 48.747073, lng: 21.3124 },
    { lat: 48.746759, lng: 21.312831 },
    { lat: 48.746567, lng: 21.312844 },
    { lat: 48.746302, lng: 21.313245 },
    { lat: 48.746301, lng: 21.3133 },
    { lat: 48.746283, lng: 21.314245 },
    { lat: 48.746248, lng: 21.314254 },
    { lat: 48.746035, lng: 21.314315 },
    { lat: 48.745821, lng: 21.31457 },
    { lat: 48.745769, lng: 21.314632 },
    { lat: 48.745526, lng: 21.314922 },
    { lat: 48.74528, lng: 21.315214 },
    { lat: 48.745229, lng: 21.315275 },
    { lat: 48.7451, lng: 21.315429 },
    { lat: 48.745107, lng: 21.315703 },
    { lat: 48.745109, lng: 21.315759 },
    { lat: 48.745115, lng: 21.316038 },
    { lat: 48.745116, lng: 21.316064 },
    { lat: 48.74509, lng: 21.316404 },
    { lat: 48.745079, lng: 21.316554 },
    { lat: 48.745084, lng: 21.316677 },
    { lat: 48.745088, lng: 21.316798 },
    { lat: 48.745096, lng: 21.317029 },
    { lat: 48.7451, lng: 21.317165 },
    { lat: 48.745078, lng: 21.317197 },
    { lat: 48.744918, lng: 21.31712 },
    { lat: 48.744845, lng: 21.317084 },
    { lat: 48.744621, lng: 21.316888 },
    { lat: 48.744383, lng: 21.316679 },
    { lat: 48.744187, lng: 21.316509 },
    { lat: 48.744077, lng: 21.316412 },
    { lat: 48.743982, lng: 21.316428 },
    { lat: 48.743828, lng: 21.316453 },
    { lat: 48.743469, lng: 21.316587 },
    { lat: 48.742991, lng: 21.316899 },
    { lat: 48.74304, lng: 21.317354 },
    { lat: 48.742089, lng: 21.317136 },
    { lat: 48.741661, lng: 21.317015 },
    { lat: 48.741432, lng: 21.31708 },
    { lat: 48.741403, lng: 21.317132 },
    { lat: 48.741265, lng: 21.317022 },
    { lat: 48.741083, lng: 21.316907 },
    { lat: 48.739792, lng: 21.315232 },
    { lat: 48.739659, lng: 21.31513 },
    { lat: 48.739349, lng: 21.31447 },
    { lat: 48.739434, lng: 21.314324 },
    { lat: 48.739017, lng: 21.313526 },
    { lat: 48.738988, lng: 21.315373 },
    { lat: 48.739075, lng: 21.315785 },
    { lat: 48.739039, lng: 21.316159 },
    { lat: 48.73889, lng: 21.316775 },
    { lat: 48.738581, lng: 21.317423 },
    { lat: 48.738608, lng: 21.317679 },
    { lat: 48.738572, lng: 21.318065 },
    { lat: 48.738257, lng: 21.318289 },
    { lat: 48.737928, lng: 21.319152 },
    { lat: 48.737655, lng: 21.319935 },
    { lat: 48.737498, lng: 21.320671 },
    { lat: 48.737358, lng: 21.320784 },
    { lat: 48.737437, lng: 21.320944 },
    { lat: 48.737314, lng: 21.321485 },
    { lat: 48.737342, lng: 21.321742 },
    { lat: 48.73724, lng: 21.322167 },
    { lat: 48.736983, lng: 21.32274 },
    { lat: 48.737011, lng: 21.323446 },
    { lat: 48.736803, lng: 21.324171 },
    { lat: 48.736122, lng: 21.324758 },
    { lat: 48.736055, lng: 21.324816 },
    { lat: 48.7360323, lng: 21.325216 },
    { lat: 48.7361113, lng: 21.3255984 },
    { lat: 48.736338, lng: 21.3259813 },
    { lat: 48.7366481, lng: 21.3260069 },
    { lat: 48.7368138, lng: 21.3261604 },
    { lat: 48.7369308, lng: 21.3267509 },
    { lat: 48.73714, lng: 21.3269407 },
    { lat: 48.7372609, lng: 21.3275131 },
    { lat: 48.7374837, lng: 21.3277708 },
    { lat: 48.7376399, lng: 21.3280602 },
    { lat: 48.7376535, lng: 21.3289552 },
    { lat: 48.7378024, lng: 21.3293003 },
    { lat: 48.7376241, lng: 21.3300331 },
    { lat: 48.7376998, lng: 21.3305638 },
    { lat: 48.7376384, lng: 21.3308732 },
    { lat: 48.7376462, lng: 21.3312488 },
    { lat: 48.7374937, lng: 21.3314119 },
    { lat: 48.7374465, lng: 21.3316009 },
    { lat: 48.7381359, lng: 21.3322599 },
    { lat: 48.7388707, lng: 21.3346808 },
    { lat: 48.7400902, lng: 21.3349843 },
    { lat: 48.7408771, lng: 21.3350115 },
    { lat: 48.7418878, lng: 21.3347926 },
    { lat: 48.7418188, lng: 21.3355201 },
  ],
  Rudník: [
    { lat: 48.6584655, lng: 21.0360147 },
    { lat: 48.6584894, lng: 21.0360172 },
    { lat: 48.6590233, lng: 21.0360641 },
    { lat: 48.659402, lng: 21.0362466 },
    { lat: 48.6595658, lng: 21.036166 },
    { lat: 48.6599015, lng: 21.0362462 },
    { lat: 48.6600508, lng: 21.0361592 },
    { lat: 48.660393, lng: 21.0361653 },
    { lat: 48.6616402, lng: 21.0366473 },
    { lat: 48.6617833, lng: 21.0366145 },
    { lat: 48.6620997, lng: 21.0363811 },
    { lat: 48.6626322, lng: 21.036525 },
    { lat: 48.6635941, lng: 21.0373649 },
    { lat: 48.6652879, lng: 21.0374977 },
    { lat: 48.6652996, lng: 21.037583 },
    { lat: 48.6654669, lng: 21.0375687 },
    { lat: 48.6657008, lng: 21.0373529 },
    { lat: 48.6657888, lng: 21.0373955 },
    { lat: 48.6657806, lng: 21.0375193 },
    { lat: 48.6658714, lng: 21.0375766 },
    { lat: 48.6661724, lng: 21.0375562 },
    { lat: 48.6662129, lng: 21.0374186 },
    { lat: 48.6662897, lng: 21.0374655 },
    { lat: 48.6667058, lng: 21.0373172 },
    { lat: 48.6680874, lng: 21.0374005 },
    { lat: 48.6696667, lng: 21.036688 },
    { lat: 48.6714398, lng: 21.0363558 },
    { lat: 48.6722013, lng: 21.0366838 },
    { lat: 48.6730286, lng: 21.0368526 },
    { lat: 48.6739025, lng: 21.037278 },
    { lat: 48.6745846, lng: 21.0378591 },
    { lat: 48.6759247, lng: 21.0383621 },
    { lat: 48.6759625, lng: 21.038558 },
    { lat: 48.6759118, lng: 21.0386889 },
    { lat: 48.6759775, lng: 21.0388429 },
    { lat: 48.6760559, lng: 21.0388676 },
    { lat: 48.676095, lng: 21.0390236 },
    { lat: 48.6761659, lng: 21.0390553 },
    { lat: 48.6761995, lng: 21.0394331 },
    { lat: 48.6761643, lng: 21.0395266 },
    { lat: 48.6762181, lng: 21.039654 },
    { lat: 48.6764536, lng: 21.039766 },
    { lat: 48.6765466, lng: 21.0399638 },
    { lat: 48.6766361, lng: 21.039945 },
    { lat: 48.6768475, lng: 21.0402505 },
    { lat: 48.6775501, lng: 21.0408725 },
    { lat: 48.6782829, lng: 21.0410827 },
    { lat: 48.678848, lng: 21.0418001 },
    { lat: 48.6791172, lng: 21.0420482 },
    { lat: 48.6792813, lng: 21.0424851 },
    { lat: 48.6795041, lng: 21.0429015 },
    { lat: 48.6797375, lng: 21.0430134 },
    { lat: 48.6800947, lng: 21.0428221 },
    { lat: 48.6804849, lng: 21.0430466 },
    { lat: 48.6812662, lng: 21.043089 },
    { lat: 48.6815242, lng: 21.0430284 },
    { lat: 48.6818536, lng: 21.0430493 },
    { lat: 48.6821008, lng: 21.0432075 },
    { lat: 48.6825501, lng: 21.043632 },
    { lat: 48.6829279, lng: 21.0437149 },
    { lat: 48.683096, lng: 21.043837 },
    { lat: 48.6833951, lng: 21.0441341 },
    { lat: 48.6834849, lng: 21.0442956 },
    { lat: 48.6837652, lng: 21.0454358 },
    { lat: 48.6838649, lng: 21.046451 },
    { lat: 48.6839066, lng: 21.0465216 },
    { lat: 48.6844838, lng: 21.046553 },
    { lat: 48.6850831, lng: 21.0463576 },
    { lat: 48.6855004, lng: 21.0464158 },
    { lat: 48.6857631, lng: 21.0463641 },
    { lat: 48.685776, lng: 21.0463112 },
    { lat: 48.6859453, lng: 21.0464624 },
    { lat: 48.6862486, lng: 21.0464483 },
    { lat: 48.6865112, lng: 21.0463489 },
    { lat: 48.6865537, lng: 21.0464433 },
    { lat: 48.6867042, lng: 21.0464392 },
    { lat: 48.686795, lng: 21.0465966 },
    { lat: 48.6868717, lng: 21.0465688 },
    { lat: 48.6869113, lng: 21.0464757 },
    { lat: 48.6870161, lng: 21.0466132 },
    { lat: 48.6871797, lng: 21.0465522 },
    { lat: 48.6873211, lng: 21.0468167 },
    { lat: 48.6874035, lng: 21.0467546 },
    { lat: 48.6874808, lng: 21.0468186 },
    { lat: 48.6874672, lng: 21.0467124 },
    { lat: 48.6875071, lng: 21.0466564 },
    { lat: 48.687581, lng: 21.0467431 },
    { lat: 48.6877541, lng: 21.0466358 },
    { lat: 48.68793, lng: 21.0467131 },
    { lat: 48.6883755, lng: 21.0466414 },
    { lat: 48.6885002, lng: 21.046668 },
    { lat: 48.6885812, lng: 21.0467724 },
    { lat: 48.6886585, lng: 21.0462077 },
    { lat: 48.6887163, lng: 21.0461126 },
    { lat: 48.6886978, lng: 21.0459658 },
    { lat: 48.6887518, lng: 21.0456554 },
    { lat: 48.6888516, lng: 21.0454356 },
    { lat: 48.689041, lng: 21.0453762 },
    { lat: 48.6893908, lng: 21.0454349 },
    { lat: 48.6894978, lng: 21.0453688 },
    { lat: 48.6895774, lng: 21.0451555 },
    { lat: 48.6895952, lng: 21.0449444 },
    { lat: 48.6898137, lng: 21.0448084 },
    { lat: 48.6904983, lng: 21.0444394 },
    { lat: 48.690529, lng: 21.0444849 },
    { lat: 48.6906173, lng: 21.0443646 },
    { lat: 48.6909308, lng: 21.0444167 },
    { lat: 48.6909905, lng: 21.0442448 },
    { lat: 48.6912833, lng: 21.044146 },
    { lat: 48.6913473, lng: 21.0444159 },
    { lat: 48.6914867, lng: 21.0443791 },
    { lat: 48.6915236, lng: 21.0442666 },
    { lat: 48.6915931, lng: 21.0442233 },
    { lat: 48.6916421, lng: 21.0442432 },
    { lat: 48.6916299, lng: 21.0443506 },
    { lat: 48.6916795, lng: 21.0444548 },
    { lat: 48.6917455, lng: 21.04441 },
    { lat: 48.6917597, lng: 21.0443242 },
    { lat: 48.6919742, lng: 21.0442771 },
    { lat: 48.6922312, lng: 21.0439152 },
    { lat: 48.6923978, lng: 21.0438746 },
    { lat: 48.6935019, lng: 21.0437761 },
    { lat: 48.6935671, lng: 21.0438717 },
    { lat: 48.6938789, lng: 21.0439075 },
    { lat: 48.6940532, lng: 21.0437655 },
    { lat: 48.6942279, lng: 21.0438426 },
    { lat: 48.6944401, lng: 21.0438239 },
    { lat: 48.6952578, lng: 21.0435654 },
    { lat: 48.6956746, lng: 21.0432709 },
    { lat: 48.6958221, lng: 21.0434106 },
    { lat: 48.6960319, lng: 21.0432863 },
    { lat: 48.6963035, lng: 21.0427782 },
    { lat: 48.6962939, lng: 21.0426574 },
    { lat: 48.6963479, lng: 21.042621 },
    { lat: 48.6963587, lng: 21.0424762 },
    { lat: 48.6966336, lng: 21.0424428 },
    { lat: 48.6969673, lng: 21.0419465 },
    { lat: 48.6970181, lng: 21.0417849 },
    { lat: 48.6969953, lng: 21.0416711 },
    { lat: 48.6971437, lng: 21.0417017 },
    { lat: 48.6972192, lng: 21.0418026 },
    { lat: 48.6972918, lng: 21.0416497 },
    { lat: 48.6972901, lng: 21.0415262 },
    { lat: 48.6975387, lng: 21.0414901 },
    { lat: 48.6979148, lng: 21.041323 },
    { lat: 48.6979406, lng: 21.0414611 },
    { lat: 48.6981492, lng: 21.0414377 },
    { lat: 48.6982341, lng: 21.0415233 },
    { lat: 48.6983748, lng: 21.0414543 },
    { lat: 48.6984773, lng: 21.0416964 },
    { lat: 48.698561, lng: 21.0417375 },
    { lat: 48.6986847, lng: 21.0416332 },
    { lat: 48.6988151, lng: 21.0418343 },
    { lat: 48.6988771, lng: 21.041804 },
    { lat: 48.6990622, lng: 21.041953 },
    { lat: 48.6991199, lng: 21.041865 },
    { lat: 48.6991955, lng: 21.0420047 },
    { lat: 48.6992733, lng: 21.0419045 },
    { lat: 48.6994662, lng: 21.0420906 },
    { lat: 48.6997158, lng: 21.0416401 },
    { lat: 48.6998841, lng: 21.0415851 },
    { lat: 48.6997964, lng: 21.0414207 },
    { lat: 48.6998443, lng: 21.0413362 },
    { lat: 48.7000612, lng: 21.0411529 },
    { lat: 48.7001191, lng: 21.0412365 },
    { lat: 48.7007316, lng: 21.0409691 },
    { lat: 48.7007813, lng: 21.0410085 },
    { lat: 48.7010675, lng: 21.040949 },
    { lat: 48.7011894, lng: 21.0410264 },
    { lat: 48.7012451, lng: 21.040868 },
    { lat: 48.7013078, lng: 21.0408241 },
    { lat: 48.7013479, lng: 21.0408172 },
    { lat: 48.7013965, lng: 21.0409272 },
    { lat: 48.7015027, lng: 21.0408722 },
    { lat: 48.7016447, lng: 21.0409058 },
    { lat: 48.7018292, lng: 21.0407373 },
    { lat: 48.7019974, lng: 21.0408872 },
    { lat: 48.7020244, lng: 21.0406116 },
    { lat: 48.7022345, lng: 21.0407683 },
    { lat: 48.7023702, lng: 21.0407931 },
    { lat: 48.702428, lng: 21.0406619 },
    { lat: 48.7026561, lng: 21.0406562 },
    { lat: 48.7028551, lng: 21.0405102 },
    { lat: 48.7032283, lng: 21.0404623 },
    { lat: 48.7033809, lng: 21.0405849 },
    { lat: 48.7034272, lng: 21.0404499 },
    { lat: 48.7035636, lng: 21.0404903 },
    { lat: 48.7035586, lng: 21.0406165 },
    { lat: 48.7037006, lng: 21.0405154 },
    { lat: 48.7037573, lng: 21.0405642 },
    { lat: 48.7039204, lng: 21.040422 },
    { lat: 48.7039494, lng: 21.0405167 },
    { lat: 48.7042803, lng: 21.040415 },
    { lat: 48.704548, lng: 21.0404085 },
    { lat: 48.7047356, lng: 21.0404315 },
    { lat: 48.7047919, lng: 21.0405395 },
    { lat: 48.704918, lng: 21.0405365 },
    { lat: 48.7049579, lng: 21.0403796 },
    { lat: 48.7051546, lng: 21.0404786 },
    { lat: 48.7055074, lng: 21.0401842 },
    { lat: 48.7057328, lng: 21.0402247 },
    { lat: 48.7059264, lng: 21.0400566 },
    { lat: 48.7059745, lng: 21.0400769 },
    { lat: 48.7060275, lng: 21.0399824 },
    { lat: 48.7061729, lng: 21.0399865 },
    { lat: 48.7062517, lng: 21.0397771 },
    { lat: 48.7063807, lng: 21.0397001 },
    { lat: 48.7065827, lng: 21.0393707 },
    { lat: 48.7067524, lng: 21.0392607 },
    { lat: 48.706757, lng: 21.039162 },
    { lat: 48.7071527, lng: 21.0388726 },
    { lat: 48.7072764, lng: 21.0385961 },
    { lat: 48.7076559, lng: 21.0381514 },
    { lat: 48.7076734, lng: 21.0380664 },
    { lat: 48.7083723, lng: 21.0377567 },
    { lat: 48.7106941, lng: 21.0373056 },
    { lat: 48.7109815, lng: 21.037108 },
    { lat: 48.7115203, lng: 21.0371682 },
    { lat: 48.7118505, lng: 21.0370825 },
    { lat: 48.7118836, lng: 21.0373366 },
    { lat: 48.7124425, lng: 21.0374209 },
    { lat: 48.7128922, lng: 21.0373031 },
    { lat: 48.7135567, lng: 21.0369383 },
    { lat: 48.7136917, lng: 21.037008 },
    { lat: 48.7138181, lng: 21.0369501 },
    { lat: 48.7138898, lng: 21.0369714 },
    { lat: 48.7139124, lng: 21.0370424 },
    { lat: 48.714228, lng: 21.0368484 },
    { lat: 48.7143984, lng: 21.036867 },
    { lat: 48.7144951, lng: 21.0367575 },
    { lat: 48.7145918, lng: 21.0367578 },
    { lat: 48.7146294, lng: 21.036839 },
    { lat: 48.7148323, lng: 21.0368132 },
    { lat: 48.7149255, lng: 21.0369067 },
    { lat: 48.7149562, lng: 21.0368506 },
    { lat: 48.7151429, lng: 21.0368138 },
    { lat: 48.7152406, lng: 21.0366995 },
    { lat: 48.7155577, lng: 21.036658 },
    { lat: 48.7157928, lng: 21.0363762 },
    { lat: 48.7163251, lng: 21.0361692 },
    { lat: 48.7166072, lng: 21.0361245 },
    { lat: 48.716855, lng: 21.0362025 },
    { lat: 48.717118, lng: 21.0360877 },
    { lat: 48.7172462, lng: 21.0360831 },
    { lat: 48.717284, lng: 21.0361376 },
    { lat: 48.7174448, lng: 21.0360618 },
    { lat: 48.7182496, lng: 21.03603 },
    { lat: 48.7186862, lng: 21.036132 },
    { lat: 48.7190562, lng: 21.0359849 },
    { lat: 48.7196954, lng: 21.0359801 },
    { lat: 48.7199447, lng: 21.0358285 },
    { lat: 48.720073, lng: 21.0358577 },
    { lat: 48.7203068, lng: 21.0356776 },
    { lat: 48.7211383, lng: 21.0359778 },
    { lat: 48.7213605, lng: 21.0362642 },
    { lat: 48.721439, lng: 21.0362925 },
    { lat: 48.7215273, lng: 21.0362021 },
    { lat: 48.7218096, lng: 21.0362305 },
    { lat: 48.7223158, lng: 21.0358796 },
    { lat: 48.7226922, lng: 21.0358582 },
    { lat: 48.7228514, lng: 21.0357415 },
    { lat: 48.7230693, lng: 21.035764 },
    { lat: 48.7230547, lng: 21.0359012 },
    { lat: 48.7227275, lng: 21.0365243 },
    { lat: 48.7224082, lng: 21.0372936 },
    { lat: 48.7224909, lng: 21.0380246 },
    { lat: 48.7225197, lng: 21.039012 },
    { lat: 48.7221989, lng: 21.0418952 },
    { lat: 48.722263, lng: 21.0438171 },
    { lat: 48.7223281, lng: 21.0442568 },
    { lat: 48.7224661, lng: 21.0444165 },
    { lat: 48.7225876, lng: 21.0440342 },
    { lat: 48.7228957, lng: 21.0433763 },
    { lat: 48.7231541, lng: 21.0435316 },
    { lat: 48.7232082, lng: 21.0434324 },
    { lat: 48.7234537, lng: 21.0433306 },
    { lat: 48.7237977, lng: 21.0423185 },
    { lat: 48.7238867, lng: 21.041811 },
    { lat: 48.7242585, lng: 21.040964 },
    { lat: 48.7244657, lng: 21.0408597 },
    { lat: 48.7246308, lng: 21.0410245 },
    { lat: 48.724733, lng: 21.0410119 },
    { lat: 48.7250806, lng: 21.0405998 },
    { lat: 48.7255634, lng: 21.0402189 },
    { lat: 48.7257859, lng: 21.0396911 },
    { lat: 48.7258246, lng: 21.0392237 },
    { lat: 48.7257535, lng: 21.038911 },
    { lat: 48.725791, lng: 21.0386786 },
    { lat: 48.7257174, lng: 21.0384285 },
    { lat: 48.7256437, lng: 21.0383449 },
    { lat: 48.7256894, lng: 21.0382584 },
    { lat: 48.7260409, lng: 21.0379291 },
    { lat: 48.72629, lng: 21.037591 },
    { lat: 48.7263447, lng: 21.0376839 },
    { lat: 48.7265035, lng: 21.0377504 },
    { lat: 48.7265852, lng: 21.0381485 },
    { lat: 48.7268875, lng: 21.0386161 },
    { lat: 48.726991, lng: 21.0388939 },
    { lat: 48.7273683, lng: 21.0380439 },
    { lat: 48.7275752, lng: 21.0377296 },
    { lat: 48.7277451, lng: 21.0369347 },
    { lat: 48.7279773, lng: 21.0361234 },
    { lat: 48.7280878, lng: 21.0355098 },
    { lat: 48.7283609, lng: 21.0352572 },
    { lat: 48.7283857, lng: 21.0350849 },
    { lat: 48.7284571, lng: 21.0349634 },
    { lat: 48.7286452, lng: 21.0349008 },
    { lat: 48.728811, lng: 21.0347425 },
    { lat: 48.728877, lng: 21.0345896 },
    { lat: 48.7290366, lng: 21.0345538 },
    { lat: 48.7290374, lng: 21.0341961 },
    { lat: 48.7294672, lng: 21.0338808 },
    { lat: 48.7296212, lng: 21.0335359 },
    { lat: 48.7295986, lng: 21.0332225 },
    { lat: 48.7297099, lng: 21.0328779 },
    { lat: 48.7300185, lng: 21.0329161 },
    { lat: 48.7302223, lng: 21.0319592 },
    { lat: 48.7306572, lng: 21.0316289 },
    { lat: 48.7307769, lng: 21.0312958 },
    { lat: 48.731222, lng: 21.0311765 },
    { lat: 48.7313436, lng: 21.0309699 },
    { lat: 48.732298, lng: 21.0299423 },
    { lat: 48.7326486, lng: 21.0301317 },
    { lat: 48.7328155, lng: 21.0297327 },
    { lat: 48.7332565, lng: 21.0297263 },
    { lat: 48.7333466, lng: 21.0294878 },
    { lat: 48.7338168, lng: 21.0287619 },
    { lat: 48.733956, lng: 21.0284027 },
    { lat: 48.7342368, lng: 21.0283506 },
    { lat: 48.7343622, lng: 21.0281601 },
    { lat: 48.7344675, lng: 21.0281482 },
    { lat: 48.7346526, lng: 21.0278537 },
    { lat: 48.7350528, lng: 21.0275306 },
    { lat: 48.7352691, lng: 21.027637 },
    { lat: 48.7353854, lng: 21.0276172 },
    { lat: 48.73581, lng: 21.0271446 },
    { lat: 48.7359942, lng: 21.0268569 },
    { lat: 48.7361347, lng: 21.0267493 },
    { lat: 48.7361643, lng: 21.0264868 },
    { lat: 48.736228, lng: 21.0263722 },
    { lat: 48.7366462, lng: 21.0261504 },
    { lat: 48.7367608, lng: 21.0262134 },
    { lat: 48.7370204, lng: 21.0259971 },
    { lat: 48.7372503, lng: 21.0261389 },
    { lat: 48.7376676, lng: 21.0253808 },
    { lat: 48.7377878, lng: 21.0253357 },
    { lat: 48.7381234, lng: 21.0248969 },
    { lat: 48.7383014, lng: 21.0247669 },
    { lat: 48.738347, lng: 21.0245092 },
    { lat: 48.7384764, lng: 21.0242709 },
    { lat: 48.7387167, lng: 21.0240831 },
    { lat: 48.7389216, lng: 21.0237076 },
    { lat: 48.7388975, lng: 21.0235225 },
    { lat: 48.7392295, lng: 21.0231467 },
    { lat: 48.7392014, lng: 21.0228721 },
    { lat: 48.7396394, lng: 21.0221919 },
    { lat: 48.7397713, lng: 21.0218946 },
    { lat: 48.7399234, lng: 21.0211753 },
    { lat: 48.7403561, lng: 21.0208517 },
    { lat: 48.7407189, lng: 21.0203166 },
    { lat: 48.7407526, lng: 21.0199432 },
    { lat: 48.7408387, lng: 21.019686 },
    { lat: 48.7411461, lng: 21.0192785 },
    { lat: 48.7412816, lng: 21.0189177 },
    { lat: 48.741891, lng: 21.0181429 },
    { lat: 48.7420109, lng: 21.0178889 },
    { lat: 48.7424227, lng: 21.0173358 },
    { lat: 48.7424905, lng: 21.0171378 },
    { lat: 48.7429803, lng: 21.016501 },
    { lat: 48.7429836, lng: 21.016494 },
    { lat: 48.7429048, lng: 21.0163926 },
    { lat: 48.7429223, lng: 21.016172 },
    { lat: 48.7428266, lng: 21.0161371 },
    { lat: 48.7426325, lng: 21.0158155 },
    { lat: 48.7425713, lng: 21.0158343 },
    { lat: 48.74245, lng: 21.0157023 },
    { lat: 48.7420843, lng: 21.0151624 },
    { lat: 48.7420358, lng: 21.0151586 },
    { lat: 48.7419071, lng: 21.0149476 },
    { lat: 48.7417713, lng: 21.014512 },
    { lat: 48.7415387, lng: 21.0142161 },
    { lat: 48.7415046, lng: 21.014035 },
    { lat: 48.7413509, lng: 21.0137799 },
    { lat: 48.7412754, lng: 21.0137774 },
    { lat: 48.7412827, lng: 21.0136927 },
    { lat: 48.7409477, lng: 21.0131894 },
    { lat: 48.7403378, lng: 21.0127825 },
    { lat: 48.739994, lng: 21.0124273 },
    { lat: 48.739663, lng: 21.0123595 },
    { lat: 48.7394866, lng: 21.0121617 },
    { lat: 48.7392564, lng: 21.0121474 },
    { lat: 48.7392127, lng: 21.0120811 },
    { lat: 48.7391209, lng: 21.0121308 },
    { lat: 48.7390358, lng: 21.0120435 },
    { lat: 48.7388723, lng: 21.0120358 },
    { lat: 48.7388472, lng: 21.0119539 },
    { lat: 48.7386323, lng: 21.0118528 },
    { lat: 48.7385695, lng: 21.0117238 },
    { lat: 48.7384535, lng: 21.0117237 },
    { lat: 48.738363, lng: 21.0116403 },
    { lat: 48.7382663, lng: 21.0114753 },
    { lat: 48.7382078, lng: 21.0109987 },
    { lat: 48.7377988, lng: 21.0098611 },
    { lat: 48.7377345, lng: 21.009821 },
    { lat: 48.7375452, lng: 21.0093735 },
    { lat: 48.7375031, lng: 21.0090839 },
    { lat: 48.7369743, lng: 21.0079008 },
    { lat: 48.73666, lng: 21.0075519 },
    { lat: 48.7363308, lng: 21.0070262 },
    { lat: 48.7358909, lng: 21.006925 },
    { lat: 48.7354649, lng: 21.0066748 },
    { lat: 48.735292, lng: 21.0061401 },
    { lat: 48.7348331, lng: 21.0054014 },
    { lat: 48.734652, lng: 21.0055449 },
    { lat: 48.7344624, lng: 21.0053228 },
    { lat: 48.7338814, lng: 21.0049453 },
    { lat: 48.7336707, lng: 21.0048984 },
    { lat: 48.7257528, lng: 21.0008693 },
    { lat: 48.7252385, lng: 21.0003602 },
    { lat: 48.7250031, lng: 21.0002533 },
    { lat: 48.7245594, lng: 21.0003146 },
    { lat: 48.7237943, lng: 21.0000174 },
    { lat: 48.7236582, lng: 21.000017 },
    { lat: 48.7226513, lng: 20.9993539 },
    { lat: 48.7211153, lng: 20.9987543 },
    { lat: 48.7200014, lng: 20.9981862 },
    { lat: 48.7198822, lng: 20.9983617 },
    { lat: 48.7194273, lng: 20.9983947 },
    { lat: 48.7193985, lng: 20.9984432 },
    { lat: 48.7191367, lng: 20.9983155 },
    { lat: 48.7190625, lng: 20.9982184 },
    { lat: 48.7186563, lng: 20.9980535 },
    { lat: 48.7183889, lng: 20.9980976 },
    { lat: 48.7181058, lng: 20.9982876 },
    { lat: 48.7178392, lng: 20.998157 },
    { lat: 48.717216, lng: 20.998027 },
    { lat: 48.717056, lng: 20.9981236 },
    { lat: 48.7167751, lng: 20.9981395 },
    { lat: 48.7167204, lng: 20.998056 },
    { lat: 48.7166216, lng: 20.9980638 },
    { lat: 48.7165443, lng: 20.9982436 },
    { lat: 48.7164183, lng: 20.9981405 },
    { lat: 48.7160712, lng: 20.9980937 },
    { lat: 48.7158412, lng: 20.9978171 },
    { lat: 48.7156823, lng: 20.9977396 },
    { lat: 48.7154015, lng: 20.9978935 },
    { lat: 48.7152112, lng: 20.9979232 },
    { lat: 48.7151812, lng: 20.9980046 },
    { lat: 48.7150358, lng: 20.997996 },
    { lat: 48.7149871, lng: 20.9979205 },
    { lat: 48.7149646, lng: 20.9979903 },
    { lat: 48.7148828, lng: 20.9979709 },
    { lat: 48.7148987, lng: 20.9980363 },
    { lat: 48.7147548, lng: 20.9980793 },
    { lat: 48.7146224, lng: 20.9979542 },
    { lat: 48.7146545, lng: 20.9979069 },
    { lat: 48.7146081, lng: 20.997658 },
    { lat: 48.7144629, lng: 20.9975902 },
    { lat: 48.7144106, lng: 20.9974424 },
    { lat: 48.7143175, lng: 20.9974512 },
    { lat: 48.7142465, lng: 20.997581 },
    { lat: 48.7140902, lng: 20.9975169 },
    { lat: 48.714047, lng: 20.9976452 },
    { lat: 48.7137172, lng: 20.9974353 },
    { lat: 48.7136394, lng: 20.9975734 },
    { lat: 48.7135559, lng: 20.9975313 },
    { lat: 48.7134807, lng: 20.9976389 },
    { lat: 48.7133654, lng: 20.9973835 },
    { lat: 48.7132904, lng: 20.9974338 },
    { lat: 48.7131258, lng: 20.9973215 },
    { lat: 48.7130477, lng: 20.9973762 },
    { lat: 48.7130467, lng: 20.9972761 },
    { lat: 48.7129222, lng: 20.997256 },
    { lat: 48.7128733, lng: 20.9971382 },
    { lat: 48.712787, lng: 20.9970816 },
    { lat: 48.7126833, lng: 20.997113 },
    { lat: 48.7125936, lng: 20.9969893 },
    { lat: 48.7124162, lng: 20.9969318 },
    { lat: 48.7119281, lng: 20.9971142 },
    { lat: 48.7117377, lng: 20.9973128 },
    { lat: 48.711719, lng: 20.9974946 },
    { lat: 48.7116422, lng: 20.9975549 },
    { lat: 48.7113055, lng: 20.9975501 },
    { lat: 48.7111892, lng: 20.9976438 },
    { lat: 48.7111094, lng: 20.9975753 },
    { lat: 48.7110959, lng: 20.99805 },
    { lat: 48.7094534, lng: 20.9983158 },
    { lat: 48.7094816, lng: 20.9986896 },
    { lat: 48.7087872, lng: 20.9991727 },
    { lat: 48.7085132, lng: 20.9992882 },
    { lat: 48.7086258, lng: 20.9981738 },
    { lat: 48.7081735, lng: 20.9980416 },
    { lat: 48.7074804, lng: 20.9979818 },
    { lat: 48.7073173, lng: 20.9970658 },
    { lat: 48.7068728, lng: 20.9972416 },
    { lat: 48.7068256, lng: 20.9969348 },
    { lat: 48.7062526, lng: 20.997136 },
    { lat: 48.7059498, lng: 20.9944944 },
    { lat: 48.7046156, lng: 20.9945819 },
    { lat: 48.7047301, lng: 20.9980983 },
    { lat: 48.70443, lng: 20.9981794 },
    { lat: 48.7043424, lng: 20.995502 },
    { lat: 48.7022778, lng: 20.9958703 },
    { lat: 48.702202, lng: 20.9943674 },
    { lat: 48.7010186, lng: 20.9943886 },
    { lat: 48.701015, lng: 20.994107 },
    { lat: 48.7000649, lng: 20.993937 },
    { lat: 48.700062, lng: 20.9957459 },
    { lat: 48.6996413, lng: 20.9957207 },
    { lat: 48.699582, lng: 20.9956682 },
    { lat: 48.6992064, lng: 20.9948272 },
    { lat: 48.6991521, lng: 20.9937656 },
    { lat: 48.6977375, lng: 20.9935072 },
    { lat: 48.6971513, lng: 20.9932052 },
    { lat: 48.6961283, lng: 20.9924287 },
    { lat: 48.6963387, lng: 20.9919898 },
    { lat: 48.6963064, lng: 20.9919602 },
    { lat: 48.6951087, lng: 20.9915222 },
    { lat: 48.6950956, lng: 20.9916301 },
    { lat: 48.694744, lng: 20.9915555 },
    { lat: 48.6933939, lng: 20.9914704 },
    { lat: 48.6926393, lng: 20.9911489 },
    { lat: 48.692063, lng: 20.9906411 },
    { lat: 48.6917019, lng: 20.9903802 },
    { lat: 48.6916555, lng: 20.9903748 },
    { lat: 48.6912027, lng: 20.9903981 },
    { lat: 48.6911374, lng: 20.9899876 },
    { lat: 48.6906985, lng: 20.9897548 },
    { lat: 48.6894839, lng: 20.9903951 },
    { lat: 48.6873183, lng: 20.9916839 },
    { lat: 48.6865753, lng: 20.9924925 },
    { lat: 48.6861163, lng: 20.9928678 },
    { lat: 48.6859671, lng: 20.9930572 },
    { lat: 48.6852722, lng: 20.9935875 },
    { lat: 48.6848418, lng: 20.9941858 },
    { lat: 48.6840105, lng: 20.9956248 },
    { lat: 48.6830308, lng: 20.99717 },
    { lat: 48.6826958, lng: 20.9978946 },
    { lat: 48.6824766, lng: 20.998552 },
    { lat: 48.6822178, lng: 20.9990631 },
    { lat: 48.6820111, lng: 20.9993455 },
    { lat: 48.6815018, lng: 20.9998702 },
    { lat: 48.6799417, lng: 21.0008907 },
    { lat: 48.6796006, lng: 21.0010543 },
    { lat: 48.6784706, lng: 21.0024212 },
    { lat: 48.6780808, lng: 21.0028143 },
    { lat: 48.6769321, lng: 21.0037116 },
    { lat: 48.675778, lng: 21.0054695 },
    { lat: 48.6753892, lng: 21.0059995 },
    { lat: 48.6748607, lng: 21.006559 },
    { lat: 48.6743855, lng: 21.007508 },
    { lat: 48.6740502, lng: 21.008288 },
    { lat: 48.6739717, lng: 21.0085756 },
    { lat: 48.6737519, lng: 21.0101314 },
    { lat: 48.6735912, lng: 21.0123194 },
    { lat: 48.6735113, lng: 21.0127909 },
    { lat: 48.6730122, lng: 21.014383 },
    { lat: 48.6721399, lng: 21.0166937 },
    { lat: 48.671815, lng: 21.0181779 },
    { lat: 48.6716783, lng: 21.0192529 },
    { lat: 48.6716207, lng: 21.0197216 },
    { lat: 48.670893, lng: 21.0213447 },
    { lat: 48.6699627, lng: 21.0231032 },
    { lat: 48.6691509, lng: 21.0242197 },
    { lat: 48.6687551, lng: 21.024848 },
    { lat: 48.6685975, lng: 21.0253274 },
    { lat: 48.668187, lng: 21.0260187 },
    { lat: 48.667671, lng: 21.0267237 },
    { lat: 48.6667423, lng: 21.0278435 },
    { lat: 48.6661544, lng: 21.0284488 },
    { lat: 48.6653767, lng: 21.0288631 },
    { lat: 48.6646292, lng: 21.0295786 },
    { lat: 48.6639896, lng: 21.0300827 },
    { lat: 48.6624267, lng: 21.0315703 },
    { lat: 48.6620629, lng: 21.0320045 },
    { lat: 48.6615541, lng: 21.0324834 },
    { lat: 48.6612906, lng: 21.0328279 },
    { lat: 48.6610694, lng: 21.0329769 },
    { lat: 48.6603878, lng: 21.0336749 },
    { lat: 48.6599209, lng: 21.0344027 },
    { lat: 48.6596856, lng: 21.0346727 },
    { lat: 48.6588987, lng: 21.0352874 },
    { lat: 48.6585828, lng: 21.0356076 },
    { lat: 48.6584655, lng: 21.0360147 },
  ],
  Opiná: [
    { lat: 48.874669, lng: 21.412254 },
    { lat: 48.8744444, lng: 21.412182 },
    { lat: 48.8737591, lng: 21.4106516 },
    { lat: 48.8704338, lng: 21.4004294 },
    { lat: 48.8700723, lng: 21.3991597 },
    { lat: 48.8689995, lng: 21.3962917 },
    { lat: 48.8684789, lng: 21.3951633 },
    { lat: 48.8678459, lng: 21.3977308 },
    { lat: 48.8675058, lng: 21.398898 },
    { lat: 48.8669953, lng: 21.4010772 },
    { lat: 48.8668777, lng: 21.4018186 },
    { lat: 48.8668678, lng: 21.4023628 },
    { lat: 48.8667837, lng: 21.4030584 },
    { lat: 48.8666566, lng: 21.4038593 },
    { lat: 48.8665691, lng: 21.4040576 },
    { lat: 48.8664042, lng: 21.404726 },
    { lat: 48.8661959, lng: 21.4052159 },
    { lat: 48.8646041, lng: 21.4077101 },
    { lat: 48.8639623, lng: 21.4085352 },
    { lat: 48.8633856, lng: 21.4095042 },
    { lat: 48.8624722, lng: 21.4105832 },
    { lat: 48.8615962, lng: 21.4114001 },
    { lat: 48.8598947, lng: 21.4133096 },
    { lat: 48.8587177, lng: 21.4151398 },
    { lat: 48.8581652, lng: 21.415522 },
    { lat: 48.857811, lng: 21.4156071 },
    { lat: 48.8574213, lng: 21.4155548 },
    { lat: 48.8567654, lng: 21.4152522 },
    { lat: 48.856295, lng: 21.41514 },
    { lat: 48.8560566, lng: 21.4150293 },
    { lat: 48.855659, lng: 21.4147049 },
    { lat: 48.8530694, lng: 21.4113312 },
    { lat: 48.8469073, lng: 21.4039291 },
    { lat: 48.8451559, lng: 21.4015121 },
    { lat: 48.8446011, lng: 21.4006681 },
    { lat: 48.8426934, lng: 21.3981069 },
    { lat: 48.8423959, lng: 21.3975598 },
    { lat: 48.8412082, lng: 21.3961023 },
    { lat: 48.8411287, lng: 21.3963774 },
    { lat: 48.8410196, lng: 21.3967567 },
    { lat: 48.8410007, lng: 21.3974888 },
    { lat: 48.840746, lng: 21.398536 },
    { lat: 48.8403334, lng: 21.3993517 },
    { lat: 48.8400851, lng: 21.4001652 },
    { lat: 48.8400092, lng: 21.4009076 },
    { lat: 48.8400208, lng: 21.4013369 },
    { lat: 48.8399892, lng: 21.40236 },
    { lat: 48.8404912, lng: 21.4041142 },
    { lat: 48.840598, lng: 21.4048382 },
    { lat: 48.8416738, lng: 21.4076617 },
    { lat: 48.8420673, lng: 21.4083334 },
    { lat: 48.8422101, lng: 21.4082187 },
    { lat: 48.8426379, lng: 21.4090585 },
    { lat: 48.8433656, lng: 21.4089721 },
    { lat: 48.8438358, lng: 21.4091256 },
    { lat: 48.843863, lng: 21.4095103 },
    { lat: 48.8437207, lng: 21.4101787 },
    { lat: 48.8437213, lng: 21.4106545 },
    { lat: 48.8446505, lng: 21.410308 },
    { lat: 48.8447124, lng: 21.4104192 },
    { lat: 48.8449205, lng: 21.4099271 },
    { lat: 48.8452265, lng: 21.4101075 },
    { lat: 48.8450792, lng: 21.4107262 },
    { lat: 48.8444804, lng: 21.411179 },
    { lat: 48.8439492, lng: 21.4117476 },
    { lat: 48.8442572, lng: 21.4125017 },
    { lat: 48.8444983, lng: 21.4128887 },
    { lat: 48.8449967, lng: 21.4141907 },
    { lat: 48.8444348, lng: 21.4150165 },
    { lat: 48.8442868, lng: 21.4154498 },
    { lat: 48.8442319, lng: 21.4159969 },
    { lat: 48.8440402, lng: 21.4165879 },
    { lat: 48.8440566, lng: 21.4168815 },
    { lat: 48.8439116, lng: 21.4176901 },
    { lat: 48.8439412, lng: 21.4180067 },
    { lat: 48.844138, lng: 21.4181174 },
    { lat: 48.844401, lng: 21.4187681 },
    { lat: 48.8445551, lng: 21.4186538 },
    { lat: 48.8448195, lng: 21.4184548 },
    { lat: 48.8451462, lng: 21.4183148 },
    { lat: 48.8454105, lng: 21.418206 },
    { lat: 48.8458279, lng: 21.4182758 },
    { lat: 48.8460388, lng: 21.4184074 },
    { lat: 48.8462113, lng: 21.4185351 },
    { lat: 48.8463575, lng: 21.4190644 },
    { lat: 48.8466289, lng: 21.4196166 },
    { lat: 48.8469779, lng: 21.4198296 },
    { lat: 48.8470016, lng: 21.4216421 },
    { lat: 48.8465823, lng: 21.4217368 },
    { lat: 48.8465752, lng: 21.4230409 },
    { lat: 48.8477276, lng: 21.4239543 },
    { lat: 48.8499883, lng: 21.4258316 },
    { lat: 48.8505779, lng: 21.4264443 },
    { lat: 48.8505331, lng: 21.4265259 },
    { lat: 48.8505659, lng: 21.4281983 },
    { lat: 48.851265, lng: 21.4280114 },
    { lat: 48.8514704, lng: 21.4303608 },
    { lat: 48.8528145, lng: 21.4320379 },
    { lat: 48.852808, lng: 21.4322359 },
    { lat: 48.8534297, lng: 21.4341018 },
    { lat: 48.853636, lng: 21.4340772 },
    { lat: 48.8537279, lng: 21.434374 },
    { lat: 48.8539655, lng: 21.4347979 },
    { lat: 48.8539667, lng: 21.4351833 },
    { lat: 48.8541575, lng: 21.4354049 },
    { lat: 48.8543239, lng: 21.435934 },
    { lat: 48.8544504, lng: 21.4361381 },
    { lat: 48.8546531, lng: 21.4362227 },
    { lat: 48.854808, lng: 21.4364013 },
    { lat: 48.8549076, lng: 21.4363868 },
    { lat: 48.8550188, lng: 21.4369567 },
    { lat: 48.8553547, lng: 21.4373236 },
    { lat: 48.8554906, lng: 21.437699 },
    { lat: 48.855655, lng: 21.4377826 },
    { lat: 48.8557376, lng: 21.4377585 },
    { lat: 48.8558919, lng: 21.4379579 },
    { lat: 48.8559634, lng: 21.4382389 },
    { lat: 48.8559583, lng: 21.4386048 },
    { lat: 48.8560899, lng: 21.4386081 },
    { lat: 48.8562285, lng: 21.4387275 },
    { lat: 48.8563372, lng: 21.4389056 },
    { lat: 48.8566278, lng: 21.439183 },
    { lat: 48.8570979, lng: 21.4391404 },
    { lat: 48.8573132, lng: 21.4393814 },
    { lat: 48.8575064, lng: 21.4396054 },
    { lat: 48.8576446, lng: 21.4402518 },
    { lat: 48.857926, lng: 21.440641 },
    { lat: 48.8582565, lng: 21.4408965 },
    { lat: 48.8585186, lng: 21.4409747 },
    { lat: 48.8590139, lng: 21.4416343 },
    { lat: 48.859522, lng: 21.4419636 },
    { lat: 48.8596474, lng: 21.4422132 },
    { lat: 48.8596437, lng: 21.4423911 },
    { lat: 48.8597618, lng: 21.4425458 },
    { lat: 48.8598787, lng: 21.4424311 },
    { lat: 48.8599753, lng: 21.4425021 },
    { lat: 48.8599812, lng: 21.4426218 },
    { lat: 48.8600768, lng: 21.4427791 },
    { lat: 48.8602371, lng: 21.4428337 },
    { lat: 48.8603122, lng: 21.4430885 },
    { lat: 48.8604641, lng: 21.4433259 },
    { lat: 48.8606321, lng: 21.4434894 },
    { lat: 48.8607487, lng: 21.4434694 },
    { lat: 48.8608886, lng: 21.444258 },
    { lat: 48.8610797, lng: 21.444445 },
    { lat: 48.8615515, lng: 21.444493 },
    { lat: 48.8616999, lng: 21.4446435 },
    { lat: 48.8618165, lng: 21.4448648 },
    { lat: 48.8619196, lng: 21.444884 },
    { lat: 48.8621415, lng: 21.4447394 },
    { lat: 48.8622578, lng: 21.4448288 },
    { lat: 48.8624743, lng: 21.4457356 },
    { lat: 48.8625729, lng: 21.445755 },
    { lat: 48.8626172, lng: 21.4459247 },
    { lat: 48.862775, lng: 21.4460874 },
    { lat: 48.8629773, lng: 21.4466196 },
    { lat: 48.8632146, lng: 21.4467741 },
    { lat: 48.8634575, lng: 21.4472335 },
    { lat: 48.8634219, lng: 21.4478667 },
    { lat: 48.8632203, lng: 21.4482595 },
    { lat: 48.8631505, lng: 21.4492085 },
    { lat: 48.863204, lng: 21.449521 },
    { lat: 48.8632715, lng: 21.4497272 },
    { lat: 48.8632766, lng: 21.450065 },
    { lat: 48.8633584, lng: 21.4502417 },
    { lat: 48.8636519, lng: 21.4503926 },
    { lat: 48.8637343, lng: 21.4505189 },
    { lat: 48.8637893, lng: 21.4506671 },
    { lat: 48.8637413, lng: 21.4507904 },
    { lat: 48.8638095, lng: 21.4509724 },
    { lat: 48.8637919, lng: 21.4511373 },
    { lat: 48.8639932, lng: 21.4516759 },
    { lat: 48.8642013, lng: 21.4518675 },
    { lat: 48.8642719, lng: 21.4523245 },
    { lat: 48.8644831, lng: 21.4529715 },
    { lat: 48.8647623, lng: 21.4532084 },
    { lat: 48.8648877, lng: 21.4536521 },
    { lat: 48.8650398, lng: 21.4537749 },
    { lat: 48.8652902, lng: 21.4545998 },
    { lat: 48.8652543, lng: 21.4548413 },
    { lat: 48.8653255, lng: 21.4550831 },
    { lat: 48.865803, lng: 21.4554407 },
    { lat: 48.8659757, lng: 21.456273 },
    { lat: 48.8663333, lng: 21.4565765 },
    { lat: 48.8664713, lng: 21.4568721 },
    { lat: 48.866878, lng: 21.4571627 },
    { lat: 48.8670394, lng: 21.457491 },
    { lat: 48.8673472, lng: 21.4577053 },
    { lat: 48.8676026, lng: 21.4586321 },
    { lat: 48.8677269, lng: 21.4589166 },
    { lat: 48.8678297, lng: 21.4597131 },
    { lat: 48.8678319, lng: 21.4602119 },
    { lat: 48.8679498, lng: 21.460941 },
    { lat: 48.867922, lng: 21.4612581 },
    { lat: 48.8679746, lng: 21.4618354 },
    { lat: 48.8681137, lng: 21.4628697 },
    { lat: 48.8680944, lng: 21.4637137 },
    { lat: 48.8682669, lng: 21.464036 },
    { lat: 48.8682397, lng: 21.4645304 },
    { lat: 48.8681944, lng: 21.4646763 },
    { lat: 48.8682239, lng: 21.4648073 },
    { lat: 48.8679446, lng: 21.465411 },
    { lat: 48.867888, lng: 21.4656692 },
    { lat: 48.8679526, lng: 21.4660788 },
    { lat: 48.8678325, lng: 21.4664742 },
    { lat: 48.8674734, lng: 21.4672054 },
    { lat: 48.8672549, lng: 21.4679798 },
    { lat: 48.8674547, lng: 21.4697499 },
    { lat: 48.8674708, lng: 21.4702639 },
    { lat: 48.8676257, lng: 21.4709894 },
    { lat: 48.8678454, lng: 21.4714291 },
    { lat: 48.867838, lng: 21.4716368 },
    { lat: 48.8679517, lng: 21.4720682 },
    { lat: 48.8679207, lng: 21.4724737 },
    { lat: 48.8679846, lng: 21.4729305 },
    { lat: 48.8680815, lng: 21.4732865 },
    { lat: 48.8683706, lng: 21.4736708 },
    { lat: 48.8687533, lng: 21.4737547 },
    { lat: 48.8690421, lng: 21.4736633 },
    { lat: 48.8697603, lng: 21.4735984 },
    { lat: 48.8699816, lng: 21.4735072 },
    { lat: 48.8700948, lng: 21.4735326 },
    { lat: 48.870074, lng: 21.4740183 },
    { lat: 48.8697599, lng: 21.475299 },
    { lat: 48.8698328, lng: 21.4757963 },
    { lat: 48.8700508, lng: 21.4758998 },
    { lat: 48.869867, lng: 21.4765886 },
    { lat: 48.8694191, lng: 21.4791531 },
    { lat: 48.8692059, lng: 21.4799203 },
    { lat: 48.8688086, lng: 21.4819792 },
    { lat: 48.8686901, lng: 21.4834102 },
    { lat: 48.8687704, lng: 21.4840858 },
    { lat: 48.8688883, lng: 21.484229 },
    { lat: 48.869182, lng: 21.484241 },
    { lat: 48.869871, lng: 21.484507 },
    { lat: 48.870632, lng: 21.484381 },
    { lat: 48.870897, lng: 21.4844 },
    { lat: 48.871521, lng: 21.484856 },
    { lat: 48.871722, lng: 21.485015 },
    { lat: 48.871995, lng: 21.485056 },
    { lat: 48.872565, lng: 21.485172 },
    { lat: 48.872921, lng: 21.485235 },
    { lat: 48.87366, lng: 21.485344 },
    { lat: 48.874663, lng: 21.485118 },
    { lat: 48.875403, lng: 21.484979 },
    { lat: 48.875854, lng: 21.484569 },
    { lat: 48.876516, lng: 21.48446 },
    { lat: 48.877708, lng: 21.48427 },
    { lat: 48.877964, lng: 21.484047 },
    { lat: 48.878325, lng: 21.483809 },
    { lat: 48.878666, lng: 21.4838 },
    { lat: 48.878974, lng: 21.483841 },
    { lat: 48.87939, lng: 21.484106 },
    { lat: 48.879442, lng: 21.484124 },
    { lat: 48.879529, lng: 21.484136 },
    { lat: 48.879882, lng: 21.483849 },
    { lat: 48.88009, lng: 21.483784 },
    { lat: 48.880668, lng: 21.483835 },
    { lat: 48.88127, lng: 21.483896 },
    { lat: 48.88131, lng: 21.483908 },
    { lat: 48.88158, lng: 21.483948 },
    { lat: 48.882269, lng: 21.483941 },
    { lat: 48.88252, lng: 21.482456 },
    { lat: 48.882659, lng: 21.481064 },
    { lat: 48.882811, lng: 21.479949 },
    { lat: 48.882514, lng: 21.478042 },
    { lat: 48.882517, lng: 21.477512 },
    { lat: 48.882817, lng: 21.476117 },
    { lat: 48.883075, lng: 21.475547 },
    { lat: 48.882764, lng: 21.474533 },
    { lat: 48.882309, lng: 21.473502 },
    { lat: 48.882201, lng: 21.473156 },
    { lat: 48.882007, lng: 21.472853 },
    { lat: 48.881838, lng: 21.472531 },
    { lat: 48.881643, lng: 21.472061 },
    { lat: 48.881408, lng: 21.471827 },
    { lat: 48.881125, lng: 21.471468 },
    { lat: 48.880878, lng: 21.471244 },
    { lat: 48.88033, lng: 21.470443 },
    { lat: 48.880305, lng: 21.470407 },
    { lat: 48.880169, lng: 21.470164 },
    { lat: 48.880378, lng: 21.469669 },
    { lat: 48.880435, lng: 21.469152 },
    { lat: 48.880485, lng: 21.46902 },
    { lat: 48.880546, lng: 21.468953 },
    { lat: 48.880656, lng: 21.468474 },
    { lat: 48.88082, lng: 21.468097 },
    { lat: 48.88082, lng: 21.467623 },
    { lat: 48.880786, lng: 21.467458 },
    { lat: 48.880831, lng: 21.467301 },
    { lat: 48.880804, lng: 21.467094 },
    { lat: 48.88069, lng: 21.466941 },
    { lat: 48.880627, lng: 21.466704 },
    { lat: 48.880665, lng: 21.466362 },
    { lat: 48.880631, lng: 21.466051 },
    { lat: 48.880772, lng: 21.465799 },
    { lat: 48.880723, lng: 21.465497 },
    { lat: 48.880828, lng: 21.465234 },
    { lat: 48.880678, lng: 21.464656 },
    { lat: 48.880674, lng: 21.464165 },
    { lat: 48.88059, lng: 21.464084 },
    { lat: 48.880564, lng: 21.463519 },
    { lat: 48.880515, lng: 21.463463 },
    { lat: 48.8805, lng: 21.463298 },
    { lat: 48.880672, lng: 21.462925 },
    { lat: 48.880664, lng: 21.462831 },
    { lat: 48.880479, lng: 21.462477 },
    { lat: 48.88046, lng: 21.462292 },
    { lat: 48.880395, lng: 21.462205 },
    { lat: 48.880329, lng: 21.462088 },
    { lat: 48.880092, lng: 21.462036 },
    { lat: 48.879852, lng: 21.461771 },
    { lat: 48.87965, lng: 21.461517 },
    { lat: 48.879717, lng: 21.461009 },
    { lat: 48.879656, lng: 21.460958 },
    { lat: 48.879377, lng: 21.461337 },
    { lat: 48.87896, lng: 21.461735 },
    { lat: 48.878941, lng: 21.461809 },
    { lat: 48.878768, lng: 21.461901 },
    { lat: 48.878762, lng: 21.462043 },
    { lat: 48.878688, lng: 21.462114 },
    { lat: 48.878653, lng: 21.462179 },
    { lat: 48.878513, lng: 21.462263 },
    { lat: 48.87834, lng: 21.462505 },
    { lat: 48.878002, lng: 21.462703 },
    { lat: 48.877781, lng: 21.462909 },
    { lat: 48.87772, lng: 21.463079 },
    { lat: 48.87769, lng: 21.463162 },
    { lat: 48.877599, lng: 21.463251 },
    { lat: 48.877132, lng: 21.463711 },
    { lat: 48.876964, lng: 21.463809 },
    { lat: 48.876745, lng: 21.463985 },
    { lat: 48.876734, lng: 21.464076 },
    { lat: 48.876616, lng: 21.464171 },
    { lat: 48.876392, lng: 21.464584 },
    { lat: 48.87613, lng: 21.46478 },
    { lat: 48.876102, lng: 21.464696 },
    { lat: 48.875979, lng: 21.464334 },
    { lat: 48.875698, lng: 21.46349 },
    { lat: 48.87559, lng: 21.463027 },
    { lat: 48.875482, lng: 21.462804 },
    { lat: 48.875348, lng: 21.462352 },
    { lat: 48.875001, lng: 21.461399 },
    { lat: 48.874777, lng: 21.460686 },
    { lat: 48.874668, lng: 21.460411 },
    { lat: 48.874541, lng: 21.460036 },
    { lat: 48.874514, lng: 21.459834 },
    { lat: 48.874051, lng: 21.458743 },
    { lat: 48.873898, lng: 21.458463 },
    { lat: 48.873897, lng: 21.458457 },
    { lat: 48.873742, lng: 21.457612 },
    { lat: 48.873823, lng: 21.457245 },
    { lat: 48.873437, lng: 21.457069 },
    { lat: 48.87292, lng: 21.45531 },
    { lat: 48.873023, lng: 21.454479 },
    { lat: 48.872976, lng: 21.454445 },
    { lat: 48.87238, lng: 21.454005 },
    { lat: 48.872295, lng: 21.453887 },
    { lat: 48.87235, lng: 21.453317 },
    { lat: 48.872196, lng: 21.453217 },
    { lat: 48.872207, lng: 21.453059 },
    { lat: 48.872098, lng: 21.453018 },
    { lat: 48.872037, lng: 21.452667 },
    { lat: 48.871905, lng: 21.452246 },
    { lat: 48.871741, lng: 21.451782 },
    { lat: 48.871714, lng: 21.451681 },
    { lat: 48.871507, lng: 21.450929 },
    { lat: 48.871441, lng: 21.450174 },
    { lat: 48.871368, lng: 21.449014 },
    { lat: 48.871438, lng: 21.448489 },
    { lat: 48.871419, lng: 21.448432 },
    { lat: 48.871506, lng: 21.447209 },
    { lat: 48.871369, lng: 21.44677 },
    { lat: 48.871434, lng: 21.446102 },
    { lat: 48.871443, lng: 21.44576 },
    { lat: 48.871492, lng: 21.445539 },
    { lat: 48.871476, lng: 21.44393 },
    { lat: 48.871431, lng: 21.442979 },
    { lat: 48.871464, lng: 21.442726 },
    { lat: 48.871462, lng: 21.442715 },
    { lat: 48.871432, lng: 21.442249 },
    { lat: 48.871474, lng: 21.441656 },
    { lat: 48.87151, lng: 21.440785 },
    { lat: 48.87153, lng: 21.440408 },
    { lat: 48.871882, lng: 21.440712 },
    { lat: 48.872109, lng: 21.440765 },
    { lat: 48.87224, lng: 21.440696 },
    { lat: 48.8724, lng: 21.440761 },
    { lat: 48.872643, lng: 21.441198 },
    { lat: 48.872778, lng: 21.441509 },
    { lat: 48.872825, lng: 21.441878 },
    { lat: 48.872912, lng: 21.441954 },
    { lat: 48.873105, lng: 21.442007 },
    { lat: 48.873393, lng: 21.442138 },
    { lat: 48.873578, lng: 21.442374 },
    { lat: 48.873727, lng: 21.442536 },
    { lat: 48.874039, lng: 21.442632 },
    { lat: 48.874137, lng: 21.442558 },
    { lat: 48.874066, lng: 21.442049 },
    { lat: 48.873893, lng: 21.441758 },
    { lat: 48.873857, lng: 21.441585 },
    { lat: 48.873962, lng: 21.441226 },
    { lat: 48.873941, lng: 21.44101 },
    { lat: 48.873895, lng: 21.440838 },
    { lat: 48.873882, lng: 21.440787 },
    { lat: 48.873836, lng: 21.44061 },
    { lat: 48.873834, lng: 21.440603 },
    { lat: 48.873655, lng: 21.440115 },
    { lat: 48.873547, lng: 21.439949 },
    { lat: 48.873587, lng: 21.439849 },
    { lat: 48.873802, lng: 21.439591 },
    { lat: 48.873832, lng: 21.439555 },
    { lat: 48.87392, lng: 21.439606 },
    { lat: 48.87444, lng: 21.43996 },
    { lat: 48.87503, lng: 21.44037 },
    { lat: 48.875106, lng: 21.440565 },
    { lat: 48.875225, lng: 21.440854 },
    { lat: 48.875387, lng: 21.440814 },
    { lat: 48.875472, lng: 21.440785 },
    { lat: 48.875628, lng: 21.44073 },
    { lat: 48.875676, lng: 21.440713 },
    { lat: 48.875762, lng: 21.440684 },
    { lat: 48.875825, lng: 21.440801 },
    { lat: 48.875961, lng: 21.440957 },
    { lat: 48.876146, lng: 21.441034 },
    { lat: 48.876346, lng: 21.441002 },
    { lat: 48.876683, lng: 21.440795 },
    { lat: 48.876775, lng: 21.440725 },
    { lat: 48.876835, lng: 21.440513 },
    { lat: 48.876851, lng: 21.440441 },
    { lat: 48.876883, lng: 21.440301 },
    { lat: 48.876204, lng: 21.439678 },
    { lat: 48.876032, lng: 21.439444 },
    { lat: 48.875809, lng: 21.439089 },
    { lat: 48.875709, lng: 21.439102 },
    { lat: 48.87546, lng: 21.439273 },
    { lat: 48.875297, lng: 21.439354 },
    { lat: 48.87509, lng: 21.439149 },
    { lat: 48.874935, lng: 21.438928 },
    { lat: 48.874846, lng: 21.438668 },
    { lat: 48.874632, lng: 21.438102 },
    { lat: 48.874544, lng: 21.437897 },
    { lat: 48.874479, lng: 21.437623 },
    { lat: 48.874432, lng: 21.437451 },
    { lat: 48.87441, lng: 21.436738 },
    { lat: 48.8742, lng: 21.435982 },
    { lat: 48.873752, lng: 21.436348 },
    { lat: 48.873244, lng: 21.436827 },
    { lat: 48.873089, lng: 21.436173 },
    { lat: 48.872831, lng: 21.435281 },
    { lat: 48.872675, lng: 21.434963 },
    { lat: 48.872612, lng: 21.434587 },
    { lat: 48.872613, lng: 21.434581 },
    { lat: 48.872631, lng: 21.434461 },
    { lat: 48.872739, lng: 21.434297 },
    { lat: 48.873019, lng: 21.43407 },
    { lat: 48.873362, lng: 21.433843 },
    { lat: 48.873716, lng: 21.433671 },
    { lat: 48.874154, lng: 21.433521 },
    { lat: 48.874326, lng: 21.433554 },
    { lat: 48.874421, lng: 21.433485 },
    { lat: 48.874469, lng: 21.433449 },
    { lat: 48.87462, lng: 21.433339 },
    { lat: 48.874601, lng: 21.432742 },
    { lat: 48.874626, lng: 21.432384 },
    { lat: 48.874697, lng: 21.432138 },
    { lat: 48.874675, lng: 21.431496 },
    { lat: 48.874486, lng: 21.431556 },
    { lat: 48.874458, lng: 21.43141 },
    { lat: 48.873802, lng: 21.431594 },
    { lat: 48.873819, lng: 21.431192 },
    { lat: 48.87335, lng: 21.431131 },
    { lat: 48.873063, lng: 21.431148 },
    { lat: 48.872948, lng: 21.430119 },
    { lat: 48.873025, lng: 21.429918 },
    { lat: 48.873406, lng: 21.42958 },
    { lat: 48.873546, lng: 21.429217 },
    { lat: 48.873546, lng: 21.429216 },
    { lat: 48.873796, lng: 21.428687 },
    { lat: 48.87397, lng: 21.42849 },
    { lat: 48.874305, lng: 21.428331 },
    { lat: 48.874447, lng: 21.42832 },
    { lat: 48.874656, lng: 21.42791 },
    { lat: 48.874974, lng: 21.427825 },
    { lat: 48.875473, lng: 21.427919 },
    { lat: 48.875512, lng: 21.427927 },
    { lat: 48.875638, lng: 21.42795 },
    { lat: 48.876124, lng: 21.427831 },
    { lat: 48.876773, lng: 21.427569 },
    { lat: 48.877183, lng: 21.427375 },
    { lat: 48.877594, lng: 21.427359 },
    { lat: 48.878365, lng: 21.427385 },
    { lat: 48.878621, lng: 21.427351 },
    { lat: 48.87878, lng: 21.42594 },
    { lat: 48.878834, lng: 21.425875 },
    { lat: 48.879876, lng: 21.425629 },
    { lat: 48.879845, lng: 21.425383 },
    { lat: 48.88019, lng: 21.425231 },
    { lat: 48.880695, lng: 21.425057 },
    { lat: 48.880934, lng: 21.424933 },
    { lat: 48.881393, lng: 21.424838 },
    { lat: 48.881398, lng: 21.424808 },
    { lat: 48.882179, lng: 21.42471 },
    { lat: 48.882665, lng: 21.424547 },
    { lat: 48.883295, lng: 21.424385 },
    { lat: 48.883659, lng: 21.42425 },
    { lat: 48.88422, lng: 21.424114 },
    { lat: 48.884922, lng: 21.424031 },
    { lat: 48.88598, lng: 21.42386 },
    { lat: 48.886145, lng: 21.423908 },
    { lat: 48.886739, lng: 21.424258 },
    { lat: 48.886822, lng: 21.424253 },
    { lat: 48.88683, lng: 21.424276 },
    { lat: 48.886875, lng: 21.424275 },
    { lat: 48.886931, lng: 21.424275 },
    { lat: 48.886999, lng: 21.424273 },
    { lat: 48.886987, lng: 21.424253 },
    { lat: 48.887099, lng: 21.424243 },
    { lat: 48.887831, lng: 21.424028 },
    { lat: 48.887968, lng: 21.423919 },
    { lat: 48.888119, lng: 21.423889 },
    { lat: 48.88826, lng: 21.42388 },
    { lat: 48.888454, lng: 21.423944 },
    { lat: 48.888768, lng: 21.424118 },
    { lat: 48.88895, lng: 21.42415 },
    { lat: 48.889676, lng: 21.424146 },
    { lat: 48.890719, lng: 21.423987 },
    { lat: 48.891487, lng: 21.423791 },
    { lat: 48.892002, lng: 21.423683 },
    { lat: 48.892532, lng: 21.423572 },
    { lat: 48.892531, lng: 21.42356 },
    { lat: 48.892531, lng: 21.423553 },
    { lat: 48.892446, lng: 21.420955 },
    { lat: 48.89352, lng: 21.420767 },
    { lat: 48.893771, lng: 21.420691 },
    { lat: 48.894163, lng: 21.420533 },
    { lat: 48.89414, lng: 21.420335 },
    { lat: 48.893989, lng: 21.420385 },
    { lat: 48.893897, lng: 21.420249 },
    { lat: 48.893928, lng: 21.420151 },
    { lat: 48.893992, lng: 21.419938 },
    { lat: 48.894072, lng: 21.419679 },
    { lat: 48.894033, lng: 21.419529 },
    { lat: 48.893465, lng: 21.419646 },
    { lat: 48.893356, lng: 21.419951 },
    { lat: 48.892553, lng: 21.420247 },
    { lat: 48.891802, lng: 21.420427 },
    { lat: 48.891724, lng: 21.420263 },
    { lat: 48.891853, lng: 21.42 },
    { lat: 48.89198, lng: 21.419899 },
    { lat: 48.891915, lng: 21.419784 },
    { lat: 48.891616, lng: 21.419781 },
    { lat: 48.890831, lng: 21.420018 },
    { lat: 48.890155, lng: 21.419956 },
    { lat: 48.889842, lng: 21.420248 },
    { lat: 48.889486, lng: 21.420056 },
    { lat: 48.889187, lng: 21.419895 },
    { lat: 48.889164, lng: 21.419882 },
    { lat: 48.889034, lng: 21.419812 },
    { lat: 48.888866, lng: 21.41973 },
    { lat: 48.888687, lng: 21.419684 },
    { lat: 48.888186, lng: 21.419401 },
    { lat: 48.888071, lng: 21.419277 },
    { lat: 48.887991, lng: 21.419189 },
    { lat: 48.887927, lng: 21.419117 },
    { lat: 48.887911, lng: 21.419094 },
    { lat: 48.887892, lng: 21.419067 },
    { lat: 48.887828, lng: 21.418995 },
    { lat: 48.887724, lng: 21.418884 },
    { lat: 48.887394, lng: 21.418777 },
    { lat: 48.886455, lng: 21.418717 },
    { lat: 48.886194, lng: 21.418701 },
    { lat: 48.886062, lng: 21.418291 },
    { lat: 48.885299, lng: 21.418398 },
    { lat: 48.884725, lng: 21.418609 },
    { lat: 48.884652, lng: 21.418631 },
    { lat: 48.884552, lng: 21.418659 },
    { lat: 48.884499, lng: 21.418689 },
    { lat: 48.884319, lng: 21.417966 },
    { lat: 48.884256, lng: 21.417789 },
    { lat: 48.884219, lng: 21.417734 },
    { lat: 48.88344, lng: 21.416538 },
    { lat: 48.883391, lng: 21.416461 },
    { lat: 48.88336, lng: 21.416028 },
    { lat: 48.883359, lng: 21.415993 },
    { lat: 48.883357, lng: 21.415901 },
    { lat: 48.883352, lng: 21.415672 },
    { lat: 48.88322, lng: 21.415768 },
    { lat: 48.882559, lng: 21.416245 },
    { lat: 48.882386, lng: 21.416461 },
    { lat: 48.882284, lng: 21.416607 },
    { lat: 48.882194, lng: 21.417016 },
    { lat: 48.881898, lng: 21.41711 },
    { lat: 48.881603, lng: 21.417208 },
    { lat: 48.881421, lng: 21.416995 },
    { lat: 48.881186, lng: 21.416869 },
    { lat: 48.880896, lng: 21.416981 },
    { lat: 48.880836, lng: 21.417154 },
    { lat: 48.880814, lng: 21.417214 },
    { lat: 48.880527, lng: 21.417159 },
    { lat: 48.880186, lng: 21.417611 },
    { lat: 48.88001, lng: 21.41753 },
    { lat: 48.879815, lng: 21.417669 },
    { lat: 48.879789, lng: 21.417716 },
    { lat: 48.879694, lng: 21.417893 },
    { lat: 48.879508, lng: 21.417854 },
    { lat: 48.879346, lng: 21.417727 },
    { lat: 48.878701, lng: 21.417941 },
    { lat: 48.878666, lng: 21.417884 },
    { lat: 48.878643, lng: 21.417844 },
    { lat: 48.878617, lng: 21.417802 },
    { lat: 48.878285, lng: 21.417537 },
    { lat: 48.878031, lng: 21.417369 },
    { lat: 48.877732, lng: 21.417498 },
    { lat: 48.877195, lng: 21.417253 },
    { lat: 48.876832, lng: 21.417124 },
    { lat: 48.876432, lng: 21.416899 },
    { lat: 48.876267, lng: 21.416698 },
    { lat: 48.876018, lng: 21.416504 },
    { lat: 48.875816, lng: 21.416502 },
    { lat: 48.875518, lng: 21.416709 },
    { lat: 48.875313, lng: 21.416641 },
    { lat: 48.875066, lng: 21.416406 },
    { lat: 48.874735, lng: 21.416466 },
    { lat: 48.874492, lng: 21.416489 },
    { lat: 48.874414, lng: 21.416497 },
    { lat: 48.873837, lng: 21.416309 },
    { lat: 48.873662, lng: 21.416385 },
    { lat: 48.873189, lng: 21.4164 },
    { lat: 48.872807, lng: 21.415833 },
    { lat: 48.872564, lng: 21.415802 },
    { lat: 48.872462, lng: 21.41556 },
    { lat: 48.872539, lng: 21.415316 },
    { lat: 48.872033, lng: 21.414453 },
    { lat: 48.871899, lng: 21.414368 },
    { lat: 48.871857, lng: 21.414258 },
    { lat: 48.871963, lng: 21.414314 },
    { lat: 48.872204, lng: 21.414398 },
    { lat: 48.872448, lng: 21.414483 },
    { lat: 48.872698, lng: 21.414409 },
    { lat: 48.872713, lng: 21.41438 },
    { lat: 48.872824, lng: 21.414169 },
    { lat: 48.872857, lng: 21.414106 },
    { lat: 48.872868, lng: 21.414086 },
    { lat: 48.873198, lng: 21.413875 },
    { lat: 48.873418, lng: 21.413663 },
    { lat: 48.873745, lng: 21.413527 },
    { lat: 48.873998, lng: 21.413237 },
    { lat: 48.874169, lng: 21.413052 },
    { lat: 48.874275, lng: 21.412748 },
    { lat: 48.874458, lng: 21.412441 },
    { lat: 48.874669, lng: 21.412254 },
  ],
  Ruskov: [
    { lat: 48.6981809, lng: 21.4174677 },
    { lat: 48.6977019, lng: 21.417443 },
    { lat: 48.6972051, lng: 21.4176916 },
    { lat: 48.6970682, lng: 21.4177026 },
    { lat: 48.6968723, lng: 21.4174739 },
    { lat: 48.6968142, lng: 21.4175665 },
    { lat: 48.6968765, lng: 21.4179574 },
    { lat: 48.6963186, lng: 21.4185007 },
    { lat: 48.6962851, lng: 21.4187737 },
    { lat: 48.6961571, lng: 21.4187496 },
    { lat: 48.6959442, lng: 21.4191578 },
    { lat: 48.6959241, lng: 21.4194701 },
    { lat: 48.6958608, lng: 21.4195774 },
    { lat: 48.6955534, lng: 21.4194937 },
    { lat: 48.6954146, lng: 21.4193278 },
    { lat: 48.6954164, lng: 21.4188539 },
    { lat: 48.6956196, lng: 21.4186415 },
    { lat: 48.6956395, lng: 21.4181026 },
    { lat: 48.6954857, lng: 21.4180378 },
    { lat: 48.695039, lng: 21.417467 },
    { lat: 48.6949011, lng: 21.4174845 },
    { lat: 48.6948821, lng: 21.4176393 },
    { lat: 48.694744, lng: 21.4176896 },
    { lat: 48.6947399, lng: 21.4177746 },
    { lat: 48.6946333, lng: 21.4177893 },
    { lat: 48.6945751, lng: 21.4175371 },
    { lat: 48.6945967, lng: 21.4173893 },
    { lat: 48.6944647, lng: 21.4169917 },
    { lat: 48.6943602, lng: 21.4168848 },
    { lat: 48.6941027, lng: 21.4168131 },
    { lat: 48.693404, lng: 21.416286 },
    { lat: 48.693339, lng: 21.4151873 },
    { lat: 48.6930805, lng: 21.4150879 },
    { lat: 48.6929274, lng: 21.4151207 },
    { lat: 48.6928596, lng: 21.4158347 },
    { lat: 48.6927724, lng: 21.4159304 },
    { lat: 48.6926785, lng: 21.4159517 },
    { lat: 48.6921859, lng: 21.4157373 },
    { lat: 48.6919251, lng: 21.4154432 },
    { lat: 48.6919434, lng: 21.4150303 },
    { lat: 48.6918625, lng: 21.4144975 },
    { lat: 48.691935, lng: 21.4141312 },
    { lat: 48.6914682, lng: 21.4135308 },
    { lat: 48.6911294, lng: 21.4131893 },
    { lat: 48.6909194, lng: 21.413087 },
    { lat: 48.6908321, lng: 21.4132531 },
    { lat: 48.6904849, lng: 21.4126294 },
    { lat: 48.6901952, lng: 21.4124965 },
    { lat: 48.6885382, lng: 21.4124402 },
    { lat: 48.6886444, lng: 21.4107032 },
    { lat: 48.6886505, lng: 21.4106002 },
    { lat: 48.6884182, lng: 21.4105831 },
    { lat: 48.6882989, lng: 21.4110791 },
    { lat: 48.6883933, lng: 21.4117992 },
    { lat: 48.6883943, lng: 21.4120634 },
    { lat: 48.6883225, lng: 21.412371 },
    { lat: 48.6882293, lng: 21.4123039 },
    { lat: 48.6879679, lng: 21.4116841 },
    { lat: 48.6882356, lng: 21.4113384 },
    { lat: 48.6882128, lng: 21.4110988 },
    { lat: 48.6879874, lng: 21.4103273 },
    { lat: 48.687805, lng: 21.4100223 },
    { lat: 48.6861035, lng: 21.4110826 },
    { lat: 48.6859746, lng: 21.4109503 },
    { lat: 48.6854345, lng: 21.4107969 },
    { lat: 48.684945, lng: 21.410867 },
    { lat: 48.6848425, lng: 21.4109804 },
    { lat: 48.684789, lng: 21.411343 },
    { lat: 48.6845227, lng: 21.4118093 },
    { lat: 48.6844816, lng: 21.4122123 },
    { lat: 48.6845147, lng: 21.4123778 },
    { lat: 48.6845477, lng: 21.412543 },
    { lat: 48.6846013, lng: 21.4128328 },
    { lat: 48.6845361, lng: 21.4128778 },
    { lat: 48.6845419, lng: 21.413225 },
    { lat: 48.6844007, lng: 21.4136259 },
    { lat: 48.6843011, lng: 21.4142917 },
    { lat: 48.6841888, lng: 21.4145215 },
    { lat: 48.6839991, lng: 21.4152277 },
    { lat: 48.6838617, lng: 21.4154869 },
    { lat: 48.6838228, lng: 21.4158838 },
    { lat: 48.6836365, lng: 21.4161734 },
    { lat: 48.6835206, lng: 21.4165113 },
    { lat: 48.6832467, lng: 21.4168808 },
    { lat: 48.6830793, lng: 21.417235 },
    { lat: 48.6830638, lng: 21.4173568 },
    { lat: 48.6829387, lng: 21.4174639 },
    { lat: 48.6828709, lng: 21.417753 },
    { lat: 48.6826396, lng: 21.4181133 },
    { lat: 48.6825672, lng: 21.4183685 },
    { lat: 48.6823903, lng: 21.4185538 },
    { lat: 48.6823352, lng: 21.4188581 },
    { lat: 48.682141, lng: 21.4194124 },
    { lat: 48.6819019, lng: 21.4199235 },
    { lat: 48.6817219, lng: 21.420193 },
    { lat: 48.6811243, lng: 21.4225571 },
    { lat: 48.6805968, lng: 21.4236381 },
    { lat: 48.6803019, lng: 21.4245107 },
    { lat: 48.6802607, lng: 21.4253318 },
    { lat: 48.6802881, lng: 21.4259876 },
    { lat: 48.6803894, lng: 21.4266826 },
    { lat: 48.6795831, lng: 21.4261991 },
    { lat: 48.6792052, lng: 21.4284614 },
    { lat: 48.677331, lng: 21.428292 },
    { lat: 48.6773743, lng: 21.4281506 },
    { lat: 48.6773528, lng: 21.4272675 },
    { lat: 48.6772588, lng: 21.4270937 },
    { lat: 48.6770541, lng: 21.4270633 },
    { lat: 48.6768351, lng: 21.4278414 },
    { lat: 48.6768689, lng: 21.4279947 },
    { lat: 48.6767099, lng: 21.4281777 },
    { lat: 48.6761618, lng: 21.4292011 },
    { lat: 48.6756452, lng: 21.4288961 },
    { lat: 48.6753592, lng: 21.4286575 },
    { lat: 48.6747516, lng: 21.4275543 },
    { lat: 48.674636, lng: 21.4274481 },
    { lat: 48.6741551, lng: 21.427286 },
    { lat: 48.6737606, lng: 21.4272616 },
    { lat: 48.674263, lng: 21.4260956 },
    { lat: 48.6736083, lng: 21.425565 },
    { lat: 48.6727586, lng: 21.4251446 },
    { lat: 48.672385, lng: 21.426247 },
    { lat: 48.6721977, lng: 21.4263087 },
    { lat: 48.6715565, lng: 21.4274597 },
    { lat: 48.6737072, lng: 21.4301089 },
    { lat: 48.6732551, lng: 21.4298508 },
    { lat: 48.6725641, lng: 21.4297761 },
    { lat: 48.6712403, lng: 21.4302871 },
    { lat: 48.6677728, lng: 21.4302708 },
    { lat: 48.6648109, lng: 21.4309604 },
    { lat: 48.6612246, lng: 21.4307986 },
    { lat: 48.6595364, lng: 21.4302184 },
    { lat: 48.6591215, lng: 21.4302697 },
    { lat: 48.6595614, lng: 21.4336341 },
    { lat: 48.660228, lng: 21.438741 },
    { lat: 48.659069, lng: 21.4444227 },
    { lat: 48.6599602, lng: 21.4473819 },
    { lat: 48.6563078, lng: 21.4508103 },
    { lat: 48.6531204, lng: 21.4564052 },
    { lat: 48.6536212, lng: 21.4564544 },
    { lat: 48.6542049, lng: 21.4565932 },
    { lat: 48.6546197, lng: 21.4569261 },
    { lat: 48.6550628, lng: 21.4571856 },
    { lat: 48.6554525, lng: 21.4571195 },
    { lat: 48.6557542, lng: 21.4571621 },
    { lat: 48.6562467, lng: 21.4573048 },
    { lat: 48.6567401, lng: 21.4575595 },
    { lat: 48.6571938, lng: 21.4574881 },
    { lat: 48.6581409, lng: 21.4592036 },
    { lat: 48.6585771, lng: 21.4595843 },
    { lat: 48.6588794, lng: 21.4601828 },
    { lat: 48.6597562, lng: 21.4608079 },
    { lat: 48.6600838, lng: 21.4613514 },
    { lat: 48.6604469, lng: 21.4621096 },
    { lat: 48.6614011, lng: 21.4628435 },
    { lat: 48.6634135, lng: 21.463981 },
    { lat: 48.6640056, lng: 21.4645629 },
    { lat: 48.6646591, lng: 21.4645601 },
    { lat: 48.6653583, lng: 21.4652249 },
    { lat: 48.6657147, lng: 21.4654885 },
    { lat: 48.6662785, lng: 21.466073 },
    { lat: 48.6700077, lng: 21.4674264 },
    { lat: 48.6703302, lng: 21.4686902 },
    { lat: 48.6703963, lng: 21.4703067 },
    { lat: 48.6704527, lng: 21.4705419 },
    { lat: 48.66957, lng: 21.4723387 },
    { lat: 48.6677388, lng: 21.475854 },
    { lat: 48.6672478, lng: 21.4762663 },
    { lat: 48.6671409, lng: 21.4765739 },
    { lat: 48.6669443, lng: 21.477631 },
    { lat: 48.6669903, lng: 21.4778995 },
    { lat: 48.6669904, lng: 21.4779038 },
    { lat: 48.6673146, lng: 21.4777542 },
    { lat: 48.6678959, lng: 21.4777008 },
    { lat: 48.6681252, lng: 21.4775337 },
    { lat: 48.6682381, lng: 21.4773122 },
    { lat: 48.6686617, lng: 21.4773743 },
    { lat: 48.669115, lng: 21.4777202 },
    { lat: 48.6692226, lng: 21.4776654 },
    { lat: 48.6693095, lng: 21.4779035 },
    { lat: 48.6695335, lng: 21.4782134 },
    { lat: 48.6696384, lng: 21.4781463 },
    { lat: 48.6699527, lng: 21.4784313 },
    { lat: 48.6700602, lng: 21.4786417 },
    { lat: 48.6702963, lng: 21.4787582 },
    { lat: 48.6704225, lng: 21.4789563 },
    { lat: 48.6705623, lng: 21.47893 },
    { lat: 48.6711176, lng: 21.4791411 },
    { lat: 48.6713663, lng: 21.4794486 },
    { lat: 48.6714401, lng: 21.479668 },
    { lat: 48.6715355, lng: 21.4804371 },
    { lat: 48.6715005, lng: 21.4807007 },
    { lat: 48.6716308, lng: 21.4808523 },
    { lat: 48.6716923, lng: 21.4810701 },
    { lat: 48.6718303, lng: 21.4812535 },
    { lat: 48.6719462, lng: 21.4812882 },
    { lat: 48.6719801, lng: 21.4816449 },
    { lat: 48.6719366, lng: 21.4820401 },
    { lat: 48.6720163, lng: 21.4821149 },
    { lat: 48.6720146, lng: 21.4822492 },
    { lat: 48.6720814, lng: 21.4823413 },
    { lat: 48.6723311, lng: 21.4824163 },
    { lat: 48.6723697, lng: 21.4826235 },
    { lat: 48.672452, lng: 21.4827473 },
    { lat: 48.6726503, lng: 21.4831037 },
    { lat: 48.6726985, lng: 21.4835641 },
    { lat: 48.672839, lng: 21.4837912 },
    { lat: 48.6728373, lng: 21.4840003 },
    { lat: 48.6729535, lng: 21.4839734 },
    { lat: 48.6730751, lng: 21.4846795 },
    { lat: 48.6732529, lng: 21.484797 },
    { lat: 48.6736078, lng: 21.485391 },
    { lat: 48.6742415, lng: 21.4860224 },
    { lat: 48.6743507, lng: 21.4860654 },
    { lat: 48.6745167, lng: 21.4863165 },
    { lat: 48.6750067, lng: 21.4863746 },
    { lat: 48.6750662, lng: 21.4862863 },
    { lat: 48.6751335, lng: 21.4863637 },
    { lat: 48.675304, lng: 21.4863425 },
    { lat: 48.6754529, lng: 21.4864765 },
    { lat: 48.6756905, lng: 21.4863598 },
    { lat: 48.6763216, lng: 21.4863957 },
    { lat: 48.676568, lng: 21.4865105 },
    { lat: 48.6766922, lng: 21.4864503 },
    { lat: 48.6767853, lng: 21.4866261 },
    { lat: 48.6770013, lng: 21.4864744 },
    { lat: 48.6771599, lng: 21.486469 },
    { lat: 48.6773506, lng: 21.4862157 },
    { lat: 48.6774735, lng: 21.486151 },
    { lat: 48.6775652, lng: 21.4861285 },
    { lat: 48.6780606, lng: 21.4862963 },
    { lat: 48.6781455, lng: 21.4862743 },
    { lat: 48.6781958, lng: 21.4861062 },
    { lat: 48.6783629, lng: 21.4862023 },
    { lat: 48.6785961, lng: 21.4862168 },
    { lat: 48.6787156, lng: 21.4861021 },
    { lat: 48.6790343, lng: 21.4861555 },
    { lat: 48.6799123, lng: 21.4858092 },
    { lat: 48.6799704, lng: 21.485836 },
    { lat: 48.6800031, lng: 21.4859556 },
    { lat: 48.6804669, lng: 21.4860892 },
    { lat: 48.6807521, lng: 21.4861714 },
    { lat: 48.6819211, lng: 21.4866356 },
    { lat: 48.6829648, lng: 21.4861583 },
    { lat: 48.6832765, lng: 21.4861167 },
    { lat: 48.6838436, lng: 21.4863118 },
    { lat: 48.6842348, lng: 21.4866481 },
    { lat: 48.6846528, lng: 21.4874026 },
    { lat: 48.6848945, lng: 21.4883641 },
    { lat: 48.6850648, lng: 21.4897855 },
    { lat: 48.6849954, lng: 21.4912355 },
    { lat: 48.6850294, lng: 21.4921057 },
    { lat: 48.685714, lng: 21.4926204 },
    { lat: 48.6861642, lng: 21.4933269 },
    { lat: 48.6866339, lng: 21.493661 },
    { lat: 48.6871348, lng: 21.4937772 },
    { lat: 48.6874204, lng: 21.4944332 },
    { lat: 48.6876397, lng: 21.4947037 },
    { lat: 48.6877446, lng: 21.4949906 },
    { lat: 48.6879289, lng: 21.4952573 },
    { lat: 48.6880684, lng: 21.4957585 },
    { lat: 48.688099, lng: 21.4972475 },
    { lat: 48.6881676, lng: 21.4980322 },
    { lat: 48.6884341, lng: 21.4987615 },
    { lat: 48.6885235, lng: 21.4995234 },
    { lat: 48.6888372, lng: 21.4999868 },
    { lat: 48.6894255, lng: 21.5005236 },
    { lat: 48.6896965, lng: 21.5006333 },
    { lat: 48.6902806, lng: 21.5010298 },
    { lat: 48.6910161, lng: 21.5016364 },
    { lat: 48.6914008, lng: 21.5016738 },
    { lat: 48.6917174, lng: 21.5018432 },
    { lat: 48.6921161, lng: 21.5017444 },
    { lat: 48.6925446, lng: 21.5018131 },
    { lat: 48.6929519, lng: 21.5022002 },
    { lat: 48.6937736, lng: 21.5024454 },
    { lat: 48.6945567, lng: 21.5027986 },
    { lat: 48.695281, lng: 21.5025854 },
    { lat: 48.6955894, lng: 21.5027489 },
    { lat: 48.6958908, lng: 21.503055 },
    { lat: 48.6962881, lng: 21.5032569 },
    { lat: 48.6965328, lng: 21.5031913 },
    { lat: 48.6977818, lng: 21.5034285 },
    { lat: 48.69811, lng: 21.5034201 },
    { lat: 48.6986866, lng: 21.5031194 },
    { lat: 48.6988123, lng: 21.5028742 },
    { lat: 48.6988456, lng: 21.5028197 },
    { lat: 48.6985802, lng: 21.5021585 },
    { lat: 48.6984815, lng: 21.5020906 },
    { lat: 48.6979787, lng: 21.5011188 },
    { lat: 48.6970454, lng: 21.4999716 },
    { lat: 48.6969086, lng: 21.4999143 },
    { lat: 48.6966998, lng: 21.4995387 },
    { lat: 48.6965473, lng: 21.4995601 },
    { lat: 48.6963859, lng: 21.4994546 },
    { lat: 48.6963025, lng: 21.4984016 },
    { lat: 48.6964512, lng: 21.49786 },
    { lat: 48.6964439, lng: 21.4974516 },
    { lat: 48.6966187, lng: 21.4966034 },
    { lat: 48.6966771, lng: 21.4960938 },
    { lat: 48.6966658, lng: 21.4952361 },
    { lat: 48.6967117, lng: 21.4949139 },
    { lat: 48.696974, lng: 21.4935937 },
    { lat: 48.697082, lng: 21.4934719 },
    { lat: 48.6970438, lng: 21.4933252 },
    { lat: 48.6970771, lng: 21.493073 },
    { lat: 48.6972409, lng: 21.4923911 },
    { lat: 48.6971842, lng: 21.4922185 },
    { lat: 48.697281, lng: 21.4918684 },
    { lat: 48.6972625, lng: 21.4915815 },
    { lat: 48.6974168, lng: 21.4908832 },
    { lat: 48.697348, lng: 21.4907616 },
    { lat: 48.6975848, lng: 21.4903143 },
    { lat: 48.6975268, lng: 21.4902152 },
    { lat: 48.697571, lng: 21.4898678 },
    { lat: 48.6975418, lng: 21.4896827 },
    { lat: 48.6977045, lng: 21.4891943 },
    { lat: 48.697657, lng: 21.4887975 },
    { lat: 48.6976885, lng: 21.4886786 },
    { lat: 48.6977686, lng: 21.4886142 },
    { lat: 48.6977866, lng: 21.4884505 },
    { lat: 48.6980366, lng: 21.4878748 },
    { lat: 48.698263, lng: 21.4874606 },
    { lat: 48.6982879, lng: 21.4871868 },
    { lat: 48.6984492, lng: 21.4868951 },
    { lat: 48.6985308, lng: 21.4866034 },
    { lat: 48.6985223, lng: 21.4863831 },
    { lat: 48.6988009, lng: 21.4859045 },
    { lat: 48.6988052, lng: 21.4857019 },
    { lat: 48.699019, lng: 21.4854334 },
    { lat: 48.6990557, lng: 21.4851859 },
    { lat: 48.6992028, lng: 21.4850512 },
    { lat: 48.6991794, lng: 21.4849434 },
    { lat: 48.699268, lng: 21.4847797 },
    { lat: 48.6992673, lng: 21.4844054 },
    { lat: 48.6993771, lng: 21.4843536 },
    { lat: 48.6994827, lng: 21.4840763 },
    { lat: 48.6995537, lng: 21.4835704 },
    { lat: 48.6996652, lng: 21.4834149 },
    { lat: 48.6997085, lng: 21.4828517 },
    { lat: 48.6995689, lng: 21.4825802 },
    { lat: 48.6995064, lng: 21.4823293 },
    { lat: 48.699102, lng: 21.4816098 },
    { lat: 48.6989553, lng: 21.4808198 },
    { lat: 48.6987366, lng: 21.4807263 },
    { lat: 48.6985526, lng: 21.4805543 },
    { lat: 48.6985588, lng: 21.4804891 },
    { lat: 48.6983012, lng: 21.4800698 },
    { lat: 48.6982574, lng: 21.479528 },
    { lat: 48.6983131, lng: 21.4793512 },
    { lat: 48.6985755, lng: 21.4792448 },
    { lat: 48.6986734, lng: 21.4790499 },
    { lat: 48.6986878, lng: 21.4785777 },
    { lat: 48.6988409, lng: 21.4784255 },
    { lat: 48.6989094, lng: 21.4782599 },
    { lat: 48.698869, lng: 21.4781511 },
    { lat: 48.6988711, lng: 21.4779152 },
    { lat: 48.6989626, lng: 21.4777296 },
    { lat: 48.6989373, lng: 21.4773608 },
    { lat: 48.6990356, lng: 21.4772613 },
    { lat: 48.6990275, lng: 21.4769402 },
    { lat: 48.6992088, lng: 21.4768669 },
    { lat: 48.6994866, lng: 21.4765198 },
    { lat: 48.6996625, lng: 21.4765274 },
    { lat: 48.6997546, lng: 21.4764404 },
    { lat: 48.6998356, lng: 21.4764785 },
    { lat: 48.6999087, lng: 21.476365 },
    { lat: 48.7000859, lng: 21.4758403 },
    { lat: 48.700582, lng: 21.474907 },
    { lat: 48.7005075, lng: 21.4747032 },
    { lat: 48.7007278, lng: 21.4741115 },
    { lat: 48.700835, lng: 21.4740428 },
    { lat: 48.7009769, lng: 21.4740512 },
    { lat: 48.7015132, lng: 21.4736081 },
    { lat: 48.7015805, lng: 21.4732125 },
    { lat: 48.7017485, lng: 21.4731832 },
    { lat: 48.7017484, lng: 21.4730685 },
    { lat: 48.7018288, lng: 21.4730094 },
    { lat: 48.7018931, lng: 21.472821 },
    { lat: 48.7018538, lng: 21.4726683 },
    { lat: 48.7019885, lng: 21.4723067 },
    { lat: 48.7018953, lng: 21.4722708 },
    { lat: 48.7018916, lng: 21.4720698 },
    { lat: 48.7021729, lng: 21.4718769 },
    { lat: 48.7023412, lng: 21.4715901 },
    { lat: 48.7023863, lng: 21.4713566 },
    { lat: 48.7025933, lng: 21.4710878 },
    { lat: 48.7024689, lng: 21.4710668 },
    { lat: 48.7024423, lng: 21.4709229 },
    { lat: 48.7023288, lng: 21.4707737 },
    { lat: 48.7019861, lng: 21.470826 },
    { lat: 48.702256, lng: 21.4695844 },
    { lat: 48.7023033, lng: 21.4685821 },
    { lat: 48.7019289, lng: 21.4671143 },
    { lat: 48.7017086, lng: 21.4665034 },
    { lat: 48.7011772, lng: 21.4654946 },
    { lat: 48.700793, lng: 21.4640831 },
    { lat: 48.7005243, lng: 21.4634373 },
    { lat: 48.6991676, lng: 21.4624845 },
    { lat: 48.6979793, lng: 21.4618981 },
    { lat: 48.69703, lng: 21.4629992 },
    { lat: 48.6965308, lng: 21.4626259 },
    { lat: 48.6968134, lng: 21.4614705 },
    { lat: 48.6972494, lng: 21.4604855 },
    { lat: 48.698339, lng: 21.458486 },
    { lat: 48.6983348, lng: 21.457729 },
    { lat: 48.6984075, lng: 21.4565198 },
    { lat: 48.6986657, lng: 21.4551465 },
    { lat: 48.698888, lng: 21.4545938 },
    { lat: 48.6983971, lng: 21.4537317 },
    { lat: 48.6984078, lng: 21.4526357 },
    { lat: 48.697899, lng: 21.4501673 },
    { lat: 48.6978319, lng: 21.4484064 },
    { lat: 48.6975797, lng: 21.4464445 },
    { lat: 48.6975728, lng: 21.4452097 },
    { lat: 48.6976543, lng: 21.4440705 },
    { lat: 48.6980317, lng: 21.4425513 },
    { lat: 48.6984217, lng: 21.440726 },
    { lat: 48.6982018, lng: 21.4390252 },
    { lat: 48.6981173, lng: 21.4359511 },
    { lat: 48.6981387, lng: 21.4327395 },
    { lat: 48.6981085, lng: 21.4325132 },
    { lat: 48.698044, lng: 21.4324189 },
    { lat: 48.6980402, lng: 21.4315685 },
    { lat: 48.6978533, lng: 21.430033 },
    { lat: 48.6977734, lng: 21.4297192 },
    { lat: 48.6978468, lng: 21.4286253 },
    { lat: 48.6980566, lng: 21.4219515 },
    { lat: 48.6981508, lng: 21.4176332 },
    { lat: 48.6981809, lng: 21.4174677 },
  ],
  NovýSalaš: [
    { lat: 48.6210072, lng: 21.5004314 },
    { lat: 48.6215697, lng: 21.5002743 },
    { lat: 48.6219982, lng: 21.5000705 },
    { lat: 48.6221058, lng: 21.5004813 },
    { lat: 48.6221882, lng: 21.5004958 },
    { lat: 48.6223816, lng: 21.5000233 },
    { lat: 48.6224594, lng: 21.5000612 },
    { lat: 48.6228747, lng: 21.4993311 },
    { lat: 48.6230406, lng: 21.4989486 },
    { lat: 48.6230624, lng: 21.4983806 },
    { lat: 48.6231485, lng: 21.4983984 },
    { lat: 48.6232889, lng: 21.4980424 },
    { lat: 48.6231807, lng: 21.4971365 },
    { lat: 48.6228644, lng: 21.4963193 },
    { lat: 48.6227026, lng: 21.4960554 },
    { lat: 48.6223384, lng: 21.4957962 },
    { lat: 48.6219563, lng: 21.4951061 },
    { lat: 48.6217517, lng: 21.4950032 },
    { lat: 48.6215053, lng: 21.4950038 },
    { lat: 48.6213814, lng: 21.4946079 },
    { lat: 48.620683, lng: 21.4938842 },
    { lat: 48.6205173, lng: 21.4938705 },
    { lat: 48.6201344, lng: 21.4942122 },
    { lat: 48.6199271, lng: 21.49408 },
    { lat: 48.6197021, lng: 21.4937797 },
    { lat: 48.6194181, lng: 21.4931774 },
    { lat: 48.6194026, lng: 21.4927979 },
    { lat: 48.6192202, lng: 21.4924443 },
    { lat: 48.6192357, lng: 21.4922776 },
    { lat: 48.6193734, lng: 21.4920156 },
    { lat: 48.6193339, lng: 21.4917021 },
    { lat: 48.619484, lng: 21.4912181 },
    { lat: 48.6194952, lng: 21.490903 },
    { lat: 48.6194442, lng: 21.4906976 },
    { lat: 48.6192292, lng: 21.490259 },
    { lat: 48.6192908, lng: 21.4901699 },
    { lat: 48.6194971, lng: 21.4901468 },
    { lat: 48.6195316, lng: 21.4899436 },
    { lat: 48.6193283, lng: 21.4898367 },
    { lat: 48.6193195, lng: 21.4893775 },
    { lat: 48.6191095, lng: 21.4890413 },
    { lat: 48.6191077, lng: 21.488593 },
    { lat: 48.6191615, lng: 21.4883526 },
    { lat: 48.6190147, lng: 21.486467 },
    { lat: 48.6187019, lng: 21.4855747 },
    { lat: 48.6188038, lng: 21.4851675 },
    { lat: 48.6189746, lng: 21.485083 },
    { lat: 48.6190621, lng: 21.4849183 },
    { lat: 48.6190603, lng: 21.484354 },
    { lat: 48.6192394, lng: 21.4837397 },
    { lat: 48.6193801, lng: 21.4835409 },
    { lat: 48.6192303, lng: 21.4816352 },
    { lat: 48.6189191, lng: 21.4811899 },
    { lat: 48.6187878, lng: 21.4801537 },
    { lat: 48.6188181, lng: 21.4796111 },
    { lat: 48.6189033, lng: 21.4792914 },
    { lat: 48.6189232, lng: 21.4789274 },
    { lat: 48.6188353, lng: 21.4784594 },
    { lat: 48.6188475, lng: 21.4782598 },
    { lat: 48.6190376, lng: 21.4779069 },
    { lat: 48.6190077, lng: 21.4775351 },
    { lat: 48.6189066, lng: 21.4772356 },
    { lat: 48.6189356, lng: 21.4768766 },
    { lat: 48.6191804, lng: 21.4763934 },
    { lat: 48.6192057, lng: 21.4754063 },
    { lat: 48.619425, lng: 21.4745436 },
    { lat: 48.6193519, lng: 21.4742756 },
    { lat: 48.6192179, lng: 21.4740803 },
    { lat: 48.6191636, lng: 21.4733712 },
    { lat: 48.6190682, lng: 21.473189 },
    { lat: 48.6187, lng: 21.4730327 },
    { lat: 48.6177964, lng: 21.4731839 },
    { lat: 48.6177354, lng: 21.4730571 },
    { lat: 48.6173539, lng: 21.4727505 },
    { lat: 48.6169381, lng: 21.4726496 },
    { lat: 48.6164424, lng: 21.4723961 },
    { lat: 48.6158865, lng: 21.4724724 },
    { lat: 48.6154795, lng: 21.4729911 },
    { lat: 48.6151352, lng: 21.4731448 },
    { lat: 48.6148299, lng: 21.4728638 },
    { lat: 48.6145574, lng: 21.4723573 },
    { lat: 48.6143878, lng: 21.4717458 },
    { lat: 48.613791, lng: 21.4708785 },
    { lat: 48.6138314, lng: 21.4706465 },
    { lat: 48.6133811, lng: 21.4704064 },
    { lat: 48.6135885, lng: 21.4697212 },
    { lat: 48.6136227, lng: 21.4698523 },
    { lat: 48.6139378, lng: 21.4698539 },
    { lat: 48.6143598, lng: 21.4696243 },
    { lat: 48.6144413, lng: 21.4694432 },
    { lat: 48.6143456, lng: 21.4692525 },
    { lat: 48.6146002, lng: 21.4690696 },
    { lat: 48.6150586, lng: 21.4691182 },
    { lat: 48.6156528, lng: 21.4700092 },
    { lat: 48.6159251, lng: 21.4706736 },
    { lat: 48.616154, lng: 21.4706876 },
    { lat: 48.6162979, lng: 21.4709145 },
    { lat: 48.6166424, lng: 21.4706876 },
    { lat: 48.6166237, lng: 21.4701582 },
    { lat: 48.6168832, lng: 21.4702609 },
    { lat: 48.6170138, lng: 21.4701473 },
    { lat: 48.6171136, lng: 21.4696286 },
    { lat: 48.6168879, lng: 21.4688032 },
    { lat: 48.6162861, lng: 21.4687698 },
    { lat: 48.6158724, lng: 21.4683602 },
    { lat: 48.6158416, lng: 21.4681118 },
    { lat: 48.6159279, lng: 21.4675545 },
    { lat: 48.6160611, lng: 21.467189 },
    { lat: 48.6155242, lng: 21.4667207 },
    { lat: 48.61503, lng: 21.4666325 },
    { lat: 48.6147338, lng: 21.4662354 },
    { lat: 48.614993, lng: 21.4656389 },
    { lat: 48.6152254, lng: 21.4649225 },
    { lat: 48.6153207, lng: 21.4649866 },
    { lat: 48.615416, lng: 21.4645838 },
    { lat: 48.6153418, lng: 21.4637783 },
    { lat: 48.6152085, lng: 21.4637185 },
    { lat: 48.6152119, lng: 21.4633356 },
    { lat: 48.6153219, lng: 21.4629414 },
    { lat: 48.6151682, lng: 21.4625739 },
    { lat: 48.6150003, lng: 21.4627579 },
    { lat: 48.6146025, lng: 21.4625791 },
    { lat: 48.6142311, lng: 21.4628551 },
    { lat: 48.614133, lng: 21.4638499 },
    { lat: 48.613524, lng: 21.4643035 },
    { lat: 48.6123342, lng: 21.4642911 },
    { lat: 48.6120121, lng: 21.4642015 },
    { lat: 48.6119685, lng: 21.4640554 },
    { lat: 48.6118356, lng: 21.4640172 },
    { lat: 48.6117566, lng: 21.464206 },
    { lat: 48.6114675, lng: 21.464244 },
    { lat: 48.6112399, lng: 21.4638886 },
    { lat: 48.6110196, lng: 21.4637407 },
    { lat: 48.6103385, lng: 21.4633495 },
    { lat: 48.6100871, lng: 21.4634032 },
    { lat: 48.6097137, lng: 21.4631819 },
    { lat: 48.6096083, lng: 21.463004 },
    { lat: 48.609749, lng: 21.4621519 },
    { lat: 48.6103242, lng: 21.4624704 },
    { lat: 48.6104079, lng: 21.4621395 },
    { lat: 48.6103784, lng: 21.4618936 },
    { lat: 48.6105314, lng: 21.4612402 },
    { lat: 48.6104937, lng: 21.4609091 },
    { lat: 48.6103983, lng: 21.460807 },
    { lat: 48.6101842, lng: 21.4609859 },
    { lat: 48.6097724, lng: 21.4616049 },
    { lat: 48.6097119, lng: 21.4618084 },
    { lat: 48.6094801, lng: 21.4620364 },
    { lat: 48.6093237, lng: 21.4618868 },
    { lat: 48.6092021, lng: 21.4628844 },
    { lat: 48.609042, lng: 21.4634188 },
    { lat: 48.6093254, lng: 21.4637983 },
    { lat: 48.6090488, lng: 21.4642675 },
    { lat: 48.6088126, lng: 21.4644215 },
    { lat: 48.6086412, lng: 21.4646807 },
    { lat: 48.608473, lng: 21.4647761 },
    { lat: 48.6082576, lng: 21.464763 },
    { lat: 48.608116, lng: 21.4648819 },
    { lat: 48.6080169, lng: 21.4649614 },
    { lat: 48.6079751, lng: 21.4649915 },
    { lat: 48.6078744, lng: 21.4657431 },
    { lat: 48.6073641, lng: 21.4659583 },
    { lat: 48.6074943, lng: 21.4665282 },
    { lat: 48.6079687, lng: 21.4674415 },
    { lat: 48.6086147, lng: 21.4678257 },
    { lat: 48.6093771, lng: 21.4695848 },
    { lat: 48.6096263, lng: 21.4703356 },
    { lat: 48.6090711, lng: 21.4700901 },
    { lat: 48.609004, lng: 21.4698321 },
    { lat: 48.6081374, lng: 21.4685127 },
    { lat: 48.6077862, lng: 21.4683144 },
    { lat: 48.6074833, lng: 21.4677695 },
    { lat: 48.6072688, lng: 21.4675522 },
    { lat: 48.6069024, lng: 21.4676846 },
    { lat: 48.6067505, lng: 21.4679578 },
    { lat: 48.6063926, lng: 21.4682997 },
    { lat: 48.6061415, lng: 21.4688013 },
    { lat: 48.6060232, lng: 21.468828 },
    { lat: 48.6059596, lng: 21.4690832 },
    { lat: 48.6057172, lng: 21.4689933 },
    { lat: 48.6052568, lng: 21.4696357 },
    { lat: 48.6048628, lng: 21.4698656 },
    { lat: 48.6047285, lng: 21.4696234 },
    { lat: 48.6037424, lng: 21.4701506 },
    { lat: 48.6035071, lng: 21.4701836 },
    { lat: 48.6029558, lng: 21.4707267 },
    { lat: 48.6024093, lng: 21.471016 },
    { lat: 48.6022798, lng: 21.4708913 },
    { lat: 48.6019358, lng: 21.4713282 },
    { lat: 48.601639, lng: 21.4710311 },
    { lat: 48.6016683, lng: 21.4708819 },
    { lat: 48.6015887, lng: 21.4708122 },
    { lat: 48.6014651, lng: 21.4708168 },
    { lat: 48.6013739, lng: 21.4706988 },
    { lat: 48.6013637, lng: 21.4704602 },
    { lat: 48.6011268, lng: 21.4707169 },
    { lat: 48.6008205, lng: 21.470782 },
    { lat: 48.6001379, lng: 21.4704485 },
    { lat: 48.6002133, lng: 21.4701392 },
    { lat: 48.5995832, lng: 21.469103 },
    { lat: 48.5992323, lng: 21.4693776 },
    { lat: 48.5990626, lng: 21.4694198 },
    { lat: 48.5990169, lng: 21.4695909 },
    { lat: 48.5962967, lng: 21.4736743 },
    { lat: 48.5956328, lng: 21.474571 },
    { lat: 48.5932245, lng: 21.4774634 },
    { lat: 48.5889968, lng: 21.4816104 },
    { lat: 48.5869953, lng: 21.4832849 },
    { lat: 48.5832675, lng: 21.4860141 },
    { lat: 48.5836346, lng: 21.4871418 },
    { lat: 48.5837397, lng: 21.4891006 },
    { lat: 48.583365, lng: 21.4907398 },
    { lat: 48.5831713, lng: 21.4913126 },
    { lat: 48.5832346, lng: 21.492058 },
    { lat: 48.5836389, lng: 21.492927 },
    { lat: 48.5839777, lng: 21.4942961 },
    { lat: 48.5840817, lng: 21.4952069 },
    { lat: 48.5839993, lng: 21.4960518 },
    { lat: 48.5841697, lng: 21.4977074 },
    { lat: 48.5844315, lng: 21.4985499 },
    { lat: 48.5849045, lng: 21.4992592 },
    { lat: 48.5856149, lng: 21.5025513 },
    { lat: 48.5856954, lng: 21.5041738 },
    { lat: 48.5862682, lng: 21.5060459 },
    { lat: 48.5877085, lng: 21.5092513 },
    { lat: 48.5881624, lng: 21.5090706 },
    { lat: 48.5885846, lng: 21.5090003 },
    { lat: 48.5892756, lng: 21.5096276 },
    { lat: 48.589895, lng: 21.5114438 },
    { lat: 48.5916619, lng: 21.5123897 },
    { lat: 48.592769, lng: 21.5125402 },
    { lat: 48.5934372, lng: 21.5129651 },
    { lat: 48.5940182, lng: 21.5131365 },
    { lat: 48.595768, lng: 21.5141522 },
    { lat: 48.596072, lng: 21.514477 },
    { lat: 48.5964557, lng: 21.5145104 },
    { lat: 48.5970444, lng: 21.5144819 },
    { lat: 48.5973857, lng: 21.5143792 },
    { lat: 48.5983508, lng: 21.5143613 },
    { lat: 48.598588, lng: 21.5142396 },
    { lat: 48.5989048, lng: 21.5138669 },
    { lat: 48.5989773, lng: 21.513886 },
    { lat: 48.5989886, lng: 21.5142784 },
    { lat: 48.5991083, lng: 21.5144498 },
    { lat: 48.5992308, lng: 21.5144873 },
    { lat: 48.5992933, lng: 21.5146994 },
    { lat: 48.5994139, lng: 21.51486 },
    { lat: 48.5997859, lng: 21.5151183 },
    { lat: 48.5999515, lng: 21.5153603 },
    { lat: 48.5999643, lng: 21.5155358 },
    { lat: 48.6002802, lng: 21.5157942 },
    { lat: 48.6002757, lng: 21.5159594 },
    { lat: 48.6004545, lng: 21.5160676 },
    { lat: 48.6006195, lng: 21.5163302 },
    { lat: 48.6008565, lng: 21.5163126 },
    { lat: 48.601081, lng: 21.5166822 },
    { lat: 48.6013459, lng: 21.5164644 },
    { lat: 48.6020415, lng: 21.5161627 },
    { lat: 48.6025057, lng: 21.5149688 },
    { lat: 48.602708, lng: 21.514845 },
    { lat: 48.6030152, lng: 21.5152384 },
    { lat: 48.603483, lng: 21.5162403 },
    { lat: 48.6044033, lng: 21.5175707 },
    { lat: 48.605396, lng: 21.5162889 },
    { lat: 48.6060191, lng: 21.5153736 },
    { lat: 48.6067801, lng: 21.5136215 },
    { lat: 48.6068978, lng: 21.5131789 },
    { lat: 48.6071691, lng: 21.5126346 },
    { lat: 48.6072663, lng: 21.5125686 },
    { lat: 48.6073657, lng: 21.512188 },
    { lat: 48.6077829, lng: 21.5115534 },
    { lat: 48.6079735, lng: 21.5114147 },
    { lat: 48.6082218, lng: 21.5115542 },
    { lat: 48.6085265, lng: 21.5117878 },
    { lat: 48.6091696, lng: 21.5124704 },
    { lat: 48.6094374, lng: 21.5126247 },
    { lat: 48.6093816, lng: 21.5131101 },
    { lat: 48.609769, lng: 21.5135122 },
    { lat: 48.6112978, lng: 21.5126403 },
    { lat: 48.6116024, lng: 21.5122787 },
    { lat: 48.6117367, lng: 21.5120103 },
    { lat: 48.6120381, lng: 21.5109153 },
    { lat: 48.6116587, lng: 21.5095786 },
    { lat: 48.611783, lng: 21.5072844 },
    { lat: 48.6128031, lng: 21.5061743 },
    { lat: 48.6128897, lng: 21.5058642 },
    { lat: 48.6131484, lng: 21.5055052 },
    { lat: 48.6133758, lng: 21.5054341 },
    { lat: 48.6135595, lng: 21.5052485 },
    { lat: 48.6137237, lng: 21.5049323 },
    { lat: 48.6138119, lng: 21.5045556 },
    { lat: 48.6141875, lng: 21.5034908 },
    { lat: 48.614712, lng: 21.5027161 },
    { lat: 48.6148988, lng: 21.5017035 },
    { lat: 48.6157069, lng: 21.5015918 },
    { lat: 48.6160694, lng: 21.5013636 },
    { lat: 48.6161614, lng: 21.5014735 },
    { lat: 48.6169099, lng: 21.5008539 },
    { lat: 48.617277, lng: 21.5001983 },
    { lat: 48.6175696, lng: 21.5000019 },
    { lat: 48.6178817, lng: 21.4996297 },
    { lat: 48.6185027, lng: 21.498026 },
    { lat: 48.6195416, lng: 21.4986214 },
    { lat: 48.6210072, lng: 21.5004314 },
  ],
  Jasov: [
    { lat: 48.6716783, lng: 21.0192529 },
    { lat: 48.671815, lng: 21.0181779 },
    { lat: 48.6721399, lng: 21.0166937 },
    { lat: 48.6730122, lng: 21.014383 },
    { lat: 48.6735113, lng: 21.0127909 },
    { lat: 48.6735912, lng: 21.0123194 },
    { lat: 48.6737519, lng: 21.0101314 },
    { lat: 48.6739717, lng: 21.0085756 },
    { lat: 48.6740502, lng: 21.008288 },
    { lat: 48.6743855, lng: 21.007508 },
    { lat: 48.6748607, lng: 21.006559 },
    { lat: 48.6753892, lng: 21.0059995 },
    { lat: 48.675778, lng: 21.0054695 },
    { lat: 48.6769321, lng: 21.0037116 },
    { lat: 48.6780808, lng: 21.0028143 },
    { lat: 48.6784706, lng: 21.0024212 },
    { lat: 48.6796006, lng: 21.0010543 },
    { lat: 48.6799417, lng: 21.0008907 },
    { lat: 48.6815018, lng: 20.9998702 },
    { lat: 48.6820111, lng: 20.9993455 },
    { lat: 48.6822178, lng: 20.9990631 },
    { lat: 48.6824766, lng: 20.998552 },
    { lat: 48.6826958, lng: 20.9978946 },
    { lat: 48.6830308, lng: 20.99717 },
    { lat: 48.6840105, lng: 20.9956248 },
    { lat: 48.6848418, lng: 20.9941858 },
    { lat: 48.6852722, lng: 20.9935875 },
    { lat: 48.6859671, lng: 20.9930572 },
    { lat: 48.6861163, lng: 20.9928678 },
    { lat: 48.6865753, lng: 20.9924925 },
    { lat: 48.6873183, lng: 20.9916839 },
    { lat: 48.6894839, lng: 20.9903951 },
    { lat: 48.6906985, lng: 20.9897548 },
    { lat: 48.6911374, lng: 20.9899876 },
    { lat: 48.6912027, lng: 20.9903981 },
    { lat: 48.6916555, lng: 20.9903748 },
    { lat: 48.6917019, lng: 20.9903802 },
    { lat: 48.6930944, lng: 20.9909591 },
    { lat: 48.6931972, lng: 20.9902284 },
    { lat: 48.6939172, lng: 20.9903167 },
    { lat: 48.6939682, lng: 20.989312 },
    { lat: 48.6943545, lng: 20.9893529 },
    { lat: 48.6948324, lng: 20.9893046 },
    { lat: 48.6957465, lng: 20.9890657 },
    { lat: 48.6965979, lng: 20.988135 },
    { lat: 48.6966282, lng: 20.9864413 },
    { lat: 48.6968742, lng: 20.9864554 },
    { lat: 48.6970526, lng: 20.9848842 },
    { lat: 48.6977363, lng: 20.9849666 },
    { lat: 48.6979657, lng: 20.9820876 },
    { lat: 48.6979819, lng: 20.9816239 },
    { lat: 48.697916, lng: 20.9804243 },
    { lat: 48.6971913, lng: 20.9806988 },
    { lat: 48.6969357, lng: 20.9807004 },
    { lat: 48.6961625, lng: 20.9805279 },
    { lat: 48.6962258, lng: 20.9793573 },
    { lat: 48.6962064, lng: 20.9774946 },
    { lat: 48.6944943, lng: 20.9772825 },
    { lat: 48.6944989, lng: 20.9770883 },
    { lat: 48.6947337, lng: 20.9770426 },
    { lat: 48.6945077, lng: 20.9750525 },
    { lat: 48.6937306, lng: 20.9753468 },
    { lat: 48.693579, lng: 20.974712 },
    { lat: 48.69342, lng: 20.974266 },
    { lat: 48.6927748, lng: 20.9743974 },
    { lat: 48.692606, lng: 20.9743639 },
    { lat: 48.6925985, lng: 20.9740395 },
    { lat: 48.6932754, lng: 20.9738469 },
    { lat: 48.6931607, lng: 20.9733074 },
    { lat: 48.6932751, lng: 20.9731333 },
    { lat: 48.6938469, lng: 20.9728935 },
    { lat: 48.6947206, lng: 20.972393 },
    { lat: 48.695449, lng: 20.9718284 },
    { lat: 48.6958892, lng: 20.9715914 },
    { lat: 48.6962139, lng: 20.9715115 },
    { lat: 48.6966782, lng: 20.9709484 },
    { lat: 48.6964448, lng: 20.9699276 },
    { lat: 48.6965598, lng: 20.9698971 },
    { lat: 48.6968573, lng: 20.9696378 },
    { lat: 48.696763, lng: 20.9691733 },
    { lat: 48.6967582, lng: 20.9687963 },
    { lat: 48.697155, lng: 20.9685811 },
    { lat: 48.6975151, lng: 20.9682384 },
    { lat: 48.697009, lng: 20.9676498 },
    { lat: 48.6985322, lng: 20.9658708 },
    { lat: 48.6986723, lng: 20.965482 },
    { lat: 48.6987643, lng: 20.9653678 },
    { lat: 48.6986656, lng: 20.9651123 },
    { lat: 48.6988624, lng: 20.9647677 },
    { lat: 48.6992941, lng: 20.9643834 },
    { lat: 48.6993263, lng: 20.9641034 },
    { lat: 48.6999818, lng: 20.9636122 },
    { lat: 48.7010566, lng: 20.963031 },
    { lat: 48.7013668, lng: 20.9627864 },
    { lat: 48.7014405, lng: 20.9625637 },
    { lat: 48.701096, lng: 20.9605447 },
    { lat: 48.7016073, lng: 20.9596465 },
    { lat: 48.701826, lng: 20.9590867 },
    { lat: 48.7021081, lng: 20.9585659 },
    { lat: 48.7036787, lng: 20.9573386 },
    { lat: 48.7043697, lng: 20.9567295 },
    { lat: 48.7050548, lng: 20.9564444 },
    { lat: 48.7057532, lng: 20.9556929 },
    { lat: 48.705915, lng: 20.9556509 },
    { lat: 48.7062677, lng: 20.9557294 },
    { lat: 48.7083382, lng: 20.9549709 },
    { lat: 48.7091476, lng: 20.9548412 },
    { lat: 48.709495, lng: 20.9544889 },
    { lat: 48.7099133, lng: 20.9542737 },
    { lat: 48.7102961, lng: 20.9541806 },
    { lat: 48.7109823, lng: 20.954266 },
    { lat: 48.7112038, lng: 20.9540919 },
    { lat: 48.7115554, lng: 20.9541049 },
    { lat: 48.7117785, lng: 20.9537086 },
    { lat: 48.7121218, lng: 20.9528333 },
    { lat: 48.7121565, lng: 20.9526304 },
    { lat: 48.7121313, lng: 20.9520107 },
    { lat: 48.7122341, lng: 20.9518207 },
    { lat: 48.7125035, lng: 20.9516971 },
    { lat: 48.7129302, lng: 20.9523366 },
    { lat: 48.713546, lng: 20.9516379 },
    { lat: 48.7136565, lng: 20.9517118 },
    { lat: 48.7137547, lng: 20.9519185 },
    { lat: 48.7138525, lng: 20.952554 },
    { lat: 48.7139552, lng: 20.9527985 },
    { lat: 48.7141583, lng: 20.9538162 },
    { lat: 48.714389, lng: 20.9539803 },
    { lat: 48.7148572, lng: 20.9539438 },
    { lat: 48.7157118, lng: 20.953518 },
    { lat: 48.7160449, lng: 20.9534123 },
    { lat: 48.7166899, lng: 20.9533832 },
    { lat: 48.7168519, lng: 20.9534792 },
    { lat: 48.717099, lng: 20.9534706 },
    { lat: 48.7173407, lng: 20.953559 },
    { lat: 48.7181348, lng: 20.9534081 },
    { lat: 48.7207352, lng: 20.9537522 },
    { lat: 48.7214894, lng: 20.9530108 },
    { lat: 48.7218153, lng: 20.9527609 },
    { lat: 48.7226305, lng: 20.9522981 },
    { lat: 48.7229976, lng: 20.9519003 },
    { lat: 48.7233608, lng: 20.9516265 },
    { lat: 48.7240347, lng: 20.9515499 },
    { lat: 48.7242827, lng: 20.9513184 },
    { lat: 48.7247614, lng: 20.9511274 },
    { lat: 48.724924, lng: 20.9512483 },
    { lat: 48.725108, lng: 20.9517329 },
    { lat: 48.725226, lng: 20.9517567 },
    { lat: 48.7253916, lng: 20.9510902 },
    { lat: 48.7254117, lng: 20.950417 },
    { lat: 48.7256499, lng: 20.9486222 },
    { lat: 48.7260335, lng: 20.9479507 },
    { lat: 48.7262566, lng: 20.947852 },
    { lat: 48.7264982, lng: 20.947909 },
    { lat: 48.726543, lng: 20.947973 },
    { lat: 48.7267358, lng: 20.9477806 },
    { lat: 48.7267173, lng: 20.9475293 },
    { lat: 48.7270463, lng: 20.9473408 },
    { lat: 48.7274357, lng: 20.9473669 },
    { lat: 48.7275901, lng: 20.9476103 },
    { lat: 48.7277226, lng: 20.947664 },
    { lat: 48.7282566, lng: 20.9477125 },
    { lat: 48.7286014, lng: 20.9478946 },
    { lat: 48.7288625, lng: 20.9478582 },
    { lat: 48.7291046, lng: 20.9479609 },
    { lat: 48.7295399, lng: 20.9483856 },
    { lat: 48.7297353, lng: 20.9485076 },
    { lat: 48.7298138, lng: 20.9481001 },
    { lat: 48.7299578, lng: 20.9481667 },
    { lat: 48.7301097, lng: 20.9483619 },
    { lat: 48.7306712, lng: 20.9484593 },
    { lat: 48.7306762, lng: 20.9485081 },
    { lat: 48.7309662, lng: 20.9485968 },
    { lat: 48.7311258, lng: 20.9485881 },
    { lat: 48.7313971, lng: 20.9487916 },
    { lat: 48.7315707, lng: 20.9487629 },
    { lat: 48.7318945, lng: 20.9483844 },
    { lat: 48.7326032, lng: 20.948473 },
    { lat: 48.7326965, lng: 20.9483981 },
    { lat: 48.7329978, lng: 20.9484931 },
    { lat: 48.7333952, lng: 20.9482593 },
    { lat: 48.7338746, lng: 20.9482215 },
    { lat: 48.7339717, lng: 20.9482975 },
    { lat: 48.7347049, lng: 20.9481097 },
    { lat: 48.734758, lng: 20.9481185 },
    { lat: 48.7348619, lng: 20.9483248 },
    { lat: 48.7351571, lng: 20.9484701 },
    { lat: 48.7356137, lng: 20.9483196 },
    { lat: 48.7359614, lng: 20.9484213 },
    { lat: 48.7360897, lng: 20.948518 },
    { lat: 48.7362752, lng: 20.948548 },
    { lat: 48.7363495, lng: 20.9484806 },
    { lat: 48.736478, lng: 20.9484843 },
    { lat: 48.7366273, lng: 20.9485406 },
    { lat: 48.7368102, lng: 20.9484585 },
    { lat: 48.7370978, lng: 20.9484404 },
    { lat: 48.7371958, lng: 20.9483744 },
    { lat: 48.7372064, lng: 20.9482901 },
    { lat: 48.7374096, lng: 20.9481722 },
    { lat: 48.7375906, lng: 20.9482328 },
    { lat: 48.7377932, lng: 20.9479998 },
    { lat: 48.7379879, lng: 20.9479617 },
    { lat: 48.738171, lng: 20.9480539 },
    { lat: 48.7382687, lng: 20.9482818 },
    { lat: 48.7386818, lng: 20.948514 },
    { lat: 48.7388922, lng: 20.948444 },
    { lat: 48.7392709, lng: 20.9484987 },
    { lat: 48.7395533, lng: 20.9483587 },
    { lat: 48.7399398, lng: 20.9486372 },
    { lat: 48.740125, lng: 20.9484839 },
    { lat: 48.7402141, lng: 20.9484906 },
    { lat: 48.7403585, lng: 20.9485697 },
    { lat: 48.7404535, lng: 20.948786 },
    { lat: 48.7407458, lng: 20.9488075 },
    { lat: 48.7409086, lng: 20.9489287 },
    { lat: 48.7412949, lng: 20.9488003 },
    { lat: 48.7416365, lng: 20.948951 },
    { lat: 48.7417693, lng: 20.9490828 },
    { lat: 48.7420963, lng: 20.9490723 },
    { lat: 48.7424541, lng: 20.9492984 },
    { lat: 48.7426563, lng: 20.9493553 },
    { lat: 48.7432028, lng: 20.9498405 },
    { lat: 48.7434286, lng: 20.9498296 },
    { lat: 48.7436391, lng: 20.9500642 },
    { lat: 48.7438931, lng: 20.9500201 },
    { lat: 48.7439794, lng: 20.9501109 },
    { lat: 48.7441241, lng: 20.9500937 },
    { lat: 48.7442723, lng: 20.950383 },
    { lat: 48.7444431, lng: 20.9504773 },
    { lat: 48.7445212, lng: 20.9503864 },
    { lat: 48.7448797, lng: 20.9506392 },
    { lat: 48.7449726, lng: 20.9508283 },
    { lat: 48.7453776, lng: 20.9510522 },
    { lat: 48.7454577, lng: 20.9510301 },
    { lat: 48.7455782, lng: 20.9511617 },
    { lat: 48.7457068, lng: 20.9511361 },
    { lat: 48.7460175, lng: 20.9513064 },
    { lat: 48.7461792, lng: 20.9512611 },
    { lat: 48.7462564, lng: 20.9513833 },
    { lat: 48.7463379, lng: 20.9513282 },
    { lat: 48.7469058, lng: 20.9513759 },
    { lat: 48.7476244, lng: 20.9511279 },
    { lat: 48.748359, lng: 20.9506725 },
    { lat: 48.7485916, lng: 20.9503928 },
    { lat: 48.7490361, lng: 20.9503616 },
    { lat: 48.7490869, lng: 20.9502668 },
    { lat: 48.7492275, lng: 20.9502599 },
    { lat: 48.7492933, lng: 20.950176 },
    { lat: 48.7494085, lng: 20.9502193 },
    { lat: 48.7495712, lng: 20.9501344 },
    { lat: 48.7497365, lng: 20.9501674 },
    { lat: 48.750072, lng: 20.9500029 },
    { lat: 48.7502178, lng: 20.9496518 },
    { lat: 48.7505773, lng: 20.94933 },
    { lat: 48.750583, lng: 20.9492247 },
    { lat: 48.7509395, lng: 20.9489812 },
    { lat: 48.7510748, lng: 20.9487539 },
    { lat: 48.7513384, lng: 20.9487312 },
    { lat: 48.7515451, lng: 20.9486137 },
    { lat: 48.7516387, lng: 20.9484499 },
    { lat: 48.7517738, lng: 20.9484207 },
    { lat: 48.7519087, lng: 20.9480472 },
    { lat: 48.7523814, lng: 20.9476417 },
    { lat: 48.7525122, lng: 20.9476174 },
    { lat: 48.752595, lng: 20.9474973 },
    { lat: 48.7526151, lng: 20.9473573 },
    { lat: 48.7528153, lng: 20.9473933 },
    { lat: 48.7531793, lng: 20.9469648 },
    { lat: 48.7532586, lng: 20.9469801 },
    { lat: 48.7533843, lng: 20.9468749 },
    { lat: 48.7538169, lng: 20.9469187 },
    { lat: 48.7540674, lng: 20.9471717 },
    { lat: 48.7558023, lng: 20.9470464 },
    { lat: 48.7561019, lng: 20.94685 },
    { lat: 48.756622, lng: 20.9468155 },
    { lat: 48.7567186, lng: 20.9469821 },
    { lat: 48.7573516, lng: 20.9471636 },
    { lat: 48.7575746, lng: 20.9473984 },
    { lat: 48.757752, lng: 20.9477226 },
    { lat: 48.7579978, lng: 20.947835 },
    { lat: 48.758668, lng: 20.9477811 },
    { lat: 48.7588605, lng: 20.9479176 },
    { lat: 48.7589199, lng: 20.948176 },
    { lat: 48.7590982, lng: 20.9484295 },
    { lat: 48.7597035, lng: 20.9488165 },
    { lat: 48.760471, lng: 20.9490826 },
    { lat: 48.760625, lng: 20.9493155 },
    { lat: 48.7609143, lng: 20.949481 },
    { lat: 48.7611835, lng: 20.9497233 },
    { lat: 48.7614242, lng: 20.9497198 },
    { lat: 48.7615946, lng: 20.9498973 },
    { lat: 48.7622567, lng: 20.9499399 },
    { lat: 48.7626064, lng: 20.9497622 },
    { lat: 48.762935, lng: 20.9498255 },
    { lat: 48.7629553, lng: 20.9497297 },
    { lat: 48.7631187, lng: 20.9496002 },
    { lat: 48.7638005, lng: 20.9494858 },
    { lat: 48.7639002, lng: 20.9494061 },
    { lat: 48.7643075, lng: 20.9494662 },
    { lat: 48.7643546, lng: 20.9493305 },
    { lat: 48.7645719, lng: 20.9490959 },
    { lat: 48.7710701, lng: 20.9451738 },
    { lat: 48.771803, lng: 20.944217 },
    { lat: 48.771726, lng: 20.944057 },
    { lat: 48.770995, lng: 20.943273 },
    { lat: 48.770868, lng: 20.943153 },
    { lat: 48.770811, lng: 20.943088 },
    { lat: 48.770388, lng: 20.942481 },
    { lat: 48.769644, lng: 20.941319 },
    { lat: 48.769388, lng: 20.940695 },
    { lat: 48.769256, lng: 20.940283 },
    { lat: 48.769219, lng: 20.940107 },
    { lat: 48.76916, lng: 20.939916 },
    { lat: 48.769073, lng: 20.939722 },
    { lat: 48.768985, lng: 20.939593 },
    { lat: 48.768911, lng: 20.939502 },
    { lat: 48.768659, lng: 20.939326 },
    { lat: 48.767586, lng: 20.938257 },
    { lat: 48.767035, lng: 20.937136 },
    { lat: 48.766553, lng: 20.934765 },
    { lat: 48.766332, lng: 20.93358 },
    { lat: 48.766155, lng: 20.931622 },
    { lat: 48.7644002, lng: 20.9284581 },
    { lat: 48.7638123, lng: 20.9285466 },
    { lat: 48.7631829, lng: 20.9284093 },
    { lat: 48.7626914, lng: 20.9285521 },
    { lat: 48.7616283, lng: 20.9290743 },
    { lat: 48.7613954, lng: 20.9291087 },
    { lat: 48.7611955, lng: 20.9293564 },
    { lat: 48.7610092, lng: 20.9297703 },
    { lat: 48.7608849, lng: 20.9299247 },
    { lat: 48.7608044, lng: 20.929739 },
    { lat: 48.7609204, lng: 20.9295442 },
    { lat: 48.7606372, lng: 20.9292874 },
    { lat: 48.7600091, lng: 20.9292245 },
    { lat: 48.7593669, lng: 20.9287378 },
    { lat: 48.7581019, lng: 20.9283723 },
    { lat: 48.7580568, lng: 20.9281753 },
    { lat: 48.757489, lng: 20.9278235 },
    { lat: 48.7575369, lng: 20.9276432 },
    { lat: 48.7571327, lng: 20.9272399 },
    { lat: 48.7568711, lng: 20.927091 },
    { lat: 48.7566933, lng: 20.9270294 },
    { lat: 48.7565435, lng: 20.9270559 },
    { lat: 48.7562904, lng: 20.9268964 },
    { lat: 48.755613, lng: 20.9266661 },
    { lat: 48.7552692, lng: 20.9264508 },
    { lat: 48.7546401, lng: 20.9266267 },
    { lat: 48.7544411, lng: 20.9267608 },
    { lat: 48.754385, lng: 20.9275039 },
    { lat: 48.7543319, lng: 20.9275305 },
    { lat: 48.753963, lng: 20.9269368 },
    { lat: 48.7536333, lng: 20.9260437 },
    { lat: 48.7531838, lng: 20.9256311 },
    { lat: 48.7524907, lng: 20.9244339 },
    { lat: 48.7519961, lng: 20.9238557 },
    { lat: 48.7517982, lng: 20.9237301 },
    { lat: 48.7511386, lng: 20.923734 },
    { lat: 48.7509161, lng: 20.9238763 },
    { lat: 48.7508163, lng: 20.9240856 },
    { lat: 48.7499588, lng: 20.9240915 },
    { lat: 48.7497255, lng: 20.9237814 },
    { lat: 48.749568, lng: 20.9238534 },
    { lat: 48.7493119, lng: 20.9237716 },
    { lat: 48.7483879, lng: 20.9231395 },
    { lat: 48.7483927, lng: 20.9233792 },
    { lat: 48.7478383, lng: 20.9234327 },
    { lat: 48.7477372, lng: 20.9231009 },
    { lat: 48.7478999, lng: 20.9229878 },
    { lat: 48.7474708, lng: 20.9228126 },
    { lat: 48.7469043, lng: 20.9229388 },
    { lat: 48.7467766, lng: 20.9230553 },
    { lat: 48.746853, lng: 20.9232498 },
    { lat: 48.7462655, lng: 20.9235252 },
    { lat: 48.7454497, lng: 20.9235276 },
    { lat: 48.7454027, lng: 20.9234403 },
    { lat: 48.7452889, lng: 20.9234369 },
    { lat: 48.745143, lng: 20.923511 },
    { lat: 48.7448719, lng: 20.923523 },
    { lat: 48.7443573, lng: 20.9238448 },
    { lat: 48.7441578, lng: 20.9239041 },
    { lat: 48.7437495, lng: 20.924013 },
    { lat: 48.7433589, lng: 20.9239804 },
    { lat: 48.743258, lng: 20.9236943 },
    { lat: 48.7426346, lng: 20.9235637 },
    { lat: 48.7418079, lng: 20.9230459 },
    { lat: 48.7414132, lng: 20.92303 },
    { lat: 48.7404238, lng: 20.9222284 },
    { lat: 48.7397693, lng: 20.9219455 },
    { lat: 48.7394834, lng: 20.9219797 },
    { lat: 48.7394832, lng: 20.9220435 },
    { lat: 48.7393572, lng: 20.9220425 },
    { lat: 48.7391139, lng: 20.9222396 },
    { lat: 48.738845, lng: 20.9226463 },
    { lat: 48.7386781, lng: 20.9231028 },
    { lat: 48.7383234, lng: 20.9241943 },
    { lat: 48.7383726, lng: 20.9245524 },
    { lat: 48.7386385, lng: 20.9246949 },
    { lat: 48.7387052, lng: 20.9249661 },
    { lat: 48.7385324, lng: 20.9250529 },
    { lat: 48.7383417, lng: 20.9249021 },
    { lat: 48.7382513, lng: 20.9249294 },
    { lat: 48.7380386, lng: 20.9247266 },
    { lat: 48.7377624, lng: 20.9246166 },
    { lat: 48.7375232, lng: 20.9248662 },
    { lat: 48.7368458, lng: 20.924938 },
    { lat: 48.7367419, lng: 20.9250238 },
    { lat: 48.7366471, lng: 20.9250176 },
    { lat: 48.7364935, lng: 20.925375 },
    { lat: 48.7362485, lng: 20.9255151 },
    { lat: 48.7360524, lng: 20.9258123 },
    { lat: 48.7359755, lng: 20.9261695 },
    { lat: 48.7359793, lng: 20.9266466 },
    { lat: 48.7358234, lng: 20.92673 },
    { lat: 48.7356277, lng: 20.9269961 },
    { lat: 48.7352071, lng: 20.9262765 },
    { lat: 48.7350621, lng: 20.9265865 },
    { lat: 48.7347192, lng: 20.9265673 },
    { lat: 48.7345909, lng: 20.9266985 },
    { lat: 48.7345155, lng: 20.9269688 },
    { lat: 48.7342535, lng: 20.9272683 },
    { lat: 48.7337599, lng: 20.9274183 },
    { lat: 48.733555, lng: 20.9275532 },
    { lat: 48.733265, lng: 20.9275356 },
    { lat: 48.7326879, lng: 20.9280076 },
    { lat: 48.7325158, lng: 20.9280808 },
    { lat: 48.7320982, lng: 20.9278459 },
    { lat: 48.7316148, lng: 20.9279334 },
    { lat: 48.7311922, lng: 20.9276141 },
    { lat: 48.7306903, lng: 20.9276571 },
    { lat: 48.7302102, lng: 20.9275358 },
    { lat: 48.7298238, lng: 20.9273595 },
    { lat: 48.7296154, lng: 20.927357 },
    { lat: 48.729459, lng: 20.9272931 },
    { lat: 48.7293464, lng: 20.9270604 },
    { lat: 48.7289549, lng: 20.9268653 },
    { lat: 48.7288116, lng: 20.9268824 },
    { lat: 48.7286625, lng: 20.9268062 },
    { lat: 48.7283916, lng: 20.9268492 },
    { lat: 48.727903, lng: 20.9274837 },
    { lat: 48.7275276, lng: 20.9278296 },
    { lat: 48.7272949, lng: 20.9275038 },
    { lat: 48.7271979, lng: 20.9275027 },
    { lat: 48.7270062, lng: 20.9273567 },
    { lat: 48.7268769, lng: 20.9271264 },
    { lat: 48.726316, lng: 20.926985 },
    { lat: 48.7261285, lng: 20.927064 },
    { lat: 48.7256532, lng: 20.9267558 },
    { lat: 48.7254472, lng: 20.9267525 },
    { lat: 48.7248206, lng: 20.9265334 },
    { lat: 48.7247236, lng: 20.9266275 },
    { lat: 48.7247116, lng: 20.9266702 },
    { lat: 48.7247644, lng: 20.9266982 },
    { lat: 48.7247151, lng: 20.9268456 },
    { lat: 48.7242442, lng: 20.9264292 },
    { lat: 48.7239924, lng: 20.9265381 },
    { lat: 48.723394, lng: 20.9263853 },
    { lat: 48.7225852, lng: 20.9260348 },
    { lat: 48.7225438, lng: 20.9261093 },
    { lat: 48.722321, lng: 20.9262085 },
    { lat: 48.7208335, lng: 20.925996 },
    { lat: 48.7200771, lng: 20.9260607 },
    { lat: 48.7193764, lng: 20.9260163 },
    { lat: 48.7191472, lng: 20.9261231 },
    { lat: 48.718838, lng: 20.9261352 },
    { lat: 48.7185036, lng: 20.9261321 },
    { lat: 48.7184685, lng: 20.9260564 },
    { lat: 48.7183217, lng: 20.9260474 },
    { lat: 48.7177232, lng: 20.9261909 },
    { lat: 48.7171479, lng: 20.9261846 },
    { lat: 48.7169103, lng: 20.9260821 },
    { lat: 48.7166643, lng: 20.9261948 },
    { lat: 48.7151029, lng: 20.9264275 },
    { lat: 48.7148175, lng: 20.9263375 },
    { lat: 48.7145773, lng: 20.9264263 },
    { lat: 48.7143726, lng: 20.9266035 },
    { lat: 48.7141915, lng: 20.9265063 },
    { lat: 48.7133978, lng: 20.9265682 },
    { lat: 48.7131328, lng: 20.9265166 },
    { lat: 48.712776, lng: 20.9268824 },
    { lat: 48.7122719, lng: 20.9271611 },
    { lat: 48.7118869, lng: 20.927292 },
    { lat: 48.7116213, lng: 20.9275234 },
    { lat: 48.7114102, lng: 20.9280383 },
    { lat: 48.7109268, lng: 20.9285603 },
    { lat: 48.7111315, lng: 20.9288532 },
    { lat: 48.7111867, lng: 20.9293558 },
    { lat: 48.7113652, lng: 20.929712 },
    { lat: 48.7111418, lng: 20.9297944 },
    { lat: 48.7109032, lng: 20.9296292 },
    { lat: 48.7108362, lng: 20.9293079 },
    { lat: 48.7105845, lng: 20.9290557 },
    { lat: 48.7103217, lng: 20.9290065 },
    { lat: 48.7100255, lng: 20.9292046 },
    { lat: 48.7098962, lng: 20.9295738 },
    { lat: 48.7097315, lng: 20.9296977 },
    { lat: 48.709648, lng: 20.9299273 },
    { lat: 48.7091883, lng: 20.9306477 },
    { lat: 48.7090141, lng: 20.93082 },
    { lat: 48.7087389, lng: 20.9308708 },
    { lat: 48.7084351, lng: 20.9310385 },
    { lat: 48.7085064, lng: 20.9311168 },
    { lat: 48.7083205, lng: 20.9313802 },
    { lat: 48.7078732, lng: 20.9314699 },
    { lat: 48.707383, lng: 20.9317543 },
    { lat: 48.7071698, lng: 20.9321766 },
    { lat: 48.7068931, lng: 20.9320764 },
    { lat: 48.7068154, lng: 20.9321091 },
    { lat: 48.7063872, lng: 20.932729 },
    { lat: 48.7061254, lng: 20.9326796 },
    { lat: 48.7059681, lng: 20.9327224 },
    { lat: 48.7058761, lng: 20.9326611 },
    { lat: 48.7053037, lng: 20.9327792 },
    { lat: 48.7052128, lng: 20.9327217 },
    { lat: 48.7047913, lng: 20.9328678 },
    { lat: 48.7044481, lng: 20.9328411 },
    { lat: 48.7038035, lng: 20.9330162 },
    { lat: 48.7036491, lng: 20.9331795 },
    { lat: 48.7032407, lng: 20.9331208 },
    { lat: 48.7029973, lng: 20.9330112 },
    { lat: 48.7028157, lng: 20.9332704 },
    { lat: 48.7019013, lng: 20.9334252 },
    { lat: 48.7016378, lng: 20.9335862 },
    { lat: 48.7015417, lng: 20.9335401 },
    { lat: 48.7010455, lng: 20.9336143 },
    { lat: 48.7009464, lng: 20.9335161 },
    { lat: 48.7009073, lng: 20.9335872 },
    { lat: 48.7005896, lng: 20.9336162 },
    { lat: 48.7004537, lng: 20.9337186 },
    { lat: 48.6997721, lng: 20.9337358 },
    { lat: 48.6994011, lng: 20.9335829 },
    { lat: 48.6991157, lng: 20.933596 },
    { lat: 48.6984711, lng: 20.933673 },
    { lat: 48.6982972, lng: 20.9338275 },
    { lat: 48.697781, lng: 20.9337379 },
    { lat: 48.6975175, lng: 20.9338365 },
    { lat: 48.6972235, lng: 20.9337291 },
    { lat: 48.6967581, lng: 20.933818 },
    { lat: 48.6965624, lng: 20.9339189 },
    { lat: 48.6959529, lng: 20.9339038 },
    { lat: 48.6955526, lng: 20.9340518 },
    { lat: 48.6953539, lng: 20.9342017 },
    { lat: 48.6951344, lng: 20.934799 },
    { lat: 48.6949063, lng: 20.9348843 },
    { lat: 48.6947126, lng: 20.9352458 },
    { lat: 48.6946604, lng: 20.9352655 },
    { lat: 48.6946701, lng: 20.9359521 },
    { lat: 48.6944154, lng: 20.9370794 },
    { lat: 48.6942981, lng: 20.9384786 },
    { lat: 48.6937349, lng: 20.9383673 },
    { lat: 48.6937015, lng: 20.9384119 },
    { lat: 48.6937034, lng: 20.9387716 },
    { lat: 48.6935507, lng: 20.9391159 },
    { lat: 48.6934785, lng: 20.9392788 },
    { lat: 48.6932226, lng: 20.9389984 },
    { lat: 48.6929608, lng: 20.9388098 },
    { lat: 48.6928093, lng: 20.9377857 },
    { lat: 48.6927555, lng: 20.937058 },
    { lat: 48.6927915, lng: 20.9366541 },
    { lat: 48.6926728, lng: 20.9358067 },
    { lat: 48.6926324, lng: 20.9349091 },
    { lat: 48.6924874, lng: 20.934114 },
    { lat: 48.692597, lng: 20.9337272 },
    { lat: 48.6926, lng: 20.9333814 },
    { lat: 48.692414, lng: 20.9323703 },
    { lat: 48.692184, lng: 20.9317969 },
    { lat: 48.6921043, lng: 20.9311347 },
    { lat: 48.6919904, lng: 20.9307284 },
    { lat: 48.6919701, lng: 20.9300584 },
    { lat: 48.6917599, lng: 20.9293998 },
    { lat: 48.6916391, lng: 20.928694 },
    { lat: 48.6914648, lng: 20.9285966 },
    { lat: 48.6913054, lng: 20.9281429 },
    { lat: 48.6911371, lng: 20.9279243 },
    { lat: 48.6909443, lng: 20.9271176 },
    { lat: 48.691, lng: 20.9264328 },
    { lat: 48.6909608, lng: 20.9257357 },
    { lat: 48.6911345, lng: 20.9252489 },
    { lat: 48.6910803, lng: 20.9247411 },
    { lat: 48.6913976, lng: 20.9230382 },
    { lat: 48.6915204, lng: 20.9226464 },
    { lat: 48.6916798, lng: 20.9209009 },
    { lat: 48.691868, lng: 20.9203085 },
    { lat: 48.6918257, lng: 20.9200883 },
    { lat: 48.6919857, lng: 20.9193328 },
    { lat: 48.692013, lng: 20.9188069 },
    { lat: 48.6918754, lng: 20.9187014 },
    { lat: 48.6916391, lng: 20.9188686 },
    { lat: 48.6913559, lng: 20.9191445 },
    { lat: 48.6912572, lng: 20.9193904 },
    { lat: 48.6908751, lng: 20.9198104 },
    { lat: 48.6904077, lng: 20.919834 },
    { lat: 48.6898358, lng: 20.919037 },
    { lat: 48.6894678, lng: 20.9187348 },
    { lat: 48.6893579, lng: 20.918547 },
    { lat: 48.6889958, lng: 20.9183227 },
    { lat: 48.6889725, lng: 20.918172 },
    { lat: 48.6890345, lng: 20.9180725 },
    { lat: 48.6891229, lng: 20.9173902 },
    { lat: 48.6891359, lng: 20.916812 },
    { lat: 48.6889091, lng: 20.9168292 },
    { lat: 48.6887967, lng: 20.9153719 },
    { lat: 48.6891761, lng: 20.9152759 },
    { lat: 48.6888645, lng: 20.9136537 },
    { lat: 48.6891546, lng: 20.9135148 },
    { lat: 48.6891382, lng: 20.9132596 },
    { lat: 48.6890607, lng: 20.9129 },
    { lat: 48.688994, lng: 20.9119283 },
    { lat: 48.6888518, lng: 20.9109902 },
    { lat: 48.6886818, lng: 20.9106881 },
    { lat: 48.6884338, lng: 20.9099125 },
    { lat: 48.6870742, lng: 20.9076671 },
    { lat: 48.6864001, lng: 20.9072053 },
    { lat: 48.6860238, lng: 20.9070638 },
    { lat: 48.6851853, lng: 20.9065366 },
    { lat: 48.6846277, lng: 20.906059 },
    { lat: 48.6843918, lng: 20.9057802 },
    { lat: 48.6840461, lng: 20.9055743 },
    { lat: 48.6838463, lng: 20.9050939 },
    { lat: 48.6833366, lng: 20.9044356 },
    { lat: 48.6833049, lng: 20.904266 },
    { lat: 48.683435, lng: 20.90389 },
    { lat: 48.6831492, lng: 20.9036628 },
    { lat: 48.6826963, lng: 20.902884 },
    { lat: 48.6826363, lng: 20.9026464 },
    { lat: 48.6823257, lng: 20.9022184 },
    { lat: 48.6821698, lng: 20.9018989 },
    { lat: 48.6816809, lng: 20.9017287 },
    { lat: 48.6807343, lng: 20.9016646 },
    { lat: 48.6806917, lng: 20.9019312 },
    { lat: 48.6804844, lng: 20.9021909 },
    { lat: 48.6800619, lng: 20.9022155 },
    { lat: 48.6799203, lng: 20.9022841 },
    { lat: 48.679538, lng: 20.9026322 },
    { lat: 48.6782791, lng: 20.9035764 },
    { lat: 48.6781704, lng: 20.9035275 },
    { lat: 48.6782251, lng: 20.9038748 },
    { lat: 48.6780949, lng: 20.9040213 },
    { lat: 48.6771659, lng: 20.9042404 },
    { lat: 48.6770478, lng: 20.9043461 },
    { lat: 48.6766789, lng: 20.9047821 },
    { lat: 48.67652, lng: 20.9052425 },
    { lat: 48.6761423, lng: 20.9057918 },
    { lat: 48.6759754, lng: 20.9061416 },
    { lat: 48.6754793, lng: 20.9066189 },
    { lat: 48.6746363, lng: 20.906849 },
    { lat: 48.6740886, lng: 20.9071329 },
    { lat: 48.6740815, lng: 20.9074515 },
    { lat: 48.6741765, lng: 20.9078947 },
    { lat: 48.6745591, lng: 20.9084916 },
    { lat: 48.6746571, lng: 20.9087892 },
    { lat: 48.6747942, lng: 20.9089852 },
    { lat: 48.6749302, lng: 20.9096061 },
    { lat: 48.6749045, lng: 20.9098083 },
    { lat: 48.6746385, lng: 20.910263 },
    { lat: 48.6744395, lng: 20.910439 },
    { lat: 48.6740833, lng: 20.9107237 },
    { lat: 48.6731815, lng: 20.9112511 },
    { lat: 48.6729914, lng: 20.9114528 },
    { lat: 48.672051, lng: 20.9130957 },
    { lat: 48.671894, lng: 20.9136136 },
    { lat: 48.6715559, lng: 20.9144307 },
    { lat: 48.6714582, lng: 20.9149372 },
    { lat: 48.6713168, lng: 20.9153794 },
    { lat: 48.6711685, lng: 20.9157217 },
    { lat: 48.6708514, lng: 20.9161291 },
    { lat: 48.6705614, lng: 20.9171372 },
    { lat: 48.670438, lng: 20.9184099 },
    { lat: 48.6704076, lng: 20.9195394 },
    { lat: 48.6701306, lng: 20.920699 },
    { lat: 48.6697272, lng: 20.92152 },
    { lat: 48.6694485, lng: 20.9218656 },
    { lat: 48.6682261, lng: 20.9222686 },
    { lat: 48.6681972, lng: 20.9222779 },
    { lat: 48.6682058, lng: 20.9223011 },
    { lat: 48.6683394, lng: 20.9230226 },
    { lat: 48.6687056, lng: 20.9244972 },
    { lat: 48.6689125, lng: 20.9251554 },
    { lat: 48.6690004, lng: 20.9256892 },
    { lat: 48.6692073, lng: 20.9263527 },
    { lat: 48.6703853, lng: 20.9308646 },
    { lat: 48.6705118, lng: 20.931013 },
    { lat: 48.6705179, lng: 20.9311607 },
    { lat: 48.670464, lng: 20.9315957 },
    { lat: 48.6702838, lng: 20.9322592 },
    { lat: 48.6699841, lng: 20.9329888 },
    { lat: 48.6699193, lng: 20.9335009 },
    { lat: 48.6699525, lng: 20.9338231 },
    { lat: 48.6698847, lng: 20.9342115 },
    { lat: 48.669551, lng: 20.9347991 },
    { lat: 48.669437, lng: 20.9352526 },
    { lat: 48.6690137, lng: 20.9358838 },
    { lat: 48.6690605, lng: 20.9362381 },
    { lat: 48.6690017, lng: 20.9366696 },
    { lat: 48.6685574, lng: 20.9373775 },
    { lat: 48.6687643, lng: 20.9379755 },
    { lat: 48.6687239, lng: 20.9381759 },
    { lat: 48.6688222, lng: 20.9382848 },
    { lat: 48.6695795, lng: 20.9375757 },
    { lat: 48.6697724, lng: 20.9370221 },
    { lat: 48.6699775, lng: 20.9368991 },
    { lat: 48.6701227, lng: 20.9369681 },
    { lat: 48.6701778, lng: 20.9370967 },
    { lat: 48.6701552, lng: 20.9373289 },
    { lat: 48.6703323, lng: 20.9379367 },
    { lat: 48.6699987, lng: 20.9391315 },
    { lat: 48.6700666, lng: 20.9392694 },
    { lat: 48.6703835, lng: 20.9405627 },
    { lat: 48.6702434, lng: 20.9413124 },
    { lat: 48.6701243, lng: 20.9415865 },
    { lat: 48.6700342, lng: 20.9420197 },
    { lat: 48.6699047, lng: 20.9430934 },
    { lat: 48.6697884, lng: 20.9434787 },
    { lat: 48.6697343, lng: 20.9438465 },
    { lat: 48.669723, lng: 20.9457188 },
    { lat: 48.6691962, lng: 20.9474297 },
    { lat: 48.6691704, lng: 20.9477283 },
    { lat: 48.6692834, lng: 20.9481762 },
    { lat: 48.6693062, lng: 20.948491 },
    { lat: 48.6690914, lng: 20.9497973 },
    { lat: 48.6682726, lng: 20.9520013 },
    { lat: 48.6678534, lng: 20.9529884 },
    { lat: 48.6677116, lng: 20.9534583 },
    { lat: 48.6676763, lng: 20.9540507 },
    { lat: 48.6678247, lng: 20.9542776 },
    { lat: 48.6678421, lng: 20.9545308 },
    { lat: 48.6677706, lng: 20.9552521 },
    { lat: 48.6678041, lng: 20.9558777 },
    { lat: 48.6682192, lng: 20.9560101 },
    { lat: 48.6677616, lng: 20.9576714 },
    { lat: 48.667243, lng: 20.9591593 },
    { lat: 48.6668922, lng: 20.9597401 },
    { lat: 48.6658937, lng: 20.961047 },
    { lat: 48.6667988, lng: 20.9624301 },
    { lat: 48.6664553, lng: 20.9629912 },
    { lat: 48.6670257, lng: 20.9639593 },
    { lat: 48.6661271, lng: 20.9651599 },
    { lat: 48.6652849, lng: 20.9661275 },
    { lat: 48.6662564, lng: 20.9678107 },
    { lat: 48.6664025, lng: 20.9683077 },
    { lat: 48.666479, lng: 20.9697551 },
    { lat: 48.6664027, lng: 20.9699375 },
    { lat: 48.6664041, lng: 20.9700767 },
    { lat: 48.6662384, lng: 20.9707438 },
    { lat: 48.6659903, lng: 20.972177 },
    { lat: 48.6660068, lng: 20.9734762 },
    { lat: 48.6661454, lng: 20.9742597 },
    { lat: 48.6662115, lng: 20.974416 },
    { lat: 48.6664685, lng: 20.9744282 },
    { lat: 48.6667683, lng: 20.9751464 },
    { lat: 48.6669247, lng: 20.9753189 },
    { lat: 48.6671908, lng: 20.9753347 },
    { lat: 48.6672951, lng: 20.9754564 },
    { lat: 48.6673916, lng: 20.9757442 },
    { lat: 48.6670853, lng: 20.9765527 },
    { lat: 48.667162, lng: 20.9781468 },
    { lat: 48.6670389, lng: 20.9787754 },
    { lat: 48.6670289, lng: 20.9790774 },
    { lat: 48.6659598, lng: 20.9800994 },
    { lat: 48.6657281, lng: 20.9794312 },
    { lat: 48.664449, lng: 20.9801248 },
    { lat: 48.6648777, lng: 20.9812046 },
    { lat: 48.6653755, lng: 20.9812074 },
    { lat: 48.666251, lng: 20.9814404 },
    { lat: 48.6664666, lng: 20.9813906 },
    { lat: 48.6666236, lng: 20.9812754 },
    { lat: 48.667448, lng: 20.9812562 },
    { lat: 48.6674325, lng: 20.9810943 },
    { lat: 48.6676411, lng: 20.9815453 },
    { lat: 48.6677387, lng: 20.9820738 },
    { lat: 48.6677136, lng: 20.9831472 },
    { lat: 48.667635, lng: 20.9838964 },
    { lat: 48.6675093, lng: 20.983975 },
    { lat: 48.6674457, lng: 20.9841603 },
    { lat: 48.6673705, lng: 20.9850132 },
    { lat: 48.6672462, lng: 20.9854653 },
    { lat: 48.6668417, lng: 20.9863014 },
    { lat: 48.6665858, lng: 20.9866303 },
    { lat: 48.6664563, lng: 20.9872679 },
    { lat: 48.6661055, lng: 20.9878517 },
    { lat: 48.6659722, lng: 20.9884825 },
    { lat: 48.6637449, lng: 20.989539 },
    { lat: 48.6634988, lng: 20.9898556 },
    { lat: 48.6633188, lng: 20.9906412 },
    { lat: 48.6629718, lng: 20.9909025 },
    { lat: 48.6629484, lng: 20.9911069 },
    { lat: 48.662735, lng: 20.9916001 },
    { lat: 48.6625318, lng: 20.9918149 },
    { lat: 48.6623374, lng: 20.9921327 },
    { lat: 48.6621896, lng: 20.9921577 },
    { lat: 48.6621776, lng: 20.9923363 },
    { lat: 48.6619707, lng: 20.9928522 },
    { lat: 48.6620147, lng: 20.9929194 },
    { lat: 48.6615505, lng: 20.9936862 },
    { lat: 48.6612474, lng: 20.9938786 },
    { lat: 48.6608929, lng: 20.9942893 },
    { lat: 48.6601512, lng: 20.9946986 },
    { lat: 48.6599233, lng: 20.9949181 },
    { lat: 48.6594456, lng: 20.9949061 },
    { lat: 48.6591155, lng: 20.9951031 },
    { lat: 48.6587832, lng: 20.995161 },
    { lat: 48.6585974, lng: 20.9952889 },
    { lat: 48.6584505, lng: 20.9952762 },
    { lat: 48.6582367, lng: 20.9954448 },
    { lat: 48.6580625, lng: 20.9954903 },
    { lat: 48.6576426, lng: 20.9958948 },
    { lat: 48.6571673, lng: 20.9961209 },
    { lat: 48.6566053, lng: 20.9966892 },
    { lat: 48.6563131, lng: 20.9968391 },
    { lat: 48.6560826, lng: 20.9971517 },
    { lat: 48.6558325, lng: 20.9973119 },
    { lat: 48.6552588, lng: 20.9982752 },
    { lat: 48.6551651, lng: 20.9986017 },
    { lat: 48.6549116, lng: 20.9991078 },
    { lat: 48.6548155, lng: 20.9994665 },
    { lat: 48.6544224, lng: 21.0001394 },
    { lat: 48.6540903, lng: 21.0005088 },
    { lat: 48.6536247, lng: 21.0007917 },
    { lat: 48.6537809, lng: 21.000922 },
    { lat: 48.6539216, lng: 21.0011609 },
    { lat: 48.6542088, lng: 21.0020135 },
    { lat: 48.6540648, lng: 21.0020152 },
    { lat: 48.65372, lng: 21.0021736 },
    { lat: 48.6541638, lng: 21.0033373 },
    { lat: 48.6541875, lng: 21.0035132 },
    { lat: 48.65436, lng: 21.0038925 },
    { lat: 48.65591, lng: 21.00304 },
    { lat: 48.6563958, lng: 21.0033743 },
    { lat: 48.6571105, lng: 21.0037088 },
    { lat: 48.6576964, lng: 21.0038668 },
    { lat: 48.6579167, lng: 21.0040228 },
    { lat: 48.6583723, lng: 21.0041821 },
    { lat: 48.6590634, lng: 21.0040851 },
    { lat: 48.6597221, lng: 21.003648 },
    { lat: 48.6602876, lng: 21.0035227 },
    { lat: 48.6605798, lng: 21.0035801 },
    { lat: 48.6611714, lng: 21.0038719 },
    { lat: 48.6615961, lng: 21.0042557 },
    { lat: 48.6621262, lng: 21.004628 },
    { lat: 48.6629709, lng: 21.0054911 },
    { lat: 48.6632035, lng: 21.0055875 },
    { lat: 48.6642291, lng: 21.0056586 },
    { lat: 48.6652184, lng: 21.0059251 },
    { lat: 48.6656271, lng: 21.0061371 },
    { lat: 48.6659686, lng: 21.0064574 },
    { lat: 48.6664278, lng: 21.0066906 },
    { lat: 48.6674082, lng: 21.0074075 },
    { lat: 48.6677304, lng: 21.0078219 },
    { lat: 48.6678644, lng: 21.0083014 },
    { lat: 48.6686023, lng: 21.0101929 },
    { lat: 48.6686798, lng: 21.0106984 },
    { lat: 48.6685386, lng: 21.0115719 },
    { lat: 48.668546, lng: 21.0117932 },
    { lat: 48.6674302, lng: 21.0136523 },
    { lat: 48.668477, lng: 21.0148181 },
    { lat: 48.6705062, lng: 21.0174708 },
    { lat: 48.6707629, lng: 21.0180138 },
    { lat: 48.6714839, lng: 21.018785 },
    { lat: 48.6716384, lng: 21.0192374 },
    { lat: 48.6716783, lng: 21.0192529 },
  ],
  KošickáPolianka: [
    { lat: 48.7043835, lng: 21.3404787 },
    { lat: 48.704138, lng: 21.3402106 },
    { lat: 48.70343, lng: 21.3396473 },
    { lat: 48.7036562, lng: 21.3383046 },
    { lat: 48.7036979, lng: 21.3371969 },
    { lat: 48.7035999, lng: 21.336107 },
    { lat: 48.7031185, lng: 21.3354851 },
    { lat: 48.7027765, lng: 21.3349304 },
    { lat: 48.7026161, lng: 21.3338684 },
    { lat: 48.7016675, lng: 21.3304482 },
    { lat: 48.700912, lng: 21.330152 },
    { lat: 48.700156, lng: 21.330189 },
    { lat: 48.699759, lng: 21.330058 },
    { lat: 48.699682, lng: 21.33005 },
    { lat: 48.699078, lng: 21.329993 },
    { lat: 48.696938, lng: 21.32979 },
    { lat: 48.696104, lng: 21.329746 },
    { lat: 48.696018, lng: 21.330248 },
    { lat: 48.695504, lng: 21.330308 },
    { lat: 48.695108, lng: 21.330005 },
    { lat: 48.694426, lng: 21.329641 },
    { lat: 48.69431, lng: 21.329665 },
    { lat: 48.694274, lng: 21.329672 },
    { lat: 48.693367, lng: 21.329806 },
    { lat: 48.692689, lng: 21.329798 },
    { lat: 48.691934, lng: 21.329694 },
    { lat: 48.690426, lng: 21.329238 },
    { lat: 48.689594, lng: 21.328836 },
    { lat: 48.689332, lng: 21.328953 },
    { lat: 48.688979, lng: 21.329524 },
    { lat: 48.688912, lng: 21.329634 },
    { lat: 48.688633, lng: 21.33069 },
    { lat: 48.688322, lng: 21.33095 },
    { lat: 48.687865, lng: 21.330584 },
    { lat: 48.687307, lng: 21.330762 },
    { lat: 48.687253, lng: 21.330732 },
    { lat: 48.687022, lng: 21.331101 },
    { lat: 48.687006, lng: 21.331127 },
    { lat: 48.686959, lng: 21.3312 },
    { lat: 48.686918, lng: 21.331268 },
    { lat: 48.68609, lng: 21.332328 },
    { lat: 48.684658, lng: 21.333494 },
    { lat: 48.684309, lng: 21.334626 },
    { lat: 48.683648, lng: 21.335148 },
    { lat: 48.683551, lng: 21.335015 },
    { lat: 48.683426, lng: 21.335095 },
    { lat: 48.683253, lng: 21.33527 },
    { lat: 48.683018, lng: 21.335687 },
    { lat: 48.682942, lng: 21.335863 },
    { lat: 48.682929, lng: 21.336234 },
    { lat: 48.683039, lng: 21.336366 },
    { lat: 48.682999, lng: 21.336763 },
    { lat: 48.682925, lng: 21.337021 },
    { lat: 48.682877, lng: 21.337189 },
    { lat: 48.68271, lng: 21.337693 },
    { lat: 48.682597, lng: 21.338079 },
    { lat: 48.682335, lng: 21.338409 },
    { lat: 48.682263, lng: 21.33856 },
    { lat: 48.682061, lng: 21.338721 },
    { lat: 48.681914, lng: 21.33869 },
    { lat: 48.681899, lng: 21.33869 },
    { lat: 48.681819, lng: 21.33869 },
    { lat: 48.681527, lng: 21.338645 },
    { lat: 48.681274, lng: 21.338816 },
    { lat: 48.681162, lng: 21.338922 },
    { lat: 48.6817278, lng: 21.3408034 },
    { lat: 48.681542, lng: 21.3460956 },
    { lat: 48.6816501, lng: 21.3468432 },
    { lat: 48.6815704, lng: 21.3469683 },
    { lat: 48.6818425, lng: 21.3497929 },
    { lat: 48.6819405, lng: 21.3502445 },
    { lat: 48.6821509, lng: 21.3508087 },
    { lat: 48.6824984, lng: 21.351474 },
    { lat: 48.6825791, lng: 21.3522127 },
    { lat: 48.6828411, lng: 21.3530672 },
    { lat: 48.6832939, lng: 21.3552068 },
    { lat: 48.684562, lng: 21.3582428 },
    { lat: 48.6846584, lng: 21.3586418 },
    { lat: 48.6847616, lng: 21.3595639 },
    { lat: 48.6847578, lng: 21.3600745 },
    { lat: 48.6846906, lng: 21.3605692 },
    { lat: 48.6848165, lng: 21.3612027 },
    { lat: 48.685067, lng: 21.3619053 },
    { lat: 48.6852925, lng: 21.362741 },
    { lat: 48.6857919, lng: 21.3640837 },
    { lat: 48.6862886, lng: 21.3659348 },
    { lat: 48.6864316, lng: 21.3662776 },
    { lat: 48.6868907, lng: 21.3669518 },
    { lat: 48.6869558, lng: 21.3672144 },
    { lat: 48.6870519, lng: 21.3677524 },
    { lat: 48.6869915, lng: 21.3685924 },
    { lat: 48.6870401, lng: 21.3696095 },
    { lat: 48.686726, lng: 21.3702488 },
    { lat: 48.6866089, lng: 21.3708495 },
    { lat: 48.6866234, lng: 21.3712556 },
    { lat: 48.6865498, lng: 21.3717681 },
    { lat: 48.6865852, lng: 21.3721232 },
    { lat: 48.6867642, lng: 21.3729519 },
    { lat: 48.6871836, lng: 21.3737282 },
    { lat: 48.6876044, lng: 21.3738815 },
    { lat: 48.6877497, lng: 21.3739951 },
    { lat: 48.6879867, lng: 21.373907 },
    { lat: 48.6887676, lng: 21.3735653 },
    { lat: 48.6892891, lng: 21.3739226 },
    { lat: 48.6890988, lng: 21.3760604 },
    { lat: 48.6892346, lng: 21.3779719 },
    { lat: 48.689732, lng: 21.3793339 },
    { lat: 48.6901314, lng: 21.379931 },
    { lat: 48.6905998, lng: 21.3809196 },
    { lat: 48.6912258, lng: 21.3819868 },
    { lat: 48.6916698, lng: 21.3821186 },
    { lat: 48.6929754, lng: 21.3817844 },
    { lat: 48.6938616, lng: 21.3812817 },
    { lat: 48.695149, lng: 21.3813957 },
    { lat: 48.6953391, lng: 21.3815459 },
    { lat: 48.6962543, lng: 21.3819652 },
    { lat: 48.6971216, lng: 21.3818474 },
    { lat: 48.6973119, lng: 21.3817379 },
    { lat: 48.698097, lng: 21.3817265 },
    { lat: 48.6986585, lng: 21.3820669 },
    { lat: 48.6999249, lng: 21.3833996 },
    { lat: 48.7001268, lng: 21.3836046 },
    { lat: 48.7003484, lng: 21.3837146 },
    { lat: 48.7012613, lng: 21.3839862 },
    { lat: 48.701761, lng: 21.3837976 },
    { lat: 48.7022653, lng: 21.3833519 },
    { lat: 48.7024044, lng: 21.383145 },
    { lat: 48.7025164, lng: 21.382567 },
    { lat: 48.702562, lng: 21.3824663 },
    { lat: 48.7027937, lng: 21.3809222 },
    { lat: 48.7027922, lng: 21.3796245 },
    { lat: 48.7028214, lng: 21.3793222 },
    { lat: 48.7029393, lng: 21.3788628 },
    { lat: 48.7029428, lng: 21.3786424 },
    { lat: 48.7030336, lng: 21.378286 },
    { lat: 48.7032317, lng: 21.3778361 },
    { lat: 48.7035071, lng: 21.3767296 },
    { lat: 48.7040283, lng: 21.3759516 },
    { lat: 48.7044774, lng: 21.3747482 },
    { lat: 48.7045264, lng: 21.3743522 },
    { lat: 48.7045121, lng: 21.3736848 },
    { lat: 48.7045658, lng: 21.3728154 },
    { lat: 48.7045301, lng: 21.3725599 },
    { lat: 48.7046447, lng: 21.3717552 },
    { lat: 48.7046521, lng: 21.3713705 },
    { lat: 48.7046543, lng: 21.370127 },
    { lat: 48.7045984, lng: 21.3691586 },
    { lat: 48.7046317, lng: 21.3686554 },
    { lat: 48.7044642, lng: 21.3678737 },
    { lat: 48.7044561, lng: 21.3665465 },
    { lat: 48.7045461, lng: 21.3657975 },
    { lat: 48.7045129, lng: 21.3648788 },
    { lat: 48.7045375, lng: 21.3641261 },
    { lat: 48.7046312, lng: 21.3638703 },
    { lat: 48.7047153, lng: 21.3634103 },
    { lat: 48.7049069, lng: 21.3630053 },
    { lat: 48.7049317, lng: 21.3627777 },
    { lat: 48.7050065, lng: 21.3626468 },
    { lat: 48.7052299, lng: 21.3625535 },
    { lat: 48.7053383, lng: 21.3619315 },
    { lat: 48.7056378, lng: 21.361295 },
    { lat: 48.7056611, lng: 21.3610956 },
    { lat: 48.7059324, lng: 21.3604247 },
    { lat: 48.7061084, lng: 21.3602145 },
    { lat: 48.7062955, lng: 21.3591724 },
    { lat: 48.7062066, lng: 21.3585771 },
    { lat: 48.7060336, lng: 21.3583606 },
    { lat: 48.7053685, lng: 21.3577976 },
    { lat: 48.7052742, lng: 21.3572422 },
    { lat: 48.7050522, lng: 21.3564141 },
    { lat: 48.7050404, lng: 21.3560533 },
    { lat: 48.705114, lng: 21.3553955 },
    { lat: 48.7049421, lng: 21.3539822 },
    { lat: 48.7042129, lng: 21.3539094 },
    { lat: 48.7045289, lng: 21.3530394 },
    { lat: 48.7043362, lng: 21.3528096 },
    { lat: 48.7041004, lng: 21.3526769 },
    { lat: 48.7040903, lng: 21.3515507 },
    { lat: 48.7041644, lng: 21.3509887 },
    { lat: 48.703851, lng: 21.3500315 },
    { lat: 48.7037947, lng: 21.3495876 },
    { lat: 48.7035009, lng: 21.3489465 },
    { lat: 48.7034293, lng: 21.3479453 },
    { lat: 48.7043918, lng: 21.3477705 },
    { lat: 48.7043317, lng: 21.3474582 },
    { lat: 48.7043964, lng: 21.3472896 },
    { lat: 48.7042339, lng: 21.346974 },
    { lat: 48.7042013, lng: 21.3467543 },
    { lat: 48.7041969, lng: 21.3464201 },
    { lat: 48.7043242, lng: 21.3462791 },
    { lat: 48.7042777, lng: 21.3458929 },
    { lat: 48.7043193, lng: 21.3455918 },
    { lat: 48.7042508, lng: 21.3454455 },
    { lat: 48.7041488, lng: 21.3453855 },
    { lat: 48.7041521, lng: 21.3452 },
    { lat: 48.7043159, lng: 21.3452187 },
    { lat: 48.7047593, lng: 21.3449965 },
    { lat: 48.7044453, lng: 21.3418233 },
    { lat: 48.7043103, lng: 21.3411867 },
    { lat: 48.704294, lng: 21.3408732 },
    { lat: 48.7043835, lng: 21.3404787 },
  ],
  Belža: [
    { lat: 48.5929121, lng: 21.2518667 },
    { lat: 48.592904, lng: 21.2518261 },
    { lat: 48.5928333, lng: 21.2517592 },
    { lat: 48.5902013, lng: 21.2525697 },
    { lat: 48.5892044, lng: 21.2531544 },
    { lat: 48.5887943, lng: 21.2535053 },
    { lat: 48.5884598, lng: 21.2538968 },
    { lat: 48.5879851, lng: 21.2541567 },
    { lat: 48.5875338, lng: 21.2541231 },
    { lat: 48.5863758, lng: 21.2534358 },
    { lat: 48.5860811, lng: 21.2533307 },
    { lat: 48.5856437, lng: 21.253305 },
    { lat: 48.5844802, lng: 21.2526984 },
    { lat: 48.5834252, lng: 21.2522851 },
    { lat: 48.5820182, lng: 21.251989 },
    { lat: 48.5815285, lng: 21.2519844 },
    { lat: 48.5813946, lng: 21.2520532 },
    { lat: 48.581015, lng: 21.2527339 },
    { lat: 48.5798496, lng: 21.2528196 },
    { lat: 48.5771419, lng: 21.2535172 },
    { lat: 48.5774646, lng: 21.2562272 },
    { lat: 48.577099, lng: 21.2563094 },
    { lat: 48.5772556, lng: 21.2576926 },
    { lat: 48.5771376, lng: 21.2576988 },
    { lat: 48.5772109, lng: 21.2587922 },
    { lat: 48.5770374, lng: 21.2588176 },
    { lat: 48.577098, lng: 21.2597985 },
    { lat: 48.5761406, lng: 21.2599163 },
    { lat: 48.5761935, lng: 21.2608231 },
    { lat: 48.5758101, lng: 21.2609111 },
    { lat: 48.5758845, lng: 21.2615982 },
    { lat: 48.575925, lng: 21.2626168 },
    { lat: 48.5758819, lng: 21.2689419 },
    { lat: 48.5760886, lng: 21.2713722 },
    { lat: 48.5757303, lng: 21.2713951 },
    { lat: 48.5756177, lng: 21.2715345 },
    { lat: 48.575528, lng: 21.2718386 },
    { lat: 48.5753706, lng: 21.2719584 },
    { lat: 48.5751917, lng: 21.2715175 },
    { lat: 48.5750355, lng: 21.2715611 },
    { lat: 48.5746062, lng: 21.2721978 },
    { lat: 48.5741987, lng: 21.2723063 },
    { lat: 48.5742849, lng: 21.2727683 },
    { lat: 48.5741166, lng: 21.2729301 },
    { lat: 48.573797, lng: 21.2730762 },
    { lat: 48.5735693, lng: 21.2729859 },
    { lat: 48.5733679, lng: 21.2731027 },
    { lat: 48.5732212, lng: 21.2730866 },
    { lat: 48.5731775, lng: 21.2730236 },
    { lat: 48.5731283, lng: 21.2726294 },
    { lat: 48.5729375, lng: 21.2723595 },
    { lat: 48.5727174, lng: 21.2724801 },
    { lat: 48.5725272, lng: 21.2723684 },
    { lat: 48.5724297, lng: 21.2724957 },
    { lat: 48.5724372, lng: 21.2727005 },
    { lat: 48.5723492, lng: 21.2727298 },
    { lat: 48.5721711, lng: 21.2726517 },
    { lat: 48.5721051, lng: 21.2726996 },
    { lat: 48.5724583, lng: 21.2739649 },
    { lat: 48.572624, lng: 21.2748502 },
    { lat: 48.5729458, lng: 21.2768935 },
    { lat: 48.5731514, lng: 21.2778347 },
    { lat: 48.5734619, lng: 21.2801279 },
    { lat: 48.5749754, lng: 21.2804546 },
    { lat: 48.5752152, lng: 21.2826437 },
    { lat: 48.5756076, lng: 21.2826365 },
    { lat: 48.5757197, lng: 21.2858443 },
    { lat: 48.5762509, lng: 21.2859152 },
    { lat: 48.5762659, lng: 21.2860347 },
    { lat: 48.5769698, lng: 21.2859806 },
    { lat: 48.5771833, lng: 21.286889 },
    { lat: 48.5776229, lng: 21.2875339 },
    { lat: 48.5777286, lng: 21.287781 },
    { lat: 48.577834, lng: 21.2885794 },
    { lat: 48.5794357, lng: 21.2889137 },
    { lat: 48.579434, lng: 21.2891878 },
    { lat: 48.5799349, lng: 21.2894127 },
    { lat: 48.5801137, lng: 21.2891898 },
    { lat: 48.5803934, lng: 21.2884755 },
    { lat: 48.5833429, lng: 21.2882466 },
    { lat: 48.5848579, lng: 21.2878868 },
    { lat: 48.5875741, lng: 21.2875779 },
    { lat: 48.5881112, lng: 21.2876584 },
    { lat: 48.5881323, lng: 21.2875012 },
    { lat: 48.59013, lng: 21.2884994 },
    { lat: 48.5901146, lng: 21.288947 },
    { lat: 48.5904063, lng: 21.2890254 },
    { lat: 48.5914633, lng: 21.2895841 },
    { lat: 48.593181, lng: 21.2901839 },
    { lat: 48.5943842, lng: 21.2904804 },
    { lat: 48.5946433, lng: 21.290633 },
    { lat: 48.5950665, lng: 21.2907453 },
    { lat: 48.5961449, lng: 21.2908653 },
    { lat: 48.596475, lng: 21.2892282 },
    { lat: 48.5971009, lng: 21.2895582 },
    { lat: 48.5976081, lng: 21.2869469 },
    { lat: 48.5979277, lng: 21.2849673 },
    { lat: 48.5979271, lng: 21.2849507 },
    { lat: 48.5978383, lng: 21.2838167 },
    { lat: 48.5964088, lng: 21.2822786 },
    { lat: 48.5963552, lng: 21.2821469 },
    { lat: 48.5958247, lng: 21.2819834 },
    { lat: 48.5945028, lng: 21.2812279 },
    { lat: 48.5944632, lng: 21.2800198 },
    { lat: 48.5945329, lng: 21.2789597 },
    { lat: 48.5935807, lng: 21.272711 },
    { lat: 48.5932258, lng: 21.2699635 },
    { lat: 48.5932542, lng: 21.2693845 },
    { lat: 48.5926621, lng: 21.2636668 },
    { lat: 48.5927789, lng: 21.2606826 },
    { lat: 48.5921559, lng: 21.2605163 },
    { lat: 48.5923423, lng: 21.2576267 },
    { lat: 48.5928539, lng: 21.2576522 },
    { lat: 48.5929026, lng: 21.2561322 },
    { lat: 48.5932081, lng: 21.255208 },
    { lat: 48.5929882, lng: 21.2534857 },
    { lat: 48.5929121, lng: 21.2518667 },
  ],
  Svinica: [
    { lat: 48.7537322, lng: 21.4929193 },
    { lat: 48.7538207, lng: 21.491736 },
    { lat: 48.7538459, lng: 21.4914009 },
    { lat: 48.753905, lng: 21.4912676 },
    { lat: 48.7540633, lng: 21.4858387 },
    { lat: 48.7540676, lng: 21.4834903 },
    { lat: 48.7541737, lng: 21.4824962 },
    { lat: 48.7544725, lng: 21.4808429 },
    { lat: 48.7544899, lng: 21.4805079 },
    { lat: 48.7551165, lng: 21.4780501 },
    { lat: 48.755288, lng: 21.4771401 },
    { lat: 48.7556385, lng: 21.4749388 },
    { lat: 48.7557925, lng: 21.4735806 },
    { lat: 48.7546886, lng: 21.4729189 },
    { lat: 48.7547234, lng: 21.4728755 },
    { lat: 48.755134, lng: 21.4677906 },
    { lat: 48.754937, lng: 21.4676746 },
    { lat: 48.7539309, lng: 21.4666592 },
    { lat: 48.7533791, lng: 21.4653276 },
    { lat: 48.753134, lng: 21.4649492 },
    { lat: 48.7531152, lng: 21.4647664 },
    { lat: 48.7522253, lng: 21.463267 },
    { lat: 48.7510814, lng: 21.4616893 },
    { lat: 48.75114, lng: 21.4615406 },
    { lat: 48.7509155, lng: 21.4608695 },
    { lat: 48.7507221, lng: 21.4605986 },
    { lat: 48.7505374, lng: 21.4604426 },
    { lat: 48.7500417, lng: 21.4596455 },
    { lat: 48.7494993, lng: 21.4580649 },
    { lat: 48.7490293, lng: 21.458307 },
    { lat: 48.748925, lng: 21.4582776 },
    { lat: 48.7479657, lng: 21.4584859 },
    { lat: 48.7458123, lng: 21.4586931 },
    { lat: 48.7457927, lng: 21.4583279 },
    { lat: 48.7458545, lng: 21.4582126 },
    { lat: 48.7459502, lng: 21.4575584 },
    { lat: 48.7459096, lng: 21.4574359 },
    { lat: 48.7457639, lng: 21.4573387 },
    { lat: 48.7450368, lng: 21.4572916 },
    { lat: 48.7450453, lng: 21.4572051 },
    { lat: 48.7444217, lng: 21.4570038 },
    { lat: 48.7444295, lng: 21.456496 },
    { lat: 48.7440279, lng: 21.4560393 },
    { lat: 48.7437, lng: 21.4558244 },
    { lat: 48.7426767, lng: 21.4523838 },
    { lat: 48.7416223, lng: 21.4526452 },
    { lat: 48.7412668, lng: 21.4527695 },
    { lat: 48.7412093, lng: 21.4528742 },
    { lat: 48.7406522, lng: 21.4531834 },
    { lat: 48.7404678, lng: 21.4531526 },
    { lat: 48.7401559, lng: 21.4533327 },
    { lat: 48.7396061, lng: 21.4540054 },
    { lat: 48.7392682, lng: 21.4539959 },
    { lat: 48.7390648, lng: 21.4541214 },
    { lat: 48.738775, lng: 21.4541767 },
    { lat: 48.7385858, lng: 21.4545547 },
    { lat: 48.7381899, lng: 21.4545223 },
    { lat: 48.738019, lng: 21.4546831 },
    { lat: 48.7378894, lng: 21.4546379 },
    { lat: 48.7372591, lng: 21.4548646 },
    { lat: 48.7371195, lng: 21.4549787 },
    { lat: 48.7370216, lng: 21.4551706 },
    { lat: 48.7366081, lng: 21.4555354 },
    { lat: 48.7363374, lng: 21.4555944 },
    { lat: 48.7361362, lng: 21.4555244 },
    { lat: 48.73602, lng: 21.455592 },
    { lat: 48.7359243, lng: 21.4557382 },
    { lat: 48.7357815, lng: 21.4557808 },
    { lat: 48.7357392, lng: 21.4559152 },
    { lat: 48.7355462, lng: 21.4560097 },
    { lat: 48.7353732, lng: 21.4560194 },
    { lat: 48.7352368, lng: 21.4561305 },
    { lat: 48.7349029, lng: 21.4562194 },
    { lat: 48.734815, lng: 21.4561318 },
    { lat: 48.7346831, lng: 21.4561271 },
    { lat: 48.7344585, lng: 21.4562198 },
    { lat: 48.7346308, lng: 21.4529452 },
    { lat: 48.7345421, lng: 21.4527989 },
    { lat: 48.7342638, lng: 21.450277 },
    { lat: 48.7336768, lng: 21.4502153 },
    { lat: 48.731942, lng: 21.4497615 },
    { lat: 48.7322149, lng: 21.4487467 },
    { lat: 48.7300203, lng: 21.4446172 },
    { lat: 48.7301133, lng: 21.4440663 },
    { lat: 48.7298813, lng: 21.439331 },
    { lat: 48.729733, lng: 21.439188 },
    { lat: 48.7295423, lng: 21.4391248 },
    { lat: 48.724267, lng: 21.4375952 },
    { lat: 48.7235381, lng: 21.4279444 },
    { lat: 48.7230851, lng: 21.4275418 },
    { lat: 48.723089, lng: 21.427368 },
    { lat: 48.7241049, lng: 21.4269793 },
    { lat: 48.7244318, lng: 21.4269167 },
    { lat: 48.7258862, lng: 21.4271668 },
    { lat: 48.7257433, lng: 21.4256734 },
    { lat: 48.7265006, lng: 21.4255986 },
    { lat: 48.7265345, lng: 21.4230042 },
    { lat: 48.7267231, lng: 21.4223767 },
    { lat: 48.7268731, lng: 21.4221634 },
    { lat: 48.7269602, lng: 21.4200358 },
    { lat: 48.7269058, lng: 21.4198003 },
    { lat: 48.7237714, lng: 21.4198176 },
    { lat: 48.7236824, lng: 21.419197 },
    { lat: 48.7235262, lng: 21.4189388 },
    { lat: 48.7232201, lng: 21.4189766 },
    { lat: 48.7229759, lng: 21.4189018 },
    { lat: 48.7226588, lng: 21.4189841 },
    { lat: 48.7223924, lng: 21.4188809 },
    { lat: 48.722339, lng: 21.4187084 },
    { lat: 48.7224366, lng: 21.4183162 },
    { lat: 48.722666, lng: 21.4181643 },
    { lat: 48.722675, lng: 21.4180446 },
    { lat: 48.7225146, lng: 21.4175704 },
    { lat: 48.722313, lng: 21.4174831 },
    { lat: 48.7222077, lng: 21.417585 },
    { lat: 48.7221559, lng: 21.4178811 },
    { lat: 48.7218408, lng: 21.4184551 },
    { lat: 48.7214373, lng: 21.418741 },
    { lat: 48.7213072, lng: 21.4189818 },
    { lat: 48.721276, lng: 21.4192989 },
    { lat: 48.721095, lng: 21.4194159 },
    { lat: 48.7207556, lng: 21.4193561 },
    { lat: 48.7206248, lng: 21.4195758 },
    { lat: 48.7206548, lng: 21.4198635 },
    { lat: 48.7202755, lng: 21.4201738 },
    { lat: 48.7200166, lng: 21.4201335 },
    { lat: 48.719836, lng: 21.4198439 },
    { lat: 48.7198247, lng: 21.4196155 },
    { lat: 48.7200202, lng: 21.419335 },
    { lat: 48.7200246, lng: 21.4192207 },
    { lat: 48.71996, lng: 21.4190832 },
    { lat: 48.7198079, lng: 21.4189648 },
    { lat: 48.719489, lng: 21.4188405 },
    { lat: 48.7193844, lng: 21.4189153 },
    { lat: 48.7190071, lng: 21.4187861 },
    { lat: 48.7189367, lng: 21.4186942 },
    { lat: 48.7184136, lng: 21.4187334 },
    { lat: 48.7182189, lng: 21.4188139 },
    { lat: 48.7181983, lng: 21.418987 },
    { lat: 48.7180239, lng: 21.419142 },
    { lat: 48.7180153, lng: 21.4192345 },
    { lat: 48.7184956, lng: 21.4199142 },
    { lat: 48.71875, lng: 21.4201461 },
    { lat: 48.7192419, lng: 21.4203756 },
    { lat: 48.7194196, lng: 21.420302 },
    { lat: 48.719439, lng: 21.4201208 },
    { lat: 48.7196294, lng: 21.420243 },
    { lat: 48.7196828, lng: 21.4204464 },
    { lat: 48.7196037, lng: 21.4209731 },
    { lat: 48.7195018, lng: 21.4211799 },
    { lat: 48.7193494, lng: 21.4217778 },
    { lat: 48.7193671, lng: 21.4220339 },
    { lat: 48.7192164, lng: 21.4226823 },
    { lat: 48.7193207, lng: 21.4230926 },
    { lat: 48.7193349, lng: 21.4239971 },
    { lat: 48.7193876, lng: 21.4244119 },
    { lat: 48.7193058, lng: 21.4248023 },
    { lat: 48.7193027, lng: 21.4250434 },
    { lat: 48.7193847, lng: 21.4258009 },
    { lat: 48.7196099, lng: 21.4266129 },
    { lat: 48.7195883, lng: 21.4273295 },
    { lat: 48.7197734, lng: 21.4283769 },
    { lat: 48.7197087, lng: 21.4290095 },
    { lat: 48.7199954, lng: 21.4299606 },
    { lat: 48.7200142, lng: 21.4304807 },
    { lat: 48.7199362, lng: 21.430821 },
    { lat: 48.7199476, lng: 21.4311267 },
    { lat: 48.7200117, lng: 21.4316658 },
    { lat: 48.7200641, lng: 21.4316812 },
    { lat: 48.7201832, lng: 21.4323635 },
    { lat: 48.720386, lng: 21.4344863 },
    { lat: 48.7203203, lng: 21.435347 },
    { lat: 48.7206391, lng: 21.4365131 },
    { lat: 48.7210488, lng: 21.4372889 },
    { lat: 48.7210226, lng: 21.4376181 },
    { lat: 48.7203666, lng: 21.4381553 },
    { lat: 48.7200708, lng: 21.4385457 },
    { lat: 48.7200237, lng: 21.4385478 },
    { lat: 48.7199781, lng: 21.4386831 },
    { lat: 48.7199907, lng: 21.4394267 },
    { lat: 48.7200536, lng: 21.4395675 },
    { lat: 48.7205489, lng: 21.4400796 },
    { lat: 48.7207735, lng: 21.4406401 },
    { lat: 48.7209833, lng: 21.4409582 },
    { lat: 48.721004, lng: 21.4410954 },
    { lat: 48.721673, lng: 21.4412641 },
    { lat: 48.7218771, lng: 21.4414714 },
    { lat: 48.7221373, lng: 21.4414436 },
    { lat: 48.7222636, lng: 21.4412634 },
    { lat: 48.7225998, lng: 21.4411393 },
    { lat: 48.7233208, lng: 21.4414343 },
    { lat: 48.7228212, lng: 21.4427008 },
    { lat: 48.7237473, lng: 21.4440505 },
    { lat: 48.7240265, lng: 21.4432845 },
    { lat: 48.7241654, lng: 21.4435357 },
    { lat: 48.7243733, lng: 21.4441383 },
    { lat: 48.7244916, lng: 21.4446821 },
    { lat: 48.7246559, lng: 21.4451081 },
    { lat: 48.7246434, lng: 21.4452536 },
    { lat: 48.7248116, lng: 21.4460068 },
    { lat: 48.7255338, lng: 21.4474975 },
    { lat: 48.7263094, lng: 21.4483934 },
    { lat: 48.726994, lng: 21.4495669 },
    { lat: 48.7275019, lng: 21.4515449 },
    { lat: 48.7268181, lng: 21.4536763 },
    { lat: 48.7267891, lng: 21.4536422 },
    { lat: 48.7267691, lng: 21.4536941 },
    { lat: 48.7268056, lng: 21.453748 },
    { lat: 48.7267939, lng: 21.4539634 },
    { lat: 48.7263007, lng: 21.4547964 },
    { lat: 48.7260691, lng: 21.4547302 },
    { lat: 48.7259077, lng: 21.4550977 },
    { lat: 48.7257859, lng: 21.4551668 },
    { lat: 48.725525, lng: 21.4554796 },
    { lat: 48.725302, lng: 21.4556271 },
    { lat: 48.7252647, lng: 21.4560031 },
    { lat: 48.725157, lng: 21.456177 },
    { lat: 48.725088, lng: 21.4565434 },
    { lat: 48.7249893, lng: 21.4567071 },
    { lat: 48.7247553, lng: 21.456908 },
    { lat: 48.7243886, lng: 21.4568344 },
    { lat: 48.7238052, lng: 21.4568968 },
    { lat: 48.7231542, lng: 21.4576102 },
    { lat: 48.7229132, lng: 21.4577545 },
    { lat: 48.7226837, lng: 21.4576387 },
    { lat: 48.7224561, lng: 21.4577804 },
    { lat: 48.7223576, lng: 21.458227 },
    { lat: 48.7222347, lng: 21.4584854 },
    { lat: 48.7218586, lng: 21.4587852 },
    { lat: 48.721648, lng: 21.4587433 },
    { lat: 48.7213382, lng: 21.4584221 },
    { lat: 48.7211956, lng: 21.4583511 },
    { lat: 48.7210115, lng: 21.4584799 },
    { lat: 48.7208981, lng: 21.4586824 },
    { lat: 48.7206494, lng: 21.4586469 },
    { lat: 48.7204675, lng: 21.4588126 },
    { lat: 48.7205983, lng: 21.4590933 },
    { lat: 48.7205565, lng: 21.459204 },
    { lat: 48.7201195, lng: 21.459268 },
    { lat: 48.7198481, lng: 21.4598 },
    { lat: 48.7193936, lng: 21.4600397 },
    { lat: 48.7191691, lng: 21.4600942 },
    { lat: 48.7185402, lng: 21.460676 },
    { lat: 48.7183831, lng: 21.460691 },
    { lat: 48.7182663, lng: 21.4608157 },
    { lat: 48.7182955, lng: 21.4613893 },
    { lat: 48.7181106, lng: 21.4616171 },
    { lat: 48.7181275, lng: 21.4617671 },
    { lat: 48.718316, lng: 21.4621765 },
    { lat: 48.7182511, lng: 21.4623853 },
    { lat: 48.7182238, lng: 21.4627186 },
    { lat: 48.7181339, lng: 21.4629512 },
    { lat: 48.7182171, lng: 21.4634516 },
    { lat: 48.7180113, lng: 21.4635355 },
    { lat: 48.7176875, lng: 21.4655928 },
    { lat: 48.71747, lng: 21.4656611 },
    { lat: 48.7173176, lng: 21.4660325 },
    { lat: 48.7173374, lng: 21.4663216 },
    { lat: 48.7174493, lng: 21.4665394 },
    { lat: 48.7175112, lng: 21.4669244 },
    { lat: 48.7174575, lng: 21.4670466 },
    { lat: 48.7172477, lng: 21.4672284 },
    { lat: 48.7171407, lng: 21.4674554 },
    { lat: 48.7169709, lng: 21.4680788 },
    { lat: 48.7171325, lng: 21.4683738 },
    { lat: 48.717136, lng: 21.4685268 },
    { lat: 48.7169396, lng: 21.4690566 },
    { lat: 48.7167787, lng: 21.4690533 },
    { lat: 48.716693, lng: 21.4692041 },
    { lat: 48.7166943, lng: 21.4695158 },
    { lat: 48.7162911, lng: 21.4698889 },
    { lat: 48.7160255, lng: 21.4700476 },
    { lat: 48.715786, lng: 21.4700492 },
    { lat: 48.7156728, lng: 21.4705706 },
    { lat: 48.715575, lng: 21.4706936 },
    { lat: 48.7154462, lng: 21.4707474 },
    { lat: 48.715362, lng: 21.4709094 },
    { lat: 48.7151051, lng: 21.4710201 },
    { lat: 48.714834, lng: 21.4708988 },
    { lat: 48.7144414, lng: 21.4709084 },
    { lat: 48.7140841, lng: 21.4706136 },
    { lat: 48.7134858, lng: 21.4708596 },
    { lat: 48.7133338, lng: 21.4710101 },
    { lat: 48.7132031, lng: 21.4708858 },
    { lat: 48.712978, lng: 21.4701507 },
    { lat: 48.7128627, lng: 21.4701338 },
    { lat: 48.7127585, lng: 21.4704927 },
    { lat: 48.7122035, lng: 21.4704116 },
    { lat: 48.7117188, lng: 21.4706914 },
    { lat: 48.7115361, lng: 21.4711792 },
    { lat: 48.7112455, lng: 21.4714054 },
    { lat: 48.7111575, lng: 21.4715474 },
    { lat: 48.7105561, lng: 21.471765 },
    { lat: 48.7104782, lng: 21.4720702 },
    { lat: 48.7101063, lng: 21.4723906 },
    { lat: 48.7099069, lng: 21.4723849 },
    { lat: 48.7096116, lng: 21.4725206 },
    { lat: 48.7092697, lng: 21.4721407 },
    { lat: 48.7092028, lng: 21.4719317 },
    { lat: 48.709126, lng: 21.4718456 },
    { lat: 48.7090818, lng: 21.4714114 },
    { lat: 48.7085608, lng: 21.4712507 },
    { lat: 48.7081932, lng: 21.4710335 },
    { lat: 48.7080349, lng: 21.4711609 },
    { lat: 48.7078223, lng: 21.4710816 },
    { lat: 48.7076989, lng: 21.4712167 },
    { lat: 48.707494, lng: 21.4705501 },
    { lat: 48.7073485, lng: 21.4704625 },
    { lat: 48.7070306, lng: 21.4705157 },
    { lat: 48.7069805, lng: 21.4704214 },
    { lat: 48.7065261, lng: 21.4703475 },
    { lat: 48.7064812, lng: 21.4701994 },
    { lat: 48.7065149, lng: 21.4700044 },
    { lat: 48.7064505, lng: 21.4699538 },
    { lat: 48.706336, lng: 21.4699244 },
    { lat: 48.7062554, lng: 21.469985 },
    { lat: 48.7061348, lng: 21.4698982 },
    { lat: 48.7060149, lng: 21.4696821 },
    { lat: 48.7055881, lng: 21.4696341 },
    { lat: 48.7054459, lng: 21.4696892 },
    { lat: 48.7053205, lng: 21.4694798 },
    { lat: 48.7047581, lng: 21.4699179 },
    { lat: 48.7046628, lng: 21.4698932 },
    { lat: 48.7044885, lng: 21.4699732 },
    { lat: 48.704268, lng: 21.469938 },
    { lat: 48.7039285, lng: 21.4701422 },
    { lat: 48.7037248, lng: 21.4704129 },
    { lat: 48.7035644, lng: 21.4704659 },
    { lat: 48.7035182, lng: 21.4705388 },
    { lat: 48.7034567, lng: 21.4704773 },
    { lat: 48.7031616, lng: 21.470815 },
    { lat: 48.7028433, lng: 21.470782 },
    { lat: 48.702793, lng: 21.470868 },
    { lat: 48.7027748, lng: 21.4712493 },
    { lat: 48.7027436, lng: 21.4712602 },
    { lat: 48.7025933, lng: 21.4710878 },
    { lat: 48.7023863, lng: 21.4713566 },
    { lat: 48.7023412, lng: 21.4715901 },
    { lat: 48.7021729, lng: 21.4718769 },
    { lat: 48.7018916, lng: 21.4720698 },
    { lat: 48.7018953, lng: 21.4722708 },
    { lat: 48.7019885, lng: 21.4723067 },
    { lat: 48.7018538, lng: 21.4726683 },
    { lat: 48.7018931, lng: 21.472821 },
    { lat: 48.7018288, lng: 21.4730094 },
    { lat: 48.7017484, lng: 21.4730685 },
    { lat: 48.7017485, lng: 21.4731832 },
    { lat: 48.7015805, lng: 21.4732125 },
    { lat: 48.7015132, lng: 21.4736081 },
    { lat: 48.7009769, lng: 21.4740512 },
    { lat: 48.700835, lng: 21.4740428 },
    { lat: 48.7007278, lng: 21.4741115 },
    { lat: 48.7005075, lng: 21.4747032 },
    { lat: 48.700582, lng: 21.474907 },
    { lat: 48.7000859, lng: 21.4758403 },
    { lat: 48.6999087, lng: 21.476365 },
    { lat: 48.6998356, lng: 21.4764785 },
    { lat: 48.6997546, lng: 21.4764404 },
    { lat: 48.6996625, lng: 21.4765274 },
    { lat: 48.6994866, lng: 21.4765198 },
    { lat: 48.6992088, lng: 21.4768669 },
    { lat: 48.6990275, lng: 21.4769402 },
    { lat: 48.6990356, lng: 21.4772613 },
    { lat: 48.6989373, lng: 21.4773608 },
    { lat: 48.6989626, lng: 21.4777296 },
    { lat: 48.6988711, lng: 21.4779152 },
    { lat: 48.698869, lng: 21.4781511 },
    { lat: 48.6989094, lng: 21.4782599 },
    { lat: 48.6988409, lng: 21.4784255 },
    { lat: 48.6986878, lng: 21.4785777 },
    { lat: 48.6986734, lng: 21.4790499 },
    { lat: 48.6985755, lng: 21.4792448 },
    { lat: 48.6983131, lng: 21.4793512 },
    { lat: 48.6982574, lng: 21.479528 },
    { lat: 48.6983012, lng: 21.4800698 },
    { lat: 48.6985588, lng: 21.4804891 },
    { lat: 48.6985526, lng: 21.4805543 },
    { lat: 48.6987366, lng: 21.4807263 },
    { lat: 48.6989553, lng: 21.4808198 },
    { lat: 48.699102, lng: 21.4816098 },
    { lat: 48.6995064, lng: 21.4823293 },
    { lat: 48.6995689, lng: 21.4825802 },
    { lat: 48.6997085, lng: 21.4828517 },
    { lat: 48.6996652, lng: 21.4834149 },
    { lat: 48.6995537, lng: 21.4835704 },
    { lat: 48.6994827, lng: 21.4840763 },
    { lat: 48.6993771, lng: 21.4843536 },
    { lat: 48.6992673, lng: 21.4844054 },
    { lat: 48.699268, lng: 21.4847797 },
    { lat: 48.6991794, lng: 21.4849434 },
    { lat: 48.6992028, lng: 21.4850512 },
    { lat: 48.6990557, lng: 21.4851859 },
    { lat: 48.699019, lng: 21.4854334 },
    { lat: 48.6988052, lng: 21.4857019 },
    { lat: 48.6988009, lng: 21.4859045 },
    { lat: 48.6985223, lng: 21.4863831 },
    { lat: 48.6985308, lng: 21.4866034 },
    { lat: 48.6984492, lng: 21.4868951 },
    { lat: 48.6982879, lng: 21.4871868 },
    { lat: 48.698263, lng: 21.4874606 },
    { lat: 48.6980366, lng: 21.4878748 },
    { lat: 48.6977866, lng: 21.4884505 },
    { lat: 48.6977686, lng: 21.4886142 },
    { lat: 48.6976885, lng: 21.4886786 },
    { lat: 48.697657, lng: 21.4887975 },
    { lat: 48.6977045, lng: 21.4891943 },
    { lat: 48.6975418, lng: 21.4896827 },
    { lat: 48.697571, lng: 21.4898678 },
    { lat: 48.6975268, lng: 21.4902152 },
    { lat: 48.6975848, lng: 21.4903143 },
    { lat: 48.697348, lng: 21.4907616 },
    { lat: 48.6974168, lng: 21.4908832 },
    { lat: 48.6972625, lng: 21.4915815 },
    { lat: 48.697281, lng: 21.4918684 },
    { lat: 48.6971842, lng: 21.4922185 },
    { lat: 48.6972409, lng: 21.4923911 },
    { lat: 48.6970771, lng: 21.493073 },
    { lat: 48.6970438, lng: 21.4933252 },
    { lat: 48.697082, lng: 21.4934719 },
    { lat: 48.696974, lng: 21.4935937 },
    { lat: 48.6967117, lng: 21.4949139 },
    { lat: 48.6966658, lng: 21.4952361 },
    { lat: 48.6966771, lng: 21.4960938 },
    { lat: 48.6966187, lng: 21.4966034 },
    { lat: 48.6964439, lng: 21.4974516 },
    { lat: 48.6964512, lng: 21.49786 },
    { lat: 48.6963025, lng: 21.4984016 },
    { lat: 48.6963859, lng: 21.4994546 },
    { lat: 48.6965473, lng: 21.4995601 },
    { lat: 48.6966998, lng: 21.4995387 },
    { lat: 48.6969086, lng: 21.4999143 },
    { lat: 48.6970454, lng: 21.4999716 },
    { lat: 48.6979787, lng: 21.5011188 },
    { lat: 48.6984815, lng: 21.5020906 },
    { lat: 48.6985802, lng: 21.5021585 },
    { lat: 48.6988456, lng: 21.5028197 },
    { lat: 48.6994005, lng: 21.5034864 },
    { lat: 48.6998684, lng: 21.5039497 },
    { lat: 48.700333, lng: 21.5046501 },
    { lat: 48.7008145, lng: 21.5055112 },
    { lat: 48.7005774, lng: 21.5063012 },
    { lat: 48.701211, lng: 21.5067797 },
    { lat: 48.7013139, lng: 21.5079716 },
    { lat: 48.7014359, lng: 21.5083765 },
    { lat: 48.7014879, lng: 21.5087759 },
    { lat: 48.7017384, lng: 21.5092286 },
    { lat: 48.7018371, lng: 21.509728 },
    { lat: 48.7019409, lng: 21.5113481 },
    { lat: 48.7018482, lng: 21.5117663 },
    { lat: 48.7019969, lng: 21.5125929 },
    { lat: 48.7019253, lng: 21.5140574 },
    { lat: 48.7020174, lng: 21.5146395 },
    { lat: 48.7018617, lng: 21.5155723 },
    { lat: 48.7019418, lng: 21.5158499 },
    { lat: 48.7019677, lng: 21.5169097 },
    { lat: 48.7019019, lng: 21.5179574 },
    { lat: 48.7016399, lng: 21.5184337 },
    { lat: 48.7015192, lng: 21.5190518 },
    { lat: 48.7015509, lng: 21.5194501 },
    { lat: 48.7015176, lng: 21.5198195 },
    { lat: 48.7016669, lng: 21.520322 },
    { lat: 48.7016273, lng: 21.5205846 },
    { lat: 48.7015497, lng: 21.5205844 },
    { lat: 48.7017777, lng: 21.5213603 },
    { lat: 48.7017842, lng: 21.5216267 },
    { lat: 48.7020558, lng: 21.521917 },
    { lat: 48.7029505, lng: 21.5219609 },
    { lat: 48.7034284, lng: 21.5218274 },
    { lat: 48.7036167, lng: 21.521922 },
    { lat: 48.7039635, lng: 21.5218566 },
    { lat: 48.7044909, lng: 21.5219208 },
    { lat: 48.7046911, lng: 21.5227456 },
    { lat: 48.7046296, lng: 21.5233877 },
    { lat: 48.7047423, lng: 21.5239421 },
    { lat: 48.7052046, lng: 21.5252735 },
    { lat: 48.7052391, lng: 21.5262095 },
    { lat: 48.7051577, lng: 21.5268287 },
    { lat: 48.7051619, lng: 21.5272049 },
    { lat: 48.7052627, lng: 21.5278624 },
    { lat: 48.7051704, lng: 21.5290461 },
    { lat: 48.705019, lng: 21.5294525 },
    { lat: 48.7048945, lng: 21.5300626 },
    { lat: 48.7045634, lng: 21.5302089 },
    { lat: 48.7044203, lng: 21.5308302 },
    { lat: 48.704349, lng: 21.5312894 },
    { lat: 48.7043168, lng: 21.5319801 },
    { lat: 48.7039603, lng: 21.5339558 },
    { lat: 48.703646, lng: 21.5346926 },
    { lat: 48.7034362, lng: 21.5350725 },
    { lat: 48.703236, lng: 21.535309 },
    { lat: 48.703195, lng: 21.535739 },
    { lat: 48.703236, lng: 21.536089 },
    { lat: 48.703324, lng: 21.536559 },
    { lat: 48.703444, lng: 21.537048 },
    { lat: 48.703668, lng: 21.537478 },
    { lat: 48.703823, lng: 21.53782 },
    { lat: 48.703866, lng: 21.538309 },
    { lat: 48.70394, lng: 21.538707 },
    { lat: 48.703941, lng: 21.539325 },
    { lat: 48.703896, lng: 21.539765 },
    { lat: 48.703982, lng: 21.540101 },
    { lat: 48.703986, lng: 21.540118 },
    { lat: 48.704091, lng: 21.540356 },
    { lat: 48.704218, lng: 21.540802 },
    { lat: 48.703927, lng: 21.54147 },
    { lat: 48.703774, lng: 21.542153 },
    { lat: 48.703592, lng: 21.542707 },
    { lat: 48.703608, lng: 21.542724 },
    { lat: 48.703931, lng: 21.542911 },
    { lat: 48.70423, lng: 21.543246 },
    { lat: 48.704477, lng: 21.543483 },
    { lat: 48.704699, lng: 21.543656 },
    { lat: 48.704921, lng: 21.543707 },
    { lat: 48.705001, lng: 21.543788 },
    { lat: 48.70508, lng: 21.544194 },
    { lat: 48.705072, lng: 21.54464 },
    { lat: 48.70503, lng: 21.544929 },
    { lat: 48.705079, lng: 21.545169 },
    { lat: 48.705262, lng: 21.545503 },
    { lat: 48.705325, lng: 21.545571 },
    { lat: 48.705424, lng: 21.545622 },
    { lat: 48.705593, lng: 21.545561 },
    { lat: 48.705723, lng: 21.545559 },
    { lat: 48.70597, lng: 21.545861 },
    { lat: 48.706077, lng: 21.546063 },
    { lat: 48.706151, lng: 21.546135 },
    { lat: 48.70636, lng: 21.546181 },
    { lat: 48.706485, lng: 21.54616 },
    { lat: 48.706532, lng: 21.546092 },
    { lat: 48.706778, lng: 21.546031 },
    { lat: 48.707201, lng: 21.546154 },
    { lat: 48.707282, lng: 21.546121 },
    { lat: 48.707535, lng: 21.54614 },
    { lat: 48.707733, lng: 21.546247 },
    { lat: 48.707861, lng: 21.546293 },
    { lat: 48.708064, lng: 21.546375 },
    { lat: 48.708158, lng: 21.546494 },
    { lat: 48.708251, lng: 21.546631 },
    { lat: 48.708392, lng: 21.54702 },
    { lat: 48.708523, lng: 21.547252 },
    { lat: 48.708644, lng: 21.547393 },
    { lat: 48.70873, lng: 21.547565 },
    { lat: 48.7089, lng: 21.547965 },
    { lat: 48.708917, lng: 21.548012 },
    { lat: 48.708917, lng: 21.548086 },
    { lat: 48.708942, lng: 21.548045 },
    { lat: 48.709071, lng: 21.547537 },
    { lat: 48.710772, lng: 21.545863 },
    { lat: 48.711206, lng: 21.545458 },
    { lat: 48.711449, lng: 21.545251 },
    { lat: 48.712031, lng: 21.544525 },
    { lat: 48.712094, lng: 21.544427 },
    { lat: 48.7123884, lng: 21.5398152 },
    { lat: 48.7127726, lng: 21.539869 },
    { lat: 48.7130628, lng: 21.5395106 },
    { lat: 48.7133238, lng: 21.5394704 },
    { lat: 48.7137694, lng: 21.5391205 },
    { lat: 48.7140017, lng: 21.539058 },
    { lat: 48.7142055, lng: 21.5386289 },
    { lat: 48.714394, lng: 21.538565 },
    { lat: 48.7151567, lng: 21.537381 },
    { lat: 48.7157877, lng: 21.5372308 },
    { lat: 48.7162853, lng: 21.5369025 },
    { lat: 48.7167635, lng: 21.5363487 },
    { lat: 48.7169038, lng: 21.5356647 },
    { lat: 48.7171374, lng: 21.5355689 },
    { lat: 48.7172768, lng: 21.5352891 },
    { lat: 48.7175879, lng: 21.5351936 },
    { lat: 48.7175852, lng: 21.5349358 },
    { lat: 48.7176711, lng: 21.5347948 },
    { lat: 48.7179803, lng: 21.5347067 },
    { lat: 48.7183757, lng: 21.5343257 },
    { lat: 48.7184106, lng: 21.5340544 },
    { lat: 48.7186319, lng: 21.5339167 },
    { lat: 48.7186754, lng: 21.5337474 },
    { lat: 48.7188467, lng: 21.5335635 },
    { lat: 48.7190285, lng: 21.5335452 },
    { lat: 48.7192166, lng: 21.5333527 },
    { lat: 48.719366, lng: 21.5330276 },
    { lat: 48.7195599, lng: 21.5330381 },
    { lat: 48.7196706, lng: 21.5327038 },
    { lat: 48.7199965, lng: 21.5326908 },
    { lat: 48.7201299, lng: 21.5324298 },
    { lat: 48.7206612, lng: 21.5323186 },
    { lat: 48.7207805, lng: 21.5323995 },
    { lat: 48.7209991, lng: 21.5323351 },
    { lat: 48.7217275, lng: 21.5329009 },
    { lat: 48.7219008, lng: 21.5329844 },
    { lat: 48.7220013, lng: 21.5329109 },
    { lat: 48.7221434, lng: 21.5329258 },
    { lat: 48.7222008, lng: 21.5331612 },
    { lat: 48.7223404, lng: 21.5332836 },
    { lat: 48.7224771, lng: 21.5331411 },
    { lat: 48.7228012, lng: 21.5332636 },
    { lat: 48.7229835, lng: 21.5335792 },
    { lat: 48.723256, lng: 21.5338252 },
    { lat: 48.7233966, lng: 21.5338275 },
    { lat: 48.7234583, lng: 21.5337728 },
    { lat: 48.7234649, lng: 21.5336163 },
    { lat: 48.7236626, lng: 21.533323 },
    { lat: 48.7237553, lng: 21.5329449 },
    { lat: 48.7241643, lng: 21.5324924 },
    { lat: 48.7243478, lng: 21.5325368 },
    { lat: 48.7246213, lng: 21.5320261 },
    { lat: 48.7246662, lng: 21.5316654 },
    { lat: 48.724806, lng: 21.531555 },
    { lat: 48.724876, lng: 21.5314044 },
    { lat: 48.7249316, lng: 21.5308762 },
    { lat: 48.7251243, lng: 21.5303739 },
    { lat: 48.7250251, lng: 21.5299699 },
    { lat: 48.7252053, lng: 21.5295429 },
    { lat: 48.7253268, lng: 21.529463 },
    { lat: 48.7254712, lng: 21.529202 },
    { lat: 48.7254855, lng: 21.5288693 },
    { lat: 48.7257417, lng: 21.5285432 },
    { lat: 48.7259206, lng: 21.5285088 },
    { lat: 48.7258975, lng: 21.5282854 },
    { lat: 48.7259762, lng: 21.5278879 },
    { lat: 48.7261454, lng: 21.5277086 },
    { lat: 48.7261914, lng: 21.5273482 },
    { lat: 48.7263882, lng: 21.5273036 },
    { lat: 48.7264564, lng: 21.5270144 },
    { lat: 48.726588, lng: 21.5269342 },
    { lat: 48.7267055, lng: 21.5266321 },
    { lat: 48.7268651, lng: 21.5264318 },
    { lat: 48.7268924, lng: 21.5262069 },
    { lat: 48.7270992, lng: 21.5260751 },
    { lat: 48.7270862, lng: 21.5258621 },
    { lat: 48.7272279, lng: 21.5257363 },
    { lat: 48.7273379, lng: 21.5255327 },
    { lat: 48.727356, lng: 21.5251769 },
    { lat: 48.7277531, lng: 21.5243869 },
    { lat: 48.7277728, lng: 21.5236978 },
    { lat: 48.7282064, lng: 21.5232428 },
    { lat: 48.7282833, lng: 21.5228244 },
    { lat: 48.7284084, lng: 21.5227549 },
    { lat: 48.7286853, lng: 21.5219242 },
    { lat: 48.7288062, lng: 21.521865 },
    { lat: 48.7291337, lng: 21.5210092 },
    { lat: 48.7291339, lng: 21.5207337 },
    { lat: 48.7293453, lng: 21.5202619 },
    { lat: 48.7293916, lng: 21.5200258 },
    { lat: 48.7295902, lng: 21.5198528 },
    { lat: 48.7295708, lng: 21.5192934 },
    { lat: 48.7297849, lng: 21.5192659 },
    { lat: 48.7298102, lng: 21.5189655 },
    { lat: 48.7300876, lng: 21.5188294 },
    { lat: 48.7302989, lng: 21.5183113 },
    { lat: 48.7303571, lng: 21.5176766 },
    { lat: 48.7305469, lng: 21.5174603 },
    { lat: 48.730613, lng: 21.516977 },
    { lat: 48.7310276, lng: 21.5168818 },
    { lat: 48.7313794, lng: 21.5165119 },
    { lat: 48.7314923, lng: 21.5154641 },
    { lat: 48.7315573, lng: 21.5153818 },
    { lat: 48.7318001, lng: 21.5153702 },
    { lat: 48.7317734, lng: 21.5147917 },
    { lat: 48.7322293, lng: 21.5138985 },
    { lat: 48.7320988, lng: 21.5136658 },
    { lat: 48.7322309, lng: 21.5133624 },
    { lat: 48.7321811, lng: 21.5126061 },
    { lat: 48.7320712, lng: 21.5124629 },
    { lat: 48.7321568, lng: 21.5121529 },
    { lat: 48.7318185, lng: 21.511536 },
    { lat: 48.7316529, lng: 21.5108258 },
    { lat: 48.7316716, lng: 21.5104913 },
    { lat: 48.7317707, lng: 21.5101487 },
    { lat: 48.7315784, lng: 21.5097599 },
    { lat: 48.7316395, lng: 21.5093428 },
    { lat: 48.7312843, lng: 21.5088767 },
    { lat: 48.7313111, lng: 21.5086027 },
    { lat: 48.7315102, lng: 21.5083615 },
    { lat: 48.7314921, lng: 21.5081645 },
    { lat: 48.7315468, lng: 21.5079111 },
    { lat: 48.7314509, lng: 21.5072002 },
    { lat: 48.7314663, lng: 21.5068717 },
    { lat: 48.7311846, lng: 21.5064653 },
    { lat: 48.7311515, lng: 21.5061741 },
    { lat: 48.7312156, lng: 21.5059429 },
    { lat: 48.7312107, lng: 21.5052653 },
    { lat: 48.7310961, lng: 21.5050739 },
    { lat: 48.7309355, lng: 21.505083 },
    { lat: 48.7308523, lng: 21.5047299 },
    { lat: 48.7310206, lng: 21.5040894 },
    { lat: 48.730935, lng: 21.5039325 },
    { lat: 48.7309335, lng: 21.5037583 },
    { lat: 48.7310704, lng: 21.5033936 },
    { lat: 48.7309707, lng: 21.5030576 },
    { lat: 48.731102, lng: 21.5028516 },
    { lat: 48.7311303, lng: 21.5026226 },
    { lat: 48.7312241, lng: 21.5025555 },
    { lat: 48.7313459, lng: 21.5025707 },
    { lat: 48.7313962, lng: 21.5026715 },
    { lat: 48.7315649, lng: 21.5026333 },
    { lat: 48.7316547, lng: 21.502385 },
    { lat: 48.7318519, lng: 21.5020841 },
    { lat: 48.7319514, lng: 21.5020622 },
    { lat: 48.7321462, lng: 21.501678 },
    { lat: 48.7322807, lng: 21.5016952 },
    { lat: 48.7323577, lng: 21.5012827 },
    { lat: 48.7327222, lng: 21.5010873 },
    { lat: 48.7328025, lng: 21.5007854 },
    { lat: 48.7327802, lng: 21.5005214 },
    { lat: 48.7329236, lng: 21.5002991 },
    { lat: 48.7331589, lng: 21.5001126 },
    { lat: 48.7332146, lng: 21.4998177 },
    { lat: 48.7333287, lng: 21.4996387 },
    { lat: 48.7335949, lng: 21.4995453 },
    { lat: 48.7336798, lng: 21.4990567 },
    { lat: 48.7336412, lng: 21.4989605 },
    { lat: 48.7336538, lng: 21.4987768 },
    { lat: 48.733764, lng: 21.4985393 },
    { lat: 48.7339246, lng: 21.4984535 },
    { lat: 48.7340819, lng: 21.498091 },
    { lat: 48.7343758, lng: 21.4981786 },
    { lat: 48.7349491, lng: 21.4980019 },
    { lat: 48.7351647, lng: 21.4977923 },
    { lat: 48.7352843, lng: 21.4978411 },
    { lat: 48.7355714, lng: 21.498471 },
    { lat: 48.7358835, lng: 21.4983685 },
    { lat: 48.7359796, lng: 21.4982659 },
    { lat: 48.7360979, lng: 21.4982713 },
    { lat: 48.7361757, lng: 21.4984359 },
    { lat: 48.7362586, lng: 21.4983855 },
    { lat: 48.7363801, lng: 21.4985739 },
    { lat: 48.7367864, lng: 21.4983472 },
    { lat: 48.7368879, lng: 21.4983918 },
    { lat: 48.7369321, lng: 21.4981654 },
    { lat: 48.737046, lng: 21.4981009 },
    { lat: 48.7370987, lng: 21.497901 },
    { lat: 48.737265, lng: 21.497617 },
    { lat: 48.7377741, lng: 21.4985766 },
    { lat: 48.7387101, lng: 21.4977454 },
    { lat: 48.7387726, lng: 21.497958 },
    { lat: 48.7389647, lng: 21.4978331 },
    { lat: 48.7392156, lng: 21.4986115 },
    { lat: 48.7398589, lng: 21.4983692 },
    { lat: 48.7410549, lng: 21.4980937 },
    { lat: 48.7410268, lng: 21.4986395 },
    { lat: 48.7421662, lng: 21.4983474 },
    { lat: 48.7427543, lng: 21.4981469 },
    { lat: 48.7427438, lng: 21.4979372 },
    { lat: 48.7429096, lng: 21.4975206 },
    { lat: 48.7428492, lng: 21.4973399 },
    { lat: 48.7428652, lng: 21.4971916 },
    { lat: 48.7430758, lng: 21.4967653 },
    { lat: 48.74306, lng: 21.4966847 },
    { lat: 48.7429188, lng: 21.496546 },
    { lat: 48.7428636, lng: 21.4961942 },
    { lat: 48.7428959, lng: 21.496145 },
    { lat: 48.7430695, lng: 21.4961543 },
    { lat: 48.7433071, lng: 21.495889 },
    { lat: 48.7433782, lng: 21.4953125 },
    { lat: 48.7435631, lng: 21.4953656 },
    { lat: 48.7446593, lng: 21.4960353 },
    { lat: 48.7455999, lng: 21.4959551 },
    { lat: 48.7459938, lng: 21.4959919 },
    { lat: 48.7462322, lng: 21.4961474 },
    { lat: 48.7463753, lng: 21.495765 },
    { lat: 48.746833, lng: 21.495789 },
    { lat: 48.7470639, lng: 21.4959564 },
    { lat: 48.7473644, lng: 21.4955676 },
    { lat: 48.7481404, lng: 21.4950419 },
    { lat: 48.7504213, lng: 21.4938379 },
    { lat: 48.7505972, lng: 21.4936979 },
    { lat: 48.7519104, lng: 21.4932687 },
    { lat: 48.7536231, lng: 21.4930363 },
    { lat: 48.7536755, lng: 21.4929801 },
    { lat: 48.7537322, lng: 21.4929193 },
  ],
  Vtáčkovce: [
    { lat: 48.8411612, lng: 21.3895771 },
    { lat: 48.8413102, lng: 21.3895916 },
    { lat: 48.8417485, lng: 21.3897668 },
    { lat: 48.842094, lng: 21.3896865 },
    { lat: 48.8423584, lng: 21.3898414 },
    { lat: 48.8425531, lng: 21.3898388 },
    { lat: 48.8427774, lng: 21.3900072 },
    { lat: 48.842956, lng: 21.3899571 },
    { lat: 48.8432777, lng: 21.3899899 },
    { lat: 48.8433287, lng: 21.3902297 },
    { lat: 48.843444, lng: 21.3904373 },
    { lat: 48.8435076, lng: 21.3908402 },
    { lat: 48.8439882, lng: 21.3919789 },
    { lat: 48.8440707, lng: 21.3922977 },
    { lat: 48.8442494, lng: 21.3926699 },
    { lat: 48.8444091, lng: 21.3927426 },
    { lat: 48.8449966, lng: 21.3935426 },
    { lat: 48.8451156, lng: 21.3935316 },
    { lat: 48.8452107, lng: 21.3936302 },
    { lat: 48.8452954, lng: 21.3934894 },
    { lat: 48.8455021, lng: 21.3935178 },
    { lat: 48.8455182, lng: 21.3936393 },
    { lat: 48.8455734, lng: 21.3936412 },
    { lat: 48.8456556, lng: 21.3938675 },
    { lat: 48.8456711, lng: 21.394088 },
    { lat: 48.8458444, lng: 21.3940894 },
    { lat: 48.8459177, lng: 21.3939043 },
    { lat: 48.8459816, lng: 21.3939765 },
    { lat: 48.8460545, lng: 21.3938893 },
    { lat: 48.8460795, lng: 21.3939769 },
    { lat: 48.8463689, lng: 21.3942509 },
    { lat: 48.8465276, lng: 21.3942501 },
    { lat: 48.8466179, lng: 21.3945205 },
    { lat: 48.8466924, lng: 21.3943468 },
    { lat: 48.8466505, lng: 21.3938377 },
    { lat: 48.8471416, lng: 21.3930244 },
    { lat: 48.8472533, lng: 21.392537 },
    { lat: 48.8474368, lng: 21.3922783 },
    { lat: 48.8474528, lng: 21.3920539 },
    { lat: 48.8476017, lng: 21.3917615 },
    { lat: 48.8476264, lng: 21.3916091 },
    { lat: 48.8481809, lng: 21.3907325 },
    { lat: 48.8484902, lng: 21.390348 },
    { lat: 48.8488321, lng: 21.3900372 },
    { lat: 48.849345, lng: 21.3888175 },
    { lat: 48.8497812, lng: 21.3881146 },
    { lat: 48.8494303, lng: 21.3875997 },
    { lat: 48.8477149, lng: 21.3871232 },
    { lat: 48.8478733, lng: 21.3861685 },
    { lat: 48.8482004, lng: 21.3846384 },
    { lat: 48.8488578, lng: 21.3844953 },
    { lat: 48.8488621, lng: 21.3843779 },
    { lat: 48.8491552, lng: 21.3844315 },
    { lat: 48.8491554, lng: 21.3841392 },
    { lat: 48.8494481, lng: 21.3840617 },
    { lat: 48.8494685, lng: 21.3836534 },
    { lat: 48.8501609, lng: 21.3831738 },
    { lat: 48.8503871, lng: 21.3827939 },
    { lat: 48.8497299, lng: 21.3821496 },
    { lat: 48.8494519, lng: 21.3823715 },
    { lat: 48.8493561, lng: 21.3819493 },
    { lat: 48.8491959, lng: 21.3820166 },
    { lat: 48.8490787, lng: 21.3815166 },
    { lat: 48.8489798, lng: 21.3806891 },
    { lat: 48.8494203, lng: 21.3804778 },
    { lat: 48.8498516, lng: 21.3801132 },
    { lat: 48.8501237, lng: 21.3797249 },
    { lat: 48.849527, lng: 21.378697 },
    { lat: 48.8491737, lng: 21.3787758 },
    { lat: 48.8490071, lng: 21.3785817 },
    { lat: 48.8484722, lng: 21.3773178 },
    { lat: 48.8480171, lng: 21.3764689 },
    { lat: 48.8479377, lng: 21.3761488 },
    { lat: 48.8479773, lng: 21.3757417 },
    { lat: 48.847901, lng: 21.3755236 },
    { lat: 48.847939, lng: 21.375485 },
    { lat: 48.847815, lng: 21.375276 },
    { lat: 48.847536, lng: 21.375049 },
    { lat: 48.847044, lng: 21.37421 },
    { lat: 48.846941, lng: 21.373907 },
    { lat: 48.846779, lng: 21.373456 },
    { lat: 48.846643, lng: 21.373234 },
    { lat: 48.846629, lng: 21.373243 },
    { lat: 48.84652, lng: 21.373177 },
    { lat: 48.846466, lng: 21.373107 },
    { lat: 48.846357, lng: 21.373028 },
    { lat: 48.846298, lng: 21.372925 },
    { lat: 48.846178, lng: 21.37284 },
    { lat: 48.845839, lng: 21.372818 },
    { lat: 48.845793, lng: 21.372557 },
    { lat: 48.84591, lng: 21.372519 },
    { lat: 48.845919, lng: 21.372334 },
    { lat: 48.845843, lng: 21.372143 },
    { lat: 48.845211, lng: 21.37199 },
    { lat: 48.844547, lng: 21.371941 },
    { lat: 48.844271, lng: 21.371667 },
    { lat: 48.844152, lng: 21.371019 },
    { lat: 48.843956, lng: 21.370906 },
    { lat: 48.843855, lng: 21.37092 },
    { lat: 48.843515, lng: 21.370749 },
    { lat: 48.843436, lng: 21.370738 },
    { lat: 48.843416, lng: 21.370564 },
    { lat: 48.843283, lng: 21.370574 },
    { lat: 48.84325, lng: 21.370557 },
    { lat: 48.84313, lng: 21.370497 },
    { lat: 48.842938, lng: 21.370512 },
    { lat: 48.842815, lng: 21.370479 },
    { lat: 48.842766, lng: 21.36986 },
    { lat: 48.842882, lng: 21.369788 },
    { lat: 48.842878, lng: 21.369265 },
    { lat: 48.842796, lng: 21.368675 },
    { lat: 48.8428364, lng: 21.3677639 },
    { lat: 48.8425775, lng: 21.3676344 },
    { lat: 48.842087, lng: 21.367431 },
    { lat: 48.8416762, lng: 21.3672303 },
    { lat: 48.841311, lng: 21.3670809 },
    { lat: 48.841176, lng: 21.367706 },
    { lat: 48.840952, lng: 21.368899 },
    { lat: 48.840777, lng: 21.368876 },
    { lat: 48.84036, lng: 21.368745 },
    { lat: 48.84014, lng: 21.36863 },
    { lat: 48.839776, lng: 21.36834 },
    { lat: 48.838087, lng: 21.367257 },
    { lat: 48.8366574, lng: 21.3665073 },
    { lat: 48.8357613, lng: 21.3665427 },
    { lat: 48.835515, lng: 21.3667984 },
    { lat: 48.8347508, lng: 21.366238 },
    { lat: 48.8343239, lng: 21.3664496 },
    { lat: 48.833919, lng: 21.3670389 },
    { lat: 48.8337036, lng: 21.3673635 },
    { lat: 48.8334721, lng: 21.3676051 },
    { lat: 48.8324834, lng: 21.368342 },
    { lat: 48.8315089, lng: 21.3693914 },
    { lat: 48.8306114, lng: 21.3702046 },
    { lat: 48.8293925, lng: 21.3711367 },
    { lat: 48.8273376, lng: 21.3721088 },
    { lat: 48.8263543, lng: 21.3724196 },
    { lat: 48.8255154, lng: 21.3725149 },
    { lat: 48.8247583, lng: 21.372407 },
    { lat: 48.8235618, lng: 21.3725475 },
    { lat: 48.8222505, lng: 21.3729111 },
    { lat: 48.8222657, lng: 21.3730479 },
    { lat: 48.822887, lng: 21.3764489 },
    { lat: 48.8230019, lng: 21.377512 },
    { lat: 48.8233148, lng: 21.379323 },
    { lat: 48.8236797, lng: 21.3812293 },
    { lat: 48.8235817, lng: 21.3826187 },
    { lat: 48.8247173, lng: 21.3827444 },
    { lat: 48.8246515, lng: 21.3830325 },
    { lat: 48.8250445, lng: 21.3833381 },
    { lat: 48.8251511, lng: 21.3833291 },
    { lat: 48.8252644, lng: 21.3830882 },
    { lat: 48.8258203, lng: 21.3831634 },
    { lat: 48.8260906, lng: 21.3835943 },
    { lat: 48.8265157, lng: 21.3837742 },
    { lat: 48.8268098, lng: 21.3840484 },
    { lat: 48.8271458, lng: 21.3841505 },
    { lat: 48.8272515, lng: 21.384264 },
    { lat: 48.8274832, lng: 21.3847521 },
    { lat: 48.8274173, lng: 21.3850344 },
    { lat: 48.827665, lng: 21.3850693 },
    { lat: 48.8279151, lng: 21.3850037 },
    { lat: 48.8281404, lng: 21.3850559 },
    { lat: 48.8283865, lng: 21.3852677 },
    { lat: 48.8289047, lng: 21.3854763 },
    { lat: 48.82891, lng: 21.3856963 },
    { lat: 48.8295034, lng: 21.3858597 },
    { lat: 48.8297062, lng: 21.3857881 },
    { lat: 48.8298215, lng: 21.3856623 },
    { lat: 48.8305595, lng: 21.3858017 },
    { lat: 48.8308503, lng: 21.3860111 },
    { lat: 48.8310063, lng: 21.3859598 },
    { lat: 48.8311856, lng: 21.3860433 },
    { lat: 48.8315055, lng: 21.3862069 },
    { lat: 48.8319977, lng: 21.3866073 },
    { lat: 48.8322032, lng: 21.3869279 },
    { lat: 48.8324876, lng: 21.3872214 },
    { lat: 48.8333924, lng: 21.3876759 },
    { lat: 48.8335778, lng: 21.3879336 },
    { lat: 48.8339076, lng: 21.3880912 },
    { lat: 48.8340348, lng: 21.3882458 },
    { lat: 48.8343633, lng: 21.3883577 },
    { lat: 48.83453, lng: 21.3883235 },
    { lat: 48.8348095, lng: 21.3879612 },
    { lat: 48.8350542, lng: 21.3877541 },
    { lat: 48.8355316, lng: 21.3876076 },
    { lat: 48.8361193, lng: 21.3872383 },
    { lat: 48.8365404, lng: 21.3870624 },
    { lat: 48.8377377, lng: 21.3868304 },
    { lat: 48.8385146, lng: 21.3868511 },
    { lat: 48.8391031, lng: 21.3871914 },
    { lat: 48.8394764, lng: 21.3876675 },
    { lat: 48.8397358, lng: 21.3881078 },
    { lat: 48.8397691, lng: 21.3883627 },
    { lat: 48.8401204, lng: 21.3887431 },
    { lat: 48.8403546, lng: 21.3889022 },
    { lat: 48.8405962, lng: 21.3892351 },
    { lat: 48.8411612, lng: 21.3895771 },
  ],
  KošickýKlečenov: [
    { lat: 48.712094, lng: 21.544427 },
    { lat: 48.71235, lng: 21.544321 },
    { lat: 48.712653, lng: 21.544175 },
    { lat: 48.712862, lng: 21.543973 },
    { lat: 48.712945, lng: 21.543825 },
    { lat: 48.713418, lng: 21.543237 },
    { lat: 48.713711, lng: 21.54305 },
    { lat: 48.713833, lng: 21.542965 },
    { lat: 48.714033, lng: 21.54288 },
    { lat: 48.71418, lng: 21.542785 },
    { lat: 48.714396, lng: 21.54277 },
    { lat: 48.714598, lng: 21.542675 },
    { lat: 48.714744, lng: 21.5426 },
    { lat: 48.714888, lng: 21.542524 },
    { lat: 48.71506, lng: 21.54247 },
    { lat: 48.715071, lng: 21.542525 },
    { lat: 48.715315, lng: 21.542562 },
    { lat: 48.715514, lng: 21.542241 },
    { lat: 48.715571, lng: 21.542159 },
    { lat: 48.715549, lng: 21.542101 },
    { lat: 48.715598, lng: 21.542062 },
    { lat: 48.715703, lng: 21.54196 },
    { lat: 48.715968, lng: 21.541581 },
    { lat: 48.716102, lng: 21.54149 },
    { lat: 48.716191, lng: 21.541479 },
    { lat: 48.716421, lng: 21.541841 },
    { lat: 48.716538, lng: 21.542116 },
    { lat: 48.716559, lng: 21.542234 },
    { lat: 48.716554, lng: 21.542371 },
    { lat: 48.716566, lng: 21.542686 },
    { lat: 48.716531, lng: 21.543024 },
    { lat: 48.716546, lng: 21.543182 },
    { lat: 48.716692, lng: 21.543542 },
    { lat: 48.716853, lng: 21.543736 },
    { lat: 48.71702, lng: 21.543844 },
    { lat: 48.717468, lng: 21.544088 },
    { lat: 48.717606, lng: 21.544254 },
    { lat: 48.718019, lng: 21.544534 },
    { lat: 48.718339, lng: 21.544739 },
    { lat: 48.718512, lng: 21.544751 },
    { lat: 48.718669, lng: 21.544966 },
    { lat: 48.718781, lng: 21.545037 },
    { lat: 48.719099, lng: 21.544966 },
    { lat: 48.719102, lng: 21.545018 },
    { lat: 48.71938, lng: 21.544874 },
    { lat: 48.719403, lng: 21.544817 },
    { lat: 48.719569, lng: 21.544864 },
    { lat: 48.719665, lng: 21.544868 },
    { lat: 48.719728, lng: 21.544848 },
    { lat: 48.719832, lng: 21.544811 },
    { lat: 48.719913, lng: 21.544831 },
    { lat: 48.720252, lng: 21.544946 },
    { lat: 48.720709, lng: 21.545482 },
    { lat: 48.720913, lng: 21.545689 },
    { lat: 48.72102, lng: 21.545761 },
    { lat: 48.721248, lng: 21.54582 },
    { lat: 48.72144, lng: 21.545901 },
    { lat: 48.721545, lng: 21.545913 },
    { lat: 48.72172, lng: 21.545895 },
    { lat: 48.721923, lng: 21.545884 },
    { lat: 48.721906, lng: 21.545949 },
    { lat: 48.722207, lng: 21.546047 },
    { lat: 48.722611, lng: 21.545842 },
    { lat: 48.722604, lng: 21.545782 },
    { lat: 48.722834, lng: 21.545772 },
    { lat: 48.723077, lng: 21.545739 },
    { lat: 48.723193, lng: 21.545703 },
    { lat: 48.723369, lng: 21.545434 },
    { lat: 48.723586, lng: 21.545184 },
    { lat: 48.724158, lng: 21.545317 },
    { lat: 48.724139, lng: 21.545393 },
    { lat: 48.724271, lng: 21.545455 },
    { lat: 48.724368, lng: 21.545516 },
    { lat: 48.724479, lng: 21.545632 },
    { lat: 48.724642, lng: 21.545708 },
    { lat: 48.724754, lng: 21.545799 },
    { lat: 48.724829, lng: 21.545898 },
    { lat: 48.724914, lng: 21.546152 },
    { lat: 48.724956, lng: 21.546234 },
    { lat: 48.725208, lng: 21.546477 },
    { lat: 48.725454, lng: 21.546678 },
    { lat: 48.725761, lng: 21.546889 },
    { lat: 48.725893, lng: 21.546973 },
    { lat: 48.726148, lng: 21.547009 },
    { lat: 48.726369, lng: 21.547076 },
    { lat: 48.726724, lng: 21.547092 },
    { lat: 48.727053, lng: 21.54718 },
    { lat: 48.727408, lng: 21.547168 },
    { lat: 48.727673, lng: 21.547215 },
    { lat: 48.728021, lng: 21.547312 },
    { lat: 48.728374, lng: 21.547386 },
    { lat: 48.728528, lng: 21.54749 },
    { lat: 48.728777, lng: 21.547618 },
    { lat: 48.729033, lng: 21.547746 },
    { lat: 48.729178, lng: 21.548038 },
    { lat: 48.729397, lng: 21.548055 },
    { lat: 48.72966, lng: 21.548267 },
    { lat: 48.729829, lng: 21.548342 },
    { lat: 48.730109, lng: 21.54823 },
    { lat: 48.730308, lng: 21.548395 },
    { lat: 48.730454, lng: 21.548459 },
    { lat: 48.730657, lng: 21.54857 },
    { lat: 48.730928, lng: 21.548913 },
    { lat: 48.731012, lng: 21.54883 },
    { lat: 48.731274, lng: 21.54898 },
    { lat: 48.731761, lng: 21.549149 },
    { lat: 48.732071, lng: 21.549249 },
    { lat: 48.732336, lng: 21.549425 },
    { lat: 48.732512, lng: 21.549514 },
    { lat: 48.732953, lng: 21.549703 },
    { lat: 48.733268, lng: 21.549787 },
    { lat: 48.733295, lng: 21.549766 },
    { lat: 48.733428, lng: 21.550041 },
    { lat: 48.733686, lng: 21.550805 },
    { lat: 48.733765, lng: 21.551 },
    { lat: 48.733955, lng: 21.551339 },
    { lat: 48.734115, lng: 21.55147 },
    { lat: 48.734168, lng: 21.551581 },
    { lat: 48.734562, lng: 21.551891 },
    { lat: 48.734664, lng: 21.551876 },
    { lat: 48.734673, lng: 21.551873 },
    { lat: 48.734723, lng: 21.551877 },
    { lat: 48.734736, lng: 21.551873 },
    { lat: 48.734772, lng: 21.551861 },
    { lat: 48.734837, lng: 21.551841 },
    { lat: 48.734874, lng: 21.551829 },
    { lat: 48.736668, lng: 21.551525 },
    { lat: 48.739193, lng: 21.551036 },
    { lat: 48.739726, lng: 21.550635 },
    { lat: 48.739799, lng: 21.550581 },
    { lat: 48.739944, lng: 21.550472 },
    { lat: 48.741357, lng: 21.548825 },
    { lat: 48.74176, lng: 21.548401 },
    { lat: 48.742804, lng: 21.547408 },
    { lat: 48.743024, lng: 21.547019 },
    { lat: 48.743197, lng: 21.546779 },
    { lat: 48.743547, lng: 21.546512 },
    { lat: 48.743837, lng: 21.546352 },
    { lat: 48.744092, lng: 21.546188 },
    { lat: 48.744863, lng: 21.545649 },
    { lat: 48.745769, lng: 21.545198 },
    { lat: 48.746221, lng: 21.5446 },
    { lat: 48.746635, lng: 21.544234 },
    { lat: 48.746631, lng: 21.544181 },
    { lat: 48.747531, lng: 21.543912 },
    { lat: 48.747539, lng: 21.54397 },
    { lat: 48.747754, lng: 21.543991 },
    { lat: 48.748012, lng: 21.543959 },
    { lat: 48.748648, lng: 21.543797 },
    { lat: 48.74924, lng: 21.543414 },
    { lat: 48.74947, lng: 21.54324 },
    { lat: 48.749751, lng: 21.543175 },
    { lat: 48.750314, lng: 21.543157 },
    { lat: 48.751033, lng: 21.54297 },
    { lat: 48.751411, lng: 21.542679 },
    { lat: 48.751715, lng: 21.542551 },
    { lat: 48.752014, lng: 21.542305 },
    { lat: 48.752457, lng: 21.542324 },
    { lat: 48.752979, lng: 21.542178 },
    { lat: 48.753278, lng: 21.541885 },
    { lat: 48.753367, lng: 21.541683 },
    { lat: 48.753352, lng: 21.541639 },
    { lat: 48.753711, lng: 21.541242 },
    { lat: 48.753884, lng: 21.54111 },
    { lat: 48.754165, lng: 21.540833 },
    { lat: 48.754312, lng: 21.54071 },
    { lat: 48.754328, lng: 21.54076 },
    { lat: 48.75474, lng: 21.540713 },
    { lat: 48.755169, lng: 21.540931 },
    { lat: 48.755313, lng: 21.541076 },
    { lat: 48.755893, lng: 21.541305 },
    { lat: 48.756114, lng: 21.541503 },
    { lat: 48.756241, lng: 21.541705 },
    { lat: 48.756294, lng: 21.542023 },
    { lat: 48.756337, lng: 21.542122 },
    { lat: 48.75675, lng: 21.542341 },
    { lat: 48.756955, lng: 21.542434 },
    { lat: 48.757195, lng: 21.54249 },
    { lat: 48.75745, lng: 21.542509 },
    { lat: 48.758147, lng: 21.542553 },
    { lat: 48.758565, lng: 21.542578 },
    { lat: 48.758883, lng: 21.542647 },
    { lat: 48.759324, lng: 21.542463 },
    { lat: 48.75979, lng: 21.542079 },
    { lat: 48.759996, lng: 21.541819 },
    { lat: 48.76022, lng: 21.541292 },
    { lat: 48.761021, lng: 21.540603 },
    { lat: 48.761963, lng: 21.540528 },
    { lat: 48.76243, lng: 21.540421 },
    { lat: 48.76272, lng: 21.540356 },
    { lat: 48.763466, lng: 21.540018 },
    { lat: 48.764264, lng: 21.539461 },
    { lat: 48.764519, lng: 21.539263 },
    { lat: 48.764992, lng: 21.539113 },
    { lat: 48.76539, lng: 21.539016 },
    { lat: 48.766427, lng: 21.538344 },
    { lat: 48.766703, lng: 21.538016 },
    { lat: 48.767176, lng: 21.537715 },
    { lat: 48.7668135, lng: 21.537677 },
    { lat: 48.766482, lng: 21.5373694 },
    { lat: 48.7662356, lng: 21.5369382 },
    { lat: 48.7656331, lng: 21.5366803 },
    { lat: 48.7646227, lng: 21.5346614 },
    { lat: 48.7640682, lng: 21.5346326 },
    { lat: 48.76384, lng: 21.5347142 },
    { lat: 48.7636543, lng: 21.5345629 },
    { lat: 48.7636924, lng: 21.5342762 },
    { lat: 48.7643982, lng: 21.5330215 },
    { lat: 48.7645563, lng: 21.532569 },
    { lat: 48.7638728, lng: 21.532075 },
    { lat: 48.7636254, lng: 21.531453 },
    { lat: 48.7635444, lng: 21.5310164 },
    { lat: 48.7629117, lng: 21.5298972 },
    { lat: 48.7627628, lng: 21.5293305 },
    { lat: 48.7626128, lng: 21.528024 },
    { lat: 48.7624679, lng: 21.5274728 },
    { lat: 48.7617427, lng: 21.5264666 },
    { lat: 48.7610863, lng: 21.5264189 },
    { lat: 48.7607112, lng: 21.5260061 },
    { lat: 48.7602793, lng: 21.5259723 },
    { lat: 48.7601474, lng: 21.5258468 },
    { lat: 48.7600195, lng: 21.5258184 },
    { lat: 48.7597932, lng: 21.5253414 },
    { lat: 48.759116, lng: 21.5248782 },
    { lat: 48.7586375, lng: 21.5240386 },
    { lat: 48.7580608, lng: 21.5223659 },
    { lat: 48.7578654, lng: 21.5220624 },
    { lat: 48.7572873, lng: 21.5206952 },
    { lat: 48.7563764, lng: 21.5189778 },
    { lat: 48.7568578, lng: 21.5174314 },
    { lat: 48.7570798, lng: 21.5169333 },
    { lat: 48.7573199, lng: 21.5156185 },
    { lat: 48.7571939, lng: 21.515146 },
    { lat: 48.7572574, lng: 21.5144586 },
    { lat: 48.7576337, lng: 21.5132147 },
    { lat: 48.7577162, lng: 21.5125217 },
    { lat: 48.7577245, lng: 21.5114792 },
    { lat: 48.757665, lng: 21.5114777 },
    { lat: 48.757645, lng: 21.51133 },
    { lat: 48.7573945, lng: 21.511111 },
    { lat: 48.7570489, lng: 21.5106694 },
    { lat: 48.7570609, lng: 21.510578 },
    { lat: 48.7568455, lng: 21.5104524 },
    { lat: 48.7565484, lng: 21.509933 },
    { lat: 48.7565806, lng: 21.5096096 },
    { lat: 48.7563317, lng: 21.5086168 },
    { lat: 48.7557857, lng: 21.5079052 },
    { lat: 48.7557952, lng: 21.5077255 },
    { lat: 48.7556679, lng: 21.5075638 },
    { lat: 48.7555303, lng: 21.5070419 },
    { lat: 48.7555227, lng: 21.5067357 },
    { lat: 48.7554598, lng: 21.5066639 },
    { lat: 48.755359, lng: 21.5067158 },
    { lat: 48.7553063, lng: 21.5065324 },
    { lat: 48.7553096, lng: 21.5063271 },
    { lat: 48.7549671, lng: 21.5057641 },
    { lat: 48.7544433, lng: 21.5053567 },
    { lat: 48.7543912, lng: 21.5050295 },
    { lat: 48.7541895, lng: 21.5050581 },
    { lat: 48.7541305, lng: 21.5048113 },
    { lat: 48.7544348, lng: 21.5030569 },
    { lat: 48.7545022, lng: 21.5024673 },
    { lat: 48.7544963, lng: 21.5018347 },
    { lat: 48.7543041, lng: 21.4993409 },
    { lat: 48.7541669, lng: 21.4982027 },
    { lat: 48.7539794, lng: 21.4972042 },
    { lat: 48.7536889, lng: 21.4939955 },
    { lat: 48.7537322, lng: 21.4929193 },
    { lat: 48.7536755, lng: 21.4929801 },
    { lat: 48.7536231, lng: 21.4930363 },
    { lat: 48.7519104, lng: 21.4932687 },
    { lat: 48.7505972, lng: 21.4936979 },
    { lat: 48.7504213, lng: 21.4938379 },
    { lat: 48.7481404, lng: 21.4950419 },
    { lat: 48.7473644, lng: 21.4955676 },
    { lat: 48.7470639, lng: 21.4959564 },
    { lat: 48.746833, lng: 21.495789 },
    { lat: 48.7463753, lng: 21.495765 },
    { lat: 48.7462322, lng: 21.4961474 },
    { lat: 48.7459938, lng: 21.4959919 },
    { lat: 48.7455999, lng: 21.4959551 },
    { lat: 48.7446593, lng: 21.4960353 },
    { lat: 48.7435631, lng: 21.4953656 },
    { lat: 48.7433782, lng: 21.4953125 },
    { lat: 48.7433071, lng: 21.495889 },
    { lat: 48.7430695, lng: 21.4961543 },
    { lat: 48.7428959, lng: 21.496145 },
    { lat: 48.7428636, lng: 21.4961942 },
    { lat: 48.7429188, lng: 21.496546 },
    { lat: 48.74306, lng: 21.4966847 },
    { lat: 48.7430758, lng: 21.4967653 },
    { lat: 48.7428652, lng: 21.4971916 },
    { lat: 48.7428492, lng: 21.4973399 },
    { lat: 48.7429096, lng: 21.4975206 },
    { lat: 48.7427438, lng: 21.4979372 },
    { lat: 48.7427543, lng: 21.4981469 },
    { lat: 48.7421662, lng: 21.4983474 },
    { lat: 48.7410268, lng: 21.4986395 },
    { lat: 48.7410549, lng: 21.4980937 },
    { lat: 48.7398589, lng: 21.4983692 },
    { lat: 48.7392156, lng: 21.4986115 },
    { lat: 48.7389647, lng: 21.4978331 },
    { lat: 48.7387726, lng: 21.497958 },
    { lat: 48.7387101, lng: 21.4977454 },
    { lat: 48.7377741, lng: 21.4985766 },
    { lat: 48.737265, lng: 21.497617 },
    { lat: 48.7370987, lng: 21.497901 },
    { lat: 48.737046, lng: 21.4981009 },
    { lat: 48.7369321, lng: 21.4981654 },
    { lat: 48.7368879, lng: 21.4983918 },
    { lat: 48.7367864, lng: 21.4983472 },
    { lat: 48.7363801, lng: 21.4985739 },
    { lat: 48.7362586, lng: 21.4983855 },
    { lat: 48.7361757, lng: 21.4984359 },
    { lat: 48.7360979, lng: 21.4982713 },
    { lat: 48.7359796, lng: 21.4982659 },
    { lat: 48.7358835, lng: 21.4983685 },
    { lat: 48.7355714, lng: 21.498471 },
    { lat: 48.7352843, lng: 21.4978411 },
    { lat: 48.7351647, lng: 21.4977923 },
    { lat: 48.7349491, lng: 21.4980019 },
    { lat: 48.7343758, lng: 21.4981786 },
    { lat: 48.7340819, lng: 21.498091 },
    { lat: 48.7339246, lng: 21.4984535 },
    { lat: 48.733764, lng: 21.4985393 },
    { lat: 48.7336538, lng: 21.4987768 },
    { lat: 48.7336412, lng: 21.4989605 },
    { lat: 48.7336798, lng: 21.4990567 },
    { lat: 48.7335949, lng: 21.4995453 },
    { lat: 48.7333287, lng: 21.4996387 },
    { lat: 48.7332146, lng: 21.4998177 },
    { lat: 48.7331589, lng: 21.5001126 },
    { lat: 48.7329236, lng: 21.5002991 },
    { lat: 48.7327802, lng: 21.5005214 },
    { lat: 48.7328025, lng: 21.5007854 },
    { lat: 48.7327222, lng: 21.5010873 },
    { lat: 48.7323577, lng: 21.5012827 },
    { lat: 48.7322807, lng: 21.5016952 },
    { lat: 48.7321462, lng: 21.501678 },
    { lat: 48.7319514, lng: 21.5020622 },
    { lat: 48.7318519, lng: 21.5020841 },
    { lat: 48.7316547, lng: 21.502385 },
    { lat: 48.7315649, lng: 21.5026333 },
    { lat: 48.7313962, lng: 21.5026715 },
    { lat: 48.7313459, lng: 21.5025707 },
    { lat: 48.7312241, lng: 21.5025555 },
    { lat: 48.7311303, lng: 21.5026226 },
    { lat: 48.731102, lng: 21.5028516 },
    { lat: 48.7309707, lng: 21.5030576 },
    { lat: 48.7310704, lng: 21.5033936 },
    { lat: 48.7309335, lng: 21.5037583 },
    { lat: 48.730935, lng: 21.5039325 },
    { lat: 48.7310206, lng: 21.5040894 },
    { lat: 48.7308523, lng: 21.5047299 },
    { lat: 48.7309355, lng: 21.505083 },
    { lat: 48.7310961, lng: 21.5050739 },
    { lat: 48.7312107, lng: 21.5052653 },
    { lat: 48.7312156, lng: 21.5059429 },
    { lat: 48.7311515, lng: 21.5061741 },
    { lat: 48.7311846, lng: 21.5064653 },
    { lat: 48.7314663, lng: 21.5068717 },
    { lat: 48.7314509, lng: 21.5072002 },
    { lat: 48.7315468, lng: 21.5079111 },
    { lat: 48.7314921, lng: 21.5081645 },
    { lat: 48.7315102, lng: 21.5083615 },
    { lat: 48.7313111, lng: 21.5086027 },
    { lat: 48.7312843, lng: 21.5088767 },
    { lat: 48.7316395, lng: 21.5093428 },
    { lat: 48.7315784, lng: 21.5097599 },
    { lat: 48.7317707, lng: 21.5101487 },
    { lat: 48.7316716, lng: 21.5104913 },
    { lat: 48.7316529, lng: 21.5108258 },
    { lat: 48.7318185, lng: 21.511536 },
    { lat: 48.7321568, lng: 21.5121529 },
    { lat: 48.7320712, lng: 21.5124629 },
    { lat: 48.7321811, lng: 21.5126061 },
    { lat: 48.7322309, lng: 21.5133624 },
    { lat: 48.7320988, lng: 21.5136658 },
    { lat: 48.7322293, lng: 21.5138985 },
    { lat: 48.7317734, lng: 21.5147917 },
    { lat: 48.7318001, lng: 21.5153702 },
    { lat: 48.7315573, lng: 21.5153818 },
    { lat: 48.7314923, lng: 21.5154641 },
    { lat: 48.7313794, lng: 21.5165119 },
    { lat: 48.7310276, lng: 21.5168818 },
    { lat: 48.730613, lng: 21.516977 },
    { lat: 48.7305469, lng: 21.5174603 },
    { lat: 48.7303571, lng: 21.5176766 },
    { lat: 48.7302989, lng: 21.5183113 },
    { lat: 48.7300876, lng: 21.5188294 },
    { lat: 48.7298102, lng: 21.5189655 },
    { lat: 48.7297849, lng: 21.5192659 },
    { lat: 48.7295708, lng: 21.5192934 },
    { lat: 48.7295902, lng: 21.5198528 },
    { lat: 48.7293916, lng: 21.5200258 },
    { lat: 48.7293453, lng: 21.5202619 },
    { lat: 48.7291339, lng: 21.5207337 },
    { lat: 48.7291337, lng: 21.5210092 },
    { lat: 48.7288062, lng: 21.521865 },
    { lat: 48.7286853, lng: 21.5219242 },
    { lat: 48.7284084, lng: 21.5227549 },
    { lat: 48.7282833, lng: 21.5228244 },
    { lat: 48.7282064, lng: 21.5232428 },
    { lat: 48.7277728, lng: 21.5236978 },
    { lat: 48.7277531, lng: 21.5243869 },
    { lat: 48.727356, lng: 21.5251769 },
    { lat: 48.7273379, lng: 21.5255327 },
    { lat: 48.7272279, lng: 21.5257363 },
    { lat: 48.7270862, lng: 21.5258621 },
    { lat: 48.7270992, lng: 21.5260751 },
    { lat: 48.7268924, lng: 21.5262069 },
    { lat: 48.7268651, lng: 21.5264318 },
    { lat: 48.7267055, lng: 21.5266321 },
    { lat: 48.726588, lng: 21.5269342 },
    { lat: 48.7264564, lng: 21.5270144 },
    { lat: 48.7263882, lng: 21.5273036 },
    { lat: 48.7261914, lng: 21.5273482 },
    { lat: 48.7261454, lng: 21.5277086 },
    { lat: 48.7259762, lng: 21.5278879 },
    { lat: 48.7258975, lng: 21.5282854 },
    { lat: 48.7259206, lng: 21.5285088 },
    { lat: 48.7257417, lng: 21.5285432 },
    { lat: 48.7254855, lng: 21.5288693 },
    { lat: 48.7254712, lng: 21.529202 },
    { lat: 48.7253268, lng: 21.529463 },
    { lat: 48.7252053, lng: 21.5295429 },
    { lat: 48.7250251, lng: 21.5299699 },
    { lat: 48.7251243, lng: 21.5303739 },
    { lat: 48.7249316, lng: 21.5308762 },
    { lat: 48.724876, lng: 21.5314044 },
    { lat: 48.724806, lng: 21.531555 },
    { lat: 48.7246662, lng: 21.5316654 },
    { lat: 48.7246213, lng: 21.5320261 },
    { lat: 48.7243478, lng: 21.5325368 },
    { lat: 48.7241643, lng: 21.5324924 },
    { lat: 48.7237553, lng: 21.5329449 },
    { lat: 48.7236626, lng: 21.533323 },
    { lat: 48.7234649, lng: 21.5336163 },
    { lat: 48.7234583, lng: 21.5337728 },
    { lat: 48.7233966, lng: 21.5338275 },
    { lat: 48.723256, lng: 21.5338252 },
    { lat: 48.7229835, lng: 21.5335792 },
    { lat: 48.7228012, lng: 21.5332636 },
    { lat: 48.7224771, lng: 21.5331411 },
    { lat: 48.7223404, lng: 21.5332836 },
    { lat: 48.7222008, lng: 21.5331612 },
    { lat: 48.7221434, lng: 21.5329258 },
    { lat: 48.7220013, lng: 21.5329109 },
    { lat: 48.7219008, lng: 21.5329844 },
    { lat: 48.7217275, lng: 21.5329009 },
    { lat: 48.7209991, lng: 21.5323351 },
    { lat: 48.7207805, lng: 21.5323995 },
    { lat: 48.7206612, lng: 21.5323186 },
    { lat: 48.7201299, lng: 21.5324298 },
    { lat: 48.7199965, lng: 21.5326908 },
    { lat: 48.7196706, lng: 21.5327038 },
    { lat: 48.7195599, lng: 21.5330381 },
    { lat: 48.719366, lng: 21.5330276 },
    { lat: 48.7192166, lng: 21.5333527 },
    { lat: 48.7190285, lng: 21.5335452 },
    { lat: 48.7188467, lng: 21.5335635 },
    { lat: 48.7186754, lng: 21.5337474 },
    { lat: 48.7186319, lng: 21.5339167 },
    { lat: 48.7184106, lng: 21.5340544 },
    { lat: 48.7183757, lng: 21.5343257 },
    { lat: 48.7179803, lng: 21.5347067 },
    { lat: 48.7176711, lng: 21.5347948 },
    { lat: 48.7175852, lng: 21.5349358 },
    { lat: 48.7175879, lng: 21.5351936 },
    { lat: 48.7172768, lng: 21.5352891 },
    { lat: 48.7171374, lng: 21.5355689 },
    { lat: 48.7169038, lng: 21.5356647 },
    { lat: 48.7167635, lng: 21.5363487 },
    { lat: 48.7162853, lng: 21.5369025 },
    { lat: 48.7157877, lng: 21.5372308 },
    { lat: 48.7151567, lng: 21.537381 },
    { lat: 48.714394, lng: 21.538565 },
    { lat: 48.7142055, lng: 21.5386289 },
    { lat: 48.7140017, lng: 21.539058 },
    { lat: 48.7137694, lng: 21.5391205 },
    { lat: 48.7133238, lng: 21.5394704 },
    { lat: 48.7130628, lng: 21.5395106 },
    { lat: 48.7127726, lng: 21.539869 },
    { lat: 48.7123884, lng: 21.5398152 },
    { lat: 48.712094, lng: 21.544427 },
  ],
};

export default UnitsKošiceOkolie;
