const UnitsTrebišov = {
  Viničky: [
    { lat: 48.4312469, lng: 21.7477762 },
    { lat: 48.4311519, lng: 21.7478507 },
    { lat: 48.4307996, lng: 21.7482607 },
    { lat: 48.4305196, lng: 21.748476 },
    { lat: 48.429638, lng: 21.7484546 },
    { lat: 48.4289008, lng: 21.7487377 },
    { lat: 48.4286336, lng: 21.7489288 },
    { lat: 48.4282505, lng: 21.7489049 },
    { lat: 48.4274324, lng: 21.7490313 },
    { lat: 48.4273921, lng: 21.7490394 },
    { lat: 48.4260742, lng: 21.7488109 },
    { lat: 48.4252257, lng: 21.7483022 },
    { lat: 48.4247266, lng: 21.7485332 },
    { lat: 48.4241144, lng: 21.7486064 },
    { lat: 48.4237558, lng: 21.7484131 },
    { lat: 48.4233673, lng: 21.747941 },
    { lat: 48.4229791, lng: 21.747646 },
    { lat: 48.4228988, lng: 21.7477554 },
    { lat: 48.4226579, lng: 21.7478723 },
    { lat: 48.4223587, lng: 21.7477948 },
    { lat: 48.4221558, lng: 21.7479452 },
    { lat: 48.4218929, lng: 21.7480093 },
    { lat: 48.4215145, lng: 21.7477453 },
    { lat: 48.4209798, lng: 21.7477281 },
    { lat: 48.4206224, lng: 21.7475375 },
    { lat: 48.4204365, lng: 21.7475668 },
    { lat: 48.420079, lng: 21.7474503 },
    { lat: 48.4197847, lng: 21.7471756 },
    { lat: 48.419551, lng: 21.7470689 },
    { lat: 48.4192553, lng: 21.7470308 },
    { lat: 48.4190572, lng: 21.7470744 },
    { lat: 48.4186185, lng: 21.7473659 },
    { lat: 48.418408, lng: 21.7473226 },
    { lat: 48.4180092, lng: 21.7470131 },
    { lat: 48.4176046, lng: 21.7469834 },
    { lat: 48.4173092, lng: 21.7468362 },
    { lat: 48.4166341, lng: 21.7458326 },
    { lat: 48.416369, lng: 21.744756 },
    { lat: 48.4156708, lng: 21.7426374 },
    { lat: 48.4146409, lng: 21.7403616 },
    { lat: 48.4141721, lng: 21.7384489 },
    { lat: 48.4135347, lng: 21.7375501 },
    { lat: 48.4133971, lng: 21.7371586 },
    { lat: 48.4130798, lng: 21.7366604 },
    { lat: 48.4129003, lng: 21.7360547 },
    { lat: 48.4128094, lng: 21.7359359 },
    { lat: 48.4126981, lng: 21.7352684 },
    { lat: 48.4128632, lng: 21.7340467 },
    { lat: 48.412877, lng: 21.733639 },
    { lat: 48.4128077, lng: 21.7334045 },
    { lat: 48.4127094, lng: 21.7332178 },
    { lat: 48.4123861, lng: 21.7330876 },
    { lat: 48.4118864, lng: 21.7333266 },
    { lat: 48.4116858, lng: 21.7335665 },
    { lat: 48.4115212, lng: 21.7335985 },
    { lat: 48.4105147, lng: 21.7331554 },
    { lat: 48.4103036, lng: 21.7327999 },
    { lat: 48.4100269, lng: 21.7320865 },
    { lat: 48.4100008, lng: 21.7318713 },
    { lat: 48.4098234, lng: 21.7315088 },
    { lat: 48.4095277, lng: 21.7304765 },
    { lat: 48.4090085, lng: 21.7293741 },
    { lat: 48.4089669, lng: 21.729168 },
    { lat: 48.4089601, lng: 21.7288713 },
    { lat: 48.409038, lng: 21.7284671 },
    { lat: 48.4090427, lng: 21.7278868 },
    { lat: 48.4092104, lng: 21.7273064 },
    { lat: 48.4092743, lng: 21.7269204 },
    { lat: 48.4091976, lng: 21.7262125 },
    { lat: 48.4092932, lng: 21.7261189 },
    { lat: 48.4093759, lng: 21.7257254 },
    { lat: 48.409527, lng: 21.7253206 },
    { lat: 48.4096511, lng: 21.7251246 },
    { lat: 48.4097317, lng: 21.7247833 },
    { lat: 48.4099669, lng: 21.7244909 },
    { lat: 48.4100498, lng: 21.7241799 },
    { lat: 48.4100251, lng: 21.7241403 },
    { lat: 48.4101696, lng: 21.7237469 },
    { lat: 48.4102715, lng: 21.7232693 },
    { lat: 48.4103352, lng: 21.7231284 },
    { lat: 48.4105172, lng: 21.7229537 },
    { lat: 48.4105501, lng: 21.7227927 },
    { lat: 48.4105207, lng: 21.7227431 },
    { lat: 48.4108932, lng: 21.7217936 },
    { lat: 48.4109822, lng: 21.7212998 },
    { lat: 48.4115702, lng: 21.7195739 },
    { lat: 48.4117057, lng: 21.7190578 },
    { lat: 48.4116901, lng: 21.718934 },
    { lat: 48.4113465, lng: 21.7188339 },
    { lat: 48.410642, lng: 21.7187902 },
    { lat: 48.4102134, lng: 21.7190023 },
    { lat: 48.4099855, lng: 21.7190144 },
    { lat: 48.4094827, lng: 21.7188185 },
    { lat: 48.4085063, lng: 21.7189124 },
    { lat: 48.4082178, lng: 21.7186446 },
    { lat: 48.407995, lng: 21.7175997 },
    { lat: 48.4075712, lng: 21.7168657 },
    { lat: 48.4071731, lng: 21.7154583 },
    { lat: 48.4065821, lng: 21.714783 },
    { lat: 48.406136, lng: 21.7141477 },
    { lat: 48.4058575, lng: 21.7139986 },
    { lat: 48.4051047, lng: 21.7137908 },
    { lat: 48.4049145, lng: 21.7126293 },
    { lat: 48.4038441, lng: 21.7126926 },
    { lat: 48.4031844, lng: 21.7129352 },
    { lat: 48.4025596, lng: 21.713291 },
    { lat: 48.4012826, lng: 21.7137076 },
    { lat: 48.3999719, lng: 21.7138812 },
    { lat: 48.3988137, lng: 21.7144439 },
    { lat: 48.3979276, lng: 21.7151729 },
    { lat: 48.396405, lng: 21.7162824 },
    { lat: 48.3962161, lng: 21.7163308 },
    { lat: 48.3956617, lng: 21.7166776 },
    { lat: 48.3954307, lng: 21.7173233 },
    { lat: 48.3930021, lng: 21.720341 },
    { lat: 48.3925209, lng: 21.720107 },
    { lat: 48.3919962, lng: 21.720549 },
    { lat: 48.391555, lng: 21.7211629 },
    { lat: 48.3912282, lng: 21.7213977 },
    { lat: 48.3910785, lng: 21.7216104 },
    { lat: 48.3907881, lng: 21.7217261 },
    { lat: 48.3905187, lng: 21.7216236 },
    { lat: 48.3903418, lng: 21.7216564 },
    { lat: 48.3901531, lng: 21.7217935 },
    { lat: 48.3899274, lng: 21.7218519 },
    { lat: 48.3896826, lng: 21.7218325 },
    { lat: 48.3891792, lng: 21.7215598 },
    { lat: 48.3889395, lng: 21.7217659 },
    { lat: 48.3887002, lng: 21.721783 },
    { lat: 48.388157, lng: 21.7215839 },
    { lat: 48.387774, lng: 21.7216069 },
    { lat: 48.3877092, lng: 21.7241182 },
    { lat: 48.3879562, lng: 21.7274303 },
    { lat: 48.3878595, lng: 21.7287303 },
    { lat: 48.387538, lng: 21.7302391 },
    { lat: 48.3873496, lng: 21.7308049 },
    { lat: 48.3870626, lng: 21.731433 },
    { lat: 48.3854647, lng: 21.7341343 },
    { lat: 48.3848418, lng: 21.7353236 },
    { lat: 48.3846268, lng: 21.7359465 },
    { lat: 48.3843594, lng: 21.7364608 },
    { lat: 48.3836055, lng: 21.7382968 },
    { lat: 48.3832799, lng: 21.7390323 },
    { lat: 48.3831407, lng: 21.7394679 },
    { lat: 48.3827973, lng: 21.7402094 },
    { lat: 48.381862, lng: 21.7418312 },
    { lat: 48.3796208, lng: 21.7451874 },
    { lat: 48.3784574, lng: 21.7474496 },
    { lat: 48.3780271, lng: 21.7485214 },
    { lat: 48.377846, lng: 21.7495683 },
    { lat: 48.3777809, lng: 21.7504967 },
    { lat: 48.3777847, lng: 21.7517288 },
    { lat: 48.3779439, lng: 21.752594 },
    { lat: 48.3782031, lng: 21.7533181 },
    { lat: 48.3784091, lng: 21.753616 },
    { lat: 48.3790602, lng: 21.7539384 },
    { lat: 48.3794287, lng: 21.7538405 },
    { lat: 48.3800535, lng: 21.7534258 },
    { lat: 48.3804365, lng: 21.7529468 },
    { lat: 48.3816504, lng: 21.7509637 },
    { lat: 48.3821713, lng: 21.7503769 },
    { lat: 48.3827321, lng: 21.7498871 },
    { lat: 48.3833571, lng: 21.7494476 },
    { lat: 48.3861105, lng: 21.7478579 },
    { lat: 48.3871309, lng: 21.7470146 },
    { lat: 48.3881826, lng: 21.7459128 },
    { lat: 48.3899574, lng: 21.7433769 },
    { lat: 48.3910976, lng: 21.7419648 },
    { lat: 48.3921131, lng: 21.7409403 },
    { lat: 48.3927558, lng: 21.7406128 },
    { lat: 48.3932833, lng: 21.7406541 },
    { lat: 48.3935836, lng: 21.7409404 },
    { lat: 48.3942722, lng: 21.7419009 },
    { lat: 48.3947006, lng: 21.7430227 },
    { lat: 48.3949384, lng: 21.7442921 },
    { lat: 48.3950261, lng: 21.7462233 },
    { lat: 48.3954365, lng: 21.7497385 },
    { lat: 48.3954827, lng: 21.7504937 },
    { lat: 48.396164, lng: 21.7542918 },
    { lat: 48.3965093, lng: 21.7572789 },
    { lat: 48.3968133, lng: 21.7572025 },
    { lat: 48.3968806, lng: 21.757196 },
    { lat: 48.3971029, lng: 21.7575143 },
    { lat: 48.3973336, lng: 21.7574745 },
    { lat: 48.397555, lng: 21.7577101 },
    { lat: 48.3976825, lng: 21.757849 },
    { lat: 48.398104, lng: 21.7585965 },
    { lat: 48.3984996, lng: 21.7588718 },
    { lat: 48.3986964, lng: 21.7589102 },
    { lat: 48.3990447, lng: 21.7592327 },
    { lat: 48.3993232, lng: 21.7599226 },
    { lat: 48.3996642, lng: 21.7600136 },
    { lat: 48.4002743, lng: 21.7600267 },
    { lat: 48.4008934, lng: 21.7599115 },
    { lat: 48.4011277, lng: 21.7597501 },
    { lat: 48.4015594, lng: 21.7591516 },
    { lat: 48.4015467, lng: 21.7590564 },
    { lat: 48.4017355, lng: 21.7587433 },
    { lat: 48.402214, lng: 21.7585109 },
    { lat: 48.402894, lng: 21.758352 },
    { lat: 48.4033274, lng: 21.7579856 },
    { lat: 48.4034223, lng: 21.7577865 },
    { lat: 48.403545, lng: 21.7576665 },
    { lat: 48.4038116, lng: 21.7575322 },
    { lat: 48.4041095, lng: 21.7572278 },
    { lat: 48.4042253, lng: 21.7572268 },
    { lat: 48.4042681, lng: 21.7571024 },
    { lat: 48.4044668, lng: 21.7570887 },
    { lat: 48.4045187, lng: 21.757002 },
    { lat: 48.4047781, lng: 21.7569445 },
    { lat: 48.4049687, lng: 21.7567514 },
    { lat: 48.4051133, lng: 21.7568985 },
    { lat: 48.4052757, lng: 21.7569552 },
    { lat: 48.4055614, lng: 21.7565308 },
    { lat: 48.4056975, lng: 21.7564464 },
    { lat: 48.405763, lng: 21.756286 },
    { lat: 48.4059072, lng: 21.756289 },
    { lat: 48.4061676, lng: 21.7561022 },
    { lat: 48.4064347, lng: 21.7560376 },
    { lat: 48.4066107, lng: 21.7558836 },
    { lat: 48.4067529, lng: 21.7559465 },
    { lat: 48.4071, lng: 21.7553317 },
    { lat: 48.4072995, lng: 21.7553855 },
    { lat: 48.4073525, lng: 21.7554783 },
    { lat: 48.4076269, lng: 21.7552227 },
    { lat: 48.4077791, lng: 21.7552288 },
    { lat: 48.4078246, lng: 21.7553333 },
    { lat: 48.4079911, lng: 21.7552461 },
    { lat: 48.4080419, lng: 21.7550822 },
    { lat: 48.4082116, lng: 21.7551244 },
    { lat: 48.4085148, lng: 21.7550548 },
    { lat: 48.4086333, lng: 21.7551138 },
    { lat: 48.4086996, lng: 21.7549834 },
    { lat: 48.4087791, lng: 21.7549655 },
    { lat: 48.4088486, lng: 21.7550442 },
    { lat: 48.4089518, lng: 21.754943 },
    { lat: 48.4090511, lng: 21.7550433 },
    { lat: 48.4095657, lng: 21.7551454 },
    { lat: 48.4099508, lng: 21.7551129 },
    { lat: 48.4100108, lng: 21.7550371 },
    { lat: 48.4101644, lng: 21.7551928 },
    { lat: 48.4103071, lng: 21.7550542 },
    { lat: 48.41046, lng: 21.7550482 },
    { lat: 48.4107201, lng: 21.7551016 },
    { lat: 48.4110196, lng: 21.7553172 },
    { lat: 48.411128, lng: 21.7552901 },
    { lat: 48.4113571, lng: 21.7554445 },
    { lat: 48.4114401, lng: 21.7554363 },
    { lat: 48.411502, lng: 21.7552644 },
    { lat: 48.4116601, lng: 21.7552453 },
    { lat: 48.4117015, lng: 21.7554425 },
    { lat: 48.4120732, lng: 21.755589 },
    { lat: 48.4121292, lng: 21.7556621 },
    { lat: 48.4123366, lng: 21.7554676 },
    { lat: 48.4126282, lng: 21.7556405 },
    { lat: 48.4127184, lng: 21.7556124 },
    { lat: 48.4132193, lng: 21.7558287 },
    { lat: 48.4134689, lng: 21.7557343 },
    { lat: 48.4136364, lng: 21.7560206 },
    { lat: 48.413728, lng: 21.7560002 },
    { lat: 48.4138317, lng: 21.7563307 },
    { lat: 48.4143586, lng: 21.7562256 },
    { lat: 48.4145192, lng: 21.7563413 },
    { lat: 48.4148205, lng: 21.7563766 },
    { lat: 48.4149858, lng: 21.756333 },
    { lat: 48.4152393, lng: 21.7560535 },
    { lat: 48.4154259, lng: 21.7557033 },
    { lat: 48.415619, lng: 21.7555185 },
    { lat: 48.4157774, lng: 21.7552167 },
    { lat: 48.4160457, lng: 21.7549884 },
    { lat: 48.4161221, lng: 21.7547673 },
    { lat: 48.4162523, lng: 21.7547191 },
    { lat: 48.4161996, lng: 21.754554 },
    { lat: 48.4163035, lng: 21.7542343 },
    { lat: 48.4165142, lng: 21.7539932 },
    { lat: 48.416548, lng: 21.7537425 },
    { lat: 48.4166253, lng: 21.753809 },
    { lat: 48.4167255, lng: 21.7535618 },
    { lat: 48.4174131, lng: 21.7536555 },
    { lat: 48.4174987, lng: 21.7537449 },
    { lat: 48.4177614, lng: 21.7535087 },
    { lat: 48.4179902, lng: 21.753151 },
    { lat: 48.4182663, lng: 21.7530808 },
    { lat: 48.4188728, lng: 21.7526181 },
    { lat: 48.4190141, lng: 21.7526457 },
    { lat: 48.4193983, lng: 21.752411 },
    { lat: 48.4195157, lng: 21.752552 },
    { lat: 48.420102, lng: 21.7523033 },
    { lat: 48.4203626, lng: 21.7522872 },
    { lat: 48.4205626, lng: 21.7520609 },
    { lat: 48.4210174, lng: 21.7518251 },
    { lat: 48.4214802, lng: 21.7520872 },
    { lat: 48.421865, lng: 21.7519999 },
    { lat: 48.4221198, lng: 21.7518587 },
    { lat: 48.4223688, lng: 21.7520792 },
    { lat: 48.42279, lng: 21.7518444 },
    { lat: 48.4230368, lng: 21.7518504 },
    { lat: 48.423102, lng: 21.7517664 },
    { lat: 48.4233333, lng: 21.7518389 },
    { lat: 48.4233454, lng: 21.7517446 },
    { lat: 48.4254444, lng: 21.7512589 },
    { lat: 48.4262025, lng: 21.7507859 },
    { lat: 48.426457, lng: 21.7507552 },
    { lat: 48.4266595, lng: 21.7508766 },
    { lat: 48.4269552, lng: 21.7508707 },
    { lat: 48.4271321, lng: 21.751136 },
    { lat: 48.4275756, lng: 21.7513483 },
    { lat: 48.4279083, lng: 21.7516297 },
    { lat: 48.4281402, lng: 21.7519352 },
    { lat: 48.4286381, lng: 21.752038 },
    { lat: 48.4289076, lng: 21.7522257 },
    { lat: 48.4290104, lng: 21.7523641 },
    { lat: 48.429665, lng: 21.7521924 },
    { lat: 48.4301759, lng: 21.7523234 },
    { lat: 48.4310984, lng: 21.7521114 },
    { lat: 48.431458, lng: 21.7519611 },
    { lat: 48.4314532, lng: 21.7517027 },
    { lat: 48.4313301, lng: 21.7504684 },
    { lat: 48.4313645, lng: 21.750072 },
    { lat: 48.4313429, lng: 21.7495114 },
    { lat: 48.4312361, lng: 21.7491437 },
    { lat: 48.4312091, lng: 21.7487181 },
    { lat: 48.4312238, lng: 21.7479271 },
    { lat: 48.4312469, lng: 21.7477762 },
  ],
  Kazimír: [
    { lat: 48.510084, lng: 21.611356 },
    { lat: 48.5101053, lng: 21.6113573 },
    { lat: 48.5104087, lng: 21.6112632 },
    { lat: 48.5104521, lng: 21.6114496 },
    { lat: 48.5103106, lng: 21.6117009 },
    { lat: 48.510344, lng: 21.6117684 },
    { lat: 48.51051, lng: 21.6117096 },
    { lat: 48.5105908, lng: 21.6117808 },
    { lat: 48.5106201, lng: 21.6120185 },
    { lat: 48.5105433, lng: 21.6120904 },
    { lat: 48.5105722, lng: 21.6122046 },
    { lat: 48.5107398, lng: 21.6122029 },
    { lat: 48.5108169, lng: 21.6123051 },
    { lat: 48.5107229, lng: 21.6124836 },
    { lat: 48.5105984, lng: 21.612374 },
    { lat: 48.5105612, lng: 21.6124509 },
    { lat: 48.5105531, lng: 21.6125234 },
    { lat: 48.510653, lng: 21.6127566 },
    { lat: 48.5105793, lng: 21.6129401 },
    { lat: 48.510684, lng: 21.6129381 },
    { lat: 48.5108033, lng: 21.6127776 },
    { lat: 48.5111028, lng: 21.6135905 },
    { lat: 48.5112554, lng: 21.6141429 },
    { lat: 48.5114693, lng: 21.6143892 },
    { lat: 48.5113867, lng: 21.6145306 },
    { lat: 48.5114667, lng: 21.6149014 },
    { lat: 48.5116491, lng: 21.6151436 },
    { lat: 48.5116291, lng: 21.6148748 },
    { lat: 48.5119168, lng: 21.6144553 },
    { lat: 48.5121285, lng: 21.6145813 },
    { lat: 48.5124564, lng: 21.6146358 },
    { lat: 48.5129335, lng: 21.6144374 },
    { lat: 48.5130406, lng: 21.6143285 },
    { lat: 48.5130883, lng: 21.6140206 },
    { lat: 48.5129256, lng: 21.6138332 },
    { lat: 48.5128951, lng: 21.61363 },
    { lat: 48.5130035, lng: 21.6134841 },
    { lat: 48.5130839, lng: 21.6134985 },
    { lat: 48.5131502, lng: 21.613171 },
    { lat: 48.5131571, lng: 21.612777 },
    { lat: 48.5132518, lng: 21.6126631 },
    { lat: 48.5134353, lng: 21.6126657 },
    { lat: 48.5135453, lng: 21.6125768 },
    { lat: 48.5135166, lng: 21.6124381 },
    { lat: 48.5133742, lng: 21.6123296 },
    { lat: 48.5133257, lng: 21.6122031 },
    { lat: 48.5133339, lng: 21.6118548 },
    { lat: 48.5134684, lng: 21.6117931 },
    { lat: 48.513616, lng: 21.6118441 },
    { lat: 48.513639, lng: 21.6117383 },
    { lat: 48.5135705, lng: 21.6115474 },
    { lat: 48.5136302, lng: 21.6113478 },
    { lat: 48.5139785, lng: 21.6113648 },
    { lat: 48.5141047, lng: 21.6112492 },
    { lat: 48.5140058, lng: 21.6109769 },
    { lat: 48.5138468, lng: 21.6109636 },
    { lat: 48.5137377, lng: 21.6106028 },
    { lat: 48.513873, lng: 21.6103646 },
    { lat: 48.5138482, lng: 21.6102026 },
    { lat: 48.5137606, lng: 21.6101329 },
    { lat: 48.5137127, lng: 21.6097505 },
    { lat: 48.5139557, lng: 21.6095886 },
    { lat: 48.5140987, lng: 21.6096682 },
    { lat: 48.5142293, lng: 21.6096752 },
    { lat: 48.5142884, lng: 21.6096119 },
    { lat: 48.5143129, lng: 21.6095212 },
    { lat: 48.5142763, lng: 21.6094253 },
    { lat: 48.5141477, lng: 21.6093284 },
    { lat: 48.5141017, lng: 21.6090914 },
    { lat: 48.5141536, lng: 21.6090593 },
    { lat: 48.5142383, lng: 21.6091469 },
    { lat: 48.5144151, lng: 21.6088309 },
    { lat: 48.5146246, lng: 21.609081 },
    { lat: 48.5147299, lng: 21.6090619 },
    { lat: 48.5147782, lng: 21.6089843 },
    { lat: 48.5147604, lng: 21.6087867 },
    { lat: 48.5149031, lng: 21.6087797 },
    { lat: 48.5149904, lng: 21.6084438 },
    { lat: 48.5151581, lng: 21.6082841 },
    { lat: 48.5151467, lng: 21.6079559 },
    { lat: 48.515368, lng: 21.6076973 },
    { lat: 48.5154726, lng: 21.6076623 },
    { lat: 48.5156202, lng: 21.6077466 },
    { lat: 48.5156994, lng: 21.60772 },
    { lat: 48.5159274, lng: 21.6073464 },
    { lat: 48.5159835, lng: 21.6073815 },
    { lat: 48.5160464, lng: 21.6076994 },
    { lat: 48.5163903, lng: 21.6080832 },
    { lat: 48.5166135, lng: 21.6080223 },
    { lat: 48.5168184, lng: 21.6082644 },
    { lat: 48.5169522, lng: 21.6081758 },
    { lat: 48.5169024, lng: 21.6079287 },
    { lat: 48.5169717, lng: 21.6078285 },
    { lat: 48.5171022, lng: 21.6077908 },
    { lat: 48.5171705, lng: 21.6079376 },
    { lat: 48.5174873, lng: 21.6080794 },
    { lat: 48.517568, lng: 21.6082748 },
    { lat: 48.5173753, lng: 21.608457 },
    { lat: 48.5175077, lng: 21.6086477 },
    { lat: 48.5173588, lng: 21.6089212 },
    { lat: 48.5173752, lng: 21.6095097 },
    { lat: 48.5174207, lng: 21.6095604 },
    { lat: 48.5175077, lng: 21.6094569 },
    { lat: 48.5175742, lng: 21.6092135 },
    { lat: 48.5176563, lng: 21.6092157 },
    { lat: 48.5177018, lng: 21.6092746 },
    { lat: 48.5177225, lng: 21.6095324 },
    { lat: 48.5178478, lng: 21.6095862 },
    { lat: 48.5179154, lng: 21.6094662 },
    { lat: 48.5178559, lng: 21.6092688 },
    { lat: 48.5179479, lng: 21.6089009 },
    { lat: 48.5179931, lng: 21.6088658 },
    { lat: 48.5180551, lng: 21.6091074 },
    { lat: 48.5181178, lng: 21.6091421 },
    { lat: 48.518483, lng: 21.6089203 },
    { lat: 48.5184322, lng: 21.6092787 },
    { lat: 48.5184808, lng: 21.6094929 },
    { lat: 48.5185466, lng: 21.6094671 },
    { lat: 48.5186328, lng: 21.6092536 },
    { lat: 48.5186945, lng: 21.6092841 },
    { lat: 48.518837, lng: 21.609674 },
    { lat: 48.5189413, lng: 21.6097019 },
    { lat: 48.5192093, lng: 21.6093466 },
    { lat: 48.5190465, lng: 21.6089967 },
    { lat: 48.5190818, lng: 21.6088486 },
    { lat: 48.5193199, lng: 21.6088321 },
    { lat: 48.5193611, lng: 21.6088976 },
    { lat: 48.5193923, lng: 21.6092882 },
    { lat: 48.5194809, lng: 21.609361 },
    { lat: 48.5196495, lng: 21.6090348 },
    { lat: 48.5201833, lng: 21.6086862 },
    { lat: 48.5201723, lng: 21.6086148 },
    { lat: 48.5200406, lng: 21.6085749 },
    { lat: 48.5198585, lng: 21.6083383 },
    { lat: 48.5198444, lng: 21.6082374 },
    { lat: 48.519899, lng: 21.608175 },
    { lat: 48.5200288, lng: 21.6082704 },
    { lat: 48.5200762, lng: 21.6082386 },
    { lat: 48.5200954, lng: 21.6079556 },
    { lat: 48.5202698, lng: 21.6077146 },
    { lat: 48.5201405, lng: 21.6075201 },
    { lat: 48.5200563, lng: 21.6074839 },
    { lat: 48.520032, lng: 21.6073822 },
    { lat: 48.5200464, lng: 21.6072063 },
    { lat: 48.5201226, lng: 21.6070382 },
    { lat: 48.5202883, lng: 21.6071313 },
    { lat: 48.5205063, lng: 21.6069588 },
    { lat: 48.5205951, lng: 21.6069951 },
    { lat: 48.5206636, lng: 21.6072292 },
    { lat: 48.5207994, lng: 21.6072973 },
    { lat: 48.5211209, lng: 21.6072932 },
    { lat: 48.5211666, lng: 21.6072297 },
    { lat: 48.5211495, lng: 21.6070238 },
    { lat: 48.5209896, lng: 21.6069392 },
    { lat: 48.5209989, lng: 21.6067079 },
    { lat: 48.5208328, lng: 21.6064409 },
    { lat: 48.5209024, lng: 21.606391 },
    { lat: 48.5210092, lng: 21.6063165 },
    { lat: 48.5210698, lng: 21.6061916 },
    { lat: 48.5213157, lng: 21.6062896 },
    { lat: 48.5213755, lng: 21.6064125 },
    { lat: 48.5213929, lng: 21.6065981 },
    { lat: 48.5214736, lng: 21.6066678 },
    { lat: 48.5215458, lng: 21.6065515 },
    { lat: 48.5217015, lng: 21.6064852 },
    { lat: 48.5217703, lng: 21.606292 },
    { lat: 48.5222059, lng: 21.6062097 },
    { lat: 48.5224036, lng: 21.6060381 },
    { lat: 48.5224246, lng: 21.6058693 },
    { lat: 48.522595, lng: 21.605604 },
    { lat: 48.5227709, lng: 21.6054544 },
    { lat: 48.5231532, lng: 21.6055256 },
    { lat: 48.5231685, lng: 21.6053468 },
    { lat: 48.5230905, lng: 21.60517 },
    { lat: 48.5231105, lng: 21.6050843 },
    { lat: 48.5234638, lng: 21.6050892 },
    { lat: 48.5236422, lng: 21.6049859 },
    { lat: 48.5237362, lng: 21.6047276 },
    { lat: 48.5235272, lng: 21.6047749 },
    { lat: 48.5234677, lng: 21.6046651 },
    { lat: 48.5234476, lng: 21.6044275 },
    { lat: 48.5232362, lng: 21.6040682 },
    { lat: 48.523241, lng: 21.6040033 },
    { lat: 48.52344, lng: 21.6038366 },
    { lat: 48.523334, lng: 21.6035867 },
    { lat: 48.5233029, lng: 21.6033158 },
    { lat: 48.5233649, lng: 21.6032007 },
    { lat: 48.5235685, lng: 21.6031615 },
    { lat: 48.5236949, lng: 21.6028511 },
    { lat: 48.5236591, lng: 21.6027007 },
    { lat: 48.5235782, lng: 21.6026253 },
    { lat: 48.5233682, lng: 21.6027203 },
    { lat: 48.5233323, lng: 21.6026846 },
    { lat: 48.5232535, lng: 21.6022352 },
    { lat: 48.5230524, lng: 21.6018828 },
    { lat: 48.5231152, lng: 21.6016743 },
    { lat: 48.5233297, lng: 21.6016231 },
    { lat: 48.5237185, lng: 21.6018852 },
    { lat: 48.5239474, lng: 21.6019072 },
    { lat: 48.524042, lng: 21.6019843 },
    { lat: 48.5241282, lng: 21.6022485 },
    { lat: 48.524266, lng: 21.6023871 },
    { lat: 48.5243786, lng: 21.6023161 },
    { lat: 48.5243823, lng: 21.6020464 },
    { lat: 48.524431, lng: 21.6018996 },
    { lat: 48.5246357, lng: 21.602053 },
    { lat: 48.5249219, lng: 21.6017484 },
    { lat: 48.5250223, lng: 21.6018396 },
    { lat: 48.5249649, lng: 21.6021546 },
    { lat: 48.5249837, lng: 21.6023032 },
    { lat: 48.5250981, lng: 21.6023737 },
    { lat: 48.5252777, lng: 21.6021933 },
    { lat: 48.5254435, lng: 21.6022531 },
    { lat: 48.5255483, lng: 21.602376 },
    { lat: 48.5256791, lng: 21.6023801 },
    { lat: 48.5259759, lng: 21.6025394 },
    { lat: 48.5259736, lng: 21.6028523 },
    { lat: 48.5260832, lng: 21.6030704 },
    { lat: 48.5262243, lng: 21.6030916 },
    { lat: 48.5263401, lng: 21.6030079 },
    { lat: 48.5264726, lng: 21.6030734 },
    { lat: 48.5264432, lng: 21.6035896 },
    { lat: 48.526497, lng: 21.603709 },
    { lat: 48.5268006, lng: 21.6039418 },
    { lat: 48.5269618, lng: 21.60383 },
    { lat: 48.5271847, lng: 21.6041194 },
    { lat: 48.5272865, lng: 21.6040934 },
    { lat: 48.5274843, lng: 21.6042416 },
    { lat: 48.5275283, lng: 21.6044064 },
    { lat: 48.5274671, lng: 21.6047145 },
    { lat: 48.5275088, lng: 21.6050835 },
    { lat: 48.5275778, lng: 21.6052169 },
    { lat: 48.5276862, lng: 21.6052295 },
    { lat: 48.5277811, lng: 21.6053609 },
    { lat: 48.5279093, lng: 21.6053574 },
    { lat: 48.5279542, lng: 21.6050748 },
    { lat: 48.5280753, lng: 21.6048601 },
    { lat: 48.5282075, lng: 21.6047584 },
    { lat: 48.5283152, lng: 21.6048666 },
    { lat: 48.5284331, lng: 21.605339 },
    { lat: 48.528535, lng: 21.6054068 },
    { lat: 48.5286724, lng: 21.605327 },
    { lat: 48.5288309, lng: 21.6053653 },
    { lat: 48.5292918, lng: 21.6057904 },
    { lat: 48.5294536, lng: 21.6057436 },
    { lat: 48.5296102, lng: 21.6055515 },
    { lat: 48.5297548, lng: 21.6055285 },
    { lat: 48.5298074, lng: 21.6056367 },
    { lat: 48.5297373, lng: 21.606192 },
    { lat: 48.5297604, lng: 21.6061331 },
    { lat: 48.5310035, lng: 21.6061586 },
    { lat: 48.531466, lng: 21.6063657 },
    { lat: 48.531501, lng: 21.6065715 },
    { lat: 48.5316418, lng: 21.6068178 },
    { lat: 48.5318193, lng: 21.6068066 },
    { lat: 48.5321211, lng: 21.6066131 },
    { lat: 48.5325628, lng: 21.6061949 },
    { lat: 48.5325501, lng: 21.6060612 },
    { lat: 48.532711, lng: 21.6060964 },
    { lat: 48.5329802, lng: 21.6064731 },
    { lat: 48.5331117, lng: 21.6064651 },
    { lat: 48.5332734, lng: 21.6066142 },
    { lat: 48.5333144, lng: 21.6064345 },
    { lat: 48.5335727, lng: 21.6062534 },
    { lat: 48.5335638, lng: 21.6060203 },
    { lat: 48.5334324, lng: 21.6058224 },
    { lat: 48.5334068, lng: 21.6055933 },
    { lat: 48.5334638, lng: 21.6054554 },
    { lat: 48.5335976, lng: 21.6053756 },
    { lat: 48.5337532, lng: 21.6050625 },
    { lat: 48.5340244, lng: 21.6050641 },
    { lat: 48.5341174, lng: 21.6049744 },
    { lat: 48.5338432, lng: 21.6043777 },
    { lat: 48.5338363, lng: 21.6040976 },
    { lat: 48.5338706, lng: 21.6040323 },
    { lat: 48.5340454, lng: 21.6040467 },
    { lat: 48.5342074, lng: 21.6039619 },
    { lat: 48.5345927, lng: 21.6040205 },
    { lat: 48.5347543, lng: 21.6035263 },
    { lat: 48.5347092, lng: 21.6033215 },
    { lat: 48.5346028, lng: 21.6031763 },
    { lat: 48.5341479, lng: 21.6030476 },
    { lat: 48.5340926, lng: 21.6027177 },
    { lat: 48.534118, lng: 21.6026199 },
    { lat: 48.5342017, lng: 21.6024782 },
    { lat: 48.534263, lng: 21.6024935 },
    { lat: 48.5343552, lng: 21.6027319 },
    { lat: 48.5345489, lng: 21.6026578 },
    { lat: 48.5349649, lng: 21.6026456 },
    { lat: 48.5351298, lng: 21.6027896 },
    { lat: 48.5353033, lng: 21.6025954 },
    { lat: 48.5353421, lng: 21.6022549 },
    { lat: 48.5355368, lng: 21.6020941 },
    { lat: 48.5354952, lng: 21.6014176 },
    { lat: 48.5355395, lng: 21.6013068 },
    { lat: 48.5359254, lng: 21.6011439 },
    { lat: 48.5359966, lng: 21.6012366 },
    { lat: 48.535897, lng: 21.601577 },
    { lat: 48.5362884, lng: 21.6015674 },
    { lat: 48.5363574, lng: 21.6018265 },
    { lat: 48.536535, lng: 21.6020236 },
    { lat: 48.5366551, lng: 21.6019326 },
    { lat: 48.536864, lng: 21.6019985 },
    { lat: 48.536962, lng: 21.6023579 },
    { lat: 48.5370507, lng: 21.6024354 },
    { lat: 48.5371076, lng: 21.602381 },
    { lat: 48.5371658, lng: 21.6019662 },
    { lat: 48.5372445, lng: 21.6017638 },
    { lat: 48.5375268, lng: 21.6015953 },
    { lat: 48.5376569, lng: 21.6019048 },
    { lat: 48.5375773, lng: 21.6022383 },
    { lat: 48.5376161, lng: 21.602419 },
    { lat: 48.5377172, lng: 21.6025685 },
    { lat: 48.5379152, lng: 21.6024913 },
    { lat: 48.5380072, lng: 21.6022397 },
    { lat: 48.5382054, lng: 21.6020418 },
    { lat: 48.5382881, lng: 21.6020252 },
    { lat: 48.5384066, lng: 21.6021558 },
    { lat: 48.5384236, lng: 21.6023997 },
    { lat: 48.5385146, lng: 21.6025576 },
    { lat: 48.5387553, lng: 21.6024333 },
    { lat: 48.5388299, lng: 21.6022826 },
    { lat: 48.5389428, lng: 21.6022985 },
    { lat: 48.5390987, lng: 21.6020816 },
    { lat: 48.5393434, lng: 21.602021 },
    { lat: 48.5394181, lng: 21.6019516 },
    { lat: 48.5394587, lng: 21.6017928 },
    { lat: 48.5396112, lng: 21.6016993 },
    { lat: 48.5398095, lng: 21.6018668 },
    { lat: 48.5400322, lng: 21.6019389 },
    { lat: 48.5401859, lng: 21.6020882 },
    { lat: 48.540223, lng: 21.6022087 },
    { lat: 48.5404497, lng: 21.6025078 },
    { lat: 48.5407522, lng: 21.6028202 },
    { lat: 48.5411162, lng: 21.6027609 },
    { lat: 48.5413678, lng: 21.6031895 },
    { lat: 48.5413474, lng: 21.6033442 },
    { lat: 48.5414309, lng: 21.6034301 },
    { lat: 48.5414725, lng: 21.6036359 },
    { lat: 48.5414602, lng: 21.6038778 },
    { lat: 48.5417473, lng: 21.6040023 },
    { lat: 48.5420025, lng: 21.6039559 },
    { lat: 48.5420013, lng: 21.6040309 },
    { lat: 48.5420506, lng: 21.604064 },
    { lat: 48.5421678, lng: 21.6040317 },
    { lat: 48.5422057, lng: 21.6039349 },
    { lat: 48.5423397, lng: 21.6039061 },
    { lat: 48.5424934, lng: 21.6037671 },
    { lat: 48.5425927, lng: 21.6039707 },
    { lat: 48.5425286, lng: 21.604056 },
    { lat: 48.5425371, lng: 21.6041578 },
    { lat: 48.5426288, lng: 21.604343 },
    { lat: 48.5430375, lng: 21.6045169 },
    { lat: 48.5434911, lng: 21.6044803 },
    { lat: 48.5435194, lng: 21.6044026 },
    { lat: 48.5437307, lng: 21.6043547 },
    { lat: 48.5440708, lng: 21.6037593 },
    { lat: 48.543954, lng: 21.6036481 },
    { lat: 48.5434434, lng: 21.5997223 },
    { lat: 48.5438039, lng: 21.599536 },
    { lat: 48.544317, lng: 21.5994203 },
    { lat: 48.5444959, lng: 21.5992896 },
    { lat: 48.5457176, lng: 21.599083 },
    { lat: 48.5466045, lng: 21.5990121 },
    { lat: 48.5471742, lng: 21.5987006 },
    { lat: 48.5477622, lng: 21.5985219 },
    { lat: 48.549389, lng: 21.5976705 },
    { lat: 48.5499133, lng: 21.5973491 },
    { lat: 48.5505768, lng: 21.5967344 },
    { lat: 48.5519208, lng: 21.5957937 },
    { lat: 48.5524115, lng: 21.5953142 },
    { lat: 48.5530505, lng: 21.594511 },
    { lat: 48.5529708, lng: 21.5943172 },
    { lat: 48.5528422, lng: 21.5942199 },
    { lat: 48.5525144, lng: 21.5935713 },
    { lat: 48.5519508, lng: 21.5928149 },
    { lat: 48.5517573, lng: 21.5923733 },
    { lat: 48.5515922, lng: 21.5923441 },
    { lat: 48.5514165, lng: 21.5920981 },
    { lat: 48.5512567, lng: 21.5920188 },
    { lat: 48.5505318, lng: 21.5908068 },
    { lat: 48.5501846, lng: 21.5894254 },
    { lat: 48.5493362, lng: 21.586903 },
    { lat: 48.5455617, lng: 21.5878058 },
    { lat: 48.5446572, lng: 21.5844144 },
    { lat: 48.5423833, lng: 21.5768702 },
    { lat: 48.5371119, lng: 21.5664613 },
    { lat: 48.5368426, lng: 21.5658537 },
    { lat: 48.5367458, lng: 21.5657655 },
    { lat: 48.5365913, lng: 21.5663129 },
    { lat: 48.5364481, lng: 21.5663022 },
    { lat: 48.5364662, lng: 21.5660256 },
    { lat: 48.5364418, lng: 21.5659629 },
    { lat: 48.5363921, lng: 21.5659891 },
    { lat: 48.5363352, lng: 21.5661288 },
    { lat: 48.536338, lng: 21.5663423 },
    { lat: 48.5362061, lng: 21.5667392 },
    { lat: 48.5361118, lng: 21.5668653 },
    { lat: 48.5359745, lng: 21.5665034 },
    { lat: 48.5357494, lng: 21.5657496 },
    { lat: 48.5356681, lng: 21.5657367 },
    { lat: 48.5356841, lng: 21.5660257 },
    { lat: 48.5355733, lng: 21.5661997 },
    { lat: 48.5354382, lng: 21.5662858 },
    { lat: 48.5353328, lng: 21.5664921 },
    { lat: 48.5352811, lng: 21.5663743 },
    { lat: 48.5351078, lng: 21.5664146 },
    { lat: 48.5350457, lng: 21.5666866 },
    { lat: 48.5349607, lng: 21.5666679 },
    { lat: 48.534784, lng: 21.5669062 },
    { lat: 48.5348645, lng: 21.5670416 },
    { lat: 48.5347873, lng: 21.5673359 },
    { lat: 48.5346355, lng: 21.5673819 },
    { lat: 48.5345414, lng: 21.5675143 },
    { lat: 48.5345082, lng: 21.5674891 },
    { lat: 48.5345171, lng: 21.5673714 },
    { lat: 48.5344039, lng: 21.567347 },
    { lat: 48.5343593, lng: 21.567401 },
    { lat: 48.5343666, lng: 21.567462 },
    { lat: 48.5344278, lng: 21.5674751 },
    { lat: 48.5344195, lng: 21.5676205 },
    { lat: 48.534405, lng: 21.5676999 },
    { lat: 48.5342467, lng: 21.5677579 },
    { lat: 48.5343233, lng: 21.5679561 },
    { lat: 48.5342765, lng: 21.5681349 },
    { lat: 48.5341608, lng: 21.5681036 },
    { lat: 48.5339211, lng: 21.5683725 },
    { lat: 48.5338463, lng: 21.568356 },
    { lat: 48.5336967, lng: 21.5681181 },
    { lat: 48.5335187, lng: 21.5681952 },
    { lat: 48.5334639, lng: 21.5684082 },
    { lat: 48.5334862, lng: 21.568519 },
    { lat: 48.5332458, lng: 21.5687307 },
    { lat: 48.5330811, lng: 21.5687938 },
    { lat: 48.5332388, lng: 21.569433 },
    { lat: 48.5331467, lng: 21.5697972 },
    { lat: 48.5329483, lng: 21.5699427 },
    { lat: 48.5328249, lng: 21.5695981 },
    { lat: 48.5327725, lng: 21.5695783 },
    { lat: 48.5326605, lng: 21.5696789 },
    { lat: 48.5325169, lng: 21.5696554 },
    { lat: 48.5323872, lng: 21.5700039 },
    { lat: 48.5324232, lng: 21.570233 },
    { lat: 48.5320511, lng: 21.5700073 },
    { lat: 48.5319254, lng: 21.5696664 },
    { lat: 48.5317508, lng: 21.5696613 },
    { lat: 48.5316665, lng: 21.569781 },
    { lat: 48.5317281, lng: 21.5703664 },
    { lat: 48.5317038, lng: 21.5706257 },
    { lat: 48.531583, lng: 21.5705656 },
    { lat: 48.5314709, lng: 21.5706197 },
    { lat: 48.5314055, lng: 21.5704588 },
    { lat: 48.5311415, lng: 21.5701534 },
    { lat: 48.5279408, lng: 21.5695819 },
    { lat: 48.5267047, lng: 21.5695224 },
    { lat: 48.5260908, lng: 21.5701351 },
    { lat: 48.5248123, lng: 21.5692913 },
    { lat: 48.5248408, lng: 21.5685053 },
    { lat: 48.5230232, lng: 21.5678595 },
    { lat: 48.522677, lng: 21.5678686 },
    { lat: 48.521855, lng: 21.568124 },
    { lat: 48.5215887, lng: 21.5683772 },
    { lat: 48.5213793, lng: 21.5684531 },
    { lat: 48.5213347, lng: 21.5683104 },
    { lat: 48.5212425, lng: 21.5684359 },
    { lat: 48.5212304, lng: 21.5686394 },
    { lat: 48.5210386, lng: 21.5686623 },
    { lat: 48.5209195, lng: 21.568945 },
    { lat: 48.5207927, lng: 21.5688389 },
    { lat: 48.5207186, lng: 21.5690438 },
    { lat: 48.5204944, lng: 21.5692687 },
    { lat: 48.5204253, lng: 21.5694545 },
    { lat: 48.5201391, lng: 21.5695641 },
    { lat: 48.5198223, lng: 21.5694985 },
    { lat: 48.5193848, lng: 21.5696618 },
    { lat: 48.5193399, lng: 21.5703089 },
    { lat: 48.5192603, lng: 21.570353 },
    { lat: 48.519212, lng: 21.5704807 },
    { lat: 48.5190543, lng: 21.5705479 },
    { lat: 48.5189386, lng: 21.5706774 },
    { lat: 48.5187449, lng: 21.571147 },
    { lat: 48.5186172, lng: 21.5711346 },
    { lat: 48.5183077, lng: 21.5716875 },
    { lat: 48.518141, lng: 21.5716038 },
    { lat: 48.5180483, lng: 21.571345 },
    { lat: 48.5179316, lng: 21.5712797 },
    { lat: 48.5178533, lng: 21.5714537 },
    { lat: 48.5178518, lng: 21.5718406 },
    { lat: 48.5177401, lng: 21.571944 },
    { lat: 48.5173303, lng: 21.5719365 },
    { lat: 48.5174394, lng: 21.5724906 },
    { lat: 48.5174209, lng: 21.5726373 },
    { lat: 48.5173065, lng: 21.5727277 },
    { lat: 48.5170556, lng: 21.5726111 },
    { lat: 48.5169508, lng: 21.5726494 },
    { lat: 48.5169867, lng: 21.5727585 },
    { lat: 48.5172441, lng: 21.5728719 },
    { lat: 48.5172587, lng: 21.5729462 },
    { lat: 48.5170031, lng: 21.5734978 },
    { lat: 48.516832, lng: 21.5733324 },
    { lat: 48.5167464, lng: 21.5731011 },
    { lat: 48.5166426, lng: 21.5730897 },
    { lat: 48.5165366, lng: 21.5732782 },
    { lat: 48.5166073, lng: 21.5735104 },
    { lat: 48.5165842, lng: 21.5735662 },
    { lat: 48.5162522, lng: 21.573444 },
    { lat: 48.5160487, lng: 21.5737328 },
    { lat: 48.5159103, lng: 21.5736924 },
    { lat: 48.5155323, lng: 21.5740073 },
    { lat: 48.5155955, lng: 21.5742992 },
    { lat: 48.5155369, lng: 21.5746124 },
    { lat: 48.5155737, lng: 21.5747971 },
    { lat: 48.5157043, lng: 21.5747625 },
    { lat: 48.515751, lng: 21.574905 },
    { lat: 48.5156801, lng: 21.5753823 },
    { lat: 48.5156324, lng: 21.575523 },
    { lat: 48.5154148, lng: 21.5758251 },
    { lat: 48.5150241, lng: 21.5757408 },
    { lat: 48.5149959, lng: 21.5758182 },
    { lat: 48.5149404, lng: 21.5761572 },
    { lat: 48.5149599, lng: 21.5762883 },
    { lat: 48.5151196, lng: 21.576408 },
    { lat: 48.5151663, lng: 21.5765945 },
    { lat: 48.5151452, lng: 21.5766814 },
    { lat: 48.5149443, lng: 21.5767801 },
    { lat: 48.5147728, lng: 21.5772379 },
    { lat: 48.5145483, lng: 21.5776547 },
    { lat: 48.5145643, lng: 21.5784199 },
    { lat: 48.5144903, lng: 21.5787157 },
    { lat: 48.514434, lng: 21.578792 },
    { lat: 48.5143448, lng: 21.5787821 },
    { lat: 48.5142141, lng: 21.5784526 },
    { lat: 48.5141589, lng: 21.5784096 },
    { lat: 48.5141215, lng: 21.5784379 },
    { lat: 48.5141053, lng: 21.5786675 },
    { lat: 48.5141561, lng: 21.5789865 },
    { lat: 48.5141285, lng: 21.5790454 },
    { lat: 48.5139376, lng: 21.5791822 },
    { lat: 48.5138099, lng: 21.5790864 },
    { lat: 48.5136767, lng: 21.5791105 },
    { lat: 48.5136331, lng: 21.5792794 },
    { lat: 48.5137073, lng: 21.5793942 },
    { lat: 48.5140592, lng: 21.5793043 },
    { lat: 48.5141374, lng: 21.5793618 },
    { lat: 48.5140383, lng: 21.5796847 },
    { lat: 48.5137924, lng: 21.5801369 },
    { lat: 48.5135492, lng: 21.5800172 },
    { lat: 48.5134821, lng: 21.5803438 },
    { lat: 48.513293, lng: 21.5806621 },
    { lat: 48.5132599, lng: 21.5810851 },
    { lat: 48.5132149, lng: 21.5812023 },
    { lat: 48.5129441, lng: 21.5816923 },
    { lat: 48.5127239, lng: 21.5817844 },
    { lat: 48.5128429, lng: 21.5821764 },
    { lat: 48.5127553, lng: 21.5823382 },
    { lat: 48.5126288, lng: 21.5824864 },
    { lat: 48.5125172, lng: 21.5823999 },
    { lat: 48.5122604, lng: 21.5824734 },
    { lat: 48.512206, lng: 21.5825759 },
    { lat: 48.5122859, lng: 21.5829342 },
    { lat: 48.5122382, lng: 21.5830412 },
    { lat: 48.5121787, lng: 21.5830816 },
    { lat: 48.5119636, lng: 21.5829186 },
    { lat: 48.5119226, lng: 21.5830614 },
    { lat: 48.5120067, lng: 21.5835702 },
    { lat: 48.5118927, lng: 21.583718 },
    { lat: 48.5119485, lng: 21.5840988 },
    { lat: 48.5118102, lng: 21.5841853 },
    { lat: 48.5117524, lng: 21.5845269 },
    { lat: 48.5116673, lng: 21.5846618 },
    { lat: 48.511387, lng: 21.5845811 },
    { lat: 48.5113232, lng: 21.5847101 },
    { lat: 48.511318, lng: 21.5850844 },
    { lat: 48.5111623, lng: 21.5852242 },
    { lat: 48.5111003, lng: 21.5855036 },
    { lat: 48.511327, lng: 21.5854057 },
    { lat: 48.5114188, lng: 21.5854673 },
    { lat: 48.5114643, lng: 21.5855996 },
    { lat: 48.5114553, lng: 21.5857662 },
    { lat: 48.511375, lng: 21.585988 },
    { lat: 48.511362, lng: 21.586078 },
    { lat: 48.511424, lng: 21.586214 },
    { lat: 48.511526, lng: 21.586271 },
    { lat: 48.511614, lng: 21.586398 },
    { lat: 48.511495, lng: 21.586581 },
    { lat: 48.511446, lng: 21.58669 },
    { lat: 48.511621, lng: 21.586935 },
    { lat: 48.511683, lng: 21.587086 },
    { lat: 48.511736, lng: 21.587311 },
    { lat: 48.51182, lng: 21.587476 },
    { lat: 48.511782, lng: 21.587816 },
    { lat: 48.511787, lng: 21.588043 },
    { lat: 48.511854, lng: 21.58819 },
    { lat: 48.511966, lng: 21.588222 },
    { lat: 48.511993, lng: 21.588166 },
    { lat: 48.512057, lng: 21.588159 },
    { lat: 48.512082, lng: 21.58823 },
    { lat: 48.512027, lng: 21.588335 },
    { lat: 48.511917, lng: 21.588453 },
    { lat: 48.51188, lng: 21.588614 },
    { lat: 48.511956, lng: 21.588731 },
    { lat: 48.512013, lng: 21.588857 },
    { lat: 48.512093, lng: 21.588929 },
    { lat: 48.512031, lng: 21.589057 },
    { lat: 48.512056, lng: 21.589202 },
    { lat: 48.512162, lng: 21.589328 },
    { lat: 48.51219, lng: 21.589404 },
    { lat: 48.512234, lng: 21.589618 },
    { lat: 48.512245, lng: 21.589728 },
    { lat: 48.512344, lng: 21.589825 },
    { lat: 48.512347, lng: 21.589829 },
    { lat: 48.512322, lng: 21.589919 },
    { lat: 48.512317, lng: 21.59004 },
    { lat: 48.51234, lng: 21.590096 },
    { lat: 48.512335, lng: 21.59025 },
    { lat: 48.512359, lng: 21.590444 },
    { lat: 48.512381, lng: 21.590553 },
    { lat: 48.512389, lng: 21.59072 },
    { lat: 48.512467, lng: 21.590934 },
    { lat: 48.512709, lng: 21.590989 },
    { lat: 48.512841, lng: 21.590912 },
    { lat: 48.51295, lng: 21.590987 },
    { lat: 48.51301, lng: 21.591108 },
    { lat: 48.512988, lng: 21.591374 },
    { lat: 48.512924, lng: 21.591534 },
    { lat: 48.512904, lng: 21.591692 },
    { lat: 48.512905, lng: 21.591789 },
    { lat: 48.512867, lng: 21.592 },
    { lat: 48.512824, lng: 21.592216 },
    { lat: 48.51293, lng: 21.592316 },
    { lat: 48.513039, lng: 21.592328 },
    { lat: 48.513111, lng: 21.592461 },
    { lat: 48.513178, lng: 21.592944 },
    { lat: 48.513236, lng: 21.593296 },
    { lat: 48.513277, lng: 21.593558 },
    { lat: 48.513347, lng: 21.594041 },
    { lat: 48.51319, lng: 21.594235 },
    { lat: 48.513202, lng: 21.594472 },
    { lat: 48.513244, lng: 21.594606 },
    { lat: 48.513102, lng: 21.59475 },
    { lat: 48.512971, lng: 21.594784 },
    { lat: 48.512839, lng: 21.594909 },
    { lat: 48.512916, lng: 21.59505 },
    { lat: 48.512917, lng: 21.595117 },
    { lat: 48.512841, lng: 21.595325 },
    { lat: 48.512767, lng: 21.595431 },
    { lat: 48.512705, lng: 21.595472 },
    { lat: 48.512601, lng: 21.595495 },
    { lat: 48.512527, lng: 21.595575 },
    { lat: 48.512472, lng: 21.595794 },
    { lat: 48.512415, lng: 21.595892 },
    { lat: 48.512355, lng: 21.595924 },
    { lat: 48.512278, lng: 21.596041 },
    { lat: 48.51226, lng: 21.596059 },
    { lat: 48.512226, lng: 21.596212 },
    { lat: 48.51213, lng: 21.596309 },
    { lat: 48.511982, lng: 21.596581 },
    { lat: 48.511922, lng: 21.596632 },
    { lat: 48.511825, lng: 21.596925 },
    { lat: 48.511809, lng: 21.597021 },
    { lat: 48.51176, lng: 21.597116 },
    { lat: 48.51175, lng: 21.597172 },
    { lat: 48.511612, lng: 21.597269 },
    { lat: 48.51157, lng: 21.597355 },
    { lat: 48.511564, lng: 21.597422 },
    { lat: 48.51152, lng: 21.597505 },
    { lat: 48.511477, lng: 21.597648 },
    { lat: 48.511411, lng: 21.597732 },
    { lat: 48.511387, lng: 21.597877 },
    { lat: 48.511325, lng: 21.597957 },
    { lat: 48.511308, lng: 21.598046 },
    { lat: 48.511278, lng: 21.598101 },
    { lat: 48.511257, lng: 21.59822 },
    { lat: 48.511186, lng: 21.59825 },
    { lat: 48.511157, lng: 21.598284 },
    { lat: 48.511156, lng: 21.598309 },
    { lat: 48.511143, lng: 21.59841 },
    { lat: 48.511119, lng: 21.598445 },
    { lat: 48.511066, lng: 21.598587 },
    { lat: 48.510972, lng: 21.598751 },
    { lat: 48.510962, lng: 21.598795 },
    { lat: 48.510905, lng: 21.598871 },
    { lat: 48.51088, lng: 21.598979 },
    { lat: 48.51081, lng: 21.599118 },
    { lat: 48.510786, lng: 21.599274 },
    { lat: 48.510736, lng: 21.599297 },
    { lat: 48.510709, lng: 21.599327 },
    { lat: 48.510695, lng: 21.599406 },
    { lat: 48.510654, lng: 21.599496 },
    { lat: 48.510557, lng: 21.599654 },
    { lat: 48.510539, lng: 21.59972 },
    { lat: 48.510504, lng: 21.599808 },
    { lat: 48.510444, lng: 21.599906 },
    { lat: 48.510432, lng: 21.599934 },
    { lat: 48.510398, lng: 21.600016 },
    { lat: 48.510348, lng: 21.600338 },
    { lat: 48.510233, lng: 21.600686 },
    { lat: 48.510194, lng: 21.600755 },
    { lat: 48.510133, lng: 21.600819 },
    { lat: 48.510105, lng: 21.601013 },
    { lat: 48.510048, lng: 21.601141 },
    { lat: 48.509996, lng: 21.601315 },
    { lat: 48.509931, lng: 21.601428 },
    { lat: 48.509858, lng: 21.601471 },
    { lat: 48.509626, lng: 21.601753 },
    { lat: 48.509629, lng: 21.601873 },
    { lat: 48.509617, lng: 21.601905 },
    { lat: 48.509584, lng: 21.601988 },
    { lat: 48.509403, lng: 21.602115 },
    { lat: 48.509317, lng: 21.602216 },
    { lat: 48.509248, lng: 21.602383 },
    { lat: 48.509081, lng: 21.602458 },
    { lat: 48.508836, lng: 21.602704 },
    { lat: 48.508526, lng: 21.602978 },
    { lat: 48.50844, lng: 21.603025 },
    { lat: 48.508356, lng: 21.603079 },
    { lat: 48.508247, lng: 21.603147 },
    { lat: 48.507971, lng: 21.603236 },
    { lat: 48.507879, lng: 21.603299 },
    { lat: 48.507819, lng: 21.603374 },
    { lat: 48.507764, lng: 21.603466 },
    { lat: 48.507722, lng: 21.603605 },
    { lat: 48.507658, lng: 21.603749 },
    { lat: 48.507546, lng: 21.604027 },
    { lat: 48.507459, lng: 21.604232 },
    { lat: 48.507418, lng: 21.604367 },
    { lat: 48.5074, lng: 21.604478 },
    { lat: 48.507392, lng: 21.604631 },
    { lat: 48.507406, lng: 21.604927 },
    { lat: 48.507436, lng: 21.605111 },
    { lat: 48.507463, lng: 21.605343 },
    { lat: 48.507434, lng: 21.605557 },
    { lat: 48.507459, lng: 21.605629 },
    { lat: 48.507479, lng: 21.605695 },
    { lat: 48.507567, lng: 21.605681 },
    { lat: 48.507606, lng: 21.60572 },
    { lat: 48.507656, lng: 21.605787 },
    { lat: 48.507771, lng: 21.606066 },
    { lat: 48.507952, lng: 21.606453 },
    { lat: 48.508059, lng: 21.606604 },
    { lat: 48.508237, lng: 21.606854 },
    { lat: 48.508288, lng: 21.607052 },
    { lat: 48.508336, lng: 21.607089 },
    { lat: 48.508487, lng: 21.607228 },
    { lat: 48.508587, lng: 21.607379 },
    { lat: 48.508731, lng: 21.607597 },
    { lat: 48.508865, lng: 21.607817 },
    { lat: 48.509036, lng: 21.608015 },
    { lat: 48.509106, lng: 21.608062 },
    { lat: 48.509255, lng: 21.608201 },
    { lat: 48.509752, lng: 21.60855 },
    { lat: 48.509882, lng: 21.608716 },
    { lat: 48.509944, lng: 21.608919 },
    { lat: 48.510023, lng: 21.608993 },
    { lat: 48.510108, lng: 21.609038 },
    { lat: 48.510255, lng: 21.609221 },
    { lat: 48.510315, lng: 21.609257 },
    { lat: 48.510407, lng: 21.609426 },
    { lat: 48.510505, lng: 21.609441 },
    { lat: 48.510559, lng: 21.609502 },
    { lat: 48.510612, lng: 21.609612 },
    { lat: 48.51062, lng: 21.6097 },
    { lat: 48.510589, lng: 21.609737 },
    { lat: 48.510543, lng: 21.609963 },
    { lat: 48.510438, lng: 21.610144 },
    { lat: 48.510462, lng: 21.610221 },
    { lat: 48.510511, lng: 21.610264 },
    { lat: 48.51051, lng: 21.610446 },
    { lat: 48.510506, lng: 21.610482 },
    { lat: 48.510437, lng: 21.610499 },
    { lat: 48.510321, lng: 21.610499 },
    { lat: 48.510298, lng: 21.610546 },
    { lat: 48.51028, lng: 21.610691 },
    { lat: 48.51025, lng: 21.610824 },
    { lat: 48.510217, lng: 21.610932 },
    { lat: 48.510185, lng: 21.61103 },
    { lat: 48.5101636, lng: 21.611053 },
    { lat: 48.510093, lng: 21.611129 },
    { lat: 48.510084, lng: 21.611355 },
    { lat: 48.510084, lng: 21.611356 },
  ],
  Dvorianky: [
    { lat: 48.7108908, lng: 21.7140561 },
    { lat: 48.7109283, lng: 21.7142829 },
    { lat: 48.7108235, lng: 21.7145241 },
    { lat: 48.7103834, lng: 21.7150186 },
    { lat: 48.7101544, lng: 21.714831 },
    { lat: 48.7098985, lng: 21.7148977 },
    { lat: 48.7097938, lng: 21.7150185 },
    { lat: 48.7097154, lng: 21.7152424 },
    { lat: 48.7097548, lng: 21.7155324 },
    { lat: 48.7096942, lng: 21.7157068 },
    { lat: 48.7094813, lng: 21.7156533 },
    { lat: 48.7094414, lng: 21.7158442 },
    { lat: 48.7094919, lng: 21.7160121 },
    { lat: 48.7100485, lng: 21.7162048 },
    { lat: 48.71006, lng: 21.7164207 },
    { lat: 48.7099917, lng: 21.716728 },
    { lat: 48.709864, lng: 21.7169621 },
    { lat: 48.7096532, lng: 21.7169405 },
    { lat: 48.7091594, lng: 21.7164921 },
    { lat: 48.7089568, lng: 21.7166901 },
    { lat: 48.7090095, lng: 21.717151 },
    { lat: 48.7092811, lng: 21.7175789 },
    { lat: 48.7093204, lng: 21.7181205 },
    { lat: 48.7092762, lng: 21.7183159 },
    { lat: 48.7086574, lng: 21.7187401 },
    { lat: 48.7085679, lng: 21.7189625 },
    { lat: 48.7085744, lng: 21.7191688 },
    { lat: 48.7087537, lng: 21.719514 },
    { lat: 48.7089382, lng: 21.7196448 },
    { lat: 48.709124, lng: 21.7199422 },
    { lat: 48.7092079, lng: 21.7203011 },
    { lat: 48.709135, lng: 21.7204303 },
    { lat: 48.7090347, lng: 21.7205476 },
    { lat: 48.7086557, lng: 21.7206396 },
    { lat: 48.7078243, lng: 21.7223553 },
    { lat: 48.7074591, lng: 21.7227923 },
    { lat: 48.7066963, lng: 21.7231661 },
    { lat: 48.7060588, lng: 21.7229723 },
    { lat: 48.705814, lng: 21.7229969 },
    { lat: 48.7057121, lng: 21.7230904 },
    { lat: 48.7056267, lng: 21.7231858 },
    { lat: 48.7055285, lng: 21.7232972 },
    { lat: 48.7054198, lng: 21.7236024 },
    { lat: 48.7054547, lng: 21.7240224 },
    { lat: 48.7054248, lng: 21.724254 },
    { lat: 48.7055612, lng: 21.7248451 },
    { lat: 48.7056517, lng: 21.7255395 },
    { lat: 48.7056989, lng: 21.7262515 },
    { lat: 48.7056844, lng: 21.726801 },
    { lat: 48.7057365, lng: 21.7271105 },
    { lat: 48.7056988, lng: 21.7272924 },
    { lat: 48.7055248, lng: 21.7275566 },
    { lat: 48.7055354, lng: 21.7280853 },
    { lat: 48.7056074, lng: 21.7284571 },
    { lat: 48.7058071, lng: 21.7289425 },
    { lat: 48.706, lng: 21.7297938 },
    { lat: 48.7062018, lng: 21.7301016 },
    { lat: 48.7062239, lng: 21.7302602 },
    { lat: 48.7061685, lng: 21.7311723 },
    { lat: 48.7065143, lng: 21.7318565 },
    { lat: 48.706578, lng: 21.732188 },
    { lat: 48.706938, lng: 21.732522 },
    { lat: 48.707596, lng: 21.733524 },
    { lat: 48.707864, lng: 21.733805 },
    { lat: 48.708121, lng: 21.733913 },
    { lat: 48.708312, lng: 21.733748 },
    { lat: 48.708474, lng: 21.733459 },
    { lat: 48.708554, lng: 21.733275 },
    { lat: 48.708683, lng: 21.732976 },
    { lat: 48.708884, lng: 21.732721 },
    { lat: 48.709083, lng: 21.732624 },
    { lat: 48.709189, lng: 21.732814 },
    { lat: 48.709333, lng: 21.733561 },
    { lat: 48.709275, lng: 21.733657 },
    { lat: 48.70926, lng: 21.733882 },
    { lat: 48.709297, lng: 21.734307 },
    { lat: 48.709362, lng: 21.734545 },
    { lat: 48.709455, lng: 21.734879 },
    { lat: 48.709607, lng: 21.735214 },
    { lat: 48.709729, lng: 21.735396 },
    { lat: 48.710015, lng: 21.73559 },
    { lat: 48.710348, lng: 21.735601 },
    { lat: 48.710539, lng: 21.735334 },
    { lat: 48.710652, lng: 21.735251 },
    { lat: 48.711163, lng: 21.734367 },
    { lat: 48.711314, lng: 21.733848 },
    { lat: 48.711385, lng: 21.733766 },
    { lat: 48.711767, lng: 21.733682 },
    { lat: 48.711854, lng: 21.733684 },
    { lat: 48.711938, lng: 21.733611 },
    { lat: 48.712227, lng: 21.733615 },
    { lat: 48.712971, lng: 21.732796 },
    { lat: 48.713078, lng: 21.732576 },
    { lat: 48.713133, lng: 21.732221 },
    { lat: 48.71317, lng: 21.731765 },
    { lat: 48.713127, lng: 21.73158 },
    { lat: 48.712796, lng: 21.73123 },
    { lat: 48.712513, lng: 21.730922 },
    { lat: 48.712339, lng: 21.730685 },
    { lat: 48.712122, lng: 21.729994 },
    { lat: 48.712022, lng: 21.729245 },
    { lat: 48.712077, lng: 21.729067 },
    { lat: 48.712173, lng: 21.728947 },
    { lat: 48.712275, lng: 21.728844 },
    { lat: 48.712915, lng: 21.728509 },
    { lat: 48.713197, lng: 21.728446 },
    { lat: 48.713465, lng: 21.728605 },
    { lat: 48.713593, lng: 21.728688 },
    { lat: 48.713674, lng: 21.728755 },
    { lat: 48.713816, lng: 21.729137 },
    { lat: 48.714228, lng: 21.729588 },
    { lat: 48.714483, lng: 21.729791 },
    { lat: 48.71471, lng: 21.729842 },
    { lat: 48.714991, lng: 21.729779 },
    { lat: 48.715385, lng: 21.729415 },
    { lat: 48.715581, lng: 21.72899 },
    { lat: 48.715795, lng: 21.728658 },
    { lat: 48.716107, lng: 21.728509 },
    { lat: 48.71621, lng: 21.728462 },
    { lat: 48.716309, lng: 21.728445 },
    { lat: 48.717754, lng: 21.728624 },
    { lat: 48.718054, lng: 21.728376 },
    { lat: 48.718417, lng: 21.72824 },
    { lat: 48.718831, lng: 21.728177 },
    { lat: 48.719323, lng: 21.72803 },
    { lat: 48.719737, lng: 21.727956 },
    { lat: 48.720161, lng: 21.727918 },
    { lat: 48.720559, lng: 21.727978 },
    { lat: 48.720592, lng: 21.727826 },
    { lat: 48.720683, lng: 21.727615 },
    { lat: 48.72155, lng: 21.726363 },
    { lat: 48.722963, lng: 21.724281 },
    { lat: 48.723098, lng: 21.724074 },
    { lat: 48.723284, lng: 21.723791 },
    { lat: 48.723318, lng: 21.723842 },
    { lat: 48.723394, lng: 21.723825 },
    { lat: 48.723949, lng: 21.723698 },
    { lat: 48.724314, lng: 21.723746 },
    { lat: 48.724719, lng: 21.723844 },
    { lat: 48.725041, lng: 21.723814 },
    { lat: 48.725166, lng: 21.723824 },
    { lat: 48.725192, lng: 21.723838 },
    { lat: 48.725623, lng: 21.724231 },
    { lat: 48.726375, lng: 21.724586 },
    { lat: 48.726723, lng: 21.724643 },
    { lat: 48.726841, lng: 21.72466 },
    { lat: 48.72699, lng: 21.724396 },
    { lat: 48.727045, lng: 21.723398 },
    { lat: 48.727145, lng: 21.723073 },
    { lat: 48.727235, lng: 21.722773 },
    { lat: 48.727372, lng: 21.722308 },
    { lat: 48.727569, lng: 21.721999 },
    { lat: 48.727716, lng: 21.721769 },
    { lat: 48.727855, lng: 21.721495 },
    { lat: 48.728137, lng: 21.721308 },
    { lat: 48.72895, lng: 21.721793 },
    { lat: 48.729344, lng: 21.721825 },
    { lat: 48.729553, lng: 21.721842 },
    { lat: 48.730163, lng: 21.722087 },
    { lat: 48.730433, lng: 21.722196 },
    { lat: 48.7304, lng: 21.722664 },
    { lat: 48.730578, lng: 21.723381 },
    { lat: 48.731556, lng: 21.723064 },
    { lat: 48.731734, lng: 21.722887 },
    { lat: 48.732228, lng: 21.722881 },
    { lat: 48.732454, lng: 21.722861 },
    { lat: 48.732778, lng: 21.722724 },
    { lat: 48.733023, lng: 21.722484 },
    { lat: 48.733054, lng: 21.72235 },
    { lat: 48.733075, lng: 21.722257 },
    { lat: 48.733097, lng: 21.722159 },
    { lat: 48.732964, lng: 21.720726 },
    { lat: 48.732828, lng: 21.720422 },
    { lat: 48.732593, lng: 21.720452 },
    { lat: 48.731996, lng: 21.720797 },
    { lat: 48.731842, lng: 21.720742 },
    { lat: 48.731817, lng: 21.720691 },
    { lat: 48.731599, lng: 21.720342 },
    { lat: 48.73155, lng: 21.720173 },
    { lat: 48.731514, lng: 21.719816 },
    { lat: 48.731405, lng: 21.719014 },
    { lat: 48.731291, lng: 21.718173 },
    { lat: 48.731283, lng: 21.718114 },
    { lat: 48.731219, lng: 21.717698 },
    { lat: 48.731201, lng: 21.717579 },
    { lat: 48.731196, lng: 21.717157 },
    { lat: 48.731225, lng: 21.716909 },
    { lat: 48.731246, lng: 21.716711 },
    { lat: 48.731329, lng: 21.71651 },
    { lat: 48.731376, lng: 21.716501 },
    { lat: 48.73147, lng: 21.716484 },
    { lat: 48.731699, lng: 21.716395 },
    { lat: 48.731884, lng: 21.716248 },
    { lat: 48.732398, lng: 21.71572 },
    { lat: 48.733347, lng: 21.714767 },
    { lat: 48.7340471, lng: 21.7140489 },
    { lat: 48.7356914, lng: 21.7124439 },
    { lat: 48.7357762, lng: 21.713087 },
    { lat: 48.7360386, lng: 21.7137171 },
    { lat: 48.7361584, lng: 21.7138245 },
    { lat: 48.7362679, lng: 21.7137946 },
    { lat: 48.7363858, lng: 21.7136209 },
    { lat: 48.7369258, lng: 21.712328 },
    { lat: 48.737174, lng: 21.7119755 },
    { lat: 48.7374181, lng: 21.7119259 },
    { lat: 48.7375609, lng: 21.7119695 },
    { lat: 48.7379526, lng: 21.7122701 },
    { lat: 48.7382621, lng: 21.7123555 },
    { lat: 48.7384165, lng: 21.7123178 },
    { lat: 48.7387654, lng: 21.7124478 },
    { lat: 48.7389268, lng: 21.7125944 },
    { lat: 48.7394829, lng: 21.7133611 },
    { lat: 48.7401005, lng: 21.7144908 },
    { lat: 48.7403841, lng: 21.7146551 },
    { lat: 48.7405491, lng: 21.7140568 },
    { lat: 48.7408792, lng: 21.7123187 },
    { lat: 48.7412236, lng: 21.7120373 },
    { lat: 48.7415886, lng: 21.7114698 },
    { lat: 48.7419879, lng: 21.7110028 },
    { lat: 48.7422775, lng: 21.7108125 },
    { lat: 48.7428283, lng: 21.7107557 },
    { lat: 48.74446, lng: 21.7111835 },
    { lat: 48.7447042, lng: 21.7103065 },
    { lat: 48.7452664, lng: 21.7088344 },
    { lat: 48.7456732, lng: 21.7070654 },
    { lat: 48.745943, lng: 21.7044321 },
    { lat: 48.7459397, lng: 21.703515 },
    { lat: 48.7467761, lng: 21.7033261 },
    { lat: 48.7465684, lng: 21.6991772 },
    { lat: 48.7377402, lng: 21.7006444 },
    { lat: 48.7368819, lng: 21.6971649 },
    { lat: 48.7353375, lng: 21.6977163 },
    { lat: 48.7347665, lng: 21.6974023 },
    { lat: 48.7346051, lng: 21.6964304 },
    { lat: 48.733207, lng: 21.6975202 },
    { lat: 48.7318537, lng: 21.6983623 },
    { lat: 48.731947, lng: 21.6987333 },
    { lat: 48.7319292, lng: 21.6991564 },
    { lat: 48.7314756, lng: 21.6992696 },
    { lat: 48.7314419, lng: 21.6990272 },
    { lat: 48.7307073, lng: 21.6994033 },
    { lat: 48.7305779, lng: 21.6993622 },
    { lat: 48.7258351, lng: 21.7010462 },
    { lat: 48.7255288, lng: 21.7001176 },
    { lat: 48.7248528, lng: 21.7005008 },
    { lat: 48.7243976, lng: 21.6976094 },
    { lat: 48.7241871, lng: 21.6977321 },
    { lat: 48.7235373, lng: 21.6974188 },
    { lat: 48.7234101, lng: 21.6970828 },
    { lat: 48.7234428, lng: 21.6969148 },
    { lat: 48.7232139, lng: 21.6967761 },
    { lat: 48.7231424, lng: 21.696959 },
    { lat: 48.7231645, lng: 21.6971116 },
    { lat: 48.722921, lng: 21.6974281 },
    { lat: 48.7229392, lng: 21.6974712 },
    { lat: 48.7224832, lng: 21.6978347 },
    { lat: 48.722265, lng: 21.6978389 },
    { lat: 48.7222156, lng: 21.6979246 },
    { lat: 48.7222108, lng: 21.6982431 },
    { lat: 48.7220756, lng: 21.6984464 },
    { lat: 48.7219616, lng: 21.6983886 },
    { lat: 48.7216328, lng: 21.6987157 },
    { lat: 48.7216647, lng: 21.698935 },
    { lat: 48.721229, lng: 21.6991376 },
    { lat: 48.7210287, lng: 21.699295 },
    { lat: 48.7208405, lng: 21.6992871 },
    { lat: 48.7205147, lng: 21.6996343 },
    { lat: 48.7204208, lng: 21.6995795 },
    { lat: 48.7201414, lng: 21.6996827 },
    { lat: 48.720108, lng: 21.6997407 },
    { lat: 48.7201528, lng: 21.699817 },
    { lat: 48.7199831, lng: 21.7001151 },
    { lat: 48.7198574, lng: 21.7000868 },
    { lat: 48.7196652, lng: 21.7003403 },
    { lat: 48.7191793, lng: 21.7007216 },
    { lat: 48.7192001, lng: 21.7008301 },
    { lat: 48.7185105, lng: 21.7017015 },
    { lat: 48.7180967, lng: 21.7015866 },
    { lat: 48.7179641, lng: 21.7018004 },
    { lat: 48.7176615, lng: 21.7019698 },
    { lat: 48.7174218, lng: 21.7023399 },
    { lat: 48.7171177, lng: 21.7025407 },
    { lat: 48.7168905, lng: 21.7027881 },
    { lat: 48.716904, lng: 21.7028336 },
    { lat: 48.7173404, lng: 21.7042803 },
    { lat: 48.7175727, lng: 21.7042161 },
    { lat: 48.717407, lng: 21.7043303 },
    { lat: 48.7173736, lng: 21.7044307 },
    { lat: 48.7173545, lng: 21.7045951 },
    { lat: 48.717475, lng: 21.7048481 },
    { lat: 48.7174626, lng: 21.7049301 },
    { lat: 48.7173972, lng: 21.7049701 },
    { lat: 48.7172932, lng: 21.7049103 },
    { lat: 48.7171234, lng: 21.7047063 },
    { lat: 48.7171123, lng: 21.7044622 },
    { lat: 48.7170447, lng: 21.7044553 },
    { lat: 48.7169646, lng: 21.7047029 },
    { lat: 48.7169731, lng: 21.7048053 },
    { lat: 48.7171374, lng: 21.7050132 },
    { lat: 48.7171979, lng: 21.7052988 },
    { lat: 48.717105, lng: 21.7053598 },
    { lat: 48.7170107, lng: 21.7055715 },
    { lat: 48.7169975, lng: 21.7059183 },
    { lat: 48.7166672, lng: 21.7058913 },
    { lat: 48.7165732, lng: 21.706201 },
    { lat: 48.7162456, lng: 21.7068144 },
    { lat: 48.71637, lng: 21.7067184 },
    { lat: 48.71631, lng: 21.7068685 },
    { lat: 48.7163611, lng: 21.7070164 },
    { lat: 48.7161743, lng: 21.7071021 },
    { lat: 48.7159659, lng: 21.707381 },
    { lat: 48.7159601, lng: 21.7077876 },
    { lat: 48.7158877, lng: 21.7078982 },
    { lat: 48.7157234, lng: 21.7078139 },
    { lat: 48.7155923, lng: 21.7082478 },
    { lat: 48.7155131, lng: 21.708362 },
    { lat: 48.7153877, lng: 21.7083031 },
    { lat: 48.7151945, lng: 21.7079327 },
    { lat: 48.7149573, lng: 21.7076958 },
    { lat: 48.7148273, lng: 21.7077512 },
    { lat: 48.7146666, lng: 21.7082624 },
    { lat: 48.714717, lng: 21.708461 },
    { lat: 48.715194, lng: 21.7086279 },
    { lat: 48.7153009, lng: 21.7087549 },
    { lat: 48.7152941, lng: 21.7089138 },
    { lat: 48.7151094, lng: 21.7091992 },
    { lat: 48.7147231, lng: 21.7091054 },
    { lat: 48.7145328, lng: 21.7092187 },
    { lat: 48.7144095, lng: 21.7091151 },
    { lat: 48.7142852, lng: 21.7088421 },
    { lat: 48.7139974, lng: 21.7090604 },
    { lat: 48.7139842, lng: 21.7091934 },
    { lat: 48.7140988, lng: 21.7096031 },
    { lat: 48.7142777, lng: 21.7097763 },
    { lat: 48.7143241, lng: 21.7099081 },
    { lat: 48.7146144, lng: 21.7094894 },
    { lat: 48.7148467, lng: 21.7097199 },
    { lat: 48.715003, lng: 21.7099746 },
    { lat: 48.7149745, lng: 21.7100793 },
    { lat: 48.7146181, lng: 21.7104675 },
    { lat: 48.7144902, lng: 21.7104811 },
    { lat: 48.714482, lng: 21.7106302 },
    { lat: 48.7147451, lng: 21.7108741 },
    { lat: 48.7147064, lng: 21.7110293 },
    { lat: 48.7145779, lng: 21.7110668 },
    { lat: 48.7143784, lng: 21.7109686 },
    { lat: 48.7140739, lng: 21.7106902 },
    { lat: 48.7140061, lng: 21.7107222 },
    { lat: 48.7139609, lng: 21.7108814 },
    { lat: 48.7140182, lng: 21.7111707 },
    { lat: 48.7139058, lng: 21.711417 },
    { lat: 48.7137798, lng: 21.7113795 },
    { lat: 48.7136367, lng: 21.7112042 },
    { lat: 48.7135114, lng: 21.7112336 },
    { lat: 48.7134971, lng: 21.7114137 },
    { lat: 48.7135687, lng: 21.7116017 },
    { lat: 48.7139295, lng: 21.7119286 },
    { lat: 48.7139174, lng: 21.7120576 },
    { lat: 48.7136068, lng: 21.7123945 },
    { lat: 48.7133745, lng: 21.7123312 },
    { lat: 48.7132746, lng: 21.7118788 },
    { lat: 48.7131499, lng: 21.7119673 },
    { lat: 48.7131253, lng: 21.7123119 },
    { lat: 48.7132162, lng: 21.7124652 },
    { lat: 48.7132094, lng: 21.7130542 },
    { lat: 48.7131741, lng: 21.7131194 },
    { lat: 48.7127025, lng: 21.712862 },
    { lat: 48.712516, lng: 21.7125354 },
    { lat: 48.712364, lng: 21.7124888 },
    { lat: 48.7122673, lng: 21.712618 },
    { lat: 48.7120808, lng: 21.7130887 },
    { lat: 48.7121588, lng: 21.7132257 },
    { lat: 48.712343, lng: 21.7133362 },
    { lat: 48.7123609, lng: 21.7134235 },
    { lat: 48.7120745, lng: 21.7137735 },
    { lat: 48.7121567, lng: 21.7139369 },
    { lat: 48.7123809, lng: 21.7141647 },
    { lat: 48.7123101, lng: 21.7143718 },
    { lat: 48.7122212, lng: 21.7144581 },
    { lat: 48.7121393, lng: 21.7144193 },
    { lat: 48.7120413, lng: 21.714544 },
    { lat: 48.7120133, lng: 21.7146686 },
    { lat: 48.7120693, lng: 21.7149128 },
    { lat: 48.7120102, lng: 21.7149766 },
    { lat: 48.7119412, lng: 21.7149623 },
    { lat: 48.7118759, lng: 21.7147939 },
    { lat: 48.7118449, lng: 21.7145628 },
    { lat: 48.7118875, lng: 21.7144284 },
    { lat: 48.7118363, lng: 21.7143239 },
    { lat: 48.7117787, lng: 21.714362 },
    { lat: 48.7117308, lng: 21.7141263 },
    { lat: 48.7117924, lng: 21.7137869 },
    { lat: 48.7117761, lng: 21.7135931 },
    { lat: 48.7115535, lng: 21.7130592 },
    { lat: 48.7113976, lng: 21.7129081 },
    { lat: 48.7109217, lng: 21.7131198 },
    { lat: 48.7106731, lng: 21.7135804 },
    { lat: 48.7106882, lng: 21.7137188 },
    { lat: 48.7108908, lng: 21.7140561 },
  ],
  Strážne: [
    { lat: 48.368778, lng: 21.886745 },
    { lat: 48.3691256, lng: 21.8865469 },
    { lat: 48.3709806, lng: 21.8857951 },
    { lat: 48.3728566, lng: 21.8844925 },
    { lat: 48.3731541, lng: 21.8841587 },
    { lat: 48.3731243, lng: 21.8839169 },
    { lat: 48.3736517, lng: 21.8833366 },
    { lat: 48.3743994, lng: 21.8823581 },
    { lat: 48.3766454, lng: 21.8796555 },
    { lat: 48.3768204, lng: 21.8797692 },
    { lat: 48.3780662, lng: 21.8795577 },
    { lat: 48.3789518, lng: 21.8798766 },
    { lat: 48.3793299, lng: 21.8797921 },
    { lat: 48.3798598, lng: 21.8794826 },
    { lat: 48.3805152, lng: 21.8792468 },
    { lat: 48.3811988, lng: 21.8794875 },
    { lat: 48.3832715, lng: 21.8788908 },
    { lat: 48.3837275, lng: 21.878898 },
    { lat: 48.3847585, lng: 21.8785258 },
    { lat: 48.3850735, lng: 21.8783069 },
    { lat: 48.3859722, lng: 21.8778567 },
    { lat: 48.3862274, lng: 21.8771882 },
    { lat: 48.3868192, lng: 21.8770643 },
    { lat: 48.3868844, lng: 21.877847 },
    { lat: 48.3873522, lng: 21.8780153 },
    { lat: 48.3873598, lng: 21.8781292 },
    { lat: 48.3880931, lng: 21.8781685 },
    { lat: 48.3880614, lng: 21.8784677 },
    { lat: 48.3889068, lng: 21.8784828 },
    { lat: 48.3893168, lng: 21.8782357 },
    { lat: 48.3894012, lng: 21.8772358 },
    { lat: 48.3927618, lng: 21.8753055 },
    { lat: 48.3929297, lng: 21.8736104 },
    { lat: 48.3925446, lng: 21.8726819 },
    { lat: 48.392352, lng: 21.8718859 },
    { lat: 48.3920451, lng: 21.8711558 },
    { lat: 48.3919769, lng: 21.8707303 },
    { lat: 48.3914932, lng: 21.8695159 },
    { lat: 48.3911018, lng: 21.867859 },
    { lat: 48.3913213, lng: 21.8671655 },
    { lat: 48.3925491, lng: 21.8665218 },
    { lat: 48.3925414, lng: 21.866353 },
    { lat: 48.3960176, lng: 21.8664309 },
    { lat: 48.3962042, lng: 21.8665085 },
    { lat: 48.3964967, lng: 21.8670293 },
    { lat: 48.3966193, lng: 21.8672477 },
    { lat: 48.3969015, lng: 21.8674196 },
    { lat: 48.4014465, lng: 21.8633903 },
    { lat: 48.4031138, lng: 21.8617378 },
    { lat: 48.4046052, lng: 21.8607994 },
    { lat: 48.4047331, lng: 21.8607031 },
    { lat: 48.4047099, lng: 21.8601977 },
    { lat: 48.4046719, lng: 21.8593749 },
    { lat: 48.4046487, lng: 21.85887 },
    { lat: 48.4042979, lng: 21.8582665 },
    { lat: 48.4041274, lng: 21.8575906 },
    { lat: 48.4042495, lng: 21.8566133 },
    { lat: 48.4045512, lng: 21.8558816 },
    { lat: 48.404595, lng: 21.8554074 },
    { lat: 48.4043056, lng: 21.8546113 },
    { lat: 48.4040601, lng: 21.8536052 },
    { lat: 48.403788, lng: 21.8530864 },
    { lat: 48.4025733, lng: 21.8524907 },
    { lat: 48.402427, lng: 21.852188 },
    { lat: 48.4024366, lng: 21.8513001 },
    { lat: 48.4028085, lng: 21.8495852 },
    { lat: 48.4030126, lng: 21.8477856 },
    { lat: 48.4029258, lng: 21.8466924 },
    { lat: 48.4029244, lng: 21.8459935 },
    { lat: 48.4027902, lng: 21.8453743 },
    { lat: 48.4016881, lng: 21.8436179 },
    { lat: 48.4010958, lng: 21.8430355 },
    { lat: 48.3991758, lng: 21.842663 },
    { lat: 48.3989784, lng: 21.8427344 },
    { lat: 48.3988028, lng: 21.8428998 },
    { lat: 48.3986672, lng: 21.843568 },
    { lat: 48.3983852, lng: 21.8437566 },
    { lat: 48.3980391, lng: 21.8435121 },
    { lat: 48.3978103, lng: 21.8431566 },
    { lat: 48.3970526, lng: 21.842358 },
    { lat: 48.3967153, lng: 21.8421077 },
    { lat: 48.3957512, lng: 21.841807 },
    { lat: 48.395391, lng: 21.8414097 },
    { lat: 48.3952514, lng: 21.8410635 },
    { lat: 48.3948675, lng: 21.8396643 },
    { lat: 48.3948981, lng: 21.8395446 },
    { lat: 48.3940194, lng: 21.8381203 },
    { lat: 48.3933234, lng: 21.8362817 },
    { lat: 48.3931541, lng: 21.8360828 },
    { lat: 48.3925661, lng: 21.8359733 },
    { lat: 48.3921055, lng: 21.835652 },
    { lat: 48.3913083, lng: 21.8337749 },
    { lat: 48.3911535, lng: 21.8337089 },
    { lat: 48.3906685, lng: 21.8337863 },
    { lat: 48.3899076, lng: 21.8330891 },
    { lat: 48.388282, lng: 21.8325723 },
    { lat: 48.3882896, lng: 21.8324299 },
    { lat: 48.3880844, lng: 21.8323729 },
    { lat: 48.3886742, lng: 21.8316452 },
    { lat: 48.3895839, lng: 21.8308111 },
    { lat: 48.3901939, lng: 21.8300457 },
    { lat: 48.3903041, lng: 21.829745 },
    { lat: 48.3902519, lng: 21.8289902 },
    { lat: 48.3902167, lng: 21.8288054 },
    { lat: 48.3896933, lng: 21.8284564 },
    { lat: 48.3883639, lng: 21.8272203 },
    { lat: 48.3880373, lng: 21.8269943 },
    { lat: 48.3866839, lng: 21.8263937 },
    { lat: 48.3855537, lng: 21.8268036 },
    { lat: 48.3841093, lng: 21.8270225 },
    { lat: 48.3841402, lng: 21.8265726 },
    { lat: 48.3830711, lng: 21.8261794 },
    { lat: 48.3827059, lng: 21.826151 },
    { lat: 48.3820629, lng: 21.8263471 },
    { lat: 48.3801637, lng: 21.8265602 },
    { lat: 48.3796929, lng: 21.8265787 },
    { lat: 48.3788755, lng: 21.8262199 },
    { lat: 48.3785761, lng: 21.8262089 },
    { lat: 48.3781601, lng: 21.8263052 },
    { lat: 48.3772653, lng: 21.8266832 },
    { lat: 48.3761814, lng: 21.8265772 },
    { lat: 48.3762276, lng: 21.8261979 },
    { lat: 48.3765728, lng: 21.8254185 },
    { lat: 48.3766638, lng: 21.8249746 },
    { lat: 48.3767046, lng: 21.8242559 },
    { lat: 48.3771639, lng: 21.8240586 },
    { lat: 48.377123, lng: 21.8233968 },
    { lat: 48.3755179, lng: 21.8237562 },
    { lat: 48.3752251, lng: 21.8238725 },
    { lat: 48.3743317, lng: 21.8245193 },
    { lat: 48.3740762, lng: 21.8236943 },
    { lat: 48.3739244, lng: 21.823658 },
    { lat: 48.3734629, lng: 21.823734 },
    { lat: 48.3729492, lng: 21.8240067 },
    { lat: 48.371552, lng: 21.8244022 },
    { lat: 48.3708882, lng: 21.8246565 },
    { lat: 48.3702347, lng: 21.8246671 },
    { lat: 48.3699846, lng: 21.8247409 },
    { lat: 48.3696065, lng: 21.8251694 },
    { lat: 48.3690052, lng: 21.8254518 },
    { lat: 48.3688523, lng: 21.825458 },
    { lat: 48.3684519, lng: 21.8254649 },
    { lat: 48.3679682, lng: 21.8247384 },
    { lat: 48.3678517, lng: 21.8243659 },
    { lat: 48.3678343, lng: 21.8238744 },
    { lat: 48.367069, lng: 21.8239549 },
    { lat: 48.3654903, lng: 21.8237278 },
    { lat: 48.3649761, lng: 21.8247997 },
    { lat: 48.3642333, lng: 21.8250926 },
    { lat: 48.363502, lng: 21.8256533 },
    { lat: 48.3631005, lng: 21.8257898 },
    { lat: 48.3626991, lng: 21.8257575 },
    { lat: 48.3623618, lng: 21.8255092 },
    { lat: 48.3618575, lng: 21.8249859 },
    { lat: 48.3614361, lng: 21.8247943 },
    { lat: 48.3604674, lng: 21.824614 },
    { lat: 48.3583423, lng: 21.8237249 },
    { lat: 48.3580452, lng: 21.8236791 },
    { lat: 48.3572212, lng: 21.8237429 },
    { lat: 48.3566141, lng: 21.8240214 },
    { lat: 48.3560465, lng: 21.824388 },
    { lat: 48.3559426, lng: 21.8251455 },
    { lat: 48.3558945, lng: 21.8264459 },
    { lat: 48.3561705, lng: 21.8276243 },
    { lat: 48.3567133, lng: 21.8291043 },
    { lat: 48.3568469, lng: 21.829696 },
    { lat: 48.3568666, lng: 21.8304059 },
    { lat: 48.3569843, lng: 21.8317726 },
    { lat: 48.3568626, lng: 21.8334863 },
    { lat: 48.3566318, lng: 21.8350765 },
    { lat: 48.3566206, lng: 21.8353789 },
    { lat: 48.356601, lng: 21.836133 },
    { lat: 48.356684, lng: 21.83613 },
    { lat: 48.356967, lng: 21.83614 },
    { lat: 48.357505, lng: 21.836087 },
    { lat: 48.357752, lng: 21.836067 },
    { lat: 48.358012, lng: 21.835987 },
    { lat: 48.358388, lng: 21.835923 },
    { lat: 48.358756, lng: 21.835782 },
    { lat: 48.35927, lng: 21.835642 },
    { lat: 48.359322, lng: 21.835618 },
    { lat: 48.359625, lng: 21.835503 },
    { lat: 48.360203, lng: 21.835226 },
    { lat: 48.360624, lng: 21.835166 },
    { lat: 48.361062, lng: 21.835114 },
    { lat: 48.36124, lng: 21.835148 },
    { lat: 48.361288, lng: 21.83516 },
    { lat: 48.36156, lng: 21.835251 },
    { lat: 48.361835, lng: 21.835422 },
    { lat: 48.36192, lng: 21.835438 },
    { lat: 48.362237, lng: 21.835666 },
    { lat: 48.362414, lng: 21.835817 },
    { lat: 48.362633, lng: 21.836022 },
    { lat: 48.362839, lng: 21.836215 },
    { lat: 48.362911, lng: 21.83632 },
    { lat: 48.362987, lng: 21.836475 },
    { lat: 48.363078, lng: 21.836749 },
    { lat: 48.363156, lng: 21.836964 },
    { lat: 48.363253, lng: 21.837213 },
    { lat: 48.36341, lng: 21.837875 },
    { lat: 48.36351, lng: 21.838228 },
    { lat: 48.363597, lng: 21.838663 },
    { lat: 48.36369, lng: 21.839197 },
    { lat: 48.363749, lng: 21.839599 },
    { lat: 48.363733, lng: 21.839895 },
    { lat: 48.363764, lng: 21.840448 },
    { lat: 48.363817, lng: 21.841054 },
    { lat: 48.363842, lng: 21.841305 },
    { lat: 48.36388, lng: 21.841908 },
    { lat: 48.363871, lng: 21.842086 },
    { lat: 48.363931, lng: 21.842771 },
    { lat: 48.363969, lng: 21.843496 },
    { lat: 48.363973, lng: 21.844204 },
    { lat: 48.363897, lng: 21.845251 },
    { lat: 48.363887, lng: 21.845637 },
    { lat: 48.36405, lng: 21.846238 },
    { lat: 48.364235, lng: 21.846356 },
    { lat: 48.364375, lng: 21.846384 },
    { lat: 48.364576, lng: 21.846449 },
    { lat: 48.364714, lng: 21.847192 },
    { lat: 48.364653, lng: 21.847853 },
    { lat: 48.364685, lng: 21.848216 },
    { lat: 48.365385, lng: 21.851605 },
    { lat: 48.365404, lng: 21.851668 },
    { lat: 48.36553, lng: 21.851958 },
    { lat: 48.365778, lng: 21.852353 },
    { lat: 48.365871, lng: 21.852501 },
    { lat: 48.366044, lng: 21.852811 },
    { lat: 48.366194, lng: 21.85313 },
    { lat: 48.366228, lng: 21.853259 },
    { lat: 48.366266, lng: 21.853483 },
    { lat: 48.36628, lng: 21.853627 },
    { lat: 48.366292, lng: 21.853843 },
    { lat: 48.366268, lng: 21.854289 },
    { lat: 48.366172, lng: 21.854749 },
    { lat: 48.366068, lng: 21.855087 },
    { lat: 48.365842, lng: 21.855558 },
    { lat: 48.36542, lng: 21.85636 },
    { lat: 48.365031, lng: 21.857045 },
    { lat: 48.364896, lng: 21.857268 },
    { lat: 48.364548, lng: 21.857759 },
    { lat: 48.364411, lng: 21.857942 },
    { lat: 48.364145, lng: 21.858239 },
    { lat: 48.363856, lng: 21.858495 },
    { lat: 48.363435, lng: 21.858754 },
    { lat: 48.363049, lng: 21.858926 },
    { lat: 48.362444, lng: 21.8592 },
    { lat: 48.361985, lng: 21.859351 },
    { lat: 48.361533, lng: 21.859466 },
    { lat: 48.360423, lng: 21.859718 },
    { lat: 48.360097, lng: 21.859765 },
    { lat: 48.359539, lng: 21.859739 },
    { lat: 48.358031, lng: 21.859826 },
    { lat: 48.357619, lng: 21.8599 },
    { lat: 48.357417, lng: 21.859982 },
    { lat: 48.357239, lng: 21.860079 },
    { lat: 48.357012, lng: 21.860268 },
    { lat: 48.356832, lng: 21.860416 },
    { lat: 48.356565, lng: 21.860619 },
    { lat: 48.356426, lng: 21.860739 },
    { lat: 48.356275, lng: 21.860879 },
    { lat: 48.355925, lng: 21.861156 },
    { lat: 48.355807, lng: 21.861306 },
    { lat: 48.355706, lng: 21.861427 },
    { lat: 48.355502, lng: 21.861756 },
    { lat: 48.355354, lng: 21.862223 },
    { lat: 48.355235, lng: 21.862799 },
    { lat: 48.355208, lng: 21.862968 },
    { lat: 48.355423, lng: 21.864071 },
    { lat: 48.355524, lng: 21.86462 },
    { lat: 48.355614, lng: 21.864971 },
    { lat: 48.355641, lng: 21.865048 },
    { lat: 48.355746, lng: 21.86525 },
    { lat: 48.355798, lng: 21.865344 },
    { lat: 48.355895, lng: 21.865427 },
    { lat: 48.356059, lng: 21.865516 },
    { lat: 48.35625, lng: 21.865563 },
    { lat: 48.356421, lng: 21.865562 },
    { lat: 48.356563, lng: 21.865528 },
    { lat: 48.356678, lng: 21.865453 },
    { lat: 48.356842, lng: 21.865368 },
    { lat: 48.357071, lng: 21.865159 },
    { lat: 48.357385, lng: 21.864854 },
    { lat: 48.358261, lng: 21.864089 },
    { lat: 48.358416, lng: 21.863968 },
    { lat: 48.358871, lng: 21.863698 },
    { lat: 48.359125, lng: 21.863635 },
    { lat: 48.359218, lng: 21.863617 },
    { lat: 48.35931, lng: 21.863615 },
    { lat: 48.359425, lng: 21.863633 },
    { lat: 48.359578, lng: 21.863688 },
    { lat: 48.359684, lng: 21.863763 },
    { lat: 48.359797, lng: 21.863867 },
    { lat: 48.359935, lng: 21.864052 },
    { lat: 48.360005, lng: 21.864201 },
    { lat: 48.360116, lng: 21.864544 },
    { lat: 48.360189, lng: 21.864962 },
    { lat: 48.3602, lng: 21.865112 },
    { lat: 48.360186, lng: 21.865481 },
    { lat: 48.360127, lng: 21.865875 },
    { lat: 48.360017, lng: 21.866392 },
    { lat: 48.359878, lng: 21.866961 },
    { lat: 48.359799, lng: 21.867198 },
    { lat: 48.359647, lng: 21.867522 },
    { lat: 48.359525, lng: 21.867781 },
    { lat: 48.359321, lng: 21.868066 },
    { lat: 48.359178, lng: 21.868297 },
    { lat: 48.358994, lng: 21.868522 },
    { lat: 48.358631, lng: 21.868928 },
    { lat: 48.358351, lng: 21.86921 },
    { lat: 48.358099, lng: 21.869508 },
    { lat: 48.3579915, lng: 21.869638 },
    { lat: 48.357692, lng: 21.87 },
    { lat: 48.357472, lng: 21.87026 },
    { lat: 48.357237, lng: 21.870537 },
    { lat: 48.357123, lng: 21.87069 },
    { lat: 48.356964, lng: 21.870934 },
    { lat: 48.35668, lng: 21.871436 },
    { lat: 48.356454, lng: 21.871815 },
    { lat: 48.3562, lng: 21.872263 },
    { lat: 48.356152, lng: 21.872384 },
    { lat: 48.356006, lng: 21.872855 },
    { lat: 48.355844, lng: 21.873432 },
    { lat: 48.355791, lng: 21.873816 },
    { lat: 48.355735, lng: 21.874059 },
    { lat: 48.355703, lng: 21.874544 },
    { lat: 48.355754, lng: 21.875046 },
    { lat: 48.355792, lng: 21.875181 },
    { lat: 48.355847, lng: 21.87535 },
    { lat: 48.355927, lng: 21.875512 },
    { lat: 48.356149, lng: 21.875784 },
    { lat: 48.356293, lng: 21.875905 },
    { lat: 48.356494, lng: 21.875925 },
    { lat: 48.356724, lng: 21.875853 },
    { lat: 48.356879, lng: 21.875767 },
    { lat: 48.357111, lng: 21.875587 },
    { lat: 48.357296, lng: 21.875413 },
    { lat: 48.357739, lng: 21.87492 },
    { lat: 48.357976, lng: 21.87465 },
    { lat: 48.35836, lng: 21.874346 },
    { lat: 48.358481, lng: 21.874278 },
    { lat: 48.358821, lng: 21.87413 },
    { lat: 48.3589, lng: 21.874105 },
    { lat: 48.358994, lng: 21.874109 },
    { lat: 48.359182, lng: 21.874053 },
    { lat: 48.359348, lng: 21.87403 },
    { lat: 48.359495, lng: 21.874054 },
    { lat: 48.359748, lng: 21.874111 },
    { lat: 48.359979, lng: 21.874169 },
    { lat: 48.360212, lng: 21.874284 },
    { lat: 48.360531, lng: 21.87453 },
    { lat: 48.360607, lng: 21.874603 },
    { lat: 48.360713, lng: 21.874724 },
    { lat: 48.361065, lng: 21.875256 },
    { lat: 48.361195, lng: 21.875546 },
    { lat: 48.361328, lng: 21.875912 },
    { lat: 48.361613, lng: 21.876794 },
    { lat: 48.361916, lng: 21.877939 },
    { lat: 48.362054, lng: 21.878543 },
    { lat: 48.362151, lng: 21.878961 },
    { lat: 48.362331, lng: 21.879578 },
    { lat: 48.362454, lng: 21.880066 },
    { lat: 48.362568, lng: 21.880657 },
    { lat: 48.362602, lng: 21.880914 },
    { lat: 48.362615, lng: 21.881172 },
    { lat: 48.362586, lng: 21.881419 },
    { lat: 48.362538, lng: 21.881637 },
    { lat: 48.362469, lng: 21.881835 },
    { lat: 48.362331, lng: 21.882104 },
    { lat: 48.362218, lng: 21.882252 },
    { lat: 48.362017, lng: 21.882416 },
    { lat: 48.361847, lng: 21.882528 },
    { lat: 48.361583, lng: 21.88264 },
    { lat: 48.36139, lng: 21.882676 },
    { lat: 48.361185, lng: 21.882694 },
    { lat: 48.360909, lng: 21.882689 },
    { lat: 48.360621, lng: 21.882605 },
    { lat: 48.360024, lng: 21.882307 },
    { lat: 48.359792, lng: 21.882142 },
    { lat: 48.359128, lng: 21.881791 },
    { lat: 48.3590976, lng: 21.8817807 },
    { lat: 48.358772, lng: 21.88167 },
    { lat: 48.358433, lng: 21.881568 },
    { lat: 48.358214, lng: 21.881539 },
    { lat: 48.357765, lng: 21.881565 },
    { lat: 48.357579, lng: 21.881598 },
    { lat: 48.357134, lng: 21.881775 },
    { lat: 48.356699, lng: 21.882001 },
    { lat: 48.356555, lng: 21.882127 },
    { lat: 48.356437, lng: 21.882262 },
    { lat: 48.356338, lng: 21.882386 },
    { lat: 48.35615, lng: 21.88273 },
    { lat: 48.356096, lng: 21.882878 },
    { lat: 48.356038, lng: 21.88314 },
    { lat: 48.356009, lng: 21.883418 },
    { lat: 48.355994, lng: 21.883575 },
    { lat: 48.356016, lng: 21.883897 },
    { lat: 48.356047, lng: 21.884298 },
    { lat: 48.356152, lng: 21.884576 },
    { lat: 48.356346, lng: 21.885065 },
    { lat: 48.35663, lng: 21.885643 },
    { lat: 48.357166, lng: 21.886695 },
    { lat: 48.357307, lng: 21.886943 },
    { lat: 48.357452, lng: 21.887161 },
    { lat: 48.357591, lng: 21.887343 },
    { lat: 48.357951, lng: 21.887744 },
    { lat: 48.358181, lng: 21.887969 },
    { lat: 48.358371, lng: 21.888103 },
    { lat: 48.358548, lng: 21.8882 },
    { lat: 48.358757, lng: 21.88825 },
    { lat: 48.359198, lng: 21.888261 },
    { lat: 48.359462, lng: 21.888194 },
    { lat: 48.35969, lng: 21.888085 },
    { lat: 48.360032, lng: 21.887818 },
    { lat: 48.360376, lng: 21.88749 },
    { lat: 48.360632, lng: 21.886981 },
    { lat: 48.360837, lng: 21.886571 },
    { lat: 48.361018, lng: 21.886115 },
    { lat: 48.361388, lng: 21.885203 },
    { lat: 48.361494, lng: 21.885021 },
    { lat: 48.361724, lng: 21.884596 },
    { lat: 48.361874, lng: 21.884404 },
    { lat: 48.362055, lng: 21.88419 },
    { lat: 48.362502, lng: 21.883694 },
    { lat: 48.362725, lng: 21.883475 },
    { lat: 48.362958, lng: 21.883262 },
    { lat: 48.363071, lng: 21.88318 },
    { lat: 48.363243, lng: 21.883107 },
    { lat: 48.363843, lng: 21.883001 },
    { lat: 48.364351, lng: 21.883052 },
    { lat: 48.3645609, lng: 21.8830987 },
    { lat: 48.364634, lng: 21.883115 },
    { lat: 48.364693, lng: 21.883152 },
    { lat: 48.364756, lng: 21.883187 },
    { lat: 48.365447, lng: 21.883356 },
    { lat: 48.365726, lng: 21.883451 },
    { lat: 48.366143, lng: 21.883753 },
    { lat: 48.366423, lng: 21.883922 },
    { lat: 48.366642, lng: 21.884058 },
    { lat: 48.3667998, lng: 21.8841872 },
    { lat: 48.367001, lng: 21.884352 },
    { lat: 48.367539, lng: 21.884843 },
    { lat: 48.367834, lng: 21.885242 },
    { lat: 48.368189, lng: 21.885618 },
    { lat: 48.368404, lng: 21.885957 },
    { lat: 48.368657, lng: 21.886523 },
    { lat: 48.368778, lng: 21.886745 },
  ],
  VeľkáTŕňa: [
    { lat: 48.4870567, lng: 21.6584261 },
    { lat: 48.4869688, lng: 21.6582996 },
    { lat: 48.4868704, lng: 21.6577925 },
    { lat: 48.4866946, lng: 21.6576588 },
    { lat: 48.4866598, lng: 21.6574995 },
    { lat: 48.4861526, lng: 21.6574287 },
    { lat: 48.4853839, lng: 21.6569332 },
    { lat: 48.4851392, lng: 21.6562583 },
    { lat: 48.4849703, lng: 21.6560905 },
    { lat: 48.4848861, lng: 21.6556031 },
    { lat: 48.4849025, lng: 21.6553875 },
    { lat: 48.4849318, lng: 21.6550632 },
    { lat: 48.4850914, lng: 21.6545688 },
    { lat: 48.4849838, lng: 21.6534795 },
    { lat: 48.4850131, lng: 21.65197 },
    { lat: 48.4849695, lng: 21.6518159 },
    { lat: 48.4849762, lng: 21.6514875 },
    { lat: 48.4849745, lng: 21.6514691 },
    { lat: 48.4840347, lng: 21.6517084 },
    { lat: 48.4824279, lng: 21.6519896 },
    { lat: 48.482326, lng: 21.649889 },
    { lat: 48.481188, lng: 21.6501605 },
    { lat: 48.4799296, lng: 21.6505924 },
    { lat: 48.4801687, lng: 21.6517442 },
    { lat: 48.4785492, lng: 21.6519369 },
    { lat: 48.4781688, lng: 21.6518149 },
    { lat: 48.4779367, lng: 21.6519662 },
    { lat: 48.4782002, lng: 21.6538471 },
    { lat: 48.4795106, lng: 21.6613052 },
    { lat: 48.4787583, lng: 21.6616088 },
    { lat: 48.4759877, lng: 21.6632271 },
    { lat: 48.4757034, lng: 21.6634462 },
    { lat: 48.4715848, lng: 21.6659229 },
    { lat: 48.4714596, lng: 21.6657069 },
    { lat: 48.4713065, lng: 21.6657838 },
    { lat: 48.4713872, lng: 21.6661433 },
    { lat: 48.4705352, lng: 21.6666292 },
    { lat: 48.4701417, lng: 21.6652482 },
    { lat: 48.4695471, lng: 21.662611 },
    { lat: 48.4684188, lng: 21.6625394 },
    { lat: 48.4675839, lng: 21.6626169 },
    { lat: 48.4673294, lng: 21.6619534 },
    { lat: 48.4670855, lng: 21.6606568 },
    { lat: 48.4663661, lng: 21.6604842 },
    { lat: 48.4656404, lng: 21.6605635 },
    { lat: 48.4642535, lng: 21.6614004 },
    { lat: 48.4632865, lng: 21.6624416 },
    { lat: 48.4622231, lng: 21.6623567 },
    { lat: 48.4619846, lng: 21.6622755 },
    { lat: 48.4621554, lng: 21.6618167 },
    { lat: 48.4614077, lng: 21.6614705 },
    { lat: 48.4613614, lng: 21.6608104 },
    { lat: 48.4600476, lng: 21.660749 },
    { lat: 48.4599618, lng: 21.6606136 },
    { lat: 48.4589657, lng: 21.6606409 },
    { lat: 48.4580442, lng: 21.6607671 },
    { lat: 48.4577534, lng: 21.6603353 },
    { lat: 48.4567791, lng: 21.6605488 },
    { lat: 48.4564942, lng: 21.6607016 },
    { lat: 48.4563858, lng: 21.6610536 },
    { lat: 48.4563347, lng: 21.6610816 },
    { lat: 48.4516155, lng: 21.6559737 },
    { lat: 48.4512683, lng: 21.6561498 },
    { lat: 48.4498484, lng: 21.6563964 },
    { lat: 48.4497184, lng: 21.656093 },
    { lat: 48.4494761, lng: 21.65502 },
    { lat: 48.4488091, lng: 21.6554763 },
    { lat: 48.4464839, lng: 21.6568436 },
    { lat: 48.4464451, lng: 21.6569006 },
    { lat: 48.4469252, lng: 21.657997 },
    { lat: 48.4480993, lng: 21.6622605 },
    { lat: 48.4497484, lng: 21.6671121 },
    { lat: 48.4512551, lng: 21.666539 },
    { lat: 48.4519476, lng: 21.6693382 },
    { lat: 48.45257, lng: 21.669592 },
    { lat: 48.4529484, lng: 21.6698925 },
    { lat: 48.4535186, lng: 21.6706177 },
    { lat: 48.4539601, lng: 21.6710054 },
    { lat: 48.4542598, lng: 21.6713852 },
    { lat: 48.4547375, lng: 21.6725639 },
    { lat: 48.4553362, lng: 21.674857 },
    { lat: 48.4556487, lng: 21.6755845 },
    { lat: 48.4557022, lng: 21.6759371 },
    { lat: 48.4559929, lng: 21.6768141 },
    { lat: 48.4560235, lng: 21.6767775 },
    { lat: 48.4566697, lng: 21.6779441 },
    { lat: 48.4572483, lng: 21.6802928 },
    { lat: 48.4580772, lng: 21.6796286 },
    { lat: 48.4587637, lng: 21.6793408 },
    { lat: 48.4587897, lng: 21.6793871 },
    { lat: 48.4592862, lng: 21.6819069 },
    { lat: 48.4593598, lng: 21.6833654 },
    { lat: 48.4585198, lng: 21.6835052 },
    { lat: 48.4582074, lng: 21.6837638 },
    { lat: 48.4581909, lng: 21.6838588 },
    { lat: 48.4582954, lng: 21.68406 },
    { lat: 48.4583407, lng: 21.6852682 },
    { lat: 48.4586052, lng: 21.6861745 },
    { lat: 48.4590875, lng: 21.686981 },
    { lat: 48.4592341, lng: 21.6875306 },
    { lat: 48.4598514, lng: 21.6886287 },
    { lat: 48.4600516, lng: 21.6893299 },
    { lat: 48.460788, lng: 21.6902569 },
    { lat: 48.4610561, lng: 21.6908008 },
    { lat: 48.4614771, lng: 21.6914721 },
    { lat: 48.4615732, lng: 21.6915644 },
    { lat: 48.4617247, lng: 21.6915951 },
    { lat: 48.46186, lng: 21.6928382 },
    { lat: 48.4617497, lng: 21.6935813 },
    { lat: 48.4618936, lng: 21.6946498 },
    { lat: 48.4619435, lng: 21.6947073 },
    { lat: 48.4620894, lng: 21.6954418 },
    { lat: 48.4620878, lng: 21.6960406 },
    { lat: 48.4621541, lng: 21.6963265 },
    { lat: 48.4622043, lng: 21.6970108 },
    { lat: 48.4621911, lng: 21.6971921 },
    { lat: 48.461917, lng: 21.6972923 },
    { lat: 48.4618971, lng: 21.6974633 },
    { lat: 48.4620356, lng: 21.6977582 },
    { lat: 48.4620505, lng: 21.6978935 },
    { lat: 48.4621143, lng: 21.6979246 },
    { lat: 48.4622352, lng: 21.6983103 },
    { lat: 48.4622889, lng: 21.6987176 },
    { lat: 48.4622825, lng: 21.6992692 },
    { lat: 48.4623361, lng: 21.6993343 },
    { lat: 48.4623687, lng: 21.6996209 },
    { lat: 48.4626816, lng: 21.6999147 },
    { lat: 48.4631746, lng: 21.700955 },
    { lat: 48.463092, lng: 21.7011451 },
    { lat: 48.4631465, lng: 21.7013279 },
    { lat: 48.4636896, lng: 21.7023907 },
    { lat: 48.463869, lng: 21.7026151 },
    { lat: 48.4639325, lng: 21.7028434 },
    { lat: 48.4641282, lng: 21.703171 },
    { lat: 48.46441, lng: 21.7042247 },
    { lat: 48.4646365, lng: 21.7047787 },
    { lat: 48.4648928, lng: 21.7057592 },
    { lat: 48.4649229, lng: 21.7064175 },
    { lat: 48.4650616, lng: 21.7071315 },
    { lat: 48.4648406, lng: 21.7102674 },
    { lat: 48.4642553, lng: 21.7110439 },
    { lat: 48.4639405, lng: 21.7113035 },
    { lat: 48.4627292, lng: 21.7126919 },
    { lat: 48.4626358, lng: 21.7130639 },
    { lat: 48.4627056, lng: 21.7138562 },
    { lat: 48.4628747, lng: 21.7143726 },
    { lat: 48.4629493, lng: 21.7144195 },
    { lat: 48.4640381, lng: 21.7143673 },
    { lat: 48.465123, lng: 21.7147104 },
    { lat: 48.4665306, lng: 21.7140492 },
    { lat: 48.4669452, lng: 21.7140273 },
    { lat: 48.4675834, lng: 21.7135741 },
    { lat: 48.4678801, lng: 21.7134393 },
    { lat: 48.4682616, lng: 21.7125338 },
    { lat: 48.469249, lng: 21.7120964 },
    { lat: 48.469707, lng: 21.7120131 },
    { lat: 48.4704836, lng: 21.7115705 },
    { lat: 48.4712217, lng: 21.7102435 },
    { lat: 48.4713849, lng: 21.7101129 },
    { lat: 48.4720445, lng: 21.7098753 },
    { lat: 48.4723398, lng: 21.7094782 },
    { lat: 48.4723595, lng: 21.7094578 },
    { lat: 48.4731378, lng: 21.7089101 },
    { lat: 48.4739206, lng: 21.7085632 },
    { lat: 48.4741743, lng: 21.7084586 },
    { lat: 48.4747034, lng: 21.7084347 },
    { lat: 48.4753242, lng: 21.7086682 },
    { lat: 48.475558, lng: 21.7088227 },
    { lat: 48.4762168, lng: 21.7100961 },
    { lat: 48.4765079, lng: 21.7104133 },
    { lat: 48.4766634, lng: 21.7107141 },
    { lat: 48.4780431, lng: 21.7114692 },
    { lat: 48.478482, lng: 21.7116835 },
    { lat: 48.4787431, lng: 21.7117309 },
    { lat: 48.4791977, lng: 21.7116424 },
    { lat: 48.4794648, lng: 21.7114931 },
    { lat: 48.4800389, lng: 21.7113387 },
    { lat: 48.4804424, lng: 21.711325 },
    { lat: 48.4809601, lng: 21.7108691 },
    { lat: 48.4819698, lng: 21.7107267 },
    { lat: 48.4827987, lng: 21.7104617 },
    { lat: 48.4829438, lng: 21.7102105 },
    { lat: 48.4831494, lng: 21.7088736 },
    { lat: 48.4832066, lng: 21.70807 },
    { lat: 48.4833403, lng: 21.7071131 },
    { lat: 48.4837404, lng: 21.7064718 },
    { lat: 48.4839089, lng: 21.7063388 },
    { lat: 48.4843867, lng: 21.7063264 },
    { lat: 48.4853296, lng: 21.7060735 },
    { lat: 48.4856506, lng: 21.70588 },
    { lat: 48.4860327, lng: 21.7057788 },
    { lat: 48.4864074, lng: 21.7055366 },
    { lat: 48.486825, lng: 21.7047137 },
    { lat: 48.4868557, lng: 21.7038119 },
    { lat: 48.4867272, lng: 21.7033102 },
    { lat: 48.4870065, lng: 21.7026546 },
    { lat: 48.4880137, lng: 21.7015449 },
    { lat: 48.4890373, lng: 21.7011563 },
    { lat: 48.4893479, lng: 21.700917 },
    { lat: 48.4899574, lng: 21.7008571 },
    { lat: 48.4902091, lng: 21.7007654 },
    { lat: 48.490515, lng: 21.7002219 },
    { lat: 48.4909985, lng: 21.6995452 },
    { lat: 48.4918302, lng: 21.699184 },
    { lat: 48.4927842, lng: 21.6986389 },
    { lat: 48.4939024, lng: 21.6983012 },
    { lat: 48.4945519, lng: 21.6976894 },
    { lat: 48.4945472, lng: 21.6976397 },
    { lat: 48.4944046, lng: 21.6960369 },
    { lat: 48.4944116, lng: 21.6951865 },
    { lat: 48.4945555, lng: 21.6933614 },
    { lat: 48.4943595, lng: 21.6923983 },
    { lat: 48.4944927, lng: 21.6921748 },
    { lat: 48.494537, lng: 21.6919386 },
    { lat: 48.4944987, lng: 21.6912773 },
    { lat: 48.4946961, lng: 21.6906308 },
    { lat: 48.4948376, lng: 21.6904163 },
    { lat: 48.494938, lng: 21.6898866 },
    { lat: 48.4949395, lng: 21.6895718 },
    { lat: 48.4952185, lng: 21.687761 },
    { lat: 48.4952581, lng: 21.6866103 },
    { lat: 48.4953362, lng: 21.6860095 },
    { lat: 48.4949624, lng: 21.6842333 },
    { lat: 48.4949436, lng: 21.6834587 },
    { lat: 48.4942979, lng: 21.682522 },
    { lat: 48.4941997, lng: 21.6823021 },
    { lat: 48.4942068, lng: 21.6821055 },
    { lat: 48.4941563, lng: 21.6819941 },
    { lat: 48.4941583, lng: 21.6814895 },
    { lat: 48.4940461, lng: 21.6810055 },
    { lat: 48.4938053, lng: 21.6805884 },
    { lat: 48.4937589, lng: 21.6803341 },
    { lat: 48.4935014, lng: 21.6800577 },
    { lat: 48.4934248, lng: 21.679807 },
    { lat: 48.4933603, lng: 21.6797518 },
    { lat: 48.4933511, lng: 21.6795782 },
    { lat: 48.4932154, lng: 21.6793911 },
    { lat: 48.4932058, lng: 21.6792846 },
    { lat: 48.4930822, lng: 21.6792451 },
    { lat: 48.4930928, lng: 21.6789795 },
    { lat: 48.4928549, lng: 21.6783837 },
    { lat: 48.492715, lng: 21.6783746 },
    { lat: 48.4924506, lng: 21.6780434 },
    { lat: 48.4924265, lng: 21.6777831 },
    { lat: 48.49246, lng: 21.6774047 },
    { lat: 48.4920265, lng: 21.6769786 },
    { lat: 48.4918434, lng: 21.6764564 },
    { lat: 48.4918317, lng: 21.6762368 },
    { lat: 48.4917528, lng: 21.6761442 },
    { lat: 48.4917488, lng: 21.6759625 },
    { lat: 48.4916866, lng: 21.6759919 },
    { lat: 48.4916763, lng: 21.6758194 },
    { lat: 48.4915264, lng: 21.6757712 },
    { lat: 48.4916151, lng: 21.6755586 },
    { lat: 48.4916071, lng: 21.6753867 },
    { lat: 48.4915241, lng: 21.6752333 },
    { lat: 48.4915853, lng: 21.6751248 },
    { lat: 48.4914771, lng: 21.6748776 },
    { lat: 48.4914146, lng: 21.6748502 },
    { lat: 48.4914331, lng: 21.6747416 },
    { lat: 48.4913375, lng: 21.6744616 },
    { lat: 48.4912398, lng: 21.6744659 },
    { lat: 48.4912717, lng: 21.6742727 },
    { lat: 48.4911775, lng: 21.6742413 },
    { lat: 48.4912273, lng: 21.6741326 },
    { lat: 48.4912251, lng: 21.6739752 },
    { lat: 48.4913041, lng: 21.6738532 },
    { lat: 48.49126, lng: 21.6735186 },
    { lat: 48.4911373, lng: 21.6735582 },
    { lat: 48.4911107, lng: 21.6733289 },
    { lat: 48.4909633, lng: 21.673326 },
    { lat: 48.4908592, lng: 21.6731845 },
    { lat: 48.4909433, lng: 21.672803 },
    { lat: 48.4908579, lng: 21.6727682 },
    { lat: 48.4909062, lng: 21.6726804 },
    { lat: 48.4907926, lng: 21.6726814 },
    { lat: 48.4906986, lng: 21.6724964 },
    { lat: 48.4908107, lng: 21.6724373 },
    { lat: 48.4907147, lng: 21.6722649 },
    { lat: 48.4907753, lng: 21.6721799 },
    { lat: 48.4907004, lng: 21.6721483 },
    { lat: 48.4906634, lng: 21.6718754 },
    { lat: 48.4907563, lng: 21.6717721 },
    { lat: 48.4907429, lng: 21.6716945 },
    { lat: 48.4906874, lng: 21.671676 },
    { lat: 48.4907407, lng: 21.6715294 },
    { lat: 48.49065, lng: 21.6715055 },
    { lat: 48.4907455, lng: 21.6712893 },
    { lat: 48.4907328, lng: 21.6711202 },
    { lat: 48.4906876, lng: 21.6709846 },
    { lat: 48.4906091, lng: 21.6709164 },
    { lat: 48.4906017, lng: 21.6707239 },
    { lat: 48.4906999, lng: 21.6706597 },
    { lat: 48.490648, lng: 21.6704859 },
    { lat: 48.4907307, lng: 21.670469 },
    { lat: 48.4905944, lng: 21.6702179 },
    { lat: 48.4906877, lng: 21.6701287 },
    { lat: 48.4907058, lng: 21.6700077 },
    { lat: 48.4905188, lng: 21.6699647 },
    { lat: 48.4906018, lng: 21.6698712 },
    { lat: 48.4905468, lng: 21.669671 },
    { lat: 48.4905789, lng: 21.6696105 },
    { lat: 48.4904877, lng: 21.6694821 },
    { lat: 48.490408, lng: 21.6694906 },
    { lat: 48.4905172, lng: 21.6694153 },
    { lat: 48.4904626, lng: 21.6690246 },
    { lat: 48.4903846, lng: 21.6690501 },
    { lat: 48.4903743, lng: 21.6689617 },
    { lat: 48.490409, lng: 21.6687907 },
    { lat: 48.4903478, lng: 21.6686909 },
    { lat: 48.4904247, lng: 21.6685467 },
    { lat: 48.4902455, lng: 21.6683308 },
    { lat: 48.4902533, lng: 21.6682466 },
    { lat: 48.4903392, lng: 21.6682143 },
    { lat: 48.4902959, lng: 21.6680723 },
    { lat: 48.4903812, lng: 21.6679414 },
    { lat: 48.4904603, lng: 21.6679546 },
    { lat: 48.4904485, lng: 21.6676109 },
    { lat: 48.4901306, lng: 21.6672221 },
    { lat: 48.4901661, lng: 21.6669935 },
    { lat: 48.4900084, lng: 21.6668228 },
    { lat: 48.4900946, lng: 21.6667266 },
    { lat: 48.4900007, lng: 21.66663 },
    { lat: 48.4899954, lng: 21.666515 },
    { lat: 48.4898497, lng: 21.6664975 },
    { lat: 48.4897687, lng: 21.6660045 },
    { lat: 48.4896623, lng: 21.6659416 },
    { lat: 48.4896545, lng: 21.6658194 },
    { lat: 48.4897142, lng: 21.6658234 },
    { lat: 48.4896744, lng: 21.6656851 },
    { lat: 48.4895835, lng: 21.6656911 },
    { lat: 48.4895896, lng: 21.6654999 },
    { lat: 48.4897216, lng: 21.6653945 },
    { lat: 48.4896823, lng: 21.6652377 },
    { lat: 48.4896155, lng: 21.6652599 },
    { lat: 48.4895672, lng: 21.6651384 },
    { lat: 48.489601, lng: 21.6650225 },
    { lat: 48.4895192, lng: 21.6648261 },
    { lat: 48.4894103, lng: 21.6648371 },
    { lat: 48.489372, lng: 21.6649186 },
    { lat: 48.4893092, lng: 21.664881 },
    { lat: 48.4893309, lng: 21.6647708 },
    { lat: 48.4895311, lng: 21.6646076 },
    { lat: 48.4894023, lng: 21.6643825 },
    { lat: 48.4893173, lng: 21.6643612 },
    { lat: 48.4892729, lng: 21.66422 },
    { lat: 48.4893298, lng: 21.6639146 },
    { lat: 48.4892033, lng: 21.6637752 },
    { lat: 48.4892141, lng: 21.6633078 },
    { lat: 48.4891375, lng: 21.6632572 },
    { lat: 48.4891303, lng: 21.6630406 },
    { lat: 48.489196, lng: 21.6628574 },
    { lat: 48.489017, lng: 21.6627256 },
    { lat: 48.4889377, lng: 21.6624992 },
    { lat: 48.4887552, lng: 21.6623737 },
    { lat: 48.488599, lng: 21.6620936 },
    { lat: 48.4885951, lng: 21.661996 },
    { lat: 48.4883766, lng: 21.6618617 },
    { lat: 48.4884173, lng: 21.6616598 },
    { lat: 48.4882838, lng: 21.6613067 },
    { lat: 48.4879395, lng: 21.6606954 },
    { lat: 48.4873339, lng: 21.6589281 },
    { lat: 48.4870567, lng: 21.6584261 },
  ],
  SvätáMária: [
    { lat: 48.4201454, lng: 21.8492144 },
    { lat: 48.4205627, lng: 21.849513 },
    { lat: 48.4214304, lng: 21.850091 },
    { lat: 48.421876, lng: 21.8502271 },
    { lat: 48.4224078, lng: 21.8501259 },
    { lat: 48.4236998, lng: 21.8506999 },
    { lat: 48.4242398, lng: 21.8505327 },
    { lat: 48.4244367, lng: 21.8506129 },
    { lat: 48.4245512, lng: 21.8504762 },
    { lat: 48.4246375, lng: 21.8504659 },
    { lat: 48.4248682, lng: 21.8503808 },
    { lat: 48.4248819, lng: 21.8502543 },
    { lat: 48.424947, lng: 21.8502462 },
    { lat: 48.4255259, lng: 21.8504883 },
    { lat: 48.4323906, lng: 21.8539872 },
    { lat: 48.4325797, lng: 21.8540336 },
    { lat: 48.4331694, lng: 21.8536969 },
    { lat: 48.4337773, lng: 21.8538389 },
    { lat: 48.4339399, lng: 21.8543849 },
    { lat: 48.4341075, lng: 21.8547297 },
    { lat: 48.4346486, lng: 21.8549833 },
    { lat: 48.437012, lng: 21.8548899 },
    { lat: 48.4377044, lng: 21.8550794 },
    { lat: 48.4394679, lng: 21.8560099 },
    { lat: 48.4414638, lng: 21.8575775 },
    { lat: 48.442365, lng: 21.8580589 },
    { lat: 48.4435176, lng: 21.8584317 },
    { lat: 48.444171, lng: 21.8584651 },
    { lat: 48.4450764, lng: 21.8583659 },
    { lat: 48.4468156, lng: 21.857702 },
    { lat: 48.4478927, lng: 21.8574144 },
    { lat: 48.448717, lng: 21.8570987 },
    { lat: 48.4494401, lng: 21.8567358 },
    { lat: 48.450494, lng: 21.8565484 },
    { lat: 48.4507918, lng: 21.8565743 },
    { lat: 48.4513919, lng: 21.8567717 },
    { lat: 48.4519761, lng: 21.8570463 },
    { lat: 48.4550793, lng: 21.8587932 },
    { lat: 48.4563128, lng: 21.8591582 },
    { lat: 48.4571396, lng: 21.8593318 },
    { lat: 48.4578035, lng: 21.8593799 },
    { lat: 48.4587681, lng: 21.859218 },
    { lat: 48.4594467, lng: 21.8587481 },
    { lat: 48.4599028, lng: 21.8582425 },
    { lat: 48.4604482, lng: 21.857534 },
    { lat: 48.4607507, lng: 21.8570073 },
    { lat: 48.461807, lng: 21.8554788 },
    { lat: 48.4622515, lng: 21.8547407 },
    { lat: 48.4625485, lng: 21.8540835 },
    { lat: 48.4628991, lng: 21.853466 },
    { lat: 48.4631379, lng: 21.8531731 },
    { lat: 48.4636009, lng: 21.8528443 },
    { lat: 48.4643072, lng: 21.8525311 },
    { lat: 48.4647299, lng: 21.8524319 },
    { lat: 48.4650769, lng: 21.8524942 },
    { lat: 48.4652883, lng: 21.8526198 },
    { lat: 48.4653696, lng: 21.8522424 },
    { lat: 48.4655576, lng: 21.8513725 },
    { lat: 48.4658667, lng: 21.8504532 },
    { lat: 48.4688687, lng: 21.8435208 },
    { lat: 48.4696259, lng: 21.8416083 },
    { lat: 48.469545, lng: 21.841134 },
    { lat: 48.469087, lng: 21.841253 },
    { lat: 48.468455, lng: 21.841209 },
    { lat: 48.4679, lng: 21.840868 },
    { lat: 48.467688, lng: 21.840642 },
    { lat: 48.467467, lng: 21.840006 },
    { lat: 48.467468, lng: 21.839816 },
    { lat: 48.467714, lng: 21.838681 },
    { lat: 48.467798, lng: 21.838205 },
    { lat: 48.467823, lng: 21.837662 },
    { lat: 48.467792, lng: 21.837171 },
    { lat: 48.467671, lng: 21.836805 },
    { lat: 48.467659, lng: 21.836771 },
    { lat: 48.467523, lng: 21.836359 },
    { lat: 48.467289, lng: 21.836058 },
    { lat: 48.466832, lng: 21.835685 },
    { lat: 48.465205, lng: 21.834584 },
    { lat: 48.46467, lng: 21.834136 },
    { lat: 48.463635, lng: 21.83296 },
    { lat: 48.463418, lng: 21.832724 },
    { lat: 48.463111, lng: 21.832688 },
    { lat: 48.462851, lng: 21.832671 },
    { lat: 48.462637, lng: 21.832759 },
    { lat: 48.46258, lng: 21.832863 },
    { lat: 48.462492, lng: 21.833109 },
    { lat: 48.462546, lng: 21.833966 },
    { lat: 48.462483, lng: 21.834436 },
    { lat: 48.462418, lng: 21.834824 },
    { lat: 48.462253, lng: 21.83521 },
    { lat: 48.46215, lng: 21.835444 },
    { lat: 48.461992, lng: 21.835656 },
    { lat: 48.46174, lng: 21.835812 },
    { lat: 48.461455, lng: 21.835996 },
    { lat: 48.461098, lng: 21.836109 },
    { lat: 48.46077, lng: 21.836095 },
    { lat: 48.460425, lng: 21.835997 },
    { lat: 48.460201, lng: 21.835853 },
    { lat: 48.459896, lng: 21.835459 },
    { lat: 48.45969, lng: 21.83473 },
    { lat: 48.459611, lng: 21.833923 },
    { lat: 48.459573, lng: 21.832994 },
    { lat: 48.459492, lng: 21.83184 },
    { lat: 48.459485, lng: 21.830985 },
    { lat: 48.459526, lng: 21.830388 },
    { lat: 48.45957, lng: 21.830117 },
    { lat: 48.459613, lng: 21.829852 },
    { lat: 48.459661, lng: 21.829684 },
    { lat: 48.459915, lng: 21.828838 },
    { lat: 48.460088, lng: 21.828527 },
    { lat: 48.460168, lng: 21.8284 },
    { lat: 48.460186, lng: 21.82837 },
    { lat: 48.460516, lng: 21.827994 },
    { lat: 48.460541, lng: 21.827965 },
    { lat: 48.460674, lng: 21.827896 },
    { lat: 48.461596, lng: 21.827449 },
    { lat: 48.461616, lng: 21.827439 },
    { lat: 48.461867, lng: 21.827277 },
    { lat: 48.462261, lng: 21.827022 },
    { lat: 48.462714, lng: 21.826724 },
    { lat: 48.462865, lng: 21.826434 },
    { lat: 48.463065, lng: 21.825805 },
    { lat: 48.463231, lng: 21.825048 },
    { lat: 48.463276, lng: 21.824515 },
    { lat: 48.463333, lng: 21.823689 },
    { lat: 48.463364, lng: 21.823355 },
    { lat: 48.463532, lng: 21.822834 },
    { lat: 48.46375, lng: 21.822456 },
    { lat: 48.463839, lng: 21.822409 },
    { lat: 48.464033, lng: 21.822468 },
    { lat: 48.464126, lng: 21.822599 },
    { lat: 48.464229, lng: 21.822746 },
    { lat: 48.464313, lng: 21.823073 },
    { lat: 48.46474, lng: 21.824494 },
    { lat: 48.465125, lng: 21.825766 },
    { lat: 48.465305, lng: 21.826327 },
    { lat: 48.465549, lng: 21.827032 },
    { lat: 48.465836, lng: 21.827644 },
    { lat: 48.465937, lng: 21.827788 },
    { lat: 48.466066, lng: 21.827929 },
    { lat: 48.466416, lng: 21.828084 },
    { lat: 48.466721, lng: 21.827974 },
    { lat: 48.466998, lng: 21.827689 },
    { lat: 48.467226, lng: 21.827445 },
    { lat: 48.467267, lng: 21.827211 },
    { lat: 48.46711, lng: 21.826008 },
    { lat: 48.467054, lng: 21.82555 },
    { lat: 48.46705, lng: 21.825521 },
    { lat: 48.466951, lng: 21.82491 },
    { lat: 48.466855, lng: 21.824139 },
    { lat: 48.4668, lng: 21.823605 },
    { lat: 48.466732, lng: 21.822649 },
    { lat: 48.466671, lng: 21.822002 },
    { lat: 48.466596, lng: 21.821557 },
    { lat: 48.466423, lng: 21.82072 },
    { lat: 48.466276, lng: 21.820003 },
    { lat: 48.466187, lng: 21.820067 },
    { lat: 48.46626, lng: 21.819626 },
    { lat: 48.4659272, lng: 21.8196154 },
    { lat: 48.4656409, lng: 21.8195121 },
    { lat: 48.4650597, lng: 21.819098 },
    { lat: 48.4645805, lng: 21.8186333 },
    { lat: 48.4635722, lng: 21.8169853 },
    { lat: 48.4627695, lng: 21.8160704 },
    { lat: 48.4622118, lng: 21.8157344 },
    { lat: 48.4612999, lng: 21.8154211 },
    { lat: 48.4606316, lng: 21.8152881 },
    { lat: 48.4596467, lng: 21.8152639 },
    { lat: 48.4591863, lng: 21.8153928 },
    { lat: 48.4586101, lng: 21.8156457 },
    { lat: 48.4556749, lng: 21.8185725 },
    { lat: 48.4548391, lng: 21.819093 },
    { lat: 48.4545273, lng: 21.8191702 },
    { lat: 48.4539808, lng: 21.8189622 },
    { lat: 48.4536295, lng: 21.8187356 },
    { lat: 48.4534458, lng: 21.8184739 },
    { lat: 48.4531397, lng: 21.817741 },
    { lat: 48.4530047, lng: 21.8172666 },
    { lat: 48.4529228, lng: 21.8164999 },
    { lat: 48.4529863, lng: 21.8154846 },
    { lat: 48.4535964, lng: 21.8128849 },
    { lat: 48.4538427, lng: 21.8115979 },
    { lat: 48.4540278, lng: 21.8099002 },
    { lat: 48.4539798, lng: 21.8088436 },
    { lat: 48.4538152, lng: 21.8080159 },
    { lat: 48.453593, lng: 21.8074375 },
    { lat: 48.4530234, lng: 21.8064878 },
    { lat: 48.4522588, lng: 21.8055164 },
    { lat: 48.4516802, lng: 21.8051212 },
    { lat: 48.4514893, lng: 21.8050528 },
    { lat: 48.4507851, lng: 21.8050532 },
    { lat: 48.4504286, lng: 21.8051435 },
    { lat: 48.45003, lng: 21.8055223 },
    { lat: 48.449704, lng: 21.8055679 },
    { lat: 48.4490165, lng: 21.8059093 },
    { lat: 48.4471367, lng: 21.8065621 },
    { lat: 48.4461573, lng: 21.8073942 },
    { lat: 48.4453828, lng: 21.8085412 },
    { lat: 48.4443514, lng: 21.8097498 },
    { lat: 48.4439938, lng: 21.8103147 },
    { lat: 48.4436656, lng: 21.8109702 },
    { lat: 48.4433094, lng: 21.8115012 },
    { lat: 48.4429052, lng: 21.8127293 },
    { lat: 48.4427529, lng: 21.8136664 },
    { lat: 48.4425927, lng: 21.8142457 },
    { lat: 48.4419127, lng: 21.815905 },
    { lat: 48.4414921, lng: 21.8165089 },
    { lat: 48.4416021, lng: 21.8170987 },
    { lat: 48.4406321, lng: 21.8178272 },
    { lat: 48.4396304, lng: 21.8183694 },
    { lat: 48.4390607, lng: 21.8185697 },
    { lat: 48.438753, lng: 21.8184617 },
    { lat: 48.4384992, lng: 21.8184852 },
    { lat: 48.4385066, lng: 21.8192799 },
    { lat: 48.4385468, lng: 21.8194844 },
    { lat: 48.4384716, lng: 21.8203942 },
    { lat: 48.438166, lng: 21.8216173 },
    { lat: 48.4373893, lng: 21.8230996 },
    { lat: 48.4368667, lng: 21.8235106 },
    { lat: 48.4367122, lng: 21.824364 },
    { lat: 48.4362713, lng: 21.8249236 },
    { lat: 48.436121, lng: 21.8254154 },
    { lat: 48.4355042, lng: 21.8263131 },
    { lat: 48.4355092, lng: 21.8267153 },
    { lat: 48.4354251, lng: 21.8269874 },
    { lat: 48.4352165, lng: 21.8270532 },
    { lat: 48.4349542, lng: 21.827355 },
    { lat: 48.434825, lng: 21.8280496 },
    { lat: 48.43466, lng: 21.8278513 },
    { lat: 48.4339978, lng: 21.8269164 },
    { lat: 48.433093, lng: 21.8254017 },
    { lat: 48.4325115, lng: 21.8253691 },
    { lat: 48.4315527, lng: 21.8255712 },
    { lat: 48.4308044, lng: 21.8259119 },
    { lat: 48.4298535, lng: 21.8265532 },
    { lat: 48.4285366, lng: 21.8270854 },
    { lat: 48.4276812, lng: 21.8274206 },
    { lat: 48.4271317, lng: 21.8298165 },
    { lat: 48.4258237, lng: 21.8309194 },
    { lat: 48.4260405, lng: 21.8319343 },
    { lat: 48.4260703, lng: 21.8322659 },
    { lat: 48.4260243, lng: 21.8326058 },
    { lat: 48.4253696, lng: 21.8339989 },
    { lat: 48.4252996, lng: 21.8342907 },
    { lat: 48.4252793, lng: 21.8349766 },
    { lat: 48.4251204, lng: 21.8357472 },
    { lat: 48.4249558, lng: 21.8361624 },
    { lat: 48.4244187, lng: 21.8371865 },
    { lat: 48.4241531, lng: 21.8378762 },
    { lat: 48.4237979, lng: 21.8390136 },
    { lat: 48.4236913, lng: 21.8396737 },
    { lat: 48.4235977, lng: 21.8398811 },
    { lat: 48.4233406, lng: 21.8395151 },
    { lat: 48.4233642, lng: 21.8419734 },
    { lat: 48.4234745, lng: 21.8429714 },
    { lat: 48.4236699, lng: 21.8441767 },
    { lat: 48.4222205, lng: 21.8448957 },
    { lat: 48.4221414, lng: 21.8449712 },
    { lat: 48.421609, lng: 21.8459587 },
    { lat: 48.4211418, lng: 21.846035 },
    { lat: 48.4209223, lng: 21.8463815 },
    { lat: 48.4209045, lng: 21.8472956 },
    { lat: 48.4208025, lng: 21.8476951 },
    { lat: 48.4203295, lng: 21.8489873 },
    { lat: 48.420206, lng: 21.849122 },
    { lat: 48.4201454, lng: 21.8492144 },
  ],
  NižnýŽipov: [
    { lat: 48.5634308, lng: 21.6021828 },
    { lat: 48.5622501, lng: 21.6031807 },
    { lat: 48.5616193, lng: 21.6037174 },
    { lat: 48.5629126, lng: 21.6073932 },
    { lat: 48.563038, lng: 21.6080946 },
    { lat: 48.563207, lng: 21.6134198 },
    { lat: 48.5630324, lng: 21.6160016 },
    { lat: 48.5628327, lng: 21.6174323 },
    { lat: 48.5624829, lng: 21.6193758 },
    { lat: 48.5620879, lng: 21.6209467 },
    { lat: 48.5618767, lng: 21.6219756 },
    { lat: 48.5615307, lng: 21.6245809 },
    { lat: 48.5614447, lng: 21.6267898 },
    { lat: 48.5617353, lng: 21.6292309 },
    { lat: 48.561646, lng: 21.6292575 },
    { lat: 48.5616442, lng: 21.629356 },
    { lat: 48.5617696, lng: 21.6293705 },
    { lat: 48.5619627, lng: 21.6303295 },
    { lat: 48.5623529, lng: 21.6315034 },
    { lat: 48.562529, lng: 21.6316781 },
    { lat: 48.5626867, lng: 21.6317297 },
    { lat: 48.5641947, lng: 21.6320588 },
    { lat: 48.5645154, lng: 21.6322205 },
    { lat: 48.5648334, lng: 21.6325319 },
    { lat: 48.5663199, lng: 21.6348512 },
    { lat: 48.5662973, lng: 21.6349716 },
    { lat: 48.5664819, lng: 21.6357053 },
    { lat: 48.5668376, lng: 21.6367626 },
    { lat: 48.5676148, lng: 21.6380537 },
    { lat: 48.5678107, lng: 21.6386304 },
    { lat: 48.5682168, lng: 21.6391946 },
    { lat: 48.5686834, lng: 21.6395096 },
    { lat: 48.5692898, lng: 21.640603 },
    { lat: 48.5704214, lng: 21.6420543 },
    { lat: 48.5708384, lng: 21.6424846 },
    { lat: 48.5711723, lng: 21.6432112 },
    { lat: 48.5712911, lng: 21.6435178 },
    { lat: 48.5714047, lng: 21.6440134 },
    { lat: 48.5720849, lng: 21.6456597 },
    { lat: 48.5720999, lng: 21.6458343 },
    { lat: 48.5730255, lng: 21.6479435 },
    { lat: 48.5733761, lng: 21.6484989 },
    { lat: 48.573072, lng: 21.6488939 },
    { lat: 48.5739758, lng: 21.6509392 },
    { lat: 48.5739704, lng: 21.6510337 },
    { lat: 48.5738886, lng: 21.6510846 },
    { lat: 48.5740818, lng: 21.6540577 },
    { lat: 48.5740347, lng: 21.6553273 },
    { lat: 48.5740858, lng: 21.6560806 },
    { lat: 48.5743337, lng: 21.6581396 },
    { lat: 48.574328, lng: 21.658712 },
    { lat: 48.5742497, lng: 21.6587279 },
    { lat: 48.5742554, lng: 21.6588072 },
    { lat: 48.5742442, lng: 21.6588541 },
    { lat: 48.5745195, lng: 21.659287 },
    { lat: 48.5747661, lng: 21.6591338 },
    { lat: 48.5748872, lng: 21.6589503 },
    { lat: 48.575183, lng: 21.6590758 },
    { lat: 48.57548, lng: 21.6591151 },
    { lat: 48.5755672, lng: 21.6591747 },
    { lat: 48.5756474, lng: 21.6594319 },
    { lat: 48.5759352, lng: 21.6593358 },
    { lat: 48.5760859, lng: 21.6596199 },
    { lat: 48.5762308, lng: 21.6597775 },
    { lat: 48.5767014, lng: 21.6601546 },
    { lat: 48.577075, lng: 21.6602368 },
    { lat: 48.5775691, lng: 21.6601675 },
    { lat: 48.578363, lng: 21.6603905 },
    { lat: 48.5785966, lng: 21.6602523 },
    { lat: 48.5794634, lng: 21.65949 },
    { lat: 48.5797873, lng: 21.659285 },
    { lat: 48.579829, lng: 21.6591605 },
    { lat: 48.5803444, lng: 21.6587595 },
    { lat: 48.5804454, lng: 21.6589074 },
    { lat: 48.5804479, lng: 21.6591609 },
    { lat: 48.5805164, lng: 21.6593621 },
    { lat: 48.5806684, lng: 21.6594887 },
    { lat: 48.580799, lng: 21.6592897 },
    { lat: 48.5808984, lng: 21.6592632 },
    { lat: 48.581246, lng: 21.660492 },
    { lat: 48.5813262, lng: 21.6605438 },
    { lat: 48.5817848, lng: 21.6605202 },
    { lat: 48.5822312, lng: 21.6603928 },
    { lat: 48.5823111, lng: 21.6604681 },
    { lat: 48.5824472, lng: 21.6604277 },
    { lat: 48.5825965, lng: 21.6600871 },
    { lat: 48.5829557, lng: 21.6598522 },
    { lat: 48.5831716, lng: 21.6599347 },
    { lat: 48.5833759, lng: 21.6598345 },
    { lat: 48.5835433, lng: 21.659946 },
    { lat: 48.5836175, lng: 21.6599022 },
    { lat: 48.5836941, lng: 21.6597795 },
    { lat: 48.5837968, lng: 21.6593814 },
    { lat: 48.5837651, lng: 21.6590867 },
    { lat: 48.5839266, lng: 21.658992 },
    { lat: 48.5841399, lng: 21.6591774 },
    { lat: 48.5843517, lng: 21.6591883 },
    { lat: 48.5844008, lng: 21.6589692 },
    { lat: 48.5847534, lng: 21.6582432 },
    { lat: 48.5848481, lng: 21.6582166 },
    { lat: 48.5849731, lng: 21.6583812 },
    { lat: 48.5850489, lng: 21.6583935 },
    { lat: 48.5851325, lng: 21.6582866 },
    { lat: 48.5853057, lng: 21.6582403 },
    { lat: 48.5854985, lng: 21.6580921 },
    { lat: 48.5858296, lng: 21.6577768 },
    { lat: 48.5859136, lng: 21.6578046 },
    { lat: 48.5859863, lng: 21.6576185 },
    { lat: 48.5859272, lng: 21.6574413 },
    { lat: 48.5860648, lng: 21.6573691 },
    { lat: 48.5861829, lng: 21.6573674 },
    { lat: 48.5862651, lng: 21.6574507 },
    { lat: 48.5864617, lng: 21.6573983 },
    { lat: 48.5866201, lng: 21.6577146 },
    { lat: 48.5864635, lng: 21.6579519 },
    { lat: 48.586058, lng: 21.6582633 },
    { lat: 48.5858044, lng: 21.6585748 },
    { lat: 48.5850079, lng: 21.6592777 },
    { lat: 48.5842601, lng: 21.6597853 },
    { lat: 48.5853121, lng: 21.6626497 },
    { lat: 48.5860219, lng: 21.6650611 },
    { lat: 48.5863033, lng: 21.6650205 },
    { lat: 48.5863439, lng: 21.6651967 },
    { lat: 48.5862645, lng: 21.6652482 },
    { lat: 48.5865767, lng: 21.6668315 },
    { lat: 48.5863119, lng: 21.6669443 },
    { lat: 48.5871416, lng: 21.6706071 },
    { lat: 48.5872874, lng: 21.6714115 },
    { lat: 48.5877244, lng: 21.6732011 },
    { lat: 48.5880265, lng: 21.6748214 },
    { lat: 48.5886204, lng: 21.6796786 },
    { lat: 48.5889972, lng: 21.6820648 },
    { lat: 48.5892268, lng: 21.6831342 },
    { lat: 48.5900388, lng: 21.6830377 },
    { lat: 48.5904155, lng: 21.6829066 },
    { lat: 48.5905084, lng: 21.6828479 },
    { lat: 48.5904621, lng: 21.6825924 },
    { lat: 48.5911145, lng: 21.6822221 },
    { lat: 48.5914856, lng: 21.6820829 },
    { lat: 48.5915607, lng: 21.6823636 },
    { lat: 48.5919151, lng: 21.6821604 },
    { lat: 48.591845, lng: 21.6818957 },
    { lat: 48.5923601, lng: 21.6816452 },
    { lat: 48.5927001, lng: 21.6814012 },
    { lat: 48.593457, lng: 21.6804824 },
    { lat: 48.5936544, lng: 21.6804211 },
    { lat: 48.5938162, lng: 21.680287 },
    { lat: 48.5940844, lng: 21.6802217 },
    { lat: 48.5944006, lng: 21.6795253 },
    { lat: 48.5943386, lng: 21.6791425 },
    { lat: 48.5944111, lng: 21.6790434 },
    { lat: 48.5943607, lng: 21.6784786 },
    { lat: 48.5946041, lng: 21.678119 },
    { lat: 48.5945057, lng: 21.6775206 },
    { lat: 48.5947566, lng: 21.6773987 },
    { lat: 48.5947295, lng: 21.6771839 },
    { lat: 48.5950016, lng: 21.6768493 },
    { lat: 48.5949154, lng: 21.676378 },
    { lat: 48.5964513, lng: 21.67576 },
    { lat: 48.5963827, lng: 21.6754323 },
    { lat: 48.596478, lng: 21.6753816 },
    { lat: 48.596461, lng: 21.6750006 },
    { lat: 48.5967438, lng: 21.6748177 },
    { lat: 48.5976408, lng: 21.6746574 },
    { lat: 48.5983295, lng: 21.6751123 },
    { lat: 48.5986916, lng: 21.6751466 },
    { lat: 48.5988157, lng: 21.6750817 },
    { lat: 48.5988924, lng: 21.6752916 },
    { lat: 48.5990265, lng: 21.6751322 },
    { lat: 48.5990964, lng: 21.6749299 },
    { lat: 48.5990934, lng: 21.674613 },
    { lat: 48.5992391, lng: 21.6745097 },
    { lat: 48.5994119, lng: 21.6746213 },
    { lat: 48.5996532, lng: 21.6746418 },
    { lat: 48.5996005, lng: 21.6743195 },
    { lat: 48.5996852, lng: 21.6742872 },
    { lat: 48.5995938, lng: 21.6736175 },
    { lat: 48.6003822, lng: 21.6733573 },
    { lat: 48.6003221, lng: 21.6730137 },
    { lat: 48.600797, lng: 21.6726899 },
    { lat: 48.60067, lng: 21.6722001 },
    { lat: 48.6012233, lng: 21.6719833 },
    { lat: 48.6014884, lng: 21.6719252 },
    { lat: 48.6012564, lng: 21.6708135 },
    { lat: 48.6009629, lng: 21.6688019 },
    { lat: 48.6007536, lng: 21.6678563 },
    { lat: 48.6006273, lng: 21.6675168 },
    { lat: 48.6000409, lng: 21.6663618 },
    { lat: 48.5998917, lng: 21.665926 },
    { lat: 48.5980498, lng: 21.6589145 },
    { lat: 48.5976616, lng: 21.6576506 },
    { lat: 48.5970333, lng: 21.6563665 },
    { lat: 48.5967366, lng: 21.6554718 },
    { lat: 48.5960578, lng: 21.6526007 },
    { lat: 48.5958078, lng: 21.6518194 },
    { lat: 48.5955027, lng: 21.651075 },
    { lat: 48.5953413, lng: 21.6492997 },
    { lat: 48.5952343, lng: 21.6485653 },
    { lat: 48.595221, lng: 21.6481286 },
    { lat: 48.5949699, lng: 21.6474267 },
    { lat: 48.5946667, lng: 21.6463413 },
    { lat: 48.5933961, lng: 21.6401799 },
    { lat: 48.593466, lng: 21.6401041 },
    { lat: 48.5931671, lng: 21.6390598 },
    { lat: 48.592781, lng: 21.6380821 },
    { lat: 48.5921627, lng: 21.636834 },
    { lat: 48.5911585, lng: 21.6350312 },
    { lat: 48.5904776, lng: 21.6339676 },
    { lat: 48.5909244, lng: 21.6332855 },
    { lat: 48.5913843, lng: 21.6327458 },
    { lat: 48.5908827, lng: 21.6309235 },
    { lat: 48.5924419, lng: 21.6289266 },
    { lat: 48.5923721, lng: 21.6286854 },
    { lat: 48.593714, lng: 21.6274065 },
    { lat: 48.5946617, lng: 21.6264083 },
    { lat: 48.59376, lng: 21.6241221 },
    { lat: 48.5933883, lng: 21.6235414 },
    { lat: 48.5922716, lng: 21.6221077 },
    { lat: 48.5916404, lng: 21.6200886 },
    { lat: 48.5911246, lng: 21.6186931 },
    { lat: 48.5901554, lng: 21.6166561 },
    { lat: 48.5897872, lng: 21.6166531 },
    { lat: 48.5893491, lng: 21.616432 },
    { lat: 48.5891612, lng: 21.6162715 },
    { lat: 48.5887586, lng: 21.6162828 },
    { lat: 48.5886273, lng: 21.6161728 },
    { lat: 48.58839, lng: 21.616137 },
    { lat: 48.5882312, lng: 21.6154458 },
    { lat: 48.5881688, lng: 21.6153392 },
    { lat: 48.588018, lng: 21.6152599 },
    { lat: 48.5879538, lng: 21.6149786 },
    { lat: 48.5879575, lng: 21.6147882 },
    { lat: 48.5880744, lng: 21.614381 },
    { lat: 48.5881003, lng: 21.6140172 },
    { lat: 48.5880726, lng: 21.6139521 },
    { lat: 48.5881161, lng: 21.6138986 },
    { lat: 48.5880868, lng: 21.6138098 },
    { lat: 48.5881477, lng: 21.6137811 },
    { lat: 48.5881082, lng: 21.6133343 },
    { lat: 48.5884485, lng: 21.6126606 },
    { lat: 48.5884983, lng: 21.612393 },
    { lat: 48.5885084, lng: 21.6109796 },
    { lat: 48.5887315, lng: 21.6106254 },
    { lat: 48.5892371, lng: 21.609515 },
    { lat: 48.5893995, lng: 21.609285 },
    { lat: 48.589307, lng: 21.6087087 },
    { lat: 48.5892266, lng: 21.6085297 },
    { lat: 48.5892293, lng: 21.6084265 },
    { lat: 48.5891097, lng: 21.6082535 },
    { lat: 48.5891146, lng: 21.6081505 },
    { lat: 48.5889773, lng: 21.6079453 },
    { lat: 48.5888015, lng: 21.6073323 },
    { lat: 48.5887218, lng: 21.6072567 },
    { lat: 48.5886475, lng: 21.6070546 },
    { lat: 48.5884903, lng: 21.6063395 },
    { lat: 48.5881052, lng: 21.6053667 },
    { lat: 48.5879356, lng: 21.6037697 },
    { lat: 48.5871242, lng: 21.6004236 },
    { lat: 48.5869207, lng: 21.5998654 },
    { lat: 48.5856301, lng: 21.5976951 },
    { lat: 48.5849655, lng: 21.5954292 },
    { lat: 48.5845255, lng: 21.5941839 },
    { lat: 48.5838223, lng: 21.5905773 },
    { lat: 48.5829443, lng: 21.5878348 },
    { lat: 48.5829207, lng: 21.5857968 },
    { lat: 48.5828184, lng: 21.5851626 },
    { lat: 48.5817858, lng: 21.5850136 },
    { lat: 48.5808624, lng: 21.5844248 },
    { lat: 48.5807743, lng: 21.5832574 },
    { lat: 48.5801504, lng: 21.5834004 },
    { lat: 48.5799721, lng: 21.581506 },
    { lat: 48.5786484, lng: 21.581805 },
    { lat: 48.5786212, lng: 21.5817606 },
    { lat: 48.5786567, lng: 21.5815824 },
    { lat: 48.5785783, lng: 21.5816357 },
    { lat: 48.5785293, lng: 21.5809339 },
    { lat: 48.5785123, lng: 21.5792524 },
    { lat: 48.5777203, lng: 21.579295 },
    { lat: 48.5746762, lng: 21.5788815 },
    { lat: 48.572375, lng: 21.5787581 },
    { lat: 48.5722903, lng: 21.5786662 },
    { lat: 48.5717712, lng: 21.5786113 },
    { lat: 48.5713025, lng: 21.5786282 },
    { lat: 48.5710809, lng: 21.5787124 },
    { lat: 48.5698587, lng: 21.5788695 },
    { lat: 48.5690155, lng: 21.5790514 },
    { lat: 48.5681818, lng: 21.5794976 },
    { lat: 48.5672453, lng: 21.5802003 },
    { lat: 48.5666234, lng: 21.5807715 },
    { lat: 48.5660276, lng: 21.5814253 },
    { lat: 48.5654058, lng: 21.5822448 },
    { lat: 48.565836, lng: 21.5830525 },
    { lat: 48.5656035, lng: 21.5833452 },
    { lat: 48.5662872, lng: 21.5846493 },
    { lat: 48.5669269, lng: 21.5857154 },
    { lat: 48.5677725, lng: 21.584813 },
    { lat: 48.5695067, lng: 21.5870985 },
    { lat: 48.5700233, lng: 21.5893901 },
    { lat: 48.5703203, lng: 21.5893847 },
    { lat: 48.5708599, lng: 21.5892067 },
    { lat: 48.5708217, lng: 21.5894145 },
    { lat: 48.5706066, lng: 21.589807 },
    { lat: 48.5706976, lng: 21.5906034 },
    { lat: 48.5705525, lng: 21.5908017 },
    { lat: 48.5701826, lng: 21.5910985 },
    { lat: 48.5701948, lng: 21.591373 },
    { lat: 48.5698641, lng: 21.591452 },
    { lat: 48.569959, lng: 21.59395 },
    { lat: 48.5700926, lng: 21.5954663 },
    { lat: 48.5701639, lng: 21.5969582 },
    { lat: 48.570186, lng: 21.599319 },
    { lat: 48.5672604, lng: 21.6001205 },
    { lat: 48.5672033, lng: 21.5999248 },
    { lat: 48.56583, lng: 21.6005887 },
    { lat: 48.5653476, lng: 21.6008837 },
    { lat: 48.5634308, lng: 21.6021828 },
  ],
  Čierna: [
    { lat: 48.448266, lng: 22.134424 },
    { lat: 48.447744, lng: 22.1340454 },
    { lat: 48.4472449, lng: 22.1336307 },
    { lat: 48.4468559, lng: 22.1329759 },
    { lat: 48.4467179, lng: 22.1324574 },
    { lat: 48.4466844, lng: 22.132134 },
    { lat: 48.4469018, lng: 22.131715 },
    { lat: 48.445936, lng: 22.1323072 },
    { lat: 48.4458086, lng: 22.1325832 },
    { lat: 48.4457328, lng: 22.1325688 },
    { lat: 48.4457084, lng: 22.1326335 },
    { lat: 48.4453112, lng: 22.1328675 },
    { lat: 48.4452462, lng: 22.1327774 },
    { lat: 48.444649, lng: 22.1331768 },
    { lat: 48.4445093, lng: 22.1321525 },
    { lat: 48.4438877, lng: 22.1314492 },
    { lat: 48.441889, lng: 22.1295534 },
    { lat: 48.4403356, lng: 22.1278301 },
    { lat: 48.4397951, lng: 22.1273253 },
    { lat: 48.4391954, lng: 22.1269869 },
    { lat: 48.4387145, lng: 22.1265331 },
    { lat: 48.4376064, lng: 22.1252943 },
    { lat: 48.4373437, lng: 22.1249274 },
    { lat: 48.4371686, lng: 22.1245601 },
    { lat: 48.4368797, lng: 22.1234206 },
    { lat: 48.4367673, lng: 22.1227631 },
    { lat: 48.4368199, lng: 22.1219816 },
    { lat: 48.4371335, lng: 22.1213553 },
    { lat: 48.4373097, lng: 22.1214274 },
    { lat: 48.4374763, lng: 22.1217182 },
    { lat: 48.438296, lng: 22.1206501 },
    { lat: 48.4380677, lng: 22.1193143 },
    { lat: 48.438329, lng: 22.1191388 },
    { lat: 48.4387028, lng: 22.1185868 },
    { lat: 48.4389901, lng: 22.1183111 },
    { lat: 48.4387411, lng: 22.118059 },
    { lat: 48.4385623, lng: 22.117675 },
    { lat: 48.4384125, lng: 22.1171508 },
    { lat: 48.4381625, lng: 22.1152763 },
    { lat: 48.4379618, lng: 22.1143556 },
    { lat: 48.437606, lng: 22.1135325 },
    { lat: 48.4370551, lng: 22.1124938 },
    { lat: 48.4368797, lng: 22.1118723 },
    { lat: 48.4368469, lng: 22.1115336 },
    { lat: 48.4369811, lng: 22.1110797 },
    { lat: 48.4370945, lng: 22.1109192 },
    { lat: 48.4372618, lng: 22.1107643 },
    { lat: 48.4375868, lng: 22.1106237 },
    { lat: 48.4379649, lng: 22.1105712 },
    { lat: 48.4376881, lng: 22.1095236 },
    { lat: 48.4372971, lng: 22.1086886 },
    { lat: 48.4369648, lng: 22.108147 },
    { lat: 48.4367804, lng: 22.108064 },
    { lat: 48.436085, lng: 22.1079549 },
    { lat: 48.4358395, lng: 22.1079893 },
    { lat: 48.4359338, lng: 22.1072715 },
    { lat: 48.4341307, lng: 22.1051915 },
    { lat: 48.4333639, lng: 22.1044975 },
    { lat: 48.4334752, lng: 22.1033948 },
    { lat: 48.4333415, lng: 22.1009868 },
    { lat: 48.433079, lng: 22.099263 },
    { lat: 48.4330865, lng: 22.0977823 },
    { lat: 48.4331458, lng: 22.0967766 },
    { lat: 48.4331191, lng: 22.0958397 },
    { lat: 48.4328393, lng: 22.0959022 },
    { lat: 48.4321865, lng: 22.0942734 },
    { lat: 48.4320908, lng: 22.0943218 },
    { lat: 48.4316461, lng: 22.0945476 },
    { lat: 48.4319038, lng: 22.0973849 },
    { lat: 48.4292974, lng: 22.098479 },
    { lat: 48.4271046, lng: 22.0997877 },
    { lat: 48.427077, lng: 22.1005242 },
    { lat: 48.4268815, lng: 22.1009179 },
    { lat: 48.4266633, lng: 22.1012005 },
    { lat: 48.4264562, lng: 22.1011918 },
    { lat: 48.4257877, lng: 22.1032237 },
    { lat: 48.4258299, lng: 22.1032512 },
    { lat: 48.4257948, lng: 22.1033817 },
    { lat: 48.4257463, lng: 22.1033747 },
    { lat: 48.4253404, lng: 22.1050473 },
    { lat: 48.4251649, lng: 22.106614 },
    { lat: 48.4252358, lng: 22.10966 },
    { lat: 48.4253092, lng: 22.1102262 },
    { lat: 48.4253351, lng: 22.1106194 },
    { lat: 48.4254746, lng: 22.1111457 },
    { lat: 48.425451, lng: 22.1112423 },
    { lat: 48.4254952, lng: 22.1114482 },
    { lat: 48.4290914, lng: 22.1234998 },
    { lat: 48.4291497, lng: 22.1239299 },
    { lat: 48.4294303, lng: 22.1259998 },
    { lat: 48.4301124, lng: 22.1289347 },
    { lat: 48.4298794, lng: 22.1290324 },
    { lat: 48.4292659, lng: 22.1294861 },
    { lat: 48.4288183, lng: 22.1296402 },
    { lat: 48.4282657, lng: 22.1296302 },
    { lat: 48.4277755, lng: 22.1294012 },
    { lat: 48.4273364, lng: 22.1289534 },
    { lat: 48.4270036, lng: 22.1283338 },
    { lat: 48.4267392, lng: 22.1272832 },
    { lat: 48.4267024, lng: 22.1266289 },
    { lat: 48.4238136, lng: 22.1286925 },
    { lat: 48.4238718, lng: 22.1299234 },
    { lat: 48.4242305, lng: 22.1314945 },
    { lat: 48.4244136, lng: 22.1328186 },
    { lat: 48.4253788, lng: 22.1364268 },
    { lat: 48.4252331, lng: 22.1368291 },
    { lat: 48.42504, lng: 22.137889 },
    { lat: 48.428514, lng: 22.14024 },
    { lat: 48.4291105, lng: 22.1398387 },
    { lat: 48.4317286, lng: 22.1380771 },
    { lat: 48.431748, lng: 22.138064 },
    { lat: 48.4319547, lng: 22.1379783 },
    { lat: 48.4326134, lng: 22.1377054 },
    { lat: 48.4326517, lng: 22.1376912 },
    { lat: 48.4352628, lng: 22.1381955 },
    { lat: 48.4353331, lng: 22.1382091 },
    { lat: 48.4464001, lng: 22.1403565 },
    { lat: 48.4464205, lng: 22.1403604 },
    { lat: 48.4476677, lng: 22.1405621 },
    { lat: 48.4476704, lng: 22.1402907 },
    { lat: 48.446822, lng: 22.138539 },
    { lat: 48.446825, lng: 22.13789 },
    { lat: 48.447061, lng: 22.137023 },
    { lat: 48.447272, lng: 22.136189 },
    { lat: 48.447565, lng: 22.135556 },
    { lat: 48.447754, lng: 22.135063 },
    { lat: 48.448017, lng: 22.134631 },
    { lat: 48.448266, lng: 22.134424 },
  ],
  MalýHoreš: [
    { lat: 48.37457, lng: 21.939243 },
    { lat: 48.374548, lng: 21.93949 },
    { lat: 48.374512, lng: 21.940765 },
    { lat: 48.374612, lng: 21.941933 },
    { lat: 48.374801, lng: 21.943257 },
    { lat: 48.374912, lng: 21.943795 },
    { lat: 48.374979, lng: 21.944488 },
    { lat: 48.375069, lng: 21.944729 },
    { lat: 48.375148, lng: 21.945114 },
    { lat: 48.375093, lng: 21.945181 },
    { lat: 48.375097, lng: 21.945294 },
    { lat: 48.375035, lng: 21.945628 },
    { lat: 48.3749648, lng: 21.9460227 },
    { lat: 48.374917, lng: 21.946292 },
    { lat: 48.374989, lng: 21.946404 },
    { lat: 48.375036, lng: 21.946397 },
    { lat: 48.375969, lng: 21.9468 },
    { lat: 48.376472, lng: 21.946871 },
    { lat: 48.376768, lng: 21.947101 },
    { lat: 48.377559, lng: 21.947604 },
    { lat: 48.378018, lng: 21.948 },
    { lat: 48.378728, lng: 21.949198 },
    { lat: 48.378763, lng: 21.949321 },
    { lat: 48.378778, lng: 21.949719 },
    { lat: 48.378812, lng: 21.949887 },
    { lat: 48.379482, lng: 21.952526 },
    { lat: 48.379507, lng: 21.953207 },
    { lat: 48.379571, lng: 21.954969 },
    { lat: 48.379634, lng: 21.956692 },
    { lat: 48.379642, lng: 21.956894 },
    { lat: 48.378518, lng: 21.957556 },
    { lat: 48.378621, lng: 21.957958 },
    { lat: 48.378419, lng: 21.958168 },
    { lat: 48.378064, lng: 21.958452 },
    { lat: 48.3779, lng: 21.958622 },
    { lat: 48.377623, lng: 21.958954 },
    { lat: 48.377384, lng: 21.959241 },
    { lat: 48.37723, lng: 21.959712 },
    { lat: 48.37712, lng: 21.960215 },
    { lat: 48.377132, lng: 21.9606 },
    { lat: 48.377134, lng: 21.961044 },
    { lat: 48.378002, lng: 21.961395 },
    { lat: 48.37822, lng: 21.961477 },
    { lat: 48.378435, lng: 21.961514 },
    { lat: 48.379593, lng: 21.961156 },
    { lat: 48.3798691, lng: 21.9609967 },
    { lat: 48.3804262, lng: 21.9608291 },
    { lat: 48.3815781, lng: 21.9609996 },
    { lat: 48.3818699, lng: 21.9609318 },
    { lat: 48.3824525, lng: 21.9604248 },
    { lat: 48.3822833, lng: 21.9583764 },
    { lat: 48.383093, lng: 21.9583283 },
    { lat: 48.3833161, lng: 21.9610851 },
    { lat: 48.3834299, lng: 21.9616318 },
    { lat: 48.3852693, lng: 21.9609072 },
    { lat: 48.3851237, lng: 21.9600794 },
    { lat: 48.3858144, lng: 21.9598533 },
    { lat: 48.3861435, lng: 21.9614544 },
    { lat: 48.3864986, lng: 21.9621582 },
    { lat: 48.3868093, lng: 21.9622147 },
    { lat: 48.3879745, lng: 21.9623303 },
    { lat: 48.3881403, lng: 21.9622601 },
    { lat: 48.389221, lng: 21.9613838 },
    { lat: 48.3890934, lng: 21.9608243 },
    { lat: 48.3895067, lng: 21.9605327 },
    { lat: 48.3896184, lng: 21.9609916 },
    { lat: 48.3905087, lng: 21.9605531 },
    { lat: 48.3908615, lng: 21.9615771 },
    { lat: 48.3921037, lng: 21.961492 },
    { lat: 48.3921147, lng: 21.9617558 },
    { lat: 48.3947153, lng: 21.9619542 },
    { lat: 48.3953682, lng: 21.9619323 },
    { lat: 48.3957161, lng: 21.9613517 },
    { lat: 48.3959243, lng: 21.9611757 },
    { lat: 48.3966558, lng: 21.9610035 },
    { lat: 48.3967789, lng: 21.9610471 },
    { lat: 48.3970406, lng: 21.9611398 },
    { lat: 48.3971103, lng: 21.9629406 },
    { lat: 48.3972169, lng: 21.963649 },
    { lat: 48.3972862, lng: 21.9639032 },
    { lat: 48.3979304, lng: 21.9653771 },
    { lat: 48.3982531, lng: 21.9663219 },
    { lat: 48.3985375, lng: 21.9711886 },
    { lat: 48.4003908, lng: 21.9710701 },
    { lat: 48.400465, lng: 21.9708893 },
    { lat: 48.4007807, lng: 21.971135 },
    { lat: 48.4011194, lng: 21.9712767 },
    { lat: 48.4015997, lng: 21.9713167 },
    { lat: 48.4028312, lng: 21.970947 },
    { lat: 48.4036955, lng: 21.970406 },
    { lat: 48.4037674, lng: 21.9706332 },
    { lat: 48.4083616, lng: 21.96768 },
    { lat: 48.4085454, lng: 21.9669746 },
    { lat: 48.4085415, lng: 21.9665924 },
    { lat: 48.4084479, lng: 21.9658134 },
    { lat: 48.408604, lng: 21.9650769 },
    { lat: 48.4086911, lng: 21.9641068 },
    { lat: 48.408663, lng: 21.9628025 },
    { lat: 48.4087454, lng: 21.9625436 },
    { lat: 48.4087416, lng: 21.9622503 },
    { lat: 48.408929, lng: 21.9613779 },
    { lat: 48.4087386, lng: 21.9605986 },
    { lat: 48.4086764, lng: 21.9596833 },
    { lat: 48.4086604, lng: 21.9590903 },
    { lat: 48.408774, lng: 21.9589954 },
    { lat: 48.4084342, lng: 21.9577834 },
    { lat: 48.4091141, lng: 21.9570741 },
    { lat: 48.4094863, lng: 21.9565265 },
    { lat: 48.4090829, lng: 21.9558269 },
    { lat: 48.4086559, lng: 21.9553057 },
    { lat: 48.4093846, lng: 21.9548461 },
    { lat: 48.4093492, lng: 21.9546908 },
    { lat: 48.411337, lng: 21.9539207 },
    { lat: 48.4112306, lng: 21.9531792 },
    { lat: 48.4113999, lng: 21.9530209 },
    { lat: 48.4112652, lng: 21.9520395 },
    { lat: 48.4119838, lng: 21.9515775 },
    { lat: 48.4125203, lng: 21.9509466 },
    { lat: 48.4125092, lng: 21.9508134 },
    { lat: 48.4136596, lng: 21.9502547 },
    { lat: 48.4138016, lng: 21.950138 },
    { lat: 48.4138829, lng: 21.9499205 },
    { lat: 48.4142174, lng: 21.9497593 },
    { lat: 48.4143803, lng: 21.9505947 },
    { lat: 48.4145019, lng: 21.9505549 },
    { lat: 48.4145325, lng: 21.9508835 },
    { lat: 48.4147319, lng: 21.9512469 },
    { lat: 48.4149234, lng: 21.9512149 },
    { lat: 48.4149501, lng: 21.9512916 },
    { lat: 48.4152861, lng: 21.9511922 },
    { lat: 48.4153118, lng: 21.9517195 },
    { lat: 48.4156563, lng: 21.9516848 },
    { lat: 48.4156647, lng: 21.9520172 },
    { lat: 48.4157136, lng: 21.9520407 },
    { lat: 48.4156937, lng: 21.9522816 },
    { lat: 48.4159727, lng: 21.9522786 },
    { lat: 48.4160715, lng: 21.9520042 },
    { lat: 48.416881, lng: 21.9522088 },
    { lat: 48.4168839, lng: 21.9522806 },
    { lat: 48.4171462, lng: 21.952332 },
    { lat: 48.4171611, lng: 21.9525695 },
    { lat: 48.4175301, lng: 21.9524764 },
    { lat: 48.4175275, lng: 21.9522803 },
    { lat: 48.417774, lng: 21.9522363 },
    { lat: 48.4183238, lng: 21.9523133 },
    { lat: 48.4186711, lng: 21.9521743 },
    { lat: 48.4187366, lng: 21.9520895 },
    { lat: 48.4191093, lng: 21.952061 },
    { lat: 48.4193858, lng: 21.9521339 },
    { lat: 48.4198665, lng: 21.9524156 },
    { lat: 48.420758, lng: 21.952215 },
    { lat: 48.421624, lng: 21.9522455 },
    { lat: 48.4224343, lng: 21.9521249 },
    { lat: 48.4230599, lng: 21.9522157 },
    { lat: 48.4233077, lng: 21.9523214 },
    { lat: 48.4233838, lng: 21.9518953 },
    { lat: 48.4235299, lng: 21.9514418 },
    { lat: 48.4235932, lng: 21.950823 },
    { lat: 48.4239302, lng: 21.9502252 },
    { lat: 48.4237562, lng: 21.9493417 },
    { lat: 48.423701, lng: 21.948391 },
    { lat: 48.4238444, lng: 21.9477435 },
    { lat: 48.4240294, lng: 21.9471752 },
    { lat: 48.4244821, lng: 21.9450439 },
    { lat: 48.4246269, lng: 21.9441374 },
    { lat: 48.4246533, lng: 21.9437059 },
    { lat: 48.4241502, lng: 21.9408799 },
    { lat: 48.4239232, lng: 21.9391338 },
    { lat: 48.4239076, lng: 21.9390136 },
    { lat: 48.4223043, lng: 21.9395006 },
    { lat: 48.4219938, lng: 21.9391442 },
    { lat: 48.4198562, lng: 21.9396756 },
    { lat: 48.4196561, lng: 21.9397734 },
    { lat: 48.4185107, lng: 21.9398964 },
    { lat: 48.4178212, lng: 21.9400878 },
    { lat: 48.4158209, lng: 21.9399966 },
    { lat: 48.4156796, lng: 21.9394784 },
    { lat: 48.4147313, lng: 21.936708 },
    { lat: 48.4145706, lng: 21.9367203 },
    { lat: 48.4145523, lng: 21.9366583 },
    { lat: 48.4143646, lng: 21.9367614 },
    { lat: 48.4140908, lng: 21.9359787 },
    { lat: 48.4137576, lng: 21.9361531 },
    { lat: 48.4132628, lng: 21.9362959 },
    { lat: 48.4124834, lng: 21.9363972 },
    { lat: 48.41206, lng: 21.936286 },
    { lat: 48.4117527, lng: 21.935569 },
    { lat: 48.4109264, lng: 21.9347459 },
    { lat: 48.4105464, lng: 21.934532 },
    { lat: 48.4102062, lng: 21.9346919 },
    { lat: 48.4099895, lng: 21.9339475 },
    { lat: 48.4093973, lng: 21.9343335 },
    { lat: 48.4089108, lng: 21.9338672 },
    { lat: 48.4082103, lng: 21.933435 },
    { lat: 48.4079772, lng: 21.9333799 },
    { lat: 48.4076584, lng: 21.9325171 },
    { lat: 48.4075065, lng: 21.9318976 },
    { lat: 48.4073925, lng: 21.9316962 },
    { lat: 48.4073509, lng: 21.9316554 },
    { lat: 48.4070058, lng: 21.9317104 },
    { lat: 48.4063758, lng: 21.9315781 },
    { lat: 48.4061878, lng: 21.9320689 },
    { lat: 48.4058046, lng: 21.9315511 },
    { lat: 48.40568, lng: 21.9317352 },
    { lat: 48.4039034, lng: 21.9316882 },
    { lat: 48.4034029, lng: 21.9318757 },
    { lat: 48.4021066, lng: 21.9325332 },
    { lat: 48.4016509, lng: 21.9327971 },
    { lat: 48.4012104, lng: 21.9346412 },
    { lat: 48.3984038, lng: 21.9362734 },
    { lat: 48.3977717, lng: 21.9369925 },
    { lat: 48.3975557, lng: 21.9371291 },
    { lat: 48.3972609, lng: 21.936094 },
    { lat: 48.3970608, lng: 21.9348539 },
    { lat: 48.3969, lng: 21.9334314 },
    { lat: 48.3966551, lng: 21.9333094 },
    { lat: 48.3966836, lng: 21.9327 },
    { lat: 48.3965122, lng: 21.9317303 },
    { lat: 48.3964501, lng: 21.931379 },
    { lat: 48.3961387, lng: 21.9304097 },
    { lat: 48.395664, lng: 21.9296087 },
    { lat: 48.395153, lng: 21.9293779 },
    { lat: 48.3943166, lng: 21.9294018 },
    { lat: 48.3928113, lng: 21.9302949 },
    { lat: 48.3915679, lng: 21.9312202 },
    { lat: 48.390652, lng: 21.9315285 },
    { lat: 48.3902543, lng: 21.9315101 },
    { lat: 48.389842, lng: 21.9313063 },
    { lat: 48.3894249, lng: 21.9309208 },
    { lat: 48.387858, lng: 21.929236 },
    { lat: 48.3871005, lng: 21.9288253 },
    { lat: 48.3860362, lng: 21.9285953 },
    { lat: 48.3855512, lng: 21.9286399 },
    { lat: 48.3843963, lng: 21.9288844 },
    { lat: 48.3831531, lng: 21.9288815 },
    { lat: 48.3820374, lng: 21.9286254 },
    { lat: 48.381365, lng: 21.9281923 },
    { lat: 48.380464, lng: 21.9274388 },
    { lat: 48.3789821, lng: 21.9255198 },
    { lat: 48.3778855, lng: 21.924602 },
    { lat: 48.3773125, lng: 21.9243169 },
    { lat: 48.3763285, lng: 21.9243376 },
    { lat: 48.3752672, lng: 21.9248047 },
    { lat: 48.3734456, lng: 21.9257411 },
    { lat: 48.3732341, lng: 21.925918 },
    { lat: 48.3730072, lng: 21.9264635 },
    { lat: 48.3730114, lng: 21.9268054 },
    { lat: 48.3731402, lng: 21.9271466 },
    { lat: 48.3745337, lng: 21.9289073 },
    { lat: 48.3756797, lng: 21.9300948 },
    { lat: 48.375938, lng: 21.9304347 },
    { lat: 48.3761268, lng: 21.930872 },
    { lat: 48.3763005, lng: 21.9315076 },
    { lat: 48.3762734, lng: 21.9323566 },
    { lat: 48.3758875, lng: 21.9333757 },
    { lat: 48.3757679, lng: 21.9335906 },
    { lat: 48.3753967, lng: 21.9339949 },
    { lat: 48.3744753, lng: 21.9346216 },
    { lat: 48.374259, lng: 21.9348313 },
    { lat: 48.3740606, lng: 21.9351243 },
    { lat: 48.3739938, lng: 21.9353375 },
    { lat: 48.3740077, lng: 21.9357939 },
    { lat: 48.3740945, lng: 21.9360661 },
    { lat: 48.3743313, lng: 21.9363615 },
    { lat: 48.3745908, lng: 21.9365266 },
    { lat: 48.3749058, lng: 21.9365678 },
    { lat: 48.3753761, lng: 21.9365673 },
    { lat: 48.3759828, lng: 21.9364472 },
    { lat: 48.3769547, lng: 21.9358177 },
    { lat: 48.3782023, lng: 21.9351895 },
    { lat: 48.378923, lng: 21.9351723 },
    { lat: 48.3792208, lng: 21.935332 },
    { lat: 48.3794213, lng: 21.9355687 },
    { lat: 48.3794988, lng: 21.9358771 },
    { lat: 48.3794498, lng: 21.9362055 },
    { lat: 48.3790644, lng: 21.9369387 },
    { lat: 48.3778267, lng: 21.9382786 },
    { lat: 48.3774273, lng: 21.9385929 },
    { lat: 48.3770082, lng: 21.9387936 },
    { lat: 48.3758927, lng: 21.9391251 },
    { lat: 48.3747709, lng: 21.939206 },
    { lat: 48.37457, lng: 21.939243 },
  ],
  MalýHorešExt: [
    { lat: 48.4201454, lng: 21.8492144 },
    { lat: 48.4200362, lng: 21.8491323 },
    { lat: 48.4196192, lng: 21.8483591 },
    { lat: 48.4195472, lng: 21.8483201 },
    { lat: 48.418949, lng: 21.8485088 },
    { lat: 48.4184888, lng: 21.8484153 },
    { lat: 48.4184616, lng: 21.8485848 },
    { lat: 48.4184023, lng: 21.8486361 },
    { lat: 48.417825, lng: 21.8489837 },
    { lat: 48.4176011, lng: 21.8490708 },
    { lat: 48.4173415, lng: 21.8492503 },
    { lat: 48.4172436, lng: 21.8494653 },
    { lat: 48.4171434, lng: 21.8500311 },
    { lat: 48.4170914, lng: 21.8500257 },
    { lat: 48.4170946, lng: 21.8510568 },
    { lat: 48.4169834, lng: 21.8518443 },
    { lat: 48.4168716, lng: 21.8520874 },
    { lat: 48.415514, lng: 21.8537583 },
    { lat: 48.4143161, lng: 21.8549425 },
    { lat: 48.4132207, lng: 21.8555702 },
    { lat: 48.4116429, lng: 21.8558925 },
    { lat: 48.4116337, lng: 21.8561034 },
    { lat: 48.4062618, lng: 21.8583425 },
    { lat: 48.4046719, lng: 21.8593749 },
    { lat: 48.4047099, lng: 21.8601977 },
    { lat: 48.4047331, lng: 21.8607031 },
    { lat: 48.4049349, lng: 21.860549 },
    { lat: 48.4042554, lng: 21.8662531 },
    { lat: 48.4040236, lng: 21.8691339 },
    { lat: 48.4037411, lng: 21.8714932 },
    { lat: 48.4037395, lng: 21.872302 },
    { lat: 48.4035067, lng: 21.8734442 },
    { lat: 48.4032798, lng: 21.8741521 },
    { lat: 48.4027694, lng: 21.8760968 },
    { lat: 48.402721, lng: 21.8768832 },
    { lat: 48.402648, lng: 21.8772018 },
    { lat: 48.4022559, lng: 21.8784457 },
    { lat: 48.400995, lng: 21.8828803 },
    { lat: 48.4002646, lng: 21.8859903 },
    { lat: 48.3992278, lng: 21.8896564 },
    { lat: 48.3990566, lng: 21.8925981 },
    { lat: 48.3992238, lng: 21.8942605 },
    { lat: 48.3993278, lng: 21.8969183 },
    { lat: 48.3993551, lng: 21.8977693 },
    { lat: 48.3993084, lng: 21.8995988 },
    { lat: 48.3993524, lng: 21.9011483 },
    { lat: 48.3996764, lng: 21.9029162 },
    { lat: 48.400072, lng: 21.9028998 },
    { lat: 48.4003898, lng: 21.9030459 },
    { lat: 48.4010133, lng: 21.9036381 },
    { lat: 48.4012839, lng: 21.9039678 },
    { lat: 48.4024338, lng: 21.9048545 },
    { lat: 48.4027344, lng: 21.9049913 },
    { lat: 48.4034645, lng: 21.9051671 },
    { lat: 48.4044021, lng: 21.9055856 },
    { lat: 48.4048099, lng: 21.9056943 },
    { lat: 48.4061936, lng: 21.9055586 },
    { lat: 48.4065829, lng: 21.9057731 },
    { lat: 48.4070963, lng: 21.9058821 },
    { lat: 48.4074065, lng: 21.9054699 },
    { lat: 48.4078935, lng: 21.9050708 },
    { lat: 48.4086849, lng: 21.9052162 },
    { lat: 48.4093798, lng: 21.9052489 },
    { lat: 48.4096867, lng: 21.9052848 },
    { lat: 48.4097401, lng: 21.9052644 },
    { lat: 48.4102406, lng: 21.9050759 },
    { lat: 48.4122119, lng: 21.9057882 },
    { lat: 48.4131644, lng: 21.9059082 },
    { lat: 48.4140416, lng: 21.9065098 },
    { lat: 48.4153777, lng: 21.90583 },
    { lat: 48.4161693, lng: 21.9057686 },
    { lat: 48.4167977, lng: 21.9053493 },
    { lat: 48.4175846, lng: 21.9051037 },
    { lat: 48.4188821, lng: 21.9041013 },
    { lat: 48.4189397, lng: 21.904245 },
    { lat: 48.4205901, lng: 21.9032218 },
    { lat: 48.4207831, lng: 21.903033 },
    { lat: 48.4206335, lng: 21.9027214 },
    { lat: 48.4204414, lng: 21.9021569 },
    { lat: 48.420405, lng: 21.9019245 },
    { lat: 48.4203237, lng: 21.901071 },
    { lat: 48.4203746, lng: 21.9003785 },
    { lat: 48.4198785, lng: 21.8978144 },
    { lat: 48.4196072, lng: 21.8961325 },
    { lat: 48.419107, lng: 21.8941212 },
    { lat: 48.4211444, lng: 21.8936582 },
    { lat: 48.4212512, lng: 21.8936339 },
    { lat: 48.422238, lng: 21.892276 },
    { lat: 48.4229856, lng: 21.8913697 },
    { lat: 48.4231882, lng: 21.8913272 },
    { lat: 48.4244021, lng: 21.8915969 },
    { lat: 48.4243687, lng: 21.891432 },
    { lat: 48.4238923, lng: 21.8896091 },
    { lat: 48.4233569, lng: 21.8878127 },
    { lat: 48.422844, lng: 21.8865363 },
    { lat: 48.422176, lng: 21.8843506 },
    { lat: 48.4221402, lng: 21.8840927 },
    { lat: 48.4221608, lng: 21.8838496 },
    { lat: 48.4223068, lng: 21.8831327 },
    { lat: 48.4222599, lng: 21.8818741 },
    { lat: 48.4221155, lng: 21.8800595 },
    { lat: 48.4220432, lng: 21.8782076 },
    { lat: 48.4217262, lng: 21.8752834 },
    { lat: 48.4216098, lng: 21.8748265 },
    { lat: 48.4213182, lng: 21.873591 },
    { lat: 48.421119, lng: 21.8719757 },
    { lat: 48.4207543, lng: 21.8720522 },
    { lat: 48.4207486, lng: 21.8708302 },
    { lat: 48.4209195, lng: 21.8695101 },
    { lat: 48.4207958, lng: 21.8687264 },
    { lat: 48.420495, lng: 21.8676647 },
    { lat: 48.4206332, lng: 21.8670442 },
    { lat: 48.4205851, lng: 21.8670077 },
    { lat: 48.4207794, lng: 21.8663419 },
    { lat: 48.4212443, lng: 21.8653454 },
    { lat: 48.4214577, lng: 21.8642403 },
    { lat: 48.4216131, lng: 21.8638136 },
    { lat: 48.4220744, lng: 21.8633323 },
    { lat: 48.4223783, lng: 21.8627305 },
    { lat: 48.4229094, lng: 21.8622073 },
    { lat: 48.4230294, lng: 21.861981 },
    { lat: 48.423358, lng: 21.8611086 },
    { lat: 48.4238923, lng: 21.859317 },
    { lat: 48.4240835, lng: 21.8584026 },
    { lat: 48.4243089, lng: 21.85759 },
    { lat: 48.4245994, lng: 21.8570739 },
    { lat: 48.4248552, lng: 21.8568737 },
    { lat: 48.425075, lng: 21.8568474 },
    { lat: 48.4254448, lng: 21.8565258 },
    { lat: 48.4256181, lng: 21.856272 },
    { lat: 48.425639, lng: 21.8560777 },
    { lat: 48.4256887, lng: 21.856084 },
    { lat: 48.4256933, lng: 21.8557402 },
    { lat: 48.4256466, lng: 21.8555849 },
    { lat: 48.4250474, lng: 21.8545597 },
    { lat: 48.4249683, lng: 21.854072 },
    { lat: 48.4247004, lng: 21.851064 },
    { lat: 48.4246004, lng: 21.850637 },
    { lat: 48.4246767, lng: 21.8506339 },
    { lat: 48.4245512, lng: 21.8504762 },
    { lat: 48.4244367, lng: 21.8506129 },
    { lat: 48.4242398, lng: 21.8505327 },
    { lat: 48.4236998, lng: 21.8506999 },
    { lat: 48.4224078, lng: 21.8501259 },
    { lat: 48.421876, lng: 21.8502271 },
    { lat: 48.4214304, lng: 21.850091 },
    { lat: 48.4205627, lng: 21.849513 },
    { lat: 48.4201454, lng: 21.8492144 },
  ],
  Vojka: [
    { lat: 48.4347351, lng: 21.8948319 },
    { lat: 48.4346534, lng: 21.8958591 },
    { lat: 48.4343515, lng: 21.8996828 },
    { lat: 48.4360947, lng: 21.8995641 },
    { lat: 48.435636, lng: 21.9113514 },
    { lat: 48.4406055, lng: 21.908829 },
    { lat: 48.4424169, lng: 21.9165962 },
    { lat: 48.447855, lng: 21.934946 },
    { lat: 48.4481496, lng: 21.9349424 },
    { lat: 48.4484138, lng: 21.9350585 },
    { lat: 48.4485293, lng: 21.9353598 },
    { lat: 48.4485375, lng: 21.9356372 },
    { lat: 48.4486809, lng: 21.9358835 },
    { lat: 48.4490816, lng: 21.936067 },
    { lat: 48.4492452, lng: 21.9360502 },
    { lat: 48.4496892, lng: 21.9357138 },
    { lat: 48.4500804, lng: 21.9355686 },
    { lat: 48.4505544, lng: 21.9355374 },
    { lat: 48.4504349, lng: 21.9361573 },
    { lat: 48.4505598, lng: 21.936118 },
    { lat: 48.4515787, lng: 21.935874 },
    { lat: 48.451531, lng: 21.9359454 },
    { lat: 48.4529394, lng: 21.9361624 },
    { lat: 48.4546885, lng: 21.9368692 },
    { lat: 48.4566197, lng: 21.9380092 },
    { lat: 48.4567389, lng: 21.9380074 },
    { lat: 48.4567457, lng: 21.9380637 },
    { lat: 48.4577385, lng: 21.9387013 },
    { lat: 48.4578811, lng: 21.9388704 },
    { lat: 48.4581671, lng: 21.9394269 },
    { lat: 48.458773, lng: 21.9392663 },
    { lat: 48.4596858, lng: 21.9392364 },
    { lat: 48.4611106, lng: 21.9393466 },
    { lat: 48.4611259, lng: 21.9392801 },
    { lat: 48.4616366, lng: 21.9395588 },
    { lat: 48.4617794, lng: 21.9390091 },
    { lat: 48.4621863, lng: 21.9381923 },
    { lat: 48.4623945, lng: 21.9379224 },
    { lat: 48.4633576, lng: 21.9373066 },
    { lat: 48.4635279, lng: 21.9370949 },
    { lat: 48.4638879, lng: 21.9368723 },
    { lat: 48.4644883, lng: 21.9363636 },
    { lat: 48.466403, lng: 21.9353763 },
    { lat: 48.4668451, lng: 21.9350243 },
    { lat: 48.46711, lng: 21.9346831 },
    { lat: 48.4672697, lng: 21.9340968 },
    { lat: 48.4672498, lng: 21.9338023 },
    { lat: 48.4670692, lng: 21.9334188 },
    { lat: 48.4668565, lng: 21.9331912 },
    { lat: 48.4666082, lng: 21.933076 },
    { lat: 48.4650177, lng: 21.9330421 },
    { lat: 48.4639009, lng: 21.932872 },
    { lat: 48.4630045, lng: 21.932436 },
    { lat: 48.4628193, lng: 21.9321421 },
    { lat: 48.4628033, lng: 21.9315046 },
    { lat: 48.4629418, lng: 21.9311922 },
    { lat: 48.4631891, lng: 21.9309215 },
    { lat: 48.4638705, lng: 21.9303722 },
    { lat: 48.4643493, lng: 21.9298582 },
    { lat: 48.4659227, lng: 21.9285488 },
    { lat: 48.4665635, lng: 21.9280819 },
    { lat: 48.4675367, lng: 21.9275503 },
    { lat: 48.4686342, lng: 21.9273962 },
    { lat: 48.469471, lng: 21.9274761 },
    { lat: 48.4699435, lng: 21.9276537 },
    { lat: 48.4704637, lng: 21.9279417 },
    { lat: 48.4707733, lng: 21.9282151 },
    { lat: 48.4714329, lng: 21.9289887 },
    { lat: 48.471692, lng: 21.9294022 },
    { lat: 48.4721519, lng: 21.9303754 },
    { lat: 48.4723679, lng: 21.931041 },
    { lat: 48.4725145, lng: 21.9317318 },
    { lat: 48.4726352, lng: 21.9334356 },
    { lat: 48.4726439, lng: 21.9346806 },
    { lat: 48.4730025, lng: 21.9355628 },
    { lat: 48.4733301, lng: 21.9357466 },
    { lat: 48.47393, lng: 21.9350881 },
    { lat: 48.4744982, lng: 21.9341077 },
    { lat: 48.4748787, lng: 21.933271 },
    { lat: 48.4752932, lng: 21.9317653 },
    { lat: 48.4754019, lng: 21.9309919 },
    { lat: 48.4754184, lng: 21.9304002 },
    { lat: 48.4753517, lng: 21.9294006 },
    { lat: 48.4750705, lng: 21.9283367 },
    { lat: 48.4735335, lng: 21.9255149 },
    { lat: 48.4733086, lng: 21.9246662 },
    { lat: 48.4730368, lng: 21.9243772 },
    { lat: 48.4723664, lng: 21.9228672 },
    { lat: 48.4720809, lng: 21.9223439 },
    { lat: 48.471886, lng: 21.9218456 },
    { lat: 48.4712469, lng: 21.9221991 },
    { lat: 48.4708876, lng: 21.9221781 },
    { lat: 48.4688101, lng: 21.9199279 },
    { lat: 48.4670493, lng: 21.9170327 },
    { lat: 48.465821, lng: 21.913624 },
    { lat: 48.4648762, lng: 21.9115899 },
    { lat: 48.4639361, lng: 21.9093893 },
    { lat: 48.4635284, lng: 21.9087466 },
    { lat: 48.4634004, lng: 21.908402 },
    { lat: 48.4632518, lng: 21.9073779 },
    { lat: 48.463152, lng: 21.9070423 },
    { lat: 48.4628224, lng: 21.9063359 },
    { lat: 48.4618812, lng: 21.9050256 },
    { lat: 48.4614909, lng: 21.9047145 },
    { lat: 48.4609591, lng: 21.9044647 },
    { lat: 48.4610579, lng: 21.9036618 },
    { lat: 48.4606848, lng: 21.9035454 },
    { lat: 48.4603944, lng: 21.9033243 },
    { lat: 48.4598902, lng: 21.9028237 },
    { lat: 48.4593853, lng: 21.9022151 },
    { lat: 48.4589599, lng: 21.9016398 },
    { lat: 48.4583662, lng: 21.9006727 },
    { lat: 48.4577741, lng: 21.8995101 },
    { lat: 48.4573438, lng: 21.8983436 },
    { lat: 48.457203, lng: 21.8977648 },
    { lat: 48.4570868, lng: 21.8968248 },
    { lat: 48.45711, lng: 21.8954855 },
    { lat: 48.457265, lng: 21.8941048 },
    { lat: 48.4575998, lng: 21.8926738 },
    { lat: 48.459274, lng: 21.8911835 },
    { lat: 48.4591966, lng: 21.8910146 },
    { lat: 48.4589625, lng: 21.8907095 },
    { lat: 48.458805, lng: 21.8903964 },
    { lat: 48.4585663, lng: 21.8896917 },
    { lat: 48.4571533, lng: 21.8905697 },
    { lat: 48.4567219, lng: 21.8908566 },
    { lat: 48.4566297, lng: 21.8911249 },
    { lat: 48.4555983, lng: 21.8919755 },
    { lat: 48.454494, lng: 21.8930926 },
    { lat: 48.453091, lng: 21.8947241 },
    { lat: 48.4526846, lng: 21.8952823 },
    { lat: 48.452222, lng: 21.8947044 },
    { lat: 48.4519498, lng: 21.894489 },
    { lat: 48.451698, lng: 21.8944507 },
    { lat: 48.4512565, lng: 21.8945187 },
    { lat: 48.4509225, lng: 21.8948353 },
    { lat: 48.4506595, lng: 21.8952428 },
    { lat: 48.4503402, lng: 21.8943617 },
    { lat: 48.450162, lng: 21.893514 },
    { lat: 48.4496336, lng: 21.8928634 },
    { lat: 48.4493712, lng: 21.8924571 },
    { lat: 48.4492931, lng: 21.8924 },
    { lat: 48.4490938, lng: 21.8924352 },
    { lat: 48.4488254, lng: 21.8926322 },
    { lat: 48.4484405, lng: 21.8923127 },
    { lat: 48.4480723, lng: 21.8921316 },
    { lat: 48.4473123, lng: 21.8914887 },
    { lat: 48.4469021, lng: 21.8909794 },
    { lat: 48.4466847, lng: 21.8914498 },
    { lat: 48.4455304, lng: 21.8928134 },
    { lat: 48.444203, lng: 21.8931327 },
    { lat: 48.4430891, lng: 21.8935215 },
    { lat: 48.4429663, lng: 21.8930782 },
    { lat: 48.4425921, lng: 21.8930966 },
    { lat: 48.4418749, lng: 21.8928559 },
    { lat: 48.4385645, lng: 21.8936219 },
    { lat: 48.4377334, lng: 21.8954017 },
    { lat: 48.4347351, lng: 21.8948319 },
  ],
  Čeľovce: [
    { lat: 48.6010926, lng: 21.5885976 },
    { lat: 48.6011638, lng: 21.5869279 },
    { lat: 48.6003891, lng: 21.5867439 },
    { lat: 48.6001154, lng: 21.586579 },
    { lat: 48.5999562, lng: 21.5866781 },
    { lat: 48.5998349, lng: 21.5866478 },
    { lat: 48.5996967, lng: 21.5868493 },
    { lat: 48.5995754, lng: 21.5868197 },
    { lat: 48.5995522, lng: 21.5869186 },
    { lat: 48.5990196, lng: 21.5870194 },
    { lat: 48.5987335, lng: 21.5867729 },
    { lat: 48.5984432, lng: 21.5868138 },
    { lat: 48.5983978, lng: 21.5870016 },
    { lat: 48.5983162, lng: 21.5870598 },
    { lat: 48.5980766, lng: 21.5869834 },
    { lat: 48.5979147, lng: 21.5870612 },
    { lat: 48.5978491, lng: 21.5868493 },
    { lat: 48.5975294, lng: 21.5867684 },
    { lat: 48.5974244, lng: 21.586917 },
    { lat: 48.597263, lng: 21.5867755 },
    { lat: 48.5972412, lng: 21.586682 },
    { lat: 48.5969992, lng: 21.5865599 },
    { lat: 48.5968286, lng: 21.5866899 },
    { lat: 48.5966946, lng: 21.5864521 },
    { lat: 48.5965681, lng: 21.5863574 },
    { lat: 48.5964291, lng: 21.5864527 },
    { lat: 48.5964173, lng: 21.5865533 },
    { lat: 48.596323, lng: 21.5865601 },
    { lat: 48.5959825, lng: 21.5868541 },
    { lat: 48.5957557, lng: 21.5868714 },
    { lat: 48.5957386, lng: 21.5869852 },
    { lat: 48.5956538, lng: 21.5869718 },
    { lat: 48.5956057, lng: 21.5870644 },
    { lat: 48.5955464, lng: 21.587033 },
    { lat: 48.5954517, lng: 21.5871048 },
    { lat: 48.5952553, lng: 21.5870456 },
    { lat: 48.5952389, lng: 21.5866649 },
    { lat: 48.5951942, lng: 21.5866347 },
    { lat: 48.5950677, lng: 21.5868567 },
    { lat: 48.5948202, lng: 21.5867002 },
    { lat: 48.5948305, lng: 21.586504 },
    { lat: 48.5947347, lng: 21.5864593 },
    { lat: 48.5946593, lng: 21.5863354 },
    { lat: 48.5945872, lng: 21.5864111 },
    { lat: 48.5945873, lng: 21.5862572 },
    { lat: 48.5945236, lng: 21.5862698 },
    { lat: 48.5944852, lng: 21.5861902 },
    { lat: 48.594158, lng: 21.5860334 },
    { lat: 48.5939515, lng: 21.5864098 },
    { lat: 48.5937108, lng: 21.5862212 },
    { lat: 48.5935759, lng: 21.5863851 },
    { lat: 48.5934668, lng: 21.58638 },
    { lat: 48.5934212, lng: 21.5864846 },
    { lat: 48.5933308, lng: 21.5864665 },
    { lat: 48.5932841, lng: 21.5863309 },
    { lat: 48.5931125, lng: 21.5863483 },
    { lat: 48.5930128, lng: 21.5864442 },
    { lat: 48.5929695, lng: 21.5863453 },
    { lat: 48.5928852, lng: 21.5863496 },
    { lat: 48.5928299, lng: 21.5864627 },
    { lat: 48.5926887, lng: 21.5865641 },
    { lat: 48.5924931, lng: 21.5865677 },
    { lat: 48.5923704, lng: 21.5868503 },
    { lat: 48.5921509, lng: 21.5869685 },
    { lat: 48.5919194, lng: 21.5868173 },
    { lat: 48.591616, lng: 21.5867538 },
    { lat: 48.5912804, lng: 21.5863825 },
    { lat: 48.5901245, lng: 21.5857239 },
    { lat: 48.5894026, lng: 21.585427 },
    { lat: 48.5879916, lng: 21.5851479 },
    { lat: 48.5876279, lng: 21.5851349 },
    { lat: 48.5875277, lng: 21.5851328 },
    { lat: 48.5868478, lng: 21.5851198 },
    { lat: 48.586015, lng: 21.5849939 },
    { lat: 48.5851867, lng: 21.5847543 },
    { lat: 48.584684, lng: 21.5846866 },
    { lat: 48.5828184, lng: 21.5851626 },
    { lat: 48.5829207, lng: 21.5857968 },
    { lat: 48.5829443, lng: 21.5878348 },
    { lat: 48.5838223, lng: 21.5905773 },
    { lat: 48.5845255, lng: 21.5941839 },
    { lat: 48.5849655, lng: 21.5954292 },
    { lat: 48.5856301, lng: 21.5976951 },
    { lat: 48.5869207, lng: 21.5998654 },
    { lat: 48.5871242, lng: 21.6004236 },
    { lat: 48.5879356, lng: 21.6037697 },
    { lat: 48.5881052, lng: 21.6053667 },
    { lat: 48.5884903, lng: 21.6063395 },
    { lat: 48.5886475, lng: 21.6070546 },
    { lat: 48.5887218, lng: 21.6072567 },
    { lat: 48.5888015, lng: 21.6073323 },
    { lat: 48.5889773, lng: 21.6079453 },
    { lat: 48.5891146, lng: 21.6081505 },
    { lat: 48.5891097, lng: 21.6082535 },
    { lat: 48.5892293, lng: 21.6084265 },
    { lat: 48.5892266, lng: 21.6085297 },
    { lat: 48.589307, lng: 21.6087087 },
    { lat: 48.5893995, lng: 21.609285 },
    { lat: 48.5892371, lng: 21.609515 },
    { lat: 48.5887315, lng: 21.6106254 },
    { lat: 48.5885084, lng: 21.6109796 },
    { lat: 48.5884983, lng: 21.612393 },
    { lat: 48.5884485, lng: 21.6126606 },
    { lat: 48.5881082, lng: 21.6133343 },
    { lat: 48.5881477, lng: 21.6137811 },
    { lat: 48.5880868, lng: 21.6138098 },
    { lat: 48.5881161, lng: 21.6138986 },
    { lat: 48.5880726, lng: 21.6139521 },
    { lat: 48.5881003, lng: 21.6140172 },
    { lat: 48.5880744, lng: 21.614381 },
    { lat: 48.5879575, lng: 21.6147882 },
    { lat: 48.5879538, lng: 21.6149786 },
    { lat: 48.588018, lng: 21.6152599 },
    { lat: 48.5881688, lng: 21.6153392 },
    { lat: 48.5882312, lng: 21.6154458 },
    { lat: 48.58839, lng: 21.616137 },
    { lat: 48.5886273, lng: 21.6161728 },
    { lat: 48.5887586, lng: 21.6162828 },
    { lat: 48.5891612, lng: 21.6162715 },
    { lat: 48.5893491, lng: 21.616432 },
    { lat: 48.5897872, lng: 21.6166531 },
    { lat: 48.5901554, lng: 21.6166561 },
    { lat: 48.5911246, lng: 21.6186931 },
    { lat: 48.5916404, lng: 21.6200886 },
    { lat: 48.5922716, lng: 21.6221077 },
    { lat: 48.5933883, lng: 21.6235414 },
    { lat: 48.59376, lng: 21.6241221 },
    { lat: 48.5946617, lng: 21.6264083 },
    { lat: 48.593714, lng: 21.6274065 },
    { lat: 48.5923721, lng: 21.6286854 },
    { lat: 48.5924419, lng: 21.6289266 },
    { lat: 48.5908827, lng: 21.6309235 },
    { lat: 48.5913843, lng: 21.6327458 },
    { lat: 48.5909244, lng: 21.6332855 },
    { lat: 48.5904776, lng: 21.6339676 },
    { lat: 48.5911585, lng: 21.6350312 },
    { lat: 48.5921627, lng: 21.636834 },
    { lat: 48.592781, lng: 21.6380821 },
    { lat: 48.5931671, lng: 21.6390598 },
    { lat: 48.593466, lng: 21.6401041 },
    { lat: 48.5933961, lng: 21.6401799 },
    { lat: 48.5946667, lng: 21.6463413 },
    { lat: 48.5949699, lng: 21.6474267 },
    { lat: 48.595221, lng: 21.6481286 },
    { lat: 48.5952343, lng: 21.6485653 },
    { lat: 48.5953413, lng: 21.6492997 },
    { lat: 48.5955027, lng: 21.651075 },
    { lat: 48.5958078, lng: 21.6518194 },
    { lat: 48.5960578, lng: 21.6526007 },
    { lat: 48.5967366, lng: 21.6554718 },
    { lat: 48.5970333, lng: 21.6563665 },
    { lat: 48.5976616, lng: 21.6576506 },
    { lat: 48.5980498, lng: 21.6589145 },
    { lat: 48.5998917, lng: 21.665926 },
    { lat: 48.6000409, lng: 21.6663618 },
    { lat: 48.6006273, lng: 21.6675168 },
    { lat: 48.6007536, lng: 21.6678563 },
    { lat: 48.6009629, lng: 21.6688019 },
    { lat: 48.6012564, lng: 21.6708135 },
    { lat: 48.6014884, lng: 21.6719252 },
    { lat: 48.6019541, lng: 21.6743911 },
    { lat: 48.6021404, lng: 21.6752835 },
    { lat: 48.6030468, lng: 21.678512 },
    { lat: 48.6033385, lng: 21.6788607 },
    { lat: 48.6036287, lng: 21.679099 },
    { lat: 48.6052989, lng: 21.6781314 },
    { lat: 48.6057119, lng: 21.6786643 },
    { lat: 48.6060947, lng: 21.6786793 },
    { lat: 48.6062744, lng: 21.6789924 },
    { lat: 48.6065253, lng: 21.6791584 },
    { lat: 48.606784, lng: 21.6791154 },
    { lat: 48.6070369, lng: 21.6789386 },
    { lat: 48.6073523, lng: 21.6782979 },
    { lat: 48.6067418, lng: 21.676042 },
    { lat: 48.6070452, lng: 21.6759133 },
    { lat: 48.6074012, lng: 21.6756352 },
    { lat: 48.607401, lng: 21.6755858 },
    { lat: 48.6085855, lng: 21.674965 },
    { lat: 48.6095636, lng: 21.6745191 },
    { lat: 48.6117553, lng: 21.6733727 },
    { lat: 48.6127051, lng: 21.6729631 },
    { lat: 48.6117554, lng: 21.6604375 },
    { lat: 48.6116121, lng: 21.658541 },
    { lat: 48.6104284, lng: 21.6566591 },
    { lat: 48.6108594, lng: 21.6564519 },
    { lat: 48.6108151, lng: 21.6541058 },
    { lat: 48.6108763, lng: 21.6492995 },
    { lat: 48.6114393, lng: 21.649154 },
    { lat: 48.6111979, lng: 21.6478632 },
    { lat: 48.6108775, lng: 21.6452657 },
    { lat: 48.6112963, lng: 21.6453187 },
    { lat: 48.611561, lng: 21.6452057 },
    { lat: 48.6113664, lng: 21.6437406 },
    { lat: 48.6111788, lng: 21.640243 },
    { lat: 48.6109389, lng: 21.638364 },
    { lat: 48.6114463, lng: 21.6382005 },
    { lat: 48.611011, lng: 21.6347928 },
    { lat: 48.6135331, lng: 21.6332228 },
    { lat: 48.6140179, lng: 21.6331844 },
    { lat: 48.6141644, lng: 21.6330653 },
    { lat: 48.6141818, lng: 21.6328448 },
    { lat: 48.6140939, lng: 21.6317409 },
    { lat: 48.6145352, lng: 21.6312178 },
    { lat: 48.6157487, lng: 21.6305528 },
    { lat: 48.6157371, lng: 21.6298878 },
    { lat: 48.6159118, lng: 21.6292165 },
    { lat: 48.616458, lng: 21.6291098 },
    { lat: 48.6167324, lng: 21.6289816 },
    { lat: 48.6170731, lng: 21.6286909 },
    { lat: 48.6172626, lng: 21.6279336 },
    { lat: 48.6173472, lng: 21.6270912 },
    { lat: 48.61721, lng: 21.626325 },
    { lat: 48.6168142, lng: 21.6251975 },
    { lat: 48.6166623, lng: 21.6241456 },
    { lat: 48.6167238, lng: 21.6224718 },
    { lat: 48.617397, lng: 21.6215886 },
    { lat: 48.6180519, lng: 21.6212741 },
    { lat: 48.6192048, lng: 21.6214561 },
    { lat: 48.6186079, lng: 21.6184445 },
    { lat: 48.6183938, lng: 21.6181874 },
    { lat: 48.6181842, lng: 21.61745 },
    { lat: 48.6179401, lng: 21.615916 },
    { lat: 48.6176985, lng: 21.6148791 },
    { lat: 48.617665, lng: 21.6148417 },
    { lat: 48.6171401, lng: 21.614545 },
    { lat: 48.617039, lng: 21.6145877 },
    { lat: 48.6169419, lng: 21.6148605 },
    { lat: 48.6164939, lng: 21.6147346 },
    { lat: 48.6163808, lng: 21.615118 },
    { lat: 48.6163274, lng: 21.6148931 },
    { lat: 48.6162604, lng: 21.6148738 },
    { lat: 48.6162049, lng: 21.6149823 },
    { lat: 48.6162563, lng: 21.6152944 },
    { lat: 48.6163251, lng: 21.6153775 },
    { lat: 48.6163238, lng: 21.6155761 },
    { lat: 48.6162167, lng: 21.61565 },
    { lat: 48.6161596, lng: 21.6156156 },
    { lat: 48.6160436, lng: 21.6157766 },
    { lat: 48.6159291, lng: 21.6157468 },
    { lat: 48.6157803, lng: 21.615938 },
    { lat: 48.6157096, lng: 21.6159421 },
    { lat: 48.6156536, lng: 21.6161138 },
    { lat: 48.6154922, lng: 21.6162965 },
    { lat: 48.6151223, lng: 21.616103 },
    { lat: 48.6149266, lng: 21.6160692 },
    { lat: 48.6149026, lng: 21.6161795 },
    { lat: 48.6151515, lng: 21.6165496 },
    { lat: 48.6151513, lng: 21.6166606 },
    { lat: 48.6150018, lng: 21.616947 },
    { lat: 48.6148384, lng: 21.6175501 },
    { lat: 48.614863, lng: 21.6176627 },
    { lat: 48.614996, lng: 21.6177887 },
    { lat: 48.6150453, lng: 21.6179421 },
    { lat: 48.6148899, lng: 21.6181884 },
    { lat: 48.614847, lng: 21.6183845 },
    { lat: 48.6147599, lng: 21.6185234 },
    { lat: 48.6144217, lng: 21.6185458 },
    { lat: 48.6142442, lng: 21.6186321 },
    { lat: 48.6141327, lng: 21.6189126 },
    { lat: 48.6137066, lng: 21.6193357 },
    { lat: 48.6138015, lng: 21.6189833 },
    { lat: 48.6137761, lng: 21.6187993 },
    { lat: 48.6135375, lng: 21.6182231 },
    { lat: 48.6135405, lng: 21.618096 },
    { lat: 48.6137673, lng: 21.61779 },
    { lat: 48.6138286, lng: 21.6173641 },
    { lat: 48.6140151, lng: 21.6173576 },
    { lat: 48.6140052, lng: 21.6171189 },
    { lat: 48.6137574, lng: 21.6170347 },
    { lat: 48.6137838, lng: 21.6168058 },
    { lat: 48.6140992, lng: 21.6167425 },
    { lat: 48.614193, lng: 21.6167948 },
    { lat: 48.6142691, lng: 21.6166556 },
    { lat: 48.6142268, lng: 21.6165185 },
    { lat: 48.614029, lng: 21.6162384 },
    { lat: 48.6141839, lng: 21.615984 },
    { lat: 48.6141496, lng: 21.6157596 },
    { lat: 48.614019, lng: 21.6157612 },
    { lat: 48.6139936, lng: 21.6156966 },
    { lat: 48.6141467, lng: 21.6154181 },
    { lat: 48.6130612, lng: 21.6129641 },
    { lat: 48.6124994, lng: 21.6134761 },
    { lat: 48.6124036, lng: 21.6133122 },
    { lat: 48.612436, lng: 21.6131392 },
    { lat: 48.612619, lng: 21.6130056 },
    { lat: 48.612682, lng: 21.612683 },
    { lat: 48.6126374, lng: 21.6125301 },
    { lat: 48.612486, lng: 21.6125144 },
    { lat: 48.6123261, lng: 21.6123073 },
    { lat: 48.6121391, lng: 21.6119348 },
    { lat: 48.6120844, lng: 21.6117075 },
    { lat: 48.6119572, lng: 21.6116601 },
    { lat: 48.6117805, lng: 21.6113428 },
    { lat: 48.6116618, lng: 21.6114001 },
    { lat: 48.611603, lng: 21.6115482 },
    { lat: 48.6114765, lng: 21.6116131 },
    { lat: 48.6113068, lng: 21.6118272 },
    { lat: 48.6113008, lng: 21.611493 },
    { lat: 48.6111137, lng: 21.6114359 },
    { lat: 48.6110021, lng: 21.6116289 },
    { lat: 48.6108386, lng: 21.6112951 },
    { lat: 48.6105599, lng: 21.6112257 },
    { lat: 48.6101548, lng: 21.6104585 },
    { lat: 48.609969, lng: 21.6102107 },
    { lat: 48.6093961, lng: 21.6096814 },
    { lat: 48.6083391, lng: 21.6085318 },
    { lat: 48.6077632, lng: 21.6074146 },
    { lat: 48.607289, lng: 21.6071923 },
    { lat: 48.6072877, lng: 21.6067476 },
    { lat: 48.6071515, lng: 21.6060178 },
    { lat: 48.6068158, lng: 21.6053177 },
    { lat: 48.6068396, lng: 21.6050809 },
    { lat: 48.6056495, lng: 21.6035673 },
    { lat: 48.6055041, lng: 21.6032345 },
    { lat: 48.6045229, lng: 21.6026299 },
    { lat: 48.6042594, lng: 21.6023391 },
    { lat: 48.6040335, lng: 21.6022724 },
    { lat: 48.6032582, lng: 21.6016633 },
    { lat: 48.6029243, lng: 21.6015196 },
    { lat: 48.6027657, lng: 21.6011948 },
    { lat: 48.6025518, lng: 21.6009461 },
    { lat: 48.6025237, lng: 21.6007943 },
    { lat: 48.6024251, lng: 21.6007657 },
    { lat: 48.6023363, lng: 21.6004838 },
    { lat: 48.6022283, lng: 21.6005258 },
    { lat: 48.6020517, lng: 21.6000891 },
    { lat: 48.6020571, lng: 21.5998755 },
    { lat: 48.6018112, lng: 21.5993717 },
    { lat: 48.6016877, lng: 21.5992221 },
    { lat: 48.6017507, lng: 21.5991388 },
    { lat: 48.6017298, lng: 21.5987967 },
    { lat: 48.6015047, lng: 21.5984371 },
    { lat: 48.6013227, lng: 21.5982457 },
    { lat: 48.6011545, lng: 21.5979044 },
    { lat: 48.6009977, lng: 21.5977697 },
    { lat: 48.6009914, lng: 21.5975871 },
    { lat: 48.6006178, lng: 21.5973073 },
    { lat: 48.6007789, lng: 21.5969028 },
    { lat: 48.6006802, lng: 21.5963616 },
    { lat: 48.6011138, lng: 21.594399 },
    { lat: 48.6011207, lng: 21.5934878 },
    { lat: 48.6012, lng: 21.5927074 },
    { lat: 48.601013, lng: 21.5902885 },
    { lat: 48.6010926, lng: 21.5885976 },
  ],
  Stankovce: [
    { lat: 48.7593331, lng: 21.6587033 },
    { lat: 48.758864, lng: 21.6599373 },
    { lat: 48.7584121, lng: 21.6617967 },
    { lat: 48.7583917, lng: 21.6622412 },
    { lat: 48.7578954, lng: 21.6633887 },
    { lat: 48.7579345, lng: 21.663878 },
    { lat: 48.7580035, lng: 21.6640556 },
    { lat: 48.7581574, lng: 21.6642513 },
    { lat: 48.7581237, lng: 21.6644599 },
    { lat: 48.7582812, lng: 21.664372 },
    { lat: 48.758306, lng: 21.6645377 },
    { lat: 48.7582028, lng: 21.6646796 },
    { lat: 48.7584235, lng: 21.6652128 },
    { lat: 48.758399, lng: 21.6653096 },
    { lat: 48.7585339, lng: 21.6654652 },
    { lat: 48.758542, lng: 21.6658048 },
    { lat: 48.7584235, lng: 21.6658771 },
    { lat: 48.7584373, lng: 21.6660881 },
    { lat: 48.7585142, lng: 21.6661874 },
    { lat: 48.7585262, lng: 21.6664181 },
    { lat: 48.7586055, lng: 21.6665258 },
    { lat: 48.7585303, lng: 21.6666539 },
    { lat: 48.7583108, lng: 21.6667735 },
    { lat: 48.7581973, lng: 21.6673947 },
    { lat: 48.7580576, lng: 21.6674312 },
    { lat: 48.758097, lng: 21.6677186 },
    { lat: 48.7580288, lng: 21.6678167 },
    { lat: 48.758131, lng: 21.6678481 },
    { lat: 48.7581289, lng: 21.6679438 },
    { lat: 48.7579908, lng: 21.6680751 },
    { lat: 48.7579055, lng: 21.6679121 },
    { lat: 48.7578706, lng: 21.6680437 },
    { lat: 48.7577994, lng: 21.6680667 },
    { lat: 48.7576458, lng: 21.6679711 },
    { lat: 48.7575974, lng: 21.6680437 },
    { lat: 48.7576956, lng: 21.6682629 },
    { lat: 48.7576446, lng: 21.6682964 },
    { lat: 48.7576741, lng: 21.6684686 },
    { lat: 48.7576034, lng: 21.668637 },
    { lat: 48.7576292, lng: 21.6693746 },
    { lat: 48.7575948, lng: 21.6693914 },
    { lat: 48.7577979, lng: 21.6702848 },
    { lat: 48.7578266, lng: 21.6724852 },
    { lat: 48.758111, lng: 21.6732566 },
    { lat: 48.7580737, lng: 21.6733413 },
    { lat: 48.7578864, lng: 21.6733534 },
    { lat: 48.7570654, lng: 21.6759733 },
    { lat: 48.7567857, lng: 21.6758211 },
    { lat: 48.7565195, lng: 21.6764199 },
    { lat: 48.7565444, lng: 21.6764662 },
    { lat: 48.7563518, lng: 21.6768304 },
    { lat: 48.7564725, lng: 21.6769599 },
    { lat: 48.7564, lng: 21.6773216 },
    { lat: 48.7560993, lng: 21.6774727 },
    { lat: 48.7559767, lng: 21.6776379 },
    { lat: 48.7558963, lng: 21.6779029 },
    { lat: 48.7558074, lng: 21.6779469 },
    { lat: 48.7556652, lng: 21.6778523 },
    { lat: 48.755589, lng: 21.677902 },
    { lat: 48.7556287, lng: 21.6780333 },
    { lat: 48.7557755, lng: 21.6782199 },
    { lat: 48.7557229, lng: 21.6783604 },
    { lat: 48.7552109, lng: 21.6782322 },
    { lat: 48.7551936, lng: 21.6784194 },
    { lat: 48.7550902, lng: 21.6784244 },
    { lat: 48.7549471, lng: 21.6782939 },
    { lat: 48.7548736, lng: 21.6784418 },
    { lat: 48.7547267, lng: 21.6783811 },
    { lat: 48.7547686, lng: 21.6786388 },
    { lat: 48.7548861, lng: 21.6786924 },
    { lat: 48.7548826, lng: 21.6787718 },
    { lat: 48.7545853, lng: 21.6791786 },
    { lat: 48.7546274, lng: 21.6792801 },
    { lat: 48.7546997, lng: 21.6792863 },
    { lat: 48.7546477, lng: 21.6797738 },
    { lat: 48.7546974, lng: 21.6798705 },
    { lat: 48.7546686, lng: 21.6802139 },
    { lat: 48.7547301, lng: 21.6802393 },
    { lat: 48.7547458, lng: 21.6804077 },
    { lat: 48.7546988, lng: 21.6804559 },
    { lat: 48.7545777, lng: 21.6804246 },
    { lat: 48.7544051, lng: 21.6808671 },
    { lat: 48.7543189, lng: 21.6809209 },
    { lat: 48.7541387, lng: 21.680756 },
    { lat: 48.7540633, lng: 21.6809197 },
    { lat: 48.7540848, lng: 21.6810428 },
    { lat: 48.7541556, lng: 21.681048 },
    { lat: 48.7542957, lng: 21.6812754 },
    { lat: 48.7544185, lng: 21.6811961 },
    { lat: 48.7544657, lng: 21.6814384 },
    { lat: 48.7540235, lng: 21.6815212 },
    { lat: 48.7540165, lng: 21.6816354 },
    { lat: 48.7541005, lng: 21.6816651 },
    { lat: 48.7541672, lng: 21.6815954 },
    { lat: 48.7542313, lng: 21.6816695 },
    { lat: 48.7545065, lng: 21.6816577 },
    { lat: 48.7545576, lng: 21.6817561 },
    { lat: 48.7544946, lng: 21.6820071 },
    { lat: 48.7545956, lng: 21.6821581 },
    { lat: 48.7545267, lng: 21.6824405 },
    { lat: 48.7545968, lng: 21.6825763 },
    { lat: 48.7545175, lng: 21.682685 },
    { lat: 48.7544862, lng: 21.6830231 },
    { lat: 48.7543452, lng: 21.6830066 },
    { lat: 48.7541485, lng: 21.6832199 },
    { lat: 48.7541219, lng: 21.6835257 },
    { lat: 48.7542359, lng: 21.6836613 },
    { lat: 48.7543277, lng: 21.6837696 },
    { lat: 48.7542006, lng: 21.684061 },
    { lat: 48.7543747, lng: 21.6852122 },
    { lat: 48.7551013, lng: 21.6847089 },
    { lat: 48.756184, lng: 21.6841378 },
    { lat: 48.7560779, lng: 21.6854548 },
    { lat: 48.7561045, lng: 21.6863495 },
    { lat: 48.7562481, lng: 21.6876592 },
    { lat: 48.7573891, lng: 21.6877431 },
    { lat: 48.7581282, lng: 21.6876994 },
    { lat: 48.7602294, lng: 21.6871902 },
    { lat: 48.7620295, lng: 21.6866116 },
    { lat: 48.762764, lng: 21.686479 },
    { lat: 48.762681, lng: 21.686059 },
    { lat: 48.762679, lng: 21.68605 },
    { lat: 48.762609, lng: 21.685536 },
    { lat: 48.76256, lng: 21.685187 },
    { lat: 48.762681, lng: 21.684399 },
    { lat: 48.763134, lng: 21.682891 },
    { lat: 48.763401, lng: 21.681807 },
    { lat: 48.763679, lng: 21.681149 },
    { lat: 48.763704, lng: 21.681087 },
    { lat: 48.763931, lng: 21.680547 },
    { lat: 48.764163, lng: 21.680319 },
    { lat: 48.764762, lng: 21.679247 },
    { lat: 48.764854, lng: 21.678848 },
    { lat: 48.764999, lng: 21.678617 },
    { lat: 48.765212, lng: 21.678247 },
    { lat: 48.765387, lng: 21.677461 },
    { lat: 48.765432, lng: 21.677152 },
    { lat: 48.765459, lng: 21.676568 },
    { lat: 48.765374, lng: 21.676413 },
    { lat: 48.765298, lng: 21.676276 },
    { lat: 48.76527, lng: 21.676223 },
    { lat: 48.765288, lng: 21.675953 },
    { lat: 48.765359, lng: 21.675195 },
    { lat: 48.765527, lng: 21.674672 },
    { lat: 48.765725, lng: 21.674259 },
    { lat: 48.765899, lng: 21.674016 },
    { lat: 48.765975, lng: 21.673903 },
    { lat: 48.770995, lng: 21.666389 },
    { lat: 48.771293, lng: 21.665964 },
    { lat: 48.771317, lng: 21.665919 },
    { lat: 48.771309, lng: 21.665904 },
    { lat: 48.771361, lng: 21.665833 },
    { lat: 48.771373, lng: 21.665853 },
    { lat: 48.771395, lng: 21.665823 },
    { lat: 48.771393, lng: 21.66582 },
    { lat: 48.771411, lng: 21.665798 },
    { lat: 48.771445, lng: 21.665768 },
    { lat: 48.771559, lng: 21.665578 },
    { lat: 48.77172, lng: 21.665276 },
    { lat: 48.772082, lng: 21.664569 },
    { lat: 48.772155, lng: 21.66437 },
    { lat: 48.772237, lng: 21.664198 },
    { lat: 48.772334, lng: 21.663935 },
    { lat: 48.772467, lng: 21.663661 },
    { lat: 48.772583, lng: 21.663563 },
    { lat: 48.772663, lng: 21.663524 },
    { lat: 48.77267, lng: 21.663521 },
    { lat: 48.772985, lng: 21.663501 },
    { lat: 48.773054, lng: 21.663424 },
    { lat: 48.773701, lng: 21.662942 },
    { lat: 48.773802, lng: 21.662872 },
    { lat: 48.774376, lng: 21.662496 },
    { lat: 48.774437, lng: 21.662462 },
    { lat: 48.774567, lng: 21.662373 },
    { lat: 48.77472, lng: 21.66227 },
    { lat: 48.774868, lng: 21.66217 },
    { lat: 48.774946, lng: 21.662121 },
    { lat: 48.775015, lng: 21.662068 },
    { lat: 48.775654, lng: 21.661658 },
    { lat: 48.7756, lng: 21.661631 },
    { lat: 48.775642, lng: 21.66161 },
    { lat: 48.775702, lng: 21.661579 },
    { lat: 48.775918, lng: 21.661531 },
    { lat: 48.775991, lng: 21.661525 },
    { lat: 48.776108, lng: 21.661516 },
    { lat: 48.77615, lng: 21.661513 },
    { lat: 48.776325, lng: 21.661512 },
    { lat: 48.776359, lng: 21.661512 },
    { lat: 48.776445, lng: 21.661511 },
    { lat: 48.776531, lng: 21.66152 },
    { lat: 48.776766, lng: 21.661545 },
    { lat: 48.776979, lng: 21.661568 },
    { lat: 48.777453, lng: 21.659554 },
    { lat: 48.778977, lng: 21.659739 },
    { lat: 48.779564, lng: 21.659635 },
    { lat: 48.779816, lng: 21.65967 },
    { lat: 48.779858, lng: 21.659667 },
    { lat: 48.779915, lng: 21.659563 },
    { lat: 48.779894, lng: 21.659569 },
    { lat: 48.779835, lng: 21.659466 },
    { lat: 48.779849, lng: 21.65938 },
    { lat: 48.779918, lng: 21.659289 },
    { lat: 48.779881, lng: 21.659109 },
    { lat: 48.779888, lng: 21.65893 },
    { lat: 48.779801, lng: 21.65866 },
    { lat: 48.779805, lng: 21.658551 },
    { lat: 48.779809, lng: 21.658377 },
    { lat: 48.779836, lng: 21.658251 },
    { lat: 48.779877, lng: 21.65806 },
    { lat: 48.779873, lng: 21.657893 },
    { lat: 48.779832, lng: 21.657712 },
    { lat: 48.779704, lng: 21.657353 },
    { lat: 48.779723, lng: 21.657291 },
    { lat: 48.779817, lng: 21.657029 },
    { lat: 48.779795, lng: 21.656798 },
    { lat: 48.779903, lng: 21.656459 },
    { lat: 48.779893, lng: 21.656425 },
    { lat: 48.779861, lng: 21.656405 },
    { lat: 48.779788, lng: 21.656098 },
    { lat: 48.77984, lng: 21.655978 },
    { lat: 48.779903, lng: 21.655806 },
    { lat: 48.780061, lng: 21.655639 },
    { lat: 48.779978, lng: 21.655516 },
    { lat: 48.779982, lng: 21.655417 },
    { lat: 48.780142, lng: 21.655344 },
    { lat: 48.78018, lng: 21.655041 },
    { lat: 48.780278, lng: 21.654845 },
    { lat: 48.780374, lng: 21.654728 },
    { lat: 48.780381, lng: 21.654628 },
    { lat: 48.780402, lng: 21.654337 },
    { lat: 48.780464, lng: 21.65433 },
    { lat: 48.780495, lng: 21.654327 },
    { lat: 48.78053, lng: 21.654208 },
    { lat: 48.780535, lng: 21.654188 },
    { lat: 48.780568, lng: 21.654045 },
    { lat: 48.78046, lng: 21.653819 },
    { lat: 48.780561, lng: 21.653813 },
    { lat: 48.780582, lng: 21.653776 },
    { lat: 48.780541, lng: 21.653588 },
    { lat: 48.780404, lng: 21.653538 },
    { lat: 48.780361, lng: 21.65353 },
    { lat: 48.780306, lng: 21.653522 },
    { lat: 48.780267, lng: 21.65344 },
    { lat: 48.780302, lng: 21.65328 },
    { lat: 48.780315, lng: 21.653211 },
    { lat: 48.780274, lng: 21.653168 },
    { lat: 48.780137, lng: 21.653028 },
    { lat: 48.780105, lng: 21.652902 },
    { lat: 48.780148, lng: 21.652643 },
    { lat: 48.780173, lng: 21.652493 },
    { lat: 48.780078, lng: 21.652242 },
    { lat: 48.780158, lng: 21.652128 },
    { lat: 48.780174, lng: 21.652105 },
    { lat: 48.780177, lng: 21.652107 },
    { lat: 48.780201, lng: 21.652127 },
    { lat: 48.780267, lng: 21.652093 },
    { lat: 48.780276, lng: 21.652116 },
    { lat: 48.780299, lng: 21.652105 },
    { lat: 48.780352, lng: 21.65204 },
    { lat: 48.780212, lng: 21.651892 },
    { lat: 48.780215, lng: 21.651668 },
    { lat: 48.780273, lng: 21.651621 },
    { lat: 48.780287, lng: 21.651353 },
    { lat: 48.78019, lng: 21.651192 },
    { lat: 48.78013, lng: 21.651223 },
    { lat: 48.780128, lng: 21.651192 },
    { lat: 48.780121, lng: 21.651154 },
    { lat: 48.7801, lng: 21.651067 },
    { lat: 48.780113, lng: 21.650959 },
    { lat: 48.780125, lng: 21.650854 },
    { lat: 48.780061, lng: 21.650559 },
    { lat: 48.779965, lng: 21.65051 },
    { lat: 48.77998, lng: 21.650473 },
    { lat: 48.780003, lng: 21.650431 },
    { lat: 48.779898, lng: 21.650184 },
    { lat: 48.7799, lng: 21.650128 },
    { lat: 48.779904, lng: 21.649993 },
    { lat: 48.779953, lng: 21.649959 },
    { lat: 48.779978, lng: 21.649941 },
    { lat: 48.779951, lng: 21.649664 },
    { lat: 48.779963, lng: 21.649637 },
    { lat: 48.77995, lng: 21.64961 },
    { lat: 48.78005, lng: 21.649487 },
    { lat: 48.780008, lng: 21.649224 },
    { lat: 48.78009, lng: 21.64885 },
    { lat: 48.780118, lng: 21.648699 },
    { lat: 48.777034, lng: 21.6481473 },
    { lat: 48.7755618, lng: 21.646772 },
    { lat: 48.7739255, lng: 21.6458584 },
    { lat: 48.7718205, lng: 21.6452461 },
    { lat: 48.7700624, lng: 21.6449826 },
    { lat: 48.768471, lng: 21.6453335 },
    { lat: 48.7683984, lng: 21.6454708 },
    { lat: 48.7683909, lng: 21.6454028 },
    { lat: 48.7682919, lng: 21.6453619 },
    { lat: 48.7680702, lng: 21.6457721 },
    { lat: 48.768102, lng: 21.645865 },
    { lat: 48.7679734, lng: 21.6462194 },
    { lat: 48.768005, lng: 21.6462638 },
    { lat: 48.7679476, lng: 21.6466301 },
    { lat: 48.7678013, lng: 21.6467513 },
    { lat: 48.7676738, lng: 21.6474327 },
    { lat: 48.7674832, lng: 21.6478659 },
    { lat: 48.7672497, lng: 21.6481374 },
    { lat: 48.7668052, lng: 21.6482524 },
    { lat: 48.7664574, lng: 21.6485342 },
    { lat: 48.7662319, lng: 21.6490463 },
    { lat: 48.7659798, lng: 21.649335 },
    { lat: 48.7659238, lng: 21.6497098 },
    { lat: 48.7655175, lng: 21.6489243 },
    { lat: 48.765387, lng: 21.6490509 },
    { lat: 48.7648863, lng: 21.6492914 },
    { lat: 48.7644419, lng: 21.6492933 },
    { lat: 48.7639522, lng: 21.6493693 },
    { lat: 48.7634169, lng: 21.6495456 },
    { lat: 48.7630228, lng: 21.6495758 },
    { lat: 48.7629447, lng: 21.6500008 },
    { lat: 48.7628053, lng: 21.6500088 },
    { lat: 48.7628094, lng: 21.6502011 },
    { lat: 48.7624147, lng: 21.6502837 },
    { lat: 48.762279, lng: 21.6503842 },
    { lat: 48.7613743, lng: 21.6505855 },
    { lat: 48.7613693, lng: 21.6506554 },
    { lat: 48.7600789, lng: 21.6506534 },
    { lat: 48.7600375, lng: 21.651847 },
    { lat: 48.7599838, lng: 21.6521514 },
    { lat: 48.7599459, lng: 21.6539221 },
    { lat: 48.7601429, lng: 21.6584864 },
    { lat: 48.7600492, lng: 21.6585151 },
    { lat: 48.7593331, lng: 21.6587033 },
  ],
  Svinice: [
    { lat: 48.4542358, lng: 21.872078 },
    { lat: 48.4538444, lng: 21.872122 },
    { lat: 48.4534966, lng: 21.8726433 },
    { lat: 48.4531825, lng: 21.8729912 },
    { lat: 48.4525255, lng: 21.8733876 },
    { lat: 48.4517768, lng: 21.873581 },
    { lat: 48.4511409, lng: 21.8735691 },
    { lat: 48.4507211, lng: 21.8738752 },
    { lat: 48.4503747, lng: 21.8738818 },
    { lat: 48.4501319, lng: 21.8740203 },
    { lat: 48.4498237, lng: 21.8745872 },
    { lat: 48.4494043, lng: 21.8751296 },
    { lat: 48.4493041, lng: 21.875386 },
    { lat: 48.4492521, lng: 21.8761602 },
    { lat: 48.4491517, lng: 21.8765491 },
    { lat: 48.4483286, lng: 21.8774442 },
    { lat: 48.4477576, lng: 21.8786028 },
    { lat: 48.4473994, lng: 21.8796381 },
    { lat: 48.4469629, lng: 21.8802123 },
    { lat: 48.4467572, lng: 21.8803437 },
    { lat: 48.4465401, lng: 21.8803931 },
    { lat: 48.4462542, lng: 21.880348 },
    { lat: 48.4461426, lng: 21.8804296 },
    { lat: 48.4458914, lng: 21.8809371 },
    { lat: 48.4457504, lng: 21.8811015 },
    { lat: 48.4455252, lng: 21.8812205 },
    { lat: 48.4451305, lng: 21.8812288 },
    { lat: 48.4449083, lng: 21.8811219 },
    { lat: 48.4443982, lng: 21.8818042 },
    { lat: 48.4428367, lng: 21.8832553 },
    { lat: 48.4426633, lng: 21.8827267 },
    { lat: 48.4430088, lng: 21.8825104 },
    { lat: 48.4425653, lng: 21.8804851 },
    { lat: 48.4421198, lng: 21.8805262 },
    { lat: 48.4415084, lng: 21.8803763 },
    { lat: 48.4413927, lng: 21.87977 },
    { lat: 48.4403109, lng: 21.8798373 },
    { lat: 48.4392801, lng: 21.8801309 },
    { lat: 48.4391081, lng: 21.8802255 },
    { lat: 48.4391152, lng: 21.8805861 },
    { lat: 48.4384416, lng: 21.880904 },
    { lat: 48.4373329, lng: 21.8812853 },
    { lat: 48.4371049, lng: 21.8812996 },
    { lat: 48.4362952, lng: 21.8811757 },
    { lat: 48.4357347, lng: 21.8809523 },
    { lat: 48.4352802, lng: 21.880861 },
    { lat: 48.4339927, lng: 21.8802827 },
    { lat: 48.4333865, lng: 21.880243 },
    { lat: 48.4323047, lng: 21.880311 },
    { lat: 48.431759, lng: 21.8802197 },
    { lat: 48.4317612, lng: 21.8792496 },
    { lat: 48.4313171, lng: 21.8790875 },
    { lat: 48.4308285, lng: 21.878723 },
    { lat: 48.430171, lng: 21.8787072 },
    { lat: 48.4299671, lng: 21.8779914 },
    { lat: 48.4297258, lng: 21.8773979 },
    { lat: 48.4298341, lng: 21.8774118 },
    { lat: 48.4298999, lng: 21.877302 },
    { lat: 48.4298743, lng: 21.8769677 },
    { lat: 48.4312311, lng: 21.8773145 },
    { lat: 48.4316522, lng: 21.8772791 },
    { lat: 48.4311244, lng: 21.8763574 },
    { lat: 48.430618, lng: 21.8759163 },
    { lat: 48.4295176, lng: 21.8753865 },
    { lat: 48.4291668, lng: 21.8751301 },
    { lat: 48.4284706, lng: 21.8750118 },
    { lat: 48.4281133, lng: 21.8750262 },
    { lat: 48.4278818, lng: 21.8749532 },
    { lat: 48.4276854, lng: 21.8741785 },
    { lat: 48.4270456, lng: 21.8742362 },
    { lat: 48.425588, lng: 21.8740838 },
    { lat: 48.4253521, lng: 21.8741443 },
    { lat: 48.4244328, lng: 21.8746163 },
    { lat: 48.423809, lng: 21.8748211 },
    { lat: 48.4233883, lng: 21.8747768 },
    { lat: 48.4225911, lng: 21.874839 },
    { lat: 48.4222533, lng: 21.8749602 },
    { lat: 48.4217262, lng: 21.8752834 },
    { lat: 48.4220432, lng: 21.8782076 },
    { lat: 48.4221155, lng: 21.8800595 },
    { lat: 48.4222599, lng: 21.8818741 },
    { lat: 48.4223068, lng: 21.8831327 },
    { lat: 48.4221608, lng: 21.8838496 },
    { lat: 48.4221402, lng: 21.8840927 },
    { lat: 48.422176, lng: 21.8843506 },
    { lat: 48.422844, lng: 21.8865363 },
    { lat: 48.4233569, lng: 21.8878127 },
    { lat: 48.4238923, lng: 21.8896091 },
    { lat: 48.4243687, lng: 21.891432 },
    { lat: 48.4244021, lng: 21.8915969 },
    { lat: 48.4266553, lng: 21.8903061 },
    { lat: 48.4287813, lng: 21.8901133 },
    { lat: 48.4290588, lng: 21.8902343 },
    { lat: 48.4291035, lng: 21.8900922 },
    { lat: 48.4296126, lng: 21.8900224 },
    { lat: 48.43057, lng: 21.8896717 },
    { lat: 48.4321368, lng: 21.8895207 },
    { lat: 48.4336464, lng: 21.8898189 },
    { lat: 48.4343431, lng: 21.8901358 },
    { lat: 48.4341148, lng: 21.8927413 },
    { lat: 48.4345441, lng: 21.8931225 },
    { lat: 48.4348286, lng: 21.8937275 },
    { lat: 48.4347438, lng: 21.8947269 },
    { lat: 48.4347351, lng: 21.8948319 },
    { lat: 48.4377334, lng: 21.8954017 },
    { lat: 48.4385645, lng: 21.8936219 },
    { lat: 48.4418749, lng: 21.8928559 },
    { lat: 48.4425921, lng: 21.8930966 },
    { lat: 48.4429663, lng: 21.8930782 },
    { lat: 48.4430891, lng: 21.8935215 },
    { lat: 48.444203, lng: 21.8931327 },
    { lat: 48.4455304, lng: 21.8928134 },
    { lat: 48.4466847, lng: 21.8914498 },
    { lat: 48.4469021, lng: 21.8909794 },
    { lat: 48.4473123, lng: 21.8914887 },
    { lat: 48.4480723, lng: 21.8921316 },
    { lat: 48.4484405, lng: 21.8923127 },
    { lat: 48.4488254, lng: 21.8926322 },
    { lat: 48.4490938, lng: 21.8924352 },
    { lat: 48.4492931, lng: 21.8924 },
    { lat: 48.4493712, lng: 21.8924571 },
    { lat: 48.4496336, lng: 21.8928634 },
    { lat: 48.450162, lng: 21.893514 },
    { lat: 48.4503402, lng: 21.8943617 },
    { lat: 48.4506595, lng: 21.8952428 },
    { lat: 48.4509225, lng: 21.8948353 },
    { lat: 48.4512565, lng: 21.8945187 },
    { lat: 48.451698, lng: 21.8944507 },
    { lat: 48.4519498, lng: 21.894489 },
    { lat: 48.452222, lng: 21.8947044 },
    { lat: 48.4526846, lng: 21.8952823 },
    { lat: 48.453091, lng: 21.8947241 },
    { lat: 48.454494, lng: 21.8930926 },
    { lat: 48.4555983, lng: 21.8919755 },
    { lat: 48.4566297, lng: 21.8911249 },
    { lat: 48.4567219, lng: 21.8908566 },
    { lat: 48.4571533, lng: 21.8905697 },
    { lat: 48.4585663, lng: 21.8896917 },
    { lat: 48.458805, lng: 21.8903964 },
    { lat: 48.4589625, lng: 21.8907095 },
    { lat: 48.4591966, lng: 21.8910146 },
    { lat: 48.459274, lng: 21.8911835 },
    { lat: 48.4599297, lng: 21.8910942 },
    { lat: 48.4599292, lng: 21.8917301 },
    { lat: 48.4600053, lng: 21.8921146 },
    { lat: 48.4602037, lng: 21.892833 },
    { lat: 48.4603716, lng: 21.8931923 },
    { lat: 48.4607971, lng: 21.8939514 },
    { lat: 48.4614334, lng: 21.8944681 },
    { lat: 48.4616118, lng: 21.8945287 },
    { lat: 48.4624495, lng: 21.8944311 },
    { lat: 48.4634652, lng: 21.8937578 },
    { lat: 48.4640102, lng: 21.8931121 },
    { lat: 48.4649495, lng: 21.8911744 },
    { lat: 48.4651276, lng: 21.8909664 },
    { lat: 48.4652742, lng: 21.8911096 },
    { lat: 48.465907, lng: 21.8911759 },
    { lat: 48.4664436, lng: 21.89056 },
    { lat: 48.4661243, lng: 21.8899467 },
    { lat: 48.4658671, lng: 21.8893032 },
    { lat: 48.4656909, lng: 21.8885704 },
    { lat: 48.4653744, lng: 21.8867581 },
    { lat: 48.4652495, lng: 21.8855249 },
    { lat: 48.4651744, lng: 21.8841283 },
    { lat: 48.4651306, lng: 21.8818496 },
    { lat: 48.465382, lng: 21.8778707 },
    { lat: 48.4653978, lng: 21.8769052 },
    { lat: 48.4653175, lng: 21.8760179 },
    { lat: 48.4651715, lng: 21.8751065 },
    { lat: 48.4649698, lng: 21.8745691 },
    { lat: 48.4646552, lng: 21.8741435 },
    { lat: 48.4643615, lng: 21.8739688 },
    { lat: 48.4640699, lng: 21.8738695 },
    { lat: 48.4637453, lng: 21.8738883 },
    { lat: 48.4628814, lng: 21.8741601 },
    { lat: 48.461829, lng: 21.8748415 },
    { lat: 48.4613926, lng: 21.8751972 },
    { lat: 48.4594694, lng: 21.8771577 },
    { lat: 48.458563, lng: 21.8779135 },
    { lat: 48.4579472, lng: 21.8782906 },
    { lat: 48.4572592, lng: 21.8785371 },
    { lat: 48.4569205, lng: 21.8785759 },
    { lat: 48.4560757, lng: 21.8777905 },
    { lat: 48.4556555, lng: 21.8772474 },
    { lat: 48.4552475, lng: 21.8765612 },
    { lat: 48.45507, lng: 21.876177 },
    { lat: 48.4543664, lng: 21.8741445 },
    { lat: 48.4542027, lng: 21.8733314 },
    { lat: 48.4541737, lng: 21.8728563 },
    { lat: 48.4542358, lng: 21.872078 },
  ],
  Zemplín: [
    { lat: 48.4167287, lng: 21.8134644 },
    { lat: 48.4168255, lng: 21.8146717 },
    { lat: 48.417316, lng: 21.8163406 },
    { lat: 48.4174196, lng: 21.8166405 },
    { lat: 48.4179715, lng: 21.8173499 },
    { lat: 48.4182298, lng: 21.8175528 },
    { lat: 48.4188558, lng: 21.8178703 },
    { lat: 48.4195575, lng: 21.8179361 },
    { lat: 48.4201963, lng: 21.8177613 },
    { lat: 48.4206413, lng: 21.8174413 },
    { lat: 48.421017, lng: 21.8170029 },
    { lat: 48.4213803, lng: 21.8163584 },
    { lat: 48.4216075, lng: 21.8156238 },
    { lat: 48.4219731, lng: 21.813259 },
    { lat: 48.4220873, lng: 21.8119127 },
    { lat: 48.4220336, lng: 21.8112336 },
    { lat: 48.4222153, lng: 21.8075798 },
    { lat: 48.4225076, lng: 21.8061612 },
    { lat: 48.4227323, lng: 21.8055071 },
    { lat: 48.4230814, lng: 21.8045703 },
    { lat: 48.4236789, lng: 21.8036351 },
    { lat: 48.4241797, lng: 21.8031229 },
    { lat: 48.4246033, lng: 21.8028512 },
    { lat: 48.4250496, lng: 21.802666 },
    { lat: 48.4260427, lng: 21.8026509 },
    { lat: 48.42583, lng: 21.805039 },
    { lat: 48.4275275, lng: 21.8095218 },
    { lat: 48.4276112, lng: 21.8104847 },
    { lat: 48.4276487, lng: 21.8116536 },
    { lat: 48.4275803, lng: 21.8126945 },
    { lat: 48.4273297, lng: 21.8136914 },
    { lat: 48.4276233, lng: 21.8158802 },
    { lat: 48.4277413, lng: 21.8162611 },
    { lat: 48.4283474, lng: 21.8217066 },
    { lat: 48.4286227, lng: 21.8233249 },
    { lat: 48.4279481, lng: 21.8236021 },
    { lat: 48.4285366, lng: 21.8270854 },
    { lat: 48.4298535, lng: 21.8265532 },
    { lat: 48.4308044, lng: 21.8259119 },
    { lat: 48.4315527, lng: 21.8255712 },
    { lat: 48.4325115, lng: 21.8253691 },
    { lat: 48.433093, lng: 21.8254017 },
    { lat: 48.4339978, lng: 21.8269164 },
    { lat: 48.43466, lng: 21.8278513 },
    { lat: 48.434825, lng: 21.8280496 },
    { lat: 48.4349542, lng: 21.827355 },
    { lat: 48.4352165, lng: 21.8270532 },
    { lat: 48.4354251, lng: 21.8269874 },
    { lat: 48.4355092, lng: 21.8267153 },
    { lat: 48.4355042, lng: 21.8263131 },
    { lat: 48.436121, lng: 21.8254154 },
    { lat: 48.4362713, lng: 21.8249236 },
    { lat: 48.4367122, lng: 21.824364 },
    { lat: 48.4368667, lng: 21.8235106 },
    { lat: 48.4373893, lng: 21.8230996 },
    { lat: 48.438166, lng: 21.8216173 },
    { lat: 48.4384716, lng: 21.8203942 },
    { lat: 48.4385468, lng: 21.8194844 },
    { lat: 48.4385066, lng: 21.8192799 },
    { lat: 48.4384992, lng: 21.8184852 },
    { lat: 48.438753, lng: 21.8184617 },
    { lat: 48.4390607, lng: 21.8185697 },
    { lat: 48.4396304, lng: 21.8183694 },
    { lat: 48.4406321, lng: 21.8178272 },
    { lat: 48.4416021, lng: 21.8170987 },
    { lat: 48.4414921, lng: 21.8165089 },
    { lat: 48.4419127, lng: 21.815905 },
    { lat: 48.4425927, lng: 21.8142457 },
    { lat: 48.4427529, lng: 21.8136664 },
    { lat: 48.4429052, lng: 21.8127293 },
    { lat: 48.4433094, lng: 21.8115012 },
    { lat: 48.4436656, lng: 21.8109702 },
    { lat: 48.4439938, lng: 21.8103147 },
    { lat: 48.4443514, lng: 21.8097498 },
    { lat: 48.4453828, lng: 21.8085412 },
    { lat: 48.4461573, lng: 21.8073942 },
    { lat: 48.4471367, lng: 21.8065621 },
    { lat: 48.4490165, lng: 21.8059093 },
    { lat: 48.449704, lng: 21.8055679 },
    { lat: 48.45003, lng: 21.8055223 },
    { lat: 48.4504286, lng: 21.8051435 },
    { lat: 48.4507851, lng: 21.8050532 },
    { lat: 48.4514893, lng: 21.8050528 },
    { lat: 48.4516802, lng: 21.8051212 },
    { lat: 48.4522588, lng: 21.8055164 },
    { lat: 48.4530234, lng: 21.8064878 },
    { lat: 48.453593, lng: 21.8074375 },
    { lat: 48.4538152, lng: 21.8080159 },
    { lat: 48.4539798, lng: 21.8088436 },
    { lat: 48.4540278, lng: 21.8099002 },
    { lat: 48.4538427, lng: 21.8115979 },
    { lat: 48.4535964, lng: 21.8128849 },
    { lat: 48.4529863, lng: 21.8154846 },
    { lat: 48.4529228, lng: 21.8164999 },
    { lat: 48.4530047, lng: 21.8172666 },
    { lat: 48.4531397, lng: 21.817741 },
    { lat: 48.4534458, lng: 21.8184739 },
    { lat: 48.4536295, lng: 21.8187356 },
    { lat: 48.4539808, lng: 21.8189622 },
    { lat: 48.4545273, lng: 21.8191702 },
    { lat: 48.4548391, lng: 21.819093 },
    { lat: 48.4556749, lng: 21.8185725 },
    { lat: 48.4586101, lng: 21.8156457 },
    { lat: 48.4591863, lng: 21.8153928 },
    { lat: 48.4596467, lng: 21.8152639 },
    { lat: 48.4606316, lng: 21.8152881 },
    { lat: 48.4612999, lng: 21.8154211 },
    { lat: 48.4622118, lng: 21.8157344 },
    { lat: 48.4627695, lng: 21.8160704 },
    { lat: 48.4630093, lng: 21.8154337 },
    { lat: 48.4632079, lng: 21.8136899 },
    { lat: 48.4630196, lng: 21.8118328 },
    { lat: 48.4621983, lng: 21.8099067 },
    { lat: 48.4604048, lng: 21.8091297 },
    { lat: 48.4594661, lng: 21.8056872 },
    { lat: 48.4593177, lng: 21.8057378 },
    { lat: 48.459169, lng: 21.8049161 },
    { lat: 48.4595136, lng: 21.8044994 },
    { lat: 48.4602047, lng: 21.8038199 },
    { lat: 48.4607819, lng: 21.803358 },
    { lat: 48.4612784, lng: 21.8028542 },
    { lat: 48.4617698, lng: 21.8022479 },
    { lat: 48.4619465, lng: 21.8007113 },
    { lat: 48.4622238, lng: 21.8002556 },
    { lat: 48.4624492, lng: 21.8000139 },
    { lat: 48.4626502, lng: 21.7999562 },
    { lat: 48.46268, lng: 21.7998551 },
    { lat: 48.4634383, lng: 21.7993807 },
    { lat: 48.4635621, lng: 21.7995966 },
    { lat: 48.4636746, lng: 21.7995559 },
    { lat: 48.4639142, lng: 21.7992531 },
    { lat: 48.4640905, lng: 21.7987229 },
    { lat: 48.4640978, lng: 21.798581 },
    { lat: 48.4645707, lng: 21.7975676 },
    { lat: 48.4647165, lng: 21.7975894 },
    { lat: 48.4650751, lng: 21.7973203 },
    { lat: 48.4653225, lng: 21.7973151 },
    { lat: 48.4652702, lng: 21.7970835 },
    { lat: 48.4653406, lng: 21.7961668 },
    { lat: 48.4654073, lng: 21.7960958 },
    { lat: 48.4656451, lng: 21.7960643 },
    { lat: 48.4658259, lng: 21.7959298 },
    { lat: 48.4658573, lng: 21.7957524 },
    { lat: 48.465777, lng: 21.7951843 },
    { lat: 48.4658458, lng: 21.7951029 },
    { lat: 48.4659838, lng: 21.7938549 },
    { lat: 48.4653487, lng: 21.7938737 },
    { lat: 48.4652587, lng: 21.7937855 },
    { lat: 48.4653187, lng: 21.7933795 },
    { lat: 48.465231, lng: 21.7930852 },
    { lat: 48.4642987, lng: 21.7923768 },
    { lat: 48.463585, lng: 21.7917211 },
    { lat: 48.4634722, lng: 21.7914052 },
    { lat: 48.4632155, lng: 21.7911029 },
    { lat: 48.4631055, lng: 21.790214 },
    { lat: 48.4630494, lng: 21.790099 },
    { lat: 48.4621369, lng: 21.7893278 },
    { lat: 48.4618095, lng: 21.7895807 },
    { lat: 48.4613568, lng: 21.7885003 },
    { lat: 48.4613046, lng: 21.7881486 },
    { lat: 48.4613515, lng: 21.7880047 },
    { lat: 48.4612809, lng: 21.7875481 },
    { lat: 48.4615014, lng: 21.7870776 },
    { lat: 48.4613626, lng: 21.7865081 },
    { lat: 48.4612356, lng: 21.7862611 },
    { lat: 48.4609299, lng: 21.7858934 },
    { lat: 48.4605812, lng: 21.784877 },
    { lat: 48.4604639, lng: 21.7847366 },
    { lat: 48.4603278, lng: 21.7846912 },
    { lat: 48.4599431, lng: 21.7839705 },
    { lat: 48.45939, lng: 21.7834811 },
    { lat: 48.4587531, lng: 21.7836929 },
    { lat: 48.4585091, lng: 21.7835665 },
    { lat: 48.4579844, lng: 21.7827099 },
    { lat: 48.4576569, lng: 21.7818997 },
    { lat: 48.4575662, lng: 21.7813136 },
    { lat: 48.4572334, lng: 21.7800532 },
    { lat: 48.4568988, lng: 21.7798666 },
    { lat: 48.4565276, lng: 21.7797814 },
    { lat: 48.4561613, lng: 21.7794649 },
    { lat: 48.4553295, lng: 21.7794091 },
    { lat: 48.4544724, lng: 21.7794622 },
    { lat: 48.4542555, lng: 21.7787841 },
    { lat: 48.4542131, lng: 21.77876 },
    { lat: 48.4540265, lng: 21.7788535 },
    { lat: 48.4538032, lng: 21.7792525 },
    { lat: 48.4536229, lng: 21.7794139 },
    { lat: 48.4527603, lng: 21.7795514 },
    { lat: 48.4520801, lng: 21.7799951 },
    { lat: 48.4517404, lng: 21.7799176 },
    { lat: 48.4513486, lng: 21.7793149 },
    { lat: 48.4510613, lng: 21.7785353 },
    { lat: 48.4507351, lng: 21.7772227 },
    { lat: 48.4502734, lng: 21.7759319 },
    { lat: 48.4492044, lng: 21.7743622 },
    { lat: 48.4488354, lng: 21.7735179 },
    { lat: 48.4481099, lng: 21.7721307 },
    { lat: 48.4477778, lng: 21.7718693 },
    { lat: 48.446772, lng: 21.7714056 },
    { lat: 48.4465936, lng: 21.7711267 },
    { lat: 48.4460276, lng: 21.7694721 },
    { lat: 48.4458641, lng: 21.7686623 },
    { lat: 48.4457264, lng: 21.767074 },
    { lat: 48.4456526, lng: 21.7666699 },
    { lat: 48.4456208, lng: 21.7659428 },
    { lat: 48.4457019, lng: 21.7655269 },
    { lat: 48.4453775, lng: 21.7652179 },
    { lat: 48.4451355, lng: 21.7648681 },
    { lat: 48.4441346, lng: 21.7639209 },
    { lat: 48.4429109, lng: 21.7623706 },
    { lat: 48.4425509, lng: 21.762203 },
    { lat: 48.4420456, lng: 21.7611484 },
    { lat: 48.4412336, lng: 21.7605294 },
    { lat: 48.440659, lng: 21.760249 },
    { lat: 48.4393631, lng: 21.7589874 },
    { lat: 48.438183, lng: 21.7573078 },
    { lat: 48.4379017, lng: 21.7559656 },
    { lat: 48.437719, lng: 21.7556935 },
    { lat: 48.4377174, lng: 21.7542061 },
    { lat: 48.4368062, lng: 21.7539954 },
    { lat: 48.4366109, lng: 21.7540551 },
    { lat: 48.4357349, lng: 21.7539893 },
    { lat: 48.4347881, lng: 21.7535944 },
    { lat: 48.4336886, lng: 21.7535843 },
    { lat: 48.4336706, lng: 21.7535889 },
    { lat: 48.4335096, lng: 21.7541719 },
    { lat: 48.4338571, lng: 21.7552985 },
    { lat: 48.4340025, lng: 21.7559831 },
    { lat: 48.4343082, lng: 21.756855 },
    { lat: 48.434632, lng: 21.7576078 },
    { lat: 48.4355155, lng: 21.7589619 },
    { lat: 48.4365181, lng: 21.7599339 },
    { lat: 48.4370718, lng: 21.760956 },
    { lat: 48.4374156, lng: 21.7614416 },
    { lat: 48.4376255, lng: 21.7619229 },
    { lat: 48.437659, lng: 21.7624641 },
    { lat: 48.4376261, lng: 21.763084 },
    { lat: 48.4376751, lng: 21.7632781 },
    { lat: 48.4378855, lng: 21.7636963 },
    { lat: 48.4380982, lng: 21.7645055 },
    { lat: 48.4383948, lng: 21.7647414 },
    { lat: 48.4388806, lng: 21.7660827 },
    { lat: 48.4388812, lng: 21.7667438 },
    { lat: 48.4385914, lng: 21.768151 },
    { lat: 48.438849, lng: 21.7700138 },
    { lat: 48.4386267, lng: 21.7707858 },
    { lat: 48.4383955, lng: 21.7711898 },
    { lat: 48.4368415, lng: 21.7728375 },
    { lat: 48.4365587, lng: 21.7735865 },
    { lat: 48.4356104, lng: 21.7746497 },
    { lat: 48.4352745, lng: 21.7751308 },
    { lat: 48.4345492, lng: 21.7752349 },
    { lat: 48.43366, lng: 21.7749637 },
    { lat: 48.4334697, lng: 21.7750432 },
    { lat: 48.433013, lng: 21.7750865 },
    { lat: 48.4328763, lng: 21.7752389 },
    { lat: 48.4324219, lng: 21.7754596 },
    { lat: 48.4322642, lng: 21.7757008 },
    { lat: 48.4321964, lng: 21.7756946 },
    { lat: 48.430712, lng: 21.7770367 },
    { lat: 48.4305113, lng: 21.7773176 },
    { lat: 48.4301232, lng: 21.7786325 },
    { lat: 48.4299777, lng: 21.7792538 },
    { lat: 48.4299912, lng: 21.779423 },
    { lat: 48.4298431, lng: 21.7802046 },
    { lat: 48.4295899, lng: 21.7807129 },
    { lat: 48.4294791, lng: 21.7812013 },
    { lat: 48.4293146, lng: 21.7814896 },
    { lat: 48.428965, lng: 21.7823519 },
    { lat: 48.4291051, lng: 21.7827987 },
    { lat: 48.4291215, lng: 21.7833734 },
    { lat: 48.4288562, lng: 21.7833794 },
    { lat: 48.4274852, lng: 21.7845918 },
    { lat: 48.4266267, lng: 21.7859064 },
    { lat: 48.4263949, lng: 21.7861987 },
    { lat: 48.4262365, lng: 21.7862524 },
    { lat: 48.4260652, lng: 21.7865429 },
    { lat: 48.425856, lng: 21.7867162 },
    { lat: 48.4255166, lng: 21.7866837 },
    { lat: 48.4253761, lng: 21.7866036 },
    { lat: 48.4250898, lng: 21.7867556 },
    { lat: 48.42475, lng: 21.786715 },
    { lat: 48.4242998, lng: 21.7869608 },
    { lat: 48.4230598, lng: 21.7871706 },
    { lat: 48.4228563, lng: 21.7873496 },
    { lat: 48.4226064, lng: 21.7873397 },
    { lat: 48.4223668, lng: 21.7875169 },
    { lat: 48.4222196, lng: 21.7874772 },
    { lat: 48.4221012, lng: 21.7875537 },
    { lat: 48.4219061, lng: 21.7874892 },
    { lat: 48.4217727, lng: 21.7876768 },
    { lat: 48.4213881, lng: 21.7879866 },
    { lat: 48.4212924, lng: 21.7882385 },
    { lat: 48.4210933, lng: 21.7883206 },
    { lat: 48.4209846, lng: 21.788552 },
    { lat: 48.4207821, lng: 21.7887234 },
    { lat: 48.4203899, lng: 21.7887465 },
    { lat: 48.4203289, lng: 21.7889875 },
    { lat: 48.4201431, lng: 21.7889808 },
    { lat: 48.4195078, lng: 21.7886175 },
    { lat: 48.4191095, lng: 21.7887046 },
    { lat: 48.4185471, lng: 21.7884928 },
    { lat: 48.4183173, lng: 21.7888614 },
    { lat: 48.418195, lng: 21.7890876 },
    { lat: 48.416695, lng: 21.7902712 },
    { lat: 48.4158554, lng: 21.7910732 },
    { lat: 48.4151673, lng: 21.7920683 },
    { lat: 48.4144299, lng: 21.7942649 },
    { lat: 48.4140389, lng: 21.7967992 },
    { lat: 48.4139957, lng: 21.7992554 },
    { lat: 48.4140677, lng: 21.8008402 },
    { lat: 48.4144262, lng: 21.8038554 },
    { lat: 48.4145856, lng: 21.804822 },
    { lat: 48.4154259, lng: 21.8082401 },
    { lat: 48.4158854, lng: 21.8096738 },
    { lat: 48.4164047, lng: 21.8118353 },
    { lat: 48.4167287, lng: 21.8134644 },
  ],
  Leles: [
    { lat: 48.493641, lng: 22.068761 },
    { lat: 48.49391, lng: 22.06759 },
    { lat: 48.494004, lng: 22.066228 },
    { lat: 48.493927, lng: 22.064216 },
    { lat: 48.493973, lng: 22.064018 },
    { lat: 48.493969, lng: 22.063385 },
    { lat: 48.494014, lng: 22.063081 },
    { lat: 48.494413, lng: 22.061236 },
    { lat: 48.49487, lng: 22.059625 },
    { lat: 48.495275, lng: 22.058874 },
    { lat: 48.496072, lng: 22.057795 },
    { lat: 48.49663, lng: 22.056954 },
    { lat: 48.497197, lng: 22.056076 },
    { lat: 48.49778, lng: 22.055152 },
    { lat: 48.497835, lng: 22.055063 },
    { lat: 48.497928, lng: 22.054915 },
    { lat: 48.498632, lng: 22.054186 },
    { lat: 48.498712, lng: 22.054101 },
    { lat: 48.499465, lng: 22.053464 },
    { lat: 48.499849, lng: 22.055 },
    { lat: 48.499912, lng: 22.055209 },
    { lat: 48.500013, lng: 22.055586 },
    { lat: 48.50006, lng: 22.055827 },
    { lat: 48.500255, lng: 22.055364 },
    { lat: 48.500333, lng: 22.055196 },
    { lat: 48.500431, lng: 22.054947 },
    { lat: 48.500602, lng: 22.054984 },
    { lat: 48.50071, lng: 22.055011 },
    { lat: 48.500912, lng: 22.054992 },
    { lat: 48.501031, lng: 22.054972 },
    { lat: 48.501156, lng: 22.05488 },
    { lat: 48.501292, lng: 22.054785 },
    { lat: 48.50141, lng: 22.054658 },
    { lat: 48.501505, lng: 22.054543 },
    { lat: 48.501584, lng: 22.054398 },
    { lat: 48.501693, lng: 22.054176 },
    { lat: 48.50179, lng: 22.053986 },
    { lat: 48.501818, lng: 22.053883 },
    { lat: 48.501855, lng: 22.053646 },
    { lat: 48.501938, lng: 22.053215 },
    { lat: 48.501974, lng: 22.052974 },
    { lat: 48.502052, lng: 22.052595 },
    { lat: 48.502131, lng: 22.052239 },
    { lat: 48.502172, lng: 22.052131 },
    { lat: 48.502236, lng: 22.051998 },
    { lat: 48.502298, lng: 22.051922 },
    { lat: 48.502419, lng: 22.051771 },
    { lat: 48.502522, lng: 22.05164 },
    { lat: 48.50262, lng: 22.051611 },
    { lat: 48.502794, lng: 22.051606 },
    { lat: 48.502909, lng: 22.051658 },
    { lat: 48.503085, lng: 22.051804 },
    { lat: 48.503144, lng: 22.051882 },
    { lat: 48.503307, lng: 22.052063 },
    { lat: 48.503466, lng: 22.052261 },
    { lat: 48.503571, lng: 22.052398 },
    { lat: 48.503628, lng: 22.052498 },
    { lat: 48.503732, lng: 22.052621 },
    { lat: 48.503835, lng: 22.052672 },
    { lat: 48.50402, lng: 22.05273 },
    { lat: 48.504159, lng: 22.052688 },
    { lat: 48.50424, lng: 22.052637 },
    { lat: 48.504305, lng: 22.052552 },
    { lat: 48.504373, lng: 22.052386 },
    { lat: 48.504397, lng: 22.052182 },
    { lat: 48.504397, lng: 22.052066 },
    { lat: 48.504364, lng: 22.05193 },
    { lat: 48.504287, lng: 22.051707 },
    { lat: 48.504217, lng: 22.051413 },
    { lat: 48.504175, lng: 22.05118 },
    { lat: 48.504104, lng: 22.0508 },
    { lat: 48.50408, lng: 22.050427 },
    { lat: 48.504104, lng: 22.04991 },
    { lat: 48.504154, lng: 22.049508 },
    { lat: 48.504165, lng: 22.049315 },
    { lat: 48.504378, lng: 22.049261 },
    { lat: 48.504989, lng: 22.048559 },
    { lat: 48.5051, lng: 22.048301 },
    { lat: 48.5053, lng: 22.048427 },
    { lat: 48.505503, lng: 22.048541 },
    { lat: 48.50571, lng: 22.048641 },
    { lat: 48.506082, lng: 22.048718 },
    { lat: 48.506278, lng: 22.048712 },
    { lat: 48.506371, lng: 22.04866 },
    { lat: 48.506527, lng: 22.048579 },
    { lat: 48.506657, lng: 22.04845 },
    { lat: 48.506702, lng: 22.048356 },
    { lat: 48.506768, lng: 22.048193 },
    { lat: 48.50684, lng: 22.047996 },
    { lat: 48.506924, lng: 22.047693 },
    { lat: 48.506938, lng: 22.047553 },
    { lat: 48.50699, lng: 22.047249 },
    { lat: 48.507077, lng: 22.046935 },
    { lat: 48.507183, lng: 22.046596 },
    { lat: 48.507234, lng: 22.046437 },
    { lat: 48.507438, lng: 22.046129 },
    { lat: 48.507533, lng: 22.046001 },
    { lat: 48.507727, lng: 22.045654 },
    { lat: 48.507837, lng: 22.045447 },
    { lat: 48.507929, lng: 22.045211 },
    { lat: 48.507995, lng: 22.045012 },
    { lat: 48.508077, lng: 22.044694 },
    { lat: 48.508099, lng: 22.044516 },
    { lat: 48.508075, lng: 22.044342 },
    { lat: 48.507995, lng: 22.043978 },
    { lat: 48.507884, lng: 22.043475 },
    { lat: 48.507822, lng: 22.043198 },
    { lat: 48.507806, lng: 22.043057 },
    { lat: 48.507779, lng: 22.042842 },
    { lat: 48.507735, lng: 22.042414 },
    { lat: 48.507706, lng: 22.042024 },
    { lat: 48.507677, lng: 22.041871 },
    { lat: 48.507659, lng: 22.041695 },
    { lat: 48.507609, lng: 22.041287 },
    { lat: 48.507676, lng: 22.041122 },
    { lat: 48.507807, lng: 22.040772 },
    { lat: 48.507931, lng: 22.040459 },
    { lat: 48.50807, lng: 22.040125 },
    { lat: 48.508169, lng: 22.039897 },
    { lat: 48.508348, lng: 22.039578 },
    { lat: 48.508482, lng: 22.03945 },
    { lat: 48.508606, lng: 22.039355 },
    { lat: 48.508778, lng: 22.03924 },
    { lat: 48.509042, lng: 22.039041 },
    { lat: 48.50941, lng: 22.038779 },
    { lat: 48.509715, lng: 22.038598 },
    { lat: 48.510017, lng: 22.038428 },
    { lat: 48.51044, lng: 22.038115 },
    { lat: 48.510815, lng: 22.037852 },
    { lat: 48.511262, lng: 22.037543 },
    { lat: 48.511552, lng: 22.037357 },
    { lat: 48.511783, lng: 22.037212 },
    { lat: 48.511977, lng: 22.037172 },
    { lat: 48.512137, lng: 22.037132 },
    { lat: 48.512316, lng: 22.037101 },
    { lat: 48.512537, lng: 22.037052 },
    { lat: 48.512785, lng: 22.036969 },
    { lat: 48.512912, lng: 22.036875 },
    { lat: 48.513212, lng: 22.036677 },
    { lat: 48.513365, lng: 22.036528 },
    { lat: 48.513498, lng: 22.036344 },
    { lat: 48.513638, lng: 22.036163 },
    { lat: 48.513771, lng: 22.036069 },
    { lat: 48.514117, lng: 22.035877 },
    { lat: 48.514324, lng: 22.035767 },
    { lat: 48.514488, lng: 22.035725 },
    { lat: 48.514714, lng: 22.035671 },
    { lat: 48.514999, lng: 22.035627 },
    { lat: 48.515193, lng: 22.03557 },
    { lat: 48.515296, lng: 22.035482 },
    { lat: 48.515401, lng: 22.035329 },
    { lat: 48.515453, lng: 22.035198 },
    { lat: 48.515525, lng: 22.035016 },
    { lat: 48.515586, lng: 22.034773 },
    { lat: 48.515599, lng: 22.034624 },
    { lat: 48.515607, lng: 22.034256 },
    { lat: 48.5156087, lng: 22.0340651 },
    { lat: 48.515612, lng: 22.033694 },
    { lat: 48.515608, lng: 22.033196 },
    { lat: 48.515601, lng: 22.032569 },
    { lat: 48.515595, lng: 22.032104 },
    { lat: 48.515586, lng: 22.031614 },
    { lat: 48.515546, lng: 22.031215 },
    { lat: 48.515475, lng: 22.030539 },
    { lat: 48.515407, lng: 22.030011 },
    { lat: 48.515337, lng: 22.02952 },
    { lat: 48.515294, lng: 22.029213 },
    { lat: 48.515263, lng: 22.029049 },
    { lat: 48.515193, lng: 22.028884 },
    { lat: 48.515117, lng: 22.028748 },
    { lat: 48.515013, lng: 22.028605 },
    { lat: 48.514934, lng: 22.028527 },
    { lat: 48.514732, lng: 22.028431 },
    { lat: 48.514528, lng: 22.028339 },
    { lat: 48.514127, lng: 22.028212 },
    { lat: 48.513839, lng: 22.028126 },
    { lat: 48.513734, lng: 22.028123 },
    { lat: 48.513609, lng: 22.028099 },
    { lat: 48.513542, lng: 22.028087 },
    { lat: 48.513456, lng: 22.028058 },
    { lat: 48.513484, lng: 22.027584 },
    { lat: 48.513508, lng: 22.027143 },
    { lat: 48.513554, lng: 22.026279 },
    { lat: 48.513588, lng: 22.025657 },
    { lat: 48.513649, lng: 22.024805 },
    { lat: 48.513695, lng: 22.02389 },
    { lat: 48.513725, lng: 22.023508 },
    { lat: 48.513806, lng: 22.023399 },
    { lat: 48.51395, lng: 22.023319 },
    { lat: 48.514004, lng: 22.02329 },
    { lat: 48.514049, lng: 22.023251 },
    { lat: 48.514135, lng: 22.023205 },
    { lat: 48.514279, lng: 22.023088 },
    { lat: 48.514411, lng: 22.022991 },
    { lat: 48.514583, lng: 22.022908 },
    { lat: 48.514687, lng: 22.022867 },
    { lat: 48.514847, lng: 22.022787 },
    { lat: 48.514965, lng: 22.022724 },
    { lat: 48.515184, lng: 22.022584 },
    { lat: 48.515277, lng: 22.02252 },
    { lat: 48.515361, lng: 22.022424 },
    { lat: 48.515474, lng: 22.02232 },
    { lat: 48.515579, lng: 22.022157 },
    { lat: 48.515659, lng: 22.021975 },
    { lat: 48.515678, lng: 22.021831 },
    { lat: 48.515646, lng: 22.021682 },
    { lat: 48.515583, lng: 22.021543 },
    { lat: 48.515497, lng: 22.021459 },
    { lat: 48.515411, lng: 22.021351 },
    { lat: 48.51531, lng: 22.02127 },
    { lat: 48.51526, lng: 22.021273 },
    { lat: 48.514861, lng: 22.021287 },
    { lat: 48.514381, lng: 22.021298 },
    { lat: 48.514146, lng: 22.021306 },
    { lat: 48.514026, lng: 22.021309 },
    { lat: 48.513954, lng: 22.021304 },
    { lat: 48.513787, lng: 22.02131 },
    { lat: 48.513517, lng: 22.021306 },
    { lat: 48.51345, lng: 22.021305 },
    { lat: 48.513237, lng: 22.021304 },
    { lat: 48.51305, lng: 22.021284 },
    { lat: 48.512924, lng: 22.021227 },
    { lat: 48.512762, lng: 22.021098 },
    { lat: 48.51263, lng: 22.02091 },
    { lat: 48.51252, lng: 22.020729 },
    { lat: 48.512439, lng: 22.020497 },
    { lat: 48.512383, lng: 22.020249 },
    { lat: 48.51236, lng: 22.02003 },
    { lat: 48.512443, lng: 22.019808 },
    { lat: 48.512526, lng: 22.019607 },
    { lat: 48.512629, lng: 22.019415 },
    { lat: 48.512718, lng: 22.019306 },
    { lat: 48.512825, lng: 22.019174 },
    { lat: 48.512985, lng: 22.01898 },
    { lat: 48.513208, lng: 22.018707 },
    { lat: 48.513438, lng: 22.018428 },
    { lat: 48.513557, lng: 22.018262 },
    { lat: 48.513604, lng: 22.018198 },
    { lat: 48.513708, lng: 22.017999 },
    { lat: 48.513784, lng: 22.017825 },
    { lat: 48.513982, lng: 22.017485 },
    { lat: 48.514121, lng: 22.017202 },
    { lat: 48.514157, lng: 22.017138 },
    { lat: 48.514274, lng: 22.016866 },
    { lat: 48.514409, lng: 22.016384 },
    { lat: 48.514603, lng: 22.015643 },
    { lat: 48.514742, lng: 22.015107 },
    { lat: 48.514818, lng: 22.014713 },
    { lat: 48.514831, lng: 22.014335 },
    { lat: 48.514851, lng: 22.013851 },
    { lat: 48.514825, lng: 22.013381 },
    { lat: 48.514787, lng: 22.012947 },
    { lat: 48.514745, lng: 22.012712 },
    { lat: 48.514666, lng: 22.012207 },
    { lat: 48.514605, lng: 22.011862 },
    { lat: 48.51445, lng: 22.011391 },
    { lat: 48.514268, lng: 22.010989 },
    { lat: 48.514054, lng: 22.010619 },
    { lat: 48.513931, lng: 22.010456 },
    { lat: 48.513835, lng: 22.010518 },
    { lat: 48.513668, lng: 22.010751 },
    { lat: 48.513365, lng: 22.011191 },
    { lat: 48.513046, lng: 22.01146 },
    { lat: 48.512796, lng: 22.011599 },
    { lat: 48.512548, lng: 22.011765 },
    { lat: 48.512436, lng: 22.01184 },
    { lat: 48.51087, lng: 22.009683 },
    { lat: 48.510501, lng: 22.009118 },
    { lat: 48.5100141, lng: 22.0095991 },
    { lat: 48.5095891, lng: 22.010339 },
    { lat: 48.5093333, lng: 22.0100012 },
    { lat: 48.5088977, lng: 22.0105409 },
    { lat: 48.5078844, lng: 22.0111079 },
    { lat: 48.5075177, lng: 22.0111967 },
    { lat: 48.5070856, lng: 22.0109645 },
    { lat: 48.5066681, lng: 22.0109181 },
    { lat: 48.5065089, lng: 22.0110295 },
    { lat: 48.5064568, lng: 22.0116067 },
    { lat: 48.5063376, lng: 22.012171 },
    { lat: 48.5059644, lng: 22.0124103 },
    { lat: 48.5056041, lng: 22.0123486 },
    { lat: 48.505137, lng: 22.0117455 },
    { lat: 48.5036074, lng: 22.0101524 },
    { lat: 48.5021466, lng: 22.0093455 },
    { lat: 48.5017683, lng: 22.0084158 },
    { lat: 48.5010235, lng: 22.0077182 },
    { lat: 48.5000298, lng: 22.0071583 },
    { lat: 48.4998515, lng: 22.00727 },
    { lat: 48.4993729, lng: 22.0084391 },
    { lat: 48.4991831, lng: 22.0085915 },
    { lat: 48.4984193, lng: 22.0084565 },
    { lat: 48.4983624, lng: 22.0084338 },
    { lat: 48.4984273, lng: 22.0078232 },
    { lat: 48.4985159, lng: 22.007532 },
    { lat: 48.498594, lng: 22.0072752 },
    { lat: 48.4968733, lng: 22.0065531 },
    { lat: 48.4965389, lng: 22.0064128 },
    { lat: 48.4962026, lng: 22.0070117 },
    { lat: 48.4961736, lng: 22.0070468 },
    { lat: 48.4959544, lng: 22.0073114 },
    { lat: 48.495338, lng: 22.0075836 },
    { lat: 48.4951029, lng: 22.0079077 },
    { lat: 48.4947811, lng: 22.008736 },
    { lat: 48.4933003, lng: 22.0109699 },
    { lat: 48.4928893, lng: 22.0111395 },
    { lat: 48.492463, lng: 22.0111369 },
    { lat: 48.4920764, lng: 22.0109246 },
    { lat: 48.4913028, lng: 22.0102089 },
    { lat: 48.4900353, lng: 22.0099873 },
    { lat: 48.489945, lng: 22.0103906 },
    { lat: 48.489887, lng: 22.0103707 },
    { lat: 48.489686, lng: 22.0098768 },
    { lat: 48.4891038, lng: 22.0095796 },
    { lat: 48.4888181, lng: 22.008339 },
    { lat: 48.4887331, lng: 22.0082164 },
    { lat: 48.4882708, lng: 22.0084952 },
    { lat: 48.4879147, lng: 22.0088838 },
    { lat: 48.487252, lng: 22.009161 },
    { lat: 48.4869424, lng: 22.0087381 },
    { lat: 48.486954, lng: 22.0082481 },
    { lat: 48.486841, lng: 22.0081394 },
    { lat: 48.4864775, lng: 22.0079947 },
    { lat: 48.4860712, lng: 22.0076144 },
    { lat: 48.4857909, lng: 22.0070196 },
    { lat: 48.4856551, lng: 22.0069328 },
    { lat: 48.4851916, lng: 22.0070263 },
    { lat: 48.4844285, lng: 22.0067867 },
    { lat: 48.4839858, lng: 22.0073545 },
    { lat: 48.4832841, lng: 22.0076832 },
    { lat: 48.4828862, lng: 22.0077053 },
    { lat: 48.4818772, lng: 22.0070687 },
    { lat: 48.4814104, lng: 22.0074322 },
    { lat: 48.4810234, lng: 22.0074845 },
    { lat: 48.4809309, lng: 22.0075669 },
    { lat: 48.4807785, lng: 22.0079537 },
    { lat: 48.480587, lng: 22.0078059 },
    { lat: 48.4802582, lng: 22.0072429 },
    { lat: 48.4798251, lng: 22.0072407 },
    { lat: 48.4793875, lng: 22.0073802 },
    { lat: 48.4789576, lng: 22.0069134 },
    { lat: 48.4790173, lng: 22.0065516 },
    { lat: 48.4795638, lng: 22.0059021 },
    { lat: 48.4794646, lng: 22.0054307 },
    { lat: 48.4795051, lng: 22.0052001 },
    { lat: 48.4789869, lng: 22.0043524 },
    { lat: 48.4780803, lng: 22.0047662 },
    { lat: 48.4774462, lng: 22.0051935 },
    { lat: 48.4771035, lng: 22.0059416 },
    { lat: 48.4768549, lng: 22.006228 },
    { lat: 48.4766578, lng: 22.006351 },
    { lat: 48.4764626, lng: 22.0066951 },
    { lat: 48.476301, lng: 22.006808 },
    { lat: 48.4748317, lng: 22.0070767 },
    { lat: 48.4746823, lng: 22.0069453 },
    { lat: 48.4750155, lng: 22.0063553 },
    { lat: 48.4750233, lng: 22.0060754 },
    { lat: 48.4749937, lng: 22.0058399 },
    { lat: 48.4747985, lng: 22.0055795 },
    { lat: 48.4728903, lng: 22.0064884 },
    { lat: 48.4716034, lng: 22.0074121 },
    { lat: 48.4719147, lng: 22.0089158 },
    { lat: 48.4668064, lng: 22.0108923 },
    { lat: 48.4660701, lng: 22.011563 },
    { lat: 48.4651585, lng: 22.00966 },
    { lat: 48.4646084, lng: 22.0080085 },
    { lat: 48.4645133, lng: 22.0060638 },
    { lat: 48.4643018, lng: 22.0051411 },
    { lat: 48.4638596, lng: 22.0041352 },
    { lat: 48.4636021, lng: 22.0039138 },
    { lat: 48.4629968, lng: 22.0036741 },
    { lat: 48.4625536, lng: 22.0037707 },
    { lat: 48.4616955, lng: 22.0042402 },
    { lat: 48.461158, lng: 22.0047581 },
    { lat: 48.4596034, lng: 22.0066354 },
    { lat: 48.4587891, lng: 22.007746 },
    { lat: 48.4580931, lng: 22.008542 },
    { lat: 48.4576181, lng: 22.0085786 },
    { lat: 48.4575094, lng: 22.0083677 },
    { lat: 48.4571515, lng: 22.0081249 },
    { lat: 48.4567663, lng: 22.0077046 },
    { lat: 48.4566203, lng: 22.0074303 },
    { lat: 48.4566022, lng: 22.0070194 },
    { lat: 48.4567132, lng: 22.0063183 },
    { lat: 48.4571434, lng: 22.0045035 },
    { lat: 48.4574698, lng: 22.0034983 },
    { lat: 48.4583229, lng: 22.0012244 },
    { lat: 48.4585706, lng: 22.000735 },
    { lat: 48.4587628, lng: 22.0005336 },
    { lat: 48.4591899, lng: 22.0004372 },
    { lat: 48.4612585, lng: 21.9993701 },
    { lat: 48.4612005, lng: 21.9991132 },
    { lat: 48.4605924, lng: 21.99927 },
    { lat: 48.4587588, lng: 21.9992784 },
    { lat: 48.4556549, lng: 22.001748 },
    { lat: 48.4556547, lng: 22.0013262 },
    { lat: 48.4554803, lng: 22.0015117 },
    { lat: 48.4551474, lng: 22.0014721 },
    { lat: 48.4533109, lng: 22.0009401 },
    { lat: 48.4529155, lng: 22.0006506 },
    { lat: 48.4526206, lng: 22.0000957 },
    { lat: 48.4523057, lng: 21.9996358 },
    { lat: 48.4516278, lng: 21.9981475 },
    { lat: 48.4513854, lng: 21.9973977 },
    { lat: 48.4510343, lng: 21.9972971 },
    { lat: 48.4509031, lng: 21.9971389 },
    { lat: 48.4506788, lng: 21.9970633 },
    { lat: 48.4497233, lng: 21.996741 },
    { lat: 48.449224, lng: 21.9968372 },
    { lat: 48.4465423, lng: 21.9978578 },
    { lat: 48.4445598, lng: 22.000953 },
    { lat: 48.4441314, lng: 22.0010094 },
    { lat: 48.4430885, lng: 22.0014578 },
    { lat: 48.4407012, lng: 22.0028316 },
    { lat: 48.4404131, lng: 22.0031016 },
    { lat: 48.4407078, lng: 22.0039721 },
    { lat: 48.4401007, lng: 22.0045354 },
    { lat: 48.4382315, lng: 22.0059766 },
    { lat: 48.4383654, lng: 22.0064354 },
    { lat: 48.4377101, lng: 22.0072349 },
    { lat: 48.4379213, lng: 22.0077242 },
    { lat: 48.4368442, lng: 22.0091273 },
    { lat: 48.4365037, lng: 22.009701 },
    { lat: 48.436397, lng: 22.0100229 },
    { lat: 48.4363395, lng: 22.0104349 },
    { lat: 48.4366824, lng: 22.0107493 },
    { lat: 48.4365051, lng: 22.0110872 },
    { lat: 48.436354, lng: 22.0115281 },
    { lat: 48.4358487, lng: 22.0123018 },
    { lat: 48.4354293, lng: 22.0127753 },
    { lat: 48.4349627, lng: 22.0136074 },
    { lat: 48.4354592, lng: 22.0141762 },
    { lat: 48.4356251, lng: 22.0145603 },
    { lat: 48.4360105, lng: 22.0160138 },
    { lat: 48.4362983, lng: 22.017803 },
    { lat: 48.4366684, lng: 22.0189003 },
    { lat: 48.4368578, lng: 22.0197936 },
    { lat: 48.4368007, lng: 22.0200317 },
    { lat: 48.4369566, lng: 22.0210282 },
    { lat: 48.4374921, lng: 22.0212186 },
    { lat: 48.4377794, lng: 22.0214157 },
    { lat: 48.4380612, lng: 22.0219897 },
    { lat: 48.441794, lng: 22.027618 },
    { lat: 48.4417551, lng: 22.028287 },
    { lat: 48.44215, lng: 22.0286185 },
    { lat: 48.4430129, lng: 22.0291734 },
    { lat: 48.4434768, lng: 22.029376 },
    { lat: 48.4438677, lng: 22.0294346 },
    { lat: 48.4438725, lng: 22.0296792 },
    { lat: 48.4442482, lng: 22.0297273 },
    { lat: 48.4454958, lng: 22.0300959 },
    { lat: 48.447333, lng: 22.0306522 },
    { lat: 48.4474751, lng: 22.0307592 },
    { lat: 48.4472826, lng: 22.0316812 },
    { lat: 48.4475166, lng: 22.0339461 },
    { lat: 48.4476018, lng: 22.035232 },
    { lat: 48.4477518, lng: 22.036408 },
    { lat: 48.4476361, lng: 22.0381748 },
    { lat: 48.4471558, lng: 22.0391532 },
    { lat: 48.4463834, lng: 22.0399036 },
    { lat: 48.4448699, lng: 22.0405449 },
    { lat: 48.4430769, lng: 22.0406907 },
    { lat: 48.4421171, lng: 22.0410044 },
    { lat: 48.4415649, lng: 22.0416642 },
    { lat: 48.4414608, lng: 22.0419105 },
    { lat: 48.4414215, lng: 22.0421933 },
    { lat: 48.4414587, lng: 22.0430381 },
    { lat: 48.44152, lng: 22.0432919 },
    { lat: 48.4418658, lng: 22.04405 },
    { lat: 48.4421281, lng: 22.044385 },
    { lat: 48.4440435, lng: 22.0451597 },
    { lat: 48.4460043, lng: 22.0462639 },
    { lat: 48.4465648, lng: 22.0467499 },
    { lat: 48.4470092, lng: 22.0470239 },
    { lat: 48.4473621, lng: 22.0475209 },
    { lat: 48.4477906, lng: 22.0484064 },
    { lat: 48.4482676, lng: 22.0501266 },
    { lat: 48.4490351, lng: 22.0518097 },
    { lat: 48.4493039, lng: 22.0522242 },
    { lat: 48.4497116, lng: 22.0526672 },
    { lat: 48.4500623, lng: 22.0527051 },
    { lat: 48.4506361, lng: 22.0538666 },
    { lat: 48.4508038, lng: 22.0541059 },
    { lat: 48.4511447, lng: 22.0542429 },
    { lat: 48.4524643, lng: 22.0542025 },
    { lat: 48.4524583, lng: 22.0543743 },
    { lat: 48.4533818, lng: 22.0546922 },
    { lat: 48.4536967, lng: 22.0547402 },
    { lat: 48.454953, lng: 22.054313 },
    { lat: 48.4550247, lng: 22.0541099 },
    { lat: 48.4552218, lng: 22.0538851 },
    { lat: 48.4552968, lng: 22.0541015 },
    { lat: 48.4555343, lng: 22.054809 },
    { lat: 48.455744, lng: 22.0550113 },
    { lat: 48.4564349, lng: 22.0560953 },
    { lat: 48.4570937, lng: 22.0572924 },
    { lat: 48.4564092, lng: 22.0583933 },
    { lat: 48.4558793, lng: 22.0590388 },
    { lat: 48.4566363, lng: 22.0607168 },
    { lat: 48.4572404, lng: 22.0627786 },
    { lat: 48.4571332, lng: 22.0633677 },
    { lat: 48.4581685, lng: 22.0640348 },
    { lat: 48.4605873, lng: 22.0658262 },
    { lat: 48.4614222, lng: 22.066255 },
    { lat: 48.4614598, lng: 22.0662828 },
    { lat: 48.4618861, lng: 22.0665974 },
    { lat: 48.462446, lng: 22.0672394 },
    { lat: 48.462701, lng: 22.0674612 },
    { lat: 48.4628952, lng: 22.067539 },
    { lat: 48.4641233, lng: 22.0688323 },
    { lat: 48.4645267, lng: 22.0693769 },
    { lat: 48.4646305, lng: 22.0695772 },
    { lat: 48.4646571, lng: 22.069798 },
    { lat: 48.4651351, lng: 22.0700248 },
    { lat: 48.4654496, lng: 22.0706125 },
    { lat: 48.465744, lng: 22.070454 },
    { lat: 48.4663034, lng: 22.0703287 },
    { lat: 48.4671528, lng: 22.0704768 },
    { lat: 48.4677249, lng: 22.0709183 },
    { lat: 48.4684363, lng: 22.0720184 },
    { lat: 48.4685993, lng: 22.0721123 },
    { lat: 48.4691503, lng: 22.0722434 },
    { lat: 48.4695142, lng: 22.0722237 },
    { lat: 48.4701347, lng: 22.0718217 },
    { lat: 48.4704361, lng: 22.0713062 },
    { lat: 48.4707085, lng: 22.0704662 },
    { lat: 48.4709216, lng: 22.0703968 },
    { lat: 48.4712466, lng: 22.0704317 },
    { lat: 48.4715987, lng: 22.0709022 },
    { lat: 48.47198, lng: 22.0711321 },
    { lat: 48.4720301, lng: 22.0714942 },
    { lat: 48.4719256, lng: 22.0721483 },
    { lat: 48.471918, lng: 22.0725411 },
    { lat: 48.4718312, lng: 22.0727635 },
    { lat: 48.4717734, lng: 22.0733112 },
    { lat: 48.4718234, lng: 22.0736169 },
    { lat: 48.4721259, lng: 22.0736983 },
    { lat: 48.4720369, lng: 22.0744022 },
    { lat: 48.472296, lng: 22.0746027 },
    { lat: 48.4726422, lng: 22.0747364 },
    { lat: 48.4732199, lng: 22.0746228 },
    { lat: 48.4731873, lng: 22.0748586 },
    { lat: 48.4728679, lng: 22.0752308 },
    { lat: 48.4729638, lng: 22.0755255 },
    { lat: 48.4732525, lng: 22.0759364 },
    { lat: 48.4732867, lng: 22.0766063 },
    { lat: 48.4735383, lng: 22.0772532 },
    { lat: 48.4735826, lng: 22.0775543 },
    { lat: 48.473215, lng: 22.0780839 },
    { lat: 48.4730249, lng: 22.0787912 },
    { lat: 48.473087, lng: 22.0795057 },
    { lat: 48.4736072, lng: 22.0796187 },
    { lat: 48.4736751, lng: 22.0799185 },
    { lat: 48.4736778, lng: 22.0809752 },
    { lat: 48.4737401, lng: 22.0811764 },
    { lat: 48.4739206, lng: 22.0812028 },
    { lat: 48.4755322, lng: 22.0806697 },
    { lat: 48.4757755, lng: 22.0808168 },
    { lat: 48.4758089, lng: 22.0818903 },
    { lat: 48.4758783, lng: 22.0820596 },
    { lat: 48.4761062, lng: 22.0823259 },
    { lat: 48.4762701, lng: 22.082311 },
    { lat: 48.4764673, lng: 22.0825134 },
    { lat: 48.4768137, lng: 22.0824189 },
    { lat: 48.4768941, lng: 22.0830984 },
    { lat: 48.4768843, lng: 22.0836279 },
    { lat: 48.4762745, lng: 22.0863163 },
    { lat: 48.476322, lng: 22.0866109 },
    { lat: 48.4764033, lng: 22.0868057 },
    { lat: 48.4765762, lng: 22.0870294 },
    { lat: 48.4771228, lng: 22.0873544 },
    { lat: 48.4775988, lng: 22.0874529 },
    { lat: 48.4780113, lng: 22.0874434 },
    { lat: 48.4784453, lng: 22.0872473 },
    { lat: 48.479313, lng: 22.087113 },
    { lat: 48.479338, lng: 22.087076 },
    { lat: 48.479402, lng: 22.086981 },
    { lat: 48.479597, lng: 22.086692 },
    { lat: 48.480076, lng: 22.085956 },
    { lat: 48.480101, lng: 22.085917 },
    { lat: 48.480122, lng: 22.085886 },
    { lat: 48.480351, lng: 22.085534 },
    { lat: 48.480372, lng: 22.085501 },
    { lat: 48.480391, lng: 22.085472 },
    { lat: 48.480968, lng: 22.084585 },
    { lat: 48.481346, lng: 22.08397 },
    { lat: 48.481538, lng: 22.08368 },
    { lat: 48.481832, lng: 22.083199 },
    { lat: 48.481871, lng: 22.083138 },
    { lat: 48.48195, lng: 22.083015 },
    { lat: 48.482125, lng: 22.08274 },
    { lat: 48.482141, lng: 22.082717 },
    { lat: 48.482171, lng: 22.08267 },
    { lat: 48.482209, lng: 22.082612 },
    { lat: 48.48226, lng: 22.082534 },
    { lat: 48.482364, lng: 22.082376 },
    { lat: 48.482576, lng: 22.082054 },
    { lat: 48.482709, lng: 22.081851 },
    { lat: 48.482871, lng: 22.081605 },
    { lat: 48.483845, lng: 22.0802896 },
    { lat: 48.484382, lng: 22.079638 },
    { lat: 48.485608, lng: 22.078026 },
    { lat: 48.485847, lng: 22.077782 },
    { lat: 48.487045, lng: 22.076563 },
    { lat: 48.488144, lng: 22.075518 },
    { lat: 48.489617, lng: 22.074192 },
    { lat: 48.49025, lng: 22.073633 },
    { lat: 48.490484, lng: 22.073426 },
    { lat: 48.490924, lng: 22.073122 },
    { lat: 48.491661, lng: 22.072412 },
    { lat: 48.491972, lng: 22.071992 },
    { lat: 48.492787, lng: 22.070877 },
    { lat: 48.493406, lng: 22.069782 },
    { lat: 48.493641, lng: 22.068761 },
  ],
  ZemplínskeJastrabie: [
    { lat: 48.520887, lng: 21.782126 },
    { lat: 48.5209082, lng: 21.7818234 },
    { lat: 48.5210478, lng: 21.781316 },
    { lat: 48.5211279, lng: 21.7808087 },
    { lat: 48.5211245, lng: 21.7794038 },
    { lat: 48.5206407, lng: 21.7779614 },
    { lat: 48.5204632, lng: 21.777297 },
    { lat: 48.5195348, lng: 21.7767739 },
    { lat: 48.5193682, lng: 21.7766423 },
    { lat: 48.5190125, lng: 21.7761729 },
    { lat: 48.5180328, lng: 21.7758396 },
    { lat: 48.517888, lng: 21.7756872 },
    { lat: 48.5164468, lng: 21.7756439 },
    { lat: 48.5160051, lng: 21.7746455 },
    { lat: 48.515767, lng: 21.774788 },
    { lat: 48.5153587, lng: 21.7731812 },
    { lat: 48.5150039, lng: 21.7710062 },
    { lat: 48.515218, lng: 21.7708912 },
    { lat: 48.5148446, lng: 21.7688157 },
    { lat: 48.5147201, lng: 21.7688771 },
    { lat: 48.5143371, lng: 21.76649 },
    { lat: 48.5141513, lng: 21.7657616 },
    { lat: 48.5133475, lng: 21.7613492 },
    { lat: 48.5135219, lng: 21.7612663 },
    { lat: 48.5131865, lng: 21.7597816 },
    { lat: 48.5128382, lng: 21.7587874 },
    { lat: 48.5126298, lng: 21.7589064 },
    { lat: 48.5124239, lng: 21.7584863 },
    { lat: 48.5120787, lng: 21.7587502 },
    { lat: 48.5117741, lng: 21.7572389 },
    { lat: 48.5116116, lng: 21.7572636 },
    { lat: 48.5111672, lng: 21.7549396 },
    { lat: 48.5107324, lng: 21.7550983 },
    { lat: 48.5106265, lng: 21.7546271 },
    { lat: 48.5105298, lng: 21.7546727 },
    { lat: 48.5105039, lng: 21.7545415 },
    { lat: 48.5102368, lng: 21.7546915 },
    { lat: 48.510153, lng: 21.754335 },
    { lat: 48.5097374, lng: 21.7545322 },
    { lat: 48.509697, lng: 21.7540666 },
    { lat: 48.5096039, lng: 21.7540872 },
    { lat: 48.5094462, lng: 21.7530201 },
    { lat: 48.5093776, lng: 21.7530215 },
    { lat: 48.5092395, lng: 21.7520264 },
    { lat: 48.5076541, lng: 21.7523114 },
    { lat: 48.5075799, lng: 21.7517692 },
    { lat: 48.506681, lng: 21.7517714 },
    { lat: 48.5066932, lng: 21.7525633 },
    { lat: 48.5065271, lng: 21.7526209 },
    { lat: 48.5064882, lng: 21.7512974 },
    { lat: 48.5063229, lng: 21.7513414 },
    { lat: 48.5035988, lng: 21.7520449 },
    { lat: 48.5030492, lng: 21.7522801 },
    { lat: 48.5027977, lng: 21.7509503 },
    { lat: 48.5009197, lng: 21.7515745 },
    { lat: 48.5006512, lng: 21.7502011 },
    { lat: 48.5004461, lng: 21.7503583 },
    { lat: 48.5003804, lng: 21.7500888 },
    { lat: 48.5000478, lng: 21.7503555 },
    { lat: 48.4999344, lng: 21.7498192 },
    { lat: 48.4994691, lng: 21.7500954 },
    { lat: 48.4981088, lng: 21.7511487 },
    { lat: 48.4972456, lng: 21.7519296 },
    { lat: 48.496433, lng: 21.7527642 },
    { lat: 48.4960225, lng: 21.7532402 },
    { lat: 48.4960717, lng: 21.7533493 },
    { lat: 48.4956727, lng: 21.7537882 },
    { lat: 48.4958506, lng: 21.7542187 },
    { lat: 48.4954093, lng: 21.7544568 },
    { lat: 48.4949307, lng: 21.7544857 },
    { lat: 48.4945963, lng: 21.7546386 },
    { lat: 48.4943749, lng: 21.7548592 },
    { lat: 48.4940111, lng: 21.7541703 },
    { lat: 48.4939492, lng: 21.7542202 },
    { lat: 48.4900426, lng: 21.7527744 },
    { lat: 48.4890648, lng: 21.752563 },
    { lat: 48.4862757, lng: 21.7524391 },
    { lat: 48.4832334, lng: 21.7514723 },
    { lat: 48.48313, lng: 21.7508828 },
    { lat: 48.4831853, lng: 21.7499736 },
    { lat: 48.482969, lng: 21.7491136 },
    { lat: 48.4825848, lng: 21.7483882 },
    { lat: 48.4819879, lng: 21.748152 },
    { lat: 48.4809683, lng: 21.7483925 },
    { lat: 48.4808114, lng: 21.7483807 },
    { lat: 48.4800649, lng: 21.7483157 },
    { lat: 48.481159, lng: 21.7512276 },
    { lat: 48.4812849, lng: 21.7518633 },
    { lat: 48.4814597, lng: 21.7520896 },
    { lat: 48.4816527, lng: 21.7521893 },
    { lat: 48.4818654, lng: 21.7524048 },
    { lat: 48.4820402, lng: 21.7527169 },
    { lat: 48.48237, lng: 21.7531389 },
    { lat: 48.4825314, lng: 21.7535841 },
    { lat: 48.4826682, lng: 21.7543617 },
    { lat: 48.4825814, lng: 21.7549855 },
    { lat: 48.482587, lng: 21.7555334 },
    { lat: 48.4825241, lng: 21.7558777 },
    { lat: 48.4823271, lng: 21.7561637 },
    { lat: 48.4821815, lng: 21.7565761 },
    { lat: 48.482006, lng: 21.7580482 },
    { lat: 48.4819737, lng: 21.7595392 },
    { lat: 48.4818765, lng: 21.7599792 },
    { lat: 48.481795, lng: 21.760088 },
    { lat: 48.4808725, lng: 21.7599678 },
    { lat: 48.479886, lng: 21.7616178 },
    { lat: 48.4801629, lng: 21.7621795 },
    { lat: 48.4817639, lng: 21.7641586 },
    { lat: 48.4825314, lng: 21.7642053 },
    { lat: 48.4827705, lng: 21.7643575 },
    { lat: 48.4828688, lng: 21.7644991 },
    { lat: 48.4829038, lng: 21.7648843 },
    { lat: 48.4828464, lng: 21.764881 },
    { lat: 48.4828288, lng: 21.7649803 },
    { lat: 48.4829165, lng: 21.7651052 },
    { lat: 48.4829571, lng: 21.7654063 },
    { lat: 48.4832102, lng: 21.7657822 },
    { lat: 48.4832021, lng: 21.7659845 },
    { lat: 48.4830948, lng: 21.7662669 },
    { lat: 48.4828903, lng: 21.7665732 },
    { lat: 48.4828337, lng: 21.7669451 },
    { lat: 48.4830943, lng: 21.7676493 },
    { lat: 48.4836107, lng: 21.7684485 },
    { lat: 48.4839052, lng: 21.769999 },
    { lat: 48.4833431, lng: 21.770702 },
    { lat: 48.4831675, lng: 21.7710289 },
    { lat: 48.4831231, lng: 21.7716026 },
    { lat: 48.4829029, lng: 21.771791 },
    { lat: 48.4827528, lng: 21.7721501 },
    { lat: 48.4823426, lng: 21.7735252 },
    { lat: 48.4829297, lng: 21.7753383 },
    { lat: 48.4826847, lng: 21.7755161 },
    { lat: 48.482898, lng: 21.7761335 },
    { lat: 48.483117, lng: 21.776463 },
    { lat: 48.4832355, lng: 21.7767715 },
    { lat: 48.4831843, lng: 21.7770451 },
    { lat: 48.4831023, lng: 21.7772231 },
    { lat: 48.4827229, lng: 21.7776417 },
    { lat: 48.4830795, lng: 21.7791222 },
    { lat: 48.4831788, lng: 21.7799401 },
    { lat: 48.4833035, lng: 21.7805273 },
    { lat: 48.482418, lng: 21.7809939 },
    { lat: 48.4809164, lng: 21.7815616 },
    { lat: 48.481116, lng: 21.7824674 },
    { lat: 48.48072, lng: 21.7827273 },
    { lat: 48.4790556, lng: 21.7834784 },
    { lat: 48.4791597, lng: 21.7837952 },
    { lat: 48.4795014, lng: 21.7854404 },
    { lat: 48.4798019, lng: 21.7859164 },
    { lat: 48.4801096, lng: 21.7866129 },
    { lat: 48.4802025, lng: 21.7867651 },
    { lat: 48.4803932, lng: 21.7867459 },
    { lat: 48.4807184, lng: 21.7872556 },
    { lat: 48.4808945, lng: 21.7873635 },
    { lat: 48.4810797, lng: 21.7876099 },
    { lat: 48.4812842, lng: 21.7881023 },
    { lat: 48.4816209, lng: 21.7886669 },
    { lat: 48.4821897, lng: 21.7899759 },
    { lat: 48.4822285, lng: 21.7899527 },
    { lat: 48.4825815, lng: 21.7903231 },
    { lat: 48.482888, lng: 21.7903839 },
    { lat: 48.4829146, lng: 21.7914724 },
    { lat: 48.4828593, lng: 21.7930053 },
    { lat: 48.4828551, lng: 21.7932279 },
    { lat: 48.4828819, lng: 21.793261 },
    { lat: 48.483678, lng: 21.7938845 },
    { lat: 48.4849502, lng: 21.7930613 },
    { lat: 48.4861798, lng: 21.7927194 },
    { lat: 48.4873906, lng: 21.7926007 },
    { lat: 48.4887153, lng: 21.7922297 },
    { lat: 48.4894473, lng: 21.7915763 },
    { lat: 48.4905842, lng: 21.7907851 },
    { lat: 48.4916171, lng: 21.7906575 },
    { lat: 48.4922099, lng: 21.7903149 },
    { lat: 48.4937003, lng: 21.7897029 },
    { lat: 48.4943105, lng: 21.7895881 },
    { lat: 48.4943543, lng: 21.7898926 },
    { lat: 48.4948533, lng: 21.7898706 },
    { lat: 48.4951748, lng: 21.7899472 },
    { lat: 48.4958607, lng: 21.7897171 },
    { lat: 48.495828, lng: 21.7895843 },
    { lat: 48.4966682, lng: 21.7889303 },
    { lat: 48.4967823, lng: 21.7890813 },
    { lat: 48.4969517, lng: 21.7890173 },
    { lat: 48.4972812, lng: 21.790209 },
    { lat: 48.4978037, lng: 21.7901384 },
    { lat: 48.4981056, lng: 21.7906163 },
    { lat: 48.4986856, lng: 21.7904298 },
    { lat: 48.5005001, lng: 21.7894401 },
    { lat: 48.5005662, lng: 21.7897288 },
    { lat: 48.5002153, lng: 21.7901113 },
    { lat: 48.5003482, lng: 21.7906362 },
    { lat: 48.4999578, lng: 21.7908401 },
    { lat: 48.4999417, lng: 21.791 },
    { lat: 48.4999831, lng: 21.7911653 },
    { lat: 48.5009949, lng: 21.7906281 },
    { lat: 48.5013587, lng: 21.7940068 },
    { lat: 48.5012119, lng: 21.7940716 },
    { lat: 48.501295, lng: 21.7945314 },
    { lat: 48.5005703, lng: 21.794917 },
    { lat: 48.5007928, lng: 21.7956749 },
    { lat: 48.5017602, lng: 21.7951957 },
    { lat: 48.5039018, lng: 21.7946667 },
    { lat: 48.5040983, lng: 21.7957154 },
    { lat: 48.5045023, lng: 21.7961852 },
    { lat: 48.5048178, lng: 21.7962057 },
    { lat: 48.5045631, lng: 21.7952928 },
    { lat: 48.5041829, lng: 21.7929065 },
    { lat: 48.5049914, lng: 21.7926322 },
    { lat: 48.505544, lng: 21.7922987 },
    { lat: 48.5073671, lng: 21.790949 },
    { lat: 48.5077819, lng: 21.7907652 },
    { lat: 48.5083382, lng: 21.7904836 },
    { lat: 48.5091307, lng: 21.7902929 },
    { lat: 48.5096551, lng: 21.7897833 },
    { lat: 48.509956, lng: 21.7893331 },
    { lat: 48.5105614, lng: 21.7886167 },
    { lat: 48.5106911, lng: 21.7889641 },
    { lat: 48.5118226, lng: 21.7885306 },
    { lat: 48.5128766, lng: 21.7879636 },
    { lat: 48.5132702, lng: 21.7886377 },
    { lat: 48.5145503, lng: 21.7883333 },
    { lat: 48.5163782, lng: 21.7883595 },
    { lat: 48.5167886, lng: 21.78827 },
    { lat: 48.5170978, lng: 21.7878722 },
    { lat: 48.5167713, lng: 21.7870964 },
    { lat: 48.5165355, lng: 21.7863518 },
    { lat: 48.5173161, lng: 21.7857891 },
    { lat: 48.517284, lng: 21.7855579 },
    { lat: 48.517495, lng: 21.7853611 },
    { lat: 48.5173699, lng: 21.7850176 },
    { lat: 48.5178457, lng: 21.7843308 },
    { lat: 48.5174098, lng: 21.7829952 },
    { lat: 48.5176909, lng: 21.7827317 },
    { lat: 48.5177104, lng: 21.7824867 },
    { lat: 48.5181405, lng: 21.7817334 },
    { lat: 48.5184579, lng: 21.7813145 },
    { lat: 48.5189551, lng: 21.7813581 },
    { lat: 48.5199038, lng: 21.7818297 },
    { lat: 48.5201473, lng: 21.7818935 },
    { lat: 48.5202502, lng: 21.7838655 },
    { lat: 48.5203212, lng: 21.784296 },
    { lat: 48.5206833, lng: 21.7859516 },
    { lat: 48.5208931, lng: 21.7862741 },
    { lat: 48.5212498, lng: 21.7857636 },
    { lat: 48.5210981, lng: 21.7848322 },
    { lat: 48.5208663, lng: 21.7824044 },
    { lat: 48.520887, lng: 21.782126 },
  ],
  Zbehňov: [
    { lat: 48.7026437, lng: 21.6217277 },
    { lat: 48.7025409, lng: 21.6214649 },
    { lat: 48.7025396, lng: 21.6211782 },
    { lat: 48.7024719, lng: 21.6210525 },
    { lat: 48.7025376, lng: 21.6207431 },
    { lat: 48.7027302, lng: 21.6202219 },
    { lat: 48.7028189, lng: 21.6200909 },
    { lat: 48.7027987, lng: 21.6200501 },
    { lat: 48.7024966, lng: 21.6203966 },
    { lat: 48.7022809, lng: 21.6208566 },
    { lat: 48.7023167, lng: 21.6208892 },
    { lat: 48.7023018, lng: 21.6209974 },
    { lat: 48.702211, lng: 21.6210559 },
    { lat: 48.7021102, lng: 21.6213579 },
    { lat: 48.7021533, lng: 21.6214495 },
    { lat: 48.7021273, lng: 21.6215652 },
    { lat: 48.7020741, lng: 21.6215525 },
    { lat: 48.7020909, lng: 21.6218742 },
    { lat: 48.7019735, lng: 21.6222257 },
    { lat: 48.7018862, lng: 21.6222412 },
    { lat: 48.7018763, lng: 21.6223327 },
    { lat: 48.7017802, lng: 21.6223106 },
    { lat: 48.7014896, lng: 21.6225848 },
    { lat: 48.7014685, lng: 21.6225346 },
    { lat: 48.7014085, lng: 21.6218381 },
    { lat: 48.7013721, lng: 21.6216753 },
    { lat: 48.7013366, lng: 21.6213727 },
    { lat: 48.7011118, lng: 21.6206129 },
    { lat: 48.7010452, lng: 21.6205231 },
    { lat: 48.7009283, lng: 21.6199125 },
    { lat: 48.7009798, lng: 21.61962 },
    { lat: 48.7008213, lng: 21.6190242 },
    { lat: 48.7008521, lng: 21.6186735 },
    { lat: 48.7006986, lng: 21.6183734 },
    { lat: 48.7008353, lng: 21.6181107 },
    { lat: 48.7007074, lng: 21.6179621 },
    { lat: 48.7008679, lng: 21.6174992 },
    { lat: 48.7007592, lng: 21.6169476 },
    { lat: 48.7006407, lng: 21.6170534 },
    { lat: 48.7005905, lng: 21.6167492 },
    { lat: 48.7004186, lng: 21.6167562 },
    { lat: 48.700352, lng: 21.6165076 },
    { lat: 48.7001789, lng: 21.6165556 },
    { lat: 48.7000456, lng: 21.6164515 },
    { lat: 48.7000736, lng: 21.6162032 },
    { lat: 48.6999387, lng: 21.6161263 },
    { lat: 48.6999933, lng: 21.6157047 },
    { lat: 48.6998331, lng: 21.6157686 },
    { lat: 48.6995714, lng: 21.6153393 },
    { lat: 48.6995416, lng: 21.6149529 },
    { lat: 48.6994392, lng: 21.614916 },
    { lat: 48.6995166, lng: 21.6145449 },
    { lat: 48.6994379, lng: 21.6143447 },
    { lat: 48.6993388, lng: 21.6145462 },
    { lat: 48.6992175, lng: 21.6144293 },
    { lat: 48.699034, lng: 21.6145043 },
    { lat: 48.6990435, lng: 21.6143206 },
    { lat: 48.6992299, lng: 21.6142244 },
    { lat: 48.6991584, lng: 21.6140868 },
    { lat: 48.6990358, lng: 21.6141623 },
    { lat: 48.6988999, lng: 21.6136932 },
    { lat: 48.6990274, lng: 21.6135815 },
    { lat: 48.6992658, lng: 21.6135407 },
    { lat: 48.6992714, lng: 21.6134937 },
    { lat: 48.6990549, lng: 21.6132731 },
    { lat: 48.6991235, lng: 21.6131124 },
    { lat: 48.6989625, lng: 21.6128654 },
    { lat: 48.6990892, lng: 21.6126056 },
    { lat: 48.6990986, lng: 21.6122146 },
    { lat: 48.6993298, lng: 21.612154 },
    { lat: 48.6991084, lng: 21.6117958 },
    { lat: 48.6991865, lng: 21.6116142 },
    { lat: 48.6991335, lng: 21.6113682 },
    { lat: 48.6991587, lng: 21.6112658 },
    { lat: 48.699259, lng: 21.6112263 },
    { lat: 48.6993222, lng: 21.6107539 },
    { lat: 48.6991494, lng: 21.6106452 },
    { lat: 48.6991523, lng: 21.6105133 },
    { lat: 48.6992967, lng: 21.6104824 },
    { lat: 48.6993637, lng: 21.610144 },
    { lat: 48.6996586, lng: 21.6097456 },
    { lat: 48.6995614, lng: 21.609522 },
    { lat: 48.6994214, lng: 21.6089382 },
    { lat: 48.6995271, lng: 21.6088984 },
    { lat: 48.6996167, lng: 21.6087634 },
    { lat: 48.6995266, lng: 21.608517 },
    { lat: 48.6996485, lng: 21.6082064 },
    { lat: 48.6996757, lng: 21.6080134 },
    { lat: 48.6995752, lng: 21.6079611 },
    { lat: 48.6995942, lng: 21.6077533 },
    { lat: 48.6995042, lng: 21.6076301 },
    { lat: 48.6995052, lng: 21.6075477 },
    { lat: 48.6997314, lng: 21.6073908 },
    { lat: 48.699739, lng: 21.6071731 },
    { lat: 48.699488, lng: 21.6071346 },
    { lat: 48.6994629, lng: 21.6068744 },
    { lat: 48.6997969, lng: 21.606508 },
    { lat: 48.6997413, lng: 21.6061293 },
    { lat: 48.6998383, lng: 21.6060142 },
    { lat: 48.6999362, lng: 21.605651 },
    { lat: 48.6998142, lng: 21.6048706 },
    { lat: 48.6999618, lng: 21.6046336 },
    { lat: 48.6998983, lng: 21.6042928 },
    { lat: 48.6999144, lng: 21.604025 },
    { lat: 48.6997137, lng: 21.6038479 },
    { lat: 48.699719, lng: 21.6037137 },
    { lat: 48.700065, lng: 21.6033725 },
    { lat: 48.70018, lng: 21.6026948 },
    { lat: 48.7003247, lng: 21.6026005 },
    { lat: 48.7005156, lng: 21.6023019 },
    { lat: 48.7004963, lng: 21.602093 },
    { lat: 48.7006221, lng: 21.6020045 },
    { lat: 48.7005673, lng: 21.6016963 },
    { lat: 48.7005631, lng: 21.6016631 },
    { lat: 48.7001186, lng: 21.6016277 },
    { lat: 48.7001321, lng: 21.6013832 },
    { lat: 48.7000504, lng: 21.6013538 },
    { lat: 48.6999695, lng: 21.6009569 },
    { lat: 48.699788, lng: 21.6008231 },
    { lat: 48.6995725, lng: 21.600808 },
    { lat: 48.6990016, lng: 21.6017925 },
    { lat: 48.6985628, lng: 21.6023223 },
    { lat: 48.6981268, lng: 21.6030215 },
    { lat: 48.697723, lng: 21.6035156 },
    { lat: 48.6972414, lng: 21.6042376 },
    { lat: 48.6969977, lng: 21.604459 },
    { lat: 48.6964279, lng: 21.6045985 },
    { lat: 48.6956326, lng: 21.6045637 },
    { lat: 48.6954296, lng: 21.6049089 },
    { lat: 48.6955247, lng: 21.6054631 },
    { lat: 48.6953404, lng: 21.6056675 },
    { lat: 48.6953582, lng: 21.6058654 },
    { lat: 48.6952553, lng: 21.6060814 },
    { lat: 48.694928, lng: 21.6064106 },
    { lat: 48.695005, lng: 21.6066707 },
    { lat: 48.6948859, lng: 21.6069639 },
    { lat: 48.6949791, lng: 21.6072792 },
    { lat: 48.6946871, lng: 21.6074631 },
    { lat: 48.6947032, lng: 21.6077092 },
    { lat: 48.6946183, lng: 21.6079361 },
    { lat: 48.6945096, lng: 21.6080723 },
    { lat: 48.6945329, lng: 21.6082639 },
    { lat: 48.6944962, lng: 21.6083599 },
    { lat: 48.6940529, lng: 21.6084436 },
    { lat: 48.6939646, lng: 21.6086255 },
    { lat: 48.6939529, lng: 21.6088061 },
    { lat: 48.6940088, lng: 21.6090009 },
    { lat: 48.6937876, lng: 21.609415 },
    { lat: 48.6937718, lng: 21.6095769 },
    { lat: 48.6895279, lng: 21.613625 },
    { lat: 48.6897437, lng: 21.6161297 },
    { lat: 48.6891463, lng: 21.6158852 },
    { lat: 48.6887845, lng: 21.6158209 },
    { lat: 48.6873598, lng: 21.6157742 },
    { lat: 48.6866188, lng: 21.6158685 },
    { lat: 48.6860407, lng: 21.6159971 },
    { lat: 48.6854901, lng: 21.6162307 },
    { lat: 48.6839133, lng: 21.6170638 },
    { lat: 48.6840356, lng: 21.6181043 },
    { lat: 48.6839162, lng: 21.6189091 },
    { lat: 48.684083, lng: 21.6190837 },
    { lat: 48.6839405, lng: 21.6191732 },
    { lat: 48.6836739, lng: 21.6195814 },
    { lat: 48.6832636, lng: 21.6204043 },
    { lat: 48.6824906, lng: 21.6208898 },
    { lat: 48.6821916, lng: 21.6211882 },
    { lat: 48.6808789, lng: 21.6221323 },
    { lat: 48.6802072, lng: 21.6229819 },
    { lat: 48.6798638, lng: 21.6235384 },
    { lat: 48.6799575, lng: 21.623959 },
    { lat: 48.6796088, lng: 21.6248089 },
    { lat: 48.6789016, lng: 21.6254421 },
    { lat: 48.6785262, lng: 21.6259437 },
    { lat: 48.6781116, lng: 21.6266969 },
    { lat: 48.677315, lng: 21.6278719 },
    { lat: 48.6767181, lng: 21.6281318 },
    { lat: 48.6771171, lng: 21.6312057 },
    { lat: 48.6744411, lng: 21.6320848 },
    { lat: 48.6749243, lng: 21.6341007 },
    { lat: 48.6745862, lng: 21.633997 },
    { lat: 48.674299, lng: 21.6341928 },
    { lat: 48.6740851, lng: 21.6338172 },
    { lat: 48.6738218, lng: 21.6336554 },
    { lat: 48.674173, lng: 21.6359821 },
    { lat: 48.6743017, lng: 21.6364821 },
    { lat: 48.6714127, lng: 21.6381257 },
    { lat: 48.669677, lng: 21.6389566 },
    { lat: 48.6681675, lng: 21.6395859 },
    { lat: 48.667078, lng: 21.64014 },
    { lat: 48.6669761, lng: 21.6415417 },
    { lat: 48.6669806, lng: 21.6415411 },
    { lat: 48.6669805, lng: 21.6415368 },
    { lat: 48.666998, lng: 21.6414378 },
    { lat: 48.6722411, lng: 21.6414786 },
    { lat: 48.678739, lng: 21.6420172 },
    { lat: 48.6784955, lng: 21.6400038 },
    { lat: 48.6799204, lng: 21.6395307 },
    { lat: 48.6818337, lng: 21.6393105 },
    { lat: 48.6818733, lng: 21.6398457 },
    { lat: 48.6829034, lng: 21.6399058 },
    { lat: 48.6829162, lng: 21.6402073 },
    { lat: 48.6843239, lng: 21.6404947 },
    { lat: 48.6843867, lng: 21.6411841 },
    { lat: 48.6858253, lng: 21.641205 },
    { lat: 48.6858629, lng: 21.6416371 },
    { lat: 48.6873664, lng: 21.6416016 },
    { lat: 48.688248, lng: 21.6414692 },
    { lat: 48.6897245, lng: 21.6411908 },
    { lat: 48.6914069, lng: 21.6406743 },
    { lat: 48.6911962, lng: 21.6402947 },
    { lat: 48.6911489, lng: 21.6400046 },
    { lat: 48.691181, lng: 21.6396647 },
    { lat: 48.6911033, lng: 21.6394997 },
    { lat: 48.6911423, lng: 21.6391935 },
    { lat: 48.6927846, lng: 21.6381235 },
    { lat: 48.6931803, lng: 21.6377442 },
    { lat: 48.6930988, lng: 21.6376097 },
    { lat: 48.6929065, lng: 21.6369559 },
    { lat: 48.6924772, lng: 21.6351795 },
    { lat: 48.6918753, lng: 21.6332727 },
    { lat: 48.6923039, lng: 21.6333482 },
    { lat: 48.6939518, lng: 21.6332136 },
    { lat: 48.6954258, lng: 21.6328118 },
    { lat: 48.6955448, lng: 21.6333293 },
    { lat: 48.6960869, lng: 21.6335629 },
    { lat: 48.697034, lng: 21.6333917 },
    { lat: 48.6980631, lng: 21.6342357 },
    { lat: 48.6983118, lng: 21.6336605 },
    { lat: 48.6986219, lng: 21.633689 },
    { lat: 48.6991136, lng: 21.6346257 },
    { lat: 48.6991674, lng: 21.6344115 },
    { lat: 48.6998637, lng: 21.6339209 },
    { lat: 48.6999069, lng: 21.6335727 },
    { lat: 48.6997905, lng: 21.6331473 },
    { lat: 48.6999323, lng: 21.6328633 },
    { lat: 48.7002241, lng: 21.6326718 },
    { lat: 48.699995, lng: 21.6321973 },
    { lat: 48.7002816, lng: 21.6315801 },
    { lat: 48.7007381, lng: 21.6308382 },
    { lat: 48.7008036, lng: 21.6306007 },
    { lat: 48.7007956, lng: 21.6301905 },
    { lat: 48.70075, lng: 21.6300438 },
    { lat: 48.6999052, lng: 21.6299316 },
    { lat: 48.6999952, lng: 21.6295633 },
    { lat: 48.7002698, lng: 21.6291182 },
    { lat: 48.7004856, lng: 21.6289503 },
    { lat: 48.7006731, lng: 21.628568 },
    { lat: 48.7006704, lng: 21.6283506 },
    { lat: 48.7005084, lng: 21.6277364 },
    { lat: 48.7005773, lng: 21.6268979 },
    { lat: 48.7012118, lng: 21.6258006 },
    { lat: 48.7014139, lng: 21.6259021 },
    { lat: 48.7017541, lng: 21.626525 },
    { lat: 48.70186, lng: 21.6265763 },
    { lat: 48.7019784, lng: 21.6265008 },
    { lat: 48.7020209, lng: 21.6263688 },
    { lat: 48.7020316, lng: 21.6260233 },
    { lat: 48.7019309, lng: 21.6258844 },
    { lat: 48.7019256, lng: 21.6257358 },
    { lat: 48.7019996, lng: 21.6254775 },
    { lat: 48.7021016, lng: 21.6253397 },
    { lat: 48.702091, lng: 21.6248381 },
    { lat: 48.7022909, lng: 21.624258 },
    { lat: 48.702461, lng: 21.6234137 },
    { lat: 48.7027279, lng: 21.6229794 },
    { lat: 48.7026709, lng: 21.6228266 },
    { lat: 48.7025465, lng: 21.6227633 },
    { lat: 48.7023656, lng: 21.6224897 },
    { lat: 48.7023101, lng: 21.6221887 },
    { lat: 48.7023624, lng: 21.6220942 },
    { lat: 48.702571, lng: 21.6221063 },
    { lat: 48.7026712, lng: 21.6220237 },
    { lat: 48.7026911, lng: 21.62189 },
    { lat: 48.7026437, lng: 21.6217277 },
  ],
  Parchovany: [
    { lat: 48.780683, lng: 21.729791 },
    { lat: 48.780102, lng: 21.729835 },
    { lat: 48.779285, lng: 21.729884 },
    { lat: 48.778298, lng: 21.729978 },
    { lat: 48.777613, lng: 21.72999 },
    { lat: 48.777096, lng: 21.730025 },
    { lat: 48.776066, lng: 21.730114 },
    { lat: 48.776055, lng: 21.729639 },
    { lat: 48.776149, lng: 21.728078 },
    { lat: 48.776304, lng: 21.72699 },
    { lat: 48.776317, lng: 21.7269 },
    { lat: 48.776345, lng: 21.7267 },
    { lat: 48.776403, lng: 21.726296 },
    { lat: 48.776242, lng: 21.726203 },
    { lat: 48.775781, lng: 21.727035 },
    { lat: 48.775665, lng: 21.727127 },
    { lat: 48.775104, lng: 21.727334 },
    { lat: 48.774575, lng: 21.727333 },
    { lat: 48.773643, lng: 21.726659 },
    { lat: 48.772713, lng: 21.726784 },
    { lat: 48.772458, lng: 21.727045 },
    { lat: 48.772401, lng: 21.727002 },
    { lat: 48.772347, lng: 21.72694 },
    { lat: 48.772066, lng: 21.726831 },
    { lat: 48.771893, lng: 21.726753 },
    { lat: 48.771693, lng: 21.726582 },
    { lat: 48.771617, lng: 21.726495 },
    { lat: 48.771279, lng: 21.726347 },
    { lat: 48.771166, lng: 21.726033 },
    { lat: 48.771163, lng: 21.725602 },
    { lat: 48.771112, lng: 21.724939 },
    { lat: 48.771178, lng: 21.724701 },
    { lat: 48.771698, lng: 21.723988 },
    { lat: 48.771668, lng: 21.72333 },
    { lat: 48.77166, lng: 21.722731 },
    { lat: 48.771593, lng: 21.722555 },
    { lat: 48.771405, lng: 21.722385 },
    { lat: 48.771108, lng: 21.722307 },
    { lat: 48.77085, lng: 21.722323 },
    { lat: 48.770692, lng: 21.722402 },
    { lat: 48.770586, lng: 21.722563 },
    { lat: 48.77056, lng: 21.722804 },
    { lat: 48.770498, lng: 21.723177 },
    { lat: 48.770419, lng: 21.723863 },
    { lat: 48.770345, lng: 21.724278 },
    { lat: 48.770281, lng: 21.724484 },
    { lat: 48.769956, lng: 21.725203 },
    { lat: 48.76987, lng: 21.72529 },
    { lat: 48.769459, lng: 21.725128 },
    { lat: 48.769302, lng: 21.724972 },
    { lat: 48.769261, lng: 21.724744 },
    { lat: 48.769301, lng: 21.724532 },
    { lat: 48.769095, lng: 21.724004 },
    { lat: 48.768884, lng: 21.723936 },
    { lat: 48.768967, lng: 21.72306 },
    { lat: 48.769163, lng: 21.72294 },
    { lat: 48.769498, lng: 21.722799 },
    { lat: 48.769462, lng: 21.722514 },
    { lat: 48.769381, lng: 21.722346 },
    { lat: 48.769368, lng: 21.722169 },
    { lat: 48.76944, lng: 21.722017 },
    { lat: 48.769717, lng: 21.721818 },
    { lat: 48.769895, lng: 21.72174 },
    { lat: 48.770087, lng: 21.721513 },
    { lat: 48.770458, lng: 21.7208 },
    { lat: 48.770516, lng: 21.720483 },
    { lat: 48.770431, lng: 21.720122 },
    { lat: 48.770217, lng: 21.719937 },
    { lat: 48.770076, lng: 21.719934 },
    { lat: 48.769793, lng: 21.71986 },
    { lat: 48.769581, lng: 21.719873 },
    { lat: 48.769381, lng: 21.719916 },
    { lat: 48.769054, lng: 21.719885 },
    { lat: 48.768866, lng: 21.719828 },
    { lat: 48.768624, lng: 21.719658 },
    { lat: 48.768519, lng: 21.71953 },
    { lat: 48.768151, lng: 21.71918 },
    { lat: 48.767665, lng: 21.718835 },
    { lat: 48.767549, lng: 21.71876 },
    { lat: 48.767462, lng: 21.718796 },
    { lat: 48.767354, lng: 21.718803 },
    { lat: 48.767309, lng: 21.719003 },
    { lat: 48.767352, lng: 21.719165 },
    { lat: 48.76728, lng: 21.719652 },
    { lat: 48.767194, lng: 21.719881 },
    { lat: 48.767021, lng: 21.720158 },
    { lat: 48.766859, lng: 21.72029 },
    { lat: 48.766639, lng: 21.720295 },
    { lat: 48.766558, lng: 21.720075 },
    { lat: 48.766615, lng: 21.719583 },
    { lat: 48.766554, lng: 21.719318 },
    { lat: 48.766574, lng: 21.718789 },
    { lat: 48.766497, lng: 21.718607 },
    { lat: 48.766471, lng: 21.718483 },
    { lat: 48.766845, lng: 21.718099 },
    { lat: 48.767095, lng: 21.717997 },
    { lat: 48.766882, lng: 21.717243 },
    { lat: 48.76659, lng: 21.715721 },
    { lat: 48.766324, lng: 21.715118 },
    { lat: 48.766257, lng: 21.714838 },
    { lat: 48.7661, lng: 21.714699 },
    { lat: 48.765871, lng: 21.714552 },
    { lat: 48.765753, lng: 21.714439 },
    { lat: 48.765483, lng: 21.714251 },
    { lat: 48.765051, lng: 21.713685 },
    { lat: 48.764236, lng: 21.713129 },
    { lat: 48.764134, lng: 21.712954 },
    { lat: 48.764013, lng: 21.712457 },
    { lat: 48.764005, lng: 21.712202 },
    { lat: 48.764109, lng: 21.711803 },
    { lat: 48.764163, lng: 21.71138 },
    { lat: 48.764172, lng: 21.711308 },
    { lat: 48.764312, lng: 21.710213 },
    { lat: 48.764362, lng: 21.710045 },
    { lat: 48.764593, lng: 21.709786 },
    { lat: 48.764723, lng: 21.709512 },
    { lat: 48.764716, lng: 21.709349 },
    { lat: 48.764631, lng: 21.709064 },
    { lat: 48.764419, lng: 21.708595 },
    { lat: 48.764348, lng: 21.708301 },
    { lat: 48.764292, lng: 21.708123 },
    { lat: 48.764249, lng: 21.707945 },
    { lat: 48.764217, lng: 21.707741 },
    { lat: 48.764204, lng: 21.707586 },
    { lat: 48.764217, lng: 21.707221 },
    { lat: 48.764278, lng: 21.706834 },
    { lat: 48.764312, lng: 21.706738 },
    { lat: 48.76435, lng: 21.706541 },
    { lat: 48.764357, lng: 21.706455 },
    { lat: 48.764353, lng: 21.706287 },
    { lat: 48.764289, lng: 21.706118 },
    { lat: 48.764197, lng: 21.705935 },
    { lat: 48.764091, lng: 21.70578 },
    { lat: 48.763947, lng: 21.705625 },
    { lat: 48.763857, lng: 21.705175 },
    { lat: 48.763743, lng: 21.704771 },
    { lat: 48.763467, lng: 21.703709 },
    { lat: 48.763312, lng: 21.703101 },
    { lat: 48.763151, lng: 21.702673 },
    { lat: 48.76317, lng: 21.702525 },
    { lat: 48.763241, lng: 21.702224 },
    { lat: 48.763372, lng: 21.701921 },
    { lat: 48.763546, lng: 21.70163 },
    { lat: 48.76363, lng: 21.701481 },
    { lat: 48.763729, lng: 21.701313 },
    { lat: 48.76388, lng: 21.700972 },
    { lat: 48.763951, lng: 21.70077 },
    { lat: 48.764054, lng: 21.700559 },
    { lat: 48.764079, lng: 21.700419 },
    { lat: 48.764162, lng: 21.700151 },
    { lat: 48.76425, lng: 21.699844 },
    { lat: 48.764312, lng: 21.699676 },
    { lat: 48.764368, lng: 21.699469 },
    { lat: 48.764438, lng: 21.69925 },
    { lat: 48.764492, lng: 21.699137 },
    { lat: 48.764555, lng: 21.698946 },
    { lat: 48.764635, lng: 21.698728 },
    { lat: 48.764679, lng: 21.698618 },
    { lat: 48.764748, lng: 21.698416 },
    { lat: 48.764811, lng: 21.698232 },
    { lat: 48.765058, lng: 21.697404 },
    { lat: 48.765053, lng: 21.697122 },
    { lat: 48.765066, lng: 21.696918 },
    { lat: 48.765068, lng: 21.696733 },
    { lat: 48.765071, lng: 21.696535 },
    { lat: 48.765074, lng: 21.696401 },
    { lat: 48.765081, lng: 21.696109 },
    { lat: 48.765083, lng: 21.695952 },
    { lat: 48.76507, lng: 21.695729 },
    { lat: 48.765029, lng: 21.695535 },
    { lat: 48.764958, lng: 21.695311 },
    { lat: 48.764856, lng: 21.694865 },
    { lat: 48.764688, lng: 21.69424 },
    { lat: 48.764564, lng: 21.693914 },
    { lat: 48.764319, lng: 21.693321 },
    { lat: 48.764219, lng: 21.693097 },
    { lat: 48.764034, lng: 21.692699 },
    { lat: 48.763961, lng: 21.692352 },
    { lat: 48.763972, lng: 21.692218 },
    { lat: 48.76391, lng: 21.692093 },
    { lat: 48.763697, lng: 21.69121 },
    { lat: 48.763557, lng: 21.690406 },
    { lat: 48.763406, lng: 21.689797 },
    { lat: 48.763318, lng: 21.689282 },
    { lat: 48.763214, lng: 21.688798 },
    { lat: 48.763129, lng: 21.688372 },
    { lat: 48.763014, lng: 21.687785 },
    { lat: 48.762939, lng: 21.687347 },
    { lat: 48.762797, lng: 21.686474 },
    { lat: 48.762764, lng: 21.686479 },
    { lat: 48.7620295, lng: 21.6866116 },
    { lat: 48.7602294, lng: 21.6871902 },
    { lat: 48.7581282, lng: 21.6876994 },
    { lat: 48.7573891, lng: 21.6877431 },
    { lat: 48.7562481, lng: 21.6876592 },
    { lat: 48.7561045, lng: 21.6863495 },
    { lat: 48.7560779, lng: 21.6854548 },
    { lat: 48.756184, lng: 21.6841378 },
    { lat: 48.7551013, lng: 21.6847089 },
    { lat: 48.7543747, lng: 21.6852122 },
    { lat: 48.7542006, lng: 21.684061 },
    { lat: 48.7543277, lng: 21.6837696 },
    { lat: 48.7542359, lng: 21.6836613 },
    { lat: 48.7541219, lng: 21.6835257 },
    { lat: 48.7540025, lng: 21.6841382 },
    { lat: 48.7538463, lng: 21.6841066 },
    { lat: 48.7538904, lng: 21.6838656 },
    { lat: 48.7536376, lng: 21.6835933 },
    { lat: 48.7535994, lng: 21.6835931 },
    { lat: 48.753501, lng: 21.6838242 },
    { lat: 48.7532686, lng: 21.6838779 },
    { lat: 48.7532626, lng: 21.6837884 },
    { lat: 48.7530835, lng: 21.6838185 },
    { lat: 48.7529689, lng: 21.6840759 },
    { lat: 48.7530306, lng: 21.6844817 },
    { lat: 48.7529391, lng: 21.6847138 },
    { lat: 48.7527747, lng: 21.684713 },
    { lat: 48.7527652, lng: 21.6843206 },
    { lat: 48.7527083, lng: 21.6843003 },
    { lat: 48.7526718, lng: 21.6843614 },
    { lat: 48.7524374, lng: 21.684387 },
    { lat: 48.7522069, lng: 21.6842659 },
    { lat: 48.7520444, lng: 21.6844121 },
    { lat: 48.7519766, lng: 21.6846499 },
    { lat: 48.7517157, lng: 21.6845313 },
    { lat: 48.7516629, lng: 21.684577 },
    { lat: 48.7516039, lng: 21.6850948 },
    { lat: 48.7515369, lng: 21.6852413 },
    { lat: 48.7513685, lng: 21.6853348 },
    { lat: 48.7512095, lng: 21.6851992 },
    { lat: 48.751038, lng: 21.6851774 },
    { lat: 48.7508621, lng: 21.6852457 },
    { lat: 48.7507057, lng: 21.6854188 },
    { lat: 48.7504055, lng: 21.6855016 },
    { lat: 48.7501947, lng: 21.6856573 },
    { lat: 48.7501642, lng: 21.6856959 },
    { lat: 48.7501692, lng: 21.6859601 },
    { lat: 48.7503449, lng: 21.686177 },
    { lat: 48.7503104, lng: 21.6862705 },
    { lat: 48.7501521, lng: 21.6863602 },
    { lat: 48.7499786, lng: 21.6862743 },
    { lat: 48.7499284, lng: 21.6862956 },
    { lat: 48.749855, lng: 21.6864902 },
    { lat: 48.7497902, lng: 21.6864646 },
    { lat: 48.7497444, lng: 21.6863088 },
    { lat: 48.7497897, lng: 21.6860735 },
    { lat: 48.7495037, lng: 21.6859772 },
    { lat: 48.7493077, lng: 21.6862165 },
    { lat: 48.7490704, lng: 21.6863856 },
    { lat: 48.7488267, lng: 21.6862421 },
    { lat: 48.7485856, lng: 21.6864792 },
    { lat: 48.7485239, lng: 21.6868541 },
    { lat: 48.7484126, lng: 21.6869841 },
    { lat: 48.7482634, lng: 21.6866667 },
    { lat: 48.748186, lng: 21.6867575 },
    { lat: 48.7483063, lng: 21.6870038 },
    { lat: 48.7480978, lng: 21.6872774 },
    { lat: 48.7480096, lng: 21.6868971 },
    { lat: 48.747915, lng: 21.686883 },
    { lat: 48.7478413, lng: 21.6870805 },
    { lat: 48.7479307, lng: 21.6872621 },
    { lat: 48.7478155, lng: 21.6876999 },
    { lat: 48.7478529, lng: 21.6881211 },
    { lat: 48.7476676, lng: 21.6885922 },
    { lat: 48.7471665, lng: 21.6890315 },
    { lat: 48.7469029, lng: 21.6891451 },
    { lat: 48.7467326, lng: 21.6893411 },
    { lat: 48.7463804, lng: 21.6893747 },
    { lat: 48.746289, lng: 21.6891629 },
    { lat: 48.7461082, lng: 21.6892943 },
    { lat: 48.7459989, lng: 21.6892084 },
    { lat: 48.7459244, lng: 21.6890351 },
    { lat: 48.7457729, lng: 21.6890933 },
    { lat: 48.7457222, lng: 21.6892466 },
    { lat: 48.7450789, lng: 21.6894241 },
    { lat: 48.7449449, lng: 21.690124 },
    { lat: 48.7446975, lng: 21.6902523 },
    { lat: 48.7447515, lng: 21.6905823 },
    { lat: 48.7444223, lng: 21.6906843 },
    { lat: 48.7442297, lng: 21.691092 },
    { lat: 48.7437481, lng: 21.6912627 },
    { lat: 48.7434523, lng: 21.6915512 },
    { lat: 48.7429568, lng: 21.6917463 },
    { lat: 48.7428366, lng: 21.6919562 },
    { lat: 48.7426227, lng: 21.6921609 },
    { lat: 48.7420337, lng: 21.6925481 },
    { lat: 48.7419909, lng: 21.6927183 },
    { lat: 48.7417503, lng: 21.6928872 },
    { lat: 48.7415954, lng: 21.6931478 },
    { lat: 48.740931, lng: 21.6935825 },
    { lat: 48.7408942, lng: 21.6937662 },
    { lat: 48.7406568, lng: 21.6938864 },
    { lat: 48.7404486, lng: 21.6941752 },
    { lat: 48.7394543, lng: 21.6951465 },
    { lat: 48.7393844, lng: 21.6951408 },
    { lat: 48.7389096, lng: 21.6954735 },
    { lat: 48.738545, lng: 21.6958754 },
    { lat: 48.7382878, lng: 21.6960606 },
    { lat: 48.7379715, lng: 21.6961853 },
    { lat: 48.7373759, lng: 21.6967486 },
    { lat: 48.7373167, lng: 21.6966454 },
    { lat: 48.7371684, lng: 21.6967301 },
    { lat: 48.7370091, lng: 21.6969959 },
    { lat: 48.7369207, lng: 21.6969761 },
    { lat: 48.7359917, lng: 21.6926659 },
    { lat: 48.7359545, lng: 21.6926618 },
    { lat: 48.7359649, lng: 21.6924273 },
    { lat: 48.7361704, lng: 21.6923287 },
    { lat: 48.735975, lng: 21.690183 },
    { lat: 48.7359717, lng: 21.6896115 },
    { lat: 48.7360412, lng: 21.6891823 },
    { lat: 48.7356193, lng: 21.6867805 },
    { lat: 48.7357304, lng: 21.6856901 },
    { lat: 48.7362079, lng: 21.6850093 },
    { lat: 48.7353385, lng: 21.6805933 },
    { lat: 48.7346443, lng: 21.6809684 },
    { lat: 48.7346231, lng: 21.6809273 },
    { lat: 48.7346626, lng: 21.680897 },
    { lat: 48.7346274, lng: 21.6808397 },
    { lat: 48.7345602, lng: 21.680728 },
    { lat: 48.7345271, lng: 21.6807087 },
    { lat: 48.7339245, lng: 21.6803081 },
    { lat: 48.733259, lng: 21.680501 },
    { lat: 48.7325561, lng: 21.6810458 },
    { lat: 48.7299384, lng: 21.6811402 },
    { lat: 48.7297969, lng: 21.6809034 },
    { lat: 48.7282555, lng: 21.6811123 },
    { lat: 48.7270347, lng: 21.6814426 },
    { lat: 48.7257228, lng: 21.6815609 },
    { lat: 48.7253017, lng: 21.6813433 },
    { lat: 48.7250334, lng: 21.6812813 },
    { lat: 48.7246286, lng: 21.6812918 },
    { lat: 48.7245542, lng: 21.6809141 },
    { lat: 48.7244622, lng: 21.680887 },
    { lat: 48.7244047, lng: 21.6788191 },
    { lat: 48.7230551, lng: 21.6789199 },
    { lat: 48.7214291, lng: 21.6786177 },
    { lat: 48.7198222, lng: 21.6785238 },
    { lat: 48.7188872, lng: 21.6786479 },
    { lat: 48.7177944, lng: 21.6786368 },
    { lat: 48.7161849, lng: 21.6790309 },
    { lat: 48.7150064, lng: 21.679173 },
    { lat: 48.7149667, lng: 21.6793237 },
    { lat: 48.7147996, lng: 21.679551 },
    { lat: 48.7139959, lng: 21.680486 },
    { lat: 48.7138283, lng: 21.6813205 },
    { lat: 48.7136446, lng: 21.6826362 },
    { lat: 48.7123524, lng: 21.6828629 },
    { lat: 48.7126233, lng: 21.6873169 },
    { lat: 48.7126217, lng: 21.687799 },
    { lat: 48.7129615, lng: 21.6909444 },
    { lat: 48.7131885, lng: 21.6941745 },
    { lat: 48.7133419, lng: 21.6955979 },
    { lat: 48.7136079, lng: 21.6968163 },
    { lat: 48.7138568, lng: 21.6976613 },
    { lat: 48.7135394, lng: 21.6979431 },
    { lat: 48.7134306, lng: 21.6982466 },
    { lat: 48.7134429, lng: 21.6987446 },
    { lat: 48.7132992, lng: 21.6990435 },
    { lat: 48.713319, lng: 21.6997388 },
    { lat: 48.7131751, lng: 21.7001118 },
    { lat: 48.7131609, lng: 21.7002594 },
    { lat: 48.7133393, lng: 21.7011081 },
    { lat: 48.7137482, lng: 21.7019247 },
    { lat: 48.7138556, lng: 21.7029723 },
    { lat: 48.7138586, lng: 21.7036686 },
    { lat: 48.7146294, lng: 21.7034618 },
    { lat: 48.7148407, lng: 21.7032923 },
    { lat: 48.7155175, lng: 21.7031501 },
    { lat: 48.7156285, lng: 21.7031731 },
    { lat: 48.7157757, lng: 21.703334 },
    { lat: 48.7158658, lng: 21.7035882 },
    { lat: 48.7159841, lng: 21.7035157 },
    { lat: 48.7161151, lng: 21.7033202 },
    { lat: 48.7163295, lng: 21.7032642 },
    { lat: 48.7165103, lng: 21.7029997 },
    { lat: 48.7168905, lng: 21.7027881 },
    { lat: 48.7171177, lng: 21.7025407 },
    { lat: 48.7174218, lng: 21.7023399 },
    { lat: 48.7176615, lng: 21.7019698 },
    { lat: 48.7179641, lng: 21.7018004 },
    { lat: 48.7180967, lng: 21.7015866 },
    { lat: 48.7185105, lng: 21.7017015 },
    { lat: 48.7192001, lng: 21.7008301 },
    { lat: 48.7191793, lng: 21.7007216 },
    { lat: 48.7196652, lng: 21.7003403 },
    { lat: 48.7198574, lng: 21.7000868 },
    { lat: 48.7199831, lng: 21.7001151 },
    { lat: 48.7201528, lng: 21.699817 },
    { lat: 48.720108, lng: 21.6997407 },
    { lat: 48.7201414, lng: 21.6996827 },
    { lat: 48.7204208, lng: 21.6995795 },
    { lat: 48.7205147, lng: 21.6996343 },
    { lat: 48.7208405, lng: 21.6992871 },
    { lat: 48.7210287, lng: 21.699295 },
    { lat: 48.721229, lng: 21.6991376 },
    { lat: 48.7216647, lng: 21.698935 },
    { lat: 48.7216328, lng: 21.6987157 },
    { lat: 48.7219616, lng: 21.6983886 },
    { lat: 48.7220756, lng: 21.6984464 },
    { lat: 48.7222108, lng: 21.6982431 },
    { lat: 48.7222156, lng: 21.6979246 },
    { lat: 48.722265, lng: 21.6978389 },
    { lat: 48.7224832, lng: 21.6978347 },
    { lat: 48.7229392, lng: 21.6974712 },
    { lat: 48.722921, lng: 21.6974281 },
    { lat: 48.7231645, lng: 21.6971116 },
    { lat: 48.7231424, lng: 21.696959 },
    { lat: 48.7232139, lng: 21.6967761 },
    { lat: 48.7234428, lng: 21.6969148 },
    { lat: 48.7234101, lng: 21.6970828 },
    { lat: 48.7235373, lng: 21.6974188 },
    { lat: 48.7241871, lng: 21.6977321 },
    { lat: 48.7243976, lng: 21.6976094 },
    { lat: 48.7248528, lng: 21.7005008 },
    { lat: 48.7255288, lng: 21.7001176 },
    { lat: 48.7258351, lng: 21.7010462 },
    { lat: 48.7305779, lng: 21.6993622 },
    { lat: 48.7307073, lng: 21.6994033 },
    { lat: 48.7314419, lng: 21.6990272 },
    { lat: 48.7314756, lng: 21.6992696 },
    { lat: 48.7319292, lng: 21.6991564 },
    { lat: 48.731947, lng: 21.6987333 },
    { lat: 48.7318537, lng: 21.6983623 },
    { lat: 48.733207, lng: 21.6975202 },
    { lat: 48.7346051, lng: 21.6964304 },
    { lat: 48.7347665, lng: 21.6974023 },
    { lat: 48.7353375, lng: 21.6977163 },
    { lat: 48.7368819, lng: 21.6971649 },
    { lat: 48.7377402, lng: 21.7006444 },
    { lat: 48.7465684, lng: 21.6991772 },
    { lat: 48.7467761, lng: 21.7033261 },
    { lat: 48.7459397, lng: 21.703515 },
    { lat: 48.745943, lng: 21.7044321 },
    { lat: 48.7456732, lng: 21.7070654 },
    { lat: 48.7452664, lng: 21.7088344 },
    { lat: 48.7447042, lng: 21.7103065 },
    { lat: 48.74446, lng: 21.7111835 },
    { lat: 48.7428283, lng: 21.7107557 },
    { lat: 48.7422775, lng: 21.7108125 },
    { lat: 48.7419879, lng: 21.7110028 },
    { lat: 48.7415886, lng: 21.7114698 },
    { lat: 48.7412236, lng: 21.7120373 },
    { lat: 48.7408792, lng: 21.7123187 },
    { lat: 48.7405491, lng: 21.7140568 },
    { lat: 48.7403841, lng: 21.7146551 },
    { lat: 48.7401005, lng: 21.7144908 },
    { lat: 48.7394829, lng: 21.7133611 },
    { lat: 48.7389268, lng: 21.7125944 },
    { lat: 48.7387654, lng: 21.7124478 },
    { lat: 48.7384165, lng: 21.7123178 },
    { lat: 48.7382621, lng: 21.7123555 },
    { lat: 48.7379526, lng: 21.7122701 },
    { lat: 48.7375609, lng: 21.7119695 },
    { lat: 48.7374181, lng: 21.7119259 },
    { lat: 48.737174, lng: 21.7119755 },
    { lat: 48.7369258, lng: 21.712328 },
    { lat: 48.7363858, lng: 21.7136209 },
    { lat: 48.7362679, lng: 21.7137946 },
    { lat: 48.7361584, lng: 21.7138245 },
    { lat: 48.7360386, lng: 21.7137171 },
    { lat: 48.7357762, lng: 21.713087 },
    { lat: 48.7356914, lng: 21.7124439 },
    { lat: 48.7340471, lng: 21.7140489 },
    { lat: 48.733347, lng: 21.714767 },
    { lat: 48.733858, lng: 21.717028 },
    { lat: 48.733919, lng: 21.717223 },
    { lat: 48.735035, lng: 21.720785 },
    { lat: 48.735138, lng: 21.722206 },
    { lat: 48.735143, lng: 21.722273 },
    { lat: 48.735152, lng: 21.722405 },
    { lat: 48.735158, lng: 21.722471 },
    { lat: 48.735169, lng: 21.722632 },
    { lat: 48.735322, lng: 21.724719 },
    { lat: 48.735537, lng: 21.727121 },
    { lat: 48.735521, lng: 21.72736 },
    { lat: 48.735537, lng: 21.727458 },
    { lat: 48.73595, lng: 21.730025 },
    { lat: 48.736062, lng: 21.731572 },
    { lat: 48.736073, lng: 21.731638 },
    { lat: 48.736083, lng: 21.731705 },
    { lat: 48.736387, lng: 21.733685 },
    { lat: 48.735982, lng: 21.734717 },
    { lat: 48.7362, lng: 21.735858 },
    { lat: 48.736527, lng: 21.735604 },
    { lat: 48.736511, lng: 21.735724 },
    { lat: 48.73702, lng: 21.73878 },
    { lat: 48.736965, lng: 21.738856 },
    { lat: 48.736759, lng: 21.738644 },
    { lat: 48.736705, lng: 21.739666 },
    { lat: 48.736687, lng: 21.740042 },
    { lat: 48.73668, lng: 21.740174 },
    { lat: 48.736649, lng: 21.74082 },
    { lat: 48.736749, lng: 21.743856 },
    { lat: 48.737071, lng: 21.743893 },
    { lat: 48.737086, lng: 21.744045 },
    { lat: 48.737082, lng: 21.744274 },
    { lat: 48.737081, lng: 21.744406 },
    { lat: 48.737101, lng: 21.744552 },
    { lat: 48.737128, lng: 21.744711 },
    { lat: 48.737266, lng: 21.745386 },
    { lat: 48.737383, lng: 21.74599 },
    { lat: 48.73754, lng: 21.746627 },
    { lat: 48.737751, lng: 21.746455 },
    { lat: 48.737819, lng: 21.746714 },
    { lat: 48.737874, lng: 21.746869 },
    { lat: 48.738684, lng: 21.749608 },
    { lat: 48.738736, lng: 21.749785 },
    { lat: 48.739235, lng: 21.751473 },
    { lat: 48.739246, lng: 21.751514 },
    { lat: 48.739538, lng: 21.752494 },
    { lat: 48.739721, lng: 21.753111 },
    { lat: 48.739942, lng: 21.753837 },
    { lat: 48.740271, lng: 21.754879 },
    { lat: 48.740756, lng: 21.756494 },
    { lat: 48.741152, lng: 21.757816 },
    { lat: 48.741232, lng: 21.758084 },
    { lat: 48.74124, lng: 21.758118 },
    { lat: 48.741283, lng: 21.758297 },
    { lat: 48.741302, lng: 21.758388 },
    { lat: 48.741305, lng: 21.758399 },
    { lat: 48.741433, lng: 21.759008 },
    { lat: 48.741604, lng: 21.759802 },
    { lat: 48.743728, lng: 21.758439 },
    { lat: 48.74409, lng: 21.758341 },
    { lat: 48.744388, lng: 21.758297 },
    { lat: 48.744816, lng: 21.758201 },
    { lat: 48.744848, lng: 21.758232 },
    { lat: 48.745097, lng: 21.758311 },
    { lat: 48.745756, lng: 21.758525 },
    { lat: 48.746208, lng: 21.758672 },
    { lat: 48.746341, lng: 21.758716 },
    { lat: 48.746366, lng: 21.758723 },
    { lat: 48.748334, lng: 21.760132 },
    { lat: 48.751107, lng: 21.762162 },
    { lat: 48.751464, lng: 21.76252 },
    { lat: 48.751532, lng: 21.76261 },
    { lat: 48.751731, lng: 21.762815 },
    { lat: 48.752035, lng: 21.763032 },
    { lat: 48.752228, lng: 21.763137 },
    { lat: 48.752698, lng: 21.763413 },
    { lat: 48.752902, lng: 21.763558 },
    { lat: 48.753146, lng: 21.763798 },
    { lat: 48.753482, lng: 21.764034 },
    { lat: 48.753864, lng: 21.764329 },
    { lat: 48.755326, lng: 21.765396 },
    { lat: 48.755769, lng: 21.765734 },
    { lat: 48.756552, lng: 21.766266 },
    { lat: 48.756754, lng: 21.766255 },
    { lat: 48.756765, lng: 21.766254 },
    { lat: 48.761052, lng: 21.766029 },
    { lat: 48.761306, lng: 21.766016 },
    { lat: 48.763871, lng: 21.766252 },
    { lat: 48.763947, lng: 21.766278 },
    { lat: 48.76439, lng: 21.766372 },
    { lat: 48.764547, lng: 21.766412 },
    { lat: 48.764695, lng: 21.766354 },
    { lat: 48.764795, lng: 21.76621 },
    { lat: 48.764974, lng: 21.765903 },
    { lat: 48.76504, lng: 21.765824 },
    { lat: 48.765113, lng: 21.765746 },
    { lat: 48.765172, lng: 21.765696 },
    { lat: 48.765374, lng: 21.765562 },
    { lat: 48.765394, lng: 21.765566 },
    { lat: 48.765493, lng: 21.765499 },
    { lat: 48.765556, lng: 21.76546 },
    { lat: 48.765603, lng: 21.765391 },
    { lat: 48.765612, lng: 21.765326 },
    { lat: 48.765641, lng: 21.765235 },
    { lat: 48.765682, lng: 21.765026 },
    { lat: 48.765712, lng: 21.764931 },
    { lat: 48.765798, lng: 21.764673 },
    { lat: 48.765892, lng: 21.764565 },
    { lat: 48.765969, lng: 21.764507 },
    { lat: 48.766057, lng: 21.764486 },
    { lat: 48.766127, lng: 21.764485 },
    { lat: 48.76628, lng: 21.764511 },
    { lat: 48.766349, lng: 21.764533 },
    { lat: 48.76641, lng: 21.764535 },
    { lat: 48.766578, lng: 21.764607 },
    { lat: 48.766678, lng: 21.764615 },
    { lat: 48.766789, lng: 21.764619 },
    { lat: 48.766836, lng: 21.764602 },
    { lat: 48.766867, lng: 21.764595 },
    { lat: 48.766938, lng: 21.764553 },
    { lat: 48.766983, lng: 21.764418 },
    { lat: 48.766992, lng: 21.764273 },
    { lat: 48.766929, lng: 21.764007 },
    { lat: 48.766839, lng: 21.763836 },
    { lat: 48.766775, lng: 21.763718 },
    { lat: 48.766766, lng: 21.763676 },
    { lat: 48.766763, lng: 21.763395 },
    { lat: 48.766787, lng: 21.763271 },
    { lat: 48.766912, lng: 21.762931 },
    { lat: 48.766943, lng: 21.762841 },
    { lat: 48.76702, lng: 21.762566 },
    { lat: 48.767105, lng: 21.762449 },
    { lat: 48.767261, lng: 21.762397 },
    { lat: 48.767532, lng: 21.762303 },
    { lat: 48.76769, lng: 21.762196 },
    { lat: 48.767741, lng: 21.76202 },
    { lat: 48.767863, lng: 21.761378 },
    { lat: 48.767962, lng: 21.76117 },
    { lat: 48.767996, lng: 21.761144 },
    { lat: 48.768089, lng: 21.761097 },
    { lat: 48.768213, lng: 21.761109 },
    { lat: 48.768275, lng: 21.761124 },
    { lat: 48.768377, lng: 21.761163 },
    { lat: 48.768627, lng: 21.761255 },
    { lat: 48.768718, lng: 21.761263 },
    { lat: 48.768815, lng: 21.761204 },
    { lat: 48.768864, lng: 21.761163 },
    { lat: 48.768884, lng: 21.760949 },
    { lat: 48.768879, lng: 21.760743 },
    { lat: 48.768857, lng: 21.760573 },
    { lat: 48.768776, lng: 21.760117 },
    { lat: 48.768762, lng: 21.759998 },
    { lat: 48.768781, lng: 21.759719 },
    { lat: 48.768835, lng: 21.759382 },
    { lat: 48.76883, lng: 21.759046 },
    { lat: 48.768764, lng: 21.758606 },
    { lat: 48.768745, lng: 21.758119 },
    { lat: 48.768802, lng: 21.758003 },
    { lat: 48.768853, lng: 21.757989 },
    { lat: 48.768971, lng: 21.757968 },
    { lat: 48.769142, lng: 21.758051 },
    { lat: 48.769343, lng: 21.75813 },
    { lat: 48.769428, lng: 21.758184 },
    { lat: 48.769619, lng: 21.758274 },
    { lat: 48.769937, lng: 21.758308 },
    { lat: 48.770079, lng: 21.758267 },
    { lat: 48.770138, lng: 21.758206 },
    { lat: 48.770147, lng: 21.758135 },
    { lat: 48.770124, lng: 21.757855 },
    { lat: 48.770086, lng: 21.757693 },
    { lat: 48.770016, lng: 21.757509 },
    { lat: 48.769998, lng: 21.757464 },
    { lat: 48.769972, lng: 21.757354 },
    { lat: 48.76996, lng: 21.757298 },
    { lat: 48.769933, lng: 21.757184 },
    { lat: 48.769915, lng: 21.75707 },
    { lat: 48.769903, lng: 21.756988 },
    { lat: 48.769777, lng: 21.756646 },
    { lat: 48.769965, lng: 21.756563 },
    { lat: 48.770887, lng: 21.755957 },
    { lat: 48.771065, lng: 21.755878 },
    { lat: 48.771647, lng: 21.755818 },
    { lat: 48.771764, lng: 21.756141 },
    { lat: 48.771821, lng: 21.756342 },
    { lat: 48.771851, lng: 21.756411 },
    { lat: 48.77191, lng: 21.756546 },
    { lat: 48.772, lng: 21.756755 },
    { lat: 48.772069, lng: 21.75686 },
    { lat: 48.77233, lng: 21.75725 },
    { lat: 48.772414, lng: 21.757332 },
    { lat: 48.772526, lng: 21.757422 },
    { lat: 48.773351, lng: 21.757995 },
    { lat: 48.773635, lng: 21.75825 },
    { lat: 48.773951, lng: 21.758528 },
    { lat: 48.774112, lng: 21.758594 },
    { lat: 48.774169, lng: 21.758559 },
    { lat: 48.77421, lng: 21.758532 },
    { lat: 48.774556, lng: 21.758001 },
    { lat: 48.774908, lng: 21.757553 },
    { lat: 48.774874, lng: 21.757427 },
    { lat: 48.774734, lng: 21.756472 },
    { lat: 48.774724, lng: 21.75641 },
    { lat: 48.774717, lng: 21.756385 },
    { lat: 48.774693, lng: 21.756273 },
    { lat: 48.774601, lng: 21.756002 },
    { lat: 48.774542, lng: 21.755809 },
    { lat: 48.774527, lng: 21.755575 },
    { lat: 48.774532, lng: 21.755452 },
    { lat: 48.774539, lng: 21.755316 },
    { lat: 48.774346, lng: 21.752886 },
    { lat: 48.77418, lng: 21.750513 },
    { lat: 48.774107, lng: 21.74915 },
    { lat: 48.77436, lng: 21.749178 },
    { lat: 48.774349, lng: 21.7491 },
    { lat: 48.774342, lng: 21.748947 },
    { lat: 48.774341, lng: 21.74877 },
    { lat: 48.774348, lng: 21.748379 },
    { lat: 48.774364, lng: 21.747796 },
    { lat: 48.774393, lng: 21.746872 },
    { lat: 48.77442, lng: 21.74596 },
    { lat: 48.774497, lng: 21.744538 },
    { lat: 48.77451, lng: 21.744188 },
    { lat: 48.774486, lng: 21.743946 },
    { lat: 48.77454, lng: 21.743123 },
    { lat: 48.774548, lng: 21.74277 },
    { lat: 48.774635, lng: 21.740046 },
    { lat: 48.774637, lng: 21.74 },
    { lat: 48.775954, lng: 21.740233 },
    { lat: 48.77719, lng: 21.740449 },
    { lat: 48.7788, lng: 21.740731 },
    { lat: 48.779939, lng: 21.740931 },
    { lat: 48.779998, lng: 21.740948 },
    { lat: 48.780199, lng: 21.740972 },
    { lat: 48.780352, lng: 21.740958 },
    { lat: 48.780472, lng: 21.740936 },
    { lat: 48.78057, lng: 21.735934 },
    { lat: 48.780687, lng: 21.729919 },
    { lat: 48.780685, lng: 21.729845 },
    { lat: 48.780683, lng: 21.729791 },
  ],
  Rad: [
    { lat: 48.4652883, lng: 21.8526198 },
    { lat: 48.4650769, lng: 21.8524942 },
    { lat: 48.4647299, lng: 21.8524319 },
    { lat: 48.4643072, lng: 21.8525311 },
    { lat: 48.4636009, lng: 21.8528443 },
    { lat: 48.4631379, lng: 21.8531731 },
    { lat: 48.4628991, lng: 21.853466 },
    { lat: 48.4625485, lng: 21.8540835 },
    { lat: 48.4622515, lng: 21.8547407 },
    { lat: 48.461807, lng: 21.8554788 },
    { lat: 48.4607507, lng: 21.8570073 },
    { lat: 48.4604482, lng: 21.857534 },
    { lat: 48.4599028, lng: 21.8582425 },
    { lat: 48.4594467, lng: 21.8587481 },
    { lat: 48.4587681, lng: 21.859218 },
    { lat: 48.4578035, lng: 21.8593799 },
    { lat: 48.4571396, lng: 21.8593318 },
    { lat: 48.4563128, lng: 21.8591582 },
    { lat: 48.4550793, lng: 21.8587932 },
    { lat: 48.4519761, lng: 21.8570463 },
    { lat: 48.4513919, lng: 21.8567717 },
    { lat: 48.4507918, lng: 21.8565743 },
    { lat: 48.450494, lng: 21.8565484 },
    { lat: 48.4494401, lng: 21.8567358 },
    { lat: 48.448717, lng: 21.8570987 },
    { lat: 48.4478927, lng: 21.8574144 },
    { lat: 48.4468156, lng: 21.857702 },
    { lat: 48.4450764, lng: 21.8583659 },
    { lat: 48.444171, lng: 21.8584651 },
    { lat: 48.4435176, lng: 21.8584317 },
    { lat: 48.442365, lng: 21.8580589 },
    { lat: 48.4414638, lng: 21.8575775 },
    { lat: 48.4394679, lng: 21.8560099 },
    { lat: 48.4377044, lng: 21.8550794 },
    { lat: 48.437012, lng: 21.8548899 },
    { lat: 48.4346486, lng: 21.8549833 },
    { lat: 48.4341075, lng: 21.8547297 },
    { lat: 48.4339399, lng: 21.8543849 },
    { lat: 48.4337773, lng: 21.8538389 },
    { lat: 48.4331694, lng: 21.8536969 },
    { lat: 48.4325797, lng: 21.8540336 },
    { lat: 48.4323906, lng: 21.8539872 },
    { lat: 48.4255259, lng: 21.8504883 },
    { lat: 48.424947, lng: 21.8502462 },
    { lat: 48.4248819, lng: 21.8502543 },
    { lat: 48.4248682, lng: 21.8503808 },
    { lat: 48.4246375, lng: 21.8504659 },
    { lat: 48.4245512, lng: 21.8504762 },
    { lat: 48.4246767, lng: 21.8506339 },
    { lat: 48.4246004, lng: 21.850637 },
    { lat: 48.4247004, lng: 21.851064 },
    { lat: 48.4249683, lng: 21.854072 },
    { lat: 48.4250474, lng: 21.8545597 },
    { lat: 48.4256466, lng: 21.8555849 },
    { lat: 48.4256933, lng: 21.8557402 },
    { lat: 48.4256887, lng: 21.856084 },
    { lat: 48.425639, lng: 21.8560777 },
    { lat: 48.4256181, lng: 21.856272 },
    { lat: 48.4254448, lng: 21.8565258 },
    { lat: 48.425075, lng: 21.8568474 },
    { lat: 48.4248552, lng: 21.8568737 },
    { lat: 48.4245994, lng: 21.8570739 },
    { lat: 48.4243089, lng: 21.85759 },
    { lat: 48.4240835, lng: 21.8584026 },
    { lat: 48.4238923, lng: 21.859317 },
    { lat: 48.423358, lng: 21.8611086 },
    { lat: 48.4230294, lng: 21.861981 },
    { lat: 48.4229094, lng: 21.8622073 },
    { lat: 48.4223783, lng: 21.8627305 },
    { lat: 48.4220744, lng: 21.8633323 },
    { lat: 48.4216131, lng: 21.8638136 },
    { lat: 48.4214577, lng: 21.8642403 },
    { lat: 48.4212443, lng: 21.8653454 },
    { lat: 48.4207794, lng: 21.8663419 },
    { lat: 48.4205851, lng: 21.8670077 },
    { lat: 48.4206332, lng: 21.8670442 },
    { lat: 48.420495, lng: 21.8676647 },
    { lat: 48.4207958, lng: 21.8687264 },
    { lat: 48.4209195, lng: 21.8695101 },
    { lat: 48.4207486, lng: 21.8708302 },
    { lat: 48.4207543, lng: 21.8720522 },
    { lat: 48.421119, lng: 21.8719757 },
    { lat: 48.4213182, lng: 21.873591 },
    { lat: 48.4216098, lng: 21.8748265 },
    { lat: 48.4217262, lng: 21.8752834 },
    { lat: 48.4222533, lng: 21.8749602 },
    { lat: 48.4225911, lng: 21.874839 },
    { lat: 48.4233883, lng: 21.8747768 },
    { lat: 48.423809, lng: 21.8748211 },
    { lat: 48.4244328, lng: 21.8746163 },
    { lat: 48.4253521, lng: 21.8741443 },
    { lat: 48.425588, lng: 21.8740838 },
    { lat: 48.4270456, lng: 21.8742362 },
    { lat: 48.4276854, lng: 21.8741785 },
    { lat: 48.4278818, lng: 21.8749532 },
    { lat: 48.4281133, lng: 21.8750262 },
    { lat: 48.4284706, lng: 21.8750118 },
    { lat: 48.4291668, lng: 21.8751301 },
    { lat: 48.4295176, lng: 21.8753865 },
    { lat: 48.430618, lng: 21.8759163 },
    { lat: 48.4311244, lng: 21.8763574 },
    { lat: 48.4316522, lng: 21.8772791 },
    { lat: 48.4312311, lng: 21.8773145 },
    { lat: 48.4298743, lng: 21.8769677 },
    { lat: 48.4298999, lng: 21.877302 },
    { lat: 48.4298341, lng: 21.8774118 },
    { lat: 48.4297258, lng: 21.8773979 },
    { lat: 48.4299671, lng: 21.8779914 },
    { lat: 48.430171, lng: 21.8787072 },
    { lat: 48.4308285, lng: 21.878723 },
    { lat: 48.4313171, lng: 21.8790875 },
    { lat: 48.4317612, lng: 21.8792496 },
    { lat: 48.431759, lng: 21.8802197 },
    { lat: 48.4323047, lng: 21.880311 },
    { lat: 48.4333865, lng: 21.880243 },
    { lat: 48.4339927, lng: 21.8802827 },
    { lat: 48.4352802, lng: 21.880861 },
    { lat: 48.4357347, lng: 21.8809523 },
    { lat: 48.4362952, lng: 21.8811757 },
    { lat: 48.4371049, lng: 21.8812996 },
    { lat: 48.4373329, lng: 21.8812853 },
    { lat: 48.4384416, lng: 21.880904 },
    { lat: 48.4391152, lng: 21.8805861 },
    { lat: 48.4391081, lng: 21.8802255 },
    { lat: 48.4392801, lng: 21.8801309 },
    { lat: 48.4403109, lng: 21.8798373 },
    { lat: 48.4413927, lng: 21.87977 },
    { lat: 48.4415084, lng: 21.8803763 },
    { lat: 48.4421198, lng: 21.8805262 },
    { lat: 48.4425653, lng: 21.8804851 },
    { lat: 48.4430088, lng: 21.8825104 },
    { lat: 48.4426633, lng: 21.8827267 },
    { lat: 48.4428367, lng: 21.8832553 },
    { lat: 48.4443982, lng: 21.8818042 },
    { lat: 48.4449083, lng: 21.8811219 },
    { lat: 48.4451305, lng: 21.8812288 },
    { lat: 48.4455252, lng: 21.8812205 },
    { lat: 48.4457504, lng: 21.8811015 },
    { lat: 48.4458914, lng: 21.8809371 },
    { lat: 48.4461426, lng: 21.8804296 },
    { lat: 48.4462542, lng: 21.880348 },
    { lat: 48.4465401, lng: 21.8803931 },
    { lat: 48.4467572, lng: 21.8803437 },
    { lat: 48.4469629, lng: 21.8802123 },
    { lat: 48.4473994, lng: 21.8796381 },
    { lat: 48.4477576, lng: 21.8786028 },
    { lat: 48.4483286, lng: 21.8774442 },
    { lat: 48.4491517, lng: 21.8765491 },
    { lat: 48.4492521, lng: 21.8761602 },
    { lat: 48.4493041, lng: 21.875386 },
    { lat: 48.4494043, lng: 21.8751296 },
    { lat: 48.4498237, lng: 21.8745872 },
    { lat: 48.4501319, lng: 21.8740203 },
    { lat: 48.4503747, lng: 21.8738818 },
    { lat: 48.4507211, lng: 21.8738752 },
    { lat: 48.4511409, lng: 21.8735691 },
    { lat: 48.4517768, lng: 21.873581 },
    { lat: 48.4525255, lng: 21.8733876 },
    { lat: 48.4531825, lng: 21.8729912 },
    { lat: 48.4534966, lng: 21.8726433 },
    { lat: 48.4538444, lng: 21.872122 },
    { lat: 48.4542358, lng: 21.872078 },
    { lat: 48.4542728, lng: 21.8719401 },
    { lat: 48.4546894, lng: 21.8705109 },
    { lat: 48.4548842, lng: 21.8699921 },
    { lat: 48.455162, lng: 21.8695105 },
    { lat: 48.4565741, lng: 21.8682077 },
    { lat: 48.4591921, lng: 21.8663707 },
    { lat: 48.4600426, lng: 21.8654889 },
    { lat: 48.4604342, lng: 21.864967 },
    { lat: 48.4607763, lng: 21.8642377 },
    { lat: 48.4613192, lng: 21.8628176 },
    { lat: 48.4617407, lng: 21.8607048 },
    { lat: 48.4619455, lng: 21.8599269 },
    { lat: 48.4620941, lng: 21.8595368 },
    { lat: 48.4624815, lng: 21.8591189 },
    { lat: 48.4628378, lng: 21.8590102 },
    { lat: 48.4631505, lng: 21.8591423 },
    { lat: 48.4633065, lng: 21.8592576 },
    { lat: 48.4637183, lng: 21.8597858 },
    { lat: 48.4641702, lng: 21.8607255 },
    { lat: 48.464393, lng: 21.8613392 },
    { lat: 48.4646817, lng: 21.8624103 },
    { lat: 48.4649298, lng: 21.8631297 },
    { lat: 48.4653857, lng: 21.8639298 },
    { lat: 48.4659044, lng: 21.8642814 },
    { lat: 48.4663064, lng: 21.8642104 },
    { lat: 48.4665144, lng: 21.8640264 },
    { lat: 48.4666649, lng: 21.8637581 },
    { lat: 48.4668122, lng: 21.8631809 },
    { lat: 48.4670622, lng: 21.859791 },
    { lat: 48.4671198, lng: 21.8584883 },
    { lat: 48.4670822, lng: 21.8574266 },
    { lat: 48.4669912, lng: 21.8564304 },
    { lat: 48.4668792, lng: 21.8554447 },
    { lat: 48.4666321, lng: 21.8544686 },
    { lat: 48.466202, lng: 21.8534529 },
    { lat: 48.4658846, lng: 21.8530153 },
    { lat: 48.465627, lng: 21.8527974 },
    { lat: 48.4652883, lng: 21.8526198 },
  ],
  VeľkýHoreš: [
    { lat: 48.37457, lng: 21.939243 },
    { lat: 48.3747709, lng: 21.939206 },
    { lat: 48.3758927, lng: 21.9391251 },
    { lat: 48.3770082, lng: 21.9387936 },
    { lat: 48.3774273, lng: 21.9385929 },
    { lat: 48.3778267, lng: 21.9382786 },
    { lat: 48.3790644, lng: 21.9369387 },
    { lat: 48.3794498, lng: 21.9362055 },
    { lat: 48.3794988, lng: 21.9358771 },
    { lat: 48.3794213, lng: 21.9355687 },
    { lat: 48.3792208, lng: 21.935332 },
    { lat: 48.378923, lng: 21.9351723 },
    { lat: 48.3782023, lng: 21.9351895 },
    { lat: 48.3769547, lng: 21.9358177 },
    { lat: 48.3759828, lng: 21.9364472 },
    { lat: 48.3753761, lng: 21.9365673 },
    { lat: 48.3749058, lng: 21.9365678 },
    { lat: 48.3745908, lng: 21.9365266 },
    { lat: 48.3743313, lng: 21.9363615 },
    { lat: 48.3740945, lng: 21.9360661 },
    { lat: 48.3740077, lng: 21.9357939 },
    { lat: 48.3739938, lng: 21.9353375 },
    { lat: 48.3740606, lng: 21.9351243 },
    { lat: 48.374259, lng: 21.9348313 },
    { lat: 48.3744753, lng: 21.9346216 },
    { lat: 48.3753967, lng: 21.9339949 },
    { lat: 48.3757679, lng: 21.9335906 },
    { lat: 48.3758875, lng: 21.9333757 },
    { lat: 48.3762734, lng: 21.9323566 },
    { lat: 48.3763005, lng: 21.9315076 },
    { lat: 48.3761268, lng: 21.930872 },
    { lat: 48.375938, lng: 21.9304347 },
    { lat: 48.3756797, lng: 21.9300948 },
    { lat: 48.3745337, lng: 21.9289073 },
    { lat: 48.3731402, lng: 21.9271466 },
    { lat: 48.3730114, lng: 21.9268054 },
    { lat: 48.3730072, lng: 21.9264635 },
    { lat: 48.3732341, lng: 21.925918 },
    { lat: 48.3734456, lng: 21.9257411 },
    { lat: 48.3752672, lng: 21.9248047 },
    { lat: 48.3763285, lng: 21.9243376 },
    { lat: 48.3773125, lng: 21.9243169 },
    { lat: 48.3778855, lng: 21.924602 },
    { lat: 48.3789821, lng: 21.9255198 },
    { lat: 48.380464, lng: 21.9274388 },
    { lat: 48.381365, lng: 21.9281923 },
    { lat: 48.3820374, lng: 21.9286254 },
    { lat: 48.3831531, lng: 21.9288815 },
    { lat: 48.3843963, lng: 21.9288844 },
    { lat: 48.3855512, lng: 21.9286399 },
    { lat: 48.3860362, lng: 21.9285953 },
    { lat: 48.3871005, lng: 21.9288253 },
    { lat: 48.387858, lng: 21.929236 },
    { lat: 48.3894249, lng: 21.9309208 },
    { lat: 48.389842, lng: 21.9313063 },
    { lat: 48.3902543, lng: 21.9315101 },
    { lat: 48.390652, lng: 21.9315285 },
    { lat: 48.3915679, lng: 21.9312202 },
    { lat: 48.3928113, lng: 21.9302949 },
    { lat: 48.3943166, lng: 21.9294018 },
    { lat: 48.395153, lng: 21.9293779 },
    { lat: 48.395664, lng: 21.9296087 },
    { lat: 48.3961387, lng: 21.9304097 },
    { lat: 48.3964501, lng: 21.931379 },
    { lat: 48.3965122, lng: 21.9317303 },
    { lat: 48.3966836, lng: 21.9327 },
    { lat: 48.3966551, lng: 21.9333094 },
    { lat: 48.3969, lng: 21.9334314 },
    { lat: 48.3970608, lng: 21.9348539 },
    { lat: 48.3972609, lng: 21.936094 },
    { lat: 48.3975557, lng: 21.9371291 },
    { lat: 48.3977717, lng: 21.9369925 },
    { lat: 48.3984038, lng: 21.9362734 },
    { lat: 48.4012104, lng: 21.9346412 },
    { lat: 48.4016509, lng: 21.9327971 },
    { lat: 48.4021066, lng: 21.9325332 },
    { lat: 48.4034029, lng: 21.9318757 },
    { lat: 48.4039034, lng: 21.9316882 },
    { lat: 48.40568, lng: 21.9317352 },
    { lat: 48.4058046, lng: 21.9315511 },
    { lat: 48.4061878, lng: 21.9320689 },
    { lat: 48.4063758, lng: 21.9315781 },
    { lat: 48.4070058, lng: 21.9317104 },
    { lat: 48.4073509, lng: 21.9316554 },
    { lat: 48.4073756, lng: 21.9315697 },
    { lat: 48.407565, lng: 21.9308256 },
    { lat: 48.4081601, lng: 21.9302376 },
    { lat: 48.411343, lng: 21.9282862 },
    { lat: 48.4110008, lng: 21.924987 },
    { lat: 48.4109432, lng: 21.9232381 },
    { lat: 48.4106777, lng: 21.9215493 },
    { lat: 48.4104987, lng: 21.9199479 },
    { lat: 48.4106287, lng: 21.9192157 },
    { lat: 48.4108061, lng: 21.9171538 },
    { lat: 48.4108342, lng: 21.9163565 },
    { lat: 48.4111374, lng: 21.9160672 },
    { lat: 48.4110584, lng: 21.9146825 },
    { lat: 48.4111859, lng: 21.9140761 },
    { lat: 48.4114116, lng: 21.9136616 },
    { lat: 48.411518, lng: 21.9135992 },
    { lat: 48.4117178, lng: 21.9136203 },
    { lat: 48.4120614, lng: 21.9140816 },
    { lat: 48.4124413, lng: 21.9143814 },
    { lat: 48.4127591, lng: 21.9143101 },
    { lat: 48.4129332, lng: 21.9140788 },
    { lat: 48.4130897, lng: 21.9137274 },
    { lat: 48.4132233, lng: 21.9133234 },
    { lat: 48.4133044, lng: 21.9128319 },
    { lat: 48.4133399, lng: 21.911925 },
    { lat: 48.4135032, lng: 21.911228 },
    { lat: 48.4134389, lng: 21.9106902 },
    { lat: 48.4135094, lng: 21.910144 },
    { lat: 48.4137676, lng: 21.9091903 },
    { lat: 48.4136299, lng: 21.9088632 },
    { lat: 48.4133808, lng: 21.9088864 },
    { lat: 48.4131147, lng: 21.9091283 },
    { lat: 48.4129302, lng: 21.9092157 },
    { lat: 48.4125348, lng: 21.9090673 },
    { lat: 48.4118953, lng: 21.9084319 },
    { lat: 48.4115917, lng: 21.907905 },
    { lat: 48.4111505, lng: 21.907363 },
    { lat: 48.4105953, lng: 21.9068152 },
    { lat: 48.4097932, lng: 21.9057082 },
    { lat: 48.4096867, lng: 21.9052848 },
    { lat: 48.4093798, lng: 21.9052489 },
    { lat: 48.4086849, lng: 21.9052162 },
    { lat: 48.4078935, lng: 21.9050708 },
    { lat: 48.4074065, lng: 21.9054699 },
    { lat: 48.4070963, lng: 21.9058821 },
    { lat: 48.4065829, lng: 21.9057731 },
    { lat: 48.4061936, lng: 21.9055586 },
    { lat: 48.4048099, lng: 21.9056943 },
    { lat: 48.4044021, lng: 21.9055856 },
    { lat: 48.4034645, lng: 21.9051671 },
    { lat: 48.4027344, lng: 21.9049913 },
    { lat: 48.4024338, lng: 21.9048545 },
    { lat: 48.4012839, lng: 21.9039678 },
    { lat: 48.4010133, lng: 21.9036381 },
    { lat: 48.4003898, lng: 21.9030459 },
    { lat: 48.400072, lng: 21.9028998 },
    { lat: 48.3996764, lng: 21.9029162 },
    { lat: 48.3993524, lng: 21.9011483 },
    { lat: 48.3993084, lng: 21.8995988 },
    { lat: 48.3993551, lng: 21.8977693 },
    { lat: 48.3993278, lng: 21.8969183 },
    { lat: 48.3992238, lng: 21.8942605 },
    { lat: 48.3990566, lng: 21.8925981 },
    { lat: 48.3992278, lng: 21.8896564 },
    { lat: 48.4002646, lng: 21.8859903 },
    { lat: 48.400995, lng: 21.8828803 },
    { lat: 48.4022559, lng: 21.8784457 },
    { lat: 48.402648, lng: 21.8772018 },
    { lat: 48.402721, lng: 21.8768832 },
    { lat: 48.4027694, lng: 21.8760968 },
    { lat: 48.4032798, lng: 21.8741521 },
    { lat: 48.4035067, lng: 21.8734442 },
    { lat: 48.4037395, lng: 21.872302 },
    { lat: 48.4037411, lng: 21.8714932 },
    { lat: 48.4040236, lng: 21.8691339 },
    { lat: 48.4042554, lng: 21.8662531 },
    { lat: 48.4049349, lng: 21.860549 },
    { lat: 48.4047331, lng: 21.8607031 },
    { lat: 48.4046052, lng: 21.8607994 },
    { lat: 48.4031138, lng: 21.8617378 },
    { lat: 48.4014465, lng: 21.8633903 },
    { lat: 48.3969015, lng: 21.8674196 },
    { lat: 48.3966193, lng: 21.8672477 },
    { lat: 48.3964967, lng: 21.8670293 },
    { lat: 48.3962042, lng: 21.8665085 },
    { lat: 48.3960176, lng: 21.8664309 },
    { lat: 48.3925414, lng: 21.866353 },
    { lat: 48.3925491, lng: 21.8665218 },
    { lat: 48.3913213, lng: 21.8671655 },
    { lat: 48.3911018, lng: 21.867859 },
    { lat: 48.3914932, lng: 21.8695159 },
    { lat: 48.3919769, lng: 21.8707303 },
    { lat: 48.3920451, lng: 21.8711558 },
    { lat: 48.392352, lng: 21.8718859 },
    { lat: 48.3925446, lng: 21.8726819 },
    { lat: 48.3929297, lng: 21.8736104 },
    { lat: 48.3927618, lng: 21.8753055 },
    { lat: 48.3894012, lng: 21.8772358 },
    { lat: 48.3893168, lng: 21.8782357 },
    { lat: 48.3889068, lng: 21.8784828 },
    { lat: 48.3880614, lng: 21.8784677 },
    { lat: 48.3880931, lng: 21.8781685 },
    { lat: 48.3873598, lng: 21.8781292 },
    { lat: 48.3873522, lng: 21.8780153 },
    { lat: 48.3868844, lng: 21.877847 },
    { lat: 48.3868192, lng: 21.8770643 },
    { lat: 48.3862274, lng: 21.8771882 },
    { lat: 48.3859722, lng: 21.8778567 },
    { lat: 48.3850735, lng: 21.8783069 },
    { lat: 48.3847585, lng: 21.8785258 },
    { lat: 48.3837275, lng: 21.878898 },
    { lat: 48.3832715, lng: 21.8788908 },
    { lat: 48.3811988, lng: 21.8794875 },
    { lat: 48.3805152, lng: 21.8792468 },
    { lat: 48.3798598, lng: 21.8794826 },
    { lat: 48.3793299, lng: 21.8797921 },
    { lat: 48.3789518, lng: 21.8798766 },
    { lat: 48.3780662, lng: 21.8795577 },
    { lat: 48.3768204, lng: 21.8797692 },
    { lat: 48.3766454, lng: 21.8796555 },
    { lat: 48.3743994, lng: 21.8823581 },
    { lat: 48.3736517, lng: 21.8833366 },
    { lat: 48.3731243, lng: 21.8839169 },
    { lat: 48.3731541, lng: 21.8841587 },
    { lat: 48.3728566, lng: 21.8844925 },
    { lat: 48.3709806, lng: 21.8857951 },
    { lat: 48.3691256, lng: 21.8865469 },
    { lat: 48.368778, lng: 21.886745 },
    { lat: 48.368898, lng: 21.887049 },
    { lat: 48.368981, lng: 21.887309 },
    { lat: 48.36904, lng: 21.887553 },
    { lat: 48.369131, lng: 21.888131 },
    { lat: 48.369196, lng: 21.889026 },
    { lat: 48.369144, lng: 21.889836 },
    { lat: 48.369113, lng: 21.890162 },
    { lat: 48.369005, lng: 21.89066 },
    { lat: 48.368957, lng: 21.890894 },
    { lat: 48.368887, lng: 21.89118 },
    { lat: 48.368795, lng: 21.891423 },
    { lat: 48.368628, lng: 21.891646 },
    { lat: 48.368496, lng: 21.891749 },
    { lat: 48.368313, lng: 21.891793 },
    { lat: 48.368179, lng: 21.891798 },
    { lat: 48.368034, lng: 21.891788 },
    { lat: 48.367945, lng: 21.891759 },
    { lat: 48.367792, lng: 21.891654 },
    { lat: 48.367669, lng: 21.891556 },
    { lat: 48.367526, lng: 21.891432 },
    { lat: 48.367336, lng: 21.891223 },
    { lat: 48.367175, lng: 21.891011 },
    { lat: 48.367045, lng: 21.890806 },
    { lat: 48.36691, lng: 21.890564 },
    { lat: 48.366781, lng: 21.890301 },
    { lat: 48.36652, lng: 21.88973 },
    { lat: 48.366073, lng: 21.888694 },
    { lat: 48.365933, lng: 21.888429 },
    { lat: 48.365765, lng: 21.88818 },
    { lat: 48.365522, lng: 21.8879 },
    { lat: 48.365365, lng: 21.887739 },
    { lat: 48.365185, lng: 21.887595 },
    { lat: 48.364735, lng: 21.887338 },
    { lat: 48.364536, lng: 21.88725 },
    { lat: 48.364313, lng: 21.887213 },
    { lat: 48.364086, lng: 21.887237 },
    { lat: 48.363931, lng: 21.887277 },
    { lat: 48.3638184, lng: 21.8873477 },
    { lat: 48.363794, lng: 21.887363 },
    { lat: 48.363627, lng: 21.88753 },
    { lat: 48.363531, lng: 21.887753 },
    { lat: 48.363442, lng: 21.888016 },
    { lat: 48.36341, lng: 21.888137 },
    { lat: 48.363392, lng: 21.888271 },
    { lat: 48.363376, lng: 21.888522 },
    { lat: 48.363387, lng: 21.888723 },
    { lat: 48.363421, lng: 21.889007 },
    { lat: 48.363525, lng: 21.889695 },
    { lat: 48.363554, lng: 21.88993 },
    { lat: 48.363576, lng: 21.890204 },
    { lat: 48.363601, lng: 21.890955 },
    { lat: 48.36359, lng: 21.891183 },
    { lat: 48.363559, lng: 21.891404 },
    { lat: 48.363515, lng: 21.891813 },
    { lat: 48.363464, lng: 21.892172 },
    { lat: 48.362993, lng: 21.893896 },
    { lat: 48.362841, lng: 21.894408 },
    { lat: 48.362711, lng: 21.894857 },
    { lat: 48.362634, lng: 21.895195 },
    { lat: 48.362557, lng: 21.895617 },
    { lat: 48.362526, lng: 21.895829 },
    { lat: 48.362508, lng: 21.896141 },
    { lat: 48.362475, lng: 21.896507 },
    { lat: 48.36248, lng: 21.896846 },
    { lat: 48.36249, lng: 21.897229 },
    { lat: 48.362515, lng: 21.897516 },
    { lat: 48.36256, lng: 21.897883 },
    { lat: 48.36265, lng: 21.898377 },
    { lat: 48.362716, lng: 21.898683 },
    { lat: 48.362794, lng: 21.89895 },
    { lat: 48.362877, lng: 21.89921 },
    { lat: 48.36299, lng: 21.899412 },
    { lat: 48.363145, lng: 21.899593 },
    { lat: 48.363344, lng: 21.899789 },
    { lat: 48.363525, lng: 21.899877 },
    { lat: 48.363679, lng: 21.899943 },
    { lat: 48.363782, lng: 21.899955 },
    { lat: 48.363822, lng: 21.899948 },
    { lat: 48.363868, lng: 21.899916 },
    { lat: 48.364268, lng: 21.899759 },
    { lat: 48.364903, lng: 21.899403 },
    { lat: 48.365152, lng: 21.899254 },
    { lat: 48.365471, lng: 21.899056 },
    { lat: 48.365831, lng: 21.898798 },
    { lat: 48.366281, lng: 21.898481 },
    { lat: 48.366604, lng: 21.898248 },
    { lat: 48.367104, lng: 21.897909 },
    { lat: 48.367404, lng: 21.897699 },
    { lat: 48.367729, lng: 21.8975 },
    { lat: 48.367955, lng: 21.897391 },
    { lat: 48.368137, lng: 21.897345 },
    { lat: 48.368308, lng: 21.897325 },
    { lat: 48.368558, lng: 21.897341 },
    { lat: 48.368771, lng: 21.897388 },
    { lat: 48.368929, lng: 21.89745 },
    { lat: 48.369149, lng: 21.897583 },
    { lat: 48.369331, lng: 21.897696 },
    { lat: 48.369517, lng: 21.89785 },
    { lat: 48.369683, lng: 21.898013 },
    { lat: 48.369826, lng: 21.898188 },
    { lat: 48.369926, lng: 21.898368 },
    { lat: 48.370041, lng: 21.898641 },
    { lat: 48.370123, lng: 21.89886 },
    { lat: 48.370177, lng: 21.899065 },
    { lat: 48.370212, lng: 21.899309 },
    { lat: 48.370223, lng: 21.899566 },
    { lat: 48.370223, lng: 21.899796 },
    { lat: 48.370192, lng: 21.899949 },
    { lat: 48.370107, lng: 21.900185 },
    { lat: 48.369971, lng: 21.900399 },
    { lat: 48.369803, lng: 21.90059 },
    { lat: 48.369607, lng: 21.900733 },
    { lat: 48.369443, lng: 21.900821 },
    { lat: 48.369264, lng: 21.900895 },
    { lat: 48.369101, lng: 21.900919 },
    { lat: 48.368874, lng: 21.900928 },
    { lat: 48.368627, lng: 21.900914 },
    { lat: 48.367677, lng: 21.900687 },
    { lat: 48.367341, lng: 21.900581 },
    { lat: 48.367125, lng: 21.90054 },
    { lat: 48.366945, lng: 21.900501 },
    { lat: 48.366769, lng: 21.900508 },
    { lat: 48.366492, lng: 21.900513 },
    { lat: 48.3662, lng: 21.900564 },
    { lat: 48.365834, lng: 21.900652 },
    { lat: 48.365562, lng: 21.90075 },
    { lat: 48.36526, lng: 21.900921 },
    { lat: 48.365005, lng: 21.90124 },
    { lat: 48.364876, lng: 21.901545 },
    { lat: 48.364826, lng: 21.901804 },
    { lat: 48.364802, lng: 21.902156 },
    { lat: 48.364867, lng: 21.902562 },
    { lat: 48.364948, lng: 21.902881 },
    { lat: 48.365136, lng: 21.903315 },
    { lat: 48.365308, lng: 21.903572 },
    { lat: 48.36543, lng: 21.903694 },
    { lat: 48.365595, lng: 21.903905 },
    { lat: 48.366392, lng: 21.90481 },
    { lat: 48.366621, lng: 21.905052 },
    { lat: 48.366829, lng: 21.905299 },
    { lat: 48.367104, lng: 21.905741 },
    { lat: 48.367281, lng: 21.906169 },
    { lat: 48.367421, lng: 21.906632 },
    { lat: 48.367514, lng: 21.907048 },
    { lat: 48.367699, lng: 21.908007 },
    { lat: 48.367927, lng: 21.908833 },
    { lat: 48.368092, lng: 21.909272 },
    { lat: 48.368229, lng: 21.90961 },
    { lat: 48.368454, lng: 21.909966 },
    { lat: 48.368723, lng: 21.910314 },
    { lat: 48.369143, lng: 21.910908 },
    { lat: 48.3691865, lng: 21.9110033 },
    { lat: 48.369279, lng: 21.911206 },
    { lat: 48.369308, lng: 21.911291 },
    { lat: 48.369398, lng: 21.911585 },
    { lat: 48.369419, lng: 21.911755 },
    { lat: 48.36941, lng: 21.912061 },
    { lat: 48.369366, lng: 21.912261 },
    { lat: 48.369288, lng: 21.912458 },
    { lat: 48.369191, lng: 21.912585 },
    { lat: 48.369076, lng: 21.912633 },
    { lat: 48.368957, lng: 21.912604 },
    { lat: 48.368811, lng: 21.912525 },
    { lat: 48.368683, lng: 21.912403 },
    { lat: 48.368397, lng: 21.912046 },
    { lat: 48.368092, lng: 21.911739 },
    { lat: 48.3679013, lng: 21.9116085 },
    { lat: 48.367867, lng: 21.911585 },
    { lat: 48.367663, lng: 21.911494 },
    { lat: 48.3675146, lng: 21.9114628 },
    { lat: 48.367492, lng: 21.911458 },
    { lat: 48.367337, lng: 21.911461 },
    { lat: 48.367169, lng: 21.911507 },
    { lat: 48.367042, lng: 21.911574 },
    { lat: 48.366776, lng: 21.911785 },
    { lat: 48.366595, lng: 21.912091 },
    { lat: 48.366543, lng: 21.912248 },
    { lat: 48.366505, lng: 21.912426 },
    { lat: 48.36647, lng: 21.912647 },
    { lat: 48.36636, lng: 21.913614 },
    { lat: 48.366296, lng: 21.914092 },
    { lat: 48.366223, lng: 21.914456 },
    { lat: 48.36615, lng: 21.914832 },
    { lat: 48.365998, lng: 21.915275 },
    { lat: 48.365762, lng: 21.915739 },
    { lat: 48.365419, lng: 21.916175 },
    { lat: 48.365213, lng: 21.916346 },
    { lat: 48.364678, lng: 21.91673 },
    { lat: 48.364434, lng: 21.916894 },
    { lat: 48.363889, lng: 21.917167 },
    { lat: 48.36367, lng: 21.917298 },
    { lat: 48.363518, lng: 21.917416 },
    { lat: 48.363399, lng: 21.917542 },
    { lat: 48.36331, lng: 21.917764 },
    { lat: 48.363263, lng: 21.918133 },
    { lat: 48.363252, lng: 21.918285 },
    { lat: 48.36327, lng: 21.918488 },
    { lat: 48.363356, lng: 21.918906 },
    { lat: 48.363787, lng: 21.920131 },
    { lat: 48.363845, lng: 21.920465 },
    { lat: 48.363865, lng: 21.920814 },
    { lat: 48.363843, lng: 21.921072 },
    { lat: 48.363817, lng: 21.921342 },
    { lat: 48.363777, lng: 21.921564 },
    { lat: 48.363685, lng: 21.921896 },
    { lat: 48.363592, lng: 21.922173 },
    { lat: 48.363406, lng: 21.92263 },
    { lat: 48.363267, lng: 21.922891 },
    { lat: 48.362835, lng: 21.923651 },
    { lat: 48.36266, lng: 21.924 },
    { lat: 48.362448, lng: 21.92451 },
    { lat: 48.362291, lng: 21.924941 },
    { lat: 48.362136, lng: 21.925325 },
    { lat: 48.362018, lng: 21.925629 },
    { lat: 48.361813, lng: 21.926236 },
    { lat: 48.361766, lng: 21.926359 },
    { lat: 48.361806, lng: 21.926487 },
    { lat: 48.361707, lng: 21.92673 },
    { lat: 48.361631, lng: 21.927071 },
    { lat: 48.3616, lng: 21.927559 },
    { lat: 48.361597, lng: 21.928186 },
    { lat: 48.361636, lng: 21.928423 },
    { lat: 48.361765, lng: 21.92894 },
    { lat: 48.3619, lng: 21.92944 },
    { lat: 48.362011, lng: 21.929804 },
    { lat: 48.362151, lng: 21.930135 },
    { lat: 48.362272, lng: 21.930383 },
    { lat: 48.362488, lng: 21.93067 },
    { lat: 48.362931, lng: 21.931167 },
    { lat: 48.363592, lng: 21.931897 },
    { lat: 48.363904, lng: 21.932186 },
    { lat: 48.364197, lng: 21.932293 },
    { lat: 48.36442, lng: 21.932314 },
    { lat: 48.364556, lng: 21.932306 },
    { lat: 48.364724, lng: 21.932271 },
    { lat: 48.364893, lng: 21.932196 },
    { lat: 48.36515, lng: 21.932028 },
    { lat: 48.365365, lng: 21.93186 },
    { lat: 48.365594, lng: 21.931611 },
    { lat: 48.365723, lng: 21.931431 },
    { lat: 48.365879, lng: 21.931183 },
    { lat: 48.366043, lng: 21.93089 },
    { lat: 48.366279, lng: 21.930312 },
    { lat: 48.366467, lng: 21.929796 },
    { lat: 48.366563, lng: 21.929578 },
    { lat: 48.366833, lng: 21.928971 },
    { lat: 48.36698, lng: 21.928693 },
    { lat: 48.367212, lng: 21.928207 },
    { lat: 48.367412, lng: 21.927868 },
    { lat: 48.367573, lng: 21.927658 },
    { lat: 48.367861, lng: 21.927358 },
    { lat: 48.36813, lng: 21.927147 },
    { lat: 48.368332, lng: 21.927024 },
    { lat: 48.368752, lng: 21.926804 },
    { lat: 48.368913, lng: 21.926746 },
    { lat: 48.369065, lng: 21.926643 },
    { lat: 48.369441, lng: 21.926477 },
    { lat: 48.369621, lng: 21.926426 },
    { lat: 48.369815, lng: 21.926398 },
    { lat: 48.369975, lng: 21.926401 },
    { lat: 48.370187, lng: 21.926438 },
    { lat: 48.370408, lng: 21.926505 },
    { lat: 48.370567, lng: 21.926579 },
    { lat: 48.370756, lng: 21.926687 },
    { lat: 48.370899, lng: 21.926806 },
    { lat: 48.371172, lng: 21.927145 },
    { lat: 48.371192, lng: 21.92733 },
    { lat: 48.371181, lng: 21.9274 },
    { lat: 48.371235, lng: 21.92777 },
    { lat: 48.371239, lng: 21.92801 },
    { lat: 48.371204, lng: 21.928269 },
    { lat: 48.370994, lng: 21.928835 },
    { lat: 48.370836, lng: 21.929209 },
    { lat: 48.370489, lng: 21.929904 },
    { lat: 48.370234, lng: 21.930514 },
    { lat: 48.369888, lng: 21.931396 },
    { lat: 48.369888, lng: 21.931489 },
    { lat: 48.369863, lng: 21.9315 },
    { lat: 48.369783, lng: 21.931704 },
    { lat: 48.36968, lng: 21.932182 },
    { lat: 48.369665, lng: 21.932408 },
    { lat: 48.369681, lng: 21.932637 },
    { lat: 48.369738, lng: 21.933038 },
    { lat: 48.369823, lng: 21.933439 },
    { lat: 48.369976, lng: 21.933903 },
    { lat: 48.370114, lng: 21.934231 },
    { lat: 48.370559, lng: 21.934987 },
    { lat: 48.370937, lng: 21.935544 },
    { lat: 48.371416, lng: 21.936336 },
    { lat: 48.372223, lng: 21.937765 },
    { lat: 48.372403, lng: 21.938062 },
    { lat: 48.372922, lng: 21.938754 },
    { lat: 48.373055, lng: 21.938889 },
    { lat: 48.373141, lng: 21.938944 },
    { lat: 48.373281, lng: 21.939004 },
    { lat: 48.374068, lng: 21.939172 },
    { lat: 48.37457, lng: 21.939243 },
  ],
  Boľ: [
    { lat: 48.4468262, lng: 21.9731701 },
    { lat: 48.4473222, lng: 21.9728464 },
    { lat: 48.4495838, lng: 21.9723204 },
    { lat: 48.4507883, lng: 21.9722581 },
    { lat: 48.4516619, lng: 21.9720064 },
    { lat: 48.4523033, lng: 21.9715579 },
    { lat: 48.452806, lng: 21.9708775 },
    { lat: 48.4529096, lng: 21.9705623 },
    { lat: 48.4529546, lng: 21.9702019 },
    { lat: 48.4529257, lng: 21.9692179 },
    { lat: 48.452821, lng: 21.9686773 },
    { lat: 48.452639, lng: 21.9670091 },
    { lat: 48.4524576, lng: 21.9660951 },
    { lat: 48.451833, lng: 21.964102 },
    { lat: 48.4506352, lng: 21.9614439 },
    { lat: 48.4500889, lng: 21.9603818 },
    { lat: 48.449955, lng: 21.959844 },
    { lat: 48.4499192, lng: 21.9591776 },
    { lat: 48.4501449, lng: 21.9579901 },
    { lat: 48.4503224, lng: 21.9575719 },
    { lat: 48.4505299, lng: 21.957367 },
    { lat: 48.4508301, lng: 21.9571343 },
    { lat: 48.451312, lng: 21.9570996 },
    { lat: 48.4517999, lng: 21.9572284 },
    { lat: 48.4533055, lng: 21.9581389 },
    { lat: 48.4538266, lng: 21.958689 },
    { lat: 48.4545109, lng: 21.9595559 },
    { lat: 48.455902, lng: 21.961929 },
    { lat: 48.4566884, lng: 21.9630971 },
    { lat: 48.457054, lng: 21.9635026 },
    { lat: 48.4573882, lng: 21.9637769 },
    { lat: 48.4577993, lng: 21.9639334 },
    { lat: 48.4581259, lng: 21.9638165 },
    { lat: 48.4582541, lng: 21.9638497 },
    { lat: 48.4584701, lng: 21.9636723 },
    { lat: 48.4585631, lng: 21.9632056 },
    { lat: 48.4584599, lng: 21.9628102 },
    { lat: 48.4582182, lng: 21.9624099 },
    { lat: 48.4580421, lng: 21.9619363 },
    { lat: 48.4576312, lng: 21.9594912 },
    { lat: 48.4576567, lng: 21.9586679 },
    { lat: 48.4579799, lng: 21.9575062 },
    { lat: 48.4585525, lng: 21.956014 },
    { lat: 48.4588124, lng: 21.9557076 },
    { lat: 48.4597675, lng: 21.955212 },
    { lat: 48.4601524, lng: 21.95485 },
    { lat: 48.4603928, lng: 21.9547042 },
    { lat: 48.4610601, lng: 21.954389 },
    { lat: 48.4613196, lng: 21.9544279 },
    { lat: 48.4615824, lng: 21.9545832 },
    { lat: 48.4623143, lng: 21.9546095 },
    { lat: 48.4635082, lng: 21.9550273 },
    { lat: 48.4645096, lng: 21.9555744 },
    { lat: 48.4653229, lng: 21.9564686 },
    { lat: 48.4657041, lng: 21.9570079 },
    { lat: 48.4664374, lng: 21.9581257 },
    { lat: 48.4669347, lng: 21.9591129 },
    { lat: 48.4672434, lng: 21.9595914 },
    { lat: 48.4676731, lng: 21.9600319 },
    { lat: 48.4679279, lng: 21.9604167 },
    { lat: 48.4681636, lng: 21.9606605 },
    { lat: 48.4684173, lng: 21.9611836 },
    { lat: 48.4688071, lng: 21.9614824 },
    { lat: 48.4692259, lng: 21.9614844 },
    { lat: 48.4695357, lng: 21.9607398 },
    { lat: 48.4702936, lng: 21.9584721 },
    { lat: 48.4707802, lng: 21.9573669 },
    { lat: 48.4719018, lng: 21.9561071 },
    { lat: 48.4741328, lng: 21.9547557 },
    { lat: 48.4744318, lng: 21.9544364 },
    { lat: 48.4748641, lng: 21.9536341 },
    { lat: 48.4750849, lng: 21.9528838 },
    { lat: 48.4761504, lng: 21.952118 },
    { lat: 48.478828, lng: 21.9519485 },
    { lat: 48.478927, lng: 21.9518992 },
    { lat: 48.479057, lng: 21.9518274 },
    { lat: 48.4803447, lng: 21.9503326 },
    { lat: 48.4809554, lng: 21.9496984 },
    { lat: 48.4812734, lng: 21.9495464 },
    { lat: 48.4814164, lng: 21.9497769 },
    { lat: 48.4820889, lng: 21.950264 },
    { lat: 48.4825516, lng: 21.9507694 },
    { lat: 48.4833131, lng: 21.9512139 },
    { lat: 48.4840715, lng: 21.9515735 },
    { lat: 48.4845458, lng: 21.9514222 },
    { lat: 48.4847978, lng: 21.9515607 },
    { lat: 48.485028, lng: 21.9518101 },
    { lat: 48.4852201, lng: 21.952161 },
    { lat: 48.48529, lng: 21.9527039 },
    { lat: 48.485429, lng: 21.9530492 },
    { lat: 48.4870685, lng: 21.9534189 },
    { lat: 48.4875526, lng: 21.9533018 },
    { lat: 48.4877386, lng: 21.9531793 },
    { lat: 48.4887322, lng: 21.9530553 },
    { lat: 48.4888503, lng: 21.9537718 },
    { lat: 48.4889305, lng: 21.9539619 },
    { lat: 48.4896267, lng: 21.9546798 },
    { lat: 48.4897328, lng: 21.9550962 },
    { lat: 48.490059, lng: 21.9556782 },
    { lat: 48.4902535, lng: 21.9558608 },
    { lat: 48.4903846, lng: 21.9558304 },
    { lat: 48.4904923, lng: 21.9556416 },
    { lat: 48.4905429, lng: 21.9554207 },
    { lat: 48.4905398, lng: 21.9539841 },
    { lat: 48.4907403, lng: 21.9536791 },
    { lat: 48.490861, lng: 21.9535545 },
    { lat: 48.4911225, lng: 21.9534936 },
    { lat: 48.4916717, lng: 21.9527341 },
    { lat: 48.4919506, lng: 21.9524612 },
    { lat: 48.4928846, lng: 21.9520643 },
    { lat: 48.493824, lng: 21.9508098 },
    { lat: 48.4940989, lng: 21.9507336 },
    { lat: 48.4950735, lng: 21.9511037 },
    { lat: 48.4955538, lng: 21.951549 },
    { lat: 48.4959159, lng: 21.9517289 },
    { lat: 48.4961223, lng: 21.9520627 },
    { lat: 48.4962765, lng: 21.9521498 },
    { lat: 48.4972707, lng: 21.9521414 },
    { lat: 48.497711, lng: 21.9519806 },
    { lat: 48.4979527, lng: 21.9519737 },
    { lat: 48.4982682, lng: 21.9520807 },
    { lat: 48.4986104, lng: 21.9515012 },
    { lat: 48.4986762, lng: 21.9516142 },
    { lat: 48.4988535, lng: 21.9512385 },
    { lat: 48.4990683, lng: 21.9509667 },
    { lat: 48.4996918, lng: 21.9509246 },
    { lat: 48.4999684, lng: 21.9508348 },
    { lat: 48.500324, lng: 21.9500737 },
    { lat: 48.500596, lng: 21.9498838 },
    { lat: 48.5006636, lng: 21.9497497 },
    { lat: 48.5006044, lng: 21.949635 },
    { lat: 48.5006647, lng: 21.9494379 },
    { lat: 48.5007663, lng: 21.949315 },
    { lat: 48.5008664, lng: 21.949358 },
    { lat: 48.500922, lng: 21.9492424 },
    { lat: 48.5010135, lng: 21.9492156 },
    { lat: 48.5013618, lng: 21.9487017 },
    { lat: 48.5021511, lng: 21.9489092 },
    { lat: 48.5023705, lng: 21.9490379 },
    { lat: 48.5026712, lng: 21.9493285 },
    { lat: 48.5031471, lng: 21.9502753 },
    { lat: 48.5033857, lng: 21.9509067 },
    { lat: 48.503662, lng: 21.9513817 },
    { lat: 48.504019, lng: 21.9517975 },
    { lat: 48.5042575, lng: 21.9518894 },
    { lat: 48.50438, lng: 21.951831 },
    { lat: 48.504368, lng: 21.951765 },
    { lat: 48.504344, lng: 21.951249 },
    { lat: 48.504159, lng: 21.949646 },
    { lat: 48.503973, lng: 21.947726 },
    { lat: 48.50384, lng: 21.946443 },
    { lat: 48.503635, lng: 21.944663 },
    { lat: 48.503512, lng: 21.94346 },
    { lat: 48.503391, lng: 21.942255 },
    { lat: 48.503325, lng: 21.941179 },
    { lat: 48.503311, lng: 21.940374 },
    { lat: 48.503297, lng: 21.939021 },
    { lat: 48.503264, lng: 21.93735 },
    { lat: 48.503223, lng: 21.936134 },
    { lat: 48.503192, lng: 21.934302 },
    { lat: 48.503173, lng: 21.932779 },
    { lat: 48.503156, lng: 21.931561 },
    { lat: 48.503139, lng: 21.930287 },
    { lat: 48.503122, lng: 21.928663 },
    { lat: 48.503085, lng: 21.926956 },
    { lat: 48.503067, lng: 21.925468 },
    { lat: 48.503039, lng: 21.924093 },
    { lat: 48.503034, lng: 21.923086 },
    { lat: 48.503015, lng: 21.921868 },
    { lat: 48.502817, lng: 21.921929 },
    { lat: 48.502356, lng: 21.921978 },
    { lat: 48.502204, lng: 21.92199 },
    { lat: 48.502063, lng: 21.922001 },
    { lat: 48.501906, lng: 21.922013 },
    { lat: 48.501533, lng: 21.922083 },
    { lat: 48.500819, lng: 21.922219 },
    { lat: 48.500657, lng: 21.92225 },
    { lat: 48.500652, lng: 21.922251 },
    { lat: 48.500617, lng: 21.922155 },
    { lat: 48.500543, lng: 21.921963 },
    { lat: 48.500408, lng: 21.92161 },
    { lat: 48.500337, lng: 21.921237 },
    { lat: 48.499979, lng: 21.920865 },
    { lat: 48.499624, lng: 21.920502 },
    { lat: 48.499525, lng: 21.920403 },
    { lat: 48.499504, lng: 21.920382 },
    { lat: 48.499253, lng: 21.920231 },
    { lat: 48.499141, lng: 21.920153 },
    { lat: 48.499048, lng: 21.920584 },
    { lat: 48.498948, lng: 21.92052 },
    { lat: 48.498908, lng: 21.920462 },
    { lat: 48.498834, lng: 21.920356 },
    { lat: 48.498814, lng: 21.920325 },
    { lat: 48.498804, lng: 21.920309 },
    { lat: 48.498712, lng: 21.920281 },
    { lat: 48.498444, lng: 21.920224 },
    { lat: 48.498371, lng: 21.9202 },
    { lat: 48.498229, lng: 21.920487 },
    { lat: 48.498089, lng: 21.920415 },
    { lat: 48.49808, lng: 21.920403 },
    { lat: 48.498056, lng: 21.920372 },
    { lat: 48.497825, lng: 21.919985 },
    { lat: 48.497666, lng: 21.9200626 },
    { lat: 48.4974945, lng: 21.9200462 },
    { lat: 48.4974405, lng: 21.9198684 },
    { lat: 48.4962696, lng: 21.9199651 },
    { lat: 48.4958044, lng: 21.9203 },
    { lat: 48.4955943, lng: 21.9208487 },
    { lat: 48.4954127, lng: 21.9214979 },
    { lat: 48.4952734, lng: 21.9234138 },
    { lat: 48.493411, lng: 21.9229069 },
    { lat: 48.4916557, lng: 21.9221761 },
    { lat: 48.4908426, lng: 21.9218376 },
    { lat: 48.4904992, lng: 21.9216057 },
    { lat: 48.4903047, lng: 21.9213938 },
    { lat: 48.4902013, lng: 21.9214809 },
    { lat: 48.4888106, lng: 21.9196031 },
    { lat: 48.4878588, lng: 21.9209339 },
    { lat: 48.4878144, lng: 21.9208754 },
    { lat: 48.4842838, lng: 21.9204635 },
    { lat: 48.4821496, lng: 21.9204174 },
    { lat: 48.4817726, lng: 21.9205486 },
    { lat: 48.4794743, lng: 21.9209963 },
    { lat: 48.4794242, lng: 21.9205251 },
    { lat: 48.4791664, lng: 21.9205196 },
    { lat: 48.4774055, lng: 21.9209529 },
    { lat: 48.475338, lng: 21.9217288 },
    { lat: 48.4750173, lng: 21.9211018 },
    { lat: 48.4745954, lng: 21.9214578 },
    { lat: 48.4731672, lng: 21.9221 },
    { lat: 48.4726447, lng: 21.9225751 },
    { lat: 48.4723664, lng: 21.9228672 },
    { lat: 48.4730368, lng: 21.9243772 },
    { lat: 48.4733086, lng: 21.9246662 },
    { lat: 48.4735335, lng: 21.9255149 },
    { lat: 48.4750705, lng: 21.9283367 },
    { lat: 48.4753517, lng: 21.9294006 },
    { lat: 48.4754184, lng: 21.9304002 },
    { lat: 48.4754019, lng: 21.9309919 },
    { lat: 48.4752932, lng: 21.9317653 },
    { lat: 48.4748787, lng: 21.933271 },
    { lat: 48.4744982, lng: 21.9341077 },
    { lat: 48.47393, lng: 21.9350881 },
    { lat: 48.4733301, lng: 21.9357466 },
    { lat: 48.4730025, lng: 21.9355628 },
    { lat: 48.4726439, lng: 21.9346806 },
    { lat: 48.4726352, lng: 21.9334356 },
    { lat: 48.4725145, lng: 21.9317318 },
    { lat: 48.4723679, lng: 21.931041 },
    { lat: 48.4721519, lng: 21.9303754 },
    { lat: 48.471692, lng: 21.9294022 },
    { lat: 48.4714329, lng: 21.9289887 },
    { lat: 48.4707733, lng: 21.9282151 },
    { lat: 48.4704637, lng: 21.9279417 },
    { lat: 48.4699435, lng: 21.9276537 },
    { lat: 48.469471, lng: 21.9274761 },
    { lat: 48.4686342, lng: 21.9273962 },
    { lat: 48.4675367, lng: 21.9275503 },
    { lat: 48.4665635, lng: 21.9280819 },
    { lat: 48.4659227, lng: 21.9285488 },
    { lat: 48.4643493, lng: 21.9298582 },
    { lat: 48.4638705, lng: 21.9303722 },
    { lat: 48.4631891, lng: 21.9309215 },
    { lat: 48.4629418, lng: 21.9311922 },
    { lat: 48.4628033, lng: 21.9315046 },
    { lat: 48.4628193, lng: 21.9321421 },
    { lat: 48.4630045, lng: 21.932436 },
    { lat: 48.4639009, lng: 21.932872 },
    { lat: 48.4650177, lng: 21.9330421 },
    { lat: 48.4666082, lng: 21.933076 },
    { lat: 48.4668565, lng: 21.9331912 },
    { lat: 48.4670692, lng: 21.9334188 },
    { lat: 48.4672498, lng: 21.9338023 },
    { lat: 48.4672697, lng: 21.9340968 },
    { lat: 48.46711, lng: 21.9346831 },
    { lat: 48.4668451, lng: 21.9350243 },
    { lat: 48.466403, lng: 21.9353763 },
    { lat: 48.4644883, lng: 21.9363636 },
    { lat: 48.4638879, lng: 21.9368723 },
    { lat: 48.4635279, lng: 21.9370949 },
    { lat: 48.4633576, lng: 21.9373066 },
    { lat: 48.4623945, lng: 21.9379224 },
    { lat: 48.4621863, lng: 21.9381923 },
    { lat: 48.4617794, lng: 21.9390091 },
    { lat: 48.4616366, lng: 21.9395588 },
    { lat: 48.4611259, lng: 21.9392801 },
    { lat: 48.4611106, lng: 21.9393466 },
    { lat: 48.4596858, lng: 21.9392364 },
    { lat: 48.458773, lng: 21.9392663 },
    { lat: 48.4581671, lng: 21.9394269 },
    { lat: 48.4578811, lng: 21.9388704 },
    { lat: 48.4577385, lng: 21.9387013 },
    { lat: 48.4567457, lng: 21.9380637 },
    { lat: 48.4567389, lng: 21.9380074 },
    { lat: 48.4566197, lng: 21.9380092 },
    { lat: 48.4546885, lng: 21.9368692 },
    { lat: 48.4529394, lng: 21.9361624 },
    { lat: 48.451531, lng: 21.9359454 },
    { lat: 48.4515787, lng: 21.935874 },
    { lat: 48.4505598, lng: 21.936118 },
    { lat: 48.4504349, lng: 21.9361573 },
    { lat: 48.4500825, lng: 21.9378943 },
    { lat: 48.4496084, lng: 21.9385492 },
    { lat: 48.449478, lng: 21.9389046 },
    { lat: 48.4494665, lng: 21.9392068 },
    { lat: 48.4495135, lng: 21.9395582 },
    { lat: 48.4497692, lng: 21.9401525 },
    { lat: 48.4496684, lng: 21.9403939 },
    { lat: 48.4494546, lng: 21.9407987 },
    { lat: 48.4489174, lng: 21.9413146 },
    { lat: 48.4486937, lng: 21.9417664 },
    { lat: 48.4485183, lng: 21.9418146 },
    { lat: 48.4479746, lng: 21.9421591 },
    { lat: 48.4473855, lng: 21.9421808 },
    { lat: 48.4472117, lng: 21.9424206 },
    { lat: 48.4479008, lng: 21.9439716 },
    { lat: 48.4483565, lng: 21.9451853 },
    { lat: 48.4511107, lng: 21.9433495 },
    { lat: 48.4510819, lng: 21.9439128 },
    { lat: 48.4511436, lng: 21.9445756 },
    { lat: 48.4511236, lng: 21.9450303 },
    { lat: 48.4507073, lng: 21.9456624 },
    { lat: 48.4504375, lng: 21.9463425 },
    { lat: 48.4503214, lng: 21.9472318 },
    { lat: 48.4497014, lng: 21.9479852 },
    { lat: 48.4498399, lng: 21.9481294 },
    { lat: 48.4496591, lng: 21.9488194 },
    { lat: 48.4499209, lng: 21.9492954 },
    { lat: 48.4500695, lng: 21.9498809 },
    { lat: 48.4500936, lng: 21.9501761 },
    { lat: 48.4500014, lng: 21.9512108 },
    { lat: 48.4499086, lng: 21.9517221 },
    { lat: 48.4496816, lng: 21.9524593 },
    { lat: 48.4492406, lng: 21.954215 },
    { lat: 48.4492253, lng: 21.954404 },
    { lat: 48.4495708, lng: 21.9574782 },
    { lat: 48.449499, lng: 21.9580297 },
    { lat: 48.449329, lng: 21.9612698 },
    { lat: 48.4488706, lng: 21.9622376 },
    { lat: 48.448632, lng: 21.9630508 },
    { lat: 48.4484121, lng: 21.9641585 },
    { lat: 48.4482658, lng: 21.9654598 },
    { lat: 48.4480154, lng: 21.9665657 },
    { lat: 48.4474059, lng: 21.9679739 },
    { lat: 48.4471503, lng: 21.9694574 },
    { lat: 48.4471031, lng: 21.9700065 },
    { lat: 48.4470931, lng: 21.9705524 },
    { lat: 48.4472411, lng: 21.971622 },
    { lat: 48.4471085, lng: 21.9720834 },
    { lat: 48.4467855, lng: 21.9725278 },
    { lat: 48.4467419, lng: 21.9727652 },
    { lat: 48.4468262, lng: 21.9731701 },
  ],
  Zatín: [
    { lat: 48.4723664, lng: 21.9228672 },
    { lat: 48.4726447, lng: 21.9225751 },
    { lat: 48.4731672, lng: 21.9221 },
    { lat: 48.4745954, lng: 21.9214578 },
    { lat: 48.4750173, lng: 21.9211018 },
    { lat: 48.475338, lng: 21.9217288 },
    { lat: 48.4774055, lng: 21.9209529 },
    { lat: 48.4791664, lng: 21.9205196 },
    { lat: 48.4794242, lng: 21.9205251 },
    { lat: 48.4794743, lng: 21.9209963 },
    { lat: 48.4817726, lng: 21.9205486 },
    { lat: 48.4821496, lng: 21.9204174 },
    { lat: 48.4842838, lng: 21.9204635 },
    { lat: 48.4878144, lng: 21.9208754 },
    { lat: 48.4878588, lng: 21.9209339 },
    { lat: 48.4888106, lng: 21.9196031 },
    { lat: 48.4902013, lng: 21.9214809 },
    { lat: 48.4903047, lng: 21.9213938 },
    { lat: 48.4904992, lng: 21.9216057 },
    { lat: 48.4908426, lng: 21.9218376 },
    { lat: 48.4916557, lng: 21.9221761 },
    { lat: 48.493411, lng: 21.9229069 },
    { lat: 48.4952734, lng: 21.9234138 },
    { lat: 48.4954127, lng: 21.9214979 },
    { lat: 48.4955943, lng: 21.9208487 },
    { lat: 48.4958044, lng: 21.9203 },
    { lat: 48.4962696, lng: 21.9199651 },
    { lat: 48.4974405, lng: 21.9198684 },
    { lat: 48.4974945, lng: 21.9200462 },
    { lat: 48.497666, lng: 21.9200626 },
    { lat: 48.497825, lng: 21.919985 },
    { lat: 48.497912, lng: 21.919845 },
    { lat: 48.497972, lng: 21.919684 },
    { lat: 48.49807, lng: 21.919448 },
    { lat: 48.498055, lng: 21.91919 },
    { lat: 48.497908, lng: 21.91873 },
    { lat: 48.497786, lng: 21.918503 },
    { lat: 48.497624, lng: 21.918197 },
    { lat: 48.497382, lng: 21.917867 },
    { lat: 48.497315, lng: 21.917696 },
    { lat: 48.497256, lng: 21.917527 },
    { lat: 48.497254, lng: 21.91752 },
    { lat: 48.497219, lng: 21.917424 },
    { lat: 48.497224, lng: 21.917155 },
    { lat: 48.497222, lng: 21.917064 },
    { lat: 48.497256, lng: 21.916946 },
    { lat: 48.497342, lng: 21.916809 },
    { lat: 48.49748, lng: 21.916725 },
    { lat: 48.49768, lng: 21.916696 },
    { lat: 48.497814, lng: 21.916753 },
    { lat: 48.49798, lng: 21.916919 },
    { lat: 48.49819, lng: 21.917136 },
    { lat: 48.49833, lng: 21.917341 },
    { lat: 48.498627, lng: 21.917715 },
    { lat: 48.498664, lng: 21.917751 },
    { lat: 48.498911, lng: 21.917906 },
    { lat: 48.498991, lng: 21.917957 },
    { lat: 48.499193, lng: 21.918029 },
    { lat: 48.499261, lng: 21.918048 },
    { lat: 48.499483, lng: 21.917964 },
    { lat: 48.499692, lng: 21.917747 },
    { lat: 48.49975, lng: 21.917582 },
    { lat: 48.499772, lng: 21.917363 },
    { lat: 48.499796, lng: 21.917024 },
    { lat: 48.499804, lng: 21.916534 },
    { lat: 48.499819, lng: 21.916088 },
    { lat: 48.499835, lng: 21.915771 },
    { lat: 48.499834, lng: 21.915434 },
    { lat: 48.499851, lng: 21.914888 },
    { lat: 48.499856, lng: 21.914679 },
    { lat: 48.499893, lng: 21.91433 },
    { lat: 48.499996, lng: 21.91392 },
    { lat: 48.500079, lng: 21.913717 },
    { lat: 48.500234, lng: 21.913481 },
    { lat: 48.500436, lng: 21.913174 },
    { lat: 48.500754, lng: 21.912757 },
    { lat: 48.50101, lng: 21.912486 },
    { lat: 48.501448, lng: 21.912062 },
    { lat: 48.501737, lng: 21.911882 },
    { lat: 48.502184, lng: 21.911594 },
    { lat: 48.502744, lng: 21.911253 },
    { lat: 48.502816, lng: 21.911221 },
    { lat: 48.502873, lng: 21.911336 },
    { lat: 48.5031085, lng: 21.9107965 },
    { lat: 48.503183, lng: 21.912116 },
    { lat: 48.503197, lng: 21.912311 },
    { lat: 48.503166, lng: 21.912487 },
    { lat: 48.502953, lng: 21.912916 },
    { lat: 48.502757, lng: 21.913311 },
    { lat: 48.502565, lng: 21.913692 },
    { lat: 48.502389, lng: 21.914045 },
    { lat: 48.50221, lng: 21.914421 },
    { lat: 48.501966, lng: 21.914768 },
    { lat: 48.501921, lng: 21.914953 },
    { lat: 48.501827, lng: 21.915379 },
    { lat: 48.501823, lng: 21.915393 },
    { lat: 48.50178, lng: 21.915582 },
    { lat: 48.50173, lng: 21.915806 },
    { lat: 48.501727, lng: 21.916178 },
    { lat: 48.501723, lng: 21.916746 },
    { lat: 48.501723, lng: 21.916934 },
    { lat: 48.50176, lng: 21.917456 },
    { lat: 48.501806, lng: 21.918094 },
    { lat: 48.50194, lng: 21.918545 },
    { lat: 48.502133, lng: 21.918833 },
    { lat: 48.502258, lng: 21.91895 },
    { lat: 48.502313, lng: 21.918977 },
    { lat: 48.502397, lng: 21.918939 },
    { lat: 48.502503, lng: 21.918849 },
    { lat: 48.502684, lng: 21.918444 },
    { lat: 48.502898, lng: 21.91777 },
    { lat: 48.503133, lng: 21.916892 },
    { lat: 48.503451, lng: 21.915838 },
    { lat: 48.503648, lng: 21.915038 },
    { lat: 48.503731, lng: 21.914594 },
    { lat: 48.503822, lng: 21.913849 },
    { lat: 48.504132, lng: 21.912451 },
    { lat: 48.504435, lng: 21.911588 },
    { lat: 48.505167, lng: 21.90996 },
    { lat: 48.505368, lng: 21.909184 },
    { lat: 48.505492, lng: 21.908695 },
    { lat: 48.505381, lng: 21.908048 },
    { lat: 48.505291, lng: 21.90783 },
    { lat: 48.505117, lng: 21.907615 },
    { lat: 48.504946, lng: 21.907597 },
    { lat: 48.504833, lng: 21.907615 },
    { lat: 48.50477, lng: 21.907661 },
    { lat: 48.504549, lng: 21.907854 },
    { lat: 48.504269, lng: 21.908069 },
    { lat: 48.503964, lng: 21.908787 },
    { lat: 48.503788, lng: 21.90911 },
    { lat: 48.503666, lng: 21.90928 },
    { lat: 48.503592, lng: 21.909389 },
    { lat: 48.503543, lng: 21.90943 },
    { lat: 48.503457, lng: 21.909501 },
    { lat: 48.503297, lng: 21.909641 },
    { lat: 48.503216, lng: 21.909765 },
    { lat: 48.503116, lng: 21.909878 },
    { lat: 48.502936, lng: 21.909935 },
    { lat: 48.502896, lng: 21.909916 },
    { lat: 48.50263, lng: 21.909755 },
    { lat: 48.502467, lng: 21.909683 },
    { lat: 48.502298, lng: 21.909604 },
    { lat: 48.501962, lng: 21.909495 },
    { lat: 48.501234, lng: 21.908664 },
    { lat: 48.500769, lng: 21.90801 },
    { lat: 48.500541, lng: 21.907657 },
    { lat: 48.500388, lng: 21.907412 },
    { lat: 48.499871, lng: 21.906532 },
    { lat: 48.499505, lng: 21.905852 },
    { lat: 48.499223, lng: 21.90505 },
    { lat: 48.499178, lng: 21.904767 },
    { lat: 48.499113, lng: 21.904342 },
    { lat: 48.499154, lng: 21.904029 },
    { lat: 48.499216, lng: 21.903685 },
    { lat: 48.499393, lng: 21.903458 },
    { lat: 48.4996, lng: 21.903378 },
    { lat: 48.499724, lng: 21.903354 },
    { lat: 48.500073, lng: 21.903503 },
    { lat: 48.500513, lng: 21.903653 },
    { lat: 48.500932, lng: 21.903829 },
    { lat: 48.501259, lng: 21.904029 },
    { lat: 48.501716, lng: 21.904442 },
    { lat: 48.502403, lng: 21.905139 },
    { lat: 48.502934, lng: 21.905623 },
    { lat: 48.503117, lng: 21.90578 },
    { lat: 48.503202, lng: 21.905813 },
    { lat: 48.50325, lng: 21.905827 },
    { lat: 48.503587, lng: 21.905914 },
    { lat: 48.50368, lng: 21.905955 },
    { lat: 48.503737, lng: 21.905976 },
    { lat: 48.504141, lng: 21.905974 },
    { lat: 48.504466, lng: 21.905897 },
    { lat: 48.504775, lng: 21.905721 },
    { lat: 48.505003, lng: 21.905561 },
    { lat: 48.505254, lng: 21.905384 },
    { lat: 48.50564, lng: 21.904803 },
    { lat: 48.505814, lng: 21.904547 },
    { lat: 48.506061, lng: 21.904048 },
    { lat: 48.506355, lng: 21.903434 },
    { lat: 48.506544, lng: 21.903042 },
    { lat: 48.506879, lng: 21.902699 },
    { lat: 48.507183, lng: 21.902465 },
    { lat: 48.508049, lng: 21.902378 },
    { lat: 48.508839, lng: 21.902359 },
    { lat: 48.508949, lng: 21.902304 },
    { lat: 48.509251, lng: 21.902136 },
    { lat: 48.509312, lng: 21.901744 },
    { lat: 48.509409, lng: 21.901182 },
    { lat: 48.509439, lng: 21.900877 },
    { lat: 48.509444, lng: 21.900505 },
    { lat: 48.509356, lng: 21.899962 },
    { lat: 48.509303, lng: 21.899745 },
    { lat: 48.509255, lng: 21.899624 },
    { lat: 48.508973, lng: 21.899231 },
    { lat: 48.50886, lng: 21.899146 },
    { lat: 48.508707, lng: 21.899135 },
    { lat: 48.508361, lng: 21.899279 },
    { lat: 48.507945, lng: 21.899715 },
    { lat: 48.507696, lng: 21.899929 },
    { lat: 48.507313, lng: 21.900064 },
    { lat: 48.506777, lng: 21.900248 },
    { lat: 48.506287, lng: 21.900362 },
    { lat: 48.506131, lng: 21.900381 },
    { lat: 48.505662, lng: 21.900429 },
    { lat: 48.50543, lng: 21.900385 },
    { lat: 48.505278, lng: 21.900388 },
    { lat: 48.505107, lng: 21.900373 },
    { lat: 48.504909, lng: 21.900255 },
    { lat: 48.504757, lng: 21.900147 },
    { lat: 48.50453, lng: 21.899932 },
    { lat: 48.504436, lng: 21.899823 },
    { lat: 48.504296, lng: 21.899656 },
    { lat: 48.504126, lng: 21.899293 },
    { lat: 48.504032, lng: 21.899012 },
    { lat: 48.503996, lng: 21.898606 },
    { lat: 48.504007, lng: 21.898305 },
    { lat: 48.504062, lng: 21.897997 },
    { lat: 48.504127, lng: 21.897741 },
    { lat: 48.504209, lng: 21.897478 },
    { lat: 48.50429, lng: 21.897214 },
    { lat: 48.50462, lng: 21.89638 },
    { lat: 48.504785, lng: 21.895777 },
    { lat: 48.504862, lng: 21.895463 },
    { lat: 48.504909, lng: 21.895105 },
    { lat: 48.504917, lng: 21.894818 },
    { lat: 48.5049, lng: 21.894576 },
    { lat: 48.504853, lng: 21.894435 },
    { lat: 48.504781, lng: 21.894269 },
    { lat: 48.50473, lng: 21.894214 },
    { lat: 48.504599, lng: 21.894134 },
    { lat: 48.504447, lng: 21.89404 },
    { lat: 48.504267, lng: 21.894087 },
    { lat: 48.504142, lng: 21.894115 },
    { lat: 48.50401, lng: 21.894215 },
    { lat: 48.503843, lng: 21.894334 },
    { lat: 48.503616, lng: 21.894494 },
    { lat: 48.503439, lng: 21.894587 },
    { lat: 48.503237, lng: 21.894681 },
    { lat: 48.503035, lng: 21.89476 },
    { lat: 48.502774, lng: 21.894893 },
    { lat: 48.502644, lng: 21.894921 },
    { lat: 48.502331, lng: 21.894897 },
    { lat: 48.502216, lng: 21.894877 },
    { lat: 48.502118, lng: 21.894768 },
    { lat: 48.502027, lng: 21.894634 },
    { lat: 48.501978, lng: 21.894479 },
    { lat: 48.50198, lng: 21.894368 },
    { lat: 48.501995, lng: 21.894254 },
    { lat: 48.502029, lng: 21.894157 },
    { lat: 48.502115, lng: 21.894027 },
    { lat: 48.502243, lng: 21.893856 },
    { lat: 48.502408, lng: 21.893635 },
    { lat: 48.502544, lng: 21.893473 },
    { lat: 48.502676, lng: 21.893259 },
    { lat: 48.502798, lng: 21.892978 },
    { lat: 48.502947, lng: 21.89276 },
    { lat: 48.503136, lng: 21.892363 },
    { lat: 48.503196, lng: 21.892136 },
    { lat: 48.503197, lng: 21.892133 },
    { lat: 48.503245, lng: 21.89195 },
    { lat: 48.50333, lng: 21.891626 },
    { lat: 48.503354, lng: 21.891399 },
    { lat: 48.503381, lng: 21.891119 },
    { lat: 48.503376, lng: 21.890975 },
    { lat: 48.503366, lng: 21.890743 },
    { lat: 48.503357, lng: 21.890568 },
    { lat: 48.50331, lng: 21.890347 },
    { lat: 48.503264, lng: 21.890122 },
    { lat: 48.503226, lng: 21.889969 },
    { lat: 48.503142, lng: 21.889752 },
    { lat: 48.502966, lng: 21.88926 },
    { lat: 48.502812, lng: 21.888993 },
    { lat: 48.502559, lng: 21.88859 },
    { lat: 48.502429, lng: 21.888452 },
    { lat: 48.502344, lng: 21.888388 },
    { lat: 48.502193, lng: 21.888314 },
    { lat: 48.502093, lng: 21.888257 },
    { lat: 48.501839, lng: 21.888216 },
    { lat: 48.501689, lng: 21.888297 },
    { lat: 48.501608, lng: 21.888356 },
    { lat: 48.501356, lng: 21.888651 },
    { lat: 48.50128, lng: 21.888875 },
    { lat: 48.5012, lng: 21.889598 },
    { lat: 48.501127, lng: 21.889979 },
    { lat: 48.501023, lng: 21.890355 },
    { lat: 48.500929, lng: 21.890712 },
    { lat: 48.500832, lng: 21.890938 },
    { lat: 48.50068, lng: 21.891265 },
    { lat: 48.500314, lng: 21.891525 },
    { lat: 48.500139, lng: 21.891639 },
    { lat: 48.500023, lng: 21.891677 },
    { lat: 48.49977, lng: 21.891705 },
    { lat: 48.499585, lng: 21.891615 },
    { lat: 48.499469, lng: 21.89155 },
    { lat: 48.499367, lng: 21.8914 },
    { lat: 48.499193, lng: 21.890972 },
    { lat: 48.499083, lng: 21.89046 },
    { lat: 48.499069, lng: 21.890291 },
    { lat: 48.49911, lng: 21.889753 },
    { lat: 48.49912, lng: 21.889597 },
    { lat: 48.499243, lng: 21.889102 },
    { lat: 48.49927, lng: 21.888989 },
    { lat: 48.499403, lng: 21.888459 },
    { lat: 48.499477, lng: 21.88815 },
    { lat: 48.499526, lng: 21.887947 },
    { lat: 48.499629, lng: 21.887534 },
    { lat: 48.49967, lng: 21.887201 },
    { lat: 48.499705, lng: 21.886854 },
    { lat: 48.499694, lng: 21.886622 },
    { lat: 48.499655, lng: 21.885622 },
    { lat: 48.49963, lng: 21.88532 },
    { lat: 48.499536, lng: 21.884565 },
    { lat: 48.499481, lng: 21.884224 },
    { lat: 48.499422, lng: 21.883911 },
    { lat: 48.499418, lng: 21.883889 },
    { lat: 48.499308, lng: 21.883257 },
    { lat: 48.49922, lng: 21.882658 },
    { lat: 48.499266, lng: 21.882468 },
    { lat: 48.499391, lng: 21.882278 },
    { lat: 48.499647, lng: 21.882026 },
    { lat: 48.499961, lng: 21.881986 },
    { lat: 48.500207, lng: 21.882141 },
    { lat: 48.500388, lng: 21.882384 },
    { lat: 48.500616, lng: 21.882754 },
    { lat: 48.500881, lng: 21.883196 },
    { lat: 48.501015, lng: 21.883391 },
    { lat: 48.501207, lng: 21.883589 },
    { lat: 48.501416, lng: 21.883603 },
    { lat: 48.501584, lng: 21.883584 },
    { lat: 48.501869, lng: 21.883481 },
    { lat: 48.502099, lng: 21.883157 },
    { lat: 48.502196, lng: 21.883019 },
    { lat: 48.502241, lng: 21.882852 },
    { lat: 48.502257, lng: 21.882609 },
    { lat: 48.502239, lng: 21.882406 },
    { lat: 48.502153, lng: 21.8821 },
    { lat: 48.502082, lng: 21.881994 },
    { lat: 48.501994, lng: 21.881895 },
    { lat: 48.501912, lng: 21.881805 },
    { lat: 48.501759, lng: 21.881643 },
    { lat: 48.501658, lng: 21.881591 },
    { lat: 48.501497, lng: 21.881515 },
    { lat: 48.501483, lng: 21.881508 },
    { lat: 48.501472, lng: 21.881502 },
    { lat: 48.501259, lng: 21.881394 },
    { lat: 48.501139, lng: 21.88134 },
    { lat: 48.500946, lng: 21.881224 },
    { lat: 48.500681, lng: 21.881065 },
    { lat: 48.500492, lng: 21.880958 },
    { lat: 48.500291, lng: 21.880745 },
    { lat: 48.500229, lng: 21.880664 },
    { lat: 48.500225, lng: 21.88066 },
    { lat: 48.500215, lng: 21.880649 },
    { lat: 48.500076, lng: 21.880502 },
    { lat: 48.499918, lng: 21.880321 },
    { lat: 48.499805, lng: 21.88019 },
    { lat: 48.499643, lng: 21.879929 },
    { lat: 48.499473, lng: 21.879645 },
    { lat: 48.499379, lng: 21.879392 },
    { lat: 48.499301, lng: 21.879148 },
    { lat: 48.499254, lng: 21.878932 },
    { lat: 48.499177, lng: 21.878548 },
    { lat: 48.499165, lng: 21.878432 },
    { lat: 48.499153, lng: 21.878277 },
    { lat: 48.499159, lng: 21.878001 },
    { lat: 48.499163, lng: 21.877861 },
    { lat: 48.499194, lng: 21.877573 },
    { lat: 48.499216, lng: 21.877453 },
    { lat: 48.499266, lng: 21.877249 },
    { lat: 48.499336, lng: 21.877048 },
    { lat: 48.499441, lng: 21.876861 },
    { lat: 48.499559, lng: 21.876693 },
    { lat: 48.499613, lng: 21.876636 },
    { lat: 48.499727, lng: 21.876533 },
    { lat: 48.499828, lng: 21.87646 },
    { lat: 48.499909, lng: 21.876433 },
    { lat: 48.500009, lng: 21.876393 },
    { lat: 48.50016, lng: 21.876391 },
    { lat: 48.500269, lng: 21.876387 },
    { lat: 48.500412, lng: 21.876413 },
    { lat: 48.500488, lng: 21.876427 },
    { lat: 48.500638, lng: 21.876484 },
    { lat: 48.500729, lng: 21.876515 },
    { lat: 48.500857, lng: 21.876605 },
    { lat: 48.500972, lng: 21.876674 },
    { lat: 48.500977, lng: 21.876677 },
    { lat: 48.500985, lng: 21.876682 },
    { lat: 48.501116, lng: 21.87677 },
    { lat: 48.501245, lng: 21.87685 },
    { lat: 48.501402, lng: 21.876974 },
    { lat: 48.50154, lng: 21.877076 },
    { lat: 48.501704, lng: 21.877202 },
    { lat: 48.501857, lng: 21.877311 },
    { lat: 48.501991, lng: 21.877403 },
    { lat: 48.502115, lng: 21.877482 },
    { lat: 48.502116, lng: 21.877483 },
    { lat: 48.502291, lng: 21.877602 },
    { lat: 48.502466, lng: 21.877722 },
    { lat: 48.502554, lng: 21.877748 },
    { lat: 48.50259, lng: 21.877775 },
    { lat: 48.502614, lng: 21.877791 },
    { lat: 48.502692, lng: 21.877817 },
    { lat: 48.502714, lng: 21.877825 },
    { lat: 48.502871, lng: 21.877893 },
    { lat: 48.503008, lng: 21.877887 },
    { lat: 48.503176, lng: 21.877894 },
    { lat: 48.503486, lng: 21.877772 },
    { lat: 48.503611, lng: 21.877647 },
    { lat: 48.503683, lng: 21.877506 },
    { lat: 48.503751, lng: 21.877336 },
    { lat: 48.503765, lng: 21.877183 },
    { lat: 48.503722, lng: 21.876972 },
    { lat: 48.503684, lng: 21.876807 },
    { lat: 48.503587, lng: 21.876669 },
    { lat: 48.503372, lng: 21.876421 },
    { lat: 48.503142, lng: 21.87617 },
    { lat: 48.503001, lng: 21.876057 },
    { lat: 48.502828, lng: 21.875904 },
    { lat: 48.502684, lng: 21.875776 },
    { lat: 48.502494, lng: 21.875555 },
    { lat: 48.502311, lng: 21.87534 },
    { lat: 48.502205, lng: 21.875209 },
    { lat: 48.502161, lng: 21.875155 },
    { lat: 48.502, lng: 21.874964 },
    { lat: 48.501872, lng: 21.874813 },
    { lat: 48.501834, lng: 21.87477 },
    { lat: 48.501717, lng: 21.87464 },
    { lat: 48.501575, lng: 21.874465 },
    { lat: 48.501407, lng: 21.874223 },
    { lat: 48.501269, lng: 21.874026 },
    { lat: 48.501137, lng: 21.873842 },
    { lat: 48.500993, lng: 21.873616 },
    { lat: 48.50083, lng: 21.873354 },
    { lat: 48.500643, lng: 21.873057 },
    { lat: 48.500434, lng: 21.872689 },
    { lat: 48.500309, lng: 21.872436 },
    { lat: 48.499877, lng: 21.871611 },
    { lat: 48.499564, lng: 21.871322 },
    { lat: 48.499443, lng: 21.871259 },
    { lat: 48.499306, lng: 21.871258 },
    { lat: 48.499155, lng: 21.871433 },
    { lat: 48.49905, lng: 21.871655 },
    { lat: 48.498912, lng: 21.872392 },
    { lat: 48.498822, lng: 21.873185 },
    { lat: 48.498565, lng: 21.874246 },
    { lat: 48.498287, lng: 21.875161 },
    { lat: 48.498121, lng: 21.875372 },
    { lat: 48.497798, lng: 21.875646 },
    { lat: 48.497557, lng: 21.875745 },
    { lat: 48.497441, lng: 21.875748 },
    { lat: 48.497298, lng: 21.875747 },
    { lat: 48.497011, lng: 21.875599 },
    { lat: 48.496776, lng: 21.875157 },
    { lat: 48.496435, lng: 21.874123 },
    { lat: 48.496031, lng: 21.873207 },
    { lat: 48.495869, lng: 21.872917 },
    { lat: 48.49556, lng: 21.872354 },
    { lat: 48.495212, lng: 21.871814 },
    { lat: 48.4950851, lng: 21.8716824 },
    { lat: 48.494696, lng: 21.871453 },
    { lat: 48.494277, lng: 21.871215 },
    { lat: 48.494044, lng: 21.871096 },
    { lat: 48.493667, lng: 21.870951 },
    { lat: 48.493328, lng: 21.870873 },
    { lat: 48.493182, lng: 21.870832 },
    { lat: 48.492761, lng: 21.870695 },
    { lat: 48.492655, lng: 21.870661 },
    { lat: 48.492267, lng: 21.870513 },
    { lat: 48.492008, lng: 21.870274 },
    { lat: 48.491908, lng: 21.870025 },
    { lat: 48.4919, lng: 21.86997 },
    { lat: 48.491892, lng: 21.869887 },
    { lat: 48.491847, lng: 21.869674 },
    { lat: 48.491836, lng: 21.869611 },
    { lat: 48.491856, lng: 21.869509 },
    { lat: 48.491905, lng: 21.869277 },
    { lat: 48.49193, lng: 21.869217 },
    { lat: 48.492173, lng: 21.86872 },
    { lat: 48.492287, lng: 21.867657 },
    { lat: 48.492252, lng: 21.867131 },
    { lat: 48.492248, lng: 21.866211 },
    { lat: 48.492323, lng: 21.865522 },
    { lat: 48.492484, lng: 21.864957 },
    { lat: 48.492731, lng: 21.864126 },
    { lat: 48.49273, lng: 21.863788 },
    { lat: 48.49273, lng: 21.863357 },
    { lat: 48.492613, lng: 21.862672 },
    { lat: 48.492319, lng: 21.862157 },
    { lat: 48.491791, lng: 21.861823 },
    { lat: 48.491412, lng: 21.861826 },
    { lat: 48.490895, lng: 21.862074 },
    { lat: 48.490458, lng: 21.862398 },
    { lat: 48.490241, lng: 21.862716 },
    { lat: 48.490034, lng: 21.86318 },
    { lat: 48.489861, lng: 21.863927 },
    { lat: 48.48991, lng: 21.864313 },
    { lat: 48.489962, lng: 21.864796 },
    { lat: 48.490092, lng: 21.86568 },
    { lat: 48.49005, lng: 21.866376 },
    { lat: 48.490011, lng: 21.866569 },
    { lat: 48.489928, lng: 21.866733 },
    { lat: 48.489841, lng: 21.86686 },
    { lat: 48.489765, lng: 21.866959 },
    { lat: 48.489734, lng: 21.866993 },
    { lat: 48.489685, lng: 21.867092 },
    { lat: 48.489662, lng: 21.867111 },
    { lat: 48.489539, lng: 21.867178 },
    { lat: 48.48942, lng: 21.867239 },
    { lat: 48.489284, lng: 21.867295 },
    { lat: 48.48917, lng: 21.867339 },
    { lat: 48.489027, lng: 21.86733 },
    { lat: 48.488855, lng: 21.867249 },
    { lat: 48.488765, lng: 21.867183 },
    { lat: 48.488664, lng: 21.867105 },
    { lat: 48.488632, lng: 21.867073 },
    { lat: 48.488528, lng: 21.866891 },
    { lat: 48.488511, lng: 21.866739 },
    { lat: 48.488526, lng: 21.866134 },
    { lat: 48.488598, lng: 21.86586 },
    { lat: 48.48861, lng: 21.865796 },
    { lat: 48.488684, lng: 21.865204 },
    { lat: 48.48872, lng: 21.864735 },
    { lat: 48.488715, lng: 21.864327 },
    { lat: 48.488705, lng: 21.864086 },
    { lat: 48.488678, lng: 21.863778 },
    { lat: 48.488631, lng: 21.863282 },
    { lat: 48.488564, lng: 21.862852 },
    { lat: 48.488548, lng: 21.862771 },
    { lat: 48.4885, lng: 21.86259 },
    { lat: 48.488352, lng: 21.86202 },
    { lat: 48.4882, lng: 21.861492 },
    { lat: 48.488095, lng: 21.861145 },
    { lat: 48.488084, lng: 21.861089 },
    { lat: 48.487882, lng: 21.860005 },
    { lat: 48.487869, lng: 21.859741 },
    { lat: 48.487854, lng: 21.85946 },
    { lat: 48.487913, lng: 21.85884 },
    { lat: 48.487954, lng: 21.858464 },
    { lat: 48.488071, lng: 21.857702 },
    { lat: 48.488064, lng: 21.857291 },
    { lat: 48.487943, lng: 21.856808 },
    { lat: 48.487812, lng: 21.856552 },
    { lat: 48.487803, lng: 21.856533 },
    { lat: 48.487772, lng: 21.856469 },
    { lat: 48.487662, lng: 21.856425 },
    { lat: 48.487576, lng: 21.85639 },
    { lat: 48.487531, lng: 21.856377 },
    { lat: 48.487122, lng: 21.85632 },
    { lat: 48.487074, lng: 21.85632 },
    { lat: 48.486748, lng: 21.856576 },
    { lat: 48.486488, lng: 21.85668 },
    { lat: 48.485947, lng: 21.856896 },
    { lat: 48.485762, lng: 21.856825 },
    { lat: 48.485491, lng: 21.856716 },
    { lat: 48.485425, lng: 21.85667 },
    { lat: 48.485262, lng: 21.856557 },
    { lat: 48.485206, lng: 21.856512 },
    { lat: 48.48508, lng: 21.856387 },
    { lat: 48.484968, lng: 21.85625 },
    { lat: 48.484867, lng: 21.856128 },
    { lat: 48.484829, lng: 21.856064 },
    { lat: 48.484796, lng: 21.855994 },
    { lat: 48.484762, lng: 21.855902 },
    { lat: 48.484683, lng: 21.855657 },
    { lat: 48.484637, lng: 21.855516 },
    { lat: 48.484622, lng: 21.85545 },
    { lat: 48.484597, lng: 21.855209 },
    { lat: 48.484598, lng: 21.855164 },
    { lat: 48.484601, lng: 21.855061 },
    { lat: 48.484616, lng: 21.854827 },
    { lat: 48.484648, lng: 21.854528 },
    { lat: 48.484669, lng: 21.854464 },
    { lat: 48.484739, lng: 21.854286 },
    { lat: 48.484764, lng: 21.854232 },
    { lat: 48.484834, lng: 21.854131 },
    { lat: 48.484902, lng: 21.854062 },
    { lat: 48.485063, lng: 21.853907 },
    { lat: 48.485188, lng: 21.853844 },
    { lat: 48.485229, lng: 21.853826 },
    { lat: 48.485288, lng: 21.853832 },
    { lat: 48.4854, lng: 21.853852 },
    { lat: 48.485522, lng: 21.853896 },
    { lat: 48.485988, lng: 21.854294 },
    { lat: 48.48621, lng: 21.854468 },
    { lat: 48.486248, lng: 21.854485 },
    { lat: 48.486638, lng: 21.854623 },
    { lat: 48.486673, lng: 21.854633 },
    { lat: 48.486897, lng: 21.854611 },
    { lat: 48.487025, lng: 21.854599 },
    { lat: 48.487147, lng: 21.854591 },
    { lat: 48.487222, lng: 21.854588 },
    { lat: 48.487372, lng: 21.854489 },
    { lat: 48.487527, lng: 21.854383 },
    { lat: 48.487815, lng: 21.854185 },
    { lat: 48.487923, lng: 21.854071 },
    { lat: 48.488009, lng: 21.853981 },
    { lat: 48.488113, lng: 21.853869 },
    { lat: 48.488191, lng: 21.853768 },
    { lat: 48.488218, lng: 21.853733 },
    { lat: 48.488306, lng: 21.853598 },
    { lat: 48.488386, lng: 21.853422 },
    { lat: 48.488573, lng: 21.852988 },
    { lat: 48.488606, lng: 21.852839 },
    { lat: 48.488655, lng: 21.852632 },
    { lat: 48.48867, lng: 21.852545 },
    { lat: 48.488686, lng: 21.85234 },
    { lat: 48.488714, lng: 21.851949 },
    { lat: 48.488684, lng: 21.851696 },
    { lat: 48.488662, lng: 21.851527 },
    { lat: 48.488621, lng: 21.851392 },
    { lat: 48.488481, lng: 21.850938 },
    { lat: 48.488394, lng: 21.85075 },
    { lat: 48.488289, lng: 21.850534 },
    { lat: 48.488143, lng: 21.850357 },
    { lat: 48.487964, lng: 21.850159 },
    { lat: 48.487682, lng: 21.849897 },
    { lat: 48.487659, lng: 21.84988 },
    { lat: 48.487537, lng: 21.849833 },
    { lat: 48.487303, lng: 21.849744 },
    { lat: 48.487229, lng: 21.84974 },
    { lat: 48.48709, lng: 21.849742 },
    { lat: 48.486947, lng: 21.849781 },
    { lat: 48.486807, lng: 21.849815 },
    { lat: 48.48672, lng: 21.849845 },
    { lat: 48.48648, lng: 21.849931 },
    { lat: 48.48643, lng: 21.849946 },
    { lat: 48.486198, lng: 21.850052 },
    { lat: 48.486139, lng: 21.850064 },
    { lat: 48.486044, lng: 21.850078 },
    { lat: 48.485719, lng: 21.850117 },
    { lat: 48.485579, lng: 21.850104 },
    { lat: 48.485274, lng: 21.849918 },
    { lat: 48.485154, lng: 21.849816 },
    { lat: 48.48497, lng: 21.849652 },
    { lat: 48.484935, lng: 21.849603 },
    { lat: 48.484862, lng: 21.849501 },
    { lat: 48.484833, lng: 21.849442 },
    { lat: 48.484729, lng: 21.849223 },
    { lat: 48.484686, lng: 21.849075 },
    { lat: 48.484631, lng: 21.848882 },
    { lat: 48.484624, lng: 21.848844 },
    { lat: 48.484605, lng: 21.84875 },
    { lat: 48.484597, lng: 21.848687 },
    { lat: 48.484571, lng: 21.848485 },
    { lat: 48.484531, lng: 21.848162 },
    { lat: 48.484531, lng: 21.848125 },
    { lat: 48.484562, lng: 21.847864 },
    { lat: 48.48459, lng: 21.847702 },
    { lat: 48.484662, lng: 21.847326 },
    { lat: 48.484728, lng: 21.846975 },
    { lat: 48.48486, lng: 21.846482 },
    { lat: 48.484957, lng: 21.846204 },
    { lat: 48.485105, lng: 21.845792 },
    { lat: 48.485276, lng: 21.845467 },
    { lat: 48.485352, lng: 21.845328 },
    { lat: 48.485502, lng: 21.845099 },
    { lat: 48.485609, lng: 21.844928 },
    { lat: 48.485743, lng: 21.844645 },
    { lat: 48.485854, lng: 21.844399 },
    { lat: 48.485907, lng: 21.844284 },
    { lat: 48.485945, lng: 21.843882 },
    { lat: 48.485941, lng: 21.843757 },
    { lat: 48.485907, lng: 21.843569 },
    { lat: 48.485804, lng: 21.843425 },
    { lat: 48.485628, lng: 21.843355 },
    { lat: 48.485586, lng: 21.843358 },
    { lat: 48.485321, lng: 21.843434 },
    { lat: 48.485282, lng: 21.843465 },
    { lat: 48.485071, lng: 21.843689 },
    { lat: 48.484957, lng: 21.84382 },
    { lat: 48.484853, lng: 21.843975 },
    { lat: 48.484671, lng: 21.84425 },
    { lat: 48.484591, lng: 21.844398 },
    { lat: 48.484509, lng: 21.844556 },
    { lat: 48.484342, lng: 21.844804 },
    { lat: 48.484115, lng: 21.845149 },
    { lat: 48.483905, lng: 21.845468 },
    { lat: 48.483664, lng: 21.845801 },
    { lat: 48.48343, lng: 21.846093 },
    { lat: 48.483341, lng: 21.846173 },
    { lat: 48.482945, lng: 21.846533 },
    { lat: 48.482704, lng: 21.846615 },
    { lat: 48.482574, lng: 21.846647 },
    { lat: 48.482209, lng: 21.846649 },
    { lat: 48.4819, lng: 21.846485 },
    { lat: 48.481564, lng: 21.846276 },
    { lat: 48.481427, lng: 21.84611 },
    { lat: 48.481215, lng: 21.845861 },
    { lat: 48.48105, lng: 21.845593 },
    { lat: 48.480937, lng: 21.845372 },
    { lat: 48.480799, lng: 21.845084 },
    { lat: 48.480709, lng: 21.844808 },
    { lat: 48.480679, lng: 21.844737 },
    { lat: 48.480388, lng: 21.844057 },
    { lat: 48.480116, lng: 21.843734 },
    { lat: 48.480075, lng: 21.843704 },
    { lat: 48.479831, lng: 21.843539 },
    { lat: 48.479801, lng: 21.843518 },
    { lat: 48.47972, lng: 21.843477 },
    { lat: 48.479585, lng: 21.843436 },
    { lat: 48.479462, lng: 21.843394 },
    { lat: 48.479326, lng: 21.843377 },
    { lat: 48.47922, lng: 21.843378 },
    { lat: 48.478953, lng: 21.843414 },
    { lat: 48.47842, lng: 21.8434 },
    { lat: 48.477977, lng: 21.843368 },
    { lat: 48.477625, lng: 21.843353 },
    { lat: 48.477505, lng: 21.843353 },
    { lat: 48.477355, lng: 21.843366 },
    { lat: 48.477202, lng: 21.843387 },
    { lat: 48.477127, lng: 21.843398 },
    { lat: 48.477066, lng: 21.843406 },
    { lat: 48.477014, lng: 21.843408 },
    { lat: 48.476887, lng: 21.843418 },
    { lat: 48.47666, lng: 21.843424 },
    { lat: 48.476397, lng: 21.843367 },
    { lat: 48.476128, lng: 21.843312 },
    { lat: 48.476086, lng: 21.843303 },
    { lat: 48.476075, lng: 21.843301 },
    { lat: 48.47603, lng: 21.843288 },
    { lat: 48.475892, lng: 21.843239 },
    { lat: 48.475534, lng: 21.842989 },
    { lat: 48.475648, lng: 21.842634 },
    { lat: 48.475735, lng: 21.842355 },
    { lat: 48.476206, lng: 21.841417 },
    { lat: 48.476373, lng: 21.84036 },
    { lat: 48.476304, lng: 21.839662 },
    { lat: 48.476215, lng: 21.838663 },
    { lat: 48.476122, lng: 21.838467 },
    { lat: 48.47597, lng: 21.838158 },
    { lat: 48.475735, lng: 21.837681 },
    { lat: 48.475644, lng: 21.8376 },
    { lat: 48.475508, lng: 21.837481 },
    { lat: 48.47545, lng: 21.837442 },
    { lat: 48.475334, lng: 21.8374 },
    { lat: 48.475204, lng: 21.837344 },
    { lat: 48.475141, lng: 21.837319 },
    { lat: 48.475065, lng: 21.837292 },
    { lat: 48.475008, lng: 21.837304 },
    { lat: 48.474875, lng: 21.83732 },
    { lat: 48.47474, lng: 21.837341 },
    { lat: 48.474604, lng: 21.837359 },
    { lat: 48.474595, lng: 21.837364 },
    { lat: 48.474553, lng: 21.837396 },
    { lat: 48.474477, lng: 21.837448 },
    { lat: 48.474301, lng: 21.837587 },
    { lat: 48.474235, lng: 21.837674 },
    { lat: 48.474138, lng: 21.837823 },
    { lat: 48.474099, lng: 21.83788 },
    { lat: 48.474004, lng: 21.838026 },
    { lat: 48.473917, lng: 21.838158 },
    { lat: 48.473847, lng: 21.838268 },
    { lat: 48.473722, lng: 21.838457 },
    { lat: 48.473627, lng: 21.838598 },
    { lat: 48.473584, lng: 21.838673 },
    { lat: 48.473553, lng: 21.838733 },
    { lat: 48.473523, lng: 21.838786 },
    { lat: 48.473488, lng: 21.838848 },
    { lat: 48.47341, lng: 21.838926 },
    { lat: 48.473367, lng: 21.838968 },
    { lat: 48.473272, lng: 21.839061 },
    { lat: 48.47319, lng: 21.839143 },
    { lat: 48.473144, lng: 21.839188 },
    { lat: 48.472933, lng: 21.839399 },
    { lat: 48.47285, lng: 21.839403 },
    { lat: 48.472798, lng: 21.83941 },
    { lat: 48.472448, lng: 21.839432 },
    { lat: 48.472135, lng: 21.839404 },
    { lat: 48.471852, lng: 21.839405 },
    { lat: 48.471712, lng: 21.839411 },
    { lat: 48.471657, lng: 21.839417 },
    { lat: 48.471599, lng: 21.839433 },
    { lat: 48.47141, lng: 21.839515 },
    { lat: 48.471201, lng: 21.839589 },
    { lat: 48.471084, lng: 21.839639 },
    { lat: 48.471062, lng: 21.839655 },
    { lat: 48.470816, lng: 21.839963 },
    { lat: 48.470716, lng: 21.840097 },
    { lat: 48.470512, lng: 21.840366 },
    { lat: 48.470394, lng: 21.840515 },
    { lat: 48.470212, lng: 21.840745 },
    { lat: 48.470138, lng: 21.840848 },
    { lat: 48.470095, lng: 21.840892 },
    { lat: 48.470021, lng: 21.84094 },
    { lat: 48.469787, lng: 21.841055 },
    { lat: 48.469564, lng: 21.841128 },
    { lat: 48.469545, lng: 21.841134 },
    { lat: 48.4696259, lng: 21.8416083 },
    { lat: 48.4688687, lng: 21.8435208 },
    { lat: 48.4658667, lng: 21.8504532 },
    { lat: 48.4655576, lng: 21.8513725 },
    { lat: 48.4653696, lng: 21.8522424 },
    { lat: 48.4652883, lng: 21.8526198 },
    { lat: 48.465627, lng: 21.8527974 },
    { lat: 48.4658846, lng: 21.8530153 },
    { lat: 48.466202, lng: 21.8534529 },
    { lat: 48.4666321, lng: 21.8544686 },
    { lat: 48.4668792, lng: 21.8554447 },
    { lat: 48.4669912, lng: 21.8564304 },
    { lat: 48.4670822, lng: 21.8574266 },
    { lat: 48.4671198, lng: 21.8584883 },
    { lat: 48.4670622, lng: 21.859791 },
    { lat: 48.4668122, lng: 21.8631809 },
    { lat: 48.4666649, lng: 21.8637581 },
    { lat: 48.4665144, lng: 21.8640264 },
    { lat: 48.4663064, lng: 21.8642104 },
    { lat: 48.4659044, lng: 21.8642814 },
    { lat: 48.4653857, lng: 21.8639298 },
    { lat: 48.4649298, lng: 21.8631297 },
    { lat: 48.4646817, lng: 21.8624103 },
    { lat: 48.464393, lng: 21.8613392 },
    { lat: 48.4641702, lng: 21.8607255 },
    { lat: 48.4637183, lng: 21.8597858 },
    { lat: 48.4633065, lng: 21.8592576 },
    { lat: 48.4631505, lng: 21.8591423 },
    { lat: 48.4628378, lng: 21.8590102 },
    { lat: 48.4624815, lng: 21.8591189 },
    { lat: 48.4620941, lng: 21.8595368 },
    { lat: 48.4619455, lng: 21.8599269 },
    { lat: 48.4617407, lng: 21.8607048 },
    { lat: 48.4613192, lng: 21.8628176 },
    { lat: 48.4607763, lng: 21.8642377 },
    { lat: 48.4604342, lng: 21.864967 },
    { lat: 48.4600426, lng: 21.8654889 },
    { lat: 48.4591921, lng: 21.8663707 },
    { lat: 48.4565741, lng: 21.8682077 },
    { lat: 48.455162, lng: 21.8695105 },
    { lat: 48.4548842, lng: 21.8699921 },
    { lat: 48.4546894, lng: 21.8705109 },
    { lat: 48.4542728, lng: 21.8719401 },
    { lat: 48.4542358, lng: 21.872078 },
    { lat: 48.4541737, lng: 21.8728563 },
    { lat: 48.4542027, lng: 21.8733314 },
    { lat: 48.4543664, lng: 21.8741445 },
    { lat: 48.45507, lng: 21.876177 },
    { lat: 48.4552475, lng: 21.8765612 },
    { lat: 48.4556555, lng: 21.8772474 },
    { lat: 48.4560757, lng: 21.8777905 },
    { lat: 48.4569205, lng: 21.8785759 },
    { lat: 48.4572592, lng: 21.8785371 },
    { lat: 48.4579472, lng: 21.8782906 },
    { lat: 48.458563, lng: 21.8779135 },
    { lat: 48.4594694, lng: 21.8771577 },
    { lat: 48.4613926, lng: 21.8751972 },
    { lat: 48.461829, lng: 21.8748415 },
    { lat: 48.4628814, lng: 21.8741601 },
    { lat: 48.4637453, lng: 21.8738883 },
    { lat: 48.4640699, lng: 21.8738695 },
    { lat: 48.4643615, lng: 21.8739688 },
    { lat: 48.4646552, lng: 21.8741435 },
    { lat: 48.4649698, lng: 21.8745691 },
    { lat: 48.4651715, lng: 21.8751065 },
    { lat: 48.4653175, lng: 21.8760179 },
    { lat: 48.4653978, lng: 21.8769052 },
    { lat: 48.465382, lng: 21.8778707 },
    { lat: 48.4651306, lng: 21.8818496 },
    { lat: 48.4651744, lng: 21.8841283 },
    { lat: 48.4652495, lng: 21.8855249 },
    { lat: 48.4653744, lng: 21.8867581 },
    { lat: 48.4656909, lng: 21.8885704 },
    { lat: 48.4658671, lng: 21.8893032 },
    { lat: 48.4661243, lng: 21.8899467 },
    { lat: 48.4664436, lng: 21.89056 },
    { lat: 48.465907, lng: 21.8911759 },
    { lat: 48.4652742, lng: 21.8911096 },
    { lat: 48.4651276, lng: 21.8909664 },
    { lat: 48.4649495, lng: 21.8911744 },
    { lat: 48.4640102, lng: 21.8931121 },
    { lat: 48.4634652, lng: 21.8937578 },
    { lat: 48.4624495, lng: 21.8944311 },
    { lat: 48.4616118, lng: 21.8945287 },
    { lat: 48.4614334, lng: 21.8944681 },
    { lat: 48.4607971, lng: 21.8939514 },
    { lat: 48.4603716, lng: 21.8931923 },
    { lat: 48.4602037, lng: 21.892833 },
    { lat: 48.4600053, lng: 21.8921146 },
    { lat: 48.4599292, lng: 21.8917301 },
    { lat: 48.4599297, lng: 21.8910942 },
    { lat: 48.459274, lng: 21.8911835 },
    { lat: 48.4575998, lng: 21.8926738 },
    { lat: 48.457265, lng: 21.8941048 },
    { lat: 48.45711, lng: 21.8954855 },
    { lat: 48.4570868, lng: 21.8968248 },
    { lat: 48.457203, lng: 21.8977648 },
    { lat: 48.4573438, lng: 21.8983436 },
    { lat: 48.4577741, lng: 21.8995101 },
    { lat: 48.4583662, lng: 21.9006727 },
    { lat: 48.4589599, lng: 21.9016398 },
    { lat: 48.4593853, lng: 21.9022151 },
    { lat: 48.4598902, lng: 21.9028237 },
    { lat: 48.4603944, lng: 21.9033243 },
    { lat: 48.4606848, lng: 21.9035454 },
    { lat: 48.4610579, lng: 21.9036618 },
    { lat: 48.4609591, lng: 21.9044647 },
    { lat: 48.4614909, lng: 21.9047145 },
    { lat: 48.4618812, lng: 21.9050256 },
    { lat: 48.4628224, lng: 21.9063359 },
    { lat: 48.463152, lng: 21.9070423 },
    { lat: 48.4632518, lng: 21.9073779 },
    { lat: 48.4634004, lng: 21.908402 },
    { lat: 48.4635284, lng: 21.9087466 },
    { lat: 48.4639361, lng: 21.9093893 },
    { lat: 48.4648762, lng: 21.9115899 },
    { lat: 48.465821, lng: 21.913624 },
    { lat: 48.4670493, lng: 21.9170327 },
    { lat: 48.4688101, lng: 21.9199279 },
    { lat: 48.4708876, lng: 21.9221781 },
    { lat: 48.4712469, lng: 21.9221991 },
    { lat: 48.471886, lng: 21.9218456 },
    { lat: 48.4720809, lng: 21.9223439 },
    { lat: 48.4723664, lng: 21.9228672 },
  ],
  Borša: [
    { lat: 48.4121719, lng: 21.7172836 },
    { lat: 48.4122372, lng: 21.7169492 },
    { lat: 48.4121911, lng: 21.7168677 },
    { lat: 48.4122421, lng: 21.7165055 },
    { lat: 48.4123102, lng: 21.7163204 },
    { lat: 48.4122277, lng: 21.7163056 },
    { lat: 48.4121973, lng: 21.7160942 },
    { lat: 48.4120225, lng: 21.7157511 },
    { lat: 48.4119288, lng: 21.7157391 },
    { lat: 48.4116567, lng: 21.7158709 },
    { lat: 48.4117886, lng: 21.7154215 },
    { lat: 48.4117967, lng: 21.7151795 },
    { lat: 48.4117542, lng: 21.7150319 },
    { lat: 48.4115809, lng: 21.7149891 },
    { lat: 48.4115108, lng: 21.7148453 },
    { lat: 48.4115234, lng: 21.7146915 },
    { lat: 48.4114224, lng: 21.7147427 },
    { lat: 48.4113488, lng: 21.7145595 },
    { lat: 48.4115415, lng: 21.7144457 },
    { lat: 48.4115081, lng: 21.7143358 },
    { lat: 48.4114377, lng: 21.7143076 },
    { lat: 48.4113587, lng: 21.7140974 },
    { lat: 48.4112457, lng: 21.7140774 },
    { lat: 48.4109404, lng: 21.7137288 },
    { lat: 48.4109163, lng: 21.7134583 },
    { lat: 48.4109575, lng: 21.7133273 },
    { lat: 48.4109104, lng: 21.7132058 },
    { lat: 48.4107749, lng: 21.7132715 },
    { lat: 48.4107074, lng: 21.7131874 },
    { lat: 48.4106809, lng: 21.7130361 },
    { lat: 48.4108142, lng: 21.712848 },
    { lat: 48.4107938, lng: 21.7127596 },
    { lat: 48.4107504, lng: 21.7126529 },
    { lat: 48.4106035, lng: 21.7126686 },
    { lat: 48.4105726, lng: 21.7126177 },
    { lat: 48.4106832, lng: 21.7125065 },
    { lat: 48.4106845, lng: 21.7124218 },
    { lat: 48.410386, lng: 21.7121188 },
    { lat: 48.4104583, lng: 21.7117142 },
    { lat: 48.4103273, lng: 21.7115788 },
    { lat: 48.4102203, lng: 21.7115719 },
    { lat: 48.4102636, lng: 21.7113454 },
    { lat: 48.410462, lng: 21.7113117 },
    { lat: 48.4104515, lng: 21.7111363 },
    { lat: 48.410307, lng: 21.7111175 },
    { lat: 48.4102791, lng: 21.7110448 },
    { lat: 48.4103171, lng: 21.7106232 },
    { lat: 48.4103899, lng: 21.7105688 },
    { lat: 48.4103358, lng: 21.7104 },
    { lat: 48.4102106, lng: 21.710425 },
    { lat: 48.4101694, lng: 21.7102778 },
    { lat: 48.4102979, lng: 21.7102001 },
    { lat: 48.410339, lng: 21.7100988 },
    { lat: 48.4101983, lng: 21.7099728 },
    { lat: 48.4102368, lng: 21.7097297 },
    { lat: 48.4103154, lng: 21.7096048 },
    { lat: 48.4104436, lng: 21.7094794 },
    { lat: 48.410536, lng: 21.7095219 },
    { lat: 48.4105572, lng: 21.7093492 },
    { lat: 48.4106329, lng: 21.7092801 },
    { lat: 48.4106706, lng: 21.7093815 },
    { lat: 48.4107451, lng: 21.7093901 },
    { lat: 48.4107226, lng: 21.7091867 },
    { lat: 48.4107784, lng: 21.7089152 },
    { lat: 48.4107393, lng: 21.7087239 },
    { lat: 48.4106316, lng: 21.7085715 },
    { lat: 48.4116203, lng: 21.7078536 },
    { lat: 48.4117355, lng: 21.7076574 },
    { lat: 48.4120784, lng: 21.7074891 },
    { lat: 48.4130732, lng: 21.7071164 },
    { lat: 48.4132069, lng: 21.7075679 },
    { lat: 48.4154923, lng: 21.7066214 },
    { lat: 48.4156115, lng: 21.7069474 },
    { lat: 48.4156543, lng: 21.7072416 },
    { lat: 48.4163574, lng: 21.7071092 },
    { lat: 48.4181108, lng: 21.707081 },
    { lat: 48.4197658, lng: 21.7072835 },
    { lat: 48.4201422, lng: 21.7074347 },
    { lat: 48.4201237, lng: 21.7073345 },
    { lat: 48.4189698, lng: 21.7052446 },
    { lat: 48.4184969, lng: 21.7041961 },
    { lat: 48.4182769, lng: 21.703588 },
    { lat: 48.4181878, lng: 21.7028794 },
    { lat: 48.4151515, lng: 21.6997612 },
    { lat: 48.4150771, lng: 21.6995508 },
    { lat: 48.4135722, lng: 21.6848959 },
    { lat: 48.4130624, lng: 21.6803731 },
    { lat: 48.4101561, lng: 21.6806962 },
    { lat: 48.4024552, lng: 21.6817213 },
    { lat: 48.3980695, lng: 21.6823853 },
    { lat: 48.3973678, lng: 21.6879938 },
    { lat: 48.3972309, lng: 21.6879711 },
    { lat: 48.3958294, lng: 21.6847807 },
    { lat: 48.3958558, lng: 21.6847578 },
    { lat: 48.3955578, lng: 21.6842307 },
    { lat: 48.3938897, lng: 21.6858967 },
    { lat: 48.3934552, lng: 21.6862959 },
    { lat: 48.3927756, lng: 21.6867907 },
    { lat: 48.3927349, lng: 21.6866218 },
    { lat: 48.3923651, lng: 21.6864722 },
    { lat: 48.3922832, lng: 21.684134 },
    { lat: 48.390299, lng: 21.6846901 },
    { lat: 48.3901746, lng: 21.684919 },
    { lat: 48.3902544, lng: 21.6843788 },
    { lat: 48.3902628, lng: 21.6834136 },
    { lat: 48.390273, lng: 21.683368 },
    { lat: 48.390221, lng: 21.683375 },
    { lat: 48.389222, lng: 21.683489 },
    { lat: 48.388427, lng: 21.683598 },
    { lat: 48.388356, lng: 21.683958 },
    { lat: 48.388258, lng: 21.684176 },
    { lat: 48.387932, lng: 21.684755 },
    { lat: 48.387517, lng: 21.685523 },
    { lat: 48.387356, lng: 21.6858 },
    { lat: 48.387046, lng: 21.686339 },
    { lat: 48.3869, lng: 21.686624 },
    { lat: 48.386719, lng: 21.687037 },
    { lat: 48.386503, lng: 21.687538 },
    { lat: 48.386363, lng: 21.68795 },
    { lat: 48.386147, lng: 21.688595 },
    { lat: 48.385915, lng: 21.688933 },
    { lat: 48.385694, lng: 21.689369 },
    { lat: 48.385634, lng: 21.689537 },
    { lat: 48.385561, lng: 21.689752 },
    { lat: 48.385177, lng: 21.690771 },
    { lat: 48.385083, lng: 21.69095 },
    { lat: 48.385014, lng: 21.691131 },
    { lat: 48.384984, lng: 21.691252 },
    { lat: 48.384948, lng: 21.691581 },
    { lat: 48.384829, lng: 21.692169 },
    { lat: 48.384595, lng: 21.692568 },
    { lat: 48.384372, lng: 21.692992 },
    { lat: 48.384183, lng: 21.693397 },
    { lat: 48.384, lng: 21.69377 },
    { lat: 48.383862, lng: 21.694065 },
    { lat: 48.383687, lng: 21.694583 },
    { lat: 48.383684, lng: 21.694627 },
    { lat: 48.383659, lng: 21.695427 },
    { lat: 48.383583, lng: 21.696055 },
    { lat: 48.38341, lng: 21.696948 },
    { lat: 48.38331, lng: 21.697323 },
    { lat: 48.383236, lng: 21.697593 },
    { lat: 48.383109, lng: 21.698069 },
    { lat: 48.382939, lng: 21.698466 },
    { lat: 48.381556, lng: 21.700499 },
    { lat: 48.381212, lng: 21.700979 },
    { lat: 48.380885, lng: 21.701444 },
    { lat: 48.380695, lng: 21.701741 },
    { lat: 48.380594, lng: 21.701865 },
    { lat: 48.38043, lng: 21.702067 },
    { lat: 48.379964, lng: 21.702643 },
    { lat: 48.379886, lng: 21.702673 },
    { lat: 48.379711, lng: 21.702742 },
    { lat: 48.379542, lng: 21.702833 },
    { lat: 48.379373, lng: 21.702924 },
    { lat: 48.379208, lng: 21.703037 },
    { lat: 48.379046, lng: 21.703155 },
    { lat: 48.37889, lng: 21.70329 },
    { lat: 48.378773, lng: 21.703399 },
    { lat: 48.37862, lng: 21.703541 },
    { lat: 48.37847, lng: 21.703691 },
    { lat: 48.378323, lng: 21.703848 },
    { lat: 48.37818, lng: 21.704013 },
    { lat: 48.378037, lng: 21.704178 },
    { lat: 48.377899, lng: 21.70435 },
    { lat: 48.377753, lng: 21.704508 },
    { lat: 48.377615, lng: 21.704683 },
    { lat: 48.377458, lng: 21.704851 },
    { lat: 48.377308, lng: 21.705008 },
    { lat: 48.377159, lng: 21.70517 },
    { lat: 48.377006, lng: 21.705319 },
    { lat: 48.376853, lng: 21.705466 },
    { lat: 48.376771, lng: 21.705524 },
    { lat: 48.376598, lng: 21.705603 },
    { lat: 48.376424, lng: 21.705683 },
    { lat: 48.376297, lng: 21.705721 },
    { lat: 48.376191, lng: 21.705761 },
    { lat: 48.376048, lng: 21.705807 },
    { lat: 48.375917, lng: 21.705868 },
    { lat: 48.375738, lng: 21.705817 },
    { lat: 48.375558, lng: 21.705806 },
    { lat: 48.375343, lng: 21.705729 },
    { lat: 48.375166, lng: 21.705666 },
    { lat: 48.374999, lng: 21.705549 },
    { lat: 48.374878, lng: 21.705457 },
    { lat: 48.374747, lng: 21.705366 },
    { lat: 48.374566, lng: 21.705259 },
    { lat: 48.374405, lng: 21.705133 },
    { lat: 48.374242, lng: 21.705022 },
    { lat: 48.374075, lng: 21.704915 },
    { lat: 48.373989, lng: 21.704882 },
    { lat: 48.37382, lng: 21.704783 },
    { lat: 48.373699, lng: 21.704711 },
    { lat: 48.373627, lng: 21.704673 },
    { lat: 48.373446, lng: 21.704558 },
    { lat: 48.373319, lng: 21.704491 },
    { lat: 48.373198, lng: 21.704396 },
    { lat: 48.373032, lng: 21.704292 },
    { lat: 48.37287, lng: 21.704172 },
    { lat: 48.372821, lng: 21.704137 },
    { lat: 48.37269, lng: 21.704028 },
    { lat: 48.372532, lng: 21.703887 },
    { lat: 48.372384, lng: 21.703753 },
    { lat: 48.372226, lng: 21.703602 },
    { lat: 48.37208, lng: 21.703444 },
    { lat: 48.371952, lng: 21.703294 },
    { lat: 48.37181, lng: 21.703146 },
    { lat: 48.371655, lng: 21.702973 },
    { lat: 48.371511, lng: 21.702815 },
    { lat: 48.371373, lng: 21.702648 },
    { lat: 48.371215, lng: 21.702477 },
    { lat: 48.371154, lng: 21.702407 },
    { lat: 48.37097, lng: 21.70281 },
    { lat: 48.370319, lng: 21.703081 },
    { lat: 48.369929, lng: 21.703245 },
    { lat: 48.3703156, lng: 21.7045181 },
    { lat: 48.3708051, lng: 21.7065894 },
    { lat: 48.3710007, lng: 21.7072448 },
    { lat: 48.3713372, lng: 21.7080261 },
    { lat: 48.3719871, lng: 21.7090104 },
    { lat: 48.3727614, lng: 21.7100098 },
    { lat: 48.3734808, lng: 21.7110604 },
    { lat: 48.3736489, lng: 21.7111991 },
    { lat: 48.3740506, lng: 21.7123619 },
    { lat: 48.3744675, lng: 21.7139158 },
    { lat: 48.3746073, lng: 21.7153345 },
    { lat: 48.374699, lng: 21.7180136 },
    { lat: 48.3747864, lng: 21.718915 },
    { lat: 48.3750534, lng: 21.7204251 },
    { lat: 48.3753333, lng: 21.7215316 },
    { lat: 48.3762592, lng: 21.7238152 },
    { lat: 48.3765288, lng: 21.7243424 },
    { lat: 48.3770308, lng: 21.7251386 },
    { lat: 48.3778895, lng: 21.7255202 },
    { lat: 48.3789844, lng: 21.7257798 },
    { lat: 48.3796221, lng: 21.7256836 },
    { lat: 48.3802245, lng: 21.7254119 },
    { lat: 48.3810416, lng: 21.7249205 },
    { lat: 48.3820683, lng: 21.7240598 },
    { lat: 48.3826197, lng: 21.723405 },
    { lat: 48.3831036, lng: 21.7227002 },
    { lat: 48.3835066, lng: 21.7219953 },
    { lat: 48.3838646, lng: 21.7211717 },
    { lat: 48.3841348, lng: 21.7201351 },
    { lat: 48.3842093, lng: 21.7194821 },
    { lat: 48.3841967, lng: 21.7191038 },
    { lat: 48.3840178, lng: 21.7183919 },
    { lat: 48.3836762, lng: 21.7176613 },
    { lat: 48.3834507, lng: 21.7173094 },
    { lat: 48.3820559, lng: 21.7163713 },
    { lat: 48.3811555, lng: 21.715619 },
    { lat: 48.380603, lng: 21.7150323 },
    { lat: 48.3799185, lng: 21.7141096 },
    { lat: 48.3794783, lng: 21.713309 },
    { lat: 48.3792271, lng: 21.7126795 },
    { lat: 48.3790201, lng: 21.7118488 },
    { lat: 48.3787381, lng: 21.7102061 },
    { lat: 48.378716, lng: 21.7096805 },
    { lat: 48.378774, lng: 21.7094946 },
    { lat: 48.378674, lng: 21.7078015 },
    { lat: 48.3787905, lng: 21.7066668 },
    { lat: 48.3792444, lng: 21.7055245 },
    { lat: 48.3797445, lng: 21.7048408 },
    { lat: 48.3800785, lng: 21.7045513 },
    { lat: 48.3805158, lng: 21.7043638 },
    { lat: 48.3812563, lng: 21.7042069 },
    { lat: 48.3819098, lng: 21.7042369 },
    { lat: 48.3824575, lng: 21.7043952 },
    { lat: 48.3831283, lng: 21.704776 },
    { lat: 48.3837401, lng: 21.705423 },
    { lat: 48.3841739, lng: 21.7060279 },
    { lat: 48.3846433, lng: 21.7068614 },
    { lat: 48.3853456, lng: 21.7083568 },
    { lat: 48.3859805, lng: 21.710811 },
    { lat: 48.387045, lng: 21.7162967 },
    { lat: 48.3873563, lng: 21.7174465 },
    { lat: 48.3875712, lng: 21.7184791 },
    { lat: 48.3877013, lng: 21.7198789 },
    { lat: 48.3877708, lng: 21.7215303 },
    { lat: 48.387774, lng: 21.7216069 },
    { lat: 48.388157, lng: 21.7215839 },
    { lat: 48.3887002, lng: 21.721783 },
    { lat: 48.3889395, lng: 21.7217659 },
    { lat: 48.3891792, lng: 21.7215598 },
    { lat: 48.3896826, lng: 21.7218325 },
    { lat: 48.3899274, lng: 21.7218519 },
    { lat: 48.3901531, lng: 21.7217935 },
    { lat: 48.3903418, lng: 21.7216564 },
    { lat: 48.3905187, lng: 21.7216236 },
    { lat: 48.3907881, lng: 21.7217261 },
    { lat: 48.3910785, lng: 21.7216104 },
    { lat: 48.3912282, lng: 21.7213977 },
    { lat: 48.391555, lng: 21.7211629 },
    { lat: 48.3919962, lng: 21.720549 },
    { lat: 48.3925209, lng: 21.720107 },
    { lat: 48.3930021, lng: 21.720341 },
    { lat: 48.3954307, lng: 21.7173233 },
    { lat: 48.3956617, lng: 21.7166776 },
    { lat: 48.3962161, lng: 21.7163308 },
    { lat: 48.396405, lng: 21.7162824 },
    { lat: 48.3979276, lng: 21.7151729 },
    { lat: 48.3988137, lng: 21.7144439 },
    { lat: 48.3999719, lng: 21.7138812 },
    { lat: 48.4012826, lng: 21.7137076 },
    { lat: 48.4025596, lng: 21.713291 },
    { lat: 48.4031844, lng: 21.7129352 },
    { lat: 48.4038441, lng: 21.7126926 },
    { lat: 48.4049145, lng: 21.7126293 },
    { lat: 48.4051047, lng: 21.7137908 },
    { lat: 48.4058575, lng: 21.7139986 },
    { lat: 48.406136, lng: 21.7141477 },
    { lat: 48.4065821, lng: 21.714783 },
    { lat: 48.4071731, lng: 21.7154583 },
    { lat: 48.4075712, lng: 21.7168657 },
    { lat: 48.407995, lng: 21.7175997 },
    { lat: 48.4082178, lng: 21.7186446 },
    { lat: 48.4085063, lng: 21.7189124 },
    { lat: 48.4094827, lng: 21.7188185 },
    { lat: 48.4099855, lng: 21.7190144 },
    { lat: 48.4102134, lng: 21.7190023 },
    { lat: 48.410642, lng: 21.7187902 },
    { lat: 48.4113465, lng: 21.7188339 },
    { lat: 48.4116901, lng: 21.718934 },
    { lat: 48.4117353, lng: 21.7188169 },
    { lat: 48.4118952, lng: 21.7180347 },
    { lat: 48.4121719, lng: 21.7172836 },
  ],
  ZemplínskyBranč: [
    { lat: 48.5521941, lng: 21.6840135 },
    { lat: 48.5510258, lng: 21.6847354 },
    { lat: 48.5502712, lng: 21.6857875 },
    { lat: 48.5498633, lng: 21.6864626 },
    { lat: 48.549617, lng: 21.6870428 },
    { lat: 48.5494218, lng: 21.6872701 },
    { lat: 48.5489075, lng: 21.6876386 },
    { lat: 48.5473281, lng: 21.6880214 },
    { lat: 48.5463995, lng: 21.6885433 },
    { lat: 48.5458291, lng: 21.6875563 },
    { lat: 48.54358, lng: 21.6819555 },
    { lat: 48.5428565, lng: 21.6803432 },
    { lat: 48.542333, lng: 21.6788918 },
    { lat: 48.5414715, lng: 21.6755316 },
    { lat: 48.541097, lng: 21.6739773 },
    { lat: 48.5409846, lng: 21.6742297 },
    { lat: 48.5405395, lng: 21.6741646 },
    { lat: 48.540426, lng: 21.6744959 },
    { lat: 48.5404617, lng: 21.6751445 },
    { lat: 48.5402765, lng: 21.6756161 },
    { lat: 48.5402671, lng: 21.6761745 },
    { lat: 48.5401661, lng: 21.6765095 },
    { lat: 48.5401019, lng: 21.6765508 },
    { lat: 48.5400258, lng: 21.6764394 },
    { lat: 48.5399608, lng: 21.6761228 },
    { lat: 48.539893, lng: 21.6761082 },
    { lat: 48.5396518, lng: 21.6763419 },
    { lat: 48.5395219, lng: 21.6762807 },
    { lat: 48.5395101, lng: 21.6763862 },
    { lat: 48.5395757, lng: 21.6765235 },
    { lat: 48.539584, lng: 21.6766643 },
    { lat: 48.5394149, lng: 21.6768193 },
    { lat: 48.5393592, lng: 21.6772456 },
    { lat: 48.5394274, lng: 21.6773969 },
    { lat: 48.539392, lng: 21.6776252 },
    { lat: 48.5399125, lng: 21.6798305 },
    { lat: 48.5404091, lng: 21.6811603 },
    { lat: 48.5428055, lng: 21.6865165 },
    { lat: 48.5427036, lng: 21.6866978 },
    { lat: 48.541714, lng: 21.6878704 },
    { lat: 48.5401749, lng: 21.688986 },
    { lat: 48.5413469, lng: 21.69282 },
    { lat: 48.5397775, lng: 21.693649 },
    { lat: 48.5384636, lng: 21.6944462 },
    { lat: 48.5388084, lng: 21.6962033 },
    { lat: 48.5394643, lng: 21.7012528 },
    { lat: 48.5398525, lng: 21.7039071 },
    { lat: 48.5399479, lng: 21.7048492 },
    { lat: 48.5392333, lng: 21.7040964 },
    { lat: 48.5376789, lng: 21.7032448 },
    { lat: 48.5363904, lng: 21.7026627 },
    { lat: 48.5356421, lng: 21.7022397 },
    { lat: 48.5343915, lng: 21.7018889 },
    { lat: 48.5344545, lng: 21.7038376 },
    { lat: 48.5343621, lng: 21.7069432 },
    { lat: 48.5342212, lng: 21.7101314 },
    { lat: 48.5339797, lng: 21.7124371 },
    { lat: 48.5336117, lng: 21.7134771 },
    { lat: 48.5328039, lng: 21.7141351 },
    { lat: 48.5325008, lng: 21.7157462 },
    { lat: 48.5324949, lng: 21.7157813 },
    { lat: 48.5326374, lng: 21.7161397 },
    { lat: 48.5328546, lng: 21.7161761 },
    { lat: 48.5329652, lng: 21.7161096 },
    { lat: 48.5329623, lng: 21.715671 },
    { lat: 48.5330907, lng: 21.7154676 },
    { lat: 48.5332209, lng: 21.7154582 },
    { lat: 48.5338102, lng: 21.7160284 },
    { lat: 48.5340283, lng: 21.7158564 },
    { lat: 48.5342149, lng: 21.7155556 },
    { lat: 48.5341761, lng: 21.7152001 },
    { lat: 48.5345245, lng: 21.7151908 },
    { lat: 48.53457, lng: 21.7151302 },
    { lat: 48.5344038, lng: 21.7147197 },
    { lat: 48.5344306, lng: 21.714586 },
    { lat: 48.5344774, lng: 21.7145717 },
    { lat: 48.5348316, lng: 21.7147314 },
    { lat: 48.5349221, lng: 21.7154544 },
    { lat: 48.5350218, lng: 21.7154785 },
    { lat: 48.5352504, lng: 21.7153618 },
    { lat: 48.5355353, lng: 21.7156611 },
    { lat: 48.5356863, lng: 21.7160491 },
    { lat: 48.535967, lng: 21.7160308 },
    { lat: 48.5364106, lng: 21.7161999 },
    { lat: 48.5364459, lng: 21.7165102 },
    { lat: 48.5361454, lng: 21.7174236 },
    { lat: 48.5362253, lng: 21.7177187 },
    { lat: 48.536104, lng: 21.718177 },
    { lat: 48.536444, lng: 21.718485 },
    { lat: 48.5367609, lng: 21.7189654 },
    { lat: 48.5370629, lng: 21.7190687 },
    { lat: 48.5371237, lng: 21.7191914 },
    { lat: 48.5370781, lng: 21.7194644 },
    { lat: 48.5368567, lng: 21.719763 },
    { lat: 48.5365107, lng: 21.7199507 },
    { lat: 48.5365169, lng: 21.7200998 },
    { lat: 48.5365538, lng: 21.7202101 },
    { lat: 48.5369538, lng: 21.7203187 },
    { lat: 48.5370107, lng: 21.7203855 },
    { lat: 48.5370055, lng: 21.7205669 },
    { lat: 48.536907, lng: 21.7207519 },
    { lat: 48.5368241, lng: 21.7207662 },
    { lat: 48.5368078, lng: 21.7209131 },
    { lat: 48.5369852, lng: 21.7211862 },
    { lat: 48.5375328, lng: 21.7215152 },
    { lat: 48.5375471, lng: 21.7216269 },
    { lat: 48.5375312, lng: 21.721788 },
    { lat: 48.5374473, lng: 21.7219288 },
    { lat: 48.537154, lng: 21.7221899 },
    { lat: 48.5371624, lng: 21.72238 },
    { lat: 48.537378, lng: 21.7224886 },
    { lat: 48.5374732, lng: 21.7226369 },
    { lat: 48.5373244, lng: 21.7230035 },
    { lat: 48.5373204, lng: 21.7233169 },
    { lat: 48.5372591, lng: 21.7234644 },
    { lat: 48.5370188, lng: 21.7236034 },
    { lat: 48.536877, lng: 21.7243544 },
    { lat: 48.5372077, lng: 21.7251443 },
    { lat: 48.5374817, lng: 21.7253328 },
    { lat: 48.5376455, lng: 21.7255319 },
    { lat: 48.5378569, lng: 21.7260626 },
    { lat: 48.5380209, lng: 21.7266359 },
    { lat: 48.5388947, lng: 21.7277495 },
    { lat: 48.5390983, lng: 21.7279547 },
    { lat: 48.5394261, lng: 21.728097 },
    { lat: 48.5392937, lng: 21.7284438 },
    { lat: 48.5394529, lng: 21.7284269 },
    { lat: 48.5396371, lng: 21.7285027 },
    { lat: 48.5396671, lng: 21.7293625 },
    { lat: 48.5397888, lng: 21.7296601 },
    { lat: 48.5399706, lng: 21.7296898 },
    { lat: 48.5403675, lng: 21.7295964 },
    { lat: 48.5409121, lng: 21.7299264 },
    { lat: 48.5410846, lng: 21.7299407 },
    { lat: 48.5412167, lng: 21.72983 },
    { lat: 48.5413454, lng: 21.7295598 },
    { lat: 48.5414833, lng: 21.7294516 },
    { lat: 48.5417226, lng: 21.7294004 },
    { lat: 48.5419937, lng: 21.7295217 },
    { lat: 48.5421697, lng: 21.7294538 },
    { lat: 48.5445171, lng: 21.7313526 },
    { lat: 48.5457003, lng: 21.7325942 },
    { lat: 48.5466844, lng: 21.7335058 },
    { lat: 48.5484134, lng: 21.7342818 },
    { lat: 48.5491637, lng: 21.7346998 },
    { lat: 48.5499435, lng: 21.7357116 },
    { lat: 48.5514264, lng: 21.7366316 },
    { lat: 48.5516337, lng: 21.7376894 },
    { lat: 48.5517823, lng: 21.7379911 },
    { lat: 48.5520893, lng: 21.7383165 },
    { lat: 48.5527123, lng: 21.738437 },
    { lat: 48.5532498, lng: 21.7392147 },
    { lat: 48.5535663, lng: 21.7398198 },
    { lat: 48.5539174, lng: 21.7410002 },
    { lat: 48.5539918, lng: 21.7411285 },
    { lat: 48.5540893, lng: 21.741281 },
    { lat: 48.5544474, lng: 21.7410866 },
    { lat: 48.5546772, lng: 21.7406692 },
    { lat: 48.5549076, lng: 21.740452 },
    { lat: 48.5549316, lng: 21.7403014 },
    { lat: 48.5544466, lng: 21.7376542 },
    { lat: 48.5540962, lng: 21.7349537 },
    { lat: 48.5540795, lng: 21.7344553 },
    { lat: 48.5541531, lng: 21.7331738 },
    { lat: 48.5542991, lng: 21.7322802 },
    { lat: 48.5541317, lng: 21.7301169 },
    { lat: 48.5541815, lng: 21.7288733 },
    { lat: 48.5542136, lng: 21.7284782 },
    { lat: 48.5544711, lng: 21.7283038 },
    { lat: 48.5546368, lng: 21.7279808 },
    { lat: 48.5545593, lng: 21.7277799 },
    { lat: 48.5544071, lng: 21.7270121 },
    { lat: 48.554446, lng: 21.7270406 },
    { lat: 48.5540746, lng: 21.7249445 },
    { lat: 48.5571043, lng: 21.7245611 },
    { lat: 48.556972, lng: 21.7241149 },
    { lat: 48.5568929, lng: 21.7234379 },
    { lat: 48.5570247, lng: 21.7229805 },
    { lat: 48.5570317, lng: 21.7223223 },
    { lat: 48.5568325, lng: 21.7216171 },
    { lat: 48.5562976, lng: 21.7206735 },
    { lat: 48.5563062, lng: 21.7204939 },
    { lat: 48.5560154, lng: 21.7199754 },
    { lat: 48.5558962, lng: 21.7199792 },
    { lat: 48.5559477, lng: 21.7197974 },
    { lat: 48.555838, lng: 21.7196113 },
    { lat: 48.5558344, lng: 21.7194786 },
    { lat: 48.5558885, lng: 21.719394 },
    { lat: 48.5558485, lng: 21.7192061 },
    { lat: 48.5557487, lng: 21.7190971 },
    { lat: 48.5558483, lng: 21.7190335 },
    { lat: 48.5556695, lng: 21.7179562 },
    { lat: 48.5560953, lng: 21.7157619 },
    { lat: 48.556402, lng: 21.715579 },
    { lat: 48.5564198, lng: 21.7154543 },
    { lat: 48.5566484, lng: 21.71537 },
    { lat: 48.5568756, lng: 21.7149142 },
    { lat: 48.5568126, lng: 21.713951 },
    { lat: 48.5565055, lng: 21.7140415 },
    { lat: 48.5564444, lng: 21.713195 },
    { lat: 48.5564409, lng: 21.7119426 },
    { lat: 48.55652, lng: 21.7108729 },
    { lat: 48.5565519, lng: 21.7105559 },
    { lat: 48.5567219, lng: 21.7101005 },
    { lat: 48.5565499, lng: 21.708782 },
    { lat: 48.5570015, lng: 21.7085866 },
    { lat: 48.5569353, lng: 21.7078477 },
    { lat: 48.5573665, lng: 21.7076901 },
    { lat: 48.5573568, lng: 21.7074968 },
    { lat: 48.557923, lng: 21.7072134 },
    { lat: 48.5578102, lng: 21.7023462 },
    { lat: 48.5560836, lng: 21.7028688 },
    { lat: 48.5556939, lng: 21.6993692 },
    { lat: 48.5554407, lng: 21.6975458 },
    { lat: 48.5550052, lng: 21.6950997 },
    { lat: 48.5547174, lng: 21.6931591 },
    { lat: 48.5538142, lng: 21.6882947 },
    { lat: 48.553066, lng: 21.6860698 },
    { lat: 48.5522043, lng: 21.6840147 },
    { lat: 48.5521941, lng: 21.6840135 },
  ],
  Hrčeľ: [
    { lat: 48.4945519, lng: 21.6976894 },
    { lat: 48.4946361, lng: 21.6976381 },
    { lat: 48.4949981, lng: 21.6973409 },
    { lat: 48.4953217, lng: 21.6972039 },
    { lat: 48.4964708, lng: 21.6968816 },
    { lat: 48.4968024, lng: 21.696997 },
    { lat: 48.4970585, lng: 21.6972246 },
    { lat: 48.4986245, lng: 21.6978391 },
    { lat: 48.4995384, lng: 21.6980145 },
    { lat: 48.5011704, lng: 21.6986129 },
    { lat: 48.501455, lng: 21.698822 },
    { lat: 48.5022199, lng: 21.6987285 },
    { lat: 48.5034558, lng: 21.6987421 },
    { lat: 48.5038645, lng: 21.6986357 },
    { lat: 48.5042711, lng: 21.6987682 },
    { lat: 48.5046156, lng: 21.6986157 },
    { lat: 48.5049411, lng: 21.698299 },
    { lat: 48.508405, lng: 21.7003516 },
    { lat: 48.5101671, lng: 21.7015103 },
    { lat: 48.5108979, lng: 21.7019082 },
    { lat: 48.51132, lng: 21.7022481 },
    { lat: 48.5116744, lng: 21.7024119 },
    { lat: 48.5122245, lng: 21.7028663 },
    { lat: 48.5126713, lng: 21.7029868 },
    { lat: 48.5122594, lng: 21.70357 },
    { lat: 48.5115078, lng: 21.7035704 },
    { lat: 48.511572, lng: 21.7039043 },
    { lat: 48.5123653, lng: 21.7038243 },
    { lat: 48.5129183, lng: 21.7032626 },
    { lat: 48.5129376, lng: 21.7027678 },
    { lat: 48.5134698, lng: 21.7024828 },
    { lat: 48.5135531, lng: 21.7026508 },
    { lat: 48.5137031, lng: 21.7027466 },
    { lat: 48.5142743, lng: 21.7023183 },
    { lat: 48.5147579, lng: 21.7018506 },
    { lat: 48.5157609, lng: 21.7013726 },
    { lat: 48.5157906, lng: 21.7013034 },
    { lat: 48.5159187, lng: 21.7014296 },
    { lat: 48.5161985, lng: 21.7021996 },
    { lat: 48.5161968, lng: 21.7027625 },
    { lat: 48.5165897, lng: 21.7041384 },
    { lat: 48.5182263, lng: 21.702785 },
    { lat: 48.5183514, lng: 21.7030094 },
    { lat: 48.5183606, lng: 21.7031412 },
    { lat: 48.5182723, lng: 21.7038646 },
    { lat: 48.518469, lng: 21.7041016 },
    { lat: 48.5186306, lng: 21.7040878 },
    { lat: 48.5188908, lng: 21.7043465 },
    { lat: 48.5194276, lng: 21.7043507 },
    { lat: 48.5196499, lng: 21.704627 },
    { lat: 48.5198075, lng: 21.7045889 },
    { lat: 48.5201669, lng: 21.7047294 },
    { lat: 48.5206515, lng: 21.7049683 },
    { lat: 48.5207877, lng: 21.7051295 },
    { lat: 48.5210512, lng: 21.7051016 },
    { lat: 48.521235, lng: 21.7053249 },
    { lat: 48.5213881, lng: 21.7053723 },
    { lat: 48.5213608, lng: 21.7055632 },
    { lat: 48.5215106, lng: 21.7061914 },
    { lat: 48.5216177, lng: 21.7062814 },
    { lat: 48.5218843, lng: 21.7059935 },
    { lat: 48.5219732, lng: 21.7060274 },
    { lat: 48.5220418, lng: 21.7062361 },
    { lat: 48.5220238, lng: 21.7064853 },
    { lat: 48.5220871, lng: 21.7065762 },
    { lat: 48.5222698, lng: 21.70656 },
    { lat: 48.5224187, lng: 21.7061562 },
    { lat: 48.5225897, lng: 21.7061148 },
    { lat: 48.5228995, lng: 21.7062093 },
    { lat: 48.5231809, lng: 21.7060919 },
    { lat: 48.5234941, lng: 21.7061909 },
    { lat: 48.5238969, lng: 21.706186 },
    { lat: 48.5240674, lng: 21.7062927 },
    { lat: 48.5240805, lng: 21.7071913 },
    { lat: 48.524267, lng: 21.7075124 },
    { lat: 48.5243123, lng: 21.7077762 },
    { lat: 48.5243729, lng: 21.7078126 },
    { lat: 48.5246465, lng: 21.7075292 },
    { lat: 48.5247739, lng: 21.7072431 },
    { lat: 48.5248808, lng: 21.7072461 },
    { lat: 48.5250329, lng: 21.7076666 },
    { lat: 48.5251136, lng: 21.7076499 },
    { lat: 48.5251934, lng: 21.7074473 },
    { lat: 48.5252405, lng: 21.7069816 },
    { lat: 48.5254441, lng: 21.7070649 },
    { lat: 48.5253726, lng: 21.7080805 },
    { lat: 48.5257394, lng: 21.7082007 },
    { lat: 48.5261806, lng: 21.7084995 },
    { lat: 48.5262006, lng: 21.7086562 },
    { lat: 48.5263698, lng: 21.7090007 },
    { lat: 48.5262866, lng: 21.7093413 },
    { lat: 48.5265696, lng: 21.7092756 },
    { lat: 48.5267166, lng: 21.7093923 },
    { lat: 48.5270501, lng: 21.7090289 },
    { lat: 48.5272511, lng: 21.7091827 },
    { lat: 48.5273244, lng: 21.7095211 },
    { lat: 48.5275696, lng: 21.7095227 },
    { lat: 48.5276872, lng: 21.7091634 },
    { lat: 48.5278596, lng: 21.7090491 },
    { lat: 48.5279306, lng: 21.7091821 },
    { lat: 48.5277554, lng: 21.7097289 },
    { lat: 48.527855, lng: 21.7097952 },
    { lat: 48.5280792, lng: 21.7095933 },
    { lat: 48.5283609, lng: 21.7096115 },
    { lat: 48.5283852, lng: 21.7098006 },
    { lat: 48.5281935, lng: 21.7104538 },
    { lat: 48.5282933, lng: 21.7105251 },
    { lat: 48.5286042, lng: 21.7103324 },
    { lat: 48.5287351, lng: 21.7103411 },
    { lat: 48.5288157, lng: 21.7105342 },
    { lat: 48.5288162, lng: 21.7108046 },
    { lat: 48.5285635, lng: 21.7113622 },
    { lat: 48.528615, lng: 21.7116437 },
    { lat: 48.5288259, lng: 21.7118228 },
    { lat: 48.5289903, lng: 21.7124524 },
    { lat: 48.5292581, lng: 21.7123323 },
    { lat: 48.5297394, lng: 21.7127777 },
    { lat: 48.5298721, lng: 21.7129844 },
    { lat: 48.5302799, lng: 21.7131642 },
    { lat: 48.5305007, lng: 21.7129696 },
    { lat: 48.5306906, lng: 21.7134634 },
    { lat: 48.5307278, lng: 21.7137998 },
    { lat: 48.5306113, lng: 21.7139806 },
    { lat: 48.5306758, lng: 21.7140831 },
    { lat: 48.5310788, lng: 21.7143807 },
    { lat: 48.5311949, lng: 21.7146736 },
    { lat: 48.5313336, lng: 21.7146494 },
    { lat: 48.5315551, lng: 21.7148442 },
    { lat: 48.5317876, lng: 21.714917 },
    { lat: 48.5319859, lng: 21.7148051 },
    { lat: 48.5320389, lng: 21.7148451 },
    { lat: 48.5320623, lng: 21.7152573 },
    { lat: 48.5322289, lng: 21.7153858 },
    { lat: 48.5323492, lng: 21.7156316 },
    { lat: 48.5324949, lng: 21.7157813 },
    { lat: 48.5325008, lng: 21.7157462 },
    { lat: 48.5328039, lng: 21.7141351 },
    { lat: 48.5336117, lng: 21.7134771 },
    { lat: 48.5339797, lng: 21.7124371 },
    { lat: 48.5342212, lng: 21.7101314 },
    { lat: 48.5343621, lng: 21.7069432 },
    { lat: 48.5344545, lng: 21.7038376 },
    { lat: 48.5343915, lng: 21.7018889 },
    { lat: 48.5356421, lng: 21.7022397 },
    { lat: 48.5363904, lng: 21.7026627 },
    { lat: 48.5376789, lng: 21.7032448 },
    { lat: 48.5392333, lng: 21.7040964 },
    { lat: 48.5399479, lng: 21.7048492 },
    { lat: 48.5398525, lng: 21.7039071 },
    { lat: 48.5394643, lng: 21.7012528 },
    { lat: 48.5388084, lng: 21.6962033 },
    { lat: 48.5384636, lng: 21.6944462 },
    { lat: 48.5397775, lng: 21.693649 },
    { lat: 48.5413469, lng: 21.69282 },
    { lat: 48.5401749, lng: 21.688986 },
    { lat: 48.541714, lng: 21.6878704 },
    { lat: 48.5427036, lng: 21.6866978 },
    { lat: 48.5428055, lng: 21.6865165 },
    { lat: 48.5404091, lng: 21.6811603 },
    { lat: 48.5399125, lng: 21.6798305 },
    { lat: 48.539392, lng: 21.6776252 },
    { lat: 48.5394274, lng: 21.6773969 },
    { lat: 48.5393592, lng: 21.6772456 },
    { lat: 48.5394149, lng: 21.6768193 },
    { lat: 48.539584, lng: 21.6766643 },
    { lat: 48.5395757, lng: 21.6765235 },
    { lat: 48.5395101, lng: 21.6763862 },
    { lat: 48.5395219, lng: 21.6762807 },
    { lat: 48.5392347, lng: 21.6754958 },
    { lat: 48.5387971, lng: 21.6740595 },
    { lat: 48.5376716, lng: 21.6746207 },
    { lat: 48.5371163, lng: 21.6748018 },
    { lat: 48.5366154, lng: 21.6750818 },
    { lat: 48.5365552, lng: 21.675224 },
    { lat: 48.5363558, lng: 21.6749357 },
    { lat: 48.5353901, lng: 21.6738496 },
    { lat: 48.5353314, lng: 21.6743347 },
    { lat: 48.5340942, lng: 21.6734945 },
    { lat: 48.5324274, lng: 21.6727357 },
    { lat: 48.5323616, lng: 21.6727597 },
    { lat: 48.5317454, lng: 21.6738388 },
    { lat: 48.5315972, lng: 21.6739734 },
    { lat: 48.5309091, lng: 21.6748986 },
    { lat: 48.5308867, lng: 21.6747772 },
    { lat: 48.5306799, lng: 21.674949 },
    { lat: 48.5292443, lng: 21.6750815 },
    { lat: 48.527405, lng: 21.6749269 },
    { lat: 48.5273917, lng: 21.6754311 },
    { lat: 48.5270052, lng: 21.6754437 },
    { lat: 48.5269962, lng: 21.6757343 },
    { lat: 48.5258879, lng: 21.6755241 },
    { lat: 48.5246248, lng: 21.6748077 },
    { lat: 48.5236608, lng: 21.6747753 },
    { lat: 48.5234741, lng: 21.6746907 },
    { lat: 48.5223272, lng: 21.6746817 },
    { lat: 48.5220473, lng: 21.6750557 },
    { lat: 48.5215572, lng: 21.6751994 },
    { lat: 48.5196641, lng: 21.6752658 },
    { lat: 48.5185121, lng: 21.6762561 },
    { lat: 48.5172605, lng: 21.6750039 },
    { lat: 48.516995, lng: 21.6746755 },
    { lat: 48.5163973, lng: 21.6736426 },
    { lat: 48.5162478, lng: 21.67484 },
    { lat: 48.5149761, lng: 21.6753998 },
    { lat: 48.5135884, lng: 21.6762127 },
    { lat: 48.5129652, lng: 21.6762982 },
    { lat: 48.5125419, lng: 21.6770285 },
    { lat: 48.5122955, lng: 21.6777677 },
    { lat: 48.5115587, lng: 21.6779393 },
    { lat: 48.5100352, lng: 21.6779007 },
    { lat: 48.5085506, lng: 21.6784977 },
    { lat: 48.5078116, lng: 21.67859 },
    { lat: 48.5073458, lng: 21.678506 },
    { lat: 48.5068167, lng: 21.6786186 },
    { lat: 48.5066793, lng: 21.6784913 },
    { lat: 48.5065181, lng: 21.6785612 },
    { lat: 48.5063515, lng: 21.6785215 },
    { lat: 48.5061317, lng: 21.6785884 },
    { lat: 48.5060383, lng: 21.6784579 },
    { lat: 48.5058462, lng: 21.6785131 },
    { lat: 48.5056069, lng: 21.6784882 },
    { lat: 48.5043962, lng: 21.6793087 },
    { lat: 48.5038921, lng: 21.6799718 },
    { lat: 48.503307, lng: 21.6804235 },
    { lat: 48.5030787, lng: 21.6807456 },
    { lat: 48.5028236, lng: 21.6809711 },
    { lat: 48.5018186, lng: 21.6812093 },
    { lat: 48.5009685, lng: 21.6818002 },
    { lat: 48.500323, lng: 21.6814825 },
    { lat: 48.5000874, lng: 21.6814268 },
    { lat: 48.4986087, lng: 21.6816663 },
    { lat: 48.4982344, lng: 21.6819698 },
    { lat: 48.4977915, lng: 21.6825634 },
    { lat: 48.4962669, lng: 21.6825148 },
    { lat: 48.4954276, lng: 21.6822765 },
    { lat: 48.4949189, lng: 21.6819792 },
    { lat: 48.4945733, lng: 21.6819367 },
    { lat: 48.494321, lng: 21.6820488 },
    { lat: 48.4942068, lng: 21.6821055 },
    { lat: 48.4941997, lng: 21.6823021 },
    { lat: 48.4942979, lng: 21.682522 },
    { lat: 48.4949436, lng: 21.6834587 },
    { lat: 48.4949624, lng: 21.6842333 },
    { lat: 48.4953362, lng: 21.6860095 },
    { lat: 48.4952581, lng: 21.6866103 },
    { lat: 48.4952185, lng: 21.687761 },
    { lat: 48.4949395, lng: 21.6895718 },
    { lat: 48.494938, lng: 21.6898866 },
    { lat: 48.4948376, lng: 21.6904163 },
    { lat: 48.4946961, lng: 21.6906308 },
    { lat: 48.4944987, lng: 21.6912773 },
    { lat: 48.494537, lng: 21.6919386 },
    { lat: 48.4944927, lng: 21.6921748 },
    { lat: 48.4943595, lng: 21.6923983 },
    { lat: 48.4945555, lng: 21.6933614 },
    { lat: 48.4944116, lng: 21.6951865 },
    { lat: 48.4944046, lng: 21.6960369 },
    { lat: 48.4945472, lng: 21.6976397 },
    { lat: 48.4945519, lng: 21.6976894 },
  ],
  Bačka: [
    { lat: 48.4272195, lng: 22.0327353 },
    { lat: 48.4271722, lng: 22.033475 },
    { lat: 48.4269653, lng: 22.0347254 },
    { lat: 48.4264347, lng: 22.0365047 },
    { lat: 48.4259202, lng: 22.0379164 },
    { lat: 48.425118, lng: 22.0397718 },
    { lat: 48.4258867, lng: 22.0405266 },
    { lat: 48.4257816, lng: 22.0436108 },
    { lat: 48.4260206, lng: 22.0443769 },
    { lat: 48.4264148, lng: 22.046294 },
    { lat: 48.4268302, lng: 22.0471058 },
    { lat: 48.4269488, lng: 22.0473951 },
    { lat: 48.4269832, lng: 22.0476121 },
    { lat: 48.4262116, lng: 22.0505024 },
    { lat: 48.4254001, lng: 22.0521576 },
    { lat: 48.4255261, lng: 22.0533699 },
    { lat: 48.4269022, lng: 22.0591092 },
    { lat: 48.4269961, lng: 22.0593687 },
    { lat: 48.4269828, lng: 22.061105 },
    { lat: 48.4270816, lng: 22.0621757 },
    { lat: 48.4271587, lng: 22.0625746 },
    { lat: 48.4274982, lng: 22.0633131 },
    { lat: 48.4275433, lng: 22.0633648 },
    { lat: 48.4276981, lng: 22.06348 },
    { lat: 48.4288375, lng: 22.0638057 },
    { lat: 48.429793, lng: 22.0636797 },
    { lat: 48.4301863, lng: 22.0637551 },
    { lat: 48.43033, lng: 22.0653264 },
    { lat: 48.430517, lng: 22.0660952 },
    { lat: 48.431009, lng: 22.0667146 },
    { lat: 48.4312806, lng: 22.0677633 },
    { lat: 48.4313545, lng: 22.068269 },
    { lat: 48.431636, lng: 22.0694989 },
    { lat: 48.4320321, lng: 22.0705344 },
    { lat: 48.4332676, lng: 22.0727056 },
    { lat: 48.433442, lng: 22.0734514 },
    { lat: 48.4334625, lng: 22.0739792 },
    { lat: 48.4337101, lng: 22.0749673 },
    { lat: 48.4338614, lng: 22.0753225 },
    { lat: 48.4341116, lng: 22.0751089 },
    { lat: 48.4343779, lng: 22.0750456 },
    { lat: 48.4367553, lng: 22.074732 },
    { lat: 48.4369966, lng: 22.072977 },
    { lat: 48.4370948, lng: 22.071528 },
    { lat: 48.4362006, lng: 22.071298 },
    { lat: 48.4354664, lng: 22.0704295 },
    { lat: 48.4352904, lng: 22.0700291 },
    { lat: 48.4351816, lng: 22.0696237 },
    { lat: 48.4351383, lng: 22.0689976 },
    { lat: 48.4351743, lng: 22.0684338 },
    { lat: 48.4352653, lng: 22.068189 },
    { lat: 48.4356886, lng: 22.067646 },
    { lat: 48.4362398, lng: 22.0670319 },
    { lat: 48.4364398, lng: 22.0668765 },
    { lat: 48.4384771, lng: 22.0661747 },
    { lat: 48.4390715, lng: 22.0662505 },
    { lat: 48.4394646, lng: 22.066215 },
    { lat: 48.440045, lng: 22.0660293 },
    { lat: 48.4404897, lng: 22.0656693 },
    { lat: 48.4408449, lng: 22.0651395 },
    { lat: 48.4412094, lng: 22.0647214 },
    { lat: 48.44157, lng: 22.064 },
    { lat: 48.4419547, lng: 22.0629232 },
    { lat: 48.4422367, lng: 22.0619644 },
    { lat: 48.4423514, lng: 22.0609912 },
    { lat: 48.44244, lng: 22.0592988 },
    { lat: 48.4423969, lng: 22.0570389 },
    { lat: 48.4424516, lng: 22.0566986 },
    { lat: 48.442953, lng: 22.0556997 },
    { lat: 48.4432835, lng: 22.0553674 },
    { lat: 48.4436582, lng: 22.0551369 },
    { lat: 48.4445238, lng: 22.0564659 },
    { lat: 48.4447241, lng: 22.0568349 },
    { lat: 48.4449766, lng: 22.0575113 },
    { lat: 48.4450848, lng: 22.0582634 },
    { lat: 48.4450863, lng: 22.0597759 },
    { lat: 48.4451748, lng: 22.0605506 },
    { lat: 48.4451093, lng: 22.0613366 },
    { lat: 48.4451367, lng: 22.0616341 },
    { lat: 48.4452562, lng: 22.0621123 },
    { lat: 48.4452617, lng: 22.0623388 },
    { lat: 48.4461561, lng: 22.0647351 },
    { lat: 48.4461059, lng: 22.064791 },
    { lat: 48.4461673, lng: 22.0650112 },
    { lat: 48.4470914, lng: 22.0676537 },
    { lat: 48.4476146, lng: 22.0692981 },
    { lat: 48.4480874, lng: 22.0690783 },
    { lat: 48.4491654, lng: 22.0711488 },
    { lat: 48.4493947, lng: 22.0714726 },
    { lat: 48.4499137, lng: 22.0720111 },
    { lat: 48.4503408, lng: 22.0723207 },
    { lat: 48.4513291, lng: 22.0731928 },
    { lat: 48.4518637, lng: 22.0735735 },
    { lat: 48.4522657, lng: 22.0737757 },
    { lat: 48.4526857, lng: 22.073888 },
    { lat: 48.4528474, lng: 22.0740642 },
    { lat: 48.4531814, lng: 22.0741513 },
    { lat: 48.4549228, lng: 22.0750887 },
    { lat: 48.4569706, lng: 22.077178 },
    { lat: 48.4603261, lng: 22.0792432 },
    { lat: 48.4611167, lng: 22.079468 },
    { lat: 48.461235, lng: 22.0787659 },
    { lat: 48.4614688, lng: 22.078056 },
    { lat: 48.4616859, lng: 22.0779054 },
    { lat: 48.4618172, lng: 22.0778979 },
    { lat: 48.4623968, lng: 22.0782401 },
    { lat: 48.4626292, lng: 22.0785023 },
    { lat: 48.4629335, lng: 22.0789455 },
    { lat: 48.4632719, lng: 22.0795929 },
    { lat: 48.4635419, lng: 22.0802578 },
    { lat: 48.4637344, lng: 22.0805019 },
    { lat: 48.4639432, lng: 22.0806178 },
    { lat: 48.4643518, lng: 22.0805412 },
    { lat: 48.464626, lng: 22.0802481 },
    { lat: 48.464725, lng: 22.0800077 },
    { lat: 48.4647411, lng: 22.0795486 },
    { lat: 48.4650196, lng: 22.0796203 },
    { lat: 48.466123, lng: 22.0802321 },
    { lat: 48.4678555, lng: 22.080986 },
    { lat: 48.4695137, lng: 22.0815019 },
    { lat: 48.472111, lng: 22.083105 },
    { lat: 48.4722473, lng: 22.083344 },
    { lat: 48.4724056, lng: 22.0834762 },
    { lat: 48.4743289, lng: 22.0847288 },
    { lat: 48.4744617, lng: 22.0848734 },
    { lat: 48.475492, lng: 22.0868552 },
    { lat: 48.4759993, lng: 22.0873221 },
    { lat: 48.4764033, lng: 22.0868057 },
    { lat: 48.476322, lng: 22.0866109 },
    { lat: 48.4762745, lng: 22.0863163 },
    { lat: 48.4768843, lng: 22.0836279 },
    { lat: 48.4768941, lng: 22.0830984 },
    { lat: 48.4768137, lng: 22.0824189 },
    { lat: 48.4764673, lng: 22.0825134 },
    { lat: 48.4762701, lng: 22.082311 },
    { lat: 48.4761062, lng: 22.0823259 },
    { lat: 48.4758783, lng: 22.0820596 },
    { lat: 48.4758089, lng: 22.0818903 },
    { lat: 48.4757755, lng: 22.0808168 },
    { lat: 48.4755322, lng: 22.0806697 },
    { lat: 48.4739206, lng: 22.0812028 },
    { lat: 48.4737401, lng: 22.0811764 },
    { lat: 48.4736778, lng: 22.0809752 },
    { lat: 48.4736751, lng: 22.0799185 },
    { lat: 48.4736072, lng: 22.0796187 },
    { lat: 48.473087, lng: 22.0795057 },
    { lat: 48.4730249, lng: 22.0787912 },
    { lat: 48.473215, lng: 22.0780839 },
    { lat: 48.4735826, lng: 22.0775543 },
    { lat: 48.4735383, lng: 22.0772532 },
    { lat: 48.4732867, lng: 22.0766063 },
    { lat: 48.4732525, lng: 22.0759364 },
    { lat: 48.4729638, lng: 22.0755255 },
    { lat: 48.4728679, lng: 22.0752308 },
    { lat: 48.4731873, lng: 22.0748586 },
    { lat: 48.4732199, lng: 22.0746228 },
    { lat: 48.4726422, lng: 22.0747364 },
    { lat: 48.472296, lng: 22.0746027 },
    { lat: 48.4720369, lng: 22.0744022 },
    { lat: 48.4721259, lng: 22.0736983 },
    { lat: 48.4718234, lng: 22.0736169 },
    { lat: 48.4717734, lng: 22.0733112 },
    { lat: 48.4718312, lng: 22.0727635 },
    { lat: 48.471918, lng: 22.0725411 },
    { lat: 48.4719256, lng: 22.0721483 },
    { lat: 48.4720301, lng: 22.0714942 },
    { lat: 48.47198, lng: 22.0711321 },
    { lat: 48.4715987, lng: 22.0709022 },
    { lat: 48.4712466, lng: 22.0704317 },
    { lat: 48.4709216, lng: 22.0703968 },
    { lat: 48.4707085, lng: 22.0704662 },
    { lat: 48.4704361, lng: 22.0713062 },
    { lat: 48.4701347, lng: 22.0718217 },
    { lat: 48.4695142, lng: 22.0722237 },
    { lat: 48.4691503, lng: 22.0722434 },
    { lat: 48.4685993, lng: 22.0721123 },
    { lat: 48.4684363, lng: 22.0720184 },
    { lat: 48.4677249, lng: 22.0709183 },
    { lat: 48.4671528, lng: 22.0704768 },
    { lat: 48.4663034, lng: 22.0703287 },
    { lat: 48.465744, lng: 22.070454 },
    { lat: 48.4654496, lng: 22.0706125 },
    { lat: 48.4651351, lng: 22.0700248 },
    { lat: 48.4646571, lng: 22.069798 },
    { lat: 48.4646305, lng: 22.0695772 },
    { lat: 48.4645267, lng: 22.0693769 },
    { lat: 48.4641233, lng: 22.0688323 },
    { lat: 48.4628952, lng: 22.067539 },
    { lat: 48.462701, lng: 22.0674612 },
    { lat: 48.462446, lng: 22.0672394 },
    { lat: 48.4618861, lng: 22.0665974 },
    { lat: 48.4614598, lng: 22.0662828 },
    { lat: 48.4614222, lng: 22.066255 },
    { lat: 48.4605873, lng: 22.0658262 },
    { lat: 48.4581685, lng: 22.0640348 },
    { lat: 48.4571332, lng: 22.0633677 },
    { lat: 48.4572404, lng: 22.0627786 },
    { lat: 48.4566363, lng: 22.0607168 },
    { lat: 48.4558793, lng: 22.0590388 },
    { lat: 48.4564092, lng: 22.0583933 },
    { lat: 48.4570937, lng: 22.0572924 },
    { lat: 48.4564349, lng: 22.0560953 },
    { lat: 48.455744, lng: 22.0550113 },
    { lat: 48.4555343, lng: 22.054809 },
    { lat: 48.4552968, lng: 22.0541015 },
    { lat: 48.4552218, lng: 22.0538851 },
    { lat: 48.4550247, lng: 22.0541099 },
    { lat: 48.454953, lng: 22.054313 },
    { lat: 48.4536967, lng: 22.0547402 },
    { lat: 48.4533818, lng: 22.0546922 },
    { lat: 48.4524583, lng: 22.0543743 },
    { lat: 48.4524643, lng: 22.0542025 },
    { lat: 48.4511447, lng: 22.0542429 },
    { lat: 48.4508038, lng: 22.0541059 },
    { lat: 48.4506361, lng: 22.0538666 },
    { lat: 48.4500623, lng: 22.0527051 },
    { lat: 48.4497116, lng: 22.0526672 },
    { lat: 48.4493039, lng: 22.0522242 },
    { lat: 48.4490351, lng: 22.0518097 },
    { lat: 48.4482676, lng: 22.0501266 },
    { lat: 48.4477906, lng: 22.0484064 },
    { lat: 48.4473621, lng: 22.0475209 },
    { lat: 48.4470092, lng: 22.0470239 },
    { lat: 48.4465648, lng: 22.0467499 },
    { lat: 48.4460043, lng: 22.0462639 },
    { lat: 48.4440435, lng: 22.0451597 },
    { lat: 48.4421281, lng: 22.044385 },
    { lat: 48.4418658, lng: 22.04405 },
    { lat: 48.44152, lng: 22.0432919 },
    { lat: 48.4414587, lng: 22.0430381 },
    { lat: 48.4414215, lng: 22.0421933 },
    { lat: 48.4414608, lng: 22.0419105 },
    { lat: 48.4415649, lng: 22.0416642 },
    { lat: 48.4421171, lng: 22.0410044 },
    { lat: 48.4430769, lng: 22.0406907 },
    { lat: 48.4448699, lng: 22.0405449 },
    { lat: 48.4463834, lng: 22.0399036 },
    { lat: 48.4471558, lng: 22.0391532 },
    { lat: 48.4476361, lng: 22.0381748 },
    { lat: 48.4477518, lng: 22.036408 },
    { lat: 48.4476018, lng: 22.035232 },
    { lat: 48.4475166, lng: 22.0339461 },
    { lat: 48.4472826, lng: 22.0316812 },
    { lat: 48.4474751, lng: 22.0307592 },
    { lat: 48.447333, lng: 22.0306522 },
    { lat: 48.4454958, lng: 22.0300959 },
    { lat: 48.4442482, lng: 22.0297273 },
    { lat: 48.4438725, lng: 22.0296792 },
    { lat: 48.4438677, lng: 22.0294346 },
    { lat: 48.4434768, lng: 22.029376 },
    { lat: 48.4430129, lng: 22.0291734 },
    { lat: 48.44215, lng: 22.0286185 },
    { lat: 48.4417551, lng: 22.028287 },
    { lat: 48.441794, lng: 22.027618 },
    { lat: 48.4380612, lng: 22.0219897 },
    { lat: 48.4377794, lng: 22.0214157 },
    { lat: 48.4374921, lng: 22.0212186 },
    { lat: 48.4369566, lng: 22.0210282 },
    { lat: 48.4368007, lng: 22.0200317 },
    { lat: 48.4364489, lng: 22.0200004 },
    { lat: 48.436165, lng: 22.0202655 },
    { lat: 48.4350011, lng: 22.0216143 },
    { lat: 48.4350803, lng: 22.022283 },
    { lat: 48.4352144, lng: 22.0228031 },
    { lat: 48.4351173, lng: 22.0231993 },
    { lat: 48.4348317, lng: 22.023705 },
    { lat: 48.4346672, lng: 22.0241618 },
    { lat: 48.4345411, lng: 22.0250782 },
    { lat: 48.4341584, lng: 22.0257146 },
    { lat: 48.4338832, lng: 22.0263277 },
    { lat: 48.4336178, lng: 22.026709 },
    { lat: 48.4332689, lng: 22.026979 },
    { lat: 48.4330031, lng: 22.0279324 },
    { lat: 48.4327952, lng: 22.0284558 },
    { lat: 48.4322729, lng: 22.0293191 },
    { lat: 48.4318168, lng: 22.0288766 },
    { lat: 48.4316357, lng: 22.0286035 },
    { lat: 48.4311885, lng: 22.0275686 },
    { lat: 48.4308671, lng: 22.027989 },
    { lat: 48.4304821, lng: 22.0288579 },
    { lat: 48.4302019, lng: 22.0293182 },
    { lat: 48.4298858, lng: 22.0300543 },
    { lat: 48.4289211, lng: 22.0319302 },
    { lat: 48.4285926, lng: 22.0324382 },
    { lat: 48.4284787, lng: 22.0325656 },
    { lat: 48.4276359, lng: 22.0327 },
    { lat: 48.4272195, lng: 22.0327353 },
  ],
  Novosad: [
    { lat: 48.5063229, lng: 21.7513414 },
    { lat: 48.5064882, lng: 21.7512974 },
    { lat: 48.5065271, lng: 21.7526209 },
    { lat: 48.5066932, lng: 21.7525633 },
    { lat: 48.506681, lng: 21.7517714 },
    { lat: 48.5075799, lng: 21.7517692 },
    { lat: 48.5076541, lng: 21.7523114 },
    { lat: 48.5092395, lng: 21.7520264 },
    { lat: 48.5093776, lng: 21.7530215 },
    { lat: 48.5094462, lng: 21.7530201 },
    { lat: 48.5096039, lng: 21.7540872 },
    { lat: 48.509697, lng: 21.7540666 },
    { lat: 48.5097374, lng: 21.7545322 },
    { lat: 48.510153, lng: 21.754335 },
    { lat: 48.5102368, lng: 21.7546915 },
    { lat: 48.5105039, lng: 21.7545415 },
    { lat: 48.5105298, lng: 21.7546727 },
    { lat: 48.5106265, lng: 21.7546271 },
    { lat: 48.5107324, lng: 21.7550983 },
    { lat: 48.5111672, lng: 21.7549396 },
    { lat: 48.5116116, lng: 21.7572636 },
    { lat: 48.5117741, lng: 21.7572389 },
    { lat: 48.5120787, lng: 21.7587502 },
    { lat: 48.5124239, lng: 21.7584863 },
    { lat: 48.5126298, lng: 21.7589064 },
    { lat: 48.5128382, lng: 21.7587874 },
    { lat: 48.5131865, lng: 21.7597816 },
    { lat: 48.5135219, lng: 21.7612663 },
    { lat: 48.5133475, lng: 21.7613492 },
    { lat: 48.5141513, lng: 21.7657616 },
    { lat: 48.5143371, lng: 21.76649 },
    { lat: 48.5147201, lng: 21.7688771 },
    { lat: 48.5148446, lng: 21.7688157 },
    { lat: 48.515218, lng: 21.7708912 },
    { lat: 48.5150039, lng: 21.7710062 },
    { lat: 48.5153587, lng: 21.7731812 },
    { lat: 48.515767, lng: 21.774788 },
    { lat: 48.5160051, lng: 21.7746455 },
    { lat: 48.5164468, lng: 21.7756439 },
    { lat: 48.517888, lng: 21.7756872 },
    { lat: 48.5180328, lng: 21.7758396 },
    { lat: 48.5190125, lng: 21.7761729 },
    { lat: 48.5193682, lng: 21.7766423 },
    { lat: 48.5195348, lng: 21.7767739 },
    { lat: 48.5204632, lng: 21.777297 },
    { lat: 48.5206407, lng: 21.7779614 },
    { lat: 48.5220495, lng: 21.7779318 },
    { lat: 48.5217794, lng: 21.7748646 },
    { lat: 48.5218771, lng: 21.7741833 },
    { lat: 48.5223256, lng: 21.7733617 },
    { lat: 48.5226329, lng: 21.7729375 },
    { lat: 48.5229051, lng: 21.7728548 },
    { lat: 48.5233228, lng: 21.7729053 },
    { lat: 48.523251, lng: 21.7723247 },
    { lat: 48.5232826, lng: 21.7723228 },
    { lat: 48.5232921, lng: 21.7719602 },
    { lat: 48.5235552, lng: 21.7718262 },
    { lat: 48.5234785, lng: 21.7710631 },
    { lat: 48.5241608, lng: 21.770654 },
    { lat: 48.5241833, lng: 21.7705231 },
    { lat: 48.5249355, lng: 21.7700025 },
    { lat: 48.5245506, lng: 21.7672026 },
    { lat: 48.5270961, lng: 21.7665025 },
    { lat: 48.5271324, lng: 21.7666785 },
    { lat: 48.5278232, lng: 21.7665098 },
    { lat: 48.5289501, lng: 21.7660756 },
    { lat: 48.529977, lng: 21.7659377 },
    { lat: 48.5306867, lng: 21.7657551 },
    { lat: 48.5315333, lng: 21.7653104 },
    { lat: 48.5346885, lng: 21.7639706 },
    { lat: 48.5365198, lng: 21.7625199 },
    { lat: 48.5377152, lng: 21.761398 },
    { lat: 48.5386226, lng: 21.7609558 },
    { lat: 48.5396092, lng: 21.7606161 },
    { lat: 48.5403955, lng: 21.7601017 },
    { lat: 48.5410395, lng: 21.7595672 },
    { lat: 48.5411524, lng: 21.761151 },
    { lat: 48.5440663, lng: 21.7603831 },
    { lat: 48.5440998, lng: 21.760544 },
    { lat: 48.5445964, lng: 21.7605993 },
    { lat: 48.5448158, lng: 21.7606837 },
    { lat: 48.5450137, lng: 21.7611045 },
    { lat: 48.5470634, lng: 21.7602876 },
    { lat: 48.5484937, lng: 21.7599249 },
    { lat: 48.5499277, lng: 21.7593947 },
    { lat: 48.5505948, lng: 21.758718 },
    { lat: 48.550114, lng: 21.7583622 },
    { lat: 48.5491919, lng: 21.7584655 },
    { lat: 48.5488901, lng: 21.7576155 },
    { lat: 48.5514235, lng: 21.7565873 },
    { lat: 48.5511492, lng: 21.7556262 },
    { lat: 48.5516087, lng: 21.7543825 },
    { lat: 48.5575117, lng: 21.7535996 },
    { lat: 48.5576415, lng: 21.754128 },
    { lat: 48.5594117, lng: 21.7537228 },
    { lat: 48.5594442, lng: 21.7539713 },
    { lat: 48.5600329, lng: 21.7539328 },
    { lat: 48.5607901, lng: 21.7503084 },
    { lat: 48.5616005, lng: 21.7472529 },
    { lat: 48.5645007, lng: 21.7463118 },
    { lat: 48.5644917, lng: 21.7459734 },
    { lat: 48.5644779, lng: 21.7454578 },
    { lat: 48.5644006, lng: 21.7450089 },
    { lat: 48.564391, lng: 21.7442703 },
    { lat: 48.5646486, lng: 21.7431328 },
    { lat: 48.564779, lng: 21.7421203 },
    { lat: 48.5646573, lng: 21.74144 },
    { lat: 48.5640264, lng: 21.739101 },
    { lat: 48.5637456, lng: 21.7392051 },
    { lat: 48.5632435, lng: 21.7400356 },
    { lat: 48.5625855, lng: 21.7401694 },
    { lat: 48.5623955, lng: 21.7402928 },
    { lat: 48.562166, lng: 21.740754 },
    { lat: 48.561962, lng: 21.7409987 },
    { lat: 48.5619141, lng: 21.7414367 },
    { lat: 48.5619216, lng: 21.7420525 },
    { lat: 48.5616549, lng: 21.7424747 },
    { lat: 48.5613693, lng: 21.7426903 },
    { lat: 48.5610277, lng: 21.742699 },
    { lat: 48.5602634, lng: 21.743319 },
    { lat: 48.560002, lng: 21.7436464 },
    { lat: 48.5593997, lng: 21.7441341 },
    { lat: 48.5592002, lng: 21.7440365 },
    { lat: 48.5589167, lng: 21.7437852 },
    { lat: 48.5586753, lng: 21.7431349 },
    { lat: 48.5585364, lng: 21.7430265 },
    { lat: 48.5583557, lng: 21.7430425 },
    { lat: 48.5575168, lng: 21.7433112 },
    { lat: 48.5571159, lng: 21.7438885 },
    { lat: 48.5564251, lng: 21.7445602 },
    { lat: 48.5554614, lng: 21.7450815 },
    { lat: 48.5553536, lng: 21.7447563 },
    { lat: 48.5551176, lng: 21.7435042 },
    { lat: 48.5549751, lng: 21.7430583 },
    { lat: 48.554318, lng: 21.7417916 },
    { lat: 48.5542438, lng: 21.7414144 },
    { lat: 48.5541226, lng: 21.7413098 },
    { lat: 48.5540893, lng: 21.741281 },
    { lat: 48.5539918, lng: 21.7411285 },
    { lat: 48.5539174, lng: 21.7410002 },
    { lat: 48.5535663, lng: 21.7398198 },
    { lat: 48.5532498, lng: 21.7392147 },
    { lat: 48.5527123, lng: 21.738437 },
    { lat: 48.5520893, lng: 21.7383165 },
    { lat: 48.5517823, lng: 21.7379911 },
    { lat: 48.5516337, lng: 21.7376894 },
    { lat: 48.5514264, lng: 21.7366316 },
    { lat: 48.5499435, lng: 21.7357116 },
    { lat: 48.5491637, lng: 21.7346998 },
    { lat: 48.5484134, lng: 21.7342818 },
    { lat: 48.5466844, lng: 21.7335058 },
    { lat: 48.5457003, lng: 21.7325942 },
    { lat: 48.5445171, lng: 21.7313526 },
    { lat: 48.5421697, lng: 21.7294538 },
    { lat: 48.5419937, lng: 21.7295217 },
    { lat: 48.5417226, lng: 21.7294004 },
    { lat: 48.5414833, lng: 21.7294516 },
    { lat: 48.5413454, lng: 21.7295598 },
    { lat: 48.5412167, lng: 21.72983 },
    { lat: 48.5410846, lng: 21.7299407 },
    { lat: 48.5409121, lng: 21.7299264 },
    { lat: 48.5403675, lng: 21.7295964 },
    { lat: 48.5399706, lng: 21.7296898 },
    { lat: 48.5397888, lng: 21.7296601 },
    { lat: 48.5396671, lng: 21.7293625 },
    { lat: 48.5396371, lng: 21.7285027 },
    { lat: 48.5394529, lng: 21.7284269 },
    { lat: 48.5392937, lng: 21.7284438 },
    { lat: 48.5394261, lng: 21.728097 },
    { lat: 48.5390983, lng: 21.7279547 },
    { lat: 48.5388947, lng: 21.7277495 },
    { lat: 48.5380209, lng: 21.7266359 },
    { lat: 48.5378569, lng: 21.7260626 },
    { lat: 48.5376455, lng: 21.7255319 },
    { lat: 48.5374817, lng: 21.7253328 },
    { lat: 48.5372077, lng: 21.7251443 },
    { lat: 48.536877, lng: 21.7243544 },
    { lat: 48.5370188, lng: 21.7236034 },
    { lat: 48.5372591, lng: 21.7234644 },
    { lat: 48.5373204, lng: 21.7233169 },
    { lat: 48.5373244, lng: 21.7230035 },
    { lat: 48.5374732, lng: 21.7226369 },
    { lat: 48.537378, lng: 21.7224886 },
    { lat: 48.5371624, lng: 21.72238 },
    { lat: 48.537154, lng: 21.7221899 },
    { lat: 48.5374473, lng: 21.7219288 },
    { lat: 48.5375312, lng: 21.721788 },
    { lat: 48.5375471, lng: 21.7216269 },
    { lat: 48.5375328, lng: 21.7215152 },
    { lat: 48.5369852, lng: 21.7211862 },
    { lat: 48.5368078, lng: 21.7209131 },
    { lat: 48.5368241, lng: 21.7207662 },
    { lat: 48.536907, lng: 21.7207519 },
    { lat: 48.5370055, lng: 21.7205669 },
    { lat: 48.5370107, lng: 21.7203855 },
    { lat: 48.5369538, lng: 21.7203187 },
    { lat: 48.5365538, lng: 21.7202101 },
    { lat: 48.5365169, lng: 21.7200998 },
    { lat: 48.5365107, lng: 21.7199507 },
    { lat: 48.5368567, lng: 21.719763 },
    { lat: 48.5370781, lng: 21.7194644 },
    { lat: 48.5371237, lng: 21.7191914 },
    { lat: 48.5370629, lng: 21.7190687 },
    { lat: 48.5367609, lng: 21.7189654 },
    { lat: 48.536444, lng: 21.718485 },
    { lat: 48.536104, lng: 21.718177 },
    { lat: 48.5362253, lng: 21.7177187 },
    { lat: 48.5361454, lng: 21.7174236 },
    { lat: 48.5364459, lng: 21.7165102 },
    { lat: 48.5364106, lng: 21.7161999 },
    { lat: 48.535967, lng: 21.7160308 },
    { lat: 48.5356863, lng: 21.7160491 },
    { lat: 48.5355353, lng: 21.7156611 },
    { lat: 48.5352504, lng: 21.7153618 },
    { lat: 48.5350218, lng: 21.7154785 },
    { lat: 48.5349221, lng: 21.7154544 },
    { lat: 48.5348316, lng: 21.7147314 },
    { lat: 48.5344774, lng: 21.7145717 },
    { lat: 48.5344306, lng: 21.714586 },
    { lat: 48.5344038, lng: 21.7147197 },
    { lat: 48.53457, lng: 21.7151302 },
    { lat: 48.5345245, lng: 21.7151908 },
    { lat: 48.5341761, lng: 21.7152001 },
    { lat: 48.5342149, lng: 21.7155556 },
    { lat: 48.5340283, lng: 21.7158564 },
    { lat: 48.5338102, lng: 21.7160284 },
    { lat: 48.5332209, lng: 21.7154582 },
    { lat: 48.5330907, lng: 21.7154676 },
    { lat: 48.5329623, lng: 21.715671 },
    { lat: 48.5329652, lng: 21.7161096 },
    { lat: 48.5328546, lng: 21.7161761 },
    { lat: 48.5326374, lng: 21.7161397 },
    { lat: 48.5324949, lng: 21.7157813 },
    { lat: 48.5323492, lng: 21.7156316 },
    { lat: 48.5322289, lng: 21.7153858 },
    { lat: 48.5320623, lng: 21.7152573 },
    { lat: 48.5320389, lng: 21.7148451 },
    { lat: 48.5319859, lng: 21.7148051 },
    { lat: 48.5317876, lng: 21.714917 },
    { lat: 48.5315551, lng: 21.7148442 },
    { lat: 48.5313336, lng: 21.7146494 },
    { lat: 48.5311949, lng: 21.7146736 },
    { lat: 48.5310788, lng: 21.7143807 },
    { lat: 48.5306758, lng: 21.7140831 },
    { lat: 48.5306113, lng: 21.7139806 },
    { lat: 48.5307278, lng: 21.7137998 },
    { lat: 48.5306906, lng: 21.7134634 },
    { lat: 48.5305007, lng: 21.7129696 },
    { lat: 48.5302799, lng: 21.7131642 },
    { lat: 48.5298721, lng: 21.7129844 },
    { lat: 48.5297394, lng: 21.7127777 },
    { lat: 48.5292581, lng: 21.7123323 },
    { lat: 48.5289903, lng: 21.7124524 },
    { lat: 48.5288259, lng: 21.7118228 },
    { lat: 48.528615, lng: 21.7116437 },
    { lat: 48.5285635, lng: 21.7113622 },
    { lat: 48.5288162, lng: 21.7108046 },
    { lat: 48.5288157, lng: 21.7105342 },
    { lat: 48.5287351, lng: 21.7103411 },
    { lat: 48.5286042, lng: 21.7103324 },
    { lat: 48.5282933, lng: 21.7105251 },
    { lat: 48.5281935, lng: 21.7104538 },
    { lat: 48.5283852, lng: 21.7098006 },
    { lat: 48.5283609, lng: 21.7096115 },
    { lat: 48.5280792, lng: 21.7095933 },
    { lat: 48.527855, lng: 21.7097952 },
    { lat: 48.5277554, lng: 21.7097289 },
    { lat: 48.5279306, lng: 21.7091821 },
    { lat: 48.5278596, lng: 21.7090491 },
    { lat: 48.5276872, lng: 21.7091634 },
    { lat: 48.5275696, lng: 21.7095227 },
    { lat: 48.5273244, lng: 21.7095211 },
    { lat: 48.5272511, lng: 21.7091827 },
    { lat: 48.5270501, lng: 21.7090289 },
    { lat: 48.5267166, lng: 21.7093923 },
    { lat: 48.5265696, lng: 21.7092756 },
    { lat: 48.5262866, lng: 21.7093413 },
    { lat: 48.5263698, lng: 21.7090007 },
    { lat: 48.5262006, lng: 21.7086562 },
    { lat: 48.5261806, lng: 21.7084995 },
    { lat: 48.5257394, lng: 21.7082007 },
    { lat: 48.5253726, lng: 21.7080805 },
    { lat: 48.5252097, lng: 21.7092061 },
    { lat: 48.5251089, lng: 21.7099758 },
    { lat: 48.5259666, lng: 21.7110271 },
    { lat: 48.5260031, lng: 21.7124972 },
    { lat: 48.5258405, lng: 21.7145566 },
    { lat: 48.5254926, lng: 21.7170906 },
    { lat: 48.5252617, lng: 21.7191574 },
    { lat: 48.525232, lng: 21.7211831 },
    { lat: 48.524851, lng: 21.7213665 },
    { lat: 48.5247442, lng: 21.7212907 },
    { lat: 48.5247444, lng: 21.721176 },
    { lat: 48.5246515, lng: 21.7210596 },
    { lat: 48.5244517, lng: 21.7209615 },
    { lat: 48.5243572, lng: 21.7210844 },
    { lat: 48.524308, lng: 21.7238495 },
    { lat: 48.5243549, lng: 21.7249615 },
    { lat: 48.5244604, lng: 21.7260387 },
    { lat: 48.5240372, lng: 21.7261994 },
    { lat: 48.5230291, lng: 21.7264107 },
    { lat: 48.5196171, lng: 21.7269736 },
    { lat: 48.5190418, lng: 21.7271638 },
    { lat: 48.5179347, lng: 21.7276672 },
    { lat: 48.5161144, lng: 21.7280444 },
    { lat: 48.5131648, lng: 21.7289423 },
    { lat: 48.511102, lng: 21.7297661 },
    { lat: 48.509913, lng: 21.7303581 },
    { lat: 48.5082523, lng: 21.7306956 },
    { lat: 48.5073337, lng: 21.7408564 },
    { lat: 48.506636, lng: 21.7480263 },
    { lat: 48.5063229, lng: 21.7513414 },
  ],
  StredanadBodrogom: [
    { lat: 48.343031, lng: 21.75969 },
    { lat: 48.342771, lng: 21.760227 },
    { lat: 48.342623, lng: 21.760411 },
    { lat: 48.342396, lng: 21.760591 },
    { lat: 48.341486, lng: 21.760929 },
    { lat: 48.340973, lng: 21.761153 },
    { lat: 48.340762, lng: 21.76126 },
    { lat: 48.340617, lng: 21.761376 },
    { lat: 48.340399, lng: 21.761615 },
    { lat: 48.340222, lng: 21.76183 },
    { lat: 48.340045, lng: 21.762107 },
    { lat: 48.339958, lng: 21.762276 },
    { lat: 48.339846, lng: 21.762526 },
    { lat: 48.33977, lng: 21.762735 },
    { lat: 48.339681, lng: 21.763045 },
    { lat: 48.339622, lng: 21.76321 },
    { lat: 48.3395, lng: 21.763465 },
    { lat: 48.339036, lng: 21.764055 },
    { lat: 48.338993, lng: 21.764221 },
    { lat: 48.338995, lng: 21.764305 },
    { lat: 48.33899, lng: 21.764472 },
    { lat: 48.339006, lng: 21.764572 },
    { lat: 48.339025, lng: 21.765707 },
    { lat: 48.3391589, lng: 21.765675 },
    { lat: 48.3396568, lng: 21.7656168 },
    { lat: 48.3399169, lng: 21.7659191 },
    { lat: 48.3403676, lng: 21.7666139 },
    { lat: 48.3406817, lng: 21.7668369 },
    { lat: 48.3410544, lng: 21.7668449 },
    { lat: 48.3415158, lng: 21.766506 },
    { lat: 48.3422085, lng: 21.7657815 },
    { lat: 48.3426483, lng: 21.7654308 },
    { lat: 48.3434487, lng: 21.7649462 },
    { lat: 48.3637422, lng: 21.7746658 },
    { lat: 48.3629519, lng: 21.7749063 },
    { lat: 48.3608515, lng: 21.7759729 },
    { lat: 48.3603906, lng: 21.776367 },
    { lat: 48.3600282, lng: 21.7768341 },
    { lat: 48.3603889, lng: 21.7774465 },
    { lat: 48.3608825, lng: 21.7785332 },
    { lat: 48.361142, lng: 21.7789475 },
    { lat: 48.3610608, lng: 21.7790673 },
    { lat: 48.3614273, lng: 21.7798981 },
    { lat: 48.3610188, lng: 21.7808692 },
    { lat: 48.3607996, lng: 21.7810921 },
    { lat: 48.3606455, lng: 21.7811304 },
    { lat: 48.3601875, lng: 21.781473 },
    { lat: 48.3593276, lng: 21.7823519 },
    { lat: 48.3592054, lng: 21.782535 },
    { lat: 48.3591284, lng: 21.7828155 },
    { lat: 48.3591657, lng: 21.7828589 },
    { lat: 48.3590698, lng: 21.7830217 },
    { lat: 48.3585289, lng: 21.7835444 },
    { lat: 48.3583471, lng: 21.7836416 },
    { lat: 48.357969, lng: 21.7837236 },
    { lat: 48.3574489, lng: 21.7836376 },
    { lat: 48.3573348, lng: 21.7833621 },
    { lat: 48.3572239, lng: 21.7833411 },
    { lat: 48.3571036, lng: 21.7831693 },
    { lat: 48.356877, lng: 21.7831142 },
    { lat: 48.3565819, lng: 21.7831435 },
    { lat: 48.3561744, lng: 21.7833444 },
    { lat: 48.3558405, lng: 21.7836544 },
    { lat: 48.3553202, lng: 21.7838075 },
    { lat: 48.3550413, lng: 21.7840654 },
    { lat: 48.3545777, lng: 21.7846959 },
    { lat: 48.3534995, lng: 21.7864727 },
    { lat: 48.3541002, lng: 21.7870128 },
    { lat: 48.3550375, lng: 21.7875257 },
    { lat: 48.3552565, lng: 21.7881479 },
    { lat: 48.3559632, lng: 21.7882837 },
    { lat: 48.356654, lng: 21.7883193 },
    { lat: 48.3568868, lng: 21.7887872 },
    { lat: 48.357199, lng: 21.7891447 },
    { lat: 48.3581281, lng: 21.7894342 },
    { lat: 48.3585871, lng: 21.7896888 },
    { lat: 48.3587441, lng: 21.7898425 },
    { lat: 48.3589391, lng: 21.7898946 },
    { lat: 48.3604811, lng: 21.7898709 },
    { lat: 48.3608484, lng: 21.7895476 },
    { lat: 48.3611973, lng: 21.7896464 },
    { lat: 48.3624506, lng: 21.789387 },
    { lat: 48.3635062, lng: 21.7900717 },
    { lat: 48.3642558, lng: 21.7908071 },
    { lat: 48.3646386, lng: 21.7912404 },
    { lat: 48.364906, lng: 21.7916971 },
    { lat: 48.3658257, lng: 21.7929416 },
    { lat: 48.3668317, lng: 21.7941381 },
    { lat: 48.3675763, lng: 21.7946012 },
    { lat: 48.3686066, lng: 21.7948431 },
    { lat: 48.3690147, lng: 21.795046 },
    { lat: 48.3693556, lng: 21.7950002 },
    { lat: 48.3705506, lng: 21.794025 },
    { lat: 48.3712043, lng: 21.7936807 },
    { lat: 48.3715427, lng: 21.7935859 },
    { lat: 48.3729063, lng: 21.7938875 },
    { lat: 48.3742952, lng: 21.7940741 },
    { lat: 48.3750005, lng: 21.7939886 },
    { lat: 48.3760073, lng: 21.7939828 },
    { lat: 48.3768056, lng: 21.7941688 },
    { lat: 48.376975, lng: 21.7940225 },
    { lat: 48.37714, lng: 21.7941362 },
    { lat: 48.3783354, lng: 21.7943244 },
    { lat: 48.3808285, lng: 21.7939806 },
    { lat: 48.3822999, lng: 21.7938466 },
    { lat: 48.3821786, lng: 21.794956 },
    { lat: 48.38204, lng: 21.795591 },
    { lat: 48.3815645, lng: 21.7985035 },
    { lat: 48.3813271, lng: 21.7985407 },
    { lat: 48.3806283, lng: 21.8010533 },
    { lat: 48.3804029, lng: 21.8023663 },
    { lat: 48.3809437, lng: 21.8024804 },
    { lat: 48.3815436, lng: 21.8030853 },
    { lat: 48.3819882, lng: 21.8026625 },
    { lat: 48.3832946, lng: 21.8018144 },
    { lat: 48.3859265, lng: 21.7999113 },
    { lat: 48.3864172, lng: 21.8019142 },
    { lat: 48.3868356, lng: 21.801403 },
    { lat: 48.3875069, lng: 21.8007447 },
    { lat: 48.3897005, lng: 21.7997074 },
    { lat: 48.3905026, lng: 21.7990201 },
    { lat: 48.3910445, lng: 21.7986654 },
    { lat: 48.3929751, lng: 21.7971678 },
    { lat: 48.3933081, lng: 21.7969157 },
    { lat: 48.3928009, lng: 21.7960641 },
    { lat: 48.3917375, lng: 21.7939726 },
    { lat: 48.3909859, lng: 21.7920983 },
    { lat: 48.3905791, lng: 21.7904545 },
    { lat: 48.390481, lng: 21.7890922 },
    { lat: 48.3904362, lng: 21.786544 },
    { lat: 48.3907683, lng: 21.7807405 },
    { lat: 48.3908753, lng: 21.7779867 },
    { lat: 48.3910399, lng: 21.7763474 },
    { lat: 48.3910703, lng: 21.7755916 },
    { lat: 48.3913499, lng: 21.774083 },
    { lat: 48.3918845, lng: 21.7721486 },
    { lat: 48.3922935, lng: 21.7712847 },
    { lat: 48.3925822, lng: 21.771016 },
    { lat: 48.3932946, lng: 21.7709339 },
    { lat: 48.3939771, lng: 21.7707089 },
    { lat: 48.3946812, lng: 21.7698969 },
    { lat: 48.3952772, lng: 21.7689924 },
    { lat: 48.3954743, lng: 21.768452 },
    { lat: 48.3961407, lng: 21.7661036 },
    { lat: 48.3963269, lng: 21.7651899 },
    { lat: 48.3965976, lng: 21.7633206 },
    { lat: 48.3967441, lng: 21.761811 },
    { lat: 48.3967786, lng: 21.7609153 },
    { lat: 48.3967971, lng: 21.7594177 },
    { lat: 48.3966683, lng: 21.7582613 },
    { lat: 48.3965093, lng: 21.7572789 },
    { lat: 48.396164, lng: 21.7542918 },
    { lat: 48.3954827, lng: 21.7504937 },
    { lat: 48.3954365, lng: 21.7497385 },
    { lat: 48.3950261, lng: 21.7462233 },
    { lat: 48.3949384, lng: 21.7442921 },
    { lat: 48.3947006, lng: 21.7430227 },
    { lat: 48.3942722, lng: 21.7419009 },
    { lat: 48.3935836, lng: 21.7409404 },
    { lat: 48.3932833, lng: 21.7406541 },
    { lat: 48.3927558, lng: 21.7406128 },
    { lat: 48.3921131, lng: 21.7409403 },
    { lat: 48.3910976, lng: 21.7419648 },
    { lat: 48.3899574, lng: 21.7433769 },
    { lat: 48.3881826, lng: 21.7459128 },
    { lat: 48.3871309, lng: 21.7470146 },
    { lat: 48.3861105, lng: 21.7478579 },
    { lat: 48.3833571, lng: 21.7494476 },
    { lat: 48.3827321, lng: 21.7498871 },
    { lat: 48.3821713, lng: 21.7503769 },
    { lat: 48.3816504, lng: 21.7509637 },
    { lat: 48.3804365, lng: 21.7529468 },
    { lat: 48.3800535, lng: 21.7534258 },
    { lat: 48.3794287, lng: 21.7538405 },
    { lat: 48.3790602, lng: 21.7539384 },
    { lat: 48.3784091, lng: 21.753616 },
    { lat: 48.3782031, lng: 21.7533181 },
    { lat: 48.3779439, lng: 21.752594 },
    { lat: 48.3777847, lng: 21.7517288 },
    { lat: 48.3777809, lng: 21.7504967 },
    { lat: 48.377846, lng: 21.7495683 },
    { lat: 48.3780271, lng: 21.7485214 },
    { lat: 48.3784574, lng: 21.7474496 },
    { lat: 48.3796208, lng: 21.7451874 },
    { lat: 48.381862, lng: 21.7418312 },
    { lat: 48.3827973, lng: 21.7402094 },
    { lat: 48.3831407, lng: 21.7394679 },
    { lat: 48.3832799, lng: 21.7390323 },
    { lat: 48.3820104, lng: 21.7390639 },
    { lat: 48.3806466, lng: 21.7392291 },
    { lat: 48.3760783, lng: 21.7404768 },
    { lat: 48.3740852, lng: 21.7314771 },
    { lat: 48.371329, lng: 21.7331209 },
    { lat: 48.3701593, lng: 21.7286809 },
    { lat: 48.366613, lng: 21.714302 },
    { lat: 48.366334, lng: 21.714188 },
    { lat: 48.366307, lng: 21.714266 },
    { lat: 48.365887, lng: 21.714557 },
    { lat: 48.365706, lng: 21.714568 },
    { lat: 48.364931, lng: 21.714513 },
    { lat: 48.363955, lng: 21.714755 },
    { lat: 48.36318, lng: 21.71443 },
    { lat: 48.362363, lng: 21.714013 },
    { lat: 48.361671, lng: 21.713663 },
    { lat: 48.361326, lng: 21.713511 },
    { lat: 48.360759, lng: 21.713422 },
    { lat: 48.359961, lng: 21.713028 },
    { lat: 48.359146, lng: 21.712631 },
    { lat: 48.35821, lng: 21.712171 },
    { lat: 48.357745, lng: 21.71196 },
    { lat: 48.357617, lng: 21.711871 },
    { lat: 48.357608, lng: 21.71189 },
    { lat: 48.35659, lng: 21.714509 },
    { lat: 48.3559, lng: 21.716303 },
    { lat: 48.35505, lng: 21.718499 },
    { lat: 48.354806, lng: 21.71909 },
    { lat: 48.354696, lng: 21.719303 },
    { lat: 48.354362, lng: 21.71979 },
    { lat: 48.354242, lng: 21.719931 },
    { lat: 48.353956, lng: 21.720158 },
    { lat: 48.353831, lng: 21.720242 },
    { lat: 48.352694, lng: 21.720866 },
    { lat: 48.351856, lng: 21.72132 },
    { lat: 48.3517276, lng: 21.7214196 },
    { lat: 48.351557, lng: 21.721552 },
    { lat: 48.351055, lng: 21.72197 },
    { lat: 48.350705, lng: 21.722385 },
    { lat: 48.350291, lng: 21.723053 },
    { lat: 48.350121, lng: 21.723363 },
    { lat: 48.349848, lng: 21.724003 },
    { lat: 48.349682, lng: 21.724435 },
    { lat: 48.349543, lng: 21.724932 },
    { lat: 48.349353, lng: 21.725786 },
    { lat: 48.349114, lng: 21.727283 },
    { lat: 48.349044, lng: 21.72821 },
    { lat: 48.349004, lng: 21.728652 },
    { lat: 48.348983, lng: 21.729086 },
    { lat: 48.348987, lng: 21.729418 },
    { lat: 48.349006, lng: 21.729669 },
    { lat: 48.349075, lng: 21.730472 },
    { lat: 48.34925, lng: 21.731969 },
    { lat: 48.349463, lng: 21.733779 },
    { lat: 48.349594, lng: 21.734926 },
    { lat: 48.349643, lng: 21.735345 },
    { lat: 48.349645, lng: 21.735438 },
    { lat: 48.34964, lng: 21.735767 },
    { lat: 48.349594, lng: 21.736293 },
    { lat: 48.349531, lng: 21.736676 },
    { lat: 48.349494, lng: 21.736871 },
    { lat: 48.3490249, lng: 21.7385821 },
    { lat: 48.348773, lng: 21.739554 },
    { lat: 48.348627, lng: 21.740356 },
    { lat: 48.348531, lng: 21.741187 },
    { lat: 48.348529, lng: 21.74137 },
    { lat: 48.348546, lng: 21.741533 },
    { lat: 48.348615, lng: 21.741639 },
    { lat: 48.348746, lng: 21.741797 },
    { lat: 48.348885, lng: 21.742032 },
    { lat: 48.348937, lng: 21.742131 },
    { lat: 48.348993, lng: 21.742331 },
    { lat: 48.349101, lng: 21.743268 },
    { lat: 48.349064, lng: 21.743652 },
    { lat: 48.348733, lng: 21.745009 },
    { lat: 48.348647, lng: 21.745251 },
    { lat: 48.348558, lng: 21.745404 },
    { lat: 48.348112, lng: 21.745993 },
    { lat: 48.347115, lng: 21.747278 },
    { lat: 48.346963, lng: 21.747581 },
    { lat: 48.346407, lng: 21.748983 },
    { lat: 48.346359, lng: 21.749188 },
    { lat: 48.346291, lng: 21.750113 },
    { lat: 48.346251, lng: 21.751176 },
    { lat: 48.346224, lng: 21.752446 },
    { lat: 48.346197, lng: 21.752826 },
    { lat: 48.346136, lng: 21.753277 },
    { lat: 48.345992, lng: 21.753803 },
    { lat: 48.345784, lng: 21.754364 },
    { lat: 48.345629, lng: 21.754712 },
    { lat: 48.345458, lng: 21.755002 },
    { lat: 48.345341, lng: 21.755166 },
    { lat: 48.344717, lng: 21.756297 },
    { lat: 48.34351, lng: 21.758512 },
    { lat: 48.343362, lng: 21.758788 },
    { lat: 48.343228, lng: 21.759043 },
    { lat: 48.343189, lng: 21.759089 },
    { lat: 48.343031, lng: 21.75969 },
  ],
  Dobrá: [
    { lat: 48.4257816, lng: 22.0436108 },
    { lat: 48.4258867, lng: 22.0405266 },
    { lat: 48.425118, lng: 22.0397718 },
    { lat: 48.4259202, lng: 22.0379164 },
    { lat: 48.4264347, lng: 22.0365047 },
    { lat: 48.4269653, lng: 22.0347254 },
    { lat: 48.4271722, lng: 22.033475 },
    { lat: 48.4272195, lng: 22.0327353 },
    { lat: 48.4271373, lng: 22.031487 },
    { lat: 48.4268799, lng: 22.0305685 },
    { lat: 48.4265303, lng: 22.0299282 },
    { lat: 48.4262376, lng: 22.0296028 },
    { lat: 48.4251792, lng: 22.0289675 },
    { lat: 48.4246589, lng: 22.0285243 },
    { lat: 48.4243946, lng: 22.0281634 },
    { lat: 48.424118, lng: 22.027608 },
    { lat: 48.4242079, lng: 22.0275138 },
    { lat: 48.4237319, lng: 22.0262663 },
    { lat: 48.4233143, lng: 22.0254686 },
    { lat: 48.423093, lng: 22.0251634 },
    { lat: 48.4222567, lng: 22.0235725 },
    { lat: 48.4220545, lng: 22.0230846 },
    { lat: 48.4197732, lng: 22.0205547 },
    { lat: 48.4196524, lng: 22.0205391 },
    { lat: 48.4192771, lng: 22.020184 },
    { lat: 48.4190498, lng: 22.0197284 },
    { lat: 48.4185361, lng: 22.0192375 },
    { lat: 48.4195504, lng: 22.0147159 },
    { lat: 48.4198706, lng: 22.0130889 },
    { lat: 48.4199675, lng: 22.0117625 },
    { lat: 48.419958, lng: 22.0112885 },
    { lat: 48.4197957, lng: 22.0098546 },
    { lat: 48.4193574, lng: 22.0075375 },
    { lat: 48.4192929, lng: 22.0070134 },
    { lat: 48.4193038, lng: 22.0062666 },
    { lat: 48.4192449, lng: 22.0062489 },
    { lat: 48.4178335, lng: 22.0027938 },
    { lat: 48.417727, lng: 22.0020787 },
    { lat: 48.4175667, lng: 22.0014058 },
    { lat: 48.4172137, lng: 22.0002369 },
    { lat: 48.4172326, lng: 21.9999961 },
    { lat: 48.4171499, lng: 21.999972 },
    { lat: 48.4169731, lng: 21.9992803 },
    { lat: 48.4166347, lng: 21.9973722 },
    { lat: 48.4162822, lng: 21.9972186 },
    { lat: 48.4161329, lng: 21.9973215 },
    { lat: 48.4160879, lng: 21.997551 },
    { lat: 48.4157472, lng: 21.998286 },
    { lat: 48.415426, lng: 21.9986718 },
    { lat: 48.4151438, lng: 21.9989127 },
    { lat: 48.4146629, lng: 21.9991615 },
    { lat: 48.4142929, lng: 21.9997196 },
    { lat: 48.414343, lng: 21.9998308 },
    { lat: 48.4144091, lng: 22.000688 },
    { lat: 48.4143173, lng: 22.0011624 },
    { lat: 48.4131385, lng: 22.0042828 },
    { lat: 48.4129959, lng: 22.0045255 },
    { lat: 48.4120544, lng: 22.005628 },
    { lat: 48.4109101, lng: 22.0061656 },
    { lat: 48.4104872, lng: 22.0062619 },
    { lat: 48.4106234, lng: 22.0080075 },
    { lat: 48.4100588, lng: 22.0082467 },
    { lat: 48.4093566, lng: 22.0089696 },
    { lat: 48.4077804, lng: 22.0100671 },
    { lat: 48.4072157, lng: 22.0097432 },
    { lat: 48.4071294, lng: 22.0097629 },
    { lat: 48.4076796, lng: 22.010954 },
    { lat: 48.4078676, lng: 22.0114694 },
    { lat: 48.4057842, lng: 22.0120187 },
    { lat: 48.4050783, lng: 22.0123247 },
    { lat: 48.4041452, lng: 22.0124864 },
    { lat: 48.4038934, lng: 22.0137335 },
    { lat: 48.4037188, lng: 22.0136822 },
    { lat: 48.4035549, lng: 22.0137373 },
    { lat: 48.4033791, lng: 22.0138628 },
    { lat: 48.4032581, lng: 22.0141588 },
    { lat: 48.4031547, lng: 22.0146616 },
    { lat: 48.4031281, lng: 22.0150985 },
    { lat: 48.4031657, lng: 22.0155269 },
    { lat: 48.4028055, lng: 22.0160754 },
    { lat: 48.4022082, lng: 22.0167627 },
    { lat: 48.4011085, lng: 22.0173427 },
    { lat: 48.4009002, lng: 22.0179352 },
    { lat: 48.4007855, lng: 22.0181228 },
    { lat: 48.4004935, lng: 22.0184126 },
    { lat: 48.4003572, lng: 22.0186806 },
    { lat: 48.3984762, lng: 22.0206069 },
    { lat: 48.3980128, lng: 22.0212587 },
    { lat: 48.3976963, lng: 22.021291 },
    { lat: 48.3972747, lng: 22.0208448 },
    { lat: 48.3967418, lng: 22.020579 },
    { lat: 48.3961825, lng: 22.0204385 },
    { lat: 48.3952901, lng: 22.0204243 },
    { lat: 48.3950569, lng: 22.0204977 },
    { lat: 48.3941102, lng: 22.0205311 },
    { lat: 48.3933381, lng: 22.0209691 },
    { lat: 48.3930597, lng: 22.0212719 },
    { lat: 48.3927331, lng: 22.0213621 },
    { lat: 48.392724, lng: 22.021382 },
    { lat: 48.39263, lng: 22.021757 },
    { lat: 48.39256, lng: 22.022113 },
    { lat: 48.392487, lng: 22.022558 },
    { lat: 48.392514, lng: 22.023865 },
    { lat: 48.392513, lng: 22.024992 },
    { lat: 48.39254, lng: 22.025768 },
    { lat: 48.392535, lng: 22.026129 },
    { lat: 48.3925, lng: 22.026832 },
    { lat: 48.392462, lng: 22.027251 },
    { lat: 48.392282, lng: 22.028177 },
    { lat: 48.392235, lng: 22.028315 },
    { lat: 48.39212, lng: 22.028484 },
    { lat: 48.392026, lng: 22.028611 },
    { lat: 48.391531, lng: 22.0292 },
    { lat: 48.391217, lng: 22.029543 },
    { lat: 48.390875, lng: 22.029911 },
    { lat: 48.390215, lng: 22.030656 },
    { lat: 48.389895, lng: 22.031028 },
    { lat: 48.388804, lng: 22.032206 },
    { lat: 48.388482, lng: 22.032539 },
    { lat: 48.388215, lng: 22.032789 },
    { lat: 48.387921, lng: 22.033126 },
    { lat: 48.387765, lng: 22.033334 },
    { lat: 48.387268, lng: 22.034125 },
    { lat: 48.386865, lng: 22.034795 },
    { lat: 48.386349, lng: 22.035613 },
    { lat: 48.386056, lng: 22.036134 },
    { lat: 48.38631, lng: 22.036476 },
    { lat: 48.386375, lng: 22.036601 },
    { lat: 48.386408, lng: 22.036741 },
    { lat: 48.386428, lng: 22.036834 },
    { lat: 48.386444, lng: 22.036903 },
    { lat: 48.386395, lng: 22.037053 },
    { lat: 48.386378, lng: 22.037126 },
    { lat: 48.386144, lng: 22.03794 },
    { lat: 48.386029, lng: 22.038369 },
    { lat: 48.385944, lng: 22.038668 },
    { lat: 48.385871, lng: 22.038918 },
    { lat: 48.385854, lng: 22.038923 },
    { lat: 48.385612, lng: 22.039793 },
    { lat: 48.385352, lng: 22.040682 },
    { lat: 48.385283, lng: 22.040923 },
    { lat: 48.385109, lng: 22.041567 },
    { lat: 48.384941, lng: 22.04217 },
    { lat: 48.384884, lng: 22.042453 },
    { lat: 48.384859, lng: 22.042546 },
    { lat: 48.3856828, lng: 22.0421855 },
    { lat: 48.3864029, lng: 22.0421893 },
    { lat: 48.386719, lng: 22.0422357 },
    { lat: 48.388178, lng: 22.0429254 },
    { lat: 48.3895882, lng: 22.0434142 },
    { lat: 48.3898886, lng: 22.0431833 },
    { lat: 48.3899955, lng: 22.0429923 },
    { lat: 48.3911343, lng: 22.0439321 },
    { lat: 48.3913967, lng: 22.0437213 },
    { lat: 48.3918485, lng: 22.0439763 },
    { lat: 48.3921945, lng: 22.0445588 },
    { lat: 48.3929341, lng: 22.0438798 },
    { lat: 48.3931159, lng: 22.0437746 },
    { lat: 48.3943781, lng: 22.0435817 },
    { lat: 48.3952552, lng: 22.0432867 },
    { lat: 48.3961837, lng: 22.0428844 },
    { lat: 48.3965375, lng: 22.0415244 },
    { lat: 48.3969945, lng: 22.0416283 },
    { lat: 48.398739, lng: 22.0408199 },
    { lat: 48.399789, lng: 22.0402402 },
    { lat: 48.4005642, lng: 22.0395432 },
    { lat: 48.4009339, lng: 22.038971 },
    { lat: 48.4011869, lng: 22.0383142 },
    { lat: 48.4063522, lng: 22.0447734 },
    { lat: 48.407355, lng: 22.0450266 },
    { lat: 48.4075161, lng: 22.0452742 },
    { lat: 48.4092568, lng: 22.0450495 },
    { lat: 48.4097579, lng: 22.0448911 },
    { lat: 48.410376, lng: 22.0443552 },
    { lat: 48.409833, lng: 22.0423665 },
    { lat: 48.4103374, lng: 22.0422472 },
    { lat: 48.4107235, lng: 22.0420714 },
    { lat: 48.4103944, lng: 22.0404301 },
    { lat: 48.4106399, lng: 22.0401617 },
    { lat: 48.4114984, lng: 22.0394716 },
    { lat: 48.4119044, lng: 22.0392759 },
    { lat: 48.4130981, lng: 22.0394201 },
    { lat: 48.4136403, lng: 22.0395565 },
    { lat: 48.4138636, lng: 22.0395379 },
    { lat: 48.4145322, lng: 22.0393674 },
    { lat: 48.4156203, lng: 22.0389076 },
    { lat: 48.4162044, lng: 22.03874 },
    { lat: 48.4165579, lng: 22.0387437 },
    { lat: 48.4168951, lng: 22.0389239 },
    { lat: 48.4173825, lng: 22.0394907 },
    { lat: 48.4176495, lng: 22.0401451 },
    { lat: 48.4176835, lng: 22.0404417 },
    { lat: 48.4176123, lng: 22.0409361 },
    { lat: 48.4179197, lng: 22.0407132 },
    { lat: 48.4179839, lng: 22.0409009 },
    { lat: 48.4185737, lng: 22.0402694 },
    { lat: 48.4189357, lng: 22.0412363 },
    { lat: 48.419201, lng: 22.0417363 },
    { lat: 48.4198397, lng: 22.0424653 },
    { lat: 48.4202571, lng: 22.0426458 },
    { lat: 48.4207713, lng: 22.0425641 },
    { lat: 48.4210857, lng: 22.0424407 },
    { lat: 48.4211785, lng: 22.0430232 },
    { lat: 48.4215944, lng: 22.0444458 },
    { lat: 48.4216971, lng: 22.0445964 },
    { lat: 48.421834, lng: 22.0446803 },
    { lat: 48.4219675, lng: 22.0446149 },
    { lat: 48.4222734, lng: 22.0440977 },
    { lat: 48.4225826, lng: 22.0439561 },
    { lat: 48.4227611, lng: 22.0440781 },
    { lat: 48.4228957, lng: 22.0443017 },
    { lat: 48.4230151, lng: 22.0443044 },
    { lat: 48.4231472, lng: 22.0441314 },
    { lat: 48.4233955, lng: 22.0440756 },
    { lat: 48.423695, lng: 22.0444595 },
    { lat: 48.4237167, lng: 22.0446563 },
    { lat: 48.4238292, lng: 22.044562 },
    { lat: 48.4246829, lng: 22.0445542 },
    { lat: 48.4248625, lng: 22.0444966 },
    { lat: 48.4257252, lng: 22.0437917 },
    { lat: 48.4257069, lng: 22.04364 },
    { lat: 48.4257816, lng: 22.0436108 },
  ],
  Somotor: [
    { lat: 48.4167287, lng: 21.8134644 },
    { lat: 48.4164047, lng: 21.8118353 },
    { lat: 48.4158854, lng: 21.8096738 },
    { lat: 48.4154259, lng: 21.8082401 },
    { lat: 48.4145856, lng: 21.804822 },
    { lat: 48.4144262, lng: 21.8038554 },
    { lat: 48.4140677, lng: 21.8008402 },
    { lat: 48.4139957, lng: 21.7992554 },
    { lat: 48.4140389, lng: 21.7967992 },
    { lat: 48.4144299, lng: 21.7942649 },
    { lat: 48.4151673, lng: 21.7920683 },
    { lat: 48.4158554, lng: 21.7910732 },
    { lat: 48.416695, lng: 21.7902712 },
    { lat: 48.418195, lng: 21.7890876 },
    { lat: 48.4183173, lng: 21.7888614 },
    { lat: 48.4185471, lng: 21.7884928 },
    { lat: 48.4189027, lng: 21.7875609 },
    { lat: 48.4189739, lng: 21.7863846 },
    { lat: 48.4187998, lng: 21.7853764 },
    { lat: 48.4185072, lng: 21.7846897 },
    { lat: 48.4180842, lng: 21.7841026 },
    { lat: 48.4171772, lng: 21.7832009 },
    { lat: 48.4164181, lng: 21.782833 },
    { lat: 48.416056, lng: 21.7825823 },
    { lat: 48.4138603, lng: 21.7832421 },
    { lat: 48.4131846, lng: 21.7831709 },
    { lat: 48.4125387, lng: 21.7829974 },
    { lat: 48.411906, lng: 21.7829471 },
    { lat: 48.4108988, lng: 21.7826815 },
    { lat: 48.4098416, lng: 21.7826527 },
    { lat: 48.4083815, lng: 21.7831802 },
    { lat: 48.4080669, lng: 21.7834883 },
    { lat: 48.4079803, lng: 21.7835739 },
    { lat: 48.4071953, lng: 21.7840149 },
    { lat: 48.4051155, lng: 21.7856923 },
    { lat: 48.4046087, lng: 21.7861769 },
    { lat: 48.4032873, lng: 21.7868397 },
    { lat: 48.4025216, lng: 21.7873337 },
    { lat: 48.4006008, lng: 21.7894089 },
    { lat: 48.3993821, lng: 21.7901996 },
    { lat: 48.3980439, lng: 21.7915929 },
    { lat: 48.3977313, lng: 21.7921126 },
    { lat: 48.3971476, lng: 21.7938259 },
    { lat: 48.3967008, lng: 21.7967966 },
    { lat: 48.3966748, lng: 21.7976879 },
    { lat: 48.3968079, lng: 21.7988561 },
    { lat: 48.3969677, lng: 21.7995419 },
    { lat: 48.3975296, lng: 21.8006255 },
    { lat: 48.3981149, lng: 21.8014018 },
    { lat: 48.3989905, lng: 21.8023917 },
    { lat: 48.3999461, lng: 21.8033062 },
    { lat: 48.4007231, lng: 21.8048721 },
    { lat: 48.4010336, lng: 21.8060638 },
    { lat: 48.4010355, lng: 21.8067329 },
    { lat: 48.4009656, lng: 21.8069013 },
    { lat: 48.4005156, lng: 21.8074127 },
    { lat: 48.4000893, lng: 21.8075474 },
    { lat: 48.3993212, lng: 21.8074775 },
    { lat: 48.3983852, lng: 21.8067513 },
    { lat: 48.398325, lng: 21.8067751 },
    { lat: 48.3978804, lng: 21.8063362 },
    { lat: 48.3967633, lng: 21.8046327 },
    { lat: 48.3955804, lng: 21.8022254 },
    { lat: 48.3948503, lng: 21.8002233 },
    { lat: 48.3942049, lng: 21.7986169 },
    { lat: 48.3933081, lng: 21.7969157 },
    { lat: 48.3929751, lng: 21.7971678 },
    { lat: 48.3910445, lng: 21.7986654 },
    { lat: 48.3905026, lng: 21.7990201 },
    { lat: 48.3897005, lng: 21.7997074 },
    { lat: 48.3875069, lng: 21.8007447 },
    { lat: 48.3868356, lng: 21.801403 },
    { lat: 48.3864172, lng: 21.8019142 },
    { lat: 48.3862009, lng: 21.8033617 },
    { lat: 48.3861828, lng: 21.8039999 },
    { lat: 48.386238, lng: 21.8040798 },
    { lat: 48.3864276, lng: 21.8054327 },
    { lat: 48.38641, lng: 21.8068181 },
    { lat: 48.3866599, lng: 21.8108409 },
    { lat: 48.3870919, lng: 21.8125041 },
    { lat: 48.3873364, lng: 21.8144903 },
    { lat: 48.3865947, lng: 21.8151748 },
    { lat: 48.3863666, lng: 21.8155278 },
    { lat: 48.3861326, lng: 21.816424 },
    { lat: 48.3861164, lng: 21.8168866 },
    { lat: 48.3857981, lng: 21.8181711 },
    { lat: 48.3857269, lng: 21.8189799 },
    { lat: 48.3856568, lng: 21.8192643 },
    { lat: 48.3855134, lng: 21.8195397 },
    { lat: 48.3852677, lng: 21.8198299 },
    { lat: 48.3851173, lng: 21.8207913 },
    { lat: 48.3851338, lng: 21.8208636 },
    { lat: 48.3853592, lng: 21.8209618 },
    { lat: 48.3854209, lng: 21.8210863 },
    { lat: 48.3855568, lng: 21.8226668 },
    { lat: 48.3857261, lng: 21.8237555 },
    { lat: 48.3861192, lng: 21.8250214 },
    { lat: 48.3862417, lng: 21.8252775 },
    { lat: 48.3867363, lng: 21.8254756 },
    { lat: 48.387167, lng: 21.8254676 },
    { lat: 48.3882818, lng: 21.8259296 },
    { lat: 48.3887868, lng: 21.826273 },
    { lat: 48.3894238, lng: 21.826874 },
    { lat: 48.3896212, lng: 21.827159 },
    { lat: 48.3898818, lng: 21.8276113 },
    { lat: 48.3900689, lng: 21.8282764 },
    { lat: 48.3902167, lng: 21.8288054 },
    { lat: 48.3902519, lng: 21.8289902 },
    { lat: 48.3903041, lng: 21.829745 },
    { lat: 48.3901939, lng: 21.8300457 },
    { lat: 48.3895839, lng: 21.8308111 },
    { lat: 48.3886742, lng: 21.8316452 },
    { lat: 48.3880844, lng: 21.8323729 },
    { lat: 48.3882896, lng: 21.8324299 },
    { lat: 48.388282, lng: 21.8325723 },
    { lat: 48.3899076, lng: 21.8330891 },
    { lat: 48.3906685, lng: 21.8337863 },
    { lat: 48.3911535, lng: 21.8337089 },
    { lat: 48.3913083, lng: 21.8337749 },
    { lat: 48.3921055, lng: 21.835652 },
    { lat: 48.3925661, lng: 21.8359733 },
    { lat: 48.3931541, lng: 21.8360828 },
    { lat: 48.3933234, lng: 21.8362817 },
    { lat: 48.3940194, lng: 21.8381203 },
    { lat: 48.3948981, lng: 21.8395446 },
    { lat: 48.3948675, lng: 21.8396643 },
    { lat: 48.3952514, lng: 21.8410635 },
    { lat: 48.395391, lng: 21.8414097 },
    { lat: 48.3957512, lng: 21.841807 },
    { lat: 48.3967153, lng: 21.8421077 },
    { lat: 48.3970526, lng: 21.842358 },
    { lat: 48.3978103, lng: 21.8431566 },
    { lat: 48.3980391, lng: 21.8435121 },
    { lat: 48.3983852, lng: 21.8437566 },
    { lat: 48.3986672, lng: 21.843568 },
    { lat: 48.3988028, lng: 21.8428998 },
    { lat: 48.3989784, lng: 21.8427344 },
    { lat: 48.3991758, lng: 21.842663 },
    { lat: 48.4010958, lng: 21.8430355 },
    { lat: 48.4016881, lng: 21.8436179 },
    { lat: 48.4027902, lng: 21.8453743 },
    { lat: 48.4029244, lng: 21.8459935 },
    { lat: 48.4029258, lng: 21.8466924 },
    { lat: 48.4030126, lng: 21.8477856 },
    { lat: 48.4028085, lng: 21.8495852 },
    { lat: 48.4024366, lng: 21.8513001 },
    { lat: 48.402427, lng: 21.852188 },
    { lat: 48.4025733, lng: 21.8524907 },
    { lat: 48.403788, lng: 21.8530864 },
    { lat: 48.4040601, lng: 21.8536052 },
    { lat: 48.4043056, lng: 21.8546113 },
    { lat: 48.404595, lng: 21.8554074 },
    { lat: 48.4045512, lng: 21.8558816 },
    { lat: 48.4042495, lng: 21.8566133 },
    { lat: 48.4041274, lng: 21.8575906 },
    { lat: 48.4042979, lng: 21.8582665 },
    { lat: 48.4046487, lng: 21.85887 },
    { lat: 48.4046719, lng: 21.8593749 },
    { lat: 48.4062618, lng: 21.8583425 },
    { lat: 48.4116337, lng: 21.8561034 },
    { lat: 48.4116429, lng: 21.8558925 },
    { lat: 48.4132207, lng: 21.8555702 },
    { lat: 48.4143161, lng: 21.8549425 },
    { lat: 48.415514, lng: 21.8537583 },
    { lat: 48.4168716, lng: 21.8520874 },
    { lat: 48.4169834, lng: 21.8518443 },
    { lat: 48.4170946, lng: 21.8510568 },
    { lat: 48.4170914, lng: 21.8500257 },
    { lat: 48.4171434, lng: 21.8500311 },
    { lat: 48.4172436, lng: 21.8494653 },
    { lat: 48.4173415, lng: 21.8492503 },
    { lat: 48.4176011, lng: 21.8490708 },
    { lat: 48.417825, lng: 21.8489837 },
    { lat: 48.4184023, lng: 21.8486361 },
    { lat: 48.4184616, lng: 21.8485848 },
    { lat: 48.4184888, lng: 21.8484153 },
    { lat: 48.418949, lng: 21.8485088 },
    { lat: 48.4195472, lng: 21.8483201 },
    { lat: 48.4196192, lng: 21.8483591 },
    { lat: 48.4200362, lng: 21.8491323 },
    { lat: 48.4201454, lng: 21.8492144 },
    { lat: 48.420206, lng: 21.849122 },
    { lat: 48.4203295, lng: 21.8489873 },
    { lat: 48.4208025, lng: 21.8476951 },
    { lat: 48.4209045, lng: 21.8472956 },
    { lat: 48.4209223, lng: 21.8463815 },
    { lat: 48.4211418, lng: 21.846035 },
    { lat: 48.421609, lng: 21.8459587 },
    { lat: 48.4221414, lng: 21.8449712 },
    { lat: 48.4222205, lng: 21.8448957 },
    { lat: 48.4236699, lng: 21.8441767 },
    { lat: 48.4234745, lng: 21.8429714 },
    { lat: 48.4233642, lng: 21.8419734 },
    { lat: 48.4233406, lng: 21.8395151 },
    { lat: 48.4235977, lng: 21.8398811 },
    { lat: 48.4236913, lng: 21.8396737 },
    { lat: 48.4237979, lng: 21.8390136 },
    { lat: 48.4241531, lng: 21.8378762 },
    { lat: 48.4244187, lng: 21.8371865 },
    { lat: 48.4249558, lng: 21.8361624 },
    { lat: 48.4251204, lng: 21.8357472 },
    { lat: 48.4252793, lng: 21.8349766 },
    { lat: 48.4252996, lng: 21.8342907 },
    { lat: 48.4253696, lng: 21.8339989 },
    { lat: 48.4260243, lng: 21.8326058 },
    { lat: 48.4260703, lng: 21.8322659 },
    { lat: 48.4260405, lng: 21.8319343 },
    { lat: 48.4258237, lng: 21.8309194 },
    { lat: 48.4271317, lng: 21.8298165 },
    { lat: 48.4276812, lng: 21.8274206 },
    { lat: 48.4285366, lng: 21.8270854 },
    { lat: 48.4279481, lng: 21.8236021 },
    { lat: 48.4286227, lng: 21.8233249 },
    { lat: 48.4283474, lng: 21.8217066 },
    { lat: 48.4277413, lng: 21.8162611 },
    { lat: 48.4276233, lng: 21.8158802 },
    { lat: 48.4273297, lng: 21.8136914 },
    { lat: 48.4275803, lng: 21.8126945 },
    { lat: 48.4276487, lng: 21.8116536 },
    { lat: 48.4276112, lng: 21.8104847 },
    { lat: 48.4275275, lng: 21.8095218 },
    { lat: 48.42583, lng: 21.805039 },
    { lat: 48.4260427, lng: 21.8026509 },
    { lat: 48.4250496, lng: 21.802666 },
    { lat: 48.4246033, lng: 21.8028512 },
    { lat: 48.4241797, lng: 21.8031229 },
    { lat: 48.4236789, lng: 21.8036351 },
    { lat: 48.4230814, lng: 21.8045703 },
    { lat: 48.4227323, lng: 21.8055071 },
    { lat: 48.4225076, lng: 21.8061612 },
    { lat: 48.4222153, lng: 21.8075798 },
    { lat: 48.4220336, lng: 21.8112336 },
    { lat: 48.4220873, lng: 21.8119127 },
    { lat: 48.4219731, lng: 21.813259 },
    { lat: 48.4216075, lng: 21.8156238 },
    { lat: 48.4213803, lng: 21.8163584 },
    { lat: 48.421017, lng: 21.8170029 },
    { lat: 48.4206413, lng: 21.8174413 },
    { lat: 48.4201963, lng: 21.8177613 },
    { lat: 48.4195575, lng: 21.8179361 },
    { lat: 48.4188558, lng: 21.8178703 },
    { lat: 48.4182298, lng: 21.8175528 },
    { lat: 48.4179715, lng: 21.8173499 },
    { lat: 48.4174196, lng: 21.8166405 },
    { lat: 48.417316, lng: 21.8163406 },
    { lat: 48.4168255, lng: 21.8146717 },
    { lat: 48.4167287, lng: 21.8134644 },
  ],
  MaláTŕňa: [
    { lat: 48.4388685, lng: 21.710672 },
    { lat: 48.4389343, lng: 21.7107006 },
    { lat: 48.4389517, lng: 21.7108792 },
    { lat: 48.4390422, lng: 21.7126021 },
    { lat: 48.4391622, lng: 21.7137492 },
    { lat: 48.4390051, lng: 21.715756 },
    { lat: 48.4389816, lng: 21.7159704 },
    { lat: 48.4395271, lng: 21.7159671 },
    { lat: 48.4398495, lng: 21.71603 },
    { lat: 48.4401325, lng: 21.7159198 },
    { lat: 48.4414287, lng: 21.7160464 },
    { lat: 48.4430141, lng: 21.7158056 },
    { lat: 48.4440514, lng: 21.7153392 },
    { lat: 48.4447292, lng: 21.715156 },
    { lat: 48.4450602, lng: 21.714866 },
    { lat: 48.4457859, lng: 21.7144931 },
    { lat: 48.4466266, lng: 21.7145859 },
    { lat: 48.4471031, lng: 21.714315 },
    { lat: 48.4475824, lng: 21.7142701 },
    { lat: 48.4479477, lng: 21.7140121 },
    { lat: 48.4484074, lng: 21.7139038 },
    { lat: 48.4488819, lng: 21.7136044 },
    { lat: 48.4490986, lng: 21.7133813 },
    { lat: 48.4493748, lng: 21.7129394 },
    { lat: 48.4496616, lng: 21.7126479 },
    { lat: 48.4498656, lng: 21.7124944 },
    { lat: 48.4505291, lng: 21.7122747 },
    { lat: 48.450745, lng: 21.7121438 },
    { lat: 48.4516282, lng: 21.7114376 },
    { lat: 48.4522445, lng: 21.7106796 },
    { lat: 48.4526719, lng: 21.7104716 },
    { lat: 48.4527552, lng: 21.7101347 },
    { lat: 48.4529903, lng: 21.7095539 },
    { lat: 48.4532616, lng: 21.7093147 },
    { lat: 48.4535988, lng: 21.7092132 },
    { lat: 48.4537245, lng: 21.7090382 },
    { lat: 48.4541614, lng: 21.7091344 },
    { lat: 48.455584, lng: 21.7088186 },
    { lat: 48.4557996, lng: 21.7085558 },
    { lat: 48.4561808, lng: 21.7082574 },
    { lat: 48.4570409, lng: 21.7105523 },
    { lat: 48.4572552, lng: 21.7112378 },
    { lat: 48.4572778, lng: 21.7116107 },
    { lat: 48.457441, lng: 21.7121186 },
    { lat: 48.4575067, lng: 21.7130802 },
    { lat: 48.4577256, lng: 21.7140575 },
    { lat: 48.4580992, lng: 21.7147674 },
    { lat: 48.4585146, lng: 21.7151503 },
    { lat: 48.4589909, lng: 21.7154119 },
    { lat: 48.459728, lng: 21.7159984 },
    { lat: 48.4601042, lng: 21.7161424 },
    { lat: 48.4609106, lng: 21.7162501 },
    { lat: 48.4613192, lng: 21.7164233 },
    { lat: 48.4617823, lng: 21.716497 },
    { lat: 48.462213, lng: 21.7168167 },
    { lat: 48.4625925, lng: 21.7172813 },
    { lat: 48.4628968, lng: 21.7180979 },
    { lat: 48.4630642, lng: 21.7190423 },
    { lat: 48.463212, lng: 21.7193552 },
    { lat: 48.4635376, lng: 21.7196147 },
    { lat: 48.4635445, lng: 21.7195823 },
    { lat: 48.4636322, lng: 21.7179519 },
    { lat: 48.4637636, lng: 21.7166109 },
    { lat: 48.4636331, lng: 21.7162402 },
    { lat: 48.4633615, lng: 21.7157279 },
    { lat: 48.4630123, lng: 21.714629 },
    { lat: 48.4629493, lng: 21.7144195 },
    { lat: 48.4628747, lng: 21.7143726 },
    { lat: 48.4627056, lng: 21.7138562 },
    { lat: 48.4626358, lng: 21.7130639 },
    { lat: 48.4627292, lng: 21.7126919 },
    { lat: 48.4639405, lng: 21.7113035 },
    { lat: 48.4642553, lng: 21.7110439 },
    { lat: 48.4648406, lng: 21.7102674 },
    { lat: 48.4650616, lng: 21.7071315 },
    { lat: 48.4649229, lng: 21.7064175 },
    { lat: 48.4648928, lng: 21.7057592 },
    { lat: 48.4646365, lng: 21.7047787 },
    { lat: 48.46441, lng: 21.7042247 },
    { lat: 48.4641282, lng: 21.703171 },
    { lat: 48.4639325, lng: 21.7028434 },
    { lat: 48.463869, lng: 21.7026151 },
    { lat: 48.4636896, lng: 21.7023907 },
    { lat: 48.4631465, lng: 21.7013279 },
    { lat: 48.463092, lng: 21.7011451 },
    { lat: 48.4631746, lng: 21.700955 },
    { lat: 48.4626816, lng: 21.6999147 },
    { lat: 48.4623687, lng: 21.6996209 },
    { lat: 48.4623361, lng: 21.6993343 },
    { lat: 48.4622825, lng: 21.6992692 },
    { lat: 48.4622889, lng: 21.6987176 },
    { lat: 48.4622352, lng: 21.6983103 },
    { lat: 48.4621143, lng: 21.6979246 },
    { lat: 48.4620505, lng: 21.6978935 },
    { lat: 48.4620356, lng: 21.6977582 },
    { lat: 48.4618971, lng: 21.6974633 },
    { lat: 48.461917, lng: 21.6972923 },
    { lat: 48.4621911, lng: 21.6971921 },
    { lat: 48.4622043, lng: 21.6970108 },
    { lat: 48.4621541, lng: 21.6963265 },
    { lat: 48.4620878, lng: 21.6960406 },
    { lat: 48.4620894, lng: 21.6954418 },
    { lat: 48.4619435, lng: 21.6947073 },
    { lat: 48.4618936, lng: 21.6946498 },
    { lat: 48.4617497, lng: 21.6935813 },
    { lat: 48.46186, lng: 21.6928382 },
    { lat: 48.4617247, lng: 21.6915951 },
    { lat: 48.4615732, lng: 21.6915644 },
    { lat: 48.4614771, lng: 21.6914721 },
    { lat: 48.4610561, lng: 21.6908008 },
    { lat: 48.460788, lng: 21.6902569 },
    { lat: 48.4600516, lng: 21.6893299 },
    { lat: 48.4598514, lng: 21.6886287 },
    { lat: 48.4592341, lng: 21.6875306 },
    { lat: 48.4590875, lng: 21.686981 },
    { lat: 48.4586052, lng: 21.6861745 },
    { lat: 48.4583407, lng: 21.6852682 },
    { lat: 48.4582954, lng: 21.68406 },
    { lat: 48.4581909, lng: 21.6838588 },
    { lat: 48.4582074, lng: 21.6837638 },
    { lat: 48.4585198, lng: 21.6835052 },
    { lat: 48.4593598, lng: 21.6833654 },
    { lat: 48.4592862, lng: 21.6819069 },
    { lat: 48.4587897, lng: 21.6793871 },
    { lat: 48.4587637, lng: 21.6793408 },
    { lat: 48.4580772, lng: 21.6796286 },
    { lat: 48.4572483, lng: 21.6802928 },
    { lat: 48.4566697, lng: 21.6779441 },
    { lat: 48.4560235, lng: 21.6767775 },
    { lat: 48.4559929, lng: 21.6768141 },
    { lat: 48.4557022, lng: 21.6759371 },
    { lat: 48.4556487, lng: 21.6755845 },
    { lat: 48.4553362, lng: 21.674857 },
    { lat: 48.4547375, lng: 21.6725639 },
    { lat: 48.4542598, lng: 21.6713852 },
    { lat: 48.4539601, lng: 21.6710054 },
    { lat: 48.4535186, lng: 21.6706177 },
    { lat: 48.4529484, lng: 21.6698925 },
    { lat: 48.45257, lng: 21.669592 },
    { lat: 48.4519476, lng: 21.6693382 },
    { lat: 48.4512551, lng: 21.666539 },
    { lat: 48.4497484, lng: 21.6671121 },
    { lat: 48.4480993, lng: 21.6622605 },
    { lat: 48.4469252, lng: 21.657997 },
    { lat: 48.4464451, lng: 21.6569006 },
    { lat: 48.446055, lng: 21.6561003 },
    { lat: 48.4455563, lng: 21.653726 },
    { lat: 48.4454176, lng: 21.6533498 },
    { lat: 48.4453672, lng: 21.6529932 },
    { lat: 48.4446861, lng: 21.6512287 },
    { lat: 48.4444239, lng: 21.6502906 },
    { lat: 48.4440717, lng: 21.6493132 },
    { lat: 48.4438508, lng: 21.6488086 },
    { lat: 48.4434322, lng: 21.6480824 },
    { lat: 48.4425319, lng: 21.6467342 },
    { lat: 48.4420318, lng: 21.6461443 },
    { lat: 48.4416063, lng: 21.6454504 },
    { lat: 48.4413766, lng: 21.6447089 },
    { lat: 48.441319, lng: 21.644595 },
    { lat: 48.441236, lng: 21.644662 },
    { lat: 48.441172, lng: 21.644683 },
    { lat: 48.441095, lng: 21.644735 },
    { lat: 48.440928, lng: 21.644873 },
    { lat: 48.44085, lng: 21.644955 },
    { lat: 48.440645, lng: 21.645099 },
    { lat: 48.440588, lng: 21.645111 },
    { lat: 48.440526, lng: 21.645134 },
    { lat: 48.440423, lng: 21.645196 },
    { lat: 48.440277, lng: 21.645303 },
    { lat: 48.440242, lng: 21.645454 },
    { lat: 48.440076, lng: 21.645807 },
    { lat: 48.439991, lng: 21.645849 },
    { lat: 48.439714, lng: 21.645879 },
    { lat: 48.43963, lng: 21.645919 },
    { lat: 48.439405, lng: 21.646074 },
    { lat: 48.439233, lng: 21.646206 },
    { lat: 48.439181, lng: 21.646248 },
    { lat: 48.439023, lng: 21.646384 },
    { lat: 48.4390978, lng: 21.6466094 },
    { lat: 48.4396196, lng: 21.6493274 },
    { lat: 48.44019, lng: 21.6488332 },
    { lat: 48.4402961, lng: 21.6485611 },
    { lat: 48.4405876, lng: 21.648359 },
    { lat: 48.4411565, lng: 21.6498501 },
    { lat: 48.4417196, lng: 21.6517349 },
    { lat: 48.4420812, lng: 21.6527323 },
    { lat: 48.4396076, lng: 21.6545064 },
    { lat: 48.4399205, lng: 21.6551215 },
    { lat: 48.4399527, lng: 21.6555221 },
    { lat: 48.4406261, lng: 21.6568774 },
    { lat: 48.4412896, lng: 21.6585367 },
    { lat: 48.4415267, lng: 21.6589797 },
    { lat: 48.442256, lng: 21.6599617 },
    { lat: 48.4423236, lng: 21.6602502 },
    { lat: 48.4416781, lng: 21.6605115 },
    { lat: 48.4419151, lng: 21.6622162 },
    { lat: 48.4391457, lng: 21.6634682 },
    { lat: 48.439258, lng: 21.664026 },
    { lat: 48.4394052, lng: 21.6643893 },
    { lat: 48.4396584, lng: 21.664594 },
    { lat: 48.4399201, lng: 21.6649459 },
    { lat: 48.440342, lng: 21.6659675 },
    { lat: 48.4408318, lng: 21.6676607 },
    { lat: 48.437906, lng: 21.6691606 },
    { lat: 48.4382665, lng: 21.6709369 },
    { lat: 48.4385071, lng: 21.6716278 },
    { lat: 48.433565, lng: 21.673504 },
    { lat: 48.4340517, lng: 21.6764338 },
    { lat: 48.4334609, lng: 21.6766783 },
    { lat: 48.4337473, lng: 21.6778887 },
    { lat: 48.4345278, lng: 21.6805052 },
    { lat: 48.4342785, lng: 21.6806081 },
    { lat: 48.4349765, lng: 21.6827986 },
    { lat: 48.4355794, lng: 21.6850138 },
    { lat: 48.435948, lng: 21.6868024 },
    { lat: 48.4362735, lng: 21.6866501 },
    { lat: 48.4369894, lng: 21.6864564 },
    { lat: 48.4372516, lng: 21.68642 },
    { lat: 48.4373646, lng: 21.6864733 },
    { lat: 48.437836, lng: 21.6885306 },
    { lat: 48.4376112, lng: 21.6889175 },
    { lat: 48.4373503, lng: 21.6897848 },
    { lat: 48.437191, lng: 21.690089 },
    { lat: 48.4368322, lng: 21.6904283 },
    { lat: 48.4358524, lng: 21.6911729 },
    { lat: 48.4357189, lng: 21.6914313 },
    { lat: 48.4354143, lng: 21.6917335 },
    { lat: 48.4348863, lng: 21.6924673 },
    { lat: 48.4347048, lng: 21.6926223 },
    { lat: 48.4344475, lng: 21.6929666 },
    { lat: 48.4347621, lng: 21.6933154 },
    { lat: 48.4351178, lng: 21.6933622 },
    { lat: 48.4352781, lng: 21.6934678 },
    { lat: 48.4353548, lng: 21.6937203 },
    { lat: 48.4354774, lng: 21.6937606 },
    { lat: 48.4356883, lng: 21.6939811 },
    { lat: 48.4357189, lng: 21.6941178 },
    { lat: 48.4355815, lng: 21.6943626 },
    { lat: 48.435558, lng: 21.6945323 },
    { lat: 48.4355877, lng: 21.6945925 },
    { lat: 48.4358625, lng: 21.6946376 },
    { lat: 48.4362753, lng: 21.694429 },
    { lat: 48.4368041, lng: 21.6939248 },
    { lat: 48.4384844, lng: 21.6962582 },
    { lat: 48.438804, lng: 21.6971715 },
    { lat: 48.4389074, lng: 21.6972023 },
    { lat: 48.4389846, lng: 21.6980952 },
    { lat: 48.4391882, lng: 21.69909 },
    { lat: 48.4392843, lng: 21.6998463 },
    { lat: 48.4392328, lng: 21.7003987 },
    { lat: 48.4389843, lng: 21.7013952 },
    { lat: 48.4389053, lng: 21.7018366 },
    { lat: 48.4389145, lng: 21.7020086 },
    { lat: 48.4387357, lng: 21.7023456 },
    { lat: 48.4385193, lng: 21.7025825 },
    { lat: 48.4384359, lng: 21.7030763 },
    { lat: 48.4382845, lng: 21.7033354 },
    { lat: 48.4378236, lng: 21.7035642 },
    { lat: 48.437058, lng: 21.703709 },
    { lat: 48.4376006, lng: 21.7043376 },
    { lat: 48.4378758, lng: 21.705321 },
    { lat: 48.438654, lng: 21.707462 },
    { lat: 48.4387722, lng: 21.708498 },
    { lat: 48.4388151, lng: 21.7085414 },
    { lat: 48.4388715, lng: 21.7094621 },
    { lat: 48.438871, lng: 21.710315 },
    { lat: 48.4388685, lng: 21.710672 },
  ],
  Stanča: [
    { lat: 48.5552008, lng: 21.6404128 },
    { lat: 48.5554685, lng: 21.6406123 },
    { lat: 48.5554421, lng: 21.6408839 },
    { lat: 48.5555566, lng: 21.6410784 },
    { lat: 48.5559754, lng: 21.641327 },
    { lat: 48.5561363, lng: 21.6412878 },
    { lat: 48.5587797, lng: 21.6438998 },
    { lat: 48.5589199, lng: 21.6441361 },
    { lat: 48.5590893, lng: 21.6446423 },
    { lat: 48.5592568, lng: 21.6449209 },
    { lat: 48.5592336, lng: 21.6452229 },
    { lat: 48.5590898, lng: 21.6457633 },
    { lat: 48.5591087, lng: 21.6460803 },
    { lat: 48.559536, lng: 21.6479024 },
    { lat: 48.559363, lng: 21.6483987 },
    { lat: 48.5599922, lng: 21.6500025 },
    { lat: 48.5600573, lng: 21.6502424 },
    { lat: 48.5600932, lng: 21.6507655 },
    { lat: 48.5600556, lng: 21.6512843 },
    { lat: 48.5596723, lng: 21.6526385 },
    { lat: 48.5594175, lng: 21.6532729 },
    { lat: 48.5592249, lng: 21.6533535 },
    { lat: 48.5593785, lng: 21.6539825 },
    { lat: 48.5597215, lng: 21.6549187 },
    { lat: 48.5606825, lng: 21.6581069 },
    { lat: 48.5628211, lng: 21.666863 },
    { lat: 48.562929, lng: 21.6668609 },
    { lat: 48.5639104, lng: 21.6708902 },
    { lat: 48.5638457, lng: 21.6711159 },
    { lat: 48.564464, lng: 21.6734788 },
    { lat: 48.5652023, lng: 21.6767731 },
    { lat: 48.5652237, lng: 21.6773945 },
    { lat: 48.5651931, lng: 21.6777154 },
    { lat: 48.5649256, lng: 21.6790449 },
    { lat: 48.5644975, lng: 21.680627 },
    { lat: 48.5643101, lng: 21.6809335 },
    { lat: 48.5639603, lng: 21.6811441 },
    { lat: 48.5640815, lng: 21.6815934 },
    { lat: 48.5634097, lng: 21.6826173 },
    { lat: 48.5616289, lng: 21.6850166 },
    { lat: 48.5618585, lng: 21.6866672 },
    { lat: 48.561785, lng: 21.6866948 },
    { lat: 48.5618694, lng: 21.6875698 },
    { lat: 48.5618494, lng: 21.687743 },
    { lat: 48.5620956, lng: 21.6893468 },
    { lat: 48.561795, lng: 21.6895447 },
    { lat: 48.5620352, lng: 21.690966 },
    { lat: 48.561598, lng: 21.6911962 },
    { lat: 48.5618421, lng: 21.692966 },
    { lat: 48.5617363, lng: 21.6930471 },
    { lat: 48.5622115, lng: 21.6959962 },
    { lat: 48.5624151, lng: 21.697034 },
    { lat: 48.562684, lng: 21.6966245 },
    { lat: 48.5630921, lng: 21.6961983 },
    { lat: 48.563481, lng: 21.6958887 },
    { lat: 48.5637915, lng: 21.6957243 },
    { lat: 48.5657864, lng: 21.6951433 },
    { lat: 48.5680505, lng: 21.6943715 },
    { lat: 48.5689515, lng: 21.693864 },
    { lat: 48.5690541, lng: 21.6937881 },
    { lat: 48.571696, lng: 21.6917633 },
    { lat: 48.569159, lng: 21.6687057 },
    { lat: 48.5689177, lng: 21.6674532 },
    { lat: 48.5684816, lng: 21.6656685 },
    { lat: 48.5681857, lng: 21.6637022 },
    { lat: 48.5683978, lng: 21.6636818 },
    { lat: 48.568539, lng: 21.6635785 },
    { lat: 48.5685941, lng: 21.6634547 },
    { lat: 48.5690575, lng: 21.663526 },
    { lat: 48.5692129, lng: 21.6636129 },
    { lat: 48.5695597, lng: 21.6636307 },
    { lat: 48.569639, lng: 21.6634769 },
    { lat: 48.5696488, lng: 21.662932 },
    { lat: 48.5697746, lng: 21.662765 },
    { lat: 48.5698864, lng: 21.6628177 },
    { lat: 48.570037, lng: 21.6632208 },
    { lat: 48.5703423, lng: 21.6633233 },
    { lat: 48.570469, lng: 21.6632667 },
    { lat: 48.5704739, lng: 21.6631957 },
    { lat: 48.5706552, lng: 21.663237 },
    { lat: 48.5707652, lng: 21.6631477 },
    { lat: 48.5710799, lng: 21.6630925 },
    { lat: 48.5713105, lng: 21.6629307 },
    { lat: 48.5713222, lng: 21.6628682 },
    { lat: 48.5711846, lng: 21.6627343 },
    { lat: 48.5711288, lng: 21.662866 },
    { lat: 48.5709727, lng: 21.6625894 },
    { lat: 48.5709352, lng: 21.6626585 },
    { lat: 48.5707209, lng: 21.6623156 },
    { lat: 48.570694, lng: 21.6621957 },
    { lat: 48.5708086, lng: 21.6619882 },
    { lat: 48.5709275, lng: 21.6619314 },
    { lat: 48.5712475, lng: 21.6619871 },
    { lat: 48.5713828, lng: 21.6619151 },
    { lat: 48.5714472, lng: 21.661721 },
    { lat: 48.5716997, lng: 21.66141 },
    { lat: 48.5713881, lng: 21.6609672 },
    { lat: 48.5714218, lng: 21.660882 },
    { lat: 48.5716111, lng: 21.6608126 },
    { lat: 48.5716627, lng: 21.6605945 },
    { lat: 48.5716207, lng: 21.6602603 },
    { lat: 48.5715332, lng: 21.6603112 },
    { lat: 48.5715209, lng: 21.6601447 },
    { lat: 48.5716367, lng: 21.6598976 },
    { lat: 48.5717396, lng: 21.6598319 },
    { lat: 48.5718983, lng: 21.6599978 },
    { lat: 48.5720793, lng: 21.6600785 },
    { lat: 48.5721065, lng: 21.6599219 },
    { lat: 48.5722486, lng: 21.659771 },
    { lat: 48.5722863, lng: 21.6595835 },
    { lat: 48.5724034, lng: 21.6595026 },
    { lat: 48.5724136, lng: 21.6592661 },
    { lat: 48.5725251, lng: 21.6589792 },
    { lat: 48.5729076, lng: 21.6592676 },
    { lat: 48.5730688, lng: 21.6593151 },
    { lat: 48.5731834, lng: 21.6591871 },
    { lat: 48.5734108, lng: 21.6591907 },
    { lat: 48.5735082, lng: 21.6587213 },
    { lat: 48.5736428, lng: 21.6587523 },
    { lat: 48.5739053, lng: 21.6586787 },
    { lat: 48.5742554, lng: 21.6588072 },
    { lat: 48.5742497, lng: 21.6587279 },
    { lat: 48.574328, lng: 21.658712 },
    { lat: 48.5743337, lng: 21.6581396 },
    { lat: 48.5740858, lng: 21.6560806 },
    { lat: 48.5740347, lng: 21.6553273 },
    { lat: 48.5740818, lng: 21.6540577 },
    { lat: 48.5738886, lng: 21.6510846 },
    { lat: 48.5739704, lng: 21.6510337 },
    { lat: 48.5739758, lng: 21.6509392 },
    { lat: 48.573072, lng: 21.6488939 },
    { lat: 48.5733761, lng: 21.6484989 },
    { lat: 48.5730255, lng: 21.6479435 },
    { lat: 48.5720999, lng: 21.6458343 },
    { lat: 48.5720849, lng: 21.6456597 },
    { lat: 48.5714047, lng: 21.6440134 },
    { lat: 48.5712911, lng: 21.6435178 },
    { lat: 48.5711723, lng: 21.6432112 },
    { lat: 48.5708384, lng: 21.6424846 },
    { lat: 48.5704214, lng: 21.6420543 },
    { lat: 48.5692898, lng: 21.640603 },
    { lat: 48.5686834, lng: 21.6395096 },
    { lat: 48.5682168, lng: 21.6391946 },
    { lat: 48.5678107, lng: 21.6386304 },
    { lat: 48.5676148, lng: 21.6380537 },
    { lat: 48.5668376, lng: 21.6367626 },
    { lat: 48.5664819, lng: 21.6357053 },
    { lat: 48.5662973, lng: 21.6349716 },
    { lat: 48.5663199, lng: 21.6348512 },
    { lat: 48.5648334, lng: 21.6325319 },
    { lat: 48.5645154, lng: 21.6322205 },
    { lat: 48.5641947, lng: 21.6320588 },
    { lat: 48.5626867, lng: 21.6317297 },
    { lat: 48.562529, lng: 21.6316781 },
    { lat: 48.5623529, lng: 21.6315034 },
    { lat: 48.5619627, lng: 21.6303295 },
    { lat: 48.5617696, lng: 21.6293705 },
    { lat: 48.5616442, lng: 21.629356 },
    { lat: 48.5612506, lng: 21.6294007 },
    { lat: 48.5606665, lng: 21.6292805 },
    { lat: 48.560132, lng: 21.6287455 },
    { lat: 48.5595124, lng: 21.6278739 },
    { lat: 48.5582342, lng: 21.6268608 },
    { lat: 48.5569303, lng: 21.6260959 },
    { lat: 48.5562991, lng: 21.6258182 },
    { lat: 48.5544124, lng: 21.6251739 },
    { lat: 48.5534559, lng: 21.6250468 },
    { lat: 48.5530087, lng: 21.6250757 },
    { lat: 48.5530273, lng: 21.6269593 },
    { lat: 48.553473, lng: 21.6290227 },
    { lat: 48.5536259, lng: 21.6294258 },
    { lat: 48.5536806, lng: 21.6297765 },
    { lat: 48.5535728, lng: 21.6301903 },
    { lat: 48.5534863, lng: 21.631491 },
    { lat: 48.553361, lng: 21.6320465 },
    { lat: 48.5533189, lng: 21.6325545 },
    { lat: 48.5534693, lng: 21.6327834 },
    { lat: 48.5534524, lng: 21.6330636 },
    { lat: 48.5535734, lng: 21.6332119 },
    { lat: 48.5535574, lng: 21.6334839 },
    { lat: 48.5536135, lng: 21.6336452 },
    { lat: 48.553535, lng: 21.6339339 },
    { lat: 48.5535645, lng: 21.6340737 },
    { lat: 48.5534021, lng: 21.6342949 },
    { lat: 48.5534607, lng: 21.6344283 },
    { lat: 48.5534838, lng: 21.6349713 },
    { lat: 48.5535946, lng: 21.6353962 },
    { lat: 48.5537215, lng: 21.6356358 },
    { lat: 48.5537065, lng: 21.6358171 },
    { lat: 48.5538226, lng: 21.6359494 },
    { lat: 48.5539499, lng: 21.6366045 },
    { lat: 48.5541159, lng: 21.6366289 },
    { lat: 48.5542886, lng: 21.6376618 },
    { lat: 48.5542172, lng: 21.6379703 },
    { lat: 48.5543081, lng: 21.638248 },
    { lat: 48.5543864, lng: 21.6382325 },
    { lat: 48.5544734, lng: 21.6384028 },
    { lat: 48.5545137, lng: 21.6389227 },
    { lat: 48.5547404, lng: 21.6388669 },
    { lat: 48.5547912, lng: 21.6391503 },
    { lat: 48.5549002, lng: 21.6391875 },
    { lat: 48.5548926, lng: 21.6399704 },
    { lat: 48.5549856, lng: 21.6402793 },
    { lat: 48.5552008, lng: 21.6404128 },
  ],
  Kravany: [
    { lat: 48.7593331, lng: 21.6587033 },
    { lat: 48.7600492, lng: 21.6585151 },
    { lat: 48.7601429, lng: 21.6584864 },
    { lat: 48.7599459, lng: 21.6539221 },
    { lat: 48.7599838, lng: 21.6521514 },
    { lat: 48.7600375, lng: 21.651847 },
    { lat: 48.7600789, lng: 21.6506534 },
    { lat: 48.7613693, lng: 21.6506554 },
    { lat: 48.7613743, lng: 21.6505855 },
    { lat: 48.762279, lng: 21.6503842 },
    { lat: 48.7624147, lng: 21.6502837 },
    { lat: 48.7628094, lng: 21.6502011 },
    { lat: 48.7628053, lng: 21.6500088 },
    { lat: 48.7629447, lng: 21.6500008 },
    { lat: 48.7630228, lng: 21.6495758 },
    { lat: 48.7634169, lng: 21.6495456 },
    { lat: 48.7639522, lng: 21.6493693 },
    { lat: 48.7644419, lng: 21.6492933 },
    { lat: 48.7648863, lng: 21.6492914 },
    { lat: 48.765387, lng: 21.6490509 },
    { lat: 48.7655175, lng: 21.6489243 },
    { lat: 48.7659238, lng: 21.6497098 },
    { lat: 48.7659798, lng: 21.649335 },
    { lat: 48.7662319, lng: 21.6490463 },
    { lat: 48.7664574, lng: 21.6485342 },
    { lat: 48.7668052, lng: 21.6482524 },
    { lat: 48.7672497, lng: 21.6481374 },
    { lat: 48.7674832, lng: 21.6478659 },
    { lat: 48.7676738, lng: 21.6474327 },
    { lat: 48.7678013, lng: 21.6467513 },
    { lat: 48.7679476, lng: 21.6466301 },
    { lat: 48.768005, lng: 21.6462638 },
    { lat: 48.7679734, lng: 21.6462194 },
    { lat: 48.768102, lng: 21.645865 },
    { lat: 48.7680702, lng: 21.6457721 },
    { lat: 48.7682919, lng: 21.6453619 },
    { lat: 48.7683909, lng: 21.6454028 },
    { lat: 48.7683984, lng: 21.6454708 },
    { lat: 48.768471, lng: 21.6453335 },
    { lat: 48.7700624, lng: 21.6449826 },
    { lat: 48.7718205, lng: 21.6452461 },
    { lat: 48.7739255, lng: 21.6458584 },
    { lat: 48.7755618, lng: 21.646772 },
    { lat: 48.777034, lng: 21.6481473 },
    { lat: 48.780118, lng: 21.648699 },
    { lat: 48.780142, lng: 21.647965 },
    { lat: 48.780055, lng: 21.647579 },
    { lat: 48.77996, lng: 21.647238 },
    { lat: 48.779973, lng: 21.64659 },
    { lat: 48.779918, lng: 21.646268 },
    { lat: 48.779962, lng: 21.645909 },
    { lat: 48.780067, lng: 21.645686 },
    { lat: 48.780091, lng: 21.645273 },
    { lat: 48.780155, lng: 21.644913 },
    { lat: 48.780094, lng: 21.644712 },
    { lat: 48.780158, lng: 21.644274 },
    { lat: 48.780377, lng: 21.643942 },
    { lat: 48.780392, lng: 21.643893 },
    { lat: 48.780379, lng: 21.643844 },
    { lat: 48.78029, lng: 21.643805 },
    { lat: 48.780165, lng: 21.643604 },
    { lat: 48.780103, lng: 21.643245 },
    { lat: 48.779974, lng: 21.642871 },
    { lat: 48.779955, lng: 21.642429 },
    { lat: 48.779832, lng: 21.641872 },
    { lat: 48.779784, lng: 21.641851 },
    { lat: 48.779716, lng: 21.641843 },
    { lat: 48.779701, lng: 21.641818 },
    { lat: 48.779747, lng: 21.641602 },
    { lat: 48.779705, lng: 21.641345 },
    { lat: 48.779737, lng: 21.640928 },
    { lat: 48.779559, lng: 21.640407 },
    { lat: 48.779156, lng: 21.639728 },
    { lat: 48.779155, lng: 21.639211 },
    { lat: 48.779341, lng: 21.639021 },
    { lat: 48.779385, lng: 21.638644 },
    { lat: 48.779559, lng: 21.638504 },
    { lat: 48.779585, lng: 21.638289 },
    { lat: 48.779926, lng: 21.638202 },
    { lat: 48.779945, lng: 21.638181 },
    { lat: 48.779946, lng: 21.638141 },
    { lat: 48.779883, lng: 21.637964 },
    { lat: 48.779976, lng: 21.637864 },
    { lat: 48.779962, lng: 21.637817 },
    { lat: 48.779303, lng: 21.635478 },
    { lat: 48.778988, lng: 21.634374 },
    { lat: 48.77896, lng: 21.634276 },
    { lat: 48.776379, lng: 21.625245 },
    { lat: 48.776039, lng: 21.624093 },
    { lat: 48.776025, lng: 21.624046 },
    { lat: 48.773579, lng: 21.615763 },
    { lat: 48.773568, lng: 21.615737 },
    { lat: 48.77314, lng: 21.6160852 },
    { lat: 48.7724718, lng: 21.6165763 },
    { lat: 48.7722005, lng: 21.6165283 },
    { lat: 48.7717374, lng: 21.6166619 },
    { lat: 48.7713965, lng: 21.6168679 },
    { lat: 48.7710273, lng: 21.6172677 },
    { lat: 48.7703757, lng: 21.6182828 },
    { lat: 48.769713, lng: 21.619574 },
    { lat: 48.7689894, lng: 21.620706 },
    { lat: 48.767528, lng: 21.6211963 },
    { lat: 48.7660676, lng: 21.6214049 },
    { lat: 48.7653159, lng: 21.6216013 },
    { lat: 48.7644396, lng: 21.6216857 },
    { lat: 48.7639286, lng: 21.6216393 },
    { lat: 48.7634927, lng: 21.6217458 },
    { lat: 48.7632125, lng: 21.6217054 },
    { lat: 48.7628635, lng: 21.6217796 },
    { lat: 48.7612653, lng: 21.6224886 },
    { lat: 48.7605879, lng: 21.6228554 },
    { lat: 48.7592729, lng: 21.6239144 },
    { lat: 48.7582277, lng: 21.6243222 },
    { lat: 48.7570573, lng: 21.6243988 },
    { lat: 48.7562745, lng: 21.6248549 },
    { lat: 48.755689, lng: 21.6254062 },
    { lat: 48.7539847, lng: 21.6264729 },
    { lat: 48.7531364, lng: 21.6268415 },
    { lat: 48.7527068, lng: 21.6270944 },
    { lat: 48.7526006, lng: 21.6273635 },
    { lat: 48.7523244, lng: 21.6273403 },
    { lat: 48.7518885, lng: 21.6275722 },
    { lat: 48.7519819, lng: 21.6287083 },
    { lat: 48.7518466, lng: 21.6290246 },
    { lat: 48.7517277, lng: 21.6294828 },
    { lat: 48.7517065, lng: 21.6298599 },
    { lat: 48.7515602, lng: 21.6298535 },
    { lat: 48.7515098, lng: 21.6303509 },
    { lat: 48.7512773, lng: 21.6302896 },
    { lat: 48.7511804, lng: 21.629956 },
    { lat: 48.7505656, lng: 21.6297303 },
    { lat: 48.7504381, lng: 21.630005 },
    { lat: 48.7503648, lng: 21.6302432 },
    { lat: 48.7503244, lng: 21.6306519 },
    { lat: 48.749986, lng: 21.6324584 },
    { lat: 48.7497734, lng: 21.634329 },
    { lat: 48.7496828, lng: 21.6343536 },
    { lat: 48.749617, lng: 21.635719 },
    { lat: 48.7493288, lng: 21.6361586 },
    { lat: 48.7492543, lng: 21.6361926 },
    { lat: 48.7489712, lng: 21.636573 },
    { lat: 48.7482083, lng: 21.6382055 },
    { lat: 48.7481634, lng: 21.6382112 },
    { lat: 48.7481514, lng: 21.6383813 },
    { lat: 48.7479248, lng: 21.6388185 },
    { lat: 48.7482182, lng: 21.6393365 },
    { lat: 48.7502996, lng: 21.6440781 },
    { lat: 48.7513791, lng: 21.6462606 },
    { lat: 48.7516247, lng: 21.6465996 },
    { lat: 48.7517792, lng: 21.6466983 },
    { lat: 48.7536668, lng: 21.6476599 },
    { lat: 48.7538094, lng: 21.6478107 },
    { lat: 48.7544854, lng: 21.6503016 },
    { lat: 48.7559404, lng: 21.6539196 },
    { lat: 48.7563527, lng: 21.6579584 },
    { lat: 48.7566513, lng: 21.6594508 },
    { lat: 48.7566603, lng: 21.659497 },
    { lat: 48.7583048, lng: 21.6589917 },
    { lat: 48.7593331, lng: 21.6587033 },
  ],
  NovýRuskov: [
    { lat: 48.6345931, lng: 21.6777249 },
    { lat: 48.6347678, lng: 21.6785176 },
    { lat: 48.6358385, lng: 21.6840815 },
    { lat: 48.6365975, lng: 21.6883942 },
    { lat: 48.6366031, lng: 21.6884359 },
    { lat: 48.636443, lng: 21.6885038 },
    { lat: 48.637194, lng: 21.6933294 },
    { lat: 48.63818, lng: 21.6929742 },
    { lat: 48.6438707, lng: 21.6912656 },
    { lat: 48.6478813, lng: 21.6901784 },
    { lat: 48.6504932, lng: 21.6893243 },
    { lat: 48.6509607, lng: 21.691995 },
    { lat: 48.6512275, lng: 21.6939089 },
    { lat: 48.6525404, lng: 21.6932825 },
    { lat: 48.653346, lng: 21.6926633 },
    { lat: 48.6547838, lng: 21.691826 },
    { lat: 48.6563722, lng: 21.6911167 },
    { lat: 48.6568442, lng: 21.6909661 },
    { lat: 48.66125, lng: 21.690015 },
    { lat: 48.6621192, lng: 21.6899523 },
    { lat: 48.6619134, lng: 21.6859447 },
    { lat: 48.6626671, lng: 21.686351 },
    { lat: 48.662957, lng: 21.6866676 },
    { lat: 48.6634515, lng: 21.6873901 },
    { lat: 48.6633374, lng: 21.6856834 },
    { lat: 48.663306, lng: 21.6843232 },
    { lat: 48.6633772, lng: 21.6813177 },
    { lat: 48.6632021, lng: 21.6787375 },
    { lat: 48.6632042, lng: 21.6780693 },
    { lat: 48.66386, lng: 21.6781421 },
    { lat: 48.6642165, lng: 21.678431 },
    { lat: 48.664483, lng: 21.6784568 },
    { lat: 48.6651665, lng: 21.6783559 },
    { lat: 48.6655638, lng: 21.6784437 },
    { lat: 48.6663804, lng: 21.6787674 },
    { lat: 48.6668471, lng: 21.6788412 },
    { lat: 48.6670291, lng: 21.6797817 },
    { lat: 48.6670884, lng: 21.6798909 },
    { lat: 48.6672432, lng: 21.6799578 },
    { lat: 48.6675445, lng: 21.6798686 },
    { lat: 48.6676547, lng: 21.6796987 },
    { lat: 48.6678752, lng: 21.6796163 },
    { lat: 48.6678146, lng: 21.6792534 },
    { lat: 48.6681043, lng: 21.6791902 },
    { lat: 48.668349, lng: 21.6791619 },
    { lat: 48.6685596, lng: 21.6792164 },
    { lat: 48.6691983, lng: 21.6796156 },
    { lat: 48.6692062, lng: 21.6796204 },
    { lat: 48.6693469, lng: 21.6797035 },
    { lat: 48.669634, lng: 21.6797864 },
    { lat: 48.6700305, lng: 21.6800099 },
    { lat: 48.6704208, lng: 21.6800931 },
    { lat: 48.6718139, lng: 21.6796495 },
    { lat: 48.6718907, lng: 21.679779 },
    { lat: 48.6721093, lng: 21.6798725 },
    { lat: 48.6724804, lng: 21.6798669 },
    { lat: 48.6725799, lng: 21.6800893 },
    { lat: 48.6728214, lng: 21.6800767 },
    { lat: 48.6728481, lng: 21.6798202 },
    { lat: 48.6730481, lng: 21.6797707 },
    { lat: 48.6731085, lng: 21.6799169 },
    { lat: 48.6732236, lng: 21.6798919 },
    { lat: 48.6733039, lng: 21.680029 },
    { lat: 48.6733882, lng: 21.6800237 },
    { lat: 48.6735281, lng: 21.6806152 },
    { lat: 48.6736963, lng: 21.6805955 },
    { lat: 48.6736599, lng: 21.6802948 },
    { lat: 48.6737174, lng: 21.6802941 },
    { lat: 48.6737835, lng: 21.6804121 },
    { lat: 48.674007, lng: 21.6803153 },
    { lat: 48.6741533, lng: 21.6806504 },
    { lat: 48.6742152, lng: 21.6806448 },
    { lat: 48.6743433, lng: 21.6808476 },
    { lat: 48.674453, lng: 21.6813256 },
    { lat: 48.6745852, lng: 21.6813399 },
    { lat: 48.6746922, lng: 21.6815564 },
    { lat: 48.6747353, lng: 21.6815271 },
    { lat: 48.6747298, lng: 21.6814452 },
    { lat: 48.6747943, lng: 21.6814597 },
    { lat: 48.6748708, lng: 21.6817059 },
    { lat: 48.6749187, lng: 21.6816897 },
    { lat: 48.6748955, lng: 21.6813313 },
    { lat: 48.6748013, lng: 21.6808092 },
    { lat: 48.675017, lng: 21.680711 },
    { lat: 48.6750158, lng: 21.6806332 },
    { lat: 48.6753081, lng: 21.6807887 },
    { lat: 48.6754663, lng: 21.6820965 },
    { lat: 48.6755933, lng: 21.6821708 },
    { lat: 48.6756875, lng: 21.6829022 },
    { lat: 48.6759594, lng: 21.6828864 },
    { lat: 48.6760085, lng: 21.683123 },
    { lat: 48.6761173, lng: 21.6831547 },
    { lat: 48.6761595, lng: 21.6831268 },
    { lat: 48.6761563, lng: 21.682976 },
    { lat: 48.6763385, lng: 21.6827999 },
    { lat: 48.6764503, lng: 21.6827744 },
    { lat: 48.6764817, lng: 21.6826468 },
    { lat: 48.677109, lng: 21.6827139 },
    { lat: 48.6771936, lng: 21.6826784 },
    { lat: 48.6772347, lng: 21.6824117 },
    { lat: 48.6773724, lng: 21.6823857 },
    { lat: 48.6774987, lng: 21.6825651 },
    { lat: 48.6775554, lng: 21.6825349 },
    { lat: 48.6776149, lng: 21.6826594 },
    { lat: 48.677817, lng: 21.682602 },
    { lat: 48.6779187, lng: 21.6831493 },
    { lat: 48.6780286, lng: 21.6832631 },
    { lat: 48.6780959, lng: 21.6832604 },
    { lat: 48.6781171, lng: 21.682834 },
    { lat: 48.6779959, lng: 21.6822708 },
    { lat: 48.6781433, lng: 21.6823492 },
    { lat: 48.6784803, lng: 21.6822058 },
    { lat: 48.6785775, lng: 21.6825968 },
    { lat: 48.6787399, lng: 21.6826141 },
    { lat: 48.6789261, lng: 21.6831661 },
    { lat: 48.6790792, lng: 21.6841642 },
    { lat: 48.679173, lng: 21.6845105 },
    { lat: 48.6793177, lng: 21.6845766 },
    { lat: 48.6794289, lng: 21.6848609 },
    { lat: 48.6795618, lng: 21.6849093 },
    { lat: 48.679635, lng: 21.6850368 },
    { lat: 48.6798121, lng: 21.6858771 },
    { lat: 48.6799526, lng: 21.685997 },
    { lat: 48.6802961, lng: 21.6859294 },
    { lat: 48.680552, lng: 21.6860256 },
    { lat: 48.6806665, lng: 21.6863112 },
    { lat: 48.6810615, lng: 21.6862265 },
    { lat: 48.6810436, lng: 21.6860265 },
    { lat: 48.6811428, lng: 21.6858629 },
    { lat: 48.6813372, lng: 21.6858142 },
    { lat: 48.681447, lng: 21.6860452 },
    { lat: 48.6820114, lng: 21.685698 },
    { lat: 48.6820046, lng: 21.6855332 },
    { lat: 48.6820609, lng: 21.6854567 },
    { lat: 48.6820186, lng: 21.6850534 },
    { lat: 48.682065, lng: 21.6849046 },
    { lat: 48.6821275, lng: 21.6848776 },
    { lat: 48.6823053, lng: 21.6853835 },
    { lat: 48.6824857, lng: 21.6852708 },
    { lat: 48.682308, lng: 21.6846919 },
    { lat: 48.6825558, lng: 21.6845825 },
    { lat: 48.682542, lng: 21.6845225 },
    { lat: 48.6826718, lng: 21.6843731 },
    { lat: 48.6827312, lng: 21.684364 },
    { lat: 48.6827748, lng: 21.6844842 },
    { lat: 48.6830076, lng: 21.6844359 },
    { lat: 48.6832415, lng: 21.6844705 },
    { lat: 48.6833244, lng: 21.6843728 },
    { lat: 48.6835948, lng: 21.684267 },
    { lat: 48.6836548, lng: 21.6843608 },
    { lat: 48.6837557, lng: 21.6843503 },
    { lat: 48.6838713, lng: 21.684675 },
    { lat: 48.6839577, lng: 21.6846155 },
    { lat: 48.684028, lng: 21.684724 },
    { lat: 48.6842074, lng: 21.6846174 },
    { lat: 48.684363, lng: 21.6852085 },
    { lat: 48.6846221, lng: 21.6852583 },
    { lat: 48.6849434, lng: 21.68516 },
    { lat: 48.6850514, lng: 21.6853267 },
    { lat: 48.6864427, lng: 21.6850591 },
    { lat: 48.686842, lng: 21.6848923 },
    { lat: 48.6869822, lng: 21.68475 },
    { lat: 48.6872207, lng: 21.6844621 },
    { lat: 48.6875108, lng: 21.6839954 },
    { lat: 48.6874634, lng: 21.6837876 },
    { lat: 48.6873446, lng: 21.6835994 },
    { lat: 48.6864125, lng: 21.6828768 },
    { lat: 48.6854333, lng: 21.6824918 },
    { lat: 48.6846257, lng: 21.682334 },
    { lat: 48.6843948, lng: 21.6821226 },
    { lat: 48.683977, lng: 21.6814891 },
    { lat: 48.6837096, lng: 21.6813313 },
    { lat: 48.6829189, lng: 21.6812227 },
    { lat: 48.6827133, lng: 21.6811083 },
    { lat: 48.6822863, lng: 21.6810473 },
    { lat: 48.6815761, lng: 21.6807414 },
    { lat: 48.6809445, lng: 21.6806888 },
    { lat: 48.6793828, lng: 21.6800239 },
    { lat: 48.6792644, lng: 21.6789849 },
    { lat: 48.6774663, lng: 21.6785644 },
    { lat: 48.6769662, lng: 21.6787525 },
    { lat: 48.6766915, lng: 21.6786236 },
    { lat: 48.6762647, lng: 21.6786445 },
    { lat: 48.6756766, lng: 21.6784963 },
    { lat: 48.674638, lng: 21.6784067 },
    { lat: 48.6742643, lng: 21.6781993 },
    { lat: 48.6738232, lng: 21.6781587 },
    { lat: 48.67312, lng: 21.6779433 },
    { lat: 48.6719797, lng: 21.6779636 },
    { lat: 48.6713518, lng: 21.6777983 },
    { lat: 48.6710866, lng: 21.6778091 },
    { lat: 48.6705806, lng: 21.6737373 },
    { lat: 48.6706517, lng: 21.673588 },
    { lat: 48.6715838, lng: 21.6731964 },
    { lat: 48.6719805, lng: 21.673218 },
    { lat: 48.6725306, lng: 21.6728413 },
    { lat: 48.6729784, lng: 21.6726774 },
    { lat: 48.6734825, lng: 21.6723527 },
    { lat: 48.6742889, lng: 21.6715509 },
    { lat: 48.6754004, lng: 21.6709352 },
    { lat: 48.6753822, lng: 21.6705518 },
    { lat: 48.6752513, lng: 21.6700516 },
    { lat: 48.6749768, lng: 21.669843 },
    { lat: 48.6742608, lng: 21.6696185 },
    { lat: 48.6737276, lng: 21.6700377 },
    { lat: 48.6737058, lng: 21.6702633 },
    { lat: 48.6737665, lng: 21.6705529 },
    { lat: 48.6735305, lng: 21.6706491 },
    { lat: 48.6731561, lng: 21.6704499 },
    { lat: 48.6729207, lng: 21.6702389 },
    { lat: 48.6729419, lng: 21.6701422 },
    { lat: 48.6728263, lng: 21.6697895 },
    { lat: 48.6721756, lng: 21.6697375 },
    { lat: 48.6721604, lng: 21.669914 },
    { lat: 48.6715591, lng: 21.6699454 },
    { lat: 48.6711841, lng: 21.6693957 },
    { lat: 48.6706462, lng: 21.6687714 },
    { lat: 48.6703541, lng: 21.6685828 },
    { lat: 48.6701269, lng: 21.6685001 },
    { lat: 48.6698684, lng: 21.6685152 },
    { lat: 48.6697316, lng: 21.6685728 },
    { lat: 48.6694619, lng: 21.6688313 },
    { lat: 48.6692303, lng: 21.6688369 },
    { lat: 48.6691662, lng: 21.6685885 },
    { lat: 48.6690051, lng: 21.6684658 },
    { lat: 48.6681896, lng: 21.6685099 },
    { lat: 48.6681988, lng: 21.6683545 },
    { lat: 48.6680951, lng: 21.6682257 },
    { lat: 48.6678605, lng: 21.6681588 },
    { lat: 48.6677921, lng: 21.6678818 },
    { lat: 48.6668556, lng: 21.6676996 },
    { lat: 48.6667686, lng: 21.6673214 },
    { lat: 48.6665266, lng: 21.6668602 },
    { lat: 48.6662372, lng: 21.6665294 },
    { lat: 48.6654772, lng: 21.6663427 },
    { lat: 48.6649441, lng: 21.6663948 },
    { lat: 48.6643137, lng: 21.6661277 },
    { lat: 48.6632564, lng: 21.6663481 },
    { lat: 48.6629376, lng: 21.6665791 },
    { lat: 48.6624796, lng: 21.6673583 },
    { lat: 48.6623119, lng: 21.6673147 },
    { lat: 48.6621162, lng: 21.6674831 },
    { lat: 48.6621233, lng: 21.6696758 },
    { lat: 48.6616908, lng: 21.670111 },
    { lat: 48.6612413, lng: 21.6679954 },
    { lat: 48.6607011, lng: 21.6665129 },
    { lat: 48.6602088, lng: 21.6668106 },
    { lat: 48.6599044, lng: 21.6655216 },
    { lat: 48.6596707, lng: 21.6651558 },
    { lat: 48.6593324, lng: 21.663533 },
    { lat: 48.6590133, lng: 21.6636298 },
    { lat: 48.6585962, lng: 21.6611782 },
    { lat: 48.6585904, lng: 21.6611757 },
    { lat: 48.6585267, lng: 21.6611455 },
    { lat: 48.6584808, lng: 21.6609533 },
    { lat: 48.6583931, lng: 21.6609531 },
    { lat: 48.6575367, lng: 21.6554531 },
    { lat: 48.6541544, lng: 21.6558175 },
    { lat: 48.6534431, lng: 21.6558948 },
    { lat: 48.6499002, lng: 21.656799 },
    { lat: 48.6491862, lng: 21.6538705 },
    { lat: 48.6488328, lng: 21.653989 },
    { lat: 48.6486785, lng: 21.6532838 },
    { lat: 48.6477066, lng: 21.6535394 },
    { lat: 48.6469873, lng: 21.6491123 },
    { lat: 48.6432358, lng: 21.6512321 },
    { lat: 48.6427757, lng: 21.6493938 },
    { lat: 48.6422271, lng: 21.6492866 },
    { lat: 48.6415721, lng: 21.6494483 },
    { lat: 48.6371853, lng: 21.6501417 },
    { lat: 48.6355972, lng: 21.650496 },
    { lat: 48.6350829, lng: 21.6507303 },
    { lat: 48.6343161, lng: 21.6511995 },
    { lat: 48.6338222, lng: 21.6513745 },
    { lat: 48.6326115, lng: 21.6516129 },
    { lat: 48.6298808, lng: 21.652394 },
    { lat: 48.6289074, lng: 21.6523476 },
    { lat: 48.6270566, lng: 21.6524329 },
    { lat: 48.6269141, lng: 21.6524806 },
    { lat: 48.627012, lng: 21.6528945 },
    { lat: 48.6274135, lng: 21.6554651 },
    { lat: 48.626096, lng: 21.6555493 },
    { lat: 48.6262102, lng: 21.6600327 },
    { lat: 48.6263347, lng: 21.6619071 },
    { lat: 48.625829, lng: 21.6621752 },
    { lat: 48.6259396, lng: 21.6627149 },
    { lat: 48.6260942, lng: 21.6635137 },
    { lat: 48.626491, lng: 21.6633758 },
    { lat: 48.6273965, lng: 21.6673437 },
    { lat: 48.6276885, lng: 21.6691739 },
    { lat: 48.6287554, lng: 21.6693924 },
    { lat: 48.6294158, lng: 21.6695984 },
    { lat: 48.6303849, lng: 21.6702313 },
    { lat: 48.6309068, lng: 21.6731813 },
    { lat: 48.6312775, lng: 21.6730373 },
    { lat: 48.6325437, lng: 21.6790684 },
    { lat: 48.6333855, lng: 21.6787418 },
    { lat: 48.6337005, lng: 21.678252 },
    { lat: 48.6338925, lng: 21.678149 },
    { lat: 48.633922, lng: 21.6780373 },
    { lat: 48.6345787, lng: 21.6777322 },
    { lat: 48.6345931, lng: 21.6777249 },
  ],
  Višňov: [
    { lat: 48.7566513, lng: 21.6594508 },
    { lat: 48.7565352, lng: 21.6594934 },
    { lat: 48.7563545, lng: 21.6595051 },
    { lat: 48.7563049, lng: 21.6595784 },
    { lat: 48.7559393, lng: 21.6597111 },
    { lat: 48.7544516, lng: 21.6601495 },
    { lat: 48.7538619, lng: 21.6603872 },
    { lat: 48.7536138, lng: 21.6599541 },
    { lat: 48.7527453, lng: 21.6602078 },
    { lat: 48.7523649, lng: 21.6602049 },
    { lat: 48.7508676, lng: 21.6608249 },
    { lat: 48.7499282, lng: 21.6609501 },
    { lat: 48.7491995, lng: 21.6613811 },
    { lat: 48.7483551, lng: 21.6620596 },
    { lat: 48.7477708, lng: 21.6622092 },
    { lat: 48.7477064, lng: 21.662235 },
    { lat: 48.7470265, lng: 21.6625118 },
    { lat: 48.7459138, lng: 21.6632559 },
    { lat: 48.7449567, lng: 21.6637172 },
    { lat: 48.7430972, lng: 21.6642705 },
    { lat: 48.7404729, lng: 21.6651914 },
    { lat: 48.7390208, lng: 21.6656233 },
    { lat: 48.7389944, lng: 21.6657582 },
    { lat: 48.7378685, lng: 21.6658582 },
    { lat: 48.7368788, lng: 21.6660765 },
    { lat: 48.7363155, lng: 21.6663844 },
    { lat: 48.7357038, lng: 21.6665973 },
    { lat: 48.7354175, lng: 21.6665417 },
    { lat: 48.7352741, lng: 21.6663579 },
    { lat: 48.7351955, lng: 21.6661564 },
    { lat: 48.734922, lng: 21.6661546 },
    { lat: 48.7346404, lng: 21.6660588 },
    { lat: 48.7346606, lng: 21.6708768 },
    { lat: 48.7341626, lng: 21.6709256 },
    { lat: 48.7341584, lng: 21.6734957 },
    { lat: 48.7342269, lng: 21.673696 },
    { lat: 48.734284, lng: 21.6743906 },
    { lat: 48.7342894, lng: 21.6766464 },
    { lat: 48.7342232, lng: 21.6767771 },
    { lat: 48.7343845, lng: 21.6795246 },
    { lat: 48.7345602, lng: 21.680728 },
    { lat: 48.7346274, lng: 21.6808397 },
    { lat: 48.7346626, lng: 21.680897 },
    { lat: 48.7346231, lng: 21.6809273 },
    { lat: 48.7346443, lng: 21.6809684 },
    { lat: 48.7353385, lng: 21.6805933 },
    { lat: 48.7362079, lng: 21.6850093 },
    { lat: 48.7357304, lng: 21.6856901 },
    { lat: 48.7356193, lng: 21.6867805 },
    { lat: 48.7360412, lng: 21.6891823 },
    { lat: 48.7359717, lng: 21.6896115 },
    { lat: 48.735975, lng: 21.690183 },
    { lat: 48.7361704, lng: 21.6923287 },
    { lat: 48.7359649, lng: 21.6924273 },
    { lat: 48.7359545, lng: 21.6926618 },
    { lat: 48.7359917, lng: 21.6926659 },
    { lat: 48.7369207, lng: 21.6969761 },
    { lat: 48.7370091, lng: 21.6969959 },
    { lat: 48.7371684, lng: 21.6967301 },
    { lat: 48.7373167, lng: 21.6966454 },
    { lat: 48.7373759, lng: 21.6967486 },
    { lat: 48.7379715, lng: 21.6961853 },
    { lat: 48.7382878, lng: 21.6960606 },
    { lat: 48.738545, lng: 21.6958754 },
    { lat: 48.7389096, lng: 21.6954735 },
    { lat: 48.7393844, lng: 21.6951408 },
    { lat: 48.7394543, lng: 21.6951465 },
    { lat: 48.7404486, lng: 21.6941752 },
    { lat: 48.7406568, lng: 21.6938864 },
    { lat: 48.7408942, lng: 21.6937662 },
    { lat: 48.740931, lng: 21.6935825 },
    { lat: 48.7415954, lng: 21.6931478 },
    { lat: 48.7417503, lng: 21.6928872 },
    { lat: 48.7419909, lng: 21.6927183 },
    { lat: 48.7420337, lng: 21.6925481 },
    { lat: 48.7426227, lng: 21.6921609 },
    { lat: 48.7428366, lng: 21.6919562 },
    { lat: 48.7429568, lng: 21.6917463 },
    { lat: 48.7434523, lng: 21.6915512 },
    { lat: 48.7437481, lng: 21.6912627 },
    { lat: 48.7442297, lng: 21.691092 },
    { lat: 48.7444223, lng: 21.6906843 },
    { lat: 48.7447515, lng: 21.6905823 },
    { lat: 48.7446975, lng: 21.6902523 },
    { lat: 48.7449449, lng: 21.690124 },
    { lat: 48.7450789, lng: 21.6894241 },
    { lat: 48.7457222, lng: 21.6892466 },
    { lat: 48.7457729, lng: 21.6890933 },
    { lat: 48.7459244, lng: 21.6890351 },
    { lat: 48.7459989, lng: 21.6892084 },
    { lat: 48.7461082, lng: 21.6892943 },
    { lat: 48.746289, lng: 21.6891629 },
    { lat: 48.7463804, lng: 21.6893747 },
    { lat: 48.7467326, lng: 21.6893411 },
    { lat: 48.7469029, lng: 21.6891451 },
    { lat: 48.7471665, lng: 21.6890315 },
    { lat: 48.7476676, lng: 21.6885922 },
    { lat: 48.7478529, lng: 21.6881211 },
    { lat: 48.7478155, lng: 21.6876999 },
    { lat: 48.7479307, lng: 21.6872621 },
    { lat: 48.7478413, lng: 21.6870805 },
    { lat: 48.747915, lng: 21.686883 },
    { lat: 48.7480096, lng: 21.6868971 },
    { lat: 48.7480978, lng: 21.6872774 },
    { lat: 48.7483063, lng: 21.6870038 },
    { lat: 48.748186, lng: 21.6867575 },
    { lat: 48.7482634, lng: 21.6866667 },
    { lat: 48.7484126, lng: 21.6869841 },
    { lat: 48.7485239, lng: 21.6868541 },
    { lat: 48.7485856, lng: 21.6864792 },
    { lat: 48.7488267, lng: 21.6862421 },
    { lat: 48.7490704, lng: 21.6863856 },
    { lat: 48.7493077, lng: 21.6862165 },
    { lat: 48.7495037, lng: 21.6859772 },
    { lat: 48.7497897, lng: 21.6860735 },
    { lat: 48.7497444, lng: 21.6863088 },
    { lat: 48.7497902, lng: 21.6864646 },
    { lat: 48.749855, lng: 21.6864902 },
    { lat: 48.7499284, lng: 21.6862956 },
    { lat: 48.7499786, lng: 21.6862743 },
    { lat: 48.7501521, lng: 21.6863602 },
    { lat: 48.7503104, lng: 21.6862705 },
    { lat: 48.7503449, lng: 21.686177 },
    { lat: 48.7501692, lng: 21.6859601 },
    { lat: 48.7501642, lng: 21.6856959 },
    { lat: 48.7501947, lng: 21.6856573 },
    { lat: 48.7504055, lng: 21.6855016 },
    { lat: 48.7507057, lng: 21.6854188 },
    { lat: 48.7508621, lng: 21.6852457 },
    { lat: 48.751038, lng: 21.6851774 },
    { lat: 48.7512095, lng: 21.6851992 },
    { lat: 48.7513685, lng: 21.6853348 },
    { lat: 48.7515369, lng: 21.6852413 },
    { lat: 48.7516039, lng: 21.6850948 },
    { lat: 48.7516629, lng: 21.684577 },
    { lat: 48.7517157, lng: 21.6845313 },
    { lat: 48.7519766, lng: 21.6846499 },
    { lat: 48.7520444, lng: 21.6844121 },
    { lat: 48.7522069, lng: 21.6842659 },
    { lat: 48.7524374, lng: 21.684387 },
    { lat: 48.7526718, lng: 21.6843614 },
    { lat: 48.7527083, lng: 21.6843003 },
    { lat: 48.7527652, lng: 21.6843206 },
    { lat: 48.7527747, lng: 21.684713 },
    { lat: 48.7529391, lng: 21.6847138 },
    { lat: 48.7530306, lng: 21.6844817 },
    { lat: 48.7529689, lng: 21.6840759 },
    { lat: 48.7530835, lng: 21.6838185 },
    { lat: 48.7532626, lng: 21.6837884 },
    { lat: 48.7532686, lng: 21.6838779 },
    { lat: 48.753501, lng: 21.6838242 },
    { lat: 48.7535994, lng: 21.6835931 },
    { lat: 48.7536376, lng: 21.6835933 },
    { lat: 48.7538904, lng: 21.6838656 },
    { lat: 48.7538463, lng: 21.6841066 },
    { lat: 48.7540025, lng: 21.6841382 },
    { lat: 48.7541219, lng: 21.6835257 },
    { lat: 48.7541485, lng: 21.6832199 },
    { lat: 48.7543452, lng: 21.6830066 },
    { lat: 48.7544862, lng: 21.6830231 },
    { lat: 48.7545175, lng: 21.682685 },
    { lat: 48.7545968, lng: 21.6825763 },
    { lat: 48.7545267, lng: 21.6824405 },
    { lat: 48.7545956, lng: 21.6821581 },
    { lat: 48.7544946, lng: 21.6820071 },
    { lat: 48.7545576, lng: 21.6817561 },
    { lat: 48.7545065, lng: 21.6816577 },
    { lat: 48.7542313, lng: 21.6816695 },
    { lat: 48.7541672, lng: 21.6815954 },
    { lat: 48.7541005, lng: 21.6816651 },
    { lat: 48.7540165, lng: 21.6816354 },
    { lat: 48.7540235, lng: 21.6815212 },
    { lat: 48.7544657, lng: 21.6814384 },
    { lat: 48.7544185, lng: 21.6811961 },
    { lat: 48.7542957, lng: 21.6812754 },
    { lat: 48.7541556, lng: 21.681048 },
    { lat: 48.7540848, lng: 21.6810428 },
    { lat: 48.7540633, lng: 21.6809197 },
    { lat: 48.7541387, lng: 21.680756 },
    { lat: 48.7543189, lng: 21.6809209 },
    { lat: 48.7544051, lng: 21.6808671 },
    { lat: 48.7545777, lng: 21.6804246 },
    { lat: 48.7546988, lng: 21.6804559 },
    { lat: 48.7547458, lng: 21.6804077 },
    { lat: 48.7547301, lng: 21.6802393 },
    { lat: 48.7546686, lng: 21.6802139 },
    { lat: 48.7546974, lng: 21.6798705 },
    { lat: 48.7546477, lng: 21.6797738 },
    { lat: 48.7546997, lng: 21.6792863 },
    { lat: 48.7546274, lng: 21.6792801 },
    { lat: 48.7545853, lng: 21.6791786 },
    { lat: 48.7548826, lng: 21.6787718 },
    { lat: 48.7548861, lng: 21.6786924 },
    { lat: 48.7547686, lng: 21.6786388 },
    { lat: 48.7547267, lng: 21.6783811 },
    { lat: 48.7548736, lng: 21.6784418 },
    { lat: 48.7549471, lng: 21.6782939 },
    { lat: 48.7550902, lng: 21.6784244 },
    { lat: 48.7551936, lng: 21.6784194 },
    { lat: 48.7552109, lng: 21.6782322 },
    { lat: 48.7557229, lng: 21.6783604 },
    { lat: 48.7557755, lng: 21.6782199 },
    { lat: 48.7556287, lng: 21.6780333 },
    { lat: 48.755589, lng: 21.677902 },
    { lat: 48.7556652, lng: 21.6778523 },
    { lat: 48.7558074, lng: 21.6779469 },
    { lat: 48.7558963, lng: 21.6779029 },
    { lat: 48.7559767, lng: 21.6776379 },
    { lat: 48.7560993, lng: 21.6774727 },
    { lat: 48.7564, lng: 21.6773216 },
    { lat: 48.7564725, lng: 21.6769599 },
    { lat: 48.7563518, lng: 21.6768304 },
    { lat: 48.7565444, lng: 21.6764662 },
    { lat: 48.7565195, lng: 21.6764199 },
    { lat: 48.7567857, lng: 21.6758211 },
    { lat: 48.7570654, lng: 21.6759733 },
    { lat: 48.7578864, lng: 21.6733534 },
    { lat: 48.7580737, lng: 21.6733413 },
    { lat: 48.758111, lng: 21.6732566 },
    { lat: 48.7578266, lng: 21.6724852 },
    { lat: 48.7577979, lng: 21.6702848 },
    { lat: 48.7575948, lng: 21.6693914 },
    { lat: 48.7576292, lng: 21.6693746 },
    { lat: 48.7576034, lng: 21.668637 },
    { lat: 48.7576741, lng: 21.6684686 },
    { lat: 48.7576446, lng: 21.6682964 },
    { lat: 48.7576956, lng: 21.6682629 },
    { lat: 48.7575974, lng: 21.6680437 },
    { lat: 48.7576458, lng: 21.6679711 },
    { lat: 48.7577994, lng: 21.6680667 },
    { lat: 48.7578706, lng: 21.6680437 },
    { lat: 48.7579055, lng: 21.6679121 },
    { lat: 48.7579908, lng: 21.6680751 },
    { lat: 48.7581289, lng: 21.6679438 },
    { lat: 48.758131, lng: 21.6678481 },
    { lat: 48.7580288, lng: 21.6678167 },
    { lat: 48.758097, lng: 21.6677186 },
    { lat: 48.7580576, lng: 21.6674312 },
    { lat: 48.7581973, lng: 21.6673947 },
    { lat: 48.7583108, lng: 21.6667735 },
    { lat: 48.7585303, lng: 21.6666539 },
    { lat: 48.7586055, lng: 21.6665258 },
    { lat: 48.7585262, lng: 21.6664181 },
    { lat: 48.7585142, lng: 21.6661874 },
    { lat: 48.7584373, lng: 21.6660881 },
    { lat: 48.7584235, lng: 21.6658771 },
    { lat: 48.758542, lng: 21.6658048 },
    { lat: 48.7585339, lng: 21.6654652 },
    { lat: 48.758399, lng: 21.6653096 },
    { lat: 48.7584235, lng: 21.6652128 },
    { lat: 48.7582028, lng: 21.6646796 },
    { lat: 48.758306, lng: 21.6645377 },
    { lat: 48.7582812, lng: 21.664372 },
    { lat: 48.7581237, lng: 21.6644599 },
    { lat: 48.7581574, lng: 21.6642513 },
    { lat: 48.7580035, lng: 21.6640556 },
    { lat: 48.7579345, lng: 21.663878 },
    { lat: 48.7578954, lng: 21.6633887 },
    { lat: 48.7583917, lng: 21.6622412 },
    { lat: 48.7584121, lng: 21.6617967 },
    { lat: 48.758864, lng: 21.6599373 },
    { lat: 48.7593331, lng: 21.6587033 },
    { lat: 48.7583048, lng: 21.6589917 },
    { lat: 48.7566603, lng: 21.659497 },
    { lat: 48.7566513, lng: 21.6594508 },
  ],
  Veľaty: [
    { lat: 48.5395219, lng: 21.6762807 },
    { lat: 48.5396518, lng: 21.6763419 },
    { lat: 48.539893, lng: 21.6761082 },
    { lat: 48.5399608, lng: 21.6761228 },
    { lat: 48.5400258, lng: 21.6764394 },
    { lat: 48.5401019, lng: 21.6765508 },
    { lat: 48.5401661, lng: 21.6765095 },
    { lat: 48.5402671, lng: 21.6761745 },
    { lat: 48.5402765, lng: 21.6756161 },
    { lat: 48.5404617, lng: 21.6751445 },
    { lat: 48.540426, lng: 21.6744959 },
    { lat: 48.5405395, lng: 21.6741646 },
    { lat: 48.5409846, lng: 21.6742297 },
    { lat: 48.541097, lng: 21.6739773 },
    { lat: 48.5413932, lng: 21.6739451 },
    { lat: 48.5417849, lng: 21.6741551 },
    { lat: 48.5420862, lng: 21.6744396 },
    { lat: 48.542294, lng: 21.6747513 },
    { lat: 48.5426867, lng: 21.6748345 },
    { lat: 48.5429052, lng: 21.6743948 },
    { lat: 48.5429505, lng: 21.6741203 },
    { lat: 48.5432021, lng: 21.6741015 },
    { lat: 48.5434954, lng: 21.6741721 },
    { lat: 48.5442907, lng: 21.6739991 },
    { lat: 48.5446478, lng: 21.6734795 },
    { lat: 48.5448363, lng: 21.6728797 },
    { lat: 48.5449654, lng: 21.6727044 },
    { lat: 48.5451437, lng: 21.6727694 },
    { lat: 48.5453565, lng: 21.6729781 },
    { lat: 48.5456089, lng: 21.6734815 },
    { lat: 48.5459242, lng: 21.6736242 },
    { lat: 48.5460146, lng: 21.6737557 },
    { lat: 48.5460897, lng: 21.6742425 },
    { lat: 48.5465325, lng: 21.6743048 },
    { lat: 48.5466561, lng: 21.6743827 },
    { lat: 48.5467492, lng: 21.6745374 },
    { lat: 48.5468974, lng: 21.6746007 },
    { lat: 48.5470354, lng: 21.6744653 },
    { lat: 48.5470495, lng: 21.6736193 },
    { lat: 48.5470905, lng: 21.6734392 },
    { lat: 48.5472281, lng: 21.6733671 },
    { lat: 48.547407, lng: 21.6734082 },
    { lat: 48.5474741, lng: 21.6736806 },
    { lat: 48.5477013, lng: 21.6737954 },
    { lat: 48.5479848, lng: 21.673509 },
    { lat: 48.5483163, lng: 21.6736212 },
    { lat: 48.548515, lng: 21.6735648 },
    { lat: 48.5484533, lng: 21.6731567 },
    { lat: 48.5484379, lng: 21.6731286 },
    { lat: 48.5480213, lng: 21.6718437 },
    { lat: 48.5487041, lng: 21.6709767 },
    { lat: 48.5482251, lng: 21.6686077 },
    { lat: 48.5475711, lng: 21.6687631 },
    { lat: 48.5471388, lng: 21.6689541 },
    { lat: 48.5462582, lng: 21.6696288 },
    { lat: 48.5454203, lng: 21.6699729 },
    { lat: 48.545145, lng: 21.6702353 },
    { lat: 48.5450351, lng: 21.6703721 },
    { lat: 48.5450697, lng: 21.670893 },
    { lat: 48.5450163, lng: 21.6714048 },
    { lat: 48.5447203, lng: 21.6717727 },
    { lat: 48.5441003, lng: 21.672798 },
    { lat: 48.543794, lng: 21.6729537 },
    { lat: 48.5435045, lng: 21.6729795 },
    { lat: 48.5420302, lng: 21.6683263 },
    { lat: 48.5423177, lng: 21.668226 },
    { lat: 48.5416901, lng: 21.6658155 },
    { lat: 48.5412776, lng: 21.6637051 },
    { lat: 48.5404035, lng: 21.6638345 },
    { lat: 48.540349, lng: 21.6622174 },
    { lat: 48.5404114, lng: 21.6592928 },
    { lat: 48.5401095, lng: 21.6592377 },
    { lat: 48.5404479, lng: 21.6566517 },
    { lat: 48.5398009, lng: 21.6562387 },
    { lat: 48.5401279, lng: 21.6542611 },
    { lat: 48.540645, lng: 21.6519216 },
    { lat: 48.5396196, lng: 21.6512522 },
    { lat: 48.5387662, lng: 21.6505046 },
    { lat: 48.5381405, lng: 21.6498077 },
    { lat: 48.5382844, lng: 21.6477263 },
    { lat: 48.5382684, lng: 21.6455175 },
    { lat: 48.5380325, lng: 21.6454425 },
    { lat: 48.5378445, lng: 21.6455277 },
    { lat: 48.5372091, lng: 21.6453744 },
    { lat: 48.5367001, lng: 21.6401084 },
    { lat: 48.5363514, lng: 21.6404392 },
    { lat: 48.5363409, lng: 21.6391237 },
    { lat: 48.5364154, lng: 21.6384857 },
    { lat: 48.5363815, lng: 21.6375097 },
    { lat: 48.5357847, lng: 21.6376941 },
    { lat: 48.534182, lng: 21.6383636 },
    { lat: 48.5339811, lng: 21.6384635 },
    { lat: 48.5338151, lng: 21.6386447 },
    { lat: 48.5328742, lng: 21.6388433 },
    { lat: 48.5326252, lng: 21.6391927 },
    { lat: 48.5316288, lng: 21.6414498 },
    { lat: 48.5311604, lng: 21.6430622 },
    { lat: 48.5309076, lng: 21.6437685 },
    { lat: 48.5308202, lng: 21.6439064 },
    { lat: 48.5301336, lng: 21.643729 },
    { lat: 48.52981, lng: 21.6437915 },
    { lat: 48.5297671, lng: 21.6435049 },
    { lat: 48.5297206, lng: 21.6434861 },
    { lat: 48.5293564, lng: 21.6435374 },
    { lat: 48.5294236, lng: 21.6446642 },
    { lat: 48.5293862, lng: 21.6468937 },
    { lat: 48.5283239, lng: 21.6464899 },
    { lat: 48.5269996, lng: 21.6456289 },
    { lat: 48.5262265, lng: 21.6454271 },
    { lat: 48.5254954, lng: 21.6453508 },
    { lat: 48.5246769, lng: 21.6454167 },
    { lat: 48.5240768, lng: 21.6455678 },
    { lat: 48.5220939, lng: 21.6462844 },
    { lat: 48.5219344, lng: 21.6454447 },
    { lat: 48.5216319, lng: 21.6454878 },
    { lat: 48.521581, lng: 21.6452315 },
    { lat: 48.5209437, lng: 21.6456693 },
    { lat: 48.5204428, lng: 21.6457852 },
    { lat: 48.5201449, lng: 21.645099 },
    { lat: 48.5187558, lng: 21.6459721 },
    { lat: 48.5189249, lng: 21.6465947 },
    { lat: 48.5191011, lng: 21.6477183 },
    { lat: 48.5183782, lng: 21.6479056 },
    { lat: 48.5174103, lng: 21.6479715 },
    { lat: 48.5154678, lng: 21.648488 },
    { lat: 48.5145024, lng: 21.6488505 },
    { lat: 48.5133491, lng: 21.6490603 },
    { lat: 48.5128582, lng: 21.6492922 },
    { lat: 48.5123405, lng: 21.6493711 },
    { lat: 48.511637, lng: 21.649605 },
    { lat: 48.5087056, lng: 21.651014 },
    { lat: 48.5077309, lng: 21.6511675 },
    { lat: 48.5066429, lng: 21.6514967 },
    { lat: 48.5047028, lng: 21.6530223 },
    { lat: 48.5043456, lng: 21.6532635 },
    { lat: 48.5030896, lng: 21.653171 },
    { lat: 48.502558, lng: 21.653069 },
    { lat: 48.5023887, lng: 21.6532935 },
    { lat: 48.5014094, lng: 21.653117 },
    { lat: 48.4986227, lng: 21.6536221 },
    { lat: 48.4978224, lng: 21.6538931 },
    { lat: 48.4951509, lng: 21.6545395 },
    { lat: 48.493564, lng: 21.6552182 },
    { lat: 48.4931464, lng: 21.6557381 },
    { lat: 48.4930375, lng: 21.6562802 },
    { lat: 48.4929186, lng: 21.6565416 },
    { lat: 48.4920575, lng: 21.6566524 },
    { lat: 48.4912682, lng: 21.6569127 },
    { lat: 48.4887647, lng: 21.6572799 },
    { lat: 48.4881864, lng: 21.657737 },
    { lat: 48.4876993, lng: 21.658225 },
    { lat: 48.4870971, lng: 21.6584204 },
    { lat: 48.4870567, lng: 21.6584261 },
    { lat: 48.4873339, lng: 21.6589281 },
    { lat: 48.4879395, lng: 21.6606954 },
    { lat: 48.4882838, lng: 21.6613067 },
    { lat: 48.4884173, lng: 21.6616598 },
    { lat: 48.4883766, lng: 21.6618617 },
    { lat: 48.4885951, lng: 21.661996 },
    { lat: 48.488599, lng: 21.6620936 },
    { lat: 48.4887552, lng: 21.6623737 },
    { lat: 48.4889377, lng: 21.6624992 },
    { lat: 48.489017, lng: 21.6627256 },
    { lat: 48.489196, lng: 21.6628574 },
    { lat: 48.4891303, lng: 21.6630406 },
    { lat: 48.4891375, lng: 21.6632572 },
    { lat: 48.4892141, lng: 21.6633078 },
    { lat: 48.4892033, lng: 21.6637752 },
    { lat: 48.4893298, lng: 21.6639146 },
    { lat: 48.4892729, lng: 21.66422 },
    { lat: 48.4893173, lng: 21.6643612 },
    { lat: 48.4894023, lng: 21.6643825 },
    { lat: 48.4895311, lng: 21.6646076 },
    { lat: 48.4893309, lng: 21.6647708 },
    { lat: 48.4893092, lng: 21.664881 },
    { lat: 48.489372, lng: 21.6649186 },
    { lat: 48.4894103, lng: 21.6648371 },
    { lat: 48.4895192, lng: 21.6648261 },
    { lat: 48.489601, lng: 21.6650225 },
    { lat: 48.4895672, lng: 21.6651384 },
    { lat: 48.4896155, lng: 21.6652599 },
    { lat: 48.4896823, lng: 21.6652377 },
    { lat: 48.4897216, lng: 21.6653945 },
    { lat: 48.4895896, lng: 21.6654999 },
    { lat: 48.4895835, lng: 21.6656911 },
    { lat: 48.4896744, lng: 21.6656851 },
    { lat: 48.4897142, lng: 21.6658234 },
    { lat: 48.4896545, lng: 21.6658194 },
    { lat: 48.4896623, lng: 21.6659416 },
    { lat: 48.4897687, lng: 21.6660045 },
    { lat: 48.4898497, lng: 21.6664975 },
    { lat: 48.4899954, lng: 21.666515 },
    { lat: 48.4900007, lng: 21.66663 },
    { lat: 48.4900946, lng: 21.6667266 },
    { lat: 48.4900084, lng: 21.6668228 },
    { lat: 48.4901661, lng: 21.6669935 },
    { lat: 48.4901306, lng: 21.6672221 },
    { lat: 48.4904485, lng: 21.6676109 },
    { lat: 48.4904603, lng: 21.6679546 },
    { lat: 48.4903812, lng: 21.6679414 },
    { lat: 48.4902959, lng: 21.6680723 },
    { lat: 48.4903392, lng: 21.6682143 },
    { lat: 48.4902533, lng: 21.6682466 },
    { lat: 48.4902455, lng: 21.6683308 },
    { lat: 48.4904247, lng: 21.6685467 },
    { lat: 48.4903478, lng: 21.6686909 },
    { lat: 48.490409, lng: 21.6687907 },
    { lat: 48.4903743, lng: 21.6689617 },
    { lat: 48.4903846, lng: 21.6690501 },
    { lat: 48.4904626, lng: 21.6690246 },
    { lat: 48.4905172, lng: 21.6694153 },
    { lat: 48.490408, lng: 21.6694906 },
    { lat: 48.4904877, lng: 21.6694821 },
    { lat: 48.4905789, lng: 21.6696105 },
    { lat: 48.4905468, lng: 21.669671 },
    { lat: 48.4906018, lng: 21.6698712 },
    { lat: 48.4905188, lng: 21.6699647 },
    { lat: 48.4907058, lng: 21.6700077 },
    { lat: 48.4906877, lng: 21.6701287 },
    { lat: 48.4905944, lng: 21.6702179 },
    { lat: 48.4907307, lng: 21.670469 },
    { lat: 48.490648, lng: 21.6704859 },
    { lat: 48.4906999, lng: 21.6706597 },
    { lat: 48.4906017, lng: 21.6707239 },
    { lat: 48.4906091, lng: 21.6709164 },
    { lat: 48.4906876, lng: 21.6709846 },
    { lat: 48.4907328, lng: 21.6711202 },
    { lat: 48.4907455, lng: 21.6712893 },
    { lat: 48.49065, lng: 21.6715055 },
    { lat: 48.4907407, lng: 21.6715294 },
    { lat: 48.4906874, lng: 21.671676 },
    { lat: 48.4907429, lng: 21.6716945 },
    { lat: 48.4907563, lng: 21.6717721 },
    { lat: 48.4906634, lng: 21.6718754 },
    { lat: 48.4907004, lng: 21.6721483 },
    { lat: 48.4907753, lng: 21.6721799 },
    { lat: 48.4907147, lng: 21.6722649 },
    { lat: 48.4908107, lng: 21.6724373 },
    { lat: 48.4906986, lng: 21.6724964 },
    { lat: 48.4907926, lng: 21.6726814 },
    { lat: 48.4909062, lng: 21.6726804 },
    { lat: 48.4908579, lng: 21.6727682 },
    { lat: 48.4909433, lng: 21.672803 },
    { lat: 48.4908592, lng: 21.6731845 },
    { lat: 48.4909633, lng: 21.673326 },
    { lat: 48.4911107, lng: 21.6733289 },
    { lat: 48.4911373, lng: 21.6735582 },
    { lat: 48.49126, lng: 21.6735186 },
    { lat: 48.4913041, lng: 21.6738532 },
    { lat: 48.4912251, lng: 21.6739752 },
    { lat: 48.4912273, lng: 21.6741326 },
    { lat: 48.4911775, lng: 21.6742413 },
    { lat: 48.4912717, lng: 21.6742727 },
    { lat: 48.4912398, lng: 21.6744659 },
    { lat: 48.4913375, lng: 21.6744616 },
    { lat: 48.4914331, lng: 21.6747416 },
    { lat: 48.4914146, lng: 21.6748502 },
    { lat: 48.4914771, lng: 21.6748776 },
    { lat: 48.4915853, lng: 21.6751248 },
    { lat: 48.4915241, lng: 21.6752333 },
    { lat: 48.4916071, lng: 21.6753867 },
    { lat: 48.4916151, lng: 21.6755586 },
    { lat: 48.4915264, lng: 21.6757712 },
    { lat: 48.4916763, lng: 21.6758194 },
    { lat: 48.4916866, lng: 21.6759919 },
    { lat: 48.4917488, lng: 21.6759625 },
    { lat: 48.4917528, lng: 21.6761442 },
    { lat: 48.4918317, lng: 21.6762368 },
    { lat: 48.4918434, lng: 21.6764564 },
    { lat: 48.4920265, lng: 21.6769786 },
    { lat: 48.49246, lng: 21.6774047 },
    { lat: 48.4924265, lng: 21.6777831 },
    { lat: 48.4924506, lng: 21.6780434 },
    { lat: 48.492715, lng: 21.6783746 },
    { lat: 48.4928549, lng: 21.6783837 },
    { lat: 48.4930928, lng: 21.6789795 },
    { lat: 48.4930822, lng: 21.6792451 },
    { lat: 48.4932058, lng: 21.6792846 },
    { lat: 48.4932154, lng: 21.6793911 },
    { lat: 48.4933511, lng: 21.6795782 },
    { lat: 48.4933603, lng: 21.6797518 },
    { lat: 48.4934248, lng: 21.679807 },
    { lat: 48.4935014, lng: 21.6800577 },
    { lat: 48.4937589, lng: 21.6803341 },
    { lat: 48.4938053, lng: 21.6805884 },
    { lat: 48.4940461, lng: 21.6810055 },
    { lat: 48.4941583, lng: 21.6814895 },
    { lat: 48.4941563, lng: 21.6819941 },
    { lat: 48.4942068, lng: 21.6821055 },
    { lat: 48.494321, lng: 21.6820488 },
    { lat: 48.4945733, lng: 21.6819367 },
    { lat: 48.4949189, lng: 21.6819792 },
    { lat: 48.4954276, lng: 21.6822765 },
    { lat: 48.4962669, lng: 21.6825148 },
    { lat: 48.4977915, lng: 21.6825634 },
    { lat: 48.4982344, lng: 21.6819698 },
    { lat: 48.4986087, lng: 21.6816663 },
    { lat: 48.5000874, lng: 21.6814268 },
    { lat: 48.500323, lng: 21.6814825 },
    { lat: 48.5009685, lng: 21.6818002 },
    { lat: 48.5018186, lng: 21.6812093 },
    { lat: 48.5028236, lng: 21.6809711 },
    { lat: 48.5030787, lng: 21.6807456 },
    { lat: 48.503307, lng: 21.6804235 },
    { lat: 48.5038921, lng: 21.6799718 },
    { lat: 48.5043962, lng: 21.6793087 },
    { lat: 48.5056069, lng: 21.6784882 },
    { lat: 48.5058462, lng: 21.6785131 },
    { lat: 48.5060383, lng: 21.6784579 },
    { lat: 48.5061317, lng: 21.6785884 },
    { lat: 48.5063515, lng: 21.6785215 },
    { lat: 48.5065181, lng: 21.6785612 },
    { lat: 48.5066793, lng: 21.6784913 },
    { lat: 48.5068167, lng: 21.6786186 },
    { lat: 48.5073458, lng: 21.678506 },
    { lat: 48.5078116, lng: 21.67859 },
    { lat: 48.5085506, lng: 21.6784977 },
    { lat: 48.5100352, lng: 21.6779007 },
    { lat: 48.5115587, lng: 21.6779393 },
    { lat: 48.5122955, lng: 21.6777677 },
    { lat: 48.5125419, lng: 21.6770285 },
    { lat: 48.5129652, lng: 21.6762982 },
    { lat: 48.5135884, lng: 21.6762127 },
    { lat: 48.5149761, lng: 21.6753998 },
    { lat: 48.5162478, lng: 21.67484 },
    { lat: 48.5163973, lng: 21.6736426 },
    { lat: 48.516995, lng: 21.6746755 },
    { lat: 48.5172605, lng: 21.6750039 },
    { lat: 48.5185121, lng: 21.6762561 },
    { lat: 48.5196641, lng: 21.6752658 },
    { lat: 48.5215572, lng: 21.6751994 },
    { lat: 48.5220473, lng: 21.6750557 },
    { lat: 48.5223272, lng: 21.6746817 },
    { lat: 48.5234741, lng: 21.6746907 },
    { lat: 48.5236608, lng: 21.6747753 },
    { lat: 48.5246248, lng: 21.6748077 },
    { lat: 48.5258879, lng: 21.6755241 },
    { lat: 48.5269962, lng: 21.6757343 },
    { lat: 48.5270052, lng: 21.6754437 },
    { lat: 48.5273917, lng: 21.6754311 },
    { lat: 48.527405, lng: 21.6749269 },
    { lat: 48.5292443, lng: 21.6750815 },
    { lat: 48.5306799, lng: 21.674949 },
    { lat: 48.5308867, lng: 21.6747772 },
    { lat: 48.5309091, lng: 21.6748986 },
    { lat: 48.5315972, lng: 21.6739734 },
    { lat: 48.5317454, lng: 21.6738388 },
    { lat: 48.5323616, lng: 21.6727597 },
    { lat: 48.5324274, lng: 21.6727357 },
    { lat: 48.5340942, lng: 21.6734945 },
    { lat: 48.5353314, lng: 21.6743347 },
    { lat: 48.5353901, lng: 21.6738496 },
    { lat: 48.5363558, lng: 21.6749357 },
    { lat: 48.5365552, lng: 21.675224 },
    { lat: 48.5366154, lng: 21.6750818 },
    { lat: 48.5371163, lng: 21.6748018 },
    { lat: 48.5376716, lng: 21.6746207 },
    { lat: 48.5387971, lng: 21.6740595 },
    { lat: 48.5392347, lng: 21.6754958 },
    { lat: 48.5395219, lng: 21.6762807 },
  ],
  Pribeník: [
    { lat: 48.3985375, lng: 21.9711886 },
    { lat: 48.3982531, lng: 21.9663219 },
    { lat: 48.3979304, lng: 21.9653771 },
    { lat: 48.3972862, lng: 21.9639032 },
    { lat: 48.3972169, lng: 21.963649 },
    { lat: 48.3971103, lng: 21.9629406 },
    { lat: 48.3970406, lng: 21.9611398 },
    { lat: 48.3967789, lng: 21.9610471 },
    { lat: 48.3966558, lng: 21.9610035 },
    { lat: 48.3959243, lng: 21.9611757 },
    { lat: 48.3957161, lng: 21.9613517 },
    { lat: 48.3953682, lng: 21.9619323 },
    { lat: 48.3947153, lng: 21.9619542 },
    { lat: 48.3921147, lng: 21.9617558 },
    { lat: 48.3921037, lng: 21.961492 },
    { lat: 48.3908615, lng: 21.9615771 },
    { lat: 48.3905087, lng: 21.9605531 },
    { lat: 48.3896184, lng: 21.9609916 },
    { lat: 48.3895067, lng: 21.9605327 },
    { lat: 48.3890934, lng: 21.9608243 },
    { lat: 48.389221, lng: 21.9613838 },
    { lat: 48.3881403, lng: 21.9622601 },
    { lat: 48.3879745, lng: 21.9623303 },
    { lat: 48.3868093, lng: 21.9622147 },
    { lat: 48.3864986, lng: 21.9621582 },
    { lat: 48.3861435, lng: 21.9614544 },
    { lat: 48.3858144, lng: 21.9598533 },
    { lat: 48.3851237, lng: 21.9600794 },
    { lat: 48.3852693, lng: 21.9609072 },
    { lat: 48.3834299, lng: 21.9616318 },
    { lat: 48.3833161, lng: 21.9610851 },
    { lat: 48.383093, lng: 21.9583283 },
    { lat: 48.3822833, lng: 21.9583764 },
    { lat: 48.3824525, lng: 21.9604248 },
    { lat: 48.3818699, lng: 21.9609318 },
    { lat: 48.3815781, lng: 21.9609996 },
    { lat: 48.3804262, lng: 21.9608291 },
    { lat: 48.3798691, lng: 21.9609967 },
    { lat: 48.379593, lng: 21.961156 },
    { lat: 48.379723, lng: 21.963304 },
    { lat: 48.379828, lng: 21.964839 },
    { lat: 48.379914, lng: 21.966239 },
    { lat: 48.380091, lng: 21.967798 },
    { lat: 48.379137, lng: 21.968397 },
    { lat: 48.379169, lng: 21.968482 },
    { lat: 48.379128, lng: 21.968508 },
    { lat: 48.38027, lng: 21.971394 },
    { lat: 48.379918, lng: 21.971538 },
    { lat: 48.379414, lng: 21.971847 },
    { lat: 48.379082, lng: 21.97208 },
    { lat: 48.378654, lng: 21.97243 },
    { lat: 48.378457, lng: 21.972916 },
    { lat: 48.378357, lng: 21.973142 },
    { lat: 48.378257, lng: 21.973415 },
    { lat: 48.378248, lng: 21.97351 },
    { lat: 48.378074, lng: 21.974073 },
    { lat: 48.378051, lng: 21.974396 },
    { lat: 48.378052, lng: 21.974858 },
    { lat: 48.378023, lng: 21.975031 },
    { lat: 48.377981, lng: 21.975196 },
    { lat: 48.377894, lng: 21.975375 },
    { lat: 48.377925, lng: 21.975419 },
    { lat: 48.378186, lng: 21.976532 },
    { lat: 48.378429, lng: 21.97757 },
    { lat: 48.378659, lng: 21.978554 },
    { lat: 48.378638, lng: 21.97856 },
    { lat: 48.378771, lng: 21.978892 },
    { lat: 48.3789, lng: 21.979284 },
    { lat: 48.379013, lng: 21.979643 },
    { lat: 48.379255, lng: 21.980282 },
    { lat: 48.379511, lng: 21.98025 },
    { lat: 48.379602, lng: 21.980542 },
    { lat: 48.37967, lng: 21.980902 },
    { lat: 48.379718, lng: 21.981452 },
    { lat: 48.379792, lng: 21.981908 },
    { lat: 48.379686, lng: 21.982151 },
    { lat: 48.379582, lng: 21.982215 },
    { lat: 48.379115, lng: 21.982107 },
    { lat: 48.378696, lng: 21.982442 },
    { lat: 48.378721, lng: 21.98256 },
    { lat: 48.378901, lng: 21.982645 },
    { lat: 48.379154, lng: 21.982721 },
    { lat: 48.37916, lng: 21.982744 },
    { lat: 48.379096, lng: 21.982893 },
    { lat: 48.378819, lng: 21.983001 },
    { lat: 48.378697, lng: 21.983185 },
    { lat: 48.378682, lng: 21.983414 },
    { lat: 48.379055, lng: 21.98338 },
    { lat: 48.379306, lng: 21.983393 },
    { lat: 48.37953, lng: 21.983364 },
    { lat: 48.379538, lng: 21.983399 },
    { lat: 48.378807, lng: 21.983896 },
    { lat: 48.378153, lng: 21.984322 },
    { lat: 48.377211, lng: 21.984958 },
    { lat: 48.376512, lng: 21.985426 },
    { lat: 48.375842, lng: 21.985906 },
    { lat: 48.375821, lng: 21.98592 },
    { lat: 48.375857, lng: 21.986255 },
    { lat: 48.37592, lng: 21.98651 },
    { lat: 48.376007, lng: 21.986555 },
    { lat: 48.376916, lng: 21.986741 },
    { lat: 48.377647, lng: 21.986895 },
    { lat: 48.377769, lng: 21.986934 },
    { lat: 48.377891, lng: 21.987013 },
    { lat: 48.37796, lng: 21.987122 },
    { lat: 48.378032, lng: 21.987295 },
    { lat: 48.378167, lng: 21.98782 },
    { lat: 48.378702, lng: 21.988648 },
    { lat: 48.378778, lng: 21.988791 },
    { lat: 48.378968, lng: 21.989348 },
    { lat: 48.379208, lng: 21.989091 },
    { lat: 48.379213, lng: 21.989121 },
    { lat: 48.379277, lng: 21.990346 },
    { lat: 48.3795, lng: 21.991652 },
    { lat: 48.379505, lng: 21.991679 },
    { lat: 48.378344, lng: 21.992255 },
    { lat: 48.377355, lng: 21.994393 },
    { lat: 48.377323, lng: 21.994463 },
    { lat: 48.37768, lng: 21.995114 },
    { lat: 48.377747, lng: 21.995186 },
    { lat: 48.37781, lng: 21.995214 },
    { lat: 48.377969, lng: 21.995224 },
    { lat: 48.378041, lng: 21.99525 },
    { lat: 48.378107, lng: 21.995303 },
    { lat: 48.378198, lng: 21.995444 },
    { lat: 48.378234, lng: 21.995533 },
    { lat: 48.378818, lng: 21.99797 },
    { lat: 48.378929, lng: 21.998295 },
    { lat: 48.379017, lng: 21.998459 },
    { lat: 48.379172, lng: 21.998668 },
    { lat: 48.379263, lng: 21.998758 },
    { lat: 48.379352, lng: 21.998815 },
    { lat: 48.379679, lng: 21.998865 },
    { lat: 48.379766, lng: 21.998914 },
    { lat: 48.379831, lng: 21.999035 },
    { lat: 48.379973, lng: 21.999901 },
    { lat: 48.380018, lng: 22.000051 },
    { lat: 48.380078, lng: 22.000147 },
    { lat: 48.380148, lng: 22.000186 },
    { lat: 48.380548, lng: 22.000256 },
    { lat: 48.380625, lng: 22.000297 },
    { lat: 48.38067, lng: 22.000352 },
    { lat: 48.380701, lng: 22.000456 },
    { lat: 48.380891, lng: 22.002108 },
    { lat: 48.380855, lng: 22.002273 },
    { lat: 48.380849, lng: 22.0023186 },
    { lat: 48.380846, lng: 22.002392 },
    { lat: 48.380846, lng: 22.002437 },
    { lat: 48.380857, lng: 22.00251 },
    { lat: 48.381452, lng: 22.003503 },
    { lat: 48.381483, lng: 22.003599 },
    { lat: 48.381495, lng: 22.003702 },
    { lat: 48.38121, lng: 22.005674 },
    { lat: 48.381196, lng: 22.005918 },
    { lat: 48.381261, lng: 22.006882 },
    { lat: 48.381285, lng: 22.006961 },
    { lat: 48.381317, lng: 22.007003 },
    { lat: 48.382031, lng: 22.00744 },
    { lat: 48.38209, lng: 22.007475 },
    { lat: 48.382228, lng: 22.007547 },
    { lat: 48.382344, lng: 22.007645 },
    { lat: 48.382411, lng: 22.007732 },
    { lat: 48.382488, lng: 22.007884 },
    { lat: 48.382598, lng: 22.008166 },
    { lat: 48.382632, lng: 22.008221 },
    { lat: 48.382675, lng: 22.008245 },
    { lat: 48.383906, lng: 22.008117 },
    { lat: 48.38396, lng: 22.008151 },
    { lat: 48.38401, lng: 22.008219 },
    { lat: 48.384044, lng: 22.008341 },
    { lat: 48.384049, lng: 22.00838 },
    { lat: 48.384032, lng: 22.008517 },
    { lat: 48.383797, lng: 22.009984 },
    { lat: 48.383794, lng: 22.010062 },
    { lat: 48.383817, lng: 22.010111 },
    { lat: 48.383832, lng: 22.010138 },
    { lat: 48.38386, lng: 22.010145 },
    { lat: 48.383922, lng: 22.010178 },
    { lat: 48.384341, lng: 22.01039 },
    { lat: 48.384389, lng: 22.010426 },
    { lat: 48.384459, lng: 22.010503 },
    { lat: 48.384509, lng: 22.010584 },
    { lat: 48.384569, lng: 22.010746 },
    { lat: 48.384716, lng: 22.011413 },
    { lat: 48.384916, lng: 22.012487 },
    { lat: 48.38512, lng: 22.014236 },
    { lat: 48.385154, lng: 22.014356 },
    { lat: 48.385208, lng: 22.014451 },
    { lat: 48.385408, lng: 22.014644 },
    { lat: 48.385528, lng: 22.014881 },
    { lat: 48.38564, lng: 22.015182 },
    { lat: 48.386036, lng: 22.017024 },
    { lat: 48.386131, lng: 22.017333 },
    { lat: 48.386332, lng: 22.017838 },
    { lat: 48.386432, lng: 22.018 },
    { lat: 48.386526, lng: 22.018102 },
    { lat: 48.386571, lng: 22.018131 },
    { lat: 48.386629, lng: 22.01818 },
    { lat: 48.386755, lng: 22.018228 },
    { lat: 48.387253, lng: 22.01838 },
    { lat: 48.387708, lng: 22.018524 },
    { lat: 48.387895, lng: 22.018628 },
    { lat: 48.388372, lng: 22.019035 },
    { lat: 48.388864, lng: 22.019499 },
    { lat: 48.388953, lng: 22.019623 },
    { lat: 48.389008, lng: 22.019769 },
    { lat: 48.389029, lng: 22.019925 },
    { lat: 48.389048, lng: 22.020953 },
    { lat: 48.389016, lng: 22.021121 },
    { lat: 48.388756, lng: 22.02205 },
    { lat: 48.388687, lng: 22.0224 },
    { lat: 48.388623, lng: 22.022976 },
    { lat: 48.388622, lng: 22.023013 },
    { lat: 48.388631, lng: 22.023048 },
    { lat: 48.389101, lng: 22.023869 },
    { lat: 48.389153, lng: 22.023932 },
    { lat: 48.389213, lng: 22.023976 },
    { lat: 48.389779, lng: 22.024078 },
    { lat: 48.389931, lng: 22.024058 },
    { lat: 48.390037, lng: 22.023984 },
    { lat: 48.390131, lng: 22.023868 },
    { lat: 48.390645, lng: 22.022989 },
    { lat: 48.390677, lng: 22.022892 },
    { lat: 48.390793, lng: 22.022189 },
    { lat: 48.390806, lng: 22.022092 },
    { lat: 48.390803, lng: 22.021996 },
    { lat: 48.390751, lng: 22.021273 },
    { lat: 48.390764, lng: 22.021212 },
    { lat: 48.390797, lng: 22.021172 },
    { lat: 48.390834, lng: 22.021153 },
    { lat: 48.391265, lng: 22.021177 },
    { lat: 48.391539, lng: 22.021222 },
    { lat: 48.391682, lng: 22.02123 },
    { lat: 48.392403, lng: 22.021121 },
    { lat: 48.392516, lng: 22.021137 },
    { lat: 48.392578, lng: 22.021146 },
    { lat: 48.392586, lng: 22.021167 },
    { lat: 48.392749, lng: 22.021326 },
    { lat: 48.39274, lng: 22.021347 },
    { lat: 48.3927331, lng: 22.0213621 },
    { lat: 48.3930597, lng: 22.0212719 },
    { lat: 48.3933381, lng: 22.0209691 },
    { lat: 48.3941102, lng: 22.0205311 },
    { lat: 48.3950569, lng: 22.0204977 },
    { lat: 48.3952901, lng: 22.0204243 },
    { lat: 48.3961825, lng: 22.0204385 },
    { lat: 48.3967418, lng: 22.020579 },
    { lat: 48.3972747, lng: 22.0208448 },
    { lat: 48.3976963, lng: 22.021291 },
    { lat: 48.3980128, lng: 22.0212587 },
    { lat: 48.3984762, lng: 22.0206069 },
    { lat: 48.4003572, lng: 22.0186806 },
    { lat: 48.4004935, lng: 22.0184126 },
    { lat: 48.4007855, lng: 22.0181228 },
    { lat: 48.4009002, lng: 22.0179352 },
    { lat: 48.4011085, lng: 22.0173427 },
    { lat: 48.4022082, lng: 22.0167627 },
    { lat: 48.4028055, lng: 22.0160754 },
    { lat: 48.4031657, lng: 22.0155269 },
    { lat: 48.4031281, lng: 22.0150985 },
    { lat: 48.4031547, lng: 22.0146616 },
    { lat: 48.4032581, lng: 22.0141588 },
    { lat: 48.4033791, lng: 22.0138628 },
    { lat: 48.4035549, lng: 22.0137373 },
    { lat: 48.4037188, lng: 22.0136822 },
    { lat: 48.4038934, lng: 22.0137335 },
    { lat: 48.4041452, lng: 22.0124864 },
    { lat: 48.4050783, lng: 22.0123247 },
    { lat: 48.4057842, lng: 22.0120187 },
    { lat: 48.4078676, lng: 22.0114694 },
    { lat: 48.4076796, lng: 22.010954 },
    { lat: 48.4071294, lng: 22.0097629 },
    { lat: 48.4072157, lng: 22.0097432 },
    { lat: 48.4077804, lng: 22.0100671 },
    { lat: 48.4093566, lng: 22.0089696 },
    { lat: 48.4100588, lng: 22.0082467 },
    { lat: 48.4106234, lng: 22.0080075 },
    { lat: 48.4104872, lng: 22.0062619 },
    { lat: 48.4109101, lng: 22.0061656 },
    { lat: 48.4120544, lng: 22.005628 },
    { lat: 48.4129959, lng: 22.0045255 },
    { lat: 48.4131385, lng: 22.0042828 },
    { lat: 48.4143173, lng: 22.0011624 },
    { lat: 48.4144091, lng: 22.000688 },
    { lat: 48.414343, lng: 21.9998308 },
    { lat: 48.4142929, lng: 21.9997196 },
    { lat: 48.4139434, lng: 21.9990088 },
    { lat: 48.4137567, lng: 21.9987299 },
    { lat: 48.4131472, lng: 21.9982389 },
    { lat: 48.4126709, lng: 21.9980788 },
    { lat: 48.4119356, lng: 21.9956795 },
    { lat: 48.4115488, lng: 21.9956426 },
    { lat: 48.4113913, lng: 21.995555 },
    { lat: 48.4113822, lng: 21.9954181 },
    { lat: 48.4116841, lng: 21.994612 },
    { lat: 48.4117217, lng: 21.9942608 },
    { lat: 48.4117049, lng: 21.9941855 },
    { lat: 48.4109802, lng: 21.9909349 },
    { lat: 48.4109513, lng: 21.9903569 },
    { lat: 48.4111277, lng: 21.9887107 },
    { lat: 48.4111549, lng: 21.9885274 },
    { lat: 48.4112502, lng: 21.9878859 },
    { lat: 48.4114603, lng: 21.9871897 },
    { lat: 48.4114833, lng: 21.9865301 },
    { lat: 48.4112225, lng: 21.985982 },
    { lat: 48.4107858, lng: 21.9857054 },
    { lat: 48.4103689, lng: 21.9857751 },
    { lat: 48.4102835, lng: 21.9855908 },
    { lat: 48.4098541, lng: 21.9853792 },
    { lat: 48.4088397, lng: 21.9838535 },
    { lat: 48.4087065, lng: 21.9836084 },
    { lat: 48.4085399, lng: 21.9831047 },
    { lat: 48.4081989, lng: 21.982463 },
    { lat: 48.4073097, lng: 21.9818552 },
    { lat: 48.4062993, lng: 21.9815785 },
    { lat: 48.4055647, lng: 21.9815307 },
    { lat: 48.4038194, lng: 21.98188 },
    { lat: 48.4031178, lng: 21.9818024 },
    { lat: 48.4027605, lng: 21.9816361 },
    { lat: 48.4018845, lng: 21.9810673 },
    { lat: 48.4015134, lng: 21.9806653 },
    { lat: 48.4007961, lng: 21.979091 },
    { lat: 48.4003974, lng: 21.9769905 },
    { lat: 48.4001986, lng: 21.9762855 },
    { lat: 48.3999802, lng: 21.9757295 },
    { lat: 48.3996892, lng: 21.9752902 },
    { lat: 48.3988033, lng: 21.9745025 },
    { lat: 48.3988145, lng: 21.9739107 },
    { lat: 48.3985048, lng: 21.9716839 },
    { lat: 48.3985375, lng: 21.9711886 },
  ],
  Kuzmice: [
    { lat: 48.5634308, lng: 21.6021828 },
    { lat: 48.5653476, lng: 21.6008837 },
    { lat: 48.56583, lng: 21.6005887 },
    { lat: 48.5672033, lng: 21.5999248 },
    { lat: 48.5672604, lng: 21.6001205 },
    { lat: 48.570186, lng: 21.599319 },
    { lat: 48.5701639, lng: 21.5969582 },
    { lat: 48.5700926, lng: 21.5954663 },
    { lat: 48.569959, lng: 21.59395 },
    { lat: 48.5698641, lng: 21.591452 },
    { lat: 48.5701948, lng: 21.591373 },
    { lat: 48.5701826, lng: 21.5910985 },
    { lat: 48.5705525, lng: 21.5908017 },
    { lat: 48.5706976, lng: 21.5906034 },
    { lat: 48.5706066, lng: 21.589807 },
    { lat: 48.5708217, lng: 21.5894145 },
    { lat: 48.5708599, lng: 21.5892067 },
    { lat: 48.5703203, lng: 21.5893847 },
    { lat: 48.5700233, lng: 21.5893901 },
    { lat: 48.5695067, lng: 21.5870985 },
    { lat: 48.5677725, lng: 21.584813 },
    { lat: 48.5669269, lng: 21.5857154 },
    { lat: 48.5662872, lng: 21.5846493 },
    { lat: 48.5656035, lng: 21.5833452 },
    { lat: 48.565836, lng: 21.5830525 },
    { lat: 48.5654058, lng: 21.5822448 },
    { lat: 48.5660276, lng: 21.5814253 },
    { lat: 48.5666234, lng: 21.5807715 },
    { lat: 48.5672453, lng: 21.5802003 },
    { lat: 48.5681818, lng: 21.5794976 },
    { lat: 48.5690155, lng: 21.5790514 },
    { lat: 48.5698587, lng: 21.5788695 },
    { lat: 48.5710809, lng: 21.5787124 },
    { lat: 48.5713025, lng: 21.5786282 },
    { lat: 48.5717712, lng: 21.5786113 },
    { lat: 48.5722903, lng: 21.5786662 },
    { lat: 48.572375, lng: 21.5787581 },
    { lat: 48.5746762, lng: 21.5788815 },
    { lat: 48.5777203, lng: 21.579295 },
    { lat: 48.5785123, lng: 21.5792524 },
    { lat: 48.5785293, lng: 21.5809339 },
    { lat: 48.5785783, lng: 21.5816357 },
    { lat: 48.5786567, lng: 21.5815824 },
    { lat: 48.5786212, lng: 21.5817606 },
    { lat: 48.5786484, lng: 21.581805 },
    { lat: 48.5799721, lng: 21.581506 },
    { lat: 48.5801504, lng: 21.5834004 },
    { lat: 48.5807743, lng: 21.5832574 },
    { lat: 48.5808624, lng: 21.5844248 },
    { lat: 48.5817858, lng: 21.5850136 },
    { lat: 48.5828184, lng: 21.5851626 },
    { lat: 48.584684, lng: 21.5846866 },
    { lat: 48.5851867, lng: 21.5847543 },
    { lat: 48.586015, lng: 21.5849939 },
    { lat: 48.5868478, lng: 21.5851198 },
    { lat: 48.5875277, lng: 21.5851328 },
    { lat: 48.5876279, lng: 21.5851349 },
    { lat: 48.5872414, lng: 21.5814788 },
    { lat: 48.5867817, lng: 21.5788217 },
    { lat: 48.5866288, lng: 21.5789022 },
    { lat: 48.5864678, lng: 21.5788617 },
    { lat: 48.5861408, lng: 21.5790803 },
    { lat: 48.5860043, lng: 21.5790236 },
    { lat: 48.5857098, lng: 21.5791143 },
    { lat: 48.5853675, lng: 21.5789908 },
    { lat: 48.585218, lng: 21.579032 },
    { lat: 48.5851826, lng: 21.5789319 },
    { lat: 48.5853143, lng: 21.5787771 },
    { lat: 48.5853209, lng: 21.5785673 },
    { lat: 48.5849262, lng: 21.5784185 },
    { lat: 48.5848756, lng: 21.5785792 },
    { lat: 48.5846986, lng: 21.5786477 },
    { lat: 48.5846525, lng: 21.5785281 },
    { lat: 48.5847319, lng: 21.5781907 },
    { lat: 48.584573, lng: 21.5780651 },
    { lat: 48.5844822, lng: 21.5778392 },
    { lat: 48.588845, lng: 21.5761345 },
    { lat: 48.5913667, lng: 21.5723169 },
    { lat: 48.5908257, lng: 21.5714041 },
    { lat: 48.5900253, lng: 21.5722228 },
    { lat: 48.5890061, lng: 21.5712419 },
    { lat: 48.5885589, lng: 21.5691108 },
    { lat: 48.5884634, lng: 21.568877 },
    { lat: 48.5884308, lng: 21.5686826 },
    { lat: 48.5884621, lng: 21.5684389 },
    { lat: 48.5884146, lng: 21.568186 },
    { lat: 48.5881732, lng: 21.56757 },
    { lat: 48.5882595, lng: 21.5674788 },
    { lat: 48.5883078, lng: 21.5671945 },
    { lat: 48.5882299, lng: 21.5662321 },
    { lat: 48.5884934, lng: 21.565686 },
    { lat: 48.588373, lng: 21.565322 },
    { lat: 48.5884153, lng: 21.5651934 },
    { lat: 48.5883564, lng: 21.5647039 },
    { lat: 48.5884363, lng: 21.5646177 },
    { lat: 48.5884553, lng: 21.5641833 },
    { lat: 48.5885507, lng: 21.5641288 },
    { lat: 48.5885812, lng: 21.5638969 },
    { lat: 48.5883581, lng: 21.5635292 },
    { lat: 48.5883272, lng: 21.5631118 },
    { lat: 48.5884115, lng: 21.5629528 },
    { lat: 48.588543, lng: 21.5623078 },
    { lat: 48.5886604, lng: 21.5620834 },
    { lat: 48.5886297, lng: 21.5619557 },
    { lat: 48.5885059, lng: 21.5619128 },
    { lat: 48.5885039, lng: 21.5614847 },
    { lat: 48.5886596, lng: 21.5611807 },
    { lat: 48.5885659, lng: 21.5609696 },
    { lat: 48.5886195, lng: 21.5606381 },
    { lat: 48.588608, lng: 21.5601914 },
    { lat: 48.588481, lng: 21.5599202 },
    { lat: 48.5884723, lng: 21.5592139 },
    { lat: 48.5883087, lng: 21.5588398 },
    { lat: 48.5881913, lng: 21.5587514 },
    { lat: 48.5879115, lng: 21.5581294 },
    { lat: 48.5879669, lng: 21.5578241 },
    { lat: 48.5879227, lng: 21.5575344 },
    { lat: 48.5880664, lng: 21.5571655 },
    { lat: 48.5881214, lng: 21.5567276 },
    { lat: 48.5879566, lng: 21.5564717 },
    { lat: 48.5879615, lng: 21.5554151 },
    { lat: 48.5880185, lng: 21.5552771 },
    { lat: 48.5879536, lng: 21.5537487 },
    { lat: 48.5877414, lng: 21.5534095 },
    { lat: 48.5877407, lng: 21.5528288 },
    { lat: 48.5879726, lng: 21.5525156 },
    { lat: 48.5879548, lng: 21.5523647 },
    { lat: 48.5877225, lng: 21.5523043 },
    { lat: 48.5878003, lng: 21.5515583 },
    { lat: 48.5877129, lng: 21.5513773 },
    { lat: 48.5877456, lng: 21.551216 },
    { lat: 48.5878641, lng: 21.5510715 },
    { lat: 48.5880229, lng: 21.5509998 },
    { lat: 48.5880289, lng: 21.5508981 },
    { lat: 48.5881474, lng: 21.5509018 },
    { lat: 48.5882379, lng: 21.5507687 },
    { lat: 48.5882011, lng: 21.5500219 },
    { lat: 48.5882364, lng: 21.5490891 },
    { lat: 48.5883305, lng: 21.5486022 },
    { lat: 48.5885347, lng: 21.5482186 },
    { lat: 48.5885076, lng: 21.5477072 },
    { lat: 48.5886894, lng: 21.5475678 },
    { lat: 48.588611, lng: 21.5467515 },
    { lat: 48.5888164, lng: 21.545979 },
    { lat: 48.5889855, lng: 21.5459567 },
    { lat: 48.5891129, lng: 21.5457708 },
    { lat: 48.5891884, lng: 21.5454158 },
    { lat: 48.5891649, lng: 21.5448618 },
    { lat: 48.5890718, lng: 21.5446837 },
    { lat: 48.5891222, lng: 21.5445159 },
    { lat: 48.5892438, lng: 21.5444373 },
    { lat: 48.5894023, lng: 21.5445479 },
    { lat: 48.58951, lng: 21.5444638 },
    { lat: 48.5895583, lng: 21.5443377 },
    { lat: 48.5894373, lng: 21.5439211 },
    { lat: 48.5894812, lng: 21.5435682 },
    { lat: 48.5897473, lng: 21.5432649 },
    { lat: 48.5897497, lng: 21.5422087 },
    { lat: 48.5895571, lng: 21.5419262 },
    { lat: 48.5894688, lng: 21.5416266 },
    { lat: 48.5892608, lng: 21.5414769 },
    { lat: 48.5890952, lng: 21.5408024 },
    { lat: 48.5891217, lng: 21.5406265 },
    { lat: 48.5889619, lng: 21.5406589 },
    { lat: 48.5888963, lng: 21.5406081 },
    { lat: 48.58893, lng: 21.5403767 },
    { lat: 48.589002, lng: 21.539948 },
    { lat: 48.58895, lng: 21.539945 },
    { lat: 48.588911, lng: 21.539949 },
    { lat: 48.588627, lng: 21.539741 },
    { lat: 48.588464, lng: 21.539647 },
    { lat: 48.5884, lng: 21.539528 },
    { lat: 48.588307, lng: 21.539551 },
    { lat: 48.588235, lng: 21.539373 },
    { lat: 48.588148, lng: 21.539349 },
    { lat: 48.588006, lng: 21.539342 },
    { lat: 48.587839, lng: 21.539031 },
    { lat: 48.587704, lng: 21.538819 },
    { lat: 48.58747, lng: 21.538517 },
    { lat: 48.587339, lng: 21.538364 },
    { lat: 48.587181, lng: 21.5381 },
    { lat: 48.587029, lng: 21.537729 },
    { lat: 48.586936, lng: 21.537612 },
    { lat: 48.586827, lng: 21.537437 },
    { lat: 48.5867, lng: 21.537182 },
    { lat: 48.586666, lng: 21.536994 },
    { lat: 48.586584, lng: 21.536787 },
    { lat: 48.586421, lng: 21.536689 },
    { lat: 48.58631, lng: 21.536671 },
    { lat: 48.5861, lng: 21.536455 },
    { lat: 48.585891, lng: 21.536281 },
    { lat: 48.585808, lng: 21.536386 },
    { lat: 48.585136, lng: 21.535771 },
    { lat: 48.585003, lng: 21.535662 },
    { lat: 48.584934, lng: 21.53555 },
    { lat: 48.584626, lng: 21.535499 },
    { lat: 48.584249, lng: 21.53553 },
    { lat: 48.583914, lng: 21.535383 },
    { lat: 48.58381, lng: 21.53512 },
    { lat: 48.583656, lng: 21.534888 },
    { lat: 48.583164, lng: 21.534057 },
    { lat: 48.583145, lng: 21.534023 },
    { lat: 48.582782, lng: 21.533657 },
    { lat: 48.582537, lng: 21.533356 },
    { lat: 48.582511, lng: 21.533322 },
    { lat: 48.582167, lng: 21.532898 },
    { lat: 48.581468, lng: 21.53236 },
    { lat: 48.581044, lng: 21.531793 },
    { lat: 48.580687, lng: 21.531452 },
    { lat: 48.580043, lng: 21.531081 },
    { lat: 48.579806, lng: 21.531058 },
    { lat: 48.578781, lng: 21.530508 },
    { lat: 48.57856, lng: 21.53031 },
    { lat: 48.578188, lng: 21.530226 },
    { lat: 48.577945, lng: 21.529673 },
    { lat: 48.577684, lng: 21.528938 },
    { lat: 48.577604, lng: 21.528655 },
    { lat: 48.577551, lng: 21.528472 },
    { lat: 48.576988, lng: 21.52805 },
    { lat: 48.576926, lng: 21.527843 },
    { lat: 48.576845, lng: 21.527734 },
    { lat: 48.576561, lng: 21.527657 },
    { lat: 48.576303, lng: 21.527639 },
    { lat: 48.575643, lng: 21.527243 },
    { lat: 48.575554, lng: 21.526863 },
    { lat: 48.575446, lng: 21.526844 },
    { lat: 48.574807, lng: 21.526321 },
    { lat: 48.574289, lng: 21.526014 },
    { lat: 48.574094, lng: 21.525851 },
    { lat: 48.573876, lng: 21.525651 },
    { lat: 48.573697, lng: 21.525371 },
    { lat: 48.573419, lng: 21.525063 },
    { lat: 48.572968, lng: 21.524434 },
    { lat: 48.572112, lng: 21.523634 },
    { lat: 48.571731, lng: 21.523224 },
    { lat: 48.57104, lng: 21.52232 },
    { lat: 48.570386, lng: 21.521141 },
    { lat: 48.570122, lng: 21.520771 },
    { lat: 48.569941, lng: 21.520597 },
    { lat: 48.569559, lng: 21.519997 },
    { lat: 48.569142, lng: 21.518984 },
    { lat: 48.568885, lng: 21.518513 },
    { lat: 48.568506, lng: 21.518144 },
    { lat: 48.5682548, lng: 21.5185125 },
    { lat: 48.5680159, lng: 21.5185778 },
    { lat: 48.5676565, lng: 21.5191527 },
    { lat: 48.5673792, lng: 21.51925 },
    { lat: 48.5671246, lng: 21.5196716 },
    { lat: 48.5667185, lng: 21.5197503 },
    { lat: 48.5667357, lng: 21.5200788 },
    { lat: 48.5663201, lng: 21.5205354 },
    { lat: 48.5660205, lng: 21.5209976 },
    { lat: 48.5658237, lng: 21.5217494 },
    { lat: 48.5658479, lng: 21.522043 },
    { lat: 48.565957, lng: 21.5222349 },
    { lat: 48.5661544, lng: 21.5228388 },
    { lat: 48.5661407, lng: 21.5233797 },
    { lat: 48.5662045, lng: 21.5237613 },
    { lat: 48.5661033, lng: 21.5240397 },
    { lat: 48.5662404, lng: 21.5247037 },
    { lat: 48.5660049, lng: 21.5253951 },
    { lat: 48.5660545, lng: 21.5257535 },
    { lat: 48.5662635, lng: 21.5260607 },
    { lat: 48.5662237, lng: 21.5263969 },
    { lat: 48.5662623, lng: 21.5269152 },
    { lat: 48.5663765, lng: 21.5273295 },
    { lat: 48.5664632, lng: 21.5274901 },
    { lat: 48.5664337, lng: 21.5279503 },
    { lat: 48.5665519, lng: 21.5282258 },
    { lat: 48.5665867, lng: 21.528501 },
    { lat: 48.5664731, lng: 21.5285783 },
    { lat: 48.5665739, lng: 21.5288791 },
    { lat: 48.566483, lng: 21.5294751 },
    { lat: 48.5662561, lng: 21.5294497 },
    { lat: 48.566222, lng: 21.5295983 },
    { lat: 48.5664062, lng: 21.5302535 },
    { lat: 48.5664914, lng: 21.5308722 },
    { lat: 48.5665471, lng: 21.5310057 },
    { lat: 48.5664755, lng: 21.5311415 },
    { lat: 48.5665141, lng: 21.5312718 },
    { lat: 48.5664964, lng: 21.5315579 },
    { lat: 48.565937, lng: 21.5316308 },
    { lat: 48.5645748, lng: 21.5322799 },
    { lat: 48.5635922, lng: 21.532895 },
    { lat: 48.5629776, lng: 21.5332042 },
    { lat: 48.5616971, lng: 21.5432515 },
    { lat: 48.5612173, lng: 21.547622 },
    { lat: 48.5607951, lng: 21.5506151 },
    { lat: 48.5606775, lng: 21.5517459 },
    { lat: 48.5605948, lng: 21.5521221 },
    { lat: 48.5603361, lng: 21.5548978 },
    { lat: 48.5600005, lng: 21.559724 },
    { lat: 48.560105, lng: 21.5601549 },
    { lat: 48.5601016, lng: 21.5607523 },
    { lat: 48.5602932, lng: 21.5642495 },
    { lat: 48.5603217, lng: 21.5657743 },
    { lat: 48.5602469, lng: 21.5665535 },
    { lat: 48.5572724, lng: 21.5787959 },
    { lat: 48.5569993, lng: 21.5806082 },
    { lat: 48.5565629, lng: 21.5840487 },
    { lat: 48.552321, lng: 21.5826122 },
    { lat: 48.552623, lng: 21.5839833 },
    { lat: 48.5531169, lng: 21.5859421 },
    { lat: 48.5531938, lng: 21.5860782 },
    { lat: 48.5536271, lng: 21.5865598 },
    { lat: 48.5539454, lng: 21.5866361 },
    { lat: 48.5545532, lng: 21.586165 },
    { lat: 48.5559884, lng: 21.5879395 },
    { lat: 48.5558289, lng: 21.5887496 },
    { lat: 48.5572214, lng: 21.5906498 },
    { lat: 48.5575698, lng: 21.590396 },
    { lat: 48.5580244, lng: 21.5899187 },
    { lat: 48.5585863, lng: 21.5891316 },
    { lat: 48.5589113, lng: 21.5885699 },
    { lat: 48.5595225, lng: 21.5879363 },
    { lat: 48.5599955, lng: 21.5870721 },
    { lat: 48.5603426, lng: 21.5868894 },
    { lat: 48.5605087, lng: 21.5863555 },
    { lat: 48.5627559, lng: 21.5844753 },
    { lat: 48.5627983, lng: 21.5849095 },
    { lat: 48.5627142, lng: 21.5849991 },
    { lat: 48.562634, lng: 21.5854667 },
    { lat: 48.562738, lng: 21.586118 },
    { lat: 48.5625989, lng: 21.5867737 },
    { lat: 48.5625962, lng: 21.5870062 },
    { lat: 48.562441, lng: 21.5877667 },
    { lat: 48.5622611, lng: 21.5881693 },
    { lat: 48.5620915, lng: 21.5887442 },
    { lat: 48.5617315, lng: 21.5895419 },
    { lat: 48.5612818, lng: 21.5900782 },
    { lat: 48.5613079, lng: 21.5901256 },
    { lat: 48.5615947, lng: 21.5903204 },
    { lat: 48.5624709, lng: 21.5904315 },
    { lat: 48.562468, lng: 21.590724 },
    { lat: 48.5621723, lng: 21.590972 },
    { lat: 48.5617523, lng: 21.5907604 },
    { lat: 48.5614722, lng: 21.5909282 },
    { lat: 48.5610918, lng: 21.5912864 },
    { lat: 48.5607782, lng: 21.5916974 },
    { lat: 48.5605119, lng: 21.5921897 },
    { lat: 48.5603755, lng: 21.5928985 },
    { lat: 48.5602608, lng: 21.5932223 },
    { lat: 48.5606242, lng: 21.5936634 },
    { lat: 48.5607763, lng: 21.5939915 },
    { lat: 48.5611916, lng: 21.5953199 },
    { lat: 48.5612492, lng: 21.5956849 },
    { lat: 48.5625362, lng: 21.5998927 },
    { lat: 48.5629003, lng: 21.6009626 },
    { lat: 48.5634308, lng: 21.6021828 },
  ],
  Kožuchov: [
    { lat: 48.5689515, lng: 21.693864 },
    { lat: 48.5680505, lng: 21.6943715 },
    { lat: 48.5657864, lng: 21.6951433 },
    { lat: 48.5637915, lng: 21.6957243 },
    { lat: 48.563481, lng: 21.6958887 },
    { lat: 48.5630921, lng: 21.6961983 },
    { lat: 48.562684, lng: 21.6966245 },
    { lat: 48.5624151, lng: 21.697034 },
    { lat: 48.5622115, lng: 21.6959962 },
    { lat: 48.5616607, lng: 21.6963072 },
    { lat: 48.5618631, lng: 21.6976303 },
    { lat: 48.5611575, lng: 21.6980759 },
    { lat: 48.559852, lng: 21.6990991 },
    { lat: 48.5588685, lng: 21.6970645 },
    { lat: 48.5585935, lng: 21.6966783 },
    { lat: 48.5564302, lng: 21.6907412 },
    { lat: 48.5561713, lng: 21.6898651 },
    { lat: 48.5543871, lng: 21.6848018 },
    { lat: 48.5542191, lng: 21.6841676 },
    { lat: 48.5521941, lng: 21.6840135 },
    { lat: 48.5522043, lng: 21.6840147 },
    { lat: 48.553066, lng: 21.6860698 },
    { lat: 48.5538142, lng: 21.6882947 },
    { lat: 48.5547174, lng: 21.6931591 },
    { lat: 48.5550052, lng: 21.6950997 },
    { lat: 48.5554407, lng: 21.6975458 },
    { lat: 48.5556939, lng: 21.6993692 },
    { lat: 48.5560836, lng: 21.7028688 },
    { lat: 48.5578102, lng: 21.7023462 },
    { lat: 48.557923, lng: 21.7072134 },
    { lat: 48.5573568, lng: 21.7074968 },
    { lat: 48.5573665, lng: 21.7076901 },
    { lat: 48.5569353, lng: 21.7078477 },
    { lat: 48.5570015, lng: 21.7085866 },
    { lat: 48.5565499, lng: 21.708782 },
    { lat: 48.5567219, lng: 21.7101005 },
    { lat: 48.5565519, lng: 21.7105559 },
    { lat: 48.55652, lng: 21.7108729 },
    { lat: 48.5564409, lng: 21.7119426 },
    { lat: 48.5564444, lng: 21.713195 },
    { lat: 48.5565055, lng: 21.7140415 },
    { lat: 48.5568126, lng: 21.713951 },
    { lat: 48.5568756, lng: 21.7149142 },
    { lat: 48.5566484, lng: 21.71537 },
    { lat: 48.5564198, lng: 21.7154543 },
    { lat: 48.556402, lng: 21.715579 },
    { lat: 48.5560953, lng: 21.7157619 },
    { lat: 48.5556695, lng: 21.7179562 },
    { lat: 48.5558483, lng: 21.7190335 },
    { lat: 48.5557487, lng: 21.7190971 },
    { lat: 48.5558485, lng: 21.7192061 },
    { lat: 48.5558885, lng: 21.719394 },
    { lat: 48.5558344, lng: 21.7194786 },
    { lat: 48.555838, lng: 21.7196113 },
    { lat: 48.5559477, lng: 21.7197974 },
    { lat: 48.5558962, lng: 21.7199792 },
    { lat: 48.5560154, lng: 21.7199754 },
    { lat: 48.5563062, lng: 21.7204939 },
    { lat: 48.5562976, lng: 21.7206735 },
    { lat: 48.5568325, lng: 21.7216171 },
    { lat: 48.5570317, lng: 21.7223223 },
    { lat: 48.5570247, lng: 21.7229805 },
    { lat: 48.5568929, lng: 21.7234379 },
    { lat: 48.556972, lng: 21.7241149 },
    { lat: 48.5571043, lng: 21.7245611 },
    { lat: 48.5540746, lng: 21.7249445 },
    { lat: 48.554446, lng: 21.7270406 },
    { lat: 48.5544071, lng: 21.7270121 },
    { lat: 48.5545593, lng: 21.7277799 },
    { lat: 48.5546368, lng: 21.7279808 },
    { lat: 48.5544711, lng: 21.7283038 },
    { lat: 48.5542136, lng: 21.7284782 },
    { lat: 48.5541815, lng: 21.7288733 },
    { lat: 48.5541317, lng: 21.7301169 },
    { lat: 48.5542991, lng: 21.7322802 },
    { lat: 48.5541531, lng: 21.7331738 },
    { lat: 48.5540795, lng: 21.7344553 },
    { lat: 48.5540962, lng: 21.7349537 },
    { lat: 48.5544466, lng: 21.7376542 },
    { lat: 48.5549316, lng: 21.7403014 },
    { lat: 48.5549076, lng: 21.740452 },
    { lat: 48.5546772, lng: 21.7406692 },
    { lat: 48.5544474, lng: 21.7410866 },
    { lat: 48.5540893, lng: 21.741281 },
    { lat: 48.5541226, lng: 21.7413098 },
    { lat: 48.5542438, lng: 21.7414144 },
    { lat: 48.554318, lng: 21.7417916 },
    { lat: 48.5549751, lng: 21.7430583 },
    { lat: 48.5551176, lng: 21.7435042 },
    { lat: 48.5553536, lng: 21.7447563 },
    { lat: 48.5554614, lng: 21.7450815 },
    { lat: 48.5564251, lng: 21.7445602 },
    { lat: 48.5571159, lng: 21.7438885 },
    { lat: 48.5575168, lng: 21.7433112 },
    { lat: 48.5583557, lng: 21.7430425 },
    { lat: 48.5585364, lng: 21.7430265 },
    { lat: 48.5586753, lng: 21.7431349 },
    { lat: 48.5589167, lng: 21.7437852 },
    { lat: 48.5592002, lng: 21.7440365 },
    { lat: 48.5593997, lng: 21.7441341 },
    { lat: 48.560002, lng: 21.7436464 },
    { lat: 48.5602634, lng: 21.743319 },
    { lat: 48.5610277, lng: 21.742699 },
    { lat: 48.5613693, lng: 21.7426903 },
    { lat: 48.5616549, lng: 21.7424747 },
    { lat: 48.5619216, lng: 21.7420525 },
    { lat: 48.5619141, lng: 21.7414367 },
    { lat: 48.561962, lng: 21.7409987 },
    { lat: 48.562166, lng: 21.740754 },
    { lat: 48.5623955, lng: 21.7402928 },
    { lat: 48.5625855, lng: 21.7401694 },
    { lat: 48.5632435, lng: 21.7400356 },
    { lat: 48.5637456, lng: 21.7392051 },
    { lat: 48.5640264, lng: 21.739101 },
    { lat: 48.564073, lng: 21.7390807 },
    { lat: 48.5644783, lng: 21.7388344 },
    { lat: 48.5647183, lng: 21.7383417 },
    { lat: 48.5654567, lng: 21.7371025 },
    { lat: 48.5663275, lng: 21.7377663 },
    { lat: 48.5667426, lng: 21.7376394 },
    { lat: 48.567015, lng: 21.7373507 },
    { lat: 48.5673855, lng: 21.7380777 },
    { lat: 48.5674911, lng: 21.7382443 },
    { lat: 48.5676436, lng: 21.7383455 },
    { lat: 48.5680859, lng: 21.7383914 },
    { lat: 48.568732, lng: 21.7381478 },
    { lat: 48.5689274, lng: 21.7380151 },
    { lat: 48.5692222, lng: 21.7376767 },
    { lat: 48.5692967, lng: 21.7376846 },
    { lat: 48.5706755, lng: 21.7358588 },
    { lat: 48.5708918, lng: 21.7358272 },
    { lat: 48.570931, lng: 21.7356983 },
    { lat: 48.5728051, lng: 21.7327529 },
    { lat: 48.5737973, lng: 21.7313175 },
    { lat: 48.5731792, lng: 21.726657 },
    { lat: 48.5723774, lng: 21.7226203 },
    { lat: 48.5726973, lng: 21.7225496 },
    { lat: 48.5724195, lng: 21.7223467 },
    { lat: 48.5723176, lng: 21.7221948 },
    { lat: 48.5720147, lng: 21.7204416 },
    { lat: 48.5713507, lng: 21.71772 },
    { lat: 48.5712124, lng: 21.7163064 },
    { lat: 48.5707018, lng: 21.7131503 },
    { lat: 48.570017, lng: 21.7102078 },
    { lat: 48.5698988, lng: 21.7095422 },
    { lat: 48.5697142, lng: 21.7073729 },
    { lat: 48.5689182, lng: 21.7063639 },
    { lat: 48.5695715, lng: 21.7035154 },
    { lat: 48.5699093, lng: 21.7023223 },
    { lat: 48.5699681, lng: 21.7019271 },
    { lat: 48.5699759, lng: 21.7014655 },
    { lat: 48.5699765, lng: 21.701446 },
    { lat: 48.5700065, lng: 21.7013425 },
    { lat: 48.569865, lng: 21.6999515 },
    { lat: 48.5697842, lng: 21.6979233 },
    { lat: 48.5696929, lng: 21.6979187 },
    { lat: 48.5689515, lng: 21.693864 },
  ],
  Brezina: [
    { lat: 48.5612818, lng: 21.5900782 },
    { lat: 48.5617315, lng: 21.5895419 },
    { lat: 48.5620915, lng: 21.5887442 },
    { lat: 48.5622611, lng: 21.5881693 },
    { lat: 48.562441, lng: 21.5877667 },
    { lat: 48.5625962, lng: 21.5870062 },
    { lat: 48.5625989, lng: 21.5867737 },
    { lat: 48.562738, lng: 21.586118 },
    { lat: 48.562634, lng: 21.5854667 },
    { lat: 48.5627142, lng: 21.5849991 },
    { lat: 48.5627983, lng: 21.5849095 },
    { lat: 48.5627559, lng: 21.5844753 },
    { lat: 48.5605087, lng: 21.5863555 },
    { lat: 48.5603426, lng: 21.5868894 },
    { lat: 48.5599955, lng: 21.5870721 },
    { lat: 48.5595225, lng: 21.5879363 },
    { lat: 48.5589113, lng: 21.5885699 },
    { lat: 48.5585863, lng: 21.5891316 },
    { lat: 48.5580244, lng: 21.5899187 },
    { lat: 48.5575698, lng: 21.590396 },
    { lat: 48.5572214, lng: 21.5906498 },
    { lat: 48.5558289, lng: 21.5887496 },
    { lat: 48.5559884, lng: 21.5879395 },
    { lat: 48.5545532, lng: 21.586165 },
    { lat: 48.5539454, lng: 21.5866361 },
    { lat: 48.5536271, lng: 21.5865598 },
    { lat: 48.5531938, lng: 21.5860782 },
    { lat: 48.5531169, lng: 21.5859421 },
    { lat: 48.552623, lng: 21.5839833 },
    { lat: 48.552321, lng: 21.5826122 },
    { lat: 48.5565629, lng: 21.5840487 },
    { lat: 48.5569993, lng: 21.5806082 },
    { lat: 48.5572724, lng: 21.5787959 },
    { lat: 48.5602469, lng: 21.5665535 },
    { lat: 48.5603217, lng: 21.5657743 },
    { lat: 48.5602932, lng: 21.5642495 },
    { lat: 48.5601016, lng: 21.5607523 },
    { lat: 48.560105, lng: 21.5601549 },
    { lat: 48.5600005, lng: 21.559724 },
    { lat: 48.5603361, lng: 21.5548978 },
    { lat: 48.5605948, lng: 21.5521221 },
    { lat: 48.5606775, lng: 21.5517459 },
    { lat: 48.5607951, lng: 21.5506151 },
    { lat: 48.5612173, lng: 21.547622 },
    { lat: 48.5616971, lng: 21.5432515 },
    { lat: 48.5629776, lng: 21.5332042 },
    { lat: 48.5635922, lng: 21.532895 },
    { lat: 48.5645748, lng: 21.5322799 },
    { lat: 48.565937, lng: 21.5316308 },
    { lat: 48.5664964, lng: 21.5315579 },
    { lat: 48.5665141, lng: 21.5312718 },
    { lat: 48.5664755, lng: 21.5311415 },
    { lat: 48.5665471, lng: 21.5310057 },
    { lat: 48.5664914, lng: 21.5308722 },
    { lat: 48.5664062, lng: 21.5302535 },
    { lat: 48.566222, lng: 21.5295983 },
    { lat: 48.5662561, lng: 21.5294497 },
    { lat: 48.566483, lng: 21.5294751 },
    { lat: 48.5665739, lng: 21.5288791 },
    { lat: 48.5664731, lng: 21.5285783 },
    { lat: 48.5665867, lng: 21.528501 },
    { lat: 48.5665519, lng: 21.5282258 },
    { lat: 48.5664337, lng: 21.5279503 },
    { lat: 48.5664632, lng: 21.5274901 },
    { lat: 48.5663765, lng: 21.5273295 },
    { lat: 48.5662623, lng: 21.5269152 },
    { lat: 48.5662237, lng: 21.5263969 },
    { lat: 48.5662635, lng: 21.5260607 },
    { lat: 48.5660545, lng: 21.5257535 },
    { lat: 48.5660049, lng: 21.5253951 },
    { lat: 48.5662404, lng: 21.5247037 },
    { lat: 48.5661033, lng: 21.5240397 },
    { lat: 48.5662045, lng: 21.5237613 },
    { lat: 48.5661407, lng: 21.5233797 },
    { lat: 48.5661544, lng: 21.5228388 },
    { lat: 48.565957, lng: 21.5222349 },
    { lat: 48.5658479, lng: 21.522043 },
    { lat: 48.5658237, lng: 21.5217494 },
    { lat: 48.5660205, lng: 21.5209976 },
    { lat: 48.5663201, lng: 21.5205354 },
    { lat: 48.5667357, lng: 21.5200788 },
    { lat: 48.5667185, lng: 21.5197503 },
    { lat: 48.5671246, lng: 21.5196716 },
    { lat: 48.5673792, lng: 21.51925 },
    { lat: 48.5676565, lng: 21.5191527 },
    { lat: 48.5680159, lng: 21.5185778 },
    { lat: 48.5682548, lng: 21.5185125 },
    { lat: 48.568506, lng: 21.518144 },
    { lat: 48.568363, lng: 21.517959 },
    { lat: 48.568067, lng: 21.517632 },
    { lat: 48.567791, lng: 21.51719 },
    { lat: 48.567779, lng: 21.517174 },
    { lat: 48.56779, lng: 21.517158 },
    { lat: 48.56739, lng: 21.516606 },
    { lat: 48.565737, lng: 21.515384 },
    { lat: 48.565423, lng: 21.514973 },
    { lat: 48.565137, lng: 21.51449 },
    { lat: 48.565148, lng: 21.514474 },
    { lat: 48.564442, lng: 21.513594 },
    { lat: 48.564078, lng: 21.513117 },
    { lat: 48.563847, lng: 21.512433 },
    { lat: 48.563821, lng: 21.512393 },
    { lat: 48.563219, lng: 21.512244 },
    { lat: 48.563031, lng: 21.512159 },
    { lat: 48.56295, lng: 21.512133 },
    { lat: 48.562937, lng: 21.512125 },
    { lat: 48.562935, lng: 21.512125 },
    { lat: 48.562924, lng: 21.512321 },
    { lat: 48.562843, lng: 21.512584 },
    { lat: 48.562632, lng: 21.512935 },
    { lat: 48.562553, lng: 21.513111 },
    { lat: 48.562428, lng: 21.513178 },
    { lat: 48.562359, lng: 21.513451 },
    { lat: 48.562285, lng: 21.513601 },
    { lat: 48.562378, lng: 21.513732 },
    { lat: 48.562284, lng: 21.514073 },
    { lat: 48.56219, lng: 21.514148 },
    { lat: 48.562171, lng: 21.514281 },
    { lat: 48.562181, lng: 21.514497 },
    { lat: 48.562069, lng: 21.514571 },
    { lat: 48.561965, lng: 21.51446 },
    { lat: 48.561963, lng: 21.5144 },
    { lat: 48.561909, lng: 21.514299 },
    { lat: 48.561465, lng: 21.514458 },
    { lat: 48.561278, lng: 21.514631 },
    { lat: 48.561131, lng: 21.51485 },
    { lat: 48.560813, lng: 21.514879 },
    { lat: 48.560771, lng: 21.51497 },
    { lat: 48.560755, lng: 21.515015 },
    { lat: 48.56058, lng: 21.514459 },
    { lat: 48.560351, lng: 21.514125 },
    { lat: 48.560345, lng: 21.513514 },
    { lat: 48.56033, lng: 21.513519 },
    { lat: 48.560278, lng: 21.513522 },
    { lat: 48.55978, lng: 21.513616 },
    { lat: 48.559082, lng: 21.513833 },
    { lat: 48.558584, lng: 21.514335 },
    { lat: 48.558275, lng: 21.514555 },
    { lat: 48.557789, lng: 21.514878 },
    { lat: 48.55732, lng: 21.514937 },
    { lat: 48.557104, lng: 21.515071 },
    { lat: 48.556656, lng: 21.515352 },
    { lat: 48.556055, lng: 21.516167 },
    { lat: 48.556032, lng: 21.516226 },
    { lat: 48.556004, lng: 21.516303 },
    { lat: 48.555764, lng: 21.516932 },
    { lat: 48.555172, lng: 21.517509 },
    { lat: 48.554346, lng: 21.517697 },
    { lat: 48.554031, lng: 21.517897 },
    { lat: 48.553824, lng: 21.517944 },
    { lat: 48.553246, lng: 21.518335 },
    { lat: 48.55314, lng: 21.51872 },
    { lat: 48.552821, lng: 21.519274 },
    { lat: 48.552778, lng: 21.519595 },
    { lat: 48.552276, lng: 21.519615 },
    { lat: 48.552144, lng: 21.520144 },
    { lat: 48.551888, lng: 21.52085 },
    { lat: 48.551465, lng: 21.521064 },
    { lat: 48.551305, lng: 21.521133 },
    { lat: 48.550949, lng: 21.521513 },
    { lat: 48.550856, lng: 21.521843 },
    { lat: 48.550681, lng: 21.522469 },
    { lat: 48.55059, lng: 21.523278 },
    { lat: 48.550466, lng: 21.523735 },
    { lat: 48.550411, lng: 21.524236 },
    { lat: 48.550266, lng: 21.5252357 },
    { lat: 48.5499861, lng: 21.5260765 },
    { lat: 48.5494673, lng: 21.5268148 },
    { lat: 48.5473541, lng: 21.5294484 },
    { lat: 48.5467326, lng: 21.5298677 },
    { lat: 48.5443684, lng: 21.5299794 },
    { lat: 48.5436954, lng: 21.5283331 },
    { lat: 48.5435864, lng: 21.5275497 },
    { lat: 48.5430373, lng: 21.526959 },
    { lat: 48.542839, lng: 21.5268767 },
    { lat: 48.5426664, lng: 21.5269735 },
    { lat: 48.5424416, lng: 21.5273756 },
    { lat: 48.5419771, lng: 21.5278539 },
    { lat: 48.5411787, lng: 21.5299205 },
    { lat: 48.5409153, lng: 21.5302661 },
    { lat: 48.5399267, lng: 21.5310344 },
    { lat: 48.5395841, lng: 21.5312076 },
    { lat: 48.5394241, lng: 21.5311626 },
    { lat: 48.5387262, lng: 21.5306866 },
    { lat: 48.5380726, lng: 21.5306534 },
    { lat: 48.537778, lng: 21.5311675 },
    { lat: 48.5373171, lng: 21.5316157 },
    { lat: 48.537216, lng: 21.5319379 },
    { lat: 48.5369624, lng: 21.5324298 },
    { lat: 48.536939, lng: 21.5329564 },
    { lat: 48.536736, lng: 21.5334074 },
    { lat: 48.5366045, lng: 21.5343174 },
    { lat: 48.536419, lng: 21.5345617 },
    { lat: 48.5365264, lng: 21.5355219 },
    { lat: 48.5364567, lng: 21.5356794 },
    { lat: 48.5364529, lng: 21.5359445 },
    { lat: 48.5364026, lng: 21.536033 },
    { lat: 48.5364432, lng: 21.5362729 },
    { lat: 48.5364373, lng: 21.5367334 },
    { lat: 48.5362272, lng: 21.537024 },
    { lat: 48.5361195, lng: 21.537271 },
    { lat: 48.5360982, lng: 21.5375504 },
    { lat: 48.5361481, lng: 21.5377193 },
    { lat: 48.5360289, lng: 21.5382362 },
    { lat: 48.5359904, lng: 21.5390173 },
    { lat: 48.5358785, lng: 21.5392376 },
    { lat: 48.5358593, lng: 21.5394632 },
    { lat: 48.5359773, lng: 21.5396984 },
    { lat: 48.5359459, lng: 21.5400506 },
    { lat: 48.5365737, lng: 21.5417089 },
    { lat: 48.5366257, lng: 21.5423066 },
    { lat: 48.5367356, lng: 21.5424902 },
    { lat: 48.536716, lng: 21.5431103 },
    { lat: 48.5367968, lng: 21.5435706 },
    { lat: 48.5369091, lng: 21.5437195 },
    { lat: 48.5368733, lng: 21.543888 },
    { lat: 48.5370521, lng: 21.5442358 },
    { lat: 48.537038, lng: 21.5444131 },
    { lat: 48.5371738, lng: 21.5445616 },
    { lat: 48.5371478, lng: 21.5449553 },
    { lat: 48.5372298, lng: 21.545342 },
    { lat: 48.5374513, lng: 21.5457683 },
    { lat: 48.53755, lng: 21.5465484 },
    { lat: 48.5374321, lng: 21.5473432 },
    { lat: 48.5373536, lng: 21.5475932 },
    { lat: 48.5372898, lng: 21.5477628 },
    { lat: 48.5372132, lng: 21.5477972 },
    { lat: 48.537133, lng: 21.5479437 },
    { lat: 48.5369917, lng: 21.5482802 },
    { lat: 48.5368976, lng: 21.5483704 },
    { lat: 48.5366783, lng: 21.5489353 },
    { lat: 48.536677, lng: 21.5490465 },
    { lat: 48.5364911, lng: 21.5493923 },
    { lat: 48.5362783, lng: 21.5502567 },
    { lat: 48.5360486, lng: 21.5505279 },
    { lat: 48.5359973, lng: 21.5507375 },
    { lat: 48.5358352, lng: 21.5507753 },
    { lat: 48.5357502, lng: 21.5508738 },
    { lat: 48.5355751, lng: 21.5513647 },
    { lat: 48.5355854, lng: 21.551728 },
    { lat: 48.535369, lng: 21.5517934 },
    { lat: 48.5352457, lng: 21.5522086 },
    { lat: 48.5351874, lng: 21.5526459 },
    { lat: 48.5349568, lng: 21.5531269 },
    { lat: 48.5350237, lng: 21.5538537 },
    { lat: 48.5348608, lng: 21.5539064 },
    { lat: 48.5347627, lng: 21.5540565 },
    { lat: 48.5348343, lng: 21.5541584 },
    { lat: 48.5349061, lng: 21.5541137 },
    { lat: 48.5349388, lng: 21.5541961 },
    { lat: 48.5348867, lng: 21.554378 },
    { lat: 48.5349818, lng: 21.5545199 },
    { lat: 48.5349705, lng: 21.5545966 },
    { lat: 48.5348772, lng: 21.5546829 },
    { lat: 48.5349582, lng: 21.5547931 },
    { lat: 48.5350224, lng: 21.5546806 },
    { lat: 48.5350204, lng: 21.5548508 },
    { lat: 48.5350653, lng: 21.5549637 },
    { lat: 48.5351174, lng: 21.5549767 },
    { lat: 48.5351838, lng: 21.555217 },
    { lat: 48.5353284, lng: 21.5553501 },
    { lat: 48.5353141, lng: 21.5554032 },
    { lat: 48.5354078, lng: 21.5555306 },
    { lat: 48.5353581, lng: 21.5556819 },
    { lat: 48.5353994, lng: 21.555676 },
    { lat: 48.5353803, lng: 21.5557504 },
    { lat: 48.5354849, lng: 21.5558282 },
    { lat: 48.5354796, lng: 21.5558846 },
    { lat: 48.535522, lng: 21.5558641 },
    { lat: 48.5355473, lng: 21.5560844 },
    { lat: 48.5357025, lng: 21.556084 },
    { lat: 48.5358063, lng: 21.5560116 },
    { lat: 48.5359658, lng: 21.556083 },
    { lat: 48.5359873, lng: 21.5561658 },
    { lat: 48.5361326, lng: 21.5562512 },
    { lat: 48.5361839, lng: 21.5563906 },
    { lat: 48.536327, lng: 21.5563981 },
    { lat: 48.5362964, lng: 21.5565158 },
    { lat: 48.5363335, lng: 21.5566242 },
    { lat: 48.5362794, lng: 21.5566618 },
    { lat: 48.5362586, lng: 21.5568452 },
    { lat: 48.5363367, lng: 21.5568626 },
    { lat: 48.5364286, lng: 21.5570026 },
    { lat: 48.5365547, lng: 21.5569675 },
    { lat: 48.536603, lng: 21.556882 },
    { lat: 48.5367912, lng: 21.5573744 },
    { lat: 48.5369661, lng: 21.5575453 },
    { lat: 48.5370115, lng: 21.558157 },
    { lat: 48.537071, lng: 21.5584255 },
    { lat: 48.5370691, lng: 21.5586404 },
    { lat: 48.5369567, lng: 21.5590407 },
    { lat: 48.5369675, lng: 21.5591401 },
    { lat: 48.5370883, lng: 21.5594739 },
    { lat: 48.5373416, lng: 21.5596341 },
    { lat: 48.5373686, lng: 21.5597133 },
    { lat: 48.5373326, lng: 21.5597568 },
    { lat: 48.5374316, lng: 21.5599567 },
    { lat: 48.5374736, lng: 21.5602409 },
    { lat: 48.5375935, lng: 21.5603432 },
    { lat: 48.5376391, lng: 21.5603229 },
    { lat: 48.5376992, lng: 21.5605797 },
    { lat: 48.5376925, lng: 21.5607427 },
    { lat: 48.5374957, lng: 21.5607447 },
    { lat: 48.5374288, lng: 21.5606887 },
    { lat: 48.5373758, lng: 21.5607656 },
    { lat: 48.5373917, lng: 21.5608437 },
    { lat: 48.5372622, lng: 21.5609241 },
    { lat: 48.5371967, lng: 21.5612667 },
    { lat: 48.5372465, lng: 21.5614027 },
    { lat: 48.5372091, lng: 21.5619057 },
    { lat: 48.5371221, lng: 21.5620125 },
    { lat: 48.5370078, lng: 21.5619886 },
    { lat: 48.5368726, lng: 21.5624205 },
    { lat: 48.53682, lng: 21.5624694 },
    { lat: 48.5367066, lng: 21.5623204 },
    { lat: 48.5366442, lng: 21.5623386 },
    { lat: 48.5362671, lng: 21.5627769 },
    { lat: 48.5361223, lng: 21.5627091 },
    { lat: 48.5360148, lng: 21.5628424 },
    { lat: 48.5358936, lng: 21.5628172 },
    { lat: 48.5358563, lng: 21.5630067 },
    { lat: 48.5355498, lng: 21.5632324 },
    { lat: 48.535507, lng: 21.5635862 },
    { lat: 48.5353584, lng: 21.5636274 },
    { lat: 48.53528, lng: 21.5638714 },
    { lat: 48.5354074, lng: 21.5641994 },
    { lat: 48.5354859, lng: 21.5642714 },
    { lat: 48.5354724, lng: 21.564387 },
    { lat: 48.535565, lng: 21.5646029 },
    { lat: 48.5355999, lng: 21.5648432 },
    { lat: 48.5355761, lng: 21.5652668 },
    { lat: 48.5357494, lng: 21.5657496 },
    { lat: 48.5359745, lng: 21.5665034 },
    { lat: 48.5361118, lng: 21.5668653 },
    { lat: 48.5362061, lng: 21.5667392 },
    { lat: 48.536338, lng: 21.5663423 },
    { lat: 48.5363352, lng: 21.5661288 },
    { lat: 48.5363921, lng: 21.5659891 },
    { lat: 48.5364418, lng: 21.5659629 },
    { lat: 48.5364662, lng: 21.5660256 },
    { lat: 48.5364481, lng: 21.5663022 },
    { lat: 48.5365913, lng: 21.5663129 },
    { lat: 48.5367458, lng: 21.5657655 },
    { lat: 48.5368426, lng: 21.5658537 },
    { lat: 48.5371119, lng: 21.5664613 },
    { lat: 48.5423833, lng: 21.5768702 },
    { lat: 48.5446572, lng: 21.5844144 },
    { lat: 48.5455617, lng: 21.5878058 },
    { lat: 48.5493362, lng: 21.586903 },
    { lat: 48.5501846, lng: 21.5894254 },
    { lat: 48.5505318, lng: 21.5908068 },
    { lat: 48.5512567, lng: 21.5920188 },
    { lat: 48.5514165, lng: 21.5920981 },
    { lat: 48.5515922, lng: 21.5923441 },
    { lat: 48.5517573, lng: 21.5923733 },
    { lat: 48.5519508, lng: 21.5928149 },
    { lat: 48.5525144, lng: 21.5935713 },
    { lat: 48.5528422, lng: 21.5942199 },
    { lat: 48.5529708, lng: 21.5943172 },
    { lat: 48.5530505, lng: 21.594511 },
    { lat: 48.5524115, lng: 21.5953142 },
    { lat: 48.5519208, lng: 21.5957937 },
    { lat: 48.5505768, lng: 21.5967344 },
    { lat: 48.5499133, lng: 21.5973491 },
    { lat: 48.549389, lng: 21.5976705 },
    { lat: 48.5477622, lng: 21.5985219 },
    { lat: 48.5471742, lng: 21.5987006 },
    { lat: 48.5466045, lng: 21.5990121 },
    { lat: 48.5457176, lng: 21.599083 },
    { lat: 48.5444959, lng: 21.5992896 },
    { lat: 48.544317, lng: 21.5994203 },
    { lat: 48.5438039, lng: 21.599536 },
    { lat: 48.5434434, lng: 21.5997223 },
    { lat: 48.543954, lng: 21.6036481 },
    { lat: 48.5440708, lng: 21.6037593 },
    { lat: 48.5441622, lng: 21.603691 },
    { lat: 48.5443129, lng: 21.6037254 },
    { lat: 48.5445932, lng: 21.6036153 },
    { lat: 48.5447836, lng: 21.6036618 },
    { lat: 48.5451187, lng: 21.6039725 },
    { lat: 48.545121, lng: 21.6034497 },
    { lat: 48.5452111, lng: 21.6033332 },
    { lat: 48.545148, lng: 21.6030506 },
    { lat: 48.545318, lng: 21.6022085 },
    { lat: 48.5454564, lng: 21.6022505 },
    { lat: 48.5455379, lng: 21.6021455 },
    { lat: 48.5457263, lng: 21.6020871 },
    { lat: 48.5462599, lng: 21.6017328 },
    { lat: 48.5463076, lng: 21.6021471 },
    { lat: 48.5470796, lng: 21.6020337 },
    { lat: 48.5475768, lng: 21.6028704 },
    { lat: 48.5472095, lng: 21.6017705 },
    { lat: 48.5474113, lng: 21.6011799 },
    { lat: 48.5478417, lng: 21.6009967 },
    { lat: 48.5482199, lng: 21.6018913 },
    { lat: 48.5501025, lng: 21.6010952 },
    { lat: 48.5500191, lng: 21.601527 },
    { lat: 48.5505801, lng: 21.6011078 },
    { lat: 48.5502871, lng: 21.6004882 },
    { lat: 48.5591907, lng: 21.592464 },
    { lat: 48.5596006, lng: 21.5919967 },
    { lat: 48.5597796, lng: 21.5915192 },
    { lat: 48.5599122, lng: 21.5913143 },
    { lat: 48.5602669, lng: 21.5913993 },
    { lat: 48.5612818, lng: 21.5900782 },
  ],
  Byšta: [
    { lat: 48.5150241, lng: 21.5757408 },
    { lat: 48.5154148, lng: 21.5758251 },
    { lat: 48.5156324, lng: 21.575523 },
    { lat: 48.5156801, lng: 21.5753823 },
    { lat: 48.515751, lng: 21.574905 },
    { lat: 48.5157043, lng: 21.5747625 },
    { lat: 48.5155737, lng: 21.5747971 },
    { lat: 48.5155369, lng: 21.5746124 },
    { lat: 48.5155955, lng: 21.5742992 },
    { lat: 48.5155323, lng: 21.5740073 },
    { lat: 48.5159103, lng: 21.5736924 },
    { lat: 48.5160487, lng: 21.5737328 },
    { lat: 48.5162522, lng: 21.573444 },
    { lat: 48.5165842, lng: 21.5735662 },
    { lat: 48.5166073, lng: 21.5735104 },
    { lat: 48.5165366, lng: 21.5732782 },
    { lat: 48.5166426, lng: 21.5730897 },
    { lat: 48.5167464, lng: 21.5731011 },
    { lat: 48.516832, lng: 21.5733324 },
    { lat: 48.5170031, lng: 21.5734978 },
    { lat: 48.5172587, lng: 21.5729462 },
    { lat: 48.5172441, lng: 21.5728719 },
    { lat: 48.5169867, lng: 21.5727585 },
    { lat: 48.5169508, lng: 21.5726494 },
    { lat: 48.5170556, lng: 21.5726111 },
    { lat: 48.5173065, lng: 21.5727277 },
    { lat: 48.5174209, lng: 21.5726373 },
    { lat: 48.5174394, lng: 21.5724906 },
    { lat: 48.5173303, lng: 21.5719365 },
    { lat: 48.5177401, lng: 21.571944 },
    { lat: 48.5178518, lng: 21.5718406 },
    { lat: 48.5178533, lng: 21.5714537 },
    { lat: 48.5179316, lng: 21.5712797 },
    { lat: 48.5180483, lng: 21.571345 },
    { lat: 48.518141, lng: 21.5716038 },
    { lat: 48.5183077, lng: 21.5716875 },
    { lat: 48.5186172, lng: 21.5711346 },
    { lat: 48.5187449, lng: 21.571147 },
    { lat: 48.5189386, lng: 21.5706774 },
    { lat: 48.5190543, lng: 21.5705479 },
    { lat: 48.519212, lng: 21.5704807 },
    { lat: 48.5192603, lng: 21.570353 },
    { lat: 48.5193399, lng: 21.5703089 },
    { lat: 48.5193848, lng: 21.5696618 },
    { lat: 48.5198223, lng: 21.5694985 },
    { lat: 48.5201391, lng: 21.5695641 },
    { lat: 48.5204253, lng: 21.5694545 },
    { lat: 48.5204944, lng: 21.5692687 },
    { lat: 48.5207186, lng: 21.5690438 },
    { lat: 48.5207927, lng: 21.5688389 },
    { lat: 48.5209195, lng: 21.568945 },
    { lat: 48.5210386, lng: 21.5686623 },
    { lat: 48.5212304, lng: 21.5686394 },
    { lat: 48.5212425, lng: 21.5684359 },
    { lat: 48.5213347, lng: 21.5683104 },
    { lat: 48.5213793, lng: 21.5684531 },
    { lat: 48.5215887, lng: 21.5683772 },
    { lat: 48.521855, lng: 21.568124 },
    { lat: 48.522677, lng: 21.5678686 },
    { lat: 48.5230232, lng: 21.5678595 },
    { lat: 48.5248408, lng: 21.5685053 },
    { lat: 48.5248123, lng: 21.5692913 },
    { lat: 48.5260908, lng: 21.5701351 },
    { lat: 48.5267047, lng: 21.5695224 },
    { lat: 48.5279408, lng: 21.5695819 },
    { lat: 48.5311415, lng: 21.5701534 },
    { lat: 48.5314055, lng: 21.5704588 },
    { lat: 48.5314709, lng: 21.5706197 },
    { lat: 48.531583, lng: 21.5705656 },
    { lat: 48.5317038, lng: 21.5706257 },
    { lat: 48.5317281, lng: 21.5703664 },
    { lat: 48.5316665, lng: 21.569781 },
    { lat: 48.5317508, lng: 21.5696613 },
    { lat: 48.5319254, lng: 21.5696664 },
    { lat: 48.5320511, lng: 21.5700073 },
    { lat: 48.5324232, lng: 21.570233 },
    { lat: 48.5323872, lng: 21.5700039 },
    { lat: 48.5325169, lng: 21.5696554 },
    { lat: 48.5326605, lng: 21.5696789 },
    { lat: 48.5327725, lng: 21.5695783 },
    { lat: 48.5328249, lng: 21.5695981 },
    { lat: 48.5329483, lng: 21.5699427 },
    { lat: 48.5331467, lng: 21.5697972 },
    { lat: 48.5332388, lng: 21.569433 },
    { lat: 48.5330811, lng: 21.5687938 },
    { lat: 48.5332458, lng: 21.5687307 },
    { lat: 48.5334862, lng: 21.568519 },
    { lat: 48.5334639, lng: 21.5684082 },
    { lat: 48.5335187, lng: 21.5681952 },
    { lat: 48.5336967, lng: 21.5681181 },
    { lat: 48.5338463, lng: 21.568356 },
    { lat: 48.5339211, lng: 21.5683725 },
    { lat: 48.5341608, lng: 21.5681036 },
    { lat: 48.5342765, lng: 21.5681349 },
    { lat: 48.5343233, lng: 21.5679561 },
    { lat: 48.5342467, lng: 21.5677579 },
    { lat: 48.534405, lng: 21.5676999 },
    { lat: 48.5344195, lng: 21.5676205 },
    { lat: 48.5344278, lng: 21.5674751 },
    { lat: 48.5343666, lng: 21.567462 },
    { lat: 48.5343593, lng: 21.567401 },
    { lat: 48.5344039, lng: 21.567347 },
    { lat: 48.5345171, lng: 21.5673714 },
    { lat: 48.5345082, lng: 21.5674891 },
    { lat: 48.5345414, lng: 21.5675143 },
    { lat: 48.5346355, lng: 21.5673819 },
    { lat: 48.5347873, lng: 21.5673359 },
    { lat: 48.5348645, lng: 21.5670416 },
    { lat: 48.534784, lng: 21.5669062 },
    { lat: 48.5349607, lng: 21.5666679 },
    { lat: 48.5350457, lng: 21.5666866 },
    { lat: 48.5351078, lng: 21.5664146 },
    { lat: 48.5352811, lng: 21.5663743 },
    { lat: 48.5353328, lng: 21.5664921 },
    { lat: 48.5354382, lng: 21.5662858 },
    { lat: 48.5355733, lng: 21.5661997 },
    { lat: 48.5356841, lng: 21.5660257 },
    { lat: 48.5356681, lng: 21.5657367 },
    { lat: 48.5357494, lng: 21.5657496 },
    { lat: 48.5355761, lng: 21.5652668 },
    { lat: 48.5355999, lng: 21.5648432 },
    { lat: 48.535565, lng: 21.5646029 },
    { lat: 48.5354724, lng: 21.564387 },
    { lat: 48.5354859, lng: 21.5642714 },
    { lat: 48.5354074, lng: 21.5641994 },
    { lat: 48.53528, lng: 21.5638714 },
    { lat: 48.5353584, lng: 21.5636274 },
    { lat: 48.535507, lng: 21.5635862 },
    { lat: 48.5355498, lng: 21.5632324 },
    { lat: 48.5358563, lng: 21.5630067 },
    { lat: 48.5358936, lng: 21.5628172 },
    { lat: 48.5360148, lng: 21.5628424 },
    { lat: 48.5361223, lng: 21.5627091 },
    { lat: 48.5362671, lng: 21.5627769 },
    { lat: 48.5366442, lng: 21.5623386 },
    { lat: 48.5367066, lng: 21.5623204 },
    { lat: 48.53682, lng: 21.5624694 },
    { lat: 48.5368726, lng: 21.5624205 },
    { lat: 48.5370078, lng: 21.5619886 },
    { lat: 48.5371221, lng: 21.5620125 },
    { lat: 48.5372091, lng: 21.5619057 },
    { lat: 48.5372465, lng: 21.5614027 },
    { lat: 48.5371967, lng: 21.5612667 },
    { lat: 48.5372622, lng: 21.5609241 },
    { lat: 48.5373917, lng: 21.5608437 },
    { lat: 48.5373758, lng: 21.5607656 },
    { lat: 48.5374288, lng: 21.5606887 },
    { lat: 48.5374957, lng: 21.5607447 },
    { lat: 48.5376925, lng: 21.5607427 },
    { lat: 48.5376992, lng: 21.5605797 },
    { lat: 48.5376391, lng: 21.5603229 },
    { lat: 48.5375935, lng: 21.5603432 },
    { lat: 48.5374736, lng: 21.5602409 },
    { lat: 48.5374316, lng: 21.5599567 },
    { lat: 48.5373326, lng: 21.5597568 },
    { lat: 48.5373686, lng: 21.5597133 },
    { lat: 48.5373416, lng: 21.5596341 },
    { lat: 48.5370883, lng: 21.5594739 },
    { lat: 48.5369675, lng: 21.5591401 },
    { lat: 48.5369567, lng: 21.5590407 },
    { lat: 48.5370691, lng: 21.5586404 },
    { lat: 48.537071, lng: 21.5584255 },
    { lat: 48.5370115, lng: 21.558157 },
    { lat: 48.5369661, lng: 21.5575453 },
    { lat: 48.5367912, lng: 21.5573744 },
    { lat: 48.536603, lng: 21.556882 },
    { lat: 48.5365547, lng: 21.5569675 },
    { lat: 48.5364286, lng: 21.5570026 },
    { lat: 48.5363367, lng: 21.5568626 },
    { lat: 48.5362586, lng: 21.5568452 },
    { lat: 48.5362794, lng: 21.5566618 },
    { lat: 48.5363335, lng: 21.5566242 },
    { lat: 48.5362964, lng: 21.5565158 },
    { lat: 48.536327, lng: 21.5563981 },
    { lat: 48.5361839, lng: 21.5563906 },
    { lat: 48.5361326, lng: 21.5562512 },
    { lat: 48.5359873, lng: 21.5561658 },
    { lat: 48.5359658, lng: 21.556083 },
    { lat: 48.5358063, lng: 21.5560116 },
    { lat: 48.5357025, lng: 21.556084 },
    { lat: 48.5355473, lng: 21.5560844 },
    { lat: 48.535522, lng: 21.5558641 },
    { lat: 48.5354796, lng: 21.5558846 },
    { lat: 48.5354849, lng: 21.5558282 },
    { lat: 48.5353803, lng: 21.5557504 },
    { lat: 48.5353994, lng: 21.555676 },
    { lat: 48.5353581, lng: 21.5556819 },
    { lat: 48.5354078, lng: 21.5555306 },
    { lat: 48.5353141, lng: 21.5554032 },
    { lat: 48.5353284, lng: 21.5553501 },
    { lat: 48.5351838, lng: 21.555217 },
    { lat: 48.5351174, lng: 21.5549767 },
    { lat: 48.5350653, lng: 21.5549637 },
    { lat: 48.5350204, lng: 21.5548508 },
    { lat: 48.5350224, lng: 21.5546806 },
    { lat: 48.5349582, lng: 21.5547931 },
    { lat: 48.5348772, lng: 21.5546829 },
    { lat: 48.5349705, lng: 21.5545966 },
    { lat: 48.5349818, lng: 21.5545199 },
    { lat: 48.5348867, lng: 21.554378 },
    { lat: 48.5349388, lng: 21.5541961 },
    { lat: 48.5349061, lng: 21.5541137 },
    { lat: 48.5348343, lng: 21.5541584 },
    { lat: 48.5347627, lng: 21.5540565 },
    { lat: 48.5348608, lng: 21.5539064 },
    { lat: 48.5350237, lng: 21.5538537 },
    { lat: 48.5349568, lng: 21.5531269 },
    { lat: 48.5351874, lng: 21.5526459 },
    { lat: 48.5352457, lng: 21.5522086 },
    { lat: 48.535369, lng: 21.5517934 },
    { lat: 48.5355854, lng: 21.551728 },
    { lat: 48.5355751, lng: 21.5513647 },
    { lat: 48.5357502, lng: 21.5508738 },
    { lat: 48.5358352, lng: 21.5507753 },
    { lat: 48.5359973, lng: 21.5507375 },
    { lat: 48.5360486, lng: 21.5505279 },
    { lat: 48.5362783, lng: 21.5502567 },
    { lat: 48.5364911, lng: 21.5493923 },
    { lat: 48.536677, lng: 21.5490465 },
    { lat: 48.5366783, lng: 21.5489353 },
    { lat: 48.5368976, lng: 21.5483704 },
    { lat: 48.5369917, lng: 21.5482802 },
    { lat: 48.537133, lng: 21.5479437 },
    { lat: 48.5372132, lng: 21.5477972 },
    { lat: 48.5372898, lng: 21.5477628 },
    { lat: 48.5373536, lng: 21.5475932 },
    { lat: 48.5374321, lng: 21.5473432 },
    { lat: 48.53755, lng: 21.5465484 },
    { lat: 48.5374513, lng: 21.5457683 },
    { lat: 48.5372298, lng: 21.545342 },
    { lat: 48.5371478, lng: 21.5449553 },
    { lat: 48.5371738, lng: 21.5445616 },
    { lat: 48.537038, lng: 21.5444131 },
    { lat: 48.5370521, lng: 21.5442358 },
    { lat: 48.5368733, lng: 21.543888 },
    { lat: 48.5369091, lng: 21.5437195 },
    { lat: 48.5367968, lng: 21.5435706 },
    { lat: 48.536716, lng: 21.5431103 },
    { lat: 48.5367356, lng: 21.5424902 },
    { lat: 48.5366257, lng: 21.5423066 },
    { lat: 48.5365737, lng: 21.5417089 },
    { lat: 48.5359459, lng: 21.5400506 },
    { lat: 48.5359773, lng: 21.5396984 },
    { lat: 48.5358593, lng: 21.5394632 },
    { lat: 48.5358785, lng: 21.5392376 },
    { lat: 48.5359904, lng: 21.5390173 },
    { lat: 48.5360289, lng: 21.5382362 },
    { lat: 48.5361481, lng: 21.5377193 },
    { lat: 48.5360982, lng: 21.5375504 },
    { lat: 48.5361195, lng: 21.537271 },
    { lat: 48.5362272, lng: 21.537024 },
    { lat: 48.5364373, lng: 21.5367334 },
    { lat: 48.5364432, lng: 21.5362729 },
    { lat: 48.5364026, lng: 21.536033 },
    { lat: 48.5364529, lng: 21.5359445 },
    { lat: 48.5364567, lng: 21.5356794 },
    { lat: 48.5365264, lng: 21.5355219 },
    { lat: 48.536419, lng: 21.5345617 },
    { lat: 48.5366045, lng: 21.5343174 },
    { lat: 48.536736, lng: 21.5334074 },
    { lat: 48.536939, lng: 21.5329564 },
    { lat: 48.5369624, lng: 21.5324298 },
    { lat: 48.537216, lng: 21.5319379 },
    { lat: 48.5373171, lng: 21.5316157 },
    { lat: 48.537778, lng: 21.5311675 },
    { lat: 48.5380726, lng: 21.5306534 },
    { lat: 48.5387262, lng: 21.5306866 },
    { lat: 48.5394241, lng: 21.5311626 },
    { lat: 48.5395841, lng: 21.5312076 },
    { lat: 48.5399267, lng: 21.5310344 },
    { lat: 48.5409153, lng: 21.5302661 },
    { lat: 48.5411787, lng: 21.5299205 },
    { lat: 48.5419771, lng: 21.5278539 },
    { lat: 48.5424416, lng: 21.5273756 },
    { lat: 48.5426664, lng: 21.5269735 },
    { lat: 48.542839, lng: 21.5268767 },
    { lat: 48.5430373, lng: 21.526959 },
    { lat: 48.5435864, lng: 21.5275497 },
    { lat: 48.5436954, lng: 21.5283331 },
    { lat: 48.5443684, lng: 21.5299794 },
    { lat: 48.5467326, lng: 21.5298677 },
    { lat: 48.5473541, lng: 21.5294484 },
    { lat: 48.5494673, lng: 21.5268148 },
    { lat: 48.5499861, lng: 21.5260765 },
    { lat: 48.550266, lng: 21.5252357 },
    { lat: 48.550411, lng: 21.524236 },
    { lat: 48.550042, lng: 21.524011 },
    { lat: 48.549428, lng: 21.523586 },
    { lat: 48.549046, lng: 21.52335 },
    { lat: 48.549011, lng: 21.523328 },
    { lat: 48.548877, lng: 21.523245 },
    { lat: 48.548406, lng: 21.522497 },
    { lat: 48.547186, lng: 21.521734 },
    { lat: 48.547167, lng: 21.521804 },
    { lat: 48.547037, lng: 21.521805 },
    { lat: 48.546639, lng: 21.521462 },
    { lat: 48.546287, lng: 21.520923 },
    { lat: 48.546065, lng: 21.520973 },
    { lat: 48.545942, lng: 21.520939 },
    { lat: 48.545888, lng: 21.520924 },
    { lat: 48.545725, lng: 21.52082 },
    { lat: 48.545327, lng: 21.520191 },
    { lat: 48.545045, lng: 21.519666 },
    { lat: 48.544802, lng: 21.519595 },
    { lat: 48.544628, lng: 21.519646 },
    { lat: 48.544464, lng: 21.519548 },
    { lat: 48.544331, lng: 21.517778 },
    { lat: 48.544098, lng: 21.517136 },
    { lat: 48.54366, lng: 21.516943 },
    { lat: 48.543057, lng: 21.516658 },
    { lat: 48.542567, lng: 21.516541 },
    { lat: 48.542304, lng: 21.516482 },
    { lat: 48.542147, lng: 21.516435 },
    { lat: 48.541638, lng: 21.51624 },
    { lat: 48.54114, lng: 21.516022 },
    { lat: 48.540885, lng: 21.515835 },
    { lat: 48.540563, lng: 21.515593 },
    { lat: 48.5405466, lng: 21.5155654 },
    { lat: 48.540196, lng: 21.51533 },
    { lat: 48.53965, lng: 21.515461 },
    { lat: 48.538995, lng: 21.515998 },
    { lat: 48.538466, lng: 21.516499 },
    { lat: 48.538082, lng: 21.516688 },
    { lat: 48.537581, lng: 21.517016 },
    { lat: 48.53719, lng: 21.517897 },
    { lat: 48.536872, lng: 21.518672 },
    { lat: 48.536622, lng: 21.518933 },
    { lat: 48.536041, lng: 21.51955 },
    { lat: 48.535543, lng: 21.520271 },
    { lat: 48.535329, lng: 21.520569 },
    { lat: 48.534525, lng: 21.521431 },
    { lat: 48.533673, lng: 21.520751 },
    { lat: 48.533413, lng: 21.520794 },
    { lat: 48.533011, lng: 21.520875 },
    { lat: 48.532452, lng: 21.521065 },
    { lat: 48.532088, lng: 21.521189 },
    { lat: 48.531239, lng: 21.52157 },
    { lat: 48.53064, lng: 21.521924 },
    { lat: 48.530099, lng: 21.522245 },
    { lat: 48.52944, lng: 21.522671 },
    { lat: 48.528892, lng: 21.523026 },
    { lat: 48.528188, lng: 21.523513 },
    { lat: 48.527829, lng: 21.523758 },
    { lat: 48.527427, lng: 21.52401 },
    { lat: 48.526444, lng: 21.524642 },
    { lat: 48.525138, lng: 21.525456 },
    { lat: 48.524594, lng: 21.525786 },
    { lat: 48.524075, lng: 21.525383 },
    { lat: 48.523622, lng: 21.52515 },
    { lat: 48.522981, lng: 21.525141 },
    { lat: 48.522883, lng: 21.52518 },
    { lat: 48.522721, lng: 21.525196 },
    { lat: 48.522631, lng: 21.52523 },
    { lat: 48.522542, lng: 21.52532 },
    { lat: 48.522345, lng: 21.525583 },
    { lat: 48.522128, lng: 21.525906 },
    { lat: 48.521869, lng: 21.526325 },
    { lat: 48.521703, lng: 21.52656 },
    { lat: 48.52165, lng: 21.526629 },
    { lat: 48.521585, lng: 21.526746 },
    { lat: 48.521382, lng: 21.527055 },
    { lat: 48.521178, lng: 21.527352 },
    { lat: 48.521127, lng: 21.527448 },
    { lat: 48.521086, lng: 21.527544 },
    { lat: 48.520974, lng: 21.527901 },
    { lat: 48.520909, lng: 21.52811 },
    { lat: 48.520852, lng: 21.528291 },
    { lat: 48.520746, lng: 21.528651 },
    { lat: 48.520691, lng: 21.528775 },
    { lat: 48.52062, lng: 21.528884 },
    { lat: 48.520397, lng: 21.529081 },
    { lat: 48.520083, lng: 21.529351 },
    { lat: 48.519902, lng: 21.52953 },
    { lat: 48.519458, lng: 21.529898 },
    { lat: 48.519213, lng: 21.530105 },
    { lat: 48.51901, lng: 21.530275 },
    { lat: 48.518639, lng: 21.530567 },
    { lat: 48.518509, lng: 21.530684 },
    { lat: 48.518145, lng: 21.531055 },
    { lat: 48.517948, lng: 21.531265 },
    { lat: 48.517694, lng: 21.531581 },
    { lat: 48.517506, lng: 21.531788 },
    { lat: 48.517407, lng: 21.531922 },
    { lat: 48.517323, lng: 21.532092 },
    { lat: 48.517222, lng: 21.532368 },
    { lat: 48.517024, lng: 21.532833 },
    { lat: 48.516894, lng: 21.533113 },
    { lat: 48.516854, lng: 21.533216 },
    { lat: 48.516772, lng: 21.533488 },
    { lat: 48.516701, lng: 21.533675 },
    { lat: 48.51662, lng: 21.533819 },
    { lat: 48.516498, lng: 21.534175 },
    { lat: 48.516266, lng: 21.534751 },
    { lat: 48.516182, lng: 21.534879 },
    { lat: 48.516066, lng: 21.535027 },
    { lat: 48.515975, lng: 21.53517 },
    { lat: 48.51593, lng: 21.53531 },
    { lat: 48.515895, lng: 21.535495 },
    { lat: 48.515853, lng: 21.535586 },
    { lat: 48.515767, lng: 21.535741 },
    { lat: 48.515649, lng: 21.536095 },
    { lat: 48.515583, lng: 21.53637 },
    { lat: 48.515507, lng: 21.536754 },
    { lat: 48.515522, lng: 21.536888 },
    { lat: 48.515566, lng: 21.5371 },
    { lat: 48.515577, lng: 21.53733 },
    { lat: 48.515563, lng: 21.537437 },
    { lat: 48.515494, lng: 21.537643 },
    { lat: 48.515441, lng: 21.537789 },
    { lat: 48.515332, lng: 21.537995 },
    { lat: 48.515251, lng: 21.538125 },
    { lat: 48.515118, lng: 21.538296 },
    { lat: 48.514989, lng: 21.538488 },
    { lat: 48.514863, lng: 21.538696 },
    { lat: 48.514718, lng: 21.538927 },
    { lat: 48.514594, lng: 21.539153 },
    { lat: 48.514481, lng: 21.539359 },
    { lat: 48.514383, lng: 21.539494 },
    { lat: 48.514296, lng: 21.539585 },
    { lat: 48.514169, lng: 21.539692 },
    { lat: 48.51413, lng: 21.539722 },
    { lat: 48.514065, lng: 21.539792 },
    { lat: 48.514013, lng: 21.539872 },
    { lat: 48.513988, lng: 21.539923 },
    { lat: 48.513966, lng: 21.539995 },
    { lat: 48.513949, lng: 21.54012 },
    { lat: 48.513938, lng: 21.540321 },
    { lat: 48.513921, lng: 21.540442 },
    { lat: 48.513895, lng: 21.540523 },
    { lat: 48.513716, lng: 21.540779 },
    { lat: 48.513618, lng: 21.540894 },
    { lat: 48.513535, lng: 21.541027 },
    { lat: 48.513469, lng: 21.541122 },
    { lat: 48.513381, lng: 21.541227 },
    { lat: 48.513151, lng: 21.541418 },
    { lat: 48.51306, lng: 21.541477 },
    { lat: 48.512915, lng: 21.541588 },
    { lat: 48.512837, lng: 21.541606 },
    { lat: 48.512744, lng: 21.541584 },
    { lat: 48.512671, lng: 21.541548 },
    { lat: 48.512637, lng: 21.541507 },
    { lat: 48.512579, lng: 21.541412 },
    { lat: 48.512509, lng: 21.541269 },
    { lat: 48.512457, lng: 21.541199 },
    { lat: 48.512397, lng: 21.541155 },
    { lat: 48.512274, lng: 21.541095 },
    { lat: 48.512192, lng: 21.541009 },
    { lat: 48.512122, lng: 21.540918 },
    { lat: 48.512069, lng: 21.54087 },
    { lat: 48.512025, lng: 21.540854 },
    { lat: 48.51195, lng: 21.540843 },
    { lat: 48.511782, lng: 21.540864 },
    { lat: 48.511641, lng: 21.540893 },
    { lat: 48.51154, lng: 21.540933 },
    { lat: 48.51142, lng: 21.541023 },
    { lat: 48.511298, lng: 21.541138 },
    { lat: 48.511159, lng: 21.541311 },
    { lat: 48.511091, lng: 21.541352 },
    { lat: 48.510903, lng: 21.541352 },
    { lat: 48.510748, lng: 21.541336 },
    { lat: 48.510492, lng: 21.541212 },
    { lat: 48.510383, lng: 21.541182 },
    { lat: 48.510265, lng: 21.54118 },
    { lat: 48.510104, lng: 21.541213 },
    { lat: 48.509987, lng: 21.541285 },
    { lat: 48.509764, lng: 21.541465 },
    { lat: 48.509521, lng: 21.541648 },
    { lat: 48.509285, lng: 21.541826 },
    { lat: 48.50916, lng: 21.541921 },
    { lat: 48.509071, lng: 21.541965 },
    { lat: 48.50893, lng: 21.541996 },
    { lat: 48.508731, lng: 21.542033 },
    { lat: 48.508583, lng: 21.542057 },
    { lat: 48.508477, lng: 21.542047 },
    { lat: 48.508395, lng: 21.54202 },
    { lat: 48.508338, lng: 21.54273 },
    { lat: 48.508475, lng: 21.543478 },
    { lat: 48.50864, lng: 21.544311 },
    { lat: 48.508788, lng: 21.545303 },
    { lat: 48.508918, lng: 21.546156 },
    { lat: 48.509098, lng: 21.547243 },
    { lat: 48.509329, lng: 21.548722 },
    { lat: 48.50947, lng: 21.549729 },
    { lat: 48.508967, lng: 21.550074 },
    { lat: 48.508684, lng: 21.550877 },
    { lat: 48.508425, lng: 21.552157 },
    { lat: 48.508581, lng: 21.553437 },
    { lat: 48.5087523, lng: 21.5540885 },
    { lat: 48.508906, lng: 21.554673 },
    { lat: 48.509119, lng: 21.555449 },
    { lat: 48.509246, lng: 21.55591 },
    { lat: 48.509324, lng: 21.556066 },
    { lat: 48.509769, lng: 21.556937 },
    { lat: 48.510211, lng: 21.557964 },
    { lat: 48.510641, lng: 21.558917 },
    { lat: 48.511028, lng: 21.559863 },
    { lat: 48.511354, lng: 21.56071 },
    { lat: 48.511529, lng: 21.561174 },
    { lat: 48.510605, lng: 21.561868 },
    { lat: 48.509979, lng: 21.562527 },
    { lat: 48.509176, lng: 21.562967 },
    { lat: 48.50833, lng: 21.562972 },
    { lat: 48.507873, lng: 21.563004 },
    { lat: 48.507281, lng: 21.563051 },
    { lat: 48.507054, lng: 21.5633993 },
    { lat: 48.50686, lng: 21.563697 },
    { lat: 48.506442, lng: 21.564482 },
    { lat: 48.505924, lng: 21.565082 },
    { lat: 48.505268, lng: 21.565639 },
    { lat: 48.505181, lng: 21.566571 },
    { lat: 48.505093, lng: 21.567547 },
    { lat: 48.504996, lng: 21.568625 },
    { lat: 48.504878, lng: 21.569907 },
    { lat: 48.504763, lng: 21.571172 },
    { lat: 48.504659, lng: 21.572303 },
    { lat: 48.50479, lng: 21.5728857 },
    { lat: 48.504837, lng: 21.573095 },
    { lat: 48.505034, lng: 21.573969 },
    { lat: 48.504987, lng: 21.575167 },
    { lat: 48.504945, lng: 21.576242 },
    { lat: 48.505101, lng: 21.577339 },
    { lat: 48.504369, lng: 21.578029 },
    { lat: 48.504467, lng: 21.578923 },
    { lat: 48.504688, lng: 21.57972 },
    { lat: 48.505132, lng: 21.579929 },
    { lat: 48.505476, lng: 21.580248 },
    { lat: 48.506186, lng: 21.580954 },
    { lat: 48.506819, lng: 21.581949 },
    { lat: 48.507678, lng: 21.582644 },
    { lat: 48.507996, lng: 21.582712 },
    { lat: 48.50836, lng: 21.582805 },
    { lat: 48.508658, lng: 21.583169 },
    { lat: 48.50941, lng: 21.584046 },
    { lat: 48.509879, lng: 21.584557 },
    { lat: 48.510009, lng: 21.584826 },
    { lat: 48.510238, lng: 21.585313 },
    { lat: 48.51035, lng: 21.585349 },
    { lat: 48.510788, lng: 21.585731 },
    { lat: 48.511332, lng: 21.585981 },
    { lat: 48.511375, lng: 21.585988 },
    { lat: 48.5114553, lng: 21.5857662 },
    { lat: 48.5114643, lng: 21.5855996 },
    { lat: 48.5114188, lng: 21.5854673 },
    { lat: 48.511327, lng: 21.5854057 },
    { lat: 48.5111003, lng: 21.5855036 },
    { lat: 48.5111623, lng: 21.5852242 },
    { lat: 48.511318, lng: 21.5850844 },
    { lat: 48.5113232, lng: 21.5847101 },
    { lat: 48.511387, lng: 21.5845811 },
    { lat: 48.5116673, lng: 21.5846618 },
    { lat: 48.5117524, lng: 21.5845269 },
    { lat: 48.5118102, lng: 21.5841853 },
    { lat: 48.5119485, lng: 21.5840988 },
    { lat: 48.5118927, lng: 21.583718 },
    { lat: 48.5120067, lng: 21.5835702 },
    { lat: 48.5119226, lng: 21.5830614 },
    { lat: 48.5119636, lng: 21.5829186 },
    { lat: 48.5121787, lng: 21.5830816 },
    { lat: 48.5122382, lng: 21.5830412 },
    { lat: 48.5122859, lng: 21.5829342 },
    { lat: 48.512206, lng: 21.5825759 },
    { lat: 48.5122604, lng: 21.5824734 },
    { lat: 48.5125172, lng: 21.5823999 },
    { lat: 48.5126288, lng: 21.5824864 },
    { lat: 48.5127553, lng: 21.5823382 },
    { lat: 48.5128429, lng: 21.5821764 },
    { lat: 48.5127239, lng: 21.5817844 },
    { lat: 48.5129441, lng: 21.5816923 },
    { lat: 48.5132149, lng: 21.5812023 },
    { lat: 48.5132599, lng: 21.5810851 },
    { lat: 48.513293, lng: 21.5806621 },
    { lat: 48.5134821, lng: 21.5803438 },
    { lat: 48.5135492, lng: 21.5800172 },
    { lat: 48.5137924, lng: 21.5801369 },
    { lat: 48.5140383, lng: 21.5796847 },
    { lat: 48.5141374, lng: 21.5793618 },
    { lat: 48.5140592, lng: 21.5793043 },
    { lat: 48.5137073, lng: 21.5793942 },
    { lat: 48.5136331, lng: 21.5792794 },
    { lat: 48.5136767, lng: 21.5791105 },
    { lat: 48.5138099, lng: 21.5790864 },
    { lat: 48.5139376, lng: 21.5791822 },
    { lat: 48.5141285, lng: 21.5790454 },
    { lat: 48.5141561, lng: 21.5789865 },
    { lat: 48.5141053, lng: 21.5786675 },
    { lat: 48.5141215, lng: 21.5784379 },
    { lat: 48.5141589, lng: 21.5784096 },
    { lat: 48.5142141, lng: 21.5784526 },
    { lat: 48.5143448, lng: 21.5787821 },
    { lat: 48.514434, lng: 21.578792 },
    { lat: 48.5144903, lng: 21.5787157 },
    { lat: 48.5145643, lng: 21.5784199 },
    { lat: 48.5145483, lng: 21.5776547 },
    { lat: 48.5147728, lng: 21.5772379 },
    { lat: 48.5149443, lng: 21.5767801 },
    { lat: 48.5151452, lng: 21.5766814 },
    { lat: 48.5151663, lng: 21.5765945 },
    { lat: 48.5151196, lng: 21.576408 },
    { lat: 48.5149599, lng: 21.5762883 },
    { lat: 48.5149404, lng: 21.5761572 },
    { lat: 48.5149959, lng: 21.5758182 },
    { lat: 48.5150241, lng: 21.5757408 },
  ],
  Poľany: [
    { lat: 48.509372, lng: 21.977732 },
    { lat: 48.509083, lng: 21.977313 },
    { lat: 48.50876, lng: 21.976721 },
    { lat: 48.508543, lng: 21.97652 },
    { lat: 48.508299, lng: 21.976298 },
    { lat: 48.507269, lng: 21.975141 },
    { lat: 48.507159, lng: 21.974647 },
    { lat: 48.507029, lng: 21.97372 },
    { lat: 48.506995, lng: 21.973304 },
    { lat: 48.506938, lng: 21.972567 },
    { lat: 48.506855, lng: 21.972254 },
    { lat: 48.5068, lng: 21.971735 },
    { lat: 48.506715, lng: 21.971054 },
    { lat: 48.506523, lng: 21.969497 },
    { lat: 48.506465, lng: 21.969077 },
    { lat: 48.50628, lng: 21.96759 },
    { lat: 48.506215, lng: 21.967225 },
    { lat: 48.506164, lng: 21.96669 },
    { lat: 48.506136, lng: 21.966394 },
    { lat: 48.505937, lng: 21.964521 },
    { lat: 48.505844, lng: 21.963846 },
    { lat: 48.505795, lng: 21.963324 },
    { lat: 48.505663, lng: 21.962098 },
    { lat: 48.505637, lng: 21.961814 },
    { lat: 48.505568, lng: 21.9618497 },
    { lat: 48.5052367, lng: 21.9618272 },
    { lat: 48.5047034, lng: 21.9623756 },
    { lat: 48.5041555, lng: 21.962232 },
    { lat: 48.5036601, lng: 21.9614975 },
    { lat: 48.5036243, lng: 21.961546 },
    { lat: 48.5033225, lng: 21.9608997 },
    { lat: 48.5026816, lng: 21.9601965 },
    { lat: 48.5024514, lng: 21.9602567 },
    { lat: 48.5023162, lng: 21.960385 },
    { lat: 48.5022356, lng: 21.9607634 },
    { lat: 48.502375, lng: 21.9615722 },
    { lat: 48.5027693, lng: 21.9623151 },
    { lat: 48.5029464, lng: 21.9633303 },
    { lat: 48.5030352, lng: 21.9641895 },
    { lat: 48.5028639, lng: 21.9661648 },
    { lat: 48.5026612, lng: 21.9661542 },
    { lat: 48.5019365, lng: 21.9669116 },
    { lat: 48.50154, lng: 21.9674827 },
    { lat: 48.5008913, lng: 21.9694032 },
    { lat: 48.5000863, lng: 21.9710064 },
    { lat: 48.4998522, lng: 21.9715974 },
    { lat: 48.4998774, lng: 21.9726499 },
    { lat: 48.500057, lng: 21.973047 },
    { lat: 48.4999549, lng: 21.9731517 },
    { lat: 48.4998144, lng: 21.973149 },
    { lat: 48.4995032, lng: 21.9735293 },
    { lat: 48.4989326, lng: 21.9740211 },
    { lat: 48.4985734, lng: 21.9741924 },
    { lat: 48.4983907, lng: 21.9740806 },
    { lat: 48.4980104, lng: 21.9743063 },
    { lat: 48.4976514, lng: 21.9748397 },
    { lat: 48.4975737, lng: 21.9746993 },
    { lat: 48.4973539, lng: 21.9745077 },
    { lat: 48.4966132, lng: 21.9744411 },
    { lat: 48.4961375, lng: 21.9743163 },
    { lat: 48.4958033, lng: 21.9740826 },
    { lat: 48.4956718, lng: 21.9739069 },
    { lat: 48.4953979, lng: 21.9734311 },
    { lat: 48.4951222, lng: 21.9726556 },
    { lat: 48.4949369, lng: 21.9711742 },
    { lat: 48.4948602, lng: 21.9708969 },
    { lat: 48.4944939, lng: 21.9705085 },
    { lat: 48.4943849, lng: 21.9704685 },
    { lat: 48.4938051, lng: 21.9705859 },
    { lat: 48.4934349, lng: 21.9708603 },
    { lat: 48.4932996, lng: 21.970887 },
    { lat: 48.4929382, lng: 21.9711918 },
    { lat: 48.4923756, lng: 21.9713667 },
    { lat: 48.4921881, lng: 21.9715262 },
    { lat: 48.4918759, lng: 21.972091 },
    { lat: 48.4916634, lng: 21.9722789 },
    { lat: 48.4912478, lng: 21.9723455 },
    { lat: 48.4910122, lng: 21.972513 },
    { lat: 48.4908923, lng: 21.9724765 },
    { lat: 48.4908229, lng: 21.9722665 },
    { lat: 48.4908601, lng: 21.9717306 },
    { lat: 48.4908182, lng: 21.9714879 },
    { lat: 48.4908272, lng: 21.9708526 },
    { lat: 48.4907865, lng: 21.9705807 },
    { lat: 48.4906903, lng: 21.970418 },
    { lat: 48.4905763, lng: 21.9703077 },
    { lat: 48.4903385, lng: 21.970292 },
    { lat: 48.4892732, lng: 21.9706151 },
    { lat: 48.4889032, lng: 21.9705745 },
    { lat: 48.4887101, lng: 21.9704109 },
    { lat: 48.4886426, lng: 21.9702281 },
    { lat: 48.4886388, lng: 21.9698481 },
    { lat: 48.4888808, lng: 21.9686284 },
    { lat: 48.4889085, lng: 21.9679782 },
    { lat: 48.4888528, lng: 21.9676813 },
    { lat: 48.4887618, lng: 21.9675591 },
    { lat: 48.4878242, lng: 21.9673083 },
    { lat: 48.487638, lng: 21.9678716 },
    { lat: 48.4873027, lng: 21.9681895 },
    { lat: 48.4866942, lng: 21.968018 },
    { lat: 48.4864917, lng: 21.9677911 },
    { lat: 48.4863386, lng: 21.9677401 },
    { lat: 48.4859243, lng: 21.9677716 },
    { lat: 48.4857327, lng: 21.9675756 },
    { lat: 48.4850111, lng: 21.9674222 },
    { lat: 48.4840778, lng: 21.9680691 },
    { lat: 48.483579, lng: 21.9680971 },
    { lat: 48.4833905, lng: 21.9682063 },
    { lat: 48.4833158, lng: 21.9683772 },
    { lat: 48.4832261, lng: 21.9682996 },
    { lat: 48.4827733, lng: 21.968185 },
    { lat: 48.4823693, lng: 21.9686336 },
    { lat: 48.4821798, lng: 21.9689684 },
    { lat: 48.482012, lng: 21.9693317 },
    { lat: 48.4818844, lng: 21.9699821 },
    { lat: 48.4815081, lng: 21.9705504 },
    { lat: 48.4813631, lng: 21.9706908 },
    { lat: 48.4811663, lng: 21.9710629 },
    { lat: 48.480743, lng: 21.9710491 },
    { lat: 48.4804728, lng: 21.9711781 },
    { lat: 48.4801564, lng: 21.9714831 },
    { lat: 48.4797633, lng: 21.9716473 },
    { lat: 48.4793063, lng: 21.9721733 },
    { lat: 48.4789064, lng: 21.9727411 },
    { lat: 48.478892, lng: 21.9728916 },
    { lat: 48.4787947, lng: 21.9730108 },
    { lat: 48.4788282, lng: 21.9733139 },
    { lat: 48.4787437, lng: 21.9733463 },
    { lat: 48.4787495, lng: 21.9734963 },
    { lat: 48.4778457, lng: 21.9730687 },
    { lat: 48.4773525, lng: 21.9731851 },
    { lat: 48.4759357, lng: 21.9732104 },
    { lat: 48.4753104, lng: 21.9734536 },
    { lat: 48.4751634, lng: 21.9736029 },
    { lat: 48.4747786, lng: 21.9731062 },
    { lat: 48.4743736, lng: 21.9726982 },
    { lat: 48.4737187, lng: 21.9722329 },
    { lat: 48.4735837, lng: 21.9720075 },
    { lat: 48.4728585, lng: 21.9712527 },
    { lat: 48.4723326, lng: 21.9709614 },
    { lat: 48.4717485, lng: 21.9710875 },
    { lat: 48.4712449, lng: 21.9716479 },
    { lat: 48.4707163, lng: 21.9718332 },
    { lat: 48.4699968, lng: 21.9725841 },
    { lat: 48.4697199, lng: 21.9726159 },
    { lat: 48.4695894, lng: 21.972441 },
    { lat: 48.4692947, lng: 21.9715855 },
    { lat: 48.4688541, lng: 21.971099 },
    { lat: 48.4686524, lng: 21.9710387 },
    { lat: 48.4681027, lng: 21.9710583 },
    { lat: 48.4673916, lng: 21.971189 },
    { lat: 48.4671869, lng: 21.9713292 },
    { lat: 48.4664842, lng: 21.9699829 },
    { lat: 48.4661749, lng: 21.9688546 },
    { lat: 48.4656426, lng: 21.9678973 },
    { lat: 48.4648163, lng: 21.9661611 },
    { lat: 48.4644735, lng: 21.9655434 },
    { lat: 48.464388, lng: 21.9655372 },
    { lat: 48.464046, lng: 21.9649552 },
    { lat: 48.4632677, lng: 21.9656428 },
    { lat: 48.462796, lng: 21.9644974 },
    { lat: 48.46275, lng: 21.9645422 },
    { lat: 48.4619587, lng: 21.9628152 },
    { lat: 48.4618224, lng: 21.9623982 },
    { lat: 48.4610874, lng: 21.9629291 },
    { lat: 48.459011, lng: 21.9642069 },
    { lat: 48.4589455, lng: 21.9640202 },
    { lat: 48.4586569, lng: 21.964264 },
    { lat: 48.4584948, lng: 21.9643054 },
    { lat: 48.4582541, lng: 21.9638497 },
    { lat: 48.4581259, lng: 21.9638165 },
    { lat: 48.4577993, lng: 21.9639334 },
    { lat: 48.4573882, lng: 21.9637769 },
    { lat: 48.457054, lng: 21.9635026 },
    { lat: 48.4566884, lng: 21.9630971 },
    { lat: 48.455902, lng: 21.961929 },
    { lat: 48.4545109, lng: 21.9595559 },
    { lat: 48.4538266, lng: 21.958689 },
    { lat: 48.4533055, lng: 21.9581389 },
    { lat: 48.4517999, lng: 21.9572284 },
    { lat: 48.451312, lng: 21.9570996 },
    { lat: 48.4508301, lng: 21.9571343 },
    { lat: 48.4505299, lng: 21.957367 },
    { lat: 48.4503224, lng: 21.9575719 },
    { lat: 48.4501449, lng: 21.9579901 },
    { lat: 48.4499192, lng: 21.9591776 },
    { lat: 48.449955, lng: 21.959844 },
    { lat: 48.4500889, lng: 21.9603818 },
    { lat: 48.4506352, lng: 21.9614439 },
    { lat: 48.451833, lng: 21.964102 },
    { lat: 48.4524576, lng: 21.9660951 },
    { lat: 48.452639, lng: 21.9670091 },
    { lat: 48.452821, lng: 21.9686773 },
    { lat: 48.4529257, lng: 21.9692179 },
    { lat: 48.4529546, lng: 21.9702019 },
    { lat: 48.4529096, lng: 21.9705623 },
    { lat: 48.452806, lng: 21.9708775 },
    { lat: 48.4523033, lng: 21.9715579 },
    { lat: 48.4516619, lng: 21.9720064 },
    { lat: 48.4507883, lng: 21.9722581 },
    { lat: 48.4495838, lng: 21.9723204 },
    { lat: 48.4473222, lng: 21.9728464 },
    { lat: 48.4468262, lng: 21.9731701 },
    { lat: 48.4461437, lng: 21.9735 },
    { lat: 48.4456117, lng: 21.9738651 },
    { lat: 48.4451275, lng: 21.9743518 },
    { lat: 48.444767, lng: 21.9748228 },
    { lat: 48.44438, lng: 21.9755103 },
    { lat: 48.4438485, lng: 21.9768086 },
    { lat: 48.4437704, lng: 21.9772396 },
    { lat: 48.4437326, lng: 21.9779791 },
    { lat: 48.4437972, lng: 21.9785388 },
    { lat: 48.4440212, lng: 21.9790946 },
    { lat: 48.444479, lng: 21.9795399 },
    { lat: 48.4448797, lng: 21.9796897 },
    { lat: 48.4455349, lng: 21.9797104 },
    { lat: 48.446702, lng: 21.9795859 },
    { lat: 48.4501478, lng: 21.9782228 },
    { lat: 48.451477, lng: 21.9779322 },
    { lat: 48.4528352, lng: 21.9779918 },
    { lat: 48.4531284, lng: 21.9782454 },
    { lat: 48.4534303, lng: 21.978803 },
    { lat: 48.4534742, lng: 21.9794427 },
    { lat: 48.4532559, lng: 21.981851 },
    { lat: 48.4530109, lng: 21.9824017 },
    { lat: 48.4525225, lng: 21.9830782 },
    { lat: 48.452016, lng: 21.9839302 },
    { lat: 48.4503852, lng: 21.9852698 },
    { lat: 48.4495133, lng: 21.9861304 },
    { lat: 48.4494382, lng: 21.9864588 },
    { lat: 48.4494418, lng: 21.9869285 },
    { lat: 48.4496528, lng: 21.9880943 },
    { lat: 48.4505524, lng: 21.9916754 },
    { lat: 48.4507892, lng: 21.9929671 },
    { lat: 48.4509824, lng: 21.9950085 },
    { lat: 48.4513854, lng: 21.9973977 },
    { lat: 48.4516278, lng: 21.9981475 },
    { lat: 48.4523057, lng: 21.9996358 },
    { lat: 48.4526206, lng: 22.0000957 },
    { lat: 48.4529155, lng: 22.0006506 },
    { lat: 48.4533109, lng: 22.0009401 },
    { lat: 48.4551474, lng: 22.0014721 },
    { lat: 48.4554803, lng: 22.0015117 },
    { lat: 48.4556547, lng: 22.0013262 },
    { lat: 48.4556549, lng: 22.001748 },
    { lat: 48.4587588, lng: 21.9992784 },
    { lat: 48.4605924, lng: 21.99927 },
    { lat: 48.4612005, lng: 21.9991132 },
    { lat: 48.4612585, lng: 21.9993701 },
    { lat: 48.4591899, lng: 22.0004372 },
    { lat: 48.4587628, lng: 22.0005336 },
    { lat: 48.4585706, lng: 22.000735 },
    { lat: 48.4583229, lng: 22.0012244 },
    { lat: 48.4574698, lng: 22.0034983 },
    { lat: 48.4571434, lng: 22.0045035 },
    { lat: 48.4567132, lng: 22.0063183 },
    { lat: 48.4566022, lng: 22.0070194 },
    { lat: 48.4566203, lng: 22.0074303 },
    { lat: 48.4567663, lng: 22.0077046 },
    { lat: 48.4571515, lng: 22.0081249 },
    { lat: 48.4575094, lng: 22.0083677 },
    { lat: 48.4576181, lng: 22.0085786 },
    { lat: 48.4580931, lng: 22.008542 },
    { lat: 48.4587891, lng: 22.007746 },
    { lat: 48.4596034, lng: 22.0066354 },
    { lat: 48.461158, lng: 22.0047581 },
    { lat: 48.4616955, lng: 22.0042402 },
    { lat: 48.4625536, lng: 22.0037707 },
    { lat: 48.4629968, lng: 22.0036741 },
    { lat: 48.4636021, lng: 22.0039138 },
    { lat: 48.4638596, lng: 22.0041352 },
    { lat: 48.4643018, lng: 22.0051411 },
    { lat: 48.4645133, lng: 22.0060638 },
    { lat: 48.4646084, lng: 22.0080085 },
    { lat: 48.4651585, lng: 22.00966 },
    { lat: 48.4660701, lng: 22.011563 },
    { lat: 48.4668064, lng: 22.0108923 },
    { lat: 48.4719147, lng: 22.0089158 },
    { lat: 48.4716034, lng: 22.0074121 },
    { lat: 48.4728903, lng: 22.0064884 },
    { lat: 48.4747985, lng: 22.0055795 },
    { lat: 48.4749937, lng: 22.0058399 },
    { lat: 48.4750233, lng: 22.0060754 },
    { lat: 48.4750155, lng: 22.0063553 },
    { lat: 48.4746823, lng: 22.0069453 },
    { lat: 48.4748317, lng: 22.0070767 },
    { lat: 48.476301, lng: 22.006808 },
    { lat: 48.4764626, lng: 22.0066951 },
    { lat: 48.4766578, lng: 22.006351 },
    { lat: 48.4768549, lng: 22.006228 },
    { lat: 48.4771035, lng: 22.0059416 },
    { lat: 48.4774462, lng: 22.0051935 },
    { lat: 48.4780803, lng: 22.0047662 },
    { lat: 48.4789869, lng: 22.0043524 },
    { lat: 48.4795051, lng: 22.0052001 },
    { lat: 48.4794646, lng: 22.0054307 },
    { lat: 48.4795638, lng: 22.0059021 },
    { lat: 48.4790173, lng: 22.0065516 },
    { lat: 48.4789576, lng: 22.0069134 },
    { lat: 48.4793875, lng: 22.0073802 },
    { lat: 48.4798251, lng: 22.0072407 },
    { lat: 48.4802582, lng: 22.0072429 },
    { lat: 48.480587, lng: 22.0078059 },
    { lat: 48.4807785, lng: 22.0079537 },
    { lat: 48.4809309, lng: 22.0075669 },
    { lat: 48.4810234, lng: 22.0074845 },
    { lat: 48.4814104, lng: 22.0074322 },
    { lat: 48.4818772, lng: 22.0070687 },
    { lat: 48.4828862, lng: 22.0077053 },
    { lat: 48.4832841, lng: 22.0076832 },
    { lat: 48.4839858, lng: 22.0073545 },
    { lat: 48.4844285, lng: 22.0067867 },
    { lat: 48.4851916, lng: 22.0070263 },
    { lat: 48.4856551, lng: 22.0069328 },
    { lat: 48.4857909, lng: 22.0070196 },
    { lat: 48.4860712, lng: 22.0076144 },
    { lat: 48.4864775, lng: 22.0079947 },
    { lat: 48.486841, lng: 22.0081394 },
    { lat: 48.486954, lng: 22.0082481 },
    { lat: 48.4869424, lng: 22.0087381 },
    { lat: 48.487252, lng: 22.009161 },
    { lat: 48.4879147, lng: 22.0088838 },
    { lat: 48.4882708, lng: 22.0084952 },
    { lat: 48.4887331, lng: 22.0082164 },
    { lat: 48.4888181, lng: 22.008339 },
    { lat: 48.4891038, lng: 22.0095796 },
    { lat: 48.489686, lng: 22.0098768 },
    { lat: 48.489887, lng: 22.0103707 },
    { lat: 48.489945, lng: 22.0103906 },
    { lat: 48.4900353, lng: 22.0099873 },
    { lat: 48.4913028, lng: 22.0102089 },
    { lat: 48.4920764, lng: 22.0109246 },
    { lat: 48.492463, lng: 22.0111369 },
    { lat: 48.4928893, lng: 22.0111395 },
    { lat: 48.4933003, lng: 22.0109699 },
    { lat: 48.4947811, lng: 22.008736 },
    { lat: 48.4951029, lng: 22.0079077 },
    { lat: 48.495338, lng: 22.0075836 },
    { lat: 48.4959544, lng: 22.0073114 },
    { lat: 48.4961736, lng: 22.0070468 },
    { lat: 48.4962026, lng: 22.0070117 },
    { lat: 48.4965389, lng: 22.0064128 },
    { lat: 48.4968733, lng: 22.0065531 },
    { lat: 48.498594, lng: 22.0072752 },
    { lat: 48.4985159, lng: 22.007532 },
    { lat: 48.4984273, lng: 22.0078232 },
    { lat: 48.4983624, lng: 22.0084338 },
    { lat: 48.4984193, lng: 22.0084565 },
    { lat: 48.4991831, lng: 22.0085915 },
    { lat: 48.4993729, lng: 22.0084391 },
    { lat: 48.4998515, lng: 22.00727 },
    { lat: 48.5000298, lng: 22.0071583 },
    { lat: 48.5010235, lng: 22.0077182 },
    { lat: 48.5017683, lng: 22.0084158 },
    { lat: 48.5021466, lng: 22.0093455 },
    { lat: 48.5036074, lng: 22.0101524 },
    { lat: 48.505137, lng: 22.0117455 },
    { lat: 48.5056041, lng: 22.0123486 },
    { lat: 48.5059644, lng: 22.0124103 },
    { lat: 48.5063376, lng: 22.012171 },
    { lat: 48.5064568, lng: 22.0116067 },
    { lat: 48.5065089, lng: 22.0110295 },
    { lat: 48.5066681, lng: 22.0109181 },
    { lat: 48.5070856, lng: 22.0109645 },
    { lat: 48.5075177, lng: 22.0111967 },
    { lat: 48.5078844, lng: 22.0111079 },
    { lat: 48.5088977, lng: 22.0105409 },
    { lat: 48.5093333, lng: 22.0100012 },
    { lat: 48.5095891, lng: 22.010339 },
    { lat: 48.5100141, lng: 22.0095991 },
    { lat: 48.510501, lng: 22.009118 },
    { lat: 48.510046, lng: 22.008163 },
    { lat: 48.510072, lng: 22.008094 },
    { lat: 48.510132, lng: 22.007924 },
    { lat: 48.510252, lng: 22.007705 },
    { lat: 48.510409, lng: 22.00755 },
    { lat: 48.511033, lng: 22.007056 },
    { lat: 48.511323, lng: 22.006783 },
    { lat: 48.511513, lng: 22.006573 },
    { lat: 48.511723, lng: 22.006193 },
    { lat: 48.511851, lng: 22.005836 },
    { lat: 48.511999, lng: 22.005454 },
    { lat: 48.51212, lng: 22.00487 },
    { lat: 48.512192, lng: 22.003911 },
    { lat: 48.512196, lng: 22.003417 },
    { lat: 48.512181, lng: 22.003109 },
    { lat: 48.512088, lng: 22.002544 },
    { lat: 48.512008, lng: 22.002261 },
    { lat: 48.511875, lng: 22.001987 },
    { lat: 48.511726, lng: 22.001826 },
    { lat: 48.511541, lng: 22.00171 },
    { lat: 48.511216, lng: 22.001722 },
    { lat: 48.510941, lng: 22.0019 },
    { lat: 48.510726, lng: 22.002102 },
    { lat: 48.510071, lng: 22.002962 },
    { lat: 48.509598, lng: 22.003471 },
    { lat: 48.509339, lng: 22.003534 },
    { lat: 48.509126, lng: 22.003556 },
    { lat: 48.508927, lng: 22.003486 },
    { lat: 48.508741, lng: 22.003385 },
    { lat: 48.508689, lng: 22.003312 },
    { lat: 48.508612, lng: 22.003206 },
    { lat: 48.508592, lng: 22.003178 },
    { lat: 48.508527, lng: 22.003089 },
    { lat: 48.508266, lng: 22.002444 },
    { lat: 48.50814, lng: 22.001572 },
    { lat: 48.508138, lng: 22.001378 },
    { lat: 48.508085, lng: 22.000462 },
    { lat: 48.507983, lng: 21.999403 },
    { lat: 48.507992, lng: 21.999135 },
    { lat: 48.50797, lng: 21.998918 },
    { lat: 48.507846, lng: 21.998574 },
    { lat: 48.507834, lng: 21.998562 },
    { lat: 48.507793, lng: 21.99852 },
    { lat: 48.507781, lng: 21.998508 },
    { lat: 48.507683, lng: 21.998408 },
    { lat: 48.507524, lng: 21.998317 },
    { lat: 48.50738, lng: 21.998309 },
    { lat: 48.507219, lng: 21.998377 },
    { lat: 48.506983, lng: 21.999024 },
    { lat: 48.506886, lng: 21.999302 },
    { lat: 48.506771, lng: 21.999748 },
    { lat: 48.506624, lng: 22.000293 },
    { lat: 48.506615, lng: 22.000577 },
    { lat: 48.506495, lng: 22.001145 },
    { lat: 48.506441, lng: 22.001217 },
    { lat: 48.506335, lng: 22.001377 },
    { lat: 48.506211, lng: 22.001485 },
    { lat: 48.505913, lng: 22.001432 },
    { lat: 48.505825, lng: 22.001334 },
    { lat: 48.505722, lng: 22.001102 },
    { lat: 48.505687, lng: 22.000802 },
    { lat: 48.505681, lng: 22.000625 },
    { lat: 48.505783, lng: 21.999729 },
    { lat: 48.505908, lng: 21.999059 },
    { lat: 48.506105, lng: 21.998477 },
    { lat: 48.506307, lng: 21.997806 },
    { lat: 48.506435, lng: 21.997244 },
    { lat: 48.50681, lng: 21.996453 },
    { lat: 48.506943, lng: 21.995933 },
    { lat: 48.507092, lng: 21.995204 },
    { lat: 48.507201, lng: 21.994585 },
    { lat: 48.507328, lng: 21.993762 },
    { lat: 48.507404, lng: 21.993274 },
    { lat: 48.50742, lng: 21.993199 },
    { lat: 48.50744, lng: 21.993106 },
    { lat: 48.507469, lng: 21.992971 },
    { lat: 48.507533, lng: 21.992679 },
    { lat: 48.50755, lng: 21.992351 },
    { lat: 48.507593, lng: 21.991609 },
    { lat: 48.507495, lng: 21.991154 },
    { lat: 48.507392, lng: 21.990828 },
    { lat: 48.507351, lng: 21.990725 },
    { lat: 48.507293, lng: 21.990604 },
    { lat: 48.507258, lng: 21.990532 },
    { lat: 48.507035, lng: 21.990188 },
    { lat: 48.506779, lng: 21.990003 },
    { lat: 48.506427, lng: 21.989897 },
    { lat: 48.506186, lng: 21.989649 },
    { lat: 48.506105, lng: 21.989029 },
    { lat: 48.50613, lng: 21.98883 },
    { lat: 48.506235, lng: 21.988494 },
    { lat: 48.506527, lng: 21.987952 },
    { lat: 48.506872, lng: 21.987505 },
    { lat: 48.507084, lng: 21.98707 },
    { lat: 48.507247, lng: 21.98661 },
    { lat: 48.507284, lng: 21.986506 },
    { lat: 48.507293, lng: 21.986447 },
    { lat: 48.507315, lng: 21.986304 },
    { lat: 48.507341, lng: 21.986157 },
    { lat: 48.50739, lng: 21.985883 },
    { lat: 48.507431, lng: 21.985531 },
    { lat: 48.507451, lng: 21.985183 },
    { lat: 48.507422, lng: 21.98444 },
    { lat: 48.507338, lng: 21.983689 },
    { lat: 48.507233, lng: 21.982791 },
    { lat: 48.507303, lng: 21.982415 },
    { lat: 48.507297, lng: 21.981678 },
    { lat: 48.507358, lng: 21.980761 },
    { lat: 48.507405, lng: 21.979843 },
    { lat: 48.5075, lng: 21.979483 },
    { lat: 48.507598, lng: 21.979316 },
    { lat: 48.507652, lng: 21.979226 },
    { lat: 48.507891, lng: 21.979099 },
    { lat: 48.508253, lng: 21.979187 },
    { lat: 48.508421, lng: 21.979329 },
    { lat: 48.508614, lng: 21.979362 },
    { lat: 48.50879, lng: 21.979298 },
    { lat: 48.509001, lng: 21.978862 },
    { lat: 48.509069, lng: 21.978501 },
    { lat: 48.509151, lng: 21.978517 },
    { lat: 48.509242, lng: 21.978534 },
    { lat: 48.509288, lng: 21.978543 },
    { lat: 48.509302, lng: 21.978546 },
    { lat: 48.50938, lng: 21.978458 },
    { lat: 48.509372, lng: 21.977732 },
  ],
  ZemplínskeHradište: [
    { lat: 48.58165, lng: 21.6995434 },
    { lat: 48.5809683, lng: 21.6997567 },
    { lat: 48.5803095, lng: 21.6997831 },
    { lat: 48.5789781, lng: 21.6993922 },
    { lat: 48.5781518, lng: 21.6989585 },
    { lat: 48.5735441, lng: 21.7003202 },
    { lat: 48.5731603, lng: 21.7006 },
    { lat: 48.5727207, lng: 21.7007781 },
    { lat: 48.5704704, lng: 21.7013587 },
    { lat: 48.5699759, lng: 21.7014655 },
    { lat: 48.5699681, lng: 21.7019271 },
    { lat: 48.5699093, lng: 21.7023223 },
    { lat: 48.5695715, lng: 21.7035154 },
    { lat: 48.5689182, lng: 21.7063639 },
    { lat: 48.5697142, lng: 21.7073729 },
    { lat: 48.5698988, lng: 21.7095422 },
    { lat: 48.570017, lng: 21.7102078 },
    { lat: 48.5707018, lng: 21.7131503 },
    { lat: 48.5712124, lng: 21.7163064 },
    { lat: 48.5713507, lng: 21.71772 },
    { lat: 48.5720147, lng: 21.7204416 },
    { lat: 48.5723176, lng: 21.7221948 },
    { lat: 48.5724195, lng: 21.7223467 },
    { lat: 48.5726973, lng: 21.7225496 },
    { lat: 48.5723774, lng: 21.7226203 },
    { lat: 48.5731792, lng: 21.726657 },
    { lat: 48.5737973, lng: 21.7313175 },
    { lat: 48.5728051, lng: 21.7327529 },
    { lat: 48.570931, lng: 21.7356983 },
    { lat: 48.5708918, lng: 21.7358272 },
    { lat: 48.5706755, lng: 21.7358588 },
    { lat: 48.5692967, lng: 21.7376846 },
    { lat: 48.5692222, lng: 21.7376767 },
    { lat: 48.5689274, lng: 21.7380151 },
    { lat: 48.568732, lng: 21.7381478 },
    { lat: 48.5680859, lng: 21.7383914 },
    { lat: 48.5676436, lng: 21.7383455 },
    { lat: 48.5674911, lng: 21.7382443 },
    { lat: 48.5673855, lng: 21.7380777 },
    { lat: 48.567015, lng: 21.7373507 },
    { lat: 48.5667426, lng: 21.7376394 },
    { lat: 48.5663275, lng: 21.7377663 },
    { lat: 48.5654567, lng: 21.7371025 },
    { lat: 48.5647183, lng: 21.7383417 },
    { lat: 48.5644783, lng: 21.7388344 },
    { lat: 48.564073, lng: 21.7390807 },
    { lat: 48.5640264, lng: 21.739101 },
    { lat: 48.5646573, lng: 21.74144 },
    { lat: 48.564779, lng: 21.7421203 },
    { lat: 48.5646486, lng: 21.7431328 },
    { lat: 48.564391, lng: 21.7442703 },
    { lat: 48.5644006, lng: 21.7450089 },
    { lat: 48.5644779, lng: 21.7454578 },
    { lat: 48.5644917, lng: 21.7459734 },
    { lat: 48.5645007, lng: 21.7463118 },
    { lat: 48.561714, lng: 21.7689093 },
    { lat: 48.5622736, lng: 21.7706213 },
    { lat: 48.5633128, lng: 21.7742536 },
    { lat: 48.5626412, lng: 21.7746396 },
    { lat: 48.562405, lng: 21.775194 },
    { lat: 48.5624607, lng: 21.7763188 },
    { lat: 48.5629502, lng: 21.7781893 },
    { lat: 48.5628578, lng: 21.7784044 },
    { lat: 48.5634388, lng: 21.7795644 },
    { lat: 48.5639264, lng: 21.7791131 },
    { lat: 48.5644799, lng: 21.7799225 },
    { lat: 48.5675042, lng: 21.7820971 },
    { lat: 48.568815, lng: 21.7860127 },
    { lat: 48.5699924, lng: 21.7878267 },
    { lat: 48.5701078, lng: 21.7903708 },
    { lat: 48.5703551, lng: 21.7912125 },
    { lat: 48.5703591, lng: 21.7913636 },
    { lat: 48.5701465, lng: 21.7920101 },
    { lat: 48.5700094, lng: 21.7937242 },
    { lat: 48.5697735, lng: 21.7983159 },
    { lat: 48.5687881, lng: 21.7987044 },
    { lat: 48.56824, lng: 21.7990806 },
    { lat: 48.5682887, lng: 21.8006404 },
    { lat: 48.5682696, lng: 21.8010717 },
    { lat: 48.5681884, lng: 21.8012737 },
    { lat: 48.5682003, lng: 21.8016009 },
    { lat: 48.568212, lng: 21.801673 },
    { lat: 48.5691993, lng: 21.8016018 },
    { lat: 48.569737, lng: 21.801563 },
    { lat: 48.570765, lng: 21.80153 },
    { lat: 48.572876, lng: 21.801326 },
    { lat: 48.574391, lng: 21.801182 },
    { lat: 48.575291, lng: 21.801105 },
    { lat: 48.575815, lng: 21.80106 },
    { lat: 48.576488, lng: 21.801002 },
    { lat: 48.576979, lng: 21.800959 },
    { lat: 48.577615, lng: 21.800902 },
    { lat: 48.578278, lng: 21.800845 },
    { lat: 48.579342, lng: 21.800752 },
    { lat: 48.580463, lng: 21.800654 },
    { lat: 48.581586, lng: 21.800556 },
    { lat: 48.582744, lng: 21.800453 },
    { lat: 48.5839433, lng: 21.8003458 },
    { lat: 48.584881, lng: 21.800262 },
    { lat: 48.586115, lng: 21.800155 },
    { lat: 48.5865656, lng: 21.8001134 },
    { lat: 48.587307, lng: 21.800045 },
    { lat: 48.5881494, lng: 21.7999774 },
    { lat: 48.588529, lng: 21.799947 },
    { lat: 48.589127, lng: 21.799908 },
    { lat: 48.589764, lng: 21.799854 },
    { lat: 48.5899225, lng: 21.799728 },
    { lat: 48.589954, lng: 21.799703 },
    { lat: 48.5902125, lng: 21.7997329 },
    { lat: 48.591295, lng: 21.799858 },
    { lat: 48.591812, lng: 21.799456 },
    { lat: 48.592545, lng: 21.799186 },
    { lat: 48.593641, lng: 21.798478 },
    { lat: 48.594236, lng: 21.798273 },
    { lat: 48.59465, lng: 21.797947 },
    { lat: 48.5949179, lng: 21.7865288 },
    { lat: 48.5950782, lng: 21.7839908 },
    { lat: 48.59525, lng: 21.7747039 },
    { lat: 48.5950809, lng: 21.7719695 },
    { lat: 48.5947335, lng: 21.7685002 },
    { lat: 48.591438, lng: 21.7280625 },
    { lat: 48.5907174, lng: 21.7283762 },
    { lat: 48.5889979, lng: 21.7240947 },
    { lat: 48.5879747, lng: 21.721778 },
    { lat: 48.5875473, lng: 21.7206595 },
    { lat: 48.5872623, lng: 21.7202672 },
    { lat: 48.5872016, lng: 21.719896 },
    { lat: 48.5875431, lng: 21.7198367 },
    { lat: 48.5872702, lng: 21.7187236 },
    { lat: 48.5869628, lng: 21.7178437 },
    { lat: 48.5867083, lng: 21.7174983 },
    { lat: 48.5866333, lng: 21.7173028 },
    { lat: 48.5866685, lng: 21.7154818 },
    { lat: 48.5865403, lng: 21.7141918 },
    { lat: 48.5864649, lng: 21.7128578 },
    { lat: 48.5863693, lng: 21.7124862 },
    { lat: 48.5860591, lng: 21.7117494 },
    { lat: 48.5858745, lng: 21.7109974 },
    { lat: 48.587099, lng: 21.7115971 },
    { lat: 48.586465, lng: 21.707579 },
    { lat: 48.5867654, lng: 21.7075371 },
    { lat: 48.5859469, lng: 21.7035685 },
    { lat: 48.5855562, lng: 21.7036896 },
    { lat: 48.5854008, lng: 21.7033058 },
    { lat: 48.5853224, lng: 21.7029112 },
    { lat: 48.5849171, lng: 21.7031024 },
    { lat: 48.5847333, lng: 21.7024688 },
    { lat: 48.5844659, lng: 21.7020688 },
    { lat: 48.5843425, lng: 21.7016201 },
    { lat: 48.5839185, lng: 21.7014618 },
    { lat: 48.5832269, lng: 21.7017286 },
    { lat: 48.5832105, lng: 21.7019066 },
    { lat: 48.5830636, lng: 21.7020542 },
    { lat: 48.5827928, lng: 21.7020645 },
    { lat: 48.5827548, lng: 21.7017403 },
    { lat: 48.5826406, lng: 21.7016794 },
    { lat: 48.5823466, lng: 21.7006681 },
    { lat: 48.5821822, lng: 21.7005788 },
    { lat: 48.582067, lng: 21.7003577 },
    { lat: 48.5819414, lng: 21.6997507 },
    { lat: 48.5817172, lng: 21.6996231 },
    { lat: 48.58165, lng: 21.6995434 },
  ],
  SlovenskéNovéMesto: [
    { lat: 48.4388685, lng: 21.710672 },
    { lat: 48.438871, lng: 21.710315 },
    { lat: 48.4388715, lng: 21.7094621 },
    { lat: 48.4388151, lng: 21.7085414 },
    { lat: 48.4387722, lng: 21.708498 },
    { lat: 48.438654, lng: 21.707462 },
    { lat: 48.4378758, lng: 21.705321 },
    { lat: 48.4376006, lng: 21.7043376 },
    { lat: 48.437058, lng: 21.703709 },
    { lat: 48.4378236, lng: 21.7035642 },
    { lat: 48.4382845, lng: 21.7033354 },
    { lat: 48.4384359, lng: 21.7030763 },
    { lat: 48.4385193, lng: 21.7025825 },
    { lat: 48.4387357, lng: 21.7023456 },
    { lat: 48.4389145, lng: 21.7020086 },
    { lat: 48.4389053, lng: 21.7018366 },
    { lat: 48.4389843, lng: 21.7013952 },
    { lat: 48.4392328, lng: 21.7003987 },
    { lat: 48.4392843, lng: 21.6998463 },
    { lat: 48.4391882, lng: 21.69909 },
    { lat: 48.4389846, lng: 21.6980952 },
    { lat: 48.4389074, lng: 21.6972023 },
    { lat: 48.438804, lng: 21.6971715 },
    { lat: 48.4384844, lng: 21.6962582 },
    { lat: 48.4368041, lng: 21.6939248 },
    { lat: 48.4362753, lng: 21.694429 },
    { lat: 48.4358625, lng: 21.6946376 },
    { lat: 48.4355877, lng: 21.6945925 },
    { lat: 48.435558, lng: 21.6945323 },
    { lat: 48.4355815, lng: 21.6943626 },
    { lat: 48.4357189, lng: 21.6941178 },
    { lat: 48.4356883, lng: 21.6939811 },
    { lat: 48.4354774, lng: 21.6937606 },
    { lat: 48.4353548, lng: 21.6937203 },
    { lat: 48.4352781, lng: 21.6934678 },
    { lat: 48.4351178, lng: 21.6933622 },
    { lat: 48.4347621, lng: 21.6933154 },
    { lat: 48.4344475, lng: 21.6929666 },
    { lat: 48.4347048, lng: 21.6926223 },
    { lat: 48.4348863, lng: 21.6924673 },
    { lat: 48.4354143, lng: 21.6917335 },
    { lat: 48.4357189, lng: 21.6914313 },
    { lat: 48.4358524, lng: 21.6911729 },
    { lat: 48.4368322, lng: 21.6904283 },
    { lat: 48.437191, lng: 21.690089 },
    { lat: 48.4373503, lng: 21.6897848 },
    { lat: 48.4376112, lng: 21.6889175 },
    { lat: 48.437836, lng: 21.6885306 },
    { lat: 48.4373646, lng: 21.6864733 },
    { lat: 48.4372516, lng: 21.68642 },
    { lat: 48.4369894, lng: 21.6864564 },
    { lat: 48.4362735, lng: 21.6866501 },
    { lat: 48.435948, lng: 21.6868024 },
    { lat: 48.4355794, lng: 21.6850138 },
    { lat: 48.4349765, lng: 21.6827986 },
    { lat: 48.4342785, lng: 21.6806081 },
    { lat: 48.4345278, lng: 21.6805052 },
    { lat: 48.4337473, lng: 21.6778887 },
    { lat: 48.4334609, lng: 21.6766783 },
    { lat: 48.4340517, lng: 21.6764338 },
    { lat: 48.433565, lng: 21.673504 },
    { lat: 48.4385071, lng: 21.6716278 },
    { lat: 48.4382665, lng: 21.6709369 },
    { lat: 48.437906, lng: 21.6691606 },
    { lat: 48.4408318, lng: 21.6676607 },
    { lat: 48.440342, lng: 21.6659675 },
    { lat: 48.4399201, lng: 21.6649459 },
    { lat: 48.4396584, lng: 21.664594 },
    { lat: 48.4394052, lng: 21.6643893 },
    { lat: 48.439258, lng: 21.664026 },
    { lat: 48.4391457, lng: 21.6634682 },
    { lat: 48.4419151, lng: 21.6622162 },
    { lat: 48.4416781, lng: 21.6605115 },
    { lat: 48.4423236, lng: 21.6602502 },
    { lat: 48.442256, lng: 21.6599617 },
    { lat: 48.4415267, lng: 21.6589797 },
    { lat: 48.4412896, lng: 21.6585367 },
    { lat: 48.4406261, lng: 21.6568774 },
    { lat: 48.4399527, lng: 21.6555221 },
    { lat: 48.4399205, lng: 21.6551215 },
    { lat: 48.4396076, lng: 21.6545064 },
    { lat: 48.4420812, lng: 21.6527323 },
    { lat: 48.4417196, lng: 21.6517349 },
    { lat: 48.4411565, lng: 21.6498501 },
    { lat: 48.4405876, lng: 21.648359 },
    { lat: 48.4402961, lng: 21.6485611 },
    { lat: 48.44019, lng: 21.6488332 },
    { lat: 48.4396196, lng: 21.6493274 },
    { lat: 48.4390978, lng: 21.6466094 },
    { lat: 48.439023, lng: 21.646384 },
    { lat: 48.43898, lng: 21.646421 },
    { lat: 48.438911, lng: 21.646485 },
    { lat: 48.438784, lng: 21.646662 },
    { lat: 48.43877, lng: 21.646774 },
    { lat: 48.438654, lng: 21.646892 },
    { lat: 48.438465, lng: 21.646979 },
    { lat: 48.438376, lng: 21.646992 },
    { lat: 48.438084, lng: 21.647137 },
    { lat: 48.438016, lng: 21.647265 },
    { lat: 48.438005, lng: 21.647334 },
    { lat: 48.438026, lng: 21.647405 },
    { lat: 48.437992, lng: 21.647599 },
    { lat: 48.437987, lng: 21.64786 },
    { lat: 48.438039, lng: 21.648095 },
    { lat: 48.438158, lng: 21.648184 },
    { lat: 48.438205, lng: 21.648192 },
    { lat: 48.438262, lng: 21.648196 },
    { lat: 48.438374, lng: 21.648217 },
    { lat: 48.438399, lng: 21.648309 },
    { lat: 48.438455, lng: 21.648483 },
    { lat: 48.438437, lng: 21.648671 },
    { lat: 48.438294, lng: 21.648891 },
    { lat: 48.438137, lng: 21.64894 },
    { lat: 48.437968, lng: 21.648898 },
    { lat: 48.437797, lng: 21.648822 },
    { lat: 48.437691, lng: 21.648877 },
    { lat: 48.437657, lng: 21.648935 },
    { lat: 48.437661, lng: 21.64906 },
    { lat: 48.437689, lng: 21.649232 },
    { lat: 48.437779, lng: 21.649448 },
    { lat: 48.437698, lng: 21.649675 },
    { lat: 48.437491, lng: 21.65004 },
    { lat: 48.437487, lng: 21.650349 },
    { lat: 48.437494, lng: 21.650506 },
    { lat: 48.437436, lng: 21.650609 },
    { lat: 48.437341, lng: 21.650748 },
    { lat: 48.437174, lng: 21.650776 },
    { lat: 48.437005, lng: 21.650613 },
    { lat: 48.436701, lng: 21.650459 },
    { lat: 48.436546, lng: 21.650527 },
    { lat: 48.436416, lng: 21.650672 },
    { lat: 48.436366, lng: 21.650887 },
    { lat: 48.436381, lng: 21.651003 },
    { lat: 48.436483, lng: 21.651254 },
    { lat: 48.436451, lng: 21.651535 },
    { lat: 48.436376, lng: 21.651657 },
    { lat: 48.436243, lng: 21.65187 },
    { lat: 48.436049, lng: 21.652082 },
    { lat: 48.43569, lng: 21.652087 },
    { lat: 48.435514, lng: 21.652146 },
    { lat: 48.435342, lng: 21.652239 },
    { lat: 48.435123, lng: 21.652357 },
    { lat: 48.434867, lng: 21.652498 },
    { lat: 48.4346, lng: 21.652344 },
    { lat: 48.434558, lng: 21.652116 },
    { lat: 48.434407, lng: 21.6517 },
    { lat: 48.434242, lng: 21.650697 },
    { lat: 48.43417, lng: 21.650365 },
    { lat: 48.433921, lng: 21.65005 },
    { lat: 48.433822, lng: 21.650109 },
    { lat: 48.433519, lng: 21.650341 },
    { lat: 48.433361, lng: 21.65037 },
    { lat: 48.433159, lng: 21.650369 },
    { lat: 48.43301, lng: 21.65043 },
    { lat: 48.432948, lng: 21.650463 },
    { lat: 48.43277, lng: 21.650358 },
    { lat: 48.432651, lng: 21.650405 },
    { lat: 48.432612, lng: 21.650443 },
    { lat: 48.432511, lng: 21.650553 },
    { lat: 48.432433, lng: 21.65082 },
    { lat: 48.432379, lng: 21.651057 },
    { lat: 48.432389, lng: 21.651315 },
    { lat: 48.432416, lng: 21.651547 },
    { lat: 48.432519, lng: 21.651982 },
    { lat: 48.432699, lng: 21.652284 },
    { lat: 48.432957, lng: 21.65242 },
    { lat: 48.433086, lng: 21.652693 },
    { lat: 48.433031, lng: 21.652802 },
    { lat: 48.432894, lng: 21.652871 },
    { lat: 48.432586, lng: 21.652763 },
    { lat: 48.432403, lng: 21.652678 },
    { lat: 48.432053, lng: 21.652484 },
    { lat: 48.431893, lng: 21.652533 },
    { lat: 48.431672, lng: 21.652571 },
    { lat: 48.431585, lng: 21.652568 },
    { lat: 48.431558, lng: 21.652592 },
    { lat: 48.43148, lng: 21.652596 },
    { lat: 48.431348, lng: 21.652709 },
    { lat: 48.43114, lng: 21.65283 },
    { lat: 48.431014, lng: 21.652925 },
    { lat: 48.430902, lng: 21.65303 },
    { lat: 48.430773, lng: 21.653182 },
    { lat: 48.430641, lng: 21.653259 },
    { lat: 48.430425, lng: 21.653478 },
    { lat: 48.430178, lng: 21.653686 },
    { lat: 48.429989, lng: 21.653818 },
    { lat: 48.429901, lng: 21.65389 },
    { lat: 48.429739, lng: 21.654048 },
    { lat: 48.429605, lng: 21.654156 },
    { lat: 48.429467, lng: 21.654249 },
    { lat: 48.429346, lng: 21.654311 },
    { lat: 48.428942, lng: 21.654497 },
    { lat: 48.428637, lng: 21.654626 },
    { lat: 48.428233, lng: 21.654807 },
    { lat: 48.427791, lng: 21.655041 },
    { lat: 48.427623, lng: 21.655146 },
    { lat: 48.427501, lng: 21.655213 },
    { lat: 48.427394, lng: 21.655275 },
    { lat: 48.426906, lng: 21.655519 },
    { lat: 48.426725, lng: 21.655565 },
    { lat: 48.426457, lng: 21.655588 },
    { lat: 48.426335, lng: 21.655674 },
    { lat: 48.426156, lng: 21.655816 },
    { lat: 48.425965, lng: 21.655927 },
    { lat: 48.42576, lng: 21.655948 },
    { lat: 48.425476, lng: 21.656004 },
    { lat: 48.425375, lng: 21.656025 },
    { lat: 48.425132, lng: 21.656098 },
    { lat: 48.4250884, lng: 21.6561204 },
    { lat: 48.4249504, lng: 21.6561787 },
    { lat: 48.424778, lng: 21.65628 },
    { lat: 48.42446, lng: 21.656433 },
    { lat: 48.423969, lng: 21.656677 },
    { lat: 48.423765, lng: 21.656783 },
    { lat: 48.423448, lng: 21.65694 },
    { lat: 48.423308, lng: 21.657032 },
    { lat: 48.423056, lng: 21.657162 },
    { lat: 48.42278, lng: 21.657301 },
    { lat: 48.422436, lng: 21.657464 },
    { lat: 48.421592, lng: 21.657885 },
    { lat: 48.421525, lng: 21.657954 },
    { lat: 48.421493, lng: 21.657974 },
    { lat: 48.421445, lng: 21.657999 },
    { lat: 48.421336, lng: 21.658029 },
    { lat: 48.420937, lng: 21.658388 },
    { lat: 48.420214, lng: 21.658687 },
    { lat: 48.419753, lng: 21.658738 },
    { lat: 48.419589, lng: 21.658808 },
    { lat: 48.418886, lng: 21.659433 },
    { lat: 48.417996, lng: 21.659563 },
    { lat: 48.417366, lng: 21.660186 },
    { lat: 48.416919, lng: 21.660372 },
    { lat: 48.416973, lng: 21.660542 },
    { lat: 48.417458, lng: 21.66201 },
    { lat: 48.417928, lng: 21.663445 },
    { lat: 48.4179421, lng: 21.6634883 },
    { lat: 48.412247, lng: 21.6656539 },
    { lat: 48.4117332, lng: 21.665818 },
    { lat: 48.4114637, lng: 21.6658635 },
    { lat: 48.410553, lng: 21.665227 },
    { lat: 48.409593, lng: 21.664631 },
    { lat: 48.40867, lng: 21.664078 },
    { lat: 48.408586, lng: 21.664199 },
    { lat: 48.408411, lng: 21.664455 },
    { lat: 48.408317, lng: 21.66456 },
    { lat: 48.408049, lng: 21.664905 },
    { lat: 48.407987, lng: 21.664965 },
    { lat: 48.407661, lng: 21.665345 },
    { lat: 48.407599, lng: 21.665444 },
    { lat: 48.407248, lng: 21.665745 },
    { lat: 48.407077, lng: 21.665784 },
    { lat: 48.406773, lng: 21.665842 },
    { lat: 48.406349, lng: 21.665759 },
    { lat: 48.40623, lng: 21.665746 },
    { lat: 48.405963, lng: 21.665704 },
    { lat: 48.405605, lng: 21.665631 },
    { lat: 48.405562, lng: 21.665609 },
    { lat: 48.405178, lng: 21.665462 },
    { lat: 48.404963, lng: 21.665373 },
    { lat: 48.404865, lng: 21.665256 },
    { lat: 48.404522, lng: 21.665092 },
    { lat: 48.404232, lng: 21.665038 },
    { lat: 48.40382, lng: 21.66505 },
    { lat: 48.403577, lng: 21.665065 },
    { lat: 48.403353, lng: 21.665076 },
    { lat: 48.403078, lng: 21.665105 },
    { lat: 48.402954, lng: 21.665074 },
    { lat: 48.402882, lng: 21.665003 },
    { lat: 48.402692, lng: 21.664796 },
    { lat: 48.402498, lng: 21.664652 },
    { lat: 48.402394, lng: 21.66458 },
    { lat: 48.402164, lng: 21.664574 },
    { lat: 48.402006, lng: 21.664608 },
    { lat: 48.401863, lng: 21.664661 },
    { lat: 48.401689, lng: 21.664802 },
    { lat: 48.401546, lng: 21.664959 },
    { lat: 48.401409, lng: 21.665166 },
    { lat: 48.401271, lng: 21.665406 },
    { lat: 48.401161, lng: 21.665717 },
    { lat: 48.401017, lng: 21.666148 },
    { lat: 48.400907, lng: 21.66647 },
    { lat: 48.400863, lng: 21.666581 },
    { lat: 48.400794, lng: 21.666647 },
    { lat: 48.40061, lng: 21.66674 },
    { lat: 48.400336, lng: 21.666846 },
    { lat: 48.400234, lng: 21.666884 },
    { lat: 48.400051, lng: 21.666958 },
    { lat: 48.399845, lng: 21.667098 },
    { lat: 48.39961, lng: 21.66724 },
    { lat: 48.399552, lng: 21.667278 },
    { lat: 48.399245, lng: 21.667369 },
    { lat: 48.399055, lng: 21.667369 },
    { lat: 48.398674, lng: 21.667402 },
    { lat: 48.398557, lng: 21.667405 },
    { lat: 48.398214, lng: 21.667415 },
    { lat: 48.397857, lng: 21.667375 },
    { lat: 48.397352, lng: 21.66726 },
    { lat: 48.397086, lng: 21.667195 },
    { lat: 48.396865, lng: 21.667128 },
    { lat: 48.39682, lng: 21.667128 },
    { lat: 48.396543, lng: 21.666988 },
    { lat: 48.396183, lng: 21.666841 },
    { lat: 48.396016, lng: 21.666758 },
    { lat: 48.39576, lng: 21.666633 },
    { lat: 48.395598, lng: 21.666547 },
    { lat: 48.395348, lng: 21.66648 },
    { lat: 48.395018, lng: 21.666182 },
    { lat: 48.394961, lng: 21.666144 },
    { lat: 48.394822, lng: 21.66606 },
    { lat: 48.394576, lng: 21.665895 },
    { lat: 48.394451, lng: 21.665811 },
    { lat: 48.394331, lng: 21.665727 },
    { lat: 48.394055, lng: 21.665605 },
    { lat: 48.393809, lng: 21.66546 },
    { lat: 48.393621, lng: 21.665364 },
    { lat: 48.393305, lng: 21.665224 },
    { lat: 48.393056, lng: 21.665071 },
    { lat: 48.392757, lng: 21.664918 },
    { lat: 48.392519, lng: 21.664785 },
    { lat: 48.392447, lng: 21.664746 },
    { lat: 48.392425, lng: 21.664725 },
    { lat: 48.392402, lng: 21.664714 },
    { lat: 48.392328, lng: 21.664662 },
    { lat: 48.3923, lng: 21.664645 },
    { lat: 48.392164, lng: 21.664561 },
    { lat: 48.3920465, lng: 21.664902 },
    { lat: 48.392024, lng: 21.664962 },
    { lat: 48.391866, lng: 21.665448 },
    { lat: 48.391736, lng: 21.665826 },
    { lat: 48.391608, lng: 21.666111 },
    { lat: 48.391432, lng: 21.666425 },
    { lat: 48.391184, lng: 21.666558 },
    { lat: 48.39114, lng: 21.666595 },
    { lat: 48.3907, lng: 21.666733 },
    { lat: 48.390481, lng: 21.6668 },
    { lat: 48.390376, lng: 21.666832 },
    { lat: 48.39025, lng: 21.667207 },
    { lat: 48.390195, lng: 21.667167 },
    { lat: 48.389945, lng: 21.667909 },
    { lat: 48.389879, lng: 21.66807 },
    { lat: 48.389808, lng: 21.668188 },
    { lat: 48.389752, lng: 21.668255 },
    { lat: 48.389666, lng: 21.668344 },
    { lat: 48.3895996, lng: 21.6683929 },
    { lat: 48.3895383, lng: 21.6684403 },
    { lat: 48.38946, lng: 21.668476 },
    { lat: 48.389323, lng: 21.668527 },
    { lat: 48.389217, lng: 21.668569 },
    { lat: 48.389231, lng: 21.668708 },
    { lat: 48.38944, lng: 21.669429 },
    { lat: 48.389484, lng: 21.669701 },
    { lat: 48.389643, lng: 21.670848 },
    { lat: 48.3897032, lng: 21.6715418 },
    { lat: 48.3897413, lng: 21.6718306 },
    { lat: 48.389736, lng: 21.672293 },
    { lat: 48.389942, lng: 21.673556 },
    { lat: 48.38999, lng: 21.673895 },
    { lat: 48.390119, lng: 21.674887 },
    { lat: 48.390131, lng: 21.674968 },
    { lat: 48.390243, lng: 21.675948 },
    { lat: 48.390264, lng: 21.676178 },
    { lat: 48.390289, lng: 21.676514 },
    { lat: 48.390319, lng: 21.677314 },
    { lat: 48.39038, lng: 21.677337 },
    { lat: 48.390392, lng: 21.677424 },
    { lat: 48.390533, lng: 21.678558 },
    { lat: 48.390724, lng: 21.68005 },
    { lat: 48.390759, lng: 21.680319 },
    { lat: 48.390849, lng: 21.681147 },
    { lat: 48.390895, lng: 21.68174 },
    { lat: 48.390897, lng: 21.6819 },
    { lat: 48.390878, lng: 21.682845 },
    { lat: 48.39082, lng: 21.683228 },
    { lat: 48.390814, lng: 21.683296 },
    { lat: 48.390273, lng: 21.683368 },
    { lat: 48.3902628, lng: 21.6834136 },
    { lat: 48.3902544, lng: 21.6843788 },
    { lat: 48.3901746, lng: 21.684919 },
    { lat: 48.390299, lng: 21.6846901 },
    { lat: 48.3922832, lng: 21.684134 },
    { lat: 48.3923651, lng: 21.6864722 },
    { lat: 48.3927349, lng: 21.6866218 },
    { lat: 48.3927756, lng: 21.6867907 },
    { lat: 48.3934552, lng: 21.6862959 },
    { lat: 48.3938897, lng: 21.6858967 },
    { lat: 48.3955578, lng: 21.6842307 },
    { lat: 48.3958558, lng: 21.6847578 },
    { lat: 48.3958294, lng: 21.6847807 },
    { lat: 48.3972309, lng: 21.6879711 },
    { lat: 48.3973678, lng: 21.6879938 },
    { lat: 48.3980695, lng: 21.6823853 },
    { lat: 48.4024552, lng: 21.6817213 },
    { lat: 48.4101561, lng: 21.6806962 },
    { lat: 48.4130624, lng: 21.6803731 },
    { lat: 48.4135722, lng: 21.6848959 },
    { lat: 48.4150771, lng: 21.6995508 },
    { lat: 48.4151515, lng: 21.6997612 },
    { lat: 48.4181878, lng: 21.7028794 },
    { lat: 48.4182769, lng: 21.703588 },
    { lat: 48.4184969, lng: 21.7041961 },
    { lat: 48.4189698, lng: 21.7052446 },
    { lat: 48.4201237, lng: 21.7073345 },
    { lat: 48.4202208, lng: 21.7075517 },
    { lat: 48.4203256, lng: 21.7074337 },
    { lat: 48.420592, lng: 21.7074231 },
    { lat: 48.4209859, lng: 21.7074737 },
    { lat: 48.4218578, lng: 21.7077592 },
    { lat: 48.4227108, lng: 21.7076793 },
    { lat: 48.4236177, lng: 21.7082121 },
    { lat: 48.423976, lng: 21.7085653 },
    { lat: 48.4242325, lng: 21.708236 },
    { lat: 48.4245798, lng: 21.7082597 },
    { lat: 48.424853, lng: 21.7078413 },
    { lat: 48.4254899, lng: 21.7075914 },
    { lat: 48.4257375, lng: 21.7073084 },
    { lat: 48.4267434, lng: 21.7074296 },
    { lat: 48.4278427, lng: 21.7077643 },
    { lat: 48.4284891, lng: 21.7080297 },
    { lat: 48.4295842, lng: 21.708828 },
    { lat: 48.4388685, lng: 21.710672 },
  ],
  ZemplínskaTeplica: [
    { lat: 48.6936154, lng: 21.5544268 },
    { lat: 48.6938688, lng: 21.5541165 },
    { lat: 48.6942175, lng: 21.5539184 },
    { lat: 48.6946296, lng: 21.5535324 },
    { lat: 48.694877, lng: 21.5534509 },
    { lat: 48.6954128, lng: 21.5534554 },
    { lat: 48.6958419, lng: 21.5529974 },
    { lat: 48.6960815, lng: 21.5528312 },
    { lat: 48.6964889, lng: 21.5528309 },
    { lat: 48.6968191, lng: 21.5529335 },
    { lat: 48.6979053, lng: 21.552709 },
    { lat: 48.6982609, lng: 21.5525097 },
    { lat: 48.6989799, lng: 21.551915 },
    { lat: 48.6994195, lng: 21.551702 },
    { lat: 48.6998059, lng: 21.5512848 },
    { lat: 48.6999846, lng: 21.5510015 },
    { lat: 48.7002211, lng: 21.5502927 },
    { lat: 48.7002433, lng: 21.548657 },
    { lat: 48.700092, lng: 21.5481642 },
    { lat: 48.700103, lng: 21.5474799 },
    { lat: 48.7003323, lng: 21.5470757 },
    { lat: 48.7004312, lng: 21.5467879 },
    { lat: 48.700593, lng: 21.5459528 },
    { lat: 48.7009791, lng: 21.5447684 },
    { lat: 48.7014597, lng: 21.5444982 },
    { lat: 48.7017318, lng: 21.5444488 },
    { lat: 48.7020268, lng: 21.5439442 },
    { lat: 48.7022086, lng: 21.5437286 },
    { lat: 48.7030815, lng: 21.5432053 },
    { lat: 48.7035684, lng: 21.5427602 },
    { lat: 48.703592, lng: 21.542707 },
    { lat: 48.703774, lng: 21.542153 },
    { lat: 48.703927, lng: 21.54147 },
    { lat: 48.704218, lng: 21.540802 },
    { lat: 48.704091, lng: 21.540356 },
    { lat: 48.703986, lng: 21.540118 },
    { lat: 48.703982, lng: 21.540101 },
    { lat: 48.703896, lng: 21.539765 },
    { lat: 48.703941, lng: 21.539325 },
    { lat: 48.70394, lng: 21.538707 },
    { lat: 48.703866, lng: 21.538309 },
    { lat: 48.703823, lng: 21.53782 },
    { lat: 48.703668, lng: 21.537478 },
    { lat: 48.703444, lng: 21.537048 },
    { lat: 48.703324, lng: 21.536559 },
    { lat: 48.703236, lng: 21.536089 },
    { lat: 48.703195, lng: 21.535739 },
    { lat: 48.703236, lng: 21.535309 },
    { lat: 48.702435, lng: 21.535644 },
    { lat: 48.70226, lng: 21.535684 },
    { lat: 48.70201, lng: 21.535847 },
    { lat: 48.701865, lng: 21.536268 },
    { lat: 48.701799, lng: 21.536557 },
    { lat: 48.701814, lng: 21.536866 },
    { lat: 48.701546, lng: 21.536974 },
    { lat: 48.701398, lng: 21.537232 },
    { lat: 48.701162, lng: 21.537383 },
    { lat: 48.700971, lng: 21.537452 },
    { lat: 48.700719, lng: 21.537473 },
    { lat: 48.700451, lng: 21.537422 },
    { lat: 48.700252, lng: 21.537432 },
    { lat: 48.70001, lng: 21.537486 },
    { lat: 48.699839, lng: 21.537576 },
    { lat: 48.699635, lng: 21.537552 },
    { lat: 48.699501, lng: 21.537586 },
    { lat: 48.699429, lng: 21.537666 },
    { lat: 48.699356, lng: 21.537788 },
    { lat: 48.699277, lng: 21.537809 },
    { lat: 48.699263, lng: 21.537869 },
    { lat: 48.699119, lng: 21.538011 },
    { lat: 48.698873, lng: 21.538096 },
    { lat: 48.698654, lng: 21.537957 },
    { lat: 48.698431, lng: 21.538219 },
    { lat: 48.698355, lng: 21.538234 },
    { lat: 48.698252, lng: 21.538217 },
    { lat: 48.698141, lng: 21.538366 },
    { lat: 48.697931, lng: 21.538328 },
    { lat: 48.697783, lng: 21.538224 },
    { lat: 48.697619, lng: 21.538301 },
    { lat: 48.697519, lng: 21.538391 },
    { lat: 48.697187, lng: 21.538319 },
    { lat: 48.696814, lng: 21.538187 },
    { lat: 48.696427, lng: 21.538069 },
    { lat: 48.696235, lng: 21.538002 },
    { lat: 48.696111, lng: 21.537927 },
    { lat: 48.696035, lng: 21.537821 },
    { lat: 48.69592, lng: 21.537725 },
    { lat: 48.695892, lng: 21.537489 },
    { lat: 48.695689, lng: 21.537386 },
    { lat: 48.695579, lng: 21.537389 },
    { lat: 48.695367, lng: 21.537237 },
    { lat: 48.695232, lng: 21.537244 },
    { lat: 48.69502, lng: 21.537003 },
    { lat: 48.694929, lng: 21.537039 },
    { lat: 48.694802, lng: 21.536791 },
    { lat: 48.6941, lng: 21.536828 },
    { lat: 48.694027, lng: 21.536873 },
    { lat: 48.693847, lng: 21.53687 },
    { lat: 48.693734, lng: 21.537097 },
    { lat: 48.693697, lng: 21.537125 },
    { lat: 48.693629, lng: 21.537141 },
    { lat: 48.693497, lng: 21.537081 },
    { lat: 48.693154, lng: 21.5373 },
    { lat: 48.693068, lng: 21.537391 },
    { lat: 48.692901, lng: 21.537278 },
    { lat: 48.692859, lng: 21.537275 },
    { lat: 48.692812, lng: 21.53735 },
    { lat: 48.692735, lng: 21.537539 },
    { lat: 48.692524, lng: 21.537469 },
    { lat: 48.692479, lng: 21.537447 },
    { lat: 48.692214, lng: 21.537661 },
    { lat: 48.692105, lng: 21.537896 },
    { lat: 48.691977, lng: 21.538103 },
    { lat: 48.691812, lng: 21.538294 },
    { lat: 48.6915, lng: 21.538603 },
    { lat: 48.691224, lng: 21.538687 },
    { lat: 48.690983, lng: 21.538847 },
    { lat: 48.690509, lng: 21.539114 },
    { lat: 48.690453, lng: 21.539274 },
    { lat: 48.690369, lng: 21.539423 },
    { lat: 48.690232, lng: 21.539413 },
    { lat: 48.690121, lng: 21.539744 },
    { lat: 48.690008, lng: 21.539814 },
    { lat: 48.689918, lng: 21.539907 },
    { lat: 48.689798, lng: 21.540058 },
    { lat: 48.689591, lng: 21.540137 },
    { lat: 48.689511, lng: 21.540368 },
    { lat: 48.689375, lng: 21.540098 },
    { lat: 48.689111, lng: 21.540206 },
    { lat: 48.688931, lng: 21.540221 },
    { lat: 48.688884, lng: 21.540319 },
    { lat: 48.688853, lng: 21.540538 },
    { lat: 48.688579, lng: 21.540599 },
    { lat: 48.688435, lng: 21.540598 },
    { lat: 48.688415, lng: 21.540674 },
    { lat: 48.688033, lng: 21.540874 },
    { lat: 48.687526, lng: 21.5413 },
    { lat: 48.687514, lng: 21.541391 },
    { lat: 48.687415, lng: 21.541436 },
    { lat: 48.68725, lng: 21.541388 },
    { lat: 48.687081, lng: 21.541273 },
    { lat: 48.686996, lng: 21.541376 },
    { lat: 48.686787, lng: 21.541497 },
    { lat: 48.686519, lng: 21.541493 },
    { lat: 48.686323, lng: 21.541581 },
    { lat: 48.686144, lng: 21.541478 },
    { lat: 48.685882, lng: 21.54152 },
    { lat: 48.685641, lng: 21.541276 },
    { lat: 48.685518, lng: 21.541456 },
    { lat: 48.685371, lng: 21.5416 },
    { lat: 48.685177, lng: 21.541396 },
    { lat: 48.684873, lng: 21.541264 },
    { lat: 48.68426, lng: 21.541167 },
    { lat: 48.683863, lng: 21.54116 },
    { lat: 48.683772, lng: 21.5411 },
    { lat: 48.683365, lng: 21.5414 },
    { lat: 48.683051, lng: 21.541344 },
    { lat: 48.682813, lng: 21.541238 },
    { lat: 48.682737, lng: 21.541314 },
    { lat: 48.682637, lng: 21.541478 },
    { lat: 48.682487, lng: 21.541397 },
    { lat: 48.682257, lng: 21.541581 },
    { lat: 48.682162, lng: 21.541602 },
    { lat: 48.682087, lng: 21.541538 },
    { lat: 48.681925, lng: 21.541339 },
    { lat: 48.681893, lng: 21.541341 },
    { lat: 48.68175, lng: 21.541545 },
    { lat: 48.681673, lng: 21.541557 },
    { lat: 48.681493, lng: 21.541426 },
    { lat: 48.681433, lng: 21.541404 },
    { lat: 48.681384, lng: 21.541428 },
    { lat: 48.68115, lng: 21.541595 },
    { lat: 48.680931, lng: 21.541392 },
    { lat: 48.680787, lng: 21.541439 },
    { lat: 48.680629, lng: 21.541389 },
    { lat: 48.680539, lng: 21.541333 },
    { lat: 48.679495, lng: 21.540547 },
    { lat: 48.679169, lng: 21.540602 },
    { lat: 48.679026, lng: 21.5404 },
    { lat: 48.67822, lng: 21.53972 },
    { lat: 48.67809, lng: 21.539745 },
    { lat: 48.677934, lng: 21.539502 },
    { lat: 48.677823, lng: 21.539401 },
    { lat: 48.677631, lng: 21.539357 },
    { lat: 48.677447, lng: 21.539192 },
    { lat: 48.677231, lng: 21.539275 },
    { lat: 48.676997, lng: 21.539222 },
    { lat: 48.676778, lng: 21.539059 },
    { lat: 48.676523, lng: 21.538857 },
    { lat: 48.676356, lng: 21.538972 },
    { lat: 48.676137, lng: 21.539006 },
    { lat: 48.675963, lng: 21.538702 },
    { lat: 48.675038, lng: 21.538949 },
    { lat: 48.674872, lng: 21.539065 },
    { lat: 48.674792, lng: 21.538896 },
    { lat: 48.674688, lng: 21.538854 },
    { lat: 48.674513, lng: 21.538917 },
    { lat: 48.674393, lng: 21.539128 },
    { lat: 48.674149, lng: 21.539034 },
    { lat: 48.673872, lng: 21.538673 },
    { lat: 48.673631, lng: 21.538706 },
    { lat: 48.673588, lng: 21.538519 },
    { lat: 48.673684, lng: 21.538455 },
    { lat: 48.673681, lng: 21.538345 },
    { lat: 48.67338, lng: 21.538148 },
    { lat: 48.673243, lng: 21.538095 },
    { lat: 48.673097, lng: 21.53801 },
    { lat: 48.672945, lng: 21.53794 },
    { lat: 48.672841, lng: 21.537979 },
    { lat: 48.672735, lng: 21.537874 },
    { lat: 48.672637, lng: 21.537835 },
    { lat: 48.67252, lng: 21.537912 },
    { lat: 48.672137, lng: 21.538009 },
    { lat: 48.67177, lng: 21.537666 },
    { lat: 48.671627, lng: 21.537647 },
    { lat: 48.671438, lng: 21.537721 },
    { lat: 48.671283, lng: 21.537471 },
    { lat: 48.671213, lng: 21.537435 },
    { lat: 48.670982, lng: 21.537435 },
    { lat: 48.670342, lng: 21.537051 },
    { lat: 48.670094, lng: 21.537192 },
    { lat: 48.669579, lng: 21.537052 },
    { lat: 48.669483, lng: 21.536868 },
    { lat: 48.66934, lng: 21.536796 },
    { lat: 48.669232, lng: 21.536786 },
    { lat: 48.669118, lng: 21.536705 },
    { lat: 48.66897, lng: 21.536544 },
    { lat: 48.668955, lng: 21.536351 },
    { lat: 48.668911, lng: 21.536285 },
    { lat: 48.6687, lng: 21.536151 },
    { lat: 48.668515, lng: 21.536265 },
    { lat: 48.66819, lng: 21.536017 },
    { lat: 48.66809, lng: 21.535886 },
    { lat: 48.667858, lng: 21.535741 },
    { lat: 48.667751, lng: 21.535455 },
    { lat: 48.667604, lng: 21.535597 },
    { lat: 48.667523, lng: 21.535556 },
    { lat: 48.667405, lng: 21.535402 },
    { lat: 48.667071, lng: 21.535294 },
    { lat: 48.666996, lng: 21.534872 },
    { lat: 48.666865, lng: 21.53469 },
    { lat: 48.666577, lng: 21.534495 },
    { lat: 48.666205, lng: 21.534488 },
    { lat: 48.665957, lng: 21.534077 },
    { lat: 48.665867, lng: 21.533897 },
    { lat: 48.665778, lng: 21.533827 },
    { lat: 48.665415, lng: 21.533675 },
    { lat: 48.665309, lng: 21.533548 },
    { lat: 48.665209, lng: 21.533272 },
    { lat: 48.665166, lng: 21.533269 },
    { lat: 48.665053, lng: 21.533319 },
    { lat: 48.664753, lng: 21.533306 },
    { lat: 48.664534, lng: 21.533277 },
    { lat: 48.664381, lng: 21.533348 },
    { lat: 48.664263, lng: 21.533431 },
    { lat: 48.664096, lng: 21.533617 },
    { lat: 48.663607, lng: 21.533486 },
    { lat: 48.663316, lng: 21.533349 },
    { lat: 48.663198, lng: 21.533163 },
    { lat: 48.663144, lng: 21.532823 },
    { lat: 48.66313, lng: 21.532776 },
    { lat: 48.663106, lng: 21.532759 },
    { lat: 48.662811, lng: 21.532744 },
    { lat: 48.662672, lng: 21.532395 },
    { lat: 48.662644, lng: 21.532379 },
    { lat: 48.662348, lng: 21.532492 },
    { lat: 48.662135, lng: 21.532647 },
    { lat: 48.66188, lng: 21.532602 },
    { lat: 48.661512, lng: 21.532676 },
    { lat: 48.661354, lng: 21.532591 },
    { lat: 48.660951, lng: 21.53248 },
    { lat: 48.660697, lng: 21.53248 },
    { lat: 48.660466, lng: 21.532393 },
    { lat: 48.659884, lng: 21.532473 },
    { lat: 48.659549, lng: 21.532238 },
    { lat: 48.659562, lng: 21.532218 },
    { lat: 48.659533, lng: 21.532202 },
    { lat: 48.659575, lng: 21.532127 },
    { lat: 48.659454, lng: 21.531988 },
    { lat: 48.659323, lng: 21.531856 },
    { lat: 48.659199, lng: 21.531797 },
    { lat: 48.659062, lng: 21.531805 },
    { lat: 48.65896, lng: 21.531779 },
    { lat: 48.658819, lng: 21.531632 },
    { lat: 48.658656, lng: 21.531618 },
    { lat: 48.658488, lng: 21.531507 },
    { lat: 48.658186, lng: 21.531617 },
    { lat: 48.658119, lng: 21.53151 },
    { lat: 48.65795, lng: 21.531504 },
    { lat: 48.657808, lng: 21.531535 },
    { lat: 48.657635, lng: 21.531278 },
    { lat: 48.657517, lng: 21.531234 },
    { lat: 48.6574, lng: 21.531152 },
    { lat: 48.657299, lng: 21.531154 },
    { lat: 48.657137, lng: 21.531167 },
    { lat: 48.65697, lng: 21.531137 },
    { lat: 48.656916, lng: 21.531113 },
    { lat: 48.656776, lng: 21.530983 },
    { lat: 48.65658, lng: 21.531011 },
    { lat: 48.656111, lng: 21.530783 },
    { lat: 48.655713, lng: 21.530437 },
    { lat: 48.655485, lng: 21.530341 },
    { lat: 48.655331, lng: 21.530345 },
    { lat: 48.65519, lng: 21.530509 },
    { lat: 48.655028, lng: 21.530817 },
    { lat: 48.654628, lng: 21.530792 },
    { lat: 48.654349, lng: 21.530532 },
    { lat: 48.654187, lng: 21.530844 },
    { lat: 48.653966, lng: 21.53135 },
    { lat: 48.653846, lng: 21.531296 },
    { lat: 48.653731, lng: 21.53118 },
    { lat: 48.653497, lng: 21.531511 },
    { lat: 48.65343, lng: 21.53175 },
    { lat: 48.653284, lng: 21.531813 },
    { lat: 48.653211, lng: 21.531822 },
    { lat: 48.653188, lng: 21.531911 },
    { lat: 48.653205, lng: 21.531994 },
    { lat: 48.653114, lng: 21.532059 },
    { lat: 48.653035, lng: 21.532063 },
    { lat: 48.653046, lng: 21.532229 },
    { lat: 48.652965, lng: 21.532215 },
    { lat: 48.652843, lng: 21.532231 },
    { lat: 48.652724, lng: 21.532429 },
    { lat: 48.652567, lng: 21.532526 },
    { lat: 48.652457, lng: 21.532834 },
    { lat: 48.652307, lng: 21.532943 },
    { lat: 48.652214, lng: 21.533163 },
    { lat: 48.652162, lng: 21.533167 },
    { lat: 48.652026, lng: 21.533293 },
    { lat: 48.651845, lng: 21.533317 },
    { lat: 48.65172, lng: 21.533518 },
    { lat: 48.651639, lng: 21.533542 },
    { lat: 48.65131, lng: 21.533405 },
    { lat: 48.651171, lng: 21.53339 },
    { lat: 48.651046, lng: 21.533315 },
    { lat: 48.650869, lng: 21.533259 },
    { lat: 48.650727, lng: 21.533395 },
    { lat: 48.650519, lng: 21.533389 },
    { lat: 48.650287, lng: 21.533662 },
    { lat: 48.650096, lng: 21.533856 },
    { lat: 48.649944, lng: 21.534121 },
    { lat: 48.649779, lng: 21.534162 },
    { lat: 48.649647, lng: 21.5343 },
    { lat: 48.649645, lng: 21.534266 },
    { lat: 48.649609, lng: 21.534311 },
    { lat: 48.649083, lng: 21.535035 },
    { lat: 48.648439, lng: 21.535646 },
    { lat: 48.648004, lng: 21.535932 },
    { lat: 48.647575, lng: 21.535999 },
    { lat: 48.647653, lng: 21.536339 },
    { lat: 48.647575, lng: 21.536509 },
    { lat: 48.647492, lng: 21.536447 },
    { lat: 48.647471, lng: 21.536674 },
    { lat: 48.647861, lng: 21.537237 },
    { lat: 48.647861, lng: 21.53731 },
    { lat: 48.647547, lng: 21.537285 },
    { lat: 48.64737, lng: 21.537378 },
    { lat: 48.646972, lng: 21.537522 },
    { lat: 48.646883, lng: 21.537422 },
    { lat: 48.646703, lng: 21.537546 },
    { lat: 48.646521, lng: 21.537569 },
    { lat: 48.64646, lng: 21.537506 },
    { lat: 48.646235, lng: 21.537521 },
    { lat: 48.646207, lng: 21.537575 },
    { lat: 48.646235, lng: 21.537729 },
    { lat: 48.646112, lng: 21.537802 },
    { lat: 48.645969, lng: 21.537941 },
    { lat: 48.645787, lng: 21.538049 },
    { lat: 48.6457, lng: 21.53838 },
    { lat: 48.645476, lng: 21.538916 },
    { lat: 48.645102, lng: 21.539392 },
    { lat: 48.644941, lng: 21.539337 },
    { lat: 48.644892, lng: 21.539352 },
    { lat: 48.644797, lng: 21.539546 },
    { lat: 48.64458, lng: 21.539704 },
    { lat: 48.644519, lng: 21.539877 },
    { lat: 48.644425, lng: 21.540137 },
    { lat: 48.644418, lng: 21.540158 },
    { lat: 48.644371, lng: 21.540193 },
    { lat: 48.644175, lng: 21.540356 },
    { lat: 48.644119, lng: 21.540163 },
    { lat: 48.644017, lng: 21.54013 },
    { lat: 48.643903, lng: 21.540432 },
    { lat: 48.643806, lng: 21.540578 },
    { lat: 48.643716, lng: 21.540576 },
    { lat: 48.643676, lng: 21.540497 },
    { lat: 48.64359, lng: 21.540489 },
    { lat: 48.643471, lng: 21.540794 },
    { lat: 48.643415, lng: 21.540967 },
    { lat: 48.643027, lng: 21.540952 },
    { lat: 48.642629, lng: 21.540968 },
    { lat: 48.642428, lng: 21.541045 },
    { lat: 48.642357, lng: 21.541127 },
    { lat: 48.642213, lng: 21.541119 },
    { lat: 48.642116, lng: 21.541058 },
    { lat: 48.642042, lng: 21.541045 },
    { lat: 48.641885, lng: 21.540912 },
    { lat: 48.641858, lng: 21.540918 },
    { lat: 48.641804, lng: 21.540997 },
    { lat: 48.641798, lng: 21.541182 },
    { lat: 48.64168, lng: 21.541206 },
    { lat: 48.641574, lng: 21.541302 },
    { lat: 48.64145, lng: 21.541223 },
    { lat: 48.641338, lng: 21.541393 },
    { lat: 48.64114, lng: 21.541493 },
    { lat: 48.641076, lng: 21.541704 },
    { lat: 48.641004, lng: 21.54179 },
    { lat: 48.640847, lng: 21.541783 },
    { lat: 48.640689, lng: 21.541954 },
    { lat: 48.640536, lng: 21.542104 },
    { lat: 48.640393, lng: 21.542577 },
    { lat: 48.640385, lng: 21.543288 },
    { lat: 48.640369, lng: 21.543327 },
    { lat: 48.640198, lng: 21.543469 },
    { lat: 48.640088, lng: 21.543928 },
    { lat: 48.640114, lng: 21.544226 },
    { lat: 48.640005, lng: 21.544433 },
    { lat: 48.640072, lng: 21.544595 },
    { lat: 48.640036, lng: 21.544732 },
    { lat: 48.640065, lng: 21.545121 },
    { lat: 48.639861, lng: 21.545478 },
    { lat: 48.639572, lng: 21.545906 },
    { lat: 48.639558, lng: 21.546135 },
    { lat: 48.639442, lng: 21.546212 },
    { lat: 48.639388, lng: 21.546453 },
    { lat: 48.63953, lng: 21.546681 },
    { lat: 48.639352, lng: 21.54715 },
    { lat: 48.639263, lng: 21.547421 },
    { lat: 48.639288, lng: 21.547651 },
    { lat: 48.639243, lng: 21.548038 },
    { lat: 48.639111, lng: 21.548127 },
    { lat: 48.639145, lng: 21.54842 },
    { lat: 48.638996, lng: 21.548654 },
    { lat: 48.639197, lng: 21.548902 },
    { lat: 48.639208, lng: 21.549009 },
    { lat: 48.639095, lng: 21.549088 },
    { lat: 48.639122, lng: 21.549456 },
    { lat: 48.639045, lng: 21.549502 },
    { lat: 48.638916, lng: 21.549544 },
    { lat: 48.638823, lng: 21.549728 },
    { lat: 48.638699, lng: 21.549839 },
    { lat: 48.638651, lng: 21.549815 },
    { lat: 48.638524, lng: 21.549752 },
    { lat: 48.638243, lng: 21.549706 },
    { lat: 48.638061, lng: 21.549473 },
    { lat: 48.637755, lng: 21.549247 },
    { lat: 48.63771, lng: 21.549702 },
    { lat: 48.637549, lng: 21.551354 },
    { lat: 48.637266, lng: 21.551969 },
    { lat: 48.637188, lng: 21.552138 },
    { lat: 48.63637, lng: 21.552383 },
    { lat: 48.635876, lng: 21.553133 },
    { lat: 48.635232, lng: 21.553508 },
    { lat: 48.634435, lng: 21.554216 },
    { lat: 48.633991, lng: 21.554599 },
    { lat: 48.633712, lng: 21.554839 },
    { lat: 48.633367, lng: 21.555127 },
    { lat: 48.633086, lng: 21.555345 },
    { lat: 48.632751, lng: 21.555574 },
    { lat: 48.632447, lng: 21.555942 },
    { lat: 48.632195, lng: 21.556435 },
    { lat: 48.631824, lng: 21.557038 },
    { lat: 48.631547, lng: 21.557367 },
    { lat: 48.631045, lng: 21.557838 },
    { lat: 48.630511, lng: 21.558431 },
    { lat: 48.630406, lng: 21.558519 },
    { lat: 48.629949, lng: 21.558954 },
    { lat: 48.62956, lng: 21.559673 },
    { lat: 48.629363, lng: 21.559993 },
    { lat: 48.629052, lng: 21.560407 },
    { lat: 48.628892, lng: 21.560635 },
    { lat: 48.628489, lng: 21.561398 },
    { lat: 48.628424, lng: 21.561522 },
    { lat: 48.628204, lng: 21.56205 },
    { lat: 48.627835, lng: 21.562686 },
    { lat: 48.627648, lng: 21.562924 },
    { lat: 48.627068, lng: 21.563672 },
    { lat: 48.62676, lng: 21.564384 },
    { lat: 48.626283, lng: 21.564937 },
    { lat: 48.62587, lng: 21.565309 },
    { lat: 48.625523, lng: 21.565561 },
    { lat: 48.625299, lng: 21.565649 },
    { lat: 48.624782, lng: 21.566415 },
    { lat: 48.624476, lng: 21.566531 },
    { lat: 48.624103, lng: 21.566602 },
    { lat: 48.623878, lng: 21.567389 },
    { lat: 48.623653, lng: 21.567824 },
    { lat: 48.623465, lng: 21.568205 },
    { lat: 48.623014, lng: 21.569154 },
    { lat: 48.622857, lng: 21.569411 },
    { lat: 48.622714, lng: 21.56965 },
    { lat: 48.622539, lng: 21.569954 },
    { lat: 48.622403, lng: 21.570205 },
    { lat: 48.622016, lng: 21.570449 },
    { lat: 48.621601, lng: 21.570906 },
    { lat: 48.621298, lng: 21.571238 },
    { lat: 48.621001, lng: 21.572202 },
    { lat: 48.620908, lng: 21.572495 },
    { lat: 48.620666, lng: 21.573194 },
    { lat: 48.620555, lng: 21.573438 },
    { lat: 48.620346, lng: 21.573862 },
    { lat: 48.620009, lng: 21.574518 },
    { lat: 48.619923, lng: 21.574627 },
    { lat: 48.619701, lng: 21.574909 },
    { lat: 48.619158, lng: 21.57557 },
    { lat: 48.619082, lng: 21.575666 },
    { lat: 48.618907, lng: 21.576046 },
    { lat: 48.618483, lng: 21.576187 },
    { lat: 48.618144, lng: 21.576502 },
    { lat: 48.617789, lng: 21.576862 },
    { lat: 48.617126, lng: 21.577272 },
    { lat: 48.616592, lng: 21.577842 },
    { lat: 48.616564, lng: 21.577896 },
    { lat: 48.616433, lng: 21.578158 },
    { lat: 48.616181, lng: 21.578478 },
    { lat: 48.615669, lng: 21.578822 },
    { lat: 48.6159683, lng: 21.5792204 },
    { lat: 48.6158664, lng: 21.5800729 },
    { lat: 48.616369, lng: 21.5809809 },
    { lat: 48.6169613, lng: 21.5818363 },
    { lat: 48.6175669, lng: 21.582327 },
    { lat: 48.6180373, lng: 21.5834495 },
    { lat: 48.618593, lng: 21.5842038 },
    { lat: 48.6190853, lng: 21.584503 },
    { lat: 48.6192026, lng: 21.5847163 },
    { lat: 48.6192038, lng: 21.5849524 },
    { lat: 48.619267, lng: 21.5852037 },
    { lat: 48.6194179, lng: 21.5855664 },
    { lat: 48.6195827, lng: 21.5857073 },
    { lat: 48.6197783, lng: 21.5857041 },
    { lat: 48.620057, lng: 21.585804 },
    { lat: 48.6201908, lng: 21.5863207 },
    { lat: 48.6208275, lng: 21.5878003 },
    { lat: 48.620936, lng: 21.587862 },
    { lat: 48.6211254, lng: 21.58783 },
    { lat: 48.6212244, lng: 21.587956 },
    { lat: 48.6212508, lng: 21.5881734 },
    { lat: 48.6214159, lng: 21.5882404 },
    { lat: 48.621525, lng: 21.5881142 },
    { lat: 48.6216472, lng: 21.588301 },
    { lat: 48.6219745, lng: 21.5883348 },
    { lat: 48.6224434, lng: 21.5888856 },
    { lat: 48.6226113, lng: 21.5887376 },
    { lat: 48.6230964, lng: 21.5893785 },
    { lat: 48.6232769, lng: 21.589315 },
    { lat: 48.6245377, lng: 21.5912991 },
    { lat: 48.6249117, lng: 21.5916373 },
    { lat: 48.6267942, lng: 21.5928491 },
    { lat: 48.6275336, lng: 21.5934137 },
    { lat: 48.628089, lng: 21.5931133 },
    { lat: 48.6273128, lng: 21.5977418 },
    { lat: 48.6272855, lng: 21.5980904 },
    { lat: 48.6273172, lng: 21.5989007 },
    { lat: 48.6276032, lng: 21.6014053 },
    { lat: 48.6272419, lng: 21.6014767 },
    { lat: 48.6277201, lng: 21.6042981 },
    { lat: 48.6279814, lng: 21.6055552 },
    { lat: 48.6285824, lng: 21.6076149 },
    { lat: 48.629054, lng: 21.6090488 },
    { lat: 48.6290497, lng: 21.6091214 },
    { lat: 48.6289093, lng: 21.610021 },
    { lat: 48.6289184, lng: 21.61055 },
    { lat: 48.6290265, lng: 21.6109941 },
    { lat: 48.6290904, lng: 21.6114753 },
    { lat: 48.629099, lng: 21.6116215 },
    { lat: 48.6291052, lng: 21.611718 },
    { lat: 48.6292325, lng: 21.6125315 },
    { lat: 48.6291383, lng: 21.6126193 },
    { lat: 48.6290427, lng: 21.6128676 },
    { lat: 48.6290161, lng: 21.6132421 },
    { lat: 48.6290107, lng: 21.6132925 },
    { lat: 48.629349, lng: 21.6132416 },
    { lat: 48.6313819, lng: 21.6127851 },
    { lat: 48.6314817, lng: 21.6128122 },
    { lat: 48.631702, lng: 21.6130426 },
    { lat: 48.6319554, lng: 21.6131353 },
    { lat: 48.6328172, lng: 21.6133304 },
    { lat: 48.6330734, lng: 21.6134461 },
    { lat: 48.6334883, lng: 21.6134693 },
    { lat: 48.6348017, lng: 21.6127958 },
    { lat: 48.6358787, lng: 21.6126848 },
    { lat: 48.636069, lng: 21.6127265 },
    { lat: 48.6360218, lng: 21.6130984 },
    { lat: 48.6361173, lng: 21.613209 },
    { lat: 48.636143, lng: 21.6133262 },
    { lat: 48.636349, lng: 21.6132078 },
    { lat: 48.6364635, lng: 21.6130341 },
    { lat: 48.6365432, lng: 21.6130664 },
    { lat: 48.6365375, lng: 21.6129472 },
    { lat: 48.6366719, lng: 21.612848 },
    { lat: 48.6370041, lng: 21.6128532 },
    { lat: 48.6372969, lng: 21.6136333 },
    { lat: 48.6373885, lng: 21.613646 },
    { lat: 48.637517, lng: 21.6132117 },
    { lat: 48.637723, lng: 21.6130488 },
    { lat: 48.637871, lng: 21.6127527 },
    { lat: 48.6384431, lng: 21.6128897 },
    { lat: 48.6384838, lng: 21.612896 },
    { lat: 48.6387837, lng: 21.6128016 },
    { lat: 48.6394103, lng: 21.6128753 },
    { lat: 48.6397062, lng: 21.6127555 },
    { lat: 48.6400405, lng: 21.6125194 },
    { lat: 48.6401784, lng: 21.6125743 },
    { lat: 48.6403635, lng: 21.6128031 },
    { lat: 48.6412235, lng: 21.6127763 },
    { lat: 48.6414828, lng: 21.6127147 },
    { lat: 48.6421631, lng: 21.6122097 },
    { lat: 48.6426865, lng: 21.6121402 },
    { lat: 48.6427718, lng: 21.6122145 },
    { lat: 48.6428704, lng: 21.6121584 },
    { lat: 48.6431075, lng: 21.6122202 },
    { lat: 48.6431826, lng: 21.6120145 },
    { lat: 48.6432952, lng: 21.6118913 },
    { lat: 48.6434927, lng: 21.6120738 },
    { lat: 48.6437986, lng: 21.6120291 },
    { lat: 48.6438973, lng: 21.6119812 },
    { lat: 48.6439759, lng: 21.6118154 },
    { lat: 48.6441842, lng: 21.611778 },
    { lat: 48.6453229, lng: 21.6119011 },
    { lat: 48.6459821, lng: 21.6118906 },
    { lat: 48.6463297, lng: 21.6118073 },
    { lat: 48.6465306, lng: 21.6114291 },
    { lat: 48.6466285, lng: 21.6114297 },
    { lat: 48.646856, lng: 21.6111587 },
    { lat: 48.6469874, lng: 21.6107483 },
    { lat: 48.6471126, lng: 21.6105941 },
    { lat: 48.6474578, lng: 21.6104583 },
    { lat: 48.6476398, lng: 21.6105364 },
    { lat: 48.648033, lng: 21.6102709 },
    { lat: 48.6484987, lng: 21.6093326 },
    { lat: 48.6490507, lng: 21.6085571 },
    { lat: 48.6491097, lng: 21.6084067 },
    { lat: 48.6490635, lng: 21.6083303 },
    { lat: 48.6491727, lng: 21.6079252 },
    { lat: 48.6491935, lng: 21.6075823 },
    { lat: 48.6492894, lng: 21.6072325 },
    { lat: 48.6494006, lng: 21.6071439 },
    { lat: 48.650161, lng: 21.607139 },
    { lat: 48.6502891, lng: 21.6070889 },
    { lat: 48.6501787, lng: 21.6069197 },
    { lat: 48.6503696, lng: 21.6068311 },
    { lat: 48.6504653, lng: 21.6067129 },
    { lat: 48.6503359, lng: 21.6065436 },
    { lat: 48.6502966, lng: 21.6064301 },
    { lat: 48.6503171, lng: 21.6063174 },
    { lat: 48.6504657, lng: 21.6061176 },
    { lat: 48.6505827, lng: 21.6061183 },
    { lat: 48.6507849, lng: 21.6055838 },
    { lat: 48.6507752, lng: 21.6053965 },
    { lat: 48.6508792, lng: 21.6051686 },
    { lat: 48.6508608, lng: 21.6050345 },
    { lat: 48.6507813, lng: 21.6049666 },
    { lat: 48.6508497, lng: 21.604678 },
    { lat: 48.6506422, lng: 21.6040218 },
    { lat: 48.6506499, lng: 21.6034086 },
    { lat: 48.6508572, lng: 21.6031741 },
    { lat: 48.6509943, lng: 21.6029248 },
    { lat: 48.6511904, lng: 21.6031013 },
    { lat: 48.6516772, lng: 21.602323 },
    { lat: 48.6519923, lng: 21.602562 },
    { lat: 48.6522219, lng: 21.6023652 },
    { lat: 48.6522335, lng: 21.6021741 },
    { lat: 48.6521033, lng: 21.6020283 },
    { lat: 48.6521516, lng: 21.6017829 },
    { lat: 48.6520969, lng: 21.6016387 },
    { lat: 48.6520967, lng: 21.6014287 },
    { lat: 48.6522682, lng: 21.6014054 },
    { lat: 48.6522906, lng: 21.6012801 },
    { lat: 48.6521844, lng: 21.6009403 },
    { lat: 48.6523197, lng: 21.6007092 },
    { lat: 48.6523457, lng: 21.6009577 },
    { lat: 48.6526337, lng: 21.6007501 },
    { lat: 48.6526881, lng: 21.6004431 },
    { lat: 48.6526959, lng: 21.5997615 },
    { lat: 48.6525745, lng: 21.5996468 },
    { lat: 48.6525682, lng: 21.5993775 },
    { lat: 48.6528241, lng: 21.5993128 },
    { lat: 48.6528296, lng: 21.5992644 },
    { lat: 48.652464, lng: 21.5983827 },
    { lat: 48.6522909, lng: 21.5983913 },
    { lat: 48.6522468, lng: 21.5983069 },
    { lat: 48.6523686, lng: 21.5980746 },
    { lat: 48.6522932, lng: 21.5979915 },
    { lat: 48.6521021, lng: 21.5981225 },
    { lat: 48.6520534, lng: 21.5979933 },
    { lat: 48.6521248, lng: 21.5977631 },
    { lat: 48.6520714, lng: 21.5977074 },
    { lat: 48.6519479, lng: 21.5978381 },
    { lat: 48.6518527, lng: 21.5978125 },
    { lat: 48.6519525, lng: 21.5975976 },
    { lat: 48.651939, lng: 21.5974347 },
    { lat: 48.6518411, lng: 21.5973552 },
    { lat: 48.651957, lng: 21.5972012 },
    { lat: 48.6520214, lng: 21.5969683 },
    { lat: 48.6525035, lng: 21.5967458 },
    { lat: 48.6522609, lng: 21.5962303 },
    { lat: 48.6524881, lng: 21.5961546 },
    { lat: 48.6525423, lng: 21.5960419 },
    { lat: 48.6522844, lng: 21.5954698 },
    { lat: 48.6521237, lng: 21.5953555 },
    { lat: 48.6523099, lng: 21.5950956 },
    { lat: 48.652274, lng: 21.5950171 },
    { lat: 48.6523184, lng: 21.5948349 },
    { lat: 48.6522088, lng: 21.5947743 },
    { lat: 48.652164, lng: 21.5943814 },
    { lat: 48.6522447, lng: 21.5940877 },
    { lat: 48.6520965, lng: 21.5939836 },
    { lat: 48.6520724, lng: 21.5938439 },
    { lat: 48.652055, lng: 21.5936276 },
    { lat: 48.652174, lng: 21.5933299 },
    { lat: 48.652078, lng: 21.5932382 },
    { lat: 48.6520486, lng: 21.592997 },
    { lat: 48.6521377, lng: 21.5928423 },
    { lat: 48.651993, lng: 21.5927369 },
    { lat: 48.6521345, lng: 21.5926877 },
    { lat: 48.6521621, lng: 21.5925855 },
    { lat: 48.6521097, lng: 21.5923654 },
    { lat: 48.6521905, lng: 21.5921973 },
    { lat: 48.6521089, lng: 21.5920929 },
    { lat: 48.6521214, lng: 21.5919768 },
    { lat: 48.652286, lng: 21.5920275 },
    { lat: 48.6523405, lng: 21.5918845 },
    { lat: 48.6521997, lng: 21.5917992 },
    { lat: 48.6521555, lng: 21.5916277 },
    { lat: 48.6520825, lng: 21.5916305 },
    { lat: 48.6519438, lng: 21.5915031 },
    { lat: 48.6519158, lng: 21.5911868 },
    { lat: 48.6517907, lng: 21.5911798 },
    { lat: 48.6516723, lng: 21.5910856 },
    { lat: 48.6517441, lng: 21.590723 },
    { lat: 48.6518611, lng: 21.5907237 },
    { lat: 48.6518771, lng: 21.590648 },
    { lat: 48.6517968, lng: 21.5904311 },
    { lat: 48.6518867, lng: 21.5903926 },
    { lat: 48.6518986, lng: 21.5902537 },
    { lat: 48.6518075, lng: 21.5902172 },
    { lat: 48.6518568, lng: 21.590165 },
    { lat: 48.6518765, lng: 21.5899427 },
    { lat: 48.6517596, lng: 21.5898687 },
    { lat: 48.6517856, lng: 21.5897546 },
    { lat: 48.6519478, lng: 21.5896795 },
    { lat: 48.6518034, lng: 21.5889873 },
    { lat: 48.651844, lng: 21.5888263 },
    { lat: 48.6517477, lng: 21.5887652 },
    { lat: 48.6517568, lng: 21.5886106 },
    { lat: 48.6516994, lng: 21.5885644 },
    { lat: 48.6518557, lng: 21.5882883 },
    { lat: 48.6516512, lng: 21.5882515 },
    { lat: 48.6515431, lng: 21.5880887 },
    { lat: 48.6515666, lng: 21.5880092 },
    { lat: 48.6517842, lng: 21.5880238 },
    { lat: 48.6519574, lng: 21.5878633 },
    { lat: 48.6518745, lng: 21.5878298 },
    { lat: 48.6518524, lng: 21.587607 },
    { lat: 48.6519817, lng: 21.5874005 },
    { lat: 48.6520326, lng: 21.5871738 },
    { lat: 48.6519127, lng: 21.5868235 },
    { lat: 48.6519522, lng: 21.5867946 },
    { lat: 48.652023, lng: 21.5869897 },
    { lat: 48.6521247, lng: 21.5869676 },
    { lat: 48.6522185, lng: 21.5868142 },
    { lat: 48.6522298, lng: 21.5866957 },
    { lat: 48.6521504, lng: 21.5866385 },
    { lat: 48.6520805, lng: 21.5864331 },
    { lat: 48.6521108, lng: 21.5863468 },
    { lat: 48.6522194, lng: 21.5864509 },
    { lat: 48.6523117, lng: 21.5863318 },
    { lat: 48.652355, lng: 21.5864717 },
    { lat: 48.6524796, lng: 21.5864239 },
    { lat: 48.6525791, lng: 21.5862392 },
    { lat: 48.652553, lng: 21.5859911 },
    { lat: 48.6531688, lng: 21.5843155 },
    { lat: 48.653628, lng: 21.5849597 },
    { lat: 48.6543293, lng: 21.5866152 },
    { lat: 48.6557905, lng: 21.5849228 },
    { lat: 48.6576613, lng: 21.5836254 },
    { lat: 48.6581811, lng: 21.5834216 },
    { lat: 48.6586969, lng: 21.583117 },
    { lat: 48.6588276, lng: 21.5831261 },
    { lat: 48.659825, lng: 21.5817436 },
    { lat: 48.660501, lng: 21.5809333 },
    { lat: 48.6611919, lng: 21.5799711 },
    { lat: 48.6613579, lng: 21.5798348 },
    { lat: 48.6618281, lng: 21.5791512 },
    { lat: 48.6626153, lng: 21.5777752 },
    { lat: 48.6639942, lng: 21.5761199 },
    { lat: 48.6657267, lng: 21.5757905 },
    { lat: 48.6657435, lng: 21.5749911 },
    { lat: 48.6659488, lng: 21.5745703 },
    { lat: 48.6662029, lng: 21.5743977 },
    { lat: 48.6671506, lng: 21.5744102 },
    { lat: 48.6675766, lng: 21.5741906 },
    { lat: 48.6677427, lng: 21.5740201 },
    { lat: 48.668108, lng: 21.5734029 },
    { lat: 48.6682433, lng: 21.5730151 },
    { lat: 48.6685862, lng: 21.5731269 },
    { lat: 48.6690029, lng: 21.5729812 },
    { lat: 48.6694747, lng: 21.5732738 },
    { lat: 48.6705772, lng: 21.5737085 },
    { lat: 48.6717964, lng: 21.5735287 },
    { lat: 48.6725314, lng: 21.5730963 },
    { lat: 48.6733952, lng: 21.5728418 },
    { lat: 48.6739005, lng: 21.5725679 },
    { lat: 48.6748526, lng: 21.5716979 },
    { lat: 48.6753249, lng: 21.5703296 },
    { lat: 48.6753389, lng: 21.5698285 },
    { lat: 48.6759151, lng: 21.569235 },
    { lat: 48.6760295, lng: 21.5680662 },
    { lat: 48.6761466, lng: 21.5679473 },
    { lat: 48.6762907, lng: 21.5679896 },
    { lat: 48.6764868, lng: 21.5679279 },
    { lat: 48.6766473, lng: 21.5679524 },
    { lat: 48.6770392, lng: 21.5677264 },
    { lat: 48.6771014, lng: 21.5675732 },
    { lat: 48.677353, lng: 21.5675179 },
    { lat: 48.6777591, lng: 21.567235 },
    { lat: 48.6779582, lng: 21.5665229 },
    { lat: 48.6784296, lng: 21.5657994 },
    { lat: 48.6785848, lng: 21.5653148 },
    { lat: 48.678672, lng: 21.5652223 },
    { lat: 48.6788296, lng: 21.5648589 },
    { lat: 48.678877, lng: 21.5645927 },
    { lat: 48.6790364, lng: 21.5644927 },
    { lat: 48.6797148, lng: 21.5635002 },
    { lat: 48.6821395, lng: 21.5598366 },
    { lat: 48.6828403, lng: 21.5586719 },
    { lat: 48.6875492, lng: 21.5517588 },
    { lat: 48.687635, lng: 21.5519313 },
    { lat: 48.6878375, lng: 21.5520464 },
    { lat: 48.6882033, lng: 21.5526506 },
    { lat: 48.6887338, lng: 21.5533385 },
    { lat: 48.6892369, lng: 21.553744 },
    { lat: 48.6895687, lng: 21.5542118 },
    { lat: 48.6896993, lng: 21.5546119 },
    { lat: 48.6897057, lng: 21.5548793 },
    { lat: 48.6898928, lng: 21.5551346 },
    { lat: 48.6907436, lng: 21.5556541 },
    { lat: 48.6911914, lng: 21.555612 },
    { lat: 48.6916566, lng: 21.5549953 },
    { lat: 48.6920343, lng: 21.5547063 },
    { lat: 48.6923312, lng: 21.5545828 },
    { lat: 48.6932403, lng: 21.5547381 },
    { lat: 48.693533, lng: 21.5545824 },
    { lat: 48.6936154, lng: 21.5544268 },
  ],
  Hriadky: [
    { lat: 48.6902337, lng: 21.7026637 },
    { lat: 48.6902145, lng: 21.7027478 },
    { lat: 48.690156, lng: 21.704038 },
    { lat: 48.690332, lng: 21.70501 },
    { lat: 48.6905852, lng: 21.7068846 },
    { lat: 48.6905808, lng: 21.7070982 },
    { lat: 48.6904374, lng: 21.7071662 },
    { lat: 48.6905903, lng: 21.7097036 },
    { lat: 48.6909434, lng: 21.7131165 },
    { lat: 48.6908399, lng: 21.7131603 },
    { lat: 48.6912799, lng: 21.7162965 },
    { lat: 48.6914953, lng: 21.7176935 },
    { lat: 48.6918831, lng: 21.7193487 },
    { lat: 48.6922667, lng: 21.720015 },
    { lat: 48.6922916, lng: 21.7201506 },
    { lat: 48.6926125, lng: 21.7204938 },
    { lat: 48.6929483, lng: 21.7210925 },
    { lat: 48.6929733, lng: 21.721269 },
    { lat: 48.6928965, lng: 21.7214282 },
    { lat: 48.6931327, lng: 21.7216833 },
    { lat: 48.6936675, lng: 21.7219856 },
    { lat: 48.6937696, lng: 21.7221443 },
    { lat: 48.6939474, lng: 21.7222324 },
    { lat: 48.6942112, lng: 21.7222062 },
    { lat: 48.6943625, lng: 21.7220627 },
    { lat: 48.6947092, lng: 21.7220663 },
    { lat: 48.6951998, lng: 21.7218133 },
    { lat: 48.6957289, lng: 21.7217894 },
    { lat: 48.6958789, lng: 21.7218881 },
    { lat: 48.6959432, lng: 21.7220601 },
    { lat: 48.6962143, lng: 21.7221389 },
    { lat: 48.6971862, lng: 21.7230979 },
    { lat: 48.6974741, lng: 21.7233021 },
    { lat: 48.6978421, lng: 21.7235103 },
    { lat: 48.6981266, lng: 21.7234637 },
    { lat: 48.6984911, lng: 21.7236736 },
    { lat: 48.6986948, lng: 21.7236742 },
    { lat: 48.6989381, lng: 21.7237715 },
    { lat: 48.6995079, lng: 21.7234972 },
    { lat: 48.7001955, lng: 21.7235915 },
    { lat: 48.7009635, lng: 21.7232801 },
    { lat: 48.7012884, lng: 21.72293 },
    { lat: 48.7013766, lng: 21.7229054 },
    { lat: 48.701495, lng: 21.7230032 },
    { lat: 48.7022637, lng: 21.7227174 },
    { lat: 48.7024925, lng: 21.7227732 },
    { lat: 48.7026002, lng: 21.7227199 },
    { lat: 48.7028552, lng: 21.722419 },
    { lat: 48.7035142, lng: 21.7225239 },
    { lat: 48.7037209, lng: 21.7228916 },
    { lat: 48.7038204, lng: 21.7229518 },
    { lat: 48.7038306, lng: 21.7233764 },
    { lat: 48.7043798, lng: 21.723259 },
    { lat: 48.7047286, lng: 21.7228401 },
    { lat: 48.7049509, lng: 21.7227332 },
    { lat: 48.7056896, lng: 21.7229283 },
    { lat: 48.7057121, lng: 21.7230904 },
    { lat: 48.705814, lng: 21.7229969 },
    { lat: 48.7060588, lng: 21.7229723 },
    { lat: 48.7066963, lng: 21.7231661 },
    { lat: 48.7074591, lng: 21.7227923 },
    { lat: 48.7078243, lng: 21.7223553 },
    { lat: 48.7086557, lng: 21.7206396 },
    { lat: 48.7090347, lng: 21.7205476 },
    { lat: 48.709135, lng: 21.7204303 },
    { lat: 48.7092079, lng: 21.7203011 },
    { lat: 48.709124, lng: 21.7199422 },
    { lat: 48.7089382, lng: 21.7196448 },
    { lat: 48.7087537, lng: 21.719514 },
    { lat: 48.7085744, lng: 21.7191688 },
    { lat: 48.7085679, lng: 21.7189625 },
    { lat: 48.7086574, lng: 21.7187401 },
    { lat: 48.7092762, lng: 21.7183159 },
    { lat: 48.7093204, lng: 21.7181205 },
    { lat: 48.7092811, lng: 21.7175789 },
    { lat: 48.7090095, lng: 21.717151 },
    { lat: 48.7089568, lng: 21.7166901 },
    { lat: 48.7091594, lng: 21.7164921 },
    { lat: 48.7096532, lng: 21.7169405 },
    { lat: 48.709864, lng: 21.7169621 },
    { lat: 48.7099917, lng: 21.716728 },
    { lat: 48.71006, lng: 21.7164207 },
    { lat: 48.7100485, lng: 21.7162048 },
    { lat: 48.7094919, lng: 21.7160121 },
    { lat: 48.7094414, lng: 21.7158442 },
    { lat: 48.7094813, lng: 21.7156533 },
    { lat: 48.7096942, lng: 21.7157068 },
    { lat: 48.7097548, lng: 21.7155324 },
    { lat: 48.7097154, lng: 21.7152424 },
    { lat: 48.7097938, lng: 21.7150185 },
    { lat: 48.7098985, lng: 21.7148977 },
    { lat: 48.7101544, lng: 21.714831 },
    { lat: 48.7103834, lng: 21.7150186 },
    { lat: 48.7108235, lng: 21.7145241 },
    { lat: 48.7109283, lng: 21.7142829 },
    { lat: 48.7108908, lng: 21.7140561 },
    { lat: 48.7103848, lng: 21.7141948 },
    { lat: 48.7096727, lng: 21.7131137 },
    { lat: 48.7095265, lng: 21.7127789 },
    { lat: 48.7088105, lng: 21.7105149 },
    { lat: 48.7084531, lng: 21.7090219 },
    { lat: 48.7082362, lng: 21.7083662 },
    { lat: 48.7073537, lng: 21.706979 },
    { lat: 48.7071334, lng: 21.7059453 },
    { lat: 48.7069112, lng: 21.7057563 },
    { lat: 48.7067109, lng: 21.7047135 },
    { lat: 48.7063214, lng: 21.7035011 },
    { lat: 48.7061532, lng: 21.7035564 },
    { lat: 48.7055029, lng: 21.7016876 },
    { lat: 48.7052404, lng: 21.7011801 },
    { lat: 48.7049787, lng: 21.700859 },
    { lat: 48.7047256, lng: 21.7004094 },
    { lat: 48.7032539, lng: 21.7007358 },
    { lat: 48.7032963, lng: 21.7009697 },
    { lat: 48.7021742, lng: 21.701247 },
    { lat: 48.7021604, lng: 21.7011103 },
    { lat: 48.701983, lng: 21.7011227 },
    { lat: 48.7019463, lng: 21.7009782 },
    { lat: 48.7015751, lng: 21.7010176 },
    { lat: 48.7015004, lng: 21.7000106 },
    { lat: 48.7013444, lng: 21.6995266 },
    { lat: 48.7012517, lng: 21.6990101 },
    { lat: 48.7011513, lng: 21.6967571 },
    { lat: 48.7010854, lng: 21.6967596 },
    { lat: 48.7009928, lng: 21.696763 },
    { lat: 48.7004843, lng: 21.6969657 },
    { lat: 48.7003094, lng: 21.695825 },
    { lat: 48.699972, lng: 21.6958324 },
    { lat: 48.6996463, lng: 21.6959352 },
    { lat: 48.6996033, lng: 21.6958018 },
    { lat: 48.6996087, lng: 21.695546 },
    { lat: 48.6992225, lng: 21.6936676 },
    { lat: 48.6990179, lng: 21.6935923 },
    { lat: 48.6991485, lng: 21.6931376 },
    { lat: 48.6992187, lng: 21.6927383 },
    { lat: 48.6991592, lng: 21.6927437 },
    { lat: 48.6991938, lng: 21.6926957 },
    { lat: 48.6974534, lng: 21.6931938 },
    { lat: 48.6974999, lng: 21.6951163 },
    { lat: 48.6948941, lng: 21.6959883 },
    { lat: 48.6955011, lng: 21.7006601 },
    { lat: 48.6955604, lng: 21.7014827 },
    { lat: 48.6956734, lng: 21.7022873 },
    { lat: 48.6929715, lng: 21.7031827 },
    { lat: 48.6929092, lng: 21.7025883 },
    { lat: 48.6927355, lng: 21.7026521 },
    { lat: 48.6927279, lng: 21.7025792 },
    { lat: 48.6920847, lng: 21.7027627 },
    { lat: 48.692014, lng: 21.7021802 },
    { lat: 48.6902337, lng: 21.7026637 },
  ],
  Kašov: [
    { lat: 48.4827987, lng: 21.7104617 },
    { lat: 48.4819698, lng: 21.7107267 },
    { lat: 48.4809601, lng: 21.7108691 },
    { lat: 48.4804424, lng: 21.711325 },
    { lat: 48.4800389, lng: 21.7113387 },
    { lat: 48.4794648, lng: 21.7114931 },
    { lat: 48.4791977, lng: 21.7116424 },
    { lat: 48.4787431, lng: 21.7117309 },
    { lat: 48.478482, lng: 21.7116835 },
    { lat: 48.4780431, lng: 21.7114692 },
    { lat: 48.4766634, lng: 21.7107141 },
    { lat: 48.4765079, lng: 21.7104133 },
    { lat: 48.4762168, lng: 21.7100961 },
    { lat: 48.475558, lng: 21.7088227 },
    { lat: 48.4753242, lng: 21.7086682 },
    { lat: 48.4747034, lng: 21.7084347 },
    { lat: 48.4741743, lng: 21.7084586 },
    { lat: 48.4739206, lng: 21.7085632 },
    { lat: 48.4731378, lng: 21.7089101 },
    { lat: 48.4723595, lng: 21.7094578 },
    { lat: 48.4723251, lng: 21.7107215 },
    { lat: 48.4721775, lng: 21.7112164 },
    { lat: 48.4717295, lng: 21.7118331 },
    { lat: 48.4717858, lng: 21.7122484 },
    { lat: 48.4717614, lng: 21.7127137 },
    { lat: 48.4718732, lng: 21.7132749 },
    { lat: 48.471878, lng: 21.7137366 },
    { lat: 48.4721864, lng: 21.7158189 },
    { lat: 48.4724223, lng: 21.7168884 },
    { lat: 48.4726025, lng: 21.7172749 },
    { lat: 48.4727985, lng: 21.7181125 },
    { lat: 48.4728165, lng: 21.7184035 },
    { lat: 48.4725247, lng: 21.7189735 },
    { lat: 48.4723861, lng: 21.7196741 },
    { lat: 48.4723624, lng: 21.7202893 },
    { lat: 48.4722451, lng: 21.7206138 },
    { lat: 48.4719204, lng: 21.7209187 },
    { lat: 48.4717732, lng: 21.7214333 },
    { lat: 48.471642, lng: 21.7229015 },
    { lat: 48.4717872, lng: 21.723155 },
    { lat: 48.4719067, lng: 21.7235026 },
    { lat: 48.4718869, lng: 21.7239754 },
    { lat: 48.4719534, lng: 21.724727 },
    { lat: 48.4720898, lng: 21.7251168 },
    { lat: 48.4724918, lng: 21.7255437 },
    { lat: 48.4726365, lng: 21.7258653 },
    { lat: 48.4726798, lng: 21.7261775 },
    { lat: 48.4727592, lng: 21.7263663 },
    { lat: 48.4731585, lng: 21.7270266 },
    { lat: 48.4736185, lng: 21.7273964 },
    { lat: 48.4739839, lng: 21.7278503 },
    { lat: 48.4741932, lng: 21.7282237 },
    { lat: 48.474432, lng: 21.7291921 },
    { lat: 48.4745706, lng: 21.72949 },
    { lat: 48.4748356, lng: 21.7305135 },
    { lat: 48.4749291, lng: 21.7315262 },
    { lat: 48.4748169, lng: 21.7323745 },
    { lat: 48.4748113, lng: 21.7329553 },
    { lat: 48.4747475, lng: 21.7333 },
    { lat: 48.4748329, lng: 21.7337571 },
    { lat: 48.474672, lng: 21.7354392 },
    { lat: 48.4745958, lng: 21.7357356 },
    { lat: 48.4744232, lng: 21.7369805 },
    { lat: 48.4745065, lng: 21.7374832 },
    { lat: 48.4746681, lng: 21.7380134 },
    { lat: 48.4747002, lng: 21.7387873 },
    { lat: 48.4747487, lng: 21.739002 },
    { lat: 48.4748736, lng: 21.7392569 },
    { lat: 48.474944, lng: 21.740032 },
    { lat: 48.4748701, lng: 21.740424 },
    { lat: 48.47469, lng: 21.7407498 },
    { lat: 48.4744222, lng: 21.7416756 },
    { lat: 48.474555, lng: 21.7425558 },
    { lat: 48.4745694, lng: 21.7433031 },
    { lat: 48.4749722, lng: 21.7443545 },
    { lat: 48.4750303, lng: 21.7446307 },
    { lat: 48.4750531, lng: 21.7459456 },
    { lat: 48.4753298, lng: 21.7478266 },
    { lat: 48.475452, lng: 21.7482366 },
    { lat: 48.4763409, lng: 21.749205 },
    { lat: 48.4765319, lng: 21.7496552 },
    { lat: 48.4767104, lng: 21.7497614 },
    { lat: 48.4770255, lng: 21.7497678 },
    { lat: 48.478396, lng: 21.7489295 },
    { lat: 48.4790623, lng: 21.7486041 },
    { lat: 48.479405, lng: 21.7483368 },
    { lat: 48.4800649, lng: 21.7483157 },
    { lat: 48.4808114, lng: 21.7483807 },
    { lat: 48.4809683, lng: 21.7483925 },
    { lat: 48.4819879, lng: 21.748152 },
    { lat: 48.4825848, lng: 21.7483882 },
    { lat: 48.482969, lng: 21.7491136 },
    { lat: 48.4831853, lng: 21.7499736 },
    { lat: 48.48313, lng: 21.7508828 },
    { lat: 48.4832334, lng: 21.7514723 },
    { lat: 48.4862757, lng: 21.7524391 },
    { lat: 48.4890648, lng: 21.752563 },
    { lat: 48.4900426, lng: 21.7527744 },
    { lat: 48.4939492, lng: 21.7542202 },
    { lat: 48.4940111, lng: 21.7541703 },
    { lat: 48.4943749, lng: 21.7548592 },
    { lat: 48.4945963, lng: 21.7546386 },
    { lat: 48.4949307, lng: 21.7544857 },
    { lat: 48.4954093, lng: 21.7544568 },
    { lat: 48.4958506, lng: 21.7542187 },
    { lat: 48.4956727, lng: 21.7537882 },
    { lat: 48.4960717, lng: 21.7533493 },
    { lat: 48.4960225, lng: 21.7532402 },
    { lat: 48.496433, lng: 21.7527642 },
    { lat: 48.4972456, lng: 21.7519296 },
    { lat: 48.4981088, lng: 21.7511487 },
    { lat: 48.4994691, lng: 21.7500954 },
    { lat: 48.4999344, lng: 21.7498192 },
    { lat: 48.5000478, lng: 21.7503555 },
    { lat: 48.5003804, lng: 21.7500888 },
    { lat: 48.5004461, lng: 21.7503583 },
    { lat: 48.5006512, lng: 21.7502011 },
    { lat: 48.5009197, lng: 21.7515745 },
    { lat: 48.5027977, lng: 21.7509503 },
    { lat: 48.5030492, lng: 21.7522801 },
    { lat: 48.5035988, lng: 21.7520449 },
    { lat: 48.5063229, lng: 21.7513414 },
    { lat: 48.506636, lng: 21.7480263 },
    { lat: 48.5073337, lng: 21.7408564 },
    { lat: 48.5082523, lng: 21.7306956 },
    { lat: 48.5075525, lng: 21.7308156 },
    { lat: 48.5052651, lng: 21.7309408 },
    { lat: 48.5051326, lng: 21.7302847 },
    { lat: 48.5040741, lng: 21.7303404 },
    { lat: 48.5029793, lng: 21.7297908 },
    { lat: 48.5017806, lng: 21.729772 },
    { lat: 48.5013339, lng: 21.7296889 },
    { lat: 48.5012093, lng: 21.7295794 },
    { lat: 48.5006408, lng: 21.7286835 },
    { lat: 48.4997122, lng: 21.7279168 },
    { lat: 48.4993734, lng: 21.7275429 },
    { lat: 48.4986164, lng: 21.7263365 },
    { lat: 48.4983067, lng: 21.7260765 },
    { lat: 48.4967271, lng: 21.7250402 },
    { lat: 48.4962161, lng: 21.7245719 },
    { lat: 48.4943874, lng: 21.7237251 },
    { lat: 48.4935762, lng: 21.7224757 },
    { lat: 48.4927562, lng: 21.7216925 },
    { lat: 48.4922602, lng: 21.7210737 },
    { lat: 48.4919122, lng: 21.719895 },
    { lat: 48.4916142, lng: 21.7191892 },
    { lat: 48.4907807, lng: 21.7179044 },
    { lat: 48.489063, lng: 21.7155905 },
    { lat: 48.4887696, lng: 21.7153534 },
    { lat: 48.4884044, lng: 21.7152477 },
    { lat: 48.4875812, lng: 21.7147647 },
    { lat: 48.4872663, lng: 21.7144385 },
    { lat: 48.4866852, lng: 21.7141684 },
    { lat: 48.4862582, lng: 21.7142644 },
    { lat: 48.4859125, lng: 21.7141778 },
    { lat: 48.4856662, lng: 21.7139701 },
    { lat: 48.484835, lng: 21.7126527 },
    { lat: 48.4845113, lng: 21.7123747 },
    { lat: 48.4838494, lng: 21.7116112 },
    { lat: 48.4835144, lng: 21.7109175 },
    { lat: 48.4827987, lng: 21.7104617 },
  ],
  Ladmovce: [
    { lat: 48.3933081, lng: 21.7969157 },
    { lat: 48.3942049, lng: 21.7986169 },
    { lat: 48.3948503, lng: 21.8002233 },
    { lat: 48.3955804, lng: 21.8022254 },
    { lat: 48.3967633, lng: 21.8046327 },
    { lat: 48.3978804, lng: 21.8063362 },
    { lat: 48.398325, lng: 21.8067751 },
    { lat: 48.3983852, lng: 21.8067513 },
    { lat: 48.3993212, lng: 21.8074775 },
    { lat: 48.4000893, lng: 21.8075474 },
    { lat: 48.4005156, lng: 21.8074127 },
    { lat: 48.4009656, lng: 21.8069013 },
    { lat: 48.4010355, lng: 21.8067329 },
    { lat: 48.4010336, lng: 21.8060638 },
    { lat: 48.4007231, lng: 21.8048721 },
    { lat: 48.3999461, lng: 21.8033062 },
    { lat: 48.3989905, lng: 21.8023917 },
    { lat: 48.3981149, lng: 21.8014018 },
    { lat: 48.3975296, lng: 21.8006255 },
    { lat: 48.3969677, lng: 21.7995419 },
    { lat: 48.3968079, lng: 21.7988561 },
    { lat: 48.3966748, lng: 21.7976879 },
    { lat: 48.3967008, lng: 21.7967966 },
    { lat: 48.3971476, lng: 21.7938259 },
    { lat: 48.3977313, lng: 21.7921126 },
    { lat: 48.3980439, lng: 21.7915929 },
    { lat: 48.3993821, lng: 21.7901996 },
    { lat: 48.4006008, lng: 21.7894089 },
    { lat: 48.4025216, lng: 21.7873337 },
    { lat: 48.4032873, lng: 21.7868397 },
    { lat: 48.4046087, lng: 21.7861769 },
    { lat: 48.4051155, lng: 21.7856923 },
    { lat: 48.4071953, lng: 21.7840149 },
    { lat: 48.4079803, lng: 21.7835739 },
    { lat: 48.4080669, lng: 21.7834883 },
    { lat: 48.4083815, lng: 21.7831802 },
    { lat: 48.4098416, lng: 21.7826527 },
    { lat: 48.4108988, lng: 21.7826815 },
    { lat: 48.411906, lng: 21.7829471 },
    { lat: 48.4125387, lng: 21.7829974 },
    { lat: 48.4131846, lng: 21.7831709 },
    { lat: 48.4138603, lng: 21.7832421 },
    { lat: 48.416056, lng: 21.7825823 },
    { lat: 48.4164181, lng: 21.782833 },
    { lat: 48.4171772, lng: 21.7832009 },
    { lat: 48.4180842, lng: 21.7841026 },
    { lat: 48.4185072, lng: 21.7846897 },
    { lat: 48.4187998, lng: 21.7853764 },
    { lat: 48.4189739, lng: 21.7863846 },
    { lat: 48.4189027, lng: 21.7875609 },
    { lat: 48.4185471, lng: 21.7884928 },
    { lat: 48.4191095, lng: 21.7887046 },
    { lat: 48.4195078, lng: 21.7886175 },
    { lat: 48.4201431, lng: 21.7889808 },
    { lat: 48.4203289, lng: 21.7889875 },
    { lat: 48.4203899, lng: 21.7887465 },
    { lat: 48.4207821, lng: 21.7887234 },
    { lat: 48.4209846, lng: 21.788552 },
    { lat: 48.4210933, lng: 21.7883206 },
    { lat: 48.4212924, lng: 21.7882385 },
    { lat: 48.4213881, lng: 21.7879866 },
    { lat: 48.4217727, lng: 21.7876768 },
    { lat: 48.4219061, lng: 21.7874892 },
    { lat: 48.4221012, lng: 21.7875537 },
    { lat: 48.4222196, lng: 21.7874772 },
    { lat: 48.4223668, lng: 21.7875169 },
    { lat: 48.4226064, lng: 21.7873397 },
    { lat: 48.4228563, lng: 21.7873496 },
    { lat: 48.4230598, lng: 21.7871706 },
    { lat: 48.4242998, lng: 21.7869608 },
    { lat: 48.42475, lng: 21.786715 },
    { lat: 48.4250898, lng: 21.7867556 },
    { lat: 48.4253761, lng: 21.7866036 },
    { lat: 48.4255166, lng: 21.7866837 },
    { lat: 48.425856, lng: 21.7867162 },
    { lat: 48.4260652, lng: 21.7865429 },
    { lat: 48.4262365, lng: 21.7862524 },
    { lat: 48.4263949, lng: 21.7861987 },
    { lat: 48.4266267, lng: 21.7859064 },
    { lat: 48.4274852, lng: 21.7845918 },
    { lat: 48.4288562, lng: 21.7833794 },
    { lat: 48.4291215, lng: 21.7833734 },
    { lat: 48.4291051, lng: 21.7827987 },
    { lat: 48.428965, lng: 21.7823519 },
    { lat: 48.4293146, lng: 21.7814896 },
    { lat: 48.4294791, lng: 21.7812013 },
    { lat: 48.4295899, lng: 21.7807129 },
    { lat: 48.4298431, lng: 21.7802046 },
    { lat: 48.4299912, lng: 21.779423 },
    { lat: 48.4299777, lng: 21.7792538 },
    { lat: 48.4301232, lng: 21.7786325 },
    { lat: 48.4305113, lng: 21.7773176 },
    { lat: 48.430712, lng: 21.7770367 },
    { lat: 48.4321964, lng: 21.7756946 },
    { lat: 48.4322642, lng: 21.7757008 },
    { lat: 48.4324219, lng: 21.7754596 },
    { lat: 48.4328763, lng: 21.7752389 },
    { lat: 48.433013, lng: 21.7750865 },
    { lat: 48.4334697, lng: 21.7750432 },
    { lat: 48.43366, lng: 21.7749637 },
    { lat: 48.4345492, lng: 21.7752349 },
    { lat: 48.4352745, lng: 21.7751308 },
    { lat: 48.4356104, lng: 21.7746497 },
    { lat: 48.4365587, lng: 21.7735865 },
    { lat: 48.4368415, lng: 21.7728375 },
    { lat: 48.4383955, lng: 21.7711898 },
    { lat: 48.4386267, lng: 21.7707858 },
    { lat: 48.438849, lng: 21.7700138 },
    { lat: 48.4385914, lng: 21.768151 },
    { lat: 48.4388812, lng: 21.7667438 },
    { lat: 48.4388806, lng: 21.7660827 },
    { lat: 48.4383948, lng: 21.7647414 },
    { lat: 48.4380982, lng: 21.7645055 },
    { lat: 48.4378855, lng: 21.7636963 },
    { lat: 48.4376751, lng: 21.7632781 },
    { lat: 48.4376261, lng: 21.763084 },
    { lat: 48.437659, lng: 21.7624641 },
    { lat: 48.4376255, lng: 21.7619229 },
    { lat: 48.4374156, lng: 21.7614416 },
    { lat: 48.4370718, lng: 21.760956 },
    { lat: 48.4365181, lng: 21.7599339 },
    { lat: 48.4355155, lng: 21.7589619 },
    { lat: 48.434632, lng: 21.7576078 },
    { lat: 48.4343082, lng: 21.756855 },
    { lat: 48.4340025, lng: 21.7559831 },
    { lat: 48.4338571, lng: 21.7552985 },
    { lat: 48.4335096, lng: 21.7541719 },
    { lat: 48.4336706, lng: 21.7535889 },
    { lat: 48.4337147, lng: 21.7531756 },
    { lat: 48.4335809, lng: 21.7519985 },
    { lat: 48.4334231, lng: 21.7515656 },
    { lat: 48.4332316, lng: 21.7513054 },
    { lat: 48.4328575, lng: 21.7511608 },
    { lat: 48.4325883, lng: 21.7514003 },
    { lat: 48.4323772, lng: 21.7515012 },
    { lat: 48.4321726, lng: 21.7515033 },
    { lat: 48.4317656, lng: 21.7517309 },
    { lat: 48.4316618, lng: 21.7517188 },
    { lat: 48.4314532, lng: 21.7517027 },
    { lat: 48.431458, lng: 21.7519611 },
    { lat: 48.4310984, lng: 21.7521114 },
    { lat: 48.4301759, lng: 21.7523234 },
    { lat: 48.429665, lng: 21.7521924 },
    { lat: 48.4290104, lng: 21.7523641 },
    { lat: 48.4289076, lng: 21.7522257 },
    { lat: 48.4286381, lng: 21.752038 },
    { lat: 48.4281402, lng: 21.7519352 },
    { lat: 48.4279083, lng: 21.7516297 },
    { lat: 48.4275756, lng: 21.7513483 },
    { lat: 48.4271321, lng: 21.751136 },
    { lat: 48.4269552, lng: 21.7508707 },
    { lat: 48.4266595, lng: 21.7508766 },
    { lat: 48.426457, lng: 21.7507552 },
    { lat: 48.4262025, lng: 21.7507859 },
    { lat: 48.4254444, lng: 21.7512589 },
    { lat: 48.4233454, lng: 21.7517446 },
    { lat: 48.4233333, lng: 21.7518389 },
    { lat: 48.423102, lng: 21.7517664 },
    { lat: 48.4230368, lng: 21.7518504 },
    { lat: 48.42279, lng: 21.7518444 },
    { lat: 48.4223688, lng: 21.7520792 },
    { lat: 48.4221198, lng: 21.7518587 },
    { lat: 48.421865, lng: 21.7519999 },
    { lat: 48.4214802, lng: 21.7520872 },
    { lat: 48.4210174, lng: 21.7518251 },
    { lat: 48.4205626, lng: 21.7520609 },
    { lat: 48.4203626, lng: 21.7522872 },
    { lat: 48.420102, lng: 21.7523033 },
    { lat: 48.4195157, lng: 21.752552 },
    { lat: 48.4193983, lng: 21.752411 },
    { lat: 48.4190141, lng: 21.7526457 },
    { lat: 48.4188728, lng: 21.7526181 },
    { lat: 48.4182663, lng: 21.7530808 },
    { lat: 48.4179902, lng: 21.753151 },
    { lat: 48.4177614, lng: 21.7535087 },
    { lat: 48.4174987, lng: 21.7537449 },
    { lat: 48.4174131, lng: 21.7536555 },
    { lat: 48.4167255, lng: 21.7535618 },
    { lat: 48.4166253, lng: 21.753809 },
    { lat: 48.416548, lng: 21.7537425 },
    { lat: 48.4165142, lng: 21.7539932 },
    { lat: 48.4163035, lng: 21.7542343 },
    { lat: 48.4161996, lng: 21.754554 },
    { lat: 48.4162523, lng: 21.7547191 },
    { lat: 48.4161221, lng: 21.7547673 },
    { lat: 48.4160457, lng: 21.7549884 },
    { lat: 48.4157774, lng: 21.7552167 },
    { lat: 48.415619, lng: 21.7555185 },
    { lat: 48.4154259, lng: 21.7557033 },
    { lat: 48.4152393, lng: 21.7560535 },
    { lat: 48.4149858, lng: 21.756333 },
    { lat: 48.4148205, lng: 21.7563766 },
    { lat: 48.4145192, lng: 21.7563413 },
    { lat: 48.4143586, lng: 21.7562256 },
    { lat: 48.4138317, lng: 21.7563307 },
    { lat: 48.413728, lng: 21.7560002 },
    { lat: 48.4136364, lng: 21.7560206 },
    { lat: 48.4134689, lng: 21.7557343 },
    { lat: 48.4132193, lng: 21.7558287 },
    { lat: 48.4127184, lng: 21.7556124 },
    { lat: 48.4126282, lng: 21.7556405 },
    { lat: 48.4123366, lng: 21.7554676 },
    { lat: 48.4121292, lng: 21.7556621 },
    { lat: 48.4120732, lng: 21.755589 },
    { lat: 48.4117015, lng: 21.7554425 },
    { lat: 48.4116601, lng: 21.7552453 },
    { lat: 48.411502, lng: 21.7552644 },
    { lat: 48.4114401, lng: 21.7554363 },
    { lat: 48.4113571, lng: 21.7554445 },
    { lat: 48.411128, lng: 21.7552901 },
    { lat: 48.4110196, lng: 21.7553172 },
    { lat: 48.4107201, lng: 21.7551016 },
    { lat: 48.41046, lng: 21.7550482 },
    { lat: 48.4103071, lng: 21.7550542 },
    { lat: 48.4101644, lng: 21.7551928 },
    { lat: 48.4100108, lng: 21.7550371 },
    { lat: 48.4099508, lng: 21.7551129 },
    { lat: 48.4095657, lng: 21.7551454 },
    { lat: 48.4090511, lng: 21.7550433 },
    { lat: 48.4089518, lng: 21.754943 },
    { lat: 48.4088486, lng: 21.7550442 },
    { lat: 48.4087791, lng: 21.7549655 },
    { lat: 48.4086996, lng: 21.7549834 },
    { lat: 48.4086333, lng: 21.7551138 },
    { lat: 48.4085148, lng: 21.7550548 },
    { lat: 48.4082116, lng: 21.7551244 },
    { lat: 48.4080419, lng: 21.7550822 },
    { lat: 48.4079911, lng: 21.7552461 },
    { lat: 48.4078246, lng: 21.7553333 },
    { lat: 48.4077791, lng: 21.7552288 },
    { lat: 48.4076269, lng: 21.7552227 },
    { lat: 48.4073525, lng: 21.7554783 },
    { lat: 48.4072995, lng: 21.7553855 },
    { lat: 48.4071, lng: 21.7553317 },
    { lat: 48.4067529, lng: 21.7559465 },
    { lat: 48.4066107, lng: 21.7558836 },
    { lat: 48.4064347, lng: 21.7560376 },
    { lat: 48.4061676, lng: 21.7561022 },
    { lat: 48.4059072, lng: 21.756289 },
    { lat: 48.405763, lng: 21.756286 },
    { lat: 48.4056975, lng: 21.7564464 },
    { lat: 48.4055614, lng: 21.7565308 },
    { lat: 48.4052757, lng: 21.7569552 },
    { lat: 48.4051133, lng: 21.7568985 },
    { lat: 48.4049687, lng: 21.7567514 },
    { lat: 48.4047781, lng: 21.7569445 },
    { lat: 48.4045187, lng: 21.757002 },
    { lat: 48.4044668, lng: 21.7570887 },
    { lat: 48.4042681, lng: 21.7571024 },
    { lat: 48.4042253, lng: 21.7572268 },
    { lat: 48.4041095, lng: 21.7572278 },
    { lat: 48.4038116, lng: 21.7575322 },
    { lat: 48.403545, lng: 21.7576665 },
    { lat: 48.4034223, lng: 21.7577865 },
    { lat: 48.4033274, lng: 21.7579856 },
    { lat: 48.402894, lng: 21.758352 },
    { lat: 48.402214, lng: 21.7585109 },
    { lat: 48.4017355, lng: 21.7587433 },
    { lat: 48.4015467, lng: 21.7590564 },
    { lat: 48.4015594, lng: 21.7591516 },
    { lat: 48.4011277, lng: 21.7597501 },
    { lat: 48.4008934, lng: 21.7599115 },
    { lat: 48.4002743, lng: 21.7600267 },
    { lat: 48.3996642, lng: 21.7600136 },
    { lat: 48.3993232, lng: 21.7599226 },
    { lat: 48.3990447, lng: 21.7592327 },
    { lat: 48.3986964, lng: 21.7589102 },
    { lat: 48.3984996, lng: 21.7588718 },
    { lat: 48.398104, lng: 21.7585965 },
    { lat: 48.3976825, lng: 21.757849 },
    { lat: 48.397555, lng: 21.7577101 },
    { lat: 48.3973336, lng: 21.7574745 },
    { lat: 48.3971029, lng: 21.7575143 },
    { lat: 48.3968806, lng: 21.757196 },
    { lat: 48.3968133, lng: 21.7572025 },
    { lat: 48.3965093, lng: 21.7572789 },
    { lat: 48.3966683, lng: 21.7582613 },
    { lat: 48.3967971, lng: 21.7594177 },
    { lat: 48.3967786, lng: 21.7609153 },
    { lat: 48.3967441, lng: 21.761811 },
    { lat: 48.3965976, lng: 21.7633206 },
    { lat: 48.3963269, lng: 21.7651899 },
    { lat: 48.3961407, lng: 21.7661036 },
    { lat: 48.3954743, lng: 21.768452 },
    { lat: 48.3952772, lng: 21.7689924 },
    { lat: 48.3946812, lng: 21.7698969 },
    { lat: 48.3939771, lng: 21.7707089 },
    { lat: 48.3932946, lng: 21.7709339 },
    { lat: 48.3925822, lng: 21.771016 },
    { lat: 48.3922935, lng: 21.7712847 },
    { lat: 48.3918845, lng: 21.7721486 },
    { lat: 48.3913499, lng: 21.774083 },
    { lat: 48.3910703, lng: 21.7755916 },
    { lat: 48.3910399, lng: 21.7763474 },
    { lat: 48.3908753, lng: 21.7779867 },
    { lat: 48.3907683, lng: 21.7807405 },
    { lat: 48.3904362, lng: 21.786544 },
    { lat: 48.390481, lng: 21.7890922 },
    { lat: 48.3905791, lng: 21.7904545 },
    { lat: 48.3909859, lng: 21.7920983 },
    { lat: 48.3917375, lng: 21.7939726 },
    { lat: 48.3928009, lng: 21.7960641 },
    { lat: 48.3933081, lng: 21.7969157 },
  ],
  Trnávka: [
    { lat: 48.7195158, lng: 21.5803484 },
    { lat: 48.7194565, lng: 21.5804431 },
    { lat: 48.7192694, lng: 21.5808346 },
    { lat: 48.7185169, lng: 21.5813988 },
    { lat: 48.7182775, lng: 21.5814484 },
    { lat: 48.716928, lng: 21.5813604 },
    { lat: 48.7164657, lng: 21.5814018 },
    { lat: 48.7156063, lng: 21.5818898 },
    { lat: 48.7156027, lng: 21.5816014 },
    { lat: 48.7157949, lng: 21.5806313 },
    { lat: 48.7157938, lng: 21.5803578 },
    { lat: 48.7154317, lng: 21.5802396 },
    { lat: 48.7147567, lng: 21.5796807 },
    { lat: 48.7138558, lng: 21.5804169 },
    { lat: 48.7128846, lng: 21.5808102 },
    { lat: 48.7120692, lng: 21.5814646 },
    { lat: 48.7120072, lng: 21.5815812 },
    { lat: 48.7106152, lng: 21.5819341 },
    { lat: 48.7101365, lng: 21.5821593 },
    { lat: 48.7096426, lng: 21.5825209 },
    { lat: 48.709237, lng: 21.5829826 },
    { lat: 48.7086931, lng: 21.58301 },
    { lat: 48.7082706, lng: 21.5832619 },
    { lat: 48.7076583, lng: 21.5836983 },
    { lat: 48.7071222, lng: 21.5842389 },
    { lat: 48.705884, lng: 21.5851059 },
    { lat: 48.7047979, lng: 21.5861807 },
    { lat: 48.7034207, lng: 21.587018 },
    { lat: 48.7031941, lng: 21.5870425 },
    { lat: 48.7024591, lng: 21.5868318 },
    { lat: 48.7023968, lng: 21.5870981 },
    { lat: 48.7021, lng: 21.5881915 },
    { lat: 48.702012, lng: 21.5882252 },
    { lat: 48.7018708, lng: 21.5888042 },
    { lat: 48.7019181, lng: 21.5894512 },
    { lat: 48.7018295, lng: 21.589577 },
    { lat: 48.7018219, lng: 21.5898279 },
    { lat: 48.7016671, lng: 21.590085 },
    { lat: 48.7017107, lng: 21.5901591 },
    { lat: 48.701583, lng: 21.5902228 },
    { lat: 48.7014485, lng: 21.5903939 },
    { lat: 48.70135, lng: 21.5908586 },
    { lat: 48.7013605, lng: 21.5911162 },
    { lat: 48.701267, lng: 21.59111 },
    { lat: 48.701235, lng: 21.591375 },
    { lat: 48.7011457, lng: 21.5914797 },
    { lat: 48.7011804, lng: 21.5917489 },
    { lat: 48.7010869, lng: 21.5919116 },
    { lat: 48.7010482, lng: 21.5923732 },
    { lat: 48.7011527, lng: 21.5925699 },
    { lat: 48.7011051, lng: 21.5928829 },
    { lat: 48.7012253, lng: 21.5931601 },
    { lat: 48.7012988, lng: 21.5936103 },
    { lat: 48.7015661, lng: 21.5942319 },
    { lat: 48.7015084, lng: 21.5947916 },
    { lat: 48.7015987, lng: 21.5953212 },
    { lat: 48.7015845, lng: 21.5956135 },
    { lat: 48.7017017, lng: 21.5961471 },
    { lat: 48.7017406, lng: 21.596737 },
    { lat: 48.7018048, lng: 21.5968996 },
    { lat: 48.7018439, lng: 21.5976487 },
    { lat: 48.7017835, lng: 21.5978666 },
    { lat: 48.7018138, lng: 21.5980207 },
    { lat: 48.7010103, lng: 21.5999562 },
    { lat: 48.7009921, lng: 21.6002227 },
    { lat: 48.7008652, lng: 21.6006006 },
    { lat: 48.7009028, lng: 21.6009341 },
    { lat: 48.7007823, lng: 21.6010518 },
    { lat: 48.7006773, lng: 21.6014061 },
    { lat: 48.7005824, lng: 21.6015119 },
    { lat: 48.7005631, lng: 21.6016631 },
    { lat: 48.7005673, lng: 21.6016963 },
    { lat: 48.7006221, lng: 21.6020045 },
    { lat: 48.7004963, lng: 21.602093 },
    { lat: 48.7005156, lng: 21.6023019 },
    { lat: 48.7003247, lng: 21.6026005 },
    { lat: 48.70018, lng: 21.6026948 },
    { lat: 48.700065, lng: 21.6033725 },
    { lat: 48.699719, lng: 21.6037137 },
    { lat: 48.6997137, lng: 21.6038479 },
    { lat: 48.6999144, lng: 21.604025 },
    { lat: 48.6998983, lng: 21.6042928 },
    { lat: 48.6999618, lng: 21.6046336 },
    { lat: 48.6998142, lng: 21.6048706 },
    { lat: 48.6999362, lng: 21.605651 },
    { lat: 48.6998383, lng: 21.6060142 },
    { lat: 48.6997413, lng: 21.6061293 },
    { lat: 48.6997969, lng: 21.606508 },
    { lat: 48.6994629, lng: 21.6068744 },
    { lat: 48.699488, lng: 21.6071346 },
    { lat: 48.699739, lng: 21.6071731 },
    { lat: 48.6997314, lng: 21.6073908 },
    { lat: 48.6995052, lng: 21.6075477 },
    { lat: 48.6995042, lng: 21.6076301 },
    { lat: 48.6995942, lng: 21.6077533 },
    { lat: 48.6995752, lng: 21.6079611 },
    { lat: 48.6996757, lng: 21.6080134 },
    { lat: 48.6996485, lng: 21.6082064 },
    { lat: 48.6995266, lng: 21.608517 },
    { lat: 48.6996167, lng: 21.6087634 },
    { lat: 48.6995271, lng: 21.6088984 },
    { lat: 48.6994214, lng: 21.6089382 },
    { lat: 48.6995614, lng: 21.609522 },
    { lat: 48.6996586, lng: 21.6097456 },
    { lat: 48.6993637, lng: 21.610144 },
    { lat: 48.6992967, lng: 21.6104824 },
    { lat: 48.6991523, lng: 21.6105133 },
    { lat: 48.6991494, lng: 21.6106452 },
    { lat: 48.6993222, lng: 21.6107539 },
    { lat: 48.699259, lng: 21.6112263 },
    { lat: 48.6991587, lng: 21.6112658 },
    { lat: 48.6991335, lng: 21.6113682 },
    { lat: 48.6991865, lng: 21.6116142 },
    { lat: 48.6991084, lng: 21.6117958 },
    { lat: 48.6993298, lng: 21.612154 },
    { lat: 48.6990986, lng: 21.6122146 },
    { lat: 48.6990892, lng: 21.6126056 },
    { lat: 48.6989625, lng: 21.6128654 },
    { lat: 48.6991235, lng: 21.6131124 },
    { lat: 48.6990549, lng: 21.6132731 },
    { lat: 48.6992714, lng: 21.6134937 },
    { lat: 48.6992658, lng: 21.6135407 },
    { lat: 48.6990274, lng: 21.6135815 },
    { lat: 48.6988999, lng: 21.6136932 },
    { lat: 48.6990358, lng: 21.6141623 },
    { lat: 48.6991584, lng: 21.6140868 },
    { lat: 48.6992299, lng: 21.6142244 },
    { lat: 48.6990435, lng: 21.6143206 },
    { lat: 48.699034, lng: 21.6145043 },
    { lat: 48.6992175, lng: 21.6144293 },
    { lat: 48.6993388, lng: 21.6145462 },
    { lat: 48.6994379, lng: 21.6143447 },
    { lat: 48.6995166, lng: 21.6145449 },
    { lat: 48.6994392, lng: 21.614916 },
    { lat: 48.6995416, lng: 21.6149529 },
    { lat: 48.6995714, lng: 21.6153393 },
    { lat: 48.6998331, lng: 21.6157686 },
    { lat: 48.6999933, lng: 21.6157047 },
    { lat: 48.6999387, lng: 21.6161263 },
    { lat: 48.7000736, lng: 21.6162032 },
    { lat: 48.7000456, lng: 21.6164515 },
    { lat: 48.7001789, lng: 21.6165556 },
    { lat: 48.700352, lng: 21.6165076 },
    { lat: 48.7004186, lng: 21.6167562 },
    { lat: 48.7005905, lng: 21.6167492 },
    { lat: 48.7006407, lng: 21.6170534 },
    { lat: 48.7007592, lng: 21.6169476 },
    { lat: 48.7008679, lng: 21.6174992 },
    { lat: 48.7007074, lng: 21.6179621 },
    { lat: 48.7008353, lng: 21.6181107 },
    { lat: 48.7006986, lng: 21.6183734 },
    { lat: 48.7008521, lng: 21.6186735 },
    { lat: 48.7008213, lng: 21.6190242 },
    { lat: 48.7009798, lng: 21.61962 },
    { lat: 48.7009283, lng: 21.6199125 },
    { lat: 48.7010452, lng: 21.6205231 },
    { lat: 48.7011118, lng: 21.6206129 },
    { lat: 48.7013366, lng: 21.6213727 },
    { lat: 48.7013721, lng: 21.6216753 },
    { lat: 48.7014085, lng: 21.6218381 },
    { lat: 48.7014685, lng: 21.6225346 },
    { lat: 48.7014896, lng: 21.6225848 },
    { lat: 48.7017802, lng: 21.6223106 },
    { lat: 48.7018763, lng: 21.6223327 },
    { lat: 48.7018862, lng: 21.6222412 },
    { lat: 48.7019735, lng: 21.6222257 },
    { lat: 48.7020909, lng: 21.6218742 },
    { lat: 48.7020741, lng: 21.6215525 },
    { lat: 48.7021273, lng: 21.6215652 },
    { lat: 48.7021533, lng: 21.6214495 },
    { lat: 48.7021102, lng: 21.6213579 },
    { lat: 48.702211, lng: 21.6210559 },
    { lat: 48.7023018, lng: 21.6209974 },
    { lat: 48.7023167, lng: 21.6208892 },
    { lat: 48.7022809, lng: 21.6208566 },
    { lat: 48.7024966, lng: 21.6203966 },
    { lat: 48.7027987, lng: 21.6200501 },
    { lat: 48.7028189, lng: 21.6200909 },
    { lat: 48.7027302, lng: 21.6202219 },
    { lat: 48.7025376, lng: 21.6207431 },
    { lat: 48.7024719, lng: 21.6210525 },
    { lat: 48.7025396, lng: 21.6211782 },
    { lat: 48.7025409, lng: 21.6214649 },
    { lat: 48.7026437, lng: 21.6217277 },
    { lat: 48.7026804, lng: 21.6217097 },
    { lat: 48.7030001, lng: 21.6216328 },
    { lat: 48.7034362, lng: 21.6218584 },
    { lat: 48.7033462, lng: 21.6221053 },
    { lat: 48.7031903, lng: 21.6228953 },
    { lat: 48.7028081, lng: 21.6235175 },
    { lat: 48.7027707, lng: 21.6239541 },
    { lat: 48.7026318, lng: 21.6245378 },
    { lat: 48.7026723, lng: 21.6249456 },
    { lat: 48.7028783, lng: 21.6251993 },
    { lat: 48.7028972, lng: 21.6258788 },
    { lat: 48.7026937, lng: 21.6264901 },
    { lat: 48.7022966, lng: 21.627103 },
    { lat: 48.7021722, lng: 21.6277263 },
    { lat: 48.7023685, lng: 21.6280313 },
    { lat: 48.7054731, lng: 21.6283673 },
    { lat: 48.7056964, lng: 21.6283097 },
    { lat: 48.7063467, lng: 21.6238781 },
    { lat: 48.7067293, lng: 21.6230346 },
    { lat: 48.7066633, lng: 21.6228033 },
    { lat: 48.7067942, lng: 21.6226108 },
    { lat: 48.7068931, lng: 21.6226491 },
    { lat: 48.70759, lng: 21.6217732 },
    { lat: 48.7143255, lng: 21.6155031 },
    { lat: 48.7155204, lng: 21.6142889 },
    { lat: 48.7171602, lng: 21.6128476 },
    { lat: 48.7218914, lng: 21.6083654 },
    { lat: 48.7244726, lng: 21.6058218 },
    { lat: 48.7244966, lng: 21.6057953 },
    { lat: 48.7241708, lng: 21.6050025 },
    { lat: 48.7241855, lng: 21.6043622 },
    { lat: 48.723955, lng: 21.6037978 },
    { lat: 48.7226214, lng: 21.601651 },
    { lat: 48.7230146, lng: 21.6015108 },
    { lat: 48.7233973, lng: 21.6004223 },
    { lat: 48.7232316, lng: 21.5997737 },
    { lat: 48.7232719, lng: 21.5994047 },
    { lat: 48.7221699, lng: 21.5972507 },
    { lat: 48.7197795, lng: 21.5928459 },
    { lat: 48.7176527, lng: 21.5958512 },
    { lat: 48.7175974, lng: 21.5957625 },
    { lat: 48.7176875, lng: 21.5953252 },
    { lat: 48.7176549, lng: 21.5950034 },
    { lat: 48.7165705, lng: 21.5923202 },
    { lat: 48.7165691, lng: 21.5919832 },
    { lat: 48.7165145, lng: 21.5917683 },
    { lat: 48.7173624, lng: 21.5905101 },
    { lat: 48.7186079, lng: 21.5891747 },
    { lat: 48.7225309, lng: 21.5836646 },
    { lat: 48.7225336, lng: 21.5836003 },
    { lat: 48.7223172, lng: 21.583425 },
    { lat: 48.7222894, lng: 21.5833786 },
    { lat: 48.7222067, lng: 21.5831955 },
    { lat: 48.7221145, lng: 21.5830647 },
    { lat: 48.7219815, lng: 21.582933 },
    { lat: 48.7219396, lng: 21.5828533 },
    { lat: 48.7218882, lng: 21.582656 },
    { lat: 48.721857, lng: 21.5825275 },
    { lat: 48.7218299, lng: 21.5824431 },
    { lat: 48.7218002, lng: 21.582362 },
    { lat: 48.721692, lng: 21.5822331 },
    { lat: 48.7216184, lng: 21.5821908 },
    { lat: 48.7214997, lng: 21.5821013 },
    { lat: 48.7213354, lng: 21.5819638 },
    { lat: 48.7210733, lng: 21.5819985 },
    { lat: 48.7210433, lng: 21.5820574 },
    { lat: 48.7208926, lng: 21.5820211 },
    { lat: 48.7208781, lng: 21.5818677 },
    { lat: 48.7209346, lng: 21.5817529 },
    { lat: 48.7208169, lng: 21.5815663 },
    { lat: 48.7206345, lng: 21.5816036 },
    { lat: 48.7204703, lng: 21.5813241 },
    { lat: 48.7203553, lng: 21.5812346 },
    { lat: 48.7202359, lng: 21.5812725 },
    { lat: 48.7200457, lng: 21.5811493 },
    { lat: 48.7200173, lng: 21.58102 },
    { lat: 48.7199017, lng: 21.5809918 },
    { lat: 48.7199299, lng: 21.5808296 },
    { lat: 48.7198939, lng: 21.5806303 },
    { lat: 48.7197831, lng: 21.5803722 },
    { lat: 48.719645, lng: 21.5803052 },
    { lat: 48.7195417, lng: 21.5803885 },
    { lat: 48.7195158, lng: 21.5803484 },
  ],
  Biel: [
    { lat: 48.4257816, lng: 22.0436108 },
    { lat: 48.4257069, lng: 22.04364 },
    { lat: 48.4257252, lng: 22.0437917 },
    { lat: 48.4248625, lng: 22.0444966 },
    { lat: 48.4246829, lng: 22.0445542 },
    { lat: 48.4238292, lng: 22.044562 },
    { lat: 48.4237167, lng: 22.0446563 },
    { lat: 48.423695, lng: 22.0444595 },
    { lat: 48.4233955, lng: 22.0440756 },
    { lat: 48.4231472, lng: 22.0441314 },
    { lat: 48.4230151, lng: 22.0443044 },
    { lat: 48.4228957, lng: 22.0443017 },
    { lat: 48.4227611, lng: 22.0440781 },
    { lat: 48.4225826, lng: 22.0439561 },
    { lat: 48.4222734, lng: 22.0440977 },
    { lat: 48.4219675, lng: 22.0446149 },
    { lat: 48.421834, lng: 22.0446803 },
    { lat: 48.4216971, lng: 22.0445964 },
    { lat: 48.4215944, lng: 22.0444458 },
    { lat: 48.4211785, lng: 22.0430232 },
    { lat: 48.4210857, lng: 22.0424407 },
    { lat: 48.4207713, lng: 22.0425641 },
    { lat: 48.4202571, lng: 22.0426458 },
    { lat: 48.4198397, lng: 22.0424653 },
    { lat: 48.419201, lng: 22.0417363 },
    { lat: 48.4189357, lng: 22.0412363 },
    { lat: 48.4185737, lng: 22.0402694 },
    { lat: 48.4179839, lng: 22.0409009 },
    { lat: 48.4179197, lng: 22.0407132 },
    { lat: 48.4176123, lng: 22.0409361 },
    { lat: 48.4176835, lng: 22.0404417 },
    { lat: 48.4176495, lng: 22.0401451 },
    { lat: 48.4173825, lng: 22.0394907 },
    { lat: 48.4168951, lng: 22.0389239 },
    { lat: 48.4165579, lng: 22.0387437 },
    { lat: 48.4162044, lng: 22.03874 },
    { lat: 48.4156203, lng: 22.0389076 },
    { lat: 48.4145322, lng: 22.0393674 },
    { lat: 48.4138636, lng: 22.0395379 },
    { lat: 48.4136403, lng: 22.0395565 },
    { lat: 48.4130981, lng: 22.0394201 },
    { lat: 48.4119044, lng: 22.0392759 },
    { lat: 48.4114984, lng: 22.0394716 },
    { lat: 48.4106399, lng: 22.0401617 },
    { lat: 48.4103944, lng: 22.0404301 },
    { lat: 48.4107235, lng: 22.0420714 },
    { lat: 48.4103374, lng: 22.0422472 },
    { lat: 48.409833, lng: 22.0423665 },
    { lat: 48.410376, lng: 22.0443552 },
    { lat: 48.4097579, lng: 22.0448911 },
    { lat: 48.4092568, lng: 22.0450495 },
    { lat: 48.4075161, lng: 22.0452742 },
    { lat: 48.407355, lng: 22.0450266 },
    { lat: 48.4063522, lng: 22.0447734 },
    { lat: 48.4011869, lng: 22.0383142 },
    { lat: 48.4009339, lng: 22.038971 },
    { lat: 48.4005642, lng: 22.0395432 },
    { lat: 48.399789, lng: 22.0402402 },
    { lat: 48.398739, lng: 22.0408199 },
    { lat: 48.3969945, lng: 22.0416283 },
    { lat: 48.3965375, lng: 22.0415244 },
    { lat: 48.3961837, lng: 22.0428844 },
    { lat: 48.3952552, lng: 22.0432867 },
    { lat: 48.3943781, lng: 22.0435817 },
    { lat: 48.3931159, lng: 22.0437746 },
    { lat: 48.3929341, lng: 22.0438798 },
    { lat: 48.3921945, lng: 22.0445588 },
    { lat: 48.3918485, lng: 22.0439763 },
    { lat: 48.3913967, lng: 22.0437213 },
    { lat: 48.3911343, lng: 22.0439321 },
    { lat: 48.3899955, lng: 22.0429923 },
    { lat: 48.3898886, lng: 22.0431833 },
    { lat: 48.3895882, lng: 22.0434142 },
    { lat: 48.388178, lng: 22.0429254 },
    { lat: 48.386719, lng: 22.0422357 },
    { lat: 48.3864029, lng: 22.0421893 },
    { lat: 48.3856828, lng: 22.0421855 },
    { lat: 48.384859, lng: 22.042546 },
    { lat: 48.384842, lng: 22.042603 },
    { lat: 48.384675, lng: 22.043198 },
    { lat: 48.384629, lng: 22.04335 },
    { lat: 48.384538, lng: 22.043645 },
    { lat: 48.384433, lng: 22.043997 },
    { lat: 48.38434, lng: 22.044325 },
    { lat: 48.38425, lng: 22.044584 },
    { lat: 48.384201, lng: 22.044725 },
    { lat: 48.384081, lng: 22.045081 },
    { lat: 48.384051, lng: 22.045145 },
    { lat: 48.383841, lng: 22.045461 },
    { lat: 48.383701, lng: 22.045662 },
    { lat: 48.383541, lng: 22.045903 },
    { lat: 48.383457, lng: 22.046017 },
    { lat: 48.383176, lng: 22.04644 },
    { lat: 48.383061, lng: 22.046612 },
    { lat: 48.383027, lng: 22.04666 },
    { lat: 48.382794, lng: 22.046964 },
    { lat: 48.382556, lng: 22.047307 },
    { lat: 48.382402, lng: 22.047535 },
    { lat: 48.382148, lng: 22.047911 },
    { lat: 48.381886, lng: 22.048303 },
    { lat: 48.381823, lng: 22.048378 },
    { lat: 48.38176, lng: 22.048478 },
    { lat: 48.381652, lng: 22.048647 },
    { lat: 48.381439, lng: 22.048958 },
    { lat: 48.381431, lng: 22.048971 },
    { lat: 48.3819352, lng: 22.0496435 },
    { lat: 48.3850737, lng: 22.0548052 },
    { lat: 48.3868867, lng: 22.054498 },
    { lat: 48.386899, lng: 22.0547345 },
    { lat: 48.387064, lng: 22.0550476 },
    { lat: 48.3873547, lng: 22.0554391 },
    { lat: 48.387621, lng: 22.0556112 },
    { lat: 48.3879755, lng: 22.0557529 },
    { lat: 48.3883288, lng: 22.0558013 },
    { lat: 48.3893465, lng: 22.0557346 },
    { lat: 48.3896439, lng: 22.0557963 },
    { lat: 48.390135, lng: 22.0561008 },
    { lat: 48.3906347, lng: 22.0567231 },
    { lat: 48.3907109, lng: 22.0568938 },
    { lat: 48.3909572, lng: 22.0570765 },
    { lat: 48.3909844, lng: 22.0571756 },
    { lat: 48.3906121, lng: 22.0572327 },
    { lat: 48.3905707, lng: 22.0573719 },
    { lat: 48.3907527, lng: 22.0584897 },
    { lat: 48.3914097, lng: 22.0584876 },
    { lat: 48.3913798, lng: 22.0602279 },
    { lat: 48.3916689, lng: 22.0605144 },
    { lat: 48.3919959, lng: 22.0611922 },
    { lat: 48.3920774, lng: 22.0618252 },
    { lat: 48.3920658, lng: 22.0623211 },
    { lat: 48.3920336, lng: 22.0626194 },
    { lat: 48.3918933, lng: 22.0628621 },
    { lat: 48.3905274, lng: 22.0644975 },
    { lat: 48.3923683, lng: 22.0669462 },
    { lat: 48.3958838, lng: 22.0681613 },
    { lat: 48.3962968, lng: 22.0669428 },
    { lat: 48.3974769, lng: 22.067699 },
    { lat: 48.3985483, lng: 22.0665393 },
    { lat: 48.3987679, lng: 22.0670276 },
    { lat: 48.399589, lng: 22.0666942 },
    { lat: 48.399794, lng: 22.0666523 },
    { lat: 48.4000276, lng: 22.0667285 },
    { lat: 48.4003188, lng: 22.066906 },
    { lat: 48.4006805, lng: 22.0688508 },
    { lat: 48.400752, lng: 22.0689241 },
    { lat: 48.3999758, lng: 22.0710881 },
    { lat: 48.4000656, lng: 22.0711265 },
    { lat: 48.4005948, lng: 22.070861 },
    { lat: 48.4012296, lng: 22.0727922 },
    { lat: 48.403179, lng: 22.071894 },
    { lat: 48.4066006, lng: 22.0704144 },
    { lat: 48.4068553, lng: 22.0720785 },
    { lat: 48.4069577, lng: 22.0724068 },
    { lat: 48.408847, lng: 22.0749173 },
    { lat: 48.4111725, lng: 22.0731334 },
    { lat: 48.4128181, lng: 22.0720015 },
    { lat: 48.4161765, lng: 22.0703253 },
    { lat: 48.4142616, lng: 22.060966 },
    { lat: 48.4140793, lng: 22.0603055 },
    { lat: 48.4141833, lng: 22.0601905 },
    { lat: 48.4137922, lng: 22.0580187 },
    { lat: 48.4134631, lng: 22.0584088 },
    { lat: 48.4133613, lng: 22.0578718 },
    { lat: 48.4137062, lng: 22.0575305 },
    { lat: 48.4135627, lng: 22.0572231 },
    { lat: 48.4133263, lng: 22.0571265 },
    { lat: 48.4130792, lng: 22.0561067 },
    { lat: 48.4129921, lng: 22.0555753 },
    { lat: 48.412999, lng: 22.0552576 },
    { lat: 48.4129108, lng: 22.05501 },
    { lat: 48.4126159, lng: 22.0545798 },
    { lat: 48.4125037, lng: 22.0545031 },
    { lat: 48.4119734, lng: 22.0535996 },
    { lat: 48.411462, lng: 22.0525935 },
    { lat: 48.41117, lng: 22.0512199 },
    { lat: 48.4111375, lng: 22.050619 },
    { lat: 48.4118854, lng: 22.0499596 },
    { lat: 48.4117512, lng: 22.0497651 },
    { lat: 48.4119304, lng: 22.0492185 },
    { lat: 48.4118911, lng: 22.0487567 },
    { lat: 48.4108157, lng: 22.043743 },
    { lat: 48.4114211, lng: 22.0430084 },
    { lat: 48.4116226, lng: 22.0435682 },
    { lat: 48.4118514, lng: 22.0440001 },
    { lat: 48.4125133, lng: 22.0447557 },
    { lat: 48.4129976, lng: 22.0446435 },
    { lat: 48.4165182, lng: 22.0474106 },
    { lat: 48.416784, lng: 22.0475122 },
    { lat: 48.4174415, lng: 22.0480408 },
    { lat: 48.417973, lng: 22.0482526 },
    { lat: 48.4188093, lng: 22.0487807 },
    { lat: 48.420632, lng: 22.0505558 },
    { lat: 48.4208978, lng: 22.0503844 },
    { lat: 48.4214824, lng: 22.0529762 },
    { lat: 48.4233647, lng: 22.0623575 },
    { lat: 48.4253985, lng: 22.0721445 },
    { lat: 48.4256157, lng: 22.072284 },
    { lat: 48.4258099, lng: 22.0734799 },
    { lat: 48.4257708, lng: 22.0739644 },
    { lat: 48.4260577, lng: 22.0752817 },
    { lat: 48.426353, lng: 22.0767548 },
    { lat: 48.4265303, lng: 22.076227 },
    { lat: 48.4273116, lng: 22.0754027 },
    { lat: 48.428426, lng: 22.0744927 },
    { lat: 48.4292383, lng: 22.073419 },
    { lat: 48.4295611, lng: 22.0728999 },
    { lat: 48.4293904, lng: 22.072634 },
    { lat: 48.4293814, lng: 22.0717383 },
    { lat: 48.4294643, lng: 22.0714401 },
    { lat: 48.4293883, lng: 22.0710932 },
    { lat: 48.4282123, lng: 22.0684426 },
    { lat: 48.4274358, lng: 22.0689926 },
    { lat: 48.4266344, lng: 22.0661724 },
    { lat: 48.4263028, lng: 22.065392 },
    { lat: 48.426944, lng: 22.0637066 },
    { lat: 48.4274982, lng: 22.0633131 },
    { lat: 48.4271587, lng: 22.0625746 },
    { lat: 48.4270816, lng: 22.0621757 },
    { lat: 48.4269828, lng: 22.061105 },
    { lat: 48.4269961, lng: 22.0593687 },
    { lat: 48.4269022, lng: 22.0591092 },
    { lat: 48.4255261, lng: 22.0533699 },
    { lat: 48.4254001, lng: 22.0521576 },
    { lat: 48.4262116, lng: 22.0505024 },
    { lat: 48.4269832, lng: 22.0476121 },
    { lat: 48.4269488, lng: 22.0473951 },
    { lat: 48.4268302, lng: 22.0471058 },
    { lat: 48.4264148, lng: 22.046294 },
    { lat: 48.4260206, lng: 22.0443769 },
    { lat: 48.4257816, lng: 22.0436108 },
  ],
  Trebišov: [
    { lat: 48.6260942, lng: 21.6635137 },
    { lat: 48.6257038, lng: 21.6636337 },
    { lat: 48.6255405, lng: 21.6628558 },
    { lat: 48.6227192, lng: 21.663937 },
    { lat: 48.6228271, lng: 21.6646309 },
    { lat: 48.6198969, lng: 21.6654385 },
    { lat: 48.6201429, lng: 21.6679271 },
    { lat: 48.6201254, lng: 21.6683204 },
    { lat: 48.618131, lng: 21.6691985 },
    { lat: 48.6174078, lng: 21.6694111 },
    { lat: 48.6177104, lng: 21.6708456 },
    { lat: 48.6166578, lng: 21.6712393 },
    { lat: 48.6167208, lng: 21.6716539 },
    { lat: 48.6146434, lng: 21.6723763 },
    { lat: 48.614038, lng: 21.6723688 },
    { lat: 48.6135625, lng: 21.6725531 },
    { lat: 48.6127051, lng: 21.6729631 },
    { lat: 48.6117553, lng: 21.6733727 },
    { lat: 48.6095636, lng: 21.6745191 },
    { lat: 48.6085855, lng: 21.674965 },
    { lat: 48.607401, lng: 21.6755858 },
    { lat: 48.6074012, lng: 21.6756352 },
    { lat: 48.6070452, lng: 21.6759133 },
    { lat: 48.6067418, lng: 21.676042 },
    { lat: 48.6073523, lng: 21.6782979 },
    { lat: 48.6070369, lng: 21.6789386 },
    { lat: 48.606784, lng: 21.6791154 },
    { lat: 48.6065253, lng: 21.6791584 },
    { lat: 48.6062744, lng: 21.6789924 },
    { lat: 48.6060947, lng: 21.6786793 },
    { lat: 48.6057119, lng: 21.6786643 },
    { lat: 48.6052989, lng: 21.6781314 },
    { lat: 48.6036287, lng: 21.679099 },
    { lat: 48.6033385, lng: 21.6788607 },
    { lat: 48.6018799, lng: 21.6811528 },
    { lat: 48.6008601, lng: 21.6823344 },
    { lat: 48.5994741, lng: 21.6832891 },
    { lat: 48.5989736, lng: 21.6837467 },
    { lat: 48.5977026, lng: 21.6846672 },
    { lat: 48.5971747, lng: 21.6854023 },
    { lat: 48.5963485, lng: 21.6860137 },
    { lat: 48.5960085, lng: 21.6863312 },
    { lat: 48.595521, lng: 21.6871842 },
    { lat: 48.5946068, lng: 21.6876229 },
    { lat: 48.5941295, lng: 21.6884415 },
    { lat: 48.5845231, lng: 21.6969981 },
    { lat: 48.5816343, lng: 21.6995049 },
    { lat: 48.58165, lng: 21.6995434 },
    { lat: 48.5817172, lng: 21.6996231 },
    { lat: 48.5819414, lng: 21.6997507 },
    { lat: 48.582067, lng: 21.7003577 },
    { lat: 48.5821822, lng: 21.7005788 },
    { lat: 48.5823466, lng: 21.7006681 },
    { lat: 48.5826406, lng: 21.7016794 },
    { lat: 48.5827548, lng: 21.7017403 },
    { lat: 48.5827928, lng: 21.7020645 },
    { lat: 48.5830636, lng: 21.7020542 },
    { lat: 48.5832105, lng: 21.7019066 },
    { lat: 48.5832269, lng: 21.7017286 },
    { lat: 48.5839185, lng: 21.7014618 },
    { lat: 48.5843425, lng: 21.7016201 },
    { lat: 48.5844659, lng: 21.7020688 },
    { lat: 48.5847333, lng: 21.7024688 },
    { lat: 48.5849171, lng: 21.7031024 },
    { lat: 48.5853224, lng: 21.7029112 },
    { lat: 48.5854008, lng: 21.7033058 },
    { lat: 48.5855562, lng: 21.7036896 },
    { lat: 48.5859469, lng: 21.7035685 },
    { lat: 48.5867654, lng: 21.7075371 },
    { lat: 48.586465, lng: 21.707579 },
    { lat: 48.587099, lng: 21.7115971 },
    { lat: 48.5858745, lng: 21.7109974 },
    { lat: 48.5860591, lng: 21.7117494 },
    { lat: 48.5863693, lng: 21.7124862 },
    { lat: 48.5864649, lng: 21.7128578 },
    { lat: 48.5865403, lng: 21.7141918 },
    { lat: 48.5866685, lng: 21.7154818 },
    { lat: 48.5866333, lng: 21.7173028 },
    { lat: 48.5867083, lng: 21.7174983 },
    { lat: 48.5869628, lng: 21.7178437 },
    { lat: 48.5872702, lng: 21.7187236 },
    { lat: 48.5875431, lng: 21.7198367 },
    { lat: 48.5872016, lng: 21.719896 },
    { lat: 48.5872623, lng: 21.7202672 },
    { lat: 48.5875473, lng: 21.7206595 },
    { lat: 48.5879747, lng: 21.721778 },
    { lat: 48.5889979, lng: 21.7240947 },
    { lat: 48.5907174, lng: 21.7283762 },
    { lat: 48.591438, lng: 21.7280625 },
    { lat: 48.5947335, lng: 21.7685002 },
    { lat: 48.5950809, lng: 21.7719695 },
    { lat: 48.59525, lng: 21.7747039 },
    { lat: 48.5950782, lng: 21.7839908 },
    { lat: 48.5949179, lng: 21.7865288 },
    { lat: 48.59465, lng: 21.797947 },
    { lat: 48.594646, lng: 21.798179 },
    { lat: 48.594775, lng: 21.798967 },
    { lat: 48.594801, lng: 21.799126 },
    { lat: 48.594812, lng: 21.799195 },
    { lat: 48.594837, lng: 21.799341 },
    { lat: 48.594831, lng: 21.799517 },
    { lat: 48.594829, lng: 21.79958 },
    { lat: 48.594822, lng: 21.799834 },
    { lat: 48.594765, lng: 21.801985 },
    { lat: 48.594677, lng: 21.805237 },
    { lat: 48.594697, lng: 21.805417 },
    { lat: 48.594517, lng: 21.806768 },
    { lat: 48.59451, lng: 21.806827 },
    { lat: 48.594497, lng: 21.806948 },
    { lat: 48.594487, lng: 21.807046 },
    { lat: 48.594564, lng: 21.807651 },
    { lat: 48.594626, lng: 21.808269 },
    { lat: 48.594645, lng: 21.808462 },
    { lat: 48.594644, lng: 21.808652 },
    { lat: 48.594651, lng: 21.808658 },
    { lat: 48.594673, lng: 21.810522 },
    { lat: 48.594711, lng: 21.810717 },
    { lat: 48.594729, lng: 21.810692 },
    { lat: 48.59474, lng: 21.810677 },
    { lat: 48.594789, lng: 21.810608 },
    { lat: 48.595015, lng: 21.810449 },
    { lat: 48.595106, lng: 21.810385 },
    { lat: 48.595361, lng: 21.810171 },
    { lat: 48.595362, lng: 21.810171 },
    { lat: 48.595666, lng: 21.810065 },
    { lat: 48.595699, lng: 21.810053 },
    { lat: 48.595718, lng: 21.810047 },
    { lat: 48.595768, lng: 21.81003 },
    { lat: 48.595839, lng: 21.810059 },
    { lat: 48.595912, lng: 21.81009 },
    { lat: 48.595989, lng: 21.810053 },
    { lat: 48.596263, lng: 21.809892 },
    { lat: 48.596341, lng: 21.809868 },
    { lat: 48.59645, lng: 21.809723 },
    { lat: 48.596477, lng: 21.809687 },
    { lat: 48.596576, lng: 21.809556 },
    { lat: 48.596764, lng: 21.809564 },
    { lat: 48.597216, lng: 21.80917 },
    { lat: 48.597271, lng: 21.809158 },
    { lat: 48.597469, lng: 21.809117 },
    { lat: 48.597665, lng: 21.809077 },
    { lat: 48.597787, lng: 21.809084 },
    { lat: 48.597881, lng: 21.809008 },
    { lat: 48.597974, lng: 21.808935 },
    { lat: 48.598058, lng: 21.808934 },
    { lat: 48.598711, lng: 21.809117 },
    { lat: 48.598927, lng: 21.809132 },
    { lat: 48.599318, lng: 21.808749 },
    { lat: 48.599409, lng: 21.80871 },
    { lat: 48.599559, lng: 21.808646 },
    { lat: 48.599917, lng: 21.808332 },
    { lat: 48.600216, lng: 21.80787 },
    { lat: 48.600262, lng: 21.807795 },
    { lat: 48.600308, lng: 21.807717 },
    { lat: 48.600383, lng: 21.807671 },
    { lat: 48.600433, lng: 21.807641 },
    { lat: 48.600679, lng: 21.80743 },
    { lat: 48.600754, lng: 21.807409 },
    { lat: 48.600779, lng: 21.807419 },
    { lat: 48.600799, lng: 21.807427 },
    { lat: 48.601046, lng: 21.807527 },
    { lat: 48.60127, lng: 21.807021 },
    { lat: 48.601293, lng: 21.806966 },
    { lat: 48.601329, lng: 21.806885 },
    { lat: 48.601343, lng: 21.806883 },
    { lat: 48.60162, lng: 21.806828 },
    { lat: 48.601625, lng: 21.806827 },
    { lat: 48.601653, lng: 21.806821 },
    { lat: 48.602113, lng: 21.806542 },
    { lat: 48.602173, lng: 21.806221 },
    { lat: 48.602187, lng: 21.806126 },
    { lat: 48.602194, lng: 21.806076 },
    { lat: 48.602122, lng: 21.80589 },
    { lat: 48.6021, lng: 21.805747 },
    { lat: 48.602106, lng: 21.805742 },
    { lat: 48.602401, lng: 21.805478 },
    { lat: 48.602506, lng: 21.805383 },
    { lat: 48.602716, lng: 21.805195 },
    { lat: 48.602721, lng: 21.805191 },
    { lat: 48.602844, lng: 21.805112 },
    { lat: 48.60288, lng: 21.80509 },
    { lat: 48.602918, lng: 21.805065 },
    { lat: 48.603021, lng: 21.804884 },
    { lat: 48.603256, lng: 21.804278 },
    { lat: 48.603259, lng: 21.804237 },
    { lat: 48.603259, lng: 21.804235 },
    { lat: 48.603268, lng: 21.80411 },
    { lat: 48.603289, lng: 21.803842 },
    { lat: 48.603489, lng: 21.803674 },
    { lat: 48.603526, lng: 21.803644 },
    { lat: 48.603622, lng: 21.803564 },
    { lat: 48.603645, lng: 21.803544 },
    { lat: 48.603753, lng: 21.803454 },
    { lat: 48.603984, lng: 21.803178 },
    { lat: 48.604129, lng: 21.803091 },
    { lat: 48.604135, lng: 21.803088 },
    { lat: 48.604453, lng: 21.802963 },
    { lat: 48.604784, lng: 21.802747 },
    { lat: 48.60496, lng: 21.802527 },
    { lat: 48.605084, lng: 21.802308 },
    { lat: 48.605169, lng: 21.802131 },
    { lat: 48.605173, lng: 21.802121 },
    { lat: 48.605606, lng: 21.801883 },
    { lat: 48.605625, lng: 21.801869 },
    { lat: 48.605747, lng: 21.801772 },
    { lat: 48.605859, lng: 21.80169 },
    { lat: 48.606001, lng: 21.801585 },
    { lat: 48.606007, lng: 21.801582 },
    { lat: 48.606286, lng: 21.801418 },
    { lat: 48.60635, lng: 21.801412 },
    { lat: 48.606558, lng: 21.801392 },
    { lat: 48.606697, lng: 21.801379 },
    { lat: 48.606854, lng: 21.801364 },
    { lat: 48.606897, lng: 21.801349 },
    { lat: 48.607084, lng: 21.801279 },
    { lat: 48.607236, lng: 21.801222 },
    { lat: 48.609097, lng: 21.800981 },
    { lat: 48.609118, lng: 21.800972 },
    { lat: 48.609114, lng: 21.800939 },
    { lat: 48.609116, lng: 21.8009 },
    { lat: 48.609105, lng: 21.800852 },
    { lat: 48.609924, lng: 21.800637 },
    { lat: 48.610751, lng: 21.800423 },
    { lat: 48.611552, lng: 21.800224 },
    { lat: 48.612268, lng: 21.800025 },
    { lat: 48.613915, lng: 21.799579 },
    { lat: 48.614513, lng: 21.799448 },
    { lat: 48.61457, lng: 21.799348 },
    { lat: 48.615962, lng: 21.799151 },
    { lat: 48.616492, lng: 21.798993 },
    { lat: 48.618291, lng: 21.798597 },
    { lat: 48.619049, lng: 21.798458 },
    { lat: 48.619241, lng: 21.79844 },
    { lat: 48.619492, lng: 21.798416 },
    { lat: 48.619939, lng: 21.798277 },
    { lat: 48.620442, lng: 21.79812 },
    { lat: 48.621978, lng: 21.797758 },
    { lat: 48.62203, lng: 21.797743 },
    { lat: 48.622519, lng: 21.797613 },
    { lat: 48.622586, lng: 21.7976 },
    { lat: 48.622631, lng: 21.797591 },
    { lat: 48.622721, lng: 21.797573 },
    { lat: 48.62276, lng: 21.797565 },
    { lat: 48.622778, lng: 21.797562 },
    { lat: 48.623086, lng: 21.7975 },
    { lat: 48.623256, lng: 21.797466 },
    { lat: 48.623736, lng: 21.797364 },
    { lat: 48.624063, lng: 21.797277 },
    { lat: 48.624335, lng: 21.797205 },
    { lat: 48.624975, lng: 21.797069 },
    { lat: 48.625278, lng: 21.796977 },
    { lat: 48.625307, lng: 21.796967 },
    { lat: 48.625482, lng: 21.796934 },
    { lat: 48.625612, lng: 21.79691 },
    { lat: 48.625778, lng: 21.796878 },
    { lat: 48.625803, lng: 21.796873 },
    { lat: 48.625843, lng: 21.796864 },
    { lat: 48.626285, lng: 21.796766 },
    { lat: 48.626416, lng: 21.796763 },
    { lat: 48.626531, lng: 21.79676 },
    { lat: 48.626593, lng: 21.796759 },
    { lat: 48.626845, lng: 21.796753 },
    { lat: 48.626936, lng: 21.796751 },
    { lat: 48.627353, lng: 21.796751 },
    { lat: 48.627373, lng: 21.796751 },
    { lat: 48.627471, lng: 21.796752 },
    { lat: 48.627671, lng: 21.796747 },
    { lat: 48.627907, lng: 21.796741 },
    { lat: 48.628869, lng: 21.796717 },
    { lat: 48.62923, lng: 21.796709 },
    { lat: 48.629489, lng: 21.796702 },
    { lat: 48.629728, lng: 21.796696 },
    { lat: 48.630054, lng: 21.796688 },
    { lat: 48.630221, lng: 21.796684 },
    { lat: 48.630451, lng: 21.796679 },
    { lat: 48.630554, lng: 21.796676 },
    { lat: 48.630994, lng: 21.796666 },
    { lat: 48.631009, lng: 21.796665 },
    { lat: 48.631484, lng: 21.796654 },
    { lat: 48.632005, lng: 21.796641 },
    { lat: 48.633263, lng: 21.796568 },
    { lat: 48.633445, lng: 21.796558 },
    { lat: 48.633628, lng: 21.796547 },
    { lat: 48.634827, lng: 21.796478 },
    { lat: 48.634988, lng: 21.796476 },
    { lat: 48.635351, lng: 21.796471 },
    { lat: 48.635372, lng: 21.79647 },
    { lat: 48.635432, lng: 21.79647 },
    { lat: 48.635897, lng: 21.796463 },
    { lat: 48.636346, lng: 21.796457 },
    { lat: 48.638713, lng: 21.796423 },
    { lat: 48.638899, lng: 21.79642 },
    { lat: 48.639261, lng: 21.796415 },
    { lat: 48.642054, lng: 21.796375 },
    { lat: 48.644874, lng: 21.796346 },
    { lat: 48.647029, lng: 21.796324 },
    { lat: 48.64794, lng: 21.796326 },
    { lat: 48.647942, lng: 21.796326 },
    { lat: 48.648666, lng: 21.796328 },
    { lat: 48.648845, lng: 21.796252 },
    { lat: 48.648941, lng: 21.796189 },
    { lat: 48.649044, lng: 21.796122 },
    { lat: 48.651114, lng: 21.79441 },
    { lat: 48.651114, lng: 21.794409 },
    { lat: 48.651114, lng: 21.794407 },
    { lat: 48.651073, lng: 21.794281 },
    { lat: 48.651026, lng: 21.79422 },
    { lat: 48.65093, lng: 21.793971 },
    { lat: 48.650933, lng: 21.79382 },
    { lat: 48.650956, lng: 21.793493 },
    { lat: 48.650938, lng: 21.7932 },
    { lat: 48.650936, lng: 21.793117 },
    { lat: 48.650953, lng: 21.793017 },
    { lat: 48.650988, lng: 21.792813 },
    { lat: 48.65092, lng: 21.792682 },
    { lat: 48.650889, lng: 21.792623 },
    { lat: 48.650607, lng: 21.79208 },
    { lat: 48.65058, lng: 21.791951 },
    { lat: 48.650525, lng: 21.791634 },
    { lat: 48.649716, lng: 21.791913 },
    { lat: 48.649243, lng: 21.791912 },
    { lat: 48.648553, lng: 21.791545 },
    { lat: 48.647821, lng: 21.791382 },
    { lat: 48.647308, lng: 21.7914 },
    { lat: 48.646339, lng: 21.791432 },
    { lat: 48.644909, lng: 21.791392 },
    { lat: 48.644385, lng: 21.791333 },
    { lat: 48.643883, lng: 21.791357 },
    { lat: 48.643302, lng: 21.791303 },
    { lat: 48.643001, lng: 21.791305 },
    { lat: 48.642361, lng: 21.791355 },
    { lat: 48.642364, lng: 21.791046 },
    { lat: 48.642392, lng: 21.790494 },
    { lat: 48.642397, lng: 21.790366 },
    { lat: 48.64238, lng: 21.790111 },
    { lat: 48.64237, lng: 21.789796 },
    { lat: 48.642802, lng: 21.786737 },
    { lat: 48.643175, lng: 21.784044 },
    { lat: 48.643195, lng: 21.783941 },
    { lat: 48.643197, lng: 21.783931 },
    { lat: 48.643203, lng: 21.78388 },
    { lat: 48.643304, lng: 21.783111 },
    { lat: 48.643349, lng: 21.783019 },
    { lat: 48.643424, lng: 21.78298 },
    { lat: 48.643437, lng: 21.782973 },
    { lat: 48.651942, lng: 21.780994 },
    { lat: 48.652318, lng: 21.780995 },
    { lat: 48.65265, lng: 21.780957 },
    { lat: 48.65267, lng: 21.780945 },
    { lat: 48.652768, lng: 21.780884 },
    { lat: 48.652864, lng: 21.780749 },
    { lat: 48.652876, lng: 21.780712 },
    { lat: 48.65289, lng: 21.780673 },
    { lat: 48.652892, lng: 21.780616 },
    { lat: 48.652895, lng: 21.780554 },
    { lat: 48.652773, lng: 21.779997 },
    { lat: 48.652778, lng: 21.779833 },
    { lat: 48.652818, lng: 21.779746 },
    { lat: 48.653108, lng: 21.779599 },
    { lat: 48.653312, lng: 21.779496 },
    { lat: 48.653345, lng: 21.77949 },
    { lat: 48.65335, lng: 21.779489 },
    { lat: 48.653459, lng: 21.779464 },
    { lat: 48.653513, lng: 21.779477 },
    { lat: 48.65352, lng: 21.779479 },
    { lat: 48.653675, lng: 21.77948 },
    { lat: 48.657666, lng: 21.778312 },
    { lat: 48.657721, lng: 21.778296 },
    { lat: 48.657967, lng: 21.778224 },
    { lat: 48.658042, lng: 21.77819 },
    { lat: 48.6581, lng: 21.778128 },
    { lat: 48.658147, lng: 21.778077 },
    { lat: 48.658619, lng: 21.777536 },
    { lat: 48.660283, lng: 21.775627 },
    { lat: 48.660398, lng: 21.775497 },
    { lat: 48.660492, lng: 21.775341 },
    { lat: 48.66051, lng: 21.775247 },
    { lat: 48.660482, lng: 21.775132 },
    { lat: 48.660474, lng: 21.775099 },
    { lat: 48.6602882, lng: 21.7744532 },
    { lat: 48.6593688, lng: 21.7724399 },
    { lat: 48.6591401, lng: 21.7718338 },
    { lat: 48.6584984, lng: 21.7705447 },
    { lat: 48.6584628, lng: 21.7703508 },
    { lat: 48.6584059, lng: 21.7703652 },
    { lat: 48.6583245, lng: 21.770057 },
    { lat: 48.6582998, lng: 21.7696774 },
    { lat: 48.6578339, lng: 21.7677625 },
    { lat: 48.6575825, lng: 21.7674033 },
    { lat: 48.6570439, lng: 21.7672379 },
    { lat: 48.6570591, lng: 21.7659118 },
    { lat: 48.6569791, lng: 21.7653903 },
    { lat: 48.6565559, lng: 21.7636953 },
    { lat: 48.6569606, lng: 21.7622802 },
    { lat: 48.6570747, lng: 21.7615338 },
    { lat: 48.6568512, lng: 21.7615512 },
    { lat: 48.6551323, lng: 21.7436479 },
    { lat: 48.6555182, lng: 21.7436112 },
    { lat: 48.6556455, lng: 21.7434875 },
    { lat: 48.6558806, lng: 21.7436098 },
    { lat: 48.6586291, lng: 21.7466048 },
    { lat: 48.6600489, lng: 21.7480303 },
    { lat: 48.6680964, lng: 21.7557159 },
    { lat: 48.6681989, lng: 21.7556314 },
    { lat: 48.6682211, lng: 21.7555371 },
    { lat: 48.6680695, lng: 21.7549149 },
    { lat: 48.6680927, lng: 21.7547245 },
    { lat: 48.6682881, lng: 21.7543766 },
    { lat: 48.6688328, lng: 21.7538755 },
    { lat: 48.6689249, lng: 21.7536606 },
    { lat: 48.6689083, lng: 21.7532406 },
    { lat: 48.6688483, lng: 21.752937 },
    { lat: 48.6686352, lng: 21.7522014 },
    { lat: 48.6686217, lng: 21.7516505 },
    { lat: 48.669336, lng: 21.7513476 },
    { lat: 48.6702555, lng: 21.751154 },
    { lat: 48.6756092, lng: 21.7484708 },
    { lat: 48.6728176, lng: 21.7357488 },
    { lat: 48.6728111, lng: 21.7357179 },
    { lat: 48.672851, lng: 21.7356468 },
    { lat: 48.6729358, lng: 21.7354971 },
    { lat: 48.6728366, lng: 21.7351115 },
    { lat: 48.6720427, lng: 21.7333189 },
    { lat: 48.6717325, lng: 21.7324526 },
    { lat: 48.6720433, lng: 21.7321652 },
    { lat: 48.672331, lng: 21.7315257 },
    { lat: 48.6723751, lng: 21.7305655 },
    { lat: 48.672314, lng: 21.7300125 },
    { lat: 48.6712379, lng: 21.7302014 },
    { lat: 48.6710638, lng: 21.7299615 },
    { lat: 48.6710009, lng: 21.7295481 },
    { lat: 48.6711089, lng: 21.7281611 },
    { lat: 48.6711354, lng: 21.7275938 },
    { lat: 48.6711078, lng: 21.7274891 },
    { lat: 48.6712274, lng: 21.7272518 },
    { lat: 48.6715411, lng: 21.7262825 },
    { lat: 48.6715588, lng: 21.7260579 },
    { lat: 48.6717147, lng: 21.7255422 },
    { lat: 48.6718945, lng: 21.7252386 },
    { lat: 48.6717077, lng: 21.7244822 },
    { lat: 48.6704907, lng: 21.7157545 },
    { lat: 48.6702915, lng: 21.7130881 },
    { lat: 48.6702551, lng: 21.7114437 },
    { lat: 48.6701453, lng: 21.7102939 },
    { lat: 48.6701172, lng: 21.7092968 },
    { lat: 48.6696, lng: 21.7058936 },
    { lat: 48.669793, lng: 21.7053322 },
    { lat: 48.6698724, lng: 21.7049375 },
    { lat: 48.6699581, lng: 21.7041167 },
    { lat: 48.6700043, lng: 21.7030335 },
    { lat: 48.6703645, lng: 21.6996794 },
    { lat: 48.6702209, lng: 21.6985738 },
    { lat: 48.6701537, lng: 21.6973388 },
    { lat: 48.6701843, lng: 21.6928258 },
    { lat: 48.6701586, lng: 21.6910098 },
    { lat: 48.6696984, lng: 21.6875649 },
    { lat: 48.6694689, lng: 21.6853785 },
    { lat: 48.6694909, lng: 21.6842036 },
    { lat: 48.6694493, lng: 21.6828389 },
    { lat: 48.6692062, lng: 21.6796204 },
    { lat: 48.6691983, lng: 21.6796156 },
    { lat: 48.6685596, lng: 21.6792164 },
    { lat: 48.668349, lng: 21.6791619 },
    { lat: 48.6681043, lng: 21.6791902 },
    { lat: 48.6678146, lng: 21.6792534 },
    { lat: 48.6678752, lng: 21.6796163 },
    { lat: 48.6676547, lng: 21.6796987 },
    { lat: 48.6675445, lng: 21.6798686 },
    { lat: 48.6672432, lng: 21.6799578 },
    { lat: 48.6670884, lng: 21.6798909 },
    { lat: 48.6670291, lng: 21.6797817 },
    { lat: 48.6668471, lng: 21.6788412 },
    { lat: 48.6663804, lng: 21.6787674 },
    { lat: 48.6655638, lng: 21.6784437 },
    { lat: 48.6651665, lng: 21.6783559 },
    { lat: 48.664483, lng: 21.6784568 },
    { lat: 48.6642165, lng: 21.678431 },
    { lat: 48.66386, lng: 21.6781421 },
    { lat: 48.6632042, lng: 21.6780693 },
    { lat: 48.6632021, lng: 21.6787375 },
    { lat: 48.6633772, lng: 21.6813177 },
    { lat: 48.663306, lng: 21.6843232 },
    { lat: 48.6633374, lng: 21.6856834 },
    { lat: 48.6634515, lng: 21.6873901 },
    { lat: 48.662957, lng: 21.6866676 },
    { lat: 48.6626671, lng: 21.686351 },
    { lat: 48.6619134, lng: 21.6859447 },
    { lat: 48.6621192, lng: 21.6899523 },
    { lat: 48.66125, lng: 21.690015 },
    { lat: 48.6568442, lng: 21.6909661 },
    { lat: 48.6563722, lng: 21.6911167 },
    { lat: 48.6547838, lng: 21.691826 },
    { lat: 48.653346, lng: 21.6926633 },
    { lat: 48.6525404, lng: 21.6932825 },
    { lat: 48.6512275, lng: 21.6939089 },
    { lat: 48.6509607, lng: 21.691995 },
    { lat: 48.6504932, lng: 21.6893243 },
    { lat: 48.6478813, lng: 21.6901784 },
    { lat: 48.6438707, lng: 21.6912656 },
    { lat: 48.63818, lng: 21.6929742 },
    { lat: 48.637194, lng: 21.6933294 },
    { lat: 48.636443, lng: 21.6885038 },
    { lat: 48.6366031, lng: 21.6884359 },
    { lat: 48.6365975, lng: 21.6883942 },
    { lat: 48.6358385, lng: 21.6840815 },
    { lat: 48.6347678, lng: 21.6785176 },
    { lat: 48.6345931, lng: 21.6777249 },
    { lat: 48.6345787, lng: 21.6777322 },
    { lat: 48.633922, lng: 21.6780373 },
    { lat: 48.6338925, lng: 21.678149 },
    { lat: 48.6337005, lng: 21.678252 },
    { lat: 48.6333855, lng: 21.6787418 },
    { lat: 48.6325437, lng: 21.6790684 },
    { lat: 48.6312775, lng: 21.6730373 },
    { lat: 48.6309068, lng: 21.6731813 },
    { lat: 48.6303849, lng: 21.6702313 },
    { lat: 48.6294158, lng: 21.6695984 },
    { lat: 48.6287554, lng: 21.6693924 },
    { lat: 48.6276885, lng: 21.6691739 },
    { lat: 48.6273965, lng: 21.6673437 },
    { lat: 48.626491, lng: 21.6633758 },
    { lat: 48.6260942, lng: 21.6635137 },
  ],
  Kysta: [
    { lat: 48.5253726, lng: 21.7080805 },
    { lat: 48.5254441, lng: 21.7070649 },
    { lat: 48.5252405, lng: 21.7069816 },
    { lat: 48.5251934, lng: 21.7074473 },
    { lat: 48.5251136, lng: 21.7076499 },
    { lat: 48.5250329, lng: 21.7076666 },
    { lat: 48.5248808, lng: 21.7072461 },
    { lat: 48.5247739, lng: 21.7072431 },
    { lat: 48.5246465, lng: 21.7075292 },
    { lat: 48.5243729, lng: 21.7078126 },
    { lat: 48.5243123, lng: 21.7077762 },
    { lat: 48.524267, lng: 21.7075124 },
    { lat: 48.5240805, lng: 21.7071913 },
    { lat: 48.5240674, lng: 21.7062927 },
    { lat: 48.5238969, lng: 21.706186 },
    { lat: 48.5234941, lng: 21.7061909 },
    { lat: 48.5231809, lng: 21.7060919 },
    { lat: 48.5228995, lng: 21.7062093 },
    { lat: 48.5225897, lng: 21.7061148 },
    { lat: 48.5224187, lng: 21.7061562 },
    { lat: 48.5222698, lng: 21.70656 },
    { lat: 48.5220871, lng: 21.7065762 },
    { lat: 48.5220238, lng: 21.7064853 },
    { lat: 48.5220418, lng: 21.7062361 },
    { lat: 48.5219732, lng: 21.7060274 },
    { lat: 48.5218843, lng: 21.7059935 },
    { lat: 48.5216177, lng: 21.7062814 },
    { lat: 48.5215106, lng: 21.7061914 },
    { lat: 48.5213608, lng: 21.7055632 },
    { lat: 48.5213881, lng: 21.7053723 },
    { lat: 48.521235, lng: 21.7053249 },
    { lat: 48.5210512, lng: 21.7051016 },
    { lat: 48.5207877, lng: 21.7051295 },
    { lat: 48.5206515, lng: 21.7049683 },
    { lat: 48.5201669, lng: 21.7047294 },
    { lat: 48.5198075, lng: 21.7045889 },
    { lat: 48.5196499, lng: 21.704627 },
    { lat: 48.5194276, lng: 21.7043507 },
    { lat: 48.5188908, lng: 21.7043465 },
    { lat: 48.5186306, lng: 21.7040878 },
    { lat: 48.518469, lng: 21.7041016 },
    { lat: 48.5182723, lng: 21.7038646 },
    { lat: 48.5183606, lng: 21.7031412 },
    { lat: 48.5183514, lng: 21.7030094 },
    { lat: 48.5182263, lng: 21.702785 },
    { lat: 48.5165897, lng: 21.7041384 },
    { lat: 48.5161968, lng: 21.7027625 },
    { lat: 48.5161985, lng: 21.7021996 },
    { lat: 48.5159187, lng: 21.7014296 },
    { lat: 48.5157906, lng: 21.7013034 },
    { lat: 48.5157609, lng: 21.7013726 },
    { lat: 48.5147579, lng: 21.7018506 },
    { lat: 48.5142743, lng: 21.7023183 },
    { lat: 48.5137031, lng: 21.7027466 },
    { lat: 48.5135531, lng: 21.7026508 },
    { lat: 48.5134698, lng: 21.7024828 },
    { lat: 48.5129376, lng: 21.7027678 },
    { lat: 48.5129183, lng: 21.7032626 },
    { lat: 48.5123653, lng: 21.7038243 },
    { lat: 48.511572, lng: 21.7039043 },
    { lat: 48.5115078, lng: 21.7035704 },
    { lat: 48.5122594, lng: 21.70357 },
    { lat: 48.5126713, lng: 21.7029868 },
    { lat: 48.5122245, lng: 21.7028663 },
    { lat: 48.5116744, lng: 21.7024119 },
    { lat: 48.51132, lng: 21.7022481 },
    { lat: 48.5108979, lng: 21.7019082 },
    { lat: 48.5101671, lng: 21.7015103 },
    { lat: 48.508405, lng: 21.7003516 },
    { lat: 48.5049411, lng: 21.698299 },
    { lat: 48.5046156, lng: 21.6986157 },
    { lat: 48.5042711, lng: 21.6987682 },
    { lat: 48.5038645, lng: 21.6986357 },
    { lat: 48.5034558, lng: 21.6987421 },
    { lat: 48.5022199, lng: 21.6987285 },
    { lat: 48.501455, lng: 21.698822 },
    { lat: 48.5011704, lng: 21.6986129 },
    { lat: 48.4995384, lng: 21.6980145 },
    { lat: 48.4986245, lng: 21.6978391 },
    { lat: 48.4970585, lng: 21.6972246 },
    { lat: 48.4968024, lng: 21.696997 },
    { lat: 48.4964708, lng: 21.6968816 },
    { lat: 48.4953217, lng: 21.6972039 },
    { lat: 48.4949981, lng: 21.6973409 },
    { lat: 48.4946361, lng: 21.6976381 },
    { lat: 48.4945519, lng: 21.6976894 },
    { lat: 48.4939024, lng: 21.6983012 },
    { lat: 48.4927842, lng: 21.6986389 },
    { lat: 48.4918302, lng: 21.699184 },
    { lat: 48.4909985, lng: 21.6995452 },
    { lat: 48.490515, lng: 21.7002219 },
    { lat: 48.4902091, lng: 21.7007654 },
    { lat: 48.4899574, lng: 21.7008571 },
    { lat: 48.4893479, lng: 21.700917 },
    { lat: 48.4890373, lng: 21.7011563 },
    { lat: 48.4880137, lng: 21.7015449 },
    { lat: 48.4870065, lng: 21.7026546 },
    { lat: 48.4867272, lng: 21.7033102 },
    { lat: 48.4868557, lng: 21.7038119 },
    { lat: 48.486825, lng: 21.7047137 },
    { lat: 48.4864074, lng: 21.7055366 },
    { lat: 48.4860327, lng: 21.7057788 },
    { lat: 48.4856506, lng: 21.70588 },
    { lat: 48.4853296, lng: 21.7060735 },
    { lat: 48.4843867, lng: 21.7063264 },
    { lat: 48.4839089, lng: 21.7063388 },
    { lat: 48.4837404, lng: 21.7064718 },
    { lat: 48.4833403, lng: 21.7071131 },
    { lat: 48.4832066, lng: 21.70807 },
    { lat: 48.4831494, lng: 21.7088736 },
    { lat: 48.4829438, lng: 21.7102105 },
    { lat: 48.4827987, lng: 21.7104617 },
    { lat: 48.4835144, lng: 21.7109175 },
    { lat: 48.4838494, lng: 21.7116112 },
    { lat: 48.4845113, lng: 21.7123747 },
    { lat: 48.484835, lng: 21.7126527 },
    { lat: 48.4856662, lng: 21.7139701 },
    { lat: 48.4859125, lng: 21.7141778 },
    { lat: 48.4862582, lng: 21.7142644 },
    { lat: 48.4866852, lng: 21.7141684 },
    { lat: 48.4872663, lng: 21.7144385 },
    { lat: 48.4875812, lng: 21.7147647 },
    { lat: 48.4884044, lng: 21.7152477 },
    { lat: 48.4887696, lng: 21.7153534 },
    { lat: 48.489063, lng: 21.7155905 },
    { lat: 48.4907807, lng: 21.7179044 },
    { lat: 48.4916142, lng: 21.7191892 },
    { lat: 48.4919122, lng: 21.719895 },
    { lat: 48.4922602, lng: 21.7210737 },
    { lat: 48.4927562, lng: 21.7216925 },
    { lat: 48.4935762, lng: 21.7224757 },
    { lat: 48.4943874, lng: 21.7237251 },
    { lat: 48.4962161, lng: 21.7245719 },
    { lat: 48.4967271, lng: 21.7250402 },
    { lat: 48.4983067, lng: 21.7260765 },
    { lat: 48.4986164, lng: 21.7263365 },
    { lat: 48.4993734, lng: 21.7275429 },
    { lat: 48.4997122, lng: 21.7279168 },
    { lat: 48.5006408, lng: 21.7286835 },
    { lat: 48.5012093, lng: 21.7295794 },
    { lat: 48.5013339, lng: 21.7296889 },
    { lat: 48.5017806, lng: 21.729772 },
    { lat: 48.5029793, lng: 21.7297908 },
    { lat: 48.5040741, lng: 21.7303404 },
    { lat: 48.5051326, lng: 21.7302847 },
    { lat: 48.5052651, lng: 21.7309408 },
    { lat: 48.5075525, lng: 21.7308156 },
    { lat: 48.5082523, lng: 21.7306956 },
    { lat: 48.509913, lng: 21.7303581 },
    { lat: 48.511102, lng: 21.7297661 },
    { lat: 48.5131648, lng: 21.7289423 },
    { lat: 48.5161144, lng: 21.7280444 },
    { lat: 48.5179347, lng: 21.7276672 },
    { lat: 48.5190418, lng: 21.7271638 },
    { lat: 48.5196171, lng: 21.7269736 },
    { lat: 48.5230291, lng: 21.7264107 },
    { lat: 48.5240372, lng: 21.7261994 },
    { lat: 48.5244604, lng: 21.7260387 },
    { lat: 48.5243549, lng: 21.7249615 },
    { lat: 48.524308, lng: 21.7238495 },
    { lat: 48.5243572, lng: 21.7210844 },
    { lat: 48.5244517, lng: 21.7209615 },
    { lat: 48.5246515, lng: 21.7210596 },
    { lat: 48.5247444, lng: 21.721176 },
    { lat: 48.5247442, lng: 21.7212907 },
    { lat: 48.524851, lng: 21.7213665 },
    { lat: 48.525232, lng: 21.7211831 },
    { lat: 48.5252617, lng: 21.7191574 },
    { lat: 48.5254926, lng: 21.7170906 },
    { lat: 48.5258405, lng: 21.7145566 },
    { lat: 48.5260031, lng: 21.7124972 },
    { lat: 48.5259666, lng: 21.7110271 },
    { lat: 48.5251089, lng: 21.7099758 },
    { lat: 48.5252097, lng: 21.7092061 },
    { lat: 48.5253726, lng: 21.7080805 },
  ],
  Čerhov: [
    { lat: 48.4464451, lng: 21.6569006 },
    { lat: 48.4464839, lng: 21.6568436 },
    { lat: 48.4488091, lng: 21.6554763 },
    { lat: 48.4494761, lng: 21.65502 },
    { lat: 48.4497184, lng: 21.656093 },
    { lat: 48.4498484, lng: 21.6563964 },
    { lat: 48.4512683, lng: 21.6561498 },
    { lat: 48.4516155, lng: 21.6559737 },
    { lat: 48.4563347, lng: 21.6610816 },
    { lat: 48.4563858, lng: 21.6610536 },
    { lat: 48.4564942, lng: 21.6607016 },
    { lat: 48.4567791, lng: 21.6605488 },
    { lat: 48.4577534, lng: 21.6603353 },
    { lat: 48.4580442, lng: 21.6607671 },
    { lat: 48.4589657, lng: 21.6606409 },
    { lat: 48.4599618, lng: 21.6606136 },
    { lat: 48.4600476, lng: 21.660749 },
    { lat: 48.4613614, lng: 21.6608104 },
    { lat: 48.4614077, lng: 21.6614705 },
    { lat: 48.4621554, lng: 21.6618167 },
    { lat: 48.4619846, lng: 21.6622755 },
    { lat: 48.4622231, lng: 21.6623567 },
    { lat: 48.4632865, lng: 21.6624416 },
    { lat: 48.4642535, lng: 21.6614004 },
    { lat: 48.4656404, lng: 21.6605635 },
    { lat: 48.4663661, lng: 21.6604842 },
    { lat: 48.4670855, lng: 21.6606568 },
    { lat: 48.4673294, lng: 21.6619534 },
    { lat: 48.4675839, lng: 21.6626169 },
    { lat: 48.4684188, lng: 21.6625394 },
    { lat: 48.4695471, lng: 21.662611 },
    { lat: 48.4701417, lng: 21.6652482 },
    { lat: 48.4705352, lng: 21.6666292 },
    { lat: 48.4713872, lng: 21.6661433 },
    { lat: 48.4713065, lng: 21.6657838 },
    { lat: 48.4714596, lng: 21.6657069 },
    { lat: 48.4715848, lng: 21.6659229 },
    { lat: 48.4757034, lng: 21.6634462 },
    { lat: 48.4759877, lng: 21.6632271 },
    { lat: 48.4787583, lng: 21.6616088 },
    { lat: 48.4795106, lng: 21.6613052 },
    { lat: 48.4782002, lng: 21.6538471 },
    { lat: 48.4779367, lng: 21.6519662 },
    { lat: 48.4781688, lng: 21.6518149 },
    { lat: 48.4785492, lng: 21.6519369 },
    { lat: 48.4801687, lng: 21.6517442 },
    { lat: 48.4799296, lng: 21.6505924 },
    { lat: 48.481188, lng: 21.6501605 },
    { lat: 48.482326, lng: 21.649889 },
    { lat: 48.4824279, lng: 21.6519896 },
    { lat: 48.4840347, lng: 21.6517084 },
    { lat: 48.4849745, lng: 21.6514691 },
    { lat: 48.4849755, lng: 21.6509321 },
    { lat: 48.4849091, lng: 21.6503616 },
    { lat: 48.4850232, lng: 21.6502173 },
    { lat: 48.4850297, lng: 21.65013 },
    { lat: 48.4849541, lng: 21.649714 },
    { lat: 48.4847403, lng: 21.6494249 },
    { lat: 48.4846419, lng: 21.649005 },
    { lat: 48.4845158, lng: 21.648759 },
    { lat: 48.4840304, lng: 21.6484971 },
    { lat: 48.48376, lng: 21.6481572 },
    { lat: 48.4836667, lng: 21.6479556 },
    { lat: 48.4833943, lng: 21.6478332 },
    { lat: 48.4829996, lng: 21.6477969 },
    { lat: 48.4826379, lng: 21.6474159 },
    { lat: 48.4823425, lng: 21.6473482 },
    { lat: 48.4813148, lng: 21.6468915 },
    { lat: 48.4808447, lng: 21.6464922 },
    { lat: 48.4804673, lng: 21.6463906 },
    { lat: 48.4793437, lng: 21.6461999 },
    { lat: 48.4785878, lng: 21.6463369 },
    { lat: 48.4783203, lng: 21.6465037 },
    { lat: 48.4780507, lng: 21.6464418 },
    { lat: 48.4776611, lng: 21.6466343 },
    { lat: 48.4761218, lng: 21.6423259 },
    { lat: 48.4753165, lng: 21.6417288 },
    { lat: 48.4732655, lng: 21.6349991 },
    { lat: 48.473648, lng: 21.6347205 },
    { lat: 48.4735735, lng: 21.6344656 },
    { lat: 48.4721207, lng: 21.63005 },
    { lat: 48.4716333, lng: 21.6290289 },
    { lat: 48.4708545, lng: 21.6263526 },
    { lat: 48.4700813, lng: 21.6246901 },
    { lat: 48.4701366, lng: 21.6246532 },
    { lat: 48.4702964, lng: 21.624212 },
    { lat: 48.4703044, lng: 21.6241356 },
    { lat: 48.4702156, lng: 21.6240512 },
    { lat: 48.4702083, lng: 21.6239162 },
    { lat: 48.4704995, lng: 21.6235461 },
    { lat: 48.4705091, lng: 21.6234412 },
    { lat: 48.4704851, lng: 21.6233062 },
    { lat: 48.4702997, lng: 21.6233544 },
    { lat: 48.4701835, lng: 21.6233054 },
    { lat: 48.4702221, lng: 21.6229579 },
    { lat: 48.4700315, lng: 21.6225817 },
    { lat: 48.470119, lng: 21.6220854 },
    { lat: 48.4700946, lng: 21.6218168 },
    { lat: 48.4701291, lng: 21.6216444 },
    { lat: 48.4704219, lng: 21.6216905 },
    { lat: 48.4704364, lng: 21.6223303 },
    { lat: 48.4705716, lng: 21.6223767 },
    { lat: 48.4707155, lng: 21.622328 },
    { lat: 48.4708824, lng: 21.6224284 },
    { lat: 48.4710655, lng: 21.6230179 },
    { lat: 48.4712479, lng: 21.6228195 },
    { lat: 48.4715362, lng: 21.6227067 },
    { lat: 48.4717011, lng: 21.6231335 },
    { lat: 48.4716729, lng: 21.6235431 },
    { lat: 48.4717627, lng: 21.6237704 },
    { lat: 48.4718697, lng: 21.6237764 },
    { lat: 48.4719885, lng: 21.6236381 },
    { lat: 48.4719565, lng: 21.6232958 },
    { lat: 48.4720218, lng: 21.6230558 },
    { lat: 48.4720095, lng: 21.6228949 },
    { lat: 48.4722107, lng: 21.6220855 },
    { lat: 48.472215, lng: 21.62206 },
    { lat: 48.472219, lng: 21.621853 },
    { lat: 48.472198, lng: 21.621651 },
    { lat: 48.472106, lng: 21.621569 },
    { lat: 48.472013, lng: 21.621539 },
    { lat: 48.471945, lng: 21.621442 },
    { lat: 48.471945, lng: 21.621307 },
    { lat: 48.471944, lng: 21.621143 },
    { lat: 48.471824, lng: 21.621049 },
    { lat: 48.471755, lng: 21.620869 },
    { lat: 48.471641, lng: 21.620716 },
    { lat: 48.471556, lng: 21.620575 },
    { lat: 48.471488, lng: 21.620496 },
    { lat: 48.471361, lng: 21.620441 },
    { lat: 48.471321, lng: 21.620413 },
    { lat: 48.471283, lng: 21.620299 },
    { lat: 48.471106, lng: 21.620269 },
    { lat: 48.470981, lng: 21.620291 },
    { lat: 48.470923, lng: 21.620308 },
    { lat: 48.470834, lng: 21.620335 },
    { lat: 48.470667, lng: 21.620287 },
    { lat: 48.470246, lng: 21.620148 },
    { lat: 48.469958, lng: 21.620132 },
    { lat: 48.469826, lng: 21.620188 },
    { lat: 48.469652, lng: 21.620324 },
    { lat: 48.469549, lng: 21.620418 },
    { lat: 48.469379, lng: 21.62052 },
    { lat: 48.469168, lng: 21.62067 },
    { lat: 48.469047, lng: 21.620759 },
    { lat: 48.468714, lng: 21.620932 },
    { lat: 48.468545, lng: 21.620999 },
    { lat: 48.468372, lng: 21.621026 },
    { lat: 48.468177, lng: 21.621077 },
    { lat: 48.46792, lng: 21.621153 },
    { lat: 48.467764, lng: 21.621238 },
    { lat: 48.467681, lng: 21.621283 },
    { lat: 48.467458, lng: 21.621393 },
    { lat: 48.467466, lng: 21.621421 },
    { lat: 48.467418, lng: 21.621548 },
    { lat: 48.467131, lng: 21.621692 },
    { lat: 48.466961, lng: 21.62179 },
    { lat: 48.466852, lng: 21.621857 },
    { lat: 48.466662, lng: 21.621963 },
    { lat: 48.46661, lng: 21.621992 },
    { lat: 48.466524, lng: 21.622052 },
    { lat: 48.466461, lng: 21.622088 },
    { lat: 48.466331, lng: 21.622146 },
    { lat: 48.466285, lng: 21.622154 },
    { lat: 48.466155, lng: 21.622219 },
    { lat: 48.466087, lng: 21.62228 },
    { lat: 48.465915, lng: 21.622463 },
    { lat: 48.465834, lng: 21.622541 },
    { lat: 48.46576, lng: 21.622618 },
    { lat: 48.465679, lng: 21.6227 },
    { lat: 48.465606, lng: 21.622785 },
    { lat: 48.465513, lng: 21.62294 },
    { lat: 48.465411, lng: 21.623054 },
    { lat: 48.465355, lng: 21.623104 },
    { lat: 48.465303, lng: 21.623145 },
    { lat: 48.465121, lng: 21.623304 },
    { lat: 48.46501, lng: 21.623394 },
    { lat: 48.464953, lng: 21.623454 },
    { lat: 48.464876, lng: 21.623564 },
    { lat: 48.464845, lng: 21.623594 },
    { lat: 48.464521, lng: 21.623913 },
    { lat: 48.464357, lng: 21.624113 },
    { lat: 48.464173, lng: 21.624291 },
    { lat: 48.463976, lng: 21.624504 },
    { lat: 48.463871, lng: 21.624612 },
    { lat: 48.463736, lng: 21.624796 },
    { lat: 48.463669, lng: 21.624855 },
    { lat: 48.463601, lng: 21.624907 },
    { lat: 48.463477, lng: 21.625049 },
    { lat: 48.463349, lng: 21.625194 },
    { lat: 48.463203, lng: 21.625326 },
    { lat: 48.46308, lng: 21.625468 },
    { lat: 48.462935, lng: 21.625604 },
    { lat: 48.462866, lng: 21.625656 },
    { lat: 48.46279, lng: 21.625751 },
    { lat: 48.462656, lng: 21.625914 },
    { lat: 48.462488, lng: 21.626043 },
    { lat: 48.462355, lng: 21.626225 },
    { lat: 48.462117, lng: 21.626464 },
    { lat: 48.462005, lng: 21.626583 },
    { lat: 48.461792, lng: 21.626827 },
    { lat: 48.461663, lng: 21.626938 },
    { lat: 48.461548, lng: 21.627178 },
    { lat: 48.461436, lng: 21.627362 },
    { lat: 48.461322, lng: 21.627549 },
    { lat: 48.461227, lng: 21.627772 },
    { lat: 48.461124, lng: 21.627936 },
    { lat: 48.460965, lng: 21.628252 },
    { lat: 48.460903, lng: 21.628336 },
    { lat: 48.460796, lng: 21.628532 },
    { lat: 48.46063, lng: 21.62883 },
    { lat: 48.460505, lng: 21.629059 },
    { lat: 48.460388, lng: 21.629271 },
    { lat: 48.460154, lng: 21.629649 },
    { lat: 48.460127, lng: 21.629741 },
    { lat: 48.460038, lng: 21.6299 },
    { lat: 48.45991, lng: 21.630111 },
    { lat: 48.459884, lng: 21.630196 },
    { lat: 48.459846, lng: 21.630279 },
    { lat: 48.459717, lng: 21.630257 },
    { lat: 48.459649, lng: 21.630338 },
    { lat: 48.459587, lng: 21.630389 },
    { lat: 48.459494, lng: 21.630406 },
    { lat: 48.459436, lng: 21.630445 },
    { lat: 48.459406, lng: 21.630511 },
    { lat: 48.4594, lng: 21.630566 },
    { lat: 48.45941, lng: 21.630609 },
    { lat: 48.459482, lng: 21.63067 },
    { lat: 48.459532, lng: 21.630736 },
    { lat: 48.459451, lng: 21.630948 },
    { lat: 48.459446, lng: 21.631131 },
    { lat: 48.45933, lng: 21.631285 },
    { lat: 48.459265, lng: 21.631373 },
    { lat: 48.459202, lng: 21.631492 },
    { lat: 48.459122, lng: 21.631454 },
    { lat: 48.459079, lng: 21.631332 },
    { lat: 48.458901, lng: 21.631072 },
    { lat: 48.458772, lng: 21.630959 },
    { lat: 48.458634, lng: 21.630804 },
    { lat: 48.458479, lng: 21.630678 },
    { lat: 48.458415, lng: 21.63066 },
    { lat: 48.458373, lng: 21.630655 },
    { lat: 48.458237, lng: 21.63068 },
    { lat: 48.458067, lng: 21.630688 },
    { lat: 48.457799, lng: 21.630706 },
    { lat: 48.457639, lng: 21.630647 },
    { lat: 48.457466, lng: 21.630573 },
    { lat: 48.457419, lng: 21.630442 },
    { lat: 48.457454, lng: 21.630305 },
    { lat: 48.457458, lng: 21.630107 },
    { lat: 48.457386, lng: 21.630073 },
    { lat: 48.457336, lng: 21.629955 },
    { lat: 48.457351, lng: 21.62979 },
    { lat: 48.457373, lng: 21.629734 },
    { lat: 48.457521, lng: 21.629615 },
    { lat: 48.457541, lng: 21.629547 },
    { lat: 48.45753, lng: 21.6295 },
    { lat: 48.457504, lng: 21.629471 },
    { lat: 48.457363, lng: 21.629453 },
    { lat: 48.457243, lng: 21.629543 },
    { lat: 48.457178, lng: 21.629549 },
    { lat: 48.457008, lng: 21.629587 },
    { lat: 48.456917, lng: 21.629479 },
    { lat: 48.456941, lng: 21.629177 },
    { lat: 48.456814, lng: 21.629055 },
    { lat: 48.456782, lng: 21.628953 },
    { lat: 48.456799, lng: 21.628789 },
    { lat: 48.45676, lng: 21.628736 },
    { lat: 48.456663, lng: 21.62872 },
    { lat: 48.45663, lng: 21.628622 },
    { lat: 48.456637, lng: 21.628473 },
    { lat: 48.456629, lng: 21.62842 },
    { lat: 48.456548, lng: 21.62835 },
    { lat: 48.45648, lng: 21.628364 },
    { lat: 48.456421, lng: 21.628438 },
    { lat: 48.456333, lng: 21.628668 },
    { lat: 48.45632, lng: 21.628826 },
    { lat: 48.456353, lng: 21.628989 },
    { lat: 48.456366, lng: 21.629246 },
    { lat: 48.456304, lng: 21.629339 },
    { lat: 48.456218, lng: 21.629302 },
    { lat: 48.456121, lng: 21.629225 },
    { lat: 48.456046, lng: 21.629078 },
    { lat: 48.455966, lng: 21.629079 },
    { lat: 48.455923, lng: 21.629038 },
    { lat: 48.455858, lng: 21.628875 },
    { lat: 48.45576, lng: 21.628777 },
    { lat: 48.455676, lng: 21.628795 },
    { lat: 48.455573, lng: 21.628862 },
    { lat: 48.455388, lng: 21.629046 },
    { lat: 48.455124, lng: 21.629202 },
    { lat: 48.455052, lng: 21.629295 },
    { lat: 48.455037, lng: 21.629388 },
    { lat: 48.455117, lng: 21.629629 },
    { lat: 48.455114, lng: 21.629692 },
    { lat: 48.455115, lng: 21.629783 },
    { lat: 48.455161, lng: 21.6299 },
    { lat: 48.45516, lng: 21.629977 },
    { lat: 48.455137, lng: 21.630006 },
    { lat: 48.455091, lng: 21.630026 },
    { lat: 48.455039, lng: 21.630015 },
    { lat: 48.454962, lng: 21.630041 },
    { lat: 48.454843, lng: 21.630127 },
    { lat: 48.454774, lng: 21.630191 },
    { lat: 48.454686, lng: 21.630207 },
    { lat: 48.454591, lng: 21.630181 },
    { lat: 48.454497, lng: 21.630081 },
    { lat: 48.454348, lng: 21.630138 },
    { lat: 48.454278, lng: 21.630351 },
    { lat: 48.454225, lng: 21.630425 },
    { lat: 48.45411, lng: 21.630533 },
    { lat: 48.45407, lng: 21.630565 },
    { lat: 48.453984, lng: 21.630556 },
    { lat: 48.453946, lng: 21.630517 },
    { lat: 48.45387, lng: 21.630375 },
    { lat: 48.453839, lng: 21.630259 },
    { lat: 48.453798, lng: 21.630175 },
    { lat: 48.453786, lng: 21.630106 },
    { lat: 48.453846, lng: 21.629951 },
    { lat: 48.453833, lng: 21.629901 },
    { lat: 48.45375, lng: 21.629856 },
    { lat: 48.453669, lng: 21.629913 },
    { lat: 48.453575, lng: 21.629945 },
    { lat: 48.453501, lng: 21.629919 },
    { lat: 48.453425, lng: 21.62998 },
    { lat: 48.453297, lng: 21.630027 },
    { lat: 48.453214, lng: 21.630004 },
    { lat: 48.453208, lng: 21.62997 },
    { lat: 48.45318, lng: 21.629837 },
    { lat: 48.453147, lng: 21.629802 },
    { lat: 48.453091, lng: 21.629789 },
    { lat: 48.452988, lng: 21.629825 },
    { lat: 48.452944, lng: 21.629847 },
    { lat: 48.452867, lng: 21.62991 },
    { lat: 48.452826, lng: 21.629896 },
    { lat: 48.452787, lng: 21.629844 },
    { lat: 48.45275, lng: 21.629774 },
    { lat: 48.4527, lng: 21.629762 },
    { lat: 48.452627, lng: 21.629795 },
    { lat: 48.452569, lng: 21.629874 },
    { lat: 48.452525, lng: 21.629994 },
    { lat: 48.452492, lng: 21.62998 },
    { lat: 48.452428, lng: 21.62994 },
    { lat: 48.452348, lng: 21.629869 },
    { lat: 48.452273, lng: 21.629885 },
    { lat: 48.452194, lng: 21.629981 },
    { lat: 48.452154, lng: 21.630063 },
    { lat: 48.452157, lng: 21.630254 },
    { lat: 48.452126, lng: 21.630337 },
    { lat: 48.4521, lng: 21.630379 },
    { lat: 48.45208, lng: 21.630491 },
    { lat: 48.452049, lng: 21.630574 },
    { lat: 48.452011, lng: 21.630603 },
    { lat: 48.451951, lng: 21.630611 },
    { lat: 48.451882, lng: 21.630599 },
    { lat: 48.451725, lng: 21.630479 },
    { lat: 48.451692, lng: 21.630356 },
    { lat: 48.451616, lng: 21.630248 },
    { lat: 48.451536, lng: 21.630155 },
    { lat: 48.451495, lng: 21.630148 },
    { lat: 48.451442, lng: 21.630295 },
    { lat: 48.451374, lng: 21.630309 },
    { lat: 48.451267, lng: 21.630293 },
    { lat: 48.45122, lng: 21.630336 },
    { lat: 48.451174, lng: 21.63044 },
    { lat: 48.451074, lng: 21.630469 },
    { lat: 48.451013, lng: 21.630504 },
    { lat: 48.450932, lng: 21.630621 },
    { lat: 48.450854, lng: 21.63056 },
    { lat: 48.450814, lng: 21.630468 },
    { lat: 48.450761, lng: 21.63042 },
    { lat: 48.450722, lng: 21.630438 },
    { lat: 48.450677, lng: 21.630513 },
    { lat: 48.450669, lng: 21.630666 },
    { lat: 48.450709, lng: 21.630782 },
    { lat: 48.45069, lng: 21.630879 },
    { lat: 48.450625, lng: 21.630962 },
    { lat: 48.450529, lng: 21.631129 },
    { lat: 48.450531, lng: 21.63133 },
    { lat: 48.450442, lng: 21.631474 },
    { lat: 48.450388, lng: 21.631538 },
    { lat: 48.450297, lng: 21.631622 },
    { lat: 48.450219, lng: 21.631703 },
    { lat: 48.450152, lng: 21.631881 },
    { lat: 48.45015, lng: 21.632054 },
    { lat: 48.450167, lng: 21.632171 },
    { lat: 48.450287, lng: 21.632264 },
    { lat: 48.450162, lng: 21.632469 },
    { lat: 48.450053, lng: 21.632493 },
    { lat: 48.449974, lng: 21.632513 },
    { lat: 48.449882, lng: 21.632479 },
    { lat: 48.449738, lng: 21.632336 },
    { lat: 48.449554, lng: 21.632291 },
    { lat: 48.449528, lng: 21.632237 },
    { lat: 48.449545, lng: 21.632077 },
    { lat: 48.44951, lng: 21.631877 },
    { lat: 48.449482, lng: 21.631723 },
    { lat: 48.449501, lng: 21.631664 },
    { lat: 48.449541, lng: 21.63154 },
    { lat: 48.449529, lng: 21.631481 },
    { lat: 48.449462, lng: 21.631385 },
    { lat: 48.449371, lng: 21.631302 },
    { lat: 48.449257, lng: 21.631278 },
    { lat: 48.449209, lng: 21.631296 },
    { lat: 48.449173, lng: 21.631334 },
    { lat: 48.449179, lng: 21.631472 },
    { lat: 48.449226, lng: 21.6316 },
    { lat: 48.449153, lng: 21.631643 },
    { lat: 48.44913, lng: 21.631732 },
    { lat: 48.449127, lng: 21.631938 },
    { lat: 48.4491, lng: 21.632003 },
    { lat: 48.449077, lng: 21.632098 },
    { lat: 48.449083, lng: 21.63221 },
    { lat: 48.449178, lng: 21.632363 },
    { lat: 48.44932, lng: 21.632557 },
    { lat: 48.449277, lng: 21.63283 },
    { lat: 48.449226, lng: 21.632873 },
    { lat: 48.449185, lng: 21.632909 },
    { lat: 48.449042, lng: 21.632989 },
    { lat: 48.44898, lng: 21.632972 },
    { lat: 48.448926, lng: 21.632941 },
    { lat: 48.44884, lng: 21.63286 },
    { lat: 48.448808, lng: 21.632816 },
    { lat: 48.448733, lng: 21.632693 },
    { lat: 48.448643, lng: 21.632595 },
    { lat: 48.448588, lng: 21.632601 },
    { lat: 48.448532, lng: 21.632808 },
    { lat: 48.448577, lng: 21.632945 },
    { lat: 48.448594, lng: 21.633011 },
    { lat: 48.448614, lng: 21.633109 },
    { lat: 48.448682, lng: 21.6332 },
    { lat: 48.448663, lng: 21.633307 },
    { lat: 48.448571, lng: 21.633454 },
    { lat: 48.448477, lng: 21.633587 },
    { lat: 48.448499, lng: 21.633775 },
    { lat: 48.448482, lng: 21.633824 },
    { lat: 48.448449, lng: 21.633831 },
    { lat: 48.44839, lng: 21.633801 },
    { lat: 48.448366, lng: 21.633757 },
    { lat: 48.448249, lng: 21.633474 },
    { lat: 48.448236, lng: 21.633433 },
    { lat: 48.448248, lng: 21.633255 },
    { lat: 48.448178, lng: 21.633012 },
    { lat: 48.448147, lng: 21.632868 },
    { lat: 48.448059, lng: 21.632785 },
    { lat: 48.447941, lng: 21.632717 },
    { lat: 48.447878, lng: 21.632693 },
    { lat: 48.447826, lng: 21.632694 },
    { lat: 48.447757, lng: 21.632722 },
    { lat: 48.447678, lng: 21.632781 },
    { lat: 48.447661, lng: 21.632926 },
    { lat: 48.447611, lng: 21.633122 },
    { lat: 48.447632, lng: 21.633201 },
    { lat: 48.447763, lng: 21.633351 },
    { lat: 48.447851, lng: 21.633661 },
    { lat: 48.447871, lng: 21.633756 },
    { lat: 48.447879, lng: 21.633842 },
    { lat: 48.447875, lng: 21.633924 },
    { lat: 48.44782, lng: 21.633976 },
    { lat: 48.447717, lng: 21.633954 },
    { lat: 48.447678, lng: 21.633906 },
    { lat: 48.447646, lng: 21.633824 },
    { lat: 48.447594, lng: 21.633822 },
    { lat: 48.447483, lng: 21.633876 },
    { lat: 48.447448, lng: 21.634033 },
    { lat: 48.447459, lng: 21.634087 },
    { lat: 48.447577, lng: 21.634294 },
    { lat: 48.447682, lng: 21.634297 },
    { lat: 48.447809, lng: 21.634276 },
    { lat: 48.447871, lng: 21.634273 },
    { lat: 48.447944, lng: 21.634284 },
    { lat: 48.448119, lng: 21.634373 },
    { lat: 48.448138, lng: 21.634409 },
    { lat: 48.448092, lng: 21.634831 },
    { lat: 48.448172, lng: 21.635007 },
    { lat: 48.448253, lng: 21.635097 },
    { lat: 48.448364, lng: 21.635282 },
    { lat: 48.448429, lng: 21.635365 },
    { lat: 48.44844, lng: 21.635528 },
    { lat: 48.448401, lng: 21.635652 },
    { lat: 48.44835, lng: 21.635817 },
    { lat: 48.448442, lng: 21.63591 },
    { lat: 48.448515, lng: 21.635834 },
    { lat: 48.448601, lng: 21.635776 },
    { lat: 48.448683, lng: 21.63578 },
    { lat: 48.448716, lng: 21.635816 },
    { lat: 48.448718, lng: 21.635923 },
    { lat: 48.44865, lng: 21.636084 },
    { lat: 48.448523, lng: 21.636388 },
    { lat: 48.448558, lng: 21.636566 },
    { lat: 48.448605, lng: 21.636599 },
    { lat: 48.448727, lng: 21.63669 },
    { lat: 48.448781, lng: 21.636772 },
    { lat: 48.448784, lng: 21.63692 },
    { lat: 48.448735, lng: 21.637007 },
    { lat: 48.448648, lng: 21.637077 },
    { lat: 48.448418, lng: 21.637348 },
    { lat: 48.448403, lng: 21.637507 },
    { lat: 48.448382, lng: 21.637657 },
    { lat: 48.44836, lng: 21.637743 },
    { lat: 48.448334, lng: 21.637903 },
    { lat: 48.448311, lng: 21.638002 },
    { lat: 48.448297, lng: 21.638058 },
    { lat: 48.448037, lng: 21.638644 },
    { lat: 48.447991, lng: 21.638776 },
    { lat: 48.44772, lng: 21.63935 },
    { lat: 48.447569, lng: 21.639623 },
    { lat: 48.447525, lng: 21.639685 },
    { lat: 48.447394, lng: 21.639834 },
    { lat: 48.44701, lng: 21.640128 },
    { lat: 48.446615, lng: 21.640434 },
    { lat: 48.446384, lng: 21.640597 },
    { lat: 48.445994, lng: 21.640868 },
    { lat: 48.44587, lng: 21.640997 },
    { lat: 48.445627, lng: 21.641198 },
    { lat: 48.445343, lng: 21.641401 },
    { lat: 48.445139, lng: 21.641617 },
    { lat: 48.444927, lng: 21.64176 },
    { lat: 48.444263, lng: 21.642267 },
    { lat: 48.443795, lng: 21.642632 },
    { lat: 48.443431, lng: 21.642913 },
    { lat: 48.443052, lng: 21.643205 },
    { lat: 48.442821, lng: 21.643341 },
    { lat: 48.442532, lng: 21.643791 },
    { lat: 48.44234, lng: 21.643853 },
    { lat: 48.442084, lng: 21.64362 },
    { lat: 48.44199, lng: 21.643703 },
    { lat: 48.441885, lng: 21.643853 },
    { lat: 48.44186, lng: 21.643897 },
    { lat: 48.441823, lng: 21.644014 },
    { lat: 48.441778, lng: 21.644266 },
    { lat: 48.441513, lng: 21.64442 },
    { lat: 48.441319, lng: 21.644595 },
    { lat: 48.4413766, lng: 21.6447089 },
    { lat: 48.4416063, lng: 21.6454504 },
    { lat: 48.4420318, lng: 21.6461443 },
    { lat: 48.4425319, lng: 21.6467342 },
    { lat: 48.4434322, lng: 21.6480824 },
    { lat: 48.4438508, lng: 21.6488086 },
    { lat: 48.4440717, lng: 21.6493132 },
    { lat: 48.4444239, lng: 21.6502906 },
    { lat: 48.4446861, lng: 21.6512287 },
    { lat: 48.4453672, lng: 21.6529932 },
    { lat: 48.4454176, lng: 21.6533498 },
    { lat: 48.4455563, lng: 21.653726 },
    { lat: 48.446055, lng: 21.6561003 },
    { lat: 48.4464451, lng: 21.6569006 },
  ],
  Bačkov: [
    { lat: 48.7566513, lng: 21.6594508 },
    { lat: 48.7563527, lng: 21.6579584 },
    { lat: 48.7559404, lng: 21.6539196 },
    { lat: 48.7544854, lng: 21.6503016 },
    { lat: 48.7538094, lng: 21.6478107 },
    { lat: 48.7536668, lng: 21.6476599 },
    { lat: 48.7517792, lng: 21.6466983 },
    { lat: 48.7516247, lng: 21.6465996 },
    { lat: 48.7513791, lng: 21.6462606 },
    { lat: 48.7502996, lng: 21.6440781 },
    { lat: 48.7482182, lng: 21.6393365 },
    { lat: 48.7479248, lng: 21.6388185 },
    { lat: 48.7481514, lng: 21.6383813 },
    { lat: 48.7481634, lng: 21.6382112 },
    { lat: 48.7482083, lng: 21.6382055 },
    { lat: 48.7489712, lng: 21.636573 },
    { lat: 48.7492543, lng: 21.6361926 },
    { lat: 48.7493288, lng: 21.6361586 },
    { lat: 48.749617, lng: 21.635719 },
    { lat: 48.7496828, lng: 21.6343536 },
    { lat: 48.7497734, lng: 21.634329 },
    { lat: 48.749986, lng: 21.6324584 },
    { lat: 48.7503244, lng: 21.6306519 },
    { lat: 48.7503648, lng: 21.6302432 },
    { lat: 48.7504381, lng: 21.630005 },
    { lat: 48.7505656, lng: 21.6297303 },
    { lat: 48.7511804, lng: 21.629956 },
    { lat: 48.7512773, lng: 21.6302896 },
    { lat: 48.7515098, lng: 21.6303509 },
    { lat: 48.7515602, lng: 21.6298535 },
    { lat: 48.7517065, lng: 21.6298599 },
    { lat: 48.7517277, lng: 21.6294828 },
    { lat: 48.7518466, lng: 21.6290246 },
    { lat: 48.7519819, lng: 21.6287083 },
    { lat: 48.7518885, lng: 21.6275722 },
    { lat: 48.7523244, lng: 21.6273403 },
    { lat: 48.7526006, lng: 21.6273635 },
    { lat: 48.7527068, lng: 21.6270944 },
    { lat: 48.7531364, lng: 21.6268415 },
    { lat: 48.7539847, lng: 21.6264729 },
    { lat: 48.755689, lng: 21.6254062 },
    { lat: 48.7562745, lng: 21.6248549 },
    { lat: 48.7570573, lng: 21.6243988 },
    { lat: 48.7582277, lng: 21.6243222 },
    { lat: 48.7592729, lng: 21.6239144 },
    { lat: 48.7605879, lng: 21.6228554 },
    { lat: 48.7612653, lng: 21.6224886 },
    { lat: 48.7628635, lng: 21.6217796 },
    { lat: 48.7632125, lng: 21.6217054 },
    { lat: 48.7634927, lng: 21.6217458 },
    { lat: 48.7639286, lng: 21.6216393 },
    { lat: 48.7644396, lng: 21.6216857 },
    { lat: 48.7653159, lng: 21.6216013 },
    { lat: 48.7660676, lng: 21.6214049 },
    { lat: 48.767528, lng: 21.6211963 },
    { lat: 48.7689894, lng: 21.620706 },
    { lat: 48.769713, lng: 21.619574 },
    { lat: 48.7703757, lng: 21.6182828 },
    { lat: 48.7710273, lng: 21.6172677 },
    { lat: 48.7713965, lng: 21.6168679 },
    { lat: 48.7717374, lng: 21.6166619 },
    { lat: 48.7722005, lng: 21.6165283 },
    { lat: 48.7724718, lng: 21.6165763 },
    { lat: 48.77314, lng: 21.6160852 },
    { lat: 48.773568, lng: 21.615737 },
    { lat: 48.773568, lng: 21.615736 },
    { lat: 48.773728, lng: 21.615538 },
    { lat: 48.773843, lng: 21.615309 },
    { lat: 48.774033, lng: 21.614922 },
    { lat: 48.77413, lng: 21.614572 },
    { lat: 48.774162, lng: 21.614127 },
    { lat: 48.774188, lng: 21.61372 },
    { lat: 48.774272, lng: 21.612966 },
    { lat: 48.77432, lng: 21.612724 },
    { lat: 48.774609, lng: 21.612287 },
    { lat: 48.774784, lng: 21.611906 },
    { lat: 48.775056, lng: 21.611623 },
    { lat: 48.775367, lng: 21.611408 },
    { lat: 48.775608, lng: 21.611074 },
    { lat: 48.775897, lng: 21.610426 },
    { lat: 48.776084, lng: 21.609948 },
    { lat: 48.776113, lng: 21.609511 },
    { lat: 48.776122, lng: 21.609401 },
    { lat: 48.776273, lng: 21.60908 },
    { lat: 48.776337, lng: 21.608927 },
    { lat: 48.776464, lng: 21.608495 },
    { lat: 48.776532, lng: 21.608053 },
    { lat: 48.776552, lng: 21.607977 },
    { lat: 48.776727, lng: 21.607747 },
    { lat: 48.777167, lng: 21.607337 },
    { lat: 48.77744, lng: 21.607072 },
    { lat: 48.777741, lng: 21.606864 },
    { lat: 48.777834, lng: 21.606618 },
    { lat: 48.777869, lng: 21.606324 },
    { lat: 48.778111, lng: 21.606009 },
    { lat: 48.778419, lng: 21.605694 },
    { lat: 48.778678, lng: 21.605356 },
    { lat: 48.778861, lng: 21.605074 },
    { lat: 48.779014, lng: 21.604756 },
    { lat: 48.779183, lng: 21.604264 },
    { lat: 48.779196, lng: 21.603826 },
    { lat: 48.779304, lng: 21.603368 },
    { lat: 48.779585, lng: 21.603004 },
    { lat: 48.77991, lng: 21.60282 },
    { lat: 48.780038, lng: 21.602729 },
    { lat: 48.780345, lng: 21.602501 },
    { lat: 48.780435, lng: 21.602431 },
    { lat: 48.780719, lng: 21.601938 },
    { lat: 48.780771, lng: 21.601757 },
    { lat: 48.780999, lng: 21.60132 },
    { lat: 48.781017, lng: 21.600812 },
    { lat: 48.781029, lng: 21.600528 },
    { lat: 48.78098, lng: 21.599947 },
    { lat: 48.780927, lng: 21.599461 },
    { lat: 48.780948, lng: 21.598832 },
    { lat: 48.781069, lng: 21.598077 },
    { lat: 48.781158, lng: 21.59772 },
    { lat: 48.781239, lng: 21.597429 },
    { lat: 48.78132, lng: 21.597153 },
    { lat: 48.781345, lng: 21.596851 },
    { lat: 48.781397, lng: 21.596626 },
    { lat: 48.781465, lng: 21.596327 },
    { lat: 48.781601, lng: 21.596069 },
    { lat: 48.781713, lng: 21.595693 },
    { lat: 48.782029, lng: 21.595397 },
    { lat: 48.782316, lng: 21.595123 },
    { lat: 48.782555, lng: 21.594709 },
    { lat: 48.782578, lng: 21.594383 },
    { lat: 48.782554, lng: 21.594377 },
    { lat: 48.78232, lng: 21.594222 },
    { lat: 48.781949, lng: 21.593268 },
    { lat: 48.781839, lng: 21.592485 },
    { lat: 48.781431, lng: 21.592019 },
    { lat: 48.781209, lng: 21.591633 },
    { lat: 48.780701, lng: 21.591126 },
    { lat: 48.78049, lng: 21.590843 },
    { lat: 48.780566, lng: 21.590128 },
    { lat: 48.780645, lng: 21.589696 },
    { lat: 48.780702, lng: 21.58908 },
    { lat: 48.78077, lng: 21.588761 },
    { lat: 48.780775, lng: 21.586132 },
    { lat: 48.780619, lng: 21.585862 },
    { lat: 48.78063, lng: 21.585593 },
    { lat: 48.780656, lng: 21.585131 },
    { lat: 48.780589, lng: 21.584698 },
    { lat: 48.780536, lng: 21.584219 },
    { lat: 48.780528, lng: 21.584151 },
    { lat: 48.7805079, lng: 21.5839721 },
    { lat: 48.780467, lng: 21.583608 },
    { lat: 48.780807, lng: 21.583232 },
    { lat: 48.781363, lng: 21.582905 },
    { lat: 48.781383, lng: 21.582887 },
    { lat: 48.782086, lng: 21.583322 },
    { lat: 48.78251, lng: 21.583828 },
    { lat: 48.782828, lng: 21.583902 },
    { lat: 48.783313, lng: 21.583783 },
    { lat: 48.783334, lng: 21.583818 },
    { lat: 48.783653, lng: 21.583989 },
    { lat: 48.784015, lng: 21.584064 },
    { lat: 48.784302, lng: 21.583827 },
    { lat: 48.78431, lng: 21.583791 },
    { lat: 48.784732, lng: 21.584027 },
    { lat: 48.785042, lng: 21.584147 },
    { lat: 48.785775, lng: 21.583916 },
    { lat: 48.786039, lng: 21.583263 },
    { lat: 48.786124, lng: 21.583094 },
    { lat: 48.786424, lng: 21.582644 },
    { lat: 48.786588, lng: 21.582289 },
    { lat: 48.786726, lng: 21.582028 },
    { lat: 48.786707, lng: 21.582018 },
    { lat: 48.786595, lng: 21.581979 },
    { lat: 48.786768, lng: 21.580573 },
    { lat: 48.788015, lng: 21.57972 },
    { lat: 48.789195, lng: 21.578733 },
    { lat: 48.789527, lng: 21.578586 },
    { lat: 48.790301, lng: 21.577729 },
    { lat: 48.790716, lng: 21.576615 },
    { lat: 48.790972, lng: 21.57593 },
    { lat: 48.790668, lng: 21.575052 },
    { lat: 48.790905, lng: 21.5731 },
    { lat: 48.791416, lng: 21.571527 },
    { lat: 48.791688, lng: 21.570543 },
    { lat: 48.791814, lng: 21.570025 },
    { lat: 48.791895, lng: 21.56952 },
    { lat: 48.791616, lng: 21.568378 },
    { lat: 48.791552, lng: 21.567634 },
    { lat: 48.791636, lng: 21.566809 },
    { lat: 48.792053, lng: 21.566357 },
    { lat: 48.792963, lng: 21.566337 },
    { lat: 48.793624, lng: 21.565484 },
    { lat: 48.793811, lng: 21.565114 },
    { lat: 48.79399, lng: 21.563579 },
    { lat: 48.793988, lng: 21.562408 },
    { lat: 48.793821, lng: 21.561665 },
    { lat: 48.793724, lng: 21.561361 },
    { lat: 48.793417, lng: 21.560482 },
    { lat: 48.792682, lng: 21.558002 },
    { lat: 48.792274, lng: 21.557378 },
    { lat: 48.792437, lng: 21.555262 },
    { lat: 48.792523, lng: 21.553603 },
    { lat: 48.792506, lng: 21.552686 },
    { lat: 48.792297, lng: 21.551985 },
    { lat: 48.791865, lng: 21.55114 },
    { lat: 48.791858, lng: 21.551059 },
    { lat: 48.791807, lng: 21.550465 },
    { lat: 48.791799, lng: 21.550356 },
    { lat: 48.791747, lng: 21.549744 },
    { lat: 48.791963, lng: 21.54809 },
    { lat: 48.792089, lng: 21.546576 },
    { lat: 48.792795, lng: 21.545559 },
    { lat: 48.793062, lng: 21.544969 },
    { lat: 48.7927022, lng: 21.5449913 },
    { lat: 48.7923429, lng: 21.5452152 },
    { lat: 48.7909507, lng: 21.5466313 },
    { lat: 48.7895043, lng: 21.5483577 },
    { lat: 48.7878941, lng: 21.5499851 },
    { lat: 48.7855923, lng: 21.5520208 },
    { lat: 48.7843267, lng: 21.5528649 },
    { lat: 48.7840079, lng: 21.5530126 },
    { lat: 48.7832473, lng: 21.5536074 },
    { lat: 48.7815781, lng: 21.5546199 },
    { lat: 48.7795024, lng: 21.5556637 },
    { lat: 48.7789282, lng: 21.5564451 },
    { lat: 48.7790998, lng: 21.5576623 },
    { lat: 48.7794686, lng: 21.5585656 },
    { lat: 48.779317, lng: 21.5590171 },
    { lat: 48.7791475, lng: 21.5597526 },
    { lat: 48.7788033, lng: 21.5607474 },
    { lat: 48.778516, lng: 21.5618526 },
    { lat: 48.7785507, lng: 21.5624051 },
    { lat: 48.7784871, lng: 21.5630164 },
    { lat: 48.7783357, lng: 21.5635606 },
    { lat: 48.7780271, lng: 21.5642651 },
    { lat: 48.7776674, lng: 21.5648367 },
    { lat: 48.7771865, lng: 21.565776 },
    { lat: 48.7767039, lng: 21.566263 },
    { lat: 48.775891, lng: 21.5658803 },
    { lat: 48.7747188, lng: 21.5659327 },
    { lat: 48.7739472, lng: 21.5658587 },
    { lat: 48.7728684, lng: 21.5662233 },
    { lat: 48.771694, lng: 21.5671088 },
    { lat: 48.7709002, lng: 21.5670548 },
    { lat: 48.7703169, lng: 21.5671541 },
    { lat: 48.7685144, lng: 21.5683929 },
    { lat: 48.7674958, lng: 21.5692617 },
    { lat: 48.7671511, lng: 21.569722 },
    { lat: 48.7666431, lng: 21.5700297 },
    { lat: 48.765713, lng: 21.5704384 },
    { lat: 48.7654149, lng: 21.57032 },
    { lat: 48.7647949, lng: 21.5703642 },
    { lat: 48.764002, lng: 21.571252 },
    { lat: 48.7631847, lng: 21.5725557 },
    { lat: 48.762529, lng: 21.5737292 },
    { lat: 48.7610569, lng: 21.5745118 },
    { lat: 48.7592464, lng: 21.5751828 },
    { lat: 48.7580759, lng: 21.5757749 },
    { lat: 48.757225, lng: 21.5758107 },
    { lat: 48.7568406, lng: 21.5764191 },
    { lat: 48.7555313, lng: 21.5774761 },
    { lat: 48.7542799, lng: 21.5772274 },
    { lat: 48.753905, lng: 21.5773417 },
    { lat: 48.7539707, lng: 21.5773575 },
    { lat: 48.7535425, lng: 21.5775322 },
    { lat: 48.7531503, lng: 21.5780745 },
    { lat: 48.752351, lng: 21.5785352 },
    { lat: 48.7518652, lng: 21.5787083 },
    { lat: 48.7513142, lng: 21.5793555 },
    { lat: 48.7508763, lng: 21.5795876 },
    { lat: 48.7505648, lng: 21.5796779 },
    { lat: 48.7500602, lng: 21.5796946 },
    { lat: 48.7499107, lng: 21.5795826 },
    { lat: 48.7496438, lng: 21.5800547 },
    { lat: 48.7494899, lng: 21.5800632 },
    { lat: 48.7488161, lng: 21.5805097 },
    { lat: 48.748723, lng: 21.580639 },
    { lat: 48.7486945, lng: 21.5805877 },
    { lat: 48.7480768, lng: 21.5811518 },
    { lat: 48.7478132, lng: 21.581177 },
    { lat: 48.7473727, lng: 21.5815193 },
    { lat: 48.7459748, lng: 21.5818636 },
    { lat: 48.7454945, lng: 21.5825511 },
    { lat: 48.7449302, lng: 21.5832144 },
    { lat: 48.7443398, lng: 21.5843475 },
    { lat: 48.7435737, lng: 21.5849909 },
    { lat: 48.7429539, lng: 21.5861957 },
    { lat: 48.7412973, lng: 21.5862284 },
    { lat: 48.740606, lng: 21.5870523 },
    { lat: 48.7397994, lng: 21.5876248 },
    { lat: 48.7389913, lng: 21.5883353 },
    { lat: 48.7385196, lng: 21.5886094 },
    { lat: 48.7379241, lng: 21.5891594 },
    { lat: 48.7371525, lng: 21.5902899 },
    { lat: 48.7360394, lng: 21.5927336 },
    { lat: 48.7343555, lng: 21.5946031 },
    { lat: 48.7340227, lng: 21.5947681 },
    { lat: 48.7339699, lng: 21.5949707 },
    { lat: 48.73305, lng: 21.59672 },
    { lat: 48.7330425, lng: 21.5966542 },
    { lat: 48.7329292, lng: 21.5967113 },
    { lat: 48.7329736, lng: 21.5972873 },
    { lat: 48.7333813, lng: 21.597829 },
    { lat: 48.7329368, lng: 21.5990322 },
    { lat: 48.7300237, lng: 21.6039468 },
    { lat: 48.7267559, lng: 21.6095929 },
    { lat: 48.7267109, lng: 21.609671 },
    { lat: 48.725369, lng: 21.611953 },
    { lat: 48.7232789, lng: 21.6141929 },
    { lat: 48.7242357, lng: 21.6175717 },
    { lat: 48.724182, lng: 21.6180658 },
    { lat: 48.724478, lng: 21.6194895 },
    { lat: 48.7252404, lng: 21.6214582 },
    { lat: 48.7254168, lng: 21.6220967 },
    { lat: 48.725624, lng: 21.6245799 },
    { lat: 48.7260143, lng: 21.6263623 },
    { lat: 48.7260466, lng: 21.6271631 },
    { lat: 48.7258977, lng: 21.6289034 },
    { lat: 48.7259267, lng: 21.6326388 },
    { lat: 48.7258843, lng: 21.6330178 },
    { lat: 48.7255262, lng: 21.6345118 },
    { lat: 48.7254477, lng: 21.6350926 },
    { lat: 48.7253673, lng: 21.6387397 },
    { lat: 48.7250299, lng: 21.6393981 },
    { lat: 48.725024, lng: 21.6407758 },
    { lat: 48.7250942, lng: 21.6415671 },
    { lat: 48.7261053, lng: 21.6478906 },
    { lat: 48.7261523, lng: 21.649266 },
    { lat: 48.726978, lng: 21.6490613 },
    { lat: 48.7279905, lng: 21.6490561 },
    { lat: 48.72849, lng: 21.6490971 },
    { lat: 48.7322746, lng: 21.6497753 },
    { lat: 48.732661, lng: 21.6497995 },
    { lat: 48.7335242, lng: 21.6496815 },
    { lat: 48.7337961, lng: 21.6497863 },
    { lat: 48.7330645, lng: 21.6539585 },
    { lat: 48.73652, lng: 21.6531468 },
    { lat: 48.7365058, lng: 21.6527898 },
    { lat: 48.7366282, lng: 21.6503733 },
    { lat: 48.7370255, lng: 21.650461 },
    { lat: 48.73739, lng: 21.650461 },
    { lat: 48.7377613, lng: 21.65033 },
    { lat: 48.7394154, lng: 21.6492373 },
    { lat: 48.7408685, lng: 21.6485211 },
    { lat: 48.7412837, lng: 21.6481079 },
    { lat: 48.7414826, lng: 21.6480989 },
    { lat: 48.7415734, lng: 21.6483738 },
    { lat: 48.7417425, lng: 21.6485874 },
    { lat: 48.7419579, lng: 21.6491014 },
    { lat: 48.7420651, lng: 21.6491567 },
    { lat: 48.742106, lng: 21.6496165 },
    { lat: 48.7422216, lng: 21.649729 },
    { lat: 48.7427833, lng: 21.6498192 },
    { lat: 48.7429305, lng: 21.6499362 },
    { lat: 48.7429234, lng: 21.6506631 },
    { lat: 48.7430738, lng: 21.6508159 },
    { lat: 48.7434812, lng: 21.6506117 },
    { lat: 48.7435965, lng: 21.6508786 },
    { lat: 48.7435893, lng: 21.6509849 },
    { lat: 48.7433469, lng: 21.6513338 },
    { lat: 48.743185, lng: 21.6519536 },
    { lat: 48.7432052, lng: 21.6520314 },
    { lat: 48.7433337, lng: 21.6521239 },
    { lat: 48.7437263, lng: 21.6519549 },
    { lat: 48.7438656, lng: 21.652072 },
    { lat: 48.7438518, lng: 21.6524311 },
    { lat: 48.7436547, lng: 21.6526681 },
    { lat: 48.7436521, lng: 21.6529869 },
    { lat: 48.7438481, lng: 21.6531179 },
    { lat: 48.744192, lng: 21.6527734 },
    { lat: 48.7444653, lng: 21.6531762 },
    { lat: 48.7444353, lng: 21.6536486 },
    { lat: 48.7449116, lng: 21.6539071 },
    { lat: 48.7451613, lng: 21.6548886 },
    { lat: 48.7457022, lng: 21.6555673 },
    { lat: 48.7456948, lng: 21.6560045 },
    { lat: 48.7457707, lng: 21.6561409 },
    { lat: 48.74624, lng: 21.6560248 },
    { lat: 48.7465568, lng: 21.6567872 },
    { lat: 48.7469148, lng: 21.6574103 },
    { lat: 48.7472804, lng: 21.6577767 },
    { lat: 48.7473227, lng: 21.6579267 },
    { lat: 48.7472906, lng: 21.6585184 },
    { lat: 48.7477178, lng: 21.6597419 },
    { lat: 48.7480838, lng: 21.6601999 },
    { lat: 48.7481795, lng: 21.6605693 },
    { lat: 48.7477708, lng: 21.6622092 },
    { lat: 48.7483551, lng: 21.6620596 },
    { lat: 48.7491995, lng: 21.6613811 },
    { lat: 48.7499282, lng: 21.6609501 },
    { lat: 48.7508676, lng: 21.6608249 },
    { lat: 48.7523649, lng: 21.6602049 },
    { lat: 48.7527453, lng: 21.6602078 },
    { lat: 48.7536138, lng: 21.6599541 },
    { lat: 48.7538619, lng: 21.6603872 },
    { lat: 48.7544516, lng: 21.6601495 },
    { lat: 48.7559393, lng: 21.6597111 },
    { lat: 48.7563049, lng: 21.6595784 },
    { lat: 48.7563545, lng: 21.6595051 },
    { lat: 48.7565352, lng: 21.6594934 },
    { lat: 48.7566513, lng: 21.6594508 },
  ],
  MalýKamenec: [
    { lat: 48.3534995, lng: 21.7864727 },
    { lat: 48.3545777, lng: 21.7846959 },
    { lat: 48.3550413, lng: 21.7840654 },
    { lat: 48.3553202, lng: 21.7838075 },
    { lat: 48.3558405, lng: 21.7836544 },
    { lat: 48.3561744, lng: 21.7833444 },
    { lat: 48.3565819, lng: 21.7831435 },
    { lat: 48.356877, lng: 21.7831142 },
    { lat: 48.3571036, lng: 21.7831693 },
    { lat: 48.3572239, lng: 21.7833411 },
    { lat: 48.3573348, lng: 21.7833621 },
    { lat: 48.3574489, lng: 21.7836376 },
    { lat: 48.357969, lng: 21.7837236 },
    { lat: 48.3583471, lng: 21.7836416 },
    { lat: 48.3585289, lng: 21.7835444 },
    { lat: 48.3590698, lng: 21.7830217 },
    { lat: 48.3591657, lng: 21.7828589 },
    { lat: 48.3591284, lng: 21.7828155 },
    { lat: 48.3592054, lng: 21.782535 },
    { lat: 48.3593276, lng: 21.7823519 },
    { lat: 48.3601875, lng: 21.781473 },
    { lat: 48.3606455, lng: 21.7811304 },
    { lat: 48.3607996, lng: 21.7810921 },
    { lat: 48.3610188, lng: 21.7808692 },
    { lat: 48.3614273, lng: 21.7798981 },
    { lat: 48.3610608, lng: 21.7790673 },
    { lat: 48.361142, lng: 21.7789475 },
    { lat: 48.3608825, lng: 21.7785332 },
    { lat: 48.3603889, lng: 21.7774465 },
    { lat: 48.3600282, lng: 21.7768341 },
    { lat: 48.3603906, lng: 21.776367 },
    { lat: 48.3608515, lng: 21.7759729 },
    { lat: 48.3629519, lng: 21.7749063 },
    { lat: 48.3637422, lng: 21.7746658 },
    { lat: 48.3434487, lng: 21.7649462 },
    { lat: 48.3426483, lng: 21.7654308 },
    { lat: 48.3422085, lng: 21.7657815 },
    { lat: 48.3415158, lng: 21.766506 },
    { lat: 48.3410544, lng: 21.7668449 },
    { lat: 48.3406817, lng: 21.7668369 },
    { lat: 48.3403676, lng: 21.7666139 },
    { lat: 48.3399169, lng: 21.7659191 },
    { lat: 48.3396568, lng: 21.7656168 },
    { lat: 48.3391589, lng: 21.765675 },
    { lat: 48.339025, lng: 21.765707 },
    { lat: 48.3391076, lng: 21.7669544 },
    { lat: 48.339129, lng: 21.767278 },
    { lat: 48.339133, lng: 21.767397 },
    { lat: 48.33928, lng: 21.770508 },
    { lat: 48.33942, lng: 21.773109 },
    { lat: 48.339459, lng: 21.773494 },
    { lat: 48.339474, lng: 21.773684 },
    { lat: 48.339526, lng: 21.77397 },
    { lat: 48.339611, lng: 21.774276 },
    { lat: 48.339776, lng: 21.774748 },
    { lat: 48.339953, lng: 21.775211 },
    { lat: 48.340062, lng: 21.775485 },
    { lat: 48.340137, lng: 21.775726 },
    { lat: 48.340218, lng: 21.77604 },
    { lat: 48.340295, lng: 21.776513 },
    { lat: 48.340299, lng: 21.777233 },
    { lat: 48.340295, lng: 21.777352 },
    { lat: 48.340254, lng: 21.777722 },
    { lat: 48.340224, lng: 21.777909 },
    { lat: 48.340176, lng: 21.778145 },
    { lat: 48.339555, lng: 21.780366 },
    { lat: 48.339013, lng: 21.782291 },
    { lat: 48.338972, lng: 21.782462 },
    { lat: 48.338935, lng: 21.782708 },
    { lat: 48.338926, lng: 21.782812 },
    { lat: 48.338943, lng: 21.78302 },
    { lat: 48.339363, lng: 21.78401 },
    { lat: 48.339407, lng: 21.784145 },
    { lat: 48.339477, lng: 21.784412 },
    { lat: 48.339551, lng: 21.784956 },
    { lat: 48.339618, lng: 21.785293 },
    { lat: 48.339651, lng: 21.785476 },
    { lat: 48.339713, lng: 21.785958 },
    { lat: 48.339742, lng: 21.786129 },
    { lat: 48.339796, lng: 21.786286 },
    { lat: 48.339989, lng: 21.786677 },
    { lat: 48.340059, lng: 21.786859 },
    { lat: 48.34009, lng: 21.787225 },
    { lat: 48.340139, lng: 21.787514 },
    { lat: 48.340238, lng: 21.787905 },
    { lat: 48.34094, lng: 21.789476 },
    { lat: 48.341073, lng: 21.789965 },
    { lat: 48.341103, lng: 21.790111 },
    { lat: 48.341078, lng: 21.79071 },
    { lat: 48.340986, lng: 21.791058 },
    { lat: 48.340889, lng: 21.79136 },
    { lat: 48.340828, lng: 21.791722 },
    { lat: 48.340802, lng: 21.79203 },
    { lat: 48.340751, lng: 21.79252 },
    { lat: 48.340679, lng: 21.793314 },
    { lat: 48.340661, lng: 21.793517 },
    { lat: 48.34067, lng: 21.793632 },
    { lat: 48.340673, lng: 21.793787 },
    { lat: 48.340701, lng: 21.793992 },
    { lat: 48.340743, lng: 21.794184 },
    { lat: 48.340804, lng: 21.794382 },
    { lat: 48.340903, lng: 21.794657 },
    { lat: 48.341004, lng: 21.794862 },
    { lat: 48.34128, lng: 21.795384 },
    { lat: 48.341371, lng: 21.79556 },
    { lat: 48.341435, lng: 21.795703 },
    { lat: 48.34155, lng: 21.795989 },
    { lat: 48.341674, lng: 21.796266 },
    { lat: 48.341934, lng: 21.796762 },
    { lat: 48.342155, lng: 21.79742 },
    { lat: 48.342442, lng: 21.798201 },
    { lat: 48.342466, lng: 21.798282 },
    { lat: 48.342488, lng: 21.798397 },
    { lat: 48.342472, lng: 21.798477 },
    { lat: 48.342404, lng: 21.798625 },
    { lat: 48.342087, lng: 21.799049 },
    { lat: 48.342053, lng: 21.79912 },
    { lat: 48.341718, lng: 21.799564 },
    { lat: 48.341346, lng: 21.80001 },
    { lat: 48.341269, lng: 21.800106 },
    { lat: 48.341187, lng: 21.800288 },
    { lat: 48.341053, lng: 21.800742 },
    { lat: 48.341013, lng: 21.800894 },
    { lat: 48.34099, lng: 21.801179 },
    { lat: 48.34096, lng: 21.80173 },
    { lat: 48.340976, lng: 21.802097 },
    { lat: 48.340998, lng: 21.802344 },
    { lat: 48.341036, lng: 21.802755 },
    { lat: 48.341027, lng: 21.802904 },
    { lat: 48.341008, lng: 21.803 },
    { lat: 48.340979, lng: 21.803073 },
    { lat: 48.340936, lng: 21.803135 },
    { lat: 48.340291, lng: 21.803507 },
    { lat: 48.340215, lng: 21.803534 },
    { lat: 48.34016, lng: 21.803531 },
    { lat: 48.340064, lng: 21.803508 },
    { lat: 48.339664, lng: 21.803341 },
    { lat: 48.339583, lng: 21.803334 },
    { lat: 48.339502, lng: 21.803354 },
    { lat: 48.339424, lng: 21.803391 },
    { lat: 48.339388, lng: 21.803439 },
    { lat: 48.339346, lng: 21.803521 },
    { lat: 48.339317, lng: 21.80359 },
    { lat: 48.339306, lng: 21.80368 },
    { lat: 48.339259, lng: 21.804005 },
    { lat: 48.33924, lng: 21.804137 },
    { lat: 48.33921, lng: 21.804239 },
    { lat: 48.33917, lng: 21.804297 },
    { lat: 48.339113, lng: 21.80437 },
    { lat: 48.33886, lng: 21.804551 },
    { lat: 48.338747, lng: 21.804682 },
    { lat: 48.338619, lng: 21.804894 },
    { lat: 48.338439, lng: 21.805275 },
    { lat: 48.338239, lng: 21.805804 },
    { lat: 48.338107, lng: 21.806459 },
    { lat: 48.338094, lng: 21.806695 },
    { lat: 48.338089, lng: 21.806759 },
    { lat: 48.338099, lng: 21.806999 },
    { lat: 48.338138, lng: 21.807112 },
    { lat: 48.338595, lng: 21.807911 },
    { lat: 48.338719, lng: 21.808097 },
    { lat: 48.3388, lng: 21.808188 },
    { lat: 48.33902, lng: 21.808432 },
    { lat: 48.339065, lng: 21.808509 },
    { lat: 48.339101, lng: 21.808578 },
    { lat: 48.339153, lng: 21.808758 },
    { lat: 48.339185, lng: 21.808892 },
    { lat: 48.339187, lng: 21.809049 },
    { lat: 48.339183, lng: 21.809232 },
    { lat: 48.339124, lng: 21.809426 },
    { lat: 48.338591, lng: 21.810371 },
    { lat: 48.338385, lng: 21.810637 },
    { lat: 48.338355, lng: 21.810678 },
    { lat: 48.338337, lng: 21.810712 },
    { lat: 48.3383294, lng: 21.8107258 },
    { lat: 48.338478, lng: 21.8108467 },
    { lat: 48.3432532, lng: 21.813166 },
    { lat: 48.3542959, lng: 21.8075457 },
    { lat: 48.3539834, lng: 21.8052048 },
    { lat: 48.353839, lng: 21.8010492 },
    { lat: 48.3535858, lng: 21.8007925 },
    { lat: 48.3532867, lng: 21.8002935 },
    { lat: 48.3528904, lng: 21.7994723 },
    { lat: 48.3526263, lng: 21.7988025 },
    { lat: 48.3523216, lng: 21.7971938 },
    { lat: 48.3521516, lng: 21.795783 },
    { lat: 48.3519447, lng: 21.7945098 },
    { lat: 48.3519221, lng: 21.7940736 },
    { lat: 48.3538802, lng: 21.7929425 },
    { lat: 48.3539847, lng: 21.792769 },
    { lat: 48.3542887, lng: 21.7926455 },
    { lat: 48.3539915, lng: 21.7923926 },
    { lat: 48.3538392, lng: 21.7920709 },
    { lat: 48.3536176, lng: 21.7905842 },
    { lat: 48.3535427, lng: 21.7894192 },
    { lat: 48.3532614, lng: 21.7879323 },
    { lat: 48.353284, lng: 21.7873006 },
    { lat: 48.3534794, lng: 21.7865153 },
    { lat: 48.3534995, lng: 21.7864727 },
  ],
  KlinnadBodrogom: [
    { lat: 48.387774, lng: 21.7216069 },
    { lat: 48.3877708, lng: 21.7215303 },
    { lat: 48.3877013, lng: 21.7198789 },
    { lat: 48.3875712, lng: 21.7184791 },
    { lat: 48.3873563, lng: 21.7174465 },
    { lat: 48.387045, lng: 21.7162967 },
    { lat: 48.3859805, lng: 21.710811 },
    { lat: 48.3853456, lng: 21.7083568 },
    { lat: 48.3846433, lng: 21.7068614 },
    { lat: 48.3841739, lng: 21.7060279 },
    { lat: 48.3837401, lng: 21.705423 },
    { lat: 48.3831283, lng: 21.704776 },
    { lat: 48.3824575, lng: 21.7043952 },
    { lat: 48.3819098, lng: 21.7042369 },
    { lat: 48.3812563, lng: 21.7042069 },
    { lat: 48.3805158, lng: 21.7043638 },
    { lat: 48.3800785, lng: 21.7045513 },
    { lat: 48.3797445, lng: 21.7048408 },
    { lat: 48.3792444, lng: 21.7055245 },
    { lat: 48.3787905, lng: 21.7066668 },
    { lat: 48.378674, lng: 21.7078015 },
    { lat: 48.378774, lng: 21.7094946 },
    { lat: 48.378716, lng: 21.7096805 },
    { lat: 48.3787381, lng: 21.7102061 },
    { lat: 48.3790201, lng: 21.7118488 },
    { lat: 48.3792271, lng: 21.7126795 },
    { lat: 48.3794783, lng: 21.713309 },
    { lat: 48.3799185, lng: 21.7141096 },
    { lat: 48.380603, lng: 21.7150323 },
    { lat: 48.3811555, lng: 21.715619 },
    { lat: 48.3820559, lng: 21.7163713 },
    { lat: 48.3834507, lng: 21.7173094 },
    { lat: 48.3836762, lng: 21.7176613 },
    { lat: 48.3840178, lng: 21.7183919 },
    { lat: 48.3841967, lng: 21.7191038 },
    { lat: 48.3842093, lng: 21.7194821 },
    { lat: 48.3841348, lng: 21.7201351 },
    { lat: 48.3838646, lng: 21.7211717 },
    { lat: 48.3835066, lng: 21.7219953 },
    { lat: 48.3831036, lng: 21.7227002 },
    { lat: 48.3826197, lng: 21.723405 },
    { lat: 48.3820683, lng: 21.7240598 },
    { lat: 48.3810416, lng: 21.7249205 },
    { lat: 48.3802245, lng: 21.7254119 },
    { lat: 48.3796221, lng: 21.7256836 },
    { lat: 48.3789844, lng: 21.7257798 },
    { lat: 48.3778895, lng: 21.7255202 },
    { lat: 48.3770308, lng: 21.7251386 },
    { lat: 48.3765288, lng: 21.7243424 },
    { lat: 48.3762592, lng: 21.7238152 },
    { lat: 48.3753333, lng: 21.7215316 },
    { lat: 48.3750534, lng: 21.7204251 },
    { lat: 48.3747864, lng: 21.718915 },
    { lat: 48.374699, lng: 21.7180136 },
    { lat: 48.3746073, lng: 21.7153345 },
    { lat: 48.3744675, lng: 21.7139158 },
    { lat: 48.3740506, lng: 21.7123619 },
    { lat: 48.3736489, lng: 21.7111991 },
    { lat: 48.3734808, lng: 21.7110604 },
    { lat: 48.3727614, lng: 21.7100098 },
    { lat: 48.3719871, lng: 21.7090104 },
    { lat: 48.3713372, lng: 21.7080261 },
    { lat: 48.3710007, lng: 21.7072448 },
    { lat: 48.3708051, lng: 21.7065894 },
    { lat: 48.3703156, lng: 21.7045181 },
    { lat: 48.369929, lng: 21.703245 },
    { lat: 48.369677, lng: 21.70335 },
    { lat: 48.369538, lng: 21.703408 },
    { lat: 48.36898, lng: 21.703777 },
    { lat: 48.368385, lng: 21.70417 },
    { lat: 48.368245, lng: 21.704263 },
    { lat: 48.367835, lng: 21.704534 },
    { lat: 48.367609, lng: 21.704684 },
    { lat: 48.367522, lng: 21.704734 },
    { lat: 48.367255, lng: 21.704887 },
    { lat: 48.367192, lng: 21.704923 },
    { lat: 48.367169, lng: 21.704937 },
    { lat: 48.36716, lng: 21.70494 },
    { lat: 48.367117, lng: 21.704956 },
    { lat: 48.367028, lng: 21.70499 },
    { lat: 48.3668, lng: 21.705076 },
    { lat: 48.366588, lng: 21.705157 },
    { lat: 48.366054, lng: 21.705367 },
    { lat: 48.366136, lng: 21.706679 },
    { lat: 48.366196, lng: 21.707511 },
    { lat: 48.366249, lng: 21.707916 },
    { lat: 48.366416, lng: 21.708394 },
    { lat: 48.366578, lng: 21.708805 },
    { lat: 48.366793, lng: 21.709383 },
    { lat: 48.367016, lng: 21.709986 },
    { lat: 48.36712, lng: 21.710296 },
    { lat: 48.367268, lng: 21.710875 },
    { lat: 48.367306, lng: 21.711145 },
    { lat: 48.367294, lng: 21.711291 },
    { lat: 48.367042, lng: 21.71178 },
    { lat: 48.36684, lng: 21.712034 },
    { lat: 48.366653, lng: 21.712344 },
    { lat: 48.366576, lng: 21.712507 },
    { lat: 48.366506, lng: 21.712851 },
    { lat: 48.366431, lng: 21.713391 },
    { lat: 48.366365, lng: 21.714107 },
    { lat: 48.366334, lng: 21.714188 },
    { lat: 48.366613, lng: 21.714302 },
    { lat: 48.3701593, lng: 21.7286809 },
    { lat: 48.371329, lng: 21.7331209 },
    { lat: 48.3740852, lng: 21.7314771 },
    { lat: 48.3760783, lng: 21.7404768 },
    { lat: 48.3806466, lng: 21.7392291 },
    { lat: 48.3820104, lng: 21.7390639 },
    { lat: 48.3832799, lng: 21.7390323 },
    { lat: 48.3836055, lng: 21.7382968 },
    { lat: 48.3843594, lng: 21.7364608 },
    { lat: 48.3846268, lng: 21.7359465 },
    { lat: 48.3848418, lng: 21.7353236 },
    { lat: 48.3854647, lng: 21.7341343 },
    { lat: 48.3870626, lng: 21.731433 },
    { lat: 48.3873496, lng: 21.7308049 },
    { lat: 48.387538, lng: 21.7302391 },
    { lat: 48.3878595, lng: 21.7287303 },
    { lat: 48.3879562, lng: 21.7274303 },
    { lat: 48.3877092, lng: 21.7241182 },
    { lat: 48.387774, lng: 21.7216069 },
  ],
  Luhyňa: [
    { lat: 48.5043456, lng: 21.6532635 },
    { lat: 48.5042977, lng: 21.6530756 },
    { lat: 48.5042212, lng: 21.6529172 },
    { lat: 48.5041888, lng: 21.6524782 },
    { lat: 48.5041028, lng: 21.6522498 },
    { lat: 48.5039238, lng: 21.6521705 },
    { lat: 48.5038059, lng: 21.6519305 },
    { lat: 48.5038624, lng: 21.6518181 },
    { lat: 48.5038329, lng: 21.6514062 },
    { lat: 48.5037359, lng: 21.6513069 },
    { lat: 48.5037184, lng: 21.6511678 },
    { lat: 48.5034986, lng: 21.6509126 },
    { lat: 48.5034461, lng: 21.650674 },
    { lat: 48.5033573, lng: 21.6506 },
    { lat: 48.5032585, lng: 21.6503632 },
    { lat: 48.503175, lng: 21.6503528 },
    { lat: 48.5031531, lng: 21.6502527 },
    { lat: 48.5025572, lng: 21.649575 },
    { lat: 48.5024654, lng: 21.6495534 },
    { lat: 48.5024137, lng: 21.649226 },
    { lat: 48.5022749, lng: 21.6489721 },
    { lat: 48.5018209, lng: 21.6484651 },
    { lat: 48.5017439, lng: 21.6479576 },
    { lat: 48.5012328, lng: 21.6474124 },
    { lat: 48.5011792, lng: 21.6472231 },
    { lat: 48.5012418, lng: 21.6470925 },
    { lat: 48.5009542, lng: 21.6462484 },
    { lat: 48.5009745, lng: 21.6460874 },
    { lat: 48.500929, lng: 21.6457461 },
    { lat: 48.5010501, lng: 21.6451195 },
    { lat: 48.5010273, lng: 21.6447821 },
    { lat: 48.5010823, lng: 21.6444178 },
    { lat: 48.5010643, lng: 21.6440081 },
    { lat: 48.5012001, lng: 21.6439545 },
    { lat: 48.5011975, lng: 21.6439043 },
    { lat: 48.5011415, lng: 21.6438723 },
    { lat: 48.501161, lng: 21.6437185 },
    { lat: 48.5011001, lng: 21.6434669 },
    { lat: 48.5009568, lng: 21.643257 },
    { lat: 48.5010364, lng: 21.6432041 },
    { lat: 48.5008928, lng: 21.6431329 },
    { lat: 48.5009069, lng: 21.6428762 },
    { lat: 48.5006232, lng: 21.6424654 },
    { lat: 48.5006019, lng: 21.6422248 },
    { lat: 48.5005036, lng: 21.6419631 },
    { lat: 48.5004915, lng: 21.6417723 },
    { lat: 48.5005267, lng: 21.6416956 },
    { lat: 48.5004574, lng: 21.6415527 },
    { lat: 48.5004839, lng: 21.6412938 },
    { lat: 48.5004309, lng: 21.6411229 },
    { lat: 48.5004859, lng: 21.640837 },
    { lat: 48.5006155, lng: 21.6405969 },
    { lat: 48.5001153, lng: 21.6398428 },
    { lat: 48.5011658, lng: 21.6374739 },
    { lat: 48.5013491, lng: 21.6365832 },
    { lat: 48.5013658, lng: 21.6363693 },
    { lat: 48.5013044, lng: 21.6357785 },
    { lat: 48.5010927, lng: 21.6352437 },
    { lat: 48.5009435, lng: 21.6345318 },
    { lat: 48.5009233, lng: 21.6342127 },
    { lat: 48.5006833, lng: 21.6341213 },
    { lat: 48.4998319, lng: 21.62963 },
    { lat: 48.4997736, lng: 21.6296443 },
    { lat: 48.499498, lng: 21.6287996 },
    { lat: 48.4995995, lng: 21.6287165 },
    { lat: 48.4989889, lng: 21.6262282 },
    { lat: 48.4991001, lng: 21.6261829 },
    { lat: 48.4984984, lng: 21.6219071 },
    { lat: 48.4987796, lng: 21.6217865 },
    { lat: 48.4982686, lng: 21.6187049 },
    { lat: 48.4977775, lng: 21.6184432 },
    { lat: 48.497744, lng: 21.618368 },
    { lat: 48.497633, lng: 21.61839 },
    { lat: 48.497192, lng: 21.618526 },
    { lat: 48.49672, lng: 21.618656 },
    { lat: 48.496188, lng: 21.618785 },
    { lat: 48.495529, lng: 21.618959 },
    { lat: 48.495204, lng: 21.619045 },
    { lat: 48.494768, lng: 21.619194 },
    { lat: 48.494511, lng: 21.619303 },
    { lat: 48.494452, lng: 21.619312 },
    { lat: 48.494312, lng: 21.619381 },
    { lat: 48.494147, lng: 21.619518 },
    { lat: 48.494082, lng: 21.619571 },
    { lat: 48.493928, lng: 21.619782 },
    { lat: 48.493832, lng: 21.619884 },
    { lat: 48.493773, lng: 21.61995 },
    { lat: 48.493758, lng: 21.619947 },
    { lat: 48.493677, lng: 21.619939 },
    { lat: 48.493656, lng: 21.619932 },
    { lat: 48.493605, lng: 21.619866 },
    { lat: 48.493573, lng: 21.619784 },
    { lat: 48.493489, lng: 21.619788 },
    { lat: 48.493385, lng: 21.619803 },
    { lat: 48.493355, lng: 21.619861 },
    { lat: 48.493287, lng: 21.620018 },
    { lat: 48.493134, lng: 21.620165 },
    { lat: 48.493106, lng: 21.620217 },
    { lat: 48.493044, lng: 21.620291 },
    { lat: 48.492943, lng: 21.620354 },
    { lat: 48.492879, lng: 21.620375 },
    { lat: 48.492787, lng: 21.620448 },
    { lat: 48.492766, lng: 21.620604 },
    { lat: 48.492822, lng: 21.620756 },
    { lat: 48.492794, lng: 21.620826 },
    { lat: 48.492734, lng: 21.620836 },
    { lat: 48.492595, lng: 21.62082 },
    { lat: 48.492541, lng: 21.620852 },
    { lat: 48.492495, lng: 21.620877 },
    { lat: 48.492386, lng: 21.6209 },
    { lat: 48.492274, lng: 21.620997 },
    { lat: 48.492198, lng: 21.621082 },
    { lat: 48.492135, lng: 21.62122 },
    { lat: 48.492, lng: 21.62124 },
    { lat: 48.49192, lng: 21.621219 },
    { lat: 48.491827, lng: 21.621213 },
    { lat: 48.491663, lng: 21.621248 },
    { lat: 48.491512, lng: 21.621256 },
    { lat: 48.491437, lng: 21.621209 },
    { lat: 48.491368, lng: 21.621166 },
    { lat: 48.491254, lng: 21.621163 },
    { lat: 48.491044, lng: 21.62121 },
    { lat: 48.49096, lng: 21.621236 },
    { lat: 48.490846, lng: 21.621235 },
    { lat: 48.490677, lng: 21.621247 },
    { lat: 48.490635, lng: 21.621211 },
    { lat: 48.490518, lng: 21.621221 },
    { lat: 48.49047, lng: 21.62127 },
    { lat: 48.490313, lng: 21.621317 },
    { lat: 48.490163, lng: 21.621264 },
    { lat: 48.490161, lng: 21.621231 },
    { lat: 48.489998, lng: 21.621189 },
    { lat: 48.489927, lng: 21.621199 },
    { lat: 48.48981, lng: 21.621243 },
    { lat: 48.489735, lng: 21.62126 },
    { lat: 48.489638, lng: 21.621257 },
    { lat: 48.489525, lng: 21.621305 },
    { lat: 48.489447, lng: 21.621378 },
    { lat: 48.489315, lng: 21.621345 },
    { lat: 48.489047, lng: 21.621357 },
    { lat: 48.488981, lng: 21.621363 },
    { lat: 48.488899, lng: 21.621389 },
    { lat: 48.488846, lng: 21.62149 },
    { lat: 48.488579, lng: 21.621584 },
    { lat: 48.488507, lng: 21.621578 },
    { lat: 48.488422, lng: 21.62153 },
    { lat: 48.488283, lng: 21.621505 },
    { lat: 48.48823, lng: 21.621473 },
    { lat: 48.488068, lng: 21.621447 },
    { lat: 48.487986, lng: 21.621466 },
    { lat: 48.487867, lng: 21.621634 },
    { lat: 48.487822, lng: 21.621642 },
    { lat: 48.487712, lng: 21.621657 },
    { lat: 48.487573, lng: 21.621655 },
    { lat: 48.487371, lng: 21.621712 },
    { lat: 48.487261, lng: 21.621733 },
    { lat: 48.487058, lng: 21.621693 },
    { lat: 48.486844, lng: 21.621733 },
    { lat: 48.486696, lng: 21.621756 },
    { lat: 48.486437, lng: 21.621774 },
    { lat: 48.486367, lng: 21.621757 },
    { lat: 48.48616, lng: 21.621801 },
    { lat: 48.486151, lng: 21.621802 },
    { lat: 48.485976, lng: 21.621849 },
    { lat: 48.485712, lng: 21.621883 },
    { lat: 48.485599, lng: 21.621927 },
    { lat: 48.485534, lng: 21.62191 },
    { lat: 48.485273, lng: 21.621945 },
    { lat: 48.484958, lng: 21.621993 },
    { lat: 48.484722, lng: 21.622013 },
    { lat: 48.484505, lng: 21.622012 },
    { lat: 48.484435, lng: 21.622017 },
    { lat: 48.483871, lng: 21.62214 },
    { lat: 48.48329, lng: 21.622201 },
    { lat: 48.483095, lng: 21.622232 },
    { lat: 48.482418, lng: 21.622343 },
    { lat: 48.482288, lng: 21.622369 },
    { lat: 48.482196, lng: 21.622338 },
    { lat: 48.481985, lng: 21.62237 },
    { lat: 48.481881, lng: 21.622384 },
    { lat: 48.48159, lng: 21.622423 },
    { lat: 48.481467, lng: 21.622459 },
    { lat: 48.481375, lng: 21.622471 },
    { lat: 48.480256, lng: 21.622641 },
    { lat: 48.480139, lng: 21.622655 },
    { lat: 48.479827, lng: 21.62269 },
    { lat: 48.479583, lng: 21.622717 },
    { lat: 48.479399, lng: 21.622734 },
    { lat: 48.478892, lng: 21.62278 },
    { lat: 48.478791, lng: 21.6228 },
    { lat: 48.478734, lng: 21.622811 },
    { lat: 48.478267, lng: 21.62286 },
    { lat: 48.478015, lng: 21.622909 },
    { lat: 48.477936, lng: 21.622915 },
    { lat: 48.477759, lng: 21.622928 },
    { lat: 48.477518, lng: 21.62293 },
    { lat: 48.477466, lng: 21.622997 },
    { lat: 48.477385, lng: 21.623009 },
    { lat: 48.47724, lng: 21.623002 },
    { lat: 48.477165, lng: 21.623013 },
    { lat: 48.477063, lng: 21.623014 },
    { lat: 48.476953, lng: 21.623036 },
    { lat: 48.476791, lng: 21.623031 },
    { lat: 48.476752, lng: 21.623058 },
    { lat: 48.476518, lng: 21.623053 },
    { lat: 48.47638, lng: 21.623071 },
    { lat: 48.476289, lng: 21.623094 },
    { lat: 48.476237, lng: 21.623106 },
    { lat: 48.476155, lng: 21.623115 },
    { lat: 48.476064, lng: 21.623133 },
    { lat: 48.475995, lng: 21.623129 },
    { lat: 48.475796, lng: 21.62317 },
    { lat: 48.475516, lng: 21.623194 },
    { lat: 48.475424, lng: 21.623219 },
    { lat: 48.475264, lng: 21.623241 },
    { lat: 48.475111, lng: 21.623216 },
    { lat: 48.475075, lng: 21.623237 },
    { lat: 48.475032, lng: 21.623271 },
    { lat: 48.474941, lng: 21.623236 },
    { lat: 48.474687, lng: 21.623296 },
    { lat: 48.474622, lng: 21.62328 },
    { lat: 48.47454, lng: 21.623309 },
    { lat: 48.474477, lng: 21.623323 },
    { lat: 48.474413, lng: 21.62336 },
    { lat: 48.474306, lng: 21.623393 },
    { lat: 48.474266, lng: 21.623404 },
    { lat: 48.474207, lng: 21.623398 },
    { lat: 48.474014, lng: 21.623442 },
    { lat: 48.473899, lng: 21.623439 },
    { lat: 48.473809, lng: 21.623357 },
    { lat: 48.473645, lng: 21.623236 },
    { lat: 48.473507, lng: 21.623258 },
    { lat: 48.473347, lng: 21.623681 },
    { lat: 48.473314, lng: 21.62369 },
    { lat: 48.473266, lng: 21.623621 },
    { lat: 48.473259, lng: 21.623375 },
    { lat: 48.473277, lng: 21.623005 },
    { lat: 48.473132, lng: 21.622992 },
    { lat: 48.47309, lng: 21.62304 },
    { lat: 48.47308, lng: 21.623196 },
    { lat: 48.473046, lng: 21.623393 },
    { lat: 48.472974, lng: 21.623557 },
    { lat: 48.473012, lng: 21.623829 },
    { lat: 48.473057, lng: 21.623922 },
    { lat: 48.473068, lng: 21.62395 },
    { lat: 48.473042, lng: 21.624083 },
    { lat: 48.473039, lng: 21.624188 },
    { lat: 48.473068, lng: 21.624383 },
    { lat: 48.473018, lng: 21.624432 },
    { lat: 48.472905, lng: 21.624404 },
    { lat: 48.472751, lng: 21.624242 },
    { lat: 48.472708, lng: 21.624168 },
    { lat: 48.472693, lng: 21.623997 },
    { lat: 48.472652, lng: 21.623871 },
    { lat: 48.472577, lng: 21.623683 },
    { lat: 48.472463, lng: 21.62344 },
    { lat: 48.472458, lng: 21.623404 },
    { lat: 48.472543, lng: 21.623038 },
    { lat: 48.472633, lng: 21.62287 },
    { lat: 48.472585, lng: 21.622607 },
    { lat: 48.472529, lng: 21.6225 },
    { lat: 48.472413, lng: 21.622491 },
    { lat: 48.472349, lng: 21.622257 },
    { lat: 48.472221, lng: 21.622069 },
    { lat: 48.472215, lng: 21.62206 },
    { lat: 48.4722107, lng: 21.6220855 },
    { lat: 48.4720095, lng: 21.6228949 },
    { lat: 48.4720218, lng: 21.6230558 },
    { lat: 48.4719565, lng: 21.6232958 },
    { lat: 48.4719885, lng: 21.6236381 },
    { lat: 48.4718697, lng: 21.6237764 },
    { lat: 48.4717627, lng: 21.6237704 },
    { lat: 48.4716729, lng: 21.6235431 },
    { lat: 48.4717011, lng: 21.6231335 },
    { lat: 48.4715362, lng: 21.6227067 },
    { lat: 48.4712479, lng: 21.6228195 },
    { lat: 48.4710655, lng: 21.6230179 },
    { lat: 48.4708824, lng: 21.6224284 },
    { lat: 48.4707155, lng: 21.622328 },
    { lat: 48.4705716, lng: 21.6223767 },
    { lat: 48.4704364, lng: 21.6223303 },
    { lat: 48.4704219, lng: 21.6216905 },
    { lat: 48.4701291, lng: 21.6216444 },
    { lat: 48.4700946, lng: 21.6218168 },
    { lat: 48.470119, lng: 21.6220854 },
    { lat: 48.4700315, lng: 21.6225817 },
    { lat: 48.4702221, lng: 21.6229579 },
    { lat: 48.4701835, lng: 21.6233054 },
    { lat: 48.4702997, lng: 21.6233544 },
    { lat: 48.4704851, lng: 21.6233062 },
    { lat: 48.4705091, lng: 21.6234412 },
    { lat: 48.4704995, lng: 21.6235461 },
    { lat: 48.4702083, lng: 21.6239162 },
    { lat: 48.4702156, lng: 21.6240512 },
    { lat: 48.4703044, lng: 21.6241356 },
    { lat: 48.4702964, lng: 21.624212 },
    { lat: 48.4701366, lng: 21.6246532 },
    { lat: 48.4700813, lng: 21.6246901 },
    { lat: 48.4708545, lng: 21.6263526 },
    { lat: 48.4716333, lng: 21.6290289 },
    { lat: 48.4721207, lng: 21.63005 },
    { lat: 48.4735735, lng: 21.6344656 },
    { lat: 48.473648, lng: 21.6347205 },
    { lat: 48.4732655, lng: 21.6349991 },
    { lat: 48.4753165, lng: 21.6417288 },
    { lat: 48.4761218, lng: 21.6423259 },
    { lat: 48.4776611, lng: 21.6466343 },
    { lat: 48.4780507, lng: 21.6464418 },
    { lat: 48.4783203, lng: 21.6465037 },
    { lat: 48.4785878, lng: 21.6463369 },
    { lat: 48.4793437, lng: 21.6461999 },
    { lat: 48.4804673, lng: 21.6463906 },
    { lat: 48.4808447, lng: 21.6464922 },
    { lat: 48.4813148, lng: 21.6468915 },
    { lat: 48.4823425, lng: 21.6473482 },
    { lat: 48.4826379, lng: 21.6474159 },
    { lat: 48.4829996, lng: 21.6477969 },
    { lat: 48.4833943, lng: 21.6478332 },
    { lat: 48.4836667, lng: 21.6479556 },
    { lat: 48.48376, lng: 21.6481572 },
    { lat: 48.4840304, lng: 21.6484971 },
    { lat: 48.4845158, lng: 21.648759 },
    { lat: 48.4846419, lng: 21.649005 },
    { lat: 48.4847403, lng: 21.6494249 },
    { lat: 48.4849541, lng: 21.649714 },
    { lat: 48.4850297, lng: 21.65013 },
    { lat: 48.4850232, lng: 21.6502173 },
    { lat: 48.4849091, lng: 21.6503616 },
    { lat: 48.4849755, lng: 21.6509321 },
    { lat: 48.4849745, lng: 21.6514691 },
    { lat: 48.4849762, lng: 21.6514875 },
    { lat: 48.4849695, lng: 21.6518159 },
    { lat: 48.4850131, lng: 21.65197 },
    { lat: 48.4849838, lng: 21.6534795 },
    { lat: 48.4850914, lng: 21.6545688 },
    { lat: 48.4849318, lng: 21.6550632 },
    { lat: 48.4849025, lng: 21.6553875 },
    { lat: 48.4848861, lng: 21.6556031 },
    { lat: 48.4849703, lng: 21.6560905 },
    { lat: 48.4851392, lng: 21.6562583 },
    { lat: 48.4853839, lng: 21.6569332 },
    { lat: 48.4861526, lng: 21.6574287 },
    { lat: 48.4866598, lng: 21.6574995 },
    { lat: 48.4866946, lng: 21.6576588 },
    { lat: 48.4868704, lng: 21.6577925 },
    { lat: 48.4869688, lng: 21.6582996 },
    { lat: 48.4870567, lng: 21.6584261 },
    { lat: 48.4870971, lng: 21.6584204 },
    { lat: 48.4876993, lng: 21.658225 },
    { lat: 48.4881864, lng: 21.657737 },
    { lat: 48.4887647, lng: 21.6572799 },
    { lat: 48.4912682, lng: 21.6569127 },
    { lat: 48.4920575, lng: 21.6566524 },
    { lat: 48.4929186, lng: 21.6565416 },
    { lat: 48.4930375, lng: 21.6562802 },
    { lat: 48.4931464, lng: 21.6557381 },
    { lat: 48.493564, lng: 21.6552182 },
    { lat: 48.4951509, lng: 21.6545395 },
    { lat: 48.4978224, lng: 21.6538931 },
    { lat: 48.4986227, lng: 21.6536221 },
    { lat: 48.5014094, lng: 21.653117 },
    { lat: 48.5023887, lng: 21.6532935 },
    { lat: 48.502558, lng: 21.653069 },
    { lat: 48.5030896, lng: 21.653171 },
    { lat: 48.5043456, lng: 21.6532635 },
  ],
  Dargov: [
    { lat: 48.794077, lng: 21.541568 },
    { lat: 48.793033, lng: 21.540915 },
    { lat: 48.792724, lng: 21.540307 },
    { lat: 48.792046, lng: 21.539724 },
    { lat: 48.790841, lng: 21.538951 },
    { lat: 48.789874, lng: 21.53963 },
    { lat: 48.788995, lng: 21.540062 },
    { lat: 48.788579, lng: 21.540253 },
    { lat: 48.787868, lng: 21.540086 },
    { lat: 48.787178, lng: 21.540009 },
    { lat: 48.786918, lng: 21.540134 },
    { lat: 48.786727, lng: 21.540406 },
    { lat: 48.78644, lng: 21.541023 },
    { lat: 48.786164, lng: 21.541437 },
    { lat: 48.785569, lng: 21.541405 },
    { lat: 48.784481, lng: 21.541448 },
    { lat: 48.78427, lng: 21.541428 },
    { lat: 48.783798, lng: 21.541251 },
    { lat: 48.783422, lng: 21.541164 },
    { lat: 48.783023, lng: 21.541135 },
    { lat: 48.782888, lng: 21.541126 },
    { lat: 48.782817, lng: 21.541122 },
    { lat: 48.782717, lng: 21.541115 },
    { lat: 48.782326, lng: 21.540902 },
    { lat: 48.782279, lng: 21.540877 },
    { lat: 48.782066, lng: 21.540762 },
    { lat: 48.781536, lng: 21.540475 },
    { lat: 48.7805293, lng: 21.5396937 },
    { lat: 48.779777, lng: 21.540347 },
    { lat: 48.779304, lng: 21.540819 },
    { lat: 48.778804, lng: 21.541394 },
    { lat: 48.778758, lng: 21.541974 },
    { lat: 48.7784, lng: 21.542711 },
    { lat: 48.778273, lng: 21.543683 },
    { lat: 48.777784, lng: 21.543671 },
    { lat: 48.77731, lng: 21.544363 },
    { lat: 48.777225, lng: 21.545056 },
    { lat: 48.776704, lng: 21.545541 },
    { lat: 48.776288, lng: 21.545568 },
    { lat: 48.775678, lng: 21.545618 },
    { lat: 48.775238, lng: 21.546114 },
    { lat: 48.774794, lng: 21.545643 },
    { lat: 48.774647, lng: 21.54521 },
    { lat: 48.773992, lng: 21.544606 },
    { lat: 48.773518, lng: 21.544213 },
    { lat: 48.772966, lng: 21.543974 },
    { lat: 48.772596, lng: 21.543796 },
    { lat: 48.771704, lng: 21.54317 },
    { lat: 48.77097, lng: 21.542682 },
    { lat: 48.770757, lng: 21.542497 },
    { lat: 48.77017, lng: 21.541801 },
    { lat: 48.769957, lng: 21.540756 },
    { lat: 48.769307, lng: 21.53995 },
    { lat: 48.768815, lng: 21.538893 },
    { lat: 48.768615, lng: 21.53853 },
    { lat: 48.768138, lng: 21.538167 },
    { lat: 48.767176, lng: 21.537715 },
    { lat: 48.766703, lng: 21.538016 },
    { lat: 48.766427, lng: 21.538344 },
    { lat: 48.76539, lng: 21.539016 },
    { lat: 48.764992, lng: 21.539113 },
    { lat: 48.764519, lng: 21.539263 },
    { lat: 48.764264, lng: 21.539461 },
    { lat: 48.763466, lng: 21.540018 },
    { lat: 48.76272, lng: 21.540356 },
    { lat: 48.76243, lng: 21.540421 },
    { lat: 48.761963, lng: 21.540528 },
    { lat: 48.761021, lng: 21.540603 },
    { lat: 48.76022, lng: 21.541292 },
    { lat: 48.759996, lng: 21.541819 },
    { lat: 48.75979, lng: 21.542079 },
    { lat: 48.759324, lng: 21.542463 },
    { lat: 48.758883, lng: 21.542647 },
    { lat: 48.758565, lng: 21.542578 },
    { lat: 48.758147, lng: 21.542553 },
    { lat: 48.75745, lng: 21.542509 },
    { lat: 48.757195, lng: 21.54249 },
    { lat: 48.756955, lng: 21.542434 },
    { lat: 48.75675, lng: 21.542341 },
    { lat: 48.756337, lng: 21.542122 },
    { lat: 48.756294, lng: 21.542023 },
    { lat: 48.756241, lng: 21.541705 },
    { lat: 48.756114, lng: 21.541503 },
    { lat: 48.755893, lng: 21.541305 },
    { lat: 48.755313, lng: 21.541076 },
    { lat: 48.755169, lng: 21.540931 },
    { lat: 48.75474, lng: 21.540713 },
    { lat: 48.754328, lng: 21.54076 },
    { lat: 48.754312, lng: 21.54071 },
    { lat: 48.754165, lng: 21.540833 },
    { lat: 48.753884, lng: 21.54111 },
    { lat: 48.753711, lng: 21.541242 },
    { lat: 48.753352, lng: 21.541639 },
    { lat: 48.753367, lng: 21.541683 },
    { lat: 48.753278, lng: 21.541885 },
    { lat: 48.752979, lng: 21.542178 },
    { lat: 48.752457, lng: 21.542324 },
    { lat: 48.752014, lng: 21.542305 },
    { lat: 48.751715, lng: 21.542551 },
    { lat: 48.751411, lng: 21.542679 },
    { lat: 48.751033, lng: 21.54297 },
    { lat: 48.750314, lng: 21.543157 },
    { lat: 48.749751, lng: 21.543175 },
    { lat: 48.74947, lng: 21.54324 },
    { lat: 48.74924, lng: 21.543414 },
    { lat: 48.748648, lng: 21.543797 },
    { lat: 48.748012, lng: 21.543959 },
    { lat: 48.747754, lng: 21.543991 },
    { lat: 48.747539, lng: 21.54397 },
    { lat: 48.747531, lng: 21.543912 },
    { lat: 48.746631, lng: 21.544181 },
    { lat: 48.746635, lng: 21.544234 },
    { lat: 48.746221, lng: 21.5446 },
    { lat: 48.745769, lng: 21.545198 },
    { lat: 48.744863, lng: 21.545649 },
    { lat: 48.744092, lng: 21.546188 },
    { lat: 48.743837, lng: 21.546352 },
    { lat: 48.743547, lng: 21.546512 },
    { lat: 48.743197, lng: 21.546779 },
    { lat: 48.743024, lng: 21.547019 },
    { lat: 48.742804, lng: 21.547408 },
    { lat: 48.74176, lng: 21.548401 },
    { lat: 48.741357, lng: 21.548825 },
    { lat: 48.739944, lng: 21.550472 },
    { lat: 48.739799, lng: 21.550581 },
    { lat: 48.739726, lng: 21.550635 },
    { lat: 48.739193, lng: 21.551036 },
    { lat: 48.736668, lng: 21.551525 },
    { lat: 48.734874, lng: 21.551829 },
    { lat: 48.734837, lng: 21.551841 },
    { lat: 48.734772, lng: 21.551861 },
    { lat: 48.734736, lng: 21.551873 },
    { lat: 48.734723, lng: 21.551877 },
    { lat: 48.734673, lng: 21.551873 },
    { lat: 48.734664, lng: 21.551876 },
    { lat: 48.734562, lng: 21.551891 },
    { lat: 48.734168, lng: 21.551581 },
    { lat: 48.734115, lng: 21.55147 },
    { lat: 48.733955, lng: 21.551339 },
    { lat: 48.733765, lng: 21.551 },
    { lat: 48.733686, lng: 21.550805 },
    { lat: 48.733428, lng: 21.550041 },
    { lat: 48.733295, lng: 21.549766 },
    { lat: 48.733268, lng: 21.549787 },
    { lat: 48.732953, lng: 21.549703 },
    { lat: 48.732512, lng: 21.549514 },
    { lat: 48.732336, lng: 21.549425 },
    { lat: 48.732071, lng: 21.549249 },
    { lat: 48.731761, lng: 21.549149 },
    { lat: 48.731274, lng: 21.54898 },
    { lat: 48.731012, lng: 21.54883 },
    { lat: 48.730928, lng: 21.548913 },
    { lat: 48.730657, lng: 21.54857 },
    { lat: 48.730454, lng: 21.548459 },
    { lat: 48.730308, lng: 21.548395 },
    { lat: 48.730109, lng: 21.54823 },
    { lat: 48.729829, lng: 21.548342 },
    { lat: 48.72966, lng: 21.548267 },
    { lat: 48.729397, lng: 21.548055 },
    { lat: 48.729178, lng: 21.548038 },
    { lat: 48.729033, lng: 21.547746 },
    { lat: 48.728777, lng: 21.547618 },
    { lat: 48.728528, lng: 21.54749 },
    { lat: 48.728374, lng: 21.547386 },
    { lat: 48.728021, lng: 21.547312 },
    { lat: 48.727673, lng: 21.547215 },
    { lat: 48.727408, lng: 21.547168 },
    { lat: 48.727053, lng: 21.54718 },
    { lat: 48.726724, lng: 21.547092 },
    { lat: 48.726369, lng: 21.547076 },
    { lat: 48.726148, lng: 21.547009 },
    { lat: 48.725893, lng: 21.546973 },
    { lat: 48.725761, lng: 21.546889 },
    { lat: 48.725454, lng: 21.546678 },
    { lat: 48.725208, lng: 21.546477 },
    { lat: 48.724956, lng: 21.546234 },
    { lat: 48.724914, lng: 21.546152 },
    { lat: 48.724829, lng: 21.545898 },
    { lat: 48.724754, lng: 21.545799 },
    { lat: 48.724642, lng: 21.545708 },
    { lat: 48.724479, lng: 21.545632 },
    { lat: 48.724368, lng: 21.545516 },
    { lat: 48.724271, lng: 21.545455 },
    { lat: 48.724139, lng: 21.545393 },
    { lat: 48.724158, lng: 21.545317 },
    { lat: 48.723586, lng: 21.545184 },
    { lat: 48.723369, lng: 21.545434 },
    { lat: 48.723193, lng: 21.545703 },
    { lat: 48.723077, lng: 21.545739 },
    { lat: 48.722834, lng: 21.545772 },
    { lat: 48.722604, lng: 21.545782 },
    { lat: 48.722611, lng: 21.545842 },
    { lat: 48.722207, lng: 21.546047 },
    { lat: 48.721906, lng: 21.545949 },
    { lat: 48.721923, lng: 21.545884 },
    { lat: 48.72172, lng: 21.545895 },
    { lat: 48.721545, lng: 21.545913 },
    { lat: 48.72144, lng: 21.545901 },
    { lat: 48.721248, lng: 21.54582 },
    { lat: 48.72102, lng: 21.545761 },
    { lat: 48.720913, lng: 21.545689 },
    { lat: 48.720709, lng: 21.545482 },
    { lat: 48.720252, lng: 21.544946 },
    { lat: 48.719913, lng: 21.544831 },
    { lat: 48.719832, lng: 21.544811 },
    { lat: 48.719728, lng: 21.544848 },
    { lat: 48.719665, lng: 21.544868 },
    { lat: 48.719569, lng: 21.544864 },
    { lat: 48.719403, lng: 21.544817 },
    { lat: 48.71938, lng: 21.544874 },
    { lat: 48.719102, lng: 21.545018 },
    { lat: 48.719099, lng: 21.544966 },
    { lat: 48.718781, lng: 21.545037 },
    { lat: 48.718669, lng: 21.544966 },
    { lat: 48.718512, lng: 21.544751 },
    { lat: 48.718339, lng: 21.544739 },
    { lat: 48.718019, lng: 21.544534 },
    { lat: 48.717606, lng: 21.544254 },
    { lat: 48.717468, lng: 21.544088 },
    { lat: 48.71702, lng: 21.543844 },
    { lat: 48.716853, lng: 21.543736 },
    { lat: 48.716692, lng: 21.543542 },
    { lat: 48.716546, lng: 21.543182 },
    { lat: 48.716531, lng: 21.543024 },
    { lat: 48.716566, lng: 21.542686 },
    { lat: 48.716554, lng: 21.542371 },
    { lat: 48.716559, lng: 21.542234 },
    { lat: 48.716538, lng: 21.542116 },
    { lat: 48.716421, lng: 21.541841 },
    { lat: 48.716191, lng: 21.541479 },
    { lat: 48.716102, lng: 21.54149 },
    { lat: 48.715968, lng: 21.541581 },
    { lat: 48.715703, lng: 21.54196 },
    { lat: 48.715598, lng: 21.542062 },
    { lat: 48.715549, lng: 21.542101 },
    { lat: 48.715571, lng: 21.542159 },
    { lat: 48.715514, lng: 21.542241 },
    { lat: 48.715315, lng: 21.542562 },
    { lat: 48.715071, lng: 21.542525 },
    { lat: 48.71506, lng: 21.54247 },
    { lat: 48.714888, lng: 21.542524 },
    { lat: 48.714744, lng: 21.5426 },
    { lat: 48.714598, lng: 21.542675 },
    { lat: 48.714396, lng: 21.54277 },
    { lat: 48.71418, lng: 21.542785 },
    { lat: 48.714033, lng: 21.54288 },
    { lat: 48.713833, lng: 21.542965 },
    { lat: 48.713711, lng: 21.54305 },
    { lat: 48.713418, lng: 21.543237 },
    { lat: 48.712945, lng: 21.543825 },
    { lat: 48.712862, lng: 21.543973 },
    { lat: 48.712653, lng: 21.544175 },
    { lat: 48.71235, lng: 21.544321 },
    { lat: 48.712094, lng: 21.544427 },
    { lat: 48.712031, lng: 21.544525 },
    { lat: 48.711449, lng: 21.545251 },
    { lat: 48.711206, lng: 21.545458 },
    { lat: 48.710772, lng: 21.545863 },
    { lat: 48.709071, lng: 21.547537 },
    { lat: 48.708942, lng: 21.548045 },
    { lat: 48.708917, lng: 21.548086 },
    { lat: 48.7078016, lng: 21.5498984 },
    { lat: 48.7062034, lng: 21.5525063 },
    { lat: 48.7063385, lng: 21.552707 },
    { lat: 48.7063777, lng: 21.5528587 },
    { lat: 48.7063532, lng: 21.5529821 },
    { lat: 48.7065303, lng: 21.5531595 },
    { lat: 48.7067367, lng: 21.5535373 },
    { lat: 48.7072478, lng: 21.5541419 },
    { lat: 48.7073522, lng: 21.5543664 },
    { lat: 48.7074365, lng: 21.5547286 },
    { lat: 48.7079314, lng: 21.5555519 },
    { lat: 48.7080894, lng: 21.555728 },
    { lat: 48.7081414, lng: 21.555932 },
    { lat: 48.708268, lng: 21.5561137 },
    { lat: 48.7084744, lng: 21.556882 },
    { lat: 48.7087049, lng: 21.5574389 },
    { lat: 48.7087743, lng: 21.5578997 },
    { lat: 48.7088571, lng: 21.5580842 },
    { lat: 48.7089925, lng: 21.5581371 },
    { lat: 48.7094419, lng: 21.558671 },
    { lat: 48.7097383, lng: 21.558888 },
    { lat: 48.7098005, lng: 21.5590543 },
    { lat: 48.710051, lng: 21.5592808 },
    { lat: 48.710147, lng: 21.5594502 },
    { lat: 48.71025, lng: 21.5602323 },
    { lat: 48.7103544, lng: 21.5603809 },
    { lat: 48.7105507, lng: 21.5608756 },
    { lat: 48.7106211, lng: 21.5611739 },
    { lat: 48.7106979, lng: 21.5613478 },
    { lat: 48.7108723, lng: 21.5615428 },
    { lat: 48.7111867, lng: 21.5621998 },
    { lat: 48.7113316, lng: 21.5622271 },
    { lat: 48.7115571, lng: 21.5625233 },
    { lat: 48.7117058, lng: 21.5626131 },
    { lat: 48.7117705, lng: 21.5631935 },
    { lat: 48.711823, lng: 21.5632988 },
    { lat: 48.7120938, lng: 21.5634032 },
    { lat: 48.7121283, lng: 21.5636643 },
    { lat: 48.712694, lng: 21.5641768 },
    { lat: 48.7127091, lng: 21.5643084 },
    { lat: 48.7128607, lng: 21.5643834 },
    { lat: 48.7129089, lng: 21.5646532 },
    { lat: 48.7129651, lng: 21.5647337 },
    { lat: 48.7130579, lng: 21.5646721 },
    { lat: 48.7131508, lng: 21.5646975 },
    { lat: 48.7133883, lng: 21.5648165 },
    { lat: 48.7134352, lng: 21.564925 },
    { lat: 48.7138228, lng: 21.5650653 },
    { lat: 48.7140814, lng: 21.5655304 },
    { lat: 48.7143047, lng: 21.5656713 },
    { lat: 48.7146333, lng: 21.5657121 },
    { lat: 48.7150714, lng: 21.5659218 },
    { lat: 48.7154451, lng: 21.566026 },
    { lat: 48.7155326, lng: 21.5660524 },
    { lat: 48.7155938, lng: 21.5660611 },
    { lat: 48.7159991, lng: 21.5663011 },
    { lat: 48.7160654, lng: 21.5663963 },
    { lat: 48.7161673, lng: 21.5664816 },
    { lat: 48.716274, lng: 21.5666279 },
    { lat: 48.716384, lng: 21.5667488 },
    { lat: 48.7164442, lng: 21.5668436 },
    { lat: 48.7165001, lng: 21.5671004 },
    { lat: 48.7165642, lng: 21.5672691 },
    { lat: 48.716719, lng: 21.5676917 },
    { lat: 48.7168768, lng: 21.5682143 },
    { lat: 48.7169248, lng: 21.568369 },
    { lat: 48.7170045, lng: 21.5687608 },
    { lat: 48.7170657, lng: 21.5688573 },
    { lat: 48.7170967, lng: 21.5691963 },
    { lat: 48.7172034, lng: 21.5695843 },
    { lat: 48.7172121, lng: 21.5696726 },
    { lat: 48.7172415, lng: 21.5698542 },
    { lat: 48.7172556, lng: 21.569965 },
    { lat: 48.7173103, lng: 21.5702385 },
    { lat: 48.7173239, lng: 21.5704744 },
    { lat: 48.7173353, lng: 21.5706369 },
    { lat: 48.7173641, lng: 21.5708486 },
    { lat: 48.7173653, lng: 21.5710219 },
    { lat: 48.7173721, lng: 21.5711797 },
    { lat: 48.7172893, lng: 21.5713204 },
    { lat: 48.717347, lng: 21.5715291 },
    { lat: 48.7173339, lng: 21.5717063 },
    { lat: 48.7172927, lng: 21.571855 },
    { lat: 48.7172727, lng: 21.5719673 },
    { lat: 48.7172483, lng: 21.5721422 },
    { lat: 48.7172638, lng: 21.5723919 },
    { lat: 48.7172667, lng: 21.5725537 },
    { lat: 48.7172574, lng: 21.5727091 },
    { lat: 48.7171921, lng: 21.5728923 },
    { lat: 48.7171803, lng: 21.5732351 },
    { lat: 48.7172251, lng: 21.5735297 },
    { lat: 48.7173078, lng: 21.573803 },
    { lat: 48.7173656, lng: 21.5739138 },
    { lat: 48.7175318, lng: 21.5740203 },
    { lat: 48.717731, lng: 21.5742637 },
    { lat: 48.717792, lng: 21.5744218 },
    { lat: 48.7178538, lng: 21.5745459 },
    { lat: 48.7179456, lng: 21.5747659 },
    { lat: 48.7180092, lng: 21.5749471 },
    { lat: 48.7182118, lng: 21.575631 },
    { lat: 48.7182553, lng: 21.5758108 },
    { lat: 48.718266, lng: 21.5760756 },
    { lat: 48.7182235, lng: 21.5763233 },
    { lat: 48.7181937, lng: 21.5764911 },
    { lat: 48.7181778, lng: 21.5767091 },
    { lat: 48.7181478, lng: 21.57679 },
    { lat: 48.7181073, lng: 21.5768752 },
    { lat: 48.7182599, lng: 21.5775982 },
    { lat: 48.7183276, lng: 21.5777757 },
    { lat: 48.7184119, lng: 21.5778905 },
    { lat: 48.7184933, lng: 21.5780347 },
    { lat: 48.7185883, lng: 21.5782108 },
    { lat: 48.7186844, lng: 21.5783817 },
    { lat: 48.7187654, lng: 21.5786196 },
    { lat: 48.7188435, lng: 21.5788508 },
    { lat: 48.7189242, lng: 21.5789798 },
    { lat: 48.7189854, lng: 21.5790758 },
    { lat: 48.7190195, lng: 21.5791492 },
    { lat: 48.7191248, lng: 21.5793284 },
    { lat: 48.719217, lng: 21.5795172 },
    { lat: 48.719271, lng: 21.5797256 },
    { lat: 48.7194612, lng: 21.5799657 },
    { lat: 48.719424, lng: 21.5802107 },
    { lat: 48.7195158, lng: 21.5803484 },
    { lat: 48.7195417, lng: 21.5803885 },
    { lat: 48.719645, lng: 21.5803052 },
    { lat: 48.7197831, lng: 21.5803722 },
    { lat: 48.7198939, lng: 21.5806303 },
    { lat: 48.7199299, lng: 21.5808296 },
    { lat: 48.7199017, lng: 21.5809918 },
    { lat: 48.7200173, lng: 21.58102 },
    { lat: 48.7200457, lng: 21.5811493 },
    { lat: 48.7202359, lng: 21.5812725 },
    { lat: 48.7203553, lng: 21.5812346 },
    { lat: 48.7204703, lng: 21.5813241 },
    { lat: 48.7206345, lng: 21.5816036 },
    { lat: 48.7208169, lng: 21.5815663 },
    { lat: 48.7209346, lng: 21.5817529 },
    { lat: 48.7208781, lng: 21.5818677 },
    { lat: 48.7208926, lng: 21.5820211 },
    { lat: 48.7210433, lng: 21.5820574 },
    { lat: 48.7210733, lng: 21.5819985 },
    { lat: 48.7213354, lng: 21.5819638 },
    { lat: 48.7214997, lng: 21.5821013 },
    { lat: 48.7216184, lng: 21.5821908 },
    { lat: 48.721692, lng: 21.5822331 },
    { lat: 48.7218002, lng: 21.582362 },
    { lat: 48.7218299, lng: 21.5824431 },
    { lat: 48.721857, lng: 21.5825275 },
    { lat: 48.7218882, lng: 21.582656 },
    { lat: 48.7219396, lng: 21.5828533 },
    { lat: 48.7219815, lng: 21.582933 },
    { lat: 48.7221145, lng: 21.5830647 },
    { lat: 48.7222067, lng: 21.5831955 },
    { lat: 48.7222894, lng: 21.5833786 },
    { lat: 48.7223172, lng: 21.583425 },
    { lat: 48.7225336, lng: 21.5836003 },
    { lat: 48.7225309, lng: 21.5836646 },
    { lat: 48.7186079, lng: 21.5891747 },
    { lat: 48.7173624, lng: 21.5905101 },
    { lat: 48.7165145, lng: 21.5917683 },
    { lat: 48.7165691, lng: 21.5919832 },
    { lat: 48.7165705, lng: 21.5923202 },
    { lat: 48.7176549, lng: 21.5950034 },
    { lat: 48.7176875, lng: 21.5953252 },
    { lat: 48.7175974, lng: 21.5957625 },
    { lat: 48.7176527, lng: 21.5958512 },
    { lat: 48.7197795, lng: 21.5928459 },
    { lat: 48.7221699, lng: 21.5972507 },
    { lat: 48.7232719, lng: 21.5994047 },
    { lat: 48.7232316, lng: 21.5997737 },
    { lat: 48.7233973, lng: 21.6004223 },
    { lat: 48.7230146, lng: 21.6015108 },
    { lat: 48.7226214, lng: 21.601651 },
    { lat: 48.723955, lng: 21.6037978 },
    { lat: 48.7241855, lng: 21.6043622 },
    { lat: 48.7241708, lng: 21.6050025 },
    { lat: 48.7244966, lng: 21.6057953 },
    { lat: 48.7244726, lng: 21.6058218 },
    { lat: 48.724502, lng: 21.6059489 },
    { lat: 48.7245453, lng: 21.6061307 },
    { lat: 48.7267109, lng: 21.609671 },
    { lat: 48.7267559, lng: 21.6095929 },
    { lat: 48.7300237, lng: 21.6039468 },
    { lat: 48.7329368, lng: 21.5990322 },
    { lat: 48.7333813, lng: 21.597829 },
    { lat: 48.7329736, lng: 21.5972873 },
    { lat: 48.7329292, lng: 21.5967113 },
    { lat: 48.7330425, lng: 21.5966542 },
    { lat: 48.73305, lng: 21.59672 },
    { lat: 48.7339699, lng: 21.5949707 },
    { lat: 48.7340227, lng: 21.5947681 },
    { lat: 48.7343555, lng: 21.5946031 },
    { lat: 48.7360394, lng: 21.5927336 },
    { lat: 48.7371525, lng: 21.5902899 },
    { lat: 48.7379241, lng: 21.5891594 },
    { lat: 48.7385196, lng: 21.5886094 },
    { lat: 48.7389913, lng: 21.5883353 },
    { lat: 48.7397994, lng: 21.5876248 },
    { lat: 48.740606, lng: 21.5870523 },
    { lat: 48.7412973, lng: 21.5862284 },
    { lat: 48.7429539, lng: 21.5861957 },
    { lat: 48.7435737, lng: 21.5849909 },
    { lat: 48.7443398, lng: 21.5843475 },
    { lat: 48.7449302, lng: 21.5832144 },
    { lat: 48.7454945, lng: 21.5825511 },
    { lat: 48.7459748, lng: 21.5818636 },
    { lat: 48.7473727, lng: 21.5815193 },
    { lat: 48.7478132, lng: 21.581177 },
    { lat: 48.7480768, lng: 21.5811518 },
    { lat: 48.7486945, lng: 21.5805877 },
    { lat: 48.748723, lng: 21.580639 },
    { lat: 48.7488161, lng: 21.5805097 },
    { lat: 48.7494899, lng: 21.5800632 },
    { lat: 48.7496438, lng: 21.5800547 },
    { lat: 48.7499107, lng: 21.5795826 },
    { lat: 48.7500602, lng: 21.5796946 },
    { lat: 48.7505648, lng: 21.5796779 },
    { lat: 48.7508763, lng: 21.5795876 },
    { lat: 48.7513142, lng: 21.5793555 },
    { lat: 48.7518652, lng: 21.5787083 },
    { lat: 48.752351, lng: 21.5785352 },
    { lat: 48.7531503, lng: 21.5780745 },
    { lat: 48.7535425, lng: 21.5775322 },
    { lat: 48.7539707, lng: 21.5773575 },
    { lat: 48.753905, lng: 21.5773417 },
    { lat: 48.7542799, lng: 21.5772274 },
    { lat: 48.7555313, lng: 21.5774761 },
    { lat: 48.7568406, lng: 21.5764191 },
    { lat: 48.757225, lng: 21.5758107 },
    { lat: 48.7580759, lng: 21.5757749 },
    { lat: 48.7592464, lng: 21.5751828 },
    { lat: 48.7610569, lng: 21.5745118 },
    { lat: 48.762529, lng: 21.5737292 },
    { lat: 48.7631847, lng: 21.5725557 },
    { lat: 48.764002, lng: 21.571252 },
    { lat: 48.7647949, lng: 21.5703642 },
    { lat: 48.7654149, lng: 21.57032 },
    { lat: 48.765713, lng: 21.5704384 },
    { lat: 48.7666431, lng: 21.5700297 },
    { lat: 48.7671511, lng: 21.569722 },
    { lat: 48.7674958, lng: 21.5692617 },
    { lat: 48.7685144, lng: 21.5683929 },
    { lat: 48.7703169, lng: 21.5671541 },
    { lat: 48.7709002, lng: 21.5670548 },
    { lat: 48.771694, lng: 21.5671088 },
    { lat: 48.7728684, lng: 21.5662233 },
    { lat: 48.7739472, lng: 21.5658587 },
    { lat: 48.7747188, lng: 21.5659327 },
    { lat: 48.775891, lng: 21.5658803 },
    { lat: 48.7767039, lng: 21.566263 },
    { lat: 48.7771865, lng: 21.565776 },
    { lat: 48.7776674, lng: 21.5648367 },
    { lat: 48.7780271, lng: 21.5642651 },
    { lat: 48.7783357, lng: 21.5635606 },
    { lat: 48.7784871, lng: 21.5630164 },
    { lat: 48.7785507, lng: 21.5624051 },
    { lat: 48.778516, lng: 21.5618526 },
    { lat: 48.7788033, lng: 21.5607474 },
    { lat: 48.7791475, lng: 21.5597526 },
    { lat: 48.779317, lng: 21.5590171 },
    { lat: 48.7794686, lng: 21.5585656 },
    { lat: 48.7790998, lng: 21.5576623 },
    { lat: 48.7789282, lng: 21.5564451 },
    { lat: 48.7795024, lng: 21.5556637 },
    { lat: 48.7815781, lng: 21.5546199 },
    { lat: 48.7832473, lng: 21.5536074 },
    { lat: 48.7840079, lng: 21.5530126 },
    { lat: 48.7843267, lng: 21.5528649 },
    { lat: 48.7855923, lng: 21.5520208 },
    { lat: 48.7878941, lng: 21.5499851 },
    { lat: 48.7895043, lng: 21.5483577 },
    { lat: 48.7909507, lng: 21.5466313 },
    { lat: 48.7923429, lng: 21.5452152 },
    { lat: 48.7927022, lng: 21.5449913 },
    { lat: 48.793062, lng: 21.544969 },
    { lat: 48.793692, lng: 21.544275 },
    { lat: 48.793939, lng: 21.543944 },
    { lat: 48.794137, lng: 21.543758 },
    { lat: 48.794291, lng: 21.543355 },
    { lat: 48.794317, lng: 21.542335 },
    { lat: 48.794077, lng: 21.541568 },
  ],
  Slivník: [
    { lat: 48.6010926, lng: 21.5885976 },
    { lat: 48.6038211, lng: 21.5869516 },
    { lat: 48.6065821, lng: 21.5852645 },
    { lat: 48.6070453, lng: 21.5849259 },
    { lat: 48.6072117, lng: 21.5848474 },
    { lat: 48.6078799, lng: 21.5848405 },
    { lat: 48.6088126, lng: 21.584361 },
    { lat: 48.6097712, lng: 21.5836778 },
    { lat: 48.610137, lng: 21.5833518 },
    { lat: 48.6106957, lng: 21.5826967 },
    { lat: 48.6128193, lng: 21.579159 },
    { lat: 48.6133266, lng: 21.5789114 },
    { lat: 48.614646, lng: 21.577036 },
    { lat: 48.614644, lng: 21.576944 },
    { lat: 48.614632, lng: 21.576352 },
    { lat: 48.614339, lng: 21.575362 },
    { lat: 48.614148, lng: 21.574908 },
    { lat: 48.613542, lng: 21.574007 },
    { lat: 48.613261, lng: 21.573744 },
    { lat: 48.613221, lng: 21.573706 },
    { lat: 48.613182, lng: 21.573669 },
    { lat: 48.613068, lng: 21.57356 },
    { lat: 48.612862, lng: 21.573249 },
    { lat: 48.61258, lng: 21.572582 },
    { lat: 48.612231, lng: 21.572098 },
    { lat: 48.611479, lng: 21.570809 },
    { lat: 48.611261, lng: 21.570535 },
    { lat: 48.611147, lng: 21.570328 },
    { lat: 48.611108, lng: 21.570199 },
    { lat: 48.610943, lng: 21.569926 },
    { lat: 48.610627, lng: 21.569655 },
    { lat: 48.610112, lng: 21.569323 },
    { lat: 48.610022, lng: 21.569256 },
    { lat: 48.609937, lng: 21.569198 },
    { lat: 48.609096, lng: 21.568628 },
    { lat: 48.608643, lng: 21.568174 },
    { lat: 48.608588, lng: 21.568068 },
    { lat: 48.608443, lng: 21.567911 },
    { lat: 48.608339, lng: 21.567839 },
    { lat: 48.607517, lng: 21.567231 },
    { lat: 48.607554, lng: 21.567184 },
    { lat: 48.607566, lng: 21.56688 },
    { lat: 48.607575, lng: 21.566657 },
    { lat: 48.607592, lng: 21.566287 },
    { lat: 48.607594, lng: 21.566246 },
    { lat: 48.6076, lng: 21.566208 },
    { lat: 48.607629, lng: 21.566032 },
    { lat: 48.607656, lng: 21.565815 },
    { lat: 48.607709, lng: 21.565509 },
    { lat: 48.60776, lng: 21.565329 },
    { lat: 48.607593, lng: 21.564948 },
    { lat: 48.607473, lng: 21.564725 },
    { lat: 48.607426, lng: 21.564608 },
    { lat: 48.607461, lng: 21.564575 },
    { lat: 48.608053, lng: 21.564058 },
    { lat: 48.608165, lng: 21.563405 },
    { lat: 48.608306, lng: 21.5627 },
    { lat: 48.608311, lng: 21.562675 },
    { lat: 48.608315, lng: 21.562606 },
    { lat: 48.608464, lng: 21.562377 },
    { lat: 48.608618, lng: 21.562409 },
    { lat: 48.608752, lng: 21.562255 },
    { lat: 48.60878, lng: 21.562261 },
    { lat: 48.608886, lng: 21.562509 },
    { lat: 48.609036, lng: 21.562619 },
    { lat: 48.609096, lng: 21.56248 },
    { lat: 48.609158, lng: 21.562385 },
    { lat: 48.609202, lng: 21.562268 },
    { lat: 48.609331, lng: 21.562103 },
    { lat: 48.609357, lng: 21.561982 },
    { lat: 48.609305, lng: 21.561613 },
    { lat: 48.60933, lng: 21.561585 },
    { lat: 48.609538, lng: 21.561746 },
    { lat: 48.609648, lng: 21.561903 },
    { lat: 48.609822, lng: 21.562157 },
    { lat: 48.610056, lng: 21.56184 },
    { lat: 48.610257, lng: 21.561755 },
    { lat: 48.610277, lng: 21.561532 },
    { lat: 48.610248, lng: 21.561313 },
    { lat: 48.61042, lng: 21.560927 },
    { lat: 48.610413, lng: 21.560815 },
    { lat: 48.610341, lng: 21.560724 },
    { lat: 48.610221, lng: 21.560667 },
    { lat: 48.609883, lng: 21.560808 },
    { lat: 48.609863, lng: 21.560781 },
    { lat: 48.609903, lng: 21.560516 },
    { lat: 48.61003, lng: 21.560255 },
    { lat: 48.610218, lng: 21.560131 },
    { lat: 48.610291, lng: 21.559783 },
    { lat: 48.610325, lng: 21.559749 },
    { lat: 48.610443, lng: 21.559973 },
    { lat: 48.61055, lng: 21.560011 },
    { lat: 48.61077, lng: 21.559616 },
    { lat: 48.610769, lng: 21.559601 },
    { lat: 48.610801, lng: 21.559302 },
    { lat: 48.610932, lng: 21.55927 },
    { lat: 48.611019, lng: 21.559199 },
    { lat: 48.611025, lng: 21.55917 },
    { lat: 48.611005, lng: 21.559044 },
    { lat: 48.611019, lng: 21.558741 },
    { lat: 48.611005, lng: 21.558711 },
    { lat: 48.610902, lng: 21.558674 },
    { lat: 48.610824, lng: 21.558536 },
    { lat: 48.610756, lng: 21.558446 },
    { lat: 48.610734, lng: 21.558364 },
    { lat: 48.610848, lng: 21.558219 },
    { lat: 48.6108774, lng: 21.5582199 },
    { lat: 48.611017, lng: 21.558224 },
    { lat: 48.611088, lng: 21.558154 },
    { lat: 48.611167, lng: 21.558118 },
    { lat: 48.611327, lng: 21.558006 },
    { lat: 48.611333, lng: 21.557969 },
    { lat: 48.611322, lng: 21.557949 },
    { lat: 48.6112062, lng: 21.5578759 },
    { lat: 48.611154, lng: 21.557843 },
    { lat: 48.611065, lng: 21.557874 },
    { lat: 48.611048, lng: 21.557735 },
    { lat: 48.611102, lng: 21.557612 },
    { lat: 48.611058, lng: 21.557409 },
    { lat: 48.610801, lng: 21.557225 },
    { lat: 48.610732, lng: 21.557071 },
    { lat: 48.610734, lng: 21.556998 },
    { lat: 48.610781, lng: 21.556891 },
    { lat: 48.610875, lng: 21.556843 },
    { lat: 48.61097, lng: 21.556902 },
    { lat: 48.611074, lng: 21.556854 },
    { lat: 48.611143, lng: 21.556907 },
    { lat: 48.611234, lng: 21.557057 },
    { lat: 48.611501, lng: 21.557291 },
    { lat: 48.611795, lng: 21.557152 },
    { lat: 48.611886, lng: 21.557003 },
    { lat: 48.611862, lng: 21.556846 },
    { lat: 48.611906, lng: 21.556588 },
    { lat: 48.611984, lng: 21.556523 },
    { lat: 48.611993, lng: 21.556476 },
    { lat: 48.611949, lng: 21.556304 },
    { lat: 48.611852, lng: 21.556162 },
    { lat: 48.611761, lng: 21.556103 },
    { lat: 48.61176, lng: 21.555949 },
    { lat: 48.6118309, lng: 21.5557118 },
    { lat: 48.611765, lng: 21.555559 },
    { lat: 48.611807, lng: 21.555447 },
    { lat: 48.612046, lng: 21.55534 },
    { lat: 48.612118, lng: 21.555257 },
    { lat: 48.61222, lng: 21.555056 },
    { lat: 48.612224, lng: 21.554952 },
    { lat: 48.612233, lng: 21.554855 },
    { lat: 48.612291, lng: 21.554721 },
    { lat: 48.612347, lng: 21.554592 },
    { lat: 48.612458, lng: 21.554518 },
    { lat: 48.612591, lng: 21.554621 },
    { lat: 48.612719, lng: 21.554581 },
    { lat: 48.612896, lng: 21.55458 },
    { lat: 48.612947, lng: 21.554666 },
    { lat: 48.613065, lng: 21.554671 },
    { lat: 48.613075, lng: 21.554665 },
    { lat: 48.6131943, lng: 21.5544715 },
    { lat: 48.613184, lng: 21.554208 },
    { lat: 48.613173, lng: 21.554177 },
    { lat: 48.613023, lng: 21.554164 },
    { lat: 48.612971, lng: 21.554112 },
    { lat: 48.612907, lng: 21.554048 },
    { lat: 48.6128841, lng: 21.5540031 },
    { lat: 48.612857, lng: 21.55395 },
    { lat: 48.612831, lng: 21.553811 },
    { lat: 48.612877, lng: 21.553657 },
    { lat: 48.612985, lng: 21.553609 },
    { lat: 48.61308, lng: 21.553712 },
    { lat: 48.613102, lng: 21.553705 },
    { lat: 48.613157, lng: 21.553606 },
    { lat: 48.613168, lng: 21.553443 },
    { lat: 48.61337, lng: 21.553355 },
    { lat: 48.613378, lng: 21.553328 },
    { lat: 48.613378, lng: 21.553066 },
    { lat: 48.6133656, lng: 21.5529653 },
    { lat: 48.613349, lng: 21.552831 },
    { lat: 48.613303, lng: 21.552568 },
    { lat: 48.613349, lng: 21.552484 },
    { lat: 48.613526, lng: 21.552426 },
    { lat: 48.613541, lng: 21.552394 },
    { lat: 48.613521, lng: 21.552265 },
    { lat: 48.613626, lng: 21.552136 },
    { lat: 48.613752, lng: 21.552055 },
    { lat: 48.614027, lng: 21.551832 },
    { lat: 48.614144, lng: 21.551598 },
    { lat: 48.6142601, lng: 21.5515357 },
    { lat: 48.614361, lng: 21.551683 },
    { lat: 48.614348, lng: 21.551819 },
    { lat: 48.61449, lng: 21.55207 },
    { lat: 48.614513, lng: 21.552069 },
    { lat: 48.614589, lng: 21.551949 },
    { lat: 48.614576, lng: 21.551899 },
    { lat: 48.61418, lng: 21.550323 },
    { lat: 48.614057, lng: 21.550191 },
    { lat: 48.613914, lng: 21.550034 },
    { lat: 48.613676, lng: 21.549772 },
    { lat: 48.613224, lng: 21.549488 },
    { lat: 48.61287, lng: 21.549266 },
    { lat: 48.612584, lng: 21.54905 },
    { lat: 48.612449, lng: 21.548851 },
    { lat: 48.611979, lng: 21.548415 },
    { lat: 48.611598, lng: 21.548043 },
    { lat: 48.611539, lng: 21.547986 },
    { lat: 48.611535, lng: 21.547983 },
    { lat: 48.611444, lng: 21.547894 },
    { lat: 48.61137, lng: 21.547822 },
    { lat: 48.61132, lng: 21.547706 },
    { lat: 48.611302, lng: 21.547661 },
    { lat: 48.611301, lng: 21.547661 },
    { lat: 48.611227, lng: 21.547552 },
    { lat: 48.61113, lng: 21.547406 },
    { lat: 48.611002, lng: 21.547222 },
    { lat: 48.61066, lng: 21.546873 },
    { lat: 48.610561, lng: 21.546764 },
    { lat: 48.610448, lng: 21.546306 },
    { lat: 48.610539, lng: 21.545933 },
    { lat: 48.6106, lng: 21.545884 },
    { lat: 48.610773, lng: 21.545839 },
    { lat: 48.610839, lng: 21.545696 },
    { lat: 48.61085, lng: 21.545584 },
    { lat: 48.611033, lng: 21.54537 },
    { lat: 48.611041, lng: 21.545345 },
    { lat: 48.610989, lng: 21.545139 },
    { lat: 48.610913, lng: 21.545028 },
    { lat: 48.610918, lng: 21.5448 },
    { lat: 48.610949, lng: 21.544619 },
    { lat: 48.611008, lng: 21.5445 },
    { lat: 48.611022, lng: 21.544393 },
    { lat: 48.610997, lng: 21.544373 },
    { lat: 48.610782, lng: 21.544277 },
    { lat: 48.610569, lng: 21.544225 },
    { lat: 48.610533, lng: 21.54411 },
    { lat: 48.610381, lng: 21.543942 },
    { lat: 48.610127, lng: 21.54378 },
    { lat: 48.610078, lng: 21.543707 },
    { lat: 48.610055, lng: 21.543678 },
    { lat: 48.610036, lng: 21.543658 },
    { lat: 48.610137, lng: 21.54319 },
    { lat: 48.610119, lng: 21.543166 },
    { lat: 48.609893, lng: 21.542937 },
    { lat: 48.608972, lng: 21.541625 },
    { lat: 48.608499, lng: 21.540934 },
    { lat: 48.608717, lng: 21.540561 },
    { lat: 48.608212, lng: 21.53976 },
    { lat: 48.608329, lng: 21.539577 },
    { lat: 48.608484, lng: 21.539493 },
    { lat: 48.608581, lng: 21.539363 },
    { lat: 48.608451, lng: 21.539146 },
    { lat: 48.608202, lng: 21.538818 },
    { lat: 48.607996, lng: 21.53864 },
    { lat: 48.607872, lng: 21.538403 },
    { lat: 48.607678, lng: 21.538211 },
    { lat: 48.607445, lng: 21.538056 },
    { lat: 48.607172, lng: 21.537946 },
    { lat: 48.606784, lng: 21.537516 },
    { lat: 48.60626, lng: 21.536687 },
    { lat: 48.606174, lng: 21.536431 },
    { lat: 48.606119, lng: 21.536071 },
    { lat: 48.606306, lng: 21.535107 },
    { lat: 48.60647, lng: 21.534258 },
    { lat: 48.606826, lng: 21.5325 },
    { lat: 48.606948, lng: 21.531966 },
    { lat: 48.607034, lng: 21.531591 },
    { lat: 48.607055, lng: 21.531482 },
    { lat: 48.607068, lng: 21.531396 },
    { lat: 48.606984, lng: 21.531348 },
    { lat: 48.606854, lng: 21.531329 },
    { lat: 48.606751, lng: 21.53115 },
    { lat: 48.606498, lng: 21.53098 },
    { lat: 48.606362, lng: 21.530783 },
    { lat: 48.606244, lng: 21.530744 },
    { lat: 48.605963, lng: 21.530459 },
    { lat: 48.605478, lng: 21.53023 },
    { lat: 48.605256, lng: 21.52999 },
    { lat: 48.604851, lng: 21.52977 },
    { lat: 48.604743, lng: 21.529605 },
    { lat: 48.604619, lng: 21.529516 },
    { lat: 48.604462, lng: 21.529402 },
    { lat: 48.604289, lng: 21.529283 },
    { lat: 48.603513, lng: 21.528768 },
    { lat: 48.603335, lng: 21.528654 },
    { lat: 48.602837, lng: 21.528315 },
    { lat: 48.602523, lng: 21.528102 },
    { lat: 48.602334, lng: 21.527704 },
    { lat: 48.602201, lng: 21.527421 },
    { lat: 48.602132, lng: 21.527274 },
    { lat: 48.60191, lng: 21.526801 },
    { lat: 48.601832, lng: 21.526633 },
    { lat: 48.600767, lng: 21.524348 },
    { lat: 48.600662, lng: 21.523392 },
    { lat: 48.600652, lng: 21.523299 },
    { lat: 48.600611, lng: 21.522979 },
    { lat: 48.60064, lng: 21.522698 },
    { lat: 48.600733, lng: 21.522363 },
    { lat: 48.601097, lng: 21.52145 },
    { lat: 48.601183, lng: 21.521149 },
    { lat: 48.601212, lng: 21.520919 },
    { lat: 48.601199, lng: 21.520692 },
    { lat: 48.601144, lng: 21.520442 },
    { lat: 48.600995, lng: 21.520028 },
    { lat: 48.600823, lng: 21.519668 },
    { lat: 48.600642, lng: 21.519377 },
    { lat: 48.600354, lng: 21.518992 },
    { lat: 48.600199, lng: 21.518689 },
    { lat: 48.599996, lng: 21.518398 },
    { lat: 48.599576, lng: 21.517943 },
    { lat: 48.599322, lng: 21.517484 },
    { lat: 48.59906, lng: 21.517156 },
    { lat: 48.598915, lng: 21.516796 },
    { lat: 48.598721, lng: 21.516433 },
    { lat: 48.598501, lng: 21.516197 },
    { lat: 48.598204, lng: 21.515965 },
    { lat: 48.597908, lng: 21.515795 },
    { lat: 48.596655, lng: 21.514973 },
    { lat: 48.596253, lng: 21.514611 },
    { lat: 48.596072, lng: 21.514477 },
    { lat: 48.595991, lng: 21.514467 },
    { lat: 48.59509, lng: 21.514503 },
    { lat: 48.594744, lng: 21.514739 },
    { lat: 48.594109, lng: 21.514955 },
    { lat: 48.594053, lng: 21.514985 },
    { lat: 48.593334, lng: 21.515375 },
    { lat: 48.593258, lng: 21.515434 },
    { lat: 48.592886, lng: 21.515754 },
    { lat: 48.592851, lng: 21.51579 },
    { lat: 48.592842, lng: 21.51587 },
    { lat: 48.592757, lng: 21.516018 },
    { lat: 48.592789, lng: 21.516118 },
    { lat: 48.592546, lng: 21.516473 },
    { lat: 48.592559, lng: 21.516643 },
    { lat: 48.592421, lng: 21.516754 },
    { lat: 48.592412, lng: 21.517069 },
    { lat: 48.592206, lng: 21.517331 },
    { lat: 48.592099, lng: 21.517553 },
    { lat: 48.592034, lng: 21.517813 },
    { lat: 48.591869, lng: 21.517719 },
    { lat: 48.591791, lng: 21.517868 },
    { lat: 48.591801, lng: 21.517906 },
    { lat: 48.591703, lng: 21.518212 },
    { lat: 48.591551, lng: 21.518404 },
    { lat: 48.591531, lng: 21.518883 },
    { lat: 48.591422, lng: 21.519199 },
    { lat: 48.591316, lng: 21.519709 },
    { lat: 48.59121, lng: 21.519778 },
    { lat: 48.591123, lng: 21.520086 },
    { lat: 48.591109, lng: 21.520134 },
    { lat: 48.591044, lng: 21.520429 },
    { lat: 48.591078, lng: 21.520731 },
    { lat: 48.59091, lng: 21.521188 },
    { lat: 48.590979, lng: 21.52152 },
    { lat: 48.590944, lng: 21.521763 },
    { lat: 48.590981, lng: 21.521969 },
    { lat: 48.590991, lng: 21.522264 },
    { lat: 48.590938, lng: 21.522487 },
    { lat: 48.590959, lng: 21.522842 },
    { lat: 48.59094, lng: 21.523456 },
    { lat: 48.59104, lng: 21.523736 },
    { lat: 48.590842, lng: 21.523892 },
    { lat: 48.590914, lng: 21.52404 },
    { lat: 48.591001, lng: 21.524177 },
    { lat: 48.59098, lng: 21.524332 },
    { lat: 48.591095, lng: 21.524595 },
    { lat: 48.591038, lng: 21.524887 },
    { lat: 48.59104, lng: 21.525088 },
    { lat: 48.591183, lng: 21.52532 },
    { lat: 48.59094, lng: 21.525462 },
    { lat: 48.590961, lng: 21.525719 },
    { lat: 48.590941, lng: 21.525965 },
    { lat: 48.590804, lng: 21.526018 },
    { lat: 48.590815, lng: 21.526414 },
    { lat: 48.590678, lng: 21.526733 },
    { lat: 48.590497, lng: 21.52695 },
    { lat: 48.590603, lng: 21.52701 },
    { lat: 48.590553, lng: 21.527191 },
    { lat: 48.590526, lng: 21.527522 },
    { lat: 48.59065, lng: 21.527661 },
    { lat: 48.590611, lng: 21.527826 },
    { lat: 48.59035, lng: 21.528115 },
    { lat: 48.590307, lng: 21.528142 },
    { lat: 48.590204, lng: 21.528381 },
    { lat: 48.590185, lng: 21.528526 },
    { lat: 48.590052, lng: 21.528606 },
    { lat: 48.590037, lng: 21.528708 },
    { lat: 48.590027, lng: 21.52882 },
    { lat: 48.590156, lng: 21.529248 },
    { lat: 48.590263, lng: 21.529425 },
    { lat: 48.590258, lng: 21.529588 },
    { lat: 48.590445, lng: 21.529771 },
    { lat: 48.590475, lng: 21.529974 },
    { lat: 48.590509, lng: 21.530215 },
    { lat: 48.590372, lng: 21.530435 },
    { lat: 48.590392, lng: 21.530642 },
    { lat: 48.590185, lng: 21.530742 },
    { lat: 48.590089, lng: 21.530918 },
    { lat: 48.590032, lng: 21.531312 },
    { lat: 48.589853, lng: 21.531734 },
    { lat: 48.58967, lng: 21.531843 },
    { lat: 48.589601, lng: 21.532027 },
    { lat: 48.589389, lng: 21.532077 },
    { lat: 48.589294, lng: 21.532359 },
    { lat: 48.589161, lng: 21.532398 },
    { lat: 48.588975, lng: 21.53282 },
    { lat: 48.589007, lng: 21.533249 },
    { lat: 48.588889, lng: 21.533485 },
    { lat: 48.588833, lng: 21.533711 },
    { lat: 48.58872, lng: 21.533919 },
    { lat: 48.58873, lng: 21.534181 },
    { lat: 48.588617, lng: 21.534458 },
    { lat: 48.588496, lng: 21.534622 },
    { lat: 48.588447, lng: 21.534831 },
    { lat: 48.588491, lng: 21.535028 },
    { lat: 48.588311, lng: 21.535211 },
    { lat: 48.588328, lng: 21.535418 },
    { lat: 48.588295, lng: 21.535612 },
    { lat: 48.588353, lng: 21.535873 },
    { lat: 48.588381, lng: 21.536131 },
    { lat: 48.588335, lng: 21.536458 },
    { lat: 48.588469, lng: 21.536625 },
    { lat: 48.588538, lng: 21.536656 },
    { lat: 48.588559, lng: 21.536926 },
    { lat: 48.588651, lng: 21.537245 },
    { lat: 48.588769, lng: 21.537425 },
    { lat: 48.588976, lng: 21.53751 },
    { lat: 48.589025, lng: 21.537667 },
    { lat: 48.588979, lng: 21.538003 },
    { lat: 48.58906, lng: 21.538207 },
    { lat: 48.589208, lng: 21.538338 },
    { lat: 48.58913, lng: 21.538554 },
    { lat: 48.589072, lng: 21.538766 },
    { lat: 48.589001, lng: 21.539182 },
    { lat: 48.589136, lng: 21.539144 },
    { lat: 48.589159, lng: 21.539487 },
    { lat: 48.589139, lng: 21.539737 },
    { lat: 48.589094, lng: 21.539952 },
    { lat: 48.589002, lng: 21.539948 },
    { lat: 48.58893, lng: 21.5403767 },
    { lat: 48.5888963, lng: 21.5406081 },
    { lat: 48.5889619, lng: 21.5406589 },
    { lat: 48.5891217, lng: 21.5406265 },
    { lat: 48.5890952, lng: 21.5408024 },
    { lat: 48.5892608, lng: 21.5414769 },
    { lat: 48.5894688, lng: 21.5416266 },
    { lat: 48.5895571, lng: 21.5419262 },
    { lat: 48.5897497, lng: 21.5422087 },
    { lat: 48.5897473, lng: 21.5432649 },
    { lat: 48.5894812, lng: 21.5435682 },
    { lat: 48.5894373, lng: 21.5439211 },
    { lat: 48.5895583, lng: 21.5443377 },
    { lat: 48.58951, lng: 21.5444638 },
    { lat: 48.5894023, lng: 21.5445479 },
    { lat: 48.5892438, lng: 21.5444373 },
    { lat: 48.5891222, lng: 21.5445159 },
    { lat: 48.5890718, lng: 21.5446837 },
    { lat: 48.5891649, lng: 21.5448618 },
    { lat: 48.5891884, lng: 21.5454158 },
    { lat: 48.5891129, lng: 21.5457708 },
    { lat: 48.5889855, lng: 21.5459567 },
    { lat: 48.5888164, lng: 21.545979 },
    { lat: 48.588611, lng: 21.5467515 },
    { lat: 48.5886894, lng: 21.5475678 },
    { lat: 48.5885076, lng: 21.5477072 },
    { lat: 48.5885347, lng: 21.5482186 },
    { lat: 48.5883305, lng: 21.5486022 },
    { lat: 48.5882364, lng: 21.5490891 },
    { lat: 48.5882011, lng: 21.5500219 },
    { lat: 48.5882379, lng: 21.5507687 },
    { lat: 48.5881474, lng: 21.5509018 },
    { lat: 48.5880289, lng: 21.5508981 },
    { lat: 48.5880229, lng: 21.5509998 },
    { lat: 48.5878641, lng: 21.5510715 },
    { lat: 48.5877456, lng: 21.551216 },
    { lat: 48.5877129, lng: 21.5513773 },
    { lat: 48.5878003, lng: 21.5515583 },
    { lat: 48.5877225, lng: 21.5523043 },
    { lat: 48.5879548, lng: 21.5523647 },
    { lat: 48.5879726, lng: 21.5525156 },
    { lat: 48.5877407, lng: 21.5528288 },
    { lat: 48.5877414, lng: 21.5534095 },
    { lat: 48.5879536, lng: 21.5537487 },
    { lat: 48.5880185, lng: 21.5552771 },
    { lat: 48.5879615, lng: 21.5554151 },
    { lat: 48.5879566, lng: 21.5564717 },
    { lat: 48.5881214, lng: 21.5567276 },
    { lat: 48.5880664, lng: 21.5571655 },
    { lat: 48.5879227, lng: 21.5575344 },
    { lat: 48.5879669, lng: 21.5578241 },
    { lat: 48.5879115, lng: 21.5581294 },
    { lat: 48.5881913, lng: 21.5587514 },
    { lat: 48.5883087, lng: 21.5588398 },
    { lat: 48.5884723, lng: 21.5592139 },
    { lat: 48.588481, lng: 21.5599202 },
    { lat: 48.588608, lng: 21.5601914 },
    { lat: 48.5886195, lng: 21.5606381 },
    { lat: 48.5885659, lng: 21.5609696 },
    { lat: 48.5886596, lng: 21.5611807 },
    { lat: 48.5885039, lng: 21.5614847 },
    { lat: 48.5885059, lng: 21.5619128 },
    { lat: 48.5886297, lng: 21.5619557 },
    { lat: 48.5886604, lng: 21.5620834 },
    { lat: 48.588543, lng: 21.5623078 },
    { lat: 48.5884115, lng: 21.5629528 },
    { lat: 48.5883272, lng: 21.5631118 },
    { lat: 48.5883581, lng: 21.5635292 },
    { lat: 48.5885812, lng: 21.5638969 },
    { lat: 48.5885507, lng: 21.5641288 },
    { lat: 48.5884553, lng: 21.5641833 },
    { lat: 48.5884363, lng: 21.5646177 },
    { lat: 48.5883564, lng: 21.5647039 },
    { lat: 48.5884153, lng: 21.5651934 },
    { lat: 48.588373, lng: 21.565322 },
    { lat: 48.5884934, lng: 21.565686 },
    { lat: 48.5882299, lng: 21.5662321 },
    { lat: 48.5883078, lng: 21.5671945 },
    { lat: 48.5882595, lng: 21.5674788 },
    { lat: 48.5881732, lng: 21.56757 },
    { lat: 48.5884146, lng: 21.568186 },
    { lat: 48.5884621, lng: 21.5684389 },
    { lat: 48.5884308, lng: 21.5686826 },
    { lat: 48.5884634, lng: 21.568877 },
    { lat: 48.5885589, lng: 21.5691108 },
    { lat: 48.5890061, lng: 21.5712419 },
    { lat: 48.5900253, lng: 21.5722228 },
    { lat: 48.5908257, lng: 21.5714041 },
    { lat: 48.5913667, lng: 21.5723169 },
    { lat: 48.588845, lng: 21.5761345 },
    { lat: 48.5844822, lng: 21.5778392 },
    { lat: 48.584573, lng: 21.5780651 },
    { lat: 48.5847319, lng: 21.5781907 },
    { lat: 48.5846525, lng: 21.5785281 },
    { lat: 48.5846986, lng: 21.5786477 },
    { lat: 48.5848756, lng: 21.5785792 },
    { lat: 48.5849262, lng: 21.5784185 },
    { lat: 48.5853209, lng: 21.5785673 },
    { lat: 48.5853143, lng: 21.5787771 },
    { lat: 48.5851826, lng: 21.5789319 },
    { lat: 48.585218, lng: 21.579032 },
    { lat: 48.5853675, lng: 21.5789908 },
    { lat: 48.5857098, lng: 21.5791143 },
    { lat: 48.5860043, lng: 21.5790236 },
    { lat: 48.5861408, lng: 21.5790803 },
    { lat: 48.5864678, lng: 21.5788617 },
    { lat: 48.5866288, lng: 21.5789022 },
    { lat: 48.5867817, lng: 21.5788217 },
    { lat: 48.5872414, lng: 21.5814788 },
    { lat: 48.5876279, lng: 21.5851349 },
    { lat: 48.5879916, lng: 21.5851479 },
    { lat: 48.5894026, lng: 21.585427 },
    { lat: 48.5901245, lng: 21.5857239 },
    { lat: 48.5912804, lng: 21.5863825 },
    { lat: 48.591616, lng: 21.5867538 },
    { lat: 48.5919194, lng: 21.5868173 },
    { lat: 48.5921509, lng: 21.5869685 },
    { lat: 48.5923704, lng: 21.5868503 },
    { lat: 48.5924931, lng: 21.5865677 },
    { lat: 48.5926887, lng: 21.5865641 },
    { lat: 48.5928299, lng: 21.5864627 },
    { lat: 48.5928852, lng: 21.5863496 },
    { lat: 48.5929695, lng: 21.5863453 },
    { lat: 48.5930128, lng: 21.5864442 },
    { lat: 48.5931125, lng: 21.5863483 },
    { lat: 48.5932841, lng: 21.5863309 },
    { lat: 48.5933308, lng: 21.5864665 },
    { lat: 48.5934212, lng: 21.5864846 },
    { lat: 48.5934668, lng: 21.58638 },
    { lat: 48.5935759, lng: 21.5863851 },
    { lat: 48.5937108, lng: 21.5862212 },
    { lat: 48.5939515, lng: 21.5864098 },
    { lat: 48.594158, lng: 21.5860334 },
    { lat: 48.5944852, lng: 21.5861902 },
    { lat: 48.5945236, lng: 21.5862698 },
    { lat: 48.5945873, lng: 21.5862572 },
    { lat: 48.5945872, lng: 21.5864111 },
    { lat: 48.5946593, lng: 21.5863354 },
    { lat: 48.5947347, lng: 21.5864593 },
    { lat: 48.5948305, lng: 21.586504 },
    { lat: 48.5948202, lng: 21.5867002 },
    { lat: 48.5950677, lng: 21.5868567 },
    { lat: 48.5951942, lng: 21.5866347 },
    { lat: 48.5952389, lng: 21.5866649 },
    { lat: 48.5952553, lng: 21.5870456 },
    { lat: 48.5954517, lng: 21.5871048 },
    { lat: 48.5955464, lng: 21.587033 },
    { lat: 48.5956057, lng: 21.5870644 },
    { lat: 48.5956538, lng: 21.5869718 },
    { lat: 48.5957386, lng: 21.5869852 },
    { lat: 48.5957557, lng: 21.5868714 },
    { lat: 48.5959825, lng: 21.5868541 },
    { lat: 48.596323, lng: 21.5865601 },
    { lat: 48.5964173, lng: 21.5865533 },
    { lat: 48.5964291, lng: 21.5864527 },
    { lat: 48.5965681, lng: 21.5863574 },
    { lat: 48.5966946, lng: 21.5864521 },
    { lat: 48.5968286, lng: 21.5866899 },
    { lat: 48.5969992, lng: 21.5865599 },
    { lat: 48.5972412, lng: 21.586682 },
    { lat: 48.597263, lng: 21.5867755 },
    { lat: 48.5974244, lng: 21.586917 },
    { lat: 48.5975294, lng: 21.5867684 },
    { lat: 48.5978491, lng: 21.5868493 },
    { lat: 48.5979147, lng: 21.5870612 },
    { lat: 48.5980766, lng: 21.5869834 },
    { lat: 48.5983162, lng: 21.5870598 },
    { lat: 48.5983978, lng: 21.5870016 },
    { lat: 48.5984432, lng: 21.5868138 },
    { lat: 48.5987335, lng: 21.5867729 },
    { lat: 48.5990196, lng: 21.5870194 },
    { lat: 48.5995522, lng: 21.5869186 },
    { lat: 48.5995754, lng: 21.5868197 },
    { lat: 48.5996967, lng: 21.5868493 },
    { lat: 48.5998349, lng: 21.5866478 },
    { lat: 48.5999562, lng: 21.5866781 },
    { lat: 48.6001154, lng: 21.586579 },
    { lat: 48.6003891, lng: 21.5867439 },
    { lat: 48.6011638, lng: 21.5869279 },
    { lat: 48.6010926, lng: 21.5885976 },
  ],
  MaléOzorovce: [
    { lat: 48.7005631, lng: 21.6016631 },
    { lat: 48.7005824, lng: 21.6015119 },
    { lat: 48.7006773, lng: 21.6014061 },
    { lat: 48.7007823, lng: 21.6010518 },
    { lat: 48.7009028, lng: 21.6009341 },
    { lat: 48.7008652, lng: 21.6006006 },
    { lat: 48.7009921, lng: 21.6002227 },
    { lat: 48.7010103, lng: 21.5999562 },
    { lat: 48.7018138, lng: 21.5980207 },
    { lat: 48.7017835, lng: 21.5978666 },
    { lat: 48.7018439, lng: 21.5976487 },
    { lat: 48.7018048, lng: 21.5968996 },
    { lat: 48.7017406, lng: 21.596737 },
    { lat: 48.7017017, lng: 21.5961471 },
    { lat: 48.7015845, lng: 21.5956135 },
    { lat: 48.7015987, lng: 21.5953212 },
    { lat: 48.7015084, lng: 21.5947916 },
    { lat: 48.7015661, lng: 21.5942319 },
    { lat: 48.7012988, lng: 21.5936103 },
    { lat: 48.7012253, lng: 21.5931601 },
    { lat: 48.7011051, lng: 21.5928829 },
    { lat: 48.7011527, lng: 21.5925699 },
    { lat: 48.7010482, lng: 21.5923732 },
    { lat: 48.7010869, lng: 21.5919116 },
    { lat: 48.7011804, lng: 21.5917489 },
    { lat: 48.7011457, lng: 21.5914797 },
    { lat: 48.701235, lng: 21.591375 },
    { lat: 48.701267, lng: 21.59111 },
    { lat: 48.7013605, lng: 21.5911162 },
    { lat: 48.70135, lng: 21.5908586 },
    { lat: 48.7014485, lng: 21.5903939 },
    { lat: 48.701583, lng: 21.5902228 },
    { lat: 48.7017107, lng: 21.5901591 },
    { lat: 48.7016671, lng: 21.590085 },
    { lat: 48.7018219, lng: 21.5898279 },
    { lat: 48.7018295, lng: 21.589577 },
    { lat: 48.7019181, lng: 21.5894512 },
    { lat: 48.7018708, lng: 21.5888042 },
    { lat: 48.702012, lng: 21.5882252 },
    { lat: 48.7021, lng: 21.5881915 },
    { lat: 48.7023968, lng: 21.5870981 },
    { lat: 48.7024591, lng: 21.5868318 },
    { lat: 48.7031941, lng: 21.5870425 },
    { lat: 48.7034207, lng: 21.587018 },
    { lat: 48.7047979, lng: 21.5861807 },
    { lat: 48.705884, lng: 21.5851059 },
    { lat: 48.7071222, lng: 21.5842389 },
    { lat: 48.7076583, lng: 21.5836983 },
    { lat: 48.7082706, lng: 21.5832619 },
    { lat: 48.7086931, lng: 21.58301 },
    { lat: 48.709237, lng: 21.5829826 },
    { lat: 48.7096426, lng: 21.5825209 },
    { lat: 48.7101365, lng: 21.5821593 },
    { lat: 48.7106152, lng: 21.5819341 },
    { lat: 48.7120072, lng: 21.5815812 },
    { lat: 48.7120692, lng: 21.5814646 },
    { lat: 48.7128846, lng: 21.5808102 },
    { lat: 48.7138558, lng: 21.5804169 },
    { lat: 48.7147567, lng: 21.5796807 },
    { lat: 48.7154317, lng: 21.5802396 },
    { lat: 48.7157938, lng: 21.5803578 },
    { lat: 48.7157949, lng: 21.5806313 },
    { lat: 48.7156027, lng: 21.5816014 },
    { lat: 48.7156063, lng: 21.5818898 },
    { lat: 48.7164657, lng: 21.5814018 },
    { lat: 48.716928, lng: 21.5813604 },
    { lat: 48.7182775, lng: 21.5814484 },
    { lat: 48.7185169, lng: 21.5813988 },
    { lat: 48.7192694, lng: 21.5808346 },
    { lat: 48.7194565, lng: 21.5804431 },
    { lat: 48.7195158, lng: 21.5803484 },
    { lat: 48.719424, lng: 21.5802107 },
    { lat: 48.7194612, lng: 21.5799657 },
    { lat: 48.719271, lng: 21.5797256 },
    { lat: 48.719217, lng: 21.5795172 },
    { lat: 48.7191248, lng: 21.5793284 },
    { lat: 48.7190195, lng: 21.5791492 },
    { lat: 48.7189854, lng: 21.5790758 },
    { lat: 48.7189242, lng: 21.5789798 },
    { lat: 48.7188435, lng: 21.5788508 },
    { lat: 48.7187654, lng: 21.5786196 },
    { lat: 48.7186844, lng: 21.5783817 },
    { lat: 48.7185883, lng: 21.5782108 },
    { lat: 48.7184933, lng: 21.5780347 },
    { lat: 48.7184119, lng: 21.5778905 },
    { lat: 48.7183276, lng: 21.5777757 },
    { lat: 48.7182599, lng: 21.5775982 },
    { lat: 48.7181073, lng: 21.5768752 },
    { lat: 48.7181478, lng: 21.57679 },
    { lat: 48.7181778, lng: 21.5767091 },
    { lat: 48.7181937, lng: 21.5764911 },
    { lat: 48.7182235, lng: 21.5763233 },
    { lat: 48.718266, lng: 21.5760756 },
    { lat: 48.7182553, lng: 21.5758108 },
    { lat: 48.7182118, lng: 21.575631 },
    { lat: 48.7180092, lng: 21.5749471 },
    { lat: 48.7179456, lng: 21.5747659 },
    { lat: 48.7178538, lng: 21.5745459 },
    { lat: 48.717792, lng: 21.5744218 },
    { lat: 48.717731, lng: 21.5742637 },
    { lat: 48.7175318, lng: 21.5740203 },
    { lat: 48.7173656, lng: 21.5739138 },
    { lat: 48.7173078, lng: 21.573803 },
    { lat: 48.7172251, lng: 21.5735297 },
    { lat: 48.7171803, lng: 21.5732351 },
    { lat: 48.7171921, lng: 21.5728923 },
    { lat: 48.7172574, lng: 21.5727091 },
    { lat: 48.7172667, lng: 21.5725537 },
    { lat: 48.7172638, lng: 21.5723919 },
    { lat: 48.7172483, lng: 21.5721422 },
    { lat: 48.7172727, lng: 21.5719673 },
    { lat: 48.7172927, lng: 21.571855 },
    { lat: 48.7173339, lng: 21.5717063 },
    { lat: 48.717347, lng: 21.5715291 },
    { lat: 48.7172893, lng: 21.5713204 },
    { lat: 48.7173721, lng: 21.5711797 },
    { lat: 48.7173653, lng: 21.5710219 },
    { lat: 48.7173641, lng: 21.5708486 },
    { lat: 48.7173353, lng: 21.5706369 },
    { lat: 48.7173239, lng: 21.5704744 },
    { lat: 48.7173103, lng: 21.5702385 },
    { lat: 48.7172556, lng: 21.569965 },
    { lat: 48.7172415, lng: 21.5698542 },
    { lat: 48.7172121, lng: 21.5696726 },
    { lat: 48.7172034, lng: 21.5695843 },
    { lat: 48.7170967, lng: 21.5691963 },
    { lat: 48.7170657, lng: 21.5688573 },
    { lat: 48.7170045, lng: 21.5687608 },
    { lat: 48.7169248, lng: 21.568369 },
    { lat: 48.7168768, lng: 21.5682143 },
    { lat: 48.716719, lng: 21.5676917 },
    { lat: 48.7165642, lng: 21.5672691 },
    { lat: 48.7165001, lng: 21.5671004 },
    { lat: 48.7164442, lng: 21.5668436 },
    { lat: 48.716384, lng: 21.5667488 },
    { lat: 48.716274, lng: 21.5666279 },
    { lat: 48.7161673, lng: 21.5664816 },
    { lat: 48.7160654, lng: 21.5663963 },
    { lat: 48.7159991, lng: 21.5663011 },
    { lat: 48.7155938, lng: 21.5660611 },
    { lat: 48.7155326, lng: 21.5660524 },
    { lat: 48.7154451, lng: 21.566026 },
    { lat: 48.7150714, lng: 21.5659218 },
    { lat: 48.7146333, lng: 21.5657121 },
    { lat: 48.7143047, lng: 21.5656713 },
    { lat: 48.7140814, lng: 21.5655304 },
    { lat: 48.7138228, lng: 21.5650653 },
    { lat: 48.7134352, lng: 21.564925 },
    { lat: 48.7133883, lng: 21.5648165 },
    { lat: 48.7131508, lng: 21.5646975 },
    { lat: 48.7130579, lng: 21.5646721 },
    { lat: 48.7129651, lng: 21.5647337 },
    { lat: 48.7129089, lng: 21.5646532 },
    { lat: 48.7128607, lng: 21.5643834 },
    { lat: 48.7127091, lng: 21.5643084 },
    { lat: 48.712694, lng: 21.5641768 },
    { lat: 48.7121283, lng: 21.5636643 },
    { lat: 48.7120938, lng: 21.5634032 },
    { lat: 48.711823, lng: 21.5632988 },
    { lat: 48.7117705, lng: 21.5631935 },
    { lat: 48.7117058, lng: 21.5626131 },
    { lat: 48.7115571, lng: 21.5625233 },
    { lat: 48.7113316, lng: 21.5622271 },
    { lat: 48.7111867, lng: 21.5621998 },
    { lat: 48.7108723, lng: 21.5615428 },
    { lat: 48.7106979, lng: 21.5613478 },
    { lat: 48.7106211, lng: 21.5611739 },
    { lat: 48.7105507, lng: 21.5608756 },
    { lat: 48.7103544, lng: 21.5603809 },
    { lat: 48.71025, lng: 21.5602323 },
    { lat: 48.710147, lng: 21.5594502 },
    { lat: 48.710051, lng: 21.5592808 },
    { lat: 48.7098005, lng: 21.5590543 },
    { lat: 48.7097383, lng: 21.558888 },
    { lat: 48.7094419, lng: 21.558671 },
    { lat: 48.7089925, lng: 21.5581371 },
    { lat: 48.7088571, lng: 21.5580842 },
    { lat: 48.7087743, lng: 21.5578997 },
    { lat: 48.7087049, lng: 21.5574389 },
    { lat: 48.7084744, lng: 21.556882 },
    { lat: 48.708268, lng: 21.5561137 },
    { lat: 48.7081414, lng: 21.555932 },
    { lat: 48.7080894, lng: 21.555728 },
    { lat: 48.7079314, lng: 21.5555519 },
    { lat: 48.7074365, lng: 21.5547286 },
    { lat: 48.7073522, lng: 21.5543664 },
    { lat: 48.7072478, lng: 21.5541419 },
    { lat: 48.7067367, lng: 21.5535373 },
    { lat: 48.7065303, lng: 21.5531595 },
    { lat: 48.7063532, lng: 21.5529821 },
    { lat: 48.7063777, lng: 21.5528587 },
    { lat: 48.7063385, lng: 21.552707 },
    { lat: 48.7062034, lng: 21.5525063 },
    { lat: 48.7078016, lng: 21.5498984 },
    { lat: 48.708917, lng: 21.548086 },
    { lat: 48.708917, lng: 21.548012 },
    { lat: 48.7089, lng: 21.547965 },
    { lat: 48.70873, lng: 21.547565 },
    { lat: 48.708644, lng: 21.547393 },
    { lat: 48.708523, lng: 21.547252 },
    { lat: 48.708392, lng: 21.54702 },
    { lat: 48.708251, lng: 21.546631 },
    { lat: 48.708158, lng: 21.546494 },
    { lat: 48.708064, lng: 21.546375 },
    { lat: 48.707861, lng: 21.546293 },
    { lat: 48.707733, lng: 21.546247 },
    { lat: 48.707535, lng: 21.54614 },
    { lat: 48.707282, lng: 21.546121 },
    { lat: 48.707201, lng: 21.546154 },
    { lat: 48.706778, lng: 21.546031 },
    { lat: 48.706532, lng: 21.546092 },
    { lat: 48.706485, lng: 21.54616 },
    { lat: 48.70636, lng: 21.546181 },
    { lat: 48.706151, lng: 21.546135 },
    { lat: 48.706077, lng: 21.546063 },
    { lat: 48.70597, lng: 21.545861 },
    { lat: 48.705723, lng: 21.545559 },
    { lat: 48.705593, lng: 21.545561 },
    { lat: 48.705424, lng: 21.545622 },
    { lat: 48.705325, lng: 21.545571 },
    { lat: 48.705262, lng: 21.545503 },
    { lat: 48.705079, lng: 21.545169 },
    { lat: 48.70503, lng: 21.544929 },
    { lat: 48.705072, lng: 21.54464 },
    { lat: 48.70508, lng: 21.544194 },
    { lat: 48.705001, lng: 21.543788 },
    { lat: 48.704921, lng: 21.543707 },
    { lat: 48.704699, lng: 21.543656 },
    { lat: 48.704477, lng: 21.543483 },
    { lat: 48.70423, lng: 21.543246 },
    { lat: 48.703931, lng: 21.542911 },
    { lat: 48.703608, lng: 21.542724 },
    { lat: 48.703592, lng: 21.542707 },
    { lat: 48.7035684, lng: 21.5427602 },
    { lat: 48.7030815, lng: 21.5432053 },
    { lat: 48.7022086, lng: 21.5437286 },
    { lat: 48.7020268, lng: 21.5439442 },
    { lat: 48.7017318, lng: 21.5444488 },
    { lat: 48.7014597, lng: 21.5444982 },
    { lat: 48.7009791, lng: 21.5447684 },
    { lat: 48.700593, lng: 21.5459528 },
    { lat: 48.7004312, lng: 21.5467879 },
    { lat: 48.7003323, lng: 21.5470757 },
    { lat: 48.700103, lng: 21.5474799 },
    { lat: 48.700092, lng: 21.5481642 },
    { lat: 48.7002433, lng: 21.548657 },
    { lat: 48.7002211, lng: 21.5502927 },
    { lat: 48.6999846, lng: 21.5510015 },
    { lat: 48.6998059, lng: 21.5512848 },
    { lat: 48.6994195, lng: 21.551702 },
    { lat: 48.6989799, lng: 21.551915 },
    { lat: 48.6982609, lng: 21.5525097 },
    { lat: 48.6979053, lng: 21.552709 },
    { lat: 48.6968191, lng: 21.5529335 },
    { lat: 48.6964889, lng: 21.5528309 },
    { lat: 48.6960815, lng: 21.5528312 },
    { lat: 48.6958419, lng: 21.5529974 },
    { lat: 48.6954128, lng: 21.5534554 },
    { lat: 48.694877, lng: 21.5534509 },
    { lat: 48.6946296, lng: 21.5535324 },
    { lat: 48.6942175, lng: 21.5539184 },
    { lat: 48.6938688, lng: 21.5541165 },
    { lat: 48.6936154, lng: 21.5544268 },
    { lat: 48.6939459, lng: 21.5549008 },
    { lat: 48.694656, lng: 21.5556559 },
    { lat: 48.6948202, lng: 21.5560075 },
    { lat: 48.6946491, lng: 21.5566002 },
    { lat: 48.6943157, lng: 21.5566687 },
    { lat: 48.6940925, lng: 21.557529 },
    { lat: 48.6938452, lng: 21.5579813 },
    { lat: 48.6936994, lng: 21.5591428 },
    { lat: 48.6935374, lng: 21.559986 },
    { lat: 48.693426, lng: 21.5619331 },
    { lat: 48.6932657, lng: 21.5623491 },
    { lat: 48.6930611, lng: 21.5631131 },
    { lat: 48.6927925, lng: 21.563562 },
    { lat: 48.6926414, lng: 21.5639878 },
    { lat: 48.6923417, lng: 21.5644551 },
    { lat: 48.6919784, lng: 21.565256 },
    { lat: 48.6915374, lng: 21.5655379 },
    { lat: 48.6912367, lng: 21.5655274 },
    { lat: 48.6904123, lng: 21.5658555 },
    { lat: 48.6899183, lng: 21.5659809 },
    { lat: 48.6889044, lng: 21.566615 },
    { lat: 48.6876653, lng: 21.5671616 },
    { lat: 48.687338, lng: 21.5674466 },
    { lat: 48.686938, lng: 21.567624 },
    { lat: 48.6866061, lng: 21.5678633 },
    { lat: 48.6861153, lng: 21.5683753 },
    { lat: 48.6859643, lng: 21.568682 },
    { lat: 48.6856603, lng: 21.568594 },
    { lat: 48.6851445, lng: 21.568739 },
    { lat: 48.6848763, lng: 21.5691636 },
    { lat: 48.6846608, lng: 21.5693029 },
    { lat: 48.684392, lng: 21.5697457 },
    { lat: 48.6844439, lng: 21.5700731 },
    { lat: 48.6844215, lng: 21.5701896 },
    { lat: 48.684117, lng: 21.5704446 },
    { lat: 48.6840728, lng: 21.5706336 },
    { lat: 48.6840743, lng: 21.5710067 },
    { lat: 48.6840231, lng: 21.5711457 },
    { lat: 48.6839145, lng: 21.5712513 },
    { lat: 48.6838257, lng: 21.5712103 },
    { lat: 48.6837858, lng: 21.5713101 },
    { lat: 48.683635, lng: 21.5713896 },
    { lat: 48.6835355, lng: 21.5716556 },
    { lat: 48.6834338, lng: 21.5716379 },
    { lat: 48.6832328, lng: 21.5718506 },
    { lat: 48.6831842, lng: 21.571842 },
    { lat: 48.6830533, lng: 21.5722321 },
    { lat: 48.6828573, lng: 21.5723391 },
    { lat: 48.6827818, lng: 21.5725796 },
    { lat: 48.6826873, lng: 21.5726979 },
    { lat: 48.6825352, lng: 21.5727721 },
    { lat: 48.6825127, lng: 21.5726931 },
    { lat: 48.6824289, lng: 21.5727497 },
    { lat: 48.6822652, lng: 21.5729732 },
    { lat: 48.6822467, lng: 21.5731111 },
    { lat: 48.6815518, lng: 21.5739704 },
    { lat: 48.6814448, lng: 21.5740069 },
    { lat: 48.681377, lng: 21.5742348 },
    { lat: 48.6812569, lng: 21.5741656 },
    { lat: 48.6810133, lng: 21.5745829 },
    { lat: 48.6809857, lng: 21.5747673 },
    { lat: 48.6807133, lng: 21.5748824 },
    { lat: 48.6806519, lng: 21.5751391 },
    { lat: 48.680596, lng: 21.5751883 },
    { lat: 48.6805965, lng: 21.5753225 },
    { lat: 48.6805349, lng: 21.5754222 },
    { lat: 48.6804305, lng: 21.5754229 },
    { lat: 48.6802363, lng: 21.5758794 },
    { lat: 48.6800894, lng: 21.5760625 },
    { lat: 48.6800754, lng: 21.5762835 },
    { lat: 48.6798766, lng: 21.5765772 },
    { lat: 48.6799416, lng: 21.5767229 },
    { lat: 48.6797708, lng: 21.5769717 },
    { lat: 48.6796026, lng: 21.5775889 },
    { lat: 48.6796812, lng: 21.5783048 },
    { lat: 48.6797388, lng: 21.5783891 },
    { lat: 48.6796513, lng: 21.5787992 },
    { lat: 48.6795723, lng: 21.5789174 },
    { lat: 48.6795072, lng: 21.579361 },
    { lat: 48.6794392, lng: 21.5794989 },
    { lat: 48.679298, lng: 21.5795268 },
    { lat: 48.6791734, lng: 21.5798922 },
    { lat: 48.6791921, lng: 21.580036 },
    { lat: 48.6791406, lng: 21.5801664 },
    { lat: 48.6790106, lng: 21.5802242 },
    { lat: 48.679099, lng: 21.5803325 },
    { lat: 48.6790997, lng: 21.5804314 },
    { lat: 48.6790375, lng: 21.5804233 },
    { lat: 48.6790143, lng: 21.5804807 },
    { lat: 48.6790299, lng: 21.5805473 },
    { lat: 48.67898, lng: 21.5806609 },
    { lat: 48.6790524, lng: 21.5808282 },
    { lat: 48.6789748, lng: 21.5808681 },
    { lat: 48.6789801, lng: 21.5810251 },
    { lat: 48.6788563, lng: 21.5810238 },
    { lat: 48.6788066, lng: 21.5812089 },
    { lat: 48.6787272, lng: 21.5812724 },
    { lat: 48.6786991, lng: 21.5814725 },
    { lat: 48.6785604, lng: 21.5815854 },
    { lat: 48.6784789, lng: 21.5817647 },
    { lat: 48.678372, lng: 21.581763 },
    { lat: 48.6783593, lng: 21.5819148 },
    { lat: 48.6782837, lng: 21.5819082 },
    { lat: 48.6780753, lng: 21.5821783 },
    { lat: 48.6781006, lng: 21.5823141 },
    { lat: 48.6779674, lng: 21.5822639 },
    { lat: 48.6779302, lng: 21.5824197 },
    { lat: 48.677758, lng: 21.582383 },
    { lat: 48.6777241, lng: 21.5827352 },
    { lat: 48.677578, lng: 21.5827374 },
    { lat: 48.6775435, lng: 21.5827938 },
    { lat: 48.6776243, lng: 21.5829022 },
    { lat: 48.6776209, lng: 21.5829804 },
    { lat: 48.6774891, lng: 21.5829817 },
    { lat: 48.6774707, lng: 21.5830484 },
    { lat: 48.6775194, lng: 21.5831791 },
    { lat: 48.6773972, lng: 21.5834298 },
    { lat: 48.6774782, lng: 21.5835933 },
    { lat: 48.677462, lng: 21.5839721 },
    { lat: 48.6773832, lng: 21.5840537 },
    { lat: 48.6773228, lng: 21.5842712 },
    { lat: 48.6772392, lng: 21.584299 },
    { lat: 48.6768662, lng: 21.5847213 },
    { lat: 48.6769342, lng: 21.5848166 },
    { lat: 48.6768307, lng: 21.5848919 },
    { lat: 48.6767677, lng: 21.5850594 },
    { lat: 48.6768962, lng: 21.5852249 },
    { lat: 48.6768662, lng: 21.5853571 },
    { lat: 48.6769138, lng: 21.5855673 },
    { lat: 48.6768845, lng: 21.5856063 },
    { lat: 48.6768132, lng: 21.5855125 },
    { lat: 48.6768125, lng: 21.5856885 },
    { lat: 48.6765989, lng: 21.5858563 },
    { lat: 48.676614, lng: 21.5863516 },
    { lat: 48.6765637, lng: 21.5864521 },
    { lat: 48.6764762, lng: 21.586458 },
    { lat: 48.6763971, lng: 21.5865628 },
    { lat: 48.6763921, lng: 21.5867051 },
    { lat: 48.6763299, lng: 21.5867728 },
    { lat: 48.6764123, lng: 21.5870616 },
    { lat: 48.6763332, lng: 21.5871333 },
    { lat: 48.6763378, lng: 21.5872135 },
    { lat: 48.6764246, lng: 21.5872998 },
    { lat: 48.6763979, lng: 21.5874752 },
    { lat: 48.6764331, lng: 21.5875694 },
    { lat: 48.6764026, lng: 21.5876426 },
    { lat: 48.6762344, lng: 21.5877053 },
    { lat: 48.6762218, lng: 21.5879806 },
    { lat: 48.676151, lng: 21.5881037 },
    { lat: 48.6761805, lng: 21.5883913 },
    { lat: 48.6759635, lng: 21.5889175 },
    { lat: 48.6760095, lng: 21.5890359 },
    { lat: 48.6759628, lng: 21.5890517 },
    { lat: 48.6758326, lng: 21.5893427 },
    { lat: 48.6758226, lng: 21.589468 },
    { lat: 48.6759569, lng: 21.5899268 },
    { lat: 48.6757746, lng: 21.5902429 },
    { lat: 48.6757087, lng: 21.5902973 },
    { lat: 48.6756446, lng: 21.5901814 },
    { lat: 48.6755517, lng: 21.5902828 },
    { lat: 48.6754839, lng: 21.5904277 },
    { lat: 48.6755205, lng: 21.5908526 },
    { lat: 48.6752874, lng: 21.591048 },
    { lat: 48.6752383, lng: 21.5915841 },
    { lat: 48.6751727, lng: 21.5918108 },
    { lat: 48.6752134, lng: 21.5921016 },
    { lat: 48.675112, lng: 21.5922608 },
    { lat: 48.675139, lng: 21.5925759 },
    { lat: 48.6750761, lng: 21.5926636 },
    { lat: 48.6751037, lng: 21.5928829 },
    { lat: 48.6750655, lng: 21.5929651 },
    { lat: 48.6749109, lng: 21.5929504 },
    { lat: 48.6747713, lng: 21.5931452 },
    { lat: 48.6747143, lng: 21.593367 },
    { lat: 48.6746049, lng: 21.5935492 },
    { lat: 48.67455, lng: 21.5937207 },
    { lat: 48.6746627, lng: 21.5940147 },
    { lat: 48.6745375, lng: 21.5941231 },
    { lat: 48.6745768, lng: 21.5942399 },
    { lat: 48.674546, lng: 21.594344 },
    { lat: 48.6744273, lng: 21.594364 },
    { lat: 48.6743692, lng: 21.5945386 },
    { lat: 48.6742623, lng: 21.5946601 },
    { lat: 48.6740197, lng: 21.5947119 },
    { lat: 48.6738766, lng: 21.5949902 },
    { lat: 48.6740756, lng: 21.5954613 },
    { lat: 48.6750057, lng: 21.6004448 },
    { lat: 48.6745494, lng: 21.6023152 },
    { lat: 48.6742487, lng: 21.6033094 },
    { lat: 48.6741046, lng: 21.6039915 },
    { lat: 48.6741028, lng: 21.6067488 },
    { lat: 48.6740371, lng: 21.6074173 },
    { lat: 48.6737835, lng: 21.6087759 },
    { lat: 48.6736474, lng: 21.6088138 },
    { lat: 48.6732071, lng: 21.609809 },
    { lat: 48.672936, lng: 21.6102598 },
    { lat: 48.6723907, lng: 21.6116494 },
    { lat: 48.6723591, lng: 21.611802 },
    { lat: 48.6724901, lng: 21.6134419 },
    { lat: 48.671908, lng: 21.6132225 },
    { lat: 48.6721837, lng: 21.6160565 },
    { lat: 48.6700247, lng: 21.6169954 },
    { lat: 48.6694216, lng: 21.6173195 },
    { lat: 48.6677734, lng: 21.6187385 },
    { lat: 48.6669886, lng: 21.6198105 },
    { lat: 48.665739, lng: 21.6217291 },
    { lat: 48.6648975, lng: 21.6226622 },
    { lat: 48.6647038, lng: 21.6230237 },
    { lat: 48.6643825, lng: 21.623895 },
    { lat: 48.6640259, lng: 21.6245426 },
    { lat: 48.6636179, lng: 21.6254493 },
    { lat: 48.6624522, lng: 21.6299556 },
    { lat: 48.6616581, lng: 21.6321608 },
    { lat: 48.6614452, lng: 21.6325967 },
    { lat: 48.6615487, lng: 21.6342644 },
    { lat: 48.6616724, lng: 21.6354802 },
    { lat: 48.6614199, lng: 21.6356709 },
    { lat: 48.6612671, lng: 21.6356784 },
    { lat: 48.6609041, lng: 21.6355258 },
    { lat: 48.660751, lng: 21.6356091 },
    { lat: 48.6606615, lng: 21.6357927 },
    { lat: 48.6607599, lng: 21.6368709 },
    { lat: 48.6611717, lng: 21.640008 },
    { lat: 48.6615246, lng: 21.64215 },
    { lat: 48.6603712, lng: 21.6423443 },
    { lat: 48.6607948, lng: 21.6460038 },
    { lat: 48.6609202, lng: 21.648347 },
    { lat: 48.6609233, lng: 21.6496071 },
    { lat: 48.6608345, lng: 21.6508331 },
    { lat: 48.6608196, lng: 21.6518448 },
    { lat: 48.6608941, lng: 21.6532078 },
    { lat: 48.6608987, lng: 21.6553366 },
    { lat: 48.6610299, lng: 21.6576503 },
    { lat: 48.6611301, lng: 21.6585178 },
    { lat: 48.6611592, lng: 21.6585145 },
    { lat: 48.6633406, lng: 21.6582585 },
    { lat: 48.6637344, lng: 21.6571522 },
    { lat: 48.6642829, lng: 21.6558572 },
    { lat: 48.6645362, lng: 21.6538203 },
    { lat: 48.6646124, lng: 21.6525335 },
    { lat: 48.6650621, lng: 21.6503604 },
    { lat: 48.6653699, lng: 21.6481745 },
    { lat: 48.6657035, lng: 21.647095 },
    { lat: 48.6669806, lng: 21.6415411 },
    { lat: 48.6669761, lng: 21.6415417 },
    { lat: 48.667078, lng: 21.64014 },
    { lat: 48.6681675, lng: 21.6395859 },
    { lat: 48.669677, lng: 21.6389566 },
    { lat: 48.6714127, lng: 21.6381257 },
    { lat: 48.6743017, lng: 21.6364821 },
    { lat: 48.674173, lng: 21.6359821 },
    { lat: 48.6738218, lng: 21.6336554 },
    { lat: 48.6740851, lng: 21.6338172 },
    { lat: 48.674299, lng: 21.6341928 },
    { lat: 48.6745862, lng: 21.633997 },
    { lat: 48.6749243, lng: 21.6341007 },
    { lat: 48.6744411, lng: 21.6320848 },
    { lat: 48.6771171, lng: 21.6312057 },
    { lat: 48.6767181, lng: 21.6281318 },
    { lat: 48.677315, lng: 21.6278719 },
    { lat: 48.6781116, lng: 21.6266969 },
    { lat: 48.6785262, lng: 21.6259437 },
    { lat: 48.6789016, lng: 21.6254421 },
    { lat: 48.6796088, lng: 21.6248089 },
    { lat: 48.6799575, lng: 21.623959 },
    { lat: 48.6798638, lng: 21.6235384 },
    { lat: 48.6802072, lng: 21.6229819 },
    { lat: 48.6808789, lng: 21.6221323 },
    { lat: 48.6821916, lng: 21.6211882 },
    { lat: 48.6824906, lng: 21.6208898 },
    { lat: 48.6832636, lng: 21.6204043 },
    { lat: 48.6836739, lng: 21.6195814 },
    { lat: 48.6839405, lng: 21.6191732 },
    { lat: 48.684083, lng: 21.6190837 },
    { lat: 48.6839162, lng: 21.6189091 },
    { lat: 48.6840356, lng: 21.6181043 },
    { lat: 48.6839133, lng: 21.6170638 },
    { lat: 48.6854901, lng: 21.6162307 },
    { lat: 48.6860407, lng: 21.6159971 },
    { lat: 48.6866188, lng: 21.6158685 },
    { lat: 48.6873598, lng: 21.6157742 },
    { lat: 48.6887845, lng: 21.6158209 },
    { lat: 48.6891463, lng: 21.6158852 },
    { lat: 48.6897437, lng: 21.6161297 },
    { lat: 48.6895279, lng: 21.613625 },
    { lat: 48.6937718, lng: 21.6095769 },
    { lat: 48.6937876, lng: 21.609415 },
    { lat: 48.6940088, lng: 21.6090009 },
    { lat: 48.6939529, lng: 21.6088061 },
    { lat: 48.6939646, lng: 21.6086255 },
    { lat: 48.6940529, lng: 21.6084436 },
    { lat: 48.6944962, lng: 21.6083599 },
    { lat: 48.6945329, lng: 21.6082639 },
    { lat: 48.6945096, lng: 21.6080723 },
    { lat: 48.6946183, lng: 21.6079361 },
    { lat: 48.6947032, lng: 21.6077092 },
    { lat: 48.6946871, lng: 21.6074631 },
    { lat: 48.6949791, lng: 21.6072792 },
    { lat: 48.6948859, lng: 21.6069639 },
    { lat: 48.695005, lng: 21.6066707 },
    { lat: 48.694928, lng: 21.6064106 },
    { lat: 48.6952553, lng: 21.6060814 },
    { lat: 48.6953582, lng: 21.6058654 },
    { lat: 48.6953404, lng: 21.6056675 },
    { lat: 48.6955247, lng: 21.6054631 },
    { lat: 48.6954296, lng: 21.6049089 },
    { lat: 48.6956326, lng: 21.6045637 },
    { lat: 48.6964279, lng: 21.6045985 },
    { lat: 48.6969977, lng: 21.604459 },
    { lat: 48.6972414, lng: 21.6042376 },
    { lat: 48.697723, lng: 21.6035156 },
    { lat: 48.6981268, lng: 21.6030215 },
    { lat: 48.6985628, lng: 21.6023223 },
    { lat: 48.6990016, lng: 21.6017925 },
    { lat: 48.6995725, lng: 21.600808 },
    { lat: 48.699788, lng: 21.6008231 },
    { lat: 48.6999695, lng: 21.6009569 },
    { lat: 48.7000504, lng: 21.6013538 },
    { lat: 48.7001321, lng: 21.6013832 },
    { lat: 48.7001186, lng: 21.6016277 },
    { lat: 48.7005631, lng: 21.6016631 },
  ],
  Vojčice: [
    { lat: 48.7057121, lng: 21.7230904 },
    { lat: 48.7056896, lng: 21.7229283 },
    { lat: 48.7049509, lng: 21.7227332 },
    { lat: 48.7047286, lng: 21.7228401 },
    { lat: 48.7043798, lng: 21.723259 },
    { lat: 48.7038306, lng: 21.7233764 },
    { lat: 48.7038204, lng: 21.7229518 },
    { lat: 48.7037209, lng: 21.7228916 },
    { lat: 48.7035142, lng: 21.7225239 },
    { lat: 48.7028552, lng: 21.722419 },
    { lat: 48.7026002, lng: 21.7227199 },
    { lat: 48.7024925, lng: 21.7227732 },
    { lat: 48.7022637, lng: 21.7227174 },
    { lat: 48.701495, lng: 21.7230032 },
    { lat: 48.7013766, lng: 21.7229054 },
    { lat: 48.7012884, lng: 21.72293 },
    { lat: 48.7009635, lng: 21.7232801 },
    { lat: 48.7001955, lng: 21.7235915 },
    { lat: 48.6995079, lng: 21.7234972 },
    { lat: 48.6989381, lng: 21.7237715 },
    { lat: 48.6986948, lng: 21.7236742 },
    { lat: 48.6984911, lng: 21.7236736 },
    { lat: 48.6981266, lng: 21.7234637 },
    { lat: 48.6978421, lng: 21.7235103 },
    { lat: 48.6974741, lng: 21.7233021 },
    { lat: 48.6971862, lng: 21.7230979 },
    { lat: 48.6962143, lng: 21.7221389 },
    { lat: 48.6959432, lng: 21.7220601 },
    { lat: 48.6958789, lng: 21.7218881 },
    { lat: 48.6957289, lng: 21.7217894 },
    { lat: 48.6951998, lng: 21.7218133 },
    { lat: 48.6947092, lng: 21.7220663 },
    { lat: 48.6943625, lng: 21.7220627 },
    { lat: 48.6942112, lng: 21.7222062 },
    { lat: 48.6939474, lng: 21.7222324 },
    { lat: 48.6937696, lng: 21.7221443 },
    { lat: 48.6936675, lng: 21.7219856 },
    { lat: 48.6931327, lng: 21.7216833 },
    { lat: 48.6928965, lng: 21.7214282 },
    { lat: 48.6929733, lng: 21.721269 },
    { lat: 48.6929483, lng: 21.7210925 },
    { lat: 48.6926125, lng: 21.7204938 },
    { lat: 48.6922916, lng: 21.7201506 },
    { lat: 48.6922667, lng: 21.720015 },
    { lat: 48.6918831, lng: 21.7193487 },
    { lat: 48.6914953, lng: 21.7176935 },
    { lat: 48.6912799, lng: 21.7162965 },
    { lat: 48.6908399, lng: 21.7131603 },
    { lat: 48.6909434, lng: 21.7131165 },
    { lat: 48.6905903, lng: 21.7097036 },
    { lat: 48.6904374, lng: 21.7071662 },
    { lat: 48.6905808, lng: 21.7070982 },
    { lat: 48.6905852, lng: 21.7068846 },
    { lat: 48.690332, lng: 21.70501 },
    { lat: 48.690156, lng: 21.704038 },
    { lat: 48.6902145, lng: 21.7027478 },
    { lat: 48.6902337, lng: 21.7026637 },
    { lat: 48.6902557, lng: 21.7023967 },
    { lat: 48.690263, lng: 21.7017075 },
    { lat: 48.6904035, lng: 21.7009581 },
    { lat: 48.6903181, lng: 21.7001312 },
    { lat: 48.6897101, lng: 21.6966654 },
    { lat: 48.6893345, lng: 21.6939356 },
    { lat: 48.68906, lng: 21.6925688 },
    { lat: 48.6882075, lng: 21.6891846 },
    { lat: 48.6873106, lng: 21.6858253 },
    { lat: 48.6869822, lng: 21.68475 },
    { lat: 48.686842, lng: 21.6848923 },
    { lat: 48.6864427, lng: 21.6850591 },
    { lat: 48.6850514, lng: 21.6853267 },
    { lat: 48.6849434, lng: 21.68516 },
    { lat: 48.6846221, lng: 21.6852583 },
    { lat: 48.684363, lng: 21.6852085 },
    { lat: 48.6842074, lng: 21.6846174 },
    { lat: 48.684028, lng: 21.684724 },
    { lat: 48.6839577, lng: 21.6846155 },
    { lat: 48.6838713, lng: 21.684675 },
    { lat: 48.6837557, lng: 21.6843503 },
    { lat: 48.6836548, lng: 21.6843608 },
    { lat: 48.6835948, lng: 21.684267 },
    { lat: 48.6833244, lng: 21.6843728 },
    { lat: 48.6832415, lng: 21.6844705 },
    { lat: 48.6830076, lng: 21.6844359 },
    { lat: 48.6827748, lng: 21.6844842 },
    { lat: 48.6827312, lng: 21.684364 },
    { lat: 48.6826718, lng: 21.6843731 },
    { lat: 48.682542, lng: 21.6845225 },
    { lat: 48.6825558, lng: 21.6845825 },
    { lat: 48.682308, lng: 21.6846919 },
    { lat: 48.6824857, lng: 21.6852708 },
    { lat: 48.6823053, lng: 21.6853835 },
    { lat: 48.6821275, lng: 21.6848776 },
    { lat: 48.682065, lng: 21.6849046 },
    { lat: 48.6820186, lng: 21.6850534 },
    { lat: 48.6820609, lng: 21.6854567 },
    { lat: 48.6820046, lng: 21.6855332 },
    { lat: 48.6820114, lng: 21.685698 },
    { lat: 48.681447, lng: 21.6860452 },
    { lat: 48.6813372, lng: 21.6858142 },
    { lat: 48.6811428, lng: 21.6858629 },
    { lat: 48.6810436, lng: 21.6860265 },
    { lat: 48.6810615, lng: 21.6862265 },
    { lat: 48.6806665, lng: 21.6863112 },
    { lat: 48.680552, lng: 21.6860256 },
    { lat: 48.6802961, lng: 21.6859294 },
    { lat: 48.6799526, lng: 21.685997 },
    { lat: 48.6798121, lng: 21.6858771 },
    { lat: 48.679635, lng: 21.6850368 },
    { lat: 48.6795618, lng: 21.6849093 },
    { lat: 48.6794289, lng: 21.6848609 },
    { lat: 48.6793177, lng: 21.6845766 },
    { lat: 48.679173, lng: 21.6845105 },
    { lat: 48.6790792, lng: 21.6841642 },
    { lat: 48.6789261, lng: 21.6831661 },
    { lat: 48.6787399, lng: 21.6826141 },
    { lat: 48.6785775, lng: 21.6825968 },
    { lat: 48.6784803, lng: 21.6822058 },
    { lat: 48.6781433, lng: 21.6823492 },
    { lat: 48.6779959, lng: 21.6822708 },
    { lat: 48.6781171, lng: 21.682834 },
    { lat: 48.6780959, lng: 21.6832604 },
    { lat: 48.6780286, lng: 21.6832631 },
    { lat: 48.6779187, lng: 21.6831493 },
    { lat: 48.677817, lng: 21.682602 },
    { lat: 48.6776149, lng: 21.6826594 },
    { lat: 48.6775554, lng: 21.6825349 },
    { lat: 48.6774987, lng: 21.6825651 },
    { lat: 48.6773724, lng: 21.6823857 },
    { lat: 48.6772347, lng: 21.6824117 },
    { lat: 48.6771936, lng: 21.6826784 },
    { lat: 48.677109, lng: 21.6827139 },
    { lat: 48.6764817, lng: 21.6826468 },
    { lat: 48.6764503, lng: 21.6827744 },
    { lat: 48.6763385, lng: 21.6827999 },
    { lat: 48.6761563, lng: 21.682976 },
    { lat: 48.6761595, lng: 21.6831268 },
    { lat: 48.6761173, lng: 21.6831547 },
    { lat: 48.6760085, lng: 21.683123 },
    { lat: 48.6759594, lng: 21.6828864 },
    { lat: 48.6756875, lng: 21.6829022 },
    { lat: 48.6755933, lng: 21.6821708 },
    { lat: 48.6754663, lng: 21.6820965 },
    { lat: 48.6753081, lng: 21.6807887 },
    { lat: 48.6750158, lng: 21.6806332 },
    { lat: 48.675017, lng: 21.680711 },
    { lat: 48.6748013, lng: 21.6808092 },
    { lat: 48.6748955, lng: 21.6813313 },
    { lat: 48.6749187, lng: 21.6816897 },
    { lat: 48.6748708, lng: 21.6817059 },
    { lat: 48.6747943, lng: 21.6814597 },
    { lat: 48.6747298, lng: 21.6814452 },
    { lat: 48.6747353, lng: 21.6815271 },
    { lat: 48.6746922, lng: 21.6815564 },
    { lat: 48.6745852, lng: 21.6813399 },
    { lat: 48.674453, lng: 21.6813256 },
    { lat: 48.6743433, lng: 21.6808476 },
    { lat: 48.6742152, lng: 21.6806448 },
    { lat: 48.6741533, lng: 21.6806504 },
    { lat: 48.674007, lng: 21.6803153 },
    { lat: 48.6737835, lng: 21.6804121 },
    { lat: 48.6737174, lng: 21.6802941 },
    { lat: 48.6736599, lng: 21.6802948 },
    { lat: 48.6736963, lng: 21.6805955 },
    { lat: 48.6735281, lng: 21.6806152 },
    { lat: 48.6733882, lng: 21.6800237 },
    { lat: 48.6733039, lng: 21.680029 },
    { lat: 48.6732236, lng: 21.6798919 },
    { lat: 48.6731085, lng: 21.6799169 },
    { lat: 48.6730481, lng: 21.6797707 },
    { lat: 48.6728481, lng: 21.6798202 },
    { lat: 48.6728214, lng: 21.6800767 },
    { lat: 48.6725799, lng: 21.6800893 },
    { lat: 48.6724804, lng: 21.6798669 },
    { lat: 48.6721093, lng: 21.6798725 },
    { lat: 48.6718907, lng: 21.679779 },
    { lat: 48.6718139, lng: 21.6796495 },
    { lat: 48.6704208, lng: 21.6800931 },
    { lat: 48.6700305, lng: 21.6800099 },
    { lat: 48.669634, lng: 21.6797864 },
    { lat: 48.6693469, lng: 21.6797035 },
    { lat: 48.6692062, lng: 21.6796204 },
    { lat: 48.6694493, lng: 21.6828389 },
    { lat: 48.6694909, lng: 21.6842036 },
    { lat: 48.6694689, lng: 21.6853785 },
    { lat: 48.6696984, lng: 21.6875649 },
    { lat: 48.6701586, lng: 21.6910098 },
    { lat: 48.6701843, lng: 21.6928258 },
    { lat: 48.6701537, lng: 21.6973388 },
    { lat: 48.6702209, lng: 21.6985738 },
    { lat: 48.6703645, lng: 21.6996794 },
    { lat: 48.6700043, lng: 21.7030335 },
    { lat: 48.6699581, lng: 21.7041167 },
    { lat: 48.6698724, lng: 21.7049375 },
    { lat: 48.669793, lng: 21.7053322 },
    { lat: 48.6696, lng: 21.7058936 },
    { lat: 48.6701172, lng: 21.7092968 },
    { lat: 48.6701453, lng: 21.7102939 },
    { lat: 48.6702551, lng: 21.7114437 },
    { lat: 48.6702915, lng: 21.7130881 },
    { lat: 48.6704907, lng: 21.7157545 },
    { lat: 48.6717077, lng: 21.7244822 },
    { lat: 48.6718945, lng: 21.7252386 },
    { lat: 48.6717147, lng: 21.7255422 },
    { lat: 48.6715588, lng: 21.7260579 },
    { lat: 48.6715411, lng: 21.7262825 },
    { lat: 48.6712274, lng: 21.7272518 },
    { lat: 48.6711078, lng: 21.7274891 },
    { lat: 48.6711354, lng: 21.7275938 },
    { lat: 48.6711089, lng: 21.7281611 },
    { lat: 48.6710009, lng: 21.7295481 },
    { lat: 48.6710638, lng: 21.7299615 },
    { lat: 48.6712379, lng: 21.7302014 },
    { lat: 48.672314, lng: 21.7300125 },
    { lat: 48.6723751, lng: 21.7305655 },
    { lat: 48.672331, lng: 21.7315257 },
    { lat: 48.6720433, lng: 21.7321652 },
    { lat: 48.6717325, lng: 21.7324526 },
    { lat: 48.6720427, lng: 21.7333189 },
    { lat: 48.6728366, lng: 21.7351115 },
    { lat: 48.6729358, lng: 21.7354971 },
    { lat: 48.672851, lng: 21.7356468 },
    { lat: 48.6728111, lng: 21.7357179 },
    { lat: 48.6728176, lng: 21.7357488 },
    { lat: 48.6756092, lng: 21.7484708 },
    { lat: 48.6702555, lng: 21.751154 },
    { lat: 48.669336, lng: 21.7513476 },
    { lat: 48.6686217, lng: 21.7516505 },
    { lat: 48.6686352, lng: 21.7522014 },
    { lat: 48.6688483, lng: 21.752937 },
    { lat: 48.6689083, lng: 21.7532406 },
    { lat: 48.6689249, lng: 21.7536606 },
    { lat: 48.6688328, lng: 21.7538755 },
    { lat: 48.6682881, lng: 21.7543766 },
    { lat: 48.6680927, lng: 21.7547245 },
    { lat: 48.6680695, lng: 21.7549149 },
    { lat: 48.6682211, lng: 21.7555371 },
    { lat: 48.6681989, lng: 21.7556314 },
    { lat: 48.6680964, lng: 21.7557159 },
    { lat: 48.6600489, lng: 21.7480303 },
    { lat: 48.6586291, lng: 21.7466048 },
    { lat: 48.6558806, lng: 21.7436098 },
    { lat: 48.6556455, lng: 21.7434875 },
    { lat: 48.6555182, lng: 21.7436112 },
    { lat: 48.6551323, lng: 21.7436479 },
    { lat: 48.6568512, lng: 21.7615512 },
    { lat: 48.6570747, lng: 21.7615338 },
    { lat: 48.6569606, lng: 21.7622802 },
    { lat: 48.6565559, lng: 21.7636953 },
    { lat: 48.6569791, lng: 21.7653903 },
    { lat: 48.6570591, lng: 21.7659118 },
    { lat: 48.6570439, lng: 21.7672379 },
    { lat: 48.6575825, lng: 21.7674033 },
    { lat: 48.6578339, lng: 21.7677625 },
    { lat: 48.6582998, lng: 21.7696774 },
    { lat: 48.6583245, lng: 21.770057 },
    { lat: 48.6584059, lng: 21.7703652 },
    { lat: 48.6584628, lng: 21.7703508 },
    { lat: 48.6584984, lng: 21.7705447 },
    { lat: 48.6591401, lng: 21.7718338 },
    { lat: 48.6593688, lng: 21.7724399 },
    { lat: 48.6602882, lng: 21.7744532 },
    { lat: 48.660474, lng: 21.775099 },
    { lat: 48.660574, lng: 21.775079 },
    { lat: 48.660728, lng: 21.774866 },
    { lat: 48.661094, lng: 21.774794 },
    { lat: 48.661497, lng: 21.774786 },
    { lat: 48.66188, lng: 21.77488 },
    { lat: 48.662593, lng: 21.775333 },
    { lat: 48.663469, lng: 21.773563 },
    { lat: 48.663528, lng: 21.773444 },
    { lat: 48.663543, lng: 21.773411 },
    { lat: 48.663591, lng: 21.773547 },
    { lat: 48.663591, lng: 21.773548 },
    { lat: 48.663697, lng: 21.773501 },
    { lat: 48.66379, lng: 21.773459 },
    { lat: 48.663842, lng: 21.773317 },
    { lat: 48.664235, lng: 21.773009 },
    { lat: 48.664806, lng: 21.772562 },
    { lat: 48.665258, lng: 21.772132 },
    { lat: 48.665462, lng: 21.772063 },
    { lat: 48.665522, lng: 21.772042 },
    { lat: 48.666269, lng: 21.771761 },
    { lat: 48.666706, lng: 21.771582 },
    { lat: 48.6668, lng: 21.771173 },
    { lat: 48.666795, lng: 21.77104 },
    { lat: 48.66676, lng: 21.770016 },
    { lat: 48.666948, lng: 21.769687 },
    { lat: 48.667021, lng: 21.769559 },
    { lat: 48.668029, lng: 21.768527 },
    { lat: 48.668457, lng: 21.76843 },
    { lat: 48.668689, lng: 21.768378 },
    { lat: 48.669455, lng: 21.769548 },
    { lat: 48.670206, lng: 21.770029 },
    { lat: 48.670791, lng: 21.770158 },
    { lat: 48.671322, lng: 21.769607 },
    { lat: 48.671808, lng: 21.769102 },
    { lat: 48.672488, lng: 21.768421 },
    { lat: 48.672615, lng: 21.768295 },
    { lat: 48.673002, lng: 21.767919 },
    { lat: 48.67356, lng: 21.767339 },
    { lat: 48.673613, lng: 21.76732 },
    { lat: 48.673772, lng: 21.767265 },
    { lat: 48.674142, lng: 21.767182 },
    { lat: 48.674269, lng: 21.767441 },
    { lat: 48.674286, lng: 21.767475 },
    { lat: 48.674669, lng: 21.767231 },
    { lat: 48.675318, lng: 21.766588 },
    { lat: 48.675733, lng: 21.766541 },
    { lat: 48.675762, lng: 21.766568 },
    { lat: 48.676838, lng: 21.766608 },
    { lat: 48.676955, lng: 21.766605 },
    { lat: 48.676968, lng: 21.766607 },
    { lat: 48.677016, lng: 21.766612 },
    { lat: 48.677052, lng: 21.766346 },
    { lat: 48.677085, lng: 21.766096 },
    { lat: 48.677109, lng: 21.765843 },
    { lat: 48.677131, lng: 21.765627 },
    { lat: 48.677191, lng: 21.765228 },
    { lat: 48.677346, lng: 21.764971 },
    { lat: 48.677523, lng: 21.764667 },
    { lat: 48.67754, lng: 21.764543 },
    { lat: 48.677568, lng: 21.764341 },
    { lat: 48.67771, lng: 21.763786 },
    { lat: 48.677752, lng: 21.763618 },
    { lat: 48.677787, lng: 21.763485 },
    { lat: 48.677906, lng: 21.763263 },
    { lat: 48.677887, lng: 21.762861 },
    { lat: 48.677881, lng: 21.762748 },
    { lat: 48.678038, lng: 21.762595 },
    { lat: 48.678083, lng: 21.762528 },
    { lat: 48.678118, lng: 21.762473 },
    { lat: 48.678189, lng: 21.762433 },
    { lat: 48.678232, lng: 21.762408 },
    { lat: 48.678422, lng: 21.762395 },
    { lat: 48.678614, lng: 21.762321 },
    { lat: 48.678751, lng: 21.762321 },
    { lat: 48.67881, lng: 21.762304 },
    { lat: 48.678972, lng: 21.762257 },
    { lat: 48.679449, lng: 21.76219 },
    { lat: 48.679627, lng: 21.762064 },
    { lat: 48.679659, lng: 21.761991 },
    { lat: 48.679673, lng: 21.761959 },
    { lat: 48.67973, lng: 21.761895 },
    { lat: 48.679748, lng: 21.761875 },
    { lat: 48.680261, lng: 21.761776 },
    { lat: 48.680382, lng: 21.761726 },
    { lat: 48.68045, lng: 21.761628 },
    { lat: 48.680529, lng: 21.761516 },
    { lat: 48.680615, lng: 21.761255 },
    { lat: 48.680696, lng: 21.761011 },
    { lat: 48.680835, lng: 21.760722 },
    { lat: 48.680917, lng: 21.760602 },
    { lat: 48.680951, lng: 21.760552 },
    { lat: 48.681369, lng: 21.760452 },
    { lat: 48.681966, lng: 21.76031 },
    { lat: 48.68205, lng: 21.760255 },
    { lat: 48.682065, lng: 21.760244 },
    { lat: 48.682759, lng: 21.760697 },
    { lat: 48.683158, lng: 21.760884 },
    { lat: 48.683165, lng: 21.760888 },
    { lat: 48.683424, lng: 21.761154 },
    { lat: 48.683515, lng: 21.761186 },
    { lat: 48.6836, lng: 21.761216 },
    { lat: 48.683793, lng: 21.761069 },
    { lat: 48.683933, lng: 21.760963 },
    { lat: 48.684203, lng: 21.760845 },
    { lat: 48.684362, lng: 21.760983 },
    { lat: 48.684481, lng: 21.761007 },
    { lat: 48.684791, lng: 21.761069 },
    { lat: 48.684865, lng: 21.761039 },
    { lat: 48.684888, lng: 21.761029 },
    { lat: 48.685118, lng: 21.760618 },
    { lat: 48.685444, lng: 21.760314 },
    { lat: 48.68551, lng: 21.760249 },
    { lat: 48.68555, lng: 21.760206 },
    { lat: 48.685582, lng: 21.760193 },
    { lat: 48.685783, lng: 21.760338 },
    { lat: 48.685821, lng: 21.760365 },
    { lat: 48.686391, lng: 21.760765 },
    { lat: 48.68655, lng: 21.760873 },
    { lat: 48.686696, lng: 21.760974 },
    { lat: 48.686845, lng: 21.761066 },
    { lat: 48.686983, lng: 21.761153 },
    { lat: 48.687208, lng: 21.761159 },
    { lat: 48.687356, lng: 21.761163 },
    { lat: 48.687453, lng: 21.761134 },
    { lat: 48.687772, lng: 21.761039 },
    { lat: 48.687804, lng: 21.76105 },
    { lat: 48.687874, lng: 21.761073 },
    { lat: 48.687937, lng: 21.761058 },
    { lat: 48.687949, lng: 21.761055 },
    { lat: 48.68809, lng: 21.760893 },
    { lat: 48.688213, lng: 21.760889 },
    { lat: 48.688354, lng: 21.7609 },
    { lat: 48.688388, lng: 21.760903 },
    { lat: 48.688506, lng: 21.76083 },
    { lat: 48.688601, lng: 21.760577 },
    { lat: 48.688618, lng: 21.760558 },
    { lat: 48.688663, lng: 21.760509 },
    { lat: 48.688713, lng: 21.760448 },
    { lat: 48.688744, lng: 21.760412 },
    { lat: 48.688797, lng: 21.76035 },
    { lat: 48.689259, lng: 21.760045 },
    { lat: 48.689314, lng: 21.760058 },
    { lat: 48.689417, lng: 21.760082 },
    { lat: 48.689556, lng: 21.760118 },
    { lat: 48.689642, lng: 21.760137 },
    { lat: 48.689709, lng: 21.760152 },
    { lat: 48.689852, lng: 21.760218 },
    { lat: 48.690424, lng: 21.760482 },
    { lat: 48.690589, lng: 21.760324 },
    { lat: 48.690635, lng: 21.76029 },
    { lat: 48.690713, lng: 21.76023 },
    { lat: 48.690713, lng: 21.760231 },
    { lat: 48.690937, lng: 21.760264 },
    { lat: 48.69105, lng: 21.760131 },
    { lat: 48.691425, lng: 21.759689 },
    { lat: 48.691429, lng: 21.759689 },
    { lat: 48.691457, lng: 21.759683 },
    { lat: 48.6915, lng: 21.759676 },
    { lat: 48.691603, lng: 21.759657 },
    { lat: 48.691631, lng: 21.75967 },
    { lat: 48.69169, lng: 21.759697 },
    { lat: 48.691821, lng: 21.759913 },
    { lat: 48.692552, lng: 21.75963 },
    { lat: 48.692556, lng: 21.759619 },
    { lat: 48.692589, lng: 21.759531 },
    { lat: 48.692609, lng: 21.759395 },
    { lat: 48.692626, lng: 21.75929 },
    { lat: 48.692816, lng: 21.759125 },
    { lat: 48.692918, lng: 21.759098 },
    { lat: 48.693481, lng: 21.758798 },
    { lat: 48.693863, lng: 21.758214 },
    { lat: 48.693887, lng: 21.757977 },
    { lat: 48.693914, lng: 21.757723 },
    { lat: 48.694113, lng: 21.757468 },
    { lat: 48.694198, lng: 21.757283 },
    { lat: 48.694239, lng: 21.757196 },
    { lat: 48.694347, lng: 21.757161 },
    { lat: 48.694421, lng: 21.757145 },
    { lat: 48.694801, lng: 21.756615 },
    { lat: 48.694869, lng: 21.756541 },
    { lat: 48.69494, lng: 21.756463 },
    { lat: 48.694994, lng: 21.756281 },
    { lat: 48.695254, lng: 21.756061 },
    { lat: 48.69529, lng: 21.756003 },
    { lat: 48.695818, lng: 21.755166 },
    { lat: 48.695992, lng: 21.75459 },
    { lat: 48.696024, lng: 21.754453 },
    { lat: 48.696134, lng: 21.753976 },
    { lat: 48.69613, lng: 21.753811 },
    { lat: 48.696191, lng: 21.753698 },
    { lat: 48.696369, lng: 21.753456 },
    { lat: 48.696398, lng: 21.75333 },
    { lat: 48.696425, lng: 21.753051 },
    { lat: 48.696451, lng: 21.7529 },
    { lat: 48.696575, lng: 21.752544 },
    { lat: 48.696644, lng: 21.752115 },
    { lat: 48.696673, lng: 21.752027 },
    { lat: 48.696706, lng: 21.75193 },
    { lat: 48.696766, lng: 21.751807 },
    { lat: 48.696808, lng: 21.75172 },
    { lat: 48.696897, lng: 21.751434 },
    { lat: 48.696982, lng: 21.751162 },
    { lat: 48.697049, lng: 21.751 },
    { lat: 48.69728, lng: 21.750774 },
    { lat: 48.697351, lng: 21.750681 },
    { lat: 48.697435, lng: 21.750505 },
    { lat: 48.697535, lng: 21.750296 },
    { lat: 48.69759, lng: 21.750098 },
    { lat: 48.697578, lng: 21.749964 },
    { lat: 48.697558, lng: 21.749753 },
    { lat: 48.69744, lng: 21.749586 },
    { lat: 48.697473, lng: 21.749276 },
    { lat: 48.697487, lng: 21.74914 },
    { lat: 48.697489, lng: 21.749069 },
    { lat: 48.697491, lng: 21.748882 },
    { lat: 48.697492, lng: 21.748866 },
    { lat: 48.697494, lng: 21.748835 },
    { lat: 48.697501, lng: 21.74871 },
    { lat: 48.697509, lng: 21.748657 },
    { lat: 48.697513, lng: 21.748594 },
    { lat: 48.697517, lng: 21.748526 },
    { lat: 48.697567, lng: 21.748428 },
    { lat: 48.697651, lng: 21.748263 },
    { lat: 48.697657, lng: 21.748082 },
    { lat: 48.69766, lng: 21.748001 },
    { lat: 48.697617, lng: 21.747773 },
    { lat: 48.697609, lng: 21.747614 },
    { lat: 48.697604, lng: 21.747545 },
    { lat: 48.697689, lng: 21.747226 },
    { lat: 48.697709, lng: 21.747118 },
    { lat: 48.697721, lng: 21.747052 },
    { lat: 48.697697, lng: 21.746961 },
    { lat: 48.697672, lng: 21.746864 },
    { lat: 48.697661, lng: 21.746783 },
    { lat: 48.697642, lng: 21.74664 },
    { lat: 48.697618, lng: 21.746475 },
    { lat: 48.697547, lng: 21.746316 },
    { lat: 48.697433, lng: 21.746063 },
    { lat: 48.69738, lng: 21.745861 },
    { lat: 48.697376, lng: 21.745848 },
    { lat: 48.697391, lng: 21.745681 },
    { lat: 48.69753, lng: 21.745235 },
    { lat: 48.697546, lng: 21.745187 },
    { lat: 48.69757, lng: 21.745146 },
    { lat: 48.697702, lng: 21.744919 },
    { lat: 48.697762, lng: 21.744762 },
    { lat: 48.697756, lng: 21.744666 },
    { lat: 48.69774, lng: 21.744399 },
    { lat: 48.697764, lng: 21.744215 },
    { lat: 48.697793, lng: 21.744001 },
    { lat: 48.697881, lng: 21.743786 },
    { lat: 48.697901, lng: 21.743736 },
    { lat: 48.698043, lng: 21.743469 },
    { lat: 48.6983, lng: 21.742758 },
    { lat: 48.698557, lng: 21.74268 },
    { lat: 48.698867, lng: 21.742585 },
    { lat: 48.698926, lng: 21.742566 },
    { lat: 48.698952, lng: 21.742519 },
    { lat: 48.698984, lng: 21.742458 },
    { lat: 48.699015, lng: 21.742435 },
    { lat: 48.699039, lng: 21.742419 },
    { lat: 48.699053, lng: 21.74241 },
    { lat: 48.699572, lng: 21.742239 },
    { lat: 48.699968, lng: 21.741942 },
    { lat: 48.700152, lng: 21.741717 },
    { lat: 48.700263, lng: 21.741533 },
    { lat: 48.700296, lng: 21.74148 },
    { lat: 48.700322, lng: 21.741209 },
    { lat: 48.700343, lng: 21.740932 },
    { lat: 48.700484, lng: 21.740232 },
    { lat: 48.70055, lng: 21.739574 },
    { lat: 48.700551, lng: 21.739557 },
    { lat: 48.700563, lng: 21.739154 },
    { lat: 48.700656, lng: 21.738834 },
    { lat: 48.70067, lng: 21.738389 },
    { lat: 48.700647, lng: 21.738278 },
    { lat: 48.700622, lng: 21.738161 },
    { lat: 48.700615, lng: 21.737622 },
    { lat: 48.700609, lng: 21.737264 },
    { lat: 48.700568, lng: 21.736943 },
    { lat: 48.700531, lng: 21.736662 },
    { lat: 48.70052, lng: 21.736495 },
    { lat: 48.7005, lng: 21.73621 },
    { lat: 48.700516, lng: 21.736081 },
    { lat: 48.700526, lng: 21.735991 },
    { lat: 48.700538, lng: 21.735951 },
    { lat: 48.700553, lng: 21.735908 },
    { lat: 48.700671, lng: 21.736015 },
    { lat: 48.701159, lng: 21.735817 },
    { lat: 48.701189, lng: 21.735773 },
    { lat: 48.701336, lng: 21.735558 },
    { lat: 48.701429, lng: 21.735352 },
    { lat: 48.701439, lng: 21.73533 },
    { lat: 48.701551, lng: 21.735196 },
    { lat: 48.701713, lng: 21.734999 },
    { lat: 48.701809, lng: 21.734884 },
    { lat: 48.701841, lng: 21.734828 },
    { lat: 48.702017, lng: 21.734523 },
    { lat: 48.702083, lng: 21.734226 },
    { lat: 48.70206, lng: 21.734086 },
    { lat: 48.702009, lng: 21.733787 },
    { lat: 48.701982, lng: 21.733436 },
    { lat: 48.701946, lng: 21.73297 },
    { lat: 48.701976, lng: 21.732491 },
    { lat: 48.70211, lng: 21.73226 },
    { lat: 48.702628, lng: 21.732466 },
    { lat: 48.702704, lng: 21.732457 },
    { lat: 48.702774, lng: 21.73245 },
    { lat: 48.702827, lng: 21.732409 },
    { lat: 48.702878, lng: 21.732369 },
    { lat: 48.703103, lng: 21.732015 },
    { lat: 48.70327, lng: 21.731721 },
    { lat: 48.703469, lng: 21.731333 },
    { lat: 48.704235, lng: 21.731416 },
    { lat: 48.704645, lng: 21.731719 },
    { lat: 48.704708, lng: 21.731739 },
    { lat: 48.704908, lng: 21.731803 },
    { lat: 48.704977, lng: 21.731854 },
    { lat: 48.70508, lng: 21.73193 },
    { lat: 48.705255, lng: 21.731969 },
    { lat: 48.705275, lng: 21.731974 },
    { lat: 48.705522, lng: 21.731916 },
    { lat: 48.705567, lng: 21.731917 },
    { lat: 48.705642, lng: 21.731917 },
    { lat: 48.705811, lng: 21.731944 },
    { lat: 48.70588, lng: 21.73196 },
    { lat: 48.706184, lng: 21.732032 },
    { lat: 48.706189, lng: 21.732034 },
    { lat: 48.706578, lng: 21.732188 },
    { lat: 48.7065143, lng: 21.7318565 },
    { lat: 48.7061685, lng: 21.7311723 },
    { lat: 48.7062239, lng: 21.7302602 },
    { lat: 48.7062018, lng: 21.7301016 },
    { lat: 48.706, lng: 21.7297938 },
    { lat: 48.7058071, lng: 21.7289425 },
    { lat: 48.7056074, lng: 21.7284571 },
    { lat: 48.7055354, lng: 21.7280853 },
    { lat: 48.7055248, lng: 21.7275566 },
    { lat: 48.7056988, lng: 21.7272924 },
    { lat: 48.7057365, lng: 21.7271105 },
    { lat: 48.7056844, lng: 21.726801 },
    { lat: 48.7056989, lng: 21.7262515 },
    { lat: 48.7056517, lng: 21.7255395 },
    { lat: 48.7055612, lng: 21.7248451 },
    { lat: 48.7054248, lng: 21.724254 },
    { lat: 48.7054547, lng: 21.7240224 },
    { lat: 48.7054198, lng: 21.7236024 },
    { lat: 48.7055285, lng: 21.7232972 },
    { lat: 48.7056267, lng: 21.7231858 },
    { lat: 48.7057121, lng: 21.7230904 },
  ],
  VeľkéTrakany: [
    { lat: 48.3849799, lng: 22.1353804 },
    { lat: 48.38522, lng: 22.135132 },
    { lat: 48.385521, lng: 22.134778 },
    { lat: 48.386253, lng: 22.133956 },
    { lat: 48.386899, lng: 22.133312 },
    { lat: 48.387088, lng: 22.133145 },
    { lat: 48.387536, lng: 22.132633 },
    { lat: 48.387757, lng: 22.132449 },
    { lat: 48.387916, lng: 22.132259 },
    { lat: 48.388125, lng: 22.132098 },
    { lat: 48.388394, lng: 22.131836 },
    { lat: 48.388789, lng: 22.131572 },
    { lat: 48.389505, lng: 22.130985 },
    { lat: 48.389703, lng: 22.13082 },
    { lat: 48.390262, lng: 22.130741 },
    { lat: 48.390831, lng: 22.130803 },
    { lat: 48.390934, lng: 22.130868 },
    { lat: 48.3911076, lng: 22.1306702 },
    { lat: 48.3929855, lng: 22.1292505 },
    { lat: 48.3948586, lng: 22.1287272 },
    { lat: 48.3956579, lng: 22.1272683 },
    { lat: 48.3957464, lng: 22.126722 },
    { lat: 48.3953714, lng: 22.1261345 },
    { lat: 48.3922473, lng: 22.1273182 },
    { lat: 48.3909327, lng: 22.1270991 },
    { lat: 48.3894309, lng: 22.1263402 },
    { lat: 48.3877895, lng: 22.1251398 },
    { lat: 48.3867468, lng: 22.1241861 },
    { lat: 48.3861862, lng: 22.1235038 },
    { lat: 48.385885, lng: 22.1229468 },
    { lat: 48.3854131, lng: 22.1211582 },
    { lat: 48.3853465, lng: 22.1205215 },
    { lat: 48.3853359, lng: 22.1199834 },
    { lat: 48.3853869, lng: 22.1195674 },
    { lat: 48.3855701, lng: 22.1187981 },
    { lat: 48.3856513, lng: 22.1186144 },
    { lat: 48.386008, lng: 22.1181863 },
    { lat: 48.3870784, lng: 22.117595 },
    { lat: 48.3876767, lng: 22.1174435 },
    { lat: 48.3878352, lng: 22.1172992 },
    { lat: 48.3887358, lng: 22.1168435 },
    { lat: 48.3890607, lng: 22.116507 },
    { lat: 48.3899443, lng: 22.1150035 },
    { lat: 48.3902246, lng: 22.1139643 },
    { lat: 48.3903203, lng: 22.1132984 },
    { lat: 48.3901879, lng: 22.1113164 },
    { lat: 48.3896923, lng: 22.1090136 },
    { lat: 48.3912563, lng: 22.1082137 },
    { lat: 48.3926515, lng: 22.1070823 },
    { lat: 48.3955681, lng: 22.1040756 },
    { lat: 48.3957621, lng: 22.1045721 },
    { lat: 48.3966912, lng: 22.1034319 },
    { lat: 48.3965632, lng: 22.1031134 },
    { lat: 48.3983608, lng: 22.1013028 },
    { lat: 48.3983662, lng: 22.1011008 },
    { lat: 48.3985187, lng: 22.1004253 },
    { lat: 48.3987135, lng: 22.100428 },
    { lat: 48.3988838, lng: 22.1001152 },
    { lat: 48.399899, lng: 22.0979598 },
    { lat: 48.4004044, lng: 22.09674 },
    { lat: 48.4006559, lng: 22.0958259 },
    { lat: 48.4007431, lng: 22.0952803 },
    { lat: 48.40117, lng: 22.0950606 },
    { lat: 48.401102, lng: 22.0948978 },
    { lat: 48.4013019, lng: 22.0948899 },
    { lat: 48.4010261, lng: 22.0936519 },
    { lat: 48.4011628, lng: 22.0936311 },
    { lat: 48.4017337, lng: 22.0932217 },
    { lat: 48.4028816, lng: 22.0926304 },
    { lat: 48.4027748, lng: 22.0921568 },
    { lat: 48.402355, lng: 22.0892689 },
    { lat: 48.4026212, lng: 22.0891144 },
    { lat: 48.4025371, lng: 22.0883273 },
    { lat: 48.4026231, lng: 22.0878306 },
    { lat: 48.4031173, lng: 22.0868053 },
    { lat: 48.4040496, lng: 22.0855224 },
    { lat: 48.4048087, lng: 22.0846155 },
    { lat: 48.4052303, lng: 22.0853087 },
    { lat: 48.4054946, lng: 22.0853036 },
    { lat: 48.4049682, lng: 22.0824388 },
    { lat: 48.4048462, lng: 22.0815188 },
    { lat: 48.4035268, lng: 22.0738702 },
    { lat: 48.403179, lng: 22.071894 },
    { lat: 48.4012296, lng: 22.0727922 },
    { lat: 48.4005948, lng: 22.070861 },
    { lat: 48.4000656, lng: 22.0711265 },
    { lat: 48.3999758, lng: 22.0710881 },
    { lat: 48.400752, lng: 22.0689241 },
    { lat: 48.4006805, lng: 22.0688508 },
    { lat: 48.4003188, lng: 22.066906 },
    { lat: 48.4000276, lng: 22.0667285 },
    { lat: 48.399794, lng: 22.0666523 },
    { lat: 48.399589, lng: 22.0666942 },
    { lat: 48.3987679, lng: 22.0670276 },
    { lat: 48.3985483, lng: 22.0665393 },
    { lat: 48.3974769, lng: 22.067699 },
    { lat: 48.3962968, lng: 22.0669428 },
    { lat: 48.3958838, lng: 22.0681613 },
    { lat: 48.3923683, lng: 22.0669462 },
    { lat: 48.3905274, lng: 22.0644975 },
    { lat: 48.3918933, lng: 22.0628621 },
    { lat: 48.3920336, lng: 22.0626194 },
    { lat: 48.3920658, lng: 22.0623211 },
    { lat: 48.3920774, lng: 22.0618252 },
    { lat: 48.3919959, lng: 22.0611922 },
    { lat: 48.3916689, lng: 22.0605144 },
    { lat: 48.3913798, lng: 22.0602279 },
    { lat: 48.3914097, lng: 22.0584876 },
    { lat: 48.3907527, lng: 22.0584897 },
    { lat: 48.3905707, lng: 22.0573719 },
    { lat: 48.3906121, lng: 22.0572327 },
    { lat: 48.3909844, lng: 22.0571756 },
    { lat: 48.3909572, lng: 22.0570765 },
    { lat: 48.3907109, lng: 22.0568938 },
    { lat: 48.3906347, lng: 22.0567231 },
    { lat: 48.390135, lng: 22.0561008 },
    { lat: 48.3896439, lng: 22.0557963 },
    { lat: 48.3893465, lng: 22.0557346 },
    { lat: 48.3883288, lng: 22.0558013 },
    { lat: 48.3879755, lng: 22.0557529 },
    { lat: 48.387621, lng: 22.0556112 },
    { lat: 48.3873547, lng: 22.0554391 },
    { lat: 48.387064, lng: 22.0550476 },
    { lat: 48.386899, lng: 22.0547345 },
    { lat: 48.3868867, lng: 22.054498 },
    { lat: 48.3850737, lng: 22.0548052 },
    { lat: 48.3819352, lng: 22.0496435 },
    { lat: 48.381431, lng: 22.048971 },
    { lat: 48.381417, lng: 22.04899 },
    { lat: 48.381326, lng: 22.049091 },
    { lat: 48.381307, lng: 22.049165 },
    { lat: 48.381274, lng: 22.049224 },
    { lat: 48.381225, lng: 22.049227 },
    { lat: 48.381102, lng: 22.049407 },
    { lat: 48.380734, lng: 22.049942 },
    { lat: 48.380457, lng: 22.050349 },
    { lat: 48.380227, lng: 22.050687 },
    { lat: 48.380195, lng: 22.050734 },
    { lat: 48.3797539, lng: 22.0513686 },
    { lat: 48.379311, lng: 22.05197 },
    { lat: 48.379223, lng: 22.052084 },
    { lat: 48.379151, lng: 22.052201 },
    { lat: 48.378935, lng: 22.052541 },
    { lat: 48.378739, lng: 22.052869 },
    { lat: 48.378658, lng: 22.052994 },
    { lat: 48.378533, lng: 22.053178 },
    { lat: 48.378174, lng: 22.053701 },
    { lat: 48.377878, lng: 22.054109 },
    { lat: 48.377702, lng: 22.054355 },
    { lat: 48.377528, lng: 22.054605 },
    { lat: 48.377709, lng: 22.056397 },
    { lat: 48.377891, lng: 22.058236 },
    { lat: 48.378256, lng: 22.058209 },
    { lat: 48.378483, lng: 22.058182 },
    { lat: 48.379133, lng: 22.05805 },
    { lat: 48.379913, lng: 22.057896 },
    { lat: 48.38061, lng: 22.057757 },
    { lat: 48.38132, lng: 22.057615 },
    { lat: 48.381337, lng: 22.057807 },
    { lat: 48.381532, lng: 22.059768 },
    { lat: 48.382376, lng: 22.060233 },
    { lat: 48.382155, lng: 22.06141 },
    { lat: 48.382775, lng: 22.061564 },
    { lat: 48.382756, lng: 22.061986 },
    { lat: 48.38274, lng: 22.062196 },
    { lat: 48.382709, lng: 22.062545 },
    { lat: 48.382684, lng: 22.062731 },
    { lat: 48.382482, lng: 22.064252 },
    { lat: 48.382401, lng: 22.064909 },
    { lat: 48.38235, lng: 22.065404 },
    { lat: 48.382323, lng: 22.065774 },
    { lat: 48.382319, lng: 22.065979 },
    { lat: 48.382316, lng: 22.066075 },
    { lat: 48.382331, lng: 22.066623 },
    { lat: 48.381831, lng: 22.066715 },
    { lat: 48.381919, lng: 22.067593 },
    { lat: 48.381982, lng: 22.068385 },
    { lat: 48.38199, lng: 22.068534 },
    { lat: 48.38203, lng: 22.06892 },
    { lat: 48.382086, lng: 22.069586 },
    { lat: 48.38233, lng: 22.069533 },
    { lat: 48.382596, lng: 22.070536 },
    { lat: 48.382827, lng: 22.07152 },
    { lat: 48.382337, lng: 22.071668 },
    { lat: 48.381957, lng: 22.071792 },
    { lat: 48.381778, lng: 22.071863 },
    { lat: 48.382012, lng: 22.072814 },
    { lat: 48.382534, lng: 22.07255 },
    { lat: 48.382882, lng: 22.072395 },
    { lat: 48.383356, lng: 22.072203 },
    { lat: 48.383593, lng: 22.072097 },
    { lat: 48.383901, lng: 22.071938 },
    { lat: 48.383999, lng: 22.071899 },
    { lat: 48.38406, lng: 22.071895 },
    { lat: 48.384089, lng: 22.071955 },
    { lat: 48.384166, lng: 22.07197 },
    { lat: 48.384429, lng: 22.072003 },
    { lat: 48.384659, lng: 22.072051 },
    { lat: 48.384886, lng: 22.072114 },
    { lat: 48.385168, lng: 22.072164 },
    { lat: 48.385312, lng: 22.072173 },
    { lat: 48.385566, lng: 22.07311 },
    { lat: 48.38565, lng: 22.073367 },
    { lat: 48.385717, lng: 22.073542 },
    { lat: 48.386042, lng: 22.074264 },
    { lat: 48.386117, lng: 22.074456 },
    { lat: 48.386354, lng: 22.074963 },
    { lat: 48.386798, lng: 22.07582 },
    { lat: 48.387241, lng: 22.076486 },
    { lat: 48.386763, lng: 22.077074 },
    { lat: 48.386238, lng: 22.077501 },
    { lat: 48.38587, lng: 22.077742 },
    { lat: 48.385493, lng: 22.078074 },
    { lat: 48.385278, lng: 22.078269 },
    { lat: 48.3849, lng: 22.078643 },
    { lat: 48.384619, lng: 22.078936 },
    { lat: 48.384119, lng: 22.079462 },
    { lat: 48.38383, lng: 22.079811 },
    { lat: 48.3835842, lng: 22.0801463 },
    { lat: 48.3835925, lng: 22.0802711 },
    { lat: 48.3836149, lng: 22.0806117 },
    { lat: 48.38301, lng: 22.081428 },
    { lat: 48.382663, lng: 22.081858 },
    { lat: 48.382469, lng: 22.082069 },
    { lat: 48.382319, lng: 22.082262 },
    { lat: 48.381987, lng: 22.082738 },
    { lat: 48.381588, lng: 22.083284 },
    { lat: 48.381357, lng: 22.083611 },
    { lat: 48.381236, lng: 22.083777 },
    { lat: 48.380915, lng: 22.084264 },
    { lat: 48.380813, lng: 22.084393 },
    { lat: 48.380788, lng: 22.084429 },
    { lat: 48.380724, lng: 22.084264 },
    { lat: 48.380703, lng: 22.084235 },
    { lat: 48.380527, lng: 22.083991 },
    { lat: 48.380373, lng: 22.083813 },
    { lat: 48.380176, lng: 22.083624 },
    { lat: 48.379854, lng: 22.083369 },
    { lat: 48.379508, lng: 22.083195 },
    { lat: 48.378709, lng: 22.082615 },
    { lat: 48.377713, lng: 22.084017 },
    { lat: 48.377285, lng: 22.084504 },
    { lat: 48.377141, lng: 22.084154 },
    { lat: 48.376966, lng: 22.084367 },
    { lat: 48.376888, lng: 22.084031 },
    { lat: 48.375615, lng: 22.084542 },
    { lat: 48.374526, lng: 22.084986 },
    { lat: 48.374344, lng: 22.085059 },
    { lat: 48.373836, lng: 22.085269 },
    { lat: 48.373282, lng: 22.085439 },
    { lat: 48.372798, lng: 22.085575 },
    { lat: 48.372675, lng: 22.08561 },
    { lat: 48.372287, lng: 22.085752 },
    { lat: 48.371993, lng: 22.085855 },
    { lat: 48.371776, lng: 22.085936 },
    { lat: 48.371741, lng: 22.086229 },
    { lat: 48.371719, lng: 22.086346 },
    { lat: 48.371673, lng: 22.086462 },
    { lat: 48.371618, lng: 22.086561 },
    { lat: 48.371576, lng: 22.086676 },
    { lat: 48.371564, lng: 22.086743 },
    { lat: 48.371589, lng: 22.086821 },
    { lat: 48.371687, lng: 22.086955 },
    { lat: 48.371742, lng: 22.087078 },
    { lat: 48.371759, lng: 22.087114 },
    { lat: 48.371757, lng: 22.087141 },
    { lat: 48.371836, lng: 22.087161 },
    { lat: 48.372104, lng: 22.087342 },
    { lat: 48.372354, lng: 22.087528 },
    { lat: 48.372551, lng: 22.0877 },
    { lat: 48.372815, lng: 22.087983 },
    { lat: 48.37313, lng: 22.088393 },
    { lat: 48.373304, lng: 22.088599 },
    { lat: 48.373668, lng: 22.089032 },
    { lat: 48.373754, lng: 22.089173 },
    { lat: 48.37369, lng: 22.089318 },
    { lat: 48.373645, lng: 22.089441 },
    { lat: 48.373645, lng: 22.089572 },
    { lat: 48.373673, lng: 22.089706 },
    { lat: 48.373731, lng: 22.089822 },
    { lat: 48.373991, lng: 22.090157 },
    { lat: 48.37415, lng: 22.090319 },
    { lat: 48.374329, lng: 22.090485 },
    { lat: 48.374709, lng: 22.090779 },
    { lat: 48.375014, lng: 22.090988 },
    { lat: 48.375163, lng: 22.091079 },
    { lat: 48.375678, lng: 22.091458 },
    { lat: 48.3761, lng: 22.091808 },
    { lat: 48.376215, lng: 22.091909 },
    { lat: 48.376323, lng: 22.091929 },
    { lat: 48.376423, lng: 22.091967 },
    { lat: 48.376524, lng: 22.092031 },
    { lat: 48.376799, lng: 22.09225 },
    { lat: 48.376893, lng: 22.092307 },
    { lat: 48.377253, lng: 22.09254 },
    { lat: 48.377538, lng: 22.092779 },
    { lat: 48.3777, lng: 22.092887 },
    { lat: 48.378234, lng: 22.093155 },
    { lat: 48.378437, lng: 22.093243 },
    { lat: 48.378552, lng: 22.093243 },
    { lat: 48.3783, lng: 22.093426 },
    { lat: 48.37819, lng: 22.09358 },
    { lat: 48.378113, lng: 22.093759 },
    { lat: 48.378023, lng: 22.09401 },
    { lat: 48.377994, lng: 22.094114 },
    { lat: 48.377934, lng: 22.094185 },
    { lat: 48.377477, lng: 22.094489 },
    { lat: 48.377284, lng: 22.094738 },
    { lat: 48.377238, lng: 22.094945 },
    { lat: 48.3772379, lng: 22.0950549 },
    { lat: 48.377264, lng: 22.095599 },
    { lat: 48.377267, lng: 22.095692 },
    { lat: 48.376909, lng: 22.096786 },
    { lat: 48.376953, lng: 22.096861 },
    { lat: 48.3770382, lng: 22.0969847 },
    { lat: 48.37721, lng: 22.097286 },
    { lat: 48.376979, lng: 22.09761 },
    { lat: 48.376745, lng: 22.097967 },
    { lat: 48.376448, lng: 22.098467 },
    { lat: 48.376294, lng: 22.099166 },
    { lat: 48.376182, lng: 22.10001 },
    { lat: 48.37619, lng: 22.101328 },
    { lat: 48.376222, lng: 22.101549 },
    { lat: 48.376426, lng: 22.102393 },
    { lat: 48.376635, lng: 22.102987 },
    { lat: 48.376817, lng: 22.103505 },
    { lat: 48.37715, lng: 22.104106 },
    { lat: 48.377421, lng: 22.104555 },
    { lat: 48.377885, lng: 22.105132 },
    { lat: 48.378658, lng: 22.106081 },
    { lat: 48.379578, lng: 22.10728 },
    { lat: 48.380121, lng: 22.107865 },
    { lat: 48.380722, lng: 22.108627 },
    { lat: 48.381013, lng: 22.109515 },
    { lat: 48.381176, lng: 22.110303 },
    { lat: 48.381259, lng: 22.111092 },
    { lat: 48.381293, lng: 22.111556 },
    { lat: 48.381199, lng: 22.112392 },
    { lat: 48.381062, lng: 22.113439 },
    { lat: 48.381009, lng: 22.11384 },
    { lat: 48.380857, lng: 22.114751 },
    { lat: 48.380685, lng: 22.115443 },
    { lat: 48.380514, lng: 22.116297 },
    { lat: 48.38045, lng: 22.116502 },
    { lat: 48.380409, lng: 22.116569 },
    { lat: 48.380387, lng: 22.116675 },
    { lat: 48.380347, lng: 22.11671 },
    { lat: 48.380303, lng: 22.116833 },
    { lat: 48.380261, lng: 22.116869 },
    { lat: 48.380011, lng: 22.117346 },
    { lat: 48.379848, lng: 22.117625 },
    { lat: 48.37975, lng: 22.117809 },
    { lat: 48.379673, lng: 22.117975 },
    { lat: 48.379593, lng: 22.118066 },
    { lat: 48.379189, lng: 22.118687 },
    { lat: 48.378925, lng: 22.119273 },
    { lat: 48.378861, lng: 22.119484 },
    { lat: 48.378763, lng: 22.119718 },
    { lat: 48.378641, lng: 22.120281 },
    { lat: 48.378605, lng: 22.120382 },
    { lat: 48.3783056, lng: 22.1210833 },
    { lat: 48.378692, lng: 22.121516 },
    { lat: 48.379049, lng: 22.121735 },
    { lat: 48.379374, lng: 22.122183 },
    { lat: 48.379707, lng: 22.122719 },
    { lat: 48.380019, lng: 22.123323 },
    { lat: 48.38043, lng: 22.124572 },
    { lat: 48.380725, lng: 22.125509 },
    { lat: 48.380848, lng: 22.126149 },
    { lat: 48.380917, lng: 22.126837 },
    { lat: 48.380973, lng: 22.127393 },
    { lat: 48.381062, lng: 22.128636 },
    { lat: 48.380946, lng: 22.129952 },
    { lat: 48.380874, lng: 22.130592 },
    { lat: 48.380785, lng: 22.131218 },
    { lat: 48.38069, lng: 22.131758 },
    { lat: 48.380536, lng: 22.132795 },
    { lat: 48.380444, lng: 22.133473 },
    { lat: 48.380462, lng: 22.134494 },
    { lat: 48.380465, lng: 22.135222 },
    { lat: 48.380498, lng: 22.135684 },
    { lat: 48.380519, lng: 22.135909 },
    { lat: 48.38061, lng: 22.13604 },
    { lat: 48.380892, lng: 22.136281 },
    { lat: 48.381117, lng: 22.136426 },
    { lat: 48.382355, lng: 22.13664 },
    { lat: 48.382775, lng: 22.136667 },
    { lat: 48.383192, lng: 22.136512 },
    { lat: 48.383581, lng: 22.136376 },
    { lat: 48.384063, lng: 22.136125 },
    { lat: 48.384508, lng: 22.135846 },
    { lat: 48.3849799, lng: 22.1353804 },
  ],
  MaléTrakany: [
    { lat: 48.390934, lng: 22.130868 },
    { lat: 48.391233, lng: 22.131056 },
    { lat: 48.391422, lng: 22.131278 },
    { lat: 48.391765, lng: 22.131786 },
    { lat: 48.392068, lng: 22.132521 },
    { lat: 48.392202, lng: 22.132881 },
    { lat: 48.392348, lng: 22.133232 },
    { lat: 48.39271, lng: 22.134589 },
    { lat: 48.392861, lng: 22.135349 },
    { lat: 48.392948, lng: 22.136164 },
    { lat: 48.392957, lng: 22.136557 },
    { lat: 48.393026, lng: 22.136955 },
    { lat: 48.393246, lng: 22.139769 },
    { lat: 48.393275, lng: 22.140479 },
    { lat: 48.393356, lng: 22.141957 },
    { lat: 48.39393, lng: 22.146506 },
    { lat: 48.394089, lng: 22.147164 },
    { lat: 48.394292, lng: 22.147847 },
    { lat: 48.394488, lng: 22.148481 },
    { lat: 48.394791, lng: 22.149432 },
    { lat: 48.395077, lng: 22.150345 },
    { lat: 48.395366, lng: 22.151056 },
    { lat: 48.395678, lng: 22.151798 },
    { lat: 48.395832, lng: 22.152137 },
    { lat: 48.396241, lng: 22.152768 },
    { lat: 48.396552, lng: 22.153159 },
    { lat: 48.396817, lng: 22.153473 },
    { lat: 48.3973328, lng: 22.1540067 },
    { lat: 48.3979444, lng: 22.1545556 },
    { lat: 48.398285, lng: 22.154849 },
    { lat: 48.398761, lng: 22.155128 },
    { lat: 48.399341, lng: 22.155294 },
    { lat: 48.400343, lng: 22.15539 },
    { lat: 48.40087, lng: 22.155404 },
    { lat: 48.4015, lng: 22.155388 },
    { lat: 48.4028333, lng: 22.1552778 },
    { lat: 48.4028889, lng: 22.1552778 },
    { lat: 48.403388, lng: 22.155256 },
    { lat: 48.403512, lng: 22.154288 },
    { lat: 48.4035395, lng: 22.154171 },
    { lat: 48.403582, lng: 22.15399 },
    { lat: 48.4036737, lng: 22.1536108 },
    { lat: 48.403959, lng: 22.152432 },
    { lat: 48.403888, lng: 22.149575 },
    { lat: 48.403666, lng: 22.146729 },
    { lat: 48.4037005, lng: 22.1461948 },
    { lat: 48.403844, lng: 22.143973 },
    { lat: 48.405255, lng: 22.142326 },
    { lat: 48.407559, lng: 22.142121 },
    { lat: 48.4082189, lng: 22.1422416 },
    { lat: 48.4094513, lng: 22.1424668 },
    { lat: 48.4105098, lng: 22.1426603 },
    { lat: 48.411285, lng: 22.142802 },
    { lat: 48.411457, lng: 22.142253 },
    { lat: 48.4117897, lng: 22.1412156 },
    { lat: 48.4126824, lng: 22.1402806 },
    { lat: 48.414586, lng: 22.138287 },
    { lat: 48.418795, lng: 22.137059 },
    { lat: 48.421634, lng: 22.137728 },
    { lat: 48.42504, lng: 22.137889 },
    { lat: 48.4252331, lng: 22.1368291 },
    { lat: 48.4253788, lng: 22.1364268 },
    { lat: 48.4244136, lng: 22.1328186 },
    { lat: 48.4242305, lng: 22.1314945 },
    { lat: 48.4238718, lng: 22.1299234 },
    { lat: 48.4238136, lng: 22.1286925 },
    { lat: 48.4238089, lng: 22.1286441 },
    { lat: 48.4237172, lng: 22.1282951 },
    { lat: 48.4236475, lng: 22.1278789 },
    { lat: 48.4236017, lng: 22.1276376 },
    { lat: 48.4235593, lng: 22.1274224 },
    { lat: 48.423284, lng: 22.1259642 },
    { lat: 48.4209098, lng: 22.1227415 },
    { lat: 48.420767, lng: 22.122311 },
    { lat: 48.4193823, lng: 22.1167833 },
    { lat: 48.4191792, lng: 22.1161878 },
    { lat: 48.4165037, lng: 22.1132135 },
    { lat: 48.4151543, lng: 22.1119055 },
    { lat: 48.414557, lng: 22.1110995 },
    { lat: 48.4128029, lng: 22.1091355 },
    { lat: 48.412618, lng: 22.1052667 },
    { lat: 48.4112299, lng: 22.0966423 },
    { lat: 48.4077362, lng: 22.0981751 },
    { lat: 48.4058194, lng: 22.0871746 },
    { lat: 48.4054946, lng: 22.0853036 },
    { lat: 48.4052303, lng: 22.0853087 },
    { lat: 48.4048087, lng: 22.0846155 },
    { lat: 48.4040496, lng: 22.0855224 },
    { lat: 48.4031173, lng: 22.0868053 },
    { lat: 48.4026231, lng: 22.0878306 },
    { lat: 48.4025371, lng: 22.0883273 },
    { lat: 48.4026212, lng: 22.0891144 },
    { lat: 48.402355, lng: 22.0892689 },
    { lat: 48.4027748, lng: 22.0921568 },
    { lat: 48.4028816, lng: 22.0926304 },
    { lat: 48.4017337, lng: 22.0932217 },
    { lat: 48.4011628, lng: 22.0936311 },
    { lat: 48.4010261, lng: 22.0936519 },
    { lat: 48.4013019, lng: 22.0948899 },
    { lat: 48.401102, lng: 22.0948978 },
    { lat: 48.40117, lng: 22.0950606 },
    { lat: 48.4007431, lng: 22.0952803 },
    { lat: 48.4006559, lng: 22.0958259 },
    { lat: 48.4004044, lng: 22.09674 },
    { lat: 48.399899, lng: 22.0979598 },
    { lat: 48.3988838, lng: 22.1001152 },
    { lat: 48.3987135, lng: 22.100428 },
    { lat: 48.3985187, lng: 22.1004253 },
    { lat: 48.3983662, lng: 22.1011008 },
    { lat: 48.3983608, lng: 22.1013028 },
    { lat: 48.3965632, lng: 22.1031134 },
    { lat: 48.3966912, lng: 22.1034319 },
    { lat: 48.3957621, lng: 22.1045721 },
    { lat: 48.3955681, lng: 22.1040756 },
    { lat: 48.3926515, lng: 22.1070823 },
    { lat: 48.3912563, lng: 22.1082137 },
    { lat: 48.3896923, lng: 22.1090136 },
    { lat: 48.3901879, lng: 22.1113164 },
    { lat: 48.3903203, lng: 22.1132984 },
    { lat: 48.3902246, lng: 22.1139643 },
    { lat: 48.3899443, lng: 22.1150035 },
    { lat: 48.3890607, lng: 22.116507 },
    { lat: 48.3887358, lng: 22.1168435 },
    { lat: 48.3878352, lng: 22.1172992 },
    { lat: 48.3876767, lng: 22.1174435 },
    { lat: 48.3870784, lng: 22.117595 },
    { lat: 48.386008, lng: 22.1181863 },
    { lat: 48.3856513, lng: 22.1186144 },
    { lat: 48.3855701, lng: 22.1187981 },
    { lat: 48.3853869, lng: 22.1195674 },
    { lat: 48.3853359, lng: 22.1199834 },
    { lat: 48.3853465, lng: 22.1205215 },
    { lat: 48.3854131, lng: 22.1211582 },
    { lat: 48.385885, lng: 22.1229468 },
    { lat: 48.3861862, lng: 22.1235038 },
    { lat: 48.3867468, lng: 22.1241861 },
    { lat: 48.3877895, lng: 22.1251398 },
    { lat: 48.3894309, lng: 22.1263402 },
    { lat: 48.3909327, lng: 22.1270991 },
    { lat: 48.3922473, lng: 22.1273182 },
    { lat: 48.3953714, lng: 22.1261345 },
    { lat: 48.3957464, lng: 22.126722 },
    { lat: 48.3956579, lng: 22.1272683 },
    { lat: 48.3948586, lng: 22.1287272 },
    { lat: 48.3929855, lng: 22.1292505 },
    { lat: 48.3911076, lng: 22.1306702 },
    { lat: 48.390934, lng: 22.130868 },
  ],
  Lastovce: [
    { lat: 48.5634308, lng: 21.6021828 },
    { lat: 48.5629003, lng: 21.6009626 },
    { lat: 48.5625362, lng: 21.5998927 },
    { lat: 48.5612492, lng: 21.5956849 },
    { lat: 48.5611916, lng: 21.5953199 },
    { lat: 48.5607763, lng: 21.5939915 },
    { lat: 48.5606242, lng: 21.5936634 },
    { lat: 48.5602608, lng: 21.5932223 },
    { lat: 48.5603755, lng: 21.5928985 },
    { lat: 48.5605119, lng: 21.5921897 },
    { lat: 48.5607782, lng: 21.5916974 },
    { lat: 48.5610918, lng: 21.5912864 },
    { lat: 48.5614722, lng: 21.5909282 },
    { lat: 48.5617523, lng: 21.5907604 },
    { lat: 48.5621723, lng: 21.590972 },
    { lat: 48.562468, lng: 21.590724 },
    { lat: 48.5624709, lng: 21.5904315 },
    { lat: 48.5615947, lng: 21.5903204 },
    { lat: 48.5613079, lng: 21.5901256 },
    { lat: 48.5612818, lng: 21.5900782 },
    { lat: 48.5602669, lng: 21.5913993 },
    { lat: 48.5599122, lng: 21.5913143 },
    { lat: 48.5597796, lng: 21.5915192 },
    { lat: 48.5596006, lng: 21.5919967 },
    { lat: 48.5591907, lng: 21.592464 },
    { lat: 48.5502871, lng: 21.6004882 },
    { lat: 48.5505801, lng: 21.6011078 },
    { lat: 48.5500191, lng: 21.601527 },
    { lat: 48.5501025, lng: 21.6010952 },
    { lat: 48.5482199, lng: 21.6018913 },
    { lat: 48.5478417, lng: 21.6009967 },
    { lat: 48.5474113, lng: 21.6011799 },
    { lat: 48.5472095, lng: 21.6017705 },
    { lat: 48.5475768, lng: 21.6028704 },
    { lat: 48.5470796, lng: 21.6020337 },
    { lat: 48.5463076, lng: 21.6021471 },
    { lat: 48.5462599, lng: 21.6017328 },
    { lat: 48.5457263, lng: 21.6020871 },
    { lat: 48.5455379, lng: 21.6021455 },
    { lat: 48.5454564, lng: 21.6022505 },
    { lat: 48.545318, lng: 21.6022085 },
    { lat: 48.545148, lng: 21.6030506 },
    { lat: 48.5452111, lng: 21.6033332 },
    { lat: 48.545121, lng: 21.6034497 },
    { lat: 48.5451187, lng: 21.6039725 },
    { lat: 48.5447836, lng: 21.6036618 },
    { lat: 48.5445932, lng: 21.6036153 },
    { lat: 48.5443129, lng: 21.6037254 },
    { lat: 48.5441622, lng: 21.603691 },
    { lat: 48.5440708, lng: 21.6037593 },
    { lat: 48.5437307, lng: 21.6043547 },
    { lat: 48.5435194, lng: 21.6044026 },
    { lat: 48.5434911, lng: 21.6044803 },
    { lat: 48.5430375, lng: 21.6045169 },
    { lat: 48.5426288, lng: 21.604343 },
    { lat: 48.5425371, lng: 21.6041578 },
    { lat: 48.5425286, lng: 21.604056 },
    { lat: 48.5425927, lng: 21.6039707 },
    { lat: 48.5424934, lng: 21.6037671 },
    { lat: 48.5423397, lng: 21.6039061 },
    { lat: 48.5422057, lng: 21.6039349 },
    { lat: 48.5421678, lng: 21.6040317 },
    { lat: 48.5420506, lng: 21.604064 },
    { lat: 48.5420013, lng: 21.6040309 },
    { lat: 48.5420025, lng: 21.6039559 },
    { lat: 48.5417473, lng: 21.6040023 },
    { lat: 48.5414602, lng: 21.6038778 },
    { lat: 48.5414725, lng: 21.6036359 },
    { lat: 48.5414309, lng: 21.6034301 },
    { lat: 48.5413474, lng: 21.6033442 },
    { lat: 48.5413678, lng: 21.6031895 },
    { lat: 48.5411162, lng: 21.6027609 },
    { lat: 48.5407522, lng: 21.6028202 },
    { lat: 48.5404497, lng: 21.6025078 },
    { lat: 48.540223, lng: 21.6022087 },
    { lat: 48.5401859, lng: 21.6020882 },
    { lat: 48.5400322, lng: 21.6019389 },
    { lat: 48.5398095, lng: 21.6018668 },
    { lat: 48.5396112, lng: 21.6016993 },
    { lat: 48.5394587, lng: 21.6017928 },
    { lat: 48.5394181, lng: 21.6019516 },
    { lat: 48.5393434, lng: 21.602021 },
    { lat: 48.5390987, lng: 21.6020816 },
    { lat: 48.5389428, lng: 21.6022985 },
    { lat: 48.5388299, lng: 21.6022826 },
    { lat: 48.5387553, lng: 21.6024333 },
    { lat: 48.5385146, lng: 21.6025576 },
    { lat: 48.5384236, lng: 21.6023997 },
    { lat: 48.5384066, lng: 21.6021558 },
    { lat: 48.5382881, lng: 21.6020252 },
    { lat: 48.5382054, lng: 21.6020418 },
    { lat: 48.5380072, lng: 21.6022397 },
    { lat: 48.5379152, lng: 21.6024913 },
    { lat: 48.5377172, lng: 21.6025685 },
    { lat: 48.5376161, lng: 21.602419 },
    { lat: 48.5375773, lng: 21.6022383 },
    { lat: 48.5376569, lng: 21.6019048 },
    { lat: 48.5375268, lng: 21.6015953 },
    { lat: 48.5372445, lng: 21.6017638 },
    { lat: 48.5371658, lng: 21.6019662 },
    { lat: 48.5371076, lng: 21.602381 },
    { lat: 48.5370507, lng: 21.6024354 },
    { lat: 48.536962, lng: 21.6023579 },
    { lat: 48.536864, lng: 21.6019985 },
    { lat: 48.5366551, lng: 21.6019326 },
    { lat: 48.536535, lng: 21.6020236 },
    { lat: 48.5363574, lng: 21.6018265 },
    { lat: 48.5362884, lng: 21.6015674 },
    { lat: 48.535897, lng: 21.601577 },
    { lat: 48.5359966, lng: 21.6012366 },
    { lat: 48.5359254, lng: 21.6011439 },
    { lat: 48.5355395, lng: 21.6013068 },
    { lat: 48.5354952, lng: 21.6014176 },
    { lat: 48.5355368, lng: 21.6020941 },
    { lat: 48.5353421, lng: 21.6022549 },
    { lat: 48.5353033, lng: 21.6025954 },
    { lat: 48.5351298, lng: 21.6027896 },
    { lat: 48.5349649, lng: 21.6026456 },
    { lat: 48.5345489, lng: 21.6026578 },
    { lat: 48.5343552, lng: 21.6027319 },
    { lat: 48.534263, lng: 21.6024935 },
    { lat: 48.5342017, lng: 21.6024782 },
    { lat: 48.534118, lng: 21.6026199 },
    { lat: 48.5340926, lng: 21.6027177 },
    { lat: 48.5341479, lng: 21.6030476 },
    { lat: 48.5346028, lng: 21.6031763 },
    { lat: 48.5347092, lng: 21.6033215 },
    { lat: 48.5347543, lng: 21.6035263 },
    { lat: 48.5345927, lng: 21.6040205 },
    { lat: 48.5342074, lng: 21.6039619 },
    { lat: 48.5340454, lng: 21.6040467 },
    { lat: 48.5338706, lng: 21.6040323 },
    { lat: 48.5338363, lng: 21.6040976 },
    { lat: 48.5338432, lng: 21.6043777 },
    { lat: 48.5341174, lng: 21.6049744 },
    { lat: 48.5340244, lng: 21.6050641 },
    { lat: 48.5337532, lng: 21.6050625 },
    { lat: 48.5335976, lng: 21.6053756 },
    { lat: 48.5334638, lng: 21.6054554 },
    { lat: 48.5334068, lng: 21.6055933 },
    { lat: 48.5334324, lng: 21.6058224 },
    { lat: 48.5335638, lng: 21.6060203 },
    { lat: 48.5335727, lng: 21.6062534 },
    { lat: 48.5333144, lng: 21.6064345 },
    { lat: 48.5332734, lng: 21.6066142 },
    { lat: 48.5331117, lng: 21.6064651 },
    { lat: 48.5329802, lng: 21.6064731 },
    { lat: 48.532711, lng: 21.6060964 },
    { lat: 48.5325501, lng: 21.6060612 },
    { lat: 48.5325628, lng: 21.6061949 },
    { lat: 48.5321211, lng: 21.6066131 },
    { lat: 48.5318193, lng: 21.6068066 },
    { lat: 48.5316418, lng: 21.6068178 },
    { lat: 48.531501, lng: 21.6065715 },
    { lat: 48.531466, lng: 21.6063657 },
    { lat: 48.5310035, lng: 21.6061586 },
    { lat: 48.5297604, lng: 21.6061331 },
    { lat: 48.5297373, lng: 21.606192 },
    { lat: 48.5298074, lng: 21.6056367 },
    { lat: 48.5297548, lng: 21.6055285 },
    { lat: 48.5296102, lng: 21.6055515 },
    { lat: 48.5294536, lng: 21.6057436 },
    { lat: 48.5292918, lng: 21.6057904 },
    { lat: 48.5288309, lng: 21.6053653 },
    { lat: 48.5286724, lng: 21.605327 },
    { lat: 48.528535, lng: 21.6054068 },
    { lat: 48.5284331, lng: 21.605339 },
    { lat: 48.5283152, lng: 21.6048666 },
    { lat: 48.5282075, lng: 21.6047584 },
    { lat: 48.5280753, lng: 21.6048601 },
    { lat: 48.5279542, lng: 21.6050748 },
    { lat: 48.5279093, lng: 21.6053574 },
    { lat: 48.5277811, lng: 21.6053609 },
    { lat: 48.5276862, lng: 21.6052295 },
    { lat: 48.5275778, lng: 21.6052169 },
    { lat: 48.5275088, lng: 21.6050835 },
    { lat: 48.5274671, lng: 21.6047145 },
    { lat: 48.5275283, lng: 21.6044064 },
    { lat: 48.5274843, lng: 21.6042416 },
    { lat: 48.5272865, lng: 21.6040934 },
    { lat: 48.5271847, lng: 21.6041194 },
    { lat: 48.5269618, lng: 21.60383 },
    { lat: 48.5268006, lng: 21.6039418 },
    { lat: 48.526497, lng: 21.603709 },
    { lat: 48.5264432, lng: 21.6035896 },
    { lat: 48.5264726, lng: 21.6030734 },
    { lat: 48.5263401, lng: 21.6030079 },
    { lat: 48.5262243, lng: 21.6030916 },
    { lat: 48.5260832, lng: 21.6030704 },
    { lat: 48.5259736, lng: 21.6028523 },
    { lat: 48.5259759, lng: 21.6025394 },
    { lat: 48.5256791, lng: 21.6023801 },
    { lat: 48.5255483, lng: 21.602376 },
    { lat: 48.5254435, lng: 21.6022531 },
    { lat: 48.5252777, lng: 21.6021933 },
    { lat: 48.5250981, lng: 21.6023737 },
    { lat: 48.5249837, lng: 21.6023032 },
    { lat: 48.5249649, lng: 21.6021546 },
    { lat: 48.5250223, lng: 21.6018396 },
    { lat: 48.5249219, lng: 21.6017484 },
    { lat: 48.5246357, lng: 21.602053 },
    { lat: 48.524431, lng: 21.6018996 },
    { lat: 48.5243823, lng: 21.6020464 },
    { lat: 48.5243786, lng: 21.6023161 },
    { lat: 48.524266, lng: 21.6023871 },
    { lat: 48.5241282, lng: 21.6022485 },
    { lat: 48.524042, lng: 21.6019843 },
    { lat: 48.5239474, lng: 21.6019072 },
    { lat: 48.5237185, lng: 21.6018852 },
    { lat: 48.5233297, lng: 21.6016231 },
    { lat: 48.5231152, lng: 21.6016743 },
    { lat: 48.5230524, lng: 21.6018828 },
    { lat: 48.5232535, lng: 21.6022352 },
    { lat: 48.5233323, lng: 21.6026846 },
    { lat: 48.5233682, lng: 21.6027203 },
    { lat: 48.5235782, lng: 21.6026253 },
    { lat: 48.5236591, lng: 21.6027007 },
    { lat: 48.5236949, lng: 21.6028511 },
    { lat: 48.5235685, lng: 21.6031615 },
    { lat: 48.5233649, lng: 21.6032007 },
    { lat: 48.5233029, lng: 21.6033158 },
    { lat: 48.523334, lng: 21.6035867 },
    { lat: 48.52344, lng: 21.6038366 },
    { lat: 48.523241, lng: 21.6040033 },
    { lat: 48.5232362, lng: 21.6040682 },
    { lat: 48.5234476, lng: 21.6044275 },
    { lat: 48.5234677, lng: 21.6046651 },
    { lat: 48.5235272, lng: 21.6047749 },
    { lat: 48.5237362, lng: 21.6047276 },
    { lat: 48.5236422, lng: 21.6049859 },
    { lat: 48.5234638, lng: 21.6050892 },
    { lat: 48.5231105, lng: 21.6050843 },
    { lat: 48.5230905, lng: 21.60517 },
    { lat: 48.5231685, lng: 21.6053468 },
    { lat: 48.5231532, lng: 21.6055256 },
    { lat: 48.5227709, lng: 21.6054544 },
    { lat: 48.522595, lng: 21.605604 },
    { lat: 48.5224246, lng: 21.6058693 },
    { lat: 48.5224036, lng: 21.6060381 },
    { lat: 48.5222059, lng: 21.6062097 },
    { lat: 48.5217703, lng: 21.606292 },
    { lat: 48.5217015, lng: 21.6064852 },
    { lat: 48.5215458, lng: 21.6065515 },
    { lat: 48.5214736, lng: 21.6066678 },
    { lat: 48.5213929, lng: 21.6065981 },
    { lat: 48.5213755, lng: 21.6064125 },
    { lat: 48.5213157, lng: 21.6062896 },
    { lat: 48.5210698, lng: 21.6061916 },
    { lat: 48.5210092, lng: 21.6063165 },
    { lat: 48.5209024, lng: 21.606391 },
    { lat: 48.5208328, lng: 21.6064409 },
    { lat: 48.5209989, lng: 21.6067079 },
    { lat: 48.5209896, lng: 21.6069392 },
    { lat: 48.5211495, lng: 21.6070238 },
    { lat: 48.5211666, lng: 21.6072297 },
    { lat: 48.5211209, lng: 21.6072932 },
    { lat: 48.5207994, lng: 21.6072973 },
    { lat: 48.5206636, lng: 21.6072292 },
    { lat: 48.5206336, lng: 21.6072429 },
    { lat: 48.5206657, lng: 21.6077917 },
    { lat: 48.5212407, lng: 21.609317 },
    { lat: 48.5217687, lng: 21.6105009 },
    { lat: 48.5221836, lng: 21.6103629 },
    { lat: 48.5226009, lng: 21.6118883 },
    { lat: 48.5232612, lng: 21.6149824 },
    { lat: 48.5232346, lng: 21.6150437 },
    { lat: 48.5239133, lng: 21.6180296 },
    { lat: 48.5238062, lng: 21.6180634 },
    { lat: 48.524528, lng: 21.622448 },
    { lat: 48.5247069, lng: 21.6224048 },
    { lat: 48.5253977, lng: 21.6228929 },
    { lat: 48.5264142, lng: 21.6234262 },
    { lat: 48.5270869, lng: 21.6291698 },
    { lat: 48.5272504, lng: 21.6316177 },
    { lat: 48.5279879, lng: 21.6313427 },
    { lat: 48.5281031, lng: 21.6320103 },
    { lat: 48.5282455, lng: 21.631953 },
    { lat: 48.5290898, lng: 21.6377668 },
    { lat: 48.5296943, lng: 21.6433508 },
    { lat: 48.5297671, lng: 21.6435049 },
    { lat: 48.52981, lng: 21.6437915 },
    { lat: 48.5301336, lng: 21.643729 },
    { lat: 48.5308202, lng: 21.6439064 },
    { lat: 48.5309076, lng: 21.6437685 },
    { lat: 48.5311604, lng: 21.6430622 },
    { lat: 48.5316288, lng: 21.6414498 },
    { lat: 48.5326252, lng: 21.6391927 },
    { lat: 48.5328742, lng: 21.6388433 },
    { lat: 48.5338151, lng: 21.6386447 },
    { lat: 48.5339811, lng: 21.6384635 },
    { lat: 48.534182, lng: 21.6383636 },
    { lat: 48.5357847, lng: 21.6376941 },
    { lat: 48.5363815, lng: 21.6375097 },
    { lat: 48.5364154, lng: 21.6384857 },
    { lat: 48.5363409, lng: 21.6391237 },
    { lat: 48.5363514, lng: 21.6404392 },
    { lat: 48.5367001, lng: 21.6401084 },
    { lat: 48.5372091, lng: 21.6453744 },
    { lat: 48.5378445, lng: 21.6455277 },
    { lat: 48.5380325, lng: 21.6454425 },
    { lat: 48.5382684, lng: 21.6455175 },
    { lat: 48.5382844, lng: 21.6477263 },
    { lat: 48.5381405, lng: 21.6498077 },
    { lat: 48.5387662, lng: 21.6505046 },
    { lat: 48.5396196, lng: 21.6512522 },
    { lat: 48.540645, lng: 21.6519216 },
    { lat: 48.5401279, lng: 21.6542611 },
    { lat: 48.5398009, lng: 21.6562387 },
    { lat: 48.5404479, lng: 21.6566517 },
    { lat: 48.5401095, lng: 21.6592377 },
    { lat: 48.5404114, lng: 21.6592928 },
    { lat: 48.540349, lng: 21.6622174 },
    { lat: 48.5404035, lng: 21.6638345 },
    { lat: 48.5412776, lng: 21.6637051 },
    { lat: 48.5416901, lng: 21.6658155 },
    { lat: 48.5423177, lng: 21.668226 },
    { lat: 48.5420302, lng: 21.6683263 },
    { lat: 48.5435045, lng: 21.6729795 },
    { lat: 48.543794, lng: 21.6729537 },
    { lat: 48.5441003, lng: 21.672798 },
    { lat: 48.5447203, lng: 21.6717727 },
    { lat: 48.5450163, lng: 21.6714048 },
    { lat: 48.5450697, lng: 21.670893 },
    { lat: 48.5450351, lng: 21.6703721 },
    { lat: 48.545145, lng: 21.6702353 },
    { lat: 48.5454203, lng: 21.6699729 },
    { lat: 48.5462582, lng: 21.6696288 },
    { lat: 48.5471388, lng: 21.6689541 },
    { lat: 48.5475711, lng: 21.6687631 },
    { lat: 48.5482251, lng: 21.6686077 },
    { lat: 48.5487041, lng: 21.6709767 },
    { lat: 48.5480213, lng: 21.6718437 },
    { lat: 48.5484379, lng: 21.6731286 },
    { lat: 48.548947, lng: 21.6733104 },
    { lat: 48.5490543, lng: 21.6731655 },
    { lat: 48.5491173, lng: 21.6729152 },
    { lat: 48.5493153, lng: 21.6727509 },
    { lat: 48.5495234, lng: 21.672912 },
    { lat: 48.5497646, lng: 21.6728849 },
    { lat: 48.5502454, lng: 21.6729811 },
    { lat: 48.5503646, lng: 21.6729002 },
    { lat: 48.5505079, lng: 21.6725031 },
    { lat: 48.5511245, lng: 21.6720489 },
    { lat: 48.5512849, lng: 21.6715443 },
    { lat: 48.551409, lng: 21.6713902 },
    { lat: 48.5517425, lng: 21.6716966 },
    { lat: 48.5518735, lng: 21.6717094 },
    { lat: 48.5519246, lng: 21.67128 },
    { lat: 48.5521651, lng: 21.6710627 },
    { lat: 48.5523866, lng: 21.6700275 },
    { lat: 48.552512, lng: 21.6699227 },
    { lat: 48.5527966, lng: 21.6701636 },
    { lat: 48.5529278, lng: 21.6701941 },
    { lat: 48.5531014, lng: 21.6701198 },
    { lat: 48.5531761, lng: 21.6696879 },
    { lat: 48.5529628, lng: 21.6694589 },
    { lat: 48.5529544, lng: 21.6692364 },
    { lat: 48.5530045, lng: 21.6690962 },
    { lat: 48.5531725, lng: 21.6689782 },
    { lat: 48.5533126, lng: 21.6696194 },
    { lat: 48.5535085, lng: 21.6694633 },
    { lat: 48.5537443, lng: 21.6689916 },
    { lat: 48.5539997, lng: 21.669068 },
    { lat: 48.5541729, lng: 21.6689028 },
    { lat: 48.5543588, lng: 21.6689917 },
    { lat: 48.5544974, lng: 21.6688797 },
    { lat: 48.5548265, lng: 21.6691427 },
    { lat: 48.5548796, lng: 21.6694384 },
    { lat: 48.5547396, lng: 21.6696293 },
    { lat: 48.5547245, lng: 21.6698188 },
    { lat: 48.5547761, lng: 21.6699723 },
    { lat: 48.5549336, lng: 21.6700598 },
    { lat: 48.5556884, lng: 21.6694325 },
    { lat: 48.5559389, lng: 21.6694613 },
    { lat: 48.5566184, lng: 21.6685925 },
    { lat: 48.5570099, lng: 21.6690441 },
    { lat: 48.5571333, lng: 21.6691185 },
    { lat: 48.5572699, lng: 21.6691365 },
    { lat: 48.5574119, lng: 21.6691409 },
    { lat: 48.5577107, lng: 21.6689181 },
    { lat: 48.5578436, lng: 21.6686335 },
    { lat: 48.5575838, lng: 21.6670776 },
    { lat: 48.557474, lng: 21.6645126 },
    { lat: 48.5574045, lng: 21.6640662 },
    { lat: 48.5565909, lng: 21.6621386 },
    { lat: 48.5559693, lng: 21.6613878 },
    { lat: 48.5569388, lng: 21.6601436 },
    { lat: 48.557743, lng: 21.6593535 },
    { lat: 48.5575574, lng: 21.6588302 },
    { lat: 48.5569782, lng: 21.6564679 },
    { lat: 48.5563283, lng: 21.6513734 },
    { lat: 48.5560108, lng: 21.6493247 },
    { lat: 48.555729, lng: 21.6470249 },
    { lat: 48.555695, lng: 21.6464538 },
    { lat: 48.5554513, lng: 21.644836 },
    { lat: 48.5554668, lng: 21.6440142 },
    { lat: 48.5553923, lng: 21.6432276 },
    { lat: 48.5551271, lng: 21.642471 },
    { lat: 48.5548256, lng: 21.642149 },
    { lat: 48.5544137, lng: 21.6409666 },
    { lat: 48.5549766, lng: 21.6406865 },
    { lat: 48.5551942, lng: 21.6404207 },
    { lat: 48.5552008, lng: 21.6404128 },
    { lat: 48.5549856, lng: 21.6402793 },
    { lat: 48.5548926, lng: 21.6399704 },
    { lat: 48.5549002, lng: 21.6391875 },
    { lat: 48.5547912, lng: 21.6391503 },
    { lat: 48.5547404, lng: 21.6388669 },
    { lat: 48.5545137, lng: 21.6389227 },
    { lat: 48.5544734, lng: 21.6384028 },
    { lat: 48.5543864, lng: 21.6382325 },
    { lat: 48.5543081, lng: 21.638248 },
    { lat: 48.5542172, lng: 21.6379703 },
    { lat: 48.5542886, lng: 21.6376618 },
    { lat: 48.5541159, lng: 21.6366289 },
    { lat: 48.5539499, lng: 21.6366045 },
    { lat: 48.5538226, lng: 21.6359494 },
    { lat: 48.5537065, lng: 21.6358171 },
    { lat: 48.5537215, lng: 21.6356358 },
    { lat: 48.5535946, lng: 21.6353962 },
    { lat: 48.5534838, lng: 21.6349713 },
    { lat: 48.5534607, lng: 21.6344283 },
    { lat: 48.5534021, lng: 21.6342949 },
    { lat: 48.5535645, lng: 21.6340737 },
    { lat: 48.553535, lng: 21.6339339 },
    { lat: 48.5536135, lng: 21.6336452 },
    { lat: 48.5535574, lng: 21.6334839 },
    { lat: 48.5535734, lng: 21.6332119 },
    { lat: 48.5534524, lng: 21.6330636 },
    { lat: 48.5534693, lng: 21.6327834 },
    { lat: 48.5533189, lng: 21.6325545 },
    { lat: 48.553361, lng: 21.6320465 },
    { lat: 48.5534863, lng: 21.631491 },
    { lat: 48.5535728, lng: 21.6301903 },
    { lat: 48.5536806, lng: 21.6297765 },
    { lat: 48.5536259, lng: 21.6294258 },
    { lat: 48.553473, lng: 21.6290227 },
    { lat: 48.5530273, lng: 21.6269593 },
    { lat: 48.5530087, lng: 21.6250757 },
    { lat: 48.5534559, lng: 21.6250468 },
    { lat: 48.5544124, lng: 21.6251739 },
    { lat: 48.5562991, lng: 21.6258182 },
    { lat: 48.5569303, lng: 21.6260959 },
    { lat: 48.5582342, lng: 21.6268608 },
    { lat: 48.5595124, lng: 21.6278739 },
    { lat: 48.560132, lng: 21.6287455 },
    { lat: 48.5606665, lng: 21.6292805 },
    { lat: 48.5612506, lng: 21.6294007 },
    { lat: 48.5616442, lng: 21.629356 },
    { lat: 48.561646, lng: 21.6292575 },
    { lat: 48.5617353, lng: 21.6292309 },
    { lat: 48.5614447, lng: 21.6267898 },
    { lat: 48.5615307, lng: 21.6245809 },
    { lat: 48.5618767, lng: 21.6219756 },
    { lat: 48.5620879, lng: 21.6209467 },
    { lat: 48.5624829, lng: 21.6193758 },
    { lat: 48.5628327, lng: 21.6174323 },
    { lat: 48.5630324, lng: 21.6160016 },
    { lat: 48.563207, lng: 21.6134198 },
    { lat: 48.563038, lng: 21.6080946 },
    { lat: 48.5629126, lng: 21.6073932 },
    { lat: 48.5616193, lng: 21.6037174 },
    { lat: 48.5622501, lng: 21.6031807 },
    { lat: 48.5634308, lng: 21.6021828 },
  ],
  Bara: [
    { lat: 48.4121719, lng: 21.7172836 },
    { lat: 48.4118952, lng: 21.7180347 },
    { lat: 48.4117353, lng: 21.7188169 },
    { lat: 48.4116901, lng: 21.718934 },
    { lat: 48.4117057, lng: 21.7190578 },
    { lat: 48.4115702, lng: 21.7195739 },
    { lat: 48.4109822, lng: 21.7212998 },
    { lat: 48.4108932, lng: 21.7217936 },
    { lat: 48.4105207, lng: 21.7227431 },
    { lat: 48.4105501, lng: 21.7227927 },
    { lat: 48.4105172, lng: 21.7229537 },
    { lat: 48.4103352, lng: 21.7231284 },
    { lat: 48.4102715, lng: 21.7232693 },
    { lat: 48.4101696, lng: 21.7237469 },
    { lat: 48.4100251, lng: 21.7241403 },
    { lat: 48.4100498, lng: 21.7241799 },
    { lat: 48.4099669, lng: 21.7244909 },
    { lat: 48.4097317, lng: 21.7247833 },
    { lat: 48.4096511, lng: 21.7251246 },
    { lat: 48.409527, lng: 21.7253206 },
    { lat: 48.4093759, lng: 21.7257254 },
    { lat: 48.4092932, lng: 21.7261189 },
    { lat: 48.4091976, lng: 21.7262125 },
    { lat: 48.4092743, lng: 21.7269204 },
    { lat: 48.4092104, lng: 21.7273064 },
    { lat: 48.4090427, lng: 21.7278868 },
    { lat: 48.409038, lng: 21.7284671 },
    { lat: 48.4089601, lng: 21.7288713 },
    { lat: 48.4089669, lng: 21.729168 },
    { lat: 48.4090085, lng: 21.7293741 },
    { lat: 48.4095277, lng: 21.7304765 },
    { lat: 48.4098234, lng: 21.7315088 },
    { lat: 48.4100008, lng: 21.7318713 },
    { lat: 48.4100269, lng: 21.7320865 },
    { lat: 48.4103036, lng: 21.7327999 },
    { lat: 48.4105147, lng: 21.7331554 },
    { lat: 48.4115212, lng: 21.7335985 },
    { lat: 48.4116858, lng: 21.7335665 },
    { lat: 48.4118864, lng: 21.7333266 },
    { lat: 48.4123861, lng: 21.7330876 },
    { lat: 48.4127094, lng: 21.7332178 },
    { lat: 48.4128077, lng: 21.7334045 },
    { lat: 48.412877, lng: 21.733639 },
    { lat: 48.4128632, lng: 21.7340467 },
    { lat: 48.4126981, lng: 21.7352684 },
    { lat: 48.4128094, lng: 21.7359359 },
    { lat: 48.4129003, lng: 21.7360547 },
    { lat: 48.4130798, lng: 21.7366604 },
    { lat: 48.4133971, lng: 21.7371586 },
    { lat: 48.4135347, lng: 21.7375501 },
    { lat: 48.4141721, lng: 21.7384489 },
    { lat: 48.4146409, lng: 21.7403616 },
    { lat: 48.4156708, lng: 21.7426374 },
    { lat: 48.416369, lng: 21.744756 },
    { lat: 48.4166341, lng: 21.7458326 },
    { lat: 48.4173092, lng: 21.7468362 },
    { lat: 48.4176046, lng: 21.7469834 },
    { lat: 48.4180092, lng: 21.7470131 },
    { lat: 48.418408, lng: 21.7473226 },
    { lat: 48.4186185, lng: 21.7473659 },
    { lat: 48.4190572, lng: 21.7470744 },
    { lat: 48.4192553, lng: 21.7470308 },
    { lat: 48.419551, lng: 21.7470689 },
    { lat: 48.4197847, lng: 21.7471756 },
    { lat: 48.420079, lng: 21.7474503 },
    { lat: 48.4204365, lng: 21.7475668 },
    { lat: 48.4206224, lng: 21.7475375 },
    { lat: 48.4209798, lng: 21.7477281 },
    { lat: 48.4215145, lng: 21.7477453 },
    { lat: 48.4218929, lng: 21.7480093 },
    { lat: 48.4221558, lng: 21.7479452 },
    { lat: 48.4223587, lng: 21.7477948 },
    { lat: 48.4226579, lng: 21.7478723 },
    { lat: 48.4228988, lng: 21.7477554 },
    { lat: 48.4229791, lng: 21.747646 },
    { lat: 48.4233673, lng: 21.747941 },
    { lat: 48.4237558, lng: 21.7484131 },
    { lat: 48.4241144, lng: 21.7486064 },
    { lat: 48.4247266, lng: 21.7485332 },
    { lat: 48.4252257, lng: 21.7483022 },
    { lat: 48.4260742, lng: 21.7488109 },
    { lat: 48.4273921, lng: 21.7490394 },
    { lat: 48.4274324, lng: 21.7490313 },
    { lat: 48.4278727, lng: 21.7475291 },
    { lat: 48.4278862, lng: 21.7467737 },
    { lat: 48.4279369, lng: 21.7465751 },
    { lat: 48.4281016, lng: 21.7462098 },
    { lat: 48.4288259, lng: 21.7453118 },
    { lat: 48.4293294, lng: 21.743111 },
    { lat: 48.4295976, lng: 21.7410357 },
    { lat: 48.4291032, lng: 21.7403855 },
    { lat: 48.4288975, lng: 21.7395566 },
    { lat: 48.4286428, lng: 21.7390045 },
    { lat: 48.4284284, lng: 21.738185 },
    { lat: 48.4284667, lng: 21.7363043 },
    { lat: 48.4279201, lng: 21.7292455 },
    { lat: 48.4279877, lng: 21.7292088 },
    { lat: 48.4277345, lng: 21.7267082 },
    { lat: 48.4274416, lng: 21.7236089 },
    { lat: 48.4275698, lng: 21.7235267 },
    { lat: 48.4275661, lng: 21.7233466 },
    { lat: 48.427896, lng: 21.7231051 },
    { lat: 48.428482, lng: 21.7229311 },
    { lat: 48.4290488, lng: 21.7223843 },
    { lat: 48.4290023, lng: 21.7219882 },
    { lat: 48.4308351, lng: 21.7216092 },
    { lat: 48.4319807, lng: 21.7210419 },
    { lat: 48.4330433, lng: 21.7206369 },
    { lat: 48.4336987, lng: 21.7198673 },
    { lat: 48.4335657, lng: 21.719108 },
    { lat: 48.4337507, lng: 21.7178779 },
    { lat: 48.4337382, lng: 21.7170046 },
    { lat: 48.4353498, lng: 21.7168079 },
    { lat: 48.437863, lng: 21.716836 },
    { lat: 48.438263, lng: 21.7165635 },
    { lat: 48.4388844, lng: 21.7159947 },
    { lat: 48.4389816, lng: 21.7159704 },
    { lat: 48.4390051, lng: 21.715756 },
    { lat: 48.4391622, lng: 21.7137492 },
    { lat: 48.4390422, lng: 21.7126021 },
    { lat: 48.4389517, lng: 21.7108792 },
    { lat: 48.4389343, lng: 21.7107006 },
    { lat: 48.4388685, lng: 21.710672 },
    { lat: 48.4295842, lng: 21.708828 },
    { lat: 48.4284891, lng: 21.7080297 },
    { lat: 48.4278427, lng: 21.7077643 },
    { lat: 48.4267434, lng: 21.7074296 },
    { lat: 48.4257375, lng: 21.7073084 },
    { lat: 48.4254899, lng: 21.7075914 },
    { lat: 48.424853, lng: 21.7078413 },
    { lat: 48.4245798, lng: 21.7082597 },
    { lat: 48.4242325, lng: 21.708236 },
    { lat: 48.423976, lng: 21.7085653 },
    { lat: 48.4236177, lng: 21.7082121 },
    { lat: 48.4227108, lng: 21.7076793 },
    { lat: 48.4218578, lng: 21.7077592 },
    { lat: 48.4209859, lng: 21.7074737 },
    { lat: 48.420592, lng: 21.7074231 },
    { lat: 48.4203256, lng: 21.7074337 },
    { lat: 48.4202208, lng: 21.7075517 },
    { lat: 48.4201237, lng: 21.7073345 },
    { lat: 48.4201422, lng: 21.7074347 },
    { lat: 48.4197658, lng: 21.7072835 },
    { lat: 48.4181108, lng: 21.707081 },
    { lat: 48.4163574, lng: 21.7071092 },
    { lat: 48.4156543, lng: 21.7072416 },
    { lat: 48.4156115, lng: 21.7069474 },
    { lat: 48.4154923, lng: 21.7066214 },
    { lat: 48.4132069, lng: 21.7075679 },
    { lat: 48.4130732, lng: 21.7071164 },
    { lat: 48.4120784, lng: 21.7074891 },
    { lat: 48.4117355, lng: 21.7076574 },
    { lat: 48.4116203, lng: 21.7078536 },
    { lat: 48.4106316, lng: 21.7085715 },
    { lat: 48.4107393, lng: 21.7087239 },
    { lat: 48.4107784, lng: 21.7089152 },
    { lat: 48.4107226, lng: 21.7091867 },
    { lat: 48.4107451, lng: 21.7093901 },
    { lat: 48.4106706, lng: 21.7093815 },
    { lat: 48.4106329, lng: 21.7092801 },
    { lat: 48.4105572, lng: 21.7093492 },
    { lat: 48.410536, lng: 21.7095219 },
    { lat: 48.4104436, lng: 21.7094794 },
    { lat: 48.4103154, lng: 21.7096048 },
    { lat: 48.4102368, lng: 21.7097297 },
    { lat: 48.4101983, lng: 21.7099728 },
    { lat: 48.410339, lng: 21.7100988 },
    { lat: 48.4102979, lng: 21.7102001 },
    { lat: 48.4101694, lng: 21.7102778 },
    { lat: 48.4102106, lng: 21.710425 },
    { lat: 48.4103358, lng: 21.7104 },
    { lat: 48.4103899, lng: 21.7105688 },
    { lat: 48.4103171, lng: 21.7106232 },
    { lat: 48.4102791, lng: 21.7110448 },
    { lat: 48.410307, lng: 21.7111175 },
    { lat: 48.4104515, lng: 21.7111363 },
    { lat: 48.410462, lng: 21.7113117 },
    { lat: 48.4102636, lng: 21.7113454 },
    { lat: 48.4102203, lng: 21.7115719 },
    { lat: 48.4103273, lng: 21.7115788 },
    { lat: 48.4104583, lng: 21.7117142 },
    { lat: 48.410386, lng: 21.7121188 },
    { lat: 48.4106845, lng: 21.7124218 },
    { lat: 48.4106832, lng: 21.7125065 },
    { lat: 48.4105726, lng: 21.7126177 },
    { lat: 48.4106035, lng: 21.7126686 },
    { lat: 48.4107504, lng: 21.7126529 },
    { lat: 48.4107938, lng: 21.7127596 },
    { lat: 48.4108142, lng: 21.712848 },
    { lat: 48.4106809, lng: 21.7130361 },
    { lat: 48.4107074, lng: 21.7131874 },
    { lat: 48.4107749, lng: 21.7132715 },
    { lat: 48.4109104, lng: 21.7132058 },
    { lat: 48.4109575, lng: 21.7133273 },
    { lat: 48.4109163, lng: 21.7134583 },
    { lat: 48.4109404, lng: 21.7137288 },
    { lat: 48.4112457, lng: 21.7140774 },
    { lat: 48.4113587, lng: 21.7140974 },
    { lat: 48.4114377, lng: 21.7143076 },
    { lat: 48.4115081, lng: 21.7143358 },
    { lat: 48.4115415, lng: 21.7144457 },
    { lat: 48.4113488, lng: 21.7145595 },
    { lat: 48.4114224, lng: 21.7147427 },
    { lat: 48.4115234, lng: 21.7146915 },
    { lat: 48.4115108, lng: 21.7148453 },
    { lat: 48.4115809, lng: 21.7149891 },
    { lat: 48.4117542, lng: 21.7150319 },
    { lat: 48.4117967, lng: 21.7151795 },
    { lat: 48.4117886, lng: 21.7154215 },
    { lat: 48.4116567, lng: 21.7158709 },
    { lat: 48.4119288, lng: 21.7157391 },
    { lat: 48.4120225, lng: 21.7157511 },
    { lat: 48.4121973, lng: 21.7160942 },
    { lat: 48.4122277, lng: 21.7163056 },
    { lat: 48.4123102, lng: 21.7163204 },
    { lat: 48.4122421, lng: 21.7165055 },
    { lat: 48.4121911, lng: 21.7168677 },
    { lat: 48.4122372, lng: 21.7169492 },
    { lat: 48.4121719, lng: 21.7172836 },
  ],
  VeľkýKamenec: [
    { lat: 48.3864172, lng: 21.8019142 },
    { lat: 48.3859265, lng: 21.7999113 },
    { lat: 48.3832946, lng: 21.8018144 },
    { lat: 48.3819882, lng: 21.8026625 },
    { lat: 48.3815436, lng: 21.8030853 },
    { lat: 48.3809437, lng: 21.8024804 },
    { lat: 48.3804029, lng: 21.8023663 },
    { lat: 48.3806283, lng: 21.8010533 },
    { lat: 48.3813271, lng: 21.7985407 },
    { lat: 48.3815645, lng: 21.7985035 },
    { lat: 48.38204, lng: 21.795591 },
    { lat: 48.3821786, lng: 21.794956 },
    { lat: 48.3822999, lng: 21.7938466 },
    { lat: 48.3808285, lng: 21.7939806 },
    { lat: 48.3783354, lng: 21.7943244 },
    { lat: 48.37714, lng: 21.7941362 },
    { lat: 48.376975, lng: 21.7940225 },
    { lat: 48.3768056, lng: 21.7941688 },
    { lat: 48.3760073, lng: 21.7939828 },
    { lat: 48.3750005, lng: 21.7939886 },
    { lat: 48.3742952, lng: 21.7940741 },
    { lat: 48.3729063, lng: 21.7938875 },
    { lat: 48.3715427, lng: 21.7935859 },
    { lat: 48.3712043, lng: 21.7936807 },
    { lat: 48.3705506, lng: 21.794025 },
    { lat: 48.3693556, lng: 21.7950002 },
    { lat: 48.3690147, lng: 21.795046 },
    { lat: 48.3686066, lng: 21.7948431 },
    { lat: 48.3675763, lng: 21.7946012 },
    { lat: 48.3668317, lng: 21.7941381 },
    { lat: 48.3658257, lng: 21.7929416 },
    { lat: 48.364906, lng: 21.7916971 },
    { lat: 48.3646386, lng: 21.7912404 },
    { lat: 48.3642558, lng: 21.7908071 },
    { lat: 48.3635062, lng: 21.7900717 },
    { lat: 48.3624506, lng: 21.789387 },
    { lat: 48.3611973, lng: 21.7896464 },
    { lat: 48.3608484, lng: 21.7895476 },
    { lat: 48.3604811, lng: 21.7898709 },
    { lat: 48.3589391, lng: 21.7898946 },
    { lat: 48.3587441, lng: 21.7898425 },
    { lat: 48.3585871, lng: 21.7896888 },
    { lat: 48.3581281, lng: 21.7894342 },
    { lat: 48.357199, lng: 21.7891447 },
    { lat: 48.3568868, lng: 21.7887872 },
    { lat: 48.356654, lng: 21.7883193 },
    { lat: 48.3559632, lng: 21.7882837 },
    { lat: 48.3552565, lng: 21.7881479 },
    { lat: 48.3550375, lng: 21.7875257 },
    { lat: 48.3541002, lng: 21.7870128 },
    { lat: 48.3534995, lng: 21.7864727 },
    { lat: 48.3534794, lng: 21.7865153 },
    { lat: 48.353284, lng: 21.7873006 },
    { lat: 48.3532614, lng: 21.7879323 },
    { lat: 48.3535427, lng: 21.7894192 },
    { lat: 48.3536176, lng: 21.7905842 },
    { lat: 48.3538392, lng: 21.7920709 },
    { lat: 48.3539915, lng: 21.7923926 },
    { lat: 48.3542887, lng: 21.7926455 },
    { lat: 48.3539847, lng: 21.792769 },
    { lat: 48.3538802, lng: 21.7929425 },
    { lat: 48.3519221, lng: 21.7940736 },
    { lat: 48.3519447, lng: 21.7945098 },
    { lat: 48.3521516, lng: 21.795783 },
    { lat: 48.3523216, lng: 21.7971938 },
    { lat: 48.3526263, lng: 21.7988025 },
    { lat: 48.3528904, lng: 21.7994723 },
    { lat: 48.3532867, lng: 21.8002935 },
    { lat: 48.3535858, lng: 21.8007925 },
    { lat: 48.353839, lng: 21.8010492 },
    { lat: 48.3539834, lng: 21.8052048 },
    { lat: 48.3542959, lng: 21.8075457 },
    { lat: 48.3432532, lng: 21.813166 },
    { lat: 48.338478, lng: 21.8108467 },
    { lat: 48.3383294, lng: 21.8107258 },
    { lat: 48.33828, lng: 21.810815 },
    { lat: 48.33819, lng: 21.810992 },
    { lat: 48.338114, lng: 21.811168 },
    { lat: 48.337936, lng: 21.811679 },
    { lat: 48.338005, lng: 21.811854 },
    { lat: 48.338111, lng: 21.812129 },
    { lat: 48.338167, lng: 21.812416 },
    { lat: 48.3382, lng: 21.812618 },
    { lat: 48.338169, lng: 21.812823 },
    { lat: 48.338108, lng: 21.813016 },
    { lat: 48.337992, lng: 21.813197 },
    { lat: 48.337869, lng: 21.813254 },
    { lat: 48.337632, lng: 21.813386 },
    { lat: 48.337529, lng: 21.813449 },
    { lat: 48.337442, lng: 21.813516 },
    { lat: 48.337378, lng: 21.813614 },
    { lat: 48.337352, lng: 21.81376 },
    { lat: 48.337372, lng: 21.814012 },
    { lat: 48.337432, lng: 21.814298 },
    { lat: 48.337562, lng: 21.814839 },
    { lat: 48.337613, lng: 21.815048 },
    { lat: 48.337649, lng: 21.815164 },
    { lat: 48.337682, lng: 21.81529 },
    { lat: 48.337709, lng: 21.815437 },
    { lat: 48.337713, lng: 21.815525 },
    { lat: 48.337642, lng: 21.815674 },
    { lat: 48.337473, lng: 21.815772 },
    { lat: 48.337327, lng: 21.815854 },
    { lat: 48.337254, lng: 21.815874 },
    { lat: 48.337146, lng: 21.815899 },
    { lat: 48.337064, lng: 21.815915 },
    { lat: 48.337011, lng: 21.815946 },
    { lat: 48.336972, lng: 21.815981 },
    { lat: 48.33691, lng: 21.816065 },
    { lat: 48.336865, lng: 21.816183 },
    { lat: 48.336852, lng: 21.816354 },
    { lat: 48.336887, lng: 21.816577 },
    { lat: 48.336909, lng: 21.816791 },
    { lat: 48.336946, lng: 21.81724 },
    { lat: 48.337025, lng: 21.817694 },
    { lat: 48.337055, lng: 21.817829 },
    { lat: 48.337124, lng: 21.818155 },
    { lat: 48.337127, lng: 21.818312 },
    { lat: 48.337092, lng: 21.818486 },
    { lat: 48.336955, lng: 21.818645 },
    { lat: 48.336863, lng: 21.818707 },
    { lat: 48.336776, lng: 21.818747 },
    { lat: 48.336696, lng: 21.818778 },
    { lat: 48.336606, lng: 21.818789 },
    { lat: 48.33645, lng: 21.818778 },
    { lat: 48.336261, lng: 21.818716 },
    { lat: 48.33591, lng: 21.81859 },
    { lat: 48.335734, lng: 21.818524 },
    { lat: 48.335556, lng: 21.818423 },
    { lat: 48.335488, lng: 21.818379 },
    { lat: 48.335415, lng: 21.81832 },
    { lat: 48.33525, lng: 21.818174 },
    { lat: 48.33519, lng: 21.818115 },
    { lat: 48.335057, lng: 21.81796 },
    { lat: 48.334957, lng: 21.817843 },
    { lat: 48.334835, lng: 21.817716 },
    { lat: 48.334679, lng: 21.817578 },
    { lat: 48.334394, lng: 21.817413 },
    { lat: 48.334172, lng: 21.817307 },
    { lat: 48.334041, lng: 21.817244 },
    { lat: 48.333965, lng: 21.817202 },
    { lat: 48.333744, lng: 21.817065 },
    { lat: 48.333519, lng: 21.816908 },
    { lat: 48.333386, lng: 21.816805 },
    { lat: 48.333295, lng: 21.816764 },
    { lat: 48.333044, lng: 21.816897 },
    { lat: 48.332996, lng: 21.81693 },
    { lat: 48.3328987, lng: 21.8169478 },
    { lat: 48.332872, lng: 21.817082 },
    { lat: 48.332821, lng: 21.817235 },
    { lat: 48.332787, lng: 21.817414 },
    { lat: 48.332809, lng: 21.817602 },
    { lat: 48.332982, lng: 21.818149 },
    { lat: 48.33309, lng: 21.818484 },
    { lat: 48.333209, lng: 21.818742 },
    { lat: 48.333401, lng: 21.819105 },
    { lat: 48.333584, lng: 21.819441 },
    { lat: 48.333652, lng: 21.819577 },
    { lat: 48.333698, lng: 21.819681 },
    { lat: 48.333718, lng: 21.81982 },
    { lat: 48.333679, lng: 21.819969 },
    { lat: 48.333524, lng: 21.820233 },
    { lat: 48.333405, lng: 21.820407 },
    { lat: 48.333341, lng: 21.820492 },
    { lat: 48.333266, lng: 21.820602 },
    { lat: 48.333185, lng: 21.820741 },
    { lat: 48.333132, lng: 21.820845 },
    { lat: 48.332952, lng: 21.821247 },
    { lat: 48.332812, lng: 21.821552 },
    { lat: 48.332694, lng: 21.82176 },
    { lat: 48.332649, lng: 21.821987 },
    { lat: 48.332582, lng: 21.822332 },
    { lat: 48.332609, lng: 21.82258 },
    { lat: 48.33264, lng: 21.822724 },
    { lat: 48.332768, lng: 21.822955 },
    { lat: 48.332947, lng: 21.823224 },
    { lat: 48.333077, lng: 21.82338 },
    { lat: 48.333156, lng: 21.823504 },
    { lat: 48.333241, lng: 21.823598 },
    { lat: 48.333368, lng: 21.823711 },
    { lat: 48.333521, lng: 21.823958 },
    { lat: 48.333653, lng: 21.824207 },
    { lat: 48.33381, lng: 21.824633 },
    { lat: 48.333913, lng: 21.824856 },
    { lat: 48.333953, lng: 21.824931 },
    { lat: 48.334063, lng: 21.825163 },
    { lat: 48.334112, lng: 21.825339 },
    { lat: 48.334114, lng: 21.825568 },
    { lat: 48.334128, lng: 21.826013 },
    { lat: 48.334152, lng: 21.826243 },
    { lat: 48.334151, lng: 21.826503 },
    { lat: 48.334138, lng: 21.827036 },
    { lat: 48.334122, lng: 21.827153 },
    { lat: 48.334005, lng: 21.827697 },
    { lat: 48.333999, lng: 21.82792 },
    { lat: 48.333991, lng: 21.828258 },
    { lat: 48.33401, lng: 21.828605 },
    { lat: 48.334057, lng: 21.82949 },
    { lat: 48.334021, lng: 21.830659 },
    { lat: 48.333999, lng: 21.831475 },
    { lat: 48.333986, lng: 21.832552 },
    { lat: 48.333995, lng: 21.832958 },
    { lat: 48.333992, lng: 21.833337 },
    { lat: 48.333992, lng: 21.833493 },
    { lat: 48.333997, lng: 21.833721 },
    { lat: 48.334044, lng: 21.834041 },
    { lat: 48.334139, lng: 21.834382 },
    { lat: 48.334641, lng: 21.835203 },
    { lat: 48.334853, lng: 21.83533 },
    { lat: 48.33515, lng: 21.83538 },
    { lat: 48.335479, lng: 21.835385 },
    { lat: 48.335618, lng: 21.835392 },
    { lat: 48.33584, lng: 21.835376 },
    { lat: 48.335929, lng: 21.835369 },
    { lat: 48.336154, lng: 21.835342 },
    { lat: 48.336396, lng: 21.83531 },
    { lat: 48.336677, lng: 21.83522 },
    { lat: 48.337132, lng: 21.835009 },
    { lat: 48.33729, lng: 21.834831 },
    { lat: 48.337529, lng: 21.83454 },
    { lat: 48.337632, lng: 21.834385 },
    { lat: 48.337731, lng: 21.834135 },
    { lat: 48.337761, lng: 21.834065 },
    { lat: 48.337872, lng: 21.833879 },
    { lat: 48.337919, lng: 21.833802 },
    { lat: 48.337962, lng: 21.833706 },
    { lat: 48.338137, lng: 21.83322 },
    { lat: 48.338378, lng: 21.833257 },
    { lat: 48.338645, lng: 21.833289 },
    { lat: 48.338819, lng: 21.833247 },
    { lat: 48.339205, lng: 21.833213 },
    { lat: 48.339586, lng: 21.8333 },
    { lat: 48.339838, lng: 21.833402 },
    { lat: 48.340223, lng: 21.833567 },
    { lat: 48.340313, lng: 21.833635 },
    { lat: 48.340472, lng: 21.833776 },
    { lat: 48.340696, lng: 21.834072 },
    { lat: 48.340902, lng: 21.834486 },
    { lat: 48.341005, lng: 21.834649 },
    { lat: 48.341376, lng: 21.835758 },
    { lat: 48.341491, lng: 21.836073 },
    { lat: 48.341582, lng: 21.83629 },
    { lat: 48.341847, lng: 21.836884 },
    { lat: 48.342263, lng: 21.837325 },
    { lat: 48.342463, lng: 21.837406 },
    { lat: 48.342638, lng: 21.837443 },
    { lat: 48.342948, lng: 21.837436 },
    { lat: 48.343251, lng: 21.837365 },
    { lat: 48.343492, lng: 21.837264 },
    { lat: 48.343642, lng: 21.837172 },
    { lat: 48.343791, lng: 21.837035 },
    { lat: 48.344035, lng: 21.836789 },
    { lat: 48.344496, lng: 21.836208 },
    { lat: 48.344621, lng: 21.836068 },
    { lat: 48.344775, lng: 21.835887 },
    { lat: 48.34487, lng: 21.835751 },
    { lat: 48.345056, lng: 21.835565 },
    { lat: 48.345284, lng: 21.835396 },
    { lat: 48.345499, lng: 21.835315 },
    { lat: 48.34564, lng: 21.835223 },
    { lat: 48.345737, lng: 21.835202 },
    { lat: 48.34613, lng: 21.835053 },
    { lat: 48.346209, lng: 21.835079 },
    { lat: 48.346414, lng: 21.835231 },
    { lat: 48.346606, lng: 21.835422 },
    { lat: 48.34677, lng: 21.835621 },
    { lat: 48.347045, lng: 21.835958 },
    { lat: 48.3472, lng: 21.836119 },
    { lat: 48.347627, lng: 21.836544 },
    { lat: 48.347726, lng: 21.836614 },
    { lat: 48.347834, lng: 21.836737 },
    { lat: 48.347896, lng: 21.836783 },
    { lat: 48.348121, lng: 21.83696 },
    { lat: 48.348419, lng: 21.837215 },
    { lat: 48.348667, lng: 21.837376 },
    { lat: 48.348763, lng: 21.837433 },
    { lat: 48.349055, lng: 21.837544 },
    { lat: 48.349652, lng: 21.837608 },
    { lat: 48.349864, lng: 21.837657 },
    { lat: 48.350117, lng: 21.837658 },
    { lat: 48.350478, lng: 21.83761 },
    { lat: 48.350752, lng: 21.837577 },
    { lat: 48.351114, lng: 21.837577 },
    { lat: 48.351206, lng: 21.837593 },
    { lat: 48.351432, lng: 21.837651 },
    { lat: 48.351589, lng: 21.837617 },
    { lat: 48.351684, lng: 21.837573 },
    { lat: 48.351897, lng: 21.837612 },
    { lat: 48.351925, lng: 21.837633 },
    { lat: 48.35198, lng: 21.837607 },
    { lat: 48.352034, lng: 21.83761 },
    { lat: 48.352207, lng: 21.837424 },
    { lat: 48.352353, lng: 21.837251 },
    { lat: 48.352568, lng: 21.837095 },
    { lat: 48.352769, lng: 21.836916 },
    { lat: 48.353006, lng: 21.836704 },
    { lat: 48.353294, lng: 21.836564 },
    { lat: 48.353426, lng: 21.836514 },
    { lat: 48.353625, lng: 21.836447 },
    { lat: 48.35396, lng: 21.836298 },
    { lat: 48.354296, lng: 21.836196 },
    { lat: 48.354465, lng: 21.836151 },
    { lat: 48.354707, lng: 21.83617 },
    { lat: 48.355103, lng: 21.836131 },
    { lat: 48.355282, lng: 21.836117 },
    { lat: 48.355683, lng: 21.836096 },
    { lat: 48.356063, lng: 21.836061 },
    { lat: 48.356297, lng: 21.836066 },
    { lat: 48.356531, lng: 21.836137 },
    { lat: 48.356601, lng: 21.836133 },
    { lat: 48.3566206, lng: 21.8353789 },
    { lat: 48.3566318, lng: 21.8350765 },
    { lat: 48.3568626, lng: 21.8334863 },
    { lat: 48.3569843, lng: 21.8317726 },
    { lat: 48.3568666, lng: 21.8304059 },
    { lat: 48.3568469, lng: 21.829696 },
    { lat: 48.3567133, lng: 21.8291043 },
    { lat: 48.3561705, lng: 21.8276243 },
    { lat: 48.3558945, lng: 21.8264459 },
    { lat: 48.3559426, lng: 21.8251455 },
    { lat: 48.3560465, lng: 21.824388 },
    { lat: 48.3566141, lng: 21.8240214 },
    { lat: 48.3572212, lng: 21.8237429 },
    { lat: 48.3580452, lng: 21.8236791 },
    { lat: 48.3583423, lng: 21.8237249 },
    { lat: 48.3604674, lng: 21.824614 },
    { lat: 48.3614361, lng: 21.8247943 },
    { lat: 48.3618575, lng: 21.8249859 },
    { lat: 48.3623618, lng: 21.8255092 },
    { lat: 48.3626991, lng: 21.8257575 },
    { lat: 48.3631005, lng: 21.8257898 },
    { lat: 48.363502, lng: 21.8256533 },
    { lat: 48.3642333, lng: 21.8250926 },
    { lat: 48.3649761, lng: 21.8247997 },
    { lat: 48.3654903, lng: 21.8237278 },
    { lat: 48.367069, lng: 21.8239549 },
    { lat: 48.3678343, lng: 21.8238744 },
    { lat: 48.3678517, lng: 21.8243659 },
    { lat: 48.3679682, lng: 21.8247384 },
    { lat: 48.3684519, lng: 21.8254649 },
    { lat: 48.3688523, lng: 21.825458 },
    { lat: 48.3690052, lng: 21.8254518 },
    { lat: 48.3696065, lng: 21.8251694 },
    { lat: 48.3699846, lng: 21.8247409 },
    { lat: 48.3702347, lng: 21.8246671 },
    { lat: 48.3708882, lng: 21.8246565 },
    { lat: 48.371552, lng: 21.8244022 },
    { lat: 48.3729492, lng: 21.8240067 },
    { lat: 48.3734629, lng: 21.823734 },
    { lat: 48.3739244, lng: 21.823658 },
    { lat: 48.3740762, lng: 21.8236943 },
    { lat: 48.3743317, lng: 21.8245193 },
    { lat: 48.3752251, lng: 21.8238725 },
    { lat: 48.3755179, lng: 21.8237562 },
    { lat: 48.377123, lng: 21.8233968 },
    { lat: 48.3771639, lng: 21.8240586 },
    { lat: 48.3767046, lng: 21.8242559 },
    { lat: 48.3766638, lng: 21.8249746 },
    { lat: 48.3765728, lng: 21.8254185 },
    { lat: 48.3762276, lng: 21.8261979 },
    { lat: 48.3761814, lng: 21.8265772 },
    { lat: 48.3772653, lng: 21.8266832 },
    { lat: 48.3781601, lng: 21.8263052 },
    { lat: 48.3785761, lng: 21.8262089 },
    { lat: 48.3788755, lng: 21.8262199 },
    { lat: 48.3796929, lng: 21.8265787 },
    { lat: 48.3801637, lng: 21.8265602 },
    { lat: 48.3820629, lng: 21.8263471 },
    { lat: 48.3827059, lng: 21.826151 },
    { lat: 48.3830711, lng: 21.8261794 },
    { lat: 48.3841402, lng: 21.8265726 },
    { lat: 48.3841093, lng: 21.8270225 },
    { lat: 48.3855537, lng: 21.8268036 },
    { lat: 48.3866839, lng: 21.8263937 },
    { lat: 48.3880373, lng: 21.8269943 },
    { lat: 48.3883639, lng: 21.8272203 },
    { lat: 48.3896933, lng: 21.8284564 },
    { lat: 48.3902167, lng: 21.8288054 },
    { lat: 48.3900689, lng: 21.8282764 },
    { lat: 48.3898818, lng: 21.8276113 },
    { lat: 48.3896212, lng: 21.827159 },
    { lat: 48.3894238, lng: 21.826874 },
    { lat: 48.3887868, lng: 21.826273 },
    { lat: 48.3882818, lng: 21.8259296 },
    { lat: 48.387167, lng: 21.8254676 },
    { lat: 48.3867363, lng: 21.8254756 },
    { lat: 48.3862417, lng: 21.8252775 },
    { lat: 48.3861192, lng: 21.8250214 },
    { lat: 48.3857261, lng: 21.8237555 },
    { lat: 48.3855568, lng: 21.8226668 },
    { lat: 48.3854209, lng: 21.8210863 },
    { lat: 48.3853592, lng: 21.8209618 },
    { lat: 48.3851338, lng: 21.8208636 },
    { lat: 48.3851173, lng: 21.8207913 },
    { lat: 48.3852677, lng: 21.8198299 },
    { lat: 48.3855134, lng: 21.8195397 },
    { lat: 48.3856568, lng: 21.8192643 },
    { lat: 48.3857269, lng: 21.8189799 },
    { lat: 48.3857981, lng: 21.8181711 },
    { lat: 48.3861164, lng: 21.8168866 },
    { lat: 48.3861326, lng: 21.816424 },
    { lat: 48.3863666, lng: 21.8155278 },
    { lat: 48.3865947, lng: 21.8151748 },
    { lat: 48.3873364, lng: 21.8144903 },
    { lat: 48.3870919, lng: 21.8125041 },
    { lat: 48.3866599, lng: 21.8108409 },
    { lat: 48.38641, lng: 21.8068181 },
    { lat: 48.3864276, lng: 21.8054327 },
    { lat: 48.386238, lng: 21.8040798 },
    { lat: 48.3861828, lng: 21.8039999 },
    { lat: 48.3862009, lng: 21.8033617 },
    { lat: 48.3864172, lng: 21.8019142 },
  ],
  Svätuše: [
    { lat: 48.447855, lng: 21.934946 },
    { lat: 48.4424169, lng: 21.9165962 },
    { lat: 48.4406055, lng: 21.908829 },
    { lat: 48.435636, lng: 21.9113514 },
    { lat: 48.4360947, lng: 21.8995641 },
    { lat: 48.4343515, lng: 21.8996828 },
    { lat: 48.4346534, lng: 21.8958591 },
    { lat: 48.4347351, lng: 21.8948319 },
    { lat: 48.4347438, lng: 21.8947269 },
    { lat: 48.4348286, lng: 21.8937275 },
    { lat: 48.4345441, lng: 21.8931225 },
    { lat: 48.4341148, lng: 21.8927413 },
    { lat: 48.4343431, lng: 21.8901358 },
    { lat: 48.4336464, lng: 21.8898189 },
    { lat: 48.4321368, lng: 21.8895207 },
    { lat: 48.43057, lng: 21.8896717 },
    { lat: 48.4296126, lng: 21.8900224 },
    { lat: 48.4291035, lng: 21.8900922 },
    { lat: 48.4290588, lng: 21.8902343 },
    { lat: 48.4287813, lng: 21.8901133 },
    { lat: 48.4266553, lng: 21.8903061 },
    { lat: 48.4244021, lng: 21.8915969 },
    { lat: 48.4231882, lng: 21.8913272 },
    { lat: 48.4229856, lng: 21.8913697 },
    { lat: 48.422238, lng: 21.892276 },
    { lat: 48.4212512, lng: 21.8936339 },
    { lat: 48.4211444, lng: 21.8936582 },
    { lat: 48.419107, lng: 21.8941212 },
    { lat: 48.4196072, lng: 21.8961325 },
    { lat: 48.4198785, lng: 21.8978144 },
    { lat: 48.4203746, lng: 21.9003785 },
    { lat: 48.4203237, lng: 21.901071 },
    { lat: 48.420405, lng: 21.9019245 },
    { lat: 48.4204414, lng: 21.9021569 },
    { lat: 48.4206335, lng: 21.9027214 },
    { lat: 48.4207831, lng: 21.903033 },
    { lat: 48.4205901, lng: 21.9032218 },
    { lat: 48.4189397, lng: 21.904245 },
    { lat: 48.4188821, lng: 21.9041013 },
    { lat: 48.4175846, lng: 21.9051037 },
    { lat: 48.4167977, lng: 21.9053493 },
    { lat: 48.4161693, lng: 21.9057686 },
    { lat: 48.4153777, lng: 21.90583 },
    { lat: 48.4140416, lng: 21.9065098 },
    { lat: 48.4131644, lng: 21.9059082 },
    { lat: 48.4122119, lng: 21.9057882 },
    { lat: 48.4102406, lng: 21.9050759 },
    { lat: 48.4097401, lng: 21.9052644 },
    { lat: 48.4096867, lng: 21.9052848 },
    { lat: 48.4097932, lng: 21.9057082 },
    { lat: 48.4105953, lng: 21.9068152 },
    { lat: 48.4111505, lng: 21.907363 },
    { lat: 48.4115917, lng: 21.907905 },
    { lat: 48.4118953, lng: 21.9084319 },
    { lat: 48.4125348, lng: 21.9090673 },
    { lat: 48.4129302, lng: 21.9092157 },
    { lat: 48.4131147, lng: 21.9091283 },
    { lat: 48.4133808, lng: 21.9088864 },
    { lat: 48.4136299, lng: 21.9088632 },
    { lat: 48.4137676, lng: 21.9091903 },
    { lat: 48.4135094, lng: 21.910144 },
    { lat: 48.4134389, lng: 21.9106902 },
    { lat: 48.4135032, lng: 21.911228 },
    { lat: 48.4133399, lng: 21.911925 },
    { lat: 48.4133044, lng: 21.9128319 },
    { lat: 48.4132233, lng: 21.9133234 },
    { lat: 48.4130897, lng: 21.9137274 },
    { lat: 48.4129332, lng: 21.9140788 },
    { lat: 48.4127591, lng: 21.9143101 },
    { lat: 48.4124413, lng: 21.9143814 },
    { lat: 48.4120614, lng: 21.9140816 },
    { lat: 48.4117178, lng: 21.9136203 },
    { lat: 48.411518, lng: 21.9135992 },
    { lat: 48.4114116, lng: 21.9136616 },
    { lat: 48.4111859, lng: 21.9140761 },
    { lat: 48.4110584, lng: 21.9146825 },
    { lat: 48.4111374, lng: 21.9160672 },
    { lat: 48.4108342, lng: 21.9163565 },
    { lat: 48.4108061, lng: 21.9171538 },
    { lat: 48.4106287, lng: 21.9192157 },
    { lat: 48.4104987, lng: 21.9199479 },
    { lat: 48.4106777, lng: 21.9215493 },
    { lat: 48.4109432, lng: 21.9232381 },
    { lat: 48.4110008, lng: 21.924987 },
    { lat: 48.411343, lng: 21.9282862 },
    { lat: 48.4081601, lng: 21.9302376 },
    { lat: 48.407565, lng: 21.9308256 },
    { lat: 48.4073756, lng: 21.9315697 },
    { lat: 48.4073509, lng: 21.9316554 },
    { lat: 48.4073925, lng: 21.9316962 },
    { lat: 48.4075065, lng: 21.9318976 },
    { lat: 48.4076584, lng: 21.9325171 },
    { lat: 48.4079772, lng: 21.9333799 },
    { lat: 48.4082103, lng: 21.933435 },
    { lat: 48.4089108, lng: 21.9338672 },
    { lat: 48.4093973, lng: 21.9343335 },
    { lat: 48.4099895, lng: 21.9339475 },
    { lat: 48.4102062, lng: 21.9346919 },
    { lat: 48.4105464, lng: 21.934532 },
    { lat: 48.4109264, lng: 21.9347459 },
    { lat: 48.4117527, lng: 21.935569 },
    { lat: 48.41206, lng: 21.936286 },
    { lat: 48.4124834, lng: 21.9363972 },
    { lat: 48.4132628, lng: 21.9362959 },
    { lat: 48.4137576, lng: 21.9361531 },
    { lat: 48.4140908, lng: 21.9359787 },
    { lat: 48.4143646, lng: 21.9367614 },
    { lat: 48.4145523, lng: 21.9366583 },
    { lat: 48.4145706, lng: 21.9367203 },
    { lat: 48.4147313, lng: 21.936708 },
    { lat: 48.4156796, lng: 21.9394784 },
    { lat: 48.4158209, lng: 21.9399966 },
    { lat: 48.4178212, lng: 21.9400878 },
    { lat: 48.4185107, lng: 21.9398964 },
    { lat: 48.4196561, lng: 21.9397734 },
    { lat: 48.4198562, lng: 21.9396756 },
    { lat: 48.4219938, lng: 21.9391442 },
    { lat: 48.4223043, lng: 21.9395006 },
    { lat: 48.4239076, lng: 21.9390136 },
    { lat: 48.424435, lng: 21.9386833 },
    { lat: 48.4268628, lng: 21.9374764 },
    { lat: 48.4279167, lng: 21.9368427 },
    { lat: 48.4280862, lng: 21.9366092 },
    { lat: 48.4285104, lng: 21.9362497 },
    { lat: 48.4291497, lng: 21.9358623 },
    { lat: 48.4293253, lng: 21.9355994 },
    { lat: 48.430201, lng: 21.9346286 },
    { lat: 48.4322861, lng: 21.9335277 },
    { lat: 48.4331001, lng: 21.9333312 },
    { lat: 48.4335683, lng: 21.9332939 },
    { lat: 48.4337637, lng: 21.9340771 },
    { lat: 48.4341314, lng: 21.934031 },
    { lat: 48.4349172, lng: 21.9337426 },
    { lat: 48.4349494, lng: 21.9341191 },
    { lat: 48.4352518, lng: 21.9340725 },
    { lat: 48.4359314, lng: 21.9342454 },
    { lat: 48.4361744, lng: 21.9349656 },
    { lat: 48.4363137, lng: 21.935228 },
    { lat: 48.436477, lng: 21.935138 },
    { lat: 48.4366511, lng: 21.9355383 },
    { lat: 48.4382141, lng: 21.9348791 },
    { lat: 48.4384578, lng: 21.9351674 },
    { lat: 48.4386659, lng: 21.9351747 },
    { lat: 48.4391056, lng: 21.9354778 },
    { lat: 48.4393472, lng: 21.9358684 },
    { lat: 48.4398313, lng: 21.936913 },
    { lat: 48.4402114, lng: 21.9369056 },
    { lat: 48.4404626, lng: 21.9367807 },
    { lat: 48.4407519, lng: 21.9368355 },
    { lat: 48.4407715, lng: 21.9372521 },
    { lat: 48.4412066, lng: 21.9373305 },
    { lat: 48.4414569, lng: 21.9375339 },
    { lat: 48.4418617, lng: 21.9376108 },
    { lat: 48.4424086, lng: 21.9373941 },
    { lat: 48.4427787, lng: 21.9373447 },
    { lat: 48.4433246, lng: 21.9370033 },
    { lat: 48.443888, lng: 21.9364114 },
    { lat: 48.4442027, lng: 21.936176 },
    { lat: 48.4452909, lng: 21.935832 },
    { lat: 48.4463191, lng: 21.9353856 },
    { lat: 48.4478625, lng: 21.9350186 },
    { lat: 48.447855, lng: 21.934946 },
  ],
  Boťany: [
    { lat: 48.4764033, lng: 22.0868057 },
    { lat: 48.4759993, lng: 22.0873221 },
    { lat: 48.475492, lng: 22.0868552 },
    { lat: 48.4744617, lng: 22.0848734 },
    { lat: 48.4743289, lng: 22.0847288 },
    { lat: 48.4724056, lng: 22.0834762 },
    { lat: 48.4722473, lng: 22.083344 },
    { lat: 48.472111, lng: 22.083105 },
    { lat: 48.4695137, lng: 22.0815019 },
    { lat: 48.4678555, lng: 22.080986 },
    { lat: 48.466123, lng: 22.0802321 },
    { lat: 48.4650196, lng: 22.0796203 },
    { lat: 48.4647411, lng: 22.0795486 },
    { lat: 48.464725, lng: 22.0800077 },
    { lat: 48.464626, lng: 22.0802481 },
    { lat: 48.4643518, lng: 22.0805412 },
    { lat: 48.4639432, lng: 22.0806178 },
    { lat: 48.4637344, lng: 22.0805019 },
    { lat: 48.4635419, lng: 22.0802578 },
    { lat: 48.4632719, lng: 22.0795929 },
    { lat: 48.4629335, lng: 22.0789455 },
    { lat: 48.4626292, lng: 22.0785023 },
    { lat: 48.4623968, lng: 22.0782401 },
    { lat: 48.4618172, lng: 22.0778979 },
    { lat: 48.4616859, lng: 22.0779054 },
    { lat: 48.4614688, lng: 22.078056 },
    { lat: 48.461235, lng: 22.0787659 },
    { lat: 48.4611167, lng: 22.079468 },
    { lat: 48.4603261, lng: 22.0792432 },
    { lat: 48.4569706, lng: 22.077178 },
    { lat: 48.4549228, lng: 22.0750887 },
    { lat: 48.4531814, lng: 22.0741513 },
    { lat: 48.4528474, lng: 22.0740642 },
    { lat: 48.4526857, lng: 22.073888 },
    { lat: 48.4522657, lng: 22.0737757 },
    { lat: 48.4518637, lng: 22.0735735 },
    { lat: 48.4513291, lng: 22.0731928 },
    { lat: 48.4503408, lng: 22.0723207 },
    { lat: 48.4499137, lng: 22.0720111 },
    { lat: 48.4493947, lng: 22.0714726 },
    { lat: 48.4491654, lng: 22.0711488 },
    { lat: 48.4480874, lng: 22.0690783 },
    { lat: 48.4476146, lng: 22.0692981 },
    { lat: 48.4470914, lng: 22.0676537 },
    { lat: 48.4461673, lng: 22.0650112 },
    { lat: 48.4461059, lng: 22.064791 },
    { lat: 48.4461561, lng: 22.0647351 },
    { lat: 48.4452617, lng: 22.0623388 },
    { lat: 48.4452562, lng: 22.0621123 },
    { lat: 48.4451367, lng: 22.0616341 },
    { lat: 48.4451093, lng: 22.0613366 },
    { lat: 48.4451748, lng: 22.0605506 },
    { lat: 48.4450863, lng: 22.0597759 },
    { lat: 48.4450848, lng: 22.0582634 },
    { lat: 48.4449766, lng: 22.0575113 },
    { lat: 48.4447241, lng: 22.0568349 },
    { lat: 48.4445238, lng: 22.0564659 },
    { lat: 48.4436582, lng: 22.0551369 },
    { lat: 48.4432835, lng: 22.0553674 },
    { lat: 48.442953, lng: 22.0556997 },
    { lat: 48.4424516, lng: 22.0566986 },
    { lat: 48.4423969, lng: 22.0570389 },
    { lat: 48.44244, lng: 22.0592988 },
    { lat: 48.4423514, lng: 22.0609912 },
    { lat: 48.4422367, lng: 22.0619644 },
    { lat: 48.4419547, lng: 22.0629232 },
    { lat: 48.44157, lng: 22.064 },
    { lat: 48.4412094, lng: 22.0647214 },
    { lat: 48.4408449, lng: 22.0651395 },
    { lat: 48.4404897, lng: 22.0656693 },
    { lat: 48.440045, lng: 22.0660293 },
    { lat: 48.4394646, lng: 22.066215 },
    { lat: 48.4390715, lng: 22.0662505 },
    { lat: 48.4384771, lng: 22.0661747 },
    { lat: 48.4364398, lng: 22.0668765 },
    { lat: 48.4362398, lng: 22.0670319 },
    { lat: 48.4356886, lng: 22.067646 },
    { lat: 48.4352653, lng: 22.068189 },
    { lat: 48.4351743, lng: 22.0684338 },
    { lat: 48.4351383, lng: 22.0689976 },
    { lat: 48.4351816, lng: 22.0696237 },
    { lat: 48.4352904, lng: 22.0700291 },
    { lat: 48.4354664, lng: 22.0704295 },
    { lat: 48.4362006, lng: 22.071298 },
    { lat: 48.4370948, lng: 22.071528 },
    { lat: 48.4369966, lng: 22.072977 },
    { lat: 48.4367553, lng: 22.074732 },
    { lat: 48.4343779, lng: 22.0750456 },
    { lat: 48.4341116, lng: 22.0751089 },
    { lat: 48.4338614, lng: 22.0753225 },
    { lat: 48.4337101, lng: 22.0749673 },
    { lat: 48.4334625, lng: 22.0739792 },
    { lat: 48.433442, lng: 22.0734514 },
    { lat: 48.4332676, lng: 22.0727056 },
    { lat: 48.4320321, lng: 22.0705344 },
    { lat: 48.431636, lng: 22.0694989 },
    { lat: 48.4313545, lng: 22.068269 },
    { lat: 48.4312806, lng: 22.0677633 },
    { lat: 48.431009, lng: 22.0667146 },
    { lat: 48.430517, lng: 22.0660952 },
    { lat: 48.43033, lng: 22.0653264 },
    { lat: 48.4301863, lng: 22.0637551 },
    { lat: 48.429793, lng: 22.0636797 },
    { lat: 48.4288375, lng: 22.0638057 },
    { lat: 48.4276981, lng: 22.06348 },
    { lat: 48.4275433, lng: 22.0633648 },
    { lat: 48.4274982, lng: 22.0633131 },
    { lat: 48.426944, lng: 22.0637066 },
    { lat: 48.4263028, lng: 22.065392 },
    { lat: 48.4266344, lng: 22.0661724 },
    { lat: 48.4274358, lng: 22.0689926 },
    { lat: 48.4282123, lng: 22.0684426 },
    { lat: 48.4293883, lng: 22.0710932 },
    { lat: 48.4294643, lng: 22.0714401 },
    { lat: 48.4293814, lng: 22.0717383 },
    { lat: 48.4293904, lng: 22.072634 },
    { lat: 48.4295611, lng: 22.0728999 },
    { lat: 48.4292383, lng: 22.073419 },
    { lat: 48.428426, lng: 22.0744927 },
    { lat: 48.4273116, lng: 22.0754027 },
    { lat: 48.4265303, lng: 22.076227 },
    { lat: 48.426353, lng: 22.0767548 },
    { lat: 48.4289881, lng: 22.0895894 },
    { lat: 48.429157, lng: 22.0912458 },
    { lat: 48.4291048, lng: 22.0929378 },
    { lat: 48.4299309, lng: 22.092813 },
    { lat: 48.4312727, lng: 22.0919014 },
    { lat: 48.4318023, lng: 22.0917004 },
    { lat: 48.4335333, lng: 22.0908401 },
    { lat: 48.4340644, lng: 22.0933433 },
    { lat: 48.4325334, lng: 22.0940996 },
    { lat: 48.4321865, lng: 22.0942734 },
    { lat: 48.4328393, lng: 22.0959022 },
    { lat: 48.4331191, lng: 22.0958397 },
    { lat: 48.4331458, lng: 22.0967766 },
    { lat: 48.4330865, lng: 22.0977823 },
    { lat: 48.433079, lng: 22.099263 },
    { lat: 48.4333415, lng: 22.1009868 },
    { lat: 48.4334752, lng: 22.1033948 },
    { lat: 48.4333639, lng: 22.1044975 },
    { lat: 48.4341307, lng: 22.1051915 },
    { lat: 48.4359338, lng: 22.1072715 },
    { lat: 48.4358395, lng: 22.1079893 },
    { lat: 48.436085, lng: 22.1079549 },
    { lat: 48.4367804, lng: 22.108064 },
    { lat: 48.4369648, lng: 22.108147 },
    { lat: 48.4372971, lng: 22.1086886 },
    { lat: 48.4376881, lng: 22.1095236 },
    { lat: 48.4379649, lng: 22.1105712 },
    { lat: 48.4375868, lng: 22.1106237 },
    { lat: 48.4372618, lng: 22.1107643 },
    { lat: 48.4370945, lng: 22.1109192 },
    { lat: 48.4369811, lng: 22.1110797 },
    { lat: 48.4368469, lng: 22.1115336 },
    { lat: 48.4368797, lng: 22.1118723 },
    { lat: 48.4370551, lng: 22.1124938 },
    { lat: 48.437606, lng: 22.1135325 },
    { lat: 48.4379618, lng: 22.1143556 },
    { lat: 48.4381625, lng: 22.1152763 },
    { lat: 48.4384125, lng: 22.1171508 },
    { lat: 48.4385623, lng: 22.117675 },
    { lat: 48.4387411, lng: 22.118059 },
    { lat: 48.4389901, lng: 22.1183111 },
    { lat: 48.4387028, lng: 22.1185868 },
    { lat: 48.438329, lng: 22.1191388 },
    { lat: 48.4380677, lng: 22.1193143 },
    { lat: 48.438296, lng: 22.1206501 },
    { lat: 48.4374763, lng: 22.1217182 },
    { lat: 48.4373097, lng: 22.1214274 },
    { lat: 48.4371335, lng: 22.1213553 },
    { lat: 48.4368199, lng: 22.1219816 },
    { lat: 48.4367673, lng: 22.1227631 },
    { lat: 48.4368797, lng: 22.1234206 },
    { lat: 48.4371686, lng: 22.1245601 },
    { lat: 48.4373437, lng: 22.1249274 },
    { lat: 48.4376064, lng: 22.1252943 },
    { lat: 48.4387145, lng: 22.1265331 },
    { lat: 48.4391954, lng: 22.1269869 },
    { lat: 48.4397951, lng: 22.1273253 },
    { lat: 48.4403356, lng: 22.1278301 },
    { lat: 48.441889, lng: 22.1295534 },
    { lat: 48.4438877, lng: 22.1314492 },
    { lat: 48.4445093, lng: 22.1321525 },
    { lat: 48.444649, lng: 22.1331768 },
    { lat: 48.4452462, lng: 22.1327774 },
    { lat: 48.4453112, lng: 22.1328675 },
    { lat: 48.4457084, lng: 22.1326335 },
    { lat: 48.4457328, lng: 22.1325688 },
    { lat: 48.4458086, lng: 22.1325832 },
    { lat: 48.445936, lng: 22.1323072 },
    { lat: 48.4469018, lng: 22.131715 },
    { lat: 48.4466844, lng: 22.132134 },
    { lat: 48.4467179, lng: 22.1324574 },
    { lat: 48.4468559, lng: 22.1329759 },
    { lat: 48.4472449, lng: 22.1336307 },
    { lat: 48.447744, lng: 22.1340454 },
    { lat: 48.448266, lng: 22.134424 },
    { lat: 48.448514, lng: 22.134217 },
    { lat: 48.449257, lng: 22.133904 },
    { lat: 48.45078, lng: 22.13337 },
    { lat: 48.450998, lng: 22.133222 },
    { lat: 48.452758, lng: 22.132578 },
    { lat: 48.452763, lng: 22.132592 },
    { lat: 48.453279, lng: 22.132422 },
    { lat: 48.454676, lng: 22.131946 },
    { lat: 48.455952, lng: 22.13147 },
    { lat: 48.457203, lng: 22.130996 },
    { lat: 48.45764, lng: 22.130828 },
    { lat: 48.4583653, lng: 22.130492 },
    { lat: 48.458455, lng: 22.13053 },
    { lat: 48.459063, lng: 22.130302 },
    { lat: 48.459577, lng: 22.129978 },
    { lat: 48.461256, lng: 22.12846 },
    { lat: 48.461489, lng: 22.128321 },
    { lat: 48.461665, lng: 22.12815 },
    { lat: 48.462034, lng: 22.127791 },
    { lat: 48.46273, lng: 22.127326 },
    { lat: 48.463078, lng: 22.127093 },
    { lat: 48.4633082, lng: 22.1268442 },
    { lat: 48.463491, lng: 22.126567 },
    { lat: 48.465743, lng: 22.126122 },
    { lat: 48.468166, lng: 22.125255 },
    { lat: 48.469165, lng: 22.124881 },
    { lat: 48.469968, lng: 22.124581 },
    { lat: 48.470777, lng: 22.124196 },
    { lat: 48.47131, lng: 22.123784 },
    { lat: 48.471635, lng: 22.12341 },
    { lat: 48.472065, lng: 22.12313 },
    { lat: 48.472447, lng: 22.122635 },
    { lat: 48.473831, lng: 22.120878 },
    { lat: 48.473959, lng: 22.120708 },
    { lat: 48.474401, lng: 22.120094 },
    { lat: 48.474407, lng: 22.120082 },
    { lat: 48.474813, lng: 22.119358 },
    { lat: 48.475439, lng: 22.118232 },
    { lat: 48.476467, lng: 22.115913 },
    { lat: 48.476723, lng: 22.115425 },
    { lat: 48.476927, lng: 22.114008 },
    { lat: 48.476963, lng: 22.113882 },
    { lat: 48.477058, lng: 22.113557 },
    { lat: 48.477151, lng: 22.113238 },
    { lat: 48.477285, lng: 22.112072 },
    { lat: 48.477306, lng: 22.110522 },
    { lat: 48.477205, lng: 22.108925 },
    { lat: 48.477137, lng: 22.107585 },
    { lat: 48.477083, lng: 22.106961 },
    { lat: 48.477004, lng: 22.106316 },
    { lat: 48.476368, lng: 22.103932 },
    { lat: 48.475966, lng: 22.102832 },
    { lat: 48.47577, lng: 22.101884 },
    { lat: 48.475746, lng: 22.10018 },
    { lat: 48.475668, lng: 22.098821 },
    { lat: 48.475451, lng: 22.097593 },
    { lat: 48.475412, lng: 22.097369 },
    { lat: 48.475383, lng: 22.097203 },
    { lat: 48.475103, lng: 22.095574 },
    { lat: 48.475067, lng: 22.093725 },
    { lat: 48.475268, lng: 22.092681 },
    { lat: 48.476292, lng: 22.090647 },
    { lat: 48.476608, lng: 22.090342 },
    { lat: 48.47709, lng: 22.089875 },
    { lat: 48.477265, lng: 22.089706 },
    { lat: 48.478674, lng: 22.088184 },
    { lat: 48.478853, lng: 22.087885 },
    { lat: 48.47901, lng: 22.087621 },
    { lat: 48.479313, lng: 22.087113 },
    { lat: 48.4784453, lng: 22.0872473 },
    { lat: 48.4780113, lng: 22.0874434 },
    { lat: 48.4775988, lng: 22.0874529 },
    { lat: 48.4771228, lng: 22.0873544 },
    { lat: 48.4765762, lng: 22.0870294 },
    { lat: 48.4764033, lng: 22.0868057 },
  ],
  Sirník: [
    { lat: 48.5077819, lng: 21.7907652 },
    { lat: 48.5079779, lng: 21.7913322 },
    { lat: 48.508539, lng: 21.7932803 },
    { lat: 48.5083654, lng: 21.793356 },
    { lat: 48.5086732, lng: 21.7944436 },
    { lat: 48.5089389, lng: 21.79599 },
    { lat: 48.5066993, lng: 21.797331 },
    { lat: 48.5070478, lng: 21.8005666 },
    { lat: 48.5063516, lng: 21.8007085 },
    { lat: 48.5074051, lng: 21.8076159 },
    { lat: 48.5079564, lng: 21.8089939 },
    { lat: 48.5081696, lng: 21.8096504 },
    { lat: 48.5082529, lng: 21.8096564 },
    { lat: 48.508372, lng: 21.810043 },
    { lat: 48.508386, lng: 21.810093 },
    { lat: 48.50862, lng: 21.80998 },
    { lat: 48.508626, lng: 21.809976 },
    { lat: 48.508641, lng: 21.809968 },
    { lat: 48.508649, lng: 21.809964 },
    { lat: 48.508695, lng: 21.809939 },
    { lat: 48.508699, lng: 21.809938 },
    { lat: 48.508715, lng: 21.809931 },
    { lat: 48.508774, lng: 21.809906 },
    { lat: 48.509063, lng: 21.809772 },
    { lat: 48.509084, lng: 21.809763 },
    { lat: 48.509183, lng: 21.809717 },
    { lat: 48.50939, lng: 21.809617 },
    { lat: 48.509395, lng: 21.809615 },
    { lat: 48.509862, lng: 21.809391 },
    { lat: 48.509867, lng: 21.809388 },
    { lat: 48.510385, lng: 21.809137 },
    { lat: 48.510901, lng: 21.808897 },
    { lat: 48.511516, lng: 21.808633 },
    { lat: 48.511742, lng: 21.808551 },
    { lat: 48.511893, lng: 21.808496 },
    { lat: 48.511938, lng: 21.808483 },
    { lat: 48.51199, lng: 21.808469 },
    { lat: 48.512075, lng: 21.808446 },
    { lat: 48.51218, lng: 21.808437 },
    { lat: 48.512381, lng: 21.808422 },
    { lat: 48.512518, lng: 21.808414 },
    { lat: 48.512859, lng: 21.808392 },
    { lat: 48.51293, lng: 21.808388 },
    { lat: 48.513303, lng: 21.808378 },
    { lat: 48.51345, lng: 21.808376 },
    { lat: 48.513934, lng: 21.808327 },
    { lat: 48.514195, lng: 21.808314 },
    { lat: 48.514401, lng: 21.808313 },
    { lat: 48.51459, lng: 21.808321 },
    { lat: 48.514776, lng: 21.808328 },
    { lat: 48.515042, lng: 21.808326 },
    { lat: 48.515047, lng: 21.80854 },
    { lat: 48.515118, lng: 21.808643 },
    { lat: 48.515191, lng: 21.808747 },
    { lat: 48.515291, lng: 21.808888 },
    { lat: 48.515428, lng: 21.809102 },
    { lat: 48.515507, lng: 21.809228 },
    { lat: 48.515627, lng: 21.809577 },
    { lat: 48.515653, lng: 21.809666 },
    { lat: 48.515675, lng: 21.809745 },
    { lat: 48.515771, lng: 21.810086 },
    { lat: 48.515805, lng: 21.810187 },
    { lat: 48.515875, lng: 21.810396 },
    { lat: 48.51588, lng: 21.810409 },
    { lat: 48.515919, lng: 21.810527 },
    { lat: 48.515951, lng: 21.810628 },
    { lat: 48.515954, lng: 21.810634 },
    { lat: 48.516014, lng: 21.810852 },
    { lat: 48.516077, lng: 21.810976 },
    { lat: 48.516147, lng: 21.811112 },
    { lat: 48.516407, lng: 21.811544 },
    { lat: 48.516539, lng: 21.811664 },
    { lat: 48.51675, lng: 21.811857 },
    { lat: 48.516918, lng: 21.811957 },
    { lat: 48.517182, lng: 21.812048 },
    { lat: 48.517374, lng: 21.812088 },
    { lat: 48.517376, lng: 21.812088 },
    { lat: 48.517648, lng: 21.812108 },
    { lat: 48.517776, lng: 21.812129 },
    { lat: 48.517777, lng: 21.812129 },
    { lat: 48.518232, lng: 21.812254 },
    { lat: 48.518234, lng: 21.812254 },
    { lat: 48.518444, lng: 21.812325 },
    { lat: 48.518549, lng: 21.812421 },
    { lat: 48.518686, lng: 21.81264 },
    { lat: 48.518746, lng: 21.812852 },
    { lat: 48.518779, lng: 21.813096 },
    { lat: 48.518779, lng: 21.813098 },
    { lat: 48.518779, lng: 21.81311 },
    { lat: 48.518756, lng: 21.813292 },
    { lat: 48.518733, lng: 21.813449 },
    { lat: 48.518732, lng: 21.813455 },
    { lat: 48.518685, lng: 21.81357 },
    { lat: 48.518684, lng: 21.81357 },
    { lat: 48.518564, lng: 21.813677 },
    { lat: 48.518328, lng: 21.81385 },
    { lat: 48.518326, lng: 21.813851 },
    { lat: 48.518213, lng: 21.813952 },
    { lat: 48.517957, lng: 21.814179 },
    { lat: 48.517767, lng: 21.814482 },
    { lat: 48.517696, lng: 21.814651 },
    { lat: 48.517682, lng: 21.814801 },
    { lat: 48.517695, lng: 21.81499 },
    { lat: 48.517695, lng: 21.814994 },
    { lat: 48.517744, lng: 21.81515 },
    { lat: 48.517751, lng: 21.815178 },
    { lat: 48.517858, lng: 21.815526 },
    { lat: 48.517953, lng: 21.815685 },
    { lat: 48.518153, lng: 21.816004 },
    { lat: 48.518204, lng: 21.816084 },
    { lat: 48.518263, lng: 21.816184 },
    { lat: 48.518491, lng: 21.816569 },
    { lat: 48.518543, lng: 21.816656 },
    { lat: 48.518544, lng: 21.816658 },
    { lat: 48.518759, lng: 21.817139 },
    { lat: 48.518834, lng: 21.817549 },
    { lat: 48.518837, lng: 21.817611 },
    { lat: 48.518845, lng: 21.817807 },
    { lat: 48.518833, lng: 21.817935 },
    { lat: 48.518821, lng: 21.818056 },
    { lat: 48.518764, lng: 21.818324 },
    { lat: 48.518642, lng: 21.818677 },
    { lat: 48.5185, lng: 21.818895 },
    { lat: 48.518457, lng: 21.818954 },
    { lat: 48.518455, lng: 21.818955 },
    { lat: 48.518261, lng: 21.819133 },
    { lat: 48.518055, lng: 21.819299 },
    { lat: 48.517611, lng: 21.819627 },
    { lat: 48.517277, lng: 21.819882 },
    { lat: 48.516842, lng: 21.820331 },
    { lat: 48.516837, lng: 21.820337 },
    { lat: 48.516821, lng: 21.820372 },
    { lat: 48.51675, lng: 21.82052 },
    { lat: 48.516713, lng: 21.820599 },
    { lat: 48.516697, lng: 21.820634 },
    { lat: 48.516693, lng: 21.820646 },
    { lat: 48.516666, lng: 21.820736 },
    { lat: 48.51667, lng: 21.82086 },
    { lat: 48.516687, lng: 21.821034 },
    { lat: 48.516764, lng: 21.821207 },
    { lat: 48.516842, lng: 21.821271 },
    { lat: 48.516919, lng: 21.821309 },
    { lat: 48.517004, lng: 21.821294 },
    { lat: 48.517354, lng: 21.821161 },
    { lat: 48.517662, lng: 21.820864 },
    { lat: 48.517765, lng: 21.820803 },
    { lat: 48.517897, lng: 21.820777 },
    { lat: 48.518013, lng: 21.820797 },
    { lat: 48.518286, lng: 21.820909 },
    { lat: 48.518287, lng: 21.82091 },
    { lat: 48.518471, lng: 21.821056 },
    { lat: 48.519041, lng: 21.821683 },
    { lat: 48.5194, lng: 21.8222 },
    { lat: 48.519725, lng: 21.822689 },
    { lat: 48.519914, lng: 21.823057 },
    { lat: 48.519918, lng: 21.823064 },
    { lat: 48.520202, lng: 21.823609 },
    { lat: 48.520306, lng: 21.823801 },
    { lat: 48.520651, lng: 21.824432 },
    { lat: 48.520792, lng: 21.824707 },
    { lat: 48.52108, lng: 21.825018 },
    { lat: 48.521286, lng: 21.825254 },
    { lat: 48.521308, lng: 21.825295 },
    { lat: 48.521355, lng: 21.825385 },
    { lat: 48.521482, lng: 21.825777 },
    { lat: 48.521503, lng: 21.82601 },
    { lat: 48.521504, lng: 21.826278 },
    { lat: 48.521398, lng: 21.826815 },
    { lat: 48.521395, lng: 21.826831 },
    { lat: 48.52139, lng: 21.826843 },
    { lat: 48.521316, lng: 21.827006 },
    { lat: 48.521149, lng: 21.827328 },
    { lat: 48.52106, lng: 21.827485 },
    { lat: 48.521044, lng: 21.827513 },
    { lat: 48.520727, lng: 21.828012 },
    { lat: 48.520705, lng: 21.828052 },
    { lat: 48.52041, lng: 21.828568 },
    { lat: 48.52038, lng: 21.828627 },
    { lat: 48.520373, lng: 21.828637 },
    { lat: 48.520292, lng: 21.828878 },
    { lat: 48.520244, lng: 21.829118 },
    { lat: 48.520242, lng: 21.829132 },
    { lat: 48.520256, lng: 21.829318 },
    { lat: 48.52028, lng: 21.829389 },
    { lat: 48.520283, lng: 21.829395 },
    { lat: 48.520283, lng: 21.829396 },
    { lat: 48.520307, lng: 21.829464 },
    { lat: 48.520341, lng: 21.829562 },
    { lat: 48.520371, lng: 21.82965 },
    { lat: 48.520415, lng: 21.829749 },
    { lat: 48.52045, lng: 21.829832 },
    { lat: 48.520452, lng: 21.829834 },
    { lat: 48.520491, lng: 21.829924 },
    { lat: 48.520882, lng: 21.83031 },
    { lat: 48.520953, lng: 21.83037 },
    { lat: 48.521029, lng: 21.830433 },
    { lat: 48.521094, lng: 21.830489 },
    { lat: 48.521529, lng: 21.830863 },
    { lat: 48.521816, lng: 21.831104 },
    { lat: 48.52213, lng: 21.831374 },
    { lat: 48.522247, lng: 21.831471 },
    { lat: 48.522377, lng: 21.831578 },
    { lat: 48.522385, lng: 21.831586 },
    { lat: 48.522528, lng: 21.831704 },
    { lat: 48.52277, lng: 21.831876 },
    { lat: 48.522775, lng: 21.83188 },
    { lat: 48.523304, lng: 21.832134 },
    { lat: 48.523433, lng: 21.832196 },
    { lat: 48.523436, lng: 21.832198 },
    { lat: 48.523647, lng: 21.83229 },
    { lat: 48.523665, lng: 21.832292 },
    { lat: 48.523678, lng: 21.832294 },
    { lat: 48.523965, lng: 21.83233 },
    { lat: 48.523976, lng: 21.832329 },
    { lat: 48.523999, lng: 21.832326 },
    { lat: 48.524054, lng: 21.83232 },
    { lat: 48.524067, lng: 21.832318 },
    { lat: 48.524088, lng: 21.832314 },
    { lat: 48.524139, lng: 21.832307 },
    { lat: 48.524289, lng: 21.832252 },
    { lat: 48.524342, lng: 21.832211 },
    { lat: 48.524345, lng: 21.832209 },
    { lat: 48.524393, lng: 21.832172 },
    { lat: 48.524509, lng: 21.832042 },
    { lat: 48.524539, lng: 21.831992 },
    { lat: 48.524637, lng: 21.831822 },
    { lat: 48.524639, lng: 21.831818 },
    { lat: 48.524658, lng: 21.831767 },
    { lat: 48.524717, lng: 21.831607 },
    { lat: 48.524707, lng: 21.831445 },
    { lat: 48.524677, lng: 21.830952 },
    { lat: 48.524667, lng: 21.83077 },
    { lat: 48.524598, lng: 21.830398 },
    { lat: 48.524568, lng: 21.830235 },
    { lat: 48.524445, lng: 21.82985 },
    { lat: 48.524441, lng: 21.829836 },
    { lat: 48.524219, lng: 21.829428 },
    { lat: 48.523962, lng: 21.828956 },
    { lat: 48.523783, lng: 21.828655 },
    { lat: 48.523369, lng: 21.828038 },
    { lat: 48.523128, lng: 21.827567 },
    { lat: 48.522779, lng: 21.826684 },
    { lat: 48.522486, lng: 21.825608 },
    { lat: 48.522331, lng: 21.825028 },
    { lat: 48.522088, lng: 21.823798 },
    { lat: 48.522076, lng: 21.823516 },
    { lat: 48.522124, lng: 21.823281 },
    { lat: 48.522282, lng: 21.823036 },
    { lat: 48.522417, lng: 21.822897 },
    { lat: 48.522461, lng: 21.822876 },
    { lat: 48.522526, lng: 21.822844 },
    { lat: 48.52266, lng: 21.822809 },
    { lat: 48.52275, lng: 21.822823 },
    { lat: 48.522779, lng: 21.822844 },
    { lat: 48.522844, lng: 21.822892 },
    { lat: 48.522937, lng: 21.823025 },
    { lat: 48.52325, lng: 21.823635 },
    { lat: 48.5233, lng: 21.823733 },
    { lat: 48.523339, lng: 21.82382 },
    { lat: 48.523623, lng: 21.824442 },
    { lat: 48.523627, lng: 21.824449 },
    { lat: 48.524108, lng: 21.82553 },
    { lat: 48.524487, lng: 21.82637 },
    { lat: 48.524635, lng: 21.826688 },
    { lat: 48.52469, lng: 21.826806 },
    { lat: 48.525029, lng: 21.827539 },
    { lat: 48.525419, lng: 21.828413 },
    { lat: 48.525501, lng: 21.828584 },
    { lat: 48.525607, lng: 21.828804 },
    { lat: 48.525778, lng: 21.829124 },
    { lat: 48.525966, lng: 21.829478 },
    { lat: 48.526208, lng: 21.829929 },
    { lat: 48.526757, lng: 21.830888 },
    { lat: 48.526826, lng: 21.830994 },
    { lat: 48.527117, lng: 21.831444 },
    { lat: 48.527183, lng: 21.831521 },
    { lat: 48.527255, lng: 21.831606 },
    { lat: 48.527335, lng: 21.831699 },
    { lat: 48.527588, lng: 21.831945 },
    { lat: 48.527606, lng: 21.831954 },
    { lat: 48.52776, lng: 21.832032 },
    { lat: 48.527915, lng: 21.832073 },
    { lat: 48.52806, lng: 21.832093 },
    { lat: 48.528397, lng: 21.832032 },
    { lat: 48.5284, lng: 21.832031 },
    { lat: 48.528725, lng: 21.831808 },
    { lat: 48.528726, lng: 21.831807 },
    { lat: 48.529112, lng: 21.831521 },
    { lat: 48.529573, lng: 21.831088 },
    { lat: 48.52959, lng: 21.831073 },
    { lat: 48.530136, lng: 21.830587 },
    { lat: 48.530799, lng: 21.830114 },
    { lat: 48.530912, lng: 21.830063 },
    { lat: 48.530915, lng: 21.830061 },
    { lat: 48.531191, lng: 21.82998 },
    { lat: 48.5312, lng: 21.829978 },
    { lat: 48.531325, lng: 21.829941 },
    { lat: 48.531599, lng: 21.829885 },
    { lat: 48.531687, lng: 21.829883 },
    { lat: 48.531738, lng: 21.8299 },
    { lat: 48.531792, lng: 21.829919 },
    { lat: 48.531878, lng: 21.829986 },
    { lat: 48.531925, lng: 21.830024 },
    { lat: 48.531946, lng: 21.830049 },
    { lat: 48.531957, lng: 21.830061 },
    { lat: 48.531981, lng: 21.830091 },
    { lat: 48.532024, lng: 21.830202 },
    { lat: 48.53205, lng: 21.830348 },
    { lat: 48.532051, lng: 21.830351 },
    { lat: 48.532061, lng: 21.830545 },
    { lat: 48.532056, lng: 21.830898 },
    { lat: 48.532002, lng: 21.831252 },
    { lat: 48.531949, lng: 21.831481 },
    { lat: 48.531946, lng: 21.831493 },
    { lat: 48.531806, lng: 21.831803 },
    { lat: 48.531795, lng: 21.831827 },
    { lat: 48.531699, lng: 21.832007 },
    { lat: 48.531323, lng: 21.832453 },
    { lat: 48.531268, lng: 21.83251 },
    { lat: 48.530714, lng: 21.833086 },
    { lat: 48.530709, lng: 21.833091 },
    { lat: 48.530589, lng: 21.833217 },
    { lat: 48.530229, lng: 21.833694 },
    { lat: 48.530217, lng: 21.83371 },
    { lat: 48.530213, lng: 21.833719 },
    { lat: 48.530126, lng: 21.833894 },
    { lat: 48.529985, lng: 21.834282 },
    { lat: 48.529981, lng: 21.834293 },
    { lat: 48.52998, lng: 21.834298 },
    { lat: 48.529916, lng: 21.834573 },
    { lat: 48.529877, lng: 21.834988 },
    { lat: 48.529962, lng: 21.835507 },
    { lat: 48.530115, lng: 21.835842 },
    { lat: 48.530152, lng: 21.83588 },
    { lat: 48.530245, lng: 21.835975 },
    { lat: 48.530273, lng: 21.836002 },
    { lat: 48.530376, lng: 21.836107 },
    { lat: 48.53049, lng: 21.836143 },
    { lat: 48.530606, lng: 21.836174 },
    { lat: 48.530708, lng: 21.836179 },
    { lat: 48.530795, lng: 21.836184 },
    { lat: 48.531169, lng: 21.836039 },
    { lat: 48.531406, lng: 21.835861 },
    { lat: 48.531434, lng: 21.835838 },
    { lat: 48.531463, lng: 21.835814 },
    { lat: 48.531606, lng: 21.835698 },
    { lat: 48.531848, lng: 21.835424 },
    { lat: 48.532209, lng: 21.835026 },
    { lat: 48.532326, lng: 21.834898 },
    { lat: 48.532336, lng: 21.834887 },
    { lat: 48.532424, lng: 21.834816 },
    { lat: 48.532429, lng: 21.834812 },
    { lat: 48.532567, lng: 21.83474 },
    { lat: 48.532888, lng: 21.834646 },
    { lat: 48.532992, lng: 21.834649 },
    { lat: 48.533112, lng: 21.834652 },
    { lat: 48.533168, lng: 21.834654 },
    { lat: 48.533324, lng: 21.834742 },
    { lat: 48.533356, lng: 21.834786 },
    { lat: 48.533465, lng: 21.834936 },
    { lat: 48.533536, lng: 21.835089 },
    { lat: 48.53357, lng: 21.835209 },
    { lat: 48.533578, lng: 21.835237 },
    { lat: 48.533583, lng: 21.835266 },
    { lat: 48.533608, lng: 21.835424 },
    { lat: 48.533617, lng: 21.835478 },
    { lat: 48.533625, lng: 21.83562 },
    { lat: 48.533784, lng: 21.835553 },
    { lat: 48.533789, lng: 21.835551 },
    { lat: 48.533966, lng: 21.835477 },
    { lat: 48.534191, lng: 21.83526 },
    { lat: 48.534276, lng: 21.835179 },
    { lat: 48.534491, lng: 21.835179 },
    { lat: 48.534664, lng: 21.835299 },
    { lat: 48.534694, lng: 21.835244 },
    { lat: 48.534761, lng: 21.835116 },
    { lat: 48.535126, lng: 21.834437 },
    { lat: 48.534613, lng: 21.833479 },
    { lat: 48.534303, lng: 21.832919 },
    { lat: 48.534283, lng: 21.832881 },
    { lat: 48.533743, lng: 21.831868 },
    { lat: 48.533076, lng: 21.830638 },
    { lat: 48.532142, lng: 21.828932 },
    { lat: 48.531825, lng: 21.828342 },
    { lat: 48.531805, lng: 21.828268 },
    { lat: 48.5318, lng: 21.82824 },
    { lat: 48.531786, lng: 21.828168 },
    { lat: 48.5317616, lng: 21.8280953 },
    { lat: 48.5309439, lng: 21.8236156 },
    { lat: 48.5309824, lng: 21.8228384 },
    { lat: 48.5306899, lng: 21.8195734 },
    { lat: 48.5305966, lng: 21.8176827 },
    { lat: 48.5300771, lng: 21.8161005 },
    { lat: 48.5295371, lng: 21.81515 },
    { lat: 48.5295154, lng: 21.8135898 },
    { lat: 48.52935, lng: 21.812648 },
    { lat: 48.5292405, lng: 21.8114306 },
    { lat: 48.5290262, lng: 21.8098212 },
    { lat: 48.5292479, lng: 21.807252 },
    { lat: 48.5293851, lng: 21.8064775 },
    { lat: 48.5295742, lng: 21.8059207 },
    { lat: 48.5296672, lng: 21.8043643 },
    { lat: 48.5295863, lng: 21.8033397 },
    { lat: 48.5293813, lng: 21.802429 },
    { lat: 48.5289881, lng: 21.8017656 },
    { lat: 48.5289101, lng: 21.8017124 },
    { lat: 48.5289889, lng: 21.8012416 },
    { lat: 48.5289767, lng: 21.8004338 },
    { lat: 48.5291325, lng: 21.799941 },
    { lat: 48.5292927, lng: 21.7998289 },
    { lat: 48.5294526, lng: 21.7995794 },
    { lat: 48.5297522, lng: 21.7989077 },
    { lat: 48.528954, lng: 21.7975642 },
    { lat: 48.5281048, lng: 21.7953532 },
    { lat: 48.5273855, lng: 21.7937985 },
    { lat: 48.5270528, lng: 21.7933044 },
    { lat: 48.5270586, lng: 21.7924511 },
    { lat: 48.5267058, lng: 21.791615 },
    { lat: 48.5265476, lng: 21.7915088 },
    { lat: 48.5261586, lng: 21.791472 },
    { lat: 48.5255971, lng: 21.7912618 },
    { lat: 48.5246361, lng: 21.7915091 },
    { lat: 48.5241958, lng: 21.7914485 },
    { lat: 48.5239033, lng: 21.7909332 },
    { lat: 48.5232177, lng: 21.790757 },
    { lat: 48.5227294, lng: 21.7905395 },
    { lat: 48.5225066, lng: 21.790326 },
    { lat: 48.5225863, lng: 21.7898059 },
    { lat: 48.5225894, lng: 21.7890661 },
    { lat: 48.522494, lng: 21.7882229 },
    { lat: 48.5225055, lng: 21.7877643 },
    { lat: 48.5226244, lng: 21.7871975 },
    { lat: 48.5224797, lng: 21.7869208 },
    { lat: 48.5223902, lng: 21.7865961 },
    { lat: 48.5223964, lng: 21.7862313 },
    { lat: 48.5222924, lng: 21.7856231 },
    { lat: 48.5222481, lng: 21.7855615 },
    { lat: 48.5221037, lng: 21.7844781 },
    { lat: 48.5220998, lng: 21.7828852 },
    { lat: 48.5221863, lng: 21.7819483 },
    { lat: 48.520887, lng: 21.782126 },
    { lat: 48.5208663, lng: 21.7824044 },
    { lat: 48.5210981, lng: 21.7848322 },
    { lat: 48.5212498, lng: 21.7857636 },
    { lat: 48.5208931, lng: 21.7862741 },
    { lat: 48.5206833, lng: 21.7859516 },
    { lat: 48.5203212, lng: 21.784296 },
    { lat: 48.5202502, lng: 21.7838655 },
    { lat: 48.5201473, lng: 21.7818935 },
    { lat: 48.5199038, lng: 21.7818297 },
    { lat: 48.5189551, lng: 21.7813581 },
    { lat: 48.5184579, lng: 21.7813145 },
    { lat: 48.5181405, lng: 21.7817334 },
    { lat: 48.5177104, lng: 21.7824867 },
    { lat: 48.5176909, lng: 21.7827317 },
    { lat: 48.5174098, lng: 21.7829952 },
    { lat: 48.5178457, lng: 21.7843308 },
    { lat: 48.5173699, lng: 21.7850176 },
    { lat: 48.517495, lng: 21.7853611 },
    { lat: 48.517284, lng: 21.7855579 },
    { lat: 48.5173161, lng: 21.7857891 },
    { lat: 48.5165355, lng: 21.7863518 },
    { lat: 48.5167713, lng: 21.7870964 },
    { lat: 48.5170978, lng: 21.7878722 },
    { lat: 48.5167886, lng: 21.78827 },
    { lat: 48.5163782, lng: 21.7883595 },
    { lat: 48.5145503, lng: 21.7883333 },
    { lat: 48.5132702, lng: 21.7886377 },
    { lat: 48.5128766, lng: 21.7879636 },
    { lat: 48.5118226, lng: 21.7885306 },
    { lat: 48.5106911, lng: 21.7889641 },
    { lat: 48.5105614, lng: 21.7886167 },
    { lat: 48.509956, lng: 21.7893331 },
    { lat: 48.5096551, lng: 21.7897833 },
    { lat: 48.5091307, lng: 21.7902929 },
    { lat: 48.5083382, lng: 21.7904836 },
    { lat: 48.5077819, lng: 21.7907652 },
  ],
  Černochov: [
    { lat: 48.4274416, lng: 21.7236089 },
    { lat: 48.4277345, lng: 21.7267082 },
    { lat: 48.4279877, lng: 21.7292088 },
    { lat: 48.4279201, lng: 21.7292455 },
    { lat: 48.4284667, lng: 21.7363043 },
    { lat: 48.4284284, lng: 21.738185 },
    { lat: 48.4286428, lng: 21.7390045 },
    { lat: 48.4288975, lng: 21.7395566 },
    { lat: 48.4291032, lng: 21.7403855 },
    { lat: 48.4295976, lng: 21.7410357 },
    { lat: 48.4293294, lng: 21.743111 },
    { lat: 48.4288259, lng: 21.7453118 },
    { lat: 48.4281016, lng: 21.7462098 },
    { lat: 48.4279369, lng: 21.7465751 },
    { lat: 48.4278862, lng: 21.7467737 },
    { lat: 48.4278727, lng: 21.7475291 },
    { lat: 48.4274324, lng: 21.7490313 },
    { lat: 48.4282505, lng: 21.7489049 },
    { lat: 48.4286336, lng: 21.7489288 },
    { lat: 48.4289008, lng: 21.7487377 },
    { lat: 48.429638, lng: 21.7484546 },
    { lat: 48.4305196, lng: 21.748476 },
    { lat: 48.4307996, lng: 21.7482607 },
    { lat: 48.4311519, lng: 21.7478507 },
    { lat: 48.4312469, lng: 21.7477762 },
    { lat: 48.4313701, lng: 21.7475909 },
    { lat: 48.4314884, lng: 21.7471749 },
    { lat: 48.43228, lng: 21.7453137 },
    { lat: 48.4329267, lng: 21.7447162 },
    { lat: 48.4331971, lng: 21.744354 },
    { lat: 48.4332158, lng: 21.7440397 },
    { lat: 48.4331673, lng: 21.7439551 },
    { lat: 48.4330827, lng: 21.7433136 },
    { lat: 48.4330623, lng: 21.742056 },
    { lat: 48.4337981, lng: 21.7417967 },
    { lat: 48.4357081, lng: 21.7415714 },
    { lat: 48.4367634, lng: 21.7422004 },
    { lat: 48.4373146, lng: 21.7427807 },
    { lat: 48.4379624, lng: 21.743111 },
    { lat: 48.4380945, lng: 21.7425329 },
    { lat: 48.4383445, lng: 21.7425468 },
    { lat: 48.4386343, lng: 21.7423609 },
    { lat: 48.4398463, lng: 21.7422431 },
    { lat: 48.4405422, lng: 21.7418451 },
    { lat: 48.4411866, lng: 21.7413277 },
    { lat: 48.441747, lng: 21.7407898 },
    { lat: 48.442254, lng: 21.7404773 },
    { lat: 48.4424812, lng: 21.740387 },
    { lat: 48.442976, lng: 21.7403839 },
    { lat: 48.4435096, lng: 21.7402636 },
    { lat: 48.4438487, lng: 21.7399078 },
    { lat: 48.4439439, lng: 21.7395094 },
    { lat: 48.4444553, lng: 21.7389482 },
    { lat: 48.4448662, lng: 21.7386645 },
    { lat: 48.4454646, lng: 21.7383691 },
    { lat: 48.4457563, lng: 21.7381246 },
    { lat: 48.4459795, lng: 21.7380958 },
    { lat: 48.4482913, lng: 21.7372905 },
    { lat: 48.4485337, lng: 21.7371429 },
    { lat: 48.4491138, lng: 21.7366193 },
    { lat: 48.4501129, lng: 21.735282 },
    { lat: 48.4506767, lng: 21.7341986 },
    { lat: 48.4508515, lng: 21.733999 },
    { lat: 48.451077, lng: 21.733811 },
    { lat: 48.4515829, lng: 21.7335902 },
    { lat: 48.4526614, lng: 21.7327335 },
    { lat: 48.4530473, lng: 21.7322326 },
    { lat: 48.4532526, lng: 21.7317951 },
    { lat: 48.4534179, lng: 21.7310776 },
    { lat: 48.4536656, lng: 21.7306276 },
    { lat: 48.455112, lng: 21.7295198 },
    { lat: 48.4559021, lng: 21.7294876 },
    { lat: 48.4561962, lng: 21.7292914 },
    { lat: 48.4564474, lng: 21.7288458 },
    { lat: 48.4566409, lng: 21.7283445 },
    { lat: 48.4568695, lng: 21.7280962 },
    { lat: 48.4579139, lng: 21.7274037 },
    { lat: 48.4584897, lng: 21.7272641 },
    { lat: 48.4593972, lng: 21.7265388 },
    { lat: 48.4596424, lng: 21.7259556 },
    { lat: 48.4598524, lng: 21.7257244 },
    { lat: 48.4604731, lng: 21.7247149 },
    { lat: 48.4609606, lng: 21.7243484 },
    { lat: 48.4613392, lng: 21.7239534 },
    { lat: 48.4619291, lng: 21.723548 },
    { lat: 48.4622106, lng: 21.7232245 },
    { lat: 48.4623479, lng: 21.722845 },
    { lat: 48.4625165, lng: 21.7226808 },
    { lat: 48.4628085, lng: 21.7221525 },
    { lat: 48.4629666, lng: 21.7219737 },
    { lat: 48.4630282, lng: 21.7217495 },
    { lat: 48.4634139, lng: 21.7209112 },
    { lat: 48.4635453, lng: 21.720364 },
    { lat: 48.4635721, lng: 21.7198529 },
    { lat: 48.463544, lng: 21.7195987 },
    { lat: 48.4635445, lng: 21.7195823 },
    { lat: 48.4635376, lng: 21.7196147 },
    { lat: 48.463212, lng: 21.7193552 },
    { lat: 48.4630642, lng: 21.7190423 },
    { lat: 48.4628968, lng: 21.7180979 },
    { lat: 48.4625925, lng: 21.7172813 },
    { lat: 48.462213, lng: 21.7168167 },
    { lat: 48.4617823, lng: 21.716497 },
    { lat: 48.4613192, lng: 21.7164233 },
    { lat: 48.4609106, lng: 21.7162501 },
    { lat: 48.4601042, lng: 21.7161424 },
    { lat: 48.459728, lng: 21.7159984 },
    { lat: 48.4589909, lng: 21.7154119 },
    { lat: 48.4585146, lng: 21.7151503 },
    { lat: 48.4580992, lng: 21.7147674 },
    { lat: 48.4577256, lng: 21.7140575 },
    { lat: 48.4575067, lng: 21.7130802 },
    { lat: 48.457441, lng: 21.7121186 },
    { lat: 48.4572778, lng: 21.7116107 },
    { lat: 48.4572552, lng: 21.7112378 },
    { lat: 48.4570409, lng: 21.7105523 },
    { lat: 48.4561808, lng: 21.7082574 },
    { lat: 48.4557996, lng: 21.7085558 },
    { lat: 48.455584, lng: 21.7088186 },
    { lat: 48.4541614, lng: 21.7091344 },
    { lat: 48.4537245, lng: 21.7090382 },
    { lat: 48.4535988, lng: 21.7092132 },
    { lat: 48.4532616, lng: 21.7093147 },
    { lat: 48.4529903, lng: 21.7095539 },
    { lat: 48.4527552, lng: 21.7101347 },
    { lat: 48.4526719, lng: 21.7104716 },
    { lat: 48.4522445, lng: 21.7106796 },
    { lat: 48.4516282, lng: 21.7114376 },
    { lat: 48.450745, lng: 21.7121438 },
    { lat: 48.4505291, lng: 21.7122747 },
    { lat: 48.4498656, lng: 21.7124944 },
    { lat: 48.4496616, lng: 21.7126479 },
    { lat: 48.4493748, lng: 21.7129394 },
    { lat: 48.4490986, lng: 21.7133813 },
    { lat: 48.4488819, lng: 21.7136044 },
    { lat: 48.4484074, lng: 21.7139038 },
    { lat: 48.4479477, lng: 21.7140121 },
    { lat: 48.4475824, lng: 21.7142701 },
    { lat: 48.4471031, lng: 21.714315 },
    { lat: 48.4466266, lng: 21.7145859 },
    { lat: 48.4457859, lng: 21.7144931 },
    { lat: 48.4450602, lng: 21.714866 },
    { lat: 48.4447292, lng: 21.715156 },
    { lat: 48.4440514, lng: 21.7153392 },
    { lat: 48.4430141, lng: 21.7158056 },
    { lat: 48.4414287, lng: 21.7160464 },
    { lat: 48.4401325, lng: 21.7159198 },
    { lat: 48.4398495, lng: 21.71603 },
    { lat: 48.4395271, lng: 21.7159671 },
    { lat: 48.4389816, lng: 21.7159704 },
    { lat: 48.4388844, lng: 21.7159947 },
    { lat: 48.438263, lng: 21.7165635 },
    { lat: 48.437863, lng: 21.716836 },
    { lat: 48.4353498, lng: 21.7168079 },
    { lat: 48.4337382, lng: 21.7170046 },
    { lat: 48.4337507, lng: 21.7178779 },
    { lat: 48.4335657, lng: 21.719108 },
    { lat: 48.4336987, lng: 21.7198673 },
    { lat: 48.4330433, lng: 21.7206369 },
    { lat: 48.4319807, lng: 21.7210419 },
    { lat: 48.4308351, lng: 21.7216092 },
    { lat: 48.4290023, lng: 21.7219882 },
    { lat: 48.4290488, lng: 21.7223843 },
    { lat: 48.428482, lng: 21.7229311 },
    { lat: 48.427896, lng: 21.7231051 },
    { lat: 48.4275661, lng: 21.7233466 },
    { lat: 48.4275698, lng: 21.7235267 },
    { lat: 48.4274416, lng: 21.7236089 },
  ],
  ZemplínskaNováVes: [
    { lat: 48.5742554, lng: 21.6588072 },
    { lat: 48.5739053, lng: 21.6586787 },
    { lat: 48.5736428, lng: 21.6587523 },
    { lat: 48.5735082, lng: 21.6587213 },
    { lat: 48.5734108, lng: 21.6591907 },
    { lat: 48.5731834, lng: 21.6591871 },
    { lat: 48.5730688, lng: 21.6593151 },
    { lat: 48.5729076, lng: 21.6592676 },
    { lat: 48.5725251, lng: 21.6589792 },
    { lat: 48.5724136, lng: 21.6592661 },
    { lat: 48.5724034, lng: 21.6595026 },
    { lat: 48.5722863, lng: 21.6595835 },
    { lat: 48.5722486, lng: 21.659771 },
    { lat: 48.5721065, lng: 21.6599219 },
    { lat: 48.5720793, lng: 21.6600785 },
    { lat: 48.5718983, lng: 21.6599978 },
    { lat: 48.5717396, lng: 21.6598319 },
    { lat: 48.5716367, lng: 21.6598976 },
    { lat: 48.5715209, lng: 21.6601447 },
    { lat: 48.5715332, lng: 21.6603112 },
    { lat: 48.5716207, lng: 21.6602603 },
    { lat: 48.5716627, lng: 21.6605945 },
    { lat: 48.5716111, lng: 21.6608126 },
    { lat: 48.5714218, lng: 21.660882 },
    { lat: 48.5713881, lng: 21.6609672 },
    { lat: 48.5716997, lng: 21.66141 },
    { lat: 48.5714472, lng: 21.661721 },
    { lat: 48.5713828, lng: 21.6619151 },
    { lat: 48.5712475, lng: 21.6619871 },
    { lat: 48.5709275, lng: 21.6619314 },
    { lat: 48.5708086, lng: 21.6619882 },
    { lat: 48.570694, lng: 21.6621957 },
    { lat: 48.5707209, lng: 21.6623156 },
    { lat: 48.5709352, lng: 21.6626585 },
    { lat: 48.5709727, lng: 21.6625894 },
    { lat: 48.5711288, lng: 21.662866 },
    { lat: 48.5711846, lng: 21.6627343 },
    { lat: 48.5713222, lng: 21.6628682 },
    { lat: 48.5713105, lng: 21.6629307 },
    { lat: 48.5710799, lng: 21.6630925 },
    { lat: 48.5707652, lng: 21.6631477 },
    { lat: 48.5706552, lng: 21.663237 },
    { lat: 48.5704739, lng: 21.6631957 },
    { lat: 48.570469, lng: 21.6632667 },
    { lat: 48.5703423, lng: 21.6633233 },
    { lat: 48.570037, lng: 21.6632208 },
    { lat: 48.5698864, lng: 21.6628177 },
    { lat: 48.5697746, lng: 21.662765 },
    { lat: 48.5696488, lng: 21.662932 },
    { lat: 48.569639, lng: 21.6634769 },
    { lat: 48.5695597, lng: 21.6636307 },
    { lat: 48.5692129, lng: 21.6636129 },
    { lat: 48.5690575, lng: 21.663526 },
    { lat: 48.5685941, lng: 21.6634547 },
    { lat: 48.568539, lng: 21.6635785 },
    { lat: 48.5683978, lng: 21.6636818 },
    { lat: 48.5681857, lng: 21.6637022 },
    { lat: 48.5684816, lng: 21.6656685 },
    { lat: 48.5689177, lng: 21.6674532 },
    { lat: 48.569159, lng: 21.6687057 },
    { lat: 48.571696, lng: 21.6917633 },
    { lat: 48.5690541, lng: 21.6937881 },
    { lat: 48.5689515, lng: 21.693864 },
    { lat: 48.5696929, lng: 21.6979187 },
    { lat: 48.5697842, lng: 21.6979233 },
    { lat: 48.569865, lng: 21.6999515 },
    { lat: 48.5700065, lng: 21.7013425 },
    { lat: 48.5699765, lng: 21.701446 },
    { lat: 48.5699759, lng: 21.7014655 },
    { lat: 48.5704704, lng: 21.7013587 },
    { lat: 48.5727207, lng: 21.7007781 },
    { lat: 48.5731603, lng: 21.7006 },
    { lat: 48.5735441, lng: 21.7003202 },
    { lat: 48.5781518, lng: 21.6989585 },
    { lat: 48.5789781, lng: 21.6993922 },
    { lat: 48.5803095, lng: 21.6997831 },
    { lat: 48.5809683, lng: 21.6997567 },
    { lat: 48.58165, lng: 21.6995434 },
    { lat: 48.5816343, lng: 21.6995049 },
    { lat: 48.5845231, lng: 21.6969981 },
    { lat: 48.5941295, lng: 21.6884415 },
    { lat: 48.5946068, lng: 21.6876229 },
    { lat: 48.595521, lng: 21.6871842 },
    { lat: 48.5960085, lng: 21.6863312 },
    { lat: 48.5963485, lng: 21.6860137 },
    { lat: 48.5971747, lng: 21.6854023 },
    { lat: 48.5977026, lng: 21.6846672 },
    { lat: 48.5989736, lng: 21.6837467 },
    { lat: 48.5994741, lng: 21.6832891 },
    { lat: 48.6008601, lng: 21.6823344 },
    { lat: 48.6018799, lng: 21.6811528 },
    { lat: 48.6033385, lng: 21.6788607 },
    { lat: 48.6030468, lng: 21.678512 },
    { lat: 48.6021404, lng: 21.6752835 },
    { lat: 48.6019541, lng: 21.6743911 },
    { lat: 48.6014884, lng: 21.6719252 },
    { lat: 48.6012233, lng: 21.6719833 },
    { lat: 48.60067, lng: 21.6722001 },
    { lat: 48.600797, lng: 21.6726899 },
    { lat: 48.6003221, lng: 21.6730137 },
    { lat: 48.6003822, lng: 21.6733573 },
    { lat: 48.5995938, lng: 21.6736175 },
    { lat: 48.5996852, lng: 21.6742872 },
    { lat: 48.5996005, lng: 21.6743195 },
    { lat: 48.5996532, lng: 21.6746418 },
    { lat: 48.5994119, lng: 21.6746213 },
    { lat: 48.5992391, lng: 21.6745097 },
    { lat: 48.5990934, lng: 21.674613 },
    { lat: 48.5990964, lng: 21.6749299 },
    { lat: 48.5990265, lng: 21.6751322 },
    { lat: 48.5988924, lng: 21.6752916 },
    { lat: 48.5988157, lng: 21.6750817 },
    { lat: 48.5986916, lng: 21.6751466 },
    { lat: 48.5983295, lng: 21.6751123 },
    { lat: 48.5976408, lng: 21.6746574 },
    { lat: 48.5967438, lng: 21.6748177 },
    { lat: 48.596461, lng: 21.6750006 },
    { lat: 48.596478, lng: 21.6753816 },
    { lat: 48.5963827, lng: 21.6754323 },
    { lat: 48.5964513, lng: 21.67576 },
    { lat: 48.5949154, lng: 21.676378 },
    { lat: 48.5950016, lng: 21.6768493 },
    { lat: 48.5947295, lng: 21.6771839 },
    { lat: 48.5947566, lng: 21.6773987 },
    { lat: 48.5945057, lng: 21.6775206 },
    { lat: 48.5946041, lng: 21.678119 },
    { lat: 48.5943607, lng: 21.6784786 },
    { lat: 48.5944111, lng: 21.6790434 },
    { lat: 48.5943386, lng: 21.6791425 },
    { lat: 48.5944006, lng: 21.6795253 },
    { lat: 48.5940844, lng: 21.6802217 },
    { lat: 48.5938162, lng: 21.680287 },
    { lat: 48.5936544, lng: 21.6804211 },
    { lat: 48.593457, lng: 21.6804824 },
    { lat: 48.5927001, lng: 21.6814012 },
    { lat: 48.5923601, lng: 21.6816452 },
    { lat: 48.591845, lng: 21.6818957 },
    { lat: 48.5919151, lng: 21.6821604 },
    { lat: 48.5915607, lng: 21.6823636 },
    { lat: 48.5914856, lng: 21.6820829 },
    { lat: 48.5911145, lng: 21.6822221 },
    { lat: 48.5904621, lng: 21.6825924 },
    { lat: 48.5905084, lng: 21.6828479 },
    { lat: 48.5904155, lng: 21.6829066 },
    { lat: 48.5900388, lng: 21.6830377 },
    { lat: 48.5892268, lng: 21.6831342 },
    { lat: 48.5889972, lng: 21.6820648 },
    { lat: 48.5886204, lng: 21.6796786 },
    { lat: 48.5880265, lng: 21.6748214 },
    { lat: 48.5877244, lng: 21.6732011 },
    { lat: 48.5872874, lng: 21.6714115 },
    { lat: 48.5871416, lng: 21.6706071 },
    { lat: 48.5863119, lng: 21.6669443 },
    { lat: 48.5865767, lng: 21.6668315 },
    { lat: 48.5862645, lng: 21.6652482 },
    { lat: 48.5863439, lng: 21.6651967 },
    { lat: 48.5863033, lng: 21.6650205 },
    { lat: 48.5860219, lng: 21.6650611 },
    { lat: 48.5853121, lng: 21.6626497 },
    { lat: 48.5842601, lng: 21.6597853 },
    { lat: 48.5850079, lng: 21.6592777 },
    { lat: 48.5858044, lng: 21.6585748 },
    { lat: 48.586058, lng: 21.6582633 },
    { lat: 48.5864635, lng: 21.6579519 },
    { lat: 48.5866201, lng: 21.6577146 },
    { lat: 48.5864617, lng: 21.6573983 },
    { lat: 48.5862651, lng: 21.6574507 },
    { lat: 48.5861829, lng: 21.6573674 },
    { lat: 48.5860648, lng: 21.6573691 },
    { lat: 48.5859272, lng: 21.6574413 },
    { lat: 48.5859863, lng: 21.6576185 },
    { lat: 48.5859136, lng: 21.6578046 },
    { lat: 48.5858296, lng: 21.6577768 },
    { lat: 48.5854985, lng: 21.6580921 },
    { lat: 48.5853057, lng: 21.6582403 },
    { lat: 48.5851325, lng: 21.6582866 },
    { lat: 48.5850489, lng: 21.6583935 },
    { lat: 48.5849731, lng: 21.6583812 },
    { lat: 48.5848481, lng: 21.6582166 },
    { lat: 48.5847534, lng: 21.6582432 },
    { lat: 48.5844008, lng: 21.6589692 },
    { lat: 48.5843517, lng: 21.6591883 },
    { lat: 48.5841399, lng: 21.6591774 },
    { lat: 48.5839266, lng: 21.658992 },
    { lat: 48.5837651, lng: 21.6590867 },
    { lat: 48.5837968, lng: 21.6593814 },
    { lat: 48.5836941, lng: 21.6597795 },
    { lat: 48.5836175, lng: 21.6599022 },
    { lat: 48.5835433, lng: 21.659946 },
    { lat: 48.5833759, lng: 21.6598345 },
    { lat: 48.5831716, lng: 21.6599347 },
    { lat: 48.5829557, lng: 21.6598522 },
    { lat: 48.5825965, lng: 21.6600871 },
    { lat: 48.5824472, lng: 21.6604277 },
    { lat: 48.5823111, lng: 21.6604681 },
    { lat: 48.5822312, lng: 21.6603928 },
    { lat: 48.5817848, lng: 21.6605202 },
    { lat: 48.5813262, lng: 21.6605438 },
    { lat: 48.581246, lng: 21.660492 },
    { lat: 48.5808984, lng: 21.6592632 },
    { lat: 48.580799, lng: 21.6592897 },
    { lat: 48.5806684, lng: 21.6594887 },
    { lat: 48.5805164, lng: 21.6593621 },
    { lat: 48.5804479, lng: 21.6591609 },
    { lat: 48.5804454, lng: 21.6589074 },
    { lat: 48.5803444, lng: 21.6587595 },
    { lat: 48.579829, lng: 21.6591605 },
    { lat: 48.5797873, lng: 21.659285 },
    { lat: 48.5794634, lng: 21.65949 },
    { lat: 48.5785966, lng: 21.6602523 },
    { lat: 48.578363, lng: 21.6603905 },
    { lat: 48.5775691, lng: 21.6601675 },
    { lat: 48.577075, lng: 21.6602368 },
    { lat: 48.5767014, lng: 21.6601546 },
    { lat: 48.5762308, lng: 21.6597775 },
    { lat: 48.5760859, lng: 21.6596199 },
    { lat: 48.5759352, lng: 21.6593358 },
    { lat: 48.5756474, lng: 21.6594319 },
    { lat: 48.5755672, lng: 21.6591747 },
    { lat: 48.57548, lng: 21.6591151 },
    { lat: 48.575183, lng: 21.6590758 },
    { lat: 48.5748872, lng: 21.6589503 },
    { lat: 48.5747661, lng: 21.6591338 },
    { lat: 48.5745195, lng: 21.659287 },
    { lat: 48.5742442, lng: 21.6588541 },
    { lat: 48.5742554, lng: 21.6588072 },
  ],
  ZemplínskaNováVesExt: [
    { lat: 48.5484379, lng: 21.6731286 },
    { lat: 48.5484533, lng: 21.6731567 },
    { lat: 48.548515, lng: 21.6735648 },
    { lat: 48.5483163, lng: 21.6736212 },
    { lat: 48.5479848, lng: 21.673509 },
    { lat: 48.5477013, lng: 21.6737954 },
    { lat: 48.5474741, lng: 21.6736806 },
    { lat: 48.547407, lng: 21.6734082 },
    { lat: 48.5472281, lng: 21.6733671 },
    { lat: 48.5470905, lng: 21.6734392 },
    { lat: 48.5470495, lng: 21.6736193 },
    { lat: 48.5470354, lng: 21.6744653 },
    { lat: 48.5468974, lng: 21.6746007 },
    { lat: 48.5467492, lng: 21.6745374 },
    { lat: 48.5466561, lng: 21.6743827 },
    { lat: 48.5465325, lng: 21.6743048 },
    { lat: 48.5460897, lng: 21.6742425 },
    { lat: 48.5460146, lng: 21.6737557 },
    { lat: 48.5459242, lng: 21.6736242 },
    { lat: 48.5456089, lng: 21.6734815 },
    { lat: 48.5453565, lng: 21.6729781 },
    { lat: 48.5451437, lng: 21.6727694 },
    { lat: 48.5449654, lng: 21.6727044 },
    { lat: 48.5448363, lng: 21.6728797 },
    { lat: 48.5446478, lng: 21.6734795 },
    { lat: 48.5442907, lng: 21.6739991 },
    { lat: 48.5434954, lng: 21.6741721 },
    { lat: 48.5432021, lng: 21.6741015 },
    { lat: 48.5429505, lng: 21.6741203 },
    { lat: 48.5429052, lng: 21.6743948 },
    { lat: 48.5426867, lng: 21.6748345 },
    { lat: 48.542294, lng: 21.6747513 },
    { lat: 48.5420862, lng: 21.6744396 },
    { lat: 48.5417849, lng: 21.6741551 },
    { lat: 48.5413932, lng: 21.6739451 },
    { lat: 48.541097, lng: 21.6739773 },
    { lat: 48.5414715, lng: 21.6755316 },
    { lat: 48.542333, lng: 21.6788918 },
    { lat: 48.5428565, lng: 21.6803432 },
    { lat: 48.54358, lng: 21.6819555 },
    { lat: 48.5458291, lng: 21.6875563 },
    { lat: 48.5463995, lng: 21.6885433 },
    { lat: 48.5473281, lng: 21.6880214 },
    { lat: 48.5489075, lng: 21.6876386 },
    { lat: 48.5494218, lng: 21.6872701 },
    { lat: 48.549617, lng: 21.6870428 },
    { lat: 48.5498633, lng: 21.6864626 },
    { lat: 48.5502712, lng: 21.6857875 },
    { lat: 48.5510258, lng: 21.6847354 },
    { lat: 48.5521941, lng: 21.6840135 },
    { lat: 48.5542191, lng: 21.6841676 },
    { lat: 48.5543871, lng: 21.6848018 },
    { lat: 48.5561713, lng: 21.6898651 },
    { lat: 48.5564302, lng: 21.6907412 },
    { lat: 48.5585935, lng: 21.6966783 },
    { lat: 48.5588685, lng: 21.6970645 },
    { lat: 48.559852, lng: 21.6990991 },
    { lat: 48.5611575, lng: 21.6980759 },
    { lat: 48.5618631, lng: 21.6976303 },
    { lat: 48.5616607, lng: 21.6963072 },
    { lat: 48.5622115, lng: 21.6959962 },
    { lat: 48.5617363, lng: 21.6930471 },
    { lat: 48.5618421, lng: 21.692966 },
    { lat: 48.561598, lng: 21.6911962 },
    { lat: 48.5620352, lng: 21.690966 },
    { lat: 48.561795, lng: 21.6895447 },
    { lat: 48.5620956, lng: 21.6893468 },
    { lat: 48.5618494, lng: 21.687743 },
    { lat: 48.5618694, lng: 21.6875698 },
    { lat: 48.561785, lng: 21.6866948 },
    { lat: 48.5618585, lng: 21.6866672 },
    { lat: 48.5616289, lng: 21.6850166 },
    { lat: 48.5634097, lng: 21.6826173 },
    { lat: 48.5640815, lng: 21.6815934 },
    { lat: 48.5639603, lng: 21.6811441 },
    { lat: 48.5643101, lng: 21.6809335 },
    { lat: 48.5644975, lng: 21.680627 },
    { lat: 48.5649256, lng: 21.6790449 },
    { lat: 48.5651931, lng: 21.6777154 },
    { lat: 48.5652237, lng: 21.6773945 },
    { lat: 48.5652023, lng: 21.6767731 },
    { lat: 48.564464, lng: 21.6734788 },
    { lat: 48.5638457, lng: 21.6711159 },
    { lat: 48.5639104, lng: 21.6708902 },
    { lat: 48.562929, lng: 21.6668609 },
    { lat: 48.5628211, lng: 21.666863 },
    { lat: 48.5606825, lng: 21.6581069 },
    { lat: 48.5597215, lng: 21.6549187 },
    { lat: 48.5593785, lng: 21.6539825 },
    { lat: 48.5592249, lng: 21.6533535 },
    { lat: 48.5594175, lng: 21.6532729 },
    { lat: 48.5596723, lng: 21.6526385 },
    { lat: 48.5600556, lng: 21.6512843 },
    { lat: 48.5600932, lng: 21.6507655 },
    { lat: 48.5600573, lng: 21.6502424 },
    { lat: 48.5599922, lng: 21.6500025 },
    { lat: 48.559363, lng: 21.6483987 },
    { lat: 48.559536, lng: 21.6479024 },
    { lat: 48.5591087, lng: 21.6460803 },
    { lat: 48.5590898, lng: 21.6457633 },
    { lat: 48.5592336, lng: 21.6452229 },
    { lat: 48.5592568, lng: 21.6449209 },
    { lat: 48.5590893, lng: 21.6446423 },
    { lat: 48.5589199, lng: 21.6441361 },
    { lat: 48.5587797, lng: 21.6438998 },
    { lat: 48.5561363, lng: 21.6412878 },
    { lat: 48.5559754, lng: 21.641327 },
    { lat: 48.5555566, lng: 21.6410784 },
    { lat: 48.5554421, lng: 21.6408839 },
    { lat: 48.5554685, lng: 21.6406123 },
    { lat: 48.5552008, lng: 21.6404128 },
    { lat: 48.5551942, lng: 21.6404207 },
    { lat: 48.5549766, lng: 21.6406865 },
    { lat: 48.5544137, lng: 21.6409666 },
    { lat: 48.5548256, lng: 21.642149 },
    { lat: 48.5551271, lng: 21.642471 },
    { lat: 48.5553923, lng: 21.6432276 },
    { lat: 48.5554668, lng: 21.6440142 },
    { lat: 48.5554513, lng: 21.644836 },
    { lat: 48.555695, lng: 21.6464538 },
    { lat: 48.555729, lng: 21.6470249 },
    { lat: 48.5560108, lng: 21.6493247 },
    { lat: 48.5563283, lng: 21.6513734 },
    { lat: 48.5569782, lng: 21.6564679 },
    { lat: 48.5575574, lng: 21.6588302 },
    { lat: 48.557743, lng: 21.6593535 },
    { lat: 48.5569388, lng: 21.6601436 },
    { lat: 48.5559693, lng: 21.6613878 },
    { lat: 48.5565909, lng: 21.6621386 },
    { lat: 48.5574045, lng: 21.6640662 },
    { lat: 48.557474, lng: 21.6645126 },
    { lat: 48.5575838, lng: 21.6670776 },
    { lat: 48.5578436, lng: 21.6686335 },
    { lat: 48.5577107, lng: 21.6689181 },
    { lat: 48.5574119, lng: 21.6691409 },
    { lat: 48.5572699, lng: 21.6691365 },
    { lat: 48.5571333, lng: 21.6691185 },
    { lat: 48.5570099, lng: 21.6690441 },
    { lat: 48.5566184, lng: 21.6685925 },
    { lat: 48.5559389, lng: 21.6694613 },
    { lat: 48.5556884, lng: 21.6694325 },
    { lat: 48.5549336, lng: 21.6700598 },
    { lat: 48.5547761, lng: 21.6699723 },
    { lat: 48.5547245, lng: 21.6698188 },
    { lat: 48.5547396, lng: 21.6696293 },
    { lat: 48.5548796, lng: 21.6694384 },
    { lat: 48.5548265, lng: 21.6691427 },
    { lat: 48.5544974, lng: 21.6688797 },
    { lat: 48.5543588, lng: 21.6689917 },
    { lat: 48.5541729, lng: 21.6689028 },
    { lat: 48.5539997, lng: 21.669068 },
    { lat: 48.5537443, lng: 21.6689916 },
    { lat: 48.5535085, lng: 21.6694633 },
    { lat: 48.5533126, lng: 21.6696194 },
    { lat: 48.5531725, lng: 21.6689782 },
    { lat: 48.5530045, lng: 21.6690962 },
    { lat: 48.5529544, lng: 21.6692364 },
    { lat: 48.5529628, lng: 21.6694589 },
    { lat: 48.5531761, lng: 21.6696879 },
    { lat: 48.5531014, lng: 21.6701198 },
    { lat: 48.5529278, lng: 21.6701941 },
    { lat: 48.5527966, lng: 21.6701636 },
    { lat: 48.552512, lng: 21.6699227 },
    { lat: 48.5523866, lng: 21.6700275 },
    { lat: 48.5521651, lng: 21.6710627 },
    { lat: 48.5519246, lng: 21.67128 },
    { lat: 48.5518735, lng: 21.6717094 },
    { lat: 48.5517425, lng: 21.6716966 },
    { lat: 48.551409, lng: 21.6713902 },
    { lat: 48.5512849, lng: 21.6715443 },
    { lat: 48.5511245, lng: 21.6720489 },
    { lat: 48.5505079, lng: 21.6725031 },
    { lat: 48.5503646, lng: 21.6729002 },
    { lat: 48.5502454, lng: 21.6729811 },
    { lat: 48.5497646, lng: 21.6728849 },
    { lat: 48.5495234, lng: 21.672912 },
    { lat: 48.5493153, lng: 21.6727509 },
    { lat: 48.5491173, lng: 21.6729152 },
    { lat: 48.5490543, lng: 21.6731655 },
    { lat: 48.548947, lng: 21.6733104 },
    { lat: 48.5484379, lng: 21.6731286 },
  ],
  Brehov: [
    { lat: 48.5077819, lng: 21.7907652 },
    { lat: 48.5073671, lng: 21.790949 },
    { lat: 48.505544, lng: 21.7922987 },
    { lat: 48.5049914, lng: 21.7926322 },
    { lat: 48.5041829, lng: 21.7929065 },
    { lat: 48.5045631, lng: 21.7952928 },
    { lat: 48.5048178, lng: 21.7962057 },
    { lat: 48.5045023, lng: 21.7961852 },
    { lat: 48.5040983, lng: 21.7957154 },
    { lat: 48.5039018, lng: 21.7946667 },
    { lat: 48.5017602, lng: 21.7951957 },
    { lat: 48.5007928, lng: 21.7956749 },
    { lat: 48.5005703, lng: 21.794917 },
    { lat: 48.501295, lng: 21.7945314 },
    { lat: 48.5012119, lng: 21.7940716 },
    { lat: 48.5013587, lng: 21.7940068 },
    { lat: 48.5009949, lng: 21.7906281 },
    { lat: 48.4999831, lng: 21.7911653 },
    { lat: 48.4999417, lng: 21.791 },
    { lat: 48.4999578, lng: 21.7908401 },
    { lat: 48.5003482, lng: 21.7906362 },
    { lat: 48.5002153, lng: 21.7901113 },
    { lat: 48.5005662, lng: 21.7897288 },
    { lat: 48.5005001, lng: 21.7894401 },
    { lat: 48.4986856, lng: 21.7904298 },
    { lat: 48.4981056, lng: 21.7906163 },
    { lat: 48.4978037, lng: 21.7901384 },
    { lat: 48.4972812, lng: 21.790209 },
    { lat: 48.4969517, lng: 21.7890173 },
    { lat: 48.4967823, lng: 21.7890813 },
    { lat: 48.4966682, lng: 21.7889303 },
    { lat: 48.495828, lng: 21.7895843 },
    { lat: 48.4958607, lng: 21.7897171 },
    { lat: 48.4951748, lng: 21.7899472 },
    { lat: 48.4948533, lng: 21.7898706 },
    { lat: 48.4943543, lng: 21.7898926 },
    { lat: 48.4943105, lng: 21.7895881 },
    { lat: 48.4937003, lng: 21.7897029 },
    { lat: 48.4922099, lng: 21.7903149 },
    { lat: 48.4916171, lng: 21.7906575 },
    { lat: 48.4905842, lng: 21.7907851 },
    { lat: 48.4894473, lng: 21.7915763 },
    { lat: 48.4887153, lng: 21.7922297 },
    { lat: 48.4873906, lng: 21.7926007 },
    { lat: 48.4861798, lng: 21.7927194 },
    { lat: 48.4849502, lng: 21.7930613 },
    { lat: 48.483678, lng: 21.7938845 },
    { lat: 48.4828819, lng: 21.793261 },
    { lat: 48.4828551, lng: 21.7932279 },
    { lat: 48.4817966, lng: 21.794645 },
    { lat: 48.4805528, lng: 21.7956487 },
    { lat: 48.4802939, lng: 21.7956851 },
    { lat: 48.4795949, lng: 21.7959715 },
    { lat: 48.4796225, lng: 21.7960351 },
    { lat: 48.4795862, lng: 21.7960691 },
    { lat: 48.4795529, lng: 21.7959959 },
    { lat: 48.479498, lng: 21.7960524 },
    { lat: 48.4794734, lng: 21.7960124 },
    { lat: 48.478677, lng: 21.796746 },
    { lat: 48.477737, lng: 21.7980967 },
    { lat: 48.477183, lng: 21.798762 },
    { lat: 48.4761432, lng: 21.7994381 },
    { lat: 48.4759752, lng: 21.7996311 },
    { lat: 48.4759007, lng: 21.7997557 },
    { lat: 48.4758078, lng: 21.8002048 },
    { lat: 48.475475, lng: 21.800887 },
    { lat: 48.4756526, lng: 21.8018207 },
    { lat: 48.4756591, lng: 21.802116 },
    { lat: 48.4754784, lng: 21.8023417 },
    { lat: 48.4755399, lng: 21.8027982 },
    { lat: 48.4747708, lng: 21.8031663 },
    { lat: 48.4746275, lng: 21.8033621 },
    { lat: 48.474435, lng: 21.8037823 },
    { lat: 48.4737321, lng: 21.8037132 },
    { lat: 48.4733152, lng: 21.8039 },
    { lat: 48.4732399, lng: 21.8040321 },
    { lat: 48.4732068, lng: 21.8043135 },
    { lat: 48.4730671, lng: 21.8047331 },
    { lat: 48.4730537, lng: 21.8056845 },
    { lat: 48.4729244, lng: 21.8058145 },
    { lat: 48.4727937, lng: 21.8058485 },
    { lat: 48.4723281, lng: 21.8057233 },
    { lat: 48.4719832, lng: 21.8057059 },
    { lat: 48.4703446, lng: 21.805944 },
    { lat: 48.4693008, lng: 21.806255 },
    { lat: 48.4684221, lng: 21.8063373 },
    { lat: 48.4675466, lng: 21.8065839 },
    { lat: 48.467305, lng: 21.8065139 },
    { lat: 48.4665823, lng: 21.8068426 },
    { lat: 48.4670499, lng: 21.8091809 },
    { lat: 48.467138, lng: 21.809556 },
    { lat: 48.467182, lng: 21.809538 },
    { lat: 48.467187, lng: 21.809537 },
    { lat: 48.467271, lng: 21.809518 },
    { lat: 48.467363, lng: 21.809505 },
    { lat: 48.467453, lng: 21.809528 },
    { lat: 48.467722, lng: 21.809586 },
    { lat: 48.467861, lng: 21.809651 },
    { lat: 48.467862, lng: 21.809652 },
    { lat: 48.46806, lng: 21.809759 },
    { lat: 48.468139, lng: 21.809822 },
    { lat: 48.468295, lng: 21.809954 },
    { lat: 48.468519, lng: 21.810153 },
    { lat: 48.468628, lng: 21.810279 },
    { lat: 48.468734, lng: 21.810377 },
    { lat: 48.468893, lng: 21.810565 },
    { lat: 48.469117, lng: 21.810797 },
    { lat: 48.469275, lng: 21.810961 },
    { lat: 48.469302, lng: 21.810991 },
    { lat: 48.469477, lng: 21.811171 },
    { lat: 48.469604, lng: 21.811281 },
    { lat: 48.469673, lng: 21.811322 },
    { lat: 48.469751, lng: 21.81135 },
    { lat: 48.469782, lng: 21.811362 },
    { lat: 48.470137, lng: 21.811484 },
    { lat: 48.470196, lng: 21.8115 },
    { lat: 48.47031, lng: 21.811531 },
    { lat: 48.470451, lng: 21.811569 },
    { lat: 48.470471, lng: 21.811575 },
    { lat: 48.47154, lng: 21.811565 },
    { lat: 48.471682, lng: 21.811564 },
    { lat: 48.472047, lng: 21.811558 },
    { lat: 48.472127, lng: 21.811557 },
    { lat: 48.472223, lng: 21.811556 },
    { lat: 48.472348, lng: 21.811554 },
    { lat: 48.472462, lng: 21.811552 },
    { lat: 48.472563, lng: 21.811553 },
    { lat: 48.472696, lng: 21.811543 },
    { lat: 48.47273, lng: 21.811541 },
    { lat: 48.472987, lng: 21.811536 },
    { lat: 48.473222, lng: 21.811531 },
    { lat: 48.473633, lng: 21.811522 },
    { lat: 48.473796, lng: 21.811516 },
    { lat: 48.473929, lng: 21.811522 },
    { lat: 48.47393, lng: 21.811522 },
    { lat: 48.474061, lng: 21.811547 },
    { lat: 48.474146, lng: 21.811578 },
    { lat: 48.474377, lng: 21.811688 },
    { lat: 48.474476, lng: 21.811771 },
    { lat: 48.474477, lng: 21.811771 },
    { lat: 48.47453, lng: 21.811823 },
    { lat: 48.474573, lng: 21.811864 },
    { lat: 48.474644, lng: 21.811953 },
    { lat: 48.474863, lng: 21.812229 },
    { lat: 48.474915, lng: 21.812295 },
    { lat: 48.475054, lng: 21.812577 },
    { lat: 48.475081, lng: 21.812639 },
    { lat: 48.475119, lng: 21.812753 },
    { lat: 48.475184, lng: 21.812952 },
    { lat: 48.475193, lng: 21.812978 },
    { lat: 48.475244, lng: 21.813138 },
    { lat: 48.475258, lng: 21.813313 },
    { lat: 48.475259, lng: 21.813405 },
    { lat: 48.475261, lng: 21.813731 },
    { lat: 48.475216, lng: 21.814021 },
    { lat: 48.475215, lng: 21.814029 },
    { lat: 48.475102, lng: 21.814355 },
    { lat: 48.47509, lng: 21.814375 },
    { lat: 48.474956, lng: 21.814591 },
    { lat: 48.474808, lng: 21.814789 },
    { lat: 48.474792, lng: 21.814811 },
    { lat: 48.474718, lng: 21.814826 },
    { lat: 48.474673, lng: 21.814836 },
    { lat: 48.474526, lng: 21.814888 },
    { lat: 48.474128, lng: 21.814851 },
    { lat: 48.473953, lng: 21.814794 },
    { lat: 48.473811, lng: 21.8147 },
    { lat: 48.473757, lng: 21.814665 },
    { lat: 48.473212, lng: 21.814288 },
    { lat: 48.473141, lng: 21.814229 },
    { lat: 48.473136, lng: 21.814226 },
    { lat: 48.472946, lng: 21.814108 },
    { lat: 48.4729, lng: 21.814079 },
    { lat: 48.472664, lng: 21.813972 },
    { lat: 48.472463, lng: 21.813885 },
    { lat: 48.472286, lng: 21.813808 },
    { lat: 48.472051, lng: 21.813769 },
    { lat: 48.472049, lng: 21.81377 },
    { lat: 48.471827, lng: 21.813811 },
    { lat: 48.471763, lng: 21.813836 },
    { lat: 48.471762, lng: 21.813836 },
    { lat: 48.471669, lng: 21.813903 },
    { lat: 48.471611, lng: 21.813945 },
    { lat: 48.471402, lng: 21.814305 },
    { lat: 48.471373, lng: 21.814391 },
    { lat: 48.471323, lng: 21.814758 },
    { lat: 48.471319, lng: 21.814839 },
    { lat: 48.471337, lng: 21.814972 },
    { lat: 48.47135, lng: 21.815023 },
    { lat: 48.471374, lng: 21.815122 },
    { lat: 48.471555, lng: 21.815611 },
    { lat: 48.471866, lng: 21.816193 },
    { lat: 48.471982, lng: 21.816381 },
    { lat: 48.472148, lng: 21.816648 },
    { lat: 48.472155, lng: 21.816663 },
    { lat: 48.472348, lng: 21.817027 },
    { lat: 48.472386, lng: 21.817124 },
    { lat: 48.472402, lng: 21.817173 },
    { lat: 48.472425, lng: 21.817239 },
    { lat: 48.472434, lng: 21.817265 },
    { lat: 48.472497, lng: 21.817484 },
    { lat: 48.47253, lng: 21.817768 },
    { lat: 48.472561, lng: 21.817994 },
    { lat: 48.472591, lng: 21.818332 },
    { lat: 48.472593, lng: 21.818427 },
    { lat: 48.472597, lng: 21.818566 },
    { lat: 48.472603, lng: 21.8188 },
    { lat: 48.472604, lng: 21.818833 },
    { lat: 48.4726, lng: 21.818914 },
    { lat: 48.472476, lng: 21.81945 },
    { lat: 48.472422, lng: 21.81963 },
    { lat: 48.47236, lng: 21.819832 },
    { lat: 48.472223, lng: 21.820298 },
    { lat: 48.472143, lng: 21.820447 },
    { lat: 48.472015, lng: 21.820689 },
    { lat: 48.47199, lng: 21.820735 },
    { lat: 48.471837, lng: 21.821151 },
    { lat: 48.471644, lng: 21.821686 },
    { lat: 48.471524, lng: 21.822097 },
    { lat: 48.471477, lng: 21.822443 },
    { lat: 48.471445, lng: 21.822805 },
    { lat: 48.471433, lng: 21.823158 },
    { lat: 48.471441, lng: 21.82323 },
    { lat: 48.471443, lng: 21.823445 },
    { lat: 48.471443, lng: 21.823446 },
    { lat: 48.471444, lng: 21.823531 },
    { lat: 48.47145, lng: 21.82402 },
    { lat: 48.471456, lng: 21.824278 },
    { lat: 48.471465, lng: 21.824671 },
    { lat: 48.471471, lng: 21.824736 },
    { lat: 48.471486, lng: 21.82486 },
    { lat: 48.471496, lng: 21.824942 },
    { lat: 48.471518, lng: 21.825125 },
    { lat: 48.471519, lng: 21.825141 },
    { lat: 48.471612, lng: 21.825547 },
    { lat: 48.471653, lng: 21.825646 },
    { lat: 48.471681, lng: 21.825734 },
    { lat: 48.471682, lng: 21.825738 },
    { lat: 48.471712, lng: 21.825794 },
    { lat: 48.471815, lng: 21.825987 },
    { lat: 48.471951, lng: 21.826199 },
    { lat: 48.471975, lng: 21.826237 },
    { lat: 48.472233, lng: 21.826443 },
    { lat: 48.472304, lng: 21.826448 },
    { lat: 48.472474, lng: 21.826461 },
    { lat: 48.472682, lng: 21.826379 },
    { lat: 48.472741, lng: 21.826346 },
    { lat: 48.472944, lng: 21.826102 },
    { lat: 48.473129, lng: 21.82586 },
    { lat: 48.47313, lng: 21.825858 },
    { lat: 48.473277, lng: 21.825598 },
    { lat: 48.473442, lng: 21.825165 },
    { lat: 48.473518, lng: 21.824743 },
    { lat: 48.473591, lng: 21.824319 },
    { lat: 48.473591, lng: 21.824317 },
    { lat: 48.473607, lng: 21.8241 },
    { lat: 48.473624, lng: 21.823864 },
    { lat: 48.473668, lng: 21.823115 },
    { lat: 48.473673, lng: 21.823033 },
    { lat: 48.473673, lng: 21.823019 },
    { lat: 48.473683, lng: 21.822617 },
    { lat: 48.473688, lng: 21.822348 },
    { lat: 48.473709, lng: 21.821877 },
    { lat: 48.473708, lng: 21.821619 },
    { lat: 48.473714, lng: 21.821454 },
    { lat: 48.473714, lng: 21.821065 },
    { lat: 48.473715, lng: 21.820641 },
    { lat: 48.473713, lng: 21.820286 },
    { lat: 48.473713, lng: 21.820243 },
    { lat: 48.473714, lng: 21.820189 },
    { lat: 48.473716, lng: 21.819624 },
    { lat: 48.473716, lng: 21.819236 },
    { lat: 48.473694, lng: 21.818735 },
    { lat: 48.473704, lng: 21.818574 },
    { lat: 48.473795, lng: 21.81813 },
    { lat: 48.473867, lng: 21.817903 },
    { lat: 48.473869, lng: 21.817897 },
    { lat: 48.474076, lng: 21.817552 },
    { lat: 48.474175, lng: 21.817387 },
    { lat: 48.474202, lng: 21.817343 },
    { lat: 48.474245, lng: 21.81727 },
    { lat: 48.474249, lng: 21.817265 },
    { lat: 48.474355, lng: 21.817087 },
    { lat: 48.474444, lng: 21.816979 },
    { lat: 48.474617, lng: 21.816767 },
    { lat: 48.474648, lng: 21.816729 },
    { lat: 48.474995, lng: 21.816647 },
    { lat: 48.475091, lng: 21.81664 },
    { lat: 48.475891, lng: 21.816584 },
    { lat: 48.476064, lng: 21.816636 },
    { lat: 48.476219, lng: 21.816695 },
    { lat: 48.476221, lng: 21.816696 },
    { lat: 48.476391, lng: 21.816797 },
    { lat: 48.476609, lng: 21.816926 },
    { lat: 48.476719, lng: 21.81699 },
    { lat: 48.476886, lng: 21.817132 },
    { lat: 48.476949, lng: 21.817187 },
    { lat: 48.477028, lng: 21.817254 },
    { lat: 48.477122, lng: 21.817373 },
    { lat: 48.477233, lng: 21.817517 },
    { lat: 48.477264, lng: 21.817561 },
    { lat: 48.477433, lng: 21.817806 },
    { lat: 48.477644, lng: 21.818151 },
    { lat: 48.477769, lng: 21.818403 },
    { lat: 48.477892, lng: 21.818663 },
    { lat: 48.477907, lng: 21.8187 },
    { lat: 48.478081, lng: 21.819129 },
    { lat: 48.478225, lng: 21.819507 },
    { lat: 48.478272, lng: 21.819643 },
    { lat: 48.478501, lng: 21.820268 },
    { lat: 48.478512, lng: 21.820298 },
    { lat: 48.478513, lng: 21.820301 },
    { lat: 48.478592, lng: 21.820504 },
    { lat: 48.478673, lng: 21.820696 },
    { lat: 48.478678, lng: 21.820706 },
    { lat: 48.478889, lng: 21.821162 },
    { lat: 48.478982, lng: 21.821331 },
    { lat: 48.479189, lng: 21.821689 },
    { lat: 48.479261, lng: 21.821796 },
    { lat: 48.479283, lng: 21.821824 },
    { lat: 48.47954, lng: 21.822149 },
    { lat: 48.479712, lng: 21.822352 },
    { lat: 48.479749, lng: 21.822396 },
    { lat: 48.480134, lng: 21.822818 },
    { lat: 48.480332, lng: 21.823001 },
    { lat: 48.480381, lng: 21.823029 },
    { lat: 48.480381, lng: 21.82303 },
    { lat: 48.480683, lng: 21.823167 },
    { lat: 48.480896, lng: 21.823226 },
    { lat: 48.481286, lng: 21.823132 },
    { lat: 48.48135, lng: 21.823117 },
    { lat: 48.481631, lng: 21.822986 },
    { lat: 48.481736, lng: 21.822922 },
    { lat: 48.481998, lng: 21.822763 },
    { lat: 48.482097, lng: 21.822701 },
    { lat: 48.48242, lng: 21.822499 },
    { lat: 48.482743, lng: 21.822161 },
    { lat: 48.483152, lng: 21.821733 },
    { lat: 48.4838, lng: 21.821202 },
    { lat: 48.484267, lng: 21.820954 },
    { lat: 48.484485, lng: 21.820838 },
    { lat: 48.485086, lng: 21.820726 },
    { lat: 48.485233, lng: 21.820699 },
    { lat: 48.485339, lng: 21.820681 },
    { lat: 48.485451, lng: 21.820684 },
    { lat: 48.485535, lng: 21.820692 },
    { lat: 48.485764, lng: 21.820713 },
    { lat: 48.486012, lng: 21.820761 },
    { lat: 48.486751, lng: 21.820983 },
    { lat: 48.486775, lng: 21.821004 },
    { lat: 48.487101, lng: 21.82128 },
    { lat: 48.487517, lng: 21.821809 },
    { lat: 48.487559, lng: 21.821885 },
    { lat: 48.487807, lng: 21.822354 },
    { lat: 48.487843, lng: 21.822431 },
    { lat: 48.488227, lng: 21.823251 },
    { lat: 48.48832, lng: 21.823492 },
    { lat: 48.488355, lng: 21.823581 },
    { lat: 48.488462, lng: 21.823859 },
    { lat: 48.48904, lng: 21.825349 },
    { lat: 48.489046, lng: 21.825361 },
    { lat: 48.489143, lng: 21.825577 },
    { lat: 48.489154, lng: 21.825599 },
    { lat: 48.489313, lng: 21.825956 },
    { lat: 48.48938, lng: 21.826104 },
    { lat: 48.489621, lng: 21.82645 },
    { lat: 48.489646, lng: 21.826485 },
    { lat: 48.489674, lng: 21.826507 },
    { lat: 48.489891, lng: 21.826678 },
    { lat: 48.490222, lng: 21.826822 },
    { lat: 48.490427, lng: 21.826854 },
    { lat: 48.49045, lng: 21.826854 },
    { lat: 48.490529, lng: 21.826853 },
    { lat: 48.491006, lng: 21.826761 },
    { lat: 48.491077, lng: 21.826733 },
    { lat: 48.491232, lng: 21.826672 },
    { lat: 48.491574, lng: 21.826502 },
    { lat: 48.491858, lng: 21.826322 },
    { lat: 48.492278, lng: 21.826051 },
    { lat: 48.49243, lng: 21.825935 },
    { lat: 48.49262, lng: 21.825791 },
    { lat: 48.493162, lng: 21.825366 },
    { lat: 48.493228, lng: 21.825315 },
    { lat: 48.493306, lng: 21.825253 },
    { lat: 48.493486, lng: 21.825112 },
    { lat: 48.493546, lng: 21.825064 },
    { lat: 48.493801, lng: 21.824858 },
    { lat: 48.493945, lng: 21.824753 },
    { lat: 48.494134, lng: 21.824616 },
    { lat: 48.494455, lng: 21.824404 },
    { lat: 48.49487, lng: 21.824104 },
    { lat: 48.495061, lng: 21.824004 },
    { lat: 48.495397, lng: 21.823808 },
    { lat: 48.495684, lng: 21.823735 },
    { lat: 48.495694, lng: 21.823732 },
    { lat: 48.496242, lng: 21.823556 },
    { lat: 48.496496, lng: 21.823434 },
    { lat: 48.496643, lng: 21.823417 },
    { lat: 48.49723, lng: 21.823353 },
    { lat: 48.4974, lng: 21.823191 },
    { lat: 48.497485, lng: 21.823112 },
    { lat: 48.497619, lng: 21.822986 },
    { lat: 48.497937, lng: 21.822627 },
    { lat: 48.497971, lng: 21.822552 },
    { lat: 48.498063, lng: 21.822351 },
    { lat: 48.498096, lng: 21.822204 },
    { lat: 48.498311, lng: 21.821194 },
    { lat: 48.498391, lng: 21.820591 },
    { lat: 48.498404, lng: 21.820428 },
    { lat: 48.498422, lng: 21.820312 },
    { lat: 48.498422, lng: 21.820308 },
    { lat: 48.498559, lng: 21.819956 },
    { lat: 48.498622, lng: 21.819796 },
    { lat: 48.498671, lng: 21.819664 },
    { lat: 48.498825, lng: 21.819251 },
    { lat: 48.498933, lng: 21.818961 },
    { lat: 48.499137, lng: 21.818656 },
    { lat: 48.499206, lng: 21.818577 },
    { lat: 48.499252, lng: 21.818529 },
    { lat: 48.499583, lng: 21.818189 },
    { lat: 48.499637, lng: 21.818133 },
    { lat: 48.499837, lng: 21.817977 },
    { lat: 48.500049, lng: 21.817811 },
    { lat: 48.500389, lng: 21.817556 },
    { lat: 48.500408, lng: 21.81754 },
    { lat: 48.500581, lng: 21.817393 },
    { lat: 48.500644, lng: 21.817325 },
    { lat: 48.50082, lng: 21.817138 },
    { lat: 48.500968, lng: 21.816988 },
    { lat: 48.501122, lng: 21.816833 },
    { lat: 48.502052, lng: 21.815873 },
    { lat: 48.502368, lng: 21.815563 },
    { lat: 48.502437, lng: 21.815495 },
    { lat: 48.502598, lng: 21.815337 },
    { lat: 48.502722, lng: 21.815226 },
    { lat: 48.502966, lng: 21.815007 },
    { lat: 48.50297, lng: 21.815003 },
    { lat: 48.502978, lng: 21.814996 },
    { lat: 48.503669, lng: 21.814399 },
    { lat: 48.504192, lng: 21.813977 },
    { lat: 48.50451, lng: 21.81375 },
    { lat: 48.504511, lng: 21.813749 },
    { lat: 48.504713, lng: 21.813572 },
    { lat: 48.504855, lng: 21.813501 },
    { lat: 48.504987, lng: 21.8134 },
    { lat: 48.505178, lng: 21.813246 },
    { lat: 48.505461, lng: 21.813025 },
    { lat: 48.505542, lng: 21.812936 },
    { lat: 48.50566, lng: 21.812811 },
    { lat: 48.505877, lng: 21.812583 },
    { lat: 48.506345, lng: 21.8121 },
    { lat: 48.50637, lng: 21.812055 },
    { lat: 48.506686, lng: 21.811728 },
    { lat: 48.506765, lng: 21.811658 },
    { lat: 48.506858, lng: 21.811568 },
    { lat: 48.506926, lng: 21.811489 },
    { lat: 48.507028, lng: 21.811373 },
    { lat: 48.507263, lng: 21.811119 },
    { lat: 48.507534, lng: 21.810842 },
    { lat: 48.507704, lng: 21.810668 },
    { lat: 48.507798, lng: 21.810573 },
    { lat: 48.507906, lng: 21.810463 },
    { lat: 48.507973, lng: 21.810396 },
    { lat: 48.508049, lng: 21.810319 },
    { lat: 48.50818, lng: 21.810187 },
    { lat: 48.508372, lng: 21.810043 },
    { lat: 48.5082529, lng: 21.8096564 },
    { lat: 48.5081696, lng: 21.8096504 },
    { lat: 48.5079564, lng: 21.8089939 },
    { lat: 48.5074051, lng: 21.8076159 },
    { lat: 48.5063516, lng: 21.8007085 },
    { lat: 48.5070478, lng: 21.8005666 },
    { lat: 48.5066993, lng: 21.797331 },
    { lat: 48.5089389, lng: 21.79599 },
    { lat: 48.5086732, lng: 21.7944436 },
    { lat: 48.5083654, lng: 21.793356 },
    { lat: 48.508539, lng: 21.7932803 },
    { lat: 48.5079779, lng: 21.7913322 },
    { lat: 48.5077819, lng: 21.7907652 },
  ],
  VeľkéOzorovce: [
    { lat: 48.6936154, lng: 21.5544268 },
    { lat: 48.693533, lng: 21.5545824 },
    { lat: 48.6932403, lng: 21.5547381 },
    { lat: 48.6923312, lng: 21.5545828 },
    { lat: 48.6920343, lng: 21.5547063 },
    { lat: 48.6916566, lng: 21.5549953 },
    { lat: 48.6911914, lng: 21.555612 },
    { lat: 48.6907436, lng: 21.5556541 },
    { lat: 48.6898928, lng: 21.5551346 },
    { lat: 48.6897057, lng: 21.5548793 },
    { lat: 48.6896993, lng: 21.5546119 },
    { lat: 48.6895687, lng: 21.5542118 },
    { lat: 48.6892369, lng: 21.553744 },
    { lat: 48.6887338, lng: 21.5533385 },
    { lat: 48.6882033, lng: 21.5526506 },
    { lat: 48.6878375, lng: 21.5520464 },
    { lat: 48.687635, lng: 21.5519313 },
    { lat: 48.6875492, lng: 21.5517588 },
    { lat: 48.6828403, lng: 21.5586719 },
    { lat: 48.6821395, lng: 21.5598366 },
    { lat: 48.6797148, lng: 21.5635002 },
    { lat: 48.6790364, lng: 21.5644927 },
    { lat: 48.678877, lng: 21.5645927 },
    { lat: 48.6788296, lng: 21.5648589 },
    { lat: 48.678672, lng: 21.5652223 },
    { lat: 48.6785848, lng: 21.5653148 },
    { lat: 48.6784296, lng: 21.5657994 },
    { lat: 48.6779582, lng: 21.5665229 },
    { lat: 48.6777591, lng: 21.567235 },
    { lat: 48.677353, lng: 21.5675179 },
    { lat: 48.6771014, lng: 21.5675732 },
    { lat: 48.6770392, lng: 21.5677264 },
    { lat: 48.6766473, lng: 21.5679524 },
    { lat: 48.6764868, lng: 21.5679279 },
    { lat: 48.6762907, lng: 21.5679896 },
    { lat: 48.6761466, lng: 21.5679473 },
    { lat: 48.6760295, lng: 21.5680662 },
    { lat: 48.6759151, lng: 21.569235 },
    { lat: 48.6753389, lng: 21.5698285 },
    { lat: 48.6753249, lng: 21.5703296 },
    { lat: 48.6748526, lng: 21.5716979 },
    { lat: 48.6739005, lng: 21.5725679 },
    { lat: 48.6733952, lng: 21.5728418 },
    { lat: 48.6725314, lng: 21.5730963 },
    { lat: 48.6717964, lng: 21.5735287 },
    { lat: 48.6705772, lng: 21.5737085 },
    { lat: 48.6694747, lng: 21.5732738 },
    { lat: 48.6690029, lng: 21.5729812 },
    { lat: 48.6685862, lng: 21.5731269 },
    { lat: 48.6682433, lng: 21.5730151 },
    { lat: 48.668108, lng: 21.5734029 },
    { lat: 48.6677427, lng: 21.5740201 },
    { lat: 48.6675766, lng: 21.5741906 },
    { lat: 48.6671506, lng: 21.5744102 },
    { lat: 48.6662029, lng: 21.5743977 },
    { lat: 48.6659488, lng: 21.5745703 },
    { lat: 48.6657435, lng: 21.5749911 },
    { lat: 48.6657267, lng: 21.5757905 },
    { lat: 48.6639942, lng: 21.5761199 },
    { lat: 48.6626153, lng: 21.5777752 },
    { lat: 48.6618281, lng: 21.5791512 },
    { lat: 48.6613579, lng: 21.5798348 },
    { lat: 48.6611919, lng: 21.5799711 },
    { lat: 48.660501, lng: 21.5809333 },
    { lat: 48.659825, lng: 21.5817436 },
    { lat: 48.6588276, lng: 21.5831261 },
    { lat: 48.6586969, lng: 21.583117 },
    { lat: 48.6581811, lng: 21.5834216 },
    { lat: 48.6576613, lng: 21.5836254 },
    { lat: 48.6557905, lng: 21.5849228 },
    { lat: 48.6543293, lng: 21.5866152 },
    { lat: 48.653628, lng: 21.5849597 },
    { lat: 48.6531688, lng: 21.5843155 },
    { lat: 48.652553, lng: 21.5859911 },
    { lat: 48.6525791, lng: 21.5862392 },
    { lat: 48.6524796, lng: 21.5864239 },
    { lat: 48.652355, lng: 21.5864717 },
    { lat: 48.6523117, lng: 21.5863318 },
    { lat: 48.6522194, lng: 21.5864509 },
    { lat: 48.6521108, lng: 21.5863468 },
    { lat: 48.6520805, lng: 21.5864331 },
    { lat: 48.6521504, lng: 21.5866385 },
    { lat: 48.6522298, lng: 21.5866957 },
    { lat: 48.6522185, lng: 21.5868142 },
    { lat: 48.6521247, lng: 21.5869676 },
    { lat: 48.652023, lng: 21.5869897 },
    { lat: 48.6519522, lng: 21.5867946 },
    { lat: 48.6519127, lng: 21.5868235 },
    { lat: 48.6520326, lng: 21.5871738 },
    { lat: 48.6519817, lng: 21.5874005 },
    { lat: 48.6518524, lng: 21.587607 },
    { lat: 48.6518745, lng: 21.5878298 },
    { lat: 48.6519574, lng: 21.5878633 },
    { lat: 48.6517842, lng: 21.5880238 },
    { lat: 48.6515666, lng: 21.5880092 },
    { lat: 48.6515431, lng: 21.5880887 },
    { lat: 48.6516512, lng: 21.5882515 },
    { lat: 48.6518557, lng: 21.5882883 },
    { lat: 48.6516994, lng: 21.5885644 },
    { lat: 48.6517568, lng: 21.5886106 },
    { lat: 48.6517477, lng: 21.5887652 },
    { lat: 48.651844, lng: 21.5888263 },
    { lat: 48.6518034, lng: 21.5889873 },
    { lat: 48.6519478, lng: 21.5896795 },
    { lat: 48.6517856, lng: 21.5897546 },
    { lat: 48.6517596, lng: 21.5898687 },
    { lat: 48.6518765, lng: 21.5899427 },
    { lat: 48.6518568, lng: 21.590165 },
    { lat: 48.6518075, lng: 21.5902172 },
    { lat: 48.6518986, lng: 21.5902537 },
    { lat: 48.6518867, lng: 21.5903926 },
    { lat: 48.6517968, lng: 21.5904311 },
    { lat: 48.6518771, lng: 21.590648 },
    { lat: 48.6518611, lng: 21.5907237 },
    { lat: 48.6517441, lng: 21.590723 },
    { lat: 48.6516723, lng: 21.5910856 },
    { lat: 48.6517907, lng: 21.5911798 },
    { lat: 48.6519158, lng: 21.5911868 },
    { lat: 48.6519438, lng: 21.5915031 },
    { lat: 48.6520825, lng: 21.5916305 },
    { lat: 48.6521555, lng: 21.5916277 },
    { lat: 48.6521997, lng: 21.5917992 },
    { lat: 48.6523405, lng: 21.5918845 },
    { lat: 48.652286, lng: 21.5920275 },
    { lat: 48.6521214, lng: 21.5919768 },
    { lat: 48.6521089, lng: 21.5920929 },
    { lat: 48.6521905, lng: 21.5921973 },
    { lat: 48.6521097, lng: 21.5923654 },
    { lat: 48.6521621, lng: 21.5925855 },
    { lat: 48.6521345, lng: 21.5926877 },
    { lat: 48.651993, lng: 21.5927369 },
    { lat: 48.6521377, lng: 21.5928423 },
    { lat: 48.6520486, lng: 21.592997 },
    { lat: 48.652078, lng: 21.5932382 },
    { lat: 48.652174, lng: 21.5933299 },
    { lat: 48.652055, lng: 21.5936276 },
    { lat: 48.6520724, lng: 21.5938439 },
    { lat: 48.6520965, lng: 21.5939836 },
    { lat: 48.6522447, lng: 21.5940877 },
    { lat: 48.652164, lng: 21.5943814 },
    { lat: 48.6522088, lng: 21.5947743 },
    { lat: 48.6523184, lng: 21.5948349 },
    { lat: 48.652274, lng: 21.5950171 },
    { lat: 48.6523099, lng: 21.5950956 },
    { lat: 48.6521237, lng: 21.5953555 },
    { lat: 48.6522844, lng: 21.5954698 },
    { lat: 48.6525423, lng: 21.5960419 },
    { lat: 48.6524881, lng: 21.5961546 },
    { lat: 48.6522609, lng: 21.5962303 },
    { lat: 48.6525035, lng: 21.5967458 },
    { lat: 48.6520214, lng: 21.5969683 },
    { lat: 48.651957, lng: 21.5972012 },
    { lat: 48.6518411, lng: 21.5973552 },
    { lat: 48.651939, lng: 21.5974347 },
    { lat: 48.6519525, lng: 21.5975976 },
    { lat: 48.6518527, lng: 21.5978125 },
    { lat: 48.6519479, lng: 21.5978381 },
    { lat: 48.6520714, lng: 21.5977074 },
    { lat: 48.6521248, lng: 21.5977631 },
    { lat: 48.6520534, lng: 21.5979933 },
    { lat: 48.6521021, lng: 21.5981225 },
    { lat: 48.6522932, lng: 21.5979915 },
    { lat: 48.6523686, lng: 21.5980746 },
    { lat: 48.6522468, lng: 21.5983069 },
    { lat: 48.6522909, lng: 21.5983913 },
    { lat: 48.652464, lng: 21.5983827 },
    { lat: 48.6528296, lng: 21.5992644 },
    { lat: 48.6528241, lng: 21.5993128 },
    { lat: 48.6525682, lng: 21.5993775 },
    { lat: 48.6525745, lng: 21.5996468 },
    { lat: 48.6526959, lng: 21.5997615 },
    { lat: 48.6526881, lng: 21.6004431 },
    { lat: 48.6526337, lng: 21.6007501 },
    { lat: 48.6523457, lng: 21.6009577 },
    { lat: 48.6523197, lng: 21.6007092 },
    { lat: 48.6521844, lng: 21.6009403 },
    { lat: 48.6522906, lng: 21.6012801 },
    { lat: 48.6522682, lng: 21.6014054 },
    { lat: 48.6520967, lng: 21.6014287 },
    { lat: 48.6520969, lng: 21.6016387 },
    { lat: 48.6521516, lng: 21.6017829 },
    { lat: 48.6521033, lng: 21.6020283 },
    { lat: 48.6522335, lng: 21.6021741 },
    { lat: 48.6522219, lng: 21.6023652 },
    { lat: 48.6519923, lng: 21.602562 },
    { lat: 48.6516772, lng: 21.602323 },
    { lat: 48.6511904, lng: 21.6031013 },
    { lat: 48.6509943, lng: 21.6029248 },
    { lat: 48.6508572, lng: 21.6031741 },
    { lat: 48.6506499, lng: 21.6034086 },
    { lat: 48.6506422, lng: 21.6040218 },
    { lat: 48.6508497, lng: 21.604678 },
    { lat: 48.6507813, lng: 21.6049666 },
    { lat: 48.6508608, lng: 21.6050345 },
    { lat: 48.6508792, lng: 21.6051686 },
    { lat: 48.6507752, lng: 21.6053965 },
    { lat: 48.6507849, lng: 21.6055838 },
    { lat: 48.6505827, lng: 21.6061183 },
    { lat: 48.6504657, lng: 21.6061176 },
    { lat: 48.6503171, lng: 21.6063174 },
    { lat: 48.6502966, lng: 21.6064301 },
    { lat: 48.6503359, lng: 21.6065436 },
    { lat: 48.6504653, lng: 21.6067129 },
    { lat: 48.6503696, lng: 21.6068311 },
    { lat: 48.6501787, lng: 21.6069197 },
    { lat: 48.6502891, lng: 21.6070889 },
    { lat: 48.650161, lng: 21.607139 },
    { lat: 48.6494006, lng: 21.6071439 },
    { lat: 48.6492894, lng: 21.6072325 },
    { lat: 48.6491935, lng: 21.6075823 },
    { lat: 48.6491727, lng: 21.6079252 },
    { lat: 48.6490635, lng: 21.6083303 },
    { lat: 48.6491097, lng: 21.6084067 },
    { lat: 48.6490507, lng: 21.6085571 },
    { lat: 48.6484987, lng: 21.6093326 },
    { lat: 48.648033, lng: 21.6102709 },
    { lat: 48.6476398, lng: 21.6105364 },
    { lat: 48.6474578, lng: 21.6104583 },
    { lat: 48.6471126, lng: 21.6105941 },
    { lat: 48.6469874, lng: 21.6107483 },
    { lat: 48.646856, lng: 21.6111587 },
    { lat: 48.6466285, lng: 21.6114297 },
    { lat: 48.6465306, lng: 21.6114291 },
    { lat: 48.6463297, lng: 21.6118073 },
    { lat: 48.6459821, lng: 21.6118906 },
    { lat: 48.6453229, lng: 21.6119011 },
    { lat: 48.6441842, lng: 21.611778 },
    { lat: 48.6439759, lng: 21.6118154 },
    { lat: 48.6438973, lng: 21.6119812 },
    { lat: 48.6437986, lng: 21.6120291 },
    { lat: 48.6434927, lng: 21.6120738 },
    { lat: 48.6432952, lng: 21.6118913 },
    { lat: 48.6431826, lng: 21.6120145 },
    { lat: 48.6431075, lng: 21.6122202 },
    { lat: 48.6428704, lng: 21.6121584 },
    { lat: 48.6427718, lng: 21.6122145 },
    { lat: 48.6426865, lng: 21.6121402 },
    { lat: 48.6421631, lng: 21.6122097 },
    { lat: 48.6414828, lng: 21.6127147 },
    { lat: 48.6412235, lng: 21.6127763 },
    { lat: 48.6403635, lng: 21.6128031 },
    { lat: 48.6401784, lng: 21.6125743 },
    { lat: 48.6400405, lng: 21.6125194 },
    { lat: 48.6397062, lng: 21.6127555 },
    { lat: 48.6394103, lng: 21.6128753 },
    { lat: 48.6387837, lng: 21.6128016 },
    { lat: 48.6384838, lng: 21.612896 },
    { lat: 48.6384431, lng: 21.6128897 },
    { lat: 48.6384134, lng: 21.6150151 },
    { lat: 48.6388244, lng: 21.6154721 },
    { lat: 48.6393515, lng: 21.6157326 },
    { lat: 48.6397568, lng: 21.6156994 },
    { lat: 48.6416439, lng: 21.6151476 },
    { lat: 48.6420099, lng: 21.614955 },
    { lat: 48.6422616, lng: 21.617204 },
    { lat: 48.6450102, lng: 21.6165887 },
    { lat: 48.6455436, lng: 21.6199901 },
    { lat: 48.6464914, lng: 21.6197195 },
    { lat: 48.6483892, lng: 21.6189407 },
    { lat: 48.6500527, lng: 21.6183515 },
    { lat: 48.6507097, lng: 21.6232098 },
    { lat: 48.6501944, lng: 21.6251585 },
    { lat: 48.6490667, lng: 21.6268079 },
    { lat: 48.648897, lng: 21.6271399 },
    { lat: 48.6492142, lng: 21.6289221 },
    { lat: 48.6484269, lng: 21.6297856 },
    { lat: 48.6485918, lng: 21.630294 },
    { lat: 48.6488056, lng: 21.6313537 },
    { lat: 48.6490013, lng: 21.6326063 },
    { lat: 48.6489324, lng: 21.632638 },
    { lat: 48.6498432, lng: 21.638598 },
    { lat: 48.6500699, lng: 21.6393538 },
    { lat: 48.6505052, lng: 21.6391848 },
    { lat: 48.6514563, lng: 21.6436413 },
    { lat: 48.6516147, lng: 21.6438359 },
    { lat: 48.652427, lng: 21.648038 },
    { lat: 48.6527116, lng: 21.6507299 },
    { lat: 48.6530763, lng: 21.6533941 },
    { lat: 48.6531748, lng: 21.6538708 },
    { lat: 48.6534431, lng: 21.6558948 },
    { lat: 48.6541544, lng: 21.6558175 },
    { lat: 48.6575367, lng: 21.6554531 },
    { lat: 48.6583931, lng: 21.6609531 },
    { lat: 48.6584808, lng: 21.6609533 },
    { lat: 48.6585267, lng: 21.6611455 },
    { lat: 48.6585904, lng: 21.6611757 },
    { lat: 48.6585962, lng: 21.6611782 },
    { lat: 48.6589722, lng: 21.6612721 },
    { lat: 48.6613364, lng: 21.6618522 },
    { lat: 48.6611301, lng: 21.6585178 },
    { lat: 48.6610299, lng: 21.6576503 },
    { lat: 48.6608987, lng: 21.6553366 },
    { lat: 48.6608941, lng: 21.6532078 },
    { lat: 48.6608196, lng: 21.6518448 },
    { lat: 48.6608345, lng: 21.6508331 },
    { lat: 48.6609233, lng: 21.6496071 },
    { lat: 48.6609202, lng: 21.648347 },
    { lat: 48.6607948, lng: 21.6460038 },
    { lat: 48.6603712, lng: 21.6423443 },
    { lat: 48.6615246, lng: 21.64215 },
    { lat: 48.6611717, lng: 21.640008 },
    { lat: 48.6607599, lng: 21.6368709 },
    { lat: 48.6606615, lng: 21.6357927 },
    { lat: 48.660751, lng: 21.6356091 },
    { lat: 48.6609041, lng: 21.6355258 },
    { lat: 48.6612671, lng: 21.6356784 },
    { lat: 48.6614199, lng: 21.6356709 },
    { lat: 48.6616724, lng: 21.6354802 },
    { lat: 48.6615487, lng: 21.6342644 },
    { lat: 48.6614452, lng: 21.6325967 },
    { lat: 48.6616581, lng: 21.6321608 },
    { lat: 48.6624522, lng: 21.6299556 },
    { lat: 48.6636179, lng: 21.6254493 },
    { lat: 48.6640259, lng: 21.6245426 },
    { lat: 48.6643825, lng: 21.623895 },
    { lat: 48.6647038, lng: 21.6230237 },
    { lat: 48.6648975, lng: 21.6226622 },
    { lat: 48.665739, lng: 21.6217291 },
    { lat: 48.6669886, lng: 21.6198105 },
    { lat: 48.6677734, lng: 21.6187385 },
    { lat: 48.6694216, lng: 21.6173195 },
    { lat: 48.6700247, lng: 21.6169954 },
    { lat: 48.6721837, lng: 21.6160565 },
    { lat: 48.671908, lng: 21.6132225 },
    { lat: 48.6724901, lng: 21.6134419 },
    { lat: 48.6723591, lng: 21.611802 },
    { lat: 48.6723907, lng: 21.6116494 },
    { lat: 48.672936, lng: 21.6102598 },
    { lat: 48.6732071, lng: 21.609809 },
    { lat: 48.6736474, lng: 21.6088138 },
    { lat: 48.6737835, lng: 21.6087759 },
    { lat: 48.6740371, lng: 21.6074173 },
    { lat: 48.6741028, lng: 21.6067488 },
    { lat: 48.6741046, lng: 21.6039915 },
    { lat: 48.6742487, lng: 21.6033094 },
    { lat: 48.6745494, lng: 21.6023152 },
    { lat: 48.6750057, lng: 21.6004448 },
    { lat: 48.6740756, lng: 21.5954613 },
    { lat: 48.6738766, lng: 21.5949902 },
    { lat: 48.6740197, lng: 21.5947119 },
    { lat: 48.6742623, lng: 21.5946601 },
    { lat: 48.6743692, lng: 21.5945386 },
    { lat: 48.6744273, lng: 21.594364 },
    { lat: 48.674546, lng: 21.594344 },
    { lat: 48.6745768, lng: 21.5942399 },
    { lat: 48.6745375, lng: 21.5941231 },
    { lat: 48.6746627, lng: 21.5940147 },
    { lat: 48.67455, lng: 21.5937207 },
    { lat: 48.6746049, lng: 21.5935492 },
    { lat: 48.6747143, lng: 21.593367 },
    { lat: 48.6747713, lng: 21.5931452 },
    { lat: 48.6749109, lng: 21.5929504 },
    { lat: 48.6750655, lng: 21.5929651 },
    { lat: 48.6751037, lng: 21.5928829 },
    { lat: 48.6750761, lng: 21.5926636 },
    { lat: 48.675139, lng: 21.5925759 },
    { lat: 48.675112, lng: 21.5922608 },
    { lat: 48.6752134, lng: 21.5921016 },
    { lat: 48.6751727, lng: 21.5918108 },
    { lat: 48.6752383, lng: 21.5915841 },
    { lat: 48.6752874, lng: 21.591048 },
    { lat: 48.6755205, lng: 21.5908526 },
    { lat: 48.6754839, lng: 21.5904277 },
    { lat: 48.6755517, lng: 21.5902828 },
    { lat: 48.6756446, lng: 21.5901814 },
    { lat: 48.6757087, lng: 21.5902973 },
    { lat: 48.6757746, lng: 21.5902429 },
    { lat: 48.6759569, lng: 21.5899268 },
    { lat: 48.6758226, lng: 21.589468 },
    { lat: 48.6758326, lng: 21.5893427 },
    { lat: 48.6759628, lng: 21.5890517 },
    { lat: 48.6760095, lng: 21.5890359 },
    { lat: 48.6759635, lng: 21.5889175 },
    { lat: 48.6761805, lng: 21.5883913 },
    { lat: 48.676151, lng: 21.5881037 },
    { lat: 48.6762218, lng: 21.5879806 },
    { lat: 48.6762344, lng: 21.5877053 },
    { lat: 48.6764026, lng: 21.5876426 },
    { lat: 48.6764331, lng: 21.5875694 },
    { lat: 48.6763979, lng: 21.5874752 },
    { lat: 48.6764246, lng: 21.5872998 },
    { lat: 48.6763378, lng: 21.5872135 },
    { lat: 48.6763332, lng: 21.5871333 },
    { lat: 48.6764123, lng: 21.5870616 },
    { lat: 48.6763299, lng: 21.5867728 },
    { lat: 48.6763921, lng: 21.5867051 },
    { lat: 48.6763971, lng: 21.5865628 },
    { lat: 48.6764762, lng: 21.586458 },
    { lat: 48.6765637, lng: 21.5864521 },
    { lat: 48.676614, lng: 21.5863516 },
    { lat: 48.6765989, lng: 21.5858563 },
    { lat: 48.6768125, lng: 21.5856885 },
    { lat: 48.6768132, lng: 21.5855125 },
    { lat: 48.6768845, lng: 21.5856063 },
    { lat: 48.6769138, lng: 21.5855673 },
    { lat: 48.6768662, lng: 21.5853571 },
    { lat: 48.6768962, lng: 21.5852249 },
    { lat: 48.6767677, lng: 21.5850594 },
    { lat: 48.6768307, lng: 21.5848919 },
    { lat: 48.6769342, lng: 21.5848166 },
    { lat: 48.6768662, lng: 21.5847213 },
    { lat: 48.6772392, lng: 21.584299 },
    { lat: 48.6773228, lng: 21.5842712 },
    { lat: 48.6773832, lng: 21.5840537 },
    { lat: 48.677462, lng: 21.5839721 },
    { lat: 48.6774782, lng: 21.5835933 },
    { lat: 48.6773972, lng: 21.5834298 },
    { lat: 48.6775194, lng: 21.5831791 },
    { lat: 48.6774707, lng: 21.5830484 },
    { lat: 48.6774891, lng: 21.5829817 },
    { lat: 48.6776209, lng: 21.5829804 },
    { lat: 48.6776243, lng: 21.5829022 },
    { lat: 48.6775435, lng: 21.5827938 },
    { lat: 48.677578, lng: 21.5827374 },
    { lat: 48.6777241, lng: 21.5827352 },
    { lat: 48.677758, lng: 21.582383 },
    { lat: 48.6779302, lng: 21.5824197 },
    { lat: 48.6779674, lng: 21.5822639 },
    { lat: 48.6781006, lng: 21.5823141 },
    { lat: 48.6780753, lng: 21.5821783 },
    { lat: 48.6782837, lng: 21.5819082 },
    { lat: 48.6783593, lng: 21.5819148 },
    { lat: 48.678372, lng: 21.581763 },
    { lat: 48.6784789, lng: 21.5817647 },
    { lat: 48.6785604, lng: 21.5815854 },
    { lat: 48.6786991, lng: 21.5814725 },
    { lat: 48.6787272, lng: 21.5812724 },
    { lat: 48.6788066, lng: 21.5812089 },
    { lat: 48.6788563, lng: 21.5810238 },
    { lat: 48.6789801, lng: 21.5810251 },
    { lat: 48.6789748, lng: 21.5808681 },
    { lat: 48.6790524, lng: 21.5808282 },
    { lat: 48.67898, lng: 21.5806609 },
    { lat: 48.6790299, lng: 21.5805473 },
    { lat: 48.6790143, lng: 21.5804807 },
    { lat: 48.6790375, lng: 21.5804233 },
    { lat: 48.6790997, lng: 21.5804314 },
    { lat: 48.679099, lng: 21.5803325 },
    { lat: 48.6790106, lng: 21.5802242 },
    { lat: 48.6791406, lng: 21.5801664 },
    { lat: 48.6791921, lng: 21.580036 },
    { lat: 48.6791734, lng: 21.5798922 },
    { lat: 48.679298, lng: 21.5795268 },
    { lat: 48.6794392, lng: 21.5794989 },
    { lat: 48.6795072, lng: 21.579361 },
    { lat: 48.6795723, lng: 21.5789174 },
    { lat: 48.6796513, lng: 21.5787992 },
    { lat: 48.6797388, lng: 21.5783891 },
    { lat: 48.6796812, lng: 21.5783048 },
    { lat: 48.6796026, lng: 21.5775889 },
    { lat: 48.6797708, lng: 21.5769717 },
    { lat: 48.6799416, lng: 21.5767229 },
    { lat: 48.6798766, lng: 21.5765772 },
    { lat: 48.6800754, lng: 21.5762835 },
    { lat: 48.6800894, lng: 21.5760625 },
    { lat: 48.6802363, lng: 21.5758794 },
    { lat: 48.6804305, lng: 21.5754229 },
    { lat: 48.6805349, lng: 21.5754222 },
    { lat: 48.6805965, lng: 21.5753225 },
    { lat: 48.680596, lng: 21.5751883 },
    { lat: 48.6806519, lng: 21.5751391 },
    { lat: 48.6807133, lng: 21.5748824 },
    { lat: 48.6809857, lng: 21.5747673 },
    { lat: 48.6810133, lng: 21.5745829 },
    { lat: 48.6812569, lng: 21.5741656 },
    { lat: 48.681377, lng: 21.5742348 },
    { lat: 48.6814448, lng: 21.5740069 },
    { lat: 48.6815518, lng: 21.5739704 },
    { lat: 48.6822467, lng: 21.5731111 },
    { lat: 48.6822652, lng: 21.5729732 },
    { lat: 48.6824289, lng: 21.5727497 },
    { lat: 48.6825127, lng: 21.5726931 },
    { lat: 48.6825352, lng: 21.5727721 },
    { lat: 48.6826873, lng: 21.5726979 },
    { lat: 48.6827818, lng: 21.5725796 },
    { lat: 48.6828573, lng: 21.5723391 },
    { lat: 48.6830533, lng: 21.5722321 },
    { lat: 48.6831842, lng: 21.571842 },
    { lat: 48.6832328, lng: 21.5718506 },
    { lat: 48.6834338, lng: 21.5716379 },
    { lat: 48.6835355, lng: 21.5716556 },
    { lat: 48.683635, lng: 21.5713896 },
    { lat: 48.6837858, lng: 21.5713101 },
    { lat: 48.6838257, lng: 21.5712103 },
    { lat: 48.6839145, lng: 21.5712513 },
    { lat: 48.6840231, lng: 21.5711457 },
    { lat: 48.6840743, lng: 21.5710067 },
    { lat: 48.6840728, lng: 21.5706336 },
    { lat: 48.684117, lng: 21.5704446 },
    { lat: 48.6844215, lng: 21.5701896 },
    { lat: 48.6844439, lng: 21.5700731 },
    { lat: 48.684392, lng: 21.5697457 },
    { lat: 48.6846608, lng: 21.5693029 },
    { lat: 48.6848763, lng: 21.5691636 },
    { lat: 48.6851445, lng: 21.568739 },
    { lat: 48.6856603, lng: 21.568594 },
    { lat: 48.6859643, lng: 21.568682 },
    { lat: 48.6861153, lng: 21.5683753 },
    { lat: 48.6866061, lng: 21.5678633 },
    { lat: 48.686938, lng: 21.567624 },
    { lat: 48.687338, lng: 21.5674466 },
    { lat: 48.6876653, lng: 21.5671616 },
    { lat: 48.6889044, lng: 21.566615 },
    { lat: 48.6899183, lng: 21.5659809 },
    { lat: 48.6904123, lng: 21.5658555 },
    { lat: 48.6912367, lng: 21.5655274 },
    { lat: 48.6915374, lng: 21.5655379 },
    { lat: 48.6919784, lng: 21.565256 },
    { lat: 48.6923417, lng: 21.5644551 },
    { lat: 48.6926414, lng: 21.5639878 },
    { lat: 48.6927925, lng: 21.563562 },
    { lat: 48.6930611, lng: 21.5631131 },
    { lat: 48.6932657, lng: 21.5623491 },
    { lat: 48.693426, lng: 21.5619331 },
    { lat: 48.6935374, lng: 21.559986 },
    { lat: 48.6936994, lng: 21.5591428 },
    { lat: 48.6938452, lng: 21.5579813 },
    { lat: 48.6940925, lng: 21.557529 },
    { lat: 48.6943157, lng: 21.5566687 },
    { lat: 48.6946491, lng: 21.5566002 },
    { lat: 48.6948202, lng: 21.5560075 },
    { lat: 48.694656, lng: 21.5556559 },
    { lat: 48.6939459, lng: 21.5549008 },
    { lat: 48.6936154, lng: 21.5544268 },
  ],
  Plechotice: [
    { lat: 48.6384431, lng: 21.6128897 },
    { lat: 48.637871, lng: 21.6127527 },
    { lat: 48.637723, lng: 21.6130488 },
    { lat: 48.637517, lng: 21.6132117 },
    { lat: 48.6373885, lng: 21.613646 },
    { lat: 48.6372969, lng: 21.6136333 },
    { lat: 48.6370041, lng: 21.6128532 },
    { lat: 48.6366719, lng: 21.612848 },
    { lat: 48.6365375, lng: 21.6129472 },
    { lat: 48.6365432, lng: 21.6130664 },
    { lat: 48.6364635, lng: 21.6130341 },
    { lat: 48.636349, lng: 21.6132078 },
    { lat: 48.636143, lng: 21.6133262 },
    { lat: 48.6361173, lng: 21.613209 },
    { lat: 48.6360218, lng: 21.6130984 },
    { lat: 48.636069, lng: 21.6127265 },
    { lat: 48.6358787, lng: 21.6126848 },
    { lat: 48.6348017, lng: 21.6127958 },
    { lat: 48.6334883, lng: 21.6134693 },
    { lat: 48.6330734, lng: 21.6134461 },
    { lat: 48.6328172, lng: 21.6133304 },
    { lat: 48.6319554, lng: 21.6131353 },
    { lat: 48.631702, lng: 21.6130426 },
    { lat: 48.6314817, lng: 21.6128122 },
    { lat: 48.6313819, lng: 21.6127851 },
    { lat: 48.629349, lng: 21.6132416 },
    { lat: 48.6290107, lng: 21.6132925 },
    { lat: 48.6285715, lng: 21.6131948 },
    { lat: 48.6243583, lng: 21.6135807 },
    { lat: 48.623498, lng: 21.6137203 },
    { lat: 48.6230043, lng: 21.6136865 },
    { lat: 48.622923, lng: 21.6135177 },
    { lat: 48.622782, lng: 21.6135854 },
    { lat: 48.6227812, lng: 21.6136743 },
    { lat: 48.6226931, lng: 21.6136266 },
    { lat: 48.6225676, lng: 21.6137263 },
    { lat: 48.6226056, lng: 21.6138729 },
    { lat: 48.6225703, lng: 21.6140195 },
    { lat: 48.6224702, lng: 21.6141397 },
    { lat: 48.6223338, lng: 21.6139342 },
    { lat: 48.6222241, lng: 21.6139557 },
    { lat: 48.6222088, lng: 21.614052 },
    { lat: 48.6222625, lng: 21.6142414 },
    { lat: 48.6224168, lng: 21.6142899 },
    { lat: 48.6224617, lng: 21.6144012 },
    { lat: 48.6223346, lng: 21.6145284 },
    { lat: 48.6221924, lng: 21.6145524 },
    { lat: 48.6220853, lng: 21.6145008 },
    { lat: 48.6220697, lng: 21.6144311 },
    { lat: 48.6221833, lng: 21.6142261 },
    { lat: 48.6221151, lng: 21.6141216 },
    { lat: 48.6221076, lng: 21.6139738 },
    { lat: 48.6220647, lng: 21.6139658 },
    { lat: 48.6220721, lng: 21.6138672 },
    { lat: 48.6219952, lng: 21.6138561 },
    { lat: 48.6218946, lng: 21.6139606 },
    { lat: 48.6217542, lng: 21.6138874 },
    { lat: 48.6216408, lng: 21.6140158 },
    { lat: 48.6216123, lng: 21.6141282 },
    { lat: 48.6216834, lng: 21.6142165 },
    { lat: 48.62151, lng: 21.6144914 },
    { lat: 48.6214659, lng: 21.6143214 },
    { lat: 48.6213866, lng: 21.614262 },
    { lat: 48.6213644, lng: 21.6144811 },
    { lat: 48.6212541, lng: 21.6144383 },
    { lat: 48.6212383, lng: 21.6142679 },
    { lat: 48.6210838, lng: 21.6141386 },
    { lat: 48.6210398, lng: 21.6139326 },
    { lat: 48.620921, lng: 21.6139491 },
    { lat: 48.6208668, lng: 21.6140595 },
    { lat: 48.6208603, lng: 21.6143526 },
    { lat: 48.621039, lng: 21.6143865 },
    { lat: 48.6211191, lng: 21.6146776 },
    { lat: 48.6210524, lng: 21.6147494 },
    { lat: 48.6207574, lng: 21.6147813 },
    { lat: 48.620821, lng: 21.6144398 },
    { lat: 48.6204718, lng: 21.6142613 },
    { lat: 48.6204576, lng: 21.6141577 },
    { lat: 48.6204166, lng: 21.6141428 },
    { lat: 48.6203393, lng: 21.614396 },
    { lat: 48.6205053, lng: 21.6145771 },
    { lat: 48.6205195, lng: 21.614807 },
    { lat: 48.6203674, lng: 21.6147971 },
    { lat: 48.6203027, lng: 21.6150231 },
    { lat: 48.6199322, lng: 21.6151707 },
    { lat: 48.6199061, lng: 21.6152837 },
    { lat: 48.6197828, lng: 21.6153349 },
    { lat: 48.6196123, lng: 21.6151999 },
    { lat: 48.6195793, lng: 21.6149427 },
    { lat: 48.6194402, lng: 21.6149188 },
    { lat: 48.6194522, lng: 21.6147833 },
    { lat: 48.6192599, lng: 21.6147432 },
    { lat: 48.6191573, lng: 21.6149392 },
    { lat: 48.619034, lng: 21.6147511 },
    { lat: 48.6189647, lng: 21.614727 },
    { lat: 48.618842, lng: 21.6148088 },
    { lat: 48.6188058, lng: 21.6149636 },
    { lat: 48.6188366, lng: 21.6152252 },
    { lat: 48.6187836, lng: 21.6151738 },
    { lat: 48.6186814, lng: 21.6148168 },
    { lat: 48.6184319, lng: 21.6147476 },
    { lat: 48.6183174, lng: 21.614842 },
    { lat: 48.6181802, lng: 21.614557 },
    { lat: 48.618106, lng: 21.6145592 },
    { lat: 48.6180127, lng: 21.6148898 },
    { lat: 48.6178604, lng: 21.6149912 },
    { lat: 48.6177116, lng: 21.6149088 },
    { lat: 48.6176985, lng: 21.6148791 },
    { lat: 48.6179401, lng: 21.615916 },
    { lat: 48.6181842, lng: 21.61745 },
    { lat: 48.6183938, lng: 21.6181874 },
    { lat: 48.6186079, lng: 21.6184445 },
    { lat: 48.6192048, lng: 21.6214561 },
    { lat: 48.6180519, lng: 21.6212741 },
    { lat: 48.617397, lng: 21.6215886 },
    { lat: 48.6167238, lng: 21.6224718 },
    { lat: 48.6166623, lng: 21.6241456 },
    { lat: 48.6168142, lng: 21.6251975 },
    { lat: 48.61721, lng: 21.626325 },
    { lat: 48.6173472, lng: 21.6270912 },
    { lat: 48.6172626, lng: 21.6279336 },
    { lat: 48.6170731, lng: 21.6286909 },
    { lat: 48.6167324, lng: 21.6289816 },
    { lat: 48.616458, lng: 21.6291098 },
    { lat: 48.6159118, lng: 21.6292165 },
    { lat: 48.6157371, lng: 21.6298878 },
    { lat: 48.6157487, lng: 21.6305528 },
    { lat: 48.6145352, lng: 21.6312178 },
    { lat: 48.6140939, lng: 21.6317409 },
    { lat: 48.6141818, lng: 21.6328448 },
    { lat: 48.6141644, lng: 21.6330653 },
    { lat: 48.6140179, lng: 21.6331844 },
    { lat: 48.6135331, lng: 21.6332228 },
    { lat: 48.611011, lng: 21.6347928 },
    { lat: 48.6114463, lng: 21.6382005 },
    { lat: 48.6109389, lng: 21.638364 },
    { lat: 48.6111788, lng: 21.640243 },
    { lat: 48.6113664, lng: 21.6437406 },
    { lat: 48.611561, lng: 21.6452057 },
    { lat: 48.6112963, lng: 21.6453187 },
    { lat: 48.6108775, lng: 21.6452657 },
    { lat: 48.6111979, lng: 21.6478632 },
    { lat: 48.6114393, lng: 21.649154 },
    { lat: 48.6108763, lng: 21.6492995 },
    { lat: 48.6108151, lng: 21.6541058 },
    { lat: 48.6108594, lng: 21.6564519 },
    { lat: 48.6104284, lng: 21.6566591 },
    { lat: 48.6116121, lng: 21.658541 },
    { lat: 48.6117554, lng: 21.6604375 },
    { lat: 48.6127051, lng: 21.6729631 },
    { lat: 48.6135625, lng: 21.6725531 },
    { lat: 48.614038, lng: 21.6723688 },
    { lat: 48.6146434, lng: 21.6723763 },
    { lat: 48.6167208, lng: 21.6716539 },
    { lat: 48.6166578, lng: 21.6712393 },
    { lat: 48.6177104, lng: 21.6708456 },
    { lat: 48.6174078, lng: 21.6694111 },
    { lat: 48.618131, lng: 21.6691985 },
    { lat: 48.6201254, lng: 21.6683204 },
    { lat: 48.6201429, lng: 21.6679271 },
    { lat: 48.6198969, lng: 21.6654385 },
    { lat: 48.6228271, lng: 21.6646309 },
    { lat: 48.6227192, lng: 21.663937 },
    { lat: 48.6255405, lng: 21.6628558 },
    { lat: 48.6257038, lng: 21.6636337 },
    { lat: 48.6260942, lng: 21.6635137 },
    { lat: 48.6259396, lng: 21.6627149 },
    { lat: 48.625829, lng: 21.6621752 },
    { lat: 48.6263347, lng: 21.6619071 },
    { lat: 48.6262102, lng: 21.6600327 },
    { lat: 48.626096, lng: 21.6555493 },
    { lat: 48.6274135, lng: 21.6554651 },
    { lat: 48.627012, lng: 21.6528945 },
    { lat: 48.6269141, lng: 21.6524806 },
    { lat: 48.6270566, lng: 21.6524329 },
    { lat: 48.6289074, lng: 21.6523476 },
    { lat: 48.6298808, lng: 21.652394 },
    { lat: 48.6326115, lng: 21.6516129 },
    { lat: 48.6338222, lng: 21.6513745 },
    { lat: 48.6343161, lng: 21.6511995 },
    { lat: 48.6350829, lng: 21.6507303 },
    { lat: 48.6355972, lng: 21.650496 },
    { lat: 48.6371853, lng: 21.6501417 },
    { lat: 48.6415721, lng: 21.6494483 },
    { lat: 48.6422271, lng: 21.6492866 },
    { lat: 48.6427757, lng: 21.6493938 },
    { lat: 48.6432358, lng: 21.6512321 },
    { lat: 48.6469873, lng: 21.6491123 },
    { lat: 48.6477066, lng: 21.6535394 },
    { lat: 48.6486785, lng: 21.6532838 },
    { lat: 48.6488328, lng: 21.653989 },
    { lat: 48.6491862, lng: 21.6538705 },
    { lat: 48.6499002, lng: 21.656799 },
    { lat: 48.6534431, lng: 21.6558948 },
    { lat: 48.6531748, lng: 21.6538708 },
    { lat: 48.6530763, lng: 21.6533941 },
    { lat: 48.6527116, lng: 21.6507299 },
    { lat: 48.652427, lng: 21.648038 },
    { lat: 48.6516147, lng: 21.6438359 },
    { lat: 48.6514563, lng: 21.6436413 },
    { lat: 48.6505052, lng: 21.6391848 },
    { lat: 48.6500699, lng: 21.6393538 },
    { lat: 48.6498432, lng: 21.638598 },
    { lat: 48.6489324, lng: 21.632638 },
    { lat: 48.6490013, lng: 21.6326063 },
    { lat: 48.6488056, lng: 21.6313537 },
    { lat: 48.6485918, lng: 21.630294 },
    { lat: 48.6484269, lng: 21.6297856 },
    { lat: 48.6492142, lng: 21.6289221 },
    { lat: 48.648897, lng: 21.6271399 },
    { lat: 48.6490667, lng: 21.6268079 },
    { lat: 48.6501944, lng: 21.6251585 },
    { lat: 48.6507097, lng: 21.6232098 },
    { lat: 48.6500527, lng: 21.6183515 },
    { lat: 48.6483892, lng: 21.6189407 },
    { lat: 48.6464914, lng: 21.6197195 },
    { lat: 48.6455436, lng: 21.6199901 },
    { lat: 48.6450102, lng: 21.6165887 },
    { lat: 48.6422616, lng: 21.617204 },
    { lat: 48.6420099, lng: 21.614955 },
    { lat: 48.6416439, lng: 21.6151476 },
    { lat: 48.6397568, lng: 21.6156994 },
    { lat: 48.6393515, lng: 21.6157326 },
    { lat: 48.6388244, lng: 21.6154721 },
    { lat: 48.6384134, lng: 21.6150151 },
    { lat: 48.6384431, lng: 21.6128897 },
  ],
  Egreš: [
    { lat: 48.6290107, lng: 21.6132925 },
    { lat: 48.6290161, lng: 21.6132421 },
    { lat: 48.6290427, lng: 21.6128676 },
    { lat: 48.6291383, lng: 21.6126193 },
    { lat: 48.6292325, lng: 21.6125315 },
    { lat: 48.6291052, lng: 21.611718 },
    { lat: 48.629099, lng: 21.6116215 },
    { lat: 48.6290904, lng: 21.6114753 },
    { lat: 48.6290265, lng: 21.6109941 },
    { lat: 48.6289184, lng: 21.61055 },
    { lat: 48.6289093, lng: 21.610021 },
    { lat: 48.6290497, lng: 21.6091214 },
    { lat: 48.629054, lng: 21.6090488 },
    { lat: 48.6285824, lng: 21.6076149 },
    { lat: 48.6279814, lng: 21.6055552 },
    { lat: 48.6277201, lng: 21.6042981 },
    { lat: 48.6272419, lng: 21.6014767 },
    { lat: 48.6276032, lng: 21.6014053 },
    { lat: 48.6273172, lng: 21.5989007 },
    { lat: 48.6272855, lng: 21.5980904 },
    { lat: 48.6273128, lng: 21.5977418 },
    { lat: 48.628089, lng: 21.5931133 },
    { lat: 48.6275336, lng: 21.5934137 },
    { lat: 48.6267942, lng: 21.5928491 },
    { lat: 48.6249117, lng: 21.5916373 },
    { lat: 48.6245377, lng: 21.5912991 },
    { lat: 48.6232769, lng: 21.589315 },
    { lat: 48.6230964, lng: 21.5893785 },
    { lat: 48.6226113, lng: 21.5887376 },
    { lat: 48.6224434, lng: 21.5888856 },
    { lat: 48.6219745, lng: 21.5883348 },
    { lat: 48.6216472, lng: 21.588301 },
    { lat: 48.621525, lng: 21.5881142 },
    { lat: 48.6214159, lng: 21.5882404 },
    { lat: 48.6212508, lng: 21.5881734 },
    { lat: 48.6212244, lng: 21.587956 },
    { lat: 48.6211254, lng: 21.58783 },
    { lat: 48.620936, lng: 21.587862 },
    { lat: 48.6208275, lng: 21.5878003 },
    { lat: 48.6201908, lng: 21.5863207 },
    { lat: 48.620057, lng: 21.585804 },
    { lat: 48.6197783, lng: 21.5857041 },
    { lat: 48.6195827, lng: 21.5857073 },
    { lat: 48.6194179, lng: 21.5855664 },
    { lat: 48.619267, lng: 21.5852037 },
    { lat: 48.6192038, lng: 21.5849524 },
    { lat: 48.6192026, lng: 21.5847163 },
    { lat: 48.6190853, lng: 21.584503 },
    { lat: 48.618593, lng: 21.5842038 },
    { lat: 48.6180373, lng: 21.5834495 },
    { lat: 48.6175669, lng: 21.582327 },
    { lat: 48.6169613, lng: 21.5818363 },
    { lat: 48.616369, lng: 21.5809809 },
    { lat: 48.6158664, lng: 21.5800729 },
    { lat: 48.6159683, lng: 21.5792204 },
    { lat: 48.615669, lng: 21.578822 },
    { lat: 48.615419, lng: 21.578474 },
    { lat: 48.615118, lng: 21.578111 },
    { lat: 48.614763, lng: 21.577883 },
    { lat: 48.61466, lng: 21.57702 },
    { lat: 48.614646, lng: 21.577036 },
    { lat: 48.6133266, lng: 21.5789114 },
    { lat: 48.6128193, lng: 21.579159 },
    { lat: 48.6106957, lng: 21.5826967 },
    { lat: 48.610137, lng: 21.5833518 },
    { lat: 48.6097712, lng: 21.5836778 },
    { lat: 48.6088126, lng: 21.584361 },
    { lat: 48.6078799, lng: 21.5848405 },
    { lat: 48.6072117, lng: 21.5848474 },
    { lat: 48.6070453, lng: 21.5849259 },
    { lat: 48.6065821, lng: 21.5852645 },
    { lat: 48.6038211, lng: 21.5869516 },
    { lat: 48.6010926, lng: 21.5885976 },
    { lat: 48.601013, lng: 21.5902885 },
    { lat: 48.6012, lng: 21.5927074 },
    { lat: 48.6011207, lng: 21.5934878 },
    { lat: 48.6011138, lng: 21.594399 },
    { lat: 48.6006802, lng: 21.5963616 },
    { lat: 48.6007789, lng: 21.5969028 },
    { lat: 48.6006178, lng: 21.5973073 },
    { lat: 48.6009914, lng: 21.5975871 },
    { lat: 48.6009977, lng: 21.5977697 },
    { lat: 48.6011545, lng: 21.5979044 },
    { lat: 48.6013227, lng: 21.5982457 },
    { lat: 48.6015047, lng: 21.5984371 },
    { lat: 48.6017298, lng: 21.5987967 },
    { lat: 48.6017507, lng: 21.5991388 },
    { lat: 48.6016877, lng: 21.5992221 },
    { lat: 48.6018112, lng: 21.5993717 },
    { lat: 48.6020571, lng: 21.5998755 },
    { lat: 48.6020517, lng: 21.6000891 },
    { lat: 48.6022283, lng: 21.6005258 },
    { lat: 48.6023363, lng: 21.6004838 },
    { lat: 48.6024251, lng: 21.6007657 },
    { lat: 48.6025237, lng: 21.6007943 },
    { lat: 48.6025518, lng: 21.6009461 },
    { lat: 48.6027657, lng: 21.6011948 },
    { lat: 48.6029243, lng: 21.6015196 },
    { lat: 48.6032582, lng: 21.6016633 },
    { lat: 48.6040335, lng: 21.6022724 },
    { lat: 48.6042594, lng: 21.6023391 },
    { lat: 48.6045229, lng: 21.6026299 },
    { lat: 48.6055041, lng: 21.6032345 },
    { lat: 48.6056495, lng: 21.6035673 },
    { lat: 48.6068396, lng: 21.6050809 },
    { lat: 48.6068158, lng: 21.6053177 },
    { lat: 48.6071515, lng: 21.6060178 },
    { lat: 48.6072877, lng: 21.6067476 },
    { lat: 48.607289, lng: 21.6071923 },
    { lat: 48.6077632, lng: 21.6074146 },
    { lat: 48.6083391, lng: 21.6085318 },
    { lat: 48.6093961, lng: 21.6096814 },
    { lat: 48.609969, lng: 21.6102107 },
    { lat: 48.6101548, lng: 21.6104585 },
    { lat: 48.6105599, lng: 21.6112257 },
    { lat: 48.6108386, lng: 21.6112951 },
    { lat: 48.6110021, lng: 21.6116289 },
    { lat: 48.6111137, lng: 21.6114359 },
    { lat: 48.6113008, lng: 21.611493 },
    { lat: 48.6113068, lng: 21.6118272 },
    { lat: 48.6114765, lng: 21.6116131 },
    { lat: 48.611603, lng: 21.6115482 },
    { lat: 48.6116618, lng: 21.6114001 },
    { lat: 48.6117805, lng: 21.6113428 },
    { lat: 48.6119572, lng: 21.6116601 },
    { lat: 48.6120844, lng: 21.6117075 },
    { lat: 48.6121391, lng: 21.6119348 },
    { lat: 48.6123261, lng: 21.6123073 },
    { lat: 48.612486, lng: 21.6125144 },
    { lat: 48.6126374, lng: 21.6125301 },
    { lat: 48.612682, lng: 21.612683 },
    { lat: 48.612619, lng: 21.6130056 },
    { lat: 48.612436, lng: 21.6131392 },
    { lat: 48.6124036, lng: 21.6133122 },
    { lat: 48.6124994, lng: 21.6134761 },
    { lat: 48.6130612, lng: 21.6129641 },
    { lat: 48.6141467, lng: 21.6154181 },
    { lat: 48.6139936, lng: 21.6156966 },
    { lat: 48.614019, lng: 21.6157612 },
    { lat: 48.6141496, lng: 21.6157596 },
    { lat: 48.6141839, lng: 21.615984 },
    { lat: 48.614029, lng: 21.6162384 },
    { lat: 48.6142268, lng: 21.6165185 },
    { lat: 48.6142691, lng: 21.6166556 },
    { lat: 48.614193, lng: 21.6167948 },
    { lat: 48.6140992, lng: 21.6167425 },
    { lat: 48.6137838, lng: 21.6168058 },
    { lat: 48.6137574, lng: 21.6170347 },
    { lat: 48.6140052, lng: 21.6171189 },
    { lat: 48.6140151, lng: 21.6173576 },
    { lat: 48.6138286, lng: 21.6173641 },
    { lat: 48.6137673, lng: 21.61779 },
    { lat: 48.6135405, lng: 21.618096 },
    { lat: 48.6135375, lng: 21.6182231 },
    { lat: 48.6137761, lng: 21.6187993 },
    { lat: 48.6138015, lng: 21.6189833 },
    { lat: 48.6137066, lng: 21.6193357 },
    { lat: 48.6141327, lng: 21.6189126 },
    { lat: 48.6142442, lng: 21.6186321 },
    { lat: 48.6144217, lng: 21.6185458 },
    { lat: 48.6147599, lng: 21.6185234 },
    { lat: 48.614847, lng: 21.6183845 },
    { lat: 48.6148899, lng: 21.6181884 },
    { lat: 48.6150453, lng: 21.6179421 },
    { lat: 48.614996, lng: 21.6177887 },
    { lat: 48.614863, lng: 21.6176627 },
    { lat: 48.6148384, lng: 21.6175501 },
    { lat: 48.6150018, lng: 21.616947 },
    { lat: 48.6151513, lng: 21.6166606 },
    { lat: 48.6151515, lng: 21.6165496 },
    { lat: 48.6149026, lng: 21.6161795 },
    { lat: 48.6149266, lng: 21.6160692 },
    { lat: 48.6151223, lng: 21.616103 },
    { lat: 48.6154922, lng: 21.6162965 },
    { lat: 48.6156536, lng: 21.6161138 },
    { lat: 48.6157096, lng: 21.6159421 },
    { lat: 48.6157803, lng: 21.615938 },
    { lat: 48.6159291, lng: 21.6157468 },
    { lat: 48.6160436, lng: 21.6157766 },
    { lat: 48.6161596, lng: 21.6156156 },
    { lat: 48.6162167, lng: 21.61565 },
    { lat: 48.6163238, lng: 21.6155761 },
    { lat: 48.6163251, lng: 21.6153775 },
    { lat: 48.6162563, lng: 21.6152944 },
    { lat: 48.6162049, lng: 21.6149823 },
    { lat: 48.6162604, lng: 21.6148738 },
    { lat: 48.6163274, lng: 21.6148931 },
    { lat: 48.6163808, lng: 21.615118 },
    { lat: 48.6164939, lng: 21.6147346 },
    { lat: 48.6169419, lng: 21.6148605 },
    { lat: 48.617039, lng: 21.6145877 },
    { lat: 48.6171401, lng: 21.614545 },
    { lat: 48.617665, lng: 21.6148417 },
    { lat: 48.6176985, lng: 21.6148791 },
    { lat: 48.6177116, lng: 21.6149088 },
    { lat: 48.6178604, lng: 21.6149912 },
    { lat: 48.6180127, lng: 21.6148898 },
    { lat: 48.618106, lng: 21.6145592 },
    { lat: 48.6181802, lng: 21.614557 },
    { lat: 48.6183174, lng: 21.614842 },
    { lat: 48.6184319, lng: 21.6147476 },
    { lat: 48.6186814, lng: 21.6148168 },
    { lat: 48.6187836, lng: 21.6151738 },
    { lat: 48.6188366, lng: 21.6152252 },
    { lat: 48.6188058, lng: 21.6149636 },
    { lat: 48.618842, lng: 21.6148088 },
    { lat: 48.6189647, lng: 21.614727 },
    { lat: 48.619034, lng: 21.6147511 },
    { lat: 48.6191573, lng: 21.6149392 },
    { lat: 48.6192599, lng: 21.6147432 },
    { lat: 48.6194522, lng: 21.6147833 },
    { lat: 48.6194402, lng: 21.6149188 },
    { lat: 48.6195793, lng: 21.6149427 },
    { lat: 48.6196123, lng: 21.6151999 },
    { lat: 48.6197828, lng: 21.6153349 },
    { lat: 48.6199061, lng: 21.6152837 },
    { lat: 48.6199322, lng: 21.6151707 },
    { lat: 48.6203027, lng: 21.6150231 },
    { lat: 48.6203674, lng: 21.6147971 },
    { lat: 48.6205195, lng: 21.614807 },
    { lat: 48.6205053, lng: 21.6145771 },
    { lat: 48.6203393, lng: 21.614396 },
    { lat: 48.6204166, lng: 21.6141428 },
    { lat: 48.6204576, lng: 21.6141577 },
    { lat: 48.6204718, lng: 21.6142613 },
    { lat: 48.620821, lng: 21.6144398 },
    { lat: 48.6207574, lng: 21.6147813 },
    { lat: 48.6210524, lng: 21.6147494 },
    { lat: 48.6211191, lng: 21.6146776 },
    { lat: 48.621039, lng: 21.6143865 },
    { lat: 48.6208603, lng: 21.6143526 },
    { lat: 48.6208668, lng: 21.6140595 },
    { lat: 48.620921, lng: 21.6139491 },
    { lat: 48.6210398, lng: 21.6139326 },
    { lat: 48.6210838, lng: 21.6141386 },
    { lat: 48.6212383, lng: 21.6142679 },
    { lat: 48.6212541, lng: 21.6144383 },
    { lat: 48.6213644, lng: 21.6144811 },
    { lat: 48.6213866, lng: 21.614262 },
    { lat: 48.6214659, lng: 21.6143214 },
    { lat: 48.62151, lng: 21.6144914 },
    { lat: 48.6216834, lng: 21.6142165 },
    { lat: 48.6216123, lng: 21.6141282 },
    { lat: 48.6216408, lng: 21.6140158 },
    { lat: 48.6217542, lng: 21.6138874 },
    { lat: 48.6218946, lng: 21.6139606 },
    { lat: 48.6219952, lng: 21.6138561 },
    { lat: 48.6220721, lng: 21.6138672 },
    { lat: 48.6220647, lng: 21.6139658 },
    { lat: 48.6221076, lng: 21.6139738 },
    { lat: 48.6221151, lng: 21.6141216 },
    { lat: 48.6221833, lng: 21.6142261 },
    { lat: 48.6220697, lng: 21.6144311 },
    { lat: 48.6220853, lng: 21.6145008 },
    { lat: 48.6221924, lng: 21.6145524 },
    { lat: 48.6223346, lng: 21.6145284 },
    { lat: 48.6224617, lng: 21.6144012 },
    { lat: 48.6224168, lng: 21.6142899 },
    { lat: 48.6222625, lng: 21.6142414 },
    { lat: 48.6222088, lng: 21.614052 },
    { lat: 48.6222241, lng: 21.6139557 },
    { lat: 48.6223338, lng: 21.6139342 },
    { lat: 48.6224702, lng: 21.6141397 },
    { lat: 48.6225703, lng: 21.6140195 },
    { lat: 48.6226056, lng: 21.6138729 },
    { lat: 48.6225676, lng: 21.6137263 },
    { lat: 48.6226931, lng: 21.6136266 },
    { lat: 48.6227812, lng: 21.6136743 },
    { lat: 48.622782, lng: 21.6135854 },
    { lat: 48.622923, lng: 21.6135177 },
    { lat: 48.6230043, lng: 21.6136865 },
    { lat: 48.623498, lng: 21.6137203 },
    { lat: 48.6243583, lng: 21.6135807 },
    { lat: 48.6285715, lng: 21.6131948 },
    { lat: 48.6290107, lng: 21.6132925 },
  ],
  Sečovce: [
    { lat: 48.7267109, lng: 21.609671 },
    { lat: 48.7245453, lng: 21.6061307 },
    { lat: 48.724502, lng: 21.6059489 },
    { lat: 48.7244726, lng: 21.6058218 },
    { lat: 48.7218914, lng: 21.6083654 },
    { lat: 48.7171602, lng: 21.6128476 },
    { lat: 48.7155204, lng: 21.6142889 },
    { lat: 48.7143255, lng: 21.6155031 },
    { lat: 48.70759, lng: 21.6217732 },
    { lat: 48.7068931, lng: 21.6226491 },
    { lat: 48.7067942, lng: 21.6226108 },
    { lat: 48.7066633, lng: 21.6228033 },
    { lat: 48.7067293, lng: 21.6230346 },
    { lat: 48.7063467, lng: 21.6238781 },
    { lat: 48.7056964, lng: 21.6283097 },
    { lat: 48.7054731, lng: 21.6283673 },
    { lat: 48.7023685, lng: 21.6280313 },
    { lat: 48.7021722, lng: 21.6277263 },
    { lat: 48.7022966, lng: 21.627103 },
    { lat: 48.7026937, lng: 21.6264901 },
    { lat: 48.7028972, lng: 21.6258788 },
    { lat: 48.7028783, lng: 21.6251993 },
    { lat: 48.7026723, lng: 21.6249456 },
    { lat: 48.7026318, lng: 21.6245378 },
    { lat: 48.7027707, lng: 21.6239541 },
    { lat: 48.7028081, lng: 21.6235175 },
    { lat: 48.7031903, lng: 21.6228953 },
    { lat: 48.7033462, lng: 21.6221053 },
    { lat: 48.7034362, lng: 21.6218584 },
    { lat: 48.7030001, lng: 21.6216328 },
    { lat: 48.7026804, lng: 21.6217097 },
    { lat: 48.7026437, lng: 21.6217277 },
    { lat: 48.7026911, lng: 21.62189 },
    { lat: 48.7026712, lng: 21.6220237 },
    { lat: 48.702571, lng: 21.6221063 },
    { lat: 48.7023624, lng: 21.6220942 },
    { lat: 48.7023101, lng: 21.6221887 },
    { lat: 48.7023656, lng: 21.6224897 },
    { lat: 48.7025465, lng: 21.6227633 },
    { lat: 48.7026709, lng: 21.6228266 },
    { lat: 48.7027279, lng: 21.6229794 },
    { lat: 48.702461, lng: 21.6234137 },
    { lat: 48.7022909, lng: 21.624258 },
    { lat: 48.702091, lng: 21.6248381 },
    { lat: 48.7021016, lng: 21.6253397 },
    { lat: 48.7019996, lng: 21.6254775 },
    { lat: 48.7019256, lng: 21.6257358 },
    { lat: 48.7019309, lng: 21.6258844 },
    { lat: 48.7020316, lng: 21.6260233 },
    { lat: 48.7020209, lng: 21.6263688 },
    { lat: 48.7019784, lng: 21.6265008 },
    { lat: 48.70186, lng: 21.6265763 },
    { lat: 48.7017541, lng: 21.626525 },
    { lat: 48.7014139, lng: 21.6259021 },
    { lat: 48.7012118, lng: 21.6258006 },
    { lat: 48.7005773, lng: 21.6268979 },
    { lat: 48.7005084, lng: 21.6277364 },
    { lat: 48.7006704, lng: 21.6283506 },
    { lat: 48.7006731, lng: 21.628568 },
    { lat: 48.7004856, lng: 21.6289503 },
    { lat: 48.7002698, lng: 21.6291182 },
    { lat: 48.6999952, lng: 21.6295633 },
    { lat: 48.6999052, lng: 21.6299316 },
    { lat: 48.70075, lng: 21.6300438 },
    { lat: 48.7007956, lng: 21.6301905 },
    { lat: 48.7008036, lng: 21.6306007 },
    { lat: 48.7007381, lng: 21.6308382 },
    { lat: 48.7002816, lng: 21.6315801 },
    { lat: 48.699995, lng: 21.6321973 },
    { lat: 48.7002241, lng: 21.6326718 },
    { lat: 48.6999323, lng: 21.6328633 },
    { lat: 48.6997905, lng: 21.6331473 },
    { lat: 48.6999069, lng: 21.6335727 },
    { lat: 48.6998637, lng: 21.6339209 },
    { lat: 48.6991674, lng: 21.6344115 },
    { lat: 48.6991136, lng: 21.6346257 },
    { lat: 48.6986219, lng: 21.633689 },
    { lat: 48.6983118, lng: 21.6336605 },
    { lat: 48.6980631, lng: 21.6342357 },
    { lat: 48.697034, lng: 21.6333917 },
    { lat: 48.6960869, lng: 21.6335629 },
    { lat: 48.6955448, lng: 21.6333293 },
    { lat: 48.6954258, lng: 21.6328118 },
    { lat: 48.6939518, lng: 21.6332136 },
    { lat: 48.6923039, lng: 21.6333482 },
    { lat: 48.6918753, lng: 21.6332727 },
    { lat: 48.6924772, lng: 21.6351795 },
    { lat: 48.6929065, lng: 21.6369559 },
    { lat: 48.6930988, lng: 21.6376097 },
    { lat: 48.6931803, lng: 21.6377442 },
    { lat: 48.6927846, lng: 21.6381235 },
    { lat: 48.6911423, lng: 21.6391935 },
    { lat: 48.6911033, lng: 21.6394997 },
    { lat: 48.691181, lng: 21.6396647 },
    { lat: 48.6911489, lng: 21.6400046 },
    { lat: 48.6911962, lng: 21.6402947 },
    { lat: 48.6914069, lng: 21.6406743 },
    { lat: 48.6897245, lng: 21.6411908 },
    { lat: 48.688248, lng: 21.6414692 },
    { lat: 48.6873664, lng: 21.6416016 },
    { lat: 48.6858629, lng: 21.6416371 },
    { lat: 48.6858253, lng: 21.641205 },
    { lat: 48.6843867, lng: 21.6411841 },
    { lat: 48.6843239, lng: 21.6404947 },
    { lat: 48.6829162, lng: 21.6402073 },
    { lat: 48.6829034, lng: 21.6399058 },
    { lat: 48.6818733, lng: 21.6398457 },
    { lat: 48.6818337, lng: 21.6393105 },
    { lat: 48.6799204, lng: 21.6395307 },
    { lat: 48.6784955, lng: 21.6400038 },
    { lat: 48.678739, lng: 21.6420172 },
    { lat: 48.6722411, lng: 21.6414786 },
    { lat: 48.666998, lng: 21.6414378 },
    { lat: 48.6669805, lng: 21.6415368 },
    { lat: 48.6669806, lng: 21.6415411 },
    { lat: 48.6657035, lng: 21.647095 },
    { lat: 48.6653699, lng: 21.6481745 },
    { lat: 48.6650621, lng: 21.6503604 },
    { lat: 48.6646124, lng: 21.6525335 },
    { lat: 48.6645362, lng: 21.6538203 },
    { lat: 48.6642829, lng: 21.6558572 },
    { lat: 48.6637344, lng: 21.6571522 },
    { lat: 48.6633406, lng: 21.6582585 },
    { lat: 48.6611592, lng: 21.6585145 },
    { lat: 48.6611301, lng: 21.6585178 },
    { lat: 48.6613364, lng: 21.6618522 },
    { lat: 48.6589722, lng: 21.6612721 },
    { lat: 48.6585962, lng: 21.6611782 },
    { lat: 48.6590133, lng: 21.6636298 },
    { lat: 48.6593324, lng: 21.663533 },
    { lat: 48.6596707, lng: 21.6651558 },
    { lat: 48.6599044, lng: 21.6655216 },
    { lat: 48.6602088, lng: 21.6668106 },
    { lat: 48.6607011, lng: 21.6665129 },
    { lat: 48.6612413, lng: 21.6679954 },
    { lat: 48.6616908, lng: 21.670111 },
    { lat: 48.6621233, lng: 21.6696758 },
    { lat: 48.6621162, lng: 21.6674831 },
    { lat: 48.6623119, lng: 21.6673147 },
    { lat: 48.6624796, lng: 21.6673583 },
    { lat: 48.6629376, lng: 21.6665791 },
    { lat: 48.6632564, lng: 21.6663481 },
    { lat: 48.6643137, lng: 21.6661277 },
    { lat: 48.6649441, lng: 21.6663948 },
    { lat: 48.6654772, lng: 21.6663427 },
    { lat: 48.6662372, lng: 21.6665294 },
    { lat: 48.6665266, lng: 21.6668602 },
    { lat: 48.6667686, lng: 21.6673214 },
    { lat: 48.6668556, lng: 21.6676996 },
    { lat: 48.6677921, lng: 21.6678818 },
    { lat: 48.6678605, lng: 21.6681588 },
    { lat: 48.6680951, lng: 21.6682257 },
    { lat: 48.6681988, lng: 21.6683545 },
    { lat: 48.6681896, lng: 21.6685099 },
    { lat: 48.6690051, lng: 21.6684658 },
    { lat: 48.6691662, lng: 21.6685885 },
    { lat: 48.6692303, lng: 21.6688369 },
    { lat: 48.6694619, lng: 21.6688313 },
    { lat: 48.6697316, lng: 21.6685728 },
    { lat: 48.6698684, lng: 21.6685152 },
    { lat: 48.6701269, lng: 21.6685001 },
    { lat: 48.6703541, lng: 21.6685828 },
    { lat: 48.6706462, lng: 21.6687714 },
    { lat: 48.6711841, lng: 21.6693957 },
    { lat: 48.6715591, lng: 21.6699454 },
    { lat: 48.6721604, lng: 21.669914 },
    { lat: 48.6721756, lng: 21.6697375 },
    { lat: 48.6728263, lng: 21.6697895 },
    { lat: 48.6729419, lng: 21.6701422 },
    { lat: 48.6729207, lng: 21.6702389 },
    { lat: 48.6731561, lng: 21.6704499 },
    { lat: 48.6735305, lng: 21.6706491 },
    { lat: 48.6737665, lng: 21.6705529 },
    { lat: 48.6737058, lng: 21.6702633 },
    { lat: 48.6737276, lng: 21.6700377 },
    { lat: 48.6742608, lng: 21.6696185 },
    { lat: 48.6749768, lng: 21.669843 },
    { lat: 48.6752513, lng: 21.6700516 },
    { lat: 48.6753822, lng: 21.6705518 },
    { lat: 48.6754004, lng: 21.6709352 },
    { lat: 48.6742889, lng: 21.6715509 },
    { lat: 48.6734825, lng: 21.6723527 },
    { lat: 48.6729784, lng: 21.6726774 },
    { lat: 48.6725306, lng: 21.6728413 },
    { lat: 48.6719805, lng: 21.673218 },
    { lat: 48.6715838, lng: 21.6731964 },
    { lat: 48.6706517, lng: 21.673588 },
    { lat: 48.6705806, lng: 21.6737373 },
    { lat: 48.6710866, lng: 21.6778091 },
    { lat: 48.6713518, lng: 21.6777983 },
    { lat: 48.6719797, lng: 21.6779636 },
    { lat: 48.67312, lng: 21.6779433 },
    { lat: 48.6738232, lng: 21.6781587 },
    { lat: 48.6742643, lng: 21.6781993 },
    { lat: 48.674638, lng: 21.6784067 },
    { lat: 48.6756766, lng: 21.6784963 },
    { lat: 48.6762647, lng: 21.6786445 },
    { lat: 48.6766915, lng: 21.6786236 },
    { lat: 48.6769662, lng: 21.6787525 },
    { lat: 48.6774663, lng: 21.6785644 },
    { lat: 48.6792644, lng: 21.6789849 },
    { lat: 48.6793828, lng: 21.6800239 },
    { lat: 48.6809445, lng: 21.6806888 },
    { lat: 48.6815761, lng: 21.6807414 },
    { lat: 48.6822863, lng: 21.6810473 },
    { lat: 48.6827133, lng: 21.6811083 },
    { lat: 48.6829189, lng: 21.6812227 },
    { lat: 48.6837096, lng: 21.6813313 },
    { lat: 48.683977, lng: 21.6814891 },
    { lat: 48.6843948, lng: 21.6821226 },
    { lat: 48.6846257, lng: 21.682334 },
    { lat: 48.6854333, lng: 21.6824918 },
    { lat: 48.6864125, lng: 21.6828768 },
    { lat: 48.6873446, lng: 21.6835994 },
    { lat: 48.6874634, lng: 21.6837876 },
    { lat: 48.6875108, lng: 21.6839954 },
    { lat: 48.6872207, lng: 21.6844621 },
    { lat: 48.6869822, lng: 21.68475 },
    { lat: 48.6873106, lng: 21.6858253 },
    { lat: 48.6882075, lng: 21.6891846 },
    { lat: 48.68906, lng: 21.6925688 },
    { lat: 48.6893345, lng: 21.6939356 },
    { lat: 48.6897101, lng: 21.6966654 },
    { lat: 48.6903181, lng: 21.7001312 },
    { lat: 48.6904035, lng: 21.7009581 },
    { lat: 48.690263, lng: 21.7017075 },
    { lat: 48.6902557, lng: 21.7023967 },
    { lat: 48.6902337, lng: 21.7026637 },
    { lat: 48.692014, lng: 21.7021802 },
    { lat: 48.6920847, lng: 21.7027627 },
    { lat: 48.6927279, lng: 21.7025792 },
    { lat: 48.6927355, lng: 21.7026521 },
    { lat: 48.6929092, lng: 21.7025883 },
    { lat: 48.6929715, lng: 21.7031827 },
    { lat: 48.6956734, lng: 21.7022873 },
    { lat: 48.6955604, lng: 21.7014827 },
    { lat: 48.6955011, lng: 21.7006601 },
    { lat: 48.6948941, lng: 21.6959883 },
    { lat: 48.6974999, lng: 21.6951163 },
    { lat: 48.6974534, lng: 21.6931938 },
    { lat: 48.6991938, lng: 21.6926957 },
    { lat: 48.6991592, lng: 21.6927437 },
    { lat: 48.6992187, lng: 21.6927383 },
    { lat: 48.6991485, lng: 21.6931376 },
    { lat: 48.6990179, lng: 21.6935923 },
    { lat: 48.6992225, lng: 21.6936676 },
    { lat: 48.6996087, lng: 21.695546 },
    { lat: 48.6996033, lng: 21.6958018 },
    { lat: 48.6996463, lng: 21.6959352 },
    { lat: 48.699972, lng: 21.6958324 },
    { lat: 48.7003094, lng: 21.695825 },
    { lat: 48.7004843, lng: 21.6969657 },
    { lat: 48.7009928, lng: 21.696763 },
    { lat: 48.7010854, lng: 21.6967596 },
    { lat: 48.7011513, lng: 21.6967571 },
    { lat: 48.7012517, lng: 21.6990101 },
    { lat: 48.7013444, lng: 21.6995266 },
    { lat: 48.7015004, lng: 21.7000106 },
    { lat: 48.7015751, lng: 21.7010176 },
    { lat: 48.7019463, lng: 21.7009782 },
    { lat: 48.701983, lng: 21.7011227 },
    { lat: 48.7021604, lng: 21.7011103 },
    { lat: 48.7021742, lng: 21.701247 },
    { lat: 48.7032963, lng: 21.7009697 },
    { lat: 48.7032539, lng: 21.7007358 },
    { lat: 48.7047256, lng: 21.7004094 },
    { lat: 48.7049787, lng: 21.700859 },
    { lat: 48.7052404, lng: 21.7011801 },
    { lat: 48.7055029, lng: 21.7016876 },
    { lat: 48.7061532, lng: 21.7035564 },
    { lat: 48.7063214, lng: 21.7035011 },
    { lat: 48.7067109, lng: 21.7047135 },
    { lat: 48.7069112, lng: 21.7057563 },
    { lat: 48.7071334, lng: 21.7059453 },
    { lat: 48.7073537, lng: 21.706979 },
    { lat: 48.7082362, lng: 21.7083662 },
    { lat: 48.7084531, lng: 21.7090219 },
    { lat: 48.7088105, lng: 21.7105149 },
    { lat: 48.7095265, lng: 21.7127789 },
    { lat: 48.7096727, lng: 21.7131137 },
    { lat: 48.7103848, lng: 21.7141948 },
    { lat: 48.7108908, lng: 21.7140561 },
    { lat: 48.7106882, lng: 21.7137188 },
    { lat: 48.7106731, lng: 21.7135804 },
    { lat: 48.7109217, lng: 21.7131198 },
    { lat: 48.7113976, lng: 21.7129081 },
    { lat: 48.7115535, lng: 21.7130592 },
    { lat: 48.7117761, lng: 21.7135931 },
    { lat: 48.7117924, lng: 21.7137869 },
    { lat: 48.7117308, lng: 21.7141263 },
    { lat: 48.7117787, lng: 21.714362 },
    { lat: 48.7118363, lng: 21.7143239 },
    { lat: 48.7118875, lng: 21.7144284 },
    { lat: 48.7118449, lng: 21.7145628 },
    { lat: 48.7118759, lng: 21.7147939 },
    { lat: 48.7119412, lng: 21.7149623 },
    { lat: 48.7120102, lng: 21.7149766 },
    { lat: 48.7120693, lng: 21.7149128 },
    { lat: 48.7120133, lng: 21.7146686 },
    { lat: 48.7120413, lng: 21.714544 },
    { lat: 48.7121393, lng: 21.7144193 },
    { lat: 48.7122212, lng: 21.7144581 },
    { lat: 48.7123101, lng: 21.7143718 },
    { lat: 48.7123809, lng: 21.7141647 },
    { lat: 48.7121567, lng: 21.7139369 },
    { lat: 48.7120745, lng: 21.7137735 },
    { lat: 48.7123609, lng: 21.7134235 },
    { lat: 48.712343, lng: 21.7133362 },
    { lat: 48.7121588, lng: 21.7132257 },
    { lat: 48.7120808, lng: 21.7130887 },
    { lat: 48.7122673, lng: 21.712618 },
    { lat: 48.712364, lng: 21.7124888 },
    { lat: 48.712516, lng: 21.7125354 },
    { lat: 48.7127025, lng: 21.712862 },
    { lat: 48.7131741, lng: 21.7131194 },
    { lat: 48.7132094, lng: 21.7130542 },
    { lat: 48.7132162, lng: 21.7124652 },
    { lat: 48.7131253, lng: 21.7123119 },
    { lat: 48.7131499, lng: 21.7119673 },
    { lat: 48.7132746, lng: 21.7118788 },
    { lat: 48.7133745, lng: 21.7123312 },
    { lat: 48.7136068, lng: 21.7123945 },
    { lat: 48.7139174, lng: 21.7120576 },
    { lat: 48.7139295, lng: 21.7119286 },
    { lat: 48.7135687, lng: 21.7116017 },
    { lat: 48.7134971, lng: 21.7114137 },
    { lat: 48.7135114, lng: 21.7112336 },
    { lat: 48.7136367, lng: 21.7112042 },
    { lat: 48.7137798, lng: 21.7113795 },
    { lat: 48.7139058, lng: 21.711417 },
    { lat: 48.7140182, lng: 21.7111707 },
    { lat: 48.7139609, lng: 21.7108814 },
    { lat: 48.7140061, lng: 21.7107222 },
    { lat: 48.7140739, lng: 21.7106902 },
    { lat: 48.7143784, lng: 21.7109686 },
    { lat: 48.7145779, lng: 21.7110668 },
    { lat: 48.7147064, lng: 21.7110293 },
    { lat: 48.7147451, lng: 21.7108741 },
    { lat: 48.714482, lng: 21.7106302 },
    { lat: 48.7144902, lng: 21.7104811 },
    { lat: 48.7146181, lng: 21.7104675 },
    { lat: 48.7149745, lng: 21.7100793 },
    { lat: 48.715003, lng: 21.7099746 },
    { lat: 48.7148467, lng: 21.7097199 },
    { lat: 48.7146144, lng: 21.7094894 },
    { lat: 48.7143241, lng: 21.7099081 },
    { lat: 48.7142777, lng: 21.7097763 },
    { lat: 48.7140988, lng: 21.7096031 },
    { lat: 48.7139842, lng: 21.7091934 },
    { lat: 48.7139974, lng: 21.7090604 },
    { lat: 48.7142852, lng: 21.7088421 },
    { lat: 48.7144095, lng: 21.7091151 },
    { lat: 48.7145328, lng: 21.7092187 },
    { lat: 48.7147231, lng: 21.7091054 },
    { lat: 48.7151094, lng: 21.7091992 },
    { lat: 48.7152941, lng: 21.7089138 },
    { lat: 48.7153009, lng: 21.7087549 },
    { lat: 48.715194, lng: 21.7086279 },
    { lat: 48.714717, lng: 21.708461 },
    { lat: 48.7146666, lng: 21.7082624 },
    { lat: 48.7148273, lng: 21.7077512 },
    { lat: 48.7149573, lng: 21.7076958 },
    { lat: 48.7151945, lng: 21.7079327 },
    { lat: 48.7153877, lng: 21.7083031 },
    { lat: 48.7155131, lng: 21.708362 },
    { lat: 48.7155923, lng: 21.7082478 },
    { lat: 48.7157234, lng: 21.7078139 },
    { lat: 48.7158877, lng: 21.7078982 },
    { lat: 48.7159601, lng: 21.7077876 },
    { lat: 48.7159659, lng: 21.707381 },
    { lat: 48.7161743, lng: 21.7071021 },
    { lat: 48.7163611, lng: 21.7070164 },
    { lat: 48.71631, lng: 21.7068685 },
    { lat: 48.71637, lng: 21.7067184 },
    { lat: 48.7162456, lng: 21.7068144 },
    { lat: 48.7165732, lng: 21.706201 },
    { lat: 48.7166672, lng: 21.7058913 },
    { lat: 48.7169975, lng: 21.7059183 },
    { lat: 48.7170107, lng: 21.7055715 },
    { lat: 48.717105, lng: 21.7053598 },
    { lat: 48.7171979, lng: 21.7052988 },
    { lat: 48.7171374, lng: 21.7050132 },
    { lat: 48.7169731, lng: 21.7048053 },
    { lat: 48.7169646, lng: 21.7047029 },
    { lat: 48.7170447, lng: 21.7044553 },
    { lat: 48.7171123, lng: 21.7044622 },
    { lat: 48.7171234, lng: 21.7047063 },
    { lat: 48.7172932, lng: 21.7049103 },
    { lat: 48.7173972, lng: 21.7049701 },
    { lat: 48.7174626, lng: 21.7049301 },
    { lat: 48.717475, lng: 21.7048481 },
    { lat: 48.7173545, lng: 21.7045951 },
    { lat: 48.7173736, lng: 21.7044307 },
    { lat: 48.717407, lng: 21.7043303 },
    { lat: 48.7175727, lng: 21.7042161 },
    { lat: 48.7173404, lng: 21.7042803 },
    { lat: 48.716904, lng: 21.7028336 },
    { lat: 48.7168905, lng: 21.7027881 },
    { lat: 48.7165103, lng: 21.7029997 },
    { lat: 48.7163295, lng: 21.7032642 },
    { lat: 48.7161151, lng: 21.7033202 },
    { lat: 48.7159841, lng: 21.7035157 },
    { lat: 48.7158658, lng: 21.7035882 },
    { lat: 48.7157757, lng: 21.703334 },
    { lat: 48.7156285, lng: 21.7031731 },
    { lat: 48.7155175, lng: 21.7031501 },
    { lat: 48.7148407, lng: 21.7032923 },
    { lat: 48.7146294, lng: 21.7034618 },
    { lat: 48.7138586, lng: 21.7036686 },
    { lat: 48.7138556, lng: 21.7029723 },
    { lat: 48.7137482, lng: 21.7019247 },
    { lat: 48.7133393, lng: 21.7011081 },
    { lat: 48.7131609, lng: 21.7002594 },
    { lat: 48.7131751, lng: 21.7001118 },
    { lat: 48.713319, lng: 21.6997388 },
    { lat: 48.7132992, lng: 21.6990435 },
    { lat: 48.7134429, lng: 21.6987446 },
    { lat: 48.7134306, lng: 21.6982466 },
    { lat: 48.7135394, lng: 21.6979431 },
    { lat: 48.7138568, lng: 21.6976613 },
    { lat: 48.7136079, lng: 21.6968163 },
    { lat: 48.7133419, lng: 21.6955979 },
    { lat: 48.7131885, lng: 21.6941745 },
    { lat: 48.7129615, lng: 21.6909444 },
    { lat: 48.7126217, lng: 21.687799 },
    { lat: 48.7126233, lng: 21.6873169 },
    { lat: 48.7123524, lng: 21.6828629 },
    { lat: 48.7136446, lng: 21.6826362 },
    { lat: 48.7138283, lng: 21.6813205 },
    { lat: 48.7139959, lng: 21.680486 },
    { lat: 48.7147996, lng: 21.679551 },
    { lat: 48.7149667, lng: 21.6793237 },
    { lat: 48.7150064, lng: 21.679173 },
    { lat: 48.7161849, lng: 21.6790309 },
    { lat: 48.7177944, lng: 21.6786368 },
    { lat: 48.7188872, lng: 21.6786479 },
    { lat: 48.7198222, lng: 21.6785238 },
    { lat: 48.7214291, lng: 21.6786177 },
    { lat: 48.7230551, lng: 21.6789199 },
    { lat: 48.7244047, lng: 21.6788191 },
    { lat: 48.7244622, lng: 21.680887 },
    { lat: 48.7245542, lng: 21.6809141 },
    { lat: 48.7246286, lng: 21.6812918 },
    { lat: 48.7250334, lng: 21.6812813 },
    { lat: 48.7253017, lng: 21.6813433 },
    { lat: 48.7257228, lng: 21.6815609 },
    { lat: 48.7270347, lng: 21.6814426 },
    { lat: 48.7282555, lng: 21.6811123 },
    { lat: 48.7297969, lng: 21.6809034 },
    { lat: 48.7299384, lng: 21.6811402 },
    { lat: 48.7325561, lng: 21.6810458 },
    { lat: 48.733259, lng: 21.680501 },
    { lat: 48.7339245, lng: 21.6803081 },
    { lat: 48.7345271, lng: 21.6807087 },
    { lat: 48.7345602, lng: 21.680728 },
    { lat: 48.7343845, lng: 21.6795246 },
    { lat: 48.7342232, lng: 21.6767771 },
    { lat: 48.7342894, lng: 21.6766464 },
    { lat: 48.734284, lng: 21.6743906 },
    { lat: 48.7342269, lng: 21.673696 },
    { lat: 48.7341584, lng: 21.6734957 },
    { lat: 48.7341626, lng: 21.6709256 },
    { lat: 48.7346606, lng: 21.6708768 },
    { lat: 48.7346404, lng: 21.6660588 },
    { lat: 48.734922, lng: 21.6661546 },
    { lat: 48.7351955, lng: 21.6661564 },
    { lat: 48.7352741, lng: 21.6663579 },
    { lat: 48.7354175, lng: 21.6665417 },
    { lat: 48.7357038, lng: 21.6665973 },
    { lat: 48.7363155, lng: 21.6663844 },
    { lat: 48.7368788, lng: 21.6660765 },
    { lat: 48.7378685, lng: 21.6658582 },
    { lat: 48.7389944, lng: 21.6657582 },
    { lat: 48.7390208, lng: 21.6656233 },
    { lat: 48.7404729, lng: 21.6651914 },
    { lat: 48.7430972, lng: 21.6642705 },
    { lat: 48.7449567, lng: 21.6637172 },
    { lat: 48.7459138, lng: 21.6632559 },
    { lat: 48.7470265, lng: 21.6625118 },
    { lat: 48.7477064, lng: 21.662235 },
    { lat: 48.7477708, lng: 21.6622092 },
    { lat: 48.7481795, lng: 21.6605693 },
    { lat: 48.7480838, lng: 21.6601999 },
    { lat: 48.7477178, lng: 21.6597419 },
    { lat: 48.7472906, lng: 21.6585184 },
    { lat: 48.7473227, lng: 21.6579267 },
    { lat: 48.7472804, lng: 21.6577767 },
    { lat: 48.7469148, lng: 21.6574103 },
    { lat: 48.7465568, lng: 21.6567872 },
    { lat: 48.74624, lng: 21.6560248 },
    { lat: 48.7457707, lng: 21.6561409 },
    { lat: 48.7456948, lng: 21.6560045 },
    { lat: 48.7457022, lng: 21.6555673 },
    { lat: 48.7451613, lng: 21.6548886 },
    { lat: 48.7449116, lng: 21.6539071 },
    { lat: 48.7444353, lng: 21.6536486 },
    { lat: 48.7444653, lng: 21.6531762 },
    { lat: 48.744192, lng: 21.6527734 },
    { lat: 48.7438481, lng: 21.6531179 },
    { lat: 48.7436521, lng: 21.6529869 },
    { lat: 48.7436547, lng: 21.6526681 },
    { lat: 48.7438518, lng: 21.6524311 },
    { lat: 48.7438656, lng: 21.652072 },
    { lat: 48.7437263, lng: 21.6519549 },
    { lat: 48.7433337, lng: 21.6521239 },
    { lat: 48.7432052, lng: 21.6520314 },
    { lat: 48.743185, lng: 21.6519536 },
    { lat: 48.7433469, lng: 21.6513338 },
    { lat: 48.7435893, lng: 21.6509849 },
    { lat: 48.7435965, lng: 21.6508786 },
    { lat: 48.7434812, lng: 21.6506117 },
    { lat: 48.7430738, lng: 21.6508159 },
    { lat: 48.7429234, lng: 21.6506631 },
    { lat: 48.7429305, lng: 21.6499362 },
    { lat: 48.7427833, lng: 21.6498192 },
    { lat: 48.7422216, lng: 21.649729 },
    { lat: 48.742106, lng: 21.6496165 },
    { lat: 48.7420651, lng: 21.6491567 },
    { lat: 48.7419579, lng: 21.6491014 },
    { lat: 48.7417425, lng: 21.6485874 },
    { lat: 48.7415734, lng: 21.6483738 },
    { lat: 48.7414826, lng: 21.6480989 },
    { lat: 48.7412837, lng: 21.6481079 },
    { lat: 48.7408685, lng: 21.6485211 },
    { lat: 48.7394154, lng: 21.6492373 },
    { lat: 48.7377613, lng: 21.65033 },
    { lat: 48.73739, lng: 21.650461 },
    { lat: 48.7370255, lng: 21.650461 },
    { lat: 48.7366282, lng: 21.6503733 },
    { lat: 48.7365058, lng: 21.6527898 },
    { lat: 48.73652, lng: 21.6531468 },
    { lat: 48.7330645, lng: 21.6539585 },
    { lat: 48.7337961, lng: 21.6497863 },
    { lat: 48.7335242, lng: 21.6496815 },
    { lat: 48.732661, lng: 21.6497995 },
    { lat: 48.7322746, lng: 21.6497753 },
    { lat: 48.72849, lng: 21.6490971 },
    { lat: 48.7279905, lng: 21.6490561 },
    { lat: 48.726978, lng: 21.6490613 },
    { lat: 48.7261523, lng: 21.649266 },
    { lat: 48.7261053, lng: 21.6478906 },
    { lat: 48.7250942, lng: 21.6415671 },
    { lat: 48.725024, lng: 21.6407758 },
    { lat: 48.7250299, lng: 21.6393981 },
    { lat: 48.7253673, lng: 21.6387397 },
    { lat: 48.7254477, lng: 21.6350926 },
    { lat: 48.7255262, lng: 21.6345118 },
    { lat: 48.7258843, lng: 21.6330178 },
    { lat: 48.7259267, lng: 21.6326388 },
    { lat: 48.7258977, lng: 21.6289034 },
    { lat: 48.7260466, lng: 21.6271631 },
    { lat: 48.7260143, lng: 21.6263623 },
    { lat: 48.725624, lng: 21.6245799 },
    { lat: 48.7254168, lng: 21.6220967 },
    { lat: 48.7252404, lng: 21.6214582 },
    { lat: 48.724478, lng: 21.6194895 },
    { lat: 48.724182, lng: 21.6180658 },
    { lat: 48.7242357, lng: 21.6175717 },
    { lat: 48.7232789, lng: 21.6141929 },
    { lat: 48.725369, lng: 21.611953 },
    { lat: 48.7267109, lng: 21.609671 },
  ],
  Michaľany: [
    { lat: 48.5297671, lng: 21.6435049 },
    { lat: 48.5296943, lng: 21.6433508 },
    { lat: 48.5290898, lng: 21.6377668 },
    { lat: 48.5282455, lng: 21.631953 },
    { lat: 48.5281031, lng: 21.6320103 },
    { lat: 48.5279879, lng: 21.6313427 },
    { lat: 48.5272504, lng: 21.6316177 },
    { lat: 48.5270869, lng: 21.6291698 },
    { lat: 48.5264142, lng: 21.6234262 },
    { lat: 48.5253977, lng: 21.6228929 },
    { lat: 48.5247069, lng: 21.6224048 },
    { lat: 48.524528, lng: 21.622448 },
    { lat: 48.5238062, lng: 21.6180634 },
    { lat: 48.5239133, lng: 21.6180296 },
    { lat: 48.5232346, lng: 21.6150437 },
    { lat: 48.5232612, lng: 21.6149824 },
    { lat: 48.5226009, lng: 21.6118883 },
    { lat: 48.5221836, lng: 21.6103629 },
    { lat: 48.5217687, lng: 21.6105009 },
    { lat: 48.5212407, lng: 21.609317 },
    { lat: 48.5206657, lng: 21.6077917 },
    { lat: 48.5206336, lng: 21.6072429 },
    { lat: 48.5206636, lng: 21.6072292 },
    { lat: 48.5205951, lng: 21.6069951 },
    { lat: 48.5205063, lng: 21.6069588 },
    { lat: 48.5202883, lng: 21.6071313 },
    { lat: 48.5201226, lng: 21.6070382 },
    { lat: 48.5200464, lng: 21.6072063 },
    { lat: 48.520032, lng: 21.6073822 },
    { lat: 48.5200563, lng: 21.6074839 },
    { lat: 48.5201405, lng: 21.6075201 },
    { lat: 48.5202698, lng: 21.6077146 },
    { lat: 48.5200954, lng: 21.6079556 },
    { lat: 48.5200762, lng: 21.6082386 },
    { lat: 48.5200288, lng: 21.6082704 },
    { lat: 48.519899, lng: 21.608175 },
    { lat: 48.5198444, lng: 21.6082374 },
    { lat: 48.5198585, lng: 21.6083383 },
    { lat: 48.5200406, lng: 21.6085749 },
    { lat: 48.5201723, lng: 21.6086148 },
    { lat: 48.5201833, lng: 21.6086862 },
    { lat: 48.5196495, lng: 21.6090348 },
    { lat: 48.5194809, lng: 21.609361 },
    { lat: 48.5193923, lng: 21.6092882 },
    { lat: 48.5193611, lng: 21.6088976 },
    { lat: 48.5193199, lng: 21.6088321 },
    { lat: 48.5190818, lng: 21.6088486 },
    { lat: 48.5190465, lng: 21.6089967 },
    { lat: 48.5192093, lng: 21.6093466 },
    { lat: 48.5189413, lng: 21.6097019 },
    { lat: 48.518837, lng: 21.609674 },
    { lat: 48.5186945, lng: 21.6092841 },
    { lat: 48.5186328, lng: 21.6092536 },
    { lat: 48.5185466, lng: 21.6094671 },
    { lat: 48.5184808, lng: 21.6094929 },
    { lat: 48.5184322, lng: 21.6092787 },
    { lat: 48.518483, lng: 21.6089203 },
    { lat: 48.5181178, lng: 21.6091421 },
    { lat: 48.5180551, lng: 21.6091074 },
    { lat: 48.5179931, lng: 21.6088658 },
    { lat: 48.5179479, lng: 21.6089009 },
    { lat: 48.5178559, lng: 21.6092688 },
    { lat: 48.5179154, lng: 21.6094662 },
    { lat: 48.5178478, lng: 21.6095862 },
    { lat: 48.5177225, lng: 21.6095324 },
    { lat: 48.5177018, lng: 21.6092746 },
    { lat: 48.5176563, lng: 21.6092157 },
    { lat: 48.5175742, lng: 21.6092135 },
    { lat: 48.5175077, lng: 21.6094569 },
    { lat: 48.5174207, lng: 21.6095604 },
    { lat: 48.5173752, lng: 21.6095097 },
    { lat: 48.5173588, lng: 21.6089212 },
    { lat: 48.5175077, lng: 21.6086477 },
    { lat: 48.5173753, lng: 21.608457 },
    { lat: 48.517568, lng: 21.6082748 },
    { lat: 48.5174873, lng: 21.6080794 },
    { lat: 48.5171705, lng: 21.6079376 },
    { lat: 48.5171022, lng: 21.6077908 },
    { lat: 48.5169717, lng: 21.6078285 },
    { lat: 48.5169024, lng: 21.6079287 },
    { lat: 48.5169522, lng: 21.6081758 },
    { lat: 48.5168184, lng: 21.6082644 },
    { lat: 48.5166135, lng: 21.6080223 },
    { lat: 48.5163903, lng: 21.6080832 },
    { lat: 48.5160464, lng: 21.6076994 },
    { lat: 48.5159835, lng: 21.6073815 },
    { lat: 48.5159274, lng: 21.6073464 },
    { lat: 48.5156994, lng: 21.60772 },
    { lat: 48.5156202, lng: 21.6077466 },
    { lat: 48.5154726, lng: 21.6076623 },
    { lat: 48.515368, lng: 21.6076973 },
    { lat: 48.5151467, lng: 21.6079559 },
    { lat: 48.5151581, lng: 21.6082841 },
    { lat: 48.5149904, lng: 21.6084438 },
    { lat: 48.5149031, lng: 21.6087797 },
    { lat: 48.5147604, lng: 21.6087867 },
    { lat: 48.5147782, lng: 21.6089843 },
    { lat: 48.5147299, lng: 21.6090619 },
    { lat: 48.5146246, lng: 21.609081 },
    { lat: 48.5144151, lng: 21.6088309 },
    { lat: 48.5142383, lng: 21.6091469 },
    { lat: 48.5141536, lng: 21.6090593 },
    { lat: 48.5141017, lng: 21.6090914 },
    { lat: 48.5141477, lng: 21.6093284 },
    { lat: 48.5142763, lng: 21.6094253 },
    { lat: 48.5143129, lng: 21.6095212 },
    { lat: 48.5142884, lng: 21.6096119 },
    { lat: 48.5142293, lng: 21.6096752 },
    { lat: 48.5140987, lng: 21.6096682 },
    { lat: 48.5139557, lng: 21.6095886 },
    { lat: 48.5137127, lng: 21.6097505 },
    { lat: 48.5137606, lng: 21.6101329 },
    { lat: 48.5138482, lng: 21.6102026 },
    { lat: 48.513873, lng: 21.6103646 },
    { lat: 48.5137377, lng: 21.6106028 },
    { lat: 48.5138468, lng: 21.6109636 },
    { lat: 48.5140058, lng: 21.6109769 },
    { lat: 48.5141047, lng: 21.6112492 },
    { lat: 48.5139785, lng: 21.6113648 },
    { lat: 48.5136302, lng: 21.6113478 },
    { lat: 48.5135705, lng: 21.6115474 },
    { lat: 48.513639, lng: 21.6117383 },
    { lat: 48.513616, lng: 21.6118441 },
    { lat: 48.5134684, lng: 21.6117931 },
    { lat: 48.5133339, lng: 21.6118548 },
    { lat: 48.5133257, lng: 21.6122031 },
    { lat: 48.5133742, lng: 21.6123296 },
    { lat: 48.5135166, lng: 21.6124381 },
    { lat: 48.5135453, lng: 21.6125768 },
    { lat: 48.5134353, lng: 21.6126657 },
    { lat: 48.5132518, lng: 21.6126631 },
    { lat: 48.5131571, lng: 21.612777 },
    { lat: 48.5131502, lng: 21.613171 },
    { lat: 48.5130839, lng: 21.6134985 },
    { lat: 48.5130035, lng: 21.6134841 },
    { lat: 48.5128951, lng: 21.61363 },
    { lat: 48.5129256, lng: 21.6138332 },
    { lat: 48.5130883, lng: 21.6140206 },
    { lat: 48.5130406, lng: 21.6143285 },
    { lat: 48.5129335, lng: 21.6144374 },
    { lat: 48.5124564, lng: 21.6146358 },
    { lat: 48.5121285, lng: 21.6145813 },
    { lat: 48.5119168, lng: 21.6144553 },
    { lat: 48.5116291, lng: 21.6148748 },
    { lat: 48.5116491, lng: 21.6151436 },
    { lat: 48.5114667, lng: 21.6149014 },
    { lat: 48.5113867, lng: 21.6145306 },
    { lat: 48.5114693, lng: 21.6143892 },
    { lat: 48.5112554, lng: 21.6141429 },
    { lat: 48.5111028, lng: 21.6135905 },
    { lat: 48.5108033, lng: 21.6127776 },
    { lat: 48.510684, lng: 21.6129381 },
    { lat: 48.5105793, lng: 21.6129401 },
    { lat: 48.510653, lng: 21.6127566 },
    { lat: 48.5105531, lng: 21.6125234 },
    { lat: 48.5105612, lng: 21.6124509 },
    { lat: 48.5105984, lng: 21.612374 },
    { lat: 48.5107229, lng: 21.6124836 },
    { lat: 48.5108169, lng: 21.6123051 },
    { lat: 48.5107398, lng: 21.6122029 },
    { lat: 48.5105722, lng: 21.6122046 },
    { lat: 48.5105433, lng: 21.6120904 },
    { lat: 48.5106201, lng: 21.6120185 },
    { lat: 48.5105908, lng: 21.6117808 },
    { lat: 48.51051, lng: 21.6117096 },
    { lat: 48.510344, lng: 21.6117684 },
    { lat: 48.5103106, lng: 21.6117009 },
    { lat: 48.5104521, lng: 21.6114496 },
    { lat: 48.5104087, lng: 21.6112632 },
    { lat: 48.5101053, lng: 21.6113573 },
    { lat: 48.510084, lng: 21.611356 },
    { lat: 48.509982, lng: 21.611376 },
    { lat: 48.509275, lng: 21.6117 },
    { lat: 48.509235, lng: 21.611718 },
    { lat: 48.509235, lng: 21.611746 },
    { lat: 48.50932, lng: 21.61263 },
    { lat: 48.509339, lng: 21.612907 },
    { lat: 48.509367, lng: 21.613236 },
    { lat: 48.509377, lng: 21.613357 },
    { lat: 48.509384, lng: 21.613404 },
    { lat: 48.509416, lng: 21.613933 },
    { lat: 48.50921, lng: 21.61392 },
    { lat: 48.509085, lng: 21.613915 },
    { lat: 48.50899, lng: 21.613912 },
    { lat: 48.508471, lng: 21.613892 },
    { lat: 48.507726, lng: 21.613849 },
    { lat: 48.506972, lng: 21.613814 },
    { lat: 48.505362, lng: 21.613745 },
    { lat: 48.504281, lng: 21.613876 },
    { lat: 48.503488, lng: 21.613972 },
    { lat: 48.50252, lng: 21.614075 },
    { lat: 48.501056, lng: 21.61424 },
    { lat: 48.500298, lng: 21.614328 },
    { lat: 48.499454, lng: 21.614426 },
    { lat: 48.497802, lng: 21.614614 },
    { lat: 48.497874, lng: 21.616064 },
    { lat: 48.497975, lng: 21.618207 },
    { lat: 48.497992, lng: 21.618317 },
    { lat: 48.497744, lng: 21.618368 },
    { lat: 48.4977775, lng: 21.6184432 },
    { lat: 48.4982686, lng: 21.6187049 },
    { lat: 48.4987796, lng: 21.6217865 },
    { lat: 48.4984984, lng: 21.6219071 },
    { lat: 48.4991001, lng: 21.6261829 },
    { lat: 48.4989889, lng: 21.6262282 },
    { lat: 48.4995995, lng: 21.6287165 },
    { lat: 48.499498, lng: 21.6287996 },
    { lat: 48.4997736, lng: 21.6296443 },
    { lat: 48.4998319, lng: 21.62963 },
    { lat: 48.5006833, lng: 21.6341213 },
    { lat: 48.5009233, lng: 21.6342127 },
    { lat: 48.5009435, lng: 21.6345318 },
    { lat: 48.5010927, lng: 21.6352437 },
    { lat: 48.5013044, lng: 21.6357785 },
    { lat: 48.5013658, lng: 21.6363693 },
    { lat: 48.5013491, lng: 21.6365832 },
    { lat: 48.5011658, lng: 21.6374739 },
    { lat: 48.5001153, lng: 21.6398428 },
    { lat: 48.5006155, lng: 21.6405969 },
    { lat: 48.5004859, lng: 21.640837 },
    { lat: 48.5004309, lng: 21.6411229 },
    { lat: 48.5004839, lng: 21.6412938 },
    { lat: 48.5004574, lng: 21.6415527 },
    { lat: 48.5005267, lng: 21.6416956 },
    { lat: 48.5004915, lng: 21.6417723 },
    { lat: 48.5005036, lng: 21.6419631 },
    { lat: 48.5006019, lng: 21.6422248 },
    { lat: 48.5006232, lng: 21.6424654 },
    { lat: 48.5009069, lng: 21.6428762 },
    { lat: 48.5008928, lng: 21.6431329 },
    { lat: 48.5010364, lng: 21.6432041 },
    { lat: 48.5009568, lng: 21.643257 },
    { lat: 48.5011001, lng: 21.6434669 },
    { lat: 48.501161, lng: 21.6437185 },
    { lat: 48.5011415, lng: 21.6438723 },
    { lat: 48.5011975, lng: 21.6439043 },
    { lat: 48.5012001, lng: 21.6439545 },
    { lat: 48.5010643, lng: 21.6440081 },
    { lat: 48.5010823, lng: 21.6444178 },
    { lat: 48.5010273, lng: 21.6447821 },
    { lat: 48.5010501, lng: 21.6451195 },
    { lat: 48.500929, lng: 21.6457461 },
    { lat: 48.5009745, lng: 21.6460874 },
    { lat: 48.5009542, lng: 21.6462484 },
    { lat: 48.5012418, lng: 21.6470925 },
    { lat: 48.5011792, lng: 21.6472231 },
    { lat: 48.5012328, lng: 21.6474124 },
    { lat: 48.5017439, lng: 21.6479576 },
    { lat: 48.5018209, lng: 21.6484651 },
    { lat: 48.5022749, lng: 21.6489721 },
    { lat: 48.5024137, lng: 21.649226 },
    { lat: 48.5024654, lng: 21.6495534 },
    { lat: 48.5025572, lng: 21.649575 },
    { lat: 48.5031531, lng: 21.6502527 },
    { lat: 48.503175, lng: 21.6503528 },
    { lat: 48.5032585, lng: 21.6503632 },
    { lat: 48.5033573, lng: 21.6506 },
    { lat: 48.5034461, lng: 21.650674 },
    { lat: 48.5034986, lng: 21.6509126 },
    { lat: 48.5037184, lng: 21.6511678 },
    { lat: 48.5037359, lng: 21.6513069 },
    { lat: 48.5038329, lng: 21.6514062 },
    { lat: 48.5038624, lng: 21.6518181 },
    { lat: 48.5038059, lng: 21.6519305 },
    { lat: 48.5039238, lng: 21.6521705 },
    { lat: 48.5041028, lng: 21.6522498 },
    { lat: 48.5041888, lng: 21.6524782 },
    { lat: 48.5042212, lng: 21.6529172 },
    { lat: 48.5042977, lng: 21.6530756 },
    { lat: 48.5043456, lng: 21.6532635 },
    { lat: 48.5047028, lng: 21.6530223 },
    { lat: 48.5066429, lng: 21.6514967 },
    { lat: 48.5077309, lng: 21.6511675 },
    { lat: 48.5087056, lng: 21.651014 },
    { lat: 48.511637, lng: 21.649605 },
    { lat: 48.5123405, lng: 21.6493711 },
    { lat: 48.5128582, lng: 21.6492922 },
    { lat: 48.5133491, lng: 21.6490603 },
    { lat: 48.5145024, lng: 21.6488505 },
    { lat: 48.5154678, lng: 21.648488 },
    { lat: 48.5174103, lng: 21.6479715 },
    { lat: 48.5183782, lng: 21.6479056 },
    { lat: 48.5191011, lng: 21.6477183 },
    { lat: 48.5189249, lng: 21.6465947 },
    { lat: 48.5187558, lng: 21.6459721 },
    { lat: 48.5201449, lng: 21.645099 },
    { lat: 48.5204428, lng: 21.6457852 },
    { lat: 48.5209437, lng: 21.6456693 },
    { lat: 48.521581, lng: 21.6452315 },
    { lat: 48.5216319, lng: 21.6454878 },
    { lat: 48.5219344, lng: 21.6454447 },
    { lat: 48.5220939, lng: 21.6462844 },
    { lat: 48.5240768, lng: 21.6455678 },
    { lat: 48.5246769, lng: 21.6454167 },
    { lat: 48.5254954, lng: 21.6453508 },
    { lat: 48.5262265, lng: 21.6454271 },
    { lat: 48.5269996, lng: 21.6456289 },
    { lat: 48.5283239, lng: 21.6464899 },
    { lat: 48.5293862, lng: 21.6468937 },
    { lat: 48.5294236, lng: 21.6446642 },
    { lat: 48.5293564, lng: 21.6435374 },
    { lat: 48.5297206, lng: 21.6434861 },
    { lat: 48.5297671, lng: 21.6435049 },
  ],
  ČiernanadTisou: [
    { lat: 48.403179, lng: 22.071894 },
    { lat: 48.4035268, lng: 22.0738702 },
    { lat: 48.4048462, lng: 22.0815188 },
    { lat: 48.4049682, lng: 22.0824388 },
    { lat: 48.4054946, lng: 22.0853036 },
    { lat: 48.4058194, lng: 22.0871746 },
    { lat: 48.4077362, lng: 22.0981751 },
    { lat: 48.4112299, lng: 22.0966423 },
    { lat: 48.412618, lng: 22.1052667 },
    { lat: 48.4128029, lng: 22.1091355 },
    { lat: 48.414557, lng: 22.1110995 },
    { lat: 48.4151543, lng: 22.1119055 },
    { lat: 48.4165037, lng: 22.1132135 },
    { lat: 48.4191792, lng: 22.1161878 },
    { lat: 48.4193823, lng: 22.1167833 },
    { lat: 48.420767, lng: 22.122311 },
    { lat: 48.4209098, lng: 22.1227415 },
    { lat: 48.423284, lng: 22.1259642 },
    { lat: 48.4235593, lng: 22.1274224 },
    { lat: 48.4236017, lng: 22.1276376 },
    { lat: 48.4236475, lng: 22.1278789 },
    { lat: 48.4237172, lng: 22.1282951 },
    { lat: 48.4238089, lng: 22.1286441 },
    { lat: 48.4238136, lng: 22.1286925 },
    { lat: 48.4267024, lng: 22.1266289 },
    { lat: 48.4267392, lng: 22.1272832 },
    { lat: 48.4270036, lng: 22.1283338 },
    { lat: 48.4273364, lng: 22.1289534 },
    { lat: 48.4277755, lng: 22.1294012 },
    { lat: 48.4282657, lng: 22.1296302 },
    { lat: 48.4288183, lng: 22.1296402 },
    { lat: 48.4292659, lng: 22.1294861 },
    { lat: 48.4298794, lng: 22.1290324 },
    { lat: 48.4301124, lng: 22.1289347 },
    { lat: 48.4294303, lng: 22.1259998 },
    { lat: 48.4291497, lng: 22.1239299 },
    { lat: 48.4290914, lng: 22.1234998 },
    { lat: 48.4254952, lng: 22.1114482 },
    { lat: 48.425451, lng: 22.1112423 },
    { lat: 48.4254746, lng: 22.1111457 },
    { lat: 48.4253351, lng: 22.1106194 },
    { lat: 48.4253092, lng: 22.1102262 },
    { lat: 48.4252358, lng: 22.10966 },
    { lat: 48.4251649, lng: 22.106614 },
    { lat: 48.4253404, lng: 22.1050473 },
    { lat: 48.4257463, lng: 22.1033747 },
    { lat: 48.4257948, lng: 22.1033817 },
    { lat: 48.4258299, lng: 22.1032512 },
    { lat: 48.4257877, lng: 22.1032237 },
    { lat: 48.4264562, lng: 22.1011918 },
    { lat: 48.4266633, lng: 22.1012005 },
    { lat: 48.4268815, lng: 22.1009179 },
    { lat: 48.427077, lng: 22.1005242 },
    { lat: 48.4271046, lng: 22.0997877 },
    { lat: 48.4292974, lng: 22.098479 },
    { lat: 48.4319038, lng: 22.0973849 },
    { lat: 48.4316461, lng: 22.0945476 },
    { lat: 48.4320908, lng: 22.0943218 },
    { lat: 48.4321865, lng: 22.0942734 },
    { lat: 48.4325334, lng: 22.0940996 },
    { lat: 48.4340644, lng: 22.0933433 },
    { lat: 48.4335333, lng: 22.0908401 },
    { lat: 48.4318023, lng: 22.0917004 },
    { lat: 48.4312727, lng: 22.0919014 },
    { lat: 48.4299309, lng: 22.092813 },
    { lat: 48.4291048, lng: 22.0929378 },
    { lat: 48.429157, lng: 22.0912458 },
    { lat: 48.4289881, lng: 22.0895894 },
    { lat: 48.426353, lng: 22.0767548 },
    { lat: 48.4260577, lng: 22.0752817 },
    { lat: 48.4257708, lng: 22.0739644 },
    { lat: 48.4258099, lng: 22.0734799 },
    { lat: 48.4256157, lng: 22.072284 },
    { lat: 48.4253985, lng: 22.0721445 },
    { lat: 48.4233647, lng: 22.0623575 },
    { lat: 48.4214824, lng: 22.0529762 },
    { lat: 48.4208978, lng: 22.0503844 },
    { lat: 48.420632, lng: 22.0505558 },
    { lat: 48.4188093, lng: 22.0487807 },
    { lat: 48.417973, lng: 22.0482526 },
    { lat: 48.4174415, lng: 22.0480408 },
    { lat: 48.416784, lng: 22.0475122 },
    { lat: 48.4165182, lng: 22.0474106 },
    { lat: 48.4129976, lng: 22.0446435 },
    { lat: 48.4125133, lng: 22.0447557 },
    { lat: 48.4118514, lng: 22.0440001 },
    { lat: 48.4116226, lng: 22.0435682 },
    { lat: 48.4114211, lng: 22.0430084 },
    { lat: 48.4108157, lng: 22.043743 },
    { lat: 48.4118911, lng: 22.0487567 },
    { lat: 48.4119304, lng: 22.0492185 },
    { lat: 48.4117512, lng: 22.0497651 },
    { lat: 48.4118854, lng: 22.0499596 },
    { lat: 48.4111375, lng: 22.050619 },
    { lat: 48.41117, lng: 22.0512199 },
    { lat: 48.411462, lng: 22.0525935 },
    { lat: 48.4119734, lng: 22.0535996 },
    { lat: 48.4125037, lng: 22.0545031 },
    { lat: 48.4126159, lng: 22.0545798 },
    { lat: 48.4129108, lng: 22.05501 },
    { lat: 48.412999, lng: 22.0552576 },
    { lat: 48.4129921, lng: 22.0555753 },
    { lat: 48.4130792, lng: 22.0561067 },
    { lat: 48.4133263, lng: 22.0571265 },
    { lat: 48.4135627, lng: 22.0572231 },
    { lat: 48.4137062, lng: 22.0575305 },
    { lat: 48.4133613, lng: 22.0578718 },
    { lat: 48.4134631, lng: 22.0584088 },
    { lat: 48.4137922, lng: 22.0580187 },
    { lat: 48.4141833, lng: 22.0601905 },
    { lat: 48.4140793, lng: 22.0603055 },
    { lat: 48.4142616, lng: 22.060966 },
    { lat: 48.4161765, lng: 22.0703253 },
    { lat: 48.4128181, lng: 22.0720015 },
    { lat: 48.4111725, lng: 22.0731334 },
    { lat: 48.408847, lng: 22.0749173 },
    { lat: 48.4069577, lng: 22.0724068 },
    { lat: 48.4068553, lng: 22.0720785 },
    { lat: 48.4066006, lng: 22.0704144 },
    { lat: 48.403179, lng: 22.071894 },
  ],
  Hraň: [
    { lat: 48.5206407, lng: 21.7779614 },
    { lat: 48.5211245, lng: 21.7794038 },
    { lat: 48.5211279, lng: 21.7808087 },
    { lat: 48.5210478, lng: 21.781316 },
    { lat: 48.5209082, lng: 21.7818234 },
    { lat: 48.520887, lng: 21.782126 },
    { lat: 48.5221863, lng: 21.7819483 },
    { lat: 48.5220998, lng: 21.7828852 },
    { lat: 48.5221037, lng: 21.7844781 },
    { lat: 48.5222481, lng: 21.7855615 },
    { lat: 48.5222924, lng: 21.7856231 },
    { lat: 48.5223964, lng: 21.7862313 },
    { lat: 48.5223902, lng: 21.7865961 },
    { lat: 48.5224797, lng: 21.7869208 },
    { lat: 48.5226244, lng: 21.7871975 },
    { lat: 48.5225055, lng: 21.7877643 },
    { lat: 48.522494, lng: 21.7882229 },
    { lat: 48.5225894, lng: 21.7890661 },
    { lat: 48.5225863, lng: 21.7898059 },
    { lat: 48.5225066, lng: 21.790326 },
    { lat: 48.5227294, lng: 21.7905395 },
    { lat: 48.5232177, lng: 21.790757 },
    { lat: 48.5239033, lng: 21.7909332 },
    { lat: 48.5241958, lng: 21.7914485 },
    { lat: 48.5246361, lng: 21.7915091 },
    { lat: 48.5255971, lng: 21.7912618 },
    { lat: 48.5261586, lng: 21.791472 },
    { lat: 48.5265476, lng: 21.7915088 },
    { lat: 48.5267058, lng: 21.791615 },
    { lat: 48.5270586, lng: 21.7924511 },
    { lat: 48.5270528, lng: 21.7933044 },
    { lat: 48.5273855, lng: 21.7937985 },
    { lat: 48.5281048, lng: 21.7953532 },
    { lat: 48.528954, lng: 21.7975642 },
    { lat: 48.5297522, lng: 21.7989077 },
    { lat: 48.5294526, lng: 21.7995794 },
    { lat: 48.5292927, lng: 21.7998289 },
    { lat: 48.5291325, lng: 21.799941 },
    { lat: 48.5289767, lng: 21.8004338 },
    { lat: 48.5289889, lng: 21.8012416 },
    { lat: 48.5289101, lng: 21.8017124 },
    { lat: 48.5289881, lng: 21.8017656 },
    { lat: 48.5293813, lng: 21.802429 },
    { lat: 48.5295863, lng: 21.8033397 },
    { lat: 48.5296672, lng: 21.8043643 },
    { lat: 48.5295742, lng: 21.8059207 },
    { lat: 48.5293851, lng: 21.8064775 },
    { lat: 48.5292479, lng: 21.807252 },
    { lat: 48.5290262, lng: 21.8098212 },
    { lat: 48.5292405, lng: 21.8114306 },
    { lat: 48.52935, lng: 21.812648 },
    { lat: 48.5295154, lng: 21.8135898 },
    { lat: 48.5295371, lng: 21.81515 },
    { lat: 48.5300771, lng: 21.8161005 },
    { lat: 48.5305966, lng: 21.8176827 },
    { lat: 48.5306899, lng: 21.8195734 },
    { lat: 48.5309824, lng: 21.8228384 },
    { lat: 48.5309439, lng: 21.8236156 },
    { lat: 48.5317616, lng: 21.8280953 },
    { lat: 48.531786, lng: 21.828168 },
    { lat: 48.53189, lng: 21.828024 },
    { lat: 48.532032, lng: 21.827994 },
    { lat: 48.532135, lng: 21.828008 },
    { lat: 48.532273, lng: 21.828108 },
    { lat: 48.532429, lng: 21.828121 },
    { lat: 48.532679, lng: 21.827947 },
    { lat: 48.532827, lng: 21.827974 },
    { lat: 48.532996, lng: 21.827953 },
    { lat: 48.533209, lng: 21.827871 },
    { lat: 48.533349, lng: 21.827818 },
    { lat: 48.533651, lng: 21.827728 },
    { lat: 48.533791, lng: 21.82765 },
    { lat: 48.53389, lng: 21.827717 },
    { lat: 48.533993, lng: 21.82818 },
    { lat: 48.534031, lng: 21.828272 },
    { lat: 48.534065, lng: 21.828314 },
    { lat: 48.534257, lng: 21.82847 },
    { lat: 48.53445, lng: 21.828381 },
    { lat: 48.534672, lng: 21.827912 },
    { lat: 48.534733, lng: 21.827857 },
    { lat: 48.535016, lng: 21.827816 },
    { lat: 48.535207, lng: 21.827729 },
    { lat: 48.53534, lng: 21.827576 },
    { lat: 48.535444, lng: 21.827304 },
    { lat: 48.535584, lng: 21.827031 },
    { lat: 48.535778, lng: 21.826534 },
    { lat: 48.535942, lng: 21.8263 },
    { lat: 48.536121, lng: 21.825928 },
    { lat: 48.536262, lng: 21.825753 },
    { lat: 48.536571, lng: 21.825583 },
    { lat: 48.536753, lng: 21.8253 },
    { lat: 48.53693, lng: 21.825156 },
    { lat: 48.537149, lng: 21.825083 },
    { lat: 48.537345, lng: 21.825186 },
    { lat: 48.537417, lng: 21.825174 },
    { lat: 48.537512, lng: 21.825128 },
    { lat: 48.537527, lng: 21.825121 },
    { lat: 48.53758, lng: 21.82501 },
    { lat: 48.537601, lng: 21.824861 },
    { lat: 48.537514, lng: 21.824609 },
    { lat: 48.537391, lng: 21.824426 },
    { lat: 48.537326, lng: 21.824265 },
    { lat: 48.537299, lng: 21.824153 },
    { lat: 48.537329, lng: 21.823969 },
    { lat: 48.537446, lng: 21.823752 },
    { lat: 48.537504, lng: 21.823704 },
    { lat: 48.537809, lng: 21.823643 },
    { lat: 48.537912, lng: 21.823523 },
    { lat: 48.537954, lng: 21.823216 },
    { lat: 48.537881, lng: 21.823049 },
    { lat: 48.537828, lng: 21.822929 },
    { lat: 48.537615, lng: 21.822731 },
    { lat: 48.537319, lng: 21.822535 },
    { lat: 48.53697, lng: 21.822214 },
    { lat: 48.536603, lng: 21.822022 },
    { lat: 48.536413, lng: 21.822059 },
    { lat: 48.536081, lng: 21.822439 },
    { lat: 48.535462, lng: 21.82297 },
    { lat: 48.535381, lng: 21.823011 },
    { lat: 48.535245, lng: 21.822962 },
    { lat: 48.535187, lng: 21.822897 },
    { lat: 48.53511, lng: 21.822585 },
    { lat: 48.535062, lng: 21.822161 },
    { lat: 48.534989, lng: 21.821725 },
    { lat: 48.534823, lng: 21.82126 },
    { lat: 48.534762, lng: 21.820862 },
    { lat: 48.534684, lng: 21.820475 },
    { lat: 48.534606, lng: 21.820151 },
    { lat: 48.534609, lng: 21.819909 },
    { lat: 48.534633, lng: 21.819702 },
    { lat: 48.534745, lng: 21.819155 },
    { lat: 48.534844, lng: 21.817966 },
    { lat: 48.534847, lng: 21.81794 },
    { lat: 48.534852, lng: 21.817893 },
    { lat: 48.534852, lng: 21.817879 },
    { lat: 48.534872, lng: 21.817734 },
    { lat: 48.534879, lng: 21.817689 },
    { lat: 48.534881, lng: 21.817676 },
    { lat: 48.534927, lng: 21.817346 },
    { lat: 48.534971, lng: 21.816346 },
    { lat: 48.534957, lng: 21.815873 },
    { lat: 48.534951, lng: 21.815751 },
    { lat: 48.534971, lng: 21.815548 },
    { lat: 48.535202, lng: 21.814456 },
    { lat: 48.535258, lng: 21.81434 },
    { lat: 48.535284, lng: 21.814287 },
    { lat: 48.536249, lng: 21.812914 },
    { lat: 48.538445, lng: 21.809787 },
    { lat: 48.53965, lng: 21.80961 },
    { lat: 48.540463, lng: 21.80949 },
    { lat: 48.54158, lng: 21.809326 },
    { lat: 48.541647, lng: 21.809315 },
    { lat: 48.541759, lng: 21.809299 },
    { lat: 48.543452, lng: 21.809045 },
    { lat: 48.543501, lng: 21.809037 },
    { lat: 48.543558, lng: 21.809034 },
    { lat: 48.545736, lng: 21.808714 },
    { lat: 48.546386, lng: 21.808618 },
    { lat: 48.546462, lng: 21.808606 },
    { lat: 48.549382, lng: 21.808177 },
    { lat: 48.549476, lng: 21.808159 },
    { lat: 48.550854, lng: 21.807942 },
    { lat: 48.55184, lng: 21.807772 },
    { lat: 48.552348, lng: 21.807685 },
    { lat: 48.553203, lng: 21.807508 },
    { lat: 48.554366, lng: 21.807274 },
    { lat: 48.555537, lng: 21.807045 },
    { lat: 48.556394, lng: 21.806896 },
    { lat: 48.557745, lng: 21.806718 },
    { lat: 48.558217, lng: 21.80665 },
    { lat: 48.558746, lng: 21.806575 },
    { lat: 48.559524, lng: 21.806463 },
    { lat: 48.560003, lng: 21.806396 },
    { lat: 48.560387, lng: 21.806341 },
    { lat: 48.560907, lng: 21.806267 },
    { lat: 48.561172, lng: 21.80623 },
    { lat: 48.561545, lng: 21.806177 },
    { lat: 48.561786, lng: 21.806142 },
    { lat: 48.562128, lng: 21.806094 },
    { lat: 48.562496, lng: 21.806042 },
    { lat: 48.562946, lng: 21.805978 },
    { lat: 48.563167, lng: 21.805947 },
    { lat: 48.563652, lng: 21.805878 },
    { lat: 48.563716, lng: 21.806924 },
    { lat: 48.563787, lng: 21.808097 },
    { lat: 48.563859, lng: 21.809306 },
    { lat: 48.563922, lng: 21.810336 },
    { lat: 48.563964, lng: 21.811042 },
    { lat: 48.564018, lng: 21.811913 },
    { lat: 48.564027, lng: 21.812073 },
    { lat: 48.56403, lng: 21.812131 },
    { lat: 48.564034, lng: 21.81219 },
    { lat: 48.564038, lng: 21.812251 },
    { lat: 48.56404, lng: 21.81229 },
    { lat: 48.564046, lng: 21.812392 },
    { lat: 48.564051, lng: 21.812474 },
    { lat: 48.564082, lng: 21.812992 },
    { lat: 48.564105, lng: 21.813378 },
    { lat: 48.564111, lng: 21.813462 },
    { lat: 48.564416, lng: 21.813403 },
    { lat: 48.565163, lng: 21.812939 },
    { lat: 48.565216, lng: 21.812933 },
    { lat: 48.565615, lng: 21.812882 },
    { lat: 48.566003, lng: 21.812565 },
    { lat: 48.566164, lng: 21.812323 },
    { lat: 48.566257, lng: 21.812018 },
    { lat: 48.566392, lng: 21.811171 },
    { lat: 48.566415, lng: 21.81069 },
    { lat: 48.566423, lng: 21.810398 },
    { lat: 48.566246, lng: 21.809554 },
    { lat: 48.566008, lng: 21.808832 },
    { lat: 48.566011, lng: 21.808607 },
    { lat: 48.566088, lng: 21.808371 },
    { lat: 48.566201, lng: 21.808229 },
    { lat: 48.566472, lng: 21.808165 },
    { lat: 48.566883, lng: 21.808254 },
    { lat: 48.566989, lng: 21.808237 },
    { lat: 48.5671, lng: 21.808166 },
    { lat: 48.567344, lng: 21.807844 },
    { lat: 48.567441, lng: 21.807484 },
    { lat: 48.567431, lng: 21.807314 },
    { lat: 48.567312, lng: 21.806926 },
    { lat: 48.567117, lng: 21.806541 },
    { lat: 48.567111, lng: 21.806246 },
    { lat: 48.567171, lng: 21.805313 },
    { lat: 48.567143, lng: 21.805173 },
    { lat: 48.567079, lng: 21.805081 },
    { lat: 48.566951, lng: 21.805022 },
    { lat: 48.566484, lng: 21.804962 },
    { lat: 48.566254, lng: 21.804778 },
    { lat: 48.566056, lng: 21.804445 },
    { lat: 48.566035, lng: 21.804277 },
    { lat: 48.565963, lng: 21.80372 },
    { lat: 48.565949, lng: 21.803612 },
    { lat: 48.565947, lng: 21.803589 },
    { lat: 48.565944, lng: 21.803531 },
    { lat: 48.565924, lng: 21.803214 },
    { lat: 48.565875, lng: 21.803116 },
    { lat: 48.565463, lng: 21.802716 },
    { lat: 48.565302, lng: 21.802559 },
    { lat: 48.565248, lng: 21.802319 },
    { lat: 48.565224, lng: 21.801963 },
    { lat: 48.565652, lng: 21.8019 },
    { lat: 48.566265, lng: 21.80181 },
    { lat: 48.566776, lng: 21.801735 },
    { lat: 48.567171, lng: 21.801718 },
    { lat: 48.567685, lng: 21.801695 },
    { lat: 48.568212, lng: 21.801673 },
    { lat: 48.5682003, lng: 21.8016009 },
    { lat: 48.5681884, lng: 21.8012737 },
    { lat: 48.5682696, lng: 21.8010717 },
    { lat: 48.5682887, lng: 21.8006404 },
    { lat: 48.56824, lng: 21.7990806 },
    { lat: 48.5687881, lng: 21.7987044 },
    { lat: 48.5697735, lng: 21.7983159 },
    { lat: 48.5700094, lng: 21.7937242 },
    { lat: 48.5701465, lng: 21.7920101 },
    { lat: 48.5703591, lng: 21.7913636 },
    { lat: 48.5703551, lng: 21.7912125 },
    { lat: 48.5701078, lng: 21.7903708 },
    { lat: 48.5699924, lng: 21.7878267 },
    { lat: 48.568815, lng: 21.7860127 },
    { lat: 48.5675042, lng: 21.7820971 },
    { lat: 48.5644799, lng: 21.7799225 },
    { lat: 48.5639264, lng: 21.7791131 },
    { lat: 48.5634388, lng: 21.7795644 },
    { lat: 48.5628578, lng: 21.7784044 },
    { lat: 48.5629502, lng: 21.7781893 },
    { lat: 48.5624607, lng: 21.7763188 },
    { lat: 48.562405, lng: 21.775194 },
    { lat: 48.5626412, lng: 21.7746396 },
    { lat: 48.5633128, lng: 21.7742536 },
    { lat: 48.5622736, lng: 21.7706213 },
    { lat: 48.561714, lng: 21.7689093 },
    { lat: 48.5645007, lng: 21.7463118 },
    { lat: 48.5616005, lng: 21.7472529 },
    { lat: 48.5607901, lng: 21.7503084 },
    { lat: 48.5600329, lng: 21.7539328 },
    { lat: 48.5594442, lng: 21.7539713 },
    { lat: 48.5594117, lng: 21.7537228 },
    { lat: 48.5576415, lng: 21.754128 },
    { lat: 48.5575117, lng: 21.7535996 },
    { lat: 48.5516087, lng: 21.7543825 },
    { lat: 48.5511492, lng: 21.7556262 },
    { lat: 48.5514235, lng: 21.7565873 },
    { lat: 48.5488901, lng: 21.7576155 },
    { lat: 48.5491919, lng: 21.7584655 },
    { lat: 48.550114, lng: 21.7583622 },
    { lat: 48.5505948, lng: 21.758718 },
    { lat: 48.5499277, lng: 21.7593947 },
    { lat: 48.5484937, lng: 21.7599249 },
    { lat: 48.5470634, lng: 21.7602876 },
    { lat: 48.5450137, lng: 21.7611045 },
    { lat: 48.5448158, lng: 21.7606837 },
    { lat: 48.5445964, lng: 21.7605993 },
    { lat: 48.5440998, lng: 21.760544 },
    { lat: 48.5440663, lng: 21.7603831 },
    { lat: 48.5411524, lng: 21.761151 },
    { lat: 48.5410395, lng: 21.7595672 },
    { lat: 48.5403955, lng: 21.7601017 },
    { lat: 48.5396092, lng: 21.7606161 },
    { lat: 48.5386226, lng: 21.7609558 },
    { lat: 48.5377152, lng: 21.761398 },
    { lat: 48.5365198, lng: 21.7625199 },
    { lat: 48.5346885, lng: 21.7639706 },
    { lat: 48.5315333, lng: 21.7653104 },
    { lat: 48.5306867, lng: 21.7657551 },
    { lat: 48.529977, lng: 21.7659377 },
    { lat: 48.5289501, lng: 21.7660756 },
    { lat: 48.5278232, lng: 21.7665098 },
    { lat: 48.5271324, lng: 21.7666785 },
    { lat: 48.5270961, lng: 21.7665025 },
    { lat: 48.5245506, lng: 21.7672026 },
    { lat: 48.5249355, lng: 21.7700025 },
    { lat: 48.5241833, lng: 21.7705231 },
    { lat: 48.5241608, lng: 21.770654 },
    { lat: 48.5234785, lng: 21.7710631 },
    { lat: 48.5235552, lng: 21.7718262 },
    { lat: 48.5232921, lng: 21.7719602 },
    { lat: 48.5232826, lng: 21.7723228 },
    { lat: 48.523251, lng: 21.7723247 },
    { lat: 48.5233228, lng: 21.7729053 },
    { lat: 48.5229051, lng: 21.7728548 },
    { lat: 48.5226329, lng: 21.7729375 },
    { lat: 48.5223256, lng: 21.7733617 },
    { lat: 48.5218771, lng: 21.7741833 },
    { lat: 48.5217794, lng: 21.7748646 },
    { lat: 48.5220495, lng: 21.7779318 },
    { lat: 48.5206407, lng: 21.7779614 },
  ],
  Cejkov: [
    { lat: 48.4627695, lng: 21.8160704 },
    { lat: 48.4635722, lng: 21.8169853 },
    { lat: 48.4645805, lng: 21.8186333 },
    { lat: 48.4650597, lng: 21.819098 },
    { lat: 48.4656409, lng: 21.8195121 },
    { lat: 48.4659272, lng: 21.8196154 },
    { lat: 48.46626, lng: 21.819626 },
    { lat: 48.466656, lng: 21.819428 },
    { lat: 48.466959, lng: 21.819285 },
    { lat: 48.467439, lng: 21.819064 },
    { lat: 48.467661, lng: 21.818969 },
    { lat: 48.467786, lng: 21.81892 },
    { lat: 48.467856, lng: 21.81888 },
    { lat: 48.467952, lng: 21.818847 },
    { lat: 48.468087, lng: 21.818543 },
    { lat: 48.468094, lng: 21.818527 },
    { lat: 48.468149, lng: 21.818372 },
    { lat: 48.468217, lng: 21.818249 },
    { lat: 48.468241, lng: 21.818069 },
    { lat: 48.468272, lng: 21.817772 },
    { lat: 48.468269, lng: 21.817704 },
    { lat: 48.468256, lng: 21.817348 },
    { lat: 48.468244, lng: 21.817186 },
    { lat: 48.4682, lng: 21.81696 },
    { lat: 48.468132, lng: 21.816618 },
    { lat: 48.468073, lng: 21.816469 },
    { lat: 48.46807, lng: 21.816463 },
    { lat: 48.467828, lng: 21.815989 },
    { lat: 48.467594, lng: 21.815559 },
    { lat: 48.46723, lng: 21.814904 },
    { lat: 48.466994, lng: 21.814359 },
    { lat: 48.46682, lng: 21.813934 },
    { lat: 48.466699, lng: 21.813491 },
    { lat: 48.466682, lng: 21.813392 },
    { lat: 48.466484, lng: 21.812799 },
    { lat: 48.46634, lng: 21.812312 },
    { lat: 48.466202, lng: 21.811701 },
    { lat: 48.466217, lng: 21.81123 },
    { lat: 48.466319, lng: 21.810557 },
    { lat: 48.466455, lng: 21.810185 },
    { lat: 48.466656, lng: 21.809859 },
    { lat: 48.466782, lng: 21.809742 },
    { lat: 48.466853, lng: 21.809675 },
    { lat: 48.466929, lng: 21.809626 },
    { lat: 48.467005, lng: 21.809589 },
    { lat: 48.467138, lng: 21.809556 },
    { lat: 48.4670499, lng: 21.8091809 },
    { lat: 48.4665823, lng: 21.8068426 },
    { lat: 48.467305, lng: 21.8065139 },
    { lat: 48.4675466, lng: 21.8065839 },
    { lat: 48.4684221, lng: 21.8063373 },
    { lat: 48.4693008, lng: 21.806255 },
    { lat: 48.4703446, lng: 21.805944 },
    { lat: 48.4719832, lng: 21.8057059 },
    { lat: 48.4723281, lng: 21.8057233 },
    { lat: 48.4727937, lng: 21.8058485 },
    { lat: 48.4729244, lng: 21.8058145 },
    { lat: 48.4730537, lng: 21.8056845 },
    { lat: 48.4730671, lng: 21.8047331 },
    { lat: 48.4732068, lng: 21.8043135 },
    { lat: 48.4732399, lng: 21.8040321 },
    { lat: 48.4733152, lng: 21.8039 },
    { lat: 48.4737321, lng: 21.8037132 },
    { lat: 48.474435, lng: 21.8037823 },
    { lat: 48.4746275, lng: 21.8033621 },
    { lat: 48.4747708, lng: 21.8031663 },
    { lat: 48.4755399, lng: 21.8027982 },
    { lat: 48.4754784, lng: 21.8023417 },
    { lat: 48.4756591, lng: 21.802116 },
    { lat: 48.4756526, lng: 21.8018207 },
    { lat: 48.475475, lng: 21.800887 },
    { lat: 48.4758078, lng: 21.8002048 },
    { lat: 48.4759007, lng: 21.7997557 },
    { lat: 48.4759752, lng: 21.7996311 },
    { lat: 48.4761432, lng: 21.7994381 },
    { lat: 48.477183, lng: 21.798762 },
    { lat: 48.477737, lng: 21.7980967 },
    { lat: 48.478677, lng: 21.796746 },
    { lat: 48.4794734, lng: 21.7960124 },
    { lat: 48.479498, lng: 21.7960524 },
    { lat: 48.4795529, lng: 21.7959959 },
    { lat: 48.4795862, lng: 21.7960691 },
    { lat: 48.4796225, lng: 21.7960351 },
    { lat: 48.4795949, lng: 21.7959715 },
    { lat: 48.4802939, lng: 21.7956851 },
    { lat: 48.4805528, lng: 21.7956487 },
    { lat: 48.4817966, lng: 21.794645 },
    { lat: 48.4828551, lng: 21.7932279 },
    { lat: 48.4828593, lng: 21.7930053 },
    { lat: 48.4829146, lng: 21.7914724 },
    { lat: 48.482888, lng: 21.7903839 },
    { lat: 48.4825815, lng: 21.7903231 },
    { lat: 48.4822285, lng: 21.7899527 },
    { lat: 48.4821897, lng: 21.7899759 },
    { lat: 48.4816209, lng: 21.7886669 },
    { lat: 48.4812842, lng: 21.7881023 },
    { lat: 48.4810797, lng: 21.7876099 },
    { lat: 48.4808945, lng: 21.7873635 },
    { lat: 48.4807184, lng: 21.7872556 },
    { lat: 48.4803932, lng: 21.7867459 },
    { lat: 48.4802025, lng: 21.7867651 },
    { lat: 48.4801096, lng: 21.7866129 },
    { lat: 48.4798019, lng: 21.7859164 },
    { lat: 48.4795014, lng: 21.7854404 },
    { lat: 48.4791597, lng: 21.7837952 },
    { lat: 48.4790556, lng: 21.7834784 },
    { lat: 48.48072, lng: 21.7827273 },
    { lat: 48.481116, lng: 21.7824674 },
    { lat: 48.4809164, lng: 21.7815616 },
    { lat: 48.482418, lng: 21.7809939 },
    { lat: 48.4833035, lng: 21.7805273 },
    { lat: 48.4831788, lng: 21.7799401 },
    { lat: 48.4830795, lng: 21.7791222 },
    { lat: 48.4827229, lng: 21.7776417 },
    { lat: 48.4831023, lng: 21.7772231 },
    { lat: 48.4831843, lng: 21.7770451 },
    { lat: 48.4832355, lng: 21.7767715 },
    { lat: 48.483117, lng: 21.776463 },
    { lat: 48.482898, lng: 21.7761335 },
    { lat: 48.4826847, lng: 21.7755161 },
    { lat: 48.4829297, lng: 21.7753383 },
    { lat: 48.4823426, lng: 21.7735252 },
    { lat: 48.4827528, lng: 21.7721501 },
    { lat: 48.4829029, lng: 21.771791 },
    { lat: 48.4831231, lng: 21.7716026 },
    { lat: 48.4831675, lng: 21.7710289 },
    { lat: 48.4833431, lng: 21.770702 },
    { lat: 48.4839052, lng: 21.769999 },
    { lat: 48.4836107, lng: 21.7684485 },
    { lat: 48.4830943, lng: 21.7676493 },
    { lat: 48.4828337, lng: 21.7669451 },
    { lat: 48.4828903, lng: 21.7665732 },
    { lat: 48.4830948, lng: 21.7662669 },
    { lat: 48.4832021, lng: 21.7659845 },
    { lat: 48.4832102, lng: 21.7657822 },
    { lat: 48.4829571, lng: 21.7654063 },
    { lat: 48.4829165, lng: 21.7651052 },
    { lat: 48.4828288, lng: 21.7649803 },
    { lat: 48.4828464, lng: 21.764881 },
    { lat: 48.4829038, lng: 21.7648843 },
    { lat: 48.4828688, lng: 21.7644991 },
    { lat: 48.4827705, lng: 21.7643575 },
    { lat: 48.4825314, lng: 21.7642053 },
    { lat: 48.4817639, lng: 21.7641586 },
    { lat: 48.4801629, lng: 21.7621795 },
    { lat: 48.479886, lng: 21.7616178 },
    { lat: 48.4808725, lng: 21.7599678 },
    { lat: 48.481795, lng: 21.760088 },
    { lat: 48.4818765, lng: 21.7599792 },
    { lat: 48.4819737, lng: 21.7595392 },
    { lat: 48.482006, lng: 21.7580482 },
    { lat: 48.4821815, lng: 21.7565761 },
    { lat: 48.4823271, lng: 21.7561637 },
    { lat: 48.4825241, lng: 21.7558777 },
    { lat: 48.482587, lng: 21.7555334 },
    { lat: 48.4825814, lng: 21.7549855 },
    { lat: 48.4826682, lng: 21.7543617 },
    { lat: 48.4825314, lng: 21.7535841 },
    { lat: 48.48237, lng: 21.7531389 },
    { lat: 48.4820402, lng: 21.7527169 },
    { lat: 48.4818654, lng: 21.7524048 },
    { lat: 48.4816527, lng: 21.7521893 },
    { lat: 48.4814597, lng: 21.7520896 },
    { lat: 48.4812849, lng: 21.7518633 },
    { lat: 48.481159, lng: 21.7512276 },
    { lat: 48.4800649, lng: 21.7483157 },
    { lat: 48.479405, lng: 21.7483368 },
    { lat: 48.4790623, lng: 21.7486041 },
    { lat: 48.478396, lng: 21.7489295 },
    { lat: 48.4770255, lng: 21.7497678 },
    { lat: 48.4767104, lng: 21.7497614 },
    { lat: 48.4765319, lng: 21.7496552 },
    { lat: 48.4763409, lng: 21.749205 },
    { lat: 48.475452, lng: 21.7482366 },
    { lat: 48.4753298, lng: 21.7478266 },
    { lat: 48.4750531, lng: 21.7459456 },
    { lat: 48.4750303, lng: 21.7446307 },
    { lat: 48.4749722, lng: 21.7443545 },
    { lat: 48.4745694, lng: 21.7433031 },
    { lat: 48.474555, lng: 21.7425558 },
    { lat: 48.4744222, lng: 21.7416756 },
    { lat: 48.47469, lng: 21.7407498 },
    { lat: 48.4748701, lng: 21.740424 },
    { lat: 48.474944, lng: 21.740032 },
    { lat: 48.4748736, lng: 21.7392569 },
    { lat: 48.4747487, lng: 21.739002 },
    { lat: 48.4747002, lng: 21.7387873 },
    { lat: 48.4746681, lng: 21.7380134 },
    { lat: 48.4745065, lng: 21.7374832 },
    { lat: 48.4744232, lng: 21.7369805 },
    { lat: 48.4745958, lng: 21.7357356 },
    { lat: 48.474672, lng: 21.7354392 },
    { lat: 48.4748329, lng: 21.7337571 },
    { lat: 48.4747475, lng: 21.7333 },
    { lat: 48.4748113, lng: 21.7329553 },
    { lat: 48.4748169, lng: 21.7323745 },
    { lat: 48.4749291, lng: 21.7315262 },
    { lat: 48.4748356, lng: 21.7305135 },
    { lat: 48.4745706, lng: 21.72949 },
    { lat: 48.474432, lng: 21.7291921 },
    { lat: 48.4741932, lng: 21.7282237 },
    { lat: 48.4739839, lng: 21.7278503 },
    { lat: 48.4736185, lng: 21.7273964 },
    { lat: 48.4731585, lng: 21.7270266 },
    { lat: 48.4727592, lng: 21.7263663 },
    { lat: 48.4726798, lng: 21.7261775 },
    { lat: 48.4726365, lng: 21.7258653 },
    { lat: 48.4724918, lng: 21.7255437 },
    { lat: 48.4720898, lng: 21.7251168 },
    { lat: 48.4719534, lng: 21.724727 },
    { lat: 48.4718869, lng: 21.7239754 },
    { lat: 48.4719067, lng: 21.7235026 },
    { lat: 48.4717872, lng: 21.723155 },
    { lat: 48.471642, lng: 21.7229015 },
    { lat: 48.4717732, lng: 21.7214333 },
    { lat: 48.4719204, lng: 21.7209187 },
    { lat: 48.4722451, lng: 21.7206138 },
    { lat: 48.4723624, lng: 21.7202893 },
    { lat: 48.4723861, lng: 21.7196741 },
    { lat: 48.4725247, lng: 21.7189735 },
    { lat: 48.4728165, lng: 21.7184035 },
    { lat: 48.4727985, lng: 21.7181125 },
    { lat: 48.4726025, lng: 21.7172749 },
    { lat: 48.4724223, lng: 21.7168884 },
    { lat: 48.4721864, lng: 21.7158189 },
    { lat: 48.471878, lng: 21.7137366 },
    { lat: 48.4718732, lng: 21.7132749 },
    { lat: 48.4717614, lng: 21.7127137 },
    { lat: 48.4717858, lng: 21.7122484 },
    { lat: 48.4717295, lng: 21.7118331 },
    { lat: 48.4721775, lng: 21.7112164 },
    { lat: 48.4723251, lng: 21.7107215 },
    { lat: 48.4723595, lng: 21.7094578 },
    { lat: 48.4723398, lng: 21.7094782 },
    { lat: 48.4720445, lng: 21.7098753 },
    { lat: 48.4713849, lng: 21.7101129 },
    { lat: 48.4712217, lng: 21.7102435 },
    { lat: 48.4704836, lng: 21.7115705 },
    { lat: 48.469707, lng: 21.7120131 },
    { lat: 48.469249, lng: 21.7120964 },
    { lat: 48.4682616, lng: 21.7125338 },
    { lat: 48.4678801, lng: 21.7134393 },
    { lat: 48.4675834, lng: 21.7135741 },
    { lat: 48.4669452, lng: 21.7140273 },
    { lat: 48.4665306, lng: 21.7140492 },
    { lat: 48.465123, lng: 21.7147104 },
    { lat: 48.4640381, lng: 21.7143673 },
    { lat: 48.4629493, lng: 21.7144195 },
    { lat: 48.4630123, lng: 21.714629 },
    { lat: 48.4633615, lng: 21.7157279 },
    { lat: 48.4636331, lng: 21.7162402 },
    { lat: 48.4637636, lng: 21.7166109 },
    { lat: 48.4636322, lng: 21.7179519 },
    { lat: 48.4635445, lng: 21.7195823 },
    { lat: 48.463544, lng: 21.7195987 },
    { lat: 48.4635721, lng: 21.7198529 },
    { lat: 48.4635453, lng: 21.720364 },
    { lat: 48.4634139, lng: 21.7209112 },
    { lat: 48.4630282, lng: 21.7217495 },
    { lat: 48.4629666, lng: 21.7219737 },
    { lat: 48.4628085, lng: 21.7221525 },
    { lat: 48.4625165, lng: 21.7226808 },
    { lat: 48.4623479, lng: 21.722845 },
    { lat: 48.4622106, lng: 21.7232245 },
    { lat: 48.4619291, lng: 21.723548 },
    { lat: 48.4613392, lng: 21.7239534 },
    { lat: 48.4609606, lng: 21.7243484 },
    { lat: 48.4604731, lng: 21.7247149 },
    { lat: 48.4598524, lng: 21.7257244 },
    { lat: 48.4596424, lng: 21.7259556 },
    { lat: 48.4593972, lng: 21.7265388 },
    { lat: 48.4584897, lng: 21.7272641 },
    { lat: 48.4579139, lng: 21.7274037 },
    { lat: 48.4568695, lng: 21.7280962 },
    { lat: 48.4566409, lng: 21.7283445 },
    { lat: 48.4564474, lng: 21.7288458 },
    { lat: 48.4561962, lng: 21.7292914 },
    { lat: 48.4559021, lng: 21.7294876 },
    { lat: 48.455112, lng: 21.7295198 },
    { lat: 48.4536656, lng: 21.7306276 },
    { lat: 48.4534179, lng: 21.7310776 },
    { lat: 48.4532526, lng: 21.7317951 },
    { lat: 48.4530473, lng: 21.7322326 },
    { lat: 48.4526614, lng: 21.7327335 },
    { lat: 48.4515829, lng: 21.7335902 },
    { lat: 48.451077, lng: 21.733811 },
    { lat: 48.4508515, lng: 21.733999 },
    { lat: 48.4506767, lng: 21.7341986 },
    { lat: 48.4501129, lng: 21.735282 },
    { lat: 48.4491138, lng: 21.7366193 },
    { lat: 48.4485337, lng: 21.7371429 },
    { lat: 48.4482913, lng: 21.7372905 },
    { lat: 48.4459795, lng: 21.7380958 },
    { lat: 48.4457563, lng: 21.7381246 },
    { lat: 48.4454646, lng: 21.7383691 },
    { lat: 48.4448662, lng: 21.7386645 },
    { lat: 48.4444553, lng: 21.7389482 },
    { lat: 48.4439439, lng: 21.7395094 },
    { lat: 48.4438487, lng: 21.7399078 },
    { lat: 48.4435096, lng: 21.7402636 },
    { lat: 48.442976, lng: 21.7403839 },
    { lat: 48.4424812, lng: 21.740387 },
    { lat: 48.442254, lng: 21.7404773 },
    { lat: 48.441747, lng: 21.7407898 },
    { lat: 48.4411866, lng: 21.7413277 },
    { lat: 48.4405422, lng: 21.7418451 },
    { lat: 48.4398463, lng: 21.7422431 },
    { lat: 48.4386343, lng: 21.7423609 },
    { lat: 48.4383445, lng: 21.7425468 },
    { lat: 48.4380945, lng: 21.7425329 },
    { lat: 48.4379624, lng: 21.743111 },
    { lat: 48.4373146, lng: 21.7427807 },
    { lat: 48.4367634, lng: 21.7422004 },
    { lat: 48.4357081, lng: 21.7415714 },
    { lat: 48.4337981, lng: 21.7417967 },
    { lat: 48.4330623, lng: 21.742056 },
    { lat: 48.4330827, lng: 21.7433136 },
    { lat: 48.4331673, lng: 21.7439551 },
    { lat: 48.4332158, lng: 21.7440397 },
    { lat: 48.4331971, lng: 21.744354 },
    { lat: 48.4329267, lng: 21.7447162 },
    { lat: 48.43228, lng: 21.7453137 },
    { lat: 48.4314884, lng: 21.7471749 },
    { lat: 48.4313701, lng: 21.7475909 },
    { lat: 48.4312469, lng: 21.7477762 },
    { lat: 48.4312238, lng: 21.7479271 },
    { lat: 48.4312091, lng: 21.7487181 },
    { lat: 48.4312361, lng: 21.7491437 },
    { lat: 48.4313429, lng: 21.7495114 },
    { lat: 48.4313645, lng: 21.750072 },
    { lat: 48.4313301, lng: 21.7504684 },
    { lat: 48.4314532, lng: 21.7517027 },
    { lat: 48.4316618, lng: 21.7517188 },
    { lat: 48.4317656, lng: 21.7517309 },
    { lat: 48.4321726, lng: 21.7515033 },
    { lat: 48.4323772, lng: 21.7515012 },
    { lat: 48.4325883, lng: 21.7514003 },
    { lat: 48.4328575, lng: 21.7511608 },
    { lat: 48.4332316, lng: 21.7513054 },
    { lat: 48.4334231, lng: 21.7515656 },
    { lat: 48.4335809, lng: 21.7519985 },
    { lat: 48.4337147, lng: 21.7531756 },
    { lat: 48.4336706, lng: 21.7535889 },
    { lat: 48.4336886, lng: 21.7535843 },
    { lat: 48.4347881, lng: 21.7535944 },
    { lat: 48.4357349, lng: 21.7539893 },
    { lat: 48.4366109, lng: 21.7540551 },
    { lat: 48.4368062, lng: 21.7539954 },
    { lat: 48.4377174, lng: 21.7542061 },
    { lat: 48.437719, lng: 21.7556935 },
    { lat: 48.4379017, lng: 21.7559656 },
    { lat: 48.438183, lng: 21.7573078 },
    { lat: 48.4393631, lng: 21.7589874 },
    { lat: 48.440659, lng: 21.760249 },
    { lat: 48.4412336, lng: 21.7605294 },
    { lat: 48.4420456, lng: 21.7611484 },
    { lat: 48.4425509, lng: 21.762203 },
    { lat: 48.4429109, lng: 21.7623706 },
    { lat: 48.4441346, lng: 21.7639209 },
    { lat: 48.4451355, lng: 21.7648681 },
    { lat: 48.4453775, lng: 21.7652179 },
    { lat: 48.4457019, lng: 21.7655269 },
    { lat: 48.4456208, lng: 21.7659428 },
    { lat: 48.4456526, lng: 21.7666699 },
    { lat: 48.4457264, lng: 21.767074 },
    { lat: 48.4458641, lng: 21.7686623 },
    { lat: 48.4460276, lng: 21.7694721 },
    { lat: 48.4465936, lng: 21.7711267 },
    { lat: 48.446772, lng: 21.7714056 },
    { lat: 48.4477778, lng: 21.7718693 },
    { lat: 48.4481099, lng: 21.7721307 },
    { lat: 48.4488354, lng: 21.7735179 },
    { lat: 48.4492044, lng: 21.7743622 },
    { lat: 48.4502734, lng: 21.7759319 },
    { lat: 48.4507351, lng: 21.7772227 },
    { lat: 48.4510613, lng: 21.7785353 },
    { lat: 48.4513486, lng: 21.7793149 },
    { lat: 48.4517404, lng: 21.7799176 },
    { lat: 48.4520801, lng: 21.7799951 },
    { lat: 48.4527603, lng: 21.7795514 },
    { lat: 48.4536229, lng: 21.7794139 },
    { lat: 48.4538032, lng: 21.7792525 },
    { lat: 48.4540265, lng: 21.7788535 },
    { lat: 48.4542131, lng: 21.77876 },
    { lat: 48.4542555, lng: 21.7787841 },
    { lat: 48.4544724, lng: 21.7794622 },
    { lat: 48.4553295, lng: 21.7794091 },
    { lat: 48.4561613, lng: 21.7794649 },
    { lat: 48.4565276, lng: 21.7797814 },
    { lat: 48.4568988, lng: 21.7798666 },
    { lat: 48.4572334, lng: 21.7800532 },
    { lat: 48.4575662, lng: 21.7813136 },
    { lat: 48.4576569, lng: 21.7818997 },
    { lat: 48.4579844, lng: 21.7827099 },
    { lat: 48.4585091, lng: 21.7835665 },
    { lat: 48.4587531, lng: 21.7836929 },
    { lat: 48.45939, lng: 21.7834811 },
    { lat: 48.4599431, lng: 21.7839705 },
    { lat: 48.4603278, lng: 21.7846912 },
    { lat: 48.4604639, lng: 21.7847366 },
    { lat: 48.4605812, lng: 21.784877 },
    { lat: 48.4609299, lng: 21.7858934 },
    { lat: 48.4612356, lng: 21.7862611 },
    { lat: 48.4613626, lng: 21.7865081 },
    { lat: 48.4615014, lng: 21.7870776 },
    { lat: 48.4612809, lng: 21.7875481 },
    { lat: 48.4613515, lng: 21.7880047 },
    { lat: 48.4613046, lng: 21.7881486 },
    { lat: 48.4613568, lng: 21.7885003 },
    { lat: 48.4618095, lng: 21.7895807 },
    { lat: 48.4621369, lng: 21.7893278 },
    { lat: 48.4630494, lng: 21.790099 },
    { lat: 48.4631055, lng: 21.790214 },
    { lat: 48.4632155, lng: 21.7911029 },
    { lat: 48.4634722, lng: 21.7914052 },
    { lat: 48.463585, lng: 21.7917211 },
    { lat: 48.4642987, lng: 21.7923768 },
    { lat: 48.465231, lng: 21.7930852 },
    { lat: 48.4653187, lng: 21.7933795 },
    { lat: 48.4652587, lng: 21.7937855 },
    { lat: 48.4653487, lng: 21.7938737 },
    { lat: 48.4659838, lng: 21.7938549 },
    { lat: 48.4658458, lng: 21.7951029 },
    { lat: 48.465777, lng: 21.7951843 },
    { lat: 48.4658573, lng: 21.7957524 },
    { lat: 48.4658259, lng: 21.7959298 },
    { lat: 48.4656451, lng: 21.7960643 },
    { lat: 48.4654073, lng: 21.7960958 },
    { lat: 48.4653406, lng: 21.7961668 },
    { lat: 48.4652702, lng: 21.7970835 },
    { lat: 48.4653225, lng: 21.7973151 },
    { lat: 48.4650751, lng: 21.7973203 },
    { lat: 48.4647165, lng: 21.7975894 },
    { lat: 48.4645707, lng: 21.7975676 },
    { lat: 48.4640978, lng: 21.798581 },
    { lat: 48.4640905, lng: 21.7987229 },
    { lat: 48.4639142, lng: 21.7992531 },
    { lat: 48.4636746, lng: 21.7995559 },
    { lat: 48.4635621, lng: 21.7995966 },
    { lat: 48.4634383, lng: 21.7993807 },
    { lat: 48.46268, lng: 21.7998551 },
    { lat: 48.4626502, lng: 21.7999562 },
    { lat: 48.4624492, lng: 21.8000139 },
    { lat: 48.4622238, lng: 21.8002556 },
    { lat: 48.4619465, lng: 21.8007113 },
    { lat: 48.4617698, lng: 21.8022479 },
    { lat: 48.4612784, lng: 21.8028542 },
    { lat: 48.4607819, lng: 21.803358 },
    { lat: 48.4602047, lng: 21.8038199 },
    { lat: 48.4595136, lng: 21.8044994 },
    { lat: 48.459169, lng: 21.8049161 },
    { lat: 48.4593177, lng: 21.8057378 },
    { lat: 48.4594661, lng: 21.8056872 },
    { lat: 48.4604048, lng: 21.8091297 },
    { lat: 48.4621983, lng: 21.8099067 },
    { lat: 48.4630196, lng: 21.8118328 },
    { lat: 48.4632079, lng: 21.8136899 },
    { lat: 48.4630093, lng: 21.8154337 },
    { lat: 48.4627695, lng: 21.8160704 },
  ],
  KráľovskýChlmec: [
    { lat: 48.4468262, lng: 21.9731701 },
    { lat: 48.4467419, lng: 21.9727652 },
    { lat: 48.4467855, lng: 21.9725278 },
    { lat: 48.4471085, lng: 21.9720834 },
    { lat: 48.4472411, lng: 21.971622 },
    { lat: 48.4470931, lng: 21.9705524 },
    { lat: 48.4471031, lng: 21.9700065 },
    { lat: 48.4471503, lng: 21.9694574 },
    { lat: 48.4474059, lng: 21.9679739 },
    { lat: 48.4480154, lng: 21.9665657 },
    { lat: 48.4482658, lng: 21.9654598 },
    { lat: 48.4484121, lng: 21.9641585 },
    { lat: 48.448632, lng: 21.9630508 },
    { lat: 48.4488706, lng: 21.9622376 },
    { lat: 48.449329, lng: 21.9612698 },
    { lat: 48.449499, lng: 21.9580297 },
    { lat: 48.4495708, lng: 21.9574782 },
    { lat: 48.4492253, lng: 21.954404 },
    { lat: 48.4492406, lng: 21.954215 },
    { lat: 48.4496816, lng: 21.9524593 },
    { lat: 48.4499086, lng: 21.9517221 },
    { lat: 48.4500014, lng: 21.9512108 },
    { lat: 48.4500936, lng: 21.9501761 },
    { lat: 48.4500695, lng: 21.9498809 },
    { lat: 48.4499209, lng: 21.9492954 },
    { lat: 48.4496591, lng: 21.9488194 },
    { lat: 48.4498399, lng: 21.9481294 },
    { lat: 48.4497014, lng: 21.9479852 },
    { lat: 48.4503214, lng: 21.9472318 },
    { lat: 48.4504375, lng: 21.9463425 },
    { lat: 48.4507073, lng: 21.9456624 },
    { lat: 48.4511236, lng: 21.9450303 },
    { lat: 48.4511436, lng: 21.9445756 },
    { lat: 48.4510819, lng: 21.9439128 },
    { lat: 48.4511107, lng: 21.9433495 },
    { lat: 48.4483565, lng: 21.9451853 },
    { lat: 48.4479008, lng: 21.9439716 },
    { lat: 48.4472117, lng: 21.9424206 },
    { lat: 48.4473855, lng: 21.9421808 },
    { lat: 48.4479746, lng: 21.9421591 },
    { lat: 48.4485183, lng: 21.9418146 },
    { lat: 48.4486937, lng: 21.9417664 },
    { lat: 48.4489174, lng: 21.9413146 },
    { lat: 48.4494546, lng: 21.9407987 },
    { lat: 48.4496684, lng: 21.9403939 },
    { lat: 48.4497692, lng: 21.9401525 },
    { lat: 48.4495135, lng: 21.9395582 },
    { lat: 48.4494665, lng: 21.9392068 },
    { lat: 48.449478, lng: 21.9389046 },
    { lat: 48.4496084, lng: 21.9385492 },
    { lat: 48.4500825, lng: 21.9378943 },
    { lat: 48.4504349, lng: 21.9361573 },
    { lat: 48.4505544, lng: 21.9355374 },
    { lat: 48.4500804, lng: 21.9355686 },
    { lat: 48.4496892, lng: 21.9357138 },
    { lat: 48.4492452, lng: 21.9360502 },
    { lat: 48.4490816, lng: 21.936067 },
    { lat: 48.4486809, lng: 21.9358835 },
    { lat: 48.4485375, lng: 21.9356372 },
    { lat: 48.4485293, lng: 21.9353598 },
    { lat: 48.4484138, lng: 21.9350585 },
    { lat: 48.4481496, lng: 21.9349424 },
    { lat: 48.447855, lng: 21.934946 },
    { lat: 48.4478625, lng: 21.9350186 },
    { lat: 48.4463191, lng: 21.9353856 },
    { lat: 48.4452909, lng: 21.935832 },
    { lat: 48.4442027, lng: 21.936176 },
    { lat: 48.443888, lng: 21.9364114 },
    { lat: 48.4433246, lng: 21.9370033 },
    { lat: 48.4427787, lng: 21.9373447 },
    { lat: 48.4424086, lng: 21.9373941 },
    { lat: 48.4418617, lng: 21.9376108 },
    { lat: 48.4414569, lng: 21.9375339 },
    { lat: 48.4412066, lng: 21.9373305 },
    { lat: 48.4407715, lng: 21.9372521 },
    { lat: 48.4407519, lng: 21.9368355 },
    { lat: 48.4404626, lng: 21.9367807 },
    { lat: 48.4402114, lng: 21.9369056 },
    { lat: 48.4398313, lng: 21.936913 },
    { lat: 48.4393472, lng: 21.9358684 },
    { lat: 48.4391056, lng: 21.9354778 },
    { lat: 48.4386659, lng: 21.9351747 },
    { lat: 48.4384578, lng: 21.9351674 },
    { lat: 48.4382141, lng: 21.9348791 },
    { lat: 48.4366511, lng: 21.9355383 },
    { lat: 48.436477, lng: 21.935138 },
    { lat: 48.4363137, lng: 21.935228 },
    { lat: 48.4361744, lng: 21.9349656 },
    { lat: 48.4359314, lng: 21.9342454 },
    { lat: 48.4352518, lng: 21.9340725 },
    { lat: 48.4349494, lng: 21.9341191 },
    { lat: 48.4349172, lng: 21.9337426 },
    { lat: 48.4341314, lng: 21.934031 },
    { lat: 48.4337637, lng: 21.9340771 },
    { lat: 48.4335683, lng: 21.9332939 },
    { lat: 48.4331001, lng: 21.9333312 },
    { lat: 48.4322861, lng: 21.9335277 },
    { lat: 48.430201, lng: 21.9346286 },
    { lat: 48.4293253, lng: 21.9355994 },
    { lat: 48.4291497, lng: 21.9358623 },
    { lat: 48.4285104, lng: 21.9362497 },
    { lat: 48.4280862, lng: 21.9366092 },
    { lat: 48.4279167, lng: 21.9368427 },
    { lat: 48.4268628, lng: 21.9374764 },
    { lat: 48.424435, lng: 21.9386833 },
    { lat: 48.4239076, lng: 21.9390136 },
    { lat: 48.4239232, lng: 21.9391338 },
    { lat: 48.4241502, lng: 21.9408799 },
    { lat: 48.4246533, lng: 21.9437059 },
    { lat: 48.4246269, lng: 21.9441374 },
    { lat: 48.4244821, lng: 21.9450439 },
    { lat: 48.4240294, lng: 21.9471752 },
    { lat: 48.4238444, lng: 21.9477435 },
    { lat: 48.423701, lng: 21.948391 },
    { lat: 48.4237562, lng: 21.9493417 },
    { lat: 48.4239302, lng: 21.9502252 },
    { lat: 48.4235932, lng: 21.950823 },
    { lat: 48.4235299, lng: 21.9514418 },
    { lat: 48.4233838, lng: 21.9518953 },
    { lat: 48.4233077, lng: 21.9523214 },
    { lat: 48.4230599, lng: 21.9522157 },
    { lat: 48.4224343, lng: 21.9521249 },
    { lat: 48.421624, lng: 21.9522455 },
    { lat: 48.420758, lng: 21.952215 },
    { lat: 48.4198665, lng: 21.9524156 },
    { lat: 48.4193858, lng: 21.9521339 },
    { lat: 48.4191093, lng: 21.952061 },
    { lat: 48.4187366, lng: 21.9520895 },
    { lat: 48.4186711, lng: 21.9521743 },
    { lat: 48.4183238, lng: 21.9523133 },
    { lat: 48.417774, lng: 21.9522363 },
    { lat: 48.4175275, lng: 21.9522803 },
    { lat: 48.4175301, lng: 21.9524764 },
    { lat: 48.4171611, lng: 21.9525695 },
    { lat: 48.4171462, lng: 21.952332 },
    { lat: 48.4168839, lng: 21.9522806 },
    { lat: 48.416881, lng: 21.9522088 },
    { lat: 48.4160715, lng: 21.9520042 },
    { lat: 48.4159727, lng: 21.9522786 },
    { lat: 48.4156937, lng: 21.9522816 },
    { lat: 48.4157136, lng: 21.9520407 },
    { lat: 48.4156647, lng: 21.9520172 },
    { lat: 48.4156563, lng: 21.9516848 },
    { lat: 48.4153118, lng: 21.9517195 },
    { lat: 48.4152861, lng: 21.9511922 },
    { lat: 48.4149501, lng: 21.9512916 },
    { lat: 48.4149234, lng: 21.9512149 },
    { lat: 48.4147319, lng: 21.9512469 },
    { lat: 48.4145325, lng: 21.9508835 },
    { lat: 48.4145019, lng: 21.9505549 },
    { lat: 48.4143803, lng: 21.9505947 },
    { lat: 48.4142174, lng: 21.9497593 },
    { lat: 48.4138829, lng: 21.9499205 },
    { lat: 48.4138016, lng: 21.950138 },
    { lat: 48.4136596, lng: 21.9502547 },
    { lat: 48.4125092, lng: 21.9508134 },
    { lat: 48.4125203, lng: 21.9509466 },
    { lat: 48.4119838, lng: 21.9515775 },
    { lat: 48.4112652, lng: 21.9520395 },
    { lat: 48.4113999, lng: 21.9530209 },
    { lat: 48.4112306, lng: 21.9531792 },
    { lat: 48.411337, lng: 21.9539207 },
    { lat: 48.4093492, lng: 21.9546908 },
    { lat: 48.4093846, lng: 21.9548461 },
    { lat: 48.4086559, lng: 21.9553057 },
    { lat: 48.4090829, lng: 21.9558269 },
    { lat: 48.4094863, lng: 21.9565265 },
    { lat: 48.4091141, lng: 21.9570741 },
    { lat: 48.4084342, lng: 21.9577834 },
    { lat: 48.408774, lng: 21.9589954 },
    { lat: 48.4086604, lng: 21.9590903 },
    { lat: 48.4086764, lng: 21.9596833 },
    { lat: 48.4087386, lng: 21.9605986 },
    { lat: 48.408929, lng: 21.9613779 },
    { lat: 48.4087416, lng: 21.9622503 },
    { lat: 48.4087454, lng: 21.9625436 },
    { lat: 48.408663, lng: 21.9628025 },
    { lat: 48.4086911, lng: 21.9641068 },
    { lat: 48.408604, lng: 21.9650769 },
    { lat: 48.4084479, lng: 21.9658134 },
    { lat: 48.4085415, lng: 21.9665924 },
    { lat: 48.4085454, lng: 21.9669746 },
    { lat: 48.4083616, lng: 21.96768 },
    { lat: 48.4037674, lng: 21.9706332 },
    { lat: 48.4036955, lng: 21.970406 },
    { lat: 48.4028312, lng: 21.970947 },
    { lat: 48.4015997, lng: 21.9713167 },
    { lat: 48.4011194, lng: 21.9712767 },
    { lat: 48.4007807, lng: 21.971135 },
    { lat: 48.400465, lng: 21.9708893 },
    { lat: 48.4003908, lng: 21.9710701 },
    { lat: 48.3985375, lng: 21.9711886 },
    { lat: 48.3985048, lng: 21.9716839 },
    { lat: 48.3988145, lng: 21.9739107 },
    { lat: 48.3988033, lng: 21.9745025 },
    { lat: 48.3996892, lng: 21.9752902 },
    { lat: 48.3999802, lng: 21.9757295 },
    { lat: 48.4001986, lng: 21.9762855 },
    { lat: 48.4003974, lng: 21.9769905 },
    { lat: 48.4007961, lng: 21.979091 },
    { lat: 48.4015134, lng: 21.9806653 },
    { lat: 48.4018845, lng: 21.9810673 },
    { lat: 48.4027605, lng: 21.9816361 },
    { lat: 48.4031178, lng: 21.9818024 },
    { lat: 48.4038194, lng: 21.98188 },
    { lat: 48.4055647, lng: 21.9815307 },
    { lat: 48.4062993, lng: 21.9815785 },
    { lat: 48.4073097, lng: 21.9818552 },
    { lat: 48.4081989, lng: 21.982463 },
    { lat: 48.4085399, lng: 21.9831047 },
    { lat: 48.4087065, lng: 21.9836084 },
    { lat: 48.4088397, lng: 21.9838535 },
    { lat: 48.4098541, lng: 21.9853792 },
    { lat: 48.4102835, lng: 21.9855908 },
    { lat: 48.4103689, lng: 21.9857751 },
    { lat: 48.4107858, lng: 21.9857054 },
    { lat: 48.4112225, lng: 21.985982 },
    { lat: 48.4114833, lng: 21.9865301 },
    { lat: 48.4114603, lng: 21.9871897 },
    { lat: 48.4112502, lng: 21.9878859 },
    { lat: 48.4111549, lng: 21.9885274 },
    { lat: 48.4111277, lng: 21.9887107 },
    { lat: 48.4109513, lng: 21.9903569 },
    { lat: 48.4109802, lng: 21.9909349 },
    { lat: 48.4117049, lng: 21.9941855 },
    { lat: 48.4117217, lng: 21.9942608 },
    { lat: 48.4116841, lng: 21.994612 },
    { lat: 48.4113822, lng: 21.9954181 },
    { lat: 48.4113913, lng: 21.995555 },
    { lat: 48.4115488, lng: 21.9956426 },
    { lat: 48.4119356, lng: 21.9956795 },
    { lat: 48.4126709, lng: 21.9980788 },
    { lat: 48.4131472, lng: 21.9982389 },
    { lat: 48.4137567, lng: 21.9987299 },
    { lat: 48.4139434, lng: 21.9990088 },
    { lat: 48.4142929, lng: 21.9997196 },
    { lat: 48.4146629, lng: 21.9991615 },
    { lat: 48.4151438, lng: 21.9989127 },
    { lat: 48.415426, lng: 21.9986718 },
    { lat: 48.4157472, lng: 21.998286 },
    { lat: 48.4160879, lng: 21.997551 },
    { lat: 48.4161329, lng: 21.9973215 },
    { lat: 48.4162822, lng: 21.9972186 },
    { lat: 48.4166347, lng: 21.9973722 },
    { lat: 48.4169731, lng: 21.9992803 },
    { lat: 48.4171499, lng: 21.999972 },
    { lat: 48.4172326, lng: 21.9999961 },
    { lat: 48.4172137, lng: 22.0002369 },
    { lat: 48.4175667, lng: 22.0014058 },
    { lat: 48.417727, lng: 22.0020787 },
    { lat: 48.4178335, lng: 22.0027938 },
    { lat: 48.4192449, lng: 22.0062489 },
    { lat: 48.4193038, lng: 22.0062666 },
    { lat: 48.4192929, lng: 22.0070134 },
    { lat: 48.4193574, lng: 22.0075375 },
    { lat: 48.4197957, lng: 22.0098546 },
    { lat: 48.419958, lng: 22.0112885 },
    { lat: 48.4199675, lng: 22.0117625 },
    { lat: 48.4198706, lng: 22.0130889 },
    { lat: 48.4195504, lng: 22.0147159 },
    { lat: 48.4185361, lng: 22.0192375 },
    { lat: 48.4190498, lng: 22.0197284 },
    { lat: 48.4192771, lng: 22.020184 },
    { lat: 48.4196524, lng: 22.0205391 },
    { lat: 48.4197732, lng: 22.0205547 },
    { lat: 48.4220545, lng: 22.0230846 },
    { lat: 48.4222567, lng: 22.0235725 },
    { lat: 48.423093, lng: 22.0251634 },
    { lat: 48.4233143, lng: 22.0254686 },
    { lat: 48.4237319, lng: 22.0262663 },
    { lat: 48.4242079, lng: 22.0275138 },
    { lat: 48.424118, lng: 22.027608 },
    { lat: 48.4243946, lng: 22.0281634 },
    { lat: 48.4246589, lng: 22.0285243 },
    { lat: 48.4251792, lng: 22.0289675 },
    { lat: 48.4262376, lng: 22.0296028 },
    { lat: 48.4265303, lng: 22.0299282 },
    { lat: 48.4268799, lng: 22.0305685 },
    { lat: 48.4271373, lng: 22.031487 },
    { lat: 48.4272195, lng: 22.0327353 },
    { lat: 48.4276359, lng: 22.0327 },
    { lat: 48.4284787, lng: 22.0325656 },
    { lat: 48.4285926, lng: 22.0324382 },
    { lat: 48.4289211, lng: 22.0319302 },
    { lat: 48.4298858, lng: 22.0300543 },
    { lat: 48.4302019, lng: 22.0293182 },
    { lat: 48.4304821, lng: 22.0288579 },
    { lat: 48.4308671, lng: 22.027989 },
    { lat: 48.4311885, lng: 22.0275686 },
    { lat: 48.4316357, lng: 22.0286035 },
    { lat: 48.4318168, lng: 22.0288766 },
    { lat: 48.4322729, lng: 22.0293191 },
    { lat: 48.4327952, lng: 22.0284558 },
    { lat: 48.4330031, lng: 22.0279324 },
    { lat: 48.4332689, lng: 22.026979 },
    { lat: 48.4336178, lng: 22.026709 },
    { lat: 48.4338832, lng: 22.0263277 },
    { lat: 48.4341584, lng: 22.0257146 },
    { lat: 48.4345411, lng: 22.0250782 },
    { lat: 48.4346672, lng: 22.0241618 },
    { lat: 48.4348317, lng: 22.023705 },
    { lat: 48.4351173, lng: 22.0231993 },
    { lat: 48.4352144, lng: 22.0228031 },
    { lat: 48.4350803, lng: 22.022283 },
    { lat: 48.4350011, lng: 22.0216143 },
    { lat: 48.436165, lng: 22.0202655 },
    { lat: 48.4364489, lng: 22.0200004 },
    { lat: 48.4368007, lng: 22.0200317 },
    { lat: 48.4368578, lng: 22.0197936 },
    { lat: 48.4366684, lng: 22.0189003 },
    { lat: 48.4362983, lng: 22.017803 },
    { lat: 48.4360105, lng: 22.0160138 },
    { lat: 48.4356251, lng: 22.0145603 },
    { lat: 48.4354592, lng: 22.0141762 },
    { lat: 48.4349627, lng: 22.0136074 },
    { lat: 48.4354293, lng: 22.0127753 },
    { lat: 48.4358487, lng: 22.0123018 },
    { lat: 48.436354, lng: 22.0115281 },
    { lat: 48.4365051, lng: 22.0110872 },
    { lat: 48.4366824, lng: 22.0107493 },
    { lat: 48.4363395, lng: 22.0104349 },
    { lat: 48.436397, lng: 22.0100229 },
    { lat: 48.4365037, lng: 22.009701 },
    { lat: 48.4368442, lng: 22.0091273 },
    { lat: 48.4379213, lng: 22.0077242 },
    { lat: 48.4377101, lng: 22.0072349 },
    { lat: 48.4383654, lng: 22.0064354 },
    { lat: 48.4382315, lng: 22.0059766 },
    { lat: 48.4401007, lng: 22.0045354 },
    { lat: 48.4407078, lng: 22.0039721 },
    { lat: 48.4404131, lng: 22.0031016 },
    { lat: 48.4407012, lng: 22.0028316 },
    { lat: 48.4430885, lng: 22.0014578 },
    { lat: 48.4441314, lng: 22.0010094 },
    { lat: 48.4445598, lng: 22.000953 },
    { lat: 48.4465423, lng: 21.9978578 },
    { lat: 48.449224, lng: 21.9968372 },
    { lat: 48.4497233, lng: 21.996741 },
    { lat: 48.4506788, lng: 21.9970633 },
    { lat: 48.4509031, lng: 21.9971389 },
    { lat: 48.4510343, lng: 21.9972971 },
    { lat: 48.4513854, lng: 21.9973977 },
    { lat: 48.4509824, lng: 21.9950085 },
    { lat: 48.4507892, lng: 21.9929671 },
    { lat: 48.4505524, lng: 21.9916754 },
    { lat: 48.4496528, lng: 21.9880943 },
    { lat: 48.4494418, lng: 21.9869285 },
    { lat: 48.4494382, lng: 21.9864588 },
    { lat: 48.4495133, lng: 21.9861304 },
    { lat: 48.4503852, lng: 21.9852698 },
    { lat: 48.452016, lng: 21.9839302 },
    { lat: 48.4525225, lng: 21.9830782 },
    { lat: 48.4530109, lng: 21.9824017 },
    { lat: 48.4532559, lng: 21.981851 },
    { lat: 48.4534742, lng: 21.9794427 },
    { lat: 48.4534303, lng: 21.978803 },
    { lat: 48.4531284, lng: 21.9782454 },
    { lat: 48.4528352, lng: 21.9779918 },
    { lat: 48.451477, lng: 21.9779322 },
    { lat: 48.4501478, lng: 21.9782228 },
    { lat: 48.446702, lng: 21.9795859 },
    { lat: 48.4455349, lng: 21.9797104 },
    { lat: 48.4448797, lng: 21.9796897 },
    { lat: 48.444479, lng: 21.9795399 },
    { lat: 48.4440212, lng: 21.9790946 },
    { lat: 48.4437972, lng: 21.9785388 },
    { lat: 48.4437326, lng: 21.9779791 },
    { lat: 48.4437704, lng: 21.9772396 },
    { lat: 48.4438485, lng: 21.9768086 },
    { lat: 48.44438, lng: 21.9755103 },
    { lat: 48.444767, lng: 21.9748228 },
    { lat: 48.4451275, lng: 21.9743518 },
    { lat: 48.4456117, lng: 21.9738651 },
    { lat: 48.4461437, lng: 21.9735 },
    { lat: 48.4468262, lng: 21.9731701 },
  ],
  Soľnička: [
    { lat: 48.50438, lng: 21.951831 },
    { lat: 48.5042575, lng: 21.9518894 },
    { lat: 48.504019, lng: 21.9517975 },
    { lat: 48.503662, lng: 21.9513817 },
    { lat: 48.5033857, lng: 21.9509067 },
    { lat: 48.5031471, lng: 21.9502753 },
    { lat: 48.5026712, lng: 21.9493285 },
    { lat: 48.5023705, lng: 21.9490379 },
    { lat: 48.5021511, lng: 21.9489092 },
    { lat: 48.5013618, lng: 21.9487017 },
    { lat: 48.5010135, lng: 21.9492156 },
    { lat: 48.500922, lng: 21.9492424 },
    { lat: 48.5008664, lng: 21.949358 },
    { lat: 48.5007663, lng: 21.949315 },
    { lat: 48.5006647, lng: 21.9494379 },
    { lat: 48.5006044, lng: 21.949635 },
    { lat: 48.5006636, lng: 21.9497497 },
    { lat: 48.500596, lng: 21.9498838 },
    { lat: 48.500324, lng: 21.9500737 },
    { lat: 48.4999684, lng: 21.9508348 },
    { lat: 48.4996918, lng: 21.9509246 },
    { lat: 48.4990683, lng: 21.9509667 },
    { lat: 48.4988535, lng: 21.9512385 },
    { lat: 48.4986762, lng: 21.9516142 },
    { lat: 48.4986104, lng: 21.9515012 },
    { lat: 48.4982682, lng: 21.9520807 },
    { lat: 48.4979527, lng: 21.9519737 },
    { lat: 48.497711, lng: 21.9519806 },
    { lat: 48.4972707, lng: 21.9521414 },
    { lat: 48.4962765, lng: 21.9521498 },
    { lat: 48.4961223, lng: 21.9520627 },
    { lat: 48.4959159, lng: 21.9517289 },
    { lat: 48.4955538, lng: 21.951549 },
    { lat: 48.4950735, lng: 21.9511037 },
    { lat: 48.4940989, lng: 21.9507336 },
    { lat: 48.493824, lng: 21.9508098 },
    { lat: 48.4928846, lng: 21.9520643 },
    { lat: 48.4919506, lng: 21.9524612 },
    { lat: 48.4916717, lng: 21.9527341 },
    { lat: 48.4911225, lng: 21.9534936 },
    { lat: 48.490861, lng: 21.9535545 },
    { lat: 48.4907403, lng: 21.9536791 },
    { lat: 48.4905398, lng: 21.9539841 },
    { lat: 48.4905429, lng: 21.9554207 },
    { lat: 48.4904923, lng: 21.9556416 },
    { lat: 48.4903846, lng: 21.9558304 },
    { lat: 48.4902535, lng: 21.9558608 },
    { lat: 48.490059, lng: 21.9556782 },
    { lat: 48.4897328, lng: 21.9550962 },
    { lat: 48.4896267, lng: 21.9546798 },
    { lat: 48.4889305, lng: 21.9539619 },
    { lat: 48.4888503, lng: 21.9537718 },
    { lat: 48.4887322, lng: 21.9530553 },
    { lat: 48.4877386, lng: 21.9531793 },
    { lat: 48.4875526, lng: 21.9533018 },
    { lat: 48.4870685, lng: 21.9534189 },
    { lat: 48.485429, lng: 21.9530492 },
    { lat: 48.48529, lng: 21.9527039 },
    { lat: 48.4852201, lng: 21.952161 },
    { lat: 48.485028, lng: 21.9518101 },
    { lat: 48.4847978, lng: 21.9515607 },
    { lat: 48.4845458, lng: 21.9514222 },
    { lat: 48.4840715, lng: 21.9515735 },
    { lat: 48.4833131, lng: 21.9512139 },
    { lat: 48.4825516, lng: 21.9507694 },
    { lat: 48.4820889, lng: 21.950264 },
    { lat: 48.4814164, lng: 21.9497769 },
    { lat: 48.4812734, lng: 21.9495464 },
    { lat: 48.4809554, lng: 21.9496984 },
    { lat: 48.4803447, lng: 21.9503326 },
    { lat: 48.479057, lng: 21.9518274 },
    { lat: 48.478927, lng: 21.9518992 },
    { lat: 48.478828, lng: 21.9519485 },
    { lat: 48.4761504, lng: 21.952118 },
    { lat: 48.4750849, lng: 21.9528838 },
    { lat: 48.4748641, lng: 21.9536341 },
    { lat: 48.4744318, lng: 21.9544364 },
    { lat: 48.4741328, lng: 21.9547557 },
    { lat: 48.4719018, lng: 21.9561071 },
    { lat: 48.4707802, lng: 21.9573669 },
    { lat: 48.4702936, lng: 21.9584721 },
    { lat: 48.4695357, lng: 21.9607398 },
    { lat: 48.4692259, lng: 21.9614844 },
    { lat: 48.4688071, lng: 21.9614824 },
    { lat: 48.4684173, lng: 21.9611836 },
    { lat: 48.4681636, lng: 21.9606605 },
    { lat: 48.4679279, lng: 21.9604167 },
    { lat: 48.4676731, lng: 21.9600319 },
    { lat: 48.4672434, lng: 21.9595914 },
    { lat: 48.4669347, lng: 21.9591129 },
    { lat: 48.4664374, lng: 21.9581257 },
    { lat: 48.4657041, lng: 21.9570079 },
    { lat: 48.4653229, lng: 21.9564686 },
    { lat: 48.4645096, lng: 21.9555744 },
    { lat: 48.4635082, lng: 21.9550273 },
    { lat: 48.4623143, lng: 21.9546095 },
    { lat: 48.4615824, lng: 21.9545832 },
    { lat: 48.4613196, lng: 21.9544279 },
    { lat: 48.4610601, lng: 21.954389 },
    { lat: 48.4603928, lng: 21.9547042 },
    { lat: 48.4601524, lng: 21.95485 },
    { lat: 48.4597675, lng: 21.955212 },
    { lat: 48.4588124, lng: 21.9557076 },
    { lat: 48.4585525, lng: 21.956014 },
    { lat: 48.4579799, lng: 21.9575062 },
    { lat: 48.4576567, lng: 21.9586679 },
    { lat: 48.4576312, lng: 21.9594912 },
    { lat: 48.4580421, lng: 21.9619363 },
    { lat: 48.4582182, lng: 21.9624099 },
    { lat: 48.4584599, lng: 21.9628102 },
    { lat: 48.4585631, lng: 21.9632056 },
    { lat: 48.4584701, lng: 21.9636723 },
    { lat: 48.4582541, lng: 21.9638497 },
    { lat: 48.4584948, lng: 21.9643054 },
    { lat: 48.4586569, lng: 21.964264 },
    { lat: 48.4589455, lng: 21.9640202 },
    { lat: 48.459011, lng: 21.9642069 },
    { lat: 48.4610874, lng: 21.9629291 },
    { lat: 48.4618224, lng: 21.9623982 },
    { lat: 48.4619587, lng: 21.9628152 },
    { lat: 48.46275, lng: 21.9645422 },
    { lat: 48.462796, lng: 21.9644974 },
    { lat: 48.4632677, lng: 21.9656428 },
    { lat: 48.464046, lng: 21.9649552 },
    { lat: 48.464388, lng: 21.9655372 },
    { lat: 48.4644735, lng: 21.9655434 },
    { lat: 48.4648163, lng: 21.9661611 },
    { lat: 48.4656426, lng: 21.9678973 },
    { lat: 48.4661749, lng: 21.9688546 },
    { lat: 48.4664842, lng: 21.9699829 },
    { lat: 48.4671869, lng: 21.9713292 },
    { lat: 48.4673916, lng: 21.971189 },
    { lat: 48.4681027, lng: 21.9710583 },
    { lat: 48.4686524, lng: 21.9710387 },
    { lat: 48.4688541, lng: 21.971099 },
    { lat: 48.4692947, lng: 21.9715855 },
    { lat: 48.4695894, lng: 21.972441 },
    { lat: 48.4697199, lng: 21.9726159 },
    { lat: 48.4699968, lng: 21.9725841 },
    { lat: 48.4707163, lng: 21.9718332 },
    { lat: 48.4712449, lng: 21.9716479 },
    { lat: 48.4717485, lng: 21.9710875 },
    { lat: 48.4723326, lng: 21.9709614 },
    { lat: 48.4728585, lng: 21.9712527 },
    { lat: 48.4735837, lng: 21.9720075 },
    { lat: 48.4737187, lng: 21.9722329 },
    { lat: 48.4743736, lng: 21.9726982 },
    { lat: 48.4747786, lng: 21.9731062 },
    { lat: 48.4751634, lng: 21.9736029 },
    { lat: 48.4753104, lng: 21.9734536 },
    { lat: 48.4759357, lng: 21.9732104 },
    { lat: 48.4773525, lng: 21.9731851 },
    { lat: 48.4778457, lng: 21.9730687 },
    { lat: 48.4787495, lng: 21.9734963 },
    { lat: 48.4787437, lng: 21.9733463 },
    { lat: 48.4788282, lng: 21.9733139 },
    { lat: 48.4787947, lng: 21.9730108 },
    { lat: 48.478892, lng: 21.9728916 },
    { lat: 48.4789064, lng: 21.9727411 },
    { lat: 48.4793063, lng: 21.9721733 },
    { lat: 48.4797633, lng: 21.9716473 },
    { lat: 48.4801564, lng: 21.9714831 },
    { lat: 48.4804728, lng: 21.9711781 },
    { lat: 48.480743, lng: 21.9710491 },
    { lat: 48.4811663, lng: 21.9710629 },
    { lat: 48.4813631, lng: 21.9706908 },
    { lat: 48.4815081, lng: 21.9705504 },
    { lat: 48.4818844, lng: 21.9699821 },
    { lat: 48.482012, lng: 21.9693317 },
    { lat: 48.4821798, lng: 21.9689684 },
    { lat: 48.4823693, lng: 21.9686336 },
    { lat: 48.4827733, lng: 21.968185 },
    { lat: 48.4832261, lng: 21.9682996 },
    { lat: 48.4833158, lng: 21.9683772 },
    { lat: 48.4833905, lng: 21.9682063 },
    { lat: 48.483579, lng: 21.9680971 },
    { lat: 48.4840778, lng: 21.9680691 },
    { lat: 48.4850111, lng: 21.9674222 },
    { lat: 48.4857327, lng: 21.9675756 },
    { lat: 48.4859243, lng: 21.9677716 },
    { lat: 48.4863386, lng: 21.9677401 },
    { lat: 48.4864917, lng: 21.9677911 },
    { lat: 48.4866942, lng: 21.968018 },
    { lat: 48.4873027, lng: 21.9681895 },
    { lat: 48.487638, lng: 21.9678716 },
    { lat: 48.4878242, lng: 21.9673083 },
    { lat: 48.4887618, lng: 21.9675591 },
    { lat: 48.4888528, lng: 21.9676813 },
    { lat: 48.4889085, lng: 21.9679782 },
    { lat: 48.4888808, lng: 21.9686284 },
    { lat: 48.4886388, lng: 21.9698481 },
    { lat: 48.4886426, lng: 21.9702281 },
    { lat: 48.4887101, lng: 21.9704109 },
    { lat: 48.4889032, lng: 21.9705745 },
    { lat: 48.4892732, lng: 21.9706151 },
    { lat: 48.4903385, lng: 21.970292 },
    { lat: 48.4905763, lng: 21.9703077 },
    { lat: 48.4906903, lng: 21.970418 },
    { lat: 48.4907865, lng: 21.9705807 },
    { lat: 48.4908272, lng: 21.9708526 },
    { lat: 48.4908182, lng: 21.9714879 },
    { lat: 48.4908601, lng: 21.9717306 },
    { lat: 48.4908229, lng: 21.9722665 },
    { lat: 48.4908923, lng: 21.9724765 },
    { lat: 48.4910122, lng: 21.972513 },
    { lat: 48.4912478, lng: 21.9723455 },
    { lat: 48.4916634, lng: 21.9722789 },
    { lat: 48.4918759, lng: 21.972091 },
    { lat: 48.4921881, lng: 21.9715262 },
    { lat: 48.4923756, lng: 21.9713667 },
    { lat: 48.4929382, lng: 21.9711918 },
    { lat: 48.4932996, lng: 21.970887 },
    { lat: 48.4934349, lng: 21.9708603 },
    { lat: 48.4938051, lng: 21.9705859 },
    { lat: 48.4943849, lng: 21.9704685 },
    { lat: 48.4944939, lng: 21.9705085 },
    { lat: 48.4948602, lng: 21.9708969 },
    { lat: 48.4949369, lng: 21.9711742 },
    { lat: 48.4951222, lng: 21.9726556 },
    { lat: 48.4953979, lng: 21.9734311 },
    { lat: 48.4956718, lng: 21.9739069 },
    { lat: 48.4958033, lng: 21.9740826 },
    { lat: 48.4961375, lng: 21.9743163 },
    { lat: 48.4966132, lng: 21.9744411 },
    { lat: 48.4973539, lng: 21.9745077 },
    { lat: 48.4975737, lng: 21.9746993 },
    { lat: 48.4976514, lng: 21.9748397 },
    { lat: 48.4980104, lng: 21.9743063 },
    { lat: 48.4983907, lng: 21.9740806 },
    { lat: 48.4985734, lng: 21.9741924 },
    { lat: 48.4989326, lng: 21.9740211 },
    { lat: 48.4995032, lng: 21.9735293 },
    { lat: 48.4998144, lng: 21.973149 },
    { lat: 48.4999549, lng: 21.9731517 },
    { lat: 48.500057, lng: 21.973047 },
    { lat: 48.4998774, lng: 21.9726499 },
    { lat: 48.4998522, lng: 21.9715974 },
    { lat: 48.5000863, lng: 21.9710064 },
    { lat: 48.5008913, lng: 21.9694032 },
    { lat: 48.50154, lng: 21.9674827 },
    { lat: 48.5019365, lng: 21.9669116 },
    { lat: 48.5026612, lng: 21.9661542 },
    { lat: 48.5028639, lng: 21.9661648 },
    { lat: 48.5030352, lng: 21.9641895 },
    { lat: 48.5029464, lng: 21.9633303 },
    { lat: 48.5027693, lng: 21.9623151 },
    { lat: 48.502375, lng: 21.9615722 },
    { lat: 48.5022356, lng: 21.9607634 },
    { lat: 48.5023162, lng: 21.960385 },
    { lat: 48.5024514, lng: 21.9602567 },
    { lat: 48.5026816, lng: 21.9601965 },
    { lat: 48.5033225, lng: 21.9608997 },
    { lat: 48.5036243, lng: 21.961546 },
    { lat: 48.5036601, lng: 21.9614975 },
    { lat: 48.5041555, lng: 21.962232 },
    { lat: 48.5047034, lng: 21.9623756 },
    { lat: 48.5052367, lng: 21.9618272 },
    { lat: 48.505568, lng: 21.9618497 },
    { lat: 48.505637, lng: 21.961814 },
    { lat: 48.505604, lng: 21.961474 },
    { lat: 48.505578, lng: 21.961207 },
    { lat: 48.505556, lng: 21.960977 },
    { lat: 48.505532, lng: 21.960712 },
    { lat: 48.505513, lng: 21.960599 },
    { lat: 48.505369, lng: 21.95962 },
    { lat: 48.505174, lng: 21.958029 },
    { lat: 48.504938, lng: 21.956026 },
    { lat: 48.504753, lng: 21.954441 },
    { lat: 48.504562, lng: 21.952844 },
    { lat: 48.50438, lng: 21.951831 },
  ],
};

export default UnitsTrebišov;
