import { faCancel, faChevronDown, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parameters from "../../../Images/parameters.png";
import PriceFilter from "./PriceFilter";
import AreaFilter from "./AreaFilter";
import Type from "./Type";
import Location from "./Location";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";

export default function ScrollFilters({ filters, setFilters, filterForSale }) {
  const [isFilter, setFilter] = useState({ price: false, area: false, type: false, location: false, all: false });
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return (
    <AnimatePresence>
      <motion.div
        className={clsx("relative w-full no-scrollbar gap-1 bg-sky-50 rounded-lg", {
          "grid-rows-[10%_90%]": isFilter.price && isFilter.area && isFilter.type,
        })}
        initial={{ height: 32 }}
        animate={{
          height:
            isFilter.price && isFilter.area && isFilter.type && isFilter.location
              ? 470
              : isFilter.price || isFilter.area || isFilter.type
                ? 200
                : isFilter.location
                  ? 140
                  : 32,
        }}
        transition={{ duration: 0.5 }}
      >
        <div
          className={clsx("w-full grid grid-rows-2 overflow-x-scroll gap-1", {
            "no-scrollbar grid-cols-[12%_26%_26%_26%_26%]": isSmallDevice,
            "grid-cols-[8%_22.58%_22.58%_22.58%_22.58%]": !isSmallDevice,
          })}
        >
          <button
            onClick={() =>
              setFilter({
                ...isFilter,
                type: true,
                price: true,
                area: true,
                location: true,
              })
            }
            className="w-full h-8 border border-opacity-50 border-sky-500 row-start-1 col-start-1 rounded-lg flex justify-center items-center "
          >
            <img src={parameters} alt="" className="w-5 h-5" />
          </button>
          <button
            onClick={() => setFilter({ ...isFilter, type: false, price: false, area: false, location: true })}
            className="w-full h-8 flex flex-row justify-between px-2 items-center border border-opacity-50 border-sky-500 row-start-1 col-start-2 rounded-lg"
          >
            <p className="w-12 truncate">{filters.location ? filters.location : "Lokalita"}</p>
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <button
            onClick={() => setFilter({ ...isFilter, type: true, price: false, area: false, location: false })}
            className="w-full h-8 flex flex-row justify-between px-2 items-center border border-opacity-50 border-sky-500 row-start-1 col-start-3 rounded-lg"
          >
            <p className="w-12 truncate">{filters.type ? filters.type : "Typ"}</p>{" "}
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <button
            onClick={() => setFilter({ ...isFilter, price: true, area: false, type: false, location: false })}
            className="w-full h-8 flex flex-row justify-between px-2 items-center border border-opacity-50 border-sky-500 row-start-1 col-start-4 rounded-lg"
          >
            <p className="w-12 truncate">{filters.minPrice ? filters.minPrice : "Cena"}</p>
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <button
            onClick={() => setFilter({ ...isFilter, area: true, price: false, type: false, location: false })}
            className="w-full h-8 flex flex-row justify-between px-2 items-center border border-opacity-50 border-sky-500 row-start-1 col-start-5 rounded-lg"
          >
            <p className="w-12 truncate">{filters.minArea ? filters.minArea : "Výmera"}</p>
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
        </div>
        <div className="w-full row-start-2 col-span-4 flex flex-col gap-1">
          {isFilter.price || isFilter.all ? (
            <PriceFilter filters={filters} setFilters={setFilters} isFilter={isFilter} />
          ) : null}
          {isFilter.area || isFilter.all ? (
            <AreaFilter filters={filters} setFilters={setFilters} isFilter={isFilter} />
          ) : null}
          {isFilter.type || isFilter.all ? (
            <Type filters={filters} setFilters={setFilters} isFilter={isFilter} />
          ) : null}
          {isFilter.location || isFilter.all ? (
            <Location filters={filters} setFilters={setFilters} isFilter={isFilter} filterForSale={filterForSale} />
          ) : null}
          {isFilter.price || isFilter.area || isFilter.type || isFilter.location || isFilter.all ? (
            <div className="w-full flex flex-row justify-center items-center gap-4 pb-2">
              <button
                onClick={() => {
                  filterForSale(1);
                  setFilters({
                    ...filters,
                    minPrice: "",
                    maxPrice: "",
                    minArea: "",
                    maxArea: "",
                    type: "",
                    location: "",
                  });
                }}
                className="w-20 h-8 flex flex-row justify-center gap-1 px-2 items-center border border-opacity-50 border-sky-500 rounded-lg"
              >
                <FontAwesomeIcon icon={faTrash} /> Vymazať
              </button>
              <button
                onClick={() => {
                  setFilter({ ...isFilter, price: false, area: false, type: false, location: false });
                  filterForSale(1);
                }}
                className="w-20 h-8 flex flex-row justify-center gap-1 px-2 items-center border border-opacity-50 border-sky-500 rounded-lg"
              >
                <FontAwesomeIcon icon={faCancel} /> Zrušiť
              </button>
              <button
                onClick={() => {
                  filterForSale();
                  setFilter({ ...isFilter, price: false, area: false, type: false, location: false });
                }}
                className="w-20 h-8 flex flex-row justify-center gap-1 px-2 items-center border border-opacity-50 border-sky-500 rounded-lg"
              >
                <FontAwesomeIcon icon={faSave} /> Použiť
              </button>
            </div>
          ) : null}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
