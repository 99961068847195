import clsx from "clsx";
import { Mock } from "../../../../data/Mock";
import { useMediaQuery } from "usehooks-ts";

export default function Rated({ openLeftBar, gallery, size }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return (
    <div
      className={clsx(
        "transition-all duration-500 ease-linear min-h-max flex flex-col justify-center overflow-ellipsis w-full px-2 gap-4 select-none",
        {
          "h-44 p-3 sm:w-96 ": openLeftBar.forSale && !isSmallDevice,
          "p-2 no-scrollbar": isSmallDevice,
          "!overflow-hidden !top-0": gallery,
        },
      )}
    >
      <div
        className={clsx("flex flex-col gap-4", {
          "grid grid-cols-1 sm:grid-cols-2 gap-4": !isSmallDevice && size.full,
        })}
      >
        {Mock?.map((item, index) => (
          <div
            key={index}
            className={clsx(
              "w-full border border-opacity-50 border-sky-500 h-auto flex flex-col gap-2 cursor-pointer hover:bg-sky-300 rounded-lg shadow-lg shadow-sky-200",
              {
                // "bg-sky-300": Number(index) === Number(clicked.current),
              },
            )}
          >
            <div className="flex flex-row">
              <div className="flex flex-col w-1/3">
                <img src={item?.img} alt="photo1" className="w-full h-24 rounded-l-lg object-cover" />
              </div>
              <div className="flex flex-col justify-between w-2/3 p-1">
                <div className="w-full h-2/5 flex flex-col items-start justify-start text-xs">
                  <div className="w-full flex flex-row items-center gap-1">
                    {/* {JSON.parse(item?.parameters).type.includes("byt") ? (
                      <FontAwesomeIcon icon={faBuilding} className="w- h-3" />
                    ) : (
                      <img src={NaPredaj} alt="Na predaj" className="w-3 h-3" />
                    )}
                    {JSON.parse(item?.parameters).type} • {JSON.parse(item?.parameters).area} m² */}
                  </div>
                  <div className="w-full flex flex-row items-center gap-1">
                    {/* • {JSON.parse(item?.parameters).condition} */}
                  </div>
                  <div className="w-full h-6 gap-1 ">
                    {/* • {item?.street ? item?.street + ", " : null} */}
                    {/* {item.city} */}
                    {item.name}
                    {/* {Number(JSON.parse(item?.lv).List.length) > 1
                      ? ", okres " + JSON.parse(item?.lv).List[0]?.nazov_okresu
                      : ", okres " + JSON.parse(item?.lv).List?.nazov_okresu} */}
                  </div>
                </div>
                <div className="w-full h-1/4 flex items-center justify-end text-xl font-semibold">{item.price} €</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
