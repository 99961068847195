const UnitsDolnýKubín = {
  Bziny: [
    { lat: 49.2323052, lng: 19.3154239 },
    { lat: 49.2322065, lng: 19.3150724 },
    { lat: 49.2321638, lng: 19.3149772 },
    { lat: 49.2320124, lng: 19.3148778 },
    { lat: 49.2320102, lng: 19.3148769 },
    { lat: 49.2319584, lng: 19.3148538 },
    { lat: 49.2318064, lng: 19.3147862 },
    { lat: 49.2317499, lng: 19.3147599 },
    { lat: 49.2317096, lng: 19.3147413 },
    { lat: 49.2310813, lng: 19.3146342 },
    { lat: 49.2308157, lng: 19.3146902 },
    { lat: 49.2306193, lng: 19.3145871 },
    { lat: 49.23049, lng: 19.3145191 },
    { lat: 49.2303145, lng: 19.3145028 },
    { lat: 49.2301793, lng: 19.3145065 },
    { lat: 49.2301448, lng: 19.3144908 },
    { lat: 49.2300476, lng: 19.3144135 },
    { lat: 49.229959, lng: 19.3143973 },
    { lat: 49.229913, lng: 19.3144028 },
    { lat: 49.2298728, lng: 19.3144115 },
    { lat: 49.2297713, lng: 19.3144072 },
    { lat: 49.2297021, lng: 19.3143968 },
    { lat: 49.229618, lng: 19.3143812 },
    { lat: 49.2295443, lng: 19.3143955 },
    { lat: 49.229521, lng: 19.3143825 },
    { lat: 49.2294591, lng: 19.3143818 },
    { lat: 49.2294005, lng: 19.3143825 },
    { lat: 49.2293334, lng: 19.3143704 },
    { lat: 49.2291831, lng: 19.3143646 },
    { lat: 49.2288872, lng: 19.3143233 },
    { lat: 49.228659, lng: 19.3143629 },
    { lat: 49.2284683, lng: 19.3144304 },
    { lat: 49.2283195, lng: 19.3144998 },
    { lat: 49.2282292, lng: 19.3145927 },
    { lat: 49.2281901, lng: 19.3146929 },
    { lat: 49.2281418, lng: 19.3146965 },
    { lat: 49.2279522, lng: 19.3145475 },
    { lat: 49.2277621, lng: 19.3144123 },
    { lat: 49.2275809, lng: 19.314225 },
    { lat: 49.2273717, lng: 19.3141417 },
    { lat: 49.2269232, lng: 19.31407 },
    { lat: 49.2268296, lng: 19.3141131 },
    { lat: 49.2267977, lng: 19.314109 },
    { lat: 49.226587, lng: 19.3140825 },
    { lat: 49.2265322, lng: 19.3140482 },
    { lat: 49.2263578, lng: 19.3139358 },
    { lat: 49.2262852, lng: 19.3138288 },
    { lat: 49.2262048, lng: 19.3137965 },
    { lat: 49.2259504, lng: 19.3138502 },
    { lat: 49.2257722, lng: 19.3138713 },
    { lat: 49.2256513, lng: 19.3138157 },
    { lat: 49.2256, lng: 19.313755 },
    { lat: 49.2255342, lng: 19.3136775 },
    { lat: 49.2254555, lng: 19.313586 },
    { lat: 49.2253814, lng: 19.3135174 },
    { lat: 49.2253291, lng: 19.313416 },
    { lat: 49.2251613, lng: 19.3134616 },
    { lat: 49.2250374, lng: 19.3135333 },
    { lat: 49.2249368, lng: 19.313579 },
    { lat: 49.2247975, lng: 19.31366 },
    { lat: 49.2246468, lng: 19.3137379 },
    { lat: 49.2245653, lng: 19.3137779 },
    { lat: 49.2244236, lng: 19.3138515 },
    { lat: 49.2241889, lng: 19.314013 },
    { lat: 49.2241064, lng: 19.3140812 },
    { lat: 49.2239175, lng: 19.3142311 },
    { lat: 49.2237459, lng: 19.314369 },
    { lat: 49.2236333, lng: 19.3144441 },
    { lat: 49.2236148, lng: 19.314457 },
    { lat: 49.2235382, lng: 19.3144813 },
    { lat: 49.2234312, lng: 19.314364 },
    { lat: 49.2233506, lng: 19.3142781 },
    { lat: 49.2232446, lng: 19.3141589 },
    { lat: 49.2232093, lng: 19.3141274 },
    { lat: 49.222967, lng: 19.3139129 },
    { lat: 49.2225248, lng: 19.3134497 },
    { lat: 49.2222453, lng: 19.3130926 },
    { lat: 49.2222118, lng: 19.3130779 },
    { lat: 49.2221938, lng: 19.3130521 },
    { lat: 49.2221487, lng: 19.3130023 },
    { lat: 49.2221076, lng: 19.3129682 },
    { lat: 49.2220808, lng: 19.3129507 },
    { lat: 49.2219433, lng: 19.3129031 },
    { lat: 49.2218867, lng: 19.3128765 },
    { lat: 49.2218399, lng: 19.312859 },
    { lat: 49.2217904, lng: 19.3128428 },
    { lat: 49.2217008, lng: 19.3128044 },
    { lat: 49.2216558, lng: 19.3127819 },
    { lat: 49.2216153, lng: 19.312759 },
    { lat: 49.2214906, lng: 19.3126941 },
    { lat: 49.2214071, lng: 19.3126911 },
    { lat: 49.2213493, lng: 19.3126888 },
    { lat: 49.2212849, lng: 19.3126844 },
    { lat: 49.2212555, lng: 19.3126825 },
    { lat: 49.2211662, lng: 19.3127465 },
    { lat: 49.2211176, lng: 19.3127666 },
    { lat: 49.2209867, lng: 19.312931 },
    { lat: 49.2209003, lng: 19.3130116 },
    { lat: 49.2202912, lng: 19.3132593 },
    { lat: 49.2201149, lng: 19.3133718 },
    { lat: 49.2196998, lng: 19.3138361 },
    { lat: 49.2195921, lng: 19.3138725 },
    { lat: 49.219505, lng: 19.3139625 },
    { lat: 49.2194001, lng: 19.3141258 },
    { lat: 49.2191962, lng: 19.3141732 },
    { lat: 49.2188074, lng: 19.3145124 },
    { lat: 49.2185982, lng: 19.3148963 },
    { lat: 49.2185085, lng: 19.3150028 },
    { lat: 49.2183298, lng: 19.3151079 },
    { lat: 49.2180205, lng: 19.3152894 },
    { lat: 49.2179728, lng: 19.3152792 },
    { lat: 49.2178937, lng: 19.3153496 },
    { lat: 49.2177209, lng: 19.315652 },
    { lat: 49.2176651, lng: 19.3157349 },
    { lat: 49.2174407, lng: 19.3158483 },
    { lat: 49.2172705, lng: 19.3161587 },
    { lat: 49.2171047, lng: 19.3161798 },
    { lat: 49.2168595, lng: 19.3162371 },
    { lat: 49.2165972, lng: 19.3164081 },
    { lat: 49.2159061, lng: 19.3167607 },
    { lat: 49.2158311, lng: 19.3168418 },
    { lat: 49.2157688, lng: 19.3169096 },
    { lat: 49.2156964, lng: 19.3173552 },
    { lat: 49.2156796, lng: 19.3180744 },
    { lat: 49.2155721, lng: 19.3184734 },
    { lat: 49.2155901, lng: 19.3189945 },
    { lat: 49.2155219, lng: 19.3191 },
    { lat: 49.2154132, lng: 19.3193034 },
    { lat: 49.2153949, lng: 19.3195351 },
    { lat: 49.2153386, lng: 19.3196844 },
    { lat: 49.2151757, lng: 19.3197654 },
    { lat: 49.2149476, lng: 19.3199689 },
    { lat: 49.2149411, lng: 19.3200933 },
    { lat: 49.2149495, lng: 19.3201291 },
    { lat: 49.2150059, lng: 19.3203443 },
    { lat: 49.2151939, lng: 19.3207446 },
    { lat: 49.2152434, lng: 19.3208884 },
    { lat: 49.2152095, lng: 19.3210039 },
    { lat: 49.2150704, lng: 19.3212088 },
    { lat: 49.2149254, lng: 19.3212651 },
    { lat: 49.2145662, lng: 19.3217235 },
    { lat: 49.2142316, lng: 19.321941 },
    { lat: 49.2139008, lng: 19.3222621 },
    { lat: 49.2138108, lng: 19.3225053 },
    { lat: 49.2137436, lng: 19.3226338 },
    { lat: 49.2136779, lng: 19.3228169 },
    { lat: 49.213648, lng: 19.3229687 },
    { lat: 49.213651, lng: 19.3233231 },
    { lat: 49.2133842, lng: 19.3240186 },
    { lat: 49.2133346, lng: 19.3240886 },
    { lat: 49.2132806, lng: 19.3241655 },
    { lat: 49.2131856, lng: 19.3242992 },
    { lat: 49.2129351, lng: 19.3246051 },
    { lat: 49.2128497, lng: 19.3245618 },
    { lat: 49.2127412, lng: 19.3247454 },
    { lat: 49.2126919, lng: 19.3248261 },
    { lat: 49.2127371, lng: 19.3251841 },
    { lat: 49.2127514, lng: 19.3254414 },
    { lat: 49.2127881, lng: 19.326048 },
    { lat: 49.2127335, lng: 19.3261329 },
    { lat: 49.212645, lng: 19.3261437 },
    { lat: 49.2123617, lng: 19.3263504 },
    { lat: 49.2123, lng: 19.3265248 },
    { lat: 49.2122044, lng: 19.3265761 },
    { lat: 49.2120436, lng: 19.3272271 },
    { lat: 49.212162, lng: 19.3276852 },
    { lat: 49.2122381, lng: 19.3279097 },
    { lat: 49.2122876, lng: 19.3280534 },
    { lat: 49.2124375, lng: 19.3286285 },
    { lat: 49.2125047, lng: 19.3287357 },
    { lat: 49.2124342, lng: 19.3288934 },
    { lat: 49.2124832, lng: 19.3290473 },
    { lat: 49.2126606, lng: 19.3296078 },
    { lat: 49.212782, lng: 19.3299344 },
    { lat: 49.2128886, lng: 19.3301624 },
    { lat: 49.2130219, lng: 19.3303842 },
    { lat: 49.2132371, lng: 19.3303346 },
    { lat: 49.213412, lng: 19.3306989 },
    { lat: 49.2134692, lng: 19.3308855 },
    { lat: 49.21341, lng: 19.3309919 },
    { lat: 49.213633, lng: 19.331299 },
    { lat: 49.2138647, lng: 19.3320576 },
    { lat: 49.21402, lng: 19.3326465 },
    { lat: 49.2137003, lng: 19.3328932 },
    { lat: 49.2135781, lng: 19.3329095 },
    { lat: 49.213334, lng: 19.3329424 },
    { lat: 49.2129454, lng: 19.3330186 },
    { lat: 49.2124492, lng: 19.3331037 },
    { lat: 49.2123685, lng: 19.3330899 },
    { lat: 49.2120271, lng: 19.3330203 },
    { lat: 49.2119173, lng: 19.3330818 },
    { lat: 49.2117763, lng: 19.3332205 },
    { lat: 49.2116315, lng: 19.3335713 },
    { lat: 49.2115691, lng: 19.3337998 },
    { lat: 49.2114197, lng: 19.3344095 },
    { lat: 49.2112617, lng: 19.3349992 },
    { lat: 49.211163, lng: 19.3351056 },
    { lat: 49.211069, lng: 19.3352911 },
    { lat: 49.2109654, lng: 19.3354074 },
    { lat: 49.2108122, lng: 19.3356491 },
    { lat: 49.2106484, lng: 19.3360019 },
    { lat: 49.2104896, lng: 19.33636 },
    { lat: 49.2103149, lng: 19.3366974 },
    { lat: 49.2102649, lng: 19.3366636 },
    { lat: 49.2102427, lng: 19.3367435 },
    { lat: 49.2101803, lng: 19.3369735 },
    { lat: 49.2100196, lng: 19.337007 },
    { lat: 49.2098179, lng: 19.3369343 },
    { lat: 49.2097628, lng: 19.3369144 },
    { lat: 49.209724, lng: 19.3369767 },
    { lat: 49.2096434, lng: 19.3371052 },
    { lat: 49.2095039, lng: 19.3373728 },
    { lat: 49.2094368, lng: 19.3375528 },
    { lat: 49.2093997, lng: 19.3376023 },
    { lat: 49.20935, lng: 19.3376701 },
    { lat: 49.2093238, lng: 19.3376413 },
    { lat: 49.2092883, lng: 19.3376037 },
    { lat: 49.2091741, lng: 19.3377168 },
    { lat: 49.2091105, lng: 19.3377804 },
    { lat: 49.2090452, lng: 19.3379033 },
    { lat: 49.2089881, lng: 19.3380548 },
    { lat: 49.2089197, lng: 19.3381311 },
    { lat: 49.2088552, lng: 19.3382211 },
    { lat: 49.2088219, lng: 19.3382799 },
    { lat: 49.2088044, lng: 19.3383597 },
    { lat: 49.2087616, lng: 19.338555 },
    { lat: 49.2087165, lng: 19.338795 },
    { lat: 49.2086942, lng: 19.3390148 },
    { lat: 49.2087474, lng: 19.3394278 },
    { lat: 49.2087838, lng: 19.3395027 },
    { lat: 49.2088429, lng: 19.339564 },
    { lat: 49.208972, lng: 19.3400551 },
    { lat: 49.209037, lng: 19.3402859 },
    { lat: 49.2091256, lng: 19.3406637 },
    { lat: 49.2091891, lng: 19.3408827 },
    { lat: 49.2092522, lng: 19.3414103 },
    { lat: 49.2092346, lng: 19.3415903 },
    { lat: 49.2091779, lng: 19.3418445 },
    { lat: 49.2091554, lng: 19.3422996 },
    { lat: 49.2091788, lng: 19.3423652 },
    { lat: 49.2092484, lng: 19.342505 },
    { lat: 49.2094133, lng: 19.3426578 },
    { lat: 49.2094777, lng: 19.3427815 },
    { lat: 49.2095291, lng: 19.3428202 },
    { lat: 49.2095783, lng: 19.3428311 },
    { lat: 49.2097502, lng: 19.3429658 },
    { lat: 49.2098576, lng: 19.34314 },
    { lat: 49.2099299, lng: 19.3432171 },
    { lat: 49.2100883, lng: 19.3434459 },
    { lat: 49.2101865, lng: 19.3436168 },
    { lat: 49.2102933, lng: 19.3438978 },
    { lat: 49.2104085, lng: 19.3442835 },
    { lat: 49.2105006, lng: 19.3445906 },
    { lat: 49.2104143, lng: 19.3446242 },
    { lat: 49.210357, lng: 19.344726 },
    { lat: 49.2101904, lng: 19.3448736 },
    { lat: 49.2099479, lng: 19.3451561 },
    { lat: 49.2098421, lng: 19.3455883 },
    { lat: 49.2096703, lng: 19.3457005 },
    { lat: 49.2094816, lng: 19.3459064 },
    { lat: 49.2092352, lng: 19.3462243 },
    { lat: 49.2090123, lng: 19.3465158 },
    { lat: 49.2088615, lng: 19.3467352 },
    { lat: 49.2087497, lng: 19.3469479 },
    { lat: 49.2086335, lng: 19.347209 },
    { lat: 49.2085553, lng: 19.3474158 },
    { lat: 49.2083085, lng: 19.3479405 },
    { lat: 49.208498, lng: 19.3482106 },
    { lat: 49.2085645, lng: 19.3483043 },
    { lat: 49.2086274, lng: 19.3483954 },
    { lat: 49.2086528, lng: 19.3484334 },
    { lat: 49.2086721, lng: 19.3484623 },
    { lat: 49.2088522, lng: 19.348723 },
    { lat: 49.2098223, lng: 19.3497478 },
    { lat: 49.209812, lng: 19.3497684 },
    { lat: 49.2102433, lng: 19.3503595 },
    { lat: 49.2107234, lng: 19.3510592 },
    { lat: 49.2109566, lng: 19.3514384 },
    { lat: 49.2112286, lng: 19.351879 },
    { lat: 49.2114164, lng: 19.3521818 },
    { lat: 49.2115703, lng: 19.3525589 },
    { lat: 49.2118658, lng: 19.3531628 },
    { lat: 49.2120215, lng: 19.3536922 },
    { lat: 49.2122816, lng: 19.3546002 },
    { lat: 49.2122954, lng: 19.3546538 },
    { lat: 49.2123572, lng: 19.3546957 },
    { lat: 49.2123989, lng: 19.3546739 },
    { lat: 49.2124954, lng: 19.3546165 },
    { lat: 49.2126506, lng: 19.3545624 },
    { lat: 49.2127186, lng: 19.3544763 },
    { lat: 49.2128179, lng: 19.3544301 },
    { lat: 49.2130915, lng: 19.3544474 },
    { lat: 49.2132083, lng: 19.3544648 },
    { lat: 49.2134856, lng: 19.3545384 },
    { lat: 49.2136446, lng: 19.3547546 },
    { lat: 49.213767, lng: 19.3547026 },
    { lat: 49.2138459, lng: 19.3545808 },
    { lat: 49.2140335, lng: 19.3545441 },
    { lat: 49.2141362, lng: 19.3545685 },
    { lat: 49.2143014, lng: 19.3545108 },
    { lat: 49.2143612, lng: 19.3543965 },
    { lat: 49.2143841, lng: 19.3543767 },
    { lat: 49.2145558, lng: 19.3544112 },
    { lat: 49.2146758, lng: 19.3545427 },
    { lat: 49.2148346, lng: 19.3544907 },
    { lat: 49.2148691, lng: 19.3546981 },
    { lat: 49.2150426, lng: 19.3547466 },
    { lat: 49.2152699, lng: 19.3553363 },
    { lat: 49.215403, lng: 19.3553409 },
    { lat: 49.2155645, lng: 19.3556365 },
    { lat: 49.2156887, lng: 19.3555973 },
    { lat: 49.215837, lng: 19.355873 },
    { lat: 49.2162068, lng: 19.3561639 },
    { lat: 49.2163244, lng: 19.3561703 },
    { lat: 49.2163753, lng: 19.3561768 },
    { lat: 49.2166679, lng: 19.3559765 },
    { lat: 49.2168689, lng: 19.3560078 },
    { lat: 49.2170505, lng: 19.3560586 },
    { lat: 49.2173706, lng: 19.3561573 },
    { lat: 49.2176759, lng: 19.3559394 },
    { lat: 49.2181659, lng: 19.3551688 },
    { lat: 49.2183655, lng: 19.3550247 },
    { lat: 49.2184763, lng: 19.3549142 },
    { lat: 49.2185418, lng: 19.354867 },
    { lat: 49.2186313, lng: 19.3548569 },
    { lat: 49.2187518, lng: 19.3549241 },
    { lat: 49.2188307, lng: 19.3550943 },
    { lat: 49.2189603, lng: 19.3553559 },
    { lat: 49.219108, lng: 19.355529 },
    { lat: 49.2192333, lng: 19.3557233 },
    { lat: 49.2192585, lng: 19.3557573 },
    { lat: 49.2193058, lng: 19.3557799 },
    { lat: 49.2193301, lng: 19.3557675 },
    { lat: 49.2193836, lng: 19.3557562 },
    { lat: 49.2193979, lng: 19.3557489 },
    { lat: 49.2195618, lng: 19.3556895 },
    { lat: 49.2196659, lng: 19.3556485 },
    { lat: 49.219814, lng: 19.355614 },
    { lat: 49.219824, lng: 19.3556093 },
    { lat: 49.2199276, lng: 19.355559 },
    { lat: 49.2200614, lng: 19.3555065 },
    { lat: 49.2202342, lng: 19.3555103 },
    { lat: 49.2203662, lng: 19.355444 },
    { lat: 49.2205159, lng: 19.3555083 },
    { lat: 49.2207003, lng: 19.3557203 },
    { lat: 49.2208372, lng: 19.3559451 },
    { lat: 49.2210556, lng: 19.356232 },
    { lat: 49.2211273, lng: 19.356244 },
    { lat: 49.2211474, lng: 19.35624 },
    { lat: 49.2212158, lng: 19.3562073 },
    { lat: 49.2212566, lng: 19.3562875 },
    { lat: 49.2213057, lng: 19.356347 },
    { lat: 49.2213525, lng: 19.3564093 },
    { lat: 49.2214171, lng: 19.3564668 },
    { lat: 49.2214593, lng: 19.3565015 },
    { lat: 49.2215405, lng: 19.3565506 },
    { lat: 49.2216721, lng: 19.3566166 },
    { lat: 49.2218397, lng: 19.3566318 },
    { lat: 49.2218759, lng: 19.3566135 },
    { lat: 49.2219352, lng: 19.3566286 },
    { lat: 49.2219786, lng: 19.3566372 },
    { lat: 49.2220497, lng: 19.3566435 },
    { lat: 49.2221343, lng: 19.356689 },
    { lat: 49.2223747, lng: 19.3567493 },
    { lat: 49.2224606, lng: 19.3568484 },
    { lat: 49.2225381, lng: 19.3568446 },
    { lat: 49.2225757, lng: 19.3568055 },
    { lat: 49.2226584, lng: 19.3568376 },
    { lat: 49.2226935, lng: 19.3568651 },
    { lat: 49.2227605, lng: 19.3568744 },
    { lat: 49.2228169, lng: 19.3568541 },
    { lat: 49.2228928, lng: 19.3568858 },
    { lat: 49.2229504, lng: 19.3569607 },
    { lat: 49.2230332, lng: 19.3570706 },
    { lat: 49.2230888, lng: 19.3570768 },
    { lat: 49.2231603, lng: 19.3571371 },
    { lat: 49.223232, lng: 19.3571491 },
    { lat: 49.2232805, lng: 19.3572209 },
    { lat: 49.2233773, lng: 19.3572922 },
    { lat: 49.2234972, lng: 19.3572765 },
    { lat: 49.2235345, lng: 19.3573265 },
    { lat: 49.2235901, lng: 19.3573828 },
    { lat: 49.2237156, lng: 19.3574149 },
    { lat: 49.2237327, lng: 19.3573975 },
    { lat: 49.2237474, lng: 19.3573029 },
    { lat: 49.2237878, lng: 19.3572736 },
    { lat: 49.2238362, lng: 19.3572737 },
    { lat: 49.2239182, lng: 19.3574115 },
    { lat: 49.2239773, lng: 19.357422 },
    { lat: 49.2239855, lng: 19.3574308 },
    { lat: 49.2239779, lng: 19.3574605 },
    { lat: 49.2239752, lng: 19.3574723 },
    { lat: 49.2240693, lng: 19.3575551 },
    { lat: 49.2241694, lng: 19.3575762 },
    { lat: 49.2241851, lng: 19.3576224 },
    { lat: 49.2242061, lng: 19.3576389 },
    { lat: 49.2242109, lng: 19.3577209 },
    { lat: 49.2242467, lng: 19.3577826 },
    { lat: 49.2242966, lng: 19.3578399 },
    { lat: 49.2243404, lng: 19.3578117 },
    { lat: 49.2244236, lng: 19.3578556 },
    { lat: 49.22449, lng: 19.3577607 },
    { lat: 49.2245492, lng: 19.3576983 },
    { lat: 49.2246291, lng: 19.3577711 },
    { lat: 49.2246563, lng: 19.3579628 },
    { lat: 49.2247062, lng: 19.3579945 },
    { lat: 49.2247546, lng: 19.357995 },
    { lat: 49.2247966, lng: 19.3580946 },
    { lat: 49.2248448, lng: 19.3581848 },
    { lat: 49.22488, lng: 19.3582152 },
    { lat: 49.2248965, lng: 19.358256 },
    { lat: 49.2249455, lng: 19.3582676 },
    { lat: 49.2249627, lng: 19.3583227 },
    { lat: 49.2250111, lng: 19.3583217 },
    { lat: 49.225047, lng: 19.3583434 },
    { lat: 49.2250921, lng: 19.358293 },
    { lat: 49.2251931, lng: 19.358331 },
    { lat: 49.2252156, lng: 19.3583577 },
    { lat: 49.2252184, lng: 19.3584364 },
    { lat: 49.2252579, lng: 19.3584898 },
    { lat: 49.2253663, lng: 19.3585652 },
    { lat: 49.2254563, lng: 19.3585402 },
    { lat: 49.2255675, lng: 19.3585305 },
    { lat: 49.2255857, lng: 19.3586771 },
    { lat: 49.2256502, lng: 19.3586614 },
    { lat: 49.2256961, lng: 19.3587988 },
    { lat: 49.2257046, lng: 19.3588602 },
    { lat: 49.2257134, lng: 19.3588564 },
    { lat: 49.2257619, lng: 19.3588327 },
    { lat: 49.2257959, lng: 19.3588571 },
    { lat: 49.2260465, lng: 19.3587489 },
    { lat: 49.226251, lng: 19.3586628 },
    { lat: 49.2264323, lng: 19.3585856 },
    { lat: 49.2267579, lng: 19.3584638 },
    { lat: 49.2269806, lng: 19.358384 },
    { lat: 49.2271103, lng: 19.3583391 },
    { lat: 49.2273497, lng: 19.3582542 },
    { lat: 49.2275903, lng: 19.3581713 },
    { lat: 49.2278995, lng: 19.3580592 },
    { lat: 49.228111, lng: 19.3579806 },
    { lat: 49.2283451, lng: 19.3579027 },
    { lat: 49.228521, lng: 19.3579022 },
    { lat: 49.2289158, lng: 19.3578414 },
    { lat: 49.2291261, lng: 19.3578093 },
    { lat: 49.2294198, lng: 19.3577974 },
    { lat: 49.2295027, lng: 19.3578122 },
    { lat: 49.2297263, lng: 19.3578476 },
    { lat: 49.2297594, lng: 19.3578526 },
    { lat: 49.2299614, lng: 19.3578842 },
    { lat: 49.2302415, lng: 19.3579203 },
    { lat: 49.2304065, lng: 19.357956 },
    { lat: 49.2310587, lng: 19.3580949 },
    { lat: 49.2311167, lng: 19.358103 },
    { lat: 49.2312392, lng: 19.3581217 },
    { lat: 49.2313074, lng: 19.3581326 },
    { lat: 49.2313199, lng: 19.358137 },
    { lat: 49.2310414, lng: 19.3572692 },
    { lat: 49.2300499, lng: 19.3534293 },
    { lat: 49.230108, lng: 19.3528165 },
    { lat: 49.2299617, lng: 19.3522707 },
    { lat: 49.2296346, lng: 19.3519027 },
    { lat: 49.2293812, lng: 19.3517173 },
    { lat: 49.2288565, lng: 19.3513396 },
    { lat: 49.228516, lng: 19.3510271 },
    { lat: 49.2279751, lng: 19.3502361 },
    { lat: 49.2277181, lng: 19.3502343 },
    { lat: 49.2274125, lng: 19.3497006 },
    { lat: 49.2273303, lng: 19.3494388 },
    { lat: 49.2272611, lng: 19.3492117 },
    { lat: 49.2270189, lng: 19.3489537 },
    { lat: 49.2269068, lng: 19.3486527 },
    { lat: 49.2267716, lng: 19.3481739 },
    { lat: 49.2264753, lng: 19.3480784 },
    { lat: 49.2263197, lng: 19.3478838 },
    { lat: 49.226111, lng: 19.3475919 },
    { lat: 49.2258527, lng: 19.3470115 },
    { lat: 49.2257241, lng: 19.3467927 },
    { lat: 49.2256505, lng: 19.3465484 },
    { lat: 49.2255174, lng: 19.346157 },
    { lat: 49.2254704, lng: 19.3460226 },
    { lat: 49.2253616, lng: 19.3458424 },
    { lat: 49.2254016, lng: 19.3456642 },
    { lat: 49.2254087, lng: 19.3454071 },
    { lat: 49.2254179, lng: 19.3450004 },
    { lat: 49.2255538, lng: 19.344369 },
    { lat: 49.2255553, lng: 19.3433233 },
    { lat: 49.2257658, lng: 19.3423858 },
    { lat: 49.2259562, lng: 19.3408704 },
    { lat: 49.2259992, lng: 19.3404204 },
    { lat: 49.2260476, lng: 19.3401346 },
    { lat: 49.2260837, lng: 19.339967 },
    { lat: 49.2260874, lng: 19.3399511 },
    { lat: 49.2261739, lng: 19.3398013 },
    { lat: 49.2262263, lng: 19.339766 },
    { lat: 49.2262657, lng: 19.3397393 },
    { lat: 49.2262734, lng: 19.3397341 },
    { lat: 49.2263396, lng: 19.3397056 },
    { lat: 49.2263584, lng: 19.3396981 },
    { lat: 49.2263739, lng: 19.339691 },
    { lat: 49.2267948, lng: 19.3395129 },
    { lat: 49.2267558, lng: 19.3389691 },
    { lat: 49.2267294, lng: 19.3381488 },
    { lat: 49.2267893, lng: 19.3377648 },
    { lat: 49.2273343, lng: 19.3358437 },
    { lat: 49.227798, lng: 19.3350389 },
    { lat: 49.2289064, lng: 19.3329268 },
    { lat: 49.2296562, lng: 19.3316902 },
    { lat: 49.2298286, lng: 19.3313554 },
    { lat: 49.2299551, lng: 19.3311471 },
    { lat: 49.230309, lng: 19.331626 },
    { lat: 49.2313706, lng: 19.329115 },
    { lat: 49.2315393, lng: 19.3279602 },
    { lat: 49.2315745, lng: 19.3271291 },
    { lat: 49.231406, lng: 19.3257002 },
    { lat: 49.2313885, lng: 19.3255781 },
    { lat: 49.2313298, lng: 19.3252056 },
    { lat: 49.2312293, lng: 19.3245675 },
    { lat: 49.2310987, lng: 19.3238752 },
    { lat: 49.230942, lng: 19.3230875 },
    { lat: 49.2305951, lng: 19.3231877 },
    { lat: 49.2304161, lng: 19.3224019 },
    { lat: 49.2303332, lng: 19.3219379 },
    { lat: 49.2303197, lng: 19.3217717 },
    { lat: 49.2303041, lng: 19.3215113 },
    { lat: 49.2303037, lng: 19.3213116 },
    { lat: 49.2302965, lng: 19.3208739 },
    { lat: 49.230303, lng: 19.3206528 },
    { lat: 49.2303056, lng: 19.3205419 },
    { lat: 49.2303165, lng: 19.3201274 },
    { lat: 49.2303202, lng: 19.3199918 },
    { lat: 49.2303211, lng: 19.3199408 },
    { lat: 49.2303223, lng: 19.319891 },
    { lat: 49.2303643, lng: 19.3195149 },
    { lat: 49.2303897, lng: 19.3189839 },
    { lat: 49.230464, lng: 19.3184752 },
    { lat: 49.2305446, lng: 19.3178475 },
    { lat: 49.2305978, lng: 19.3176141 },
    { lat: 49.2308477, lng: 19.31701 },
    { lat: 49.2308539, lng: 19.3169981 },
    { lat: 49.2309863, lng: 19.3167015 },
    { lat: 49.2310734, lng: 19.3165639 },
    { lat: 49.2313222, lng: 19.3162488 },
    { lat: 49.2313958, lng: 19.3161617 },
    { lat: 49.2319042, lng: 19.3157808 },
    { lat: 49.2320853, lng: 19.3156807 },
    { lat: 49.2323052, lng: 19.3154239 },
  ],
  Chlebnice: [
    { lat: 49.2371393, lng: 19.4452192 },
    { lat: 49.2364248, lng: 19.4440217 },
    { lat: 49.2364061, lng: 19.4439357 },
    { lat: 49.2364459, lng: 19.443431 },
    { lat: 49.2363817, lng: 19.4431862 },
    { lat: 49.2362436, lng: 19.4429778 },
    { lat: 49.2361102, lng: 19.4428945 },
    { lat: 49.2360359, lng: 19.4428722 },
    { lat: 49.2359011, lng: 19.4428102 },
    { lat: 49.2357308, lng: 19.4425646 },
    { lat: 49.2353063, lng: 19.442571 },
    { lat: 49.2342678, lng: 19.4428956 },
    { lat: 49.2340918, lng: 19.4431611 },
    { lat: 49.2336926, lng: 19.443397 },
    { lat: 49.2334257, lng: 19.443376 },
    { lat: 49.2330433, lng: 19.4433429 },
    { lat: 49.2329822, lng: 19.4424794 },
    { lat: 49.2330737, lng: 19.4413402 },
    { lat: 49.2329775, lng: 19.4407715 },
    { lat: 49.2329675, lng: 19.4407232 },
    { lat: 49.2329487, lng: 19.4406332 },
    { lat: 49.2328733, lng: 19.4401719 },
    { lat: 49.2328623, lng: 19.4401017 },
    { lat: 49.2328513, lng: 19.4400376 },
    { lat: 49.2327624, lng: 19.4395045 },
    { lat: 49.2321916, lng: 19.4397823 },
    { lat: 49.2315454, lng: 19.4397441 },
    { lat: 49.2314546, lng: 19.4400272 },
    { lat: 49.2313395, lng: 19.4403872 },
    { lat: 49.2312599, lng: 19.4406377 },
    { lat: 49.2311833, lng: 19.4408097 },
    { lat: 49.2309251, lng: 19.4413952 },
    { lat: 49.2308242, lng: 19.4416228 },
    { lat: 49.2304772, lng: 19.4413551 },
    { lat: 49.2302194, lng: 19.4411651 },
    { lat: 49.2295663, lng: 19.4404923 },
    { lat: 49.2291157, lng: 19.4400289 },
    { lat: 49.2287142, lng: 19.4395374 },
    { lat: 49.2283243, lng: 19.4392445 },
    { lat: 49.2280103, lng: 19.4391269 },
    { lat: 49.2272998, lng: 19.438827 },
    { lat: 49.2268232, lng: 19.4387785 },
    { lat: 49.226511, lng: 19.4387508 },
    { lat: 49.2265125, lng: 19.4386872 },
    { lat: 49.2262201, lng: 19.4385262 },
    { lat: 49.2258768, lng: 19.4383176 },
    { lat: 49.2254256, lng: 19.43816 },
    { lat: 49.225034, lng: 19.4380241 },
    { lat: 49.224294, lng: 19.4375703 },
    { lat: 49.2241524, lng: 19.4376028 },
    { lat: 49.2236738, lng: 19.4377305 },
    { lat: 49.2233078, lng: 19.4378078 },
    { lat: 49.223076, lng: 19.438079 },
    { lat: 49.2226289, lng: 19.4382554 },
    { lat: 49.2224904, lng: 19.4383807 },
    { lat: 49.2220917, lng: 19.4386291 },
    { lat: 49.2216274, lng: 19.4385288 },
    { lat: 49.221412, lng: 19.4393307 },
    { lat: 49.2212115, lng: 19.4399683 },
    { lat: 49.2211161, lng: 19.4405871 },
    { lat: 49.2210848, lng: 19.4406406 },
    { lat: 49.2210085, lng: 19.4407933 },
    { lat: 49.2206485, lng: 19.4415116 },
    { lat: 49.2201242, lng: 19.4422861 },
    { lat: 49.2200893, lng: 19.4423382 },
    { lat: 49.2197144, lng: 19.442809 },
    { lat: 49.2193831, lng: 19.4430504 },
    { lat: 49.2186517, lng: 19.4437315 },
    { lat: 49.2180979, lng: 19.4441862 },
    { lat: 49.2176378, lng: 19.444935 },
    { lat: 49.2175355, lng: 19.4449889 },
    { lat: 49.217333, lng: 19.4449454 },
    { lat: 49.2172159, lng: 19.4451188 },
    { lat: 49.2172117, lng: 19.4451261 },
    { lat: 49.2171843, lng: 19.4451672 },
    { lat: 49.2171665, lng: 19.4451958 },
    { lat: 49.2170669, lng: 19.4453795 },
    { lat: 49.2170346, lng: 19.4454385 },
    { lat: 49.2170047, lng: 19.4454951 },
    { lat: 49.2169962, lng: 19.4455101 },
    { lat: 49.2169058, lng: 19.4456762 },
    { lat: 49.2164459, lng: 19.4463774 },
    { lat: 49.2164375, lng: 19.4463902 },
    { lat: 49.2163106, lng: 19.4468886 },
    { lat: 49.2163032, lng: 19.4469038 },
    { lat: 49.2161771, lng: 19.4471492 },
    { lat: 49.2160435, lng: 19.4474764 },
    { lat: 49.2159794, lng: 19.4476026 },
    { lat: 49.2159785, lng: 19.4476038 },
    { lat: 49.2159567, lng: 19.4476469 },
    { lat: 49.2158835, lng: 19.4477903 },
    { lat: 49.2157804, lng: 19.4479783 },
    { lat: 49.2157347, lng: 19.4480604 },
    { lat: 49.2156037, lng: 19.448272 },
    { lat: 49.215567, lng: 19.4482849 },
    { lat: 49.215343, lng: 19.4483631 },
    { lat: 49.2152185, lng: 19.4483736 },
    { lat: 49.2149783, lng: 19.4483717 },
    { lat: 49.214753, lng: 19.4483696 },
    { lat: 49.2145571, lng: 19.4485719 },
    { lat: 49.2145456, lng: 19.4488604 },
    { lat: 49.2145612, lng: 19.4491734 },
    { lat: 49.2145238, lng: 19.449411 },
    { lat: 49.2145149, lng: 19.4494639 },
    { lat: 49.2144333, lng: 19.4499672 },
    { lat: 49.2144334, lng: 19.44997 },
    { lat: 49.2144219, lng: 19.4500379 },
    { lat: 49.2142356, lng: 19.4502472 },
    { lat: 49.2142248, lng: 19.4502592 },
    { lat: 49.2141444, lng: 19.45035 },
    { lat: 49.2139118, lng: 19.450384 },
    { lat: 49.2136341, lng: 19.450425 },
    { lat: 49.2135412, lng: 19.4504386 },
    { lat: 49.213492, lng: 19.450446 },
    { lat: 49.2132952, lng: 19.4504805 },
    { lat: 49.2130069, lng: 19.4505306 },
    { lat: 49.2127061, lng: 19.4505831 },
    { lat: 49.2126646, lng: 19.4512197 },
    { lat: 49.2125781, lng: 19.4513956 },
    { lat: 49.2125128, lng: 19.451568 },
    { lat: 49.2122796, lng: 19.4517697 },
    { lat: 49.2121345, lng: 19.4518224 },
    { lat: 49.2118085, lng: 19.4521573 },
    { lat: 49.211534, lng: 19.4524376 },
    { lat: 49.2115936, lng: 19.4527264 },
    { lat: 49.2115999, lng: 19.4527657 },
    { lat: 49.2108025, lng: 19.4531386 },
    { lat: 49.2101328, lng: 19.4536441 },
    { lat: 49.2094111, lng: 19.4542932 },
    { lat: 49.2087319, lng: 19.4550339 },
    { lat: 49.2082563, lng: 19.4555445 },
    { lat: 49.2075327, lng: 19.4560748 },
    { lat: 49.2066272, lng: 19.4565986 },
    { lat: 49.2057066, lng: 19.4572128 },
    { lat: 49.2051634, lng: 19.4575748 },
    { lat: 49.204278, lng: 19.4579963 },
    { lat: 49.2033819, lng: 19.458678 },
    { lat: 49.2030975, lng: 19.4591309 },
    { lat: 49.2026541, lng: 19.4597075 },
    { lat: 49.2016389, lng: 19.4607857 },
    { lat: 49.2006714, lng: 19.462262 },
    { lat: 49.2006135, lng: 19.4630854 },
    { lat: 49.2005474, lng: 19.4639995 },
    { lat: 49.2005069, lng: 19.4647353 },
    { lat: 49.200489, lng: 19.4652767 },
    { lat: 49.2004712, lng: 19.4654789 },
    { lat: 49.2003948, lng: 19.4661433 },
    { lat: 49.2003384, lng: 19.4671511 },
    { lat: 49.2002542, lng: 19.4679132 },
    { lat: 49.200222, lng: 19.4684925 },
    { lat: 49.2001915, lng: 19.4690517 },
    { lat: 49.1999555, lng: 19.4705126 },
    { lat: 49.1999334, lng: 19.4720417 },
    { lat: 49.1998939, lng: 19.4733409 },
    { lat: 49.199731, lng: 19.4746782 },
    { lat: 49.1997268, lng: 19.4752495 },
    { lat: 49.1997193, lng: 19.4761875 },
    { lat: 49.1996958, lng: 19.4766109 },
    { lat: 49.1995399, lng: 19.4775093 },
    { lat: 49.1993335, lng: 19.4787809 },
    { lat: 49.199058, lng: 19.4804135 },
    { lat: 49.1989831, lng: 19.4821982 },
    { lat: 49.1989835, lng: 19.4822064 },
    { lat: 49.1988984, lng: 19.4830028 },
    { lat: 49.1988985, lng: 19.4830046 },
    { lat: 49.1988797, lng: 19.4839691 },
    { lat: 49.1988744, lng: 19.4843234 },
    { lat: 49.1988085, lng: 19.4852194 },
    { lat: 49.1988703, lng: 19.4859291 },
    { lat: 49.198883, lng: 19.48643 },
    { lat: 49.19883, lng: 19.486806 },
    { lat: 49.198136, lng: 19.489421 },
    { lat: 49.197353, lng: 19.491534 },
    { lat: 49.197386, lng: 19.491837 },
    { lat: 49.197469, lng: 19.492378 },
    { lat: 49.199398, lng: 19.49503 },
    { lat: 49.199395, lng: 19.49621 },
    { lat: 49.20081, lng: 19.497581 },
    { lat: 49.2011, lng: 19.497904 },
    { lat: 49.201654, lng: 19.498522 },
    { lat: 49.202394, lng: 19.499278 },
    { lat: 49.202491, lng: 19.499747 },
    { lat: 49.202607, lng: 19.500309 },
    { lat: 49.202736, lng: 19.500585 },
    { lat: 49.203409, lng: 19.501681 },
    { lat: 49.203957, lng: 19.502321 },
    { lat: 49.203941, lng: 19.502363 },
    { lat: 49.203925, lng: 19.502403 },
    { lat: 49.203906, lng: 19.502484 },
    { lat: 49.20402, lng: 19.502565 },
    { lat: 49.204123, lng: 19.502627 },
    { lat: 49.2042, lng: 19.50268 },
    { lat: 49.204304, lng: 19.502957 },
    { lat: 49.204406, lng: 19.503269 },
    { lat: 49.204685, lng: 19.503518 },
    { lat: 49.205314, lng: 19.504929 },
    { lat: 49.205875, lng: 19.506984 },
    { lat: 49.205932, lng: 19.50715 },
    { lat: 49.206069, lng: 19.507552 },
    { lat: 49.206258, lng: 19.507998 },
    { lat: 49.206316, lng: 19.508364 },
    { lat: 49.206513, lng: 19.509768 },
    { lat: 49.206892, lng: 19.510212 },
    { lat: 49.207024, lng: 19.510259 },
    { lat: 49.207962, lng: 19.510499 },
    { lat: 49.208614, lng: 19.510741 },
    { lat: 49.209988, lng: 19.511226 },
    { lat: 49.210115, lng: 19.511268 },
    { lat: 49.210905, lng: 19.51153 },
    { lat: 49.211782, lng: 19.511755 },
    { lat: 49.212223, lng: 19.512013 },
    { lat: 49.212959, lng: 19.512889 },
    { lat: 49.213474, lng: 19.513524 },
    { lat: 49.213816, lng: 19.514391 },
    { lat: 49.214453, lng: 19.516335 },
    { lat: 49.215684, lng: 19.518106 },
    { lat: 49.21713, lng: 19.520222 },
    { lat: 49.218121, lng: 19.522136 },
    { lat: 49.218141, lng: 19.522158 },
    { lat: 49.218954, lng: 19.522807 },
    { lat: 49.220659, lng: 19.523205 },
    { lat: 49.222136, lng: 19.523258 },
    { lat: 49.222492, lng: 19.523351 },
    { lat: 49.223435, lng: 19.523801 },
    { lat: 49.223981, lng: 19.523812 },
    { lat: 49.2268426, lng: 19.5251396 },
    { lat: 49.226962, lng: 19.525195 },
    { lat: 49.227951, lng: 19.525636 },
    { lat: 49.22895, lng: 19.525818 },
    { lat: 49.230383, lng: 19.52595 },
    { lat: 49.230951, lng: 19.526002 },
    { lat: 49.231271, lng: 19.526304 },
    { lat: 49.233461, lng: 19.527645 },
    { lat: 49.234635, lng: 19.528059 },
    { lat: 49.235246, lng: 19.528259 },
    { lat: 49.236117, lng: 19.528936 },
    { lat: 49.2360519, lng: 19.5264768 },
    { lat: 49.2360075, lng: 19.5250515 },
    { lat: 49.2360423, lng: 19.5247053 },
    { lat: 49.2360693, lng: 19.5230932 },
    { lat: 49.2363612, lng: 19.5215327 },
    { lat: 49.2376131, lng: 19.5201213 },
    { lat: 49.2378444, lng: 19.5198602 },
    { lat: 49.2388916, lng: 19.5186784 },
    { lat: 49.2389859, lng: 19.5185721 },
    { lat: 49.2390941, lng: 19.51845 },
    { lat: 49.2409769, lng: 19.5175584 },
    { lat: 49.2410071, lng: 19.5175329 },
    { lat: 49.2415086, lng: 19.5172633 },
    { lat: 49.2427609, lng: 19.5170957 },
    { lat: 49.2433654, lng: 19.5170877 },
    { lat: 49.2438855, lng: 19.5170044 },
    { lat: 49.2449563, lng: 19.516939 },
    { lat: 49.245204, lng: 19.5168615 },
    { lat: 49.2455197, lng: 19.516763 },
    { lat: 49.2456719, lng: 19.5167151 },
    { lat: 49.2472684, lng: 19.5158733 },
    { lat: 49.2480346, lng: 19.5155002 },
    { lat: 49.2489962, lng: 19.5158106 },
    { lat: 49.2501313, lng: 19.515747 },
    { lat: 49.25055, lng: 19.515612 },
    { lat: 49.2506958, lng: 19.5152258 },
    { lat: 49.2508152, lng: 19.5149303 },
    { lat: 49.2508255, lng: 19.5149065 },
    { lat: 49.2510561, lng: 19.5143565 },
    { lat: 49.2506007, lng: 19.5142267 },
    { lat: 49.2501447, lng: 19.5141114 },
    { lat: 49.2495884, lng: 19.5138455 },
    { lat: 49.2489502, lng: 19.5135873 },
    { lat: 49.2480742, lng: 19.5131546 },
    { lat: 49.2476578, lng: 19.512845 },
    { lat: 49.2474427, lng: 19.5121051 },
    { lat: 49.2468303, lng: 19.5120908 },
    { lat: 49.2462195, lng: 19.5120645 },
    { lat: 49.2463526, lng: 19.5115491 },
    { lat: 49.2464258, lng: 19.511348 },
    { lat: 49.246428, lng: 19.5112511 },
    { lat: 49.2461168, lng: 19.5107738 },
    { lat: 49.2460869, lng: 19.5105898 },
    { lat: 49.246212, lng: 19.5099205 },
    { lat: 49.2462818, lng: 19.5095968 },
    { lat: 49.2462925, lng: 19.5092163 },
    { lat: 49.2463315, lng: 19.5090358 },
    { lat: 49.2464031, lng: 19.5087721 },
    { lat: 49.2464599, lng: 19.5085104 },
    { lat: 49.2465608, lng: 19.5082547 },
    { lat: 49.2465658, lng: 19.5080414 },
    { lat: 49.2465724, lng: 19.507965 },
    { lat: 49.2467735, lng: 19.5071859 },
    { lat: 49.2468273, lng: 19.5068076 },
    { lat: 49.2469086, lng: 19.5066651 },
    { lat: 49.2469328, lng: 19.5063553 },
    { lat: 49.2469088, lng: 19.5060989 },
    { lat: 49.246897, lng: 19.5059642 },
    { lat: 49.2469153, lng: 19.5059204 },
    { lat: 49.2468593, lng: 19.5056093 },
    { lat: 49.2469017, lng: 19.5054512 },
    { lat: 49.2468542, lng: 19.5052488 },
    { lat: 49.2469244, lng: 19.5050859 },
    { lat: 49.2470085, lng: 19.5047023 },
    { lat: 49.2470658, lng: 19.5045043 },
    { lat: 49.2470937, lng: 19.5042979 },
    { lat: 49.2471074, lng: 19.5041792 },
    { lat: 49.2473742, lng: 19.503854 },
    { lat: 49.2475406, lng: 19.5036253 },
    { lat: 49.2476272, lng: 19.5035205 },
    { lat: 49.2478294, lng: 19.5032382 },
    { lat: 49.2481219, lng: 19.5029059 },
    { lat: 49.2483043, lng: 19.5027787 },
    { lat: 49.2484105, lng: 19.5024673 },
    { lat: 49.2485397, lng: 19.5019403 },
    { lat: 49.2487123, lng: 19.501501 },
    { lat: 49.2487079, lng: 19.5012029 },
    { lat: 49.2486301, lng: 19.5011076 },
    { lat: 49.2485424, lng: 19.5008876 },
    { lat: 49.2485694, lng: 19.5002171 },
    { lat: 49.248546, lng: 19.4999531 },
    { lat: 49.2485802, lng: 19.4998383 },
    { lat: 49.2485901, lng: 19.4997074 },
    { lat: 49.2485546, lng: 19.4992777 },
    { lat: 49.2485137, lng: 19.4990476 },
    { lat: 49.2484396, lng: 19.4986617 },
    { lat: 49.2484087, lng: 19.4984031 },
    { lat: 49.2483752, lng: 19.4981153 },
    { lat: 49.2482811, lng: 19.4977812 },
    { lat: 49.2482729, lng: 19.4976014 },
    { lat: 49.2481027, lng: 19.4970811 },
    { lat: 49.2480374, lng: 19.4969845 },
    { lat: 49.2478881, lng: 19.4965269 },
    { lat: 49.2478906, lng: 19.4964595 },
    { lat: 49.2479395, lng: 19.4962206 },
    { lat: 49.24776, lng: 19.4957179 },
    { lat: 49.2477875, lng: 19.4955559 },
    { lat: 49.2478921, lng: 19.4953799 },
    { lat: 49.2478893, lng: 19.4952464 },
    { lat: 49.2478384, lng: 19.495219 },
    { lat: 49.2477255, lng: 19.4951387 },
    { lat: 49.2477416, lng: 19.4948968 },
    { lat: 49.2477551, lng: 19.4946252 },
    { lat: 49.2477797, lng: 19.4944754 },
    { lat: 49.2477411, lng: 19.4941986 },
    { lat: 49.2478358, lng: 19.4938538 },
    { lat: 49.2477972, lng: 19.4936509 },
    { lat: 49.2475806, lng: 19.4934212 },
    { lat: 49.2475526, lng: 19.4932535 },
    { lat: 49.2476413, lng: 19.4931016 },
    { lat: 49.2475953, lng: 19.4926835 },
    { lat: 49.2475295, lng: 19.4921585 },
    { lat: 49.2472894, lng: 19.491958 },
    { lat: 49.2473079, lng: 19.4912513 },
    { lat: 49.2471554, lng: 19.4911965 },
    { lat: 49.2470866, lng: 19.490849 },
    { lat: 49.2468374, lng: 19.4905715 },
    { lat: 49.2467753, lng: 19.4902514 },
    { lat: 49.2465747, lng: 19.4900532 },
    { lat: 49.2463543, lng: 19.4900406 },
    { lat: 49.246316, lng: 19.4899664 },
    { lat: 49.2460952, lng: 19.4894696 },
    { lat: 49.245785, lng: 19.4891467 },
    { lat: 49.2455994, lng: 19.4888055 },
    { lat: 49.2455726, lng: 19.4887619 },
    { lat: 49.2455716, lng: 19.4887183 },
    { lat: 49.2455999, lng: 19.4884499 },
    { lat: 49.2455598, lng: 19.4883369 },
    { lat: 49.2455197, lng: 19.4882244 },
    { lat: 49.2454712, lng: 19.488196 },
    { lat: 49.2452505, lng: 19.4878322 },
    { lat: 49.2447493, lng: 19.4876117 },
    { lat: 49.2448589, lng: 19.4873241 },
    { lat: 49.244648, lng: 19.4871976 },
    { lat: 49.2443901, lng: 19.4864859 },
    { lat: 49.2442911, lng: 19.4863439 },
    { lat: 49.2439177, lng: 19.4862337 },
    { lat: 49.2438741, lng: 19.4860952 },
    { lat: 49.2438637, lng: 19.4858695 },
    { lat: 49.2438414, lng: 19.4854997 },
    { lat: 49.2437347, lng: 19.485237 },
    { lat: 49.2436187, lng: 19.4850911 },
    { lat: 49.2435697, lng: 19.4846877 },
    { lat: 49.2433899, lng: 19.4842045 },
    { lat: 49.2433031, lng: 19.4838874 },
    { lat: 49.2431212, lng: 19.4837251 },
    { lat: 49.2430952, lng: 19.4836734 },
    { lat: 49.2430944, lng: 19.4834864 },
    { lat: 49.2430496, lng: 19.4832694 },
    { lat: 49.2430149, lng: 19.4830285 },
    { lat: 49.2429822, lng: 19.4827104 },
    { lat: 49.242926, lng: 19.4824181 },
    { lat: 49.2427304, lng: 19.4820802 },
    { lat: 49.2425892, lng: 19.4815078 },
    { lat: 49.242532, lng: 19.4812189 },
    { lat: 49.2424799, lng: 19.48087 },
    { lat: 49.2424361, lng: 19.480527 },
    { lat: 49.2423987, lng: 19.4803504 },
    { lat: 49.2423757, lng: 19.4802194 },
    { lat: 49.2423029, lng: 19.4795164 },
    { lat: 49.2423073, lng: 19.4794383 },
    { lat: 49.2423249, lng: 19.4794296 },
    { lat: 49.2423756, lng: 19.4794033 },
    { lat: 49.2425334, lng: 19.4794041 },
    { lat: 49.2425587, lng: 19.4793682 },
    { lat: 49.2425786, lng: 19.4792889 },
    { lat: 49.2425888, lng: 19.4791136 },
    { lat: 49.2424959, lng: 19.4789057 },
    { lat: 49.2424329, lng: 19.4785417 },
    { lat: 49.2424379, lng: 19.4782794 },
    { lat: 49.2425125, lng: 19.478041 },
    { lat: 49.2424808, lng: 19.4775723 },
    { lat: 49.2424639, lng: 19.4773739 },
    { lat: 49.2425227, lng: 19.4770077 },
    { lat: 49.2424531, lng: 19.4769932 },
    { lat: 49.242218, lng: 19.4765578 },
    { lat: 49.2422283, lng: 19.4760919 },
    { lat: 49.2422296, lng: 19.4760232 },
    { lat: 49.2422389, lng: 19.4756857 },
    { lat: 49.242241, lng: 19.4756336 },
    { lat: 49.242321, lng: 19.4755861 },
    { lat: 49.242366, lng: 19.4755582 },
    { lat: 49.2423747, lng: 19.4751346 },
    { lat: 49.2423449, lng: 19.4747523 },
    { lat: 49.2423132, lng: 19.474408 },
    { lat: 49.2422207, lng: 19.4741348 },
    { lat: 49.2422086, lng: 19.4740405 },
    { lat: 49.2422095, lng: 19.4740148 },
    { lat: 49.2422623, lng: 19.4738628 },
    { lat: 49.2422583, lng: 19.4736071 },
    { lat: 49.2422843, lng: 19.4733869 },
    { lat: 49.2423122, lng: 19.4732335 },
    { lat: 49.2423175, lng: 19.4730548 },
    { lat: 49.2422751, lng: 19.4728412 },
    { lat: 49.2421947, lng: 19.4726583 },
    { lat: 49.2421374, lng: 19.4725674 },
    { lat: 49.2420509, lng: 19.472304 },
    { lat: 49.2420197, lng: 19.4722623 },
    { lat: 49.2419818, lng: 19.471117 },
    { lat: 49.2419074, lng: 19.4708697 },
    { lat: 49.2419149, lng: 19.4707401 },
    { lat: 49.2419897, lng: 19.4704292 },
    { lat: 49.2419563, lng: 19.4703148 },
    { lat: 49.2418212, lng: 19.4702665 },
    { lat: 49.2416932, lng: 19.4699113 },
    { lat: 49.2416805, lng: 19.4697544 },
    { lat: 49.241788, lng: 19.4692766 },
    { lat: 49.2418585, lng: 19.4692903 },
    { lat: 49.2421822, lng: 19.4692221 },
    { lat: 49.2421936, lng: 19.469179 },
    { lat: 49.2422034, lng: 19.4691444 },
    { lat: 49.2422181, lng: 19.4690956 },
    { lat: 49.2422578, lng: 19.4689558 },
    { lat: 49.2421224, lng: 19.4687279 },
    { lat: 49.2419848, lng: 19.4687034 },
    { lat: 49.2417444, lng: 19.4681279 },
    { lat: 49.241484, lng: 19.4676337 },
    { lat: 49.2414261, lng: 19.4676027 },
    { lat: 49.2414464, lng: 19.4674321 },
    { lat: 49.2414592, lng: 19.4673146 },
    { lat: 49.2415101, lng: 19.4671018 },
    { lat: 49.2416012, lng: 19.4669734 },
    { lat: 49.2416676, lng: 19.466826 },
    { lat: 49.2416632, lng: 19.4667302 },
    { lat: 49.2416012, lng: 19.466532 },
    { lat: 49.2415577, lng: 19.466422 },
    { lat: 49.2414831, lng: 19.4662681 },
    { lat: 49.2414215, lng: 19.4661553 },
    { lat: 49.2413327, lng: 19.4660123 },
    { lat: 49.2411991, lng: 19.4658477 },
    { lat: 49.2410859, lng: 19.4656922 },
    { lat: 49.2410524, lng: 19.4655028 },
    { lat: 49.2410287, lng: 19.4653561 },
    { lat: 49.2410781, lng: 19.4653738 },
    { lat: 49.2411635, lng: 19.4652936 },
    { lat: 49.2410916, lng: 19.4644438 },
    { lat: 49.2409987, lng: 19.4644058 },
    { lat: 49.2409397, lng: 19.4644211 },
    { lat: 49.2408507, lng: 19.4640787 },
    { lat: 49.2408931, lng: 19.4640449 },
    { lat: 49.2409963, lng: 19.4638665 },
    { lat: 49.2408405, lng: 19.4635169 },
    { lat: 49.2407467, lng: 19.463413 },
    { lat: 49.2406829, lng: 19.4634216 },
    { lat: 49.2406217, lng: 19.4632428 },
    { lat: 49.2405761, lng: 19.4630825 },
    { lat: 49.2405622, lng: 19.4629538 },
    { lat: 49.2405415, lng: 19.4628439 },
    { lat: 49.2404763, lng: 19.4623586 },
    { lat: 49.2404606, lng: 19.4622392 },
    { lat: 49.2404484, lng: 19.4621431 },
    { lat: 49.240387, lng: 19.4618136 },
    { lat: 49.2404447, lng: 19.4617706 },
    { lat: 49.24057, lng: 19.461702 },
    { lat: 49.2405624, lng: 19.4616801 },
    { lat: 49.2404944, lng: 19.4615006 },
    { lat: 49.2404339, lng: 19.4613394 },
    { lat: 49.2402899, lng: 19.461075 },
    { lat: 49.2401321, lng: 19.4605589 },
    { lat: 49.2400776, lng: 19.4605244 },
    { lat: 49.2398784, lng: 19.4606543 },
    { lat: 49.2398413, lng: 19.4606064 },
    { lat: 49.2397523, lng: 19.4605308 },
    { lat: 49.23968, lng: 19.4603085 },
    { lat: 49.2396788, lng: 19.4603036 },
    { lat: 49.2396591, lng: 19.460244 },
    { lat: 49.2396572, lng: 19.4602291 },
    { lat: 49.2396569, lng: 19.4602226 },
    { lat: 49.239626, lng: 19.4598177 },
    { lat: 49.2395432, lng: 19.4594133 },
    { lat: 49.2395139, lng: 19.4595153 },
    { lat: 49.2394441, lng: 19.459394 },
    { lat: 49.2397619, lng: 19.4587076 },
    { lat: 49.2398513, lng: 19.4585209 },
    { lat: 49.2402207, lng: 19.4581221 },
    { lat: 49.2403809, lng: 19.4580537 },
    { lat: 49.2405169, lng: 19.4579958 },
    { lat: 49.2405814, lng: 19.4579319 },
    { lat: 49.2407086, lng: 19.4578068 },
    { lat: 49.2407756, lng: 19.4576211 },
    { lat: 49.2408235, lng: 19.4573635 },
    { lat: 49.2408009, lng: 19.4570471 },
    { lat: 49.2407585, lng: 19.4567874 },
    { lat: 49.2407508, lng: 19.4566407 },
    { lat: 49.2407754, lng: 19.4565389 },
    { lat: 49.2408269, lng: 19.4564367 },
    { lat: 49.2408787, lng: 19.4563871 },
    { lat: 49.2414453, lng: 19.4566274 },
    { lat: 49.2415062, lng: 19.4566259 },
    { lat: 49.2415498, lng: 19.456547 },
    { lat: 49.2416181, lng: 19.4561707 },
    { lat: 49.2416222, lng: 19.4560639 },
    { lat: 49.241668, lng: 19.4559814 },
    { lat: 49.2417404, lng: 19.455816 },
    { lat: 49.2415131, lng: 19.4553103 },
    { lat: 49.2413578, lng: 19.4548488 },
    { lat: 49.2410932, lng: 19.4546576 },
    { lat: 49.2407132, lng: 19.4539689 },
    { lat: 49.2402365, lng: 19.4531077 },
    { lat: 49.2398994, lng: 19.4519056 },
    { lat: 49.239881, lng: 19.4518481 },
    { lat: 49.2398263, lng: 19.4516408 },
    { lat: 49.2396924, lng: 19.4511071 },
    { lat: 49.2391024, lng: 19.449966 },
    { lat: 49.2389107, lng: 19.4493058 },
    { lat: 49.2388333, lng: 19.4489229 },
    { lat: 49.2385435, lng: 19.4482097 },
    { lat: 49.2380613, lng: 19.4472825 },
    { lat: 49.2380039, lng: 19.4471322 },
    { lat: 49.2378568, lng: 19.4468765 },
    { lat: 49.2376758, lng: 19.4465916 },
    { lat: 49.2374756, lng: 19.4461638 },
    { lat: 49.2372276, lng: 19.4456702 },
    { lat: 49.2371393, lng: 19.4452192 },
  ],
  DlhánadOravou: [
    { lat: 49.308921, lng: 19.405882 },
    { lat: 49.3085532, lng: 19.4066457 },
    { lat: 49.3081537, lng: 19.407313 },
    { lat: 49.3076573, lng: 19.4080292 },
    { lat: 49.30665, lng: 19.409072 },
    { lat: 49.3058931, lng: 19.4099301 },
    { lat: 49.3052819, lng: 19.4103362 },
    { lat: 49.3045952, lng: 19.4106912 },
    { lat: 49.3040284, lng: 19.411056 },
    { lat: 49.3034589, lng: 19.4116771 },
    { lat: 49.3030995, lng: 19.4119212 },
    { lat: 49.3025984, lng: 19.4122413 },
    { lat: 49.3025901, lng: 19.4128159 },
    { lat: 49.3029474, lng: 19.4136221 },
    { lat: 49.3030872, lng: 19.4148914 },
    { lat: 49.3026861, lng: 19.4151569 },
    { lat: 49.3021692, lng: 19.4154573 },
    { lat: 49.3019924, lng: 19.4155043 },
    { lat: 49.3015328, lng: 19.4156545 },
    { lat: 49.3009589, lng: 19.4161312 },
    { lat: 49.3004209, lng: 19.4170473 },
    { lat: 49.3002732, lng: 19.4172377 },
    { lat: 49.3001273, lng: 19.4174203 },
    { lat: 49.2997299, lng: 19.4178692 },
    { lat: 49.2993065, lng: 19.4180945 },
    { lat: 49.2990068, lng: 19.4184335 },
    { lat: 49.2986275, lng: 19.418662 },
    { lat: 49.2981946, lng: 19.418924 },
    { lat: 49.2975808, lng: 19.4191778 },
    { lat: 49.2973031, lng: 19.4194318 },
    { lat: 49.2971968, lng: 19.4195227 },
    { lat: 49.2971215, lng: 19.4195984 },
    { lat: 49.2965101, lng: 19.4202187 },
    { lat: 49.296116, lng: 19.4206112 },
    { lat: 49.2960187, lng: 19.4206792 },
    { lat: 49.2956159, lng: 19.4209842 },
    { lat: 49.2952745, lng: 19.4211789 },
    { lat: 49.29471, lng: 19.4211053 },
    { lat: 49.2944199, lng: 19.4210706 },
    { lat: 49.294237, lng: 19.4208438 },
    { lat: 49.2941721, lng: 19.420853 },
    { lat: 49.2934646, lng: 19.4209296 },
    { lat: 49.2933532, lng: 19.420955 },
    { lat: 49.293189, lng: 19.4209883 },
    { lat: 49.2926968, lng: 19.4213585 },
    { lat: 49.2923522, lng: 19.4214128 },
    { lat: 49.2912808, lng: 19.4220013 },
    { lat: 49.2909706, lng: 19.4221879 },
    { lat: 49.2908988, lng: 19.4221971 },
    { lat: 49.2908488, lng: 19.4222079 },
    { lat: 49.2902729, lng: 19.4226471 },
    { lat: 49.2898669, lng: 19.4229556 },
    { lat: 49.289662, lng: 19.4231045 },
    { lat: 49.2889802, lng: 19.4237619 },
    { lat: 49.2889261, lng: 19.423832 },
    { lat: 49.2884029, lng: 19.4245312 },
    { lat: 49.2882824, lng: 19.4247027 },
    { lat: 49.2880772, lng: 19.4249941 },
    { lat: 49.2877841, lng: 19.4256012 },
    { lat: 49.2877093, lng: 19.4257349 },
    { lat: 49.287477, lng: 19.4261481 },
    { lat: 49.2873821, lng: 19.426359 },
    { lat: 49.2873403, lng: 19.426456 },
    { lat: 49.2869169, lng: 19.4271432 },
    { lat: 49.2868217, lng: 19.4272805 },
    { lat: 49.2866437, lng: 19.4278625 },
    { lat: 49.2858298, lng: 19.4290994 },
    { lat: 49.2853025, lng: 19.4295112 },
    { lat: 49.2851038, lng: 19.4297037 },
    { lat: 49.2846807, lng: 19.4303471 },
    { lat: 49.2844821, lng: 19.430827 },
    { lat: 49.2837842, lng: 19.4315033 },
    { lat: 49.2835238, lng: 19.4317448 },
    { lat: 49.2832096, lng: 19.4320895 },
    { lat: 49.2828833, lng: 19.4322687 },
    { lat: 49.2826867, lng: 19.4324787 },
    { lat: 49.2825758, lng: 19.4325434 },
    { lat: 49.2823771, lng: 19.4326561 },
    { lat: 49.2822929, lng: 19.4327107 },
    { lat: 49.2821065, lng: 19.43282 },
    { lat: 49.2813964, lng: 19.4329883 },
    { lat: 49.2811692, lng: 19.4328486 },
    { lat: 49.280786, lng: 19.4330441 },
    { lat: 49.2803454, lng: 19.4333133 },
    { lat: 49.280262, lng: 19.4332627 },
    { lat: 49.2798297, lng: 19.4332931 },
    { lat: 49.2797826, lng: 19.4334444 },
    { lat: 49.2792318, lng: 19.433501 },
    { lat: 49.2786825, lng: 19.4335866 },
    { lat: 49.2786966, lng: 19.4337193 },
    { lat: 49.2783809, lng: 19.4338613 },
    { lat: 49.2779489, lng: 19.4340472 },
    { lat: 49.2776812, lng: 19.4342288 },
    { lat: 49.2772703, lng: 19.434529 },
    { lat: 49.2771665, lng: 19.4343316 },
    { lat: 49.2768268, lng: 19.4348089 },
    { lat: 49.2767604, lng: 19.4347356 },
    { lat: 49.2766836, lng: 19.4347085 },
    { lat: 49.2763868, lng: 19.4346261 },
    { lat: 49.2762641, lng: 19.4344604 },
    { lat: 49.2762074, lng: 19.4344235 },
    { lat: 49.276029, lng: 19.4343443 },
    { lat: 49.2757224, lng: 19.434193 },
    { lat: 49.2755762, lng: 19.4342495 },
    { lat: 49.2754244, lng: 19.4341623 },
    { lat: 49.2753851, lng: 19.4341391 },
    { lat: 49.2750843, lng: 19.4340417 },
    { lat: 49.2750522, lng: 19.4340275 },
    { lat: 49.2747585, lng: 19.4339664 },
    { lat: 49.2746096, lng: 19.4339374 },
    { lat: 49.2745662, lng: 19.4339236 },
    { lat: 49.2744706, lng: 19.4339289 },
    { lat: 49.2742154, lng: 19.4339164 },
    { lat: 49.274154, lng: 19.433906 },
    { lat: 49.2739972, lng: 19.4338797 },
    { lat: 49.2738684, lng: 19.4338928 },
    { lat: 49.2737824, lng: 19.4339361 },
    { lat: 49.2735035, lng: 19.4340686 },
    { lat: 49.2730351, lng: 19.4342956 },
    { lat: 49.2728624, lng: 19.4343648 },
    { lat: 49.2727386, lng: 19.4344146 },
    { lat: 49.2726258, lng: 19.4344621 },
    { lat: 49.2723402, lng: 19.4344987 },
    { lat: 49.2720533, lng: 19.4343622 },
    { lat: 49.2717953, lng: 19.434241 },
    { lat: 49.2717004, lng: 19.4341842 },
    { lat: 49.2714721, lng: 19.4340468 },
    { lat: 49.271289, lng: 19.4338652 },
    { lat: 49.2711402, lng: 19.4338148 },
    { lat: 49.2708674, lng: 19.4338389 },
    { lat: 49.2705163, lng: 19.4338735 },
    { lat: 49.2704121, lng: 19.4339075 },
    { lat: 49.2701332, lng: 19.4339942 },
    { lat: 49.2699265, lng: 19.434058 },
    { lat: 49.2697092, lng: 19.4340887 },
    { lat: 49.2694232, lng: 19.4341369 },
    { lat: 49.2691385, lng: 19.4341488 },
    { lat: 49.2689981, lng: 19.4341575 },
    { lat: 49.2686675, lng: 19.4341975 },
    { lat: 49.2680202, lng: 19.4340162 },
    { lat: 49.267454, lng: 19.4338777 },
    { lat: 49.2671521, lng: 19.4336404 },
    { lat: 49.266569, lng: 19.4337254 },
    { lat: 49.266162, lng: 19.4337408 },
    { lat: 49.2657864, lng: 19.4337384 },
    { lat: 49.2646125, lng: 19.4336936 },
    { lat: 49.2643011, lng: 19.4337059 },
    { lat: 49.2642054, lng: 19.433708 },
    { lat: 49.2638399, lng: 19.4337759 },
    { lat: 49.2635717, lng: 19.4338215 },
    { lat: 49.2632376, lng: 19.4338849 },
    { lat: 49.2631129, lng: 19.4338677 },
    { lat: 49.2629006, lng: 19.4340053 },
    { lat: 49.2627853, lng: 19.4339936 },
    { lat: 49.2626089, lng: 19.4335184 },
    { lat: 49.2621166, lng: 19.4335953 },
    { lat: 49.261946, lng: 19.4336801 },
    { lat: 49.2613163, lng: 19.433976 },
    { lat: 49.2611896, lng: 19.4345298 },
    { lat: 49.2606695, lng: 19.435635 },
    { lat: 49.2605563, lng: 19.4358175 },
    { lat: 49.2597437, lng: 19.435938 },
    { lat: 49.2594786, lng: 19.4359539 },
    { lat: 49.259374, lng: 19.4359605 },
    { lat: 49.2592516, lng: 19.4359683 },
    { lat: 49.2583155, lng: 19.4359765 },
    { lat: 49.2575517, lng: 19.4361765 },
    { lat: 49.2571702, lng: 19.436305 },
    { lat: 49.2566069, lng: 19.4363852 },
    { lat: 49.2561363, lng: 19.4364857 },
    { lat: 49.2558867, lng: 19.436548 },
    { lat: 49.2558303, lng: 19.436549 },
    { lat: 49.2556532, lng: 19.4365681 },
    { lat: 49.2555036, lng: 19.4365763 },
    { lat: 49.2548412, lng: 19.4366517 },
    { lat: 49.2545825, lng: 19.4366828 },
    { lat: 49.2543178, lng: 19.4367128 },
    { lat: 49.2542821, lng: 19.4367188 },
    { lat: 49.2542339, lng: 19.4367265 },
    { lat: 49.2540571, lng: 19.436755 },
    { lat: 49.2540114, lng: 19.4367624 },
    { lat: 49.2540874, lng: 19.4373339 },
    { lat: 49.2541522, lng: 19.4372995 },
    { lat: 49.254216, lng: 19.4372659 },
    { lat: 49.2543652, lng: 19.4379085 },
    { lat: 49.2543836, lng: 19.4379892 },
    { lat: 49.2544369, lng: 19.4382186 },
    { lat: 49.2549551, lng: 19.4394585 },
    { lat: 49.2546894, lng: 19.4399027 },
    { lat: 49.2546358, lng: 19.4399842 },
    { lat: 49.2546039, lng: 19.4400313 },
    { lat: 49.2544652, lng: 19.4401978 },
    { lat: 49.2544344, lng: 19.4402346 },
    { lat: 49.254295, lng: 19.4404628 },
    { lat: 49.2538389, lng: 19.4411061 },
    { lat: 49.2532893, lng: 19.4418438 },
    { lat: 49.2529896, lng: 19.4417199 },
    { lat: 49.2528645, lng: 19.4417918 },
    { lat: 49.2526648, lng: 19.4419114 },
    { lat: 49.2523217, lng: 19.4418496 },
    { lat: 49.2520332, lng: 19.4418965 },
    { lat: 49.2515742, lng: 19.4419636 },
    { lat: 49.2512249, lng: 19.4424005 },
    { lat: 49.2510704, lng: 19.4425936 },
    { lat: 49.2508967, lng: 19.4428831 },
    { lat: 49.2506349, lng: 19.4433132 },
    { lat: 49.2506097, lng: 19.4433552 },
    { lat: 49.2498922, lng: 19.4443407 },
    { lat: 49.2495691, lng: 19.4444667 },
    { lat: 49.2492413, lng: 19.4445424 },
    { lat: 49.2488667, lng: 19.4446256 },
    { lat: 49.2483045, lng: 19.4449945 },
    { lat: 49.2482443, lng: 19.4450353 },
    { lat: 49.2477009, lng: 19.4451973 },
    { lat: 49.2472857, lng: 19.445308 },
    { lat: 49.2471073, lng: 19.4453524 },
    { lat: 49.246401, lng: 19.4455507 },
    { lat: 49.2462382, lng: 19.4455372 },
    { lat: 49.2461783, lng: 19.4455339 },
    { lat: 49.2458794, lng: 19.4454063 },
    { lat: 49.245552, lng: 19.4452699 },
    { lat: 49.2447675, lng: 19.44517 },
    { lat: 49.2446473, lng: 19.4451491 },
    { lat: 49.244385, lng: 19.4450593 },
    { lat: 49.2440493, lng: 19.4449613 },
    { lat: 49.2436153, lng: 19.4448392 },
    { lat: 49.2435024, lng: 19.4448085 },
    { lat: 49.2430142, lng: 19.4446562 },
    { lat: 49.2427415, lng: 19.4445802 },
    { lat: 49.2424607, lng: 19.4445019 },
    { lat: 49.2423699, lng: 19.4445412 },
    { lat: 49.2423535, lng: 19.4445488 },
    { lat: 49.2422275, lng: 19.444602 },
    { lat: 49.2420931, lng: 19.4445494 },
    { lat: 49.241869, lng: 19.4445028 },
    { lat: 49.2417393, lng: 19.4444764 },
    { lat: 49.2414167, lng: 19.4449031 },
    { lat: 49.240618, lng: 19.4450089 },
    { lat: 49.2401626, lng: 19.4449793 },
    { lat: 49.2398381, lng: 19.4448343 },
    { lat: 49.2393887, lng: 19.4443262 },
    { lat: 49.2393596, lng: 19.4443315 },
    { lat: 49.2391134, lng: 19.4443692 },
    { lat: 49.2384012, lng: 19.4444127 },
    { lat: 49.2378296, lng: 19.4441882 },
    { lat: 49.2375149, lng: 19.4445931 },
    { lat: 49.2374082, lng: 19.4448004 },
    { lat: 49.2373836, lng: 19.4448496 },
    { lat: 49.2372682, lng: 19.4451581 },
    { lat: 49.2371393, lng: 19.4452192 },
    { lat: 49.2372276, lng: 19.4456702 },
    { lat: 49.2374756, lng: 19.4461638 },
    { lat: 49.2376758, lng: 19.4465916 },
    { lat: 49.2378568, lng: 19.4468765 },
    { lat: 49.2380039, lng: 19.4471322 },
    { lat: 49.2380613, lng: 19.4472825 },
    { lat: 49.2385435, lng: 19.4482097 },
    { lat: 49.2388333, lng: 19.4489229 },
    { lat: 49.2389107, lng: 19.4493058 },
    { lat: 49.2391024, lng: 19.449966 },
    { lat: 49.2396924, lng: 19.4511071 },
    { lat: 49.2398263, lng: 19.4516408 },
    { lat: 49.239881, lng: 19.4518481 },
    { lat: 49.2398994, lng: 19.4519056 },
    { lat: 49.2402365, lng: 19.4531077 },
    { lat: 49.2407132, lng: 19.4539689 },
    { lat: 49.2410932, lng: 19.4546576 },
    { lat: 49.2413578, lng: 19.4548488 },
    { lat: 49.2415131, lng: 19.4553103 },
    { lat: 49.2417404, lng: 19.455816 },
    { lat: 49.241668, lng: 19.4559814 },
    { lat: 49.2416222, lng: 19.4560639 },
    { lat: 49.2416181, lng: 19.4561707 },
    { lat: 49.2415498, lng: 19.456547 },
    { lat: 49.2415062, lng: 19.4566259 },
    { lat: 49.2414453, lng: 19.4566274 },
    { lat: 49.2408787, lng: 19.4563871 },
    { lat: 49.2408269, lng: 19.4564367 },
    { lat: 49.2407754, lng: 19.4565389 },
    { lat: 49.2407508, lng: 19.4566407 },
    { lat: 49.2407585, lng: 19.4567874 },
    { lat: 49.2408009, lng: 19.4570471 },
    { lat: 49.2408235, lng: 19.4573635 },
    { lat: 49.2407756, lng: 19.4576211 },
    { lat: 49.2407086, lng: 19.4578068 },
    { lat: 49.2405814, lng: 19.4579319 },
    { lat: 49.2405169, lng: 19.4579958 },
    { lat: 49.2403809, lng: 19.4580537 },
    { lat: 49.2402207, lng: 19.4581221 },
    { lat: 49.2398513, lng: 19.4585209 },
    { lat: 49.2397619, lng: 19.4587076 },
    { lat: 49.2394441, lng: 19.459394 },
    { lat: 49.2395139, lng: 19.4595153 },
    { lat: 49.2395432, lng: 19.4594133 },
    { lat: 49.239626, lng: 19.4598177 },
    { lat: 49.2396569, lng: 19.4602226 },
    { lat: 49.2396572, lng: 19.4602291 },
    { lat: 49.2396591, lng: 19.460244 },
    { lat: 49.2396788, lng: 19.4603036 },
    { lat: 49.23968, lng: 19.4603085 },
    { lat: 49.2397523, lng: 19.4605308 },
    { lat: 49.2398413, lng: 19.4606064 },
    { lat: 49.2398784, lng: 19.4606543 },
    { lat: 49.2400776, lng: 19.4605244 },
    { lat: 49.2401321, lng: 19.4605589 },
    { lat: 49.2402899, lng: 19.461075 },
    { lat: 49.2404339, lng: 19.4613394 },
    { lat: 49.2404944, lng: 19.4615006 },
    { lat: 49.2405624, lng: 19.4616801 },
    { lat: 49.24057, lng: 19.461702 },
    { lat: 49.2404447, lng: 19.4617706 },
    { lat: 49.240387, lng: 19.4618136 },
    { lat: 49.2404484, lng: 19.4621431 },
    { lat: 49.2404606, lng: 19.4622392 },
    { lat: 49.2404763, lng: 19.4623586 },
    { lat: 49.2405415, lng: 19.4628439 },
    { lat: 49.2405622, lng: 19.4629538 },
    { lat: 49.2405761, lng: 19.4630825 },
    { lat: 49.2406217, lng: 19.4632428 },
    { lat: 49.2406829, lng: 19.4634216 },
    { lat: 49.2407467, lng: 19.463413 },
    { lat: 49.2408405, lng: 19.4635169 },
    { lat: 49.2409963, lng: 19.4638665 },
    { lat: 49.2408931, lng: 19.4640449 },
    { lat: 49.2408507, lng: 19.4640787 },
    { lat: 49.2409397, lng: 19.4644211 },
    { lat: 49.2409987, lng: 19.4644058 },
    { lat: 49.2410916, lng: 19.4644438 },
    { lat: 49.2411635, lng: 19.4652936 },
    { lat: 49.2410781, lng: 19.4653738 },
    { lat: 49.2410287, lng: 19.4653561 },
    { lat: 49.2410524, lng: 19.4655028 },
    { lat: 49.2410859, lng: 19.4656922 },
    { lat: 49.2411991, lng: 19.4658477 },
    { lat: 49.2413327, lng: 19.4660123 },
    { lat: 49.2414215, lng: 19.4661553 },
    { lat: 49.2414831, lng: 19.4662681 },
    { lat: 49.2415577, lng: 19.466422 },
    { lat: 49.2416012, lng: 19.466532 },
    { lat: 49.2416632, lng: 19.4667302 },
    { lat: 49.2416676, lng: 19.466826 },
    { lat: 49.2416012, lng: 19.4669734 },
    { lat: 49.2415101, lng: 19.4671018 },
    { lat: 49.2414592, lng: 19.4673146 },
    { lat: 49.2414464, lng: 19.4674321 },
    { lat: 49.2414261, lng: 19.4676027 },
    { lat: 49.241484, lng: 19.4676337 },
    { lat: 49.2417444, lng: 19.4681279 },
    { lat: 49.2419848, lng: 19.4687034 },
    { lat: 49.2421224, lng: 19.4687279 },
    { lat: 49.2422578, lng: 19.4689558 },
    { lat: 49.2422181, lng: 19.4690956 },
    { lat: 49.2422034, lng: 19.4691444 },
    { lat: 49.2421936, lng: 19.469179 },
    { lat: 49.2421822, lng: 19.4692221 },
    { lat: 49.2418585, lng: 19.4692903 },
    { lat: 49.241788, lng: 19.4692766 },
    { lat: 49.2416805, lng: 19.4697544 },
    { lat: 49.2416932, lng: 19.4699113 },
    { lat: 49.2418212, lng: 19.4702665 },
    { lat: 49.2419563, lng: 19.4703148 },
    { lat: 49.2419897, lng: 19.4704292 },
    { lat: 49.2419149, lng: 19.4707401 },
    { lat: 49.2419074, lng: 19.4708697 },
    { lat: 49.2419818, lng: 19.471117 },
    { lat: 49.2420197, lng: 19.4722623 },
    { lat: 49.2420509, lng: 19.472304 },
    { lat: 49.2421374, lng: 19.4725674 },
    { lat: 49.2421947, lng: 19.4726583 },
    { lat: 49.2422751, lng: 19.4728412 },
    { lat: 49.2423175, lng: 19.4730548 },
    { lat: 49.2423122, lng: 19.4732335 },
    { lat: 49.2422843, lng: 19.4733869 },
    { lat: 49.2422583, lng: 19.4736071 },
    { lat: 49.2422623, lng: 19.4738628 },
    { lat: 49.2422095, lng: 19.4740148 },
    { lat: 49.2422086, lng: 19.4740405 },
    { lat: 49.2422207, lng: 19.4741348 },
    { lat: 49.2423132, lng: 19.474408 },
    { lat: 49.2423449, lng: 19.4747523 },
    { lat: 49.2423747, lng: 19.4751346 },
    { lat: 49.242366, lng: 19.4755582 },
    { lat: 49.242321, lng: 19.4755861 },
    { lat: 49.242241, lng: 19.4756336 },
    { lat: 49.2422389, lng: 19.4756857 },
    { lat: 49.2422296, lng: 19.4760232 },
    { lat: 49.2422283, lng: 19.4760919 },
    { lat: 49.242218, lng: 19.4765578 },
    { lat: 49.2424531, lng: 19.4769932 },
    { lat: 49.2425227, lng: 19.4770077 },
    { lat: 49.2424639, lng: 19.4773739 },
    { lat: 49.2424808, lng: 19.4775723 },
    { lat: 49.2425125, lng: 19.478041 },
    { lat: 49.2424379, lng: 19.4782794 },
    { lat: 49.2424329, lng: 19.4785417 },
    { lat: 49.2424959, lng: 19.4789057 },
    { lat: 49.2425888, lng: 19.4791136 },
    { lat: 49.2425786, lng: 19.4792889 },
    { lat: 49.2425587, lng: 19.4793682 },
    { lat: 49.2425334, lng: 19.4794041 },
    { lat: 49.2423756, lng: 19.4794033 },
    { lat: 49.2423249, lng: 19.4794296 },
    { lat: 49.2423073, lng: 19.4794383 },
    { lat: 49.2423029, lng: 19.4795164 },
    { lat: 49.2423757, lng: 19.4802194 },
    { lat: 49.2423987, lng: 19.4803504 },
    { lat: 49.2424361, lng: 19.480527 },
    { lat: 49.2424799, lng: 19.48087 },
    { lat: 49.242532, lng: 19.4812189 },
    { lat: 49.2425892, lng: 19.4815078 },
    { lat: 49.2427304, lng: 19.4820802 },
    { lat: 49.242926, lng: 19.4824181 },
    { lat: 49.2429822, lng: 19.4827104 },
    { lat: 49.2430149, lng: 19.4830285 },
    { lat: 49.2430496, lng: 19.4832694 },
    { lat: 49.2430944, lng: 19.4834864 },
    { lat: 49.2430952, lng: 19.4836734 },
    { lat: 49.2431212, lng: 19.4837251 },
    { lat: 49.2433031, lng: 19.4838874 },
    { lat: 49.2433899, lng: 19.4842045 },
    { lat: 49.2435697, lng: 19.4846877 },
    { lat: 49.2436187, lng: 19.4850911 },
    { lat: 49.2437347, lng: 19.485237 },
    { lat: 49.2438414, lng: 19.4854997 },
    { lat: 49.2438637, lng: 19.4858695 },
    { lat: 49.2438741, lng: 19.4860952 },
    { lat: 49.2439177, lng: 19.4862337 },
    { lat: 49.2442911, lng: 19.4863439 },
    { lat: 49.2443901, lng: 19.4864859 },
    { lat: 49.244648, lng: 19.4871976 },
    { lat: 49.2448589, lng: 19.4873241 },
    { lat: 49.2447493, lng: 19.4876117 },
    { lat: 49.2452505, lng: 19.4878322 },
    { lat: 49.2454712, lng: 19.488196 },
    { lat: 49.2455197, lng: 19.4882244 },
    { lat: 49.2455598, lng: 19.4883369 },
    { lat: 49.2455999, lng: 19.4884499 },
    { lat: 49.2455716, lng: 19.4887183 },
    { lat: 49.2455726, lng: 19.4887619 },
    { lat: 49.2455994, lng: 19.4888055 },
    { lat: 49.245785, lng: 19.4891467 },
    { lat: 49.2460952, lng: 19.4894696 },
    { lat: 49.246316, lng: 19.4899664 },
    { lat: 49.2463543, lng: 19.4900406 },
    { lat: 49.2465747, lng: 19.4900532 },
    { lat: 49.2467753, lng: 19.4902514 },
    { lat: 49.2468374, lng: 19.4905715 },
    { lat: 49.2470866, lng: 19.490849 },
    { lat: 49.2471554, lng: 19.4911965 },
    { lat: 49.2473079, lng: 19.4912513 },
    { lat: 49.2472894, lng: 19.491958 },
    { lat: 49.2475295, lng: 19.4921585 },
    { lat: 49.2475953, lng: 19.4926835 },
    { lat: 49.2476413, lng: 19.4931016 },
    { lat: 49.2475526, lng: 19.4932535 },
    { lat: 49.2475806, lng: 19.4934212 },
    { lat: 49.2477972, lng: 19.4936509 },
    { lat: 49.2478358, lng: 19.4938538 },
    { lat: 49.2477411, lng: 19.4941986 },
    { lat: 49.2477797, lng: 19.4944754 },
    { lat: 49.2477551, lng: 19.4946252 },
    { lat: 49.2477416, lng: 19.4948968 },
    { lat: 49.2477255, lng: 19.4951387 },
    { lat: 49.2478384, lng: 19.495219 },
    { lat: 49.2478893, lng: 19.4952464 },
    { lat: 49.2478921, lng: 19.4953799 },
    { lat: 49.2477875, lng: 19.4955559 },
    { lat: 49.24776, lng: 19.4957179 },
    { lat: 49.2479395, lng: 19.4962206 },
    { lat: 49.2478906, lng: 19.4964595 },
    { lat: 49.2478881, lng: 19.4965269 },
    { lat: 49.2480374, lng: 19.4969845 },
    { lat: 49.2481027, lng: 19.4970811 },
    { lat: 49.2482729, lng: 19.4976014 },
    { lat: 49.2482811, lng: 19.4977812 },
    { lat: 49.2483752, lng: 19.4981153 },
    { lat: 49.2484087, lng: 19.4984031 },
    { lat: 49.2484396, lng: 19.4986617 },
    { lat: 49.2485137, lng: 19.4990476 },
    { lat: 49.2485546, lng: 19.4992777 },
    { lat: 49.2485901, lng: 19.4997074 },
    { lat: 49.2485802, lng: 19.4998383 },
    { lat: 49.248546, lng: 19.4999531 },
    { lat: 49.2485694, lng: 19.5002171 },
    { lat: 49.2485424, lng: 19.5008876 },
    { lat: 49.2486301, lng: 19.5011076 },
    { lat: 49.2487079, lng: 19.5012029 },
    { lat: 49.2487123, lng: 19.501501 },
    { lat: 49.2485397, lng: 19.5019403 },
    { lat: 49.2484105, lng: 19.5024673 },
    { lat: 49.2483043, lng: 19.5027787 },
    { lat: 49.2481219, lng: 19.5029059 },
    { lat: 49.2478294, lng: 19.5032382 },
    { lat: 49.2476272, lng: 19.5035205 },
    { lat: 49.2475406, lng: 19.5036253 },
    { lat: 49.2473742, lng: 19.503854 },
    { lat: 49.2471074, lng: 19.5041792 },
    { lat: 49.2470937, lng: 19.5042979 },
    { lat: 49.2470658, lng: 19.5045043 },
    { lat: 49.2470085, lng: 19.5047023 },
    { lat: 49.2469244, lng: 19.5050859 },
    { lat: 49.2468542, lng: 19.5052488 },
    { lat: 49.2469017, lng: 19.5054512 },
    { lat: 49.2468593, lng: 19.5056093 },
    { lat: 49.2469153, lng: 19.5059204 },
    { lat: 49.246897, lng: 19.5059642 },
    { lat: 49.2469088, lng: 19.5060989 },
    { lat: 49.2469328, lng: 19.5063553 },
    { lat: 49.2469086, lng: 19.5066651 },
    { lat: 49.2468273, lng: 19.5068076 },
    { lat: 49.2467735, lng: 19.5071859 },
    { lat: 49.2465724, lng: 19.507965 },
    { lat: 49.2465658, lng: 19.5080414 },
    { lat: 49.2465608, lng: 19.5082547 },
    { lat: 49.2464599, lng: 19.5085104 },
    { lat: 49.2464031, lng: 19.5087721 },
    { lat: 49.2463315, lng: 19.5090358 },
    { lat: 49.2462925, lng: 19.5092163 },
    { lat: 49.2462818, lng: 19.5095968 },
    { lat: 49.246212, lng: 19.5099205 },
    { lat: 49.2460869, lng: 19.5105898 },
    { lat: 49.2461168, lng: 19.5107738 },
    { lat: 49.246428, lng: 19.5112511 },
    { lat: 49.2464258, lng: 19.511348 },
    { lat: 49.2463526, lng: 19.5115491 },
    { lat: 49.2462195, lng: 19.5120645 },
    { lat: 49.2468303, lng: 19.5120908 },
    { lat: 49.2474427, lng: 19.5121051 },
    { lat: 49.2476578, lng: 19.512845 },
    { lat: 49.2480742, lng: 19.5131546 },
    { lat: 49.2489502, lng: 19.5135873 },
    { lat: 49.2495884, lng: 19.5138455 },
    { lat: 49.2501447, lng: 19.5141114 },
    { lat: 49.2506007, lng: 19.5142267 },
    { lat: 49.2510561, lng: 19.5143565 },
    { lat: 49.2512889, lng: 19.5138012 },
    { lat: 49.251472, lng: 19.513144 },
    { lat: 49.2515652, lng: 19.5127709 },
    { lat: 49.2517649, lng: 19.5122761 },
    { lat: 49.2519402, lng: 19.5116526 },
    { lat: 49.2519464, lng: 19.5113414 },
    { lat: 49.2519588, lng: 19.5108935 },
    { lat: 49.2520604, lng: 19.5106046 },
    { lat: 49.2524633, lng: 19.5090375 },
    { lat: 49.2529218, lng: 19.5081194 },
    { lat: 49.2534681, lng: 19.5065341 },
    { lat: 49.2537947, lng: 19.5055666 },
    { lat: 49.2539106, lng: 19.5053385 },
    { lat: 49.2541584, lng: 19.50455 },
    { lat: 49.2543812, lng: 19.5040721 },
    { lat: 49.2543757, lng: 19.5039245 },
    { lat: 49.2543656, lng: 19.5036561 },
    { lat: 49.2543684, lng: 19.5030776 },
    { lat: 49.2543721, lng: 19.5020445 },
    { lat: 49.2545402, lng: 19.501435 },
    { lat: 49.2547748, lng: 19.5008945 },
    { lat: 49.2548774, lng: 19.5004006 },
    { lat: 49.254757, lng: 19.4996415 },
    { lat: 49.2548078, lng: 19.499275 },
    { lat: 49.2552345, lng: 19.4981795 },
    { lat: 49.2551354, lng: 19.4964016 },
    { lat: 49.2550408, lng: 19.4953714 },
    { lat: 49.2551445, lng: 19.4951742 },
    { lat: 49.2551765, lng: 19.4948166 },
    { lat: 49.255319, lng: 19.4940843 },
    { lat: 49.2554656, lng: 19.4935478 },
    { lat: 49.2555401, lng: 19.4932758 },
    { lat: 49.2557831, lng: 19.4921092 },
    { lat: 49.2560019, lng: 19.4916238 },
    { lat: 49.2561421, lng: 19.4914606 },
    { lat: 49.2562989, lng: 19.4913183 },
    { lat: 49.2565234, lng: 19.4909033 },
    { lat: 49.2571347, lng: 19.4904488 },
    { lat: 49.2571743, lng: 19.4905251 },
    { lat: 49.257966, lng: 19.4901491 },
    { lat: 49.2581427, lng: 19.4900223 },
    { lat: 49.2583713, lng: 19.4896451 },
    { lat: 49.2591389, lng: 19.4888669 },
    { lat: 49.2596369, lng: 19.4883971 },
    { lat: 49.2600782, lng: 19.4879686 },
    { lat: 49.2603395, lng: 19.4877196 },
    { lat: 49.2608323, lng: 19.4874588 },
    { lat: 49.261614, lng: 19.4869355 },
    { lat: 49.2621157, lng: 19.4867239 },
    { lat: 49.2623425, lng: 19.4865297 },
    { lat: 49.2626221, lng: 19.4862885 },
    { lat: 49.2626445, lng: 19.4862871 },
    { lat: 49.2627534, lng: 19.4862784 },
    { lat: 49.2628508, lng: 19.4861875 },
    { lat: 49.2628911, lng: 19.4860624 },
    { lat: 49.2633226, lng: 19.4851995 },
    { lat: 49.2634231, lng: 19.4849091 },
    { lat: 49.2633181, lng: 19.4844611 },
    { lat: 49.2631084, lng: 19.483815 },
    { lat: 49.2628471, lng: 19.4830791 },
    { lat: 49.2627936, lng: 19.4829676 },
    { lat: 49.2629893, lng: 19.4822973 },
    { lat: 49.2630125, lng: 19.4822154 },
    { lat: 49.2633828, lng: 19.4809484 },
    { lat: 49.2637826, lng: 19.4802815 },
    { lat: 49.2641409, lng: 19.4798409 },
    { lat: 49.2647213, lng: 19.4794238 },
    { lat: 49.2657601, lng: 19.4789775 },
    { lat: 49.2664263, lng: 19.4787388 },
    { lat: 49.2666165, lng: 19.478809 },
    { lat: 49.2669257, lng: 19.4786215 },
    { lat: 49.2672167, lng: 19.4783612 },
    { lat: 49.2674317, lng: 19.4782289 },
    { lat: 49.2679124, lng: 19.4780227 },
    { lat: 49.268679, lng: 19.4775174 },
    { lat: 49.2690652, lng: 19.4772625 },
    { lat: 49.2696207, lng: 19.476674 },
    { lat: 49.2697773, lng: 19.4764271 },
    { lat: 49.2701701, lng: 19.4764204 },
    { lat: 49.2706804, lng: 19.4769831 },
    { lat: 49.2711672, lng: 19.4770082 },
    { lat: 49.2714249, lng: 19.4769283 },
    { lat: 49.2720261, lng: 19.4765479 },
    { lat: 49.2722992, lng: 19.4762888 },
    { lat: 49.2729547, lng: 19.475393 },
    { lat: 49.2734695, lng: 19.4747312 },
    { lat: 49.2739072, lng: 19.4741539 },
    { lat: 49.2739854, lng: 19.4739629 },
    { lat: 49.2743735, lng: 19.4730138 },
    { lat: 49.2749952, lng: 19.4701371 },
    { lat: 49.2752668, lng: 19.469156 },
    { lat: 49.2753294, lng: 19.4689985 },
    { lat: 49.2755347, lng: 19.4684868 },
    { lat: 49.2760019, lng: 19.4678156 },
    { lat: 49.2761081, lng: 19.4676508 },
    { lat: 49.2763243, lng: 19.4673296 },
    { lat: 49.2768614, lng: 19.4663618 },
    { lat: 49.2771702, lng: 19.4659707 },
    { lat: 49.2776947, lng: 19.4652464 },
    { lat: 49.2777368, lng: 19.465208 },
    { lat: 49.2778194, lng: 19.4651392 },
    { lat: 49.2778541, lng: 19.4651128 },
    { lat: 49.2781126, lng: 19.4653914 },
    { lat: 49.2786719, lng: 19.4648586 },
    { lat: 49.2787881, lng: 19.464814 },
    { lat: 49.2789873, lng: 19.4645646 },
    { lat: 49.2790871, lng: 19.4646051 },
    { lat: 49.2792338, lng: 19.4643855 },
    { lat: 49.2794285, lng: 19.4642852 },
    { lat: 49.2798192, lng: 19.4640068 },
    { lat: 49.2800477, lng: 19.4638044 },
    { lat: 49.2800548, lng: 19.4637903 },
    { lat: 49.2800714, lng: 19.4637618 },
    { lat: 49.2801188, lng: 19.4636618 },
    { lat: 49.2801392, lng: 19.4636184 },
    { lat: 49.2801496, lng: 19.4635975 },
    { lat: 49.2803324, lng: 19.4632325 },
    { lat: 49.2803704, lng: 19.4631559 },
    { lat: 49.2806607, lng: 19.4625068 },
    { lat: 49.2808661, lng: 19.4620481 },
    { lat: 49.2809173, lng: 19.4619339 },
    { lat: 49.2809641, lng: 19.4618278 },
    { lat: 49.2809999, lng: 19.4617493 },
    { lat: 49.2810295, lng: 19.461683 },
    { lat: 49.2810817, lng: 19.4615662 },
    { lat: 49.2816324, lng: 19.4603351 },
    { lat: 49.2821431, lng: 19.4599952 },
    { lat: 49.2826538, lng: 19.4598569 },
    { lat: 49.2832437, lng: 19.4597716 },
    { lat: 49.2846019, lng: 19.4602443 },
    { lat: 49.2854719, lng: 19.4603252 },
    { lat: 49.2855311, lng: 19.4603589 },
    { lat: 49.2855292, lng: 19.4604923 },
    { lat: 49.2855844, lng: 19.46059 },
    { lat: 49.2860135, lng: 19.4610019 },
    { lat: 49.2862644, lng: 19.4611656 },
    { lat: 49.286596, lng: 19.4615873 },
    { lat: 49.2868295, lng: 19.4618658 },
    { lat: 49.2870606, lng: 19.462085 },
    { lat: 49.2869934, lng: 19.4622665 },
    { lat: 49.2870248, lng: 19.4624104 },
    { lat: 49.2871992, lng: 19.4624047 },
    { lat: 49.2874321, lng: 19.4628901 },
    { lat: 49.2875259, lng: 19.4627721 },
    { lat: 49.2877877, lng: 19.4627306 },
    { lat: 49.2882174, lng: 19.4624726 },
    { lat: 49.2889179, lng: 19.4620443 },
    { lat: 49.2898365, lng: 19.4617594 },
    { lat: 49.2904513, lng: 19.4620421 },
    { lat: 49.2907823, lng: 19.4624777 },
    { lat: 49.2906977, lng: 19.4631914 },
    { lat: 49.2908138, lng: 19.4634814 },
    { lat: 49.2908307, lng: 19.463654 },
    { lat: 49.2909245, lng: 19.4636876 },
    { lat: 49.2913308, lng: 19.4647302 },
    { lat: 49.2917566, lng: 19.4643859 },
    { lat: 49.2920256, lng: 19.4644509 },
    { lat: 49.2921886, lng: 19.4640771 },
    { lat: 49.292281, lng: 19.4635596 },
    { lat: 49.2917439, lng: 19.4621715 },
    { lat: 49.2919535, lng: 19.4618263 },
    { lat: 49.2923121, lng: 19.461316 },
    { lat: 49.292598, lng: 19.462272 },
    { lat: 49.292904, lng: 19.461765 },
    { lat: 49.293067, lng: 19.461647 },
    { lat: 49.293244, lng: 19.461517 },
    { lat: 49.293329, lng: 19.460911 },
    { lat: 49.293585, lng: 19.460379 },
    { lat: 49.293351, lng: 19.459772 },
    { lat: 49.293556, lng: 19.459523 },
    { lat: 49.293731, lng: 19.459378 },
    { lat: 49.293743, lng: 19.459145 },
    { lat: 49.293648, lng: 19.458925 },
    { lat: 49.293729, lng: 19.458792 },
    { lat: 49.293855, lng: 19.458504 },
    { lat: 49.293237, lng: 19.457612 },
    { lat: 49.293361, lng: 19.457367 },
    { lat: 49.293215, lng: 19.457191 },
    { lat: 49.293648, lng: 19.456362 },
    { lat: 49.293669, lng: 19.455961 },
    { lat: 49.294239, lng: 19.45545 },
    { lat: 49.294694, lng: 19.455113 },
    { lat: 49.294913, lng: 19.45488 },
    { lat: 49.295067, lng: 19.454541 },
    { lat: 49.295675, lng: 19.453716 },
    { lat: 49.296259, lng: 19.453292 },
    { lat: 49.296899, lng: 19.4534 },
    { lat: 49.297532, lng: 19.453074 },
    { lat: 49.297531, lng: 19.45293 },
    { lat: 49.298558, lng: 19.452579 },
    { lat: 49.298535, lng: 19.452142 },
    { lat: 49.299162, lng: 19.452074 },
    { lat: 49.299835, lng: 19.451876 },
    { lat: 49.299628, lng: 19.449702 },
    { lat: 49.300679, lng: 19.449299 },
    { lat: 49.301419, lng: 19.44918 },
    { lat: 49.301676, lng: 19.448412 },
    { lat: 49.30169, lng: 19.447985 },
    { lat: 49.301931, lng: 19.447832 },
    { lat: 49.301988, lng: 19.447657 },
    { lat: 49.301994, lng: 19.447552 },
    { lat: 49.302146, lng: 19.446965 },
    { lat: 49.302294, lng: 19.446835 },
    { lat: 49.302682, lng: 19.446542 },
    { lat: 49.303186, lng: 19.446071 },
    { lat: 49.303312, lng: 19.445467 },
    { lat: 49.303289, lng: 19.444612 },
    { lat: 49.303369, lng: 19.444013 },
    { lat: 49.303307, lng: 19.443713 },
    { lat: 49.303595, lng: 19.442992 },
    { lat: 49.303726, lng: 19.442675 },
    { lat: 49.304066, lng: 19.442177 },
    { lat: 49.304224, lng: 19.441576 },
    { lat: 49.304259, lng: 19.44117 },
    { lat: 49.304301, lng: 19.440801 },
    { lat: 49.304331, lng: 19.440621 },
    { lat: 49.304333, lng: 19.439902 },
    { lat: 49.304381, lng: 19.439772 },
    { lat: 49.304739, lng: 19.439256 },
    { lat: 49.305045, lng: 19.438819 },
    { lat: 49.305248, lng: 19.438634 },
    { lat: 49.305712, lng: 19.438403 },
    { lat: 49.305873, lng: 19.438397 },
    { lat: 49.306083, lng: 19.438207 },
    { lat: 49.306495, lng: 19.437672 },
    { lat: 49.306786, lng: 19.43705 },
    { lat: 49.30685, lng: 19.43661 },
    { lat: 49.306982, lng: 19.436155 },
    { lat: 49.307322, lng: 19.435784 },
    { lat: 49.307385, lng: 19.435535 },
    { lat: 49.307441, lng: 19.43528 },
    { lat: 49.3076, lng: 19.435035 },
    { lat: 49.307923, lng: 19.434876 },
    { lat: 49.308574, lng: 19.434926 },
    { lat: 49.309234, lng: 19.434719 },
    { lat: 49.30998, lng: 19.434524 },
    { lat: 49.310233, lng: 19.434045 },
    { lat: 49.310351, lng: 19.433612 },
    { lat: 49.310406, lng: 19.433064 },
    { lat: 49.310585, lng: 19.432823 },
    { lat: 49.310743, lng: 19.432642 },
    { lat: 49.310979, lng: 19.432399 },
    { lat: 49.311194, lng: 19.432214 },
    { lat: 49.311744, lng: 19.431787 },
    { lat: 49.312135, lng: 19.431469 },
    { lat: 49.312794, lng: 19.430863 },
    { lat: 49.313667, lng: 19.430002 },
    { lat: 49.314208, lng: 19.429098 },
    { lat: 49.315393, lng: 19.427839 },
    { lat: 49.316299, lng: 19.426732 },
    { lat: 49.316366, lng: 19.426514 },
    { lat: 49.316388, lng: 19.426408 },
    { lat: 49.316744, lng: 19.425038 },
    { lat: 49.316942, lng: 19.424296 },
    { lat: 49.316983, lng: 19.423322 },
    { lat: 49.317273, lng: 19.422538 },
    { lat: 49.317575, lng: 19.421937 },
    { lat: 49.317726, lng: 19.421744 },
    { lat: 49.318878, lng: 19.420513 },
    { lat: 49.319135, lng: 19.420215 },
    { lat: 49.318727, lng: 19.419782 },
    { lat: 49.31844, lng: 19.41883 },
    { lat: 49.318138, lng: 19.418236 },
    { lat: 49.317808, lng: 19.4177 },
    { lat: 49.317512, lng: 19.417257 },
    { lat: 49.3172, lng: 19.416878 },
    { lat: 49.316847, lng: 19.416559 },
    { lat: 49.31658, lng: 19.416259 },
    { lat: 49.316375, lng: 19.416014 },
    { lat: 49.315393, lng: 19.415274 },
    { lat: 49.3152423, lng: 19.4150276 },
    { lat: 49.314757, lng: 19.414234 },
    { lat: 49.314186, lng: 19.413373 },
    { lat: 49.313775, lng: 19.412717 },
    { lat: 49.312627, lng: 19.411196 },
    { lat: 49.311596, lng: 19.410252 },
    { lat: 49.311138, lng: 19.409648 },
    { lat: 49.310742, lng: 19.40878 },
    { lat: 49.310476, lng: 19.408285 },
    { lat: 49.310422, lng: 19.408181 },
    { lat: 49.309974, lng: 19.407326 },
    { lat: 49.309596, lng: 19.406912 },
    { lat: 49.308921, lng: 19.405882 },
  ],
  HornáLehota: [
    { lat: 49.2527522, lng: 19.3843234 },
    { lat: 49.252864, lng: 19.3844988 },
    { lat: 49.2527248, lng: 19.3846074 },
    { lat: 49.2525524, lng: 19.3847751 },
    { lat: 49.2522396, lng: 19.3849318 },
    { lat: 49.2520707, lng: 19.3850271 },
    { lat: 49.2519328, lng: 19.3850684 },
    { lat: 49.2516212, lng: 19.3850338 },
    { lat: 49.2512533, lng: 19.3853592 },
    { lat: 49.2510302, lng: 19.3852637 },
    { lat: 49.2508454, lng: 19.3853109 },
    { lat: 49.2507183, lng: 19.3854362 },
    { lat: 49.2505888, lng: 19.385631 },
    { lat: 49.2504602, lng: 19.3856547 },
    { lat: 49.2502787, lng: 19.3855294 },
    { lat: 49.2500914, lng: 19.3855033 },
    { lat: 49.2496668, lng: 19.3856491 },
    { lat: 49.249421, lng: 19.3859381 },
    { lat: 49.2492887, lng: 19.386542 },
    { lat: 49.2492201, lng: 19.3867791 },
    { lat: 49.2489053, lng: 19.3870124 },
    { lat: 49.2487829, lng: 19.3870211 },
    { lat: 49.2486248, lng: 19.3871588 },
    { lat: 49.2482299, lng: 19.3873655 },
    { lat: 49.2480645, lng: 19.3874895 },
    { lat: 49.2479746, lng: 19.3875415 },
    { lat: 49.2478147, lng: 19.3876411 },
    { lat: 49.247699, lng: 19.3877937 },
    { lat: 49.2474093, lng: 19.3882508 },
    { lat: 49.2472353, lng: 19.3884591 },
    { lat: 49.2470444, lng: 19.3889325 },
    { lat: 49.2469768, lng: 19.3892955 },
    { lat: 49.246876, lng: 19.3896004 },
    { lat: 49.2467238, lng: 19.389674 },
    { lat: 49.2463465, lng: 19.3896739 },
    { lat: 49.2460662, lng: 19.3896582 },
    { lat: 49.2457631, lng: 19.389707 },
    { lat: 49.2455169, lng: 19.3898142 },
    { lat: 49.2453158, lng: 19.3900439 },
    { lat: 49.2452543, lng: 19.3902954 },
    { lat: 49.2452917, lng: 19.3907649 },
    { lat: 49.2453474, lng: 19.3912303 },
    { lat: 49.2453354, lng: 19.3914837 },
    { lat: 49.2451895, lng: 19.3916417 },
    { lat: 49.2445356, lng: 19.3919699 },
    { lat: 49.244506, lng: 19.3920146 },
    { lat: 49.2444635, lng: 19.3921913 },
    { lat: 49.2443883, lng: 19.3925341 },
    { lat: 49.244338, lng: 19.3928112 },
    { lat: 49.2442507, lng: 19.3936693 },
    { lat: 49.2442718, lng: 19.3938798 },
    { lat: 49.2443114, lng: 19.3940512 },
    { lat: 49.2443416, lng: 19.3942181 },
    { lat: 49.2442384, lng: 19.3946639 },
    { lat: 49.2441441, lng: 19.3948401 },
    { lat: 49.2440661, lng: 19.3950051 },
    { lat: 49.2440311, lng: 19.3951964 },
    { lat: 49.2439722, lng: 19.3953107 },
    { lat: 49.2438789, lng: 19.3954121 },
    { lat: 49.2438089, lng: 19.3954085 },
    { lat: 49.2437665, lng: 19.395372 },
    { lat: 49.2434407, lng: 19.3955347 },
    { lat: 49.2432779, lng: 19.3956436 },
    { lat: 49.243138, lng: 19.3961277 },
    { lat: 49.2430137, lng: 19.3964113 },
    { lat: 49.2429447, lng: 19.3965962 },
    { lat: 49.2428406, lng: 19.3966126 },
    { lat: 49.242735, lng: 19.396623 },
    { lat: 49.242601, lng: 19.3968381 },
    { lat: 49.2424353, lng: 19.397058 },
    { lat: 49.24233, lng: 19.3972159 },
    { lat: 49.2420666, lng: 19.3970501 },
    { lat: 49.2419655, lng: 19.3971559 },
    { lat: 49.2420642, lng: 19.3974341 },
    { lat: 49.2419421, lng: 19.3976219 },
    { lat: 49.2415449, lng: 19.3981436 },
    { lat: 49.2414138, lng: 19.3984225 },
    { lat: 49.2414269, lng: 19.3986061 },
    { lat: 49.2414513, lng: 19.3986752 },
    { lat: 49.2415596, lng: 19.3989416 },
    { lat: 49.2415193, lng: 19.3991165 },
    { lat: 49.2412597, lng: 19.3990841 },
    { lat: 49.2410618, lng: 19.3992408 },
    { lat: 49.2409991, lng: 19.3995109 },
    { lat: 49.2408923, lng: 19.3996877 },
    { lat: 49.2409881, lng: 19.3999048 },
    { lat: 49.2409527, lng: 19.4000666 },
    { lat: 49.2408366, lng: 19.400063 },
    { lat: 49.2406003, lng: 19.3998066 },
    { lat: 49.2404999, lng: 19.3998262 },
    { lat: 49.2404004, lng: 19.4001331 },
    { lat: 49.2404053, lng: 19.4004376 },
    { lat: 49.2403728, lng: 19.4005612 },
    { lat: 49.2402587, lng: 19.4004081 },
    { lat: 49.2400639, lng: 19.4001436 },
    { lat: 49.2399018, lng: 19.4000754 },
    { lat: 49.2397694, lng: 19.400208 },
    { lat: 49.2396064, lng: 19.4000699 },
    { lat: 49.2392775, lng: 19.4001976 },
    { lat: 49.2391238, lng: 19.3999957 },
    { lat: 49.2390578, lng: 19.4000541 },
    { lat: 49.2390205, lng: 19.3999784 },
    { lat: 49.2389519, lng: 19.4000512 },
    { lat: 49.2388813, lng: 19.4004007 },
    { lat: 49.2388185, lng: 19.4005994 },
    { lat: 49.2387109, lng: 19.400611 },
    { lat: 49.2386533, lng: 19.4004637 },
    { lat: 49.2385775, lng: 19.400337 },
    { lat: 49.2386521, lng: 19.4001248 },
    { lat: 49.2386041, lng: 19.3999915 },
    { lat: 49.2384267, lng: 19.3999545 },
    { lat: 49.2382963, lng: 19.3999633 },
    { lat: 49.2381359, lng: 19.3999064 },
    { lat: 49.2377553, lng: 19.3998857 },
    { lat: 49.2376478, lng: 19.3998032 },
    { lat: 49.2375744, lng: 19.3996525 },
    { lat: 49.2375581, lng: 19.3995699 },
    { lat: 49.2375239, lng: 19.3994654 },
    { lat: 49.2374127, lng: 19.3994504 },
    { lat: 49.237293, lng: 19.3996124 },
    { lat: 49.2371376, lng: 19.3996624 },
    { lat: 49.2370426, lng: 19.3996572 },
    { lat: 49.2369859, lng: 19.3995505 },
    { lat: 49.2369436, lng: 19.3992944 },
    { lat: 49.2368767, lng: 19.3991163 },
    { lat: 49.2367284, lng: 19.3990808 },
    { lat: 49.2366871, lng: 19.3992075 },
    { lat: 49.2366318, lng: 19.3992777 },
    { lat: 49.2365621, lng: 19.3991843 },
    { lat: 49.2364535, lng: 19.39908 },
    { lat: 49.2363494, lng: 19.39907 },
    { lat: 49.2362109, lng: 19.3992196 },
    { lat: 49.2362768, lng: 19.3994984 },
    { lat: 49.2362221, lng: 19.3995818 },
    { lat: 49.2359014, lng: 19.3996166 },
    { lat: 49.2357574, lng: 19.3996427 },
    { lat: 49.2355949, lng: 19.3995922 },
    { lat: 49.2354992, lng: 19.3994721 },
    { lat: 49.2353837, lng: 19.3992891 },
    { lat: 49.235284, lng: 19.3994456 },
    { lat: 49.2351513, lng: 19.3996494 },
    { lat: 49.2348838, lng: 19.3997563 },
    { lat: 49.2345846, lng: 19.4000343 },
    { lat: 49.2344103, lng: 19.4000186 },
    { lat: 49.2343189, lng: 19.3997999 },
    { lat: 49.2343088, lng: 19.3998017 },
    { lat: 49.2341276, lng: 19.4000489 },
    { lat: 49.2340807, lng: 19.4001308 },
    { lat: 49.2339383, lng: 19.4004362 },
    { lat: 49.2339036, lng: 19.4012639 },
    { lat: 49.2340938, lng: 19.4022504 },
    { lat: 49.2341532, lng: 19.4026092 },
    { lat: 49.2343009, lng: 19.4029967 },
    { lat: 49.2344208, lng: 19.4035639 },
    { lat: 49.2343078, lng: 19.4045516 },
    { lat: 49.2341334, lng: 19.4049913 },
    { lat: 49.2340136, lng: 19.4054712 },
    { lat: 49.2340278, lng: 19.4060412 },
    { lat: 49.2337039, lng: 19.4070485 },
    { lat: 49.2335111, lng: 19.4074833 },
    { lat: 49.2331718, lng: 19.4083527 },
    { lat: 49.2329312, lng: 19.4086827 },
    { lat: 49.2317819, lng: 19.4079804 },
    { lat: 49.2315022, lng: 19.4088984 },
    { lat: 49.2311912, lng: 19.4104213 },
    { lat: 49.2313961, lng: 19.4109295 },
    { lat: 49.2314127, lng: 19.4109713 },
    { lat: 49.2317706, lng: 19.4118603 },
    { lat: 49.231935, lng: 19.4124411 },
    { lat: 49.232222, lng: 19.4134495 },
    { lat: 49.2327299, lng: 19.4152393 },
    { lat: 49.2328634, lng: 19.416154 },
    { lat: 49.2331061, lng: 19.4168395 },
    { lat: 49.2335655, lng: 19.4181193 },
    { lat: 49.2335216, lng: 19.4186545 },
    { lat: 49.2335453, lng: 19.4189256 },
    { lat: 49.2334656, lng: 19.4191982 },
    { lat: 49.2334587, lng: 19.4192191 },
    { lat: 49.2333559, lng: 19.4191653 },
    { lat: 49.2330952, lng: 19.4192771 },
    { lat: 49.2329191, lng: 19.4195381 },
    { lat: 49.2327469, lng: 19.4197861 },
    { lat: 49.2325437, lng: 19.4202169 },
    { lat: 49.2324835, lng: 19.4206186 },
    { lat: 49.2323073, lng: 19.4206831 },
    { lat: 49.2321834, lng: 19.4209298 },
    { lat: 49.2320499, lng: 19.4209882 },
    { lat: 49.2319098, lng: 19.4210542 },
    { lat: 49.2319241, lng: 19.4213159 },
    { lat: 49.2317051, lng: 19.421182 },
    { lat: 49.2314592, lng: 19.4212518 },
    { lat: 49.2312261, lng: 19.4211335 },
    { lat: 49.2310917, lng: 19.4212944 },
    { lat: 49.2306818, lng: 19.4214227 },
    { lat: 49.23013, lng: 19.4216266 },
    { lat: 49.2299719, lng: 19.422006 },
    { lat: 49.2296564, lng: 19.4225658 },
    { lat: 49.229498, lng: 19.4232341 },
    { lat: 49.2291263, lng: 19.4237729 },
    { lat: 49.2288496, lng: 19.4242426 },
    { lat: 49.2282431, lng: 19.4240478 },
    { lat: 49.2277639, lng: 19.4241119 },
    { lat: 49.2273869, lng: 19.424246 },
    { lat: 49.2271637, lng: 19.4244377 },
    { lat: 49.2267452, lng: 19.4245233 },
    { lat: 49.2266732, lng: 19.4245999 },
    { lat: 49.2265109, lng: 19.4247909 },
    { lat: 49.2261339, lng: 19.4253652 },
    { lat: 49.2258794, lng: 19.4253411 },
    { lat: 49.2258513, lng: 19.4259058 },
    { lat: 49.2255726, lng: 19.4261648 },
    { lat: 49.2253128, lng: 19.4265138 },
    { lat: 49.225067, lng: 19.4269733 },
    { lat: 49.2248037, lng: 19.4270807 },
    { lat: 49.2245908, lng: 19.4275204 },
    { lat: 49.2243035, lng: 19.4278135 },
    { lat: 49.2243283, lng: 19.4284918 },
    { lat: 49.2241178, lng: 19.4288415 },
    { lat: 49.2237072, lng: 19.4294637 },
    { lat: 49.2235036, lng: 19.4296162 },
    { lat: 49.2232707, lng: 19.4299391 },
    { lat: 49.2232011, lng: 19.4303341 },
    { lat: 49.2230863, lng: 19.4306555 },
    { lat: 49.2230037, lng: 19.4308187 },
    { lat: 49.2228873, lng: 19.4312026 },
    { lat: 49.2228492, lng: 19.4315466 },
    { lat: 49.2227436, lng: 19.4319632 },
    { lat: 49.2226458, lng: 19.432167 },
    { lat: 49.2224895, lng: 19.4324182 },
    { lat: 49.2223035, lng: 19.4329761 },
    { lat: 49.2219941, lng: 19.4333014 },
    { lat: 49.2216598, lng: 19.4336497 },
    { lat: 49.221804, lng: 19.4361121 },
    { lat: 49.2224718, lng: 19.4383203 },
    { lat: 49.2224823, lng: 19.4383541 },
    { lat: 49.2224904, lng: 19.4383807 },
    { lat: 49.2226289, lng: 19.4382554 },
    { lat: 49.223076, lng: 19.438079 },
    { lat: 49.2233078, lng: 19.4378078 },
    { lat: 49.2236738, lng: 19.4377305 },
    { lat: 49.2241524, lng: 19.4376028 },
    { lat: 49.224294, lng: 19.4375703 },
    { lat: 49.225034, lng: 19.4380241 },
    { lat: 49.2254256, lng: 19.43816 },
    { lat: 49.2258768, lng: 19.4383176 },
    { lat: 49.2262201, lng: 19.4385262 },
    { lat: 49.2265125, lng: 19.4386872 },
    { lat: 49.226511, lng: 19.4387508 },
    { lat: 49.2268232, lng: 19.4387785 },
    { lat: 49.2272998, lng: 19.438827 },
    { lat: 49.2280103, lng: 19.4391269 },
    { lat: 49.2283243, lng: 19.4392445 },
    { lat: 49.2287142, lng: 19.4395374 },
    { lat: 49.2291157, lng: 19.4400289 },
    { lat: 49.2295663, lng: 19.4404923 },
    { lat: 49.2302194, lng: 19.4411651 },
    { lat: 49.2304772, lng: 19.4413551 },
    { lat: 49.2308242, lng: 19.4416228 },
    { lat: 49.2309251, lng: 19.4413952 },
    { lat: 49.2311833, lng: 19.4408097 },
    { lat: 49.2312599, lng: 19.4406377 },
    { lat: 49.2313395, lng: 19.4403872 },
    { lat: 49.2314546, lng: 19.4400272 },
    { lat: 49.2315454, lng: 19.4397441 },
    { lat: 49.2321916, lng: 19.4397823 },
    { lat: 49.2327624, lng: 19.4395045 },
    { lat: 49.2331487, lng: 19.4393043 },
    { lat: 49.2337247, lng: 19.4389649 },
    { lat: 49.2335099, lng: 19.4379752 },
    { lat: 49.2336613, lng: 19.4379095 },
    { lat: 49.2343811, lng: 19.4377115 },
    { lat: 49.2341406, lng: 19.4368457 },
    { lat: 49.2345131, lng: 19.4364241 },
    { lat: 49.2349411, lng: 19.4359066 },
    { lat: 49.2354261, lng: 19.4353539 },
    { lat: 49.2355883, lng: 19.4352066 },
    { lat: 49.2360178, lng: 19.4348682 },
    { lat: 49.2365936, lng: 19.434311 },
    { lat: 49.2366336, lng: 19.4342713 },
    { lat: 49.2366301, lng: 19.4341506 },
    { lat: 49.2365959, lng: 19.4340432 },
    { lat: 49.2366097, lng: 19.4338765 },
    { lat: 49.2365925, lng: 19.433776 },
    { lat: 49.2366526, lng: 19.433538 },
    { lat: 49.2366947, lng: 19.4334729 },
    { lat: 49.2367639, lng: 19.4332188 },
    { lat: 49.2367636, lng: 19.4324042 },
    { lat: 49.2367468, lng: 19.4321399 },
    { lat: 49.2367667, lng: 19.4319874 },
    { lat: 49.2367148, lng: 19.4317899 },
    { lat: 49.2367654, lng: 19.431566 },
    { lat: 49.2368678, lng: 19.4315185 },
    { lat: 49.2369835, lng: 19.4313398 },
    { lat: 49.2370015, lng: 19.4312127 },
    { lat: 49.237104, lng: 19.4311883 },
    { lat: 49.2372214, lng: 19.4310981 },
    { lat: 49.2373217, lng: 19.4308778 },
    { lat: 49.2374143, lng: 19.4307592 },
    { lat: 49.2375036, lng: 19.4306245 },
    { lat: 49.2376152, lng: 19.4307192 },
    { lat: 49.2378075, lng: 19.4306844 },
    { lat: 49.2379214, lng: 19.4305689 },
    { lat: 49.2380709, lng: 19.4304381 },
    { lat: 49.2383008, lng: 19.4301447 },
    { lat: 49.2383652, lng: 19.4300057 },
    { lat: 49.2383847, lng: 19.4298655 },
    { lat: 49.2384999, lng: 19.4297009 },
    { lat: 49.2385334, lng: 19.4295949 },
    { lat: 49.2386548, lng: 19.4292742 },
    { lat: 49.2387875, lng: 19.4292941 },
    { lat: 49.2389307, lng: 19.4289995 },
    { lat: 49.2390296, lng: 19.4287743 },
    { lat: 49.2390584, lng: 19.4286219 },
    { lat: 49.2391834, lng: 19.4282041 },
    { lat: 49.2392505, lng: 19.4280728 },
    { lat: 49.239227, lng: 19.4278309 },
    { lat: 49.2391638, lng: 19.4275885 },
    { lat: 49.239198, lng: 19.4274947 },
    { lat: 49.239248, lng: 19.4274334 },
    { lat: 49.2394153, lng: 19.4272977 },
    { lat: 49.2394161, lng: 19.4272688 },
    { lat: 49.2394007, lng: 19.4272275 },
    { lat: 49.2394303, lng: 19.4272341 },
    { lat: 49.2394543, lng: 19.4272423 },
    { lat: 49.239602, lng: 19.4273421 },
    { lat: 49.2397214, lng: 19.4274125 },
    { lat: 49.2398518, lng: 19.427525 },
    { lat: 49.2399701, lng: 19.4275498 },
    { lat: 49.2401157, lng: 19.4276051 },
    { lat: 49.2401229, lng: 19.4274189 },
    { lat: 49.2400546, lng: 19.4271111 },
    { lat: 49.2399836, lng: 19.4267257 },
    { lat: 49.239948, lng: 19.4264151 },
    { lat: 49.2399167, lng: 19.4260297 },
    { lat: 49.239943, lng: 19.4258949 },
    { lat: 49.2400018, lng: 19.4258272 },
    { lat: 49.2401047, lng: 19.4254184 },
    { lat: 49.2401867, lng: 19.4253175 },
    { lat: 49.2404034, lng: 19.4251787 },
    { lat: 49.2408269, lng: 19.4249581 },
    { lat: 49.2412654, lng: 19.4250816 },
    { lat: 49.2414487, lng: 19.4251931 },
    { lat: 49.2418389, lng: 19.4251055 },
    { lat: 49.242034, lng: 19.4251833 },
    { lat: 49.2421713, lng: 19.4252719 },
    { lat: 49.2423584, lng: 19.4252525 },
    { lat: 49.2426427, lng: 19.4251622 },
    { lat: 49.2428775, lng: 19.4251434 },
    { lat: 49.2428794, lng: 19.4255525 },
    { lat: 49.2430504, lng: 19.425644 },
    { lat: 49.2436435, lng: 19.4258923 },
    { lat: 49.2440123, lng: 19.4259747 },
    { lat: 49.2440972, lng: 19.4258588 },
    { lat: 49.2441414, lng: 19.4257689 },
    { lat: 49.2443049, lng: 19.4256026 },
    { lat: 49.2446349, lng: 19.4253809 },
    { lat: 49.244792, lng: 19.4252453 },
    { lat: 49.2450985, lng: 19.424832 },
    { lat: 49.2454715, lng: 19.4244166 },
    { lat: 49.245629, lng: 19.4243156 },
    { lat: 49.2457239, lng: 19.4243198 },
    { lat: 49.245805, lng: 19.4241754 },
    { lat: 49.2458152, lng: 19.4240798 },
    { lat: 49.2458615, lng: 19.4239832 },
    { lat: 49.2459993, lng: 19.4238168 },
    { lat: 49.2465495, lng: 19.4232167 },
    { lat: 49.2465336, lng: 19.4231434 },
    { lat: 49.2465987, lng: 19.4229625 },
    { lat: 49.2466985, lng: 19.4227577 },
    { lat: 49.2467971, lng: 19.4226024 },
    { lat: 49.2470235, lng: 19.4224084 },
    { lat: 49.2471352, lng: 19.4223379 },
    { lat: 49.2474349, lng: 19.4221904 },
    { lat: 49.2477011, lng: 19.4221209 },
    { lat: 49.2478231, lng: 19.4220327 },
    { lat: 49.247876, lng: 19.4217878 },
    { lat: 49.24798, lng: 19.4214037 },
    { lat: 49.2480567, lng: 19.4211368 },
    { lat: 49.2480779, lng: 19.4208409 },
    { lat: 49.2480692, lng: 19.4205814 },
    { lat: 49.2481844, lng: 19.4204427 },
    { lat: 49.2483287, lng: 19.4202958 },
    { lat: 49.2484982, lng: 19.4201887 },
    { lat: 49.2486406, lng: 19.4201228 },
    { lat: 49.2489154, lng: 19.4197555 },
    { lat: 49.2489792, lng: 19.4197446 },
    { lat: 49.2490389, lng: 19.4197692 },
    { lat: 49.2492637, lng: 19.4197341 },
    { lat: 49.2499517, lng: 19.4197931 },
    { lat: 49.2502067, lng: 19.419921 },
    { lat: 49.2507342, lng: 19.4202175 },
    { lat: 49.2512616, lng: 19.4207787 },
    { lat: 49.2515554, lng: 19.4209446 },
    { lat: 49.2516864, lng: 19.4210711 },
    { lat: 49.2521258, lng: 19.4214121 },
    { lat: 49.2524965, lng: 19.4214817 },
    { lat: 49.2530486, lng: 19.4213819 },
    { lat: 49.2533212, lng: 19.4214286 },
    { lat: 49.2534179, lng: 19.421447 },
    { lat: 49.2535414, lng: 19.4200044 },
    { lat: 49.2537749, lng: 19.4193537 },
    { lat: 49.2538167, lng: 19.4191388 },
    { lat: 49.2539799, lng: 19.4192056 },
    { lat: 49.2542834, lng: 19.4194321 },
    { lat: 49.2543489, lng: 19.4196328 },
    { lat: 49.2545021, lng: 19.4196563 },
    { lat: 49.2548107, lng: 19.4196002 },
    { lat: 49.2549907, lng: 19.4195248 },
    { lat: 49.2552525, lng: 19.4194835 },
    { lat: 49.2552598, lng: 19.4195891 },
    { lat: 49.255457, lng: 19.4195649 },
    { lat: 49.2556918, lng: 19.4193581 },
    { lat: 49.2557623, lng: 19.4192231 },
    { lat: 49.2561789, lng: 19.4189508 },
    { lat: 49.2564659, lng: 19.4188721 },
    { lat: 49.2565871, lng: 19.4188615 },
    { lat: 49.2566217, lng: 19.418927 },
    { lat: 49.2566974, lng: 19.4189037 },
    { lat: 49.2568259, lng: 19.418787 },
    { lat: 49.2569544, lng: 19.4186403 },
    { lat: 49.2570186, lng: 19.4186146 },
    { lat: 49.2573854, lng: 19.4181893 },
    { lat: 49.2575043, lng: 19.4179372 },
    { lat: 49.2575589, lng: 19.4175367 },
    { lat: 49.2576947, lng: 19.4171605 },
    { lat: 49.2577322, lng: 19.4170414 },
    { lat: 49.25778, lng: 19.4165914 },
    { lat: 49.2578879, lng: 19.416 },
    { lat: 49.2579292, lng: 19.4158896 },
    { lat: 49.258101, lng: 19.4156141 },
    { lat: 49.2582531, lng: 19.4153896 },
    { lat: 49.2584351, lng: 19.4151467 },
    { lat: 49.2585617, lng: 19.414982 },
    { lat: 49.2588321, lng: 19.4147213 },
    { lat: 49.2595041, lng: 19.4147463 },
    { lat: 49.2599496, lng: 19.4147518 },
    { lat: 49.2607555, lng: 19.4147836 },
    { lat: 49.2607232, lng: 19.4137474 },
    { lat: 49.2606882, lng: 19.4130642 },
    { lat: 49.2606387, lng: 19.4121904 },
    { lat: 49.261219, lng: 19.4121928 },
    { lat: 49.2619378, lng: 19.4121168 },
    { lat: 49.2628343, lng: 19.4117653 },
    { lat: 49.2628831, lng: 19.4117481 },
    { lat: 49.2630196, lng: 19.4117019 },
    { lat: 49.2636319, lng: 19.4114938 },
    { lat: 49.2636959, lng: 19.4114428 },
    { lat: 49.2640789, lng: 19.4113411 },
    { lat: 49.2640865, lng: 19.4113384 },
    { lat: 49.2641483, lng: 19.4113051 },
    { lat: 49.264201, lng: 19.411277 },
    { lat: 49.2648258, lng: 19.4109502 },
    { lat: 49.2651621, lng: 19.4107647 },
    { lat: 49.2654555, lng: 19.4106041 },
    { lat: 49.2656323, lng: 19.4105309 },
    { lat: 49.2657997, lng: 19.4104701 },
    { lat: 49.2659143, lng: 19.4104613 },
    { lat: 49.2661855, lng: 19.4103565 },
    { lat: 49.2663083, lng: 19.410332 },
    { lat: 49.2665947, lng: 19.4102148 },
    { lat: 49.2670078, lng: 19.4100856 },
    { lat: 49.2675288, lng: 19.4097769 },
    { lat: 49.2688978, lng: 19.4095563 },
    { lat: 49.269055, lng: 19.4096923 },
    { lat: 49.2692308, lng: 19.409644 },
    { lat: 49.2694793, lng: 19.4096827 },
    { lat: 49.2697641, lng: 19.4104255 },
    { lat: 49.2701503, lng: 19.4109531 },
    { lat: 49.2703794, lng: 19.4115439 },
    { lat: 49.2707631, lng: 19.4117268 },
    { lat: 49.2711703, lng: 19.4116628 },
    { lat: 49.2715572, lng: 19.4118183 },
    { lat: 49.2719211, lng: 19.4121307 },
    { lat: 49.2721436, lng: 19.4122376 },
    { lat: 49.272446, lng: 19.4122715 },
    { lat: 49.2726756, lng: 19.4122189 },
    { lat: 49.2728048, lng: 19.4122089 },
    { lat: 49.2731078, lng: 19.4121136 },
    { lat: 49.2733409, lng: 19.4121284 },
    { lat: 49.2738207, lng: 19.4119317 },
    { lat: 49.2738898, lng: 19.4120866 },
    { lat: 49.2740033, lng: 19.41242 },
    { lat: 49.2742651, lng: 19.4127197 },
    { lat: 49.2746406, lng: 19.4131633 },
    { lat: 49.2747559, lng: 19.4132681 },
    { lat: 49.2750063, lng: 19.4135402 },
    { lat: 49.2751538, lng: 19.4137815 },
    { lat: 49.2752967, lng: 19.4138945 },
    { lat: 49.2755884, lng: 19.4140906 },
    { lat: 49.2760568, lng: 19.4142275 },
    { lat: 49.2762736, lng: 19.4140189 },
    { lat: 49.2765427, lng: 19.413696 },
    { lat: 49.276924, lng: 19.4131953 },
    { lat: 49.2771057, lng: 19.4132492 },
    { lat: 49.2773884, lng: 19.4128595 },
    { lat: 49.2777233, lng: 19.4125244 },
    { lat: 49.2779089, lng: 19.4124429 },
    { lat: 49.2780314, lng: 19.4124372 },
    { lat: 49.2781194, lng: 19.4123631 },
    { lat: 49.2782387, lng: 19.4121462 },
    { lat: 49.2784624, lng: 19.4115283 },
    { lat: 49.2789156, lng: 19.4110919 },
    { lat: 49.2789842, lng: 19.4109473 },
    { lat: 49.2791054, lng: 19.4108368 },
    { lat: 49.2792541, lng: 19.4107629 },
    { lat: 49.2794356, lng: 19.4106451 },
    { lat: 49.2798542, lng: 19.4107289 },
    { lat: 49.2800164, lng: 19.410703 },
    { lat: 49.2801548, lng: 19.4106021 },
    { lat: 49.2804516, lng: 19.4101758 },
    { lat: 49.2804576, lng: 19.4097446 },
    { lat: 49.2804659, lng: 19.4089515 },
    { lat: 49.2805648, lng: 19.4088999 },
    { lat: 49.280886, lng: 19.4093589 },
    { lat: 49.2810239, lng: 19.409223 },
    { lat: 49.2809215, lng: 19.4088371 },
    { lat: 49.2808417, lng: 19.4084556 },
    { lat: 49.2808656, lng: 19.4078524 },
    { lat: 49.2812379, lng: 19.4078849 },
    { lat: 49.2815409, lng: 19.4078809 },
    { lat: 49.2816956, lng: 19.4079887 },
    { lat: 49.2821355, lng: 19.408046 },
    { lat: 49.2823337, lng: 19.4080422 },
    { lat: 49.282685, lng: 19.4082553 },
    { lat: 49.2830343, lng: 19.4089837 },
    { lat: 49.2835338, lng: 19.4084314 },
    { lat: 49.2838218, lng: 19.4083223 },
    { lat: 49.2841214, lng: 19.4081984 },
    { lat: 49.284493, lng: 19.4080769 },
    { lat: 49.285046, lng: 19.4079946 },
    { lat: 49.2855608, lng: 19.4079441 },
    { lat: 49.2858354, lng: 19.407696 },
    { lat: 49.2861708, lng: 19.4070517 },
    { lat: 49.2865093, lng: 19.4074059 },
    { lat: 49.2870157, lng: 19.4072914 },
    { lat: 49.2878649, lng: 19.407038 },
    { lat: 49.288158, lng: 19.4069174 },
    { lat: 49.2881236, lng: 19.4065951 },
    { lat: 49.2889513, lng: 19.4060209 },
    { lat: 49.2894148, lng: 19.4060562 },
    { lat: 49.2895007, lng: 19.4056743 },
    { lat: 49.2898287, lng: 19.405603 },
    { lat: 49.29034, lng: 19.4058634 },
    { lat: 49.2907749, lng: 19.4057403 },
    { lat: 49.2910088, lng: 19.4056828 },
    { lat: 49.2912385, lng: 19.4054326 },
    { lat: 49.2915931, lng: 19.4051833 },
    { lat: 49.2920523, lng: 19.404758 },
    { lat: 49.292261, lng: 19.4044487 },
    { lat: 49.2924908, lng: 19.4041079 },
    { lat: 49.2928675, lng: 19.4037753 },
    { lat: 49.2932525, lng: 19.4037411 },
    { lat: 49.2931954, lng: 19.4031942 },
    { lat: 49.2932397, lng: 19.4030082 },
    { lat: 49.2934346, lng: 19.4029599 },
    { lat: 49.2938739, lng: 19.4023209 },
    { lat: 49.294266, lng: 19.4019584 },
    { lat: 49.2944509, lng: 19.4015979 },
    { lat: 49.2948305, lng: 19.4014025 },
    { lat: 49.2952922, lng: 19.401226 },
    { lat: 49.2957084, lng: 19.4014538 },
    { lat: 49.2959376, lng: 19.4010949 },
    { lat: 49.2962078, lng: 19.4004355 },
    { lat: 49.2964464, lng: 19.3994135 },
    { lat: 49.2965925, lng: 19.3990398 },
    { lat: 49.29682, lng: 19.3985761 },
    { lat: 49.29725, lng: 19.3983268 },
    { lat: 49.2974549, lng: 19.3983208 },
    { lat: 49.2977253, lng: 19.398295 },
    { lat: 49.2979446, lng: 19.3979238 },
    { lat: 49.2982796, lng: 19.3975661 },
    { lat: 49.2986298, lng: 19.3970742 },
    { lat: 49.2990065, lng: 19.3967209 },
    { lat: 49.299188, lng: 19.3964101 },
    { lat: 49.2995961, lng: 19.3960444 },
    { lat: 49.3001796, lng: 19.3960658 },
    { lat: 49.3003394, lng: 19.3957253 },
    { lat: 49.300456, lng: 19.395728 },
    { lat: 49.300184, lng: 19.395394 },
    { lat: 49.29973, lng: 19.394834 },
    { lat: 49.299283, lng: 19.394265 },
    { lat: 49.298854, lng: 19.39388 },
    { lat: 49.29869, lng: 19.393701 },
    { lat: 49.298378, lng: 19.393358 },
    { lat: 49.298087, lng: 19.393207 },
    { lat: 49.29797, lng: 19.393145 },
    { lat: 49.297717, lng: 19.393126 },
    { lat: 49.297349, lng: 19.392855 },
    { lat: 49.296969, lng: 19.392387 },
    { lat: 49.29692, lng: 19.392215 },
    { lat: 49.296627, lng: 19.391168 },
    { lat: 49.296433, lng: 19.390476 },
    { lat: 49.296345, lng: 19.389985 },
    { lat: 49.296249, lng: 19.389452 },
    { lat: 49.295943, lng: 19.388307 },
    { lat: 49.295305, lng: 19.386096 },
    { lat: 49.295247, lng: 19.385894 },
    { lat: 49.295121, lng: 19.385582 },
    { lat: 49.295073, lng: 19.385465 },
    { lat: 49.294771, lng: 19.384493 },
    { lat: 49.294651, lng: 19.384092 },
    { lat: 49.294397, lng: 19.383228 },
    { lat: 49.294387, lng: 19.383208 },
    { lat: 49.2943518, lng: 19.3831972 },
    { lat: 49.2942569, lng: 19.382975 },
    { lat: 49.2941822, lng: 19.3828008 },
    { lat: 49.2941329, lng: 19.3826863 },
    { lat: 49.2941141, lng: 19.3826425 },
    { lat: 49.2940777, lng: 19.3825656 },
    { lat: 49.2940741, lng: 19.3825592 },
    { lat: 49.2940417, lng: 19.3824916 },
    { lat: 49.2939803, lng: 19.3823604 },
    { lat: 49.2939617, lng: 19.3823205 },
    { lat: 49.2938955, lng: 19.3822071 },
    { lat: 49.2938328, lng: 19.3820992 },
    { lat: 49.2937325, lng: 19.3819274 },
    { lat: 49.293713, lng: 19.3818952 },
    { lat: 49.293645, lng: 19.3818138 },
    { lat: 49.2934625, lng: 19.3815969 },
    { lat: 49.2933872, lng: 19.3815066 },
    { lat: 49.2933072, lng: 19.3814117 },
    { lat: 49.2931554, lng: 19.3812704 },
    { lat: 49.2930003, lng: 19.3810642 },
    { lat: 49.2929392, lng: 19.3809842 },
    { lat: 49.2929286, lng: 19.3809746 },
    { lat: 49.292838, lng: 19.3808935 },
    { lat: 49.2928264, lng: 19.3808839 },
    { lat: 49.2927158, lng: 19.3807858 },
    { lat: 49.2926264, lng: 19.3807061 },
    { lat: 49.2925771, lng: 19.3806623 },
    { lat: 49.2925419, lng: 19.3806315 },
    { lat: 49.2925055, lng: 19.3805994 },
    { lat: 49.2924656, lng: 19.380564 },
    { lat: 49.2924407, lng: 19.3805418 },
    { lat: 49.2923733, lng: 19.3804957 },
    { lat: 49.292308, lng: 19.3804512 },
    { lat: 49.2922102, lng: 19.380385 },
    { lat: 49.2921648, lng: 19.3803535 },
    { lat: 49.2920951, lng: 19.3803066 },
    { lat: 49.2920812, lng: 19.3802969 },
    { lat: 49.2920522, lng: 19.3802578 },
    { lat: 49.2920175, lng: 19.3802129 },
    { lat: 49.291929, lng: 19.3801019 },
    { lat: 49.2918703, lng: 19.3800278 },
    { lat: 49.2917983, lng: 19.3799371 },
    { lat: 49.2917926, lng: 19.3799316 },
    { lat: 49.2917053, lng: 19.3798527 },
    { lat: 49.2916489, lng: 19.3798022 },
    { lat: 49.2916479, lng: 19.3798008 },
    { lat: 49.2915231, lng: 19.3796881 },
    { lat: 49.2914137, lng: 19.3795888 },
    { lat: 49.2913242, lng: 19.3795076 },
    { lat: 49.2912982, lng: 19.3794833 },
    { lat: 49.2912809, lng: 19.3794701 },
    { lat: 49.2912409, lng: 19.3794398 },
    { lat: 49.291199, lng: 19.3794072 },
    { lat: 49.2911557, lng: 19.379374 },
    { lat: 49.2911172, lng: 19.3793442 },
    { lat: 49.2910435, lng: 19.3792873 },
    { lat: 49.2909945, lng: 19.3792493 },
    { lat: 49.2909793, lng: 19.379238 },
    { lat: 49.2909375, lng: 19.3792364 },
    { lat: 49.2908845, lng: 19.3792339 },
    { lat: 49.2908009, lng: 19.37923 },
    { lat: 49.2907513, lng: 19.3792293 },
    { lat: 49.2906745, lng: 19.3792283 },
    { lat: 49.2906294, lng: 19.3792281 },
    { lat: 49.2906001, lng: 19.3792277 },
    { lat: 49.2905769, lng: 19.3792357 },
    { lat: 49.2905268, lng: 19.3792516 },
    { lat: 49.2903892, lng: 19.3792969 },
    { lat: 49.2902893, lng: 19.3793273 },
    { lat: 49.2901618, lng: 19.379399 },
    { lat: 49.2899925, lng: 19.3794911 },
    { lat: 49.2899147, lng: 19.3795338 },
    { lat: 49.2897795, lng: 19.3795605 },
    { lat: 49.2897648, lng: 19.3795592 },
    { lat: 49.2894579, lng: 19.3795259 },
    { lat: 49.2893066, lng: 19.3793753 },
    { lat: 49.2890725, lng: 19.3789936 },
    { lat: 49.2889871, lng: 19.3788527 },
    { lat: 49.288937, lng: 19.3787708 },
    { lat: 49.2886065, lng: 19.3789354 },
    { lat: 49.288259, lng: 19.3791902 },
    { lat: 49.2881729, lng: 19.3792538 },
    { lat: 49.2878254, lng: 19.3795082 },
    { lat: 49.2874297, lng: 19.3797922 },
    { lat: 49.2873214, lng: 19.3798661 },
    { lat: 49.2872821, lng: 19.3798942 },
    { lat: 49.2872624, lng: 19.3799061 },
    { lat: 49.2868088, lng: 19.3801808 },
    { lat: 49.2868065, lng: 19.3801821 },
    { lat: 49.2865213, lng: 19.3803497 },
    { lat: 49.2862502, lng: 19.3806481 },
    { lat: 49.285949, lng: 19.3808319 },
    { lat: 49.285938, lng: 19.3808392 },
    { lat: 49.2857003, lng: 19.3809878 },
    { lat: 49.2855844, lng: 19.3811351 },
    { lat: 49.2853698, lng: 19.3814141 },
    { lat: 49.2852377, lng: 19.3814783 },
    { lat: 49.2851407, lng: 19.381525 },
    { lat: 49.2849466, lng: 19.3816165 },
    { lat: 49.2849003, lng: 19.3816403 },
    { lat: 49.2848168, lng: 19.3816628 },
    { lat: 49.2846898, lng: 19.3817907 },
    { lat: 49.2845274, lng: 19.3819531 },
    { lat: 49.2843407, lng: 19.382133 },
    { lat: 49.2841486, lng: 19.3823135 },
    { lat: 49.2841374, lng: 19.3823403 },
    { lat: 49.2841062, lng: 19.382421 },
    { lat: 49.2841043, lng: 19.3824259 },
    { lat: 49.2840269, lng: 19.3826292 },
    { lat: 49.2839625, lng: 19.3827933 },
    { lat: 49.2839887, lng: 19.3829705 },
    { lat: 49.2840168, lng: 19.3831126 },
    { lat: 49.2840372, lng: 19.3832594 },
    { lat: 49.2840477, lng: 19.38339 },
    { lat: 49.2840458, lng: 19.383491 },
    { lat: 49.2840451, lng: 19.3835268 },
    { lat: 49.2840345, lng: 19.3835875 },
    { lat: 49.2840042, lng: 19.3836875 },
    { lat: 49.2839704, lng: 19.3837832 },
    { lat: 49.2839519, lng: 19.3838206 },
    { lat: 49.2838984, lng: 19.3839308 },
    { lat: 49.2838419, lng: 19.3840005 },
    { lat: 49.2837854, lng: 19.3840683 },
    { lat: 49.2838414, lng: 19.3842066 },
    { lat: 49.2838631, lng: 19.3842624 },
    { lat: 49.2839358, lng: 19.3844436 },
    { lat: 49.2838423, lng: 19.3846621 },
    { lat: 49.2838389, lng: 19.3846646 },
    { lat: 49.2837534, lng: 19.3847595 },
    { lat: 49.2836885, lng: 19.3848672 },
    { lat: 49.2836065, lng: 19.384992 },
    { lat: 49.2835645, lng: 19.3850385 },
    { lat: 49.2835079, lng: 19.3851017 },
    { lat: 49.2834373, lng: 19.3851617 },
    { lat: 49.2833991, lng: 19.3851879 },
    { lat: 49.2833585, lng: 19.3852082 },
    { lat: 49.2833056, lng: 19.3852352 },
    { lat: 49.2832903, lng: 19.3852463 },
    { lat: 49.2832239, lng: 19.3852961 },
    { lat: 49.283187, lng: 19.3853232 },
    { lat: 49.2831068, lng: 19.385372 },
    { lat: 49.2829566, lng: 19.3854631 },
    { lat: 49.2828167, lng: 19.3855505 },
    { lat: 49.2825594, lng: 19.3857143 },
    { lat: 49.2825003, lng: 19.3857546 },
    { lat: 49.2823541, lng: 19.3858554 },
    { lat: 49.2821502, lng: 19.3859989 },
    { lat: 49.2821073, lng: 19.3860259 },
    { lat: 49.2820342, lng: 19.3860732 },
    { lat: 49.2818737, lng: 19.3861867 },
    { lat: 49.28161, lng: 19.3863796 },
    { lat: 49.2814009, lng: 19.3865356 },
    { lat: 49.2811902, lng: 19.3867047 },
    { lat: 49.2810441, lng: 19.3868336 },
    { lat: 49.2809239, lng: 19.3869405 },
    { lat: 49.2807702, lng: 19.3870717 },
    { lat: 49.2806401, lng: 19.3871829 },
    { lat: 49.2806296, lng: 19.3871512 },
    { lat: 49.2805196, lng: 19.3872072 },
    { lat: 49.2805029, lng: 19.3872145 },
    { lat: 49.280438, lng: 19.387245 },
    { lat: 49.2804225, lng: 19.3872521 },
    { lat: 49.2803684, lng: 19.3872775 },
    { lat: 49.2803398, lng: 19.3872907 },
    { lat: 49.2802977, lng: 19.3873108 },
    { lat: 49.2802196, lng: 19.3873478 },
    { lat: 49.2798643, lng: 19.3875352 },
    { lat: 49.2795206, lng: 19.3877758 },
    { lat: 49.2793557, lng: 19.3878909 },
    { lat: 49.2793504, lng: 19.387895 },
    { lat: 49.2791425, lng: 19.3879834 },
    { lat: 49.278742, lng: 19.3880699 },
    { lat: 49.2786547, lng: 19.3881061 },
    { lat: 49.2785466, lng: 19.3881518 },
    { lat: 49.2784606, lng: 19.3881709 },
    { lat: 49.2784103, lng: 19.3881822 },
    { lat: 49.2783786, lng: 19.3881762 },
    { lat: 49.2782798, lng: 19.3881581 },
    { lat: 49.2781876, lng: 19.3881407 },
    { lat: 49.2781843, lng: 19.38814 },
    { lat: 49.2781353, lng: 19.388154 },
    { lat: 49.2780507, lng: 19.3881776 },
    { lat: 49.2779918, lng: 19.3881949 },
    { lat: 49.2779328, lng: 19.3882118 },
    { lat: 49.2778739, lng: 19.388229 },
    { lat: 49.277804, lng: 19.3882489 },
    { lat: 49.2777427, lng: 19.3882667 },
    { lat: 49.2776694, lng: 19.3882895 },
    { lat: 49.2775817, lng: 19.3883175 },
    { lat: 49.277546, lng: 19.3883286 },
    { lat: 49.2774798, lng: 19.3883553 },
    { lat: 49.2774343, lng: 19.3883732 },
    { lat: 49.2774125, lng: 19.3883868 },
    { lat: 49.2773271, lng: 19.388439 },
    { lat: 49.2772964, lng: 19.3884582 },
    { lat: 49.2772766, lng: 19.3884708 },
    { lat: 49.2772158, lng: 19.3885446 },
    { lat: 49.2771615, lng: 19.3886104 },
    { lat: 49.2770928, lng: 19.3886612 },
    { lat: 49.2770292, lng: 19.3887002 },
    { lat: 49.2770086, lng: 19.388713 },
    { lat: 49.2769778, lng: 19.3887325 },
    { lat: 49.2768874, lng: 19.3885558 },
    { lat: 49.2768718, lng: 19.3885589 },
    { lat: 49.2767075, lng: 19.3885916 },
    { lat: 49.2766439, lng: 19.3886043 },
    { lat: 49.2765859, lng: 19.388616 },
    { lat: 49.2765005, lng: 19.3886441 },
    { lat: 49.2764244, lng: 19.3886784 },
    { lat: 49.2764034, lng: 19.3886882 },
    { lat: 49.2763471, lng: 19.3887113 },
    { lat: 49.2763084, lng: 19.3887267 },
    { lat: 49.2762753, lng: 19.3887466 },
    { lat: 49.2761886, lng: 19.3887968 },
    { lat: 49.2761502, lng: 19.3888194 },
    { lat: 49.2761011, lng: 19.3888485 },
    { lat: 49.2760516, lng: 19.388877 },
    { lat: 49.2760448, lng: 19.3888774 },
    { lat: 49.275985, lng: 19.388877 },
    { lat: 49.2758958, lng: 19.3888734 },
    { lat: 49.2758303, lng: 19.3888701 },
    { lat: 49.27573, lng: 19.3888662 },
    { lat: 49.2755356, lng: 19.3887596 },
    { lat: 49.2754304, lng: 19.3887519 },
    { lat: 49.2753047, lng: 19.3887143 },
    { lat: 49.2752458, lng: 19.3887326 },
    { lat: 49.2751304, lng: 19.388769 },
    { lat: 49.2750514, lng: 19.3887917 },
    { lat: 49.2750037, lng: 19.3888059 },
    { lat: 49.274988, lng: 19.3888101 },
    { lat: 49.2749329, lng: 19.3888829 },
    { lat: 49.2749118, lng: 19.3889115 },
    { lat: 49.2747955, lng: 19.3889801 },
    { lat: 49.2747762, lng: 19.389001 },
    { lat: 49.2746555, lng: 19.3891221 },
    { lat: 49.2745228, lng: 19.3892505 },
    { lat: 49.2744399, lng: 19.3893306 },
    { lat: 49.2743249, lng: 19.3893298 },
    { lat: 49.2741812, lng: 19.3893884 },
    { lat: 49.2740393, lng: 19.3894655 },
    { lat: 49.2739314, lng: 19.3895668 },
    { lat: 49.2738586, lng: 19.3896259 },
    { lat: 49.2737974, lng: 19.3896658 },
    { lat: 49.2737271, lng: 19.3897027 },
    { lat: 49.2736743, lng: 19.3897301 },
    { lat: 49.2736359, lng: 19.3897512 },
    { lat: 49.2735963, lng: 19.3897739 },
    { lat: 49.2735928, lng: 19.3897754 },
    { lat: 49.2734837, lng: 19.3898003 },
    { lat: 49.2734826, lng: 19.3898004 },
    { lat: 49.2733117, lng: 19.389832 },
    { lat: 49.2731308, lng: 19.3899902 },
    { lat: 49.2729197, lng: 19.3901781 },
    { lat: 49.2729531, lng: 19.3902394 },
    { lat: 49.2726307, lng: 19.3905733 },
    { lat: 49.2724082, lng: 19.3907603 },
    { lat: 49.2723507, lng: 19.3907817 },
    { lat: 49.2723053, lng: 19.3907762 },
    { lat: 49.2722744, lng: 19.3907665 },
    { lat: 49.2721674, lng: 19.3906692 },
    { lat: 49.2720467, lng: 19.390617 },
    { lat: 49.2715583, lng: 19.3907012 },
    { lat: 49.2715514, lng: 19.3907019 },
    { lat: 49.2714927, lng: 19.3906982 },
    { lat: 49.2714123, lng: 19.3906192 },
    { lat: 49.2712987, lng: 19.3905017 },
    { lat: 49.271272, lng: 19.3904869 },
    { lat: 49.2711154, lng: 19.390435 },
    { lat: 49.2709997, lng: 19.3903938 },
    { lat: 49.2708096, lng: 19.3904297 },
    { lat: 49.2707139, lng: 19.3903834 },
    { lat: 49.2705836, lng: 19.3903435 },
    { lat: 49.270476, lng: 19.3904003 },
    { lat: 49.2703609, lng: 19.3903027 },
    { lat: 49.2702324, lng: 19.3903275 },
    { lat: 49.2701848, lng: 19.3903432 },
    { lat: 49.2701704, lng: 19.3903484 },
    { lat: 49.2700769, lng: 19.3904008 },
    { lat: 49.2700447, lng: 19.3904331 },
    { lat: 49.2699553, lng: 19.3904714 },
    { lat: 49.2698503, lng: 19.3905178 },
    { lat: 49.2697966, lng: 19.3905713 },
    { lat: 49.2696163, lng: 19.3906959 },
    { lat: 49.2694036, lng: 19.3907021 },
    { lat: 49.2693104, lng: 19.3906826 },
    { lat: 49.2692739, lng: 19.3907201 },
    { lat: 49.2691595, lng: 19.3907596 },
    { lat: 49.2689882, lng: 19.3907573 },
    { lat: 49.2688047, lng: 19.3907867 },
    { lat: 49.2687699, lng: 19.3908143 },
    { lat: 49.2686276, lng: 19.3908327 },
    { lat: 49.2684851, lng: 19.3907695 },
    { lat: 49.2684387, lng: 19.3907417 },
    { lat: 49.2683729, lng: 19.3907586 },
    { lat: 49.2683959, lng: 19.3908409 },
    { lat: 49.2682747, lng: 19.3909216 },
    { lat: 49.2681431, lng: 19.3909756 },
    { lat: 49.2679796, lng: 19.3910698 },
    { lat: 49.2679269, lng: 19.3910987 },
    { lat: 49.2678371, lng: 19.3911337 },
    { lat: 49.2678363, lng: 19.391141 },
    { lat: 49.267831, lng: 19.3911686 },
    { lat: 49.2676398, lng: 19.3912013 },
    { lat: 49.2674175, lng: 19.3912948 },
    { lat: 49.2672083, lng: 19.3913511 },
    { lat: 49.2671439, lng: 19.391444 },
    { lat: 49.2670543, lng: 19.391457 },
    { lat: 49.267013, lng: 19.3914882 },
    { lat: 49.2669158, lng: 19.3915045 },
    { lat: 49.266837, lng: 19.3915322 },
    { lat: 49.2667714, lng: 19.3915469 },
    { lat: 49.2664901, lng: 19.3916078 },
    { lat: 49.2663372, lng: 19.3914679 },
    { lat: 49.2661525, lng: 19.3914013 },
    { lat: 49.2660263, lng: 19.3913725 },
    { lat: 49.2660014, lng: 19.3913702 },
    { lat: 49.265921, lng: 19.3912916 },
    { lat: 49.265856, lng: 19.3912261 },
    { lat: 49.2658204, lng: 19.3913335 },
    { lat: 49.2657856, lng: 19.3913352 },
    { lat: 49.2657077, lng: 19.391357 },
    { lat: 49.2656218, lng: 19.3913782 },
    { lat: 49.2654592, lng: 19.391152 },
    { lat: 49.2654263, lng: 19.3911246 },
    { lat: 49.265411, lng: 19.3911111 },
    { lat: 49.2653677, lng: 19.3910743 },
    { lat: 49.265264, lng: 19.3909755 },
    { lat: 49.2651777, lng: 19.3908871 },
    { lat: 49.2651381, lng: 19.3905486 },
    { lat: 49.265148, lng: 19.3904938 },
    { lat: 49.2650158, lng: 19.3903873 },
    { lat: 49.2648985, lng: 19.3901962 },
    { lat: 49.2647257, lng: 19.3898737 },
    { lat: 49.2646061, lng: 19.3897243 },
    { lat: 49.2645229, lng: 19.3896045 },
    { lat: 49.2644164, lng: 19.3894992 },
    { lat: 49.2642444, lng: 19.3893152 },
    { lat: 49.2641548, lng: 19.3891813 },
    { lat: 49.2640653, lng: 19.3891456 },
    { lat: 49.2639876, lng: 19.3890487 },
    { lat: 49.2638956, lng: 19.3889851 },
    { lat: 49.2638258, lng: 19.3888909 },
    { lat: 49.2637366, lng: 19.3888353 },
    { lat: 49.263675, lng: 19.3887258 },
    { lat: 49.2635781, lng: 19.3887035 },
    { lat: 49.2633994, lng: 19.3884917 },
    { lat: 49.2633706, lng: 19.3884819 },
    { lat: 49.2632884, lng: 19.3884544 },
    { lat: 49.2632705, lng: 19.3884101 },
    { lat: 49.2632732, lng: 19.3883719 },
    { lat: 49.2630837, lng: 19.3882731 },
    { lat: 49.2629449, lng: 19.3880977 },
    { lat: 49.2629034, lng: 19.3880528 },
    { lat: 49.2628581, lng: 19.3880494 },
    { lat: 49.2627697, lng: 19.387966 },
    { lat: 49.2626882, lng: 19.3879088 },
    { lat: 49.2626346, lng: 19.387849 },
    { lat: 49.2626089, lng: 19.3878034 },
    { lat: 49.262565, lng: 19.3877328 },
    { lat: 49.2625069, lng: 19.3876222 },
    { lat: 49.2624806, lng: 19.3875634 },
    { lat: 49.2624573, lng: 19.3874793 },
    { lat: 49.2624231, lng: 19.3874445 },
    { lat: 49.2622512, lng: 19.3872629 },
    { lat: 49.2621579, lng: 19.387171 },
    { lat: 49.2620649, lng: 19.3871115 },
    { lat: 49.2619769, lng: 19.3870103 },
    { lat: 49.2619237, lng: 19.3868375 },
    { lat: 49.2619039, lng: 19.3867981 },
    { lat: 49.2618897, lng: 19.3868325 },
    { lat: 49.2618796, lng: 19.3868592 },
    { lat: 49.2616515, lng: 19.3868238 },
    { lat: 49.2615496, lng: 19.3868114 },
    { lat: 49.2615189, lng: 19.3868054 },
    { lat: 49.2614571, lng: 19.3867865 },
    { lat: 49.2614401, lng: 19.3867808 },
    { lat: 49.2613919, lng: 19.3867658 },
    { lat: 49.2613359, lng: 19.3867488 },
    { lat: 49.2612801, lng: 19.3867383 },
    { lat: 49.2611915, lng: 19.3867223 },
    { lat: 49.2611177, lng: 19.3867087 },
    { lat: 49.2611087, lng: 19.3867075 },
    { lat: 49.2611075, lng: 19.3867073 },
    { lat: 49.2610407, lng: 19.386699 },
    { lat: 49.2610089, lng: 19.3866953 },
    { lat: 49.2609714, lng: 19.38669 },
    { lat: 49.2609557, lng: 19.3866881 },
    { lat: 49.2609283, lng: 19.3866839 },
    { lat: 49.2608988, lng: 19.3866791 },
    { lat: 49.2608762, lng: 19.3866758 },
    { lat: 49.2608365, lng: 19.3866693 },
    { lat: 49.2608013, lng: 19.3866641 },
    { lat: 49.2607739, lng: 19.3866592 },
    { lat: 49.2607478, lng: 19.3866555 },
    { lat: 49.260733, lng: 19.3866528 },
    { lat: 49.2607161, lng: 19.3866492 },
    { lat: 49.2606899, lng: 19.3866441 },
    { lat: 49.2606558, lng: 19.3866367 },
    { lat: 49.2606262, lng: 19.3866305 },
    { lat: 49.2605965, lng: 19.3866222 },
    { lat: 49.2605714, lng: 19.3866152 },
    { lat: 49.2604875, lng: 19.3865832 },
    { lat: 49.26043, lng: 19.3865548 },
    { lat: 49.2604264, lng: 19.3865534 },
    { lat: 49.2603952, lng: 19.3865326 },
    { lat: 49.260345, lng: 19.3864995 },
    { lat: 49.2602591, lng: 19.3864446 },
    { lat: 49.2602218, lng: 19.3864202 },
    { lat: 49.2601357, lng: 19.3863603 },
    { lat: 49.2600787, lng: 19.3863181 },
    { lat: 49.259998, lng: 19.3862598 },
    { lat: 49.2599678, lng: 19.3862396 },
    { lat: 49.2595703, lng: 19.3859788 },
    { lat: 49.2595404, lng: 19.3859651 },
    { lat: 49.2594792, lng: 19.3859349 },
    { lat: 49.2594538, lng: 19.3859222 },
    { lat: 49.2594273, lng: 19.3859096 },
    { lat: 49.2593918, lng: 19.3858741 },
    { lat: 49.2593269, lng: 19.385809 },
    { lat: 49.2591143, lng: 19.3855987 },
    { lat: 49.2590078, lng: 19.3854927 },
    { lat: 49.2589003, lng: 19.3855513 },
    { lat: 49.2588991, lng: 19.3855514 },
    { lat: 49.2587529, lng: 19.3854645 },
    { lat: 49.2587519, lng: 19.3854636 },
    { lat: 49.2587182, lng: 19.3854456 },
    { lat: 49.2585282, lng: 19.3853302 },
    { lat: 49.2583738, lng: 19.3852345 },
    { lat: 49.2583567, lng: 19.3852335 },
    { lat: 49.258344, lng: 19.3852244 },
    { lat: 49.2582953, lng: 19.3851709 },
    { lat: 49.258244, lng: 19.3851104 },
    { lat: 49.2581713, lng: 19.3850246 },
    { lat: 49.2581093, lng: 19.3849516 },
    { lat: 49.2580306, lng: 19.384857 },
    { lat: 49.2577001, lng: 19.3844654 },
    { lat: 49.2575096, lng: 19.3842495 },
    { lat: 49.2574088, lng: 19.3841375 },
    { lat: 49.2573719, lng: 19.3840971 },
    { lat: 49.2573185, lng: 19.3840394 },
    { lat: 49.2572662, lng: 19.383982 },
    { lat: 49.2571403, lng: 19.3838442 },
    { lat: 49.2569378, lng: 19.383583 },
    { lat: 49.2567985, lng: 19.3834009 },
    { lat: 49.2566989, lng: 19.3832714 },
    { lat: 49.2565656, lng: 19.3831932 },
    { lat: 49.2563746, lng: 19.3830809 },
    { lat: 49.2561321, lng: 19.3831978 },
    { lat: 49.2559721, lng: 19.3832701 },
    { lat: 49.2559089, lng: 19.3832928 },
    { lat: 49.2557589, lng: 19.3833629 },
    { lat: 49.2554875, lng: 19.3834881 },
    { lat: 49.2553739, lng: 19.3836141 },
    { lat: 49.2551437, lng: 19.3838718 },
    { lat: 49.2549157, lng: 19.3841279 },
    { lat: 49.2547274, lng: 19.3843439 },
    { lat: 49.2547033, lng: 19.3841907 },
    { lat: 49.2546052, lng: 19.3839965 },
    { lat: 49.2544544, lng: 19.3837092 },
    { lat: 49.2543133, lng: 19.3833879 },
    { lat: 49.2542426, lng: 19.3832293 },
    { lat: 49.2540483, lng: 19.3827859 },
    { lat: 49.2537624, lng: 19.382593 },
    { lat: 49.2535399, lng: 19.3824401 },
    { lat: 49.2531592, lng: 19.3821764 },
    { lat: 49.2530845, lng: 19.3821243 },
    { lat: 49.2528086, lng: 19.3819357 },
    { lat: 49.2527192, lng: 19.3818762 },
    { lat: 49.2526765, lng: 19.3819988 },
    { lat: 49.2526754, lng: 19.3820014 },
    { lat: 49.2526439, lng: 19.382099 },
    { lat: 49.2526452, lng: 19.3823196 },
    { lat: 49.2526983, lng: 19.3824657 },
    { lat: 49.2527303, lng: 19.3825488 },
    { lat: 49.2527119, lng: 19.382566 },
    { lat: 49.2527813, lng: 19.3827514 },
    { lat: 49.2528083, lng: 19.3828188 },
    { lat: 49.2528827, lng: 19.3830178 },
    { lat: 49.2529393, lng: 19.3831667 },
    { lat: 49.2529854, lng: 19.3833565 },
    { lat: 49.253033, lng: 19.3835569 },
    { lat: 49.2530666, lng: 19.3836936 },
    { lat: 49.2530701, lng: 19.3837953 },
    { lat: 49.2530652, lng: 19.3839066 },
    { lat: 49.2530118, lng: 19.3840181 },
    { lat: 49.252968, lng: 19.3840957 },
    { lat: 49.2528864, lng: 19.3842305 },
    { lat: 49.252821, lng: 19.3842766 },
    { lat: 49.2527522, lng: 19.3843234 },
  ],
  Istebné: [
    { lat: 49.2446806, lng: 19.1871036 },
    { lat: 49.2444874, lng: 19.1870742 },
    { lat: 49.2441151, lng: 19.1869928 },
    { lat: 49.2439279, lng: 19.186971 },
    { lat: 49.2436834, lng: 19.1869463 },
    { lat: 49.2433274, lng: 19.1870416 },
    { lat: 49.2431477, lng: 19.1871002 },
    { lat: 49.2419554, lng: 19.186572 },
    { lat: 49.2418821, lng: 19.1865951 },
    { lat: 49.2416968, lng: 19.1866553 },
    { lat: 49.2415921, lng: 19.186727 },
    { lat: 49.2413272, lng: 19.1869095 },
    { lat: 49.240361, lng: 19.1875805 },
    { lat: 49.2399209, lng: 19.1878025 },
    { lat: 49.2392395, lng: 19.1879288 },
    { lat: 49.239155, lng: 19.1879788 },
    { lat: 49.2385445, lng: 19.1883358 },
    { lat: 49.2374975, lng: 19.1883111 },
    { lat: 49.2367435, lng: 19.1882897 },
    { lat: 49.2364209, lng: 19.1883088 },
    { lat: 49.236054, lng: 19.1883664 },
    { lat: 49.2354017, lng: 19.1883462 },
    { lat: 49.2352782, lng: 19.1883132 },
    { lat: 49.2350893, lng: 19.188319 },
    { lat: 49.2347474, lng: 19.1882667 },
    { lat: 49.2345288, lng: 19.1880847 },
    { lat: 49.2341049, lng: 19.1877524 },
    { lat: 49.2338179, lng: 19.1875269 },
    { lat: 49.2333561, lng: 19.186906 },
    { lat: 49.2332255, lng: 19.1867927 },
    { lat: 49.2329313, lng: 19.1865373 },
    { lat: 49.2324607, lng: 19.1866178 },
    { lat: 49.2316829, lng: 19.1867088 },
    { lat: 49.2312929, lng: 19.1865656 },
    { lat: 49.2300251, lng: 19.1861962 },
    { lat: 49.2297721, lng: 19.186048 },
    { lat: 49.2291229, lng: 19.1856733 },
    { lat: 49.2282863, lng: 19.1847605 },
    { lat: 49.2282696, lng: 19.1847426 },
    { lat: 49.2273226, lng: 19.1837347 },
    { lat: 49.2271872, lng: 19.1838261 },
    { lat: 49.2269762, lng: 19.183935 },
    { lat: 49.2263869, lng: 19.1840077 },
    { lat: 49.2260881, lng: 19.1840324 },
    { lat: 49.2256963, lng: 19.184271 },
    { lat: 49.225154, lng: 19.1847591 },
    { lat: 49.2248079, lng: 19.1847567 },
    { lat: 49.2243534, lng: 19.1849857 },
    { lat: 49.2237202, lng: 19.1848477 },
    { lat: 49.2231771, lng: 19.1848509 },
    { lat: 49.2222198, lng: 19.1845868 },
    { lat: 49.2214893, lng: 19.1844457 },
    { lat: 49.2212886, lng: 19.1846518 },
    { lat: 49.2211051, lng: 19.1851254 },
    { lat: 49.221071, lng: 19.1852111 },
    { lat: 49.2208348, lng: 19.1858258 },
    { lat: 49.220558, lng: 19.1865566 },
    { lat: 49.2203666, lng: 19.1870718 },
    { lat: 49.2198848, lng: 19.1874337 },
    { lat: 49.2193669, lng: 19.1878198 },
    { lat: 49.2189305, lng: 19.1882808 },
    { lat: 49.2184696, lng: 19.1889825 },
    { lat: 49.2179082, lng: 19.1896796 },
    { lat: 49.2173941, lng: 19.1925429 },
    { lat: 49.2169227, lng: 19.193961 },
    { lat: 49.2165688, lng: 19.1949848 },
    { lat: 49.2163121, lng: 19.1951316 },
    { lat: 49.2161439, lng: 19.1965977 },
    { lat: 49.2157098, lng: 19.1973168 },
    { lat: 49.2155999, lng: 19.1983329 },
    { lat: 49.2150714, lng: 19.1994534 },
    { lat: 49.2147393, lng: 19.2000683 },
    { lat: 49.214697, lng: 19.2002209 },
    { lat: 49.214625, lng: 19.2004997 },
    { lat: 49.214513, lng: 19.2009136 },
    { lat: 49.2144732, lng: 19.2010677 },
    { lat: 49.2143476, lng: 19.2013378 },
    { lat: 49.2142895, lng: 19.2014658 },
    { lat: 49.2142331, lng: 19.2015839 },
    { lat: 49.2141505, lng: 19.2017612 },
    { lat: 49.2139657, lng: 19.2021605 },
    { lat: 49.2138638, lng: 19.202384 },
    { lat: 49.2138138, lng: 19.2024927 },
    { lat: 49.2135301, lng: 19.2031288 },
    { lat: 49.2133927, lng: 19.2034374 },
    { lat: 49.213347, lng: 19.2035171 },
    { lat: 49.2131675, lng: 19.2038355 },
    { lat: 49.2131403, lng: 19.2039048 },
    { lat: 49.2131149, lng: 19.2039834 },
    { lat: 49.2130929, lng: 19.2040674 },
    { lat: 49.2131026, lng: 19.2041755 },
    { lat: 49.2130839, lng: 19.2042281 },
    { lat: 49.2130447, lng: 19.2043507 },
    { lat: 49.2130009, lng: 19.2044519 },
    { lat: 49.2129853, lng: 19.2044501 },
    { lat: 49.2129346, lng: 19.2045217 },
    { lat: 49.2128901, lng: 19.2045833 },
    { lat: 49.2128079, lng: 19.204656 },
    { lat: 49.2126936, lng: 19.2048084 },
    { lat: 49.2126399, lng: 19.2050015 },
    { lat: 49.2125999, lng: 19.205107 },
    { lat: 49.2125825, lng: 19.2051925 },
    { lat: 49.2125401, lng: 19.2053621 },
    { lat: 49.2124974, lng: 19.205484 },
    { lat: 49.2123662, lng: 19.2059865 },
    { lat: 49.2122246, lng: 19.2062281 },
    { lat: 49.2121595, lng: 19.2064452 },
    { lat: 49.2120755, lng: 19.2066685 },
    { lat: 49.2120513, lng: 19.2067981 },
    { lat: 49.2120278, lng: 19.2068696 },
    { lat: 49.2118881, lng: 19.2072678 },
    { lat: 49.2117018, lng: 19.2075187 },
    { lat: 49.211764, lng: 19.2075452 },
    { lat: 49.2117985, lng: 19.2075597 },
    { lat: 49.2115877, lng: 19.2082139 },
    { lat: 49.211589, lng: 19.2082891 },
    { lat: 49.2115962, lng: 19.2084141 },
    { lat: 49.2116006, lng: 19.2085308 },
    { lat: 49.211658, lng: 19.2098831 },
    { lat: 49.2116677, lng: 19.2101076 },
    { lat: 49.2116924, lng: 19.2104098 },
    { lat: 49.2117352, lng: 19.2108773 },
    { lat: 49.2117462, lng: 19.2109193 },
    { lat: 49.2117479, lng: 19.2109285 },
    { lat: 49.2115227, lng: 19.2110497 },
    { lat: 49.2114955, lng: 19.2110495 },
    { lat: 49.2112782, lng: 19.2110786 },
    { lat: 49.2112571, lng: 19.2110604 },
    { lat: 49.2112018, lng: 19.2110796 },
    { lat: 49.2111674, lng: 19.211091 },
    { lat: 49.2111229, lng: 19.2111281 },
    { lat: 49.2110374, lng: 19.2111532 },
    { lat: 49.2110051, lng: 19.2111828 },
    { lat: 49.2109739, lng: 19.2111682 },
    { lat: 49.2109317, lng: 19.2111856 },
    { lat: 49.210827, lng: 19.2112572 },
    { lat: 49.2107816, lng: 19.21132 },
    { lat: 49.2106424, lng: 19.2114261 },
    { lat: 49.2105231, lng: 19.2114986 },
    { lat: 49.2104528, lng: 19.211744 },
    { lat: 49.2103574, lng: 19.2117789 },
    { lat: 49.210178, lng: 19.2120305 },
    { lat: 49.2100429, lng: 19.2123394 },
    { lat: 49.2100176, lng: 19.2125164 },
    { lat: 49.2099019, lng: 19.2126891 },
    { lat: 49.2096506, lng: 19.2130608 },
    { lat: 49.2095172, lng: 19.2130765 },
    { lat: 49.2093846, lng: 19.2131325 },
    { lat: 49.2092642, lng: 19.2131824 },
    { lat: 49.209147, lng: 19.2132302 },
    { lat: 49.2090784, lng: 19.2133009 },
    { lat: 49.2089599, lng: 19.2133236 },
    { lat: 49.2088761, lng: 19.2133882 },
    { lat: 49.2088738, lng: 19.2134335 },
    { lat: 49.2088395, lng: 19.2134456 },
    { lat: 49.2087338, lng: 19.2133803 },
    { lat: 49.2086117, lng: 19.2134189 },
    { lat: 49.2085769, lng: 19.2133972 },
    { lat: 49.2085321, lng: 19.213474 },
    { lat: 49.2084126, lng: 19.2135227 },
    { lat: 49.2082554, lng: 19.2136957 },
    { lat: 49.2079387, lng: 19.2140496 },
    { lat: 49.2079117, lng: 19.2142339 },
    { lat: 49.207852, lng: 19.2146566 },
    { lat: 49.2077418, lng: 19.2150114 },
    { lat: 49.2076814, lng: 19.2151396 },
    { lat: 49.2076193, lng: 19.2152292 },
    { lat: 49.207606, lng: 19.2153294 },
    { lat: 49.2075692, lng: 19.2154107 },
    { lat: 49.2075317, lng: 19.2154491 },
    { lat: 49.2074578, lng: 19.2154614 },
    { lat: 49.2073509, lng: 19.2156042 },
    { lat: 49.2072852, lng: 19.215829 },
    { lat: 49.2071967, lng: 19.2159095 },
    { lat: 49.2071982, lng: 19.2159404 },
    { lat: 49.207106, lng: 19.2159925 },
    { lat: 49.2070666, lng: 19.216042 },
    { lat: 49.2070743, lng: 19.2161085 },
    { lat: 49.2068294, lng: 19.2163105 },
    { lat: 49.2068094, lng: 19.2162929 },
    { lat: 49.2067798, lng: 19.2163596 },
    { lat: 49.2067931, lng: 19.2164007 },
    { lat: 49.2067207, lng: 19.2164914 },
    { lat: 49.2065809, lng: 19.2165589 },
    { lat: 49.2065413, lng: 19.2165313 },
    { lat: 49.2064435, lng: 19.2165862 },
    { lat: 49.2064285, lng: 19.2166229 },
    { lat: 49.2063946, lng: 19.2166191 },
    { lat: 49.2063832, lng: 19.2166662 },
    { lat: 49.2061897, lng: 19.21672 },
    { lat: 49.2061717, lng: 19.2167678 },
    { lat: 49.2061242, lng: 19.2167871 },
    { lat: 49.2060112, lng: 19.2167598 },
    { lat: 49.2060179, lng: 19.2168012 },
    { lat: 49.2059302, lng: 19.2169268 },
    { lat: 49.2058393, lng: 19.2169571 },
    { lat: 49.2058061, lng: 19.2170171 },
    { lat: 49.2057677, lng: 19.2170149 },
    { lat: 49.2057532, lng: 19.2171117 },
    { lat: 49.2055801, lng: 19.2171214 },
    { lat: 49.205532, lng: 19.2172205 },
    { lat: 49.2054195, lng: 19.2172252 },
    { lat: 49.205356, lng: 19.2173581 },
    { lat: 49.2053042, lng: 19.2173354 },
    { lat: 49.2052998, lng: 19.2172689 },
    { lat: 49.2051728, lng: 19.2173931 },
    { lat: 49.2049842, lng: 19.2174095 },
    { lat: 49.204951, lng: 19.2174659 },
    { lat: 49.2049076, lng: 19.217434 },
    { lat: 49.2047142, lng: 19.2176539 },
    { lat: 49.2046973, lng: 19.2177448 },
    { lat: 49.2046519, lng: 19.217738 },
    { lat: 49.2046025, lng: 19.2178113 },
    { lat: 49.2045075, lng: 19.2178061 },
    { lat: 49.2044323, lng: 19.2178812 },
    { lat: 49.2043964, lng: 19.2178434 },
    { lat: 49.2044207, lng: 19.217734 },
    { lat: 49.2043553, lng: 19.2177107 },
    { lat: 49.2042598, lng: 19.2178123 },
    { lat: 49.2042692, lng: 19.217888 },
    { lat: 49.2042509, lng: 19.2179311 },
    { lat: 49.2041101, lng: 19.2179109 },
    { lat: 49.2040769, lng: 19.2178544 },
    { lat: 49.2039617, lng: 19.2179632 },
    { lat: 49.2038498, lng: 19.2179105 },
    { lat: 49.2037309, lng: 19.2179224 },
    { lat: 49.2036181, lng: 19.2180623 },
    { lat: 49.203568, lng: 19.2180754 },
    { lat: 49.2035064, lng: 19.2181541 },
    { lat: 49.2034683, lng: 19.2181829 },
    { lat: 49.2034073, lng: 19.2181562 },
    { lat: 49.2033249, lng: 19.2182213 },
    { lat: 49.2031686, lng: 19.2181339 },
    { lat: 49.2031354, lng: 19.2180778 },
    { lat: 49.2030505, lng: 19.2178581 },
    { lat: 49.202863, lng: 19.2173594 },
    { lat: 49.2023341, lng: 19.217259 },
    { lat: 49.2018885, lng: 19.217181 },
    { lat: 49.2016063, lng: 19.2173583 },
    { lat: 49.2016043, lng: 19.2174101 },
    { lat: 49.2015244, lng: 19.2173704 },
    { lat: 49.2015287, lng: 19.2173162 },
    { lat: 49.2014802, lng: 19.2173144 },
    { lat: 49.2014451, lng: 19.2173126 },
    { lat: 49.2014113, lng: 19.2173113 },
    { lat: 49.201033, lng: 19.2172975 },
    { lat: 49.2009764, lng: 19.2179036 },
    { lat: 49.2009283, lng: 19.2193373 },
    { lat: 49.2009324, lng: 19.2202173 },
    { lat: 49.2008514, lng: 19.2200804 },
    { lat: 49.2008052, lng: 19.2209023 },
    { lat: 49.200712, lng: 19.2211632 },
    { lat: 49.2006393, lng: 19.2211913 },
    { lat: 49.1999572, lng: 19.2214543 },
    { lat: 49.1999129, lng: 19.2214715 },
    { lat: 49.1996574, lng: 19.2215701 },
    { lat: 49.1995234, lng: 19.2216215 },
    { lat: 49.1994603, lng: 19.2216459 },
    { lat: 49.1993466, lng: 19.221668 },
    { lat: 49.1992549, lng: 19.2216862 },
    { lat: 49.199161, lng: 19.2217043 },
    { lat: 49.1990886, lng: 19.2217207 },
    { lat: 49.1990431, lng: 19.2217308 },
    { lat: 49.1986438, lng: 19.2218217 },
    { lat: 49.1983964, lng: 19.2218775 },
    { lat: 49.1982723, lng: 19.2218518 },
    { lat: 49.1975928, lng: 19.2220183 },
    { lat: 49.1970714, lng: 19.2223099 },
    { lat: 49.1968562, lng: 19.2225676 },
    { lat: 49.1967262, lng: 19.222723 },
    { lat: 49.1967208, lng: 19.2227298 },
    { lat: 49.196677, lng: 19.2227815 },
    { lat: 49.1961795, lng: 19.2233767 },
    { lat: 49.1959309, lng: 19.2238597 },
    { lat: 49.1956788, lng: 19.2245903 },
    { lat: 49.1956347, lng: 19.2248461 },
    { lat: 49.1955978, lng: 19.2253904 },
    { lat: 49.1955971, lng: 19.2254496 },
    { lat: 49.1955991, lng: 19.2257957 },
    { lat: 49.1955997, lng: 19.225827 },
    { lat: 49.195599, lng: 19.2259568 },
    { lat: 49.1956001, lng: 19.2261664 },
    { lat: 49.1956524, lng: 19.2265263 },
    { lat: 49.195666, lng: 19.2266218 },
    { lat: 49.1957649, lng: 19.2269466 },
    { lat: 49.1960448, lng: 19.22733 },
    { lat: 49.1962946, lng: 19.2277414 },
    { lat: 49.1965218, lng: 19.2281095 },
    { lat: 49.1967561, lng: 19.2284577 },
    { lat: 49.1967901, lng: 19.2285069 },
    { lat: 49.1970869, lng: 19.2289133 },
    { lat: 49.1973989, lng: 19.2293066 },
    { lat: 49.1978737, lng: 19.2297367 },
    { lat: 49.198273, lng: 19.2300496 },
    { lat: 49.1986879, lng: 19.2303333 },
    { lat: 49.199022, lng: 19.2305082 },
    { lat: 49.1994307, lng: 19.2307129 },
    { lat: 49.199756, lng: 19.2308665 },
    { lat: 49.2000658, lng: 19.231003 },
    { lat: 49.2005406, lng: 19.2311528 },
    { lat: 49.2006305, lng: 19.2311907 },
    { lat: 49.2010172, lng: 19.2313341 },
    { lat: 49.2010838, lng: 19.2313591 },
    { lat: 49.2010987, lng: 19.2313654 },
    { lat: 49.2012122, lng: 19.2314082 },
    { lat: 49.2013442, lng: 19.2314313 },
    { lat: 49.2019517, lng: 19.2315511 },
    { lat: 49.2023119, lng: 19.231517 },
    { lat: 49.2027894, lng: 19.231629 },
    { lat: 49.2029688, lng: 19.2316774 },
    { lat: 49.2029485, lng: 19.2317943 },
    { lat: 49.2032832, lng: 19.2319576 },
    { lat: 49.203469, lng: 19.2320734 },
    { lat: 49.2041474, lng: 19.2326561 },
    { lat: 49.2045084, lng: 19.2330385 },
    { lat: 49.2049946, lng: 19.2337991 },
    { lat: 49.2051331, lng: 19.2340582 },
    { lat: 49.2053188, lng: 19.2344026 },
    { lat: 49.2053894, lng: 19.2346049 },
    { lat: 49.2055828, lng: 19.2347609 },
    { lat: 49.2056654, lng: 19.2349579 },
    { lat: 49.205679, lng: 19.2350047 },
    { lat: 49.2056764, lng: 19.2350428 },
    { lat: 49.205695, lng: 19.2350573 },
    { lat: 49.2058174, lng: 19.2349996 },
    { lat: 49.2058361, lng: 19.2349907 },
    { lat: 49.205856, lng: 19.2349816 },
    { lat: 49.2058879, lng: 19.2349665 },
    { lat: 49.2059165, lng: 19.2349529 },
    { lat: 49.2059746, lng: 19.2348948 },
    { lat: 49.2061025, lng: 19.2347151 },
    { lat: 49.2061806, lng: 19.2346054 },
    { lat: 49.2063111, lng: 19.2344362 },
    { lat: 49.2063322, lng: 19.2344086 },
    { lat: 49.2063429, lng: 19.2343952 },
    { lat: 49.2063483, lng: 19.2343881 },
    { lat: 49.2064002, lng: 19.2343181 },
    { lat: 49.2064106, lng: 19.2343036 },
    { lat: 49.2064393, lng: 19.2342665 },
    { lat: 49.2064539, lng: 19.2342919 },
    { lat: 49.2069508, lng: 19.2340745 },
    { lat: 49.2073144, lng: 19.2338555 },
    { lat: 49.2074196, lng: 19.2337684 },
    { lat: 49.207474, lng: 19.2337273 },
    { lat: 49.2075227, lng: 19.2336884 },
    { lat: 49.2075875, lng: 19.2336757 },
    { lat: 49.2076962, lng: 19.2336124 },
    { lat: 49.2077855, lng: 19.2335256 },
    { lat: 49.2078931, lng: 19.2334436 },
    { lat: 49.208005, lng: 19.2334034 },
    { lat: 49.2082313, lng: 19.2333507 },
    { lat: 49.2084061, lng: 19.2332818 },
    { lat: 49.2085034, lng: 19.233149 },
    { lat: 49.2086662, lng: 19.2330432 },
    { lat: 49.2088259, lng: 19.2328722 },
    { lat: 49.2090486, lng: 19.2326058 },
    { lat: 49.2091275, lng: 19.2324398 },
    { lat: 49.2094526, lng: 19.2318158 },
    { lat: 49.2094579, lng: 19.2318098 },
    { lat: 49.2094483, lng: 19.2317713 },
    { lat: 49.2095144, lng: 19.231673 },
    { lat: 49.2095619, lng: 19.231605 },
    { lat: 49.2097875, lng: 19.2312802 },
    { lat: 49.2097934, lng: 19.231285 },
    { lat: 49.2098187, lng: 19.2312266 },
    { lat: 49.2098746, lng: 19.2310971 },
    { lat: 49.2098877, lng: 19.231066 },
    { lat: 49.2099994, lng: 19.2307662 },
    { lat: 49.2100185, lng: 19.2307158 },
    { lat: 49.2100276, lng: 19.2306932 },
    { lat: 49.2100323, lng: 19.2306732 },
    { lat: 49.2100623, lng: 19.2305451 },
    { lat: 49.2101869, lng: 19.2303271 },
    { lat: 49.2103188, lng: 19.2300943 },
    { lat: 49.2103212, lng: 19.2300954 },
    { lat: 49.2103341, lng: 19.230107 },
    { lat: 49.2103717, lng: 19.2301385 },
    { lat: 49.2104374, lng: 19.2301928 },
    { lat: 49.210448, lng: 19.2302017 },
    { lat: 49.2104515, lng: 19.2302009 },
    { lat: 49.2104559, lng: 19.2301993 },
    { lat: 49.2105347, lng: 19.2301783 },
    { lat: 49.2106037, lng: 19.2301597 },
    { lat: 49.2109642, lng: 19.2301316 },
    { lat: 49.2110022, lng: 19.2301461 },
    { lat: 49.2110229, lng: 19.2301539 },
    { lat: 49.2114991, lng: 19.2303321 },
    { lat: 49.2119056, lng: 19.2304174 },
    { lat: 49.2119292, lng: 19.2304166 },
    { lat: 49.2120732, lng: 19.2304099 },
    { lat: 49.2122756, lng: 19.2304006 },
    { lat: 49.2125505, lng: 19.2298839 },
    { lat: 49.2129426, lng: 19.2300469 },
    { lat: 49.2130024, lng: 19.2300481 },
    { lat: 49.2130731, lng: 19.2300658 },
    { lat: 49.213696, lng: 19.2302204 },
    { lat: 49.2139787, lng: 19.2302634 },
    { lat: 49.2142144, lng: 19.2302701 },
    { lat: 49.2144163, lng: 19.2308092 },
    { lat: 49.214655, lng: 19.2307587 },
    { lat: 49.2148398, lng: 19.23083 },
    { lat: 49.2148836, lng: 19.2308467 },
    { lat: 49.2152017, lng: 19.2309694 },
    { lat: 49.2154738, lng: 19.2310745 },
    { lat: 49.2157835, lng: 19.2311887 },
    { lat: 49.2163709, lng: 19.2314046 },
    { lat: 49.216418, lng: 19.2314217 },
    { lat: 49.2164971, lng: 19.2314507 },
    { lat: 49.216504, lng: 19.2314535 },
    { lat: 49.2165376, lng: 19.2314497 },
    { lat: 49.2166229, lng: 19.2314398 },
    { lat: 49.2167831, lng: 19.2314212 },
    { lat: 49.2168716, lng: 19.2314113 },
    { lat: 49.2169165, lng: 19.2314059 },
    { lat: 49.2169569, lng: 19.2314014 },
    { lat: 49.2170018, lng: 19.2313964 },
    { lat: 49.2170443, lng: 19.2313913 },
    { lat: 49.217134, lng: 19.2313812 },
    { lat: 49.2171573, lng: 19.2313739 },
    { lat: 49.2178128, lng: 19.2311755 },
    { lat: 49.2179513, lng: 19.2312912 },
    { lat: 49.2179841, lng: 19.2313189 },
    { lat: 49.2180992, lng: 19.2314142 },
    { lat: 49.2181297, lng: 19.2313672 },
    { lat: 49.2181559, lng: 19.2313275 },
    { lat: 49.218181, lng: 19.2312886 },
    { lat: 49.218201, lng: 19.2312572 },
    { lat: 49.2182082, lng: 19.2312463 },
    { lat: 49.2182199, lng: 19.2312287 },
    { lat: 49.2182252, lng: 19.2312198 },
    { lat: 49.2182535, lng: 19.2311763 },
    { lat: 49.2182767, lng: 19.231142 },
    { lat: 49.2182912, lng: 19.2311187 },
    { lat: 49.2183059, lng: 19.2310958 },
    { lat: 49.2183206, lng: 19.2310725 },
    { lat: 49.2183437, lng: 19.2310379 },
    { lat: 49.2183971, lng: 19.2309551 },
    { lat: 49.2184433, lng: 19.2308847 },
    { lat: 49.2184959, lng: 19.2308034 },
    { lat: 49.2185484, lng: 19.2307222 },
    { lat: 49.2185674, lng: 19.2306934 },
    { lat: 49.218605, lng: 19.2306351 },
    { lat: 49.21871, lng: 19.2304726 },
    { lat: 49.218793, lng: 19.2303462 },
    { lat: 49.2188075, lng: 19.2303431 },
    { lat: 49.2188776, lng: 19.2303284 },
    { lat: 49.2190262, lng: 19.2302981 },
    { lat: 49.219101, lng: 19.2302825 },
    { lat: 49.2191779, lng: 19.2302671 },
    { lat: 49.2193486, lng: 19.2302314 },
    { lat: 49.2194827, lng: 19.2302041 },
    { lat: 49.2198029, lng: 19.2302083 },
    { lat: 49.2199607, lng: 19.2302101 },
    { lat: 49.2201637, lng: 19.2302126 },
    { lat: 49.2201784, lng: 19.2302131 },
    { lat: 49.2209713, lng: 19.2302257 },
    { lat: 49.2213, lng: 19.2297949 },
    { lat: 49.2213393, lng: 19.2297447 },
    { lat: 49.2213607, lng: 19.2297214 },
    { lat: 49.2214751, lng: 19.2295672 },
    { lat: 49.2215897, lng: 19.2294891 },
    { lat: 49.2216354, lng: 19.2294573 },
    { lat: 49.2219985, lng: 19.2292487 },
    { lat: 49.2226257, lng: 19.2288633 },
    { lat: 49.2230724, lng: 19.2285905 },
    { lat: 49.2239957, lng: 19.2274465 },
    { lat: 49.2247405, lng: 19.2264995 },
    { lat: 49.2247946, lng: 19.2264311 },
    { lat: 49.2248419, lng: 19.2263843 },
    { lat: 49.2249012, lng: 19.2263261 },
    { lat: 49.2249839, lng: 19.2262436 },
    { lat: 49.2250667, lng: 19.2261612 },
    { lat: 49.2251194, lng: 19.2261091 },
    { lat: 49.2251947, lng: 19.226034 },
    { lat: 49.2252486, lng: 19.225981 },
    { lat: 49.2252754, lng: 19.2259546 },
    { lat: 49.2253025, lng: 19.2259278 },
    { lat: 49.2253282, lng: 19.2259022 },
    { lat: 49.2253555, lng: 19.2258858 },
    { lat: 49.225383, lng: 19.2258693 },
    { lat: 49.2254092, lng: 19.2258534 },
    { lat: 49.2255199, lng: 19.2257873 },
    { lat: 49.2255518, lng: 19.2257679 },
    { lat: 49.2256415, lng: 19.2257138 },
    { lat: 49.2256842, lng: 19.2256881 },
    { lat: 49.2257269, lng: 19.2256621 },
    { lat: 49.2257489, lng: 19.2256495 },
    { lat: 49.2257707, lng: 19.2256359 },
    { lat: 49.2257937, lng: 19.2256225 },
    { lat: 49.2258146, lng: 19.2256093 },
    { lat: 49.2258584, lng: 19.2255832 },
    { lat: 49.2259023, lng: 19.2255573 },
    { lat: 49.2260336, lng: 19.2254781 },
    { lat: 49.2261201, lng: 19.2254258 },
    { lat: 49.2261619, lng: 19.2254006 },
    { lat: 49.2262057, lng: 19.2253741 },
    { lat: 49.2262362, lng: 19.2253559 },
    { lat: 49.2262518, lng: 19.2253509 },
    { lat: 49.2262651, lng: 19.2253465 },
    { lat: 49.226293, lng: 19.2253376 },
    { lat: 49.2263715, lng: 19.2253115 },
    { lat: 49.226396, lng: 19.2253037 },
    { lat: 49.2264616, lng: 19.2252827 },
    { lat: 49.2264971, lng: 19.2252707 },
    { lat: 49.2265095, lng: 19.225273 },
    { lat: 49.2266153, lng: 19.2252932 },
    { lat: 49.2266687, lng: 19.2253034 },
    { lat: 49.2267631, lng: 19.225321 },
    { lat: 49.2267973, lng: 19.225324 },
    { lat: 49.2268402, lng: 19.2253275 },
    { lat: 49.2269679, lng: 19.225337 },
    { lat: 49.2270675, lng: 19.2253439 },
    { lat: 49.2270935, lng: 19.2253456 },
    { lat: 49.2271445, lng: 19.2253493 },
    { lat: 49.2271703, lng: 19.2253511 },
    { lat: 49.2272225, lng: 19.2253554 },
    { lat: 49.2273265, lng: 19.2253628 },
    { lat: 49.2273796, lng: 19.225367 },
    { lat: 49.2274078, lng: 19.2253685 },
    { lat: 49.2274339, lng: 19.2253706 },
    { lat: 49.2274611, lng: 19.2253723 },
    { lat: 49.2274881, lng: 19.2253739 },
    { lat: 49.2275142, lng: 19.2253768 },
    { lat: 49.2275403, lng: 19.2253782 },
    { lat: 49.2275673, lng: 19.2253798 },
    { lat: 49.2275945, lng: 19.2253818 },
    { lat: 49.2276239, lng: 19.2253843 },
    { lat: 49.2276542, lng: 19.2253813 },
    { lat: 49.2277102, lng: 19.225375 },
    { lat: 49.2277943, lng: 19.2253659 },
    { lat: 49.2278504, lng: 19.2253597 },
    { lat: 49.2279357, lng: 19.2253501 },
    { lat: 49.2279637, lng: 19.2253473 },
    { lat: 49.2280431, lng: 19.2253345 },
    { lat: 49.2280955, lng: 19.225325 },
    { lat: 49.2281471, lng: 19.2253163 },
    { lat: 49.2282118, lng: 19.2253055 },
    { lat: 49.2282766, lng: 19.2252942 },
    { lat: 49.228308, lng: 19.2252893 },
    { lat: 49.2287368, lng: 19.2252088 },
    { lat: 49.2299922, lng: 19.225344 },
    { lat: 49.2300516, lng: 19.2253161 },
    { lat: 49.2301036, lng: 19.2252926 },
    { lat: 49.2302094, lng: 19.225244 },
    { lat: 49.2302933, lng: 19.2252057 },
    { lat: 49.2303439, lng: 19.225182 },
    { lat: 49.2304123, lng: 19.2251505 },
    { lat: 49.2304477, lng: 19.2251347 },
    { lat: 49.2304818, lng: 19.2251186 },
    { lat: 49.2305181, lng: 19.2251019 },
    { lat: 49.2305899, lng: 19.2250715 },
    { lat: 49.2306707, lng: 19.2250192 },
    { lat: 49.2307101, lng: 19.2249942 },
    { lat: 49.230755, lng: 19.224965 },
    { lat: 49.2307768, lng: 19.224951 },
    { lat: 49.2308992, lng: 19.2248926 },
    { lat: 49.2310633, lng: 19.224814 },
    { lat: 49.2312406, lng: 19.2247296 },
    { lat: 49.2316627, lng: 19.2245283 },
    { lat: 49.2320074, lng: 19.2246884 },
    { lat: 49.232188, lng: 19.2245985 },
    { lat: 49.2325533, lng: 19.2244162 },
    { lat: 49.2326857, lng: 19.224334 },
    { lat: 49.2328822, lng: 19.2236624 },
    { lat: 49.2329512, lng: 19.2224494 },
    { lat: 49.2330487, lng: 19.221966 },
    { lat: 49.2331665, lng: 19.2214411 },
    { lat: 49.2334686, lng: 19.2210177 },
    { lat: 49.2336096, lng: 19.2207624 },
    { lat: 49.2338207, lng: 19.2204881 },
    { lat: 49.2339352, lng: 19.2203397 },
    { lat: 49.2341691, lng: 19.220096 },
    { lat: 49.2344296, lng: 19.2200022 },
    { lat: 49.2352273, lng: 19.2187579 },
    { lat: 49.2356046, lng: 19.217789 },
    { lat: 49.235724, lng: 19.217481 },
    { lat: 49.2359806, lng: 19.2168214 },
    { lat: 49.2358271, lng: 19.215854 },
    { lat: 49.2361396, lng: 19.2149973 },
    { lat: 49.2371828, lng: 19.2147529 },
    { lat: 49.2375897, lng: 19.2149369 },
    { lat: 49.2380064, lng: 19.2147917 },
    { lat: 49.2383207, lng: 19.2143196 },
    { lat: 49.2386064, lng: 19.2139795 },
    { lat: 49.2386375, lng: 19.2127778 },
    { lat: 49.2386802, lng: 19.2125656 },
    { lat: 49.2389023, lng: 19.2124249 },
    { lat: 49.2398682, lng: 19.2121447 },
    { lat: 49.2402979, lng: 19.2123872 },
    { lat: 49.2406886, lng: 19.2127502 },
    { lat: 49.2409185, lng: 19.212865 },
    { lat: 49.2410629, lng: 19.2128719 },
    { lat: 49.2410811, lng: 19.2128724 },
    { lat: 49.2412946, lng: 19.2128823 },
    { lat: 49.242047, lng: 19.2131564 },
    { lat: 49.2420582, lng: 19.2132471 },
    { lat: 49.2424515, lng: 19.2133663 },
    { lat: 49.2427405, lng: 19.213257 },
    { lat: 49.2431454, lng: 19.2131697 },
    { lat: 49.2434359, lng: 19.2131173 },
    { lat: 49.2435738, lng: 19.2131696 },
    { lat: 49.2437169, lng: 19.2130323 },
    { lat: 49.2441001, lng: 19.2124706 },
    { lat: 49.2444296, lng: 19.2114217 },
    { lat: 49.2444979, lng: 19.211084 },
    { lat: 49.2446221, lng: 19.2103677 },
    { lat: 49.2447348, lng: 19.2099214 },
    { lat: 49.2448987, lng: 19.2093936 },
    { lat: 49.245158, lng: 19.2088587 },
    { lat: 49.2452229, lng: 19.2086108 },
    { lat: 49.2453972, lng: 19.2076208 },
    { lat: 49.2452675, lng: 19.207318 },
    { lat: 49.2452611, lng: 19.2073024 },
    { lat: 49.2452332, lng: 19.2072378 },
    { lat: 49.2452194, lng: 19.2072058 },
    { lat: 49.2451152, lng: 19.2069606 },
    { lat: 49.2451089, lng: 19.2069462 },
    { lat: 49.2450605, lng: 19.2068344 },
    { lat: 49.2450516, lng: 19.2068123 },
    { lat: 49.2450352, lng: 19.2067756 },
    { lat: 49.2448408, lng: 19.2063198 },
    { lat: 49.2449019, lng: 19.2051063 },
    { lat: 49.2449113, lng: 19.2049981 },
    { lat: 49.2450169, lng: 19.2038733 },
    { lat: 49.2451044, lng: 19.2034183 },
    { lat: 49.245143, lng: 19.2020944 },
    { lat: 49.2457239, lng: 19.2006286 },
    { lat: 49.2458712, lng: 19.2004804 },
    { lat: 49.2462701, lng: 19.1993832 },
    { lat: 49.2463252, lng: 19.1988672 },
    { lat: 49.2464186, lng: 19.1985594 },
    { lat: 49.2466209, lng: 19.198011 },
    { lat: 49.2467924, lng: 19.1975267 },
    { lat: 49.2470088, lng: 19.1970341 },
    { lat: 49.2473178, lng: 19.1963348 },
    { lat: 49.2483214, lng: 19.1953685 },
    { lat: 49.2488829, lng: 19.1945786 },
    { lat: 49.248364, lng: 19.1934736 },
    { lat: 49.2478511, lng: 19.1924917 },
    { lat: 49.2463835, lng: 19.1900637 },
    { lat: 49.2459664, lng: 19.1890336 },
    { lat: 49.2457543, lng: 19.1885885 },
    { lat: 49.2446806, lng: 19.1871036 },
  ],
  Jasenová: [
    { lat: 49.151346, lng: 19.273237 },
    { lat: 49.151382, lng: 19.275046 },
    { lat: 49.151292, lng: 19.275456 },
    { lat: 49.15128, lng: 19.275496 },
    { lat: 49.151157, lng: 19.276169 },
    { lat: 49.150992, lng: 19.277041 },
    { lat: 49.150938, lng: 19.277283 },
    { lat: 49.150881, lng: 19.277586 },
    { lat: 49.150481, lng: 19.278783 },
    { lat: 49.150366, lng: 19.279463 },
    { lat: 49.150109, lng: 19.279847 },
    { lat: 49.151179, lng: 19.281052 },
    { lat: 49.151802, lng: 19.28176 },
    { lat: 49.151974, lng: 19.281974 },
    { lat: 49.152227, lng: 19.282827 },
    { lat: 49.151655, lng: 19.28313 },
    { lat: 49.151162, lng: 19.283335 },
    { lat: 49.151283, lng: 19.284485 },
    { lat: 49.151345, lng: 19.285092 },
    { lat: 49.151328, lng: 19.285178 },
    { lat: 49.151308, lng: 19.28527 },
    { lat: 49.151368, lng: 19.285636 },
    { lat: 49.1514, lng: 19.285846 },
    { lat: 49.15143, lng: 19.28603 },
    { lat: 49.152575, lng: 19.287945 },
    { lat: 49.152541, lng: 19.289796 },
    { lat: 49.152669, lng: 19.29165 },
    { lat: 49.152826, lng: 19.293026 },
    { lat: 49.152848, lng: 19.293229 },
    { lat: 49.152173, lng: 19.293717 },
    { lat: 49.151975, lng: 19.294317 },
    { lat: 49.151957, lng: 19.294373 },
    { lat: 49.15174, lng: 19.295026 },
    { lat: 49.15141, lng: 19.295813 },
    { lat: 49.150953, lng: 19.296641 },
    { lat: 49.150344, lng: 19.297428 },
    { lat: 49.151332, lng: 19.299139 },
    { lat: 49.151488, lng: 19.299454 },
    { lat: 49.151929, lng: 19.300044 },
    { lat: 49.151887, lng: 19.300243 },
    { lat: 49.1518, lng: 19.300662 },
    { lat: 49.151582, lng: 19.301706 },
    { lat: 49.151579, lng: 19.302072 },
    { lat: 49.151602, lng: 19.302451 },
    { lat: 49.151674, lng: 19.302952 },
    { lat: 49.151387, lng: 19.303282 },
    { lat: 49.150868, lng: 19.303391 },
    { lat: 49.150503, lng: 19.304266 },
    { lat: 49.150411, lng: 19.304908 },
    { lat: 49.150253, lng: 19.306017 },
    { lat: 49.150321, lng: 19.3081 },
    { lat: 49.150276, lng: 19.308356 },
    { lat: 49.150105, lng: 19.30973 },
    { lat: 49.150243, lng: 19.309955 },
    { lat: 49.150827, lng: 19.310908 },
    { lat: 49.151018, lng: 19.311183 },
    { lat: 49.151667, lng: 19.312105 },
    { lat: 49.152457, lng: 19.31378 },
    { lat: 49.152947, lng: 19.315016 },
    { lat: 49.152504, lng: 19.317332 },
    { lat: 49.151308, lng: 19.316805 },
    { lat: 49.150849, lng: 19.31937 },
    { lat: 49.150783, lng: 19.320242 },
    { lat: 49.15068, lng: 19.32161 },
    { lat: 49.149904, lng: 19.324886 },
    { lat: 49.149497, lng: 19.326014 },
    { lat: 49.148863, lng: 19.327413 },
    { lat: 49.148329, lng: 19.329572 },
    { lat: 49.148717, lng: 19.333219 },
    { lat: 49.148323, lng: 19.338729 },
    { lat: 49.14799, lng: 19.343339 },
    { lat: 49.1479132, lng: 19.3443313 },
    { lat: 49.1479285, lng: 19.3447846 },
    { lat: 49.149369, lng: 19.34453 },
    { lat: 49.1501429, lng: 19.3440719 },
    { lat: 49.150576, lng: 19.3437669 },
    { lat: 49.1506141, lng: 19.3437175 },
    { lat: 49.1507245, lng: 19.3435453 },
    { lat: 49.1508359, lng: 19.3433755 },
    { lat: 49.1499266, lng: 19.3384262 },
    { lat: 49.1495338, lng: 19.336216 },
    { lat: 49.1507397, lng: 19.3365975 },
    { lat: 49.1514697, lng: 19.3368537 },
    { lat: 49.1519593, lng: 19.3353837 },
    { lat: 49.1518171, lng: 19.3344024 },
    { lat: 49.1527382, lng: 19.3313055 },
    { lat: 49.1534641, lng: 19.3302361 },
    { lat: 49.1540849, lng: 19.3293233 },
    { lat: 49.1551429, lng: 19.3265744 },
    { lat: 49.1558999, lng: 19.3257125 },
    { lat: 49.1593899, lng: 19.3242506 },
    { lat: 49.1596383, lng: 19.3238808 },
    { lat: 49.1613921, lng: 19.3230737 },
    { lat: 49.1622796, lng: 19.323373 },
    { lat: 49.1630445, lng: 19.323173 },
    { lat: 49.1639864, lng: 19.3234104 },
    { lat: 49.1644847, lng: 19.3235797 },
    { lat: 49.1645095, lng: 19.3235308 },
    { lat: 49.1649446, lng: 19.3240329 },
    { lat: 49.1652293, lng: 19.3245242 },
    { lat: 49.1654308, lng: 19.3246407 },
    { lat: 49.1657646, lng: 19.3248571 },
    { lat: 49.1661353, lng: 19.3251211 },
    { lat: 49.1669892, lng: 19.3253765 },
    { lat: 49.1675523, lng: 19.3253172 },
    { lat: 49.1678772, lng: 19.3249217 },
    { lat: 49.1680675, lng: 19.3242716 },
    { lat: 49.1681912, lng: 19.3236267 },
    { lat: 49.1683218, lng: 19.3231221 },
    { lat: 49.1688558, lng: 19.3222092 },
    { lat: 49.1692656, lng: 19.3220852 },
    { lat: 49.1698372, lng: 19.321753 },
    { lat: 49.1701915, lng: 19.3210502 },
    { lat: 49.1703799, lng: 19.3207633 },
    { lat: 49.1705309, lng: 19.3205007 },
    { lat: 49.1705502, lng: 19.3203852 },
    { lat: 49.1708131, lng: 19.3196312 },
    { lat: 49.1708454, lng: 19.3194555 },
    { lat: 49.1708967, lng: 19.3191578 },
    { lat: 49.171229, lng: 19.3179147 },
    { lat: 49.1715925, lng: 19.3170481 },
    { lat: 49.1717985, lng: 19.3166404 },
    { lat: 49.172313, lng: 19.3154856 },
    { lat: 49.1725828, lng: 19.3150179 },
    { lat: 49.1728109, lng: 19.3148137 },
    { lat: 49.1731107, lng: 19.3145506 },
    { lat: 49.17335, lng: 19.3143938 },
    { lat: 49.1738428, lng: 19.3141126 },
    { lat: 49.1739628, lng: 19.3139827 },
    { lat: 49.1740457, lng: 19.3138742 },
    { lat: 49.1742794, lng: 19.3134584 },
    { lat: 49.1744579, lng: 19.3130623 },
    { lat: 49.1745765, lng: 19.3126847 },
    { lat: 49.1746332, lng: 19.3124552 },
    { lat: 49.1746638, lng: 19.3122937 },
    { lat: 49.1747203, lng: 19.3118919 },
    { lat: 49.1748332, lng: 19.3116079 },
    { lat: 49.1749231, lng: 19.3110826 },
    { lat: 49.1749906, lng: 19.3111508 },
    { lat: 49.1751132, lng: 19.3102897 },
    { lat: 49.1752948, lng: 19.3086485 },
    { lat: 49.1753267, lng: 19.3084659 },
    { lat: 49.1758024, lng: 19.3072551 },
    { lat: 49.1759287, lng: 19.3067808 },
    { lat: 49.1759977, lng: 19.3061702 },
    { lat: 49.1760561, lng: 19.3059022 },
    { lat: 49.1762288, lng: 19.3052463 },
    { lat: 49.1762199, lng: 19.3051717 },
    { lat: 49.1762032, lng: 19.3048962 },
    { lat: 49.1762161, lng: 19.3047838 },
    { lat: 49.1762586, lng: 19.3045886 },
    { lat: 49.1763308, lng: 19.3042593 },
    { lat: 49.176694, lng: 19.3032193 },
    { lat: 49.1773947, lng: 19.3009338 },
    { lat: 49.1803137, lng: 19.3002672 },
    { lat: 49.1803288, lng: 19.3002529 },
    { lat: 49.180403, lng: 19.3001784 },
    { lat: 49.1804449, lng: 19.300137 },
    { lat: 49.1807128, lng: 19.3001529 },
    { lat: 49.1808768, lng: 19.3000454 },
    { lat: 49.1811252, lng: 19.2998649 },
    { lat: 49.1812082, lng: 19.2998078 },
    { lat: 49.181538, lng: 19.299467 },
    { lat: 49.1823723, lng: 19.2985761 },
    { lat: 49.1832161, lng: 19.2978543 },
    { lat: 49.1843138, lng: 19.2970478 },
    { lat: 49.1853685, lng: 19.2965915 },
    { lat: 49.1853597, lng: 19.295984 },
    { lat: 49.1855098, lng: 19.2949867 },
    { lat: 49.1854864, lng: 19.2944237 },
    { lat: 49.1855043, lng: 19.2939706 },
    { lat: 49.185693, lng: 19.2939559 },
    { lat: 49.1867814, lng: 19.2915784 },
    { lat: 49.1863613, lng: 19.2906852 },
    { lat: 49.1860276, lng: 19.2906094 },
    { lat: 49.1855915, lng: 19.2896629 },
    { lat: 49.1851158, lng: 19.2882611 },
    { lat: 49.1850727, lng: 19.287662 },
    { lat: 49.1850656, lng: 19.2876077 },
    { lat: 49.1849726, lng: 19.2873157 },
    { lat: 49.1842108, lng: 19.2874306 },
    { lat: 49.1839627, lng: 19.287265 },
    { lat: 49.1839879, lng: 19.2868897 },
    { lat: 49.1823192, lng: 19.2852187 },
    { lat: 49.1823045, lng: 19.2852661 },
    { lat: 49.1820944, lng: 19.2849266 },
    { lat: 49.1818051, lng: 19.2837701 },
    { lat: 49.1810883, lng: 19.283887 },
    { lat: 49.1810423, lng: 19.2831593 },
    { lat: 49.1809396, lng: 19.2832237 },
    { lat: 49.1808512, lng: 19.2833826 },
    { lat: 49.1803466, lng: 19.2834129 },
    { lat: 49.1795639, lng: 19.2843256 },
    { lat: 49.178705, lng: 19.2833721 },
    { lat: 49.1783826, lng: 19.2841755 },
    { lat: 49.1779009, lng: 19.2844514 },
    { lat: 49.1775608, lng: 19.2844113 },
    { lat: 49.1770286, lng: 19.2840758 },
    { lat: 49.1766584, lng: 19.2833037 },
    { lat: 49.1760428, lng: 19.2820215 },
    { lat: 49.1749774, lng: 19.2798817 },
    { lat: 49.174639, lng: 19.279049 },
    { lat: 49.174508, lng: 19.2787134 },
    { lat: 49.1740224, lng: 19.2776527 },
    { lat: 49.1734939, lng: 19.2762558 },
    { lat: 49.1729986, lng: 19.2739521 },
    { lat: 49.1724531, lng: 19.2723677 },
    { lat: 49.1719382, lng: 19.2714019 },
    { lat: 49.1708294, lng: 19.2710743 },
    { lat: 49.1708016, lng: 19.2710619 },
    { lat: 49.1697845, lng: 19.2698703 },
    { lat: 49.1697175, lng: 19.269694 },
    { lat: 49.1690119, lng: 19.2687257 },
    { lat: 49.1690301, lng: 19.2686801 },
    { lat: 49.168526, lng: 19.2682225 },
    { lat: 49.1679418, lng: 19.2677446 },
    { lat: 49.1668727, lng: 19.2664306 },
    { lat: 49.1664452, lng: 19.2660491 },
    { lat: 49.165665, lng: 19.2659917 },
    { lat: 49.1652827, lng: 19.2659424 },
    { lat: 49.1648525, lng: 19.2657564 },
    { lat: 49.1645016, lng: 19.2656355 },
    { lat: 49.1640957, lng: 19.2653255 },
    { lat: 49.16299, lng: 19.265389 },
    { lat: 49.162895, lng: 19.265488 },
    { lat: 49.16288, lng: 19.265503 },
    { lat: 49.16284, lng: 19.265545 },
    { lat: 49.162574, lng: 19.265901 },
    { lat: 49.16242, lng: 19.266041 },
    { lat: 49.16213, lng: 19.266496 },
    { lat: 49.161922, lng: 19.267204 },
    { lat: 49.161766, lng: 19.267734 },
    { lat: 49.161578, lng: 19.268374 },
    { lat: 49.161509, lng: 19.268608 },
    { lat: 49.161445, lng: 19.268825 },
    { lat: 49.161432, lng: 19.268864 },
    { lat: 49.161414, lng: 19.268921 },
    { lat: 49.161102, lng: 19.269846 },
    { lat: 49.160911, lng: 19.270101 },
    { lat: 49.160669, lng: 19.270298 },
    { lat: 49.160596, lng: 19.270319 },
    { lat: 49.159697, lng: 19.270567 },
    { lat: 49.158723, lng: 19.270807 },
    { lat: 49.157644, lng: 19.271413 },
    { lat: 49.156995, lng: 19.271766 },
    { lat: 49.156236, lng: 19.272076 },
    { lat: 49.15585, lng: 19.272135 },
    { lat: 49.155792, lng: 19.272133 },
    { lat: 49.155694, lng: 19.272158 },
    { lat: 49.15524, lng: 19.272283 },
    { lat: 49.155229, lng: 19.272301 },
    { lat: 49.155057, lng: 19.272553 },
    { lat: 49.154547, lng: 19.27305 },
    { lat: 49.154454, lng: 19.273168 },
    { lat: 49.154343, lng: 19.273311 },
    { lat: 49.154197, lng: 19.273477 },
    { lat: 49.154091, lng: 19.273608 },
    { lat: 49.15397, lng: 19.273759 },
    { lat: 49.153697, lng: 19.274096 },
    { lat: 49.153205, lng: 19.274603 },
    { lat: 49.153161, lng: 19.27472 },
    { lat: 49.153081, lng: 19.274709 },
    { lat: 49.152396, lng: 19.274273 },
    { lat: 49.151346, lng: 19.273237 },
  ],
  Kraľovany: [
    { lat: 49.167816, lng: 19.13484 },
    { lat: 49.1683967, lng: 19.1348714 },
    { lat: 49.1690056, lng: 19.1346678 },
    { lat: 49.1701588, lng: 19.1343468 },
    { lat: 49.1706048, lng: 19.1341552 },
    { lat: 49.1711506, lng: 19.1337916 },
    { lat: 49.1715925, lng: 19.1335394 },
    { lat: 49.1720137, lng: 19.133299 },
    { lat: 49.1724856, lng: 19.1330371 },
    { lat: 49.1728144, lng: 19.1328736 },
    { lat: 49.1733659, lng: 19.1326019 },
    { lat: 49.1744057, lng: 19.1319663 },
    { lat: 49.1745236, lng: 19.1319147 },
    { lat: 49.1747369, lng: 19.1318706 },
    { lat: 49.1749751, lng: 19.1318781 },
    { lat: 49.1751392, lng: 19.1319592 },
    { lat: 49.1752102, lng: 19.1320297 },
    { lat: 49.1752822, lng: 19.1321209 },
    { lat: 49.1753495, lng: 19.1322058 },
    { lat: 49.1754338, lng: 19.1323612 },
    { lat: 49.1754835, lng: 19.1324792 },
    { lat: 49.1755568, lng: 19.1327126 },
    { lat: 49.1755333, lng: 19.1330928 },
    { lat: 49.1755802, lng: 19.1330496 },
    { lat: 49.175953, lng: 19.1330418 },
    { lat: 49.1759381, lng: 19.1329696 },
    { lat: 49.1760627, lng: 19.1329133 },
    { lat: 49.1760979, lng: 19.1328956 },
    { lat: 49.1761277, lng: 19.1328811 },
    { lat: 49.1761635, lng: 19.1328316 },
    { lat: 49.1761865, lng: 19.1328185 },
    { lat: 49.1762228, lng: 19.1327971 },
    { lat: 49.1762946, lng: 19.1327915 },
    { lat: 49.1766275, lng: 19.1328001 },
    { lat: 49.1767564, lng: 19.1327166 },
    { lat: 49.1767788, lng: 19.1326913 },
    { lat: 49.1768465, lng: 19.132577 },
    { lat: 49.1768575, lng: 19.1324558 },
    { lat: 49.1769142, lng: 19.1321607 },
    { lat: 49.1769298, lng: 19.1319057 },
    { lat: 49.1769596, lng: 19.131756 },
    { lat: 49.17699, lng: 19.1316377 },
    { lat: 49.177065, lng: 19.1313755 },
    { lat: 49.1770858, lng: 19.1313364 },
    { lat: 49.1777883, lng: 19.1303291 },
    { lat: 49.1778706, lng: 19.1300992 },
    { lat: 49.1780155, lng: 19.1294443 },
    { lat: 49.1781307, lng: 19.1291732 },
    { lat: 49.1782362, lng: 19.1290034 },
    { lat: 49.1785066, lng: 19.1287463 },
    { lat: 49.1785697, lng: 19.1286318 },
    { lat: 49.1786732, lng: 19.1283948 },
    { lat: 49.1787981, lng: 19.1280902 },
    { lat: 49.1788886, lng: 19.1279358 },
    { lat: 49.1790244, lng: 19.1278346 },
    { lat: 49.1794377, lng: 19.127598 },
    { lat: 49.1796012, lng: 19.1274828 },
    { lat: 49.1796763, lng: 19.1274238 },
    { lat: 49.1798608, lng: 19.1270043 },
    { lat: 49.1800133, lng: 19.1266414 },
    { lat: 49.1801166, lng: 19.1264261 },
    { lat: 49.1803019, lng: 19.1260674 },
    { lat: 49.180502, lng: 19.1256674 },
    { lat: 49.1805395, lng: 19.1255539 },
    { lat: 49.1806412, lng: 19.1251716 },
    { lat: 49.180739, lng: 19.1248686 },
    { lat: 49.1809, lng: 19.1246777 },
    { lat: 49.1809579, lng: 19.124617 },
    { lat: 49.18101, lng: 19.1245751 },
    { lat: 49.1810531, lng: 19.1245817 },
    { lat: 49.1811416, lng: 19.1245712 },
    { lat: 49.1812387, lng: 19.1245771 },
    { lat: 49.1813863, lng: 19.1246205 },
    { lat: 49.1814239, lng: 19.1246259 },
    { lat: 49.1817538, lng: 19.124802 },
    { lat: 49.1819613, lng: 19.1248962 },
    { lat: 49.1821376, lng: 19.1249553 },
    { lat: 49.1822114, lng: 19.1249639 },
    { lat: 49.1822503, lng: 19.1249753 },
    { lat: 49.1824532, lng: 19.1249759 },
    { lat: 49.1826254, lng: 19.1249466 },
    { lat: 49.1826652, lng: 19.1249114 },
    { lat: 49.1829243, lng: 19.1246996 },
    { lat: 49.1831917, lng: 19.1244522 },
    { lat: 49.1835644, lng: 19.1241442 },
    { lat: 49.1837153, lng: 19.1240473 },
    { lat: 49.1840756, lng: 19.1238805 },
    { lat: 49.1843585, lng: 19.1238108 },
    { lat: 49.1844172, lng: 19.1238131 },
    { lat: 49.1844349, lng: 19.1238036 },
    { lat: 49.1844874, lng: 19.1237947 },
    { lat: 49.18461, lng: 19.1236748 },
    { lat: 49.1846503, lng: 19.1236922 },
    { lat: 49.1846137, lng: 19.1235911 },
    { lat: 49.1840161, lng: 19.1218356 },
    { lat: 49.1840653, lng: 19.1212538 },
    { lat: 49.1842004, lng: 19.1203772 },
    { lat: 49.1844365, lng: 19.1198105 },
    { lat: 49.1844931, lng: 19.1193562 },
    { lat: 49.184591, lng: 19.1188708 },
    { lat: 49.1848333, lng: 19.1171882 },
    { lat: 49.1849547, lng: 19.1164039 },
    { lat: 49.1850348, lng: 19.1148201 },
    { lat: 49.1850886, lng: 19.1134555 },
    { lat: 49.1851591, lng: 19.1126878 },
    { lat: 49.1852302, lng: 19.1120239 },
    { lat: 49.1852373, lng: 19.111408 },
    { lat: 49.185058, lng: 19.1102386 },
    { lat: 49.1850879, lng: 19.1094468 },
    { lat: 49.1851264, lng: 19.1086676 },
    { lat: 49.1851454, lng: 19.1086172 },
    { lat: 49.1851775, lng: 19.1085382 },
    { lat: 49.1853449, lng: 19.1081137 },
    { lat: 49.1855905, lng: 19.1079249 },
    { lat: 49.1858174, lng: 19.1077033 },
    { lat: 49.1858264, lng: 19.1069246 },
    { lat: 49.1857752, lng: 19.1061313 },
    { lat: 49.1857754, lng: 19.1048275 },
    { lat: 49.1858295, lng: 19.1041172 },
    { lat: 49.185885, lng: 19.1032755 },
    { lat: 49.1860541, lng: 19.10268 },
    { lat: 49.1861665, lng: 19.1021201 },
    { lat: 49.1863487, lng: 19.1014744 },
    { lat: 49.1864304, lng: 19.1010709 },
    { lat: 49.1871514, lng: 19.1000049 },
    { lat: 49.1875221, lng: 19.0994562 },
    { lat: 49.1872162, lng: 19.0988746 },
    { lat: 49.1873176, lng: 19.098529 },
    { lat: 49.1874899, lng: 19.0979745 },
    { lat: 49.1879989, lng: 19.0975281 },
    { lat: 49.1887245, lng: 19.096974 },
    { lat: 49.1893626, lng: 19.0966065 },
    { lat: 49.1903467, lng: 19.0962195 },
    { lat: 49.1915783, lng: 19.0964839 },
    { lat: 49.191836, lng: 19.0956727 },
    { lat: 49.1920029, lng: 19.0952867 },
    { lat: 49.1919899, lng: 19.0948842 },
    { lat: 49.1919554, lng: 19.0945047 },
    { lat: 49.1919021, lng: 19.0939727 },
    { lat: 49.1911037, lng: 19.0925243 },
    { lat: 49.1908214, lng: 19.0921011 },
    { lat: 49.1904284, lng: 19.0915118 },
    { lat: 49.1897746, lng: 19.0902999 },
    { lat: 49.1893165, lng: 19.0896489 },
    { lat: 49.1889019, lng: 19.0886034 },
    { lat: 49.188439, lng: 19.0876951 },
    { lat: 49.1879386, lng: 19.0865751 },
    { lat: 49.1874742, lng: 19.0855257 },
    { lat: 49.1872894, lng: 19.084748 },
    { lat: 49.1872944, lng: 19.0842786 },
    { lat: 49.187295, lng: 19.084154 },
    { lat: 49.187281, lng: 19.084153 },
    { lat: 49.186687, lng: 19.084074 },
    { lat: 49.186456, lng: 19.084011 },
    { lat: 49.186178, lng: 19.084114 },
    { lat: 49.185867, lng: 19.08431 },
    { lat: 49.185675, lng: 19.08439 },
    { lat: 49.185309, lng: 19.084394 },
    { lat: 49.185175, lng: 19.084474 },
    { lat: 49.185147, lng: 19.084562 },
    { lat: 49.18511, lng: 19.084622 },
    { lat: 49.185064, lng: 19.08468 },
    { lat: 49.184892, lng: 19.084839 },
    { lat: 49.184775, lng: 19.084828 },
    { lat: 49.184653, lng: 19.084922 },
    { lat: 49.18453, lng: 19.085098 },
    { lat: 49.184345, lng: 19.085179 },
    { lat: 49.184223, lng: 19.085178 },
    { lat: 49.184147, lng: 19.085138 },
    { lat: 49.18401, lng: 19.085091 },
    { lat: 49.183971, lng: 19.08505 },
    { lat: 49.183947, lng: 19.085049 },
    { lat: 49.183835, lng: 19.08506 },
    { lat: 49.183642, lng: 19.085071 },
    { lat: 49.183608, lng: 19.085039 },
    { lat: 49.18357, lng: 19.084997 },
    { lat: 49.183495, lng: 19.085019 },
    { lat: 49.1834, lng: 19.085101 },
    { lat: 49.183316, lng: 19.085232 },
    { lat: 49.183221, lng: 19.085298 },
    { lat: 49.183141, lng: 19.085285 },
    { lat: 49.182898, lng: 19.085321 },
    { lat: 49.182665, lng: 19.085395 },
    { lat: 49.182532, lng: 19.085423 },
    { lat: 49.182458, lng: 19.085431 },
    { lat: 49.182222, lng: 19.085439 },
    { lat: 49.182067, lng: 19.085355 },
    { lat: 49.181864, lng: 19.085355 },
    { lat: 49.181668, lng: 19.085484 },
    { lat: 49.181374, lng: 19.08555 },
    { lat: 49.181312, lng: 19.085563 },
    { lat: 49.181142, lng: 19.085535 },
    { lat: 49.18102, lng: 19.085472 },
    { lat: 49.180938, lng: 19.085445 },
    { lat: 49.180815, lng: 19.085342 },
    { lat: 49.18079, lng: 19.085343 },
    { lat: 49.180689, lng: 19.085363 },
    { lat: 49.180658, lng: 19.085327 },
    { lat: 49.180515, lng: 19.085068 },
    { lat: 49.180507, lng: 19.085001 },
    { lat: 49.180319, lng: 19.084567 },
    { lat: 49.180228, lng: 19.084425 },
    { lat: 49.180163, lng: 19.084379 },
    { lat: 49.179963, lng: 19.08429 },
    { lat: 49.179835, lng: 19.084239 },
    { lat: 49.179706, lng: 19.084172 },
    { lat: 49.179624, lng: 19.084086 },
    { lat: 49.179452, lng: 19.08395 },
    { lat: 49.17921, lng: 19.083757 },
    { lat: 49.179122, lng: 19.08368 },
    { lat: 49.179035, lng: 19.083612 },
    { lat: 49.179014, lng: 19.083556 },
    { lat: 49.178944, lng: 19.083417 },
    { lat: 49.1788, lng: 19.083179 },
    { lat: 49.178629, lng: 19.083013 },
    { lat: 49.178498, lng: 19.082775 },
    { lat: 49.178338, lng: 19.082549 },
    { lat: 49.178238, lng: 19.08246 },
    { lat: 49.178086, lng: 19.082423 },
    { lat: 49.178, lng: 19.082367 },
    { lat: 49.177858, lng: 19.082241 },
    { lat: 49.17764, lng: 19.082239 },
    { lat: 49.177529, lng: 19.08229 },
    { lat: 49.177442, lng: 19.082314 },
    { lat: 49.17726, lng: 19.082135 },
    { lat: 49.177018, lng: 19.08198 },
    { lat: 49.176836, lng: 19.081989 },
    { lat: 49.176747, lng: 19.081912 },
    { lat: 49.176684, lng: 19.081817 },
    { lat: 49.176645, lng: 19.081765 },
    { lat: 49.176544, lng: 19.081588 },
    { lat: 49.176375, lng: 19.081482 },
    { lat: 49.176209, lng: 19.08144 },
    { lat: 49.175959, lng: 19.081358 },
    { lat: 49.175873, lng: 19.081243 },
    { lat: 49.175779, lng: 19.081094 },
    { lat: 49.175722, lng: 19.081021 },
    { lat: 49.175639, lng: 19.080963 },
    { lat: 49.175476, lng: 19.080864 },
    { lat: 49.175456, lng: 19.080865 },
    { lat: 49.175334, lng: 19.080898 },
    { lat: 49.175266, lng: 19.080917 },
    { lat: 49.175121, lng: 19.080864 },
    { lat: 49.175077, lng: 19.080841 },
    { lat: 49.174853, lng: 19.080606 },
    { lat: 49.174731, lng: 19.080554 },
    { lat: 49.174628, lng: 19.080485 },
    { lat: 49.174338, lng: 19.080176 },
    { lat: 49.174265, lng: 19.080005 },
    { lat: 49.174148, lng: 19.080001 },
    { lat: 49.17357, lng: 19.079602 },
    { lat: 49.173456, lng: 19.079442 },
    { lat: 49.1731, lng: 19.079282 },
    { lat: 49.172875, lng: 19.079144 },
    { lat: 49.172564, lng: 19.078981 },
    { lat: 49.172386, lng: 19.078808 },
    { lat: 49.172334, lng: 19.078772 },
    { lat: 49.172181, lng: 19.078543 },
    { lat: 49.172084, lng: 19.078403 },
    { lat: 49.172054, lng: 19.078398 },
    { lat: 49.171881, lng: 19.078365 },
    { lat: 49.171762, lng: 19.078372 },
    { lat: 49.171669, lng: 19.078343 },
    { lat: 49.171657, lng: 19.07834 },
    { lat: 49.171552, lng: 19.078302 },
    { lat: 49.171347, lng: 19.078346 },
    { lat: 49.171152, lng: 19.078466 },
    { lat: 49.170644, lng: 19.07844 },
    { lat: 49.170387, lng: 19.078247 },
    { lat: 49.170307, lng: 19.078211 },
    { lat: 49.170037, lng: 19.078305 },
    { lat: 49.169877, lng: 19.078417 },
    { lat: 49.16974, lng: 19.078506 },
    { lat: 49.169686, lng: 19.07854 },
    { lat: 49.169457, lng: 19.078685 },
    { lat: 49.169204, lng: 19.078792 },
    { lat: 49.168951, lng: 19.078896 },
    { lat: 49.168883, lng: 19.078924 },
    { lat: 49.16881, lng: 19.078909 },
    { lat: 49.168749, lng: 19.078891 },
    { lat: 49.168662, lng: 19.078924 },
    { lat: 49.168539, lng: 19.079156 },
    { lat: 49.168248, lng: 19.07938 },
    { lat: 49.168182, lng: 19.07942 },
    { lat: 49.168152, lng: 19.079413 },
    { lat: 49.168122, lng: 19.079423 },
    { lat: 49.168069, lng: 19.07947 },
    { lat: 49.168005, lng: 19.079502 },
    { lat: 49.16795, lng: 19.079513 },
    { lat: 49.167725, lng: 19.079659 },
    { lat: 49.1677, lng: 19.079676 },
    { lat: 49.167569, lng: 19.079763 },
    { lat: 49.167364, lng: 19.079918 },
    { lat: 49.167281, lng: 19.080042 },
    { lat: 49.16721, lng: 19.080364 },
    { lat: 49.167069, lng: 19.08052 },
    { lat: 49.167003, lng: 19.080552 },
    { lat: 49.166932, lng: 19.080535 },
    { lat: 49.166723, lng: 19.080426 },
    { lat: 49.166541, lng: 19.080248 },
    { lat: 49.166362, lng: 19.080247 },
    { lat: 49.166328, lng: 19.080235 },
    { lat: 49.166205, lng: 19.080235 },
    { lat: 49.166115, lng: 19.080325 },
    { lat: 49.166068, lng: 19.080399 },
    { lat: 49.166025, lng: 19.080465 },
    { lat: 49.165866, lng: 19.080639 },
    { lat: 49.165727, lng: 19.080765 },
    { lat: 49.165648, lng: 19.080799 },
    { lat: 49.165386, lng: 19.080856 },
    { lat: 49.16522, lng: 19.081018 },
    { lat: 49.165092, lng: 19.081184 },
    { lat: 49.165044, lng: 19.081217 },
    { lat: 49.164984, lng: 19.081214 },
    { lat: 49.164853, lng: 19.081232 },
    { lat: 49.164834, lng: 19.081236 },
    { lat: 49.164746, lng: 19.081296 },
    { lat: 49.164626, lng: 19.081482 },
    { lat: 49.164419, lng: 19.081831 },
    { lat: 49.163994, lng: 19.082196 },
    { lat: 49.163871, lng: 19.082364 },
    { lat: 49.163787, lng: 19.082544 },
    { lat: 49.163682, lng: 19.0827 },
    { lat: 49.163558, lng: 19.082811 },
    { lat: 49.163452, lng: 19.082988 },
    { lat: 49.163251, lng: 19.083443 },
    { lat: 49.163117, lng: 19.083569 },
    { lat: 49.162941, lng: 19.083796 },
    { lat: 49.162848, lng: 19.084001 },
    { lat: 49.162836, lng: 19.084058 },
    { lat: 49.16282, lng: 19.084236 },
    { lat: 49.162756, lng: 19.084415 },
    { lat: 49.162569, lng: 19.084826 },
    { lat: 49.162502, lng: 19.084922 },
    { lat: 49.162407, lng: 19.085047 },
    { lat: 49.162174, lng: 19.085146 },
    { lat: 49.161935, lng: 19.085309 },
    { lat: 49.161244, lng: 19.085894 },
    { lat: 49.160966, lng: 19.08614 },
    { lat: 49.160918, lng: 19.086199 },
    { lat: 49.160856, lng: 19.086261 },
    { lat: 49.16077, lng: 19.086273 },
    { lat: 49.160722, lng: 19.086269 },
    { lat: 49.16056, lng: 19.08633 },
    { lat: 49.160481, lng: 19.086384 },
    { lat: 49.16038, lng: 19.086533 },
    { lat: 49.160259, lng: 19.086691 },
    { lat: 49.160128, lng: 19.086833 },
    { lat: 49.159997, lng: 19.086918 },
    { lat: 49.159979, lng: 19.086919 },
    { lat: 49.15992, lng: 19.086894 },
    { lat: 49.159756, lng: 19.086847 },
    { lat: 49.159625, lng: 19.086807 },
    { lat: 49.159422, lng: 19.08672 },
    { lat: 49.159278, lng: 19.086802 },
    { lat: 49.159128, lng: 19.086931 },
    { lat: 49.159074, lng: 19.087 },
    { lat: 49.159003, lng: 19.087199 },
    { lat: 49.158955, lng: 19.087256 },
    { lat: 49.158808, lng: 19.087334 },
    { lat: 49.158675, lng: 19.08734 },
    { lat: 49.158604, lng: 19.087325 },
    { lat: 49.158517, lng: 19.087412 },
    { lat: 49.158196, lng: 19.087647 },
    { lat: 49.157977, lng: 19.087746 },
    { lat: 49.157773, lng: 19.087923 },
    { lat: 49.157701, lng: 19.088035 },
    { lat: 49.157657, lng: 19.088165 },
    { lat: 49.157603, lng: 19.088267 },
    { lat: 49.157566, lng: 19.088366 },
    { lat: 49.157531, lng: 19.088446 },
    { lat: 49.157416, lng: 19.08867 },
    { lat: 49.157146, lng: 19.088896 },
    { lat: 49.157052, lng: 19.088933 },
    { lat: 49.156961, lng: 19.088956 },
    { lat: 49.156793, lng: 19.089133 },
    { lat: 49.156521, lng: 19.089461 },
    { lat: 49.156476, lng: 19.089804 },
    { lat: 49.156385, lng: 19.090014 },
    { lat: 49.156256, lng: 19.090118 },
    { lat: 49.156009, lng: 19.090213 },
    { lat: 49.155749, lng: 19.090322 },
    { lat: 49.155617, lng: 19.09041 },
    { lat: 49.155466, lng: 19.090581 },
    { lat: 49.155338, lng: 19.090748 },
    { lat: 49.155177, lng: 19.091064 },
    { lat: 49.155085, lng: 19.091188 },
    { lat: 49.155004, lng: 19.091188 },
    { lat: 49.154922, lng: 19.091154 },
    { lat: 49.154847, lng: 19.091119 },
    { lat: 49.154791, lng: 19.091129 },
    { lat: 49.154632, lng: 19.091214 },
    { lat: 49.154401, lng: 19.091278 },
    { lat: 49.154273, lng: 19.091554 },
    { lat: 49.154106, lng: 19.091829 },
    { lat: 49.154038, lng: 19.091883 },
    { lat: 49.153744, lng: 19.092125 },
    { lat: 49.153633, lng: 19.092296 },
    { lat: 49.153513, lng: 19.092438 },
    { lat: 49.153347, lng: 19.092503 },
    { lat: 49.153305, lng: 19.092511 },
    { lat: 49.153165, lng: 19.092679 },
    { lat: 49.153137, lng: 19.092744 },
    { lat: 49.152952, lng: 19.093207 },
    { lat: 49.152869, lng: 19.09345 },
    { lat: 49.152801, lng: 19.093647 },
    { lat: 49.152797, lng: 19.093738 },
    { lat: 49.15288, lng: 19.094038 },
    { lat: 49.152883, lng: 19.094202 },
    { lat: 49.152809, lng: 19.094373 },
    { lat: 49.152736, lng: 19.09449 },
    { lat: 49.1527, lng: 19.094499 },
    { lat: 49.152527, lng: 19.094538 },
    { lat: 49.152362, lng: 19.094544 },
    { lat: 49.152281, lng: 19.094555 },
    { lat: 49.152249, lng: 19.094567 },
    { lat: 49.152179, lng: 19.094602 },
    { lat: 49.152055, lng: 19.094665 },
    { lat: 49.152022, lng: 19.09471 },
    { lat: 49.151988, lng: 19.094776 },
    { lat: 49.151923, lng: 19.09502 },
    { lat: 49.151918, lng: 19.095106 },
    { lat: 49.151917, lng: 19.095245 },
    { lat: 49.151905, lng: 19.095325 },
    { lat: 49.15185, lng: 19.095502 },
    { lat: 49.151782, lng: 19.095618 },
    { lat: 49.151752, lng: 19.09563 },
    { lat: 49.151751, lng: 19.09563 },
    { lat: 49.151617, lng: 19.095695 },
    { lat: 49.15156, lng: 19.095722 },
    { lat: 49.151448, lng: 19.095775 },
    { lat: 49.151332, lng: 19.095865 },
    { lat: 49.151318, lng: 19.095885 },
    { lat: 49.151232, lng: 19.096007 },
    { lat: 49.151127, lng: 19.096115 },
    { lat: 49.151086, lng: 19.096149 },
    { lat: 49.150928, lng: 19.096284 },
    { lat: 49.150852, lng: 19.096382 },
    { lat: 49.150797, lng: 19.096455 },
    { lat: 49.150671, lng: 19.096621 },
    { lat: 49.150637, lng: 19.096667 },
    { lat: 49.150615, lng: 19.096695 },
    { lat: 49.150582, lng: 19.096721 },
    { lat: 49.150566, lng: 19.096732 },
    { lat: 49.150337, lng: 19.096903 },
    { lat: 49.150272, lng: 19.096962 },
    { lat: 49.149987, lng: 19.097223 },
    { lat: 49.14992, lng: 19.09682 },
    { lat: 49.149301, lng: 19.096954 },
    { lat: 49.149042, lng: 19.097015 },
    { lat: 49.148433, lng: 19.097309 },
    { lat: 49.145811, lng: 19.098509 },
    { lat: 49.145647, lng: 19.098411 },
    { lat: 49.144794, lng: 19.098257 },
    { lat: 49.143973, lng: 19.098214 },
    { lat: 49.143332, lng: 19.098186 },
    { lat: 49.143094, lng: 19.098432 },
    { lat: 49.141755, lng: 19.099521 },
    { lat: 49.141084, lng: 19.100659 },
    { lat: 49.140175, lng: 19.101498 },
    { lat: 49.139097, lng: 19.102737 },
    { lat: 49.138997, lng: 19.103301 },
    { lat: 49.139043, lng: 19.103592 },
    { lat: 49.139187, lng: 19.103973 },
    { lat: 49.139272, lng: 19.10451 },
    { lat: 49.139007, lng: 19.105254 },
    { lat: 49.138957, lng: 19.10569 },
    { lat: 49.139146, lng: 19.106762 },
    { lat: 49.139214, lng: 19.107242 },
    { lat: 49.139163, lng: 19.107917 },
    { lat: 49.139157, lng: 19.10836 },
    { lat: 49.13895, lng: 19.109389 },
    { lat: 49.138979, lng: 19.109881 },
    { lat: 49.139212, lng: 19.110868 },
    { lat: 49.138932, lng: 19.111669 },
    { lat: 49.139042, lng: 19.112406 },
    { lat: 49.139074, lng: 19.113266 },
    { lat: 49.139024, lng: 19.114054 },
    { lat: 49.139071, lng: 19.115149 },
    { lat: 49.138993, lng: 19.115831 },
    { lat: 49.139024, lng: 19.116073 },
    { lat: 49.139087, lng: 19.11657 },
    { lat: 49.139288, lng: 19.11703 },
    { lat: 49.139469, lng: 19.117332 },
    { lat: 49.139748, lng: 19.117733 },
    { lat: 49.140594, lng: 19.119202 },
    { lat: 49.140922, lng: 19.119652 },
    { lat: 49.141509, lng: 19.120881 },
    { lat: 49.141245, lng: 19.122105 },
    { lat: 49.141216, lng: 19.12262 },
    { lat: 49.141157, lng: 19.122877 },
    { lat: 49.141037, lng: 19.123631 },
    { lat: 49.140935, lng: 19.123744 },
    { lat: 49.140909, lng: 19.124071 },
    { lat: 49.140895, lng: 19.124631 },
    { lat: 49.140814, lng: 19.124886 },
    { lat: 49.1408, lng: 19.125092 },
    { lat: 49.140632, lng: 19.125394 },
    { lat: 49.140498, lng: 19.125716 },
    { lat: 49.140359, lng: 19.126251 },
    { lat: 49.140352, lng: 19.126562 },
    { lat: 49.140359, lng: 19.126729 },
    { lat: 49.140271, lng: 19.126991 },
    { lat: 49.140076, lng: 19.127248 },
    { lat: 49.140028, lng: 19.127425 },
    { lat: 49.139832, lng: 19.127732 },
    { lat: 49.139383, lng: 19.128222 },
    { lat: 49.13913, lng: 19.128453 },
    { lat: 49.138903, lng: 19.128721 },
    { lat: 49.138879, lng: 19.12892 },
    { lat: 49.138727, lng: 19.129413 },
    { lat: 49.138653, lng: 19.129696 },
    { lat: 49.138598, lng: 19.130002 },
    { lat: 49.138599, lng: 19.130166 },
    { lat: 49.138429, lng: 19.130494 },
    { lat: 49.138357, lng: 19.130699 },
    { lat: 49.138197, lng: 19.131059 },
    { lat: 49.137912, lng: 19.131622 },
    { lat: 49.137643, lng: 19.132155 },
    { lat: 49.1375, lng: 19.13249 },
    { lat: 49.13741, lng: 19.13288 },
    { lat: 49.137262, lng: 19.133013 },
    { lat: 49.137084, lng: 19.133287 },
    { lat: 49.136958, lng: 19.133649 },
    { lat: 49.136926, lng: 19.13383 },
    { lat: 49.136853, lng: 19.134002 },
    { lat: 49.136713, lng: 19.134793 },
    { lat: 49.136809, lng: 19.135402 },
    { lat: 49.136878, lng: 19.135578 },
    { lat: 49.136973, lng: 19.135754 },
    { lat: 49.136931, lng: 19.136267 },
    { lat: 49.136917, lng: 19.136572 },
    { lat: 49.136954, lng: 19.137057 },
    { lat: 49.136955, lng: 19.137235 },
    { lat: 49.137069, lng: 19.137818 },
    { lat: 49.137057, lng: 19.137893 },
    { lat: 49.136951, lng: 19.13838 },
    { lat: 49.136873, lng: 19.138936 },
    { lat: 49.136726, lng: 19.139473 },
    { lat: 49.136594, lng: 19.140079 },
    { lat: 49.136656, lng: 19.140499 },
    { lat: 49.136587, lng: 19.141404 },
    { lat: 49.136553, lng: 19.14191 },
    { lat: 49.137432, lng: 19.142242 },
    { lat: 49.138024, lng: 19.142463 },
    { lat: 49.138668, lng: 19.142548 },
    { lat: 49.139681, lng: 19.142797 },
    { lat: 49.140375, lng: 19.143241 },
    { lat: 49.140879, lng: 19.1435 },
    { lat: 49.141189, lng: 19.143836 },
    { lat: 49.141909, lng: 19.144269 },
    { lat: 49.142938, lng: 19.144579 },
    { lat: 49.143552, lng: 19.144993 },
    { lat: 49.143624, lng: 19.145209 },
    { lat: 49.143787, lng: 19.145443 },
    { lat: 49.143939, lng: 19.145411 },
    { lat: 49.143907, lng: 19.145308 },
    { lat: 49.143974, lng: 19.145314 },
    { lat: 49.143963, lng: 19.145249 },
    { lat: 49.144015, lng: 19.145228 },
    { lat: 49.144432, lng: 19.145399 },
    { lat: 49.144874, lng: 19.145441 },
    { lat: 49.145157, lng: 19.145556 },
    { lat: 49.145863, lng: 19.145704 },
    { lat: 49.146079, lng: 19.14578 },
    { lat: 49.146346, lng: 19.145881 },
    { lat: 49.146674, lng: 19.145968 },
    { lat: 49.147138, lng: 19.146158 },
    { lat: 49.14738, lng: 19.14625 },
    { lat: 49.147605, lng: 19.146298 },
    { lat: 49.148132, lng: 19.14645 },
    { lat: 49.148553, lng: 19.146488 },
    { lat: 49.148843, lng: 19.146489 },
    { lat: 49.149154, lng: 19.146595 },
    { lat: 49.149233, lng: 19.146645 },
    { lat: 49.149415, lng: 19.146744 },
    { lat: 49.149607, lng: 19.147014 },
    { lat: 49.149795, lng: 19.147174 },
    { lat: 49.150082, lng: 19.147234 },
    { lat: 49.150261, lng: 19.147331 },
    { lat: 49.150417, lng: 19.147344 },
    { lat: 49.150553, lng: 19.147113 },
    { lat: 49.150832, lng: 19.147104 },
    { lat: 49.150865, lng: 19.147109 },
    { lat: 49.150958, lng: 19.147131 },
    { lat: 49.150988, lng: 19.147145 },
    { lat: 49.151024, lng: 19.147167 },
    { lat: 49.151135, lng: 19.147226 },
    { lat: 49.151282, lng: 19.147433 },
    { lat: 49.1512931, lng: 19.1472185 },
    { lat: 49.151366, lng: 19.146952 },
    { lat: 49.15145, lng: 19.146477 },
    { lat: 49.151575, lng: 19.146175 },
    { lat: 49.151648, lng: 19.14593 },
    { lat: 49.1517154, lng: 19.1458002 },
    { lat: 49.151873, lng: 19.145503 },
    { lat: 49.152046, lng: 19.145179 },
    { lat: 49.152652, lng: 19.144526 },
    { lat: 49.153032, lng: 19.144295 },
    { lat: 49.153427, lng: 19.143829 },
    { lat: 49.153584, lng: 19.143479 },
    { lat: 49.153674, lng: 19.142843 },
    { lat: 49.153704, lng: 19.142365 },
    { lat: 49.153702, lng: 19.142092 },
    { lat: 49.1536602, lng: 19.1415522 },
    { lat: 49.153664, lng: 19.141413 },
    { lat: 49.153654, lng: 19.141304 },
    { lat: 49.153618, lng: 19.140918 },
    { lat: 49.153605, lng: 19.140855 },
    { lat: 49.153518, lng: 19.140694 },
    { lat: 49.1534177, lng: 19.1405432 },
    { lat: 49.153315, lng: 19.140094 },
    { lat: 49.153394, lng: 19.139508 },
    { lat: 49.15352, lng: 19.139538 },
    { lat: 49.153651, lng: 19.13951 },
    { lat: 49.153846, lng: 19.139482 },
    { lat: 49.153974, lng: 19.13948 },
    { lat: 49.15409, lng: 19.139479 },
    { lat: 49.154235, lng: 19.139466 },
    { lat: 49.154269, lng: 19.139466 },
    { lat: 49.15449, lng: 19.139464 },
    { lat: 49.155447, lng: 19.139266 },
    { lat: 49.156138, lng: 19.138982 },
    { lat: 49.15678, lng: 19.138794 },
    { lat: 49.157301, lng: 19.138569 },
    { lat: 49.158094, lng: 19.138242 },
    { lat: 49.158476, lng: 19.138137 },
    { lat: 49.158822, lng: 19.138026 },
    { lat: 49.158909, lng: 19.138002 },
    { lat: 49.159353, lng: 19.137533 },
    { lat: 49.159721, lng: 19.136858 },
    { lat: 49.160245, lng: 19.136053 },
    { lat: 49.160327, lng: 19.135935 },
    { lat: 49.160413, lng: 19.1358 },
    { lat: 49.160915, lng: 19.134946 },
    { lat: 49.160946, lng: 19.134892 },
    { lat: 49.161198, lng: 19.13456 },
    { lat: 49.161501, lng: 19.134255 },
    { lat: 49.161609, lng: 19.134168 },
    { lat: 49.162145, lng: 19.13388 },
    { lat: 49.162876, lng: 19.133935 },
    { lat: 49.162993, lng: 19.133935 },
    { lat: 49.163682, lng: 19.134314 },
    { lat: 49.164035, lng: 19.134508 },
    { lat: 49.164085, lng: 19.134534 },
    { lat: 49.164665, lng: 19.134832 },
    { lat: 49.165205, lng: 19.135017 },
    { lat: 49.165587, lng: 19.135071 },
    { lat: 49.166307, lng: 19.135086 },
    { lat: 49.167303, lng: 19.13501 },
    { lat: 49.16733, lng: 19.135001 },
    { lat: 49.167674, lng: 19.134887 },
    { lat: 49.167816, lng: 19.13484 },
  ],
  Krivá: [
    { lat: 49.2510561, lng: 19.5143565 },
    { lat: 49.2508255, lng: 19.5149065 },
    { lat: 49.2508152, lng: 19.5149303 },
    { lat: 49.2506958, lng: 19.5152258 },
    { lat: 49.25055, lng: 19.515612 },
    { lat: 49.2501313, lng: 19.515747 },
    { lat: 49.2489962, lng: 19.5158106 },
    { lat: 49.2480346, lng: 19.5155002 },
    { lat: 49.2472684, lng: 19.5158733 },
    { lat: 49.2456719, lng: 19.5167151 },
    { lat: 49.2455197, lng: 19.516763 },
    { lat: 49.245204, lng: 19.5168615 },
    { lat: 49.2449563, lng: 19.516939 },
    { lat: 49.2438855, lng: 19.5170044 },
    { lat: 49.2433654, lng: 19.5170877 },
    { lat: 49.2427609, lng: 19.5170957 },
    { lat: 49.2415086, lng: 19.5172633 },
    { lat: 49.2410071, lng: 19.5175329 },
    { lat: 49.2409769, lng: 19.5175584 },
    { lat: 49.2390941, lng: 19.51845 },
    { lat: 49.2389859, lng: 19.5185721 },
    { lat: 49.2388916, lng: 19.5186784 },
    { lat: 49.2378444, lng: 19.5198602 },
    { lat: 49.2376131, lng: 19.5201213 },
    { lat: 49.2363612, lng: 19.5215327 },
    { lat: 49.2360693, lng: 19.5230932 },
    { lat: 49.2360423, lng: 19.5247053 },
    { lat: 49.2360075, lng: 19.5250515 },
    { lat: 49.2360519, lng: 19.5264768 },
    { lat: 49.236117, lng: 19.528936 },
    { lat: 49.236123, lng: 19.529039 },
    { lat: 49.236171, lng: 19.529496 },
    { lat: 49.236288, lng: 19.530472 },
    { lat: 49.236334, lng: 19.530947 },
    { lat: 49.236571, lng: 19.533365 },
    { lat: 49.236778, lng: 19.534589 },
    { lat: 49.236932, lng: 19.535743 },
    { lat: 49.236941, lng: 19.535811 },
    { lat: 49.236986, lng: 19.536147 },
    { lat: 49.237182, lng: 19.538368 },
    { lat: 49.237323, lng: 19.540084 },
    { lat: 49.237553, lng: 19.541512 },
    { lat: 49.238297, lng: 19.543434 },
    { lat: 49.238689, lng: 19.544857 },
    { lat: 49.239445, lng: 19.547177 },
    { lat: 49.239829, lng: 19.548352 },
    { lat: 49.239865, lng: 19.548433 },
    { lat: 49.240838, lng: 19.552303 },
    { lat: 49.24116, lng: 19.553306 },
    { lat: 49.242009, lng: 19.554759 },
    { lat: 49.242343, lng: 19.555729 },
    { lat: 49.242363, lng: 19.555789 },
    { lat: 49.24238, lng: 19.555959 },
    { lat: 49.242482, lng: 19.557025 },
    { lat: 49.24249, lng: 19.557093 },
    { lat: 49.242461, lng: 19.557881 },
    { lat: 49.242902, lng: 19.555748 },
    { lat: 49.243017, lng: 19.554891 },
    { lat: 49.243121, lng: 19.554296 },
    { lat: 49.24326, lng: 19.553418 },
    { lat: 49.243425, lng: 19.55242 },
    { lat: 49.243635, lng: 19.550939 },
    { lat: 49.243452, lng: 19.549461 },
    { lat: 49.243493, lng: 19.547831 },
    { lat: 49.243653, lng: 19.547109 },
    { lat: 49.244263, lng: 19.545904 },
    { lat: 49.245031, lng: 19.54497 },
    { lat: 49.245871, lng: 19.544757 },
    { lat: 49.24683, lng: 19.544514 },
    { lat: 49.249415, lng: 19.544089 },
    { lat: 49.250616, lng: 19.543814 },
    { lat: 49.250693, lng: 19.543778 },
    { lat: 49.251055, lng: 19.543713 },
    { lat: 49.254496, lng: 19.542097 },
    { lat: 49.254543, lng: 19.542075 },
    { lat: 49.255043, lng: 19.54184 },
    { lat: 49.257947, lng: 19.541103 },
    { lat: 49.25947, lng: 19.540593 },
    { lat: 49.261547, lng: 19.540443 },
    { lat: 49.263245, lng: 19.540617 },
    { lat: 49.2635372, lng: 19.5405449 },
    { lat: 49.264011, lng: 19.540428 },
    { lat: 49.264781, lng: 19.540326 },
    { lat: 49.265148, lng: 19.540229 },
    { lat: 49.265446, lng: 19.540192 },
    { lat: 49.26624, lng: 19.540086 },
    { lat: 49.266542, lng: 19.539954 },
    { lat: 49.266684, lng: 19.539893 },
    { lat: 49.267072, lng: 19.539874 },
    { lat: 49.267432, lng: 19.540088 },
    { lat: 49.267737, lng: 19.540204 },
    { lat: 49.268468, lng: 19.540036 },
    { lat: 49.26862, lng: 19.539978 },
    { lat: 49.269322, lng: 19.540012 },
    { lat: 49.269861, lng: 19.539944 },
    { lat: 49.270215, lng: 19.539824 },
    { lat: 49.270502, lng: 19.539555 },
    { lat: 49.270561, lng: 19.539502 },
    { lat: 49.270762, lng: 19.539483 },
    { lat: 49.270791, lng: 19.539274 },
    { lat: 49.27106, lng: 19.538622 },
    { lat: 49.271114, lng: 19.538447 },
    { lat: 49.271417, lng: 19.537831 },
    { lat: 49.271532, lng: 19.537384 },
    { lat: 49.271536, lng: 19.5368108 },
    { lat: 49.271538, lng: 19.536522 },
    { lat: 49.270372, lng: 19.535367 },
    { lat: 49.271012, lng: 19.533213 },
    { lat: 49.272224, lng: 19.529613 },
    { lat: 49.272411, lng: 19.528988 },
    { lat: 49.273113, lng: 19.526645 },
    { lat: 49.273567, lng: 19.525206 },
    { lat: 49.273862, lng: 19.523953 },
    { lat: 49.274246, lng: 19.52297 },
    { lat: 49.27527, lng: 19.518933 },
    { lat: 49.276143, lng: 19.517588 },
    { lat: 49.276186, lng: 19.517541 },
    { lat: 49.27631, lng: 19.517232 },
    { lat: 49.276411, lng: 19.517057 },
    { lat: 49.276508, lng: 19.516956 },
    { lat: 49.276601, lng: 19.516725 },
    { lat: 49.276642, lng: 19.516662 },
    { lat: 49.276712, lng: 19.516505 },
    { lat: 49.276774, lng: 19.516576 },
    { lat: 49.276899, lng: 19.516349 },
    { lat: 49.277144, lng: 19.516121 },
    { lat: 49.277553, lng: 19.515616 },
    { lat: 49.277958, lng: 19.515173 },
    { lat: 49.278356, lng: 19.514792 },
    { lat: 49.278483, lng: 19.514627 },
    { lat: 49.278593, lng: 19.514489 },
    { lat: 49.278568, lng: 19.51434 },
    { lat: 49.278576, lng: 19.514255 },
    { lat: 49.27854, lng: 19.513836 },
    { lat: 49.278568, lng: 19.513688 },
    { lat: 49.27856, lng: 19.513544 },
    { lat: 49.278648, lng: 19.513303 },
    { lat: 49.2787, lng: 19.512948 },
    { lat: 49.278696, lng: 19.512635 },
    { lat: 49.278677, lng: 19.512439 },
    { lat: 49.278639, lng: 19.512247 },
    { lat: 49.278643, lng: 19.512057 },
    { lat: 49.278574, lng: 19.511859 },
    { lat: 49.278473, lng: 19.511639 },
    { lat: 49.278341, lng: 19.511465 },
    { lat: 49.278281, lng: 19.511223 },
    { lat: 49.27827, lng: 19.510885 },
    { lat: 49.278155, lng: 19.510913 },
    { lat: 49.278141, lng: 19.510806 },
    { lat: 49.27814, lng: 19.510679 },
    { lat: 49.278042, lng: 19.510469 },
    { lat: 49.27793, lng: 19.510379 },
    { lat: 49.277941, lng: 19.510173 },
    { lat: 49.277881, lng: 19.510038 },
    { lat: 49.277755, lng: 19.509874 },
    { lat: 49.277599, lng: 19.509258 },
    { lat: 49.277502, lng: 19.508972 },
    { lat: 49.277469, lng: 19.508506 },
    { lat: 49.277422, lng: 19.507893 },
    { lat: 49.276497, lng: 19.507161 },
    { lat: 49.276388, lng: 19.507124 },
    { lat: 49.276325, lng: 19.502688 },
    { lat: 49.277371, lng: 19.502385 },
    { lat: 49.277875, lng: 19.502062 },
    { lat: 49.278527, lng: 19.501967 },
    { lat: 49.279169, lng: 19.501496 },
    { lat: 49.279304, lng: 19.501238 },
    { lat: 49.279634, lng: 19.500571 },
    { lat: 49.280149, lng: 19.499796 },
    { lat: 49.280601, lng: 19.499273 },
    { lat: 49.28094, lng: 19.498968 },
    { lat: 49.281811, lng: 19.498206 },
    { lat: 49.282557, lng: 19.498458 },
    { lat: 49.282752, lng: 19.496955 },
    { lat: 49.283106, lng: 19.496144 },
    { lat: 49.283269, lng: 19.495396 },
    { lat: 49.283513, lng: 19.494349 },
    { lat: 49.284141, lng: 19.494685 },
    { lat: 49.28677, lng: 19.496701 },
    { lat: 49.286837, lng: 19.496464 },
    { lat: 49.287237, lng: 19.495878 },
    { lat: 49.287332, lng: 19.495916 },
    { lat: 49.287507, lng: 19.495434 },
    { lat: 49.287602, lng: 19.495039 },
    { lat: 49.287663, lng: 19.494696 },
    { lat: 49.287711, lng: 19.494545 },
    { lat: 49.287909, lng: 19.49396 },
    { lat: 49.288111, lng: 19.493746 },
    { lat: 49.288171, lng: 19.493683 },
    { lat: 49.28821, lng: 19.493218 },
    { lat: 49.288234, lng: 19.492913 },
    { lat: 49.288244, lng: 19.492844 },
    { lat: 49.288305, lng: 19.49279 },
    { lat: 49.288323, lng: 19.49277 },
    { lat: 49.288398, lng: 19.492584 },
    { lat: 49.288434, lng: 19.492194 },
    { lat: 49.288417, lng: 19.491906 },
    { lat: 49.288347, lng: 19.491585 },
    { lat: 49.288796, lng: 19.491798 },
    { lat: 49.289516, lng: 19.492277 },
    { lat: 49.289997, lng: 19.492406 },
    { lat: 49.291098, lng: 19.492083 },
    { lat: 49.291463, lng: 19.492058 },
    { lat: 49.292896, lng: 19.490493 },
    { lat: 49.293771, lng: 19.489672 },
    { lat: 49.293874, lng: 19.48934 },
    { lat: 49.294246, lng: 19.488137 },
    { lat: 49.294219, lng: 19.488167 },
    { lat: 49.294255, lng: 19.487993 },
    { lat: 49.294177, lng: 19.487809 },
    { lat: 49.294184, lng: 19.487516 },
    { lat: 49.294326, lng: 19.487159 },
    { lat: 49.294325, lng: 19.486971 },
    { lat: 49.294324, lng: 19.486931 },
    { lat: 49.294384, lng: 19.486759 },
    { lat: 49.294397, lng: 19.486666 },
    { lat: 49.294635, lng: 19.485656 },
    { lat: 49.294706, lng: 19.485366 },
    { lat: 49.294705, lng: 19.485136 },
    { lat: 49.294582, lng: 19.48498 },
    { lat: 49.294432, lng: 19.484856 },
    { lat: 49.294335, lng: 19.484669 },
    { lat: 49.294374, lng: 19.484326 },
    { lat: 49.294463, lng: 19.483867 },
    { lat: 49.294553, lng: 19.483377 },
    { lat: 49.29453, lng: 19.483359 },
    { lat: 49.294429, lng: 19.483082 },
    { lat: 49.294356, lng: 19.482646 },
    { lat: 49.294066, lng: 19.481824 },
    { lat: 49.293866, lng: 19.480017 },
    { lat: 49.293681, lng: 19.478638 },
    { lat: 49.293687, lng: 19.478081 },
    { lat: 49.293543, lng: 19.477647 },
    { lat: 49.29337, lng: 19.477201 },
    { lat: 49.293183, lng: 19.476886 },
    { lat: 49.292696, lng: 19.475271 },
    { lat: 49.292583, lng: 19.474882 },
    { lat: 49.292561, lng: 19.474366 },
    { lat: 49.292628, lng: 19.474017 },
    { lat: 49.29272, lng: 19.473118 },
    { lat: 49.292095, lng: 19.472828 },
    { lat: 49.292051, lng: 19.47235 },
    { lat: 49.29199, lng: 19.471467 },
    { lat: 49.292376, lng: 19.470441 },
    { lat: 49.292258, lng: 19.469284 },
    { lat: 49.292298, lng: 19.468877 },
    { lat: 49.291994, lng: 19.468283 },
    { lat: 49.292151, lng: 19.46771 },
    { lat: 49.292134, lng: 19.467407 },
    { lat: 49.292144, lng: 19.467158 },
    { lat: 49.292227, lng: 19.466988 },
    { lat: 49.292387, lng: 19.466815 },
    { lat: 49.293052, lng: 19.465556 },
    { lat: 49.292493, lng: 19.464247 },
    { lat: 49.292913, lng: 19.463786 },
    { lat: 49.292799, lng: 19.463059 },
    { lat: 49.292598, lng: 19.462272 },
    { lat: 49.2923121, lng: 19.461316 },
    { lat: 49.2919535, lng: 19.4618263 },
    { lat: 49.2917439, lng: 19.4621715 },
    { lat: 49.292281, lng: 19.4635596 },
    { lat: 49.2921886, lng: 19.4640771 },
    { lat: 49.2920256, lng: 19.4644509 },
    { lat: 49.2917566, lng: 19.4643859 },
    { lat: 49.2913308, lng: 19.4647302 },
    { lat: 49.2909245, lng: 19.4636876 },
    { lat: 49.2908307, lng: 19.463654 },
    { lat: 49.2908138, lng: 19.4634814 },
    { lat: 49.2906977, lng: 19.4631914 },
    { lat: 49.2907823, lng: 19.4624777 },
    { lat: 49.2904513, lng: 19.4620421 },
    { lat: 49.2898365, lng: 19.4617594 },
    { lat: 49.2889179, lng: 19.4620443 },
    { lat: 49.2882174, lng: 19.4624726 },
    { lat: 49.2877877, lng: 19.4627306 },
    { lat: 49.2875259, lng: 19.4627721 },
    { lat: 49.2874321, lng: 19.4628901 },
    { lat: 49.2871992, lng: 19.4624047 },
    { lat: 49.2870248, lng: 19.4624104 },
    { lat: 49.2869934, lng: 19.4622665 },
    { lat: 49.2870606, lng: 19.462085 },
    { lat: 49.2868295, lng: 19.4618658 },
    { lat: 49.286596, lng: 19.4615873 },
    { lat: 49.2862644, lng: 19.4611656 },
    { lat: 49.2860135, lng: 19.4610019 },
    { lat: 49.2855844, lng: 19.46059 },
    { lat: 49.2855292, lng: 19.4604923 },
    { lat: 49.2855311, lng: 19.4603589 },
    { lat: 49.2854719, lng: 19.4603252 },
    { lat: 49.2846019, lng: 19.4602443 },
    { lat: 49.2832437, lng: 19.4597716 },
    { lat: 49.2826538, lng: 19.4598569 },
    { lat: 49.2821431, lng: 19.4599952 },
    { lat: 49.2816324, lng: 19.4603351 },
    { lat: 49.2810817, lng: 19.4615662 },
    { lat: 49.2810295, lng: 19.461683 },
    { lat: 49.2809999, lng: 19.4617493 },
    { lat: 49.2809641, lng: 19.4618278 },
    { lat: 49.2809173, lng: 19.4619339 },
    { lat: 49.2808661, lng: 19.4620481 },
    { lat: 49.2806607, lng: 19.4625068 },
    { lat: 49.2803704, lng: 19.4631559 },
    { lat: 49.2803324, lng: 19.4632325 },
    { lat: 49.2801496, lng: 19.4635975 },
    { lat: 49.2801392, lng: 19.4636184 },
    { lat: 49.2801188, lng: 19.4636618 },
    { lat: 49.2800714, lng: 19.4637618 },
    { lat: 49.2800548, lng: 19.4637903 },
    { lat: 49.2800477, lng: 19.4638044 },
    { lat: 49.2798192, lng: 19.4640068 },
    { lat: 49.2794285, lng: 19.4642852 },
    { lat: 49.2792338, lng: 19.4643855 },
    { lat: 49.2790871, lng: 19.4646051 },
    { lat: 49.2789873, lng: 19.4645646 },
    { lat: 49.2787881, lng: 19.464814 },
    { lat: 49.2786719, lng: 19.4648586 },
    { lat: 49.2781126, lng: 19.4653914 },
    { lat: 49.2778541, lng: 19.4651128 },
    { lat: 49.2778194, lng: 19.4651392 },
    { lat: 49.2777368, lng: 19.465208 },
    { lat: 49.2776947, lng: 19.4652464 },
    { lat: 49.2771702, lng: 19.4659707 },
    { lat: 49.2768614, lng: 19.4663618 },
    { lat: 49.2763243, lng: 19.4673296 },
    { lat: 49.2761081, lng: 19.4676508 },
    { lat: 49.2760019, lng: 19.4678156 },
    { lat: 49.2755347, lng: 19.4684868 },
    { lat: 49.2753294, lng: 19.4689985 },
    { lat: 49.2752668, lng: 19.469156 },
    { lat: 49.2749952, lng: 19.4701371 },
    { lat: 49.2743735, lng: 19.4730138 },
    { lat: 49.2739854, lng: 19.4739629 },
    { lat: 49.2739072, lng: 19.4741539 },
    { lat: 49.2734695, lng: 19.4747312 },
    { lat: 49.2729547, lng: 19.475393 },
    { lat: 49.2722992, lng: 19.4762888 },
    { lat: 49.2720261, lng: 19.4765479 },
    { lat: 49.2714249, lng: 19.4769283 },
    { lat: 49.2711672, lng: 19.4770082 },
    { lat: 49.2706804, lng: 19.4769831 },
    { lat: 49.2701701, lng: 19.4764204 },
    { lat: 49.2697773, lng: 19.4764271 },
    { lat: 49.2696207, lng: 19.476674 },
    { lat: 49.2690652, lng: 19.4772625 },
    { lat: 49.268679, lng: 19.4775174 },
    { lat: 49.2679124, lng: 19.4780227 },
    { lat: 49.2674317, lng: 19.4782289 },
    { lat: 49.2672167, lng: 19.4783612 },
    { lat: 49.2669257, lng: 19.4786215 },
    { lat: 49.2666165, lng: 19.478809 },
    { lat: 49.2664263, lng: 19.4787388 },
    { lat: 49.2657601, lng: 19.4789775 },
    { lat: 49.2647213, lng: 19.4794238 },
    { lat: 49.2641409, lng: 19.4798409 },
    { lat: 49.2637826, lng: 19.4802815 },
    { lat: 49.2633828, lng: 19.4809484 },
    { lat: 49.2630125, lng: 19.4822154 },
    { lat: 49.2629893, lng: 19.4822973 },
    { lat: 49.2627936, lng: 19.4829676 },
    { lat: 49.2628471, lng: 19.4830791 },
    { lat: 49.2631084, lng: 19.483815 },
    { lat: 49.2633181, lng: 19.4844611 },
    { lat: 49.2634231, lng: 19.4849091 },
    { lat: 49.2633226, lng: 19.4851995 },
    { lat: 49.2628911, lng: 19.4860624 },
    { lat: 49.2628508, lng: 19.4861875 },
    { lat: 49.2627534, lng: 19.4862784 },
    { lat: 49.2626445, lng: 19.4862871 },
    { lat: 49.2626221, lng: 19.4862885 },
    { lat: 49.2623425, lng: 19.4865297 },
    { lat: 49.2621157, lng: 19.4867239 },
    { lat: 49.261614, lng: 19.4869355 },
    { lat: 49.2608323, lng: 19.4874588 },
    { lat: 49.2603395, lng: 19.4877196 },
    { lat: 49.2600782, lng: 19.4879686 },
    { lat: 49.2596369, lng: 19.4883971 },
    { lat: 49.2591389, lng: 19.4888669 },
    { lat: 49.2583713, lng: 19.4896451 },
    { lat: 49.2581427, lng: 19.4900223 },
    { lat: 49.257966, lng: 19.4901491 },
    { lat: 49.2571743, lng: 19.4905251 },
    { lat: 49.2571347, lng: 19.4904488 },
    { lat: 49.2565234, lng: 19.4909033 },
    { lat: 49.2562989, lng: 19.4913183 },
    { lat: 49.2561421, lng: 19.4914606 },
    { lat: 49.2560019, lng: 19.4916238 },
    { lat: 49.2557831, lng: 19.4921092 },
    { lat: 49.2555401, lng: 19.4932758 },
    { lat: 49.2554656, lng: 19.4935478 },
    { lat: 49.255319, lng: 19.4940843 },
    { lat: 49.2551765, lng: 19.4948166 },
    { lat: 49.2551445, lng: 19.4951742 },
    { lat: 49.2550408, lng: 19.4953714 },
    { lat: 49.2551354, lng: 19.4964016 },
    { lat: 49.2552345, lng: 19.4981795 },
    { lat: 49.2548078, lng: 19.499275 },
    { lat: 49.254757, lng: 19.4996415 },
    { lat: 49.2548774, lng: 19.5004006 },
    { lat: 49.2547748, lng: 19.5008945 },
    { lat: 49.2545402, lng: 19.501435 },
    { lat: 49.2543721, lng: 19.5020445 },
    { lat: 49.2543684, lng: 19.5030776 },
    { lat: 49.2543656, lng: 19.5036561 },
    { lat: 49.2543757, lng: 19.5039245 },
    { lat: 49.2543812, lng: 19.5040721 },
    { lat: 49.2541584, lng: 19.50455 },
    { lat: 49.2539106, lng: 19.5053385 },
    { lat: 49.2537947, lng: 19.5055666 },
    { lat: 49.2534681, lng: 19.5065341 },
    { lat: 49.2529218, lng: 19.5081194 },
    { lat: 49.2524633, lng: 19.5090375 },
    { lat: 49.2520604, lng: 19.5106046 },
    { lat: 49.2519588, lng: 19.5108935 },
    { lat: 49.2519464, lng: 19.5113414 },
    { lat: 49.2519402, lng: 19.5116526 },
    { lat: 49.2517649, lng: 19.5122761 },
    { lat: 49.2515652, lng: 19.5127709 },
    { lat: 49.251472, lng: 19.513144 },
    { lat: 49.2512889, lng: 19.5138012 },
    { lat: 49.2510561, lng: 19.5143565 },
  ],
  Leštiny: [
    { lat: 49.2011778, lng: 19.3371287 },
    { lat: 49.2010162, lng: 19.3370982 },
    { lat: 49.2008241, lng: 19.3371563 },
    { lat: 49.2004329, lng: 19.3374627 },
    { lat: 49.2001887, lng: 19.3377126 },
    { lat: 49.2000383, lng: 19.3380595 },
    { lat: 49.1990023, lng: 19.3384362 },
    { lat: 49.1990685, lng: 19.3377108 },
    { lat: 49.1985251, lng: 19.3369441 },
    { lat: 49.1984277, lng: 19.3369313 },
    { lat: 49.1983246, lng: 19.3369181 },
    { lat: 49.1980637, lng: 19.3368841 },
    { lat: 49.1975485, lng: 19.3368062 },
    { lat: 49.1971968, lng: 19.3383373 },
    { lat: 49.1969677, lng: 19.3384273 },
    { lat: 49.1965843, lng: 19.3385894 },
    { lat: 49.1964595, lng: 19.3386182 },
    { lat: 49.1962266, lng: 19.3386719 },
    { lat: 49.1958472, lng: 19.3386829 },
    { lat: 49.1952424, lng: 19.3388083 },
    { lat: 49.1951128, lng: 19.3388351 },
    { lat: 49.1947644, lng: 19.3393093 },
    { lat: 49.1944346, lng: 19.3396518 },
    { lat: 49.1944206, lng: 19.3396666 },
    { lat: 49.1944132, lng: 19.3396743 },
    { lat: 49.1943957, lng: 19.3396892 },
    { lat: 49.1941498, lng: 19.3398974 },
    { lat: 49.1939426, lng: 19.3400455 },
    { lat: 49.1934391, lng: 19.340336 },
    { lat: 49.1929366, lng: 19.3406736 },
    { lat: 49.1922626, lng: 19.3408877 },
    { lat: 49.1922425, lng: 19.3408938 },
    { lat: 49.1917148, lng: 19.3411708 },
    { lat: 49.1911844, lng: 19.3408005 },
    { lat: 49.1909876, lng: 19.3406625 },
    { lat: 49.1909742, lng: 19.3406185 },
    { lat: 49.1907783, lng: 19.3406962 },
    { lat: 49.1905859, lng: 19.3407728 },
    { lat: 49.1905839, lng: 19.3407752 },
    { lat: 49.1900292, lng: 19.3412777 },
    { lat: 49.1898104, lng: 19.3414851 },
    { lat: 49.1898285, lng: 19.3415123 },
    { lat: 49.1898097, lng: 19.3415461 },
    { lat: 49.1897953, lng: 19.3415714 },
    { lat: 49.1897732, lng: 19.3416084 },
    { lat: 49.189766, lng: 19.3416197 },
    { lat: 49.1894887, lng: 19.3420763 },
    { lat: 49.1894731, lng: 19.3420996 },
    { lat: 49.1894416, lng: 19.3421474 },
    { lat: 49.1892451, lng: 19.3419012 },
    { lat: 49.1891284, lng: 19.3416748 },
    { lat: 49.1891247, lng: 19.3416676 },
    { lat: 49.1890418, lng: 19.3414609 },
    { lat: 49.1888178, lng: 19.3413983 },
    { lat: 49.1888099, lng: 19.3413952 },
    { lat: 49.1886294, lng: 19.3412236 },
    { lat: 49.1885927, lng: 19.341308 },
    { lat: 49.1885675, lng: 19.3413435 },
    { lat: 49.1885073, lng: 19.3414291 },
    { lat: 49.1882325, lng: 19.3418166 },
    { lat: 49.1880355, lng: 19.3418717 },
    { lat: 49.1875445, lng: 19.3419749 },
    { lat: 49.1872425, lng: 19.3422367 },
    { lat: 49.1869316, lng: 19.3429827 },
    { lat: 49.186816, lng: 19.343186 },
    { lat: 49.1866668, lng: 19.3431969 },
    { lat: 49.1865416, lng: 19.3433165 },
    { lat: 49.1859799, lng: 19.3434736 },
    { lat: 49.1859153, lng: 19.3434914 },
    { lat: 49.1858503, lng: 19.3436654 },
    { lat: 49.1857833, lng: 19.343846 },
    { lat: 49.1855756, lng: 19.3441253 },
    { lat: 49.1855454, lng: 19.3441556 },
    { lat: 49.1853154, lng: 19.3443923 },
    { lat: 49.1851722, lng: 19.3445099 },
    { lat: 49.1848444, lng: 19.344535 },
    { lat: 49.1844062, lng: 19.3450431 },
    { lat: 49.1843789, lng: 19.3454675 },
    { lat: 49.1842813, lng: 19.3459322 },
    { lat: 49.1840393, lng: 19.3458935 },
    { lat: 49.1835459, lng: 19.3456568 },
    { lat: 49.183315, lng: 19.3461378 },
    { lat: 49.1830508, lng: 19.3463901 },
    { lat: 49.1830061, lng: 19.3474036 },
    { lat: 49.1826877, lng: 19.3471755 },
    { lat: 49.1821593, lng: 19.3467964 },
    { lat: 49.181871, lng: 19.3465614 },
    { lat: 49.1816232, lng: 19.3464891 },
    { lat: 49.1815699, lng: 19.3464618 },
    { lat: 49.1814383, lng: 19.3466095 },
    { lat: 49.1813271, lng: 19.3469776 },
    { lat: 49.1813224, lng: 19.3469975 },
    { lat: 49.181295, lng: 19.3470373 },
    { lat: 49.1809523, lng: 19.3475139 },
    { lat: 49.1809122, lng: 19.3475706 },
    { lat: 49.1806305, lng: 19.3476666 },
    { lat: 49.180282, lng: 19.3477112 },
    { lat: 49.1795731, lng: 19.3480939 },
    { lat: 49.1791912, lng: 19.3480724 },
    { lat: 49.1785587, lng: 19.3484723 },
    { lat: 49.1783127, lng: 19.348439 },
    { lat: 49.178208, lng: 19.3484674 },
    { lat: 49.178029, lng: 19.3485166 },
    { lat: 49.1777705, lng: 19.3485096 },
    { lat: 49.1773233, lng: 19.3485362 },
    { lat: 49.1767867, lng: 19.3486803 },
    { lat: 49.1761457, lng: 19.3491852 },
    { lat: 49.1757181, lng: 19.3494874 },
    { lat: 49.175376, lng: 19.3496653 },
    { lat: 49.1752705, lng: 19.3497219 },
    { lat: 49.1751955, lng: 19.3497596 },
    { lat: 49.1751483, lng: 19.3497842 },
    { lat: 49.1748037, lng: 19.3499595 },
    { lat: 49.1746263, lng: 19.3497059 },
    { lat: 49.1743731, lng: 19.3498809 },
    { lat: 49.1742207, lng: 19.3500712 },
    { lat: 49.1739496, lng: 19.3501765 },
    { lat: 49.1738012, lng: 19.3503239 },
    { lat: 49.1735549, lng: 19.3504106 },
    { lat: 49.1733165, lng: 19.3506841 },
    { lat: 49.172925, lng: 19.3512269 },
    { lat: 49.1726428, lng: 19.3515736 },
    { lat: 49.1724443, lng: 19.3518557 },
    { lat: 49.1722626, lng: 19.3521148 },
    { lat: 49.172006, lng: 19.3525091 },
    { lat: 49.1716451, lng: 19.3534122 },
    { lat: 49.1708546, lng: 19.3543137 },
    { lat: 49.1707395, lng: 19.3549105 },
    { lat: 49.1707779, lng: 19.355556 },
    { lat: 49.1706498, lng: 19.3563839 },
    { lat: 49.170623, lng: 19.357807 },
    { lat: 49.17065, lng: 19.358044 },
    { lat: 49.170673, lng: 19.358237 },
    { lat: 49.170875, lng: 19.359008 },
    { lat: 49.171487, lng: 19.359854 },
    { lat: 49.172155, lng: 19.361074 },
    { lat: 49.172269, lng: 19.36164 },
    { lat: 49.173051, lng: 19.362782 },
    { lat: 49.173387, lng: 19.363598 },
    { lat: 49.173518, lng: 19.36446 },
    { lat: 49.173777, lng: 19.364858 },
    { lat: 49.173763, lng: 19.365473 },
    { lat: 49.173992, lng: 19.365895 },
    { lat: 49.174164, lng: 19.365976 },
    { lat: 49.174337, lng: 19.366449 },
    { lat: 49.174609, lng: 19.36718 },
    { lat: 49.174591, lng: 19.367591 },
    { lat: 49.174651, lng: 19.367793 },
    { lat: 49.17481, lng: 19.36833 },
    { lat: 49.174888, lng: 19.368611 },
    { lat: 49.175151, lng: 19.36907 },
    { lat: 49.1762, lng: 19.370327 },
    { lat: 49.176473, lng: 19.371361 },
    { lat: 49.17675, lng: 19.372416 },
    { lat: 49.17703, lng: 19.373184 },
    { lat: 49.176881, lng: 19.373411 },
    { lat: 49.176553, lng: 19.373926 },
    { lat: 49.176488, lng: 19.374022 },
    { lat: 49.176262, lng: 19.374619 },
    { lat: 49.17624, lng: 19.374677 },
    { lat: 49.176137, lng: 19.37486 },
    { lat: 49.175609, lng: 19.375878 },
    { lat: 49.175393, lng: 19.375977 },
    { lat: 49.175039, lng: 19.376089 },
    { lat: 49.174618, lng: 19.376223 },
    { lat: 49.174171, lng: 19.376673 },
    { lat: 49.174093, lng: 19.376751 },
    { lat: 49.174052, lng: 19.376794 },
    { lat: 49.174006, lng: 19.37684 },
    { lat: 49.173987, lng: 19.376859 },
    { lat: 49.17396, lng: 19.376886 },
    { lat: 49.17394, lng: 19.377553 },
    { lat: 49.174132, lng: 19.378554 },
    { lat: 49.174205, lng: 19.378757 },
    { lat: 49.174221, lng: 19.378801 },
    { lat: 49.174263, lng: 19.378923 },
    { lat: 49.174332, lng: 19.379118 },
    { lat: 49.174379, lng: 19.379343 },
    { lat: 49.174379, lng: 19.379392 },
    { lat: 49.174385, lng: 19.379856 },
    { lat: 49.174386, lng: 19.38001 },
    { lat: 49.174389, lng: 19.380256 },
    { lat: 49.174392, lng: 19.380435 },
    { lat: 49.174393, lng: 19.380645 },
    { lat: 49.174407, lng: 19.380691 },
    { lat: 49.174523, lng: 19.381099 },
    { lat: 49.17465, lng: 19.381546 },
    { lat: 49.174786, lng: 19.382029 },
    { lat: 49.174829, lng: 19.382177 },
    { lat: 49.174959, lng: 19.382523 },
    { lat: 49.175137, lng: 19.382999 },
    { lat: 49.175282, lng: 19.383384 },
    { lat: 49.175303, lng: 19.383614 },
    { lat: 49.175198, lng: 19.383878 },
    { lat: 49.17519, lng: 19.384047 },
    { lat: 49.175184, lng: 19.384205 },
    { lat: 49.175242, lng: 19.384541 },
    { lat: 49.175265, lng: 19.384774 },
    { lat: 49.1754, lng: 19.384925 },
    { lat: 49.1757275, lng: 19.3848075 },
    { lat: 49.1757961, lng: 19.3847799 },
    { lat: 49.1762541, lng: 19.3845946 },
    { lat: 49.1764146, lng: 19.3845127 },
    { lat: 49.1767833, lng: 19.3843235 },
    { lat: 49.1779463, lng: 19.3837209 },
    { lat: 49.178994, lng: 19.3832294 },
    { lat: 49.1796444, lng: 19.3829242 },
    { lat: 49.1797558, lng: 19.3828011 },
    { lat: 49.1798472, lng: 19.3827062 },
    { lat: 49.1798993, lng: 19.3826382 },
    { lat: 49.1801979, lng: 19.3824678 },
    { lat: 49.1803867, lng: 19.3822894 },
    { lat: 49.1805403, lng: 19.3821492 },
    { lat: 49.1810014, lng: 19.3817244 },
    { lat: 49.1813311, lng: 19.3815679 },
    { lat: 49.1816534, lng: 19.3807504 },
    { lat: 49.1824431, lng: 19.3801648 },
    { lat: 49.1827144, lng: 19.3799166 },
    { lat: 49.1829943, lng: 19.379661 },
    { lat: 49.1826975, lng: 19.3791908 },
    { lat: 49.1822623, lng: 19.378298 },
    { lat: 49.1822393, lng: 19.3782159 },
    { lat: 49.182234, lng: 19.3781981 },
    { lat: 49.1822663, lng: 19.3781661 },
    { lat: 49.1822936, lng: 19.3781239 },
    { lat: 49.1823218, lng: 19.3780754 },
    { lat: 49.1823646, lng: 19.3779814 },
    { lat: 49.1824844, lng: 19.3779874 },
    { lat: 49.1826306, lng: 19.3779067 },
    { lat: 49.1827074, lng: 19.3778893 },
    { lat: 49.182815, lng: 19.3777814 },
    { lat: 49.1829004, lng: 19.3777246 },
    { lat: 49.1829985, lng: 19.3777009 },
    { lat: 49.1830846, lng: 19.3776635 },
    { lat: 49.1831146, lng: 19.3776087 },
    { lat: 49.1831536, lng: 19.377599 },
    { lat: 49.1831806, lng: 19.3775503 },
    { lat: 49.1832144, lng: 19.3775449 },
    { lat: 49.1832327, lng: 19.3774831 },
    { lat: 49.1832675, lng: 19.3774533 },
    { lat: 49.1833456, lng: 19.3774113 },
    { lat: 49.1833995, lng: 19.3774076 },
    { lat: 49.1834352, lng: 19.3773987 },
    { lat: 49.1835333, lng: 19.3773297 },
    { lat: 49.1836017, lng: 19.3773233 },
    { lat: 49.1837372, lng: 19.3772563 },
    { lat: 49.183772, lng: 19.377254 },
    { lat: 49.1837961, lng: 19.3772139 },
    { lat: 49.183873, lng: 19.3771475 },
    { lat: 49.1839154, lng: 19.3771403 },
    { lat: 49.1839668, lng: 19.377178 },
    { lat: 49.1840804, lng: 19.3770777 },
    { lat: 49.1841342, lng: 19.3770456 },
    { lat: 49.1841676, lng: 19.3770643 },
    { lat: 49.1841969, lng: 19.377038 },
    { lat: 49.1842383, lng: 19.3770551 },
    { lat: 49.1842677, lng: 19.3770134 },
    { lat: 49.1843492, lng: 19.3769469 },
    { lat: 49.1844066, lng: 19.3769176 },
    { lat: 49.1844592, lng: 19.3768859 },
    { lat: 49.1845104, lng: 19.3768508 },
    { lat: 49.1845569, lng: 19.3768544 },
    { lat: 49.1846636, lng: 19.3767537 },
    { lat: 49.1847141, lng: 19.3767018 },
    { lat: 49.1848193, lng: 19.3766125 },
    { lat: 49.1848556, lng: 19.3766179 },
    { lat: 49.1850053, lng: 19.3767324 },
    { lat: 49.1850667, lng: 19.3767924 },
    { lat: 49.1851228, lng: 19.3769076 },
    { lat: 49.1852482, lng: 19.3769181 },
    { lat: 49.1853952, lng: 19.3769039 },
    { lat: 49.1854789, lng: 19.3769063 },
    { lat: 49.1855392, lng: 19.3769437 },
    { lat: 49.1855925, lng: 19.3768831 },
    { lat: 49.1856674, lng: 19.3769139 },
    { lat: 49.1856635, lng: 19.3769511 },
    { lat: 49.1856612, lng: 19.3769978 },
    { lat: 49.1857521, lng: 19.3769681 },
    { lat: 49.1856948, lng: 19.3772816 },
    { lat: 49.1856469, lng: 19.3774201 },
    { lat: 49.185611, lng: 19.3775155 },
    { lat: 49.1855728, lng: 19.3776166 },
    { lat: 49.1855118, lng: 19.3777313 },
    { lat: 49.1854902, lng: 19.3779217 },
    { lat: 49.1854984, lng: 19.377976 },
    { lat: 49.1854751, lng: 19.3780066 },
    { lat: 49.1854569, lng: 19.378103 },
    { lat: 49.1853914, lng: 19.378216 },
    { lat: 49.1854386, lng: 19.3782415 },
    { lat: 49.1854561, lng: 19.3782515 },
    { lat: 49.1854699, lng: 19.378259 },
    { lat: 49.1855338, lng: 19.3782981 },
    { lat: 49.1855587, lng: 19.3783264 },
    { lat: 49.1856388, lng: 19.3783263 },
    { lat: 49.1862306, lng: 19.3786189 },
    { lat: 49.1863954, lng: 19.3787256 },
    { lat: 49.1865792, lng: 19.3788692 },
    { lat: 49.1868323, lng: 19.3790787 },
    { lat: 49.1870133, lng: 19.3791166 },
    { lat: 49.1871387, lng: 19.3792438 },
    { lat: 49.187225, lng: 19.3793519 },
    { lat: 49.1873363, lng: 19.3794442 },
    { lat: 49.1873732, lng: 19.3795378 },
    { lat: 49.1874356, lng: 19.3795682 },
    { lat: 49.1876074, lng: 19.3796017 },
    { lat: 49.1875917, lng: 19.3796754 },
    { lat: 49.1878947, lng: 19.3797203 },
    { lat: 49.1880178, lng: 19.3796572 },
    { lat: 49.1883449, lng: 19.379592 },
    { lat: 49.1885659, lng: 19.3796173 },
    { lat: 49.1888979, lng: 19.3796305 },
    { lat: 49.189261, lng: 19.3796595 },
    { lat: 49.1896808, lng: 19.3796494 },
    { lat: 49.1900446, lng: 19.3795007 },
    { lat: 49.1902247, lng: 19.3800046 },
    { lat: 49.190342, lng: 19.3798617 },
    { lat: 49.1904894, lng: 19.3798071 },
    { lat: 49.190875, lng: 19.3796636 },
    { lat: 49.1908374, lng: 19.3789572 },
    { lat: 49.1908202, lng: 19.3783257 },
    { lat: 49.1908144, lng: 19.3777214 },
    { lat: 49.1907657, lng: 19.3769193 },
    { lat: 49.1907633, lng: 19.3766998 },
    { lat: 49.1907633, lng: 19.3760992 },
    { lat: 49.190979, lng: 19.3745174 },
    { lat: 49.1914987, lng: 19.3725739 },
    { lat: 49.1916743, lng: 19.3721592 },
    { lat: 49.1920608, lng: 19.3714613 },
    { lat: 49.1921428, lng: 19.37114 },
    { lat: 49.1921636, lng: 19.3705057 },
    { lat: 49.1918662, lng: 19.3694684 },
    { lat: 49.1920892, lng: 19.3673745 },
    { lat: 49.1922283, lng: 19.3665934 },
    { lat: 49.1922913, lng: 19.366255 },
    { lat: 49.1923039, lng: 19.3662576 },
    { lat: 49.1923094, lng: 19.3662076 },
    { lat: 49.1923861, lng: 19.3655363 },
    { lat: 49.1923431, lng: 19.3648816 },
    { lat: 49.1919775, lng: 19.3635765 },
    { lat: 49.1918589, lng: 19.3631827 },
    { lat: 49.1924155, lng: 19.3632534 },
    { lat: 49.1924943, lng: 19.3632506 },
    { lat: 49.1932178, lng: 19.3631802 },
    { lat: 49.1935434, lng: 19.3630824 },
    { lat: 49.1939935, lng: 19.363022 },
    { lat: 49.1943665, lng: 19.3629712 },
    { lat: 49.1943723, lng: 19.3627814 },
    { lat: 49.1947927, lng: 19.3625204 },
    { lat: 49.1948218, lng: 19.3623263 },
    { lat: 49.1950406, lng: 19.3620872 },
    { lat: 49.1952492, lng: 19.361204 },
    { lat: 49.1952799, lng: 19.3606299 },
    { lat: 49.1953283, lng: 19.3606787 },
    { lat: 49.1955792, lng: 19.3603086 },
    { lat: 49.195887, lng: 19.3600238 },
    { lat: 49.1960258, lng: 19.3598371 },
    { lat: 49.1965407, lng: 19.3591358 },
    { lat: 49.1967548, lng: 19.3586811 },
    { lat: 49.1966535, lng: 19.3584446 },
    { lat: 49.196841, lng: 19.358022 },
    { lat: 49.1970056, lng: 19.3576595 },
    { lat: 49.1973539, lng: 19.3571098 },
    { lat: 49.1975963, lng: 19.3567298 },
    { lat: 49.1975548, lng: 19.3557012 },
    { lat: 49.1975571, lng: 19.3550566 },
    { lat: 49.1975563, lng: 19.3542445 },
    { lat: 49.197643, lng: 19.3541691 },
    { lat: 49.1977244, lng: 19.354153 },
    { lat: 49.1978473, lng: 19.3541795 },
    { lat: 49.1979677, lng: 19.3541999 },
    { lat: 49.1982473, lng: 19.353792 },
    { lat: 49.1982798, lng: 19.3537156 },
    { lat: 49.1982785, lng: 19.3535479 },
    { lat: 49.1983426, lng: 19.3536396 },
    { lat: 49.1983322, lng: 19.3535636 },
    { lat: 49.1983732, lng: 19.3535732 },
    { lat: 49.1983948, lng: 19.3535294 },
    { lat: 49.198413, lng: 19.3534582 },
    { lat: 49.1984111, lng: 19.3533935 },
    { lat: 49.1984048, lng: 19.3533308 },
    { lat: 49.1983685, lng: 19.3533261 },
    { lat: 49.1983222, lng: 19.3533254 },
    { lat: 49.198298, lng: 19.3532877 },
    { lat: 49.1982969, lng: 19.3532396 },
    { lat: 49.1983115, lng: 19.353194 },
    { lat: 49.1983777, lng: 19.3530891 },
    { lat: 49.1984189, lng: 19.3531786 },
    { lat: 49.1985125, lng: 19.3531032 },
    { lat: 49.1985125, lng: 19.3530837 },
    { lat: 49.1984767, lng: 19.3529644 },
    { lat: 49.1984859, lng: 19.3529457 },
    { lat: 49.1985268, lng: 19.3529056 },
    { lat: 49.1985379, lng: 19.3528557 },
    { lat: 49.1985281, lng: 19.352788 },
    { lat: 49.1985103, lng: 19.3527261 },
    { lat: 49.198495, lng: 19.3527112 },
    { lat: 49.198475, lng: 19.3527642 },
    { lat: 49.1984465, lng: 19.3527547 },
    { lat: 49.1984374, lng: 19.3527319 },
    { lat: 49.1984404, lng: 19.3527013 },
    { lat: 49.198527, lng: 19.3525726 },
    { lat: 49.1985615, lng: 19.3523976 },
    { lat: 49.1985965, lng: 19.3523733 },
    { lat: 49.1986303, lng: 19.3522788 },
    { lat: 49.1986065, lng: 19.3521336 },
    { lat: 49.1986157, lng: 19.3520883 },
    { lat: 49.1986683, lng: 19.3521024 },
    { lat: 49.1987309, lng: 19.3520685 },
    { lat: 49.1987512, lng: 19.3520482 },
    { lat: 49.1987358, lng: 19.3518856 },
    { lat: 49.1987364, lng: 19.3517789 },
    { lat: 49.1988159, lng: 19.3516963 },
    { lat: 49.1988432, lng: 19.3515582 },
    { lat: 49.1988322, lng: 19.351514 },
    { lat: 49.1987913, lng: 19.3514831 },
    { lat: 49.1987434, lng: 19.351519 },
    { lat: 49.1987228, lng: 19.3515126 },
    { lat: 49.1987122, lng: 19.3514334 },
    { lat: 49.1987246, lng: 19.3514058 },
    { lat: 49.198826, lng: 19.3513388 },
    { lat: 49.1988359, lng: 19.3513064 },
    { lat: 49.1988296, lng: 19.3512681 },
    { lat: 49.198785, lng: 19.3511829 },
    { lat: 49.1987334, lng: 19.3510196 },
    { lat: 49.1985941, lng: 19.3507894 },
    { lat: 49.198597, lng: 19.3505642 },
    { lat: 49.1983774, lng: 19.3500629 },
    { lat: 49.1983733, lng: 19.3499058 },
    { lat: 49.1983187, lng: 19.3497486 },
    { lat: 49.1983351, lng: 19.3494996 },
    { lat: 49.198421, lng: 19.3493374 },
    { lat: 49.1984538, lng: 19.3491723 },
    { lat: 49.1984093, lng: 19.3490143 },
    { lat: 49.1985508, lng: 19.3488129 },
    { lat: 49.1986291, lng: 19.3485359 },
    { lat: 49.1985378, lng: 19.3483657 },
    { lat: 49.1985214, lng: 19.3482334 },
    { lat: 49.1985551, lng: 19.3480665 },
    { lat: 49.1986504, lng: 19.3480089 },
    { lat: 49.1986476, lng: 19.3479248 },
    { lat: 49.198715, lng: 19.3478007 },
    { lat: 49.1987001, lng: 19.3476488 },
    { lat: 49.1986407, lng: 19.3474917 },
    { lat: 49.1986369, lng: 19.3473858 },
    { lat: 49.1986205, lng: 19.347277 },
    { lat: 49.1986484, lng: 19.3471276 },
    { lat: 49.1987313, lng: 19.3471121 },
    { lat: 49.1986565, lng: 19.3469127 },
    { lat: 49.1986748, lng: 19.3468502 },
    { lat: 49.1986476, lng: 19.3467991 },
    { lat: 49.1986734, lng: 19.3467966 },
    { lat: 49.1986993, lng: 19.3467945 },
    { lat: 49.1990569, lng: 19.3467294 },
    { lat: 49.1991652, lng: 19.3467092 },
    { lat: 49.199671, lng: 19.3466066 },
    { lat: 49.2002822, lng: 19.3467591 },
    { lat: 49.2003077, lng: 19.3471856 },
    { lat: 49.2004335, lng: 19.3479423 },
    { lat: 49.2007569, lng: 19.3479901 },
    { lat: 49.2008682, lng: 19.3480064 },
    { lat: 49.2015177, lng: 19.347994 },
    { lat: 49.2021338, lng: 19.3479388 },
    { lat: 49.2023618, lng: 19.347965 },
    { lat: 49.2029312, lng: 19.3483593 },
    { lat: 49.2034486, lng: 19.3487462 },
    { lat: 49.2040379, lng: 19.3491492 },
    { lat: 49.2045216, lng: 19.3496346 },
    { lat: 49.2049783, lng: 19.3488746 },
    { lat: 49.2051913, lng: 19.3484437 },
    { lat: 49.2054464, lng: 19.3476845 },
    { lat: 49.2056682, lng: 19.3474411 },
    { lat: 49.205851, lng: 19.3468934 },
    { lat: 49.205951, lng: 19.3457872 },
    { lat: 49.2064871, lng: 19.3461141 },
    { lat: 49.2073123, lng: 19.3466487 },
    { lat: 49.207679, lng: 19.3470183 },
    { lat: 49.2077749, lng: 19.3471138 },
    { lat: 49.2080914, lng: 19.3477596 },
    { lat: 49.2081226, lng: 19.347825 },
    { lat: 49.2083857, lng: 19.3472127 },
    { lat: 49.2085414, lng: 19.3468539 },
    { lat: 49.2091009, lng: 19.3460253 },
    { lat: 49.2093571, lng: 19.3455078 },
    { lat: 49.2093245, lng: 19.3454382 },
    { lat: 49.2091037, lng: 19.3449606 },
    { lat: 49.2089091, lng: 19.3445145 },
    { lat: 49.2081655, lng: 19.3437518 },
    { lat: 49.207915, lng: 19.3435771 },
    { lat: 49.2077087, lng: 19.3430962 },
    { lat: 49.2073017, lng: 19.3430701 },
    { lat: 49.2070359, lng: 19.3429519 },
    { lat: 49.2069009, lng: 19.3427165 },
    { lat: 49.2066952, lng: 19.34225 },
    { lat: 49.2066304, lng: 19.3418307 },
    { lat: 49.2065865, lng: 19.3414012 },
    { lat: 49.2065748, lng: 19.3413672 },
    { lat: 49.2065893, lng: 19.3411036 },
    { lat: 49.2063108, lng: 19.3408105 },
    { lat: 49.2062025, lng: 19.3406425 },
    { lat: 49.2060764, lng: 19.3402361 },
    { lat: 49.2057737, lng: 19.3400761 },
    { lat: 49.2056472, lng: 19.3400229 },
    { lat: 49.2050543, lng: 19.3396137 },
    { lat: 49.2045039, lng: 19.3394841 },
    { lat: 49.204254, lng: 19.3397533 },
    { lat: 49.2040461, lng: 19.339552 },
    { lat: 49.203762, lng: 19.33943 },
    { lat: 49.2032063, lng: 19.3390678 },
    { lat: 49.2028909, lng: 19.3390004 },
    { lat: 49.2024739, lng: 19.3390724 },
    { lat: 49.2022056, lng: 19.3391415 },
    { lat: 49.2010293, lng: 19.3387386 },
    { lat: 49.2009674, lng: 19.3381176 },
    { lat: 49.2010462, lng: 19.3376841 },
    { lat: 49.2010962, lng: 19.3374577 },
    { lat: 49.2011778, lng: 19.3371287 },
  ],
  Malatiná: [
    { lat: 49.174455, lng: 19.470202 },
    { lat: 49.174417, lng: 19.471074 },
    { lat: 49.174342, lng: 19.471492 },
    { lat: 49.174192, lng: 19.473177 },
    { lat: 49.174203, lng: 19.473214 },
    { lat: 49.174263, lng: 19.473452 },
    { lat: 49.175396, lng: 19.475612 },
    { lat: 49.176613, lng: 19.478145 },
    { lat: 49.17684, lng: 19.478169 },
    { lat: 49.178966, lng: 19.478308 },
    { lat: 49.179569, lng: 19.479288 },
    { lat: 49.179626, lng: 19.479332 },
    { lat: 49.180744, lng: 19.480105 },
    { lat: 49.182409, lng: 19.479961 },
    { lat: 49.183312, lng: 19.479849 },
    { lat: 49.184501, lng: 19.479701 },
    { lat: 49.18458, lng: 19.479722 },
    { lat: 49.184615, lng: 19.479732 },
    { lat: 49.185277, lng: 19.479913 },
    { lat: 49.186389, lng: 19.47952 },
    { lat: 49.18746, lng: 19.479194 },
    { lat: 49.187505, lng: 19.47918 },
    { lat: 49.188699, lng: 19.478817 },
    { lat: 49.19009, lng: 19.479002 },
    { lat: 49.190985, lng: 19.479121 },
    { lat: 49.192087, lng: 19.479132 },
    { lat: 49.19264, lng: 19.47974 },
    { lat: 49.192832, lng: 19.480259 },
    { lat: 49.192927, lng: 19.480514 },
    { lat: 49.193095, lng: 19.48094 },
    { lat: 49.193153, lng: 19.481083 },
    { lat: 49.1934, lng: 19.481706 },
    { lat: 49.193863, lng: 19.481976 },
    { lat: 49.194104, lng: 19.482103 },
    { lat: 49.194436, lng: 19.48216 },
    { lat: 49.195325, lng: 19.482108 },
    { lat: 49.195474, lng: 19.482687 },
    { lat: 49.196114, lng: 19.483558 },
    { lat: 49.19616, lng: 19.483622 },
    { lat: 49.196433, lng: 19.483486 },
    { lat: 49.198883, lng: 19.48643 },
    { lat: 49.1988703, lng: 19.4859291 },
    { lat: 49.1988085, lng: 19.4852194 },
    { lat: 49.1988744, lng: 19.4843234 },
    { lat: 49.1988797, lng: 19.4839691 },
    { lat: 49.1988985, lng: 19.4830046 },
    { lat: 49.1988984, lng: 19.4830028 },
    { lat: 49.1989835, lng: 19.4822064 },
    { lat: 49.1989831, lng: 19.4821982 },
    { lat: 49.199058, lng: 19.4804135 },
    { lat: 49.1993335, lng: 19.4787809 },
    { lat: 49.1995399, lng: 19.4775093 },
    { lat: 49.1996958, lng: 19.4766109 },
    { lat: 49.1997193, lng: 19.4761875 },
    { lat: 49.1997268, lng: 19.4752495 },
    { lat: 49.199731, lng: 19.4746782 },
    { lat: 49.1998939, lng: 19.4733409 },
    { lat: 49.1999334, lng: 19.4720417 },
    { lat: 49.1999555, lng: 19.4705126 },
    { lat: 49.2001915, lng: 19.4690517 },
    { lat: 49.200222, lng: 19.4684925 },
    { lat: 49.2002542, lng: 19.4679132 },
    { lat: 49.2003384, lng: 19.4671511 },
    { lat: 49.2003948, lng: 19.4661433 },
    { lat: 49.2004712, lng: 19.4654789 },
    { lat: 49.200489, lng: 19.4652767 },
    { lat: 49.2005069, lng: 19.4647353 },
    { lat: 49.2005474, lng: 19.4639995 },
    { lat: 49.2006135, lng: 19.4630854 },
    { lat: 49.2006714, lng: 19.462262 },
    { lat: 49.2016389, lng: 19.4607857 },
    { lat: 49.2026541, lng: 19.4597075 },
    { lat: 49.2030975, lng: 19.4591309 },
    { lat: 49.2033819, lng: 19.458678 },
    { lat: 49.204278, lng: 19.4579963 },
    { lat: 49.2051634, lng: 19.4575748 },
    { lat: 49.2057066, lng: 19.4572128 },
    { lat: 49.2066272, lng: 19.4565986 },
    { lat: 49.2075327, lng: 19.4560748 },
    { lat: 49.2082563, lng: 19.4555445 },
    { lat: 49.2087319, lng: 19.4550339 },
    { lat: 49.2094111, lng: 19.4542932 },
    { lat: 49.2101328, lng: 19.4536441 },
    { lat: 49.2108025, lng: 19.4531386 },
    { lat: 49.2115999, lng: 19.4527657 },
    { lat: 49.2115936, lng: 19.4527264 },
    { lat: 49.211534, lng: 19.4524376 },
    { lat: 49.2118085, lng: 19.4521573 },
    { lat: 49.2121345, lng: 19.4518224 },
    { lat: 49.2122796, lng: 19.4517697 },
    { lat: 49.2125128, lng: 19.451568 },
    { lat: 49.2125781, lng: 19.4513956 },
    { lat: 49.2126646, lng: 19.4512197 },
    { lat: 49.2127061, lng: 19.4505831 },
    { lat: 49.2130069, lng: 19.4505306 },
    { lat: 49.2132952, lng: 19.4504805 },
    { lat: 49.213492, lng: 19.450446 },
    { lat: 49.2135412, lng: 19.4504386 },
    { lat: 49.2136341, lng: 19.450425 },
    { lat: 49.2139118, lng: 19.450384 },
    { lat: 49.2141444, lng: 19.45035 },
    { lat: 49.2142248, lng: 19.4502592 },
    { lat: 49.2142356, lng: 19.4502472 },
    { lat: 49.2144219, lng: 19.4500379 },
    { lat: 49.2144334, lng: 19.44997 },
    { lat: 49.2144333, lng: 19.4499672 },
    { lat: 49.2145149, lng: 19.4494639 },
    { lat: 49.2145238, lng: 19.449411 },
    { lat: 49.2145612, lng: 19.4491734 },
    { lat: 49.2145456, lng: 19.4488604 },
    { lat: 49.2145571, lng: 19.4485719 },
    { lat: 49.214753, lng: 19.4483696 },
    { lat: 49.2149783, lng: 19.4483717 },
    { lat: 49.2152185, lng: 19.4483736 },
    { lat: 49.215343, lng: 19.4483631 },
    { lat: 49.215567, lng: 19.4482849 },
    { lat: 49.2156037, lng: 19.448272 },
    { lat: 49.2157347, lng: 19.4480604 },
    { lat: 49.2157804, lng: 19.4479783 },
    { lat: 49.2158835, lng: 19.4477903 },
    { lat: 49.2159567, lng: 19.4476469 },
    { lat: 49.2159785, lng: 19.4476038 },
    { lat: 49.2159794, lng: 19.4476026 },
    { lat: 49.2160435, lng: 19.4474764 },
    { lat: 49.2161771, lng: 19.4471492 },
    { lat: 49.2163032, lng: 19.4469038 },
    { lat: 49.2163106, lng: 19.4468886 },
    { lat: 49.2164375, lng: 19.4463902 },
    { lat: 49.2164459, lng: 19.4463774 },
    { lat: 49.2169058, lng: 19.4456762 },
    { lat: 49.2169962, lng: 19.4455101 },
    { lat: 49.2170047, lng: 19.4454951 },
    { lat: 49.2170346, lng: 19.4454385 },
    { lat: 49.2170669, lng: 19.4453795 },
    { lat: 49.2171665, lng: 19.4451958 },
    { lat: 49.2171843, lng: 19.4451672 },
    { lat: 49.2172117, lng: 19.4451261 },
    { lat: 49.2172159, lng: 19.4451188 },
    { lat: 49.217333, lng: 19.4449454 },
    { lat: 49.2175355, lng: 19.4449889 },
    { lat: 49.2176378, lng: 19.444935 },
    { lat: 49.2180979, lng: 19.4441862 },
    { lat: 49.2186517, lng: 19.4437315 },
    { lat: 49.2193831, lng: 19.4430504 },
    { lat: 49.2197144, lng: 19.442809 },
    { lat: 49.2200893, lng: 19.4423382 },
    { lat: 49.2201242, lng: 19.4422861 },
    { lat: 49.2206485, lng: 19.4415116 },
    { lat: 49.2210085, lng: 19.4407933 },
    { lat: 49.2210848, lng: 19.4406406 },
    { lat: 49.2211161, lng: 19.4405871 },
    { lat: 49.2212115, lng: 19.4399683 },
    { lat: 49.221412, lng: 19.4393307 },
    { lat: 49.2216274, lng: 19.4385288 },
    { lat: 49.2200142, lng: 19.4371496 },
    { lat: 49.2195476, lng: 19.4364147 },
    { lat: 49.2194515, lng: 19.4362618 },
    { lat: 49.2192218, lng: 19.4358998 },
    { lat: 49.2186029, lng: 19.4350422 },
    { lat: 49.2181636, lng: 19.434533 },
    { lat: 49.2175998, lng: 19.4341836 },
    { lat: 49.2174618, lng: 19.4340986 },
    { lat: 49.2172065, lng: 19.4339408 },
    { lat: 49.21692, lng: 19.433616 },
    { lat: 49.2167151, lng: 19.4332095 },
    { lat: 49.2161186, lng: 19.4322494 },
    { lat: 49.2155197, lng: 19.4309976 },
    { lat: 49.2153003, lng: 19.4301032 },
    { lat: 49.2152171, lng: 19.4297665 },
    { lat: 49.2151536, lng: 19.4296126 },
    { lat: 49.2148474, lng: 19.4288653 },
    { lat: 49.2138264, lng: 19.4279367 },
    { lat: 49.213189, lng: 19.4273386 },
    { lat: 49.2130072, lng: 19.4272069 },
    { lat: 49.2128054, lng: 19.4270596 },
    { lat: 49.2124171, lng: 19.4267779 },
    { lat: 49.2110136, lng: 19.4260593 },
    { lat: 49.2104215, lng: 19.4259048 },
    { lat: 49.2103202, lng: 19.4258779 },
    { lat: 49.2089183, lng: 19.425512 },
    { lat: 49.208664, lng: 19.4254458 },
    { lat: 49.2086571, lng: 19.4254437 },
    { lat: 49.2086456, lng: 19.4254409 },
    { lat: 49.2074692, lng: 19.4260476 },
    { lat: 49.2071979, lng: 19.4262012 },
    { lat: 49.2069389, lng: 19.4263481 },
    { lat: 49.2069097, lng: 19.4263246 },
    { lat: 49.2062941, lng: 19.4258353 },
    { lat: 49.2064219, lng: 19.4252363 },
    { lat: 49.206481, lng: 19.4247383 },
    { lat: 49.2057961, lng: 19.4240126 },
    { lat: 49.2053609, lng: 19.4229882 },
    { lat: 49.2050117, lng: 19.4221632 },
    { lat: 49.2049346, lng: 19.422054 },
    { lat: 49.2046161, lng: 19.4216019 },
    { lat: 49.2044339, lng: 19.4213438 },
    { lat: 49.2042131, lng: 19.4212202 },
    { lat: 49.2041128, lng: 19.4210739 },
    { lat: 49.2040327, lng: 19.4210221 },
    { lat: 49.2040269, lng: 19.4210209 },
    { lat: 49.2040258, lng: 19.4210206 },
    { lat: 49.2039789, lng: 19.4210077 },
    { lat: 49.203915, lng: 19.4209901 },
    { lat: 49.2037044, lng: 19.4208233 },
    { lat: 49.2036991, lng: 19.420804 },
    { lat: 49.2036976, lng: 19.4207984 },
    { lat: 49.2036875, lng: 19.4207729 },
    { lat: 49.20386, lng: 19.4206043 },
    { lat: 49.2038689, lng: 19.420506 },
    { lat: 49.2037927, lng: 19.4203428 },
    { lat: 49.2037479, lng: 19.4201573 },
    { lat: 49.2038253, lng: 19.4200027 },
    { lat: 49.2038459, lng: 19.4198866 },
    { lat: 49.2040454, lng: 19.4196466 },
    { lat: 49.204163, lng: 19.4195806 },
    { lat: 49.2040981, lng: 19.4192762 },
    { lat: 49.2040376, lng: 19.419113 },
    { lat: 49.2040527, lng: 19.4189557 },
    { lat: 49.2040918, lng: 19.4188978 },
    { lat: 49.2041297, lng: 19.4187218 },
    { lat: 49.2041857, lng: 19.4185439 },
    { lat: 49.2041062, lng: 19.4182865 },
    { lat: 49.2041302, lng: 19.4181001 },
    { lat: 49.2041949, lng: 19.4180596 },
    { lat: 49.2041943, lng: 19.4180474 },
    { lat: 49.2040264, lng: 19.4180007 },
    { lat: 49.2039915, lng: 19.4178286 },
    { lat: 49.2040025, lng: 19.4177766 },
    { lat: 49.2039933, lng: 19.4177207 },
    { lat: 49.2039791, lng: 19.4176137 },
    { lat: 49.2040614, lng: 19.417322 },
    { lat: 49.2040635, lng: 19.4173156 },
    { lat: 49.2040566, lng: 19.4172893 },
    { lat: 49.2040523, lng: 19.4172732 },
    { lat: 49.2040445, lng: 19.4172474 },
    { lat: 49.2039544, lng: 19.4169332 },
    { lat: 49.2038882, lng: 19.4166733 },
    { lat: 49.2034898, lng: 19.4165593 },
    { lat: 49.2034136, lng: 19.4165707 },
    { lat: 49.2032053, lng: 19.4166019 },
    { lat: 49.2031763, lng: 19.4166065 },
    { lat: 49.2028905, lng: 19.416539 },
    { lat: 49.2028155, lng: 19.4165262 },
    { lat: 49.202566, lng: 19.4164472 },
    { lat: 49.2025615, lng: 19.4164456 },
    { lat: 49.2024227, lng: 19.4156898 },
    { lat: 49.202421, lng: 19.4156791 },
    { lat: 49.2023707, lng: 19.4153671 },
    { lat: 49.2023024, lng: 19.4147259 },
    { lat: 49.2022108, lng: 19.4142785 },
    { lat: 49.2021847, lng: 19.4140319 },
    { lat: 49.2021488, lng: 19.413696 },
    { lat: 49.2020984, lng: 19.4132208 },
    { lat: 49.2020391, lng: 19.4132055 },
    { lat: 49.2016991, lng: 19.4131158 },
    { lat: 49.2015428, lng: 19.4130502 },
    { lat: 49.2013301, lng: 19.4130044 },
    { lat: 49.2011404, lng: 19.412972 },
    { lat: 49.2008498, lng: 19.4129984 },
    { lat: 49.2006423, lng: 19.413046 },
    { lat: 49.2005119, lng: 19.413076 },
    { lat: 49.2002852, lng: 19.4131675 },
    { lat: 49.2001779, lng: 19.4132105 },
    { lat: 49.1996737, lng: 19.4139767 },
    { lat: 49.1995532, lng: 19.414078 },
    { lat: 49.1984945, lng: 19.4141601 },
    { lat: 49.1984584, lng: 19.4141619 },
    { lat: 49.1981627, lng: 19.4134696 },
    { lat: 49.1980234, lng: 19.4135056 },
    { lat: 49.1976705, lng: 19.4135989 },
    { lat: 49.1974512, lng: 19.4136565 },
    { lat: 49.1971715, lng: 19.4136734 },
    { lat: 49.197051, lng: 19.4136014 },
    { lat: 49.1968027, lng: 19.4134506 },
    { lat: 49.1967029, lng: 19.4134373 },
    { lat: 49.1957846, lng: 19.4133158 },
    { lat: 49.1954876, lng: 19.4132766 },
    { lat: 49.1951704, lng: 19.4132399 },
    { lat: 49.194793, lng: 19.4131968 },
    { lat: 49.1945801, lng: 19.4124232 },
    { lat: 49.194165, lng: 19.4126757 },
    { lat: 49.1938316, lng: 19.4128043 },
    { lat: 49.1938008, lng: 19.4128167 },
    { lat: 49.1930878, lng: 19.4130941 },
    { lat: 49.1924334, lng: 19.4134885 },
    { lat: 49.1921915, lng: 19.4138374 },
    { lat: 49.1921671, lng: 19.413821 },
    { lat: 49.1921091, lng: 19.4137819 },
    { lat: 49.1914231, lng: 19.4135214 },
    { lat: 49.191238, lng: 19.4132229 },
    { lat: 49.1904993, lng: 19.4134286 },
    { lat: 49.1899211, lng: 19.4134526 },
    { lat: 49.1896692, lng: 19.4134629 },
    { lat: 49.1893583, lng: 19.4133945 },
    { lat: 49.1891169, lng: 19.4133414 },
    { lat: 49.188977, lng: 19.4133331 },
    { lat: 49.188913, lng: 19.4133141 },
    { lat: 49.1885218, lng: 19.4131939 },
    { lat: 49.1883153, lng: 19.4119709 },
    { lat: 49.1881358, lng: 19.41228 },
    { lat: 49.1881551, lng: 19.4124321 },
    { lat: 49.1876555, lng: 19.4132203 },
    { lat: 49.187435, lng: 19.4134253 },
    { lat: 49.1873444, lng: 19.4135068 },
    { lat: 49.1870668, lng: 19.4136208 },
    { lat: 49.1870058, lng: 19.4136454 },
    { lat: 49.1869368, lng: 19.4136648 },
    { lat: 49.1869058, lng: 19.4136739 },
    { lat: 49.1867145, lng: 19.4137284 },
    { lat: 49.1866436, lng: 19.4137325 },
    { lat: 49.1865739, lng: 19.4137357 },
    { lat: 49.1863963, lng: 19.4137452 },
    { lat: 49.1862207, lng: 19.4137228 },
    { lat: 49.1861547, lng: 19.4137083 },
    { lat: 49.1857664, lng: 19.4136199 },
    { lat: 49.185361, lng: 19.4135812 },
    { lat: 49.1851597, lng: 19.413589 },
    { lat: 49.1850753, lng: 19.4135923 },
    { lat: 49.1848926, lng: 19.4136135 },
    { lat: 49.1846623, lng: 19.413699 },
    { lat: 49.1844333, lng: 19.4138156 },
    { lat: 49.1842179, lng: 19.4139823 },
    { lat: 49.1836096, lng: 19.4145203 },
    { lat: 49.1826489, lng: 19.4151843 },
    { lat: 49.1819877, lng: 19.4148996 },
    { lat: 49.1812007, lng: 19.4138734 },
    { lat: 49.1803955, lng: 19.4131587 },
    { lat: 49.1794474, lng: 19.4127869 },
    { lat: 49.1788449, lng: 19.4124541 },
    { lat: 49.178528, lng: 19.4123518 },
    { lat: 49.1782241, lng: 19.4121899 },
    { lat: 49.1778079, lng: 19.411745 },
    { lat: 49.177495, lng: 19.4114133 },
    { lat: 49.1769687, lng: 19.4108522 },
    { lat: 49.1765084, lng: 19.4101907 },
    { lat: 49.1758346, lng: 19.4095379 },
    { lat: 49.175197, lng: 19.409119 },
    { lat: 49.175203, lng: 19.409187 },
    { lat: 49.175309, lng: 19.410419 },
    { lat: 49.175315, lng: 19.410851 },
    { lat: 49.175457, lng: 19.412052 },
    { lat: 49.17463, lng: 19.411957 },
    { lat: 49.173708, lng: 19.411861 },
    { lat: 49.17263, lng: 19.411784 },
    { lat: 49.172611, lng: 19.411786 },
    { lat: 49.172608, lng: 19.412212 },
    { lat: 49.17261, lng: 19.412597 },
    { lat: 49.172825, lng: 19.413613 },
    { lat: 49.172994, lng: 19.414413 },
    { lat: 49.173003, lng: 19.414559 },
    { lat: 49.173133, lng: 19.414837 },
    { lat: 49.173166, lng: 19.415168 },
    { lat: 49.173175, lng: 19.415313 },
    { lat: 49.173196, lng: 19.415775 },
    { lat: 49.173202, lng: 19.415877 },
    { lat: 49.173142, lng: 19.416018 },
    { lat: 49.17309, lng: 19.416071 },
    { lat: 49.173095, lng: 19.416128 },
    { lat: 49.172877, lng: 19.416503 },
    { lat: 49.172673, lng: 19.416797 },
    { lat: 49.1725996, lng: 19.4168886 },
    { lat: 49.172516, lng: 19.41706 },
    { lat: 49.172411, lng: 19.417441 },
    { lat: 49.172322, lng: 19.41776 },
    { lat: 49.172221, lng: 19.418076 },
    { lat: 49.172192, lng: 19.418165 },
    { lat: 49.172057, lng: 19.41833 },
    { lat: 49.17198, lng: 19.418583 },
    { lat: 49.171935, lng: 19.418718 },
    { lat: 49.171766, lng: 19.418884 },
    { lat: 49.171752, lng: 19.419006 },
    { lat: 49.171739, lng: 19.419029 },
    { lat: 49.171634, lng: 19.419025 },
    { lat: 49.171423, lng: 19.419256 },
    { lat: 49.171379, lng: 19.419367 },
    { lat: 49.171325, lng: 19.419504 },
    { lat: 49.171162, lng: 19.419742 },
    { lat: 49.171148, lng: 19.419764 },
    { lat: 49.170994, lng: 19.41999 },
    { lat: 49.170975, lng: 19.420028 },
    { lat: 49.171165, lng: 19.420071 },
    { lat: 49.17124, lng: 19.420193 },
    { lat: 49.171349, lng: 19.420173 },
    { lat: 49.17154, lng: 19.420302 },
    { lat: 49.171701, lng: 19.420585 },
    { lat: 49.171775, lng: 19.420631 },
    { lat: 49.171844, lng: 19.420831 },
    { lat: 49.171992, lng: 19.421073 },
    { lat: 49.172006, lng: 19.421144 },
    { lat: 49.171985, lng: 19.421225 },
    { lat: 49.171773, lng: 19.421482 },
    { lat: 49.171567, lng: 19.421685 },
    { lat: 49.171131, lng: 19.422531 },
    { lat: 49.171053, lng: 19.422746 },
    { lat: 49.170946, lng: 19.423338 },
    { lat: 49.170897, lng: 19.424203 },
    { lat: 49.170866, lng: 19.424452 },
    { lat: 49.170786, lng: 19.425418 },
    { lat: 49.170905, lng: 19.425894 },
    { lat: 49.170992, lng: 19.426236 },
    { lat: 49.170993, lng: 19.426239 },
    { lat: 49.171668, lng: 19.425685 },
    { lat: 49.172153, lng: 19.425274 },
    { lat: 49.17252, lng: 19.424945 },
    { lat: 49.173231, lng: 19.424315 },
    { lat: 49.17391, lng: 19.423993 },
    { lat: 49.174086, lng: 19.42398 },
    { lat: 49.174245, lng: 19.424055 },
    { lat: 49.174501, lng: 19.424203 },
    { lat: 49.174856, lng: 19.424416 },
    { lat: 49.175108, lng: 19.42456 },
    { lat: 49.175421, lng: 19.424756 },
    { lat: 49.175864, lng: 19.425022 },
    { lat: 49.176066, lng: 19.425349 },
    { lat: 49.176099, lng: 19.425642 },
    { lat: 49.176197, lng: 19.425883 },
    { lat: 49.176263, lng: 19.426103 },
    { lat: 49.17627, lng: 19.426419 },
    { lat: 49.176265, lng: 19.427328 },
    { lat: 49.176275, lng: 19.427381 },
    { lat: 49.176402, lng: 19.427478 },
    { lat: 49.176532, lng: 19.427609 },
    { lat: 49.176655, lng: 19.427696 },
    { lat: 49.176852, lng: 19.427891 },
    { lat: 49.177026, lng: 19.428095 },
    { lat: 49.177091, lng: 19.428175 },
    { lat: 49.177151, lng: 19.428425 },
    { lat: 49.17714, lng: 19.428576 },
    { lat: 49.177167, lng: 19.428676 },
    { lat: 49.177229, lng: 19.428983 },
    { lat: 49.177246, lng: 19.429179 },
    { lat: 49.177305, lng: 19.429319 },
    { lat: 49.177312, lng: 19.429452 },
    { lat: 49.177377, lng: 19.429782 },
    { lat: 49.177486, lng: 19.430036 },
    { lat: 49.177489, lng: 19.430153 },
    { lat: 49.177511, lng: 19.430533 },
    { lat: 49.177499, lng: 19.430766 },
    { lat: 49.177412, lng: 19.431195 },
    { lat: 49.177288, lng: 19.431488 },
    { lat: 49.177188, lng: 19.431811 },
    { lat: 49.177058, lng: 19.432087 },
    { lat: 49.176941, lng: 19.432208 },
    { lat: 49.176795, lng: 19.432315 },
    { lat: 49.176782, lng: 19.432326 },
    { lat: 49.176543, lng: 19.43203 },
    { lat: 49.176327, lng: 19.431889 },
    { lat: 49.1760523, lng: 19.4318133 },
    { lat: 49.175928, lng: 19.431779 },
    { lat: 49.175871, lng: 19.431763 },
    { lat: 49.175563, lng: 19.431883 },
    { lat: 49.175181, lng: 19.432209 },
    { lat: 49.174899, lng: 19.432167 },
    { lat: 49.174811, lng: 19.432178 },
    { lat: 49.174678, lng: 19.432118 },
    { lat: 49.174144, lng: 19.431906 },
    { lat: 49.173874, lng: 19.431916 },
    { lat: 49.173705, lng: 19.431796 },
    { lat: 49.1733568, lng: 19.4315568 },
    { lat: 49.1731725, lng: 19.4315724 },
    { lat: 49.172495, lng: 19.431913 },
    { lat: 49.171758, lng: 19.432122 },
    { lat: 49.171436, lng: 19.432152 },
    { lat: 49.171035, lng: 19.432072 },
    { lat: 49.170858, lng: 19.432095 },
    { lat: 49.170648, lng: 19.432287 },
    { lat: 49.170499, lng: 19.432289 },
    { lat: 49.170329, lng: 19.432178 },
    { lat: 49.170242, lng: 19.432198 },
    { lat: 49.169855, lng: 19.432638 },
    { lat: 49.169743, lng: 19.432629 },
    { lat: 49.169625, lng: 19.432561 },
    { lat: 49.169193, lng: 19.432606 },
    { lat: 49.169018, lng: 19.432625 },
    { lat: 49.16877, lng: 19.432499 },
    { lat: 49.16854, lng: 19.432427 },
    { lat: 49.168527, lng: 19.432488 },
    { lat: 49.168515, lng: 19.432547 },
    { lat: 49.168478, lng: 19.432902 },
    { lat: 49.16999, lng: 19.436105 },
    { lat: 49.170568, lng: 19.437546 },
    { lat: 49.170809, lng: 19.438303 },
    { lat: 49.171264, lng: 19.439461 },
    { lat: 49.171718, lng: 19.441074 },
    { lat: 49.171677, lng: 19.44628 },
    { lat: 49.170694, lng: 19.44808 },
    { lat: 49.170004, lng: 19.449196 },
    { lat: 49.169406, lng: 19.450254 },
    { lat: 49.169136, lng: 19.450233 },
    { lat: 49.168916, lng: 19.450343 },
    { lat: 49.168825, lng: 19.451055 },
    { lat: 49.169935, lng: 19.452012 },
    { lat: 49.171246, lng: 19.453421 },
    { lat: 49.171977, lng: 19.454335 },
    { lat: 49.172198, lng: 19.454611 },
    { lat: 49.172217, lng: 19.454635 },
    { lat: 49.172483, lng: 19.454959 },
    { lat: 49.172769, lng: 19.455299 },
    { lat: 49.173941, lng: 19.455382 },
    { lat: 49.174276, lng: 19.456102 },
    { lat: 49.175163, lng: 19.458215 },
    { lat: 49.175209, lng: 19.45883 },
    { lat: 49.175398, lng: 19.459621 },
    { lat: 49.175769, lng: 19.461209 },
    { lat: 49.175851, lng: 19.461905 },
    { lat: 49.175928, lng: 19.46256 },
    { lat: 49.175947, lng: 19.462721 },
    { lat: 49.175718, lng: 19.464196 },
    { lat: 49.175249, lng: 19.465268 },
    { lat: 49.174664, lng: 19.466618 },
    { lat: 49.174385, lng: 19.467336 },
    { lat: 49.174277, lng: 19.467615 },
    { lat: 49.174062, lng: 19.468165 },
    { lat: 49.173491, lng: 19.469282 },
    { lat: 49.174455, lng: 19.470202 },
  ],
  MedzibrodienadOravou: [
    { lat: 49.2389036, lng: 19.3349147 },
    { lat: 49.2378116, lng: 19.3347224 },
    { lat: 49.2377968, lng: 19.3347193 },
    { lat: 49.2377591, lng: 19.3347126 },
    { lat: 49.2372103, lng: 19.3346164 },
    { lat: 49.2371274, lng: 19.3346013 },
    { lat: 49.2370855, lng: 19.334594 },
    { lat: 49.2369695, lng: 19.3345736 },
    { lat: 49.2368789, lng: 19.3343744 },
    { lat: 49.2369188, lng: 19.3342896 },
    { lat: 49.2367699, lng: 19.3338039 },
    { lat: 49.23676, lng: 19.3337657 },
    { lat: 49.2368444, lng: 19.3335458 },
    { lat: 49.2370551, lng: 19.3338332 },
    { lat: 49.2371791, lng: 19.333759 },
    { lat: 49.2368948, lng: 19.3332273 },
    { lat: 49.2367983, lng: 19.3330435 },
    { lat: 49.236515, lng: 19.3325098 },
    { lat: 49.2363579, lng: 19.332213 },
    { lat: 49.2351204, lng: 19.3313043 },
    { lat: 49.2350382, lng: 19.3316389 },
    { lat: 49.2345139, lng: 19.3312728 },
    { lat: 49.2341716, lng: 19.3319249 },
    { lat: 49.234133, lng: 19.330244 },
    { lat: 49.2337393, lng: 19.3288715 },
    { lat: 49.2332663, lng: 19.3279957 },
    { lat: 49.2331823, lng: 19.3277215 },
    { lat: 49.2331732, lng: 19.3276943 },
    { lat: 49.2329876, lng: 19.3272478 },
    { lat: 49.2322045, lng: 19.3259614 },
    { lat: 49.2315996, lng: 19.3257525 },
    { lat: 49.2315698, lng: 19.3257421 },
    { lat: 49.2315446, lng: 19.3257333 },
    { lat: 49.2315391, lng: 19.3257314 },
    { lat: 49.2315367, lng: 19.3257306 },
    { lat: 49.231469, lng: 19.3257074 },
    { lat: 49.231406, lng: 19.3257002 },
    { lat: 49.2315745, lng: 19.3271291 },
    { lat: 49.2315393, lng: 19.3279602 },
    { lat: 49.2313706, lng: 19.329115 },
    { lat: 49.230309, lng: 19.331626 },
    { lat: 49.2299551, lng: 19.3311471 },
    { lat: 49.2298286, lng: 19.3313554 },
    { lat: 49.2296562, lng: 19.3316902 },
    { lat: 49.2289064, lng: 19.3329268 },
    { lat: 49.227798, lng: 19.3350389 },
    { lat: 49.2273343, lng: 19.3358437 },
    { lat: 49.2267893, lng: 19.3377648 },
    { lat: 49.2267294, lng: 19.3381488 },
    { lat: 49.2267558, lng: 19.3389691 },
    { lat: 49.2267948, lng: 19.3395129 },
    { lat: 49.2263739, lng: 19.339691 },
    { lat: 49.2263584, lng: 19.3396981 },
    { lat: 49.2263396, lng: 19.3397056 },
    { lat: 49.2262734, lng: 19.3397341 },
    { lat: 49.2262657, lng: 19.3397393 },
    { lat: 49.2262263, lng: 19.339766 },
    { lat: 49.2261739, lng: 19.3398013 },
    { lat: 49.2260874, lng: 19.3399511 },
    { lat: 49.2260837, lng: 19.339967 },
    { lat: 49.2260476, lng: 19.3401346 },
    { lat: 49.2259992, lng: 19.3404204 },
    { lat: 49.2259562, lng: 19.3408704 },
    { lat: 49.2257658, lng: 19.3423858 },
    { lat: 49.2255553, lng: 19.3433233 },
    { lat: 49.2255538, lng: 19.344369 },
    { lat: 49.2254179, lng: 19.3450004 },
    { lat: 49.2254087, lng: 19.3454071 },
    { lat: 49.2254016, lng: 19.3456642 },
    { lat: 49.2253616, lng: 19.3458424 },
    { lat: 49.2254704, lng: 19.3460226 },
    { lat: 49.2255174, lng: 19.346157 },
    { lat: 49.2256505, lng: 19.3465484 },
    { lat: 49.2257241, lng: 19.3467927 },
    { lat: 49.2258527, lng: 19.3470115 },
    { lat: 49.226111, lng: 19.3475919 },
    { lat: 49.2263197, lng: 19.3478838 },
    { lat: 49.2264753, lng: 19.3480784 },
    { lat: 49.2267716, lng: 19.3481739 },
    { lat: 49.2269068, lng: 19.3486527 },
    { lat: 49.2270189, lng: 19.3489537 },
    { lat: 49.2272611, lng: 19.3492117 },
    { lat: 49.2273303, lng: 19.3494388 },
    { lat: 49.2274125, lng: 19.3497006 },
    { lat: 49.2277181, lng: 19.3502343 },
    { lat: 49.2279751, lng: 19.3502361 },
    { lat: 49.228516, lng: 19.3510271 },
    { lat: 49.2288565, lng: 19.3513396 },
    { lat: 49.2293812, lng: 19.3517173 },
    { lat: 49.2296346, lng: 19.3519027 },
    { lat: 49.2299617, lng: 19.3522707 },
    { lat: 49.230108, lng: 19.3528165 },
    { lat: 49.2300499, lng: 19.3534293 },
    { lat: 49.2310414, lng: 19.3572692 },
    { lat: 49.2313199, lng: 19.358137 },
    { lat: 49.2317881, lng: 19.3584363 },
    { lat: 49.2322781, lng: 19.3586928 },
    { lat: 49.2328152, lng: 19.3589987 },
    { lat: 49.2331195, lng: 19.3591226 },
    { lat: 49.2334458, lng: 19.3591107 },
    { lat: 49.2339096, lng: 19.3594148 },
    { lat: 49.2343359, lng: 19.3600696 },
    { lat: 49.234408, lng: 19.360188 },
    { lat: 49.2344086, lng: 19.3604883 },
    { lat: 49.234522, lng: 19.3607907 },
    { lat: 49.2345374, lng: 19.3608575 },
    { lat: 49.2346227, lng: 19.3612322 },
    { lat: 49.2346394, lng: 19.3613015 },
    { lat: 49.2346509, lng: 19.3613543 },
    { lat: 49.2347708, lng: 19.3618666 },
    { lat: 49.2347838, lng: 19.362074 },
    { lat: 49.2348325, lng: 19.3622717 },
    { lat: 49.2349612, lng: 19.3626846 },
    { lat: 49.234977, lng: 19.3628761 },
    { lat: 49.2350537, lng: 19.3630264 },
    { lat: 49.2350874, lng: 19.3632867 },
    { lat: 49.2351513, lng: 19.3633511 },
    { lat: 49.2352898, lng: 19.3636858 },
    { lat: 49.2352085, lng: 19.3638497 },
    { lat: 49.2352077, lng: 19.3639988 },
    { lat: 49.2353103, lng: 19.3641235 },
    { lat: 49.2353424, lng: 19.3642091 },
    { lat: 49.2352023, lng: 19.3645597 },
    { lat: 49.2352936, lng: 19.3648963 },
    { lat: 49.2353701, lng: 19.3650427 },
    { lat: 49.2354115, lng: 19.3650785 },
    { lat: 49.235514, lng: 19.3653919 },
    { lat: 49.2353887, lng: 19.3660395 },
    { lat: 49.2350502, lng: 19.3662939 },
    { lat: 49.2349187, lng: 19.366542 },
    { lat: 49.2348489, lng: 19.3667108 },
    { lat: 49.2347722, lng: 19.3669532 },
    { lat: 49.2347336, lng: 19.3673029 },
    { lat: 49.234568, lng: 19.3671609 },
    { lat: 49.2342299, lng: 19.368126 },
    { lat: 49.2340004, lng: 19.3686148 },
    { lat: 49.2339635, lng: 19.3692455 },
    { lat: 49.2340526, lng: 19.369323 },
    { lat: 49.234068, lng: 19.3694083 },
    { lat: 49.2340295, lng: 19.3697161 },
    { lat: 49.2339702, lng: 19.3703259 },
    { lat: 49.2338818, lng: 19.3708706 },
    { lat: 49.2338279, lng: 19.3711866 },
    { lat: 49.2336642, lng: 19.3712809 },
    { lat: 49.2337153, lng: 19.3717932 },
    { lat: 49.2337313, lng: 19.3719692 },
    { lat: 49.2337169, lng: 19.3721147 },
    { lat: 49.2337325, lng: 19.3725698 },
    { lat: 49.2337275, lng: 19.3726551 },
    { lat: 49.2336334, lng: 19.373343 },
    { lat: 49.2335475, lng: 19.373723 },
    { lat: 49.2335586, lng: 19.3739858 },
    { lat: 49.2338362, lng: 19.3744286 },
    { lat: 49.2341759, lng: 19.3747994 },
    { lat: 49.2341869, lng: 19.3748444 },
    { lat: 49.2343836, lng: 19.3755755 },
    { lat: 49.234781, lng: 19.3761008 },
    { lat: 49.2348243, lng: 19.3763511 },
    { lat: 49.2348897, lng: 19.3769757 },
    { lat: 49.2349988, lng: 19.3771676 },
    { lat: 49.2351081, lng: 19.3772649 },
    { lat: 49.2351845, lng: 19.3773323 },
    { lat: 49.2352375, lng: 19.3773803 },
    { lat: 49.2353696, lng: 19.3778412 },
    { lat: 49.2353967, lng: 19.3780131 },
    { lat: 49.235463, lng: 19.3784451 },
    { lat: 49.2353348, lng: 19.3788615 },
    { lat: 49.2352484, lng: 19.3791544 },
    { lat: 49.2350058, lng: 19.3794862 },
    { lat: 49.2348661, lng: 19.3798271 },
    { lat: 49.2349195, lng: 19.3801963 },
    { lat: 49.2348035, lng: 19.3807311 },
    { lat: 49.2348453, lng: 19.3812873 },
    { lat: 49.2349214, lng: 19.3816378 },
    { lat: 49.2349902, lng: 19.381666 },
    { lat: 49.2350832, lng: 19.3819177 },
    { lat: 49.2352404, lng: 19.3821941 },
    { lat: 49.2354034, lng: 19.382741 },
    { lat: 49.2354125, lng: 19.3828403 },
    { lat: 49.2354357, lng: 19.3832626 },
    { lat: 49.2355891, lng: 19.3841607 },
    { lat: 49.2356571, lng: 19.3847718 },
    { lat: 49.2355618, lng: 19.3849769 },
    { lat: 49.235418, lng: 19.3852046 },
    { lat: 49.2354309, lng: 19.3853088 },
    { lat: 49.2355773, lng: 19.3857649 },
    { lat: 49.2356924, lng: 19.386642 },
    { lat: 49.2357222, lng: 19.3873968 },
    { lat: 49.2356965, lng: 19.388031 },
    { lat: 49.2356539, lng: 19.3885199 },
    { lat: 49.2356045, lng: 19.3892025 },
    { lat: 49.2355234, lng: 19.3899758 },
    { lat: 49.2354821, lng: 19.3904159 },
    { lat: 49.2354769, lng: 19.390816 },
    { lat: 49.2358428, lng: 19.3904694 },
    { lat: 49.2360698, lng: 19.3899965 },
    { lat: 49.2366975, lng: 19.3894389 },
    { lat: 49.2371906, lng: 19.3889943 },
    { lat: 49.2376598, lng: 19.3883771 },
    { lat: 49.2380092, lng: 19.3883042 },
    { lat: 49.239343, lng: 19.3870873 },
    { lat: 49.2396823, lng: 19.3867777 },
    { lat: 49.2399087, lng: 19.3862442 },
    { lat: 49.2404305, lng: 19.3850136 },
    { lat: 49.2418965, lng: 19.3808828 },
    { lat: 49.2424281, lng: 19.380033 },
    { lat: 49.2427183, lng: 19.3795628 },
    { lat: 49.2431721, lng: 19.3789056 },
    { lat: 49.2432584, lng: 19.3787967 },
    { lat: 49.2441934, lng: 19.3776117 },
    { lat: 49.2439313, lng: 19.3765611 },
    { lat: 49.2452089, lng: 19.3757582 },
    { lat: 49.2459113, lng: 19.3750901 },
    { lat: 49.2463905, lng: 19.3746368 },
    { lat: 49.2465896, lng: 19.3744596 },
    { lat: 49.2462727, lng: 19.3719953 },
    { lat: 49.2462295, lng: 19.3713601 },
    { lat: 49.246413, lng: 19.3692156 },
    { lat: 49.2465959, lng: 19.3684516 },
    { lat: 49.247653, lng: 19.3665005 },
    { lat: 49.2479726, lng: 19.36618 },
    { lat: 49.2485295, lng: 19.3658692 },
    { lat: 49.2490051, lng: 19.3653905 },
    { lat: 49.2502301, lng: 19.3641886 },
    { lat: 49.2502364, lng: 19.3641821 },
    { lat: 49.2502454, lng: 19.3641768 },
    { lat: 49.2501913, lng: 19.3639847 },
    { lat: 49.2503793, lng: 19.3637437 },
    { lat: 49.2504133, lng: 19.3636996 },
    { lat: 49.2520847, lng: 19.3626696 },
    { lat: 49.2523089, lng: 19.3623554 },
    { lat: 49.252633, lng: 19.3616025 },
    { lat: 49.2528436, lng: 19.3613345 },
    { lat: 49.2531259, lng: 19.3610782 },
    { lat: 49.2532913, lng: 19.3608335 },
    { lat: 49.2533316, lng: 19.3604182 },
    { lat: 49.2533233, lng: 19.3598347 },
    { lat: 49.2532322, lng: 19.3592343 },
    { lat: 49.2532446, lng: 19.3585872 },
    { lat: 49.2532551, lng: 19.3575821 },
    { lat: 49.2531751, lng: 19.3553979 },
    { lat: 49.2529117, lng: 19.3533633 },
    { lat: 49.2527367, lng: 19.3529694 },
    { lat: 49.2522693, lng: 19.3505777 },
    { lat: 49.2519965, lng: 19.3498074 },
    { lat: 49.2517714, lng: 19.3487105 },
    { lat: 49.2515414, lng: 19.3475778 },
    { lat: 49.2513831, lng: 19.3475425 },
    { lat: 49.2510417, lng: 19.3471614 },
    { lat: 49.250891, lng: 19.3469998 },
    { lat: 49.2507154, lng: 19.3468105 },
    { lat: 49.2506856, lng: 19.3467737 },
    { lat: 49.25067, lng: 19.3467542 },
    { lat: 49.2503886, lng: 19.3464044 },
    { lat: 49.2498844, lng: 19.3470412 },
    { lat: 49.2493993, lng: 19.3474111 },
    { lat: 49.248807, lng: 19.347757 },
    { lat: 49.2485991, lng: 19.3478656 },
    { lat: 49.2481477, lng: 19.3479739 },
    { lat: 49.2479036, lng: 19.3480555 },
    { lat: 49.247678, lng: 19.3480734 },
    { lat: 49.2476033, lng: 19.3480671 },
    { lat: 49.2467009, lng: 19.3478349 },
    { lat: 49.245531, lng: 19.3473503 },
    { lat: 49.2444992, lng: 19.3469274 },
    { lat: 49.2431696, lng: 19.3458291 },
    { lat: 49.2422638, lng: 19.3448948 },
    { lat: 49.2419851, lng: 19.3444561 },
    { lat: 49.2419328, lng: 19.3443738 },
    { lat: 49.2419115, lng: 19.3443339 },
    { lat: 49.2412879, lng: 19.3431739 },
    { lat: 49.2408223, lng: 19.3423262 },
    { lat: 49.2406171, lng: 19.3416793 },
    { lat: 49.2400974, lng: 19.3398786 },
    { lat: 49.2398987, lng: 19.3392238 },
    { lat: 49.239752, lng: 19.3389114 },
    { lat: 49.2393583, lng: 19.3383231 },
    { lat: 49.2392639, lng: 19.3373514 },
    { lat: 49.2392391, lng: 19.3370854 },
    { lat: 49.2392301, lng: 19.3370641 },
    { lat: 49.239171, lng: 19.3369086 },
    { lat: 49.2391119, lng: 19.3367564 },
    { lat: 49.2390993, lng: 19.3367243 },
    { lat: 49.2389655, lng: 19.3363743 },
    { lat: 49.2387686, lng: 19.3358049 },
    { lat: 49.2389036, lng: 19.3349147 },
  ],
  Osádka: [
    { lat: 49.190875, lng: 19.3796636 },
    { lat: 49.1904894, lng: 19.3798071 },
    { lat: 49.190342, lng: 19.3798617 },
    { lat: 49.1902247, lng: 19.3800046 },
    { lat: 49.1900446, lng: 19.3795007 },
    { lat: 49.1896808, lng: 19.3796494 },
    { lat: 49.189261, lng: 19.3796595 },
    { lat: 49.1888979, lng: 19.3796305 },
    { lat: 49.1885659, lng: 19.3796173 },
    { lat: 49.1883449, lng: 19.379592 },
    { lat: 49.1880178, lng: 19.3796572 },
    { lat: 49.1878947, lng: 19.3797203 },
    { lat: 49.1875917, lng: 19.3796754 },
    { lat: 49.1876074, lng: 19.3796017 },
    { lat: 49.1874356, lng: 19.3795682 },
    { lat: 49.1873732, lng: 19.3795378 },
    { lat: 49.1873363, lng: 19.3794442 },
    { lat: 49.187225, lng: 19.3793519 },
    { lat: 49.1871387, lng: 19.3792438 },
    { lat: 49.1870133, lng: 19.3791166 },
    { lat: 49.1868323, lng: 19.3790787 },
    { lat: 49.1865792, lng: 19.3788692 },
    { lat: 49.1863954, lng: 19.3787256 },
    { lat: 49.1862306, lng: 19.3786189 },
    { lat: 49.1856388, lng: 19.3783263 },
    { lat: 49.1855587, lng: 19.3783264 },
    { lat: 49.1855338, lng: 19.3782981 },
    { lat: 49.1854699, lng: 19.378259 },
    { lat: 49.1854561, lng: 19.3782515 },
    { lat: 49.1854386, lng: 19.3782415 },
    { lat: 49.1853914, lng: 19.378216 },
    { lat: 49.1854569, lng: 19.378103 },
    { lat: 49.1854751, lng: 19.3780066 },
    { lat: 49.1854984, lng: 19.377976 },
    { lat: 49.1854902, lng: 19.3779217 },
    { lat: 49.1855118, lng: 19.3777313 },
    { lat: 49.1855728, lng: 19.3776166 },
    { lat: 49.185611, lng: 19.3775155 },
    { lat: 49.1856469, lng: 19.3774201 },
    { lat: 49.1856948, lng: 19.3772816 },
    { lat: 49.1857521, lng: 19.3769681 },
    { lat: 49.1856612, lng: 19.3769978 },
    { lat: 49.1856635, lng: 19.3769511 },
    { lat: 49.1856674, lng: 19.3769139 },
    { lat: 49.1855925, lng: 19.3768831 },
    { lat: 49.1855392, lng: 19.3769437 },
    { lat: 49.1854789, lng: 19.3769063 },
    { lat: 49.1853952, lng: 19.3769039 },
    { lat: 49.1852482, lng: 19.3769181 },
    { lat: 49.1851228, lng: 19.3769076 },
    { lat: 49.1850667, lng: 19.3767924 },
    { lat: 49.1850053, lng: 19.3767324 },
    { lat: 49.1848556, lng: 19.3766179 },
    { lat: 49.1848193, lng: 19.3766125 },
    { lat: 49.1847141, lng: 19.3767018 },
    { lat: 49.1846636, lng: 19.3767537 },
    { lat: 49.1845569, lng: 19.3768544 },
    { lat: 49.1845104, lng: 19.3768508 },
    { lat: 49.1844592, lng: 19.3768859 },
    { lat: 49.1844066, lng: 19.3769176 },
    { lat: 49.1843492, lng: 19.3769469 },
    { lat: 49.1842677, lng: 19.3770134 },
    { lat: 49.1842383, lng: 19.3770551 },
    { lat: 49.1841969, lng: 19.377038 },
    { lat: 49.1841676, lng: 19.3770643 },
    { lat: 49.1841342, lng: 19.3770456 },
    { lat: 49.1840804, lng: 19.3770777 },
    { lat: 49.1839668, lng: 19.377178 },
    { lat: 49.1839154, lng: 19.3771403 },
    { lat: 49.183873, lng: 19.3771475 },
    { lat: 49.1837961, lng: 19.3772139 },
    { lat: 49.183772, lng: 19.377254 },
    { lat: 49.1837372, lng: 19.3772563 },
    { lat: 49.1836017, lng: 19.3773233 },
    { lat: 49.1835333, lng: 19.3773297 },
    { lat: 49.1834352, lng: 19.3773987 },
    { lat: 49.1833995, lng: 19.3774076 },
    { lat: 49.1833456, lng: 19.3774113 },
    { lat: 49.1832675, lng: 19.3774533 },
    { lat: 49.1832327, lng: 19.3774831 },
    { lat: 49.1832144, lng: 19.3775449 },
    { lat: 49.1831806, lng: 19.3775503 },
    { lat: 49.1831536, lng: 19.377599 },
    { lat: 49.1831146, lng: 19.3776087 },
    { lat: 49.1830846, lng: 19.3776635 },
    { lat: 49.1829985, lng: 19.3777009 },
    { lat: 49.1829004, lng: 19.3777246 },
    { lat: 49.182815, lng: 19.3777814 },
    { lat: 49.1827074, lng: 19.3778893 },
    { lat: 49.1826306, lng: 19.3779067 },
    { lat: 49.1824844, lng: 19.3779874 },
    { lat: 49.1823646, lng: 19.3779814 },
    { lat: 49.1823218, lng: 19.3780754 },
    { lat: 49.1822936, lng: 19.3781239 },
    { lat: 49.1822663, lng: 19.3781661 },
    { lat: 49.182234, lng: 19.3781981 },
    { lat: 49.1822393, lng: 19.3782159 },
    { lat: 49.1822623, lng: 19.378298 },
    { lat: 49.1826975, lng: 19.3791908 },
    { lat: 49.1829943, lng: 19.379661 },
    { lat: 49.1827144, lng: 19.3799166 },
    { lat: 49.1824431, lng: 19.3801648 },
    { lat: 49.1816534, lng: 19.3807504 },
    { lat: 49.1813311, lng: 19.3815679 },
    { lat: 49.1810014, lng: 19.3817244 },
    { lat: 49.1805403, lng: 19.3821492 },
    { lat: 49.1803867, lng: 19.3822894 },
    { lat: 49.1801979, lng: 19.3824678 },
    { lat: 49.1798993, lng: 19.3826382 },
    { lat: 49.1798472, lng: 19.3827062 },
    { lat: 49.1797558, lng: 19.3828011 },
    { lat: 49.1796444, lng: 19.3829242 },
    { lat: 49.178994, lng: 19.3832294 },
    { lat: 49.1779463, lng: 19.3837209 },
    { lat: 49.1767833, lng: 19.3843235 },
    { lat: 49.1764146, lng: 19.3845127 },
    { lat: 49.1762541, lng: 19.3845946 },
    { lat: 49.1757961, lng: 19.3847799 },
    { lat: 49.1757275, lng: 19.3848075 },
    { lat: 49.1754, lng: 19.384925 },
    { lat: 49.175378, lng: 19.384941 },
    { lat: 49.175035, lng: 19.38578 },
    { lat: 49.174741, lng: 19.386059 },
    { lat: 49.173757, lng: 19.386598 },
    { lat: 49.173739, lng: 19.38658 },
    { lat: 49.173618, lng: 19.387194 },
    { lat: 49.173493, lng: 19.387485 },
    { lat: 49.173401, lng: 19.387936 },
    { lat: 49.173263, lng: 19.388416 },
    { lat: 49.173221, lng: 19.388758 },
    { lat: 49.173059, lng: 19.389435 },
    { lat: 49.173044, lng: 19.389854 },
    { lat: 49.173, lng: 19.390478 },
    { lat: 49.172957, lng: 19.390867 },
    { lat: 49.172932, lng: 19.391049 },
    { lat: 49.172952, lng: 19.391055 },
    { lat: 49.172826, lng: 19.391547 },
    { lat: 49.172817, lng: 19.391642 },
    { lat: 49.172646, lng: 19.392184 },
    { lat: 49.172744, lng: 19.39287 },
    { lat: 49.172789, lng: 19.39324 },
    { lat: 49.172891, lng: 19.393663 },
    { lat: 49.172948, lng: 19.394014 },
    { lat: 49.172972, lng: 19.394117 },
    { lat: 49.173018, lng: 19.394727 },
    { lat: 49.172951, lng: 19.395033 },
    { lat: 49.173876, lng: 19.397329 },
    { lat: 49.173983, lng: 19.397868 },
    { lat: 49.174078, lng: 19.398344 },
    { lat: 49.17423, lng: 19.398679 },
    { lat: 49.174517, lng: 19.399348 },
    { lat: 49.174588, lng: 19.399601 },
    { lat: 49.174224, lng: 19.401228 },
    { lat: 49.174197, lng: 19.402125 },
    { lat: 49.174217, lng: 19.403105 },
    { lat: 49.174245, lng: 19.403967 },
    { lat: 49.174259, lng: 19.405052 },
    { lat: 49.174556, lng: 19.406343 },
    { lat: 49.174731, lng: 19.406569 },
    { lat: 49.174874, lng: 19.406854 },
    { lat: 49.174906, lng: 19.407014 },
    { lat: 49.175197, lng: 19.409119 },
    { lat: 49.1758346, lng: 19.4095379 },
    { lat: 49.1765084, lng: 19.4101907 },
    { lat: 49.1769687, lng: 19.4108522 },
    { lat: 49.177495, lng: 19.4114133 },
    { lat: 49.1778079, lng: 19.411745 },
    { lat: 49.1782241, lng: 19.4121899 },
    { lat: 49.178528, lng: 19.4123518 },
    { lat: 49.1788449, lng: 19.4124541 },
    { lat: 49.1794474, lng: 19.4127869 },
    { lat: 49.1803955, lng: 19.4131587 },
    { lat: 49.1812007, lng: 19.4138734 },
    { lat: 49.1819877, lng: 19.4148996 },
    { lat: 49.1826489, lng: 19.4151843 },
    { lat: 49.1836096, lng: 19.4145203 },
    { lat: 49.1842179, lng: 19.4139823 },
    { lat: 49.1844333, lng: 19.4138156 },
    { lat: 49.1846623, lng: 19.413699 },
    { lat: 49.1848926, lng: 19.4136135 },
    { lat: 49.1850753, lng: 19.4135923 },
    { lat: 49.1851597, lng: 19.413589 },
    { lat: 49.185361, lng: 19.4135812 },
    { lat: 49.1857664, lng: 19.4136199 },
    { lat: 49.1861547, lng: 19.4137083 },
    { lat: 49.1862207, lng: 19.4137228 },
    { lat: 49.1863963, lng: 19.4137452 },
    { lat: 49.1865739, lng: 19.4137357 },
    { lat: 49.1866436, lng: 19.4137325 },
    { lat: 49.1867145, lng: 19.4137284 },
    { lat: 49.1869058, lng: 19.4136739 },
    { lat: 49.1869368, lng: 19.4136648 },
    { lat: 49.1870058, lng: 19.4136454 },
    { lat: 49.1870668, lng: 19.4136208 },
    { lat: 49.1873444, lng: 19.4135068 },
    { lat: 49.1873058, lng: 19.4133122 },
    { lat: 49.1872297, lng: 19.4128575 },
    { lat: 49.1871459, lng: 19.4124371 },
    { lat: 49.1868846, lng: 19.4109855 },
    { lat: 49.1866101, lng: 19.4093736 },
    { lat: 49.1865905, lng: 19.4092939 },
    { lat: 49.1866572, lng: 19.4092237 },
    { lat: 49.1869107, lng: 19.4088363 },
    { lat: 49.1874787, lng: 19.4079131 },
    { lat: 49.1875711, lng: 19.4077479 },
    { lat: 49.1876329, lng: 19.4076446 },
    { lat: 49.1877852, lng: 19.4073803 },
    { lat: 49.1879143, lng: 19.4071757 },
    { lat: 49.1880325, lng: 19.4068859 },
    { lat: 49.188049, lng: 19.4069 },
    { lat: 49.188393, lng: 19.406229 },
    { lat: 49.1885694, lng: 19.405609 },
    { lat: 49.1886508, lng: 19.4054467 },
    { lat: 49.1887516, lng: 19.4051659 },
    { lat: 49.1889709, lng: 19.40448 },
    { lat: 49.1891284, lng: 19.4039164 },
    { lat: 49.1891603, lng: 19.4037808 },
    { lat: 49.1893141, lng: 19.4031387 },
    { lat: 49.1892088, lng: 19.4020167 },
    { lat: 49.1891529, lng: 19.4017803 },
    { lat: 49.1890893, lng: 19.4013124 },
    { lat: 49.1889859, lng: 19.4007407 },
    { lat: 49.1889659, lng: 19.4007418 },
    { lat: 49.1889583, lng: 19.400628 },
    { lat: 49.1889676, lng: 19.4002963 },
    { lat: 49.1890024, lng: 19.3997157 },
    { lat: 49.1891522, lng: 19.3984299 },
    { lat: 49.1892466, lng: 19.397795 },
    { lat: 49.1896066, lng: 19.3966481 },
    { lat: 49.1896669, lng: 19.3963465 },
    { lat: 49.1896854, lng: 19.3963524 },
    { lat: 49.1899028, lng: 19.3955308 },
    { lat: 49.1899163, lng: 19.3954807 },
    { lat: 49.1899742, lng: 19.3948634 },
    { lat: 49.1900048, lng: 19.3947458 },
    { lat: 49.1901218, lng: 19.3943066 },
    { lat: 49.1901679, lng: 19.3942065 },
    { lat: 49.1903362, lng: 19.3938499 },
    { lat: 49.190548, lng: 19.393298 },
    { lat: 49.1905581, lng: 19.3932728 },
    { lat: 49.1906724, lng: 19.3928918 },
    { lat: 49.1909742, lng: 19.392382 },
    { lat: 49.1910155, lng: 19.3922298 },
    { lat: 49.1911789, lng: 19.3921324 },
    { lat: 49.1913366, lng: 19.3919654 },
    { lat: 49.1916028, lng: 19.3917038 },
    { lat: 49.1918457, lng: 19.3913517 },
    { lat: 49.1919987, lng: 19.3908411 },
    { lat: 49.192065, lng: 19.390425 },
    { lat: 49.1920793, lng: 19.3892132 },
    { lat: 49.1919586, lng: 19.3892069 },
    { lat: 49.1919382, lng: 19.3892048 },
    { lat: 49.192026, lng: 19.3875861 },
    { lat: 49.1919964, lng: 19.3869765 },
    { lat: 49.192016, lng: 19.3863604 },
    { lat: 49.1920391, lng: 19.3860344 },
    { lat: 49.1921028, lng: 19.3856347 },
    { lat: 49.1921529, lng: 19.3854538 },
    { lat: 49.1921701, lng: 19.3852199 },
    { lat: 49.1921529, lng: 19.3849721 },
    { lat: 49.1921312, lng: 19.3848682 },
    { lat: 49.1920499, lng: 19.3846738 },
    { lat: 49.1919853, lng: 19.3845202 },
    { lat: 49.1919704, lng: 19.3845402 },
    { lat: 49.1919567, lng: 19.3845089 },
    { lat: 49.1918017, lng: 19.3841827 },
    { lat: 49.1917179, lng: 19.3840074 },
    { lat: 49.1916741, lng: 19.3839138 },
    { lat: 49.1914855, lng: 19.3832541 },
    { lat: 49.1911582, lng: 19.3822074 },
    { lat: 49.1910868, lng: 19.3818826 },
    { lat: 49.191062, lng: 19.381352 },
    { lat: 49.1909919, lng: 19.3807454 },
    { lat: 49.1909211, lng: 19.3799291 },
    { lat: 49.190875, lng: 19.3796636 },
  ],
  Pokryváč: [
    { lat: 49.1919853, lng: 19.3845202 },
    { lat: 49.1920499, lng: 19.3846738 },
    { lat: 49.1921312, lng: 19.3848682 },
    { lat: 49.1921529, lng: 19.3849721 },
    { lat: 49.1921701, lng: 19.3852199 },
    { lat: 49.1921529, lng: 19.3854538 },
    { lat: 49.1921028, lng: 19.3856347 },
    { lat: 49.1920391, lng: 19.3860344 },
    { lat: 49.192016, lng: 19.3863604 },
    { lat: 49.1919964, lng: 19.3869765 },
    { lat: 49.192026, lng: 19.3875861 },
    { lat: 49.1919382, lng: 19.3892048 },
    { lat: 49.1919586, lng: 19.3892069 },
    { lat: 49.1920793, lng: 19.3892132 },
    { lat: 49.192065, lng: 19.390425 },
    { lat: 49.1919987, lng: 19.3908411 },
    { lat: 49.1918457, lng: 19.3913517 },
    { lat: 49.1916028, lng: 19.3917038 },
    { lat: 49.1913366, lng: 19.3919654 },
    { lat: 49.1911789, lng: 19.3921324 },
    { lat: 49.1910155, lng: 19.3922298 },
    { lat: 49.1909742, lng: 19.392382 },
    { lat: 49.1906724, lng: 19.3928918 },
    { lat: 49.1905581, lng: 19.3932728 },
    { lat: 49.190548, lng: 19.393298 },
    { lat: 49.1903362, lng: 19.3938499 },
    { lat: 49.1901679, lng: 19.3942065 },
    { lat: 49.1901218, lng: 19.3943066 },
    { lat: 49.1900048, lng: 19.3947458 },
    { lat: 49.1899742, lng: 19.3948634 },
    { lat: 49.1899163, lng: 19.3954807 },
    { lat: 49.1899028, lng: 19.3955308 },
    { lat: 49.1896854, lng: 19.3963524 },
    { lat: 49.1896669, lng: 19.3963465 },
    { lat: 49.1896066, lng: 19.3966481 },
    { lat: 49.1892466, lng: 19.397795 },
    { lat: 49.1891522, lng: 19.3984299 },
    { lat: 49.1890024, lng: 19.3997157 },
    { lat: 49.1889676, lng: 19.4002963 },
    { lat: 49.1889583, lng: 19.400628 },
    { lat: 49.1889659, lng: 19.4007418 },
    { lat: 49.1889859, lng: 19.4007407 },
    { lat: 49.1890893, lng: 19.4013124 },
    { lat: 49.1891529, lng: 19.4017803 },
    { lat: 49.1892088, lng: 19.4020167 },
    { lat: 49.1893141, lng: 19.4031387 },
    { lat: 49.1891603, lng: 19.4037808 },
    { lat: 49.1891284, lng: 19.4039164 },
    { lat: 49.1889709, lng: 19.40448 },
    { lat: 49.1887516, lng: 19.4051659 },
    { lat: 49.1886508, lng: 19.4054467 },
    { lat: 49.1885694, lng: 19.405609 },
    { lat: 49.188393, lng: 19.406229 },
    { lat: 49.188049, lng: 19.4069 },
    { lat: 49.1880325, lng: 19.4068859 },
    { lat: 49.1879143, lng: 19.4071757 },
    { lat: 49.1877852, lng: 19.4073803 },
    { lat: 49.1876329, lng: 19.4076446 },
    { lat: 49.1875711, lng: 19.4077479 },
    { lat: 49.1874787, lng: 19.4079131 },
    { lat: 49.1869107, lng: 19.4088363 },
    { lat: 49.1866572, lng: 19.4092237 },
    { lat: 49.1865905, lng: 19.4092939 },
    { lat: 49.1866101, lng: 19.4093736 },
    { lat: 49.1868846, lng: 19.4109855 },
    { lat: 49.1871459, lng: 19.4124371 },
    { lat: 49.1872297, lng: 19.4128575 },
    { lat: 49.1873058, lng: 19.4133122 },
    { lat: 49.1873444, lng: 19.4135068 },
    { lat: 49.187435, lng: 19.4134253 },
    { lat: 49.1876555, lng: 19.4132203 },
    { lat: 49.1881551, lng: 19.4124321 },
    { lat: 49.1881358, lng: 19.41228 },
    { lat: 49.1883153, lng: 19.4119709 },
    { lat: 49.1885218, lng: 19.4131939 },
    { lat: 49.188913, lng: 19.4133141 },
    { lat: 49.188977, lng: 19.4133331 },
    { lat: 49.1891169, lng: 19.4133414 },
    { lat: 49.1893583, lng: 19.4133945 },
    { lat: 49.1896692, lng: 19.4134629 },
    { lat: 49.1899211, lng: 19.4134526 },
    { lat: 49.1904993, lng: 19.4134286 },
    { lat: 49.191238, lng: 19.4132229 },
    { lat: 49.1914231, lng: 19.4135214 },
    { lat: 49.1921091, lng: 19.4137819 },
    { lat: 49.1921671, lng: 19.413821 },
    { lat: 49.1921915, lng: 19.4138374 },
    { lat: 49.1924334, lng: 19.4134885 },
    { lat: 49.1930878, lng: 19.4130941 },
    { lat: 49.1938008, lng: 19.4128167 },
    { lat: 49.1938316, lng: 19.4128043 },
    { lat: 49.194165, lng: 19.4126757 },
    { lat: 49.1945801, lng: 19.4124232 },
    { lat: 49.194793, lng: 19.4131968 },
    { lat: 49.1951704, lng: 19.4132399 },
    { lat: 49.1954876, lng: 19.4132766 },
    { lat: 49.1957846, lng: 19.4133158 },
    { lat: 49.1967029, lng: 19.4134373 },
    { lat: 49.1968027, lng: 19.4134506 },
    { lat: 49.197051, lng: 19.4136014 },
    { lat: 49.1971715, lng: 19.4136734 },
    { lat: 49.1974512, lng: 19.4136565 },
    { lat: 49.1976705, lng: 19.4135989 },
    { lat: 49.1980234, lng: 19.4135056 },
    { lat: 49.1981627, lng: 19.4134696 },
    { lat: 49.1984584, lng: 19.4141619 },
    { lat: 49.1984945, lng: 19.4141601 },
    { lat: 49.1995532, lng: 19.414078 },
    { lat: 49.1996737, lng: 19.4139767 },
    { lat: 49.1995379, lng: 19.4134329 },
    { lat: 49.1994312, lng: 19.4128787 },
    { lat: 49.1994193, lng: 19.4124051 },
    { lat: 49.199423, lng: 19.412027 },
    { lat: 49.1994153, lng: 19.4116874 },
    { lat: 49.1994857, lng: 19.4111904 },
    { lat: 49.1998347, lng: 19.4107242 },
    { lat: 49.2000068, lng: 19.4104998 },
    { lat: 49.2002104, lng: 19.4103935 },
    { lat: 49.2002808, lng: 19.4103091 },
    { lat: 49.2004437, lng: 19.4101051 },
    { lat: 49.2005565, lng: 19.4098883 },
    { lat: 49.2007067, lng: 19.4094576 },
    { lat: 49.2010833, lng: 19.4090514 },
    { lat: 49.2014193, lng: 19.4084778 },
    { lat: 49.2016721, lng: 19.4083621 },
    { lat: 49.2023748, lng: 19.4087631 },
    { lat: 49.2025371, lng: 19.4087398 },
    { lat: 49.2026765, lng: 19.4089752 },
    { lat: 49.2028577, lng: 19.4089465 },
    { lat: 49.2029267, lng: 19.409196 },
    { lat: 49.2031323, lng: 19.4094494 },
    { lat: 49.2032962, lng: 19.4095315 },
    { lat: 49.2034678, lng: 19.4096102 },
    { lat: 49.203652, lng: 19.4095492 },
    { lat: 49.2036773, lng: 19.4095151 },
    { lat: 49.2036896, lng: 19.4093433 },
    { lat: 49.2037197, lng: 19.4093105 },
    { lat: 49.2037315, lng: 19.4093002 },
    { lat: 49.2042282, lng: 19.4095618 },
    { lat: 49.2043354, lng: 19.4094644 },
    { lat: 49.2043933, lng: 19.4094505 },
    { lat: 49.2045838, lng: 19.4095258 },
    { lat: 49.2046554, lng: 19.4094914 },
    { lat: 49.2048216, lng: 19.4091137 },
    { lat: 49.2049236, lng: 19.4090519 },
    { lat: 49.2050012, lng: 19.4089544 },
    { lat: 49.2050262, lng: 19.4089597 },
    { lat: 49.2050682, lng: 19.4090603 },
    { lat: 49.2051705, lng: 19.4090298 },
    { lat: 49.205155, lng: 19.4088895 },
    { lat: 49.2051983, lng: 19.4087792 },
    { lat: 49.2054689, lng: 19.4087333 },
    { lat: 49.2055385, lng: 19.4086566 },
    { lat: 49.2054754, lng: 19.4085135 },
    { lat: 49.2054238, lng: 19.4085162 },
    { lat: 49.2053776, lng: 19.4083951 },
    { lat: 49.2054622, lng: 19.4081787 },
    { lat: 49.2054614, lng: 19.4078984 },
    { lat: 49.2056318, lng: 19.407419 },
    { lat: 49.2057176, lng: 19.4073233 },
    { lat: 49.2057022, lng: 19.4070172 },
    { lat: 49.2056532, lng: 19.4068326 },
    { lat: 49.2057541, lng: 19.4065809 },
    { lat: 49.2057592, lng: 19.4065227 },
    { lat: 49.2057385, lng: 19.4064882 },
    { lat: 49.2058037, lng: 19.4062649 },
    { lat: 49.2059255, lng: 19.4060012 },
    { lat: 49.2060049, lng: 19.4059667 },
    { lat: 49.2060663, lng: 19.4058299 },
    { lat: 49.2061203, lng: 19.4057307 },
    { lat: 49.2062971, lng: 19.4054409 },
    { lat: 49.2063351, lng: 19.4053582 },
    { lat: 49.2064141, lng: 19.4053118 },
    { lat: 49.2063435, lng: 19.4050773 },
    { lat: 49.2063463, lng: 19.4048485 },
    { lat: 49.2063884, lng: 19.4046627 },
    { lat: 49.2063624, lng: 19.404322 },
    { lat: 49.2062071, lng: 19.4040131 },
    { lat: 49.2060356, lng: 19.403428 },
    { lat: 49.2059079, lng: 19.403008 },
    { lat: 49.2057939, lng: 19.4026387 },
    { lat: 49.2054969, lng: 19.4015854 },
    { lat: 49.2053118, lng: 19.4009272 },
    { lat: 49.2051897, lng: 19.4002629 },
    { lat: 49.205093, lng: 19.3998071 },
    { lat: 49.2049784, lng: 19.3992584 },
    { lat: 49.2052873, lng: 19.3988988 },
    { lat: 49.2050003, lng: 19.3981324 },
    { lat: 49.2051628, lng: 19.3976758 },
    { lat: 49.2054124, lng: 19.3969157 },
    { lat: 49.2055411, lng: 19.3966776 },
    { lat: 49.2058366, lng: 19.3963208 },
    { lat: 49.2064399, lng: 19.395656 },
    { lat: 49.2068453, lng: 19.3952148 },
    { lat: 49.2068473, lng: 19.3946999 },
    { lat: 49.2068515, lng: 19.3943546 },
    { lat: 49.2068468, lng: 19.3940877 },
    { lat: 49.2069046, lng: 19.3935387 },
    { lat: 49.2069507, lng: 19.3931235 },
    { lat: 49.2070047, lng: 19.3925904 },
    { lat: 49.2069845, lng: 19.3920368 },
    { lat: 49.2069446, lng: 19.3916869 },
    { lat: 49.2069284, lng: 19.3915816 },
    { lat: 49.2069138, lng: 19.3914863 },
    { lat: 49.2069053, lng: 19.3914256 },
    { lat: 49.2068758, lng: 19.3912298 },
    { lat: 49.2068596, lng: 19.3906391 },
    { lat: 49.2068556, lng: 19.3905033 },
    { lat: 49.2069698, lng: 19.3905111 },
    { lat: 49.2070783, lng: 19.3905696 },
    { lat: 49.207287, lng: 19.3904269 },
    { lat: 49.2074697, lng: 19.3905523 },
    { lat: 49.2074388, lng: 19.3904702 },
    { lat: 49.2073892, lng: 19.3903707 },
    { lat: 49.207346, lng: 19.390339 },
    { lat: 49.2072367, lng: 19.3901469 },
    { lat: 49.2072008, lng: 19.3901025 },
    { lat: 49.2071302, lng: 19.3900878 },
    { lat: 49.2070692, lng: 19.3900105 },
    { lat: 49.2070327, lng: 19.3899305 },
    { lat: 49.2069241, lng: 19.3898457 },
    { lat: 49.2068795, lng: 19.3897338 },
    { lat: 49.2069377, lng: 19.3895098 },
    { lat: 49.206954, lng: 19.3892049 },
    { lat: 49.2068949, lng: 19.3891223 },
    { lat: 49.2068506, lng: 19.3889484 },
    { lat: 49.2069039, lng: 19.3886431 },
    { lat: 49.2069024, lng: 19.3882457 },
    { lat: 49.2068376, lng: 19.3878953 },
    { lat: 49.206878, lng: 19.3877496 },
    { lat: 49.2068631, lng: 19.3875004 },
    { lat: 49.2068278, lng: 19.3874675 },
    { lat: 49.2068001, lng: 19.3873101 },
    { lat: 49.2068346, lng: 19.3872281 },
    { lat: 49.2068377, lng: 19.3871283 },
    { lat: 49.2068046, lng: 19.3869005 },
    { lat: 49.2067782, lng: 19.3868393 },
    { lat: 49.2067833, lng: 19.3867828 },
    { lat: 49.2067525, lng: 19.3866986 },
    { lat: 49.206656, lng: 19.3863213 },
    { lat: 49.2066538, lng: 19.3862034 },
    { lat: 49.2065937, lng: 19.3861454 },
    { lat: 49.2065684, lng: 19.3860177 },
    { lat: 49.2065069, lng: 19.3858795 },
    { lat: 49.2064319, lng: 19.385724 },
    { lat: 49.2064092, lng: 19.3855978 },
    { lat: 49.2063081, lng: 19.3855108 },
    { lat: 49.206121, lng: 19.3852208 },
    { lat: 49.2061216, lng: 19.3851382 },
    { lat: 49.2060238, lng: 19.3850916 },
    { lat: 49.2059037, lng: 19.3849576 },
    { lat: 49.2057733, lng: 19.3849408 },
    { lat: 49.2056797, lng: 19.3848418 },
    { lat: 49.205567, lng: 19.3848403 },
    { lat: 49.2054106, lng: 19.3847748 },
    { lat: 49.2052981, lng: 19.3848274 },
    { lat: 49.2050828, lng: 19.3848043 },
    { lat: 49.204828, lng: 19.3847719 },
    { lat: 49.2047498, lng: 19.3848388 },
    { lat: 49.2046918, lng: 19.3847798 },
    { lat: 49.2046284, lng: 19.3847709 },
    { lat: 49.2044846, lng: 19.3846355 },
    { lat: 49.2043387, lng: 19.3845753 },
    { lat: 49.2041484, lng: 19.3843128 },
    { lat: 49.2039712, lng: 19.3837796 },
    { lat: 49.2038973, lng: 19.3837649 },
    { lat: 49.2037606, lng: 19.3835881 },
    { lat: 49.2036027, lng: 19.3833436 },
    { lat: 49.2033987, lng: 19.3831709 },
    { lat: 49.203182, lng: 19.3828993 },
    { lat: 49.2031062, lng: 19.3827728 },
    { lat: 49.2029339, lng: 19.3824877 },
    { lat: 49.2028366, lng: 19.3824313 },
    { lat: 49.2027429, lng: 19.3823323 },
    { lat: 49.2025836, lng: 19.3822282 },
    { lat: 49.2024672, lng: 19.3821713 },
    { lat: 49.2023574, lng: 19.3820153 },
    { lat: 49.2022148, lng: 19.3817137 },
    { lat: 49.2020736, lng: 19.3814148 },
    { lat: 49.201901, lng: 19.3810253 },
    { lat: 49.2017238, lng: 19.3806594 },
    { lat: 49.2014687, lng: 19.3802147 },
    { lat: 49.2013185, lng: 19.3799665 },
    { lat: 49.2012257, lng: 19.3798159 },
    { lat: 49.2011377, lng: 19.3799588 },
    { lat: 49.2010792, lng: 19.3800816 },
    { lat: 49.2010097, lng: 19.3802301 },
    { lat: 49.2007812, lng: 19.3806474 },
    { lat: 49.2004969, lng: 19.381097 },
    { lat: 49.2003112, lng: 19.3813684 },
    { lat: 49.2000101, lng: 19.3816996 },
    { lat: 49.1998782, lng: 19.3817744 },
    { lat: 49.199817, lng: 19.3818121 },
    { lat: 49.1997429, lng: 19.3818443 },
    { lat: 49.1995856, lng: 19.3818787 },
    { lat: 49.1994258, lng: 19.3818803 },
    { lat: 49.1992802, lng: 19.381877 },
    { lat: 49.1991059, lng: 19.3818607 },
    { lat: 49.1989926, lng: 19.3818478 },
    { lat: 49.1989489, lng: 19.3818525 },
    { lat: 49.1987967, lng: 19.3818741 },
    { lat: 49.1986046, lng: 19.3819138 },
    { lat: 49.1984161, lng: 19.3819581 },
    { lat: 49.1979109, lng: 19.382066 },
    { lat: 49.1977075, lng: 19.3821307 },
    { lat: 49.197207, lng: 19.3823898 },
    { lat: 49.1971457, lng: 19.3824275 },
    { lat: 49.1969181, lng: 19.3829127 },
    { lat: 49.196497, lng: 19.3829938 },
    { lat: 49.1964051, lng: 19.3830028 },
    { lat: 49.1962092, lng: 19.382934 },
    { lat: 49.1960533, lng: 19.3828068 },
    { lat: 49.1959695, lng: 19.3827007 },
    { lat: 49.1958634, lng: 19.3825032 },
    { lat: 49.195807, lng: 19.3824074 },
    { lat: 49.1957582, lng: 19.3823035 },
    { lat: 49.1955907, lng: 19.3819726 },
    { lat: 49.1955285, lng: 19.3818486 },
    { lat: 49.1954789, lng: 19.3817977 },
    { lat: 49.1953412, lng: 19.3816506 },
    { lat: 49.1949271, lng: 19.3814715 },
    { lat: 49.1946867, lng: 19.3813647 },
    { lat: 49.1945201, lng: 19.3812705 },
    { lat: 49.1943501, lng: 19.3812014 },
    { lat: 49.1941936, lng: 19.3811323 },
    { lat: 49.1940325, lng: 19.381303 },
    { lat: 49.1939386, lng: 19.3814915 },
    { lat: 49.1938119, lng: 19.38167 },
    { lat: 49.1935176, lng: 19.3819831 },
    { lat: 49.1932709, lng: 19.382247 },
    { lat: 49.1930542, lng: 19.3825303 },
    { lat: 49.1929846, lng: 19.3826334 },
    { lat: 49.192876, lng: 19.3828167 },
    { lat: 49.1926406, lng: 19.383181 },
    { lat: 49.1925426, lng: 19.3833268 },
    { lat: 49.1924968, lng: 19.3834071 },
    { lat: 49.1924349, lng: 19.3835028 },
    { lat: 49.1922893, lng: 19.3838847 },
    { lat: 49.1921805, lng: 19.3841642 },
    { lat: 49.1920463, lng: 19.3843806 },
    { lat: 49.1919978, lng: 19.3844734 },
    { lat: 49.1919853, lng: 19.3845202 },
  ],
  Pribiš: [
    { lat: 49.2354769, lng: 19.390816 },
    { lat: 49.2352609, lng: 19.390868 },
    { lat: 49.2350707, lng: 19.3908768 },
    { lat: 49.2347967, lng: 19.3910869 },
    { lat: 49.2344898, lng: 19.3912958 },
    { lat: 49.2341647, lng: 19.3914105 },
    { lat: 49.2337256, lng: 19.3912945 },
    { lat: 49.2331608, lng: 19.3907836 },
    { lat: 49.232904, lng: 19.3914347 },
    { lat: 49.232698, lng: 19.391682 },
    { lat: 49.2325004, lng: 19.3919401 },
    { lat: 49.2322158, lng: 19.3921924 },
    { lat: 49.2319102, lng: 19.3927199 },
    { lat: 49.231891, lng: 19.3926979 },
    { lat: 49.2318541, lng: 19.3926554 },
    { lat: 49.2317103, lng: 19.3924925 },
    { lat: 49.2313396, lng: 19.3921801 },
    { lat: 49.2312399, lng: 19.3920972 },
    { lat: 49.2311391, lng: 19.3920123 },
    { lat: 49.230847, lng: 19.3918645 },
    { lat: 49.2304627, lng: 19.3916711 },
    { lat: 49.2292754, lng: 19.391361 },
    { lat: 49.2288593, lng: 19.3913103 },
    { lat: 49.228201, lng: 19.3911338 },
    { lat: 49.2273904, lng: 19.3908603 },
    { lat: 49.2269391, lng: 19.3907272 },
    { lat: 49.2267145, lng: 19.3906265 },
    { lat: 49.2265643, lng: 19.3905196 },
    { lat: 49.2265793, lng: 19.3904808 },
    { lat: 49.2264143, lng: 19.3903015 },
    { lat: 49.2262027, lng: 19.3900426 },
    { lat: 49.226044, lng: 19.3901629 },
    { lat: 49.225837, lng: 19.3904137 },
    { lat: 49.2255108, lng: 19.3900154 },
    { lat: 49.2251324, lng: 19.3896333 },
    { lat: 49.2249561, lng: 19.3894076 },
    { lat: 49.2247496, lng: 19.3897845 },
    { lat: 49.2244502, lng: 19.3905193 },
    { lat: 49.2243927, lng: 19.3907358 },
    { lat: 49.2242651, lng: 19.3912147 },
    { lat: 49.2239897, lng: 19.3918319 },
    { lat: 49.2237751, lng: 19.3922579 },
    { lat: 49.2235339, lng: 19.392543 },
    { lat: 49.2234878, lng: 19.3925974 },
    { lat: 49.2234676, lng: 19.3926209 },
    { lat: 49.2231611, lng: 19.3929865 },
    { lat: 49.2229838, lng: 19.3931688 },
    { lat: 49.222538, lng: 19.3936869 },
    { lat: 49.2224017, lng: 19.3939083 },
    { lat: 49.2218396, lng: 19.3949507 },
    { lat: 49.2217068, lng: 19.3951259 },
    { lat: 49.2216419, lng: 19.3952072 },
    { lat: 49.2215183, lng: 19.3953122 },
    { lat: 49.2212868, lng: 19.3954674 },
    { lat: 49.2202605, lng: 19.3958864 },
    { lat: 49.2200845, lng: 19.3960255 },
    { lat: 49.2199626, lng: 19.3961213 },
    { lat: 49.2199169, lng: 19.3961548 },
    { lat: 49.2197459, lng: 19.3965237 },
    { lat: 49.2193027, lng: 19.3974828 },
    { lat: 49.2192256, lng: 19.3976671 },
    { lat: 49.2189038, lng: 19.3980628 },
    { lat: 49.2185739, lng: 19.3983376 },
    { lat: 49.2185003, lng: 19.3984001 },
    { lat: 49.2180578, lng: 19.3987696 },
    { lat: 49.2177642, lng: 19.3990462 },
    { lat: 49.2175211, lng: 19.3992027 },
    { lat: 49.2175124, lng: 19.3992084 },
    { lat: 49.2172552, lng: 19.3993751 },
    { lat: 49.2169051, lng: 19.3995479 },
    { lat: 49.2168359, lng: 19.3995878 },
    { lat: 49.2167941, lng: 19.3996089 },
    { lat: 49.216739, lng: 19.3996369 },
    { lat: 49.2163898, lng: 19.3998064 },
    { lat: 49.2162337, lng: 19.3999181 },
    { lat: 49.2160834, lng: 19.4000757 },
    { lat: 49.2161725, lng: 19.4003674 },
    { lat: 49.2161884, lng: 19.4004204 },
    { lat: 49.2162043, lng: 19.4004724 },
    { lat: 49.216112, lng: 19.400569 },
    { lat: 49.2160205, lng: 19.4006639 },
    { lat: 49.215912, lng: 19.4007766 },
    { lat: 49.2159274, lng: 19.4008391 },
    { lat: 49.2159412, lng: 19.4008935 },
    { lat: 49.2159563, lng: 19.4009528 },
    { lat: 49.2154914, lng: 19.4016643 },
    { lat: 49.2150663, lng: 19.4021199 },
    { lat: 49.214662, lng: 19.4024636 },
    { lat: 49.2143069, lng: 19.4027739 },
    { lat: 49.2139541, lng: 19.4029916 },
    { lat: 49.2137102, lng: 19.4031251 },
    { lat: 49.2133979, lng: 19.403487 },
    { lat: 49.2131323, lng: 19.4037378 },
    { lat: 49.2129996, lng: 19.4038643 },
    { lat: 49.2128327, lng: 19.4040522 },
    { lat: 49.2127996, lng: 19.4040925 },
    { lat: 49.2125196, lng: 19.4044429 },
    { lat: 49.2120898, lng: 19.4050175 },
    { lat: 49.2119253, lng: 19.4050891 },
    { lat: 49.2118193, lng: 19.4051355 },
    { lat: 49.2116767, lng: 19.4051978 },
    { lat: 49.2108522, lng: 19.4063673 },
    { lat: 49.2108317, lng: 19.4063364 },
    { lat: 49.2097461, lng: 19.4071017 },
    { lat: 49.2091072, lng: 19.4074189 },
    { lat: 49.2074685, lng: 19.4084811 },
    { lat: 49.2071622, lng: 19.4088707 },
    { lat: 49.2065787, lng: 19.4099423 },
    { lat: 49.2058688, lng: 19.4114953 },
    { lat: 49.2054129, lng: 19.4119443 },
    { lat: 49.2041631, lng: 19.4121588 },
    { lat: 49.2029297, lng: 19.4128231 },
    { lat: 49.2024458, lng: 19.4130797 },
    { lat: 49.2020984, lng: 19.4132208 },
    { lat: 49.2021488, lng: 19.413696 },
    { lat: 49.2021847, lng: 19.4140319 },
    { lat: 49.2022108, lng: 19.4142785 },
    { lat: 49.2023024, lng: 19.4147259 },
    { lat: 49.2023707, lng: 19.4153671 },
    { lat: 49.202421, lng: 19.4156791 },
    { lat: 49.2024227, lng: 19.4156898 },
    { lat: 49.2025615, lng: 19.4164456 },
    { lat: 49.202566, lng: 19.4164472 },
    { lat: 49.2028155, lng: 19.4165262 },
    { lat: 49.2028905, lng: 19.416539 },
    { lat: 49.2031763, lng: 19.4166065 },
    { lat: 49.2032053, lng: 19.4166019 },
    { lat: 49.2034136, lng: 19.4165707 },
    { lat: 49.2034898, lng: 19.4165593 },
    { lat: 49.2038882, lng: 19.4166733 },
    { lat: 49.2039544, lng: 19.4169332 },
    { lat: 49.2040445, lng: 19.4172474 },
    { lat: 49.2040523, lng: 19.4172732 },
    { lat: 49.2040566, lng: 19.4172893 },
    { lat: 49.2040635, lng: 19.4173156 },
    { lat: 49.2040614, lng: 19.417322 },
    { lat: 49.2039791, lng: 19.4176137 },
    { lat: 49.2039933, lng: 19.4177207 },
    { lat: 49.2040025, lng: 19.4177766 },
    { lat: 49.2039915, lng: 19.4178286 },
    { lat: 49.2040264, lng: 19.4180007 },
    { lat: 49.2041943, lng: 19.4180474 },
    { lat: 49.2041949, lng: 19.4180596 },
    { lat: 49.2041302, lng: 19.4181001 },
    { lat: 49.2041062, lng: 19.4182865 },
    { lat: 49.2041857, lng: 19.4185439 },
    { lat: 49.2041297, lng: 19.4187218 },
    { lat: 49.2040918, lng: 19.4188978 },
    { lat: 49.2040527, lng: 19.4189557 },
    { lat: 49.2040376, lng: 19.419113 },
    { lat: 49.2040981, lng: 19.4192762 },
    { lat: 49.204163, lng: 19.4195806 },
    { lat: 49.2040454, lng: 19.4196466 },
    { lat: 49.2038459, lng: 19.4198866 },
    { lat: 49.2038253, lng: 19.4200027 },
    { lat: 49.2037479, lng: 19.4201573 },
    { lat: 49.2037927, lng: 19.4203428 },
    { lat: 49.2038689, lng: 19.420506 },
    { lat: 49.20386, lng: 19.4206043 },
    { lat: 49.2036875, lng: 19.4207729 },
    { lat: 49.2036976, lng: 19.4207984 },
    { lat: 49.2036991, lng: 19.420804 },
    { lat: 49.2037044, lng: 19.4208233 },
    { lat: 49.203915, lng: 19.4209901 },
    { lat: 49.2039789, lng: 19.4210077 },
    { lat: 49.2040258, lng: 19.4210206 },
    { lat: 49.2040269, lng: 19.4210209 },
    { lat: 49.2040327, lng: 19.4210221 },
    { lat: 49.2041128, lng: 19.4210739 },
    { lat: 49.2042131, lng: 19.4212202 },
    { lat: 49.2044339, lng: 19.4213438 },
    { lat: 49.2046161, lng: 19.4216019 },
    { lat: 49.2049346, lng: 19.422054 },
    { lat: 49.2050117, lng: 19.4221632 },
    { lat: 49.2053609, lng: 19.4229882 },
    { lat: 49.2057961, lng: 19.4240126 },
    { lat: 49.206481, lng: 19.4247383 },
    { lat: 49.2064219, lng: 19.4252363 },
    { lat: 49.2062941, lng: 19.4258353 },
    { lat: 49.2069097, lng: 19.4263246 },
    { lat: 49.2069389, lng: 19.4263481 },
    { lat: 49.2071979, lng: 19.4262012 },
    { lat: 49.2074692, lng: 19.4260476 },
    { lat: 49.2086456, lng: 19.4254409 },
    { lat: 49.2086571, lng: 19.4254437 },
    { lat: 49.208664, lng: 19.4254458 },
    { lat: 49.2089183, lng: 19.425512 },
    { lat: 49.2103202, lng: 19.4258779 },
    { lat: 49.2104215, lng: 19.4259048 },
    { lat: 49.2110136, lng: 19.4260593 },
    { lat: 49.2124171, lng: 19.4267779 },
    { lat: 49.2128054, lng: 19.4270596 },
    { lat: 49.2130072, lng: 19.4272069 },
    { lat: 49.213189, lng: 19.4273386 },
    { lat: 49.2138264, lng: 19.4279367 },
    { lat: 49.2148474, lng: 19.4288653 },
    { lat: 49.2151536, lng: 19.4296126 },
    { lat: 49.2152171, lng: 19.4297665 },
    { lat: 49.2153003, lng: 19.4301032 },
    { lat: 49.2155197, lng: 19.4309976 },
    { lat: 49.2161186, lng: 19.4322494 },
    { lat: 49.2167151, lng: 19.4332095 },
    { lat: 49.21692, lng: 19.433616 },
    { lat: 49.2172065, lng: 19.4339408 },
    { lat: 49.2174618, lng: 19.4340986 },
    { lat: 49.2175998, lng: 19.4341836 },
    { lat: 49.2181636, lng: 19.434533 },
    { lat: 49.2186029, lng: 19.4350422 },
    { lat: 49.2192218, lng: 19.4358998 },
    { lat: 49.2194515, lng: 19.4362618 },
    { lat: 49.2195476, lng: 19.4364147 },
    { lat: 49.2200142, lng: 19.4371496 },
    { lat: 49.2216274, lng: 19.4385288 },
    { lat: 49.2220917, lng: 19.4386291 },
    { lat: 49.2224904, lng: 19.4383807 },
    { lat: 49.2224823, lng: 19.4383541 },
    { lat: 49.2224718, lng: 19.4383203 },
    { lat: 49.221804, lng: 19.4361121 },
    { lat: 49.2216598, lng: 19.4336497 },
    { lat: 49.2219941, lng: 19.4333014 },
    { lat: 49.2223035, lng: 19.4329761 },
    { lat: 49.2224895, lng: 19.4324182 },
    { lat: 49.2226458, lng: 19.432167 },
    { lat: 49.2227436, lng: 19.4319632 },
    { lat: 49.2228492, lng: 19.4315466 },
    { lat: 49.2228873, lng: 19.4312026 },
    { lat: 49.2230037, lng: 19.4308187 },
    { lat: 49.2230863, lng: 19.4306555 },
    { lat: 49.2232011, lng: 19.4303341 },
    { lat: 49.2232707, lng: 19.4299391 },
    { lat: 49.2235036, lng: 19.4296162 },
    { lat: 49.2237072, lng: 19.4294637 },
    { lat: 49.2241178, lng: 19.4288415 },
    { lat: 49.2243283, lng: 19.4284918 },
    { lat: 49.2243035, lng: 19.4278135 },
    { lat: 49.2245908, lng: 19.4275204 },
    { lat: 49.2248037, lng: 19.4270807 },
    { lat: 49.225067, lng: 19.4269733 },
    { lat: 49.2253128, lng: 19.4265138 },
    { lat: 49.2255726, lng: 19.4261648 },
    { lat: 49.2258513, lng: 19.4259058 },
    { lat: 49.2258794, lng: 19.4253411 },
    { lat: 49.2261339, lng: 19.4253652 },
    { lat: 49.2265109, lng: 19.4247909 },
    { lat: 49.2266732, lng: 19.4245999 },
    { lat: 49.2267452, lng: 19.4245233 },
    { lat: 49.2271637, lng: 19.4244377 },
    { lat: 49.2273869, lng: 19.424246 },
    { lat: 49.2277639, lng: 19.4241119 },
    { lat: 49.2282431, lng: 19.4240478 },
    { lat: 49.2288496, lng: 19.4242426 },
    { lat: 49.2291263, lng: 19.4237729 },
    { lat: 49.229498, lng: 19.4232341 },
    { lat: 49.2296564, lng: 19.4225658 },
    { lat: 49.2299719, lng: 19.422006 },
    { lat: 49.23013, lng: 19.4216266 },
    { lat: 49.2306818, lng: 19.4214227 },
    { lat: 49.2310917, lng: 19.4212944 },
    { lat: 49.2312261, lng: 19.4211335 },
    { lat: 49.2314592, lng: 19.4212518 },
    { lat: 49.2317051, lng: 19.421182 },
    { lat: 49.2319241, lng: 19.4213159 },
    { lat: 49.2319098, lng: 19.4210542 },
    { lat: 49.2320499, lng: 19.4209882 },
    { lat: 49.2321834, lng: 19.4209298 },
    { lat: 49.2323073, lng: 19.4206831 },
    { lat: 49.2324835, lng: 19.4206186 },
    { lat: 49.2325437, lng: 19.4202169 },
    { lat: 49.2327469, lng: 19.4197861 },
    { lat: 49.2329191, lng: 19.4195381 },
    { lat: 49.2330952, lng: 19.4192771 },
    { lat: 49.2333559, lng: 19.4191653 },
    { lat: 49.2334587, lng: 19.4192191 },
    { lat: 49.2334656, lng: 19.4191982 },
    { lat: 49.2335453, lng: 19.4189256 },
    { lat: 49.2335216, lng: 19.4186545 },
    { lat: 49.2335655, lng: 19.4181193 },
    { lat: 49.2331061, lng: 19.4168395 },
    { lat: 49.2328634, lng: 19.416154 },
    { lat: 49.2327299, lng: 19.4152393 },
    { lat: 49.232222, lng: 19.4134495 },
    { lat: 49.231935, lng: 19.4124411 },
    { lat: 49.2317706, lng: 19.4118603 },
    { lat: 49.2314127, lng: 19.4109713 },
    { lat: 49.2313961, lng: 19.4109295 },
    { lat: 49.2311912, lng: 19.4104213 },
    { lat: 49.2315022, lng: 19.4088984 },
    { lat: 49.2317819, lng: 19.4079804 },
    { lat: 49.2329312, lng: 19.4086827 },
    { lat: 49.2331718, lng: 19.4083527 },
    { lat: 49.2335111, lng: 19.4074833 },
    { lat: 49.2337039, lng: 19.4070485 },
    { lat: 49.2340278, lng: 19.4060412 },
    { lat: 49.2340136, lng: 19.4054712 },
    { lat: 49.2341334, lng: 19.4049913 },
    { lat: 49.2343078, lng: 19.4045516 },
    { lat: 49.2344208, lng: 19.4035639 },
    { lat: 49.2343009, lng: 19.4029967 },
    { lat: 49.2341532, lng: 19.4026092 },
    { lat: 49.2340938, lng: 19.4022504 },
    { lat: 49.2339036, lng: 19.4012639 },
    { lat: 49.2339383, lng: 19.4004362 },
    { lat: 49.2340807, lng: 19.4001308 },
    { lat: 49.2341276, lng: 19.4000489 },
    { lat: 49.2343088, lng: 19.3998017 },
    { lat: 49.2343189, lng: 19.3997999 },
    { lat: 49.2344103, lng: 19.4000186 },
    { lat: 49.2345846, lng: 19.4000343 },
    { lat: 49.2348838, lng: 19.3997563 },
    { lat: 49.2351513, lng: 19.3996494 },
    { lat: 49.235284, lng: 19.3994456 },
    { lat: 49.2353837, lng: 19.3992891 },
    { lat: 49.2354992, lng: 19.3994721 },
    { lat: 49.2355949, lng: 19.3995922 },
    { lat: 49.2357574, lng: 19.3996427 },
    { lat: 49.2359014, lng: 19.3996166 },
    { lat: 49.2362221, lng: 19.3995818 },
    { lat: 49.2362768, lng: 19.3994984 },
    { lat: 49.2362109, lng: 19.3992196 },
    { lat: 49.2363494, lng: 19.39907 },
    { lat: 49.2364535, lng: 19.39908 },
    { lat: 49.2365621, lng: 19.3991843 },
    { lat: 49.2366318, lng: 19.3992777 },
    { lat: 49.2366871, lng: 19.3992075 },
    { lat: 49.2367284, lng: 19.3990808 },
    { lat: 49.2368767, lng: 19.3991163 },
    { lat: 49.2369436, lng: 19.3992944 },
    { lat: 49.2369859, lng: 19.3995505 },
    { lat: 49.2370426, lng: 19.3996572 },
    { lat: 49.2371376, lng: 19.3996624 },
    { lat: 49.237293, lng: 19.3996124 },
    { lat: 49.2374127, lng: 19.3994504 },
    { lat: 49.2375239, lng: 19.3994654 },
    { lat: 49.2375581, lng: 19.3995699 },
    { lat: 49.2375744, lng: 19.3996525 },
    { lat: 49.2376478, lng: 19.3998032 },
    { lat: 49.2377553, lng: 19.3998857 },
    { lat: 49.2381359, lng: 19.3999064 },
    { lat: 49.2382963, lng: 19.3999633 },
    { lat: 49.2384267, lng: 19.3999545 },
    { lat: 49.2386041, lng: 19.3999915 },
    { lat: 49.2386521, lng: 19.4001248 },
    { lat: 49.2385775, lng: 19.400337 },
    { lat: 49.2386533, lng: 19.4004637 },
    { lat: 49.2387109, lng: 19.400611 },
    { lat: 49.2388185, lng: 19.4005994 },
    { lat: 49.2388813, lng: 19.4004007 },
    { lat: 49.2389519, lng: 19.4000512 },
    { lat: 49.2390205, lng: 19.3999784 },
    { lat: 49.2390578, lng: 19.4000541 },
    { lat: 49.2391238, lng: 19.3999957 },
    { lat: 49.2392775, lng: 19.4001976 },
    { lat: 49.2396064, lng: 19.4000699 },
    { lat: 49.2397694, lng: 19.400208 },
    { lat: 49.2399018, lng: 19.4000754 },
    { lat: 49.2400639, lng: 19.4001436 },
    { lat: 49.2402587, lng: 19.4004081 },
    { lat: 49.2403728, lng: 19.4005612 },
    { lat: 49.2404053, lng: 19.4004376 },
    { lat: 49.2404004, lng: 19.4001331 },
    { lat: 49.2404999, lng: 19.3998262 },
    { lat: 49.2406003, lng: 19.3998066 },
    { lat: 49.2408366, lng: 19.400063 },
    { lat: 49.2409527, lng: 19.4000666 },
    { lat: 49.2409881, lng: 19.3999048 },
    { lat: 49.2408923, lng: 19.3996877 },
    { lat: 49.2409991, lng: 19.3995109 },
    { lat: 49.2410618, lng: 19.3992408 },
    { lat: 49.2412597, lng: 19.3990841 },
    { lat: 49.2415193, lng: 19.3991165 },
    { lat: 49.2415596, lng: 19.3989416 },
    { lat: 49.2414513, lng: 19.3986752 },
    { lat: 49.2414269, lng: 19.3986061 },
    { lat: 49.2414138, lng: 19.3984225 },
    { lat: 49.2415449, lng: 19.3981436 },
    { lat: 49.2419421, lng: 19.3976219 },
    { lat: 49.2420642, lng: 19.3974341 },
    { lat: 49.2419655, lng: 19.3971559 },
    { lat: 49.2420666, lng: 19.3970501 },
    { lat: 49.24233, lng: 19.3972159 },
    { lat: 49.2424353, lng: 19.397058 },
    { lat: 49.242601, lng: 19.3968381 },
    { lat: 49.242735, lng: 19.396623 },
    { lat: 49.2428406, lng: 19.3966126 },
    { lat: 49.2429447, lng: 19.3965962 },
    { lat: 49.2430137, lng: 19.3964113 },
    { lat: 49.243138, lng: 19.3961277 },
    { lat: 49.2432779, lng: 19.3956436 },
    { lat: 49.2434407, lng: 19.3955347 },
    { lat: 49.2437665, lng: 19.395372 },
    { lat: 49.2438089, lng: 19.3954085 },
    { lat: 49.2438789, lng: 19.3954121 },
    { lat: 49.2439722, lng: 19.3953107 },
    { lat: 49.2440311, lng: 19.3951964 },
    { lat: 49.2440661, lng: 19.3950051 },
    { lat: 49.2441441, lng: 19.3948401 },
    { lat: 49.2442384, lng: 19.3946639 },
    { lat: 49.2443416, lng: 19.3942181 },
    { lat: 49.2443114, lng: 19.3940512 },
    { lat: 49.2442718, lng: 19.3938798 },
    { lat: 49.2442507, lng: 19.3936693 },
    { lat: 49.244338, lng: 19.3928112 },
    { lat: 49.2443883, lng: 19.3925341 },
    { lat: 49.2444635, lng: 19.3921913 },
    { lat: 49.244506, lng: 19.3920146 },
    { lat: 49.2445356, lng: 19.3919699 },
    { lat: 49.2451895, lng: 19.3916417 },
    { lat: 49.2453354, lng: 19.3914837 },
    { lat: 49.2453474, lng: 19.3912303 },
    { lat: 49.2452917, lng: 19.3907649 },
    { lat: 49.2452543, lng: 19.3902954 },
    { lat: 49.2453158, lng: 19.3900439 },
    { lat: 49.2455169, lng: 19.3898142 },
    { lat: 49.2457631, lng: 19.389707 },
    { lat: 49.2460662, lng: 19.3896582 },
    { lat: 49.2463465, lng: 19.3896739 },
    { lat: 49.2467238, lng: 19.389674 },
    { lat: 49.246876, lng: 19.3896004 },
    { lat: 49.2469768, lng: 19.3892955 },
    { lat: 49.2470444, lng: 19.3889325 },
    { lat: 49.2472353, lng: 19.3884591 },
    { lat: 49.2474093, lng: 19.3882508 },
    { lat: 49.247699, lng: 19.3877937 },
    { lat: 49.2478147, lng: 19.3876411 },
    { lat: 49.2479746, lng: 19.3875415 },
    { lat: 49.2480645, lng: 19.3874895 },
    { lat: 49.2482299, lng: 19.3873655 },
    { lat: 49.2486248, lng: 19.3871588 },
    { lat: 49.2487829, lng: 19.3870211 },
    { lat: 49.2489053, lng: 19.3870124 },
    { lat: 49.2492201, lng: 19.3867791 },
    { lat: 49.2492887, lng: 19.386542 },
    { lat: 49.249421, lng: 19.3859381 },
    { lat: 49.2496668, lng: 19.3856491 },
    { lat: 49.2500914, lng: 19.3855033 },
    { lat: 49.2502787, lng: 19.3855294 },
    { lat: 49.2504602, lng: 19.3856547 },
    { lat: 49.2505888, lng: 19.385631 },
    { lat: 49.2507183, lng: 19.3854362 },
    { lat: 49.2508454, lng: 19.3853109 },
    { lat: 49.2510302, lng: 19.3852637 },
    { lat: 49.2512533, lng: 19.3853592 },
    { lat: 49.2516212, lng: 19.3850338 },
    { lat: 49.2519328, lng: 19.3850684 },
    { lat: 49.2520707, lng: 19.3850271 },
    { lat: 49.2522396, lng: 19.3849318 },
    { lat: 49.2525524, lng: 19.3847751 },
    { lat: 49.2527248, lng: 19.3846074 },
    { lat: 49.252864, lng: 19.3844988 },
    { lat: 49.2527522, lng: 19.3843234 },
    { lat: 49.2527072, lng: 19.3842504 },
    { lat: 49.2481566, lng: 19.38007 },
    { lat: 49.247416, lng: 19.3798722 },
    { lat: 49.2457661, lng: 19.3789519 },
    { lat: 49.2445951, lng: 19.3787293 },
    { lat: 49.2441934, lng: 19.3776117 },
    { lat: 49.2432584, lng: 19.3787967 },
    { lat: 49.2431721, lng: 19.3789056 },
    { lat: 49.2427183, lng: 19.3795628 },
    { lat: 49.2424281, lng: 19.380033 },
    { lat: 49.2418965, lng: 19.3808828 },
    { lat: 49.2404305, lng: 19.3850136 },
    { lat: 49.2399087, lng: 19.3862442 },
    { lat: 49.2396823, lng: 19.3867777 },
    { lat: 49.239343, lng: 19.3870873 },
    { lat: 49.2380092, lng: 19.3883042 },
    { lat: 49.2376598, lng: 19.3883771 },
    { lat: 49.2371906, lng: 19.3889943 },
    { lat: 49.2366975, lng: 19.3894389 },
    { lat: 49.2360698, lng: 19.3899965 },
    { lat: 49.2358428, lng: 19.3904694 },
    { lat: 49.2354769, lng: 19.390816 },
  ],
  Pucov: [
    { lat: 49.2122816, lng: 19.3546002 },
    { lat: 49.2121188, lng: 19.3547096 },
    { lat: 49.2119698, lng: 19.3548946 },
    { lat: 49.2119102, lng: 19.3548726 },
    { lat: 49.2118816, lng: 19.3548873 },
    { lat: 49.2117831, lng: 19.3550036 },
    { lat: 49.2116604, lng: 19.3549302 },
    { lat: 49.2115368, lng: 19.3549376 },
    { lat: 49.211494, lng: 19.3549607 },
    { lat: 49.2115087, lng: 19.355083 },
    { lat: 49.211425, lng: 19.3551019 },
    { lat: 49.211338, lng: 19.3550494 },
    { lat: 49.2111893, lng: 19.3552167 },
    { lat: 49.2111068, lng: 19.3553807 },
    { lat: 49.211001, lng: 19.3555253 },
    { lat: 49.2108782, lng: 19.3555766 },
    { lat: 49.2107934, lng: 19.3556414 },
    { lat: 49.2105562, lng: 19.35584 },
    { lat: 49.2103963, lng: 19.3565935 },
    { lat: 49.2104007, lng: 19.3570252 },
    { lat: 49.2104537, lng: 19.3575063 },
    { lat: 49.2106423, lng: 19.3584045 },
    { lat: 49.2106378, lng: 19.3587362 },
    { lat: 49.210573, lng: 19.3590417 },
    { lat: 49.2101262, lng: 19.3598921 },
    { lat: 49.209948, lng: 19.3602011 },
    { lat: 49.209597, lng: 19.3608129 },
    { lat: 49.2092922, lng: 19.3612624 },
    { lat: 49.2089577, lng: 19.3616216 },
    { lat: 49.2087873, lng: 19.3616449 },
    { lat: 49.2086949, lng: 19.3616687 },
    { lat: 49.2085056, lng: 19.3617665 },
    { lat: 49.2083214, lng: 19.3619473 },
    { lat: 49.2082853, lng: 19.3620645 },
    { lat: 49.2082788, lng: 19.3620706 },
    { lat: 49.2080175, lng: 19.3623597 },
    { lat: 49.2078531, lng: 19.3624591 },
    { lat: 49.2077488, lng: 19.3624709 },
    { lat: 49.2077517, lng: 19.3621501 },
    { lat: 49.2075732, lng: 19.3618489 },
    { lat: 49.2073409, lng: 19.3616544 },
    { lat: 49.2071188, lng: 19.3616513 },
    { lat: 49.2069241, lng: 19.3617053 },
    { lat: 49.2066727, lng: 19.3618016 },
    { lat: 49.2064432, lng: 19.3619031 },
    { lat: 49.2064462, lng: 19.3621378 },
    { lat: 49.2066178, lng: 19.3634922 },
    { lat: 49.2067813, lng: 19.3640225 },
    { lat: 49.2069504, lng: 19.3644322 },
    { lat: 49.2066787, lng: 19.3647664 },
    { lat: 49.2059496, lng: 19.3654392 },
    { lat: 49.2054182, lng: 19.3659311 },
    { lat: 49.2052464, lng: 19.3660897 },
    { lat: 49.2051403, lng: 19.3661788 },
    { lat: 49.2050182, lng: 19.3662948 },
    { lat: 49.2047456, lng: 19.3666097 },
    { lat: 49.2046379, lng: 19.3667598 },
    { lat: 49.2044971, lng: 19.3669557 },
    { lat: 49.2044181, lng: 19.3670991 },
    { lat: 49.204324, lng: 19.3673494 },
    { lat: 49.204132, lng: 19.3679174 },
    { lat: 49.2039233, lng: 19.3686844 },
    { lat: 49.2037553, lng: 19.369404 },
    { lat: 49.2037052, lng: 19.3696351 },
    { lat: 49.2036334, lng: 19.3700743 },
    { lat: 49.2036063, lng: 19.3704586 },
    { lat: 49.203572, lng: 19.3708814 },
    { lat: 49.2034985, lng: 19.3713785 },
    { lat: 49.2034051, lng: 19.3719617 },
    { lat: 49.2033322, lng: 19.3724277 },
    { lat: 49.2032829, lng: 19.3726702 },
    { lat: 49.2031764, lng: 19.3730429 },
    { lat: 49.202981, lng: 19.3734959 },
    { lat: 49.2027658, lng: 19.3740228 },
    { lat: 49.202712, lng: 19.3742957 },
    { lat: 49.2026838, lng: 19.3747038 },
    { lat: 49.2026673, lng: 19.3753604 },
    { lat: 49.2026614, lng: 19.3759099 },
    { lat: 49.2026703, lng: 19.3764848 },
    { lat: 49.2026915, lng: 19.3771557 },
    { lat: 49.2026932, lng: 19.3775793 },
    { lat: 49.2026219, lng: 19.3782477 },
    { lat: 49.2025503, lng: 19.3786457 },
    { lat: 49.2023737, lng: 19.3790841 },
    { lat: 49.2022685, lng: 19.3791734 },
    { lat: 49.2021056, lng: 19.3792545 },
    { lat: 49.2019169, lng: 19.3793154 },
    { lat: 49.2017369, lng: 19.3793952 },
    { lat: 49.2014713, lng: 19.3795719 },
    { lat: 49.2012257, lng: 19.3798159 },
    { lat: 49.2013185, lng: 19.3799665 },
    { lat: 49.2014687, lng: 19.3802147 },
    { lat: 49.2017238, lng: 19.3806594 },
    { lat: 49.201901, lng: 19.3810253 },
    { lat: 49.2020736, lng: 19.3814148 },
    { lat: 49.2022148, lng: 19.3817137 },
    { lat: 49.2023574, lng: 19.3820153 },
    { lat: 49.2024672, lng: 19.3821713 },
    { lat: 49.2025836, lng: 19.3822282 },
    { lat: 49.2027429, lng: 19.3823323 },
    { lat: 49.2028366, lng: 19.3824313 },
    { lat: 49.2029339, lng: 19.3824877 },
    { lat: 49.2031062, lng: 19.3827728 },
    { lat: 49.203182, lng: 19.3828993 },
    { lat: 49.2033987, lng: 19.3831709 },
    { lat: 49.2036027, lng: 19.3833436 },
    { lat: 49.2037606, lng: 19.3835881 },
    { lat: 49.2038973, lng: 19.3837649 },
    { lat: 49.2039712, lng: 19.3837796 },
    { lat: 49.2041484, lng: 19.3843128 },
    { lat: 49.2043387, lng: 19.3845753 },
    { lat: 49.2044846, lng: 19.3846355 },
    { lat: 49.2046284, lng: 19.3847709 },
    { lat: 49.2046918, lng: 19.3847798 },
    { lat: 49.2047498, lng: 19.3848388 },
    { lat: 49.204828, lng: 19.3847719 },
    { lat: 49.2050828, lng: 19.3848043 },
    { lat: 49.2052981, lng: 19.3848274 },
    { lat: 49.2054106, lng: 19.3847748 },
    { lat: 49.205567, lng: 19.3848403 },
    { lat: 49.2056797, lng: 19.3848418 },
    { lat: 49.2057733, lng: 19.3849408 },
    { lat: 49.2059037, lng: 19.3849576 },
    { lat: 49.2060238, lng: 19.3850916 },
    { lat: 49.2061216, lng: 19.3851382 },
    { lat: 49.206121, lng: 19.3852208 },
    { lat: 49.2063081, lng: 19.3855108 },
    { lat: 49.2064092, lng: 19.3855978 },
    { lat: 49.2064319, lng: 19.385724 },
    { lat: 49.2065069, lng: 19.3858795 },
    { lat: 49.2065684, lng: 19.3860177 },
    { lat: 49.2065937, lng: 19.3861454 },
    { lat: 49.2066538, lng: 19.3862034 },
    { lat: 49.206656, lng: 19.3863213 },
    { lat: 49.2067525, lng: 19.3866986 },
    { lat: 49.2067833, lng: 19.3867828 },
    { lat: 49.2067782, lng: 19.3868393 },
    { lat: 49.2068046, lng: 19.3869005 },
    { lat: 49.2068377, lng: 19.3871283 },
    { lat: 49.2068346, lng: 19.3872281 },
    { lat: 49.2068001, lng: 19.3873101 },
    { lat: 49.2068278, lng: 19.3874675 },
    { lat: 49.2068631, lng: 19.3875004 },
    { lat: 49.206878, lng: 19.3877496 },
    { lat: 49.2068376, lng: 19.3878953 },
    { lat: 49.2069024, lng: 19.3882457 },
    { lat: 49.2069039, lng: 19.3886431 },
    { lat: 49.2068506, lng: 19.3889484 },
    { lat: 49.2068949, lng: 19.3891223 },
    { lat: 49.206954, lng: 19.3892049 },
    { lat: 49.2069377, lng: 19.3895098 },
    { lat: 49.2068795, lng: 19.3897338 },
    { lat: 49.2069241, lng: 19.3898457 },
    { lat: 49.2070327, lng: 19.3899305 },
    { lat: 49.2070692, lng: 19.3900105 },
    { lat: 49.2071302, lng: 19.3900878 },
    { lat: 49.2072008, lng: 19.3901025 },
    { lat: 49.2072367, lng: 19.3901469 },
    { lat: 49.207346, lng: 19.390339 },
    { lat: 49.2073892, lng: 19.3903707 },
    { lat: 49.2074388, lng: 19.3904702 },
    { lat: 49.2074697, lng: 19.3905523 },
    { lat: 49.207287, lng: 19.3904269 },
    { lat: 49.2070783, lng: 19.3905696 },
    { lat: 49.2069698, lng: 19.3905111 },
    { lat: 49.2068556, lng: 19.3905033 },
    { lat: 49.2068596, lng: 19.3906391 },
    { lat: 49.2068758, lng: 19.3912298 },
    { lat: 49.2069053, lng: 19.3914256 },
    { lat: 49.2069138, lng: 19.3914863 },
    { lat: 49.2069284, lng: 19.3915816 },
    { lat: 49.2069446, lng: 19.3916869 },
    { lat: 49.2069845, lng: 19.3920368 },
    { lat: 49.2070047, lng: 19.3925904 },
    { lat: 49.2069507, lng: 19.3931235 },
    { lat: 49.2069046, lng: 19.3935387 },
    { lat: 49.2068468, lng: 19.3940877 },
    { lat: 49.2068515, lng: 19.3943546 },
    { lat: 49.2068473, lng: 19.3946999 },
    { lat: 49.2068453, lng: 19.3952148 },
    { lat: 49.2064399, lng: 19.395656 },
    { lat: 49.2058366, lng: 19.3963208 },
    { lat: 49.2055411, lng: 19.3966776 },
    { lat: 49.2054124, lng: 19.3969157 },
    { lat: 49.2051628, lng: 19.3976758 },
    { lat: 49.2050003, lng: 19.3981324 },
    { lat: 49.2052873, lng: 19.3988988 },
    { lat: 49.2049784, lng: 19.3992584 },
    { lat: 49.205093, lng: 19.3998071 },
    { lat: 49.2051897, lng: 19.4002629 },
    { lat: 49.2053118, lng: 19.4009272 },
    { lat: 49.2054969, lng: 19.4015854 },
    { lat: 49.2057939, lng: 19.4026387 },
    { lat: 49.2059079, lng: 19.403008 },
    { lat: 49.2060356, lng: 19.403428 },
    { lat: 49.2062071, lng: 19.4040131 },
    { lat: 49.2063624, lng: 19.404322 },
    { lat: 49.2063884, lng: 19.4046627 },
    { lat: 49.2063463, lng: 19.4048485 },
    { lat: 49.2063435, lng: 19.4050773 },
    { lat: 49.2064141, lng: 19.4053118 },
    { lat: 49.2063351, lng: 19.4053582 },
    { lat: 49.2062971, lng: 19.4054409 },
    { lat: 49.2061203, lng: 19.4057307 },
    { lat: 49.2060663, lng: 19.4058299 },
    { lat: 49.2060049, lng: 19.4059667 },
    { lat: 49.2059255, lng: 19.4060012 },
    { lat: 49.2058037, lng: 19.4062649 },
    { lat: 49.2057385, lng: 19.4064882 },
    { lat: 49.2057592, lng: 19.4065227 },
    { lat: 49.2057541, lng: 19.4065809 },
    { lat: 49.2056532, lng: 19.4068326 },
    { lat: 49.2057022, lng: 19.4070172 },
    { lat: 49.2057176, lng: 19.4073233 },
    { lat: 49.2056318, lng: 19.407419 },
    { lat: 49.2054614, lng: 19.4078984 },
    { lat: 49.2054622, lng: 19.4081787 },
    { lat: 49.2053776, lng: 19.4083951 },
    { lat: 49.2054238, lng: 19.4085162 },
    { lat: 49.2054754, lng: 19.4085135 },
    { lat: 49.2055385, lng: 19.4086566 },
    { lat: 49.2054689, lng: 19.4087333 },
    { lat: 49.2051983, lng: 19.4087792 },
    { lat: 49.205155, lng: 19.4088895 },
    { lat: 49.2051705, lng: 19.4090298 },
    { lat: 49.2050682, lng: 19.4090603 },
    { lat: 49.2050262, lng: 19.4089597 },
    { lat: 49.2050012, lng: 19.4089544 },
    { lat: 49.2049236, lng: 19.4090519 },
    { lat: 49.2048216, lng: 19.4091137 },
    { lat: 49.2046554, lng: 19.4094914 },
    { lat: 49.2045838, lng: 19.4095258 },
    { lat: 49.2043933, lng: 19.4094505 },
    { lat: 49.2043354, lng: 19.4094644 },
    { lat: 49.2042282, lng: 19.4095618 },
    { lat: 49.2037315, lng: 19.4093002 },
    { lat: 49.2037197, lng: 19.4093105 },
    { lat: 49.2036896, lng: 19.4093433 },
    { lat: 49.2036773, lng: 19.4095151 },
    { lat: 49.203652, lng: 19.4095492 },
    { lat: 49.2034678, lng: 19.4096102 },
    { lat: 49.2032962, lng: 19.4095315 },
    { lat: 49.2031323, lng: 19.4094494 },
    { lat: 49.2029267, lng: 19.409196 },
    { lat: 49.2028577, lng: 19.4089465 },
    { lat: 49.2026765, lng: 19.4089752 },
    { lat: 49.2025371, lng: 19.4087398 },
    { lat: 49.2023748, lng: 19.4087631 },
    { lat: 49.2016721, lng: 19.4083621 },
    { lat: 49.2014193, lng: 19.4084778 },
    { lat: 49.2010833, lng: 19.4090514 },
    { lat: 49.2007067, lng: 19.4094576 },
    { lat: 49.2005565, lng: 19.4098883 },
    { lat: 49.2004437, lng: 19.4101051 },
    { lat: 49.2002808, lng: 19.4103091 },
    { lat: 49.2002104, lng: 19.4103935 },
    { lat: 49.2000068, lng: 19.4104998 },
    { lat: 49.1998347, lng: 19.4107242 },
    { lat: 49.1994857, lng: 19.4111904 },
    { lat: 49.1994153, lng: 19.4116874 },
    { lat: 49.199423, lng: 19.412027 },
    { lat: 49.1994193, lng: 19.4124051 },
    { lat: 49.1994312, lng: 19.4128787 },
    { lat: 49.1995379, lng: 19.4134329 },
    { lat: 49.1996737, lng: 19.4139767 },
    { lat: 49.2001779, lng: 19.4132105 },
    { lat: 49.2002852, lng: 19.4131675 },
    { lat: 49.2005119, lng: 19.413076 },
    { lat: 49.2006423, lng: 19.413046 },
    { lat: 49.2008498, lng: 19.4129984 },
    { lat: 49.2011404, lng: 19.412972 },
    { lat: 49.2013301, lng: 19.4130044 },
    { lat: 49.2015428, lng: 19.4130502 },
    { lat: 49.2016991, lng: 19.4131158 },
    { lat: 49.2020391, lng: 19.4132055 },
    { lat: 49.2020984, lng: 19.4132208 },
    { lat: 49.2024458, lng: 19.4130797 },
    { lat: 49.2029297, lng: 19.4128231 },
    { lat: 49.2041631, lng: 19.4121588 },
    { lat: 49.2054129, lng: 19.4119443 },
    { lat: 49.2058688, lng: 19.4114953 },
    { lat: 49.2065787, lng: 19.4099423 },
    { lat: 49.2071622, lng: 19.4088707 },
    { lat: 49.2074685, lng: 19.4084811 },
    { lat: 49.2091072, lng: 19.4074189 },
    { lat: 49.2097461, lng: 19.4071017 },
    { lat: 49.2108317, lng: 19.4063364 },
    { lat: 49.2108522, lng: 19.4063673 },
    { lat: 49.2116767, lng: 19.4051978 },
    { lat: 49.2118193, lng: 19.4051355 },
    { lat: 49.2119253, lng: 19.4050891 },
    { lat: 49.2120898, lng: 19.4050175 },
    { lat: 49.2125196, lng: 19.4044429 },
    { lat: 49.2127996, lng: 19.4040925 },
    { lat: 49.2128327, lng: 19.4040522 },
    { lat: 49.2129996, lng: 19.4038643 },
    { lat: 49.2131323, lng: 19.4037378 },
    { lat: 49.2133979, lng: 19.403487 },
    { lat: 49.2137102, lng: 19.4031251 },
    { lat: 49.2139541, lng: 19.4029916 },
    { lat: 49.2143069, lng: 19.4027739 },
    { lat: 49.214662, lng: 19.4024636 },
    { lat: 49.2150663, lng: 19.4021199 },
    { lat: 49.2154914, lng: 19.4016643 },
    { lat: 49.2159563, lng: 19.4009528 },
    { lat: 49.2159412, lng: 19.4008935 },
    { lat: 49.2159274, lng: 19.4008391 },
    { lat: 49.215912, lng: 19.4007766 },
    { lat: 49.2160205, lng: 19.4006639 },
    { lat: 49.216112, lng: 19.400569 },
    { lat: 49.2162043, lng: 19.4004724 },
    { lat: 49.2161884, lng: 19.4004204 },
    { lat: 49.2161725, lng: 19.4003674 },
    { lat: 49.2160834, lng: 19.4000757 },
    { lat: 49.2162337, lng: 19.3999181 },
    { lat: 49.2163898, lng: 19.3998064 },
    { lat: 49.216739, lng: 19.3996369 },
    { lat: 49.2167941, lng: 19.3996089 },
    { lat: 49.2168359, lng: 19.3995878 },
    { lat: 49.2169051, lng: 19.3995479 },
    { lat: 49.2172552, lng: 19.3993751 },
    { lat: 49.2175124, lng: 19.3992084 },
    { lat: 49.2175211, lng: 19.3992027 },
    { lat: 49.2177642, lng: 19.3990462 },
    { lat: 49.2180578, lng: 19.3987696 },
    { lat: 49.2185003, lng: 19.3984001 },
    { lat: 49.2185739, lng: 19.3983376 },
    { lat: 49.2189038, lng: 19.3980628 },
    { lat: 49.2192256, lng: 19.3976671 },
    { lat: 49.2193027, lng: 19.3974828 },
    { lat: 49.2197459, lng: 19.3965237 },
    { lat: 49.2199169, lng: 19.3961548 },
    { lat: 49.2199626, lng: 19.3961213 },
    { lat: 49.2200845, lng: 19.3960255 },
    { lat: 49.2202605, lng: 19.3958864 },
    { lat: 49.2212868, lng: 19.3954674 },
    { lat: 49.2215183, lng: 19.3953122 },
    { lat: 49.2216419, lng: 19.3952072 },
    { lat: 49.2217068, lng: 19.3951259 },
    { lat: 49.2218396, lng: 19.3949507 },
    { lat: 49.2224017, lng: 19.3939083 },
    { lat: 49.222538, lng: 19.3936869 },
    { lat: 49.2229838, lng: 19.3931688 },
    { lat: 49.2231611, lng: 19.3929865 },
    { lat: 49.2234676, lng: 19.3926209 },
    { lat: 49.2234878, lng: 19.3925974 },
    { lat: 49.2235339, lng: 19.392543 },
    { lat: 49.2237751, lng: 19.3922579 },
    { lat: 49.2239897, lng: 19.3918319 },
    { lat: 49.2242651, lng: 19.3912147 },
    { lat: 49.2243927, lng: 19.3907358 },
    { lat: 49.2244502, lng: 19.3905193 },
    { lat: 49.2247496, lng: 19.3897845 },
    { lat: 49.2249561, lng: 19.3894076 },
    { lat: 49.2251324, lng: 19.3896333 },
    { lat: 49.2255108, lng: 19.3900154 },
    { lat: 49.225837, lng: 19.3904137 },
    { lat: 49.226044, lng: 19.3901629 },
    { lat: 49.2262027, lng: 19.3900426 },
    { lat: 49.2264143, lng: 19.3903015 },
    { lat: 49.2265793, lng: 19.3904808 },
    { lat: 49.2265643, lng: 19.3905196 },
    { lat: 49.2267145, lng: 19.3906265 },
    { lat: 49.2269391, lng: 19.3907272 },
    { lat: 49.2273904, lng: 19.3908603 },
    { lat: 49.228201, lng: 19.3911338 },
    { lat: 49.2288593, lng: 19.3913103 },
    { lat: 49.2292754, lng: 19.391361 },
    { lat: 49.2304627, lng: 19.3916711 },
    { lat: 49.230847, lng: 19.3918645 },
    { lat: 49.2311391, lng: 19.3920123 },
    { lat: 49.2312399, lng: 19.3920972 },
    { lat: 49.2313396, lng: 19.3921801 },
    { lat: 49.2317103, lng: 19.3924925 },
    { lat: 49.2318541, lng: 19.3926554 },
    { lat: 49.231891, lng: 19.3926979 },
    { lat: 49.2319102, lng: 19.3927199 },
    { lat: 49.2322158, lng: 19.3921924 },
    { lat: 49.2325004, lng: 19.3919401 },
    { lat: 49.232698, lng: 19.391682 },
    { lat: 49.232904, lng: 19.3914347 },
    { lat: 49.2331608, lng: 19.3907836 },
    { lat: 49.2337256, lng: 19.3912945 },
    { lat: 49.2341647, lng: 19.3914105 },
    { lat: 49.2344898, lng: 19.3912958 },
    { lat: 49.2347967, lng: 19.3910869 },
    { lat: 49.2350707, lng: 19.3908768 },
    { lat: 49.2352609, lng: 19.390868 },
    { lat: 49.2354769, lng: 19.390816 },
    { lat: 49.2354821, lng: 19.3904159 },
    { lat: 49.2355234, lng: 19.3899758 },
    { lat: 49.2356045, lng: 19.3892025 },
    { lat: 49.2356539, lng: 19.3885199 },
    { lat: 49.2356965, lng: 19.388031 },
    { lat: 49.2357222, lng: 19.3873968 },
    { lat: 49.2356924, lng: 19.386642 },
    { lat: 49.2355773, lng: 19.3857649 },
    { lat: 49.2354309, lng: 19.3853088 },
    { lat: 49.235418, lng: 19.3852046 },
    { lat: 49.2355618, lng: 19.3849769 },
    { lat: 49.2356571, lng: 19.3847718 },
    { lat: 49.2355891, lng: 19.3841607 },
    { lat: 49.2354357, lng: 19.3832626 },
    { lat: 49.2354125, lng: 19.3828403 },
    { lat: 49.2354034, lng: 19.382741 },
    { lat: 49.2352404, lng: 19.3821941 },
    { lat: 49.2350832, lng: 19.3819177 },
    { lat: 49.2349902, lng: 19.381666 },
    { lat: 49.2349214, lng: 19.3816378 },
    { lat: 49.2348453, lng: 19.3812873 },
    { lat: 49.2348035, lng: 19.3807311 },
    { lat: 49.2349195, lng: 19.3801963 },
    { lat: 49.2348661, lng: 19.3798271 },
    { lat: 49.2350058, lng: 19.3794862 },
    { lat: 49.2352484, lng: 19.3791544 },
    { lat: 49.2353348, lng: 19.3788615 },
    { lat: 49.235463, lng: 19.3784451 },
    { lat: 49.2353967, lng: 19.3780131 },
    { lat: 49.2353696, lng: 19.3778412 },
    { lat: 49.2352375, lng: 19.3773803 },
    { lat: 49.2351845, lng: 19.3773323 },
    { lat: 49.2351081, lng: 19.3772649 },
    { lat: 49.2349988, lng: 19.3771676 },
    { lat: 49.2348897, lng: 19.3769757 },
    { lat: 49.2348243, lng: 19.3763511 },
    { lat: 49.234781, lng: 19.3761008 },
    { lat: 49.2343836, lng: 19.3755755 },
    { lat: 49.2341869, lng: 19.3748444 },
    { lat: 49.2341759, lng: 19.3747994 },
    { lat: 49.2338362, lng: 19.3744286 },
    { lat: 49.2335586, lng: 19.3739858 },
    { lat: 49.2335475, lng: 19.373723 },
    { lat: 49.2336334, lng: 19.373343 },
    { lat: 49.2337275, lng: 19.3726551 },
    { lat: 49.2337325, lng: 19.3725698 },
    { lat: 49.2337169, lng: 19.3721147 },
    { lat: 49.2337313, lng: 19.3719692 },
    { lat: 49.2337153, lng: 19.3717932 },
    { lat: 49.2336642, lng: 19.3712809 },
    { lat: 49.2338279, lng: 19.3711866 },
    { lat: 49.2338818, lng: 19.3708706 },
    { lat: 49.2339702, lng: 19.3703259 },
    { lat: 49.2340295, lng: 19.3697161 },
    { lat: 49.234068, lng: 19.3694083 },
    { lat: 49.2340526, lng: 19.369323 },
    { lat: 49.2339635, lng: 19.3692455 },
    { lat: 49.2340004, lng: 19.3686148 },
    { lat: 49.2342299, lng: 19.368126 },
    { lat: 49.234568, lng: 19.3671609 },
    { lat: 49.2347336, lng: 19.3673029 },
    { lat: 49.2347722, lng: 19.3669532 },
    { lat: 49.2348489, lng: 19.3667108 },
    { lat: 49.2349187, lng: 19.366542 },
    { lat: 49.2350502, lng: 19.3662939 },
    { lat: 49.2353887, lng: 19.3660395 },
    { lat: 49.235514, lng: 19.3653919 },
    { lat: 49.2354115, lng: 19.3650785 },
    { lat: 49.2353701, lng: 19.3650427 },
    { lat: 49.2352936, lng: 19.3648963 },
    { lat: 49.2352023, lng: 19.3645597 },
    { lat: 49.2353424, lng: 19.3642091 },
    { lat: 49.2353103, lng: 19.3641235 },
    { lat: 49.2352077, lng: 19.3639988 },
    { lat: 49.2352085, lng: 19.3638497 },
    { lat: 49.2352898, lng: 19.3636858 },
    { lat: 49.2351513, lng: 19.3633511 },
    { lat: 49.2350874, lng: 19.3632867 },
    { lat: 49.2350537, lng: 19.3630264 },
    { lat: 49.234977, lng: 19.3628761 },
    { lat: 49.2349612, lng: 19.3626846 },
    { lat: 49.2348325, lng: 19.3622717 },
    { lat: 49.2347838, lng: 19.362074 },
    { lat: 49.2347708, lng: 19.3618666 },
    { lat: 49.2346509, lng: 19.3613543 },
    { lat: 49.2346394, lng: 19.3613015 },
    { lat: 49.2346227, lng: 19.3612322 },
    { lat: 49.2345374, lng: 19.3608575 },
    { lat: 49.234522, lng: 19.3607907 },
    { lat: 49.2344086, lng: 19.3604883 },
    { lat: 49.234408, lng: 19.360188 },
    { lat: 49.2343359, lng: 19.3600696 },
    { lat: 49.2339096, lng: 19.3594148 },
    { lat: 49.2334458, lng: 19.3591107 },
    { lat: 49.2331195, lng: 19.3591226 },
    { lat: 49.2328152, lng: 19.3589987 },
    { lat: 49.2322781, lng: 19.3586928 },
    { lat: 49.2317881, lng: 19.3584363 },
    { lat: 49.2313199, lng: 19.358137 },
    { lat: 49.2313074, lng: 19.3581326 },
    { lat: 49.2312392, lng: 19.3581217 },
    { lat: 49.2311167, lng: 19.358103 },
    { lat: 49.2310587, lng: 19.3580949 },
    { lat: 49.2304065, lng: 19.357956 },
    { lat: 49.2302415, lng: 19.3579203 },
    { lat: 49.2299614, lng: 19.3578842 },
    { lat: 49.2297594, lng: 19.3578526 },
    { lat: 49.2297263, lng: 19.3578476 },
    { lat: 49.2295027, lng: 19.3578122 },
    { lat: 49.2294198, lng: 19.3577974 },
    { lat: 49.2291261, lng: 19.3578093 },
    { lat: 49.2289158, lng: 19.3578414 },
    { lat: 49.228521, lng: 19.3579022 },
    { lat: 49.2283451, lng: 19.3579027 },
    { lat: 49.228111, lng: 19.3579806 },
    { lat: 49.2278995, lng: 19.3580592 },
    { lat: 49.2275903, lng: 19.3581713 },
    { lat: 49.2273497, lng: 19.3582542 },
    { lat: 49.2271103, lng: 19.3583391 },
    { lat: 49.2269806, lng: 19.358384 },
    { lat: 49.2267579, lng: 19.3584638 },
    { lat: 49.2264323, lng: 19.3585856 },
    { lat: 49.226251, lng: 19.3586628 },
    { lat: 49.2260465, lng: 19.3587489 },
    { lat: 49.2257959, lng: 19.3588571 },
    { lat: 49.2257619, lng: 19.3588327 },
    { lat: 49.2257134, lng: 19.3588564 },
    { lat: 49.2257046, lng: 19.3588602 },
    { lat: 49.2256961, lng: 19.3587988 },
    { lat: 49.2256502, lng: 19.3586614 },
    { lat: 49.2255857, lng: 19.3586771 },
    { lat: 49.2255675, lng: 19.3585305 },
    { lat: 49.2254563, lng: 19.3585402 },
    { lat: 49.2253663, lng: 19.3585652 },
    { lat: 49.2252579, lng: 19.3584898 },
    { lat: 49.2252184, lng: 19.3584364 },
    { lat: 49.2252156, lng: 19.3583577 },
    { lat: 49.2251931, lng: 19.358331 },
    { lat: 49.2250921, lng: 19.358293 },
    { lat: 49.225047, lng: 19.3583434 },
    { lat: 49.2250111, lng: 19.3583217 },
    { lat: 49.2249627, lng: 19.3583227 },
    { lat: 49.2249455, lng: 19.3582676 },
    { lat: 49.2248965, lng: 19.358256 },
    { lat: 49.22488, lng: 19.3582152 },
    { lat: 49.2248448, lng: 19.3581848 },
    { lat: 49.2247966, lng: 19.3580946 },
    { lat: 49.2247546, lng: 19.357995 },
    { lat: 49.2247062, lng: 19.3579945 },
    { lat: 49.2246563, lng: 19.3579628 },
    { lat: 49.2246291, lng: 19.3577711 },
    { lat: 49.2245492, lng: 19.3576983 },
    { lat: 49.22449, lng: 19.3577607 },
    { lat: 49.2244236, lng: 19.3578556 },
    { lat: 49.2243404, lng: 19.3578117 },
    { lat: 49.2242966, lng: 19.3578399 },
    { lat: 49.2242467, lng: 19.3577826 },
    { lat: 49.2242109, lng: 19.3577209 },
    { lat: 49.2242061, lng: 19.3576389 },
    { lat: 49.2241851, lng: 19.3576224 },
    { lat: 49.2241694, lng: 19.3575762 },
    { lat: 49.2240693, lng: 19.3575551 },
    { lat: 49.2239752, lng: 19.3574723 },
    { lat: 49.2239779, lng: 19.3574605 },
    { lat: 49.2239855, lng: 19.3574308 },
    { lat: 49.2239773, lng: 19.357422 },
    { lat: 49.2239182, lng: 19.3574115 },
    { lat: 49.2238362, lng: 19.3572737 },
    { lat: 49.2237878, lng: 19.3572736 },
    { lat: 49.2237474, lng: 19.3573029 },
    { lat: 49.2237327, lng: 19.3573975 },
    { lat: 49.2237156, lng: 19.3574149 },
    { lat: 49.2235901, lng: 19.3573828 },
    { lat: 49.2235345, lng: 19.3573265 },
    { lat: 49.2234972, lng: 19.3572765 },
    { lat: 49.2233773, lng: 19.3572922 },
    { lat: 49.2232805, lng: 19.3572209 },
    { lat: 49.223232, lng: 19.3571491 },
    { lat: 49.2231603, lng: 19.3571371 },
    { lat: 49.2230888, lng: 19.3570768 },
    { lat: 49.2230332, lng: 19.3570706 },
    { lat: 49.2229504, lng: 19.3569607 },
    { lat: 49.2228928, lng: 19.3568858 },
    { lat: 49.2228169, lng: 19.3568541 },
    { lat: 49.2227605, lng: 19.3568744 },
    { lat: 49.2226935, lng: 19.3568651 },
    { lat: 49.2226584, lng: 19.3568376 },
    { lat: 49.2225757, lng: 19.3568055 },
    { lat: 49.2225381, lng: 19.3568446 },
    { lat: 49.2224606, lng: 19.3568484 },
    { lat: 49.2223747, lng: 19.3567493 },
    { lat: 49.2221343, lng: 19.356689 },
    { lat: 49.2220497, lng: 19.3566435 },
    { lat: 49.2219786, lng: 19.3566372 },
    { lat: 49.2219352, lng: 19.3566286 },
    { lat: 49.2218759, lng: 19.3566135 },
    { lat: 49.2218397, lng: 19.3566318 },
    { lat: 49.2216721, lng: 19.3566166 },
    { lat: 49.2215405, lng: 19.3565506 },
    { lat: 49.2214593, lng: 19.3565015 },
    { lat: 49.2214171, lng: 19.3564668 },
    { lat: 49.2213525, lng: 19.3564093 },
    { lat: 49.2213057, lng: 19.356347 },
    { lat: 49.2212566, lng: 19.3562875 },
    { lat: 49.2212158, lng: 19.3562073 },
    { lat: 49.2211474, lng: 19.35624 },
    { lat: 49.2211273, lng: 19.356244 },
    { lat: 49.2210556, lng: 19.356232 },
    { lat: 49.2208372, lng: 19.3559451 },
    { lat: 49.2207003, lng: 19.3557203 },
    { lat: 49.2205159, lng: 19.3555083 },
    { lat: 49.2203662, lng: 19.355444 },
    { lat: 49.2202342, lng: 19.3555103 },
    { lat: 49.2200614, lng: 19.3555065 },
    { lat: 49.2199276, lng: 19.355559 },
    { lat: 49.219824, lng: 19.3556093 },
    { lat: 49.219814, lng: 19.355614 },
    { lat: 49.2196659, lng: 19.3556485 },
    { lat: 49.2195618, lng: 19.3556895 },
    { lat: 49.2193979, lng: 19.3557489 },
    { lat: 49.2193836, lng: 19.3557562 },
    { lat: 49.2193301, lng: 19.3557675 },
    { lat: 49.2193058, lng: 19.3557799 },
    { lat: 49.2192585, lng: 19.3557573 },
    { lat: 49.2192333, lng: 19.3557233 },
    { lat: 49.219108, lng: 19.355529 },
    { lat: 49.2189603, lng: 19.3553559 },
    { lat: 49.2188307, lng: 19.3550943 },
    { lat: 49.2187518, lng: 19.3549241 },
    { lat: 49.2186313, lng: 19.3548569 },
    { lat: 49.2185418, lng: 19.354867 },
    { lat: 49.2184763, lng: 19.3549142 },
    { lat: 49.2183655, lng: 19.3550247 },
    { lat: 49.2181659, lng: 19.3551688 },
    { lat: 49.2176759, lng: 19.3559394 },
    { lat: 49.2173706, lng: 19.3561573 },
    { lat: 49.2170505, lng: 19.3560586 },
    { lat: 49.2168689, lng: 19.3560078 },
    { lat: 49.2166679, lng: 19.3559765 },
    { lat: 49.2163753, lng: 19.3561768 },
    { lat: 49.2163244, lng: 19.3561703 },
    { lat: 49.2162068, lng: 19.3561639 },
    { lat: 49.215837, lng: 19.355873 },
    { lat: 49.2156887, lng: 19.3555973 },
    { lat: 49.2155645, lng: 19.3556365 },
    { lat: 49.215403, lng: 19.3553409 },
    { lat: 49.2152699, lng: 19.3553363 },
    { lat: 49.2150426, lng: 19.3547466 },
    { lat: 49.2148691, lng: 19.3546981 },
    { lat: 49.2148346, lng: 19.3544907 },
    { lat: 49.2146758, lng: 19.3545427 },
    { lat: 49.2145558, lng: 19.3544112 },
    { lat: 49.2143841, lng: 19.3543767 },
    { lat: 49.2143612, lng: 19.3543965 },
    { lat: 49.2143014, lng: 19.3545108 },
    { lat: 49.2141362, lng: 19.3545685 },
    { lat: 49.2140335, lng: 19.3545441 },
    { lat: 49.2138459, lng: 19.3545808 },
    { lat: 49.213767, lng: 19.3547026 },
    { lat: 49.2136446, lng: 19.3547546 },
    { lat: 49.2134856, lng: 19.3545384 },
    { lat: 49.2132083, lng: 19.3544648 },
    { lat: 49.2130915, lng: 19.3544474 },
    { lat: 49.2128179, lng: 19.3544301 },
    { lat: 49.2127186, lng: 19.3544763 },
    { lat: 49.2126506, lng: 19.3545624 },
    { lat: 49.2124954, lng: 19.3546165 },
    { lat: 49.2123989, lng: 19.3546739 },
    { lat: 49.2123572, lng: 19.3546957 },
    { lat: 49.2122954, lng: 19.3546538 },
    { lat: 49.2122816, lng: 19.3546002 },
  ],
  Párnica: [
    { lat: 49.1930166, lng: 19.2189933 },
    { lat: 49.1931805, lng: 19.2190027 },
    { lat: 49.1932288, lng: 19.2190204 },
    { lat: 49.1932951, lng: 19.2190432 },
    { lat: 49.1933156, lng: 19.2190488 },
    { lat: 49.1933433, lng: 19.2190594 },
    { lat: 49.1934764, lng: 19.2191112 },
    { lat: 49.1937053, lng: 19.2192041 },
    { lat: 49.1938446, lng: 19.2192425 },
    { lat: 49.1938733, lng: 19.2192501 },
    { lat: 49.1939647, lng: 19.2192305 },
    { lat: 49.1940315, lng: 19.2192158 },
    { lat: 49.1940895, lng: 19.2192028 },
    { lat: 49.1941332, lng: 19.2191932 },
    { lat: 49.1941476, lng: 19.2191902 },
    { lat: 49.194287, lng: 19.2191609 },
    { lat: 49.1943263, lng: 19.219133 },
    { lat: 49.1944713, lng: 19.2190299 },
    { lat: 49.1946236, lng: 19.2189905 },
    { lat: 49.1946785, lng: 19.2189577 },
    { lat: 49.1947978, lng: 19.2188849 },
    { lat: 49.1948466, lng: 19.218843 },
    { lat: 49.195019, lng: 19.2187008 },
    { lat: 49.1951668, lng: 19.2185678 },
    { lat: 49.1952155, lng: 19.2185249 },
    { lat: 49.1953012, lng: 19.2184547 },
    { lat: 49.1954751, lng: 19.2183199 },
    { lat: 49.1955142, lng: 19.2182881 },
    { lat: 49.1959024, lng: 19.2179289 },
    { lat: 49.1960122, lng: 19.2178442 },
    { lat: 49.1961136, lng: 19.2176995 },
    { lat: 49.1961441, lng: 19.2176564 },
    { lat: 49.1962211, lng: 19.2175224 },
    { lat: 49.1963346, lng: 19.2173309 },
    { lat: 49.1963585, lng: 19.2172868 },
    { lat: 49.1965249, lng: 19.2169762 },
    { lat: 49.196751, lng: 19.2168683 },
    { lat: 49.1967513, lng: 19.2167382 },
    { lat: 49.1968371, lng: 19.2166212 },
    { lat: 49.1969732, lng: 19.2164329 },
    { lat: 49.1969829, lng: 19.216349 },
    { lat: 49.1970171, lng: 19.21629 },
    { lat: 49.1970481, lng: 19.2162321 },
    { lat: 49.197071, lng: 19.2161924 },
    { lat: 49.1970763, lng: 19.2161839 },
    { lat: 49.197116, lng: 19.2161153 },
    { lat: 49.1970951, lng: 19.2160838 },
    { lat: 49.1972043, lng: 19.2157767 },
    { lat: 49.1972525, lng: 19.2156751 },
    { lat: 49.1972533, lng: 19.2156725 },
    { lat: 49.197333, lng: 19.2154977 },
    { lat: 49.1973874, lng: 19.2152725 },
    { lat: 49.1974377, lng: 19.2150532 },
    { lat: 49.1975309, lng: 19.2147659 },
    { lat: 49.1976917, lng: 19.2144762 },
    { lat: 49.1978046, lng: 19.2142465 },
    { lat: 49.1978314, lng: 19.2141949 },
    { lat: 49.1979827, lng: 19.2139876 },
    { lat: 49.1980028, lng: 19.2139619 },
    { lat: 49.1982987, lng: 19.2135993 },
    { lat: 49.198239, lng: 19.2133202 },
    { lat: 49.1981545, lng: 19.2129012 },
    { lat: 49.1982048, lng: 19.2127986 },
    { lat: 49.1982969, lng: 19.212625 },
    { lat: 49.1984539, lng: 19.2123306 },
    { lat: 49.1984595, lng: 19.2123048 },
    { lat: 49.1984054, lng: 19.2122106 },
    { lat: 49.1984242, lng: 19.2121801 },
    { lat: 49.1988445, lng: 19.2117815 },
    { lat: 49.1990525, lng: 19.211862 },
    { lat: 49.1992338, lng: 19.2119537 },
    { lat: 49.1993829, lng: 19.212035 },
    { lat: 49.1997161, lng: 19.212234 },
    { lat: 49.1997711, lng: 19.2121104 },
    { lat: 49.1998667, lng: 19.2118982 },
    { lat: 49.199933, lng: 19.2117498 },
    { lat: 49.1999752, lng: 19.2117389 },
    { lat: 49.2000947, lng: 19.2116942 },
    { lat: 49.2001202, lng: 19.2116852 },
    { lat: 49.2002204, lng: 19.2116382 },
    { lat: 49.2002283, lng: 19.2116338 },
    { lat: 49.2003147, lng: 19.2115808 },
    { lat: 49.2005676, lng: 19.2114274 },
    { lat: 49.2008398, lng: 19.2124676 },
    { lat: 49.2011163, lng: 19.2135261 },
    { lat: 49.2011484, lng: 19.2144513 },
    { lat: 49.2011773, lng: 19.214513 },
    { lat: 49.201215, lng: 19.2145249 },
    { lat: 49.2011708, lng: 19.215196 },
    { lat: 49.2011065, lng: 19.2161803 },
    { lat: 49.2010515, lng: 19.217001 },
    { lat: 49.201033, lng: 19.2172975 },
    { lat: 49.2014113, lng: 19.2173113 },
    { lat: 49.2014451, lng: 19.2173126 },
    { lat: 49.2014802, lng: 19.2173144 },
    { lat: 49.2015287, lng: 19.2173162 },
    { lat: 49.2015244, lng: 19.2173704 },
    { lat: 49.2016043, lng: 19.2174101 },
    { lat: 49.2016063, lng: 19.2173583 },
    { lat: 49.2018885, lng: 19.217181 },
    { lat: 49.2023341, lng: 19.217259 },
    { lat: 49.202863, lng: 19.2173594 },
    { lat: 49.2030505, lng: 19.2178581 },
    { lat: 49.2031354, lng: 19.2180778 },
    { lat: 49.2031686, lng: 19.2181339 },
    { lat: 49.2033249, lng: 19.2182213 },
    { lat: 49.2034073, lng: 19.2181562 },
    { lat: 49.2034683, lng: 19.2181829 },
    { lat: 49.2035064, lng: 19.2181541 },
    { lat: 49.203568, lng: 19.2180754 },
    { lat: 49.2036181, lng: 19.2180623 },
    { lat: 49.2037309, lng: 19.2179224 },
    { lat: 49.2038498, lng: 19.2179105 },
    { lat: 49.2039617, lng: 19.2179632 },
    { lat: 49.2040769, lng: 19.2178544 },
    { lat: 49.2041101, lng: 19.2179109 },
    { lat: 49.2042509, lng: 19.2179311 },
    { lat: 49.2042692, lng: 19.217888 },
    { lat: 49.2042598, lng: 19.2178123 },
    { lat: 49.2043553, lng: 19.2177107 },
    { lat: 49.2044207, lng: 19.217734 },
    { lat: 49.2043964, lng: 19.2178434 },
    { lat: 49.2044323, lng: 19.2178812 },
    { lat: 49.2045075, lng: 19.2178061 },
    { lat: 49.2046025, lng: 19.2178113 },
    { lat: 49.2046519, lng: 19.217738 },
    { lat: 49.2046973, lng: 19.2177448 },
    { lat: 49.2047142, lng: 19.2176539 },
    { lat: 49.2049076, lng: 19.217434 },
    { lat: 49.204951, lng: 19.2174659 },
    { lat: 49.2049842, lng: 19.2174095 },
    { lat: 49.2051728, lng: 19.2173931 },
    { lat: 49.2052998, lng: 19.2172689 },
    { lat: 49.2053042, lng: 19.2173354 },
    { lat: 49.205356, lng: 19.2173581 },
    { lat: 49.2054195, lng: 19.2172252 },
    { lat: 49.205532, lng: 19.2172205 },
    { lat: 49.2055801, lng: 19.2171214 },
    { lat: 49.2057532, lng: 19.2171117 },
    { lat: 49.2057677, lng: 19.2170149 },
    { lat: 49.2058061, lng: 19.2170171 },
    { lat: 49.2058393, lng: 19.2169571 },
    { lat: 49.2059302, lng: 19.2169268 },
    { lat: 49.2060179, lng: 19.2168012 },
    { lat: 49.2060112, lng: 19.2167598 },
    { lat: 49.2061242, lng: 19.2167871 },
    { lat: 49.2061717, lng: 19.2167678 },
    { lat: 49.2061897, lng: 19.21672 },
    { lat: 49.2063832, lng: 19.2166662 },
    { lat: 49.2063946, lng: 19.2166191 },
    { lat: 49.2064285, lng: 19.2166229 },
    { lat: 49.2064435, lng: 19.2165862 },
    { lat: 49.2065413, lng: 19.2165313 },
    { lat: 49.2065809, lng: 19.2165589 },
    { lat: 49.2067207, lng: 19.2164914 },
    { lat: 49.2067931, lng: 19.2164007 },
    { lat: 49.2067798, lng: 19.2163596 },
    { lat: 49.2068094, lng: 19.2162929 },
    { lat: 49.2068294, lng: 19.2163105 },
    { lat: 49.2070743, lng: 19.2161085 },
    { lat: 49.2070666, lng: 19.216042 },
    { lat: 49.207106, lng: 19.2159925 },
    { lat: 49.2071982, lng: 19.2159404 },
    { lat: 49.2071967, lng: 19.2159095 },
    { lat: 49.2072852, lng: 19.215829 },
    { lat: 49.2073509, lng: 19.2156042 },
    { lat: 49.2074578, lng: 19.2154614 },
    { lat: 49.2075317, lng: 19.2154491 },
    { lat: 49.2075692, lng: 19.2154107 },
    { lat: 49.207606, lng: 19.2153294 },
    { lat: 49.2076193, lng: 19.2152292 },
    { lat: 49.2076814, lng: 19.2151396 },
    { lat: 49.2077418, lng: 19.2150114 },
    { lat: 49.207852, lng: 19.2146566 },
    { lat: 49.2079117, lng: 19.2142339 },
    { lat: 49.2079387, lng: 19.2140496 },
    { lat: 49.2082554, lng: 19.2136957 },
    { lat: 49.2084126, lng: 19.2135227 },
    { lat: 49.2085321, lng: 19.213474 },
    { lat: 49.2085769, lng: 19.2133972 },
    { lat: 49.2086117, lng: 19.2134189 },
    { lat: 49.2087338, lng: 19.2133803 },
    { lat: 49.2088395, lng: 19.2134456 },
    { lat: 49.2088738, lng: 19.2134335 },
    { lat: 49.2088761, lng: 19.2133882 },
    { lat: 49.2089599, lng: 19.2133236 },
    { lat: 49.2090784, lng: 19.2133009 },
    { lat: 49.209147, lng: 19.2132302 },
    { lat: 49.2092642, lng: 19.2131824 },
    { lat: 49.2093846, lng: 19.2131325 },
    { lat: 49.2095172, lng: 19.2130765 },
    { lat: 49.2096506, lng: 19.2130608 },
    { lat: 49.2099019, lng: 19.2126891 },
    { lat: 49.2100176, lng: 19.2125164 },
    { lat: 49.2100429, lng: 19.2123394 },
    { lat: 49.210178, lng: 19.2120305 },
    { lat: 49.2103574, lng: 19.2117789 },
    { lat: 49.2104528, lng: 19.211744 },
    { lat: 49.2105231, lng: 19.2114986 },
    { lat: 49.2106424, lng: 19.2114261 },
    { lat: 49.2107816, lng: 19.21132 },
    { lat: 49.210827, lng: 19.2112572 },
    { lat: 49.2109317, lng: 19.2111856 },
    { lat: 49.2109739, lng: 19.2111682 },
    { lat: 49.2110051, lng: 19.2111828 },
    { lat: 49.2110374, lng: 19.2111532 },
    { lat: 49.2111229, lng: 19.2111281 },
    { lat: 49.2111674, lng: 19.211091 },
    { lat: 49.2112018, lng: 19.2110796 },
    { lat: 49.2112571, lng: 19.2110604 },
    { lat: 49.2112782, lng: 19.2110786 },
    { lat: 49.2114955, lng: 19.2110495 },
    { lat: 49.2115227, lng: 19.2110497 },
    { lat: 49.2117479, lng: 19.2109285 },
    { lat: 49.2117462, lng: 19.2109193 },
    { lat: 49.2117352, lng: 19.2108773 },
    { lat: 49.2116924, lng: 19.2104098 },
    { lat: 49.2116677, lng: 19.2101076 },
    { lat: 49.211658, lng: 19.2098831 },
    { lat: 49.2116006, lng: 19.2085308 },
    { lat: 49.2115962, lng: 19.2084141 },
    { lat: 49.211589, lng: 19.2082891 },
    { lat: 49.2115877, lng: 19.2082139 },
    { lat: 49.2117985, lng: 19.2075597 },
    { lat: 49.211764, lng: 19.2075452 },
    { lat: 49.2117018, lng: 19.2075187 },
    { lat: 49.2118881, lng: 19.2072678 },
    { lat: 49.2120278, lng: 19.2068696 },
    { lat: 49.2120513, lng: 19.2067981 },
    { lat: 49.2120755, lng: 19.2066685 },
    { lat: 49.2121595, lng: 19.2064452 },
    { lat: 49.2122246, lng: 19.2062281 },
    { lat: 49.2123662, lng: 19.2059865 },
    { lat: 49.2124974, lng: 19.205484 },
    { lat: 49.2125401, lng: 19.2053621 },
    { lat: 49.2125825, lng: 19.2051925 },
    { lat: 49.2125999, lng: 19.205107 },
    { lat: 49.2126399, lng: 19.2050015 },
    { lat: 49.2126936, lng: 19.2048084 },
    { lat: 49.2128079, lng: 19.204656 },
    { lat: 49.2128901, lng: 19.2045833 },
    { lat: 49.2129346, lng: 19.2045217 },
    { lat: 49.2129853, lng: 19.2044501 },
    { lat: 49.2130009, lng: 19.2044519 },
    { lat: 49.2130447, lng: 19.2043507 },
    { lat: 49.2130839, lng: 19.2042281 },
    { lat: 49.2131026, lng: 19.2041755 },
    { lat: 49.2130929, lng: 19.2040674 },
    { lat: 49.2131149, lng: 19.2039834 },
    { lat: 49.2131403, lng: 19.2039048 },
    { lat: 49.2131675, lng: 19.2038355 },
    { lat: 49.213347, lng: 19.2035171 },
    { lat: 49.2133927, lng: 19.2034374 },
    { lat: 49.2135301, lng: 19.2031288 },
    { lat: 49.2138138, lng: 19.2024927 },
    { lat: 49.2138638, lng: 19.202384 },
    { lat: 49.2139657, lng: 19.2021605 },
    { lat: 49.2141505, lng: 19.2017612 },
    { lat: 49.2142331, lng: 19.2015839 },
    { lat: 49.2142895, lng: 19.2014658 },
    { lat: 49.2143476, lng: 19.2013378 },
    { lat: 49.2144732, lng: 19.2010677 },
    { lat: 49.214513, lng: 19.2009136 },
    { lat: 49.214625, lng: 19.2004997 },
    { lat: 49.214697, lng: 19.2002209 },
    { lat: 49.2147393, lng: 19.2000683 },
    { lat: 49.2150714, lng: 19.1994534 },
    { lat: 49.2155999, lng: 19.1983329 },
    { lat: 49.2157098, lng: 19.1973168 },
    { lat: 49.2161439, lng: 19.1965977 },
    { lat: 49.2163121, lng: 19.1951316 },
    { lat: 49.2165688, lng: 19.1949848 },
    { lat: 49.2169227, lng: 19.193961 },
    { lat: 49.2173941, lng: 19.1925429 },
    { lat: 49.2179082, lng: 19.1896796 },
    { lat: 49.2184696, lng: 19.1889825 },
    { lat: 49.2189305, lng: 19.1882808 },
    { lat: 49.2193669, lng: 19.1878198 },
    { lat: 49.2198848, lng: 19.1874337 },
    { lat: 49.2203666, lng: 19.1870718 },
    { lat: 49.220558, lng: 19.1865566 },
    { lat: 49.2208348, lng: 19.1858258 },
    { lat: 49.221071, lng: 19.1852111 },
    { lat: 49.2211051, lng: 19.1851254 },
    { lat: 49.2212886, lng: 19.1846518 },
    { lat: 49.2214893, lng: 19.1844457 },
    { lat: 49.2222198, lng: 19.1845868 },
    { lat: 49.2231771, lng: 19.1848509 },
    { lat: 49.2237202, lng: 19.1848477 },
    { lat: 49.2243534, lng: 19.1849857 },
    { lat: 49.2248079, lng: 19.1847567 },
    { lat: 49.225154, lng: 19.1847591 },
    { lat: 49.2256963, lng: 19.184271 },
    { lat: 49.2260881, lng: 19.1840324 },
    { lat: 49.2263869, lng: 19.1840077 },
    { lat: 49.2269762, lng: 19.183935 },
    { lat: 49.2271872, lng: 19.1838261 },
    { lat: 49.2273226, lng: 19.1837347 },
    { lat: 49.2282696, lng: 19.1847426 },
    { lat: 49.2282863, lng: 19.1847605 },
    { lat: 49.2291229, lng: 19.1856733 },
    { lat: 49.2297721, lng: 19.186048 },
    { lat: 49.2300251, lng: 19.1861962 },
    { lat: 49.2312929, lng: 19.1865656 },
    { lat: 49.2316829, lng: 19.1867088 },
    { lat: 49.2324607, lng: 19.1866178 },
    { lat: 49.2329313, lng: 19.1865373 },
    { lat: 49.2332255, lng: 19.1867927 },
    { lat: 49.2333561, lng: 19.186906 },
    { lat: 49.2338179, lng: 19.1875269 },
    { lat: 49.2341049, lng: 19.1877524 },
    { lat: 49.2345288, lng: 19.1880847 },
    { lat: 49.2347474, lng: 19.1882667 },
    { lat: 49.2350893, lng: 19.188319 },
    { lat: 49.2352782, lng: 19.1883132 },
    { lat: 49.2354017, lng: 19.1883462 },
    { lat: 49.236054, lng: 19.1883664 },
    { lat: 49.2364209, lng: 19.1883088 },
    { lat: 49.2367435, lng: 19.1882897 },
    { lat: 49.2374975, lng: 19.1883111 },
    { lat: 49.2385445, lng: 19.1883358 },
    { lat: 49.239155, lng: 19.1879788 },
    { lat: 49.2392395, lng: 19.1879288 },
    { lat: 49.2399209, lng: 19.1878025 },
    { lat: 49.240361, lng: 19.1875805 },
    { lat: 49.2413272, lng: 19.1869095 },
    { lat: 49.2415921, lng: 19.186727 },
    { lat: 49.2416968, lng: 19.1866553 },
    { lat: 49.2418821, lng: 19.1865951 },
    { lat: 49.2419554, lng: 19.186572 },
    { lat: 49.2431477, lng: 19.1871002 },
    { lat: 49.2433274, lng: 19.1870416 },
    { lat: 49.2436834, lng: 19.1869463 },
    { lat: 49.2439279, lng: 19.186971 },
    { lat: 49.2441151, lng: 19.1869928 },
    { lat: 49.2444874, lng: 19.1870742 },
    { lat: 49.2446806, lng: 19.1871036 },
    { lat: 49.2447951, lng: 19.1866957 },
    { lat: 49.2448717, lng: 19.1861856 },
    { lat: 49.2449116, lng: 19.1855657 },
    { lat: 49.2449688, lng: 19.1849553 },
    { lat: 49.2450215, lng: 19.1843906 },
    { lat: 49.2449357, lng: 19.1838761 },
    { lat: 49.2449308, lng: 19.1838435 },
    { lat: 49.2449264, lng: 19.18366 },
    { lat: 49.2449053, lng: 19.1827555 },
    { lat: 49.2448061, lng: 19.1820571 },
    { lat: 49.2447453, lng: 19.181222 },
    { lat: 49.2447443, lng: 19.1812001 },
    { lat: 49.2448945, lng: 19.1805074 },
    { lat: 49.244902, lng: 19.180477 },
    { lat: 49.2451111, lng: 19.1800259 },
    { lat: 49.2451079, lng: 19.1796998 },
    { lat: 49.2451063, lng: 19.179415 },
    { lat: 49.2448795, lng: 19.1786181 },
    { lat: 49.2448696, lng: 19.1785301 },
    { lat: 49.2447842, lng: 19.1777876 },
    { lat: 49.2447738, lng: 19.1776899 },
    { lat: 49.2446938, lng: 19.1774566 },
    { lat: 49.2445564, lng: 19.1770684 },
    { lat: 49.2442434, lng: 19.1767448 },
    { lat: 49.2441907, lng: 19.1764961 },
    { lat: 49.244148, lng: 19.1762914 },
    { lat: 49.2442185, lng: 19.1759533 },
    { lat: 49.2442799, lng: 19.1756599 },
    { lat: 49.2442646, lng: 19.1752086 },
    { lat: 49.2442555, lng: 19.1749271 },
    { lat: 49.2444545, lng: 19.1747997 },
    { lat: 49.2447588, lng: 19.1745921 },
    { lat: 49.2447764, lng: 19.1745804 },
    { lat: 49.2451231, lng: 19.174385 },
    { lat: 49.2457116, lng: 19.1741099 },
    { lat: 49.2463143, lng: 19.1738451 },
    { lat: 49.2467582, lng: 19.1735904 },
    { lat: 49.2471593, lng: 19.17305 },
    { lat: 49.2472874, lng: 19.1725312 },
    { lat: 49.2476554, lng: 19.1721721 },
    { lat: 49.2481377, lng: 19.1716352 },
    { lat: 49.2484127, lng: 19.1711942 },
    { lat: 49.2488773, lng: 19.1706405 },
    { lat: 49.2492858, lng: 19.1704431 },
    { lat: 49.2501261, lng: 19.1700334 },
    { lat: 49.2507708, lng: 19.1699626 },
    { lat: 49.2509232, lng: 19.169945 },
    { lat: 49.2514017, lng: 19.1698912 },
    { lat: 49.2514174, lng: 19.1698894 },
    { lat: 49.2514231, lng: 19.1698888 },
    { lat: 49.2514221, lng: 19.1698438 },
    { lat: 49.2514245, lng: 19.1694989 },
    { lat: 49.2513226, lng: 19.1692832 },
    { lat: 49.2510495, lng: 19.1688093 },
    { lat: 49.2509814, lng: 19.1687063 },
    { lat: 49.2508536, lng: 19.1684422 },
    { lat: 49.2506717, lng: 19.1675984 },
    { lat: 49.2506012, lng: 19.1675385 },
    { lat: 49.2505628, lng: 19.1671413 },
    { lat: 49.2505231, lng: 19.1670426 },
    { lat: 49.2504866, lng: 19.1666412 },
    { lat: 49.2505021, lng: 19.1662852 },
    { lat: 49.250357, lng: 19.1653874 },
    { lat: 49.2503968, lng: 19.165205 },
    { lat: 49.2503582, lng: 19.1650178 },
    { lat: 49.2503702, lng: 19.1647314 },
    { lat: 49.2503697, lng: 19.1642101 },
    { lat: 49.2503797, lng: 19.1640217 },
    { lat: 49.2503904, lng: 19.1638242 },
    { lat: 49.2503555, lng: 19.1637524 },
    { lat: 49.2503463, lng: 19.1636542 },
    { lat: 49.2503585, lng: 19.163584 },
    { lat: 49.250343, lng: 19.1631492 },
    { lat: 49.2503318, lng: 19.1626854 },
    { lat: 49.250248, lng: 19.1624917 },
    { lat: 49.2502078, lng: 19.1619656 },
    { lat: 49.2500059, lng: 19.1612413 },
    { lat: 49.2499881, lng: 19.1609478 },
    { lat: 49.249838, lng: 19.1603183 },
    { lat: 49.2497309, lng: 19.16004 },
    { lat: 49.2496054, lng: 19.1596822 },
    { lat: 49.2495902, lng: 19.1595065 },
    { lat: 49.249655, lng: 19.1593083 },
    { lat: 49.2494301, lng: 19.158487 },
    { lat: 49.2494055, lng: 19.1583978 },
    { lat: 49.2492562, lng: 19.1577606 },
    { lat: 49.2492708, lng: 19.1568079 },
    { lat: 49.2493227, lng: 19.1563013 },
    { lat: 49.2493376, lng: 19.1556307 },
    { lat: 49.2491751, lng: 19.15482 },
    { lat: 49.2491239, lng: 19.1541616 },
    { lat: 49.2491863, lng: 19.1542397 },
    { lat: 49.249152, lng: 19.1541368 },
    { lat: 49.249139, lng: 19.1540972 },
    { lat: 49.2490766, lng: 19.1540191 },
    { lat: 49.2488745, lng: 19.1540153 },
    { lat: 49.2484739, lng: 19.1541661 },
    { lat: 49.2484651, lng: 19.1541693 },
    { lat: 49.2482372, lng: 19.1542579 },
    { lat: 49.2478276, lng: 19.1545527 },
    { lat: 49.2476961, lng: 19.1546502 },
    { lat: 49.2474594, lng: 19.1549999 },
    { lat: 49.2472349, lng: 19.1554836 },
    { lat: 49.2470706, lng: 19.1559756 },
    { lat: 49.2470524, lng: 19.1560379 },
    { lat: 49.2470435, lng: 19.1560642 },
    { lat: 49.2470297, lng: 19.1561303 },
    { lat: 49.2469179, lng: 19.1566322 },
    { lat: 49.2469088, lng: 19.1566783 },
    { lat: 49.2468551, lng: 19.1569885 },
    { lat: 49.2468159, lng: 19.1569695 },
    { lat: 49.2468026, lng: 19.1569534 },
    { lat: 49.2465811, lng: 19.1565705 },
    { lat: 49.246571, lng: 19.1565471 },
    { lat: 49.2465022, lng: 19.1564777 },
    { lat: 49.2464853, lng: 19.1564551 },
    { lat: 49.2463685, lng: 19.1562533 },
    { lat: 49.2462463, lng: 19.1559645 },
    { lat: 49.2462581, lng: 19.1555374 },
    { lat: 49.2462, lng: 19.1553626 },
    { lat: 49.2461303, lng: 19.1550169 },
    { lat: 49.246285, lng: 19.1543522 },
    { lat: 49.2462825, lng: 19.1540426 },
    { lat: 49.2464488, lng: 19.1536647 },
    { lat: 49.2464918, lng: 19.1531555 },
    { lat: 49.2466585, lng: 19.1527379 },
    { lat: 49.2468528, lng: 19.1521678 },
    { lat: 49.2470728, lng: 19.1516169 },
    { lat: 49.2472262, lng: 19.1511773 },
    { lat: 49.2474087, lng: 19.1506663 },
    { lat: 49.2474595, lng: 19.1502554 },
    { lat: 49.2476067, lng: 19.1499883 },
    { lat: 49.2476702, lng: 19.1496975 },
    { lat: 49.2477448, lng: 19.1492835 },
    { lat: 49.2477411, lng: 19.149232 },
    { lat: 49.2476977, lng: 19.1487575 },
    { lat: 49.2477722, lng: 19.1485029 },
    { lat: 49.2476467, lng: 19.1481041 },
    { lat: 49.2476707, lng: 19.1478297 },
    { lat: 49.2476105, lng: 19.1473116 },
    { lat: 49.247542, lng: 19.1468796 },
    { lat: 49.2474444, lng: 19.1464477 },
    { lat: 49.2473104, lng: 19.1458496 },
    { lat: 49.2472569, lng: 19.1454689 },
    { lat: 49.2470316, lng: 19.1450768 },
    { lat: 49.2469898, lng: 19.1450288 },
    { lat: 49.246848, lng: 19.1447314 },
    { lat: 49.2466257, lng: 19.1444248 },
    { lat: 49.2464901, lng: 19.1441419 },
    { lat: 49.2463489, lng: 19.1439058 },
    { lat: 49.2462549, lng: 19.143639 },
    { lat: 49.2461067, lng: 19.1433997 },
    { lat: 49.2460388, lng: 19.1431852 },
    { lat: 49.2460185, lng: 19.1430692 },
    { lat: 49.2458812, lng: 19.1427287 },
    { lat: 49.2457868, lng: 19.1425252 },
    { lat: 49.2457226, lng: 19.1424094 },
    { lat: 49.2455201, lng: 19.1421868 },
    { lat: 49.2453505, lng: 19.1419918 },
    { lat: 49.2452437, lng: 19.141812 },
    { lat: 49.2450791, lng: 19.1416034 },
    { lat: 49.2448822, lng: 19.1414451 },
    { lat: 49.2446671, lng: 19.1411943 },
    { lat: 49.2445704, lng: 19.1410873 },
    { lat: 49.244495, lng: 19.1409974 },
    { lat: 49.2443111, lng: 19.1407828 },
    { lat: 49.2441599, lng: 19.1406409 },
    { lat: 49.2440365, lng: 19.140494 },
    { lat: 49.2439474, lng: 19.1402841 },
    { lat: 49.2438859, lng: 19.1402247 },
    { lat: 49.2438749, lng: 19.1402069 },
    { lat: 49.2437411, lng: 19.139958 },
    { lat: 49.2436284, lng: 19.1398449 },
    { lat: 49.2435285, lng: 19.1396896 },
    { lat: 49.2434522, lng: 19.1395847 },
    { lat: 49.2433607, lng: 19.1395085 },
    { lat: 49.2432254, lng: 19.1393208 },
    { lat: 49.2431296, lng: 19.139182 },
    { lat: 49.2430291, lng: 19.1391123 },
    { lat: 49.2428972, lng: 19.1389949 },
    { lat: 49.2428265, lng: 19.1389355 },
    { lat: 49.2426937, lng: 19.1388647 },
    { lat: 49.2426045, lng: 19.1387511 },
    { lat: 49.2425316, lng: 19.1386407 },
    { lat: 49.2425145, lng: 19.1386146 },
    { lat: 49.2424153, lng: 19.1384754 },
    { lat: 49.2422877, lng: 19.1383096 },
    { lat: 49.2422674, lng: 19.1382841 },
    { lat: 49.2422407, lng: 19.1381973 },
    { lat: 49.2422354, lng: 19.1381615 },
    { lat: 49.2422225, lng: 19.1379894 },
    { lat: 49.2421979, lng: 19.1378768 },
    { lat: 49.2421137, lng: 19.1377439 },
    { lat: 49.2420751, lng: 19.1376455 },
    { lat: 49.2420184, lng: 19.1375246 },
    { lat: 49.2419977, lng: 19.1374245 },
    { lat: 49.241994, lng: 19.1373729 },
    { lat: 49.2419966, lng: 19.1372168 },
    { lat: 49.2419842, lng: 19.1369643 },
    { lat: 49.2419326, lng: 19.1368056 },
    { lat: 49.2419112, lng: 19.1367601 },
    { lat: 49.2418419, lng: 19.1366078 },
    { lat: 49.2417398, lng: 19.1363879 },
    { lat: 49.2417282, lng: 19.1363343 },
    { lat: 49.2416818, lng: 19.1361924 },
    { lat: 49.2415884, lng: 19.1359866 },
    { lat: 49.2414973, lng: 19.1358098 },
    { lat: 49.2414941, lng: 19.1357629 },
    { lat: 49.2414432, lng: 19.1356453 },
    { lat: 49.2414026, lng: 19.1355529 },
    { lat: 49.2413489, lng: 19.1354941 },
    { lat: 49.2412579, lng: 19.1353818 },
    { lat: 49.241208, lng: 19.1352641 },
    { lat: 49.2411609, lng: 19.1351266 },
    { lat: 49.2410097, lng: 19.1348679 },
    { lat: 49.2409357, lng: 19.1346014 },
    { lat: 49.2408997, lng: 19.1345114 },
    { lat: 49.2408912, lng: 19.1344975 },
    { lat: 49.2408117, lng: 19.1343688 },
    { lat: 49.2407665, lng: 19.1343234 },
    { lat: 49.2406825, lng: 19.1342875 },
    { lat: 49.240669, lng: 19.1342862 },
    { lat: 49.2405868, lng: 19.1342898 },
    { lat: 49.2404715, lng: 19.1342376 },
    { lat: 49.2404027, lng: 19.1342357 },
    { lat: 49.2403666, lng: 19.1342355 },
    { lat: 49.2403496, lng: 19.134232 },
    { lat: 49.2402843, lng: 19.1342092 },
    { lat: 49.2402306, lng: 19.1341742 },
    { lat: 49.2401604, lng: 19.1340772 },
    { lat: 49.2400496, lng: 19.1338824 },
    { lat: 49.2399903, lng: 19.1337759 },
    { lat: 49.2399568, lng: 19.1336902 },
    { lat: 49.239917, lng: 19.133615 },
    { lat: 49.2398736, lng: 19.1335586 },
    { lat: 49.2398274, lng: 19.133512 },
    { lat: 49.2397106, lng: 19.1332861 },
    { lat: 49.2395942, lng: 19.1327738 },
    { lat: 49.2395853, lng: 19.1327322 },
    { lat: 49.2395744, lng: 19.1326934 },
    { lat: 49.2395676, lng: 19.1326708 },
    { lat: 49.239492, lng: 19.1323894 },
    { lat: 49.2394578, lng: 19.1322648 },
    { lat: 49.2393732, lng: 19.1320562 },
    { lat: 49.2393282, lng: 19.1317825 },
    { lat: 49.2392937, lng: 19.1316988 },
    { lat: 49.2391683, lng: 19.1315064 },
    { lat: 49.2391373, lng: 19.1313602 },
    { lat: 49.2390491, lng: 19.131169 },
    { lat: 49.2389222, lng: 19.1308541 },
    { lat: 49.2388581, lng: 19.1306738 },
    { lat: 49.2387932, lng: 19.1305193 },
    { lat: 49.2387482, lng: 19.1303621 },
    { lat: 49.2386395, lng: 19.1302608 },
    { lat: 49.2385546, lng: 19.13018 },
    { lat: 49.2384948, lng: 19.130137 },
    { lat: 49.2384821, lng: 19.1301269 },
    { lat: 49.2383941, lng: 19.1300338 },
    { lat: 49.2383502, lng: 19.129967 },
    { lat: 49.2383032, lng: 19.1299035 },
    { lat: 49.2369494, lng: 19.128475 },
    { lat: 49.2367574, lng: 19.1281646 },
    { lat: 49.2367454, lng: 19.1281527 },
    { lat: 49.2362873, lng: 19.1277731 },
    { lat: 49.2356398, lng: 19.1272291 },
    { lat: 49.2355764, lng: 19.1271789 },
    { lat: 49.2352291, lng: 19.1269213 },
    { lat: 49.2350323, lng: 19.1267717 },
    { lat: 49.2349436, lng: 19.1263375 },
    { lat: 49.2344185, lng: 19.1257686 },
    { lat: 49.2343874, lng: 19.1257311 },
    { lat: 49.2342089, lng: 19.1254914 },
    { lat: 49.2341436, lng: 19.1254021 },
    { lat: 49.2340675, lng: 19.1252979 },
    { lat: 49.2340169, lng: 19.1252294 },
    { lat: 49.2339853, lng: 19.125158 },
    { lat: 49.2335089, lng: 19.1249858 },
    { lat: 49.2328468, lng: 19.1244442 },
    { lat: 49.2319291, lng: 19.1236357 },
    { lat: 49.2318441, lng: 19.1235151 },
    { lat: 49.2313222, lng: 19.1227744 },
    { lat: 49.2308517, lng: 19.1222095 },
    { lat: 49.2302048, lng: 19.1213362 },
    { lat: 49.2295779, lng: 19.1206421 },
    { lat: 49.2290353, lng: 19.1201244 },
    { lat: 49.2290594, lng: 19.1200672 },
    { lat: 49.2291965, lng: 19.1194807 },
    { lat: 49.2292952, lng: 19.1183225 },
    { lat: 49.2296919, lng: 19.1180442 },
    { lat: 49.2297202, lng: 19.1180251 },
    { lat: 49.2297748, lng: 19.1179868 },
    { lat: 49.2302177, lng: 19.1173159 },
    { lat: 49.2306471, lng: 19.1168777 },
    { lat: 49.2310926, lng: 19.1165642 },
    { lat: 49.2315481, lng: 19.1163355 },
    { lat: 49.2320409, lng: 19.1160397 },
    { lat: 49.2323451, lng: 19.1156479 },
    { lat: 49.2332853, lng: 19.1151434 },
    { lat: 49.2338156, lng: 19.1150153 },
    { lat: 49.2344901, lng: 19.1146797 },
    { lat: 49.2349343, lng: 19.1144269 },
    { lat: 49.2355444, lng: 19.1145044 },
    { lat: 49.2360157, lng: 19.1145061 },
    { lat: 49.236152, lng: 19.1139534 },
    { lat: 49.2362582, lng: 19.1133637 },
    { lat: 49.2362645, lng: 19.1133273 },
    { lat: 49.236277, lng: 19.1132592 },
    { lat: 49.2363107, lng: 19.1130515 },
    { lat: 49.2363864, lng: 19.1126876 },
    { lat: 49.2364112, lng: 19.1121805 },
    { lat: 49.2365248, lng: 19.1112368 },
    { lat: 49.2365458, lng: 19.1100517 },
    { lat: 49.2365126, lng: 19.1097683 },
    { lat: 49.2364436, lng: 19.1091666 },
    { lat: 49.2364485, lng: 19.1089666 },
    { lat: 49.2364657, lng: 19.1083262 },
    { lat: 49.236478, lng: 19.1078447 },
    { lat: 49.2364804, lng: 19.10773 },
    { lat: 49.2364925, lng: 19.1072648 },
    { lat: 49.2365119, lng: 19.1065819 },
    { lat: 49.2363148, lng: 19.1058473 },
    { lat: 49.2362441, lng: 19.1051437 },
    { lat: 49.2361743, lng: 19.1045973 },
    { lat: 49.236167, lng: 19.104103 },
    { lat: 49.236164, lng: 19.104101 },
    { lat: 49.233965, lng: 19.1028 },
    { lat: 49.233767, lng: 19.102683 },
    { lat: 49.23359, lng: 19.102578 },
    { lat: 49.233228, lng: 19.102072 },
    { lat: 49.232783, lng: 19.101459 },
    { lat: 49.232283, lng: 19.100773 },
    { lat: 49.232199, lng: 19.100657 },
    { lat: 49.232007, lng: 19.10039 },
    { lat: 49.231741, lng: 19.100019 },
    { lat: 49.23159, lng: 19.098712 },
    { lat: 49.231076, lng: 19.099336 },
    { lat: 49.228712, lng: 19.102201 },
    { lat: 49.228678, lng: 19.102243 },
    { lat: 49.227623, lng: 19.103521 },
    { lat: 49.226587, lng: 19.103812 },
    { lat: 49.225322, lng: 19.103987 },
    { lat: 49.224817, lng: 19.104081 },
    { lat: 49.223956, lng: 19.104113 },
    { lat: 49.223574, lng: 19.104117 },
    { lat: 49.223129, lng: 19.104115 },
    { lat: 49.222637, lng: 19.103935 },
    { lat: 49.222012, lng: 19.103708 },
    { lat: 49.221402, lng: 19.10348 },
    { lat: 49.220713, lng: 19.10323 },
    { lat: 49.220122, lng: 19.103029 },
    { lat: 49.219357, lng: 19.102331 },
    { lat: 49.218841, lng: 19.101946 },
    { lat: 49.218301, lng: 19.101539 },
    { lat: 49.217731, lng: 19.101243 },
    { lat: 49.217207, lng: 19.101024 },
    { lat: 49.216742, lng: 19.101061 },
    { lat: 49.2164, lng: 19.100733 },
    { lat: 49.215726, lng: 19.099826 },
    { lat: 49.215212, lng: 19.099201 },
    { lat: 49.214647, lng: 19.098492 },
    { lat: 49.213891, lng: 19.097555 },
    { lat: 49.213111, lng: 19.096611 },
    { lat: 49.212664, lng: 19.095714 },
    { lat: 49.212291, lng: 19.094977 },
    { lat: 49.212038, lng: 19.094466 },
    { lat: 49.211789, lng: 19.093962 },
    { lat: 49.212001, lng: 19.093993 },
    { lat: 49.212268, lng: 19.094032 },
    { lat: 49.212709, lng: 19.094093 },
    { lat: 49.213422, lng: 19.093815 },
    { lat: 49.213786, lng: 19.093439 },
    { lat: 49.213986, lng: 19.092973 },
    { lat: 49.214373, lng: 19.092069 },
    { lat: 49.214444, lng: 19.091904 },
    { lat: 49.214774, lng: 19.090462 },
    { lat: 49.215097, lng: 19.089257 },
    { lat: 49.21547, lng: 19.088418 },
    { lat: 49.215715, lng: 19.087862 },
    { lat: 49.215807, lng: 19.087651 },
    { lat: 49.216103, lng: 19.087028 },
    { lat: 49.216501, lng: 19.086194 },
    { lat: 49.216905, lng: 19.085457 },
    { lat: 49.217219, lng: 19.084877 },
    { lat: 49.217588, lng: 19.084191 },
    { lat: 49.218029, lng: 19.083387 },
    { lat: 49.218092, lng: 19.083273 },
    { lat: 49.217058, lng: 19.082473 },
    { lat: 49.216345, lng: 19.081562 },
    { lat: 49.215427, lng: 19.080518 },
    { lat: 49.215155, lng: 19.080379 },
    { lat: 49.214289, lng: 19.080042 },
    { lat: 49.214154, lng: 19.079926 },
    { lat: 49.213846, lng: 19.079527 },
    { lat: 49.213616, lng: 19.079334 },
    { lat: 49.213135, lng: 19.07926 },
    { lat: 49.213006, lng: 19.079249 },
    { lat: 49.212819, lng: 19.079147 },
    { lat: 49.212434, lng: 19.079136 },
    { lat: 49.212257, lng: 19.079169 },
    { lat: 49.212126, lng: 19.079114 },
    { lat: 49.211954, lng: 19.079156 },
    { lat: 49.211805, lng: 19.079129 },
    { lat: 49.211649, lng: 19.079001 },
    { lat: 49.211506, lng: 19.078999 },
    { lat: 49.211332, lng: 19.079013 },
    { lat: 49.211164, lng: 19.078963 },
    { lat: 49.210834, lng: 19.079032 },
    { lat: 49.210571, lng: 19.078909 },
    { lat: 49.210252, lng: 19.078554 },
    { lat: 49.209886, lng: 19.078226 },
    { lat: 49.209787, lng: 19.078026 },
    { lat: 49.20953, lng: 19.077785 },
    { lat: 49.209089, lng: 19.077649 },
    { lat: 49.208799, lng: 19.077582 },
    { lat: 49.208512, lng: 19.077775 },
    { lat: 49.208208, lng: 19.07775 },
    { lat: 49.207983, lng: 19.077626 },
    { lat: 49.207833, lng: 19.077703 },
    { lat: 49.207645, lng: 19.077799 },
    { lat: 49.207471, lng: 19.07779 },
    { lat: 49.207028, lng: 19.077655 },
    { lat: 49.206702, lng: 19.077641 },
    { lat: 49.206173, lng: 19.077564 },
    { lat: 49.205984, lng: 19.077594 },
    { lat: 49.20579, lng: 19.077748 },
    { lat: 49.205668, lng: 19.077875 },
    { lat: 49.205355, lng: 19.078185 },
    { lat: 49.205062, lng: 19.078337 },
    { lat: 49.204836, lng: 19.078452 },
    { lat: 49.204711, lng: 19.078476 },
    { lat: 49.204489, lng: 19.078496 },
    { lat: 49.204178, lng: 19.078586 },
    { lat: 49.203785, lng: 19.078583 },
    { lat: 49.203244, lng: 19.078614 },
    { lat: 49.202998, lng: 19.07867 },
    { lat: 49.20283, lng: 19.078712 },
    { lat: 49.202676, lng: 19.078829 },
    { lat: 49.202521, lng: 19.078844 },
    { lat: 49.202171, lng: 19.078937 },
    { lat: 49.201982, lng: 19.078927 },
    { lat: 49.201823, lng: 19.07904 },
    { lat: 49.201642, lng: 19.079238 },
    { lat: 49.201477, lng: 19.079293 },
    { lat: 49.201168, lng: 19.079368 },
    { lat: 49.200807, lng: 19.079332 },
    { lat: 49.200646, lng: 19.07922 },
    { lat: 49.200486, lng: 19.079175 },
    { lat: 49.200341, lng: 19.079209 },
    { lat: 49.200211, lng: 19.079362 },
    { lat: 49.199971, lng: 19.079339 },
    { lat: 49.199923, lng: 19.079304 },
    { lat: 49.199862, lng: 19.079387 },
    { lat: 49.199787, lng: 19.079493 },
    { lat: 49.199671, lng: 19.079726 },
    { lat: 49.199553, lng: 19.079875 },
    { lat: 49.19928, lng: 19.080338 },
    { lat: 49.199199, lng: 19.080545 },
    { lat: 49.19912, lng: 19.08092 },
    { lat: 49.198961, lng: 19.081149 },
    { lat: 49.198704, lng: 19.081534 },
    { lat: 49.198504, lng: 19.081704 },
    { lat: 49.198289, lng: 19.081937 },
    { lat: 49.19797, lng: 19.082276 },
    { lat: 49.197749, lng: 19.082554 },
    { lat: 49.197592, lng: 19.082573 },
    { lat: 49.197385, lng: 19.082489 },
    { lat: 49.197219, lng: 19.082536 },
    { lat: 49.19689, lng: 19.082412 },
    { lat: 49.196651, lng: 19.082364 },
    { lat: 49.196448, lng: 19.082214 },
    { lat: 49.196128, lng: 19.082115 },
    { lat: 49.195997, lng: 19.082053 },
    { lat: 49.195345, lng: 19.081951 },
    { lat: 49.194999, lng: 19.081948 },
    { lat: 49.194813, lng: 19.081917 },
    { lat: 49.194501, lng: 19.081888 },
    { lat: 49.194189, lng: 19.081817 },
    { lat: 49.193599, lng: 19.081767 },
    { lat: 49.193379, lng: 19.081739 },
    { lat: 49.193184, lng: 19.081787 },
    { lat: 49.193023, lng: 19.081864 },
    { lat: 49.192858, lng: 19.081844 },
    { lat: 49.192709, lng: 19.081816 },
    { lat: 49.192585, lng: 19.081809 },
    { lat: 49.192498, lng: 19.081894 },
    { lat: 49.192233, lng: 19.082197 },
    { lat: 49.192029, lng: 19.082592 },
    { lat: 49.191845, lng: 19.082868 },
    { lat: 49.191318, lng: 19.083045 },
    { lat: 49.191209, lng: 19.083115 },
    { lat: 49.191116, lng: 19.083235 },
    { lat: 49.190909, lng: 19.083289 },
    { lat: 49.190433, lng: 19.083379 },
    { lat: 49.19037, lng: 19.083353 },
    { lat: 49.190323, lng: 19.083374 },
    { lat: 49.189811, lng: 19.083706 },
    { lat: 49.189766, lng: 19.083792 },
    { lat: 49.189227, lng: 19.083851 },
    { lat: 49.189052, lng: 19.083897 },
    { lat: 49.188937, lng: 19.084018 },
    { lat: 49.188607, lng: 19.084436 },
    { lat: 49.188508, lng: 19.084406 },
    { lat: 49.188256, lng: 19.084456 },
    { lat: 49.188151, lng: 19.084454 },
    { lat: 49.18799, lng: 19.084402 },
    { lat: 49.187741, lng: 19.084372 },
    { lat: 49.187555, lng: 19.084224 },
    { lat: 49.187295, lng: 19.084154 },
    { lat: 49.1872944, lng: 19.0842786 },
    { lat: 49.1872894, lng: 19.084748 },
    { lat: 49.1874742, lng: 19.0855257 },
    { lat: 49.1879386, lng: 19.0865751 },
    { lat: 49.188439, lng: 19.0876951 },
    { lat: 49.1889019, lng: 19.0886034 },
    { lat: 49.1893165, lng: 19.0896489 },
    { lat: 49.1897746, lng: 19.0902999 },
    { lat: 49.1904284, lng: 19.0915118 },
    { lat: 49.1908214, lng: 19.0921011 },
    { lat: 49.1911037, lng: 19.0925243 },
    { lat: 49.1919021, lng: 19.0939727 },
    { lat: 49.1919554, lng: 19.0945047 },
    { lat: 49.1919899, lng: 19.0948842 },
    { lat: 49.1920029, lng: 19.0952867 },
    { lat: 49.191836, lng: 19.0956727 },
    { lat: 49.1915783, lng: 19.0964839 },
    { lat: 49.1903467, lng: 19.0962195 },
    { lat: 49.1893626, lng: 19.0966065 },
    { lat: 49.1887245, lng: 19.096974 },
    { lat: 49.1879989, lng: 19.0975281 },
    { lat: 49.1874899, lng: 19.0979745 },
    { lat: 49.1873176, lng: 19.098529 },
    { lat: 49.1872162, lng: 19.0988746 },
    { lat: 49.1875221, lng: 19.0994562 },
    { lat: 49.1871514, lng: 19.1000049 },
    { lat: 49.1864304, lng: 19.1010709 },
    { lat: 49.1863487, lng: 19.1014744 },
    { lat: 49.1861665, lng: 19.1021201 },
    { lat: 49.1860541, lng: 19.10268 },
    { lat: 49.185885, lng: 19.1032755 },
    { lat: 49.1858295, lng: 19.1041172 },
    { lat: 49.1857754, lng: 19.1048275 },
    { lat: 49.1857752, lng: 19.1061313 },
    { lat: 49.1858264, lng: 19.1069246 },
    { lat: 49.1858174, lng: 19.1077033 },
    { lat: 49.1855905, lng: 19.1079249 },
    { lat: 49.1853449, lng: 19.1081137 },
    { lat: 49.1851775, lng: 19.1085382 },
    { lat: 49.1851454, lng: 19.1086172 },
    { lat: 49.1851264, lng: 19.1086676 },
    { lat: 49.1850879, lng: 19.1094468 },
    { lat: 49.185058, lng: 19.1102386 },
    { lat: 49.1852373, lng: 19.111408 },
    { lat: 49.1852302, lng: 19.1120239 },
    { lat: 49.1851591, lng: 19.1126878 },
    { lat: 49.1850886, lng: 19.1134555 },
    { lat: 49.1850348, lng: 19.1148201 },
    { lat: 49.1849547, lng: 19.1164039 },
    { lat: 49.1848333, lng: 19.1171882 },
    { lat: 49.184591, lng: 19.1188708 },
    { lat: 49.1844931, lng: 19.1193562 },
    { lat: 49.1844365, lng: 19.1198105 },
    { lat: 49.1842004, lng: 19.1203772 },
    { lat: 49.1840653, lng: 19.1212538 },
    { lat: 49.1840161, lng: 19.1218356 },
    { lat: 49.1846137, lng: 19.1235911 },
    { lat: 49.1846503, lng: 19.1236922 },
    { lat: 49.18461, lng: 19.1236748 },
    { lat: 49.1844874, lng: 19.1237947 },
    { lat: 49.1844349, lng: 19.1238036 },
    { lat: 49.1844172, lng: 19.1238131 },
    { lat: 49.1843585, lng: 19.1238108 },
    { lat: 49.1840756, lng: 19.1238805 },
    { lat: 49.1837153, lng: 19.1240473 },
    { lat: 49.1835644, lng: 19.1241442 },
    { lat: 49.1831917, lng: 19.1244522 },
    { lat: 49.1829243, lng: 19.1246996 },
    { lat: 49.1826652, lng: 19.1249114 },
    { lat: 49.1826254, lng: 19.1249466 },
    { lat: 49.1824532, lng: 19.1249759 },
    { lat: 49.1822503, lng: 19.1249753 },
    { lat: 49.1822114, lng: 19.1249639 },
    { lat: 49.1821376, lng: 19.1249553 },
    { lat: 49.1819613, lng: 19.1248962 },
    { lat: 49.1817538, lng: 19.124802 },
    { lat: 49.1814239, lng: 19.1246259 },
    { lat: 49.1813863, lng: 19.1246205 },
    { lat: 49.1812387, lng: 19.1245771 },
    { lat: 49.1811416, lng: 19.1245712 },
    { lat: 49.1810531, lng: 19.1245817 },
    { lat: 49.18101, lng: 19.1245751 },
    { lat: 49.1809579, lng: 19.124617 },
    { lat: 49.1809, lng: 19.1246777 },
    { lat: 49.180739, lng: 19.1248686 },
    { lat: 49.1806412, lng: 19.1251716 },
    { lat: 49.1805395, lng: 19.1255539 },
    { lat: 49.180502, lng: 19.1256674 },
    { lat: 49.1803019, lng: 19.1260674 },
    { lat: 49.1801166, lng: 19.1264261 },
    { lat: 49.1800133, lng: 19.1266414 },
    { lat: 49.1798608, lng: 19.1270043 },
    { lat: 49.1796763, lng: 19.1274238 },
    { lat: 49.1796012, lng: 19.1274828 },
    { lat: 49.1794377, lng: 19.127598 },
    { lat: 49.1790244, lng: 19.1278346 },
    { lat: 49.1788886, lng: 19.1279358 },
    { lat: 49.1787981, lng: 19.1280902 },
    { lat: 49.1786732, lng: 19.1283948 },
    { lat: 49.1785697, lng: 19.1286318 },
    { lat: 49.1785066, lng: 19.1287463 },
    { lat: 49.1782362, lng: 19.1290034 },
    { lat: 49.1781307, lng: 19.1291732 },
    { lat: 49.1780155, lng: 19.1294443 },
    { lat: 49.1778706, lng: 19.1300992 },
    { lat: 49.1777883, lng: 19.1303291 },
    { lat: 49.1770858, lng: 19.1313364 },
    { lat: 49.177065, lng: 19.1313755 },
    { lat: 49.17699, lng: 19.1316377 },
    { lat: 49.1769596, lng: 19.131756 },
    { lat: 49.1769298, lng: 19.1319057 },
    { lat: 49.1769142, lng: 19.1321607 },
    { lat: 49.1768575, lng: 19.1324558 },
    { lat: 49.1768465, lng: 19.132577 },
    { lat: 49.1767788, lng: 19.1326913 },
    { lat: 49.1767564, lng: 19.1327166 },
    { lat: 49.1766275, lng: 19.1328001 },
    { lat: 49.1762946, lng: 19.1327915 },
    { lat: 49.1762228, lng: 19.1327971 },
    { lat: 49.1761865, lng: 19.1328185 },
    { lat: 49.1761635, lng: 19.1328316 },
    { lat: 49.1761277, lng: 19.1328811 },
    { lat: 49.1760979, lng: 19.1328956 },
    { lat: 49.1760627, lng: 19.1329133 },
    { lat: 49.1759381, lng: 19.1329696 },
    { lat: 49.175953, lng: 19.1330418 },
    { lat: 49.1755802, lng: 19.1330496 },
    { lat: 49.1756064, lng: 19.1331951 },
    { lat: 49.1756055, lng: 19.1336354 },
    { lat: 49.1756051, lng: 19.1337918 },
    { lat: 49.1756043, lng: 19.1340754 },
    { lat: 49.175116, lng: 19.1353346 },
    { lat: 49.1750638, lng: 19.1354695 },
    { lat: 49.1746275, lng: 19.1365934 },
    { lat: 49.1745592, lng: 19.1375515 },
    { lat: 49.1745489, lng: 19.1377073 },
    { lat: 49.1744922, lng: 19.1385095 },
    { lat: 49.1745894, lng: 19.1389081 },
    { lat: 49.1746868, lng: 19.139307 },
    { lat: 49.1748782, lng: 19.1398786 },
    { lat: 49.1750705, lng: 19.1404493 },
    { lat: 49.1751537, lng: 19.1407904 },
    { lat: 49.1752368, lng: 19.1411319 },
    { lat: 49.1753524, lng: 19.1425767 },
    { lat: 49.1754099, lng: 19.1431062 },
    { lat: 49.1754265, lng: 19.1432611 },
    { lat: 49.1754672, lng: 19.1436361 },
    { lat: 49.1755454, lng: 19.1442649 },
    { lat: 49.1755858, lng: 19.1445856 },
    { lat: 49.175961, lng: 19.1451342 },
    { lat: 49.1761879, lng: 19.1454662 },
    { lat: 49.1766583, lng: 19.1458668 },
    { lat: 49.1767475, lng: 19.1459431 },
    { lat: 49.1770075, lng: 19.1461643 },
    { lat: 49.1771288, lng: 19.1462673 },
    { lat: 49.1777664, lng: 19.1469584 },
    { lat: 49.1781153, lng: 19.147336 },
    { lat: 49.1784043, lng: 19.147649 },
    { lat: 49.1791629, lng: 19.1484089 },
    { lat: 49.1799213, lng: 19.1491688 },
    { lat: 49.1802775, lng: 19.1495409 },
    { lat: 49.1806339, lng: 19.1499134 },
    { lat: 49.181005, lng: 19.1504971 },
    { lat: 49.1810759, lng: 19.1506098 },
    { lat: 49.181376, lng: 19.1510809 },
    { lat: 49.1816674, lng: 19.1517836 },
    { lat: 49.1819587, lng: 19.1524866 },
    { lat: 49.182185, lng: 19.1536165 },
    { lat: 49.1821213, lng: 19.1549028 },
    { lat: 49.1813836, lng: 19.1591954 },
    { lat: 49.1811667, lng: 19.1604856 },
    { lat: 49.1808821, lng: 19.1635303 },
    { lat: 49.1807496, lng: 19.1654433 },
    { lat: 49.1808169, lng: 19.1683104 },
    { lat: 49.1810467, lng: 19.1697011 },
    { lat: 49.1815345, lng: 19.1708288 },
    { lat: 49.1818802, lng: 19.1714501 },
    { lat: 49.1824947, lng: 19.1729624 },
    { lat: 49.1835092, lng: 19.1751036 },
    { lat: 49.1844445, lng: 19.1769102 },
    { lat: 49.1854026, lng: 19.1786317 },
    { lat: 49.185849, lng: 19.1800255 },
    { lat: 49.1861166, lng: 19.181082 },
    { lat: 49.1866789, lng: 19.185242 },
    { lat: 49.1869014, lng: 19.1877892 },
    { lat: 49.1869815, lng: 19.1890731 },
    { lat: 49.1870645, lng: 19.1900406 },
    { lat: 49.1870181, lng: 19.190761 },
    { lat: 49.1868305, lng: 19.1921027 },
    { lat: 49.1865948, lng: 19.194124 },
    { lat: 49.1864819, lng: 19.1960763 },
    { lat: 49.1864865, lng: 19.1978281 },
    { lat: 49.1869452, lng: 19.1977123 },
    { lat: 49.1874338, lng: 19.1988648 },
    { lat: 49.1875798, lng: 19.199208 },
    { lat: 49.1879485, lng: 19.1999074 },
    { lat: 49.1883419, lng: 19.2007023 },
    { lat: 49.1885227, lng: 19.2010621 },
    { lat: 49.1891895, lng: 19.2018012 },
    { lat: 49.1895633, lng: 19.2018392 },
    { lat: 49.1895992, lng: 19.201883 },
    { lat: 49.1896538, lng: 19.2018935 },
    { lat: 49.1897057, lng: 19.202288 },
    { lat: 49.1897457, lng: 19.2023001 },
    { lat: 49.1903176, lng: 19.2024725 },
    { lat: 49.1905169, lng: 19.2029121 },
    { lat: 49.1909046, lng: 19.2037063 },
    { lat: 49.1908054, lng: 19.203844 },
    { lat: 49.1910501, lng: 19.2053177 },
    { lat: 49.191177, lng: 19.2060825 },
    { lat: 49.1911992, lng: 19.2062141 },
    { lat: 49.1911731, lng: 19.2062559 },
    { lat: 49.1911383, lng: 19.2063077 },
    { lat: 49.1906758, lng: 19.2070213 },
    { lat: 49.1906346, lng: 19.2070785 },
    { lat: 49.1905719, lng: 19.2071598 },
    { lat: 49.1905235, lng: 19.2072286 },
    { lat: 49.1904874, lng: 19.207277 },
    { lat: 49.1901605, lng: 19.2077184 },
    { lat: 49.1901011, lng: 19.2077776 },
    { lat: 49.1900557, lng: 19.2078603 },
    { lat: 49.1900543, lng: 19.2078763 },
    { lat: 49.1900328, lng: 19.2079014 },
    { lat: 49.1900084, lng: 19.2079286 },
    { lat: 49.1899493, lng: 19.2080153 },
    { lat: 49.190232, lng: 19.2084512 },
    { lat: 49.1905127, lng: 19.2086892 },
    { lat: 49.1905979, lng: 19.2087503 },
    { lat: 49.1906749, lng: 19.2088051 },
    { lat: 49.1908467, lng: 19.2089281 },
    { lat: 49.1910242, lng: 19.209079 },
    { lat: 49.1911707, lng: 19.2091966 },
    { lat: 49.1912726, lng: 19.2092781 },
    { lat: 49.1914417, lng: 19.2094199 },
    { lat: 49.1916565, lng: 19.2097121 },
    { lat: 49.1917026, lng: 19.2097812 },
    { lat: 49.1919417, lng: 19.21011 },
    { lat: 49.1922653, lng: 19.2105573 },
    { lat: 49.1925039, lng: 19.210853 },
    { lat: 49.1925769, lng: 19.210989 },
    { lat: 49.1926198, lng: 19.2110379 },
    { lat: 49.1927655, lng: 19.2112089 },
    { lat: 49.1928254, lng: 19.21128 },
    { lat: 49.1929252, lng: 19.2114094 },
    { lat: 49.1929743, lng: 19.2116096 },
    { lat: 49.1930053, lng: 19.2117388 },
    { lat: 49.1930706, lng: 19.2123448 },
    { lat: 49.1933599, lng: 19.2128972 },
    { lat: 49.1933943, lng: 19.2138951 },
    { lat: 49.1933665, lng: 19.2145584 },
    { lat: 49.1933502, lng: 19.2148273 },
    { lat: 49.1933722, lng: 19.2152115 },
    { lat: 49.1933861, lng: 19.2154805 },
    { lat: 49.1934059, lng: 19.2158181 },
    { lat: 49.1934147, lng: 19.2159559 },
    { lat: 49.1934137, lng: 19.2160724 },
    { lat: 49.1934133, lng: 19.2161831 },
    { lat: 49.1934126, lng: 19.2162405 },
    { lat: 49.1933806, lng: 19.2164635 },
    { lat: 49.1933487, lng: 19.2166887 },
    { lat: 49.1933362, lng: 19.2168069 },
    { lat: 49.1933057, lng: 19.2170125 },
    { lat: 49.1932859, lng: 19.2171412 },
    { lat: 49.1932741, lng: 19.2172239 },
    { lat: 49.1932488, lng: 19.2174246 },
    { lat: 49.1932068, lng: 19.2177695 },
    { lat: 49.1931566, lng: 19.218111 },
    { lat: 49.1931288, lng: 19.2183036 },
    { lat: 49.1931149, lng: 19.2183863 },
    { lat: 49.1931017, lng: 19.2184659 },
    { lat: 49.1930877, lng: 19.2185475 },
    { lat: 49.1930678, lng: 19.2186741 },
    { lat: 49.1930296, lng: 19.2189108 },
    { lat: 49.1930166, lng: 19.2189933 },
  ],
  SedliackaDubová: [
    { lat: 49.2327624, lng: 19.4395045 },
    { lat: 49.2328513, lng: 19.4400376 },
    { lat: 49.2328623, lng: 19.4401017 },
    { lat: 49.2328733, lng: 19.4401719 },
    { lat: 49.2329487, lng: 19.4406332 },
    { lat: 49.2329675, lng: 19.4407232 },
    { lat: 49.2329775, lng: 19.4407715 },
    { lat: 49.2330737, lng: 19.4413402 },
    { lat: 49.2329822, lng: 19.4424794 },
    { lat: 49.2330433, lng: 19.4433429 },
    { lat: 49.2334257, lng: 19.443376 },
    { lat: 49.2336926, lng: 19.443397 },
    { lat: 49.2340918, lng: 19.4431611 },
    { lat: 49.2342678, lng: 19.4428956 },
    { lat: 49.2353063, lng: 19.442571 },
    { lat: 49.2357308, lng: 19.4425646 },
    { lat: 49.2359011, lng: 19.4428102 },
    { lat: 49.2360359, lng: 19.4428722 },
    { lat: 49.2361102, lng: 19.4428945 },
    { lat: 49.2362436, lng: 19.4429778 },
    { lat: 49.2363817, lng: 19.4431862 },
    { lat: 49.2364459, lng: 19.443431 },
    { lat: 49.2364061, lng: 19.4439357 },
    { lat: 49.2364248, lng: 19.4440217 },
    { lat: 49.2371393, lng: 19.4452192 },
    { lat: 49.2372682, lng: 19.4451581 },
    { lat: 49.2373836, lng: 19.4448496 },
    { lat: 49.2374082, lng: 19.4448004 },
    { lat: 49.2375149, lng: 19.4445931 },
    { lat: 49.2378296, lng: 19.4441882 },
    { lat: 49.2384012, lng: 19.4444127 },
    { lat: 49.2391134, lng: 19.4443692 },
    { lat: 49.2393596, lng: 19.4443315 },
    { lat: 49.2393887, lng: 19.4443262 },
    { lat: 49.2398381, lng: 19.4448343 },
    { lat: 49.2401626, lng: 19.4449793 },
    { lat: 49.240618, lng: 19.4450089 },
    { lat: 49.2414167, lng: 19.4449031 },
    { lat: 49.2417393, lng: 19.4444764 },
    { lat: 49.241869, lng: 19.4445028 },
    { lat: 49.2420931, lng: 19.4445494 },
    { lat: 49.2422275, lng: 19.444602 },
    { lat: 49.2423535, lng: 19.4445488 },
    { lat: 49.2423699, lng: 19.4445412 },
    { lat: 49.2424607, lng: 19.4445019 },
    { lat: 49.2427415, lng: 19.4445802 },
    { lat: 49.2430142, lng: 19.4446562 },
    { lat: 49.2435024, lng: 19.4448085 },
    { lat: 49.2436153, lng: 19.4448392 },
    { lat: 49.2440493, lng: 19.4449613 },
    { lat: 49.244385, lng: 19.4450593 },
    { lat: 49.2446473, lng: 19.4451491 },
    { lat: 49.2447675, lng: 19.44517 },
    { lat: 49.245552, lng: 19.4452699 },
    { lat: 49.2458794, lng: 19.4454063 },
    { lat: 49.2461783, lng: 19.4455339 },
    { lat: 49.2462382, lng: 19.4455372 },
    { lat: 49.246401, lng: 19.4455507 },
    { lat: 49.2471073, lng: 19.4453524 },
    { lat: 49.2472857, lng: 19.445308 },
    { lat: 49.2477009, lng: 19.4451973 },
    { lat: 49.2482443, lng: 19.4450353 },
    { lat: 49.2483045, lng: 19.4449945 },
    { lat: 49.2488667, lng: 19.4446256 },
    { lat: 49.2492413, lng: 19.4445424 },
    { lat: 49.2495691, lng: 19.4444667 },
    { lat: 49.2498922, lng: 19.4443407 },
    { lat: 49.2506097, lng: 19.4433552 },
    { lat: 49.2506349, lng: 19.4433132 },
    { lat: 49.2508967, lng: 19.4428831 },
    { lat: 49.2510704, lng: 19.4425936 },
    { lat: 49.2512249, lng: 19.4424005 },
    { lat: 49.2515742, lng: 19.4419636 },
    { lat: 49.2520332, lng: 19.4418965 },
    { lat: 49.2523217, lng: 19.4418496 },
    { lat: 49.2526648, lng: 19.4419114 },
    { lat: 49.2528645, lng: 19.4417918 },
    { lat: 49.2529896, lng: 19.4417199 },
    { lat: 49.2532893, lng: 19.4418438 },
    { lat: 49.2538389, lng: 19.4411061 },
    { lat: 49.254295, lng: 19.4404628 },
    { lat: 49.2544344, lng: 19.4402346 },
    { lat: 49.2544652, lng: 19.4401978 },
    { lat: 49.2546039, lng: 19.4400313 },
    { lat: 49.2546358, lng: 19.4399842 },
    { lat: 49.2546894, lng: 19.4399027 },
    { lat: 49.2549551, lng: 19.4394585 },
    { lat: 49.2544369, lng: 19.4382186 },
    { lat: 49.2543836, lng: 19.4379892 },
    { lat: 49.2543652, lng: 19.4379085 },
    { lat: 49.254216, lng: 19.4372659 },
    { lat: 49.2541522, lng: 19.4372995 },
    { lat: 49.2540874, lng: 19.4373339 },
    { lat: 49.2540114, lng: 19.4367624 },
    { lat: 49.2540571, lng: 19.436755 },
    { lat: 49.2542339, lng: 19.4367265 },
    { lat: 49.2542821, lng: 19.4367188 },
    { lat: 49.2543178, lng: 19.4367128 },
    { lat: 49.2545825, lng: 19.4366828 },
    { lat: 49.2548412, lng: 19.4366517 },
    { lat: 49.2555036, lng: 19.4365763 },
    { lat: 49.2556532, lng: 19.4365681 },
    { lat: 49.2558303, lng: 19.436549 },
    { lat: 49.2558867, lng: 19.436548 },
    { lat: 49.2561363, lng: 19.4364857 },
    { lat: 49.2566069, lng: 19.4363852 },
    { lat: 49.2571702, lng: 19.436305 },
    { lat: 49.2575517, lng: 19.4361765 },
    { lat: 49.2583155, lng: 19.4359765 },
    { lat: 49.2592516, lng: 19.4359683 },
    { lat: 49.259374, lng: 19.4359605 },
    { lat: 49.2594786, lng: 19.4359539 },
    { lat: 49.2597437, lng: 19.435938 },
    { lat: 49.2605563, lng: 19.4358175 },
    { lat: 49.2606695, lng: 19.435635 },
    { lat: 49.2611896, lng: 19.4345298 },
    { lat: 49.2613163, lng: 19.433976 },
    { lat: 49.261946, lng: 19.4336801 },
    { lat: 49.2621166, lng: 19.4335953 },
    { lat: 49.2626089, lng: 19.4335184 },
    { lat: 49.2627853, lng: 19.4339936 },
    { lat: 49.2629006, lng: 19.4340053 },
    { lat: 49.2631129, lng: 19.4338677 },
    { lat: 49.2632376, lng: 19.4338849 },
    { lat: 49.2635717, lng: 19.4338215 },
    { lat: 49.2638399, lng: 19.4337759 },
    { lat: 49.2642054, lng: 19.433708 },
    { lat: 49.2643011, lng: 19.4337059 },
    { lat: 49.2646125, lng: 19.4336936 },
    { lat: 49.2657864, lng: 19.4337384 },
    { lat: 49.266162, lng: 19.4337408 },
    { lat: 49.266569, lng: 19.4337254 },
    { lat: 49.2671521, lng: 19.4336404 },
    { lat: 49.267454, lng: 19.4338777 },
    { lat: 49.2680202, lng: 19.4340162 },
    { lat: 49.2686675, lng: 19.4341975 },
    { lat: 49.2689981, lng: 19.4341575 },
    { lat: 49.2691385, lng: 19.4341488 },
    { lat: 49.2694232, lng: 19.4341369 },
    { lat: 49.2697092, lng: 19.4340887 },
    { lat: 49.2699265, lng: 19.434058 },
    { lat: 49.2701332, lng: 19.4339942 },
    { lat: 49.2704121, lng: 19.4339075 },
    { lat: 49.2705163, lng: 19.4338735 },
    { lat: 49.2708674, lng: 19.4338389 },
    { lat: 49.2711402, lng: 19.4338148 },
    { lat: 49.271289, lng: 19.4338652 },
    { lat: 49.2714721, lng: 19.4340468 },
    { lat: 49.2717004, lng: 19.4341842 },
    { lat: 49.2717953, lng: 19.434241 },
    { lat: 49.2720533, lng: 19.4343622 },
    { lat: 49.2723402, lng: 19.4344987 },
    { lat: 49.2726258, lng: 19.4344621 },
    { lat: 49.2727386, lng: 19.4344146 },
    { lat: 49.2728624, lng: 19.4343648 },
    { lat: 49.2730351, lng: 19.4342956 },
    { lat: 49.2735035, lng: 19.4340686 },
    { lat: 49.2737824, lng: 19.4339361 },
    { lat: 49.2738684, lng: 19.4338928 },
    { lat: 49.2739972, lng: 19.4338797 },
    { lat: 49.274154, lng: 19.433906 },
    { lat: 49.2742154, lng: 19.4339164 },
    { lat: 49.2744706, lng: 19.4339289 },
    { lat: 49.2745662, lng: 19.4339236 },
    { lat: 49.2746096, lng: 19.4339374 },
    { lat: 49.2747585, lng: 19.4339664 },
    { lat: 49.2750522, lng: 19.4340275 },
    { lat: 49.2750843, lng: 19.4340417 },
    { lat: 49.2753851, lng: 19.4341391 },
    { lat: 49.2754244, lng: 19.4341623 },
    { lat: 49.2755762, lng: 19.4342495 },
    { lat: 49.2757224, lng: 19.434193 },
    { lat: 49.276029, lng: 19.4343443 },
    { lat: 49.2762074, lng: 19.4344235 },
    { lat: 49.2762641, lng: 19.4344604 },
    { lat: 49.2763868, lng: 19.4346261 },
    { lat: 49.2766836, lng: 19.4347085 },
    { lat: 49.2767604, lng: 19.4347356 },
    { lat: 49.2768268, lng: 19.4348089 },
    { lat: 49.2771665, lng: 19.4343316 },
    { lat: 49.2772703, lng: 19.434529 },
    { lat: 49.2776812, lng: 19.4342288 },
    { lat: 49.2779489, lng: 19.4340472 },
    { lat: 49.2783809, lng: 19.4338613 },
    { lat: 49.2786966, lng: 19.4337193 },
    { lat: 49.2786825, lng: 19.4335866 },
    { lat: 49.2792318, lng: 19.433501 },
    { lat: 49.2797826, lng: 19.4334444 },
    { lat: 49.2798297, lng: 19.4332931 },
    { lat: 49.280262, lng: 19.4332627 },
    { lat: 49.2803454, lng: 19.4333133 },
    { lat: 49.280786, lng: 19.4330441 },
    { lat: 49.2811692, lng: 19.4328486 },
    { lat: 49.2813964, lng: 19.4329883 },
    { lat: 49.2821065, lng: 19.43282 },
    { lat: 49.2822929, lng: 19.4327107 },
    { lat: 49.2823771, lng: 19.4326561 },
    { lat: 49.2825758, lng: 19.4325434 },
    { lat: 49.2826867, lng: 19.4324787 },
    { lat: 49.2828833, lng: 19.4322687 },
    { lat: 49.2832096, lng: 19.4320895 },
    { lat: 49.2835238, lng: 19.4317448 },
    { lat: 49.2837842, lng: 19.4315033 },
    { lat: 49.2844821, lng: 19.430827 },
    { lat: 49.2846807, lng: 19.4303471 },
    { lat: 49.2851038, lng: 19.4297037 },
    { lat: 49.2853025, lng: 19.4295112 },
    { lat: 49.2858298, lng: 19.4290994 },
    { lat: 49.2866437, lng: 19.4278625 },
    { lat: 49.2868217, lng: 19.4272805 },
    { lat: 49.2869169, lng: 19.4271432 },
    { lat: 49.2873403, lng: 19.426456 },
    { lat: 49.2873821, lng: 19.426359 },
    { lat: 49.287477, lng: 19.4261481 },
    { lat: 49.2877093, lng: 19.4257349 },
    { lat: 49.2877841, lng: 19.4256012 },
    { lat: 49.2880772, lng: 19.4249941 },
    { lat: 49.2882824, lng: 19.4247027 },
    { lat: 49.2884029, lng: 19.4245312 },
    { lat: 49.2889261, lng: 19.423832 },
    { lat: 49.2889802, lng: 19.4237619 },
    { lat: 49.289662, lng: 19.4231045 },
    { lat: 49.2898669, lng: 19.4229556 },
    { lat: 49.2902729, lng: 19.4226471 },
    { lat: 49.2908488, lng: 19.4222079 },
    { lat: 49.2908988, lng: 19.4221971 },
    { lat: 49.2909706, lng: 19.4221879 },
    { lat: 49.2912808, lng: 19.4220013 },
    { lat: 49.2923522, lng: 19.4214128 },
    { lat: 49.2926968, lng: 19.4213585 },
    { lat: 49.293189, lng: 19.4209883 },
    { lat: 49.2933532, lng: 19.420955 },
    { lat: 49.2934646, lng: 19.4209296 },
    { lat: 49.2941721, lng: 19.420853 },
    { lat: 49.294237, lng: 19.4208438 },
    { lat: 49.2944199, lng: 19.4210706 },
    { lat: 49.29471, lng: 19.4211053 },
    { lat: 49.2952745, lng: 19.4211789 },
    { lat: 49.2956159, lng: 19.4209842 },
    { lat: 49.2960187, lng: 19.4206792 },
    { lat: 49.296116, lng: 19.4206112 },
    { lat: 49.2965101, lng: 19.4202187 },
    { lat: 49.2971215, lng: 19.4195984 },
    { lat: 49.2971968, lng: 19.4195227 },
    { lat: 49.2973031, lng: 19.4194318 },
    { lat: 49.2975808, lng: 19.4191778 },
    { lat: 49.2981946, lng: 19.418924 },
    { lat: 49.2986275, lng: 19.418662 },
    { lat: 49.2990068, lng: 19.4184335 },
    { lat: 49.2993065, lng: 19.4180945 },
    { lat: 49.2997299, lng: 19.4178692 },
    { lat: 49.3001273, lng: 19.4174203 },
    { lat: 49.3002732, lng: 19.4172377 },
    { lat: 49.3004209, lng: 19.4170473 },
    { lat: 49.3009589, lng: 19.4161312 },
    { lat: 49.3015328, lng: 19.4156545 },
    { lat: 49.3019924, lng: 19.4155043 },
    { lat: 49.3021692, lng: 19.4154573 },
    { lat: 49.3026861, lng: 19.4151569 },
    { lat: 49.3030872, lng: 19.4148914 },
    { lat: 49.3029474, lng: 19.4136221 },
    { lat: 49.3025901, lng: 19.4128159 },
    { lat: 49.3025984, lng: 19.4122413 },
    { lat: 49.3030995, lng: 19.4119212 },
    { lat: 49.3034589, lng: 19.4116771 },
    { lat: 49.3040284, lng: 19.411056 },
    { lat: 49.3045952, lng: 19.4106912 },
    { lat: 49.3052819, lng: 19.4103362 },
    { lat: 49.3058931, lng: 19.4099301 },
    { lat: 49.30665, lng: 19.409072 },
    { lat: 49.3076573, lng: 19.4080292 },
    { lat: 49.3081537, lng: 19.407313 },
    { lat: 49.3085532, lng: 19.4066457 },
    { lat: 49.308921, lng: 19.405882 },
    { lat: 49.308411, lng: 19.404434 },
    { lat: 49.307752, lng: 19.403715 },
    { lat: 49.307426, lng: 19.403359 },
    { lat: 49.306459, lng: 19.402401 },
    { lat: 49.305519, lng: 19.401686 },
    { lat: 49.305406, lng: 19.40159 },
    { lat: 49.304587, lng: 19.400911 },
    { lat: 49.303567, lng: 19.400184 },
    { lat: 49.303044, lng: 19.3997 },
    { lat: 49.302337, lng: 19.39884 },
    { lat: 49.302203, lng: 19.398678 },
    { lat: 49.302103, lng: 19.398556 },
    { lat: 49.301505, lng: 19.397728 },
    { lat: 49.3006, lng: 19.396186 },
    { lat: 49.300456, lng: 19.395728 },
    { lat: 49.3003394, lng: 19.3957253 },
    { lat: 49.3001796, lng: 19.3960658 },
    { lat: 49.2995961, lng: 19.3960444 },
    { lat: 49.299188, lng: 19.3964101 },
    { lat: 49.2990065, lng: 19.3967209 },
    { lat: 49.2986298, lng: 19.3970742 },
    { lat: 49.2982796, lng: 19.3975661 },
    { lat: 49.2979446, lng: 19.3979238 },
    { lat: 49.2977253, lng: 19.398295 },
    { lat: 49.2974549, lng: 19.3983208 },
    { lat: 49.29725, lng: 19.3983268 },
    { lat: 49.29682, lng: 19.3985761 },
    { lat: 49.2965925, lng: 19.3990398 },
    { lat: 49.2964464, lng: 19.3994135 },
    { lat: 49.2962078, lng: 19.4004355 },
    { lat: 49.2959376, lng: 19.4010949 },
    { lat: 49.2957084, lng: 19.4014538 },
    { lat: 49.2952922, lng: 19.401226 },
    { lat: 49.2948305, lng: 19.4014025 },
    { lat: 49.2944509, lng: 19.4015979 },
    { lat: 49.294266, lng: 19.4019584 },
    { lat: 49.2938739, lng: 19.4023209 },
    { lat: 49.2934346, lng: 19.4029599 },
    { lat: 49.2932397, lng: 19.4030082 },
    { lat: 49.2931954, lng: 19.4031942 },
    { lat: 49.2932525, lng: 19.4037411 },
    { lat: 49.2928675, lng: 19.4037753 },
    { lat: 49.2924908, lng: 19.4041079 },
    { lat: 49.292261, lng: 19.4044487 },
    { lat: 49.2920523, lng: 19.404758 },
    { lat: 49.2915931, lng: 19.4051833 },
    { lat: 49.2912385, lng: 19.4054326 },
    { lat: 49.2910088, lng: 19.4056828 },
    { lat: 49.2907749, lng: 19.4057403 },
    { lat: 49.29034, lng: 19.4058634 },
    { lat: 49.2898287, lng: 19.405603 },
    { lat: 49.2895007, lng: 19.4056743 },
    { lat: 49.2894148, lng: 19.4060562 },
    { lat: 49.2889513, lng: 19.4060209 },
    { lat: 49.2881236, lng: 19.4065951 },
    { lat: 49.288158, lng: 19.4069174 },
    { lat: 49.2878649, lng: 19.407038 },
    { lat: 49.2870157, lng: 19.4072914 },
    { lat: 49.2865093, lng: 19.4074059 },
    { lat: 49.2861708, lng: 19.4070517 },
    { lat: 49.2858354, lng: 19.407696 },
    { lat: 49.2855608, lng: 19.4079441 },
    { lat: 49.285046, lng: 19.4079946 },
    { lat: 49.284493, lng: 19.4080769 },
    { lat: 49.2841214, lng: 19.4081984 },
    { lat: 49.2838218, lng: 19.4083223 },
    { lat: 49.2835338, lng: 19.4084314 },
    { lat: 49.2830343, lng: 19.4089837 },
    { lat: 49.282685, lng: 19.4082553 },
    { lat: 49.2823337, lng: 19.4080422 },
    { lat: 49.2821355, lng: 19.408046 },
    { lat: 49.2816956, lng: 19.4079887 },
    { lat: 49.2815409, lng: 19.4078809 },
    { lat: 49.2812379, lng: 19.4078849 },
    { lat: 49.2808656, lng: 19.4078524 },
    { lat: 49.2808417, lng: 19.4084556 },
    { lat: 49.2809215, lng: 19.4088371 },
    { lat: 49.2810239, lng: 19.409223 },
    { lat: 49.280886, lng: 19.4093589 },
    { lat: 49.2805648, lng: 19.4088999 },
    { lat: 49.2804659, lng: 19.4089515 },
    { lat: 49.2804576, lng: 19.4097446 },
    { lat: 49.2804516, lng: 19.4101758 },
    { lat: 49.2801548, lng: 19.4106021 },
    { lat: 49.2800164, lng: 19.410703 },
    { lat: 49.2798542, lng: 19.4107289 },
    { lat: 49.2794356, lng: 19.4106451 },
    { lat: 49.2792541, lng: 19.4107629 },
    { lat: 49.2791054, lng: 19.4108368 },
    { lat: 49.2789842, lng: 19.4109473 },
    { lat: 49.2789156, lng: 19.4110919 },
    { lat: 49.2784624, lng: 19.4115283 },
    { lat: 49.2782387, lng: 19.4121462 },
    { lat: 49.2781194, lng: 19.4123631 },
    { lat: 49.2780314, lng: 19.4124372 },
    { lat: 49.2779089, lng: 19.4124429 },
    { lat: 49.2777233, lng: 19.4125244 },
    { lat: 49.2773884, lng: 19.4128595 },
    { lat: 49.2771057, lng: 19.4132492 },
    { lat: 49.276924, lng: 19.4131953 },
    { lat: 49.2765427, lng: 19.413696 },
    { lat: 49.2762736, lng: 19.4140189 },
    { lat: 49.2760568, lng: 19.4142275 },
    { lat: 49.2755884, lng: 19.4140906 },
    { lat: 49.2752967, lng: 19.4138945 },
    { lat: 49.2751538, lng: 19.4137815 },
    { lat: 49.2750063, lng: 19.4135402 },
    { lat: 49.2747559, lng: 19.4132681 },
    { lat: 49.2746406, lng: 19.4131633 },
    { lat: 49.2742651, lng: 19.4127197 },
    { lat: 49.2740033, lng: 19.41242 },
    { lat: 49.2738898, lng: 19.4120866 },
    { lat: 49.2738207, lng: 19.4119317 },
    { lat: 49.2733409, lng: 19.4121284 },
    { lat: 49.2731078, lng: 19.4121136 },
    { lat: 49.2728048, lng: 19.4122089 },
    { lat: 49.2726756, lng: 19.4122189 },
    { lat: 49.272446, lng: 19.4122715 },
    { lat: 49.2721436, lng: 19.4122376 },
    { lat: 49.2719211, lng: 19.4121307 },
    { lat: 49.2715572, lng: 19.4118183 },
    { lat: 49.2711703, lng: 19.4116628 },
    { lat: 49.2707631, lng: 19.4117268 },
    { lat: 49.2703794, lng: 19.4115439 },
    { lat: 49.2701503, lng: 19.4109531 },
    { lat: 49.2697641, lng: 19.4104255 },
    { lat: 49.2694793, lng: 19.4096827 },
    { lat: 49.2692308, lng: 19.409644 },
    { lat: 49.269055, lng: 19.4096923 },
    { lat: 49.2688978, lng: 19.4095563 },
    { lat: 49.2675288, lng: 19.4097769 },
    { lat: 49.2670078, lng: 19.4100856 },
    { lat: 49.2665947, lng: 19.4102148 },
    { lat: 49.2663083, lng: 19.410332 },
    { lat: 49.2661855, lng: 19.4103565 },
    { lat: 49.2659143, lng: 19.4104613 },
    { lat: 49.2657997, lng: 19.4104701 },
    { lat: 49.2656323, lng: 19.4105309 },
    { lat: 49.2654555, lng: 19.4106041 },
    { lat: 49.2651621, lng: 19.4107647 },
    { lat: 49.2648258, lng: 19.4109502 },
    { lat: 49.264201, lng: 19.411277 },
    { lat: 49.2641483, lng: 19.4113051 },
    { lat: 49.2640865, lng: 19.4113384 },
    { lat: 49.2640789, lng: 19.4113411 },
    { lat: 49.2636959, lng: 19.4114428 },
    { lat: 49.2636319, lng: 19.4114938 },
    { lat: 49.2630196, lng: 19.4117019 },
    { lat: 49.2628831, lng: 19.4117481 },
    { lat: 49.2628343, lng: 19.4117653 },
    { lat: 49.2619378, lng: 19.4121168 },
    { lat: 49.261219, lng: 19.4121928 },
    { lat: 49.2606387, lng: 19.4121904 },
    { lat: 49.2606882, lng: 19.4130642 },
    { lat: 49.2607232, lng: 19.4137474 },
    { lat: 49.2607555, lng: 19.4147836 },
    { lat: 49.2599496, lng: 19.4147518 },
    { lat: 49.2595041, lng: 19.4147463 },
    { lat: 49.2588321, lng: 19.4147213 },
    { lat: 49.2585617, lng: 19.414982 },
    { lat: 49.2584351, lng: 19.4151467 },
    { lat: 49.2582531, lng: 19.4153896 },
    { lat: 49.258101, lng: 19.4156141 },
    { lat: 49.2579292, lng: 19.4158896 },
    { lat: 49.2578879, lng: 19.416 },
    { lat: 49.25778, lng: 19.4165914 },
    { lat: 49.2577322, lng: 19.4170414 },
    { lat: 49.2576947, lng: 19.4171605 },
    { lat: 49.2575589, lng: 19.4175367 },
    { lat: 49.2575043, lng: 19.4179372 },
    { lat: 49.2573854, lng: 19.4181893 },
    { lat: 49.2570186, lng: 19.4186146 },
    { lat: 49.2569544, lng: 19.4186403 },
    { lat: 49.2568259, lng: 19.418787 },
    { lat: 49.2566974, lng: 19.4189037 },
    { lat: 49.2566217, lng: 19.418927 },
    { lat: 49.2565871, lng: 19.4188615 },
    { lat: 49.2564659, lng: 19.4188721 },
    { lat: 49.2561789, lng: 19.4189508 },
    { lat: 49.2557623, lng: 19.4192231 },
    { lat: 49.2556918, lng: 19.4193581 },
    { lat: 49.255457, lng: 19.4195649 },
    { lat: 49.2552598, lng: 19.4195891 },
    { lat: 49.2552525, lng: 19.4194835 },
    { lat: 49.2549907, lng: 19.4195248 },
    { lat: 49.2548107, lng: 19.4196002 },
    { lat: 49.2545021, lng: 19.4196563 },
    { lat: 49.2543489, lng: 19.4196328 },
    { lat: 49.2542834, lng: 19.4194321 },
    { lat: 49.2539799, lng: 19.4192056 },
    { lat: 49.2538167, lng: 19.4191388 },
    { lat: 49.2537749, lng: 19.4193537 },
    { lat: 49.2535414, lng: 19.4200044 },
    { lat: 49.2534179, lng: 19.421447 },
    { lat: 49.2533212, lng: 19.4214286 },
    { lat: 49.2530486, lng: 19.4213819 },
    { lat: 49.2524965, lng: 19.4214817 },
    { lat: 49.2521258, lng: 19.4214121 },
    { lat: 49.2516864, lng: 19.4210711 },
    { lat: 49.2515554, lng: 19.4209446 },
    { lat: 49.2512616, lng: 19.4207787 },
    { lat: 49.2507342, lng: 19.4202175 },
    { lat: 49.2502067, lng: 19.419921 },
    { lat: 49.2499517, lng: 19.4197931 },
    { lat: 49.2492637, lng: 19.4197341 },
    { lat: 49.2490389, lng: 19.4197692 },
    { lat: 49.2489792, lng: 19.4197446 },
    { lat: 49.2489154, lng: 19.4197555 },
    { lat: 49.2486406, lng: 19.4201228 },
    { lat: 49.2484982, lng: 19.4201887 },
    { lat: 49.2483287, lng: 19.4202958 },
    { lat: 49.2481844, lng: 19.4204427 },
    { lat: 49.2480692, lng: 19.4205814 },
    { lat: 49.2480779, lng: 19.4208409 },
    { lat: 49.2480567, lng: 19.4211368 },
    { lat: 49.24798, lng: 19.4214037 },
    { lat: 49.247876, lng: 19.4217878 },
    { lat: 49.2478231, lng: 19.4220327 },
    { lat: 49.2477011, lng: 19.4221209 },
    { lat: 49.2474349, lng: 19.4221904 },
    { lat: 49.2471352, lng: 19.4223379 },
    { lat: 49.2470235, lng: 19.4224084 },
    { lat: 49.2467971, lng: 19.4226024 },
    { lat: 49.2466985, lng: 19.4227577 },
    { lat: 49.2465987, lng: 19.4229625 },
    { lat: 49.2465336, lng: 19.4231434 },
    { lat: 49.2465495, lng: 19.4232167 },
    { lat: 49.2459993, lng: 19.4238168 },
    { lat: 49.2458615, lng: 19.4239832 },
    { lat: 49.2458152, lng: 19.4240798 },
    { lat: 49.245805, lng: 19.4241754 },
    { lat: 49.2457239, lng: 19.4243198 },
    { lat: 49.245629, lng: 19.4243156 },
    { lat: 49.2454715, lng: 19.4244166 },
    { lat: 49.2450985, lng: 19.424832 },
    { lat: 49.244792, lng: 19.4252453 },
    { lat: 49.2446349, lng: 19.4253809 },
    { lat: 49.2443049, lng: 19.4256026 },
    { lat: 49.2441414, lng: 19.4257689 },
    { lat: 49.2440972, lng: 19.4258588 },
    { lat: 49.2440123, lng: 19.4259747 },
    { lat: 49.2436435, lng: 19.4258923 },
    { lat: 49.2430504, lng: 19.425644 },
    { lat: 49.2428794, lng: 19.4255525 },
    { lat: 49.2428775, lng: 19.4251434 },
    { lat: 49.2426427, lng: 19.4251622 },
    { lat: 49.2423584, lng: 19.4252525 },
    { lat: 49.2421713, lng: 19.4252719 },
    { lat: 49.242034, lng: 19.4251833 },
    { lat: 49.2418389, lng: 19.4251055 },
    { lat: 49.2414487, lng: 19.4251931 },
    { lat: 49.2412654, lng: 19.4250816 },
    { lat: 49.2408269, lng: 19.4249581 },
    { lat: 49.2404034, lng: 19.4251787 },
    { lat: 49.2401867, lng: 19.4253175 },
    { lat: 49.2401047, lng: 19.4254184 },
    { lat: 49.2400018, lng: 19.4258272 },
    { lat: 49.239943, lng: 19.4258949 },
    { lat: 49.2399167, lng: 19.4260297 },
    { lat: 49.239948, lng: 19.4264151 },
    { lat: 49.2399836, lng: 19.4267257 },
    { lat: 49.2400546, lng: 19.4271111 },
    { lat: 49.2401229, lng: 19.4274189 },
    { lat: 49.2401157, lng: 19.4276051 },
    { lat: 49.2399701, lng: 19.4275498 },
    { lat: 49.2398518, lng: 19.427525 },
    { lat: 49.2397214, lng: 19.4274125 },
    { lat: 49.239602, lng: 19.4273421 },
    { lat: 49.2394543, lng: 19.4272423 },
    { lat: 49.2394303, lng: 19.4272341 },
    { lat: 49.2394007, lng: 19.4272275 },
    { lat: 49.2394161, lng: 19.4272688 },
    { lat: 49.2394153, lng: 19.4272977 },
    { lat: 49.239248, lng: 19.4274334 },
    { lat: 49.239198, lng: 19.4274947 },
    { lat: 49.2391638, lng: 19.4275885 },
    { lat: 49.239227, lng: 19.4278309 },
    { lat: 49.2392505, lng: 19.4280728 },
    { lat: 49.2391834, lng: 19.4282041 },
    { lat: 49.2390584, lng: 19.4286219 },
    { lat: 49.2390296, lng: 19.4287743 },
    { lat: 49.2389307, lng: 19.4289995 },
    { lat: 49.2387875, lng: 19.4292941 },
    { lat: 49.2386548, lng: 19.4292742 },
    { lat: 49.2385334, lng: 19.4295949 },
    { lat: 49.2384999, lng: 19.4297009 },
    { lat: 49.2383847, lng: 19.4298655 },
    { lat: 49.2383652, lng: 19.4300057 },
    { lat: 49.2383008, lng: 19.4301447 },
    { lat: 49.2380709, lng: 19.4304381 },
    { lat: 49.2379214, lng: 19.4305689 },
    { lat: 49.2378075, lng: 19.4306844 },
    { lat: 49.2376152, lng: 19.4307192 },
    { lat: 49.2375036, lng: 19.4306245 },
    { lat: 49.2374143, lng: 19.4307592 },
    { lat: 49.2373217, lng: 19.4308778 },
    { lat: 49.2372214, lng: 19.4310981 },
    { lat: 49.237104, lng: 19.4311883 },
    { lat: 49.2370015, lng: 19.4312127 },
    { lat: 49.2369835, lng: 19.4313398 },
    { lat: 49.2368678, lng: 19.4315185 },
    { lat: 49.2367654, lng: 19.431566 },
    { lat: 49.2367148, lng: 19.4317899 },
    { lat: 49.2367667, lng: 19.4319874 },
    { lat: 49.2367468, lng: 19.4321399 },
    { lat: 49.2367636, lng: 19.4324042 },
    { lat: 49.2367639, lng: 19.4332188 },
    { lat: 49.2366947, lng: 19.4334729 },
    { lat: 49.2366526, lng: 19.433538 },
    { lat: 49.2365925, lng: 19.433776 },
    { lat: 49.2366097, lng: 19.4338765 },
    { lat: 49.2365959, lng: 19.4340432 },
    { lat: 49.2366301, lng: 19.4341506 },
    { lat: 49.2366336, lng: 19.4342713 },
    { lat: 49.2365936, lng: 19.434311 },
    { lat: 49.2360178, lng: 19.4348682 },
    { lat: 49.2355883, lng: 19.4352066 },
    { lat: 49.2354261, lng: 19.4353539 },
    { lat: 49.2349411, lng: 19.4359066 },
    { lat: 49.2345131, lng: 19.4364241 },
    { lat: 49.2341406, lng: 19.4368457 },
    { lat: 49.2343811, lng: 19.4377115 },
    { lat: 49.2336613, lng: 19.4379095 },
    { lat: 49.2335099, lng: 19.4379752 },
    { lat: 49.2337247, lng: 19.4389649 },
    { lat: 49.2331487, lng: 19.4393043 },
    { lat: 49.2327624, lng: 19.4395045 },
  ],
  VyšnýKubín: [
    { lat: 49.1895254, lng: 19.2940204 },
    { lat: 49.1887815, lng: 19.2935182 },
    { lat: 49.1875807, lng: 19.2936946 },
    { lat: 49.1876196, lng: 19.2933538 },
    { lat: 49.1879414, lng: 19.2921299 },
    { lat: 49.1867814, lng: 19.2915784 },
    { lat: 49.185693, lng: 19.2939559 },
    { lat: 49.1855043, lng: 19.2939706 },
    { lat: 49.1854864, lng: 19.2944237 },
    { lat: 49.1855098, lng: 19.2949867 },
    { lat: 49.1853597, lng: 19.295984 },
    { lat: 49.1853685, lng: 19.2965915 },
    { lat: 49.1843138, lng: 19.2970478 },
    { lat: 49.1832161, lng: 19.2978543 },
    { lat: 49.1823723, lng: 19.2985761 },
    { lat: 49.181538, lng: 19.299467 },
    { lat: 49.1812082, lng: 19.2998078 },
    { lat: 49.1811252, lng: 19.2998649 },
    { lat: 49.1808768, lng: 19.3000454 },
    { lat: 49.1807128, lng: 19.3001529 },
    { lat: 49.1804449, lng: 19.300137 },
    { lat: 49.180403, lng: 19.3001784 },
    { lat: 49.1803288, lng: 19.3002529 },
    { lat: 49.1803137, lng: 19.3002672 },
    { lat: 49.1773947, lng: 19.3009338 },
    { lat: 49.176694, lng: 19.3032193 },
    { lat: 49.1763308, lng: 19.3042593 },
    { lat: 49.1762586, lng: 19.3045886 },
    { lat: 49.1762161, lng: 19.3047838 },
    { lat: 49.1762032, lng: 19.3048962 },
    { lat: 49.1762199, lng: 19.3051717 },
    { lat: 49.1762288, lng: 19.3052463 },
    { lat: 49.1760561, lng: 19.3059022 },
    { lat: 49.1759977, lng: 19.3061702 },
    { lat: 49.1759287, lng: 19.3067808 },
    { lat: 49.1758024, lng: 19.3072551 },
    { lat: 49.1753267, lng: 19.3084659 },
    { lat: 49.1752948, lng: 19.3086485 },
    { lat: 49.1751132, lng: 19.3102897 },
    { lat: 49.1749906, lng: 19.3111508 },
    { lat: 49.1749231, lng: 19.3110826 },
    { lat: 49.1748332, lng: 19.3116079 },
    { lat: 49.1747203, lng: 19.3118919 },
    { lat: 49.1746638, lng: 19.3122937 },
    { lat: 49.1746332, lng: 19.3124552 },
    { lat: 49.1745765, lng: 19.3126847 },
    { lat: 49.1744579, lng: 19.3130623 },
    { lat: 49.1742794, lng: 19.3134584 },
    { lat: 49.1740457, lng: 19.3138742 },
    { lat: 49.1739628, lng: 19.3139827 },
    { lat: 49.1738428, lng: 19.3141126 },
    { lat: 49.17335, lng: 19.3143938 },
    { lat: 49.1731107, lng: 19.3145506 },
    { lat: 49.1728109, lng: 19.3148137 },
    { lat: 49.1725828, lng: 19.3150179 },
    { lat: 49.172313, lng: 19.3154856 },
    { lat: 49.1717985, lng: 19.3166404 },
    { lat: 49.1715925, lng: 19.3170481 },
    { lat: 49.171229, lng: 19.3179147 },
    { lat: 49.1708967, lng: 19.3191578 },
    { lat: 49.1708454, lng: 19.3194555 },
    { lat: 49.1708131, lng: 19.3196312 },
    { lat: 49.1705502, lng: 19.3203852 },
    { lat: 49.1705309, lng: 19.3205007 },
    { lat: 49.1703799, lng: 19.3207633 },
    { lat: 49.1701915, lng: 19.3210502 },
    { lat: 49.1698372, lng: 19.321753 },
    { lat: 49.1692656, lng: 19.3220852 },
    { lat: 49.1688558, lng: 19.3222092 },
    { lat: 49.1683218, lng: 19.3231221 },
    { lat: 49.1681912, lng: 19.3236267 },
    { lat: 49.1680675, lng: 19.3242716 },
    { lat: 49.1678772, lng: 19.3249217 },
    { lat: 49.1675523, lng: 19.3253172 },
    { lat: 49.1669892, lng: 19.3253765 },
    { lat: 49.1661353, lng: 19.3251211 },
    { lat: 49.1657646, lng: 19.3248571 },
    { lat: 49.1654308, lng: 19.3246407 },
    { lat: 49.1652293, lng: 19.3245242 },
    { lat: 49.1649446, lng: 19.3240329 },
    { lat: 49.1645095, lng: 19.3235308 },
    { lat: 49.1644847, lng: 19.3235797 },
    { lat: 49.1639864, lng: 19.3234104 },
    { lat: 49.1630445, lng: 19.323173 },
    { lat: 49.1622796, lng: 19.323373 },
    { lat: 49.1613921, lng: 19.3230737 },
    { lat: 49.1596383, lng: 19.3238808 },
    { lat: 49.1593899, lng: 19.3242506 },
    { lat: 49.1558999, lng: 19.3257125 },
    { lat: 49.1551429, lng: 19.3265744 },
    { lat: 49.1540849, lng: 19.3293233 },
    { lat: 49.1534641, lng: 19.3302361 },
    { lat: 49.1527382, lng: 19.3313055 },
    { lat: 49.1518171, lng: 19.3344024 },
    { lat: 49.1519593, lng: 19.3353837 },
    { lat: 49.1514697, lng: 19.3368537 },
    { lat: 49.1507397, lng: 19.3365975 },
    { lat: 49.1495338, lng: 19.336216 },
    { lat: 49.1499266, lng: 19.3384262 },
    { lat: 49.1508359, lng: 19.3433755 },
    { lat: 49.1520579, lng: 19.3448555 },
    { lat: 49.154691, lng: 19.34807 },
    { lat: 49.154995, lng: 19.348215 },
    { lat: 49.155371, lng: 19.348545 },
    { lat: 49.155845, lng: 19.348616 },
    { lat: 49.156223, lng: 19.348547 },
    { lat: 49.156449, lng: 19.348589 },
    { lat: 49.157058, lng: 19.34911 },
    { lat: 49.157451, lng: 19.349261 },
    { lat: 49.157824, lng: 19.349276 },
    { lat: 49.158075, lng: 19.349193 },
    { lat: 49.158461, lng: 19.348957 },
    { lat: 49.158721, lng: 19.348941 },
    { lat: 49.158908, lng: 19.348763 },
    { lat: 49.15924, lng: 19.34865 },
    { lat: 49.159401, lng: 19.348709 },
    { lat: 49.16027, lng: 19.348366 },
    { lat: 49.161277, lng: 19.349847 },
    { lat: 49.162946, lng: 19.351261 },
    { lat: 49.164717, lng: 19.353695 },
    { lat: 49.165264, lng: 19.354385 },
    { lat: 49.165289, lng: 19.354434 },
    { lat: 49.165551, lng: 19.354906 },
    { lat: 49.166755, lng: 19.357507 },
    { lat: 49.167199, lng: 19.357571 },
    { lat: 49.168454, lng: 19.357202 },
    { lat: 49.170623, lng: 19.357807 },
    { lat: 49.1706498, lng: 19.3563839 },
    { lat: 49.1707779, lng: 19.355556 },
    { lat: 49.1707395, lng: 19.3549105 },
    { lat: 49.1708546, lng: 19.3543137 },
    { lat: 49.1716451, lng: 19.3534122 },
    { lat: 49.172006, lng: 19.3525091 },
    { lat: 49.1722626, lng: 19.3521148 },
    { lat: 49.1724443, lng: 19.3518557 },
    { lat: 49.1726428, lng: 19.3515736 },
    { lat: 49.172925, lng: 19.3512269 },
    { lat: 49.1733165, lng: 19.3506841 },
    { lat: 49.1735549, lng: 19.3504106 },
    { lat: 49.1738012, lng: 19.3503239 },
    { lat: 49.1739496, lng: 19.3501765 },
    { lat: 49.1742207, lng: 19.3500712 },
    { lat: 49.1743731, lng: 19.3498809 },
    { lat: 49.1746263, lng: 19.3497059 },
    { lat: 49.1748037, lng: 19.3499595 },
    { lat: 49.1751483, lng: 19.3497842 },
    { lat: 49.1751955, lng: 19.3497596 },
    { lat: 49.1752705, lng: 19.3497219 },
    { lat: 49.175376, lng: 19.3496653 },
    { lat: 49.1757181, lng: 19.3494874 },
    { lat: 49.1761457, lng: 19.3491852 },
    { lat: 49.1767867, lng: 19.3486803 },
    { lat: 49.1773233, lng: 19.3485362 },
    { lat: 49.1777705, lng: 19.3485096 },
    { lat: 49.178029, lng: 19.3485166 },
    { lat: 49.178208, lng: 19.3484674 },
    { lat: 49.1783127, lng: 19.348439 },
    { lat: 49.1785587, lng: 19.3484723 },
    { lat: 49.1791912, lng: 19.3480724 },
    { lat: 49.1795731, lng: 19.3480939 },
    { lat: 49.180282, lng: 19.3477112 },
    { lat: 49.1806305, lng: 19.3476666 },
    { lat: 49.1809122, lng: 19.3475706 },
    { lat: 49.1809523, lng: 19.3475139 },
    { lat: 49.181295, lng: 19.3470373 },
    { lat: 49.1813224, lng: 19.3469975 },
    { lat: 49.1813271, lng: 19.3469776 },
    { lat: 49.1814383, lng: 19.3466095 },
    { lat: 49.1815699, lng: 19.3464618 },
    { lat: 49.1816232, lng: 19.3464891 },
    { lat: 49.181871, lng: 19.3465614 },
    { lat: 49.1821593, lng: 19.3467964 },
    { lat: 49.1826877, lng: 19.3471755 },
    { lat: 49.1830061, lng: 19.3474036 },
    { lat: 49.1830508, lng: 19.3463901 },
    { lat: 49.183315, lng: 19.3461378 },
    { lat: 49.1835459, lng: 19.3456568 },
    { lat: 49.1840393, lng: 19.3458935 },
    { lat: 49.1842813, lng: 19.3459322 },
    { lat: 49.1843789, lng: 19.3454675 },
    { lat: 49.1844062, lng: 19.3450431 },
    { lat: 49.1848444, lng: 19.344535 },
    { lat: 49.1851722, lng: 19.3445099 },
    { lat: 49.1853154, lng: 19.3443923 },
    { lat: 49.1855454, lng: 19.3441556 },
    { lat: 49.1855756, lng: 19.3441253 },
    { lat: 49.1857833, lng: 19.343846 },
    { lat: 49.1858503, lng: 19.3436654 },
    { lat: 49.1859153, lng: 19.3434914 },
    { lat: 49.1859799, lng: 19.3434736 },
    { lat: 49.1865416, lng: 19.3433165 },
    { lat: 49.1866668, lng: 19.3431969 },
    { lat: 49.186816, lng: 19.343186 },
    { lat: 49.1869316, lng: 19.3429827 },
    { lat: 49.1872425, lng: 19.3422367 },
    { lat: 49.1875445, lng: 19.3419749 },
    { lat: 49.1880355, lng: 19.3418717 },
    { lat: 49.1882325, lng: 19.3418166 },
    { lat: 49.1885073, lng: 19.3414291 },
    { lat: 49.1885675, lng: 19.3413435 },
    { lat: 49.1885927, lng: 19.341308 },
    { lat: 49.1886294, lng: 19.3412236 },
    { lat: 49.1888099, lng: 19.3413952 },
    { lat: 49.1888178, lng: 19.3413983 },
    { lat: 49.1890418, lng: 19.3414609 },
    { lat: 49.1891247, lng: 19.3416676 },
    { lat: 49.1891284, lng: 19.3416748 },
    { lat: 49.1892451, lng: 19.3419012 },
    { lat: 49.1894416, lng: 19.3421474 },
    { lat: 49.1894731, lng: 19.3420996 },
    { lat: 49.1894887, lng: 19.3420763 },
    { lat: 49.189766, lng: 19.3416197 },
    { lat: 49.1897732, lng: 19.3416084 },
    { lat: 49.1897953, lng: 19.3415714 },
    { lat: 49.1898097, lng: 19.3415461 },
    { lat: 49.1898285, lng: 19.3415123 },
    { lat: 49.1898104, lng: 19.3414851 },
    { lat: 49.1900292, lng: 19.3412777 },
    { lat: 49.1905839, lng: 19.3407752 },
    { lat: 49.1905859, lng: 19.3407728 },
    { lat: 49.1907783, lng: 19.3406962 },
    { lat: 49.1909742, lng: 19.3406185 },
    { lat: 49.1909876, lng: 19.3406625 },
    { lat: 49.1911844, lng: 19.3408005 },
    { lat: 49.1917148, lng: 19.3411708 },
    { lat: 49.1922425, lng: 19.3408938 },
    { lat: 49.1922626, lng: 19.3408877 },
    { lat: 49.1929366, lng: 19.3406736 },
    { lat: 49.1934391, lng: 19.340336 },
    { lat: 49.1939426, lng: 19.3400455 },
    { lat: 49.1941498, lng: 19.3398974 },
    { lat: 49.1943957, lng: 19.3396892 },
    { lat: 49.1944132, lng: 19.3396743 },
    { lat: 49.1944206, lng: 19.3396666 },
    { lat: 49.1944346, lng: 19.3396518 },
    { lat: 49.1947644, lng: 19.3393093 },
    { lat: 49.1951128, lng: 19.3388351 },
    { lat: 49.1952424, lng: 19.3388083 },
    { lat: 49.1958472, lng: 19.3386829 },
    { lat: 49.1962266, lng: 19.3386719 },
    { lat: 49.1964595, lng: 19.3386182 },
    { lat: 49.1965843, lng: 19.3385894 },
    { lat: 49.1969677, lng: 19.3384273 },
    { lat: 49.1971968, lng: 19.3383373 },
    { lat: 49.1975485, lng: 19.3368062 },
    { lat: 49.1974263, lng: 19.3368239 },
    { lat: 49.19731, lng: 19.3368403 },
    { lat: 49.1971816, lng: 19.3368663 },
    { lat: 49.1971584, lng: 19.3368058 },
    { lat: 49.1970739, lng: 19.3350587 },
    { lat: 49.1974155, lng: 19.3343701 },
    { lat: 49.1979474, lng: 19.3322425 },
    { lat: 49.1972218, lng: 19.3316927 },
    { lat: 49.1967741, lng: 19.3314496 },
    { lat: 49.196961, lng: 19.3300349 },
    { lat: 49.1969497, lng: 19.3290091 },
    { lat: 49.1969306, lng: 19.3288887 },
    { lat: 49.1966609, lng: 19.327473 },
    { lat: 49.1966864, lng: 19.3260061 },
    { lat: 49.1967193, lng: 19.3239831 },
    { lat: 49.1967084, lng: 19.3238975 },
    { lat: 49.1970492, lng: 19.3214237 },
    { lat: 49.1972157, lng: 19.3208443 },
    { lat: 49.1973193, lng: 19.3204866 },
    { lat: 49.1975372, lng: 19.3197318 },
    { lat: 49.1964104, lng: 19.3185211 },
    { lat: 49.1963421, lng: 19.3184587 },
    { lat: 49.1960419, lng: 19.3183534 },
    { lat: 49.1956976, lng: 19.3184143 },
    { lat: 49.1955114, lng: 19.3183819 },
    { lat: 49.195246, lng: 19.3182518 },
    { lat: 49.1950028, lng: 19.3181143 },
    { lat: 49.1945764, lng: 19.3179147 },
    { lat: 49.1943902, lng: 19.3177435 },
    { lat: 49.1943549, lng: 19.3175673 },
    { lat: 49.194367, lng: 19.3173227 },
    { lat: 49.1944819, lng: 19.3168917 },
    { lat: 49.1939906, lng: 19.3173941 },
    { lat: 49.1937168, lng: 19.3175853 },
    { lat: 49.1935384, lng: 19.3176748 },
    { lat: 49.1933327, lng: 19.317638 },
    { lat: 49.1931398, lng: 19.3176347 },
    { lat: 49.1929497, lng: 19.3175699 },
    { lat: 49.1926787, lng: 19.317563 },
    { lat: 49.1924905, lng: 19.3175117 },
    { lat: 49.1923875, lng: 19.3175961 },
    { lat: 49.1923702, lng: 19.3170659 },
    { lat: 49.1923986, lng: 19.3167374 },
    { lat: 49.1924617, lng: 19.316524 },
    { lat: 49.1924313, lng: 19.3159753 },
    { lat: 49.1924726, lng: 19.3151319 },
    { lat: 49.1924391, lng: 19.3146128 },
    { lat: 49.1923376, lng: 19.3140399 },
    { lat: 49.1921966, lng: 19.3139452 },
    { lat: 49.1919153, lng: 19.3133366 },
    { lat: 49.1917777, lng: 19.3131176 },
    { lat: 49.1917643, lng: 19.3130744 },
    { lat: 49.1914978, lng: 19.3127031 },
    { lat: 49.1914212, lng: 19.3126304 },
    { lat: 49.1915135, lng: 19.312132 },
    { lat: 49.1916105, lng: 19.3116068 },
    { lat: 49.1916363, lng: 19.3100809 },
    { lat: 49.1916402, lng: 19.3096899 },
    { lat: 49.1916456, lng: 19.3091355 },
    { lat: 49.1915271, lng: 19.3083732 },
    { lat: 49.1915181, lng: 19.308278 },
    { lat: 49.1913959, lng: 19.3071003 },
    { lat: 49.1912914, lng: 19.3059905 },
    { lat: 49.1918288, lng: 19.3057251 },
    { lat: 49.1916868, lng: 19.3049416 },
    { lat: 49.1916792, lng: 19.304901 },
    { lat: 49.1916577, lng: 19.3047769 },
    { lat: 49.1916502, lng: 19.3047374 },
    { lat: 49.191609, lng: 19.3045113 },
    { lat: 49.1917197, lng: 19.3037075 },
    { lat: 49.1917615, lng: 19.3034045 },
    { lat: 49.1917995, lng: 19.3031099 },
    { lat: 49.1917781, lng: 19.3022796 },
    { lat: 49.1918896, lng: 19.3021815 },
    { lat: 49.1919405, lng: 19.3020682 },
    { lat: 49.1919479, lng: 19.3019362 },
    { lat: 49.1918842, lng: 19.3017133 },
    { lat: 49.191746, lng: 19.3012436 },
    { lat: 49.1915885, lng: 19.3007065 },
    { lat: 49.1915193, lng: 19.3003376 },
    { lat: 49.1914327, lng: 19.3000553 },
    { lat: 49.1913765, lng: 19.2999411 },
    { lat: 49.1912844, lng: 19.2996137 },
    { lat: 49.1912225, lng: 19.299547 },
    { lat: 49.1911444, lng: 19.2995142 },
    { lat: 49.1912235, lng: 19.2989517 },
    { lat: 49.1902719, lng: 19.2982032 },
    { lat: 49.1903438, lng: 19.297608 },
    { lat: 49.1902801, lng: 19.296952 },
    { lat: 49.1900644, lng: 19.2964938 },
    { lat: 49.189916, lng: 19.2961679 },
    { lat: 49.1904604, lng: 19.2956966 },
    { lat: 49.1899397, lng: 19.2952195 },
    { lat: 49.1901606, lng: 19.2949086 },
    { lat: 49.1898311, lng: 19.294406 },
    { lat: 49.1895254, lng: 19.2940204 },
  ],
  Zázrivá: [
    { lat: 49.2514231, lng: 19.1698888 },
    { lat: 49.2514254, lng: 19.1700299 },
    { lat: 49.2515039, lng: 19.1702992 },
    { lat: 49.2517437, lng: 19.1707584 },
    { lat: 49.2521095, lng: 19.1712157 },
    { lat: 49.2522289, lng: 19.1714212 },
    { lat: 49.2526411, lng: 19.1722996 },
    { lat: 49.25275, lng: 19.17266 },
    { lat: 49.2527904, lng: 19.1728654 },
    { lat: 49.2528776, lng: 19.1730132 },
    { lat: 49.2530801, lng: 19.1732631 },
    { lat: 49.253135, lng: 19.1737238 },
    { lat: 49.2529649, lng: 19.1740731 },
    { lat: 49.2529703, lng: 19.1744878 },
    { lat: 49.253051, lng: 19.175104 },
    { lat: 49.2531487, lng: 19.1751911 },
    { lat: 49.2531805, lng: 19.1752416 },
    { lat: 49.2533615, lng: 19.1753943 },
    { lat: 49.2538391, lng: 19.1759017 },
    { lat: 49.2538992, lng: 19.1763968 },
    { lat: 49.2539285, lng: 19.1764667 },
    { lat: 49.254014, lng: 19.1766714 },
    { lat: 49.254352, lng: 19.1774843 },
    { lat: 49.2544727, lng: 19.17793 },
    { lat: 49.2545945, lng: 19.1783027 },
    { lat: 49.2546925, lng: 19.1783472 },
    { lat: 49.2548039, lng: 19.1782974 },
    { lat: 49.2549937, lng: 19.1783961 },
    { lat: 49.2553756, lng: 19.1786513 },
    { lat: 49.2556272, lng: 19.179027 },
    { lat: 49.2558896, lng: 19.1796072 },
    { lat: 49.2559579, lng: 19.1798524 },
    { lat: 49.2560359, lng: 19.1801618 },
    { lat: 49.2559193, lng: 19.181221 },
    { lat: 49.2557283, lng: 19.1824431 },
    { lat: 49.2555722, lng: 19.1831326 },
    { lat: 49.2555511, lng: 19.1836026 },
    { lat: 49.2554604, lng: 19.1843164 },
    { lat: 49.2554274, lng: 19.1846355 },
    { lat: 49.2554124, lng: 19.1850474 },
    { lat: 49.2553017, lng: 19.1855994 },
    { lat: 49.2553317, lng: 19.1856836 },
    { lat: 49.2553501, lng: 19.1859706 },
    { lat: 49.2552931, lng: 19.1862181 },
    { lat: 49.2551261, lng: 19.1864469 },
    { lat: 49.2551887, lng: 19.1869468 },
    { lat: 49.2551291, lng: 19.1873006 },
    { lat: 49.2550966, lng: 19.1877228 },
    { lat: 49.2550371, lng: 19.1881144 },
    { lat: 49.2551174, lng: 19.188178 },
    { lat: 49.2552882, lng: 19.1886439 },
    { lat: 49.2553227, lng: 19.1889263 },
    { lat: 49.2553229, lng: 19.1891167 },
    { lat: 49.2553519, lng: 19.1892527 },
    { lat: 49.2556798, lng: 19.1899046 },
    { lat: 49.2560783, lng: 19.189567 },
    { lat: 49.2577182, lng: 19.1898446 },
    { lat: 49.257754, lng: 19.1898618 },
    { lat: 49.2582084, lng: 19.1900069 },
    { lat: 49.2585492, lng: 19.1901348 },
    { lat: 49.2588062, lng: 19.1918126 },
    { lat: 49.2597893, lng: 19.1931256 },
    { lat: 49.2603839, lng: 19.1934653 },
    { lat: 49.2613461, lng: 19.1939941 },
    { lat: 49.2616009, lng: 19.1948137 },
    { lat: 49.261637, lng: 19.1949064 },
    { lat: 49.2620133, lng: 19.1959556 },
    { lat: 49.2624983, lng: 19.196732 },
    { lat: 49.2633601, lng: 19.1973093 },
    { lat: 49.2637768, lng: 19.1977709 },
    { lat: 49.2644804, lng: 19.1983333 },
    { lat: 49.2645222, lng: 19.1983628 },
    { lat: 49.2647483, lng: 19.198542 },
    { lat: 49.2655602, lng: 19.1995285 },
    { lat: 49.2657364, lng: 19.2000525 },
    { lat: 49.2673817, lng: 19.2010044 },
    { lat: 49.267748, lng: 19.2016615 },
    { lat: 49.2687449, lng: 19.2017196 },
    { lat: 49.2696129, lng: 19.2014165 },
    { lat: 49.2703532, lng: 19.201837 },
    { lat: 49.2704686, lng: 19.2018876 },
    { lat: 49.270509, lng: 19.2019079 },
    { lat: 49.2706966, lng: 19.2019929 },
    { lat: 49.2707186, lng: 19.2020038 },
    { lat: 49.2707188, lng: 19.2020319 },
    { lat: 49.2707211, lng: 19.2020804 },
    { lat: 49.270722, lng: 19.2020976 },
    { lat: 49.2707385, lng: 19.202417 },
    { lat: 49.2707407, lng: 19.2024629 },
    { lat: 49.2707542, lng: 19.2027169 },
    { lat: 49.2707549, lng: 19.2027558 },
    { lat: 49.2707513, lng: 19.2033583 },
    { lat: 49.2708134, lng: 19.2034556 },
    { lat: 49.2708341, lng: 19.2035821 },
    { lat: 49.2708362, lng: 19.2041157 },
    { lat: 49.2711773, lng: 19.2047147 },
    { lat: 49.2711054, lng: 19.2051624 },
    { lat: 49.2711655, lng: 19.2057775 },
    { lat: 49.2713006, lng: 19.2061066 },
    { lat: 49.271428, lng: 19.2066929 },
    { lat: 49.2718599, lng: 19.2074224 },
    { lat: 49.2719391, lng: 19.2075655 },
    { lat: 49.2717608, lng: 19.2082432 },
    { lat: 49.2717299, lng: 19.2089342 },
    { lat: 49.2717935, lng: 19.2090833 },
    { lat: 49.2720451, lng: 19.2093936 },
    { lat: 49.2723323, lng: 19.2098612 },
    { lat: 49.2723362, lng: 19.2103144 },
    { lat: 49.2725911, lng: 19.2119602 },
    { lat: 49.2727017, lng: 19.212217 },
    { lat: 49.2730066, lng: 19.2131684 },
    { lat: 49.273416, lng: 19.2137111 },
    { lat: 49.2735817, lng: 19.2141126 },
    { lat: 49.2736009, lng: 19.2141578 },
    { lat: 49.2736047, lng: 19.2141678 },
    { lat: 49.2737766, lng: 19.2145798 },
    { lat: 49.2739802, lng: 19.2149677 },
    { lat: 49.2745877, lng: 19.2164674 },
    { lat: 49.2747036, lng: 19.2165826 },
    { lat: 49.2751488, lng: 19.2168895 },
    { lat: 49.2758098, lng: 19.2173441 },
    { lat: 49.2758413, lng: 19.2173665 },
    { lat: 49.2761711, lng: 19.2175937 },
    { lat: 49.2765592, lng: 19.217365 },
    { lat: 49.2773892, lng: 19.2174692 },
    { lat: 49.2778241, lng: 19.2176778 },
    { lat: 49.2787513, lng: 19.218514 },
    { lat: 49.2794872, lng: 19.2190295 },
    { lat: 49.2797331, lng: 19.2191972 },
    { lat: 49.2799336, lng: 19.2195223 },
    { lat: 49.2806279, lng: 19.219527 },
    { lat: 49.2814225, lng: 19.2194303 },
    { lat: 49.2831828, lng: 19.2192168 },
    { lat: 49.2834311, lng: 19.2194358 },
    { lat: 49.2840749, lng: 19.2191599 },
    { lat: 49.2850245, lng: 19.2189631 },
    { lat: 49.2857763, lng: 19.2190324 },
    { lat: 49.2854435, lng: 19.2197308 },
    { lat: 49.284995, lng: 19.2221481 },
    { lat: 49.2852304, lng: 19.2230134 },
    { lat: 49.2851017, lng: 19.2242333 },
    { lat: 49.2847798, lng: 19.2262113 },
    { lat: 49.2842555, lng: 19.2285094 },
    { lat: 49.2840219, lng: 19.2296764 },
    { lat: 49.2831628, lng: 19.2339484 },
    { lat: 49.2826035, lng: 19.2352135 },
    { lat: 49.2818364, lng: 19.2374311 },
    { lat: 49.2813895, lng: 19.2395604 },
    { lat: 49.2810626, lng: 19.2413752 },
    { lat: 49.2807144, lng: 19.2429519 },
    { lat: 49.2802662, lng: 19.2440452 },
    { lat: 49.2793777, lng: 19.2457541 },
    { lat: 49.28093, lng: 19.245713 },
    { lat: 49.282749, lng: 19.245864 },
    { lat: 49.284928, lng: 19.245677 },
    { lat: 49.286083, lng: 19.245933 },
    { lat: 49.28736, lng: 19.246316 },
    { lat: 49.288023, lng: 19.246756 },
    { lat: 49.28893, lng: 19.24752 },
    { lat: 49.289568, lng: 19.247855 },
    { lat: 49.289803, lng: 19.247886 },
    { lat: 49.290148, lng: 19.247772 },
    { lat: 49.290493, lng: 19.247593 },
    { lat: 49.291436, lng: 19.246857 },
    { lat: 49.294335, lng: 19.244475 },
    { lat: 49.294834, lng: 19.244193 },
    { lat: 49.295108, lng: 19.244073 },
    { lat: 49.295454, lng: 19.2439437 },
    { lat: 49.29597, lng: 19.243514 },
    { lat: 49.296413, lng: 19.243429 },
    { lat: 49.297665, lng: 19.242503 },
    { lat: 49.298431, lng: 19.240077 },
    { lat: 49.298726, lng: 19.239672 },
    { lat: 49.298974, lng: 19.23954 },
    { lat: 49.299304, lng: 19.239664 },
    { lat: 49.29989, lng: 19.239469 },
    { lat: 49.300122, lng: 19.239551 },
    { lat: 49.300576, lng: 19.239959 },
    { lat: 49.301309, lng: 19.240386 },
    { lat: 49.301376, lng: 19.240424 },
    { lat: 49.301724, lng: 19.240722 },
    { lat: 49.302325, lng: 19.240631 },
    { lat: 49.303348, lng: 19.240083 },
    { lat: 49.30384, lng: 19.239998 },
    { lat: 49.304428, lng: 19.239999 },
    { lat: 49.304901, lng: 19.239915 },
    { lat: 49.305179, lng: 19.239967 },
    { lat: 49.30573, lng: 19.240135 },
    { lat: 49.306543, lng: 19.240709 },
    { lat: 49.307334, lng: 19.24051 },
    { lat: 49.308418, lng: 19.240172 },
    { lat: 49.309012, lng: 19.240371 },
    { lat: 49.309427, lng: 19.240597 },
    { lat: 49.309912, lng: 19.241328 },
    { lat: 49.309945, lng: 19.241303 },
    { lat: 49.310782, lng: 19.240698 },
    { lat: 49.31172, lng: 19.239518 },
    { lat: 49.312073, lng: 19.239545 },
    { lat: 49.31246, lng: 19.239755 },
    { lat: 49.313532, lng: 19.240705 },
    { lat: 49.315461, lng: 19.241567 },
    { lat: 49.316184, lng: 19.241594 },
    { lat: 49.316762, lng: 19.241458 },
    { lat: 49.317034, lng: 19.241249 },
    { lat: 49.318369, lng: 19.239897 },
    { lat: 49.319159, lng: 19.239824 },
    { lat: 49.319694, lng: 19.239041 },
    { lat: 49.320123, lng: 19.238209 },
    { lat: 49.320279, lng: 19.236665 },
    { lat: 49.320172, lng: 19.234823 },
    { lat: 49.320167, lng: 19.233406 },
    { lat: 49.320516, lng: 19.232395 },
    { lat: 49.321021, lng: 19.231318 },
    { lat: 49.321454, lng: 19.230577 },
    { lat: 49.321117, lng: 19.229736 },
    { lat: 49.3208, lng: 19.22897 },
    { lat: 49.320391, lng: 19.228046 },
    { lat: 49.320368, lng: 19.22578 },
    { lat: 49.320496, lng: 19.22459 },
    { lat: 49.320597, lng: 19.223504 },
    { lat: 49.320743, lng: 19.222972 },
    { lat: 49.320938, lng: 19.22215 },
    { lat: 49.320995, lng: 19.221977 },
    { lat: 49.321115, lng: 19.221581 },
    { lat: 49.321216, lng: 19.22103 },
    { lat: 49.321254, lng: 19.220856 },
    { lat: 49.321725, lng: 19.219159 },
    { lat: 49.322495, lng: 19.217956 },
    { lat: 49.323233, lng: 19.217331 },
    { lat: 49.323712, lng: 19.216564 },
    { lat: 49.324134, lng: 19.215693 },
    { lat: 49.324138, lng: 19.214936 },
    { lat: 49.324106, lng: 19.214294 },
    { lat: 49.323945, lng: 19.21346 },
    { lat: 49.323683, lng: 19.212612 },
    { lat: 49.323424, lng: 19.212156 },
    { lat: 49.32302, lng: 19.211464 },
    { lat: 49.322721, lng: 19.21098 },
    { lat: 49.322604, lng: 19.210594 },
    { lat: 49.322502, lng: 19.21035 },
    { lat: 49.321906, lng: 19.208698 },
    { lat: 49.321819, lng: 19.207976 },
    { lat: 49.321814, lng: 19.206958 },
    { lat: 49.321964, lng: 19.206349 },
    { lat: 49.321863, lng: 19.205773 },
    { lat: 49.321903, lng: 19.204802 },
    { lat: 49.321816, lng: 19.20406 },
    { lat: 49.321733, lng: 19.203767 },
    { lat: 49.321703, lng: 19.203579 },
    { lat: 49.321372, lng: 19.202609 },
    { lat: 49.320294, lng: 19.202618 },
    { lat: 49.320051, lng: 19.202185 },
    { lat: 49.319802, lng: 19.201801 },
    { lat: 49.319345, lng: 19.201016 },
    { lat: 49.318334, lng: 19.199533 },
    { lat: 49.318136, lng: 19.199129 },
    { lat: 49.317436, lng: 19.198216 },
    { lat: 49.317197, lng: 19.197775 },
    { lat: 49.317455, lng: 19.193081 },
    { lat: 49.316884, lng: 19.189618 },
    { lat: 49.317021, lng: 19.187161 },
    { lat: 49.317014, lng: 19.187053 },
    { lat: 49.317018, lng: 19.186856 },
    { lat: 49.317176, lng: 19.183339 },
    { lat: 49.316941, lng: 19.180648 },
    { lat: 49.316981, lng: 19.17936 },
    { lat: 49.316898, lng: 19.177341 },
    { lat: 49.317354, lng: 19.17365 },
    { lat: 49.317632, lng: 19.1734 },
    { lat: 49.318106, lng: 19.173085 },
    { lat: 49.318623, lng: 19.172639 },
    { lat: 49.318897, lng: 19.172418 },
    { lat: 49.319394, lng: 19.171303 },
    { lat: 49.319622, lng: 19.170243 },
    { lat: 49.319747, lng: 19.169324 },
    { lat: 49.319851, lng: 19.168434 },
    { lat: 49.319623, lng: 19.167232 },
    { lat: 49.319417, lng: 19.165663 },
    { lat: 49.320041, lng: 19.165481 },
    { lat: 49.320484, lng: 19.165409 },
    { lat: 49.32094, lng: 19.165424 },
    { lat: 49.321438, lng: 19.165383 },
    { lat: 49.322161, lng: 19.165222 },
    { lat: 49.322367, lng: 19.165194 },
    { lat: 49.322428, lng: 19.165184 },
    { lat: 49.322691, lng: 19.165271 },
    { lat: 49.323103, lng: 19.16526 },
    { lat: 49.32318, lng: 19.165211 },
    { lat: 49.323267, lng: 19.165097 },
    { lat: 49.323381, lng: 19.164952 },
    { lat: 49.323549, lng: 19.164929 },
    { lat: 49.323564, lng: 19.164942 },
    { lat: 49.323635, lng: 19.165008 },
    { lat: 49.323713, lng: 19.165081 },
    { lat: 49.323836, lng: 19.165073 },
    { lat: 49.323898, lng: 19.164932 },
    { lat: 49.323933, lng: 19.164837 },
    { lat: 49.323971, lng: 19.164723 },
    { lat: 49.324109, lng: 19.16459 },
    { lat: 49.324224, lng: 19.164574 },
    { lat: 49.3245, lng: 19.164634 },
    { lat: 49.324751, lng: 19.164529 },
    { lat: 49.325068, lng: 19.164547 },
    { lat: 49.325206, lng: 19.164481 },
    { lat: 49.325298, lng: 19.164427 },
    { lat: 49.32547, lng: 19.164256 },
    { lat: 49.325701, lng: 19.164212 },
    { lat: 49.32596, lng: 19.164006 },
    { lat: 49.326045, lng: 19.163982 },
    { lat: 49.326245, lng: 19.164033 },
    { lat: 49.326352, lng: 19.164006 },
    { lat: 49.326441, lng: 19.163953 },
    { lat: 49.326535, lng: 19.163857 },
    { lat: 49.326691, lng: 19.163845 },
    { lat: 49.326872, lng: 19.163871 },
    { lat: 49.326966, lng: 19.163728 },
    { lat: 49.327235, lng: 19.163781 },
    { lat: 49.327305, lng: 19.163662 },
    { lat: 49.327465, lng: 19.163686 },
    { lat: 49.327597, lng: 19.163648 },
    { lat: 49.327678, lng: 19.163686 },
    { lat: 49.327895, lng: 19.163844 },
    { lat: 49.328041, lng: 19.163948 },
    { lat: 49.328071, lng: 19.163932 },
    { lat: 49.328099, lng: 19.163827 },
    { lat: 49.328091, lng: 19.163697 },
    { lat: 49.328134, lng: 19.163691 },
    { lat: 49.328345, lng: 19.163961 },
    { lat: 49.328435, lng: 19.163923 },
    { lat: 49.328495, lng: 19.163755 },
    { lat: 49.32851, lng: 19.163582 },
    { lat: 49.328578, lng: 19.163518 },
    { lat: 49.328718, lng: 19.163544 },
    { lat: 49.329065, lng: 19.163821 },
    { lat: 49.329075, lng: 19.163821 },
    { lat: 49.329154, lng: 19.163818 },
    { lat: 49.329226, lng: 19.163709 },
    { lat: 49.329296, lng: 19.163619 },
    { lat: 49.329402, lng: 19.163619 },
    { lat: 49.329643, lng: 19.163764 },
    { lat: 49.330001, lng: 19.163979 },
    { lat: 49.330387, lng: 19.164011 },
    { lat: 49.330421, lng: 19.164053 },
    { lat: 49.330447, lng: 19.164215 },
    { lat: 49.330475, lng: 19.164258 },
    { lat: 49.330604, lng: 19.164133 },
    { lat: 49.330771, lng: 19.164044 },
    { lat: 49.331059, lng: 19.164057 },
    { lat: 49.331334, lng: 19.164215 },
    { lat: 49.331608, lng: 19.164177 },
    { lat: 49.331627, lng: 19.16405 },
    { lat: 49.331698, lng: 19.16362 },
    { lat: 49.331709, lng: 19.163557 },
    { lat: 49.33185, lng: 19.162701 },
    { lat: 49.332198, lng: 19.161779 },
    { lat: 49.332497, lng: 19.161507 },
    { lat: 49.332637, lng: 19.161284 },
    { lat: 49.332816, lng: 19.161001 },
    { lat: 49.332956, lng: 19.16092 },
    { lat: 49.332964, lng: 19.160897 },
    { lat: 49.332973, lng: 19.160871 },
    { lat: 49.332898, lng: 19.16086 },
    { lat: 49.332852, lng: 19.160849 },
    { lat: 49.332835, lng: 19.160846 },
    { lat: 49.332636, lng: 19.160802 },
    { lat: 49.332402, lng: 19.160747 },
    { lat: 49.332277, lng: 19.160633 },
    { lat: 49.332084, lng: 19.1605 },
    { lat: 49.3317, lng: 19.159941 },
    { lat: 49.331448, lng: 19.159489 },
    { lat: 49.33144, lng: 19.159313 },
    { lat: 49.331387, lng: 19.159123 },
    { lat: 49.33133, lng: 19.159035 },
    { lat: 49.331125, lng: 19.158483 },
    { lat: 49.331055, lng: 19.158363 },
    { lat: 49.330939, lng: 19.158204 },
    { lat: 49.330898, lng: 19.158018 },
    { lat: 49.330823, lng: 19.157841 },
    { lat: 49.330553, lng: 19.157622 },
    { lat: 49.330274, lng: 19.157578 },
    { lat: 49.33009, lng: 19.157534 },
    { lat: 49.33005, lng: 19.157524 },
    { lat: 49.329469, lng: 19.157384 },
    { lat: 49.329431, lng: 19.157375 },
    { lat: 49.329191, lng: 19.15847 },
    { lat: 49.329033, lng: 19.159173 },
    { lat: 49.329001, lng: 19.159317 },
    { lat: 49.328971, lng: 19.15944 },
    { lat: 49.328724, lng: 19.160534 },
    { lat: 49.328529, lng: 19.160256 },
    { lat: 49.328501, lng: 19.160214 },
    { lat: 49.328069, lng: 19.1596 },
    { lat: 49.32735, lng: 19.158651 },
    { lat: 49.326623, lng: 19.157635 },
    { lat: 49.326017, lng: 19.156634 },
    { lat: 49.325275, lng: 19.155533 },
    { lat: 49.324928, lng: 19.15502 },
    { lat: 49.324846, lng: 19.1549 },
    { lat: 49.323392, lng: 19.152827 },
    { lat: 49.322013, lng: 19.150785 },
    { lat: 49.321292, lng: 19.14985 },
    { lat: 49.320623, lng: 19.148827 },
    { lat: 49.320013, lng: 19.147923 },
    { lat: 49.319541, lng: 19.147223 },
    { lat: 49.318738, lng: 19.146081 },
    { lat: 49.318224, lng: 19.145319 },
    { lat: 49.317756, lng: 19.14462 },
    { lat: 49.316629, lng: 19.142952 },
    { lat: 49.316269, lng: 19.1424276 },
    { lat: 49.316018, lng: 19.142062 },
    { lat: 49.315689, lng: 19.141572 },
    { lat: 49.315228, lng: 19.140888 },
    { lat: 49.31463, lng: 19.139991 },
    { lat: 49.313852, lng: 19.138839 },
    { lat: 49.313317, lng: 19.138027 },
    { lat: 49.313174, lng: 19.137815 },
    { lat: 49.312934, lng: 19.137467 },
    { lat: 49.312065, lng: 19.136173 },
    { lat: 49.311477, lng: 19.135244 },
    { lat: 49.310822, lng: 19.134228 },
    { lat: 49.30969, lng: 19.132564 },
    { lat: 49.309528, lng: 19.132333 },
    { lat: 49.3087, lng: 19.131099 },
    { lat: 49.307891, lng: 19.129892 },
    { lat: 49.307001, lng: 19.126487 },
    { lat: 49.307154, lng: 19.124658 },
    { lat: 49.307495, lng: 19.123618 },
    { lat: 49.307782, lng: 19.122947 },
    { lat: 49.30702, lng: 19.121499 },
    { lat: 49.306958, lng: 19.121392 },
    { lat: 49.306483, lng: 19.120584 },
    { lat: 49.306272, lng: 19.119772 },
    { lat: 49.306101, lng: 19.119111 },
    { lat: 49.306056, lng: 19.118671 },
    { lat: 49.305915, lng: 19.117293 },
    { lat: 49.305538, lng: 19.116535 },
    { lat: 49.305195, lng: 19.115572 },
    { lat: 49.305056, lng: 19.1151 },
    { lat: 49.304978, lng: 19.114831 },
    { lat: 49.30489, lng: 19.11481 },
    { lat: 49.304285, lng: 19.114664 },
    { lat: 49.303886, lng: 19.114636 },
    { lat: 49.303571, lng: 19.114614 },
    { lat: 49.303568, lng: 19.114615 },
    { lat: 49.303058, lng: 19.114776 },
    { lat: 49.303056, lng: 19.114776 },
    { lat: 49.303014, lng: 19.11475 },
    { lat: 49.302898, lng: 19.114675 },
    { lat: 49.301806, lng: 19.114353 },
    { lat: 49.301799, lng: 19.114353 },
    { lat: 49.301047, lng: 19.114309 },
    { lat: 49.30074, lng: 19.114304 },
    { lat: 49.300647, lng: 19.114303 },
    { lat: 49.300642, lng: 19.114277 },
    { lat: 49.300641, lng: 19.114264 },
    { lat: 49.300269, lng: 19.112561 },
    { lat: 49.300157, lng: 19.112386 },
    { lat: 49.299911, lng: 19.112009 },
    { lat: 49.299763, lng: 19.11163 },
    { lat: 49.299634, lng: 19.111474 },
    { lat: 49.299375, lng: 19.111077 },
    { lat: 49.299334, lng: 19.111016 },
    { lat: 49.299037, lng: 19.110631 },
    { lat: 49.299035, lng: 19.110628 },
    { lat: 49.298886, lng: 19.110376 },
    { lat: 49.298794, lng: 19.110221 },
    { lat: 49.298492, lng: 19.109998 },
    { lat: 49.298335, lng: 19.109883 },
    { lat: 49.298335, lng: 19.109881 },
    { lat: 49.297221, lng: 19.109567 },
    { lat: 49.297149, lng: 19.109547 },
    { lat: 49.29689, lng: 19.109473 },
    { lat: 49.296715, lng: 19.10939 },
    { lat: 49.296164, lng: 19.109128 },
    { lat: 49.295948, lng: 19.109025 },
    { lat: 49.295868, lng: 19.108987 },
    { lat: 49.295697, lng: 19.108906 },
    { lat: 49.295693, lng: 19.108898 },
    { lat: 49.295655, lng: 19.10884 },
    { lat: 49.295283, lng: 19.108274 },
    { lat: 49.295225, lng: 19.108184 },
    { lat: 49.29522, lng: 19.10818 },
    { lat: 49.294786, lng: 19.107729 },
    { lat: 49.294274, lng: 19.107315 },
    { lat: 49.294072, lng: 19.107157 },
    { lat: 49.293876, lng: 19.107003 },
    { lat: 49.293835, lng: 19.10697 },
    { lat: 49.293447, lng: 19.106838 },
    { lat: 49.29313, lng: 19.106731 },
    { lat: 49.292359, lng: 19.106293 },
    { lat: 49.292335, lng: 19.106269 },
    { lat: 49.292178, lng: 19.1061 },
    { lat: 49.292027, lng: 19.105999 },
    { lat: 49.291701, lng: 19.105783 },
    { lat: 49.290882, lng: 19.105481 },
    { lat: 49.290882, lng: 19.10548 },
    { lat: 49.290871, lng: 19.10547 },
    { lat: 49.290362, lng: 19.105021 },
    { lat: 49.290357, lng: 19.105014 },
    { lat: 49.290124, lng: 19.104633 },
    { lat: 49.289935, lng: 19.104386 },
    { lat: 49.289788, lng: 19.104195 },
    { lat: 49.289779, lng: 19.10418 },
    { lat: 49.289677, lng: 19.104 },
    { lat: 49.289671, lng: 19.103992 },
    { lat: 49.289634, lng: 19.10394 },
    { lat: 49.289387, lng: 19.103587 },
    { lat: 49.289186, lng: 19.1033 },
    { lat: 49.288958, lng: 19.102976 },
    { lat: 49.288436, lng: 19.102105 },
    { lat: 49.288336, lng: 19.101956 },
    { lat: 49.288081, lng: 19.101912 },
    { lat: 49.287962, lng: 19.101891 },
    { lat: 49.287926, lng: 19.101885 },
    { lat: 49.287688, lng: 19.101972 },
    { lat: 49.287554, lng: 19.102113 },
    { lat: 49.287521, lng: 19.102148 },
    { lat: 49.287505, lng: 19.102165 },
    { lat: 49.287178, lng: 19.102436 },
    { lat: 49.286789, lng: 19.102757 },
    { lat: 49.28639, lng: 19.103087 },
    { lat: 49.285446, lng: 19.103674 },
    { lat: 49.285401, lng: 19.103673 },
    { lat: 49.284312, lng: 19.103659 },
    { lat: 49.284311, lng: 19.103659 },
    { lat: 49.284094, lng: 19.103591 },
    { lat: 49.283291, lng: 19.10334 },
    { lat: 49.283145, lng: 19.103294 },
    { lat: 49.282768, lng: 19.103176 },
    { lat: 49.282574, lng: 19.103116 },
    { lat: 49.281211, lng: 19.102195 },
    { lat: 49.281039, lng: 19.102013 },
    { lat: 49.280616, lng: 19.101565 },
    { lat: 49.28059, lng: 19.10155 },
    { lat: 49.280431, lng: 19.101458 },
    { lat: 49.280096, lng: 19.101263 },
    { lat: 49.2800275, lng: 19.1012232 },
    { lat: 49.279791, lng: 19.101086 },
    { lat: 49.279158, lng: 19.100719 },
    { lat: 49.279108, lng: 19.10069 },
    { lat: 49.279098, lng: 19.100684 },
    { lat: 49.278387, lng: 19.100476 },
    { lat: 49.278363, lng: 19.100473 },
    { lat: 49.277309, lng: 19.100341 },
    { lat: 49.276876, lng: 19.100284 },
    { lat: 49.276427, lng: 19.100224 },
    { lat: 49.276415, lng: 19.100223 },
    { lat: 49.276283, lng: 19.100247 },
    { lat: 49.276038, lng: 19.100293 },
    { lat: 49.275942, lng: 19.100311 },
    { lat: 49.275886, lng: 19.100434 },
    { lat: 49.275776, lng: 19.100675 },
    { lat: 49.275551, lng: 19.101171 },
    { lat: 49.275493, lng: 19.101299 },
    { lat: 49.275287, lng: 19.101754 },
    { lat: 49.275111, lng: 19.10195 },
    { lat: 49.274906, lng: 19.10218 },
    { lat: 49.274601, lng: 19.102522 },
    { lat: 49.274404, lng: 19.102742 },
    { lat: 49.273602, lng: 19.103122 },
    { lat: 49.273594, lng: 19.103125 },
    { lat: 49.273086, lng: 19.103355 },
    { lat: 49.272594, lng: 19.103578 },
    { lat: 49.272583, lng: 19.103583 },
    { lat: 49.272372, lng: 19.103695 },
    { lat: 49.272063, lng: 19.103859 },
    { lat: 49.271899, lng: 19.103946 },
    { lat: 49.271548, lng: 19.104132 },
    { lat: 49.271544, lng: 19.104133 },
    { lat: 49.271457, lng: 19.104154 },
    { lat: 49.270967, lng: 19.104274 },
    { lat: 49.270899, lng: 19.104291 },
    { lat: 49.27044, lng: 19.104403 },
    { lat: 49.270247, lng: 19.10445 },
    { lat: 49.26965, lng: 19.104595 },
    { lat: 49.269625, lng: 19.1046 },
    { lat: 49.269522, lng: 19.10462 },
    { lat: 49.26929, lng: 19.104664 },
    { lat: 49.269223, lng: 19.104676 },
    { lat: 49.269194, lng: 19.104671 },
    { lat: 49.26906, lng: 19.104647 },
    { lat: 49.268874, lng: 19.104613 },
    { lat: 49.268849, lng: 19.104609 },
    { lat: 49.268693, lng: 19.10458 },
    { lat: 49.268369, lng: 19.104614 },
    { lat: 49.268361, lng: 19.104619 },
    { lat: 49.26836, lng: 19.10462 },
    { lat: 49.268249, lng: 19.104699 },
    { lat: 49.268224, lng: 19.104726 },
    { lat: 49.268178, lng: 19.104775 },
    { lat: 49.268164, lng: 19.104772 },
    { lat: 49.268106, lng: 19.104761 },
    { lat: 49.267783, lng: 19.104696 },
    { lat: 49.267538, lng: 19.104683 },
    { lat: 49.267527, lng: 19.104682 },
    { lat: 49.267523, lng: 19.104683 },
    { lat: 49.267472, lng: 19.104689 },
    { lat: 49.267325, lng: 19.104707 },
    { lat: 49.267316, lng: 19.104708 },
    { lat: 49.267297, lng: 19.104706 },
    { lat: 49.267043, lng: 19.104685 },
    { lat: 49.26704, lng: 19.104685 },
    { lat: 49.266787, lng: 19.104332 },
    { lat: 49.266787, lng: 19.104331 },
    { lat: 49.266449, lng: 19.104287 },
    { lat: 49.266345, lng: 19.104274 },
    { lat: 49.266163, lng: 19.104248 },
    { lat: 49.265829, lng: 19.104202 },
    { lat: 49.265671, lng: 19.104181 },
    { lat: 49.265441, lng: 19.10415 },
    { lat: 49.265347, lng: 19.104137 },
    { lat: 49.26506, lng: 19.104095 },
    { lat: 49.265031, lng: 19.104091 },
    { lat: 49.26501, lng: 19.104088 },
    { lat: 49.264436, lng: 19.104179 },
    { lat: 49.264365, lng: 19.104261 },
    { lat: 49.264267, lng: 19.104616 },
    { lat: 49.264229, lng: 19.104631 },
    { lat: 49.264201, lng: 19.104644 },
    { lat: 49.264135, lng: 19.104672 },
    { lat: 49.264129, lng: 19.104675 },
    { lat: 49.26411, lng: 19.104683 },
    { lat: 49.264095, lng: 19.104677 },
    { lat: 49.263637, lng: 19.104485 },
    { lat: 49.263417, lng: 19.104392 },
    { lat: 49.263255, lng: 19.104324 },
    { lat: 49.263004, lng: 19.104218 },
    { lat: 49.262798, lng: 19.104131 },
    { lat: 49.262747, lng: 19.10411 },
    { lat: 49.262269, lng: 19.103908 },
    { lat: 49.262205, lng: 19.103918 },
    { lat: 49.262149, lng: 19.103927 },
    { lat: 49.261937, lng: 19.103961 },
    { lat: 49.261533, lng: 19.104025 },
    { lat: 49.261456, lng: 19.104066 },
    { lat: 49.260963, lng: 19.104331 },
    { lat: 49.260709, lng: 19.104602 },
    { lat: 49.260413, lng: 19.104919 },
    { lat: 49.2598421, lng: 19.1054597 },
    { lat: 49.259496, lng: 19.1059852 },
    { lat: 49.259094, lng: 19.106803 },
    { lat: 49.258969, lng: 19.107024 },
    { lat: 49.258963, lng: 19.107038 },
    { lat: 49.258566, lng: 19.107856 },
    { lat: 49.25827, lng: 19.108466 },
    { lat: 49.258269, lng: 19.108468 },
    { lat: 49.257485, lng: 19.108526 },
    { lat: 49.256512, lng: 19.108597 },
    { lat: 49.256501, lng: 19.108598 },
    { lat: 49.256117, lng: 19.108507 },
    { lat: 49.255514, lng: 19.108364 },
    { lat: 49.255445, lng: 19.108385 },
    { lat: 49.254989, lng: 19.108519 },
    { lat: 49.254436, lng: 19.108679 },
    { lat: 49.25432, lng: 19.108714 },
    { lat: 49.254061, lng: 19.108046 },
    { lat: 49.253086, lng: 19.107914 },
    { lat: 49.252806, lng: 19.107876 },
    { lat: 49.252795, lng: 19.107875 },
    { lat: 49.251756, lng: 19.107304 },
    { lat: 49.251415, lng: 19.107117 },
    { lat: 49.250952, lng: 19.107156 },
    { lat: 49.250762, lng: 19.107172 },
    { lat: 49.250494, lng: 19.107194 },
    { lat: 49.248589, lng: 19.107354 },
    { lat: 49.248111, lng: 19.107395 },
    { lat: 49.247623, lng: 19.107554 },
    { lat: 49.247593, lng: 19.107435 },
    { lat: 49.247441, lng: 19.106829 },
    { lat: 49.247434, lng: 19.106811 },
    { lat: 49.247276, lng: 19.106314 },
    { lat: 49.246746, lng: 19.106264 },
    { lat: 49.246406, lng: 19.106233 },
    { lat: 49.246043, lng: 19.106198 },
    { lat: 49.246041, lng: 19.106198 },
    { lat: 49.245435, lng: 19.106458 },
    { lat: 49.245338, lng: 19.1065 },
    { lat: 49.245127, lng: 19.10657 },
    { lat: 49.24496, lng: 19.106626 },
    { lat: 49.244113, lng: 19.10691 },
    { lat: 49.243534, lng: 19.107104 },
    { lat: 49.243528, lng: 19.107106 },
    { lat: 49.243172, lng: 19.107198 },
    { lat: 49.24269, lng: 19.107324 },
    { lat: 49.242651, lng: 19.107336 },
    { lat: 49.24249, lng: 19.10738 },
    { lat: 49.242487, lng: 19.107379 },
    { lat: 49.242484, lng: 19.107377 },
    { lat: 49.24223, lng: 19.107279 },
    { lat: 49.241982, lng: 19.107162 },
    { lat: 49.241982, lng: 19.107161 },
    { lat: 49.241667, lng: 19.107013 },
    { lat: 49.241614, lng: 19.106983 },
    { lat: 49.241396, lng: 19.106861 },
    { lat: 49.241002, lng: 19.10664 },
    { lat: 49.240601, lng: 19.106415 },
    { lat: 49.240365, lng: 19.106303 },
    { lat: 49.239809, lng: 19.10604 },
    { lat: 49.239355, lng: 19.105825 },
    { lat: 49.239151, lng: 19.105732 },
    { lat: 49.238145, lng: 19.105278 },
    { lat: 49.238144, lng: 19.105276 },
    { lat: 49.238068, lng: 19.105124 },
    { lat: 49.237817, lng: 19.104623 },
    { lat: 49.237816, lng: 19.10462 },
    { lat: 49.237724, lng: 19.104577 },
    { lat: 49.237631, lng: 19.104535 },
    { lat: 49.236857, lng: 19.104177 },
    { lat: 49.236593, lng: 19.104148 },
    { lat: 49.236433, lng: 19.104131 },
    { lat: 49.236323, lng: 19.104119 },
    { lat: 49.236167, lng: 19.104103 },
    { lat: 49.2361743, lng: 19.1045973 },
    { lat: 49.2362441, lng: 19.1051437 },
    { lat: 49.2363148, lng: 19.1058473 },
    { lat: 49.2365119, lng: 19.1065819 },
    { lat: 49.2364925, lng: 19.1072648 },
    { lat: 49.2364804, lng: 19.10773 },
    { lat: 49.236478, lng: 19.1078447 },
    { lat: 49.2364657, lng: 19.1083262 },
    { lat: 49.2364485, lng: 19.1089666 },
    { lat: 49.2364436, lng: 19.1091666 },
    { lat: 49.2365126, lng: 19.1097683 },
    { lat: 49.2365458, lng: 19.1100517 },
    { lat: 49.2365248, lng: 19.1112368 },
    { lat: 49.2364112, lng: 19.1121805 },
    { lat: 49.2363864, lng: 19.1126876 },
    { lat: 49.2363107, lng: 19.1130515 },
    { lat: 49.236277, lng: 19.1132592 },
    { lat: 49.2362645, lng: 19.1133273 },
    { lat: 49.2362582, lng: 19.1133637 },
    { lat: 49.236152, lng: 19.1139534 },
    { lat: 49.2360157, lng: 19.1145061 },
    { lat: 49.2355444, lng: 19.1145044 },
    { lat: 49.2349343, lng: 19.1144269 },
    { lat: 49.2344901, lng: 19.1146797 },
    { lat: 49.2338156, lng: 19.1150153 },
    { lat: 49.2332853, lng: 19.1151434 },
    { lat: 49.2323451, lng: 19.1156479 },
    { lat: 49.2320409, lng: 19.1160397 },
    { lat: 49.2315481, lng: 19.1163355 },
    { lat: 49.2310926, lng: 19.1165642 },
    { lat: 49.2306471, lng: 19.1168777 },
    { lat: 49.2302177, lng: 19.1173159 },
    { lat: 49.2297748, lng: 19.1179868 },
    { lat: 49.2297202, lng: 19.1180251 },
    { lat: 49.2296919, lng: 19.1180442 },
    { lat: 49.2292952, lng: 19.1183225 },
    { lat: 49.2291965, lng: 19.1194807 },
    { lat: 49.2290594, lng: 19.1200672 },
    { lat: 49.2290353, lng: 19.1201244 },
    { lat: 49.2295779, lng: 19.1206421 },
    { lat: 49.2302048, lng: 19.1213362 },
    { lat: 49.2308517, lng: 19.1222095 },
    { lat: 49.2313222, lng: 19.1227744 },
    { lat: 49.2318441, lng: 19.1235151 },
    { lat: 49.2319291, lng: 19.1236357 },
    { lat: 49.2328468, lng: 19.1244442 },
    { lat: 49.2335089, lng: 19.1249858 },
    { lat: 49.2339853, lng: 19.125158 },
    { lat: 49.2340169, lng: 19.1252294 },
    { lat: 49.2340675, lng: 19.1252979 },
    { lat: 49.2341436, lng: 19.1254021 },
    { lat: 49.2342089, lng: 19.1254914 },
    { lat: 49.2343874, lng: 19.1257311 },
    { lat: 49.2344185, lng: 19.1257686 },
    { lat: 49.2349436, lng: 19.1263375 },
    { lat: 49.2350323, lng: 19.1267717 },
    { lat: 49.2352291, lng: 19.1269213 },
    { lat: 49.2355764, lng: 19.1271789 },
    { lat: 49.2356398, lng: 19.1272291 },
    { lat: 49.2362873, lng: 19.1277731 },
    { lat: 49.2367454, lng: 19.1281527 },
    { lat: 49.2367574, lng: 19.1281646 },
    { lat: 49.2369494, lng: 19.128475 },
    { lat: 49.2383032, lng: 19.1299035 },
    { lat: 49.2383502, lng: 19.129967 },
    { lat: 49.2383941, lng: 19.1300338 },
    { lat: 49.2384821, lng: 19.1301269 },
    { lat: 49.2384948, lng: 19.130137 },
    { lat: 49.2385546, lng: 19.13018 },
    { lat: 49.2386395, lng: 19.1302608 },
    { lat: 49.2387482, lng: 19.1303621 },
    { lat: 49.2387932, lng: 19.1305193 },
    { lat: 49.2388581, lng: 19.1306738 },
    { lat: 49.2389222, lng: 19.1308541 },
    { lat: 49.2390491, lng: 19.131169 },
    { lat: 49.2391373, lng: 19.1313602 },
    { lat: 49.2391683, lng: 19.1315064 },
    { lat: 49.2392937, lng: 19.1316988 },
    { lat: 49.2393282, lng: 19.1317825 },
    { lat: 49.2393732, lng: 19.1320562 },
    { lat: 49.2394578, lng: 19.1322648 },
    { lat: 49.239492, lng: 19.1323894 },
    { lat: 49.2395676, lng: 19.1326708 },
    { lat: 49.2395744, lng: 19.1326934 },
    { lat: 49.2395853, lng: 19.1327322 },
    { lat: 49.2395942, lng: 19.1327738 },
    { lat: 49.2397106, lng: 19.1332861 },
    { lat: 49.2398274, lng: 19.133512 },
    { lat: 49.2398736, lng: 19.1335586 },
    { lat: 49.239917, lng: 19.133615 },
    { lat: 49.2399568, lng: 19.1336902 },
    { lat: 49.2399903, lng: 19.1337759 },
    { lat: 49.2400496, lng: 19.1338824 },
    { lat: 49.2401604, lng: 19.1340772 },
    { lat: 49.2402306, lng: 19.1341742 },
    { lat: 49.2402843, lng: 19.1342092 },
    { lat: 49.2403496, lng: 19.134232 },
    { lat: 49.2403666, lng: 19.1342355 },
    { lat: 49.2404027, lng: 19.1342357 },
    { lat: 49.2404715, lng: 19.1342376 },
    { lat: 49.2405868, lng: 19.1342898 },
    { lat: 49.240669, lng: 19.1342862 },
    { lat: 49.2406825, lng: 19.1342875 },
    { lat: 49.2407665, lng: 19.1343234 },
    { lat: 49.2408117, lng: 19.1343688 },
    { lat: 49.2408912, lng: 19.1344975 },
    { lat: 49.2408997, lng: 19.1345114 },
    { lat: 49.2409357, lng: 19.1346014 },
    { lat: 49.2410097, lng: 19.1348679 },
    { lat: 49.2411609, lng: 19.1351266 },
    { lat: 49.241208, lng: 19.1352641 },
    { lat: 49.2412579, lng: 19.1353818 },
    { lat: 49.2413489, lng: 19.1354941 },
    { lat: 49.2414026, lng: 19.1355529 },
    { lat: 49.2414432, lng: 19.1356453 },
    { lat: 49.2414941, lng: 19.1357629 },
    { lat: 49.2414973, lng: 19.1358098 },
    { lat: 49.2415884, lng: 19.1359866 },
    { lat: 49.2416818, lng: 19.1361924 },
    { lat: 49.2417282, lng: 19.1363343 },
    { lat: 49.2417398, lng: 19.1363879 },
    { lat: 49.2418419, lng: 19.1366078 },
    { lat: 49.2419112, lng: 19.1367601 },
    { lat: 49.2419326, lng: 19.1368056 },
    { lat: 49.2419842, lng: 19.1369643 },
    { lat: 49.2419966, lng: 19.1372168 },
    { lat: 49.241994, lng: 19.1373729 },
    { lat: 49.2419977, lng: 19.1374245 },
    { lat: 49.2420184, lng: 19.1375246 },
    { lat: 49.2420751, lng: 19.1376455 },
    { lat: 49.2421137, lng: 19.1377439 },
    { lat: 49.2421979, lng: 19.1378768 },
    { lat: 49.2422225, lng: 19.1379894 },
    { lat: 49.2422354, lng: 19.1381615 },
    { lat: 49.2422407, lng: 19.1381973 },
    { lat: 49.2422674, lng: 19.1382841 },
    { lat: 49.2422877, lng: 19.1383096 },
    { lat: 49.2424153, lng: 19.1384754 },
    { lat: 49.2425145, lng: 19.1386146 },
    { lat: 49.2425316, lng: 19.1386407 },
    { lat: 49.2426045, lng: 19.1387511 },
    { lat: 49.2426937, lng: 19.1388647 },
    { lat: 49.2428265, lng: 19.1389355 },
    { lat: 49.2428972, lng: 19.1389949 },
    { lat: 49.2430291, lng: 19.1391123 },
    { lat: 49.2431296, lng: 19.139182 },
    { lat: 49.2432254, lng: 19.1393208 },
    { lat: 49.2433607, lng: 19.1395085 },
    { lat: 49.2434522, lng: 19.1395847 },
    { lat: 49.2435285, lng: 19.1396896 },
    { lat: 49.2436284, lng: 19.1398449 },
    { lat: 49.2437411, lng: 19.139958 },
    { lat: 49.2438749, lng: 19.1402069 },
    { lat: 49.2438859, lng: 19.1402247 },
    { lat: 49.2439474, lng: 19.1402841 },
    { lat: 49.2440365, lng: 19.140494 },
    { lat: 49.2441599, lng: 19.1406409 },
    { lat: 49.2443111, lng: 19.1407828 },
    { lat: 49.244495, lng: 19.1409974 },
    { lat: 49.2445704, lng: 19.1410873 },
    { lat: 49.2446671, lng: 19.1411943 },
    { lat: 49.2448822, lng: 19.1414451 },
    { lat: 49.2450791, lng: 19.1416034 },
    { lat: 49.2452437, lng: 19.141812 },
    { lat: 49.2453505, lng: 19.1419918 },
    { lat: 49.2455201, lng: 19.1421868 },
    { lat: 49.2457226, lng: 19.1424094 },
    { lat: 49.2457868, lng: 19.1425252 },
    { lat: 49.2458812, lng: 19.1427287 },
    { lat: 49.2460185, lng: 19.1430692 },
    { lat: 49.2460388, lng: 19.1431852 },
    { lat: 49.2461067, lng: 19.1433997 },
    { lat: 49.2462549, lng: 19.143639 },
    { lat: 49.2463489, lng: 19.1439058 },
    { lat: 49.2464901, lng: 19.1441419 },
    { lat: 49.2466257, lng: 19.1444248 },
    { lat: 49.246848, lng: 19.1447314 },
    { lat: 49.2469898, lng: 19.1450288 },
    { lat: 49.2470316, lng: 19.1450768 },
    { lat: 49.2472569, lng: 19.1454689 },
    { lat: 49.2473104, lng: 19.1458496 },
    { lat: 49.2474444, lng: 19.1464477 },
    { lat: 49.247542, lng: 19.1468796 },
    { lat: 49.2476105, lng: 19.1473116 },
    { lat: 49.2476707, lng: 19.1478297 },
    { lat: 49.2476467, lng: 19.1481041 },
    { lat: 49.2477722, lng: 19.1485029 },
    { lat: 49.2476977, lng: 19.1487575 },
    { lat: 49.2477411, lng: 19.149232 },
    { lat: 49.2477448, lng: 19.1492835 },
    { lat: 49.2476702, lng: 19.1496975 },
    { lat: 49.2476067, lng: 19.1499883 },
    { lat: 49.2474595, lng: 19.1502554 },
    { lat: 49.2474087, lng: 19.1506663 },
    { lat: 49.2472262, lng: 19.1511773 },
    { lat: 49.2470728, lng: 19.1516169 },
    { lat: 49.2468528, lng: 19.1521678 },
    { lat: 49.2466585, lng: 19.1527379 },
    { lat: 49.2464918, lng: 19.1531555 },
    { lat: 49.2464488, lng: 19.1536647 },
    { lat: 49.2462825, lng: 19.1540426 },
    { lat: 49.246285, lng: 19.1543522 },
    { lat: 49.2461303, lng: 19.1550169 },
    { lat: 49.2462, lng: 19.1553626 },
    { lat: 49.2462581, lng: 19.1555374 },
    { lat: 49.2462463, lng: 19.1559645 },
    { lat: 49.2463685, lng: 19.1562533 },
    { lat: 49.2464853, lng: 19.1564551 },
    { lat: 49.2465022, lng: 19.1564777 },
    { lat: 49.246571, lng: 19.1565471 },
    { lat: 49.2465811, lng: 19.1565705 },
    { lat: 49.2468026, lng: 19.1569534 },
    { lat: 49.2468159, lng: 19.1569695 },
    { lat: 49.2468551, lng: 19.1569885 },
    { lat: 49.2469088, lng: 19.1566783 },
    { lat: 49.2469179, lng: 19.1566322 },
    { lat: 49.2470297, lng: 19.1561303 },
    { lat: 49.2470435, lng: 19.1560642 },
    { lat: 49.2470524, lng: 19.1560379 },
    { lat: 49.2470706, lng: 19.1559756 },
    { lat: 49.2472349, lng: 19.1554836 },
    { lat: 49.2474594, lng: 19.1549999 },
    { lat: 49.2476961, lng: 19.1546502 },
    { lat: 49.2478276, lng: 19.1545527 },
    { lat: 49.2482372, lng: 19.1542579 },
    { lat: 49.2484651, lng: 19.1541693 },
    { lat: 49.2484739, lng: 19.1541661 },
    { lat: 49.2488745, lng: 19.1540153 },
    { lat: 49.2490766, lng: 19.1540191 },
    { lat: 49.249139, lng: 19.1540972 },
    { lat: 49.249152, lng: 19.1541368 },
    { lat: 49.2491863, lng: 19.1542397 },
    { lat: 49.2491239, lng: 19.1541616 },
    { lat: 49.2491751, lng: 19.15482 },
    { lat: 49.2493376, lng: 19.1556307 },
    { lat: 49.2493227, lng: 19.1563013 },
    { lat: 49.2492708, lng: 19.1568079 },
    { lat: 49.2492562, lng: 19.1577606 },
    { lat: 49.2494055, lng: 19.1583978 },
    { lat: 49.2494301, lng: 19.158487 },
    { lat: 49.249655, lng: 19.1593083 },
    { lat: 49.2495902, lng: 19.1595065 },
    { lat: 49.2496054, lng: 19.1596822 },
    { lat: 49.2497309, lng: 19.16004 },
    { lat: 49.249838, lng: 19.1603183 },
    { lat: 49.2499881, lng: 19.1609478 },
    { lat: 49.2500059, lng: 19.1612413 },
    { lat: 49.2502078, lng: 19.1619656 },
    { lat: 49.250248, lng: 19.1624917 },
    { lat: 49.2503318, lng: 19.1626854 },
    { lat: 49.250343, lng: 19.1631492 },
    { lat: 49.2503585, lng: 19.163584 },
    { lat: 49.2503463, lng: 19.1636542 },
    { lat: 49.2503555, lng: 19.1637524 },
    { lat: 49.2503904, lng: 19.1638242 },
    { lat: 49.2503797, lng: 19.1640217 },
    { lat: 49.2503697, lng: 19.1642101 },
    { lat: 49.2503702, lng: 19.1647314 },
    { lat: 49.2503582, lng: 19.1650178 },
    { lat: 49.2503968, lng: 19.165205 },
    { lat: 49.250357, lng: 19.1653874 },
    { lat: 49.2505021, lng: 19.1662852 },
    { lat: 49.2504866, lng: 19.1666412 },
    { lat: 49.2505231, lng: 19.1670426 },
    { lat: 49.2505628, lng: 19.1671413 },
    { lat: 49.2506012, lng: 19.1675385 },
    { lat: 49.2506717, lng: 19.1675984 },
    { lat: 49.2508536, lng: 19.1684422 },
    { lat: 49.2509814, lng: 19.1687063 },
    { lat: 49.2510495, lng: 19.1688093 },
    { lat: 49.2513226, lng: 19.1692832 },
    { lat: 49.2514245, lng: 19.1694989 },
    { lat: 49.2514221, lng: 19.1698438 },
    { lat: 49.2514231, lng: 19.1698888 },
  ],
  Žaškov: [
    { lat: 49.1930166, lng: 19.2189933 },
    { lat: 49.1930296, lng: 19.2189108 },
    { lat: 49.1930678, lng: 19.2186741 },
    { lat: 49.1930877, lng: 19.2185475 },
    { lat: 49.1931017, lng: 19.2184659 },
    { lat: 49.1931149, lng: 19.2183863 },
    { lat: 49.1931288, lng: 19.2183036 },
    { lat: 49.1931566, lng: 19.218111 },
    { lat: 49.1932068, lng: 19.2177695 },
    { lat: 49.1932488, lng: 19.2174246 },
    { lat: 49.1932741, lng: 19.2172239 },
    { lat: 49.1932859, lng: 19.2171412 },
    { lat: 49.1933057, lng: 19.2170125 },
    { lat: 49.1933362, lng: 19.2168069 },
    { lat: 49.1933487, lng: 19.2166887 },
    { lat: 49.1933806, lng: 19.2164635 },
    { lat: 49.1934126, lng: 19.2162405 },
    { lat: 49.1934133, lng: 19.2161831 },
    { lat: 49.1934137, lng: 19.2160724 },
    { lat: 49.1934147, lng: 19.2159559 },
    { lat: 49.1934059, lng: 19.2158181 },
    { lat: 49.1933861, lng: 19.2154805 },
    { lat: 49.1933722, lng: 19.2152115 },
    { lat: 49.1933502, lng: 19.2148273 },
    { lat: 49.1933665, lng: 19.2145584 },
    { lat: 49.1933943, lng: 19.2138951 },
    { lat: 49.1933599, lng: 19.2128972 },
    { lat: 49.1930706, lng: 19.2123448 },
    { lat: 49.1930053, lng: 19.2117388 },
    { lat: 49.1929743, lng: 19.2116096 },
    { lat: 49.1929252, lng: 19.2114094 },
    { lat: 49.1928254, lng: 19.21128 },
    { lat: 49.1927655, lng: 19.2112089 },
    { lat: 49.1926198, lng: 19.2110379 },
    { lat: 49.1925769, lng: 19.210989 },
    { lat: 49.1925039, lng: 19.210853 },
    { lat: 49.1922653, lng: 19.2105573 },
    { lat: 49.1919417, lng: 19.21011 },
    { lat: 49.1917026, lng: 19.2097812 },
    { lat: 49.1916565, lng: 19.2097121 },
    { lat: 49.1914417, lng: 19.2094199 },
    { lat: 49.1912726, lng: 19.2092781 },
    { lat: 49.1911707, lng: 19.2091966 },
    { lat: 49.1910242, lng: 19.209079 },
    { lat: 49.1908467, lng: 19.2089281 },
    { lat: 49.1906749, lng: 19.2088051 },
    { lat: 49.1905979, lng: 19.2087503 },
    { lat: 49.1905127, lng: 19.2086892 },
    { lat: 49.190232, lng: 19.2084512 },
    { lat: 49.1899493, lng: 19.2080153 },
    { lat: 49.1900084, lng: 19.2079286 },
    { lat: 49.1900328, lng: 19.2079014 },
    { lat: 49.1900543, lng: 19.2078763 },
    { lat: 49.1900557, lng: 19.2078603 },
    { lat: 49.1901011, lng: 19.2077776 },
    { lat: 49.1901605, lng: 19.2077184 },
    { lat: 49.1904874, lng: 19.207277 },
    { lat: 49.1905235, lng: 19.2072286 },
    { lat: 49.1905719, lng: 19.2071598 },
    { lat: 49.1906346, lng: 19.2070785 },
    { lat: 49.1906758, lng: 19.2070213 },
    { lat: 49.1911383, lng: 19.2063077 },
    { lat: 49.1911731, lng: 19.2062559 },
    { lat: 49.1911992, lng: 19.2062141 },
    { lat: 49.191177, lng: 19.2060825 },
    { lat: 49.1910501, lng: 19.2053177 },
    { lat: 49.1908054, lng: 19.203844 },
    { lat: 49.1909046, lng: 19.2037063 },
    { lat: 49.1905169, lng: 19.2029121 },
    { lat: 49.1903176, lng: 19.2024725 },
    { lat: 49.1897457, lng: 19.2023001 },
    { lat: 49.1897057, lng: 19.202288 },
    { lat: 49.1896538, lng: 19.2018935 },
    { lat: 49.1895992, lng: 19.201883 },
    { lat: 49.1895633, lng: 19.2018392 },
    { lat: 49.1891895, lng: 19.2018012 },
    { lat: 49.1885227, lng: 19.2010621 },
    { lat: 49.1883419, lng: 19.2007023 },
    { lat: 49.1879485, lng: 19.1999074 },
    { lat: 49.1875798, lng: 19.199208 },
    { lat: 49.1874338, lng: 19.1988648 },
    { lat: 49.1869452, lng: 19.1977123 },
    { lat: 49.1864865, lng: 19.1978281 },
    { lat: 49.1864819, lng: 19.1960763 },
    { lat: 49.1865948, lng: 19.194124 },
    { lat: 49.1868305, lng: 19.1921027 },
    { lat: 49.1870181, lng: 19.190761 },
    { lat: 49.1870645, lng: 19.1900406 },
    { lat: 49.1869815, lng: 19.1890731 },
    { lat: 49.1869014, lng: 19.1877892 },
    { lat: 49.1866789, lng: 19.185242 },
    { lat: 49.1861166, lng: 19.181082 },
    { lat: 49.185849, lng: 19.1800255 },
    { lat: 49.1854026, lng: 19.1786317 },
    { lat: 49.1844445, lng: 19.1769102 },
    { lat: 49.1835092, lng: 19.1751036 },
    { lat: 49.1824947, lng: 19.1729624 },
    { lat: 49.1818802, lng: 19.1714501 },
    { lat: 49.1815345, lng: 19.1708288 },
    { lat: 49.1810467, lng: 19.1697011 },
    { lat: 49.1808169, lng: 19.1683104 },
    { lat: 49.1807496, lng: 19.1654433 },
    { lat: 49.1808821, lng: 19.1635303 },
    { lat: 49.1811667, lng: 19.1604856 },
    { lat: 49.1813836, lng: 19.1591954 },
    { lat: 49.1821213, lng: 19.1549028 },
    { lat: 49.182185, lng: 19.1536165 },
    { lat: 49.1819587, lng: 19.1524866 },
    { lat: 49.1816674, lng: 19.1517836 },
    { lat: 49.181376, lng: 19.1510809 },
    { lat: 49.1810759, lng: 19.1506098 },
    { lat: 49.181005, lng: 19.1504971 },
    { lat: 49.1806339, lng: 19.1499134 },
    { lat: 49.1802775, lng: 19.1495409 },
    { lat: 49.1799213, lng: 19.1491688 },
    { lat: 49.1791629, lng: 19.1484089 },
    { lat: 49.1784043, lng: 19.147649 },
    { lat: 49.1781153, lng: 19.147336 },
    { lat: 49.1777664, lng: 19.1469584 },
    { lat: 49.1771288, lng: 19.1462673 },
    { lat: 49.1770075, lng: 19.1461643 },
    { lat: 49.1767475, lng: 19.1459431 },
    { lat: 49.1766583, lng: 19.1458668 },
    { lat: 49.1761879, lng: 19.1454662 },
    { lat: 49.175961, lng: 19.1451342 },
    { lat: 49.1755858, lng: 19.1445856 },
    { lat: 49.1755454, lng: 19.1442649 },
    { lat: 49.1754672, lng: 19.1436361 },
    { lat: 49.1754265, lng: 19.1432611 },
    { lat: 49.1754099, lng: 19.1431062 },
    { lat: 49.1753524, lng: 19.1425767 },
    { lat: 49.1752368, lng: 19.1411319 },
    { lat: 49.1751537, lng: 19.1407904 },
    { lat: 49.1750705, lng: 19.1404493 },
    { lat: 49.1748782, lng: 19.1398786 },
    { lat: 49.1746868, lng: 19.139307 },
    { lat: 49.1745894, lng: 19.1389081 },
    { lat: 49.1744922, lng: 19.1385095 },
    { lat: 49.1745489, lng: 19.1377073 },
    { lat: 49.1745592, lng: 19.1375515 },
    { lat: 49.1746275, lng: 19.1365934 },
    { lat: 49.1750638, lng: 19.1354695 },
    { lat: 49.175116, lng: 19.1353346 },
    { lat: 49.1756043, lng: 19.1340754 },
    { lat: 49.1756051, lng: 19.1337918 },
    { lat: 49.1756055, lng: 19.1336354 },
    { lat: 49.1756064, lng: 19.1331951 },
    { lat: 49.1755802, lng: 19.1330496 },
    { lat: 49.1755333, lng: 19.1330928 },
    { lat: 49.1755568, lng: 19.1327126 },
    { lat: 49.1754835, lng: 19.1324792 },
    { lat: 49.1754338, lng: 19.1323612 },
    { lat: 49.1753495, lng: 19.1322058 },
    { lat: 49.1752822, lng: 19.1321209 },
    { lat: 49.1752102, lng: 19.1320297 },
    { lat: 49.1751392, lng: 19.1319592 },
    { lat: 49.1749751, lng: 19.1318781 },
    { lat: 49.1747369, lng: 19.1318706 },
    { lat: 49.1745236, lng: 19.1319147 },
    { lat: 49.1744057, lng: 19.1319663 },
    { lat: 49.1733659, lng: 19.1326019 },
    { lat: 49.1728144, lng: 19.1328736 },
    { lat: 49.1724856, lng: 19.1330371 },
    { lat: 49.1720137, lng: 19.133299 },
    { lat: 49.1715925, lng: 19.1335394 },
    { lat: 49.1711506, lng: 19.1337916 },
    { lat: 49.1706048, lng: 19.1341552 },
    { lat: 49.1701588, lng: 19.1343468 },
    { lat: 49.1690056, lng: 19.1346678 },
    { lat: 49.1683967, lng: 19.1348714 },
    { lat: 49.167816, lng: 19.13484 },
    { lat: 49.167969, lng: 19.135546 },
    { lat: 49.168081, lng: 19.135961 },
    { lat: 49.168139, lng: 19.136387 },
    { lat: 49.168227, lng: 19.136659 },
    { lat: 49.168229, lng: 19.137649 },
    { lat: 49.168005, lng: 19.138459 },
    { lat: 49.167972, lng: 19.138705 },
    { lat: 49.167978, lng: 19.13965 },
    { lat: 49.167977, lng: 19.140061 },
    { lat: 49.167892, lng: 19.141174 },
    { lat: 49.167914, lng: 19.141645 },
    { lat: 49.16789, lng: 19.142362 },
    { lat: 49.167747, lng: 19.142973 },
    { lat: 49.167773, lng: 19.143316 },
    { lat: 49.167739, lng: 19.143916 },
    { lat: 49.167879, lng: 19.1448 },
    { lat: 49.168141, lng: 19.145395 },
    { lat: 49.168328, lng: 19.145969 },
    { lat: 49.168401, lng: 19.14596 },
    { lat: 49.168506, lng: 19.146299 },
    { lat: 49.168902, lng: 19.146918 },
    { lat: 49.169146, lng: 19.14733 },
    { lat: 49.169852, lng: 19.147915 },
    { lat: 49.1704, lng: 19.148401 },
    { lat: 49.170666, lng: 19.148569 },
    { lat: 49.171279, lng: 19.149151 },
    { lat: 49.171317, lng: 19.149186 },
    { lat: 49.171785, lng: 19.149694 },
    { lat: 49.171985, lng: 19.150018 },
    { lat: 49.171997, lng: 19.150034 },
    { lat: 49.171846, lng: 19.150215 },
    { lat: 49.171727, lng: 19.150437 },
    { lat: 49.171563, lng: 19.150707 },
    { lat: 49.171492, lng: 19.150994 },
    { lat: 49.171338, lng: 19.151272 },
    { lat: 49.171278, lng: 19.151527 },
    { lat: 49.17117, lng: 19.151702 },
    { lat: 49.171136, lng: 19.15183 },
    { lat: 49.170982, lng: 19.152019 },
    { lat: 49.170875, lng: 19.152336 },
    { lat: 49.170692, lng: 19.152605 },
    { lat: 49.170753, lng: 19.153425 },
    { lat: 49.170829, lng: 19.153687 },
    { lat: 49.170838, lng: 19.154238 },
    { lat: 49.170705, lng: 19.155093 },
    { lat: 49.170658, lng: 19.155376 },
    { lat: 49.170482, lng: 19.156152 },
    { lat: 49.170278, lng: 19.156443 },
    { lat: 49.170161, lng: 19.156538 },
    { lat: 49.16997, lng: 19.156965 },
    { lat: 49.169744, lng: 19.157817 },
    { lat: 49.169668, lng: 19.158155 },
    { lat: 49.169573, lng: 19.15828 },
    { lat: 49.169388, lng: 19.158694 },
    { lat: 49.16922, lng: 19.15909 },
    { lat: 49.168994, lng: 19.159306 },
    { lat: 49.168892, lng: 19.159457 },
    { lat: 49.168575, lng: 19.159892 },
    { lat: 49.168388, lng: 19.160142 },
    { lat: 49.168233, lng: 19.160353 },
    { lat: 49.167993, lng: 19.160587 },
    { lat: 49.167485, lng: 19.161178 },
    { lat: 49.167246, lng: 19.161376 },
    { lat: 49.167045, lng: 19.161816 },
    { lat: 49.16689, lng: 19.161902 },
    { lat: 49.166692, lng: 19.162052 },
    { lat: 49.166464, lng: 19.162171 },
    { lat: 49.166209, lng: 19.162394 },
    { lat: 49.16561, lng: 19.16286 },
    { lat: 49.165452, lng: 19.163262 },
    { lat: 49.16533, lng: 19.163799 },
    { lat: 49.165102, lng: 19.164398 },
    { lat: 49.16491, lng: 19.164647 },
    { lat: 49.164838, lng: 19.164719 },
    { lat: 49.16431, lng: 19.164841 },
    { lat: 49.164037, lng: 19.165268 },
    { lat: 49.163779, lng: 19.165942 },
    { lat: 49.163756, lng: 19.166259 },
    { lat: 49.163692, lng: 19.166499 },
    { lat: 49.16375, lng: 19.166897 },
    { lat: 49.163675, lng: 19.16717 },
    { lat: 49.163565, lng: 19.167478 },
    { lat: 49.163561, lng: 19.167669 },
    { lat: 49.163467, lng: 19.168048 },
    { lat: 49.163367, lng: 19.16819 },
    { lat: 49.163517, lng: 19.168487 },
    { lat: 49.1635383, lng: 19.1685488 },
    { lat: 49.163684, lng: 19.168971 },
    { lat: 49.163955, lng: 19.169632 },
    { lat: 49.164622, lng: 19.170264 },
    { lat: 49.164702, lng: 19.170873 },
    { lat: 49.164607, lng: 19.171539 },
    { lat: 49.164833, lng: 19.171958 },
    { lat: 49.164918, lng: 19.172455 },
    { lat: 49.164965, lng: 19.173588 },
    { lat: 49.16498, lng: 19.173719 },
    { lat: 49.165043, lng: 19.173975 },
    { lat: 49.165112, lng: 19.174446 },
    { lat: 49.165159, lng: 19.174982 },
    { lat: 49.165317, lng: 19.176192 },
    { lat: 49.165397, lng: 19.17691 },
    { lat: 49.165357, lng: 19.177221 },
    { lat: 49.165314, lng: 19.177703 },
    { lat: 49.165252, lng: 19.178337 },
    { lat: 49.165327, lng: 19.178707 },
    { lat: 49.16533, lng: 19.178973 },
    { lat: 49.16563, lng: 19.179417 },
    { lat: 49.165779, lng: 19.1802 },
    { lat: 49.165804, lng: 19.180577 },
    { lat: 49.165951, lng: 19.180964 },
    { lat: 49.165903, lng: 19.181721 },
    { lat: 49.165608, lng: 19.182562 },
    { lat: 49.165406, lng: 19.183042 },
    { lat: 49.165146, lng: 19.183641 },
    { lat: 49.16522, lng: 19.185008 },
    { lat: 49.165085, lng: 19.185631 },
    { lat: 49.164911, lng: 19.186157 },
    { lat: 49.164778, lng: 19.186593 },
    { lat: 49.164648, lng: 19.186793 },
    { lat: 49.164408, lng: 19.187405 },
    { lat: 49.164219, lng: 19.188613 },
    { lat: 49.16415, lng: 19.189264 },
    { lat: 49.163993, lng: 19.189974 },
    { lat: 49.163432, lng: 19.190661 },
    { lat: 49.163121, lng: 19.191646 },
    { lat: 49.162893, lng: 19.192214 },
    { lat: 49.162459, lng: 19.192687 },
    { lat: 49.162119, lng: 19.193068 },
    { lat: 49.161554, lng: 19.193812 },
    { lat: 49.16107, lng: 19.194412 },
    { lat: 49.16079, lng: 19.195019 },
    { lat: 49.160609, lng: 19.195217 },
    { lat: 49.160375, lng: 19.195391 },
    { lat: 49.159767, lng: 19.195896 },
    { lat: 49.159684, lng: 19.196052 },
    { lat: 49.158772, lng: 19.196156 },
    { lat: 49.158137, lng: 19.196798 },
    { lat: 49.157741, lng: 19.197188 },
    { lat: 49.156875, lng: 19.198267 },
    { lat: 49.156617, lng: 19.198471 },
    { lat: 49.156425, lng: 19.198679 },
    { lat: 49.156027, lng: 19.199186 },
    { lat: 49.155821, lng: 19.199725 },
    { lat: 49.155729, lng: 19.200058 },
    { lat: 49.155539, lng: 19.200339 },
    { lat: 49.155362, lng: 19.200323 },
    { lat: 49.155256, lng: 19.200266 },
    { lat: 49.154811, lng: 19.200379 },
    { lat: 49.154412, lng: 19.200576 },
    { lat: 49.153648, lng: 19.199899 },
    { lat: 49.153184, lng: 19.200887 },
    { lat: 49.152969, lng: 19.200849 },
    { lat: 49.152793, lng: 19.200817 },
    { lat: 49.152419, lng: 19.200872 },
    { lat: 49.152065, lng: 19.200882 },
    { lat: 49.151248, lng: 19.200924 },
    { lat: 49.150827, lng: 19.200901 },
    { lat: 49.150704, lng: 19.20048 },
    { lat: 49.150251, lng: 19.199848 },
    { lat: 49.149716, lng: 19.20038 },
    { lat: 49.149022, lng: 19.200643 },
    { lat: 49.148358, lng: 19.201378 },
    { lat: 49.14781, lng: 19.201996 },
    { lat: 49.147194, lng: 19.202566 },
    { lat: 49.146952, lng: 19.202708 },
    { lat: 49.146491, lng: 19.202836 },
    { lat: 49.145575, lng: 19.202841 },
    { lat: 49.144904, lng: 19.202286 },
    { lat: 49.144313, lng: 19.202097 },
    { lat: 49.144043, lng: 19.201882 },
    { lat: 49.14376, lng: 19.201868 },
    { lat: 49.142987, lng: 19.201656 },
    { lat: 49.142267, lng: 19.201213 },
    { lat: 49.142097, lng: 19.200824 },
    { lat: 49.141552, lng: 19.200534 },
    { lat: 49.140668, lng: 19.200172 },
    { lat: 49.139723, lng: 19.199424 },
    { lat: 49.139215, lng: 19.199293 },
    { lat: 49.13918, lng: 19.199731 },
    { lat: 49.139115, lng: 19.200685 },
    { lat: 49.139227, lng: 19.200946 },
    { lat: 49.139208, lng: 19.201409 },
    { lat: 49.139316, lng: 19.2018 },
    { lat: 49.139241, lng: 19.202535 },
    { lat: 49.139212, lng: 19.202828 },
    { lat: 49.139139, lng: 19.203158 },
    { lat: 49.139018, lng: 19.203793 },
    { lat: 49.138973, lng: 19.204529 },
    { lat: 49.138884, lng: 19.20486 },
    { lat: 49.1381, lng: 19.207791 },
    { lat: 49.138222, lng: 19.209117 },
    { lat: 49.138702, lng: 19.209072 },
    { lat: 49.139812, lng: 19.209335 },
    { lat: 49.140916, lng: 19.209687 },
    { lat: 49.141113, lng: 19.21029 },
    { lat: 49.141117, lng: 19.210515 },
    { lat: 49.141365, lng: 19.211023 },
    { lat: 49.14168, lng: 19.21123 },
    { lat: 49.14184, lng: 19.2117 },
    { lat: 49.143332, lng: 19.213517 },
    { lat: 49.143849, lng: 19.213956 },
    { lat: 49.14384, lng: 19.214178 },
    { lat: 49.144563, lng: 19.2146 },
    { lat: 49.14471, lng: 19.214982 },
    { lat: 49.145044, lng: 19.215354 },
    { lat: 49.145295, lng: 19.21618 },
    { lat: 49.145866, lng: 19.216654 },
    { lat: 49.146289, lng: 19.216914 },
    { lat: 49.14653, lng: 19.217181 },
    { lat: 49.146728, lng: 19.217434 },
    { lat: 49.147182, lng: 19.217699 },
    { lat: 49.147008, lng: 19.219679 },
    { lat: 49.146614, lng: 19.220323 },
    { lat: 49.146409, lng: 19.220952 },
    { lat: 49.146483, lng: 19.221037 },
    { lat: 49.146647, lng: 19.221226 },
    { lat: 49.146778, lng: 19.221377 },
    { lat: 49.146844, lng: 19.221492 },
    { lat: 49.146854, lng: 19.221508 },
    { lat: 49.147063, lng: 19.222037 },
    { lat: 49.147231, lng: 19.222465 },
    { lat: 49.147765, lng: 19.223053 },
    { lat: 49.148135, lng: 19.223463 },
    { lat: 49.14894, lng: 19.224871 },
    { lat: 49.149483, lng: 19.22592 },
    { lat: 49.150155, lng: 19.227277 },
    { lat: 49.150263, lng: 19.227462 },
    { lat: 49.150293, lng: 19.227531 },
    { lat: 49.150366, lng: 19.227698 },
    { lat: 49.150448, lng: 19.227883 },
    { lat: 49.15101, lng: 19.228982 },
    { lat: 49.15143, lng: 19.229696 },
    { lat: 49.151568, lng: 19.229995 },
    { lat: 49.15173, lng: 19.230539 },
    { lat: 49.151939, lng: 19.231233 },
    { lat: 49.152055, lng: 19.231434 },
    { lat: 49.152293, lng: 19.231643 },
    { lat: 49.152454, lng: 19.231746 },
    { lat: 49.152524, lng: 19.231791 },
    { lat: 49.153009, lng: 19.232097 },
    { lat: 49.153634, lng: 19.232636 },
    { lat: 49.154205, lng: 19.233033 },
    { lat: 49.15449, lng: 19.233851 },
    { lat: 49.154598, lng: 19.234021 },
    { lat: 49.154959, lng: 19.234588 },
    { lat: 49.15499, lng: 19.234645 },
    { lat: 49.155011, lng: 19.234685 },
    { lat: 49.155045, lng: 19.234747 },
    { lat: 49.155321, lng: 19.235262 },
    { lat: 49.15554, lng: 19.235828 },
    { lat: 49.15639, lng: 19.236718 },
    { lat: 49.156983, lng: 19.237666 },
    { lat: 49.157245, lng: 19.238259 },
    { lat: 49.157451, lng: 19.239145 },
    { lat: 49.157575, lng: 19.239887 },
    { lat: 49.157915, lng: 19.24054 },
    { lat: 49.158194, lng: 19.241035 },
    { lat: 49.158264, lng: 19.241159 },
    { lat: 49.158389, lng: 19.241382 },
    { lat: 49.158537, lng: 19.24173 },
    { lat: 49.158613, lng: 19.241911 },
    { lat: 49.15871, lng: 19.242215 },
    { lat: 49.158906, lng: 19.24251 },
    { lat: 49.159288, lng: 19.242867 },
    { lat: 49.159728, lng: 19.243171 },
    { lat: 49.15979, lng: 19.243301 },
    { lat: 49.160008, lng: 19.243993 },
    { lat: 49.160331, lng: 19.244661 },
    { lat: 49.160886, lng: 19.245541 },
    { lat: 49.161441, lng: 19.246426 },
    { lat: 49.161653, lng: 19.246577 },
    { lat: 49.161799, lng: 19.247031 },
    { lat: 49.161993, lng: 19.247227 },
    { lat: 49.162407, lng: 19.247641 },
    { lat: 49.162633, lng: 19.248095 },
    { lat: 49.162726, lng: 19.24828 },
    { lat: 49.16333, lng: 19.249252 },
    { lat: 49.163434, lng: 19.249402 },
    { lat: 49.163586, lng: 19.249622 },
    { lat: 49.163757, lng: 19.249869 },
    { lat: 49.16395, lng: 19.250149 },
    { lat: 49.163885, lng: 19.250434 },
    { lat: 49.163902, lng: 19.25075 },
    { lat: 49.164098, lng: 19.251476 },
    { lat: 49.164607, lng: 19.252891 },
    { lat: 49.16494, lng: 19.254215 },
    { lat: 49.165019, lng: 19.254984 },
    { lat: 49.16508, lng: 19.255569 },
    { lat: 49.165065, lng: 19.256329 },
    { lat: 49.165058, lng: 19.25668 },
    { lat: 49.164774, lng: 19.257654 },
    { lat: 49.164479, lng: 19.258218 },
    { lat: 49.164205, lng: 19.258916 },
    { lat: 49.163769, lng: 19.25992 },
    { lat: 49.163318, lng: 19.260639 },
    { lat: 49.163226, lng: 19.260872 },
    { lat: 49.163207, lng: 19.260921 },
    { lat: 49.162941, lng: 19.261594 },
    { lat: 49.1641879, lng: 19.2622779 },
    { lat: 49.1643996, lng: 19.2618931 },
    { lat: 49.1646594, lng: 19.2614829 },
    { lat: 49.1651549, lng: 19.2611177 },
    { lat: 49.1655568, lng: 19.260688 },
    { lat: 49.1659231, lng: 19.2606192 },
    { lat: 49.1666776, lng: 19.2608832 },
    { lat: 49.1670728, lng: 19.260876 },
    { lat: 49.1676367, lng: 19.2605771 },
    { lat: 49.1681766, lng: 19.260203 },
    { lat: 49.1687042, lng: 19.2599952 },
    { lat: 49.1688387, lng: 19.2596276 },
    { lat: 49.1692859, lng: 19.2594075 },
    { lat: 49.1694599, lng: 19.2591588 },
    { lat: 49.1695954, lng: 19.2589063 },
    { lat: 49.1698025, lng: 19.2580711 },
    { lat: 49.169762, lng: 19.2578408 },
    { lat: 49.1695983, lng: 19.2578312 },
    { lat: 49.1697013, lng: 19.2576091 },
    { lat: 49.1699616, lng: 19.2571565 },
    { lat: 49.1701043, lng: 19.2563234 },
    { lat: 49.1703037, lng: 19.2557804 },
    { lat: 49.170465, lng: 19.2556959 },
    { lat: 49.171014, lng: 19.2554085 },
    { lat: 49.1710352, lng: 19.2550978 },
    { lat: 49.1718734, lng: 19.2546678 },
    { lat: 49.17189, lng: 19.2546594 },
    { lat: 49.1720304, lng: 19.2546532 },
    { lat: 49.1721636, lng: 19.2544896 },
    { lat: 49.1721404, lng: 19.2540787 },
    { lat: 49.1722832, lng: 19.2536734 },
    { lat: 49.1726062, lng: 19.2533986 },
    { lat: 49.1729402, lng: 19.2531482 },
    { lat: 49.1732887, lng: 19.2526635 },
    { lat: 49.1735068, lng: 19.2525826 },
    { lat: 49.1736813, lng: 19.2522711 },
    { lat: 49.1743328, lng: 19.2522684 },
    { lat: 49.1748057, lng: 19.2519546 },
    { lat: 49.1749946, lng: 19.2518053 },
    { lat: 49.1752034, lng: 19.2515497 },
    { lat: 49.1754633, lng: 19.2509296 },
    { lat: 49.1755138, lng: 19.2502882 },
    { lat: 49.1756425, lng: 19.2500116 },
    { lat: 49.1758543, lng: 19.2497751 },
    { lat: 49.1762453, lng: 19.2492103 },
    { lat: 49.1763342, lng: 19.248784 },
    { lat: 49.1767532, lng: 19.2483299 },
    { lat: 49.176669, lng: 19.2477953 },
    { lat: 49.176778, lng: 19.2475771 },
    { lat: 49.1770483, lng: 19.2476421 },
    { lat: 49.1771671, lng: 19.2474664 },
    { lat: 49.1773344, lng: 19.247241 },
    { lat: 49.1775634, lng: 19.2468937 },
    { lat: 49.1777752, lng: 19.2462073 },
    { lat: 49.1779618, lng: 19.2460366 },
    { lat: 49.1783018, lng: 19.2456248 },
    { lat: 49.1786692, lng: 19.245154 },
    { lat: 49.1790933, lng: 19.2448077 },
    { lat: 49.1792973, lng: 19.2441268 },
    { lat: 49.1794922, lng: 19.2437736 },
    { lat: 49.1795774, lng: 19.243388 },
    { lat: 49.17975, lng: 19.2433436 },
    { lat: 49.1799149, lng: 19.2430002 },
    { lat: 49.1801691, lng: 19.2428685 },
    { lat: 49.1803111, lng: 19.2423778 },
    { lat: 49.1806708, lng: 19.2420772 },
    { lat: 49.180829, lng: 19.2416384 },
    { lat: 49.1809714, lng: 19.2413617 },
    { lat: 49.1813983, lng: 19.2408421 },
    { lat: 49.1817807, lng: 19.2406812 },
    { lat: 49.1822844, lng: 19.240188 },
    { lat: 49.1824925, lng: 19.2396168 },
    { lat: 49.1827472, lng: 19.23924 },
    { lat: 49.1831111, lng: 19.2387915 },
    { lat: 49.1833868, lng: 19.2385716 },
    { lat: 49.1834238, lng: 19.2381899 },
    { lat: 49.1837136, lng: 19.2379597 },
    { lat: 49.1838649, lng: 19.2374446 },
    { lat: 49.184088, lng: 19.2373757 },
    { lat: 49.1841023, lng: 19.2371067 },
    { lat: 49.1843431, lng: 19.2370071 },
    { lat: 49.184355, lng: 19.2367622 },
    { lat: 49.1846322, lng: 19.2364077 },
    { lat: 49.1847496, lng: 19.2359654 },
    { lat: 49.1852945, lng: 19.2354841 },
    { lat: 49.1855338, lng: 19.2354213 },
    { lat: 49.1855645, lng: 19.2350511 },
    { lat: 49.1857982, lng: 19.2349209 },
    { lat: 49.1858696, lng: 19.2345749 },
    { lat: 49.1861987, lng: 19.2334018 },
    { lat: 49.1864003, lng: 19.2330946 },
    { lat: 49.1863832, lng: 19.232693 },
    { lat: 49.1869169, lng: 19.2321173 },
    { lat: 49.1872353, lng: 19.2320839 },
    { lat: 49.1873007, lng: 19.2320135 },
    { lat: 49.1875072, lng: 19.2317854 },
    { lat: 49.1877408, lng: 19.2316725 },
    { lat: 49.1878859, lng: 19.2313597 },
    { lat: 49.1881182, lng: 19.2309161 },
    { lat: 49.1885172, lng: 19.230402 },
    { lat: 49.1889781, lng: 19.2299069 },
    { lat: 49.1893183, lng: 19.2295507 },
    { lat: 49.1894252, lng: 19.2295495 },
    { lat: 49.189468, lng: 19.2295491 },
    { lat: 49.1898901, lng: 19.2295441 },
    { lat: 49.1903939, lng: 19.2295381 },
    { lat: 49.190887, lng: 19.2291032 },
    { lat: 49.1912658, lng: 19.228823 },
    { lat: 49.1913518, lng: 19.2287597 },
    { lat: 49.1915967, lng: 19.2284155 },
    { lat: 49.1919763, lng: 19.2282011 },
    { lat: 49.1921584, lng: 19.2282168 },
    { lat: 49.1922577, lng: 19.2281452 },
    { lat: 49.1928429, lng: 19.2279417 },
    { lat: 49.1936503, lng: 19.2275717 },
    { lat: 49.1936755, lng: 19.2275606 },
    { lat: 49.1936811, lng: 19.2275582 },
    { lat: 49.1936733, lng: 19.2275154 },
    { lat: 49.193667, lng: 19.2274772 },
    { lat: 49.193664, lng: 19.2274581 },
    { lat: 49.1936639, lng: 19.2274563 },
    { lat: 49.19354, lng: 19.2267811 },
    { lat: 49.1934778, lng: 19.2263755 },
    { lat: 49.1930965, lng: 19.2249389 },
    { lat: 49.1930871, lng: 19.2249061 },
    { lat: 49.1930085, lng: 19.2247747 },
    { lat: 49.1929779, lng: 19.2245601 },
    { lat: 49.1929268, lng: 19.224412 },
    { lat: 49.1927706, lng: 19.2239531 },
    { lat: 49.1927614, lng: 19.223762 },
    { lat: 49.19273, lng: 19.2235317 },
    { lat: 49.1927008, lng: 19.2228755 },
    { lat: 49.192619, lng: 19.2219708 },
    { lat: 49.1926197, lng: 19.2216115 },
    { lat: 49.1926521, lng: 19.2212298 },
    { lat: 49.1928539, lng: 19.2199896 },
    { lat: 49.1928485, lng: 19.2197595 },
    { lat: 49.1930166, lng: 19.2189933 },
  ],
  OravskáPoruba: [
    { lat: 49.162941, lng: 19.261594 },
    { lat: 49.162747, lng: 19.262334 },
    { lat: 49.162516, lng: 19.262914 },
    { lat: 49.162409, lng: 19.263271 },
    { lat: 49.162418, lng: 19.263941 },
    { lat: 49.162701, lng: 19.264806 },
    { lat: 49.16299, lng: 19.265389 },
    { lat: 49.1640957, lng: 19.2653255 },
    { lat: 49.1645016, lng: 19.2656355 },
    { lat: 49.1648525, lng: 19.2657564 },
    { lat: 49.1652827, lng: 19.2659424 },
    { lat: 49.165665, lng: 19.2659917 },
    { lat: 49.1664452, lng: 19.2660491 },
    { lat: 49.1668727, lng: 19.2664306 },
    { lat: 49.1679418, lng: 19.2677446 },
    { lat: 49.168526, lng: 19.2682225 },
    { lat: 49.1690301, lng: 19.2686801 },
    { lat: 49.1690119, lng: 19.2687257 },
    { lat: 49.1697175, lng: 19.269694 },
    { lat: 49.1697845, lng: 19.2698703 },
    { lat: 49.1708016, lng: 19.2710619 },
    { lat: 49.1708294, lng: 19.2710743 },
    { lat: 49.1719382, lng: 19.2714019 },
    { lat: 49.1724531, lng: 19.2723677 },
    { lat: 49.1729986, lng: 19.2739521 },
    { lat: 49.1734939, lng: 19.2762558 },
    { lat: 49.1740224, lng: 19.2776527 },
    { lat: 49.174508, lng: 19.2787134 },
    { lat: 49.174639, lng: 19.279049 },
    { lat: 49.1749774, lng: 19.2798817 },
    { lat: 49.1760428, lng: 19.2820215 },
    { lat: 49.1766584, lng: 19.2833037 },
    { lat: 49.1770286, lng: 19.2840758 },
    { lat: 49.1775608, lng: 19.2844113 },
    { lat: 49.1779009, lng: 19.2844514 },
    { lat: 49.1783826, lng: 19.2841755 },
    { lat: 49.178705, lng: 19.2833721 },
    { lat: 49.1795639, lng: 19.2843256 },
    { lat: 49.1803466, lng: 19.2834129 },
    { lat: 49.1808512, lng: 19.2833826 },
    { lat: 49.1809396, lng: 19.2832237 },
    { lat: 49.1810423, lng: 19.2831593 },
    { lat: 49.1810883, lng: 19.283887 },
    { lat: 49.1818051, lng: 19.2837701 },
    { lat: 49.1820944, lng: 19.2849266 },
    { lat: 49.1823045, lng: 19.2852661 },
    { lat: 49.1823192, lng: 19.2852187 },
    { lat: 49.1839879, lng: 19.2868897 },
    { lat: 49.1839627, lng: 19.287265 },
    { lat: 49.1842108, lng: 19.2874306 },
    { lat: 49.1849726, lng: 19.2873157 },
    { lat: 49.1850656, lng: 19.2876077 },
    { lat: 49.1850727, lng: 19.287662 },
    { lat: 49.1851158, lng: 19.2882611 },
    { lat: 49.1855915, lng: 19.2896629 },
    { lat: 49.1860276, lng: 19.2906094 },
    { lat: 49.1863613, lng: 19.2906852 },
    { lat: 49.1867814, lng: 19.2915784 },
    { lat: 49.1879414, lng: 19.2921299 },
    { lat: 49.1876196, lng: 19.2933538 },
    { lat: 49.1875807, lng: 19.2936946 },
    { lat: 49.1887815, lng: 19.2935182 },
    { lat: 49.1895254, lng: 19.2940204 },
    { lat: 49.1900904, lng: 19.2933609 },
    { lat: 49.1905249, lng: 19.2930426 },
    { lat: 49.1901312, lng: 19.2917044 },
    { lat: 49.1907903, lng: 19.2908002 },
    { lat: 49.1908083, lng: 19.2907755 },
    { lat: 49.1908888, lng: 19.290665 },
    { lat: 49.1912166, lng: 19.2910431 },
    { lat: 49.1912347, lng: 19.2909961 },
    { lat: 49.1916392, lng: 19.2903802 },
    { lat: 49.1916686, lng: 19.2902389 },
    { lat: 49.1916793, lng: 19.2900846 },
    { lat: 49.1919173, lng: 19.2900388 },
    { lat: 49.1922639, lng: 19.2901979 },
    { lat: 49.1924245, lng: 19.2900184 },
    { lat: 49.1932058, lng: 19.2896473 },
    { lat: 49.1939039, lng: 19.2898457 },
    { lat: 49.1943831, lng: 19.2899703 },
    { lat: 49.1947828, lng: 19.2898634 },
    { lat: 49.1950906, lng: 19.2906055 },
    { lat: 49.1956459, lng: 19.290334 },
    { lat: 49.1958716, lng: 19.2902932 },
    { lat: 49.1961706, lng: 19.2902784 },
    { lat: 49.1965634, lng: 19.2904335 },
    { lat: 49.1967751, lng: 19.2905961 },
    { lat: 49.1970977, lng: 19.2910771 },
    { lat: 49.1975436, lng: 19.2917341 },
    { lat: 49.1980755, lng: 19.292516 },
    { lat: 49.1983421, lng: 19.2929773 },
    { lat: 49.19883, lng: 19.2936398 },
    { lat: 49.1992695, lng: 19.2936899 },
    { lat: 49.1992935, lng: 19.2935972 },
    { lat: 49.1992963, lng: 19.2935861 },
    { lat: 49.1994374, lng: 19.2930465 },
    { lat: 49.1996146, lng: 19.2925321 },
    { lat: 49.1997466, lng: 19.2918897 },
    { lat: 49.2001991, lng: 19.2913344 },
    { lat: 49.2006465, lng: 19.2910733 },
    { lat: 49.2007755, lng: 19.2905617 },
    { lat: 49.2012535, lng: 19.2896688 },
    { lat: 49.2017114, lng: 19.288893 },
    { lat: 49.2022858, lng: 19.2884315 },
    { lat: 49.2029234, lng: 19.2880679 },
    { lat: 49.2030581, lng: 19.287991 },
    { lat: 49.2035395, lng: 19.287302 },
    { lat: 49.2040463, lng: 19.2866818 },
    { lat: 49.2040463, lng: 19.2866811 },
    { lat: 49.2045952, lng: 19.2863745 },
    { lat: 49.2045767, lng: 19.2854384 },
    { lat: 49.2044649, lng: 19.2847032 },
    { lat: 49.2042305, lng: 19.2840141 },
    { lat: 49.2038737, lng: 19.2838309 },
    { lat: 49.2037463, lng: 19.2837149 },
    { lat: 49.2037247, lng: 19.2834737 },
    { lat: 49.2037242, lng: 19.2829425 },
    { lat: 49.2045447, lng: 19.2823993 },
    { lat: 49.2053572, lng: 19.2826588 },
    { lat: 49.2055572, lng: 19.2826186 },
    { lat: 49.205575, lng: 19.2826149 },
    { lat: 49.2057319, lng: 19.2818384 },
    { lat: 49.2059009, lng: 19.2814138 },
    { lat: 49.2060502, lng: 19.281092 },
    { lat: 49.2062454, lng: 19.2808815 },
    { lat: 49.2065052, lng: 19.2808228 },
    { lat: 49.206896, lng: 19.2806029 },
    { lat: 49.2073367, lng: 19.2806091 },
    { lat: 49.2076732, lng: 19.2808115 },
    { lat: 49.2080547, lng: 19.2804214 },
    { lat: 49.2081881, lng: 19.2801932 },
    { lat: 49.2085373, lng: 19.2797404 },
    { lat: 49.2085695, lng: 19.2797508 },
    { lat: 49.2086554, lng: 19.2797795 },
    { lat: 49.2088023, lng: 19.2792939 },
    { lat: 49.2088531, lng: 19.2791257 },
    { lat: 49.2088802, lng: 19.2790341 },
    { lat: 49.2089448, lng: 19.2788748 },
    { lat: 49.208976, lng: 19.2787956 },
    { lat: 49.2090738, lng: 19.2785523 },
    { lat: 49.2095838, lng: 19.2775488 },
    { lat: 49.2102896, lng: 19.2769424 },
    { lat: 49.2103605, lng: 19.2767998 },
    { lat: 49.2106037, lng: 19.2761982 },
    { lat: 49.2106177, lng: 19.2761646 },
    { lat: 49.2106232, lng: 19.2758042 },
    { lat: 49.2109807, lng: 19.2742455 },
    { lat: 49.2110888, lng: 19.2728518 },
    { lat: 49.2111697, lng: 19.2726569 },
    { lat: 49.2112662, lng: 19.2724576 },
    { lat: 49.2112681, lng: 19.2724516 },
    { lat: 49.2113521, lng: 19.2722271 },
    { lat: 49.211374, lng: 19.2721677 },
    { lat: 49.2109386, lng: 19.2714011 },
    { lat: 49.2104473, lng: 19.2707408 },
    { lat: 49.2097181, lng: 19.2698446 },
    { lat: 49.2093892, lng: 19.2694457 },
    { lat: 49.2090509, lng: 19.2689682 },
    { lat: 49.2085557, lng: 19.2684151 },
    { lat: 49.208355, lng: 19.268272 },
    { lat: 49.2078942, lng: 19.2679433 },
    { lat: 49.2066426, lng: 19.2671546 },
    { lat: 49.2057464, lng: 19.2663917 },
    { lat: 49.2051273, lng: 19.2657973 },
    { lat: 49.2047766, lng: 19.2652971 },
    { lat: 49.2042527, lng: 19.2647552 },
    { lat: 49.2037082, lng: 19.2639951 },
    { lat: 49.2032264, lng: 19.2633231 },
    { lat: 49.2024714, lng: 19.2622149 },
    { lat: 49.2021152, lng: 19.2619333 },
    { lat: 49.2020283, lng: 19.2617657 },
    { lat: 49.2014075, lng: 19.2605654 },
    { lat: 49.2008096, lng: 19.2590202 },
    { lat: 49.2004058, lng: 19.2573407 },
    { lat: 49.2001879, lng: 19.2558004 },
    { lat: 49.2000975, lng: 19.2545228 },
    { lat: 49.2000689, lng: 19.2537814 },
    { lat: 49.2000138, lng: 19.2527929 },
    { lat: 49.2000049, lng: 19.2521624 },
    { lat: 49.2000982, lng: 19.2513783 },
    { lat: 49.200108, lng: 19.2512784 },
    { lat: 49.2001272, lng: 19.2508784 },
    { lat: 49.1999442, lng: 19.2508696 },
    { lat: 49.1996584, lng: 19.250855 },
    { lat: 49.199577, lng: 19.2508514 },
    { lat: 49.1994856, lng: 19.2508468 },
    { lat: 49.1994494, lng: 19.2508447 },
    { lat: 49.1994119, lng: 19.2508612 },
    { lat: 49.1991961, lng: 19.2510142 },
    { lat: 49.1991557, lng: 19.2510696 },
    { lat: 49.1990754, lng: 19.251178 },
    { lat: 49.1989189, lng: 19.251416 },
    { lat: 49.1988914, lng: 19.2514335 },
    { lat: 49.1988783, lng: 19.2514414 },
    { lat: 49.1988299, lng: 19.2514414 },
    { lat: 49.1987626, lng: 19.2513325 },
    { lat: 49.1987666, lng: 19.2512733 },
    { lat: 49.1987753, lng: 19.2511502 },
    { lat: 49.198794, lng: 19.2509718 },
    { lat: 49.1988331, lng: 19.2505898 },
    { lat: 49.1988619, lng: 19.2506043 },
    { lat: 49.1988697, lng: 19.2505313 },
    { lat: 49.1988794, lng: 19.2504715 },
    { lat: 49.1988891, lng: 19.2503936 },
    { lat: 49.1988423, lng: 19.2503823 },
    { lat: 49.1988915, lng: 19.2497137 },
    { lat: 49.1990513, lng: 19.2497323 },
    { lat: 49.1990633, lng: 19.2496062 },
    { lat: 49.1990709, lng: 19.2494108 },
    { lat: 49.1990829, lng: 19.2494051 },
    { lat: 49.1991018, lng: 19.2491378 },
    { lat: 49.199111, lng: 19.2491183 },
    { lat: 49.1991042, lng: 19.249096 },
    { lat: 49.1992988, lng: 19.2490906 },
    { lat: 49.1993337, lng: 19.2490632 },
    { lat: 49.1992929, lng: 19.2488937 },
    { lat: 49.1993258, lng: 19.2486176 },
    { lat: 49.1993073, lng: 19.2485382 },
    { lat: 49.1992986, lng: 19.2484934 },
    { lat: 49.1992596, lng: 19.2483154 },
    { lat: 49.1993271, lng: 19.2480534 },
    { lat: 49.1994201, lng: 19.2479724 },
    { lat: 49.1994364, lng: 19.2479832 },
    { lat: 49.1996493, lng: 19.2481235 },
    { lat: 49.1996939, lng: 19.2481131 },
    { lat: 49.1997099, lng: 19.2480929 },
    { lat: 49.199683, lng: 19.2480278 },
    { lat: 49.1997312, lng: 19.2480441 },
    { lat: 49.1998695, lng: 19.2480859 },
    { lat: 49.1999256, lng: 19.2481028 },
    { lat: 49.1999735, lng: 19.2481172 },
    { lat: 49.2001096, lng: 19.2481579 },
    { lat: 49.2003983, lng: 19.2482551 },
    { lat: 49.2004741, lng: 19.2479529 },
    { lat: 49.2006811, lng: 19.246885 },
    { lat: 49.20081, lng: 19.2463755 },
    { lat: 49.2006542, lng: 19.2462797 },
    { lat: 49.2004905, lng: 19.246179 },
    { lat: 49.2001469, lng: 19.2459679 },
    { lat: 49.1999199, lng: 19.2458203 },
    { lat: 49.1998654, lng: 19.2457856 },
    { lat: 49.1997954, lng: 19.2457401 },
    { lat: 49.1998294, lng: 19.2455777 },
    { lat: 49.1997512, lng: 19.2454286 },
    { lat: 49.199739, lng: 19.2454051 },
    { lat: 49.1996111, lng: 19.2451858 },
    { lat: 49.1994834, lng: 19.2450098 },
    { lat: 49.1993981, lng: 19.2449252 },
    { lat: 49.1992315, lng: 19.2448094 },
    { lat: 49.1991177, lng: 19.2447655 },
    { lat: 49.1990556, lng: 19.2447425 },
    { lat: 49.1989529, lng: 19.2447136 },
    { lat: 49.1987733, lng: 19.2447066 },
    { lat: 49.1987371, lng: 19.2446807 },
    { lat: 49.1987103, lng: 19.244683 },
    { lat: 49.1986644, lng: 19.2446409 },
    { lat: 49.1985712, lng: 19.2445555 },
    { lat: 49.1984314, lng: 19.2443912 },
    { lat: 49.1983449, lng: 19.2442574 },
    { lat: 49.1982495, lng: 19.2440504 },
    { lat: 49.1982075, lng: 19.2439041 },
    { lat: 49.1982033, lng: 19.2434854 },
    { lat: 49.198244, lng: 19.2433259 },
    { lat: 49.198272, lng: 19.2432057 },
    { lat: 49.1983206, lng: 19.2431394 },
    { lat: 49.1983546, lng: 19.2430264 },
    { lat: 49.1982723, lng: 19.2424578 },
    { lat: 49.1980954, lng: 19.2418008 },
    { lat: 49.1981611, lng: 19.2413646 },
    { lat: 49.1981963, lng: 19.2411772 },
    { lat: 49.1982973, lng: 19.2406982 },
    { lat: 49.1984421, lng: 19.2403335 },
    { lat: 49.1986225, lng: 19.2400088 },
    { lat: 49.1988353, lng: 19.239722 },
    { lat: 49.1990416, lng: 19.2394395 },
    { lat: 49.1991858, lng: 19.2390882 },
    { lat: 49.1993464, lng: 19.2388425 },
    { lat: 49.1993691, lng: 19.2388918 },
    { lat: 49.1994047, lng: 19.2389989 },
    { lat: 49.1994113, lng: 19.2389942 },
    { lat: 49.1994397, lng: 19.2389755 },
    { lat: 49.1994756, lng: 19.2389513 },
    { lat: 49.1995445, lng: 19.238905 },
    { lat: 49.1996146, lng: 19.2388846 },
    { lat: 49.1998248, lng: 19.2388748 },
    { lat: 49.1998319, lng: 19.2389047 },
    { lat: 49.200317, lng: 19.2387957 },
    { lat: 49.2005337, lng: 19.2387535 },
    { lat: 49.2007487, lng: 19.2387273 },
    { lat: 49.2010311, lng: 19.2386954 },
    { lat: 49.2010828, lng: 19.2387394 },
    { lat: 49.2011276, lng: 19.2387776 },
    { lat: 49.2011498, lng: 19.2387964 },
    { lat: 49.2011651, lng: 19.2388109 },
    { lat: 49.201204, lng: 19.2388448 },
    { lat: 49.2012452, lng: 19.2388798 },
    { lat: 49.201276, lng: 19.2389113 },
    { lat: 49.2013256, lng: 19.2389616 },
    { lat: 49.2014051, lng: 19.2390432 },
    { lat: 49.2014337, lng: 19.2390728 },
    { lat: 49.2014644, lng: 19.2391036 },
    { lat: 49.2015581, lng: 19.2391995 },
    { lat: 49.2015555, lng: 19.2391468 },
    { lat: 49.2015449, lng: 19.2389736 },
    { lat: 49.2015394, lng: 19.2388585 },
    { lat: 49.2015386, lng: 19.2388434 },
    { lat: 49.2015201, lng: 19.2386682 },
    { lat: 49.2015112, lng: 19.2385769 },
    { lat: 49.201492, lng: 19.2383891 },
    { lat: 49.2014859, lng: 19.2383296 },
    { lat: 49.2014708, lng: 19.2381777 },
    { lat: 49.2014581, lng: 19.2380566 },
    { lat: 49.2014396, lng: 19.2378814 },
    { lat: 49.2014222, lng: 19.2377053 },
    { lat: 49.2014118, lng: 19.2376077 },
    { lat: 49.2014071, lng: 19.2375588 },
    { lat: 49.2014026, lng: 19.2375085 },
    { lat: 49.2013883, lng: 19.2373786 },
    { lat: 49.2013794, lng: 19.2372862 },
    { lat: 49.2013707, lng: 19.2371996 },
    { lat: 49.2013666, lng: 19.2371568 },
    { lat: 49.2013525, lng: 19.2370506 },
    { lat: 49.2013416, lng: 19.2369653 },
    { lat: 49.2013255, lng: 19.2368424 },
    { lat: 49.2013072, lng: 19.2366967 },
    { lat: 49.2013015, lng: 19.2366476 },
    { lat: 49.2012907, lng: 19.2365648 },
    { lat: 49.2012747, lng: 19.2364434 },
    { lat: 49.2012651, lng: 19.2363608 },
    { lat: 49.2012545, lng: 19.2362784 },
    { lat: 49.2012331, lng: 19.2361136 },
    { lat: 49.2011852, lng: 19.2357293 },
    { lat: 49.2011762, lng: 19.2356557 },
    { lat: 49.201167, lng: 19.2355818 },
    { lat: 49.2011578, lng: 19.2355086 },
    { lat: 49.2011498, lng: 19.2354359 },
    { lat: 49.2011444, lng: 19.2353972 },
    { lat: 49.2011407, lng: 19.2353638 },
    { lat: 49.2011261, lng: 19.2352472 },
    { lat: 49.2011116, lng: 19.2351306 },
    { lat: 49.2011076, lng: 19.2350961 },
    { lat: 49.2011024, lng: 19.2350567 },
    { lat: 49.2010949, lng: 19.2349941 },
    { lat: 49.2010909, lng: 19.2349617 },
    { lat: 49.2010584, lng: 19.2349192 },
    { lat: 49.2009969, lng: 19.2348814 },
    { lat: 49.2009645, lng: 19.234862 },
    { lat: 49.2009353, lng: 19.2348436 },
    { lat: 49.2009052, lng: 19.2348261 },
    { lat: 49.200875, lng: 19.2348074 },
    { lat: 49.2008448, lng: 19.2347888 },
    { lat: 49.2008146, lng: 19.2347702 },
    { lat: 49.2007531, lng: 19.2347328 },
    { lat: 49.2007415, lng: 19.2347265 },
    { lat: 49.2006601, lng: 19.2346758 },
    { lat: 49.200601, lng: 19.2346384 },
    { lat: 49.2005079, lng: 19.23458 },
    { lat: 49.2004128, lng: 19.2345211 },
    { lat: 49.2003512, lng: 19.2344829 },
    { lat: 49.200228, lng: 19.2344058 },
    { lat: 49.2002012, lng: 19.234389 },
    { lat: 49.2001652, lng: 19.2343674 },
    { lat: 49.2000632, lng: 19.2343032 },
    { lat: 49.1999945, lng: 19.2342604 },
    { lat: 49.1998911, lng: 19.2341963 },
    { lat: 49.1998234, lng: 19.2341494 },
    { lat: 49.1997895, lng: 19.2341233 },
    { lat: 49.1997557, lng: 19.2340964 },
    { lat: 49.1997215, lng: 19.2340696 },
    { lat: 49.1996877, lng: 19.2340435 },
    { lat: 49.1996536, lng: 19.234017 },
    { lat: 49.1996197, lng: 19.2339898 },
    { lat: 49.1995843, lng: 19.2339566 },
    { lat: 49.1995146, lng: 19.2338913 },
    { lat: 49.199478, lng: 19.2338575 },
    { lat: 49.1994108, lng: 19.2337948 },
    { lat: 49.1994085, lng: 19.2337918 },
    { lat: 49.1992732, lng: 19.2336501 },
    { lat: 49.1991949, lng: 19.2335684 },
    { lat: 49.1991546, lng: 19.2335264 },
    { lat: 49.1991474, lng: 19.233519 },
    { lat: 49.1991154, lng: 19.2334858 },
    { lat: 49.1990763, lng: 19.2334454 },
    { lat: 49.1990359, lng: 19.2334049 },
    { lat: 49.1990123, lng: 19.2333798 },
    { lat: 49.1989661, lng: 19.2333327 },
    { lat: 49.1988368, lng: 19.2331987 },
    { lat: 49.1988023, lng: 19.2331643 },
    { lat: 49.1987885, lng: 19.2331569 },
    { lat: 49.198644, lng: 19.2330775 },
    { lat: 49.1985906, lng: 19.2330489 },
    { lat: 49.1985745, lng: 19.2330435 },
    { lat: 49.1985484, lng: 19.2333459 },
    { lat: 49.1984329, lng: 19.2344371 },
    { lat: 49.198411, lng: 19.2346597 },
    { lat: 49.1984002, lng: 19.2354672 },
    { lat: 49.19839, lng: 19.2354679 },
    { lat: 49.1983787, lng: 19.2354685 },
    { lat: 49.1983584, lng: 19.235443 },
    { lat: 49.1983094, lng: 19.2353829 },
    { lat: 49.1982986, lng: 19.2353693 },
    { lat: 49.1982421, lng: 19.2352989 },
    { lat: 49.1981858, lng: 19.2352284 },
    { lat: 49.1981223, lng: 19.235149 },
    { lat: 49.198073, lng: 19.2350878 },
    { lat: 49.1979929, lng: 19.2349872 },
    { lat: 49.1979052, lng: 19.2348777 },
    { lat: 49.19788, lng: 19.2348459 },
    { lat: 49.1978643, lng: 19.2348232 },
    { lat: 49.1978015, lng: 19.2347344 },
    { lat: 49.1977713, lng: 19.2346909 },
    { lat: 49.1977556, lng: 19.2346685 },
    { lat: 49.1977102, lng: 19.2345939 },
    { lat: 49.1976036, lng: 19.2344159 },
    { lat: 49.1975497, lng: 19.2343261 },
    { lat: 49.1973902, lng: 19.2340592 },
    { lat: 49.1972288, lng: 19.2337897 },
    { lat: 49.1971233, lng: 19.2336144 },
    { lat: 49.1968785, lng: 19.2332097 },
    { lat: 49.1968454, lng: 19.2331543 },
    { lat: 49.1968233, lng: 19.2331164 },
    { lat: 49.1967989, lng: 19.2330759 },
    { lat: 49.1967768, lng: 19.2330391 },
    { lat: 49.1967535, lng: 19.2330009 },
    { lat: 49.1967291, lng: 19.2329597 },
    { lat: 49.1967045, lng: 19.2329192 },
    { lat: 49.1966593, lng: 19.2328439 },
    { lat: 49.196593, lng: 19.232732 },
    { lat: 49.1965476, lng: 19.2326571 },
    { lat: 49.1964791, lng: 19.2325437 },
    { lat: 49.1964569, lng: 19.2325051 },
    { lat: 49.1964336, lng: 19.2324666 },
    { lat: 49.1964104, lng: 19.2324277 },
    { lat: 49.1963871, lng: 19.2323889 },
    { lat: 49.1963406, lng: 19.2323119 },
    { lat: 49.1962876, lng: 19.2325208 },
    { lat: 49.1962513, lng: 19.2326603 },
    { lat: 49.1962425, lng: 19.2329446 },
    { lat: 49.1962375, lng: 19.2330994 },
    { lat: 49.1962353, lng: 19.2331735 },
    { lat: 49.1962682, lng: 19.2333864 },
    { lat: 49.1962581, lng: 19.2335731 },
    { lat: 49.1962378, lng: 19.2336914 },
    { lat: 49.1961938, lng: 19.2338091 },
    { lat: 49.1961848, lng: 19.2338332 },
    { lat: 49.1961397, lng: 19.2339265 },
    { lat: 49.1961166, lng: 19.2339619 },
    { lat: 49.1959896, lng: 19.2341552 },
    { lat: 49.1958681, lng: 19.2342802 },
    { lat: 49.1957573, lng: 19.2343639 },
    { lat: 49.1957365, lng: 19.2343774 },
    { lat: 49.1956965, lng: 19.2342723 },
    { lat: 49.19534, lng: 19.2333503 },
    { lat: 49.1953237, lng: 19.2333612 },
    { lat: 49.1952691, lng: 19.2333979 },
    { lat: 49.1949371, lng: 19.233621 },
    { lat: 49.194721, lng: 19.2337691 },
    { lat: 49.194591, lng: 19.2331966 },
    { lat: 49.1945755, lng: 19.2330837 },
    { lat: 49.1945281, lng: 19.2327085 },
    { lat: 49.1944908, lng: 19.2322348 },
    { lat: 49.1944752, lng: 19.2319598 },
    { lat: 49.1944161, lng: 19.2304446 },
    { lat: 49.194388, lng: 19.2304931 },
    { lat: 49.1943296, lng: 19.2305915 },
    { lat: 49.1943203, lng: 19.2306081 },
    { lat: 49.1942918, lng: 19.230816 },
    { lat: 49.1942853, lng: 19.2308682 },
    { lat: 49.1942036, lng: 19.2314626 },
    { lat: 49.1940741, lng: 19.2315153 },
    { lat: 49.1937197, lng: 19.2315272 },
    { lat: 49.1936275, lng: 19.2309457 },
    { lat: 49.193529, lng: 19.2303254 },
    { lat: 49.1935211, lng: 19.2303288 },
    { lat: 49.1934672, lng: 19.2303514 },
    { lat: 49.1934019, lng: 19.23038 },
    { lat: 49.1932816, lng: 19.2304313 },
    { lat: 49.1931733, lng: 19.2304773 },
    { lat: 49.1930872, lng: 19.2305139 },
    { lat: 49.1930453, lng: 19.230532 },
    { lat: 49.1930022, lng: 19.2305505 },
    { lat: 49.192959, lng: 19.2305693 },
    { lat: 49.1928828, lng: 19.2306016 },
    { lat: 49.1927979, lng: 19.2306385 },
    { lat: 49.1927549, lng: 19.2306563 },
    { lat: 49.1927063, lng: 19.2306769 },
    { lat: 49.1925581, lng: 19.2307403 },
    { lat: 49.1923705, lng: 19.2308207 },
    { lat: 49.1916956, lng: 19.2311085 },
    { lat: 49.1916657, lng: 19.2311208 },
    { lat: 49.1916567, lng: 19.2310959 },
    { lat: 49.19162, lng: 19.230989 },
    { lat: 49.1915992, lng: 19.2309301 },
    { lat: 49.1915624, lng: 19.2308228 },
    { lat: 49.1915337, lng: 19.230741 },
    { lat: 49.1915076, lng: 19.2306657 },
    { lat: 49.1914708, lng: 19.2305592 },
    { lat: 49.1914501, lng: 19.2305006 },
    { lat: 49.1914058, lng: 19.2303748 },
    { lat: 49.1913639, lng: 19.2302559 },
    { lat: 49.1913442, lng: 19.230199 },
    { lat: 49.1913235, lng: 19.2301411 },
    { lat: 49.1913038, lng: 19.2300853 },
    { lat: 49.1912646, lng: 19.2299729 },
    { lat: 49.191224, lng: 19.2298564 },
    { lat: 49.1912099, lng: 19.2298173 },
    { lat: 49.191199, lng: 19.2298026 },
    { lat: 49.1911651, lng: 19.2297556 },
    { lat: 49.1910372, lng: 19.2295807 },
    { lat: 49.1909926, lng: 19.229518 },
    { lat: 49.1909777, lng: 19.2294674 },
    { lat: 49.1908926, lng: 19.2291714 },
    { lat: 49.1908901, lng: 19.2291446 },
    { lat: 49.1908873, lng: 19.2291093 },
    { lat: 49.190887, lng: 19.2291032 },
    { lat: 49.1903939, lng: 19.2295381 },
    { lat: 49.1898901, lng: 19.2295441 },
    { lat: 49.189468, lng: 19.2295491 },
    { lat: 49.1894252, lng: 19.2295495 },
    { lat: 49.1893183, lng: 19.2295507 },
    { lat: 49.1889781, lng: 19.2299069 },
    { lat: 49.1885172, lng: 19.230402 },
    { lat: 49.1881182, lng: 19.2309161 },
    { lat: 49.1878859, lng: 19.2313597 },
    { lat: 49.1877408, lng: 19.2316725 },
    { lat: 49.1875072, lng: 19.2317854 },
    { lat: 49.1873007, lng: 19.2320135 },
    { lat: 49.1872353, lng: 19.2320839 },
    { lat: 49.1869169, lng: 19.2321173 },
    { lat: 49.1863832, lng: 19.232693 },
    { lat: 49.1864003, lng: 19.2330946 },
    { lat: 49.1861987, lng: 19.2334018 },
    { lat: 49.1858696, lng: 19.2345749 },
    { lat: 49.1857982, lng: 19.2349209 },
    { lat: 49.1855645, lng: 19.2350511 },
    { lat: 49.1855338, lng: 19.2354213 },
    { lat: 49.1852945, lng: 19.2354841 },
    { lat: 49.1847496, lng: 19.2359654 },
    { lat: 49.1846322, lng: 19.2364077 },
    { lat: 49.184355, lng: 19.2367622 },
    { lat: 49.1843431, lng: 19.2370071 },
    { lat: 49.1841023, lng: 19.2371067 },
    { lat: 49.184088, lng: 19.2373757 },
    { lat: 49.1838649, lng: 19.2374446 },
    { lat: 49.1837136, lng: 19.2379597 },
    { lat: 49.1834238, lng: 19.2381899 },
    { lat: 49.1833868, lng: 19.2385716 },
    { lat: 49.1831111, lng: 19.2387915 },
    { lat: 49.1827472, lng: 19.23924 },
    { lat: 49.1824925, lng: 19.2396168 },
    { lat: 49.1822844, lng: 19.240188 },
    { lat: 49.1817807, lng: 19.2406812 },
    { lat: 49.1813983, lng: 19.2408421 },
    { lat: 49.1809714, lng: 19.2413617 },
    { lat: 49.180829, lng: 19.2416384 },
    { lat: 49.1806708, lng: 19.2420772 },
    { lat: 49.1803111, lng: 19.2423778 },
    { lat: 49.1801691, lng: 19.2428685 },
    { lat: 49.1799149, lng: 19.2430002 },
    { lat: 49.17975, lng: 19.2433436 },
    { lat: 49.1795774, lng: 19.243388 },
    { lat: 49.1794922, lng: 19.2437736 },
    { lat: 49.1792973, lng: 19.2441268 },
    { lat: 49.1790933, lng: 19.2448077 },
    { lat: 49.1786692, lng: 19.245154 },
    { lat: 49.1783018, lng: 19.2456248 },
    { lat: 49.1779618, lng: 19.2460366 },
    { lat: 49.1777752, lng: 19.2462073 },
    { lat: 49.1775634, lng: 19.2468937 },
    { lat: 49.1773344, lng: 19.247241 },
    { lat: 49.1771671, lng: 19.2474664 },
    { lat: 49.1770483, lng: 19.2476421 },
    { lat: 49.176778, lng: 19.2475771 },
    { lat: 49.176669, lng: 19.2477953 },
    { lat: 49.1767532, lng: 19.2483299 },
    { lat: 49.1763342, lng: 19.248784 },
    { lat: 49.1762453, lng: 19.2492103 },
    { lat: 49.1758543, lng: 19.2497751 },
    { lat: 49.1756425, lng: 19.2500116 },
    { lat: 49.1755138, lng: 19.2502882 },
    { lat: 49.1754633, lng: 19.2509296 },
    { lat: 49.1752034, lng: 19.2515497 },
    { lat: 49.1749946, lng: 19.2518053 },
    { lat: 49.1748057, lng: 19.2519546 },
    { lat: 49.1743328, lng: 19.2522684 },
    { lat: 49.1736813, lng: 19.2522711 },
    { lat: 49.1735068, lng: 19.2525826 },
    { lat: 49.1732887, lng: 19.2526635 },
    { lat: 49.1729402, lng: 19.2531482 },
    { lat: 49.1726062, lng: 19.2533986 },
    { lat: 49.1722832, lng: 19.2536734 },
    { lat: 49.1721404, lng: 19.2540787 },
    { lat: 49.1721636, lng: 19.2544896 },
    { lat: 49.1720304, lng: 19.2546532 },
    { lat: 49.17189, lng: 19.2546594 },
    { lat: 49.1718734, lng: 19.2546678 },
    { lat: 49.1710352, lng: 19.2550978 },
    { lat: 49.171014, lng: 19.2554085 },
    { lat: 49.170465, lng: 19.2556959 },
    { lat: 49.1703037, lng: 19.2557804 },
    { lat: 49.1701043, lng: 19.2563234 },
    { lat: 49.1699616, lng: 19.2571565 },
    { lat: 49.1697013, lng: 19.2576091 },
    { lat: 49.1695983, lng: 19.2578312 },
    { lat: 49.169762, lng: 19.2578408 },
    { lat: 49.1698025, lng: 19.2580711 },
    { lat: 49.1695954, lng: 19.2589063 },
    { lat: 49.1694599, lng: 19.2591588 },
    { lat: 49.1692859, lng: 19.2594075 },
    { lat: 49.1688387, lng: 19.2596276 },
    { lat: 49.1687042, lng: 19.2599952 },
    { lat: 49.1681766, lng: 19.260203 },
    { lat: 49.1676367, lng: 19.2605771 },
    { lat: 49.1670728, lng: 19.260876 },
    { lat: 49.1666776, lng: 19.2608832 },
    { lat: 49.1659231, lng: 19.2606192 },
    { lat: 49.1655568, lng: 19.260688 },
    { lat: 49.1651549, lng: 19.2611177 },
    { lat: 49.1646594, lng: 19.2614829 },
    { lat: 49.1643996, lng: 19.2618931 },
    { lat: 49.1641879, lng: 19.2622779 },
    { lat: 49.162941, lng: 19.261594 },
  ],
  DolnýKubín: [
    { lat: 49.2793777, lng: 19.2457541 },
    { lat: 49.2786232, lng: 19.2459359 },
    { lat: 49.2730273, lng: 19.2454067 },
    { lat: 49.270195, lng: 19.2427413 },
    { lat: 49.2689366, lng: 19.241931 },
    { lat: 49.2688657, lng: 19.2418859 },
    { lat: 49.269874, lng: 19.2462361 },
    { lat: 49.2692178, lng: 19.2465837 },
    { lat: 49.2683196, lng: 19.2470564 },
    { lat: 49.267707, lng: 19.2473923 },
    { lat: 49.2672517, lng: 19.2476304 },
    { lat: 49.2667785, lng: 19.2478677 },
    { lat: 49.2651016, lng: 19.2486958 },
    { lat: 49.2643479, lng: 19.249148 },
    { lat: 49.264034, lng: 19.2487367 },
    { lat: 49.2636212, lng: 19.2482097 },
    { lat: 49.2632779, lng: 19.2470402 },
    { lat: 49.2625501, lng: 19.2472344 },
    { lat: 49.2615368, lng: 19.2474234 },
    { lat: 49.2615145, lng: 19.2474273 },
    { lat: 49.2595492, lng: 19.2482818 },
    { lat: 49.2594226, lng: 19.2482479 },
    { lat: 49.259307, lng: 19.2482601 },
    { lat: 49.2591946, lng: 19.2483592 },
    { lat: 49.2591187, lng: 19.2484698 },
    { lat: 49.2590604, lng: 19.2485002 },
    { lat: 49.2589459, lng: 19.248601 },
    { lat: 49.2588969, lng: 19.2487111 },
    { lat: 49.2588419, lng: 19.2488294 },
    { lat: 49.2587659, lng: 19.2489649 },
    { lat: 49.2587609, lng: 19.2491163 },
    { lat: 49.2587921, lng: 19.2492261 },
    { lat: 49.2587563, lng: 19.2493763 },
    { lat: 49.2587242, lng: 19.2495303 },
    { lat: 49.2586135, lng: 19.2496152 },
    { lat: 49.2584912, lng: 19.2497237 },
    { lat: 49.2583753, lng: 19.2498442 },
    { lat: 49.2582467, lng: 19.2499101 },
    { lat: 49.2580376, lng: 19.2499241 },
    { lat: 49.2578948, lng: 19.2499786 },
    { lat: 49.2577479, lng: 19.2500885 },
    { lat: 49.2575738, lng: 19.2502905 },
    { lat: 49.2574361, lng: 19.2503776 },
    { lat: 49.2572528, lng: 19.2503624 },
    { lat: 49.2571906, lng: 19.2504754 },
    { lat: 49.2570002, lng: 19.2506413 },
    { lat: 49.2567856, lng: 19.2506328 },
    { lat: 49.2567669, lng: 19.2506183 },
    { lat: 49.2566501, lng: 19.2504423 },
    { lat: 49.2565814, lng: 19.2504864 },
    { lat: 49.2565301, lng: 19.2505192 },
    { lat: 49.2564623, lng: 19.2505635 },
    { lat: 49.2563666, lng: 19.2506615 },
    { lat: 49.2562972, lng: 19.2507912 },
    { lat: 49.2560533, lng: 19.2508761 },
    { lat: 49.2559732, lng: 19.2508955 },
    { lat: 49.2559171, lng: 19.250878 },
    { lat: 49.2558056, lng: 19.250902 },
    { lat: 49.2557214, lng: 19.2509341 },
    { lat: 49.2555966, lng: 19.250847 },
    { lat: 49.2554138, lng: 19.2509352 },
    { lat: 49.2553105, lng: 19.2509882 },
    { lat: 49.2552187, lng: 19.2510977 },
    { lat: 49.2550833, lng: 19.2512095 },
    { lat: 49.2550324, lng: 19.2512069 },
    { lat: 49.2549636, lng: 19.2513246 },
    { lat: 49.2548632, lng: 19.2513686 },
    { lat: 49.2547949, lng: 19.2513077 },
    { lat: 49.2547303, lng: 19.2512557 },
    { lat: 49.2545201, lng: 19.2514293 },
    { lat: 49.2544274, lng: 19.2515406 },
    { lat: 49.2543956, lng: 19.2515853 },
    { lat: 49.2542686, lng: 19.2515223 },
    { lat: 49.254231, lng: 19.2515618 },
    { lat: 49.2540994, lng: 19.251566 },
    { lat: 49.2539431, lng: 19.2516899 },
    { lat: 49.2538572, lng: 19.2516854 },
    { lat: 49.2538257, lng: 19.2516157 },
    { lat: 49.253794, lng: 19.2515402 },
    { lat: 49.2537587, lng: 19.2515323 },
    { lat: 49.2536265, lng: 19.2516469 },
    { lat: 49.2535805, lng: 19.2516477 },
    { lat: 49.2534598, lng: 19.2517676 },
    { lat: 49.2533135, lng: 19.2517941 },
    { lat: 49.2532422, lng: 19.2518096 },
    { lat: 49.2531037, lng: 19.2515559 },
    { lat: 49.2529727, lng: 19.2515716 },
    { lat: 49.2525885, lng: 19.2514777 },
    { lat: 49.2524668, lng: 19.2515505 },
    { lat: 49.2522317, lng: 19.2515146 },
    { lat: 49.2519949, lng: 19.2515353 },
    { lat: 49.2517841, lng: 19.2515804 },
    { lat: 49.251699, lng: 19.2516397 },
    { lat: 49.2516202, lng: 19.2516907 },
    { lat: 49.2515324, lng: 19.2518794 },
    { lat: 49.2513581, lng: 19.2519332 },
    { lat: 49.2511888, lng: 19.2519787 },
    { lat: 49.2511361, lng: 19.2519832 },
    { lat: 49.2510295, lng: 19.2518728 },
    { lat: 49.2509121, lng: 19.2518704 },
    { lat: 49.2507972, lng: 19.2518236 },
    { lat: 49.2506459, lng: 19.2521031 },
    { lat: 49.2504627, lng: 19.2522527 },
    { lat: 49.2504082, lng: 19.2522938 },
    { lat: 49.2503129, lng: 19.2523488 },
    { lat: 49.2501761, lng: 19.2523872 },
    { lat: 49.2500841, lng: 19.2523505 },
    { lat: 49.2499448, lng: 19.2524434 },
    { lat: 49.2498471, lng: 19.2525306 },
    { lat: 49.2497525, lng: 19.2525325 },
    { lat: 49.249519, lng: 19.2527851 },
    { lat: 49.2494313, lng: 19.2529075 },
    { lat: 49.2493398, lng: 19.2530432 },
    { lat: 49.2490565, lng: 19.2529676 },
    { lat: 49.2490498, lng: 19.2529662 },
    { lat: 49.2488781, lng: 19.2529601 },
    { lat: 49.2488404, lng: 19.2529739 },
    { lat: 49.2486442, lng: 19.2529931 },
    { lat: 49.2484014, lng: 19.2530313 },
    { lat: 49.2483133, lng: 19.2530274 },
    { lat: 49.2480446, lng: 19.2530188 },
    { lat: 49.2478401, lng: 19.2530091 },
    { lat: 49.2477375, lng: 19.253193 },
    { lat: 49.2476555, lng: 19.2533414 },
    { lat: 49.2474687, lng: 19.2536086 },
    { lat: 49.2473512, lng: 19.2539078 },
    { lat: 49.2472394, lng: 19.2538806 },
    { lat: 49.2468549, lng: 19.2539433 },
    { lat: 49.2468163, lng: 19.2540103 },
    { lat: 49.2467944, lng: 19.2540474 },
    { lat: 49.2466455, lng: 19.2542072 },
    { lat: 49.2466277, lng: 19.2542601 },
    { lat: 49.2466234, lng: 19.2543374 },
    { lat: 49.2465226, lng: 19.2544929 },
    { lat: 49.2464298, lng: 19.2545328 },
    { lat: 49.246363, lng: 19.2547351 },
    { lat: 49.2462454, lng: 19.2548441 },
    { lat: 49.2461337, lng: 19.2548883 },
    { lat: 49.2460577, lng: 19.254904 },
    { lat: 49.245971, lng: 19.2550223 },
    { lat: 49.2459096, lng: 19.2551557 },
    { lat: 49.2458063, lng: 19.2552787 },
    { lat: 49.2457264, lng: 19.2554236 },
    { lat: 49.2457137, lng: 19.2555596 },
    { lat: 49.2456424, lng: 19.2557176 },
    { lat: 49.2456463, lng: 19.2557994 },
    { lat: 49.2456296, lng: 19.2558503 },
    { lat: 49.2455917, lng: 19.2559068 },
    { lat: 49.2455788, lng: 19.2560136 },
    { lat: 49.2454737, lng: 19.2561952 },
    { lat: 49.2453989, lng: 19.2562811 },
    { lat: 49.2453269, lng: 19.2564046 },
    { lat: 49.2450926, lng: 19.25643 },
    { lat: 49.2450035, lng: 19.256735 },
    { lat: 49.2449142, lng: 19.2568232 },
    { lat: 49.2448602, lng: 19.256875 },
    { lat: 49.2447489, lng: 19.2569264 },
    { lat: 49.2446025, lng: 19.2571162 },
    { lat: 49.2445931, lng: 19.257062 },
    { lat: 49.2443907, lng: 19.257002 },
    { lat: 49.2443087, lng: 19.2568881 },
    { lat: 49.2441679, lng: 19.2567235 },
    { lat: 49.2440624, lng: 19.2566411 },
    { lat: 49.2439145, lng: 19.256611 },
    { lat: 49.2438098, lng: 19.2565895 },
    { lat: 49.2437139, lng: 19.2566132 },
    { lat: 49.2433912, lng: 19.256933 },
    { lat: 49.2433913, lng: 19.2569337 },
    { lat: 49.2432252, lng: 19.2571596 },
    { lat: 49.2431447, lng: 19.2572689 },
    { lat: 49.2431374, lng: 19.2572801 },
    { lat: 49.2430504, lng: 19.257397 },
    { lat: 49.2429944, lng: 19.2574746 },
    { lat: 49.2429393, lng: 19.2574537 },
    { lat: 49.2427373, lng: 19.2573777 },
    { lat: 49.2427228, lng: 19.2573812 },
    { lat: 49.2425213, lng: 19.2574285 },
    { lat: 49.2424141, lng: 19.2574538 },
    { lat: 49.242304, lng: 19.2574336 },
    { lat: 49.2419584, lng: 19.2573722 },
    { lat: 49.2416039, lng: 19.2573623 },
    { lat: 49.241323, lng: 19.2573546 },
    { lat: 49.2411157, lng: 19.2573063 },
    { lat: 49.2410882, lng: 19.2572996 },
    { lat: 49.2410271, lng: 19.2569641 },
    { lat: 49.2410087, lng: 19.2568605 },
    { lat: 49.2409032, lng: 19.256566 },
    { lat: 49.2407662, lng: 19.2563869 },
    { lat: 49.2407549, lng: 19.2563636 },
    { lat: 49.2406078, lng: 19.2560669 },
    { lat: 49.2405381, lng: 19.2559282 },
    { lat: 49.2404521, lng: 19.2557553 },
    { lat: 49.2403959, lng: 19.2556415 },
    { lat: 49.2403764, lng: 19.2556087 },
    { lat: 49.2401196, lng: 19.2551918 },
    { lat: 49.240061, lng: 19.2550977 },
    { lat: 49.2399524, lng: 19.2549933 },
    { lat: 49.2399381, lng: 19.2546007 },
    { lat: 49.2399378, lng: 19.2545942 },
    { lat: 49.2399065, lng: 19.2544794 },
    { lat: 49.2398424, lng: 19.2542482 },
    { lat: 49.2398069, lng: 19.2541194 },
    { lat: 49.2397944, lng: 19.2540706 },
    { lat: 49.2397537, lng: 19.2540193 },
    { lat: 49.2396914, lng: 19.2539415 },
    { lat: 49.2396675, lng: 19.2539106 },
    { lat: 49.2396388, lng: 19.2538283 },
    { lat: 49.2396035, lng: 19.2537283 },
    { lat: 49.2395882, lng: 19.2537145 },
    { lat: 49.2394187, lng: 19.2535675 },
    { lat: 49.239392, lng: 19.2535719 },
    { lat: 49.23923, lng: 19.2536005 },
    { lat: 49.2390948, lng: 19.2536238 },
    { lat: 49.2390623, lng: 19.2536055 },
    { lat: 49.2388573, lng: 19.2534927 },
    { lat: 49.2385733, lng: 19.2531835 },
    { lat: 49.2385394, lng: 19.2530181 },
    { lat: 49.2383369, lng: 19.2530245 },
    { lat: 49.2381012, lng: 19.2531186 },
    { lat: 49.2378374, lng: 19.2529763 },
    { lat: 49.2377343, lng: 19.2533387 },
    { lat: 49.2375706, lng: 19.2532394 },
    { lat: 49.2372185, lng: 19.2531355 },
    { lat: 49.2370491, lng: 19.2542858 },
    { lat: 49.2368119, lng: 19.2541813 },
    { lat: 49.2367027, lng: 19.2541332 },
    { lat: 49.2365635, lng: 19.2540708 },
    { lat: 49.2361207, lng: 19.2536968 },
    { lat: 49.2360939, lng: 19.2538667 },
    { lat: 49.2360658, lng: 19.2540347 },
    { lat: 49.2360377, lng: 19.254203 },
    { lat: 49.2360056, lng: 19.2544017 },
    { lat: 49.2359967, lng: 19.2544074 },
    { lat: 49.2358756, lng: 19.2544883 },
    { lat: 49.2357369, lng: 19.2545806 },
    { lat: 49.2354407, lng: 19.2547777 },
    { lat: 49.2353709, lng: 19.2548244 },
    { lat: 49.2352945, lng: 19.2548755 },
    { lat: 49.2352913, lng: 19.2548777 },
    { lat: 49.235071, lng: 19.2551247 },
    { lat: 49.2349989, lng: 19.2552222 },
    { lat: 49.2349175, lng: 19.255333 },
    { lat: 49.2349037, lng: 19.2553515 },
    { lat: 49.2348094, lng: 19.2555272 },
    { lat: 49.2347639, lng: 19.2556123 },
    { lat: 49.2347364, lng: 19.2556507 },
    { lat: 49.2345724, lng: 19.2558717 },
    { lat: 49.2345647, lng: 19.2558786 },
    { lat: 49.234482, lng: 19.2559604 },
    { lat: 49.2344601, lng: 19.2559527 },
    { lat: 49.2343592, lng: 19.255916 },
    { lat: 49.2340002, lng: 19.2557865 },
    { lat: 49.233611, lng: 19.2558031 },
    { lat: 49.2332008, lng: 19.2557327 },
    { lat: 49.2324838, lng: 19.2556099 },
    { lat: 49.2322382, lng: 19.2556092 },
    { lat: 49.2321491, lng: 19.2556086 },
    { lat: 49.2319635, lng: 19.2556643 },
    { lat: 49.2312048, lng: 19.2556356 },
    { lat: 49.2309249, lng: 19.255625 },
    { lat: 49.2307416, lng: 19.2556829 },
    { lat: 49.2305037, lng: 19.2555889 },
    { lat: 49.2303539, lng: 19.2554931 },
    { lat: 49.2300122, lng: 19.2552759 },
    { lat: 49.2299594, lng: 19.2552576 },
    { lat: 49.229767, lng: 19.2551903 },
    { lat: 49.2293355, lng: 19.2549579 },
    { lat: 49.2290568, lng: 19.2548349 },
    { lat: 49.2288576, lng: 19.2547475 },
    { lat: 49.2288131, lng: 19.2549483 },
    { lat: 49.228803, lng: 19.254993 },
    { lat: 49.2281322, lng: 19.254661 },
    { lat: 49.2281323, lng: 19.2546621 },
    { lat: 49.2281034, lng: 19.2547205 },
    { lat: 49.2279117, lng: 19.2551165 },
    { lat: 49.2278461, lng: 19.2552576 },
    { lat: 49.2278433, lng: 19.2553866 },
    { lat: 49.2277579, lng: 19.2554131 },
    { lat: 49.2276217, lng: 19.2556309 },
    { lat: 49.2273904, lng: 19.2560004 },
    { lat: 49.2272805, lng: 19.256176 },
    { lat: 49.2272291, lng: 19.256257 },
    { lat: 49.2271694, lng: 19.2563524 },
    { lat: 49.2271422, lng: 19.2563962 },
    { lat: 49.2270892, lng: 19.2564673 },
    { lat: 49.226958, lng: 19.2566468 },
    { lat: 49.2267761, lng: 19.2568942 },
    { lat: 49.2267055, lng: 19.2569035 },
    { lat: 49.2266719, lng: 19.2569079 },
    { lat: 49.2265783, lng: 19.2568113 },
    { lat: 49.2265565, lng: 19.2568307 },
    { lat: 49.2264961, lng: 19.256885 },
    { lat: 49.2264747, lng: 19.2569068 },
    { lat: 49.2264476, lng: 19.2569333 },
    { lat: 49.2264351, lng: 19.2569545 },
    { lat: 49.2264016, lng: 19.2570094 },
    { lat: 49.2263674, lng: 19.2570684 },
    { lat: 49.2263287, lng: 19.2571333 },
    { lat: 49.2262744, lng: 19.2572237 },
    { lat: 49.2262191, lng: 19.2573164 },
    { lat: 49.2261044, lng: 19.2575102 },
    { lat: 49.2259657, lng: 19.2577424 },
    { lat: 49.2260117, lng: 19.2578793 },
    { lat: 49.2261338, lng: 19.2582393 },
    { lat: 49.225316, lng: 19.2589578 },
    { lat: 49.2253149, lng: 19.2589583 },
    { lat: 49.2250674, lng: 19.2590637 },
    { lat: 49.2249566, lng: 19.2590573 },
    { lat: 49.2247132, lng: 19.2591028 },
    { lat: 49.2245278, lng: 19.2592565 },
    { lat: 49.2245135, lng: 19.2593817 },
    { lat: 49.2243667, lng: 19.259468 },
    { lat: 49.2242964, lng: 19.259509 },
    { lat: 49.2239943, lng: 19.2593653 },
    { lat: 49.2235988, lng: 19.2594363 },
    { lat: 49.2234635, lng: 19.2594615 },
    { lat: 49.2233935, lng: 19.2594101 },
    { lat: 49.2233796, lng: 19.2594002 },
    { lat: 49.2232698, lng: 19.2593215 },
    { lat: 49.2232617, lng: 19.2593156 },
    { lat: 49.2230802, lng: 19.2591998 },
    { lat: 49.2229462, lng: 19.2591077 },
    { lat: 49.2227413, lng: 19.2592048 },
    { lat: 49.2225466, lng: 19.2593306 },
    { lat: 49.2225181, lng: 19.2593468 },
    { lat: 49.2222873, lng: 19.2594944 },
    { lat: 49.2222776, lng: 19.2596671 },
    { lat: 49.2222046, lng: 19.2597898 },
    { lat: 49.222061, lng: 19.2600178 },
    { lat: 49.2220402, lng: 19.2600508 },
    { lat: 49.2219847, lng: 19.2601395 },
    { lat: 49.2217783, lng: 19.2604672 },
    { lat: 49.2217407, lng: 19.260506 },
    { lat: 49.2215526, lng: 19.2607014 },
    { lat: 49.2215359, lng: 19.2607271 },
    { lat: 49.2214214, lng: 19.2608754 },
    { lat: 49.2211897, lng: 19.2611481 },
    { lat: 49.2210795, lng: 19.2612491 },
    { lat: 49.2206958, lng: 19.261591 },
    { lat: 49.2206763, lng: 19.2616084 },
    { lat: 49.2205832, lng: 19.2614734 },
    { lat: 49.2205469, lng: 19.2614944 },
    { lat: 49.220457, lng: 19.2615469 },
    { lat: 49.2204024, lng: 19.261579 },
    { lat: 49.2203617, lng: 19.2616026 },
    { lat: 49.2202608, lng: 19.2616607 },
    { lat: 49.2201488, lng: 19.2617247 },
    { lat: 49.219904, lng: 19.2617898 },
    { lat: 49.2198652, lng: 19.2617797 },
    { lat: 49.2198607, lng: 19.2617781 },
    { lat: 49.2198265, lng: 19.2617696 },
    { lat: 49.2195253, lng: 19.2619758 },
    { lat: 49.2195077, lng: 19.2619129 },
    { lat: 49.2194955, lng: 19.2618717 },
    { lat: 49.2194607, lng: 19.2618745 },
    { lat: 49.2193786, lng: 19.2618804 },
    { lat: 49.2192934, lng: 19.261887 },
    { lat: 49.2192113, lng: 19.2618936 },
    { lat: 49.2191395, lng: 19.2618991 },
    { lat: 49.2191303, lng: 19.2618741 },
    { lat: 49.2190914, lng: 19.2617674 },
    { lat: 49.2190867, lng: 19.2617629 },
    { lat: 49.2190502, lng: 19.2617291 },
    { lat: 49.2188622, lng: 19.2615413 },
    { lat: 49.21881, lng: 19.2615355 },
    { lat: 49.2187532, lng: 19.2615292 },
    { lat: 49.2185824, lng: 19.2614686 },
    { lat: 49.218344, lng: 19.261383 },
    { lat: 49.2181445, lng: 19.261452 },
    { lat: 49.2179182, lng: 19.2615302 },
    { lat: 49.2179255, lng: 19.2612089 },
    { lat: 49.2179281, lng: 19.26105 },
    { lat: 49.217901, lng: 19.26077 },
    { lat: 49.2178928, lng: 19.2606919 },
    { lat: 49.2178917, lng: 19.2605017 },
    { lat: 49.2178901, lng: 19.2603494 },
    { lat: 49.2178899, lng: 19.2603256 },
    { lat: 49.2178317, lng: 19.2601208 },
    { lat: 49.2176381, lng: 19.2598395 },
    { lat: 49.2173516, lng: 19.2594341 },
    { lat: 49.2171547, lng: 19.2592758 },
    { lat: 49.2169983, lng: 19.2590715 },
    { lat: 49.2168427, lng: 19.258906 },
    { lat: 49.216654, lng: 19.2587525 },
    { lat: 49.2165191, lng: 19.2585682 },
    { lat: 49.216436, lng: 19.2584109 },
    { lat: 49.2163511, lng: 19.2582401 },
    { lat: 49.2162195, lng: 19.2580561 },
    { lat: 49.2161851, lng: 19.2581125 },
    { lat: 49.2160986, lng: 19.2582581 },
    { lat: 49.2158622, lng: 19.2585941 },
    { lat: 49.2156194, lng: 19.2589167 },
    { lat: 49.21506, lng: 19.2598511 },
    { lat: 49.2150343, lng: 19.2599029 },
    { lat: 49.2148944, lng: 19.2601817 },
    { lat: 49.2146908, lng: 19.260593 },
    { lat: 49.2144958, lng: 19.2612765 },
    { lat: 49.2144211, lng: 19.261482 },
    { lat: 49.2143878, lng: 19.2615665 },
    { lat: 49.2141361, lng: 19.2622647 },
    { lat: 49.2139609, lng: 19.2628184 },
    { lat: 49.213872, lng: 19.263391 },
    { lat: 49.2138331, lng: 19.2636408 },
    { lat: 49.2137679, lng: 19.26404 },
    { lat: 49.2137518, lng: 19.2641081 },
    { lat: 49.213749, lng: 19.2641192 },
    { lat: 49.213646, lng: 19.2645586 },
    { lat: 49.2136945, lng: 19.2648434 },
    { lat: 49.2137122, lng: 19.2649506 },
    { lat: 49.2137815, lng: 19.2652944 },
    { lat: 49.2137859, lng: 19.2656241 },
    { lat: 49.2137875, lng: 19.2657526 },
    { lat: 49.2137944, lng: 19.2662043 },
    { lat: 49.2137439, lng: 19.2667286 },
    { lat: 49.2136812, lng: 19.2670875 },
    { lat: 49.2135337, lng: 19.2674013 },
    { lat: 49.2134522, lng: 19.2676288 },
    { lat: 49.2134407, lng: 19.2679332 },
    { lat: 49.2134344, lng: 19.2683654 },
    { lat: 49.2134265, lng: 19.2683879 },
    { lat: 49.2133662, lng: 19.2685691 },
    { lat: 49.2133534, lng: 19.2686087 },
    { lat: 49.2133184, lng: 19.2687456 },
    { lat: 49.213304, lng: 19.2688013 },
    { lat: 49.2132738, lng: 19.2689197 },
    { lat: 49.2132558, lng: 19.2689927 },
    { lat: 49.2132482, lng: 19.2690224 },
    { lat: 49.2132378, lng: 19.2690657 },
    { lat: 49.2132229, lng: 19.2691308 },
    { lat: 49.2132004, lng: 19.2692285 },
    { lat: 49.2131677, lng: 19.2693698 },
    { lat: 49.2130862, lng: 19.2696204 },
    { lat: 49.2130506, lng: 19.2697271 },
    { lat: 49.2130212, lng: 19.2698172 },
    { lat: 49.213016, lng: 19.2698282 },
    { lat: 49.2129432, lng: 19.2699553 },
    { lat: 49.2128703, lng: 19.2700816 },
    { lat: 49.2128185, lng: 19.2701735 },
    { lat: 49.2127957, lng: 19.2702161 },
    { lat: 49.2127171, lng: 19.270365 },
    { lat: 49.212625, lng: 19.2705386 },
    { lat: 49.2124471, lng: 19.2707743 },
    { lat: 49.2121812, lng: 19.2710122 },
    { lat: 49.2120508, lng: 19.2710904 },
    { lat: 49.211672, lng: 19.2711603 },
    { lat: 49.2116672, lng: 19.2712033 },
    { lat: 49.2116639, lng: 19.2712289 },
    { lat: 49.2116626, lng: 19.2712467 },
    { lat: 49.2116503, lng: 19.271344 },
    { lat: 49.2116391, lng: 19.2714404 },
    { lat: 49.2116328, lng: 19.2714973 },
    { lat: 49.2116272, lng: 19.2715434 },
    { lat: 49.2115975, lng: 19.271609 },
    { lat: 49.2115394, lng: 19.2717377 },
    { lat: 49.2115202, lng: 19.2717816 },
    { lat: 49.2115018, lng: 19.2718215 },
    { lat: 49.2114908, lng: 19.2718534 },
    { lat: 49.2114798, lng: 19.2718802 },
    { lat: 49.211374, lng: 19.2721677 },
    { lat: 49.2113521, lng: 19.2722271 },
    { lat: 49.2112681, lng: 19.2724516 },
    { lat: 49.2112662, lng: 19.2724576 },
    { lat: 49.2111697, lng: 19.2726569 },
    { lat: 49.2110888, lng: 19.2728518 },
    { lat: 49.2109807, lng: 19.2742455 },
    { lat: 49.2106232, lng: 19.2758042 },
    { lat: 49.2106177, lng: 19.2761646 },
    { lat: 49.2106037, lng: 19.2761982 },
    { lat: 49.2103605, lng: 19.2767998 },
    { lat: 49.2102896, lng: 19.2769424 },
    { lat: 49.2095838, lng: 19.2775488 },
    { lat: 49.2090738, lng: 19.2785523 },
    { lat: 49.208976, lng: 19.2787956 },
    { lat: 49.2089448, lng: 19.2788748 },
    { lat: 49.2088802, lng: 19.2790341 },
    { lat: 49.2088531, lng: 19.2791257 },
    { lat: 49.2088023, lng: 19.2792939 },
    { lat: 49.2086554, lng: 19.2797795 },
    { lat: 49.2085695, lng: 19.2797508 },
    { lat: 49.2085373, lng: 19.2797404 },
    { lat: 49.2081881, lng: 19.2801932 },
    { lat: 49.2080547, lng: 19.2804214 },
    { lat: 49.2076732, lng: 19.2808115 },
    { lat: 49.2073367, lng: 19.2806091 },
    { lat: 49.206896, lng: 19.2806029 },
    { lat: 49.2065052, lng: 19.2808228 },
    { lat: 49.2062454, lng: 19.2808815 },
    { lat: 49.2060502, lng: 19.281092 },
    { lat: 49.2059009, lng: 19.2814138 },
    { lat: 49.2057319, lng: 19.2818384 },
    { lat: 49.205575, lng: 19.2826149 },
    { lat: 49.2055572, lng: 19.2826186 },
    { lat: 49.2053572, lng: 19.2826588 },
    { lat: 49.2045447, lng: 19.2823993 },
    { lat: 49.2037242, lng: 19.2829425 },
    { lat: 49.2037247, lng: 19.2834737 },
    { lat: 49.2037463, lng: 19.2837149 },
    { lat: 49.2038737, lng: 19.2838309 },
    { lat: 49.2042305, lng: 19.2840141 },
    { lat: 49.2044649, lng: 19.2847032 },
    { lat: 49.2045767, lng: 19.2854384 },
    { lat: 49.2045952, lng: 19.2863745 },
    { lat: 49.2040463, lng: 19.2866811 },
    { lat: 49.2040463, lng: 19.2866818 },
    { lat: 49.2035395, lng: 19.287302 },
    { lat: 49.2030581, lng: 19.287991 },
    { lat: 49.2029234, lng: 19.2880679 },
    { lat: 49.2022858, lng: 19.2884315 },
    { lat: 49.2017114, lng: 19.288893 },
    { lat: 49.2012535, lng: 19.2896688 },
    { lat: 49.2007755, lng: 19.2905617 },
    { lat: 49.2006465, lng: 19.2910733 },
    { lat: 49.2001991, lng: 19.2913344 },
    { lat: 49.1997466, lng: 19.2918897 },
    { lat: 49.1996146, lng: 19.2925321 },
    { lat: 49.1994374, lng: 19.2930465 },
    { lat: 49.1992963, lng: 19.2935861 },
    { lat: 49.1992935, lng: 19.2935972 },
    { lat: 49.1992695, lng: 19.2936899 },
    { lat: 49.19883, lng: 19.2936398 },
    { lat: 49.1983421, lng: 19.2929773 },
    { lat: 49.1980755, lng: 19.292516 },
    { lat: 49.1975436, lng: 19.2917341 },
    { lat: 49.1970977, lng: 19.2910771 },
    { lat: 49.1967751, lng: 19.2905961 },
    { lat: 49.1965634, lng: 19.2904335 },
    { lat: 49.1961706, lng: 19.2902784 },
    { lat: 49.1958716, lng: 19.2902932 },
    { lat: 49.1956459, lng: 19.290334 },
    { lat: 49.1950906, lng: 19.2906055 },
    { lat: 49.1947828, lng: 19.2898634 },
    { lat: 49.1943831, lng: 19.2899703 },
    { lat: 49.1939039, lng: 19.2898457 },
    { lat: 49.1932058, lng: 19.2896473 },
    { lat: 49.1924245, lng: 19.2900184 },
    { lat: 49.1922639, lng: 19.2901979 },
    { lat: 49.1919173, lng: 19.2900388 },
    { lat: 49.1916793, lng: 19.2900846 },
    { lat: 49.1916686, lng: 19.2902389 },
    { lat: 49.1916392, lng: 19.2903802 },
    { lat: 49.1912347, lng: 19.2909961 },
    { lat: 49.1912166, lng: 19.2910431 },
    { lat: 49.1908888, lng: 19.290665 },
    { lat: 49.1908083, lng: 19.2907755 },
    { lat: 49.1907903, lng: 19.2908002 },
    { lat: 49.1901312, lng: 19.2917044 },
    { lat: 49.1905249, lng: 19.2930426 },
    { lat: 49.1900904, lng: 19.2933609 },
    { lat: 49.1895254, lng: 19.2940204 },
    { lat: 49.1898311, lng: 19.294406 },
    { lat: 49.1901606, lng: 19.2949086 },
    { lat: 49.1899397, lng: 19.2952195 },
    { lat: 49.1904604, lng: 19.2956966 },
    { lat: 49.189916, lng: 19.2961679 },
    { lat: 49.1900644, lng: 19.2964938 },
    { lat: 49.1902801, lng: 19.296952 },
    { lat: 49.1903438, lng: 19.297608 },
    { lat: 49.1902719, lng: 19.2982032 },
    { lat: 49.1912235, lng: 19.2989517 },
    { lat: 49.1911444, lng: 19.2995142 },
    { lat: 49.1912225, lng: 19.299547 },
    { lat: 49.1912844, lng: 19.2996137 },
    { lat: 49.1913765, lng: 19.2999411 },
    { lat: 49.1914327, lng: 19.3000553 },
    { lat: 49.1915193, lng: 19.3003376 },
    { lat: 49.1915885, lng: 19.3007065 },
    { lat: 49.191746, lng: 19.3012436 },
    { lat: 49.1918842, lng: 19.3017133 },
    { lat: 49.1919479, lng: 19.3019362 },
    { lat: 49.1919405, lng: 19.3020682 },
    { lat: 49.1918896, lng: 19.3021815 },
    { lat: 49.1917781, lng: 19.3022796 },
    { lat: 49.1917995, lng: 19.3031099 },
    { lat: 49.1917615, lng: 19.3034045 },
    { lat: 49.1917197, lng: 19.3037075 },
    { lat: 49.191609, lng: 19.3045113 },
    { lat: 49.1916502, lng: 19.3047374 },
    { lat: 49.1916577, lng: 19.3047769 },
    { lat: 49.1916792, lng: 19.304901 },
    { lat: 49.1916868, lng: 19.3049416 },
    { lat: 49.1918288, lng: 19.3057251 },
    { lat: 49.1912914, lng: 19.3059905 },
    { lat: 49.1913959, lng: 19.3071003 },
    { lat: 49.1915181, lng: 19.308278 },
    { lat: 49.1915271, lng: 19.3083732 },
    { lat: 49.1916456, lng: 19.3091355 },
    { lat: 49.1916402, lng: 19.3096899 },
    { lat: 49.1916363, lng: 19.3100809 },
    { lat: 49.1916105, lng: 19.3116068 },
    { lat: 49.1915135, lng: 19.312132 },
    { lat: 49.1914212, lng: 19.3126304 },
    { lat: 49.1914978, lng: 19.3127031 },
    { lat: 49.1917643, lng: 19.3130744 },
    { lat: 49.1917777, lng: 19.3131176 },
    { lat: 49.1919153, lng: 19.3133366 },
    { lat: 49.1921966, lng: 19.3139452 },
    { lat: 49.1923376, lng: 19.3140399 },
    { lat: 49.1924391, lng: 19.3146128 },
    { lat: 49.1924726, lng: 19.3151319 },
    { lat: 49.1924313, lng: 19.3159753 },
    { lat: 49.1924617, lng: 19.316524 },
    { lat: 49.1923986, lng: 19.3167374 },
    { lat: 49.1923702, lng: 19.3170659 },
    { lat: 49.1923875, lng: 19.3175961 },
    { lat: 49.1924905, lng: 19.3175117 },
    { lat: 49.1926787, lng: 19.317563 },
    { lat: 49.1929497, lng: 19.3175699 },
    { lat: 49.1931398, lng: 19.3176347 },
    { lat: 49.1933327, lng: 19.317638 },
    { lat: 49.1935384, lng: 19.3176748 },
    { lat: 49.1937168, lng: 19.3175853 },
    { lat: 49.1939906, lng: 19.3173941 },
    { lat: 49.1944819, lng: 19.3168917 },
    { lat: 49.194367, lng: 19.3173227 },
    { lat: 49.1943549, lng: 19.3175673 },
    { lat: 49.1943902, lng: 19.3177435 },
    { lat: 49.1945764, lng: 19.3179147 },
    { lat: 49.1950028, lng: 19.3181143 },
    { lat: 49.195246, lng: 19.3182518 },
    { lat: 49.1955114, lng: 19.3183819 },
    { lat: 49.1956976, lng: 19.3184143 },
    { lat: 49.1960419, lng: 19.3183534 },
    { lat: 49.1963421, lng: 19.3184587 },
    { lat: 49.1964104, lng: 19.3185211 },
    { lat: 49.1975372, lng: 19.3197318 },
    { lat: 49.1973193, lng: 19.3204866 },
    { lat: 49.1972157, lng: 19.3208443 },
    { lat: 49.1970492, lng: 19.3214237 },
    { lat: 49.1967084, lng: 19.3238975 },
    { lat: 49.1967193, lng: 19.3239831 },
    { lat: 49.1966864, lng: 19.3260061 },
    { lat: 49.1966609, lng: 19.327473 },
    { lat: 49.1969306, lng: 19.3288887 },
    { lat: 49.1969497, lng: 19.3290091 },
    { lat: 49.196961, lng: 19.3300349 },
    { lat: 49.1967741, lng: 19.3314496 },
    { lat: 49.1972218, lng: 19.3316927 },
    { lat: 49.1979474, lng: 19.3322425 },
    { lat: 49.1974155, lng: 19.3343701 },
    { lat: 49.1970739, lng: 19.3350587 },
    { lat: 49.1971584, lng: 19.3368058 },
    { lat: 49.1971816, lng: 19.3368663 },
    { lat: 49.19731, lng: 19.3368403 },
    { lat: 49.1974263, lng: 19.3368239 },
    { lat: 49.1975485, lng: 19.3368062 },
    { lat: 49.1980637, lng: 19.3368841 },
    { lat: 49.1983246, lng: 19.3369181 },
    { lat: 49.1984277, lng: 19.3369313 },
    { lat: 49.1985251, lng: 19.3369441 },
    { lat: 49.1990685, lng: 19.3377108 },
    { lat: 49.1990023, lng: 19.3384362 },
    { lat: 49.2000383, lng: 19.3380595 },
    { lat: 49.2001887, lng: 19.3377126 },
    { lat: 49.2004329, lng: 19.3374627 },
    { lat: 49.2008241, lng: 19.3371563 },
    { lat: 49.2010162, lng: 19.3370982 },
    { lat: 49.2011778, lng: 19.3371287 },
    { lat: 49.2010962, lng: 19.3374577 },
    { lat: 49.2010462, lng: 19.3376841 },
    { lat: 49.2009674, lng: 19.3381176 },
    { lat: 49.2010293, lng: 19.3387386 },
    { lat: 49.2022056, lng: 19.3391415 },
    { lat: 49.2024739, lng: 19.3390724 },
    { lat: 49.2028909, lng: 19.3390004 },
    { lat: 49.2032063, lng: 19.3390678 },
    { lat: 49.203762, lng: 19.33943 },
    { lat: 49.2040461, lng: 19.339552 },
    { lat: 49.204254, lng: 19.3397533 },
    { lat: 49.2045039, lng: 19.3394841 },
    { lat: 49.2050543, lng: 19.3396137 },
    { lat: 49.2056472, lng: 19.3400229 },
    { lat: 49.2057737, lng: 19.3400761 },
    { lat: 49.2060764, lng: 19.3402361 },
    { lat: 49.2062025, lng: 19.3406425 },
    { lat: 49.2063108, lng: 19.3408105 },
    { lat: 49.2065893, lng: 19.3411036 },
    { lat: 49.2065748, lng: 19.3413672 },
    { lat: 49.2065865, lng: 19.3414012 },
    { lat: 49.2066304, lng: 19.3418307 },
    { lat: 49.2066952, lng: 19.34225 },
    { lat: 49.2069009, lng: 19.3427165 },
    { lat: 49.2070359, lng: 19.3429519 },
    { lat: 49.2073017, lng: 19.3430701 },
    { lat: 49.2077087, lng: 19.3430962 },
    { lat: 49.207915, lng: 19.3435771 },
    { lat: 49.2081655, lng: 19.3437518 },
    { lat: 49.2089091, lng: 19.3445145 },
    { lat: 49.2091037, lng: 19.3449606 },
    { lat: 49.2093245, lng: 19.3454382 },
    { lat: 49.2093571, lng: 19.3455078 },
    { lat: 49.2091009, lng: 19.3460253 },
    { lat: 49.2085414, lng: 19.3468539 },
    { lat: 49.2083857, lng: 19.3472127 },
    { lat: 49.2081226, lng: 19.347825 },
    { lat: 49.2080914, lng: 19.3477596 },
    { lat: 49.2077749, lng: 19.3471138 },
    { lat: 49.207679, lng: 19.3470183 },
    { lat: 49.2073123, lng: 19.3466487 },
    { lat: 49.2064871, lng: 19.3461141 },
    { lat: 49.205951, lng: 19.3457872 },
    { lat: 49.205851, lng: 19.3468934 },
    { lat: 49.2056682, lng: 19.3474411 },
    { lat: 49.2054464, lng: 19.3476845 },
    { lat: 49.2051913, lng: 19.3484437 },
    { lat: 49.2049783, lng: 19.3488746 },
    { lat: 49.2045216, lng: 19.3496346 },
    { lat: 49.2040379, lng: 19.3491492 },
    { lat: 49.2034486, lng: 19.3487462 },
    { lat: 49.2029312, lng: 19.3483593 },
    { lat: 49.2023618, lng: 19.347965 },
    { lat: 49.2021338, lng: 19.3479388 },
    { lat: 49.2015177, lng: 19.347994 },
    { lat: 49.2008682, lng: 19.3480064 },
    { lat: 49.2007569, lng: 19.3479901 },
    { lat: 49.2004335, lng: 19.3479423 },
    { lat: 49.2003077, lng: 19.3471856 },
    { lat: 49.2002822, lng: 19.3467591 },
    { lat: 49.199671, lng: 19.3466066 },
    { lat: 49.1991652, lng: 19.3467092 },
    { lat: 49.1990569, lng: 19.3467294 },
    { lat: 49.1986993, lng: 19.3467945 },
    { lat: 49.1986734, lng: 19.3467966 },
    { lat: 49.1986476, lng: 19.3467991 },
    { lat: 49.1986748, lng: 19.3468502 },
    { lat: 49.1986565, lng: 19.3469127 },
    { lat: 49.1987313, lng: 19.3471121 },
    { lat: 49.1986484, lng: 19.3471276 },
    { lat: 49.1986205, lng: 19.347277 },
    { lat: 49.1986369, lng: 19.3473858 },
    { lat: 49.1986407, lng: 19.3474917 },
    { lat: 49.1987001, lng: 19.3476488 },
    { lat: 49.198715, lng: 19.3478007 },
    { lat: 49.1986476, lng: 19.3479248 },
    { lat: 49.1986504, lng: 19.3480089 },
    { lat: 49.1985551, lng: 19.3480665 },
    { lat: 49.1985214, lng: 19.3482334 },
    { lat: 49.1985378, lng: 19.3483657 },
    { lat: 49.1986291, lng: 19.3485359 },
    { lat: 49.1985508, lng: 19.3488129 },
    { lat: 49.1984093, lng: 19.3490143 },
    { lat: 49.1984538, lng: 19.3491723 },
    { lat: 49.198421, lng: 19.3493374 },
    { lat: 49.1983351, lng: 19.3494996 },
    { lat: 49.1983187, lng: 19.3497486 },
    { lat: 49.1983733, lng: 19.3499058 },
    { lat: 49.1983774, lng: 19.3500629 },
    { lat: 49.198597, lng: 19.3505642 },
    { lat: 49.1985941, lng: 19.3507894 },
    { lat: 49.1987334, lng: 19.3510196 },
    { lat: 49.198785, lng: 19.3511829 },
    { lat: 49.1988296, lng: 19.3512681 },
    { lat: 49.1988359, lng: 19.3513064 },
    { lat: 49.198826, lng: 19.3513388 },
    { lat: 49.1987246, lng: 19.3514058 },
    { lat: 49.1987122, lng: 19.3514334 },
    { lat: 49.1987228, lng: 19.3515126 },
    { lat: 49.1987434, lng: 19.351519 },
    { lat: 49.1987913, lng: 19.3514831 },
    { lat: 49.1988322, lng: 19.351514 },
    { lat: 49.1988432, lng: 19.3515582 },
    { lat: 49.1988159, lng: 19.3516963 },
    { lat: 49.1987364, lng: 19.3517789 },
    { lat: 49.1987358, lng: 19.3518856 },
    { lat: 49.1987512, lng: 19.3520482 },
    { lat: 49.1987309, lng: 19.3520685 },
    { lat: 49.1986683, lng: 19.3521024 },
    { lat: 49.1986157, lng: 19.3520883 },
    { lat: 49.1986065, lng: 19.3521336 },
    { lat: 49.1986303, lng: 19.3522788 },
    { lat: 49.1985965, lng: 19.3523733 },
    { lat: 49.1985615, lng: 19.3523976 },
    { lat: 49.198527, lng: 19.3525726 },
    { lat: 49.1984404, lng: 19.3527013 },
    { lat: 49.1984374, lng: 19.3527319 },
    { lat: 49.1984465, lng: 19.3527547 },
    { lat: 49.198475, lng: 19.3527642 },
    { lat: 49.198495, lng: 19.3527112 },
    { lat: 49.1985103, lng: 19.3527261 },
    { lat: 49.1985281, lng: 19.352788 },
    { lat: 49.1985379, lng: 19.3528557 },
    { lat: 49.1985268, lng: 19.3529056 },
    { lat: 49.1984859, lng: 19.3529457 },
    { lat: 49.1984767, lng: 19.3529644 },
    { lat: 49.1985125, lng: 19.3530837 },
    { lat: 49.1985125, lng: 19.3531032 },
    { lat: 49.1984189, lng: 19.3531786 },
    { lat: 49.1983777, lng: 19.3530891 },
    { lat: 49.1983115, lng: 19.353194 },
    { lat: 49.1982969, lng: 19.3532396 },
    { lat: 49.198298, lng: 19.3532877 },
    { lat: 49.1983222, lng: 19.3533254 },
    { lat: 49.1983685, lng: 19.3533261 },
    { lat: 49.1984048, lng: 19.3533308 },
    { lat: 49.1984111, lng: 19.3533935 },
    { lat: 49.198413, lng: 19.3534582 },
    { lat: 49.1983948, lng: 19.3535294 },
    { lat: 49.1983732, lng: 19.3535732 },
    { lat: 49.1983322, lng: 19.3535636 },
    { lat: 49.1983426, lng: 19.3536396 },
    { lat: 49.1982785, lng: 19.3535479 },
    { lat: 49.1982798, lng: 19.3537156 },
    { lat: 49.1982473, lng: 19.353792 },
    { lat: 49.1979677, lng: 19.3541999 },
    { lat: 49.1978473, lng: 19.3541795 },
    { lat: 49.1977244, lng: 19.354153 },
    { lat: 49.197643, lng: 19.3541691 },
    { lat: 49.1975563, lng: 19.3542445 },
    { lat: 49.1975571, lng: 19.3550566 },
    { lat: 49.1975548, lng: 19.3557012 },
    { lat: 49.1975963, lng: 19.3567298 },
    { lat: 49.1973539, lng: 19.3571098 },
    { lat: 49.1970056, lng: 19.3576595 },
    { lat: 49.196841, lng: 19.358022 },
    { lat: 49.1966535, lng: 19.3584446 },
    { lat: 49.1967548, lng: 19.3586811 },
    { lat: 49.1965407, lng: 19.3591358 },
    { lat: 49.1960258, lng: 19.3598371 },
    { lat: 49.195887, lng: 19.3600238 },
    { lat: 49.1955792, lng: 19.3603086 },
    { lat: 49.1953283, lng: 19.3606787 },
    { lat: 49.1952799, lng: 19.3606299 },
    { lat: 49.1952492, lng: 19.361204 },
    { lat: 49.1950406, lng: 19.3620872 },
    { lat: 49.1948218, lng: 19.3623263 },
    { lat: 49.1947927, lng: 19.3625204 },
    { lat: 49.1943723, lng: 19.3627814 },
    { lat: 49.1943665, lng: 19.3629712 },
    { lat: 49.1939935, lng: 19.363022 },
    { lat: 49.1935434, lng: 19.3630824 },
    { lat: 49.1932178, lng: 19.3631802 },
    { lat: 49.1924943, lng: 19.3632506 },
    { lat: 49.1924155, lng: 19.3632534 },
    { lat: 49.1918589, lng: 19.3631827 },
    { lat: 49.1919775, lng: 19.3635765 },
    { lat: 49.1923431, lng: 19.3648816 },
    { lat: 49.1923861, lng: 19.3655363 },
    { lat: 49.1923094, lng: 19.3662076 },
    { lat: 49.1923039, lng: 19.3662576 },
    { lat: 49.1922913, lng: 19.366255 },
    { lat: 49.1922283, lng: 19.3665934 },
    { lat: 49.1920892, lng: 19.3673745 },
    { lat: 49.1918662, lng: 19.3694684 },
    { lat: 49.1921636, lng: 19.3705057 },
    { lat: 49.1921428, lng: 19.37114 },
    { lat: 49.1920608, lng: 19.3714613 },
    { lat: 49.1916743, lng: 19.3721592 },
    { lat: 49.1914987, lng: 19.3725739 },
    { lat: 49.190979, lng: 19.3745174 },
    { lat: 49.1907633, lng: 19.3760992 },
    { lat: 49.1907633, lng: 19.3766998 },
    { lat: 49.1907657, lng: 19.3769193 },
    { lat: 49.1908144, lng: 19.3777214 },
    { lat: 49.1908202, lng: 19.3783257 },
    { lat: 49.1908374, lng: 19.3789572 },
    { lat: 49.190875, lng: 19.3796636 },
    { lat: 49.1909211, lng: 19.3799291 },
    { lat: 49.1909919, lng: 19.3807454 },
    { lat: 49.191062, lng: 19.381352 },
    { lat: 49.1910868, lng: 19.3818826 },
    { lat: 49.1911582, lng: 19.3822074 },
    { lat: 49.1914855, lng: 19.3832541 },
    { lat: 49.1916741, lng: 19.3839138 },
    { lat: 49.1917179, lng: 19.3840074 },
    { lat: 49.1918017, lng: 19.3841827 },
    { lat: 49.1919567, lng: 19.3845089 },
    { lat: 49.1919704, lng: 19.3845402 },
    { lat: 49.1919853, lng: 19.3845202 },
    { lat: 49.1919978, lng: 19.3844734 },
    { lat: 49.1920463, lng: 19.3843806 },
    { lat: 49.1921805, lng: 19.3841642 },
    { lat: 49.1922893, lng: 19.3838847 },
    { lat: 49.1924349, lng: 19.3835028 },
    { lat: 49.1924968, lng: 19.3834071 },
    { lat: 49.1925426, lng: 19.3833268 },
    { lat: 49.1926406, lng: 19.383181 },
    { lat: 49.192876, lng: 19.3828167 },
    { lat: 49.1929846, lng: 19.3826334 },
    { lat: 49.1930542, lng: 19.3825303 },
    { lat: 49.1932709, lng: 19.382247 },
    { lat: 49.1935176, lng: 19.3819831 },
    { lat: 49.1938119, lng: 19.38167 },
    { lat: 49.1939386, lng: 19.3814915 },
    { lat: 49.1940325, lng: 19.381303 },
    { lat: 49.1941936, lng: 19.3811323 },
    { lat: 49.1943501, lng: 19.3812014 },
    { lat: 49.1945201, lng: 19.3812705 },
    { lat: 49.1946867, lng: 19.3813647 },
    { lat: 49.1949271, lng: 19.3814715 },
    { lat: 49.1953412, lng: 19.3816506 },
    { lat: 49.1954789, lng: 19.3817977 },
    { lat: 49.1955285, lng: 19.3818486 },
    { lat: 49.1955907, lng: 19.3819726 },
    { lat: 49.1957582, lng: 19.3823035 },
    { lat: 49.195807, lng: 19.3824074 },
    { lat: 49.1958634, lng: 19.3825032 },
    { lat: 49.1959695, lng: 19.3827007 },
    { lat: 49.1960533, lng: 19.3828068 },
    { lat: 49.1962092, lng: 19.382934 },
    { lat: 49.1964051, lng: 19.3830028 },
    { lat: 49.196497, lng: 19.3829938 },
    { lat: 49.1969181, lng: 19.3829127 },
    { lat: 49.1971457, lng: 19.3824275 },
    { lat: 49.197207, lng: 19.3823898 },
    { lat: 49.1977075, lng: 19.3821307 },
    { lat: 49.1979109, lng: 19.382066 },
    { lat: 49.1984161, lng: 19.3819581 },
    { lat: 49.1986046, lng: 19.3819138 },
    { lat: 49.1987967, lng: 19.3818741 },
    { lat: 49.1989489, lng: 19.3818525 },
    { lat: 49.1989926, lng: 19.3818478 },
    { lat: 49.1991059, lng: 19.3818607 },
    { lat: 49.1992802, lng: 19.381877 },
    { lat: 49.1994258, lng: 19.3818803 },
    { lat: 49.1995856, lng: 19.3818787 },
    { lat: 49.1997429, lng: 19.3818443 },
    { lat: 49.199817, lng: 19.3818121 },
    { lat: 49.1998782, lng: 19.3817744 },
    { lat: 49.2000101, lng: 19.3816996 },
    { lat: 49.2003112, lng: 19.3813684 },
    { lat: 49.2004969, lng: 19.381097 },
    { lat: 49.2007812, lng: 19.3806474 },
    { lat: 49.2010097, lng: 19.3802301 },
    { lat: 49.2010792, lng: 19.3800816 },
    { lat: 49.2011377, lng: 19.3799588 },
    { lat: 49.2012257, lng: 19.3798159 },
    { lat: 49.2014713, lng: 19.3795719 },
    { lat: 49.2017369, lng: 19.3793952 },
    { lat: 49.2019169, lng: 19.3793154 },
    { lat: 49.2021056, lng: 19.3792545 },
    { lat: 49.2022685, lng: 19.3791734 },
    { lat: 49.2023737, lng: 19.3790841 },
    { lat: 49.2025503, lng: 19.3786457 },
    { lat: 49.2026219, lng: 19.3782477 },
    { lat: 49.2026932, lng: 19.3775793 },
    { lat: 49.2026915, lng: 19.3771557 },
    { lat: 49.2026703, lng: 19.3764848 },
    { lat: 49.2026614, lng: 19.3759099 },
    { lat: 49.2026673, lng: 19.3753604 },
    { lat: 49.2026838, lng: 19.3747038 },
    { lat: 49.202712, lng: 19.3742957 },
    { lat: 49.2027658, lng: 19.3740228 },
    { lat: 49.202981, lng: 19.3734959 },
    { lat: 49.2031764, lng: 19.3730429 },
    { lat: 49.2032829, lng: 19.3726702 },
    { lat: 49.2033322, lng: 19.3724277 },
    { lat: 49.2034051, lng: 19.3719617 },
    { lat: 49.2034985, lng: 19.3713785 },
    { lat: 49.203572, lng: 19.3708814 },
    { lat: 49.2036063, lng: 19.3704586 },
    { lat: 49.2036334, lng: 19.3700743 },
    { lat: 49.2037052, lng: 19.3696351 },
    { lat: 49.2037553, lng: 19.369404 },
    { lat: 49.2039233, lng: 19.3686844 },
    { lat: 49.204132, lng: 19.3679174 },
    { lat: 49.204324, lng: 19.3673494 },
    { lat: 49.2044181, lng: 19.3670991 },
    { lat: 49.2044971, lng: 19.3669557 },
    { lat: 49.2046379, lng: 19.3667598 },
    { lat: 49.2047456, lng: 19.3666097 },
    { lat: 49.2050182, lng: 19.3662948 },
    { lat: 49.2051403, lng: 19.3661788 },
    { lat: 49.2052464, lng: 19.3660897 },
    { lat: 49.2054182, lng: 19.3659311 },
    { lat: 49.2059496, lng: 19.3654392 },
    { lat: 49.2066787, lng: 19.3647664 },
    { lat: 49.2069504, lng: 19.3644322 },
    { lat: 49.2067813, lng: 19.3640225 },
    { lat: 49.2066178, lng: 19.3634922 },
    { lat: 49.2064462, lng: 19.3621378 },
    { lat: 49.2064432, lng: 19.3619031 },
    { lat: 49.2066727, lng: 19.3618016 },
    { lat: 49.2069241, lng: 19.3617053 },
    { lat: 49.2071188, lng: 19.3616513 },
    { lat: 49.2073409, lng: 19.3616544 },
    { lat: 49.2075732, lng: 19.3618489 },
    { lat: 49.2077517, lng: 19.3621501 },
    { lat: 49.2077488, lng: 19.3624709 },
    { lat: 49.2078531, lng: 19.3624591 },
    { lat: 49.2080175, lng: 19.3623597 },
    { lat: 49.2082788, lng: 19.3620706 },
    { lat: 49.2082853, lng: 19.3620645 },
    { lat: 49.2083214, lng: 19.3619473 },
    { lat: 49.2085056, lng: 19.3617665 },
    { lat: 49.2086949, lng: 19.3616687 },
    { lat: 49.2087873, lng: 19.3616449 },
    { lat: 49.2089577, lng: 19.3616216 },
    { lat: 49.2092922, lng: 19.3612624 },
    { lat: 49.209597, lng: 19.3608129 },
    { lat: 49.209948, lng: 19.3602011 },
    { lat: 49.2101262, lng: 19.3598921 },
    { lat: 49.210573, lng: 19.3590417 },
    { lat: 49.2106378, lng: 19.3587362 },
    { lat: 49.2106423, lng: 19.3584045 },
    { lat: 49.2104537, lng: 19.3575063 },
    { lat: 49.2104007, lng: 19.3570252 },
    { lat: 49.2103963, lng: 19.3565935 },
    { lat: 49.2105562, lng: 19.35584 },
    { lat: 49.2107934, lng: 19.3556414 },
    { lat: 49.2108782, lng: 19.3555766 },
    { lat: 49.211001, lng: 19.3555253 },
    { lat: 49.2111068, lng: 19.3553807 },
    { lat: 49.2111893, lng: 19.3552167 },
    { lat: 49.211338, lng: 19.3550494 },
    { lat: 49.211425, lng: 19.3551019 },
    { lat: 49.2115087, lng: 19.355083 },
    { lat: 49.211494, lng: 19.3549607 },
    { lat: 49.2115368, lng: 19.3549376 },
    { lat: 49.2116604, lng: 19.3549302 },
    { lat: 49.2117831, lng: 19.3550036 },
    { lat: 49.2118816, lng: 19.3548873 },
    { lat: 49.2119102, lng: 19.3548726 },
    { lat: 49.2119698, lng: 19.3548946 },
    { lat: 49.2121188, lng: 19.3547096 },
    { lat: 49.2122816, lng: 19.3546002 },
    { lat: 49.2120215, lng: 19.3536922 },
    { lat: 49.2118658, lng: 19.3531628 },
    { lat: 49.2115703, lng: 19.3525589 },
    { lat: 49.2114164, lng: 19.3521818 },
    { lat: 49.2112286, lng: 19.351879 },
    { lat: 49.2109566, lng: 19.3514384 },
    { lat: 49.2107234, lng: 19.3510592 },
    { lat: 49.2102433, lng: 19.3503595 },
    { lat: 49.209812, lng: 19.3497684 },
    { lat: 49.2098223, lng: 19.3497478 },
    { lat: 49.2088522, lng: 19.348723 },
    { lat: 49.2086721, lng: 19.3484623 },
    { lat: 49.2086528, lng: 19.3484334 },
    { lat: 49.2086274, lng: 19.3483954 },
    { lat: 49.2085645, lng: 19.3483043 },
    { lat: 49.208498, lng: 19.3482106 },
    { lat: 49.2083085, lng: 19.3479405 },
    { lat: 49.2085553, lng: 19.3474158 },
    { lat: 49.2086335, lng: 19.347209 },
    { lat: 49.2087497, lng: 19.3469479 },
    { lat: 49.2088615, lng: 19.3467352 },
    { lat: 49.2090123, lng: 19.3465158 },
    { lat: 49.2092352, lng: 19.3462243 },
    { lat: 49.2094816, lng: 19.3459064 },
    { lat: 49.2096703, lng: 19.3457005 },
    { lat: 49.2098421, lng: 19.3455883 },
    { lat: 49.2099479, lng: 19.3451561 },
    { lat: 49.2101904, lng: 19.3448736 },
    { lat: 49.210357, lng: 19.344726 },
    { lat: 49.2104143, lng: 19.3446242 },
    { lat: 49.2105006, lng: 19.3445906 },
    { lat: 49.2104085, lng: 19.3442835 },
    { lat: 49.2102933, lng: 19.3438978 },
    { lat: 49.2101865, lng: 19.3436168 },
    { lat: 49.2100883, lng: 19.3434459 },
    { lat: 49.2099299, lng: 19.3432171 },
    { lat: 49.2098576, lng: 19.34314 },
    { lat: 49.2097502, lng: 19.3429658 },
    { lat: 49.2095783, lng: 19.3428311 },
    { lat: 49.2095291, lng: 19.3428202 },
    { lat: 49.2094777, lng: 19.3427815 },
    { lat: 49.2094133, lng: 19.3426578 },
    { lat: 49.2092484, lng: 19.342505 },
    { lat: 49.2091788, lng: 19.3423652 },
    { lat: 49.2091554, lng: 19.3422996 },
    { lat: 49.2091779, lng: 19.3418445 },
    { lat: 49.2092346, lng: 19.3415903 },
    { lat: 49.2092522, lng: 19.3414103 },
    { lat: 49.2091891, lng: 19.3408827 },
    { lat: 49.2091256, lng: 19.3406637 },
    { lat: 49.209037, lng: 19.3402859 },
    { lat: 49.208972, lng: 19.3400551 },
    { lat: 49.2088429, lng: 19.339564 },
    { lat: 49.2087838, lng: 19.3395027 },
    { lat: 49.2087474, lng: 19.3394278 },
    { lat: 49.2086942, lng: 19.3390148 },
    { lat: 49.2087165, lng: 19.338795 },
    { lat: 49.2087616, lng: 19.338555 },
    { lat: 49.2088044, lng: 19.3383597 },
    { lat: 49.2088219, lng: 19.3382799 },
    { lat: 49.2088552, lng: 19.3382211 },
    { lat: 49.2089197, lng: 19.3381311 },
    { lat: 49.2089881, lng: 19.3380548 },
    { lat: 49.2090452, lng: 19.3379033 },
    { lat: 49.2091105, lng: 19.3377804 },
    { lat: 49.2091741, lng: 19.3377168 },
    { lat: 49.2092883, lng: 19.3376037 },
    { lat: 49.2093238, lng: 19.3376413 },
    { lat: 49.20935, lng: 19.3376701 },
    { lat: 49.2093997, lng: 19.3376023 },
    { lat: 49.2094368, lng: 19.3375528 },
    { lat: 49.2095039, lng: 19.3373728 },
    { lat: 49.2096434, lng: 19.3371052 },
    { lat: 49.209724, lng: 19.3369767 },
    { lat: 49.2097628, lng: 19.3369144 },
    { lat: 49.2098179, lng: 19.3369343 },
    { lat: 49.2100196, lng: 19.337007 },
    { lat: 49.2101803, lng: 19.3369735 },
    { lat: 49.2102427, lng: 19.3367435 },
    { lat: 49.2102649, lng: 19.3366636 },
    { lat: 49.2103149, lng: 19.3366974 },
    { lat: 49.2104896, lng: 19.33636 },
    { lat: 49.2106484, lng: 19.3360019 },
    { lat: 49.2108122, lng: 19.3356491 },
    { lat: 49.2109654, lng: 19.3354074 },
    { lat: 49.211069, lng: 19.3352911 },
    { lat: 49.211163, lng: 19.3351056 },
    { lat: 49.2112617, lng: 19.3349992 },
    { lat: 49.2114197, lng: 19.3344095 },
    { lat: 49.2115691, lng: 19.3337998 },
    { lat: 49.2116315, lng: 19.3335713 },
    { lat: 49.2117763, lng: 19.3332205 },
    { lat: 49.2119173, lng: 19.3330818 },
    { lat: 49.2120271, lng: 19.3330203 },
    { lat: 49.2123685, lng: 19.3330899 },
    { lat: 49.2124492, lng: 19.3331037 },
    { lat: 49.2129454, lng: 19.3330186 },
    { lat: 49.213334, lng: 19.3329424 },
    { lat: 49.2135781, lng: 19.3329095 },
    { lat: 49.2137003, lng: 19.3328932 },
    { lat: 49.21402, lng: 19.3326465 },
    { lat: 49.2138647, lng: 19.3320576 },
    { lat: 49.213633, lng: 19.331299 },
    { lat: 49.21341, lng: 19.3309919 },
    { lat: 49.2134692, lng: 19.3308855 },
    { lat: 49.213412, lng: 19.3306989 },
    { lat: 49.2132371, lng: 19.3303346 },
    { lat: 49.2130219, lng: 19.3303842 },
    { lat: 49.2128886, lng: 19.3301624 },
    { lat: 49.212782, lng: 19.3299344 },
    { lat: 49.2126606, lng: 19.3296078 },
    { lat: 49.2124832, lng: 19.3290473 },
    { lat: 49.2124342, lng: 19.3288934 },
    { lat: 49.2125047, lng: 19.3287357 },
    { lat: 49.2124375, lng: 19.3286285 },
    { lat: 49.2122876, lng: 19.3280534 },
    { lat: 49.2122381, lng: 19.3279097 },
    { lat: 49.212162, lng: 19.3276852 },
    { lat: 49.2120436, lng: 19.3272271 },
    { lat: 49.2122044, lng: 19.3265761 },
    { lat: 49.2123, lng: 19.3265248 },
    { lat: 49.2123617, lng: 19.3263504 },
    { lat: 49.212645, lng: 19.3261437 },
    { lat: 49.2127335, lng: 19.3261329 },
    { lat: 49.2127881, lng: 19.326048 },
    { lat: 49.2127514, lng: 19.3254414 },
    { lat: 49.2127371, lng: 19.3251841 },
    { lat: 49.2126919, lng: 19.3248261 },
    { lat: 49.2127412, lng: 19.3247454 },
    { lat: 49.2128497, lng: 19.3245618 },
    { lat: 49.2129351, lng: 19.3246051 },
    { lat: 49.2131856, lng: 19.3242992 },
    { lat: 49.2132806, lng: 19.3241655 },
    { lat: 49.2133346, lng: 19.3240886 },
    { lat: 49.2133842, lng: 19.3240186 },
    { lat: 49.213651, lng: 19.3233231 },
    { lat: 49.213648, lng: 19.3229687 },
    { lat: 49.2136779, lng: 19.3228169 },
    { lat: 49.2137436, lng: 19.3226338 },
    { lat: 49.2138108, lng: 19.3225053 },
    { lat: 49.2139008, lng: 19.3222621 },
    { lat: 49.2142316, lng: 19.321941 },
    { lat: 49.2145662, lng: 19.3217235 },
    { lat: 49.2149254, lng: 19.3212651 },
    { lat: 49.2150704, lng: 19.3212088 },
    { lat: 49.2152095, lng: 19.3210039 },
    { lat: 49.2152434, lng: 19.3208884 },
    { lat: 49.2151939, lng: 19.3207446 },
    { lat: 49.2150059, lng: 19.3203443 },
    { lat: 49.2149495, lng: 19.3201291 },
    { lat: 49.2149411, lng: 19.3200933 },
    { lat: 49.2149476, lng: 19.3199689 },
    { lat: 49.2151757, lng: 19.3197654 },
    { lat: 49.2153386, lng: 19.3196844 },
    { lat: 49.2153949, lng: 19.3195351 },
    { lat: 49.2154132, lng: 19.3193034 },
    { lat: 49.2155219, lng: 19.3191 },
    { lat: 49.2155901, lng: 19.3189945 },
    { lat: 49.2155721, lng: 19.3184734 },
    { lat: 49.2156796, lng: 19.3180744 },
    { lat: 49.2156964, lng: 19.3173552 },
    { lat: 49.2157688, lng: 19.3169096 },
    { lat: 49.2158311, lng: 19.3168418 },
    { lat: 49.2159061, lng: 19.3167607 },
    { lat: 49.2165972, lng: 19.3164081 },
    { lat: 49.2168595, lng: 19.3162371 },
    { lat: 49.2171047, lng: 19.3161798 },
    { lat: 49.2172705, lng: 19.3161587 },
    { lat: 49.2174407, lng: 19.3158483 },
    { lat: 49.2176651, lng: 19.3157349 },
    { lat: 49.2177209, lng: 19.315652 },
    { lat: 49.2178937, lng: 19.3153496 },
    { lat: 49.2179728, lng: 19.3152792 },
    { lat: 49.2180205, lng: 19.3152894 },
    { lat: 49.2183298, lng: 19.3151079 },
    { lat: 49.2185085, lng: 19.3150028 },
    { lat: 49.2185982, lng: 19.3148963 },
    { lat: 49.2188074, lng: 19.3145124 },
    { lat: 49.2191962, lng: 19.3141732 },
    { lat: 49.2194001, lng: 19.3141258 },
    { lat: 49.219505, lng: 19.3139625 },
    { lat: 49.2195921, lng: 19.3138725 },
    { lat: 49.2196998, lng: 19.3138361 },
    { lat: 49.2201149, lng: 19.3133718 },
    { lat: 49.2202912, lng: 19.3132593 },
    { lat: 49.2209003, lng: 19.3130116 },
    { lat: 49.2209867, lng: 19.312931 },
    { lat: 49.2211176, lng: 19.3127666 },
    { lat: 49.2211662, lng: 19.3127465 },
    { lat: 49.2212555, lng: 19.3126825 },
    { lat: 49.2212849, lng: 19.3126844 },
    { lat: 49.2213493, lng: 19.3126888 },
    { lat: 49.2214071, lng: 19.3126911 },
    { lat: 49.2214906, lng: 19.3126941 },
    { lat: 49.2216153, lng: 19.312759 },
    { lat: 49.2216558, lng: 19.3127819 },
    { lat: 49.2217008, lng: 19.3128044 },
    { lat: 49.2217904, lng: 19.3128428 },
    { lat: 49.2218399, lng: 19.312859 },
    { lat: 49.2218867, lng: 19.3128765 },
    { lat: 49.2219433, lng: 19.3129031 },
    { lat: 49.2220808, lng: 19.3129507 },
    { lat: 49.2221076, lng: 19.3129682 },
    { lat: 49.2221487, lng: 19.3130023 },
    { lat: 49.2221938, lng: 19.3130521 },
    { lat: 49.2222118, lng: 19.3130779 },
    { lat: 49.2222453, lng: 19.3130926 },
    { lat: 49.2225248, lng: 19.3134497 },
    { lat: 49.222967, lng: 19.3139129 },
    { lat: 49.2232093, lng: 19.3141274 },
    { lat: 49.2232446, lng: 19.3141589 },
    { lat: 49.2233506, lng: 19.3142781 },
    { lat: 49.2234312, lng: 19.314364 },
    { lat: 49.2235382, lng: 19.3144813 },
    { lat: 49.2236148, lng: 19.314457 },
    { lat: 49.2236333, lng: 19.3144441 },
    { lat: 49.2237459, lng: 19.314369 },
    { lat: 49.2239175, lng: 19.3142311 },
    { lat: 49.2241064, lng: 19.3140812 },
    { lat: 49.2241889, lng: 19.314013 },
    { lat: 49.2244236, lng: 19.3138515 },
    { lat: 49.2245653, lng: 19.3137779 },
    { lat: 49.2246468, lng: 19.3137379 },
    { lat: 49.2247975, lng: 19.31366 },
    { lat: 49.2249368, lng: 19.313579 },
    { lat: 49.2250374, lng: 19.3135333 },
    { lat: 49.2251613, lng: 19.3134616 },
    { lat: 49.2253291, lng: 19.313416 },
    { lat: 49.2253814, lng: 19.3135174 },
    { lat: 49.2254555, lng: 19.313586 },
    { lat: 49.2255342, lng: 19.3136775 },
    { lat: 49.2256, lng: 19.313755 },
    { lat: 49.2256513, lng: 19.3138157 },
    { lat: 49.2257722, lng: 19.3138713 },
    { lat: 49.2259504, lng: 19.3138502 },
    { lat: 49.2262048, lng: 19.3137965 },
    { lat: 49.2262852, lng: 19.3138288 },
    { lat: 49.2263578, lng: 19.3139358 },
    { lat: 49.2265322, lng: 19.3140482 },
    { lat: 49.226587, lng: 19.3140825 },
    { lat: 49.2267977, lng: 19.314109 },
    { lat: 49.2268296, lng: 19.3141131 },
    { lat: 49.2269232, lng: 19.31407 },
    { lat: 49.2273717, lng: 19.3141417 },
    { lat: 49.2275809, lng: 19.314225 },
    { lat: 49.2277621, lng: 19.3144123 },
    { lat: 49.2279522, lng: 19.3145475 },
    { lat: 49.2281418, lng: 19.3146965 },
    { lat: 49.2281901, lng: 19.3146929 },
    { lat: 49.2282292, lng: 19.3145927 },
    { lat: 49.2283195, lng: 19.3144998 },
    { lat: 49.2284683, lng: 19.3144304 },
    { lat: 49.228659, lng: 19.3143629 },
    { lat: 49.2288872, lng: 19.3143233 },
    { lat: 49.2291831, lng: 19.3143646 },
    { lat: 49.2293334, lng: 19.3143704 },
    { lat: 49.2294005, lng: 19.3143825 },
    { lat: 49.2294591, lng: 19.3143818 },
    { lat: 49.229521, lng: 19.3143825 },
    { lat: 49.2295443, lng: 19.3143955 },
    { lat: 49.229618, lng: 19.3143812 },
    { lat: 49.2297021, lng: 19.3143968 },
    { lat: 49.2297713, lng: 19.3144072 },
    { lat: 49.2298728, lng: 19.3144115 },
    { lat: 49.229913, lng: 19.3144028 },
    { lat: 49.229959, lng: 19.3143973 },
    { lat: 49.2300476, lng: 19.3144135 },
    { lat: 49.2301448, lng: 19.3144908 },
    { lat: 49.2301793, lng: 19.3145065 },
    { lat: 49.2303145, lng: 19.3145028 },
    { lat: 49.23049, lng: 19.3145191 },
    { lat: 49.2306193, lng: 19.3145871 },
    { lat: 49.2308157, lng: 19.3146902 },
    { lat: 49.2310813, lng: 19.3146342 },
    { lat: 49.2317096, lng: 19.3147413 },
    { lat: 49.2317499, lng: 19.3147599 },
    { lat: 49.2318064, lng: 19.3147862 },
    { lat: 49.2319584, lng: 19.3148538 },
    { lat: 49.2320102, lng: 19.3148769 },
    { lat: 49.2320124, lng: 19.3148778 },
    { lat: 49.2321638, lng: 19.3149772 },
    { lat: 49.2322065, lng: 19.3150724 },
    { lat: 49.2323052, lng: 19.3154239 },
    { lat: 49.2320853, lng: 19.3156807 },
    { lat: 49.2319042, lng: 19.3157808 },
    { lat: 49.2313958, lng: 19.3161617 },
    { lat: 49.2313222, lng: 19.3162488 },
    { lat: 49.2310734, lng: 19.3165639 },
    { lat: 49.2309863, lng: 19.3167015 },
    { lat: 49.2308539, lng: 19.3169981 },
    { lat: 49.2308477, lng: 19.31701 },
    { lat: 49.2305978, lng: 19.3176141 },
    { lat: 49.2305446, lng: 19.3178475 },
    { lat: 49.230464, lng: 19.3184752 },
    { lat: 49.2303897, lng: 19.3189839 },
    { lat: 49.2303643, lng: 19.3195149 },
    { lat: 49.2303223, lng: 19.319891 },
    { lat: 49.2303211, lng: 19.3199408 },
    { lat: 49.2303202, lng: 19.3199918 },
    { lat: 49.2303165, lng: 19.3201274 },
    { lat: 49.2303056, lng: 19.3205419 },
    { lat: 49.230303, lng: 19.3206528 },
    { lat: 49.2302965, lng: 19.3208739 },
    { lat: 49.2303037, lng: 19.3213116 },
    { lat: 49.2303041, lng: 19.3215113 },
    { lat: 49.2303197, lng: 19.3217717 },
    { lat: 49.2303332, lng: 19.3219379 },
    { lat: 49.2304161, lng: 19.3224019 },
    { lat: 49.2305951, lng: 19.3231877 },
    { lat: 49.230942, lng: 19.3230875 },
    { lat: 49.2310987, lng: 19.3238752 },
    { lat: 49.2312293, lng: 19.3245675 },
    { lat: 49.2313298, lng: 19.3252056 },
    { lat: 49.2313885, lng: 19.3255781 },
    { lat: 49.231406, lng: 19.3257002 },
    { lat: 49.231469, lng: 19.3257074 },
    { lat: 49.2315367, lng: 19.3257306 },
    { lat: 49.2315391, lng: 19.3257314 },
    { lat: 49.2315446, lng: 19.3257333 },
    { lat: 49.2315698, lng: 19.3257421 },
    { lat: 49.2315996, lng: 19.3257525 },
    { lat: 49.2322045, lng: 19.3259614 },
    { lat: 49.2329876, lng: 19.3272478 },
    { lat: 49.2331732, lng: 19.3276943 },
    { lat: 49.2331823, lng: 19.3277215 },
    { lat: 49.2332663, lng: 19.3279957 },
    { lat: 49.2337393, lng: 19.3288715 },
    { lat: 49.234133, lng: 19.330244 },
    { lat: 49.2341716, lng: 19.3319249 },
    { lat: 49.2345139, lng: 19.3312728 },
    { lat: 49.2350382, lng: 19.3316389 },
    { lat: 49.2351204, lng: 19.3313043 },
    { lat: 49.2363579, lng: 19.332213 },
    { lat: 49.236515, lng: 19.3325098 },
    { lat: 49.2367983, lng: 19.3330435 },
    { lat: 49.2368948, lng: 19.3332273 },
    { lat: 49.2371791, lng: 19.333759 },
    { lat: 49.2370551, lng: 19.3338332 },
    { lat: 49.2368444, lng: 19.3335458 },
    { lat: 49.23676, lng: 19.3337657 },
    { lat: 49.2367699, lng: 19.3338039 },
    { lat: 49.2369188, lng: 19.3342896 },
    { lat: 49.2368789, lng: 19.3343744 },
    { lat: 49.2369695, lng: 19.3345736 },
    { lat: 49.2370855, lng: 19.334594 },
    { lat: 49.2371274, lng: 19.3346013 },
    { lat: 49.2372103, lng: 19.3346164 },
    { lat: 49.2377591, lng: 19.3347126 },
    { lat: 49.2377968, lng: 19.3347193 },
    { lat: 49.2378116, lng: 19.3347224 },
    { lat: 49.2389036, lng: 19.3349147 },
    { lat: 49.2389068, lng: 19.3348873 },
    { lat: 49.2389163, lng: 19.3348026 },
    { lat: 49.2389549, lng: 19.3344958 },
    { lat: 49.2390414, lng: 19.3338191 },
    { lat: 49.2390503, lng: 19.3337474 },
    { lat: 49.2393872, lng: 19.3334803 },
    { lat: 49.2394957, lng: 19.3333934 },
    { lat: 49.2399378, lng: 19.3330419 },
    { lat: 49.2402112, lng: 19.3323839 },
    { lat: 49.2405907, lng: 19.3318084 },
    { lat: 49.2406453, lng: 19.3317242 },
    { lat: 49.2406833, lng: 19.3316929 },
    { lat: 49.2407267, lng: 19.3316568 },
    { lat: 49.2407744, lng: 19.3316169 },
    { lat: 49.2408237, lng: 19.3316357 },
    { lat: 49.2409213, lng: 19.3316725 },
    { lat: 49.2410394, lng: 19.3315236 },
    { lat: 49.2413552, lng: 19.3311228 },
    { lat: 49.2416337, lng: 19.3307698 },
    { lat: 49.2416477, lng: 19.3307055 },
    { lat: 49.2416753, lng: 19.3306945 },
    { lat: 49.2425443, lng: 19.3300291 },
    { lat: 49.2425749, lng: 19.330007 },
    { lat: 49.2426076, lng: 19.3299835 },
    { lat: 49.2432797, lng: 19.3294941 },
    { lat: 49.243964, lng: 19.3289965 },
    { lat: 49.2443992, lng: 19.3285202 },
    { lat: 49.2446423, lng: 19.3282262 },
    { lat: 49.2449769, lng: 19.3278207 },
    { lat: 49.2449899, lng: 19.3278049 },
    { lat: 49.2460335, lng: 19.3264951 },
    { lat: 49.246132, lng: 19.3261942 },
    { lat: 49.2471364, lng: 19.3264873 },
    { lat: 49.2472235, lng: 19.326612 },
    { lat: 49.2479335, lng: 19.3272098 },
    { lat: 49.2484519, lng: 19.3278573 },
    { lat: 49.2485589, lng: 19.3283797 },
    { lat: 49.2486976, lng: 19.3286704 },
    { lat: 49.2487128, lng: 19.3286582 },
    { lat: 49.2492073, lng: 19.3282716 },
    { lat: 49.2493602, lng: 19.3279749 },
    { lat: 49.2495646, lng: 19.3275765 },
    { lat: 49.2502881, lng: 19.3265987 },
    { lat: 49.2505455, lng: 19.3261321 },
    { lat: 49.2503311, lng: 19.3257668 },
    { lat: 49.2499886, lng: 19.3240932 },
    { lat: 49.2499312, lng: 19.3238124 },
    { lat: 49.2499964, lng: 19.3235685 },
    { lat: 49.2500164, lng: 19.3234917 },
    { lat: 49.2500843, lng: 19.3232338 },
    { lat: 49.2502474, lng: 19.3226059 },
    { lat: 49.2503685, lng: 19.322305 },
    { lat: 49.2505025, lng: 19.3219725 },
    { lat: 49.2507145, lng: 19.3214715 },
    { lat: 49.2508651, lng: 19.321079 },
    { lat: 49.251094, lng: 19.3207508 },
    { lat: 49.2512535, lng: 19.3205229 },
    { lat: 49.251315, lng: 19.3205147 },
    { lat: 49.2513667, lng: 19.3205083 },
    { lat: 49.2518419, lng: 19.3205017 },
    { lat: 49.2519094, lng: 19.3205008 },
    { lat: 49.2522946, lng: 19.3204959 },
    { lat: 49.2524767, lng: 19.3204625 },
    { lat: 49.2531539, lng: 19.3203388 },
    { lat: 49.2532386, lng: 19.3203234 },
    { lat: 49.2532757, lng: 19.3202745 },
    { lat: 49.2534042, lng: 19.3201042 },
    { lat: 49.2536016, lng: 19.3198432 },
    { lat: 49.2536885, lng: 19.3197283 },
    { lat: 49.2537414, lng: 19.3196583 },
    { lat: 49.254117, lng: 19.319162 },
    { lat: 49.2544677, lng: 19.3187124 },
    { lat: 49.254543, lng: 19.3186157 },
    { lat: 49.2546816, lng: 19.3184201 },
    { lat: 49.2547236, lng: 19.3183599 },
    { lat: 49.2548585, lng: 19.3181816 },
    { lat: 49.2551918, lng: 19.3179637 },
    { lat: 49.2552422, lng: 19.3179308 },
    { lat: 49.2564798, lng: 19.317123 },
    { lat: 49.2564885, lng: 19.3171169 },
    { lat: 49.2572546, lng: 19.3165887 },
    { lat: 49.2573113, lng: 19.3165493 },
    { lat: 49.2573376, lng: 19.3165316 },
    { lat: 49.258311, lng: 19.3158607 },
    { lat: 49.2586854, lng: 19.3156022 },
    { lat: 49.26034, lng: 19.3144618 },
    { lat: 49.2603846, lng: 19.3144313 },
    { lat: 49.2607743, lng: 19.3141628 },
    { lat: 49.2608082, lng: 19.3141392 },
    { lat: 49.2616333, lng: 19.3135705 },
    { lat: 49.2625189, lng: 19.3129712 },
    { lat: 49.2631511, lng: 19.3125431 },
    { lat: 49.2641591, lng: 19.3118611 },
    { lat: 49.2642411, lng: 19.3118058 },
    { lat: 49.264277, lng: 19.3117817 },
    { lat: 49.2659795, lng: 19.3106292 },
    { lat: 49.2661138, lng: 19.3105382 },
    { lat: 49.2661477, lng: 19.3105154 },
    { lat: 49.2663293, lng: 19.31119 },
    { lat: 49.2662341, lng: 19.3124386 },
    { lat: 49.2662333, lng: 19.312447 },
    { lat: 49.266223, lng: 19.3125892 },
    { lat: 49.2662386, lng: 19.3128679 },
    { lat: 49.2662539, lng: 19.3131217 },
    { lat: 49.2662564, lng: 19.3131762 },
    { lat: 49.2662732, lng: 19.3134616 },
    { lat: 49.2662939, lng: 19.3138231 },
    { lat: 49.2670578, lng: 19.3153724 },
    { lat: 49.2672443, lng: 19.3153609 },
    { lat: 49.2673375, lng: 19.3154946 },
    { lat: 49.267723, lng: 19.3160475 },
    { lat: 49.2678525, lng: 19.3162338 },
    { lat: 49.2679335, lng: 19.316733 },
    { lat: 49.2680314, lng: 19.3173277 },
    { lat: 49.2680568, lng: 19.3174848 },
    { lat: 49.2680561, lng: 19.3174907 },
    { lat: 49.2679946, lng: 19.3177183 },
    { lat: 49.2680004, lng: 19.3177458 },
    { lat: 49.268292, lng: 19.319291 },
    { lat: 49.2683207, lng: 19.3193225 },
    { lat: 49.2684015, lng: 19.3194146 },
    { lat: 49.2684197, lng: 19.31944 },
    { lat: 49.268743, lng: 19.3187704 },
    { lat: 49.268768, lng: 19.3182736 },
    { lat: 49.268899, lng: 19.3181123 },
    { lat: 49.2689364, lng: 19.3179479 },
    { lat: 49.2689204, lng: 19.3175859 },
    { lat: 49.2691472, lng: 19.317117 },
    { lat: 49.2692507, lng: 19.3172341 },
    { lat: 49.2693636, lng: 19.3172848 },
    { lat: 49.2696781, lng: 19.3169982 },
    { lat: 49.2700292, lng: 19.3172027 },
    { lat: 49.2701467, lng: 19.3171854 },
    { lat: 49.270363, lng: 19.3168743 },
    { lat: 49.2706503, lng: 19.316777 },
    { lat: 49.2715839, lng: 19.3157848 },
    { lat: 49.2716693, lng: 19.3155166 },
    { lat: 49.2720105, lng: 19.3151765 },
    { lat: 49.2721653, lng: 19.3151649 },
    { lat: 49.2722902, lng: 19.3147299 },
    { lat: 49.2724783, lng: 19.3147276 },
    { lat: 49.2726016, lng: 19.3145465 },
    { lat: 49.2727844, lng: 19.3145982 },
    { lat: 49.2728724, lng: 19.3144802 },
    { lat: 49.2729425, lng: 19.3145107 },
    { lat: 49.2730972, lng: 19.31442 },
    { lat: 49.2732718, lng: 19.3144181 },
    { lat: 49.2733262, lng: 19.314354 },
    { lat: 49.2734234, lng: 19.3144068 },
    { lat: 49.2736534, lng: 19.3145319 },
    { lat: 49.2742909, lng: 19.3146211 },
    { lat: 49.2746217, lng: 19.314585 },
    { lat: 49.2750123, lng: 19.3149096 },
    { lat: 49.275312, lng: 19.3149055 },
    { lat: 49.2755756, lng: 19.3150207 },
    { lat: 49.2758401, lng: 19.3150158 },
    { lat: 49.2761203, lng: 19.3148895 },
    { lat: 49.2765398, lng: 19.3148025 },
    { lat: 49.2771507, lng: 19.3148712 },
    { lat: 49.2777403, lng: 19.3143051 },
    { lat: 49.2777668, lng: 19.3141968 },
    { lat: 49.2778768, lng: 19.3142099 },
    { lat: 49.2783147, lng: 19.3139411 },
    { lat: 49.2784455, lng: 19.3136812 },
    { lat: 49.2790658, lng: 19.3134056 },
    { lat: 49.279313, lng: 19.3131 },
    { lat: 49.2797065, lng: 19.3128418 },
    { lat: 49.2803387, lng: 19.3121259 },
    { lat: 49.2805658, lng: 19.3117807 },
    { lat: 49.2811307, lng: 19.3113364 },
    { lat: 49.2811714, lng: 19.3113142 },
    { lat: 49.2812833, lng: 19.3112275 },
    { lat: 49.2815146, lng: 19.3107547 },
    { lat: 49.2820742, lng: 19.310293 },
    { lat: 49.282121, lng: 19.3102546 },
    { lat: 49.282249, lng: 19.3101492 },
    { lat: 49.2826151, lng: 19.3101463 },
    { lat: 49.2826082, lng: 19.3101716 },
    { lat: 49.2825782, lng: 19.3104406 },
    { lat: 49.2866087, lng: 19.308715 },
    { lat: 49.2866143, lng: 19.3087126 },
    { lat: 49.286617, lng: 19.3086964 },
    { lat: 49.2868492, lng: 19.3073141 },
    { lat: 49.28653, lng: 19.3054977 },
    { lat: 49.2865159, lng: 19.3054141 },
    { lat: 49.285374, lng: 19.303909 },
    { lat: 49.2847467, lng: 19.3019409 },
    { lat: 49.2847136, lng: 19.2995021 },
    { lat: 49.2852201, lng: 19.2987273 },
    { lat: 49.2858789, lng: 19.2976746 },
    { lat: 49.2864703, lng: 19.2962399 },
    { lat: 49.2868967, lng: 19.2951768 },
    { lat: 49.2873178, lng: 19.2941261 },
    { lat: 49.2875874, lng: 19.2934876 },
    { lat: 49.2878956, lng: 19.2929748 },
    { lat: 49.2880589, lng: 19.2924054 },
    { lat: 49.2893403, lng: 19.2927829 },
    { lat: 49.2910107, lng: 19.2932185 },
    { lat: 49.2923276, lng: 19.2935896 },
    { lat: 49.2934884, lng: 19.2938968 },
    { lat: 49.2953193, lng: 19.2943402 },
    { lat: 49.2969889, lng: 19.2948034 },
    { lat: 49.2986696, lng: 19.2952874 },
    { lat: 49.3002827, lng: 19.2956811 },
    { lat: 49.3019654, lng: 19.2960628 },
    { lat: 49.3036241, lng: 19.296511 },
    { lat: 49.3051065, lng: 19.2969268 },
    { lat: 49.3051177, lng: 19.2969295 },
    { lat: 49.3053848, lng: 19.2969989 },
    { lat: 49.3055502, lng: 19.2970413 },
    { lat: 49.3057145, lng: 19.2970841 },
    { lat: 49.3058799, lng: 19.2971268 },
    { lat: 49.3060112, lng: 19.2971607 },
    { lat: 49.3061549, lng: 19.2971982 },
    { lat: 49.3061681, lng: 19.2971679 },
    { lat: 49.3061978, lng: 19.2971032 },
    { lat: 49.306228, lng: 19.297047 },
    { lat: 49.306204, lng: 19.297012 },
    { lat: 49.306167, lng: 19.296579 },
    { lat: 49.306265, lng: 19.29586 },
    { lat: 49.306228, lng: 19.295368 },
    { lat: 49.306121, lng: 19.295173 },
    { lat: 49.306056, lng: 19.295188 },
    { lat: 49.306025, lng: 19.295186 },
    { lat: 49.305898, lng: 19.295022 },
    { lat: 49.305808, lng: 19.294521 },
    { lat: 49.305765, lng: 19.29376 },
    { lat: 49.305753, lng: 19.293239 },
    { lat: 49.305624, lng: 19.29303 },
    { lat: 49.305445, lng: 19.292578 },
    { lat: 49.305319, lng: 19.292249 },
    { lat: 49.305317, lng: 19.292249 },
    { lat: 49.305316, lng: 19.292242 },
    { lat: 49.305071, lng: 19.291604 },
    { lat: 49.305225, lng: 19.291165 },
    { lat: 49.305445, lng: 19.290391 },
    { lat: 49.305433, lng: 19.289948 },
    { lat: 49.305182, lng: 19.289278 },
    { lat: 49.305166, lng: 19.287766 },
    { lat: 49.305112, lng: 19.287014 },
    { lat: 49.304972, lng: 19.28605 },
    { lat: 49.304983, lng: 19.28532 },
    { lat: 49.304728, lng: 19.284746 },
    { lat: 49.304441, lng: 19.284298 },
    { lat: 49.304364, lng: 19.283636 },
    { lat: 49.304163, lng: 19.283205 },
    { lat: 49.3041, lng: 19.282598 },
    { lat: 49.303817, lng: 19.281834 },
    { lat: 49.30358, lng: 19.281564 },
    { lat: 49.303082, lng: 19.281356 },
    { lat: 49.302669, lng: 19.28075 },
    { lat: 49.302493, lng: 19.280297 },
    { lat: 49.302257, lng: 19.280078 },
    { lat: 49.302244, lng: 19.279531 },
    { lat: 49.301944, lng: 19.278858 },
    { lat: 49.3017117, lng: 19.2783991 },
    { lat: 49.30158, lng: 19.278139 },
    { lat: 49.301164, lng: 19.277452 },
    { lat: 49.301094, lng: 19.277109 },
    { lat: 49.300926, lng: 19.276798 },
    { lat: 49.300813, lng: 19.276534 },
    { lat: 49.30067, lng: 19.275954 },
    { lat: 49.300633, lng: 19.274647 },
    { lat: 49.300526, lng: 19.27415 },
    { lat: 49.300552, lng: 19.273563 },
    { lat: 49.300479, lng: 19.273116 },
    { lat: 49.300513, lng: 19.272222 },
    { lat: 49.300455, lng: 19.271777 },
    { lat: 49.300489, lng: 19.270758 },
    { lat: 49.300325, lng: 19.270125 },
    { lat: 49.300117, lng: 19.26969 },
    { lat: 49.299888, lng: 19.269458 },
    { lat: 49.299631, lng: 19.269292 },
    { lat: 49.299293, lng: 19.268843 },
    { lat: 49.298816, lng: 19.268576 },
    { lat: 49.298541, lng: 19.268264 },
    { lat: 49.298312, lng: 19.268123 },
    { lat: 49.298173, lng: 19.268202 },
    { lat: 49.297858, lng: 19.268422 },
    { lat: 49.297709, lng: 19.268358 },
    { lat: 49.297274, lng: 19.268406 },
    { lat: 49.296801, lng: 19.267959 },
    { lat: 49.296493, lng: 19.267588 },
    { lat: 49.296249, lng: 19.267573 },
    { lat: 49.29615, lng: 19.267436 },
    { lat: 49.295977, lng: 19.266933 },
    { lat: 49.295666, lng: 19.266725 },
    { lat: 49.295398, lng: 19.266303 },
    { lat: 49.295299, lng: 19.266002 },
    { lat: 49.295204, lng: 19.265778 },
    { lat: 49.295058, lng: 19.265759 },
    { lat: 49.294985, lng: 19.265673 },
    { lat: 49.294931, lng: 19.265513 },
    { lat: 49.294698, lng: 19.265171 },
    { lat: 49.294382, lng: 19.26498 },
    { lat: 49.294239, lng: 19.264898 },
    { lat: 49.294133, lng: 19.26475 },
    { lat: 49.294047, lng: 19.264589 },
    { lat: 49.293926, lng: 19.264422 },
    { lat: 49.293642, lng: 19.264342 },
    { lat: 49.293459, lng: 19.264011 },
    { lat: 49.293394, lng: 19.263782 },
    { lat: 49.293238, lng: 19.263416 },
    { lat: 49.293135, lng: 19.263227 },
    { lat: 49.292964, lng: 19.263132 },
    { lat: 49.292697, lng: 19.262747 },
    { lat: 49.292558, lng: 19.262678 },
    { lat: 49.292411, lng: 19.262523 },
    { lat: 49.292169, lng: 19.262365 },
    { lat: 49.291678, lng: 19.26157 },
    { lat: 49.291487, lng: 19.261373 },
    { lat: 49.291171, lng: 19.261245 },
    { lat: 49.290899, lng: 19.261 },
    { lat: 49.290362, lng: 19.260622 },
    { lat: 49.289996, lng: 19.260183 },
    { lat: 49.289772, lng: 19.259846 },
    { lat: 49.289664, lng: 19.259635 },
    { lat: 49.289583, lng: 19.259262 },
    { lat: 49.289514, lng: 19.25889 },
    { lat: 49.28925, lng: 19.258381 },
    { lat: 49.288979, lng: 19.258013 },
    { lat: 49.288934, lng: 19.257823 },
    { lat: 49.288685, lng: 19.257396 },
    { lat: 49.288405, lng: 19.257182 },
    { lat: 49.288099, lng: 19.256857 },
    { lat: 49.287843, lng: 19.256718 },
    { lat: 49.287676, lng: 19.25653 },
    { lat: 49.287612, lng: 19.256283 },
    { lat: 49.287431, lng: 19.256004 },
    { lat: 49.287187, lng: 19.255887 },
    { lat: 49.286982, lng: 19.255759 },
    { lat: 49.285935, lng: 19.255604 },
    { lat: 49.285623, lng: 19.25549 },
    { lat: 49.285359, lng: 19.2555 },
    { lat: 49.285166, lng: 19.255633 },
    { lat: 49.284571, lng: 19.256224 },
    { lat: 49.284332, lng: 19.256554 },
    { lat: 49.283841, lng: 19.256792 },
    { lat: 49.283666, lng: 19.256733 },
    { lat: 49.283538, lng: 19.256542 },
    { lat: 49.283372, lng: 19.256388 },
    { lat: 49.283211, lng: 19.256333 },
    { lat: 49.282566, lng: 19.256396 },
    { lat: 49.282341, lng: 19.255602 },
    { lat: 49.282333, lng: 19.254221 },
    { lat: 49.282164, lng: 19.25373 },
    { lat: 49.281779, lng: 19.252826 },
    { lat: 49.281563, lng: 19.252155 },
    { lat: 49.280947, lng: 19.249739 },
    { lat: 49.280755, lng: 19.249062 },
    { lat: 49.280516, lng: 19.248571 },
    { lat: 49.280347, lng: 19.247987 },
    { lat: 49.280048, lng: 19.247376 },
    { lat: 49.279551, lng: 19.246148 },
    { lat: 49.2793777, lng: 19.2457541 },
  ],
  OravskýPodzámok: [
    { lat: 49.303075, lng: 19.363637 },
    { lat: 49.302348, lng: 19.361691 },
    { lat: 49.301484, lng: 19.359885 },
    { lat: 49.300726, lng: 19.357891 },
    { lat: 49.301672, lng: 19.356158 },
    { lat: 49.303065, lng: 19.3541 },
    { lat: 49.303064, lng: 19.35407 },
    { lat: 49.303064, lng: 19.35403 },
    { lat: 49.303064, lng: 19.353993 },
    { lat: 49.303063, lng: 19.353905 },
    { lat: 49.303062, lng: 19.353833 },
    { lat: 49.303063, lng: 19.353819 },
    { lat: 49.303888, lng: 19.352749 },
    { lat: 49.304106, lng: 19.352354 },
    { lat: 49.304762, lng: 19.351169 },
    { lat: 49.304766, lng: 19.351145 },
    { lat: 49.304838, lng: 19.350755 },
    { lat: 49.304917, lng: 19.350325 },
    { lat: 49.304995, lng: 19.349901 },
    { lat: 49.305036, lng: 19.349685 },
    { lat: 49.305093, lng: 19.349372 },
    { lat: 49.305457, lng: 19.348091 },
    { lat: 49.305549, lng: 19.347544 },
    { lat: 49.30602, lng: 19.347216 },
    { lat: 49.306352, lng: 19.34629 },
    { lat: 49.306447, lng: 19.345252 },
    { lat: 49.306844, lng: 19.344056 },
    { lat: 49.306861, lng: 19.343807 },
    { lat: 49.306921, lng: 19.34295 },
    { lat: 49.306963, lng: 19.342459 },
    { lat: 49.307028, lng: 19.341707 },
    { lat: 49.307037, lng: 19.341601 },
    { lat: 49.307039, lng: 19.341567 },
    { lat: 49.307054, lng: 19.341399 },
    { lat: 49.307526, lng: 19.339964 },
    { lat: 49.307603, lng: 19.339854 },
    { lat: 49.307767, lng: 19.339619 },
    { lat: 49.307794, lng: 19.339211 },
    { lat: 49.307793, lng: 19.339187 },
    { lat: 49.307752, lng: 19.338409 },
    { lat: 49.307792, lng: 19.338018 },
    { lat: 49.307659, lng: 19.337385 },
    { lat: 49.307433, lng: 19.336996 },
    { lat: 49.307234, lng: 19.336012 },
    { lat: 49.307217, lng: 19.335519 },
    { lat: 49.307148, lng: 19.3347 },
    { lat: 49.307116, lng: 19.334011 },
    { lat: 49.307103, lng: 19.333768 },
    { lat: 49.306919, lng: 19.333262 },
    { lat: 49.306868, lng: 19.333122 },
    { lat: 49.306846, lng: 19.332572 },
    { lat: 49.306826, lng: 19.33211 },
    { lat: 49.306815, lng: 19.33187 },
    { lat: 49.306776, lng: 19.331118 },
    { lat: 49.306767, lng: 19.330925 },
    { lat: 49.30674, lng: 19.33077 },
    { lat: 49.306638, lng: 19.330182 },
    { lat: 49.306475, lng: 19.329701 },
    { lat: 49.306199, lng: 19.329083 },
    { lat: 49.306056, lng: 19.328698 },
    { lat: 49.30605, lng: 19.328685 },
    { lat: 49.306042, lng: 19.328662 },
    { lat: 49.305919, lng: 19.328336 },
    { lat: 49.305909, lng: 19.328294 },
    { lat: 49.305713, lng: 19.327452 },
    { lat: 49.305658, lng: 19.326508 },
    { lat: 49.305469, lng: 19.325774 },
    { lat: 49.305439, lng: 19.325659 },
    { lat: 49.305223, lng: 19.324602 },
    { lat: 49.304978, lng: 19.323937 },
    { lat: 49.304674, lng: 19.323261 },
    { lat: 49.304477, lng: 19.322653 },
    { lat: 49.304392, lng: 19.322363 },
    { lat: 49.30429, lng: 19.321838 },
    { lat: 49.304163, lng: 19.32151 },
    { lat: 49.304055, lng: 19.320555 },
    { lat: 49.303912, lng: 19.31914 },
    { lat: 49.303765, lng: 19.317896 },
    { lat: 49.303829, lng: 19.317089 },
    { lat: 49.304155, lng: 19.316061 },
    { lat: 49.304151, lng: 19.315993 },
    { lat: 49.304029, lng: 19.314213 },
    { lat: 49.303988, lng: 19.313381 },
    { lat: 49.303658, lng: 19.310336 },
    { lat: 49.303632, lng: 19.31009 },
    { lat: 49.303725, lng: 19.309962 },
    { lat: 49.30406, lng: 19.30835 },
    { lat: 49.30407, lng: 19.308303 },
    { lat: 49.30436, lng: 19.308148 },
    { lat: 49.304552, lng: 19.307879 },
    { lat: 49.304391, lng: 19.307118 },
    { lat: 49.304283, lng: 19.306488 },
    { lat: 49.304001, lng: 19.305472 },
    { lat: 49.303783, lng: 19.304823 },
    { lat: 49.303627, lng: 19.304606 },
    { lat: 49.303323, lng: 19.304183 },
    { lat: 49.303637, lng: 19.304231 },
    { lat: 49.303639, lng: 19.30423 },
    { lat: 49.303671, lng: 19.304218 },
    { lat: 49.304294, lng: 19.303982 },
    { lat: 49.304662, lng: 19.303909 },
    { lat: 49.304861, lng: 19.303781 },
    { lat: 49.305022, lng: 19.303678 },
    { lat: 49.305301, lng: 19.303737 },
    { lat: 49.305499, lng: 19.303728 },
    { lat: 49.30556, lng: 19.303725 },
    { lat: 49.305954, lng: 19.303691 },
    { lat: 49.30609, lng: 19.303726 },
    { lat: 49.306359, lng: 19.303795 },
    { lat: 49.30665, lng: 19.303807 },
    { lat: 49.306871, lng: 19.303773 },
    { lat: 49.306911, lng: 19.303767 },
    { lat: 49.30696, lng: 19.30376 },
    { lat: 49.306988, lng: 19.303755 },
    { lat: 49.307, lng: 19.303754 },
    { lat: 49.30718, lng: 19.303601 },
    { lat: 49.307225, lng: 19.303563 },
    { lat: 49.307252, lng: 19.303541 },
    { lat: 49.307365, lng: 19.303444 },
    { lat: 49.307656, lng: 19.30333 },
    { lat: 49.307704, lng: 19.30331 },
    { lat: 49.307746, lng: 19.303336 },
    { lat: 49.308005, lng: 19.303494 },
    { lat: 49.308039, lng: 19.303514 },
    { lat: 49.308109, lng: 19.303555 },
    { lat: 49.308263, lng: 19.303643 },
    { lat: 49.308341, lng: 19.303688 },
    { lat: 49.308377, lng: 19.303673 },
    { lat: 49.308474, lng: 19.303633 },
    { lat: 49.308572, lng: 19.303599 },
    { lat: 49.308586, lng: 19.303437 },
    { lat: 49.308561, lng: 19.303331 },
    { lat: 49.308509, lng: 19.303106 },
    { lat: 49.308505, lng: 19.30309 },
    { lat: 49.308492, lng: 19.303036 },
    { lat: 49.308317, lng: 19.302498 },
    { lat: 49.308171, lng: 19.302211 },
    { lat: 49.308119, lng: 19.302175 },
    { lat: 49.30779, lng: 19.30194 },
    { lat: 49.307678, lng: 19.301785 },
    { lat: 49.307672, lng: 19.301776 },
    { lat: 49.307642, lng: 19.301501 },
    { lat: 49.307652, lng: 19.301397 },
    { lat: 49.307683, lng: 19.301299 },
    { lat: 49.307705, lng: 19.301299 },
    { lat: 49.307717, lng: 19.301264 },
    { lat: 49.307763, lng: 19.301118 },
    { lat: 49.307723, lng: 19.300986 },
    { lat: 49.307694, lng: 19.300891 },
    { lat: 49.30761, lng: 19.300635 },
    { lat: 49.30755, lng: 19.300452 },
    { lat: 49.307502, lng: 19.300262 },
    { lat: 49.307413, lng: 19.299906 },
    { lat: 49.307387, lng: 19.299803 },
    { lat: 49.307387, lng: 19.299471 },
    { lat: 49.307388, lng: 19.299412 },
    { lat: 49.307388, lng: 19.299387 },
    { lat: 49.307387, lng: 19.299298 },
    { lat: 49.307376, lng: 19.299287 },
    { lat: 49.30726, lng: 19.299164 },
    { lat: 49.307181, lng: 19.29908 },
    { lat: 49.307018, lng: 19.29891 },
    { lat: 49.306984, lng: 19.298572 },
    { lat: 49.306967, lng: 19.298392 },
    { lat: 49.306822, lng: 19.297905 },
    { lat: 49.306688, lng: 19.297711 },
    { lat: 49.306542, lng: 19.297503 },
    { lat: 49.306494, lng: 19.297433 },
    { lat: 49.306228, lng: 19.297047 },
    { lat: 49.3061978, lng: 19.2971032 },
    { lat: 49.3061681, lng: 19.2971679 },
    { lat: 49.3061549, lng: 19.2971982 },
    { lat: 49.3060112, lng: 19.2971607 },
    { lat: 49.3058799, lng: 19.2971268 },
    { lat: 49.3057145, lng: 19.2970841 },
    { lat: 49.3055502, lng: 19.2970413 },
    { lat: 49.3053848, lng: 19.2969989 },
    { lat: 49.3051177, lng: 19.2969295 },
    { lat: 49.3051065, lng: 19.2969268 },
    { lat: 49.3036241, lng: 19.296511 },
    { lat: 49.3019654, lng: 19.2960628 },
    { lat: 49.3002827, lng: 19.2956811 },
    { lat: 49.2986696, lng: 19.2952874 },
    { lat: 49.2969889, lng: 19.2948034 },
    { lat: 49.2953193, lng: 19.2943402 },
    { lat: 49.2934884, lng: 19.2938968 },
    { lat: 49.2923276, lng: 19.2935896 },
    { lat: 49.2910107, lng: 19.2932185 },
    { lat: 49.2893403, lng: 19.2927829 },
    { lat: 49.2880589, lng: 19.2924054 },
    { lat: 49.2878956, lng: 19.2929748 },
    { lat: 49.2875874, lng: 19.2934876 },
    { lat: 49.2873178, lng: 19.2941261 },
    { lat: 49.2868967, lng: 19.2951768 },
    { lat: 49.2864703, lng: 19.2962399 },
    { lat: 49.2858789, lng: 19.2976746 },
    { lat: 49.2852201, lng: 19.2987273 },
    { lat: 49.2847136, lng: 19.2995021 },
    { lat: 49.2847467, lng: 19.3019409 },
    { lat: 49.285374, lng: 19.303909 },
    { lat: 49.2865159, lng: 19.3054141 },
    { lat: 49.28653, lng: 19.3054977 },
    { lat: 49.2868492, lng: 19.3073141 },
    { lat: 49.286617, lng: 19.3086964 },
    { lat: 49.2866143, lng: 19.3087126 },
    { lat: 49.2866087, lng: 19.308715 },
    { lat: 49.2825782, lng: 19.3104406 },
    { lat: 49.2826082, lng: 19.3101716 },
    { lat: 49.2826151, lng: 19.3101463 },
    { lat: 49.282249, lng: 19.3101492 },
    { lat: 49.282121, lng: 19.3102546 },
    { lat: 49.2820742, lng: 19.310293 },
    { lat: 49.2815146, lng: 19.3107547 },
    { lat: 49.2812833, lng: 19.3112275 },
    { lat: 49.2811714, lng: 19.3113142 },
    { lat: 49.2811307, lng: 19.3113364 },
    { lat: 49.2805658, lng: 19.3117807 },
    { lat: 49.2803387, lng: 19.3121259 },
    { lat: 49.2797065, lng: 19.3128418 },
    { lat: 49.279313, lng: 19.3131 },
    { lat: 49.2790658, lng: 19.3134056 },
    { lat: 49.2784455, lng: 19.3136812 },
    { lat: 49.2783147, lng: 19.3139411 },
    { lat: 49.2778768, lng: 19.3142099 },
    { lat: 49.2777668, lng: 19.3141968 },
    { lat: 49.2777403, lng: 19.3143051 },
    { lat: 49.2771507, lng: 19.3148712 },
    { lat: 49.2765398, lng: 19.3148025 },
    { lat: 49.2761203, lng: 19.3148895 },
    { lat: 49.2758401, lng: 19.3150158 },
    { lat: 49.2755756, lng: 19.3150207 },
    { lat: 49.275312, lng: 19.3149055 },
    { lat: 49.2750123, lng: 19.3149096 },
    { lat: 49.2746217, lng: 19.314585 },
    { lat: 49.2742909, lng: 19.3146211 },
    { lat: 49.2736534, lng: 19.3145319 },
    { lat: 49.2734234, lng: 19.3144068 },
    { lat: 49.2733262, lng: 19.314354 },
    { lat: 49.2732718, lng: 19.3144181 },
    { lat: 49.2730972, lng: 19.31442 },
    { lat: 49.2729425, lng: 19.3145107 },
    { lat: 49.2728724, lng: 19.3144802 },
    { lat: 49.2727844, lng: 19.3145982 },
    { lat: 49.2726016, lng: 19.3145465 },
    { lat: 49.2724783, lng: 19.3147276 },
    { lat: 49.2722902, lng: 19.3147299 },
    { lat: 49.2721653, lng: 19.3151649 },
    { lat: 49.2720105, lng: 19.3151765 },
    { lat: 49.2716693, lng: 19.3155166 },
    { lat: 49.2715839, lng: 19.3157848 },
    { lat: 49.2706503, lng: 19.316777 },
    { lat: 49.270363, lng: 19.3168743 },
    { lat: 49.2701467, lng: 19.3171854 },
    { lat: 49.2700292, lng: 19.3172027 },
    { lat: 49.2696781, lng: 19.3169982 },
    { lat: 49.2693636, lng: 19.3172848 },
    { lat: 49.2692507, lng: 19.3172341 },
    { lat: 49.2691472, lng: 19.317117 },
    { lat: 49.2689204, lng: 19.3175859 },
    { lat: 49.2689364, lng: 19.3179479 },
    { lat: 49.268899, lng: 19.3181123 },
    { lat: 49.268768, lng: 19.3182736 },
    { lat: 49.268743, lng: 19.3187704 },
    { lat: 49.2684197, lng: 19.31944 },
    { lat: 49.2684015, lng: 19.3194146 },
    { lat: 49.2683207, lng: 19.3193225 },
    { lat: 49.268292, lng: 19.319291 },
    { lat: 49.2680004, lng: 19.3177458 },
    { lat: 49.2679946, lng: 19.3177183 },
    { lat: 49.2680561, lng: 19.3174907 },
    { lat: 49.2680568, lng: 19.3174848 },
    { lat: 49.2680314, lng: 19.3173277 },
    { lat: 49.2679335, lng: 19.316733 },
    { lat: 49.2678525, lng: 19.3162338 },
    { lat: 49.267723, lng: 19.3160475 },
    { lat: 49.2673375, lng: 19.3154946 },
    { lat: 49.2672443, lng: 19.3153609 },
    { lat: 49.2670578, lng: 19.3153724 },
    { lat: 49.2662939, lng: 19.3138231 },
    { lat: 49.2662732, lng: 19.3134616 },
    { lat: 49.2662564, lng: 19.3131762 },
    { lat: 49.2662539, lng: 19.3131217 },
    { lat: 49.2662386, lng: 19.3128679 },
    { lat: 49.266223, lng: 19.3125892 },
    { lat: 49.2662333, lng: 19.312447 },
    { lat: 49.2662341, lng: 19.3124386 },
    { lat: 49.2663293, lng: 19.31119 },
    { lat: 49.2661477, lng: 19.3105154 },
    { lat: 49.2661138, lng: 19.3105382 },
    { lat: 49.2659795, lng: 19.3106292 },
    { lat: 49.264277, lng: 19.3117817 },
    { lat: 49.2642411, lng: 19.3118058 },
    { lat: 49.2641591, lng: 19.3118611 },
    { lat: 49.2631511, lng: 19.3125431 },
    { lat: 49.2625189, lng: 19.3129712 },
    { lat: 49.2616333, lng: 19.3135705 },
    { lat: 49.2608082, lng: 19.3141392 },
    { lat: 49.2607743, lng: 19.3141628 },
    { lat: 49.2603846, lng: 19.3144313 },
    { lat: 49.26034, lng: 19.3144618 },
    { lat: 49.2586854, lng: 19.3156022 },
    { lat: 49.258311, lng: 19.3158607 },
    { lat: 49.2573376, lng: 19.3165316 },
    { lat: 49.2573113, lng: 19.3165493 },
    { lat: 49.2572546, lng: 19.3165887 },
    { lat: 49.2564885, lng: 19.3171169 },
    { lat: 49.2564798, lng: 19.317123 },
    { lat: 49.2552422, lng: 19.3179308 },
    { lat: 49.2551918, lng: 19.3179637 },
    { lat: 49.2548585, lng: 19.3181816 },
    { lat: 49.2547236, lng: 19.3183599 },
    { lat: 49.2546816, lng: 19.3184201 },
    { lat: 49.254543, lng: 19.3186157 },
    { lat: 49.2544677, lng: 19.3187124 },
    { lat: 49.254117, lng: 19.319162 },
    { lat: 49.2537414, lng: 19.3196583 },
    { lat: 49.2536885, lng: 19.3197283 },
    { lat: 49.2536016, lng: 19.3198432 },
    { lat: 49.2534042, lng: 19.3201042 },
    { lat: 49.2532757, lng: 19.3202745 },
    { lat: 49.2532386, lng: 19.3203234 },
    { lat: 49.2531539, lng: 19.3203388 },
    { lat: 49.2524767, lng: 19.3204625 },
    { lat: 49.2522946, lng: 19.3204959 },
    { lat: 49.2519094, lng: 19.3205008 },
    { lat: 49.2518419, lng: 19.3205017 },
    { lat: 49.2513667, lng: 19.3205083 },
    { lat: 49.251315, lng: 19.3205147 },
    { lat: 49.2512535, lng: 19.3205229 },
    { lat: 49.251094, lng: 19.3207508 },
    { lat: 49.2508651, lng: 19.321079 },
    { lat: 49.2507145, lng: 19.3214715 },
    { lat: 49.2505025, lng: 19.3219725 },
    { lat: 49.2503685, lng: 19.322305 },
    { lat: 49.2502474, lng: 19.3226059 },
    { lat: 49.2500843, lng: 19.3232338 },
    { lat: 49.2500164, lng: 19.3234917 },
    { lat: 49.2499964, lng: 19.3235685 },
    { lat: 49.2499312, lng: 19.3238124 },
    { lat: 49.2499886, lng: 19.3240932 },
    { lat: 49.2503311, lng: 19.3257668 },
    { lat: 49.2505455, lng: 19.3261321 },
    { lat: 49.2502881, lng: 19.3265987 },
    { lat: 49.2495646, lng: 19.3275765 },
    { lat: 49.2493602, lng: 19.3279749 },
    { lat: 49.2492073, lng: 19.3282716 },
    { lat: 49.2487128, lng: 19.3286582 },
    { lat: 49.2486976, lng: 19.3286704 },
    { lat: 49.2485589, lng: 19.3283797 },
    { lat: 49.2484519, lng: 19.3278573 },
    { lat: 49.2479335, lng: 19.3272098 },
    { lat: 49.2472235, lng: 19.326612 },
    { lat: 49.2471364, lng: 19.3264873 },
    { lat: 49.246132, lng: 19.3261942 },
    { lat: 49.2460335, lng: 19.3264951 },
    { lat: 49.2449899, lng: 19.3278049 },
    { lat: 49.2449769, lng: 19.3278207 },
    { lat: 49.2446423, lng: 19.3282262 },
    { lat: 49.2443992, lng: 19.3285202 },
    { lat: 49.243964, lng: 19.3289965 },
    { lat: 49.2432797, lng: 19.3294941 },
    { lat: 49.2426076, lng: 19.3299835 },
    { lat: 49.2425749, lng: 19.330007 },
    { lat: 49.2425443, lng: 19.3300291 },
    { lat: 49.2416753, lng: 19.3306945 },
    { lat: 49.2416477, lng: 19.3307055 },
    { lat: 49.2416337, lng: 19.3307698 },
    { lat: 49.2413552, lng: 19.3311228 },
    { lat: 49.2410394, lng: 19.3315236 },
    { lat: 49.2409213, lng: 19.3316725 },
    { lat: 49.2408237, lng: 19.3316357 },
    { lat: 49.2407744, lng: 19.3316169 },
    { lat: 49.2407267, lng: 19.3316568 },
    { lat: 49.2406833, lng: 19.3316929 },
    { lat: 49.2406453, lng: 19.3317242 },
    { lat: 49.2405907, lng: 19.3318084 },
    { lat: 49.2402112, lng: 19.3323839 },
    { lat: 49.2399378, lng: 19.3330419 },
    { lat: 49.2394957, lng: 19.3333934 },
    { lat: 49.2393872, lng: 19.3334803 },
    { lat: 49.2390503, lng: 19.3337474 },
    { lat: 49.2390414, lng: 19.3338191 },
    { lat: 49.2389549, lng: 19.3344958 },
    { lat: 49.2389163, lng: 19.3348026 },
    { lat: 49.2389068, lng: 19.3348873 },
    { lat: 49.2389036, lng: 19.3349147 },
    { lat: 49.2387686, lng: 19.3358049 },
    { lat: 49.2389655, lng: 19.3363743 },
    { lat: 49.2390993, lng: 19.3367243 },
    { lat: 49.2391119, lng: 19.3367564 },
    { lat: 49.239171, lng: 19.3369086 },
    { lat: 49.2392301, lng: 19.3370641 },
    { lat: 49.2392391, lng: 19.3370854 },
    { lat: 49.2392639, lng: 19.3373514 },
    { lat: 49.2393583, lng: 19.3383231 },
    { lat: 49.239752, lng: 19.3389114 },
    { lat: 49.2398987, lng: 19.3392238 },
    { lat: 49.2400974, lng: 19.3398786 },
    { lat: 49.2406171, lng: 19.3416793 },
    { lat: 49.2408223, lng: 19.3423262 },
    { lat: 49.2412879, lng: 19.3431739 },
    { lat: 49.2419115, lng: 19.3443339 },
    { lat: 49.2419328, lng: 19.3443738 },
    { lat: 49.2419851, lng: 19.3444561 },
    { lat: 49.2422638, lng: 19.3448948 },
    { lat: 49.2431696, lng: 19.3458291 },
    { lat: 49.2444992, lng: 19.3469274 },
    { lat: 49.245531, lng: 19.3473503 },
    { lat: 49.2467009, lng: 19.3478349 },
    { lat: 49.2476033, lng: 19.3480671 },
    { lat: 49.247678, lng: 19.3480734 },
    { lat: 49.2479036, lng: 19.3480555 },
    { lat: 49.2481477, lng: 19.3479739 },
    { lat: 49.2485991, lng: 19.3478656 },
    { lat: 49.248807, lng: 19.347757 },
    { lat: 49.2493993, lng: 19.3474111 },
    { lat: 49.2498844, lng: 19.3470412 },
    { lat: 49.2503886, lng: 19.3464044 },
    { lat: 49.25067, lng: 19.3467542 },
    { lat: 49.2506856, lng: 19.3467737 },
    { lat: 49.2507154, lng: 19.3468105 },
    { lat: 49.250891, lng: 19.3469998 },
    { lat: 49.2510417, lng: 19.3471614 },
    { lat: 49.2513831, lng: 19.3475425 },
    { lat: 49.2515414, lng: 19.3475778 },
    { lat: 49.2517714, lng: 19.3487105 },
    { lat: 49.2519965, lng: 19.3498074 },
    { lat: 49.2522693, lng: 19.3505777 },
    { lat: 49.2527367, lng: 19.3529694 },
    { lat: 49.2529117, lng: 19.3533633 },
    { lat: 49.2531751, lng: 19.3553979 },
    { lat: 49.2532551, lng: 19.3575821 },
    { lat: 49.2532446, lng: 19.3585872 },
    { lat: 49.2532322, lng: 19.3592343 },
    { lat: 49.2533233, lng: 19.3598347 },
    { lat: 49.2533316, lng: 19.3604182 },
    { lat: 49.2532913, lng: 19.3608335 },
    { lat: 49.2531259, lng: 19.3610782 },
    { lat: 49.2528436, lng: 19.3613345 },
    { lat: 49.252633, lng: 19.3616025 },
    { lat: 49.2523089, lng: 19.3623554 },
    { lat: 49.2520847, lng: 19.3626696 },
    { lat: 49.2504133, lng: 19.3636996 },
    { lat: 49.2503793, lng: 19.3637437 },
    { lat: 49.2501913, lng: 19.3639847 },
    { lat: 49.2502454, lng: 19.3641768 },
    { lat: 49.2502364, lng: 19.3641821 },
    { lat: 49.2502301, lng: 19.3641886 },
    { lat: 49.2490051, lng: 19.3653905 },
    { lat: 49.2485295, lng: 19.3658692 },
    { lat: 49.2479726, lng: 19.36618 },
    { lat: 49.247653, lng: 19.3665005 },
    { lat: 49.2465959, lng: 19.3684516 },
    { lat: 49.246413, lng: 19.3692156 },
    { lat: 49.2462295, lng: 19.3713601 },
    { lat: 49.2462727, lng: 19.3719953 },
    { lat: 49.2465896, lng: 19.3744596 },
    { lat: 49.2463905, lng: 19.3746368 },
    { lat: 49.2459113, lng: 19.3750901 },
    { lat: 49.2452089, lng: 19.3757582 },
    { lat: 49.2439313, lng: 19.3765611 },
    { lat: 49.2441934, lng: 19.3776117 },
    { lat: 49.2445951, lng: 19.3787293 },
    { lat: 49.2457661, lng: 19.3789519 },
    { lat: 49.247416, lng: 19.3798722 },
    { lat: 49.2481566, lng: 19.38007 },
    { lat: 49.2527072, lng: 19.3842504 },
    { lat: 49.2527522, lng: 19.3843234 },
    { lat: 49.252821, lng: 19.3842766 },
    { lat: 49.2528864, lng: 19.3842305 },
    { lat: 49.252968, lng: 19.3840957 },
    { lat: 49.2530118, lng: 19.3840181 },
    { lat: 49.2530652, lng: 19.3839066 },
    { lat: 49.2530701, lng: 19.3837953 },
    { lat: 49.2530666, lng: 19.3836936 },
    { lat: 49.253033, lng: 19.3835569 },
    { lat: 49.2529854, lng: 19.3833565 },
    { lat: 49.2529393, lng: 19.3831667 },
    { lat: 49.2528827, lng: 19.3830178 },
    { lat: 49.2528083, lng: 19.3828188 },
    { lat: 49.2527813, lng: 19.3827514 },
    { lat: 49.2527119, lng: 19.382566 },
    { lat: 49.2527303, lng: 19.3825488 },
    { lat: 49.2526983, lng: 19.3824657 },
    { lat: 49.2526452, lng: 19.3823196 },
    { lat: 49.2526439, lng: 19.382099 },
    { lat: 49.2526754, lng: 19.3820014 },
    { lat: 49.2526765, lng: 19.3819988 },
    { lat: 49.2527192, lng: 19.3818762 },
    { lat: 49.2528086, lng: 19.3819357 },
    { lat: 49.2530845, lng: 19.3821243 },
    { lat: 49.2531592, lng: 19.3821764 },
    { lat: 49.2535399, lng: 19.3824401 },
    { lat: 49.2537624, lng: 19.382593 },
    { lat: 49.2540483, lng: 19.3827859 },
    { lat: 49.2542426, lng: 19.3832293 },
    { lat: 49.2543133, lng: 19.3833879 },
    { lat: 49.2544544, lng: 19.3837092 },
    { lat: 49.2546052, lng: 19.3839965 },
    { lat: 49.2547033, lng: 19.3841907 },
    { lat: 49.2547274, lng: 19.3843439 },
    { lat: 49.2549157, lng: 19.3841279 },
    { lat: 49.2551437, lng: 19.3838718 },
    { lat: 49.2553739, lng: 19.3836141 },
    { lat: 49.2554875, lng: 19.3834881 },
    { lat: 49.2557589, lng: 19.3833629 },
    { lat: 49.2559089, lng: 19.3832928 },
    { lat: 49.2559721, lng: 19.3832701 },
    { lat: 49.2561321, lng: 19.3831978 },
    { lat: 49.2563746, lng: 19.3830809 },
    { lat: 49.2565656, lng: 19.3831932 },
    { lat: 49.2566989, lng: 19.3832714 },
    { lat: 49.2567985, lng: 19.3834009 },
    { lat: 49.2569378, lng: 19.383583 },
    { lat: 49.2571403, lng: 19.3838442 },
    { lat: 49.2572662, lng: 19.383982 },
    { lat: 49.2573185, lng: 19.3840394 },
    { lat: 49.2573719, lng: 19.3840971 },
    { lat: 49.2574088, lng: 19.3841375 },
    { lat: 49.2575096, lng: 19.3842495 },
    { lat: 49.2577001, lng: 19.3844654 },
    { lat: 49.2580306, lng: 19.384857 },
    { lat: 49.2581093, lng: 19.3849516 },
    { lat: 49.2581713, lng: 19.3850246 },
    { lat: 49.258244, lng: 19.3851104 },
    { lat: 49.2582953, lng: 19.3851709 },
    { lat: 49.258344, lng: 19.3852244 },
    { lat: 49.2583567, lng: 19.3852335 },
    { lat: 49.2583738, lng: 19.3852345 },
    { lat: 49.2585282, lng: 19.3853302 },
    { lat: 49.2587182, lng: 19.3854456 },
    { lat: 49.2587519, lng: 19.3854636 },
    { lat: 49.2587529, lng: 19.3854645 },
    { lat: 49.2588991, lng: 19.3855514 },
    { lat: 49.2589003, lng: 19.3855513 },
    { lat: 49.2590078, lng: 19.3854927 },
    { lat: 49.2591143, lng: 19.3855987 },
    { lat: 49.2593269, lng: 19.385809 },
    { lat: 49.2593918, lng: 19.3858741 },
    { lat: 49.2594273, lng: 19.3859096 },
    { lat: 49.2594538, lng: 19.3859222 },
    { lat: 49.2594792, lng: 19.3859349 },
    { lat: 49.2595404, lng: 19.3859651 },
    { lat: 49.2595703, lng: 19.3859788 },
    { lat: 49.2599678, lng: 19.3862396 },
    { lat: 49.259998, lng: 19.3862598 },
    { lat: 49.2600787, lng: 19.3863181 },
    { lat: 49.2601357, lng: 19.3863603 },
    { lat: 49.2602218, lng: 19.3864202 },
    { lat: 49.2602591, lng: 19.3864446 },
    { lat: 49.260345, lng: 19.3864995 },
    { lat: 49.2603952, lng: 19.3865326 },
    { lat: 49.2604264, lng: 19.3865534 },
    { lat: 49.26043, lng: 19.3865548 },
    { lat: 49.2604875, lng: 19.3865832 },
    { lat: 49.2605714, lng: 19.3866152 },
    { lat: 49.2605965, lng: 19.3866222 },
    { lat: 49.2606262, lng: 19.3866305 },
    { lat: 49.2606558, lng: 19.3866367 },
    { lat: 49.2606899, lng: 19.3866441 },
    { lat: 49.2607161, lng: 19.3866492 },
    { lat: 49.260733, lng: 19.3866528 },
    { lat: 49.2607478, lng: 19.3866555 },
    { lat: 49.2607739, lng: 19.3866592 },
    { lat: 49.2608013, lng: 19.3866641 },
    { lat: 49.2608365, lng: 19.3866693 },
    { lat: 49.2608762, lng: 19.3866758 },
    { lat: 49.2608988, lng: 19.3866791 },
    { lat: 49.2609283, lng: 19.3866839 },
    { lat: 49.2609557, lng: 19.3866881 },
    { lat: 49.2609714, lng: 19.38669 },
    { lat: 49.2610089, lng: 19.3866953 },
    { lat: 49.2610407, lng: 19.386699 },
    { lat: 49.2611075, lng: 19.3867073 },
    { lat: 49.2611087, lng: 19.3867075 },
    { lat: 49.2611177, lng: 19.3867087 },
    { lat: 49.2611915, lng: 19.3867223 },
    { lat: 49.2612801, lng: 19.3867383 },
    { lat: 49.2613359, lng: 19.3867488 },
    { lat: 49.2613919, lng: 19.3867658 },
    { lat: 49.2614401, lng: 19.3867808 },
    { lat: 49.2614571, lng: 19.3867865 },
    { lat: 49.2615189, lng: 19.3868054 },
    { lat: 49.2615496, lng: 19.3868114 },
    { lat: 49.2616515, lng: 19.3868238 },
    { lat: 49.2618796, lng: 19.3868592 },
    { lat: 49.2618897, lng: 19.3868325 },
    { lat: 49.2619039, lng: 19.3867981 },
    { lat: 49.2619237, lng: 19.3868375 },
    { lat: 49.2619769, lng: 19.3870103 },
    { lat: 49.2620649, lng: 19.3871115 },
    { lat: 49.2621579, lng: 19.387171 },
    { lat: 49.2622512, lng: 19.3872629 },
    { lat: 49.2624231, lng: 19.3874445 },
    { lat: 49.2624573, lng: 19.3874793 },
    { lat: 49.2624806, lng: 19.3875634 },
    { lat: 49.2625069, lng: 19.3876222 },
    { lat: 49.262565, lng: 19.3877328 },
    { lat: 49.2626089, lng: 19.3878034 },
    { lat: 49.2626346, lng: 19.387849 },
    { lat: 49.2626882, lng: 19.3879088 },
    { lat: 49.2627697, lng: 19.387966 },
    { lat: 49.2628581, lng: 19.3880494 },
    { lat: 49.2629034, lng: 19.3880528 },
    { lat: 49.2629449, lng: 19.3880977 },
    { lat: 49.2630837, lng: 19.3882731 },
    { lat: 49.2632732, lng: 19.3883719 },
    { lat: 49.2632705, lng: 19.3884101 },
    { lat: 49.2632884, lng: 19.3884544 },
    { lat: 49.2633706, lng: 19.3884819 },
    { lat: 49.2633994, lng: 19.3884917 },
    { lat: 49.2635781, lng: 19.3887035 },
    { lat: 49.263675, lng: 19.3887258 },
    { lat: 49.2637366, lng: 19.3888353 },
    { lat: 49.2638258, lng: 19.3888909 },
    { lat: 49.2638956, lng: 19.3889851 },
    { lat: 49.2639876, lng: 19.3890487 },
    { lat: 49.2640653, lng: 19.3891456 },
    { lat: 49.2641548, lng: 19.3891813 },
    { lat: 49.2642444, lng: 19.3893152 },
    { lat: 49.2644164, lng: 19.3894992 },
    { lat: 49.2645229, lng: 19.3896045 },
    { lat: 49.2646061, lng: 19.3897243 },
    { lat: 49.2647257, lng: 19.3898737 },
    { lat: 49.2648985, lng: 19.3901962 },
    { lat: 49.2650158, lng: 19.3903873 },
    { lat: 49.265148, lng: 19.3904938 },
    { lat: 49.2651381, lng: 19.3905486 },
    { lat: 49.2651777, lng: 19.3908871 },
    { lat: 49.265264, lng: 19.3909755 },
    { lat: 49.2653677, lng: 19.3910743 },
    { lat: 49.265411, lng: 19.3911111 },
    { lat: 49.2654263, lng: 19.3911246 },
    { lat: 49.2654592, lng: 19.391152 },
    { lat: 49.2656218, lng: 19.3913782 },
    { lat: 49.2657077, lng: 19.391357 },
    { lat: 49.2657856, lng: 19.3913352 },
    { lat: 49.2658204, lng: 19.3913335 },
    { lat: 49.265856, lng: 19.3912261 },
    { lat: 49.265921, lng: 19.3912916 },
    { lat: 49.2660014, lng: 19.3913702 },
    { lat: 49.2660263, lng: 19.3913725 },
    { lat: 49.2661525, lng: 19.3914013 },
    { lat: 49.2663372, lng: 19.3914679 },
    { lat: 49.2664901, lng: 19.3916078 },
    { lat: 49.2667714, lng: 19.3915469 },
    { lat: 49.266837, lng: 19.3915322 },
    { lat: 49.2669158, lng: 19.3915045 },
    { lat: 49.267013, lng: 19.3914882 },
    { lat: 49.2670543, lng: 19.391457 },
    { lat: 49.2671439, lng: 19.391444 },
    { lat: 49.2672083, lng: 19.3913511 },
    { lat: 49.2674175, lng: 19.3912948 },
    { lat: 49.2676398, lng: 19.3912013 },
    { lat: 49.267831, lng: 19.3911686 },
    { lat: 49.2678363, lng: 19.391141 },
    { lat: 49.2678371, lng: 19.3911337 },
    { lat: 49.2679269, lng: 19.3910987 },
    { lat: 49.2679796, lng: 19.3910698 },
    { lat: 49.2681431, lng: 19.3909756 },
    { lat: 49.2682747, lng: 19.3909216 },
    { lat: 49.2683959, lng: 19.3908409 },
    { lat: 49.2683729, lng: 19.3907586 },
    { lat: 49.2684387, lng: 19.3907417 },
    { lat: 49.2684851, lng: 19.3907695 },
    { lat: 49.2686276, lng: 19.3908327 },
    { lat: 49.2687699, lng: 19.3908143 },
    { lat: 49.2688047, lng: 19.3907867 },
    { lat: 49.2689882, lng: 19.3907573 },
    { lat: 49.2691595, lng: 19.3907596 },
    { lat: 49.2692739, lng: 19.3907201 },
    { lat: 49.2693104, lng: 19.3906826 },
    { lat: 49.2694036, lng: 19.3907021 },
    { lat: 49.2696163, lng: 19.3906959 },
    { lat: 49.2697966, lng: 19.3905713 },
    { lat: 49.2698503, lng: 19.3905178 },
    { lat: 49.2699553, lng: 19.3904714 },
    { lat: 49.2700447, lng: 19.3904331 },
    { lat: 49.2700769, lng: 19.3904008 },
    { lat: 49.2701704, lng: 19.3903484 },
    { lat: 49.2701848, lng: 19.3903432 },
    { lat: 49.2702324, lng: 19.3903275 },
    { lat: 49.2703609, lng: 19.3903027 },
    { lat: 49.270476, lng: 19.3904003 },
    { lat: 49.2705836, lng: 19.3903435 },
    { lat: 49.2707139, lng: 19.3903834 },
    { lat: 49.2708096, lng: 19.3904297 },
    { lat: 49.2709997, lng: 19.3903938 },
    { lat: 49.2711154, lng: 19.390435 },
    { lat: 49.271272, lng: 19.3904869 },
    { lat: 49.2712987, lng: 19.3905017 },
    { lat: 49.2714123, lng: 19.3906192 },
    { lat: 49.2714927, lng: 19.3906982 },
    { lat: 49.2715514, lng: 19.3907019 },
    { lat: 49.2715583, lng: 19.3907012 },
    { lat: 49.2720467, lng: 19.390617 },
    { lat: 49.2721674, lng: 19.3906692 },
    { lat: 49.2722744, lng: 19.3907665 },
    { lat: 49.2723053, lng: 19.3907762 },
    { lat: 49.2723507, lng: 19.3907817 },
    { lat: 49.2724082, lng: 19.3907603 },
    { lat: 49.2726307, lng: 19.3905733 },
    { lat: 49.2729531, lng: 19.3902394 },
    { lat: 49.2729197, lng: 19.3901781 },
    { lat: 49.2731308, lng: 19.3899902 },
    { lat: 49.2733117, lng: 19.389832 },
    { lat: 49.2734826, lng: 19.3898004 },
    { lat: 49.2734837, lng: 19.3898003 },
    { lat: 49.2735928, lng: 19.3897754 },
    { lat: 49.2735963, lng: 19.3897739 },
    { lat: 49.2736359, lng: 19.3897512 },
    { lat: 49.2736743, lng: 19.3897301 },
    { lat: 49.2737271, lng: 19.3897027 },
    { lat: 49.2737974, lng: 19.3896658 },
    { lat: 49.2738586, lng: 19.3896259 },
    { lat: 49.2739314, lng: 19.3895668 },
    { lat: 49.2740393, lng: 19.3894655 },
    { lat: 49.2741812, lng: 19.3893884 },
    { lat: 49.2743249, lng: 19.3893298 },
    { lat: 49.2744399, lng: 19.3893306 },
    { lat: 49.2745228, lng: 19.3892505 },
    { lat: 49.2746555, lng: 19.3891221 },
    { lat: 49.2747762, lng: 19.389001 },
    { lat: 49.2747955, lng: 19.3889801 },
    { lat: 49.2749118, lng: 19.3889115 },
    { lat: 49.2749329, lng: 19.3888829 },
    { lat: 49.274988, lng: 19.3888101 },
    { lat: 49.2750037, lng: 19.3888059 },
    { lat: 49.2750514, lng: 19.3887917 },
    { lat: 49.2751304, lng: 19.388769 },
    { lat: 49.2752458, lng: 19.3887326 },
    { lat: 49.2753047, lng: 19.3887143 },
    { lat: 49.2754304, lng: 19.3887519 },
    { lat: 49.2755356, lng: 19.3887596 },
    { lat: 49.27573, lng: 19.3888662 },
    { lat: 49.2758303, lng: 19.3888701 },
    { lat: 49.2758958, lng: 19.3888734 },
    { lat: 49.275985, lng: 19.388877 },
    { lat: 49.2760448, lng: 19.3888774 },
    { lat: 49.2760516, lng: 19.388877 },
    { lat: 49.2761011, lng: 19.3888485 },
    { lat: 49.2761502, lng: 19.3888194 },
    { lat: 49.2761886, lng: 19.3887968 },
    { lat: 49.2762753, lng: 19.3887466 },
    { lat: 49.2763084, lng: 19.3887267 },
    { lat: 49.2763471, lng: 19.3887113 },
    { lat: 49.2764034, lng: 19.3886882 },
    { lat: 49.2764244, lng: 19.3886784 },
    { lat: 49.2765005, lng: 19.3886441 },
    { lat: 49.2765859, lng: 19.388616 },
    { lat: 49.2766439, lng: 19.3886043 },
    { lat: 49.2767075, lng: 19.3885916 },
    { lat: 49.2768718, lng: 19.3885589 },
    { lat: 49.2768874, lng: 19.3885558 },
    { lat: 49.2769778, lng: 19.3887325 },
    { lat: 49.2770086, lng: 19.388713 },
    { lat: 49.2770292, lng: 19.3887002 },
    { lat: 49.2770928, lng: 19.3886612 },
    { lat: 49.2771615, lng: 19.3886104 },
    { lat: 49.2772158, lng: 19.3885446 },
    { lat: 49.2772766, lng: 19.3884708 },
    { lat: 49.2772964, lng: 19.3884582 },
    { lat: 49.2773271, lng: 19.388439 },
    { lat: 49.2774125, lng: 19.3883868 },
    { lat: 49.2774343, lng: 19.3883732 },
    { lat: 49.2774798, lng: 19.3883553 },
    { lat: 49.277546, lng: 19.3883286 },
    { lat: 49.2775817, lng: 19.3883175 },
    { lat: 49.2776694, lng: 19.3882895 },
    { lat: 49.2777427, lng: 19.3882667 },
    { lat: 49.277804, lng: 19.3882489 },
    { lat: 49.2778739, lng: 19.388229 },
    { lat: 49.2779328, lng: 19.3882118 },
    { lat: 49.2779918, lng: 19.3881949 },
    { lat: 49.2780507, lng: 19.3881776 },
    { lat: 49.2781353, lng: 19.388154 },
    { lat: 49.2781843, lng: 19.38814 },
    { lat: 49.2781876, lng: 19.3881407 },
    { lat: 49.2782798, lng: 19.3881581 },
    { lat: 49.2783786, lng: 19.3881762 },
    { lat: 49.2784103, lng: 19.3881822 },
    { lat: 49.2784606, lng: 19.3881709 },
    { lat: 49.2785466, lng: 19.3881518 },
    { lat: 49.2786547, lng: 19.3881061 },
    { lat: 49.278742, lng: 19.3880699 },
    { lat: 49.2791425, lng: 19.3879834 },
    { lat: 49.2793504, lng: 19.387895 },
    { lat: 49.2793557, lng: 19.3878909 },
    { lat: 49.2795206, lng: 19.3877758 },
    { lat: 49.2798643, lng: 19.3875352 },
    { lat: 49.2802196, lng: 19.3873478 },
    { lat: 49.2802977, lng: 19.3873108 },
    { lat: 49.2803398, lng: 19.3872907 },
    { lat: 49.2803684, lng: 19.3872775 },
    { lat: 49.2804225, lng: 19.3872521 },
    { lat: 49.280438, lng: 19.387245 },
    { lat: 49.2805029, lng: 19.3872145 },
    { lat: 49.2805196, lng: 19.3872072 },
    { lat: 49.2806296, lng: 19.3871512 },
    { lat: 49.2806401, lng: 19.3871829 },
    { lat: 49.2807702, lng: 19.3870717 },
    { lat: 49.2809239, lng: 19.3869405 },
    { lat: 49.2810441, lng: 19.3868336 },
    { lat: 49.2811902, lng: 19.3867047 },
    { lat: 49.2814009, lng: 19.3865356 },
    { lat: 49.28161, lng: 19.3863796 },
    { lat: 49.2818737, lng: 19.3861867 },
    { lat: 49.2820342, lng: 19.3860732 },
    { lat: 49.2821073, lng: 19.3860259 },
    { lat: 49.2821502, lng: 19.3859989 },
    { lat: 49.2823541, lng: 19.3858554 },
    { lat: 49.2825003, lng: 19.3857546 },
    { lat: 49.2825594, lng: 19.3857143 },
    { lat: 49.2828167, lng: 19.3855505 },
    { lat: 49.2829566, lng: 19.3854631 },
    { lat: 49.2831068, lng: 19.385372 },
    { lat: 49.283187, lng: 19.3853232 },
    { lat: 49.2832239, lng: 19.3852961 },
    { lat: 49.2832903, lng: 19.3852463 },
    { lat: 49.2833056, lng: 19.3852352 },
    { lat: 49.2833585, lng: 19.3852082 },
    { lat: 49.2833991, lng: 19.3851879 },
    { lat: 49.2834373, lng: 19.3851617 },
    { lat: 49.2835079, lng: 19.3851017 },
    { lat: 49.2835645, lng: 19.3850385 },
    { lat: 49.2836065, lng: 19.384992 },
    { lat: 49.2836885, lng: 19.3848672 },
    { lat: 49.2837534, lng: 19.3847595 },
    { lat: 49.2838389, lng: 19.3846646 },
    { lat: 49.2838423, lng: 19.3846621 },
    { lat: 49.2839358, lng: 19.3844436 },
    { lat: 49.2838631, lng: 19.3842624 },
    { lat: 49.2838414, lng: 19.3842066 },
    { lat: 49.2837854, lng: 19.3840683 },
    { lat: 49.2838419, lng: 19.3840005 },
    { lat: 49.2838984, lng: 19.3839308 },
    { lat: 49.2839519, lng: 19.3838206 },
    { lat: 49.2839704, lng: 19.3837832 },
    { lat: 49.2840042, lng: 19.3836875 },
    { lat: 49.2840345, lng: 19.3835875 },
    { lat: 49.2840451, lng: 19.3835268 },
    { lat: 49.2840458, lng: 19.383491 },
    { lat: 49.2840477, lng: 19.38339 },
    { lat: 49.2840372, lng: 19.3832594 },
    { lat: 49.2840168, lng: 19.3831126 },
    { lat: 49.2839887, lng: 19.3829705 },
    { lat: 49.2839625, lng: 19.3827933 },
    { lat: 49.2840269, lng: 19.3826292 },
    { lat: 49.2841043, lng: 19.3824259 },
    { lat: 49.2841062, lng: 19.382421 },
    { lat: 49.2841374, lng: 19.3823403 },
    { lat: 49.2841486, lng: 19.3823135 },
    { lat: 49.2843407, lng: 19.382133 },
    { lat: 49.2845274, lng: 19.3819531 },
    { lat: 49.2846898, lng: 19.3817907 },
    { lat: 49.2848168, lng: 19.3816628 },
    { lat: 49.2849003, lng: 19.3816403 },
    { lat: 49.2849466, lng: 19.3816165 },
    { lat: 49.2851407, lng: 19.381525 },
    { lat: 49.2852377, lng: 19.3814783 },
    { lat: 49.2853698, lng: 19.3814141 },
    { lat: 49.2855844, lng: 19.3811351 },
    { lat: 49.2857003, lng: 19.3809878 },
    { lat: 49.285938, lng: 19.3808392 },
    { lat: 49.285949, lng: 19.3808319 },
    { lat: 49.2862502, lng: 19.3806481 },
    { lat: 49.2865213, lng: 19.3803497 },
    { lat: 49.2868065, lng: 19.3801821 },
    { lat: 49.2868088, lng: 19.3801808 },
    { lat: 49.2872624, lng: 19.3799061 },
    { lat: 49.2872821, lng: 19.3798942 },
    { lat: 49.2873214, lng: 19.3798661 },
    { lat: 49.2874297, lng: 19.3797922 },
    { lat: 49.2878254, lng: 19.3795082 },
    { lat: 49.2881729, lng: 19.3792538 },
    { lat: 49.288259, lng: 19.3791902 },
    { lat: 49.2886065, lng: 19.3789354 },
    { lat: 49.288937, lng: 19.3787708 },
    { lat: 49.2889871, lng: 19.3788527 },
    { lat: 49.2890725, lng: 19.3789936 },
    { lat: 49.2893066, lng: 19.3793753 },
    { lat: 49.2894579, lng: 19.3795259 },
    { lat: 49.2897648, lng: 19.3795592 },
    { lat: 49.2897795, lng: 19.3795605 },
    { lat: 49.2899147, lng: 19.3795338 },
    { lat: 49.2899925, lng: 19.3794911 },
    { lat: 49.2901618, lng: 19.379399 },
    { lat: 49.2902893, lng: 19.3793273 },
    { lat: 49.2903892, lng: 19.3792969 },
    { lat: 49.2905268, lng: 19.3792516 },
    { lat: 49.2905769, lng: 19.3792357 },
    { lat: 49.2906001, lng: 19.3792277 },
    { lat: 49.2906294, lng: 19.3792281 },
    { lat: 49.2906745, lng: 19.3792283 },
    { lat: 49.2907513, lng: 19.3792293 },
    { lat: 49.2908009, lng: 19.37923 },
    { lat: 49.2908845, lng: 19.3792339 },
    { lat: 49.2909375, lng: 19.3792364 },
    { lat: 49.2909793, lng: 19.379238 },
    { lat: 49.2909945, lng: 19.3792493 },
    { lat: 49.2910435, lng: 19.3792873 },
    { lat: 49.2911172, lng: 19.3793442 },
    { lat: 49.2911557, lng: 19.379374 },
    { lat: 49.291199, lng: 19.3794072 },
    { lat: 49.2912409, lng: 19.3794398 },
    { lat: 49.2912809, lng: 19.3794701 },
    { lat: 49.2912982, lng: 19.3794833 },
    { lat: 49.2913242, lng: 19.3795076 },
    { lat: 49.2914137, lng: 19.3795888 },
    { lat: 49.2915231, lng: 19.3796881 },
    { lat: 49.2916479, lng: 19.3798008 },
    { lat: 49.2916489, lng: 19.3798022 },
    { lat: 49.2917053, lng: 19.3798527 },
    { lat: 49.2917926, lng: 19.3799316 },
    { lat: 49.2917983, lng: 19.3799371 },
    { lat: 49.2918703, lng: 19.3800278 },
    { lat: 49.291929, lng: 19.3801019 },
    { lat: 49.2920175, lng: 19.3802129 },
    { lat: 49.2920522, lng: 19.3802578 },
    { lat: 49.2920812, lng: 19.3802969 },
    { lat: 49.2920951, lng: 19.3803066 },
    { lat: 49.2921648, lng: 19.3803535 },
    { lat: 49.2922102, lng: 19.380385 },
    { lat: 49.292308, lng: 19.3804512 },
    { lat: 49.2923733, lng: 19.3804957 },
    { lat: 49.2924407, lng: 19.3805418 },
    { lat: 49.2924656, lng: 19.380564 },
    { lat: 49.2925055, lng: 19.3805994 },
    { lat: 49.2925419, lng: 19.3806315 },
    { lat: 49.2925771, lng: 19.3806623 },
    { lat: 49.2926264, lng: 19.3807061 },
    { lat: 49.2927158, lng: 19.3807858 },
    { lat: 49.2928264, lng: 19.3808839 },
    { lat: 49.292838, lng: 19.3808935 },
    { lat: 49.2929286, lng: 19.3809746 },
    { lat: 49.2929392, lng: 19.3809842 },
    { lat: 49.2930003, lng: 19.3810642 },
    { lat: 49.2931554, lng: 19.3812704 },
    { lat: 49.2933072, lng: 19.3814117 },
    { lat: 49.2933872, lng: 19.3815066 },
    { lat: 49.2934625, lng: 19.3815969 },
    { lat: 49.293645, lng: 19.3818138 },
    { lat: 49.293713, lng: 19.3818952 },
    { lat: 49.2937325, lng: 19.3819274 },
    { lat: 49.2938328, lng: 19.3820992 },
    { lat: 49.2938955, lng: 19.3822071 },
    { lat: 49.2939617, lng: 19.3823205 },
    { lat: 49.2939803, lng: 19.3823604 },
    { lat: 49.2940417, lng: 19.3824916 },
    { lat: 49.2940741, lng: 19.3825592 },
    { lat: 49.2940777, lng: 19.3825656 },
    { lat: 49.2941141, lng: 19.3826425 },
    { lat: 49.2941329, lng: 19.3826863 },
    { lat: 49.2941822, lng: 19.3828008 },
    { lat: 49.2942569, lng: 19.382975 },
    { lat: 49.2943518, lng: 19.3831972 },
    { lat: 49.294387, lng: 19.383208 },
    { lat: 49.294682, lng: 19.382573 },
    { lat: 49.295172, lng: 19.381231 },
    { lat: 49.295773, lng: 19.379657 },
    { lat: 49.297183, lng: 19.380303 },
    { lat: 49.298217, lng: 19.380638 },
    { lat: 49.298297, lng: 19.380462 },
    { lat: 49.298437, lng: 19.380137 },
    { lat: 49.298556, lng: 19.379597 },
    { lat: 49.298572, lng: 19.379523 },
    { lat: 49.29859, lng: 19.379118 },
    { lat: 49.298594, lng: 19.379028 },
    { lat: 49.298634, lng: 19.378621 },
    { lat: 49.298666, lng: 19.378297 },
    { lat: 49.298668, lng: 19.378277 },
    { lat: 49.298863, lng: 19.377842 },
    { lat: 49.299046, lng: 19.377426 },
    { lat: 49.299134, lng: 19.377184 },
    { lat: 49.299044, lng: 19.376868 },
    { lat: 49.298899, lng: 19.376463 },
    { lat: 49.298861, lng: 19.375944 },
    { lat: 49.298906, lng: 19.375835 },
    { lat: 49.298999, lng: 19.375608 },
    { lat: 49.299304, lng: 19.374905 },
    { lat: 49.299358, lng: 19.374787 },
    { lat: 49.299685, lng: 19.374081 },
    { lat: 49.299978, lng: 19.373366 },
    { lat: 49.300384, lng: 19.372417 },
    { lat: 49.300527, lng: 19.372161 },
    { lat: 49.300847, lng: 19.371589 },
    { lat: 49.301277, lng: 19.370855 },
    { lat: 49.301749, lng: 19.369865 },
    { lat: 49.302175, lng: 19.368857 },
    { lat: 49.302299, lng: 19.368567 },
    { lat: 49.3026, lng: 19.367862 },
    { lat: 49.302974, lng: 19.367206 },
    { lat: 49.303045, lng: 19.367081 },
    { lat: 49.303453, lng: 19.36628 },
    { lat: 49.303516, lng: 19.366155 },
    { lat: 49.303682, lng: 19.365979 },
    { lat: 49.304, lng: 19.365642 },
    { lat: 49.303613, lng: 19.364968 },
    { lat: 49.303224, lng: 19.364209 },
    { lat: 49.303075, lng: 19.363637 },
  ],
  Veličná: [
    { lat: 49.2793777, lng: 19.2457541 },
    { lat: 49.2802662, lng: 19.2440452 },
    { lat: 49.2807144, lng: 19.2429519 },
    { lat: 49.2810626, lng: 19.2413752 },
    { lat: 49.2813895, lng: 19.2395604 },
    { lat: 49.2818364, lng: 19.2374311 },
    { lat: 49.2826035, lng: 19.2352135 },
    { lat: 49.2831628, lng: 19.2339484 },
    { lat: 49.2840219, lng: 19.2296764 },
    { lat: 49.2842555, lng: 19.2285094 },
    { lat: 49.2847798, lng: 19.2262113 },
    { lat: 49.2851017, lng: 19.2242333 },
    { lat: 49.2852304, lng: 19.2230134 },
    { lat: 49.284995, lng: 19.2221481 },
    { lat: 49.2854435, lng: 19.2197308 },
    { lat: 49.2857763, lng: 19.2190324 },
    { lat: 49.2850245, lng: 19.2189631 },
    { lat: 49.2840749, lng: 19.2191599 },
    { lat: 49.2834311, lng: 19.2194358 },
    { lat: 49.2831828, lng: 19.2192168 },
    { lat: 49.2814225, lng: 19.2194303 },
    { lat: 49.2806279, lng: 19.219527 },
    { lat: 49.2799336, lng: 19.2195223 },
    { lat: 49.2797331, lng: 19.2191972 },
    { lat: 49.2794872, lng: 19.2190295 },
    { lat: 49.2787513, lng: 19.218514 },
    { lat: 49.2778241, lng: 19.2176778 },
    { lat: 49.2773892, lng: 19.2174692 },
    { lat: 49.2765592, lng: 19.217365 },
    { lat: 49.2761711, lng: 19.2175937 },
    { lat: 49.2758413, lng: 19.2173665 },
    { lat: 49.2758098, lng: 19.2173441 },
    { lat: 49.2751488, lng: 19.2168895 },
    { lat: 49.2747036, lng: 19.2165826 },
    { lat: 49.2745877, lng: 19.2164674 },
    { lat: 49.2739802, lng: 19.2149677 },
    { lat: 49.2737766, lng: 19.2145798 },
    { lat: 49.2736047, lng: 19.2141678 },
    { lat: 49.2736009, lng: 19.2141578 },
    { lat: 49.2735817, lng: 19.2141126 },
    { lat: 49.273416, lng: 19.2137111 },
    { lat: 49.2730066, lng: 19.2131684 },
    { lat: 49.2727017, lng: 19.212217 },
    { lat: 49.2725911, lng: 19.2119602 },
    { lat: 49.2723362, lng: 19.2103144 },
    { lat: 49.2723323, lng: 19.2098612 },
    { lat: 49.2720451, lng: 19.2093936 },
    { lat: 49.2717935, lng: 19.2090833 },
    { lat: 49.2717299, lng: 19.2089342 },
    { lat: 49.2717608, lng: 19.2082432 },
    { lat: 49.2719391, lng: 19.2075655 },
    { lat: 49.2718599, lng: 19.2074224 },
    { lat: 49.271428, lng: 19.2066929 },
    { lat: 49.2713006, lng: 19.2061066 },
    { lat: 49.2711655, lng: 19.2057775 },
    { lat: 49.2711054, lng: 19.2051624 },
    { lat: 49.2711773, lng: 19.2047147 },
    { lat: 49.2708362, lng: 19.2041157 },
    { lat: 49.2708341, lng: 19.2035821 },
    { lat: 49.2708134, lng: 19.2034556 },
    { lat: 49.2707513, lng: 19.2033583 },
    { lat: 49.2707549, lng: 19.2027558 },
    { lat: 49.2707542, lng: 19.2027169 },
    { lat: 49.2707407, lng: 19.2024629 },
    { lat: 49.2707385, lng: 19.202417 },
    { lat: 49.270722, lng: 19.2020976 },
    { lat: 49.2707211, lng: 19.2020804 },
    { lat: 49.2707188, lng: 19.2020319 },
    { lat: 49.2707186, lng: 19.2020038 },
    { lat: 49.2706966, lng: 19.2019929 },
    { lat: 49.270509, lng: 19.2019079 },
    { lat: 49.2704686, lng: 19.2018876 },
    { lat: 49.2703532, lng: 19.201837 },
    { lat: 49.2696129, lng: 19.2014165 },
    { lat: 49.2687449, lng: 19.2017196 },
    { lat: 49.267748, lng: 19.2016615 },
    { lat: 49.2673817, lng: 19.2010044 },
    { lat: 49.2657364, lng: 19.2000525 },
    { lat: 49.2655602, lng: 19.1995285 },
    { lat: 49.2647483, lng: 19.198542 },
    { lat: 49.2645222, lng: 19.1983628 },
    { lat: 49.2644804, lng: 19.1983333 },
    { lat: 49.2637768, lng: 19.1977709 },
    { lat: 49.2633601, lng: 19.1973093 },
    { lat: 49.2624983, lng: 19.196732 },
    { lat: 49.2620133, lng: 19.1959556 },
    { lat: 49.261637, lng: 19.1949064 },
    { lat: 49.2616009, lng: 19.1948137 },
    { lat: 49.2613461, lng: 19.1939941 },
    { lat: 49.2603839, lng: 19.1934653 },
    { lat: 49.2597893, lng: 19.1931256 },
    { lat: 49.2588062, lng: 19.1918126 },
    { lat: 49.2585492, lng: 19.1901348 },
    { lat: 49.2582084, lng: 19.1900069 },
    { lat: 49.257754, lng: 19.1898618 },
    { lat: 49.2577182, lng: 19.1898446 },
    { lat: 49.2560783, lng: 19.189567 },
    { lat: 49.2556798, lng: 19.1899046 },
    { lat: 49.2553519, lng: 19.1892527 },
    { lat: 49.2553229, lng: 19.1891167 },
    { lat: 49.2553227, lng: 19.1889263 },
    { lat: 49.2552882, lng: 19.1886439 },
    { lat: 49.2551174, lng: 19.188178 },
    { lat: 49.2550371, lng: 19.1881144 },
    { lat: 49.2550966, lng: 19.1877228 },
    { lat: 49.2551291, lng: 19.1873006 },
    { lat: 49.2551887, lng: 19.1869468 },
    { lat: 49.2551261, lng: 19.1864469 },
    { lat: 49.2552931, lng: 19.1862181 },
    { lat: 49.2553501, lng: 19.1859706 },
    { lat: 49.2553317, lng: 19.1856836 },
    { lat: 49.2553017, lng: 19.1855994 },
    { lat: 49.2554124, lng: 19.1850474 },
    { lat: 49.2554274, lng: 19.1846355 },
    { lat: 49.2554604, lng: 19.1843164 },
    { lat: 49.2555511, lng: 19.1836026 },
    { lat: 49.2555722, lng: 19.1831326 },
    { lat: 49.2557283, lng: 19.1824431 },
    { lat: 49.2559193, lng: 19.181221 },
    { lat: 49.2560359, lng: 19.1801618 },
    { lat: 49.2559579, lng: 19.1798524 },
    { lat: 49.2558896, lng: 19.1796072 },
    { lat: 49.2556272, lng: 19.179027 },
    { lat: 49.2553756, lng: 19.1786513 },
    { lat: 49.2549937, lng: 19.1783961 },
    { lat: 49.2548039, lng: 19.1782974 },
    { lat: 49.2546925, lng: 19.1783472 },
    { lat: 49.2545945, lng: 19.1783027 },
    { lat: 49.2544727, lng: 19.17793 },
    { lat: 49.254352, lng: 19.1774843 },
    { lat: 49.254014, lng: 19.1766714 },
    { lat: 49.2539285, lng: 19.1764667 },
    { lat: 49.2538992, lng: 19.1763968 },
    { lat: 49.2538391, lng: 19.1759017 },
    { lat: 49.2533615, lng: 19.1753943 },
    { lat: 49.2531805, lng: 19.1752416 },
    { lat: 49.2531487, lng: 19.1751911 },
    { lat: 49.253051, lng: 19.175104 },
    { lat: 49.2529703, lng: 19.1744878 },
    { lat: 49.2529649, lng: 19.1740731 },
    { lat: 49.253135, lng: 19.1737238 },
    { lat: 49.2530801, lng: 19.1732631 },
    { lat: 49.2528776, lng: 19.1730132 },
    { lat: 49.2527904, lng: 19.1728654 },
    { lat: 49.25275, lng: 19.17266 },
    { lat: 49.2526411, lng: 19.1722996 },
    { lat: 49.2522289, lng: 19.1714212 },
    { lat: 49.2521095, lng: 19.1712157 },
    { lat: 49.2517437, lng: 19.1707584 },
    { lat: 49.2515039, lng: 19.1702992 },
    { lat: 49.2514254, lng: 19.1700299 },
    { lat: 49.2514231, lng: 19.1698888 },
    { lat: 49.2514174, lng: 19.1698894 },
    { lat: 49.2514017, lng: 19.1698912 },
    { lat: 49.2509232, lng: 19.169945 },
    { lat: 49.2507708, lng: 19.1699626 },
    { lat: 49.2501261, lng: 19.1700334 },
    { lat: 49.2492858, lng: 19.1704431 },
    { lat: 49.2488773, lng: 19.1706405 },
    { lat: 49.2484127, lng: 19.1711942 },
    { lat: 49.2481377, lng: 19.1716352 },
    { lat: 49.2476554, lng: 19.1721721 },
    { lat: 49.2472874, lng: 19.1725312 },
    { lat: 49.2471593, lng: 19.17305 },
    { lat: 49.2467582, lng: 19.1735904 },
    { lat: 49.2463143, lng: 19.1738451 },
    { lat: 49.2457116, lng: 19.1741099 },
    { lat: 49.2451231, lng: 19.174385 },
    { lat: 49.2447764, lng: 19.1745804 },
    { lat: 49.2447588, lng: 19.1745921 },
    { lat: 49.2444545, lng: 19.1747997 },
    { lat: 49.2442555, lng: 19.1749271 },
    { lat: 49.2442646, lng: 19.1752086 },
    { lat: 49.2442799, lng: 19.1756599 },
    { lat: 49.2442185, lng: 19.1759533 },
    { lat: 49.244148, lng: 19.1762914 },
    { lat: 49.2441907, lng: 19.1764961 },
    { lat: 49.2442434, lng: 19.1767448 },
    { lat: 49.2445564, lng: 19.1770684 },
    { lat: 49.2446938, lng: 19.1774566 },
    { lat: 49.2447738, lng: 19.1776899 },
    { lat: 49.2447842, lng: 19.1777876 },
    { lat: 49.2448696, lng: 19.1785301 },
    { lat: 49.2448795, lng: 19.1786181 },
    { lat: 49.2451063, lng: 19.179415 },
    { lat: 49.2451079, lng: 19.1796998 },
    { lat: 49.2451111, lng: 19.1800259 },
    { lat: 49.244902, lng: 19.180477 },
    { lat: 49.2448945, lng: 19.1805074 },
    { lat: 49.2447443, lng: 19.1812001 },
    { lat: 49.2447453, lng: 19.181222 },
    { lat: 49.2448061, lng: 19.1820571 },
    { lat: 49.2449053, lng: 19.1827555 },
    { lat: 49.2449264, lng: 19.18366 },
    { lat: 49.2449308, lng: 19.1838435 },
    { lat: 49.2449357, lng: 19.1838761 },
    { lat: 49.2450215, lng: 19.1843906 },
    { lat: 49.2449688, lng: 19.1849553 },
    { lat: 49.2449116, lng: 19.1855657 },
    { lat: 49.2448717, lng: 19.1861856 },
    { lat: 49.2447951, lng: 19.1866957 },
    { lat: 49.2446806, lng: 19.1871036 },
    { lat: 49.2457543, lng: 19.1885885 },
    { lat: 49.2459664, lng: 19.1890336 },
    { lat: 49.2463835, lng: 19.1900637 },
    { lat: 49.2478511, lng: 19.1924917 },
    { lat: 49.248364, lng: 19.1934736 },
    { lat: 49.2488829, lng: 19.1945786 },
    { lat: 49.2483214, lng: 19.1953685 },
    { lat: 49.2473178, lng: 19.1963348 },
    { lat: 49.2470088, lng: 19.1970341 },
    { lat: 49.2467924, lng: 19.1975267 },
    { lat: 49.2466209, lng: 19.198011 },
    { lat: 49.2464186, lng: 19.1985594 },
    { lat: 49.2463252, lng: 19.1988672 },
    { lat: 49.2462701, lng: 19.1993832 },
    { lat: 49.2458712, lng: 19.2004804 },
    { lat: 49.2457239, lng: 19.2006286 },
    { lat: 49.245143, lng: 19.2020944 },
    { lat: 49.2451044, lng: 19.2034183 },
    { lat: 49.2450169, lng: 19.2038733 },
    { lat: 49.2449113, lng: 19.2049981 },
    { lat: 49.2449019, lng: 19.2051063 },
    { lat: 49.2448408, lng: 19.2063198 },
    { lat: 49.2450352, lng: 19.2067756 },
    { lat: 49.2450516, lng: 19.2068123 },
    { lat: 49.2450605, lng: 19.2068344 },
    { lat: 49.2451089, lng: 19.2069462 },
    { lat: 49.2451152, lng: 19.2069606 },
    { lat: 49.2452194, lng: 19.2072058 },
    { lat: 49.2452332, lng: 19.2072378 },
    { lat: 49.2452611, lng: 19.2073024 },
    { lat: 49.2452675, lng: 19.207318 },
    { lat: 49.2453972, lng: 19.2076208 },
    { lat: 49.2452229, lng: 19.2086108 },
    { lat: 49.245158, lng: 19.2088587 },
    { lat: 49.2448987, lng: 19.2093936 },
    { lat: 49.2447348, lng: 19.2099214 },
    { lat: 49.2446221, lng: 19.2103677 },
    { lat: 49.2444979, lng: 19.211084 },
    { lat: 49.2444296, lng: 19.2114217 },
    { lat: 49.2441001, lng: 19.2124706 },
    { lat: 49.2437169, lng: 19.2130323 },
    { lat: 49.2435738, lng: 19.2131696 },
    { lat: 49.2434359, lng: 19.2131173 },
    { lat: 49.2431454, lng: 19.2131697 },
    { lat: 49.2427405, lng: 19.213257 },
    { lat: 49.2424515, lng: 19.2133663 },
    { lat: 49.2420582, lng: 19.2132471 },
    { lat: 49.242047, lng: 19.2131564 },
    { lat: 49.2412946, lng: 19.2128823 },
    { lat: 49.2410811, lng: 19.2128724 },
    { lat: 49.2410629, lng: 19.2128719 },
    { lat: 49.2409185, lng: 19.212865 },
    { lat: 49.2406886, lng: 19.2127502 },
    { lat: 49.2402979, lng: 19.2123872 },
    { lat: 49.2398682, lng: 19.2121447 },
    { lat: 49.2389023, lng: 19.2124249 },
    { lat: 49.2386802, lng: 19.2125656 },
    { lat: 49.2386375, lng: 19.2127778 },
    { lat: 49.2386064, lng: 19.2139795 },
    { lat: 49.2383207, lng: 19.2143196 },
    { lat: 49.2380064, lng: 19.2147917 },
    { lat: 49.2375897, lng: 19.2149369 },
    { lat: 49.2371828, lng: 19.2147529 },
    { lat: 49.2361396, lng: 19.2149973 },
    { lat: 49.2358271, lng: 19.215854 },
    { lat: 49.2359806, lng: 19.2168214 },
    { lat: 49.235724, lng: 19.217481 },
    { lat: 49.2356046, lng: 19.217789 },
    { lat: 49.2352273, lng: 19.2187579 },
    { lat: 49.2344296, lng: 19.2200022 },
    { lat: 49.2341691, lng: 19.220096 },
    { lat: 49.2339352, lng: 19.2203397 },
    { lat: 49.2338207, lng: 19.2204881 },
    { lat: 49.2336096, lng: 19.2207624 },
    { lat: 49.2334686, lng: 19.2210177 },
    { lat: 49.2331665, lng: 19.2214411 },
    { lat: 49.2330487, lng: 19.221966 },
    { lat: 49.2329512, lng: 19.2224494 },
    { lat: 49.2328822, lng: 19.2236624 },
    { lat: 49.2326857, lng: 19.224334 },
    { lat: 49.2325533, lng: 19.2244162 },
    { lat: 49.232188, lng: 19.2245985 },
    { lat: 49.2320074, lng: 19.2246884 },
    { lat: 49.2316627, lng: 19.2245283 },
    { lat: 49.2312406, lng: 19.2247296 },
    { lat: 49.2310633, lng: 19.224814 },
    { lat: 49.2308992, lng: 19.2248926 },
    { lat: 49.2307768, lng: 19.224951 },
    { lat: 49.230755, lng: 19.224965 },
    { lat: 49.2307101, lng: 19.2249942 },
    { lat: 49.2306707, lng: 19.2250192 },
    { lat: 49.2305899, lng: 19.2250715 },
    { lat: 49.2305181, lng: 19.2251019 },
    { lat: 49.2304818, lng: 19.2251186 },
    { lat: 49.2304477, lng: 19.2251347 },
    { lat: 49.2304123, lng: 19.2251505 },
    { lat: 49.2303439, lng: 19.225182 },
    { lat: 49.2302933, lng: 19.2252057 },
    { lat: 49.2302094, lng: 19.225244 },
    { lat: 49.2301036, lng: 19.2252926 },
    { lat: 49.2300516, lng: 19.2253161 },
    { lat: 49.2299922, lng: 19.225344 },
    { lat: 49.2287368, lng: 19.2252088 },
    { lat: 49.228308, lng: 19.2252893 },
    { lat: 49.2282766, lng: 19.2252942 },
    { lat: 49.2282118, lng: 19.2253055 },
    { lat: 49.2281471, lng: 19.2253163 },
    { lat: 49.2280955, lng: 19.225325 },
    { lat: 49.2280431, lng: 19.2253345 },
    { lat: 49.2279637, lng: 19.2253473 },
    { lat: 49.2279357, lng: 19.2253501 },
    { lat: 49.2278504, lng: 19.2253597 },
    { lat: 49.2277943, lng: 19.2253659 },
    { lat: 49.2277102, lng: 19.225375 },
    { lat: 49.2276542, lng: 19.2253813 },
    { lat: 49.2276239, lng: 19.2253843 },
    { lat: 49.2275945, lng: 19.2253818 },
    { lat: 49.2275673, lng: 19.2253798 },
    { lat: 49.2275403, lng: 19.2253782 },
    { lat: 49.2275142, lng: 19.2253768 },
    { lat: 49.2274881, lng: 19.2253739 },
    { lat: 49.2274611, lng: 19.2253723 },
    { lat: 49.2274339, lng: 19.2253706 },
    { lat: 49.2274078, lng: 19.2253685 },
    { lat: 49.2273796, lng: 19.225367 },
    { lat: 49.2273265, lng: 19.2253628 },
    { lat: 49.2272225, lng: 19.2253554 },
    { lat: 49.2271703, lng: 19.2253511 },
    { lat: 49.2271445, lng: 19.2253493 },
    { lat: 49.2270935, lng: 19.2253456 },
    { lat: 49.2270675, lng: 19.2253439 },
    { lat: 49.2269679, lng: 19.225337 },
    { lat: 49.2268402, lng: 19.2253275 },
    { lat: 49.2267973, lng: 19.225324 },
    { lat: 49.2267631, lng: 19.225321 },
    { lat: 49.2266687, lng: 19.2253034 },
    { lat: 49.2266153, lng: 19.2252932 },
    { lat: 49.2265095, lng: 19.225273 },
    { lat: 49.2264971, lng: 19.2252707 },
    { lat: 49.2264616, lng: 19.2252827 },
    { lat: 49.226396, lng: 19.2253037 },
    { lat: 49.2263715, lng: 19.2253115 },
    { lat: 49.226293, lng: 19.2253376 },
    { lat: 49.2262651, lng: 19.2253465 },
    { lat: 49.2262518, lng: 19.2253509 },
    { lat: 49.2262362, lng: 19.2253559 },
    { lat: 49.2262057, lng: 19.2253741 },
    { lat: 49.2261619, lng: 19.2254006 },
    { lat: 49.2261201, lng: 19.2254258 },
    { lat: 49.2260336, lng: 19.2254781 },
    { lat: 49.2259023, lng: 19.2255573 },
    { lat: 49.2258584, lng: 19.2255832 },
    { lat: 49.2258146, lng: 19.2256093 },
    { lat: 49.2257937, lng: 19.2256225 },
    { lat: 49.2257707, lng: 19.2256359 },
    { lat: 49.2257489, lng: 19.2256495 },
    { lat: 49.2257269, lng: 19.2256621 },
    { lat: 49.2256842, lng: 19.2256881 },
    { lat: 49.2256415, lng: 19.2257138 },
    { lat: 49.2255518, lng: 19.2257679 },
    { lat: 49.2255199, lng: 19.2257873 },
    { lat: 49.2254092, lng: 19.2258534 },
    { lat: 49.225383, lng: 19.2258693 },
    { lat: 49.2253555, lng: 19.2258858 },
    { lat: 49.2253282, lng: 19.2259022 },
    { lat: 49.2253025, lng: 19.2259278 },
    { lat: 49.2252754, lng: 19.2259546 },
    { lat: 49.2252486, lng: 19.225981 },
    { lat: 49.2251947, lng: 19.226034 },
    { lat: 49.2251194, lng: 19.2261091 },
    { lat: 49.2250667, lng: 19.2261612 },
    { lat: 49.2249839, lng: 19.2262436 },
    { lat: 49.2249012, lng: 19.2263261 },
    { lat: 49.2248419, lng: 19.2263843 },
    { lat: 49.2247946, lng: 19.2264311 },
    { lat: 49.2247405, lng: 19.2264995 },
    { lat: 49.2239957, lng: 19.2274465 },
    { lat: 49.2230724, lng: 19.2285905 },
    { lat: 49.2226257, lng: 19.2288633 },
    { lat: 49.2219985, lng: 19.2292487 },
    { lat: 49.2216354, lng: 19.2294573 },
    { lat: 49.2215897, lng: 19.2294891 },
    { lat: 49.2214751, lng: 19.2295672 },
    { lat: 49.2213607, lng: 19.2297214 },
    { lat: 49.2213393, lng: 19.2297447 },
    { lat: 49.2213, lng: 19.2297949 },
    { lat: 49.2209713, lng: 19.2302257 },
    { lat: 49.2201784, lng: 19.2302131 },
    { lat: 49.2201637, lng: 19.2302126 },
    { lat: 49.2199607, lng: 19.2302101 },
    { lat: 49.2198029, lng: 19.2302083 },
    { lat: 49.2194827, lng: 19.2302041 },
    { lat: 49.2193486, lng: 19.2302314 },
    { lat: 49.2191779, lng: 19.2302671 },
    { lat: 49.219101, lng: 19.2302825 },
    { lat: 49.2190262, lng: 19.2302981 },
    { lat: 49.2188776, lng: 19.2303284 },
    { lat: 49.2188075, lng: 19.2303431 },
    { lat: 49.218793, lng: 19.2303462 },
    { lat: 49.21871, lng: 19.2304726 },
    { lat: 49.218605, lng: 19.2306351 },
    { lat: 49.2185674, lng: 19.2306934 },
    { lat: 49.2185484, lng: 19.2307222 },
    { lat: 49.2184959, lng: 19.2308034 },
    { lat: 49.2184433, lng: 19.2308847 },
    { lat: 49.2183971, lng: 19.2309551 },
    { lat: 49.2183437, lng: 19.2310379 },
    { lat: 49.2183206, lng: 19.2310725 },
    { lat: 49.2183059, lng: 19.2310958 },
    { lat: 49.2182912, lng: 19.2311187 },
    { lat: 49.2182767, lng: 19.231142 },
    { lat: 49.2182535, lng: 19.2311763 },
    { lat: 49.2182252, lng: 19.2312198 },
    { lat: 49.2182199, lng: 19.2312287 },
    { lat: 49.2182082, lng: 19.2312463 },
    { lat: 49.218201, lng: 19.2312572 },
    { lat: 49.218181, lng: 19.2312886 },
    { lat: 49.2181559, lng: 19.2313275 },
    { lat: 49.2181297, lng: 19.2313672 },
    { lat: 49.2180992, lng: 19.2314142 },
    { lat: 49.2179841, lng: 19.2313189 },
    { lat: 49.2179513, lng: 19.2312912 },
    { lat: 49.2178128, lng: 19.2311755 },
    { lat: 49.2171573, lng: 19.2313739 },
    { lat: 49.217134, lng: 19.2313812 },
    { lat: 49.2170443, lng: 19.2313913 },
    { lat: 49.2170018, lng: 19.2313964 },
    { lat: 49.2169569, lng: 19.2314014 },
    { lat: 49.2169165, lng: 19.2314059 },
    { lat: 49.2168716, lng: 19.2314113 },
    { lat: 49.2167831, lng: 19.2314212 },
    { lat: 49.2166229, lng: 19.2314398 },
    { lat: 49.2165376, lng: 19.2314497 },
    { lat: 49.216504, lng: 19.2314535 },
    { lat: 49.2164971, lng: 19.2314507 },
    { lat: 49.216418, lng: 19.2314217 },
    { lat: 49.2163709, lng: 19.2314046 },
    { lat: 49.2157835, lng: 19.2311887 },
    { lat: 49.2154738, lng: 19.2310745 },
    { lat: 49.2152017, lng: 19.2309694 },
    { lat: 49.2148836, lng: 19.2308467 },
    { lat: 49.2148398, lng: 19.23083 },
    { lat: 49.214655, lng: 19.2307587 },
    { lat: 49.2144163, lng: 19.2308092 },
    { lat: 49.2142144, lng: 19.2302701 },
    { lat: 49.2139787, lng: 19.2302634 },
    { lat: 49.213696, lng: 19.2302204 },
    { lat: 49.2130731, lng: 19.2300658 },
    { lat: 49.2130024, lng: 19.2300481 },
    { lat: 49.2129426, lng: 19.2300469 },
    { lat: 49.2125505, lng: 19.2298839 },
    { lat: 49.2122756, lng: 19.2304006 },
    { lat: 49.2120732, lng: 19.2304099 },
    { lat: 49.2119292, lng: 19.2304166 },
    { lat: 49.2119056, lng: 19.2304174 },
    { lat: 49.2114991, lng: 19.2303321 },
    { lat: 49.2110229, lng: 19.2301539 },
    { lat: 49.2110022, lng: 19.2301461 },
    { lat: 49.2109642, lng: 19.2301316 },
    { lat: 49.2106037, lng: 19.2301597 },
    { lat: 49.2105347, lng: 19.2301783 },
    { lat: 49.2104559, lng: 19.2301993 },
    { lat: 49.2104515, lng: 19.2302009 },
    { lat: 49.210448, lng: 19.2302017 },
    { lat: 49.2104374, lng: 19.2301928 },
    { lat: 49.2103717, lng: 19.2301385 },
    { lat: 49.2103341, lng: 19.230107 },
    { lat: 49.2103212, lng: 19.2300954 },
    { lat: 49.2103188, lng: 19.2300943 },
    { lat: 49.2101869, lng: 19.2303271 },
    { lat: 49.2100623, lng: 19.2305451 },
    { lat: 49.2100323, lng: 19.2306732 },
    { lat: 49.2100276, lng: 19.2306932 },
    { lat: 49.2100185, lng: 19.2307158 },
    { lat: 49.2099994, lng: 19.2307662 },
    { lat: 49.2098877, lng: 19.231066 },
    { lat: 49.2098746, lng: 19.2310971 },
    { lat: 49.2098187, lng: 19.2312266 },
    { lat: 49.2097934, lng: 19.231285 },
    { lat: 49.2097875, lng: 19.2312802 },
    { lat: 49.2095619, lng: 19.231605 },
    { lat: 49.2095144, lng: 19.231673 },
    { lat: 49.2094483, lng: 19.2317713 },
    { lat: 49.2094579, lng: 19.2318098 },
    { lat: 49.2094526, lng: 19.2318158 },
    { lat: 49.2091275, lng: 19.2324398 },
    { lat: 49.2090486, lng: 19.2326058 },
    { lat: 49.2088259, lng: 19.2328722 },
    { lat: 49.2086662, lng: 19.2330432 },
    { lat: 49.2085034, lng: 19.233149 },
    { lat: 49.2084061, lng: 19.2332818 },
    { lat: 49.2082313, lng: 19.2333507 },
    { lat: 49.208005, lng: 19.2334034 },
    { lat: 49.2078931, lng: 19.2334436 },
    { lat: 49.2077855, lng: 19.2335256 },
    { lat: 49.2076962, lng: 19.2336124 },
    { lat: 49.2075875, lng: 19.2336757 },
    { lat: 49.2075227, lng: 19.2336884 },
    { lat: 49.207474, lng: 19.2337273 },
    { lat: 49.2074196, lng: 19.2337684 },
    { lat: 49.2073144, lng: 19.2338555 },
    { lat: 49.2069508, lng: 19.2340745 },
    { lat: 49.2064539, lng: 19.2342919 },
    { lat: 49.2064393, lng: 19.2342665 },
    { lat: 49.2064106, lng: 19.2343036 },
    { lat: 49.2064002, lng: 19.2343181 },
    { lat: 49.2063483, lng: 19.2343881 },
    { lat: 49.2063429, lng: 19.2343952 },
    { lat: 49.2063322, lng: 19.2344086 },
    { lat: 49.2063111, lng: 19.2344362 },
    { lat: 49.2061806, lng: 19.2346054 },
    { lat: 49.2061025, lng: 19.2347151 },
    { lat: 49.2059746, lng: 19.2348948 },
    { lat: 49.2059165, lng: 19.2349529 },
    { lat: 49.2058879, lng: 19.2349665 },
    { lat: 49.205856, lng: 19.2349816 },
    { lat: 49.2058361, lng: 19.2349907 },
    { lat: 49.2058174, lng: 19.2349996 },
    { lat: 49.205695, lng: 19.2350573 },
    { lat: 49.2056764, lng: 19.2350428 },
    { lat: 49.205679, lng: 19.2350047 },
    { lat: 49.2056654, lng: 19.2349579 },
    { lat: 49.2055828, lng: 19.2347609 },
    { lat: 49.2053894, lng: 19.2346049 },
    { lat: 49.2053188, lng: 19.2344026 },
    { lat: 49.2051331, lng: 19.2340582 },
    { lat: 49.2049946, lng: 19.2337991 },
    { lat: 49.2045084, lng: 19.2330385 },
    { lat: 49.2041474, lng: 19.2326561 },
    { lat: 49.203469, lng: 19.2320734 },
    { lat: 49.2032832, lng: 19.2319576 },
    { lat: 49.2029485, lng: 19.2317943 },
    { lat: 49.2029688, lng: 19.2316774 },
    { lat: 49.2027894, lng: 19.231629 },
    { lat: 49.2023119, lng: 19.231517 },
    { lat: 49.2019517, lng: 19.2315511 },
    { lat: 49.2013442, lng: 19.2314313 },
    { lat: 49.2012122, lng: 19.2314082 },
    { lat: 49.2010987, lng: 19.2313654 },
    { lat: 49.2010838, lng: 19.2313591 },
    { lat: 49.2010172, lng: 19.2313341 },
    { lat: 49.2006305, lng: 19.2311907 },
    { lat: 49.2005406, lng: 19.2311528 },
    { lat: 49.2000658, lng: 19.231003 },
    { lat: 49.199756, lng: 19.2308665 },
    { lat: 49.1994307, lng: 19.2307129 },
    { lat: 49.199022, lng: 19.2305082 },
    { lat: 49.1986879, lng: 19.2303333 },
    { lat: 49.198273, lng: 19.2300496 },
    { lat: 49.1978737, lng: 19.2297367 },
    { lat: 49.1973989, lng: 19.2293066 },
    { lat: 49.1970869, lng: 19.2289133 },
    { lat: 49.1967901, lng: 19.2285069 },
    { lat: 49.1967561, lng: 19.2284577 },
    { lat: 49.1965218, lng: 19.2281095 },
    { lat: 49.1962946, lng: 19.2277414 },
    { lat: 49.1960448, lng: 19.22733 },
    { lat: 49.1957649, lng: 19.2269466 },
    { lat: 49.195666, lng: 19.2266218 },
    { lat: 49.1956524, lng: 19.2265263 },
    { lat: 49.1956001, lng: 19.2261664 },
    { lat: 49.195599, lng: 19.2259568 },
    { lat: 49.1955997, lng: 19.225827 },
    { lat: 49.1955991, lng: 19.2257957 },
    { lat: 49.1955971, lng: 19.2254496 },
    { lat: 49.1955978, lng: 19.2253904 },
    { lat: 49.1956347, lng: 19.2248461 },
    { lat: 49.1956788, lng: 19.2245903 },
    { lat: 49.1959309, lng: 19.2238597 },
    { lat: 49.1961795, lng: 19.2233767 },
    { lat: 49.196677, lng: 19.2227815 },
    { lat: 49.1967208, lng: 19.2227298 },
    { lat: 49.1967262, lng: 19.222723 },
    { lat: 49.1968562, lng: 19.2225676 },
    { lat: 49.1970714, lng: 19.2223099 },
    { lat: 49.1975928, lng: 19.2220183 },
    { lat: 49.1982723, lng: 19.2218518 },
    { lat: 49.1983964, lng: 19.2218775 },
    { lat: 49.1986438, lng: 19.2218217 },
    { lat: 49.1990431, lng: 19.2217308 },
    { lat: 49.1990886, lng: 19.2217207 },
    { lat: 49.199161, lng: 19.2217043 },
    { lat: 49.1992549, lng: 19.2216862 },
    { lat: 49.1993466, lng: 19.221668 },
    { lat: 49.1994603, lng: 19.2216459 },
    { lat: 49.1995234, lng: 19.2216215 },
    { lat: 49.1996574, lng: 19.2215701 },
    { lat: 49.1999129, lng: 19.2214715 },
    { lat: 49.1999572, lng: 19.2214543 },
    { lat: 49.2006393, lng: 19.2211913 },
    { lat: 49.200712, lng: 19.2211632 },
    { lat: 49.2008052, lng: 19.2209023 },
    { lat: 49.2008514, lng: 19.2200804 },
    { lat: 49.2009324, lng: 19.2202173 },
    { lat: 49.2009283, lng: 19.2193373 },
    { lat: 49.2009764, lng: 19.2179036 },
    { lat: 49.201033, lng: 19.2172975 },
    { lat: 49.2010515, lng: 19.217001 },
    { lat: 49.2011065, lng: 19.2161803 },
    { lat: 49.2011708, lng: 19.215196 },
    { lat: 49.201215, lng: 19.2145249 },
    { lat: 49.2011773, lng: 19.214513 },
    { lat: 49.2011484, lng: 19.2144513 },
    { lat: 49.2011163, lng: 19.2135261 },
    { lat: 49.2008398, lng: 19.2124676 },
    { lat: 49.2005676, lng: 19.2114274 },
    { lat: 49.2003147, lng: 19.2115808 },
    { lat: 49.2002283, lng: 19.2116338 },
    { lat: 49.2002204, lng: 19.2116382 },
    { lat: 49.2001202, lng: 19.2116852 },
    { lat: 49.2000947, lng: 19.2116942 },
    { lat: 49.1999752, lng: 19.2117389 },
    { lat: 49.199933, lng: 19.2117498 },
    { lat: 49.1998667, lng: 19.2118982 },
    { lat: 49.1997711, lng: 19.2121104 },
    { lat: 49.1997161, lng: 19.212234 },
    { lat: 49.1993829, lng: 19.212035 },
    { lat: 49.1992338, lng: 19.2119537 },
    { lat: 49.1990525, lng: 19.211862 },
    { lat: 49.1988445, lng: 19.2117815 },
    { lat: 49.1984242, lng: 19.2121801 },
    { lat: 49.1984054, lng: 19.2122106 },
    { lat: 49.1984595, lng: 19.2123048 },
    { lat: 49.1984539, lng: 19.2123306 },
    { lat: 49.1982969, lng: 19.212625 },
    { lat: 49.1982048, lng: 19.2127986 },
    { lat: 49.1981545, lng: 19.2129012 },
    { lat: 49.198239, lng: 19.2133202 },
    { lat: 49.1982987, lng: 19.2135993 },
    { lat: 49.1980028, lng: 19.2139619 },
    { lat: 49.1979827, lng: 19.2139876 },
    { lat: 49.1978314, lng: 19.2141949 },
    { lat: 49.1978046, lng: 19.2142465 },
    { lat: 49.1976917, lng: 19.2144762 },
    { lat: 49.1975309, lng: 19.2147659 },
    { lat: 49.1974377, lng: 19.2150532 },
    { lat: 49.1973874, lng: 19.2152725 },
    { lat: 49.197333, lng: 19.2154977 },
    { lat: 49.1972533, lng: 19.2156725 },
    { lat: 49.1972525, lng: 19.2156751 },
    { lat: 49.1972043, lng: 19.2157767 },
    { lat: 49.1970951, lng: 19.2160838 },
    { lat: 49.197116, lng: 19.2161153 },
    { lat: 49.1970763, lng: 19.2161839 },
    { lat: 49.197071, lng: 19.2161924 },
    { lat: 49.1970481, lng: 19.2162321 },
    { lat: 49.1970171, lng: 19.21629 },
    { lat: 49.1969829, lng: 19.216349 },
    { lat: 49.1969732, lng: 19.2164329 },
    { lat: 49.1968371, lng: 19.2166212 },
    { lat: 49.1967513, lng: 19.2167382 },
    { lat: 49.196751, lng: 19.2168683 },
    { lat: 49.1965249, lng: 19.2169762 },
    { lat: 49.1963585, lng: 19.2172868 },
    { lat: 49.1963346, lng: 19.2173309 },
    { lat: 49.1962211, lng: 19.2175224 },
    { lat: 49.1961441, lng: 19.2176564 },
    { lat: 49.1961136, lng: 19.2176995 },
    { lat: 49.1960122, lng: 19.2178442 },
    { lat: 49.1959024, lng: 19.2179289 },
    { lat: 49.1955142, lng: 19.2182881 },
    { lat: 49.1954751, lng: 19.2183199 },
    { lat: 49.1953012, lng: 19.2184547 },
    { lat: 49.1952155, lng: 19.2185249 },
    { lat: 49.1951668, lng: 19.2185678 },
    { lat: 49.195019, lng: 19.2187008 },
    { lat: 49.1948466, lng: 19.218843 },
    { lat: 49.1947978, lng: 19.2188849 },
    { lat: 49.1946785, lng: 19.2189577 },
    { lat: 49.1946236, lng: 19.2189905 },
    { lat: 49.1944713, lng: 19.2190299 },
    { lat: 49.1943263, lng: 19.219133 },
    { lat: 49.194287, lng: 19.2191609 },
    { lat: 49.1941476, lng: 19.2191902 },
    { lat: 49.1941332, lng: 19.2191932 },
    { lat: 49.1940895, lng: 19.2192028 },
    { lat: 49.1940315, lng: 19.2192158 },
    { lat: 49.1939647, lng: 19.2192305 },
    { lat: 49.1938733, lng: 19.2192501 },
    { lat: 49.1938446, lng: 19.2192425 },
    { lat: 49.1937053, lng: 19.2192041 },
    { lat: 49.1934764, lng: 19.2191112 },
    { lat: 49.1933433, lng: 19.2190594 },
    { lat: 49.1933156, lng: 19.2190488 },
    { lat: 49.1932951, lng: 19.2190432 },
    { lat: 49.1932288, lng: 19.2190204 },
    { lat: 49.1931805, lng: 19.2190027 },
    { lat: 49.1930166, lng: 19.2189933 },
    { lat: 49.1928485, lng: 19.2197595 },
    { lat: 49.1928539, lng: 19.2199896 },
    { lat: 49.1926521, lng: 19.2212298 },
    { lat: 49.1926197, lng: 19.2216115 },
    { lat: 49.192619, lng: 19.2219708 },
    { lat: 49.1927008, lng: 19.2228755 },
    { lat: 49.19273, lng: 19.2235317 },
    { lat: 49.1927614, lng: 19.223762 },
    { lat: 49.1927706, lng: 19.2239531 },
    { lat: 49.1929268, lng: 19.224412 },
    { lat: 49.1929779, lng: 19.2245601 },
    { lat: 49.1930085, lng: 19.2247747 },
    { lat: 49.1930871, lng: 19.2249061 },
    { lat: 49.1930965, lng: 19.2249389 },
    { lat: 49.1934778, lng: 19.2263755 },
    { lat: 49.19354, lng: 19.2267811 },
    { lat: 49.1936639, lng: 19.2274563 },
    { lat: 49.193664, lng: 19.2274581 },
    { lat: 49.193667, lng: 19.2274772 },
    { lat: 49.1936733, lng: 19.2275154 },
    { lat: 49.1936811, lng: 19.2275582 },
    { lat: 49.1936755, lng: 19.2275606 },
    { lat: 49.1936503, lng: 19.2275717 },
    { lat: 49.1928429, lng: 19.2279417 },
    { lat: 49.1922577, lng: 19.2281452 },
    { lat: 49.1921584, lng: 19.2282168 },
    { lat: 49.1919763, lng: 19.2282011 },
    { lat: 49.1915967, lng: 19.2284155 },
    { lat: 49.1913518, lng: 19.2287597 },
    { lat: 49.1912658, lng: 19.228823 },
    { lat: 49.190887, lng: 19.2291032 },
    { lat: 49.1908873, lng: 19.2291093 },
    { lat: 49.1908901, lng: 19.2291446 },
    { lat: 49.1908926, lng: 19.2291714 },
    { lat: 49.1909777, lng: 19.2294674 },
    { lat: 49.1909926, lng: 19.229518 },
    { lat: 49.1910372, lng: 19.2295807 },
    { lat: 49.1911651, lng: 19.2297556 },
    { lat: 49.191199, lng: 19.2298026 },
    { lat: 49.1912099, lng: 19.2298173 },
    { lat: 49.191224, lng: 19.2298564 },
    { lat: 49.1912646, lng: 19.2299729 },
    { lat: 49.1913038, lng: 19.2300853 },
    { lat: 49.1913235, lng: 19.2301411 },
    { lat: 49.1913442, lng: 19.230199 },
    { lat: 49.1913639, lng: 19.2302559 },
    { lat: 49.1914058, lng: 19.2303748 },
    { lat: 49.1914501, lng: 19.2305006 },
    { lat: 49.1914708, lng: 19.2305592 },
    { lat: 49.1915076, lng: 19.2306657 },
    { lat: 49.1915337, lng: 19.230741 },
    { lat: 49.1915624, lng: 19.2308228 },
    { lat: 49.1915992, lng: 19.2309301 },
    { lat: 49.19162, lng: 19.230989 },
    { lat: 49.1916567, lng: 19.2310959 },
    { lat: 49.1916657, lng: 19.2311208 },
    { lat: 49.1916956, lng: 19.2311085 },
    { lat: 49.1923705, lng: 19.2308207 },
    { lat: 49.1925581, lng: 19.2307403 },
    { lat: 49.1927063, lng: 19.2306769 },
    { lat: 49.1927549, lng: 19.2306563 },
    { lat: 49.1927979, lng: 19.2306385 },
    { lat: 49.1928828, lng: 19.2306016 },
    { lat: 49.192959, lng: 19.2305693 },
    { lat: 49.1930022, lng: 19.2305505 },
    { lat: 49.1930453, lng: 19.230532 },
    { lat: 49.1930872, lng: 19.2305139 },
    { lat: 49.1931733, lng: 19.2304773 },
    { lat: 49.1932816, lng: 19.2304313 },
    { lat: 49.1934019, lng: 19.23038 },
    { lat: 49.1934672, lng: 19.2303514 },
    { lat: 49.1935211, lng: 19.2303288 },
    { lat: 49.193529, lng: 19.2303254 },
    { lat: 49.1936275, lng: 19.2309457 },
    { lat: 49.1937197, lng: 19.2315272 },
    { lat: 49.1940741, lng: 19.2315153 },
    { lat: 49.1942036, lng: 19.2314626 },
    { lat: 49.1942853, lng: 19.2308682 },
    { lat: 49.1942918, lng: 19.230816 },
    { lat: 49.1943203, lng: 19.2306081 },
    { lat: 49.1943296, lng: 19.2305915 },
    { lat: 49.194388, lng: 19.2304931 },
    { lat: 49.1944161, lng: 19.2304446 },
    { lat: 49.1944752, lng: 19.2319598 },
    { lat: 49.1944908, lng: 19.2322348 },
    { lat: 49.1945281, lng: 19.2327085 },
    { lat: 49.1945755, lng: 19.2330837 },
    { lat: 49.194591, lng: 19.2331966 },
    { lat: 49.194721, lng: 19.2337691 },
    { lat: 49.1949371, lng: 19.233621 },
    { lat: 49.1952691, lng: 19.2333979 },
    { lat: 49.1953237, lng: 19.2333612 },
    { lat: 49.19534, lng: 19.2333503 },
    { lat: 49.1956965, lng: 19.2342723 },
    { lat: 49.1957365, lng: 19.2343774 },
    { lat: 49.1957573, lng: 19.2343639 },
    { lat: 49.1958681, lng: 19.2342802 },
    { lat: 49.1959896, lng: 19.2341552 },
    { lat: 49.1961166, lng: 19.2339619 },
    { lat: 49.1961397, lng: 19.2339265 },
    { lat: 49.1961848, lng: 19.2338332 },
    { lat: 49.1961938, lng: 19.2338091 },
    { lat: 49.1962378, lng: 19.2336914 },
    { lat: 49.1962581, lng: 19.2335731 },
    { lat: 49.1962682, lng: 19.2333864 },
    { lat: 49.1962353, lng: 19.2331735 },
    { lat: 49.1962375, lng: 19.2330994 },
    { lat: 49.1962425, lng: 19.2329446 },
    { lat: 49.1962513, lng: 19.2326603 },
    { lat: 49.1962876, lng: 19.2325208 },
    { lat: 49.1963406, lng: 19.2323119 },
    { lat: 49.1963871, lng: 19.2323889 },
    { lat: 49.1964104, lng: 19.2324277 },
    { lat: 49.1964336, lng: 19.2324666 },
    { lat: 49.1964569, lng: 19.2325051 },
    { lat: 49.1964791, lng: 19.2325437 },
    { lat: 49.1965476, lng: 19.2326571 },
    { lat: 49.196593, lng: 19.232732 },
    { lat: 49.1966593, lng: 19.2328439 },
    { lat: 49.1967045, lng: 19.2329192 },
    { lat: 49.1967291, lng: 19.2329597 },
    { lat: 49.1967535, lng: 19.2330009 },
    { lat: 49.1967768, lng: 19.2330391 },
    { lat: 49.1967989, lng: 19.2330759 },
    { lat: 49.1968233, lng: 19.2331164 },
    { lat: 49.1968454, lng: 19.2331543 },
    { lat: 49.1968785, lng: 19.2332097 },
    { lat: 49.1971233, lng: 19.2336144 },
    { lat: 49.1972288, lng: 19.2337897 },
    { lat: 49.1973902, lng: 19.2340592 },
    { lat: 49.1975497, lng: 19.2343261 },
    { lat: 49.1976036, lng: 19.2344159 },
    { lat: 49.1977102, lng: 19.2345939 },
    { lat: 49.1977556, lng: 19.2346685 },
    { lat: 49.1977713, lng: 19.2346909 },
    { lat: 49.1978015, lng: 19.2347344 },
    { lat: 49.1978643, lng: 19.2348232 },
    { lat: 49.19788, lng: 19.2348459 },
    { lat: 49.1979052, lng: 19.2348777 },
    { lat: 49.1979929, lng: 19.2349872 },
    { lat: 49.198073, lng: 19.2350878 },
    { lat: 49.1981223, lng: 19.235149 },
    { lat: 49.1981858, lng: 19.2352284 },
    { lat: 49.1982421, lng: 19.2352989 },
    { lat: 49.1982986, lng: 19.2353693 },
    { lat: 49.1983094, lng: 19.2353829 },
    { lat: 49.1983584, lng: 19.235443 },
    { lat: 49.1983787, lng: 19.2354685 },
    { lat: 49.19839, lng: 19.2354679 },
    { lat: 49.1984002, lng: 19.2354672 },
    { lat: 49.198411, lng: 19.2346597 },
    { lat: 49.1984329, lng: 19.2344371 },
    { lat: 49.1985484, lng: 19.2333459 },
    { lat: 49.1985745, lng: 19.2330435 },
    { lat: 49.1985906, lng: 19.2330489 },
    { lat: 49.198644, lng: 19.2330775 },
    { lat: 49.1987885, lng: 19.2331569 },
    { lat: 49.1988023, lng: 19.2331643 },
    { lat: 49.1988368, lng: 19.2331987 },
    { lat: 49.1989661, lng: 19.2333327 },
    { lat: 49.1990123, lng: 19.2333798 },
    { lat: 49.1990359, lng: 19.2334049 },
    { lat: 49.1990763, lng: 19.2334454 },
    { lat: 49.1991154, lng: 19.2334858 },
    { lat: 49.1991474, lng: 19.233519 },
    { lat: 49.1991546, lng: 19.2335264 },
    { lat: 49.1991949, lng: 19.2335684 },
    { lat: 49.1992732, lng: 19.2336501 },
    { lat: 49.1994085, lng: 19.2337918 },
    { lat: 49.1994108, lng: 19.2337948 },
    { lat: 49.199478, lng: 19.2338575 },
    { lat: 49.1995146, lng: 19.2338913 },
    { lat: 49.1995843, lng: 19.2339566 },
    { lat: 49.1996197, lng: 19.2339898 },
    { lat: 49.1996536, lng: 19.234017 },
    { lat: 49.1996877, lng: 19.2340435 },
    { lat: 49.1997215, lng: 19.2340696 },
    { lat: 49.1997557, lng: 19.2340964 },
    { lat: 49.1997895, lng: 19.2341233 },
    { lat: 49.1998234, lng: 19.2341494 },
    { lat: 49.1998911, lng: 19.2341963 },
    { lat: 49.1999945, lng: 19.2342604 },
    { lat: 49.2000632, lng: 19.2343032 },
    { lat: 49.2001652, lng: 19.2343674 },
    { lat: 49.2002012, lng: 19.234389 },
    { lat: 49.200228, lng: 19.2344058 },
    { lat: 49.2003512, lng: 19.2344829 },
    { lat: 49.2004128, lng: 19.2345211 },
    { lat: 49.2005079, lng: 19.23458 },
    { lat: 49.200601, lng: 19.2346384 },
    { lat: 49.2006601, lng: 19.2346758 },
    { lat: 49.2007415, lng: 19.2347265 },
    { lat: 49.2007531, lng: 19.2347328 },
    { lat: 49.2008146, lng: 19.2347702 },
    { lat: 49.2008448, lng: 19.2347888 },
    { lat: 49.200875, lng: 19.2348074 },
    { lat: 49.2009052, lng: 19.2348261 },
    { lat: 49.2009353, lng: 19.2348436 },
    { lat: 49.2009645, lng: 19.234862 },
    { lat: 49.2009969, lng: 19.2348814 },
    { lat: 49.2010584, lng: 19.2349192 },
    { lat: 49.2010909, lng: 19.2349617 },
    { lat: 49.2010949, lng: 19.2349941 },
    { lat: 49.2011024, lng: 19.2350567 },
    { lat: 49.2011076, lng: 19.2350961 },
    { lat: 49.2011116, lng: 19.2351306 },
    { lat: 49.2011261, lng: 19.2352472 },
    { lat: 49.2011407, lng: 19.2353638 },
    { lat: 49.2011444, lng: 19.2353972 },
    { lat: 49.2011498, lng: 19.2354359 },
    { lat: 49.2011578, lng: 19.2355086 },
    { lat: 49.201167, lng: 19.2355818 },
    { lat: 49.2011762, lng: 19.2356557 },
    { lat: 49.2011852, lng: 19.2357293 },
    { lat: 49.2012331, lng: 19.2361136 },
    { lat: 49.2012545, lng: 19.2362784 },
    { lat: 49.2012651, lng: 19.2363608 },
    { lat: 49.2012747, lng: 19.2364434 },
    { lat: 49.2012907, lng: 19.2365648 },
    { lat: 49.2013015, lng: 19.2366476 },
    { lat: 49.2013072, lng: 19.2366967 },
    { lat: 49.2013255, lng: 19.2368424 },
    { lat: 49.2013416, lng: 19.2369653 },
    { lat: 49.2013525, lng: 19.2370506 },
    { lat: 49.2013666, lng: 19.2371568 },
    { lat: 49.2013707, lng: 19.2371996 },
    { lat: 49.2013794, lng: 19.2372862 },
    { lat: 49.2013883, lng: 19.2373786 },
    { lat: 49.2014026, lng: 19.2375085 },
    { lat: 49.2014071, lng: 19.2375588 },
    { lat: 49.2014118, lng: 19.2376077 },
    { lat: 49.2014222, lng: 19.2377053 },
    { lat: 49.2014396, lng: 19.2378814 },
    { lat: 49.2014581, lng: 19.2380566 },
    { lat: 49.2014708, lng: 19.2381777 },
    { lat: 49.2014859, lng: 19.2383296 },
    { lat: 49.201492, lng: 19.2383891 },
    { lat: 49.2015112, lng: 19.2385769 },
    { lat: 49.2015201, lng: 19.2386682 },
    { lat: 49.2015386, lng: 19.2388434 },
    { lat: 49.2015394, lng: 19.2388585 },
    { lat: 49.2015449, lng: 19.2389736 },
    { lat: 49.2015555, lng: 19.2391468 },
    { lat: 49.2015581, lng: 19.2391995 },
    { lat: 49.2014644, lng: 19.2391036 },
    { lat: 49.2014337, lng: 19.2390728 },
    { lat: 49.2014051, lng: 19.2390432 },
    { lat: 49.2013256, lng: 19.2389616 },
    { lat: 49.201276, lng: 19.2389113 },
    { lat: 49.2012452, lng: 19.2388798 },
    { lat: 49.201204, lng: 19.2388448 },
    { lat: 49.2011651, lng: 19.2388109 },
    { lat: 49.2011498, lng: 19.2387964 },
    { lat: 49.2011276, lng: 19.2387776 },
    { lat: 49.2010828, lng: 19.2387394 },
    { lat: 49.2010311, lng: 19.2386954 },
    { lat: 49.2007487, lng: 19.2387273 },
    { lat: 49.2005337, lng: 19.2387535 },
    { lat: 49.200317, lng: 19.2387957 },
    { lat: 49.1998319, lng: 19.2389047 },
    { lat: 49.1998248, lng: 19.2388748 },
    { lat: 49.1996146, lng: 19.2388846 },
    { lat: 49.1995445, lng: 19.238905 },
    { lat: 49.1994756, lng: 19.2389513 },
    { lat: 49.1994397, lng: 19.2389755 },
    { lat: 49.1994113, lng: 19.2389942 },
    { lat: 49.1994047, lng: 19.2389989 },
    { lat: 49.1993691, lng: 19.2388918 },
    { lat: 49.1993464, lng: 19.2388425 },
    { lat: 49.1991858, lng: 19.2390882 },
    { lat: 49.1990416, lng: 19.2394395 },
    { lat: 49.1988353, lng: 19.239722 },
    { lat: 49.1986225, lng: 19.2400088 },
    { lat: 49.1984421, lng: 19.2403335 },
    { lat: 49.1982973, lng: 19.2406982 },
    { lat: 49.1981963, lng: 19.2411772 },
    { lat: 49.1981611, lng: 19.2413646 },
    { lat: 49.1980954, lng: 19.2418008 },
    { lat: 49.1982723, lng: 19.2424578 },
    { lat: 49.1983546, lng: 19.2430264 },
    { lat: 49.1983206, lng: 19.2431394 },
    { lat: 49.198272, lng: 19.2432057 },
    { lat: 49.198244, lng: 19.2433259 },
    { lat: 49.1982033, lng: 19.2434854 },
    { lat: 49.1982075, lng: 19.2439041 },
    { lat: 49.1982495, lng: 19.2440504 },
    { lat: 49.1983449, lng: 19.2442574 },
    { lat: 49.1984314, lng: 19.2443912 },
    { lat: 49.1985712, lng: 19.2445555 },
    { lat: 49.1986644, lng: 19.2446409 },
    { lat: 49.1987103, lng: 19.244683 },
    { lat: 49.1987371, lng: 19.2446807 },
    { lat: 49.1987733, lng: 19.2447066 },
    { lat: 49.1989529, lng: 19.2447136 },
    { lat: 49.1990556, lng: 19.2447425 },
    { lat: 49.1991177, lng: 19.2447655 },
    { lat: 49.1992315, lng: 19.2448094 },
    { lat: 49.1993981, lng: 19.2449252 },
    { lat: 49.1994834, lng: 19.2450098 },
    { lat: 49.1996111, lng: 19.2451858 },
    { lat: 49.199739, lng: 19.2454051 },
    { lat: 49.1997512, lng: 19.2454286 },
    { lat: 49.1998294, lng: 19.2455777 },
    { lat: 49.1997954, lng: 19.2457401 },
    { lat: 49.1998654, lng: 19.2457856 },
    { lat: 49.1999199, lng: 19.2458203 },
    { lat: 49.2001469, lng: 19.2459679 },
    { lat: 49.2004905, lng: 19.246179 },
    { lat: 49.2006542, lng: 19.2462797 },
    { lat: 49.20081, lng: 19.2463755 },
    { lat: 49.2006811, lng: 19.246885 },
    { lat: 49.2004741, lng: 19.2479529 },
    { lat: 49.2003983, lng: 19.2482551 },
    { lat: 49.2001096, lng: 19.2481579 },
    { lat: 49.1999735, lng: 19.2481172 },
    { lat: 49.1999256, lng: 19.2481028 },
    { lat: 49.1998695, lng: 19.2480859 },
    { lat: 49.1997312, lng: 19.2480441 },
    { lat: 49.199683, lng: 19.2480278 },
    { lat: 49.1997099, lng: 19.2480929 },
    { lat: 49.1996939, lng: 19.2481131 },
    { lat: 49.1996493, lng: 19.2481235 },
    { lat: 49.1994364, lng: 19.2479832 },
    { lat: 49.1994201, lng: 19.2479724 },
    { lat: 49.1993271, lng: 19.2480534 },
    { lat: 49.1992596, lng: 19.2483154 },
    { lat: 49.1992986, lng: 19.2484934 },
    { lat: 49.1993073, lng: 19.2485382 },
    { lat: 49.1993258, lng: 19.2486176 },
    { lat: 49.1992929, lng: 19.2488937 },
    { lat: 49.1993337, lng: 19.2490632 },
    { lat: 49.1992988, lng: 19.2490906 },
    { lat: 49.1991042, lng: 19.249096 },
    { lat: 49.199111, lng: 19.2491183 },
    { lat: 49.1991018, lng: 19.2491378 },
    { lat: 49.1990829, lng: 19.2494051 },
    { lat: 49.1990709, lng: 19.2494108 },
    { lat: 49.1990633, lng: 19.2496062 },
    { lat: 49.1990513, lng: 19.2497323 },
    { lat: 49.1988915, lng: 19.2497137 },
    { lat: 49.1988423, lng: 19.2503823 },
    { lat: 49.1988891, lng: 19.2503936 },
    { lat: 49.1988794, lng: 19.2504715 },
    { lat: 49.1988697, lng: 19.2505313 },
    { lat: 49.1988619, lng: 19.2506043 },
    { lat: 49.1988331, lng: 19.2505898 },
    { lat: 49.198794, lng: 19.2509718 },
    { lat: 49.1987753, lng: 19.2511502 },
    { lat: 49.1987666, lng: 19.2512733 },
    { lat: 49.1987626, lng: 19.2513325 },
    { lat: 49.1988299, lng: 19.2514414 },
    { lat: 49.1988783, lng: 19.2514414 },
    { lat: 49.1988914, lng: 19.2514335 },
    { lat: 49.1989189, lng: 19.251416 },
    { lat: 49.1990754, lng: 19.251178 },
    { lat: 49.1991557, lng: 19.2510696 },
    { lat: 49.1991961, lng: 19.2510142 },
    { lat: 49.1994119, lng: 19.2508612 },
    { lat: 49.1994494, lng: 19.2508447 },
    { lat: 49.1994856, lng: 19.2508468 },
    { lat: 49.199577, lng: 19.2508514 },
    { lat: 49.1996584, lng: 19.250855 },
    { lat: 49.1999442, lng: 19.2508696 },
    { lat: 49.2001272, lng: 19.2508784 },
    { lat: 49.200108, lng: 19.2512784 },
    { lat: 49.2000982, lng: 19.2513783 },
    { lat: 49.2000049, lng: 19.2521624 },
    { lat: 49.2000138, lng: 19.2527929 },
    { lat: 49.2000689, lng: 19.2537814 },
    { lat: 49.2000975, lng: 19.2545228 },
    { lat: 49.2001879, lng: 19.2558004 },
    { lat: 49.2004058, lng: 19.2573407 },
    { lat: 49.2008096, lng: 19.2590202 },
    { lat: 49.2014075, lng: 19.2605654 },
    { lat: 49.2020283, lng: 19.2617657 },
    { lat: 49.2021152, lng: 19.2619333 },
    { lat: 49.2024714, lng: 19.2622149 },
    { lat: 49.2032264, lng: 19.2633231 },
    { lat: 49.2037082, lng: 19.2639951 },
    { lat: 49.2042527, lng: 19.2647552 },
    { lat: 49.2047766, lng: 19.2652971 },
    { lat: 49.2051273, lng: 19.2657973 },
    { lat: 49.2057464, lng: 19.2663917 },
    { lat: 49.2066426, lng: 19.2671546 },
    { lat: 49.2078942, lng: 19.2679433 },
    { lat: 49.208355, lng: 19.268272 },
    { lat: 49.2085557, lng: 19.2684151 },
    { lat: 49.2090509, lng: 19.2689682 },
    { lat: 49.2093892, lng: 19.2694457 },
    { lat: 49.2097181, lng: 19.2698446 },
    { lat: 49.2104473, lng: 19.2707408 },
    { lat: 49.2109386, lng: 19.2714011 },
    { lat: 49.211374, lng: 19.2721677 },
    { lat: 49.2114798, lng: 19.2718802 },
    { lat: 49.2114908, lng: 19.2718534 },
    { lat: 49.2115018, lng: 19.2718215 },
    { lat: 49.2115202, lng: 19.2717816 },
    { lat: 49.2115394, lng: 19.2717377 },
    { lat: 49.2115975, lng: 19.271609 },
    { lat: 49.2116272, lng: 19.2715434 },
    { lat: 49.2116328, lng: 19.2714973 },
    { lat: 49.2116391, lng: 19.2714404 },
    { lat: 49.2116503, lng: 19.271344 },
    { lat: 49.2116626, lng: 19.2712467 },
    { lat: 49.2116639, lng: 19.2712289 },
    { lat: 49.2116672, lng: 19.2712033 },
    { lat: 49.211672, lng: 19.2711603 },
    { lat: 49.2120508, lng: 19.2710904 },
    { lat: 49.2121812, lng: 19.2710122 },
    { lat: 49.2124471, lng: 19.2707743 },
    { lat: 49.212625, lng: 19.2705386 },
    { lat: 49.2127171, lng: 19.270365 },
    { lat: 49.2127957, lng: 19.2702161 },
    { lat: 49.2128185, lng: 19.2701735 },
    { lat: 49.2128703, lng: 19.2700816 },
    { lat: 49.2129432, lng: 19.2699553 },
    { lat: 49.213016, lng: 19.2698282 },
    { lat: 49.2130212, lng: 19.2698172 },
    { lat: 49.2130506, lng: 19.2697271 },
    { lat: 49.2130862, lng: 19.2696204 },
    { lat: 49.2131677, lng: 19.2693698 },
    { lat: 49.2132004, lng: 19.2692285 },
    { lat: 49.2132229, lng: 19.2691308 },
    { lat: 49.2132378, lng: 19.2690657 },
    { lat: 49.2132482, lng: 19.2690224 },
    { lat: 49.2132558, lng: 19.2689927 },
    { lat: 49.2132738, lng: 19.2689197 },
    { lat: 49.213304, lng: 19.2688013 },
    { lat: 49.2133184, lng: 19.2687456 },
    { lat: 49.2133534, lng: 19.2686087 },
    { lat: 49.2133662, lng: 19.2685691 },
    { lat: 49.2134265, lng: 19.2683879 },
    { lat: 49.2134344, lng: 19.2683654 },
    { lat: 49.2134407, lng: 19.2679332 },
    { lat: 49.2134522, lng: 19.2676288 },
    { lat: 49.2135337, lng: 19.2674013 },
    { lat: 49.2136812, lng: 19.2670875 },
    { lat: 49.2137439, lng: 19.2667286 },
    { lat: 49.2137944, lng: 19.2662043 },
    { lat: 49.2137875, lng: 19.2657526 },
    { lat: 49.2137859, lng: 19.2656241 },
    { lat: 49.2137815, lng: 19.2652944 },
    { lat: 49.2137122, lng: 19.2649506 },
    { lat: 49.2136945, lng: 19.2648434 },
    { lat: 49.213646, lng: 19.2645586 },
    { lat: 49.213749, lng: 19.2641192 },
    { lat: 49.2137518, lng: 19.2641081 },
    { lat: 49.2137679, lng: 19.26404 },
    { lat: 49.2138331, lng: 19.2636408 },
    { lat: 49.213872, lng: 19.263391 },
    { lat: 49.2139609, lng: 19.2628184 },
    { lat: 49.2141361, lng: 19.2622647 },
    { lat: 49.2143878, lng: 19.2615665 },
    { lat: 49.2144211, lng: 19.261482 },
    { lat: 49.2144958, lng: 19.2612765 },
    { lat: 49.2146908, lng: 19.260593 },
    { lat: 49.2148944, lng: 19.2601817 },
    { lat: 49.2150343, lng: 19.2599029 },
    { lat: 49.21506, lng: 19.2598511 },
    { lat: 49.2156194, lng: 19.2589167 },
    { lat: 49.2158622, lng: 19.2585941 },
    { lat: 49.2160986, lng: 19.2582581 },
    { lat: 49.2161851, lng: 19.2581125 },
    { lat: 49.2162195, lng: 19.2580561 },
    { lat: 49.2163511, lng: 19.2582401 },
    { lat: 49.216436, lng: 19.2584109 },
    { lat: 49.2165191, lng: 19.2585682 },
    { lat: 49.216654, lng: 19.2587525 },
    { lat: 49.2168427, lng: 19.258906 },
    { lat: 49.2169983, lng: 19.2590715 },
    { lat: 49.2171547, lng: 19.2592758 },
    { lat: 49.2173516, lng: 19.2594341 },
    { lat: 49.2176381, lng: 19.2598395 },
    { lat: 49.2178317, lng: 19.2601208 },
    { lat: 49.2178899, lng: 19.2603256 },
    { lat: 49.2178901, lng: 19.2603494 },
    { lat: 49.2178917, lng: 19.2605017 },
    { lat: 49.2178928, lng: 19.2606919 },
    { lat: 49.217901, lng: 19.26077 },
    { lat: 49.2179281, lng: 19.26105 },
    { lat: 49.2179255, lng: 19.2612089 },
    { lat: 49.2179182, lng: 19.2615302 },
    { lat: 49.2181445, lng: 19.261452 },
    { lat: 49.218344, lng: 19.261383 },
    { lat: 49.2185824, lng: 19.2614686 },
    { lat: 49.2187532, lng: 19.2615292 },
    { lat: 49.21881, lng: 19.2615355 },
    { lat: 49.2188622, lng: 19.2615413 },
    { lat: 49.2190502, lng: 19.2617291 },
    { lat: 49.2190867, lng: 19.2617629 },
    { lat: 49.2190914, lng: 19.2617674 },
    { lat: 49.2191303, lng: 19.2618741 },
    { lat: 49.2191395, lng: 19.2618991 },
    { lat: 49.2192113, lng: 19.2618936 },
    { lat: 49.2192934, lng: 19.261887 },
    { lat: 49.2193786, lng: 19.2618804 },
    { lat: 49.2194607, lng: 19.2618745 },
    { lat: 49.2194955, lng: 19.2618717 },
    { lat: 49.2195077, lng: 19.2619129 },
    { lat: 49.2195253, lng: 19.2619758 },
    { lat: 49.2198265, lng: 19.2617696 },
    { lat: 49.2198607, lng: 19.2617781 },
    { lat: 49.2198652, lng: 19.2617797 },
    { lat: 49.219904, lng: 19.2617898 },
    { lat: 49.2201488, lng: 19.2617247 },
    { lat: 49.2202608, lng: 19.2616607 },
    { lat: 49.2203617, lng: 19.2616026 },
    { lat: 49.2204024, lng: 19.261579 },
    { lat: 49.220457, lng: 19.2615469 },
    { lat: 49.2205469, lng: 19.2614944 },
    { lat: 49.2205832, lng: 19.2614734 },
    { lat: 49.2206763, lng: 19.2616084 },
    { lat: 49.2206958, lng: 19.261591 },
    { lat: 49.2210795, lng: 19.2612491 },
    { lat: 49.2211897, lng: 19.2611481 },
    { lat: 49.2214214, lng: 19.2608754 },
    { lat: 49.2215359, lng: 19.2607271 },
    { lat: 49.2215526, lng: 19.2607014 },
    { lat: 49.2217407, lng: 19.260506 },
    { lat: 49.2217783, lng: 19.2604672 },
    { lat: 49.2219847, lng: 19.2601395 },
    { lat: 49.2220402, lng: 19.2600508 },
    { lat: 49.222061, lng: 19.2600178 },
    { lat: 49.2222046, lng: 19.2597898 },
    { lat: 49.2222776, lng: 19.2596671 },
    { lat: 49.2222873, lng: 19.2594944 },
    { lat: 49.2225181, lng: 19.2593468 },
    { lat: 49.2225466, lng: 19.2593306 },
    { lat: 49.2227413, lng: 19.2592048 },
    { lat: 49.2229462, lng: 19.2591077 },
    { lat: 49.2230802, lng: 19.2591998 },
    { lat: 49.2232617, lng: 19.2593156 },
    { lat: 49.2232698, lng: 19.2593215 },
    { lat: 49.2233796, lng: 19.2594002 },
    { lat: 49.2233935, lng: 19.2594101 },
    { lat: 49.2234635, lng: 19.2594615 },
    { lat: 49.2235988, lng: 19.2594363 },
    { lat: 49.2239943, lng: 19.2593653 },
    { lat: 49.2242964, lng: 19.259509 },
    { lat: 49.2243667, lng: 19.259468 },
    { lat: 49.2245135, lng: 19.2593817 },
    { lat: 49.2245278, lng: 19.2592565 },
    { lat: 49.2247132, lng: 19.2591028 },
    { lat: 49.2249566, lng: 19.2590573 },
    { lat: 49.2250674, lng: 19.2590637 },
    { lat: 49.2253149, lng: 19.2589583 },
    { lat: 49.225316, lng: 19.2589578 },
    { lat: 49.2261338, lng: 19.2582393 },
    { lat: 49.2260117, lng: 19.2578793 },
    { lat: 49.2259657, lng: 19.2577424 },
    { lat: 49.2261044, lng: 19.2575102 },
    { lat: 49.2262191, lng: 19.2573164 },
    { lat: 49.2262744, lng: 19.2572237 },
    { lat: 49.2263287, lng: 19.2571333 },
    { lat: 49.2263674, lng: 19.2570684 },
    { lat: 49.2264016, lng: 19.2570094 },
    { lat: 49.2264351, lng: 19.2569545 },
    { lat: 49.2264476, lng: 19.2569333 },
    { lat: 49.2264747, lng: 19.2569068 },
    { lat: 49.2264961, lng: 19.256885 },
    { lat: 49.2265565, lng: 19.2568307 },
    { lat: 49.2265783, lng: 19.2568113 },
    { lat: 49.2266719, lng: 19.2569079 },
    { lat: 49.2267055, lng: 19.2569035 },
    { lat: 49.2267761, lng: 19.2568942 },
    { lat: 49.226958, lng: 19.2566468 },
    { lat: 49.2270892, lng: 19.2564673 },
    { lat: 49.2271422, lng: 19.2563962 },
    { lat: 49.2271694, lng: 19.2563524 },
    { lat: 49.2272291, lng: 19.256257 },
    { lat: 49.2272805, lng: 19.256176 },
    { lat: 49.2273904, lng: 19.2560004 },
    { lat: 49.2276217, lng: 19.2556309 },
    { lat: 49.2277579, lng: 19.2554131 },
    { lat: 49.2278433, lng: 19.2553866 },
    { lat: 49.2278461, lng: 19.2552576 },
    { lat: 49.2279117, lng: 19.2551165 },
    { lat: 49.2281034, lng: 19.2547205 },
    { lat: 49.2281323, lng: 19.2546621 },
    { lat: 49.2281322, lng: 19.254661 },
    { lat: 49.228803, lng: 19.254993 },
    { lat: 49.2288131, lng: 19.2549483 },
    { lat: 49.2288576, lng: 19.2547475 },
    { lat: 49.2290568, lng: 19.2548349 },
    { lat: 49.2293355, lng: 19.2549579 },
    { lat: 49.229767, lng: 19.2551903 },
    { lat: 49.2299594, lng: 19.2552576 },
    { lat: 49.2300122, lng: 19.2552759 },
    { lat: 49.2303539, lng: 19.2554931 },
    { lat: 49.2305037, lng: 19.2555889 },
    { lat: 49.2307416, lng: 19.2556829 },
    { lat: 49.2309249, lng: 19.255625 },
    { lat: 49.2312048, lng: 19.2556356 },
    { lat: 49.2319635, lng: 19.2556643 },
    { lat: 49.2321491, lng: 19.2556086 },
    { lat: 49.2322382, lng: 19.2556092 },
    { lat: 49.2324838, lng: 19.2556099 },
    { lat: 49.2332008, lng: 19.2557327 },
    { lat: 49.233611, lng: 19.2558031 },
    { lat: 49.2340002, lng: 19.2557865 },
    { lat: 49.2343592, lng: 19.255916 },
    { lat: 49.2344601, lng: 19.2559527 },
    { lat: 49.234482, lng: 19.2559604 },
    { lat: 49.2345647, lng: 19.2558786 },
    { lat: 49.2345724, lng: 19.2558717 },
    { lat: 49.2347364, lng: 19.2556507 },
    { lat: 49.2347639, lng: 19.2556123 },
    { lat: 49.2348094, lng: 19.2555272 },
    { lat: 49.2349037, lng: 19.2553515 },
    { lat: 49.2349175, lng: 19.255333 },
    { lat: 49.2349989, lng: 19.2552222 },
    { lat: 49.235071, lng: 19.2551247 },
    { lat: 49.2352913, lng: 19.2548777 },
    { lat: 49.2352945, lng: 19.2548755 },
    { lat: 49.2353709, lng: 19.2548244 },
    { lat: 49.2354407, lng: 19.2547777 },
    { lat: 49.2357369, lng: 19.2545806 },
    { lat: 49.2358756, lng: 19.2544883 },
    { lat: 49.2359967, lng: 19.2544074 },
    { lat: 49.2360056, lng: 19.2544017 },
    { lat: 49.2360377, lng: 19.254203 },
    { lat: 49.2360658, lng: 19.2540347 },
    { lat: 49.2360939, lng: 19.2538667 },
    { lat: 49.2361207, lng: 19.2536968 },
    { lat: 49.2365635, lng: 19.2540708 },
    { lat: 49.2367027, lng: 19.2541332 },
    { lat: 49.2368119, lng: 19.2541813 },
    { lat: 49.2370491, lng: 19.2542858 },
    { lat: 49.2372185, lng: 19.2531355 },
    { lat: 49.2375706, lng: 19.2532394 },
    { lat: 49.2377343, lng: 19.2533387 },
    { lat: 49.2378374, lng: 19.2529763 },
    { lat: 49.2381012, lng: 19.2531186 },
    { lat: 49.2383369, lng: 19.2530245 },
    { lat: 49.2385394, lng: 19.2530181 },
    { lat: 49.2385733, lng: 19.2531835 },
    { lat: 49.2388573, lng: 19.2534927 },
    { lat: 49.2390623, lng: 19.2536055 },
    { lat: 49.2390948, lng: 19.2536238 },
    { lat: 49.23923, lng: 19.2536005 },
    { lat: 49.239392, lng: 19.2535719 },
    { lat: 49.2394187, lng: 19.2535675 },
    { lat: 49.2395882, lng: 19.2537145 },
    { lat: 49.2396035, lng: 19.2537283 },
    { lat: 49.2396388, lng: 19.2538283 },
    { lat: 49.2396675, lng: 19.2539106 },
    { lat: 49.2396914, lng: 19.2539415 },
    { lat: 49.2397537, lng: 19.2540193 },
    { lat: 49.2397944, lng: 19.2540706 },
    { lat: 49.2398069, lng: 19.2541194 },
    { lat: 49.2398424, lng: 19.2542482 },
    { lat: 49.2399065, lng: 19.2544794 },
    { lat: 49.2399378, lng: 19.2545942 },
    { lat: 49.2399381, lng: 19.2546007 },
    { lat: 49.2399524, lng: 19.2549933 },
    { lat: 49.240061, lng: 19.2550977 },
    { lat: 49.2401196, lng: 19.2551918 },
    { lat: 49.2403764, lng: 19.2556087 },
    { lat: 49.2403959, lng: 19.2556415 },
    { lat: 49.2404521, lng: 19.2557553 },
    { lat: 49.2405381, lng: 19.2559282 },
    { lat: 49.2406078, lng: 19.2560669 },
    { lat: 49.2407549, lng: 19.2563636 },
    { lat: 49.2407662, lng: 19.2563869 },
    { lat: 49.2409032, lng: 19.256566 },
    { lat: 49.2410087, lng: 19.2568605 },
    { lat: 49.2410271, lng: 19.2569641 },
    { lat: 49.2410882, lng: 19.2572996 },
    { lat: 49.2411157, lng: 19.2573063 },
    { lat: 49.241323, lng: 19.2573546 },
    { lat: 49.2416039, lng: 19.2573623 },
    { lat: 49.2419584, lng: 19.2573722 },
    { lat: 49.242304, lng: 19.2574336 },
    { lat: 49.2424141, lng: 19.2574538 },
    { lat: 49.2425213, lng: 19.2574285 },
    { lat: 49.2427228, lng: 19.2573812 },
    { lat: 49.2427373, lng: 19.2573777 },
    { lat: 49.2429393, lng: 19.2574537 },
    { lat: 49.2429944, lng: 19.2574746 },
    { lat: 49.2430504, lng: 19.257397 },
    { lat: 49.2431374, lng: 19.2572801 },
    { lat: 49.2431447, lng: 19.2572689 },
    { lat: 49.2432252, lng: 19.2571596 },
    { lat: 49.2433913, lng: 19.2569337 },
    { lat: 49.2433912, lng: 19.256933 },
    { lat: 49.2437139, lng: 19.2566132 },
    { lat: 49.2438098, lng: 19.2565895 },
    { lat: 49.2439145, lng: 19.256611 },
    { lat: 49.2440624, lng: 19.2566411 },
    { lat: 49.2441679, lng: 19.2567235 },
    { lat: 49.2443087, lng: 19.2568881 },
    { lat: 49.2443907, lng: 19.257002 },
    { lat: 49.2445931, lng: 19.257062 },
    { lat: 49.2446025, lng: 19.2571162 },
    { lat: 49.2447489, lng: 19.2569264 },
    { lat: 49.2448602, lng: 19.256875 },
    { lat: 49.2449142, lng: 19.2568232 },
    { lat: 49.2450035, lng: 19.256735 },
    { lat: 49.2450926, lng: 19.25643 },
    { lat: 49.2453269, lng: 19.2564046 },
    { lat: 49.2453989, lng: 19.2562811 },
    { lat: 49.2454737, lng: 19.2561952 },
    { lat: 49.2455788, lng: 19.2560136 },
    { lat: 49.2455917, lng: 19.2559068 },
    { lat: 49.2456296, lng: 19.2558503 },
    { lat: 49.2456463, lng: 19.2557994 },
    { lat: 49.2456424, lng: 19.2557176 },
    { lat: 49.2457137, lng: 19.2555596 },
    { lat: 49.2457264, lng: 19.2554236 },
    { lat: 49.2458063, lng: 19.2552787 },
    { lat: 49.2459096, lng: 19.2551557 },
    { lat: 49.245971, lng: 19.2550223 },
    { lat: 49.2460577, lng: 19.254904 },
    { lat: 49.2461337, lng: 19.2548883 },
    { lat: 49.2462454, lng: 19.2548441 },
    { lat: 49.246363, lng: 19.2547351 },
    { lat: 49.2464298, lng: 19.2545328 },
    { lat: 49.2465226, lng: 19.2544929 },
    { lat: 49.2466234, lng: 19.2543374 },
    { lat: 49.2466277, lng: 19.2542601 },
    { lat: 49.2466455, lng: 19.2542072 },
    { lat: 49.2467944, lng: 19.2540474 },
    { lat: 49.2468163, lng: 19.2540103 },
    { lat: 49.2468549, lng: 19.2539433 },
    { lat: 49.2472394, lng: 19.2538806 },
    { lat: 49.2473512, lng: 19.2539078 },
    { lat: 49.2474687, lng: 19.2536086 },
    { lat: 49.2476555, lng: 19.2533414 },
    { lat: 49.2477375, lng: 19.253193 },
    { lat: 49.2478401, lng: 19.2530091 },
    { lat: 49.2480446, lng: 19.2530188 },
    { lat: 49.2483133, lng: 19.2530274 },
    { lat: 49.2484014, lng: 19.2530313 },
    { lat: 49.2486442, lng: 19.2529931 },
    { lat: 49.2488404, lng: 19.2529739 },
    { lat: 49.2488781, lng: 19.2529601 },
    { lat: 49.2490498, lng: 19.2529662 },
    { lat: 49.2490565, lng: 19.2529676 },
    { lat: 49.2493398, lng: 19.2530432 },
    { lat: 49.2494313, lng: 19.2529075 },
    { lat: 49.249519, lng: 19.2527851 },
    { lat: 49.2497525, lng: 19.2525325 },
    { lat: 49.2498471, lng: 19.2525306 },
    { lat: 49.2499448, lng: 19.2524434 },
    { lat: 49.2500841, lng: 19.2523505 },
    { lat: 49.2501761, lng: 19.2523872 },
    { lat: 49.2503129, lng: 19.2523488 },
    { lat: 49.2504082, lng: 19.2522938 },
    { lat: 49.2504627, lng: 19.2522527 },
    { lat: 49.2506459, lng: 19.2521031 },
    { lat: 49.2507972, lng: 19.2518236 },
    { lat: 49.2509121, lng: 19.2518704 },
    { lat: 49.2510295, lng: 19.2518728 },
    { lat: 49.2511361, lng: 19.2519832 },
    { lat: 49.2511888, lng: 19.2519787 },
    { lat: 49.2513581, lng: 19.2519332 },
    { lat: 49.2515324, lng: 19.2518794 },
    { lat: 49.2516202, lng: 19.2516907 },
    { lat: 49.251699, lng: 19.2516397 },
    { lat: 49.2517841, lng: 19.2515804 },
    { lat: 49.2519949, lng: 19.2515353 },
    { lat: 49.2522317, lng: 19.2515146 },
    { lat: 49.2524668, lng: 19.2515505 },
    { lat: 49.2525885, lng: 19.2514777 },
    { lat: 49.2529727, lng: 19.2515716 },
    { lat: 49.2531037, lng: 19.2515559 },
    { lat: 49.2532422, lng: 19.2518096 },
    { lat: 49.2533135, lng: 19.2517941 },
    { lat: 49.2534598, lng: 19.2517676 },
    { lat: 49.2535805, lng: 19.2516477 },
    { lat: 49.2536265, lng: 19.2516469 },
    { lat: 49.2537587, lng: 19.2515323 },
    { lat: 49.253794, lng: 19.2515402 },
    { lat: 49.2538257, lng: 19.2516157 },
    { lat: 49.2538572, lng: 19.2516854 },
    { lat: 49.2539431, lng: 19.2516899 },
    { lat: 49.2540994, lng: 19.251566 },
    { lat: 49.254231, lng: 19.2515618 },
    { lat: 49.2542686, lng: 19.2515223 },
    { lat: 49.2543956, lng: 19.2515853 },
    { lat: 49.2544274, lng: 19.2515406 },
    { lat: 49.2545201, lng: 19.2514293 },
    { lat: 49.2547303, lng: 19.2512557 },
    { lat: 49.2547949, lng: 19.2513077 },
    { lat: 49.2548632, lng: 19.2513686 },
    { lat: 49.2549636, lng: 19.2513246 },
    { lat: 49.2550324, lng: 19.2512069 },
    { lat: 49.2550833, lng: 19.2512095 },
    { lat: 49.2552187, lng: 19.2510977 },
    { lat: 49.2553105, lng: 19.2509882 },
    { lat: 49.2554138, lng: 19.2509352 },
    { lat: 49.2555966, lng: 19.250847 },
    { lat: 49.2557214, lng: 19.2509341 },
    { lat: 49.2558056, lng: 19.250902 },
    { lat: 49.2559171, lng: 19.250878 },
    { lat: 49.2559732, lng: 19.2508955 },
    { lat: 49.2560533, lng: 19.2508761 },
    { lat: 49.2562972, lng: 19.2507912 },
    { lat: 49.2563666, lng: 19.2506615 },
    { lat: 49.2564623, lng: 19.2505635 },
    { lat: 49.2565301, lng: 19.2505192 },
    { lat: 49.2565814, lng: 19.2504864 },
    { lat: 49.2566501, lng: 19.2504423 },
    { lat: 49.2567669, lng: 19.2506183 },
    { lat: 49.2567856, lng: 19.2506328 },
    { lat: 49.2570002, lng: 19.2506413 },
    { lat: 49.2571906, lng: 19.2504754 },
    { lat: 49.2572528, lng: 19.2503624 },
    { lat: 49.2574361, lng: 19.2503776 },
    { lat: 49.2575738, lng: 19.2502905 },
    { lat: 49.2577479, lng: 19.2500885 },
    { lat: 49.2578948, lng: 19.2499786 },
    { lat: 49.2580376, lng: 19.2499241 },
    { lat: 49.2582467, lng: 19.2499101 },
    { lat: 49.2583753, lng: 19.2498442 },
    { lat: 49.2584912, lng: 19.2497237 },
    { lat: 49.2586135, lng: 19.2496152 },
    { lat: 49.2587242, lng: 19.2495303 },
    { lat: 49.2587563, lng: 19.2493763 },
    { lat: 49.2587921, lng: 19.2492261 },
    { lat: 49.2587609, lng: 19.2491163 },
    { lat: 49.2587659, lng: 19.2489649 },
    { lat: 49.2588419, lng: 19.2488294 },
    { lat: 49.2588969, lng: 19.2487111 },
    { lat: 49.2589459, lng: 19.248601 },
    { lat: 49.2590604, lng: 19.2485002 },
    { lat: 49.2591187, lng: 19.2484698 },
    { lat: 49.2591946, lng: 19.2483592 },
    { lat: 49.259307, lng: 19.2482601 },
    { lat: 49.2594226, lng: 19.2482479 },
    { lat: 49.2595492, lng: 19.2482818 },
    { lat: 49.2615145, lng: 19.2474273 },
    { lat: 49.2615368, lng: 19.2474234 },
    { lat: 49.2625501, lng: 19.2472344 },
    { lat: 49.2632779, lng: 19.2470402 },
    { lat: 49.2636212, lng: 19.2482097 },
    { lat: 49.264034, lng: 19.2487367 },
    { lat: 49.2643479, lng: 19.249148 },
    { lat: 49.2651016, lng: 19.2486958 },
    { lat: 49.2667785, lng: 19.2478677 },
    { lat: 49.2672517, lng: 19.2476304 },
    { lat: 49.267707, lng: 19.2473923 },
    { lat: 49.2683196, lng: 19.2470564 },
    { lat: 49.2692178, lng: 19.2465837 },
    { lat: 49.269874, lng: 19.2462361 },
    { lat: 49.2688657, lng: 19.2418859 },
    { lat: 49.2689366, lng: 19.241931 },
    { lat: 49.270195, lng: 19.2427413 },
    { lat: 49.2730273, lng: 19.2454067 },
    { lat: 49.2786232, lng: 19.2459359 },
    { lat: 49.2793777, lng: 19.2457541 },
  ],
};

export default UnitsDolnýKubín;
