import clsx from "clsx";
import { motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";
import Awesome from "../../Images/awesome.png";
import Good from "../../Images/good.png";
import Meh from "../../Images/meh.png";
import Bad from "../../Images/bad.png";
import { activityService } from "../../services/activityService";

export default function Rating({ rating, setRating, subMenu, inputValues, setInputValues, previous, size }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const saveActivity = async (activity) => {
    const email = JSON.parse(localStorage.getItem("userData"))?.m;
    const request = JSON.parse(JSON.stringify({ email: email, activityId: activity }));
    return await activityService.saveActivity(request);
  };

  return subMenu?.rating ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-full flex flex-col items-start pt-1"
      initial={{ x: previous !== "sh" ? 500 : -500 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-base w-full flex flex-col items-center font-semibold gap-2">
        <button
          onClick={() => {
            setRating({ ...rating, awesome: true, good: false, meh: false, bad: false });
            saveActivity("61");
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-green-200 rounded-lg", {
            "!bg-green-400": rating.awesome,
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={Awesome} alt="Awesome" className="w-5 h-5" />
          Úžasné
        </button>
        <button
          onClick={() => {
            setRating({ ...rating, awesome: false, good: true, meh: false, bad: false });
            saveActivity("62");
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-lime-200 rounded-lg", {
            "!bg-lime-400": rating.good,
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={Good} alt="" className="w-5 h-5" />
          Dobré
        </button>
        <button
          onClick={() => {
            setRating({ ...rating, awesome: false, good: false, meh: true, bad: false });
            saveActivity("63");
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-orange-200 rounded-lg", {
            "!bg-orange-400": rating.meh,
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={Meh} alt="Meh" className="w-5 h-5" /> Priemerné
        </button>
        <button
          onClick={() => {
            setRating({ ...rating, awesome: false, good: false, meh: false, bad: true });
            saveActivity("64");
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-red-200 rounded-lg", {
            "!bg-red-400": rating.bad,
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={Bad} alt="" className="w-5 h-5" />
          Zlé
        </button>
        <div className={clsx("w-full", { "w-full px-4": isSmallDevice })}>
          {/**  "w-1/2": !isSmallDevice && size.full*/}
          <div className="w-full text-start">Recenzia</div>
          <textarea
            onClick={(event) => event.stopPropagation()}
            value={inputValues.review}
            onChange={(e) => setInputValues({ ...inputValues, review: e.target.value })}
            placeholder="Recenzia..."
            type="text"
            cols={37}
            rows={6}
            className={clsx("resize-none rounded-xl w-full px-2 border border-opacity-50 border-sky-500", {
              "py-2 ": isSmallDevice,
            })}
          ></textarea>
        </div>
        <button
          disabled={inputValues.review === ""}
          onClick={() => saveActivity("60")}
          className="disabled:opacity-60 disabled:bg-gray-300 w-full bg-sky-300 rounded-xl h-10 mt-2"
        >
          Odošli
        </button>
      </div>
    </motion.div>
  ) : null;
}
