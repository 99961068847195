const UnitsSenec = {
  Bernolákovo: [
    { lat: 48.1741746, lng: 17.3359826 },
    { lat: 48.1744022, lng: 17.3359221 },
    { lat: 48.1745368, lng: 17.3359083 },
    { lat: 48.1752197, lng: 17.3358838 },
    { lat: 48.1752729, lng: 17.3358845 },
    { lat: 48.1755999, lng: 17.3359275 },
    { lat: 48.1758666, lng: 17.3359467 },
    { lat: 48.1761211, lng: 17.3359523 },
    { lat: 48.1763627, lng: 17.3359649 },
    { lat: 48.1765762, lng: 17.3359655 },
    { lat: 48.1770085, lng: 17.3359442 },
    { lat: 48.177355, lng: 17.3359911 },
    { lat: 48.1775296, lng: 17.3360534 },
    { lat: 48.1777167, lng: 17.3361313 },
    { lat: 48.1779378, lng: 17.3362315 },
    { lat: 48.1781469, lng: 17.3363181 },
    { lat: 48.1784477, lng: 17.3366513 },
    { lat: 48.1786053, lng: 17.3368139 },
    { lat: 48.1788087, lng: 17.3370382 },
    { lat: 48.1788798, lng: 17.3371063 },
    { lat: 48.1791113, lng: 17.3373458 },
    { lat: 48.1793855, lng: 17.3376831 },
    { lat: 48.1795346, lng: 17.3378739 },
    { lat: 48.1798124, lng: 17.3382148 },
    { lat: 48.1802256, lng: 17.3387283 },
    { lat: 48.1803628, lng: 17.3389067 },
    { lat: 48.1810307, lng: 17.3397551 },
    { lat: 48.181339, lng: 17.3401497 },
    { lat: 48.1816987, lng: 17.3406019 },
    { lat: 48.1818607, lng: 17.3407977 },
    { lat: 48.1821156, lng: 17.3411192 },
    { lat: 48.1829619, lng: 17.3422219 },
    { lat: 48.183594, lng: 17.3429205 },
    { lat: 48.1837677, lng: 17.343122 },
    { lat: 48.1843207, lng: 17.3437494 },
    { lat: 48.1847779, lng: 17.3442455 },
    { lat: 48.1851325, lng: 17.3446224 },
    { lat: 48.1854964, lng: 17.3450217 },
    { lat: 48.1857419, lng: 17.3453015 },
    { lat: 48.1861857, lng: 17.345782 },
    { lat: 48.1865699, lng: 17.3462112 },
    { lat: 48.1872002, lng: 17.3463517 },
    { lat: 48.1874718, lng: 17.3464267 },
    { lat: 48.1877047, lng: 17.3464827 },
    { lat: 48.188255, lng: 17.3466233 },
    { lat: 48.188639, lng: 17.3467248 },
    { lat: 48.1889741, lng: 17.3468217 },
    { lat: 48.1891822, lng: 17.3468613 },
    { lat: 48.1896263, lng: 17.3469852 },
    { lat: 48.1900004, lng: 17.347073 },
    { lat: 48.1903062, lng: 17.3471539 },
    { lat: 48.1907174, lng: 17.3472574 },
    { lat: 48.1909904, lng: 17.3473212 },
    { lat: 48.1913029, lng: 17.3473996 },
    { lat: 48.1916226, lng: 17.3474894 },
    { lat: 48.1920811, lng: 17.3475893 },
    { lat: 48.1927577, lng: 17.3477611 },
    { lat: 48.1936101, lng: 17.347962 },
    { lat: 48.1939093, lng: 17.3480455 },
    { lat: 48.1941714, lng: 17.3480982 },
    { lat: 48.1944206, lng: 17.3481766 },
    { lat: 48.194805, lng: 17.3481826 },
    { lat: 48.1954728, lng: 17.3482028 },
    { lat: 48.1964682, lng: 17.3482398 },
    { lat: 48.1967278, lng: 17.348255 },
    { lat: 48.1972528, lng: 17.3482683 },
    { lat: 48.1974764, lng: 17.3482666 },
    { lat: 48.1978773, lng: 17.3482998 },
    { lat: 48.1981419, lng: 17.3483395 },
    { lat: 48.1985591, lng: 17.3483974 },
    { lat: 48.1989546, lng: 17.3484676 },
    { lat: 48.199627, lng: 17.3485751 },
    { lat: 48.199868, lng: 17.3486157 },
    { lat: 48.200089, lng: 17.3486432 },
    { lat: 48.2005324, lng: 17.3487217 },
    { lat: 48.200749, lng: 17.3487532 },
    { lat: 48.2019234, lng: 17.3489373 },
    { lat: 48.2024887, lng: 17.3490301 },
    { lat: 48.2033664, lng: 17.3491697 },
    { lat: 48.2038872, lng: 17.3492572 },
    { lat: 48.2040275, lng: 17.3492424 },
    { lat: 48.2043161, lng: 17.3490439 },
    { lat: 48.2047703, lng: 17.3489424 },
    { lat: 48.2049402, lng: 17.3488304 },
    { lat: 48.2052026, lng: 17.3486964 },
    { lat: 48.2057662, lng: 17.3484201 },
    { lat: 48.2062992, lng: 17.348161 },
    { lat: 48.2066871, lng: 17.3479781 },
    { lat: 48.2069779, lng: 17.3478476 },
    { lat: 48.2082144, lng: 17.3472737 },
    { lat: 48.2084403, lng: 17.3471713 },
    { lat: 48.2089817, lng: 17.3469524 },
    { lat: 48.2091751, lng: 17.3468698 },
    { lat: 48.2097969, lng: 17.3465343 },
    { lat: 48.210903, lng: 17.3459526 },
    { lat: 48.2111437, lng: 17.3458336 },
    { lat: 48.2117078, lng: 17.3455478 },
    { lat: 48.2121287, lng: 17.345317 },
    { lat: 48.2123071, lng: 17.3452299 },
    { lat: 48.2124995, lng: 17.3451494 },
    { lat: 48.2128814, lng: 17.3449969 },
    { lat: 48.213045, lng: 17.3449433 },
    { lat: 48.2133587, lng: 17.3447661 },
    { lat: 48.2135365, lng: 17.3446529 },
    { lat: 48.2138393, lng: 17.3444461 },
    { lat: 48.2143842, lng: 17.3440569 },
    { lat: 48.2148929, lng: 17.3436346 },
    { lat: 48.2152513, lng: 17.3433453 },
    { lat: 48.2153731, lng: 17.3432403 },
    { lat: 48.2156382, lng: 17.3430272 },
    { lat: 48.2158003, lng: 17.3428826 },
    { lat: 48.2162441, lng: 17.3425369 },
    { lat: 48.2165136, lng: 17.3423223 },
    { lat: 48.216734, lng: 17.3421344 },
    { lat: 48.2168936, lng: 17.3419841 },
    { lat: 48.2170109, lng: 17.3418629 },
    { lat: 48.2174626, lng: 17.3413443 },
    { lat: 48.2177432, lng: 17.3410073 },
    { lat: 48.2180274, lng: 17.3406908 },
    { lat: 48.2181509, lng: 17.3405239 },
    { lat: 48.2182919, lng: 17.3403493 },
    { lat: 48.2185997, lng: 17.3399466 },
    { lat: 48.2187806, lng: 17.3396892 },
    { lat: 48.2188885, lng: 17.3395448 },
    { lat: 48.2191379, lng: 17.339198 },
    { lat: 48.2192242, lng: 17.3390323 },
    { lat: 48.2193105, lng: 17.3388111 },
    { lat: 48.2193848, lng: 17.3386208 },
    { lat: 48.2194613, lng: 17.3384447 },
    { lat: 48.2195104, lng: 17.3383673 },
    { lat: 48.21957, lng: 17.3382445 },
    { lat: 48.2197483, lng: 17.3378447 },
    { lat: 48.2200108, lng: 17.3372677 },
    { lat: 48.2200864, lng: 17.3371121 },
    { lat: 48.2202999, lng: 17.336784 },
    { lat: 48.2204027, lng: 17.3366649 },
    { lat: 48.2205981, lng: 17.3364231 },
    { lat: 48.220684, lng: 17.336304 },
    { lat: 48.2208315, lng: 17.3359527 },
    { lat: 48.2209333, lng: 17.3356975 },
    { lat: 48.2210516, lng: 17.3355383 },
    { lat: 48.2211398, lng: 17.3354551 },
    { lat: 48.221239, lng: 17.335312 },
    { lat: 48.2213715, lng: 17.3350955 },
    { lat: 48.2216558, lng: 17.3347292 },
    { lat: 48.2217803, lng: 17.334595 },
    { lat: 48.2220123, lng: 17.3343774 },
    { lat: 48.2220872, lng: 17.3342989 },
    { lat: 48.2223886, lng: 17.3338664 },
    { lat: 48.2225552, lng: 17.333547 },
    { lat: 48.2227535, lng: 17.3331282 },
    { lat: 48.2229588, lng: 17.3327961 },
    { lat: 48.2232015, lng: 17.3323817 },
    { lat: 48.2234138, lng: 17.3321062 },
    { lat: 48.2237369, lng: 17.3316936 },
    { lat: 48.223823, lng: 17.3315776 },
    { lat: 48.2240245, lng: 17.331275 },
    { lat: 48.2242083, lng: 17.331029 },
    { lat: 48.2243335, lng: 17.3308728 },
    { lat: 48.2245418, lng: 17.3305869 },
    { lat: 48.2246562, lng: 17.3304196 },
    { lat: 48.2248687, lng: 17.3300957 },
    { lat: 48.2249292, lng: 17.3299676 },
    { lat: 48.2252647, lng: 17.3293317 },
    { lat: 48.2255051, lng: 17.3290375 },
    { lat: 48.2258785, lng: 17.3287382 },
    { lat: 48.2260524, lng: 17.3285486 },
    { lat: 48.2261267, lng: 17.3284592 },
    { lat: 48.2262428, lng: 17.3283 },
    { lat: 48.2262951, lng: 17.3282712 },
    { lat: 48.2264521, lng: 17.3281152 },
    { lat: 48.2270986, lng: 17.3277275 },
    { lat: 48.2273335, lng: 17.3275551 },
    { lat: 48.2276675, lng: 17.3272396 },
    { lat: 48.2278726, lng: 17.3271121 },
    { lat: 48.228036, lng: 17.3269875 },
    { lat: 48.2281641, lng: 17.3268731 },
    { lat: 48.2282324, lng: 17.3267962 },
    { lat: 48.2283723, lng: 17.3266741 },
    { lat: 48.2285271, lng: 17.3265525 },
    { lat: 48.2288803, lng: 17.3262882 },
    { lat: 48.2292762, lng: 17.325973 },
    { lat: 48.2294672, lng: 17.3257503 },
    { lat: 48.2300318, lng: 17.3248538 },
    { lat: 48.230167, lng: 17.3246091 },
    { lat: 48.2302524, lng: 17.324413 },
    { lat: 48.2303484, lng: 17.3241393 },
    { lat: 48.2304197, lng: 17.3238845 },
    { lat: 48.2294871, lng: 17.3234261 },
    { lat: 48.2282974, lng: 17.3228737 },
    { lat: 48.2283673, lng: 17.3224601 },
    { lat: 48.228439, lng: 17.3221774 },
    { lat: 48.2285936, lng: 17.3217277 },
    { lat: 48.2287445, lng: 17.3213774 },
    { lat: 48.2289034, lng: 17.3211148 },
    { lat: 48.2290541, lng: 17.3209675 },
    { lat: 48.2294399, lng: 17.3203764 },
    { lat: 48.2296666, lng: 17.3199075 },
    { lat: 48.2297086, lng: 17.3198083 },
    { lat: 48.2298304, lng: 17.3194098 },
    { lat: 48.2300704, lng: 17.3185959 },
    { lat: 48.2302432, lng: 17.3179778 },
    { lat: 48.2303151, lng: 17.3176806 },
    { lat: 48.2304133, lng: 17.3173711 },
    { lat: 48.2301481, lng: 17.3171524 },
    { lat: 48.2298614, lng: 17.3169327 },
    { lat: 48.2296594, lng: 17.3167985 },
    { lat: 48.2288208, lng: 17.3161403 },
    { lat: 48.2286134, lng: 17.3160273 },
    { lat: 48.228827, lng: 17.3156153 },
    { lat: 48.228962, lng: 17.3151803 },
    { lat: 48.2290331, lng: 17.3150084 },
    { lat: 48.2291973, lng: 17.3146714 },
    { lat: 48.2296999, lng: 17.3138473 },
    { lat: 48.2300768, lng: 17.3131052 },
    { lat: 48.2302396, lng: 17.3128309 },
    { lat: 48.2299967, lng: 17.3125913 },
    { lat: 48.2299323, lng: 17.3125399 },
    { lat: 48.2294956, lng: 17.3121707 },
    { lat: 48.2293284, lng: 17.3120331 },
    { lat: 48.2289974, lng: 17.3117414 },
    { lat: 48.2289177, lng: 17.3116465 },
    { lat: 48.2287872, lng: 17.3114502 },
    { lat: 48.2282685, lng: 17.3108794 },
    { lat: 48.2276988, lng: 17.3101723 },
    { lat: 48.2275931, lng: 17.3099225 },
    { lat: 48.22741, lng: 17.3095953 },
    { lat: 48.2271226, lng: 17.3091958 },
    { lat: 48.2264731, lng: 17.308202 },
    { lat: 48.2263255, lng: 17.3078823 },
    { lat: 48.2262003, lng: 17.3075441 },
    { lat: 48.2260486, lng: 17.3070923 },
    { lat: 48.2257642, lng: 17.3061717 },
    { lat: 48.2255695, lng: 17.3053948 },
    { lat: 48.2251427, lng: 17.3047657 },
    { lat: 48.2250413, lng: 17.3045472 },
    { lat: 48.2250755, lng: 17.3043631 },
    { lat: 48.2247751, lng: 17.3045359 },
    { lat: 48.2244842, lng: 17.3046457 },
    { lat: 48.2241285, lng: 17.3047693 },
    { lat: 48.2239233, lng: 17.3048268 },
    { lat: 48.2237476, lng: 17.3048695 },
    { lat: 48.2232839, lng: 17.3049625 },
    { lat: 48.2226694, lng: 17.3050991 },
    { lat: 48.2224488, lng: 17.3051283 },
    { lat: 48.2223281, lng: 17.305115 },
    { lat: 48.2227889, lng: 17.3030757 },
    { lat: 48.223046, lng: 17.3019574 },
    { lat: 48.2228025, lng: 17.301777 },
    { lat: 48.2225466, lng: 17.3015976 },
    { lat: 48.2218614, lng: 17.3012966 },
    { lat: 48.2221029, lng: 17.2999753 },
    { lat: 48.2221974, lng: 17.2993882 },
    { lat: 48.2222652, lng: 17.2988238 },
    { lat: 48.22233, lng: 17.2980958 },
    { lat: 48.222352, lng: 17.2977273 },
    { lat: 48.2222552, lng: 17.2977163 },
    { lat: 48.2222548, lng: 17.2969235 },
    { lat: 48.2222238, lng: 17.2963854 },
    { lat: 48.2221833, lng: 17.2957896 },
    { lat: 48.2220685, lng: 17.2945488 },
    { lat: 48.2216895, lng: 17.2913796 },
    { lat: 48.2216444, lng: 17.291261 },
    { lat: 48.2220948, lng: 17.2897862 },
    { lat: 48.2221919, lng: 17.2894776 },
    { lat: 48.2225362, lng: 17.2883134 },
    { lat: 48.2230303, lng: 17.286783 },
    { lat: 48.2233584, lng: 17.2858689 },
    { lat: 48.223494, lng: 17.2853577 },
    { lat: 48.2235977, lng: 17.2849096 },
    { lat: 48.2236705, lng: 17.2847466 },
    { lat: 48.223737, lng: 17.2846404 },
    { lat: 48.2238048, lng: 17.2845746 },
    { lat: 48.2239504, lng: 17.2845707 },
    { lat: 48.2240993, lng: 17.284552 },
    { lat: 48.22391, lng: 17.2842867 },
    { lat: 48.223817, lng: 17.2841432 },
    { lat: 48.2237188, lng: 17.2839406 },
    { lat: 48.2235144, lng: 17.2836852 },
    { lat: 48.2233537, lng: 17.2834757 },
    { lat: 48.222948, lng: 17.2829282 },
    { lat: 48.2222676, lng: 17.2818643 },
    { lat: 48.2220616, lng: 17.2814485 },
    { lat: 48.2220276, lng: 17.2813444 },
    { lat: 48.2220158, lng: 17.2812346 },
    { lat: 48.221879, lng: 17.2797318 },
    { lat: 48.2218266, lng: 17.2791961 },
    { lat: 48.2217696, lng: 17.2784548 },
    { lat: 48.2216876, lng: 17.2778143 },
    { lat: 48.221639, lng: 17.2774564 },
    { lat: 48.2214802, lng: 17.2766955 },
    { lat: 48.2214265, lng: 17.2764821 },
    { lat: 48.2212896, lng: 17.276138 },
    { lat: 48.2211612, lng: 17.2758039 },
    { lat: 48.2208089, lng: 17.2752779 },
    { lat: 48.2204173, lng: 17.2746862 },
    { lat: 48.2202387, lng: 17.2744109 },
    { lat: 48.220114, lng: 17.2740811 },
    { lat: 48.2199424, lng: 17.2736408 },
    { lat: 48.2198031, lng: 17.2732947 },
    { lat: 48.2196932, lng: 17.2729991 },
    { lat: 48.2196165, lng: 17.2724713 },
    { lat: 48.2195739, lng: 17.2721547 },
    { lat: 48.2194912, lng: 17.2718441 },
    { lat: 48.2194169, lng: 17.2715408 },
    { lat: 48.2192899, lng: 17.2710566 },
    { lat: 48.2192549, lng: 17.2709359 },
    { lat: 48.2191642, lng: 17.2706612 },
    { lat: 48.218992, lng: 17.2701087 },
    { lat: 48.2188654, lng: 17.2696641 },
    { lat: 48.2186556, lng: 17.2688342 },
    { lat: 48.2185705, lng: 17.2685213 },
    { lat: 48.2185447, lng: 17.2682513 },
    { lat: 48.2185053, lng: 17.2681007 },
    { lat: 48.2183153, lng: 17.2673311 },
    { lat: 48.2180163, lng: 17.2661449 },
    { lat: 48.2178765, lng: 17.2655699 },
    { lat: 48.2177473, lng: 17.2650186 },
    { lat: 48.2175934, lng: 17.2643854 },
    { lat: 48.2174522, lng: 17.2637878 },
    { lat: 48.2172736, lng: 17.2630054 },
    { lat: 48.2168371, lng: 17.2613986 },
    { lat: 48.2167128, lng: 17.2594935 },
    { lat: 48.2165454, lng: 17.2587239 },
    { lat: 48.216519, lng: 17.2586677 },
    { lat: 48.2160547, lng: 17.2579187 },
    { lat: 48.2158273, lng: 17.2575062 },
    { lat: 48.2154362, lng: 17.2567717 },
    { lat: 48.2153003, lng: 17.2565121 },
    { lat: 48.2151383, lng: 17.2562262 },
    { lat: 48.2149778, lng: 17.255943 },
    { lat: 48.2147894, lng: 17.2556222 },
    { lat: 48.2146561, lng: 17.2554036 },
    { lat: 48.2143374, lng: 17.2551788 },
    { lat: 48.2140428, lng: 17.2549802 },
    { lat: 48.2137269, lng: 17.2547812 },
    { lat: 48.2132088, lng: 17.2544644 },
    { lat: 48.2129883, lng: 17.2543256 },
    { lat: 48.2126267, lng: 17.2542214 },
    { lat: 48.2124429, lng: 17.2543122 },
    { lat: 48.2113605, lng: 17.2548705 },
    { lat: 48.2112914, lng: 17.2549027 },
    { lat: 48.2108578, lng: 17.2551269 },
    { lat: 48.2106471, lng: 17.2552867 },
    { lat: 48.210534, lng: 17.2556255 },
    { lat: 48.2104215, lng: 17.2558528 },
    { lat: 48.2096999, lng: 17.2563336 },
    { lat: 48.2094596, lng: 17.2562367 },
    { lat: 48.2090856, lng: 17.2560658 },
    { lat: 48.2090287, lng: 17.2562627 },
    { lat: 48.2089002, lng: 17.2565733 },
    { lat: 48.2088877, lng: 17.2566056 },
    { lat: 48.208761, lng: 17.2571831 },
    { lat: 48.2083909, lng: 17.2579538 },
    { lat: 48.2080718, lng: 17.2585743 },
    { lat: 48.2076611, lng: 17.2592624 },
    { lat: 48.2074607, lng: 17.2595634 },
    { lat: 48.2072222, lng: 17.2599513 },
    { lat: 48.2068308, lng: 17.2606069 },
    { lat: 48.2065057, lng: 17.2613092 },
    { lat: 48.2060673, lng: 17.2622253 },
    { lat: 48.2055779, lng: 17.2632605 },
    { lat: 48.204505, lng: 17.2655797 },
    { lat: 48.2039119, lng: 17.2670071 },
    { lat: 48.2034444, lng: 17.2681522 },
    { lat: 48.2031224, lng: 17.2692071 },
    { lat: 48.2028904, lng: 17.2699844 },
    { lat: 48.20272, lng: 17.2703958 },
    { lat: 48.2024123, lng: 17.2712914 },
    { lat: 48.2021901, lng: 17.2719607 },
    { lat: 48.2020888, lng: 17.2720187 },
    { lat: 48.2019302, lng: 17.2720965 },
    { lat: 48.2016624, lng: 17.2729135 },
    { lat: 48.2012852, lng: 17.2737848 },
    { lat: 48.2008658, lng: 17.2747342 },
    { lat: 48.1999915, lng: 17.2764049 },
    { lat: 48.19994, lng: 17.276514 },
    { lat: 48.1997199, lng: 17.2769075 },
    { lat: 48.1995902, lng: 17.2771514 },
    { lat: 48.1994683, lng: 17.2772709 },
    { lat: 48.1989205, lng: 17.2779047 },
    { lat: 48.1987075, lng: 17.27803 },
    { lat: 48.1984752, lng: 17.2781726 },
    { lat: 48.1970374, lng: 17.2780749 },
    { lat: 48.1968765, lng: 17.2780667 },
    { lat: 48.1964054, lng: 17.2780326 },
    { lat: 48.196138, lng: 17.2780029 },
    { lat: 48.1960087, lng: 17.2780467 },
    { lat: 48.1958088, lng: 17.2779968 },
    { lat: 48.195364, lng: 17.2779665 },
    { lat: 48.1949916, lng: 17.2779034 },
    { lat: 48.1944859, lng: 17.278029 },
    { lat: 48.1940064, lng: 17.2783122 },
    { lat: 48.1937024, lng: 17.2785518 },
    { lat: 48.1936103, lng: 17.2786244 },
    { lat: 48.1934488, lng: 17.2786754 },
    { lat: 48.1929913, lng: 17.2790346 },
    { lat: 48.1929551, lng: 17.2791363 },
    { lat: 48.1927839, lng: 17.2792799 },
    { lat: 48.1923398, lng: 17.2796195 },
    { lat: 48.1921535, lng: 17.2798253 },
    { lat: 48.1919422, lng: 17.2801659 },
    { lat: 48.1915077, lng: 17.2807176 },
    { lat: 48.1913851, lng: 17.2809132 },
    { lat: 48.1911277, lng: 17.281274 },
    { lat: 48.1909928, lng: 17.2815045 },
    { lat: 48.1905053, lng: 17.2819738 },
    { lat: 48.1901667, lng: 17.2823372 },
    { lat: 48.1899016, lng: 17.2825987 },
    { lat: 48.1896786, lng: 17.2828124 },
    { lat: 48.1895444, lng: 17.2829346 },
    { lat: 48.1893088, lng: 17.283114 },
    { lat: 48.1891082, lng: 17.2832915 },
    { lat: 48.1890479, lng: 17.2833528 },
    { lat: 48.1888464, lng: 17.2835347 },
    { lat: 48.1884225, lng: 17.2838703 },
    { lat: 48.1882609, lng: 17.283988 },
    { lat: 48.1881672, lng: 17.2840248 },
    { lat: 48.1879946, lng: 17.2841634 },
    { lat: 48.18768, lng: 17.2845132 },
    { lat: 48.1874795, lng: 17.2846934 },
    { lat: 48.187167, lng: 17.2849052 },
    { lat: 48.1869977, lng: 17.2848559 },
    { lat: 48.1868304, lng: 17.2848715 },
    { lat: 48.1866217, lng: 17.2849935 },
    { lat: 48.1863383, lng: 17.2851513 },
    { lat: 48.1859338, lng: 17.2854552 },
    { lat: 48.1856824, lng: 17.2856851 },
    { lat: 48.1854967, lng: 17.285868 },
    { lat: 48.1853106, lng: 17.2860433 },
    { lat: 48.1846268, lng: 17.2867021 },
    { lat: 48.1843899, lng: 17.2869273 },
    { lat: 48.1846532, lng: 17.2873578 },
    { lat: 48.1848514, lng: 17.2875701 },
    { lat: 48.1851444, lng: 17.287694 },
    { lat: 48.1854148, lng: 17.2877865 },
    { lat: 48.1856585, lng: 17.2878509 },
    { lat: 48.1857902, lng: 17.2879269 },
    { lat: 48.186063, lng: 17.2880217 },
    { lat: 48.1864087, lng: 17.2882762 },
    { lat: 48.1865644, lng: 17.2885319 },
    { lat: 48.1866129, lng: 17.288649 },
    { lat: 48.1866737, lng: 17.2890402 },
    { lat: 48.1865684, lng: 17.2895804 },
    { lat: 48.1864146, lng: 17.2899536 },
    { lat: 48.1862905, lng: 17.2902145 },
    { lat: 48.186188, lng: 17.2905252 },
    { lat: 48.1861224, lng: 17.2904894 },
    { lat: 48.1861074, lng: 17.2904679 },
    { lat: 48.186095, lng: 17.2904689 },
    { lat: 48.1859006, lng: 17.2905863 },
    { lat: 48.1856572, lng: 17.2906824 },
    { lat: 48.1855668, lng: 17.2906957 },
    { lat: 48.1855021, lng: 17.2907595 },
    { lat: 48.1854415, lng: 17.2907844 },
    { lat: 48.185337, lng: 17.2908936 },
    { lat: 48.1852387, lng: 17.2910307 },
    { lat: 48.1850652, lng: 17.2912937 },
    { lat: 48.1850138, lng: 17.2913882 },
    { lat: 48.1847259, lng: 17.2915609 },
    { lat: 48.1845205, lng: 17.2915812 },
    { lat: 48.1844686, lng: 17.2916851 },
    { lat: 48.1842559, lng: 17.2914591 },
    { lat: 48.1839101, lng: 17.2911487 },
    { lat: 48.1836918, lng: 17.2907858 },
    { lat: 48.1834778, lng: 17.2907438 },
    { lat: 48.1832928, lng: 17.2908668 },
    { lat: 48.1830221, lng: 17.2910613 },
    { lat: 48.1828338, lng: 17.2911526 },
    { lat: 48.1824885, lng: 17.2915001 },
    { lat: 48.1822865, lng: 17.2918273 },
    { lat: 48.1821719, lng: 17.2920579 },
    { lat: 48.1819565, lng: 17.2925078 },
    { lat: 48.1819229, lng: 17.292648 },
    { lat: 48.1818659, lng: 17.2929656 },
    { lat: 48.1818353, lng: 17.2932023 },
    { lat: 48.1818275, lng: 17.2935801 },
    { lat: 48.181871, lng: 17.2940855 },
    { lat: 48.181882, lng: 17.2943702 },
    { lat: 48.1819166, lng: 17.2948752 },
    { lat: 48.1819452, lng: 17.295599 },
    { lat: 48.1820134, lng: 17.2962392 },
    { lat: 48.1820684, lng: 17.2967269 },
    { lat: 48.1821762, lng: 17.2973334 },
    { lat: 48.182185, lng: 17.2977746 },
    { lat: 48.1821636, lng: 17.2981333 },
    { lat: 48.1820609, lng: 17.2986131 },
    { lat: 48.1818803, lng: 17.2989405 },
    { lat: 48.1816154, lng: 17.2993242 },
    { lat: 48.1815412, lng: 17.2993968 },
    { lat: 48.1812762, lng: 17.2996793 },
    { lat: 48.1809345, lng: 17.3000642 },
    { lat: 48.1808156, lng: 17.3002464 },
    { lat: 48.1806363, lng: 17.3004231 },
    { lat: 48.1802144, lng: 17.3005345 },
    { lat: 48.1799618, lng: 17.3006437 },
    { lat: 48.1797471, lng: 17.300829 },
    { lat: 48.1795696, lng: 17.3011188 },
    { lat: 48.1793574, lng: 17.3015818 },
    { lat: 48.1792782, lng: 17.301786 },
    { lat: 48.179172, lng: 17.3019394 },
    { lat: 48.1790618, lng: 17.3021348 },
    { lat: 48.178926, lng: 17.3023212 },
    { lat: 48.178792, lng: 17.3024634 },
    { lat: 48.1787131, lng: 17.3025848 },
    { lat: 48.1785762, lng: 17.3025654 },
    { lat: 48.1785362, lng: 17.3027115 },
    { lat: 48.1784096, lng: 17.3029498 },
    { lat: 48.1782262, lng: 17.3032183 },
    { lat: 48.1780752, lng: 17.303462 },
    { lat: 48.1779428, lng: 17.3036302 },
    { lat: 48.1778014, lng: 17.3037962 },
    { lat: 48.1775449, lng: 17.3040713 },
    { lat: 48.1773896, lng: 17.3042682 },
    { lat: 48.1769791, lng: 17.3047224 },
    { lat: 48.1767538, lng: 17.3049542 },
    { lat: 48.1766862, lng: 17.3050435 },
    { lat: 48.1764695, lng: 17.305285 },
    { lat: 48.1763205, lng: 17.3054228 },
    { lat: 48.1761701, lng: 17.305608 },
    { lat: 48.1760643, lng: 17.3056843 },
    { lat: 48.1758033, lng: 17.3059391 },
    { lat: 48.1756685, lng: 17.3061025 },
    { lat: 48.1755658, lng: 17.3062435 },
    { lat: 48.1752663, lng: 17.3067007 },
    { lat: 48.1750601, lng: 17.3071027 },
    { lat: 48.1749898, lng: 17.3072195 },
    { lat: 48.174913, lng: 17.3073904 },
    { lat: 48.17474, lng: 17.307866 },
    { lat: 48.1744746, lng: 17.308655 },
    { lat: 48.1744101, lng: 17.3088589 },
    { lat: 48.1742784, lng: 17.3091738 },
    { lat: 48.1741759, lng: 17.3093831 },
    { lat: 48.1740512, lng: 17.3096143 },
    { lat: 48.1737876, lng: 17.3101582 },
    { lat: 48.1737162, lng: 17.3104271 },
    { lat: 48.1736378, lng: 17.3107638 },
    { lat: 48.1736271, lng: 17.310892 },
    { lat: 48.1735632, lng: 17.3113449 },
    { lat: 48.1735441, lng: 17.3116356 },
    { lat: 48.1734672, lng: 17.3123899 },
    { lat: 48.173477, lng: 17.3124688 },
    { lat: 48.1735467, lng: 17.3128731 },
    { lat: 48.173584, lng: 17.3130465 },
    { lat: 48.1736098, lng: 17.3132489 },
    { lat: 48.1736708, lng: 17.3135219 },
    { lat: 48.1737278, lng: 17.3137533 },
    { lat: 48.173804, lng: 17.3140174 },
    { lat: 48.1739196, lng: 17.3143491 },
    { lat: 48.1740567, lng: 17.3146295 },
    { lat: 48.1741967, lng: 17.3149001 },
    { lat: 48.1744175, lng: 17.3151825 },
    { lat: 48.1746066, lng: 17.3154309 },
    { lat: 48.1747194, lng: 17.3155645 },
    { lat: 48.1748621, lng: 17.3157073 },
    { lat: 48.1751578, lng: 17.3159778 },
    { lat: 48.1754158, lng: 17.3162234 },
    { lat: 48.1756056, lng: 17.3164293 },
    { lat: 48.1757237, lng: 17.3166264 },
    { lat: 48.1758486, lng: 17.3168766 },
    { lat: 48.1759462, lng: 17.3171933 },
    { lat: 48.1759905, lng: 17.3173834 },
    { lat: 48.1760447, lng: 17.3176921 },
    { lat: 48.1760581, lng: 17.3180136 },
    { lat: 48.1760474, lng: 17.3181444 },
    { lat: 48.1759999, lng: 17.3184849 },
    { lat: 48.1759771, lng: 17.3186208 },
    { lat: 48.1758531, lng: 17.3191712 },
    { lat: 48.1757939, lng: 17.3194045 },
    { lat: 48.1757379, lng: 17.3196019 },
    { lat: 48.1756571, lng: 17.3198493 },
    { lat: 48.1756249, lng: 17.320028 },
    { lat: 48.1756205, lng: 17.3201335 },
    { lat: 48.1755622, lng: 17.3203287 },
    { lat: 48.1754729, lng: 17.3204297 },
    { lat: 48.1752134, lng: 17.3206741 },
    { lat: 48.1749458, lng: 17.3209329 },
    { lat: 48.1748371, lng: 17.3209811 },
    { lat: 48.1744508, lng: 17.3211688 },
    { lat: 48.1742981, lng: 17.3212016 },
    { lat: 48.1741038, lng: 17.3212707 },
    { lat: 48.1739262, lng: 17.3213703 },
    { lat: 48.1737857, lng: 17.3214146 },
    { lat: 48.1734592, lng: 17.3214998 },
    { lat: 48.1732164, lng: 17.321517 },
    { lat: 48.173068, lng: 17.3215431 },
    { lat: 48.1727885, lng: 17.3215548 },
    { lat: 48.1726681, lng: 17.3215286 },
    { lat: 48.1724482, lng: 17.3214986 },
    { lat: 48.172243, lng: 17.3213674 },
    { lat: 48.1720684, lng: 17.3212895 },
    { lat: 48.1720165, lng: 17.321151 },
    { lat: 48.1718332, lng: 17.3210631 },
    { lat: 48.1715877, lng: 17.3209195 },
    { lat: 48.1714369, lng: 17.3208428 },
    { lat: 48.1712696, lng: 17.3207737 },
    { lat: 48.1710089, lng: 17.3206214 },
    { lat: 48.1705656, lng: 17.3202881 },
    { lat: 48.1702816, lng: 17.3200412 },
    { lat: 48.1700034, lng: 17.3197115 },
    { lat: 48.1697665, lng: 17.3195277 },
    { lat: 48.1696778, lng: 17.3194497 },
    { lat: 48.169621, lng: 17.3193264 },
    { lat: 48.1693633, lng: 17.3187083 },
    { lat: 48.1693048, lng: 17.3187971 },
    { lat: 48.1690531, lng: 17.3182862 },
    { lat: 48.1689875, lng: 17.3181135 },
    { lat: 48.168808, lng: 17.3175844 },
    { lat: 48.1687544, lng: 17.317404 },
    { lat: 48.1686414, lng: 17.3168886 },
    { lat: 48.1685497, lng: 17.3164243 },
    { lat: 48.1685042, lng: 17.3161456 },
    { lat: 48.1684583, lng: 17.3157244 },
    { lat: 48.168445, lng: 17.3155052 },
    { lat: 48.1683834, lng: 17.3148463 },
    { lat: 48.1683054, lng: 17.314211 },
    { lat: 48.1682682, lng: 17.3138872 },
    { lat: 48.1682147, lng: 17.3136764 },
    { lat: 48.1681468, lng: 17.3133817 },
    { lat: 48.1680748, lng: 17.3130967 },
    { lat: 48.1680355, lng: 17.3129624 },
    { lat: 48.167902, lng: 17.3126351 },
    { lat: 48.1678646, lng: 17.3125292 },
    { lat: 48.1677514, lng: 17.312269 },
    { lat: 48.1676017, lng: 17.3120377 },
    { lat: 48.167386, lng: 17.3117318 },
    { lat: 48.1669948, lng: 17.3113993 },
    { lat: 48.1666394, lng: 17.3112011 },
    { lat: 48.1659828, lng: 17.3110607 },
    { lat: 48.1657759, lng: 17.3110397 },
    { lat: 48.1655979, lng: 17.3110828 },
    { lat: 48.1652737, lng: 17.3111855 },
    { lat: 48.1650197, lng: 17.3112898 },
    { lat: 48.1647659, lng: 17.3113989 },
    { lat: 48.1646777, lng: 17.3114482 },
    { lat: 48.1644495, lng: 17.3116195 },
    { lat: 48.1643382, lng: 17.3117127 },
    { lat: 48.1641073, lng: 17.3119613 },
    { lat: 48.1639338, lng: 17.3122267 },
    { lat: 48.1638716, lng: 17.3123784 },
    { lat: 48.163785, lng: 17.3127088 },
    { lat: 48.1637737, lng: 17.3128978 },
    { lat: 48.1637337, lng: 17.3133014 },
    { lat: 48.1636554, lng: 17.3138626 },
    { lat: 48.1635596, lng: 17.3143098 },
    { lat: 48.1634981, lng: 17.3145754 },
    { lat: 48.1634266, lng: 17.314863 },
    { lat: 48.1633749, lng: 17.3151222 },
    { lat: 48.1632908, lng: 17.3157322 },
    { lat: 48.1632743, lng: 17.3161652 },
    { lat: 48.1632768, lng: 17.3165296 },
    { lat: 48.1632916, lng: 17.3168728 },
    { lat: 48.1633175, lng: 17.3170445 },
    { lat: 48.163385, lng: 17.3173485 },
    { lat: 48.1635601, lng: 17.317983 },
    { lat: 48.1636689, lng: 17.3183646 },
    { lat: 48.1637383, lng: 17.3185773 },
    { lat: 48.1638473, lng: 17.3188938 },
    { lat: 48.1639239, lng: 17.3190784 },
    { lat: 48.1640321, lng: 17.3192643 },
    { lat: 48.164165, lng: 17.3194278 },
    { lat: 48.1644583, lng: 17.3197147 },
    { lat: 48.164692, lng: 17.3197976 },
    { lat: 48.1648233, lng: 17.3198534 },
    { lat: 48.1648932, lng: 17.3198331 },
    { lat: 48.1650572, lng: 17.3198367 },
    { lat: 48.165198, lng: 17.319762 },
    { lat: 48.1652294, lng: 17.3197768 },
    { lat: 48.1652843, lng: 17.3197864 },
    { lat: 48.1653212, lng: 17.3197624 },
    { lat: 48.165352, lng: 17.319752 },
    { lat: 48.1653873, lng: 17.319636 },
    { lat: 48.1654732, lng: 17.3194461 },
    { lat: 48.165825, lng: 17.3187358 },
    { lat: 48.1659308, lng: 17.3185935 },
    { lat: 48.1661629, lng: 17.3183263 },
    { lat: 48.1662726, lng: 17.3182434 },
    { lat: 48.1665846, lng: 17.3180227 },
    { lat: 48.1668206, lng: 17.3179888 },
    { lat: 48.1670754, lng: 17.3180828 },
    { lat: 48.1671657, lng: 17.3181337 },
    { lat: 48.1674048, lng: 17.3183333 },
    { lat: 48.1676263, lng: 17.3185919 },
    { lat: 48.1679205, lng: 17.3189935 },
    { lat: 48.1680386, lng: 17.3191905 },
    { lat: 48.1682022, lng: 17.3195303 },
    { lat: 48.1682681, lng: 17.3198465 },
    { lat: 48.1687008, lng: 17.3205326 },
    { lat: 48.1690224, lng: 17.3208516 },
    { lat: 48.1692512, lng: 17.3210678 },
    { lat: 48.1694541, lng: 17.3212668 },
    { lat: 48.1697329, lng: 17.3215026 },
    { lat: 48.1697996, lng: 17.3215714 },
    { lat: 48.1700616, lng: 17.3217776 },
    { lat: 48.1702029, lng: 17.321967 },
    { lat: 48.1703508, lng: 17.3220854 },
    { lat: 48.170537, lng: 17.3222894 },
    { lat: 48.1706729, lng: 17.3224296 },
    { lat: 48.1710588, lng: 17.3229393 },
    { lat: 48.1714761, lng: 17.3234766 },
    { lat: 48.1716296, lng: 17.3237495 },
    { lat: 48.1717813, lng: 17.3240296 },
    { lat: 48.1719128, lng: 17.3243099 },
    { lat: 48.1721263, lng: 17.3248256 },
    { lat: 48.1722568, lng: 17.3252294 },
    { lat: 48.172326, lng: 17.3256262 },
    { lat: 48.1723121, lng: 17.326341 },
    { lat: 48.1723389, lng: 17.3265759 },
    { lat: 48.1723606, lng: 17.3269047 },
    { lat: 48.172385, lng: 17.3272075 },
    { lat: 48.1723738, lng: 17.3273992 },
    { lat: 48.1722621, lng: 17.3276411 },
    { lat: 48.1723813, lng: 17.3286967 },
    { lat: 48.1724568, lng: 17.3291044 },
    { lat: 48.1724733, lng: 17.3293891 },
    { lat: 48.1725192, lng: 17.3295207 },
    { lat: 48.1726298, lng: 17.3299833 },
    { lat: 48.1726939, lng: 17.3302339 },
    { lat: 48.1727829, lng: 17.3305392 },
    { lat: 48.172853, lng: 17.3307628 },
    { lat: 48.17303, lng: 17.3312728 },
    { lat: 48.1731907, lng: 17.3318276 },
    { lat: 48.1733462, lng: 17.3324044 },
    { lat: 48.1734709, lng: 17.3328251 },
    { lat: 48.1736279, lng: 17.3333728 },
    { lat: 48.1737132, lng: 17.3336405 },
    { lat: 48.1738333, lng: 17.3339868 },
    { lat: 48.1739557, lng: 17.3342862 },
    { lat: 48.1740259, lng: 17.3345107 },
    { lat: 48.1740713, lng: 17.3349103 },
    { lat: 48.17409, lng: 17.3351946 },
    { lat: 48.1741013, lng: 17.3354698 },
    { lat: 48.1741298, lng: 17.3356279 },
    { lat: 48.1741746, lng: 17.3359826 },
  ],
  Blatné: [
    { lat: 48.295387, lng: 17.40593 },
    { lat: 48.295392, lng: 17.405913 },
    { lat: 48.29529, lng: 17.405788 },
    { lat: 48.294969, lng: 17.405392 },
    { lat: 48.294497, lng: 17.404794 },
    { lat: 48.294245, lng: 17.404466 },
    { lat: 48.294032, lng: 17.404191 },
    { lat: 48.29382, lng: 17.403909 },
    { lat: 48.293749, lng: 17.403827 },
    { lat: 48.29355, lng: 17.403577 },
    { lat: 48.293353, lng: 17.403334 },
    { lat: 48.293175, lng: 17.403109 },
    { lat: 48.292995, lng: 17.402881 },
    { lat: 48.293021, lng: 17.402836 },
    { lat: 48.293156, lng: 17.402572 },
    { lat: 48.2933, lng: 17.402291 },
    { lat: 48.293473, lng: 17.402055 },
    { lat: 48.29366, lng: 17.401792 },
    { lat: 48.293786, lng: 17.401574 },
    { lat: 48.293974, lng: 17.401371 },
    { lat: 48.294128, lng: 17.40127 },
    { lat: 48.294234, lng: 17.401088 },
    { lat: 48.294526, lng: 17.400612 },
    { lat: 48.294904, lng: 17.400011 },
    { lat: 48.29524, lng: 17.399455 },
    { lat: 48.295559, lng: 17.398936 },
    { lat: 48.295858, lng: 17.398447 },
    { lat: 48.295724, lng: 17.398144 },
    { lat: 48.295519, lng: 17.397674 },
    { lat: 48.295347, lng: 17.397289 },
    { lat: 48.295121, lng: 17.396782 },
    { lat: 48.294961, lng: 17.396419 },
    { lat: 48.29467, lng: 17.395766 },
    { lat: 48.294547, lng: 17.395493 },
    { lat: 48.294286, lng: 17.394896 },
    { lat: 48.29401, lng: 17.394273 },
    { lat: 48.293667, lng: 17.393507 },
    { lat: 48.293491, lng: 17.393029 },
    { lat: 48.293317, lng: 17.39264 },
    { lat: 48.293107, lng: 17.392165 },
    { lat: 48.292904, lng: 17.391708 },
    { lat: 48.292798, lng: 17.391469 },
    { lat: 48.292551, lng: 17.390917 },
    { lat: 48.292402, lng: 17.390581 },
    { lat: 48.292298, lng: 17.390354 },
    { lat: 48.292131, lng: 17.39001 },
    { lat: 48.29199, lng: 17.389712 },
    { lat: 48.291848, lng: 17.389406 },
    { lat: 48.291649, lng: 17.389024 },
    { lat: 48.291587, lng: 17.388923 },
    { lat: 48.291437, lng: 17.388667 },
    { lat: 48.291372, lng: 17.388574 },
    { lat: 48.291299, lng: 17.388468 },
    { lat: 48.291231, lng: 17.388375 },
    { lat: 48.291121, lng: 17.388241 },
    { lat: 48.291051, lng: 17.38816 },
    { lat: 48.29097, lng: 17.388086 },
    { lat: 48.290866, lng: 17.387992 },
    { lat: 48.290678, lng: 17.387849 },
    { lat: 48.290523, lng: 17.387713 },
    { lat: 48.290347, lng: 17.387586 },
    { lat: 48.290239, lng: 17.387486 },
    { lat: 48.290139, lng: 17.387411 },
    { lat: 48.290025, lng: 17.387331 },
    { lat: 48.289876, lng: 17.387214 },
    { lat: 48.289748, lng: 17.387117 },
    { lat: 48.289674, lng: 17.387072 },
    { lat: 48.289633, lng: 17.387038 },
    { lat: 48.289614, lng: 17.38702 },
    { lat: 48.289567, lng: 17.386986 },
    { lat: 48.289517, lng: 17.386962 },
    { lat: 48.289424, lng: 17.386899 },
    { lat: 48.289383, lng: 17.38687 },
    { lat: 48.289287, lng: 17.386806 },
    { lat: 48.289136, lng: 17.386719 },
    { lat: 48.288903, lng: 17.386561 },
    { lat: 48.288703, lng: 17.38644 },
    { lat: 48.288505, lng: 17.386309 },
    { lat: 48.288338, lng: 17.386838 },
    { lat: 48.28828, lng: 17.386994 },
    { lat: 48.288087, lng: 17.387558 },
    { lat: 48.288001, lng: 17.387953 },
    { lat: 48.28793, lng: 17.388231 },
    { lat: 48.287728, lng: 17.388793 },
    { lat: 48.287439, lng: 17.389606 },
    { lat: 48.287359, lng: 17.389834 },
    { lat: 48.287234, lng: 17.390084 },
    { lat: 48.28713, lng: 17.390207 },
    { lat: 48.28696, lng: 17.390373 },
    { lat: 48.286662, lng: 17.389933 },
    { lat: 48.286457, lng: 17.389641 },
    { lat: 48.286253, lng: 17.389348 },
    { lat: 48.286158, lng: 17.389195 },
    { lat: 48.286015, lng: 17.388958 },
    { lat: 48.285847, lng: 17.388735 },
    { lat: 48.285675, lng: 17.388498 },
    { lat: 48.285517, lng: 17.38828 },
    { lat: 48.285281, lng: 17.387953 },
    { lat: 48.285048, lng: 17.387627 },
    { lat: 48.284604, lng: 17.387004 },
    { lat: 48.284501, lng: 17.386855 },
    { lat: 48.284314, lng: 17.386902 },
    { lat: 48.284256, lng: 17.38683 },
    { lat: 48.283981, lng: 17.386673 },
    { lat: 48.283705, lng: 17.3865 },
    { lat: 48.283483, lng: 17.38637 },
    { lat: 48.283245, lng: 17.38626 },
    { lat: 48.282932, lng: 17.386068 },
    { lat: 48.282622, lng: 17.385867 },
    { lat: 48.282489, lng: 17.385782 },
    { lat: 48.282333, lng: 17.385656 },
    { lat: 48.28199, lng: 17.385423 },
    { lat: 48.281697, lng: 17.385227 },
    { lat: 48.281344, lng: 17.385025 },
    { lat: 48.281508, lng: 17.384312 },
    { lat: 48.281636, lng: 17.383761 },
    { lat: 48.281788, lng: 17.383099 },
    { lat: 48.281918, lng: 17.382503 },
    { lat: 48.282053, lng: 17.381831 },
    { lat: 48.282186, lng: 17.381162 },
    { lat: 48.28221, lng: 17.381032 },
    { lat: 48.281695, lng: 17.380858 },
    { lat: 48.281199, lng: 17.380693 },
    { lat: 48.280777, lng: 17.380536 },
    { lat: 48.280644, lng: 17.380494 },
    { lat: 48.280676, lng: 17.380244 },
    { lat: 48.280351, lng: 17.380028 },
    { lat: 48.280051, lng: 17.379824 },
    { lat: 48.279829, lng: 17.379671 },
    { lat: 48.279487, lng: 17.379476 },
    { lat: 48.279124, lng: 17.379231 },
    { lat: 48.278665, lng: 17.378935 },
    { lat: 48.278429, lng: 17.378794 },
    { lat: 48.278121, lng: 17.378572 },
    { lat: 48.277933, lng: 17.378428 },
    { lat: 48.277806, lng: 17.378291 },
    { lat: 48.2775, lng: 17.377952 },
    { lat: 48.277455, lng: 17.377928 },
    { lat: 48.277409, lng: 17.377883 },
    { lat: 48.277201, lng: 17.377667 },
    { lat: 48.277023, lng: 17.377513 },
    { lat: 48.276993, lng: 17.377481 },
    { lat: 48.277135, lng: 17.377038 },
    { lat: 48.277236, lng: 17.376717 },
    { lat: 48.277324, lng: 17.376435 },
    { lat: 48.277414, lng: 17.376188 },
    { lat: 48.277499, lng: 17.375926 },
    { lat: 48.2776, lng: 17.375626 },
    { lat: 48.277681, lng: 17.375364 },
    { lat: 48.277733, lng: 17.375201 },
    { lat: 48.277823, lng: 17.374957 },
    { lat: 48.277911, lng: 17.374707 },
    { lat: 48.278005, lng: 17.374435 },
    { lat: 48.278094, lng: 17.374199 },
    { lat: 48.278175, lng: 17.373965 },
    { lat: 48.278218, lng: 17.373849 },
    { lat: 48.27829, lng: 17.37362 },
    { lat: 48.27821, lng: 17.373538 },
    { lat: 48.278039, lng: 17.373359 },
    { lat: 48.277849, lng: 17.373182 },
    { lat: 48.277501, lng: 17.372859 },
    { lat: 48.277316, lng: 17.372679 },
    { lat: 48.277155, lng: 17.372523 },
    { lat: 48.276985, lng: 17.372362 },
    { lat: 48.276854, lng: 17.372235 },
    { lat: 48.276718, lng: 17.372125 },
    { lat: 48.276613, lng: 17.372027 },
    { lat: 48.276484, lng: 17.371923 },
    { lat: 48.276333, lng: 17.371804 },
    { lat: 48.276202, lng: 17.371703 },
    { lat: 48.276, lng: 17.37155 },
    { lat: 48.27581, lng: 17.371404 },
    { lat: 48.275638, lng: 17.371276 },
    { lat: 48.275441, lng: 17.371125 },
    { lat: 48.275336, lng: 17.371056 },
    { lat: 48.275189, lng: 17.370957 },
    { lat: 48.275041, lng: 17.370852 },
    { lat: 48.27491, lng: 17.370786 },
    { lat: 48.274777, lng: 17.37071 },
    { lat: 48.274572, lng: 17.370614 },
    { lat: 48.274381, lng: 17.370514 },
    { lat: 48.274182, lng: 17.370441 },
    { lat: 48.274046, lng: 17.370398 },
    { lat: 48.273829, lng: 17.370323 },
    { lat: 48.2737984, lng: 17.3707025 },
    { lat: 48.2737036, lng: 17.3723797 },
    { lat: 48.2735936, lng: 17.3737225 },
    { lat: 48.2736001, lng: 17.3744439 },
    { lat: 48.2735922, lng: 17.3746516 },
    { lat: 48.2735513, lng: 17.3751499 },
    { lat: 48.2735128, lng: 17.3753731 },
    { lat: 48.2733603, lng: 17.3756848 },
    { lat: 48.2732441, lng: 17.3758608 },
    { lat: 48.2729712, lng: 17.3763864 },
    { lat: 48.2724996, lng: 17.3772762 },
    { lat: 48.2722932, lng: 17.3778682 },
    { lat: 48.2719684, lng: 17.3786889 },
    { lat: 48.2715987, lng: 17.3796881 },
    { lat: 48.2714918, lng: 17.3800049 },
    { lat: 48.2712993, lng: 17.3804992 },
    { lat: 48.2710749, lng: 17.3810236 },
    { lat: 48.2706381, lng: 17.3817389 },
    { lat: 48.2693236, lng: 17.3837342 },
    { lat: 48.2691959, lng: 17.3839246 },
    { lat: 48.268871, lng: 17.3842927 },
    { lat: 48.2683369, lng: 17.3847474 },
    { lat: 48.2680926, lng: 17.3850005 },
    { lat: 48.2679246, lng: 17.385213 },
    { lat: 48.267655, lng: 17.3856348 },
    { lat: 48.2674615, lng: 17.3861123 },
    { lat: 48.2674401, lng: 17.3861493 },
    { lat: 48.2674265, lng: 17.3863035 },
    { lat: 48.2673847, lng: 17.3863899 },
    { lat: 48.2671114, lng: 17.3868952 },
    { lat: 48.2667463, lng: 17.3877732 },
    { lat: 48.2666805, lng: 17.3879259 },
    { lat: 48.266319, lng: 17.3885997 },
    { lat: 48.2659739, lng: 17.3892998 },
    { lat: 48.2654446, lng: 17.3902247 },
    { lat: 48.265185, lng: 17.3906128 },
    { lat: 48.2647206, lng: 17.3915251 },
    { lat: 48.2646049, lng: 17.3916747 },
    { lat: 48.2644974, lng: 17.3918791 },
    { lat: 48.2644117, lng: 17.3919492 },
    { lat: 48.2642766, lng: 17.392095 },
    { lat: 48.2639091, lng: 17.3923669 },
    { lat: 48.2635706, lng: 17.3925973 },
    { lat: 48.2634021, lng: 17.3927159 },
    { lat: 48.2629068, lng: 17.3931215 },
    { lat: 48.2622028, lng: 17.3936195 },
    { lat: 48.261894, lng: 17.3939259 },
    { lat: 48.261647, lng: 17.3941228 },
    { lat: 48.2609367, lng: 17.3948533 },
    { lat: 48.260662, lng: 17.3951268 },
    { lat: 48.2604335, lng: 17.3953988 },
    { lat: 48.2602022, lng: 17.3957142 },
    { lat: 48.2599926, lng: 17.3960341 },
    { lat: 48.2596451, lng: 17.3965416 },
    { lat: 48.2591431, lng: 17.3972629 },
    { lat: 48.2588068, lng: 17.3976833 },
    { lat: 48.2585593, lng: 17.3980298 },
    { lat: 48.2583848, lng: 17.3983326 },
    { lat: 48.2577989, lng: 17.3996031 },
    { lat: 48.257229, lng: 17.4007562 },
    { lat: 48.2570073, lng: 17.4012021 },
    { lat: 48.2568426, lng: 17.4015696 },
    { lat: 48.2567252, lng: 17.401864 },
    { lat: 48.2566769, lng: 17.4019733 },
    { lat: 48.2563429, lng: 17.40262 },
    { lat: 48.2558845, lng: 17.4034041 },
    { lat: 48.2555931, lng: 17.4040471 },
    { lat: 48.2555257, lng: 17.4042262 },
    { lat: 48.2554309, lng: 17.404557 },
    { lat: 48.2552734, lng: 17.4051559 },
    { lat: 48.2551722, lng: 17.4054599 },
    { lat: 48.2550265, lng: 17.4058762 },
    { lat: 48.2549616, lng: 17.406043 },
    { lat: 48.2548376, lng: 17.4062885 },
    { lat: 48.2543222, lng: 17.4072196 },
    { lat: 48.2545917, lng: 17.4084454 },
    { lat: 48.2546745, lng: 17.4087978 },
    { lat: 48.2547786, lng: 17.4093931 },
    { lat: 48.2548066, lng: 17.4097536 },
    { lat: 48.2548995, lng: 17.4102093 },
    { lat: 48.2549828, lng: 17.4107096 },
    { lat: 48.255053, lng: 17.4110596 },
    { lat: 48.2551434, lng: 17.4114406 },
    { lat: 48.2550604, lng: 17.4117623 },
    { lat: 48.2549602, lng: 17.4119594 },
    { lat: 48.254896, lng: 17.4121532 },
    { lat: 48.2548049, lng: 17.4125589 },
    { lat: 48.2552922, lng: 17.4126528 },
    { lat: 48.2552945, lng: 17.4128274 },
    { lat: 48.2552464, lng: 17.4132345 },
    { lat: 48.255187, lng: 17.4139371 },
    { lat: 48.254968, lng: 17.4165283 },
    { lat: 48.2549873, lng: 17.4167564 },
    { lat: 48.2549943, lng: 17.4172636 },
    { lat: 48.2549904, lng: 17.4175848 },
    { lat: 48.2549934, lng: 17.4178236 },
    { lat: 48.2549637, lng: 17.4181146 },
    { lat: 48.25494, lng: 17.4182364 },
    { lat: 48.2533022, lng: 17.4220959 },
    { lat: 48.2529197, lng: 17.4229026 },
    { lat: 48.2524903, lng: 17.4229862 },
    { lat: 48.2514761, lng: 17.4232293 },
    { lat: 48.2510994, lng: 17.4230474 },
    { lat: 48.2498346, lng: 17.4223387 },
    { lat: 48.249367, lng: 17.4244251 },
    { lat: 48.2493396, lng: 17.4245425 },
    { lat: 48.2489408, lng: 17.424367 },
    { lat: 48.248854, lng: 17.4243527 },
    { lat: 48.2487315, lng: 17.4246216 },
    { lat: 48.2486935, lng: 17.424682 },
    { lat: 48.2484211, lng: 17.4251655 },
    { lat: 48.2480442, lng: 17.4257971 },
    { lat: 48.2479049, lng: 17.4260525 },
    { lat: 48.2476728, lng: 17.4265648 },
    { lat: 48.2475449, lng: 17.4270324 },
    { lat: 48.2474425, lng: 17.4272629 },
    { lat: 48.2472279, lng: 17.4279079 },
    { lat: 48.2471565, lng: 17.4281296 },
    { lat: 48.2467862, lng: 17.4292134 },
    { lat: 48.2466274, lng: 17.4297422 },
    { lat: 48.2465482, lng: 17.4299666 },
    { lat: 48.2464624, lng: 17.4301923 },
    { lat: 48.246157, lng: 17.4309172 },
    { lat: 48.2460701, lng: 17.4311632 },
    { lat: 48.2459833, lng: 17.431531 },
    { lat: 48.2459551, lng: 17.4317591 },
    { lat: 48.2459368, lng: 17.4321549 },
    { lat: 48.2459451, lng: 17.4322832 },
    { lat: 48.2459718, lng: 17.4324154 },
    { lat: 48.246083, lng: 17.4325454 },
    { lat: 48.246176, lng: 17.4326215 },
    { lat: 48.2463378, lng: 17.4327123 },
    { lat: 48.2465001, lng: 17.4328467 },
    { lat: 48.2464771, lng: 17.4329447 },
    { lat: 48.2467391, lng: 17.433102 },
    { lat: 48.2472612, lng: 17.4334718 },
    { lat: 48.2478169, lng: 17.4338738 },
    { lat: 48.2483709, lng: 17.434282 },
    { lat: 48.2486324, lng: 17.4344663 },
    { lat: 48.2489555, lng: 17.434711 },
    { lat: 48.2493568, lng: 17.4349961 },
    { lat: 48.2498489, lng: 17.435355 },
    { lat: 48.2500821, lng: 17.4355206 },
    { lat: 48.2503366, lng: 17.4357187 },
    { lat: 48.2507496, lng: 17.436008 },
    { lat: 48.250972, lng: 17.436181 },
    { lat: 48.2511682, lng: 17.4363157 },
    { lat: 48.2513367, lng: 17.4364578 },
    { lat: 48.2515219, lng: 17.4365974 },
    { lat: 48.2516198, lng: 17.4366619 },
    { lat: 48.2517644, lng: 17.4367839 },
    { lat: 48.2520159, lng: 17.4369865 },
    { lat: 48.2521645, lng: 17.4371012 },
    { lat: 48.2522844, lng: 17.4372105 },
    { lat: 48.2524348, lng: 17.4373341 },
    { lat: 48.2527504, lng: 17.4375682 },
    { lat: 48.2530646, lng: 17.437816 },
    { lat: 48.2533731, lng: 17.4380443 },
    { lat: 48.2535599, lng: 17.4381575 },
    { lat: 48.2537823, lng: 17.4383104 },
    { lat: 48.2539063, lng: 17.4384125 },
    { lat: 48.2541865, lng: 17.4386576 },
    { lat: 48.2544512, lng: 17.4388382 },
    { lat: 48.2552399, lng: 17.4394365 },
    { lat: 48.255417, lng: 17.4395572 },
    { lat: 48.2554236, lng: 17.439552 },
    { lat: 48.2556771, lng: 17.4397172 },
    { lat: 48.255983, lng: 17.4399417 },
    { lat: 48.2561882, lng: 17.4400751 },
    { lat: 48.2563734, lng: 17.4402148 },
    { lat: 48.2565437, lng: 17.4403329 },
    { lat: 48.2567874, lng: 17.4404515 },
    { lat: 48.2572698, lng: 17.4407146 },
    { lat: 48.2574871, lng: 17.4408438 },
    { lat: 48.2577028, lng: 17.4409815 },
    { lat: 48.2581799, lng: 17.4412709 },
    { lat: 48.2584019, lng: 17.4414187 },
    { lat: 48.2589452, lng: 17.4417517 },
    { lat: 48.2592371, lng: 17.4419158 },
    { lat: 48.2593911, lng: 17.4420091 },
    { lat: 48.2596022, lng: 17.4421291 },
    { lat: 48.259994, lng: 17.4423716 },
    { lat: 48.2602379, lng: 17.4425112 },
    { lat: 48.2605119, lng: 17.4426777 },
    { lat: 48.2609193, lng: 17.4428995 },
    { lat: 48.2615777, lng: 17.4432478 },
    { lat: 48.2621057, lng: 17.4435189 },
    { lat: 48.2624689, lng: 17.4436851 },
    { lat: 48.2629153, lng: 17.4439164 },
    { lat: 48.2631147, lng: 17.4440294 },
    { lat: 48.263544, lng: 17.4442404 },
    { lat: 48.2640702, lng: 17.4444837 },
    { lat: 48.2642917, lng: 17.4445911 },
    { lat: 48.2646819, lng: 17.4447568 },
    { lat: 48.2651204, lng: 17.4449699 },
    { lat: 48.2655854, lng: 17.4451907 },
    { lat: 48.2657945, lng: 17.4452805 },
    { lat: 48.2660022, lng: 17.445384 },
    { lat: 48.2662525, lng: 17.4454999 },
    { lat: 48.2665102, lng: 17.4456249 },
    { lat: 48.2670413, lng: 17.4458743 },
    { lat: 48.267375, lng: 17.4460542 },
    { lat: 48.2677144, lng: 17.4460641 },
    { lat: 48.2678408, lng: 17.4460619 },
    { lat: 48.2681608, lng: 17.4461035 },
    { lat: 48.2684266, lng: 17.4461275 },
    { lat: 48.2687838, lng: 17.4460613 },
    { lat: 48.2691281, lng: 17.4459021 },
    { lat: 48.269258, lng: 17.4458326 },
    { lat: 48.2694831, lng: 17.4456974 },
    { lat: 48.2697343, lng: 17.4455298 },
    { lat: 48.2698558, lng: 17.4454175 },
    { lat: 48.2702827, lng: 17.4449808 },
    { lat: 48.2705044, lng: 17.4447244 },
    { lat: 48.2706645, lng: 17.4445287 },
    { lat: 48.2709379, lng: 17.4442327 },
    { lat: 48.2709099, lng: 17.4441843 },
    { lat: 48.2709179, lng: 17.4440977 },
    { lat: 48.2709517, lng: 17.4439228 },
    { lat: 48.2709548, lng: 17.4438309 },
    { lat: 48.2711482, lng: 17.4434509 },
    { lat: 48.2715267, lng: 17.4428936 },
    { lat: 48.2717061, lng: 17.4426476 },
    { lat: 48.2719326, lng: 17.4423068 },
    { lat: 48.2720361, lng: 17.4421622 },
    { lat: 48.2722355, lng: 17.4418033 },
    { lat: 48.2724552, lng: 17.4414483 },
    { lat: 48.2725958, lng: 17.441213 },
    { lat: 48.2726836, lng: 17.4410836 },
    { lat: 48.2728124, lng: 17.4408584 },
    { lat: 48.272847, lng: 17.4407823 },
    { lat: 48.2729082, lng: 17.4405949 },
    { lat: 48.2730207, lng: 17.4404289 },
    { lat: 48.2731139, lng: 17.4402284 },
    { lat: 48.2732367, lng: 17.439999 },
    { lat: 48.2733127, lng: 17.4398458 },
    { lat: 48.2734728, lng: 17.4395985 },
    { lat: 48.2736061, lng: 17.4394581 },
    { lat: 48.2737529, lng: 17.4392837 },
    { lat: 48.273856, lng: 17.439184 },
    { lat: 48.2740045, lng: 17.4390171 },
    { lat: 48.2742611, lng: 17.4387412 },
    { lat: 48.274588, lng: 17.438401 },
    { lat: 48.2747491, lng: 17.4382193 },
    { lat: 48.2747975, lng: 17.4381286 },
    { lat: 48.2748877, lng: 17.4380056 },
    { lat: 48.2749853, lng: 17.4378898 },
    { lat: 48.275122, lng: 17.4377701 },
    { lat: 48.2751881, lng: 17.4377435 },
    { lat: 48.2752295, lng: 17.437702 },
    { lat: 48.2754239, lng: 17.4375646 },
    { lat: 48.2754982, lng: 17.4374921 },
    { lat: 48.2756222, lng: 17.437331 },
    { lat: 48.2757575, lng: 17.4371177 },
    { lat: 48.2758885, lng: 17.4369446 },
    { lat: 48.2760393, lng: 17.4368483 },
    { lat: 48.2761718, lng: 17.4367478 },
    { lat: 48.2763058, lng: 17.4365846 },
    { lat: 48.2763553, lng: 17.4365121 },
    { lat: 48.2763754, lng: 17.4364924 },
    { lat: 48.2764358, lng: 17.4364302 },
    { lat: 48.2765203, lng: 17.4363731 },
    { lat: 48.2766899, lng: 17.4362199 },
    { lat: 48.276834, lng: 17.4360373 },
    { lat: 48.2769829, lng: 17.4358618 },
    { lat: 48.277261, lng: 17.435482 },
    { lat: 48.2774475, lng: 17.4352391 },
    { lat: 48.2775448, lng: 17.4351209 },
    { lat: 48.2777498, lng: 17.4348146 },
    { lat: 48.2778521, lng: 17.434633 },
    { lat: 48.2781064, lng: 17.4341484 },
    { lat: 48.2782698, lng: 17.4338295 },
    { lat: 48.2782971, lng: 17.4337963 },
    { lat: 48.2783076, lng: 17.4337834 },
    { lat: 48.2783666, lng: 17.4337697 },
    { lat: 48.2786985, lng: 17.4335242 },
    { lat: 48.2788766, lng: 17.4333783 },
    { lat: 48.2790859, lng: 17.4331929 },
    { lat: 48.2798996, lng: 17.4323647 },
    { lat: 48.2800997, lng: 17.43207 },
    { lat: 48.2803622, lng: 17.4316418 },
    { lat: 48.28057, lng: 17.4314153 },
    { lat: 48.2806967, lng: 17.4312089 },
    { lat: 48.2808658, lng: 17.4309779 },
    { lat: 48.2810622, lng: 17.4306964 },
    { lat: 48.281131, lng: 17.4306076 },
    { lat: 48.2812392, lng: 17.4305174 },
    { lat: 48.2813568, lng: 17.4304319 },
    { lat: 48.281493, lng: 17.4303215 },
    { lat: 48.2817104, lng: 17.4302061 },
    { lat: 48.2818088, lng: 17.4301732 },
    { lat: 48.2819061, lng: 17.4301252 },
    { lat: 48.282092, lng: 17.4300136 },
    { lat: 48.2821531, lng: 17.4299623 },
    { lat: 48.282265, lng: 17.4297895 },
    { lat: 48.2823606, lng: 17.4296952 },
    { lat: 48.2825059, lng: 17.429498 },
    { lat: 48.2827714, lng: 17.429203 },
    { lat: 48.2830423, lng: 17.4288354 },
    { lat: 48.2832905, lng: 17.4285335 },
    { lat: 48.2833789, lng: 17.4284513 },
    { lat: 48.2837855, lng: 17.4280341 },
    { lat: 48.2839662, lng: 17.4278405 },
    { lat: 48.284171, lng: 17.4276371 },
    { lat: 48.2842249, lng: 17.4275624 },
    { lat: 48.2847343, lng: 17.4270932 },
    { lat: 48.2850134, lng: 17.4267977 },
    { lat: 48.2851278, lng: 17.426683 },
    { lat: 48.2854355, lng: 17.426359 },
    { lat: 48.285559, lng: 17.4262614 },
    { lat: 48.2856515, lng: 17.4261548 },
    { lat: 48.2857409, lng: 17.4260157 },
    { lat: 48.2859788, lng: 17.4255571 },
    { lat: 48.286137, lng: 17.4252288 },
    { lat: 48.2862245, lng: 17.4250094 },
    { lat: 48.286443, lng: 17.424516 },
    { lat: 48.286489, lng: 17.424386 },
    { lat: 48.286537, lng: 17.424223 },
    { lat: 48.28661, lng: 17.424051 },
    { lat: 48.286874, lng: 17.42362 },
    { lat: 48.28702, lng: 17.423394 },
    { lat: 48.287133, lng: 17.423265 },
    { lat: 48.287189, lng: 17.423195 },
    { lat: 48.28729, lng: 17.423081 },
    { lat: 48.287405, lng: 17.42294 },
    { lat: 48.287651, lng: 17.42261 },
    { lat: 48.28777, lng: 17.422423 },
    { lat: 48.28782, lng: 17.42233 },
    { lat: 48.287917, lng: 17.422121 },
    { lat: 48.287984, lng: 17.421957 },
    { lat: 48.288084, lng: 17.421784 },
    { lat: 48.288302, lng: 17.421265 },
    { lat: 48.288392, lng: 17.421038 },
    { lat: 48.288432, lng: 17.420927 },
    { lat: 48.288486, lng: 17.420777 },
    { lat: 48.28856, lng: 17.420634 },
    { lat: 48.288657, lng: 17.420361 },
    { lat: 48.288783, lng: 17.420015 },
    { lat: 48.288838, lng: 17.41983 },
    { lat: 48.288899, lng: 17.419592 },
    { lat: 48.289105, lng: 17.418874 },
    { lat: 48.289314, lng: 17.418152 },
    { lat: 48.289354, lng: 17.418059 },
    { lat: 48.289426, lng: 17.417839 },
    { lat: 48.289693, lng: 17.417296 },
    { lat: 48.289726, lng: 17.41724 },
    { lat: 48.290064, lng: 17.41665 },
    { lat: 48.29009, lng: 17.416589 },
    { lat: 48.29013, lng: 17.416522 },
    { lat: 48.2902, lng: 17.416406 },
    { lat: 48.290336, lng: 17.41619 },
    { lat: 48.290527, lng: 17.415899 },
    { lat: 48.290666, lng: 17.415647 },
    { lat: 48.290748, lng: 17.415506 },
    { lat: 48.29091, lng: 17.415246 },
    { lat: 48.291074, lng: 17.414938 },
    { lat: 48.291224, lng: 17.414606 },
    { lat: 48.291267, lng: 17.414559 },
    { lat: 48.291325, lng: 17.414436 },
    { lat: 48.291555, lng: 17.414025 },
    { lat: 48.291749, lng: 17.413651 },
    { lat: 48.291923, lng: 17.413281 },
    { lat: 48.291981, lng: 17.413148 },
    { lat: 48.292123, lng: 17.412836 },
    { lat: 48.292341, lng: 17.412404 },
    { lat: 48.292428, lng: 17.412223 },
    { lat: 48.292467, lng: 17.412135 },
    { lat: 48.292646, lng: 17.411668 },
    { lat: 48.292739, lng: 17.411419 },
    { lat: 48.292811, lng: 17.411273 },
    { lat: 48.292892, lng: 17.411116 },
    { lat: 48.293061, lng: 17.410752 },
    { lat: 48.293206, lng: 17.410452 },
    { lat: 48.293373, lng: 17.410058 },
    { lat: 48.293568, lng: 17.409604 },
    { lat: 48.293699, lng: 17.409304 },
    { lat: 48.293814, lng: 17.40896 },
    { lat: 48.293998, lng: 17.408594 },
    { lat: 48.294107, lng: 17.408363 },
    { lat: 48.294266, lng: 17.408009 },
    { lat: 48.294368, lng: 17.407773 },
    { lat: 48.294463, lng: 17.407555 },
    { lat: 48.29463, lng: 17.407233 },
    { lat: 48.294719, lng: 17.407056 },
    { lat: 48.294886, lng: 17.40674 },
    { lat: 48.295047, lng: 17.406442 },
    { lat: 48.295206, lng: 17.406193 },
    { lat: 48.295271, lng: 17.406085 },
    { lat: 48.29534, lng: 17.405967 },
    { lat: 48.295355, lng: 17.405953 },
    { lat: 48.295387, lng: 17.40593 },
  ],
  Čataj: [
    { lat: 48.288808, lng: 17.493304 },
    { lat: 48.2879811, lng: 17.4918951 },
    { lat: 48.2877019, lng: 17.4914174 },
    { lat: 48.2871768, lng: 17.4904347 },
    { lat: 48.2868901, lng: 17.4899108 },
    { lat: 48.2863604, lng: 17.4888552 },
    { lat: 48.286206, lng: 17.4885442 },
    { lat: 48.2855658, lng: 17.4872457 },
    { lat: 48.2849609, lng: 17.4860249 },
    { lat: 48.2841959, lng: 17.4844906 },
    { lat: 48.2837871, lng: 17.4835961 },
    { lat: 48.2835603, lng: 17.4830899 },
    { lat: 48.2827857, lng: 17.4813725 },
    { lat: 48.2822623, lng: 17.4802071 },
    { lat: 48.2812002, lng: 17.4781135 },
    { lat: 48.2807281, lng: 17.4771843 },
    { lat: 48.2801557, lng: 17.4760454 },
    { lat: 48.2798649, lng: 17.4755122 },
    { lat: 48.2793337, lng: 17.4745237 },
    { lat: 48.2786294, lng: 17.4732171 },
    { lat: 48.2781947, lng: 17.4724136 },
    { lat: 48.2780457, lng: 17.4721339 },
    { lat: 48.2773066, lng: 17.4708477 },
    { lat: 48.2768216, lng: 17.4700167 },
    { lat: 48.2766301, lng: 17.469586 },
    { lat: 48.2758624, lng: 17.4679257 },
    { lat: 48.2752856, lng: 17.4667241 },
    { lat: 48.2747243, lng: 17.4655509 },
    { lat: 48.2745604, lng: 17.465199 },
    { lat: 48.274118, lng: 17.4642935 },
    { lat: 48.2736758, lng: 17.4633738 },
    { lat: 48.2733099, lng: 17.4626392 },
    { lat: 48.2728702, lng: 17.4617935 },
    { lat: 48.2725849, lng: 17.461241 },
    { lat: 48.2722245, lng: 17.4605557 },
    { lat: 48.2718705, lng: 17.4599885 },
    { lat: 48.2714611, lng: 17.4593178 },
    { lat: 48.2712853, lng: 17.4590253 },
    { lat: 48.2710824, lng: 17.4586986 },
    { lat: 48.2707784, lng: 17.4582917 },
    { lat: 48.2704193, lng: 17.4578742 },
    { lat: 48.2701644, lng: 17.4575813 },
    { lat: 48.2697183, lng: 17.4570936 },
    { lat: 48.2693854, lng: 17.4567833 },
    { lat: 48.26905, lng: 17.4564505 },
    { lat: 48.268761, lng: 17.4561928 },
    { lat: 48.2682495, lng: 17.4557561 },
    { lat: 48.2679264, lng: 17.4554924 },
    { lat: 48.2676278, lng: 17.4553108 },
    { lat: 48.2674912, lng: 17.4551893 },
    { lat: 48.267401, lng: 17.455139 },
    { lat: 48.2672607, lng: 17.4550299 },
    { lat: 48.2670559, lng: 17.4548497 },
    { lat: 48.2668154, lng: 17.454729 },
    { lat: 48.2666821, lng: 17.4546943 },
    { lat: 48.2663932, lng: 17.4546449 },
    { lat: 48.2662558, lng: 17.454861 },
    { lat: 48.2656138, lng: 17.4548009 },
    { lat: 48.264771, lng: 17.4546744 },
    { lat: 48.2645784, lng: 17.4546483 },
    { lat: 48.2644456, lng: 17.4546032 },
    { lat: 48.2642248, lng: 17.4546106 },
    { lat: 48.2639598, lng: 17.4544943 },
    { lat: 48.2633437, lng: 17.4542775 },
    { lat: 48.2630302, lng: 17.4541622 },
    { lat: 48.2626861, lng: 17.4540455 },
    { lat: 48.2625504, lng: 17.4540076 },
    { lat: 48.2624301, lng: 17.4539481 },
    { lat: 48.2622002, lng: 17.4538834 },
    { lat: 48.2620681, lng: 17.4538322 },
    { lat: 48.2616397, lng: 17.4537051 },
    { lat: 48.2610581, lng: 17.4534966 },
    { lat: 48.2607953, lng: 17.4533968 },
    { lat: 48.2604881, lng: 17.4532918 },
    { lat: 48.2597468, lng: 17.4530592 },
    { lat: 48.259558, lng: 17.4529868 },
    { lat: 48.2593373, lng: 17.4528919 },
    { lat: 48.2590289, lng: 17.4528039 },
    { lat: 48.2584998, lng: 17.4527259 },
    { lat: 48.2582795, lng: 17.452691 },
    { lat: 48.2580893, lng: 17.4526475 },
    { lat: 48.257723, lng: 17.4525713 },
    { lat: 48.2571602, lng: 17.4524811 },
    { lat: 48.2563201, lng: 17.4523086 },
    { lat: 48.2559477, lng: 17.4522256 },
    { lat: 48.2556951, lng: 17.4521786 },
    { lat: 48.2554184, lng: 17.4520394 },
    { lat: 48.2553233, lng: 17.452 },
    { lat: 48.2535418, lng: 17.4513307 },
    { lat: 48.2532411, lng: 17.4512221 },
    { lat: 48.2528727, lng: 17.4510792 },
    { lat: 48.2521498, lng: 17.4508154 },
    { lat: 48.2516488, lng: 17.4506666 },
    { lat: 48.2514732, lng: 17.4506226 },
    { lat: 48.2506837, lng: 17.4503939 },
    { lat: 48.250657, lng: 17.4503841 },
    { lat: 48.2499982, lng: 17.4502743 },
    { lat: 48.249454, lng: 17.4500141 },
    { lat: 48.249266, lng: 17.4499205 },
    { lat: 48.2487086, lng: 17.4496647 },
    { lat: 48.2484007, lng: 17.4495157 },
    { lat: 48.2476085, lng: 17.4491419 },
    { lat: 48.2475945, lng: 17.4491887 },
    { lat: 48.2474233, lng: 17.4495789 },
    { lat: 48.2473348, lng: 17.4497753 },
    { lat: 48.2471201, lng: 17.4502518 },
    { lat: 48.2469288, lng: 17.4509433 },
    { lat: 48.2467874, lng: 17.4514636 },
    { lat: 48.2465998, lng: 17.4521274 },
    { lat: 48.2463959, lng: 17.4528156 },
    { lat: 48.246336, lng: 17.4530592 },
    { lat: 48.2461669, lng: 17.4536401 },
    { lat: 48.2460822, lng: 17.4538857 },
    { lat: 48.2460622, lng: 17.4539428 },
    { lat: 48.2456566, lng: 17.4551648 },
    { lat: 48.24567, lng: 17.4560879 },
    { lat: 48.2457929, lng: 17.4568192 },
    { lat: 48.2457598, lng: 17.4580186 },
    { lat: 48.2454502, lng: 17.4591516 },
    { lat: 48.2451618, lng: 17.4603347 },
    { lat: 48.2452677, lng: 17.4615047 },
    { lat: 48.245242, lng: 17.4627309 },
    { lat: 48.2450524, lng: 17.4639242 },
    { lat: 48.2450111, lng: 17.4640013 },
    { lat: 48.2448428, lng: 17.4642623 },
    { lat: 48.2447338, lng: 17.4644185 },
    { lat: 48.2441895, lng: 17.4651989 },
    { lat: 48.2438661, lng: 17.4657192 },
    { lat: 48.2433109, lng: 17.4664437 },
    { lat: 48.2430074, lng: 17.4667456 },
    { lat: 48.242383, lng: 17.4675181 },
    { lat: 48.2423786, lng: 17.4680792 },
    { lat: 48.2423807, lng: 17.4683055 },
    { lat: 48.242483, lng: 17.4688943 },
    { lat: 48.2427198, lng: 17.4700073 },
    { lat: 48.2427731, lng: 17.4702726 },
    { lat: 48.2429958, lng: 17.4713108 },
    { lat: 48.2431025, lng: 17.4717729 },
    { lat: 48.2431903, lng: 17.4729599 },
    { lat: 48.2431985, lng: 17.4735953 },
    { lat: 48.2431921, lng: 17.4738819 },
    { lat: 48.2431704, lng: 17.4751379 },
    { lat: 48.2433799, lng: 17.4770216 },
    { lat: 48.2434998, lng: 17.4779353 },
    { lat: 48.243573, lng: 17.478428 },
    { lat: 48.243595, lng: 17.478422 },
    { lat: 48.243639, lng: 17.47841 },
    { lat: 48.243683, lng: 17.478395 },
    { lat: 48.244316, lng: 17.478082 },
    { lat: 48.244381, lng: 17.478085 },
    { lat: 48.244875, lng: 17.478389 },
    { lat: 48.245406, lng: 17.478713 },
    { lat: 48.245435, lng: 17.478732 },
    { lat: 48.2454968, lng: 17.4787742 },
    { lat: 48.245586, lng: 17.478835 },
    { lat: 48.245633, lng: 17.478866 },
    { lat: 48.245913, lng: 17.479015 },
    { lat: 48.246455, lng: 17.479299 },
    { lat: 48.24693, lng: 17.479546 },
    { lat: 48.247612, lng: 17.479914 },
    { lat: 48.247807, lng: 17.480015 },
    { lat: 48.248456, lng: 17.480348 },
    { lat: 48.248974, lng: 17.480607 },
    { lat: 48.24932, lng: 17.48078 },
    { lat: 48.249354, lng: 17.480799 },
    { lat: 48.250062, lng: 17.481162 },
    { lat: 48.250194, lng: 17.481233 },
    { lat: 48.250449, lng: 17.481366 },
    { lat: 48.25083, lng: 17.481567 },
    { lat: 48.252009, lng: 17.482207 },
    { lat: 48.252881, lng: 17.482684 },
    { lat: 48.252869, lng: 17.482715 },
    { lat: 48.252901, lng: 17.482754 },
    { lat: 48.252908, lng: 17.482765 },
    { lat: 48.252969, lng: 17.482847 },
    { lat: 48.253067, lng: 17.482979 },
    { lat: 48.253807, lng: 17.483741 },
    { lat: 48.254063, lng: 17.483966 },
    { lat: 48.254417, lng: 17.484295 },
    { lat: 48.254445, lng: 17.48432 },
    { lat: 48.25508, lng: 17.485007 },
    { lat: 48.255478, lng: 17.485507 },
    { lat: 48.255509, lng: 17.485547 },
    { lat: 48.255524, lng: 17.485518 },
    { lat: 48.255839, lng: 17.485883 },
    { lat: 48.255896, lng: 17.485955 },
    { lat: 48.25613, lng: 17.486248 },
    { lat: 48.256331, lng: 17.486527 },
    { lat: 48.256659, lng: 17.486983 },
    { lat: 48.257006, lng: 17.48741 },
    { lat: 48.2571629, lng: 17.4876001 },
    { lat: 48.257285, lng: 17.487748 },
    { lat: 48.257412, lng: 17.4879 },
    { lat: 48.257484, lng: 17.487986 },
    { lat: 48.257779, lng: 17.488418 },
    { lat: 48.258032, lng: 17.488734 },
    { lat: 48.258175, lng: 17.488918 },
    { lat: 48.258251, lng: 17.48901 },
    { lat: 48.258358, lng: 17.489164 },
    { lat: 48.258568, lng: 17.489457 },
    { lat: 48.258656, lng: 17.489585 },
    { lat: 48.258776, lng: 17.489753 },
    { lat: 48.258794, lng: 17.489779 },
    { lat: 48.259013, lng: 17.490096 },
    { lat: 48.259414, lng: 17.490669 },
    { lat: 48.259609, lng: 17.49095 },
    { lat: 48.25968, lng: 17.491053 },
    { lat: 48.259818, lng: 17.491264 },
    { lat: 48.260015, lng: 17.491555 },
    { lat: 48.260271, lng: 17.491944 },
    { lat: 48.26041, lng: 17.49216 },
    { lat: 48.260601, lng: 17.492428 },
    { lat: 48.260892, lng: 17.492839 },
    { lat: 48.260966, lng: 17.492949 },
    { lat: 48.260963, lng: 17.492998 },
    { lat: 48.260959, lng: 17.493083 },
    { lat: 48.26096, lng: 17.493131 },
    { lat: 48.261291, lng: 17.493577 },
    { lat: 48.261585, lng: 17.493969 },
    { lat: 48.261906, lng: 17.49436 },
    { lat: 48.262357, lng: 17.49491 },
    { lat: 48.262543, lng: 17.495148 },
    { lat: 48.262833, lng: 17.495515 },
    { lat: 48.263119, lng: 17.495878 },
    { lat: 48.263368, lng: 17.496193 },
    { lat: 48.26366, lng: 17.496567 },
    { lat: 48.263913, lng: 17.496891 },
    { lat: 48.264146, lng: 17.497194 },
    { lat: 48.264352, lng: 17.497433 },
    { lat: 48.264613, lng: 17.49774 },
    { lat: 48.265046, lng: 17.498249 },
    { lat: 48.265187, lng: 17.498402 },
    { lat: 48.265192, lng: 17.498409 },
    { lat: 48.265546, lng: 17.498804 },
    { lat: 48.265939, lng: 17.499236 },
    { lat: 48.266257, lng: 17.49959 },
    { lat: 48.266519, lng: 17.499881 },
    { lat: 48.266763, lng: 17.500149 },
    { lat: 48.2668, lng: 17.500192 },
    { lat: 48.267096, lng: 17.500519 },
    { lat: 48.267488, lng: 17.500951 },
    { lat: 48.267714, lng: 17.501204 },
    { lat: 48.268018, lng: 17.501565 },
    { lat: 48.2682, lng: 17.501771 },
    { lat: 48.268322, lng: 17.501905 },
    { lat: 48.26861, lng: 17.502214 },
    { lat: 48.268823, lng: 17.502441 },
    { lat: 48.268861, lng: 17.502479 },
    { lat: 48.269156, lng: 17.502801 },
    { lat: 48.269266, lng: 17.502922 },
    { lat: 48.269597, lng: 17.503287 },
    { lat: 48.269901, lng: 17.503602 },
    { lat: 48.26997, lng: 17.503673 },
    { lat: 48.270169, lng: 17.503853 },
    { lat: 48.270539, lng: 17.504185 },
    { lat: 48.27069, lng: 17.504319 },
    { lat: 48.270851, lng: 17.504482 },
    { lat: 48.27123, lng: 17.504868 },
    { lat: 48.2714, lng: 17.505038 },
    { lat: 48.271743, lng: 17.505405 },
    { lat: 48.271912, lng: 17.505582 },
    { lat: 48.272147, lng: 17.505834 },
    { lat: 48.272309, lng: 17.506007 },
    { lat: 48.272297, lng: 17.506038 },
    { lat: 48.272511, lng: 17.506267 },
    { lat: 48.272697, lng: 17.506466 },
    { lat: 48.272948, lng: 17.506732 },
    { lat: 48.273174, lng: 17.506975 },
    { lat: 48.273494, lng: 17.50732 },
    { lat: 48.273637, lng: 17.507473 },
    { lat: 48.273797, lng: 17.507647 },
    { lat: 48.273819, lng: 17.50767 },
    { lat: 48.27401, lng: 17.507878 },
    { lat: 48.274086, lng: 17.507958 },
    { lat: 48.274118, lng: 17.507992 },
    { lat: 48.27414, lng: 17.508049 },
    { lat: 48.274163, lng: 17.508109 },
    { lat: 48.274191, lng: 17.508179 },
    { lat: 48.274271, lng: 17.508231 },
    { lat: 48.274429, lng: 17.508377 },
    { lat: 48.274576, lng: 17.508514 },
    { lat: 48.274646, lng: 17.508578 },
    { lat: 48.274665, lng: 17.508596 },
    { lat: 48.274771, lng: 17.508712 },
    { lat: 48.275185, lng: 17.509182 },
    { lat: 48.275472, lng: 17.509501 },
    { lat: 48.275805, lng: 17.509866 },
    { lat: 48.275903, lng: 17.509977 },
    { lat: 48.276209, lng: 17.510311 },
    { lat: 48.27637, lng: 17.510487 },
    { lat: 48.276535, lng: 17.510664 },
    { lat: 48.276555, lng: 17.510688 },
    { lat: 48.276853, lng: 17.511027 },
    { lat: 48.277115, lng: 17.511326 },
    { lat: 48.277341, lng: 17.511599 },
    { lat: 48.277589, lng: 17.511906 },
    { lat: 48.277704, lng: 17.51205 },
    { lat: 48.27793, lng: 17.512324 },
    { lat: 48.278162, lng: 17.512606 },
    { lat: 48.278324, lng: 17.512811 },
    { lat: 48.278577, lng: 17.513129 },
    { lat: 48.278863, lng: 17.513485 },
    { lat: 48.278993, lng: 17.513654 },
    { lat: 48.279077, lng: 17.513744 },
    { lat: 48.2848728, lng: 17.5061924 },
    { lat: 48.284883, lng: 17.506182 },
    { lat: 48.285477, lng: 17.505544 },
    { lat: 48.285841, lng: 17.505142 },
    { lat: 48.28613, lng: 17.504853 },
    { lat: 48.286167, lng: 17.504816 },
    { lat: 48.286307, lng: 17.504674 },
    { lat: 48.286531, lng: 17.504459 },
    { lat: 48.286809, lng: 17.504177 },
    { lat: 48.286953, lng: 17.504069 },
    { lat: 48.28716, lng: 17.503913 },
    { lat: 48.287336, lng: 17.503815 },
    { lat: 48.287441, lng: 17.503733 },
    { lat: 48.287703, lng: 17.50353 },
    { lat: 48.287981, lng: 17.503261 },
    { lat: 48.288263, lng: 17.502952 },
    { lat: 48.288445, lng: 17.502698 },
    { lat: 48.288931, lng: 17.501979 },
    { lat: 48.289156, lng: 17.501605 },
    { lat: 48.289321, lng: 17.501315 },
    { lat: 48.289463, lng: 17.501058 },
    { lat: 48.289622, lng: 17.500768 },
    { lat: 48.289721, lng: 17.500449 },
    { lat: 48.289785, lng: 17.500163 },
    { lat: 48.289814, lng: 17.499912 },
    { lat: 48.289798, lng: 17.499105 },
    { lat: 48.289744, lng: 17.498293 },
    { lat: 48.289668, lng: 17.498195 },
    { lat: 48.28744, lng: 17.49574 },
    { lat: 48.287496, lng: 17.49558 },
    { lat: 48.287722, lng: 17.49518 },
    { lat: 48.287949, lng: 17.494805 },
    { lat: 48.2881379, lng: 17.4944301 },
    { lat: 48.288145, lng: 17.494416 },
    { lat: 48.288405, lng: 17.493948 },
    { lat: 48.288668, lng: 17.493511 },
    { lat: 48.288796, lng: 17.49337 },
    { lat: 48.288782, lng: 17.49335 },
    { lat: 48.288808, lng: 17.493304 },
  ],
  ChorvátskyGrob: [
    { lat: 48.2300318, lng: 17.3248538 },
    { lat: 48.2301963, lng: 17.3245904 },
    { lat: 48.2309945, lng: 17.3239833 },
    { lat: 48.2313453, lng: 17.3236678 },
    { lat: 48.231953, lng: 17.3231342 },
    { lat: 48.2322612, lng: 17.3230119 },
    { lat: 48.2326004, lng: 17.3228968 },
    { lat: 48.2332806, lng: 17.3225039 },
    { lat: 48.2335464, lng: 17.3222864 },
    { lat: 48.2337368, lng: 17.3220392 },
    { lat: 48.2338426, lng: 17.3218613 },
    { lat: 48.2341178, lng: 17.3213559 },
    { lat: 48.2342594, lng: 17.3210898 },
    { lat: 48.2345622, lng: 17.3205742 },
    { lat: 48.2346778, lng: 17.320323 },
    { lat: 48.2349462, lng: 17.3195969 },
    { lat: 48.2351747, lng: 17.3191555 },
    { lat: 48.2354235, lng: 17.3187469 },
    { lat: 48.2355677, lng: 17.3185185 },
    { lat: 48.2357306, lng: 17.3182298 },
    { lat: 48.2358379, lng: 17.3180255 },
    { lat: 48.2359674, lng: 17.3177426 },
    { lat: 48.236223, lng: 17.3171992 },
    { lat: 48.2364318, lng: 17.3168369 },
    { lat: 48.2365903, lng: 17.31662 },
    { lat: 48.2367972, lng: 17.316384 },
    { lat: 48.2370707, lng: 17.3161618 },
    { lat: 48.2373375, lng: 17.3160092 },
    { lat: 48.2376721, lng: 17.3159107 },
    { lat: 48.2378835, lng: 17.3158787 },
    { lat: 48.2380259, lng: 17.3158296 },
    { lat: 48.2380539, lng: 17.3158087 },
    { lat: 48.2386438, lng: 17.3171673 },
    { lat: 48.238876, lng: 17.317695 },
    { lat: 48.2389, lng: 17.317675 },
    { lat: 48.238871, lng: 17.317442 },
    { lat: 48.23881, lng: 17.317077 },
    { lat: 48.238805, lng: 17.316965 },
    { lat: 48.238797, lng: 17.316868 },
    { lat: 48.238785, lng: 17.316759 },
    { lat: 48.238776, lng: 17.316527 },
    { lat: 48.238757, lng: 17.316313 },
    { lat: 48.238738, lng: 17.316093 },
    { lat: 48.238706, lng: 17.315848 },
    { lat: 48.238673, lng: 17.315374 },
    { lat: 48.238647, lng: 17.315037 },
    { lat: 48.238636, lng: 17.314829 },
    { lat: 48.238626, lng: 17.314605 },
    { lat: 48.238617, lng: 17.314476 },
    { lat: 48.238605, lng: 17.314144 },
    { lat: 48.238572, lng: 17.314012 },
    { lat: 48.238558, lng: 17.313745 },
    { lat: 48.238537, lng: 17.31357 },
    { lat: 48.238514, lng: 17.313454 },
    { lat: 48.238492, lng: 17.313351 },
    { lat: 48.238433, lng: 17.312805 },
    { lat: 48.238404, lng: 17.312429 },
    { lat: 48.23838, lng: 17.312232 },
    { lat: 48.238342, lng: 17.311992 },
    { lat: 48.238332, lng: 17.311816 },
    { lat: 48.238317, lng: 17.311678 },
    { lat: 48.238299, lng: 17.311593 },
    { lat: 48.238306, lng: 17.311526 },
    { lat: 48.238276, lng: 17.311259 },
    { lat: 48.238237, lng: 17.31093 },
    { lat: 48.238166, lng: 17.310242 },
    { lat: 48.238148, lng: 17.310088 },
    { lat: 48.238134, lng: 17.309789 },
    { lat: 48.23809, lng: 17.309434 },
    { lat: 48.238074, lng: 17.309266 },
    { lat: 48.238037, lng: 17.309054 },
    { lat: 48.238017, lng: 17.308841 },
    { lat: 48.238008, lng: 17.30865 },
    { lat: 48.237995, lng: 17.308538 },
    { lat: 48.23798, lng: 17.308124 },
    { lat: 48.23795, lng: 17.307563 },
    { lat: 48.23794, lng: 17.30724 },
    { lat: 48.23793, lng: 17.306952 },
    { lat: 48.237924, lng: 17.306688 },
    { lat: 48.237917, lng: 17.306528 },
    { lat: 48.237912, lng: 17.306266 },
    { lat: 48.237906, lng: 17.306066 },
    { lat: 48.237929, lng: 17.305821 },
    { lat: 48.237914, lng: 17.305637 },
    { lat: 48.237869, lng: 17.305059 },
    { lat: 48.237842, lng: 17.304595 },
    { lat: 48.237816, lng: 17.30427 },
    { lat: 48.23778, lng: 17.303772 },
    { lat: 48.237749, lng: 17.303239 },
    { lat: 48.23773, lng: 17.30284 },
    { lat: 48.237702, lng: 17.302423 },
    { lat: 48.237696, lng: 17.302189 },
    { lat: 48.237679, lng: 17.302052 },
    { lat: 48.237673, lng: 17.301896 },
    { lat: 48.237656, lng: 17.30169 },
    { lat: 48.237644, lng: 17.301338 },
    { lat: 48.237633, lng: 17.301145 },
    { lat: 48.23762, lng: 17.301035 },
    { lat: 48.237619, lng: 17.300941 },
    { lat: 48.237588, lng: 17.300167 },
    { lat: 48.237571, lng: 17.299925 },
    { lat: 48.237558, lng: 17.299675 },
    { lat: 48.237549, lng: 17.299535 },
    { lat: 48.237547, lng: 17.299403 },
    { lat: 48.237512, lng: 17.298639 },
    { lat: 48.237495, lng: 17.29814 },
    { lat: 48.237476, lng: 17.297902 },
    { lat: 48.237458, lng: 17.297129 },
    { lat: 48.23744, lng: 17.295985 },
    { lat: 48.237427, lng: 17.295469 },
    { lat: 48.237412, lng: 17.294986 },
    { lat: 48.23739, lng: 17.294169 },
    { lat: 48.23761, lng: 17.294124 },
    { lat: 48.237811, lng: 17.294101 },
    { lat: 48.238038, lng: 17.294081 },
    { lat: 48.237994, lng: 17.293464 },
    { lat: 48.238493, lng: 17.293344 },
    { lat: 48.238742, lng: 17.293284 },
    { lat: 48.239095, lng: 17.293199 },
    { lat: 48.239353, lng: 17.293139 },
    { lat: 48.239349, lng: 17.292593 },
    { lat: 48.239624, lng: 17.292522 },
    { lat: 48.239839, lng: 17.292407 },
    { lat: 48.239874, lng: 17.292343 },
    { lat: 48.239945, lng: 17.292141 },
    { lat: 48.240029, lng: 17.29185 },
    { lat: 48.240104, lng: 17.291664 },
    { lat: 48.240191, lng: 17.291434 },
    { lat: 48.240276, lng: 17.291214 },
    { lat: 48.240363, lng: 17.290958 },
    { lat: 48.240423, lng: 17.290809 },
    { lat: 48.240495, lng: 17.29063 },
    { lat: 48.240706, lng: 17.290135 },
    { lat: 48.24087, lng: 17.289776 },
    { lat: 48.2411, lng: 17.289574 },
    { lat: 48.241151, lng: 17.289473 },
    { lat: 48.241319, lng: 17.289219 },
    { lat: 48.241397, lng: 17.289102 },
    { lat: 48.241453, lng: 17.289044 },
    { lat: 48.241552, lng: 17.28886 },
    { lat: 48.241533, lng: 17.288689 },
    { lat: 48.241483, lng: 17.288352 },
    { lat: 48.241446, lng: 17.288201 },
    { lat: 48.241411, lng: 17.288042 },
    { lat: 48.241389, lng: 17.287862 },
    { lat: 48.241882, lng: 17.287719 },
    { lat: 48.241861, lng: 17.287512 },
    { lat: 48.242098, lng: 17.287379 },
    { lat: 48.242049, lng: 17.286928 },
    { lat: 48.242036, lng: 17.286779 },
    { lat: 48.242019, lng: 17.286562 },
    { lat: 48.241982, lng: 17.286069 },
    { lat: 48.241957, lng: 17.285606 },
    { lat: 48.241925, lng: 17.285291 },
    { lat: 48.241892, lng: 17.28496 },
    { lat: 48.24188, lng: 17.284785 },
    { lat: 48.241841, lng: 17.284356 },
    { lat: 48.241811, lng: 17.284144 },
    { lat: 48.241779, lng: 17.283792 },
    { lat: 48.24177, lng: 17.283686 },
    { lat: 48.241785, lng: 17.283677 },
    { lat: 48.241762, lng: 17.283607 },
    { lat: 48.241557, lng: 17.282927 },
    { lat: 48.241529, lng: 17.282862 },
    { lat: 48.241429, lng: 17.282807 },
    { lat: 48.241388, lng: 17.282734 },
    { lat: 48.241191, lng: 17.282612 },
    { lat: 48.240717, lng: 17.28231 },
    { lat: 48.240559, lng: 17.282253 },
    { lat: 48.240406, lng: 17.28221 },
    { lat: 48.240218, lng: 17.282164 },
    { lat: 48.240072, lng: 17.28209 },
    { lat: 48.240026, lng: 17.28208 },
    { lat: 48.239975, lng: 17.282056 },
    { lat: 48.239965, lng: 17.282002 },
    { lat: 48.23997, lng: 17.281714 },
    { lat: 48.239941, lng: 17.281397 },
    { lat: 48.239958, lng: 17.281247 },
    { lat: 48.239897, lng: 17.281132 },
    { lat: 48.239762, lng: 17.280719 },
    { lat: 48.239666, lng: 17.280576 },
    { lat: 48.239446, lng: 17.28035 },
    { lat: 48.239453, lng: 17.280156 },
    { lat: 48.239408, lng: 17.27997 },
    { lat: 48.239386, lng: 17.279807 },
    { lat: 48.239358, lng: 17.279621 },
    { lat: 48.239336, lng: 17.279447 },
    { lat: 48.239276, lng: 17.279072 },
    { lat: 48.239239, lng: 17.278802 },
    { lat: 48.239223, lng: 17.278652 },
    { lat: 48.239171, lng: 17.278322 },
    { lat: 48.239127, lng: 17.278033 },
    { lat: 48.2391, lng: 17.277613 },
    { lat: 48.239053, lng: 17.277321 },
    { lat: 48.239112, lng: 17.277152 },
    { lat: 48.239246, lng: 17.27685 },
    { lat: 48.239392, lng: 17.276522 },
    { lat: 48.23952, lng: 17.276316 },
    { lat: 48.239721, lng: 17.276044 },
    { lat: 48.239783, lng: 17.275935 },
    { lat: 48.239801, lng: 17.275503 },
    { lat: 48.239861, lng: 17.275167 },
    { lat: 48.239896, lng: 17.274953 },
    { lat: 48.239944, lng: 17.274514 },
    { lat: 48.239988, lng: 17.27413 },
    { lat: 48.239983, lng: 17.274093 },
    { lat: 48.23983, lng: 17.274316 },
    { lat: 48.239691, lng: 17.274394 },
    { lat: 48.2396749, lng: 17.2742405 },
    { lat: 48.239643, lng: 17.273937 },
    { lat: 48.239528, lng: 17.272942 },
    { lat: 48.239388, lng: 17.271718 },
    { lat: 48.23929, lng: 17.270894 },
    { lat: 48.239233, lng: 17.270386 },
    { lat: 48.239174, lng: 17.269891 },
    { lat: 48.239062, lng: 17.268881 },
    { lat: 48.238948, lng: 17.267841 },
    { lat: 48.238887, lng: 17.26734 },
    { lat: 48.23883, lng: 17.26682 },
    { lat: 48.238714, lng: 17.265809 },
    { lat: 48.238598, lng: 17.264784 },
    { lat: 48.238564, lng: 17.26449 },
    { lat: 48.238482, lng: 17.263777 },
    { lat: 48.238425, lng: 17.263248 },
    { lat: 48.238367, lng: 17.262749 },
    { lat: 48.238288, lng: 17.262029 },
    { lat: 48.23825, lng: 17.261731 },
    { lat: 48.238216, lng: 17.261388 },
    { lat: 48.238521, lng: 17.261152 },
    { lat: 48.238663, lng: 17.260965 },
    { lat: 48.238935, lng: 17.260696 },
    { lat: 48.239631, lng: 17.260584 },
    { lat: 48.239779, lng: 17.260147 },
    { lat: 48.239832, lng: 17.259951 },
    { lat: 48.239872, lng: 17.25968 },
    { lat: 48.239951, lng: 17.259443 },
    { lat: 48.24009, lng: 17.259187 },
    { lat: 48.240075, lng: 17.259064 },
    { lat: 48.240533, lng: 17.258909 },
    { lat: 48.240884, lng: 17.258781 },
    { lat: 48.241166, lng: 17.258825 },
    { lat: 48.241265, lng: 17.258728 },
    { lat: 48.241339, lng: 17.258608 },
    { lat: 48.241391, lng: 17.258475 },
    { lat: 48.241481, lng: 17.258231 },
    { lat: 48.241557, lng: 17.25808 },
    { lat: 48.241699, lng: 17.257773 },
    { lat: 48.241788, lng: 17.257496 },
    { lat: 48.241666, lng: 17.257368 },
    { lat: 48.241585, lng: 17.257293 },
    { lat: 48.241561, lng: 17.257269 },
    { lat: 48.24111, lng: 17.256627 },
    { lat: 48.240838, lng: 17.25624 },
    { lat: 48.240725, lng: 17.256078 },
    { lat: 48.240538, lng: 17.255812 },
    { lat: 48.240411, lng: 17.255633 },
    { lat: 48.240154, lng: 17.255269 },
    { lat: 48.240119, lng: 17.255221 },
    { lat: 48.239539, lng: 17.254567 },
    { lat: 48.239529, lng: 17.254557 },
    { lat: 48.239187, lng: 17.254176 },
    { lat: 48.23918, lng: 17.254168 },
    { lat: 48.238835, lng: 17.253783 },
    { lat: 48.238597, lng: 17.253518 },
    { lat: 48.238186, lng: 17.253065 },
    { lat: 48.238045, lng: 17.252907 },
    { lat: 48.237988, lng: 17.252845 },
    { lat: 48.237974, lng: 17.252829 },
    { lat: 48.237903, lng: 17.252753 },
    { lat: 48.237726, lng: 17.252561 },
    { lat: 48.237684, lng: 17.252515 },
    { lat: 48.237435, lng: 17.252246 },
    { lat: 48.237298, lng: 17.252098 },
    { lat: 48.237233, lng: 17.252026 },
    { lat: 48.237186, lng: 17.251976 },
    { lat: 48.23705, lng: 17.251826 },
    { lat: 48.236952, lng: 17.25172 },
    { lat: 48.236941, lng: 17.251708 },
    { lat: 48.236804, lng: 17.251557 },
    { lat: 48.236615, lng: 17.251352 },
    { lat: 48.236493, lng: 17.251219 },
    { lat: 48.236378, lng: 17.251097 },
    { lat: 48.236288, lng: 17.251003 },
    { lat: 48.236259, lng: 17.250972 },
    { lat: 48.236147, lng: 17.250855 },
    { lat: 48.236122, lng: 17.250827 },
    { lat: 48.236092, lng: 17.250797 },
    { lat: 48.235903, lng: 17.250399 },
    { lat: 48.235534, lng: 17.249618 },
    { lat: 48.235141, lng: 17.248784 },
    { lat: 48.234626, lng: 17.247699 },
    { lat: 48.234439, lng: 17.247307 },
    { lat: 48.234419, lng: 17.247298 },
    { lat: 48.233643, lng: 17.24637 },
    { lat: 48.233623, lng: 17.246346 },
    { lat: 48.23305, lng: 17.245662 },
    { lat: 48.232912, lng: 17.245512 },
    { lat: 48.232105, lng: 17.244567 },
    { lat: 48.231922, lng: 17.244353 },
    { lat: 48.231904, lng: 17.24433 },
    { lat: 48.231812, lng: 17.244224 },
    { lat: 48.23169, lng: 17.244061 },
    { lat: 48.231645, lng: 17.244 },
    { lat: 48.231615, lng: 17.243964 },
    { lat: 48.231597, lng: 17.243942 },
    { lat: 48.231482, lng: 17.243795 },
    { lat: 48.231229, lng: 17.243493 },
    { lat: 48.230922, lng: 17.243124 },
    { lat: 48.230667, lng: 17.242825 },
    { lat: 48.230497, lng: 17.242614 },
    { lat: 48.230336, lng: 17.242393 },
    { lat: 48.230022, lng: 17.241954 },
    { lat: 48.229779, lng: 17.241615 },
    { lat: 48.229405, lng: 17.241091 },
    { lat: 48.229097, lng: 17.240659 },
    { lat: 48.228916, lng: 17.240413 },
    { lat: 48.228602, lng: 17.239967 },
    { lat: 48.228344, lng: 17.239616 },
    { lat: 48.228119, lng: 17.23929 },
    { lat: 48.227986, lng: 17.239073 },
    { lat: 48.227736, lng: 17.238605 },
    { lat: 48.227422, lng: 17.238007 },
    { lat: 48.227207, lng: 17.237585 },
    { lat: 48.227076, lng: 17.237331 },
    { lat: 48.22711, lng: 17.236593 },
    { lat: 48.227144, lng: 17.236004 },
    { lat: 48.227174, lng: 17.23548 },
    { lat: 48.227186, lng: 17.235208 },
    { lat: 48.227201, lng: 17.235069 },
    { lat: 48.227227, lng: 17.234565 },
    { lat: 48.227251, lng: 17.23411 },
    { lat: 48.227289, lng: 17.233414 },
    { lat: 48.227331, lng: 17.232646 },
    { lat: 48.227363, lng: 17.232088 },
    { lat: 48.227719, lng: 17.231888 },
    { lat: 48.227913, lng: 17.231778 },
    { lat: 48.228194, lng: 17.23165 },
    { lat: 48.228377, lng: 17.231535 },
    { lat: 48.228843, lng: 17.231261 },
    { lat: 48.229004, lng: 17.231182 },
    { lat: 48.22962, lng: 17.230852 },
    { lat: 48.230145, lng: 17.230557 },
    { lat: 48.23063, lng: 17.230265 },
    { lat: 48.23102, lng: 17.230024 },
    { lat: 48.230846, lng: 17.228495 },
    { lat: 48.230767, lng: 17.227121 },
    { lat: 48.230758, lng: 17.227032 },
    { lat: 48.230657, lng: 17.225467 },
    { lat: 48.23059, lng: 17.225331 },
    { lat: 48.230427, lng: 17.225006 },
    { lat: 48.230292, lng: 17.224737 },
    { lat: 48.230067, lng: 17.224291 },
    { lat: 48.229924, lng: 17.224005 },
    { lat: 48.229757, lng: 17.223672 },
    { lat: 48.229627, lng: 17.223412 },
    { lat: 48.229506, lng: 17.22317 },
    { lat: 48.229426, lng: 17.223012 },
    { lat: 48.229298, lng: 17.222755 },
    { lat: 48.229174, lng: 17.222508 },
    { lat: 48.229066, lng: 17.222294 },
    { lat: 48.228726, lng: 17.222035 },
    { lat: 48.228404, lng: 17.221792 },
    { lat: 48.227983, lng: 17.221472 },
    { lat: 48.227724, lng: 17.221275 },
    { lat: 48.227426, lng: 17.221176 },
    { lat: 48.227158, lng: 17.221084 },
    { lat: 48.226859, lng: 17.22098 },
    { lat: 48.226745, lng: 17.220941 },
    { lat: 48.226413, lng: 17.220822 },
    { lat: 48.226068, lng: 17.220612 },
    { lat: 48.225691, lng: 17.220383 },
    { lat: 48.225429, lng: 17.220223 },
    { lat: 48.225354, lng: 17.220178 },
    { lat: 48.224952, lng: 17.219821 },
    { lat: 48.22484, lng: 17.219723 },
    { lat: 48.224731, lng: 17.219627 },
    { lat: 48.224502, lng: 17.219425 },
    { lat: 48.224414, lng: 17.219349 },
    { lat: 48.224394, lng: 17.219331 },
    { lat: 48.224161, lng: 17.219439 },
    { lat: 48.223839, lng: 17.219608 },
    { lat: 48.223543, lng: 17.219755 },
    { lat: 48.22316, lng: 17.219945 },
    { lat: 48.222815, lng: 17.220109 },
    { lat: 48.22253, lng: 17.220249 },
    { lat: 48.222207, lng: 17.22042 },
    { lat: 48.221947, lng: 17.220778 },
    { lat: 48.221679, lng: 17.221143 },
    { lat: 48.221598, lng: 17.221612 },
    { lat: 48.221524, lng: 17.22209 },
    { lat: 48.221443, lng: 17.222591 },
    { lat: 48.221331, lng: 17.223021 },
    { lat: 48.221204, lng: 17.223493 },
    { lat: 48.221052, lng: 17.224066 },
    { lat: 48.221029, lng: 17.224137 },
    { lat: 48.2208, lng: 17.224491 },
    { lat: 48.220584, lng: 17.224822 },
    { lat: 48.22029, lng: 17.22527 },
    { lat: 48.219958, lng: 17.225786 },
    { lat: 48.219531, lng: 17.226103 },
    { lat: 48.219159, lng: 17.226393 },
    { lat: 48.218846, lng: 17.226639 },
    { lat: 48.218468, lng: 17.226934 },
    { lat: 48.218129, lng: 17.227185 },
    { lat: 48.217769, lng: 17.227475 },
    { lat: 48.217412, lng: 17.227761 },
    { lat: 48.217257, lng: 17.227891 },
    { lat: 48.216971, lng: 17.22813 },
    { lat: 48.216862, lng: 17.228222 },
    { lat: 48.216171, lng: 17.228802 },
    { lat: 48.215768, lng: 17.229154 },
    { lat: 48.215551, lng: 17.229573 },
    { lat: 48.215336, lng: 17.229988 },
    { lat: 48.215094, lng: 17.230463 },
    { lat: 48.214879, lng: 17.230881 },
    { lat: 48.214636, lng: 17.23136 },
    { lat: 48.2144, lng: 17.231824 },
    { lat: 48.214323, lng: 17.23197 },
    { lat: 48.214134, lng: 17.232314 },
    { lat: 48.213961, lng: 17.232631 },
    { lat: 48.213743, lng: 17.233025 },
    { lat: 48.213564, lng: 17.233361 },
    { lat: 48.213364, lng: 17.233734 },
    { lat: 48.213164, lng: 17.234099 },
    { lat: 48.212967, lng: 17.234462 },
    { lat: 48.212943, lng: 17.234516 },
    { lat: 48.212839, lng: 17.23485 },
    { lat: 48.212736, lng: 17.235172 },
    { lat: 48.212601, lng: 17.235571 },
    { lat: 48.212516, lng: 17.235841 },
    { lat: 48.21238, lng: 17.236252 },
    { lat: 48.212254, lng: 17.236652 },
    { lat: 48.212099, lng: 17.237136 },
    { lat: 48.211997, lng: 17.237441 },
    { lat: 48.211839, lng: 17.237906 },
    { lat: 48.21015, lng: 17.241936 },
    { lat: 48.210095, lng: 17.24196 },
    { lat: 48.209508, lng: 17.242219 },
    { lat: 48.209244, lng: 17.24226 },
    { lat: 48.209004, lng: 17.242381 },
    { lat: 48.208819, lng: 17.242421 },
    { lat: 48.20852, lng: 17.24262 },
    { lat: 48.20815, lng: 17.243003 },
    { lat: 48.208096, lng: 17.243033 },
    { lat: 48.208065, lng: 17.242977 },
    { lat: 48.207194, lng: 17.241407 },
    { lat: 48.206827, lng: 17.23962 },
    { lat: 48.206775, lng: 17.239372 },
    { lat: 48.206766, lng: 17.239324 },
    { lat: 48.20668, lng: 17.238894 },
    { lat: 48.206556, lng: 17.238298 },
    { lat: 48.206276, lng: 17.237273 },
    { lat: 48.20621, lng: 17.237002 },
    { lat: 48.20605, lng: 17.236377 },
    { lat: 48.205907, lng: 17.235805 },
    { lat: 48.205767, lng: 17.235202 },
    { lat: 48.205644, lng: 17.234699 },
    { lat: 48.205553, lng: 17.234343 },
    { lat: 48.205529, lng: 17.234369 },
    { lat: 48.205138, lng: 17.233907 },
    { lat: 48.204664, lng: 17.233344 },
    { lat: 48.204442, lng: 17.233118 },
    { lat: 48.204262, lng: 17.232886 },
    { lat: 48.204226, lng: 17.23293 },
    { lat: 48.2039377, lng: 17.233272 },
    { lat: 48.2036889, lng: 17.2335754 },
    { lat: 48.2032788, lng: 17.2340658 },
    { lat: 48.2032177, lng: 17.2342161 },
    { lat: 48.2030521, lng: 17.2346471 },
    { lat: 48.2029884, lng: 17.2348266 },
    { lat: 48.2028806, lng: 17.2351071 },
    { lat: 48.2023712, lng: 17.2359572 },
    { lat: 48.2019022, lng: 17.2369211 },
    { lat: 48.2012207, lng: 17.2382563 },
    { lat: 48.2019418, lng: 17.2408906 },
    { lat: 48.2026754, lng: 17.2433915 },
    { lat: 48.2029788, lng: 17.2444532 },
    { lat: 48.2035049, lng: 17.2462988 },
    { lat: 48.2035795, lng: 17.2465531 },
    { lat: 48.203752, lng: 17.2470995 },
    { lat: 48.2039038, lng: 17.2476067 },
    { lat: 48.2040888, lng: 17.2482154 },
    { lat: 48.2041099, lng: 17.2482765 },
    { lat: 48.2046072, lng: 17.2495696 },
    { lat: 48.2046851, lng: 17.2497574 },
    { lat: 48.2041525, lng: 17.2506487 },
    { lat: 48.2039315, lng: 17.2511135 },
    { lat: 48.2044462, lng: 17.2522782 },
    { lat: 48.2044988, lng: 17.2523913 },
    { lat: 48.2046449, lng: 17.2526677 },
    { lat: 48.2048985, lng: 17.2531362 },
    { lat: 48.2054161, lng: 17.2538018 },
    { lat: 48.2057134, lng: 17.2540762 },
    { lat: 48.2059957, lng: 17.2543453 },
    { lat: 48.2061343, lng: 17.2544598 },
    { lat: 48.206811, lng: 17.2549684 },
    { lat: 48.2068577, lng: 17.2550087 },
    { lat: 48.2073367, lng: 17.2553499 },
    { lat: 48.2074131, lng: 17.2553773 },
    { lat: 48.2080489, lng: 17.2556799 },
    { lat: 48.2082106, lng: 17.2558857 },
    { lat: 48.2085074, lng: 17.2562912 },
    { lat: 48.2089002, lng: 17.2565733 },
    { lat: 48.2090287, lng: 17.2562627 },
    { lat: 48.2090856, lng: 17.2560658 },
    { lat: 48.2094596, lng: 17.2562367 },
    { lat: 48.2096999, lng: 17.2563336 },
    { lat: 48.2104215, lng: 17.2558528 },
    { lat: 48.210534, lng: 17.2556255 },
    { lat: 48.2106471, lng: 17.2552867 },
    { lat: 48.2108578, lng: 17.2551269 },
    { lat: 48.2112914, lng: 17.2549027 },
    { lat: 48.2113605, lng: 17.2548705 },
    { lat: 48.2124429, lng: 17.2543122 },
    { lat: 48.2126267, lng: 17.2542214 },
    { lat: 48.2129883, lng: 17.2543256 },
    { lat: 48.2132088, lng: 17.2544644 },
    { lat: 48.2137269, lng: 17.2547812 },
    { lat: 48.2140428, lng: 17.2549802 },
    { lat: 48.2143374, lng: 17.2551788 },
    { lat: 48.2146561, lng: 17.2554036 },
    { lat: 48.2147894, lng: 17.2556222 },
    { lat: 48.2149778, lng: 17.255943 },
    { lat: 48.2151383, lng: 17.2562262 },
    { lat: 48.2153003, lng: 17.2565121 },
    { lat: 48.2154362, lng: 17.2567717 },
    { lat: 48.2158273, lng: 17.2575062 },
    { lat: 48.2160547, lng: 17.2579187 },
    { lat: 48.216519, lng: 17.2586677 },
    { lat: 48.2165454, lng: 17.2587239 },
    { lat: 48.2167128, lng: 17.2594935 },
    { lat: 48.2168371, lng: 17.2613986 },
    { lat: 48.2172736, lng: 17.2630054 },
    { lat: 48.2174522, lng: 17.2637878 },
    { lat: 48.2175934, lng: 17.2643854 },
    { lat: 48.2177473, lng: 17.2650186 },
    { lat: 48.2178765, lng: 17.2655699 },
    { lat: 48.2180163, lng: 17.2661449 },
    { lat: 48.2183153, lng: 17.2673311 },
    { lat: 48.2185053, lng: 17.2681007 },
    { lat: 48.2185447, lng: 17.2682513 },
    { lat: 48.2185705, lng: 17.2685213 },
    { lat: 48.2186556, lng: 17.2688342 },
    { lat: 48.2188654, lng: 17.2696641 },
    { lat: 48.218992, lng: 17.2701087 },
    { lat: 48.2191642, lng: 17.2706612 },
    { lat: 48.2192549, lng: 17.2709359 },
    { lat: 48.2192899, lng: 17.2710566 },
    { lat: 48.2194169, lng: 17.2715408 },
    { lat: 48.2194912, lng: 17.2718441 },
    { lat: 48.2195739, lng: 17.2721547 },
    { lat: 48.2196165, lng: 17.2724713 },
    { lat: 48.2196932, lng: 17.2729991 },
    { lat: 48.2198031, lng: 17.2732947 },
    { lat: 48.2199424, lng: 17.2736408 },
    { lat: 48.220114, lng: 17.2740811 },
    { lat: 48.2202387, lng: 17.2744109 },
    { lat: 48.2204173, lng: 17.2746862 },
    { lat: 48.2208089, lng: 17.2752779 },
    { lat: 48.2211612, lng: 17.2758039 },
    { lat: 48.2212896, lng: 17.276138 },
    { lat: 48.2214265, lng: 17.2764821 },
    { lat: 48.2214802, lng: 17.2766955 },
    { lat: 48.221639, lng: 17.2774564 },
    { lat: 48.2216876, lng: 17.2778143 },
    { lat: 48.2217696, lng: 17.2784548 },
    { lat: 48.2218266, lng: 17.2791961 },
    { lat: 48.221879, lng: 17.2797318 },
    { lat: 48.2220158, lng: 17.2812346 },
    { lat: 48.2220276, lng: 17.2813444 },
    { lat: 48.2220616, lng: 17.2814485 },
    { lat: 48.2222676, lng: 17.2818643 },
    { lat: 48.222948, lng: 17.2829282 },
    { lat: 48.2233537, lng: 17.2834757 },
    { lat: 48.2235144, lng: 17.2836852 },
    { lat: 48.2237188, lng: 17.2839406 },
    { lat: 48.223817, lng: 17.2841432 },
    { lat: 48.22391, lng: 17.2842867 },
    { lat: 48.2240993, lng: 17.284552 },
    { lat: 48.2239504, lng: 17.2845707 },
    { lat: 48.2238048, lng: 17.2845746 },
    { lat: 48.223737, lng: 17.2846404 },
    { lat: 48.2236705, lng: 17.2847466 },
    { lat: 48.2235977, lng: 17.2849096 },
    { lat: 48.223494, lng: 17.2853577 },
    { lat: 48.2233584, lng: 17.2858689 },
    { lat: 48.2230303, lng: 17.286783 },
    { lat: 48.2225362, lng: 17.2883134 },
    { lat: 48.2221919, lng: 17.2894776 },
    { lat: 48.2220948, lng: 17.2897862 },
    { lat: 48.2216444, lng: 17.291261 },
    { lat: 48.2216895, lng: 17.2913796 },
    { lat: 48.2220685, lng: 17.2945488 },
    { lat: 48.2221833, lng: 17.2957896 },
    { lat: 48.2222238, lng: 17.2963854 },
    { lat: 48.2222548, lng: 17.2969235 },
    { lat: 48.2222552, lng: 17.2977163 },
    { lat: 48.222352, lng: 17.2977273 },
    { lat: 48.22233, lng: 17.2980958 },
    { lat: 48.2222652, lng: 17.2988238 },
    { lat: 48.2221974, lng: 17.2993882 },
    { lat: 48.2221029, lng: 17.2999753 },
    { lat: 48.2218614, lng: 17.3012966 },
    { lat: 48.2225466, lng: 17.3015976 },
    { lat: 48.2228025, lng: 17.301777 },
    { lat: 48.223046, lng: 17.3019574 },
    { lat: 48.2227889, lng: 17.3030757 },
    { lat: 48.2223281, lng: 17.305115 },
    { lat: 48.2224488, lng: 17.3051283 },
    { lat: 48.2226694, lng: 17.3050991 },
    { lat: 48.2232839, lng: 17.3049625 },
    { lat: 48.2237476, lng: 17.3048695 },
    { lat: 48.2239233, lng: 17.3048268 },
    { lat: 48.2241285, lng: 17.3047693 },
    { lat: 48.2244842, lng: 17.3046457 },
    { lat: 48.2247751, lng: 17.3045359 },
    { lat: 48.2250755, lng: 17.3043631 },
    { lat: 48.2250413, lng: 17.3045472 },
    { lat: 48.2251427, lng: 17.3047657 },
    { lat: 48.2255695, lng: 17.3053948 },
    { lat: 48.2257642, lng: 17.3061717 },
    { lat: 48.2260486, lng: 17.3070923 },
    { lat: 48.2262003, lng: 17.3075441 },
    { lat: 48.2263255, lng: 17.3078823 },
    { lat: 48.2264731, lng: 17.308202 },
    { lat: 48.2271226, lng: 17.3091958 },
    { lat: 48.22741, lng: 17.3095953 },
    { lat: 48.2275931, lng: 17.3099225 },
    { lat: 48.2276988, lng: 17.3101723 },
    { lat: 48.2282685, lng: 17.3108794 },
    { lat: 48.2287872, lng: 17.3114502 },
    { lat: 48.2289177, lng: 17.3116465 },
    { lat: 48.2289974, lng: 17.3117414 },
    { lat: 48.2293284, lng: 17.3120331 },
    { lat: 48.2294956, lng: 17.3121707 },
    { lat: 48.2299323, lng: 17.3125399 },
    { lat: 48.2299967, lng: 17.3125913 },
    { lat: 48.2302396, lng: 17.3128309 },
    { lat: 48.2300768, lng: 17.3131052 },
    { lat: 48.2296999, lng: 17.3138473 },
    { lat: 48.2291973, lng: 17.3146714 },
    { lat: 48.2290331, lng: 17.3150084 },
    { lat: 48.228962, lng: 17.3151803 },
    { lat: 48.228827, lng: 17.3156153 },
    { lat: 48.2286134, lng: 17.3160273 },
    { lat: 48.2288208, lng: 17.3161403 },
    { lat: 48.2296594, lng: 17.3167985 },
    { lat: 48.2298614, lng: 17.3169327 },
    { lat: 48.2301481, lng: 17.3171524 },
    { lat: 48.2304133, lng: 17.3173711 },
    { lat: 48.2303151, lng: 17.3176806 },
    { lat: 48.2302432, lng: 17.3179778 },
    { lat: 48.2300704, lng: 17.3185959 },
    { lat: 48.2298304, lng: 17.3194098 },
    { lat: 48.2297086, lng: 17.3198083 },
    { lat: 48.2296666, lng: 17.3199075 },
    { lat: 48.2294399, lng: 17.3203764 },
    { lat: 48.2290541, lng: 17.3209675 },
    { lat: 48.2289034, lng: 17.3211148 },
    { lat: 48.2287445, lng: 17.3213774 },
    { lat: 48.2285936, lng: 17.3217277 },
    { lat: 48.228439, lng: 17.3221774 },
    { lat: 48.2283673, lng: 17.3224601 },
    { lat: 48.2282974, lng: 17.3228737 },
    { lat: 48.2294871, lng: 17.3234261 },
    { lat: 48.2304197, lng: 17.3238845 },
    { lat: 48.2303484, lng: 17.3241393 },
    { lat: 48.2302524, lng: 17.324413 },
    { lat: 48.230167, lng: 17.3246091 },
    { lat: 48.2300318, lng: 17.3248538 },
  ],
  DunajskáLužná: [
    { lat: 48.112764, lng: 17.2833 },
    { lat: 48.112482, lng: 17.283288 },
    { lat: 48.110198, lng: 17.283186 },
    { lat: 48.108755, lng: 17.282246 },
    { lat: 48.106399, lng: 17.280724 },
    { lat: 48.106403, lng: 17.280694 },
    { lat: 48.106408, lng: 17.280647 },
    { lat: 48.104182, lng: 17.279092 },
    { lat: 48.102043, lng: 17.277615 },
    { lat: 48.10049, lng: 17.276432 },
    { lat: 48.100327, lng: 17.276307 },
    { lat: 48.100239, lng: 17.27624 },
    { lat: 48.100141, lng: 17.276168 },
    { lat: 48.100122, lng: 17.276152 },
    { lat: 48.100136, lng: 17.276093 },
    { lat: 48.100253, lng: 17.275778 },
    { lat: 48.100484, lng: 17.275165 },
    { lat: 48.100517, lng: 17.27507 },
    { lat: 48.100908, lng: 17.274019 },
    { lat: 48.101104, lng: 17.273507 },
    { lat: 48.101212, lng: 17.273216 },
    { lat: 48.101303, lng: 17.272995 },
    { lat: 48.1010727, lng: 17.272655 },
    { lat: 48.1008062, lng: 17.2722814 },
    { lat: 48.1003517, lng: 17.2716302 },
    { lat: 48.1002501, lng: 17.2714774 },
    { lat: 48.1000233, lng: 17.2711593 },
    { lat: 48.099796, lng: 17.2708338 },
    { lat: 48.0996304, lng: 17.2706028 },
    { lat: 48.0991325, lng: 17.2698924 },
    { lat: 48.0989034, lng: 17.2695552 },
    { lat: 48.0990012, lng: 17.2693941 },
    { lat: 48.0986471, lng: 17.2688783 },
    { lat: 48.0984538, lng: 17.2685864 },
    { lat: 48.0978357, lng: 17.2676662 },
    { lat: 48.0976164, lng: 17.267342 },
    { lat: 48.0972009, lng: 17.2667501 },
    { lat: 48.0969464, lng: 17.2662018 },
    { lat: 48.0968388, lng: 17.2659597 },
    { lat: 48.0965011, lng: 17.2653149 },
    { lat: 48.0963153, lng: 17.2649841 },
    { lat: 48.0961804, lng: 17.264758 },
    { lat: 48.0959506, lng: 17.26441 },
    { lat: 48.0957288, lng: 17.2640636 },
    { lat: 48.0954392, lng: 17.2636174 },
    { lat: 48.0952882, lng: 17.2634011 },
    { lat: 48.0943113, lng: 17.2620309 },
    { lat: 48.0942432, lng: 17.2619574 },
    { lat: 48.0938207, lng: 17.2614838 },
    { lat: 48.0935018, lng: 17.2611382 },
    { lat: 48.0934076, lng: 17.2609785 },
    { lat: 48.0926342, lng: 17.2596447 },
    { lat: 48.0926355, lng: 17.259578 },
    { lat: 48.0921103, lng: 17.258596 },
    { lat: 48.091629, lng: 17.257611 },
    { lat: 48.0913005, lng: 17.2567331 },
    { lat: 48.0910664, lng: 17.256117 },
    { lat: 48.0907301, lng: 17.2555126 },
    { lat: 48.090298, lng: 17.2547075 },
    { lat: 48.0900695, lng: 17.2542786 },
    { lat: 48.0899632, lng: 17.2540903 },
    { lat: 48.0896319, lng: 17.253475 },
    { lat: 48.0894409, lng: 17.2531856 },
    { lat: 48.0893578, lng: 17.2530403 },
    { lat: 48.0891523, lng: 17.2527201 },
    { lat: 48.0887689, lng: 17.2523242 },
    { lat: 48.0886535, lng: 17.2522191 },
    { lat: 48.0884398, lng: 17.2517754 },
    { lat: 48.088109, lng: 17.2511181 },
    { lat: 48.0879676, lng: 17.2508262 },
    { lat: 48.0874063, lng: 17.249693 },
    { lat: 48.0872052, lng: 17.2492862 },
    { lat: 48.0871044, lng: 17.2491637 },
    { lat: 48.0869891, lng: 17.2490097 },
    { lat: 48.0869225, lng: 17.2487911 },
    { lat: 48.0867727, lng: 17.2485613 },
    { lat: 48.0866978, lng: 17.2483011 },
    { lat: 48.0866219, lng: 17.2481116 },
    { lat: 48.0861297, lng: 17.24727 },
    { lat: 48.0858501, lng: 17.246821 },
    { lat: 48.0855365, lng: 17.2463524 },
    { lat: 48.0853512, lng: 17.2460478 },
    { lat: 48.0852174, lng: 17.2458536 },
    { lat: 48.0850917, lng: 17.2456455 },
    { lat: 48.0850623, lng: 17.2455444 },
    { lat: 48.0850636, lng: 17.2452255 },
    { lat: 48.0850972, lng: 17.2446962 },
    { lat: 48.0851523, lng: 17.2444377 },
    { lat: 48.0852047, lng: 17.2443085 },
    { lat: 48.0853444, lng: 17.2441342 },
    { lat: 48.0856893, lng: 17.2438503 },
    { lat: 48.0859062, lng: 17.2437321 },
    { lat: 48.0860552, lng: 17.2437133 },
    { lat: 48.0863661, lng: 17.2437169 },
    { lat: 48.0869086, lng: 17.2438908 },
    { lat: 48.0873107, lng: 17.2435503 },
    { lat: 48.0874795, lng: 17.2433541 },
    { lat: 48.0875636, lng: 17.2431925 },
    { lat: 48.0878033, lng: 17.2420599 },
    { lat: 48.0878788, lng: 17.2415664 },
    { lat: 48.0879244, lng: 17.241048 },
    { lat: 48.0879515, lng: 17.2408786 },
    { lat: 48.088, lng: 17.240708 },
    { lat: 48.0880276, lng: 17.2405285 },
    { lat: 48.0881204, lng: 17.2402103 },
    { lat: 48.0881826, lng: 17.2399397 },
    { lat: 48.088213, lng: 17.2397698 },
    { lat: 48.0883192, lng: 17.2389733 },
    { lat: 48.0882849, lng: 17.2383926 },
    { lat: 48.088249, lng: 17.2381079 },
    { lat: 48.0882367, lng: 17.2378569 },
    { lat: 48.0882395, lng: 17.237713 },
    { lat: 48.0882029, lng: 17.2375372 },
    { lat: 48.0881419, lng: 17.237333 },
    { lat: 48.0876748, lng: 17.2365476 },
    { lat: 48.0874665, lng: 17.2363712 },
    { lat: 48.0869637, lng: 17.23571 },
    { lat: 48.0865974, lng: 17.2353674 },
    { lat: 48.0864805, lng: 17.2352254 },
    { lat: 48.0862753, lng: 17.2350284 },
    { lat: 48.0858958, lng: 17.2346911 },
    { lat: 48.0854537, lng: 17.2342453 },
    { lat: 48.0852057, lng: 17.2338133 },
    { lat: 48.0849433, lng: 17.2333189 },
    { lat: 48.0848286, lng: 17.2330222 },
    { lat: 48.0846455, lng: 17.232649 },
    { lat: 48.0845824, lng: 17.2324655 },
    { lat: 48.0845697, lng: 17.2322421 },
    { lat: 48.0845735, lng: 17.2319432 },
    { lat: 48.0846454, lng: 17.2314469 },
    { lat: 48.0846627, lng: 17.2312496 },
    { lat: 48.0846168, lng: 17.2310019 },
    { lat: 48.0845755, lng: 17.2307045 },
    { lat: 48.0844636, lng: 17.2302827 },
    { lat: 48.0843469, lng: 17.2300741 },
    { lat: 48.0842606, lng: 17.2298982 },
    { lat: 48.0840661, lng: 17.2295916 },
    { lat: 48.0838556, lng: 17.2291805 },
    { lat: 48.0838374, lng: 17.2290433 },
    { lat: 48.0838379, lng: 17.228442 },
    { lat: 48.0838417, lng: 17.2283134 },
    { lat: 48.0838973, lng: 17.2280126 },
    { lat: 48.0839695, lng: 17.2277918 },
    { lat: 48.0841319, lng: 17.2274828 },
    { lat: 48.0842423, lng: 17.2272083 },
    { lat: 48.0842776, lng: 17.2270099 },
    { lat: 48.0842788, lng: 17.226622 },
    { lat: 48.0843728, lng: 17.2262701 },
    { lat: 48.0844583, lng: 17.2260616 },
    { lat: 48.0845465, lng: 17.2258774 },
    { lat: 48.0846259, lng: 17.2255612 },
    { lat: 48.0846688, lng: 17.2254082 },
    { lat: 48.0847518, lng: 17.2251976 },
    { lat: 48.0848613, lng: 17.2249934 },
    { lat: 48.0849141, lng: 17.2248532 },
    { lat: 48.0849887, lng: 17.2245672 },
    { lat: 48.08506, lng: 17.2243829 },
    { lat: 48.0851215, lng: 17.224104 },
    { lat: 48.0851299, lng: 17.2239425 },
    { lat: 48.0852377, lng: 17.2234258 },
    { lat: 48.0852928, lng: 17.2232531 },
    { lat: 48.085342, lng: 17.2230594 },
    { lat: 48.0855002, lng: 17.2226388 },
    { lat: 48.0856534, lng: 17.2221751 },
    { lat: 48.0857679, lng: 17.222029 },
    { lat: 48.0858417, lng: 17.2219664 },
    { lat: 48.0859246, lng: 17.2219398 },
    { lat: 48.0862729, lng: 17.2219444 },
    { lat: 48.0863186, lng: 17.221919 },
    { lat: 48.0863835, lng: 17.2218083 },
    { lat: 48.0864327, lng: 17.2217672 },
    { lat: 48.0865456, lng: 17.2217319 },
    { lat: 48.0866119, lng: 17.2216925 },
    { lat: 48.0872343, lng: 17.2214723 },
    { lat: 48.0875611, lng: 17.2213418 },
    { lat: 48.0878812, lng: 17.2212798 },
    { lat: 48.0885496, lng: 17.2210376 },
    { lat: 48.0887455, lng: 17.2209258 },
    { lat: 48.0888076, lng: 17.2209251 },
    { lat: 48.0890493, lng: 17.2208738 },
    { lat: 48.0889903, lng: 17.2206169 },
    { lat: 48.0889236, lng: 17.2203968 },
    { lat: 48.0884768, lng: 17.219122 },
    { lat: 48.0881909, lng: 17.2182777 },
    { lat: 48.0876004, lng: 17.2164434 },
    { lat: 48.0874831, lng: 17.216074 },
    { lat: 48.0874156, lng: 17.2158767 },
    { lat: 48.0869992, lng: 17.2147862 },
    { lat: 48.0869015, lng: 17.2145261 },
    { lat: 48.086698, lng: 17.2141012 },
    { lat: 48.0865939, lng: 17.2138966 },
    { lat: 48.0863116, lng: 17.213443 },
    { lat: 48.0862488, lng: 17.2133327 },
    { lat: 48.0861671, lng: 17.2132091 },
    { lat: 48.0858419, lng: 17.2130595 },
    { lat: 48.0855511, lng: 17.2129856 },
    { lat: 48.08476, lng: 17.2124082 },
    { lat: 48.084474, lng: 17.2121862 },
    { lat: 48.0839894, lng: 17.211783 },
    { lat: 48.0838039, lng: 17.2113234 },
    { lat: 48.0835, lng: 17.210579 },
    { lat: 48.083132, lng: 17.210959 },
    { lat: 48.082656, lng: 17.211361 },
    { lat: 48.082144, lng: 17.211683 },
    { lat: 48.08187, lng: 17.211811 },
    { lat: 48.081709, lng: 17.211867 },
    { lat: 48.081194, lng: 17.212043 },
    { lat: 48.080737, lng: 17.212152 },
    { lat: 48.080002, lng: 17.212103 },
    { lat: 48.079488, lng: 17.211876 },
    { lat: 48.07895, lng: 17.211547 },
    { lat: 48.07866, lng: 17.211321 },
    { lat: 48.078334, lng: 17.211068 },
    { lat: 48.078046, lng: 17.210784 },
    { lat: 48.077596, lng: 17.210278 },
    { lat: 48.077103, lng: 17.209699 },
    { lat: 48.076655, lng: 17.209052 },
    { lat: 48.076481, lng: 17.208692 },
    { lat: 48.076118, lng: 17.207786 },
    { lat: 48.075997, lng: 17.207341 },
    { lat: 48.075674, lng: 17.205884 },
    { lat: 48.075584, lng: 17.205502 },
    { lat: 48.075574, lng: 17.205458 },
    { lat: 48.075567, lng: 17.205429 },
    { lat: 48.075449, lng: 17.204659 },
    { lat: 48.075429, lng: 17.204329 },
    { lat: 48.075195, lng: 17.204109 },
    { lat: 48.074922, lng: 17.203866 },
    { lat: 48.07407, lng: 17.203096 },
    { lat: 48.073769, lng: 17.202859 },
    { lat: 48.073561, lng: 17.202722 },
    { lat: 48.073152, lng: 17.202423 },
    { lat: 48.072805, lng: 17.202175 },
    { lat: 48.072644, lng: 17.202082 },
    { lat: 48.07254, lng: 17.202019 },
    { lat: 48.072206, lng: 17.201797 },
    { lat: 48.071943, lng: 17.201707 },
    { lat: 48.071336, lng: 17.201387 },
    { lat: 48.071106, lng: 17.201193 },
    { lat: 48.070791, lng: 17.201079 },
    { lat: 48.070543, lng: 17.200828 },
    { lat: 48.070472, lng: 17.200907 },
    { lat: 48.070391, lng: 17.201122 },
    { lat: 48.070187, lng: 17.201547 },
    { lat: 48.070145, lng: 17.201668 },
    { lat: 48.070058, lng: 17.202079 },
    { lat: 48.069813, lng: 17.202024 },
    { lat: 48.069566, lng: 17.20195 },
    { lat: 48.069096, lng: 17.201585 },
    { lat: 48.06904, lng: 17.20152 },
    { lat: 48.068842, lng: 17.201353 },
    { lat: 48.068706, lng: 17.201293 },
    { lat: 48.068571, lng: 17.201127 },
    { lat: 48.068376, lng: 17.200833 },
    { lat: 48.068324, lng: 17.200725 },
    { lat: 48.068069, lng: 17.200864 },
    { lat: 48.067077, lng: 17.201695 },
    { lat: 48.066658, lng: 17.20149 },
    { lat: 48.066168, lng: 17.201209 },
    { lat: 48.065449, lng: 17.200839 },
    { lat: 48.065435, lng: 17.200832 },
    { lat: 48.065396, lng: 17.200812 },
    { lat: 48.065372, lng: 17.2008 },
    { lat: 48.064971, lng: 17.200944 },
    { lat: 48.064385, lng: 17.201142 },
    { lat: 48.063855, lng: 17.201324 },
    { lat: 48.063172, lng: 17.201561 },
    { lat: 48.062564, lng: 17.201769 },
    { lat: 48.061663, lng: 17.202084 },
    { lat: 48.0615436, lng: 17.2025032 },
    { lat: 48.0612006, lng: 17.2036542 },
    { lat: 48.0606289, lng: 17.2055908 },
    { lat: 48.0600201, lng: 17.2076477 },
    { lat: 48.0598932, lng: 17.2080634 },
    { lat: 48.0588856, lng: 17.2088576 },
    { lat: 48.0581423, lng: 17.2094302 },
    { lat: 48.0577486, lng: 17.2097266 },
    { lat: 48.0585458, lng: 17.2097366 },
    { lat: 48.0590705, lng: 17.2097611 },
    { lat: 48.0595573, lng: 17.2091525 },
    { lat: 48.0599475, lng: 17.2088061 },
    { lat: 48.0604054, lng: 17.2084411 },
    { lat: 48.0606061, lng: 17.2083153 },
    { lat: 48.0610441, lng: 17.2081588 },
    { lat: 48.0612209, lng: 17.2080676 },
    { lat: 48.0616629, lng: 17.2077327 },
    { lat: 48.0618335, lng: 17.2075826 },
    { lat: 48.0620739, lng: 17.2073588 },
    { lat: 48.0624368, lng: 17.2070931 },
    { lat: 48.0625584, lng: 17.2070546 },
    { lat: 48.0626724, lng: 17.2070528 },
    { lat: 48.0627212, lng: 17.2070895 },
    { lat: 48.0627393, lng: 17.2071398 },
    { lat: 48.0627475, lng: 17.2072118 },
    { lat: 48.0627325, lng: 17.2072916 },
    { lat: 48.0626653, lng: 17.2075199 },
    { lat: 48.0626674, lng: 17.2076511 },
    { lat: 48.0628591, lng: 17.2079335 },
    { lat: 48.0630366, lng: 17.2081371 },
    { lat: 48.0633518, lng: 17.2084397 },
    { lat: 48.0633777, lng: 17.2085066 },
    { lat: 48.0634352, lng: 17.2087062 },
    { lat: 48.0635114, lng: 17.2090849 },
    { lat: 48.0635287, lng: 17.2092249 },
    { lat: 48.063553, lng: 17.2097226 },
    { lat: 48.0635347, lng: 17.2099377 },
    { lat: 48.0634688, lng: 17.210202 },
    { lat: 48.0633632, lng: 17.2105641 },
    { lat: 48.0632851, lng: 17.2107308 },
    { lat: 48.0632371, lng: 17.2107902 },
    { lat: 48.0631112, lng: 17.2109008 },
    { lat: 48.0630669, lng: 17.2109639 },
    { lat: 48.0630072, lng: 17.2111026 },
    { lat: 48.0629745, lng: 17.2113568 },
    { lat: 48.0629944, lng: 17.2115006 },
    { lat: 48.0630395, lng: 17.2116684 },
    { lat: 48.0631668, lng: 17.2120665 },
    { lat: 48.0632845, lng: 17.2123903 },
    { lat: 48.0637444, lng: 17.2129157 },
    { lat: 48.0638185, lng: 17.2130259 },
    { lat: 48.0639796, lng: 17.213323 },
    { lat: 48.0640385, lng: 17.213578 },
    { lat: 48.064055, lng: 17.2138233 },
    { lat: 48.0640312, lng: 17.2140248 },
    { lat: 48.0638764, lng: 17.2144634 },
    { lat: 48.0636677, lng: 17.2148037 },
    { lat: 48.0635903, lng: 17.2150487 },
    { lat: 48.0635321, lng: 17.2154274 },
    { lat: 48.0633181, lng: 17.2162143 },
    { lat: 48.0632362, lng: 17.2167288 },
    { lat: 48.0634849, lng: 17.2164785 },
    { lat: 48.0638548, lng: 17.2161142 },
    { lat: 48.0644446, lng: 17.215609 },
    { lat: 48.0645727, lng: 17.2155309 },
    { lat: 48.0647207, lng: 17.215396 },
    { lat: 48.0648546, lng: 17.2153372 },
    { lat: 48.0650115, lng: 17.2153189 },
    { lat: 48.0652646, lng: 17.2152684 },
    { lat: 48.0653864, lng: 17.2152824 },
    { lat: 48.065502, lng: 17.2153898 },
    { lat: 48.0657994, lng: 17.2155789 },
    { lat: 48.0659355, lng: 17.2157036 },
    { lat: 48.0661244, lng: 17.2160622 },
    { lat: 48.0663384, lng: 17.2163725 },
    { lat: 48.066516, lng: 17.2166124 },
    { lat: 48.0666422, lng: 17.2168438 },
    { lat: 48.0667304, lng: 17.2170641 },
    { lat: 48.0668574, lng: 17.2172559 },
    { lat: 48.0670356, lng: 17.2175057 },
    { lat: 48.0671479, lng: 17.2176297 },
    { lat: 48.0672155, lng: 17.2177628 },
    { lat: 48.067449, lng: 17.2181275 },
    { lat: 48.0672795, lng: 17.2183475 },
    { lat: 48.0670636, lng: 17.218598 },
    { lat: 48.0668902, lng: 17.2187932 },
    { lat: 48.0668073, lng: 17.2191738 },
    { lat: 48.0668684, lng: 17.219496 },
    { lat: 48.0669804, lng: 17.2197514 },
    { lat: 48.0670622, lng: 17.2198927 },
    { lat: 48.0671814, lng: 17.2202221 },
    { lat: 48.0672978, lng: 17.2203413 },
    { lat: 48.0675959, lng: 17.2206264 },
    { lat: 48.0677781, lng: 17.2208671 },
    { lat: 48.0680921, lng: 17.2212544 },
    { lat: 48.0682063, lng: 17.22139 },
    { lat: 48.0683643, lng: 17.2214887 },
    { lat: 48.0686409, lng: 17.2216724 },
    { lat: 48.0689565, lng: 17.2218638 },
    { lat: 48.0690874, lng: 17.2222321 },
    { lat: 48.0690919, lng: 17.2225373 },
    { lat: 48.06905, lng: 17.2230425 },
    { lat: 48.0688875, lng: 17.2236179 },
    { lat: 48.0688431, lng: 17.2237829 },
    { lat: 48.0687449, lng: 17.2244423 },
    { lat: 48.0687381, lng: 17.2244753 },
    { lat: 48.0687444, lng: 17.2250255 },
    { lat: 48.0687421, lng: 17.225599 },
    { lat: 48.0687275, lng: 17.2260732 },
    { lat: 48.0687277, lng: 17.2266842 },
    { lat: 48.068747, lng: 17.2270572 },
    { lat: 48.0687624, lng: 17.2274738 },
    { lat: 48.0687759, lng: 17.2279631 },
    { lat: 48.0687664, lng: 17.2282433 },
    { lat: 48.0687536, lng: 17.22844 },
    { lat: 48.0686607, lng: 17.2289257 },
    { lat: 48.0685889, lng: 17.2292879 },
    { lat: 48.0684756, lng: 17.2298239 },
    { lat: 48.0683987, lng: 17.2302626 },
    { lat: 48.0684546, lng: 17.2306589 },
    { lat: 48.0684688, lng: 17.2309213 },
    { lat: 48.0683505, lng: 17.2315356 },
    { lat: 48.0681291, lng: 17.2320422 },
    { lat: 48.0678443, lng: 17.2322776 },
    { lat: 48.0675282, lng: 17.2325858 },
    { lat: 48.0672927, lng: 17.2327136 },
    { lat: 48.0670447, lng: 17.2328229 },
    { lat: 48.0669607, lng: 17.2328675 },
    { lat: 48.0666352, lng: 17.2330685 },
    { lat: 48.0664614, lng: 17.233171 },
    { lat: 48.0663003, lng: 17.2335337 },
    { lat: 48.0662426, lng: 17.233769 },
    { lat: 48.0662242, lng: 17.2338654 },
    { lat: 48.0662233, lng: 17.2344276 },
    { lat: 48.0663496, lng: 17.2352187 },
    { lat: 48.0664588, lng: 17.2355007 },
    { lat: 48.066564, lng: 17.2358237 },
    { lat: 48.066794, lng: 17.2362565 },
    { lat: 48.0669219, lng: 17.2364803 },
    { lat: 48.0671863, lng: 17.2369204 },
    { lat: 48.0673092, lng: 17.2371541 },
    { lat: 48.0674403, lng: 17.2372897 },
    { lat: 48.0676192, lng: 17.2373971 },
    { lat: 48.0678036, lng: 17.2374177 },
    { lat: 48.0679573, lng: 17.2373679 },
    { lat: 48.0682079, lng: 17.2369412 },
    { lat: 48.0683542, lng: 17.2363928 },
    { lat: 48.0683551, lng: 17.2360337 },
    { lat: 48.0683411, lng: 17.2358916 },
    { lat: 48.0683094, lng: 17.2354167 },
    { lat: 48.0682913, lng: 17.2350789 },
    { lat: 48.0682135, lng: 17.2343708 },
    { lat: 48.0682119, lng: 17.2339926 },
    { lat: 48.0682608, lng: 17.2336415 },
    { lat: 48.0684085, lng: 17.2331805 },
    { lat: 48.0685547, lng: 17.2329682 },
    { lat: 48.0686453, lng: 17.2328874 },
    { lat: 48.0687621, lng: 17.2328084 },
    { lat: 48.0688939, lng: 17.232804 },
    { lat: 48.0692115, lng: 17.2329413 },
    { lat: 48.069436, lng: 17.2331389 },
    { lat: 48.069673, lng: 17.2333885 },
    { lat: 48.0697881, lng: 17.2335568 },
    { lat: 48.0701674, lng: 17.2341425 },
    { lat: 48.0703695, lng: 17.2344125 },
    { lat: 48.0707598, lng: 17.2349445 },
    { lat: 48.0708048, lng: 17.2350102 },
    { lat: 48.0709561, lng: 17.2351942 },
    { lat: 48.0711615, lng: 17.2355454 },
    { lat: 48.0713019, lng: 17.2357715 },
    { lat: 48.0714334, lng: 17.2362517 },
    { lat: 48.0715111, lng: 17.2369583 },
    { lat: 48.0715996, lng: 17.2376058 },
    { lat: 48.0717355, lng: 17.2379321 },
    { lat: 48.0719429, lng: 17.2380107 },
    { lat: 48.0721519, lng: 17.2380614 },
    { lat: 48.0723347, lng: 17.2380756 },
    { lat: 48.0724668, lng: 17.2380567 },
    { lat: 48.0726284, lng: 17.2380226 },
    { lat: 48.0729385, lng: 17.2379646 },
    { lat: 48.0730773, lng: 17.2379281 },
    { lat: 48.073292, lng: 17.2378448 },
    { lat: 48.07344, lng: 17.237794 },
    { lat: 48.0736285, lng: 17.2377914 },
    { lat: 48.0737149, lng: 17.2378501 },
    { lat: 48.0739179, lng: 17.2380475 },
    { lat: 48.074109, lng: 17.2384734 },
    { lat: 48.0742546, lng: 17.2388428 },
    { lat: 48.0743601, lng: 17.2392561 },
    { lat: 48.0744561, lng: 17.2397122 },
    { lat: 48.0745026, lng: 17.2399177 },
    { lat: 48.0745801, lng: 17.2404025 },
    { lat: 48.0746083, lng: 17.2406394 },
    { lat: 48.0746435, lng: 17.2410649 },
    { lat: 48.0746738, lng: 17.2416201 },
    { lat: 48.0746857, lng: 17.2422547 },
    { lat: 48.0746912, lng: 17.242355 },
    { lat: 48.0746655, lng: 17.2433415 },
    { lat: 48.0745523, lng: 17.2438456 },
    { lat: 48.0744958, lng: 17.2442687 },
    { lat: 48.0744836, lng: 17.2443901 },
    { lat: 48.0744443, lng: 17.2446817 },
    { lat: 48.0743923, lng: 17.2448512 },
    { lat: 48.074043, lng: 17.2459483 },
    { lat: 48.0737438, lng: 17.2465784 },
    { lat: 48.0735799, lng: 17.2468809 },
    { lat: 48.0734026, lng: 17.2470346 },
    { lat: 48.0731362, lng: 17.2470379 },
    { lat: 48.0725951, lng: 17.2469371 },
    { lat: 48.0720319, lng: 17.2469111 },
    { lat: 48.0714858, lng: 17.2469374 },
    { lat: 48.0709566, lng: 17.2470143 },
    { lat: 48.070677, lng: 17.2471755 },
    { lat: 48.0703984, lng: 17.2472674 },
    { lat: 48.0700441, lng: 17.2472905 },
    { lat: 48.069601, lng: 17.2471516 },
    { lat: 48.069318, lng: 17.2471085 },
    { lat: 48.069024, lng: 17.2469187 },
    { lat: 48.0686693, lng: 17.2465458 },
    { lat: 48.0684348, lng: 17.2464003 },
    { lat: 48.0681852, lng: 17.2462824 },
    { lat: 48.0679869, lng: 17.2462906 },
    { lat: 48.0677507, lng: 17.2462727 },
    { lat: 48.0673726, lng: 17.2464108 },
    { lat: 48.0673856, lng: 17.2470483 },
    { lat: 48.0676333, lng: 17.2482553 },
    { lat: 48.0679432, lng: 17.2493109 },
    { lat: 48.0680444, lng: 17.249643 },
    { lat: 48.0682295, lng: 17.2502324 },
    { lat: 48.0684258, lng: 17.2509921 },
    { lat: 48.0685839, lng: 17.2516184 },
    { lat: 48.0686837, lng: 17.2519963 },
    { lat: 48.0688467, lng: 17.2526285 },
    { lat: 48.068936, lng: 17.2529846 },
    { lat: 48.0687917, lng: 17.2537012 },
    { lat: 48.0685565, lng: 17.2547498 },
    { lat: 48.0683902, lng: 17.2556113 },
    { lat: 48.0683056, lng: 17.2557992 },
    { lat: 48.0679662, lng: 17.2565719 },
    { lat: 48.0672894, lng: 17.2573834 },
    { lat: 48.0672181, lng: 17.2574656 },
    { lat: 48.066546, lng: 17.258435 },
    { lat: 48.0661444, lng: 17.2590195 },
    { lat: 48.0658579, lng: 17.2594168 },
    { lat: 48.0654331, lng: 17.2599929 },
    { lat: 48.064311, lng: 17.2616508 },
    { lat: 48.0637625, lng: 17.2625073 },
    { lat: 48.0628763, lng: 17.263873 },
    { lat: 48.0622979, lng: 17.2646715 },
    { lat: 48.0619152, lng: 17.2652525 },
    { lat: 48.0616265, lng: 17.2657856 },
    { lat: 48.0613412, lng: 17.2663199 },
    { lat: 48.0610444, lng: 17.2668508 },
    { lat: 48.0608967, lng: 17.2670927 },
    { lat: 48.0606166, lng: 17.2675689 },
    { lat: 48.0604304, lng: 17.267877 },
    { lat: 48.0600389, lng: 17.2685468 },
    { lat: 48.0597792, lng: 17.2689737 },
    { lat: 48.0594949, lng: 17.2694547 },
    { lat: 48.0590082, lng: 17.2702893 },
    { lat: 48.058836, lng: 17.270571 },
    { lat: 48.0585013, lng: 17.2711269 },
    { lat: 48.0584122, lng: 17.2712817 },
    { lat: 48.0581323, lng: 17.2717274 },
    { lat: 48.0580643, lng: 17.2717931 },
    { lat: 48.058074, lng: 17.2718187 },
    { lat: 48.0580068, lng: 17.2718791 },
    { lat: 48.0576735, lng: 17.2722023 },
    { lat: 48.057122, lng: 17.2727245 },
    { lat: 48.0568093, lng: 17.2730346 },
    { lat: 48.0558266, lng: 17.2740039 },
    { lat: 48.0554769, lng: 17.2743851 },
    { lat: 48.0553985, lng: 17.2744632 },
    { lat: 48.0549889, lng: 17.2748439 },
    { lat: 48.054483, lng: 17.2753391 },
    { lat: 48.0542589, lng: 17.2755712 },
    { lat: 48.0540363, lng: 17.275808 },
    { lat: 48.0535548, lng: 17.2763484 },
    { lat: 48.0530362, lng: 17.2767713 },
    { lat: 48.0527844, lng: 17.2769441 },
    { lat: 48.0524331, lng: 17.2771991 },
    { lat: 48.0520916, lng: 17.2774316 },
    { lat: 48.0518112, lng: 17.2776332 },
    { lat: 48.05121, lng: 17.2780034 },
    { lat: 48.0508394, lng: 17.2782401 },
    { lat: 48.0498496, lng: 17.2789677 },
    { lat: 48.0492503, lng: 17.2794178 },
    { lat: 48.0481451, lng: 17.2802448 },
    { lat: 48.047868, lng: 17.2804602 },
    { lat: 48.0475493, lng: 17.2806808 },
    { lat: 48.0475843, lng: 17.2807994 },
    { lat: 48.0479946, lng: 17.2819248 },
    { lat: 48.0484667, lng: 17.2828416 },
    { lat: 48.0487205, lng: 17.2832277 },
    { lat: 48.0491166, lng: 17.2844294 },
    { lat: 48.0492156, lng: 17.2847122 },
    { lat: 48.0493843, lng: 17.28533 },
    { lat: 48.0496267, lng: 17.2862083 },
    { lat: 48.049643, lng: 17.2862505 },
    { lat: 48.04963, lng: 17.286463 },
    { lat: 48.0495416, lng: 17.2870876 },
    { lat: 48.0494841, lng: 17.2873783 },
    { lat: 48.0493634, lng: 17.2877903 },
    { lat: 48.049293, lng: 17.2882616 },
    { lat: 48.0491993, lng: 17.2886536 },
    { lat: 48.0491695, lng: 17.2892258 },
    { lat: 48.049116, lng: 17.2895428 },
    { lat: 48.049084, lng: 17.2896056 },
    { lat: 48.0490071, lng: 17.2900298 },
    { lat: 48.0489027, lng: 17.2905506 },
    { lat: 48.0487281, lng: 17.2914096 },
    { lat: 48.048719, lng: 17.2921925 },
    { lat: 48.0487187, lng: 17.2926137 },
    { lat: 48.0487149, lng: 17.2937145 },
    { lat: 48.0487089, lng: 17.294305 },
    { lat: 48.0488771, lng: 17.2954798 },
    { lat: 48.0488819, lng: 17.2955852 },
    { lat: 48.0491192, lng: 17.2963025 },
    { lat: 48.0492506, lng: 17.2968533 },
    { lat: 48.0493401, lng: 17.2974366 },
    { lat: 48.0489434, lng: 17.297774 },
    { lat: 48.0487462, lng: 17.297952 },
    { lat: 48.0484705, lng: 17.2982075 },
    { lat: 48.0483623, lng: 17.298296 },
    { lat: 48.048063, lng: 17.2985177 },
    { lat: 48.0478306, lng: 17.2986245 },
    { lat: 48.0476428, lng: 17.2986379 },
    { lat: 48.0473808, lng: 17.2986218 },
    { lat: 48.04721, lng: 17.2986023 },
    { lat: 48.0471163, lng: 17.2986016 },
    { lat: 48.0469539, lng: 17.2986231 },
    { lat: 48.0467287, lng: 17.2986858 },
    { lat: 48.0465039, lng: 17.2988723 },
    { lat: 48.0464017, lng: 17.2989673 },
    { lat: 48.046252, lng: 17.2991276 },
    { lat: 48.0461118, lng: 17.2992972 },
    { lat: 48.045909, lng: 17.299587 },
    { lat: 48.045422, lng: 17.300345 },
    { lat: 48.0449, lng: 17.301448 },
    { lat: 48.046273, lng: 17.303009 },
    { lat: 48.047373, lng: 17.304102 },
    { lat: 48.047994, lng: 17.304398 },
    { lat: 48.049786, lng: 17.306407 },
    { lat: 48.050155, lng: 17.305405 },
    { lat: 48.050528, lng: 17.304681 },
    { lat: 48.051881, lng: 17.302833 },
    { lat: 48.053121, lng: 17.3017 },
    { lat: 48.054141, lng: 17.301185 },
    { lat: 48.054708, lng: 17.300646 },
    { lat: 48.05613, lng: 17.299615 },
    { lat: 48.057364, lng: 17.298831 },
    { lat: 48.058566, lng: 17.298466 },
    { lat: 48.059099, lng: 17.298631 },
    { lat: 48.060542, lng: 17.298572 },
    { lat: 48.06215, lng: 17.298743 },
    { lat: 48.063623, lng: 17.298067 },
    { lat: 48.064965, lng: 17.2973 },
    { lat: 48.0661604, lng: 17.2965011 },
    { lat: 48.066763, lng: 17.298491 },
    { lat: 48.067631, lng: 17.300467 },
    { lat: 48.06845, lng: 17.30253 },
    { lat: 48.067036, lng: 17.304157 },
    { lat: 48.066201, lng: 17.305391 },
    { lat: 48.065529, lng: 17.306532 },
    { lat: 48.064686, lng: 17.309112 },
    { lat: 48.063377, lng: 17.310396 },
    { lat: 48.061911, lng: 17.311217 },
    { lat: 48.06053, lng: 17.312783 },
    { lat: 48.060021, lng: 17.313816 },
    { lat: 48.059949, lng: 17.314056 },
    { lat: 48.060865, lng: 17.315194 },
    { lat: 48.060693, lng: 17.315451 },
    { lat: 48.063211, lng: 17.317624 },
    { lat: 48.06272, lng: 17.318914 },
    { lat: 48.070828, lng: 17.324001 },
    { lat: 48.070831, lng: 17.3239927 },
    { lat: 48.0708342, lng: 17.3239896 },
    { lat: 48.070857, lng: 17.3239762 },
    { lat: 48.0736829, lng: 17.3244491 },
    { lat: 48.0737122, lng: 17.3243621 },
    { lat: 48.0749849, lng: 17.3204745 },
    { lat: 48.0756251, lng: 17.318452 },
    { lat: 48.0759947, lng: 17.3172573 },
    { lat: 48.076154, lng: 17.3167282 },
    { lat: 48.0762304, lng: 17.3164667 },
    { lat: 48.0765605, lng: 17.315427 },
    { lat: 48.0774472, lng: 17.3126061 },
    { lat: 48.077293, lng: 17.3120861 },
    { lat: 48.0775336, lng: 17.3117768 },
    { lat: 48.0780932, lng: 17.3110665 },
    { lat: 48.0786024, lng: 17.310602 },
    { lat: 48.0790576, lng: 17.3101902 },
    { lat: 48.0799822, lng: 17.309346 },
    { lat: 48.0801542, lng: 17.3091799 },
    { lat: 48.0804728, lng: 17.3088565 },
    { lat: 48.0811348, lng: 17.3081733 },
    { lat: 48.0815502, lng: 17.3077412 },
    { lat: 48.0817989, lng: 17.3074196 },
    { lat: 48.0820763, lng: 17.3070543 },
    { lat: 48.0827302, lng: 17.3058731 },
    { lat: 48.0833391, lng: 17.304765 },
    { lat: 48.083839, lng: 17.3038864 },
    { lat: 48.0838568, lng: 17.3038652 },
    { lat: 48.0843913, lng: 17.3030986 },
    { lat: 48.084948, lng: 17.3022949 },
    { lat: 48.0851384, lng: 17.30203 },
    { lat: 48.0854044, lng: 17.3016459 },
    { lat: 48.0854491, lng: 17.3015888 },
    { lat: 48.0859299, lng: 17.3008982 },
    { lat: 48.0866134, lng: 17.2999283 },
    { lat: 48.086924, lng: 17.2994829 },
    { lat: 48.0875744, lng: 17.2985574 },
    { lat: 48.0876811, lng: 17.2984118 },
    { lat: 48.0878489, lng: 17.2981662 },
    { lat: 48.0894855, lng: 17.2958255 },
    { lat: 48.0907792, lng: 17.2939713 },
    { lat: 48.0927881, lng: 17.2910965 },
    { lat: 48.0932615, lng: 17.2904161 },
    { lat: 48.0936376, lng: 17.2898859 },
    { lat: 48.0939226, lng: 17.2894651 },
    { lat: 48.0941451, lng: 17.2891582 },
    { lat: 48.0948202, lng: 17.2881801 },
    { lat: 48.0951788, lng: 17.287676 },
    { lat: 48.0952834, lng: 17.2875332 },
    { lat: 48.0958397, lng: 17.286741 },
    { lat: 48.0960805, lng: 17.286998 },
    { lat: 48.0963329, lng: 17.2872795 },
    { lat: 48.0966325, lng: 17.2876054 },
    { lat: 48.0969555, lng: 17.2879631 },
    { lat: 48.0974025, lng: 17.2884518 },
    { lat: 48.0988197, lng: 17.2900115 },
    { lat: 48.0991724, lng: 17.2904071 },
    { lat: 48.0992518, lng: 17.2904814 },
    { lat: 48.0998373, lng: 17.2911159 },
    { lat: 48.1002235, lng: 17.2915412 },
    { lat: 48.1007907, lng: 17.2921556 },
    { lat: 48.1011296, lng: 17.2925137 },
    { lat: 48.1017705, lng: 17.2932014 },
    { lat: 48.1031461, lng: 17.2930447 },
    { lat: 48.1038401, lng: 17.2929818 },
    { lat: 48.10515, lng: 17.2928896 },
    { lat: 48.1054264, lng: 17.2928673 },
    { lat: 48.1057058, lng: 17.2928386 },
    { lat: 48.1064473, lng: 17.2927765 },
    { lat: 48.1072653, lng: 17.2926943 },
    { lat: 48.1076966, lng: 17.2926568 },
    { lat: 48.1080184, lng: 17.2926202 },
    { lat: 48.1099228, lng: 17.2925497 },
    { lat: 48.1120057, lng: 17.2924781 },
    { lat: 48.1120058, lng: 17.2924293 },
    { lat: 48.1121593, lng: 17.2906016 },
    { lat: 48.1123367, lng: 17.2885065 },
    { lat: 48.1124106, lng: 17.2874565 },
    { lat: 48.1125136, lng: 17.2860454 },
    { lat: 48.112764, lng: 17.2833 },
  ],
  Hamuliakovo: [
    { lat: 48.0508394, lng: 17.2782401 },
    { lat: 48.0505533, lng: 17.277879 },
    { lat: 48.0501226, lng: 17.2773638 },
    { lat: 48.0499021, lng: 17.2771403 },
    { lat: 48.0492033, lng: 17.276382 },
    { lat: 48.0487678, lng: 17.2758794 },
    { lat: 48.0487085, lng: 17.2758047 },
    { lat: 48.0479906, lng: 17.2750642 },
    { lat: 48.0479333, lng: 17.2749505 },
    { lat: 48.0478987, lng: 17.2748547 },
    { lat: 48.0478961, lng: 17.2746117 },
    { lat: 48.0474951, lng: 17.2742556 },
    { lat: 48.0468807, lng: 17.2737467 },
    { lat: 48.0462515, lng: 17.2732721 },
    { lat: 48.0456294, lng: 17.2729041 },
    { lat: 48.045183, lng: 17.2726972 },
    { lat: 48.0452491, lng: 17.2723826 },
    { lat: 48.045361, lng: 17.2719423 },
    { lat: 48.0455247, lng: 17.2713629 },
    { lat: 48.0456658, lng: 17.27092 },
    { lat: 48.0459701, lng: 17.2700099 },
    { lat: 48.046009, lng: 17.2697962 },
    { lat: 48.0460088, lng: 17.2692335 },
    { lat: 48.0460931, lng: 17.2689716 },
    { lat: 48.0461244, lng: 17.2688482 },
    { lat: 48.0462456, lng: 17.2685649 },
    { lat: 48.0463326, lng: 17.2682421 },
    { lat: 48.0463866, lng: 17.2679663 },
    { lat: 48.0464222, lng: 17.2675508 },
    { lat: 48.0465049, lng: 17.2668587 },
    { lat: 48.0466068, lng: 17.2658268 },
    { lat: 48.0466159, lng: 17.2656588 },
    { lat: 48.0466303, lng: 17.2652465 },
    { lat: 48.0466843, lng: 17.2649216 },
    { lat: 48.0467231, lng: 17.2644172 },
    { lat: 48.0467256, lng: 17.2642172 },
    { lat: 48.0466617, lng: 17.2633935 },
    { lat: 48.0467135, lng: 17.2631366 },
    { lat: 48.0468677, lng: 17.2627035 },
    { lat: 48.0469106, lng: 17.2624656 },
    { lat: 48.0469286, lng: 17.2622606 },
    { lat: 48.0469387, lng: 17.2620056 },
    { lat: 48.0469395, lng: 17.2615935 },
    { lat: 48.0468984, lng: 17.2612997 },
    { lat: 48.0467652, lng: 17.2608596 },
    { lat: 48.0467379, lng: 17.260739 },
    { lat: 48.0467316, lng: 17.2605756 },
    { lat: 48.046809, lng: 17.2604311 },
    { lat: 48.0468602, lng: 17.2602508 },
    { lat: 48.0467903, lng: 17.2599309 },
    { lat: 48.0467313, lng: 17.2594864 },
    { lat: 48.0465885, lng: 17.2586138 },
    { lat: 48.0465315, lng: 17.2584025 },
    { lat: 48.0464735, lng: 17.2582948 },
    { lat: 48.0462149, lng: 17.2579575 },
    { lat: 48.0460937, lng: 17.2577497 },
    { lat: 48.0460598, lng: 17.2576654 },
    { lat: 48.0460381, lng: 17.2573897 },
    { lat: 48.0460644, lng: 17.2572924 },
    { lat: 48.0462478, lng: 17.2568751 },
    { lat: 48.0463246, lng: 17.256654 },
    { lat: 48.0465166, lng: 17.2561792 },
    { lat: 48.0465388, lng: 17.2561051 },
    { lat: 48.0466111, lng: 17.2558003 },
    { lat: 48.0466159, lng: 17.2556169 },
    { lat: 48.0464892, lng: 17.2554807 },
    { lat: 48.0463808, lng: 17.2554798 },
    { lat: 48.0463157, lng: 17.2554533 },
    { lat: 48.0462798, lng: 17.2554224 },
    { lat: 48.046251, lng: 17.2553803 },
    { lat: 48.0462032, lng: 17.2552399 },
    { lat: 48.0461933, lng: 17.2551244 },
    { lat: 48.0462042, lng: 17.254983 },
    { lat: 48.0462328, lng: 17.2549037 },
    { lat: 48.0462679, lng: 17.2548715 },
    { lat: 48.0463043, lng: 17.2548746 },
    { lat: 48.0464112, lng: 17.2549194 },
    { lat: 48.0465758, lng: 17.2550324 },
    { lat: 48.0467451, lng: 17.255014 },
    { lat: 48.0468969, lng: 17.2549696 },
    { lat: 48.0469772, lng: 17.2548346 },
    { lat: 48.0469788, lng: 17.2547241 },
    { lat: 48.0469471, lng: 17.2546209 },
    { lat: 48.04678, lng: 17.2543172 },
    { lat: 48.0467452, lng: 17.2542027 },
    { lat: 48.0467588, lng: 17.254033 },
    { lat: 48.0468115, lng: 17.2540278 },
    { lat: 48.0468779, lng: 17.2540414 },
    { lat: 48.0470464, lng: 17.2541959 },
    { lat: 48.0471194, lng: 17.2542407 },
    { lat: 48.0472092, lng: 17.2542493 },
    { lat: 48.0472868, lng: 17.254226 },
    { lat: 48.0474896, lng: 17.2538969 },
    { lat: 48.0475264, lng: 17.253872 },
    { lat: 48.0475607, lng: 17.2538611 },
    { lat: 48.047606, lng: 17.2538805 },
    { lat: 48.0477595, lng: 17.2540808 },
    { lat: 48.0478473, lng: 17.2541437 },
    { lat: 48.0480482, lng: 17.2541409 },
    { lat: 48.0481014, lng: 17.2541599 },
    { lat: 48.0481491, lng: 17.2541983 },
    { lat: 48.0482488, lng: 17.2543377 },
    { lat: 48.0483217, lng: 17.2544827 },
    { lat: 48.0484269, lng: 17.2545851 },
    { lat: 48.0485127, lng: 17.2546355 },
    { lat: 48.0487792, lng: 17.2547014 },
    { lat: 48.0488635, lng: 17.2547461 },
    { lat: 48.0489967, lng: 17.2548804 },
    { lat: 48.0491012, lng: 17.254956 },
    { lat: 48.0492061, lng: 17.2549539 },
    { lat: 48.049621, lng: 17.2548571 },
    { lat: 48.0497624, lng: 17.2548596 },
    { lat: 48.0498579, lng: 17.2548851 },
    { lat: 48.0500462, lng: 17.2548976 },
    { lat: 48.0503483, lng: 17.2549723 },
    { lat: 48.0505612, lng: 17.2549122 },
    { lat: 48.050686, lng: 17.254903 },
    { lat: 48.0507793, lng: 17.2530829 },
    { lat: 48.0509827, lng: 17.2531858 },
    { lat: 48.0510932, lng: 17.2522404 },
    { lat: 48.0511563, lng: 17.251458 },
    { lat: 48.0511842, lng: 17.2510916 },
    { lat: 48.0512456, lng: 17.2506385 },
    { lat: 48.0512447, lng: 17.2501714 },
    { lat: 48.0513584, lng: 17.2496061 },
    { lat: 48.0514138, lng: 17.2493368 },
    { lat: 48.05146, lng: 17.2491011 },
    { lat: 48.0516009, lng: 17.2476555 },
    { lat: 48.0516228, lng: 17.247448 },
    { lat: 48.0516627, lng: 17.2470963 },
    { lat: 48.0517088, lng: 17.2466568 },
    { lat: 48.0517668, lng: 17.246108 },
    { lat: 48.0517741, lng: 17.2460392 },
    { lat: 48.0519202, lng: 17.2451224 },
    { lat: 48.0514239, lng: 17.2447783 },
    { lat: 48.0510398, lng: 17.24449 },
    { lat: 48.0509263, lng: 17.244415 },
    { lat: 48.0506323, lng: 17.2443432 },
    { lat: 48.0500939, lng: 17.2439953 },
    { lat: 48.0498768, lng: 17.243789 },
    { lat: 48.0496349, lng: 17.2436001 },
    { lat: 48.0493294, lng: 17.2433564 },
    { lat: 48.0494124, lng: 17.2429575 },
    { lat: 48.049423, lng: 17.2429651 },
    { lat: 48.0495344, lng: 17.2424331 },
    { lat: 48.0496111, lng: 17.2418563 },
    { lat: 48.0496832, lng: 17.2413966 },
    { lat: 48.0497372, lng: 17.2408182 },
    { lat: 48.049751, lng: 17.2405517 },
    { lat: 48.0497861, lng: 17.2400949 },
    { lat: 48.0497975, lng: 17.2399112 },
    { lat: 48.0498052, lng: 17.2396372 },
    { lat: 48.0498188, lng: 17.2393336 },
    { lat: 48.0498374, lng: 17.2385642 },
    { lat: 48.0498454, lng: 17.2385478 },
    { lat: 48.0498448, lng: 17.2384889 },
    { lat: 48.0498625, lng: 17.2381602 },
    { lat: 48.0498765, lng: 17.2380159 },
    { lat: 48.0499096, lng: 17.2377834 },
    { lat: 48.04992, lng: 17.2376345 },
    { lat: 48.0500094, lng: 17.2361831 },
    { lat: 48.0500247, lng: 17.2358548 },
    { lat: 48.0500333, lng: 17.2355258 },
    { lat: 48.0500321, lng: 17.2348839 },
    { lat: 48.0500348, lng: 17.2346705 },
    { lat: 48.0500523, lng: 17.2342223 },
    { lat: 48.0500833, lng: 17.2339084 },
    { lat: 48.0501321, lng: 17.2335237 },
    { lat: 48.0502143, lng: 17.2329285 },
    { lat: 48.0502485, lng: 17.2325275 },
    { lat: 48.0502598, lng: 17.2318502 },
    { lat: 48.0502868, lng: 17.2314442 },
    { lat: 48.0503768, lng: 17.230778 },
    { lat: 48.0500551, lng: 17.2307146 },
    { lat: 48.0497007, lng: 17.230534 },
    { lat: 48.0494439, lng: 17.2303271 },
    { lat: 48.0493329, lng: 17.2301371 },
    { lat: 48.049178, lng: 17.229814 },
    { lat: 48.0490449, lng: 17.2292761 },
    { lat: 48.048977, lng: 17.2289365 },
    { lat: 48.0489206, lng: 17.2285322 },
    { lat: 48.048848, lng: 17.227548 },
    { lat: 48.0488412, lng: 17.2270579 },
    { lat: 48.0488309, lng: 17.2260098 },
    { lat: 48.0488205, lng: 17.2253483 },
    { lat: 48.0488151, lng: 17.2248452 },
    { lat: 48.0488113, lng: 17.2242654 },
    { lat: 48.0487481, lng: 17.2240802 },
    { lat: 48.0485882, lng: 17.2235649 },
    { lat: 48.0483887, lng: 17.2229637 },
    { lat: 48.0483796, lng: 17.2229137 },
    { lat: 48.048106, lng: 17.2228095 },
    { lat: 48.0478873, lng: 17.2227139 },
    { lat: 48.0475921, lng: 17.2225919 },
    { lat: 48.0474636, lng: 17.2226287 },
    { lat: 48.0471505, lng: 17.2227789 },
    { lat: 48.0468336, lng: 17.222972 },
    { lat: 48.0466508, lng: 17.2230943 },
    { lat: 48.0455956, lng: 17.2237014 },
    { lat: 48.0453668, lng: 17.2237944 },
    { lat: 48.0451377, lng: 17.2239489 },
    { lat: 48.0447444, lng: 17.2241321 },
    { lat: 48.0444594, lng: 17.2241957 },
    { lat: 48.043776, lng: 17.2242975 },
    { lat: 48.042911, lng: 17.2244379 },
    { lat: 48.0420592, lng: 17.2245741 },
    { lat: 48.0413727, lng: 17.2246805 },
    { lat: 48.0410721, lng: 17.2247295 },
    { lat: 48.0402759, lng: 17.2248521 },
    { lat: 48.0400768, lng: 17.2234305 },
    { lat: 48.0399356, lng: 17.2224871 },
    { lat: 48.039851, lng: 17.222637 },
    { lat: 48.039752, lng: 17.222812 },
    { lat: 48.039564, lng: 17.223117 },
    { lat: 48.039402, lng: 17.223361 },
    { lat: 48.039177, lng: 17.223684 },
    { lat: 48.038914, lng: 17.224021 },
    { lat: 48.038623, lng: 17.22436 },
    { lat: 48.038338, lng: 17.224663 },
    { lat: 48.038074, lng: 17.224921 },
    { lat: 48.037809, lng: 17.225153 },
    { lat: 48.037463, lng: 17.225428 },
    { lat: 48.037017, lng: 17.225775 },
    { lat: 48.0367, lng: 17.226023 },
    { lat: 48.036151, lng: 17.226479 },
    { lat: 48.035718, lng: 17.22685 },
    { lat: 48.035418, lng: 17.227116 },
    { lat: 48.035114, lng: 17.227395 },
    { lat: 48.034829, lng: 17.227667 },
    { lat: 48.034413, lng: 17.228069 },
    { lat: 48.034112, lng: 17.228373 },
    { lat: 48.033688, lng: 17.228768 },
    { lat: 48.033191, lng: 17.229205 },
    { lat: 48.032879, lng: 17.229462 },
    { lat: 48.032381, lng: 17.229846 },
    { lat: 48.032214, lng: 17.229964 },
    { lat: 48.032058, lng: 17.230074 },
    { lat: 48.031829, lng: 17.230236 },
    { lat: 48.031682, lng: 17.23034 },
    { lat: 48.031674, lng: 17.230346 },
    { lat: 48.031605, lng: 17.230393 },
    { lat: 48.031575, lng: 17.230414 },
    { lat: 48.030905, lng: 17.230826 },
    { lat: 48.03068, lng: 17.230981 },
    { lat: 48.030543, lng: 17.231083 },
    { lat: 48.03049, lng: 17.231123 },
    { lat: 48.030298, lng: 17.231275 },
    { lat: 48.030114, lng: 17.231433 },
    { lat: 48.030086, lng: 17.231458 },
    { lat: 48.029933, lng: 17.231602 },
    { lat: 48.029728, lng: 17.231809 },
    { lat: 48.029655, lng: 17.231886 },
    { lat: 48.029581, lng: 17.231963 },
    { lat: 48.029394, lng: 17.232173 },
    { lat: 48.029205, lng: 17.232401 },
    { lat: 48.029013, lng: 17.232654 },
    { lat: 48.028792, lng: 17.232965 },
    { lat: 48.028607, lng: 17.233241 },
    { lat: 48.028435, lng: 17.233521 },
    { lat: 48.028275, lng: 17.233801 },
    { lat: 48.028072, lng: 17.234175 },
    { lat: 48.027794, lng: 17.234661 },
    { lat: 48.027588, lng: 17.235013 },
    { lat: 48.027435, lng: 17.235266 },
    { lat: 48.026996, lng: 17.235964 },
    { lat: 48.026868, lng: 17.236164 },
    { lat: 48.026602, lng: 17.236563 },
    { lat: 48.026313, lng: 17.236984 },
    { lat: 48.026088, lng: 17.237303 },
    { lat: 48.025887, lng: 17.237575 },
    { lat: 48.025648, lng: 17.237889 },
    { lat: 48.02526, lng: 17.238385 },
    { lat: 48.025013, lng: 17.238691 },
    { lat: 48.024795, lng: 17.238953 },
    { lat: 48.024422, lng: 17.23939 },
    { lat: 48.024149, lng: 17.239696 },
    { lat: 48.024019, lng: 17.239834 },
    { lat: 48.023803, lng: 17.24006 },
    { lat: 48.023528, lng: 17.240369 },
    { lat: 48.023286, lng: 17.240657 },
    { lat: 48.023052, lng: 17.240951 },
    { lat: 48.02287, lng: 17.241193 },
    { lat: 48.0227, lng: 17.241429 },
    { lat: 48.0224651, lng: 17.241769 },
    { lat: 48.02134, lng: 17.243597 },
    { lat: 48.020594, lng: 17.244516 },
    { lat: 48.01977, lng: 17.245278 },
    { lat: 48.018885, lng: 17.245868 },
    { lat: 48.017957, lng: 17.246275 },
    { lat: 48.016373, lng: 17.246734 },
    { lat: 48.012855, lng: 17.247334 },
    { lat: 48.012014, lng: 17.24743 },
    { lat: 48.012391, lng: 17.248785 },
    { lat: 48.022608, lng: 17.258852 },
    { lat: 48.028109, lng: 17.253473 },
    { lat: 48.028918, lng: 17.253501 },
    { lat: 48.029118, lng: 17.254935 },
    { lat: 48.029042, lng: 17.258899 },
    { lat: 48.026083, lng: 17.261522 },
    { lat: 48.0284522, lng: 17.2686814 },
    { lat: 48.028641, lng: 17.269252 },
    { lat: 48.0292356, lng: 17.2698769 },
    { lat: 48.0293638, lng: 17.2700117 },
    { lat: 48.0294952, lng: 17.2701498 },
    { lat: 48.0296374, lng: 17.2702992 },
    { lat: 48.0298237, lng: 17.270495 },
    { lat: 48.035529, lng: 17.276492 },
    { lat: 48.042792, lng: 17.281716 },
    { lat: 48.04486, lng: 17.295538 },
    { lat: 48.045909, lng: 17.299587 },
    { lat: 48.0461118, lng: 17.2992972 },
    { lat: 48.046252, lng: 17.2991276 },
    { lat: 48.0464017, lng: 17.2989673 },
    { lat: 48.0465039, lng: 17.2988723 },
    { lat: 48.0467287, lng: 17.2986858 },
    { lat: 48.0469539, lng: 17.2986231 },
    { lat: 48.0471163, lng: 17.2986016 },
    { lat: 48.04721, lng: 17.2986023 },
    { lat: 48.0473808, lng: 17.2986218 },
    { lat: 48.0476428, lng: 17.2986379 },
    { lat: 48.0478306, lng: 17.2986245 },
    { lat: 48.048063, lng: 17.2985177 },
    { lat: 48.0483623, lng: 17.298296 },
    { lat: 48.0484705, lng: 17.2982075 },
    { lat: 48.0487462, lng: 17.297952 },
    { lat: 48.0489434, lng: 17.297774 },
    { lat: 48.0493401, lng: 17.2974366 },
    { lat: 48.0492506, lng: 17.2968533 },
    { lat: 48.0491192, lng: 17.2963025 },
    { lat: 48.0488819, lng: 17.2955852 },
    { lat: 48.0488771, lng: 17.2954798 },
    { lat: 48.0487089, lng: 17.294305 },
    { lat: 48.0487149, lng: 17.2937145 },
    { lat: 48.0487187, lng: 17.2926137 },
    { lat: 48.048719, lng: 17.2921925 },
    { lat: 48.0487281, lng: 17.2914096 },
    { lat: 48.0489027, lng: 17.2905506 },
    { lat: 48.0490071, lng: 17.2900298 },
    { lat: 48.049084, lng: 17.2896056 },
    { lat: 48.049116, lng: 17.2895428 },
    { lat: 48.0491695, lng: 17.2892258 },
    { lat: 48.0491993, lng: 17.2886536 },
    { lat: 48.049293, lng: 17.2882616 },
    { lat: 48.0493634, lng: 17.2877903 },
    { lat: 48.0494841, lng: 17.2873783 },
    { lat: 48.0495416, lng: 17.2870876 },
    { lat: 48.04963, lng: 17.286463 },
    { lat: 48.049643, lng: 17.2862505 },
    { lat: 48.0496267, lng: 17.2862083 },
    { lat: 48.0493843, lng: 17.28533 },
    { lat: 48.0492156, lng: 17.2847122 },
    { lat: 48.0491166, lng: 17.2844294 },
    { lat: 48.0487205, lng: 17.2832277 },
    { lat: 48.0484667, lng: 17.2828416 },
    { lat: 48.0479946, lng: 17.2819248 },
    { lat: 48.0475843, lng: 17.2807994 },
    { lat: 48.0475493, lng: 17.2806808 },
    { lat: 48.047868, lng: 17.2804602 },
    { lat: 48.0481451, lng: 17.2802448 },
    { lat: 48.0492503, lng: 17.2794178 },
    { lat: 48.0498496, lng: 17.2789677 },
    { lat: 48.0508394, lng: 17.2782401 },
  ],
  HrubáBorša: [
    { lat: 48.176515, lng: 17.5071079 },
    { lat: 48.1766747, lng: 17.5068382 },
    { lat: 48.1767725, lng: 17.506706 },
    { lat: 48.1768425, lng: 17.5066351 },
    { lat: 48.1770855, lng: 17.5064288 },
    { lat: 48.1772075, lng: 17.5063412 },
    { lat: 48.1774543, lng: 17.5062086 },
    { lat: 48.1777628, lng: 17.5061237 },
    { lat: 48.1778508, lng: 17.506052 },
    { lat: 48.178034, lng: 17.5058274 },
    { lat: 48.1785088, lng: 17.5053428 },
    { lat: 48.1786151, lng: 17.5053284 },
    { lat: 48.1789787, lng: 17.5049772 },
    { lat: 48.1797724, lng: 17.5040416 },
    { lat: 48.1806348, lng: 17.5028395 },
    { lat: 48.1809957, lng: 17.502341 },
    { lat: 48.1811031, lng: 17.5022014 },
    { lat: 48.1813633, lng: 17.5018405 },
    { lat: 48.1820374, lng: 17.5002922 },
    { lat: 48.1825411, lng: 17.499133 },
    { lat: 48.1827358, lng: 17.4986128 },
    { lat: 48.1829811, lng: 17.497951 },
    { lat: 48.1832481, lng: 17.496766 },
    { lat: 48.1835361, lng: 17.4954833 },
    { lat: 48.1837541, lng: 17.4943432 },
    { lat: 48.1840239, lng: 17.4929575 },
    { lat: 48.1846736, lng: 17.4900664 },
    { lat: 48.1847679, lng: 17.4896373 },
    { lat: 48.1849408, lng: 17.4889892 },
    { lat: 48.1850929, lng: 17.4883889 },
    { lat: 48.1851571, lng: 17.4880703 },
    { lat: 48.1852324, lng: 17.4876967 },
    { lat: 48.1854026, lng: 17.4868142 },
    { lat: 48.1854236, lng: 17.4866676 },
    { lat: 48.1855916, lng: 17.4865396 },
    { lat: 48.1857914, lng: 17.4862567 },
    { lat: 48.1861067, lng: 17.4858235 },
    { lat: 48.1864293, lng: 17.4853447 },
    { lat: 48.1867649, lng: 17.4847855 },
    { lat: 48.1870233, lng: 17.4842036 },
    { lat: 48.1876226, lng: 17.4828673 },
    { lat: 48.1881314, lng: 17.4817359 },
    { lat: 48.1884502, lng: 17.4807965 },
    { lat: 48.1889965, lng: 17.4792144 },
    { lat: 48.1888525, lng: 17.4783806 },
    { lat: 48.1886195, lng: 17.476976 },
    { lat: 48.1883769, lng: 17.4756682 },
    { lat: 48.1881968, lng: 17.4747846 },
    { lat: 48.1878734, lng: 17.4731146 },
    { lat: 48.1872613, lng: 17.4724159 },
    { lat: 48.1866415, lng: 17.4715815 },
    { lat: 48.1857308, lng: 17.4701398 },
    { lat: 48.1851316, lng: 17.4689757 },
    { lat: 48.1849651, lng: 17.4686543 },
    { lat: 48.1839379, lng: 17.4667568 },
    { lat: 48.1833724, lng: 17.4654164 },
    { lat: 48.1826841, lng: 17.4634167 },
    { lat: 48.1824445, lng: 17.4629607 },
    { lat: 48.1823389, lng: 17.4626534 },
    { lat: 48.181556, lng: 17.4629143 },
    { lat: 48.1811432, lng: 17.4630449 },
    { lat: 48.1810873, lng: 17.4624231 },
    { lat: 48.1812384, lng: 17.4615992 },
    { lat: 48.1813418, lng: 17.4610134 },
    { lat: 48.1815146, lng: 17.4602639 },
    { lat: 48.1817029, lng: 17.4594386 },
    { lat: 48.1818419, lng: 17.4588461 },
    { lat: 48.1819968, lng: 17.4581694 },
    { lat: 48.1821571, lng: 17.4574858 },
    { lat: 48.1807691, lng: 17.4571216 },
    { lat: 48.1792356, lng: 17.4568275 },
    { lat: 48.1782019, lng: 17.456631 },
    { lat: 48.1766575, lng: 17.4563427 },
    { lat: 48.1737644, lng: 17.4557245 },
    { lat: 48.1719097, lng: 17.4612356 },
    { lat: 48.171613, lng: 17.4621504 },
    { lat: 48.1701822, lng: 17.4665555 },
    { lat: 48.1693891, lng: 17.4689984 },
    { lat: 48.1690853, lng: 17.469975 },
    { lat: 48.1689674, lng: 17.4703541 },
    { lat: 48.1681858, lng: 17.4728192 },
    { lat: 48.1679164, lng: 17.473614 },
    { lat: 48.1678987, lng: 17.4736707 },
    { lat: 48.1663632, lng: 17.4784209 },
    { lat: 48.1662413, lng: 17.4785795 },
    { lat: 48.165552, lng: 17.4796819 },
    { lat: 48.165955, lng: 17.480508 },
    { lat: 48.166434, lng: 17.481456 },
    { lat: 48.166928, lng: 17.481942 },
    { lat: 48.167109, lng: 17.482202 },
    { lat: 48.167261, lng: 17.482731 },
    { lat: 48.167568, lng: 17.482488 },
    { lat: 48.167957, lng: 17.482245 },
    { lat: 48.168481, lng: 17.481921 },
    { lat: 48.16914, lng: 17.481562 },
    { lat: 48.169505, lng: 17.481308 },
    { lat: 48.170218, lng: 17.480827 },
    { lat: 48.170649, lng: 17.481543 },
    { lat: 48.171067, lng: 17.48224 },
    { lat: 48.17123, lng: 17.482925 },
    { lat: 48.171397, lng: 17.483335 },
    { lat: 48.171706, lng: 17.484105 },
    { lat: 48.171908, lng: 17.484677 },
    { lat: 48.171986, lng: 17.484889 },
    { lat: 48.172138, lng: 17.485315 },
    { lat: 48.172219, lng: 17.485553 },
    { lat: 48.172278, lng: 17.486236 },
    { lat: 48.172157, lng: 17.486817 },
    { lat: 48.172105, lng: 17.487091 },
    { lat: 48.172015, lng: 17.487382 },
    { lat: 48.171849, lng: 17.487911 },
    { lat: 48.17164, lng: 17.488565 },
    { lat: 48.171339, lng: 17.489504 },
    { lat: 48.171063, lng: 17.490372 },
    { lat: 48.171399, lng: 17.490189 },
    { lat: 48.171334, lng: 17.49039 },
    { lat: 48.171254, lng: 17.490947 },
    { lat: 48.171128, lng: 17.491693 },
    { lat: 48.171058, lng: 17.492035 },
    { lat: 48.170954, lng: 17.492583 },
    { lat: 48.170753, lng: 17.49356 },
    { lat: 48.170666, lng: 17.493993 },
    { lat: 48.170559, lng: 17.494456 },
    { lat: 48.170479, lng: 17.494732 },
    { lat: 48.170308, lng: 17.495314 },
    { lat: 48.170101, lng: 17.495927 },
    { lat: 48.170047, lng: 17.496065 },
    { lat: 48.169849, lng: 17.496633 },
    { lat: 48.169658, lng: 17.49719 },
    { lat: 48.169401, lng: 17.497858 },
    { lat: 48.169156, lng: 17.498444 },
    { lat: 48.16896, lng: 17.498884 },
    { lat: 48.168715, lng: 17.499404 },
    { lat: 48.168448, lng: 17.49993 },
    { lat: 48.168336, lng: 17.500149 },
    { lat: 48.168347, lng: 17.500158 },
    { lat: 48.170355, lng: 17.501968 },
    { lat: 48.171955, lng: 17.503357 },
    { lat: 48.171983, lng: 17.503381 },
    { lat: 48.172223, lng: 17.50359 },
    { lat: 48.174461, lng: 17.505515 },
    { lat: 48.175009, lng: 17.505966 },
    { lat: 48.175838, lng: 17.506645 },
    { lat: 48.176443, lng: 17.507059 },
    { lat: 48.176515, lng: 17.5071079 },
  ],
  HrubýŠúr: [
    { lat: 48.1889923, lng: 17.4216747 },
    { lat: 48.1886539, lng: 17.4210938 },
    { lat: 48.1883063, lng: 17.4204888 },
    { lat: 48.187297, lng: 17.4191931 },
    { lat: 48.1869758, lng: 17.4187703 },
    { lat: 48.1870361, lng: 17.4186534 },
    { lat: 48.1867237, lng: 17.4182784 },
    { lat: 48.1864851, lng: 17.4179981 },
    { lat: 48.1858101, lng: 17.4171867 },
    { lat: 48.1853676, lng: 17.416741 },
    { lat: 48.1848486, lng: 17.4162286 },
    { lat: 48.1844306, lng: 17.4158276 },
    { lat: 48.1844323, lng: 17.4157665 },
    { lat: 48.1837976, lng: 17.4152812 },
    { lat: 48.1831703, lng: 17.4148059 },
    { lat: 48.1825796, lng: 17.414408 },
    { lat: 48.1822731, lng: 17.4142102 },
    { lat: 48.1817498, lng: 17.4139452 },
    { lat: 48.1815259, lng: 17.413836 },
    { lat: 48.1808821, lng: 17.4135066 },
    { lat: 48.180861, lng: 17.4133922 },
    { lat: 48.1807021, lng: 17.4132929 },
    { lat: 48.1806155, lng: 17.4133832 },
    { lat: 48.1802373, lng: 17.4132314 },
    { lat: 48.1797152, lng: 17.4129308 },
    { lat: 48.1797092, lng: 17.4128556 },
    { lat: 48.1795922, lng: 17.412811 },
    { lat: 48.1794213, lng: 17.4127016 },
    { lat: 48.1792267, lng: 17.412594 },
    { lat: 48.17828, lng: 17.4118613 },
    { lat: 48.1777469, lng: 17.4115505 },
    { lat: 48.1776586, lng: 17.4114948 },
    { lat: 48.1773233, lng: 17.4111163 },
    { lat: 48.1768501, lng: 17.41077 },
    { lat: 48.1764745, lng: 17.4105003 },
    { lat: 48.176355, lng: 17.4104333 },
    { lat: 48.1762198, lng: 17.4103879 },
    { lat: 48.1759173, lng: 17.4101474 },
    { lat: 48.1746493, lng: 17.4091652 },
    { lat: 48.1743091, lng: 17.4088145 },
    { lat: 48.1739396, lng: 17.40843 },
    { lat: 48.1735221, lng: 17.4080037 },
    { lat: 48.1730087, lng: 17.407441 },
    { lat: 48.1726924, lng: 17.4069712 },
    { lat: 48.1720701, lng: 17.4058984 },
    { lat: 48.1717333, lng: 17.4053758 },
    { lat: 48.1713569, lng: 17.4047798 },
    { lat: 48.1714336, lng: 17.4046918 },
    { lat: 48.1717048, lng: 17.4044851 },
    { lat: 48.1718808, lng: 17.4043632 },
    { lat: 48.1720565, lng: 17.4042844 },
    { lat: 48.1723951, lng: 17.4043516 },
    { lat: 48.1725519, lng: 17.4043642 },
    { lat: 48.173001, lng: 17.4043401 },
    { lat: 48.1733076, lng: 17.4042263 },
    { lat: 48.173496, lng: 17.4041371 },
    { lat: 48.1737297, lng: 17.4039105 },
    { lat: 48.1737885, lng: 17.403842 },
    { lat: 48.1739734, lng: 17.4035753 },
    { lat: 48.1739679, lng: 17.4034747 },
    { lat: 48.1740613, lng: 17.4033489 },
    { lat: 48.174053, lng: 17.4032716 },
    { lat: 48.1742441, lng: 17.4027034 },
    { lat: 48.174281, lng: 17.4025595 },
    { lat: 48.1743145, lng: 17.4024669 },
    { lat: 48.1744314, lng: 17.4021998 },
    { lat: 48.1746248, lng: 17.4019429 },
    { lat: 48.1747374, lng: 17.4018327 },
    { lat: 48.174934, lng: 17.4016968 },
    { lat: 48.1750942, lng: 17.4016591 },
    { lat: 48.1752034, lng: 17.4016533 },
    { lat: 48.1754284, lng: 17.4016576 },
    { lat: 48.1757357, lng: 17.4016923 },
    { lat: 48.1758872, lng: 17.4017826 },
    { lat: 48.1759346, lng: 17.4019192 },
    { lat: 48.1760496, lng: 17.4021059 },
    { lat: 48.1761041, lng: 17.4021814 },
    { lat: 48.1764594, lng: 17.4024667 },
    { lat: 48.1766741, lng: 17.4025899 },
    { lat: 48.1768572, lng: 17.4026789 },
    { lat: 48.1772218, lng: 17.4027617 },
    { lat: 48.1774053, lng: 17.4027526 },
    { lat: 48.1776974, lng: 17.4026248 },
    { lat: 48.1777899, lng: 17.4025531 },
    { lat: 48.1781207, lng: 17.4022566 },
    { lat: 48.1784435, lng: 17.4018541 },
    { lat: 48.1785603, lng: 17.4016369 },
    { lat: 48.1786738, lng: 17.401508 },
    { lat: 48.178855, lng: 17.4013229 },
    { lat: 48.1789492, lng: 17.401191 },
    { lat: 48.1790287, lng: 17.4010417 },
    { lat: 48.1792545, lng: 17.4005224 },
    { lat: 48.1793088, lng: 17.4003499 },
    { lat: 48.179339, lng: 17.4001537 },
    { lat: 48.1793641, lng: 17.3996907 },
    { lat: 48.1794598, lng: 17.3992697 },
    { lat: 48.1797208, lng: 17.3983525 },
    { lat: 48.1797732, lng: 17.3981685 },
    { lat: 48.1798067, lng: 17.3980065 },
    { lat: 48.1799221, lng: 17.3974054 },
    { lat: 48.1799872, lng: 17.3971205 },
    { lat: 48.1800501, lng: 17.3966842 },
    { lat: 48.1801451, lng: 17.3960623 },
    { lat: 48.1802007, lng: 17.3952004 },
    { lat: 48.1801455, lng: 17.3950117 },
    { lat: 48.1800699, lng: 17.3949788 },
    { lat: 48.1799203, lng: 17.3947271 },
    { lat: 48.1800437, lng: 17.3945419 },
    { lat: 48.180451, lng: 17.393497 },
    { lat: 48.1803907, lng: 17.3908255 },
    { lat: 48.1803745, lng: 17.390284 },
    { lat: 48.1803612, lng: 17.3897344 },
    { lat: 48.1803459, lng: 17.388877 },
    { lat: 48.1802924, lng: 17.3887312 },
    { lat: 48.1803536, lng: 17.3886665 },
    { lat: 48.1804327, lng: 17.3884582 },
    { lat: 48.1804335, lng: 17.3880578 },
    { lat: 48.1803985, lng: 17.3877632 },
    { lat: 48.1803335, lng: 17.3873892 },
    { lat: 48.1802051, lng: 17.3869638 },
    { lat: 48.1800162, lng: 17.3863547 },
    { lat: 48.1799397, lng: 17.3861167 },
    { lat: 48.1797234, lng: 17.3857642 },
    { lat: 48.1795593, lng: 17.385517 },
    { lat: 48.1794881, lng: 17.3854278 },
    { lat: 48.1791709, lng: 17.385122 },
    { lat: 48.1788443, lng: 17.3850498 },
    { lat: 48.1786159, lng: 17.3850469 },
    { lat: 48.178401, lng: 17.3851627 },
    { lat: 48.1782184, lng: 17.3852874 },
    { lat: 48.1780879, lng: 17.3854524 },
    { lat: 48.178002, lng: 17.3855724 },
    { lat: 48.1778236, lng: 17.3859175 },
    { lat: 48.1777797, lng: 17.3860937 },
    { lat: 48.1775803, lng: 17.3869326 },
    { lat: 48.1775151, lng: 17.3872309 },
    { lat: 48.1773346, lng: 17.3877007 },
    { lat: 48.1772097, lng: 17.3879511 },
    { lat: 48.1770607, lng: 17.3883494 },
    { lat: 48.1769431, lng: 17.3886241 },
    { lat: 48.1768061, lng: 17.3888781 },
    { lat: 48.1766073, lng: 17.3891545 },
    { lat: 48.1764586, lng: 17.3893519 },
    { lat: 48.1764342, lng: 17.3893926 },
    { lat: 48.176282, lng: 17.3895532 },
    { lat: 48.1760876, lng: 17.3896019 },
    { lat: 48.1758563, lng: 17.3896415 },
    { lat: 48.1756442, lng: 17.3896606 },
    { lat: 48.1754215, lng: 17.3896079 },
    { lat: 48.1752497, lng: 17.3897099 },
    { lat: 48.1750856, lng: 17.3896705 },
    { lat: 48.174772, lng: 17.3896436 },
    { lat: 48.1746648, lng: 17.3896433 },
    { lat: 48.1743925, lng: 17.3896602 },
    { lat: 48.174047, lng: 17.3896971 },
    { lat: 48.1739937, lng: 17.3897109 },
    { lat: 48.1737395, lng: 17.3898299 },
    { lat: 48.173497, lng: 17.3899555 },
    { lat: 48.173382, lng: 17.390028 },
    { lat: 48.1731189, lng: 17.3902363 },
    { lat: 48.1730787, lng: 17.3901417 },
    { lat: 48.1729444, lng: 17.3900912 },
    { lat: 48.1727944, lng: 17.390059 },
    { lat: 48.1726538, lng: 17.3899824 },
    { lat: 48.1725227, lng: 17.3898775 },
    { lat: 48.1723612, lng: 17.3897204 },
    { lat: 48.172221, lng: 17.3895137 },
    { lat: 48.1721124, lng: 17.3894915 },
    { lat: 48.171837, lng: 17.3895993 },
    { lat: 48.171613, lng: 17.3895425 },
    { lat: 48.1714859, lng: 17.3894816 },
    { lat: 48.1712948, lng: 17.3894623 },
    { lat: 48.1711751, lng: 17.389392 },
    { lat: 48.171081, lng: 17.3894024 },
    { lat: 48.1710282, lng: 17.3894768 },
    { lat: 48.1707973, lng: 17.3895223 },
    { lat: 48.1704765, lng: 17.3895918 },
    { lat: 48.1698357, lng: 17.3897057 },
    { lat: 48.1693448, lng: 17.3898002 },
    { lat: 48.1689485, lng: 17.3897837 },
    { lat: 48.169054, lng: 17.392645 },
    { lat: 48.168926, lng: 17.394508 },
    { lat: 48.168743, lng: 17.395291 },
    { lat: 48.168514, lng: 17.395923 },
    { lat: 48.168248, lng: 17.396247 },
    { lat: 48.16885, lng: 17.397131 },
    { lat: 48.169031, lng: 17.397304 },
    { lat: 48.169372, lng: 17.397521 },
    { lat: 48.169763, lng: 17.397668 },
    { lat: 48.170242, lng: 17.397471 },
    { lat: 48.170904, lng: 17.396894 },
    { lat: 48.17113, lng: 17.396857 },
    { lat: 48.171306, lng: 17.396952 },
    { lat: 48.172087, lng: 17.397662 },
    { lat: 48.172918, lng: 17.39811 },
    { lat: 48.173499, lng: 17.398538 },
    { lat: 48.173902, lng: 17.399058 },
    { lat: 48.174105, lng: 17.399651 },
    { lat: 48.174089, lng: 17.40038 },
    { lat: 48.173241, lng: 17.401485 },
    { lat: 48.172766, lng: 17.40166 },
    { lat: 48.172422, lng: 17.402002 },
    { lat: 48.172199, lng: 17.402322 },
    { lat: 48.171306, lng: 17.402692 },
    { lat: 48.170926, lng: 17.402912 },
    { lat: 48.169422, lng: 17.404351 },
    { lat: 48.167135, lng: 17.406075 },
    { lat: 48.166971, lng: 17.406183 },
    { lat: 48.16666, lng: 17.406237 },
    { lat: 48.166631, lng: 17.407225 },
    { lat: 48.166517, lng: 17.407865 },
    { lat: 48.166299, lng: 17.408523 },
    { lat: 48.165932, lng: 17.409133 },
    { lat: 48.165724, lng: 17.409454 },
    { lat: 48.165114, lng: 17.410069 },
    { lat: 48.164179, lng: 17.410229 },
    { lat: 48.16338, lng: 17.410028 },
    { lat: 48.162475, lng: 17.409308 },
    { lat: 48.16223, lng: 17.40893 },
    { lat: 48.161726, lng: 17.407965 },
    { lat: 48.160917, lng: 17.40498 },
    { lat: 48.160715, lng: 17.404131 },
    { lat: 48.160662, lng: 17.403628 },
    { lat: 48.160536, lng: 17.403047 },
    { lat: 48.160362, lng: 17.402491 },
    { lat: 48.15985, lng: 17.401381 },
    { lat: 48.159187, lng: 17.400379 },
    { lat: 48.158339, lng: 17.399297 },
    { lat: 48.157157, lng: 17.398115 },
    { lat: 48.15606, lng: 17.397549 },
    { lat: 48.155099, lng: 17.397696 },
    { lat: 48.154437, lng: 17.397995 },
    { lat: 48.153735, lng: 17.398508 },
    { lat: 48.153339, lng: 17.398975 },
    { lat: 48.153034, lng: 17.399485 },
    { lat: 48.152462, lng: 17.400842 },
    { lat: 48.152232, lng: 17.401219 },
    { lat: 48.151998, lng: 17.402142 },
    { lat: 48.151892, lng: 17.402379 },
    { lat: 48.151741, lng: 17.403068 },
    { lat: 48.151612, lng: 17.404681 },
    { lat: 48.151663, lng: 17.405551 },
    { lat: 48.15154, lng: 17.407052 },
    { lat: 48.151555, lng: 17.407673 },
    { lat: 48.1515836, lng: 17.4079616 },
    { lat: 48.151694, lng: 17.409078 },
    { lat: 48.152314, lng: 17.412313 },
    { lat: 48.15273, lng: 17.413898 },
    { lat: 48.153123, lng: 17.41503 },
    { lat: 48.153578, lng: 17.416592 },
    { lat: 48.153572, lng: 17.417599 },
    { lat: 48.153523, lng: 17.418247 },
    { lat: 48.153389, lng: 17.419007 },
    { lat: 48.153129, lng: 17.419956 },
    { lat: 48.1527976, lng: 17.4207791 },
    { lat: 48.1533767, lng: 17.4214656 },
    { lat: 48.1534834, lng: 17.4215961 },
    { lat: 48.1535954, lng: 17.4217714 },
    { lat: 48.1533455, lng: 17.4224272 },
    { lat: 48.1531695, lng: 17.4227439 },
    { lat: 48.153026, lng: 17.4231589 },
    { lat: 48.1529585, lng: 17.4234059 },
    { lat: 48.1529227, lng: 17.423638 },
    { lat: 48.1529007, lng: 17.4240455 },
    { lat: 48.1529266, lng: 17.4244113 },
    { lat: 48.1532747, lng: 17.4241516 },
    { lat: 48.1536467, lng: 17.4239161 },
    { lat: 48.1538278, lng: 17.4238171 },
    { lat: 48.1541049, lng: 17.4266903 },
    { lat: 48.1541661, lng: 17.4273339 },
    { lat: 48.1557084, lng: 17.4270162 },
    { lat: 48.1568172, lng: 17.4267834 },
    { lat: 48.1580609, lng: 17.426527 },
    { lat: 48.1596475, lng: 17.4262274 },
    { lat: 48.1617094, lng: 17.4258367 },
    { lat: 48.1614502, lng: 17.4242992 },
    { lat: 48.1616132, lng: 17.4242874 },
    { lat: 48.1618191, lng: 17.4242584 },
    { lat: 48.1619287, lng: 17.4242053 },
    { lat: 48.1620635, lng: 17.4241266 },
    { lat: 48.1624793, lng: 17.4239016 },
    { lat: 48.1627218, lng: 17.4236705 },
    { lat: 48.1632277, lng: 17.4231826 },
    { lat: 48.1636224, lng: 17.4227927 },
    { lat: 48.1642894, lng: 17.4232174 },
    { lat: 48.1648943, lng: 17.4236098 },
    { lat: 48.1652413, lng: 17.4237865 },
    { lat: 48.1663902, lng: 17.4241549 },
    { lat: 48.1666071, lng: 17.4242955 },
    { lat: 48.1671416, lng: 17.424629 },
    { lat: 48.1676866, lng: 17.4249862 },
    { lat: 48.1681977, lng: 17.4253081 },
    { lat: 48.169082, lng: 17.4258834 },
    { lat: 48.1691353, lng: 17.4259181 },
    { lat: 48.1696693, lng: 17.4262433 },
    { lat: 48.170485, lng: 17.426752 },
    { lat: 48.1705298, lng: 17.4267817 },
    { lat: 48.1711229, lng: 17.4269566 },
    { lat: 48.1713637, lng: 17.4269435 },
    { lat: 48.1718051, lng: 17.4269394 },
    { lat: 48.1721307, lng: 17.4269454 },
    { lat: 48.1724684, lng: 17.4269444 },
    { lat: 48.1725824, lng: 17.4268755 },
    { lat: 48.1729281, lng: 17.4266496 },
    { lat: 48.172957, lng: 17.4266244 },
    { lat: 48.1733338, lng: 17.4263745 },
    { lat: 48.173737, lng: 17.4262172 },
    { lat: 48.1740558, lng: 17.4258745 },
    { lat: 48.1741907, lng: 17.4258039 },
    { lat: 48.1742586, lng: 17.4257793 },
    { lat: 48.1747936, lng: 17.4254963 },
    { lat: 48.175022, lng: 17.4253619 },
    { lat: 48.1752841, lng: 17.4252216 },
    { lat: 48.1756326, lng: 17.4250924 },
    { lat: 48.1762914, lng: 17.424854 },
    { lat: 48.1765096, lng: 17.4247709 },
    { lat: 48.1769467, lng: 17.4246322 },
    { lat: 48.1772527, lng: 17.4244737 },
    { lat: 48.1775836, lng: 17.4243708 },
    { lat: 48.1779308, lng: 17.4242738 },
    { lat: 48.179185, lng: 17.4239682 },
    { lat: 48.1797249, lng: 17.4237758 },
    { lat: 48.1796613, lng: 17.4240132 },
    { lat: 48.1800121, lng: 17.4239723 },
    { lat: 48.1806105, lng: 17.4239359 },
    { lat: 48.1808721, lng: 17.4240145 },
    { lat: 48.181325, lng: 17.4243286 },
    { lat: 48.1815293, lng: 17.4244128 },
    { lat: 48.1820619, lng: 17.4246464 },
    { lat: 48.1823776, lng: 17.4247608 },
    { lat: 48.1827472, lng: 17.4247655 },
    { lat: 48.1830472, lng: 17.4247589 },
    { lat: 48.1831618, lng: 17.4247491 },
    { lat: 48.1833295, lng: 17.4247221 },
    { lat: 48.1839065, lng: 17.4246163 },
    { lat: 48.1859866, lng: 17.4242448 },
    { lat: 48.1862491, lng: 17.4248789 },
    { lat: 48.1889923, lng: 17.4216747 },
  ],
  HurbanovaVes: [
    { lat: 48.1527976, lng: 17.4207791 },
    { lat: 48.151847, lng: 17.42314 },
    { lat: 48.151288, lng: 17.424017 },
    { lat: 48.150858, lng: 17.424928 },
    { lat: 48.150462, lng: 17.425589 },
    { lat: 48.149789, lng: 17.426447 },
    { lat: 48.149141, lng: 17.426778 },
    { lat: 48.148751, lng: 17.427091 },
    { lat: 48.147655, lng: 17.428485 },
    { lat: 48.147064, lng: 17.429102 },
    { lat: 48.145188, lng: 17.431291 },
    { lat: 48.1447, lng: 17.431976 },
    { lat: 48.144321, lng: 17.432839 },
    { lat: 48.14414, lng: 17.434151 },
    { lat: 48.144085, lng: 17.4352033 },
    { lat: 48.144045, lng: 17.435967 },
    { lat: 48.144055, lng: 17.436964 },
    { lat: 48.144159, lng: 17.438768 },
    { lat: 48.144319, lng: 17.440068 },
    { lat: 48.145005, lng: 17.441401 },
    { lat: 48.145085, lng: 17.441906 },
    { lat: 48.145118, lng: 17.443563 },
    { lat: 48.145373, lng: 17.445545 },
    { lat: 48.145575, lng: 17.446375 },
    { lat: 48.146129, lng: 17.447796 },
    { lat: 48.146448, lng: 17.448496 },
    { lat: 48.147355, lng: 17.449797 },
    { lat: 48.148692, lng: 17.450978 },
    { lat: 48.149098, lng: 17.451419 },
    { lat: 48.1504, lng: 17.45317 },
    { lat: 48.150992, lng: 17.454222 },
    { lat: 48.151795, lng: 17.455224 },
    { lat: 48.153094, lng: 17.456487 },
    { lat: 48.153635, lng: 17.457084 },
    { lat: 48.154233, lng: 17.458236 },
    { lat: 48.15453, lng: 17.459182 },
    { lat: 48.154919, lng: 17.461014 },
    { lat: 48.155113, lng: 17.462311 },
    { lat: 48.155133, lng: 17.462706 },
    { lat: 48.155061, lng: 17.463247 },
    { lat: 48.1549953, lng: 17.4634468 },
    { lat: 48.1562115, lng: 17.4644063 },
    { lat: 48.1577572, lng: 17.4656212 },
    { lat: 48.1582918, lng: 17.4660479 },
    { lat: 48.1593392, lng: 17.4668934 },
    { lat: 48.1611157, lng: 17.468271 },
    { lat: 48.1618812, lng: 17.4688635 },
    { lat: 48.162414, lng: 17.4692778 },
    { lat: 48.1631043, lng: 17.467289 },
    { lat: 48.1632899, lng: 17.4667718 },
    { lat: 48.1640585, lng: 17.4644568 },
    { lat: 48.1641003, lng: 17.4643344 },
    { lat: 48.1635064, lng: 17.4638657 },
    { lat: 48.1631563, lng: 17.4635862 },
    { lat: 48.1639558, lng: 17.4611755 },
    { lat: 48.1654405, lng: 17.4566997 },
    { lat: 48.16591, lng: 17.4552812 },
    { lat: 48.1681178, lng: 17.4486068 },
    { lat: 48.1681655, lng: 17.4484631 },
    { lat: 48.1679588, lng: 17.4484449 },
    { lat: 48.1679301, lng: 17.4477231 },
    { lat: 48.1678175, lng: 17.445168 },
    { lat: 48.1678134, lng: 17.4450505 },
    { lat: 48.1676747, lng: 17.4419193 },
    { lat: 48.1666429, lng: 17.4420131 },
    { lat: 48.1631779, lng: 17.4423295 },
    { lat: 48.1631663, lng: 17.4421859 },
    { lat: 48.1630626, lng: 17.4412835 },
    { lat: 48.1630164, lng: 17.440744 },
    { lat: 48.1627922, lng: 17.438212 },
    { lat: 48.1626591, lng: 17.4366495 },
    { lat: 48.1625612, lng: 17.4354879 },
    { lat: 48.1625133, lng: 17.4349428 },
    { lat: 48.162218, lng: 17.4315602 },
    { lat: 48.1619918, lng: 17.4289983 },
    { lat: 48.1619896, lng: 17.428964 },
    { lat: 48.1618141, lng: 17.426978 },
    { lat: 48.1617094, lng: 17.4258367 },
    { lat: 48.1596475, lng: 17.4262274 },
    { lat: 48.1580609, lng: 17.426527 },
    { lat: 48.1568172, lng: 17.4267834 },
    { lat: 48.1557084, lng: 17.4270162 },
    { lat: 48.1541661, lng: 17.4273339 },
    { lat: 48.1541049, lng: 17.4266903 },
    { lat: 48.1538278, lng: 17.4238171 },
    { lat: 48.1536467, lng: 17.4239161 },
    { lat: 48.1532747, lng: 17.4241516 },
    { lat: 48.1529266, lng: 17.4244113 },
    { lat: 48.1529007, lng: 17.4240455 },
    { lat: 48.1529227, lng: 17.423638 },
    { lat: 48.1529585, lng: 17.4234059 },
    { lat: 48.153026, lng: 17.4231589 },
    { lat: 48.1531695, lng: 17.4227439 },
    { lat: 48.1533455, lng: 17.4224272 },
    { lat: 48.1535954, lng: 17.4217714 },
    { lat: 48.1534834, lng: 17.4215961 },
    { lat: 48.1533767, lng: 17.4214656 },
    { lat: 48.1527976, lng: 17.4207791 },
  ],
  Igram: [
    { lat: 48.2476085, lng: 17.4491419 },
    { lat: 48.2484007, lng: 17.4495157 },
    { lat: 48.2487086, lng: 17.4496647 },
    { lat: 48.249266, lng: 17.4499205 },
    { lat: 48.249454, lng: 17.4500141 },
    { lat: 48.2499982, lng: 17.4502743 },
    { lat: 48.250657, lng: 17.4503841 },
    { lat: 48.2506837, lng: 17.4503939 },
    { lat: 48.2514732, lng: 17.4506226 },
    { lat: 48.2516488, lng: 17.4506666 },
    { lat: 48.2521498, lng: 17.4508154 },
    { lat: 48.2528727, lng: 17.4510792 },
    { lat: 48.2532411, lng: 17.4512221 },
    { lat: 48.2535418, lng: 17.4513307 },
    { lat: 48.2553233, lng: 17.452 },
    { lat: 48.2554184, lng: 17.4520394 },
    { lat: 48.2556951, lng: 17.4521786 },
    { lat: 48.2559477, lng: 17.4522256 },
    { lat: 48.2563201, lng: 17.4523086 },
    { lat: 48.2571602, lng: 17.4524811 },
    { lat: 48.257723, lng: 17.4525713 },
    { lat: 48.2580893, lng: 17.4526475 },
    { lat: 48.2582795, lng: 17.452691 },
    { lat: 48.2584998, lng: 17.4527259 },
    { lat: 48.2590289, lng: 17.4528039 },
    { lat: 48.2593373, lng: 17.4528919 },
    { lat: 48.259558, lng: 17.4529868 },
    { lat: 48.2597468, lng: 17.4530592 },
    { lat: 48.2604881, lng: 17.4532918 },
    { lat: 48.2607953, lng: 17.4533968 },
    { lat: 48.2610581, lng: 17.4534966 },
    { lat: 48.2616397, lng: 17.4537051 },
    { lat: 48.2620681, lng: 17.4538322 },
    { lat: 48.2622002, lng: 17.4538834 },
    { lat: 48.2624301, lng: 17.4539481 },
    { lat: 48.2625504, lng: 17.4540076 },
    { lat: 48.2626861, lng: 17.4540455 },
    { lat: 48.2630302, lng: 17.4541622 },
    { lat: 48.2633437, lng: 17.4542775 },
    { lat: 48.2639598, lng: 17.4544943 },
    { lat: 48.2642248, lng: 17.4546106 },
    { lat: 48.2644456, lng: 17.4546032 },
    { lat: 48.2645784, lng: 17.4546483 },
    { lat: 48.264771, lng: 17.4546744 },
    { lat: 48.2656138, lng: 17.4548009 },
    { lat: 48.2662558, lng: 17.454861 },
    { lat: 48.2663932, lng: 17.4546449 },
    { lat: 48.2666821, lng: 17.4546943 },
    { lat: 48.2668154, lng: 17.454729 },
    { lat: 48.2670559, lng: 17.4548497 },
    { lat: 48.2672607, lng: 17.4550299 },
    { lat: 48.267401, lng: 17.455139 },
    { lat: 48.2674912, lng: 17.4551893 },
    { lat: 48.2676278, lng: 17.4553108 },
    { lat: 48.2679264, lng: 17.4554924 },
    { lat: 48.2682495, lng: 17.4557561 },
    { lat: 48.268761, lng: 17.4561928 },
    { lat: 48.26905, lng: 17.4564505 },
    { lat: 48.2693854, lng: 17.4567833 },
    { lat: 48.2697183, lng: 17.4570936 },
    { lat: 48.2701644, lng: 17.4575813 },
    { lat: 48.2704193, lng: 17.4578742 },
    { lat: 48.2707784, lng: 17.4582917 },
    { lat: 48.2710824, lng: 17.4586986 },
    { lat: 48.2712853, lng: 17.4590253 },
    { lat: 48.2714611, lng: 17.4593178 },
    { lat: 48.2718705, lng: 17.4599885 },
    { lat: 48.2722245, lng: 17.4605557 },
    { lat: 48.2725849, lng: 17.461241 },
    { lat: 48.2728702, lng: 17.4617935 },
    { lat: 48.2733099, lng: 17.4626392 },
    { lat: 48.2736758, lng: 17.4633738 },
    { lat: 48.274118, lng: 17.4642935 },
    { lat: 48.2745604, lng: 17.465199 },
    { lat: 48.2747243, lng: 17.4655509 },
    { lat: 48.2752856, lng: 17.4667241 },
    { lat: 48.2758624, lng: 17.4679257 },
    { lat: 48.2766301, lng: 17.469586 },
    { lat: 48.2768216, lng: 17.4700167 },
    { lat: 48.2773066, lng: 17.4708477 },
    { lat: 48.2780457, lng: 17.4721339 },
    { lat: 48.2781947, lng: 17.4724136 },
    { lat: 48.2786294, lng: 17.4732171 },
    { lat: 48.2793337, lng: 17.4745237 },
    { lat: 48.2798649, lng: 17.4755122 },
    { lat: 48.2801557, lng: 17.4760454 },
    { lat: 48.2807281, lng: 17.4771843 },
    { lat: 48.2812002, lng: 17.4781135 },
    { lat: 48.2822623, lng: 17.4802071 },
    { lat: 48.2827857, lng: 17.4813725 },
    { lat: 48.2835603, lng: 17.4830899 },
    { lat: 48.2837871, lng: 17.4835961 },
    { lat: 48.2841959, lng: 17.4844906 },
    { lat: 48.2849609, lng: 17.4860249 },
    { lat: 48.2855658, lng: 17.4872457 },
    { lat: 48.286206, lng: 17.4885442 },
    { lat: 48.2863604, lng: 17.4888552 },
    { lat: 48.2868901, lng: 17.4899108 },
    { lat: 48.2871768, lng: 17.4904347 },
    { lat: 48.2877019, lng: 17.4914174 },
    { lat: 48.2879811, lng: 17.4918951 },
    { lat: 48.288808, lng: 17.493304 },
    { lat: 48.288966, lng: 17.493507 },
    { lat: 48.28921, lng: 17.493839 },
    { lat: 48.289384, lng: 17.49407 },
    { lat: 48.289503, lng: 17.493656 },
    { lat: 48.28961, lng: 17.493312 },
    { lat: 48.28973, lng: 17.492917 },
    { lat: 48.28985, lng: 17.492476 },
    { lat: 48.289979, lng: 17.492053 },
    { lat: 48.290087, lng: 17.491678 },
    { lat: 48.290242, lng: 17.491273 },
    { lat: 48.290392, lng: 17.49087 },
    { lat: 48.290503, lng: 17.490567 },
    { lat: 48.290595, lng: 17.490335 },
    { lat: 48.290695, lng: 17.490051 },
    { lat: 48.290809, lng: 17.489718 },
    { lat: 48.290945, lng: 17.489334 },
    { lat: 48.291017, lng: 17.489093 },
    { lat: 48.29112, lng: 17.488767 },
    { lat: 48.291231, lng: 17.488404 },
    { lat: 48.291318, lng: 17.488103 },
    { lat: 48.291534, lng: 17.487496 },
    { lat: 48.291671, lng: 17.487218 },
    { lat: 48.291788, lng: 17.486966 },
    { lat: 48.291901, lng: 17.486731 },
    { lat: 48.292043, lng: 17.486438 },
    { lat: 48.292187, lng: 17.48613 },
    { lat: 48.292307, lng: 17.485892 },
    { lat: 48.292448, lng: 17.485598 },
    { lat: 48.292596, lng: 17.485277 },
    { lat: 48.292757, lng: 17.485027 },
    { lat: 48.292945, lng: 17.484747 },
    { lat: 48.293068, lng: 17.484583 },
    { lat: 48.293259, lng: 17.484342 },
    { lat: 48.293469, lng: 17.484066 },
    { lat: 48.293646, lng: 17.483845 },
    { lat: 48.293795, lng: 17.483632 },
    { lat: 48.293984, lng: 17.483353 },
    { lat: 48.294259, lng: 17.482953 },
    { lat: 48.294463, lng: 17.48264 },
    { lat: 48.294672, lng: 17.482313 },
    { lat: 48.294895, lng: 17.481959 },
    { lat: 48.295045, lng: 17.481721 },
    { lat: 48.295221, lng: 17.481458 },
    { lat: 48.295416, lng: 17.481163 },
    { lat: 48.295557, lng: 17.48089 },
    { lat: 48.295702, lng: 17.480606 },
    { lat: 48.295834, lng: 17.480348 },
    { lat: 48.296037, lng: 17.47997 },
    { lat: 48.29618, lng: 17.47968 },
    { lat: 48.296345, lng: 17.47934 },
    { lat: 48.296501, lng: 17.479032 },
    { lat: 48.296636, lng: 17.478762 },
    { lat: 48.296778, lng: 17.478469 },
    { lat: 48.296928, lng: 17.478174 },
    { lat: 48.297032, lng: 17.477916 },
    { lat: 48.297178, lng: 17.477548 },
    { lat: 48.297298, lng: 17.477222 },
    { lat: 48.297443, lng: 17.476839 },
    { lat: 48.297527, lng: 17.476621 },
    { lat: 48.2973708, lng: 17.4763566 },
    { lat: 48.2968394, lng: 17.475506 },
    { lat: 48.2964646, lng: 17.4748958 },
    { lat: 48.2961914, lng: 17.4744394 },
    { lat: 48.296057, lng: 17.4742304 },
    { lat: 48.295498, lng: 17.4731442 },
    { lat: 48.2952395, lng: 17.4726028 },
    { lat: 48.294957, lng: 17.4720056 },
    { lat: 48.294803, lng: 17.4716994 },
    { lat: 48.294758, lng: 17.4715799 },
    { lat: 48.2944892, lng: 17.471018 },
    { lat: 48.2940867, lng: 17.4700319 },
    { lat: 48.2938075, lng: 17.4693436 },
    { lat: 48.2936012, lng: 17.468842 },
    { lat: 48.2933885, lng: 17.468311 },
    { lat: 48.2931149, lng: 17.467622 },
    { lat: 48.2929078, lng: 17.4671086 },
    { lat: 48.2928063, lng: 17.4668474 },
    { lat: 48.2925827, lng: 17.4663053 },
    { lat: 48.2923735, lng: 17.4658795 },
    { lat: 48.2921023, lng: 17.4653543 },
    { lat: 48.2915053, lng: 17.4642569 },
    { lat: 48.2911448, lng: 17.4636068 },
    { lat: 48.2908765, lng: 17.4630346 },
    { lat: 48.2904986, lng: 17.4623423 },
    { lat: 48.290005, lng: 17.4613983 },
    { lat: 48.2897348, lng: 17.460779 },
    { lat: 48.2895599, lng: 17.4604499 },
    { lat: 48.2893339, lng: 17.4599766 },
    { lat: 48.289148, lng: 17.4595966 },
    { lat: 48.2889551, lng: 17.4591456 },
    { lat: 48.2887913, lng: 17.45883 },
    { lat: 48.288626, lng: 17.4584748 },
    { lat: 48.2884957, lng: 17.4582077 },
    { lat: 48.2882962, lng: 17.4577577 },
    { lat: 48.2881413, lng: 17.4572658 },
    { lat: 48.2879363, lng: 17.4566802 },
    { lat: 48.2877893, lng: 17.4563783 },
    { lat: 48.2875949, lng: 17.4559935 },
    { lat: 48.2872827, lng: 17.4553196 },
    { lat: 48.2870707, lng: 17.4548723 },
    { lat: 48.2865258, lng: 17.4536559 },
    { lat: 48.2864793, lng: 17.4535662 },
    { lat: 48.2861153, lng: 17.4528261 },
    { lat: 48.2859111, lng: 17.4524275 },
    { lat: 48.2856685, lng: 17.4519245 },
    { lat: 48.285495, lng: 17.4515462 },
    { lat: 48.2855073, lng: 17.4515105 },
    { lat: 48.2853832, lng: 17.4512501 },
    { lat: 48.2851242, lng: 17.4507572 },
    { lat: 48.2848895, lng: 17.450287 },
    { lat: 48.2842933, lng: 17.4492414 },
    { lat: 48.284043, lng: 17.4488115 },
    { lat: 48.2837719, lng: 17.4483212 },
    { lat: 48.2834192, lng: 17.4477049 },
    { lat: 48.2828381, lng: 17.4467002 },
    { lat: 48.2825669, lng: 17.4462269 },
    { lat: 48.2820722, lng: 17.4453697 },
    { lat: 48.2819726, lng: 17.445193 },
    { lat: 48.2816483, lng: 17.4446486 },
    { lat: 48.2813871, lng: 17.4442263 },
    { lat: 48.2810771, lng: 17.4437112 },
    { lat: 48.280721, lng: 17.4431309 },
    { lat: 48.2802167, lng: 17.4423191 },
    { lat: 48.2795459, lng: 17.4412211 },
    { lat: 48.2789308, lng: 17.4402166 },
    { lat: 48.2785578, lng: 17.4395998 },
    { lat: 48.2784546, lng: 17.4397341 },
    { lat: 48.2776781, lng: 17.4384689 },
    { lat: 48.2769521, lng: 17.4373754 },
    { lat: 48.2767013, lng: 17.4370068 },
    { lat: 48.2764833, lng: 17.4366924 },
    { lat: 48.2763754, lng: 17.4364924 },
    { lat: 48.2763553, lng: 17.4365121 },
    { lat: 48.2763058, lng: 17.4365846 },
    { lat: 48.2761718, lng: 17.4367478 },
    { lat: 48.2760393, lng: 17.4368483 },
    { lat: 48.2758885, lng: 17.4369446 },
    { lat: 48.2757575, lng: 17.4371177 },
    { lat: 48.2756222, lng: 17.437331 },
    { lat: 48.2754982, lng: 17.4374921 },
    { lat: 48.2754239, lng: 17.4375646 },
    { lat: 48.2752295, lng: 17.437702 },
    { lat: 48.2751881, lng: 17.4377435 },
    { lat: 48.275122, lng: 17.4377701 },
    { lat: 48.2749853, lng: 17.4378898 },
    { lat: 48.2748877, lng: 17.4380056 },
    { lat: 48.2747975, lng: 17.4381286 },
    { lat: 48.2747491, lng: 17.4382193 },
    { lat: 48.274588, lng: 17.438401 },
    { lat: 48.2742611, lng: 17.4387412 },
    { lat: 48.2740045, lng: 17.4390171 },
    { lat: 48.273856, lng: 17.439184 },
    { lat: 48.2737529, lng: 17.4392837 },
    { lat: 48.2736061, lng: 17.4394581 },
    { lat: 48.2734728, lng: 17.4395985 },
    { lat: 48.2733127, lng: 17.4398458 },
    { lat: 48.2732367, lng: 17.439999 },
    { lat: 48.2731139, lng: 17.4402284 },
    { lat: 48.2730207, lng: 17.4404289 },
    { lat: 48.2729082, lng: 17.4405949 },
    { lat: 48.272847, lng: 17.4407823 },
    { lat: 48.2728124, lng: 17.4408584 },
    { lat: 48.2726836, lng: 17.4410836 },
    { lat: 48.2725958, lng: 17.441213 },
    { lat: 48.2724552, lng: 17.4414483 },
    { lat: 48.2722355, lng: 17.4418033 },
    { lat: 48.2720361, lng: 17.4421622 },
    { lat: 48.2719326, lng: 17.4423068 },
    { lat: 48.2717061, lng: 17.4426476 },
    { lat: 48.2715267, lng: 17.4428936 },
    { lat: 48.2711482, lng: 17.4434509 },
    { lat: 48.2709548, lng: 17.4438309 },
    { lat: 48.2709517, lng: 17.4439228 },
    { lat: 48.2709179, lng: 17.4440977 },
    { lat: 48.2709099, lng: 17.4441843 },
    { lat: 48.2709379, lng: 17.4442327 },
    { lat: 48.2706645, lng: 17.4445287 },
    { lat: 48.2705044, lng: 17.4447244 },
    { lat: 48.2702827, lng: 17.4449808 },
    { lat: 48.2698558, lng: 17.4454175 },
    { lat: 48.2697343, lng: 17.4455298 },
    { lat: 48.2694831, lng: 17.4456974 },
    { lat: 48.269258, lng: 17.4458326 },
    { lat: 48.2691281, lng: 17.4459021 },
    { lat: 48.2687838, lng: 17.4460613 },
    { lat: 48.2684266, lng: 17.4461275 },
    { lat: 48.2681608, lng: 17.4461035 },
    { lat: 48.2678408, lng: 17.4460619 },
    { lat: 48.2677144, lng: 17.4460641 },
    { lat: 48.267375, lng: 17.4460542 },
    { lat: 48.2670413, lng: 17.4458743 },
    { lat: 48.2665102, lng: 17.4456249 },
    { lat: 48.2662525, lng: 17.4454999 },
    { lat: 48.2660022, lng: 17.445384 },
    { lat: 48.2657945, lng: 17.4452805 },
    { lat: 48.2655854, lng: 17.4451907 },
    { lat: 48.2651204, lng: 17.4449699 },
    { lat: 48.2646819, lng: 17.4447568 },
    { lat: 48.2642917, lng: 17.4445911 },
    { lat: 48.2640702, lng: 17.4444837 },
    { lat: 48.263544, lng: 17.4442404 },
    { lat: 48.2631147, lng: 17.4440294 },
    { lat: 48.2629153, lng: 17.4439164 },
    { lat: 48.2624689, lng: 17.4436851 },
    { lat: 48.2621057, lng: 17.4435189 },
    { lat: 48.2615777, lng: 17.4432478 },
    { lat: 48.2609193, lng: 17.4428995 },
    { lat: 48.2605119, lng: 17.4426777 },
    { lat: 48.2602379, lng: 17.4425112 },
    { lat: 48.259994, lng: 17.4423716 },
    { lat: 48.2596022, lng: 17.4421291 },
    { lat: 48.2593911, lng: 17.4420091 },
    { lat: 48.2592371, lng: 17.4419158 },
    { lat: 48.2589452, lng: 17.4417517 },
    { lat: 48.2584019, lng: 17.4414187 },
    { lat: 48.2581799, lng: 17.4412709 },
    { lat: 48.2577028, lng: 17.4409815 },
    { lat: 48.2574871, lng: 17.4408438 },
    { lat: 48.2572698, lng: 17.4407146 },
    { lat: 48.2567874, lng: 17.4404515 },
    { lat: 48.2565437, lng: 17.4403329 },
    { lat: 48.2563734, lng: 17.4402148 },
    { lat: 48.2561882, lng: 17.4400751 },
    { lat: 48.255983, lng: 17.4399417 },
    { lat: 48.2556771, lng: 17.4397172 },
    { lat: 48.2554236, lng: 17.439552 },
    { lat: 48.255417, lng: 17.4395572 },
    { lat: 48.2552399, lng: 17.4394365 },
    { lat: 48.2544512, lng: 17.4388382 },
    { lat: 48.2541865, lng: 17.4386576 },
    { lat: 48.2539063, lng: 17.4384125 },
    { lat: 48.2537823, lng: 17.4383104 },
    { lat: 48.2535599, lng: 17.4381575 },
    { lat: 48.2533731, lng: 17.4380443 },
    { lat: 48.2530646, lng: 17.437816 },
    { lat: 48.2527504, lng: 17.4375682 },
    { lat: 48.2524348, lng: 17.4373341 },
    { lat: 48.2522844, lng: 17.4372105 },
    { lat: 48.2521645, lng: 17.4371012 },
    { lat: 48.2520159, lng: 17.4369865 },
    { lat: 48.2517644, lng: 17.4367839 },
    { lat: 48.2516198, lng: 17.4366619 },
    { lat: 48.2515219, lng: 17.4365974 },
    { lat: 48.2513367, lng: 17.4364578 },
    { lat: 48.2511682, lng: 17.4363157 },
    { lat: 48.250972, lng: 17.436181 },
    { lat: 48.2507496, lng: 17.436008 },
    { lat: 48.2503366, lng: 17.4357187 },
    { lat: 48.2500821, lng: 17.4355206 },
    { lat: 48.2498489, lng: 17.435355 },
    { lat: 48.2493568, lng: 17.4349961 },
    { lat: 48.2489555, lng: 17.434711 },
    { lat: 48.2486324, lng: 17.4344663 },
    { lat: 48.2483709, lng: 17.434282 },
    { lat: 48.2478169, lng: 17.4338738 },
    { lat: 48.2472612, lng: 17.4334718 },
    { lat: 48.2467391, lng: 17.433102 },
    { lat: 48.2464771, lng: 17.4329447 },
    { lat: 48.2461905, lng: 17.4341693 },
    { lat: 48.2461135, lng: 17.4345482 },
    { lat: 48.2460071, lng: 17.4351903 },
    { lat: 48.24594, lng: 17.4358122 },
    { lat: 48.2458943, lng: 17.4362736 },
    { lat: 48.2458579, lng: 17.436514 },
    { lat: 48.2458021, lng: 17.4369946 },
    { lat: 48.2457853, lng: 17.4372221 },
    { lat: 48.2457525, lng: 17.4375362 },
    { lat: 48.2457009, lng: 17.4382396 },
    { lat: 48.2456877, lng: 17.4383827 },
    { lat: 48.2456258, lng: 17.4392406 },
    { lat: 48.2456075, lng: 17.4394122 },
    { lat: 48.2455726, lng: 17.4398326 },
    { lat: 48.2455659, lng: 17.4400245 },
    { lat: 48.2455622, lng: 17.440474 },
    { lat: 48.2455449, lng: 17.4406431 },
    { lat: 48.2455525, lng: 17.4408999 },
    { lat: 48.245595, lng: 17.4412945 },
    { lat: 48.245649, lng: 17.4416409 },
    { lat: 48.2457056, lng: 17.4419388 },
    { lat: 48.2458216, lng: 17.4426135 },
    { lat: 48.2459319, lng: 17.4431132 },
    { lat: 48.2459607, lng: 17.4432605 },
    { lat: 48.2460723, lng: 17.443886 },
    { lat: 48.2462383, lng: 17.4446532 },
    { lat: 48.2464097, lng: 17.4453639 },
    { lat: 48.2465357, lng: 17.4460364 },
    { lat: 48.2468469, lng: 17.4470067 },
    { lat: 48.2470819, lng: 17.4478629 },
    { lat: 48.2472282, lng: 17.4482045 },
    { lat: 48.2476085, lng: 17.4491419 },
  ],
  IvankapriDunaji: [
    { lat: 48.204226, lng: 17.23293 },
    { lat: 48.204207, lng: 17.232894 },
    { lat: 48.203955, lng: 17.232402 },
    { lat: 48.203939, lng: 17.232347 },
    { lat: 48.203936, lng: 17.232331 },
    { lat: 48.203914, lng: 17.232248 },
    { lat: 48.203903, lng: 17.232212 },
    { lat: 48.203889, lng: 17.232159 },
    { lat: 48.203885, lng: 17.232142 },
    { lat: 48.20387, lng: 17.232092 },
    { lat: 48.203872, lng: 17.232062 },
    { lat: 48.203879, lng: 17.232027 },
    { lat: 48.2039204, lng: 17.2317797 },
    { lat: 48.203969, lng: 17.231489 },
    { lat: 48.203987, lng: 17.231379 },
    { lat: 48.204066, lng: 17.230884 },
    { lat: 48.204085, lng: 17.23076 },
    { lat: 48.204128, lng: 17.230534 },
    { lat: 48.204146, lng: 17.230442 },
    { lat: 48.204221, lng: 17.230012 },
    { lat: 48.204321, lng: 17.229617 },
    { lat: 48.204427, lng: 17.229189 },
    { lat: 48.204572, lng: 17.228627 },
    { lat: 48.204563, lng: 17.228538 },
    { lat: 48.204406, lng: 17.228112 },
    { lat: 48.204046, lng: 17.227151 },
    { lat: 48.203064, lng: 17.227904 },
    { lat: 48.202618, lng: 17.228304 },
    { lat: 48.202437, lng: 17.228467 },
    { lat: 48.20202, lng: 17.228842 },
    { lat: 48.200835, lng: 17.229687 },
    { lat: 48.200726, lng: 17.229765 },
    { lat: 48.200266, lng: 17.230088 },
    { lat: 48.199995, lng: 17.230271 },
    { lat: 48.1993, lng: 17.230811 },
    { lat: 48.1984813, lng: 17.2315976 },
    { lat: 48.197834, lng: 17.230092 },
    { lat: 48.197707, lng: 17.229173 },
    { lat: 48.197501, lng: 17.229143 },
    { lat: 48.197463, lng: 17.229137 },
    { lat: 48.197342, lng: 17.22912 },
    { lat: 48.196215, lng: 17.22887 },
    { lat: 48.195123, lng: 17.22872 },
    { lat: 48.195091, lng: 17.228715 },
    { lat: 48.194879, lng: 17.228682 },
    { lat: 48.194752, lng: 17.228584 },
    { lat: 48.194817, lng: 17.226294 },
    { lat: 48.194595, lng: 17.225496 },
    { lat: 48.194261, lng: 17.225052 },
    { lat: 48.194112, lng: 17.224854 },
    { lat: 48.193364, lng: 17.224153 },
    { lat: 48.192701, lng: 17.224064 },
    { lat: 48.191747, lng: 17.224437 },
    { lat: 48.191572, lng: 17.224489 },
    { lat: 48.191338, lng: 17.224558 },
    { lat: 48.191035, lng: 17.224648 },
    { lat: 48.19077, lng: 17.224621 },
    { lat: 48.190594, lng: 17.224605 },
    { lat: 48.190404, lng: 17.224586 },
    { lat: 48.190167, lng: 17.224564 },
    { lat: 48.189893, lng: 17.224538 },
    { lat: 48.189676, lng: 17.224567 },
    { lat: 48.18945, lng: 17.224594 },
    { lat: 48.189152, lng: 17.224629 },
    { lat: 48.188942, lng: 17.224654 },
    { lat: 48.188755, lng: 17.224676 },
    { lat: 48.18841, lng: 17.224715 },
    { lat: 48.18815, lng: 17.224746 },
    { lat: 48.187869, lng: 17.22484 },
    { lat: 48.187426, lng: 17.225224 },
    { lat: 48.186876, lng: 17.225334 },
    { lat: 48.186388, lng: 17.225134 },
    { lat: 48.186278, lng: 17.224998 },
    { lat: 48.18598, lng: 17.224624 },
    { lat: 48.185944, lng: 17.224573 },
    { lat: 48.18558, lng: 17.223864 },
    { lat: 48.185437, lng: 17.223583 },
    { lat: 48.185099, lng: 17.222918 },
    { lat: 48.184907, lng: 17.222692 },
    { lat: 48.18472, lng: 17.222473 },
    { lat: 48.184484, lng: 17.222351 },
    { lat: 48.184295, lng: 17.222251 },
    { lat: 48.184203, lng: 17.222203 },
    { lat: 48.183908, lng: 17.222049 },
    { lat: 48.183682, lng: 17.221957 },
    { lat: 48.183372, lng: 17.221828 },
    { lat: 48.183093, lng: 17.221639 },
    { lat: 48.182788, lng: 17.221342 },
    { lat: 48.182514, lng: 17.221054 },
    { lat: 48.182398, lng: 17.220824 },
    { lat: 48.182223, lng: 17.22047 },
    { lat: 48.182183, lng: 17.220092 },
    { lat: 48.182177, lng: 17.219836 },
    { lat: 48.182166, lng: 17.219416 },
    { lat: 48.182182, lng: 17.219202 },
    { lat: 48.182223, lng: 17.218975 },
    { lat: 48.182303, lng: 17.218649 },
    { lat: 48.182332, lng: 17.218478 },
    { lat: 48.18218, lng: 17.218307 },
    { lat: 48.18204, lng: 17.21815 },
    { lat: 48.181832, lng: 17.217919 },
    { lat: 48.181432, lng: 17.218293 },
    { lat: 48.181277, lng: 17.218437 },
    { lat: 48.181097, lng: 17.218604 },
    { lat: 48.181051, lng: 17.218646 },
    { lat: 48.180897, lng: 17.218791 },
    { lat: 48.18067, lng: 17.219001 },
    { lat: 48.180324, lng: 17.219294 },
    { lat: 48.180125, lng: 17.219463 },
    { lat: 48.17987, lng: 17.21968 },
    { lat: 48.179724, lng: 17.219805 },
    { lat: 48.179608, lng: 17.219904 },
    { lat: 48.179542, lng: 17.21996 },
    { lat: 48.179491, lng: 17.220003 },
    { lat: 48.179191, lng: 17.22035 },
    { lat: 48.179009, lng: 17.220565 },
    { lat: 48.178985, lng: 17.220607 },
    { lat: 48.178978, lng: 17.220619 },
    { lat: 48.178931, lng: 17.220697 },
    { lat: 48.178923, lng: 17.220711 },
    { lat: 48.178911, lng: 17.220727 },
    { lat: 48.178576, lng: 17.221089 },
    { lat: 48.178009, lng: 17.221607 },
    { lat: 48.177721, lng: 17.221845 },
    { lat: 48.177262, lng: 17.222224 },
    { lat: 48.177131, lng: 17.222331 },
    { lat: 48.177124, lng: 17.222337 },
    { lat: 48.17663, lng: 17.222744 },
    { lat: 48.176624, lng: 17.222749 },
    { lat: 48.175998, lng: 17.223265 },
    { lat: 48.175598, lng: 17.223532 },
    { lat: 48.175551, lng: 17.223565 },
    { lat: 48.175296, lng: 17.223729 },
    { lat: 48.175215, lng: 17.223782 },
    { lat: 48.175161, lng: 17.223809 },
    { lat: 48.174962, lng: 17.223916 },
    { lat: 48.174915, lng: 17.223934 },
    { lat: 48.174124, lng: 17.22424 },
    { lat: 48.173341, lng: 17.22517 },
    { lat: 48.173251, lng: 17.225279 },
    { lat: 48.173004, lng: 17.225527 },
    { lat: 48.172835, lng: 17.225697 },
    { lat: 48.172419, lng: 17.226226 },
    { lat: 48.171984, lng: 17.226853 },
    { lat: 48.171691, lng: 17.227468 },
    { lat: 48.171581, lng: 17.227621 },
    { lat: 48.171188, lng: 17.228002 },
    { lat: 48.171039, lng: 17.228215 },
    { lat: 48.17101, lng: 17.228361 },
    { lat: 48.170978, lng: 17.228519 },
    { lat: 48.170979, lng: 17.228675 },
    { lat: 48.1710264, lng: 17.2286651 },
    { lat: 48.1714462, lng: 17.2286235 },
    { lat: 48.1716484, lng: 17.2286576 },
    { lat: 48.171941, lng: 17.2287581 },
    { lat: 48.1721507, lng: 17.2289212 },
    { lat: 48.1724782, lng: 17.2293935 },
    { lat: 48.1728721, lng: 17.2299468 },
    { lat: 48.1730364, lng: 17.2304459 },
    { lat: 48.1731513, lng: 17.2308468 },
    { lat: 48.1732038, lng: 17.2311085 },
    { lat: 48.1733164, lng: 17.2315612 },
    { lat: 48.1733822, lng: 17.2316492 },
    { lat: 48.1734413, lng: 17.231757 },
    { lat: 48.1739326, lng: 17.2326863 },
    { lat: 48.1740025, lng: 17.2328017 },
    { lat: 48.1741351, lng: 17.2328755 },
    { lat: 48.1742013, lng: 17.2330727 },
    { lat: 48.1742367, lng: 17.2332641 },
    { lat: 48.1744073, lng: 17.2337902 },
    { lat: 48.1744422, lng: 17.2339075 },
    { lat: 48.1745814, lng: 17.2352489 },
    { lat: 48.1745557, lng: 17.2355086 },
    { lat: 48.1744784, lng: 17.2360928 },
    { lat: 48.1744443, lng: 17.2363097 },
    { lat: 48.1743204, lng: 17.2366888 },
    { lat: 48.1740362, lng: 17.2373383 },
    { lat: 48.1739178, lng: 17.2375637 },
    { lat: 48.1735654, lng: 17.2380571 },
    { lat: 48.173235, lng: 17.238456 },
    { lat: 48.1725164, lng: 17.2388451 },
    { lat: 48.1723088, lng: 17.2388803 },
    { lat: 48.1718684, lng: 17.2388512 },
    { lat: 48.1715776, lng: 17.2390313 },
    { lat: 48.171214, lng: 17.2399292 },
    { lat: 48.1710475, lng: 17.240415 },
    { lat: 48.1708823, lng: 17.2409556 },
    { lat: 48.1706865, lng: 17.2419507 },
    { lat: 48.1705959, lng: 17.2423447 },
    { lat: 48.1705695, lng: 17.2424335 },
    { lat: 48.1704773, lng: 17.2429825 },
    { lat: 48.1704056, lng: 17.2431934 },
    { lat: 48.1704048, lng: 17.2431977 },
    { lat: 48.1702847, lng: 17.2435483 },
    { lat: 48.1701783, lng: 17.244021 },
    { lat: 48.1700142, lng: 17.2451347 },
    { lat: 48.1701047, lng: 17.2456643 },
    { lat: 48.1701622, lng: 17.2458821 },
    { lat: 48.1702508, lng: 17.2461111 },
    { lat: 48.170482, lng: 17.2464947 },
    { lat: 48.1706205, lng: 17.2466245 },
    { lat: 48.1711492, lng: 17.2470323 },
    { lat: 48.1713933, lng: 17.247289 },
    { lat: 48.1717151, lng: 17.2475746 },
    { lat: 48.1721583, lng: 17.2482061 },
    { lat: 48.1718497, lng: 17.248611 },
    { lat: 48.1714997, lng: 17.2491232 },
    { lat: 48.17131, lng: 17.2503117 },
    { lat: 48.1718329, lng: 17.2512946 },
    { lat: 48.1719765, lng: 17.2517378 },
    { lat: 48.1720229, lng: 17.2518423 },
    { lat: 48.1720291, lng: 17.2518667 },
    { lat: 48.1721356, lng: 17.2521092 },
    { lat: 48.1727259, lng: 17.2527815 },
    { lat: 48.1727844, lng: 17.2528598 },
    { lat: 48.1732277, lng: 17.2534281 },
    { lat: 48.1734735, lng: 17.253405 },
    { lat: 48.1736704, lng: 17.2533926 },
    { lat: 48.1739853, lng: 17.2533374 },
    { lat: 48.1743205, lng: 17.2526534 },
    { lat: 48.1744652, lng: 17.2523844 },
    { lat: 48.174803, lng: 17.2518072 },
    { lat: 48.174829, lng: 17.2517559 },
    { lat: 48.1751181, lng: 17.2513988 },
    { lat: 48.1754371, lng: 17.2510634 },
    { lat: 48.1756534, lng: 17.2509366 },
    { lat: 48.1759798, lng: 17.2508146 },
    { lat: 48.1760054, lng: 17.2509789 },
    { lat: 48.1760242, lng: 17.2512978 },
    { lat: 48.1763208, lng: 17.2512714 },
    { lat: 48.1766554, lng: 17.2513432 },
    { lat: 48.1769799, lng: 17.2515011 },
    { lat: 48.1774605, lng: 17.25183 },
    { lat: 48.1778851, lng: 17.2522862 },
    { lat: 48.1779432, lng: 17.252394 },
    { lat: 48.1781871, lng: 17.2528696 },
    { lat: 48.1783872, lng: 17.2534817 },
    { lat: 48.1784798, lng: 17.2537881 },
    { lat: 48.1786525, lng: 17.254482 },
    { lat: 48.1787993, lng: 17.2552971 },
    { lat: 48.1788935, lng: 17.256153 },
    { lat: 48.1788999, lng: 17.2564695 },
    { lat: 48.1789047, lng: 17.2571884 },
    { lat: 48.1788563, lng: 17.2575986 },
    { lat: 48.1787676, lng: 17.2582713 },
    { lat: 48.1786515, lng: 17.2590555 },
    { lat: 48.1784239, lng: 17.2600343 },
    { lat: 48.1782378, lng: 17.2608709 },
    { lat: 48.178084, lng: 17.2616498 },
    { lat: 48.1773269, lng: 17.2611381 },
    { lat: 48.1769181, lng: 17.2608686 },
    { lat: 48.1766458, lng: 17.260594 },
    { lat: 48.1764985, lng: 17.2609967 },
    { lat: 48.1762035, lng: 17.2614536 },
    { lat: 48.1759439, lng: 17.2618645 },
    { lat: 48.1757152, lng: 17.2622135 },
    { lat: 48.1751395, lng: 17.262798 },
    { lat: 48.1747994, lng: 17.2629347 },
    { lat: 48.1742903, lng: 17.2630923 },
    { lat: 48.1738463, lng: 17.2631945 },
    { lat: 48.1734128, lng: 17.2632834 },
    { lat: 48.1731536, lng: 17.2632931 },
    { lat: 48.1728756, lng: 17.2632246 },
    { lat: 48.1724061, lng: 17.2629751 },
    { lat: 48.1718214, lng: 17.2625748 },
    { lat: 48.1711129, lng: 17.2620949 },
    { lat: 48.1705129, lng: 17.2617871 },
    { lat: 48.1700887, lng: 17.2615394 },
    { lat: 48.1696145, lng: 17.2612214 },
    { lat: 48.1696439, lng: 17.2619533 },
    { lat: 48.1696531, lng: 17.2621259 },
    { lat: 48.1698109, lng: 17.262273 },
    { lat: 48.1701088, lng: 17.262743 },
    { lat: 48.1702085, lng: 17.2628683 },
    { lat: 48.1701958, lng: 17.2628982 },
    { lat: 48.1704281, lng: 17.2630443 },
    { lat: 48.1707483, lng: 17.263273 },
    { lat: 48.1709734, lng: 17.2633965 },
    { lat: 48.1711201, lng: 17.2634954 },
    { lat: 48.1712513, lng: 17.2635663 },
    { lat: 48.1715462, lng: 17.2636864 },
    { lat: 48.1715376, lng: 17.2637257 },
    { lat: 48.1714615, lng: 17.26377 },
    { lat: 48.1710187, lng: 17.2640603 },
    { lat: 48.1709578, lng: 17.264217 },
    { lat: 48.1711769, lng: 17.2645561 },
    { lat: 48.1714628, lng: 17.2655938 },
    { lat: 48.1715659, lng: 17.265955 },
    { lat: 48.1715689, lng: 17.266495 },
    { lat: 48.171496, lng: 17.2671315 },
    { lat: 48.1715732, lng: 17.2674267 },
    { lat: 48.1717382, lng: 17.267734 },
    { lat: 48.1718313, lng: 17.2678256 },
    { lat: 48.1720221, lng: 17.2679782 },
    { lat: 48.1725819, lng: 17.268226 },
    { lat: 48.1730939, lng: 17.2680766 },
    { lat: 48.173591, lng: 17.2677369 },
    { lat: 48.1736236, lng: 17.2679042 },
    { lat: 48.1739194, lng: 17.2682075 },
    { lat: 48.1741593, lng: 17.2684016 },
    { lat: 48.1743593, lng: 17.268572 },
    { lat: 48.1746285, lng: 17.268831 },
    { lat: 48.1746375, lng: 17.2691032 },
    { lat: 48.1747258, lng: 17.2695318 },
    { lat: 48.1747072, lng: 17.2699485 },
    { lat: 48.1745176, lng: 17.2702916 },
    { lat: 48.174328, lng: 17.270823 },
    { lat: 48.1744289, lng: 17.271102 },
    { lat: 48.1744896, lng: 17.2713184 },
    { lat: 48.1745264, lng: 17.2715832 },
    { lat: 48.1745184, lng: 17.2719238 },
    { lat: 48.1743527, lng: 17.2723368 },
    { lat: 48.1743352, lng: 17.2726687 },
    { lat: 48.1744488, lng: 17.2728833 },
    { lat: 48.1745489, lng: 17.2730281 },
    { lat: 48.1746833, lng: 17.2731998 },
    { lat: 48.1751119, lng: 17.2735289 },
    { lat: 48.1754426, lng: 17.2737789 },
    { lat: 48.1757217, lng: 17.2738152 },
    { lat: 48.1761334, lng: 17.2738402 },
    { lat: 48.176912, lng: 17.2733837 },
    { lat: 48.1774081, lng: 17.2735735 },
    { lat: 48.1777429, lng: 17.2738693 },
    { lat: 48.1778904, lng: 17.2740155 },
    { lat: 48.1780888, lng: 17.2745618 },
    { lat: 48.178258, lng: 17.2750276 },
    { lat: 48.1784929, lng: 17.2754363 },
    { lat: 48.1786568, lng: 17.27571 },
    { lat: 48.1790107, lng: 17.2762757 },
    { lat: 48.1791669, lng: 17.27669 },
    { lat: 48.1791985, lng: 17.2768602 },
    { lat: 48.179192, lng: 17.2771872 },
    { lat: 48.1791582, lng: 17.2776988 },
    { lat: 48.1791293, lng: 17.2778442 },
    { lat: 48.1788952, lng: 17.278351 },
    { lat: 48.1786678, lng: 17.2785502 },
    { lat: 48.1778329, lng: 17.279044 },
    { lat: 48.1775922, lng: 17.2790947 },
    { lat: 48.177432, lng: 17.2793862 },
    { lat: 48.1774205, lng: 17.2794859 },
    { lat: 48.1774937, lng: 17.2798575 },
    { lat: 48.1775981, lng: 17.2801192 },
    { lat: 48.1777647, lng: 17.2803994 },
    { lat: 48.1778672, lng: 17.280515 },
    { lat: 48.1779721, lng: 17.2805655 },
    { lat: 48.1781334, lng: 17.280697 },
    { lat: 48.1783694, lng: 17.2807988 },
    { lat: 48.1785419, lng: 17.2808442 },
    { lat: 48.17907, lng: 17.2810049 },
    { lat: 48.179385, lng: 17.2809676 },
    { lat: 48.179523, lng: 17.2809024 },
    { lat: 48.1798204, lng: 17.2803962 },
    { lat: 48.1799077, lng: 17.2801106 },
    { lat: 48.1800331, lng: 17.2797517 },
    { lat: 48.1801717, lng: 17.2794938 },
    { lat: 48.1802967, lng: 17.2792827 },
    { lat: 48.1804999, lng: 17.2790912 },
    { lat: 48.1806333, lng: 17.2791111 },
    { lat: 48.1809067, lng: 17.2795224 },
    { lat: 48.1810466, lng: 17.2799266 },
    { lat: 48.1811982, lng: 17.2804066 },
    { lat: 48.1812267, lng: 17.2808027 },
    { lat: 48.1811954, lng: 17.2810135 },
    { lat: 48.1808965, lng: 17.2815999 },
    { lat: 48.1807843, lng: 17.2817644 },
    { lat: 48.1806117, lng: 17.2822105 },
    { lat: 48.1805388, lng: 17.2823565 },
    { lat: 48.1804247, lng: 17.2825617 },
    { lat: 48.1802569, lng: 17.2827901 },
    { lat: 48.1799297, lng: 17.2831046 },
    { lat: 48.1795626, lng: 17.2832409 },
    { lat: 48.179011, lng: 17.2833884 },
    { lat: 48.1788718, lng: 17.2835223 },
    { lat: 48.1787192, lng: 17.2838277 },
    { lat: 48.1786249, lng: 17.2839043 },
    { lat: 48.1784462, lng: 17.2843446 },
    { lat: 48.178421, lng: 17.2844412 },
    { lat: 48.1784145, lng: 17.284497 },
    { lat: 48.178415, lng: 17.284606 },
    { lat: 48.1784495, lng: 17.2847511 },
    { lat: 48.1785191, lng: 17.2848666 },
    { lat: 48.1786439, lng: 17.2849925 },
    { lat: 48.1787602, lng: 17.2851788 },
    { lat: 48.1789127, lng: 17.285315 },
    { lat: 48.179165, lng: 17.2854238 },
    { lat: 48.1795667, lng: 17.2856903 },
    { lat: 48.1796469, lng: 17.2858085 },
    { lat: 48.1797124, lng: 17.2861307 },
    { lat: 48.1796926, lng: 17.2866362 },
    { lat: 48.179684, lng: 17.2871045 },
    { lat: 48.1796116, lng: 17.2875113 },
    { lat: 48.1795415, lng: 17.287748 },
    { lat: 48.1794214, lng: 17.2879821 },
    { lat: 48.1793006, lng: 17.2882566 },
    { lat: 48.1791303, lng: 17.2885833 },
    { lat: 48.1789897, lng: 17.2888323 },
    { lat: 48.1789069, lng: 17.2890319 },
    { lat: 48.1789011, lng: 17.2891662 },
    { lat: 48.1789352, lng: 17.2893732 },
    { lat: 48.178989, lng: 17.2895045 },
    { lat: 48.1790722, lng: 17.2896012 },
    { lat: 48.1792004, lng: 17.2896944 },
    { lat: 48.179302, lng: 17.2898661 },
    { lat: 48.1794243, lng: 17.2900397 },
    { lat: 48.1795273, lng: 17.2901621 },
    { lat: 48.1797463, lng: 17.2903975 },
    { lat: 48.1798052, lng: 17.290659 },
    { lat: 48.1798155, lng: 17.2910181 },
    { lat: 48.1797772, lng: 17.2918894 },
    { lat: 48.1797135, lng: 17.2922595 },
    { lat: 48.1797062, lng: 17.2924397 },
    { lat: 48.1798391, lng: 17.2928253 },
    { lat: 48.1799401, lng: 17.2931576 },
    { lat: 48.1801402, lng: 17.2934994 },
    { lat: 48.180192, lng: 17.2936691 },
    { lat: 48.1802018, lng: 17.293851 },
    { lat: 48.1801953, lng: 17.2940427 },
    { lat: 48.1801764, lng: 17.2944062 },
    { lat: 48.1802214, lng: 17.2946429 },
    { lat: 48.1802308, lng: 17.2947521 },
    { lat: 48.1801757, lng: 17.2950278 },
    { lat: 48.180171, lng: 17.295314 },
    { lat: 48.1802161, lng: 17.2955025 },
    { lat: 48.1802863, lng: 17.2958307 },
    { lat: 48.1803073, lng: 17.2960624 },
    { lat: 48.1802711, lng: 17.2967283 },
    { lat: 48.1801812, lng: 17.2978277 },
    { lat: 48.1801447, lng: 17.2979918 },
    { lat: 48.1800268, lng: 17.2982264 },
    { lat: 48.1799413, lng: 17.2982988 },
    { lat: 48.1797479, lng: 17.2984313 },
    { lat: 48.1795902, lng: 17.2985256 },
    { lat: 48.1793961, lng: 17.298632 },
    { lat: 48.178872, lng: 17.2986647 },
    { lat: 48.1787596, lng: 17.2986568 },
    { lat: 48.1786312, lng: 17.2986277 },
    { lat: 48.1779793, lng: 17.2984225 },
    { lat: 48.1778938, lng: 17.298393 },
    { lat: 48.1777618, lng: 17.2984302 },
    { lat: 48.177213, lng: 17.2988086 },
    { lat: 48.1772009, lng: 17.2988476 },
    { lat: 48.1771836, lng: 17.2988432 },
    { lat: 48.177132, lng: 17.2990866 },
    { lat: 48.1774168, lng: 17.2992438 },
    { lat: 48.1776682, lng: 17.2994244 },
    { lat: 48.1778985, lng: 17.2995746 },
    { lat: 48.1779948, lng: 17.299682 },
    { lat: 48.178114, lng: 17.2998949 },
    { lat: 48.1783631, lng: 17.3002963 },
    { lat: 48.1784965, lng: 17.3007107 },
    { lat: 48.178592, lng: 17.3012489 },
    { lat: 48.1786183, lng: 17.3016639 },
    { lat: 48.1786311, lng: 17.301994 },
    { lat: 48.1786061, lng: 17.302316 },
    { lat: 48.1785762, lng: 17.3025654 },
    { lat: 48.1787131, lng: 17.3025848 },
    { lat: 48.178792, lng: 17.3024634 },
    { lat: 48.178926, lng: 17.3023212 },
    { lat: 48.1790618, lng: 17.3021348 },
    { lat: 48.179172, lng: 17.3019394 },
    { lat: 48.1792782, lng: 17.301786 },
    { lat: 48.1793574, lng: 17.3015818 },
    { lat: 48.1795696, lng: 17.3011188 },
    { lat: 48.1797471, lng: 17.300829 },
    { lat: 48.1799618, lng: 17.3006437 },
    { lat: 48.1802144, lng: 17.3005345 },
    { lat: 48.1806363, lng: 17.3004231 },
    { lat: 48.1808156, lng: 17.3002464 },
    { lat: 48.1809345, lng: 17.3000642 },
    { lat: 48.1812762, lng: 17.2996793 },
    { lat: 48.1815412, lng: 17.2993968 },
    { lat: 48.1816154, lng: 17.2993242 },
    { lat: 48.1818803, lng: 17.2989405 },
    { lat: 48.1820609, lng: 17.2986131 },
    { lat: 48.1821636, lng: 17.2981333 },
    { lat: 48.182185, lng: 17.2977746 },
    { lat: 48.1821762, lng: 17.2973334 },
    { lat: 48.1820684, lng: 17.2967269 },
    { lat: 48.1820134, lng: 17.2962392 },
    { lat: 48.1819452, lng: 17.295599 },
    { lat: 48.1819166, lng: 17.2948752 },
    { lat: 48.181882, lng: 17.2943702 },
    { lat: 48.181871, lng: 17.2940855 },
    { lat: 48.1818275, lng: 17.2935801 },
    { lat: 48.1818353, lng: 17.2932023 },
    { lat: 48.1818659, lng: 17.2929656 },
    { lat: 48.1819229, lng: 17.292648 },
    { lat: 48.1819565, lng: 17.2925078 },
    { lat: 48.1821719, lng: 17.2920579 },
    { lat: 48.1822865, lng: 17.2918273 },
    { lat: 48.1824885, lng: 17.2915001 },
    { lat: 48.1828338, lng: 17.2911526 },
    { lat: 48.1830221, lng: 17.2910613 },
    { lat: 48.1832928, lng: 17.2908668 },
    { lat: 48.1834778, lng: 17.2907438 },
    { lat: 48.1836918, lng: 17.2907858 },
    { lat: 48.1839101, lng: 17.2911487 },
    { lat: 48.1842559, lng: 17.2914591 },
    { lat: 48.1844686, lng: 17.2916851 },
    { lat: 48.1845205, lng: 17.2915812 },
    { lat: 48.1847259, lng: 17.2915609 },
    { lat: 48.1850138, lng: 17.2913882 },
    { lat: 48.1850652, lng: 17.2912937 },
    { lat: 48.1852387, lng: 17.2910307 },
    { lat: 48.185337, lng: 17.2908936 },
    { lat: 48.1854415, lng: 17.2907844 },
    { lat: 48.1855021, lng: 17.2907595 },
    { lat: 48.1855668, lng: 17.2906957 },
    { lat: 48.1856572, lng: 17.2906824 },
    { lat: 48.1859006, lng: 17.2905863 },
    { lat: 48.186095, lng: 17.2904689 },
    { lat: 48.1861074, lng: 17.2904679 },
    { lat: 48.1861224, lng: 17.2904894 },
    { lat: 48.186188, lng: 17.2905252 },
    { lat: 48.1862905, lng: 17.2902145 },
    { lat: 48.1864146, lng: 17.2899536 },
    { lat: 48.1865684, lng: 17.2895804 },
    { lat: 48.1866737, lng: 17.2890402 },
    { lat: 48.1866129, lng: 17.288649 },
    { lat: 48.1865644, lng: 17.2885319 },
    { lat: 48.1864087, lng: 17.2882762 },
    { lat: 48.186063, lng: 17.2880217 },
    { lat: 48.1857902, lng: 17.2879269 },
    { lat: 48.1856585, lng: 17.2878509 },
    { lat: 48.1854148, lng: 17.2877865 },
    { lat: 48.1851444, lng: 17.287694 },
    { lat: 48.1848514, lng: 17.2875701 },
    { lat: 48.1846532, lng: 17.2873578 },
    { lat: 48.1843899, lng: 17.2869273 },
    { lat: 48.1846268, lng: 17.2867021 },
    { lat: 48.1853106, lng: 17.2860433 },
    { lat: 48.1854967, lng: 17.285868 },
    { lat: 48.1856824, lng: 17.2856851 },
    { lat: 48.1859338, lng: 17.2854552 },
    { lat: 48.1863383, lng: 17.2851513 },
    { lat: 48.1866217, lng: 17.2849935 },
    { lat: 48.1868304, lng: 17.2848715 },
    { lat: 48.1869977, lng: 17.2848559 },
    { lat: 48.187167, lng: 17.2849052 },
    { lat: 48.1874795, lng: 17.2846934 },
    { lat: 48.18768, lng: 17.2845132 },
    { lat: 48.1879946, lng: 17.2841634 },
    { lat: 48.1881672, lng: 17.2840248 },
    { lat: 48.1882609, lng: 17.283988 },
    { lat: 48.1884225, lng: 17.2838703 },
    { lat: 48.1888464, lng: 17.2835347 },
    { lat: 48.1890479, lng: 17.2833528 },
    { lat: 48.1891082, lng: 17.2832915 },
    { lat: 48.1893088, lng: 17.283114 },
    { lat: 48.1895444, lng: 17.2829346 },
    { lat: 48.1896786, lng: 17.2828124 },
    { lat: 48.1899016, lng: 17.2825987 },
    { lat: 48.1901667, lng: 17.2823372 },
    { lat: 48.1905053, lng: 17.2819738 },
    { lat: 48.1909928, lng: 17.2815045 },
    { lat: 48.1911277, lng: 17.281274 },
    { lat: 48.1913851, lng: 17.2809132 },
    { lat: 48.1915077, lng: 17.2807176 },
    { lat: 48.1919422, lng: 17.2801659 },
    { lat: 48.1921535, lng: 17.2798253 },
    { lat: 48.1923398, lng: 17.2796195 },
    { lat: 48.1927839, lng: 17.2792799 },
    { lat: 48.1929551, lng: 17.2791363 },
    { lat: 48.1929913, lng: 17.2790346 },
    { lat: 48.1934488, lng: 17.2786754 },
    { lat: 48.1936103, lng: 17.2786244 },
    { lat: 48.1937024, lng: 17.2785518 },
    { lat: 48.1940064, lng: 17.2783122 },
    { lat: 48.1944859, lng: 17.278029 },
    { lat: 48.1949916, lng: 17.2779034 },
    { lat: 48.195364, lng: 17.2779665 },
    { lat: 48.1958088, lng: 17.2779968 },
    { lat: 48.1960087, lng: 17.2780467 },
    { lat: 48.196138, lng: 17.2780029 },
    { lat: 48.1964054, lng: 17.2780326 },
    { lat: 48.1968765, lng: 17.2780667 },
    { lat: 48.1970374, lng: 17.2780749 },
    { lat: 48.1984752, lng: 17.2781726 },
    { lat: 48.1987075, lng: 17.27803 },
    { lat: 48.1989205, lng: 17.2779047 },
    { lat: 48.1994683, lng: 17.2772709 },
    { lat: 48.1995902, lng: 17.2771514 },
    { lat: 48.1997199, lng: 17.2769075 },
    { lat: 48.19994, lng: 17.276514 },
    { lat: 48.1999915, lng: 17.2764049 },
    { lat: 48.2008658, lng: 17.2747342 },
    { lat: 48.2012852, lng: 17.2737848 },
    { lat: 48.2016624, lng: 17.2729135 },
    { lat: 48.2019302, lng: 17.2720965 },
    { lat: 48.2020888, lng: 17.2720187 },
    { lat: 48.2021901, lng: 17.2719607 },
    { lat: 48.2024123, lng: 17.2712914 },
    { lat: 48.20272, lng: 17.2703958 },
    { lat: 48.2028904, lng: 17.2699844 },
    { lat: 48.2031224, lng: 17.2692071 },
    { lat: 48.2034444, lng: 17.2681522 },
    { lat: 48.2039119, lng: 17.2670071 },
    { lat: 48.204505, lng: 17.2655797 },
    { lat: 48.2055779, lng: 17.2632605 },
    { lat: 48.2060673, lng: 17.2622253 },
    { lat: 48.2065057, lng: 17.2613092 },
    { lat: 48.2068308, lng: 17.2606069 },
    { lat: 48.2072222, lng: 17.2599513 },
    { lat: 48.2074607, lng: 17.2595634 },
    { lat: 48.2076611, lng: 17.2592624 },
    { lat: 48.2080718, lng: 17.2585743 },
    { lat: 48.2083909, lng: 17.2579538 },
    { lat: 48.208761, lng: 17.2571831 },
    { lat: 48.2088877, lng: 17.2566056 },
    { lat: 48.2089002, lng: 17.2565733 },
    { lat: 48.2085074, lng: 17.2562912 },
    { lat: 48.2082106, lng: 17.2558857 },
    { lat: 48.2080489, lng: 17.2556799 },
    { lat: 48.2074131, lng: 17.2553773 },
    { lat: 48.2073367, lng: 17.2553499 },
    { lat: 48.2068577, lng: 17.2550087 },
    { lat: 48.206811, lng: 17.2549684 },
    { lat: 48.2061343, lng: 17.2544598 },
    { lat: 48.2059957, lng: 17.2543453 },
    { lat: 48.2057134, lng: 17.2540762 },
    { lat: 48.2054161, lng: 17.2538018 },
    { lat: 48.2048985, lng: 17.2531362 },
    { lat: 48.2046449, lng: 17.2526677 },
    { lat: 48.2044988, lng: 17.2523913 },
    { lat: 48.2044462, lng: 17.2522782 },
    { lat: 48.2039315, lng: 17.2511135 },
    { lat: 48.2041525, lng: 17.2506487 },
    { lat: 48.2046851, lng: 17.2497574 },
    { lat: 48.2046072, lng: 17.2495696 },
    { lat: 48.2041099, lng: 17.2482765 },
    { lat: 48.2040888, lng: 17.2482154 },
    { lat: 48.2039038, lng: 17.2476067 },
    { lat: 48.203752, lng: 17.2470995 },
    { lat: 48.2035795, lng: 17.2465531 },
    { lat: 48.2035049, lng: 17.2462988 },
    { lat: 48.2029788, lng: 17.2444532 },
    { lat: 48.2026754, lng: 17.2433915 },
    { lat: 48.2019418, lng: 17.2408906 },
    { lat: 48.2012207, lng: 17.2382563 },
    { lat: 48.2019022, lng: 17.2369211 },
    { lat: 48.2023712, lng: 17.2359572 },
    { lat: 48.2028806, lng: 17.2351071 },
    { lat: 48.2029884, lng: 17.2348266 },
    { lat: 48.2030521, lng: 17.2346471 },
    { lat: 48.2032177, lng: 17.2342161 },
    { lat: 48.2032788, lng: 17.2340658 },
    { lat: 48.2036889, lng: 17.2335754 },
    { lat: 48.2039377, lng: 17.233272 },
    { lat: 48.204226, lng: 17.23293 },
  ],
  Kalinkovo: [
    { lat: 48.0508394, lng: 17.2782401 },
    { lat: 48.05121, lng: 17.2780034 },
    { lat: 48.0518112, lng: 17.2776332 },
    { lat: 48.0520916, lng: 17.2774316 },
    { lat: 48.0524331, lng: 17.2771991 },
    { lat: 48.0527844, lng: 17.2769441 },
    { lat: 48.0530362, lng: 17.2767713 },
    { lat: 48.0535548, lng: 17.2763484 },
    { lat: 48.0540363, lng: 17.275808 },
    { lat: 48.0542589, lng: 17.2755712 },
    { lat: 48.054483, lng: 17.2753391 },
    { lat: 48.0549889, lng: 17.2748439 },
    { lat: 48.0553985, lng: 17.2744632 },
    { lat: 48.0554769, lng: 17.2743851 },
    { lat: 48.0558266, lng: 17.2740039 },
    { lat: 48.0568093, lng: 17.2730346 },
    { lat: 48.057122, lng: 17.2727245 },
    { lat: 48.0576735, lng: 17.2722023 },
    { lat: 48.0580068, lng: 17.2718791 },
    { lat: 48.058074, lng: 17.2718187 },
    { lat: 48.0580643, lng: 17.2717931 },
    { lat: 48.0581323, lng: 17.2717274 },
    { lat: 48.0584122, lng: 17.2712817 },
    { lat: 48.0585013, lng: 17.2711269 },
    { lat: 48.058836, lng: 17.270571 },
    { lat: 48.0590082, lng: 17.2702893 },
    { lat: 48.0594949, lng: 17.2694547 },
    { lat: 48.0597792, lng: 17.2689737 },
    { lat: 48.0600389, lng: 17.2685468 },
    { lat: 48.0604304, lng: 17.267877 },
    { lat: 48.0606166, lng: 17.2675689 },
    { lat: 48.0608967, lng: 17.2670927 },
    { lat: 48.0610444, lng: 17.2668508 },
    { lat: 48.0613412, lng: 17.2663199 },
    { lat: 48.0616265, lng: 17.2657856 },
    { lat: 48.0619152, lng: 17.2652525 },
    { lat: 48.0622979, lng: 17.2646715 },
    { lat: 48.0628763, lng: 17.263873 },
    { lat: 48.0637625, lng: 17.2625073 },
    { lat: 48.064311, lng: 17.2616508 },
    { lat: 48.0654331, lng: 17.2599929 },
    { lat: 48.0658579, lng: 17.2594168 },
    { lat: 48.0661444, lng: 17.2590195 },
    { lat: 48.066546, lng: 17.258435 },
    { lat: 48.0672181, lng: 17.2574656 },
    { lat: 48.0672894, lng: 17.2573834 },
    { lat: 48.0679662, lng: 17.2565719 },
    { lat: 48.0683056, lng: 17.2557992 },
    { lat: 48.0683902, lng: 17.2556113 },
    { lat: 48.0685565, lng: 17.2547498 },
    { lat: 48.0687917, lng: 17.2537012 },
    { lat: 48.068936, lng: 17.2529846 },
    { lat: 48.0688467, lng: 17.2526285 },
    { lat: 48.0686837, lng: 17.2519963 },
    { lat: 48.0685839, lng: 17.2516184 },
    { lat: 48.0684258, lng: 17.2509921 },
    { lat: 48.0682295, lng: 17.2502324 },
    { lat: 48.0680444, lng: 17.249643 },
    { lat: 48.0679432, lng: 17.2493109 },
    { lat: 48.0676333, lng: 17.2482553 },
    { lat: 48.0673856, lng: 17.2470483 },
    { lat: 48.0673726, lng: 17.2464108 },
    { lat: 48.0677507, lng: 17.2462727 },
    { lat: 48.0679869, lng: 17.2462906 },
    { lat: 48.0681852, lng: 17.2462824 },
    { lat: 48.0684348, lng: 17.2464003 },
    { lat: 48.0686693, lng: 17.2465458 },
    { lat: 48.069024, lng: 17.2469187 },
    { lat: 48.069318, lng: 17.2471085 },
    { lat: 48.069601, lng: 17.2471516 },
    { lat: 48.0700441, lng: 17.2472905 },
    { lat: 48.0703984, lng: 17.2472674 },
    { lat: 48.070677, lng: 17.2471755 },
    { lat: 48.0709566, lng: 17.2470143 },
    { lat: 48.0714858, lng: 17.2469374 },
    { lat: 48.0720319, lng: 17.2469111 },
    { lat: 48.0725951, lng: 17.2469371 },
    { lat: 48.0731362, lng: 17.2470379 },
    { lat: 48.0734026, lng: 17.2470346 },
    { lat: 48.0735799, lng: 17.2468809 },
    { lat: 48.0737438, lng: 17.2465784 },
    { lat: 48.074043, lng: 17.2459483 },
    { lat: 48.0743923, lng: 17.2448512 },
    { lat: 48.0744443, lng: 17.2446817 },
    { lat: 48.0744836, lng: 17.2443901 },
    { lat: 48.0744958, lng: 17.2442687 },
    { lat: 48.0745523, lng: 17.2438456 },
    { lat: 48.0746655, lng: 17.2433415 },
    { lat: 48.0746912, lng: 17.242355 },
    { lat: 48.0746857, lng: 17.2422547 },
    { lat: 48.0746738, lng: 17.2416201 },
    { lat: 48.0746435, lng: 17.2410649 },
    { lat: 48.0746083, lng: 17.2406394 },
    { lat: 48.0745801, lng: 17.2404025 },
    { lat: 48.0745026, lng: 17.2399177 },
    { lat: 48.0744561, lng: 17.2397122 },
    { lat: 48.0743601, lng: 17.2392561 },
    { lat: 48.0742546, lng: 17.2388428 },
    { lat: 48.074109, lng: 17.2384734 },
    { lat: 48.0739179, lng: 17.2380475 },
    { lat: 48.0737149, lng: 17.2378501 },
    { lat: 48.0736285, lng: 17.2377914 },
    { lat: 48.07344, lng: 17.237794 },
    { lat: 48.073292, lng: 17.2378448 },
    { lat: 48.0730773, lng: 17.2379281 },
    { lat: 48.0729385, lng: 17.2379646 },
    { lat: 48.0726284, lng: 17.2380226 },
    { lat: 48.0724668, lng: 17.2380567 },
    { lat: 48.0723347, lng: 17.2380756 },
    { lat: 48.0721519, lng: 17.2380614 },
    { lat: 48.0719429, lng: 17.2380107 },
    { lat: 48.0717355, lng: 17.2379321 },
    { lat: 48.0715996, lng: 17.2376058 },
    { lat: 48.0715111, lng: 17.2369583 },
    { lat: 48.0714334, lng: 17.2362517 },
    { lat: 48.0713019, lng: 17.2357715 },
    { lat: 48.0711615, lng: 17.2355454 },
    { lat: 48.0709561, lng: 17.2351942 },
    { lat: 48.0708048, lng: 17.2350102 },
    { lat: 48.0707598, lng: 17.2349445 },
    { lat: 48.0703695, lng: 17.2344125 },
    { lat: 48.0701674, lng: 17.2341425 },
    { lat: 48.0697881, lng: 17.2335568 },
    { lat: 48.069673, lng: 17.2333885 },
    { lat: 48.069436, lng: 17.2331389 },
    { lat: 48.0692115, lng: 17.2329413 },
    { lat: 48.0688939, lng: 17.232804 },
    { lat: 48.0687621, lng: 17.2328084 },
    { lat: 48.0686453, lng: 17.2328874 },
    { lat: 48.0685547, lng: 17.2329682 },
    { lat: 48.0684085, lng: 17.2331805 },
    { lat: 48.0682608, lng: 17.2336415 },
    { lat: 48.0682119, lng: 17.2339926 },
    { lat: 48.0682135, lng: 17.2343708 },
    { lat: 48.0682913, lng: 17.2350789 },
    { lat: 48.0683094, lng: 17.2354167 },
    { lat: 48.0683411, lng: 17.2358916 },
    { lat: 48.0683551, lng: 17.2360337 },
    { lat: 48.0683542, lng: 17.2363928 },
    { lat: 48.0682079, lng: 17.2369412 },
    { lat: 48.0679573, lng: 17.2373679 },
    { lat: 48.0678036, lng: 17.2374177 },
    { lat: 48.0676192, lng: 17.2373971 },
    { lat: 48.0674403, lng: 17.2372897 },
    { lat: 48.0673092, lng: 17.2371541 },
    { lat: 48.0671863, lng: 17.2369204 },
    { lat: 48.0669219, lng: 17.2364803 },
    { lat: 48.066794, lng: 17.2362565 },
    { lat: 48.066564, lng: 17.2358237 },
    { lat: 48.0664588, lng: 17.2355007 },
    { lat: 48.0663496, lng: 17.2352187 },
    { lat: 48.0662233, lng: 17.2344276 },
    { lat: 48.0662242, lng: 17.2338654 },
    { lat: 48.0662426, lng: 17.233769 },
    { lat: 48.0663003, lng: 17.2335337 },
    { lat: 48.0664614, lng: 17.233171 },
    { lat: 48.0666352, lng: 17.2330685 },
    { lat: 48.0669607, lng: 17.2328675 },
    { lat: 48.0670447, lng: 17.2328229 },
    { lat: 48.0672927, lng: 17.2327136 },
    { lat: 48.0675282, lng: 17.2325858 },
    { lat: 48.0678443, lng: 17.2322776 },
    { lat: 48.0681291, lng: 17.2320422 },
    { lat: 48.0683505, lng: 17.2315356 },
    { lat: 48.0684688, lng: 17.2309213 },
    { lat: 48.0684546, lng: 17.2306589 },
    { lat: 48.0683987, lng: 17.2302626 },
    { lat: 48.0684756, lng: 17.2298239 },
    { lat: 48.0685889, lng: 17.2292879 },
    { lat: 48.0686607, lng: 17.2289257 },
    { lat: 48.0687536, lng: 17.22844 },
    { lat: 48.0687664, lng: 17.2282433 },
    { lat: 48.0687759, lng: 17.2279631 },
    { lat: 48.0687624, lng: 17.2274738 },
    { lat: 48.068747, lng: 17.2270572 },
    { lat: 48.0687277, lng: 17.2266842 },
    { lat: 48.0687275, lng: 17.2260732 },
    { lat: 48.0687421, lng: 17.225599 },
    { lat: 48.0687444, lng: 17.2250255 },
    { lat: 48.0687381, lng: 17.2244753 },
    { lat: 48.0687449, lng: 17.2244423 },
    { lat: 48.0688431, lng: 17.2237829 },
    { lat: 48.0688875, lng: 17.2236179 },
    { lat: 48.06905, lng: 17.2230425 },
    { lat: 48.0690919, lng: 17.2225373 },
    { lat: 48.0690874, lng: 17.2222321 },
    { lat: 48.0689565, lng: 17.2218638 },
    { lat: 48.0686409, lng: 17.2216724 },
    { lat: 48.0683643, lng: 17.2214887 },
    { lat: 48.0682063, lng: 17.22139 },
    { lat: 48.0680921, lng: 17.2212544 },
    { lat: 48.0677781, lng: 17.2208671 },
    { lat: 48.0675959, lng: 17.2206264 },
    { lat: 48.0672978, lng: 17.2203413 },
    { lat: 48.0671814, lng: 17.2202221 },
    { lat: 48.0670622, lng: 17.2198927 },
    { lat: 48.0669804, lng: 17.2197514 },
    { lat: 48.0668684, lng: 17.219496 },
    { lat: 48.0668073, lng: 17.2191738 },
    { lat: 48.0668902, lng: 17.2187932 },
    { lat: 48.0670636, lng: 17.218598 },
    { lat: 48.0672795, lng: 17.2183475 },
    { lat: 48.067449, lng: 17.2181275 },
    { lat: 48.0672155, lng: 17.2177628 },
    { lat: 48.0671479, lng: 17.2176297 },
    { lat: 48.0670356, lng: 17.2175057 },
    { lat: 48.0668574, lng: 17.2172559 },
    { lat: 48.0667304, lng: 17.2170641 },
    { lat: 48.0666422, lng: 17.2168438 },
    { lat: 48.066516, lng: 17.2166124 },
    { lat: 48.0663384, lng: 17.2163725 },
    { lat: 48.0661244, lng: 17.2160622 },
    { lat: 48.0659355, lng: 17.2157036 },
    { lat: 48.0657994, lng: 17.2155789 },
    { lat: 48.065502, lng: 17.2153898 },
    { lat: 48.0653864, lng: 17.2152824 },
    { lat: 48.0652646, lng: 17.2152684 },
    { lat: 48.0650115, lng: 17.2153189 },
    { lat: 48.0648546, lng: 17.2153372 },
    { lat: 48.0647207, lng: 17.215396 },
    { lat: 48.0645727, lng: 17.2155309 },
    { lat: 48.0644446, lng: 17.215609 },
    { lat: 48.0638548, lng: 17.2161142 },
    { lat: 48.0634849, lng: 17.2164785 },
    { lat: 48.0632362, lng: 17.2167288 },
    { lat: 48.0633181, lng: 17.2162143 },
    { lat: 48.0635321, lng: 17.2154274 },
    { lat: 48.0635903, lng: 17.2150487 },
    { lat: 48.0636677, lng: 17.2148037 },
    { lat: 48.0638764, lng: 17.2144634 },
    { lat: 48.0640312, lng: 17.2140248 },
    { lat: 48.064055, lng: 17.2138233 },
    { lat: 48.0640385, lng: 17.213578 },
    { lat: 48.0639796, lng: 17.213323 },
    { lat: 48.0638185, lng: 17.2130259 },
    { lat: 48.0637444, lng: 17.2129157 },
    { lat: 48.0632845, lng: 17.2123903 },
    { lat: 48.0631668, lng: 17.2120665 },
    { lat: 48.0630395, lng: 17.2116684 },
    { lat: 48.0629944, lng: 17.2115006 },
    { lat: 48.0629745, lng: 17.2113568 },
    { lat: 48.0630072, lng: 17.2111026 },
    { lat: 48.0630669, lng: 17.2109639 },
    { lat: 48.0631112, lng: 17.2109008 },
    { lat: 48.0632371, lng: 17.2107902 },
    { lat: 48.0632851, lng: 17.2107308 },
    { lat: 48.0633632, lng: 17.2105641 },
    { lat: 48.0634688, lng: 17.210202 },
    { lat: 48.0635347, lng: 17.2099377 },
    { lat: 48.063553, lng: 17.2097226 },
    { lat: 48.0635287, lng: 17.2092249 },
    { lat: 48.0635114, lng: 17.2090849 },
    { lat: 48.0634352, lng: 17.2087062 },
    { lat: 48.0633777, lng: 17.2085066 },
    { lat: 48.0633518, lng: 17.2084397 },
    { lat: 48.0630366, lng: 17.2081371 },
    { lat: 48.0628591, lng: 17.2079335 },
    { lat: 48.0626674, lng: 17.2076511 },
    { lat: 48.0626653, lng: 17.2075199 },
    { lat: 48.0627325, lng: 17.2072916 },
    { lat: 48.0627475, lng: 17.2072118 },
    { lat: 48.0627393, lng: 17.2071398 },
    { lat: 48.0627212, lng: 17.2070895 },
    { lat: 48.0626724, lng: 17.2070528 },
    { lat: 48.0625584, lng: 17.2070546 },
    { lat: 48.0624368, lng: 17.2070931 },
    { lat: 48.0620739, lng: 17.2073588 },
    { lat: 48.0618335, lng: 17.2075826 },
    { lat: 48.0616629, lng: 17.2077327 },
    { lat: 48.0612209, lng: 17.2080676 },
    { lat: 48.0610441, lng: 17.2081588 },
    { lat: 48.0606061, lng: 17.2083153 },
    { lat: 48.0604054, lng: 17.2084411 },
    { lat: 48.0599475, lng: 17.2088061 },
    { lat: 48.0595573, lng: 17.2091525 },
    { lat: 48.0590705, lng: 17.2097611 },
    { lat: 48.0585458, lng: 17.2097366 },
    { lat: 48.0577486, lng: 17.2097266 },
    { lat: 48.0581423, lng: 17.2094302 },
    { lat: 48.0588856, lng: 17.2088576 },
    { lat: 48.0598932, lng: 17.2080634 },
    { lat: 48.0600201, lng: 17.2076477 },
    { lat: 48.0606289, lng: 17.2055908 },
    { lat: 48.0612006, lng: 17.2036542 },
    { lat: 48.0615436, lng: 17.2025032 },
    { lat: 48.061663, lng: 17.202084 },
    { lat: 48.062303, lng: 17.199919 },
    { lat: 48.063877, lng: 17.194483 },
    { lat: 48.063892, lng: 17.194428 },
    { lat: 48.062333, lng: 17.193335 },
    { lat: 48.062237, lng: 17.193267 },
    { lat: 48.062204, lng: 17.193244 },
    { lat: 48.062143, lng: 17.1932 },
    { lat: 48.062093, lng: 17.193168 },
    { lat: 48.058188, lng: 17.190501 },
    { lat: 48.055515, lng: 17.188591 },
    { lat: 48.055463, lng: 17.188555 },
    { lat: 48.053795, lng: 17.187371 },
    { lat: 48.053715, lng: 17.187601 },
    { lat: 48.053647, lng: 17.187798 },
    { lat: 48.053558, lng: 17.18808 },
    { lat: 48.053478, lng: 17.188339 },
    { lat: 48.05335, lng: 17.188801 },
    { lat: 48.053089, lng: 17.189819 },
    { lat: 48.052889, lng: 17.190567 },
    { lat: 48.052784, lng: 17.190942 },
    { lat: 48.052674, lng: 17.191292 },
    { lat: 48.052562, lng: 17.191619 },
    { lat: 48.052454, lng: 17.19192 },
    { lat: 48.052363, lng: 17.192144 },
    { lat: 48.052276, lng: 17.192356 },
    { lat: 48.052183, lng: 17.192569 },
    { lat: 48.052067, lng: 17.192816 },
    { lat: 48.05196, lng: 17.193034 },
    { lat: 48.051835, lng: 17.193281 },
    { lat: 48.051668, lng: 17.193577 },
    { lat: 48.051475, lng: 17.193898 },
    { lat: 48.051296, lng: 17.194193 },
    { lat: 48.050816, lng: 17.194992 },
    { lat: 48.050242, lng: 17.196005 },
    { lat: 48.05001, lng: 17.196428 },
    { lat: 48.049808, lng: 17.196811 },
    { lat: 48.049574, lng: 17.197262 },
    { lat: 48.048961, lng: 17.198477 },
    { lat: 48.0482, lng: 17.200031 },
    { lat: 48.047902, lng: 17.200658 },
    { lat: 48.047356, lng: 17.201823 },
    { lat: 48.046763, lng: 17.203132 },
    { lat: 48.046546, lng: 17.203639 },
    { lat: 48.046279, lng: 17.204265 },
    { lat: 48.045912, lng: 17.205178 },
    { lat: 48.045634, lng: 17.2059 },
    { lat: 48.045469, lng: 17.206338 },
    { lat: 48.045263, lng: 17.206908 },
    { lat: 48.045137, lng: 17.207284 },
    { lat: 48.044985, lng: 17.20771 },
    { lat: 48.04475, lng: 17.208364 },
    { lat: 48.044521, lng: 17.20901 },
    { lat: 48.044287, lng: 17.209711 },
    { lat: 48.044132, lng: 17.210197 },
    { lat: 48.043913, lng: 17.210908 },
    { lat: 48.043707, lng: 17.211632 },
    { lat: 48.043511, lng: 17.212364 },
    { lat: 48.043228, lng: 17.213491 },
    { lat: 48.043116, lng: 17.213987 },
    { lat: 48.042992, lng: 17.214483 },
    { lat: 48.042871, lng: 17.214933 },
    { lat: 48.042742, lng: 17.215374 },
    { lat: 48.042622, lng: 17.215765 },
    { lat: 48.042386, lng: 17.216445 },
    { lat: 48.042277, lng: 17.216758 },
    { lat: 48.041594, lng: 17.218605 },
    { lat: 48.041078, lng: 17.219927 },
    { lat: 48.040795, lng: 17.220621 },
    { lat: 48.040497, lng: 17.221333 },
    { lat: 48.040402, lng: 17.221552 },
    { lat: 48.040304, lng: 17.221765 },
    { lat: 48.040065, lng: 17.222245 },
    { lat: 48.0399356, lng: 17.2224871 },
    { lat: 48.0400768, lng: 17.2234305 },
    { lat: 48.0402759, lng: 17.2248521 },
    { lat: 48.0410721, lng: 17.2247295 },
    { lat: 48.0413727, lng: 17.2246805 },
    { lat: 48.0420592, lng: 17.2245741 },
    { lat: 48.042911, lng: 17.2244379 },
    { lat: 48.043776, lng: 17.2242975 },
    { lat: 48.0444594, lng: 17.2241957 },
    { lat: 48.0447444, lng: 17.2241321 },
    { lat: 48.0451377, lng: 17.2239489 },
    { lat: 48.0453668, lng: 17.2237944 },
    { lat: 48.0455956, lng: 17.2237014 },
    { lat: 48.0466508, lng: 17.2230943 },
    { lat: 48.0468336, lng: 17.222972 },
    { lat: 48.0471505, lng: 17.2227789 },
    { lat: 48.0474636, lng: 17.2226287 },
    { lat: 48.0475921, lng: 17.2225919 },
    { lat: 48.0478873, lng: 17.2227139 },
    { lat: 48.048106, lng: 17.2228095 },
    { lat: 48.0483796, lng: 17.2229137 },
    { lat: 48.0483887, lng: 17.2229637 },
    { lat: 48.0485882, lng: 17.2235649 },
    { lat: 48.0487481, lng: 17.2240802 },
    { lat: 48.0488113, lng: 17.2242654 },
    { lat: 48.0488151, lng: 17.2248452 },
    { lat: 48.0488205, lng: 17.2253483 },
    { lat: 48.0488309, lng: 17.2260098 },
    { lat: 48.0488412, lng: 17.2270579 },
    { lat: 48.048848, lng: 17.227548 },
    { lat: 48.0489206, lng: 17.2285322 },
    { lat: 48.048977, lng: 17.2289365 },
    { lat: 48.0490449, lng: 17.2292761 },
    { lat: 48.049178, lng: 17.229814 },
    { lat: 48.0493329, lng: 17.2301371 },
    { lat: 48.0494439, lng: 17.2303271 },
    { lat: 48.0497007, lng: 17.230534 },
    { lat: 48.0500551, lng: 17.2307146 },
    { lat: 48.0503768, lng: 17.230778 },
    { lat: 48.0502868, lng: 17.2314442 },
    { lat: 48.0502598, lng: 17.2318502 },
    { lat: 48.0502485, lng: 17.2325275 },
    { lat: 48.0502143, lng: 17.2329285 },
    { lat: 48.0501321, lng: 17.2335237 },
    { lat: 48.0500833, lng: 17.2339084 },
    { lat: 48.0500523, lng: 17.2342223 },
    { lat: 48.0500348, lng: 17.2346705 },
    { lat: 48.0500321, lng: 17.2348839 },
    { lat: 48.0500333, lng: 17.2355258 },
    { lat: 48.0500247, lng: 17.2358548 },
    { lat: 48.0500094, lng: 17.2361831 },
    { lat: 48.04992, lng: 17.2376345 },
    { lat: 48.0499096, lng: 17.2377834 },
    { lat: 48.0498765, lng: 17.2380159 },
    { lat: 48.0498625, lng: 17.2381602 },
    { lat: 48.0498448, lng: 17.2384889 },
    { lat: 48.0498454, lng: 17.2385478 },
    { lat: 48.0498374, lng: 17.2385642 },
    { lat: 48.0498188, lng: 17.2393336 },
    { lat: 48.0498052, lng: 17.2396372 },
    { lat: 48.0497975, lng: 17.2399112 },
    { lat: 48.0497861, lng: 17.2400949 },
    { lat: 48.049751, lng: 17.2405517 },
    { lat: 48.0497372, lng: 17.2408182 },
    { lat: 48.0496832, lng: 17.2413966 },
    { lat: 48.0496111, lng: 17.2418563 },
    { lat: 48.0495344, lng: 17.2424331 },
    { lat: 48.049423, lng: 17.2429651 },
    { lat: 48.0494124, lng: 17.2429575 },
    { lat: 48.0493294, lng: 17.2433564 },
    { lat: 48.0496349, lng: 17.2436001 },
    { lat: 48.0498768, lng: 17.243789 },
    { lat: 48.0500939, lng: 17.2439953 },
    { lat: 48.0506323, lng: 17.2443432 },
    { lat: 48.0509263, lng: 17.244415 },
    { lat: 48.0510398, lng: 17.24449 },
    { lat: 48.0514239, lng: 17.2447783 },
    { lat: 48.0519202, lng: 17.2451224 },
    { lat: 48.0517741, lng: 17.2460392 },
    { lat: 48.0517668, lng: 17.246108 },
    { lat: 48.0517088, lng: 17.2466568 },
    { lat: 48.0516627, lng: 17.2470963 },
    { lat: 48.0516228, lng: 17.247448 },
    { lat: 48.0516009, lng: 17.2476555 },
    { lat: 48.05146, lng: 17.2491011 },
    { lat: 48.0514138, lng: 17.2493368 },
    { lat: 48.0513584, lng: 17.2496061 },
    { lat: 48.0512447, lng: 17.2501714 },
    { lat: 48.0512456, lng: 17.2506385 },
    { lat: 48.0511842, lng: 17.2510916 },
    { lat: 48.0511563, lng: 17.251458 },
    { lat: 48.0510932, lng: 17.2522404 },
    { lat: 48.0509827, lng: 17.2531858 },
    { lat: 48.0507793, lng: 17.2530829 },
    { lat: 48.050686, lng: 17.254903 },
    { lat: 48.0505612, lng: 17.2549122 },
    { lat: 48.0503483, lng: 17.2549723 },
    { lat: 48.0500462, lng: 17.2548976 },
    { lat: 48.0498579, lng: 17.2548851 },
    { lat: 48.0497624, lng: 17.2548596 },
    { lat: 48.049621, lng: 17.2548571 },
    { lat: 48.0492061, lng: 17.2549539 },
    { lat: 48.0491012, lng: 17.254956 },
    { lat: 48.0489967, lng: 17.2548804 },
    { lat: 48.0488635, lng: 17.2547461 },
    { lat: 48.0487792, lng: 17.2547014 },
    { lat: 48.0485127, lng: 17.2546355 },
    { lat: 48.0484269, lng: 17.2545851 },
    { lat: 48.0483217, lng: 17.2544827 },
    { lat: 48.0482488, lng: 17.2543377 },
    { lat: 48.0481491, lng: 17.2541983 },
    { lat: 48.0481014, lng: 17.2541599 },
    { lat: 48.0480482, lng: 17.2541409 },
    { lat: 48.0478473, lng: 17.2541437 },
    { lat: 48.0477595, lng: 17.2540808 },
    { lat: 48.047606, lng: 17.2538805 },
    { lat: 48.0475607, lng: 17.2538611 },
    { lat: 48.0475264, lng: 17.253872 },
    { lat: 48.0474896, lng: 17.2538969 },
    { lat: 48.0472868, lng: 17.254226 },
    { lat: 48.0472092, lng: 17.2542493 },
    { lat: 48.0471194, lng: 17.2542407 },
    { lat: 48.0470464, lng: 17.2541959 },
    { lat: 48.0468779, lng: 17.2540414 },
    { lat: 48.0468115, lng: 17.2540278 },
    { lat: 48.0467588, lng: 17.254033 },
    { lat: 48.0467452, lng: 17.2542027 },
    { lat: 48.04678, lng: 17.2543172 },
    { lat: 48.0469471, lng: 17.2546209 },
    { lat: 48.0469788, lng: 17.2547241 },
    { lat: 48.0469772, lng: 17.2548346 },
    { lat: 48.0468969, lng: 17.2549696 },
    { lat: 48.0467451, lng: 17.255014 },
    { lat: 48.0465758, lng: 17.2550324 },
    { lat: 48.0464112, lng: 17.2549194 },
    { lat: 48.0463043, lng: 17.2548746 },
    { lat: 48.0462679, lng: 17.2548715 },
    { lat: 48.0462328, lng: 17.2549037 },
    { lat: 48.0462042, lng: 17.254983 },
    { lat: 48.0461933, lng: 17.2551244 },
    { lat: 48.0462032, lng: 17.2552399 },
    { lat: 48.046251, lng: 17.2553803 },
    { lat: 48.0462798, lng: 17.2554224 },
    { lat: 48.0463157, lng: 17.2554533 },
    { lat: 48.0463808, lng: 17.2554798 },
    { lat: 48.0464892, lng: 17.2554807 },
    { lat: 48.0466159, lng: 17.2556169 },
    { lat: 48.0466111, lng: 17.2558003 },
    { lat: 48.0465388, lng: 17.2561051 },
    { lat: 48.0465166, lng: 17.2561792 },
    { lat: 48.0463246, lng: 17.256654 },
    { lat: 48.0462478, lng: 17.2568751 },
    { lat: 48.0460644, lng: 17.2572924 },
    { lat: 48.0460381, lng: 17.2573897 },
    { lat: 48.0460598, lng: 17.2576654 },
    { lat: 48.0460937, lng: 17.2577497 },
    { lat: 48.0462149, lng: 17.2579575 },
    { lat: 48.0464735, lng: 17.2582948 },
    { lat: 48.0465315, lng: 17.2584025 },
    { lat: 48.0465885, lng: 17.2586138 },
    { lat: 48.0467313, lng: 17.2594864 },
    { lat: 48.0467903, lng: 17.2599309 },
    { lat: 48.0468602, lng: 17.2602508 },
    { lat: 48.046809, lng: 17.2604311 },
    { lat: 48.0467316, lng: 17.2605756 },
    { lat: 48.0467379, lng: 17.260739 },
    { lat: 48.0467652, lng: 17.2608596 },
    { lat: 48.0468984, lng: 17.2612997 },
    { lat: 48.0469395, lng: 17.2615935 },
    { lat: 48.0469387, lng: 17.2620056 },
    { lat: 48.0469286, lng: 17.2622606 },
    { lat: 48.0469106, lng: 17.2624656 },
    { lat: 48.0468677, lng: 17.2627035 },
    { lat: 48.0467135, lng: 17.2631366 },
    { lat: 48.0466617, lng: 17.2633935 },
    { lat: 48.0467256, lng: 17.2642172 },
    { lat: 48.0467231, lng: 17.2644172 },
    { lat: 48.0466843, lng: 17.2649216 },
    { lat: 48.0466303, lng: 17.2652465 },
    { lat: 48.0466159, lng: 17.2656588 },
    { lat: 48.0466068, lng: 17.2658268 },
    { lat: 48.0465049, lng: 17.2668587 },
    { lat: 48.0464222, lng: 17.2675508 },
    { lat: 48.0463866, lng: 17.2679663 },
    { lat: 48.0463326, lng: 17.2682421 },
    { lat: 48.0462456, lng: 17.2685649 },
    { lat: 48.0461244, lng: 17.2688482 },
    { lat: 48.0460931, lng: 17.2689716 },
    { lat: 48.0460088, lng: 17.2692335 },
    { lat: 48.046009, lng: 17.2697962 },
    { lat: 48.0459701, lng: 17.2700099 },
    { lat: 48.0456658, lng: 17.27092 },
    { lat: 48.0455247, lng: 17.2713629 },
    { lat: 48.045361, lng: 17.2719423 },
    { lat: 48.0452491, lng: 17.2723826 },
    { lat: 48.045183, lng: 17.2726972 },
    { lat: 48.0456294, lng: 17.2729041 },
    { lat: 48.0462515, lng: 17.2732721 },
    { lat: 48.0468807, lng: 17.2737467 },
    { lat: 48.0474951, lng: 17.2742556 },
    { lat: 48.0478961, lng: 17.2746117 },
    { lat: 48.0478987, lng: 17.2748547 },
    { lat: 48.0479333, lng: 17.2749505 },
    { lat: 48.0479906, lng: 17.2750642 },
    { lat: 48.0487085, lng: 17.2758047 },
    { lat: 48.0487678, lng: 17.2758794 },
    { lat: 48.0492033, lng: 17.276382 },
    { lat: 48.0499021, lng: 17.2771403 },
    { lat: 48.0501226, lng: 17.2773638 },
    { lat: 48.0505533, lng: 17.277879 },
    { lat: 48.0508394, lng: 17.2782401 },
  ],
  Kaplna: [
    { lat: 48.297527, lng: 17.476621 },
    { lat: 48.297641, lng: 17.476412 },
    { lat: 48.297847, lng: 17.476048 },
    { lat: 48.298039, lng: 17.475726 },
    { lat: 48.298211, lng: 17.475426 },
    { lat: 48.298386, lng: 17.475122 },
    { lat: 48.298565, lng: 17.474828 },
    { lat: 48.298809, lng: 17.474409 },
    { lat: 48.298973, lng: 17.474123 },
    { lat: 48.299166, lng: 17.473799 },
    { lat: 48.299344, lng: 17.473485 },
    { lat: 48.29955, lng: 17.473142 },
    { lat: 48.299929, lng: 17.472511 },
    { lat: 48.300098, lng: 17.472237 },
    { lat: 48.300261, lng: 17.471972 },
    { lat: 48.300484, lng: 17.471722 },
    { lat: 48.30046, lng: 17.471713 },
    { lat: 48.30053, lng: 17.471631 },
    { lat: 48.300828, lng: 17.471689 },
    { lat: 48.301402, lng: 17.471735 },
    { lat: 48.301524, lng: 17.471647 },
    { lat: 48.301471, lng: 17.471647 },
    { lat: 48.301656, lng: 17.471507 },
    { lat: 48.302016, lng: 17.471238 },
    { lat: 48.302402, lng: 17.470958 },
    { lat: 48.302908, lng: 17.470589 },
    { lat: 48.3034, lng: 17.47023 },
    { lat: 48.304019, lng: 17.46986 },
    { lat: 48.304202, lng: 17.469753 },
    { lat: 48.304396, lng: 17.469631 },
    { lat: 48.304468, lng: 17.469587 },
    { lat: 48.304754, lng: 17.46941 },
    { lat: 48.304978, lng: 17.469271 },
    { lat: 48.305256, lng: 17.469099 },
    { lat: 48.305505, lng: 17.468943 },
    { lat: 48.305743, lng: 17.468802 },
    { lat: 48.306328, lng: 17.468443 },
    { lat: 48.30665, lng: 17.468243 },
    { lat: 48.306952, lng: 17.468064 },
    { lat: 48.307267, lng: 17.467872 },
    { lat: 48.307619, lng: 17.467655 },
    { lat: 48.30802, lng: 17.467414 },
    { lat: 48.308348, lng: 17.467214 },
    { lat: 48.308537, lng: 17.467097 },
    { lat: 48.308811, lng: 17.466928 },
    { lat: 48.309094, lng: 17.466753 },
    { lat: 48.309364, lng: 17.466587 },
    { lat: 48.3098062, lng: 17.4663139 },
    { lat: 48.309819, lng: 17.466306 },
    { lat: 48.309959, lng: 17.466231 },
    { lat: 48.310094, lng: 17.465881 },
    { lat: 48.31027, lng: 17.465435 },
    { lat: 48.310435, lng: 17.465013 },
    { lat: 48.310543, lng: 17.464738 },
    { lat: 48.310383, lng: 17.464604 },
    { lat: 48.309738, lng: 17.464089 },
    { lat: 48.309586, lng: 17.463956 },
    { lat: 48.309362, lng: 17.46378 },
    { lat: 48.308839, lng: 17.463372 },
    { lat: 48.308605, lng: 17.463147 },
    { lat: 48.308278, lng: 17.462792 },
    { lat: 48.308071, lng: 17.46257 },
    { lat: 48.307844, lng: 17.462318 },
    { lat: 48.307355, lng: 17.46179 },
    { lat: 48.306839, lng: 17.461128 },
    { lat: 48.306514, lng: 17.460707 },
    { lat: 48.306102, lng: 17.460173 },
    { lat: 48.305778, lng: 17.459762 },
    { lat: 48.305813, lng: 17.459697 },
    { lat: 48.305743, lng: 17.459609 },
    { lat: 48.305714, lng: 17.459573 },
    { lat: 48.305019, lng: 17.458705 },
    { lat: 48.304978, lng: 17.458647 },
    { lat: 48.304747, lng: 17.458325 },
    { lat: 48.304593, lng: 17.458057 },
    { lat: 48.304471, lng: 17.457836 },
    { lat: 48.304221, lng: 17.457353 },
    { lat: 48.303714, lng: 17.456367 },
    { lat: 48.303654, lng: 17.456253 },
    { lat: 48.302622, lng: 17.454072 },
    { lat: 48.30249, lng: 17.453814 },
    { lat: 48.302211, lng: 17.453272 },
    { lat: 48.301936, lng: 17.452736 },
    { lat: 48.301723, lng: 17.452322 },
    { lat: 48.301383, lng: 17.451598 },
    { lat: 48.301235, lng: 17.45129 },
    { lat: 48.3011, lng: 17.451004 },
    { lat: 48.301099, lng: 17.451 },
    { lat: 48.301086, lng: 17.450975 },
    { lat: 48.301001, lng: 17.450806 },
    { lat: 48.300959, lng: 17.450727 },
    { lat: 48.300892, lng: 17.450599 },
    { lat: 48.300756, lng: 17.450334 },
    { lat: 48.300743, lng: 17.450311 },
    { lat: 48.300709, lng: 17.450244 },
    { lat: 48.300695, lng: 17.450215 },
    { lat: 48.300644, lng: 17.450117 },
    { lat: 48.300586, lng: 17.450003 },
    { lat: 48.300311, lng: 17.449443 },
    { lat: 48.300291, lng: 17.449401 },
    { lat: 48.300191, lng: 17.449197 },
    { lat: 48.300121, lng: 17.449092 },
    { lat: 48.299841, lng: 17.448501 },
    { lat: 48.299833, lng: 17.448481 },
    { lat: 48.299785, lng: 17.448372 },
    { lat: 48.299767, lng: 17.448331 },
    { lat: 48.299504, lng: 17.447704 },
    { lat: 48.299123, lng: 17.446902 },
    { lat: 48.298892, lng: 17.446424 },
    { lat: 48.298571, lng: 17.445751 },
    { lat: 48.298174, lng: 17.444924 },
    { lat: 48.297773, lng: 17.444139 },
    { lat: 48.295992, lng: 17.440656 },
    { lat: 48.295706, lng: 17.44015 },
    { lat: 48.295128, lng: 17.439224 },
    { lat: 48.294681, lng: 17.438434 },
    { lat: 48.29451, lng: 17.438134 },
    { lat: 48.293883, lng: 17.437036 },
    { lat: 48.293792, lng: 17.436876 },
    { lat: 48.293615, lng: 17.436541 },
    { lat: 48.293284, lng: 17.435949 },
    { lat: 48.293172, lng: 17.435757 },
    { lat: 48.293053, lng: 17.435578 },
    { lat: 48.292827, lng: 17.435246 },
    { lat: 48.292635, lng: 17.434958 },
    { lat: 48.29212, lng: 17.434272 },
    { lat: 48.291769, lng: 17.433806 },
    { lat: 48.29155, lng: 17.433517 },
    { lat: 48.29133, lng: 17.433218 },
    { lat: 48.291186, lng: 17.433025 },
    { lat: 48.290846, lng: 17.432609 },
    { lat: 48.290778, lng: 17.432528 },
    { lat: 48.290583, lng: 17.432319 },
    { lat: 48.290266, lng: 17.431903 },
    { lat: 48.290277, lng: 17.431888 },
    { lat: 48.290231, lng: 17.431822 },
    { lat: 48.290118, lng: 17.431647 },
    { lat: 48.29007, lng: 17.431576 },
    { lat: 48.290041, lng: 17.431531 },
    { lat: 48.289918, lng: 17.431354 },
    { lat: 48.289783, lng: 17.431155 },
    { lat: 48.289475, lng: 17.430565 },
    { lat: 48.289372, lng: 17.430392 },
    { lat: 48.289255, lng: 17.43015 },
    { lat: 48.289076, lng: 17.429842 },
    { lat: 48.288818, lng: 17.429369 },
    { lat: 48.288531, lng: 17.428837 },
    { lat: 48.288237, lng: 17.428313 },
    { lat: 48.287992, lng: 17.427789 },
    { lat: 48.287649, lng: 17.427052 },
    { lat: 48.287271, lng: 17.426236 },
    { lat: 48.287108, lng: 17.425889 },
    { lat: 48.286796, lng: 17.425233 },
    { lat: 48.286725, lng: 17.42508 },
    { lat: 48.28665, lng: 17.424924 },
    { lat: 48.286582, lng: 17.424781 },
    { lat: 48.286516, lng: 17.424641 },
    { lat: 48.286481, lng: 17.424563 },
    { lat: 48.286443, lng: 17.424516 },
    { lat: 48.2862245, lng: 17.4250094 },
    { lat: 48.286137, lng: 17.4252288 },
    { lat: 48.2859788, lng: 17.4255571 },
    { lat: 48.2857409, lng: 17.4260157 },
    { lat: 48.2856515, lng: 17.4261548 },
    { lat: 48.285559, lng: 17.4262614 },
    { lat: 48.2854355, lng: 17.426359 },
    { lat: 48.2851278, lng: 17.426683 },
    { lat: 48.2850134, lng: 17.4267977 },
    { lat: 48.2847343, lng: 17.4270932 },
    { lat: 48.2842249, lng: 17.4275624 },
    { lat: 48.284171, lng: 17.4276371 },
    { lat: 48.2839662, lng: 17.4278405 },
    { lat: 48.2837855, lng: 17.4280341 },
    { lat: 48.2833789, lng: 17.4284513 },
    { lat: 48.2832905, lng: 17.4285335 },
    { lat: 48.2830423, lng: 17.4288354 },
    { lat: 48.2827714, lng: 17.429203 },
    { lat: 48.2825059, lng: 17.429498 },
    { lat: 48.2823606, lng: 17.4296952 },
    { lat: 48.282265, lng: 17.4297895 },
    { lat: 48.2821531, lng: 17.4299623 },
    { lat: 48.282092, lng: 17.4300136 },
    { lat: 48.2819061, lng: 17.4301252 },
    { lat: 48.2818088, lng: 17.4301732 },
    { lat: 48.2817104, lng: 17.4302061 },
    { lat: 48.281493, lng: 17.4303215 },
    { lat: 48.2813568, lng: 17.4304319 },
    { lat: 48.2812392, lng: 17.4305174 },
    { lat: 48.281131, lng: 17.4306076 },
    { lat: 48.2810622, lng: 17.4306964 },
    { lat: 48.2808658, lng: 17.4309779 },
    { lat: 48.2806967, lng: 17.4312089 },
    { lat: 48.28057, lng: 17.4314153 },
    { lat: 48.2803622, lng: 17.4316418 },
    { lat: 48.2800997, lng: 17.43207 },
    { lat: 48.2798996, lng: 17.4323647 },
    { lat: 48.2790859, lng: 17.4331929 },
    { lat: 48.2788766, lng: 17.4333783 },
    { lat: 48.2786985, lng: 17.4335242 },
    { lat: 48.2783666, lng: 17.4337697 },
    { lat: 48.2783076, lng: 17.4337834 },
    { lat: 48.2782971, lng: 17.4337963 },
    { lat: 48.2782698, lng: 17.4338295 },
    { lat: 48.2781064, lng: 17.4341484 },
    { lat: 48.2778521, lng: 17.434633 },
    { lat: 48.2777498, lng: 17.4348146 },
    { lat: 48.2775448, lng: 17.4351209 },
    { lat: 48.2774475, lng: 17.4352391 },
    { lat: 48.277261, lng: 17.435482 },
    { lat: 48.2769829, lng: 17.4358618 },
    { lat: 48.276834, lng: 17.4360373 },
    { lat: 48.2766899, lng: 17.4362199 },
    { lat: 48.2765203, lng: 17.4363731 },
    { lat: 48.2764358, lng: 17.4364302 },
    { lat: 48.2763754, lng: 17.4364924 },
    { lat: 48.2764833, lng: 17.4366924 },
    { lat: 48.2767013, lng: 17.4370068 },
    { lat: 48.2769521, lng: 17.4373754 },
    { lat: 48.2776781, lng: 17.4384689 },
    { lat: 48.2784546, lng: 17.4397341 },
    { lat: 48.2785578, lng: 17.4395998 },
    { lat: 48.2789308, lng: 17.4402166 },
    { lat: 48.2795459, lng: 17.4412211 },
    { lat: 48.2802167, lng: 17.4423191 },
    { lat: 48.280721, lng: 17.4431309 },
    { lat: 48.2810771, lng: 17.4437112 },
    { lat: 48.2813871, lng: 17.4442263 },
    { lat: 48.2816483, lng: 17.4446486 },
    { lat: 48.2819726, lng: 17.445193 },
    { lat: 48.2820722, lng: 17.4453697 },
    { lat: 48.2825669, lng: 17.4462269 },
    { lat: 48.2828381, lng: 17.4467002 },
    { lat: 48.2834192, lng: 17.4477049 },
    { lat: 48.2837719, lng: 17.4483212 },
    { lat: 48.284043, lng: 17.4488115 },
    { lat: 48.2842933, lng: 17.4492414 },
    { lat: 48.2848895, lng: 17.450287 },
    { lat: 48.2851242, lng: 17.4507572 },
    { lat: 48.2853832, lng: 17.4512501 },
    { lat: 48.2855073, lng: 17.4515105 },
    { lat: 48.285495, lng: 17.4515462 },
    { lat: 48.2856685, lng: 17.4519245 },
    { lat: 48.2859111, lng: 17.4524275 },
    { lat: 48.2861153, lng: 17.4528261 },
    { lat: 48.2864793, lng: 17.4535662 },
    { lat: 48.2865258, lng: 17.4536559 },
    { lat: 48.2870707, lng: 17.4548723 },
    { lat: 48.2872827, lng: 17.4553196 },
    { lat: 48.2875949, lng: 17.4559935 },
    { lat: 48.2877893, lng: 17.4563783 },
    { lat: 48.2879363, lng: 17.4566802 },
    { lat: 48.2881413, lng: 17.4572658 },
    { lat: 48.2882962, lng: 17.4577577 },
    { lat: 48.2884957, lng: 17.4582077 },
    { lat: 48.288626, lng: 17.4584748 },
    { lat: 48.2887913, lng: 17.45883 },
    { lat: 48.2889551, lng: 17.4591456 },
    { lat: 48.289148, lng: 17.4595966 },
    { lat: 48.2893339, lng: 17.4599766 },
    { lat: 48.2895599, lng: 17.4604499 },
    { lat: 48.2897348, lng: 17.460779 },
    { lat: 48.290005, lng: 17.4613983 },
    { lat: 48.2904986, lng: 17.4623423 },
    { lat: 48.2908765, lng: 17.4630346 },
    { lat: 48.2911448, lng: 17.4636068 },
    { lat: 48.2915053, lng: 17.4642569 },
    { lat: 48.2921023, lng: 17.4653543 },
    { lat: 48.2923735, lng: 17.4658795 },
    { lat: 48.2925827, lng: 17.4663053 },
    { lat: 48.2928063, lng: 17.4668474 },
    { lat: 48.2929078, lng: 17.4671086 },
    { lat: 48.2931149, lng: 17.467622 },
    { lat: 48.2933885, lng: 17.468311 },
    { lat: 48.2936012, lng: 17.468842 },
    { lat: 48.2938075, lng: 17.4693436 },
    { lat: 48.2940867, lng: 17.4700319 },
    { lat: 48.2944892, lng: 17.471018 },
    { lat: 48.294758, lng: 17.4715799 },
    { lat: 48.294803, lng: 17.4716994 },
    { lat: 48.294957, lng: 17.4720056 },
    { lat: 48.2952395, lng: 17.4726028 },
    { lat: 48.295498, lng: 17.4731442 },
    { lat: 48.296057, lng: 17.4742304 },
    { lat: 48.2961914, lng: 17.4744394 },
    { lat: 48.2964646, lng: 17.4748958 },
    { lat: 48.2968394, lng: 17.475506 },
    { lat: 48.2973708, lng: 17.4763566 },
    { lat: 48.297527, lng: 17.476621 },
  ],
  KráľovápriSenci: [
    { lat: 48.1889923, lng: 17.4216747 },
    { lat: 48.1892564, lng: 17.4222121 },
    { lat: 48.1893208, lng: 17.4223346 },
    { lat: 48.1897585, lng: 17.423107 },
    { lat: 48.1900031, lng: 17.4235343 },
    { lat: 48.1900367, lng: 17.4236004 },
    { lat: 48.1900781, lng: 17.4236805 },
    { lat: 48.1901272, lng: 17.4236886 },
    { lat: 48.1903566, lng: 17.4238832 },
    { lat: 48.1905694, lng: 17.4239925 },
    { lat: 48.190739, lng: 17.4240668 },
    { lat: 48.1911794, lng: 17.424273 },
    { lat: 48.1917743, lng: 17.4246155 },
    { lat: 48.1915693, lng: 17.4260846 },
    { lat: 48.1915418, lng: 17.4263217 },
    { lat: 48.1915051, lng: 17.4268523 },
    { lat: 48.1914713, lng: 17.4270784 },
    { lat: 48.1913988, lng: 17.4274396 },
    { lat: 48.1913215, lng: 17.4277117 },
    { lat: 48.1912265, lng: 17.4279697 },
    { lat: 48.1909399, lng: 17.428671 },
    { lat: 48.1908072, lng: 17.4290086 },
    { lat: 48.1906743, lng: 17.4292948 },
    { lat: 48.190368, lng: 17.4298816 },
    { lat: 48.1902715, lng: 17.4301007 },
    { lat: 48.1901744, lng: 17.4303969 },
    { lat: 48.1900737, lng: 17.4307427 },
    { lat: 48.1900327, lng: 17.4309285 },
    { lat: 48.1900225, lng: 17.4310161 },
    { lat: 48.1900278, lng: 17.4312171 },
    { lat: 48.190053, lng: 17.4313283 },
    { lat: 48.1901458, lng: 17.4315734 },
    { lat: 48.190328, lng: 17.432014 },
    { lat: 48.1903867, lng: 17.4321195 },
    { lat: 48.1905769, lng: 17.4323325 },
    { lat: 48.1908513, lng: 17.4325428 },
    { lat: 48.191035, lng: 17.432674 },
    { lat: 48.1918714, lng: 17.43298 },
    { lat: 48.1916722, lng: 17.4344531 },
    { lat: 48.191623, lng: 17.4347575 },
    { lat: 48.191456, lng: 17.4358214 },
    { lat: 48.1913705, lng: 17.4363304 },
    { lat: 48.1912946, lng: 17.4366936 },
    { lat: 48.1912237, lng: 17.4369067 },
    { lat: 48.1909354, lng: 17.4378106 },
    { lat: 48.1907389, lng: 17.4384261 },
    { lat: 48.190592, lng: 17.4388876 },
    { lat: 48.1899305, lng: 17.4409462 },
    { lat: 48.1895987, lng: 17.4419952 },
    { lat: 48.1892756, lng: 17.4430049 },
    { lat: 48.1889428, lng: 17.4440564 },
    { lat: 48.1887812, lng: 17.4445603 },
    { lat: 48.1886163, lng: 17.4450656 },
    { lat: 48.1884555, lng: 17.4455821 },
    { lat: 48.1880042, lng: 17.4461129 },
    { lat: 48.1879169, lng: 17.4462304 },
    { lat: 48.1875681, lng: 17.4466516 },
    { lat: 48.1873665, lng: 17.4468691 },
    { lat: 48.1872998, lng: 17.4468864 },
    { lat: 48.1871911, lng: 17.446951 },
    { lat: 48.1870578, lng: 17.4470565 },
    { lat: 48.1869672, lng: 17.4471043 },
    { lat: 48.1869123, lng: 17.4471976 },
    { lat: 48.1868629, lng: 17.4472368 },
    { lat: 48.1868555, lng: 17.4472454 },
    { lat: 48.1868415, lng: 17.4472721 },
    { lat: 48.1867856, lng: 17.4474734 },
    { lat: 48.1867657, lng: 17.4475853 },
    { lat: 48.186669, lng: 17.4477657 },
    { lat: 48.186579, lng: 17.4478597 },
    { lat: 48.1864441, lng: 17.4478345 },
    { lat: 48.186294, lng: 17.4478191 },
    { lat: 48.1862426, lng: 17.4478444 },
    { lat: 48.1861425, lng: 17.4478504 },
    { lat: 48.1859696, lng: 17.4479878 },
    { lat: 48.1857585, lng: 17.4481821 },
    { lat: 48.1856552, lng: 17.4482969 },
    { lat: 48.1855286, lng: 17.4483996 },
    { lat: 48.1854225, lng: 17.4485552 },
    { lat: 48.1852798, lng: 17.4486215 },
    { lat: 48.1848156, lng: 17.4488474 },
    { lat: 48.1845169, lng: 17.4492926 },
    { lat: 48.1842789, lng: 17.4496995 },
    { lat: 48.183608, lng: 17.4508312 },
    { lat: 48.1831502, lng: 17.4518719 },
    { lat: 48.1828841, lng: 17.4528907 },
    { lat: 48.1827926, lng: 17.4532552 },
    { lat: 48.1825611, lng: 17.4540953 },
    { lat: 48.1824298, lng: 17.4551714 },
    { lat: 48.1821612, lng: 17.4574801 },
    { lat: 48.1821571, lng: 17.4574858 },
    { lat: 48.1819968, lng: 17.4581694 },
    { lat: 48.1818419, lng: 17.4588461 },
    { lat: 48.1817029, lng: 17.4594386 },
    { lat: 48.1815146, lng: 17.4602639 },
    { lat: 48.1813418, lng: 17.4610134 },
    { lat: 48.1812384, lng: 17.4615992 },
    { lat: 48.1810873, lng: 17.4624231 },
    { lat: 48.1811432, lng: 17.4630449 },
    { lat: 48.181556, lng: 17.4629143 },
    { lat: 48.1823389, lng: 17.4626534 },
    { lat: 48.1824445, lng: 17.4629607 },
    { lat: 48.1826841, lng: 17.4634167 },
    { lat: 48.1833724, lng: 17.4654164 },
    { lat: 48.1839379, lng: 17.4667568 },
    { lat: 48.1849651, lng: 17.4686543 },
    { lat: 48.1851316, lng: 17.4689757 },
    { lat: 48.1857308, lng: 17.4701398 },
    { lat: 48.1866415, lng: 17.4715815 },
    { lat: 48.1872613, lng: 17.4724159 },
    { lat: 48.1878734, lng: 17.4731146 },
    { lat: 48.1881968, lng: 17.4747846 },
    { lat: 48.1883769, lng: 17.4756682 },
    { lat: 48.1886195, lng: 17.476976 },
    { lat: 48.1888525, lng: 17.4783806 },
    { lat: 48.1889965, lng: 17.4792144 },
    { lat: 48.1884502, lng: 17.4807965 },
    { lat: 48.1881314, lng: 17.4817359 },
    { lat: 48.1876226, lng: 17.4828673 },
    { lat: 48.1870233, lng: 17.4842036 },
    { lat: 48.1867649, lng: 17.4847855 },
    { lat: 48.1864293, lng: 17.4853447 },
    { lat: 48.1861067, lng: 17.4858235 },
    { lat: 48.1857914, lng: 17.4862567 },
    { lat: 48.1855916, lng: 17.4865396 },
    { lat: 48.1854236, lng: 17.4866676 },
    { lat: 48.1854026, lng: 17.4868142 },
    { lat: 48.1852324, lng: 17.4876967 },
    { lat: 48.1851571, lng: 17.4880703 },
    { lat: 48.1850929, lng: 17.4883889 },
    { lat: 48.1849408, lng: 17.4889892 },
    { lat: 48.1847679, lng: 17.4896373 },
    { lat: 48.1846736, lng: 17.4900664 },
    { lat: 48.1840239, lng: 17.4929575 },
    { lat: 48.1837541, lng: 17.4943432 },
    { lat: 48.1835361, lng: 17.4954833 },
    { lat: 48.1832481, lng: 17.496766 },
    { lat: 48.1829811, lng: 17.497951 },
    { lat: 48.1827358, lng: 17.4986128 },
    { lat: 48.1825411, lng: 17.499133 },
    { lat: 48.1820374, lng: 17.5002922 },
    { lat: 48.1813633, lng: 17.5018405 },
    { lat: 48.1811031, lng: 17.5022014 },
    { lat: 48.1809957, lng: 17.502341 },
    { lat: 48.1806348, lng: 17.5028395 },
    { lat: 48.1797724, lng: 17.5040416 },
    { lat: 48.1789787, lng: 17.5049772 },
    { lat: 48.1786151, lng: 17.5053284 },
    { lat: 48.1785088, lng: 17.5053428 },
    { lat: 48.178034, lng: 17.5058274 },
    { lat: 48.1778508, lng: 17.506052 },
    { lat: 48.1777628, lng: 17.5061237 },
    { lat: 48.1774543, lng: 17.5062086 },
    { lat: 48.1772075, lng: 17.5063412 },
    { lat: 48.1770855, lng: 17.5064288 },
    { lat: 48.1768425, lng: 17.5066351 },
    { lat: 48.1767725, lng: 17.506706 },
    { lat: 48.1766747, lng: 17.5068382 },
    { lat: 48.176515, lng: 17.5071079 },
    { lat: 48.176262, lng: 17.507677 },
    { lat: 48.176218, lng: 17.507952 },
    { lat: 48.176231, lng: 17.50805 },
    { lat: 48.176283, lng: 17.508134 },
    { lat: 48.176393, lng: 17.50818 },
    { lat: 48.176621, lng: 17.508389 },
    { lat: 48.176698, lng: 17.508502 },
    { lat: 48.176825, lng: 17.508684 },
    { lat: 48.176851, lng: 17.508881 },
    { lat: 48.176822, lng: 17.50901 },
    { lat: 48.176643, lng: 17.509367 },
    { lat: 48.176472, lng: 17.509553 },
    { lat: 48.176067, lng: 17.509869 },
    { lat: 48.175805, lng: 17.510188 },
    { lat: 48.175731, lng: 17.510223 },
    { lat: 48.175629, lng: 17.510216 },
    { lat: 48.175448, lng: 17.510109 },
    { lat: 48.175354, lng: 17.510006 },
    { lat: 48.174951, lng: 17.509467 },
    { lat: 48.174671, lng: 17.50924 },
    { lat: 48.174495, lng: 17.509176 },
    { lat: 48.174363, lng: 17.509311 },
    { lat: 48.174116, lng: 17.509506 },
    { lat: 48.174057, lng: 17.509613 },
    { lat: 48.174032, lng: 17.509752 },
    { lat: 48.174033, lng: 17.509808 },
    { lat: 48.174067, lng: 17.509933 },
    { lat: 48.174056, lng: 17.510114 },
    { lat: 48.174095, lng: 17.510363 },
    { lat: 48.174229, lng: 17.510728 },
    { lat: 48.174275, lng: 17.510887 },
    { lat: 48.174258, lng: 17.511143 },
    { lat: 48.174269, lng: 17.511267 },
    { lat: 48.174288, lng: 17.511344 },
    { lat: 48.17439, lng: 17.511587 },
    { lat: 48.174634, lng: 17.512021 },
    { lat: 48.174722, lng: 17.512119 },
    { lat: 48.174794, lng: 17.512175 },
    { lat: 48.175252, lng: 17.512177 },
    { lat: 48.175581, lng: 17.512109 },
    { lat: 48.175665, lng: 17.512168 },
    { lat: 48.17574, lng: 17.512426 },
    { lat: 48.175771, lng: 17.512918 },
    { lat: 48.175762, lng: 17.513057 },
    { lat: 48.175655, lng: 17.513328 },
    { lat: 48.175575, lng: 17.513556 },
    { lat: 48.175147, lng: 17.514136 },
    { lat: 48.174994, lng: 17.514265 },
    { lat: 48.174951, lng: 17.514293 },
    { lat: 48.175076, lng: 17.514338 },
    { lat: 48.175067, lng: 17.514437 },
    { lat: 48.178258, lng: 17.515694 },
    { lat: 48.180335, lng: 17.516516 },
    { lat: 48.180355, lng: 17.516462 },
    { lat: 48.180363, lng: 17.516437 },
    { lat: 48.18043, lng: 17.516463 },
    { lat: 48.180445, lng: 17.516469 },
    { lat: 48.180437, lng: 17.516497 },
    { lat: 48.180409, lng: 17.51658 },
    { lat: 48.181599, lng: 17.517056 },
    { lat: 48.183829, lng: 17.517943 },
    { lat: 48.185871, lng: 17.518752 },
    { lat: 48.186896, lng: 17.519173 },
    { lat: 48.186397, lng: 17.521918 },
    { lat: 48.186459, lng: 17.521915 },
    { lat: 48.194194, lng: 17.521457 },
    { lat: 48.194397, lng: 17.521445 },
    { lat: 48.194636, lng: 17.523831 },
    { lat: 48.194709, lng: 17.524572 },
    { lat: 48.194934, lng: 17.524923 },
    { lat: 48.194983, lng: 17.52503 },
    { lat: 48.19508, lng: 17.52538 },
    { lat: 48.195142, lng: 17.525613 },
    { lat: 48.195155, lng: 17.525777 },
    { lat: 48.195139, lng: 17.525967 },
    { lat: 48.195081, lng: 17.526147 },
    { lat: 48.194994, lng: 17.526346 },
    { lat: 48.194983, lng: 17.526802 },
    { lat: 48.195009, lng: 17.52703 },
    { lat: 48.19506, lng: 17.527208 },
    { lat: 48.195159, lng: 17.527362 },
    { lat: 48.195196, lng: 17.527392 },
    { lat: 48.195542, lng: 17.527603 },
    { lat: 48.195778, lng: 17.527576 },
    { lat: 48.195942, lng: 17.527607 },
    { lat: 48.196053, lng: 17.52765 },
    { lat: 48.196146, lng: 17.527803 },
    { lat: 48.196224, lng: 17.528011 },
    { lat: 48.19629, lng: 17.528118 },
    { lat: 48.196314, lng: 17.528301 },
    { lat: 48.196318, lng: 17.528486 },
    { lat: 48.196362, lng: 17.528687 },
    { lat: 48.196367, lng: 17.52887 },
    { lat: 48.196371, lng: 17.52897 },
    { lat: 48.196389, lng: 17.529003 },
    { lat: 48.196423, lng: 17.529065 },
    { lat: 48.196497, lng: 17.529199 },
    { lat: 48.196528, lng: 17.529144 },
    { lat: 48.196594, lng: 17.529028 },
    { lat: 48.196701, lng: 17.528804 },
    { lat: 48.196765, lng: 17.528658 },
    { lat: 48.196814, lng: 17.528543 },
    { lat: 48.196839, lng: 17.528437 },
    { lat: 48.196854, lng: 17.528351 },
    { lat: 48.196893, lng: 17.528138 },
    { lat: 48.196902, lng: 17.528042 },
    { lat: 48.196916, lng: 17.527804 },
    { lat: 48.19692, lng: 17.527657 },
    { lat: 48.196931, lng: 17.527527 },
    { lat: 48.196933, lng: 17.527377 },
    { lat: 48.196931, lng: 17.526996 },
    { lat: 48.196945, lng: 17.526655 },
    { lat: 48.196957, lng: 17.526462 },
    { lat: 48.196977, lng: 17.526306 },
    { lat: 48.196998, lng: 17.526072 },
    { lat: 48.197017, lng: 17.525838 },
    { lat: 48.197031, lng: 17.525697 },
    { lat: 48.197049, lng: 17.525567 },
    { lat: 48.197095, lng: 17.525429 },
    { lat: 48.197116, lng: 17.525355 },
    { lat: 48.197136, lng: 17.525294 },
    { lat: 48.197178, lng: 17.525212 },
    { lat: 48.197249, lng: 17.525075 },
    { lat: 48.197306, lng: 17.524986 },
    { lat: 48.197347, lng: 17.524918 },
    { lat: 48.19739, lng: 17.52485 },
    { lat: 48.197432, lng: 17.524791 },
    { lat: 48.197469, lng: 17.524753 },
    { lat: 48.197515, lng: 17.524695 },
    { lat: 48.197556, lng: 17.524646 },
    { lat: 48.19764, lng: 17.524558 },
    { lat: 48.197662, lng: 17.524541 },
    { lat: 48.197782, lng: 17.524444 },
    { lat: 48.197884, lng: 17.524363 },
    { lat: 48.197947, lng: 17.524314 },
    { lat: 48.198053, lng: 17.524235 },
    { lat: 48.198115, lng: 17.524185 },
    { lat: 48.19821, lng: 17.524121 },
    { lat: 48.198478, lng: 17.523973 },
    { lat: 48.198622, lng: 17.523896 },
    { lat: 48.198748, lng: 17.523829 },
    { lat: 48.198892, lng: 17.5238 },
    { lat: 48.19936, lng: 17.523723 },
    { lat: 48.199498, lng: 17.523706 },
    { lat: 48.199614, lng: 17.523719 },
    { lat: 48.19994, lng: 17.523717 },
    { lat: 48.199993, lng: 17.523721 },
    { lat: 48.200043, lng: 17.523731 },
    { lat: 48.200138, lng: 17.523753 },
    { lat: 48.200174, lng: 17.523754 },
    { lat: 48.200282, lng: 17.523748 },
    { lat: 48.200312, lng: 17.523739 },
    { lat: 48.200347, lng: 17.523731 },
    { lat: 48.200436, lng: 17.52371 },
    { lat: 48.200498, lng: 17.52371 },
    { lat: 48.200634, lng: 17.523716 },
    { lat: 48.201018, lng: 17.523671 },
    { lat: 48.201168, lng: 17.523652 },
    { lat: 48.201226, lng: 17.523645 },
    { lat: 48.201234, lng: 17.52364 },
    { lat: 48.201255, lng: 17.523624 },
    { lat: 48.20131, lng: 17.523589 },
    { lat: 48.201341, lng: 17.523568 },
    { lat: 48.201602, lng: 17.523406 },
    { lat: 48.201754, lng: 17.523311 },
    { lat: 48.201855, lng: 17.523244 },
    { lat: 48.20193, lng: 17.523194 },
    { lat: 48.201998, lng: 17.52315 },
    { lat: 48.202016, lng: 17.523136 },
    { lat: 48.202037, lng: 17.523102 },
    { lat: 48.202097, lng: 17.523019 },
    { lat: 48.202154, lng: 17.522932 },
    { lat: 48.202192, lng: 17.522881 },
    { lat: 48.202236, lng: 17.522837 },
    { lat: 48.202299, lng: 17.522778 },
    { lat: 48.202396, lng: 17.522712 },
    { lat: 48.202516, lng: 17.522642 },
    { lat: 48.202562, lng: 17.522624 },
    { lat: 48.202587, lng: 17.52258 },
    { lat: 48.202679, lng: 17.52243 },
    { lat: 48.202757, lng: 17.52231 },
    { lat: 48.202845, lng: 17.522146 },
    { lat: 48.202942, lng: 17.521954 },
    { lat: 48.20296, lng: 17.521909 },
    { lat: 48.202971, lng: 17.521855 },
    { lat: 48.203033, lng: 17.521593 },
    { lat: 48.203079, lng: 17.521303 },
    { lat: 48.2031, lng: 17.521153 },
    { lat: 48.203096, lng: 17.521047 },
    { lat: 48.20309, lng: 17.520749 },
    { lat: 48.203078, lng: 17.52053 },
    { lat: 48.203055, lng: 17.520337 },
    { lat: 48.203027, lng: 17.520195 },
    { lat: 48.202952, lng: 17.519889 },
    { lat: 48.202865, lng: 17.519659 },
    { lat: 48.202744, lng: 17.519367 },
    { lat: 48.202676, lng: 17.519196 },
    { lat: 48.202586, lng: 17.519032 },
    { lat: 48.202486, lng: 17.518905 },
    { lat: 48.202383, lng: 17.518776 },
    { lat: 48.202294, lng: 17.518678 },
    { lat: 48.202239, lng: 17.51864 },
    { lat: 48.202079, lng: 17.518563 },
    { lat: 48.2019, lng: 17.518518 },
    { lat: 48.201708, lng: 17.51849 },
    { lat: 48.20155, lng: 17.518465 },
    { lat: 48.201489, lng: 17.518452 },
    { lat: 48.201377, lng: 17.518445 },
    { lat: 48.201319, lng: 17.518432 },
    { lat: 48.201287, lng: 17.518423 },
    { lat: 48.201244, lng: 17.518413 },
    { lat: 48.201226, lng: 17.518413 },
    { lat: 48.201209, lng: 17.518408 },
    { lat: 48.201171, lng: 17.518386 },
    { lat: 48.201112, lng: 17.518358 },
    { lat: 48.201022, lng: 17.518316 },
    { lat: 48.200921, lng: 17.518266 },
    { lat: 48.200781, lng: 17.518204 },
    { lat: 48.200655, lng: 17.518157 },
    { lat: 48.200601, lng: 17.518134 },
    { lat: 48.200565, lng: 17.518117 },
    { lat: 48.200501, lng: 17.51809 },
    { lat: 48.200467, lng: 17.518077 },
    { lat: 48.200437, lng: 17.518055 },
    { lat: 48.200367, lng: 17.518011 },
    { lat: 48.200317, lng: 17.517968 },
    { lat: 48.20029, lng: 17.517947 },
    { lat: 48.200261, lng: 17.517928 },
    { lat: 48.200193, lng: 17.517882 },
    { lat: 48.200161, lng: 17.517854 },
    { lat: 48.200118, lng: 17.517812 },
    { lat: 48.199986, lng: 17.517649 },
    { lat: 48.199894, lng: 17.517538 },
    { lat: 48.199857, lng: 17.517503 },
    { lat: 48.19972, lng: 17.517297 },
    { lat: 48.19963, lng: 17.517165 },
    { lat: 48.199533, lng: 17.517021 },
    { lat: 48.199512, lng: 17.516997 },
    { lat: 48.199487, lng: 17.516942 },
    { lat: 48.199438, lng: 17.516812 },
    { lat: 48.199407, lng: 17.516733 },
    { lat: 48.199355, lng: 17.516548 },
    { lat: 48.199316, lng: 17.516406 },
    { lat: 48.19928, lng: 17.516271 },
    { lat: 48.199247, lng: 17.516155 },
    { lat: 48.199186, lng: 17.515851 },
    { lat: 48.199158, lng: 17.515714 },
    { lat: 48.199132, lng: 17.515576 },
    { lat: 48.199096, lng: 17.515318 },
    { lat: 48.199037, lng: 17.514901 },
    { lat: 48.198999, lng: 17.51478 },
    { lat: 48.199005, lng: 17.514578 },
    { lat: 48.19901, lng: 17.514397 },
    { lat: 48.199024, lng: 17.514227 },
    { lat: 48.19904, lng: 17.514119 },
    { lat: 48.199072, lng: 17.513956 },
    { lat: 48.19914, lng: 17.513749 },
    { lat: 48.199196, lng: 17.513616 },
    { lat: 48.199304, lng: 17.513374 },
    { lat: 48.199378, lng: 17.513201 },
    { lat: 48.199495, lng: 17.512915 },
    { lat: 48.199575, lng: 17.512769 },
    { lat: 48.199629, lng: 17.512621 },
    { lat: 48.199743, lng: 17.512217 },
    { lat: 48.199755, lng: 17.512129 },
    { lat: 48.199796, lng: 17.511587 },
    { lat: 48.199797, lng: 17.511161 },
    { lat: 48.199796, lng: 17.510777 },
    { lat: 48.199782, lng: 17.510405 },
    { lat: 48.199757, lng: 17.510007 },
    { lat: 48.199751, lng: 17.509531 },
    { lat: 48.199768, lng: 17.509461 },
    { lat: 48.19985, lng: 17.508922 },
    { lat: 48.199896, lng: 17.508574 },
    { lat: 48.199912, lng: 17.508292 },
    { lat: 48.199921, lng: 17.507783 },
    { lat: 48.199923, lng: 17.507445 },
    { lat: 48.199927, lng: 17.507184 },
    { lat: 48.199964, lng: 17.506573 },
    { lat: 48.19999, lng: 17.506242 },
    { lat: 48.200011, lng: 17.506055 },
    { lat: 48.200071, lng: 17.505796 },
    { lat: 48.200177, lng: 17.505335 },
    { lat: 48.200327, lng: 17.504919 },
    { lat: 48.20054, lng: 17.504454 },
    { lat: 48.200758, lng: 17.50414 },
    { lat: 48.200814, lng: 17.504075 },
    { lat: 48.20106, lng: 17.503831 },
    { lat: 48.201357, lng: 17.503626 },
    { lat: 48.201481, lng: 17.503573 },
    { lat: 48.201602, lng: 17.503534 },
    { lat: 48.20169, lng: 17.503514 },
    { lat: 48.20185, lng: 17.503501 },
    { lat: 48.201935, lng: 17.503503 },
    { lat: 48.202039, lng: 17.503511 },
    { lat: 48.202256, lng: 17.503488 },
    { lat: 48.202499, lng: 17.503472 },
    { lat: 48.202632, lng: 17.503455 },
    { lat: 48.202743, lng: 17.50342 },
    { lat: 48.202909, lng: 17.503364 },
    { lat: 48.203279, lng: 17.50319 },
    { lat: 48.203489, lng: 17.503084 },
    { lat: 48.203726, lng: 17.502989 },
    { lat: 48.204102, lng: 17.50272 },
    { lat: 48.204516, lng: 17.502331 },
    { lat: 48.204562, lng: 17.502236 },
    { lat: 48.204901, lng: 17.501706 },
    { lat: 48.205015, lng: 17.501511 },
    { lat: 48.20509, lng: 17.501336 },
    { lat: 48.205202, lng: 17.500985 },
    { lat: 48.205313, lng: 17.50074 },
    { lat: 48.2053307, lng: 17.5004549 },
    { lat: 48.2053655, lng: 17.5002768 },
    { lat: 48.2053164, lng: 17.4998648 },
    { lat: 48.2052956, lng: 17.4997344 },
    { lat: 48.2052583, lng: 17.4995758 },
    { lat: 48.2051764, lng: 17.4993021 },
    { lat: 48.205051, lng: 17.4990034 },
    { lat: 48.2048921, lng: 17.4987094 },
    { lat: 48.2048497, lng: 17.4985061 },
    { lat: 48.2047787, lng: 17.498246 },
    { lat: 48.2047592, lng: 17.4980292 },
    { lat: 48.2047766, lng: 17.4978628 },
    { lat: 48.2048364, lng: 17.4975449 },
    { lat: 48.2049574, lng: 17.496829 },
    { lat: 48.2050557, lng: 17.496302 },
    { lat: 48.2052026, lng: 17.4957064 },
    { lat: 48.2053291, lng: 17.4952152 },
    { lat: 48.2054463, lng: 17.4947362 },
    { lat: 48.2054535, lng: 17.4946558 },
    { lat: 48.2054821, lng: 17.4940298 },
    { lat: 48.2054694, lng: 17.4935343 },
    { lat: 48.2053845, lng: 17.4930041 },
    { lat: 48.2053234, lng: 17.4927907 },
    { lat: 48.205175, lng: 17.4923466 },
    { lat: 48.2050449, lng: 17.4919396 },
    { lat: 48.2048318, lng: 17.491558 },
    { lat: 48.2046217, lng: 17.4912411 },
    { lat: 48.2045615, lng: 17.4911663 },
    { lat: 48.204228, lng: 17.4908105 },
    { lat: 48.2038507, lng: 17.4904408 },
    { lat: 48.2037467, lng: 17.4903856 },
    { lat: 48.2035113, lng: 17.4903604 },
    { lat: 48.2034633, lng: 17.4903689 },
    { lat: 48.2032621, lng: 17.4904555 },
    { lat: 48.2030479, lng: 17.4905826 },
    { lat: 48.2029891, lng: 17.4906341 },
    { lat: 48.202908, lng: 17.4907268 },
    { lat: 48.2026391, lng: 17.4910766 },
    { lat: 48.2023618, lng: 17.4913817 },
    { lat: 48.2021259, lng: 17.4915407 },
    { lat: 48.2019542, lng: 17.4916135 },
    { lat: 48.2016359, lng: 17.4916688 },
    { lat: 48.2013359, lng: 17.4916376 },
    { lat: 48.2013068, lng: 17.4916248 },
    { lat: 48.200848, lng: 17.491359 },
    { lat: 48.2006988, lng: 17.4912495 },
    { lat: 48.2005832, lng: 17.4911387 },
    { lat: 48.2004542, lng: 17.490995 },
    { lat: 48.200364, lng: 17.490873 },
    { lat: 48.2001872, lng: 17.4906011 },
    { lat: 48.200167, lng: 17.4900068 },
    { lat: 48.2001767, lng: 17.4897351 },
    { lat: 48.200196, lng: 17.4895626 },
    { lat: 48.2002222, lng: 17.4894262 },
    { lat: 48.2003116, lng: 17.489165 },
    { lat: 48.200442, lng: 17.4889814 },
    { lat: 48.2005477, lng: 17.4887989 },
    { lat: 48.2006323, lng: 17.4887267 },
    { lat: 48.2009014, lng: 17.4886611 },
    { lat: 48.2010843, lng: 17.4886439 },
    { lat: 48.2015452, lng: 17.4887862 },
    { lat: 48.2020995, lng: 17.4888879 },
    { lat: 48.2025314, lng: 17.4888754 },
    { lat: 48.2029013, lng: 17.48878 },
    { lat: 48.2030815, lng: 17.4886839 },
    { lat: 48.2034653, lng: 17.4883659 },
    { lat: 48.2037804, lng: 17.488121 },
    { lat: 48.204097, lng: 17.4878633 },
    { lat: 48.2042474, lng: 17.4876898 },
    { lat: 48.2046353, lng: 17.4872097 },
    { lat: 48.2047705, lng: 17.4869849 },
    { lat: 48.2048769, lng: 17.4868041 },
    { lat: 48.2049547, lng: 17.486504 },
    { lat: 48.2050361, lng: 17.4860486 },
    { lat: 48.2050921, lng: 17.4857619 },
    { lat: 48.205118, lng: 17.4856027 },
    { lat: 48.2051398, lng: 17.4854162 },
    { lat: 48.2051556, lng: 17.4850296 },
    { lat: 48.2051036, lng: 17.4846611 },
    { lat: 48.2050558, lng: 17.4844282 },
    { lat: 48.205033, lng: 17.4843529 },
    { lat: 48.204879, lng: 17.4841368 },
    { lat: 48.2043856, lng: 17.4835432 },
    { lat: 48.2040419, lng: 17.4830462 },
    { lat: 48.2036441, lng: 17.4823603 },
    { lat: 48.2035829, lng: 17.482233 },
    { lat: 48.2033792, lng: 17.4816483 },
    { lat: 48.2033646, lng: 17.4815633 },
    { lat: 48.2033062, lng: 17.4807617 },
    { lat: 48.2033322, lng: 17.4804639 },
    { lat: 48.2033893, lng: 17.4802631 },
    { lat: 48.203529, lng: 17.4798383 },
    { lat: 48.2036782, lng: 17.4794891 },
    { lat: 48.2037474, lng: 17.4793878 },
    { lat: 48.2042378, lng: 17.4782535 },
    { lat: 48.2043867, lng: 17.4776027 },
    { lat: 48.2043995, lng: 17.4775205 },
    { lat: 48.2043593, lng: 17.4766361 },
    { lat: 48.2043313, lng: 17.4763757 },
    { lat: 48.2042429, lng: 17.4761282 },
    { lat: 48.2041522, lng: 17.4759986 },
    { lat: 48.2037686, lng: 17.4756028 },
    { lat: 48.2034677, lng: 17.4755602 },
    { lat: 48.203152, lng: 17.4756201 },
    { lat: 48.2028535, lng: 17.4757037 },
    { lat: 48.2025744, lng: 17.4757001 },
    { lat: 48.2023906, lng: 17.4756868 },
    { lat: 48.2023127, lng: 17.4756533 },
    { lat: 48.2021622, lng: 17.4754547 },
    { lat: 48.202086, lng: 17.4752722 },
    { lat: 48.2020079, lng: 17.4750073 },
    { lat: 48.2019486, lng: 17.4746475 },
    { lat: 48.201941, lng: 17.4744789 },
    { lat: 48.201975, lng: 17.4742695 },
    { lat: 48.2020024, lng: 17.4739597 },
    { lat: 48.2020419, lng: 17.4736955 },
    { lat: 48.2021158, lng: 17.4733723 },
    { lat: 48.2021801, lng: 17.4731435 },
    { lat: 48.2023186, lng: 17.4727332 },
    { lat: 48.2025924, lng: 17.4719004 },
    { lat: 48.2026987, lng: 17.4715716 },
    { lat: 48.2028587, lng: 17.4710054 },
    { lat: 48.2029182, lng: 17.4705787 },
    { lat: 48.2029621, lng: 17.470139 },
    { lat: 48.2029731, lng: 17.4699616 },
    { lat: 48.2029841, lng: 17.4696053 },
    { lat: 48.2030121, lng: 17.4689897 },
    { lat: 48.2030202, lng: 17.4685214 },
    { lat: 48.2030359, lng: 17.4683281 },
    { lat: 48.2030606, lng: 17.4681311 },
    { lat: 48.2031046, lng: 17.4678873 },
    { lat: 48.2031789, lng: 17.467445 },
    { lat: 48.2032624, lng: 17.4674793 },
    { lat: 48.2034192, lng: 17.4675294 },
    { lat: 48.2040821, lng: 17.4677917 },
    { lat: 48.2044914, lng: 17.4679724 },
    { lat: 48.2048723, lng: 17.4681877 },
    { lat: 48.2055962, lng: 17.468606 },
    { lat: 48.2058921, lng: 17.4688506 },
    { lat: 48.2070251, lng: 17.4697639 },
    { lat: 48.208081, lng: 17.4705499 },
    { lat: 48.2088047, lng: 17.4710707 },
    { lat: 48.2092426, lng: 17.4713993 },
    { lat: 48.2092929, lng: 17.4714259 },
    { lat: 48.2093052, lng: 17.4714064 },
    { lat: 48.2102368, lng: 17.4700373 },
    { lat: 48.211041, lng: 17.4686404 },
    { lat: 48.2114381, lng: 17.4667614 },
    { lat: 48.2117547, lng: 17.465281 },
    { lat: 48.2116953, lng: 17.464257 },
    { lat: 48.2115521, lng: 17.4617423 },
    { lat: 48.2110129, lng: 17.4572622 },
    { lat: 48.2109024, lng: 17.4563402 },
    { lat: 48.2106157, lng: 17.4553308 },
    { lat: 48.2105222, lng: 17.4548796 },
    { lat: 48.2103068, lng: 17.4538715 },
    { lat: 48.2103122, lng: 17.4526297 },
    { lat: 48.2098815, lng: 17.4520821 },
    { lat: 48.209957, lng: 17.4505258 },
    { lat: 48.2100382, lng: 17.4482735 },
    { lat: 48.21031, lng: 17.4466288 },
    { lat: 48.2101411, lng: 17.4448579 },
    { lat: 48.2099106, lng: 17.4424851 },
    { lat: 48.2097512, lng: 17.4415754 },
    { lat: 48.2094507, lng: 17.4398338 },
    { lat: 48.2093606, lng: 17.4393484 },
    { lat: 48.2091888, lng: 17.4383988 },
    { lat: 48.2089592, lng: 17.4371296 },
    { lat: 48.2088301, lng: 17.4359055 },
    { lat: 48.2088867, lng: 17.4356102 },
    { lat: 48.209061, lng: 17.4346757 },
    { lat: 48.2091384, lng: 17.4342851 },
    { lat: 48.2092253, lng: 17.433867 },
    { lat: 48.2080467, lng: 17.4322031 },
    { lat: 48.2070563, lng: 17.4306049 },
    { lat: 48.2070244, lng: 17.4297139 },
    { lat: 48.2068213, lng: 17.4277664 },
    { lat: 48.2067184, lng: 17.4274013 },
    { lat: 48.2077622, lng: 17.4244454 },
    { lat: 48.2075244, lng: 17.424263 },
    { lat: 48.2071139, lng: 17.4240649 },
    { lat: 48.2065043, lng: 17.4236872 },
    { lat: 48.2062628, lng: 17.4234977 },
    { lat: 48.2053968, lng: 17.4228428 },
    { lat: 48.2048294, lng: 17.422453 },
    { lat: 48.2046135, lng: 17.4223131 },
    { lat: 48.204202, lng: 17.4224632 },
    { lat: 48.2039741, lng: 17.4216836 },
    { lat: 48.2038366, lng: 17.4212888 },
    { lat: 48.2037848, lng: 17.4211181 },
    { lat: 48.2037563, lng: 17.4209744 },
    { lat: 48.2035379, lng: 17.4206379 },
    { lat: 48.2033087, lng: 17.420291 },
    { lat: 48.2029189, lng: 17.4200116 },
    { lat: 48.2026074, lng: 17.41986 },
    { lat: 48.2017226, lng: 17.4197657 },
    { lat: 48.2015489, lng: 17.4197858 },
    { lat: 48.2011125, lng: 17.4199364 },
    { lat: 48.2009221, lng: 17.4200147 },
    { lat: 48.2007661, lng: 17.420116 },
    { lat: 48.2002868, lng: 17.420734 },
    { lat: 48.2001635, lng: 17.4209411 },
    { lat: 48.1999394, lng: 17.4212812 },
    { lat: 48.1997538, lng: 17.4215709 },
    { lat: 48.1993769, lng: 17.4219746 },
    { lat: 48.1991959, lng: 17.4221445 },
    { lat: 48.1988238, lng: 17.4223466 },
    { lat: 48.1985628, lng: 17.4223626 },
    { lat: 48.1982399, lng: 17.4223647 },
    { lat: 48.1980904, lng: 17.4223213 },
    { lat: 48.1979884, lng: 17.4223185 },
    { lat: 48.1974853, lng: 17.422292 },
    { lat: 48.1971788, lng: 17.4224606 },
    { lat: 48.1966761, lng: 17.4225633 },
    { lat: 48.1959175, lng: 17.4227091 },
    { lat: 48.1954953, lng: 17.422659 },
    { lat: 48.1953348, lng: 17.4225886 },
    { lat: 48.1949562, lng: 17.4222719 },
    { lat: 48.1947119, lng: 17.421989 },
    { lat: 48.1943914, lng: 17.4210373 },
    { lat: 48.1942895, lng: 17.4205302 },
    { lat: 48.1941887, lng: 17.4199882 },
    { lat: 48.1941726, lng: 17.4198435 },
    { lat: 48.1940955, lng: 17.4187432 },
    { lat: 48.1941051, lng: 17.4179538 },
    { lat: 48.1941428, lng: 17.4175099 },
    { lat: 48.1941726, lng: 17.4172724 },
    { lat: 48.1942074, lng: 17.4170797 },
    { lat: 48.1943628, lng: 17.4164973 },
    { lat: 48.1944126, lng: 17.416327 },
    { lat: 48.1945765, lng: 17.4160327 },
    { lat: 48.1946946, lng: 17.4156955 },
    { lat: 48.1948084, lng: 17.4154837 },
    { lat: 48.1949718, lng: 17.4152329 },
    { lat: 48.1952318, lng: 17.4148553 },
    { lat: 48.1959567, lng: 17.4141045 },
    { lat: 48.1959954, lng: 17.4140583 },
    { lat: 48.1962888, lng: 17.4137758 },
    { lat: 48.1968286, lng: 17.4133745 },
    { lat: 48.1971362, lng: 17.4133635 },
    { lat: 48.1977722, lng: 17.4135564 },
    { lat: 48.1978713, lng: 17.4136215 },
    { lat: 48.1979258, lng: 17.4136438 },
    { lat: 48.1981948, lng: 17.4138371 },
    { lat: 48.198382, lng: 17.4140235 },
    { lat: 48.1984412, lng: 17.4139947 },
    { lat: 48.1986976, lng: 17.4141684 },
    { lat: 48.1990015, lng: 17.4142392 },
    { lat: 48.1992484, lng: 17.4142682 },
    { lat: 48.1993634, lng: 17.4142125 },
    { lat: 48.1994869, lng: 17.4140628 },
    { lat: 48.1996237, lng: 17.4137523 },
    { lat: 48.1997418, lng: 17.4136397 },
    { lat: 48.1998049, lng: 17.4136034 },
    { lat: 48.2000958, lng: 17.4135256 },
    { lat: 48.2003628, lng: 17.4134292 },
    { lat: 48.2004244, lng: 17.413351 },
    { lat: 48.2008878, lng: 17.4127835 },
    { lat: 48.2010365, lng: 17.4126216 },
    { lat: 48.2012663, lng: 17.4123525 },
    { lat: 48.2014247, lng: 17.4121477 },
    { lat: 48.2015869, lng: 17.411826 },
    { lat: 48.201613, lng: 17.4117606 },
    { lat: 48.2017423, lng: 17.4115245 },
    { lat: 48.2019349, lng: 17.4111357 },
    { lat: 48.2020497, lng: 17.410852 },
    { lat: 48.2021026, lng: 17.4107101 },
    { lat: 48.2021648, lng: 17.4105718 },
    { lat: 48.2023176, lng: 17.410264 },
    { lat: 48.2027337, lng: 17.4097327 },
    { lat: 48.2028276, lng: 17.409616 },
    { lat: 48.2027029, lng: 17.4096257 },
    { lat: 48.2027114, lng: 17.4092181 },
    { lat: 48.2027268, lng: 17.4087783 },
    { lat: 48.2026793, lng: 17.4084997 },
    { lat: 48.202615, lng: 17.4082911 },
    { lat: 48.2025788, lng: 17.4081004 },
    { lat: 48.2025015, lng: 17.4076427 },
    { lat: 48.2023737, lng: 17.4071883 },
    { lat: 48.2019047, lng: 17.4056397 },
    { lat: 48.2018099, lng: 17.4053993 },
    { lat: 48.2017038, lng: 17.4051924 },
    { lat: 48.2013072, lng: 17.4044798 },
    { lat: 48.2011401, lng: 17.4042027 },
    { lat: 48.2010713, lng: 17.4041165 },
    { lat: 48.2007944, lng: 17.4038189 },
    { lat: 48.2005199, lng: 17.4035936 },
    { lat: 48.2003754, lng: 17.4035606 },
    { lat: 48.2002349, lng: 17.4035372 },
    { lat: 48.2001538, lng: 17.4035397 },
    { lat: 48.199909, lng: 17.4035281 },
    { lat: 48.1997489, lng: 17.4035151 },
    { lat: 48.1995521, lng: 17.40351 },
    { lat: 48.1991863, lng: 17.4035481 },
    { lat: 48.1989631, lng: 17.4036398 },
    { lat: 48.1987577, lng: 17.4037297 },
    { lat: 48.1987106, lng: 17.4037695 },
    { lat: 48.1985813, lng: 17.4039337 },
    { lat: 48.1983386, lng: 17.4043518 },
    { lat: 48.198036, lng: 17.404857 },
    { lat: 48.197263, lng: 17.4059597 },
    { lat: 48.1969512, lng: 17.4063937 },
    { lat: 48.1964176, lng: 17.4072182 },
    { lat: 48.1961113, lng: 17.4074758 },
    { lat: 48.1958098, lng: 17.4078575 },
    { lat: 48.1955174, lng: 17.4080167 },
    { lat: 48.1947473, lng: 17.408471 },
    { lat: 48.1946517, lng: 17.408218 },
    { lat: 48.1945127, lng: 17.4084063 },
    { lat: 48.1943325, lng: 17.408604 },
    { lat: 48.1941625, lng: 17.4087707 },
    { lat: 48.1939317, lng: 17.4089716 },
    { lat: 48.1938263, lng: 17.4090358 },
    { lat: 48.1931988, lng: 17.4094956 },
    { lat: 48.1930044, lng: 17.4096311 },
    { lat: 48.1927727, lng: 17.4097502 },
    { lat: 48.1926715, lng: 17.4097911 },
    { lat: 48.1923794, lng: 17.4098675 },
    { lat: 48.1918378, lng: 17.4098069 },
    { lat: 48.1917622, lng: 17.4097901 },
    { lat: 48.1914859, lng: 17.4096235 },
    { lat: 48.1912952, lng: 17.4094519 },
    { lat: 48.19099, lng: 17.4090157 },
    { lat: 48.190849, lng: 17.4086368 },
    { lat: 48.1907996, lng: 17.4084505 },
    { lat: 48.1907158, lng: 17.4080133 },
    { lat: 48.1906751, lng: 17.4074062 },
    { lat: 48.1906719, lng: 17.4072699 },
    { lat: 48.1907815, lng: 17.4066626 },
    { lat: 48.1909117, lng: 17.4060086 },
    { lat: 48.1909401, lng: 17.4058751 },
    { lat: 48.190975, lng: 17.4056301 },
    { lat: 48.1910917, lng: 17.4049578 },
    { lat: 48.1913063, lng: 17.4040042 },
    { lat: 48.191369, lng: 17.4037906 },
    { lat: 48.1914616, lng: 17.4035297 },
    { lat: 48.191492, lng: 17.4034577 },
    { lat: 48.1915066, lng: 17.4033888 },
    { lat: 48.1917166, lng: 17.4028143 },
    { lat: 48.1917819, lng: 17.402672 },
    { lat: 48.1918483, lng: 17.4025468 },
    { lat: 48.1920107, lng: 17.4022992 },
    { lat: 48.1921558, lng: 17.4021531 },
    { lat: 48.1923488, lng: 17.4020464 },
    { lat: 48.1920172, lng: 17.4019149 },
    { lat: 48.191508, lng: 17.4017289 },
    { lat: 48.1911569, lng: 17.4016095 },
    { lat: 48.1907227, lng: 17.4014456 },
    { lat: 48.1903261, lng: 17.401305 },
    { lat: 48.1899082, lng: 17.4011505 },
    { lat: 48.1895801, lng: 17.401037 },
    { lat: 48.189286, lng: 17.4009254 },
    { lat: 48.1890038, lng: 17.4008254 },
    { lat: 48.1885763, lng: 17.4006614 },
    { lat: 48.1882937, lng: 17.4005708 },
    { lat: 48.1883224, lng: 17.401391 },
    { lat: 48.1883526, lng: 17.4021324 },
    { lat: 48.1883838, lng: 17.4030433 },
    { lat: 48.1884051, lng: 17.4035444 },
    { lat: 48.1884448, lng: 17.4046915 },
    { lat: 48.1884781, lng: 17.405619 },
    { lat: 48.1884974, lng: 17.406106 },
    { lat: 48.1885113, lng: 17.4065957 },
    { lat: 48.1885751, lng: 17.4083903 },
    { lat: 48.1886211, lng: 17.4095651 },
    { lat: 48.1886355, lng: 17.4099777 },
    { lat: 48.1881728, lng: 17.4098619 },
    { lat: 48.1877671, lng: 17.4097562 },
    { lat: 48.1872128, lng: 17.4096039 },
    { lat: 48.1868037, lng: 17.4092875 },
    { lat: 48.186414, lng: 17.4089921 },
    { lat: 48.1860973, lng: 17.4087411 },
    { lat: 48.1857398, lng: 17.4084731 },
    { lat: 48.1854024, lng: 17.4081996 },
    { lat: 48.1849419, lng: 17.4078411 },
    { lat: 48.1848541, lng: 17.4074451 },
    { lat: 48.1847974, lng: 17.4071663 },
    { lat: 48.1846876, lng: 17.4071095 },
    { lat: 48.1842909, lng: 17.406881 },
    { lat: 48.1836532, lng: 17.406523 },
    { lat: 48.1829287, lng: 17.4060467 },
    { lat: 48.182626, lng: 17.4058407 },
    { lat: 48.1821406, lng: 17.4054809 },
    { lat: 48.1815793, lng: 17.4050476 },
    { lat: 48.1810827, lng: 17.4046546 },
    { lat: 48.1808922, lng: 17.4044535 },
    { lat: 48.1807631, lng: 17.4043608 },
    { lat: 48.1802839, lng: 17.4041132 },
    { lat: 48.1796036, lng: 17.4038323 },
    { lat: 48.1776477, lng: 17.4030061 },
    { lat: 48.1774084, lng: 17.4029219 },
    { lat: 48.1774094, lng: 17.402763 },
    { lat: 48.1774053, lng: 17.4027526 },
    { lat: 48.1772218, lng: 17.4027617 },
    { lat: 48.1768572, lng: 17.4026789 },
    { lat: 48.1766741, lng: 17.4025899 },
    { lat: 48.1764594, lng: 17.4024667 },
    { lat: 48.1761041, lng: 17.4021814 },
    { lat: 48.1760496, lng: 17.4021059 },
    { lat: 48.1759346, lng: 17.4019192 },
    { lat: 48.1758872, lng: 17.4017826 },
    { lat: 48.1757357, lng: 17.4016923 },
    { lat: 48.1754284, lng: 17.4016576 },
    { lat: 48.1752034, lng: 17.4016533 },
    { lat: 48.1750942, lng: 17.4016591 },
    { lat: 48.174934, lng: 17.4016968 },
    { lat: 48.1747374, lng: 17.4018327 },
    { lat: 48.1746248, lng: 17.4019429 },
    { lat: 48.1744314, lng: 17.4021998 },
    { lat: 48.1743145, lng: 17.4024669 },
    { lat: 48.174281, lng: 17.4025595 },
    { lat: 48.1742441, lng: 17.4027034 },
    { lat: 48.174053, lng: 17.4032716 },
    { lat: 48.1740613, lng: 17.4033489 },
    { lat: 48.1739679, lng: 17.4034747 },
    { lat: 48.1739734, lng: 17.4035753 },
    { lat: 48.1737885, lng: 17.403842 },
    { lat: 48.1737297, lng: 17.4039105 },
    { lat: 48.173496, lng: 17.4041371 },
    { lat: 48.1733076, lng: 17.4042263 },
    { lat: 48.173001, lng: 17.4043401 },
    { lat: 48.1725519, lng: 17.4043642 },
    { lat: 48.1723951, lng: 17.4043516 },
    { lat: 48.1720565, lng: 17.4042844 },
    { lat: 48.1718808, lng: 17.4043632 },
    { lat: 48.1717048, lng: 17.4044851 },
    { lat: 48.1714336, lng: 17.4046918 },
    { lat: 48.1713569, lng: 17.4047798 },
    { lat: 48.1717333, lng: 17.4053758 },
    { lat: 48.1720701, lng: 17.4058984 },
    { lat: 48.1726924, lng: 17.4069712 },
    { lat: 48.1730087, lng: 17.407441 },
    { lat: 48.1735221, lng: 17.4080037 },
    { lat: 48.1739396, lng: 17.40843 },
    { lat: 48.1743091, lng: 17.4088145 },
    { lat: 48.1746493, lng: 17.4091652 },
    { lat: 48.1759173, lng: 17.4101474 },
    { lat: 48.1762198, lng: 17.4103879 },
    { lat: 48.176355, lng: 17.4104333 },
    { lat: 48.1764745, lng: 17.4105003 },
    { lat: 48.1768501, lng: 17.41077 },
    { lat: 48.1773233, lng: 17.4111163 },
    { lat: 48.1776586, lng: 17.4114948 },
    { lat: 48.1777469, lng: 17.4115505 },
    { lat: 48.17828, lng: 17.4118613 },
    { lat: 48.1792267, lng: 17.412594 },
    { lat: 48.1794213, lng: 17.4127016 },
    { lat: 48.1795922, lng: 17.412811 },
    { lat: 48.1797092, lng: 17.4128556 },
    { lat: 48.1797152, lng: 17.4129308 },
    { lat: 48.1802373, lng: 17.4132314 },
    { lat: 48.1806155, lng: 17.4133832 },
    { lat: 48.1807021, lng: 17.4132929 },
    { lat: 48.180861, lng: 17.4133922 },
    { lat: 48.1808821, lng: 17.4135066 },
    { lat: 48.1815259, lng: 17.413836 },
    { lat: 48.1817498, lng: 17.4139452 },
    { lat: 48.1822731, lng: 17.4142102 },
    { lat: 48.1825796, lng: 17.414408 },
    { lat: 48.1831703, lng: 17.4148059 },
    { lat: 48.1837976, lng: 17.4152812 },
    { lat: 48.1844323, lng: 17.4157665 },
    { lat: 48.1844306, lng: 17.4158276 },
    { lat: 48.1848486, lng: 17.4162286 },
    { lat: 48.1853676, lng: 17.416741 },
    { lat: 48.1858101, lng: 17.4171867 },
    { lat: 48.1864851, lng: 17.4179981 },
    { lat: 48.1867237, lng: 17.4182784 },
    { lat: 48.1870361, lng: 17.4186534 },
    { lat: 48.1869758, lng: 17.4187703 },
    { lat: 48.187297, lng: 17.4191931 },
    { lat: 48.1883063, lng: 17.4204888 },
    { lat: 48.1886539, lng: 17.4210938 },
    { lat: 48.1889923, lng: 17.4216747 },
  ],
  Malinovo: [
    { lat: 48.1207147, lng: 17.3342012 },
    { lat: 48.121017, lng: 17.3341237 },
    { lat: 48.1212596, lng: 17.3340856 },
    { lat: 48.1215673, lng: 17.3340236 },
    { lat: 48.1216541, lng: 17.3339695 },
    { lat: 48.1221386, lng: 17.3333001 },
    { lat: 48.1225684, lng: 17.3327588 },
    { lat: 48.1227885, lng: 17.3324995 },
    { lat: 48.1230344, lng: 17.3322203 },
    { lat: 48.1232092, lng: 17.3320092 },
    { lat: 48.1233857, lng: 17.3317748 },
    { lat: 48.1234849, lng: 17.3316522 },
    { lat: 48.1242037, lng: 17.3307301 },
    { lat: 48.1244018, lng: 17.3304807 },
    { lat: 48.1246566, lng: 17.330148 },
    { lat: 48.1248642, lng: 17.3298518 },
    { lat: 48.1250609, lng: 17.329563 },
    { lat: 48.1252818, lng: 17.3292293 },
    { lat: 48.1254745, lng: 17.3289657 },
    { lat: 48.125574, lng: 17.328676 },
    { lat: 48.1256305, lng: 17.3284357 },
    { lat: 48.1262294, lng: 17.3288851 },
    { lat: 48.1266104, lng: 17.3290449 },
    { lat: 48.1267739, lng: 17.3292798 },
    { lat: 48.1269659, lng: 17.3295014 },
    { lat: 48.1271323, lng: 17.3296617 },
    { lat: 48.1276352, lng: 17.3300771 },
    { lat: 48.1280162, lng: 17.330442 },
    { lat: 48.128377, lng: 17.3308083 },
    { lat: 48.129011, lng: 17.3314988 },
    { lat: 48.1292213, lng: 17.3317412 },
    { lat: 48.1295435, lng: 17.3320718 },
    { lat: 48.1297418, lng: 17.3322864 },
    { lat: 48.1299263, lng: 17.3324467 },
    { lat: 48.1300947, lng: 17.3325342 },
    { lat: 48.1309582, lng: 17.3326106 },
    { lat: 48.1322937, lng: 17.3307115 },
    { lat: 48.1327478, lng: 17.3300947 },
    { lat: 48.1330447, lng: 17.3296976 },
    { lat: 48.1334973, lng: 17.3290741 },
    { lat: 48.1336584, lng: 17.3292571 },
    { lat: 48.1340635, lng: 17.3297299 },
    { lat: 48.1342525, lng: 17.3299586 },
    { lat: 48.1345593, lng: 17.3303625 },
    { lat: 48.134584, lng: 17.3303098 },
    { lat: 48.1346458, lng: 17.3302189 },
    { lat: 48.1347413, lng: 17.3301087 },
    { lat: 48.1339788, lng: 17.328583 },
    { lat: 48.1336747, lng: 17.3279612 },
    { lat: 48.1331952, lng: 17.3269762 },
    { lat: 48.1324252, lng: 17.3254224 },
    { lat: 48.1323428, lng: 17.3254759 },
    { lat: 48.1321153, lng: 17.3255532 },
    { lat: 48.1316422, lng: 17.325471 },
    { lat: 48.1314619, lng: 17.3254443 },
    { lat: 48.1312998, lng: 17.3253145 },
    { lat: 48.130689, lng: 17.3248586 },
    { lat: 48.1305067, lng: 17.3247479 },
    { lat: 48.1303141, lng: 17.3246539 },
    { lat: 48.1300542, lng: 17.324704 },
    { lat: 48.1295087, lng: 17.3245337 },
    { lat: 48.1295098, lng: 17.3241227 },
    { lat: 48.1294846, lng: 17.3235004 },
    { lat: 48.1294865, lng: 17.3233583 },
    { lat: 48.129464, lng: 17.3226918 },
    { lat: 48.1294215, lng: 17.3222357 },
    { lat: 48.1294066, lng: 17.3219418 },
    { lat: 48.1294095, lng: 17.3217971 },
    { lat: 48.1294372, lng: 17.3212571 },
    { lat: 48.1294707, lng: 17.3207385 },
    { lat: 48.1295585, lng: 17.319671 },
    { lat: 48.1295739, lng: 17.3193927 },
    { lat: 48.1296056, lng: 17.3190675 },
    { lat: 48.129652, lng: 17.318487 },
    { lat: 48.1296697, lng: 17.3181225 },
    { lat: 48.1296683, lng: 17.3179295 },
    { lat: 48.1296005, lng: 17.3174324 },
    { lat: 48.1295941, lng: 17.3173016 },
    { lat: 48.1296377, lng: 17.3170548 },
    { lat: 48.1296585, lng: 17.3166509 },
    { lat: 48.1296694, lng: 17.3162865 },
    { lat: 48.1296385, lng: 17.3153049 },
    { lat: 48.1287654, lng: 17.3151685 },
    { lat: 48.1284282, lng: 17.3151263 },
    { lat: 48.1277918, lng: 17.3150006 },
    { lat: 48.1276452, lng: 17.3149522 },
    { lat: 48.1275242, lng: 17.3148815 },
    { lat: 48.1272943, lng: 17.3147358 },
    { lat: 48.1269003, lng: 17.3144319 },
    { lat: 48.126267, lng: 17.3139084 },
    { lat: 48.1259866, lng: 17.3137152 },
    { lat: 48.1254565, lng: 17.3134044 },
    { lat: 48.1256824, lng: 17.3120357 },
    { lat: 48.124949, lng: 17.3117396 },
    { lat: 48.1231082, lng: 17.3109784 },
    { lat: 48.1224146, lng: 17.3107036 },
    { lat: 48.1213272, lng: 17.3102796 },
    { lat: 48.121166, lng: 17.310926 },
    { lat: 48.12104, lng: 17.311846 },
    { lat: 48.121032, lng: 17.312318 },
    { lat: 48.121145, lng: 17.313181 },
    { lat: 48.121474, lng: 17.314637 },
    { lat: 48.121762, lng: 17.315358 },
    { lat: 48.121754, lng: 17.31587 },
    { lat: 48.121629, lng: 17.316164 },
    { lat: 48.121401, lng: 17.316432 },
    { lat: 48.120433, lng: 17.31694 },
    { lat: 48.120129, lng: 17.317159 },
    { lat: 48.11993, lng: 17.317413 },
    { lat: 48.119825, lng: 17.317894 },
    { lat: 48.120011, lng: 17.319227 },
    { lat: 48.11996, lng: 17.319769 },
    { lat: 48.119766, lng: 17.320361 },
    { lat: 48.119315, lng: 17.321298 },
    { lat: 48.119508, lng: 17.321474 },
    { lat: 48.119446, lng: 17.321911 },
    { lat: 48.119281, lng: 17.322825 },
    { lat: 48.118562, lng: 17.326091 },
    { lat: 48.118361, lng: 17.327411 },
    { lat: 48.118236, lng: 17.329764 },
    { lat: 48.118664, lng: 17.329942 },
    { lat: 48.118627, lng: 17.330188 },
    { lat: 48.119725, lng: 17.330618 },
    { lat: 48.119164, lng: 17.333081 },
    { lat: 48.12073, lng: 17.334159 },
    { lat: 48.1207147, lng: 17.3342012 },
  ],
  MalinovoExt: [
    { lat: 48.177132, lng: 17.2990866 },
    { lat: 48.1770842, lng: 17.2993386 },
    { lat: 48.176927, lng: 17.2992664 },
    { lat: 48.1768344, lng: 17.2992674 },
    { lat: 48.176671, lng: 17.2991894 },
    { lat: 48.1765044, lng: 17.2991294 },
    { lat: 48.1761679, lng: 17.2990449 },
    { lat: 48.1760133, lng: 17.2989445 },
    { lat: 48.1759124, lng: 17.2989062 },
    { lat: 48.1757424, lng: 17.2988115 },
    { lat: 48.1755985, lng: 17.2986857 },
    { lat: 48.175403, lng: 17.2985635 },
    { lat: 48.1752428, lng: 17.2984479 },
    { lat: 48.1750818, lng: 17.2983526 },
    { lat: 48.1749319, lng: 17.2982387 },
    { lat: 48.1748102, lng: 17.2980227 },
    { lat: 48.1746646, lng: 17.2978696 },
    { lat: 48.1744206, lng: 17.2976464 },
    { lat: 48.1741202, lng: 17.2973927 },
    { lat: 48.1739734, lng: 17.2972734 },
    { lat: 48.1738332, lng: 17.2971007 },
    { lat: 48.1736767, lng: 17.2969897 },
    { lat: 48.1735167, lng: 17.296911 },
    { lat: 48.1733394, lng: 17.2968773 },
    { lat: 48.1731707, lng: 17.2968196 },
    { lat: 48.1730093, lng: 17.2967877 },
    { lat: 48.1728388, lng: 17.2967909 },
    { lat: 48.1726885, lng: 17.2969761 },
    { lat: 48.1725175, lng: 17.2970377 },
    { lat: 48.1721821, lng: 17.2972435 },
    { lat: 48.1720081, lng: 17.2972793 },
    { lat: 48.1718884, lng: 17.2973814 },
    { lat: 48.1717878, lng: 17.2974318 },
    { lat: 48.1714508, lng: 17.2975786 },
    { lat: 48.1712315, lng: 17.2976111 },
    { lat: 48.1711079, lng: 17.2976387 },
    { lat: 48.1709629, lng: 17.2976507 },
    { lat: 48.1708056, lng: 17.2976285 },
    { lat: 48.1704642, lng: 17.2975227 },
    { lat: 48.1701126, lng: 17.297399 },
    { lat: 48.1699573, lng: 17.2973062 },
    { lat: 48.1696259, lng: 17.2971797 },
    { lat: 48.1693703, lng: 17.2970207 },
    { lat: 48.1691517, lng: 17.2967719 },
    { lat: 48.1688289, lng: 17.2964194 },
    { lat: 48.1687618, lng: 17.2962579 },
    { lat: 48.1685817, lng: 17.2960118 },
    { lat: 48.1684315, lng: 17.2957393 },
    { lat: 48.1682802, lng: 17.2953809 },
    { lat: 48.1681781, lng: 17.2950515 },
    { lat: 48.1681692, lng: 17.2950022 },
    { lat: 48.1681074, lng: 17.2950568 },
    { lat: 48.1680258, lng: 17.2946771 },
    { lat: 48.1679261, lng: 17.2942301 },
    { lat: 48.1678931, lng: 17.293833 },
    { lat: 48.1678777, lng: 17.2929343 },
    { lat: 48.1677642, lng: 17.2917488 },
    { lat: 48.1676598, lng: 17.2910753 },
    { lat: 48.1677002, lng: 17.2907813 },
    { lat: 48.1677271, lng: 17.2901821 },
    { lat: 48.1676336, lng: 17.2890416 },
    { lat: 48.1675189, lng: 17.288146 },
    { lat: 48.1674324, lng: 17.2876749 },
    { lat: 48.1673802, lng: 17.2874158 },
    { lat: 48.1672367, lng: 17.2868503 },
    { lat: 48.1669568, lng: 17.2860548 },
    { lat: 48.1667756, lng: 17.2855541 },
    { lat: 48.1666289, lng: 17.2852472 },
    { lat: 48.1662181, lng: 17.284403 },
    { lat: 48.1657384, lng: 17.2838272 },
    { lat: 48.1650412, lng: 17.2832626 },
    { lat: 48.164883, lng: 17.2831248 },
    { lat: 48.1647097, lng: 17.2830661 },
    { lat: 48.1640831, lng: 17.2825449 },
    { lat: 48.1636604, lng: 17.2821997 },
    { lat: 48.1630917, lng: 17.2818694 },
    { lat: 48.1628787, lng: 17.2817228 },
    { lat: 48.1625159, lng: 17.2814997 },
    { lat: 48.1619859, lng: 17.281189 },
    { lat: 48.161521, lng: 17.2809935 },
    { lat: 48.161315, lng: 17.2809853 },
    { lat: 48.1608362, lng: 17.2810063 },
    { lat: 48.1606208, lng: 17.2810619 },
    { lat: 48.1605018, lng: 17.2811252 },
    { lat: 48.1601264, lng: 17.2813918 },
    { lat: 48.1599891, lng: 17.2815708 },
    { lat: 48.1594936, lng: 17.2820207 },
    { lat: 48.1588999, lng: 17.2825562 },
    { lat: 48.1587668, lng: 17.2826433 },
    { lat: 48.1583558, lng: 17.2828527 },
    { lat: 48.1580381, lng: 17.2829674 },
    { lat: 48.1579652, lng: 17.2830102 },
    { lat: 48.1576644, lng: 17.2832084 },
    { lat: 48.1575626, lng: 17.2832589 },
    { lat: 48.157359, lng: 17.2834081 },
    { lat: 48.1571943, lng: 17.2835481 },
    { lat: 48.1571051, lng: 17.2836339 },
    { lat: 48.1567686, lng: 17.2839927 },
    { lat: 48.1565314, lng: 17.2841993 },
    { lat: 48.1563859, lng: 17.2843558 },
    { lat: 48.1561305, lng: 17.2845598 },
    { lat: 48.1559888, lng: 17.2847211 },
    { lat: 48.1556046, lng: 17.284891 },
    { lat: 48.1552274, lng: 17.284995 },
    { lat: 48.1549953, lng: 17.2850546 },
    { lat: 48.1548316, lng: 17.2851936 },
    { lat: 48.1546848, lng: 17.285348 },
    { lat: 48.1541058, lng: 17.2854404 },
    { lat: 48.1537807, lng: 17.2854953 },
    { lat: 48.1536101, lng: 17.2855288 },
    { lat: 48.1534713, lng: 17.2855469 },
    { lat: 48.1532404, lng: 17.2855415 },
    { lat: 48.1527919, lng: 17.2855572 },
    { lat: 48.1525028, lng: 17.285524 },
    { lat: 48.1523136, lng: 17.2855166 },
    { lat: 48.1519661, lng: 17.2854734 },
    { lat: 48.1518011, lng: 17.2854708 },
    { lat: 48.1511772, lng: 17.2852787 },
    { lat: 48.1508098, lng: 17.2851381 },
    { lat: 48.1504198, lng: 17.2849974 },
    { lat: 48.1496688, lng: 17.2844907 },
    { lat: 48.1494214, lng: 17.2843383 },
    { lat: 48.1491505, lng: 17.2841868 },
    { lat: 48.1486233, lng: 17.2839187 },
    { lat: 48.1485141, lng: 17.2838556 },
    { lat: 48.1484539, lng: 17.2838021 },
    { lat: 48.1483072, lng: 17.2837173 },
    { lat: 48.1480266, lng: 17.2835638 },
    { lat: 48.1474489, lng: 17.2851012 },
    { lat: 48.1467954, lng: 17.2868167 },
    { lat: 48.1462224, lng: 17.288311 },
    { lat: 48.1459769, lng: 17.2889541 },
    { lat: 48.1459354, lng: 17.2890277 },
    { lat: 48.1454365, lng: 17.2904166 },
    { lat: 48.1444857, lng: 17.292997 },
    { lat: 48.1438629, lng: 17.2946791 },
    { lat: 48.1446632, lng: 17.295901 },
    { lat: 48.1453215, lng: 17.2969034 },
    { lat: 48.1453156, lng: 17.2986074 },
    { lat: 48.1453152, lng: 17.2990952 },
    { lat: 48.1453075, lng: 17.3014241 },
    { lat: 48.1454217, lng: 17.3013034 },
    { lat: 48.145696, lng: 17.3020295 },
    { lat: 48.1463266, lng: 17.3036983 },
    { lat: 48.1467715, lng: 17.304924 },
    { lat: 48.1470547, lng: 17.3056729 },
    { lat: 48.1474301, lng: 17.3066868 },
    { lat: 48.1476717, lng: 17.3073349 },
    { lat: 48.1479595, lng: 17.3071945 },
    { lat: 48.1482101, lng: 17.3081315 },
    { lat: 48.148708, lng: 17.3093916 },
    { lat: 48.1487578, lng: 17.3095135 },
    { lat: 48.1492929, lng: 17.3108769 },
    { lat: 48.1496675, lng: 17.3118126 },
    { lat: 48.1498708, lng: 17.3118122 },
    { lat: 48.1502059, lng: 17.3118033 },
    { lat: 48.150797, lng: 17.3117929 },
    { lat: 48.1518105, lng: 17.3117092 },
    { lat: 48.151913, lng: 17.3117059 },
    { lat: 48.1508646, lng: 17.3142187 },
    { lat: 48.1507285, lng: 17.314552 },
    { lat: 48.1516115, lng: 17.3144615 },
    { lat: 48.1522472, lng: 17.3143906 },
    { lat: 48.1524236, lng: 17.3143755 },
    { lat: 48.1526541, lng: 17.3143424 },
    { lat: 48.1528967, lng: 17.3143201 },
    { lat: 48.1531829, lng: 17.3143092 },
    { lat: 48.1543588, lng: 17.3141721 },
    { lat: 48.154605, lng: 17.3141206 },
    { lat: 48.1548856, lng: 17.314075 },
    { lat: 48.155196, lng: 17.3140369 },
    { lat: 48.1554239, lng: 17.3140008 },
    { lat: 48.1556965, lng: 17.3139707 },
    { lat: 48.1559503, lng: 17.3139317 },
    { lat: 48.1559634, lng: 17.3139575 },
    { lat: 48.1559927, lng: 17.3139565 },
    { lat: 48.1573582, lng: 17.3138152 },
    { lat: 48.1575603, lng: 17.3137972 },
    { lat: 48.1575649, lng: 17.3139881 },
    { lat: 48.1576226, lng: 17.3141772 },
    { lat: 48.1577454, lng: 17.314446 },
    { lat: 48.1579331, lng: 17.314654 },
    { lat: 48.1581235, lng: 17.3147998 },
    { lat: 48.1581702, lng: 17.3149084 },
    { lat: 48.1582156, lng: 17.3150816 },
    { lat: 48.1582235, lng: 17.3153058 },
    { lat: 48.1582835, lng: 17.3157182 },
    { lat: 48.1583028, lng: 17.3159087 },
    { lat: 48.1583551, lng: 17.3161702 },
    { lat: 48.1583996, lng: 17.3164187 },
    { lat: 48.1585285, lng: 17.3170868 },
    { lat: 48.1585212, lng: 17.3171639 },
    { lat: 48.1586072, lng: 17.3176129 },
    { lat: 48.1586763, lng: 17.3181792 },
    { lat: 48.1586695, lng: 17.3184716 },
    { lat: 48.1587052, lng: 17.3187382 },
    { lat: 48.1587929, lng: 17.3189708 },
    { lat: 48.1590284, lng: 17.3192544 },
    { lat: 48.159184, lng: 17.3195076 },
    { lat: 48.1592921, lng: 17.3196723 },
    { lat: 48.1597758, lng: 17.3201388 },
    { lat: 48.1600145, lng: 17.3204682 },
    { lat: 48.1603171, lng: 17.3206727 },
    { lat: 48.1608828, lng: 17.3209776 },
    { lat: 48.1611039, lng: 17.3210921 },
    { lat: 48.1614634, lng: 17.3211488 },
    { lat: 48.1618305, lng: 17.3210813 },
    { lat: 48.1619909, lng: 17.3210458 },
    { lat: 48.1622221, lng: 17.3209542 },
    { lat: 48.1626615, lng: 17.320904 },
    { lat: 48.1628944, lng: 17.3208527 },
    { lat: 48.1632228, lng: 17.3207967 },
    { lat: 48.1635259, lng: 17.3207335 },
    { lat: 48.1639539, lng: 17.3206114 },
    { lat: 48.1644266, lng: 17.3202953 },
    { lat: 48.1645336, lng: 17.3202567 },
    { lat: 48.1645871, lng: 17.3200917 },
    { lat: 48.164692, lng: 17.3197976 },
    { lat: 48.1644583, lng: 17.3197147 },
    { lat: 48.164165, lng: 17.3194278 },
    { lat: 48.1640321, lng: 17.3192643 },
    { lat: 48.1639239, lng: 17.3190784 },
    { lat: 48.1638473, lng: 17.3188938 },
    { lat: 48.1637383, lng: 17.3185773 },
    { lat: 48.1636689, lng: 17.3183646 },
    { lat: 48.1635601, lng: 17.317983 },
    { lat: 48.163385, lng: 17.3173485 },
    { lat: 48.1633175, lng: 17.3170445 },
    { lat: 48.1632916, lng: 17.3168728 },
    { lat: 48.1632768, lng: 17.3165296 },
    { lat: 48.1632743, lng: 17.3161652 },
    { lat: 48.1632908, lng: 17.3157322 },
    { lat: 48.1633749, lng: 17.3151222 },
    { lat: 48.1634266, lng: 17.314863 },
    { lat: 48.1634981, lng: 17.3145754 },
    { lat: 48.1635596, lng: 17.3143098 },
    { lat: 48.1636554, lng: 17.3138626 },
    { lat: 48.1637337, lng: 17.3133014 },
    { lat: 48.1637737, lng: 17.3128978 },
    { lat: 48.163785, lng: 17.3127088 },
    { lat: 48.1638716, lng: 17.3123784 },
    { lat: 48.1639338, lng: 17.3122267 },
    { lat: 48.1641073, lng: 17.3119613 },
    { lat: 48.1643382, lng: 17.3117127 },
    { lat: 48.1644495, lng: 17.3116195 },
    { lat: 48.1646777, lng: 17.3114482 },
    { lat: 48.1647659, lng: 17.3113989 },
    { lat: 48.1650197, lng: 17.3112898 },
    { lat: 48.1652737, lng: 17.3111855 },
    { lat: 48.1655979, lng: 17.3110828 },
    { lat: 48.1657759, lng: 17.3110397 },
    { lat: 48.1659828, lng: 17.3110607 },
    { lat: 48.1666394, lng: 17.3112011 },
    { lat: 48.1669948, lng: 17.3113993 },
    { lat: 48.167386, lng: 17.3117318 },
    { lat: 48.1676017, lng: 17.3120377 },
    { lat: 48.1677514, lng: 17.312269 },
    { lat: 48.1678646, lng: 17.3125292 },
    { lat: 48.167902, lng: 17.3126351 },
    { lat: 48.1680355, lng: 17.3129624 },
    { lat: 48.1680748, lng: 17.3130967 },
    { lat: 48.1681468, lng: 17.3133817 },
    { lat: 48.1682147, lng: 17.3136764 },
    { lat: 48.1682682, lng: 17.3138872 },
    { lat: 48.1683054, lng: 17.314211 },
    { lat: 48.1683834, lng: 17.3148463 },
    { lat: 48.168445, lng: 17.3155052 },
    { lat: 48.1684583, lng: 17.3157244 },
    { lat: 48.1685042, lng: 17.3161456 },
    { lat: 48.1685497, lng: 17.3164243 },
    { lat: 48.1686414, lng: 17.3168886 },
    { lat: 48.1687544, lng: 17.317404 },
    { lat: 48.168808, lng: 17.3175844 },
    { lat: 48.1689875, lng: 17.3181135 },
    { lat: 48.1690531, lng: 17.3182862 },
    { lat: 48.1693048, lng: 17.3187971 },
    { lat: 48.1693633, lng: 17.3187083 },
    { lat: 48.169621, lng: 17.3193264 },
    { lat: 48.1696778, lng: 17.3194497 },
    { lat: 48.1697665, lng: 17.3195277 },
    { lat: 48.1700034, lng: 17.3197115 },
    { lat: 48.1702816, lng: 17.3200412 },
    { lat: 48.1705656, lng: 17.3202881 },
    { lat: 48.1710089, lng: 17.3206214 },
    { lat: 48.1712696, lng: 17.3207737 },
    { lat: 48.1714369, lng: 17.3208428 },
    { lat: 48.1715877, lng: 17.3209195 },
    { lat: 48.1718332, lng: 17.3210631 },
    { lat: 48.1720165, lng: 17.321151 },
    { lat: 48.1720684, lng: 17.3212895 },
    { lat: 48.172243, lng: 17.3213674 },
    { lat: 48.1724482, lng: 17.3214986 },
    { lat: 48.1726681, lng: 17.3215286 },
    { lat: 48.1727885, lng: 17.3215548 },
    { lat: 48.173068, lng: 17.3215431 },
    { lat: 48.1732164, lng: 17.321517 },
    { lat: 48.1734592, lng: 17.3214998 },
    { lat: 48.1737857, lng: 17.3214146 },
    { lat: 48.1739262, lng: 17.3213703 },
    { lat: 48.1741038, lng: 17.3212707 },
    { lat: 48.1742981, lng: 17.3212016 },
    { lat: 48.1744508, lng: 17.3211688 },
    { lat: 48.1748371, lng: 17.3209811 },
    { lat: 48.1749458, lng: 17.3209329 },
    { lat: 48.1752134, lng: 17.3206741 },
    { lat: 48.1754729, lng: 17.3204297 },
    { lat: 48.1755622, lng: 17.3203287 },
    { lat: 48.1756205, lng: 17.3201335 },
    { lat: 48.1756249, lng: 17.320028 },
    { lat: 48.1756571, lng: 17.3198493 },
    { lat: 48.1757379, lng: 17.3196019 },
    { lat: 48.1757939, lng: 17.3194045 },
    { lat: 48.1758531, lng: 17.3191712 },
    { lat: 48.1759771, lng: 17.3186208 },
    { lat: 48.1759999, lng: 17.3184849 },
    { lat: 48.1760474, lng: 17.3181444 },
    { lat: 48.1760581, lng: 17.3180136 },
    { lat: 48.1760447, lng: 17.3176921 },
    { lat: 48.1759905, lng: 17.3173834 },
    { lat: 48.1759462, lng: 17.3171933 },
    { lat: 48.1758486, lng: 17.3168766 },
    { lat: 48.1757237, lng: 17.3166264 },
    { lat: 48.1756056, lng: 17.3164293 },
    { lat: 48.1754158, lng: 17.3162234 },
    { lat: 48.1751578, lng: 17.3159778 },
    { lat: 48.1748621, lng: 17.3157073 },
    { lat: 48.1747194, lng: 17.3155645 },
    { lat: 48.1746066, lng: 17.3154309 },
    { lat: 48.1744175, lng: 17.3151825 },
    { lat: 48.1741967, lng: 17.3149001 },
    { lat: 48.1740567, lng: 17.3146295 },
    { lat: 48.1739196, lng: 17.3143491 },
    { lat: 48.173804, lng: 17.3140174 },
    { lat: 48.1737278, lng: 17.3137533 },
    { lat: 48.1736708, lng: 17.3135219 },
    { lat: 48.1736098, lng: 17.3132489 },
    { lat: 48.173584, lng: 17.3130465 },
    { lat: 48.1735467, lng: 17.3128731 },
    { lat: 48.173477, lng: 17.3124688 },
    { lat: 48.1734672, lng: 17.3123899 },
    { lat: 48.1735441, lng: 17.3116356 },
    { lat: 48.1735632, lng: 17.3113449 },
    { lat: 48.1736271, lng: 17.310892 },
    { lat: 48.1736378, lng: 17.3107638 },
    { lat: 48.1737162, lng: 17.3104271 },
    { lat: 48.1737876, lng: 17.3101582 },
    { lat: 48.1740512, lng: 17.3096143 },
    { lat: 48.1741759, lng: 17.3093831 },
    { lat: 48.1742784, lng: 17.3091738 },
    { lat: 48.1744101, lng: 17.3088589 },
    { lat: 48.1744746, lng: 17.308655 },
    { lat: 48.17474, lng: 17.307866 },
    { lat: 48.174913, lng: 17.3073904 },
    { lat: 48.1749898, lng: 17.3072195 },
    { lat: 48.1750601, lng: 17.3071027 },
    { lat: 48.1752663, lng: 17.3067007 },
    { lat: 48.1755658, lng: 17.3062435 },
    { lat: 48.1756685, lng: 17.3061025 },
    { lat: 48.1758033, lng: 17.3059391 },
    { lat: 48.1760643, lng: 17.3056843 },
    { lat: 48.1761701, lng: 17.305608 },
    { lat: 48.1763205, lng: 17.3054228 },
    { lat: 48.1764695, lng: 17.305285 },
    { lat: 48.1766862, lng: 17.3050435 },
    { lat: 48.1767538, lng: 17.3049542 },
    { lat: 48.1769791, lng: 17.3047224 },
    { lat: 48.1773896, lng: 17.3042682 },
    { lat: 48.1775449, lng: 17.3040713 },
    { lat: 48.1778014, lng: 17.3037962 },
    { lat: 48.1779428, lng: 17.3036302 },
    { lat: 48.1780752, lng: 17.303462 },
    { lat: 48.1782262, lng: 17.3032183 },
    { lat: 48.1784096, lng: 17.3029498 },
    { lat: 48.1785362, lng: 17.3027115 },
    { lat: 48.1785762, lng: 17.3025654 },
    { lat: 48.1786061, lng: 17.302316 },
    { lat: 48.1786311, lng: 17.301994 },
    { lat: 48.1786183, lng: 17.3016639 },
    { lat: 48.178592, lng: 17.3012489 },
    { lat: 48.1784965, lng: 17.3007107 },
    { lat: 48.1783631, lng: 17.3002963 },
    { lat: 48.178114, lng: 17.2998949 },
    { lat: 48.1779948, lng: 17.299682 },
    { lat: 48.1778985, lng: 17.2995746 },
    { lat: 48.1776682, lng: 17.2994244 },
    { lat: 48.1774168, lng: 17.2992438 },
    { lat: 48.177132, lng: 17.2990866 },
  ],
  Miloslavov: [
    { lat: 48.085072, lng: 17.34971 },
    { lat: 48.092173, lng: 17.353677 },
    { lat: 48.092794, lng: 17.351338 },
    { lat: 48.091478, lng: 17.348268 },
    { lat: 48.096056, lng: 17.343434 },
    { lat: 48.08884, lng: 17.326551 },
    { lat: 48.090314, lng: 17.324541 },
    { lat: 48.093704, lng: 17.320492 },
    { lat: 48.097554, lng: 17.316259 },
    { lat: 48.098624, lng: 17.315269 },
    { lat: 48.098064, lng: 17.314628 },
    { lat: 48.097463, lng: 17.312482 },
    { lat: 48.099408, lng: 17.307123 },
    { lat: 48.115333, lng: 17.313432 },
    { lat: 48.116171, lng: 17.311851 },
    { lat: 48.118502, lng: 17.307114 },
    { lat: 48.118759, lng: 17.306549 },
    { lat: 48.119492, lng: 17.304567 },
    { lat: 48.1198, lng: 17.304111 },
    { lat: 48.120175, lng: 17.303723 },
    { lat: 48.120495, lng: 17.303488 },
    { lat: 48.120743, lng: 17.30337 },
    { lat: 48.121434, lng: 17.303309 },
    { lat: 48.1203952, lng: 17.3024384 },
    { lat: 48.1197295, lng: 17.3019563 },
    { lat: 48.1193329, lng: 17.3016647 },
    { lat: 48.1188238, lng: 17.3012937 },
    { lat: 48.1184476, lng: 17.3009181 },
    { lat: 48.1181097, lng: 17.3005742 },
    { lat: 48.1176123, lng: 17.3000747 },
    { lat: 48.1169277, lng: 17.2993736 },
    { lat: 48.1168649, lng: 17.2992944 },
    { lat: 48.1158011, lng: 17.2978665 },
    { lat: 48.1155414, lng: 17.2973735 },
    { lat: 48.1151186, lng: 17.296584 },
    { lat: 48.1147718, lng: 17.2959183 },
    { lat: 48.1140977, lng: 17.2946092 },
    { lat: 48.1140155, lng: 17.294408 },
    { lat: 48.1136348, lng: 17.293452 },
    { lat: 48.113267, lng: 17.2925212 },
    { lat: 48.1126497, lng: 17.2925138 },
    { lat: 48.1120057, lng: 17.2924781 },
    { lat: 48.1099228, lng: 17.2925497 },
    { lat: 48.1080184, lng: 17.2926202 },
    { lat: 48.1076966, lng: 17.2926568 },
    { lat: 48.1072653, lng: 17.2926943 },
    { lat: 48.1064473, lng: 17.2927765 },
    { lat: 48.1057058, lng: 17.2928386 },
    { lat: 48.1054264, lng: 17.2928673 },
    { lat: 48.10515, lng: 17.2928896 },
    { lat: 48.1038401, lng: 17.2929818 },
    { lat: 48.1031461, lng: 17.2930447 },
    { lat: 48.1017705, lng: 17.2932014 },
    { lat: 48.1011296, lng: 17.2925137 },
    { lat: 48.1007907, lng: 17.2921556 },
    { lat: 48.1002235, lng: 17.2915412 },
    { lat: 48.0998373, lng: 17.2911159 },
    { lat: 48.0992518, lng: 17.2904814 },
    { lat: 48.0991724, lng: 17.2904071 },
    { lat: 48.0988197, lng: 17.2900115 },
    { lat: 48.0974025, lng: 17.2884518 },
    { lat: 48.0969555, lng: 17.2879631 },
    { lat: 48.0966325, lng: 17.2876054 },
    { lat: 48.0963329, lng: 17.2872795 },
    { lat: 48.0960805, lng: 17.286998 },
    { lat: 48.0958397, lng: 17.286741 },
    { lat: 48.0952834, lng: 17.2875332 },
    { lat: 48.0951788, lng: 17.287676 },
    { lat: 48.0948202, lng: 17.2881801 },
    { lat: 48.0941451, lng: 17.2891582 },
    { lat: 48.0939226, lng: 17.2894651 },
    { lat: 48.0936376, lng: 17.2898859 },
    { lat: 48.0932615, lng: 17.2904161 },
    { lat: 48.0927881, lng: 17.2910965 },
    { lat: 48.0907792, lng: 17.2939713 },
    { lat: 48.0894855, lng: 17.2958255 },
    { lat: 48.0878489, lng: 17.2981662 },
    { lat: 48.0876811, lng: 17.2984118 },
    { lat: 48.0875744, lng: 17.2985574 },
    { lat: 48.086924, lng: 17.2994829 },
    { lat: 48.0866134, lng: 17.2999283 },
    { lat: 48.0859299, lng: 17.3008982 },
    { lat: 48.0854491, lng: 17.3015888 },
    { lat: 48.0854044, lng: 17.3016459 },
    { lat: 48.0851384, lng: 17.30203 },
    { lat: 48.084948, lng: 17.3022949 },
    { lat: 48.0843913, lng: 17.3030986 },
    { lat: 48.0838568, lng: 17.3038652 },
    { lat: 48.083839, lng: 17.3038864 },
    { lat: 48.0833391, lng: 17.304765 },
    { lat: 48.0827302, lng: 17.3058731 },
    { lat: 48.0820763, lng: 17.3070543 },
    { lat: 48.0817989, lng: 17.3074196 },
    { lat: 48.0815502, lng: 17.3077412 },
    { lat: 48.0811348, lng: 17.3081733 },
    { lat: 48.0804728, lng: 17.3088565 },
    { lat: 48.0801542, lng: 17.3091799 },
    { lat: 48.0799822, lng: 17.309346 },
    { lat: 48.0790576, lng: 17.3101902 },
    { lat: 48.0786024, lng: 17.310602 },
    { lat: 48.0780932, lng: 17.3110665 },
    { lat: 48.0775336, lng: 17.3117768 },
    { lat: 48.077293, lng: 17.3120861 },
    { lat: 48.0774472, lng: 17.3126061 },
    { lat: 48.0765605, lng: 17.315427 },
    { lat: 48.0762304, lng: 17.3164667 },
    { lat: 48.076154, lng: 17.3167282 },
    { lat: 48.0759947, lng: 17.3172573 },
    { lat: 48.0756251, lng: 17.318452 },
    { lat: 48.0749849, lng: 17.3204745 },
    { lat: 48.0737122, lng: 17.3243621 },
    { lat: 48.0736829, lng: 17.3244491 },
    { lat: 48.070857, lng: 17.3239762 },
    { lat: 48.0708342, lng: 17.3239896 },
    { lat: 48.070831, lng: 17.3239927 },
    { lat: 48.070828, lng: 17.324001 },
    { lat: 48.069886, lng: 17.326824 },
    { lat: 48.069679, lng: 17.327558 },
    { lat: 48.069148, lng: 17.330098 },
    { lat: 48.069178, lng: 17.331638 },
    { lat: 48.080869, lng: 17.335654 },
    { lat: 48.081328, lng: 17.333831 },
    { lat: 48.0834937, lng: 17.3386651 },
    { lat: 48.0835933, lng: 17.3389046 },
    { lat: 48.084527, lng: 17.341331 },
    { lat: 48.084472, lng: 17.345245 },
    { lat: 48.085072, lng: 17.34971 },
  ],
  MostpriBratislave: [
    { lat: 48.178084, lng: 17.2616498 },
    { lat: 48.1782378, lng: 17.2608709 },
    { lat: 48.1784239, lng: 17.2600343 },
    { lat: 48.1786515, lng: 17.2590555 },
    { lat: 48.1787676, lng: 17.2582713 },
    { lat: 48.1788563, lng: 17.2575986 },
    { lat: 48.1789047, lng: 17.2571884 },
    { lat: 48.1788999, lng: 17.2564695 },
    { lat: 48.1788935, lng: 17.256153 },
    { lat: 48.1787993, lng: 17.2552971 },
    { lat: 48.1786525, lng: 17.254482 },
    { lat: 48.1784798, lng: 17.2537881 },
    { lat: 48.1783872, lng: 17.2534817 },
    { lat: 48.1781871, lng: 17.2528696 },
    { lat: 48.1779432, lng: 17.252394 },
    { lat: 48.1778851, lng: 17.2522862 },
    { lat: 48.1774605, lng: 17.25183 },
    { lat: 48.1769799, lng: 17.2515011 },
    { lat: 48.1766554, lng: 17.2513432 },
    { lat: 48.1763208, lng: 17.2512714 },
    { lat: 48.1760242, lng: 17.2512978 },
    { lat: 48.1760054, lng: 17.2509789 },
    { lat: 48.1759798, lng: 17.2508146 },
    { lat: 48.1756534, lng: 17.2509366 },
    { lat: 48.1754371, lng: 17.2510634 },
    { lat: 48.1751181, lng: 17.2513988 },
    { lat: 48.174829, lng: 17.2517559 },
    { lat: 48.174803, lng: 17.2518072 },
    { lat: 48.1744652, lng: 17.2523844 },
    { lat: 48.1743205, lng: 17.2526534 },
    { lat: 48.1739853, lng: 17.2533374 },
    { lat: 48.1736704, lng: 17.2533926 },
    { lat: 48.1734735, lng: 17.253405 },
    { lat: 48.1732277, lng: 17.2534281 },
    { lat: 48.1727844, lng: 17.2528598 },
    { lat: 48.1727259, lng: 17.2527815 },
    { lat: 48.1721356, lng: 17.2521092 },
    { lat: 48.1720291, lng: 17.2518667 },
    { lat: 48.1720229, lng: 17.2518423 },
    { lat: 48.1719765, lng: 17.2517378 },
    { lat: 48.1718329, lng: 17.2512946 },
    { lat: 48.17131, lng: 17.2503117 },
    { lat: 48.1714997, lng: 17.2491232 },
    { lat: 48.1718497, lng: 17.248611 },
    { lat: 48.1721583, lng: 17.2482061 },
    { lat: 48.1717151, lng: 17.2475746 },
    { lat: 48.1713933, lng: 17.247289 },
    { lat: 48.1711492, lng: 17.2470323 },
    { lat: 48.1706205, lng: 17.2466245 },
    { lat: 48.170482, lng: 17.2464947 },
    { lat: 48.1702508, lng: 17.2461111 },
    { lat: 48.1701622, lng: 17.2458821 },
    { lat: 48.1701047, lng: 17.2456643 },
    { lat: 48.1700142, lng: 17.2451347 },
    { lat: 48.1701783, lng: 17.244021 },
    { lat: 48.1702847, lng: 17.2435483 },
    { lat: 48.1704048, lng: 17.2431977 },
    { lat: 48.1704056, lng: 17.2431934 },
    { lat: 48.1704773, lng: 17.2429825 },
    { lat: 48.1705695, lng: 17.2424335 },
    { lat: 48.1705959, lng: 17.2423447 },
    { lat: 48.1706865, lng: 17.2419507 },
    { lat: 48.1708823, lng: 17.2409556 },
    { lat: 48.1710475, lng: 17.240415 },
    { lat: 48.171214, lng: 17.2399292 },
    { lat: 48.1715776, lng: 17.2390313 },
    { lat: 48.1718684, lng: 17.2388512 },
    { lat: 48.1723088, lng: 17.2388803 },
    { lat: 48.1725164, lng: 17.2388451 },
    { lat: 48.173235, lng: 17.238456 },
    { lat: 48.1735654, lng: 17.2380571 },
    { lat: 48.1739178, lng: 17.2375637 },
    { lat: 48.1740362, lng: 17.2373383 },
    { lat: 48.1743204, lng: 17.2366888 },
    { lat: 48.1744443, lng: 17.2363097 },
    { lat: 48.1744784, lng: 17.2360928 },
    { lat: 48.1745557, lng: 17.2355086 },
    { lat: 48.1745814, lng: 17.2352489 },
    { lat: 48.1744422, lng: 17.2339075 },
    { lat: 48.1744073, lng: 17.2337902 },
    { lat: 48.1742367, lng: 17.2332641 },
    { lat: 48.1742013, lng: 17.2330727 },
    { lat: 48.1741351, lng: 17.2328755 },
    { lat: 48.1740025, lng: 17.2328017 },
    { lat: 48.1739326, lng: 17.2326863 },
    { lat: 48.1734413, lng: 17.231757 },
    { lat: 48.1733822, lng: 17.2316492 },
    { lat: 48.1733164, lng: 17.2315612 },
    { lat: 48.1732038, lng: 17.2311085 },
    { lat: 48.1731513, lng: 17.2308468 },
    { lat: 48.1730364, lng: 17.2304459 },
    { lat: 48.1728721, lng: 17.2299468 },
    { lat: 48.1724782, lng: 17.2293935 },
    { lat: 48.1721507, lng: 17.2289212 },
    { lat: 48.171941, lng: 17.2287581 },
    { lat: 48.1716484, lng: 17.2286576 },
    { lat: 48.1714462, lng: 17.2286235 },
    { lat: 48.1710264, lng: 17.2286651 },
    { lat: 48.170979, lng: 17.228675 },
    { lat: 48.170961, lng: 17.228677 },
    { lat: 48.170944, lng: 17.228678 },
    { lat: 48.170904, lng: 17.228677 },
    { lat: 48.170826, lng: 17.228691 },
    { lat: 48.17056, lng: 17.228816 },
    { lat: 48.170251, lng: 17.228997 },
    { lat: 48.16981, lng: 17.229601 },
    { lat: 48.169719, lng: 17.229784 },
    { lat: 48.169617, lng: 17.229989 },
    { lat: 48.169457, lng: 17.230401 },
    { lat: 48.169348, lng: 17.230923 },
    { lat: 48.169365, lng: 17.231623 },
    { lat: 48.169431, lng: 17.232037 },
    { lat: 48.169524, lng: 17.232477 },
    { lat: 48.169617, lng: 17.232719 },
    { lat: 48.169751, lng: 17.23345 },
    { lat: 48.169763, lng: 17.233956 },
    { lat: 48.169835, lng: 17.234036 },
    { lat: 48.169848, lng: 17.234221 },
    { lat: 48.169791, lng: 17.234462 },
    { lat: 48.169646, lng: 17.234889 },
    { lat: 48.169511, lng: 17.235261 },
    { lat: 48.169448, lng: 17.2356 },
    { lat: 48.169306, lng: 17.235908 },
    { lat: 48.169121, lng: 17.236395 },
    { lat: 48.168967, lng: 17.236599 },
    { lat: 48.168801, lng: 17.236748 },
    { lat: 48.168538, lng: 17.236996 },
    { lat: 48.168399, lng: 17.237149 },
    { lat: 48.16814, lng: 17.237288 },
    { lat: 48.1681, lng: 17.23729 },
    { lat: 48.167921, lng: 17.237299 },
    { lat: 48.167775, lng: 17.237252 },
    { lat: 48.167626, lng: 17.237163 },
    { lat: 48.167434, lng: 17.237012 },
    { lat: 48.167275, lng: 17.236862 },
    { lat: 48.167102, lng: 17.236665 },
    { lat: 48.166831, lng: 17.236294 },
    { lat: 48.166831, lng: 17.236296 },
    { lat: 48.166645, lng: 17.235944 },
    { lat: 48.166594, lng: 17.235754 },
    { lat: 48.166563, lng: 17.235478 },
    { lat: 48.166582, lng: 17.234867 },
    { lat: 48.166544, lng: 17.234162 },
    { lat: 48.166561, lng: 17.233952 },
    { lat: 48.16661, lng: 17.233708 },
    { lat: 48.166591, lng: 17.233508 },
    { lat: 48.166605, lng: 17.233384 },
    { lat: 48.166609, lng: 17.23321 },
    { lat: 48.166648, lng: 17.233043 },
    { lat: 48.166949, lng: 17.232772 },
    { lat: 48.167056, lng: 17.232596 },
    { lat: 48.167239, lng: 17.231973 },
    { lat: 48.167348, lng: 17.231292 },
    { lat: 48.167334, lng: 17.231156 },
    { lat: 48.167325, lng: 17.231039 },
    { lat: 48.167285, lng: 17.230824 },
    { lat: 48.167278, lng: 17.230702 },
    { lat: 48.167349, lng: 17.230237 },
    { lat: 48.16738, lng: 17.230134 },
    { lat: 48.167376, lng: 17.229872 },
    { lat: 48.167359, lng: 17.229737 },
    { lat: 48.167318, lng: 17.22957 },
    { lat: 48.167146, lng: 17.229084 },
    { lat: 48.166951, lng: 17.228807 },
    { lat: 48.16683, lng: 17.228691 },
    { lat: 48.166484, lng: 17.228526 },
    { lat: 48.166055, lng: 17.228435 },
    { lat: 48.165733, lng: 17.228409 },
    { lat: 48.165417, lng: 17.228201 },
    { lat: 48.165266, lng: 17.228275 },
    { lat: 48.165101, lng: 17.227995 },
    { lat: 48.165021, lng: 17.22789 },
    { lat: 48.165008, lng: 17.227872 },
    { lat: 48.164543, lng: 17.227549 },
    { lat: 48.164442, lng: 17.22748 },
    { lat: 48.163997, lng: 17.227177 },
    { lat: 48.163859, lng: 17.228608 },
    { lat: 48.163957, lng: 17.229159 },
    { lat: 48.164095, lng: 17.229929 },
    { lat: 48.164108, lng: 17.230003 },
    { lat: 48.164145, lng: 17.230207 },
    { lat: 48.164153, lng: 17.230248 },
    { lat: 48.164156, lng: 17.230261 },
    { lat: 48.164192, lng: 17.230396 },
    { lat: 48.164246, lng: 17.230518 },
    { lat: 48.164341, lng: 17.230682 },
    { lat: 48.164512, lng: 17.231088 },
    { lat: 48.164445, lng: 17.231446 },
    { lat: 48.164396, lng: 17.231765 },
    { lat: 48.164342, lng: 17.231903 },
    { lat: 48.164293, lng: 17.231985 },
    { lat: 48.164174, lng: 17.232082 },
    { lat: 48.163995, lng: 17.232136 },
    { lat: 48.163793, lng: 17.232089 },
    { lat: 48.163478, lng: 17.231848 },
    { lat: 48.163249, lng: 17.231805 },
    { lat: 48.16313, lng: 17.231783 },
    { lat: 48.162939, lng: 17.232175 },
    { lat: 48.162943, lng: 17.232221 },
    { lat: 48.163005, lng: 17.23286 },
    { lat: 48.163004, lng: 17.233128 },
    { lat: 48.162945, lng: 17.233437 },
    { lat: 48.162865, lng: 17.233655 },
    { lat: 48.162767, lng: 17.233824 },
    { lat: 48.162075, lng: 17.234278 },
    { lat: 48.161781, lng: 17.234411 },
    { lat: 48.161536, lng: 17.234446 },
    { lat: 48.161117, lng: 17.234506 },
    { lat: 48.160054, lng: 17.234419 },
    { lat: 48.159616, lng: 17.234529 },
    { lat: 48.159287, lng: 17.234611 },
    { lat: 48.158741, lng: 17.2346 },
    { lat: 48.15848, lng: 17.234216 },
    { lat: 48.15823, lng: 17.23479 },
    { lat: 48.157946, lng: 17.235177 },
    { lat: 48.157567, lng: 17.235141 },
    { lat: 48.157353, lng: 17.235245 },
    { lat: 48.157278, lng: 17.235207 },
    { lat: 48.157169, lng: 17.235081 },
    { lat: 48.157131, lng: 17.235038 },
    { lat: 48.157116, lng: 17.23502 },
    { lat: 48.156506, lng: 17.233947 },
    { lat: 48.156303, lng: 17.233599 },
    { lat: 48.156133, lng: 17.233718 },
    { lat: 48.155906, lng: 17.233911 },
    { lat: 48.155656, lng: 17.234165 },
    { lat: 48.155509, lng: 17.234331 },
    { lat: 48.155044, lng: 17.234891 },
    { lat: 48.154998, lng: 17.234947 },
    { lat: 48.154691, lng: 17.235345 },
    { lat: 48.154545, lng: 17.235573 },
    { lat: 48.154378, lng: 17.235831 },
    { lat: 48.154347, lng: 17.235879 },
    { lat: 48.154308, lng: 17.235938 },
    { lat: 48.15419, lng: 17.236121 },
    { lat: 48.153767, lng: 17.236625 },
    { lat: 48.153597, lng: 17.237303 },
    { lat: 48.153284, lng: 17.238386 },
    { lat: 48.153103, lng: 17.238413 },
    { lat: 48.152995, lng: 17.238429 },
    { lat: 48.15292, lng: 17.23844 },
    { lat: 48.152878, lng: 17.238446 },
    { lat: 48.151135, lng: 17.238707 },
    { lat: 48.150703, lng: 17.238403 },
    { lat: 48.150135, lng: 17.238008 },
    { lat: 48.14976, lng: 17.237686 },
    { lat: 48.148447, lng: 17.238984 },
    { lat: 48.145885, lng: 17.237562 },
    { lat: 48.14586, lng: 17.23755 },
    { lat: 48.145796, lng: 17.237731 },
    { lat: 48.145706, lng: 17.237704 },
    { lat: 48.145653, lng: 17.237688 },
    { lat: 48.145409, lng: 17.237616 },
    { lat: 48.144408, lng: 17.237394 },
    { lat: 48.1441786, lng: 17.237439 },
    { lat: 48.144155, lng: 17.237455 },
    { lat: 48.144207, lng: 17.2378 },
    { lat: 48.144173, lng: 17.238162 },
    { lat: 48.144061, lng: 17.238497 },
    { lat: 48.143757, lng: 17.238923 },
    { lat: 48.143238, lng: 17.239358 },
    { lat: 48.142871, lng: 17.239781 },
    { lat: 48.142475, lng: 17.240338 },
    { lat: 48.141986, lng: 17.240569 },
    { lat: 48.141698, lng: 17.240654 },
    { lat: 48.141232, lng: 17.240839 },
    { lat: 48.140576, lng: 17.24123 },
    { lat: 48.139765, lng: 17.241658 },
    { lat: 48.139415, lng: 17.241822 },
    { lat: 48.139167, lng: 17.241903 },
    { lat: 48.139118, lng: 17.241891 },
    { lat: 48.138989, lng: 17.241875 },
    { lat: 48.138922, lng: 17.241871 },
    { lat: 48.13892, lng: 17.241891 },
    { lat: 48.138906, lng: 17.242008 },
    { lat: 48.138901, lng: 17.242048 },
    { lat: 48.138839, lng: 17.242575 },
    { lat: 48.138751, lng: 17.243324 },
    { lat: 48.138598, lng: 17.244793 },
    { lat: 48.138388, lng: 17.246697 },
    { lat: 48.138339, lng: 17.247211 },
    { lat: 48.138093, lng: 17.249696 },
    { lat: 48.137892, lng: 17.251728 },
    { lat: 48.137693, lng: 17.253563 },
    { lat: 48.137641, lng: 17.253558 },
    { lat: 48.136908, lng: 17.253491 },
    { lat: 48.136831, lng: 17.253917 },
    { lat: 48.13677, lng: 17.254373 },
    { lat: 48.136681, lng: 17.255071 },
    { lat: 48.1365, lng: 17.255844 },
    { lat: 48.136444, lng: 17.256263 },
    { lat: 48.136449, lng: 17.256612 },
    { lat: 48.136416, lng: 17.256783 },
    { lat: 48.136311, lng: 17.257124 },
    { lat: 48.136262, lng: 17.257335 },
    { lat: 48.13608, lng: 17.257901 },
    { lat: 48.135966, lng: 17.258208 },
    { lat: 48.135851, lng: 17.258451 },
    { lat: 48.135713, lng: 17.258679 },
    { lat: 48.135162, lng: 17.25916 },
    { lat: 48.134812, lng: 17.259435 },
    { lat: 48.134467, lng: 17.25978 },
    { lat: 48.133877, lng: 17.260075 },
    { lat: 48.133847, lng: 17.260091 },
    { lat: 48.132123, lng: 17.260954 },
    { lat: 48.130384, lng: 17.262041 },
    { lat: 48.129385, lng: 17.262574 },
    { lat: 48.127307, lng: 17.263599 },
    { lat: 48.126333, lng: 17.264059 },
    { lat: 48.125396, lng: 17.264467 },
    { lat: 48.123836, lng: 17.264997 },
    { lat: 48.123834, lng: 17.265033 },
    { lat: 48.123831, lng: 17.265071 },
    { lat: 48.123681, lng: 17.267368 },
    { lat: 48.123573, lng: 17.269288 },
    { lat: 48.123256, lng: 17.271188 },
    { lat: 48.123005, lng: 17.272686 },
    { lat: 48.122809, lng: 17.273893 },
    { lat: 48.122671, lng: 17.274727 },
    { lat: 48.123411, lng: 17.280106 },
    { lat: 48.121963, lng: 17.281358 },
    { lat: 48.121413, lng: 17.282012 },
    { lat: 48.120819, lng: 17.282712 },
    { lat: 48.120779, lng: 17.28277 },
    { lat: 48.12059, lng: 17.282603 },
    { lat: 48.120498, lng: 17.282523 },
    { lat: 48.120129, lng: 17.282198 },
    { lat: 48.119912, lng: 17.282007 },
    { lat: 48.119634, lng: 17.281766 },
    { lat: 48.118822, lng: 17.28102 },
    { lat: 48.117082, lng: 17.279439 },
    { lat: 48.116947, lng: 17.279331 },
    { lat: 48.116873, lng: 17.27951 },
    { lat: 48.116789, lng: 17.279803 },
    { lat: 48.116746, lng: 17.280014 },
    { lat: 48.116736, lng: 17.280113 },
    { lat: 48.116731, lng: 17.280174 },
    { lat: 48.116685, lng: 17.280622 },
    { lat: 48.116611, lng: 17.281846 },
    { lat: 48.116587, lng: 17.28241 },
    { lat: 48.116594, lng: 17.283107 },
    { lat: 48.116667, lng: 17.28442 },
    { lat: 48.116683, lng: 17.284531 },
    { lat: 48.112764, lng: 17.2833 },
    { lat: 48.1125136, lng: 17.2860454 },
    { lat: 48.1124106, lng: 17.2874565 },
    { lat: 48.1123367, lng: 17.2885065 },
    { lat: 48.1121593, lng: 17.2906016 },
    { lat: 48.1120058, lng: 17.2924293 },
    { lat: 48.1120057, lng: 17.2924781 },
    { lat: 48.1126497, lng: 17.2925138 },
    { lat: 48.113267, lng: 17.2925212 },
    { lat: 48.1136348, lng: 17.293452 },
    { lat: 48.1140155, lng: 17.294408 },
    { lat: 48.1140977, lng: 17.2946092 },
    { lat: 48.1147718, lng: 17.2959183 },
    { lat: 48.1151186, lng: 17.296584 },
    { lat: 48.1155414, lng: 17.2973735 },
    { lat: 48.1158011, lng: 17.2978665 },
    { lat: 48.1168649, lng: 17.2992944 },
    { lat: 48.1169277, lng: 17.2993736 },
    { lat: 48.1176123, lng: 17.3000747 },
    { lat: 48.1181097, lng: 17.3005742 },
    { lat: 48.1184476, lng: 17.3009181 },
    { lat: 48.1188238, lng: 17.3012937 },
    { lat: 48.1193329, lng: 17.3016647 },
    { lat: 48.1197295, lng: 17.3019563 },
    { lat: 48.1203952, lng: 17.3024384 },
    { lat: 48.121434, lng: 17.303309 },
    { lat: 48.1231805, lng: 17.3043633 },
    { lat: 48.1235668, lng: 17.3045856 },
    { lat: 48.1245624, lng: 17.3051726 },
    { lat: 48.125505, lng: 17.3057261 },
    { lat: 48.1257258, lng: 17.3058716 },
    { lat: 48.1259525, lng: 17.3060027 },
    { lat: 48.1265729, lng: 17.3063482 },
    { lat: 48.1278454, lng: 17.3070903 },
    { lat: 48.1295075, lng: 17.308071 },
    { lat: 48.1301506, lng: 17.3084351 },
    { lat: 48.1300107, lng: 17.3086262 },
    { lat: 48.129883, lng: 17.3088475 },
    { lat: 48.1297782, lng: 17.3091262 },
    { lat: 48.1297049, lng: 17.3094347 },
    { lat: 48.1296943, lng: 17.3095993 },
    { lat: 48.1297269, lng: 17.3098356 },
    { lat: 48.1298324, lng: 17.3104074 },
    { lat: 48.1300078, lng: 17.3111129 },
    { lat: 48.1300846, lng: 17.3113868 },
    { lat: 48.1303261, lng: 17.3119815 },
    { lat: 48.1304688, lng: 17.3122262 },
    { lat: 48.1306917, lng: 17.3126245 },
    { lat: 48.1308085, lng: 17.3128207 },
    { lat: 48.1311544, lng: 17.3133029 },
    { lat: 48.1312817, lng: 17.3134175 },
    { lat: 48.1314939, lng: 17.3135685 },
    { lat: 48.1319372, lng: 17.3137631 },
    { lat: 48.1321093, lng: 17.3138212 },
    { lat: 48.132381, lng: 17.3138647 },
    { lat: 48.1327326, lng: 17.3138517 },
    { lat: 48.1330318, lng: 17.3137823 },
    { lat: 48.1333264, lng: 17.3136917 },
    { lat: 48.1336252, lng: 17.3136501 },
    { lat: 48.1340472, lng: 17.3136756 },
    { lat: 48.1342975, lng: 17.3137038 },
    { lat: 48.1345922, lng: 17.3138046 },
    { lat: 48.1360736, lng: 17.311827 },
    { lat: 48.1366056, lng: 17.3111072 },
    { lat: 48.136666, lng: 17.3110307 },
    { lat: 48.1380206, lng: 17.3092557 },
    { lat: 48.1383398, lng: 17.3088221 },
    { lat: 48.13868, lng: 17.3083777 },
    { lat: 48.1390093, lng: 17.3079589 },
    { lat: 48.1400454, lng: 17.3065951 },
    { lat: 48.140735, lng: 17.3056806 },
    { lat: 48.1409546, lng: 17.3058245 },
    { lat: 48.1422257, lng: 17.3045289 },
    { lat: 48.1423045, lng: 17.3044575 },
    { lat: 48.1426944, lng: 17.3040648 },
    { lat: 48.1436197, lng: 17.3031291 },
    { lat: 48.1446639, lng: 17.3020753 },
    { lat: 48.144752, lng: 17.3019896 },
    { lat: 48.1453075, lng: 17.3014241 },
    { lat: 48.1453152, lng: 17.2990952 },
    { lat: 48.1453156, lng: 17.2986074 },
    { lat: 48.1453215, lng: 17.2969034 },
    { lat: 48.1446632, lng: 17.295901 },
    { lat: 48.1438629, lng: 17.2946791 },
    { lat: 48.1444857, lng: 17.292997 },
    { lat: 48.1454365, lng: 17.2904166 },
    { lat: 48.1459354, lng: 17.2890277 },
    { lat: 48.1459769, lng: 17.2889541 },
    { lat: 48.1462224, lng: 17.288311 },
    { lat: 48.1467954, lng: 17.2868167 },
    { lat: 48.1474489, lng: 17.2851012 },
    { lat: 48.1480266, lng: 17.2835638 },
    { lat: 48.1481919, lng: 17.2831386 },
    { lat: 48.1475609, lng: 17.2825466 },
    { lat: 48.1470707, lng: 17.2817989 },
    { lat: 48.1467568, lng: 17.2811862 },
    { lat: 48.1464945, lng: 17.2805034 },
    { lat: 48.1462524, lng: 17.2795273 },
    { lat: 48.1461155, lng: 17.2787913 },
    { lat: 48.1460539, lng: 17.2780155 },
    { lat: 48.1460617, lng: 17.2774177 },
    { lat: 48.1461005, lng: 17.2770329 },
    { lat: 48.1462475, lng: 17.2761495 },
    { lat: 48.1463553, lng: 17.2758144 },
    { lat: 48.1464043, lng: 17.2756866 },
    { lat: 48.1465349, lng: 17.2752712 },
    { lat: 48.1467479, lng: 17.2746995 },
    { lat: 48.1468761, lng: 17.2742847 },
    { lat: 48.1469204, lng: 17.2741853 },
    { lat: 48.1474462, lng: 17.2729165 },
    { lat: 48.147822, lng: 17.2724505 },
    { lat: 48.1483527, lng: 17.2720393 },
    { lat: 48.1487375, lng: 17.2718616 },
    { lat: 48.149909, lng: 17.2714033 },
    { lat: 48.1505178, lng: 17.2711291 },
    { lat: 48.1512812, lng: 17.2707172 },
    { lat: 48.1521855, lng: 17.2698066 },
    { lat: 48.1531872, lng: 17.2687792 },
    { lat: 48.1535267, lng: 17.2684326 },
    { lat: 48.1541463, lng: 17.2675556 },
    { lat: 48.1544403, lng: 17.2678656 },
    { lat: 48.1546759, lng: 17.2677396 },
    { lat: 48.1557061, lng: 17.2671949 },
    { lat: 48.1560745, lng: 17.2670104 },
    { lat: 48.156323, lng: 17.2668561 },
    { lat: 48.1564031, lng: 17.2668189 },
    { lat: 48.1568457, lng: 17.2666292 },
    { lat: 48.1571307, lng: 17.2665489 },
    { lat: 48.1577876, lng: 17.2662336 },
    { lat: 48.1581032, lng: 17.2659682 },
    { lat: 48.1583464, lng: 17.2656331 },
    { lat: 48.1585489, lng: 17.2652982 },
    { lat: 48.1587061, lng: 17.2650455 },
    { lat: 48.1588865, lng: 17.2648016 },
    { lat: 48.159034, lng: 17.2645561 },
    { lat: 48.1590926, lng: 17.264197 },
    { lat: 48.1591742, lng: 17.2636724 },
    { lat: 48.1591598, lng: 17.263168 },
    { lat: 48.1590914, lng: 17.2629537 },
    { lat: 48.1590061, lng: 17.2627063 },
    { lat: 48.1586397, lng: 17.2622271 },
    { lat: 48.1583593, lng: 17.2621033 },
    { lat: 48.1577125, lng: 17.262196 },
    { lat: 48.157529, lng: 17.262273 },
    { lat: 48.1572371, lng: 17.262421 },
    { lat: 48.1569026, lng: 17.2626395 },
    { lat: 48.1568586, lng: 17.2624535 },
    { lat: 48.1569667, lng: 17.2622112 },
    { lat: 48.157088, lng: 17.2618429 },
    { lat: 48.1569192, lng: 17.2614618 },
    { lat: 48.1566718, lng: 17.2609693 },
    { lat: 48.1565734, lng: 17.2608633 },
    { lat: 48.1560275, lng: 17.2603009 },
    { lat: 48.1558957, lng: 17.2601703 },
    { lat: 48.1555695, lng: 17.2598363 },
    { lat: 48.15598, lng: 17.2595188 },
    { lat: 48.156272, lng: 17.2593732 },
    { lat: 48.1566834, lng: 17.2590538 },
    { lat: 48.1568472, lng: 17.2590185 },
    { lat: 48.157023, lng: 17.2590802 },
    { lat: 48.1572103, lng: 17.2590777 },
    { lat: 48.157406, lng: 17.2591153 },
    { lat: 48.157422, lng: 17.259253 },
    { lat: 48.1576429, lng: 17.2594313 },
    { lat: 48.1579393, lng: 17.2602728 },
    { lat: 48.1581166, lng: 17.2609506 },
    { lat: 48.1582268, lng: 17.2611985 },
    { lat: 48.1584886, lng: 17.2616189 },
    { lat: 48.1589106, lng: 17.262002 },
    { lat: 48.1593505, lng: 17.2620936 },
    { lat: 48.1597594, lng: 17.262077 },
    { lat: 48.1600787, lng: 17.2621368 },
    { lat: 48.1604143, lng: 17.262292 },
    { lat: 48.1607697, lng: 17.2624892 },
    { lat: 48.1609551, lng: 17.2625436 },
    { lat: 48.1616265, lng: 17.2627859 },
    { lat: 48.1619439, lng: 17.2630915 },
    { lat: 48.1624591, lng: 17.2634143 },
    { lat: 48.1627439, lng: 17.2635529 },
    { lat: 48.1628816, lng: 17.2635848 },
    { lat: 48.1632586, lng: 17.2636327 },
    { lat: 48.1634513, lng: 17.2636419 },
    { lat: 48.1637481, lng: 17.263603 },
    { lat: 48.1642002, lng: 17.2634354 },
    { lat: 48.1644146, lng: 17.2632786 },
    { lat: 48.1647118, lng: 17.2635022 },
    { lat: 48.1649844, lng: 17.2637285 },
    { lat: 48.1654369, lng: 17.2639593 },
    { lat: 48.1656575, lng: 17.2640152 },
    { lat: 48.165862, lng: 17.2640321 },
    { lat: 48.1662802, lng: 17.2640004 },
    { lat: 48.1665964, lng: 17.2638801 },
    { lat: 48.1668793, lng: 17.2636321 },
    { lat: 48.167157, lng: 17.2630167 },
    { lat: 48.1672871, lng: 17.2626621 },
    { lat: 48.1673341, lng: 17.2624187 },
    { lat: 48.1673982, lng: 17.2620401 },
    { lat: 48.1674592, lng: 17.2617855 },
    { lat: 48.1676028, lng: 17.2611233 },
    { lat: 48.1676244, lng: 17.2608018 },
    { lat: 48.1676628, lng: 17.2603442 },
    { lat: 48.1676248, lng: 17.2601118 },
    { lat: 48.1674546, lng: 17.2595046 },
    { lat: 48.1672915, lng: 17.2592105 },
    { lat: 48.1671441, lng: 17.2590821 },
    { lat: 48.1669007, lng: 17.2587535 },
    { lat: 48.1671944, lng: 17.2580233 },
    { lat: 48.1672122, lng: 17.2579844 },
    { lat: 48.1676192, lng: 17.2584636 },
    { lat: 48.1677167, lng: 17.2585892 },
    { lat: 48.1679662, lng: 17.2589423 },
    { lat: 48.1680627, lng: 17.2591028 },
    { lat: 48.1681371, lng: 17.2592529 },
    { lat: 48.1682761, lng: 17.2595591 },
    { lat: 48.1683246, lng: 17.2596777 },
    { lat: 48.1683375, lng: 17.259821 },
    { lat: 48.168332, lng: 17.2599425 },
    { lat: 48.1683663, lng: 17.2603572 },
    { lat: 48.168455, lng: 17.2606041 },
    { lat: 48.1685231, lng: 17.2605745 },
    { lat: 48.1687116, lng: 17.2608783 },
    { lat: 48.1689222, lng: 17.2614981 },
    { lat: 48.169109, lng: 17.2618597 },
    { lat: 48.1694048, lng: 17.262248 },
    { lat: 48.1695811, lng: 17.2624186 },
    { lat: 48.1697891, lng: 17.2625742 },
    { lat: 48.1700674, lng: 17.2627339 },
    { lat: 48.1701787, lng: 17.2628618 },
    { lat: 48.1701958, lng: 17.2628982 },
    { lat: 48.1702085, lng: 17.2628683 },
    { lat: 48.1701088, lng: 17.262743 },
    { lat: 48.1698109, lng: 17.262273 },
    { lat: 48.1696531, lng: 17.2621259 },
    { lat: 48.1696439, lng: 17.2619533 },
    { lat: 48.1696145, lng: 17.2612214 },
    { lat: 48.1700887, lng: 17.2615394 },
    { lat: 48.1705129, lng: 17.2617871 },
    { lat: 48.1711129, lng: 17.2620949 },
    { lat: 48.1718214, lng: 17.2625748 },
    { lat: 48.1724061, lng: 17.2629751 },
    { lat: 48.1728756, lng: 17.2632246 },
    { lat: 48.1731536, lng: 17.2632931 },
    { lat: 48.1734128, lng: 17.2632834 },
    { lat: 48.1738463, lng: 17.2631945 },
    { lat: 48.1742903, lng: 17.2630923 },
    { lat: 48.1747994, lng: 17.2629347 },
    { lat: 48.1751395, lng: 17.262798 },
    { lat: 48.1757152, lng: 17.2622135 },
    { lat: 48.1759439, lng: 17.2618645 },
    { lat: 48.1762035, lng: 17.2614536 },
    { lat: 48.1764985, lng: 17.2609967 },
    { lat: 48.1766458, lng: 17.260594 },
    { lat: 48.1769181, lng: 17.2608686 },
    { lat: 48.1773269, lng: 17.2611381 },
    { lat: 48.178084, lng: 17.2616498 },
  ],
  NováDedinka: [
    { lat: 48.1999873, lng: 17.3746226 },
    { lat: 48.2002257, lng: 17.3745523 },
    { lat: 48.2004068, lng: 17.3744201 },
    { lat: 48.200546, lng: 17.3743397 },
    { lat: 48.2018652, lng: 17.3735958 },
    { lat: 48.2022463, lng: 17.3733255 },
    { lat: 48.2030359, lng: 17.3727046 },
    { lat: 48.204043, lng: 17.3719049 },
    { lat: 48.2044598, lng: 17.3716116 },
    { lat: 48.2054495, lng: 17.3708895 },
    { lat: 48.2057597, lng: 17.3706582 },
    { lat: 48.2058993, lng: 17.370549 },
    { lat: 48.2060967, lng: 17.3704213 },
    { lat: 48.2070003, lng: 17.3695767 },
    { lat: 48.2069829, lng: 17.3695362 },
    { lat: 48.2069499, lng: 17.3691515 },
    { lat: 48.2069295, lng: 17.3689762 },
    { lat: 48.2066835, lng: 17.3673588 },
    { lat: 48.2065494, lng: 17.3664836 },
    { lat: 48.206276, lng: 17.3646556 },
    { lat: 48.2062175, lng: 17.3644521 },
    { lat: 48.2059639, lng: 17.3628224 },
    { lat: 48.2058058, lng: 17.3629089 },
    { lat: 48.205642, lng: 17.3622704 },
    { lat: 48.2056278, lng: 17.3622768 },
    { lat: 48.2058223, lng: 17.3619017 },
    { lat: 48.2057191, lng: 17.361067 },
    { lat: 48.20559, lng: 17.3600635 },
    { lat: 48.2054246, lng: 17.3589015 },
    { lat: 48.2052743, lng: 17.3578836 },
    { lat: 48.2051552, lng: 17.3570672 },
    { lat: 48.2050101, lng: 17.3567995 },
    { lat: 48.2051155, lng: 17.3567536 },
    { lat: 48.2049555, lng: 17.355519 },
    { lat: 48.2048458, lng: 17.3546911 },
    { lat: 48.204683, lng: 17.35357 },
    { lat: 48.2044572, lng: 17.3519853 },
    { lat: 48.2043835, lng: 17.3515002 },
    { lat: 48.204287, lng: 17.35089 },
    { lat: 48.2041708, lng: 17.3506687 },
    { lat: 48.204102, lng: 17.3505843 },
    { lat: 48.2040942, lng: 17.3505339 },
    { lat: 48.2043424, lng: 17.3504272 },
    { lat: 48.2045369, lng: 17.3503961 },
    { lat: 48.2045223, lng: 17.3502936 },
    { lat: 48.2043161, lng: 17.3490439 },
    { lat: 48.2040275, lng: 17.3492424 },
    { lat: 48.2038872, lng: 17.3492572 },
    { lat: 48.2033664, lng: 17.3491697 },
    { lat: 48.2024887, lng: 17.3490301 },
    { lat: 48.2019234, lng: 17.3489373 },
    { lat: 48.200749, lng: 17.3487532 },
    { lat: 48.2005324, lng: 17.3487217 },
    { lat: 48.200089, lng: 17.3486432 },
    { lat: 48.199868, lng: 17.3486157 },
    { lat: 48.199627, lng: 17.3485751 },
    { lat: 48.1989546, lng: 17.3484676 },
    { lat: 48.1985591, lng: 17.3483974 },
    { lat: 48.1981419, lng: 17.3483395 },
    { lat: 48.1978773, lng: 17.3482998 },
    { lat: 48.1974764, lng: 17.3482666 },
    { lat: 48.1972528, lng: 17.3482683 },
    { lat: 48.1967278, lng: 17.348255 },
    { lat: 48.1964682, lng: 17.3482398 },
    { lat: 48.1954728, lng: 17.3482028 },
    { lat: 48.194805, lng: 17.3481826 },
    { lat: 48.1944206, lng: 17.3481766 },
    { lat: 48.1941714, lng: 17.3480982 },
    { lat: 48.1939093, lng: 17.3480455 },
    { lat: 48.1936101, lng: 17.347962 },
    { lat: 48.1927577, lng: 17.3477611 },
    { lat: 48.1920811, lng: 17.3475893 },
    { lat: 48.1916226, lng: 17.3474894 },
    { lat: 48.1913029, lng: 17.3473996 },
    { lat: 48.1909904, lng: 17.3473212 },
    { lat: 48.1907174, lng: 17.3472574 },
    { lat: 48.1903062, lng: 17.3471539 },
    { lat: 48.1900004, lng: 17.347073 },
    { lat: 48.1896263, lng: 17.3469852 },
    { lat: 48.1891822, lng: 17.3468613 },
    { lat: 48.1889741, lng: 17.3468217 },
    { lat: 48.188639, lng: 17.3467248 },
    { lat: 48.188255, lng: 17.3466233 },
    { lat: 48.1877047, lng: 17.3464827 },
    { lat: 48.1874718, lng: 17.3464267 },
    { lat: 48.1872002, lng: 17.3463517 },
    { lat: 48.1865699, lng: 17.3462112 },
    { lat: 48.1861857, lng: 17.345782 },
    { lat: 48.1857419, lng: 17.3453015 },
    { lat: 48.1854964, lng: 17.3450217 },
    { lat: 48.1851325, lng: 17.3446224 },
    { lat: 48.1847779, lng: 17.3442455 },
    { lat: 48.1843207, lng: 17.3437494 },
    { lat: 48.1837677, lng: 17.343122 },
    { lat: 48.183594, lng: 17.3429205 },
    { lat: 48.1829619, lng: 17.3422219 },
    { lat: 48.1821156, lng: 17.3411192 },
    { lat: 48.1818607, lng: 17.3407977 },
    { lat: 48.1816987, lng: 17.3406019 },
    { lat: 48.181339, lng: 17.3401497 },
    { lat: 48.1810307, lng: 17.3397551 },
    { lat: 48.1803628, lng: 17.3389067 },
    { lat: 48.1802256, lng: 17.3387283 },
    { lat: 48.1798124, lng: 17.3382148 },
    { lat: 48.1795346, lng: 17.3378739 },
    { lat: 48.1793855, lng: 17.3376831 },
    { lat: 48.1791113, lng: 17.3373458 },
    { lat: 48.1788798, lng: 17.3371063 },
    { lat: 48.1788087, lng: 17.3370382 },
    { lat: 48.1786053, lng: 17.3368139 },
    { lat: 48.1784477, lng: 17.3366513 },
    { lat: 48.1781469, lng: 17.3363181 },
    { lat: 48.1779378, lng: 17.3362315 },
    { lat: 48.1777167, lng: 17.3361313 },
    { lat: 48.1775296, lng: 17.3360534 },
    { lat: 48.177355, lng: 17.3359911 },
    { lat: 48.1770085, lng: 17.3359442 },
    { lat: 48.1765762, lng: 17.3359655 },
    { lat: 48.1763627, lng: 17.3359649 },
    { lat: 48.1761211, lng: 17.3359523 },
    { lat: 48.1758666, lng: 17.3359467 },
    { lat: 48.1755999, lng: 17.3359275 },
    { lat: 48.1752729, lng: 17.3358845 },
    { lat: 48.1752197, lng: 17.3358838 },
    { lat: 48.1745368, lng: 17.3359083 },
    { lat: 48.1744022, lng: 17.3359221 },
    { lat: 48.1741746, lng: 17.3359826 },
    { lat: 48.1743244, lng: 17.3365256 },
    { lat: 48.1744078, lng: 17.3367793 },
    { lat: 48.1744773, lng: 17.3370477 },
    { lat: 48.1745472, lng: 17.3371827 },
    { lat: 48.1746717, lng: 17.3374768 },
    { lat: 48.1747735, lng: 17.3382522 },
    { lat: 48.1746762, lng: 17.3388525 },
    { lat: 48.1744837, lng: 17.3396686 },
    { lat: 48.1743431, lng: 17.3401258 },
    { lat: 48.1741629, lng: 17.3405966 },
    { lat: 48.173925, lng: 17.3411693 },
    { lat: 48.1738776, lng: 17.3412575 },
    { lat: 48.1735565, lng: 17.3417344 },
    { lat: 48.17322, lng: 17.3420962 },
    { lat: 48.1726712, lng: 17.342528 },
    { lat: 48.1720712, lng: 17.3426795 },
    { lat: 48.1719583, lng: 17.342714 },
    { lat: 48.1717202, lng: 17.3427362 },
    { lat: 48.171437, lng: 17.3442015 },
    { lat: 48.1712072, lng: 17.3453802 },
    { lat: 48.1709675, lng: 17.3466151 },
    { lat: 48.1707605, lng: 17.3476579 },
    { lat: 48.1705091, lng: 17.3475968 },
    { lat: 48.1703013, lng: 17.3475995 },
    { lat: 48.1698821, lng: 17.3474247 },
    { lat: 48.1694607, lng: 17.347218 },
    { lat: 48.1691881, lng: 17.3470756 },
    { lat: 48.1689583, lng: 17.3471187 },
    { lat: 48.1687414, lng: 17.3472037 },
    { lat: 48.1684989, lng: 17.3473635 },
    { lat: 48.1683085, lng: 17.3476481 },
    { lat: 48.1680971, lng: 17.3480033 },
    { lat: 48.1678196, lng: 17.3487506 },
    { lat: 48.1676667, lng: 17.3493295 },
    { lat: 48.1674345, lng: 17.3498186 },
    { lat: 48.1672641, lng: 17.3501469 },
    { lat: 48.1671409, lng: 17.3504055 },
    { lat: 48.1669364, lng: 17.3508163 },
    { lat: 48.1665929, lng: 17.3511758 },
    { lat: 48.1662241, lng: 17.351511 },
    { lat: 48.1656967, lng: 17.3518229 },
    { lat: 48.1650623, lng: 17.3519836 },
    { lat: 48.1647118, lng: 17.3519954 },
    { lat: 48.1639612, lng: 17.3518143 },
    { lat: 48.1634923, lng: 17.3516231 },
    { lat: 48.1627704, lng: 17.3511236 },
    { lat: 48.1625999, lng: 17.3514517 },
    { lat: 48.1625315, lng: 17.3524571 },
    { lat: 48.1624898, lng: 17.3530955 },
    { lat: 48.1623781, lng: 17.3547657 },
    { lat: 48.1623377, lng: 17.3553034 },
    { lat: 48.1623286, lng: 17.3555444 },
    { lat: 48.162287, lng: 17.3559769 },
    { lat: 48.1632214, lng: 17.356582 },
    { lat: 48.1638121, lng: 17.3572527 },
    { lat: 48.1640846, lng: 17.3576364 },
    { lat: 48.1643805, lng: 17.3580498 },
    { lat: 48.1648006, lng: 17.3588577 },
    { lat: 48.1650452, lng: 17.3593326 },
    { lat: 48.1652285, lng: 17.3596806 },
    { lat: 48.165602, lng: 17.3604161 },
    { lat: 48.1661747, lng: 17.3614643 },
    { lat: 48.1666553, lng: 17.3627667 },
    { lat: 48.1667829, lng: 17.3631093 },
    { lat: 48.1670176, lng: 17.3640543 },
    { lat: 48.1673331, lng: 17.3654383 },
    { lat: 48.1674115, lng: 17.3659451 },
    { lat: 48.1675166, lng: 17.3665511 },
    { lat: 48.1676104, lng: 17.3672914 },
    { lat: 48.1667044, lng: 17.3677736 },
    { lat: 48.1662194, lng: 17.3680261 },
    { lat: 48.166069, lng: 17.3683149 },
    { lat: 48.1659146, lng: 17.3685967 },
    { lat: 48.1655924, lng: 17.3691975 },
    { lat: 48.1653366, lng: 17.3697222 },
    { lat: 48.1650696, lng: 17.3702854 },
    { lat: 48.1647666, lng: 17.3709175 },
    { lat: 48.1644606, lng: 17.3715413 },
    { lat: 48.1640197, lng: 17.3724331 },
    { lat: 48.1637649, lng: 17.3729576 },
    { lat: 48.1636389, lng: 17.3732081 },
    { lat: 48.1630482, lng: 17.3744148 },
    { lat: 48.1629468, lng: 17.3746255 },
    { lat: 48.1627379, lng: 17.3750434 },
    { lat: 48.161947, lng: 17.376649 },
    { lat: 48.163216, lng: 17.377752 },
    { lat: 48.163509, lng: 17.377576 },
    { lat: 48.164249, lng: 17.377459 },
    { lat: 48.164448, lng: 17.377719 },
    { lat: 48.164903, lng: 17.378011 },
    { lat: 48.165738, lng: 17.378162 },
    { lat: 48.1657438, lng: 17.3779098 },
    { lat: 48.1656792, lng: 17.3773891 },
    { lat: 48.1656773, lng: 17.3769107 },
    { lat: 48.1657594, lng: 17.3767333 },
    { lat: 48.1659823, lng: 17.3764449 },
    { lat: 48.1665026, lng: 17.3758529 },
    { lat: 48.1666154, lng: 17.3757115 },
    { lat: 48.1668689, lng: 17.3752523 },
    { lat: 48.1668996, lng: 17.3751017 },
    { lat: 48.1669265, lng: 17.3745127 },
    { lat: 48.1670927, lng: 17.3742386 },
    { lat: 48.1675072, lng: 17.37396 },
    { lat: 48.1683608, lng: 17.3734376 },
    { lat: 48.1689947, lng: 17.3734233 },
    { lat: 48.1692113, lng: 17.3734204 },
    { lat: 48.170075, lng: 17.373397 },
    { lat: 48.1704672, lng: 17.3735727 },
    { lat: 48.1705601, lng: 17.3736275 },
    { lat: 48.1708946, lng: 17.3738394 },
    { lat: 48.1713933, lng: 17.3741743 },
    { lat: 48.1725072, lng: 17.3749028 },
    { lat: 48.1729683, lng: 17.3751834 },
    { lat: 48.1738781, lng: 17.3757443 },
    { lat: 48.1743482, lng: 17.3760404 },
    { lat: 48.1747422, lng: 17.3762968 },
    { lat: 48.1750375, lng: 17.3764242 },
    { lat: 48.175495, lng: 17.376614 },
    { lat: 48.1757195, lng: 17.3767128 },
    { lat: 48.1767513, lng: 17.3771556 },
    { lat: 48.1777762, lng: 17.3775579 },
    { lat: 48.1780665, lng: 17.3776785 },
    { lat: 48.178141, lng: 17.3773322 },
    { lat: 48.1782739, lng: 17.3770148 },
    { lat: 48.178351, lng: 17.376882 },
    { lat: 48.1786081, lng: 17.3764965 },
    { lat: 48.1788573, lng: 17.376161 },
    { lat: 48.1792041, lng: 17.3757472 },
    { lat: 48.1793581, lng: 17.375511 },
    { lat: 48.1795553, lng: 17.3751605 },
    { lat: 48.1797076, lng: 17.374656 },
    { lat: 48.1798127, lng: 17.3742251 },
    { lat: 48.1799327, lng: 17.3739871 },
    { lat: 48.1801964, lng: 17.3737535 },
    { lat: 48.1805763, lng: 17.3743633 },
    { lat: 48.1807985, lng: 17.3747042 },
    { lat: 48.1811696, lng: 17.3752841 },
    { lat: 48.1813511, lng: 17.3755716 },
    { lat: 48.1817731, lng: 17.376514 },
    { lat: 48.1818815, lng: 17.3767717 },
    { lat: 48.1819724, lng: 17.3769534 },
    { lat: 48.1822525, lng: 17.3768146 },
    { lat: 48.1824549, lng: 17.3766794 },
    { lat: 48.1826191, lng: 17.3765313 },
    { lat: 48.183069, lng: 17.3759832 },
    { lat: 48.1833548, lng: 17.3756392 },
    { lat: 48.1834826, lng: 17.37552 },
    { lat: 48.1836701, lng: 17.3752966 },
    { lat: 48.1838514, lng: 17.3750471 },
    { lat: 48.1840336, lng: 17.3753608 },
    { lat: 48.184247, lng: 17.3757576 },
    { lat: 48.1843978, lng: 17.3760253 },
    { lat: 48.1848858, lng: 17.3769074 },
    { lat: 48.1856214, lng: 17.3782061 },
    { lat: 48.1864383, lng: 17.3779498 },
    { lat: 48.1866728, lng: 17.3778741 },
    { lat: 48.1871131, lng: 17.3777851 },
    { lat: 48.1873436, lng: 17.3777354 },
    { lat: 48.1877031, lng: 17.3776515 },
    { lat: 48.1883177, lng: 17.3772746 },
    { lat: 48.1890426, lng: 17.3768746 },
    { lat: 48.1897474, lng: 17.3766996 },
    { lat: 48.1906432, lng: 17.3765804 },
    { lat: 48.1916913, lng: 17.3765453 },
    { lat: 48.1923667, lng: 17.3765445 },
    { lat: 48.1931754, lng: 17.3764921 },
    { lat: 48.1940032, lng: 17.3764545 },
    { lat: 48.1946049, lng: 17.3763978 },
    { lat: 48.1950493, lng: 17.3763538 },
    { lat: 48.1955059, lng: 17.3763047 },
    { lat: 48.1959692, lng: 17.3762741 },
    { lat: 48.1961993, lng: 17.3762355 },
    { lat: 48.1968861, lng: 17.3761146 },
    { lat: 48.1968662, lng: 17.3761717 },
    { lat: 48.1975481, lng: 17.3758057 },
    { lat: 48.1978938, lng: 17.3756158 },
    { lat: 48.1980463, lng: 17.3755124 },
    { lat: 48.1983785, lng: 17.3753581 },
    { lat: 48.1986523, lng: 17.3752269 },
    { lat: 48.1988925, lng: 17.3750997 },
    { lat: 48.1990995, lng: 17.3750009 },
    { lat: 48.199308, lng: 17.3748926 },
    { lat: 48.1994964, lng: 17.3748017 },
    { lat: 48.199823, lng: 17.3746314 },
    { lat: 48.1999795, lng: 17.3746069 },
    { lat: 48.1999873, lng: 17.3746226 },
  ],
  NovýSvet: [
    { lat: 48.2092929, lng: 17.4714259 },
    { lat: 48.2092426, lng: 17.4713993 },
    { lat: 48.2088047, lng: 17.4710707 },
    { lat: 48.208081, lng: 17.4705499 },
    { lat: 48.2070251, lng: 17.4697639 },
    { lat: 48.2058921, lng: 17.4688506 },
    { lat: 48.2055962, lng: 17.468606 },
    { lat: 48.2048723, lng: 17.4681877 },
    { lat: 48.2044914, lng: 17.4679724 },
    { lat: 48.2040821, lng: 17.4677917 },
    { lat: 48.2034192, lng: 17.4675294 },
    { lat: 48.2032624, lng: 17.4674793 },
    { lat: 48.2031789, lng: 17.467445 },
    { lat: 48.2031046, lng: 17.4678873 },
    { lat: 48.2030606, lng: 17.4681311 },
    { lat: 48.2030359, lng: 17.4683281 },
    { lat: 48.2030202, lng: 17.4685214 },
    { lat: 48.2030121, lng: 17.4689897 },
    { lat: 48.2029841, lng: 17.4696053 },
    { lat: 48.2029731, lng: 17.4699616 },
    { lat: 48.2029621, lng: 17.470139 },
    { lat: 48.2029182, lng: 17.4705787 },
    { lat: 48.2028587, lng: 17.4710054 },
    { lat: 48.2026987, lng: 17.4715716 },
    { lat: 48.2025924, lng: 17.4719004 },
    { lat: 48.2023186, lng: 17.4727332 },
    { lat: 48.2021801, lng: 17.4731435 },
    { lat: 48.2021158, lng: 17.4733723 },
    { lat: 48.2020419, lng: 17.4736955 },
    { lat: 48.2020024, lng: 17.4739597 },
    { lat: 48.201975, lng: 17.4742695 },
    { lat: 48.201941, lng: 17.4744789 },
    { lat: 48.2019486, lng: 17.4746475 },
    { lat: 48.2020079, lng: 17.4750073 },
    { lat: 48.202086, lng: 17.4752722 },
    { lat: 48.2021622, lng: 17.4754547 },
    { lat: 48.2023127, lng: 17.4756533 },
    { lat: 48.2023906, lng: 17.4756868 },
    { lat: 48.2025744, lng: 17.4757001 },
    { lat: 48.2028535, lng: 17.4757037 },
    { lat: 48.203152, lng: 17.4756201 },
    { lat: 48.2034677, lng: 17.4755602 },
    { lat: 48.2037686, lng: 17.4756028 },
    { lat: 48.2041522, lng: 17.4759986 },
    { lat: 48.2042429, lng: 17.4761282 },
    { lat: 48.2043313, lng: 17.4763757 },
    { lat: 48.2043593, lng: 17.4766361 },
    { lat: 48.2043995, lng: 17.4775205 },
    { lat: 48.2043867, lng: 17.4776027 },
    { lat: 48.2042378, lng: 17.4782535 },
    { lat: 48.2037474, lng: 17.4793878 },
    { lat: 48.2036782, lng: 17.4794891 },
    { lat: 48.203529, lng: 17.4798383 },
    { lat: 48.2033893, lng: 17.4802631 },
    { lat: 48.2033322, lng: 17.4804639 },
    { lat: 48.2033062, lng: 17.4807617 },
    { lat: 48.2033646, lng: 17.4815633 },
    { lat: 48.2033792, lng: 17.4816483 },
    { lat: 48.2035829, lng: 17.482233 },
    { lat: 48.2036441, lng: 17.4823603 },
    { lat: 48.2040419, lng: 17.4830462 },
    { lat: 48.2043856, lng: 17.4835432 },
    { lat: 48.204879, lng: 17.4841368 },
    { lat: 48.205033, lng: 17.4843529 },
    { lat: 48.2050558, lng: 17.4844282 },
    { lat: 48.2051036, lng: 17.4846611 },
    { lat: 48.2051556, lng: 17.4850296 },
    { lat: 48.2051398, lng: 17.4854162 },
    { lat: 48.205118, lng: 17.4856027 },
    { lat: 48.2050921, lng: 17.4857619 },
    { lat: 48.2050361, lng: 17.4860486 },
    { lat: 48.2049547, lng: 17.486504 },
    { lat: 48.2048769, lng: 17.4868041 },
    { lat: 48.2047705, lng: 17.4869849 },
    { lat: 48.2046353, lng: 17.4872097 },
    { lat: 48.2042474, lng: 17.4876898 },
    { lat: 48.204097, lng: 17.4878633 },
    { lat: 48.2037804, lng: 17.488121 },
    { lat: 48.2034653, lng: 17.4883659 },
    { lat: 48.2030815, lng: 17.4886839 },
    { lat: 48.2029013, lng: 17.48878 },
    { lat: 48.2025314, lng: 17.4888754 },
    { lat: 48.2020995, lng: 17.4888879 },
    { lat: 48.2015452, lng: 17.4887862 },
    { lat: 48.2010843, lng: 17.4886439 },
    { lat: 48.2009014, lng: 17.4886611 },
    { lat: 48.2006323, lng: 17.4887267 },
    { lat: 48.2005477, lng: 17.4887989 },
    { lat: 48.200442, lng: 17.4889814 },
    { lat: 48.2003116, lng: 17.489165 },
    { lat: 48.2002222, lng: 17.4894262 },
    { lat: 48.200196, lng: 17.4895626 },
    { lat: 48.2001767, lng: 17.4897351 },
    { lat: 48.200167, lng: 17.4900068 },
    { lat: 48.2001872, lng: 17.4906011 },
    { lat: 48.200364, lng: 17.490873 },
    { lat: 48.2004542, lng: 17.490995 },
    { lat: 48.2005832, lng: 17.4911387 },
    { lat: 48.2006988, lng: 17.4912495 },
    { lat: 48.200848, lng: 17.491359 },
    { lat: 48.2013068, lng: 17.4916248 },
    { lat: 48.2013359, lng: 17.4916376 },
    { lat: 48.2016359, lng: 17.4916688 },
    { lat: 48.2019542, lng: 17.4916135 },
    { lat: 48.2021259, lng: 17.4915407 },
    { lat: 48.2023618, lng: 17.4913817 },
    { lat: 48.2026391, lng: 17.4910766 },
    { lat: 48.202908, lng: 17.4907268 },
    { lat: 48.2029891, lng: 17.4906341 },
    { lat: 48.2030479, lng: 17.4905826 },
    { lat: 48.2032621, lng: 17.4904555 },
    { lat: 48.2034633, lng: 17.4903689 },
    { lat: 48.2035113, lng: 17.4903604 },
    { lat: 48.2037467, lng: 17.4903856 },
    { lat: 48.2038507, lng: 17.4904408 },
    { lat: 48.204228, lng: 17.4908105 },
    { lat: 48.2045615, lng: 17.4911663 },
    { lat: 48.2046217, lng: 17.4912411 },
    { lat: 48.2048318, lng: 17.491558 },
    { lat: 48.2050449, lng: 17.4919396 },
    { lat: 48.205175, lng: 17.4923466 },
    { lat: 48.2053234, lng: 17.4927907 },
    { lat: 48.2053845, lng: 17.4930041 },
    { lat: 48.2054694, lng: 17.4935343 },
    { lat: 48.2054821, lng: 17.4940298 },
    { lat: 48.2054535, lng: 17.4946558 },
    { lat: 48.2054463, lng: 17.4947362 },
    { lat: 48.2053291, lng: 17.4952152 },
    { lat: 48.2052026, lng: 17.4957064 },
    { lat: 48.2050557, lng: 17.496302 },
    { lat: 48.2049574, lng: 17.496829 },
    { lat: 48.2048364, lng: 17.4975449 },
    { lat: 48.2047766, lng: 17.4978628 },
    { lat: 48.2047592, lng: 17.4980292 },
    { lat: 48.2047787, lng: 17.498246 },
    { lat: 48.2048497, lng: 17.4985061 },
    { lat: 48.2048921, lng: 17.4987094 },
    { lat: 48.205051, lng: 17.4990034 },
    { lat: 48.2051764, lng: 17.4993021 },
    { lat: 48.2052583, lng: 17.4995758 },
    { lat: 48.2052956, lng: 17.4997344 },
    { lat: 48.2053164, lng: 17.4998648 },
    { lat: 48.2053655, lng: 17.5002768 },
    { lat: 48.2053307, lng: 17.5004549 },
    { lat: 48.205313, lng: 17.50074 },
    { lat: 48.205388, lng: 17.500835 },
    { lat: 48.2054, lng: 17.500851 },
    { lat: 48.205539, lng: 17.500998 },
    { lat: 48.205658, lng: 17.501124 },
    { lat: 48.205829, lng: 17.501308 },
    { lat: 48.206572, lng: 17.502094 },
    { lat: 48.207216, lng: 17.502785 },
    { lat: 48.207869, lng: 17.503434 },
    { lat: 48.207868, lng: 17.503435 },
    { lat: 48.207958, lng: 17.503525 },
    { lat: 48.208648, lng: 17.504218 },
    { lat: 48.208855, lng: 17.504442 },
    { lat: 48.208929, lng: 17.504522 },
    { lat: 48.209022, lng: 17.504621 },
    { lat: 48.209532, lng: 17.505163 },
    { lat: 48.210057, lng: 17.505728 },
    { lat: 48.210667, lng: 17.506394 },
    { lat: 48.211212, lng: 17.506984 },
    { lat: 48.212895, lng: 17.508839 },
    { lat: 48.212935, lng: 17.508884 },
    { lat: 48.21298, lng: 17.508932 },
    { lat: 48.213394, lng: 17.509388 },
    { lat: 48.213432, lng: 17.509429 },
    { lat: 48.213479, lng: 17.509481 },
    { lat: 48.213715, lng: 17.509744 },
    { lat: 48.214075, lng: 17.510142 },
    { lat: 48.214243, lng: 17.510333 },
    { lat: 48.214617, lng: 17.510767 },
    { lat: 48.215379, lng: 17.511629 },
    { lat: 48.215688, lng: 17.511985 },
    { lat: 48.216181, lng: 17.512503 },
    { lat: 48.21664, lng: 17.512967 },
    { lat: 48.216966, lng: 17.513303 },
    { lat: 48.217031, lng: 17.513372 },
    { lat: 48.217059, lng: 17.513401 },
    { lat: 48.217573, lng: 17.51393 },
    { lat: 48.217978, lng: 17.514333 },
    { lat: 48.218484, lng: 17.514848 },
    { lat: 48.218771, lng: 17.515145 },
    { lat: 48.219381, lng: 17.515757 },
    { lat: 48.219697, lng: 17.516083 },
    { lat: 48.219712, lng: 17.516097 },
    { lat: 48.220047, lng: 17.516432 },
    { lat: 48.22029, lng: 17.516688 },
    { lat: 48.220645, lng: 17.517065 },
    { lat: 48.22103, lng: 17.517385 },
    { lat: 48.221721, lng: 17.518076 },
    { lat: 48.221979, lng: 17.518305 },
    { lat: 48.222153, lng: 17.518461 },
    { lat: 48.2225894, lng: 17.5188486 },
    { lat: 48.222985, lng: 17.5192 },
    { lat: 48.223376, lng: 17.519552 },
    { lat: 48.223744, lng: 17.519883 },
    { lat: 48.223798, lng: 17.5199 },
    { lat: 48.223954, lng: 17.519951 },
    { lat: 48.224166, lng: 17.52002 },
    { lat: 48.224633, lng: 17.520171 },
    { lat: 48.225088, lng: 17.52036 },
    { lat: 48.225498, lng: 17.520529 },
    { lat: 48.225519, lng: 17.520538 },
    { lat: 48.225548, lng: 17.520557 },
    { lat: 48.225577, lng: 17.52023 },
    { lat: 48.225648, lng: 17.519408 },
    { lat: 48.225841, lng: 17.517258 },
    { lat: 48.225938, lng: 17.516111 },
    { lat: 48.226039, lng: 17.514926 },
    { lat: 48.226072, lng: 17.514313 },
    { lat: 48.226115, lng: 17.513567 },
    { lat: 48.226146, lng: 17.513411 },
    { lat: 48.226368, lng: 17.51232 },
    { lat: 48.22656, lng: 17.511399 },
    { lat: 48.226685, lng: 17.510836 },
    { lat: 48.226735, lng: 17.510654 },
    { lat: 48.227002, lng: 17.509746 },
    { lat: 48.227171, lng: 17.509151 },
    { lat: 48.227411, lng: 17.508312 },
    { lat: 48.227454, lng: 17.508153 },
    { lat: 48.227525, lng: 17.507849 },
    { lat: 48.227767, lng: 17.506845 },
    { lat: 48.228081, lng: 17.505614 },
    { lat: 48.228315, lng: 17.504753 },
    { lat: 48.228526, lng: 17.503963 },
    { lat: 48.22864, lng: 17.503537 },
    { lat: 48.228968, lng: 17.502343 },
    { lat: 48.229195, lng: 17.501503 },
    { lat: 48.229283, lng: 17.501024 },
    { lat: 48.229446, lng: 17.50043 },
    { lat: 48.229843, lng: 17.498989 },
    { lat: 48.230348, lng: 17.49715 },
    { lat: 48.230787, lng: 17.495529 },
    { lat: 48.231022, lng: 17.494708 },
    { lat: 48.231258, lng: 17.493907 },
    { lat: 48.231264, lng: 17.493884 },
    { lat: 48.23158, lng: 17.492785 },
    { lat: 48.231868, lng: 17.491852 },
    { lat: 48.231881, lng: 17.491584 },
    { lat: 48.231879, lng: 17.490724 },
    { lat: 48.23189, lng: 17.490352 },
    { lat: 48.231883, lng: 17.490272 },
    { lat: 48.231879, lng: 17.490219 },
    { lat: 48.231871, lng: 17.490125 },
    { lat: 48.231893, lng: 17.489172 },
    { lat: 48.2319, lng: 17.488886 },
    { lat: 48.231964, lng: 17.48856 },
    { lat: 48.232304, lng: 17.487108 },
    { lat: 48.232313, lng: 17.487071 },
    { lat: 48.232204, lng: 17.487041 },
    { lat: 48.231731, lng: 17.48688 },
    { lat: 48.231634, lng: 17.48685 },
    { lat: 48.231513, lng: 17.486751 },
    { lat: 48.231478, lng: 17.486723 },
    { lat: 48.231318, lng: 17.486602 },
    { lat: 48.231286, lng: 17.486586 },
    { lat: 48.231285, lng: 17.486585 },
    { lat: 48.231248, lng: 17.486565 },
    { lat: 48.230945, lng: 17.4864 },
    { lat: 48.230768, lng: 17.486301 },
    { lat: 48.2306509, lng: 17.48624 },
    { lat: 48.230395, lng: 17.4860464 },
    { lat: 48.2303218, lng: 17.4859969 },
    { lat: 48.2299486, lng: 17.4859358 },
    { lat: 48.2296857, lng: 17.4856239 },
    { lat: 48.2296573, lng: 17.4856052 },
    { lat: 48.2290859, lng: 17.4851846 },
    { lat: 48.2290826, lng: 17.4851842 },
    { lat: 48.2286721, lng: 17.4849139 },
    { lat: 48.2280778, lng: 17.4844722 },
    { lat: 48.2277706, lng: 17.4841219 },
    { lat: 48.227501, lng: 17.4837213 },
    { lat: 48.2269553, lng: 17.4824571 },
    { lat: 48.2267395, lng: 17.4818427 },
    { lat: 48.2266164, lng: 17.4815798 },
    { lat: 48.2261543, lng: 17.4806484 },
    { lat: 48.2257142, lng: 17.4798473 },
    { lat: 48.2251399, lng: 17.479032 },
    { lat: 48.2248605, lng: 17.4786573 },
    { lat: 48.2247371, lng: 17.4784783 },
    { lat: 48.2245139, lng: 17.4781335 },
    { lat: 48.2243205, lng: 17.4778682 },
    { lat: 48.2238682, lng: 17.4766854 },
    { lat: 48.2236776, lng: 17.476199 },
    { lat: 48.2233539, lng: 17.4754159 },
    { lat: 48.2230773, lng: 17.4748398 },
    { lat: 48.2228151, lng: 17.4743647 },
    { lat: 48.2221846, lng: 17.4732052 },
    { lat: 48.2220602, lng: 17.4730448 },
    { lat: 48.2216649, lng: 17.4726423 },
    { lat: 48.2211293, lng: 17.4720774 },
    { lat: 48.2207775, lng: 17.4717014 },
    { lat: 48.2206112, lng: 17.4715566 },
    { lat: 48.219816, lng: 17.4732718 },
    { lat: 48.2192471, lng: 17.4745532 },
    { lat: 48.2186966, lng: 17.4757547 },
    { lat: 48.2181653, lng: 17.4769562 },
    { lat: 48.2179927, lng: 17.4768519 },
    { lat: 48.2175836, lng: 17.4765864 },
    { lat: 48.217281, lng: 17.4763953 },
    { lat: 48.2163111, lng: 17.4758027 },
    { lat: 48.215881, lng: 17.4755623 },
    { lat: 48.2153532, lng: 17.4752423 },
    { lat: 48.2144914, lng: 17.4746966 },
    { lat: 48.214239, lng: 17.4745294 },
    { lat: 48.2138684, lng: 17.4743177 },
    { lat: 48.2134593, lng: 17.4740514 },
    { lat: 48.2129142, lng: 17.4737077 },
    { lat: 48.212257, lng: 17.4732899 },
    { lat: 48.2117942, lng: 17.4730127 },
    { lat: 48.2112988, lng: 17.4727051 },
    { lat: 48.2109037, lng: 17.4724622 },
    { lat: 48.2103834, lng: 17.4721183 },
    { lat: 48.20959, lng: 17.4716186 },
    { lat: 48.2092929, lng: 17.4714259 },
  ],
  Reca: [
    { lat: 48.2460822, lng: 17.4538857 },
    { lat: 48.2460176, lng: 17.4538719 },
    { lat: 48.2458726, lng: 17.4538409 },
    { lat: 48.2456769, lng: 17.4537137 },
    { lat: 48.2451566, lng: 17.4533857 },
    { lat: 48.2440287, lng: 17.452572 },
    { lat: 48.2428811, lng: 17.4519427 },
    { lat: 48.2427173, lng: 17.4518539 },
    { lat: 48.2414703, lng: 17.451207 },
    { lat: 48.2407517, lng: 17.4508177 },
    { lat: 48.2396057, lng: 17.4501071 },
    { lat: 48.2389173, lng: 17.4497672 },
    { lat: 48.2382294, lng: 17.4495052 },
    { lat: 48.238239, lng: 17.4494422 },
    { lat: 48.2383707, lng: 17.4484238 },
    { lat: 48.2383875, lng: 17.448265 },
    { lat: 48.2383999, lng: 17.4482641 },
    { lat: 48.2383952, lng: 17.4481773 },
    { lat: 48.238381, lng: 17.4479185 },
    { lat: 48.238327, lng: 17.4471891 },
    { lat: 48.2382801, lng: 17.446431 },
    { lat: 48.2382348, lng: 17.4457485 },
    { lat: 48.2381935, lng: 17.4454071 },
    { lat: 48.238229, lng: 17.4451702 },
    { lat: 48.2382477, lng: 17.4447424 },
    { lat: 48.2382993, lng: 17.4440577 },
    { lat: 48.2383038, lng: 17.4438846 },
    { lat: 48.2374704, lng: 17.4436573 },
    { lat: 48.2359605, lng: 17.4433117 },
    { lat: 48.2349675, lng: 17.4430999 },
    { lat: 48.2341551, lng: 17.4429727 },
    { lat: 48.2338576, lng: 17.4429481 },
    { lat: 48.2337274, lng: 17.4429435 },
    { lat: 48.2319359, lng: 17.4428357 },
    { lat: 48.2303996, lng: 17.4430764 },
    { lat: 48.22994, lng: 17.4431455 },
    { lat: 48.2298396, lng: 17.4431643 },
    { lat: 48.2298191, lng: 17.4431624 },
    { lat: 48.2288288, lng: 17.4429045 },
    { lat: 48.2274001, lng: 17.4420164 },
    { lat: 48.2263667, lng: 17.4413533 },
    { lat: 48.2254746, lng: 17.4408168 },
    { lat: 48.225009, lng: 17.4421716 },
    { lat: 48.2249516, lng: 17.4422787 },
    { lat: 48.2232961, lng: 17.4410821 },
    { lat: 48.2220462, lng: 17.4400239 },
    { lat: 48.2213927, lng: 17.4394731 },
    { lat: 48.2204938, lng: 17.4388125 },
    { lat: 48.2201658, lng: 17.4385788 },
    { lat: 48.2197701, lng: 17.4383109 },
    { lat: 48.2196102, lng: 17.4381979 },
    { lat: 48.2184657, lng: 17.4372672 },
    { lat: 48.2172316, lng: 17.4362804 },
    { lat: 48.216898, lng: 17.4360313 },
    { lat: 48.2168746, lng: 17.4360186 },
    { lat: 48.2168338, lng: 17.4359831 },
    { lat: 48.2165847, lng: 17.4357979 },
    { lat: 48.2146027, lng: 17.4344068 },
    { lat: 48.2143955, lng: 17.4342593 },
    { lat: 48.2136334, lng: 17.4337246 },
    { lat: 48.2136251, lng: 17.4338069 },
    { lat: 48.2136498, lng: 17.433821 },
    { lat: 48.213735, lng: 17.4349602 },
    { lat: 48.2134691, lng: 17.4356756 },
    { lat: 48.2129994, lng: 17.4369396 },
    { lat: 48.2127728, lng: 17.4370686 },
    { lat: 48.2112198, lng: 17.435867 },
    { lat: 48.2103109, lng: 17.4345484 },
    { lat: 48.2092253, lng: 17.433867 },
    { lat: 48.2091384, lng: 17.4342851 },
    { lat: 48.209061, lng: 17.4346757 },
    { lat: 48.2088867, lng: 17.4356102 },
    { lat: 48.2088301, lng: 17.4359055 },
    { lat: 48.2089592, lng: 17.4371296 },
    { lat: 48.2091888, lng: 17.4383988 },
    { lat: 48.2093606, lng: 17.4393484 },
    { lat: 48.2094507, lng: 17.4398338 },
    { lat: 48.2097512, lng: 17.4415754 },
    { lat: 48.2099106, lng: 17.4424851 },
    { lat: 48.2101411, lng: 17.4448579 },
    { lat: 48.21031, lng: 17.4466288 },
    { lat: 48.2100382, lng: 17.4482735 },
    { lat: 48.209957, lng: 17.4505258 },
    { lat: 48.2098815, lng: 17.4520821 },
    { lat: 48.2103122, lng: 17.4526297 },
    { lat: 48.2103068, lng: 17.4538715 },
    { lat: 48.2105222, lng: 17.4548796 },
    { lat: 48.2106157, lng: 17.4553308 },
    { lat: 48.2109024, lng: 17.4563402 },
    { lat: 48.2110129, lng: 17.4572622 },
    { lat: 48.2115521, lng: 17.4617423 },
    { lat: 48.2116953, lng: 17.464257 },
    { lat: 48.2117547, lng: 17.465281 },
    { lat: 48.2114381, lng: 17.4667614 },
    { lat: 48.211041, lng: 17.4686404 },
    { lat: 48.2102368, lng: 17.4700373 },
    { lat: 48.2093052, lng: 17.4714064 },
    { lat: 48.2092929, lng: 17.4714259 },
    { lat: 48.20959, lng: 17.4716186 },
    { lat: 48.2103834, lng: 17.4721183 },
    { lat: 48.2109037, lng: 17.4724622 },
    { lat: 48.2112988, lng: 17.4727051 },
    { lat: 48.2117942, lng: 17.4730127 },
    { lat: 48.212257, lng: 17.4732899 },
    { lat: 48.2129142, lng: 17.4737077 },
    { lat: 48.2134593, lng: 17.4740514 },
    { lat: 48.2138684, lng: 17.4743177 },
    { lat: 48.214239, lng: 17.4745294 },
    { lat: 48.2144914, lng: 17.4746966 },
    { lat: 48.2153532, lng: 17.4752423 },
    { lat: 48.215881, lng: 17.4755623 },
    { lat: 48.2163111, lng: 17.4758027 },
    { lat: 48.217281, lng: 17.4763953 },
    { lat: 48.2175836, lng: 17.4765864 },
    { lat: 48.2179927, lng: 17.4768519 },
    { lat: 48.2181653, lng: 17.4769562 },
    { lat: 48.2186966, lng: 17.4757547 },
    { lat: 48.2192471, lng: 17.4745532 },
    { lat: 48.219816, lng: 17.4732718 },
    { lat: 48.2206112, lng: 17.4715566 },
    { lat: 48.2207775, lng: 17.4717014 },
    { lat: 48.2211293, lng: 17.4720774 },
    { lat: 48.2216649, lng: 17.4726423 },
    { lat: 48.2220602, lng: 17.4730448 },
    { lat: 48.2221846, lng: 17.4732052 },
    { lat: 48.2228151, lng: 17.4743647 },
    { lat: 48.2230773, lng: 17.4748398 },
    { lat: 48.2233539, lng: 17.4754159 },
    { lat: 48.2236776, lng: 17.476199 },
    { lat: 48.2238682, lng: 17.4766854 },
    { lat: 48.2243205, lng: 17.4778682 },
    { lat: 48.2245139, lng: 17.4781335 },
    { lat: 48.2247371, lng: 17.4784783 },
    { lat: 48.2248605, lng: 17.4786573 },
    { lat: 48.2251399, lng: 17.479032 },
    { lat: 48.2257142, lng: 17.4798473 },
    { lat: 48.2261543, lng: 17.4806484 },
    { lat: 48.2266164, lng: 17.4815798 },
    { lat: 48.2267395, lng: 17.4818427 },
    { lat: 48.2269553, lng: 17.4824571 },
    { lat: 48.227501, lng: 17.4837213 },
    { lat: 48.2277706, lng: 17.4841219 },
    { lat: 48.2280778, lng: 17.4844722 },
    { lat: 48.2286721, lng: 17.4849139 },
    { lat: 48.2290826, lng: 17.4851842 },
    { lat: 48.2290859, lng: 17.4851846 },
    { lat: 48.2296573, lng: 17.4856052 },
    { lat: 48.2296857, lng: 17.4856239 },
    { lat: 48.2299486, lng: 17.4859358 },
    { lat: 48.2303218, lng: 17.4859969 },
    { lat: 48.230395, lng: 17.4860464 },
    { lat: 48.2306509, lng: 17.48624 },
    { lat: 48.230748, lng: 17.486007 },
    { lat: 48.231183, lng: 17.484985 },
    { lat: 48.231576, lng: 17.484029 },
    { lat: 48.232, lng: 17.483085 },
    { lat: 48.232007, lng: 17.483068 },
    { lat: 48.232347, lng: 17.4823 },
    { lat: 48.232368, lng: 17.482254 },
    { lat: 48.23238, lng: 17.482232 },
    { lat: 48.232657, lng: 17.481837 },
    { lat: 48.232763, lng: 17.481565 },
    { lat: 48.232988, lng: 17.480988 },
    { lat: 48.233023, lng: 17.480868 },
    { lat: 48.233082, lng: 17.480675 },
    { lat: 48.233201, lng: 17.480279 },
    { lat: 48.233314, lng: 17.4799 },
    { lat: 48.23344, lng: 17.479349 },
    { lat: 48.233654, lng: 17.478399 },
    { lat: 48.23381, lng: 17.477729 },
    { lat: 48.233951, lng: 17.477129 },
    { lat: 48.234025, lng: 17.476814 },
    { lat: 48.234078, lng: 17.476587 },
    { lat: 48.234132, lng: 17.476359 },
    { lat: 48.234194, lng: 17.47615 },
    { lat: 48.23444, lng: 17.475348 },
    { lat: 48.234494, lng: 17.475163 },
    { lat: 48.234667, lng: 17.474598 },
    { lat: 48.234858, lng: 17.473994 },
    { lat: 48.235083, lng: 17.473277 },
    { lat: 48.235098, lng: 17.473292 },
    { lat: 48.23527, lng: 17.473457 },
    { lat: 48.235477, lng: 17.473664 },
    { lat: 48.236022, lng: 17.474204 },
    { lat: 48.236219, lng: 17.474391 },
    { lat: 48.236782, lng: 17.474927 },
    { lat: 48.237024, lng: 17.475161 },
    { lat: 48.237297, lng: 17.475426 },
    { lat: 48.237425, lng: 17.475551 },
    { lat: 48.237764, lng: 17.475878 },
    { lat: 48.23828, lng: 17.476373 },
    { lat: 48.238628, lng: 17.476705 },
    { lat: 48.23903, lng: 17.477094 },
    { lat: 48.239051, lng: 17.477115 },
    { lat: 48.239108, lng: 17.477166 },
    { lat: 48.239527, lng: 17.477577 },
    { lat: 48.239707, lng: 17.477753 },
    { lat: 48.240349, lng: 17.478229 },
    { lat: 48.240912, lng: 17.478639 },
    { lat: 48.241205, lng: 17.478727 },
    { lat: 48.241682, lng: 17.478861 },
    { lat: 48.242224, lng: 17.479007 },
    { lat: 48.24252, lng: 17.47888 },
    { lat: 48.242981, lng: 17.478671 },
    { lat: 48.243458, lng: 17.47846 },
    { lat: 48.243503, lng: 17.478449 },
    { lat: 48.243573, lng: 17.478428 },
    { lat: 48.2434998, lng: 17.4779353 },
    { lat: 48.2433799, lng: 17.4770216 },
    { lat: 48.2431704, lng: 17.4751379 },
    { lat: 48.2431921, lng: 17.4738819 },
    { lat: 48.2431985, lng: 17.4735953 },
    { lat: 48.2431903, lng: 17.4729599 },
    { lat: 48.2431025, lng: 17.4717729 },
    { lat: 48.2429958, lng: 17.4713108 },
    { lat: 48.2427731, lng: 17.4702726 },
    { lat: 48.2427198, lng: 17.4700073 },
    { lat: 48.242483, lng: 17.4688943 },
    { lat: 48.2423807, lng: 17.4683055 },
    { lat: 48.2423786, lng: 17.4680792 },
    { lat: 48.242383, lng: 17.4675181 },
    { lat: 48.2430074, lng: 17.4667456 },
    { lat: 48.2433109, lng: 17.4664437 },
    { lat: 48.2438661, lng: 17.4657192 },
    { lat: 48.2441895, lng: 17.4651989 },
    { lat: 48.2447338, lng: 17.4644185 },
    { lat: 48.2448428, lng: 17.4642623 },
    { lat: 48.2450111, lng: 17.4640013 },
    { lat: 48.2450524, lng: 17.4639242 },
    { lat: 48.245242, lng: 17.4627309 },
    { lat: 48.2452677, lng: 17.4615047 },
    { lat: 48.2451618, lng: 17.4603347 },
    { lat: 48.2454502, lng: 17.4591516 },
    { lat: 48.2457598, lng: 17.4580186 },
    { lat: 48.2457929, lng: 17.4568192 },
    { lat: 48.24567, lng: 17.4560879 },
    { lat: 48.2456566, lng: 17.4551648 },
    { lat: 48.2460622, lng: 17.4539428 },
    { lat: 48.2460822, lng: 17.4538857 },
  ],
  Rovinka: [
    { lat: 48.0835, lng: 17.210579 },
    { lat: 48.0838039, lng: 17.2113234 },
    { lat: 48.0839894, lng: 17.211783 },
    { lat: 48.084474, lng: 17.2121862 },
    { lat: 48.08476, lng: 17.2124082 },
    { lat: 48.0855511, lng: 17.2129856 },
    { lat: 48.0858419, lng: 17.2130595 },
    { lat: 48.0861671, lng: 17.2132091 },
    { lat: 48.0862488, lng: 17.2133327 },
    { lat: 48.0863116, lng: 17.213443 },
    { lat: 48.0865939, lng: 17.2138966 },
    { lat: 48.086698, lng: 17.2141012 },
    { lat: 48.0869015, lng: 17.2145261 },
    { lat: 48.0869992, lng: 17.2147862 },
    { lat: 48.0874156, lng: 17.2158767 },
    { lat: 48.0874831, lng: 17.216074 },
    { lat: 48.0876004, lng: 17.2164434 },
    { lat: 48.0881909, lng: 17.2182777 },
    { lat: 48.0884768, lng: 17.219122 },
    { lat: 48.0889236, lng: 17.2203968 },
    { lat: 48.0889903, lng: 17.2206169 },
    { lat: 48.0890493, lng: 17.2208738 },
    { lat: 48.0888076, lng: 17.2209251 },
    { lat: 48.0887455, lng: 17.2209258 },
    { lat: 48.0885496, lng: 17.2210376 },
    { lat: 48.0878812, lng: 17.2212798 },
    { lat: 48.0875611, lng: 17.2213418 },
    { lat: 48.0872343, lng: 17.2214723 },
    { lat: 48.0866119, lng: 17.2216925 },
    { lat: 48.0865456, lng: 17.2217319 },
    { lat: 48.0864327, lng: 17.2217672 },
    { lat: 48.0863835, lng: 17.2218083 },
    { lat: 48.0863186, lng: 17.221919 },
    { lat: 48.0862729, lng: 17.2219444 },
    { lat: 48.0859246, lng: 17.2219398 },
    { lat: 48.0858417, lng: 17.2219664 },
    { lat: 48.0857679, lng: 17.222029 },
    { lat: 48.0856534, lng: 17.2221751 },
    { lat: 48.0855002, lng: 17.2226388 },
    { lat: 48.085342, lng: 17.2230594 },
    { lat: 48.0852928, lng: 17.2232531 },
    { lat: 48.0852377, lng: 17.2234258 },
    { lat: 48.0851299, lng: 17.2239425 },
    { lat: 48.0851215, lng: 17.224104 },
    { lat: 48.08506, lng: 17.2243829 },
    { lat: 48.0849887, lng: 17.2245672 },
    { lat: 48.0849141, lng: 17.2248532 },
    { lat: 48.0848613, lng: 17.2249934 },
    { lat: 48.0847518, lng: 17.2251976 },
    { lat: 48.0846688, lng: 17.2254082 },
    { lat: 48.0846259, lng: 17.2255612 },
    { lat: 48.0845465, lng: 17.2258774 },
    { lat: 48.0844583, lng: 17.2260616 },
    { lat: 48.0843728, lng: 17.2262701 },
    { lat: 48.0842788, lng: 17.226622 },
    { lat: 48.0842776, lng: 17.2270099 },
    { lat: 48.0842423, lng: 17.2272083 },
    { lat: 48.0841319, lng: 17.2274828 },
    { lat: 48.0839695, lng: 17.2277918 },
    { lat: 48.0838973, lng: 17.2280126 },
    { lat: 48.0838417, lng: 17.2283134 },
    { lat: 48.0838379, lng: 17.228442 },
    { lat: 48.0838374, lng: 17.2290433 },
    { lat: 48.0838556, lng: 17.2291805 },
    { lat: 48.0840661, lng: 17.2295916 },
    { lat: 48.0842606, lng: 17.2298982 },
    { lat: 48.0843469, lng: 17.2300741 },
    { lat: 48.0844636, lng: 17.2302827 },
    { lat: 48.0845755, lng: 17.2307045 },
    { lat: 48.0846168, lng: 17.2310019 },
    { lat: 48.0846627, lng: 17.2312496 },
    { lat: 48.0846454, lng: 17.2314469 },
    { lat: 48.0845735, lng: 17.2319432 },
    { lat: 48.0845697, lng: 17.2322421 },
    { lat: 48.0845824, lng: 17.2324655 },
    { lat: 48.0846455, lng: 17.232649 },
    { lat: 48.0848286, lng: 17.2330222 },
    { lat: 48.0849433, lng: 17.2333189 },
    { lat: 48.0852057, lng: 17.2338133 },
    { lat: 48.0854537, lng: 17.2342453 },
    { lat: 48.0858958, lng: 17.2346911 },
    { lat: 48.0862753, lng: 17.2350284 },
    { lat: 48.0864805, lng: 17.2352254 },
    { lat: 48.0865974, lng: 17.2353674 },
    { lat: 48.0869637, lng: 17.23571 },
    { lat: 48.0874665, lng: 17.2363712 },
    { lat: 48.0876748, lng: 17.2365476 },
    { lat: 48.0881419, lng: 17.237333 },
    { lat: 48.0882029, lng: 17.2375372 },
    { lat: 48.0882395, lng: 17.237713 },
    { lat: 48.0882367, lng: 17.2378569 },
    { lat: 48.088249, lng: 17.2381079 },
    { lat: 48.0882849, lng: 17.2383926 },
    { lat: 48.0883192, lng: 17.2389733 },
    { lat: 48.088213, lng: 17.2397698 },
    { lat: 48.0881826, lng: 17.2399397 },
    { lat: 48.0881204, lng: 17.2402103 },
    { lat: 48.0880276, lng: 17.2405285 },
    { lat: 48.088, lng: 17.240708 },
    { lat: 48.0879515, lng: 17.2408786 },
    { lat: 48.0879244, lng: 17.241048 },
    { lat: 48.0878788, lng: 17.2415664 },
    { lat: 48.0878033, lng: 17.2420599 },
    { lat: 48.0875636, lng: 17.2431925 },
    { lat: 48.0874795, lng: 17.2433541 },
    { lat: 48.0873107, lng: 17.2435503 },
    { lat: 48.0869086, lng: 17.2438908 },
    { lat: 48.0863661, lng: 17.2437169 },
    { lat: 48.0860552, lng: 17.2437133 },
    { lat: 48.0859062, lng: 17.2437321 },
    { lat: 48.0856893, lng: 17.2438503 },
    { lat: 48.0853444, lng: 17.2441342 },
    { lat: 48.0852047, lng: 17.2443085 },
    { lat: 48.0851523, lng: 17.2444377 },
    { lat: 48.0850972, lng: 17.2446962 },
    { lat: 48.0850636, lng: 17.2452255 },
    { lat: 48.0850623, lng: 17.2455444 },
    { lat: 48.0850917, lng: 17.2456455 },
    { lat: 48.0852174, lng: 17.2458536 },
    { lat: 48.0853512, lng: 17.2460478 },
    { lat: 48.0855365, lng: 17.2463524 },
    { lat: 48.0858501, lng: 17.246821 },
    { lat: 48.0861297, lng: 17.24727 },
    { lat: 48.0866219, lng: 17.2481116 },
    { lat: 48.0866978, lng: 17.2483011 },
    { lat: 48.0867727, lng: 17.2485613 },
    { lat: 48.0869225, lng: 17.2487911 },
    { lat: 48.0869891, lng: 17.2490097 },
    { lat: 48.0871044, lng: 17.2491637 },
    { lat: 48.0872052, lng: 17.2492862 },
    { lat: 48.0874063, lng: 17.249693 },
    { lat: 48.0879676, lng: 17.2508262 },
    { lat: 48.088109, lng: 17.2511181 },
    { lat: 48.0884398, lng: 17.2517754 },
    { lat: 48.0886535, lng: 17.2522191 },
    { lat: 48.0887689, lng: 17.2523242 },
    { lat: 48.0891523, lng: 17.2527201 },
    { lat: 48.0893578, lng: 17.2530403 },
    { lat: 48.0894409, lng: 17.2531856 },
    { lat: 48.0896319, lng: 17.253475 },
    { lat: 48.0899632, lng: 17.2540903 },
    { lat: 48.0900695, lng: 17.2542786 },
    { lat: 48.090298, lng: 17.2547075 },
    { lat: 48.0907301, lng: 17.2555126 },
    { lat: 48.0910664, lng: 17.256117 },
    { lat: 48.0913005, lng: 17.2567331 },
    { lat: 48.091629, lng: 17.257611 },
    { lat: 48.0921103, lng: 17.258596 },
    { lat: 48.0926355, lng: 17.259578 },
    { lat: 48.0926342, lng: 17.2596447 },
    { lat: 48.0934076, lng: 17.2609785 },
    { lat: 48.0935018, lng: 17.2611382 },
    { lat: 48.0938207, lng: 17.2614838 },
    { lat: 48.0942432, lng: 17.2619574 },
    { lat: 48.0943113, lng: 17.2620309 },
    { lat: 48.0952882, lng: 17.2634011 },
    { lat: 48.0954392, lng: 17.2636174 },
    { lat: 48.0957288, lng: 17.2640636 },
    { lat: 48.0959506, lng: 17.26441 },
    { lat: 48.0961804, lng: 17.264758 },
    { lat: 48.0963153, lng: 17.2649841 },
    { lat: 48.0965011, lng: 17.2653149 },
    { lat: 48.0968388, lng: 17.2659597 },
    { lat: 48.0969464, lng: 17.2662018 },
    { lat: 48.0972009, lng: 17.2667501 },
    { lat: 48.0976164, lng: 17.267342 },
    { lat: 48.0978357, lng: 17.2676662 },
    { lat: 48.0984538, lng: 17.2685864 },
    { lat: 48.0986471, lng: 17.2688783 },
    { lat: 48.0990012, lng: 17.2693941 },
    { lat: 48.0989034, lng: 17.2695552 },
    { lat: 48.0991325, lng: 17.2698924 },
    { lat: 48.0996304, lng: 17.2706028 },
    { lat: 48.099796, lng: 17.2708338 },
    { lat: 48.1000233, lng: 17.2711593 },
    { lat: 48.1002501, lng: 17.2714774 },
    { lat: 48.1003517, lng: 17.2716302 },
    { lat: 48.1008062, lng: 17.2722814 },
    { lat: 48.1010727, lng: 17.272655 },
    { lat: 48.101303, lng: 17.272995 },
    { lat: 48.101455, lng: 17.272536 },
    { lat: 48.101518, lng: 17.27236 },
    { lat: 48.101653, lng: 17.272015 },
    { lat: 48.101797, lng: 17.271618 },
    { lat: 48.102004, lng: 17.27108 },
    { lat: 48.102256, lng: 17.270395 },
    { lat: 48.10233, lng: 17.2702 },
    { lat: 48.102469, lng: 17.269793 },
    { lat: 48.102516, lng: 17.269618 },
    { lat: 48.102551, lng: 17.269464 },
    { lat: 48.102745, lng: 17.26856 },
    { lat: 48.102806, lng: 17.268345 },
    { lat: 48.102845, lng: 17.268202 },
    { lat: 48.103067, lng: 17.267589 },
    { lat: 48.103177, lng: 17.267284 },
    { lat: 48.103456, lng: 17.266601 },
    { lat: 48.103871, lng: 17.265837 },
    { lat: 48.104373, lng: 17.265013 },
    { lat: 48.10446, lng: 17.264908 },
    { lat: 48.104766, lng: 17.264709 },
    { lat: 48.104962, lng: 17.264633 },
    { lat: 48.105074, lng: 17.264575 },
    { lat: 48.105254, lng: 17.264465 },
    { lat: 48.105362, lng: 17.26438 },
    { lat: 48.10556, lng: 17.264177 },
    { lat: 48.105772, lng: 17.263919 },
    { lat: 48.106006, lng: 17.263606 },
    { lat: 48.10609, lng: 17.263465 },
    { lat: 48.106238, lng: 17.263247 },
    { lat: 48.106389, lng: 17.262992 },
    { lat: 48.106577, lng: 17.262613 },
    { lat: 48.106751, lng: 17.262182 },
    { lat: 48.106888, lng: 17.261759 },
    { lat: 48.106998, lng: 17.261357 },
    { lat: 48.107418, lng: 17.259858 },
    { lat: 48.107614, lng: 17.258875 },
    { lat: 48.10769, lng: 17.258542 },
    { lat: 48.107752, lng: 17.258226 },
    { lat: 48.107824, lng: 17.257828 },
    { lat: 48.107878, lng: 17.257483 },
    { lat: 48.107914, lng: 17.257115 },
    { lat: 48.107923, lng: 17.256785 },
    { lat: 48.107924, lng: 17.256735 },
    { lat: 48.107926, lng: 17.256614 },
    { lat: 48.107957, lng: 17.256373 },
    { lat: 48.108008, lng: 17.256025 },
    { lat: 48.108078, lng: 17.255623 },
    { lat: 48.10817, lng: 17.255163 },
    { lat: 48.108191, lng: 17.255092 },
    { lat: 48.108311, lng: 17.254858 },
    { lat: 48.108364, lng: 17.254795 },
    { lat: 48.108504, lng: 17.254676 },
    { lat: 48.108595, lng: 17.254618 },
    { lat: 48.108767, lng: 17.254541 },
    { lat: 48.10887, lng: 17.254475 },
    { lat: 48.108972, lng: 17.254362 },
    { lat: 48.109025, lng: 17.254261 },
    { lat: 48.109126, lng: 17.254021 },
    { lat: 48.109309, lng: 17.253352 },
    { lat: 48.109458, lng: 17.252624 },
    { lat: 48.109478, lng: 17.252346 },
    { lat: 48.109521, lng: 17.252089 },
    { lat: 48.109586, lng: 17.251884 },
    { lat: 48.109654, lng: 17.251704 },
    { lat: 48.109838, lng: 17.251342 },
    { lat: 48.109917, lng: 17.251166 },
    { lat: 48.109968, lng: 17.251017 },
    { lat: 48.109982, lng: 17.250852 },
    { lat: 48.109981, lng: 17.250643 },
    { lat: 48.10997, lng: 17.25045 },
    { lat: 48.109969, lng: 17.250441 },
    { lat: 48.109965, lng: 17.250233 },
    { lat: 48.109969, lng: 17.250086 },
    { lat: 48.109971, lng: 17.249964 },
    { lat: 48.110008, lng: 17.249709 },
    { lat: 48.110052, lng: 17.249478 },
    { lat: 48.110094, lng: 17.249328 },
    { lat: 48.110244, lng: 17.248831 },
    { lat: 48.110379, lng: 17.248509 },
    { lat: 48.110527, lng: 17.248295 },
    { lat: 48.110793, lng: 17.247956 },
    { lat: 48.110979, lng: 17.247716 },
    { lat: 48.111136, lng: 17.247493 },
    { lat: 48.111255, lng: 17.247295 },
    { lat: 48.111356, lng: 17.247144 },
    { lat: 48.111509, lng: 17.246884 },
    { lat: 48.111586, lng: 17.246756 },
    { lat: 48.111863, lng: 17.246313 },
    { lat: 48.111953, lng: 17.246151 },
    { lat: 48.112132, lng: 17.245808 },
    { lat: 48.112253, lng: 17.245521 },
    { lat: 48.112161, lng: 17.245408 },
    { lat: 48.111998, lng: 17.245204 },
    { lat: 48.111816, lng: 17.24495 },
    { lat: 48.111431, lng: 17.24431 },
    { lat: 48.111637, lng: 17.244053 },
    { lat: 48.111963, lng: 17.242932 },
    { lat: 48.111993, lng: 17.242813 },
    { lat: 48.112055, lng: 17.242497 },
    { lat: 48.112085, lng: 17.242149 },
    { lat: 48.112137, lng: 17.241615 },
    { lat: 48.112262, lng: 17.240615 },
    { lat: 48.111441, lng: 17.239183 },
    { lat: 48.111428, lng: 17.239053 },
    { lat: 48.111392, lng: 17.238584 },
    { lat: 48.11139, lng: 17.238456 },
    { lat: 48.111384, lng: 17.238081 },
    { lat: 48.111401, lng: 17.23773 },
    { lat: 48.111421, lng: 17.23762 },
    { lat: 48.111472, lng: 17.237182 },
    { lat: 48.111523, lng: 17.236686 },
    { lat: 48.11158, lng: 17.236059 },
    { lat: 48.111215, lng: 17.235407 },
    { lat: 48.110859, lng: 17.234759 },
    { lat: 48.110306, lng: 17.233808 },
    { lat: 48.109822, lng: 17.232962 },
    { lat: 48.109336, lng: 17.232125 },
    { lat: 48.108972, lng: 17.231485 },
    { lat: 48.108598, lng: 17.230814 },
    { lat: 48.10806, lng: 17.229899 },
    { lat: 48.107341, lng: 17.228287 },
    { lat: 48.107062, lng: 17.227518 },
    { lat: 48.106843, lng: 17.226807 },
    { lat: 48.106728, lng: 17.226424 },
    { lat: 48.106717, lng: 17.226386 },
    { lat: 48.106677, lng: 17.226255 },
    { lat: 48.106666, lng: 17.226216 },
    { lat: 48.10664, lng: 17.226132 },
    { lat: 48.106251, lng: 17.225445 },
    { lat: 48.105826, lng: 17.224763 },
    { lat: 48.105649, lng: 17.22442 },
    { lat: 48.10533, lng: 17.223794 },
    { lat: 48.104791, lng: 17.222596 },
    { lat: 48.104453, lng: 17.221827 },
    { lat: 48.103839, lng: 17.220539 },
    { lat: 48.103765, lng: 17.220384 },
    { lat: 48.103884, lng: 17.219994 },
    { lat: 48.103856, lng: 17.219956 },
    { lat: 48.103765, lng: 17.219842 },
    { lat: 48.103704, lng: 17.219736 },
    { lat: 48.103654, lng: 17.21954 },
    { lat: 48.103622, lng: 17.21923 },
    { lat: 48.103644, lng: 17.219087 },
    { lat: 48.10369, lng: 17.218938 },
    { lat: 48.103811, lng: 17.218468 },
    { lat: 48.103808, lng: 17.218198 },
    { lat: 48.103793, lng: 17.21804 },
    { lat: 48.10372, lng: 17.217783 },
    { lat: 48.103637, lng: 17.217588 },
    { lat: 48.103495, lng: 17.217309 },
    { lat: 48.103383, lng: 17.217147 },
    { lat: 48.103264, lng: 17.217029 },
    { lat: 48.103124, lng: 17.216943 },
    { lat: 48.102682, lng: 17.21673 },
    { lat: 48.102314, lng: 17.21667 },
    { lat: 48.101896, lng: 17.216748 },
    { lat: 48.101739, lng: 17.216759 },
    { lat: 48.10166, lng: 17.216809 },
    { lat: 48.101512, lng: 17.216947 },
    { lat: 48.101462, lng: 17.216996 },
    { lat: 48.10136, lng: 17.217111 },
    { lat: 48.101182, lng: 17.217317 },
    { lat: 48.101073, lng: 17.217427 },
    { lat: 48.100991, lng: 17.217526 },
    { lat: 48.100838, lng: 17.217304 },
    { lat: 48.100748, lng: 17.217215 },
    { lat: 48.100613, lng: 17.217103 },
    { lat: 48.100418, lng: 17.21701 },
    { lat: 48.100032, lng: 17.216728 },
    { lat: 48.09967, lng: 17.21637 },
    { lat: 48.099634, lng: 17.216335 },
    { lat: 48.099537, lng: 17.216423 },
    { lat: 48.099132, lng: 17.216791 },
    { lat: 48.099018, lng: 17.216879 },
    { lat: 48.098841, lng: 17.217069 },
    { lat: 48.098779, lng: 17.217133 },
    { lat: 48.098644, lng: 17.217282 },
    { lat: 48.098019, lng: 17.217838 },
    { lat: 48.097834, lng: 17.217984 },
    { lat: 48.097697, lng: 17.218078 },
    { lat: 48.097553, lng: 17.218079 },
    { lat: 48.097112, lng: 17.218018 },
    { lat: 48.09636, lng: 17.217947 },
    { lat: 48.096049, lng: 17.217869 },
    { lat: 48.095754, lng: 17.217796 },
    { lat: 48.095654, lng: 17.217746 },
    { lat: 48.095038, lng: 17.217331 },
    { lat: 48.094903, lng: 17.21723 },
    { lat: 48.094746, lng: 17.217106 },
    { lat: 48.094604, lng: 17.216988 },
    { lat: 48.094419, lng: 17.216817 },
    { lat: 48.09458, lng: 17.216 },
    { lat: 48.094075, lng: 17.215671 },
    { lat: 48.093594, lng: 17.215296 },
    { lat: 48.093497, lng: 17.215151 },
    { lat: 48.093439, lng: 17.214891 },
    { lat: 48.093335, lng: 17.214951 },
    { lat: 48.093061, lng: 17.215035 },
    { lat: 48.092907, lng: 17.215052 },
    { lat: 48.092712, lng: 17.215099 },
    { lat: 48.092583, lng: 17.214517 },
    { lat: 48.092565, lng: 17.214391 },
    { lat: 48.092511, lng: 17.214185 },
    { lat: 48.092447, lng: 17.213994 },
    { lat: 48.092418, lng: 17.213932 },
    { lat: 48.092372, lng: 17.213881 },
    { lat: 48.092222, lng: 17.213781 },
    { lat: 48.092195, lng: 17.213747 },
    { lat: 48.092667, lng: 17.213163 },
    { lat: 48.09276, lng: 17.213048 },
    { lat: 48.092709, lng: 17.213002 },
    { lat: 48.091444, lng: 17.21186 },
    { lat: 48.090817, lng: 17.211282 },
    { lat: 48.089165, lng: 17.209813 },
    { lat: 48.088939, lng: 17.209614 },
    { lat: 48.087761, lng: 17.208553 },
    { lat: 48.086862, lng: 17.207728 },
    { lat: 48.086809, lng: 17.20768 },
    { lat: 48.086748, lng: 17.207625 },
    { lat: 48.085737, lng: 17.20672 },
    { lat: 48.08544, lng: 17.207289 },
    { lat: 48.085158, lng: 17.208036 },
    { lat: 48.084898, lng: 17.208649 },
    { lat: 48.084328, lng: 17.209523 },
    { lat: 48.083936, lng: 17.210083 },
    { lat: 48.0835, lng: 17.210579 },
  ],
  Senec: [
    { lat: 48.2092253, lng: 17.433867 },
    { lat: 48.2103109, lng: 17.4345484 },
    { lat: 48.2112198, lng: 17.435867 },
    { lat: 48.2127728, lng: 17.4370686 },
    { lat: 48.2129994, lng: 17.4369396 },
    { lat: 48.2134691, lng: 17.4356756 },
    { lat: 48.213735, lng: 17.4349602 },
    { lat: 48.2136498, lng: 17.433821 },
    { lat: 48.2136251, lng: 17.4338069 },
    { lat: 48.2136334, lng: 17.4337246 },
    { lat: 48.2143955, lng: 17.4342593 },
    { lat: 48.2146027, lng: 17.4344068 },
    { lat: 48.2165847, lng: 17.4357979 },
    { lat: 48.2168338, lng: 17.4359831 },
    { lat: 48.2168746, lng: 17.4360186 },
    { lat: 48.216898, lng: 17.4360313 },
    { lat: 48.2172316, lng: 17.4362804 },
    { lat: 48.2184657, lng: 17.4372672 },
    { lat: 48.2196102, lng: 17.4381979 },
    { lat: 48.2197701, lng: 17.4383109 },
    { lat: 48.2201658, lng: 17.4385788 },
    { lat: 48.2204938, lng: 17.4388125 },
    { lat: 48.2213927, lng: 17.4394731 },
    { lat: 48.2220462, lng: 17.4400239 },
    { lat: 48.2232961, lng: 17.4410821 },
    { lat: 48.2249516, lng: 17.4422787 },
    { lat: 48.225009, lng: 17.4421716 },
    { lat: 48.2254746, lng: 17.4408168 },
    { lat: 48.2263667, lng: 17.4413533 },
    { lat: 48.2274001, lng: 17.4420164 },
    { lat: 48.2288288, lng: 17.4429045 },
    { lat: 48.2298191, lng: 17.4431624 },
    { lat: 48.2298396, lng: 17.4431643 },
    { lat: 48.22994, lng: 17.4431455 },
    { lat: 48.2303996, lng: 17.4430764 },
    { lat: 48.2319359, lng: 17.4428357 },
    { lat: 48.2337274, lng: 17.4429435 },
    { lat: 48.2338576, lng: 17.4429481 },
    { lat: 48.2341551, lng: 17.4429727 },
    { lat: 48.2349675, lng: 17.4430999 },
    { lat: 48.2359605, lng: 17.4433117 },
    { lat: 48.2374704, lng: 17.4436573 },
    { lat: 48.2383038, lng: 17.4438846 },
    { lat: 48.2382771, lng: 17.443454 },
    { lat: 48.2381591, lng: 17.4424871 },
    { lat: 48.238119, lng: 17.4419544 },
    { lat: 48.2377537, lng: 17.4408129 },
    { lat: 48.2377023, lng: 17.440681 },
    { lat: 48.2373775, lng: 17.4400256 },
    { lat: 48.2371971, lng: 17.4395269 },
    { lat: 48.2370537, lng: 17.4389702 },
    { lat: 48.2369226, lng: 17.4379909 },
    { lat: 48.2369013, lng: 17.4375749 },
    { lat: 48.2368416, lng: 17.4369326 },
    { lat: 48.2368407, lng: 17.4365354 },
    { lat: 48.2368102, lng: 17.4363977 },
    { lat: 48.2367578, lng: 17.4363538 },
    { lat: 48.2361688, lng: 17.4359626 },
    { lat: 48.2355644, lng: 17.4355263 },
    { lat: 48.2354753, lng: 17.4354556 },
    { lat: 48.2351591, lng: 17.4352842 },
    { lat: 48.234985, lng: 17.4351759 },
    { lat: 48.2346345, lng: 17.4348921 },
    { lat: 48.2344703, lng: 17.4347292 },
    { lat: 48.2341978, lng: 17.4343935 },
    { lat: 48.233708, lng: 17.433616 },
    { lat: 48.2334381, lng: 17.4332494 },
    { lat: 48.2333809, lng: 17.4332037 },
    { lat: 48.2330434, lng: 17.4330338 },
    { lat: 48.2326557, lng: 17.4328018 },
    { lat: 48.231624, lng: 17.4321504 },
    { lat: 48.2309069, lng: 17.4316816 },
    { lat: 48.2307309, lng: 17.4315618 },
    { lat: 48.2286957, lng: 17.4302043 },
    { lat: 48.2278209, lng: 17.4296046 },
    { lat: 48.2268034, lng: 17.4289275 },
    { lat: 48.2264038, lng: 17.4287237 },
    { lat: 48.225919, lng: 17.428424 },
    { lat: 48.2258451, lng: 17.4283915 },
    { lat: 48.225782, lng: 17.4283653 },
    { lat: 48.2256469, lng: 17.4282682 },
    { lat: 48.2246376, lng: 17.4276129 },
    { lat: 48.223152, lng: 17.4266862 },
    { lat: 48.2219585, lng: 17.4259775 },
    { lat: 48.2216747, lng: 17.4258169 },
    { lat: 48.2217163, lng: 17.4256054 },
    { lat: 48.2217362, lng: 17.4254412 },
    { lat: 48.2217503, lng: 17.4252599 },
    { lat: 48.2219048, lng: 17.4243002 },
    { lat: 48.2219407, lng: 17.4240862 },
    { lat: 48.2220533, lng: 17.4233507 },
    { lat: 48.2220808, lng: 17.4231464 },
    { lat: 48.2221498, lng: 17.4226782 },
    { lat: 48.2221715, lng: 17.4225095 },
    { lat: 48.2223164, lng: 17.4215241 },
    { lat: 48.2224331, lng: 17.4207111 },
    { lat: 48.2225034, lng: 17.4202638 },
    { lat: 48.2226541, lng: 17.4194214 },
    { lat: 48.2227204, lng: 17.4190846 },
    { lat: 48.2228749, lng: 17.4184037 },
    { lat: 48.2229377, lng: 17.4181183 },
    { lat: 48.2231269, lng: 17.4172929 },
    { lat: 48.2232056, lng: 17.4169232 },
    { lat: 48.223325, lng: 17.4163977 },
    { lat: 48.2234967, lng: 17.4155986 },
    { lat: 48.2235566, lng: 17.4153042 },
    { lat: 48.2236504, lng: 17.4148705 },
    { lat: 48.2237022, lng: 17.4148672 },
    { lat: 48.2237064, lng: 17.4146004 },
    { lat: 48.2237389, lng: 17.4143371 },
    { lat: 48.2238428, lng: 17.4138609 },
    { lat: 48.2240557, lng: 17.4129556 },
    { lat: 48.224316, lng: 17.4120961 },
    { lat: 48.2244207, lng: 17.4117082 },
    { lat: 48.2244914, lng: 17.4115281 },
    { lat: 48.2245549, lng: 17.4115491 },
    { lat: 48.224576, lng: 17.4114573 },
    { lat: 48.2253984, lng: 17.4121363 },
    { lat: 48.2259724, lng: 17.4126249 },
    { lat: 48.2265715, lng: 17.4131182 },
    { lat: 48.2270999, lng: 17.4134967 },
    { lat: 48.2277272, lng: 17.4139236 },
    { lat: 48.2282874, lng: 17.4142332 },
    { lat: 48.2285687, lng: 17.4143385 },
    { lat: 48.2288718, lng: 17.4145023 },
    { lat: 48.2292237, lng: 17.4146682 },
    { lat: 48.2294425, lng: 17.4147159 },
    { lat: 48.2295853, lng: 17.4147406 },
    { lat: 48.2298338, lng: 17.4147568 },
    { lat: 48.2299866, lng: 17.414744 },
    { lat: 48.2304846, lng: 17.4146925 },
    { lat: 48.2312929, lng: 17.4144945 },
    { lat: 48.231609, lng: 17.4144577 },
    { lat: 48.2322132, lng: 17.414423 },
    { lat: 48.2325569, lng: 17.4144269 },
    { lat: 48.2328697, lng: 17.4145125 },
    { lat: 48.2333484, lng: 17.4146303 },
    { lat: 48.23387, lng: 17.4147479 },
    { lat: 48.2348394, lng: 17.4149964 },
    { lat: 48.2352325, lng: 17.415049 },
    { lat: 48.2354324, lng: 17.4151012 },
    { lat: 48.235916, lng: 17.4152614 },
    { lat: 48.2366074, lng: 17.4156101 },
    { lat: 48.2372948, lng: 17.4159516 },
    { lat: 48.2378177, lng: 17.4161764 },
    { lat: 48.2381685, lng: 17.4163079 },
    { lat: 48.2384377, lng: 17.4164014 },
    { lat: 48.2387761, lng: 17.416533 },
    { lat: 48.2389363, lng: 17.4166172 },
    { lat: 48.2390516, lng: 17.4166874 },
    { lat: 48.2394181, lng: 17.4169401 },
    { lat: 48.2394579, lng: 17.4169613 },
    { lat: 48.2403137, lng: 17.417704 },
    { lat: 48.2412651, lng: 17.4186654 },
    { lat: 48.2420647, lng: 17.4193953 },
    { lat: 48.2430647, lng: 17.4202347 },
    { lat: 48.2434367, lng: 17.4204679 },
    { lat: 48.2439259, lng: 17.4208186 },
    { lat: 48.2444636, lng: 17.4210971 },
    { lat: 48.2446596, lng: 17.4211945 },
    { lat: 48.2449515, lng: 17.4213228 },
    { lat: 48.2451369, lng: 17.4214117 },
    { lat: 48.2452782, lng: 17.4215003 },
    { lat: 48.2457996, lng: 17.4218597 },
    { lat: 48.2465949, lng: 17.4224881 },
    { lat: 48.2469317, lng: 17.4228051 },
    { lat: 48.2478634, lng: 17.4236038 },
    { lat: 48.2484498, lng: 17.424041 },
    { lat: 48.2487648, lng: 17.4242675 },
    { lat: 48.248854, lng: 17.4243527 },
    { lat: 48.2489408, lng: 17.424367 },
    { lat: 48.2493396, lng: 17.4245425 },
    { lat: 48.249367, lng: 17.4244251 },
    { lat: 48.2498346, lng: 17.4223387 },
    { lat: 48.2510994, lng: 17.4230474 },
    { lat: 48.2514761, lng: 17.4232293 },
    { lat: 48.2524903, lng: 17.4229862 },
    { lat: 48.2529197, lng: 17.4229026 },
    { lat: 48.2533022, lng: 17.4220959 },
    { lat: 48.25494, lng: 17.4182364 },
    { lat: 48.2549637, lng: 17.4181146 },
    { lat: 48.2549934, lng: 17.4178236 },
    { lat: 48.2549904, lng: 17.4175848 },
    { lat: 48.2549943, lng: 17.4172636 },
    { lat: 48.2549873, lng: 17.4167564 },
    { lat: 48.254968, lng: 17.4165283 },
    { lat: 48.255187, lng: 17.4139371 },
    { lat: 48.2552464, lng: 17.4132345 },
    { lat: 48.2552945, lng: 17.4128274 },
    { lat: 48.2552922, lng: 17.4126528 },
    { lat: 48.2548049, lng: 17.4125589 },
    { lat: 48.254896, lng: 17.4121532 },
    { lat: 48.2549602, lng: 17.4119594 },
    { lat: 48.2550604, lng: 17.4117623 },
    { lat: 48.2551434, lng: 17.4114406 },
    { lat: 48.255053, lng: 17.4110596 },
    { lat: 48.2549828, lng: 17.4107096 },
    { lat: 48.2548995, lng: 17.4102093 },
    { lat: 48.2548066, lng: 17.4097536 },
    { lat: 48.2547786, lng: 17.4093931 },
    { lat: 48.2546745, lng: 17.4087978 },
    { lat: 48.2545917, lng: 17.4084454 },
    { lat: 48.2543222, lng: 17.4072196 },
    { lat: 48.2548376, lng: 17.4062885 },
    { lat: 48.2549616, lng: 17.406043 },
    { lat: 48.2550265, lng: 17.4058762 },
    { lat: 48.2551722, lng: 17.4054599 },
    { lat: 48.2552734, lng: 17.4051559 },
    { lat: 48.2554309, lng: 17.404557 },
    { lat: 48.2555257, lng: 17.4042262 },
    { lat: 48.2555931, lng: 17.4040471 },
    { lat: 48.2558845, lng: 17.4034041 },
    { lat: 48.2563429, lng: 17.40262 },
    { lat: 48.2566769, lng: 17.4019733 },
    { lat: 48.2567252, lng: 17.401864 },
    { lat: 48.2568426, lng: 17.4015696 },
    { lat: 48.2570073, lng: 17.4012021 },
    { lat: 48.257229, lng: 17.4007562 },
    { lat: 48.2577989, lng: 17.3996031 },
    { lat: 48.2583848, lng: 17.3983326 },
    { lat: 48.2585593, lng: 17.3980298 },
    { lat: 48.2588068, lng: 17.3976833 },
    { lat: 48.2591431, lng: 17.3972629 },
    { lat: 48.2596451, lng: 17.3965416 },
    { lat: 48.2599926, lng: 17.3960341 },
    { lat: 48.2602022, lng: 17.3957142 },
    { lat: 48.2604335, lng: 17.3953988 },
    { lat: 48.260662, lng: 17.3951268 },
    { lat: 48.2609367, lng: 17.3948533 },
    { lat: 48.261647, lng: 17.3941228 },
    { lat: 48.261894, lng: 17.3939259 },
    { lat: 48.2622028, lng: 17.3936195 },
    { lat: 48.2629068, lng: 17.3931215 },
    { lat: 48.2634021, lng: 17.3927159 },
    { lat: 48.2635706, lng: 17.3925973 },
    { lat: 48.2639091, lng: 17.3923669 },
    { lat: 48.2642766, lng: 17.392095 },
    { lat: 48.2644117, lng: 17.3919492 },
    { lat: 48.2644974, lng: 17.3918791 },
    { lat: 48.2646049, lng: 17.3916747 },
    { lat: 48.2647206, lng: 17.3915251 },
    { lat: 48.265185, lng: 17.3906128 },
    { lat: 48.2654446, lng: 17.3902247 },
    { lat: 48.2659739, lng: 17.3892998 },
    { lat: 48.266319, lng: 17.3885997 },
    { lat: 48.2666805, lng: 17.3879259 },
    { lat: 48.2667463, lng: 17.3877732 },
    { lat: 48.2671114, lng: 17.3868952 },
    { lat: 48.2673847, lng: 17.3863899 },
    { lat: 48.2674265, lng: 17.3863035 },
    { lat: 48.2674401, lng: 17.3861493 },
    { lat: 48.2674615, lng: 17.3861123 },
    { lat: 48.267655, lng: 17.3856348 },
    { lat: 48.2679246, lng: 17.385213 },
    { lat: 48.2680926, lng: 17.3850005 },
    { lat: 48.2683369, lng: 17.3847474 },
    { lat: 48.268871, lng: 17.3842927 },
    { lat: 48.2691959, lng: 17.3839246 },
    { lat: 48.2693236, lng: 17.3837342 },
    { lat: 48.2706381, lng: 17.3817389 },
    { lat: 48.2710749, lng: 17.3810236 },
    { lat: 48.2712993, lng: 17.3804992 },
    { lat: 48.2714918, lng: 17.3800049 },
    { lat: 48.2715987, lng: 17.3796881 },
    { lat: 48.2719684, lng: 17.3786889 },
    { lat: 48.2722932, lng: 17.3778682 },
    { lat: 48.2724996, lng: 17.3772762 },
    { lat: 48.2729712, lng: 17.3763864 },
    { lat: 48.2732441, lng: 17.3758608 },
    { lat: 48.2733603, lng: 17.3756848 },
    { lat: 48.2735128, lng: 17.3753731 },
    { lat: 48.2735513, lng: 17.3751499 },
    { lat: 48.2735922, lng: 17.3746516 },
    { lat: 48.2736001, lng: 17.3744439 },
    { lat: 48.2735936, lng: 17.3737225 },
    { lat: 48.2737036, lng: 17.3723797 },
    { lat: 48.2737984, lng: 17.3707025 },
    { lat: 48.273829, lng: 17.370323 },
    { lat: 48.273805, lng: 17.370315 },
    { lat: 48.273808, lng: 17.370176 },
    { lat: 48.273804, lng: 17.369483 },
    { lat: 48.273801, lng: 17.369115 },
    { lat: 48.273811, lng: 17.368287 },
    { lat: 48.273824, lng: 17.367792 },
    { lat: 48.273823, lng: 17.367624 },
    { lat: 48.273821, lng: 17.367489 },
    { lat: 48.273841, lng: 17.367186 },
    { lat: 48.273884, lng: 17.366841 },
    { lat: 48.273983, lng: 17.366512 },
    { lat: 48.274201, lng: 17.365795 },
    { lat: 48.274289, lng: 17.365572 },
    { lat: 48.274365, lng: 17.365289 },
    { lat: 48.274526, lng: 17.364727 },
    { lat: 48.274758, lng: 17.3641 },
    { lat: 48.274843, lng: 17.363891 },
    { lat: 48.274854, lng: 17.363863 },
    { lat: 48.274877, lng: 17.363803 },
    { lat: 48.274888, lng: 17.36377 },
    { lat: 48.274942, lng: 17.363611 },
    { lat: 48.275016, lng: 17.363249 },
    { lat: 48.275122, lng: 17.362874 },
    { lat: 48.275298, lng: 17.362194 },
    { lat: 48.275473, lng: 17.361634 },
    { lat: 48.275622, lng: 17.361073 },
    { lat: 48.275658, lng: 17.360945 },
    { lat: 48.27571, lng: 17.360757 },
    { lat: 48.275715, lng: 17.360732 },
    { lat: 48.275785, lng: 17.3604 },
    { lat: 48.275803, lng: 17.360334 },
    { lat: 48.275818, lng: 17.360282 },
    { lat: 48.275931, lng: 17.359866 },
    { lat: 48.276088, lng: 17.359127 },
    { lat: 48.276194, lng: 17.358695 },
    { lat: 48.276431, lng: 17.357876 },
    { lat: 48.276474, lng: 17.35768 },
    { lat: 48.276531, lng: 17.35731 },
    { lat: 48.276733, lng: 17.356641 },
    { lat: 48.27682, lng: 17.356064 },
    { lat: 48.276907, lng: 17.35566 },
    { lat: 48.276962, lng: 17.355318 },
    { lat: 48.277039, lng: 17.355 },
    { lat: 48.277135, lng: 17.354712 },
    { lat: 48.277265, lng: 17.354452 },
    { lat: 48.277488, lng: 17.354148 },
    { lat: 48.277647, lng: 17.353681 },
    { lat: 48.27782, lng: 17.352907 },
    { lat: 48.277962, lng: 17.351918 },
    { lat: 48.277996, lng: 17.351699 },
    { lat: 48.278024, lng: 17.351508 },
    { lat: 48.278071, lng: 17.351175 },
    { lat: 48.278088, lng: 17.350971 },
    { lat: 48.278354, lng: 17.35002 },
    { lat: 48.278443, lng: 17.349698 },
    { lat: 48.278519, lng: 17.348958 },
    { lat: 48.278528, lng: 17.348907 },
    { lat: 48.278545, lng: 17.348844 },
    { lat: 48.278412, lng: 17.348802 },
    { lat: 48.277829, lng: 17.348703 },
    { lat: 48.277255, lng: 17.348707 },
    { lat: 48.276742, lng: 17.348688 },
    { lat: 48.276598, lng: 17.348694 },
    { lat: 48.276255, lng: 17.348639 },
    { lat: 48.275621, lng: 17.3486 },
    { lat: 48.275158, lng: 17.348427 },
    { lat: 48.274764, lng: 17.348229 },
    { lat: 48.27453, lng: 17.348178 },
    { lat: 48.274433, lng: 17.348121 },
    { lat: 48.274135, lng: 17.347898 },
    { lat: 48.273661, lng: 17.347414 },
    { lat: 48.273407, lng: 17.347305 },
    { lat: 48.273144, lng: 17.347457 },
    { lat: 48.272817, lng: 17.347453 },
    { lat: 48.272556, lng: 17.347451 },
    { lat: 48.272491, lng: 17.347466 },
    { lat: 48.272215, lng: 17.34753 },
    { lat: 48.271463, lng: 17.347291 },
    { lat: 48.27108, lng: 17.347253 },
    { lat: 48.270982, lng: 17.347258 },
    { lat: 48.270578, lng: 17.34729 },
    { lat: 48.270191, lng: 17.347289 },
    { lat: 48.270084, lng: 17.347297 },
    { lat: 48.269523, lng: 17.347356 },
    { lat: 48.269385, lng: 17.347364 },
    { lat: 48.26927, lng: 17.347372 },
    { lat: 48.268911, lng: 17.347394 },
    { lat: 48.268485, lng: 17.347333 },
    { lat: 48.268286, lng: 17.347305 },
    { lat: 48.268192, lng: 17.347264 },
    { lat: 48.26797, lng: 17.347165 },
    { lat: 48.267838, lng: 17.347157 },
    { lat: 48.26676, lng: 17.347264 },
    { lat: 48.266458, lng: 17.347266 },
    { lat: 48.266058, lng: 17.347271 },
    { lat: 48.265969, lng: 17.347293 },
    { lat: 48.265605, lng: 17.347379 },
    { lat: 48.265551, lng: 17.347386 },
    { lat: 48.265177, lng: 17.347438 },
    { lat: 48.264925, lng: 17.3474286 },
    { lat: 48.264612, lng: 17.347417 },
    { lat: 48.264353, lng: 17.347455 },
    { lat: 48.2635, lng: 17.347442 },
    { lat: 48.263197, lng: 17.347472 },
    { lat: 48.263149, lng: 17.347476 },
    { lat: 48.263079, lng: 17.347483 },
    { lat: 48.262378, lng: 17.34767 },
    { lat: 48.262243, lng: 17.347638 },
    { lat: 48.261871, lng: 17.347571 },
    { lat: 48.261731, lng: 17.347518 },
    { lat: 48.261393, lng: 17.347373 },
    { lat: 48.260943, lng: 17.347335 },
    { lat: 48.260582, lng: 17.347249 },
    { lat: 48.259956, lng: 17.347177 },
    { lat: 48.259451, lng: 17.34705 },
    { lat: 48.259206, lng: 17.347036 },
    { lat: 48.258976, lng: 17.347033 },
    { lat: 48.258741, lng: 17.347015 },
    { lat: 48.258618, lng: 17.347017 },
    { lat: 48.258352, lng: 17.347106 },
    { lat: 48.258082, lng: 17.347133 },
    { lat: 48.257698, lng: 17.347066 },
    { lat: 48.257357, lng: 17.346963 },
    { lat: 48.257105, lng: 17.346942 },
    { lat: 48.25665, lng: 17.346874 },
    { lat: 48.256491, lng: 17.346848 },
    { lat: 48.255914, lng: 17.346899 },
    { lat: 48.255524, lng: 17.346968 },
    { lat: 48.255126, lng: 17.346988 },
    { lat: 48.254881, lng: 17.346961 },
    { lat: 48.254545, lng: 17.346932 },
    { lat: 48.254228, lng: 17.34685 },
    { lat: 48.253883, lng: 17.346706 },
    { lat: 48.253678, lng: 17.346574 },
    { lat: 48.253507, lng: 17.346396 },
    { lat: 48.253453, lng: 17.346285 },
    { lat: 48.2533, lng: 17.345763 },
    { lat: 48.253238, lng: 17.345565 },
    { lat: 48.253184, lng: 17.345411 },
    { lat: 48.252831, lng: 17.344401 },
    { lat: 48.252768, lng: 17.344215 },
    { lat: 48.252672, lng: 17.343928 },
    { lat: 48.25255, lng: 17.343445 },
    { lat: 48.252477, lng: 17.343129 },
    { lat: 48.252453, lng: 17.342946 },
    { lat: 48.252421, lng: 17.342778 },
    { lat: 48.252311, lng: 17.342416 },
    { lat: 48.252115, lng: 17.34188 },
    { lat: 48.252069, lng: 17.341646 },
    { lat: 48.252034, lng: 17.341448 },
    { lat: 48.251982, lng: 17.34124 },
    { lat: 48.251952, lng: 17.341105 },
    { lat: 48.251743, lng: 17.340149 },
    { lat: 48.251449, lng: 17.339282 },
    { lat: 48.251192, lng: 17.338731 },
    { lat: 48.250984, lng: 17.338417 },
    { lat: 48.250772, lng: 17.337935 },
    { lat: 48.250534, lng: 17.337616 },
    { lat: 48.250518, lng: 17.33759 },
    { lat: 48.250482, lng: 17.337521 },
    { lat: 48.250468, lng: 17.337494 },
    { lat: 48.250465, lng: 17.33749 },
    { lat: 48.250389, lng: 17.337381 },
    { lat: 48.250243, lng: 17.337163 },
    { lat: 48.249746, lng: 17.336015 },
    { lat: 48.248608, lng: 17.334833 },
    { lat: 48.2485901, lng: 17.3347667 },
    { lat: 48.2485727, lng: 17.3348115 },
    { lat: 48.2482537, lng: 17.335338 },
    { lat: 48.247609, lng: 17.3362879 },
    { lat: 48.2474045, lng: 17.3365801 },
    { lat: 48.2464468, lng: 17.3379929 },
    { lat: 48.2463938, lng: 17.3380634 },
    { lat: 48.2462848, lng: 17.3382442 },
    { lat: 48.2460864, lng: 17.3385431 },
    { lat: 48.2456787, lng: 17.3391445 },
    { lat: 48.2455483, lng: 17.3393276 },
    { lat: 48.2451185, lng: 17.3399735 },
    { lat: 48.2434616, lng: 17.3424201 },
    { lat: 48.2427964, lng: 17.3434184 },
    { lat: 48.2424219, lng: 17.3439944 },
    { lat: 48.2420504, lng: 17.344514 },
    { lat: 48.2412986, lng: 17.3456038 },
    { lat: 48.2408424, lng: 17.3462273 },
    { lat: 48.24046, lng: 17.3467163 },
    { lat: 48.2397821, lng: 17.3478509 },
    { lat: 48.2396238, lng: 17.3481929 },
    { lat: 48.2395364, lng: 17.3484281 },
    { lat: 48.2393936, lng: 17.34892 },
    { lat: 48.2392304, lng: 17.3492391 },
    { lat: 48.2387973, lng: 17.3500232 },
    { lat: 48.2383437, lng: 17.350773 },
    { lat: 48.2380457, lng: 17.3513435 },
    { lat: 48.2377444, lng: 17.3518493 },
    { lat: 48.2372909, lng: 17.3525636 },
    { lat: 48.2369136, lng: 17.3531152 },
    { lat: 48.2360433, lng: 17.3543126 },
    { lat: 48.2354829, lng: 17.3550341 },
    { lat: 48.2350242, lng: 17.3556517 },
    { lat: 48.234742, lng: 17.3560008 },
    { lat: 48.2344074, lng: 17.3564101 },
    { lat: 48.2340745, lng: 17.3568454 },
    { lat: 48.2335661, lng: 17.3574991 },
    { lat: 48.2333483, lng: 17.3577628 },
    { lat: 48.2328014, lng: 17.3584652 },
    { lat: 48.2325196, lng: 17.3588353 },
    { lat: 48.2320248, lng: 17.3594913 },
    { lat: 48.2314577, lng: 17.3602152 },
    { lat: 48.2312591, lng: 17.3604591 },
    { lat: 48.2308566, lng: 17.3609874 },
    { lat: 48.2297178, lng: 17.3624849 },
    { lat: 48.2296024, lng: 17.362621 },
    { lat: 48.2288363, lng: 17.3636168 },
    { lat: 48.2285621, lng: 17.3639485 },
    { lat: 48.2281689, lng: 17.3644467 },
    { lat: 48.2279036, lng: 17.3647418 },
    { lat: 48.227574, lng: 17.365074 },
    { lat: 48.2272221, lng: 17.3654799 },
    { lat: 48.2271223, lng: 17.3655595 },
    { lat: 48.2267502, lng: 17.3661729 },
    { lat: 48.2265546, lng: 17.3665328 },
    { lat: 48.2264623, lng: 17.3666418 },
    { lat: 48.2263635, lng: 17.3664772 },
    { lat: 48.2259329, lng: 17.3670788 },
    { lat: 48.225669, lng: 17.3673617 },
    { lat: 48.2252957, lng: 17.3678348 },
    { lat: 48.2250433, lng: 17.3682419 },
    { lat: 48.2249763, lng: 17.3683752 },
    { lat: 48.2248014, lng: 17.3686704 },
    { lat: 48.2245996, lng: 17.3689527 },
    { lat: 48.2243095, lng: 17.3693519 },
    { lat: 48.2242533, lng: 17.3694446 },
    { lat: 48.2240226, lng: 17.3698715 },
    { lat: 48.2238459, lng: 17.3701561 },
    { lat: 48.2236262, lng: 17.370528 },
    { lat: 48.2235587, lng: 17.370636 },
    { lat: 48.2234178, lng: 17.3707445 },
    { lat: 48.2234049, lng: 17.3707191 },
    { lat: 48.2233192, lng: 17.3705493 },
    { lat: 48.2232693, lng: 17.3704078 },
    { lat: 48.2232071, lng: 17.3702851 },
    { lat: 48.2231107, lng: 17.3703313 },
    { lat: 48.2230222, lng: 17.3704111 },
    { lat: 48.2227818, lng: 17.3706569 },
    { lat: 48.2226793, lng: 17.3708164 },
    { lat: 48.2226227, lng: 17.3708838 },
    { lat: 48.2221322, lng: 17.3715017 },
    { lat: 48.2219678, lng: 17.3717167 },
    { lat: 48.2217381, lng: 17.3720555 },
    { lat: 48.2217097, lng: 17.3720875 },
    { lat: 48.2216491, lng: 17.3721091 },
    { lat: 48.220232, lng: 17.3694939 },
    { lat: 48.2202296, lng: 17.3694916 },
    { lat: 48.2195211, lng: 17.3681073 },
    { lat: 48.2195187, lng: 17.3681043 },
    { lat: 48.219494, lng: 17.3680537 },
    { lat: 48.2188661, lng: 17.3688151 },
    { lat: 48.2160453, lng: 17.3722647 },
    { lat: 48.2151635, lng: 17.3733395 },
    { lat: 48.2127752, lng: 17.3762521 },
    { lat: 48.2123938, lng: 17.3767255 },
    { lat: 48.2123817, lng: 17.3766773 },
    { lat: 48.2123345, lng: 17.3767315 },
    { lat: 48.2122438, lng: 17.3759852 },
    { lat: 48.2122096, lng: 17.3756786 },
    { lat: 48.2121867, lng: 17.3754738 },
    { lat: 48.2121619, lng: 17.3751817 },
    { lat: 48.2121376, lng: 17.374975 },
    { lat: 48.2121175, lng: 17.3747856 },
    { lat: 48.2120948, lng: 17.3746116 },
    { lat: 48.2120578, lng: 17.3742978 },
    { lat: 48.2120031, lng: 17.3738935 },
    { lat: 48.2119568, lng: 17.3736164 },
    { lat: 48.2118612, lng: 17.3733127 },
    { lat: 48.2116561, lng: 17.3727496 },
    { lat: 48.2113958, lng: 17.3720693 },
    { lat: 48.2111896, lng: 17.3715428 },
    { lat: 48.2105424, lng: 17.3699189 },
    { lat: 48.2101184, lng: 17.3688251 },
    { lat: 48.2099057, lng: 17.3682681 },
    { lat: 48.2096836, lng: 17.3677574 },
    { lat: 48.2095114, lng: 17.3674144 },
    { lat: 48.2094683, lng: 17.3674704 },
    { lat: 48.2094509, lng: 17.3674321 },
    { lat: 48.2094406, lng: 17.3674093 },
    { lat: 48.2090704, lng: 17.3678455 },
    { lat: 48.2089254, lng: 17.3679758 },
    { lat: 48.208354, lng: 17.3684786 },
    { lat: 48.2073574, lng: 17.3693031 },
    { lat: 48.2070003, lng: 17.3695767 },
    { lat: 48.2060967, lng: 17.3704213 },
    { lat: 48.2058993, lng: 17.370549 },
    { lat: 48.2057597, lng: 17.3706582 },
    { lat: 48.2054495, lng: 17.3708895 },
    { lat: 48.2044598, lng: 17.3716116 },
    { lat: 48.204043, lng: 17.3719049 },
    { lat: 48.2030359, lng: 17.3727046 },
    { lat: 48.2022463, lng: 17.3733255 },
    { lat: 48.2018652, lng: 17.3735958 },
    { lat: 48.200546, lng: 17.3743397 },
    { lat: 48.2004068, lng: 17.3744201 },
    { lat: 48.2002257, lng: 17.3745523 },
    { lat: 48.1999873, lng: 17.3746226 },
    { lat: 48.1998075, lng: 17.3756889 },
    { lat: 48.1997477, lng: 17.3760186 },
    { lat: 48.1995907, lng: 17.3766583 },
    { lat: 48.1993723, lng: 17.3774455 },
    { lat: 48.1992786, lng: 17.3778614 },
    { lat: 48.1992479, lng: 17.3780482 },
    { lat: 48.1992218, lng: 17.3789272 },
    { lat: 48.1992117, lng: 17.3790657 },
    { lat: 48.199103, lng: 17.3800142 },
    { lat: 48.1990823, lng: 17.3803525 },
    { lat: 48.1990909, lng: 17.3805709 },
    { lat: 48.1991285, lng: 17.3808341 },
    { lat: 48.1991454, lng: 17.3809896 },
    { lat: 48.1991534, lng: 17.3811651 },
    { lat: 48.1991413, lng: 17.3815672 },
    { lat: 48.199107, lng: 17.3818206 },
    { lat: 48.1989074, lng: 17.3827232 },
    { lat: 48.1987977, lng: 17.3831193 },
    { lat: 48.1987228, lng: 17.3833551 },
    { lat: 48.1985178, lng: 17.3839342 },
    { lat: 48.1984316, lng: 17.3841369 },
    { lat: 48.1983026, lng: 17.38441 },
    { lat: 48.1980032, lng: 17.3848922 },
    { lat: 48.1976403, lng: 17.3854249 },
    { lat: 48.1970776, lng: 17.3862159 },
    { lat: 48.1969434, lng: 17.3863632 },
    { lat: 48.1964989, lng: 17.3868512 },
    { lat: 48.1962598, lng: 17.3871862 },
    { lat: 48.1960484, lng: 17.3876139 },
    { lat: 48.1960231, lng: 17.3876797 },
    { lat: 48.1959508, lng: 17.3878673 },
    { lat: 48.1958215, lng: 17.3882917 },
    { lat: 48.1957679, lng: 17.3885259 },
    { lat: 48.1956161, lng: 17.3893151 },
    { lat: 48.1954949, lng: 17.3899316 },
    { lat: 48.1949441, lng: 17.3916161 },
    { lat: 48.1948673, lng: 17.391842 },
    { lat: 48.1948541, lng: 17.3919165 },
    { lat: 48.1948368, lng: 17.3920137 },
    { lat: 48.1949653, lng: 17.3921325 },
    { lat: 48.1951411, lng: 17.392264 },
    { lat: 48.195388, lng: 17.3924653 },
    { lat: 48.1955449, lng: 17.3926536 },
    { lat: 48.1957391, lng: 17.3928778 },
    { lat: 48.195989, lng: 17.3932966 },
    { lat: 48.196158, lng: 17.3936199 },
    { lat: 48.1962794, lng: 17.3938869 },
    { lat: 48.196428, lng: 17.3943484 },
    { lat: 48.196584, lng: 17.3949719 },
    { lat: 48.196632, lng: 17.395258 },
    { lat: 48.1966922, lng: 17.3956622 },
    { lat: 48.1967473, lng: 17.3966789 },
    { lat: 48.1967425, lng: 17.3969684 },
    { lat: 48.1967246, lng: 17.3975607 },
    { lat: 48.1966725, lng: 17.3979568 },
    { lat: 48.1966319, lng: 17.3980456 },
    { lat: 48.196606, lng: 17.3981853 },
    { lat: 48.1965474, lng: 17.3984117 },
    { lat: 48.1964885, lng: 17.3987025 },
    { lat: 48.1963853, lng: 17.3990792 },
    { lat: 48.1963125, lng: 17.3992958 },
    { lat: 48.1962068, lng: 17.3995629 },
    { lat: 48.1961358, lng: 17.3997735 },
    { lat: 48.1960728, lng: 17.3998808 },
    { lat: 48.1959141, lng: 17.4001852 },
    { lat: 48.1957726, lng: 17.4004389 },
    { lat: 48.1956743, lng: 17.4005943 },
    { lat: 48.1955522, lng: 17.4008014 },
    { lat: 48.1954843, lng: 17.400867 },
    { lat: 48.1951369, lng: 17.4011703 },
    { lat: 48.1948773, lng: 17.4013306 },
    { lat: 48.1948104, lng: 17.4013766 },
    { lat: 48.1947162, lng: 17.4014224 },
    { lat: 48.1941406, lng: 17.4015672 },
    { lat: 48.1936133, lng: 17.4016576 },
    { lat: 48.1935768, lng: 17.4016696 },
    { lat: 48.1929832, lng: 17.4017461 },
    { lat: 48.1927487, lng: 17.4017693 },
    { lat: 48.1926495, lng: 17.4018261 },
    { lat: 48.192565, lng: 17.401829 },
    { lat: 48.1923747, lng: 17.4020298 },
    { lat: 48.1923488, lng: 17.4020464 },
    { lat: 48.1921558, lng: 17.4021531 },
    { lat: 48.1920107, lng: 17.4022992 },
    { lat: 48.1918483, lng: 17.4025468 },
    { lat: 48.1917819, lng: 17.402672 },
    { lat: 48.1917166, lng: 17.4028143 },
    { lat: 48.1915066, lng: 17.4033888 },
    { lat: 48.191492, lng: 17.4034577 },
    { lat: 48.1914616, lng: 17.4035297 },
    { lat: 48.191369, lng: 17.4037906 },
    { lat: 48.1913063, lng: 17.4040042 },
    { lat: 48.1910917, lng: 17.4049578 },
    { lat: 48.190975, lng: 17.4056301 },
    { lat: 48.1909401, lng: 17.4058751 },
    { lat: 48.1909117, lng: 17.4060086 },
    { lat: 48.1907815, lng: 17.4066626 },
    { lat: 48.1906719, lng: 17.4072699 },
    { lat: 48.1906751, lng: 17.4074062 },
    { lat: 48.1907158, lng: 17.4080133 },
    { lat: 48.1907996, lng: 17.4084505 },
    { lat: 48.190849, lng: 17.4086368 },
    { lat: 48.19099, lng: 17.4090157 },
    { lat: 48.1912952, lng: 17.4094519 },
    { lat: 48.1914859, lng: 17.4096235 },
    { lat: 48.1917622, lng: 17.4097901 },
    { lat: 48.1918378, lng: 17.4098069 },
    { lat: 48.1923794, lng: 17.4098675 },
    { lat: 48.1926715, lng: 17.4097911 },
    { lat: 48.1927727, lng: 17.4097502 },
    { lat: 48.1930044, lng: 17.4096311 },
    { lat: 48.1931988, lng: 17.4094956 },
    { lat: 48.1938263, lng: 17.4090358 },
    { lat: 48.1939317, lng: 17.4089716 },
    { lat: 48.1941625, lng: 17.4087707 },
    { lat: 48.1943325, lng: 17.408604 },
    { lat: 48.1945127, lng: 17.4084063 },
    { lat: 48.1946517, lng: 17.408218 },
    { lat: 48.1947473, lng: 17.408471 },
    { lat: 48.1955174, lng: 17.4080167 },
    { lat: 48.1958098, lng: 17.4078575 },
    { lat: 48.1961113, lng: 17.4074758 },
    { lat: 48.1964176, lng: 17.4072182 },
    { lat: 48.1969512, lng: 17.4063937 },
    { lat: 48.197263, lng: 17.4059597 },
    { lat: 48.198036, lng: 17.404857 },
    { lat: 48.1983386, lng: 17.4043518 },
    { lat: 48.1985813, lng: 17.4039337 },
    { lat: 48.1987106, lng: 17.4037695 },
    { lat: 48.1987577, lng: 17.4037297 },
    { lat: 48.1989631, lng: 17.4036398 },
    { lat: 48.1991863, lng: 17.4035481 },
    { lat: 48.1995521, lng: 17.40351 },
    { lat: 48.1997489, lng: 17.4035151 },
    { lat: 48.199909, lng: 17.4035281 },
    { lat: 48.2001538, lng: 17.4035397 },
    { lat: 48.2002349, lng: 17.4035372 },
    { lat: 48.2003754, lng: 17.4035606 },
    { lat: 48.2005199, lng: 17.4035936 },
    { lat: 48.2007944, lng: 17.4038189 },
    { lat: 48.2010713, lng: 17.4041165 },
    { lat: 48.2011401, lng: 17.4042027 },
    { lat: 48.2013072, lng: 17.4044798 },
    { lat: 48.2017038, lng: 17.4051924 },
    { lat: 48.2018099, lng: 17.4053993 },
    { lat: 48.2019047, lng: 17.4056397 },
    { lat: 48.2023737, lng: 17.4071883 },
    { lat: 48.2025015, lng: 17.4076427 },
    { lat: 48.2025788, lng: 17.4081004 },
    { lat: 48.202615, lng: 17.4082911 },
    { lat: 48.2026793, lng: 17.4084997 },
    { lat: 48.2027268, lng: 17.4087783 },
    { lat: 48.2027114, lng: 17.4092181 },
    { lat: 48.2027029, lng: 17.4096257 },
    { lat: 48.2028276, lng: 17.409616 },
    { lat: 48.2027337, lng: 17.4097327 },
    { lat: 48.2023176, lng: 17.410264 },
    { lat: 48.2021648, lng: 17.4105718 },
    { lat: 48.2021026, lng: 17.4107101 },
    { lat: 48.2020497, lng: 17.410852 },
    { lat: 48.2019349, lng: 17.4111357 },
    { lat: 48.2017423, lng: 17.4115245 },
    { lat: 48.201613, lng: 17.4117606 },
    { lat: 48.2015869, lng: 17.411826 },
    { lat: 48.2014247, lng: 17.4121477 },
    { lat: 48.2012663, lng: 17.4123525 },
    { lat: 48.2010365, lng: 17.4126216 },
    { lat: 48.2008878, lng: 17.4127835 },
    { lat: 48.2004244, lng: 17.413351 },
    { lat: 48.2003628, lng: 17.4134292 },
    { lat: 48.2000958, lng: 17.4135256 },
    { lat: 48.1998049, lng: 17.4136034 },
    { lat: 48.1997418, lng: 17.4136397 },
    { lat: 48.1996237, lng: 17.4137523 },
    { lat: 48.1994869, lng: 17.4140628 },
    { lat: 48.1993634, lng: 17.4142125 },
    { lat: 48.1992484, lng: 17.4142682 },
    { lat: 48.1990015, lng: 17.4142392 },
    { lat: 48.1986976, lng: 17.4141684 },
    { lat: 48.1984412, lng: 17.4139947 },
    { lat: 48.198382, lng: 17.4140235 },
    { lat: 48.1981948, lng: 17.4138371 },
    { lat: 48.1979258, lng: 17.4136438 },
    { lat: 48.1978713, lng: 17.4136215 },
    { lat: 48.1977722, lng: 17.4135564 },
    { lat: 48.1971362, lng: 17.4133635 },
    { lat: 48.1968286, lng: 17.4133745 },
    { lat: 48.1962888, lng: 17.4137758 },
    { lat: 48.1959954, lng: 17.4140583 },
    { lat: 48.1959567, lng: 17.4141045 },
    { lat: 48.1952318, lng: 17.4148553 },
    { lat: 48.1949718, lng: 17.4152329 },
    { lat: 48.1948084, lng: 17.4154837 },
    { lat: 48.1946946, lng: 17.4156955 },
    { lat: 48.1945765, lng: 17.4160327 },
    { lat: 48.1944126, lng: 17.416327 },
    { lat: 48.1943628, lng: 17.4164973 },
    { lat: 48.1942074, lng: 17.4170797 },
    { lat: 48.1941726, lng: 17.4172724 },
    { lat: 48.1941428, lng: 17.4175099 },
    { lat: 48.1941051, lng: 17.4179538 },
    { lat: 48.1940955, lng: 17.4187432 },
    { lat: 48.1941726, lng: 17.4198435 },
    { lat: 48.1941887, lng: 17.4199882 },
    { lat: 48.1942895, lng: 17.4205302 },
    { lat: 48.1943914, lng: 17.4210373 },
    { lat: 48.1947119, lng: 17.421989 },
    { lat: 48.1949562, lng: 17.4222719 },
    { lat: 48.1953348, lng: 17.4225886 },
    { lat: 48.1954953, lng: 17.422659 },
    { lat: 48.1959175, lng: 17.4227091 },
    { lat: 48.1966761, lng: 17.4225633 },
    { lat: 48.1971788, lng: 17.4224606 },
    { lat: 48.1974853, lng: 17.422292 },
    { lat: 48.1979884, lng: 17.4223185 },
    { lat: 48.1980904, lng: 17.4223213 },
    { lat: 48.1982399, lng: 17.4223647 },
    { lat: 48.1985628, lng: 17.4223626 },
    { lat: 48.1988238, lng: 17.4223466 },
    { lat: 48.1991959, lng: 17.4221445 },
    { lat: 48.1993769, lng: 17.4219746 },
    { lat: 48.1997538, lng: 17.4215709 },
    { lat: 48.1999394, lng: 17.4212812 },
    { lat: 48.2001635, lng: 17.4209411 },
    { lat: 48.2002868, lng: 17.420734 },
    { lat: 48.2007661, lng: 17.420116 },
    { lat: 48.2009221, lng: 17.4200147 },
    { lat: 48.2011125, lng: 17.4199364 },
    { lat: 48.2015489, lng: 17.4197858 },
    { lat: 48.2017226, lng: 17.4197657 },
    { lat: 48.2026074, lng: 17.41986 },
    { lat: 48.2029189, lng: 17.4200116 },
    { lat: 48.2033087, lng: 17.420291 },
    { lat: 48.2035379, lng: 17.4206379 },
    { lat: 48.2037563, lng: 17.4209744 },
    { lat: 48.2037848, lng: 17.4211181 },
    { lat: 48.2038366, lng: 17.4212888 },
    { lat: 48.2039741, lng: 17.4216836 },
    { lat: 48.204202, lng: 17.4224632 },
    { lat: 48.2046135, lng: 17.4223131 },
    { lat: 48.2048294, lng: 17.422453 },
    { lat: 48.2053968, lng: 17.4228428 },
    { lat: 48.2062628, lng: 17.4234977 },
    { lat: 48.2065043, lng: 17.4236872 },
    { lat: 48.2071139, lng: 17.4240649 },
    { lat: 48.2075244, lng: 17.424263 },
    { lat: 48.2077622, lng: 17.4244454 },
    { lat: 48.2067184, lng: 17.4274013 },
    { lat: 48.2068213, lng: 17.4277664 },
    { lat: 48.2070244, lng: 17.4297139 },
    { lat: 48.2070563, lng: 17.4306049 },
    { lat: 48.2080467, lng: 17.4322031 },
    { lat: 48.2092253, lng: 17.433867 },
  ],
  Tomášov: [
    { lat: 48.1231805, lng: 17.3043633 },
    { lat: 48.12239, lng: 17.30651 },
    { lat: 48.121688, lng: 17.308833 },
    { lat: 48.1213272, lng: 17.3102796 },
    { lat: 48.1224146, lng: 17.3107036 },
    { lat: 48.1231082, lng: 17.3109784 },
    { lat: 48.124949, lng: 17.3117396 },
    { lat: 48.1256824, lng: 17.3120357 },
    { lat: 48.1254565, lng: 17.3134044 },
    { lat: 48.1259866, lng: 17.3137152 },
    { lat: 48.126267, lng: 17.3139084 },
    { lat: 48.1269003, lng: 17.3144319 },
    { lat: 48.1272943, lng: 17.3147358 },
    { lat: 48.1275242, lng: 17.3148815 },
    { lat: 48.1276452, lng: 17.3149522 },
    { lat: 48.1277918, lng: 17.3150006 },
    { lat: 48.1284282, lng: 17.3151263 },
    { lat: 48.1287654, lng: 17.3151685 },
    { lat: 48.1296385, lng: 17.3153049 },
    { lat: 48.1296694, lng: 17.3162865 },
    { lat: 48.1296585, lng: 17.3166509 },
    { lat: 48.1296377, lng: 17.3170548 },
    { lat: 48.1295941, lng: 17.3173016 },
    { lat: 48.1296005, lng: 17.3174324 },
    { lat: 48.1296683, lng: 17.3179295 },
    { lat: 48.1296697, lng: 17.3181225 },
    { lat: 48.129652, lng: 17.318487 },
    { lat: 48.1296056, lng: 17.3190675 },
    { lat: 48.1295739, lng: 17.3193927 },
    { lat: 48.1295585, lng: 17.319671 },
    { lat: 48.1294707, lng: 17.3207385 },
    { lat: 48.1294372, lng: 17.3212571 },
    { lat: 48.1294095, lng: 17.3217971 },
    { lat: 48.1294066, lng: 17.3219418 },
    { lat: 48.1294215, lng: 17.3222357 },
    { lat: 48.129464, lng: 17.3226918 },
    { lat: 48.1294865, lng: 17.3233583 },
    { lat: 48.1294846, lng: 17.3235004 },
    { lat: 48.1295098, lng: 17.3241227 },
    { lat: 48.1295087, lng: 17.3245337 },
    { lat: 48.1300542, lng: 17.324704 },
    { lat: 48.1303141, lng: 17.3246539 },
    { lat: 48.1305067, lng: 17.3247479 },
    { lat: 48.130689, lng: 17.3248586 },
    { lat: 48.1312998, lng: 17.3253145 },
    { lat: 48.1314619, lng: 17.3254443 },
    { lat: 48.1316422, lng: 17.325471 },
    { lat: 48.1321153, lng: 17.3255532 },
    { lat: 48.1323428, lng: 17.3254759 },
    { lat: 48.1324252, lng: 17.3254224 },
    { lat: 48.1331952, lng: 17.3269762 },
    { lat: 48.1336747, lng: 17.3279612 },
    { lat: 48.1339788, lng: 17.328583 },
    { lat: 48.1347413, lng: 17.3301087 },
    { lat: 48.1346458, lng: 17.3302189 },
    { lat: 48.134584, lng: 17.3303098 },
    { lat: 48.1345593, lng: 17.3303625 },
    { lat: 48.1342525, lng: 17.3299586 },
    { lat: 48.1340635, lng: 17.3297299 },
    { lat: 48.1336584, lng: 17.3292571 },
    { lat: 48.1334973, lng: 17.3290741 },
    { lat: 48.1330447, lng: 17.3296976 },
    { lat: 48.1327478, lng: 17.3300947 },
    { lat: 48.1322937, lng: 17.3307115 },
    { lat: 48.1309582, lng: 17.3326106 },
    { lat: 48.1300947, lng: 17.3325342 },
    { lat: 48.1299263, lng: 17.3324467 },
    { lat: 48.1297418, lng: 17.3322864 },
    { lat: 48.1295435, lng: 17.3320718 },
    { lat: 48.1292213, lng: 17.3317412 },
    { lat: 48.129011, lng: 17.3314988 },
    { lat: 48.128377, lng: 17.3308083 },
    { lat: 48.1280162, lng: 17.330442 },
    { lat: 48.1276352, lng: 17.3300771 },
    { lat: 48.1271323, lng: 17.3296617 },
    { lat: 48.1269659, lng: 17.3295014 },
    { lat: 48.1267739, lng: 17.3292798 },
    { lat: 48.1266104, lng: 17.3290449 },
    { lat: 48.1262294, lng: 17.3288851 },
    { lat: 48.1256305, lng: 17.3284357 },
    { lat: 48.125574, lng: 17.328676 },
    { lat: 48.1254745, lng: 17.3289657 },
    { lat: 48.1252818, lng: 17.3292293 },
    { lat: 48.1250609, lng: 17.329563 },
    { lat: 48.1248642, lng: 17.3298518 },
    { lat: 48.1246566, lng: 17.330148 },
    { lat: 48.1244018, lng: 17.3304807 },
    { lat: 48.1242037, lng: 17.3307301 },
    { lat: 48.1234849, lng: 17.3316522 },
    { lat: 48.1233857, lng: 17.3317748 },
    { lat: 48.1232092, lng: 17.3320092 },
    { lat: 48.1230344, lng: 17.3322203 },
    { lat: 48.1227885, lng: 17.3324995 },
    { lat: 48.1225684, lng: 17.3327588 },
    { lat: 48.1221386, lng: 17.3333001 },
    { lat: 48.1216541, lng: 17.3339695 },
    { lat: 48.1215673, lng: 17.3340236 },
    { lat: 48.1212596, lng: 17.3340856 },
    { lat: 48.121017, lng: 17.3341237 },
    { lat: 48.1207147, lng: 17.3342012 },
    { lat: 48.118445, lng: 17.340467 },
    { lat: 48.118621, lng: 17.341106 },
    { lat: 48.119183, lng: 17.341573 },
    { lat: 48.119712, lng: 17.342125 },
    { lat: 48.120261, lng: 17.343127 },
    { lat: 48.121046, lng: 17.344032 },
    { lat: 48.121594, lng: 17.344859 },
    { lat: 48.122055, lng: 17.345664 },
    { lat: 48.122267, lng: 17.346692 },
    { lat: 48.12265, lng: 17.347536 },
    { lat: 48.123, lng: 17.347797 },
    { lat: 48.122192, lng: 17.3491 },
    { lat: 48.122442, lng: 17.349759 },
    { lat: 48.123188, lng: 17.351242 },
    { lat: 48.123742, lng: 17.352174 },
    { lat: 48.125002, lng: 17.353932 },
    { lat: 48.125242, lng: 17.354891 },
    { lat: 48.125719, lng: 17.35603 },
    { lat: 48.126456, lng: 17.356904 },
    { lat: 48.126637, lng: 17.357603 },
    { lat: 48.126328, lng: 17.358086 },
    { lat: 48.126828, lng: 17.359936 },
    { lat: 48.127079, lng: 17.361082 },
    { lat: 48.127657, lng: 17.36189 },
    { lat: 48.128129, lng: 17.362347 },
    { lat: 48.130851, lng: 17.364332 },
    { lat: 48.13132, lng: 17.365231 },
    { lat: 48.131868, lng: 17.3661 },
    { lat: 48.132626, lng: 17.366495 },
    { lat: 48.1336927, lng: 17.3667437 },
    { lat: 48.132215, lng: 17.372521 },
    { lat: 48.130462, lng: 17.378969 },
    { lat: 48.130922, lng: 17.379157 },
    { lat: 48.132083, lng: 17.380255 },
    { lat: 48.132968, lng: 17.381235 },
    { lat: 48.134683, lng: 17.383572 },
    { lat: 48.13517, lng: 17.384055 },
    { lat: 48.136246, lng: 17.384962 },
    { lat: 48.1367, lng: 17.385561 },
    { lat: 48.138249, lng: 17.38627 },
    { lat: 48.139402, lng: 17.386914 },
    { lat: 48.139751, lng: 17.38718 },
    { lat: 48.140364, lng: 17.387885 },
    { lat: 48.140791, lng: 17.388923 },
    { lat: 48.141712, lng: 17.390392 },
    { lat: 48.1429904, lng: 17.3909734 },
    { lat: 48.143535, lng: 17.387603 },
    { lat: 48.143835, lng: 17.385309 },
    { lat: 48.144089, lng: 17.383821 },
    { lat: 48.14437, lng: 17.382914 },
    { lat: 48.1448, lng: 17.38115 },
    { lat: 48.145105, lng: 17.380323 },
    { lat: 48.147398, lng: 17.378439 },
    { lat: 48.147461, lng: 17.377746 },
    { lat: 48.1475058, lng: 17.3773142 },
    { lat: 48.1475946, lng: 17.3764269 },
    { lat: 48.1475892, lng: 17.3756556 },
    { lat: 48.1475572, lng: 17.3752171 },
    { lat: 48.1475087, lng: 17.3747871 },
    { lat: 48.1476393, lng: 17.3747469 },
    { lat: 48.1477559, lng: 17.3744742 },
    { lat: 48.1464172, lng: 17.3732463 },
    { lat: 48.1456175, lng: 17.372521 },
    { lat: 48.1454916, lng: 17.3723942 },
    { lat: 48.1452016, lng: 17.3720704 },
    { lat: 48.1444999, lng: 17.3712567 },
    { lat: 48.1443183, lng: 17.3710367 },
    { lat: 48.1442207, lng: 17.3709076 },
    { lat: 48.1439825, lng: 17.3705306 },
    { lat: 48.1439421, lng: 17.3704318 },
    { lat: 48.1436483, lng: 17.3699144 },
    { lat: 48.1435059, lng: 17.3696873 },
    { lat: 48.1432844, lng: 17.3693045 },
    { lat: 48.1430013, lng: 17.3688464 },
    { lat: 48.1428156, lng: 17.3684776 },
    { lat: 48.1423411, lng: 17.3676316 },
    { lat: 48.1420091, lng: 17.3670642 },
    { lat: 48.1417586, lng: 17.3666561 },
    { lat: 48.141614, lng: 17.3664477 },
    { lat: 48.1413638, lng: 17.3661114 },
    { lat: 48.1416111, lng: 17.3655959 },
    { lat: 48.1416914, lng: 17.365423 },
    { lat: 48.1420105, lng: 17.3647594 },
    { lat: 48.142514, lng: 17.3637921 },
    { lat: 48.1428327, lng: 17.3626567 },
    { lat: 48.1429895, lng: 17.3617926 },
    { lat: 48.1431474, lng: 17.3609813 },
    { lat: 48.1431438, lng: 17.360375 },
    { lat: 48.1433866, lng: 17.3597707 },
    { lat: 48.1434599, lng: 17.3596148 },
    { lat: 48.1440065, lng: 17.3584757 },
    { lat: 48.1440258, lng: 17.3584281 },
    { lat: 48.144447, lng: 17.3576154 },
    { lat: 48.1445424, lng: 17.3574343 },
    { lat: 48.1446305, lng: 17.3572433 },
    { lat: 48.1451911, lng: 17.356113 },
    { lat: 48.1453868, lng: 17.3557232 },
    { lat: 48.1456409, lng: 17.3553779 },
    { lat: 48.1460644, lng: 17.3546879 },
    { lat: 48.1466044, lng: 17.3530896 },
    { lat: 48.1468824, lng: 17.3522818 },
    { lat: 48.14693, lng: 17.3524579 },
    { lat: 48.1470625, lng: 17.3527557 },
    { lat: 48.1472182, lng: 17.3530105 },
    { lat: 48.1473561, lng: 17.3532706 },
    { lat: 48.1474355, lng: 17.3533948 },
    { lat: 48.1474618, lng: 17.3534527 },
    { lat: 48.1475032, lng: 17.3535131 },
    { lat: 48.1475703, lng: 17.3536594 },
    { lat: 48.1476025, lng: 17.3537037 },
    { lat: 48.1476387, lng: 17.3537887 },
    { lat: 48.1477797, lng: 17.3539916 },
    { lat: 48.147862, lng: 17.3541281 },
    { lat: 48.1479843, lng: 17.3542874 },
    { lat: 48.1481809, lng: 17.3545127 },
    { lat: 48.1484741, lng: 17.354797 },
    { lat: 48.1487118, lng: 17.3549935 },
    { lat: 48.1488563, lng: 17.3550947 },
    { lat: 48.1491889, lng: 17.3553447 },
    { lat: 48.1493819, lng: 17.3555155 },
    { lat: 48.1495591, lng: 17.3556331 },
    { lat: 48.149801, lng: 17.3558069 },
    { lat: 48.1499785, lng: 17.3558949 },
    { lat: 48.1501345, lng: 17.3559504 },
    { lat: 48.1503057, lng: 17.3560629 },
    { lat: 48.1505001, lng: 17.3561669 },
    { lat: 48.1507466, lng: 17.3562558 },
    { lat: 48.1508203, lng: 17.3562956 },
    { lat: 48.151044, lng: 17.3563657 },
    { lat: 48.1511719, lng: 17.356385 },
    { lat: 48.1512427, lng: 17.3564142 },
    { lat: 48.1513301, lng: 17.3564234 },
    { lat: 48.1515166, lng: 17.3564754 },
    { lat: 48.151623, lng: 17.3564961 },
    { lat: 48.1518386, lng: 17.3565616 },
    { lat: 48.1520302, lng: 17.3565908 },
    { lat: 48.1522428, lng: 17.3566095 },
    { lat: 48.1523256, lng: 17.3565991 },
    { lat: 48.1524511, lng: 17.3566009 },
    { lat: 48.1525447, lng: 17.356615 },
    { lat: 48.1527962, lng: 17.3566094 },
    { lat: 48.1528796, lng: 17.3566234 },
    { lat: 48.1531593, lng: 17.356617 },
    { lat: 48.1540209, lng: 17.3567691 },
    { lat: 48.1541995, lng: 17.3567895 },
    { lat: 48.1544032, lng: 17.3568457 },
    { lat: 48.1546396, lng: 17.3569199 },
    { lat: 48.1548733, lng: 17.3570046 },
    { lat: 48.1550744, lng: 17.3570899 },
    { lat: 48.1553411, lng: 17.3571784 },
    { lat: 48.1555602, lng: 17.3572635 },
    { lat: 48.1557897, lng: 17.3573355 },
    { lat: 48.1558761, lng: 17.3573439 },
    { lat: 48.1559457, lng: 17.3573708 },
    { lat: 48.1562468, lng: 17.3574321 },
    { lat: 48.1564399, lng: 17.3574318 },
    { lat: 48.1570677, lng: 17.3574115 },
    { lat: 48.15735, lng: 17.357376 },
    { lat: 48.1578791, lng: 17.3572622 },
    { lat: 48.1582196, lng: 17.3571658 },
    { lat: 48.158872, lng: 17.3568837 },
    { lat: 48.1593129, lng: 17.3566991 },
    { lat: 48.159706, lng: 17.3565106 },
    { lat: 48.1598691, lng: 17.3564141 },
    { lat: 48.1601267, lng: 17.356243 },
    { lat: 48.1607543, lng: 17.3558075 },
    { lat: 48.1613449, lng: 17.3557858 },
    { lat: 48.1614481, lng: 17.3558112 },
    { lat: 48.1617332, lng: 17.3558681 },
    { lat: 48.1617684, lng: 17.3558553 },
    { lat: 48.1618429, lng: 17.3558538 },
    { lat: 48.162287, lng: 17.3559769 },
    { lat: 48.1623286, lng: 17.3555444 },
    { lat: 48.1623377, lng: 17.3553034 },
    { lat: 48.1623781, lng: 17.3547657 },
    { lat: 48.1624898, lng: 17.3530955 },
    { lat: 48.1625315, lng: 17.3524571 },
    { lat: 48.1625999, lng: 17.3514517 },
    { lat: 48.1627704, lng: 17.3511236 },
    { lat: 48.1634923, lng: 17.3516231 },
    { lat: 48.1639612, lng: 17.3518143 },
    { lat: 48.1647118, lng: 17.3519954 },
    { lat: 48.1650623, lng: 17.3519836 },
    { lat: 48.1656967, lng: 17.3518229 },
    { lat: 48.1662241, lng: 17.351511 },
    { lat: 48.1665929, lng: 17.3511758 },
    { lat: 48.1669364, lng: 17.3508163 },
    { lat: 48.1671409, lng: 17.3504055 },
    { lat: 48.1672641, lng: 17.3501469 },
    { lat: 48.1674345, lng: 17.3498186 },
    { lat: 48.1676667, lng: 17.3493295 },
    { lat: 48.1678196, lng: 17.3487506 },
    { lat: 48.1680971, lng: 17.3480033 },
    { lat: 48.1683085, lng: 17.3476481 },
    { lat: 48.1684989, lng: 17.3473635 },
    { lat: 48.1687414, lng: 17.3472037 },
    { lat: 48.1689583, lng: 17.3471187 },
    { lat: 48.1691881, lng: 17.3470756 },
    { lat: 48.1694607, lng: 17.347218 },
    { lat: 48.1698821, lng: 17.3474247 },
    { lat: 48.1703013, lng: 17.3475995 },
    { lat: 48.1705091, lng: 17.3475968 },
    { lat: 48.1707605, lng: 17.3476579 },
    { lat: 48.1709675, lng: 17.3466151 },
    { lat: 48.1712072, lng: 17.3453802 },
    { lat: 48.171437, lng: 17.3442015 },
    { lat: 48.1717202, lng: 17.3427362 },
    { lat: 48.1719583, lng: 17.342714 },
    { lat: 48.1720712, lng: 17.3426795 },
    { lat: 48.1726712, lng: 17.342528 },
    { lat: 48.17322, lng: 17.3420962 },
    { lat: 48.1735565, lng: 17.3417344 },
    { lat: 48.1738776, lng: 17.3412575 },
    { lat: 48.173925, lng: 17.3411693 },
    { lat: 48.1741629, lng: 17.3405966 },
    { lat: 48.1743431, lng: 17.3401258 },
    { lat: 48.1744837, lng: 17.3396686 },
    { lat: 48.1746762, lng: 17.3388525 },
    { lat: 48.1747735, lng: 17.3382522 },
    { lat: 48.1746717, lng: 17.3374768 },
    { lat: 48.1745472, lng: 17.3371827 },
    { lat: 48.1744773, lng: 17.3370477 },
    { lat: 48.1744078, lng: 17.3367793 },
    { lat: 48.1743244, lng: 17.3365256 },
    { lat: 48.1741746, lng: 17.3359826 },
    { lat: 48.1741298, lng: 17.3356279 },
    { lat: 48.1741013, lng: 17.3354698 },
    { lat: 48.17409, lng: 17.3351946 },
    { lat: 48.1740713, lng: 17.3349103 },
    { lat: 48.1740259, lng: 17.3345107 },
    { lat: 48.1739557, lng: 17.3342862 },
    { lat: 48.1738333, lng: 17.3339868 },
    { lat: 48.1737132, lng: 17.3336405 },
    { lat: 48.1736279, lng: 17.3333728 },
    { lat: 48.1734709, lng: 17.3328251 },
    { lat: 48.1733462, lng: 17.3324044 },
    { lat: 48.1731907, lng: 17.3318276 },
    { lat: 48.17303, lng: 17.3312728 },
    { lat: 48.172853, lng: 17.3307628 },
    { lat: 48.1727829, lng: 17.3305392 },
    { lat: 48.1726939, lng: 17.3302339 },
    { lat: 48.1726298, lng: 17.3299833 },
    { lat: 48.1725192, lng: 17.3295207 },
    { lat: 48.1724733, lng: 17.3293891 },
    { lat: 48.1724568, lng: 17.3291044 },
    { lat: 48.1723813, lng: 17.3286967 },
    { lat: 48.1722621, lng: 17.3276411 },
    { lat: 48.1723738, lng: 17.3273992 },
    { lat: 48.172385, lng: 17.3272075 },
    { lat: 48.1723606, lng: 17.3269047 },
    { lat: 48.1723389, lng: 17.3265759 },
    { lat: 48.1723121, lng: 17.326341 },
    { lat: 48.172326, lng: 17.3256262 },
    { lat: 48.1722568, lng: 17.3252294 },
    { lat: 48.1721263, lng: 17.3248256 },
    { lat: 48.1719128, lng: 17.3243099 },
    { lat: 48.1717813, lng: 17.3240296 },
    { lat: 48.1716296, lng: 17.3237495 },
    { lat: 48.1714761, lng: 17.3234766 },
    { lat: 48.1710588, lng: 17.3229393 },
    { lat: 48.1706729, lng: 17.3224296 },
    { lat: 48.170537, lng: 17.3222894 },
    { lat: 48.1703508, lng: 17.3220854 },
    { lat: 48.1702029, lng: 17.321967 },
    { lat: 48.1700616, lng: 17.3217776 },
    { lat: 48.1697996, lng: 17.3215714 },
    { lat: 48.1697329, lng: 17.3215026 },
    { lat: 48.1694541, lng: 17.3212668 },
    { lat: 48.1692512, lng: 17.3210678 },
    { lat: 48.1690224, lng: 17.3208516 },
    { lat: 48.1687008, lng: 17.3205326 },
    { lat: 48.1682681, lng: 17.3198465 },
    { lat: 48.1682022, lng: 17.3195303 },
    { lat: 48.1680386, lng: 17.3191905 },
    { lat: 48.1679205, lng: 17.3189935 },
    { lat: 48.1676263, lng: 17.3185919 },
    { lat: 48.1674048, lng: 17.3183333 },
    { lat: 48.1671657, lng: 17.3181337 },
    { lat: 48.1670754, lng: 17.3180828 },
    { lat: 48.1668206, lng: 17.3179888 },
    { lat: 48.1665846, lng: 17.3180227 },
    { lat: 48.1662726, lng: 17.3182434 },
    { lat: 48.1661629, lng: 17.3183263 },
    { lat: 48.1659308, lng: 17.3185935 },
    { lat: 48.165825, lng: 17.3187358 },
    { lat: 48.1654732, lng: 17.3194461 },
    { lat: 48.1653873, lng: 17.319636 },
    { lat: 48.165352, lng: 17.319752 },
    { lat: 48.1653212, lng: 17.3197624 },
    { lat: 48.1652843, lng: 17.3197864 },
    { lat: 48.1652294, lng: 17.3197768 },
    { lat: 48.165198, lng: 17.319762 },
    { lat: 48.1650572, lng: 17.3198367 },
    { lat: 48.1648932, lng: 17.3198331 },
    { lat: 48.1648233, lng: 17.3198534 },
    { lat: 48.164692, lng: 17.3197976 },
    { lat: 48.1645871, lng: 17.3200917 },
    { lat: 48.1645336, lng: 17.3202567 },
    { lat: 48.1644266, lng: 17.3202953 },
    { lat: 48.1639539, lng: 17.3206114 },
    { lat: 48.1635259, lng: 17.3207335 },
    { lat: 48.1632228, lng: 17.3207967 },
    { lat: 48.1628944, lng: 17.3208527 },
    { lat: 48.1626615, lng: 17.320904 },
    { lat: 48.1622221, lng: 17.3209542 },
    { lat: 48.1619909, lng: 17.3210458 },
    { lat: 48.1618305, lng: 17.3210813 },
    { lat: 48.1614634, lng: 17.3211488 },
    { lat: 48.1611039, lng: 17.3210921 },
    { lat: 48.1608828, lng: 17.3209776 },
    { lat: 48.1603171, lng: 17.3206727 },
    { lat: 48.1600145, lng: 17.3204682 },
    { lat: 48.1597758, lng: 17.3201388 },
    { lat: 48.1592921, lng: 17.3196723 },
    { lat: 48.159184, lng: 17.3195076 },
    { lat: 48.1590284, lng: 17.3192544 },
    { lat: 48.1587929, lng: 17.3189708 },
    { lat: 48.1587052, lng: 17.3187382 },
    { lat: 48.1586695, lng: 17.3184716 },
    { lat: 48.1586763, lng: 17.3181792 },
    { lat: 48.1586072, lng: 17.3176129 },
    { lat: 48.1585212, lng: 17.3171639 },
    { lat: 48.1585285, lng: 17.3170868 },
    { lat: 48.1583996, lng: 17.3164187 },
    { lat: 48.1583551, lng: 17.3161702 },
    { lat: 48.1583028, lng: 17.3159087 },
    { lat: 48.1582835, lng: 17.3157182 },
    { lat: 48.1582235, lng: 17.3153058 },
    { lat: 48.1582156, lng: 17.3150816 },
    { lat: 48.1581702, lng: 17.3149084 },
    { lat: 48.1581235, lng: 17.3147998 },
    { lat: 48.1579331, lng: 17.314654 },
    { lat: 48.1577454, lng: 17.314446 },
    { lat: 48.1576226, lng: 17.3141772 },
    { lat: 48.1575649, lng: 17.3139881 },
    { lat: 48.1575603, lng: 17.3137972 },
    { lat: 48.1573582, lng: 17.3138152 },
    { lat: 48.1559927, lng: 17.3139565 },
    { lat: 48.1559634, lng: 17.3139575 },
    { lat: 48.1559503, lng: 17.3139317 },
    { lat: 48.1556965, lng: 17.3139707 },
    { lat: 48.1554239, lng: 17.3140008 },
    { lat: 48.155196, lng: 17.3140369 },
    { lat: 48.1548856, lng: 17.314075 },
    { lat: 48.154605, lng: 17.3141206 },
    { lat: 48.1543588, lng: 17.3141721 },
    { lat: 48.1531829, lng: 17.3143092 },
    { lat: 48.1528967, lng: 17.3143201 },
    { lat: 48.1526541, lng: 17.3143424 },
    { lat: 48.1524236, lng: 17.3143755 },
    { lat: 48.1522472, lng: 17.3143906 },
    { lat: 48.1516115, lng: 17.3144615 },
    { lat: 48.1507285, lng: 17.314552 },
    { lat: 48.1508646, lng: 17.3142187 },
    { lat: 48.151913, lng: 17.3117059 },
    { lat: 48.1518105, lng: 17.3117092 },
    { lat: 48.150797, lng: 17.3117929 },
    { lat: 48.1502059, lng: 17.3118033 },
    { lat: 48.1498708, lng: 17.3118122 },
    { lat: 48.1496675, lng: 17.3118126 },
    { lat: 48.1492929, lng: 17.3108769 },
    { lat: 48.1487578, lng: 17.3095135 },
    { lat: 48.148708, lng: 17.3093916 },
    { lat: 48.1482101, lng: 17.3081315 },
    { lat: 48.1479595, lng: 17.3071945 },
    { lat: 48.1476717, lng: 17.3073349 },
    { lat: 48.1474301, lng: 17.3066868 },
    { lat: 48.1470547, lng: 17.3056729 },
    { lat: 48.1467715, lng: 17.304924 },
    { lat: 48.1463266, lng: 17.3036983 },
    { lat: 48.145696, lng: 17.3020295 },
    { lat: 48.1454217, lng: 17.3013034 },
    { lat: 48.1453075, lng: 17.3014241 },
    { lat: 48.144752, lng: 17.3019896 },
    { lat: 48.1446639, lng: 17.3020753 },
    { lat: 48.1436197, lng: 17.3031291 },
    { lat: 48.1426944, lng: 17.3040648 },
    { lat: 48.1423045, lng: 17.3044575 },
    { lat: 48.1422257, lng: 17.3045289 },
    { lat: 48.1409546, lng: 17.3058245 },
    { lat: 48.140735, lng: 17.3056806 },
    { lat: 48.1400454, lng: 17.3065951 },
    { lat: 48.1390093, lng: 17.3079589 },
    { lat: 48.13868, lng: 17.3083777 },
    { lat: 48.1383398, lng: 17.3088221 },
    { lat: 48.1380206, lng: 17.3092557 },
    { lat: 48.136666, lng: 17.3110307 },
    { lat: 48.1366056, lng: 17.3111072 },
    { lat: 48.1360736, lng: 17.311827 },
    { lat: 48.1345922, lng: 17.3138046 },
    { lat: 48.1342975, lng: 17.3137038 },
    { lat: 48.1340472, lng: 17.3136756 },
    { lat: 48.1336252, lng: 17.3136501 },
    { lat: 48.1333264, lng: 17.3136917 },
    { lat: 48.1330318, lng: 17.3137823 },
    { lat: 48.1327326, lng: 17.3138517 },
    { lat: 48.132381, lng: 17.3138647 },
    { lat: 48.1321093, lng: 17.3138212 },
    { lat: 48.1319372, lng: 17.3137631 },
    { lat: 48.1314939, lng: 17.3135685 },
    { lat: 48.1312817, lng: 17.3134175 },
    { lat: 48.1311544, lng: 17.3133029 },
    { lat: 48.1308085, lng: 17.3128207 },
    { lat: 48.1306917, lng: 17.3126245 },
    { lat: 48.1304688, lng: 17.3122262 },
    { lat: 48.1303261, lng: 17.3119815 },
    { lat: 48.1300846, lng: 17.3113868 },
    { lat: 48.1300078, lng: 17.3111129 },
    { lat: 48.1298324, lng: 17.3104074 },
    { lat: 48.1297269, lng: 17.3098356 },
    { lat: 48.1296943, lng: 17.3095993 },
    { lat: 48.1297049, lng: 17.3094347 },
    { lat: 48.1297782, lng: 17.3091262 },
    { lat: 48.129883, lng: 17.3088475 },
    { lat: 48.1300107, lng: 17.3086262 },
    { lat: 48.1301506, lng: 17.3084351 },
    { lat: 48.1295075, lng: 17.308071 },
    { lat: 48.1278454, lng: 17.3070903 },
    { lat: 48.1265729, lng: 17.3063482 },
    { lat: 48.1259525, lng: 17.3060027 },
    { lat: 48.1257258, lng: 17.3058716 },
    { lat: 48.125505, lng: 17.3057261 },
    { lat: 48.1245624, lng: 17.3051726 },
    { lat: 48.1235668, lng: 17.3045856 },
    { lat: 48.1231805, lng: 17.3043633 },
  ],
  Tureň: [
    { lat: 48.1810827, lng: 17.4046546 },
    { lat: 48.1815793, lng: 17.4050476 },
    { lat: 48.1821406, lng: 17.4054809 },
    { lat: 48.182626, lng: 17.4058407 },
    { lat: 48.1829287, lng: 17.4060467 },
    { lat: 48.1836532, lng: 17.406523 },
    { lat: 48.1842909, lng: 17.406881 },
    { lat: 48.1846876, lng: 17.4071095 },
    { lat: 48.1847974, lng: 17.4071663 },
    { lat: 48.1848541, lng: 17.4074451 },
    { lat: 48.1849419, lng: 17.4078411 },
    { lat: 48.1854024, lng: 17.4081996 },
    { lat: 48.1857398, lng: 17.4084731 },
    { lat: 48.1860973, lng: 17.4087411 },
    { lat: 48.186414, lng: 17.4089921 },
    { lat: 48.1868037, lng: 17.4092875 },
    { lat: 48.1872128, lng: 17.4096039 },
    { lat: 48.1877671, lng: 17.4097562 },
    { lat: 48.1881728, lng: 17.4098619 },
    { lat: 48.1886355, lng: 17.4099777 },
    { lat: 48.1886211, lng: 17.4095651 },
    { lat: 48.1885751, lng: 17.4083903 },
    { lat: 48.1885113, lng: 17.4065957 },
    { lat: 48.1884974, lng: 17.406106 },
    { lat: 48.1884781, lng: 17.405619 },
    { lat: 48.1884448, lng: 17.4046915 },
    { lat: 48.1884051, lng: 17.4035444 },
    { lat: 48.1883838, lng: 17.4030433 },
    { lat: 48.1883526, lng: 17.4021324 },
    { lat: 48.1883224, lng: 17.401391 },
    { lat: 48.1882937, lng: 17.4005708 },
    { lat: 48.1885763, lng: 17.4006614 },
    { lat: 48.1890038, lng: 17.4008254 },
    { lat: 48.189286, lng: 17.4009254 },
    { lat: 48.1895801, lng: 17.401037 },
    { lat: 48.1899082, lng: 17.4011505 },
    { lat: 48.1903261, lng: 17.401305 },
    { lat: 48.1907227, lng: 17.4014456 },
    { lat: 48.1911569, lng: 17.4016095 },
    { lat: 48.191508, lng: 17.4017289 },
    { lat: 48.1920172, lng: 17.4019149 },
    { lat: 48.1923488, lng: 17.4020464 },
    { lat: 48.1923747, lng: 17.4020298 },
    { lat: 48.192565, lng: 17.401829 },
    { lat: 48.1926495, lng: 17.4018261 },
    { lat: 48.1927487, lng: 17.4017693 },
    { lat: 48.1929832, lng: 17.4017461 },
    { lat: 48.1935768, lng: 17.4016696 },
    { lat: 48.1936133, lng: 17.4016576 },
    { lat: 48.1941406, lng: 17.4015672 },
    { lat: 48.1947162, lng: 17.4014224 },
    { lat: 48.1948104, lng: 17.4013766 },
    { lat: 48.1948773, lng: 17.4013306 },
    { lat: 48.1951369, lng: 17.4011703 },
    { lat: 48.1954843, lng: 17.400867 },
    { lat: 48.1955522, lng: 17.4008014 },
    { lat: 48.1956743, lng: 17.4005943 },
    { lat: 48.1957726, lng: 17.4004389 },
    { lat: 48.1959141, lng: 17.4001852 },
    { lat: 48.1960728, lng: 17.3998808 },
    { lat: 48.1961358, lng: 17.3997735 },
    { lat: 48.1962068, lng: 17.3995629 },
    { lat: 48.1963125, lng: 17.3992958 },
    { lat: 48.1963853, lng: 17.3990792 },
    { lat: 48.1964885, lng: 17.3987025 },
    { lat: 48.1965474, lng: 17.3984117 },
    { lat: 48.196606, lng: 17.3981853 },
    { lat: 48.1966319, lng: 17.3980456 },
    { lat: 48.1966725, lng: 17.3979568 },
    { lat: 48.1967246, lng: 17.3975607 },
    { lat: 48.1967425, lng: 17.3969684 },
    { lat: 48.1967473, lng: 17.3966789 },
    { lat: 48.1966922, lng: 17.3956622 },
    { lat: 48.196632, lng: 17.395258 },
    { lat: 48.196584, lng: 17.3949719 },
    { lat: 48.196428, lng: 17.3943484 },
    { lat: 48.1962794, lng: 17.3938869 },
    { lat: 48.196158, lng: 17.3936199 },
    { lat: 48.195989, lng: 17.3932966 },
    { lat: 48.1957391, lng: 17.3928778 },
    { lat: 48.1955449, lng: 17.3926536 },
    { lat: 48.195388, lng: 17.3924653 },
    { lat: 48.1951411, lng: 17.392264 },
    { lat: 48.1949653, lng: 17.3921325 },
    { lat: 48.1948368, lng: 17.3920137 },
    { lat: 48.1948541, lng: 17.3919165 },
    { lat: 48.1948673, lng: 17.391842 },
    { lat: 48.1949441, lng: 17.3916161 },
    { lat: 48.1954949, lng: 17.3899316 },
    { lat: 48.1956161, lng: 17.3893151 },
    { lat: 48.1957679, lng: 17.3885259 },
    { lat: 48.1958215, lng: 17.3882917 },
    { lat: 48.1959508, lng: 17.3878673 },
    { lat: 48.1960231, lng: 17.3876797 },
    { lat: 48.1960484, lng: 17.3876139 },
    { lat: 48.1962598, lng: 17.3871862 },
    { lat: 48.1964989, lng: 17.3868512 },
    { lat: 48.1969434, lng: 17.3863632 },
    { lat: 48.1970776, lng: 17.3862159 },
    { lat: 48.1976403, lng: 17.3854249 },
    { lat: 48.1980032, lng: 17.3848922 },
    { lat: 48.1983026, lng: 17.38441 },
    { lat: 48.1984316, lng: 17.3841369 },
    { lat: 48.1985178, lng: 17.3839342 },
    { lat: 48.1987228, lng: 17.3833551 },
    { lat: 48.1987977, lng: 17.3831193 },
    { lat: 48.1989074, lng: 17.3827232 },
    { lat: 48.199107, lng: 17.3818206 },
    { lat: 48.1991413, lng: 17.3815672 },
    { lat: 48.1991534, lng: 17.3811651 },
    { lat: 48.1991454, lng: 17.3809896 },
    { lat: 48.1991285, lng: 17.3808341 },
    { lat: 48.1990909, lng: 17.3805709 },
    { lat: 48.1990823, lng: 17.3803525 },
    { lat: 48.199103, lng: 17.3800142 },
    { lat: 48.1992117, lng: 17.3790657 },
    { lat: 48.1992218, lng: 17.3789272 },
    { lat: 48.1992479, lng: 17.3780482 },
    { lat: 48.1992786, lng: 17.3778614 },
    { lat: 48.1993723, lng: 17.3774455 },
    { lat: 48.1995907, lng: 17.3766583 },
    { lat: 48.1997477, lng: 17.3760186 },
    { lat: 48.1998075, lng: 17.3756889 },
    { lat: 48.1999873, lng: 17.3746226 },
    { lat: 48.1999795, lng: 17.3746069 },
    { lat: 48.199823, lng: 17.3746314 },
    { lat: 48.1994964, lng: 17.3748017 },
    { lat: 48.199308, lng: 17.3748926 },
    { lat: 48.1990995, lng: 17.3750009 },
    { lat: 48.1988925, lng: 17.3750997 },
    { lat: 48.1986523, lng: 17.3752269 },
    { lat: 48.1983785, lng: 17.3753581 },
    { lat: 48.1980463, lng: 17.3755124 },
    { lat: 48.1978938, lng: 17.3756158 },
    { lat: 48.1975481, lng: 17.3758057 },
    { lat: 48.1968662, lng: 17.3761717 },
    { lat: 48.1968861, lng: 17.3761146 },
    { lat: 48.1961993, lng: 17.3762355 },
    { lat: 48.1959692, lng: 17.3762741 },
    { lat: 48.1955059, lng: 17.3763047 },
    { lat: 48.1950493, lng: 17.3763538 },
    { lat: 48.1946049, lng: 17.3763978 },
    { lat: 48.1940032, lng: 17.3764545 },
    { lat: 48.1931754, lng: 17.3764921 },
    { lat: 48.1923667, lng: 17.3765445 },
    { lat: 48.1916913, lng: 17.3765453 },
    { lat: 48.1906432, lng: 17.3765804 },
    { lat: 48.1897474, lng: 17.3766996 },
    { lat: 48.1890426, lng: 17.3768746 },
    { lat: 48.1883177, lng: 17.3772746 },
    { lat: 48.1877031, lng: 17.3776515 },
    { lat: 48.1873436, lng: 17.3777354 },
    { lat: 48.1871131, lng: 17.3777851 },
    { lat: 48.1866728, lng: 17.3778741 },
    { lat: 48.1864383, lng: 17.3779498 },
    { lat: 48.1856214, lng: 17.3782061 },
    { lat: 48.1848858, lng: 17.3769074 },
    { lat: 48.1843978, lng: 17.3760253 },
    { lat: 48.184247, lng: 17.3757576 },
    { lat: 48.1840336, lng: 17.3753608 },
    { lat: 48.1838514, lng: 17.3750471 },
    { lat: 48.1836701, lng: 17.3752966 },
    { lat: 48.1834826, lng: 17.37552 },
    { lat: 48.1833548, lng: 17.3756392 },
    { lat: 48.183069, lng: 17.3759832 },
    { lat: 48.1826191, lng: 17.3765313 },
    { lat: 48.1824549, lng: 17.3766794 },
    { lat: 48.1822525, lng: 17.3768146 },
    { lat: 48.1819724, lng: 17.3769534 },
    { lat: 48.1818815, lng: 17.3767717 },
    { lat: 48.1817731, lng: 17.376514 },
    { lat: 48.1813511, lng: 17.3755716 },
    { lat: 48.1811696, lng: 17.3752841 },
    { lat: 48.1807985, lng: 17.3747042 },
    { lat: 48.1805763, lng: 17.3743633 },
    { lat: 48.1801964, lng: 17.3737535 },
    { lat: 48.1799327, lng: 17.3739871 },
    { lat: 48.1798127, lng: 17.3742251 },
    { lat: 48.1797076, lng: 17.374656 },
    { lat: 48.1795553, lng: 17.3751605 },
    { lat: 48.1793581, lng: 17.375511 },
    { lat: 48.1792041, lng: 17.3757472 },
    { lat: 48.1788573, lng: 17.376161 },
    { lat: 48.1786081, lng: 17.3764965 },
    { lat: 48.178351, lng: 17.376882 },
    { lat: 48.1782739, lng: 17.3770148 },
    { lat: 48.178141, lng: 17.3773322 },
    { lat: 48.1780665, lng: 17.3776785 },
    { lat: 48.1777762, lng: 17.3775579 },
    { lat: 48.1767513, lng: 17.3771556 },
    { lat: 48.1757195, lng: 17.3767128 },
    { lat: 48.175495, lng: 17.376614 },
    { lat: 48.1750375, lng: 17.3764242 },
    { lat: 48.1747422, lng: 17.3762968 },
    { lat: 48.1743482, lng: 17.3760404 },
    { lat: 48.1738781, lng: 17.3757443 },
    { lat: 48.1729683, lng: 17.3751834 },
    { lat: 48.1725072, lng: 17.3749028 },
    { lat: 48.1713933, lng: 17.3741743 },
    { lat: 48.1708946, lng: 17.3738394 },
    { lat: 48.1705601, lng: 17.3736275 },
    { lat: 48.1704672, lng: 17.3735727 },
    { lat: 48.170075, lng: 17.373397 },
    { lat: 48.1692113, lng: 17.3734204 },
    { lat: 48.1689947, lng: 17.3734233 },
    { lat: 48.1683608, lng: 17.3734376 },
    { lat: 48.1675072, lng: 17.37396 },
    { lat: 48.1670927, lng: 17.3742386 },
    { lat: 48.1669265, lng: 17.3745127 },
    { lat: 48.1668996, lng: 17.3751017 },
    { lat: 48.1668689, lng: 17.3752523 },
    { lat: 48.1666154, lng: 17.3757115 },
    { lat: 48.1665026, lng: 17.3758529 },
    { lat: 48.1659823, lng: 17.3764449 },
    { lat: 48.1657594, lng: 17.3767333 },
    { lat: 48.1656773, lng: 17.3769107 },
    { lat: 48.1656792, lng: 17.3773891 },
    { lat: 48.1657438, lng: 17.3779098 },
    { lat: 48.165738, lng: 17.378162 },
    { lat: 48.165697, lng: 17.378659 },
    { lat: 48.165428, lng: 17.379541 },
    { lat: 48.165401, lng: 17.379763 },
    { lat: 48.16543, lng: 17.379987 },
    { lat: 48.165614, lng: 17.380517 },
    { lat: 48.165901, lng: 17.38108 },
    { lat: 48.166027, lng: 17.38122 },
    { lat: 48.166611, lng: 17.381603 },
    { lat: 48.16694, lng: 17.381793 },
    { lat: 48.167154, lng: 17.381829 },
    { lat: 48.167529, lng: 17.381656 },
    { lat: 48.167808, lng: 17.381287 },
    { lat: 48.168101, lng: 17.380168 },
    { lat: 48.169039, lng: 17.379986 },
    { lat: 48.170071, lng: 17.380068 },
    { lat: 48.170038, lng: 17.38122 },
    { lat: 48.169636, lng: 17.385269 },
    { lat: 48.169366, lng: 17.387084 },
    { lat: 48.169104, lng: 17.388444 },
    { lat: 48.1689485, lng: 17.3897837 },
    { lat: 48.1693448, lng: 17.3898002 },
    { lat: 48.1698357, lng: 17.3897057 },
    { lat: 48.1704765, lng: 17.3895918 },
    { lat: 48.1707973, lng: 17.3895223 },
    { lat: 48.1710282, lng: 17.3894768 },
    { lat: 48.171081, lng: 17.3894024 },
    { lat: 48.1711751, lng: 17.389392 },
    { lat: 48.1712948, lng: 17.3894623 },
    { lat: 48.1714859, lng: 17.3894816 },
    { lat: 48.171613, lng: 17.3895425 },
    { lat: 48.171837, lng: 17.3895993 },
    { lat: 48.1721124, lng: 17.3894915 },
    { lat: 48.172221, lng: 17.3895137 },
    { lat: 48.1723612, lng: 17.3897204 },
    { lat: 48.1725227, lng: 17.3898775 },
    { lat: 48.1726538, lng: 17.3899824 },
    { lat: 48.1727944, lng: 17.390059 },
    { lat: 48.1729444, lng: 17.3900912 },
    { lat: 48.1730787, lng: 17.3901417 },
    { lat: 48.1731189, lng: 17.3902363 },
    { lat: 48.173382, lng: 17.390028 },
    { lat: 48.173497, lng: 17.3899555 },
    { lat: 48.1737395, lng: 17.3898299 },
    { lat: 48.1739937, lng: 17.3897109 },
    { lat: 48.174047, lng: 17.3896971 },
    { lat: 48.1743925, lng: 17.3896602 },
    { lat: 48.1746648, lng: 17.3896433 },
    { lat: 48.174772, lng: 17.3896436 },
    { lat: 48.1750856, lng: 17.3896705 },
    { lat: 48.1752497, lng: 17.3897099 },
    { lat: 48.1754215, lng: 17.3896079 },
    { lat: 48.1756442, lng: 17.3896606 },
    { lat: 48.1758563, lng: 17.3896415 },
    { lat: 48.1760876, lng: 17.3896019 },
    { lat: 48.176282, lng: 17.3895532 },
    { lat: 48.1764342, lng: 17.3893926 },
    { lat: 48.1764586, lng: 17.3893519 },
    { lat: 48.1766073, lng: 17.3891545 },
    { lat: 48.1768061, lng: 17.3888781 },
    { lat: 48.1769431, lng: 17.3886241 },
    { lat: 48.1770607, lng: 17.3883494 },
    { lat: 48.1772097, lng: 17.3879511 },
    { lat: 48.1773346, lng: 17.3877007 },
    { lat: 48.1775151, lng: 17.3872309 },
    { lat: 48.1775803, lng: 17.3869326 },
    { lat: 48.1777797, lng: 17.3860937 },
    { lat: 48.1778236, lng: 17.3859175 },
    { lat: 48.178002, lng: 17.3855724 },
    { lat: 48.1780879, lng: 17.3854524 },
    { lat: 48.1782184, lng: 17.3852874 },
    { lat: 48.178401, lng: 17.3851627 },
    { lat: 48.1786159, lng: 17.3850469 },
    { lat: 48.1788443, lng: 17.3850498 },
    { lat: 48.1791709, lng: 17.385122 },
    { lat: 48.1794881, lng: 17.3854278 },
    { lat: 48.1795593, lng: 17.385517 },
    { lat: 48.1797234, lng: 17.3857642 },
    { lat: 48.1799397, lng: 17.3861167 },
    { lat: 48.1800162, lng: 17.3863547 },
    { lat: 48.1802051, lng: 17.3869638 },
    { lat: 48.1803335, lng: 17.3873892 },
    { lat: 48.1803985, lng: 17.3877632 },
    { lat: 48.1804335, lng: 17.3880578 },
    { lat: 48.1804327, lng: 17.3884582 },
    { lat: 48.1803536, lng: 17.3886665 },
    { lat: 48.1802924, lng: 17.3887312 },
    { lat: 48.1803459, lng: 17.388877 },
    { lat: 48.1803612, lng: 17.3897344 },
    { lat: 48.1803745, lng: 17.390284 },
    { lat: 48.1803907, lng: 17.3908255 },
    { lat: 48.180451, lng: 17.393497 },
    { lat: 48.1800437, lng: 17.3945419 },
    { lat: 48.1799203, lng: 17.3947271 },
    { lat: 48.1800699, lng: 17.3949788 },
    { lat: 48.1801455, lng: 17.3950117 },
    { lat: 48.1802007, lng: 17.3952004 },
    { lat: 48.1801451, lng: 17.3960623 },
    { lat: 48.1800501, lng: 17.3966842 },
    { lat: 48.1799872, lng: 17.3971205 },
    { lat: 48.1799221, lng: 17.3974054 },
    { lat: 48.1798067, lng: 17.3980065 },
    { lat: 48.1797732, lng: 17.3981685 },
    { lat: 48.1797208, lng: 17.3983525 },
    { lat: 48.1794598, lng: 17.3992697 },
    { lat: 48.1793641, lng: 17.3996907 },
    { lat: 48.179339, lng: 17.4001537 },
    { lat: 48.1793088, lng: 17.4003499 },
    { lat: 48.1792545, lng: 17.4005224 },
    { lat: 48.1790287, lng: 17.4010417 },
    { lat: 48.1789492, lng: 17.401191 },
    { lat: 48.178855, lng: 17.4013229 },
    { lat: 48.1786738, lng: 17.401508 },
    { lat: 48.1785603, lng: 17.4016369 },
    { lat: 48.1784435, lng: 17.4018541 },
    { lat: 48.1781207, lng: 17.4022566 },
    { lat: 48.1777899, lng: 17.4025531 },
    { lat: 48.1776974, lng: 17.4026248 },
    { lat: 48.1774053, lng: 17.4027526 },
    { lat: 48.1774094, lng: 17.402763 },
    { lat: 48.1774084, lng: 17.4029219 },
    { lat: 48.1776477, lng: 17.4030061 },
    { lat: 48.1796036, lng: 17.4038323 },
    { lat: 48.1802839, lng: 17.4041132 },
    { lat: 48.1807631, lng: 17.4043608 },
    { lat: 48.1808922, lng: 17.4044535 },
    { lat: 48.1810827, lng: 17.4046546 },
  ],
  VeľkýBiel: [
    { lat: 48.238876, lng: 17.317695 },
    { lat: 48.2386438, lng: 17.3171673 },
    { lat: 48.2380539, lng: 17.3158087 },
    { lat: 48.2380259, lng: 17.3158296 },
    { lat: 48.2378835, lng: 17.3158787 },
    { lat: 48.2376721, lng: 17.3159107 },
    { lat: 48.2373375, lng: 17.3160092 },
    { lat: 48.2370707, lng: 17.3161618 },
    { lat: 48.2367972, lng: 17.316384 },
    { lat: 48.2365903, lng: 17.31662 },
    { lat: 48.2364318, lng: 17.3168369 },
    { lat: 48.236223, lng: 17.3171992 },
    { lat: 48.2359674, lng: 17.3177426 },
    { lat: 48.2358379, lng: 17.3180255 },
    { lat: 48.2357306, lng: 17.3182298 },
    { lat: 48.2355677, lng: 17.3185185 },
    { lat: 48.2354235, lng: 17.3187469 },
    { lat: 48.2351747, lng: 17.3191555 },
    { lat: 48.2349462, lng: 17.3195969 },
    { lat: 48.2346778, lng: 17.320323 },
    { lat: 48.2345622, lng: 17.3205742 },
    { lat: 48.2342594, lng: 17.3210898 },
    { lat: 48.2341178, lng: 17.3213559 },
    { lat: 48.2338426, lng: 17.3218613 },
    { lat: 48.2337368, lng: 17.3220392 },
    { lat: 48.2335464, lng: 17.3222864 },
    { lat: 48.2332806, lng: 17.3225039 },
    { lat: 48.2326004, lng: 17.3228968 },
    { lat: 48.2322612, lng: 17.3230119 },
    { lat: 48.231953, lng: 17.3231342 },
    { lat: 48.2313453, lng: 17.3236678 },
    { lat: 48.2309945, lng: 17.3239833 },
    { lat: 48.2301963, lng: 17.3245904 },
    { lat: 48.2300318, lng: 17.3248538 },
    { lat: 48.2294672, lng: 17.3257503 },
    { lat: 48.2292762, lng: 17.325973 },
    { lat: 48.2288803, lng: 17.3262882 },
    { lat: 48.2285271, lng: 17.3265525 },
    { lat: 48.2283723, lng: 17.3266741 },
    { lat: 48.2282324, lng: 17.3267962 },
    { lat: 48.2281641, lng: 17.3268731 },
    { lat: 48.228036, lng: 17.3269875 },
    { lat: 48.2278726, lng: 17.3271121 },
    { lat: 48.2276675, lng: 17.3272396 },
    { lat: 48.2273335, lng: 17.3275551 },
    { lat: 48.2270986, lng: 17.3277275 },
    { lat: 48.2264521, lng: 17.3281152 },
    { lat: 48.2262951, lng: 17.3282712 },
    { lat: 48.2262428, lng: 17.3283 },
    { lat: 48.2261267, lng: 17.3284592 },
    { lat: 48.2260524, lng: 17.3285486 },
    { lat: 48.2258785, lng: 17.3287382 },
    { lat: 48.2255051, lng: 17.3290375 },
    { lat: 48.2252647, lng: 17.3293317 },
    { lat: 48.2249292, lng: 17.3299676 },
    { lat: 48.2248687, lng: 17.3300957 },
    { lat: 48.2246562, lng: 17.3304196 },
    { lat: 48.2245418, lng: 17.3305869 },
    { lat: 48.2243335, lng: 17.3308728 },
    { lat: 48.2242083, lng: 17.331029 },
    { lat: 48.2240245, lng: 17.331275 },
    { lat: 48.223823, lng: 17.3315776 },
    { lat: 48.2237369, lng: 17.3316936 },
    { lat: 48.2234138, lng: 17.3321062 },
    { lat: 48.2232015, lng: 17.3323817 },
    { lat: 48.2229588, lng: 17.3327961 },
    { lat: 48.2227535, lng: 17.3331282 },
    { lat: 48.2225552, lng: 17.333547 },
    { lat: 48.2223886, lng: 17.3338664 },
    { lat: 48.2220872, lng: 17.3342989 },
    { lat: 48.2220123, lng: 17.3343774 },
    { lat: 48.2217803, lng: 17.334595 },
    { lat: 48.2216558, lng: 17.3347292 },
    { lat: 48.2213715, lng: 17.3350955 },
    { lat: 48.221239, lng: 17.335312 },
    { lat: 48.2211398, lng: 17.3354551 },
    { lat: 48.2210516, lng: 17.3355383 },
    { lat: 48.2209333, lng: 17.3356975 },
    { lat: 48.2208315, lng: 17.3359527 },
    { lat: 48.220684, lng: 17.336304 },
    { lat: 48.2205981, lng: 17.3364231 },
    { lat: 48.2204027, lng: 17.3366649 },
    { lat: 48.2202999, lng: 17.336784 },
    { lat: 48.2200864, lng: 17.3371121 },
    { lat: 48.2200108, lng: 17.3372677 },
    { lat: 48.2197483, lng: 17.3378447 },
    { lat: 48.21957, lng: 17.3382445 },
    { lat: 48.2195104, lng: 17.3383673 },
    { lat: 48.2194613, lng: 17.3384447 },
    { lat: 48.2193848, lng: 17.3386208 },
    { lat: 48.2193105, lng: 17.3388111 },
    { lat: 48.2192242, lng: 17.3390323 },
    { lat: 48.2191379, lng: 17.339198 },
    { lat: 48.2188885, lng: 17.3395448 },
    { lat: 48.2187806, lng: 17.3396892 },
    { lat: 48.2185997, lng: 17.3399466 },
    { lat: 48.2182919, lng: 17.3403493 },
    { lat: 48.2181509, lng: 17.3405239 },
    { lat: 48.2180274, lng: 17.3406908 },
    { lat: 48.2177432, lng: 17.3410073 },
    { lat: 48.2174626, lng: 17.3413443 },
    { lat: 48.2170109, lng: 17.3418629 },
    { lat: 48.2168936, lng: 17.3419841 },
    { lat: 48.216734, lng: 17.3421344 },
    { lat: 48.2165136, lng: 17.3423223 },
    { lat: 48.2162441, lng: 17.3425369 },
    { lat: 48.2158003, lng: 17.3428826 },
    { lat: 48.2156382, lng: 17.3430272 },
    { lat: 48.2153731, lng: 17.3432403 },
    { lat: 48.2152513, lng: 17.3433453 },
    { lat: 48.2148929, lng: 17.3436346 },
    { lat: 48.2143842, lng: 17.3440569 },
    { lat: 48.2138393, lng: 17.3444461 },
    { lat: 48.2135365, lng: 17.3446529 },
    { lat: 48.2133587, lng: 17.3447661 },
    { lat: 48.213045, lng: 17.3449433 },
    { lat: 48.2128814, lng: 17.3449969 },
    { lat: 48.2124995, lng: 17.3451494 },
    { lat: 48.2123071, lng: 17.3452299 },
    { lat: 48.2121287, lng: 17.345317 },
    { lat: 48.2117078, lng: 17.3455478 },
    { lat: 48.2111437, lng: 17.3458336 },
    { lat: 48.210903, lng: 17.3459526 },
    { lat: 48.2097969, lng: 17.3465343 },
    { lat: 48.2091751, lng: 17.3468698 },
    { lat: 48.2089817, lng: 17.3469524 },
    { lat: 48.2084403, lng: 17.3471713 },
    { lat: 48.2082144, lng: 17.3472737 },
    { lat: 48.2069779, lng: 17.3478476 },
    { lat: 48.2066871, lng: 17.3479781 },
    { lat: 48.2062992, lng: 17.348161 },
    { lat: 48.2057662, lng: 17.3484201 },
    { lat: 48.2052026, lng: 17.3486964 },
    { lat: 48.2049402, lng: 17.3488304 },
    { lat: 48.2047703, lng: 17.3489424 },
    { lat: 48.2043161, lng: 17.3490439 },
    { lat: 48.2045223, lng: 17.3502936 },
    { lat: 48.2045369, lng: 17.3503961 },
    { lat: 48.2043424, lng: 17.3504272 },
    { lat: 48.2040942, lng: 17.3505339 },
    { lat: 48.204102, lng: 17.3505843 },
    { lat: 48.2041708, lng: 17.3506687 },
    { lat: 48.204287, lng: 17.35089 },
    { lat: 48.2043835, lng: 17.3515002 },
    { lat: 48.2044572, lng: 17.3519853 },
    { lat: 48.204683, lng: 17.35357 },
    { lat: 48.2048458, lng: 17.3546911 },
    { lat: 48.2049555, lng: 17.355519 },
    { lat: 48.2051155, lng: 17.3567536 },
    { lat: 48.2050101, lng: 17.3567995 },
    { lat: 48.2051552, lng: 17.3570672 },
    { lat: 48.2052743, lng: 17.3578836 },
    { lat: 48.2054246, lng: 17.3589015 },
    { lat: 48.20559, lng: 17.3600635 },
    { lat: 48.2057191, lng: 17.361067 },
    { lat: 48.2058223, lng: 17.3619017 },
    { lat: 48.2056278, lng: 17.3622768 },
    { lat: 48.205642, lng: 17.3622704 },
    { lat: 48.2058058, lng: 17.3629089 },
    { lat: 48.2059639, lng: 17.3628224 },
    { lat: 48.2062175, lng: 17.3644521 },
    { lat: 48.206276, lng: 17.3646556 },
    { lat: 48.2065494, lng: 17.3664836 },
    { lat: 48.2066835, lng: 17.3673588 },
    { lat: 48.2069295, lng: 17.3689762 },
    { lat: 48.2069499, lng: 17.3691515 },
    { lat: 48.2069829, lng: 17.3695362 },
    { lat: 48.2070003, lng: 17.3695767 },
    { lat: 48.2073574, lng: 17.3693031 },
    { lat: 48.208354, lng: 17.3684786 },
    { lat: 48.2089254, lng: 17.3679758 },
    { lat: 48.2090704, lng: 17.3678455 },
    { lat: 48.2094406, lng: 17.3674093 },
    { lat: 48.2094509, lng: 17.3674321 },
    { lat: 48.2094683, lng: 17.3674704 },
    { lat: 48.2095114, lng: 17.3674144 },
    { lat: 48.2096836, lng: 17.3677574 },
    { lat: 48.2099057, lng: 17.3682681 },
    { lat: 48.2101184, lng: 17.3688251 },
    { lat: 48.2105424, lng: 17.3699189 },
    { lat: 48.2111896, lng: 17.3715428 },
    { lat: 48.2113958, lng: 17.3720693 },
    { lat: 48.2116561, lng: 17.3727496 },
    { lat: 48.2118612, lng: 17.3733127 },
    { lat: 48.2119568, lng: 17.3736164 },
    { lat: 48.2120031, lng: 17.3738935 },
    { lat: 48.2120578, lng: 17.3742978 },
    { lat: 48.2120948, lng: 17.3746116 },
    { lat: 48.2121175, lng: 17.3747856 },
    { lat: 48.2121376, lng: 17.374975 },
    { lat: 48.2121619, lng: 17.3751817 },
    { lat: 48.2121867, lng: 17.3754738 },
    { lat: 48.2122096, lng: 17.3756786 },
    { lat: 48.2122438, lng: 17.3759852 },
    { lat: 48.2123345, lng: 17.3767315 },
    { lat: 48.2123817, lng: 17.3766773 },
    { lat: 48.2123938, lng: 17.3767255 },
    { lat: 48.2127752, lng: 17.3762521 },
    { lat: 48.2151635, lng: 17.3733395 },
    { lat: 48.2160453, lng: 17.3722647 },
    { lat: 48.2188661, lng: 17.3688151 },
    { lat: 48.219494, lng: 17.3680537 },
    { lat: 48.2195187, lng: 17.3681043 },
    { lat: 48.2195211, lng: 17.3681073 },
    { lat: 48.2202296, lng: 17.3694916 },
    { lat: 48.220232, lng: 17.3694939 },
    { lat: 48.2216491, lng: 17.3721091 },
    { lat: 48.2217097, lng: 17.3720875 },
    { lat: 48.2217381, lng: 17.3720555 },
    { lat: 48.2219678, lng: 17.3717167 },
    { lat: 48.2221322, lng: 17.3715017 },
    { lat: 48.2226227, lng: 17.3708838 },
    { lat: 48.2226793, lng: 17.3708164 },
    { lat: 48.2227818, lng: 17.3706569 },
    { lat: 48.2230222, lng: 17.3704111 },
    { lat: 48.2231107, lng: 17.3703313 },
    { lat: 48.2232071, lng: 17.3702851 },
    { lat: 48.2232693, lng: 17.3704078 },
    { lat: 48.2233192, lng: 17.3705493 },
    { lat: 48.2234049, lng: 17.3707191 },
    { lat: 48.2234178, lng: 17.3707445 },
    { lat: 48.2235587, lng: 17.370636 },
    { lat: 48.2236262, lng: 17.370528 },
    { lat: 48.2238459, lng: 17.3701561 },
    { lat: 48.2240226, lng: 17.3698715 },
    { lat: 48.2242533, lng: 17.3694446 },
    { lat: 48.2243095, lng: 17.3693519 },
    { lat: 48.2245996, lng: 17.3689527 },
    { lat: 48.2248014, lng: 17.3686704 },
    { lat: 48.2249763, lng: 17.3683752 },
    { lat: 48.2250433, lng: 17.3682419 },
    { lat: 48.2252957, lng: 17.3678348 },
    { lat: 48.225669, lng: 17.3673617 },
    { lat: 48.2259329, lng: 17.3670788 },
    { lat: 48.2263635, lng: 17.3664772 },
    { lat: 48.2264623, lng: 17.3666418 },
    { lat: 48.2265546, lng: 17.3665328 },
    { lat: 48.2267502, lng: 17.3661729 },
    { lat: 48.2271223, lng: 17.3655595 },
    { lat: 48.2272221, lng: 17.3654799 },
    { lat: 48.227574, lng: 17.365074 },
    { lat: 48.2279036, lng: 17.3647418 },
    { lat: 48.2281689, lng: 17.3644467 },
    { lat: 48.2285621, lng: 17.3639485 },
    { lat: 48.2288363, lng: 17.3636168 },
    { lat: 48.2296024, lng: 17.362621 },
    { lat: 48.2297178, lng: 17.3624849 },
    { lat: 48.2308566, lng: 17.3609874 },
    { lat: 48.2312591, lng: 17.3604591 },
    { lat: 48.2314577, lng: 17.3602152 },
    { lat: 48.2320248, lng: 17.3594913 },
    { lat: 48.2325196, lng: 17.3588353 },
    { lat: 48.2328014, lng: 17.3584652 },
    { lat: 48.2333483, lng: 17.3577628 },
    { lat: 48.2335661, lng: 17.3574991 },
    { lat: 48.2340745, lng: 17.3568454 },
    { lat: 48.2344074, lng: 17.3564101 },
    { lat: 48.234742, lng: 17.3560008 },
    { lat: 48.2350242, lng: 17.3556517 },
    { lat: 48.2354829, lng: 17.3550341 },
    { lat: 48.2360433, lng: 17.3543126 },
    { lat: 48.2369136, lng: 17.3531152 },
    { lat: 48.2372909, lng: 17.3525636 },
    { lat: 48.2377444, lng: 17.3518493 },
    { lat: 48.2380457, lng: 17.3513435 },
    { lat: 48.2383437, lng: 17.350773 },
    { lat: 48.2387973, lng: 17.3500232 },
    { lat: 48.2392304, lng: 17.3492391 },
    { lat: 48.2393936, lng: 17.34892 },
    { lat: 48.2395364, lng: 17.3484281 },
    { lat: 48.2396238, lng: 17.3481929 },
    { lat: 48.2397821, lng: 17.3478509 },
    { lat: 48.24046, lng: 17.3467163 },
    { lat: 48.2408424, lng: 17.3462273 },
    { lat: 48.2412986, lng: 17.3456038 },
    { lat: 48.2420504, lng: 17.344514 },
    { lat: 48.2424219, lng: 17.3439944 },
    { lat: 48.2427964, lng: 17.3434184 },
    { lat: 48.2434616, lng: 17.3424201 },
    { lat: 48.2451185, lng: 17.3399735 },
    { lat: 48.2455483, lng: 17.3393276 },
    { lat: 48.2456787, lng: 17.3391445 },
    { lat: 48.2460864, lng: 17.3385431 },
    { lat: 48.2462848, lng: 17.3382442 },
    { lat: 48.2463938, lng: 17.3380634 },
    { lat: 48.2464468, lng: 17.3379929 },
    { lat: 48.2474045, lng: 17.3365801 },
    { lat: 48.247609, lng: 17.3362879 },
    { lat: 48.2482537, lng: 17.335338 },
    { lat: 48.2485727, lng: 17.3348115 },
    { lat: 48.2485901, lng: 17.3347667 },
    { lat: 48.248422, lng: 17.334532 },
    { lat: 48.248241, lng: 17.334287 },
    { lat: 48.248201, lng: 17.334242 },
    { lat: 48.248073, lng: 17.334101 },
    { lat: 48.247984, lng: 17.334003 },
    { lat: 48.247808, lng: 17.333804 },
    { lat: 48.247718, lng: 17.333667 },
    { lat: 48.247523, lng: 17.333384 },
    { lat: 48.247268, lng: 17.333062 },
    { lat: 48.246953, lng: 17.332606 },
    { lat: 48.246869, lng: 17.332456 },
    { lat: 48.246704, lng: 17.332164 },
    { lat: 48.246526, lng: 17.331851 },
    { lat: 48.246216, lng: 17.331335 },
    { lat: 48.245967, lng: 17.330846 },
    { lat: 48.245788, lng: 17.330429 },
    { lat: 48.2456, lng: 17.329844 },
    { lat: 48.245487, lng: 17.329484 },
    { lat: 48.245415, lng: 17.329275 },
    { lat: 48.245356, lng: 17.329116 },
    { lat: 48.245284, lng: 17.328957 },
    { lat: 48.245135, lng: 17.32866 },
    { lat: 48.244987, lng: 17.328386 },
    { lat: 48.244864, lng: 17.32824 },
    { lat: 48.244791, lng: 17.328158 },
    { lat: 48.244694, lng: 17.328012 },
    { lat: 48.244682, lng: 17.327991 },
    { lat: 48.244631, lng: 17.327906 },
    { lat: 48.244512, lng: 17.327752 },
    { lat: 48.244284, lng: 17.327461 },
    { lat: 48.244058, lng: 17.327158 },
    { lat: 48.24376, lng: 17.326639 },
    { lat: 48.243509, lng: 17.326189 },
    { lat: 48.243363, lng: 17.325879 },
    { lat: 48.243228, lng: 17.325537 },
    { lat: 48.243082, lng: 17.325208 },
    { lat: 48.24294, lng: 17.32491 },
    { lat: 48.242772, lng: 17.324621 },
    { lat: 48.242469, lng: 17.324148 },
    { lat: 48.242277, lng: 17.323787 },
    { lat: 48.242011, lng: 17.323416 },
    { lat: 48.241943, lng: 17.323318 },
    { lat: 48.2419392, lng: 17.3233295 },
    { lat: 48.241767, lng: 17.323048 },
    { lat: 48.241729, lng: 17.322976 },
    { lat: 48.241507, lng: 17.322477 },
    { lat: 48.241407, lng: 17.32209 },
    { lat: 48.241358, lng: 17.322049 },
    { lat: 48.241082, lng: 17.3218 },
    { lat: 48.240706, lng: 17.321464 },
    { lat: 48.240621, lng: 17.321358 },
    { lat: 48.240411, lng: 17.321077 },
    { lat: 48.240155, lng: 17.320627 },
    { lat: 48.239924, lng: 17.319988 },
    { lat: 48.239528, lng: 17.319289 },
    { lat: 48.239401, lng: 17.319061 },
    { lat: 48.239341, lng: 17.318934 },
    { lat: 48.23934, lng: 17.318932 },
    { lat: 48.239165, lng: 17.318474 },
    { lat: 48.238876, lng: 17.317695 },
  ],
  Vlky: [
    { lat: 48.161947, lng: 17.376649 },
    { lat: 48.1627379, lng: 17.3750434 },
    { lat: 48.1629468, lng: 17.3746255 },
    { lat: 48.1630482, lng: 17.3744148 },
    { lat: 48.1636389, lng: 17.3732081 },
    { lat: 48.1637649, lng: 17.3729576 },
    { lat: 48.1640197, lng: 17.3724331 },
    { lat: 48.1644606, lng: 17.3715413 },
    { lat: 48.1647666, lng: 17.3709175 },
    { lat: 48.1650696, lng: 17.3702854 },
    { lat: 48.1653366, lng: 17.3697222 },
    { lat: 48.1655924, lng: 17.3691975 },
    { lat: 48.1659146, lng: 17.3685967 },
    { lat: 48.166069, lng: 17.3683149 },
    { lat: 48.1662194, lng: 17.3680261 },
    { lat: 48.1667044, lng: 17.3677736 },
    { lat: 48.1676104, lng: 17.3672914 },
    { lat: 48.1675166, lng: 17.3665511 },
    { lat: 48.1674115, lng: 17.3659451 },
    { lat: 48.1673331, lng: 17.3654383 },
    { lat: 48.1670176, lng: 17.3640543 },
    { lat: 48.1667829, lng: 17.3631093 },
    { lat: 48.1666553, lng: 17.3627667 },
    { lat: 48.1661747, lng: 17.3614643 },
    { lat: 48.165602, lng: 17.3604161 },
    { lat: 48.1652285, lng: 17.3596806 },
    { lat: 48.1650452, lng: 17.3593326 },
    { lat: 48.1648006, lng: 17.3588577 },
    { lat: 48.1643805, lng: 17.3580498 },
    { lat: 48.1640846, lng: 17.3576364 },
    { lat: 48.1638121, lng: 17.3572527 },
    { lat: 48.1632214, lng: 17.356582 },
    { lat: 48.162287, lng: 17.3559769 },
    { lat: 48.1618429, lng: 17.3558538 },
    { lat: 48.1617684, lng: 17.3558553 },
    { lat: 48.1617332, lng: 17.3558681 },
    { lat: 48.1614481, lng: 17.3558112 },
    { lat: 48.1613449, lng: 17.3557858 },
    { lat: 48.1607543, lng: 17.3558075 },
    { lat: 48.1601267, lng: 17.356243 },
    { lat: 48.1598691, lng: 17.3564141 },
    { lat: 48.159706, lng: 17.3565106 },
    { lat: 48.1593129, lng: 17.3566991 },
    { lat: 48.158872, lng: 17.3568837 },
    { lat: 48.1582196, lng: 17.3571658 },
    { lat: 48.1578791, lng: 17.3572622 },
    { lat: 48.15735, lng: 17.357376 },
    { lat: 48.1570677, lng: 17.3574115 },
    { lat: 48.1564399, lng: 17.3574318 },
    { lat: 48.1562468, lng: 17.3574321 },
    { lat: 48.1559457, lng: 17.3573708 },
    { lat: 48.1558761, lng: 17.3573439 },
    { lat: 48.1557897, lng: 17.3573355 },
    { lat: 48.1555602, lng: 17.3572635 },
    { lat: 48.1553411, lng: 17.3571784 },
    { lat: 48.1550744, lng: 17.3570899 },
    { lat: 48.1548733, lng: 17.3570046 },
    { lat: 48.1546396, lng: 17.3569199 },
    { lat: 48.1544032, lng: 17.3568457 },
    { lat: 48.1541995, lng: 17.3567895 },
    { lat: 48.1540209, lng: 17.3567691 },
    { lat: 48.1531593, lng: 17.356617 },
    { lat: 48.1528796, lng: 17.3566234 },
    { lat: 48.1527962, lng: 17.3566094 },
    { lat: 48.1525447, lng: 17.356615 },
    { lat: 48.1524511, lng: 17.3566009 },
    { lat: 48.1523256, lng: 17.3565991 },
    { lat: 48.1522428, lng: 17.3566095 },
    { lat: 48.1520302, lng: 17.3565908 },
    { lat: 48.1518386, lng: 17.3565616 },
    { lat: 48.151623, lng: 17.3564961 },
    { lat: 48.1515166, lng: 17.3564754 },
    { lat: 48.1513301, lng: 17.3564234 },
    { lat: 48.1512427, lng: 17.3564142 },
    { lat: 48.1511719, lng: 17.356385 },
    { lat: 48.151044, lng: 17.3563657 },
    { lat: 48.1508203, lng: 17.3562956 },
    { lat: 48.1507466, lng: 17.3562558 },
    { lat: 48.1505001, lng: 17.3561669 },
    { lat: 48.1503057, lng: 17.3560629 },
    { lat: 48.1501345, lng: 17.3559504 },
    { lat: 48.1499785, lng: 17.3558949 },
    { lat: 48.149801, lng: 17.3558069 },
    { lat: 48.1495591, lng: 17.3556331 },
    { lat: 48.1493819, lng: 17.3555155 },
    { lat: 48.1491889, lng: 17.3553447 },
    { lat: 48.1488563, lng: 17.3550947 },
    { lat: 48.1487118, lng: 17.3549935 },
    { lat: 48.1484741, lng: 17.354797 },
    { lat: 48.1481809, lng: 17.3545127 },
    { lat: 48.1479843, lng: 17.3542874 },
    { lat: 48.147862, lng: 17.3541281 },
    { lat: 48.1477797, lng: 17.3539916 },
    { lat: 48.1476387, lng: 17.3537887 },
    { lat: 48.1476025, lng: 17.3537037 },
    { lat: 48.1475703, lng: 17.3536594 },
    { lat: 48.1475032, lng: 17.3535131 },
    { lat: 48.1474618, lng: 17.3534527 },
    { lat: 48.1474355, lng: 17.3533948 },
    { lat: 48.1473561, lng: 17.3532706 },
    { lat: 48.1472182, lng: 17.3530105 },
    { lat: 48.1470625, lng: 17.3527557 },
    { lat: 48.14693, lng: 17.3524579 },
    { lat: 48.1468824, lng: 17.3522818 },
    { lat: 48.1466044, lng: 17.3530896 },
    { lat: 48.1460644, lng: 17.3546879 },
    { lat: 48.1456409, lng: 17.3553779 },
    { lat: 48.1453868, lng: 17.3557232 },
    { lat: 48.1451911, lng: 17.356113 },
    { lat: 48.1446305, lng: 17.3572433 },
    { lat: 48.1445424, lng: 17.3574343 },
    { lat: 48.144447, lng: 17.3576154 },
    { lat: 48.1440258, lng: 17.3584281 },
    { lat: 48.1440065, lng: 17.3584757 },
    { lat: 48.1434599, lng: 17.3596148 },
    { lat: 48.1433866, lng: 17.3597707 },
    { lat: 48.1431438, lng: 17.360375 },
    { lat: 48.1431474, lng: 17.3609813 },
    { lat: 48.1429895, lng: 17.3617926 },
    { lat: 48.1428327, lng: 17.3626567 },
    { lat: 48.142514, lng: 17.3637921 },
    { lat: 48.1420105, lng: 17.3647594 },
    { lat: 48.1416914, lng: 17.365423 },
    { lat: 48.1416111, lng: 17.3655959 },
    { lat: 48.1413638, lng: 17.3661114 },
    { lat: 48.141614, lng: 17.3664477 },
    { lat: 48.1417586, lng: 17.3666561 },
    { lat: 48.1420091, lng: 17.3670642 },
    { lat: 48.1423411, lng: 17.3676316 },
    { lat: 48.1428156, lng: 17.3684776 },
    { lat: 48.1430013, lng: 17.3688464 },
    { lat: 48.1432844, lng: 17.3693045 },
    { lat: 48.1435059, lng: 17.3696873 },
    { lat: 48.1436483, lng: 17.3699144 },
    { lat: 48.1439421, lng: 17.3704318 },
    { lat: 48.1439825, lng: 17.3705306 },
    { lat: 48.1442207, lng: 17.3709076 },
    { lat: 48.1443183, lng: 17.3710367 },
    { lat: 48.1444999, lng: 17.3712567 },
    { lat: 48.1452016, lng: 17.3720704 },
    { lat: 48.1454916, lng: 17.3723942 },
    { lat: 48.1456175, lng: 17.372521 },
    { lat: 48.1464172, lng: 17.3732463 },
    { lat: 48.1477559, lng: 17.3744742 },
    { lat: 48.1476393, lng: 17.3747469 },
    { lat: 48.1475087, lng: 17.3747871 },
    { lat: 48.1475572, lng: 17.3752171 },
    { lat: 48.1475892, lng: 17.3756556 },
    { lat: 48.1475946, lng: 17.3764269 },
    { lat: 48.1475058, lng: 17.3773142 },
    { lat: 48.147461, lng: 17.377746 },
    { lat: 48.151208, lng: 17.379187 },
    { lat: 48.151004, lng: 17.377919 },
    { lat: 48.151013, lng: 17.377262 },
    { lat: 48.151293, lng: 17.375582 },
    { lat: 48.151559, lng: 17.374761 },
    { lat: 48.151902, lng: 17.374265 },
    { lat: 48.152666, lng: 17.37384 },
    { lat: 48.153211, lng: 17.373879 },
    { lat: 48.153776, lng: 17.373741 },
    { lat: 48.154621, lng: 17.374067 },
    { lat: 48.155029, lng: 17.374567 },
    { lat: 48.155154, lng: 17.374927 },
    { lat: 48.155429, lng: 17.376223 },
    { lat: 48.155659, lng: 17.376802 },
    { lat: 48.155738, lng: 17.377128 },
    { lat: 48.156248, lng: 17.37812 },
    { lat: 48.15636, lng: 17.378508 },
    { lat: 48.15616, lng: 17.379072 },
    { lat: 48.156079, lng: 17.379476 },
    { lat: 48.156106, lng: 17.379776 },
    { lat: 48.156735, lng: 17.380261 },
    { lat: 48.157338, lng: 17.381118 },
    { lat: 48.157677, lng: 17.38128 },
    { lat: 48.157952, lng: 17.380528 },
    { lat: 48.158242, lng: 17.380301 },
    { lat: 48.158306, lng: 17.380157 },
    { lat: 48.158332, lng: 17.378693 },
    { lat: 48.158825, lng: 17.377975 },
    { lat: 48.159052, lng: 17.37783 },
    { lat: 48.159086, lng: 17.376954 },
    { lat: 48.159142, lng: 17.376741 },
    { lat: 48.15964, lng: 17.375788 },
    { lat: 48.160364, lng: 17.37418 },
    { lat: 48.161254, lng: 17.375836 },
    { lat: 48.161947, lng: 17.376649 },
  ],
  Zálesie: [
    { lat: 48.1480266, lng: 17.2835638 },
    { lat: 48.1483072, lng: 17.2837173 },
    { lat: 48.1484539, lng: 17.2838021 },
    { lat: 48.1485141, lng: 17.2838556 },
    { lat: 48.1486233, lng: 17.2839187 },
    { lat: 48.1491505, lng: 17.2841868 },
    { lat: 48.1494214, lng: 17.2843383 },
    { lat: 48.1496688, lng: 17.2844907 },
    { lat: 48.1504198, lng: 17.2849974 },
    { lat: 48.1508098, lng: 17.2851381 },
    { lat: 48.1511772, lng: 17.2852787 },
    { lat: 48.1518011, lng: 17.2854708 },
    { lat: 48.1519661, lng: 17.2854734 },
    { lat: 48.1523136, lng: 17.2855166 },
    { lat: 48.1525028, lng: 17.285524 },
    { lat: 48.1527919, lng: 17.2855572 },
    { lat: 48.1532404, lng: 17.2855415 },
    { lat: 48.1534713, lng: 17.2855469 },
    { lat: 48.1536101, lng: 17.2855288 },
    { lat: 48.1537807, lng: 17.2854953 },
    { lat: 48.1541058, lng: 17.2854404 },
    { lat: 48.1546848, lng: 17.285348 },
    { lat: 48.1548316, lng: 17.2851936 },
    { lat: 48.1549953, lng: 17.2850546 },
    { lat: 48.1552274, lng: 17.284995 },
    { lat: 48.1556046, lng: 17.284891 },
    { lat: 48.1559888, lng: 17.2847211 },
    { lat: 48.1561305, lng: 17.2845598 },
    { lat: 48.1563859, lng: 17.2843558 },
    { lat: 48.1565314, lng: 17.2841993 },
    { lat: 48.1567686, lng: 17.2839927 },
    { lat: 48.1571051, lng: 17.2836339 },
    { lat: 48.1571943, lng: 17.2835481 },
    { lat: 48.157359, lng: 17.2834081 },
    { lat: 48.1575626, lng: 17.2832589 },
    { lat: 48.1576644, lng: 17.2832084 },
    { lat: 48.1579652, lng: 17.2830102 },
    { lat: 48.1580381, lng: 17.2829674 },
    { lat: 48.1583558, lng: 17.2828527 },
    { lat: 48.1587668, lng: 17.2826433 },
    { lat: 48.1588999, lng: 17.2825562 },
    { lat: 48.1594936, lng: 17.2820207 },
    { lat: 48.1599891, lng: 17.2815708 },
    { lat: 48.1601264, lng: 17.2813918 },
    { lat: 48.1605018, lng: 17.2811252 },
    { lat: 48.1606208, lng: 17.2810619 },
    { lat: 48.1608362, lng: 17.2810063 },
    { lat: 48.161315, lng: 17.2809853 },
    { lat: 48.161521, lng: 17.2809935 },
    { lat: 48.1619859, lng: 17.281189 },
    { lat: 48.1625159, lng: 17.2814997 },
    { lat: 48.1628787, lng: 17.2817228 },
    { lat: 48.1630917, lng: 17.2818694 },
    { lat: 48.1636604, lng: 17.2821997 },
    { lat: 48.1640831, lng: 17.2825449 },
    { lat: 48.1647097, lng: 17.2830661 },
    { lat: 48.164883, lng: 17.2831248 },
    { lat: 48.1650412, lng: 17.2832626 },
    { lat: 48.1657384, lng: 17.2838272 },
    { lat: 48.1662181, lng: 17.284403 },
    { lat: 48.1666289, lng: 17.2852472 },
    { lat: 48.1667756, lng: 17.2855541 },
    { lat: 48.1669568, lng: 17.2860548 },
    { lat: 48.1672367, lng: 17.2868503 },
    { lat: 48.1673802, lng: 17.2874158 },
    { lat: 48.1674324, lng: 17.2876749 },
    { lat: 48.1675189, lng: 17.288146 },
    { lat: 48.1676336, lng: 17.2890416 },
    { lat: 48.1677271, lng: 17.2901821 },
    { lat: 48.1677002, lng: 17.2907813 },
    { lat: 48.1676598, lng: 17.2910753 },
    { lat: 48.1677642, lng: 17.2917488 },
    { lat: 48.1678777, lng: 17.2929343 },
    { lat: 48.1678931, lng: 17.293833 },
    { lat: 48.1679261, lng: 17.2942301 },
    { lat: 48.1680258, lng: 17.2946771 },
    { lat: 48.1681074, lng: 17.2950568 },
    { lat: 48.1681692, lng: 17.2950022 },
    { lat: 48.1681781, lng: 17.2950515 },
    { lat: 48.1682802, lng: 17.2953809 },
    { lat: 48.1684315, lng: 17.2957393 },
    { lat: 48.1685817, lng: 17.2960118 },
    { lat: 48.1687618, lng: 17.2962579 },
    { lat: 48.1688289, lng: 17.2964194 },
    { lat: 48.1691517, lng: 17.2967719 },
    { lat: 48.1693703, lng: 17.2970207 },
    { lat: 48.1696259, lng: 17.2971797 },
    { lat: 48.1699573, lng: 17.2973062 },
    { lat: 48.1701126, lng: 17.297399 },
    { lat: 48.1704642, lng: 17.2975227 },
    { lat: 48.1708056, lng: 17.2976285 },
    { lat: 48.1709629, lng: 17.2976507 },
    { lat: 48.1711079, lng: 17.2976387 },
    { lat: 48.1712315, lng: 17.2976111 },
    { lat: 48.1714508, lng: 17.2975786 },
    { lat: 48.1717878, lng: 17.2974318 },
    { lat: 48.1718884, lng: 17.2973814 },
    { lat: 48.1720081, lng: 17.2972793 },
    { lat: 48.1721821, lng: 17.2972435 },
    { lat: 48.1725175, lng: 17.2970377 },
    { lat: 48.1726885, lng: 17.2969761 },
    { lat: 48.1728388, lng: 17.2967909 },
    { lat: 48.1730093, lng: 17.2967877 },
    { lat: 48.1731707, lng: 17.2968196 },
    { lat: 48.1733394, lng: 17.2968773 },
    { lat: 48.1735167, lng: 17.296911 },
    { lat: 48.1736767, lng: 17.2969897 },
    { lat: 48.1738332, lng: 17.2971007 },
    { lat: 48.1739734, lng: 17.2972734 },
    { lat: 48.1741202, lng: 17.2973927 },
    { lat: 48.1744206, lng: 17.2976464 },
    { lat: 48.1746646, lng: 17.2978696 },
    { lat: 48.1748102, lng: 17.2980227 },
    { lat: 48.1749319, lng: 17.2982387 },
    { lat: 48.1750818, lng: 17.2983526 },
    { lat: 48.1752428, lng: 17.2984479 },
    { lat: 48.175403, lng: 17.2985635 },
    { lat: 48.1755985, lng: 17.2986857 },
    { lat: 48.1757424, lng: 17.2988115 },
    { lat: 48.1759124, lng: 17.2989062 },
    { lat: 48.1760133, lng: 17.2989445 },
    { lat: 48.1761679, lng: 17.2990449 },
    { lat: 48.1765044, lng: 17.2991294 },
    { lat: 48.176671, lng: 17.2991894 },
    { lat: 48.1768344, lng: 17.2992674 },
    { lat: 48.176927, lng: 17.2992664 },
    { lat: 48.1770842, lng: 17.2993386 },
    { lat: 48.177132, lng: 17.2990866 },
    { lat: 48.1771836, lng: 17.2988432 },
    { lat: 48.1772009, lng: 17.2988476 },
    { lat: 48.177213, lng: 17.2988086 },
    { lat: 48.1777618, lng: 17.2984302 },
    { lat: 48.1778938, lng: 17.298393 },
    { lat: 48.1779793, lng: 17.2984225 },
    { lat: 48.1786312, lng: 17.2986277 },
    { lat: 48.1787596, lng: 17.2986568 },
    { lat: 48.178872, lng: 17.2986647 },
    { lat: 48.1793961, lng: 17.298632 },
    { lat: 48.1795902, lng: 17.2985256 },
    { lat: 48.1797479, lng: 17.2984313 },
    { lat: 48.1799413, lng: 17.2982988 },
    { lat: 48.1800268, lng: 17.2982264 },
    { lat: 48.1801447, lng: 17.2979918 },
    { lat: 48.1801812, lng: 17.2978277 },
    { lat: 48.1802711, lng: 17.2967283 },
    { lat: 48.1803073, lng: 17.2960624 },
    { lat: 48.1802863, lng: 17.2958307 },
    { lat: 48.1802161, lng: 17.2955025 },
    { lat: 48.180171, lng: 17.295314 },
    { lat: 48.1801757, lng: 17.2950278 },
    { lat: 48.1802308, lng: 17.2947521 },
    { lat: 48.1802214, lng: 17.2946429 },
    { lat: 48.1801764, lng: 17.2944062 },
    { lat: 48.1801953, lng: 17.2940427 },
    { lat: 48.1802018, lng: 17.293851 },
    { lat: 48.180192, lng: 17.2936691 },
    { lat: 48.1801402, lng: 17.2934994 },
    { lat: 48.1799401, lng: 17.2931576 },
    { lat: 48.1798391, lng: 17.2928253 },
    { lat: 48.1797062, lng: 17.2924397 },
    { lat: 48.1797135, lng: 17.2922595 },
    { lat: 48.1797772, lng: 17.2918894 },
    { lat: 48.1798155, lng: 17.2910181 },
    { lat: 48.1798052, lng: 17.290659 },
    { lat: 48.1797463, lng: 17.2903975 },
    { lat: 48.1795273, lng: 17.2901621 },
    { lat: 48.1794243, lng: 17.2900397 },
    { lat: 48.179302, lng: 17.2898661 },
    { lat: 48.1792004, lng: 17.2896944 },
    { lat: 48.1790722, lng: 17.2896012 },
    { lat: 48.178989, lng: 17.2895045 },
    { lat: 48.1789352, lng: 17.2893732 },
    { lat: 48.1789011, lng: 17.2891662 },
    { lat: 48.1789069, lng: 17.2890319 },
    { lat: 48.1789897, lng: 17.2888323 },
    { lat: 48.1791303, lng: 17.2885833 },
    { lat: 48.1793006, lng: 17.2882566 },
    { lat: 48.1794214, lng: 17.2879821 },
    { lat: 48.1795415, lng: 17.287748 },
    { lat: 48.1796116, lng: 17.2875113 },
    { lat: 48.179684, lng: 17.2871045 },
    { lat: 48.1796926, lng: 17.2866362 },
    { lat: 48.1797124, lng: 17.2861307 },
    { lat: 48.1796469, lng: 17.2858085 },
    { lat: 48.1795667, lng: 17.2856903 },
    { lat: 48.179165, lng: 17.2854238 },
    { lat: 48.1789127, lng: 17.285315 },
    { lat: 48.1787602, lng: 17.2851788 },
    { lat: 48.1786439, lng: 17.2849925 },
    { lat: 48.1785191, lng: 17.2848666 },
    { lat: 48.1784495, lng: 17.2847511 },
    { lat: 48.178415, lng: 17.284606 },
    { lat: 48.1784145, lng: 17.284497 },
    { lat: 48.178421, lng: 17.2844412 },
    { lat: 48.1784462, lng: 17.2843446 },
    { lat: 48.1786249, lng: 17.2839043 },
    { lat: 48.1787192, lng: 17.2838277 },
    { lat: 48.1788718, lng: 17.2835223 },
    { lat: 48.179011, lng: 17.2833884 },
    { lat: 48.1795626, lng: 17.2832409 },
    { lat: 48.1799297, lng: 17.2831046 },
    { lat: 48.1802569, lng: 17.2827901 },
    { lat: 48.1804247, lng: 17.2825617 },
    { lat: 48.1805388, lng: 17.2823565 },
    { lat: 48.1806117, lng: 17.2822105 },
    { lat: 48.1807843, lng: 17.2817644 },
    { lat: 48.1808965, lng: 17.2815999 },
    { lat: 48.1811954, lng: 17.2810135 },
    { lat: 48.1812267, lng: 17.2808027 },
    { lat: 48.1811982, lng: 17.2804066 },
    { lat: 48.1810466, lng: 17.2799266 },
    { lat: 48.1809067, lng: 17.2795224 },
    { lat: 48.1806333, lng: 17.2791111 },
    { lat: 48.1804999, lng: 17.2790912 },
    { lat: 48.1802967, lng: 17.2792827 },
    { lat: 48.1801717, lng: 17.2794938 },
    { lat: 48.1800331, lng: 17.2797517 },
    { lat: 48.1799077, lng: 17.2801106 },
    { lat: 48.1798204, lng: 17.2803962 },
    { lat: 48.179523, lng: 17.2809024 },
    { lat: 48.179385, lng: 17.2809676 },
    { lat: 48.17907, lng: 17.2810049 },
    { lat: 48.1785419, lng: 17.2808442 },
    { lat: 48.1783694, lng: 17.2807988 },
    { lat: 48.1781334, lng: 17.280697 },
    { lat: 48.1779721, lng: 17.2805655 },
    { lat: 48.1778672, lng: 17.280515 },
    { lat: 48.1777647, lng: 17.2803994 },
    { lat: 48.1775981, lng: 17.2801192 },
    { lat: 48.1774937, lng: 17.2798575 },
    { lat: 48.1774205, lng: 17.2794859 },
    { lat: 48.177432, lng: 17.2793862 },
    { lat: 48.1775922, lng: 17.2790947 },
    { lat: 48.1778329, lng: 17.279044 },
    { lat: 48.1786678, lng: 17.2785502 },
    { lat: 48.1788952, lng: 17.278351 },
    { lat: 48.1791293, lng: 17.2778442 },
    { lat: 48.1791582, lng: 17.2776988 },
    { lat: 48.179192, lng: 17.2771872 },
    { lat: 48.1791985, lng: 17.2768602 },
    { lat: 48.1791669, lng: 17.27669 },
    { lat: 48.1790107, lng: 17.2762757 },
    { lat: 48.1786568, lng: 17.27571 },
    { lat: 48.1784929, lng: 17.2754363 },
    { lat: 48.178258, lng: 17.2750276 },
    { lat: 48.1780888, lng: 17.2745618 },
    { lat: 48.1778904, lng: 17.2740155 },
    { lat: 48.1777429, lng: 17.2738693 },
    { lat: 48.1774081, lng: 17.2735735 },
    { lat: 48.176912, lng: 17.2733837 },
    { lat: 48.1761334, lng: 17.2738402 },
    { lat: 48.1757217, lng: 17.2738152 },
    { lat: 48.1754426, lng: 17.2737789 },
    { lat: 48.1751119, lng: 17.2735289 },
    { lat: 48.1746833, lng: 17.2731998 },
    { lat: 48.1745489, lng: 17.2730281 },
    { lat: 48.1744488, lng: 17.2728833 },
    { lat: 48.1743352, lng: 17.2726687 },
    { lat: 48.1743527, lng: 17.2723368 },
    { lat: 48.1745184, lng: 17.2719238 },
    { lat: 48.1745264, lng: 17.2715832 },
    { lat: 48.1744896, lng: 17.2713184 },
    { lat: 48.1744289, lng: 17.271102 },
    { lat: 48.174328, lng: 17.270823 },
    { lat: 48.1745176, lng: 17.2702916 },
    { lat: 48.1747072, lng: 17.2699485 },
    { lat: 48.1747258, lng: 17.2695318 },
    { lat: 48.1746375, lng: 17.2691032 },
    { lat: 48.1746285, lng: 17.268831 },
    { lat: 48.1743593, lng: 17.268572 },
    { lat: 48.1741593, lng: 17.2684016 },
    { lat: 48.1739194, lng: 17.2682075 },
    { lat: 48.1736236, lng: 17.2679042 },
    { lat: 48.173591, lng: 17.2677369 },
    { lat: 48.1730939, lng: 17.2680766 },
    { lat: 48.1725819, lng: 17.268226 },
    { lat: 48.1720221, lng: 17.2679782 },
    { lat: 48.1718313, lng: 17.2678256 },
    { lat: 48.1717382, lng: 17.267734 },
    { lat: 48.1715732, lng: 17.2674267 },
    { lat: 48.171496, lng: 17.2671315 },
    { lat: 48.1715689, lng: 17.266495 },
    { lat: 48.1715659, lng: 17.265955 },
    { lat: 48.1714628, lng: 17.2655938 },
    { lat: 48.1711769, lng: 17.2645561 },
    { lat: 48.1709578, lng: 17.264217 },
    { lat: 48.1710187, lng: 17.2640603 },
    { lat: 48.1714615, lng: 17.26377 },
    { lat: 48.1715376, lng: 17.2637257 },
    { lat: 48.1715462, lng: 17.2636864 },
    { lat: 48.1712513, lng: 17.2635663 },
    { lat: 48.1711201, lng: 17.2634954 },
    { lat: 48.1709734, lng: 17.2633965 },
    { lat: 48.1707483, lng: 17.263273 },
    { lat: 48.1704281, lng: 17.2630443 },
    { lat: 48.1701958, lng: 17.2628982 },
    { lat: 48.1701787, lng: 17.2628618 },
    { lat: 48.1700674, lng: 17.2627339 },
    { lat: 48.1697891, lng: 17.2625742 },
    { lat: 48.1695811, lng: 17.2624186 },
    { lat: 48.1694048, lng: 17.262248 },
    { lat: 48.169109, lng: 17.2618597 },
    { lat: 48.1689222, lng: 17.2614981 },
    { lat: 48.1687116, lng: 17.2608783 },
    { lat: 48.1685231, lng: 17.2605745 },
    { lat: 48.168455, lng: 17.2606041 },
    { lat: 48.1683663, lng: 17.2603572 },
    { lat: 48.168332, lng: 17.2599425 },
    { lat: 48.1683375, lng: 17.259821 },
    { lat: 48.1683246, lng: 17.2596777 },
    { lat: 48.1682761, lng: 17.2595591 },
    { lat: 48.1681371, lng: 17.2592529 },
    { lat: 48.1680627, lng: 17.2591028 },
    { lat: 48.1679662, lng: 17.2589423 },
    { lat: 48.1677167, lng: 17.2585892 },
    { lat: 48.1676192, lng: 17.2584636 },
    { lat: 48.1672122, lng: 17.2579844 },
    { lat: 48.1671944, lng: 17.2580233 },
    { lat: 48.1669007, lng: 17.2587535 },
    { lat: 48.1671441, lng: 17.2590821 },
    { lat: 48.1672915, lng: 17.2592105 },
    { lat: 48.1674546, lng: 17.2595046 },
    { lat: 48.1676248, lng: 17.2601118 },
    { lat: 48.1676628, lng: 17.2603442 },
    { lat: 48.1676244, lng: 17.2608018 },
    { lat: 48.1676028, lng: 17.2611233 },
    { lat: 48.1674592, lng: 17.2617855 },
    { lat: 48.1673982, lng: 17.2620401 },
    { lat: 48.1673341, lng: 17.2624187 },
    { lat: 48.1672871, lng: 17.2626621 },
    { lat: 48.167157, lng: 17.2630167 },
    { lat: 48.1668793, lng: 17.2636321 },
    { lat: 48.1665964, lng: 17.2638801 },
    { lat: 48.1662802, lng: 17.2640004 },
    { lat: 48.165862, lng: 17.2640321 },
    { lat: 48.1656575, lng: 17.2640152 },
    { lat: 48.1654369, lng: 17.2639593 },
    { lat: 48.1649844, lng: 17.2637285 },
    { lat: 48.1647118, lng: 17.2635022 },
    { lat: 48.1644146, lng: 17.2632786 },
    { lat: 48.1642002, lng: 17.2634354 },
    { lat: 48.1637481, lng: 17.263603 },
    { lat: 48.1634513, lng: 17.2636419 },
    { lat: 48.1632586, lng: 17.2636327 },
    { lat: 48.1628816, lng: 17.2635848 },
    { lat: 48.1627439, lng: 17.2635529 },
    { lat: 48.1624591, lng: 17.2634143 },
    { lat: 48.1619439, lng: 17.2630915 },
    { lat: 48.1616265, lng: 17.2627859 },
    { lat: 48.1609551, lng: 17.2625436 },
    { lat: 48.1607697, lng: 17.2624892 },
    { lat: 48.1604143, lng: 17.262292 },
    { lat: 48.1600787, lng: 17.2621368 },
    { lat: 48.1597594, lng: 17.262077 },
    { lat: 48.1593505, lng: 17.2620936 },
    { lat: 48.1589106, lng: 17.262002 },
    { lat: 48.1584886, lng: 17.2616189 },
    { lat: 48.1582268, lng: 17.2611985 },
    { lat: 48.1581166, lng: 17.2609506 },
    { lat: 48.1579393, lng: 17.2602728 },
    { lat: 48.1576429, lng: 17.2594313 },
    { lat: 48.157422, lng: 17.259253 },
    { lat: 48.157406, lng: 17.2591153 },
    { lat: 48.1572103, lng: 17.2590777 },
    { lat: 48.157023, lng: 17.2590802 },
    { lat: 48.1568472, lng: 17.2590185 },
    { lat: 48.1566834, lng: 17.2590538 },
    { lat: 48.156272, lng: 17.2593732 },
    { lat: 48.15598, lng: 17.2595188 },
    { lat: 48.1555695, lng: 17.2598363 },
    { lat: 48.1558957, lng: 17.2601703 },
    { lat: 48.1560275, lng: 17.2603009 },
    { lat: 48.1565734, lng: 17.2608633 },
    { lat: 48.1566718, lng: 17.2609693 },
    { lat: 48.1569192, lng: 17.2614618 },
    { lat: 48.157088, lng: 17.2618429 },
    { lat: 48.1569667, lng: 17.2622112 },
    { lat: 48.1568586, lng: 17.2624535 },
    { lat: 48.1569026, lng: 17.2626395 },
    { lat: 48.1572371, lng: 17.262421 },
    { lat: 48.157529, lng: 17.262273 },
    { lat: 48.1577125, lng: 17.262196 },
    { lat: 48.1583593, lng: 17.2621033 },
    { lat: 48.1586397, lng: 17.2622271 },
    { lat: 48.1590061, lng: 17.2627063 },
    { lat: 48.1590914, lng: 17.2629537 },
    { lat: 48.1591598, lng: 17.263168 },
    { lat: 48.1591742, lng: 17.2636724 },
    { lat: 48.1590926, lng: 17.264197 },
    { lat: 48.159034, lng: 17.2645561 },
    { lat: 48.1588865, lng: 17.2648016 },
    { lat: 48.1587061, lng: 17.2650455 },
    { lat: 48.1585489, lng: 17.2652982 },
    { lat: 48.1583464, lng: 17.2656331 },
    { lat: 48.1581032, lng: 17.2659682 },
    { lat: 48.1577876, lng: 17.2662336 },
    { lat: 48.1571307, lng: 17.2665489 },
    { lat: 48.1568457, lng: 17.2666292 },
    { lat: 48.1564031, lng: 17.2668189 },
    { lat: 48.156323, lng: 17.2668561 },
    { lat: 48.1560745, lng: 17.2670104 },
    { lat: 48.1557061, lng: 17.2671949 },
    { lat: 48.1546759, lng: 17.2677396 },
    { lat: 48.1544403, lng: 17.2678656 },
    { lat: 48.1541463, lng: 17.2675556 },
    { lat: 48.1535267, lng: 17.2684326 },
    { lat: 48.1531872, lng: 17.2687792 },
    { lat: 48.1521855, lng: 17.2698066 },
    { lat: 48.1512812, lng: 17.2707172 },
    { lat: 48.1505178, lng: 17.2711291 },
    { lat: 48.149909, lng: 17.2714033 },
    { lat: 48.1487375, lng: 17.2718616 },
    { lat: 48.1483527, lng: 17.2720393 },
    { lat: 48.147822, lng: 17.2724505 },
    { lat: 48.1474462, lng: 17.2729165 },
    { lat: 48.1469204, lng: 17.2741853 },
    { lat: 48.1468761, lng: 17.2742847 },
    { lat: 48.1467479, lng: 17.2746995 },
    { lat: 48.1465349, lng: 17.2752712 },
    { lat: 48.1464043, lng: 17.2756866 },
    { lat: 48.1463553, lng: 17.2758144 },
    { lat: 48.1462475, lng: 17.2761495 },
    { lat: 48.1461005, lng: 17.2770329 },
    { lat: 48.1460617, lng: 17.2774177 },
    { lat: 48.1460539, lng: 17.2780155 },
    { lat: 48.1461155, lng: 17.2787913 },
    { lat: 48.1462524, lng: 17.2795273 },
    { lat: 48.1464945, lng: 17.2805034 },
    { lat: 48.1467568, lng: 17.2811862 },
    { lat: 48.1470707, lng: 17.2817989 },
    { lat: 48.1475609, lng: 17.2825466 },
    { lat: 48.1481919, lng: 17.2831386 },
    { lat: 48.1480266, lng: 17.2835638 },
  ],
  Boldog: [
    { lat: 48.2460822, lng: 17.4538857 },
    { lat: 48.2461669, lng: 17.4536401 },
    { lat: 48.246336, lng: 17.4530592 },
    { lat: 48.2463959, lng: 17.4528156 },
    { lat: 48.2465998, lng: 17.4521274 },
    { lat: 48.2467874, lng: 17.4514636 },
    { lat: 48.2469288, lng: 17.4509433 },
    { lat: 48.2471201, lng: 17.4502518 },
    { lat: 48.2473348, lng: 17.4497753 },
    { lat: 48.2474233, lng: 17.4495789 },
    { lat: 48.2475945, lng: 17.4491887 },
    { lat: 48.2476085, lng: 17.4491419 },
    { lat: 48.2472282, lng: 17.4482045 },
    { lat: 48.2470819, lng: 17.4478629 },
    { lat: 48.2468469, lng: 17.4470067 },
    { lat: 48.2465357, lng: 17.4460364 },
    { lat: 48.2464097, lng: 17.4453639 },
    { lat: 48.2462383, lng: 17.4446532 },
    { lat: 48.2460723, lng: 17.443886 },
    { lat: 48.2459607, lng: 17.4432605 },
    { lat: 48.2459319, lng: 17.4431132 },
    { lat: 48.2458216, lng: 17.4426135 },
    { lat: 48.2457056, lng: 17.4419388 },
    { lat: 48.245649, lng: 17.4416409 },
    { lat: 48.245595, lng: 17.4412945 },
    { lat: 48.2455525, lng: 17.4408999 },
    { lat: 48.2455449, lng: 17.4406431 },
    { lat: 48.2455622, lng: 17.440474 },
    { lat: 48.2455659, lng: 17.4400245 },
    { lat: 48.2455726, lng: 17.4398326 },
    { lat: 48.2456075, lng: 17.4394122 },
    { lat: 48.2456258, lng: 17.4392406 },
    { lat: 48.2456877, lng: 17.4383827 },
    { lat: 48.2457009, lng: 17.4382396 },
    { lat: 48.2457525, lng: 17.4375362 },
    { lat: 48.2457853, lng: 17.4372221 },
    { lat: 48.2458021, lng: 17.4369946 },
    { lat: 48.2458579, lng: 17.436514 },
    { lat: 48.2458943, lng: 17.4362736 },
    { lat: 48.24594, lng: 17.4358122 },
    { lat: 48.2460071, lng: 17.4351903 },
    { lat: 48.2461135, lng: 17.4345482 },
    { lat: 48.2461905, lng: 17.4341693 },
    { lat: 48.2464771, lng: 17.4329447 },
    { lat: 48.2465001, lng: 17.4328467 },
    { lat: 48.2463378, lng: 17.4327123 },
    { lat: 48.246176, lng: 17.4326215 },
    { lat: 48.246083, lng: 17.4325454 },
    { lat: 48.2459718, lng: 17.4324154 },
    { lat: 48.2459451, lng: 17.4322832 },
    { lat: 48.2459368, lng: 17.4321549 },
    { lat: 48.2459551, lng: 17.4317591 },
    { lat: 48.2459833, lng: 17.431531 },
    { lat: 48.2460701, lng: 17.4311632 },
    { lat: 48.246157, lng: 17.4309172 },
    { lat: 48.2464624, lng: 17.4301923 },
    { lat: 48.2465482, lng: 17.4299666 },
    { lat: 48.2466274, lng: 17.4297422 },
    { lat: 48.2467862, lng: 17.4292134 },
    { lat: 48.2471565, lng: 17.4281296 },
    { lat: 48.2472279, lng: 17.4279079 },
    { lat: 48.2474425, lng: 17.4272629 },
    { lat: 48.2475449, lng: 17.4270324 },
    { lat: 48.2476728, lng: 17.4265648 },
    { lat: 48.2479049, lng: 17.4260525 },
    { lat: 48.2480442, lng: 17.4257971 },
    { lat: 48.2484211, lng: 17.4251655 },
    { lat: 48.2486935, lng: 17.424682 },
    { lat: 48.2487315, lng: 17.4246216 },
    { lat: 48.248854, lng: 17.4243527 },
    { lat: 48.2487648, lng: 17.4242675 },
    { lat: 48.2484498, lng: 17.424041 },
    { lat: 48.2478634, lng: 17.4236038 },
    { lat: 48.2469317, lng: 17.4228051 },
    { lat: 48.2465949, lng: 17.4224881 },
    { lat: 48.2457996, lng: 17.4218597 },
    { lat: 48.2452782, lng: 17.4215003 },
    { lat: 48.2451369, lng: 17.4214117 },
    { lat: 48.2449515, lng: 17.4213228 },
    { lat: 48.2446596, lng: 17.4211945 },
    { lat: 48.2444636, lng: 17.4210971 },
    { lat: 48.2439259, lng: 17.4208186 },
    { lat: 48.2434367, lng: 17.4204679 },
    { lat: 48.2430647, lng: 17.4202347 },
    { lat: 48.2420647, lng: 17.4193953 },
    { lat: 48.2412651, lng: 17.4186654 },
    { lat: 48.2403137, lng: 17.417704 },
    { lat: 48.2394579, lng: 17.4169613 },
    { lat: 48.2394181, lng: 17.4169401 },
    { lat: 48.2390516, lng: 17.4166874 },
    { lat: 48.2389363, lng: 17.4166172 },
    { lat: 48.2387761, lng: 17.416533 },
    { lat: 48.2384377, lng: 17.4164014 },
    { lat: 48.2381685, lng: 17.4163079 },
    { lat: 48.2378177, lng: 17.4161764 },
    { lat: 48.2372948, lng: 17.4159516 },
    { lat: 48.2366074, lng: 17.4156101 },
    { lat: 48.235916, lng: 17.4152614 },
    { lat: 48.2354324, lng: 17.4151012 },
    { lat: 48.2352325, lng: 17.415049 },
    { lat: 48.2348394, lng: 17.4149964 },
    { lat: 48.23387, lng: 17.4147479 },
    { lat: 48.2333484, lng: 17.4146303 },
    { lat: 48.2328697, lng: 17.4145125 },
    { lat: 48.2325569, lng: 17.4144269 },
    { lat: 48.2322132, lng: 17.414423 },
    { lat: 48.231609, lng: 17.4144577 },
    { lat: 48.2312929, lng: 17.4144945 },
    { lat: 48.2304846, lng: 17.4146925 },
    { lat: 48.2299866, lng: 17.414744 },
    { lat: 48.2298338, lng: 17.4147568 },
    { lat: 48.2295853, lng: 17.4147406 },
    { lat: 48.2294425, lng: 17.4147159 },
    { lat: 48.2292237, lng: 17.4146682 },
    { lat: 48.2288718, lng: 17.4145023 },
    { lat: 48.2285687, lng: 17.4143385 },
    { lat: 48.2282874, lng: 17.4142332 },
    { lat: 48.2277272, lng: 17.4139236 },
    { lat: 48.2270999, lng: 17.4134967 },
    { lat: 48.2265715, lng: 17.4131182 },
    { lat: 48.2259724, lng: 17.4126249 },
    { lat: 48.2253984, lng: 17.4121363 },
    { lat: 48.224576, lng: 17.4114573 },
    { lat: 48.2245549, lng: 17.4115491 },
    { lat: 48.2244914, lng: 17.4115281 },
    { lat: 48.2244207, lng: 17.4117082 },
    { lat: 48.224316, lng: 17.4120961 },
    { lat: 48.2240557, lng: 17.4129556 },
    { lat: 48.2238428, lng: 17.4138609 },
    { lat: 48.2237389, lng: 17.4143371 },
    { lat: 48.2237064, lng: 17.4146004 },
    { lat: 48.2237022, lng: 17.4148672 },
    { lat: 48.2236504, lng: 17.4148705 },
    { lat: 48.2235566, lng: 17.4153042 },
    { lat: 48.2234967, lng: 17.4155986 },
    { lat: 48.223325, lng: 17.4163977 },
    { lat: 48.2232056, lng: 17.4169232 },
    { lat: 48.2231269, lng: 17.4172929 },
    { lat: 48.2229377, lng: 17.4181183 },
    { lat: 48.2228749, lng: 17.4184037 },
    { lat: 48.2227204, lng: 17.4190846 },
    { lat: 48.2226541, lng: 17.4194214 },
    { lat: 48.2225034, lng: 17.4202638 },
    { lat: 48.2224331, lng: 17.4207111 },
    { lat: 48.2223164, lng: 17.4215241 },
    { lat: 48.2221715, lng: 17.4225095 },
    { lat: 48.2221498, lng: 17.4226782 },
    { lat: 48.2220808, lng: 17.4231464 },
    { lat: 48.2220533, lng: 17.4233507 },
    { lat: 48.2219407, lng: 17.4240862 },
    { lat: 48.2219048, lng: 17.4243002 },
    { lat: 48.2217503, lng: 17.4252599 },
    { lat: 48.2217362, lng: 17.4254412 },
    { lat: 48.2217163, lng: 17.4256054 },
    { lat: 48.2216747, lng: 17.4258169 },
    { lat: 48.2219585, lng: 17.4259775 },
    { lat: 48.223152, lng: 17.4266862 },
    { lat: 48.2246376, lng: 17.4276129 },
    { lat: 48.2256469, lng: 17.4282682 },
    { lat: 48.225782, lng: 17.4283653 },
    { lat: 48.2258451, lng: 17.4283915 },
    { lat: 48.225919, lng: 17.428424 },
    { lat: 48.2264038, lng: 17.4287237 },
    { lat: 48.2268034, lng: 17.4289275 },
    { lat: 48.2278209, lng: 17.4296046 },
    { lat: 48.2286957, lng: 17.4302043 },
    { lat: 48.2307309, lng: 17.4315618 },
    { lat: 48.2309069, lng: 17.4316816 },
    { lat: 48.231624, lng: 17.4321504 },
    { lat: 48.2326557, lng: 17.4328018 },
    { lat: 48.2330434, lng: 17.4330338 },
    { lat: 48.2333809, lng: 17.4332037 },
    { lat: 48.2334381, lng: 17.4332494 },
    { lat: 48.233708, lng: 17.433616 },
    { lat: 48.2341978, lng: 17.4343935 },
    { lat: 48.2344703, lng: 17.4347292 },
    { lat: 48.2346345, lng: 17.4348921 },
    { lat: 48.234985, lng: 17.4351759 },
    { lat: 48.2351591, lng: 17.4352842 },
    { lat: 48.2354753, lng: 17.4354556 },
    { lat: 48.2355644, lng: 17.4355263 },
    { lat: 48.2361688, lng: 17.4359626 },
    { lat: 48.2367578, lng: 17.4363538 },
    { lat: 48.2368102, lng: 17.4363977 },
    { lat: 48.2368407, lng: 17.4365354 },
    { lat: 48.2368416, lng: 17.4369326 },
    { lat: 48.2369013, lng: 17.4375749 },
    { lat: 48.2369226, lng: 17.4379909 },
    { lat: 48.2370537, lng: 17.4389702 },
    { lat: 48.2371971, lng: 17.4395269 },
    { lat: 48.2373775, lng: 17.4400256 },
    { lat: 48.2377023, lng: 17.440681 },
    { lat: 48.2377537, lng: 17.4408129 },
    { lat: 48.238119, lng: 17.4419544 },
    { lat: 48.2381591, lng: 17.4424871 },
    { lat: 48.2382771, lng: 17.443454 },
    { lat: 48.2383038, lng: 17.4438846 },
    { lat: 48.2382993, lng: 17.4440577 },
    { lat: 48.2382477, lng: 17.4447424 },
    { lat: 48.238229, lng: 17.4451702 },
    { lat: 48.2381935, lng: 17.4454071 },
    { lat: 48.2382348, lng: 17.4457485 },
    { lat: 48.2382801, lng: 17.446431 },
    { lat: 48.238327, lng: 17.4471891 },
    { lat: 48.238381, lng: 17.4479185 },
    { lat: 48.2383952, lng: 17.4481773 },
    { lat: 48.2383999, lng: 17.4482641 },
    { lat: 48.2383875, lng: 17.448265 },
    { lat: 48.2383707, lng: 17.4484238 },
    { lat: 48.238239, lng: 17.4494422 },
    { lat: 48.2382294, lng: 17.4495052 },
    { lat: 48.2389173, lng: 17.4497672 },
    { lat: 48.2396057, lng: 17.4501071 },
    { lat: 48.2407517, lng: 17.4508177 },
    { lat: 48.2414703, lng: 17.451207 },
    { lat: 48.2427173, lng: 17.4518539 },
    { lat: 48.2428811, lng: 17.4519427 },
    { lat: 48.2440287, lng: 17.452572 },
    { lat: 48.2451566, lng: 17.4533857 },
    { lat: 48.2456769, lng: 17.4537137 },
    { lat: 48.2458726, lng: 17.4538409 },
    { lat: 48.2460176, lng: 17.4538719 },
    { lat: 48.2460822, lng: 17.4538857 },
  ],
  KostolnápriDunaji: [
    { lat: 48.1889923, lng: 17.4216747 },
    { lat: 48.1862491, lng: 17.4248789 },
    { lat: 48.1859866, lng: 17.4242448 },
    { lat: 48.1839065, lng: 17.4246163 },
    { lat: 48.1833295, lng: 17.4247221 },
    { lat: 48.1831618, lng: 17.4247491 },
    { lat: 48.1830472, lng: 17.4247589 },
    { lat: 48.1827472, lng: 17.4247655 },
    { lat: 48.1823776, lng: 17.4247608 },
    { lat: 48.1820619, lng: 17.4246464 },
    { lat: 48.1815293, lng: 17.4244128 },
    { lat: 48.181325, lng: 17.4243286 },
    { lat: 48.1808721, lng: 17.4240145 },
    { lat: 48.1806105, lng: 17.4239359 },
    { lat: 48.1800121, lng: 17.4239723 },
    { lat: 48.1796613, lng: 17.4240132 },
    { lat: 48.1797249, lng: 17.4237758 },
    { lat: 48.179185, lng: 17.4239682 },
    { lat: 48.1779308, lng: 17.4242738 },
    { lat: 48.1775836, lng: 17.4243708 },
    { lat: 48.1772527, lng: 17.4244737 },
    { lat: 48.1769467, lng: 17.4246322 },
    { lat: 48.1765096, lng: 17.4247709 },
    { lat: 48.1762914, lng: 17.424854 },
    { lat: 48.1756326, lng: 17.4250924 },
    { lat: 48.1752841, lng: 17.4252216 },
    { lat: 48.175022, lng: 17.4253619 },
    { lat: 48.1747936, lng: 17.4254963 },
    { lat: 48.1742586, lng: 17.4257793 },
    { lat: 48.1741907, lng: 17.4258039 },
    { lat: 48.1740558, lng: 17.4258745 },
    { lat: 48.173737, lng: 17.4262172 },
    { lat: 48.1733338, lng: 17.4263745 },
    { lat: 48.172957, lng: 17.4266244 },
    { lat: 48.1729281, lng: 17.4266496 },
    { lat: 48.1725824, lng: 17.4268755 },
    { lat: 48.1724684, lng: 17.4269444 },
    { lat: 48.1721307, lng: 17.4269454 },
    { lat: 48.1718051, lng: 17.4269394 },
    { lat: 48.1713637, lng: 17.4269435 },
    { lat: 48.1711229, lng: 17.4269566 },
    { lat: 48.1705298, lng: 17.4267817 },
    { lat: 48.170485, lng: 17.426752 },
    { lat: 48.1696693, lng: 17.4262433 },
    { lat: 48.1691353, lng: 17.4259181 },
    { lat: 48.169082, lng: 17.4258834 },
    { lat: 48.1681977, lng: 17.4253081 },
    { lat: 48.1676866, lng: 17.4249862 },
    { lat: 48.1671416, lng: 17.424629 },
    { lat: 48.1666071, lng: 17.4242955 },
    { lat: 48.1663902, lng: 17.4241549 },
    { lat: 48.1652413, lng: 17.4237865 },
    { lat: 48.1648943, lng: 17.4236098 },
    { lat: 48.1642894, lng: 17.4232174 },
    { lat: 48.1636224, lng: 17.4227927 },
    { lat: 48.1632277, lng: 17.4231826 },
    { lat: 48.1627218, lng: 17.4236705 },
    { lat: 48.1624793, lng: 17.4239016 },
    { lat: 48.1620635, lng: 17.4241266 },
    { lat: 48.1619287, lng: 17.4242053 },
    { lat: 48.1618191, lng: 17.4242584 },
    { lat: 48.1616132, lng: 17.4242874 },
    { lat: 48.1614502, lng: 17.4242992 },
    { lat: 48.1617094, lng: 17.4258367 },
    { lat: 48.1618141, lng: 17.426978 },
    { lat: 48.1619896, lng: 17.428964 },
    { lat: 48.1619918, lng: 17.4289983 },
    { lat: 48.162218, lng: 17.4315602 },
    { lat: 48.1625133, lng: 17.4349428 },
    { lat: 48.1625612, lng: 17.4354879 },
    { lat: 48.1626591, lng: 17.4366495 },
    { lat: 48.1627922, lng: 17.438212 },
    { lat: 48.1630164, lng: 17.440744 },
    { lat: 48.1630626, lng: 17.4412835 },
    { lat: 48.1631663, lng: 17.4421859 },
    { lat: 48.1631779, lng: 17.4423295 },
    { lat: 48.1666429, lng: 17.4420131 },
    { lat: 48.1676747, lng: 17.4419193 },
    { lat: 48.1678134, lng: 17.4450505 },
    { lat: 48.1678175, lng: 17.445168 },
    { lat: 48.1679301, lng: 17.4477231 },
    { lat: 48.1679588, lng: 17.4484449 },
    { lat: 48.1681655, lng: 17.4484631 },
    { lat: 48.1681178, lng: 17.4486068 },
    { lat: 48.16591, lng: 17.4552812 },
    { lat: 48.1654405, lng: 17.4566997 },
    { lat: 48.1639558, lng: 17.4611755 },
    { lat: 48.1631563, lng: 17.4635862 },
    { lat: 48.1635064, lng: 17.4638657 },
    { lat: 48.1641003, lng: 17.4643344 },
    { lat: 48.1640585, lng: 17.4644568 },
    { lat: 48.1632899, lng: 17.4667718 },
    { lat: 48.1631043, lng: 17.467289 },
    { lat: 48.162414, lng: 17.4692778 },
    { lat: 48.1618812, lng: 17.4688635 },
    { lat: 48.1611157, lng: 17.468271 },
    { lat: 48.1593392, lng: 17.4668934 },
    { lat: 48.1582918, lng: 17.4660479 },
    { lat: 48.1577572, lng: 17.4656212 },
    { lat: 48.1562115, lng: 17.4644063 },
    { lat: 48.1549953, lng: 17.4634468 },
    { lat: 48.154798, lng: 17.464045 },
    { lat: 48.154116, lng: 17.465628 },
    { lat: 48.153546, lng: 17.466439 },
    { lat: 48.152834, lng: 17.46709 },
    { lat: 48.152472, lng: 17.467355 },
    { lat: 48.152296, lng: 17.467401 },
    { lat: 48.152746, lng: 17.467769 },
    { lat: 48.154018, lng: 17.46878 },
    { lat: 48.154112, lng: 17.468854 },
    { lat: 48.154584, lng: 17.469234 },
    { lat: 48.154793, lng: 17.469401 },
    { lat: 48.154977, lng: 17.46955 },
    { lat: 48.155092, lng: 17.469642 },
    { lat: 48.155148, lng: 17.469685 },
    { lat: 48.155275, lng: 17.469788 },
    { lat: 48.155411, lng: 17.469896 },
    { lat: 48.155541, lng: 17.47 },
    { lat: 48.155668, lng: 17.470102 },
    { lat: 48.155932, lng: 17.470313 },
    { lat: 48.156197, lng: 17.470527 },
    { lat: 48.156328, lng: 17.470631 },
    { lat: 48.156458, lng: 17.470735 },
    { lat: 48.156726, lng: 17.47095 },
    { lat: 48.157269, lng: 17.471385 },
    { lat: 48.15741, lng: 17.471498 },
    { lat: 48.157685, lng: 17.471719 },
    { lat: 48.157825, lng: 17.471831 },
    { lat: 48.15797, lng: 17.471947 },
    { lat: 48.158004, lng: 17.471975 },
    { lat: 48.158128, lng: 17.472073 },
    { lat: 48.158183, lng: 17.472119 },
    { lat: 48.158242, lng: 17.472166 },
    { lat: 48.158466, lng: 17.472344 },
    { lat: 48.158585, lng: 17.47244 },
    { lat: 48.158708, lng: 17.472539 },
    { lat: 48.158825, lng: 17.472632 },
    { lat: 48.158954, lng: 17.472736 },
    { lat: 48.159159, lng: 17.472901 },
    { lat: 48.159207, lng: 17.472939 },
    { lat: 48.159457, lng: 17.473139 },
    { lat: 48.159581, lng: 17.473238 },
    { lat: 48.159708, lng: 17.473341 },
    { lat: 48.159764, lng: 17.473386 },
    { lat: 48.159832, lng: 17.47344 },
    { lat: 48.159949, lng: 17.473533 },
    { lat: 48.160012, lng: 17.473584 },
    { lat: 48.160074, lng: 17.473633 },
    { lat: 48.160218, lng: 17.47375 },
    { lat: 48.160342, lng: 17.473849 },
    { lat: 48.160483, lng: 17.473961 },
    { lat: 48.160617, lng: 17.474069 },
    { lat: 48.160755, lng: 17.47418 },
    { lat: 48.160865, lng: 17.474267 },
    { lat: 48.160987, lng: 17.474366 },
    { lat: 48.161063, lng: 17.474427 },
    { lat: 48.161589, lng: 17.474847 },
    { lat: 48.16257, lng: 17.475631 },
    { lat: 48.160872, lng: 17.480379 },
    { lat: 48.163897, lng: 17.482791 },
    { lat: 48.163987, lng: 17.482593 },
    { lat: 48.164353, lng: 17.481865 },
    { lat: 48.164965, lng: 17.480664 },
    { lat: 48.1652677, lng: 17.4801575 },
    { lat: 48.165552, lng: 17.4796819 },
    { lat: 48.1662413, lng: 17.4785795 },
    { lat: 48.1663632, lng: 17.4784209 },
    { lat: 48.1678987, lng: 17.4736707 },
    { lat: 48.1679164, lng: 17.473614 },
    { lat: 48.1681858, lng: 17.4728192 },
    { lat: 48.1689674, lng: 17.4703541 },
    { lat: 48.1690853, lng: 17.469975 },
    { lat: 48.1693891, lng: 17.4689984 },
    { lat: 48.1701822, lng: 17.4665555 },
    { lat: 48.171613, lng: 17.4621504 },
    { lat: 48.1719097, lng: 17.4612356 },
    { lat: 48.1737644, lng: 17.4557245 },
    { lat: 48.1766575, lng: 17.4563427 },
    { lat: 48.1782019, lng: 17.456631 },
    { lat: 48.1792356, lng: 17.4568275 },
    { lat: 48.1807691, lng: 17.4571216 },
    { lat: 48.1821571, lng: 17.4574858 },
    { lat: 48.1821612, lng: 17.4574801 },
    { lat: 48.1824298, lng: 17.4551714 },
    { lat: 48.1825611, lng: 17.4540953 },
    { lat: 48.1827926, lng: 17.4532552 },
    { lat: 48.1828841, lng: 17.4528907 },
    { lat: 48.1831502, lng: 17.4518719 },
    { lat: 48.183608, lng: 17.4508312 },
    { lat: 48.1842789, lng: 17.4496995 },
    { lat: 48.1845169, lng: 17.4492926 },
    { lat: 48.1848156, lng: 17.4488474 },
    { lat: 48.1852798, lng: 17.4486215 },
    { lat: 48.1854225, lng: 17.4485552 },
    { lat: 48.1855286, lng: 17.4483996 },
    { lat: 48.1856552, lng: 17.4482969 },
    { lat: 48.1857585, lng: 17.4481821 },
    { lat: 48.1859696, lng: 17.4479878 },
    { lat: 48.1861425, lng: 17.4478504 },
    { lat: 48.1862426, lng: 17.4478444 },
    { lat: 48.186294, lng: 17.4478191 },
    { lat: 48.1864441, lng: 17.4478345 },
    { lat: 48.186579, lng: 17.4478597 },
    { lat: 48.186669, lng: 17.4477657 },
    { lat: 48.1867657, lng: 17.4475853 },
    { lat: 48.1867856, lng: 17.4474734 },
    { lat: 48.1868415, lng: 17.4472721 },
    { lat: 48.1868555, lng: 17.4472454 },
    { lat: 48.1868629, lng: 17.4472368 },
    { lat: 48.1869123, lng: 17.4471976 },
    { lat: 48.1869672, lng: 17.4471043 },
    { lat: 48.1870578, lng: 17.4470565 },
    { lat: 48.1871911, lng: 17.446951 },
    { lat: 48.1872998, lng: 17.4468864 },
    { lat: 48.1873665, lng: 17.4468691 },
    { lat: 48.1875681, lng: 17.4466516 },
    { lat: 48.1879169, lng: 17.4462304 },
    { lat: 48.1880042, lng: 17.4461129 },
    { lat: 48.1884555, lng: 17.4455821 },
    { lat: 48.1886163, lng: 17.4450656 },
    { lat: 48.1887812, lng: 17.4445603 },
    { lat: 48.1889428, lng: 17.4440564 },
    { lat: 48.1892756, lng: 17.4430049 },
    { lat: 48.1895987, lng: 17.4419952 },
    { lat: 48.1899305, lng: 17.4409462 },
    { lat: 48.190592, lng: 17.4388876 },
    { lat: 48.1907389, lng: 17.4384261 },
    { lat: 48.1909354, lng: 17.4378106 },
    { lat: 48.1912237, lng: 17.4369067 },
    { lat: 48.1912946, lng: 17.4366936 },
    { lat: 48.1913705, lng: 17.4363304 },
    { lat: 48.191456, lng: 17.4358214 },
    { lat: 48.191623, lng: 17.4347575 },
    { lat: 48.1916722, lng: 17.4344531 },
    { lat: 48.1918714, lng: 17.43298 },
    { lat: 48.191035, lng: 17.432674 },
    { lat: 48.1908513, lng: 17.4325428 },
    { lat: 48.1905769, lng: 17.4323325 },
    { lat: 48.1903867, lng: 17.4321195 },
    { lat: 48.190328, lng: 17.432014 },
    { lat: 48.1901458, lng: 17.4315734 },
    { lat: 48.190053, lng: 17.4313283 },
    { lat: 48.1900278, lng: 17.4312171 },
    { lat: 48.1900225, lng: 17.4310161 },
    { lat: 48.1900327, lng: 17.4309285 },
    { lat: 48.1900737, lng: 17.4307427 },
    { lat: 48.1901744, lng: 17.4303969 },
    { lat: 48.1902715, lng: 17.4301007 },
    { lat: 48.190368, lng: 17.4298816 },
    { lat: 48.1906743, lng: 17.4292948 },
    { lat: 48.1908072, lng: 17.4290086 },
    { lat: 48.1909399, lng: 17.428671 },
    { lat: 48.1912265, lng: 17.4279697 },
    { lat: 48.1913215, lng: 17.4277117 },
    { lat: 48.1913988, lng: 17.4274396 },
    { lat: 48.1914713, lng: 17.4270784 },
    { lat: 48.1915051, lng: 17.4268523 },
    { lat: 48.1915418, lng: 17.4263217 },
    { lat: 48.1915693, lng: 17.4260846 },
    { lat: 48.1917743, lng: 17.4246155 },
    { lat: 48.1911794, lng: 17.424273 },
    { lat: 48.190739, lng: 17.4240668 },
    { lat: 48.1905694, lng: 17.4239925 },
    { lat: 48.1903566, lng: 17.4238832 },
    { lat: 48.1901272, lng: 17.4236886 },
    { lat: 48.1900781, lng: 17.4236805 },
    { lat: 48.1900367, lng: 17.4236004 },
    { lat: 48.1900031, lng: 17.4235343 },
    { lat: 48.1897585, lng: 17.423107 },
    { lat: 48.1893208, lng: 17.4223346 },
    { lat: 48.1892564, lng: 17.4222121 },
    { lat: 48.1889923, lng: 17.4216747 },
  ],
};

export default UnitsSenec;
