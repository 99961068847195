const UnitsStaráĽubovňa = {
  Ľubotín: [
    { lat: 49.2833049, lng: 20.8990058 },
    { lat: 49.2832113, lng: 20.8975268 },
    { lat: 49.28325, lng: 20.8965292 },
    { lat: 49.283223, lng: 20.8964643 },
    { lat: 49.2832827, lng: 20.8957977 },
    { lat: 49.2834527, lng: 20.8952995 },
    { lat: 49.2837351, lng: 20.8910475 },
    { lat: 49.2837349, lng: 20.889838 },
    { lat: 49.2836248, lng: 20.8898117 },
    { lat: 49.2837168, lng: 20.8886631 },
    { lat: 49.28388, lng: 20.8871931 },
    { lat: 49.2839843, lng: 20.8872527 },
    { lat: 49.2840055, lng: 20.8867121 },
    { lat: 49.2843508, lng: 20.8869091 },
    { lat: 49.2844036, lng: 20.8867697 },
    { lat: 49.2840343, lng: 20.8866002 },
    { lat: 49.2839165, lng: 20.885807 },
    { lat: 49.284205, lng: 20.8817484 },
    { lat: 49.2842905, lng: 20.8811497 },
    { lat: 49.2842195, lng: 20.8805132 },
    { lat: 49.2842497, lng: 20.8801727 },
    { lat: 49.2841476, lng: 20.878685 },
    { lat: 49.2838869, lng: 20.8774322 },
    { lat: 49.2835684, lng: 20.8762266 },
    { lat: 49.2837503, lng: 20.8759855 },
    { lat: 49.2835248, lng: 20.8748764 },
    { lat: 49.282875, lng: 20.8748606 },
    { lat: 49.2816432, lng: 20.8746429 },
    { lat: 49.2815574, lng: 20.8747354 },
    { lat: 49.2811068, lng: 20.8739286 },
    { lat: 49.2805301, lng: 20.87318 },
    { lat: 49.2802799, lng: 20.8727686 },
    { lat: 49.2797424, lng: 20.8720516 },
    { lat: 49.2793524, lng: 20.8717036 },
    { lat: 49.2791428, lng: 20.8712605 },
    { lat: 49.2788975, lng: 20.8710625 },
    { lat: 49.2781794, lng: 20.8702008 },
    { lat: 49.2774267, lng: 20.8690416 },
    { lat: 49.2765547, lng: 20.8680829 },
    { lat: 49.2758983, lng: 20.8676771 },
    { lat: 49.2751869, lng: 20.8676062 },
    { lat: 49.2747575, lng: 20.867488 },
    { lat: 49.27399, lng: 20.8676608 },
    { lat: 49.2733183, lng: 20.8679652 },
    { lat: 49.2726414, lng: 20.8683824 },
    { lat: 49.2721445, lng: 20.8689602 },
    { lat: 49.2709499, lng: 20.8701774 },
    { lat: 49.2708923, lng: 20.8700731 },
    { lat: 49.2709512, lng: 20.8696179 },
    { lat: 49.2707733, lng: 20.8691202 },
    { lat: 49.2707938, lng: 20.8690916 },
    { lat: 49.2705802, lng: 20.8685311 },
    { lat: 49.2701993, lng: 20.8682873 },
    { lat: 49.2697972, lng: 20.8675763 },
    { lat: 49.2693518, lng: 20.8672591 },
    { lat: 49.2695131, lng: 20.8666318 },
    { lat: 49.2695985, lng: 20.8666319 },
    { lat: 49.2698414, lng: 20.8664595 },
    { lat: 49.2696905, lng: 20.8661262 },
    { lat: 49.2693291, lng: 20.86496 },
    { lat: 49.2693635, lng: 20.8649104 },
    { lat: 49.2693424, lng: 20.8646494 },
    { lat: 49.2691282, lng: 20.8641131 },
    { lat: 49.2681457, lng: 20.8644118 },
    { lat: 49.2680754, lng: 20.8644599 },
    { lat: 49.2674478, lng: 20.8661015 },
    { lat: 49.2670995, lng: 20.8671803 },
    { lat: 49.2663109, lng: 20.8686248 },
    { lat: 49.2647682, lng: 20.8705238 },
    { lat: 49.2631441, lng: 20.8724158 },
    { lat: 49.2629848, lng: 20.8718676 },
    { lat: 49.2622737, lng: 20.8724049 },
    { lat: 49.2614335, lng: 20.8729235 },
    { lat: 49.260472, lng: 20.8733079 },
    { lat: 49.2607743, lng: 20.873564 },
    { lat: 49.2609663, lng: 20.873947 },
    { lat: 49.2594082, lng: 20.8747299 },
    { lat: 49.2593597, lng: 20.8747614 },
    { lat: 49.2593124, lng: 20.8747926 },
    { lat: 49.2582235, lng: 20.8753776 },
    { lat: 49.257429, lng: 20.8754774 },
    { lat: 49.2569151, lng: 20.8754265 },
    { lat: 49.2563472, lng: 20.8755055 },
    { lat: 49.2548966, lng: 20.8751426 },
    { lat: 49.2540635, lng: 20.8750351 },
    { lat: 49.2537405, lng: 20.8750671 },
    { lat: 49.2535287, lng: 20.8750319 },
    { lat: 49.2535364, lng: 20.8749589 },
    { lat: 49.253006, lng: 20.8749518 },
    { lat: 49.2528414, lng: 20.8748423 },
    { lat: 49.2526079, lng: 20.8749601 },
    { lat: 49.2521197, lng: 20.8749994 },
    { lat: 49.2517844, lng: 20.8751347 },
    { lat: 49.2510549, lng: 20.8752319 },
    { lat: 49.2503252, lng: 20.8752805 },
    { lat: 49.2499733, lng: 20.8752308 },
    { lat: 49.2498401, lng: 20.8752824 },
    { lat: 49.2495886, lng: 20.8752028 },
    { lat: 49.2488825, lng: 20.8756552 },
    { lat: 49.2478455, lng: 20.8760389 },
    { lat: 49.247511, lng: 20.8760635 },
    { lat: 49.2472817, lng: 20.8764721 },
    { lat: 49.2471002, lng: 20.8769645 },
    { lat: 49.2472792, lng: 20.8767684 },
    { lat: 49.2473599, lng: 20.8774887 },
    { lat: 49.2469284, lng: 20.8776452 },
    { lat: 49.246816, lng: 20.8786462 },
    { lat: 49.2465782, lng: 20.8786718 },
    { lat: 49.2467791, lng: 20.8794855 },
    { lat: 49.2461987, lng: 20.8798289 },
    { lat: 49.2457943, lng: 20.8807163 },
    { lat: 49.2454876, lng: 20.8801011 },
    { lat: 49.2445713, lng: 20.8795958 },
    { lat: 49.2433433, lng: 20.8781603 },
    { lat: 49.2419441, lng: 20.8779501 },
    { lat: 49.2408977, lng: 20.8764675 },
    { lat: 49.2402748, lng: 20.8758518 },
    { lat: 49.2397789, lng: 20.8758952 },
    { lat: 49.2397837, lng: 20.8750052 },
    { lat: 49.2395083, lng: 20.8746184 },
    { lat: 49.2379299, lng: 20.8714441 },
    { lat: 49.2355238, lng: 20.8723487 },
    { lat: 49.2346722, lng: 20.8724963 },
    { lat: 49.2338968, lng: 20.8728943 },
    { lat: 49.2330739, lng: 20.8729891 },
    { lat: 49.2313522, lng: 20.8734989 },
    { lat: 49.2309573, lng: 20.8734325 },
    { lat: 49.228853, lng: 20.8747383 },
    { lat: 49.2288538, lng: 20.8745942 },
    { lat: 49.227801, lng: 20.875187 },
    { lat: 49.2275512, lng: 20.8754515 },
    { lat: 49.226271, lng: 20.876807 },
    { lat: 49.22534, lng: 20.881178 },
    { lat: 49.225031, lng: 20.882954 },
    { lat: 49.224801, lng: 20.884739 },
    { lat: 49.225781, lng: 20.88462 },
    { lat: 49.22638, lng: 20.884719 },
    { lat: 49.22652, lng: 20.885497 },
    { lat: 49.226655, lng: 20.886271 },
    { lat: 49.2280809, lng: 20.885296 },
    { lat: 49.2297419, lng: 20.8846521 },
    { lat: 49.2313093, lng: 20.8845402 },
    { lat: 49.2330867, lng: 20.8837838 },
    { lat: 49.2349619, lng: 20.8830916 },
    { lat: 49.2366856, lng: 20.8834744 },
    { lat: 49.2381063, lng: 20.8851141 },
    { lat: 49.2397675, lng: 20.8882528 },
    { lat: 49.2406364, lng: 20.8910253 },
    { lat: 49.240801, lng: 20.8911009 },
    { lat: 49.2412042, lng: 20.8918112 },
    { lat: 49.242996, lng: 20.8887088 },
    { lat: 49.2431332, lng: 20.8885741 },
    { lat: 49.2465597, lng: 20.8827868 },
    { lat: 49.2474112, lng: 20.8843419 },
    { lat: 49.2474645, lng: 20.8858503 },
    { lat: 49.2467466, lng: 20.8880892 },
    { lat: 49.2471099, lng: 20.8895178 },
    { lat: 49.2467836, lng: 20.8906267 },
    { lat: 49.2468359, lng: 20.8915069 },
    { lat: 49.2464457, lng: 20.8926234 },
    { lat: 49.2470238, lng: 20.893311 },
    { lat: 49.2479098, lng: 20.894726 },
    { lat: 49.2483835, lng: 20.8945533 },
    { lat: 49.2493203, lng: 20.8950313 },
    { lat: 49.2497696, lng: 20.895509 },
    { lat: 49.2508675, lng: 20.8953911 },
    { lat: 49.2517726, lng: 20.8958687 },
    { lat: 49.2527898, lng: 20.8956292 },
    { lat: 49.2528626, lng: 20.896419 },
    { lat: 49.2544049, lng: 20.8965032 },
    { lat: 49.2543774, lng: 20.8978914 },
    { lat: 49.255538, lng: 20.8983995 },
    { lat: 49.2550802, lng: 20.9007824 },
    { lat: 49.2556375, lng: 20.9011559 },
    { lat: 49.2561902, lng: 20.9001214 },
    { lat: 49.2567912, lng: 20.9012251 },
    { lat: 49.2565211, lng: 20.9025008 },
    { lat: 49.2571272, lng: 20.9023892 },
    { lat: 49.2570715, lng: 20.9036784 },
    { lat: 49.2575555, lng: 20.9041168 },
    { lat: 49.2598265, lng: 20.9065951 },
    { lat: 49.2597933, lng: 20.9073775 },
    { lat: 49.2604118, lng: 20.9079701 },
    { lat: 49.261726, lng: 20.9097399 },
    { lat: 49.2628588, lng: 20.9096224 },
    { lat: 49.2639158, lng: 20.909024 },
    { lat: 49.2653893, lng: 20.9089324 },
    { lat: 49.2658928, lng: 20.9087784 },
    { lat: 49.2662934, lng: 20.9082068 },
    { lat: 49.2668006, lng: 20.9079931 },
    { lat: 49.2671583, lng: 20.9073881 },
    { lat: 49.2679052, lng: 20.9071058 },
    { lat: 49.2682111, lng: 20.907803 },
    { lat: 49.2689613, lng: 20.9083587 },
    { lat: 49.2708523, lng: 20.9071943 },
    { lat: 49.2712424, lng: 20.90671 },
    { lat: 49.2712018, lng: 20.9066043 },
    { lat: 49.271661, lng: 20.9061038 },
    { lat: 49.2727563, lng: 20.9052365 },
    { lat: 49.2735359, lng: 20.9042225 },
    { lat: 49.2744563, lng: 20.9034817 },
    { lat: 49.2747609, lng: 20.9031003 },
    { lat: 49.2751756, lng: 20.9024143 },
    { lat: 49.2754006, lng: 20.9019385 },
    { lat: 49.275708, lng: 20.9011405 },
    { lat: 49.2758646, lng: 20.9005425 },
    { lat: 49.2765731, lng: 20.8992888 },
    { lat: 49.2768234, lng: 20.8986679 },
    { lat: 49.2769644, lng: 20.8984691 },
    { lat: 49.2775523, lng: 20.89795 },
    { lat: 49.2778931, lng: 20.8980133 },
    { lat: 49.2783881, lng: 20.8979063 },
    { lat: 49.2792039, lng: 20.8979998 },
    { lat: 49.2796817, lng: 20.8981879 },
    { lat: 49.2798642, lng: 20.8981884 },
    { lat: 49.2801891, lng: 20.8979826 },
    { lat: 49.2802979, lng: 20.8980044 },
    { lat: 49.2805166, lng: 20.8982125 },
    { lat: 49.2812525, lng: 20.8983126 },
    { lat: 49.2814775, lng: 20.8983065 },
    { lat: 49.2818857, lng: 20.8981656 },
    { lat: 49.28312, lng: 20.898591 },
    { lat: 49.2833049, lng: 20.8990058 },
  ],
  VeľkýLipník: [
    { lat: 49.3347688, lng: 20.5163891 },
    { lat: 49.3363069, lng: 20.5170051 },
    { lat: 49.3371389, lng: 20.5163644 },
    { lat: 49.3372445, lng: 20.5161791 },
    { lat: 49.3377995, lng: 20.5156887 },
    { lat: 49.3381317, lng: 20.5155127 },
    { lat: 49.3385507, lng: 20.5154885 },
    { lat: 49.3397495, lng: 20.5144753 },
    { lat: 49.3402082, lng: 20.5142155 },
    { lat: 49.3404949, lng: 20.5141752 },
    { lat: 49.3405795, lng: 20.5142986 },
    { lat: 49.3406748, lng: 20.5143196 },
    { lat: 49.3408843, lng: 20.5141412 },
    { lat: 49.3411143, lng: 20.514308 },
    { lat: 49.3412725, lng: 20.5142907 },
    { lat: 49.3413908, lng: 20.5144173 },
    { lat: 49.3414933, lng: 20.5144139 },
    { lat: 49.3419496, lng: 20.5146709 },
    { lat: 49.3420606, lng: 20.5146559 },
    { lat: 49.3423533, lng: 20.514904 },
    { lat: 49.3425855, lng: 20.5152467 },
    { lat: 49.3428004, lng: 20.5152769 },
    { lat: 49.3433989, lng: 20.5161806 },
    { lat: 49.3436232, lng: 20.5160645 },
    { lat: 49.3440479, lng: 20.5167456 },
    { lat: 49.3442781, lng: 20.5168224 },
    { lat: 49.3443737, lng: 20.5169456 },
    { lat: 49.3444976, lng: 20.5169718 },
    { lat: 49.3446025, lng: 20.5171613 },
    { lat: 49.3453286, lng: 20.5175012 },
    { lat: 49.3455951, lng: 20.5177378 },
    { lat: 49.3456338, lng: 20.5178417 },
    { lat: 49.3461551, lng: 20.5184549 },
    { lat: 49.3464389, lng: 20.5190699 },
    { lat: 49.3470062, lng: 20.5196467 },
    { lat: 49.3471525, lng: 20.5198922 },
    { lat: 49.3474697, lng: 20.520101 },
    { lat: 49.3475105, lng: 20.5203816 },
    { lat: 49.3480872, lng: 20.5209389 },
    { lat: 49.3482489, lng: 20.5209289 },
    { lat: 49.3484491, lng: 20.5212283 },
    { lat: 49.3484977, lng: 20.5215101 },
    { lat: 49.3486243, lng: 20.5215526 },
    { lat: 49.3488049, lng: 20.5213251 },
    { lat: 49.3493344, lng: 20.5213932 },
    { lat: 49.3496661, lng: 20.5214791 },
    { lat: 49.3499714, lng: 20.5218203 },
    { lat: 49.3500514, lng: 20.5217954 },
    { lat: 49.3501176, lng: 20.5214781 },
    { lat: 49.3502761, lng: 20.5214445 },
    { lat: 49.3507587, lng: 20.5216506 },
    { lat: 49.3509967, lng: 20.5215969 },
    { lat: 49.3511896, lng: 20.5217633 },
    { lat: 49.3514237, lng: 20.521767 },
    { lat: 49.3516316, lng: 20.5219098 },
    { lat: 49.3528926, lng: 20.5216379 },
    { lat: 49.3530882, lng: 20.5216589 },
    { lat: 49.3534744, lng: 20.5218455 },
    { lat: 49.3535241, lng: 20.5219384 },
    { lat: 49.353797, lng: 20.5220799 },
    { lat: 49.3538808, lng: 20.5222142 },
    { lat: 49.3540028, lng: 20.5222864 },
    { lat: 49.3546808, lng: 20.5224176 },
    { lat: 49.3549565, lng: 20.5223826 },
    { lat: 49.3551383, lng: 20.5227977 },
    { lat: 49.3552142, lng: 20.5227193 },
    { lat: 49.3558818, lng: 20.5224766 },
    { lat: 49.3560065, lng: 20.5225008 },
    { lat: 49.3565343, lng: 20.5223109 },
    { lat: 49.3567951, lng: 20.5225116 },
    { lat: 49.3570396, lng: 20.5228571 },
    { lat: 49.3573414, lng: 20.5227689 },
    { lat: 49.3578606, lng: 20.5228961 },
    { lat: 49.3579235, lng: 20.5227979 },
    { lat: 49.358012, lng: 20.5228181 },
    { lat: 49.3584168, lng: 20.5232028 },
    { lat: 49.3588406, lng: 20.523431 },
    { lat: 49.3589665, lng: 20.5235852 },
    { lat: 49.3590688, lng: 20.5230553 },
    { lat: 49.3595688, lng: 20.5232151 },
    { lat: 49.3611941, lng: 20.5241325 },
    { lat: 49.3622683, lng: 20.5248118 },
    { lat: 49.3626579, lng: 20.5251802 },
    { lat: 49.3633255, lng: 20.5255803 },
    { lat: 49.3636551, lng: 20.5256061 },
    { lat: 49.3639979, lng: 20.5259284 },
    { lat: 49.3643661, lng: 20.5260242 },
    { lat: 49.3651135, lng: 20.5260201 },
    { lat: 49.3654954, lng: 20.5263029 },
    { lat: 49.3657324, lng: 20.5265963 },
    { lat: 49.3661984, lng: 20.5275762 },
    { lat: 49.3663696, lng: 20.5278255 },
    { lat: 49.3666159, lng: 20.5280947 },
    { lat: 49.3669374, lng: 20.5282939 },
    { lat: 49.3675205, lng: 20.5288447 },
    { lat: 49.3681019, lng: 20.5296269 },
    { lat: 49.3684243, lng: 20.5302551 },
    { lat: 49.3694963, lng: 20.5312733 },
    { lat: 49.3699711, lng: 20.5322818 },
    { lat: 49.3714384, lng: 20.5337948 },
    { lat: 49.3720894, lng: 20.534272 },
    { lat: 49.3727069, lng: 20.5345003 },
    { lat: 49.3728888, lng: 20.5344576 },
    { lat: 49.3738617, lng: 20.5345228 },
    { lat: 49.3743726, lng: 20.5344908 },
    { lat: 49.3746899, lng: 20.5346062 },
    { lat: 49.3748111, lng: 20.5345662 },
    { lat: 49.3754426, lng: 20.5346914 },
    { lat: 49.3758378, lng: 20.5345372 },
    { lat: 49.3764422, lng: 20.5340448 },
    { lat: 49.3773092, lng: 20.5336816 },
    { lat: 49.3776116, lng: 20.5336426 },
    { lat: 49.3790273, lng: 20.5339659 },
    { lat: 49.3802416, lng: 20.5345122 },
    { lat: 49.3806094, lng: 20.5348094 },
    { lat: 49.3811893, lng: 20.5350905 },
    { lat: 49.381544, lng: 20.5351264 },
    { lat: 49.3821202, lng: 20.5349703 },
    { lat: 49.3828089, lng: 20.5351153 },
    { lat: 49.3829511, lng: 20.5350629 },
    { lat: 49.3833908, lng: 20.5351151 },
    { lat: 49.3839958, lng: 20.5350681 },
    { lat: 49.3843535, lng: 20.5351483 },
    { lat: 49.385324, lng: 20.5356123 },
    { lat: 49.3858672, lng: 20.5356642 },
    { lat: 49.38684, lng: 20.535944 },
    { lat: 49.3870283, lng: 20.5343411 },
    { lat: 49.3874201, lng: 20.5329641 },
    { lat: 49.3876535, lng: 20.5316534 },
    { lat: 49.3880557, lng: 20.5302985 },
    { lat: 49.3883908, lng: 20.5293732 },
    { lat: 49.388865, lng: 20.528285 },
    { lat: 49.3890587, lng: 20.5278431 },
    { lat: 49.3894115, lng: 20.5268387 },
    { lat: 49.3896708, lng: 20.5263102 },
    { lat: 49.3897541, lng: 20.526368 },
    { lat: 49.390136, lng: 20.5253346 },
    { lat: 49.3904965, lng: 20.5241662 },
    { lat: 49.3907388, lng: 20.5236363 },
    { lat: 49.3912304, lng: 20.5227664 },
    { lat: 49.3917184, lng: 20.5220668 },
    { lat: 49.392152, lng: 20.5211962 },
    { lat: 49.392505, lng: 20.5199565 },
    { lat: 49.3925293, lng: 20.5186479 },
    { lat: 49.3924158, lng: 20.5180887 },
    { lat: 49.392491, lng: 20.51756 },
    { lat: 49.392575, lng: 20.517022 },
    { lat: 49.392733, lng: 20.515948 },
    { lat: 49.3927364, lng: 20.5159314 },
    { lat: 49.3928653, lng: 20.5153761 },
    { lat: 49.3930602, lng: 20.5148583 },
    { lat: 49.3931079, lng: 20.514548 },
    { lat: 49.393199, lng: 20.514115 },
    { lat: 49.3933051, lng: 20.5136089 },
    { lat: 49.3933451, lng: 20.5131331 },
    { lat: 49.393346, lng: 20.513117 },
    { lat: 49.39335, lng: 20.5128636 },
    { lat: 49.393349, lng: 20.512857 },
    { lat: 49.3933185, lng: 20.5121913 },
    { lat: 49.393354, lng: 20.510986 },
    { lat: 49.3926029, lng: 20.5091841 },
    { lat: 49.3918738, lng: 20.5088283 },
    { lat: 49.3912644, lng: 20.5087874 },
    { lat: 49.3907823, lng: 20.5082654 },
    { lat: 49.3907016, lng: 20.5080259 },
    { lat: 49.3905357, lng: 20.5080753 },
    { lat: 49.3904504, lng: 20.5077202 },
    { lat: 49.390187, lng: 20.5077813 },
    { lat: 49.38939, lng: 20.5075319 },
    { lat: 49.389414, lng: 20.5073907 },
    { lat: 49.3880132, lng: 20.5064373 },
    { lat: 49.3880125, lng: 20.506352 },
    { lat: 49.3862751, lng: 20.5047268 },
    { lat: 49.3860966, lng: 20.504799 },
    { lat: 49.3860405, lng: 20.5049621 },
    { lat: 49.386007, lng: 20.5049046 },
    { lat: 49.3857711, lng: 20.5055289 },
    { lat: 49.3855658, lng: 20.5053356 },
    { lat: 49.3852965, lng: 20.5060285 },
    { lat: 49.3850496, lng: 20.5064145 },
    { lat: 49.3848665, lng: 20.506216 },
    { lat: 49.3845541, lng: 20.5060718 },
    { lat: 49.3841994, lng: 20.5050073 },
    { lat: 49.3836633, lng: 20.505117 },
    { lat: 49.3831575, lng: 20.5032179 },
    { lat: 49.3830272, lng: 20.503166 },
    { lat: 49.3829646, lng: 20.5025691 },
    { lat: 49.382903, lng: 20.5024263 },
    { lat: 49.3828212, lng: 20.5024677 },
    { lat: 49.3826684, lng: 20.502323 },
    { lat: 49.3824526, lng: 20.501997 },
    { lat: 49.3823677, lng: 20.5013388 },
    { lat: 49.3823953, lng: 20.5009885 },
    { lat: 49.3824707, lng: 20.5002914 },
    { lat: 49.3827129, lng: 20.498892 },
    { lat: 49.3826864, lng: 20.4988434 },
    { lat: 49.3828603, lng: 20.498041 },
    { lat: 49.3829101, lng: 20.4974361 },
    { lat: 49.3829367, lng: 20.496421 },
    { lat: 49.382833, lng: 20.4957471 },
    { lat: 49.3828498, lng: 20.4955039 },
    { lat: 49.3827985, lng: 20.4951521 },
    { lat: 49.3831447, lng: 20.495048 },
    { lat: 49.3831567, lng: 20.4944537 },
    { lat: 49.3834046, lng: 20.4932795 },
    { lat: 49.3834639, lng: 20.4932371 },
    { lat: 49.383738, lng: 20.4933131 },
    { lat: 49.3843379, lng: 20.4919125 },
    { lat: 49.3842548, lng: 20.4918211 },
    { lat: 49.3845308, lng: 20.4910693 },
    { lat: 49.3842746, lng: 20.4906551 },
    { lat: 49.3843463, lng: 20.4896418 },
    { lat: 49.3842261, lng: 20.4896501 },
    { lat: 49.3841939, lng: 20.4887181 },
    { lat: 49.384082, lng: 20.4887401 },
    { lat: 49.3841174, lng: 20.4879638 },
    { lat: 49.3838214, lng: 20.4878461 },
    { lat: 49.3838866, lng: 20.4872944 },
    { lat: 49.383973, lng: 20.486888 },
    { lat: 49.3843175, lng: 20.4859171 },
    { lat: 49.384323, lng: 20.485792 },
    { lat: 49.3842327, lng: 20.485608 },
    { lat: 49.3843965, lng: 20.4850752 },
    { lat: 49.3845511, lng: 20.4852063 },
    { lat: 49.3848422, lng: 20.4845241 },
    { lat: 49.384889, lng: 20.4846027 },
    { lat: 49.3850357, lng: 20.4839161 },
    { lat: 49.3852378, lng: 20.4839906 },
    { lat: 49.385353, lng: 20.4828792 },
    { lat: 49.3854997, lng: 20.4820386 },
    { lat: 49.3857018, lng: 20.4812933 },
    { lat: 49.3856118, lng: 20.4812072 },
    { lat: 49.3858317, lng: 20.4805481 },
    { lat: 49.3858451, lng: 20.4803637 },
    { lat: 49.3857605, lng: 20.4802056 },
    { lat: 49.3858505, lng: 20.4799365 },
    { lat: 49.3859143, lng: 20.4791388 },
    { lat: 49.386012, lng: 20.478675 },
    { lat: 49.3859535, lng: 20.4781623 },
    { lat: 49.3857036, lng: 20.4781028 },
    { lat: 49.3856839, lng: 20.4772987 },
    { lat: 49.3855265, lng: 20.4763006 },
    { lat: 49.3855086, lng: 20.4750299 },
    { lat: 49.3852265, lng: 20.4738313 },
    { lat: 49.3848582, lng: 20.473463 },
    { lat: 49.384993, lng: 20.4716713 },
    { lat: 49.3854063, lng: 20.4700409 },
    { lat: 49.3849436, lng: 20.4693789 },
    { lat: 49.383333, lng: 20.4685688 },
    { lat: 49.3820455, lng: 20.4661881 },
    { lat: 49.3818596, lng: 20.4661808 },
    { lat: 49.3810114, lng: 20.4666213 },
    { lat: 49.3801276, lng: 20.4661681 },
    { lat: 49.3797872, lng: 20.4659146 },
    { lat: 49.3789747, lng: 20.4657106 },
    { lat: 49.378637, lng: 20.4653477 },
    { lat: 49.3784882, lng: 20.4650124 },
    { lat: 49.3783571, lng: 20.4648764 },
    { lat: 49.3782508, lng: 20.4648655 },
    { lat: 49.3781628, lng: 20.464737 },
    { lat: 49.377858, lng: 20.4635359 },
    { lat: 49.3777964, lng: 20.463529 },
    { lat: 49.3775877, lng: 20.4635054 },
    { lat: 49.377175, lng: 20.463609 },
    { lat: 49.3770343, lng: 20.4634284 },
    { lat: 49.376886, lng: 20.4634934 },
    { lat: 49.3759041, lng: 20.4635813 },
    { lat: 49.375745, lng: 20.4637174 },
    { lat: 49.3754996, lng: 20.4637468 },
    { lat: 49.3754117, lng: 20.4636862 },
    { lat: 49.3751391, lng: 20.4637714 },
    { lat: 49.3749363, lng: 20.4639821 },
    { lat: 49.374453, lng: 20.4639387 },
    { lat: 49.3742138, lng: 20.4638378 },
    { lat: 49.3739094, lng: 20.4635508 },
    { lat: 49.3735044, lng: 20.4634071 },
    { lat: 49.3732811, lng: 20.4632459 },
    { lat: 49.3731868, lng: 20.4633409 },
    { lat: 49.3729734, lng: 20.4643815 },
    { lat: 49.372875, lng: 20.4646401 },
    { lat: 49.3725549, lng: 20.4649073 },
    { lat: 49.3723505, lng: 20.4649793 },
    { lat: 49.3718789, lng: 20.4648594 },
    { lat: 49.371749, lng: 20.4641204 },
    { lat: 49.3712009, lng: 20.464371 },
    { lat: 49.3709479, lng: 20.4640138 },
    { lat: 49.3707653, lng: 20.4639492 },
    { lat: 49.3706465, lng: 20.4637507 },
    { lat: 49.3702051, lng: 20.4637178 },
    { lat: 49.369922, lng: 20.4645221 },
    { lat: 49.3698243, lng: 20.4654962 },
    { lat: 49.3698684, lng: 20.4658634 },
    { lat: 49.3697502, lng: 20.4665257 },
    { lat: 49.3692771, lng: 20.467848 },
    { lat: 49.3691502, lng: 20.4688225 },
    { lat: 49.3687406, lng: 20.4698281 },
    { lat: 49.3676935, lng: 20.4712748 },
    { lat: 49.3664199, lng: 20.4709907 },
    { lat: 49.3656663, lng: 20.4712537 },
    { lat: 49.365233, lng: 20.4711665 },
    { lat: 49.3642558, lng: 20.4712835 },
    { lat: 49.3643184, lng: 20.4713936 },
    { lat: 49.3629936, lng: 20.4710653 },
    { lat: 49.3616685, lng: 20.4709757 },
    { lat: 49.3608312, lng: 20.4712525 },
    { lat: 49.3603566, lng: 20.4712336 },
    { lat: 49.3593687, lng: 20.4706704 },
    { lat: 49.3586003, lng: 20.470745 },
    { lat: 49.3573978, lng: 20.4707419 },
    { lat: 49.3563793, lng: 20.4702384 },
    { lat: 49.3553879, lng: 20.4712173 },
    { lat: 49.3549284, lng: 20.4715101 },
    { lat: 49.3539402, lng: 20.4713392 },
    { lat: 49.3529849, lng: 20.4709563 },
    { lat: 49.3526091, lng: 20.4712657 },
    { lat: 49.3523781, lng: 20.4709854 },
    { lat: 49.3514774, lng: 20.4712886 },
    { lat: 49.3503906, lng: 20.4706109 },
    { lat: 49.3500527, lng: 20.4702721 },
    { lat: 49.3489366, lng: 20.4698358 },
    { lat: 49.3483517, lng: 20.4699406 },
    { lat: 49.3473442, lng: 20.4695793 },
    { lat: 49.3468551, lng: 20.4687789 },
    { lat: 49.3465625, lng: 20.4689033 },
    { lat: 49.3452857, lng: 20.4678698 },
    { lat: 49.3447284, lng: 20.4681083 },
    { lat: 49.3440856, lng: 20.4677467 },
    { lat: 49.3430093, lng: 20.4674734 },
    { lat: 49.3424567, lng: 20.4676599 },
    { lat: 49.3419599, lng: 20.4663815 },
    { lat: 49.3414387, lng: 20.4662656 },
    { lat: 49.3411983, lng: 20.4663083 },
    { lat: 49.34095, lng: 20.4664397 },
    { lat: 49.34008, lng: 20.4663875 },
    { lat: 49.339996, lng: 20.4662796 },
    { lat: 49.3396125, lng: 20.4666189 },
    { lat: 49.3393229, lng: 20.4671597 },
    { lat: 49.3392095, lng: 20.4672241 },
    { lat: 49.3390295, lng: 20.4675019 },
    { lat: 49.3389073, lng: 20.4681465 },
    { lat: 49.3385662, lng: 20.4683564 },
    { lat: 49.3381321, lng: 20.4702174 },
    { lat: 49.3378505, lng: 20.4702394 },
    { lat: 49.3372835, lng: 20.470647 },
    { lat: 49.3371057, lng: 20.4707069 },
    { lat: 49.3365465, lng: 20.4707484 },
    { lat: 49.3362557, lng: 20.4706496 },
    { lat: 49.335916, lng: 20.4706547 },
    { lat: 49.3356249, lng: 20.4708205 },
    { lat: 49.3352968, lng: 20.4708642 },
    { lat: 49.3338785, lng: 20.4706294 },
    { lat: 49.3331629, lng: 20.4712109 },
    { lat: 49.3329859, lng: 20.4714169 },
    { lat: 49.3328193, lng: 20.471778 },
    { lat: 49.3326907, lng: 20.4717704 },
    { lat: 49.3321846, lng: 20.4724202 },
    { lat: 49.3319894, lng: 20.4725622 },
    { lat: 49.3317388, lng: 20.4728778 },
    { lat: 49.3313553, lng: 20.4738241 },
    { lat: 49.3311688, lng: 20.4739862 },
    { lat: 49.3306866, lng: 20.4747017 },
    { lat: 49.330111, lng: 20.4751781 },
    { lat: 49.3300528, lng: 20.4753702 },
    { lat: 49.3299375, lng: 20.4755354 },
    { lat: 49.3294894, lng: 20.476022 },
    { lat: 49.329015, lng: 20.4760344 },
    { lat: 49.3285166, lng: 20.476136 },
    { lat: 49.327497, lng: 20.4756913 },
    { lat: 49.3270048, lng: 20.4759236 },
    { lat: 49.3258296, lng: 20.4758365 },
    { lat: 49.323404, lng: 20.4763624 },
    { lat: 49.3218637, lng: 20.4758421 },
    { lat: 49.3202138, lng: 20.4755827 },
    { lat: 49.3188939, lng: 20.4754786 },
    { lat: 49.3186934, lng: 20.4754809 },
    { lat: 49.3185313, lng: 20.4755682 },
    { lat: 49.317695, lng: 20.476139 },
    { lat: 49.31755, lng: 20.476421 },
    { lat: 49.31734, lng: 20.477023 },
    { lat: 49.317185, lng: 20.477264 },
    { lat: 49.317092, lng: 20.477552 },
    { lat: 49.317059, lng: 20.477939 },
    { lat: 49.317002, lng: 20.478127 },
    { lat: 49.316806, lng: 20.478732 },
    { lat: 49.316512, lng: 20.479202 },
    { lat: 49.316335, lng: 20.479556 },
    { lat: 49.31615, lng: 20.479761 },
    { lat: 49.315967, lng: 20.480021 },
    { lat: 49.315907, lng: 20.480102 },
    { lat: 49.315338, lng: 20.480856 },
    { lat: 49.314931, lng: 20.482751 },
    { lat: 49.314916, lng: 20.4831 },
    { lat: 49.314881, lng: 20.483908 },
    { lat: 49.314891, lng: 20.483964 },
    { lat: 49.315484, lng: 20.485243 },
    { lat: 49.315579, lng: 20.485447 },
    { lat: 49.31624, lng: 20.486943 },
    { lat: 49.317018, lng: 20.487389 },
    { lat: 49.317094, lng: 20.487433 },
    { lat: 49.318282, lng: 20.488337 },
    { lat: 49.318773, lng: 20.488798 },
    { lat: 49.319629, lng: 20.489405 },
    { lat: 49.319674, lng: 20.489439 },
    { lat: 49.320446, lng: 20.489986 },
    { lat: 49.321067, lng: 20.490395 },
    { lat: 49.321964, lng: 20.491301 },
    { lat: 49.321983, lng: 20.491339 },
    { lat: 49.323124, lng: 20.494901 },
    { lat: 49.3234818, lng: 20.4963565 },
    { lat: 49.3234699, lng: 20.4971584 },
    { lat: 49.3245509, lng: 20.4973188 },
    { lat: 49.3260481, lng: 20.4976814 },
    { lat: 49.3268565, lng: 20.4994535 },
    { lat: 49.3276289, lng: 20.4996966 },
    { lat: 49.3284382, lng: 20.5002098 },
    { lat: 49.3313117, lng: 20.5032088 },
    { lat: 49.3321622, lng: 20.5044751 },
    { lat: 49.332744, lng: 20.506113 },
    { lat: 49.333759, lng: 20.5077773 },
    { lat: 49.3344238, lng: 20.5085912 },
    { lat: 49.3346131, lng: 20.5090218 },
    { lat: 49.3351118, lng: 20.5103006 },
    { lat: 49.3351479, lng: 20.5105804 },
    { lat: 49.3350187, lng: 20.51135 },
    { lat: 49.3350757, lng: 20.5117945 },
    { lat: 49.3350131, lng: 20.5127197 },
    { lat: 49.33502, lng: 20.5133356 },
    { lat: 49.3346509, lng: 20.5143811 },
    { lat: 49.3345768, lng: 20.5149062 },
    { lat: 49.3346376, lng: 20.5153911 },
    { lat: 49.3347688, lng: 20.5163891 },
  ],
  NováĽubovňa: [
    { lat: 49.287813, lng: 20.6663912 },
    { lat: 49.2870797, lng: 20.6651127 },
    { lat: 49.2854881, lng: 20.6637851 },
    { lat: 49.2839559, lng: 20.6632498 },
    { lat: 49.2817869, lng: 20.662492 },
    { lat: 49.2816874, lng: 20.662002 },
    { lat: 49.2810102, lng: 20.6613788 },
    { lat: 49.2802953, lng: 20.660118 },
    { lat: 49.2796034, lng: 20.6592418 },
    { lat: 49.279536, lng: 20.6591508 },
    { lat: 49.278407, lng: 20.6561855 },
    { lat: 49.2764975, lng: 20.6574075 },
    { lat: 49.275067, lng: 20.6559556 },
    { lat: 49.2748111, lng: 20.6559503 },
    { lat: 49.2737991, lng: 20.6563249 },
    { lat: 49.2723474, lng: 20.6571521 },
    { lat: 49.2721671, lng: 20.6567749 },
    { lat: 49.2719874, lng: 20.6556785 },
    { lat: 49.2718614, lng: 20.6553325 },
    { lat: 49.271754, lng: 20.6547553 },
    { lat: 49.2715066, lng: 20.6548899 },
    { lat: 49.2713919, lng: 20.6533764 },
    { lat: 49.2714557, lng: 20.652923 },
    { lat: 49.2714249, lng: 20.651626 },
    { lat: 49.2712786, lng: 20.6509634 },
    { lat: 49.2711562, lng: 20.649176 },
    { lat: 49.2711516, lng: 20.6485715 },
    { lat: 49.2701021, lng: 20.6469598 },
    { lat: 49.2699633, lng: 20.6468853 },
    { lat: 49.2693686, lng: 20.6471603 },
    { lat: 49.2690742, lng: 20.6472058 },
    { lat: 49.2686119, lng: 20.6475634 },
    { lat: 49.2683167, lng: 20.6480541 },
    { lat: 49.2673014, lng: 20.6507299 },
    { lat: 49.2670055, lng: 20.6508919 },
    { lat: 49.2669774, lng: 20.6511166 },
    { lat: 49.2660259, lng: 20.6523646 },
    { lat: 49.265549, lng: 20.6526289 },
    { lat: 49.2651781, lng: 20.6523315 },
    { lat: 49.2648541, lng: 20.6522079 },
    { lat: 49.2644128, lng: 20.6524934 },
    { lat: 49.2634769, lng: 20.6525737 },
    { lat: 49.2621065, lng: 20.6521802 },
    { lat: 49.2608892, lng: 20.6523589 },
    { lat: 49.2604865, lng: 20.6527143 },
    { lat: 49.25996, lng: 20.6529384 },
    { lat: 49.2595043, lng: 20.6529126 },
    { lat: 49.2592216, lng: 20.653035 },
    { lat: 49.2587065, lng: 20.6531078 },
    { lat: 49.2584767, lng: 20.6530423 },
    { lat: 49.2581262, lng: 20.6530935 },
    { lat: 49.2579203, lng: 20.6532545 },
    { lat: 49.2571877, lng: 20.6532288 },
    { lat: 49.2568373, lng: 20.6531268 },
    { lat: 49.2559753, lng: 20.6532895 },
    { lat: 49.2553295, lng: 20.6532962 },
    { lat: 49.2546013, lng: 20.6534935 },
    { lat: 49.2542806, lng: 20.6536539 },
    { lat: 49.2531218, lng: 20.653543 },
    { lat: 49.2528589, lng: 20.6536204 },
    { lat: 49.2527416, lng: 20.6534259 },
    { lat: 49.2519603, lng: 20.6532006 },
    { lat: 49.2514803, lng: 20.6531884 },
    { lat: 49.2512154, lng: 20.6530227 },
    { lat: 49.250877, lng: 20.6533489 },
    { lat: 49.2504603, lng: 20.6533368 },
    { lat: 49.2501737, lng: 20.6534505 },
    { lat: 49.2498344, lng: 20.6536247 },
    { lat: 49.2496251, lng: 20.653881 },
    { lat: 49.249314, lng: 20.6540857 },
    { lat: 49.2491396, lng: 20.6540901 },
    { lat: 49.2486563, lng: 20.6545805 },
    { lat: 49.2486136, lng: 20.6546456 },
    { lat: 49.2486021, lng: 20.6548325 },
    { lat: 49.2483996, lng: 20.6551178 },
    { lat: 49.2482733, lng: 20.6550758 },
    { lat: 49.2480562, lng: 20.6551465 },
    { lat: 49.2478689, lng: 20.6550075 },
    { lat: 49.2478107, lng: 20.6551413 },
    { lat: 49.2477159, lng: 20.6570243 },
    { lat: 49.2481384, lng: 20.6574474 },
    { lat: 49.2484894, lng: 20.6581017 },
    { lat: 49.2487876, lng: 20.6584822 },
    { lat: 49.2490575, lng: 20.6591973 },
    { lat: 49.2492945, lng: 20.6593739 },
    { lat: 49.2494845, lng: 20.6599043 },
    { lat: 49.2499428, lng: 20.6599379 },
    { lat: 49.2503403, lng: 20.6603563 },
    { lat: 49.2510531, lng: 20.6622197 },
    { lat: 49.2516638, lng: 20.6629673 },
    { lat: 49.2517797, lng: 20.6633728 },
    { lat: 49.2516856, lng: 20.6637637 },
    { lat: 49.2522905, lng: 20.6643778 },
    { lat: 49.2529773, lng: 20.6643491 },
    { lat: 49.2537453, lng: 20.6646114 },
    { lat: 49.2544053, lng: 20.6645266 },
    { lat: 49.2550483, lng: 20.6646915 },
    { lat: 49.2552939, lng: 20.6648844 },
    { lat: 49.2557967, lng: 20.6648772 },
    { lat: 49.2561066, lng: 20.6644773 },
    { lat: 49.2560424, lng: 20.6649202 },
    { lat: 49.256091, lng: 20.6652714 },
    { lat: 49.2567357, lng: 20.6680752 },
    { lat: 49.2570943, lng: 20.6690422 },
    { lat: 49.2572334, lng: 20.6697233 },
    { lat: 49.2573246, lng: 20.6714047 },
    { lat: 49.2572919, lng: 20.672223 },
    { lat: 49.2571954, lng: 20.6726735 },
    { lat: 49.2572024, lng: 20.6728106 },
    { lat: 49.2593135, lng: 20.6810637 },
    { lat: 49.2598797, lng: 20.6841727 },
    { lat: 49.2605702, lng: 20.6886683 },
    { lat: 49.2607829, lng: 20.6888533 },
    { lat: 49.2607913, lng: 20.6891221 },
    { lat: 49.2594677, lng: 20.6897603 },
    { lat: 49.2597004, lng: 20.6912161 },
    { lat: 49.2598482, lng: 20.693293 },
    { lat: 49.2595721, lng: 20.6951631 },
    { lat: 49.2600171, lng: 20.6956803 },
    { lat: 49.2595638, lng: 20.6995122 },
    { lat: 49.2592448, lng: 20.7018258 },
    { lat: 49.2593607, lng: 20.705269 },
    { lat: 49.2591252, lng: 20.7095736 },
    { lat: 49.2583018, lng: 20.711859 },
    { lat: 49.2586225, lng: 20.71668 },
    { lat: 49.2595607, lng: 20.7188095 },
    { lat: 49.2594905, lng: 20.7205584 },
    { lat: 49.2587783, lng: 20.721756 },
    { lat: 49.2589293, lng: 20.7220113 },
    { lat: 49.2584688, lng: 20.7226469 },
    { lat: 49.258984, lng: 20.7234418 },
    { lat: 49.2590391, lng: 20.723726 },
    { lat: 49.2593047, lng: 20.7240826 },
    { lat: 49.2593772, lng: 20.7249931 },
    { lat: 49.2594298, lng: 20.7252082 },
    { lat: 49.2596879, lng: 20.7255402 },
    { lat: 49.2599729, lng: 20.7262466 },
    { lat: 49.260156, lng: 20.7263956 },
    { lat: 49.2605698, lng: 20.7274136 },
    { lat: 49.2609379, lng: 20.7278952 },
    { lat: 49.2609936, lng: 20.7281642 },
    { lat: 49.2609384, lng: 20.728132 },
    { lat: 49.2608585, lng: 20.7282001 },
    { lat: 49.2602856, lng: 20.728202 },
    { lat: 49.2602561, lng: 20.7292509 },
    { lat: 49.2610939, lng: 20.7295535 },
    { lat: 49.2615322, lng: 20.7295941 },
    { lat: 49.2615907, lng: 20.729688 },
    { lat: 49.2618179, lng: 20.7297181 },
    { lat: 49.2625478, lng: 20.7300576 },
    { lat: 49.2628182, lng: 20.7300593 },
    { lat: 49.2634882, lng: 20.7295878 },
    { lat: 49.2634694, lng: 20.7292134 },
    { lat: 49.2632389, lng: 20.728833 },
    { lat: 49.2633122, lng: 20.728712 },
    { lat: 49.2633609, lng: 20.7284597 },
    { lat: 49.2633265, lng: 20.728251 },
    { lat: 49.2628257, lng: 20.7280959 },
    { lat: 49.262672, lng: 20.7262824 },
    { lat: 49.2628232, lng: 20.7258725 },
    { lat: 49.262782, lng: 20.7249889 },
    { lat: 49.2629038, lng: 20.7244211 },
    { lat: 49.263042, lng: 20.7243128 },
    { lat: 49.2631053, lng: 20.7243898 },
    { lat: 49.2632654, lng: 20.7243006 },
    { lat: 49.2636781, lng: 20.7239024 },
    { lat: 49.2635629, lng: 20.7231543 },
    { lat: 49.2643064, lng: 20.7226251 },
    { lat: 49.2647535, lng: 20.7221841 },
    { lat: 49.2648517, lng: 20.7220329 },
    { lat: 49.2651489, lng: 20.7209453 },
    { lat: 49.2656171, lng: 20.7204023 },
    { lat: 49.2666369, lng: 20.7197625 },
    { lat: 49.2671352, lng: 20.719745 },
    { lat: 49.2675304, lng: 20.7194945 },
    { lat: 49.2679886, lng: 20.7190161 },
    { lat: 49.2691142, lng: 20.7198223 },
    { lat: 49.2693993, lng: 20.7198947 },
    { lat: 49.269583, lng: 20.7198332 },
    { lat: 49.2698481, lng: 20.7199477 },
    { lat: 49.2704606, lng: 20.7199186 },
    { lat: 49.2710452, lng: 20.7202495 },
    { lat: 49.2714117, lng: 20.7202108 },
    { lat: 49.2716594, lng: 20.720083 },
    { lat: 49.2719857, lng: 20.7204026 },
    { lat: 49.2722698, lng: 20.7205714 },
    { lat: 49.2723698, lng: 20.7205052 },
    { lat: 49.2726603, lng: 20.7205351 },
    { lat: 49.2734262, lng: 20.7203295 },
    { lat: 49.2736267, lng: 20.7203963 },
    { lat: 49.2736908, lng: 20.7205896 },
    { lat: 49.2741305, lng: 20.7206044 },
    { lat: 49.2744149, lng: 20.720537 },
    { lat: 49.2744911, lng: 20.720591 },
    { lat: 49.2750857, lng: 20.7204344 },
    { lat: 49.27546, lng: 20.7204823 },
    { lat: 49.2757682, lng: 20.720285 },
    { lat: 49.2758946, lng: 20.7203929 },
    { lat: 49.2760012, lng: 20.720352 },
    { lat: 49.2764854, lng: 20.7206789 },
    { lat: 49.2768463, lng: 20.7210833 },
    { lat: 49.2771545, lng: 20.719731 },
    { lat: 49.2776846, lng: 20.7183861 },
    { lat: 49.2782677, lng: 20.7164949 },
    { lat: 49.2782683, lng: 20.7159357 },
    { lat: 49.2788537, lng: 20.7153977 },
    { lat: 49.2802775, lng: 20.7144031 },
    { lat: 49.2810002, lng: 20.7142748 },
    { lat: 49.2811844, lng: 20.7138808 },
    { lat: 49.2812629, lng: 20.7131338 },
    { lat: 49.2812377, lng: 20.71274 },
    { lat: 49.2811623, lng: 20.7124203 },
    { lat: 49.280919, lng: 20.7104667 },
    { lat: 49.2812875, lng: 20.7087812 },
    { lat: 49.2815135, lng: 20.7083296 },
    { lat: 49.2815335, lng: 20.7078784 },
    { lat: 49.2816105, lng: 20.7075677 },
    { lat: 49.2814712, lng: 20.7052155 },
    { lat: 49.2814752, lng: 20.7046928 },
    { lat: 49.2816063, lng: 20.7040016 },
    { lat: 49.2825213, lng: 20.7019923 },
    { lat: 49.2827102, lng: 20.7002612 },
    { lat: 49.2829175, lng: 20.6992771 },
    { lat: 49.2829261, lng: 20.6988881 },
    { lat: 49.2830011, lng: 20.6983984 },
    { lat: 49.2830004, lng: 20.6971629 },
    { lat: 49.2833584, lng: 20.6964345 },
    { lat: 49.2841314, lng: 20.6943894 },
    { lat: 49.2847856, lng: 20.6935393 },
    { lat: 49.2848625, lng: 20.6933249 },
    { lat: 49.2851307, lng: 20.6930442 },
    { lat: 49.2861969, lng: 20.6913994 },
    { lat: 49.2862784, lng: 20.6898502 },
    { lat: 49.2861816, lng: 20.689662 },
    { lat: 49.2864922, lng: 20.6872753 },
    { lat: 49.286296, lng: 20.6864697 },
    { lat: 49.2863363, lng: 20.6840192 },
    { lat: 49.2862208, lng: 20.6816132 },
    { lat: 49.2864136, lng: 20.680513 },
    { lat: 49.2865026, lng: 20.6782347 },
    { lat: 49.286383, lng: 20.6743536 },
    { lat: 49.2856262, lng: 20.671212 },
    { lat: 49.285851, lng: 20.6701577 },
    { lat: 49.2859111, lng: 20.6702014 },
    { lat: 49.2861941, lng: 20.6692991 },
    { lat: 49.2864774, lng: 20.6687481 },
    { lat: 49.2866677, lng: 20.6679927 },
    { lat: 49.2868258, lng: 20.6678822 },
    { lat: 49.2873789, lng: 20.6669405 },
    { lat: 49.287813, lng: 20.6663912 },
  ],
  Vislanka: [
    { lat: 49.224721, lng: 20.832985 },
    { lat: 49.224702, lng: 20.832978 },
    { lat: 49.224666, lng: 20.833037 },
    { lat: 49.224441, lng: 20.832797 },
    { lat: 49.224023, lng: 20.832525 },
    { lat: 49.223905, lng: 20.832352 },
    { lat: 49.225179, lng: 20.836643 },
    { lat: 49.225337, lng: 20.837477 },
    { lat: 49.225654, lng: 20.837585 },
    { lat: 49.225814, lng: 20.838098 },
    { lat: 49.225944, lng: 20.83822 },
    { lat: 49.226099, lng: 20.838369 },
    { lat: 49.22744, lng: 20.841143 },
    { lat: 49.226863, lng: 20.841374 },
    { lat: 49.227514, lng: 20.843143 },
    { lat: 49.227917, lng: 20.844256 },
    { lat: 49.227107, lng: 20.845018 },
    { lat: 49.227164, lng: 20.846225 },
    { lat: 49.227351, lng: 20.847125 },
    { lat: 49.226309, lng: 20.847323 },
    { lat: 49.22703, lng: 20.847795 },
    { lat: 49.227128, lng: 20.847788 },
    { lat: 49.226727, lng: 20.847948 },
    { lat: 49.226587, lng: 20.848024 },
    { lat: 49.225939, lng: 20.848931 },
    { lat: 49.22559, lng: 20.849733 },
    { lat: 49.225417, lng: 20.850723 },
    { lat: 49.225176, lng: 20.850985 },
    { lat: 49.224566, lng: 20.852531 },
    { lat: 49.22456, lng: 20.852529 },
    { lat: 49.224047, lng: 20.852146 },
    { lat: 49.223497, lng: 20.852094 },
    { lat: 49.223305, lng: 20.851708 },
    { lat: 49.222669, lng: 20.851329 },
    { lat: 49.222545, lng: 20.851686 },
    { lat: 49.222074, lng: 20.851932 },
    { lat: 49.221815, lng: 20.851684 },
    { lat: 49.221487, lng: 20.851215 },
    { lat: 49.221064, lng: 20.851035 },
    { lat: 49.220625, lng: 20.851128 },
    { lat: 49.22088, lng: 20.850481 },
    { lat: 49.220406, lng: 20.849939 },
    { lat: 49.220122, lng: 20.848535 },
    { lat: 49.219883, lng: 20.847391 },
    { lat: 49.219631, lng: 20.846903 },
    { lat: 49.219837, lng: 20.846575 },
    { lat: 49.220047, lng: 20.846176 },
    { lat: 49.219857, lng: 20.845936 },
    { lat: 49.220025, lng: 20.845283 },
    { lat: 49.220248, lng: 20.845333 },
    { lat: 49.220279, lng: 20.845151 },
    { lat: 49.22013, lng: 20.845048 },
    { lat: 49.220204, lng: 20.844582 },
    { lat: 49.219997, lng: 20.844423 },
    { lat: 49.219822, lng: 20.84485 },
    { lat: 49.219477, lng: 20.845043 },
    { lat: 49.21918, lng: 20.84481 },
    { lat: 49.219238, lng: 20.844059 },
    { lat: 49.219137, lng: 20.843987 },
    { lat: 49.219064, lng: 20.844155 },
    { lat: 49.21884, lng: 20.844309 },
    { lat: 49.218272, lng: 20.844243 },
    { lat: 49.217836, lng: 20.844605 },
    { lat: 49.217438, lng: 20.843866 },
    { lat: 49.217313, lng: 20.843956 },
    { lat: 49.217076, lng: 20.843481 },
    { lat: 49.216744, lng: 20.843361 },
    { lat: 49.216372, lng: 20.84259 },
    { lat: 49.215677, lng: 20.842668 },
    { lat: 49.215714, lng: 20.84322 },
    { lat: 49.215776, lng: 20.843592 },
    { lat: 49.216175, lng: 20.844226 },
    { lat: 49.216758, lng: 20.843912 },
    { lat: 49.216998, lng: 20.844199 },
    { lat: 49.217306, lng: 20.844583 },
    { lat: 49.217353, lng: 20.844917 },
    { lat: 49.218021, lng: 20.845326 },
    { lat: 49.218468, lng: 20.845944 },
    { lat: 49.218033, lng: 20.846433 },
    { lat: 49.217482, lng: 20.846163 },
    { lat: 49.217132, lng: 20.845997 },
    { lat: 49.217017, lng: 20.845616 },
    { lat: 49.216812, lng: 20.845622 },
    { lat: 49.216586, lng: 20.84601 },
    { lat: 49.216672, lng: 20.846032 },
    { lat: 49.216891, lng: 20.846088 },
    { lat: 49.216882, lng: 20.846973 },
    { lat: 49.216269, lng: 20.848743 },
    { lat: 49.215959, lng: 20.84895 },
    { lat: 49.216107, lng: 20.848996 },
    { lat: 49.216082, lng: 20.849613 },
    { lat: 49.215888, lng: 20.849864 },
    { lat: 49.216264, lng: 20.850174 },
    { lat: 49.216431, lng: 20.849428 },
    { lat: 49.216777, lng: 20.848916 },
    { lat: 49.217667, lng: 20.848675 },
    { lat: 49.218435, lng: 20.848537 },
    { lat: 49.218679, lng: 20.84874 },
    { lat: 49.218777, lng: 20.848942 },
    { lat: 49.218784, lng: 20.849067 },
    { lat: 49.218714, lng: 20.849387 },
    { lat: 49.218453, lng: 20.849518 },
    { lat: 49.21826, lng: 20.849847 },
    { lat: 49.218177, lng: 20.849987 },
    { lat: 49.218023, lng: 20.850068 },
    { lat: 49.218041, lng: 20.850135 },
    { lat: 49.218358, lng: 20.850908 },
    { lat: 49.218253, lng: 20.852146 },
    { lat: 49.218309, lng: 20.852898 },
    { lat: 49.218745, lng: 20.853483 },
    { lat: 49.218796, lng: 20.853692 },
    { lat: 49.218533, lng: 20.854235 },
    { lat: 49.218681, lng: 20.854771 },
    { lat: 49.218242, lng: 20.856572 },
    { lat: 49.218887, lng: 20.857423 },
    { lat: 49.219234, lng: 20.857263 },
    { lat: 49.219522, lng: 20.857698 },
    { lat: 49.219595, lng: 20.857609 },
    { lat: 49.219737, lng: 20.857781 },
    { lat: 49.220443, lng: 20.858652 },
    { lat: 49.220819, lng: 20.858309 },
    { lat: 49.220587, lng: 20.85799 },
    { lat: 49.220952, lng: 20.857406 },
    { lat: 49.221047, lng: 20.856977 },
    { lat: 49.220775, lng: 20.856262 },
    { lat: 49.2209, lng: 20.85617 },
    { lat: 49.220695, lng: 20.855684 },
    { lat: 49.220747, lng: 20.855593 },
    { lat: 49.220999, lng: 20.85562 },
    { lat: 49.221326, lng: 20.855615 },
    { lat: 49.222068, lng: 20.856343 },
    { lat: 49.221974, lng: 20.857236 },
    { lat: 49.221932, lng: 20.857643 },
    { lat: 49.221652, lng: 20.858079 },
    { lat: 49.221927, lng: 20.8589 },
    { lat: 49.222086, lng: 20.859668 },
    { lat: 49.222127, lng: 20.86011 },
    { lat: 49.221912, lng: 20.860216 },
    { lat: 49.221813, lng: 20.859935 },
    { lat: 49.22096, lng: 20.860502 },
    { lat: 49.220166, lng: 20.861942 },
    { lat: 49.220115, lng: 20.861969 },
    { lat: 49.220067, lng: 20.862014 },
    { lat: 49.22012, lng: 20.862204 },
    { lat: 49.220223, lng: 20.862227 },
    { lat: 49.220134, lng: 20.862308 },
    { lat: 49.220085, lng: 20.862354 },
    { lat: 49.219876, lng: 20.862546 },
    { lat: 49.219332, lng: 20.863408 },
    { lat: 49.21922, lng: 20.863796 },
    { lat: 49.219121, lng: 20.864038 },
    { lat: 49.219116, lng: 20.864043 },
    { lat: 49.219077, lng: 20.864089 },
    { lat: 49.2186, lng: 20.864662 },
    { lat: 49.218353, lng: 20.864749 },
    { lat: 49.218279, lng: 20.864974 },
    { lat: 49.218086, lng: 20.865172 },
    { lat: 49.217996, lng: 20.865553 },
    { lat: 49.217182, lng: 20.867533 },
    { lat: 49.21687, lng: 20.868352 },
    { lat: 49.216206, lng: 20.868087 },
    { lat: 49.215497, lng: 20.868145 },
    { lat: 49.214757, lng: 20.868525 },
    { lat: 49.214696, lng: 20.869122 },
    { lat: 49.214694, lng: 20.869191 },
    { lat: 49.213632, lng: 20.86879 },
    { lat: 49.213374, lng: 20.86917 },
    { lat: 49.212418, lng: 20.869952 },
    { lat: 49.212427, lng: 20.86851 },
    { lat: 49.211445, lng: 20.868171 },
    { lat: 49.210983, lng: 20.872033 },
    { lat: 49.210781, lng: 20.873879 },
    { lat: 49.210766, lng: 20.87392 },
    { lat: 49.210751, lng: 20.873961 },
    { lat: 49.210833, lng: 20.873994 },
    { lat: 49.210898, lng: 20.874218 },
    { lat: 49.210985, lng: 20.874479 },
    { lat: 49.211271, lng: 20.874653 },
    { lat: 49.21134, lng: 20.874885 },
    { lat: 49.211372, lng: 20.875074 },
    { lat: 49.211432, lng: 20.875178 },
    { lat: 49.21159, lng: 20.874807 },
    { lat: 49.211674, lng: 20.874822 },
    { lat: 49.211779, lng: 20.874588 },
    { lat: 49.21185, lng: 20.874538 },
    { lat: 49.212132, lng: 20.873938 },
    { lat: 49.212199, lng: 20.873484 },
    { lat: 49.212411, lng: 20.873568 },
    { lat: 49.212451, lng: 20.873564 },
    { lat: 49.21252, lng: 20.873437 },
    { lat: 49.212613, lng: 20.873435 },
    { lat: 49.212768, lng: 20.873212 },
    { lat: 49.212876, lng: 20.873144 },
    { lat: 49.213194, lng: 20.873184 },
    { lat: 49.213378, lng: 20.873149 },
    { lat: 49.213762, lng: 20.873325 },
    { lat: 49.213975, lng: 20.873359 },
    { lat: 49.21404, lng: 20.87336 },
    { lat: 49.213965, lng: 20.873699 },
    { lat: 49.214342, lng: 20.874061 },
    { lat: 49.214623, lng: 20.874001 },
    { lat: 49.214987, lng: 20.874436 },
    { lat: 49.215076, lng: 20.874486 },
    { lat: 49.215219, lng: 20.874617 },
    { lat: 49.215307, lng: 20.874928 },
    { lat: 49.215459, lng: 20.87505 },
    { lat: 49.215711, lng: 20.875109 },
    { lat: 49.215788, lng: 20.8752 },
    { lat: 49.215869, lng: 20.875234 },
    { lat: 49.215932, lng: 20.875297 },
    { lat: 49.216003, lng: 20.875474 },
    { lat: 49.216059, lng: 20.875536 },
    { lat: 49.216075, lng: 20.875616 },
    { lat: 49.216216, lng: 20.875789 },
    { lat: 49.21629, lng: 20.87583 },
    { lat: 49.216511, lng: 20.876094 },
    { lat: 49.216645, lng: 20.876303 },
    { lat: 49.216824, lng: 20.876471 },
    { lat: 49.216877, lng: 20.876192 },
    { lat: 49.216862, lng: 20.875911 },
    { lat: 49.217034, lng: 20.87584 },
    { lat: 49.217196, lng: 20.875992 },
    { lat: 49.217718, lng: 20.876238 },
    { lat: 49.218118, lng: 20.876576 },
    { lat: 49.218284, lng: 20.876655 },
    { lat: 49.219389, lng: 20.876335 },
    { lat: 49.220445, lng: 20.876217 },
    { lat: 49.221034, lng: 20.876267 },
    { lat: 49.221324, lng: 20.876422 },
    { lat: 49.221188, lng: 20.876914 },
    { lat: 49.221205, lng: 20.877276 },
    { lat: 49.221217, lng: 20.877393 },
    { lat: 49.221551, lng: 20.877449 },
    { lat: 49.221707, lng: 20.877631 },
    { lat: 49.221828, lng: 20.877747 },
    { lat: 49.221971, lng: 20.877768 },
    { lat: 49.222101, lng: 20.877869 },
    { lat: 49.22224, lng: 20.877909 },
    { lat: 49.222359, lng: 20.877932 },
    { lat: 49.222487, lng: 20.877903 },
    { lat: 49.222682, lng: 20.877824 },
    { lat: 49.222809, lng: 20.877998 },
    { lat: 49.222968, lng: 20.877913 },
    { lat: 49.222989, lng: 20.877794 },
    { lat: 49.223127, lng: 20.877739 },
    { lat: 49.223218, lng: 20.877676 },
    { lat: 49.22328, lng: 20.877528 },
    { lat: 49.223524, lng: 20.877494 },
    { lat: 49.223552, lng: 20.877601 },
    { lat: 49.223608, lng: 20.877621 },
    { lat: 49.223653, lng: 20.877569 },
    { lat: 49.223669, lng: 20.877502 },
    { lat: 49.223758, lng: 20.877437 },
    { lat: 49.223895, lng: 20.877394 },
    { lat: 49.223925, lng: 20.877297 },
    { lat: 49.224009, lng: 20.877166 },
    { lat: 49.22404, lng: 20.876884 },
    { lat: 49.224063, lng: 20.876948 },
    { lat: 49.224081, lng: 20.876894 },
    { lat: 49.224161, lng: 20.876866 },
    { lat: 49.224152, lng: 20.876762 },
    { lat: 49.224229, lng: 20.876619 },
    { lat: 49.22432, lng: 20.876582 },
    { lat: 49.224368, lng: 20.87642 },
    { lat: 49.224514, lng: 20.876355 },
    { lat: 49.224649, lng: 20.876148 },
    { lat: 49.224805, lng: 20.876108 },
    { lat: 49.22498, lng: 20.876199 },
    { lat: 49.225105, lng: 20.876201 },
    { lat: 49.225113, lng: 20.876108 },
    { lat: 49.225252, lng: 20.876185 },
    { lat: 49.225345, lng: 20.876077 },
    { lat: 49.225426, lng: 20.876107 },
    { lat: 49.2255, lng: 20.87603 },
    { lat: 49.22561, lng: 20.876023 },
    { lat: 49.225671, lng: 20.876136 },
    { lat: 49.225784, lng: 20.87601 },
    { lat: 49.225851, lng: 20.876037 },
    { lat: 49.225936, lng: 20.87588 },
    { lat: 49.225848, lng: 20.875867 },
    { lat: 49.225845, lng: 20.875784 },
    { lat: 49.225978, lng: 20.875646 },
    { lat: 49.226044, lng: 20.875691 },
    { lat: 49.22602, lng: 20.87549 },
    { lat: 49.2261, lng: 20.875448 },
    { lat: 49.226129, lng: 20.875356 },
    { lat: 49.226208, lng: 20.875406 },
    { lat: 49.226281, lng: 20.875394 },
    { lat: 49.226333, lng: 20.87552 },
    { lat: 49.226384, lng: 20.875559 },
    { lat: 49.226418, lng: 20.875518 },
    { lat: 49.2264, lng: 20.875344 },
    { lat: 49.226446, lng: 20.875311 },
    { lat: 49.226466, lng: 20.875173 },
    { lat: 49.226623, lng: 20.875272 },
    { lat: 49.226662, lng: 20.875141 },
    { lat: 49.226717, lng: 20.875208 },
    { lat: 49.226764, lng: 20.875167 },
    { lat: 49.22676, lng: 20.875075 },
    { lat: 49.226806, lng: 20.875028 },
    { lat: 49.227801, lng: 20.875187 },
    { lat: 49.2288538, lng: 20.8745942 },
    { lat: 49.228853, lng: 20.8747383 },
    { lat: 49.2309573, lng: 20.8734325 },
    { lat: 49.2313522, lng: 20.8734989 },
    { lat: 49.2330739, lng: 20.8729891 },
    { lat: 49.2338968, lng: 20.8728943 },
    { lat: 49.2346722, lng: 20.8724963 },
    { lat: 49.2355238, lng: 20.8723487 },
    { lat: 49.2379299, lng: 20.8714441 },
    { lat: 49.2378949, lng: 20.8713435 },
    { lat: 49.2375342, lng: 20.8705609 },
    { lat: 49.2374679, lng: 20.870135 },
    { lat: 49.2371171, lng: 20.8688524 },
    { lat: 49.2372121, lng: 20.8673354 },
    { lat: 49.2366589, lng: 20.8670507 },
    { lat: 49.2366107, lng: 20.8656639 },
    { lat: 49.2366751, lng: 20.865241 },
    { lat: 49.2364827, lng: 20.8636859 },
    { lat: 49.236486, lng: 20.8627823 },
    { lat: 49.2365046, lng: 20.8621696 },
    { lat: 49.2366982, lng: 20.8622721 },
    { lat: 49.2367178, lng: 20.8619197 },
    { lat: 49.2364082, lng: 20.8605954 },
    { lat: 49.2361678, lng: 20.8597122 },
    { lat: 49.2354129, lng: 20.8582334 },
    { lat: 49.2341083, lng: 20.856205 },
    { lat: 49.2339754, lng: 20.8543838 },
    { lat: 49.2341032, lng: 20.8526168 },
    { lat: 49.233725, lng: 20.8502014 },
    { lat: 49.233425, lng: 20.8496547 },
    { lat: 49.2332246, lng: 20.8488945 },
    { lat: 49.2332624, lng: 20.8481835 },
    { lat: 49.2330159, lng: 20.8475245 },
    { lat: 49.2321943, lng: 20.8466675 },
    { lat: 49.2317657, lng: 20.8457807 },
    { lat: 49.231083, lng: 20.8458598 },
    { lat: 49.2308631, lng: 20.8457469 },
    { lat: 49.2305132, lng: 20.8453555 },
    { lat: 49.2297894, lng: 20.8439383 },
    { lat: 49.2290055, lng: 20.8416797 },
    { lat: 49.227788, lng: 20.8403296 },
    { lat: 49.2276671, lng: 20.8385045 },
    { lat: 49.2267993, lng: 20.8366654 },
    { lat: 49.2253282, lng: 20.8349493 },
    { lat: 49.2247836, lng: 20.8347569 },
    { lat: 49.2247693, lng: 20.8344346 },
    { lat: 49.224981, lng: 20.8339075 },
    { lat: 49.224721, lng: 20.832985 },
  ],
  Obručné: [
    { lat: 49.30774, lng: 20.962492 },
    { lat: 49.3077094, lng: 20.962642 },
    { lat: 49.3076799, lng: 20.9626317 },
    { lat: 49.3077651, lng: 20.9630359 },
    { lat: 49.3079772, lng: 20.9633592 },
    { lat: 49.3080231, lng: 20.9635836 },
    { lat: 49.3081669, lng: 20.9637196 },
    { lat: 49.3082581, lng: 20.9640242 },
    { lat: 49.3082647, lng: 20.9642596 },
    { lat: 49.308003, lng: 20.964511 },
    { lat: 49.3079706, lng: 20.9646577 },
    { lat: 49.3077442, lng: 20.9647495 },
    { lat: 49.307623, lng: 20.9650384 },
    { lat: 49.3072643, lng: 20.9653507 },
    { lat: 49.306796, lng: 20.9659606 },
    { lat: 49.30608, lng: 20.9670809 },
    { lat: 49.3058118, lng: 20.9673428 },
    { lat: 49.3054245, lng: 20.9675751 },
    { lat: 49.3044607, lng: 20.9675907 },
    { lat: 49.3042874, lng: 20.9676625 },
    { lat: 49.3040489, lng: 20.9680018 },
    { lat: 49.3039653, lng: 20.9682986 },
    { lat: 49.3037507, lng: 20.9687198 },
    { lat: 49.3034841, lng: 20.9690656 },
    { lat: 49.3026827, lng: 20.9696781 },
    { lat: 49.3022715, lng: 20.9698667 },
    { lat: 49.3018885, lng: 20.9703016 },
    { lat: 49.3008311, lng: 20.9686453 },
    { lat: 49.3006583, lng: 20.9686244 },
    { lat: 49.2997939, lng: 20.9690275 },
    { lat: 49.2990376, lng: 20.9688576 },
    { lat: 49.298801, lng: 20.9688823 },
    { lat: 49.2984674, lng: 20.969106 },
    { lat: 49.2981358, lng: 20.969428 },
    { lat: 49.2981255, lng: 20.9695218 },
    { lat: 49.2979679, lng: 20.969622 },
    { lat: 49.2979117, lng: 20.9697965 },
    { lat: 49.2977947, lng: 20.9698661 },
    { lat: 49.2975655, lng: 20.9698409 },
    { lat: 49.2970747, lng: 20.9692239 },
    { lat: 49.2968642, lng: 20.969084 },
    { lat: 49.2965974, lng: 20.9690166 },
    { lat: 49.2963011, lng: 20.9692782 },
    { lat: 49.2907207, lng: 20.9753656 },
    { lat: 49.285129, lng: 20.9741746 },
    { lat: 49.2822374, lng: 20.9947026 },
    { lat: 49.2821565, lng: 20.9949474 },
    { lat: 49.2819701, lng: 20.9951001 },
    { lat: 49.2818722, lng: 20.9950986 },
    { lat: 49.2811122, lng: 20.9956712 },
    { lat: 49.2811312, lng: 20.996285 },
    { lat: 49.2810688, lng: 20.9972969 },
    { lat: 49.277415, lng: 21.004962 },
    { lat: 49.277401, lng: 21.004961 },
    { lat: 49.2774, lng: 21.005897 },
    { lat: 49.277558, lng: 21.006839 },
    { lat: 49.277644, lng: 21.00708 },
    { lat: 49.277743, lng: 21.007707 },
    { lat: 49.277766, lng: 21.00797 },
    { lat: 49.277881, lng: 21.00818 },
    { lat: 49.277835, lng: 21.008431 },
    { lat: 49.278425, lng: 21.010603 },
    { lat: 49.278512, lng: 21.010751 },
    { lat: 49.278501, lng: 21.011186 },
    { lat: 49.278541, lng: 21.011608 },
    { lat: 49.278597, lng: 21.011676 },
    { lat: 49.278642, lng: 21.011729 },
    { lat: 49.278955, lng: 21.012045 },
    { lat: 49.279056, lng: 21.012356 },
    { lat: 49.279426, lng: 21.012601 },
    { lat: 49.279787, lng: 21.012688 },
    { lat: 49.280006, lng: 21.01264 },
    { lat: 49.280225, lng: 21.012645 },
    { lat: 49.280472, lng: 21.013036 },
    { lat: 49.280582, lng: 21.013369 },
    { lat: 49.280691, lng: 21.013358 },
    { lat: 49.280794, lng: 21.013191 },
    { lat: 49.28088, lng: 21.01313 },
    { lat: 49.280978, lng: 21.013166 },
    { lat: 49.281072, lng: 21.013363 },
    { lat: 49.281217, lng: 21.013604 },
    { lat: 49.281367, lng: 21.013667 },
    { lat: 49.281544, lng: 21.013653 },
    { lat: 49.28173, lng: 21.013835 },
    { lat: 49.28178, lng: 21.014089 },
    { lat: 49.28204, lng: 21.014559 },
    { lat: 49.282143, lng: 21.01467 },
    { lat: 49.282327, lng: 21.01477 },
    { lat: 49.282422, lng: 21.014947 },
    { lat: 49.282435, lng: 21.015343 },
    { lat: 49.282647, lng: 21.015384 },
    { lat: 49.28271, lng: 21.01564 },
    { lat: 49.283039, lng: 21.015622 },
    { lat: 49.283103, lng: 21.015408 },
    { lat: 49.28343, lng: 21.01556 },
    { lat: 49.283493, lng: 21.015565 },
    { lat: 49.283672, lng: 21.015431 },
    { lat: 49.28377, lng: 21.015407 },
    { lat: 49.283956, lng: 21.015411 },
    { lat: 49.283966, lng: 21.015353 },
    { lat: 49.283977, lng: 21.015214 },
    { lat: 49.283979, lng: 21.015159 },
    { lat: 49.284081, lng: 21.014332 },
    { lat: 49.284006, lng: 21.013648 },
    { lat: 49.28348, lng: 21.010852 },
    { lat: 49.283587, lng: 21.010558 },
    { lat: 49.283737, lng: 21.00959 },
    { lat: 49.283899, lng: 21.009065 },
    { lat: 49.284257, lng: 21.008574 },
    { lat: 49.284509, lng: 21.008241 },
    { lat: 49.284881, lng: 21.007512 },
    { lat: 49.28512, lng: 21.007175 },
    { lat: 49.285287, lng: 21.006833 },
    { lat: 49.285687, lng: 21.00633 },
    { lat: 49.285844, lng: 21.006104 },
    { lat: 49.286571, lng: 21.005734 },
    { lat: 49.288028, lng: 21.004716 },
    { lat: 49.28889, lng: 21.004078 },
    { lat: 49.288918, lng: 21.004024 },
    { lat: 49.289177, lng: 21.003454 },
    { lat: 49.289563, lng: 21.002676 },
    { lat: 49.289889, lng: 21.002234 },
    { lat: 49.290678, lng: 21.001518 },
    { lat: 49.290771, lng: 21.001437 },
    { lat: 49.290787, lng: 21.001423 },
    { lat: 49.290836, lng: 21.001387 },
    { lat: 49.291479, lng: 21.000841 },
    { lat: 49.291648, lng: 21.00087 },
    { lat: 49.291987, lng: 21.000591 },
    { lat: 49.292037, lng: 21.00054 },
    { lat: 49.292334, lng: 21.000463 },
    { lat: 49.292542, lng: 21.00036 },
    { lat: 49.292687, lng: 21.000358 },
    { lat: 49.293231, lng: 21.000121 },
    { lat: 49.294566, lng: 20.999679 },
    { lat: 49.294788, lng: 20.999553 },
    { lat: 49.295074, lng: 20.999131 },
    { lat: 49.295162, lng: 20.998886 },
    { lat: 49.295267, lng: 20.99882 },
    { lat: 49.295323, lng: 20.998786 },
    { lat: 49.295361, lng: 20.998672 },
    { lat: 49.295445, lng: 20.998486 },
    { lat: 49.29573, lng: 20.997471 },
    { lat: 49.295764, lng: 20.997153 },
    { lat: 49.295716, lng: 20.99629 },
    { lat: 49.296208, lng: 20.996091 },
    { lat: 49.296292, lng: 20.995972 },
    { lat: 49.296477, lng: 20.995785 },
    { lat: 49.296751, lng: 20.995573 },
    { lat: 49.296731, lng: 20.994961 },
    { lat: 49.296896, lng: 20.994371 },
    { lat: 49.296986, lng: 20.994237 },
    { lat: 49.297261, lng: 20.993549 },
    { lat: 49.297683, lng: 20.992534 },
    { lat: 49.298246, lng: 20.992113 },
    { lat: 49.29837, lng: 20.99203 },
    { lat: 49.298606, lng: 20.991829 },
    { lat: 49.298831, lng: 20.991631 },
    { lat: 49.299349, lng: 20.990999 },
    { lat: 49.299677, lng: 20.990647 },
    { lat: 49.299896, lng: 20.990434 },
    { lat: 49.300157, lng: 20.990395 },
    { lat: 49.300501, lng: 20.990149 },
    { lat: 49.300904, lng: 20.990004 },
    { lat: 49.301075, lng: 20.989988 },
    { lat: 49.301235, lng: 20.989982 },
    { lat: 49.301577, lng: 20.989918 },
    { lat: 49.301867, lng: 20.989783 },
    { lat: 49.302221, lng: 20.989545 },
    { lat: 49.303165, lng: 20.989034 },
    { lat: 49.303379, lng: 20.988903 },
    { lat: 49.303698, lng: 20.988708 },
    { lat: 49.303974, lng: 20.988539 },
    { lat: 49.304126, lng: 20.988417 },
    { lat: 49.304255, lng: 20.988326 },
    { lat: 49.304362, lng: 20.988277 },
    { lat: 49.304484, lng: 20.988318 },
    { lat: 49.304531, lng: 20.98825 },
    { lat: 49.305084, lng: 20.987929 },
    { lat: 49.305221, lng: 20.98785 },
    { lat: 49.305372, lng: 20.98794 },
    { lat: 49.305477, lng: 20.987786 },
    { lat: 49.305647, lng: 20.987495 },
    { lat: 49.305651, lng: 20.987419 },
    { lat: 49.305759, lng: 20.987224 },
    { lat: 49.30594, lng: 20.987037 },
    { lat: 49.306219, lng: 20.9867 },
    { lat: 49.30634, lng: 20.986453 },
    { lat: 49.3064944, lng: 20.9865237 },
    { lat: 49.306589, lng: 20.986567 },
    { lat: 49.306621, lng: 20.986545 },
    { lat: 49.30658, lng: 20.986036 },
    { lat: 49.306579, lng: 20.986001 },
    { lat: 49.306587, lng: 20.985828 },
    { lat: 49.306597, lng: 20.985784 },
    { lat: 49.306603, lng: 20.985765 },
    { lat: 49.30669, lng: 20.98559 },
    { lat: 49.306923, lng: 20.985127 },
    { lat: 49.306945, lng: 20.985088 },
    { lat: 49.30704, lng: 20.98495 },
    { lat: 49.307096, lng: 20.984887 },
    { lat: 49.307156, lng: 20.98483 },
    { lat: 49.307182, lng: 20.98481 },
    { lat: 49.30739, lng: 20.984707 },
    { lat: 49.307597, lng: 20.984664 },
    { lat: 49.307698, lng: 20.98463 },
    { lat: 49.307727, lng: 20.984617 },
    { lat: 49.307797, lng: 20.984578 },
    { lat: 49.307846, lng: 20.984549 },
    { lat: 49.307866, lng: 20.984533 },
    { lat: 49.307905, lng: 20.984496 },
    { lat: 49.307926, lng: 20.984474 },
    { lat: 49.307952, lng: 20.984434 },
    { lat: 49.307965, lng: 20.984416 },
    { lat: 49.307977, lng: 20.984343 },
    { lat: 49.307965, lng: 20.984308 },
    { lat: 49.307941, lng: 20.984229 },
    { lat: 49.307924, lng: 20.984179 },
    { lat: 49.307867, lng: 20.984027 },
    { lat: 49.307852, lng: 20.983984 },
    { lat: 49.307653, lng: 20.983418 },
    { lat: 49.307567, lng: 20.983109 },
    { lat: 49.307654, lng: 20.982642 },
    { lat: 49.3076138, lng: 20.9824093 },
    { lat: 49.307577, lng: 20.982196 },
    { lat: 49.307689, lng: 20.98191 },
    { lat: 49.307766, lng: 20.98175 },
    { lat: 49.307769, lng: 20.981744 },
    { lat: 49.307841, lng: 20.981589 },
    { lat: 49.307844, lng: 20.981582 },
    { lat: 49.307845, lng: 20.981244 },
    { lat: 49.307845, lng: 20.981236 },
    { lat: 49.30805, lng: 20.98092 },
    { lat: 49.308046, lng: 20.980902 },
    { lat: 49.30802, lng: 20.980718 },
    { lat: 49.308019, lng: 20.980714 },
    { lat: 49.308032, lng: 20.980468 },
    { lat: 49.308035, lng: 20.980452 },
    { lat: 49.308107, lng: 20.980363 },
    { lat: 49.308107, lng: 20.980347 },
    { lat: 49.308112, lng: 20.980264 },
    { lat: 49.308118, lng: 20.980255 },
    { lat: 49.308287, lng: 20.980036 },
    { lat: 49.30829, lng: 20.980032 },
    { lat: 49.30849, lng: 20.980001 },
    { lat: 49.308491, lng: 20.979994 },
    { lat: 49.308544, lng: 20.979721 },
    { lat: 49.308547, lng: 20.979711 },
    { lat: 49.30862, lng: 20.979522 },
    { lat: 49.308622, lng: 20.979516 },
    { lat: 49.308652, lng: 20.979395 },
    { lat: 49.308658, lng: 20.979391 },
    { lat: 49.308694, lng: 20.979358 },
    { lat: 49.308696, lng: 20.979353 },
    { lat: 49.308737, lng: 20.979193 },
    { lat: 49.30874, lng: 20.979183 },
    { lat: 49.308644, lng: 20.978971 },
    { lat: 49.308646, lng: 20.978965 },
    { lat: 49.308675, lng: 20.978903 },
    { lat: 49.308678, lng: 20.978896 },
    { lat: 49.308758, lng: 20.978823 },
    { lat: 49.308762, lng: 20.97882 },
    { lat: 49.308752, lng: 20.978611 },
    { lat: 49.308752, lng: 20.978431 },
    { lat: 49.308754, lng: 20.978423 },
    { lat: 49.308827, lng: 20.978104 },
    { lat: 49.308931, lng: 20.977705 },
    { lat: 49.308965, lng: 20.977519 },
    { lat: 49.308971, lng: 20.977509 },
    { lat: 49.309119, lng: 20.977345 },
    { lat: 49.309124, lng: 20.977316 },
    { lat: 49.309116, lng: 20.97727 },
    { lat: 49.309077, lng: 20.977148 },
    { lat: 49.309082, lng: 20.977128 },
    { lat: 49.309115, lng: 20.977082 },
    { lat: 49.309195, lng: 20.977073 },
    { lat: 49.309274, lng: 20.976983 },
    { lat: 49.3093, lng: 20.976591 },
    { lat: 49.30924, lng: 20.976425 },
    { lat: 49.30928, lng: 20.976175 },
    { lat: 49.309294, lng: 20.97596 },
    { lat: 49.309393, lng: 20.975878 },
    { lat: 49.309476, lng: 20.97573 },
    { lat: 49.309494, lng: 20.975429 },
    { lat: 49.309445, lng: 20.975319 },
    { lat: 49.309487, lng: 20.975089 },
    { lat: 49.309488, lng: 20.975076 },
    { lat: 49.309443, lng: 20.974806 },
    { lat: 49.309458, lng: 20.974613 },
    { lat: 49.309419, lng: 20.974424 },
    { lat: 49.30938, lng: 20.974316 },
    { lat: 49.309351, lng: 20.974031 },
    { lat: 49.309245, lng: 20.973875 },
    { lat: 49.309233, lng: 20.973857 },
    { lat: 49.309262, lng: 20.973801 },
    { lat: 49.309224, lng: 20.97369 },
    { lat: 49.309118, lng: 20.973578 },
    { lat: 49.309113, lng: 20.973572 },
    { lat: 49.309085, lng: 20.973444 },
    { lat: 49.309118, lng: 20.973404 },
    { lat: 49.309104, lng: 20.973301 },
    { lat: 49.309102, lng: 20.973231 },
    { lat: 49.309092, lng: 20.973066 },
    { lat: 49.308984, lng: 20.972737 },
    { lat: 49.309004, lng: 20.972596 },
    { lat: 49.309067, lng: 20.972144 },
    { lat: 49.309171, lng: 20.971909 },
    { lat: 49.309316, lng: 20.971577 },
    { lat: 49.309423, lng: 20.971306 },
    { lat: 49.309512, lng: 20.971156 },
    { lat: 49.309454, lng: 20.970978 },
    { lat: 49.309489, lng: 20.970872 },
    { lat: 49.309484, lng: 20.970525 },
    { lat: 49.309614, lng: 20.970252 },
    { lat: 49.309645, lng: 20.970211 },
    { lat: 49.309665, lng: 20.970196 },
    { lat: 49.309708, lng: 20.970064 },
    { lat: 49.309715, lng: 20.970062 },
    { lat: 49.309769, lng: 20.970046 },
    { lat: 49.309809, lng: 20.97011 },
    { lat: 49.309844, lng: 20.970097 },
    { lat: 49.309853, lng: 20.970095 },
    { lat: 49.309885, lng: 20.96999 },
    { lat: 49.309897, lng: 20.969884 },
    { lat: 49.309861, lng: 20.96974 },
    { lat: 49.309864, lng: 20.969727 },
    { lat: 49.309887, lng: 20.969615 },
    { lat: 49.309888, lng: 20.969607 },
    { lat: 49.309849, lng: 20.969423 },
    { lat: 49.309836, lng: 20.969422 },
    { lat: 49.309622, lng: 20.969414 },
    { lat: 49.309469, lng: 20.969444 },
    { lat: 49.30947, lng: 20.969354 },
    { lat: 49.309468, lng: 20.969333 },
    { lat: 49.309511, lng: 20.96929 },
    { lat: 49.309505, lng: 20.969271 },
    { lat: 49.309424, lng: 20.968995 },
    { lat: 49.30942, lng: 20.968984 },
    { lat: 49.309504, lng: 20.968606 },
    { lat: 49.309506, lng: 20.968596 },
    { lat: 49.309548, lng: 20.968261 },
    { lat: 49.309548, lng: 20.968234 },
    { lat: 49.309528, lng: 20.967818 },
    { lat: 49.309525, lng: 20.967802 },
    { lat: 49.309336, lng: 20.967525 },
    { lat: 49.309319, lng: 20.967498 },
    { lat: 49.309186, lng: 20.967259 },
    { lat: 49.308963, lng: 20.966874 },
    { lat: 49.308962, lng: 20.966866 },
    { lat: 49.308905, lng: 20.966612 },
    { lat: 49.308835, lng: 20.966411 },
    { lat: 49.30883, lng: 20.966392 },
    { lat: 49.3087969, lng: 20.9662132 },
    { lat: 49.308731, lng: 20.965857 },
    { lat: 49.308729, lng: 20.965839 },
    { lat: 49.308684, lng: 20.965515 },
    { lat: 49.308777, lng: 20.964949 },
    { lat: 49.308785, lng: 20.964333 },
    { lat: 49.308784, lng: 20.964325 },
    { lat: 49.308804, lng: 20.963963 },
    { lat: 49.308803, lng: 20.963934 },
    { lat: 49.308781, lng: 20.963542 },
    { lat: 49.308779, lng: 20.96352 },
    { lat: 49.308746, lng: 20.963329 },
    { lat: 49.308742, lng: 20.963324 },
    { lat: 49.30859, lng: 20.963129 },
    { lat: 49.30858, lng: 20.963107 },
    { lat: 49.308477, lng: 20.962738 },
    { lat: 49.30846, lng: 20.962735 },
    { lat: 49.308301, lng: 20.962718 },
    { lat: 49.308094, lng: 20.962703 },
    { lat: 49.308089, lng: 20.962703 },
    { lat: 49.307751, lng: 20.962496 },
    { lat: 49.30774, lng: 20.962492 },
  ],
  MníšeknadPopradom: [
    { lat: 49.419073, lng: 20.710963 },
    { lat: 49.419157, lng: 20.710482 },
    { lat: 49.418995, lng: 20.710415 },
    { lat: 49.419037, lng: 20.709713 },
    { lat: 49.418843, lng: 20.709105 },
    { lat: 49.418692, lng: 20.708176 },
    { lat: 49.418766, lng: 20.707433 },
    { lat: 49.419024, lng: 20.706785 },
    { lat: 49.41922, lng: 20.706331 },
    { lat: 49.419275, lng: 20.705935 },
    { lat: 49.419215, lng: 20.704929 },
    { lat: 49.419698, lng: 20.704174 },
    { lat: 49.419752, lng: 20.702673 },
    { lat: 49.419755, lng: 20.701198 },
    { lat: 49.419938, lng: 20.700619 },
    { lat: 49.420289, lng: 20.699913 },
    { lat: 49.420521, lng: 20.698861 },
    { lat: 49.420207, lng: 20.696818 },
    { lat: 49.4200004, lng: 20.6950613 },
    { lat: 49.419953, lng: 20.693138 },
    { lat: 49.419967, lng: 20.69269 },
    { lat: 49.419776, lng: 20.6917211 },
    { lat: 49.4195513, lng: 20.6911992 },
    { lat: 49.4188907, lng: 20.6902084 },
    { lat: 49.4187666, lng: 20.6899655 },
    { lat: 49.4186293, lng: 20.6894683 },
    { lat: 49.4185934, lng: 20.6890966 },
    { lat: 49.418502, lng: 20.688829 },
    { lat: 49.41798, lng: 20.687826 },
    { lat: 49.417755, lng: 20.687733 },
    { lat: 49.417439, lng: 20.687593 },
    { lat: 49.4169931, lng: 20.6870351 },
    { lat: 49.4164878, lng: 20.6862584 },
    { lat: 49.416357, lng: 20.686144 },
    { lat: 49.416009, lng: 20.685946 },
    { lat: 49.4157648, lng: 20.6857254 },
    { lat: 49.415639, lng: 20.685566 },
    { lat: 49.415412, lng: 20.685181 },
    { lat: 49.4150541, lng: 20.6845876 },
    { lat: 49.4147615, lng: 20.6839617 },
    { lat: 49.414619, lng: 20.6836473 },
    { lat: 49.414295, lng: 20.6828618 },
    { lat: 49.4142265, lng: 20.6826019 },
    { lat: 49.4142052, lng: 20.6824556 },
    { lat: 49.4141487, lng: 20.6818834 },
    { lat: 49.414058, lng: 20.681548 },
    { lat: 49.413193, lng: 20.679666 },
    { lat: 49.413079, lng: 20.679563 },
    { lat: 49.412792, lng: 20.679215 },
    { lat: 49.412826, lng: 20.679114 },
    { lat: 49.412539, lng: 20.678614 },
    { lat: 49.412709, lng: 20.678317 },
    { lat: 49.412451, lng: 20.677397 },
    { lat: 49.412274, lng: 20.676439 },
    { lat: 49.411677, lng: 20.6752 },
    { lat: 49.4115908, lng: 20.6750866 },
    { lat: 49.411174, lng: 20.6742304 },
    { lat: 49.411, lng: 20.673759 },
    { lat: 49.410573, lng: 20.672848 },
    { lat: 49.409856, lng: 20.671722 },
    { lat: 49.408977, lng: 20.670851 },
    { lat: 49.408156, lng: 20.670299 },
    { lat: 49.407777, lng: 20.669588 },
    { lat: 49.407343, lng: 20.66809 },
    { lat: 49.406886, lng: 20.667417 },
    { lat: 49.406707, lng: 20.667384 },
    { lat: 49.406311, lng: 20.665767 },
    { lat: 49.405164, lng: 20.665399 },
    { lat: 49.404375, lng: 20.665326 },
    { lat: 49.403873, lng: 20.665611 },
    { lat: 49.403552, lng: 20.6657 },
    { lat: 49.4033841, lng: 20.6673681 },
    { lat: 49.4018835, lng: 20.6687903 },
    { lat: 49.4008317, lng: 20.6704876 },
    { lat: 49.3997617, lng: 20.6720552 },
    { lat: 49.4001398, lng: 20.6758015 },
    { lat: 49.4000868, lng: 20.6776089 },
    { lat: 49.399213, lng: 20.6791907 },
    { lat: 49.3981316, lng: 20.6802777 },
    { lat: 49.3974665, lng: 20.6806307 },
    { lat: 49.3969219, lng: 20.6807323 },
    { lat: 49.3964879, lng: 20.6809715 },
    { lat: 49.396363, lng: 20.6812276 },
    { lat: 49.3962727, lng: 20.6816022 },
    { lat: 49.3961376, lng: 20.6833824 },
    { lat: 49.3960143, lng: 20.6841909 },
    { lat: 49.3957709, lng: 20.6853263 },
    { lat: 49.3951595, lng: 20.6864062 },
    { lat: 49.3949658, lng: 20.6866586 },
    { lat: 49.3948595, lng: 20.687088 },
    { lat: 49.3946201, lng: 20.6876416 },
    { lat: 49.3945121, lng: 20.6881821 },
    { lat: 49.3943901, lng: 20.6884286 },
    { lat: 49.3942632, lng: 20.6889428 },
    { lat: 49.3943272, lng: 20.6897054 },
    { lat: 49.3942561, lng: 20.6901774 },
    { lat: 49.3933592, lng: 20.6912361 },
    { lat: 49.3923524, lng: 20.6931023 },
    { lat: 49.3915258, lng: 20.6938208 },
    { lat: 49.3910742, lng: 20.6941039 },
    { lat: 49.3909806, lng: 20.6942924 },
    { lat: 49.3906974, lng: 20.6946211 },
    { lat: 49.3904017, lng: 20.694823 },
    { lat: 49.3891501, lng: 20.6964803 },
    { lat: 49.3888046, lng: 20.6968015 },
    { lat: 49.3884366, lng: 20.6975347 },
    { lat: 49.3882341, lng: 20.6984024 },
    { lat: 49.3881655, lng: 20.6992073 },
    { lat: 49.3884603, lng: 20.6997754 },
    { lat: 49.3890736, lng: 20.7007006 },
    { lat: 49.3895052, lng: 20.7019596 },
    { lat: 49.3898389, lng: 20.7025549 },
    { lat: 49.3899872, lng: 20.7032508 },
    { lat: 49.3901475, lng: 20.7036759 },
    { lat: 49.3901831, lng: 20.7040523 },
    { lat: 49.390283, lng: 20.7043635 },
    { lat: 49.3902539, lng: 20.7045542 },
    { lat: 49.3903801, lng: 20.7051656 },
    { lat: 49.3903399, lng: 20.7062369 },
    { lat: 49.3903809, lng: 20.7066971 },
    { lat: 49.3909543, lng: 20.7083741 },
    { lat: 49.3910068, lng: 20.7089081 },
    { lat: 49.3911522, lng: 20.7093297 },
    { lat: 49.3913699, lng: 20.7095043 },
    { lat: 49.3915035, lng: 20.709721 },
    { lat: 49.3916094, lng: 20.7103586 },
    { lat: 49.3916294, lng: 20.7109615 },
    { lat: 49.3915749, lng: 20.7113635 },
    { lat: 49.3915705, lng: 20.7118495 },
    { lat: 49.3916715, lng: 20.7120337 },
    { lat: 49.3916431, lng: 20.7129582 },
    { lat: 49.391778, lng: 20.7135613 },
    { lat: 49.3917936, lng: 20.7140399 },
    { lat: 49.3923267, lng: 20.7153726 },
    { lat: 49.3919294, lng: 20.7161074 },
    { lat: 49.3918593, lng: 20.7169002 },
    { lat: 49.3919372, lng: 20.71768 },
    { lat: 49.3919207, lng: 20.7179444 },
    { lat: 49.3919232, lng: 20.7179872 },
    { lat: 49.3916519, lng: 20.7178863 },
    { lat: 49.391388, lng: 20.7177447 },
    { lat: 49.391141, lng: 20.7174749 },
    { lat: 49.3907974, lng: 20.717307 },
    { lat: 49.3904522, lng: 20.7173163 },
    { lat: 49.3901936, lng: 20.7176758 },
    { lat: 49.3895297, lng: 20.7182233 },
    { lat: 49.3888771, lng: 20.7186452 },
    { lat: 49.3884474, lng: 20.7186885 },
    { lat: 49.3881966, lng: 20.7188536 },
    { lat: 49.3877576, lng: 20.7193692 },
    { lat: 49.3871457, lng: 20.7197299 },
    { lat: 49.387129, lng: 20.7196709 },
    { lat: 49.3866779, lng: 20.7198425 },
    { lat: 49.3863485, lng: 20.71985 },
    { lat: 49.3859086, lng: 20.7197894 },
    { lat: 49.385501, lng: 20.7196271 },
    { lat: 49.3849276, lng: 20.7196118 },
    { lat: 49.3845355, lng: 20.7194403 },
    { lat: 49.383994, lng: 20.7194336 },
    { lat: 49.383739, lng: 20.7198722 },
    { lat: 49.3834822, lng: 20.7201823 },
    { lat: 49.382799, lng: 20.7206673 },
    { lat: 49.3825446, lng: 20.7209198 },
    { lat: 49.3822469, lng: 20.7213222 },
    { lat: 49.3819174, lng: 20.721906 },
    { lat: 49.3817643, lng: 20.72223 },
    { lat: 49.3817577, lng: 20.7226539 },
    { lat: 49.3814965, lng: 20.7232891 },
    { lat: 49.3813404, lng: 20.7239103 },
    { lat: 49.3813136, lng: 20.7242423 },
    { lat: 49.3810459, lng: 20.724497 },
    { lat: 49.3806724, lng: 20.725472 },
    { lat: 49.3805421, lng: 20.7257034 },
    { lat: 49.3804272, lng: 20.7262822 },
    { lat: 49.3803066, lng: 20.7276872 },
    { lat: 49.3803645, lng: 20.7279949 },
    { lat: 49.3806809, lng: 20.7285433 },
    { lat: 49.3810261, lng: 20.7297201 },
    { lat: 49.3806499, lng: 20.7313575 },
    { lat: 49.3806259, lng: 20.7320559 },
    { lat: 49.3803009, lng: 20.7320627 },
    { lat: 49.3801699, lng: 20.732146 },
    { lat: 49.3799655, lng: 20.7324785 },
    { lat: 49.3795618, lng: 20.7328808 },
    { lat: 49.3794618, lng: 20.733204 },
    { lat: 49.3791971, lng: 20.733492 },
    { lat: 49.3793297, lng: 20.7337441 },
    { lat: 49.3792675, lng: 20.7339554 },
    { lat: 49.3793379, lng: 20.7341678 },
    { lat: 49.3792404, lng: 20.734407 },
    { lat: 49.3789937, lng: 20.7346241 },
    { lat: 49.3788284, lng: 20.7350487 },
    { lat: 49.37893, lng: 20.7355393 },
    { lat: 49.379056, lng: 20.7358942 },
    { lat: 49.3793583, lng: 20.7360735 },
    { lat: 49.3795843, lng: 20.736582 },
    { lat: 49.3797184, lng: 20.7367477 },
    { lat: 49.3796878, lng: 20.7372909 },
    { lat: 49.3798038, lng: 20.7378414 },
    { lat: 49.3800617, lng: 20.7384535 },
    { lat: 49.3801596, lng: 20.7394877 },
    { lat: 49.3811956, lng: 20.7398573 },
    { lat: 49.3818045, lng: 20.7405661 },
    { lat: 49.3824645, lng: 20.7421287 },
    { lat: 49.3833788, lng: 20.7449808 },
    { lat: 49.3846034, lng: 20.7497976 },
    { lat: 49.3846678, lng: 20.7504487 },
    { lat: 49.3848191, lng: 20.7510737 },
    { lat: 49.3849785, lng: 20.7514523 },
    { lat: 49.3851878, lng: 20.7516107 },
    { lat: 49.3859851, lng: 20.7526942 },
    { lat: 49.3864499, lng: 20.752985 },
    { lat: 49.3865978, lng: 20.7531933 },
    { lat: 49.3866893, lng: 20.7534308 },
    { lat: 49.386911, lng: 20.753648 },
    { lat: 49.386974, lng: 20.753506 },
    { lat: 49.387019, lng: 20.753404 },
    { lat: 49.387174, lng: 20.753001 },
    { lat: 49.387222, lng: 20.752893 },
    { lat: 49.387313, lng: 20.752698 },
    { lat: 49.387406, lng: 20.752508 },
    { lat: 49.387455, lng: 20.752404 },
    { lat: 49.387461, lng: 20.752391 },
    { lat: 49.387536, lng: 20.752238 },
    { lat: 49.387576, lng: 20.75213 },
    { lat: 49.387618, lng: 20.752004 },
    { lat: 49.387717, lng: 20.751744 },
    { lat: 49.387793, lng: 20.751555 },
    { lat: 49.387813, lng: 20.751509 },
    { lat: 49.387883, lng: 20.751345 },
    { lat: 49.388019, lng: 20.751017 },
    { lat: 49.388232, lng: 20.750552 },
    { lat: 49.388504, lng: 20.749968 },
    { lat: 49.388564, lng: 20.74984 },
    { lat: 49.38871, lng: 20.749502 },
    { lat: 49.388752, lng: 20.749411 },
    { lat: 49.388883, lng: 20.749139 },
    { lat: 49.388916, lng: 20.749077 },
    { lat: 49.38905, lng: 20.748852 },
    { lat: 49.389186, lng: 20.748657 },
    { lat: 49.389341, lng: 20.748493 },
    { lat: 49.389436, lng: 20.748418 },
    { lat: 49.389528, lng: 20.748328 },
    { lat: 49.389624, lng: 20.748249 },
    { lat: 49.389697, lng: 20.7482 },
    { lat: 49.389701, lng: 20.748196 },
    { lat: 49.389896, lng: 20.748045 },
    { lat: 49.390006, lng: 20.747965 },
    { lat: 49.390069, lng: 20.74792 },
    { lat: 49.39021, lng: 20.747805 },
    { lat: 49.390249, lng: 20.747788 },
    { lat: 49.390407, lng: 20.747702 },
    { lat: 49.390483, lng: 20.747668 },
    { lat: 49.390636, lng: 20.747614 },
    { lat: 49.390757, lng: 20.747577 },
    { lat: 49.390775, lng: 20.747571 },
    { lat: 49.390963, lng: 20.747539 },
    { lat: 49.391002, lng: 20.747534 },
    { lat: 49.391256, lng: 20.74753 },
    { lat: 49.391324, lng: 20.747533 },
    { lat: 49.391438, lng: 20.747539 },
    { lat: 49.391628, lng: 20.747546 },
    { lat: 49.39189, lng: 20.747545 },
    { lat: 49.391913, lng: 20.747546 },
    { lat: 49.392075, lng: 20.747539 },
    { lat: 49.392158, lng: 20.747538 },
    { lat: 49.392295, lng: 20.74754 },
    { lat: 49.392341, lng: 20.747537 },
    { lat: 49.392539, lng: 20.747591 },
    { lat: 49.392555, lng: 20.747598 },
    { lat: 49.392765, lng: 20.747681 },
    { lat: 49.392911, lng: 20.747734 },
    { lat: 49.392981, lng: 20.747762 },
    { lat: 49.393203, lng: 20.747872 },
    { lat: 49.39347, lng: 20.748006 },
    { lat: 49.393645, lng: 20.748098 },
    { lat: 49.393812, lng: 20.748272 },
    { lat: 49.393899, lng: 20.74835 },
    { lat: 49.393907, lng: 20.748356 },
    { lat: 49.394078, lng: 20.748573 },
    { lat: 49.394092, lng: 20.748596 },
    { lat: 49.394265, lng: 20.748862 },
    { lat: 49.394277, lng: 20.74888 },
    { lat: 49.394394, lng: 20.749091 },
    { lat: 49.394421, lng: 20.749138 },
    { lat: 49.394668, lng: 20.749535 },
    { lat: 49.394716, lng: 20.749605 },
    { lat: 49.394847, lng: 20.749806 },
    { lat: 49.395008, lng: 20.750043 },
    { lat: 49.395047, lng: 20.750103 },
    { lat: 49.395248, lng: 20.750359 },
    { lat: 49.395324, lng: 20.75047 },
    { lat: 49.395389, lng: 20.750571 },
    { lat: 49.395419, lng: 20.750617 },
    { lat: 49.395497, lng: 20.750764 },
    { lat: 49.395581, lng: 20.750932 },
    { lat: 49.395639, lng: 20.751062 },
    { lat: 49.395684, lng: 20.751175 },
    { lat: 49.395819, lng: 20.75152 },
    { lat: 49.395839, lng: 20.751576 },
    { lat: 49.395913, lng: 20.751852 },
    { lat: 49.396056, lng: 20.752348 },
    { lat: 49.396061, lng: 20.752365 },
    { lat: 49.396181, lng: 20.752791 },
    { lat: 49.396197, lng: 20.752851 },
    { lat: 49.396212, lng: 20.752906 },
    { lat: 49.396275, lng: 20.753154 },
    { lat: 49.396286, lng: 20.7532 },
    { lat: 49.396297, lng: 20.75324 },
    { lat: 49.396369, lng: 20.753517 },
    { lat: 49.396381, lng: 20.753561 },
    { lat: 49.396433, lng: 20.753717 },
    { lat: 49.396556, lng: 20.754122 },
    { lat: 49.396615, lng: 20.754259 },
    { lat: 49.396652, lng: 20.754375 },
    { lat: 49.396671, lng: 20.754424 },
    { lat: 49.396706, lng: 20.7545 },
    { lat: 49.396753, lng: 20.754644 },
    { lat: 49.396797, lng: 20.754748 },
    { lat: 49.396861, lng: 20.754962 },
    { lat: 49.396877, lng: 20.75501 },
    { lat: 49.396974, lng: 20.75529 },
    { lat: 49.396985, lng: 20.755314 },
    { lat: 49.397049, lng: 20.755478 },
    { lat: 49.397114, lng: 20.755669 },
    { lat: 49.397135, lng: 20.755742 },
    { lat: 49.397248, lng: 20.756063 },
    { lat: 49.397374, lng: 20.756292 },
    { lat: 49.397623, lng: 20.756701 },
    { lat: 49.397767, lng: 20.756943 },
    { lat: 49.397923, lng: 20.757223 },
    { lat: 49.398221, lng: 20.757725 },
    { lat: 49.398498, lng: 20.758058 },
    { lat: 49.398529, lng: 20.75807 },
    { lat: 49.398698, lng: 20.75819 },
    { lat: 49.398874, lng: 20.758269 },
    { lat: 49.399053, lng: 20.75831 },
    { lat: 49.39912, lng: 20.758317 },
    { lat: 49.399384, lng: 20.758322 },
    { lat: 49.399498, lng: 20.758313 },
    { lat: 49.399928, lng: 20.758243 },
    { lat: 49.4, lng: 20.758222 },
    { lat: 49.400157, lng: 20.758162 },
    { lat: 49.400293, lng: 20.758091 },
    { lat: 49.400395, lng: 20.758027 },
    { lat: 49.400577, lng: 20.757862 },
    { lat: 49.400681, lng: 20.757778 },
    { lat: 49.400777, lng: 20.757689 },
    { lat: 49.400886, lng: 20.757567 },
    { lat: 49.401017, lng: 20.757412 },
    { lat: 49.401152, lng: 20.7572 },
    { lat: 49.401142, lng: 20.757164 },
    { lat: 49.401251, lng: 20.756977 },
    { lat: 49.401375, lng: 20.756729 },
    { lat: 49.401808, lng: 20.755823 },
    { lat: 49.401943, lng: 20.755584 },
    { lat: 49.402023, lng: 20.755377 },
    { lat: 49.402074, lng: 20.755214 },
    { lat: 49.4020838, lng: 20.7551839 },
    { lat: 49.402159, lng: 20.754954 },
    { lat: 49.402193, lng: 20.754809 },
    { lat: 49.402206, lng: 20.75463 },
    { lat: 49.402181, lng: 20.754541 },
    { lat: 49.402182, lng: 20.754435 },
    { lat: 49.402165, lng: 20.754009 },
    { lat: 49.402161, lng: 20.753764 },
    { lat: 49.402159, lng: 20.75363 },
    { lat: 49.402142, lng: 20.753204 },
    { lat: 49.402129, lng: 20.752927 },
    { lat: 49.402073, lng: 20.752427 },
    { lat: 49.402006, lng: 20.751991 },
    { lat: 49.401942, lng: 20.751615 },
    { lat: 49.401896, lng: 20.751295 },
    { lat: 49.40188, lng: 20.751087 },
    { lat: 49.40187, lng: 20.750853 },
    { lat: 49.401875, lng: 20.750531 },
    { lat: 49.401893, lng: 20.750235 },
    { lat: 49.401934, lng: 20.749664 },
    { lat: 49.401963, lng: 20.749331 },
    { lat: 49.402036, lng: 20.748913 },
    { lat: 49.402127, lng: 20.748487 },
    { lat: 49.402239, lng: 20.748076 },
    { lat: 49.402402, lng: 20.747526 },
    { lat: 49.402562, lng: 20.747066 },
    { lat: 49.402653, lng: 20.74678 },
    { lat: 49.402752, lng: 20.746466 },
    { lat: 49.402782, lng: 20.746388 },
    { lat: 49.402836, lng: 20.746217 },
    { lat: 49.402889, lng: 20.746115 },
    { lat: 49.402943, lng: 20.74604 },
    { lat: 49.403046, lng: 20.745816 },
    { lat: 49.403157, lng: 20.745311 },
    { lat: 49.403188, lng: 20.745145 },
    { lat: 49.403283, lng: 20.744818 },
    { lat: 49.403337, lng: 20.744607 },
    { lat: 49.403407, lng: 20.74428 },
    { lat: 49.403494, lng: 20.74372 },
    { lat: 49.403599, lng: 20.743122 },
    { lat: 49.40364, lng: 20.742913 },
    { lat: 49.403678, lng: 20.742785 },
    { lat: 49.40369, lng: 20.74266 },
    { lat: 49.403792, lng: 20.742386 },
    { lat: 49.4038, lng: 20.742356 },
    { lat: 49.403833, lng: 20.742269 },
    { lat: 49.40404, lng: 20.742274 },
    { lat: 49.404144, lng: 20.742257 },
    { lat: 49.40425, lng: 20.742261 },
    { lat: 49.404412, lng: 20.742283 },
    { lat: 49.404675, lng: 20.742295 },
    { lat: 49.404836, lng: 20.74233 },
    { lat: 49.404934, lng: 20.74238 },
    { lat: 49.405081, lng: 20.742372 },
    { lat: 49.40516, lng: 20.742341 },
    { lat: 49.405382, lng: 20.742391 },
    { lat: 49.405589, lng: 20.742362 },
    { lat: 49.405724, lng: 20.742392 },
    { lat: 49.40591, lng: 20.742391 },
    { lat: 49.406032, lng: 20.742403 },
    { lat: 49.406257, lng: 20.742495 },
    { lat: 49.406376, lng: 20.742545 },
    { lat: 49.406547, lng: 20.742583 },
    { lat: 49.406613, lng: 20.742606 },
    { lat: 49.40683, lng: 20.742684 },
    { lat: 49.406844, lng: 20.742689 },
    { lat: 49.406956, lng: 20.742723 },
    { lat: 49.406969, lng: 20.742725 },
    { lat: 49.407105, lng: 20.742767 },
    { lat: 49.407177, lng: 20.742789 },
    { lat: 49.407243, lng: 20.742805 },
    { lat: 49.40738, lng: 20.742865 },
    { lat: 49.407533, lng: 20.742922 },
    { lat: 49.407663, lng: 20.742984 },
    { lat: 49.407669, lng: 20.742987 },
    { lat: 49.407833, lng: 20.743044 },
    { lat: 49.407913, lng: 20.743083 },
    { lat: 49.40795, lng: 20.7431 },
    { lat: 49.407996, lng: 20.743137 },
    { lat: 49.408138, lng: 20.743237 },
    { lat: 49.408206, lng: 20.743332 },
    { lat: 49.408261, lng: 20.743371 },
    { lat: 49.408342, lng: 20.743434 },
    { lat: 49.408452, lng: 20.743502 },
    { lat: 49.408507, lng: 20.743544 },
    { lat: 49.40866, lng: 20.74365 },
    { lat: 49.408696, lng: 20.743675 },
    { lat: 49.408828, lng: 20.743768 },
    { lat: 49.409026, lng: 20.743945 },
    { lat: 49.409202, lng: 20.7441 },
    { lat: 49.40921, lng: 20.74411 },
    { lat: 49.40932, lng: 20.744233 },
    { lat: 49.409384, lng: 20.744302 },
    { lat: 49.409453, lng: 20.744368 },
    { lat: 49.409509, lng: 20.744413 },
    { lat: 49.409629, lng: 20.744532 },
    { lat: 49.409663, lng: 20.744566 },
    { lat: 49.409836, lng: 20.744723 },
    { lat: 49.409854, lng: 20.744743 },
    { lat: 49.409937, lng: 20.744841 },
    { lat: 49.409954, lng: 20.744858 },
    { lat: 49.410143, lng: 20.745058 },
    { lat: 49.410434, lng: 20.74538 },
    { lat: 49.410597, lng: 20.745523 },
    { lat: 49.410894, lng: 20.745755 },
    { lat: 49.411177, lng: 20.74591 },
    { lat: 49.411337, lng: 20.745984 },
    { lat: 49.411643, lng: 20.746107 },
    { lat: 49.411798, lng: 20.746114 },
    { lat: 49.41206, lng: 20.746056 },
    { lat: 49.412201, lng: 20.746012 },
    { lat: 49.412652, lng: 20.745784 },
    { lat: 49.412775, lng: 20.745734 },
    { lat: 49.413068, lng: 20.745577 },
    { lat: 49.413357, lng: 20.745393 },
    { lat: 49.413709, lng: 20.74512 },
    { lat: 49.413993, lng: 20.744859 },
    { lat: 49.414176, lng: 20.744662 },
    { lat: 49.414533, lng: 20.744143 },
    { lat: 49.414585, lng: 20.744064 },
    { lat: 49.414645, lng: 20.743975 },
    { lat: 49.414717, lng: 20.743862 },
    { lat: 49.414758, lng: 20.743789 },
    { lat: 49.414855, lng: 20.743615 },
    { lat: 49.414882, lng: 20.743565 },
    { lat: 49.41499, lng: 20.743377 },
    { lat: 49.415015, lng: 20.743327 },
    { lat: 49.415142, lng: 20.743119 },
    { lat: 49.415181, lng: 20.743042 },
    { lat: 49.415363, lng: 20.742694 },
    { lat: 49.415409, lng: 20.742604 },
    { lat: 49.415534, lng: 20.742352 },
    { lat: 49.415768, lng: 20.741794 },
    { lat: 49.415947, lng: 20.741322 },
    { lat: 49.416026, lng: 20.741078 },
    { lat: 49.416066, lng: 20.740922 },
    { lat: 49.416092, lng: 20.740825 },
    { lat: 49.416173, lng: 20.740539 },
    { lat: 49.416244, lng: 20.740285 },
    { lat: 49.416301, lng: 20.740105 },
    { lat: 49.416391, lng: 20.739721 },
    { lat: 49.416434, lng: 20.739456 },
    { lat: 49.416483, lng: 20.739135 },
    { lat: 49.416519, lng: 20.738906 },
    { lat: 49.416544, lng: 20.738683 },
    { lat: 49.416616, lng: 20.738298 },
    { lat: 49.416632, lng: 20.738007 },
    { lat: 49.416658, lng: 20.737814 },
    { lat: 49.416674, lng: 20.737484 },
    { lat: 49.416681, lng: 20.736897 },
    { lat: 49.416671, lng: 20.736621 },
    { lat: 49.416653, lng: 20.736298 },
    { lat: 49.416581, lng: 20.735758 },
    { lat: 49.416518, lng: 20.735386 },
    { lat: 49.416503, lng: 20.735304 },
    { lat: 49.416285, lng: 20.734408 },
    { lat: 49.41614, lng: 20.733976 },
    { lat: 49.4159825, lng: 20.7335674 },
    { lat: 49.4158723, lng: 20.7333741 },
    { lat: 49.4157659, lng: 20.7332585 },
    { lat: 49.415645, lng: 20.733144 },
    { lat: 49.415285, lng: 20.732751 },
    { lat: 49.415137, lng: 20.732552 },
    { lat: 49.415051, lng: 20.732408 },
    { lat: 49.415018, lng: 20.732307 },
    { lat: 49.414973, lng: 20.732215 },
    { lat: 49.414917, lng: 20.732012 },
    { lat: 49.414925, lng: 20.731807 },
    { lat: 49.414949, lng: 20.731553 },
    { lat: 49.414983, lng: 20.731457 },
    { lat: 49.41497, lng: 20.731303 },
    { lat: 49.414964, lng: 20.730986 },
    { lat: 49.414947, lng: 20.730807 },
    { lat: 49.414907, lng: 20.73064 },
    { lat: 49.414891, lng: 20.730434 },
    { lat: 49.414805, lng: 20.72994 },
    { lat: 49.414775, lng: 20.729697 },
    { lat: 49.414754, lng: 20.729154 },
    { lat: 49.414768, lng: 20.728652 },
    { lat: 49.414765, lng: 20.72857 },
    { lat: 49.414822, lng: 20.728137 },
    { lat: 49.414852, lng: 20.728093 },
    { lat: 49.414859, lng: 20.728008 },
    { lat: 49.414897, lng: 20.727861 },
    { lat: 49.414953, lng: 20.72773 },
    { lat: 49.414985, lng: 20.727707 },
    { lat: 49.415016, lng: 20.727618 },
    { lat: 49.415179, lng: 20.727404 },
    { lat: 49.415461, lng: 20.727015 },
    { lat: 49.41561, lng: 20.726848 },
    { lat: 49.415797, lng: 20.726658 },
    { lat: 49.416041, lng: 20.726316 },
    { lat: 49.416348, lng: 20.725862 },
    { lat: 49.416429, lng: 20.725701 },
    { lat: 49.416616, lng: 20.725362 },
    { lat: 49.416756, lng: 20.725142 },
    { lat: 49.416817, lng: 20.725075 },
    { lat: 49.416933, lng: 20.724945 },
    { lat: 49.417188, lng: 20.724727 },
    { lat: 49.417264, lng: 20.72468 },
    { lat: 49.417352, lng: 20.724591 },
    { lat: 49.417464, lng: 20.724508 },
    { lat: 49.417663, lng: 20.724371 },
    { lat: 49.417876, lng: 20.724215 },
    { lat: 49.418014, lng: 20.724112 },
    { lat: 49.418122, lng: 20.724046 },
    { lat: 49.418279, lng: 20.723921 },
    { lat: 49.41842, lng: 20.723858 },
    { lat: 49.418599, lng: 20.723748 },
    { lat: 49.418681, lng: 20.723683 },
    { lat: 49.418927, lng: 20.723514 },
    { lat: 49.41922, lng: 20.723313 },
    { lat: 49.419306, lng: 20.723252 },
    { lat: 49.419466, lng: 20.723109 },
    { lat: 49.419612, lng: 20.722959 },
    { lat: 49.419656, lng: 20.72292 },
    { lat: 49.419356, lng: 20.722035 },
    { lat: 49.418925, lng: 20.720216 },
    { lat: 49.418906, lng: 20.7201568 },
    { lat: 49.4189, lng: 20.720138 },
    { lat: 49.418855, lng: 20.719998 },
    { lat: 49.418499, lng: 20.718861 },
    { lat: 49.418371, lng: 20.71833 },
    { lat: 49.418397, lng: 20.717873 },
    { lat: 49.418458, lng: 20.717498 },
    { lat: 49.418589, lng: 20.716939 },
    { lat: 49.418763, lng: 20.716441 },
    { lat: 49.418792, lng: 20.715978 },
    { lat: 49.418838, lng: 20.715303 },
    { lat: 49.419017, lng: 20.714549 },
    { lat: 49.419161, lng: 20.712675 },
    { lat: 49.419264, lng: 20.712355 },
    { lat: 49.419442, lng: 20.711534 },
    { lat: 49.419455, lng: 20.711107 },
    { lat: 49.419235, lng: 20.711056 },
    { lat: 49.419073, lng: 20.710963 },
  ],
  Plavnica: [
    { lat: 49.2407785, lng: 20.7657797 },
    { lat: 49.2408653, lng: 20.7662683 },
    { lat: 49.2410699, lng: 20.7666508 },
    { lat: 49.2410648, lng: 20.7667291 },
    { lat: 49.2413901, lng: 20.7669862 },
    { lat: 49.2414884, lng: 20.7672909 },
    { lat: 49.2418259, lng: 20.7674483 },
    { lat: 49.2420565, lng: 20.7674462 },
    { lat: 49.2421386, lng: 20.7676081 },
    { lat: 49.2423609, lng: 20.7675588 },
    { lat: 49.2425331, lng: 20.7675931 },
    { lat: 49.2426174, lng: 20.7676824 },
    { lat: 49.2429735, lng: 20.7676009 },
    { lat: 49.2435865, lng: 20.7675873 },
    { lat: 49.2438369, lng: 20.7678278 },
    { lat: 49.2443034, lng: 20.768098 },
    { lat: 49.2443306, lng: 20.7682709 },
    { lat: 49.2447304, lng: 20.7686318 },
    { lat: 49.245075, lng: 20.7684757 },
    { lat: 49.2450959, lng: 20.7685871 },
    { lat: 49.2452512, lng: 20.7686253 },
    { lat: 49.2453361, lng: 20.7688025 },
    { lat: 49.2452933, lng: 20.7689277 },
    { lat: 49.2454693, lng: 20.769099 },
    { lat: 49.2455413, lng: 20.7692625 },
    { lat: 49.2457686, lng: 20.7692599 },
    { lat: 49.2459177, lng: 20.7695324 },
    { lat: 49.2460739, lng: 20.7694596 },
    { lat: 49.2462297, lng: 20.769737 },
    { lat: 49.2463789, lng: 20.7694954 },
    { lat: 49.2464552, lng: 20.7695181 },
    { lat: 49.2465887, lng: 20.769281 },
    { lat: 49.2466589, lng: 20.7693549 },
    { lat: 49.2467654, lng: 20.7693438 },
    { lat: 49.246799, lng: 20.7694351 },
    { lat: 49.2469292, lng: 20.7693957 },
    { lat: 49.2469632, lng: 20.7692405 },
    { lat: 49.2469352, lng: 20.769147 },
    { lat: 49.2470182, lng: 20.7690722 },
    { lat: 49.2471167, lng: 20.7690548 },
    { lat: 49.2471725, lng: 20.7692347 },
    { lat: 49.2473108, lng: 20.7691349 },
    { lat: 49.2475297, lng: 20.7698633 },
    { lat: 49.2476527, lng: 20.7708102 },
    { lat: 49.2480635, lng: 20.7718812 },
    { lat: 49.2482356, lng: 20.7717501 },
    { lat: 49.2483723, lng: 20.7714091 },
    { lat: 49.2489967, lng: 20.7718182 },
    { lat: 49.2490594, lng: 20.7716474 },
    { lat: 49.249332, lng: 20.7717528 },
    { lat: 49.2493153, lng: 20.7721775 },
    { lat: 49.2493717, lng: 20.772245 },
    { lat: 49.2493583, lng: 20.7724443 },
    { lat: 49.2495384, lng: 20.7728699 },
    { lat: 49.2496321, lng: 20.7729733 },
    { lat: 49.2499346, lng: 20.7730919 },
    { lat: 49.2499162, lng: 20.7734396 },
    { lat: 49.2500536, lng: 20.7736064 },
    { lat: 49.2502408, lng: 20.7737448 },
    { lat: 49.2504502, lng: 20.773779 },
    { lat: 49.2506665, lng: 20.7735892 },
    { lat: 49.2517467, lng: 20.774262 },
    { lat: 49.251645, lng: 20.7755436 },
    { lat: 49.2518046, lng: 20.7757681 },
    { lat: 49.2525304, lng: 20.7762181 },
    { lat: 49.2527021, lng: 20.7765566 },
    { lat: 49.2527175, lng: 20.7768119 },
    { lat: 49.2528255, lng: 20.7771299 },
    { lat: 49.2529302, lng: 20.7772602 },
    { lat: 49.2532518, lng: 20.7774803 },
    { lat: 49.253416, lng: 20.7775081 },
    { lat: 49.2536653, lng: 20.7777891 },
    { lat: 49.2549075, lng: 20.7780976 },
    { lat: 49.2552285, lng: 20.7780378 },
    { lat: 49.2554439, lng: 20.7779508 },
    { lat: 49.2554438, lng: 20.7778867 },
    { lat: 49.2564767, lng: 20.777713 },
    { lat: 49.2563538, lng: 20.7802089 },
    { lat: 49.2562384, lng: 20.7810049 },
    { lat: 49.2567379, lng: 20.7819093 },
    { lat: 49.2569275, lng: 20.7823779 },
    { lat: 49.25715, lng: 20.7831786 },
    { lat: 49.2573915, lng: 20.7847597 },
    { lat: 49.2575327, lng: 20.7872805 },
    { lat: 49.2571554, lng: 20.7875012 },
    { lat: 49.257118, lng: 20.7879474 },
    { lat: 49.2580093, lng: 20.7876542 },
    { lat: 49.2581476, lng: 20.7875526 },
    { lat: 49.2582693, lng: 20.7873904 },
    { lat: 49.2587519, lng: 20.7864098 },
    { lat: 49.2587544, lng: 20.7862219 },
    { lat: 49.2590238, lng: 20.7858751 },
    { lat: 49.2590494, lng: 20.785771 },
    { lat: 49.2592497, lng: 20.785574 },
    { lat: 49.2603213, lng: 20.786615 },
    { lat: 49.2605957, lng: 20.786999 },
    { lat: 49.2607883, lng: 20.7874591 },
    { lat: 49.2610865, lng: 20.7885608 },
    { lat: 49.2614417, lng: 20.7884161 },
    { lat: 49.26161, lng: 20.7890213 },
    { lat: 49.2615231, lng: 20.7891145 },
    { lat: 49.2614906, lng: 20.7892468 },
    { lat: 49.261513, lng: 20.7894706 },
    { lat: 49.2614757, lng: 20.789825 },
    { lat: 49.2613477, lng: 20.7901283 },
    { lat: 49.2613012, lng: 20.7904474 },
    { lat: 49.2613425, lng: 20.7908584 },
    { lat: 49.261251, lng: 20.7910157 },
    { lat: 49.2610457, lng: 20.7921051 },
    { lat: 49.2610978, lng: 20.7927056 },
    { lat: 49.2611982, lng: 20.7932972 },
    { lat: 49.2612813, lng: 20.7934559 },
    { lat: 49.2615693, lng: 20.7937772 },
    { lat: 49.2620501, lng: 20.7941103 },
    { lat: 49.2617645, lng: 20.7946696 },
    { lat: 49.2614894, lng: 20.7954399 },
    { lat: 49.2613479, lng: 20.7963634 },
    { lat: 49.2615703, lng: 20.7968071 },
    { lat: 49.262407, lng: 20.7978306 },
    { lat: 49.2634682, lng: 20.7994277 },
    { lat: 49.2637625, lng: 20.8001573 },
    { lat: 49.2643741, lng: 20.801151 },
    { lat: 49.2648955, lng: 20.8026288 },
    { lat: 49.2638836, lng: 20.8035795 },
    { lat: 49.2642032, lng: 20.8042637 },
    { lat: 49.2644326, lng: 20.8046179 },
    { lat: 49.2648407, lng: 20.8050933 },
    { lat: 49.2648851, lng: 20.805204 },
    { lat: 49.2648616, lng: 20.8054044 },
    { lat: 49.2653464, lng: 20.8059493 },
    { lat: 49.2658359, lng: 20.8068048 },
    { lat: 49.2666013, lng: 20.8080134 },
    { lat: 49.267186, lng: 20.8089457 },
    { lat: 49.2685274, lng: 20.8093674 },
    { lat: 49.2685868, lng: 20.8094917 },
    { lat: 49.2697861, lng: 20.8098348 },
    { lat: 49.2699176, lng: 20.8098314 },
    { lat: 49.2717568, lng: 20.8087809 },
    { lat: 49.272387, lng: 20.8085832 },
    { lat: 49.2729478, lng: 20.8082653 },
    { lat: 49.2729366, lng: 20.8086616 },
    { lat: 49.2732136, lng: 20.8107905 },
    { lat: 49.2743938, lng: 20.8114034 },
    { lat: 49.2758582, lng: 20.811177 },
    { lat: 49.2767572, lng: 20.8108723 },
    { lat: 49.2771781, lng: 20.8109056 },
    { lat: 49.2776196, lng: 20.8104859 },
    { lat: 49.2779047, lng: 20.810366 },
    { lat: 49.2775613, lng: 20.8066979 },
    { lat: 49.2773793, lng: 20.8057948 },
    { lat: 49.2777342, lng: 20.8057035 },
    { lat: 49.2780594, lng: 20.8051125 },
    { lat: 49.2785691, lng: 20.8044551 },
    { lat: 49.278825, lng: 20.8035838 },
    { lat: 49.2788779, lng: 20.8032248 },
    { lat: 49.2789587, lng: 20.8031527 },
    { lat: 49.2790118, lng: 20.7999833 },
    { lat: 49.2790923, lng: 20.7994169 },
    { lat: 49.2793461, lng: 20.7988752 },
    { lat: 49.2798885, lng: 20.7979249 },
    { lat: 49.2800409, lng: 20.7977392 },
    { lat: 49.2808728, lng: 20.7969335 },
    { lat: 49.2814122, lng: 20.7965444 },
    { lat: 49.2828615, lng: 20.7962091 },
    { lat: 49.2839019, lng: 20.7960954 },
    { lat: 49.2842934, lng: 20.7961226 },
    { lat: 49.2849559, lng: 20.7959441 },
    { lat: 49.2856559, lng: 20.7952272 },
    { lat: 49.2861208, lng: 20.7946381 },
    { lat: 49.2877532, lng: 20.7917777 },
    { lat: 49.2890492, lng: 20.7896731 },
    { lat: 49.2896017, lng: 20.7879151 },
    { lat: 49.2897123, lng: 20.7872402 },
    { lat: 49.2896525, lng: 20.7866147 },
    { lat: 49.2896747, lng: 20.7853307 },
    { lat: 49.2898124, lng: 20.7848606 },
    { lat: 49.2901365, lng: 20.7840425 },
    { lat: 49.2905127, lng: 20.7834648 },
    { lat: 49.2910729, lng: 20.7829327 },
    { lat: 49.2920119, lng: 20.7822254 },
    { lat: 49.2924039, lng: 20.7814552 },
    { lat: 49.2926703, lng: 20.7807644 },
    { lat: 49.2926395, lng: 20.7806843 },
    { lat: 49.2928476, lng: 20.7803598 },
    { lat: 49.2932435, lng: 20.7797303 },
    { lat: 49.2942145, lng: 20.7779662 },
    { lat: 49.2943735, lng: 20.7775185 },
    { lat: 49.2940816, lng: 20.774879 },
    { lat: 49.2940265, lng: 20.7738763 },
    { lat: 49.2940581, lng: 20.7733927 },
    { lat: 49.2944294, lng: 20.7722854 },
    { lat: 49.2954869, lng: 20.7699296 },
    { lat: 49.2955768, lng: 20.769443 },
    { lat: 49.2962847, lng: 20.7673311 },
    { lat: 49.2967852, lng: 20.7655407 },
    { lat: 49.2968816, lng: 20.7653962 },
    { lat: 49.2976089, lng: 20.7626175 },
    { lat: 49.2978603, lng: 20.7605263 },
    { lat: 49.2978706, lng: 20.7590724 },
    { lat: 49.2980337, lng: 20.7587443 },
    { lat: 49.2981963, lng: 20.7571158 },
    { lat: 49.2975955, lng: 20.7562176 },
    { lat: 49.2974564, lng: 20.7561911 },
    { lat: 49.2970547, lng: 20.7558116 },
    { lat: 49.2964861, lng: 20.7554482 },
    { lat: 49.2961257, lng: 20.7553791 },
    { lat: 49.2958352, lng: 20.7553743 },
    { lat: 49.2956245, lng: 20.7561101 },
    { lat: 49.2954347, lng: 20.756026 },
    { lat: 49.2951204, lng: 20.7565706 },
    { lat: 49.2950342, lng: 20.7554824 },
    { lat: 49.2944832, lng: 20.7545947 },
    { lat: 49.2938944, lng: 20.754489 },
    { lat: 49.2930525, lng: 20.7544256 },
    { lat: 49.2921257, lng: 20.7548346 },
    { lat: 49.2917406, lng: 20.7549229 },
    { lat: 49.29092, lng: 20.7552479 },
    { lat: 49.2897153, lng: 20.7559803 },
    { lat: 49.286259, lng: 20.7556605 },
    { lat: 49.2854429, lng: 20.7557284 },
    { lat: 49.2847266, lng: 20.7556201 },
    { lat: 49.2843485, lng: 20.7555517 },
    { lat: 49.283385, lng: 20.7551972 },
    { lat: 49.283031, lng: 20.7546635 },
    { lat: 49.2823181, lng: 20.7545254 },
    { lat: 49.2822342, lng: 20.7546336 },
    { lat: 49.2803644, lng: 20.7522337 },
    { lat: 49.2798203, lng: 20.7524511 },
    { lat: 49.2787964, lng: 20.7515225 },
    { lat: 49.2786591, lng: 20.7514623 },
    { lat: 49.2781412, lng: 20.7503873 },
    { lat: 49.2774989, lng: 20.7497237 },
    { lat: 49.2772162, lng: 20.7493351 },
    { lat: 49.2771507, lng: 20.7491 },
    { lat: 49.2762016, lng: 20.7477685 },
    { lat: 49.2754349, lng: 20.7471815 },
    { lat: 49.2748215, lng: 20.74699 },
    { lat: 49.2739652, lng: 20.7464189 },
    { lat: 49.2734168, lng: 20.7457953 },
    { lat: 49.2731157, lng: 20.7455888 },
    { lat: 49.2728041, lng: 20.7455623 },
    { lat: 49.2725246, lng: 20.7454179 },
    { lat: 49.2723771, lng: 20.7451945 },
    { lat: 49.2719813, lng: 20.7447867 },
    { lat: 49.2717162, lng: 20.7443221 },
    { lat: 49.2710494, lng: 20.7436004 },
    { lat: 49.2705002, lng: 20.7426987 },
    { lat: 49.2698632, lng: 20.7419242 },
    { lat: 49.2694066, lng: 20.7412648 },
    { lat: 49.2683777, lng: 20.740128 },
    { lat: 49.2681719, lng: 20.7400377 },
    { lat: 49.2680112, lng: 20.7400494 },
    { lat: 49.2674793, lng: 20.7403841 },
    { lat: 49.26666, lng: 20.7400654 },
    { lat: 49.2661229, lng: 20.739476 },
    { lat: 49.265705, lng: 20.7386022 },
    { lat: 49.265149, lng: 20.7382159 },
    { lat: 49.2641403, lng: 20.7371803 },
    { lat: 49.2639787, lng: 20.7356222 },
    { lat: 49.2637211, lng: 20.7352699 },
    { lat: 49.2634811, lng: 20.7351327 },
    { lat: 49.2633235, lng: 20.7351993 },
    { lat: 49.2626956, lng: 20.7351699 },
    { lat: 49.2623162, lng: 20.7349797 },
    { lat: 49.262134, lng: 20.7347247 },
    { lat: 49.2617993, lng: 20.7344907 },
    { lat: 49.2616526, lng: 20.7341269 },
    { lat: 49.2614877, lng: 20.7334668 },
    { lat: 49.2612378, lng: 20.7333344 },
    { lat: 49.2610253, lng: 20.7333093 },
    { lat: 49.2608786, lng: 20.7330398 },
    { lat: 49.2602712, lng: 20.7324127 },
    { lat: 49.2600831, lng: 20.7323416 },
    { lat: 49.2600167, lng: 20.7323784 },
    { lat: 49.2594536, lng: 20.7331366 },
    { lat: 49.2591954, lng: 20.7333815 },
    { lat: 49.2587662, lng: 20.7337247 },
    { lat: 49.2582311, lng: 20.7340052 },
    { lat: 49.257647, lng: 20.734426 },
    { lat: 49.2570879, lng: 20.7344021 },
    { lat: 49.2566659, lng: 20.7345966 },
    { lat: 49.2560968, lng: 20.7347069 },
    { lat: 49.2556338, lng: 20.7348864 },
    { lat: 49.2551106, lng: 20.734793 },
    { lat: 49.254688, lng: 20.7348751 },
    { lat: 49.2539166, lng: 20.7346751 },
    { lat: 49.2536347, lng: 20.7346881 },
    { lat: 49.2529551, lng: 20.735118 },
    { lat: 49.2526326, lng: 20.7352294 },
    { lat: 49.2514364, lng: 20.7352051 },
    { lat: 49.2511773, lng: 20.7352616 },
    { lat: 49.2511654, lng: 20.7363982 },
    { lat: 49.2511591, lng: 20.7369248 },
    { lat: 49.2499919, lng: 20.7365781 },
    { lat: 49.2495876, lng: 20.7358959 },
    { lat: 49.2494159, lng: 20.7348802 },
    { lat: 49.2487369, lng: 20.7341946 },
    { lat: 49.2486326, lng: 20.7350751 },
    { lat: 49.2469207, lng: 20.7362692 },
    { lat: 49.2459713, lng: 20.7376012 },
    { lat: 49.2457966, lng: 20.7380478 },
    { lat: 49.2451742, lng: 20.7389777 },
    { lat: 49.2442899, lng: 20.7389905 },
    { lat: 49.2444258, lng: 20.7397878 },
    { lat: 49.2443882, lng: 20.7400009 },
    { lat: 49.2442013, lng: 20.7402276 },
    { lat: 49.2450695, lng: 20.7414865 },
    { lat: 49.2448478, lng: 20.7421682 },
    { lat: 49.2452323, lng: 20.7433105 },
    { lat: 49.2452292, lng: 20.7439636 },
    { lat: 49.2455385, lng: 20.7450858 },
    { lat: 49.2456941, lng: 20.7460541 },
    { lat: 49.2456417, lng: 20.7461701 },
    { lat: 49.2459126, lng: 20.7480891 },
    { lat: 49.2460684, lng: 20.7482955 },
    { lat: 49.2460167, lng: 20.7487536 },
    { lat: 49.2466738, lng: 20.7498414 },
    { lat: 49.2472162, lng: 20.7504555 },
    { lat: 49.2473472, lng: 20.7516863 },
    { lat: 49.247323, lng: 20.7524118 },
    { lat: 49.2474163, lng: 20.7531193 },
    { lat: 49.2468494, lng: 20.7572045 },
    { lat: 49.2467069, lng: 20.7579863 },
    { lat: 49.2459029, lng: 20.7594978 },
    { lat: 49.2451095, lng: 20.7604074 },
    { lat: 49.2443966, lng: 20.7617186 },
    { lat: 49.2443487, lng: 20.7621844 },
    { lat: 49.2442176, lng: 20.7625931 },
    { lat: 49.2439259, lng: 20.7630703 },
    { lat: 49.2433848, lng: 20.7634673 },
    { lat: 49.242843, lng: 20.7632296 },
    { lat: 49.241704, lng: 20.76401 },
    { lat: 49.2412083, lng: 20.764547 },
    { lat: 49.2408034, lng: 20.765139 },
    { lat: 49.2407785, lng: 20.7657797 },
  ],
  Kamienka: [
    { lat: 49.3278245, lng: 20.6462618 },
    { lat: 49.3294209, lng: 20.6461752 },
    { lat: 49.3292005, lng: 20.6445436 },
    { lat: 49.3305849, lng: 20.6444768 },
    { lat: 49.3316297, lng: 20.6445139 },
    { lat: 49.331702, lng: 20.6449918 },
    { lat: 49.3336419, lng: 20.6433641 },
    { lat: 49.3340032, lng: 20.6425527 },
    { lat: 49.3340568, lng: 20.6422249 },
    { lat: 49.334744, lng: 20.6419908 },
    { lat: 49.3359342, lng: 20.6413349 },
    { lat: 49.3367332, lng: 20.6405749 },
    { lat: 49.3376517, lng: 20.6405741 },
    { lat: 49.3391367, lng: 20.6383499 },
    { lat: 49.3397368, lng: 20.6375347 },
    { lat: 49.3400492, lng: 20.637275 },
    { lat: 49.3407653, lng: 20.6378145 },
    { lat: 49.341601, lng: 20.6375109 },
    { lat: 49.3422737, lng: 20.6370563 },
    { lat: 49.3437167, lng: 20.6382547 },
    { lat: 49.3444328, lng: 20.6385449 },
    { lat: 49.34561, lng: 20.6378119 },
    { lat: 49.3458502, lng: 20.6373253 },
    { lat: 49.3468534, lng: 20.6364505 },
    { lat: 49.3472716, lng: 20.6346467 },
    { lat: 49.3477595, lng: 20.6339372 },
    { lat: 49.3490861, lng: 20.6332622 },
    { lat: 49.3495945, lng: 20.6332177 },
    { lat: 49.3495189, lng: 20.6318965 },
    { lat: 49.3504159, lng: 20.6308839 },
    { lat: 49.3525519, lng: 20.6295537 },
    { lat: 49.3526389, lng: 20.6295323 },
    { lat: 49.3527452, lng: 20.629609 },
    { lat: 49.353054, lng: 20.6301828 },
    { lat: 49.3536617, lng: 20.6299941 },
    { lat: 49.3540516, lng: 20.6289095 },
    { lat: 49.3541872, lng: 20.6288873 },
    { lat: 49.3543158, lng: 20.6283035 },
    { lat: 49.3553886, lng: 20.6255109 },
    { lat: 49.3558662, lng: 20.6217258 },
    { lat: 49.356347, lng: 20.6207894 },
    { lat: 49.3559734, lng: 20.619071 },
    { lat: 49.3558641, lng: 20.6180395 },
    { lat: 49.3566214, lng: 20.6166198 },
    { lat: 49.3578698, lng: 20.61493 },
    { lat: 49.3581641, lng: 20.6141945 },
    { lat: 49.3594435, lng: 20.612151 },
    { lat: 49.360906, lng: 20.6091608 },
    { lat: 49.3611238, lng: 20.609266 },
    { lat: 49.3617669, lng: 20.6074619 },
    { lat: 49.3622579, lng: 20.6065367 },
    { lat: 49.3650738, lng: 20.6068336 },
    { lat: 49.3662737, lng: 20.6071143 },
    { lat: 49.37143, lng: 20.6045517 },
    { lat: 49.372311, lng: 20.6026336 },
    { lat: 49.3739948, lng: 20.6029705 },
    { lat: 49.3784024, lng: 20.5999701 },
    { lat: 49.3795481, lng: 20.5986845 },
    { lat: 49.3797978, lng: 20.5983375 },
    { lat: 49.3818824, lng: 20.5947482 },
    { lat: 49.3823184, lng: 20.5945096 },
    { lat: 49.38303, lng: 20.592135 },
    { lat: 49.3839, lng: 20.592006 },
    { lat: 49.383932, lng: 20.591579 },
    { lat: 49.384353, lng: 20.590601 },
    { lat: 49.384631, lng: 20.589613 },
    { lat: 49.385243, lng: 20.587906 },
    { lat: 49.385489, lng: 20.586895 },
    { lat: 49.385386, lng: 20.585262 },
    { lat: 49.384891, lng: 20.584048 },
    { lat: 49.384463, lng: 20.583099 },
    { lat: 49.384208, lng: 20.582374 },
    { lat: 49.383878, lng: 20.581692 },
    { lat: 49.383038, lng: 20.580283 },
    { lat: 49.382899, lng: 20.580019 },
    { lat: 49.382335, lng: 20.580112 },
    { lat: 49.382018, lng: 20.580315 },
    { lat: 49.38183, lng: 20.580265 },
    { lat: 49.381531, lng: 20.57981 },
    { lat: 49.381221, lng: 20.579018 },
    { lat: 49.380904, lng: 20.5787 },
    { lat: 49.380201, lng: 20.578009 },
    { lat: 49.379699, lng: 20.577656 },
    { lat: 49.379331, lng: 20.57735 },
    { lat: 49.379025, lng: 20.57703 },
    { lat: 49.37767, lng: 20.576455 },
    { lat: 49.376908, lng: 20.57648 },
    { lat: 49.376248, lng: 20.576244 },
    { lat: 49.376165, lng: 20.575829 },
    { lat: 49.376106, lng: 20.575525 },
    { lat: 49.376254, lng: 20.574817 },
    { lat: 49.376393, lng: 20.573973 },
    { lat: 49.376594, lng: 20.573519 },
    { lat: 49.376869, lng: 20.572827 },
    { lat: 49.377065, lng: 20.572279 },
    { lat: 49.377141, lng: 20.571817 },
    { lat: 49.377509, lng: 20.57046 },
    { lat: 49.377756, lng: 20.570226 },
    { lat: 49.378153, lng: 20.569463 },
    { lat: 49.378405, lng: 20.569031 },
    { lat: 49.378606, lng: 20.568565 },
    { lat: 49.37899, lng: 20.567314 },
    { lat: 49.378951, lng: 20.566887 },
    { lat: 49.378985, lng: 20.566705 },
    { lat: 49.37911, lng: 20.565766 },
    { lat: 49.379475, lng: 20.563175 },
    { lat: 49.379221, lng: 20.562044 },
    { lat: 49.378487, lng: 20.561076 },
    { lat: 49.378305, lng: 20.560704 },
    { lat: 49.378117, lng: 20.55996 },
    { lat: 49.37795, lng: 20.559055 },
    { lat: 49.378099, lng: 20.557681 },
    { lat: 49.3768878, lng: 20.556111 },
    { lat: 49.3760177, lng: 20.5556479 },
    { lat: 49.3745164, lng: 20.5551976 },
    { lat: 49.3734906, lng: 20.5553103 },
    { lat: 49.372844, lng: 20.5571205 },
    { lat: 49.3718127, lng: 20.5570534 },
    { lat: 49.3685044, lng: 20.5590321 },
    { lat: 49.3663792, lng: 20.5614705 },
    { lat: 49.3642538, lng: 20.5635734 },
    { lat: 49.3624947, lng: 20.5637637 },
    { lat: 49.3622902, lng: 20.5639321 },
    { lat: 49.3595678, lng: 20.5625831 },
    { lat: 49.356893, lng: 20.5617776 },
    { lat: 49.3552903, lng: 20.561077 },
    { lat: 49.3539642, lng: 20.5587547 },
    { lat: 49.3537018, lng: 20.5583753 },
    { lat: 49.3534841, lng: 20.5580605 },
    { lat: 49.3525581, lng: 20.5564072 },
    { lat: 49.3510488, lng: 20.5540419 },
    { lat: 49.3502477, lng: 20.5532832 },
    { lat: 49.3498587, lng: 20.5527398 },
    { lat: 49.3492615, lng: 20.5524147 },
    { lat: 49.348679, lng: 20.551728 },
    { lat: 49.3480879, lng: 20.5507337 },
    { lat: 49.346959, lng: 20.5483077 },
    { lat: 49.3458327, lng: 20.5475495 },
    { lat: 49.3440777, lng: 20.5466265 },
    { lat: 49.3435676, lng: 20.5457287 },
    { lat: 49.3428644, lng: 20.5453727 },
    { lat: 49.3428348, lng: 20.5449315 },
    { lat: 49.342122, lng: 20.5438518 },
    { lat: 49.3415061, lng: 20.543232 },
    { lat: 49.3404819, lng: 20.5426224 },
    { lat: 49.3404444, lng: 20.5426426 },
    { lat: 49.3403495, lng: 20.5430651 },
    { lat: 49.3399692, lng: 20.54528 },
    { lat: 49.3380321, lng: 20.5478397 },
    { lat: 49.3377657, lng: 20.553247 },
    { lat: 49.3373752, lng: 20.556331 },
    { lat: 49.3375673, lng: 20.5578928 },
    { lat: 49.3377533, lng: 20.5619751 },
    { lat: 49.3376308, lng: 20.5653795 },
    { lat: 49.3380504, lng: 20.5676587 },
    { lat: 49.3377447, lng: 20.5677624 },
    { lat: 49.3371165, lng: 20.5679762 },
    { lat: 49.3372661, lng: 20.5700434 },
    { lat: 49.337335, lng: 20.5708559 },
    { lat: 49.3370815, lng: 20.5715005 },
    { lat: 49.3369676, lng: 20.5727332 },
    { lat: 49.3373827, lng: 20.5742442 },
    { lat: 49.337196, lng: 20.5762921 },
    { lat: 49.3364212, lng: 20.5769622 },
    { lat: 49.3362499, lng: 20.5778561 },
    { lat: 49.3346017, lng: 20.58024 },
    { lat: 49.333548, lng: 20.5815708 },
    { lat: 49.3330161, lng: 20.5821109 },
    { lat: 49.3320304, lng: 20.5824054 },
    { lat: 49.3311266, lng: 20.5829351 },
    { lat: 49.3303101, lng: 20.5836679 },
    { lat: 49.3299245, lng: 20.5843668 },
    { lat: 49.3296711, lng: 20.5851708 },
    { lat: 49.3294879, lng: 20.5855173 },
    { lat: 49.3291729, lng: 20.5858657 },
    { lat: 49.3273408, lng: 20.5874925 },
    { lat: 49.3265239, lng: 20.5888405 },
    { lat: 49.3261502, lng: 20.5898025 },
    { lat: 49.325616, lng: 20.5908728 },
    { lat: 49.3246827, lng: 20.5930442 },
    { lat: 49.3242061, lng: 20.593876 },
    { lat: 49.3240235, lng: 20.594182 },
    { lat: 49.3236644, lng: 20.5945495 },
    { lat: 49.3235282, lng: 20.5946119 },
    { lat: 49.3233873, lng: 20.5947941 },
    { lat: 49.3232748, lng: 20.5947347 },
    { lat: 49.3230706, lng: 20.5950046 },
    { lat: 49.3228491, lng: 20.5951667 },
    { lat: 49.3227254, lng: 20.5957 },
    { lat: 49.3227687, lng: 20.5959002 },
    { lat: 49.3226658, lng: 20.5963528 },
    { lat: 49.3223072, lng: 20.5965863 },
    { lat: 49.3221385, lng: 20.5970889 },
    { lat: 49.3221147, lng: 20.5972973 },
    { lat: 49.3218061, lng: 20.5976348 },
    { lat: 49.3217559, lng: 20.5978393 },
    { lat: 49.3216423, lng: 20.597841 },
    { lat: 49.3214327, lng: 20.5980204 },
    { lat: 49.3212513, lng: 20.5990451 },
    { lat: 49.3211925, lng: 20.5991594 },
    { lat: 49.3210777, lng: 20.5991954 },
    { lat: 49.3210828, lng: 20.5994263 },
    { lat: 49.3209298, lng: 20.599397 },
    { lat: 49.3209385, lng: 20.5994815 },
    { lat: 49.3203197, lng: 20.6004904 },
    { lat: 49.3199274, lng: 20.6007505 },
    { lat: 49.3196901, lng: 20.6010694 },
    { lat: 49.319366, lng: 20.6012592 },
    { lat: 49.3190547, lng: 20.6017459 },
    { lat: 49.3189155, lng: 20.6021889 },
    { lat: 49.3187377, lng: 20.60238 },
    { lat: 49.318677, lng: 20.6025673 },
    { lat: 49.3187417, lng: 20.6030522 },
    { lat: 49.3186651, lng: 20.6036415 },
    { lat: 49.3186088, lng: 20.6037339 },
    { lat: 49.3186159, lng: 20.604368 },
    { lat: 49.3187122, lng: 20.6045414 },
    { lat: 49.3185945, lng: 20.604958 },
    { lat: 49.3186434, lng: 20.6050992 },
    { lat: 49.3185327, lng: 20.6053973 },
    { lat: 49.3184642, lng: 20.6058389 },
    { lat: 49.3185155, lng: 20.6061359 },
    { lat: 49.3184741, lng: 20.6065832 },
    { lat: 49.3187649, lng: 20.6069949 },
    { lat: 49.3187409, lng: 20.6071135 },
    { lat: 49.318616, lng: 20.6072406 },
    { lat: 49.3185592, lng: 20.6075041 },
    { lat: 49.3186355, lng: 20.6077163 },
    { lat: 49.3187474, lng: 20.607822 },
    { lat: 49.3187553, lng: 20.6082897 },
    { lat: 49.318896, lng: 20.6086678 },
    { lat: 49.3188138, lng: 20.6095411 },
    { lat: 49.3193497, lng: 20.6101949 },
    { lat: 49.3194485, lng: 20.6106892 },
    { lat: 49.3193754, lng: 20.6113773 },
    { lat: 49.319274, lng: 20.6115941 },
    { lat: 49.3193026, lng: 20.612042 },
    { lat: 49.3193845, lng: 20.6122267 },
    { lat: 49.319429, lng: 20.6128067 },
    { lat: 49.3193669, lng: 20.6131378 },
    { lat: 49.3193742, lng: 20.6134067 },
    { lat: 49.3194528, lng: 20.6134344 },
    { lat: 49.3194863, lng: 20.6135517 },
    { lat: 49.3195823, lng: 20.6135252 },
    { lat: 49.3196428, lng: 20.6135804 },
    { lat: 49.3197693, lng: 20.6139028 },
    { lat: 49.3197425, lng: 20.6144102 },
    { lat: 49.3202658, lng: 20.6156915 },
    { lat: 49.3203746, lng: 20.6162441 },
    { lat: 49.320484, lng: 20.616341 },
    { lat: 49.3207178, lng: 20.6171805 },
    { lat: 49.3207424, lng: 20.6176738 },
    { lat: 49.3208597, lng: 20.6181143 },
    { lat: 49.320845, lng: 20.6184923 },
    { lat: 49.3209961, lng: 20.6188115 },
    { lat: 49.3209894, lng: 20.6189979 },
    { lat: 49.3210732, lng: 20.6192294 },
    { lat: 49.3210354, lng: 20.6194068 },
    { lat: 49.3211642, lng: 20.6197922 },
    { lat: 49.3211461, lng: 20.6203516 },
    { lat: 49.3209843, lng: 20.6207661 },
    { lat: 49.3209206, lng: 20.6210616 },
    { lat: 49.3207351, lng: 20.6210068 },
    { lat: 49.3206891, lng: 20.6211045 },
    { lat: 49.3207535, lng: 20.6214246 },
    { lat: 49.3207157, lng: 20.6215014 },
    { lat: 49.3205191, lng: 20.6215721 },
    { lat: 49.320501, lng: 20.6218825 },
    { lat: 49.320555, lng: 20.6219786 },
    { lat: 49.3208344, lng: 20.6219832 },
    { lat: 49.3208693, lng: 20.6223307 },
    { lat: 49.3205434, lng: 20.6227532 },
    { lat: 49.32059, lng: 20.6231383 },
    { lat: 49.3205327, lng: 20.6234264 },
    { lat: 49.3202417, lng: 20.6234097 },
    { lat: 49.3201839, lng: 20.6239941 },
    { lat: 49.3204382, lng: 20.6243018 },
    { lat: 49.3204768, lng: 20.6245305 },
    { lat: 49.3204071, lng: 20.6246244 },
    { lat: 49.320107, lng: 20.6246111 },
    { lat: 49.3201, lng: 20.625005 },
    { lat: 49.3201623, lng: 20.6252379 },
    { lat: 49.3197578, lng: 20.6255126 },
    { lat: 49.3197319, lng: 20.6256078 },
    { lat: 49.3199836, lng: 20.6256848 },
    { lat: 49.3200226, lng: 20.6263314 },
    { lat: 49.3200557, lng: 20.6263477 },
    { lat: 49.3199931, lng: 20.6265157 },
    { lat: 49.3199035, lng: 20.627626 },
    { lat: 49.3205342, lng: 20.6289792 },
    { lat: 49.3214695, lng: 20.6313612 },
    { lat: 49.3214817, lng: 20.6317669 },
    { lat: 49.3224584, lng: 20.6315088 },
    { lat: 49.3231529, lng: 20.631663 },
    { lat: 49.323248, lng: 20.6316164 },
    { lat: 49.3233144, lng: 20.6309241 },
    { lat: 49.3237865, lng: 20.6307485 },
    { lat: 49.3240505, lng: 20.630797 },
    { lat: 49.3240746, lng: 20.6315604 },
    { lat: 49.3246722, lng: 20.6327816 },
    { lat: 49.3257639, lng: 20.6353232 },
    { lat: 49.3260574, lng: 20.6362264 },
    { lat: 49.3264313, lng: 20.6376166 },
    { lat: 49.3269166, lng: 20.6389382 },
    { lat: 49.3269455, lng: 20.6396222 },
    { lat: 49.3268549, lng: 20.6402336 },
    { lat: 49.3269627, lng: 20.6412354 },
    { lat: 49.3271742, lng: 20.6424598 },
    { lat: 49.3273762, lng: 20.6429117 },
    { lat: 49.3273128, lng: 20.6429978 },
    { lat: 49.3271923, lng: 20.6438437 },
    { lat: 49.3278245, lng: 20.6462618 },
  ],
  Kyjov: [
    { lat: 49.250283, lng: 21.003295 },
    { lat: 49.2495435, lng: 21.000511 },
    { lat: 49.2494454, lng: 20.9989626 },
    { lat: 49.2476347, lng: 20.9932644 },
    { lat: 49.2471471, lng: 20.9932164 },
    { lat: 49.2468973, lng: 20.9935676 },
    { lat: 49.2466847, lng: 20.9936725 },
    { lat: 49.246294, lng: 20.9930487 },
    { lat: 49.2464338, lng: 20.9916447 },
    { lat: 49.2464076, lng: 20.9908834 },
    { lat: 49.2459703, lng: 20.9885334 },
    { lat: 49.2458612, lng: 20.9881898 },
    { lat: 49.2457516, lng: 20.9869811 },
    { lat: 49.2456318, lng: 20.986312 },
    { lat: 49.2451991, lng: 20.9842495 },
    { lat: 49.2450435, lng: 20.983755 },
    { lat: 49.2447584, lng: 20.9830969 },
    { lat: 49.2444829, lng: 20.9827928 },
    { lat: 49.2441969, lng: 20.98276 },
    { lat: 49.2441777, lng: 20.9823764 },
    { lat: 49.2435561, lng: 20.9812755 },
    { lat: 49.2432582, lng: 20.9804623 },
    { lat: 49.2428942, lng: 20.9796936 },
    { lat: 49.2425627, lng: 20.9794374 },
    { lat: 49.242292, lng: 20.9789085 },
    { lat: 49.2415527, lng: 20.9781924 },
    { lat: 49.2415526, lng: 20.9781182 },
    { lat: 49.2423286, lng: 20.9781378 },
    { lat: 49.2432452, lng: 20.9787009 },
    { lat: 49.243699, lng: 20.9751895 },
    { lat: 49.2427866, lng: 20.9741459 },
    { lat: 49.2425045, lng: 20.9735373 },
    { lat: 49.2424681, lng: 20.9730856 },
    { lat: 49.2425665, lng: 20.9720428 },
    { lat: 49.2426848, lng: 20.9713973 },
    { lat: 49.2426084, lng: 20.9703125 },
    { lat: 49.2426689, lng: 20.969451 },
    { lat: 49.2425493, lng: 20.9687592 },
    { lat: 49.2428928, lng: 20.9674404 },
    { lat: 49.2428914, lng: 20.966785 },
    { lat: 49.2424712, lng: 20.9651025 },
    { lat: 49.2422781, lng: 20.9645782 },
    { lat: 49.2421222, lng: 20.9645546 },
    { lat: 49.2419012, lng: 20.9642687 },
    { lat: 49.2417211, lng: 20.9637279 },
    { lat: 49.2414826, lng: 20.9634902 },
    { lat: 49.2414178, lng: 20.9630947 },
    { lat: 49.2412175, lng: 20.9625198 },
    { lat: 49.241298, lng: 20.9622316 },
    { lat: 49.2415753, lng: 20.9622444 },
    { lat: 49.241265, lng: 20.9609292 },
    { lat: 49.2408004, lng: 20.9593018 },
    { lat: 49.240269, lng: 20.9588509 },
    { lat: 49.2401203, lng: 20.9583419 },
    { lat: 49.2398855, lng: 20.9579098 },
    { lat: 49.2393268, lng: 20.9575197 },
    { lat: 49.23913, lng: 20.9572223 },
    { lat: 49.2391805, lng: 20.9570462 },
    { lat: 49.239017, lng: 20.9562186 },
    { lat: 49.2390034, lng: 20.9550336 },
    { lat: 49.2391261, lng: 20.9541453 },
    { lat: 49.2394089, lng: 20.9532731 },
    { lat: 49.2394003, lng: 20.9525095 },
    { lat: 49.2392766, lng: 20.9523429 },
    { lat: 49.2380906, lng: 20.9525489 },
    { lat: 49.2375258, lng: 20.9530673 },
    { lat: 49.2377346, lng: 20.9525361 },
    { lat: 49.2377334, lng: 20.9508089 },
    { lat: 49.2375911, lng: 20.9499116 },
    { lat: 49.2372399, lng: 20.9487877 },
    { lat: 49.2371226, lng: 20.9482076 },
    { lat: 49.2370726, lng: 20.9477836 },
    { lat: 49.237072, lng: 20.9470536 },
    { lat: 49.2369175, lng: 20.945997 },
    { lat: 49.2369354, lng: 20.9454154 },
    { lat: 49.2366751, lng: 20.9451039 },
    { lat: 49.2365851, lng: 20.9444255 },
    { lat: 49.2354912, lng: 20.943172 },
    { lat: 49.235308, lng: 20.9424382 },
    { lat: 49.2351929, lng: 20.9416498 },
    { lat: 49.235266, lng: 20.9410769 },
    { lat: 49.2352895, lng: 20.9397206 },
    { lat: 49.235135, lng: 20.9385207 },
    { lat: 49.2346015, lng: 20.9372397 },
    { lat: 49.2336501, lng: 20.9364507 },
    { lat: 49.2347568, lng: 20.9345396 },
    { lat: 49.235194, lng: 20.9315812 },
    { lat: 49.235131, lng: 20.9293896 },
    { lat: 49.2341464, lng: 20.9274383 },
    { lat: 49.2329514, lng: 20.9263011 },
    { lat: 49.2327442, lng: 20.9259586 },
    { lat: 49.230879, lng: 20.9225783 },
    { lat: 49.2300702, lng: 20.9206795 },
    { lat: 49.2287789, lng: 20.9187795 },
    { lat: 49.2288425, lng: 20.9177911 },
    { lat: 49.2291818, lng: 20.9169666 },
    { lat: 49.2294227, lng: 20.9166302 },
    { lat: 49.2295959, lng: 20.9162242 },
    { lat: 49.2295587, lng: 20.9158554 },
    { lat: 49.2292102, lng: 20.9147228 },
    { lat: 49.2287522, lng: 20.9135479 },
    { lat: 49.2290076, lng: 20.9127735 },
    { lat: 49.2289293, lng: 20.9121458 },
    { lat: 49.2290488, lng: 20.9119539 },
    { lat: 49.2293115, lng: 20.9117634 },
    { lat: 49.2294048, lng: 20.9114598 },
    { lat: 49.2293932, lng: 20.9109788 },
    { lat: 49.2297886, lng: 20.9105558 },
    { lat: 49.2298543, lng: 20.9099957 },
    { lat: 49.230179, lng: 20.9096152 },
    { lat: 49.2302899, lng: 20.9093278 },
    { lat: 49.2301716, lng: 20.9091549 },
    { lat: 49.2301884, lng: 20.9088564 },
    { lat: 49.2302965, lng: 20.9087533 },
    { lat: 49.2302987, lng: 20.9085165 },
    { lat: 49.2300964, lng: 20.9079918 },
    { lat: 49.2299947, lng: 20.9072367 },
    { lat: 49.2301477, lng: 20.9070735 },
    { lat: 49.2301455, lng: 20.9069029 },
    { lat: 49.230068, lng: 20.9068078 },
    { lat: 49.2299912, lng: 20.9065362 },
    { lat: 49.2300396, lng: 20.906227 },
    { lat: 49.2300034, lng: 20.9059576 },
    { lat: 49.2301042, lng: 20.9057066 },
    { lat: 49.2300798, lng: 20.9053808 },
    { lat: 49.2302505, lng: 20.9053045 },
    { lat: 49.2302981, lng: 20.9050847 },
    { lat: 49.230253, lng: 20.9045765 },
    { lat: 49.2300431, lng: 20.9043934 },
    { lat: 49.2300595, lng: 20.9040136 },
    { lat: 49.2302004, lng: 20.9039806 },
    { lat: 49.2302197, lng: 20.90365 },
    { lat: 49.2300817, lng: 20.9033594 },
    { lat: 49.2300344, lng: 20.9031254 },
    { lat: 49.2301499, lng: 20.9024501 },
    { lat: 49.2300282, lng: 20.9021748 },
    { lat: 49.2293371, lng: 20.9012322 },
    { lat: 49.2289966, lng: 20.9009478 },
    { lat: 49.2290161, lng: 20.9006916 },
    { lat: 49.2289461, lng: 20.9005534 },
    { lat: 49.2288202, lng: 20.9004553 },
    { lat: 49.2287965, lng: 20.90072 },
    { lat: 49.2285301, lng: 20.9008334 },
    { lat: 49.2282774, lng: 20.9008788 },
    { lat: 49.2278741, lng: 20.9008366 },
    { lat: 49.2277582, lng: 20.900904 },
    { lat: 49.2277015, lng: 20.9007533 },
    { lat: 49.2278835, lng: 20.9002464 },
    { lat: 49.2278413, lng: 20.900057 },
    { lat: 49.2277622, lng: 20.9001191 },
    { lat: 49.2270607, lng: 20.899541 },
    { lat: 49.2271081, lng: 20.900009 },
    { lat: 49.2272887, lng: 20.9001972 },
    { lat: 49.2273083, lng: 20.9007441 },
    { lat: 49.2272348, lng: 20.9012036 },
    { lat: 49.2272663, lng: 20.9015724 },
    { lat: 49.2270436, lng: 20.9015765 },
    { lat: 49.2269793, lng: 20.9013698 },
    { lat: 49.226674, lng: 20.9010916 },
    { lat: 49.2264789, lng: 20.9006766 },
    { lat: 49.2263945, lng: 20.9002436 },
    { lat: 49.2265088, lng: 20.8999959 },
    { lat: 49.2260335, lng: 20.9000472 },
    { lat: 49.2260117, lng: 20.9003409 },
    { lat: 49.2261583, lng: 20.9004137 },
    { lat: 49.2261255, lng: 20.9006449 },
    { lat: 49.2259838, lng: 20.9008141 },
    { lat: 49.2255226, lng: 20.9007559 },
    { lat: 49.2253994, lng: 20.9008707 },
    { lat: 49.2253212, lng: 20.9006932 },
    { lat: 49.2254192, lng: 20.9004306 },
    { lat: 49.2253284, lng: 20.900401 },
    { lat: 49.2252844, lng: 20.9001995 },
    { lat: 49.2253612, lng: 20.9000102 },
    { lat: 49.2252509, lng: 20.8999771 },
    { lat: 49.2251951, lng: 20.9001896 },
    { lat: 49.225033, lng: 20.9004897 },
    { lat: 49.2250041, lng: 20.9008625 },
    { lat: 49.2251295, lng: 20.9009815 },
    { lat: 49.2251054, lng: 20.9013723 },
    { lat: 49.2249468, lng: 20.9020772 },
    { lat: 49.2246001, lng: 20.9042792 },
    { lat: 49.2241854, lng: 20.905604 },
    { lat: 49.2238416, lng: 20.9061595 },
    { lat: 49.2235105, lng: 20.9072901 },
    { lat: 49.2231111, lng: 20.9074009 },
    { lat: 49.2226436, lng: 20.9084251 },
    { lat: 49.2222966, lng: 20.9084148 },
    { lat: 49.2221876, lng: 20.9092558 },
    { lat: 49.2214892, lng: 20.9108133 },
    { lat: 49.2213434, lng: 20.911269 },
    { lat: 49.2213337, lng: 20.9127596 },
    { lat: 49.2207785, lng: 20.9133852 },
    { lat: 49.2181143, lng: 20.9158637 },
    { lat: 49.217894, lng: 20.9159684 },
    { lat: 49.217354, lng: 20.9165826 },
    { lat: 49.2115486, lng: 20.9223238 },
    { lat: 49.211319, lng: 20.92265 },
    { lat: 49.211431, lng: 20.922749 },
    { lat: 49.212057, lng: 20.923252 },
    { lat: 49.212233, lng: 20.923405 },
    { lat: 49.212523, lng: 20.923655 },
    { lat: 49.212913, lng: 20.924057 },
    { lat: 49.212815, lng: 20.924745 },
    { lat: 49.213054, lng: 20.925124 },
    { lat: 49.21389, lng: 20.926448 },
    { lat: 49.21259, lng: 20.927981 },
    { lat: 49.211889, lng: 20.928789 },
    { lat: 49.2111, lng: 20.9297 },
    { lat: 49.210319, lng: 20.930512 },
    { lat: 49.211014, lng: 20.931275 },
    { lat: 49.211436, lng: 20.932057 },
    { lat: 49.210587, lng: 20.934935 },
    { lat: 49.210872, lng: 20.935345 },
    { lat: 49.210724, lng: 20.935889 },
    { lat: 49.21119, lng: 20.936602 },
    { lat: 49.211435, lng: 20.936817 },
    { lat: 49.21173, lng: 20.93695 },
    { lat: 49.212228, lng: 20.936656 },
    { lat: 49.212381, lng: 20.936543 },
    { lat: 49.212267, lng: 20.937653 },
    { lat: 49.212249, lng: 20.937819 },
    { lat: 49.21212, lng: 20.938144 },
    { lat: 49.211806, lng: 20.938928 },
    { lat: 49.21165, lng: 20.939319 },
    { lat: 49.211298, lng: 20.940203 },
    { lat: 49.210971, lng: 20.941026 },
    { lat: 49.210728, lng: 20.941633 },
    { lat: 49.210709, lng: 20.941681 },
    { lat: 49.210856, lng: 20.943145 },
    { lat: 49.210864, lng: 20.943226 },
    { lat: 49.210668, lng: 20.943614 },
    { lat: 49.210665, lng: 20.943617 },
    { lat: 49.209999, lng: 20.945744 },
    { lat: 49.208933, lng: 20.948991 },
    { lat: 49.208713, lng: 20.94966 },
    { lat: 49.209487, lng: 20.949504 },
    { lat: 49.210617, lng: 20.949606 },
    { lat: 49.211908, lng: 20.950058 },
    { lat: 49.212773, lng: 20.951171 },
    { lat: 49.213772, lng: 20.953368 },
    { lat: 49.213912, lng: 20.953934 },
    { lat: 49.214121, lng: 20.955674 },
    { lat: 49.214352, lng: 20.956344 },
    { lat: 49.214617, lng: 20.957113 },
    { lat: 49.214839, lng: 20.95772 },
    { lat: 49.215419, lng: 20.958666 },
    { lat: 49.215982, lng: 20.959271 },
    { lat: 49.216751, lng: 20.959996 },
    { lat: 49.217253, lng: 20.960683 },
    { lat: 49.217666, lng: 20.961333 },
    { lat: 49.217834, lng: 20.961722 },
    { lat: 49.21823, lng: 20.962521 },
    { lat: 49.218765, lng: 20.964724 },
    { lat: 49.218899, lng: 20.965416 },
    { lat: 49.218969, lng: 20.967168 },
    { lat: 49.218638, lng: 20.969773 },
    { lat: 49.21863, lng: 20.970392 },
    { lat: 49.21845, lng: 20.970923 },
    { lat: 49.2185669, lng: 20.9721376 },
    { lat: 49.21964, lng: 20.973893 },
    { lat: 49.219667, lng: 20.973875 },
    { lat: 49.21972, lng: 20.974099 },
    { lat: 49.219948, lng: 20.975149 },
    { lat: 49.220038, lng: 20.975521 },
    { lat: 49.220132, lng: 20.975762 },
    { lat: 49.220305, lng: 20.976394 },
    { lat: 49.22056, lng: 20.976854 },
    { lat: 49.220887, lng: 20.977247 },
    { lat: 49.221168, lng: 20.977427 },
    { lat: 49.221475, lng: 20.977806 },
    { lat: 49.221728, lng: 20.977958 },
    { lat: 49.221883, lng: 20.977884 },
    { lat: 49.222061, lng: 20.978103 },
    { lat: 49.222192, lng: 20.978113 },
    { lat: 49.2222966, lng: 20.9782259 },
    { lat: 49.2224472, lng: 20.9782703 },
    { lat: 49.222799, lng: 20.978424 },
    { lat: 49.223072, lng: 20.978454 },
    { lat: 49.223672, lng: 20.978688 },
    { lat: 49.224243, lng: 20.978875 },
    { lat: 49.224491, lng: 20.978995 },
    { lat: 49.224917, lng: 20.97925 },
    { lat: 49.225259, lng: 20.979606 },
    { lat: 49.225662, lng: 20.980133 },
    { lat: 49.226222, lng: 20.980919 },
    { lat: 49.226583, lng: 20.981187 },
    { lat: 49.226596, lng: 20.981621 },
    { lat: 49.226657, lng: 20.982225 },
    { lat: 49.22686, lng: 20.983013 },
    { lat: 49.226935, lng: 20.983466 },
    { lat: 49.227114, lng: 20.983995 },
    { lat: 49.227221, lng: 20.984444 },
    { lat: 49.227323, lng: 20.984735 },
    { lat: 49.22757, lng: 20.985454 },
    { lat: 49.228102, lng: 20.988902 },
    { lat: 49.228276, lng: 20.992129 },
    { lat: 49.230453, lng: 20.994443 },
    { lat: 49.230872, lng: 20.994004 },
    { lat: 49.231807, lng: 20.993376 },
    { lat: 49.232121, lng: 20.993027 },
    { lat: 49.232663, lng: 20.992631 },
    { lat: 49.232658, lng: 20.992553 },
    { lat: 49.232787, lng: 20.992901 },
    { lat: 49.233057, lng: 20.993362 },
    { lat: 49.233417, lng: 20.994066 },
    { lat: 49.233617, lng: 20.994577 },
    { lat: 49.233682, lng: 20.994985 },
    { lat: 49.234074, lng: 20.995878 },
    { lat: 49.234272, lng: 20.996179 },
    { lat: 49.234511, lng: 20.996519 },
    { lat: 49.234812, lng: 20.996784 },
    { lat: 49.234939, lng: 20.997024 },
    { lat: 49.235101, lng: 20.997069 },
    { lat: 49.235228, lng: 20.997288 },
    { lat: 49.235242, lng: 20.997273 },
    { lat: 49.235362, lng: 20.997494 },
    { lat: 49.235651, lng: 20.998111 },
    { lat: 49.236112, lng: 20.998413 },
    { lat: 49.236329, lng: 20.998612 },
    { lat: 49.236637, lng: 20.998664 },
    { lat: 49.236993, lng: 20.998771 },
    { lat: 49.237327, lng: 20.999048 },
    { lat: 49.2375, lng: 20.9994 },
    { lat: 49.23762, lng: 20.999958 },
    { lat: 49.237808, lng: 21.000532 },
    { lat: 49.238066, lng: 21.000829 },
    { lat: 49.238378, lng: 21.001043 },
    { lat: 49.238722, lng: 21.001195 },
    { lat: 49.2392, lng: 21.001676 },
    { lat: 49.239596, lng: 21.002138 },
    { lat: 49.239721, lng: 21.002463 },
    { lat: 49.239869, lng: 21.002583 },
    { lat: 49.240316, lng: 21.002654 },
    { lat: 49.240719, lng: 21.0029 },
    { lat: 49.241025, lng: 21.003047 },
    { lat: 49.241214, lng: 21.00307 },
    { lat: 49.241439, lng: 21.002943 },
    { lat: 49.24178, lng: 21.002839 },
    { lat: 49.242113, lng: 21.002925 },
    { lat: 49.242414, lng: 21.002912 },
    { lat: 49.242898, lng: 21.003154 },
    { lat: 49.243149, lng: 21.003218 },
    { lat: 49.243293, lng: 21.003292 },
    { lat: 49.243356, lng: 21.003298 },
    { lat: 49.243573, lng: 21.003375 },
    { lat: 49.243743, lng: 21.003466 },
    { lat: 49.24403, lng: 21.003496 },
    { lat: 49.244333, lng: 21.003601 },
    { lat: 49.244461, lng: 21.003517 },
    { lat: 49.244619, lng: 21.003462 },
    { lat: 49.244834, lng: 21.003504 },
    { lat: 49.245007, lng: 21.003614 },
    { lat: 49.245304, lng: 21.003677 },
    { lat: 49.245482, lng: 21.003652 },
    { lat: 49.24562, lng: 21.003547 },
    { lat: 49.245802, lng: 21.003302 },
    { lat: 49.246065, lng: 21.003086 },
    { lat: 49.246539, lng: 21.002866 },
    { lat: 49.246666, lng: 21.002898 },
    { lat: 49.246801, lng: 21.002886 },
    { lat: 49.247077, lng: 21.002698 },
    { lat: 49.247167, lng: 21.002589 },
    { lat: 49.247414, lng: 21.002434 },
    { lat: 49.247768, lng: 21.002744 },
    { lat: 49.248383, lng: 21.002916 },
    { lat: 49.248485, lng: 21.00301 },
    { lat: 49.24879, lng: 21.003046 },
    { lat: 49.249032, lng: 21.003148 },
    { lat: 49.249238, lng: 21.003111 },
    { lat: 49.24971, lng: 21.003271 },
    { lat: 49.249885, lng: 21.003245 },
    { lat: 49.25016, lng: 21.003261 },
    { lat: 49.250283, lng: 21.003295 },
  ],
  Hajtovka: [
    { lat: 49.3115801, lng: 20.744296 },
    { lat: 49.3112002, lng: 20.7442448 },
    { lat: 49.3110484, lng: 20.744316 },
    { lat: 49.3109577, lng: 20.7442314 },
    { lat: 49.3107605, lng: 20.7444531 },
    { lat: 49.3102873, lng: 20.7447883 },
    { lat: 49.3102464, lng: 20.7448793 },
    { lat: 49.3100193, lng: 20.7450169 },
    { lat: 49.3099105, lng: 20.7449587 },
    { lat: 49.309684, lng: 20.7451117 },
    { lat: 49.3095859, lng: 20.7451071 },
    { lat: 49.3093383, lng: 20.7454649 },
    { lat: 49.3089851, lng: 20.7455656 },
    { lat: 49.3087907, lng: 20.7453535 },
    { lat: 49.3085745, lng: 20.7453495 },
    { lat: 49.3085015, lng: 20.74542 },
    { lat: 49.3080683, lng: 20.7452927 },
    { lat: 49.307854, lng: 20.7453776 },
    { lat: 49.3075118, lng: 20.7452425 },
    { lat: 49.3067964, lng: 20.7454838 },
    { lat: 49.3061884, lng: 20.7458628 },
    { lat: 49.3060162, lng: 20.7458977 },
    { lat: 49.3057653, lng: 20.7461891 },
    { lat: 49.3055332, lng: 20.746282 },
    { lat: 49.3052698, lng: 20.7467634 },
    { lat: 49.3050295, lng: 20.7468133 },
    { lat: 49.3047125, lng: 20.7473744 },
    { lat: 49.3044878, lng: 20.7475781 },
    { lat: 49.3043134, lng: 20.7475751 },
    { lat: 49.3042179, lng: 20.7473863 },
    { lat: 49.3041513, lng: 20.7473841 },
    { lat: 49.3039162, lng: 20.7475479 },
    { lat: 49.3038224, lng: 20.7475091 },
    { lat: 49.3031849, lng: 20.747942 },
    { lat: 49.3020768, lng: 20.7497347 },
    { lat: 49.3016179, lng: 20.7508462 },
    { lat: 49.301143, lng: 20.7514577 },
    { lat: 49.30118, lng: 20.7516797 },
    { lat: 49.3009635, lng: 20.7516407 },
    { lat: 49.3008477, lng: 20.7520602 },
    { lat: 49.3004856, lng: 20.7522596 },
    { lat: 49.3003597, lng: 20.7524935 },
    { lat: 49.3000498, lng: 20.7527431 },
    { lat: 49.2998619, lng: 20.7530711 },
    { lat: 49.2995867, lng: 20.7531462 },
    { lat: 49.2995319, lng: 20.7535167 },
    { lat: 49.2993288, lng: 20.7535978 },
    { lat: 49.2992702, lng: 20.753789 },
    { lat: 49.2990968, lng: 20.7540469 },
    { lat: 49.2986821, lng: 20.75445 },
    { lat: 49.2985631, lng: 20.7546512 },
    { lat: 49.2984111, lng: 20.7548533 },
    { lat: 49.2982998, lng: 20.7550688 },
    { lat: 49.2982126, lng: 20.7552652 },
    { lat: 49.2981339, lng: 20.7557526 },
    { lat: 49.2981361, lng: 20.7566253 },
    { lat: 49.2981963, lng: 20.7571158 },
    { lat: 49.2980337, lng: 20.7587443 },
    { lat: 49.2978706, lng: 20.7590724 },
    { lat: 49.2978603, lng: 20.7605263 },
    { lat: 49.2976089, lng: 20.7626175 },
    { lat: 49.2968816, lng: 20.7653962 },
    { lat: 49.2967852, lng: 20.7655407 },
    { lat: 49.2962847, lng: 20.7673311 },
    { lat: 49.2955768, lng: 20.769443 },
    { lat: 49.2954869, lng: 20.7699296 },
    { lat: 49.2944294, lng: 20.7722854 },
    { lat: 49.2940581, lng: 20.7733927 },
    { lat: 49.2940265, lng: 20.7738763 },
    { lat: 49.2940816, lng: 20.774879 },
    { lat: 49.2943735, lng: 20.7775185 },
    { lat: 49.2942145, lng: 20.7779662 },
    { lat: 49.2932435, lng: 20.7797303 },
    { lat: 49.2928476, lng: 20.7803598 },
    { lat: 49.2926395, lng: 20.7806843 },
    { lat: 49.2932888, lng: 20.7814979 },
    { lat: 49.2935883, lng: 20.7815296 },
    { lat: 49.2936022, lng: 20.7813189 },
    { lat: 49.2938172, lng: 20.7816073 },
    { lat: 49.2944544, lng: 20.7820351 },
    { lat: 49.2945589, lng: 20.7818041 },
    { lat: 49.2946401, lng: 20.7817788 },
    { lat: 49.2947379, lng: 20.7819342 },
    { lat: 49.2950588, lng: 20.782068 },
    { lat: 49.2955679, lng: 20.7824744 },
    { lat: 49.2962754, lng: 20.7826559 },
    { lat: 49.2963019, lng: 20.7828359 },
    { lat: 49.296772, lng: 20.7830225 },
    { lat: 49.2971272, lng: 20.7835867 },
    { lat: 49.2973167, lng: 20.7832756 },
    { lat: 49.297711, lng: 20.78322 },
    { lat: 49.2978384, lng: 20.783003 },
    { lat: 49.2983917, lng: 20.782889 },
    { lat: 49.2989624, lng: 20.7819193 },
    { lat: 49.2992376, lng: 20.7818353 },
    { lat: 49.2994049, lng: 20.781952 },
    { lat: 49.2996626, lng: 20.7819856 },
    { lat: 49.2998269, lng: 20.7817916 },
    { lat: 49.2999529, lng: 20.781753 },
    { lat: 49.3000293, lng: 20.7816223 },
    { lat: 49.3014359, lng: 20.7811569 },
    { lat: 49.3013467, lng: 20.7808237 },
    { lat: 49.3012985, lng: 20.7795934 },
    { lat: 49.3045578, lng: 20.7777624 },
    { lat: 49.305047, lng: 20.7786277 },
    { lat: 49.3054582, lng: 20.7788354 },
    { lat: 49.3061138, lng: 20.7797948 },
    { lat: 49.3061868, lng: 20.7800507 },
    { lat: 49.306426, lng: 20.7803322 },
    { lat: 49.3067561, lng: 20.7793009 },
    { lat: 49.3068415, lng: 20.7785787 },
    { lat: 49.3068727, lng: 20.7779886 },
    { lat: 49.3068357, lng: 20.7779281 },
    { lat: 49.3072776, lng: 20.7749544 },
    { lat: 49.3075197, lng: 20.7725855 },
    { lat: 49.3070312, lng: 20.7684009 },
    { lat: 49.3069961, lng: 20.767295 },
    { lat: 49.307577, lng: 20.7627241 },
    { lat: 49.3082632, lng: 20.7592798 },
    { lat: 49.3084827, lng: 20.7570226 },
    { lat: 49.3089332, lng: 20.7559929 },
    { lat: 49.3094842, lng: 20.753555 },
    { lat: 49.3100599, lng: 20.7516319 },
    { lat: 49.3101888, lng: 20.7492921 },
    { lat: 49.3106034, lng: 20.7463649 },
    { lat: 49.3114765, lng: 20.744518 },
    { lat: 49.3115801, lng: 20.744296 },
  ],
  Ďurková: [
    { lat: 49.2379299, lng: 20.8714441 },
    { lat: 49.2395083, lng: 20.8746184 },
    { lat: 49.2397837, lng: 20.8750052 },
    { lat: 49.2397789, lng: 20.8758952 },
    { lat: 49.2402748, lng: 20.8758518 },
    { lat: 49.2408977, lng: 20.8764675 },
    { lat: 49.2419441, lng: 20.8779501 },
    { lat: 49.2433433, lng: 20.8781603 },
    { lat: 49.2445713, lng: 20.8795958 },
    { lat: 49.2454876, lng: 20.8801011 },
    { lat: 49.2457943, lng: 20.8807163 },
    { lat: 49.2461987, lng: 20.8798289 },
    { lat: 49.2467791, lng: 20.8794855 },
    { lat: 49.2465782, lng: 20.8786718 },
    { lat: 49.246816, lng: 20.8786462 },
    { lat: 49.2469284, lng: 20.8776452 },
    { lat: 49.2473599, lng: 20.8774887 },
    { lat: 49.2472792, lng: 20.8767684 },
    { lat: 49.2471002, lng: 20.8769645 },
    { lat: 49.2472817, lng: 20.8764721 },
    { lat: 49.247511, lng: 20.8760635 },
    { lat: 49.2478455, lng: 20.8760389 },
    { lat: 49.2488825, lng: 20.8756552 },
    { lat: 49.2495886, lng: 20.8752028 },
    { lat: 49.2498401, lng: 20.8752824 },
    { lat: 49.2499733, lng: 20.8752308 },
    { lat: 49.2503252, lng: 20.8752805 },
    { lat: 49.2510549, lng: 20.8752319 },
    { lat: 49.2517844, lng: 20.8751347 },
    { lat: 49.2521197, lng: 20.8749994 },
    { lat: 49.2526079, lng: 20.8749601 },
    { lat: 49.2528414, lng: 20.8748423 },
    { lat: 49.253006, lng: 20.8749518 },
    { lat: 49.2535364, lng: 20.8749589 },
    { lat: 49.2535287, lng: 20.8750319 },
    { lat: 49.2537405, lng: 20.8750671 },
    { lat: 49.2540635, lng: 20.8750351 },
    { lat: 49.2548966, lng: 20.8751426 },
    { lat: 49.2563472, lng: 20.8755055 },
    { lat: 49.2569151, lng: 20.8754265 },
    { lat: 49.257429, lng: 20.8754774 },
    { lat: 49.2582235, lng: 20.8753776 },
    { lat: 49.2593124, lng: 20.8747926 },
    { lat: 49.2593597, lng: 20.8747614 },
    { lat: 49.2581439, lng: 20.8729888 },
    { lat: 49.2569325, lng: 20.8724132 },
    { lat: 49.256169, lng: 20.8722303 },
    { lat: 49.2558211, lng: 20.8699025 },
    { lat: 49.2551403, lng: 20.8682149 },
    { lat: 49.2539635, lng: 20.8678598 },
    { lat: 49.2530308, lng: 20.8673683 },
    { lat: 49.2520588, lng: 20.8650896 },
    { lat: 49.2514948, lng: 20.8639951 },
    { lat: 49.2519429, lng: 20.8628081 },
    { lat: 49.2492807, lng: 20.8615278 },
    { lat: 49.2475921, lng: 20.8614526 },
    { lat: 49.2465544, lng: 20.8612524 },
    { lat: 49.245345, lng: 20.8613982 },
    { lat: 49.2441374, lng: 20.861799 },
    { lat: 49.2429765, lng: 20.861785 },
    { lat: 49.2421919, lng: 20.8609178 },
    { lat: 49.2409331, lng: 20.8571572 },
    { lat: 49.2406188, lng: 20.8553289 },
    { lat: 49.2403643, lng: 20.8532127 },
    { lat: 49.240234, lng: 20.8512023 },
    { lat: 49.2399129, lng: 20.8502625 },
    { lat: 49.2385177, lng: 20.8469742 },
    { lat: 49.2381777, lng: 20.8454229 },
    { lat: 49.2378862, lng: 20.8445283 },
    { lat: 49.2381251, lng: 20.8430131 },
    { lat: 49.2399901, lng: 20.8399856 },
    { lat: 49.2402813, lng: 20.8395132 },
    { lat: 49.238829, lng: 20.8395017 },
    { lat: 49.2387926, lng: 20.8396954 },
    { lat: 49.2386652, lng: 20.8403739 },
    { lat: 49.2382557, lng: 20.8402825 },
    { lat: 49.2368029, lng: 20.8395309 },
    { lat: 49.235366, lng: 20.8383541 },
    { lat: 49.2336504, lng: 20.8411152 },
    { lat: 49.2329572, lng: 20.8416818 },
    { lat: 49.2328032, lng: 20.8415489 },
    { lat: 49.2322698, lng: 20.842706 },
    { lat: 49.2318667, lng: 20.8429326 },
    { lat: 49.2315186, lng: 20.8429275 },
    { lat: 49.2316754, lng: 20.8426352 },
    { lat: 49.2316811, lng: 20.8424082 },
    { lat: 49.2319221, lng: 20.8417235 },
    { lat: 49.2321637, lng: 20.8404535 },
    { lat: 49.2323531, lng: 20.8404694 },
    { lat: 49.2324843, lng: 20.8400901 },
    { lat: 49.2323766, lng: 20.8399359 },
    { lat: 49.2323833, lng: 20.839738 },
    { lat: 49.2333874, lng: 20.8379916 },
    { lat: 49.2336189, lng: 20.83716 },
    { lat: 49.2336674, lng: 20.8366688 },
    { lat: 49.2336476, lng: 20.8361222 },
    { lat: 49.2335652, lng: 20.8356181 },
    { lat: 49.2338371, lng: 20.8345181 },
    { lat: 49.2338892, lng: 20.8339031 },
    { lat: 49.2338552, lng: 20.8334077 },
    { lat: 49.2339173, lng: 20.8331128 },
    { lat: 49.2338815, lng: 20.8327894 },
    { lat: 49.2339534, lng: 20.8323255 },
    { lat: 49.2344225, lng: 20.8315952 },
    { lat: 49.2339952, lng: 20.8301911 },
    { lat: 49.231796, lng: 20.825399 },
    { lat: 49.231242, lng: 20.825649 },
    { lat: 49.230104, lng: 20.826678 },
    { lat: 49.228942, lng: 20.829153 },
    { lat: 49.228667, lng: 20.8303 },
    { lat: 49.227606, lng: 20.831465 },
    { lat: 49.22628, lng: 20.832754 },
    { lat: 49.22604, lng: 20.833148 },
    { lat: 49.22581, lng: 20.83334 },
    { lat: 49.225551, lng: 20.833333 },
    { lat: 49.225257, lng: 20.833219 },
    { lat: 49.225035, lng: 20.833184 },
    { lat: 49.224892, lng: 20.833086 },
    { lat: 49.224721, lng: 20.832985 },
    { lat: 49.224981, lng: 20.8339075 },
    { lat: 49.2247693, lng: 20.8344346 },
    { lat: 49.2247836, lng: 20.8347569 },
    { lat: 49.2253282, lng: 20.8349493 },
    { lat: 49.2267993, lng: 20.8366654 },
    { lat: 49.2276671, lng: 20.8385045 },
    { lat: 49.227788, lng: 20.8403296 },
    { lat: 49.2290055, lng: 20.8416797 },
    { lat: 49.2297894, lng: 20.8439383 },
    { lat: 49.2305132, lng: 20.8453555 },
    { lat: 49.2308631, lng: 20.8457469 },
    { lat: 49.231083, lng: 20.8458598 },
    { lat: 49.2317657, lng: 20.8457807 },
    { lat: 49.2321943, lng: 20.8466675 },
    { lat: 49.2330159, lng: 20.8475245 },
    { lat: 49.2332624, lng: 20.8481835 },
    { lat: 49.2332246, lng: 20.8488945 },
    { lat: 49.233425, lng: 20.8496547 },
    { lat: 49.233725, lng: 20.8502014 },
    { lat: 49.2341032, lng: 20.8526168 },
    { lat: 49.2339754, lng: 20.8543838 },
    { lat: 49.2341083, lng: 20.856205 },
    { lat: 49.2354129, lng: 20.8582334 },
    { lat: 49.2361678, lng: 20.8597122 },
    { lat: 49.2364082, lng: 20.8605954 },
    { lat: 49.2367178, lng: 20.8619197 },
    { lat: 49.2366982, lng: 20.8622721 },
    { lat: 49.2365046, lng: 20.8621696 },
    { lat: 49.236486, lng: 20.8627823 },
    { lat: 49.2364827, lng: 20.8636859 },
    { lat: 49.2366751, lng: 20.865241 },
    { lat: 49.2366107, lng: 20.8656639 },
    { lat: 49.2366589, lng: 20.8670507 },
    { lat: 49.2372121, lng: 20.8673354 },
    { lat: 49.2371171, lng: 20.8688524 },
    { lat: 49.2374679, lng: 20.870135 },
    { lat: 49.2375342, lng: 20.8705609 },
    { lat: 49.2378949, lng: 20.8713435 },
    { lat: 49.2379299, lng: 20.8714441 },
  ],
  MalýLipník: [
    { lat: 49.35361, lng: 20.80857 },
    { lat: 49.3551267, lng: 20.8083341 },
    { lat: 49.3565737, lng: 20.8075675 },
    { lat: 49.3570531, lng: 20.8072094 },
    { lat: 49.3579823, lng: 20.8072617 },
    { lat: 49.3581619, lng: 20.806949 },
    { lat: 49.3587834, lng: 20.8070535 },
    { lat: 49.3607319, lng: 20.80523 },
    { lat: 49.3610693, lng: 20.8045059 },
    { lat: 49.3612411, lng: 20.8037753 },
    { lat: 49.3615596, lng: 20.8019731 },
    { lat: 49.3615751, lng: 20.79925 },
    { lat: 49.3613904, lng: 20.7984257 },
    { lat: 49.3614108, lng: 20.7975445 },
    { lat: 49.3612962, lng: 20.7974548 },
    { lat: 49.3613685, lng: 20.7972341 },
    { lat: 49.3609907, lng: 20.7964941 },
    { lat: 49.3604813, lng: 20.7962142 },
    { lat: 49.3602307, lng: 20.7957314 },
    { lat: 49.3599495, lng: 20.7943994 },
    { lat: 49.3596006, lng: 20.7940354 },
    { lat: 49.3591241, lng: 20.7933066 },
    { lat: 49.3586923, lng: 20.7929887 },
    { lat: 49.3578055, lng: 20.7918454 },
    { lat: 49.3575787, lng: 20.7914358 },
    { lat: 49.3565678, lng: 20.790873 },
    { lat: 49.3561427, lng: 20.7907546 },
    { lat: 49.3560535, lng: 20.7898365 },
    { lat: 49.3557581, lng: 20.7888065 },
    { lat: 49.3556067, lng: 20.7885606 },
    { lat: 49.3549359, lng: 20.7878144 },
    { lat: 49.3546481, lng: 20.7877575 },
    { lat: 49.3544358, lng: 20.7874772 },
    { lat: 49.3542663, lng: 20.7873901 },
    { lat: 49.3538102, lng: 20.7865641 },
    { lat: 49.3534169, lng: 20.7854121 },
    { lat: 49.3529511, lng: 20.784796 },
    { lat: 49.3525705, lng: 20.7839683 },
    { lat: 49.3524451, lng: 20.7834048 },
    { lat: 49.3523478, lng: 20.7831617 },
    { lat: 49.3518721, lng: 20.7826851 },
    { lat: 49.3518956, lng: 20.7818359 },
    { lat: 49.3520266, lng: 20.7815502 },
    { lat: 49.3522088, lng: 20.7807014 },
    { lat: 49.3521408, lng: 20.7794163 },
    { lat: 49.3521657, lng: 20.7787402 },
    { lat: 49.352328, lng: 20.7780639 },
    { lat: 49.3515828, lng: 20.7771138 },
    { lat: 49.3511162, lng: 20.7770361 },
    { lat: 49.3504928, lng: 20.7772318 },
    { lat: 49.350216, lng: 20.777234 },
    { lat: 49.3495953, lng: 20.7766962 },
    { lat: 49.3492225, lng: 20.7762369 },
    { lat: 49.3489544, lng: 20.7759988 },
    { lat: 49.348608, lng: 20.7752247 },
    { lat: 49.3483774, lng: 20.7749402 },
    { lat: 49.3481887, lng: 20.7745237 },
    { lat: 49.3477451, lng: 20.7738092 },
    { lat: 49.3470436, lng: 20.7729554 },
    { lat: 49.3458125, lng: 20.7720149 },
    { lat: 49.3455732, lng: 20.7711193 },
    { lat: 49.3455937, lng: 20.7703758 },
    { lat: 49.3453681, lng: 20.7688686 },
    { lat: 49.3452418, lng: 20.7676974 },
    { lat: 49.3451714, lng: 20.7673202 },
    { lat: 49.3449937, lng: 20.7669679 },
    { lat: 49.3447831, lng: 20.7667384 },
    { lat: 49.3447933, lng: 20.7663802 },
    { lat: 49.3447046, lng: 20.7662218 },
    { lat: 49.3447482, lng: 20.7658951 },
    { lat: 49.3444777, lng: 20.7651696 },
    { lat: 49.3444824, lng: 20.7647541 },
    { lat: 49.3443104, lng: 20.7634274 },
    { lat: 49.3438033, lng: 20.7627262 },
    { lat: 49.3435997, lng: 20.7626369 },
    { lat: 49.3433275, lng: 20.7623183 },
    { lat: 49.3430107, lng: 20.7618135 },
    { lat: 49.3429251, lng: 20.7615898 },
    { lat: 49.3424908, lng: 20.7610429 },
    { lat: 49.3425454, lng: 20.7605711 },
    { lat: 49.3427727, lng: 20.7598249 },
    { lat: 49.3428313, lng: 20.7593714 },
    { lat: 49.3423166, lng: 20.7581691 },
    { lat: 49.3422652, lng: 20.7578235 },
    { lat: 49.3423778, lng: 20.7567567 },
    { lat: 49.3420963, lng: 20.7565555 },
    { lat: 49.3414274, lng: 20.7553814 },
    { lat: 49.340749, lng: 20.7548678 },
    { lat: 49.3403481, lng: 20.7544417 },
    { lat: 49.3401372, lng: 20.7540763 },
    { lat: 49.3400012, lng: 20.7536907 },
    { lat: 49.3399782, lng: 20.7527729 },
    { lat: 49.340459, lng: 20.7512957 },
    { lat: 49.3404782, lng: 20.7510061 },
    { lat: 49.3411109, lng: 20.7498825 },
    { lat: 49.3415336, lng: 20.7492874 },
    { lat: 49.3419863, lng: 20.7475522 },
    { lat: 49.3425094, lng: 20.746057 },
    { lat: 49.3425863, lng: 20.7459088 },
    { lat: 49.343009, lng: 20.7455711 },
    { lat: 49.3430221, lng: 20.7455264 },
    { lat: 49.3411179, lng: 20.745693 },
    { lat: 49.3405962, lng: 20.7452613 },
    { lat: 49.3399437, lng: 20.7452349 },
    { lat: 49.338857, lng: 20.7456709 },
    { lat: 49.3377264, lng: 20.7487257 },
    { lat: 49.3376556, lng: 20.7493386 },
    { lat: 49.3374667, lng: 20.7502141 },
    { lat: 49.3372625, lng: 20.7507819 },
    { lat: 49.3366937, lng: 20.7520637 },
    { lat: 49.3362961, lng: 20.7522142 },
    { lat: 49.3361845, lng: 20.7523417 },
    { lat: 49.33612, lng: 20.7528825 },
    { lat: 49.3359643, lng: 20.7535835 },
    { lat: 49.3358097, lng: 20.7541833 },
    { lat: 49.3355992, lng: 20.7546678 },
    { lat: 49.3354007, lng: 20.7549504 },
    { lat: 49.3348877, lng: 20.7553069 },
    { lat: 49.3345451, lng: 20.7558108 },
    { lat: 49.3339951, lng: 20.7564757 },
    { lat: 49.3333933, lng: 20.7574901 },
    { lat: 49.3333072, lng: 20.7582149 },
    { lat: 49.3328279, lng: 20.7593854 },
    { lat: 49.3326246, lng: 20.7609209 },
    { lat: 49.3326558, lng: 20.7612691 },
    { lat: 49.3325521, lng: 20.7617471 },
    { lat: 49.3321728, lng: 20.7614864 },
    { lat: 49.3319218, lng: 20.7612282 },
    { lat: 49.3316115, lng: 20.760789 },
    { lat: 49.3306399, lng: 20.7598402 },
    { lat: 49.3302085, lng: 20.7605747 },
    { lat: 49.3302015, lng: 20.7616016 },
    { lat: 49.3290404, lng: 20.7626534 },
    { lat: 49.3288506, lng: 20.7630821 },
    { lat: 49.3264094, lng: 20.7650949 },
    { lat: 49.3265936, lng: 20.7659589 },
    { lat: 49.3264802, lng: 20.7673664 },
    { lat: 49.3258067, lng: 20.767607 },
    { lat: 49.325781, lng: 20.7679742 },
    { lat: 49.3255187, lng: 20.768395 },
    { lat: 49.3254268, lng: 20.7684024 },
    { lat: 49.325315, lng: 20.7687493 },
    { lat: 49.3253661, lng: 20.7689241 },
    { lat: 49.325096, lng: 20.7698344 },
    { lat: 49.3246359, lng: 20.7703239 },
    { lat: 49.3247343, lng: 20.7707962 },
    { lat: 49.3244594, lng: 20.7715325 },
    { lat: 49.3236413, lng: 20.7726457 },
    { lat: 49.3225806, lng: 20.7738326 },
    { lat: 49.3211781, lng: 20.7749998 },
    { lat: 49.3202722, lng: 20.7766294 },
    { lat: 49.3186978, lng: 20.7789073 },
    { lat: 49.3185874, lng: 20.7790028 },
    { lat: 49.3184334, lng: 20.7795947 },
    { lat: 49.3177877, lng: 20.7806474 },
    { lat: 49.3163252, lng: 20.7821616 },
    { lat: 49.3149455, lng: 20.7829807 },
    { lat: 49.3136168, lng: 20.7831614 },
    { lat: 49.312958, lng: 20.7836025 },
    { lat: 49.3127364, lng: 20.7834761 },
    { lat: 49.3122888, lng: 20.7867117 },
    { lat: 49.3122981, lng: 20.7894612 },
    { lat: 49.3125478, lng: 20.7911178 },
    { lat: 49.3129048, lng: 20.7917119 },
    { lat: 49.3138569, lng: 20.7921632 },
    { lat: 49.3144837, lng: 20.792289 },
    { lat: 49.3146348, lng: 20.7924666 },
    { lat: 49.3146153, lng: 20.7939231 },
    { lat: 49.3144575, lng: 20.7942389 },
    { lat: 49.3144595, lng: 20.7950479 },
    { lat: 49.3146565, lng: 20.7959945 },
    { lat: 49.3144496, lng: 20.7968571 },
    { lat: 49.3145112, lng: 20.798775 },
    { lat: 49.3157195, lng: 20.8036287 },
    { lat: 49.3163386, lng: 20.8057943 },
    { lat: 49.3164126, lng: 20.8069327 },
    { lat: 49.3165886, lng: 20.8076981 },
    { lat: 49.3194994, lng: 20.8111163 },
    { lat: 49.321798, lng: 20.8108288 },
    { lat: 49.3235166, lng: 20.8103693 },
    { lat: 49.3244591, lng: 20.8106475 },
    { lat: 49.3246472, lng: 20.81078 },
    { lat: 49.3248373, lng: 20.8110425 },
    { lat: 49.3250687, lng: 20.81116 },
    { lat: 49.3252337, lng: 20.8120628 },
    { lat: 49.3254578, lng: 20.8122317 },
    { lat: 49.3256379, lng: 20.8127932 },
    { lat: 49.3265047, lng: 20.8130909 },
    { lat: 49.327568, lng: 20.8126861 },
    { lat: 49.3283819, lng: 20.8121037 },
    { lat: 49.3304954, lng: 20.8115088 },
    { lat: 49.3324869, lng: 20.8119139 },
    { lat: 49.3343797, lng: 20.8117135 },
    { lat: 49.3346941, lng: 20.8121812 },
    { lat: 49.3356433, lng: 20.8124615 },
    { lat: 49.3365755, lng: 20.8130911 },
    { lat: 49.3373973, lng: 20.8126328 },
    { lat: 49.3379456, lng: 20.8127428 },
    { lat: 49.3380597, lng: 20.8129488 },
    { lat: 49.3390538, lng: 20.813223 },
    { lat: 49.3392752, lng: 20.8130496 },
    { lat: 49.3400423, lng: 20.8141827 },
    { lat: 49.340985, lng: 20.8148917 },
    { lat: 49.3420421, lng: 20.8152908 },
    { lat: 49.343223, lng: 20.8155671 },
    { lat: 49.343774, lng: 20.815753 },
    { lat: 49.34392, lng: 20.815597 },
    { lat: 49.343969, lng: 20.815485 },
    { lat: 49.34408, lng: 20.815372 },
    { lat: 49.34434, lng: 20.815225 },
    { lat: 49.344509, lng: 20.815205 },
    { lat: 49.344753, lng: 20.815146 },
    { lat: 49.34501, lng: 20.815082 },
    { lat: 49.345071, lng: 20.815064 },
    { lat: 49.345229, lng: 20.815011 },
    { lat: 49.345339, lng: 20.814972 },
    { lat: 49.345454, lng: 20.814911 },
    { lat: 49.345705, lng: 20.814756 },
    { lat: 49.345719, lng: 20.814744 },
    { lat: 49.345937, lng: 20.814567 },
    { lat: 49.346117, lng: 20.814404 },
    { lat: 49.346143, lng: 20.814383 },
    { lat: 49.346329, lng: 20.814178 },
    { lat: 49.346348, lng: 20.814156 },
    { lat: 49.346491, lng: 20.814027 },
    { lat: 49.346636, lng: 20.813874 },
    { lat: 49.346791, lng: 20.813736 },
    { lat: 49.346908, lng: 20.813639 },
    { lat: 49.347067, lng: 20.8134 },
    { lat: 49.347104, lng: 20.813325 },
    { lat: 49.347199, lng: 20.813131 },
    { lat: 49.347303, lng: 20.812915 },
    { lat: 49.347441, lng: 20.812609 },
    { lat: 49.347539, lng: 20.812253 },
    { lat: 49.347576, lng: 20.812161 },
    { lat: 49.347687, lng: 20.811836 },
    { lat: 49.347693, lng: 20.811798 },
    { lat: 49.347734, lng: 20.811587 },
    { lat: 49.347747, lng: 20.811483 },
    { lat: 49.347767, lng: 20.811363 },
    { lat: 49.347774, lng: 20.811311 },
    { lat: 49.347783, lng: 20.811251 },
    { lat: 49.347812, lng: 20.811052 },
    { lat: 49.34787, lng: 20.810522 },
    { lat: 49.347889, lng: 20.810326 },
    { lat: 49.347905, lng: 20.810256 },
    { lat: 49.347908, lng: 20.809978 },
    { lat: 49.347901, lng: 20.809853 },
    { lat: 49.34788, lng: 20.80964 },
    { lat: 49.347785, lng: 20.809157 },
    { lat: 49.347746, lng: 20.809025 },
    { lat: 49.347738, lng: 20.809007 },
    { lat: 49.347643, lng: 20.80876 },
    { lat: 49.347521, lng: 20.808552 },
    { lat: 49.347348, lng: 20.808291 },
    { lat: 49.346951, lng: 20.807723 },
    { lat: 49.346808, lng: 20.807533 },
    { lat: 49.34665, lng: 20.807222 },
    { lat: 49.346568, lng: 20.807094 },
    { lat: 49.346466, lng: 20.806901 },
    { lat: 49.34635, lng: 20.806567 },
    { lat: 49.346202, lng: 20.806306 },
    { lat: 49.346034, lng: 20.805984 },
    { lat: 49.345992, lng: 20.805925 },
    { lat: 49.345815, lng: 20.805635 },
    { lat: 49.345794, lng: 20.805603 },
    { lat: 49.345658, lng: 20.805392 },
    { lat: 49.345563, lng: 20.805233 },
    { lat: 49.345468, lng: 20.805061 },
    { lat: 49.345457, lng: 20.805044 },
    { lat: 49.345163, lng: 20.80457 },
    { lat: 49.345107, lng: 20.804478 },
    { lat: 49.344914, lng: 20.80416 },
    { lat: 49.344892, lng: 20.804125 },
    { lat: 49.34478, lng: 20.803935 },
    { lat: 49.344537, lng: 20.803527 },
    { lat: 49.344397, lng: 20.803312 },
    { lat: 49.344176, lng: 20.802941 },
    { lat: 49.343987, lng: 20.802576 },
    { lat: 49.343865, lng: 20.802231 },
    { lat: 49.343807, lng: 20.802068 },
    { lat: 49.34363, lng: 20.801614 },
    { lat: 49.343494, lng: 20.801299 },
    { lat: 49.343404, lng: 20.801133 },
    { lat: 49.343355, lng: 20.801045 },
    { lat: 49.343158, lng: 20.800679 },
    { lat: 49.342985, lng: 20.800319 },
    { lat: 49.342903, lng: 20.800012 },
    { lat: 49.34287, lng: 20.799914 },
    { lat: 49.342721, lng: 20.799407 },
    { lat: 49.342619, lng: 20.798983 },
    { lat: 49.342624, lng: 20.798657 },
    { lat: 49.342669, lng: 20.798402 },
    { lat: 49.342767, lng: 20.798128 },
    { lat: 49.342898, lng: 20.797877 },
    { lat: 49.342915, lng: 20.797848 },
    { lat: 49.343, lng: 20.797676 },
    { lat: 49.343172, lng: 20.79738 },
    { lat: 49.343241, lng: 20.797271 },
    { lat: 49.343262, lng: 20.797238 },
    { lat: 49.343493, lng: 20.796887 },
    { lat: 49.343527, lng: 20.796828 },
    { lat: 49.343683, lng: 20.796651 },
    { lat: 49.343803, lng: 20.796525 },
    { lat: 49.343825, lng: 20.796503 },
    { lat: 49.343972, lng: 20.796385 },
    { lat: 49.344072, lng: 20.796324 },
    { lat: 49.344148, lng: 20.796293 },
    { lat: 49.34423, lng: 20.796263 },
    { lat: 49.344399, lng: 20.796194 },
    { lat: 49.344429, lng: 20.796182 },
    { lat: 49.344444, lng: 20.796179 },
    { lat: 49.344514, lng: 20.796176 },
    { lat: 49.344799, lng: 20.796177 },
    { lat: 49.344831, lng: 20.796187 },
    { lat: 49.344959, lng: 20.79624 },
    { lat: 49.34513, lng: 20.796376 },
    { lat: 49.345245, lng: 20.796508 },
    { lat: 49.345324, lng: 20.796602 },
    { lat: 49.345435, lng: 20.796741 },
    { lat: 49.345519, lng: 20.796838 },
    { lat: 49.345544, lng: 20.796877 },
    { lat: 49.345687, lng: 20.797011 },
    { lat: 49.345816, lng: 20.797115 },
    { lat: 49.345846, lng: 20.797137 },
    { lat: 49.34617, lng: 20.797314 },
    { lat: 49.346298, lng: 20.797433 },
    { lat: 49.346427, lng: 20.797616 },
    { lat: 49.346464, lng: 20.797699 },
    { lat: 49.34664, lng: 20.797903 },
    { lat: 49.346695, lng: 20.798012 },
    { lat: 49.346705, lng: 20.79818 },
    { lat: 49.347019, lng: 20.798855 },
    { lat: 49.347131, lng: 20.798988 },
    { lat: 49.347212, lng: 20.799057 },
    { lat: 49.347561, lng: 20.799274 },
    { lat: 49.347994, lng: 20.799507 },
    { lat: 49.348177, lng: 20.799754 },
    { lat: 49.348411, lng: 20.800069 },
    { lat: 49.348803, lng: 20.800543 },
    { lat: 49.348929, lng: 20.800691 },
    { lat: 49.34894, lng: 20.800699 },
    { lat: 49.349133, lng: 20.800877 },
    { lat: 49.34914, lng: 20.800883 },
    { lat: 49.349318, lng: 20.801034 },
    { lat: 49.349408, lng: 20.801098 },
    { lat: 49.349577, lng: 20.801197 },
    { lat: 49.349812, lng: 20.801295 },
    { lat: 49.349854, lng: 20.801309 },
    { lat: 49.350087, lng: 20.80136 },
    { lat: 49.350139, lng: 20.801346 },
    { lat: 49.350248, lng: 20.801326 },
    { lat: 49.350367, lng: 20.801334 },
    { lat: 49.350503, lng: 20.801412 },
    { lat: 49.350657, lng: 20.801427 },
    { lat: 49.350722, lng: 20.801412 },
    { lat: 49.350832, lng: 20.801403 },
    { lat: 49.351056, lng: 20.801387 },
    { lat: 49.351347, lng: 20.801303 },
    { lat: 49.351372, lng: 20.80129 },
    { lat: 49.351561, lng: 20.801335 },
    { lat: 49.351663, lng: 20.801385 },
    { lat: 49.351834, lng: 20.801504 },
    { lat: 49.352115, lng: 20.801603 },
    { lat: 49.35228, lng: 20.801676 },
    { lat: 49.352471, lng: 20.801741 },
    { lat: 49.352701, lng: 20.801812 },
    { lat: 49.353211, lng: 20.801777 },
    { lat: 49.353519, lng: 20.801751 },
    { lat: 49.353675, lng: 20.801755 },
    { lat: 49.353804, lng: 20.801749 },
    { lat: 49.354237, lng: 20.801842 },
    { lat: 49.354649, lng: 20.801971 },
    { lat: 49.354801, lng: 20.802042 },
    { lat: 49.355033, lng: 20.802183 },
    { lat: 49.355276, lng: 20.802218 },
    { lat: 49.355372, lng: 20.802219 },
    { lat: 49.355518, lng: 20.802183 },
    { lat: 49.35574, lng: 20.802073 },
    { lat: 49.355949, lng: 20.801928 },
    { lat: 49.356077, lng: 20.801776 },
    { lat: 49.3562, lng: 20.801607 },
    { lat: 49.356237, lng: 20.801564 },
    { lat: 49.356287, lng: 20.801461 },
    { lat: 49.356341, lng: 20.801288 },
    { lat: 49.356376, lng: 20.801231 },
    { lat: 49.356591, lng: 20.800661 },
    { lat: 49.356661, lng: 20.80054 },
    { lat: 49.356748, lng: 20.800254 },
    { lat: 49.356821, lng: 20.800096 },
    { lat: 49.357118, lng: 20.799744 },
    { lat: 49.35725, lng: 20.79955 },
    { lat: 49.357388, lng: 20.799245 },
    { lat: 49.357459, lng: 20.799038 },
    { lat: 49.357486, lng: 20.798982 },
    { lat: 49.357606, lng: 20.798733 },
    { lat: 49.357711, lng: 20.798435 },
    { lat: 49.357727, lng: 20.798416 },
    { lat: 49.357798, lng: 20.798266 },
    { lat: 49.35781, lng: 20.798246 },
    { lat: 49.357895, lng: 20.798086 },
    { lat: 49.357967, lng: 20.798006 },
    { lat: 49.358038, lng: 20.797934 },
    { lat: 49.358112, lng: 20.79785 },
    { lat: 49.358152, lng: 20.797827 },
    { lat: 49.358225, lng: 20.797789 },
    { lat: 49.358438, lng: 20.797739 },
    { lat: 49.358468, lng: 20.797769 },
    { lat: 49.358565, lng: 20.797792 },
    { lat: 49.35857, lng: 20.797794 },
    { lat: 49.358704, lng: 20.797871 },
    { lat: 49.358734, lng: 20.797908 },
    { lat: 49.358779, lng: 20.797961 },
    { lat: 49.358863, lng: 20.798063 },
    { lat: 49.35907, lng: 20.798317 },
    { lat: 49.359099, lng: 20.798351 },
    { lat: 49.359212, lng: 20.79857 },
    { lat: 49.359276, lng: 20.798691 },
    { lat: 49.359284, lng: 20.798708 },
    { lat: 49.359394, lng: 20.798944 },
    { lat: 49.359456, lng: 20.799076 },
    { lat: 49.359576, lng: 20.799256 },
    { lat: 49.359651, lng: 20.799405 },
    { lat: 49.35975, lng: 20.799564 },
    { lat: 49.359939, lng: 20.799879 },
    { lat: 49.360039, lng: 20.800054 },
    { lat: 49.360127, lng: 20.800307 },
    { lat: 49.360208, lng: 20.800706 },
    { lat: 49.360238, lng: 20.801425 },
    { lat: 49.360238, lng: 20.801496 },
    { lat: 49.360193, lng: 20.801858 },
    { lat: 49.360192, lng: 20.80187 },
    { lat: 49.360127, lng: 20.802101 },
    { lat: 49.360097, lng: 20.802227 },
    { lat: 49.360047, lng: 20.802456 },
    { lat: 49.359991, lng: 20.802797 },
    { lat: 49.359976, lng: 20.80288 },
    { lat: 49.359956, lng: 20.803228 },
    { lat: 49.359899, lng: 20.803649 },
    { lat: 49.359885, lng: 20.803731 },
    { lat: 49.359812, lng: 20.803999 },
    { lat: 49.359768, lng: 20.804132 },
    { lat: 49.359678, lng: 20.804384 },
    { lat: 49.359607, lng: 20.804543 },
    { lat: 49.359516, lng: 20.804715 },
    { lat: 49.359506, lng: 20.804737 },
    { lat: 49.359423, lng: 20.804859 },
    { lat: 49.359395, lng: 20.804871 },
    { lat: 49.359281, lng: 20.805002 },
    { lat: 49.359136, lng: 20.805119 },
    { lat: 49.359121, lng: 20.805153 },
    { lat: 49.358963, lng: 20.805274 },
    { lat: 49.358829, lng: 20.805414 },
    { lat: 49.35875, lng: 20.805466 },
    { lat: 49.358603, lng: 20.805531 },
    { lat: 49.358591, lng: 20.805542 },
    { lat: 49.35834, lng: 20.805628 },
    { lat: 49.358221, lng: 20.805656 },
    { lat: 49.358155, lng: 20.80567 },
    { lat: 49.357898, lng: 20.805656 },
    { lat: 49.357818, lng: 20.805661 },
    { lat: 49.357643, lng: 20.805649 },
    { lat: 49.3575, lng: 20.805633 },
    { lat: 49.357397, lng: 20.805595 },
    { lat: 49.357315, lng: 20.805556 },
    { lat: 49.356934, lng: 20.805449 },
    { lat: 49.356723, lng: 20.805412 },
    { lat: 49.356378, lng: 20.805407 },
    { lat: 49.356218, lng: 20.805437 },
    { lat: 49.356058, lng: 20.805458 },
    { lat: 49.355899, lng: 20.805472 },
    { lat: 49.355716, lng: 20.805483 },
    { lat: 49.355573, lng: 20.805484 },
    { lat: 49.355391, lng: 20.805481 },
    { lat: 49.355273, lng: 20.805473 },
    { lat: 49.354831, lng: 20.805513 },
    { lat: 49.354604, lng: 20.805583 },
    { lat: 49.354472, lng: 20.805605 },
    { lat: 49.35433, lng: 20.805645 },
    { lat: 49.354204, lng: 20.805664 },
    { lat: 49.353994, lng: 20.805676 },
    { lat: 49.353789, lng: 20.8057 },
    { lat: 49.353561, lng: 20.805795 },
    { lat: 49.353344, lng: 20.805976 },
    { lat: 49.353314, lng: 20.806047 },
    { lat: 49.353254, lng: 20.806158 },
    { lat: 49.353215, lng: 20.806286 },
    { lat: 49.353207, lng: 20.80634 },
    { lat: 49.353169, lng: 20.806598 },
    { lat: 49.353155, lng: 20.806827 },
    { lat: 49.353151, lng: 20.80688 },
    { lat: 49.353168, lng: 20.807059 },
    { lat: 49.353171, lng: 20.807101 },
    { lat: 49.353232, lng: 20.80734 },
    { lat: 49.353304, lng: 20.807525 },
    { lat: 49.353482, lng: 20.808085 },
    { lat: 49.353524, lng: 20.808261 },
    { lat: 49.353563, lng: 20.808404 },
    { lat: 49.353576, lng: 20.808453 },
    { lat: 49.35361, lng: 20.80857 },
  ],
  Haligovce: [
    { lat: 49.382282, lng: 20.43525 },
    { lat: 49.3821946, lng: 20.434424 },
    { lat: 49.3820831, lng: 20.4340928 },
    { lat: 49.3818377, lng: 20.433645 },
    { lat: 49.3811551, lng: 20.4324017 },
    { lat: 49.381075, lng: 20.4324923 },
    { lat: 49.3809926, lng: 20.4326036 },
    { lat: 49.3807985, lng: 20.4328649 },
    { lat: 49.380775, lng: 20.4328966 },
    { lat: 49.3806946, lng: 20.4330063 },
    { lat: 49.3806679, lng: 20.4330412 },
    { lat: 49.3802501, lng: 20.4339097 },
    { lat: 49.3802411, lng: 20.4340912 },
    { lat: 49.3802417, lng: 20.4341623 },
    { lat: 49.3799381, lng: 20.4341446 },
    { lat: 49.3799757, lng: 20.4345174 },
    { lat: 49.3798269, lng: 20.4351419 },
    { lat: 49.3797423, lng: 20.4354976 },
    { lat: 49.3797394, lng: 20.4355087 },
    { lat: 49.3796748, lng: 20.4358602 },
    { lat: 49.3796553, lng: 20.4358518 },
    { lat: 49.37964, lng: 20.4359203 },
    { lat: 49.3795718, lng: 20.4362342 },
    { lat: 49.3795118, lng: 20.4364956 },
    { lat: 49.3794098, lng: 20.4370476 },
    { lat: 49.3793918, lng: 20.437141 },
    { lat: 49.3793108, lng: 20.4374715 },
    { lat: 49.3792389, lng: 20.4377119 },
    { lat: 49.3791777, lng: 20.4376711 },
    { lat: 49.3791256, lng: 20.4378747 },
    { lat: 49.3791044, lng: 20.4379687 },
    { lat: 49.379063, lng: 20.438145 },
    { lat: 49.378969, lng: 20.438117 },
    { lat: 49.37888, lng: 20.43809 },
    { lat: 49.378854, lng: 20.438082 },
    { lat: 49.377958, lng: 20.437744 },
    { lat: 49.377713, lng: 20.437639 },
    { lat: 49.377539, lng: 20.437409 },
    { lat: 49.377455, lng: 20.43727 },
    { lat: 49.377343, lng: 20.437136 },
    { lat: 49.377196, lng: 20.436895 },
    { lat: 49.37707, lng: 20.436588 },
    { lat: 49.376908, lng: 20.436323 },
    { lat: 49.376828, lng: 20.43613 },
    { lat: 49.37677, lng: 20.435974 },
    { lat: 49.376713, lng: 20.435836 },
    { lat: 49.376636, lng: 20.435637 },
    { lat: 49.376594, lng: 20.435458 },
    { lat: 49.376549, lng: 20.435314 },
    { lat: 49.376338, lng: 20.434868 },
    { lat: 49.376211, lng: 20.434687 },
    { lat: 49.376102, lng: 20.43456 },
    { lat: 49.375986, lng: 20.434444 },
    { lat: 49.375848, lng: 20.434251 },
    { lat: 49.375747, lng: 20.434166 },
    { lat: 49.375681, lng: 20.434079 },
    { lat: 49.37516, lng: 20.433385 },
    { lat: 49.375063, lng: 20.43321 },
    { lat: 49.374579, lng: 20.432292 },
    { lat: 49.374504, lng: 20.432174 },
    { lat: 49.374456, lng: 20.432098 },
    { lat: 49.37442, lng: 20.43204 },
    { lat: 49.37437, lng: 20.43196 },
    { lat: 49.374172, lng: 20.43164 },
    { lat: 49.374101, lng: 20.431524 },
    { lat: 49.3739, lng: 20.431227 },
    { lat: 49.373684, lng: 20.430904 },
    { lat: 49.373527, lng: 20.430654 },
    { lat: 49.373323, lng: 20.430094 },
    { lat: 49.373042, lng: 20.429441 },
    { lat: 49.372981, lng: 20.429313 },
    { lat: 49.37279, lng: 20.42892 },
    { lat: 49.372743, lng: 20.428572 },
    { lat: 49.372687, lng: 20.428386 },
    { lat: 49.372098, lng: 20.428095 },
    { lat: 49.371555, lng: 20.427809 },
    { lat: 49.3713, lng: 20.427674 },
    { lat: 49.37118, lng: 20.427671 },
    { lat: 49.370958, lng: 20.427665 },
    { lat: 49.370649, lng: 20.427753 },
    { lat: 49.37041, lng: 20.427495 },
    { lat: 49.369976, lng: 20.427633 },
    { lat: 49.369876, lng: 20.427583 },
    { lat: 49.369811, lng: 20.427468 },
    { lat: 49.369706, lng: 20.427483 },
    { lat: 49.36962, lng: 20.427403 },
    { lat: 49.369465, lng: 20.427381 },
    { lat: 49.369305, lng: 20.427419 },
    { lat: 49.369212, lng: 20.427375 },
    { lat: 49.36911, lng: 20.427372 },
    { lat: 49.368987, lng: 20.427273 },
    { lat: 49.368866, lng: 20.427335 },
    { lat: 49.368764, lng: 20.427357 },
    { lat: 49.368662, lng: 20.427426 },
    { lat: 49.368503, lng: 20.427434 },
    { lat: 49.368487, lng: 20.42738 },
    { lat: 49.368381, lng: 20.42746 },
    { lat: 49.36831, lng: 20.42759 },
    { lat: 49.3682, lng: 20.427623 },
    { lat: 49.368231, lng: 20.427665 },
    { lat: 49.368168, lng: 20.427676 },
    { lat: 49.368021, lng: 20.427642 },
    { lat: 49.367916, lng: 20.427568 },
    { lat: 49.367833, lng: 20.427404 },
    { lat: 49.367807, lng: 20.427242 },
    { lat: 49.367859, lng: 20.427148 },
    { lat: 49.36789, lng: 20.427137 },
    { lat: 49.367948, lng: 20.427103 },
    { lat: 49.368, lng: 20.427038 },
    { lat: 49.368001, lng: 20.42696 },
    { lat: 49.367963, lng: 20.426828 },
    { lat: 49.367895, lng: 20.426659 },
    { lat: 49.3678, lng: 20.426453 },
    { lat: 49.367716, lng: 20.426332 },
    { lat: 49.367634, lng: 20.426234 },
    { lat: 49.367581, lng: 20.426179 },
    { lat: 49.36757, lng: 20.426184 },
    { lat: 49.367581, lng: 20.426209 },
    { lat: 49.367474, lng: 20.426284 },
    { lat: 49.367315, lng: 20.426351 },
    { lat: 49.367182, lng: 20.426324 },
    { lat: 49.367031, lng: 20.426314 },
    { lat: 49.366823, lng: 20.426398 },
    { lat: 49.366721, lng: 20.426407 },
    { lat: 49.366397, lng: 20.426608 },
    { lat: 49.366256, lng: 20.426975 },
    { lat: 49.36624, lng: 20.426974 },
    { lat: 49.366151, lng: 20.42693 },
    { lat: 49.365681, lng: 20.426852 },
    { lat: 49.365591, lng: 20.426838 },
    { lat: 49.365589, lng: 20.426838 },
    { lat: 49.365221, lng: 20.426816 },
    { lat: 49.36512, lng: 20.426796 },
    { lat: 49.365044, lng: 20.426734 },
    { lat: 49.364976, lng: 20.426774 },
    { lat: 49.364837, lng: 20.426687 },
    { lat: 49.364747, lng: 20.42672 },
    { lat: 49.364654, lng: 20.426682 },
    { lat: 49.364572, lng: 20.42668 },
    { lat: 49.364452, lng: 20.426641 },
    { lat: 49.364348, lng: 20.426608 },
    { lat: 49.364339, lng: 20.426709 },
    { lat: 49.364246, lng: 20.426677 },
    { lat: 49.364133, lng: 20.426621 },
    { lat: 49.364037, lng: 20.426528 },
    { lat: 49.36389, lng: 20.426417 },
    { lat: 49.363561, lng: 20.426229 },
    { lat: 49.363402, lng: 20.426183 },
    { lat: 49.363207, lng: 20.426148 },
    { lat: 49.36314, lng: 20.42614 },
    { lat: 49.363009, lng: 20.426125 },
    { lat: 49.36285, lng: 20.426115 },
    { lat: 49.362698, lng: 20.426105 },
    { lat: 49.362518, lng: 20.426119 },
    { lat: 49.362409, lng: 20.426139 },
    { lat: 49.362292, lng: 20.426178 },
    { lat: 49.362132, lng: 20.42621 },
    { lat: 49.361863, lng: 20.426262 },
    { lat: 49.361709, lng: 20.426396 },
    { lat: 49.361514, lng: 20.426545 },
    { lat: 49.361411, lng: 20.426632 },
    { lat: 49.361349, lng: 20.426673 },
    { lat: 49.361023, lng: 20.426964 },
    { lat: 49.360877, lng: 20.427116 },
    { lat: 49.360672, lng: 20.427272 },
    { lat: 49.360437, lng: 20.427475 },
    { lat: 49.360276, lng: 20.427585 },
    { lat: 49.360229, lng: 20.427614 },
    { lat: 49.359735, lng: 20.427859 },
    { lat: 49.359626, lng: 20.427868 },
    { lat: 49.359407, lng: 20.427887 },
    { lat: 49.359322, lng: 20.427867 },
    { lat: 49.359298, lng: 20.427944 },
    { lat: 49.35924, lng: 20.427901 },
    { lat: 49.359158, lng: 20.427893 },
    { lat: 49.359128, lng: 20.427861 },
    { lat: 49.359112, lng: 20.427844 },
    { lat: 49.358948, lng: 20.427775 },
    { lat: 49.358811, lng: 20.427683 },
    { lat: 49.35871, lng: 20.427617 },
    { lat: 49.358565, lng: 20.427521 },
    { lat: 49.358348, lng: 20.427396 },
    { lat: 49.358118, lng: 20.427367 },
    { lat: 49.358071, lng: 20.427324 },
    { lat: 49.357776, lng: 20.427233 },
    { lat: 49.35757, lng: 20.427175 },
    { lat: 49.357209, lng: 20.427035 },
    { lat: 49.357152, lng: 20.426937 },
    { lat: 49.356969, lng: 20.426838 },
    { lat: 49.356872, lng: 20.426775 },
    { lat: 49.356764, lng: 20.426678 },
    { lat: 49.356539, lng: 20.426559 },
    { lat: 49.35654, lng: 20.426457 },
    { lat: 49.356427, lng: 20.426365 },
    { lat: 49.356257, lng: 20.426206 },
    { lat: 49.356057, lng: 20.425938 },
    { lat: 49.355876, lng: 20.425743 },
    { lat: 49.355777, lng: 20.425579 },
    { lat: 49.355699, lng: 20.425475 },
    { lat: 49.355513, lng: 20.425346 },
    { lat: 49.355459, lng: 20.425315 },
    { lat: 49.355366, lng: 20.425265 },
    { lat: 49.355077, lng: 20.424929 },
    { lat: 49.354995, lng: 20.424802 },
    { lat: 49.354815, lng: 20.424583 },
    { lat: 49.354726, lng: 20.424515 },
    { lat: 49.354645, lng: 20.4244 },
    { lat: 49.354486, lng: 20.424271 },
    { lat: 49.354412, lng: 20.424203 },
    { lat: 49.354098, lng: 20.424088 },
    { lat: 49.353807, lng: 20.423998 },
    { lat: 49.353713, lng: 20.42396 },
    { lat: 49.353371, lng: 20.42385 },
    { lat: 49.35318, lng: 20.423845 },
    { lat: 49.3533442, lng: 20.4248408 },
    { lat: 49.353384, lng: 20.4272786 },
    { lat: 49.3533524, lng: 20.4273911 },
    { lat: 49.3535409, lng: 20.4276761 },
    { lat: 49.3536707, lng: 20.4279839 },
    { lat: 49.3536974, lng: 20.4283669 },
    { lat: 49.3537544, lng: 20.4285652 },
    { lat: 49.353821, lng: 20.4285369 },
    { lat: 49.3539412, lng: 20.4286534 },
    { lat: 49.3542827, lng: 20.4296472 },
    { lat: 49.3542043, lng: 20.4304435 },
    { lat: 49.354268, lng: 20.4307257 },
    { lat: 49.3544577, lng: 20.4311608 },
    { lat: 49.3545617, lng: 20.431623 },
    { lat: 49.3546184, lng: 20.4324421 },
    { lat: 49.3545824, lng: 20.4335511 },
    { lat: 49.3548887, lng: 20.4336487 },
    { lat: 49.3549232, lng: 20.4337569 },
    { lat: 49.3549106, lng: 20.4345027 },
    { lat: 49.3546765, lng: 20.4354926 },
    { lat: 49.3545706, lng: 20.4356384 },
    { lat: 49.3545959, lng: 20.4364328 },
    { lat: 49.3546184, lng: 20.4365233 },
    { lat: 49.3547069, lng: 20.4365729 },
    { lat: 49.3546753, lng: 20.4370196 },
    { lat: 49.3544348, lng: 20.4378724 },
    { lat: 49.3542952, lng: 20.4382024 },
    { lat: 49.3541725, lng: 20.438384 },
    { lat: 49.3539804, lng: 20.4384504 },
    { lat: 49.3541841, lng: 20.4387247 },
    { lat: 49.3541576, lng: 20.4390043 },
    { lat: 49.3540322, lng: 20.4393527 },
    { lat: 49.3538331, lng: 20.4403799 },
    { lat: 49.3538053, lng: 20.4404502 },
    { lat: 49.3537508, lng: 20.4404369 },
    { lat: 49.3537323, lng: 20.4407228 },
    { lat: 49.3531644, lng: 20.4416736 },
    { lat: 49.3530574, lng: 20.4421785 },
    { lat: 49.352991, lng: 20.4421515 },
    { lat: 49.3526816, lng: 20.4429659 },
    { lat: 49.3524069, lng: 20.4434782 },
    { lat: 49.3523619, lng: 20.4436324 },
    { lat: 49.3523957, lng: 20.4437229 },
    { lat: 49.3523415, lng: 20.444109 },
    { lat: 49.3522579, lng: 20.44422 },
    { lat: 49.3522621, lng: 20.4444829 },
    { lat: 49.3521679, lng: 20.4446114 },
    { lat: 49.3520309, lng: 20.4446195 },
    { lat: 49.3519877, lng: 20.4450599 },
    { lat: 49.3518223, lng: 20.4451863 },
    { lat: 49.3516544, lng: 20.4455398 },
    { lat: 49.3516879, lng: 20.4456538 },
    { lat: 49.3516545, lng: 20.4458142 },
    { lat: 49.3515415, lng: 20.4458646 },
    { lat: 49.3515016, lng: 20.4459948 },
    { lat: 49.3515731, lng: 20.4461341 },
    { lat: 49.3511218, lng: 20.4470999 },
    { lat: 49.3511201, lng: 20.4472672 },
    { lat: 49.3509773, lng: 20.4474242 },
    { lat: 49.3508735, lng: 20.4477135 },
    { lat: 49.3506352, lng: 20.4477247 },
    { lat: 49.350406, lng: 20.4480048 },
    { lat: 49.3503479, lng: 20.4483193 },
    { lat: 49.3502091, lng: 20.4485243 },
    { lat: 49.3500744, lng: 20.448682 },
    { lat: 49.3498447, lng: 20.4487408 },
    { lat: 49.3496529, lng: 20.4490938 },
    { lat: 49.3494282, lng: 20.4493734 },
    { lat: 49.349335, lng: 20.4496514 },
    { lat: 49.3490507, lng: 20.4499659 },
    { lat: 49.3490274, lng: 20.4506688 },
    { lat: 49.3491272, lng: 20.4517159 },
    { lat: 49.3490809, lng: 20.4520427 },
    { lat: 49.3489309, lng: 20.4522119 },
    { lat: 49.348904, lng: 20.452515 },
    { lat: 49.3489341, lng: 20.4529038 },
    { lat: 49.3488882, lng: 20.453266 },
    { lat: 49.3485687, lng: 20.4545401 },
    { lat: 49.3485296, lng: 20.4570228 },
    { lat: 49.3487528, lng: 20.4581728 },
    { lat: 49.3486897, lng: 20.4582014 },
    { lat: 49.3485878, lng: 20.4583974 },
    { lat: 49.3481696, lng: 20.4586535 },
    { lat: 49.3480815, lng: 20.4588912 },
    { lat: 49.3479575, lng: 20.4589432 },
    { lat: 49.3479896, lng: 20.4593799 },
    { lat: 49.3476095, lng: 20.4602699 },
    { lat: 49.3474813, lng: 20.461056 },
    { lat: 49.3470873, lng: 20.4616628 },
    { lat: 49.346657, lng: 20.4618632 },
    { lat: 49.3465402, lng: 20.4618131 },
    { lat: 49.3465403, lng: 20.4622074 },
    { lat: 49.3464347, lng: 20.4623307 },
    { lat: 49.3463862, lng: 20.462509 },
    { lat: 49.3460603, lng: 20.4627653 },
    { lat: 49.3459182, lng: 20.4629716 },
    { lat: 49.3457336, lng: 20.4630636 },
    { lat: 49.3452579, lng: 20.4631372 },
    { lat: 49.3449149, lng: 20.4631181 },
    { lat: 49.3445586, lng: 20.4632247 },
    { lat: 49.3439699, lng: 20.4632844 },
    { lat: 49.3428796, lng: 20.4630894 },
    { lat: 49.3423336, lng: 20.4630602 },
    { lat: 49.34196, lng: 20.4635185 },
    { lat: 49.341741, lng: 20.4640163 },
    { lat: 49.3416844, lng: 20.4643076 },
    { lat: 49.3415894, lng: 20.4643596 },
    { lat: 49.3414557, lng: 20.4646074 },
    { lat: 49.3412385, lng: 20.4648188 },
    { lat: 49.3409649, lng: 20.4649979 },
    { lat: 49.3409401, lng: 20.4651466 },
    { lat: 49.3406646, lng: 20.4654581 },
    { lat: 49.3403193, lng: 20.4657077 },
    { lat: 49.3400526, lng: 20.4661668 },
    { lat: 49.339996, lng: 20.4662796 },
    { lat: 49.34008, lng: 20.4663875 },
    { lat: 49.34095, lng: 20.4664397 },
    { lat: 49.3411983, lng: 20.4663083 },
    { lat: 49.3414387, lng: 20.4662656 },
    { lat: 49.3419599, lng: 20.4663815 },
    { lat: 49.3424567, lng: 20.4676599 },
    { lat: 49.3430093, lng: 20.4674734 },
    { lat: 49.3440856, lng: 20.4677467 },
    { lat: 49.3447284, lng: 20.4681083 },
    { lat: 49.3452857, lng: 20.4678698 },
    { lat: 49.3465625, lng: 20.4689033 },
    { lat: 49.3468551, lng: 20.4687789 },
    { lat: 49.3473442, lng: 20.4695793 },
    { lat: 49.3483517, lng: 20.4699406 },
    { lat: 49.3489366, lng: 20.4698358 },
    { lat: 49.3500527, lng: 20.4702721 },
    { lat: 49.3503906, lng: 20.4706109 },
    { lat: 49.3514774, lng: 20.4712886 },
    { lat: 49.3523781, lng: 20.4709854 },
    { lat: 49.3526091, lng: 20.4712657 },
    { lat: 49.3529849, lng: 20.4709563 },
    { lat: 49.3539402, lng: 20.4713392 },
    { lat: 49.3549284, lng: 20.4715101 },
    { lat: 49.3553879, lng: 20.4712173 },
    { lat: 49.3563793, lng: 20.4702384 },
    { lat: 49.3573978, lng: 20.4707419 },
    { lat: 49.3586003, lng: 20.470745 },
    { lat: 49.3593687, lng: 20.4706704 },
    { lat: 49.3603566, lng: 20.4712336 },
    { lat: 49.3608312, lng: 20.4712525 },
    { lat: 49.3616685, lng: 20.4709757 },
    { lat: 49.3629936, lng: 20.4710653 },
    { lat: 49.3643184, lng: 20.4713936 },
    { lat: 49.3642558, lng: 20.4712835 },
    { lat: 49.365233, lng: 20.4711665 },
    { lat: 49.3656663, lng: 20.4712537 },
    { lat: 49.3664199, lng: 20.4709907 },
    { lat: 49.3676935, lng: 20.4712748 },
    { lat: 49.3687406, lng: 20.4698281 },
    { lat: 49.3691502, lng: 20.4688225 },
    { lat: 49.3692771, lng: 20.467848 },
    { lat: 49.3697502, lng: 20.4665257 },
    { lat: 49.3698684, lng: 20.4658634 },
    { lat: 49.3698243, lng: 20.4654962 },
    { lat: 49.369922, lng: 20.4645221 },
    { lat: 49.3702051, lng: 20.4637178 },
    { lat: 49.3706465, lng: 20.4637507 },
    { lat: 49.3707653, lng: 20.4639492 },
    { lat: 49.3709479, lng: 20.4640138 },
    { lat: 49.3712009, lng: 20.464371 },
    { lat: 49.371749, lng: 20.4641204 },
    { lat: 49.3718789, lng: 20.4648594 },
    { lat: 49.3723505, lng: 20.4649793 },
    { lat: 49.3725549, lng: 20.4649073 },
    { lat: 49.372875, lng: 20.4646401 },
    { lat: 49.3729734, lng: 20.4643815 },
    { lat: 49.3731868, lng: 20.4633409 },
    { lat: 49.3732811, lng: 20.4632459 },
    { lat: 49.3735044, lng: 20.4634071 },
    { lat: 49.3739094, lng: 20.4635508 },
    { lat: 49.3742138, lng: 20.4638378 },
    { lat: 49.374453, lng: 20.4639387 },
    { lat: 49.3749363, lng: 20.4639821 },
    { lat: 49.3751391, lng: 20.4637714 },
    { lat: 49.3754117, lng: 20.4636862 },
    { lat: 49.3754996, lng: 20.4637468 },
    { lat: 49.375745, lng: 20.4637174 },
    { lat: 49.3759041, lng: 20.4635813 },
    { lat: 49.376886, lng: 20.4634934 },
    { lat: 49.3770343, lng: 20.4634284 },
    { lat: 49.377175, lng: 20.463609 },
    { lat: 49.3775877, lng: 20.4635054 },
    { lat: 49.3777964, lng: 20.463529 },
    { lat: 49.377858, lng: 20.4635359 },
    { lat: 49.3781628, lng: 20.464737 },
    { lat: 49.3782508, lng: 20.4648655 },
    { lat: 49.3783571, lng: 20.4648764 },
    { lat: 49.3784882, lng: 20.4650124 },
    { lat: 49.378637, lng: 20.4653477 },
    { lat: 49.3789747, lng: 20.4657106 },
    { lat: 49.3797872, lng: 20.4659146 },
    { lat: 49.3801276, lng: 20.4661681 },
    { lat: 49.3810114, lng: 20.4666213 },
    { lat: 49.3818596, lng: 20.4661808 },
    { lat: 49.3820455, lng: 20.4661881 },
    { lat: 49.383333, lng: 20.4685688 },
    { lat: 49.3849436, lng: 20.4693789 },
    { lat: 49.3854063, lng: 20.4700409 },
    { lat: 49.3857261, lng: 20.4698605 },
    { lat: 49.3861487, lng: 20.4699957 },
    { lat: 49.3867442, lng: 20.4693575 },
    { lat: 49.3867977, lng: 20.4690414 },
    { lat: 49.3866639, lng: 20.4678671 },
    { lat: 49.3866867, lng: 20.4666657 },
    { lat: 49.3865934, lng: 20.4661557 },
    { lat: 49.3866035, lng: 20.4659405 },
    { lat: 49.3864087, lng: 20.4650932 },
    { lat: 49.3862786, lng: 20.4647735 },
    { lat: 49.386283, lng: 20.46429 },
    { lat: 49.3863582, lng: 20.4637111 },
    { lat: 49.3863455, lng: 20.4625811 },
    { lat: 49.3862576, lng: 20.4620056 },
    { lat: 49.3862061, lng: 20.4607132 },
    { lat: 49.3862541, lng: 20.4597098 },
    { lat: 49.3864734, lng: 20.457951 },
    { lat: 49.3864553, lng: 20.4578012 },
    { lat: 49.3862522, lng: 20.4573629 },
    { lat: 49.3865281, lng: 20.4564892 },
    { lat: 49.386638, lng: 20.4553326 },
    { lat: 49.3868132, lng: 20.4542364 },
    { lat: 49.3869257, lng: 20.4542681 },
    { lat: 49.3873091, lng: 20.4536177 },
    { lat: 49.38748, lng: 20.4530712 },
    { lat: 49.3875578, lng: 20.4525653 },
    { lat: 49.3876069, lng: 20.4517952 },
    { lat: 49.3876712, lng: 20.4515276 },
    { lat: 49.387614, lng: 20.450726 },
    { lat: 49.387609, lng: 20.450678 },
    { lat: 49.387562, lng: 20.450212 },
    { lat: 49.387532, lng: 20.450044 },
    { lat: 49.387486, lng: 20.449929 },
    { lat: 49.387358, lng: 20.449724 },
    { lat: 49.38705, lng: 20.449198 },
    { lat: 49.387029, lng: 20.449162 },
    { lat: 49.386578, lng: 20.448393 },
    { lat: 49.386566, lng: 20.448372 },
    { lat: 49.386384, lng: 20.447611 },
    { lat: 49.386348, lng: 20.446768 },
    { lat: 49.386493, lng: 20.446149 },
    { lat: 49.386527, lng: 20.44572 },
    { lat: 49.386458, lng: 20.444459 },
    { lat: 49.386244, lng: 20.442221 },
    { lat: 49.386442, lng: 20.44128 },
    { lat: 49.386069, lng: 20.440067 },
    { lat: 49.385112, lng: 20.437722 },
    { lat: 49.384849, lng: 20.437484 },
    { lat: 49.384753, lng: 20.43676 },
    { lat: 49.384214, lng: 20.435718 },
    { lat: 49.38369, lng: 20.43501 },
    { lat: 49.383608, lng: 20.434991 },
    { lat: 49.383506, lng: 20.435031 },
    { lat: 49.383156, lng: 20.435084 },
    { lat: 49.382912, lng: 20.43523 },
    { lat: 49.382714, lng: 20.435274 },
    { lat: 49.382406, lng: 20.435233 },
    { lat: 49.382359, lng: 20.435239 },
    { lat: 49.382349, lng: 20.43524 },
    { lat: 49.382282, lng: 20.43525 },
  ],
  Stráňany: [
    { lat: 49.3404819, lng: 20.5426224 },
    { lat: 49.3415061, lng: 20.543232 },
    { lat: 49.342122, lng: 20.5438518 },
    { lat: 49.3428348, lng: 20.5449315 },
    { lat: 49.3428644, lng: 20.5453727 },
    { lat: 49.3435676, lng: 20.5457287 },
    { lat: 49.3440777, lng: 20.5466265 },
    { lat: 49.3458327, lng: 20.5475495 },
    { lat: 49.346959, lng: 20.5483077 },
    { lat: 49.3480879, lng: 20.5507337 },
    { lat: 49.348679, lng: 20.551728 },
    { lat: 49.3492615, lng: 20.5524147 },
    { lat: 49.3498587, lng: 20.5527398 },
    { lat: 49.3502477, lng: 20.5532832 },
    { lat: 49.3510488, lng: 20.5540419 },
    { lat: 49.3525581, lng: 20.5564072 },
    { lat: 49.3534841, lng: 20.5580605 },
    { lat: 49.3537018, lng: 20.5583753 },
    { lat: 49.3539642, lng: 20.5587547 },
    { lat: 49.3552903, lng: 20.561077 },
    { lat: 49.356893, lng: 20.5617776 },
    { lat: 49.3595678, lng: 20.5625831 },
    { lat: 49.3622902, lng: 20.5639321 },
    { lat: 49.3624947, lng: 20.5637637 },
    { lat: 49.3642538, lng: 20.5635734 },
    { lat: 49.3663792, lng: 20.5614705 },
    { lat: 49.3685044, lng: 20.5590321 },
    { lat: 49.3718127, lng: 20.5570534 },
    { lat: 49.372844, lng: 20.5571205 },
    { lat: 49.3734906, lng: 20.5553103 },
    { lat: 49.3745164, lng: 20.5551976 },
    { lat: 49.3760177, lng: 20.5556479 },
    { lat: 49.3768878, lng: 20.556111 },
    { lat: 49.378099, lng: 20.557681 },
    { lat: 49.378358, lng: 20.557492 },
    { lat: 49.37864, lng: 20.557461 },
    { lat: 49.378942, lng: 20.556818 },
    { lat: 49.379063, lng: 20.556518 },
    { lat: 49.379183, lng: 20.556175 },
    { lat: 49.38026, lng: 20.555501 },
    { lat: 49.381034, lng: 20.55459 },
    { lat: 49.3810166, lng: 20.5544123 },
    { lat: 49.381004, lng: 20.554283 },
    { lat: 49.380945, lng: 20.553971 },
    { lat: 49.381158, lng: 20.553537 },
    { lat: 49.381465, lng: 20.552689 },
    { lat: 49.38181, lng: 20.552371 },
    { lat: 49.381987, lng: 20.552128 },
    { lat: 49.382437, lng: 20.551326 },
    { lat: 49.382562, lng: 20.551247 },
    { lat: 49.382837, lng: 20.551202 },
    { lat: 49.383486, lng: 20.550106 },
    { lat: 49.383989, lng: 20.54922 },
    { lat: 49.384112, lng: 20.548745 },
    { lat: 49.384293, lng: 20.548153 },
    { lat: 49.384522, lng: 20.547669 },
    { lat: 49.384748, lng: 20.547395 },
    { lat: 49.385458, lng: 20.546686 },
    { lat: 49.385542, lng: 20.546507 },
    { lat: 49.385589, lng: 20.545262 },
    { lat: 49.385642, lng: 20.544415 },
    { lat: 49.38576, lng: 20.543532 },
    { lat: 49.385919, lng: 20.542573 },
    { lat: 49.3861, lng: 20.541993 },
    { lat: 49.386487, lng: 20.540538 },
    { lat: 49.386773, lng: 20.539137 },
    { lat: 49.386972, lng: 20.538207 },
    { lat: 49.387066, lng: 20.537374 },
    { lat: 49.38684, lng: 20.535944 },
    { lat: 49.3858672, lng: 20.5356642 },
    { lat: 49.385324, lng: 20.5356123 },
    { lat: 49.3843535, lng: 20.5351483 },
    { lat: 49.3839958, lng: 20.5350681 },
    { lat: 49.3833908, lng: 20.5351151 },
    { lat: 49.3829511, lng: 20.5350629 },
    { lat: 49.3828089, lng: 20.5351153 },
    { lat: 49.3821202, lng: 20.5349703 },
    { lat: 49.381544, lng: 20.5351264 },
    { lat: 49.3811893, lng: 20.5350905 },
    { lat: 49.3806094, lng: 20.5348094 },
    { lat: 49.3802416, lng: 20.5345122 },
    { lat: 49.3790273, lng: 20.5339659 },
    { lat: 49.3776116, lng: 20.5336426 },
    { lat: 49.3773092, lng: 20.5336816 },
    { lat: 49.3764422, lng: 20.5340448 },
    { lat: 49.3758378, lng: 20.5345372 },
    { lat: 49.3754426, lng: 20.5346914 },
    { lat: 49.3748111, lng: 20.5345662 },
    { lat: 49.3746899, lng: 20.5346062 },
    { lat: 49.3743726, lng: 20.5344908 },
    { lat: 49.3738617, lng: 20.5345228 },
    { lat: 49.3728888, lng: 20.5344576 },
    { lat: 49.3727069, lng: 20.5345003 },
    { lat: 49.3720894, lng: 20.534272 },
    { lat: 49.3714384, lng: 20.5337948 },
    { lat: 49.3699711, lng: 20.5322818 },
    { lat: 49.3694963, lng: 20.5312733 },
    { lat: 49.3684243, lng: 20.5302551 },
    { lat: 49.3681019, lng: 20.5296269 },
    { lat: 49.3675205, lng: 20.5288447 },
    { lat: 49.3669374, lng: 20.5282939 },
    { lat: 49.3666159, lng: 20.5280947 },
    { lat: 49.3663696, lng: 20.5278255 },
    { lat: 49.3661984, lng: 20.5275762 },
    { lat: 49.3657324, lng: 20.5265963 },
    { lat: 49.3654954, lng: 20.5263029 },
    { lat: 49.3651135, lng: 20.5260201 },
    { lat: 49.3643661, lng: 20.5260242 },
    { lat: 49.3639979, lng: 20.5259284 },
    { lat: 49.3636551, lng: 20.5256061 },
    { lat: 49.3633255, lng: 20.5255803 },
    { lat: 49.3626579, lng: 20.5251802 },
    { lat: 49.3622683, lng: 20.5248118 },
    { lat: 49.3611941, lng: 20.5241325 },
    { lat: 49.3595688, lng: 20.5232151 },
    { lat: 49.3590688, lng: 20.5230553 },
    { lat: 49.3589665, lng: 20.5235852 },
    { lat: 49.3588406, lng: 20.523431 },
    { lat: 49.3584168, lng: 20.5232028 },
    { lat: 49.358012, lng: 20.5228181 },
    { lat: 49.3579235, lng: 20.5227979 },
    { lat: 49.3578606, lng: 20.5228961 },
    { lat: 49.3573414, lng: 20.5227689 },
    { lat: 49.3570396, lng: 20.5228571 },
    { lat: 49.3567951, lng: 20.5225116 },
    { lat: 49.3565343, lng: 20.5223109 },
    { lat: 49.3560065, lng: 20.5225008 },
    { lat: 49.3558818, lng: 20.5224766 },
    { lat: 49.3552142, lng: 20.5227193 },
    { lat: 49.3551383, lng: 20.5227977 },
    { lat: 49.3549565, lng: 20.5223826 },
    { lat: 49.3546808, lng: 20.5224176 },
    { lat: 49.3540028, lng: 20.5222864 },
    { lat: 49.3538808, lng: 20.5222142 },
    { lat: 49.353797, lng: 20.5220799 },
    { lat: 49.3535241, lng: 20.5219384 },
    { lat: 49.3534744, lng: 20.5218455 },
    { lat: 49.3530882, lng: 20.5216589 },
    { lat: 49.3528926, lng: 20.5216379 },
    { lat: 49.3516316, lng: 20.5219098 },
    { lat: 49.3514237, lng: 20.521767 },
    { lat: 49.3511896, lng: 20.5217633 },
    { lat: 49.3509967, lng: 20.5215969 },
    { lat: 49.3507587, lng: 20.5216506 },
    { lat: 49.3502761, lng: 20.5214445 },
    { lat: 49.3501176, lng: 20.5214781 },
    { lat: 49.3500514, lng: 20.5217954 },
    { lat: 49.3499714, lng: 20.5218203 },
    { lat: 49.3496661, lng: 20.5214791 },
    { lat: 49.3493344, lng: 20.5213932 },
    { lat: 49.3488049, lng: 20.5213251 },
    { lat: 49.3486243, lng: 20.5215526 },
    { lat: 49.3484977, lng: 20.5215101 },
    { lat: 49.3484491, lng: 20.5212283 },
    { lat: 49.3482489, lng: 20.5209289 },
    { lat: 49.3480872, lng: 20.5209389 },
    { lat: 49.3475105, lng: 20.5203816 },
    { lat: 49.3474697, lng: 20.520101 },
    { lat: 49.3471525, lng: 20.5198922 },
    { lat: 49.3470062, lng: 20.5196467 },
    { lat: 49.3464389, lng: 20.5190699 },
    { lat: 49.3461551, lng: 20.5184549 },
    { lat: 49.3456338, lng: 20.5178417 },
    { lat: 49.3455951, lng: 20.5177378 },
    { lat: 49.3453286, lng: 20.5175012 },
    { lat: 49.3446025, lng: 20.5171613 },
    { lat: 49.3444976, lng: 20.5169718 },
    { lat: 49.3443737, lng: 20.5169456 },
    { lat: 49.3442781, lng: 20.5168224 },
    { lat: 49.3440479, lng: 20.5167456 },
    { lat: 49.3436232, lng: 20.5160645 },
    { lat: 49.3433989, lng: 20.5161806 },
    { lat: 49.3428004, lng: 20.5152769 },
    { lat: 49.3425855, lng: 20.5152467 },
    { lat: 49.3423533, lng: 20.514904 },
    { lat: 49.3420606, lng: 20.5146559 },
    { lat: 49.3419496, lng: 20.5146709 },
    { lat: 49.3414933, lng: 20.5144139 },
    { lat: 49.3413908, lng: 20.5144173 },
    { lat: 49.3412725, lng: 20.5142907 },
    { lat: 49.3411143, lng: 20.514308 },
    { lat: 49.3408843, lng: 20.5141412 },
    { lat: 49.3406748, lng: 20.5143196 },
    { lat: 49.3405795, lng: 20.5142986 },
    { lat: 49.3404949, lng: 20.5141752 },
    { lat: 49.3402082, lng: 20.5142155 },
    { lat: 49.3397495, lng: 20.5144753 },
    { lat: 49.3385507, lng: 20.5154885 },
    { lat: 49.3381317, lng: 20.5155127 },
    { lat: 49.3377995, lng: 20.5156887 },
    { lat: 49.3372445, lng: 20.5161791 },
    { lat: 49.3371389, lng: 20.5163644 },
    { lat: 49.3363069, lng: 20.5170051 },
    { lat: 49.3347688, lng: 20.5163891 },
    { lat: 49.334882, lng: 20.5180846 },
    { lat: 49.3353493, lng: 20.5205635 },
    { lat: 49.3347954, lng: 20.5214159 },
    { lat: 49.3348116, lng: 20.5226829 },
    { lat: 49.3347772, lng: 20.5234937 },
    { lat: 49.3348327, lng: 20.5246354 },
    { lat: 49.3350755, lng: 20.5253314 },
    { lat: 49.3351429, lng: 20.5256934 },
    { lat: 49.3350454, lng: 20.5265331 },
    { lat: 49.3350614, lng: 20.5270335 },
    { lat: 49.3350029, lng: 20.5275836 },
    { lat: 49.3350647, lng: 20.5283168 },
    { lat: 49.3349776, lng: 20.5295267 },
    { lat: 49.334816, lng: 20.5308556 },
    { lat: 49.334646, lng: 20.531346 },
    { lat: 49.3354214, lng: 20.5329423 },
    { lat: 49.3358742, lng: 20.5331896 },
    { lat: 49.335984, lng: 20.5336661 },
    { lat: 49.3364147, lng: 20.5344835 },
    { lat: 49.3365861, lng: 20.5349489 },
    { lat: 49.3368541, lng: 20.5352915 },
    { lat: 49.336985, lng: 20.535547 },
    { lat: 49.3370584, lng: 20.5360125 },
    { lat: 49.3374464, lng: 20.5371373 },
    { lat: 49.3376654, lng: 20.5374596 },
    { lat: 49.3380347, lng: 20.538417 },
    { lat: 49.3382369, lng: 20.5385837 },
    { lat: 49.3385523, lng: 20.5390877 },
    { lat: 49.3390988, lng: 20.5395606 },
    { lat: 49.3393778, lng: 20.5397082 },
    { lat: 49.340048, lng: 20.539903 },
    { lat: 49.3402162, lng: 20.5400687 },
    { lat: 49.3404722, lng: 20.5408496 },
    { lat: 49.3405222, lng: 20.5412038 },
    { lat: 49.3405573, lng: 20.5417307 },
    { lat: 49.3404693, lng: 20.5425867 },
    { lat: 49.3404819, lng: 20.5426224 },
  ],
  PustéPole: [
    { lat: 49.217161, lng: 20.885552 },
    { lat: 49.215524, lng: 20.885625 },
    { lat: 49.214442, lng: 20.885873 },
    { lat: 49.214191, lng: 20.885924 },
    { lat: 49.214099, lng: 20.886533 },
    { lat: 49.214005, lng: 20.886444 },
    { lat: 49.213778, lng: 20.886608 },
    { lat: 49.21261, lng: 20.885564 },
    { lat: 49.212171, lng: 20.885421 },
    { lat: 49.211021, lng: 20.885653 },
    { lat: 49.211121, lng: 20.886267 },
    { lat: 49.210562, lng: 20.886242 },
    { lat: 49.210631, lng: 20.886741 },
    { lat: 49.210666, lng: 20.887183 },
    { lat: 49.210426, lng: 20.887345 },
    { lat: 49.210101, lng: 20.886396 },
    { lat: 49.210093, lng: 20.885671 },
    { lat: 49.209602, lng: 20.885947 },
    { lat: 49.20934, lng: 20.886174 },
    { lat: 49.209272, lng: 20.886233 },
    { lat: 49.209476, lng: 20.88713 },
    { lat: 49.209746, lng: 20.887803 },
    { lat: 49.210299, lng: 20.888787 },
    { lat: 49.210514, lng: 20.88904 },
    { lat: 49.210021, lng: 20.88959 },
    { lat: 49.209977, lng: 20.88965 },
    { lat: 49.209721, lng: 20.890109 },
    { lat: 49.208994, lng: 20.890476 },
    { lat: 49.208405, lng: 20.891954 },
    { lat: 49.207464, lng: 20.892869 },
    { lat: 49.207434, lng: 20.892953 },
    { lat: 49.207568, lng: 20.893075 },
    { lat: 49.206959, lng: 20.893495 },
    { lat: 49.205876, lng: 20.894484 },
    { lat: 49.205864, lng: 20.89469 },
    { lat: 49.205292, lng: 20.895001 },
    { lat: 49.205372, lng: 20.895267 },
    { lat: 49.205991, lng: 20.8953 },
    { lat: 49.205982, lng: 20.895563 },
    { lat: 49.206499, lng: 20.89558 },
    { lat: 49.206902, lng: 20.896054 },
    { lat: 49.207094, lng: 20.896703 },
    { lat: 49.207501, lng: 20.897356 },
    { lat: 49.208095, lng: 20.898171 },
    { lat: 49.209027, lng: 20.898808 },
    { lat: 49.208764, lng: 20.899026 },
    { lat: 49.208159, lng: 20.900056 },
    { lat: 49.208284, lng: 20.901785 },
    { lat: 49.207962, lng: 20.903503 },
    { lat: 49.207979, lng: 20.903593 },
    { lat: 49.206973, lng: 20.905841 },
    { lat: 49.207653, lng: 20.906867 },
    { lat: 49.20879, lng: 20.908115 },
    { lat: 49.208019, lng: 20.908591 },
    { lat: 49.206763, lng: 20.910836 },
    { lat: 49.205778, lng: 20.912426 },
    { lat: 49.205598, lng: 20.913881 },
    { lat: 49.205827, lng: 20.914569 },
    { lat: 49.205736, lng: 20.915553 },
    { lat: 49.206151, lng: 20.916616 },
    { lat: 49.20698, lng: 20.918229 },
    { lat: 49.210001, lng: 20.9209 },
    { lat: 49.210289, lng: 20.921155 },
    { lat: 49.210662, lng: 20.921832 },
    { lat: 49.210687, lng: 20.921862 },
    { lat: 49.211319, lng: 20.92265 },
    { lat: 49.2115486, lng: 20.9223238 },
    { lat: 49.217354, lng: 20.9165826 },
    { lat: 49.217894, lng: 20.9159684 },
    { lat: 49.2181143, lng: 20.9158637 },
    { lat: 49.2207785, lng: 20.9133852 },
    { lat: 49.2213337, lng: 20.9127596 },
    { lat: 49.2213434, lng: 20.911269 },
    { lat: 49.2214892, lng: 20.9108133 },
    { lat: 49.2221876, lng: 20.9092558 },
    { lat: 49.2222966, lng: 20.9084148 },
    { lat: 49.2226436, lng: 20.9084251 },
    { lat: 49.2231111, lng: 20.9074009 },
    { lat: 49.2235105, lng: 20.9072901 },
    { lat: 49.2238416, lng: 20.9061595 },
    { lat: 49.2241854, lng: 20.905604 },
    { lat: 49.2246001, lng: 20.9042792 },
    { lat: 49.2249468, lng: 20.9020772 },
    { lat: 49.2251054, lng: 20.9013723 },
    { lat: 49.2251295, lng: 20.9009815 },
    { lat: 49.2250041, lng: 20.9008625 },
    { lat: 49.2249794, lng: 20.9011391 },
    { lat: 49.2248182, lng: 20.9013602 },
    { lat: 49.2246172, lng: 20.9010422 },
    { lat: 49.2244787, lng: 20.9006699 },
    { lat: 49.2244202, lng: 20.9003382 },
    { lat: 49.2244933, lng: 20.9001697 },
    { lat: 49.2242175, lng: 20.8999994 },
    { lat: 49.2238958, lng: 20.9001395 },
    { lat: 49.2239975, lng: 20.900349 },
    { lat: 49.2239087, lng: 20.9005205 },
    { lat: 49.2235294, lng: 20.9006241 },
    { lat: 49.2229293, lng: 20.9003478 },
    { lat: 49.2224224, lng: 20.9007022 },
    { lat: 49.2222449, lng: 20.9010784 },
    { lat: 49.2219771, lng: 20.9010817 },
    { lat: 49.2218295, lng: 20.9005064 },
    { lat: 49.2216142, lng: 20.9002284 },
    { lat: 49.2213579, lng: 20.9006434 },
    { lat: 49.2208684, lng: 20.9004075 },
    { lat: 49.2206728, lng: 20.9008192 },
    { lat: 49.2204277, lng: 20.9002566 },
    { lat: 49.2199183, lng: 20.9000034 },
    { lat: 49.2195227, lng: 20.9003509 },
    { lat: 49.2192761, lng: 20.9004163 },
    { lat: 49.2192139, lng: 20.9011139 },
    { lat: 49.2187862, lng: 20.901074 },
    { lat: 49.2186994, lng: 20.9009069 },
    { lat: 49.2185372, lng: 20.9009002 },
    { lat: 49.218341, lng: 20.9005854 },
    { lat: 49.2182857, lng: 20.9006182 },
    { lat: 49.2180632, lng: 20.9004243 },
    { lat: 49.2180071, lng: 20.9002645 },
    { lat: 49.217526, lng: 20.9002784 },
    { lat: 49.2166619, lng: 20.9004275 },
    { lat: 49.2165654, lng: 20.9003332 },
    { lat: 49.2164995, lng: 20.900113 },
    { lat: 49.2165618, lng: 20.9000599 },
    { lat: 49.2165554, lng: 20.8998699 },
    { lat: 49.2167229, lng: 20.8997945 },
    { lat: 49.2168386, lng: 20.899222 },
    { lat: 49.2167132, lng: 20.8991685 },
    { lat: 49.2166826, lng: 20.8992681 },
    { lat: 49.2166391, lng: 20.8992438 },
    { lat: 49.2165478, lng: 20.8991732 },
    { lat: 49.2165689, lng: 20.8990657 },
    { lat: 49.2162794, lng: 20.8988565 },
    { lat: 49.2161604, lng: 20.8991679 },
    { lat: 49.2160257, lng: 20.8987393 },
    { lat: 49.215895, lng: 20.8985007 },
    { lat: 49.2149408, lng: 20.8977108 },
    { lat: 49.2143413, lng: 20.89762 },
    { lat: 49.214405, lng: 20.897233 },
    { lat: 49.2146649, lng: 20.8972349 },
    { lat: 49.2148633, lng: 20.8968079 },
    { lat: 49.2152294, lng: 20.8963471 },
    { lat: 49.2155138, lng: 20.8957024 },
    { lat: 49.2156083, lng: 20.8951701 },
    { lat: 49.2159006, lng: 20.8942906 },
    { lat: 49.2158824, lng: 20.8938554 },
    { lat: 49.2164988, lng: 20.8921725 },
    { lat: 49.2172696, lng: 20.8906009 },
    { lat: 49.2180228, lng: 20.8896672 },
    { lat: 49.2183459, lng: 20.8889408 },
    { lat: 49.2179875, lng: 20.8884891 },
    { lat: 49.2170482, lng: 20.8890716 },
    { lat: 49.2169256, lng: 20.8876387 },
    { lat: 49.217161, lng: 20.885552 },
  ],
  NižnéRužbachy: [
    { lat: 49.2985264, lng: 20.5856207 },
    { lat: 49.298624, lng: 20.5852994 },
    { lat: 49.2987333, lng: 20.5851206 },
    { lat: 49.2989476, lng: 20.5849732 },
    { lat: 49.2982736, lng: 20.5830942 },
    { lat: 49.2979548, lng: 20.5818766 },
    { lat: 49.2976123, lng: 20.5807861 },
    { lat: 49.2974812, lng: 20.580214 },
    { lat: 49.2974907, lng: 20.5800988 },
    { lat: 49.2972542, lng: 20.5791685 },
    { lat: 49.29718, lng: 20.5782756 },
    { lat: 49.2969607, lng: 20.5775696 },
    { lat: 49.2969094, lng: 20.5772425 },
    { lat: 49.2956949, lng: 20.5744301 },
    { lat: 49.2950071, lng: 20.572634 },
    { lat: 49.2942374, lng: 20.5713131 },
    { lat: 49.2940635, lng: 20.571202 },
    { lat: 49.2938764, lng: 20.571228 },
    { lat: 49.2937799, lng: 20.5711418 },
    { lat: 49.2934372, lng: 20.5701398 },
    { lat: 49.2933984, lng: 20.5688274 },
    { lat: 49.2928324, lng: 20.5687422 },
    { lat: 49.2926572, lng: 20.5686265 },
    { lat: 49.2926385, lng: 20.5684557 },
    { lat: 49.2927651, lng: 20.5681294 },
    { lat: 49.2929842, lng: 20.5679354 },
    { lat: 49.2930405, lng: 20.5677193 },
    { lat: 49.2929023, lng: 20.5676304 },
    { lat: 49.2923611, lng: 20.5676077 },
    { lat: 49.2919323, lng: 20.5676734 },
    { lat: 49.2917392, lng: 20.5661762 },
    { lat: 49.2915431, lng: 20.5651759 },
    { lat: 49.2916554, lng: 20.5646156 },
    { lat: 49.2918031, lng: 20.5645002 },
    { lat: 49.2920857, lng: 20.5638526 },
    { lat: 49.292818, lng: 20.5612421 },
    { lat: 49.2928503, lng: 20.5609256 },
    { lat: 49.2931198, lng: 20.5602941 },
    { lat: 49.2932077, lng: 20.5596151 },
    { lat: 49.2934349, lng: 20.5588733 },
    { lat: 49.2934682, lng: 20.5583965 },
    { lat: 49.2933326, lng: 20.5581394 },
    { lat: 49.2930057, lng: 20.5572293 },
    { lat: 49.2928341, lng: 20.5561991 },
    { lat: 49.292467, lng: 20.5555794 },
    { lat: 49.2923523, lng: 20.5552726 },
    { lat: 49.292254, lng: 20.5547137 },
    { lat: 49.292253, lng: 20.5544083 },
    { lat: 49.2921401, lng: 20.5540901 },
    { lat: 49.2921284, lng: 20.5538653 },
    { lat: 49.2920128, lng: 20.5534388 },
    { lat: 49.2918709, lng: 20.5531895 },
    { lat: 49.2917341, lng: 20.5533 },
    { lat: 49.291407, lng: 20.5526957 },
    { lat: 49.2911107, lng: 20.5512442 },
    { lat: 49.2909571, lng: 20.5507099 },
    { lat: 49.2910533, lng: 20.5501978 },
    { lat: 49.2910492, lng: 20.549901 },
    { lat: 49.2908965, lng: 20.549267 },
    { lat: 49.290933, lng: 20.5490075 },
    { lat: 49.2908517, lng: 20.5484414 },
    { lat: 49.2907296, lng: 20.548489 },
    { lat: 49.2906874, lng: 20.5484771 },
    { lat: 49.2896519, lng: 20.549156 },
    { lat: 49.2894184, lng: 20.549233 },
    { lat: 49.2891372, lng: 20.5491817 },
    { lat: 49.2887133, lng: 20.5497178 },
    { lat: 49.2879198, lng: 20.5501174 },
    { lat: 49.2870853, lng: 20.5511271 },
    { lat: 49.2865425, lng: 20.5514624 },
    { lat: 49.2863712, lng: 20.5514864 },
    { lat: 49.2863244, lng: 20.5515622 },
    { lat: 49.2862223, lng: 20.5514797 },
    { lat: 49.2860291, lng: 20.5516137 },
    { lat: 49.2856105, lng: 20.5517393 },
    { lat: 49.2854325, lng: 20.5519229 },
    { lat: 49.2852257, lng: 20.5519907 },
    { lat: 49.2837932, lng: 20.5536695 },
    { lat: 49.2834236, lng: 20.5538446 },
    { lat: 49.2831936, lng: 20.5538646 },
    { lat: 49.2829055, lng: 20.55408 },
    { lat: 49.2828635, lng: 20.5541964 },
    { lat: 49.2827145, lng: 20.5541864 },
    { lat: 49.2820262, lng: 20.5551564 },
    { lat: 49.2819265, lng: 20.5551386 },
    { lat: 49.2807196, lng: 20.5558464 },
    { lat: 49.2805562, lng: 20.5560864 },
    { lat: 49.2804175, lng: 20.5565143 },
    { lat: 49.2802487, lng: 20.556819 },
    { lat: 49.2800686, lng: 20.5570352 },
    { lat: 49.2799411, lng: 20.5570577 },
    { lat: 49.2788841, lng: 20.5585072 },
    { lat: 49.2781495, lng: 20.5587051 },
    { lat: 49.2772191, lng: 20.559582 },
    { lat: 49.276205, lng: 20.5603586 },
    { lat: 49.2737902, lng: 20.561096 },
    { lat: 49.2735843, lng: 20.5608835 },
    { lat: 49.2735979, lng: 20.5610435 },
    { lat: 49.2735, lng: 20.5612884 },
    { lat: 49.2733467, lng: 20.5614701 },
    { lat: 49.2733672, lng: 20.5616003 },
    { lat: 49.2732899, lng: 20.5616985 },
    { lat: 49.2725457, lng: 20.5621596 },
    { lat: 49.2722952, lng: 20.5622044 },
    { lat: 49.272968, lng: 20.5629351 },
    { lat: 49.2733224, lng: 20.5635182 },
    { lat: 49.2734761, lng: 20.5642733 },
    { lat: 49.2735585, lng: 20.5652707 },
    { lat: 49.2734949, lng: 20.5661457 },
    { lat: 49.2725119, lng: 20.5725037 },
    { lat: 49.2724598, lng: 20.5727097 },
    { lat: 49.2719896, lng: 20.5736167 },
    { lat: 49.2718636, lng: 20.5742121 },
    { lat: 49.2718177, lng: 20.57533 },
    { lat: 49.271879, lng: 20.5765195 },
    { lat: 49.2718275, lng: 20.5773613 },
    { lat: 49.2716082, lng: 20.579194 },
    { lat: 49.2717771, lng: 20.5797831 },
    { lat: 49.2721039, lng: 20.5802871 },
    { lat: 49.2729226, lng: 20.580979 },
    { lat: 49.2734435, lng: 20.5813104 },
    { lat: 49.273435, lng: 20.5817272 },
    { lat: 49.2731628, lng: 20.5830384 },
    { lat: 49.2718567, lng: 20.5829354 },
    { lat: 49.2723546, lng: 20.58474 },
    { lat: 49.2716781, lng: 20.5851763 },
    { lat: 49.2712694, lng: 20.5853004 },
    { lat: 49.2707947, lng: 20.5857739 },
    { lat: 49.2705248, lng: 20.5858444 },
    { lat: 49.2700096, lng: 20.5861606 },
    { lat: 49.269835, lng: 20.5861927 },
    { lat: 49.2696631, lng: 20.5861373 },
    { lat: 49.2695088, lng: 20.5862261 },
    { lat: 49.2695595, lng: 20.5866627 },
    { lat: 49.2691325, lng: 20.5864725 },
    { lat: 49.2690107, lng: 20.5865203 },
    { lat: 49.2688729, lng: 20.5867598 },
    { lat: 49.2686686, lng: 20.5868698 },
    { lat: 49.2683118, lng: 20.5868405 },
    { lat: 49.2682561, lng: 20.5869188 },
    { lat: 49.2682257, lng: 20.5874448 },
    { lat: 49.268167, lng: 20.5875319 },
    { lat: 49.2680496, lng: 20.5875534 },
    { lat: 49.2676092, lng: 20.5873006 },
    { lat: 49.2673455, lng: 20.5873261 },
    { lat: 49.2668097, lng: 20.5879151 },
    { lat: 49.266719, lng: 20.5878915 },
    { lat: 49.2662725, lng: 20.5873807 },
    { lat: 49.2661384, lng: 20.5873634 },
    { lat: 49.2660734, lng: 20.587355 },
    { lat: 49.2659677, lng: 20.5874757 },
    { lat: 49.2658074, lng: 20.5875567 },
    { lat: 49.2654636, lng: 20.5881972 },
    { lat: 49.2654322, lng: 20.58845 },
    { lat: 49.2654485, lng: 20.5886389 },
    { lat: 49.2655041, lng: 20.5892819 },
    { lat: 49.2655053, lng: 20.5893507 },
    { lat: 49.2655173, lng: 20.5900582 },
    { lat: 49.2655221, lng: 20.5903369 },
    { lat: 49.2655579, lng: 20.590683 },
    { lat: 49.2656172, lng: 20.591257 },
    { lat: 49.2656829, lng: 20.5918926 },
    { lat: 49.2656987, lng: 20.592046 },
    { lat: 49.2656567, lng: 20.5926256 },
    { lat: 49.2661216, lng: 20.5940507 },
    { lat: 49.2661332, lng: 20.5942783 },
    { lat: 49.2662666, lng: 20.5946999 },
    { lat: 49.2663351, lng: 20.5953473 },
    { lat: 49.2665529, lng: 20.5962931 },
    { lat: 49.2669462, lng: 20.5989802 },
    { lat: 49.2674279, lng: 20.6005324 },
    { lat: 49.267614, lng: 20.6013533 },
    { lat: 49.2674832, lng: 20.6014066 },
    { lat: 49.2669586, lng: 20.6014083 },
    { lat: 49.2666474, lng: 20.6015492 },
    { lat: 49.2658924, lng: 20.6025823 },
    { lat: 49.2655292, lng: 20.6032178 },
    { lat: 49.2653653, lng: 20.6036039 },
    { lat: 49.2651635, lng: 20.6043779 },
    { lat: 49.2660445, lng: 20.6043295 },
    { lat: 49.2677309, lng: 20.604676 },
    { lat: 49.2680463, lng: 20.6046847 },
    { lat: 49.2698909, lng: 20.6047355 },
    { lat: 49.2735849, lng: 20.6043499 },
    { lat: 49.2746305, lng: 20.604745 },
    { lat: 49.2756876, lng: 20.6053423 },
    { lat: 49.2766537, lng: 20.6057856 },
    { lat: 49.2796265, lng: 20.605096 },
    { lat: 49.2806816, lng: 20.6052333 },
    { lat: 49.2808929, lng: 20.6053489 },
    { lat: 49.2804975, lng: 20.6064906 },
    { lat: 49.2804366, lng: 20.60683 },
    { lat: 49.2806661, lng: 20.6075732 },
    { lat: 49.2807783, lng: 20.6077873 },
    { lat: 49.2813276, lng: 20.608315 },
    { lat: 49.2817395, lng: 20.6086545 },
    { lat: 49.2820603, lng: 20.6088131 },
    { lat: 49.2828758, lng: 20.6081649 },
    { lat: 49.2842685, lng: 20.6076443 },
    { lat: 49.285486, lng: 20.6066675 },
    { lat: 49.2866032, lng: 20.6055257 },
    { lat: 49.287207, lng: 20.6050741 },
    { lat: 49.2883645, lng: 20.6034265 },
    { lat: 49.2887544, lng: 20.6027553 },
    { lat: 49.2895151, lng: 20.6017331 },
    { lat: 49.2896293, lng: 20.6015796 },
    { lat: 49.2900997, lng: 20.6010476 },
    { lat: 49.2906803, lng: 20.600506 },
    { lat: 49.290779, lng: 20.6003144 },
    { lat: 49.290928, lng: 20.6001691 },
    { lat: 49.291348, lng: 20.5998913 },
    { lat: 49.2914412, lng: 20.5998866 },
    { lat: 49.2924345, lng: 20.5991813 },
    { lat: 49.2932573, lng: 20.5987128 },
    { lat: 49.2937056, lng: 20.5986569 },
    { lat: 49.2943173, lng: 20.5987631 },
    { lat: 49.2943179, lng: 20.5987629 },
    { lat: 49.2943835, lng: 20.5969806 },
    { lat: 49.2938162, lng: 20.5949598 },
    { lat: 49.2935055, lng: 20.5940596 },
    { lat: 49.293773, lng: 20.5925565 },
    { lat: 49.2937267, lng: 20.590347 },
    { lat: 49.2939159, lng: 20.5908496 },
    { lat: 49.2943413, lng: 20.5911559 },
    { lat: 49.2949185, lng: 20.5914568 },
    { lat: 49.2950942, lng: 20.5917092 },
    { lat: 49.2959881, lng: 20.5900335 },
    { lat: 49.2964208, lng: 20.5893646 },
    { lat: 49.2969864, lng: 20.5889156 },
    { lat: 49.2972028, lng: 20.5883905 },
    { lat: 49.2974724, lng: 20.5875045 },
    { lat: 49.2978285, lng: 20.5861178 },
    { lat: 49.2984289, lng: 20.5858847 },
    { lat: 49.2985264, lng: 20.5856207 },
  ],
  Údol: [
    { lat: 49.3127364, lng: 20.7834761 },
    { lat: 49.3121759, lng: 20.7831174 },
    { lat: 49.3113587, lng: 20.7829876 },
    { lat: 49.311098, lng: 20.7832244 },
    { lat: 49.3102822, lng: 20.7817656 },
    { lat: 49.3087006, lng: 20.7799035 },
    { lat: 49.3073256, lng: 20.7789107 },
    { lat: 49.3068727, lng: 20.7779886 },
    { lat: 49.3068415, lng: 20.7785787 },
    { lat: 49.3067561, lng: 20.7793009 },
    { lat: 49.306426, lng: 20.7803322 },
    { lat: 49.3061868, lng: 20.7800507 },
    { lat: 49.3061138, lng: 20.7797948 },
    { lat: 49.3054582, lng: 20.7788354 },
    { lat: 49.305047, lng: 20.7786277 },
    { lat: 49.3045578, lng: 20.7777624 },
    { lat: 49.3012985, lng: 20.7795934 },
    { lat: 49.3013467, lng: 20.7808237 },
    { lat: 49.3014359, lng: 20.7811569 },
    { lat: 49.3000293, lng: 20.7816223 },
    { lat: 49.2999529, lng: 20.781753 },
    { lat: 49.2998269, lng: 20.7817916 },
    { lat: 49.2996626, lng: 20.7819856 },
    { lat: 49.2994049, lng: 20.781952 },
    { lat: 49.2992376, lng: 20.7818353 },
    { lat: 49.2989624, lng: 20.7819193 },
    { lat: 49.2983917, lng: 20.782889 },
    { lat: 49.2978384, lng: 20.783003 },
    { lat: 49.297711, lng: 20.78322 },
    { lat: 49.2973167, lng: 20.7832756 },
    { lat: 49.2971272, lng: 20.7835867 },
    { lat: 49.296772, lng: 20.7830225 },
    { lat: 49.2963019, lng: 20.7828359 },
    { lat: 49.2962754, lng: 20.7826559 },
    { lat: 49.2955679, lng: 20.7824744 },
    { lat: 49.2950588, lng: 20.782068 },
    { lat: 49.2947379, lng: 20.7819342 },
    { lat: 49.2946401, lng: 20.7817788 },
    { lat: 49.2945589, lng: 20.7818041 },
    { lat: 49.2944544, lng: 20.7820351 },
    { lat: 49.2938172, lng: 20.7816073 },
    { lat: 49.2936022, lng: 20.7813189 },
    { lat: 49.2935883, lng: 20.7815296 },
    { lat: 49.2932888, lng: 20.7814979 },
    { lat: 49.2926395, lng: 20.7806843 },
    { lat: 49.2926703, lng: 20.7807644 },
    { lat: 49.2924039, lng: 20.7814552 },
    { lat: 49.2920119, lng: 20.7822254 },
    { lat: 49.2910729, lng: 20.7829327 },
    { lat: 49.2905127, lng: 20.7834648 },
    { lat: 49.2901365, lng: 20.7840425 },
    { lat: 49.2898124, lng: 20.7848606 },
    { lat: 49.2896747, lng: 20.7853307 },
    { lat: 49.2896525, lng: 20.7866147 },
    { lat: 49.2897123, lng: 20.7872402 },
    { lat: 49.2896017, lng: 20.7879151 },
    { lat: 49.2890492, lng: 20.7896731 },
    { lat: 49.2877532, lng: 20.7917777 },
    { lat: 49.2861208, lng: 20.7946381 },
    { lat: 49.2856559, lng: 20.7952272 },
    { lat: 49.2849559, lng: 20.7959441 },
    { lat: 49.2842934, lng: 20.7961226 },
    { lat: 49.2839019, lng: 20.7960954 },
    { lat: 49.2828615, lng: 20.7962091 },
    { lat: 49.2814122, lng: 20.7965444 },
    { lat: 49.2808728, lng: 20.7969335 },
    { lat: 49.2800409, lng: 20.7977392 },
    { lat: 49.2798885, lng: 20.7979249 },
    { lat: 49.2793461, lng: 20.7988752 },
    { lat: 49.2790923, lng: 20.7994169 },
    { lat: 49.2790118, lng: 20.7999833 },
    { lat: 49.2789587, lng: 20.8031527 },
    { lat: 49.2788779, lng: 20.8032248 },
    { lat: 49.278825, lng: 20.8035838 },
    { lat: 49.2785691, lng: 20.8044551 },
    { lat: 49.2780594, lng: 20.8051125 },
    { lat: 49.2777342, lng: 20.8057035 },
    { lat: 49.2773793, lng: 20.8057948 },
    { lat: 49.2775613, lng: 20.8066979 },
    { lat: 49.2779047, lng: 20.810366 },
    { lat: 49.2776196, lng: 20.8104859 },
    { lat: 49.2771781, lng: 20.8109056 },
    { lat: 49.2767572, lng: 20.8108723 },
    { lat: 49.276213, lng: 20.8128896 },
    { lat: 49.2764821, lng: 20.8138442 },
    { lat: 49.2767706, lng: 20.816216 },
    { lat: 49.2778801, lng: 20.8196836 },
    { lat: 49.2784344, lng: 20.8208818 },
    { lat: 49.2783549, lng: 20.8211909 },
    { lat: 49.2797535, lng: 20.8229532 },
    { lat: 49.2806375, lng: 20.8236918 },
    { lat: 49.2810468, lng: 20.823779 },
    { lat: 49.2821063, lng: 20.825137 },
    { lat: 49.2827206, lng: 20.8255616 },
    { lat: 49.2822122, lng: 20.8286259 },
    { lat: 49.281468, lng: 20.8305589 },
    { lat: 49.2810239, lng: 20.8322231 },
    { lat: 49.281089, lng: 20.8324789 },
    { lat: 49.2812627, lng: 20.8331518 },
    { lat: 49.2827464, lng: 20.8330604 },
    { lat: 49.2843734, lng: 20.832853 },
    { lat: 49.2850981, lng: 20.8340656 },
    { lat: 49.2854338, lng: 20.8347653 },
    { lat: 49.2858216, lng: 20.8351836 },
    { lat: 49.2873705, lng: 20.8343292 },
    { lat: 49.288838, lng: 20.8345239 },
    { lat: 49.291323, lng: 20.8308623 },
    { lat: 49.2921737, lng: 20.8317199 },
    { lat: 49.2932776, lng: 20.8317785 },
    { lat: 49.2938145, lng: 20.8320457 },
    { lat: 49.2946305, lng: 20.8326668 },
    { lat: 49.2953158, lng: 20.8336506 },
    { lat: 49.2959278, lng: 20.83658 },
    { lat: 49.2966919, lng: 20.8390188 },
    { lat: 49.3027408, lng: 20.8420099 },
    { lat: 49.3030817, lng: 20.8430661 },
    { lat: 49.3036791, lng: 20.8422287 },
    { lat: 49.3040482, lng: 20.8407119 },
    { lat: 49.304291, lng: 20.8401079 },
    { lat: 49.3047025, lng: 20.8385076 },
    { lat: 49.3049592, lng: 20.8376879 },
    { lat: 49.3050951, lng: 20.8355686 },
    { lat: 49.3048934, lng: 20.8321659 },
    { lat: 49.3053862, lng: 20.8288995 },
    { lat: 49.3059347, lng: 20.8271049 },
    { lat: 49.305843, lng: 20.8253038 },
    { lat: 49.3068299, lng: 20.8218156 },
    { lat: 49.3097276, lng: 20.8159892 },
    { lat: 49.3110591, lng: 20.8146462 },
    { lat: 49.3117492, lng: 20.8144544 },
    { lat: 49.3127267, lng: 20.8143089 },
    { lat: 49.3129311, lng: 20.8144277 },
    { lat: 49.31296, lng: 20.8143836 },
    { lat: 49.313739, lng: 20.8145213 },
    { lat: 49.3138583, lng: 20.8144248 },
    { lat: 49.3145726, lng: 20.8133288 },
    { lat: 49.3151861, lng: 20.8125422 },
    { lat: 49.3163386, lng: 20.8057943 },
    { lat: 49.3157195, lng: 20.8036287 },
    { lat: 49.3145112, lng: 20.798775 },
    { lat: 49.3144496, lng: 20.7968571 },
    { lat: 49.3146565, lng: 20.7959945 },
    { lat: 49.3144595, lng: 20.7950479 },
    { lat: 49.3144575, lng: 20.7942389 },
    { lat: 49.3146153, lng: 20.7939231 },
    { lat: 49.3146348, lng: 20.7924666 },
    { lat: 49.3144837, lng: 20.792289 },
    { lat: 49.3138569, lng: 20.7921632 },
    { lat: 49.3129048, lng: 20.7917119 },
    { lat: 49.3125478, lng: 20.7911178 },
    { lat: 49.3122981, lng: 20.7894612 },
    { lat: 49.3122888, lng: 20.7867117 },
    { lat: 49.3127364, lng: 20.7834761 },
  ],
  Kolačkov: [
    { lat: 49.2610706, lng: 20.605185 },
    { lat: 49.2609639, lng: 20.6051324 },
    { lat: 49.2594591, lng: 20.6059531 },
    { lat: 49.2584061, lng: 20.6066824 },
    { lat: 49.2583303, lng: 20.6068902 },
    { lat: 49.2580785, lng: 20.6072146 },
    { lat: 49.2578041, lng: 20.6078792 },
    { lat: 49.2575284, lng: 20.6083753 },
    { lat: 49.2569302, lng: 20.6092978 },
    { lat: 49.2568852, lng: 20.6094566 },
    { lat: 49.2566487, lng: 20.6097648 },
    { lat: 49.2561951, lng: 20.609642 },
    { lat: 49.2556811, lng: 20.6097451 },
    { lat: 49.254675, lng: 20.6101308 },
    { lat: 49.253541, lng: 20.6109565 },
    { lat: 49.2530303, lng: 20.6112179 },
    { lat: 49.2516385, lng: 20.6108 },
    { lat: 49.2512407, lng: 20.6111632 },
    { lat: 49.2507265, lng: 20.6122944 },
    { lat: 49.2502648, lng: 20.6127556 },
    { lat: 49.2484445, lng: 20.6143141 },
    { lat: 49.2474651, lng: 20.6144704 },
    { lat: 49.2470717, lng: 20.6141396 },
    { lat: 49.2467892, lng: 20.6135804 },
    { lat: 49.2460299, lng: 20.6130037 },
    { lat: 49.2457314, lng: 20.6128458 },
    { lat: 49.2441489, lng: 20.6124743 },
    { lat: 49.2428182, lng: 20.6117854 },
    { lat: 49.2421325, lng: 20.6115311 },
    { lat: 49.2417068, lng: 20.6130513 },
    { lat: 49.2412071, lng: 20.6139883 },
    { lat: 49.2409073, lng: 20.6144801 },
    { lat: 49.2399475, lng: 20.6157108 },
    { lat: 49.2393581, lng: 20.6167201 },
    { lat: 49.2392033, lng: 20.6172022 },
    { lat: 49.2389858, lng: 20.6183539 },
    { lat: 49.2384229, lng: 20.6194772 },
    { lat: 49.2381316, lng: 20.619826 },
    { lat: 49.2354361, lng: 20.6257812 },
    { lat: 49.2346126, lng: 20.6270437 },
    { lat: 49.2339022, lng: 20.6272322 },
    { lat: 49.2326272, lng: 20.6284043 },
    { lat: 49.2321804, lng: 20.6291907 },
    { lat: 49.2315513, lng: 20.6297556 },
    { lat: 49.2310325, lng: 20.630133 },
    { lat: 49.2310152, lng: 20.6307479 },
    { lat: 49.2310955, lng: 20.6316369 },
    { lat: 49.2312792, lng: 20.632678 },
    { lat: 49.2316279, lng: 20.6333573 },
    { lat: 49.2323855, lng: 20.6343586 },
    { lat: 49.2333918, lng: 20.6352739 },
    { lat: 49.2328496, lng: 20.6370051 },
    { lat: 49.2322695, lng: 20.6374956 },
    { lat: 49.2319935, lng: 20.6374877 },
    { lat: 49.2316927, lng: 20.6371401 },
    { lat: 49.2311777, lng: 20.6369001 },
    { lat: 49.2304883, lng: 20.6363897 },
    { lat: 49.2300921, lng: 20.6362947 },
    { lat: 49.2298887, lng: 20.6360567 },
    { lat: 49.229613, lng: 20.6353994 },
    { lat: 49.2292855, lng: 20.6353065 },
    { lat: 49.2288876, lng: 20.6348539 },
    { lat: 49.2286534, lng: 20.6344744 },
    { lat: 49.2285962, lng: 20.6343535 },
    { lat: 49.2285006, lng: 20.6336084 },
    { lat: 49.2281664, lng: 20.6332024 },
    { lat: 49.227581, lng: 20.6329485 },
    { lat: 49.2270441, lng: 20.6329542 },
    { lat: 49.2268295, lng: 20.633211 },
    { lat: 49.2266413, lng: 20.6332725 },
    { lat: 49.2263734, lng: 20.6331415 },
    { lat: 49.2261146, lng: 20.6331819 },
    { lat: 49.2259338, lng: 20.6336905 },
    { lat: 49.2258222, lng: 20.6338478 },
    { lat: 49.2256299, lng: 20.6338784 },
    { lat: 49.2253577, lng: 20.6336692 },
    { lat: 49.2252519, lng: 20.633673 },
    { lat: 49.225145, lng: 20.6337043 },
    { lat: 49.2248721, lng: 20.6339994 },
    { lat: 49.2246179, lng: 20.6338514 },
    { lat: 49.2242447, lng: 20.6339612 },
    { lat: 49.2233982, lng: 20.6338668 },
    { lat: 49.2231894, lng: 20.6336016 },
    { lat: 49.2229287, lng: 20.6335488 },
    { lat: 49.2227086, lng: 20.633286 },
    { lat: 49.2225789, lng: 20.6332483 },
    { lat: 49.2224043, lng: 20.6332388 },
    { lat: 49.2221829, lng: 20.6334053 },
    { lat: 49.2219789, lng: 20.6334004 },
    { lat: 49.2218926, lng: 20.6332564 },
    { lat: 49.2211755, lng: 20.6330672 },
    { lat: 49.2208715, lng: 20.6332533 },
    { lat: 49.220612, lng: 20.6332063 },
    { lat: 49.2202895, lng: 20.6329351 },
    { lat: 49.2198905, lng: 20.6330499 },
    { lat: 49.2197562, lng: 20.6332243 },
    { lat: 49.2195917, lng: 20.6332892 },
    { lat: 49.2193575, lng: 20.6332836 },
    { lat: 49.2190701, lng: 20.6335885 },
    { lat: 49.2186017, lng: 20.6338341 },
    { lat: 49.2183444, lng: 20.6338805 },
    { lat: 49.2180556, lng: 20.6337999 },
    { lat: 49.2178564, lng: 20.6339541 },
    { lat: 49.2173984, lng: 20.6337134 },
    { lat: 49.21705, lng: 20.6337591 },
    { lat: 49.2164989, lng: 20.6335226 },
    { lat: 49.2160969, lng: 20.6334551 },
    { lat: 49.2150336, lng: 20.6326839 },
    { lat: 49.2142811, lng: 20.6324523 },
    { lat: 49.2140695, lng: 20.6322936 },
    { lat: 49.2138198, lng: 20.6322721 },
    { lat: 49.2135713, lng: 20.6320592 },
    { lat: 49.2127699, lng: 20.6323128 },
    { lat: 49.2122136, lng: 20.6322445 },
    { lat: 49.2114647, lng: 20.6329212 },
    { lat: 49.2104949, lng: 20.6330956 },
    { lat: 49.2101641, lng: 20.632975 },
    { lat: 49.2096602, lng: 20.6325736 },
    { lat: 49.2090356, lng: 20.6312038 },
    { lat: 49.208476, lng: 20.6305716 },
    { lat: 49.2081021, lng: 20.6304143 },
    { lat: 49.2078622, lng: 20.6304398 },
    { lat: 49.2071494, lng: 20.6305276 },
    { lat: 49.2062789, lng: 20.6308017 },
    { lat: 49.2057884, lng: 20.6308709 },
    { lat: 49.2053639, lng: 20.6307747 },
    { lat: 49.2050221, lng: 20.6308152 },
    { lat: 49.2046294, lng: 20.6304474 },
    { lat: 49.2046676, lng: 20.6307578 },
    { lat: 49.2049113, lng: 20.6312756 },
    { lat: 49.2056695, lng: 20.6321701 },
    { lat: 49.2061406, lng: 20.6324644 },
    { lat: 49.206545, lng: 20.632941 },
    { lat: 49.2068905, lng: 20.6337527 },
    { lat: 49.206778, lng: 20.6341924 },
    { lat: 49.2052691, lng: 20.6372534 },
    { lat: 49.2048956, lng: 20.6378567 },
    { lat: 49.2046687, lng: 20.6380884 },
    { lat: 49.2045989, lng: 20.6380874 },
    { lat: 49.2047366, lng: 20.6382257 },
    { lat: 49.2049763, lng: 20.6382856 },
    { lat: 49.2054666, lng: 20.6386766 },
    { lat: 49.2072281, lng: 20.6414937 },
    { lat: 49.2083904, lng: 20.6427992 },
    { lat: 49.2092631, lng: 20.6435008 },
    { lat: 49.2103787, lng: 20.6446015 },
    { lat: 49.2107657, lng: 20.6447838 },
    { lat: 49.2114412, lng: 20.6449391 },
    { lat: 49.212559, lng: 20.645536 },
    { lat: 49.2125714, lng: 20.6459753 },
    { lat: 49.2129157, lng: 20.6465706 },
    { lat: 49.213067, lng: 20.6470224 },
    { lat: 49.2135247, lng: 20.6477605 },
    { lat: 49.2150614, lng: 20.6497808 },
    { lat: 49.2154561, lng: 20.6501804 },
    { lat: 49.2157787, lng: 20.6503213 },
    { lat: 49.2162281, lng: 20.6506777 },
    { lat: 49.2171704, lng: 20.6510856 },
    { lat: 49.2190136, lng: 20.6509399 },
    { lat: 49.2192081, lng: 20.6510976 },
    { lat: 49.2196127, lng: 20.6521143 },
    { lat: 49.2200461, lng: 20.6542255 },
    { lat: 49.2204392, lng: 20.6547372 },
    { lat: 49.2216715, lng: 20.6550426 },
    { lat: 49.2224525, lng: 20.6550697 },
    { lat: 49.2230195, lng: 20.6549281 },
    { lat: 49.2245254, lng: 20.6543885 },
    { lat: 49.2264384, lng: 20.6542172 },
    { lat: 49.2270098, lng: 20.6542996 },
    { lat: 49.2286788, lng: 20.6551654 },
    { lat: 49.2312902, lng: 20.658666 },
    { lat: 49.2340625, lng: 20.6615917 },
    { lat: 49.2343492, lng: 20.6613899 },
    { lat: 49.2349398, lng: 20.6604039 },
    { lat: 49.2352935, lng: 20.6599721 },
    { lat: 49.2356038, lng: 20.6598025 },
    { lat: 49.2359334, lng: 20.6597329 },
    { lat: 49.2365147, lng: 20.6586716 },
    { lat: 49.2368494, lng: 20.6583332 },
    { lat: 49.2374318, lng: 20.6567415 },
    { lat: 49.2376994, lng: 20.6562574 },
    { lat: 49.2380702, lng: 20.655801 },
    { lat: 49.238366, lng: 20.6550941 },
    { lat: 49.2384105, lng: 20.6549877 },
    { lat: 49.2384427, lng: 20.6549342 },
    { lat: 49.2399106, lng: 20.6524897 },
    { lat: 49.2402357, lng: 20.6522 },
    { lat: 49.2408436, lng: 20.6522675 },
    { lat: 49.2414106, lng: 20.6524446 },
    { lat: 49.2418811, lng: 20.6524133 },
    { lat: 49.2421908, lng: 20.6525395 },
    { lat: 49.2425331, lng: 20.6520069 },
    { lat: 49.2427462, lng: 20.6518649 },
    { lat: 49.2430625, lng: 20.6518904 },
    { lat: 49.2432237, lng: 20.6517735 },
    { lat: 49.2441828, lng: 20.6526587 },
    { lat: 49.2445943, lng: 20.6531155 },
    { lat: 49.2450138, lng: 20.6534484 },
    { lat: 49.2455566, lng: 20.6537079 },
    { lat: 49.2458389, lng: 20.6539838 },
    { lat: 49.2459672, lng: 20.6543006 },
    { lat: 49.2461902, lng: 20.6545277 },
    { lat: 49.2462682, lng: 20.6547572 },
    { lat: 49.2465139, lng: 20.6550185 },
    { lat: 49.2467677, lng: 20.6550319 },
    { lat: 49.2471111, lng: 20.6552548 },
    { lat: 49.2473101, lng: 20.6551204 },
    { lat: 49.2477856, lng: 20.6550407 },
    { lat: 49.2478107, lng: 20.6551413 },
    { lat: 49.2478689, lng: 20.6550075 },
    { lat: 49.2480562, lng: 20.6551465 },
    { lat: 49.2482733, lng: 20.6550758 },
    { lat: 49.2483996, lng: 20.6551178 },
    { lat: 49.2486021, lng: 20.6548325 },
    { lat: 49.2486136, lng: 20.6546456 },
    { lat: 49.2486563, lng: 20.6545805 },
    { lat: 49.2491396, lng: 20.6540901 },
    { lat: 49.249314, lng: 20.6540857 },
    { lat: 49.2496251, lng: 20.653881 },
    { lat: 49.2498344, lng: 20.6536247 },
    { lat: 49.2501737, lng: 20.6534505 },
    { lat: 49.2504603, lng: 20.6533368 },
    { lat: 49.250877, lng: 20.6533489 },
    { lat: 49.2512154, lng: 20.6530227 },
    { lat: 49.2514803, lng: 20.6531884 },
    { lat: 49.2519603, lng: 20.6532006 },
    { lat: 49.2527416, lng: 20.6534259 },
    { lat: 49.2528589, lng: 20.6536204 },
    { lat: 49.2531218, lng: 20.653543 },
    { lat: 49.2542806, lng: 20.6536539 },
    { lat: 49.2546013, lng: 20.6534935 },
    { lat: 49.2553295, lng: 20.6532962 },
    { lat: 49.2559753, lng: 20.6532895 },
    { lat: 49.2568373, lng: 20.6531268 },
    { lat: 49.2571877, lng: 20.6532288 },
    { lat: 49.2579203, lng: 20.6532545 },
    { lat: 49.2581262, lng: 20.6530935 },
    { lat: 49.2584767, lng: 20.6530423 },
    { lat: 49.2587065, lng: 20.6531078 },
    { lat: 49.2592216, lng: 20.653035 },
    { lat: 49.2595043, lng: 20.6529126 },
    { lat: 49.25996, lng: 20.6529384 },
    { lat: 49.2604865, lng: 20.6527143 },
    { lat: 49.2608892, lng: 20.6523589 },
    { lat: 49.2621065, lng: 20.6521802 },
    { lat: 49.2634769, lng: 20.6525737 },
    { lat: 49.2644128, lng: 20.6524934 },
    { lat: 49.2648541, lng: 20.6522079 },
    { lat: 49.2651781, lng: 20.6523315 },
    { lat: 49.265549, lng: 20.6526289 },
    { lat: 49.2660259, lng: 20.6523646 },
    { lat: 49.2669774, lng: 20.6511166 },
    { lat: 49.2670055, lng: 20.6508919 },
    { lat: 49.2673014, lng: 20.6507299 },
    { lat: 49.2683167, lng: 20.6480541 },
    { lat: 49.2686119, lng: 20.6475634 },
    { lat: 49.2690742, lng: 20.6472058 },
    { lat: 49.2693686, lng: 20.6471603 },
    { lat: 49.2699633, lng: 20.6468853 },
    { lat: 49.2701021, lng: 20.6469598 },
    { lat: 49.2711516, lng: 20.6485715 },
    { lat: 49.2711562, lng: 20.649176 },
    { lat: 49.2712786, lng: 20.6509634 },
    { lat: 49.2714249, lng: 20.651626 },
    { lat: 49.2714557, lng: 20.652923 },
    { lat: 49.2713919, lng: 20.6533764 },
    { lat: 49.2715066, lng: 20.6548899 },
    { lat: 49.271754, lng: 20.6547553 },
    { lat: 49.2718614, lng: 20.6553325 },
    { lat: 49.2719874, lng: 20.6556785 },
    { lat: 49.2721671, lng: 20.6567749 },
    { lat: 49.2723474, lng: 20.6571521 },
    { lat: 49.2737991, lng: 20.6563249 },
    { lat: 49.2748111, lng: 20.6559503 },
    { lat: 49.275067, lng: 20.6559556 },
    { lat: 49.2764975, lng: 20.6574075 },
    { lat: 49.278407, lng: 20.6561855 },
    { lat: 49.279536, lng: 20.6591508 },
    { lat: 49.2797149, lng: 20.6586773 },
    { lat: 49.2790801, lng: 20.6508861 },
    { lat: 49.2788119, lng: 20.650186 },
    { lat: 49.2780588, lng: 20.6487682 },
    { lat: 49.2777347, lng: 20.6461558 },
    { lat: 49.2781457, lng: 20.6449999 },
    { lat: 49.2785249, lng: 20.6414378 },
    { lat: 49.2748354, lng: 20.6373354 },
    { lat: 49.2750106, lng: 20.633217 },
    { lat: 49.2747133, lng: 20.6315767 },
    { lat: 49.274445, lng: 20.6309435 },
    { lat: 49.2742628, lng: 20.6306314 },
    { lat: 49.2742695, lng: 20.6288766 },
    { lat: 49.2708547, lng: 20.6246965 },
    { lat: 49.2703684, lng: 20.6225366 },
    { lat: 49.2701685, lng: 20.6219236 },
    { lat: 49.2689641, lng: 20.6207383 },
    { lat: 49.268217, lng: 20.6182743 },
    { lat: 49.2673521, lng: 20.614519 },
    { lat: 49.266149, lng: 20.615432 },
    { lat: 49.2640216, lng: 20.6145572 },
    { lat: 49.2637343, lng: 20.6108116 },
    { lat: 49.2632972, lng: 20.6095512 },
    { lat: 49.2631045, lng: 20.6091717 },
    { lat: 49.2624527, lng: 20.6082949 },
    { lat: 49.2610706, lng: 20.605185 },
  ],
  VeľkáLesná: [
    { lat: 49.317695, lng: 20.476139 },
    { lat: 49.3185313, lng: 20.4755682 },
    { lat: 49.3186934, lng: 20.4754809 },
    { lat: 49.3188939, lng: 20.4754786 },
    { lat: 49.3202138, lng: 20.4755827 },
    { lat: 49.3218637, lng: 20.4758421 },
    { lat: 49.323404, lng: 20.4763624 },
    { lat: 49.3258296, lng: 20.4758365 },
    { lat: 49.3270048, lng: 20.4759236 },
    { lat: 49.327497, lng: 20.4756913 },
    { lat: 49.3285166, lng: 20.476136 },
    { lat: 49.329015, lng: 20.4760344 },
    { lat: 49.3294894, lng: 20.476022 },
    { lat: 49.3299375, lng: 20.4755354 },
    { lat: 49.3300528, lng: 20.4753702 },
    { lat: 49.330111, lng: 20.4751781 },
    { lat: 49.3306866, lng: 20.4747017 },
    { lat: 49.3311688, lng: 20.4739862 },
    { lat: 49.3313553, lng: 20.4738241 },
    { lat: 49.3317388, lng: 20.4728778 },
    { lat: 49.3319894, lng: 20.4725622 },
    { lat: 49.3321846, lng: 20.4724202 },
    { lat: 49.3326907, lng: 20.4717704 },
    { lat: 49.3328193, lng: 20.471778 },
    { lat: 49.3329859, lng: 20.4714169 },
    { lat: 49.3331629, lng: 20.4712109 },
    { lat: 49.3338785, lng: 20.4706294 },
    { lat: 49.3352968, lng: 20.4708642 },
    { lat: 49.3356249, lng: 20.4708205 },
    { lat: 49.335916, lng: 20.4706547 },
    { lat: 49.3362557, lng: 20.4706496 },
    { lat: 49.3365465, lng: 20.4707484 },
    { lat: 49.3371057, lng: 20.4707069 },
    { lat: 49.3372835, lng: 20.470647 },
    { lat: 49.3378505, lng: 20.4702394 },
    { lat: 49.3381321, lng: 20.4702174 },
    { lat: 49.3385662, lng: 20.4683564 },
    { lat: 49.3389073, lng: 20.4681465 },
    { lat: 49.3390295, lng: 20.4675019 },
    { lat: 49.3392095, lng: 20.4672241 },
    { lat: 49.3393229, lng: 20.4671597 },
    { lat: 49.3396125, lng: 20.4666189 },
    { lat: 49.339996, lng: 20.4662796 },
    { lat: 49.3400526, lng: 20.4661668 },
    { lat: 49.3403193, lng: 20.4657077 },
    { lat: 49.3406646, lng: 20.4654581 },
    { lat: 49.3409401, lng: 20.4651466 },
    { lat: 49.3409649, lng: 20.4649979 },
    { lat: 49.3412385, lng: 20.4648188 },
    { lat: 49.3414557, lng: 20.4646074 },
    { lat: 49.3415894, lng: 20.4643596 },
    { lat: 49.3416844, lng: 20.4643076 },
    { lat: 49.341741, lng: 20.4640163 },
    { lat: 49.34196, lng: 20.4635185 },
    { lat: 49.3423336, lng: 20.4630602 },
    { lat: 49.3428796, lng: 20.4630894 },
    { lat: 49.3439699, lng: 20.4632844 },
    { lat: 49.3445586, lng: 20.4632247 },
    { lat: 49.3449149, lng: 20.4631181 },
    { lat: 49.3452579, lng: 20.4631372 },
    { lat: 49.3457336, lng: 20.4630636 },
    { lat: 49.3459182, lng: 20.4629716 },
    { lat: 49.3460603, lng: 20.4627653 },
    { lat: 49.3463862, lng: 20.462509 },
    { lat: 49.3464347, lng: 20.4623307 },
    { lat: 49.3465403, lng: 20.4622074 },
    { lat: 49.3465402, lng: 20.4618131 },
    { lat: 49.346657, lng: 20.4618632 },
    { lat: 49.3470873, lng: 20.4616628 },
    { lat: 49.3474813, lng: 20.461056 },
    { lat: 49.3476095, lng: 20.4602699 },
    { lat: 49.3479896, lng: 20.4593799 },
    { lat: 49.3479575, lng: 20.4589432 },
    { lat: 49.3480815, lng: 20.4588912 },
    { lat: 49.3481696, lng: 20.4586535 },
    { lat: 49.3485878, lng: 20.4583974 },
    { lat: 49.3486897, lng: 20.4582014 },
    { lat: 49.3487528, lng: 20.4581728 },
    { lat: 49.3485296, lng: 20.4570228 },
    { lat: 49.3485687, lng: 20.4545401 },
    { lat: 49.3488882, lng: 20.453266 },
    { lat: 49.3489341, lng: 20.4529038 },
    { lat: 49.348904, lng: 20.452515 },
    { lat: 49.3489309, lng: 20.4522119 },
    { lat: 49.3490809, lng: 20.4520427 },
    { lat: 49.3491272, lng: 20.4517159 },
    { lat: 49.3490274, lng: 20.4506688 },
    { lat: 49.3490507, lng: 20.4499659 },
    { lat: 49.349335, lng: 20.4496514 },
    { lat: 49.3494282, lng: 20.4493734 },
    { lat: 49.3496529, lng: 20.4490938 },
    { lat: 49.3498447, lng: 20.4487408 },
    { lat: 49.3500744, lng: 20.448682 },
    { lat: 49.3502091, lng: 20.4485243 },
    { lat: 49.3503479, lng: 20.4483193 },
    { lat: 49.350406, lng: 20.4480048 },
    { lat: 49.3506352, lng: 20.4477247 },
    { lat: 49.3508735, lng: 20.4477135 },
    { lat: 49.3509773, lng: 20.4474242 },
    { lat: 49.3511201, lng: 20.4472672 },
    { lat: 49.3511218, lng: 20.4470999 },
    { lat: 49.3515731, lng: 20.4461341 },
    { lat: 49.3515016, lng: 20.4459948 },
    { lat: 49.3515415, lng: 20.4458646 },
    { lat: 49.3516545, lng: 20.4458142 },
    { lat: 49.3516879, lng: 20.4456538 },
    { lat: 49.3516544, lng: 20.4455398 },
    { lat: 49.3518223, lng: 20.4451863 },
    { lat: 49.3519877, lng: 20.4450599 },
    { lat: 49.3520309, lng: 20.4446195 },
    { lat: 49.3521679, lng: 20.4446114 },
    { lat: 49.3522621, lng: 20.4444829 },
    { lat: 49.3522579, lng: 20.44422 },
    { lat: 49.3523415, lng: 20.444109 },
    { lat: 49.3523957, lng: 20.4437229 },
    { lat: 49.3523619, lng: 20.4436324 },
    { lat: 49.3524069, lng: 20.4434782 },
    { lat: 49.3526816, lng: 20.4429659 },
    { lat: 49.352991, lng: 20.4421515 },
    { lat: 49.3530574, lng: 20.4421785 },
    { lat: 49.3531644, lng: 20.4416736 },
    { lat: 49.3537323, lng: 20.4407228 },
    { lat: 49.3537508, lng: 20.4404369 },
    { lat: 49.3538053, lng: 20.4404502 },
    { lat: 49.3538331, lng: 20.4403799 },
    { lat: 49.3540322, lng: 20.4393527 },
    { lat: 49.3541576, lng: 20.4390043 },
    { lat: 49.3541841, lng: 20.4387247 },
    { lat: 49.3539804, lng: 20.4384504 },
    { lat: 49.3541725, lng: 20.438384 },
    { lat: 49.3542952, lng: 20.4382024 },
    { lat: 49.3544348, lng: 20.4378724 },
    { lat: 49.3546753, lng: 20.4370196 },
    { lat: 49.3547069, lng: 20.4365729 },
    { lat: 49.3546184, lng: 20.4365233 },
    { lat: 49.3545959, lng: 20.4364328 },
    { lat: 49.3545706, lng: 20.4356384 },
    { lat: 49.3546765, lng: 20.4354926 },
    { lat: 49.3549106, lng: 20.4345027 },
    { lat: 49.3549232, lng: 20.4337569 },
    { lat: 49.3548887, lng: 20.4336487 },
    { lat: 49.3545824, lng: 20.4335511 },
    { lat: 49.3546184, lng: 20.4324421 },
    { lat: 49.3545617, lng: 20.431623 },
    { lat: 49.3544577, lng: 20.4311608 },
    { lat: 49.354268, lng: 20.4307257 },
    { lat: 49.3542043, lng: 20.4304435 },
    { lat: 49.3542827, lng: 20.4296472 },
    { lat: 49.3539412, lng: 20.4286534 },
    { lat: 49.353821, lng: 20.4285369 },
    { lat: 49.3537544, lng: 20.4285652 },
    { lat: 49.3536974, lng: 20.4283669 },
    { lat: 49.3536707, lng: 20.4279839 },
    { lat: 49.3535409, lng: 20.4276761 },
    { lat: 49.3533524, lng: 20.4273911 },
    { lat: 49.353384, lng: 20.4272786 },
    { lat: 49.3533442, lng: 20.4248408 },
    { lat: 49.35318, lng: 20.423845 },
    { lat: 49.353156, lng: 20.423677 },
    { lat: 49.353089, lng: 20.423165 },
    { lat: 49.353063, lng: 20.423057 },
    { lat: 49.353056, lng: 20.423028 },
    { lat: 49.352995, lng: 20.422766 },
    { lat: 49.352898, lng: 20.422738 },
    { lat: 49.352787, lng: 20.422568 },
    { lat: 49.352747, lng: 20.422508 },
    { lat: 49.352679, lng: 20.422435 },
    { lat: 49.352661, lng: 20.422414 },
    { lat: 49.352618, lng: 20.42236 },
    { lat: 49.352489, lng: 20.422191 },
    { lat: 49.352475, lng: 20.422173 },
    { lat: 49.352055, lng: 20.422038 },
    { lat: 49.352008, lng: 20.421955 },
    { lat: 49.351921, lng: 20.421819 },
    { lat: 49.351804, lng: 20.421696 },
    { lat: 49.351708, lng: 20.421597 },
    { lat: 49.35156, lng: 20.421629 },
    { lat: 49.35142, lng: 20.421734 },
    { lat: 49.351349, lng: 20.421562 },
    { lat: 49.351262, lng: 20.421408 },
    { lat: 49.35116, lng: 20.421398 },
    { lat: 49.350666, lng: 20.421036 },
    { lat: 49.350421, lng: 20.420857 },
    { lat: 49.350173, lng: 20.420665 },
    { lat: 49.350081, lng: 20.420522 },
    { lat: 49.350067, lng: 20.4205 },
    { lat: 49.349916, lng: 20.420276 },
    { lat: 49.349818, lng: 20.4202 },
    { lat: 49.349735, lng: 20.420136 },
    { lat: 49.349601, lng: 20.419977 },
    { lat: 49.349487, lng: 20.419812 },
    { lat: 49.349255, lng: 20.419303 },
    { lat: 49.349125, lng: 20.419114 },
    { lat: 49.348991, lng: 20.419279 },
    { lat: 49.348808, lng: 20.419503 },
    { lat: 49.348536, lng: 20.419646 },
    { lat: 49.348347, lng: 20.419751 },
    { lat: 49.348288, lng: 20.419776 },
    { lat: 49.34823, lng: 20.419826 },
    { lat: 49.34811, lng: 20.419895 },
    { lat: 49.348082, lng: 20.419912 },
    { lat: 49.347988, lng: 20.419848 },
    { lat: 49.347917, lng: 20.419838 },
    { lat: 49.347858, lng: 20.41985 },
    { lat: 49.347679, lng: 20.419798 },
    { lat: 49.347367, lng: 20.419706 },
    { lat: 49.347083, lng: 20.419623 },
    { lat: 49.346457, lng: 20.419445 },
    { lat: 49.346456, lng: 20.419541 },
    { lat: 49.346412, lng: 20.419535 },
    { lat: 49.346086, lng: 20.419493 },
    { lat: 49.345608, lng: 20.419354 },
    { lat: 49.34535, lng: 20.419415 },
    { lat: 49.345116, lng: 20.419429 },
    { lat: 49.344828, lng: 20.419412 },
    { lat: 49.344601, lng: 20.41945 },
    { lat: 49.344137, lng: 20.419616 },
    { lat: 49.343875, lng: 20.419719 },
    { lat: 49.343546, lng: 20.419815 },
    { lat: 49.34328, lng: 20.419936 },
    { lat: 49.343061, lng: 20.420052 },
    { lat: 49.342697, lng: 20.420297 },
    { lat: 49.342462, lng: 20.42052 },
    { lat: 49.342189, lng: 20.420622 },
    { lat: 49.342036, lng: 20.420632 },
    { lat: 49.341864, lng: 20.420736 },
    { lat: 49.341648, lng: 20.420841 },
    { lat: 49.341407, lng: 20.420777 },
    { lat: 49.341275, lng: 20.420787 },
    { lat: 49.34126, lng: 20.420783 },
    { lat: 49.340617, lng: 20.420638 },
    { lat: 49.34059, lng: 20.420602 },
    { lat: 49.340274, lng: 20.420179 },
    { lat: 49.340013, lng: 20.420014 },
    { lat: 49.339982, lng: 20.419947 },
    { lat: 49.339838, lng: 20.419885 },
    { lat: 49.339573, lng: 20.419845 },
    { lat: 49.339522, lng: 20.419775 },
    { lat: 49.339477, lng: 20.419712 },
    { lat: 49.33896, lng: 20.419443 },
    { lat: 49.338933, lng: 20.419428 },
    { lat: 49.338945, lng: 20.419393 },
    { lat: 49.338632, lng: 20.419089 },
    { lat: 49.338541, lng: 20.41903 },
    { lat: 49.338492, lng: 20.418997 },
    { lat: 49.338454, lng: 20.418907 },
    { lat: 49.338437, lng: 20.41886 },
    { lat: 49.338422, lng: 20.418817 },
    { lat: 49.338342, lng: 20.418583 },
    { lat: 49.338188, lng: 20.418425 },
    { lat: 49.337886, lng: 20.418056 },
    { lat: 49.3378, lng: 20.417971 },
    { lat: 49.337774, lng: 20.417957 },
    { lat: 49.337352, lng: 20.417748 },
    { lat: 49.337342, lng: 20.417743 },
    { lat: 49.33723, lng: 20.417716 },
    { lat: 49.337136, lng: 20.417694 },
    { lat: 49.33707, lng: 20.417678 },
    { lat: 49.33705, lng: 20.417678 },
    { lat: 49.337039, lng: 20.417658 },
    { lat: 49.336934, lng: 20.417479 },
    { lat: 49.336764, lng: 20.417303 },
    { lat: 49.336602, lng: 20.417068 },
    { lat: 49.336485, lng: 20.416952 },
    { lat: 49.336385, lng: 20.416748 },
    { lat: 49.336314, lng: 20.416799 },
    { lat: 49.335979, lng: 20.41704 },
    { lat: 49.335916, lng: 20.417115 },
    { lat: 49.335688, lng: 20.417393 },
    { lat: 49.335562, lng: 20.417522 },
    { lat: 49.335433, lng: 20.417645 },
    { lat: 49.334928, lng: 20.417822 },
    { lat: 49.334784, lng: 20.41779 },
    { lat: 49.334777, lng: 20.417766 },
    { lat: 49.334753, lng: 20.417688 },
    { lat: 49.33449, lng: 20.417534 },
    { lat: 49.33447, lng: 20.417509 },
    { lat: 49.334326, lng: 20.417335 },
    { lat: 49.334265, lng: 20.41722 },
    { lat: 49.334144, lng: 20.416863 },
    { lat: 49.334032, lng: 20.416532 },
    { lat: 49.333958, lng: 20.416484 },
    { lat: 49.333889, lng: 20.416438 },
    { lat: 49.333713, lng: 20.416212 },
    { lat: 49.333607, lng: 20.416185 },
    { lat: 49.333568, lng: 20.415892 },
    { lat: 49.333461, lng: 20.415752 },
    { lat: 49.333446, lng: 20.415733 },
    { lat: 49.333375, lng: 20.415444 },
    { lat: 49.33329, lng: 20.415095 },
    { lat: 49.333168, lng: 20.414501 },
    { lat: 49.333193, lng: 20.414352 },
    { lat: 49.333122, lng: 20.414147 },
    { lat: 49.333017, lng: 20.413798 },
    { lat: 49.332926, lng: 20.413592 },
    { lat: 49.332792, lng: 20.413684 },
    { lat: 49.332644, lng: 20.413722 },
    { lat: 49.332479, lng: 20.413448 },
    { lat: 49.332297, lng: 20.413395 },
    { lat: 49.332039, lng: 20.413441 },
    { lat: 49.331705, lng: 20.413718 },
    { lat: 49.331367, lng: 20.413776 },
    { lat: 49.330872, lng: 20.413861 },
    { lat: 49.330761, lng: 20.413936 },
    { lat: 49.330715, lng: 20.413683 },
    { lat: 49.330661, lng: 20.41358 },
    { lat: 49.330504, lng: 20.413421 },
    { lat: 49.330378, lng: 20.413172 },
    { lat: 49.330197, lng: 20.412994 },
    { lat: 49.330106, lng: 20.413045 },
    { lat: 49.329888, lng: 20.413056 },
    { lat: 49.329708, lng: 20.412927 },
    { lat: 49.329575, lng: 20.412833 },
    { lat: 49.329429, lng: 20.412614 },
    { lat: 49.329368, lng: 20.412588 },
    { lat: 49.329296, lng: 20.412621 },
    { lat: 49.329258, lng: 20.412638 },
    { lat: 49.329141, lng: 20.412593 },
    { lat: 49.328833, lng: 20.412268 },
    { lat: 49.32871, lng: 20.412102 },
    { lat: 49.328618, lng: 20.411981 },
    { lat: 49.328536, lng: 20.411704 },
    { lat: 49.328407, lng: 20.411503 },
    { lat: 49.328298, lng: 20.411452 },
    { lat: 49.328433, lng: 20.411071 },
    { lat: 49.328516, lng: 20.410838 },
    { lat: 49.328565, lng: 20.410702 },
    { lat: 49.328431, lng: 20.410453 },
    { lat: 49.32837, lng: 20.410374 },
    { lat: 49.32828, lng: 20.41036 },
    { lat: 49.328081, lng: 20.410366 },
    { lat: 49.327774, lng: 20.410339 },
    { lat: 49.327539, lng: 20.410356 },
    { lat: 49.32716, lng: 20.410464 },
    { lat: 49.327078, lng: 20.410509 },
    { lat: 49.32699, lng: 20.410591 },
    { lat: 49.32677, lng: 20.410794 },
    { lat: 49.326698, lng: 20.410887 },
    { lat: 49.326398, lng: 20.410622 },
    { lat: 49.326189, lng: 20.41052 },
    { lat: 49.326157, lng: 20.410238 },
    { lat: 49.32612, lng: 20.410148 },
    { lat: 49.325872, lng: 20.409991 },
    { lat: 49.325671, lng: 20.409812 },
    { lat: 49.325594, lng: 20.409739 },
    { lat: 49.325472, lng: 20.409544 },
    { lat: 49.325374, lng: 20.409296 },
    { lat: 49.325325, lng: 20.409092 },
    { lat: 49.325241, lng: 20.408916 },
    { lat: 49.325105, lng: 20.408668 },
    { lat: 49.325049, lng: 20.408421 },
    { lat: 49.324958, lng: 20.408264 },
    { lat: 49.324749, lng: 20.408108 },
    { lat: 49.324552, lng: 20.408007 },
    { lat: 49.324423, lng: 20.407997 },
    { lat: 49.324293, lng: 20.408048 },
    { lat: 49.324251, lng: 20.40801 },
    { lat: 49.324047, lng: 20.408171 },
    { lat: 49.323953, lng: 20.408139 },
    { lat: 49.32393, lng: 20.408113 },
    { lat: 49.323929, lng: 20.408112 },
    { lat: 49.323738, lng: 20.4079 },
    { lat: 49.323669, lng: 20.407791 },
    { lat: 49.323593, lng: 20.407663 },
    { lat: 49.323389, lng: 20.407448 },
    { lat: 49.32332, lng: 20.407309 },
    { lat: 49.323178, lng: 20.407215 },
    { lat: 49.32298, lng: 20.40711 },
    { lat: 49.322952, lng: 20.407095 },
    { lat: 49.322876, lng: 20.407034 },
    { lat: 49.322737, lng: 20.406874 },
    { lat: 49.322412, lng: 20.406602 },
    { lat: 49.322283, lng: 20.406414 },
    { lat: 49.322232, lng: 20.406391 },
    { lat: 49.322139, lng: 20.40635 },
    { lat: 49.321937, lng: 20.405983 },
    { lat: 49.321857, lng: 20.405834 },
    { lat: 49.321745, lng: 20.405765 },
    { lat: 49.321574, lng: 20.405485 },
    { lat: 49.321433, lng: 20.405252 },
    { lat: 49.321302, lng: 20.405037 },
    { lat: 49.321239, lng: 20.404742 },
    { lat: 49.321225, lng: 20.404702 },
    { lat: 49.321164, lng: 20.404519 },
    { lat: 49.321155, lng: 20.404498 },
    { lat: 49.321096, lng: 20.404356 },
    { lat: 49.321063, lng: 20.40414 },
    { lat: 49.320952, lng: 20.404125 },
    { lat: 49.320842, lng: 20.40411 },
    { lat: 49.32084, lng: 20.40411 },
    { lat: 49.32078, lng: 20.404105 },
    { lat: 49.320643, lng: 20.404094 },
    { lat: 49.320559, lng: 20.403852 },
    { lat: 49.320387, lng: 20.403362 },
    { lat: 49.320285, lng: 20.402991 },
    { lat: 49.320049, lng: 20.402389 },
    { lat: 49.319849, lng: 20.401974 },
    { lat: 49.319401, lng: 20.401344 },
    { lat: 49.318733, lng: 20.401347 },
    { lat: 49.317913, lng: 20.401538 },
    { lat: 49.317347, lng: 20.401472 },
    { lat: 49.316779, lng: 20.401662 },
    { lat: 49.31599, lng: 20.401865 },
    { lat: 49.315646, lng: 20.401922 },
    { lat: 49.315138, lng: 20.402359 },
    { lat: 49.314768, lng: 20.402702 },
    { lat: 49.314175, lng: 20.40321 },
    { lat: 49.314123, lng: 20.403251 },
    { lat: 49.313213, lng: 20.403408 },
    { lat: 49.312643, lng: 20.403517 },
    { lat: 49.311635, lng: 20.403833 },
    { lat: 49.310991, lng: 20.404323 },
    { lat: 49.310309, lng: 20.404907 },
    { lat: 49.309834, lng: 20.40463 },
    { lat: 49.30895, lng: 20.404364 },
    { lat: 49.30831, lng: 20.403911 },
    { lat: 49.3076, lng: 20.403386 },
    { lat: 49.307214, lng: 20.403192 },
    { lat: 49.307158, lng: 20.403157 },
    { lat: 49.30711, lng: 20.403127 },
    { lat: 49.306643, lng: 20.402837 },
    { lat: 49.305996, lng: 20.402253 },
    { lat: 49.305534, lng: 20.401852 },
    { lat: 49.30508, lng: 20.401516 },
    { lat: 49.304567, lng: 20.401311 },
    { lat: 49.304543, lng: 20.40134 },
    { lat: 49.304527, lng: 20.401366 },
    { lat: 49.304324, lng: 20.401687 },
    { lat: 49.30406, lng: 20.401927 },
    { lat: 49.303998, lng: 20.402012 },
    { lat: 49.304011, lng: 20.402056 },
    { lat: 49.303556, lng: 20.402658 },
    { lat: 49.303598, lng: 20.402725 },
    { lat: 49.303437, lng: 20.403079 },
    { lat: 49.303138, lng: 20.403424 },
    { lat: 49.303188, lng: 20.40351 },
    { lat: 49.302873, lng: 20.404378 },
    { lat: 49.302941, lng: 20.40451 },
    { lat: 49.302864, lng: 20.405035 },
    { lat: 49.30286, lng: 20.405263 },
    { lat: 49.302795, lng: 20.405466 },
    { lat: 49.302705, lng: 20.405656 },
    { lat: 49.302659, lng: 20.405803 },
    { lat: 49.302653, lng: 20.406129 },
    { lat: 49.302623, lng: 20.406357 },
    { lat: 49.302629, lng: 20.406574 },
    { lat: 49.302735, lng: 20.406911 },
    { lat: 49.30277, lng: 20.407151 },
    { lat: 49.302782, lng: 20.407478 },
    { lat: 49.302837, lng: 20.407669 },
    { lat: 49.302838, lng: 20.408026 },
    { lat: 49.302743, lng: 20.408336 },
    { lat: 49.30254, lng: 20.408479 },
    { lat: 49.302525, lng: 20.408455 },
    { lat: 49.302251, lng: 20.408894 },
    { lat: 49.30213, lng: 20.409049 },
    { lat: 49.301986, lng: 20.409204 },
    { lat: 49.301878, lng: 20.409422 },
    { lat: 49.301967, lng: 20.40955 },
    { lat: 49.301898, lng: 20.409743 },
    { lat: 49.30184, lng: 20.410017 },
    { lat: 49.301781, lng: 20.410152 },
    { lat: 49.30164, lng: 20.410389 },
    { lat: 49.301586, lng: 20.410528 },
    { lat: 49.301364, lng: 20.410776 },
    { lat: 49.300928, lng: 20.411219 },
    { lat: 49.300742, lng: 20.411375 },
    { lat: 49.300553, lng: 20.411479 },
    { lat: 49.300398, lng: 20.411679 },
    { lat: 49.30041, lng: 20.411711 },
    { lat: 49.300244, lng: 20.411938 },
    { lat: 49.300148, lng: 20.412036 },
    { lat: 49.300072, lng: 20.412115 },
    { lat: 49.300082, lng: 20.41215 },
    { lat: 49.299773, lng: 20.412594 },
    { lat: 49.299721, lng: 20.412618 },
    { lat: 49.29923, lng: 20.41313 },
    { lat: 49.299159, lng: 20.413508 },
    { lat: 49.299024, lng: 20.413739 },
    { lat: 49.29885, lng: 20.41386 },
    { lat: 49.298756, lng: 20.414006 },
    { lat: 49.298212, lng: 20.414463 },
    { lat: 49.298051, lng: 20.414615 },
    { lat: 49.297618, lng: 20.4148 },
    { lat: 49.297298, lng: 20.415085 },
    { lat: 49.297125, lng: 20.415351 },
    { lat: 49.296952, lng: 20.415722 },
    { lat: 49.296853, lng: 20.415854 },
    { lat: 49.296818, lng: 20.415979 },
    { lat: 49.296558, lng: 20.416665 },
    { lat: 49.296451, lng: 20.417054 },
    { lat: 49.296402, lng: 20.417366 },
    { lat: 49.296251, lng: 20.41803 },
    { lat: 49.29603, lng: 20.418175 },
    { lat: 49.295953, lng: 20.419152 },
    { lat: 49.296037, lng: 20.420133 },
    { lat: 49.296175, lng: 20.420237 },
    { lat: 49.296169, lng: 20.420378 },
    { lat: 49.296082, lng: 20.420868 },
    { lat: 49.295977, lng: 20.421212 },
    { lat: 49.295687, lng: 20.421228 },
    { lat: 49.295619, lng: 20.421458 },
    { lat: 49.295575, lng: 20.42169 },
    { lat: 49.29546, lng: 20.422041 },
    { lat: 49.295313, lng: 20.422222 },
    { lat: 49.294978, lng: 20.422961 },
    { lat: 49.294859, lng: 20.423146 },
    { lat: 49.294858, lng: 20.423204 },
    { lat: 49.294854, lng: 20.423842 },
    { lat: 49.294849, lng: 20.42386 },
    { lat: 49.294244, lng: 20.425957 },
    { lat: 49.29385, lng: 20.427512 },
    { lat: 49.293831, lng: 20.427586 },
    { lat: 49.293637, lng: 20.428333 },
    { lat: 49.29397, lng: 20.428713 },
    { lat: 49.294152, lng: 20.428948 },
    { lat: 49.294612, lng: 20.429974 },
    { lat: 49.294664, lng: 20.43009 },
    { lat: 49.294693, lng: 20.430155 },
    { lat: 49.295049, lng: 20.43095 },
    { lat: 49.29526, lng: 20.431424 },
    { lat: 49.29534, lng: 20.431603 },
    { lat: 49.296655, lng: 20.433401 },
    { lat: 49.297442, lng: 20.435309 },
    { lat: 49.297469, lng: 20.435373 },
    { lat: 49.297474, lng: 20.435382 },
    { lat: 49.298469, lng: 20.437731 },
    { lat: 49.298553, lng: 20.437927 },
    { lat: 49.298637, lng: 20.438125 },
    { lat: 49.299174, lng: 20.43941 },
    { lat: 49.299352, lng: 20.439837 },
    { lat: 49.299382, lng: 20.439908 },
    { lat: 49.300386, lng: 20.442393 },
    { lat: 49.300418, lng: 20.442475 },
    { lat: 49.300425, lng: 20.442492 },
    { lat: 49.300441, lng: 20.442531 },
    { lat: 49.300465, lng: 20.442587 },
    { lat: 49.300702, lng: 20.443149 },
    { lat: 49.300904, lng: 20.443624 },
    { lat: 49.301002, lng: 20.443857 },
    { lat: 49.301352, lng: 20.44468 },
    { lat: 49.301644, lng: 20.445377 },
    { lat: 49.301676, lng: 20.445456 },
    { lat: 49.301717, lng: 20.445551 },
    { lat: 49.302646, lng: 20.44773 },
    { lat: 49.303379, lng: 20.449448 },
    { lat: 49.303383, lng: 20.449456 },
    { lat: 49.304299, lng: 20.451689 },
    { lat: 49.304654, lng: 20.452552 },
    { lat: 49.304992, lng: 20.453369 },
    { lat: 49.305102, lng: 20.453641 },
    { lat: 49.305598, lng: 20.454858 },
    { lat: 49.305706, lng: 20.45512 },
    { lat: 49.30585, lng: 20.455488 },
    { lat: 49.306462, lng: 20.456678 },
    { lat: 49.306548, lng: 20.456847 },
    { lat: 49.30684, lng: 20.457852 },
    { lat: 49.306879, lng: 20.457988 },
    { lat: 49.307341, lng: 20.458518 },
    { lat: 49.307244, lng: 20.458951 },
    { lat: 49.307248, lng: 20.459237 },
    { lat: 49.307231, lng: 20.459773 },
    { lat: 49.307336, lng: 20.460903 },
    { lat: 49.307326, lng: 20.461458 },
    { lat: 49.307479, lng: 20.462195 },
    { lat: 49.308014, lng: 20.462417 },
    { lat: 49.308322, lng: 20.462663 },
    { lat: 49.308727, lng: 20.46315 },
    { lat: 49.309024, lng: 20.463367 },
    { lat: 49.309492, lng: 20.463605 },
    { lat: 49.309769, lng: 20.463914 },
    { lat: 49.309781, lng: 20.463928 },
    { lat: 49.309792, lng: 20.463998 },
    { lat: 49.309953, lng: 20.465141 },
    { lat: 49.310031, lng: 20.465693 },
    { lat: 49.310086, lng: 20.466077 },
    { lat: 49.310101, lng: 20.466184 },
    { lat: 49.310248, lng: 20.466282 },
    { lat: 49.310609, lng: 20.466521 },
    { lat: 49.310886, lng: 20.466705 },
    { lat: 49.311517, lng: 20.467448 },
    { lat: 49.311707, lng: 20.467864 },
    { lat: 49.311848, lng: 20.468037 },
    { lat: 49.31199, lng: 20.46821 },
    { lat: 49.312211, lng: 20.468479 },
    { lat: 49.312641, lng: 20.469009 },
    { lat: 49.31333, lng: 20.469588 },
    { lat: 49.31351, lng: 20.46974 },
    { lat: 49.313743, lng: 20.470217 },
    { lat: 49.313951, lng: 20.470372 },
    { lat: 49.314451, lng: 20.470742 },
    { lat: 49.314746, lng: 20.471089 },
    { lat: 49.314871, lng: 20.471177 },
    { lat: 49.315106, lng: 20.471343 },
    { lat: 49.315515, lng: 20.471759 },
    { lat: 49.315653, lng: 20.471951 },
    { lat: 49.315852, lng: 20.472227 },
    { lat: 49.31624, lng: 20.472445 },
    { lat: 49.316296, lng: 20.472458 },
    { lat: 49.316462, lng: 20.472498 },
    { lat: 49.31669, lng: 20.473289 },
    { lat: 49.316737, lng: 20.473448 },
    { lat: 49.316954, lng: 20.474199 },
    { lat: 49.317695, lng: 20.476139 },
  ],
  Podolínec: [
    { lat: 49.2906874, lng: 20.5484771 },
    { lat: 49.2907559, lng: 20.5465973 },
    { lat: 49.2908965, lng: 20.5453053 },
    { lat: 49.2911245, lng: 20.5441177 },
    { lat: 49.2914749, lng: 20.543573 },
    { lat: 49.2918794, lng: 20.5434619 },
    { lat: 49.2919885, lng: 20.5428459 },
    { lat: 49.292268, lng: 20.5428245 },
    { lat: 49.2948006, lng: 20.5415266 },
    { lat: 49.2951586, lng: 20.5414916 },
    { lat: 49.2960638, lng: 20.5418666 },
    { lat: 49.2967883, lng: 20.5408725 },
    { lat: 49.2969055, lng: 20.540601 },
    { lat: 49.2967859, lng: 20.5403753 },
    { lat: 49.2967932, lng: 20.5402647 },
    { lat: 49.2970186, lng: 20.5399648 },
    { lat: 49.2970513, lng: 20.5398465 },
    { lat: 49.2969282, lng: 20.5394957 },
    { lat: 49.2969707, lng: 20.5394314 },
    { lat: 49.2976933, lng: 20.5399791 },
    { lat: 49.2981456, lng: 20.5399107 },
    { lat: 49.2982923, lng: 20.5399814 },
    { lat: 49.2990385, lng: 20.5406854 },
    { lat: 49.2992864, lng: 20.5405668 },
    { lat: 49.2996737, lng: 20.5405978 },
    { lat: 49.2998718, lng: 20.5400437 },
    { lat: 49.3006549, lng: 20.5394196 },
    { lat: 49.3013491, lng: 20.5398099 },
    { lat: 49.3020935, lng: 20.5390816 },
    { lat: 49.3040418, lng: 20.5406064 },
    { lat: 49.3054754, lng: 20.5397663 },
    { lat: 49.3068363, lng: 20.5393278 },
    { lat: 49.3076943, lng: 20.5384161 },
    { lat: 49.3079738, lng: 20.5378376 },
    { lat: 49.3083035, lng: 20.5374684 },
    { lat: 49.3089752, lng: 20.5368732 },
    { lat: 49.3095905, lng: 20.5366487 },
    { lat: 49.3097834, lng: 20.5364737 },
    { lat: 49.3098936, lng: 20.536316 },
    { lat: 49.3101568, lng: 20.5355433 },
    { lat: 49.3104916, lng: 20.5343611 },
    { lat: 49.3117438, lng: 20.5357505 },
    { lat: 49.3120811, lng: 20.5365424 },
    { lat: 49.3121327, lng: 20.5361934 },
    { lat: 49.3122527, lng: 20.5359987 },
    { lat: 49.3122379, lng: 20.5356266 },
    { lat: 49.3122741, lng: 20.5353864 },
    { lat: 49.3124119, lng: 20.5351556 },
    { lat: 49.3125707, lng: 20.535033 },
    { lat: 49.3128995, lng: 20.5350646 },
    { lat: 49.3129603, lng: 20.5349746 },
    { lat: 49.3130377, lng: 20.5340476 },
    { lat: 49.3131355, lng: 20.533739 },
    { lat: 49.3135867, lng: 20.5333625 },
    { lat: 49.3140735, lng: 20.53322 },
    { lat: 49.3141493, lng: 20.5331388 },
    { lat: 49.3143088, lng: 20.532791 },
    { lat: 49.314295, lng: 20.5323841 },
    { lat: 49.3144263, lng: 20.5320679 },
    { lat: 49.3146069, lng: 20.531776 },
    { lat: 49.3148434, lng: 20.5316879 },
    { lat: 49.3149798, lng: 20.5315361 },
    { lat: 49.3152088, lng: 20.530881 },
    { lat: 49.3152727, lng: 20.5302242 },
    { lat: 49.315691, lng: 20.530001 },
    { lat: 49.3159703, lng: 20.529758 },
    { lat: 49.3161934, lng: 20.5287227 },
    { lat: 49.3163836, lng: 20.5282607 },
    { lat: 49.3166748, lng: 20.5279113 },
    { lat: 49.3168385, lng: 20.527436 },
    { lat: 49.3170825, lng: 20.5273689 },
    { lat: 49.3173161, lng: 20.527107 },
    { lat: 49.3174294, lng: 20.5268854 },
    { lat: 49.3174378, lng: 20.5266823 },
    { lat: 49.317688, lng: 20.5265384 },
    { lat: 49.317731, lng: 20.5264507 },
    { lat: 49.3176855, lng: 20.5260742 },
    { lat: 49.3177075, lng: 20.5259017 },
    { lat: 49.3179541, lng: 20.5255712 },
    { lat: 49.3183001, lng: 20.5252433 },
    { lat: 49.3184666, lng: 20.5252103 },
    { lat: 49.3185206, lng: 20.5251183 },
    { lat: 49.3184427, lng: 20.524439 },
    { lat: 49.3188558, lng: 20.5238232 },
    { lat: 49.3190675, lng: 20.5233976 },
    { lat: 49.319305, lng: 20.5233392 },
    { lat: 49.3195426, lng: 20.5230308 },
    { lat: 49.3196606, lng: 20.5229954 },
    { lat: 49.3197443, lng: 20.5228839 },
    { lat: 49.3197626, lng: 20.5226773 },
    { lat: 49.3199256, lng: 20.5224262 },
    { lat: 49.319967, lng: 20.5217496 },
    { lat: 49.3201576, lng: 20.5215766 },
    { lat: 49.3202057, lng: 20.5214093 },
    { lat: 49.3201823, lng: 20.5212371 },
    { lat: 49.3202536, lng: 20.5210018 },
    { lat: 49.3201867, lng: 20.5206514 },
    { lat: 49.3203917, lng: 20.5203825 },
    { lat: 49.3204508, lng: 20.5201219 },
    { lat: 49.3204392, lng: 20.5200511 },
    { lat: 49.3201715, lng: 20.5198407 },
    { lat: 49.3203304, lng: 20.5193012 },
    { lat: 49.3201946, lng: 20.5191538 },
    { lat: 49.3201803, lng: 20.51901 },
    { lat: 49.3203061, lng: 20.5184553 },
    { lat: 49.3201658, lng: 20.51834 },
    { lat: 49.3201345, lng: 20.5181969 },
    { lat: 49.3203446, lng: 20.5180027 },
    { lat: 49.3204932, lng: 20.517668 },
    { lat: 49.3205078, lng: 20.5173921 },
    { lat: 49.3206676, lng: 20.5169604 },
    { lat: 49.3207698, lng: 20.5164038 },
    { lat: 49.3209512, lng: 20.516346 },
    { lat: 49.3210428, lng: 20.5161107 },
    { lat: 49.3211443, lng: 20.5156007 },
    { lat: 49.3212985, lng: 20.5154784 },
    { lat: 49.321413, lng: 20.515531 },
    { lat: 49.3215711, lng: 20.5154816 },
    { lat: 49.3222855, lng: 20.5155304 },
    { lat: 49.3225355, lng: 20.515446 },
    { lat: 49.3227039, lng: 20.5154622 },
    { lat: 49.322806, lng: 20.5154166 },
    { lat: 49.322881, lng: 20.515134 },
    { lat: 49.3229855, lng: 20.5150651 },
    { lat: 49.323439, lng: 20.5151249 },
    { lat: 49.3236575, lng: 20.51494 },
    { lat: 49.3236347, lng: 20.5147182 },
    { lat: 49.3237044, lng: 20.5145938 },
    { lat: 49.3239365, lng: 20.5145103 },
    { lat: 49.3243761, lng: 20.51404 },
    { lat: 49.3247069, lng: 20.5138194 },
    { lat: 49.3249299, lng: 20.5134887 },
    { lat: 49.3249784, lng: 20.5133377 },
    { lat: 49.3253549, lng: 20.5132294 },
    { lat: 49.3256277, lng: 20.512964 },
    { lat: 49.3259824, lng: 20.5129684 },
    { lat: 49.3260869, lng: 20.5128092 },
    { lat: 49.3265496, lng: 20.5129018 },
    { lat: 49.3270012, lng: 20.5127549 },
    { lat: 49.3276539, lng: 20.5128375 },
    { lat: 49.3279401, lng: 20.5127557 },
    { lat: 49.3282469, lng: 20.5127978 },
    { lat: 49.3285401, lng: 20.5127545 },
    { lat: 49.3286845, lng: 20.5126698 },
    { lat: 49.3290506, lng: 20.5127977 },
    { lat: 49.3302526, lng: 20.5125763 },
    { lat: 49.3305849, lng: 20.512583 },
    { lat: 49.331045, lng: 20.5124508 },
    { lat: 49.331544, lng: 20.5120948 },
    { lat: 49.3319421, lng: 20.5114392 },
    { lat: 49.3333649, lng: 20.5099129 },
    { lat: 49.3346131, lng: 20.5090218 },
    { lat: 49.3344238, lng: 20.5085912 },
    { lat: 49.333759, lng: 20.5077773 },
    { lat: 49.332744, lng: 20.506113 },
    { lat: 49.3321622, lng: 20.5044751 },
    { lat: 49.3313117, lng: 20.5032088 },
    { lat: 49.3284382, lng: 20.5002098 },
    { lat: 49.3276289, lng: 20.4996966 },
    { lat: 49.3268565, lng: 20.4994535 },
    { lat: 49.3260481, lng: 20.4976814 },
    { lat: 49.3245509, lng: 20.4973188 },
    { lat: 49.3234699, lng: 20.4971584 },
    { lat: 49.3234818, lng: 20.4963565 },
    { lat: 49.323124, lng: 20.494901 },
    { lat: 49.321983, lng: 20.491339 },
    { lat: 49.321382, lng: 20.491658 },
    { lat: 49.321353, lng: 20.491673 },
    { lat: 49.32078, lng: 20.491972 },
    { lat: 49.320165, lng: 20.492288 },
    { lat: 49.319632, lng: 20.492442 },
    { lat: 49.318772, lng: 20.49269 },
    { lat: 49.318005, lng: 20.493138 },
    { lat: 49.315786, lng: 20.493781 },
    { lat: 49.313568, lng: 20.494584 },
    { lat: 49.312445, lng: 20.495725 },
    { lat: 49.311063, lng: 20.496311 },
    { lat: 49.308651, lng: 20.497146 },
    { lat: 49.308474, lng: 20.497146 },
    { lat: 49.308442, lng: 20.497147 },
    { lat: 49.308306, lng: 20.497148 },
    { lat: 49.30823, lng: 20.497148 },
    { lat: 49.3082, lng: 20.497148 },
    { lat: 49.308168, lng: 20.497148 },
    { lat: 49.307, lng: 20.497157 },
    { lat: 49.305323, lng: 20.497211 },
    { lat: 49.30357, lng: 20.499071 },
    { lat: 49.301294, lng: 20.499068 },
    { lat: 49.301188, lng: 20.499051 },
    { lat: 49.300557, lng: 20.498954 },
    { lat: 49.300241, lng: 20.498905 },
    { lat: 49.300076, lng: 20.498928 },
    { lat: 49.299639, lng: 20.498986 },
    { lat: 49.299165, lng: 20.499016 },
    { lat: 49.299122, lng: 20.499019 },
    { lat: 49.29832, lng: 20.499069 },
    { lat: 49.296742, lng: 20.499187 },
    { lat: 49.295933, lng: 20.499756 },
    { lat: 49.295045, lng: 20.49949 },
    { lat: 49.294401, lng: 20.50015 },
    { lat: 49.29426, lng: 20.500296 },
    { lat: 49.293268, lng: 20.501314 },
    { lat: 49.292646, lng: 20.502049 },
    { lat: 49.292609, lng: 20.50216 },
    { lat: 49.292354, lng: 20.502573 },
    { lat: 49.292188, lng: 20.50266 },
    { lat: 49.292051, lng: 20.502721 },
    { lat: 49.291835, lng: 20.502781 },
    { lat: 49.291569, lng: 20.502984 },
    { lat: 49.291401, lng: 20.503459 },
    { lat: 49.291096, lng: 20.50388 },
    { lat: 49.29019, lng: 20.503775 },
    { lat: 49.290007, lng: 20.50357 },
    { lat: 49.289777, lng: 20.503573 },
    { lat: 49.289449, lng: 20.503806 },
    { lat: 49.289058, lng: 20.50389 },
    { lat: 49.288884, lng: 20.503871 },
    { lat: 49.288674, lng: 20.503857 },
    { lat: 49.288401, lng: 20.503881 },
    { lat: 49.288181, lng: 20.503875 },
    { lat: 49.287976, lng: 20.503965 },
    { lat: 49.287567, lng: 20.503955 },
    { lat: 49.287351, lng: 20.503913 },
    { lat: 49.287337, lng: 20.503911 },
    { lat: 49.287041, lng: 20.50388 },
    { lat: 49.286847, lng: 20.50412 },
    { lat: 49.286678, lng: 20.504416 },
    { lat: 49.286424, lng: 20.504779 },
    { lat: 49.286267, lng: 20.50505 },
    { lat: 49.286214, lng: 20.505078 },
    { lat: 49.286231, lng: 20.505555 },
    { lat: 49.28623, lng: 20.505658 },
    { lat: 49.286125, lng: 20.506271 },
    { lat: 49.28544, lng: 20.507485 },
    { lat: 49.285418, lng: 20.507525 },
    { lat: 49.28483, lng: 20.508282 },
    { lat: 49.284401, lng: 20.508803 },
    { lat: 49.284258, lng: 20.509004 },
    { lat: 49.283804, lng: 20.50964 },
    { lat: 49.283767, lng: 20.510027 },
    { lat: 49.283811, lng: 20.510316 },
    { lat: 49.283848, lng: 20.510696 },
    { lat: 49.283863, lng: 20.510895 },
    { lat: 49.283898, lng: 20.511318 },
    { lat: 49.28394, lng: 20.511832 },
    { lat: 49.283272, lng: 20.512291 },
    { lat: 49.282777, lng: 20.511987 },
    { lat: 49.282653, lng: 20.511668 },
    { lat: 49.282342, lng: 20.511756 },
    { lat: 49.282129, lng: 20.511885 },
    { lat: 49.28129, lng: 20.511975 },
    { lat: 49.280975, lng: 20.511907 },
    { lat: 49.280042, lng: 20.511185 },
    { lat: 49.279818, lng: 20.511088 },
    { lat: 49.27881, lng: 20.508472 },
    { lat: 49.277389, lng: 20.507775 },
    { lat: 49.276446, lng: 20.507313 },
    { lat: 49.275829, lng: 20.506764 },
    { lat: 49.275611, lng: 20.506583 },
    { lat: 49.274963, lng: 20.505673 },
    { lat: 49.274836, lng: 20.505432 },
    { lat: 49.274811, lng: 20.505399 },
    { lat: 49.27278, lng: 20.502831 },
    { lat: 49.272526, lng: 20.503133 },
    { lat: 49.272363, lng: 20.503362 },
    { lat: 49.272267, lng: 20.503464 },
    { lat: 49.272195, lng: 20.503495 },
    { lat: 49.271976, lng: 20.503732 },
    { lat: 49.27199, lng: 20.503784 },
    { lat: 49.271872, lng: 20.503917 },
    { lat: 49.271667, lng: 20.504044 },
    { lat: 49.271487, lng: 20.504128 },
    { lat: 49.271449, lng: 20.504197 },
    { lat: 49.271362, lng: 20.504255 },
    { lat: 49.271106, lng: 20.504347 },
    { lat: 49.270868, lng: 20.504535 },
    { lat: 49.270813, lng: 20.504634 },
    { lat: 49.270625, lng: 20.504758 },
    { lat: 49.27056, lng: 20.504829 },
    { lat: 49.270499, lng: 20.504869 },
    { lat: 49.270387, lng: 20.504981 },
    { lat: 49.270258, lng: 20.505177 },
    { lat: 49.269896, lng: 20.505871 },
    { lat: 49.269811, lng: 20.505777 },
    { lat: 49.269577, lng: 20.505537 },
    { lat: 49.269352, lng: 20.505331 },
    { lat: 49.268902, lng: 20.504919 },
    { lat: 49.26885, lng: 20.50487 },
    { lat: 49.268798, lng: 20.50482 },
    { lat: 49.268157, lng: 20.504215 },
    { lat: 49.267443, lng: 20.503553 },
    { lat: 49.265978, lng: 20.502171 },
    { lat: 49.265559, lng: 20.501766 },
    { lat: 49.265038, lng: 20.501262 },
    { lat: 49.264863, lng: 20.501074 },
    { lat: 49.264536, lng: 20.500719 },
    { lat: 49.263371, lng: 20.499549 },
    { lat: 49.26326, lng: 20.499737 },
    { lat: 49.262944, lng: 20.500267 },
    { lat: 49.262506, lng: 20.501006 },
    { lat: 49.261817, lng: 20.502167 },
    { lat: 49.261743, lng: 20.502185 },
    { lat: 49.261709, lng: 20.502194 },
    { lat: 49.261619, lng: 20.50236 },
    { lat: 49.261546, lng: 20.502511 },
    { lat: 49.26151, lng: 20.502587 },
    { lat: 49.261434, lng: 20.502645 },
    { lat: 49.261319, lng: 20.502733 },
    { lat: 49.261142, lng: 20.502941 },
    { lat: 49.261094, lng: 20.503008 },
    { lat: 49.260959, lng: 20.503194 },
    { lat: 49.260844, lng: 20.503353 },
    { lat: 49.260823, lng: 20.503386 },
    { lat: 49.260757, lng: 20.503487 },
    { lat: 49.260656, lng: 20.503646 },
    { lat: 49.260576, lng: 20.503771 },
    { lat: 49.260548, lng: 20.503814 },
    { lat: 49.260486, lng: 20.503932 },
    { lat: 49.260465, lng: 20.503972 },
    { lat: 49.260379, lng: 20.504139 },
    { lat: 49.260359, lng: 20.504177 },
    { lat: 49.260323, lng: 20.504245 },
    { lat: 49.260304, lng: 20.504288 },
    { lat: 49.260291, lng: 20.504314 },
    { lat: 49.260248, lng: 20.504406 },
    { lat: 49.260199, lng: 20.504533 },
    { lat: 49.260185, lng: 20.504571 },
    { lat: 49.26014, lng: 20.504685 },
    { lat: 49.260126, lng: 20.504723 },
    { lat: 49.260043, lng: 20.504862 },
    { lat: 49.260009, lng: 20.504918 },
    { lat: 49.25998, lng: 20.504969 },
    { lat: 49.259967, lng: 20.504997 },
    { lat: 49.259954, lng: 20.50503 },
    { lat: 49.259941, lng: 20.50506 },
    { lat: 49.259918, lng: 20.505118 },
    { lat: 49.259856, lng: 20.50522 },
    { lat: 49.259825, lng: 20.505258 },
    { lat: 49.259783, lng: 20.50531 },
    { lat: 49.259749, lng: 20.505376 },
    { lat: 49.259653, lng: 20.505563 },
    { lat: 49.259612, lng: 20.505644 },
    { lat: 49.259605, lng: 20.505655 },
    { lat: 49.259593, lng: 20.505675 },
    { lat: 49.259564, lng: 20.505722 },
    { lat: 49.259526, lng: 20.505784 },
    { lat: 49.259434, lng: 20.505986 },
    { lat: 49.259311, lng: 20.506258 },
    { lat: 49.259272, lng: 20.506209 },
    { lat: 49.259221, lng: 20.506271 },
    { lat: 49.259018, lng: 20.506075 },
    { lat: 49.258768, lng: 20.506486 },
    { lat: 49.258585, lng: 20.506774 },
    { lat: 49.25843, lng: 20.506994 },
    { lat: 49.258097, lng: 20.507497 },
    { lat: 49.257458, lng: 20.508381 },
    { lat: 49.257166, lng: 20.508683 },
    { lat: 49.256703, lng: 20.50909 },
    { lat: 49.256305, lng: 20.509524 },
    { lat: 49.256085, lng: 20.50983 },
    { lat: 49.256036, lng: 20.509915 },
    { lat: 49.256001, lng: 20.509974 },
    { lat: 49.255981, lng: 20.510006 },
    { lat: 49.255917, lng: 20.510117 },
    { lat: 49.255772, lng: 20.510318 },
    { lat: 49.25559, lng: 20.510599 },
    { lat: 49.255499, lng: 20.510695 },
    { lat: 49.255438, lng: 20.510777 },
    { lat: 49.255133, lng: 20.511248 },
    { lat: 49.25487, lng: 20.511732 },
    { lat: 49.254769, lng: 20.511881 },
    { lat: 49.254626, lng: 20.512169 },
    { lat: 49.253928, lng: 20.513382 },
    { lat: 49.253804, lng: 20.513554 },
    { lat: 49.253286, lng: 20.514391 },
    { lat: 49.253123, lng: 20.514629 },
    { lat: 49.252799, lng: 20.515147 },
    { lat: 49.252665, lng: 20.515368 },
    { lat: 49.252564, lng: 20.515536 },
    { lat: 49.252131, lng: 20.516264 },
    { lat: 49.252028, lng: 20.516378 },
    { lat: 49.251943, lng: 20.515912 },
    { lat: 49.251911, lng: 20.515733 },
    { lat: 49.251851, lng: 20.515504 },
    { lat: 49.251806, lng: 20.515315 },
    { lat: 49.251805, lng: 20.515069 },
    { lat: 49.251743, lng: 20.514902 },
    { lat: 49.251705, lng: 20.514753 },
    { lat: 49.251659, lng: 20.514617 },
    { lat: 49.251602, lng: 20.514485 },
    { lat: 49.25146, lng: 20.514253 },
    { lat: 49.251304, lng: 20.514072 },
    { lat: 49.251182, lng: 20.513972 },
    { lat: 49.251067, lng: 20.513853 },
    { lat: 49.250933, lng: 20.513617 },
    { lat: 49.250781, lng: 20.513151 },
    { lat: 49.250279, lng: 20.512496 },
    { lat: 49.250219, lng: 20.512305 },
    { lat: 49.250142, lng: 20.512145 },
    { lat: 49.250061, lng: 20.511943 },
    { lat: 49.249903, lng: 20.511703 },
    { lat: 49.24975, lng: 20.511444 },
    { lat: 49.249666, lng: 20.511302 },
    { lat: 49.249579, lng: 20.51113 },
    { lat: 49.24935, lng: 20.510739 },
    { lat: 49.249223, lng: 20.510569 },
    { lat: 49.249153, lng: 20.510487 },
    { lat: 49.248812, lng: 20.510087 },
    { lat: 49.248637, lng: 20.509826 },
    { lat: 49.248478, lng: 20.509632 },
    { lat: 49.248368, lng: 20.509454 },
    { lat: 49.248215, lng: 20.509179 },
    { lat: 49.2481, lng: 20.509061 },
    { lat: 49.248009, lng: 20.508967 },
    { lat: 49.247633, lng: 20.508653 },
    { lat: 49.24742, lng: 20.508449 },
    { lat: 49.2472, lng: 20.508326 },
    { lat: 49.247095, lng: 20.508304 },
    { lat: 49.246987, lng: 20.508302 },
    { lat: 49.246939, lng: 20.508303 },
    { lat: 49.246906, lng: 20.508304 },
    { lat: 49.246859, lng: 20.508305 },
    { lat: 49.246771, lng: 20.508449 },
    { lat: 49.246727, lng: 20.508535 },
    { lat: 49.246661, lng: 20.508741 },
    { lat: 49.246583, lng: 20.508948 },
    { lat: 49.24656, lng: 20.509091 },
    { lat: 49.246548, lng: 20.509232 },
    { lat: 49.246539, lng: 20.509456 },
    { lat: 49.246555, lng: 20.509647 },
    { lat: 49.246557, lng: 20.509688 },
    { lat: 49.246573, lng: 20.509842 },
    { lat: 49.24661, lng: 20.510079 },
    { lat: 49.246674, lng: 20.510239 },
    { lat: 49.246792, lng: 20.510391 },
    { lat: 49.246969, lng: 20.510586 },
    { lat: 49.24702, lng: 20.510754 },
    { lat: 49.247209, lng: 20.511446 },
    { lat: 49.247275, lng: 20.511718 },
    { lat: 49.247389, lng: 20.512245 },
    { lat: 49.247995, lng: 20.511755 },
    { lat: 49.248235, lng: 20.511564 },
    { lat: 49.248306, lng: 20.511473 },
    { lat: 49.24839, lng: 20.511684 },
    { lat: 49.248471, lng: 20.511854 },
    { lat: 49.248554, lng: 20.512027 },
    { lat: 49.248607, lng: 20.512132 },
    { lat: 49.24877, lng: 20.512378 },
    { lat: 49.248906, lng: 20.512716 },
    { lat: 49.248732, lng: 20.512936 },
    { lat: 49.248846, lng: 20.513311 },
    { lat: 49.248884, lng: 20.513418 },
    { lat: 49.248987, lng: 20.514469 },
    { lat: 49.249056, lng: 20.515147 },
    { lat: 49.249075, lng: 20.515274 },
    { lat: 49.249117, lng: 20.515506 },
    { lat: 49.249298, lng: 20.515895 },
    { lat: 49.248986, lng: 20.51598 },
    { lat: 49.249214, lng: 20.516518 },
    { lat: 49.249323, lng: 20.516834 },
    { lat: 49.249411, lng: 20.517244 },
    { lat: 49.249453, lng: 20.517629 },
    { lat: 49.249535, lng: 20.518001 },
    { lat: 49.249587, lng: 20.518227 },
    { lat: 49.249395, lng: 20.518423 },
    { lat: 49.248121, lng: 20.519959 },
    { lat: 49.247852, lng: 20.520071 },
    { lat: 49.2477783, lng: 20.5200595 },
    { lat: 49.247704, lng: 20.520048 },
    { lat: 49.24769, lng: 20.520059 },
    { lat: 49.247634, lng: 20.519919 },
    { lat: 49.247526, lng: 20.519712 },
    { lat: 49.24737, lng: 20.519469 },
    { lat: 49.247307, lng: 20.519399 },
    { lat: 49.247224, lng: 20.519328 },
    { lat: 49.247159, lng: 20.51929 },
    { lat: 49.247045, lng: 20.51923 },
    { lat: 49.2469571, lng: 20.5192026 },
    { lat: 49.2468801, lng: 20.5192657 },
    { lat: 49.246805, lng: 20.519409 },
    { lat: 49.246793, lng: 20.519396 },
    { lat: 49.2467717, lng: 20.519383 },
    { lat: 49.24671, lng: 20.519428 },
    { lat: 49.24665, lng: 20.519451 },
    { lat: 49.246493, lng: 20.519523 },
    { lat: 49.2464, lng: 20.519633 },
    { lat: 49.246349, lng: 20.519746 },
    { lat: 49.246303, lng: 20.519752 },
    { lat: 49.246277, lng: 20.51967 },
    { lat: 49.24623, lng: 20.519658 },
    { lat: 49.246196, lng: 20.519701 },
    { lat: 49.246147, lng: 20.519793 },
    { lat: 49.246048, lng: 20.52011 },
    { lat: 49.246026, lng: 20.520132 },
    { lat: 49.245909, lng: 20.520218 },
    { lat: 49.245849, lng: 20.520355 },
    { lat: 49.24576, lng: 20.520394 },
    { lat: 49.245721, lng: 20.520486 },
    { lat: 49.245659, lng: 20.520596 },
    { lat: 49.245629, lng: 20.52061 },
    { lat: 49.24535, lng: 20.520682 },
    { lat: 49.245177, lng: 20.52075 },
    { lat: 49.245033, lng: 20.520846 },
    { lat: 49.245022, lng: 20.520866 },
    { lat: 49.245002, lng: 20.520983 },
    { lat: 49.245031, lng: 20.521127 },
    { lat: 49.244979, lng: 20.521158 },
    { lat: 49.244817, lng: 20.52108 },
    { lat: 49.244796, lng: 20.521086 },
    { lat: 49.244728, lng: 20.521166 },
    { lat: 49.244718, lng: 20.521333 },
    { lat: 49.244708, lng: 20.521369 },
    { lat: 49.244581, lng: 20.521672 },
    { lat: 49.244408, lng: 20.522036 },
    { lat: 49.244003, lng: 20.522344 },
    { lat: 49.243928, lng: 20.522394 },
    { lat: 49.24376, lng: 20.522789 },
    { lat: 49.243752, lng: 20.522944 },
    { lat: 49.243735, lng: 20.522991 },
    { lat: 49.243629, lng: 20.523064 },
    { lat: 49.243448, lng: 20.523052 },
    { lat: 49.243202, lng: 20.523002 },
    { lat: 49.24318, lng: 20.52302 },
    { lat: 49.243115, lng: 20.5233 },
    { lat: 49.24308, lng: 20.523336 },
    { lat: 49.242891, lng: 20.523485 },
    { lat: 49.242796, lng: 20.523527 },
    { lat: 49.242703, lng: 20.523454 },
    { lat: 49.242684, lng: 20.523462 },
    { lat: 49.242546, lng: 20.523625 },
    { lat: 49.242372, lng: 20.523785 },
    { lat: 49.242299, lng: 20.523813 },
    { lat: 49.242079, lng: 20.523631 },
    { lat: 49.241996, lng: 20.523494 },
    { lat: 49.241881, lng: 20.523616 },
    { lat: 49.241765, lng: 20.523935 },
    { lat: 49.241625, lng: 20.524223 },
    { lat: 49.241634, lng: 20.5243 },
    { lat: 49.241373, lng: 20.524612 },
    { lat: 49.241349, lng: 20.524647 },
    { lat: 49.241322, lng: 20.524646 },
    { lat: 49.241304, lng: 20.524654 },
    { lat: 49.241217, lng: 20.524523 },
    { lat: 49.241203, lng: 20.524511 },
    { lat: 49.241129, lng: 20.524481 },
    { lat: 49.241033, lng: 20.524527 },
    { lat: 49.241024, lng: 20.524683 },
    { lat: 49.241001, lng: 20.524785 },
    { lat: 49.240978, lng: 20.524849 },
    { lat: 49.240975, lng: 20.524853 },
    { lat: 49.240958, lng: 20.524867 },
    { lat: 49.240841, lng: 20.524969 },
    { lat: 49.240593, lng: 20.525035 },
    { lat: 49.240549, lng: 20.525144 },
    { lat: 49.240534, lng: 20.525278 },
    { lat: 49.240465, lng: 20.525501 },
    { lat: 49.240461, lng: 20.525631 },
    { lat: 49.240517, lng: 20.525831 },
    { lat: 49.240484, lng: 20.526158 },
    { lat: 49.240494, lng: 20.526327 },
    { lat: 49.240465, lng: 20.526445 },
    { lat: 49.240215, lng: 20.526849 },
    { lat: 49.240154, lng: 20.527025 },
    { lat: 49.240156, lng: 20.527257 },
    { lat: 49.240032, lng: 20.527572 },
    { lat: 49.240024, lng: 20.527741 },
    { lat: 49.240104, lng: 20.527894 },
    { lat: 49.240126, lng: 20.528122 },
    { lat: 49.240074, lng: 20.528251 },
    { lat: 49.240013, lng: 20.528337 },
    { lat: 49.239901, lng: 20.528394 },
    { lat: 49.239903, lng: 20.528436 },
    { lat: 49.239917, lng: 20.528619 },
    { lat: 49.240009, lng: 20.528784 },
    { lat: 49.240043, lng: 20.528877 },
    { lat: 49.240038, lng: 20.529001 },
    { lat: 49.239728, lng: 20.53015 },
    { lat: 49.239663, lng: 20.530293 },
    { lat: 49.239702, lng: 20.530399 },
    { lat: 49.239831, lng: 20.530495 },
    { lat: 49.239861, lng: 20.530636 },
    { lat: 49.23989, lng: 20.5309 },
    { lat: 49.23984, lng: 20.531103 },
    { lat: 49.23961, lng: 20.531482 },
    { lat: 49.239533, lng: 20.53149 },
    { lat: 49.239531, lng: 20.531536 },
    { lat: 49.239626, lng: 20.531756 },
    { lat: 49.239717, lng: 20.53182 },
    { lat: 49.23975, lng: 20.531918 },
    { lat: 49.239741, lng: 20.532101 },
    { lat: 49.239706, lng: 20.532239 },
    { lat: 49.239706, lng: 20.532272 },
    { lat: 49.239751, lng: 20.53239 },
    { lat: 49.239834, lng: 20.532435 },
    { lat: 49.239902, lng: 20.532642 },
    { lat: 49.239891, lng: 20.532732 },
    { lat: 49.239847, lng: 20.532862 },
    { lat: 49.239748, lng: 20.533184 },
    { lat: 49.239719, lng: 20.533629 },
    { lat: 49.239685, lng: 20.534403 },
    { lat: 49.23961, lng: 20.534568 },
    { lat: 49.239568, lng: 20.535061 },
    { lat: 49.239497, lng: 20.535314 },
    { lat: 49.239454, lng: 20.535371 },
    { lat: 49.239394, lng: 20.535381 },
    { lat: 49.239325, lng: 20.535341 },
    { lat: 49.239193, lng: 20.535091 },
    { lat: 49.239175, lng: 20.535075 },
    { lat: 49.239063, lng: 20.535107 },
    { lat: 49.238989, lng: 20.535288 },
    { lat: 49.239001, lng: 20.535419 },
    { lat: 49.238908, lng: 20.535707 },
    { lat: 49.238735, lng: 20.536035 },
    { lat: 49.238695, lng: 20.536172 },
    { lat: 49.238641, lng: 20.536274 },
    { lat: 49.238589, lng: 20.53632 },
    { lat: 49.23848, lng: 20.536328 },
    { lat: 49.238465, lng: 20.536345 },
    { lat: 49.238405, lng: 20.536437 },
    { lat: 49.238234, lng: 20.536797 },
    { lat: 49.238156, lng: 20.536851 },
    { lat: 49.238037, lng: 20.536827 },
    { lat: 49.238015, lng: 20.536838 },
    { lat: 49.237899, lng: 20.536976 },
    { lat: 49.237858, lng: 20.53713 },
    { lat: 49.237708, lng: 20.537297 },
    { lat: 49.23769, lng: 20.537307 },
    { lat: 49.237554, lng: 20.537379 },
    { lat: 49.237499, lng: 20.53744 },
    { lat: 49.237406, lng: 20.537393 },
    { lat: 49.237346, lng: 20.537363 },
    { lat: 49.237324, lng: 20.537358 },
    { lat: 49.23722, lng: 20.537475 },
    { lat: 49.237143, lng: 20.537589 },
    { lat: 49.236717, lng: 20.538007 },
    { lat: 49.236593, lng: 20.538266 },
    { lat: 49.236569, lng: 20.538295 },
    { lat: 49.236329, lng: 20.538325 },
    { lat: 49.236258, lng: 20.538527 },
    { lat: 49.236204, lng: 20.538569 },
    { lat: 49.236081, lng: 20.53858 },
    { lat: 49.236002, lng: 20.538656 },
    { lat: 49.235937, lng: 20.538786 },
    { lat: 49.235912, lng: 20.539014 },
    { lat: 49.235886, lng: 20.539116 },
    { lat: 49.235906, lng: 20.539651 },
    { lat: 49.235883, lng: 20.53984 },
    { lat: 49.235808, lng: 20.539925 },
    { lat: 49.235758, lng: 20.540021 },
    { lat: 49.235748, lng: 20.540197 },
    { lat: 49.235738, lng: 20.540225 },
    { lat: 49.235721, lng: 20.540254 },
    { lat: 49.235702, lng: 20.540267 },
    { lat: 49.235548, lng: 20.540324 },
    { lat: 49.235458, lng: 20.540396 },
    { lat: 49.235386, lng: 20.540551 },
    { lat: 49.235306, lng: 20.540688 },
    { lat: 49.235307, lng: 20.540715 },
    { lat: 49.235367, lng: 20.54083 },
    { lat: 49.235334, lng: 20.540936 },
    { lat: 49.235256, lng: 20.541088 },
    { lat: 49.235217, lng: 20.541206 },
    { lat: 49.235107, lng: 20.541276 },
    { lat: 49.23503, lng: 20.541246 },
    { lat: 49.235002, lng: 20.541249 },
    { lat: 49.23481, lng: 20.541309 },
    { lat: 49.23463, lng: 20.541584 },
    { lat: 49.234426, lng: 20.541597 },
    { lat: 49.234183, lng: 20.541743 },
    { lat: 49.234126, lng: 20.541815 },
    { lat: 49.233939, lng: 20.542152 },
    { lat: 49.233917, lng: 20.542217 },
    { lat: 49.233761, lng: 20.542479 },
    { lat: 49.233528, lng: 20.542592 },
    { lat: 49.233489, lng: 20.542691 },
    { lat: 49.233437, lng: 20.542781 },
    { lat: 49.233376, lng: 20.542855 },
    { lat: 49.233347, lng: 20.542906 },
    { lat: 49.233339, lng: 20.542932 },
    { lat: 49.23334, lng: 20.542994 },
    { lat: 49.233422, lng: 20.54328 },
    { lat: 49.23314, lng: 20.543555 },
    { lat: 49.233082, lng: 20.543628 },
    { lat: 49.233, lng: 20.543686 },
    { lat: 49.23298, lng: 20.543719 },
    { lat: 49.232945, lng: 20.543965 },
    { lat: 49.232878, lng: 20.544013 },
    { lat: 49.232694, lng: 20.54403 },
    { lat: 49.232673, lng: 20.544084 },
    { lat: 49.23263, lng: 20.544293 },
    { lat: 49.232647, lng: 20.544378 },
    { lat: 49.2326, lng: 20.544448 },
    { lat: 49.232525, lng: 20.544417 },
    { lat: 49.232491, lng: 20.544363 },
    { lat: 49.232464, lng: 20.544364 },
    { lat: 49.232392, lng: 20.544402 },
    { lat: 49.232332, lng: 20.544463 },
    { lat: 49.232317, lng: 20.544493 },
    { lat: 49.232214, lng: 20.544691 },
    { lat: 49.23214, lng: 20.544784 },
    { lat: 49.231924, lng: 20.545011 },
    { lat: 49.231846, lng: 20.545076 },
    { lat: 49.231746, lng: 20.545131 },
    { lat: 49.231684, lng: 20.545185 },
    { lat: 49.231481, lng: 20.545495 },
    { lat: 49.231419, lng: 20.545667 },
    { lat: 49.231404, lng: 20.545696 },
    { lat: 49.231281, lng: 20.545757 },
    { lat: 49.231155, lng: 20.545748 },
    { lat: 49.231027, lng: 20.545904 },
    { lat: 49.230966, lng: 20.546097 },
    { lat: 49.230818, lng: 20.546196 },
    { lat: 49.230794, lng: 20.546608 },
    { lat: 49.230566, lng: 20.547116 },
    { lat: 49.230496, lng: 20.547159 },
    { lat: 49.230415, lng: 20.547089 },
    { lat: 49.230323, lng: 20.547186 },
    { lat: 49.230317, lng: 20.547379 },
    { lat: 49.230272, lng: 20.54755 },
    { lat: 49.230297, lng: 20.547865 },
    { lat: 49.23036, lng: 20.548127 },
    { lat: 49.230268, lng: 20.54837 },
    { lat: 49.230272, lng: 20.548504 },
    { lat: 49.230294, lng: 20.548617 },
    { lat: 49.230077, lng: 20.549259 },
    { lat: 49.23011, lng: 20.549424 },
    { lat: 49.230116, lng: 20.549896 },
    { lat: 49.230037, lng: 20.550419 },
    { lat: 49.229977, lng: 20.550422 },
    { lat: 49.229892, lng: 20.550889 },
    { lat: 49.229692, lng: 20.551293 },
    { lat: 49.229716, lng: 20.551474 },
    { lat: 49.229407, lng: 20.552095 },
    { lat: 49.229023, lng: 20.552467 },
    { lat: 49.229023, lng: 20.552488 },
    { lat: 49.2290136, lng: 20.5524993 },
    { lat: 49.2289712, lng: 20.5526618 },
    { lat: 49.2288823, lng: 20.5529391 },
    { lat: 49.2288892, lng: 20.553038 },
    { lat: 49.2288064, lng: 20.5531391 },
    { lat: 49.2287653, lng: 20.5534358 },
    { lat: 49.2287389, lng: 20.5535122 },
    { lat: 49.228645, lng: 20.5537756 },
    { lat: 49.2286278, lng: 20.5538282 },
    { lat: 49.2286216, lng: 20.5539361 },
    { lat: 49.2286008, lng: 20.5542891 },
    { lat: 49.2286358, lng: 20.5543837 },
    { lat: 49.2286322, lng: 20.5544067 },
    { lat: 49.2285526, lng: 20.5548479 },
    { lat: 49.2284882, lng: 20.5549957 },
    { lat: 49.2284104, lng: 20.5551781 },
    { lat: 49.2283887, lng: 20.5552293 },
    { lat: 49.228316, lng: 20.5551515 },
    { lat: 49.2281765, lng: 20.555001 },
    { lat: 49.2278725, lng: 20.55552 },
    { lat: 49.2274386, lng: 20.5561229 },
    { lat: 49.2274118, lng: 20.5561627 },
    { lat: 49.2272774, lng: 20.5564237 },
    { lat: 49.2271934, lng: 20.5565905 },
    { lat: 49.2269095, lng: 20.5566781 },
    { lat: 49.2268947, lng: 20.5566728 },
    { lat: 49.2262859, lng: 20.5564608 },
    { lat: 49.2262104, lng: 20.5564607 },
    { lat: 49.2261716, lng: 20.5564774 },
    { lat: 49.2259589, lng: 20.5566349 },
    { lat: 49.2258531, lng: 20.5567606 },
    { lat: 49.225744, lng: 20.5568862 },
    { lat: 49.2256988, lng: 20.5569405 },
    { lat: 49.2255193, lng: 20.5570841 },
    { lat: 49.2252009, lng: 20.557363 },
    { lat: 49.2251636, lng: 20.5574512 },
    { lat: 49.2250793, lng: 20.5576366 },
    { lat: 49.2250675, lng: 20.5576564 },
    { lat: 49.2249461, lng: 20.557935 },
    { lat: 49.2249443, lng: 20.5580079 },
    { lat: 49.2249413, lng: 20.5581123 },
    { lat: 49.2249372, lng: 20.5583097 },
    { lat: 49.2249333, lng: 20.5584184 },
    { lat: 49.2249043, lng: 20.5585775 },
    { lat: 49.2248799, lng: 20.5587125 },
    { lat: 49.2248771, lng: 20.5587261 },
    { lat: 49.2248541, lng: 20.5589031 },
    { lat: 49.2248559, lng: 20.5589512 },
    { lat: 49.2248601, lng: 20.559002 },
    { lat: 49.2248649, lng: 20.5591968 },
    { lat: 49.2248672, lng: 20.5596277 },
    { lat: 49.2248654, lng: 20.5596362 },
    { lat: 49.2248362, lng: 20.5597362 },
    { lat: 49.2248365, lng: 20.5597766 },
    { lat: 49.224856, lng: 20.5599669 },
    { lat: 49.2248772, lng: 20.5599924 },
    { lat: 49.2249699, lng: 20.5600969 },
    { lat: 49.2250098, lng: 20.5601411 },
    { lat: 49.2250661, lng: 20.5602032 },
    { lat: 49.225099, lng: 20.5602404 },
    { lat: 49.2251232, lng: 20.5602895 },
    { lat: 49.2254234, lng: 20.5609245 },
    { lat: 49.2263255, lng: 20.562597 },
    { lat: 49.2271275, lng: 20.5634101 },
    { lat: 49.2281149, lng: 20.5632323 },
    { lat: 49.2289866, lng: 20.5627179 },
    { lat: 49.2291157, lng: 20.562551 },
    { lat: 49.2294328, lng: 20.5623594 },
    { lat: 49.2299521, lng: 20.5624585 },
    { lat: 49.2302439, lng: 20.5616646 },
    { lat: 49.2303996, lng: 20.5613927 },
    { lat: 49.2309097, lng: 20.560756 },
    { lat: 49.2311133, lng: 20.5603499 },
    { lat: 49.2317011, lng: 20.5588143 },
    { lat: 49.2316593, lng: 20.5582235 },
    { lat: 49.2317819, lng: 20.5577015 },
    { lat: 49.2321523, lng: 20.5572697 },
    { lat: 49.2328217, lng: 20.5569522 },
    { lat: 49.2332273, lng: 20.5570822 },
    { lat: 49.2336579, lng: 20.5569101 },
    { lat: 49.2346221, lng: 20.5561898 },
    { lat: 49.2347159, lng: 20.556014 },
    { lat: 49.2356177, lng: 20.5551884 },
    { lat: 49.2362109, lng: 20.5548118 },
    { lat: 49.2374972, lng: 20.5543399 },
    { lat: 49.237825, lng: 20.5539762 },
    { lat: 49.2383165, lng: 20.5535974 },
    { lat: 49.2395873, lng: 20.5522985 },
    { lat: 49.240047, lng: 20.5521606 },
    { lat: 49.2401906, lng: 20.5522031 },
    { lat: 49.2404056, lng: 20.5521098 },
    { lat: 49.2406005, lng: 20.5521482 },
    { lat: 49.2410773, lng: 20.5520405 },
    { lat: 49.2412684, lng: 20.5521564 },
    { lat: 49.2414026, lng: 20.5521309 },
    { lat: 49.2417954, lng: 20.5522555 },
    { lat: 49.2420266, lng: 20.5522369 },
    { lat: 49.2423033, lng: 20.5524103 },
    { lat: 49.2427147, lng: 20.5529754 },
    { lat: 49.2429265, lng: 20.5529811 },
    { lat: 49.2431769, lng: 20.5530884 },
    { lat: 49.2433495, lng: 20.5533442 },
    { lat: 49.2434351, lng: 20.5533178 },
    { lat: 49.2436459, lng: 20.5534166 },
    { lat: 49.2437975, lng: 20.5532527 },
    { lat: 49.2440555, lng: 20.5531607 },
    { lat: 49.2441827, lng: 20.5529823 },
    { lat: 49.2448154, lng: 20.5528278 },
    { lat: 49.2450752, lng: 20.5528499 },
    { lat: 49.2452799, lng: 20.5526616 },
    { lat: 49.2453184, lng: 20.5528482 },
    { lat: 49.2455572, lng: 20.5528246 },
    { lat: 49.2462703, lng: 20.5531143 },
    { lat: 49.2462843, lng: 20.5532208 },
    { lat: 49.2466175, lng: 20.5535278 },
    { lat: 49.24682, lng: 20.5538334 },
    { lat: 49.2477091, lng: 20.5553019 },
    { lat: 49.2484503, lng: 20.5557809 },
    { lat: 49.248432, lng: 20.5558596 },
    { lat: 49.2505765, lng: 20.5574312 },
    { lat: 49.2531372, lng: 20.5588982 },
    { lat: 49.2537193, lng: 20.5593037 },
    { lat: 49.2542553, lng: 20.5583808 },
    { lat: 49.2548296, lng: 20.5575253 },
    { lat: 49.2558723, lng: 20.5589215 },
    { lat: 49.2565772, lng: 20.559478 },
    { lat: 49.2569651, lng: 20.5599902 },
    { lat: 49.2570972, lng: 20.5596614 },
    { lat: 49.2582176, lng: 20.5579732 },
    { lat: 49.2585398, lng: 20.5576751 },
    { lat: 49.2590154, lng: 20.5575992 },
    { lat: 49.2599917, lng: 20.5567431 },
    { lat: 49.260328, lng: 20.5572255 },
    { lat: 49.2605553, lng: 20.5574371 },
    { lat: 49.2611653, lng: 20.5590359 },
    { lat: 49.2616692, lng: 20.5600695 },
    { lat: 49.2621331, lng: 20.5612738 },
    { lat: 49.2623418, lng: 20.5614726 },
    { lat: 49.2626956, lng: 20.56219 },
    { lat: 49.2629913, lng: 20.5642154 },
    { lat: 49.2631877, lng: 20.5648453 },
    { lat: 49.2632782, lng: 20.5660332 },
    { lat: 49.2629828, lng: 20.5668841 },
    { lat: 49.2628354, lng: 20.5671641 },
    { lat: 49.2630569, lng: 20.5677713 },
    { lat: 49.2631189, lng: 20.5680872 },
    { lat: 49.2630156, lng: 20.5706908 },
    { lat: 49.2633752, lng: 20.5721575 },
    { lat: 49.2636199, lng: 20.5728453 },
    { lat: 49.2637177, lng: 20.5735824 },
    { lat: 49.2638091, lng: 20.5737134 },
    { lat: 49.2639548, lng: 20.5742237 },
    { lat: 49.2640568, lng: 20.5753872 },
    { lat: 49.2640541, lng: 20.576393 },
    { lat: 49.2639741, lng: 20.5780925 },
    { lat: 49.2639491, lng: 20.5783977 },
    { lat: 49.2637947, lng: 20.5790416 },
    { lat: 49.2638585, lng: 20.5797708 },
    { lat: 49.265403, lng: 20.5826581 },
    { lat: 49.2654844, lng: 20.5845537 },
    { lat: 49.2658665, lng: 20.587326 },
    { lat: 49.265873, lng: 20.5874149 },
    { lat: 49.2658074, lng: 20.5875567 },
    { lat: 49.2659677, lng: 20.5874757 },
    { lat: 49.2660734, lng: 20.587355 },
    { lat: 49.2661384, lng: 20.5873634 },
    { lat: 49.2662725, lng: 20.5873807 },
    { lat: 49.266719, lng: 20.5878915 },
    { lat: 49.2668097, lng: 20.5879151 },
    { lat: 49.2673455, lng: 20.5873261 },
    { lat: 49.2676092, lng: 20.5873006 },
    { lat: 49.2680496, lng: 20.5875534 },
    { lat: 49.268167, lng: 20.5875319 },
    { lat: 49.2682257, lng: 20.5874448 },
    { lat: 49.2682561, lng: 20.5869188 },
    { lat: 49.2683118, lng: 20.5868405 },
    { lat: 49.2686686, lng: 20.5868698 },
    { lat: 49.2688729, lng: 20.5867598 },
    { lat: 49.2690107, lng: 20.5865203 },
    { lat: 49.2691325, lng: 20.5864725 },
    { lat: 49.2695595, lng: 20.5866627 },
    { lat: 49.2695088, lng: 20.5862261 },
    { lat: 49.2696631, lng: 20.5861373 },
    { lat: 49.269835, lng: 20.5861927 },
    { lat: 49.2700096, lng: 20.5861606 },
    { lat: 49.2705248, lng: 20.5858444 },
    { lat: 49.2707947, lng: 20.5857739 },
    { lat: 49.2712694, lng: 20.5853004 },
    { lat: 49.2716781, lng: 20.5851763 },
    { lat: 49.2723546, lng: 20.58474 },
    { lat: 49.2718567, lng: 20.5829354 },
    { lat: 49.2731628, lng: 20.5830384 },
    { lat: 49.273435, lng: 20.5817272 },
    { lat: 49.2734435, lng: 20.5813104 },
    { lat: 49.2729226, lng: 20.580979 },
    { lat: 49.2721039, lng: 20.5802871 },
    { lat: 49.2717771, lng: 20.5797831 },
    { lat: 49.2716082, lng: 20.579194 },
    { lat: 49.2718275, lng: 20.5773613 },
    { lat: 49.271879, lng: 20.5765195 },
    { lat: 49.2718177, lng: 20.57533 },
    { lat: 49.2718636, lng: 20.5742121 },
    { lat: 49.2719896, lng: 20.5736167 },
    { lat: 49.2724598, lng: 20.5727097 },
    { lat: 49.2725119, lng: 20.5725037 },
    { lat: 49.2734949, lng: 20.5661457 },
    { lat: 49.2735585, lng: 20.5652707 },
    { lat: 49.2734761, lng: 20.5642733 },
    { lat: 49.2733224, lng: 20.5635182 },
    { lat: 49.272968, lng: 20.5629351 },
    { lat: 49.2722952, lng: 20.5622044 },
    { lat: 49.2725457, lng: 20.5621596 },
    { lat: 49.2732899, lng: 20.5616985 },
    { lat: 49.2733672, lng: 20.5616003 },
    { lat: 49.2733467, lng: 20.5614701 },
    { lat: 49.2735, lng: 20.5612884 },
    { lat: 49.2735979, lng: 20.5610435 },
    { lat: 49.2735843, lng: 20.5608835 },
    { lat: 49.2737902, lng: 20.561096 },
    { lat: 49.276205, lng: 20.5603586 },
    { lat: 49.2772191, lng: 20.559582 },
    { lat: 49.2781495, lng: 20.5587051 },
    { lat: 49.2788841, lng: 20.5585072 },
    { lat: 49.2799411, lng: 20.5570577 },
    { lat: 49.2800686, lng: 20.5570352 },
    { lat: 49.2802487, lng: 20.556819 },
    { lat: 49.2804175, lng: 20.5565143 },
    { lat: 49.2805562, lng: 20.5560864 },
    { lat: 49.2807196, lng: 20.5558464 },
    { lat: 49.2819265, lng: 20.5551386 },
    { lat: 49.2820262, lng: 20.5551564 },
    { lat: 49.2827145, lng: 20.5541864 },
    { lat: 49.2828635, lng: 20.5541964 },
    { lat: 49.2829055, lng: 20.55408 },
    { lat: 49.2831936, lng: 20.5538646 },
    { lat: 49.2834236, lng: 20.5538446 },
    { lat: 49.2837932, lng: 20.5536695 },
    { lat: 49.2852257, lng: 20.5519907 },
    { lat: 49.2854325, lng: 20.5519229 },
    { lat: 49.2856105, lng: 20.5517393 },
    { lat: 49.2860291, lng: 20.5516137 },
    { lat: 49.2862223, lng: 20.5514797 },
    { lat: 49.2863244, lng: 20.5515622 },
    { lat: 49.2863712, lng: 20.5514864 },
    { lat: 49.2865425, lng: 20.5514624 },
    { lat: 49.2870853, lng: 20.5511271 },
    { lat: 49.2879198, lng: 20.5501174 },
    { lat: 49.2887133, lng: 20.5497178 },
    { lat: 49.2891372, lng: 20.5491817 },
    { lat: 49.2894184, lng: 20.549233 },
    { lat: 49.2896519, lng: 20.549156 },
    { lat: 49.2906874, lng: 20.5484771 },
  ],
  Lomnička: [
    { lat: 49.2651635, lng: 20.6043779 },
    { lat: 49.2653653, lng: 20.6036039 },
    { lat: 49.2655292, lng: 20.6032178 },
    { lat: 49.2658924, lng: 20.6025823 },
    { lat: 49.2666474, lng: 20.6015492 },
    { lat: 49.2669586, lng: 20.6014083 },
    { lat: 49.2674832, lng: 20.6014066 },
    { lat: 49.267614, lng: 20.6013533 },
    { lat: 49.2674279, lng: 20.6005324 },
    { lat: 49.2669462, lng: 20.5989802 },
    { lat: 49.2665529, lng: 20.5962931 },
    { lat: 49.2663351, lng: 20.5953473 },
    { lat: 49.2662666, lng: 20.5946999 },
    { lat: 49.2661332, lng: 20.5942783 },
    { lat: 49.2661216, lng: 20.5940507 },
    { lat: 49.2656567, lng: 20.5926256 },
    { lat: 49.2656987, lng: 20.592046 },
    { lat: 49.2656829, lng: 20.5918926 },
    { lat: 49.2656172, lng: 20.591257 },
    { lat: 49.2655579, lng: 20.590683 },
    { lat: 49.2655221, lng: 20.5903369 },
    { lat: 49.2655173, lng: 20.5900582 },
    { lat: 49.2655053, lng: 20.5893507 },
    { lat: 49.2655041, lng: 20.5892819 },
    { lat: 49.2654485, lng: 20.5886389 },
    { lat: 49.2654322, lng: 20.58845 },
    { lat: 49.2654636, lng: 20.5881972 },
    { lat: 49.2658074, lng: 20.5875567 },
    { lat: 49.265873, lng: 20.5874149 },
    { lat: 49.2658665, lng: 20.587326 },
    { lat: 49.2654844, lng: 20.5845537 },
    { lat: 49.265403, lng: 20.5826581 },
    { lat: 49.2638585, lng: 20.5797708 },
    { lat: 49.2637947, lng: 20.5790416 },
    { lat: 49.2639491, lng: 20.5783977 },
    { lat: 49.2639741, lng: 20.5780925 },
    { lat: 49.2640541, lng: 20.576393 },
    { lat: 49.2640568, lng: 20.5753872 },
    { lat: 49.2639548, lng: 20.5742237 },
    { lat: 49.2638091, lng: 20.5737134 },
    { lat: 49.2637177, lng: 20.5735824 },
    { lat: 49.2636199, lng: 20.5728453 },
    { lat: 49.2633752, lng: 20.5721575 },
    { lat: 49.2630156, lng: 20.5706908 },
    { lat: 49.2631189, lng: 20.5680872 },
    { lat: 49.2630569, lng: 20.5677713 },
    { lat: 49.2628354, lng: 20.5671641 },
    { lat: 49.2629828, lng: 20.5668841 },
    { lat: 49.2632782, lng: 20.5660332 },
    { lat: 49.2631877, lng: 20.5648453 },
    { lat: 49.2629913, lng: 20.5642154 },
    { lat: 49.2626956, lng: 20.56219 },
    { lat: 49.2623418, lng: 20.5614726 },
    { lat: 49.2621331, lng: 20.5612738 },
    { lat: 49.2616692, lng: 20.5600695 },
    { lat: 49.2611653, lng: 20.5590359 },
    { lat: 49.2605553, lng: 20.5574371 },
    { lat: 49.260328, lng: 20.5572255 },
    { lat: 49.2599917, lng: 20.5567431 },
    { lat: 49.2590154, lng: 20.5575992 },
    { lat: 49.2585398, lng: 20.5576751 },
    { lat: 49.2582176, lng: 20.5579732 },
    { lat: 49.2570972, lng: 20.5596614 },
    { lat: 49.2569651, lng: 20.5599902 },
    { lat: 49.2565772, lng: 20.559478 },
    { lat: 49.2558723, lng: 20.5589215 },
    { lat: 49.2548296, lng: 20.5575253 },
    { lat: 49.2542553, lng: 20.5583808 },
    { lat: 49.2537193, lng: 20.5593037 },
    { lat: 49.2531372, lng: 20.5588982 },
    { lat: 49.2505765, lng: 20.5574312 },
    { lat: 49.248432, lng: 20.5558596 },
    { lat: 49.2484503, lng: 20.5557809 },
    { lat: 49.2477091, lng: 20.5553019 },
    { lat: 49.24682, lng: 20.5538334 },
    { lat: 49.2466175, lng: 20.5535278 },
    { lat: 49.2462843, lng: 20.5532208 },
    { lat: 49.2462703, lng: 20.5531143 },
    { lat: 49.2455572, lng: 20.5528246 },
    { lat: 49.2453184, lng: 20.5528482 },
    { lat: 49.2452799, lng: 20.5526616 },
    { lat: 49.2450752, lng: 20.5528499 },
    { lat: 49.2448154, lng: 20.5528278 },
    { lat: 49.2441827, lng: 20.5529823 },
    { lat: 49.2440555, lng: 20.5531607 },
    { lat: 49.2437975, lng: 20.5532527 },
    { lat: 49.2436459, lng: 20.5534166 },
    { lat: 49.2434351, lng: 20.5533178 },
    { lat: 49.2433495, lng: 20.5533442 },
    { lat: 49.2431769, lng: 20.5530884 },
    { lat: 49.2429265, lng: 20.5529811 },
    { lat: 49.2427147, lng: 20.5529754 },
    { lat: 49.2423033, lng: 20.5524103 },
    { lat: 49.2420266, lng: 20.5522369 },
    { lat: 49.2417954, lng: 20.5522555 },
    { lat: 49.2414026, lng: 20.5521309 },
    { lat: 49.2412684, lng: 20.5521564 },
    { lat: 49.2410773, lng: 20.5520405 },
    { lat: 49.2406005, lng: 20.5521482 },
    { lat: 49.2404056, lng: 20.5521098 },
    { lat: 49.2401906, lng: 20.5522031 },
    { lat: 49.240047, lng: 20.5521606 },
    { lat: 49.2395873, lng: 20.5522985 },
    { lat: 49.2383165, lng: 20.5535974 },
    { lat: 49.237825, lng: 20.5539762 },
    { lat: 49.2374972, lng: 20.5543399 },
    { lat: 49.2362109, lng: 20.5548118 },
    { lat: 49.2356177, lng: 20.5551884 },
    { lat: 49.2347159, lng: 20.556014 },
    { lat: 49.2346221, lng: 20.5561898 },
    { lat: 49.2336579, lng: 20.5569101 },
    { lat: 49.2332273, lng: 20.5570822 },
    { lat: 49.2328217, lng: 20.5569522 },
    { lat: 49.2321523, lng: 20.5572697 },
    { lat: 49.2317819, lng: 20.5577015 },
    { lat: 49.2316593, lng: 20.5582235 },
    { lat: 49.2317011, lng: 20.5588143 },
    { lat: 49.2311133, lng: 20.5603499 },
    { lat: 49.2309097, lng: 20.560756 },
    { lat: 49.2303996, lng: 20.5613927 },
    { lat: 49.2302439, lng: 20.5616646 },
    { lat: 49.2299521, lng: 20.5624585 },
    { lat: 49.2294328, lng: 20.5623594 },
    { lat: 49.2291157, lng: 20.562551 },
    { lat: 49.2289866, lng: 20.5627179 },
    { lat: 49.2281149, lng: 20.5632323 },
    { lat: 49.2271275, lng: 20.5634101 },
    { lat: 49.2263255, lng: 20.562597 },
    { lat: 49.2254234, lng: 20.5609245 },
    { lat: 49.225282, lng: 20.5610586 },
    { lat: 49.225137, lng: 20.5611873 },
    { lat: 49.2251349, lng: 20.5611889 },
    { lat: 49.225126, lng: 20.5611969 },
    { lat: 49.2250499, lng: 20.5612704 },
    { lat: 49.2249179, lng: 20.5613835 },
    { lat: 49.2248373, lng: 20.5614574 },
    { lat: 49.2247653, lng: 20.5615208 },
    { lat: 49.2246183, lng: 20.5616547 },
    { lat: 49.2245181, lng: 20.5617472 },
    { lat: 49.224383, lng: 20.5618718 },
    { lat: 49.2242511, lng: 20.5619932 },
    { lat: 49.2241108, lng: 20.5621247 },
    { lat: 49.2240029, lng: 20.5620684 },
    { lat: 49.2239627, lng: 20.5620473 },
    { lat: 49.2238754, lng: 20.5620022 },
    { lat: 49.2237665, lng: 20.5619452 },
    { lat: 49.2234944, lng: 20.5621099 },
    { lat: 49.2234159, lng: 20.562055 },
    { lat: 49.2233164, lng: 20.5618546 },
    { lat: 49.2232895, lng: 20.5617963 },
    { lat: 49.2230412, lng: 20.5614779 },
    { lat: 49.2228546, lng: 20.5616955 },
    { lat: 49.2228459, lng: 20.5617372 },
    { lat: 49.2228163, lng: 20.5618817 },
    { lat: 49.2227618, lng: 20.562146 },
    { lat: 49.2227235, lng: 20.5623318 },
    { lat: 49.2227088, lng: 20.5624177 },
    { lat: 49.2226669, lng: 20.5626612 },
    { lat: 49.2226128, lng: 20.5629694 },
    { lat: 49.2225907, lng: 20.5630995 },
    { lat: 49.2225446, lng: 20.5631689 },
    { lat: 49.2224552, lng: 20.5633041 },
    { lat: 49.2223772, lng: 20.563422 },
    { lat: 49.2222012, lng: 20.5636873 },
    { lat: 49.2221241, lng: 20.5636413 },
    { lat: 49.2218473, lng: 20.5634581 },
    { lat: 49.2217877, lng: 20.5637059 },
    { lat: 49.2217455, lng: 20.5638809 },
    { lat: 49.2217007, lng: 20.5644376 },
    { lat: 49.2216958, lng: 20.5649224 },
    { lat: 49.2216784, lng: 20.5650607 },
    { lat: 49.2216712, lng: 20.5650833 },
    { lat: 49.2216502, lng: 20.5650898 },
    { lat: 49.2215885, lng: 20.5650975 },
    { lat: 49.2215266, lng: 20.5650949 },
    { lat: 49.2213071, lng: 20.5650988 },
    { lat: 49.2207473, lng: 20.5651173 },
    { lat: 49.2206841, lng: 20.5651194 },
    { lat: 49.2206971, lng: 20.5657453 },
    { lat: 49.2207146, lng: 20.5666013 },
    { lat: 49.2207161, lng: 20.5666739 },
    { lat: 49.2207365, lng: 20.5676013 },
    { lat: 49.2207446, lng: 20.567977 },
    { lat: 49.2207618, lng: 20.5687848 },
    { lat: 49.2207685, lng: 20.5690932 },
    { lat: 49.2207701, lng: 20.5692926 },
    { lat: 49.2207713, lng: 20.5693256 },
    { lat: 49.2205311, lng: 20.5693414 },
    { lat: 49.2201993, lng: 20.5689842 },
    { lat: 49.2201469, lng: 20.568962 },
    { lat: 49.2201027, lng: 20.5689528 },
    { lat: 49.2199999, lng: 20.5689486 },
    { lat: 49.2199148, lng: 20.5688975 },
    { lat: 49.2198311, lng: 20.5688866 },
    { lat: 49.2197219, lng: 20.5688239 },
    { lat: 49.219642, lng: 20.5687352 },
    { lat: 49.2195796, lng: 20.5685961 },
    { lat: 49.2194166, lng: 20.5686654 },
    { lat: 49.2193503, lng: 20.5686401 },
    { lat: 49.2192272, lng: 20.5685645 },
    { lat: 49.2190863, lng: 20.5684404 },
    { lat: 49.2189346, lng: 20.5681994 },
    { lat: 49.2186351, lng: 20.5682608 },
    { lat: 49.2185614, lng: 20.5683013 },
    { lat: 49.2183263, lng: 20.5682464 },
    { lat: 49.2182192, lng: 20.5681486 },
    { lat: 49.218107, lng: 20.5681318 },
    { lat: 49.2180075, lng: 20.5679991 },
    { lat: 49.2179348, lng: 20.5679787 },
    { lat: 49.2178984, lng: 20.5679951 },
    { lat: 49.2178686, lng: 20.5680138 },
    { lat: 49.2176107, lng: 20.5680444 },
    { lat: 49.217513, lng: 20.5680153 },
    { lat: 49.2172358, lng: 20.5681634 },
    { lat: 49.2171156, lng: 20.5681409 },
    { lat: 49.2170533, lng: 20.5681008 },
    { lat: 49.2169708, lng: 20.5683044 },
    { lat: 49.2168725, lng: 20.5684219 },
    { lat: 49.2166665, lng: 20.5685428 },
    { lat: 49.2165018, lng: 20.5685946 },
    { lat: 49.2164451, lng: 20.5686426 },
    { lat: 49.2163039, lng: 20.5686651 },
    { lat: 49.2162412, lng: 20.5687385 },
    { lat: 49.2161929, lng: 20.5687973 },
    { lat: 49.2160611, lng: 20.5688579 },
    { lat: 49.2159775, lng: 20.5689708 },
    { lat: 49.215869, lng: 20.5690877 },
    { lat: 49.2157437, lng: 20.5691384 },
    { lat: 49.2155957, lng: 20.5691884 },
    { lat: 49.2155473, lng: 20.5691889 },
    { lat: 49.2154916, lng: 20.5691745 },
    { lat: 49.215353, lng: 20.5691691 },
    { lat: 49.2152372, lng: 20.5690813 },
    { lat: 49.2152119, lng: 20.5690975 },
    { lat: 49.2151743, lng: 20.5691724 },
    { lat: 49.2151463, lng: 20.5692064 },
    { lat: 49.2151099, lng: 20.5692002 },
    { lat: 49.2150871, lng: 20.5692522 },
    { lat: 49.2150549, lng: 20.5693924 },
    { lat: 49.2150324, lng: 20.5694797 },
    { lat: 49.2147625, lng: 20.5697068 },
    { lat: 49.21454, lng: 20.5697816 },
    { lat: 49.2144575, lng: 20.5698948 },
    { lat: 49.2142282, lng: 20.5699651 },
    { lat: 49.2140255, lng: 20.5700273 },
    { lat: 49.2140154, lng: 20.5700292 },
    { lat: 49.2140321, lng: 20.5702089 },
    { lat: 49.2139976, lng: 20.5705568 },
    { lat: 49.2139623, lng: 20.5707327 },
    { lat: 49.213929, lng: 20.5709591 },
    { lat: 49.2139461, lng: 20.5710581 },
    { lat: 49.2139079, lng: 20.5710927 },
    { lat: 49.2139124, lng: 20.5711813 },
    { lat: 49.2138802, lng: 20.5712236 },
    { lat: 49.2138262, lng: 20.5713791 },
    { lat: 49.2138368, lng: 20.5714902 },
    { lat: 49.2138285, lng: 20.5716619 },
    { lat: 49.2138251, lng: 20.5717501 },
    { lat: 49.2137843, lng: 20.5719004 },
    { lat: 49.2137333, lng: 20.5724755 },
    { lat: 49.2136741, lng: 20.5728579 },
    { lat: 49.2136239, lng: 20.5733695 },
    { lat: 49.2135473, lng: 20.5736105 },
    { lat: 49.2134397, lng: 20.5738432 },
    { lat: 49.2133231, lng: 20.5741012 },
    { lat: 49.2132475, lng: 20.574342 },
    { lat: 49.2131861, lng: 20.5746969 },
    { lat: 49.2131284, lng: 20.5750291 },
    { lat: 49.2130743, lng: 20.5752145 },
    { lat: 49.2129625, lng: 20.5753568 },
    { lat: 49.2128303, lng: 20.5755614 },
    { lat: 49.2126078, lng: 20.5759105 },
    { lat: 49.2125103, lng: 20.5759872 },
    { lat: 49.2125753, lng: 20.5770914 },
    { lat: 49.2126692, lng: 20.578711 },
    { lat: 49.2127506, lng: 20.5801437 },
    { lat: 49.2127321, lng: 20.5801611 },
    { lat: 49.212008, lng: 20.5811421 },
    { lat: 49.2113522, lng: 20.5820233 },
    { lat: 49.2109173, lng: 20.5826042 },
    { lat: 49.2095042, lng: 20.5844814 },
    { lat: 49.2087986, lng: 20.5854171 },
    { lat: 49.2085554, lng: 20.5857283 },
    { lat: 49.2084938, lng: 20.5858228 },
    { lat: 49.2087743, lng: 20.5870718 },
    { lat: 49.2092935, lng: 20.5894352 },
    { lat: 49.2095567, lng: 20.5906356 },
    { lat: 49.2098131, lng: 20.59181 },
    { lat: 49.210354, lng: 20.5942746 },
    { lat: 49.2106542, lng: 20.5956578 },
    { lat: 49.2099274, lng: 20.5966337 },
    { lat: 49.209641, lng: 20.5970219 },
    { lat: 49.2093136, lng: 20.5974661 },
    { lat: 49.208785, lng: 20.5981956 },
    { lat: 49.2084232, lng: 20.5986787 },
    { lat: 49.2082324, lng: 20.5989396 },
    { lat: 49.2080402, lng: 20.5991953 },
    { lat: 49.2078492, lng: 20.5994591 },
    { lat: 49.2076571, lng: 20.5997172 },
    { lat: 49.2074651, lng: 20.5999779 },
    { lat: 49.2072751, lng: 20.6002344 },
    { lat: 49.2070831, lng: 20.6004951 },
    { lat: 49.2068287, lng: 20.6008374 },
    { lat: 49.2065755, lng: 20.6011799 },
    { lat: 49.2063178, lng: 20.6015289 },
    { lat: 49.2060625, lng: 20.6018733 },
    { lat: 49.205809, lng: 20.6022155 },
    { lat: 49.2055494, lng: 20.60257 },
    { lat: 49.2052985, lng: 20.6029098 },
    { lat: 49.2047877, lng: 20.603606 },
    { lat: 49.2042791, lng: 20.6042951 },
    { lat: 49.203765, lng: 20.6049911 },
    { lat: 49.203252, lng: 20.6056852 },
    { lat: 49.2027391, lng: 20.6063786 },
    { lat: 49.2022249, lng: 20.607072 },
    { lat: 49.2019857, lng: 20.6073993 },
    { lat: 49.2017076, lng: 20.6077704 },
    { lat: 49.2014446, lng: 20.6081241 },
    { lat: 49.2013011, lng: 20.6083241 },
    { lat: 49.2012784, lng: 20.6083556 },
    { lat: 49.2008373, lng: 20.6082322 },
    { lat: 49.2006738, lng: 20.6079759 },
    { lat: 49.2005762, lng: 20.6079255 },
    { lat: 49.2005459, lng: 20.607955 },
    { lat: 49.2005255, lng: 20.6079848 },
    { lat: 49.2003269, lng: 20.6077827 },
    { lat: 49.2002031, lng: 20.6077258 },
    { lat: 49.2000401, lng: 20.6076081 },
    { lat: 49.1998608, lng: 20.6075952 },
    { lat: 49.1997728, lng: 20.6074967 },
    { lat: 49.1997371, lng: 20.60735 },
    { lat: 49.1994558, lng: 20.6069511 },
    { lat: 49.1993921, lng: 20.6069302 },
    { lat: 49.1990919, lng: 20.6069133 },
    { lat: 49.1989956, lng: 20.606893 },
    { lat: 49.1986436, lng: 20.6071178 },
    { lat: 49.1985376, lng: 20.6071152 },
    { lat: 49.1985095, lng: 20.6072709 },
    { lat: 49.198362, lng: 20.6074914 },
    { lat: 49.198236, lng: 20.6075898 },
    { lat: 49.1981532, lng: 20.6076619 },
    { lat: 49.1980305, lng: 20.6076935 },
    { lat: 49.1979652, lng: 20.6080061 },
    { lat: 49.1978798, lng: 20.6081047 },
    { lat: 49.1978178, lng: 20.6082226 },
    { lat: 49.1976713, lng: 20.6084703 },
    { lat: 49.197588, lng: 20.6085619 },
    { lat: 49.1975667, lng: 20.6086901 },
    { lat: 49.1974864, lng: 20.6088952 },
    { lat: 49.1972135, lng: 20.6094444 },
    { lat: 49.1971925, lng: 20.6095473 },
    { lat: 49.1970858, lng: 20.6096178 },
    { lat: 49.1968638, lng: 20.610107 },
    { lat: 49.196717, lng: 20.6103461 },
    { lat: 49.196597, lng: 20.6104538 },
    { lat: 49.1963735, lng: 20.6107436 },
    { lat: 49.196136, lng: 20.6109666 },
    { lat: 49.1960355, lng: 20.6109859 },
    { lat: 49.1958953, lng: 20.6110607 },
    { lat: 49.1957626, lng: 20.6111629 },
    { lat: 49.1955057, lng: 20.6112151 },
    { lat: 49.1950834, lng: 20.611337 },
    { lat: 49.1949817, lng: 20.6113236 },
    { lat: 49.1948101, lng: 20.611315 },
    { lat: 49.1947549, lng: 20.611437 },
    { lat: 49.1946078, lng: 20.6114476 },
    { lat: 49.1945267, lng: 20.611697 },
    { lat: 49.1944418, lng: 20.6117423 },
    { lat: 49.1942932, lng: 20.611866 },
    { lat: 49.1941029, lng: 20.6123971 },
    { lat: 49.1940366, lng: 20.612678 },
    { lat: 49.1940736, lng: 20.6131144 },
    { lat: 49.1941788, lng: 20.6136904 },
    { lat: 49.194239, lng: 20.614109 },
    { lat: 49.1942736, lng: 20.6143804 },
    { lat: 49.1943257, lng: 20.6152301 },
    { lat: 49.1943327, lng: 20.615705 },
    { lat: 49.1943738, lng: 20.6165024 },
    { lat: 49.1943402, lng: 20.616597 },
    { lat: 49.1943212, lng: 20.6169477 },
    { lat: 49.1942873, lng: 20.6172572 },
    { lat: 49.1940832, lng: 20.6180259 },
    { lat: 49.1938797, lng: 20.6183094 },
    { lat: 49.1931568, lng: 20.6193379 },
    { lat: 49.1926151, lng: 20.6200493 },
    { lat: 49.1920314, lng: 20.6208123 },
    { lat: 49.1915712, lng: 20.6214074 },
    { lat: 49.1921263, lng: 20.6227562 },
    { lat: 49.1928886, lng: 20.6241697 },
    { lat: 49.1929524, lng: 20.6245002 },
    { lat: 49.1929119, lng: 20.6251295 },
    { lat: 49.1930559, lng: 20.6264369 },
    { lat: 49.1933022, lng: 20.6271829 },
    { lat: 49.1936323, lng: 20.6274097 },
    { lat: 49.1938608, lng: 20.6280035 },
    { lat: 49.1941343, lng: 20.6285309 },
    { lat: 49.1952631, lng: 20.630367 },
    { lat: 49.1958477, lng: 20.6317283 },
    { lat: 49.1963441, lng: 20.6323519 },
    { lat: 49.1969551, lng: 20.6338751 },
    { lat: 49.1979159, lng: 20.6349927 },
    { lat: 49.1984223, lng: 20.6357411 },
    { lat: 49.1986368, lng: 20.6359453 },
    { lat: 49.1993578, lng: 20.6365196 },
    { lat: 49.2011724, lng: 20.6375877 },
    { lat: 49.2013534, lng: 20.6376432 },
    { lat: 49.2022088, lng: 20.6374554 },
    { lat: 49.2025305, lng: 20.6376052 },
    { lat: 49.2029597, lng: 20.6376154 },
    { lat: 49.2042994, lng: 20.6381492 },
    { lat: 49.2045729, lng: 20.6380867 },
    { lat: 49.2045989, lng: 20.6380874 },
    { lat: 49.2046687, lng: 20.6380884 },
    { lat: 49.2048956, lng: 20.6378567 },
    { lat: 49.2052691, lng: 20.6372534 },
    { lat: 49.206778, lng: 20.6341924 },
    { lat: 49.2068905, lng: 20.6337527 },
    { lat: 49.206545, lng: 20.632941 },
    { lat: 49.2061406, lng: 20.6324644 },
    { lat: 49.2056695, lng: 20.6321701 },
    { lat: 49.2049113, lng: 20.6312756 },
    { lat: 49.2046676, lng: 20.6307578 },
    { lat: 49.2046294, lng: 20.6304474 },
    { lat: 49.2050221, lng: 20.6308152 },
    { lat: 49.2053639, lng: 20.6307747 },
    { lat: 49.2057884, lng: 20.6308709 },
    { lat: 49.2062789, lng: 20.6308017 },
    { lat: 49.2071494, lng: 20.6305276 },
    { lat: 49.2078622, lng: 20.6304398 },
    { lat: 49.2081021, lng: 20.6304143 },
    { lat: 49.208476, lng: 20.6305716 },
    { lat: 49.2090356, lng: 20.6312038 },
    { lat: 49.2096602, lng: 20.6325736 },
    { lat: 49.2101641, lng: 20.632975 },
    { lat: 49.2104949, lng: 20.6330956 },
    { lat: 49.2114647, lng: 20.6329212 },
    { lat: 49.2122136, lng: 20.6322445 },
    { lat: 49.2127699, lng: 20.6323128 },
    { lat: 49.2135713, lng: 20.6320592 },
    { lat: 49.2138198, lng: 20.6322721 },
    { lat: 49.2140695, lng: 20.6322936 },
    { lat: 49.2142811, lng: 20.6324523 },
    { lat: 49.2150336, lng: 20.6326839 },
    { lat: 49.2160969, lng: 20.6334551 },
    { lat: 49.2164989, lng: 20.6335226 },
    { lat: 49.21705, lng: 20.6337591 },
    { lat: 49.2173984, lng: 20.6337134 },
    { lat: 49.2178564, lng: 20.6339541 },
    { lat: 49.2180556, lng: 20.6337999 },
    { lat: 49.2183444, lng: 20.6338805 },
    { lat: 49.2186017, lng: 20.6338341 },
    { lat: 49.2190701, lng: 20.6335885 },
    { lat: 49.2193575, lng: 20.6332836 },
    { lat: 49.2195917, lng: 20.6332892 },
    { lat: 49.2197562, lng: 20.6332243 },
    { lat: 49.2198905, lng: 20.6330499 },
    { lat: 49.2202895, lng: 20.6329351 },
    { lat: 49.220612, lng: 20.6332063 },
    { lat: 49.2208715, lng: 20.6332533 },
    { lat: 49.2211755, lng: 20.6330672 },
    { lat: 49.2218926, lng: 20.6332564 },
    { lat: 49.2219789, lng: 20.6334004 },
    { lat: 49.2221829, lng: 20.6334053 },
    { lat: 49.2224043, lng: 20.6332388 },
    { lat: 49.2225789, lng: 20.6332483 },
    { lat: 49.2227086, lng: 20.633286 },
    { lat: 49.2229287, lng: 20.6335488 },
    { lat: 49.2231894, lng: 20.6336016 },
    { lat: 49.2233982, lng: 20.6338668 },
    { lat: 49.2242447, lng: 20.6339612 },
    { lat: 49.2246179, lng: 20.6338514 },
    { lat: 49.2248721, lng: 20.6339994 },
    { lat: 49.225145, lng: 20.6337043 },
    { lat: 49.2252519, lng: 20.633673 },
    { lat: 49.2253577, lng: 20.6336692 },
    { lat: 49.2256299, lng: 20.6338784 },
    { lat: 49.2258222, lng: 20.6338478 },
    { lat: 49.2259338, lng: 20.6336905 },
    { lat: 49.2261146, lng: 20.6331819 },
    { lat: 49.2263734, lng: 20.6331415 },
    { lat: 49.2266413, lng: 20.6332725 },
    { lat: 49.2268295, lng: 20.633211 },
    { lat: 49.2270441, lng: 20.6329542 },
    { lat: 49.227581, lng: 20.6329485 },
    { lat: 49.2281664, lng: 20.6332024 },
    { lat: 49.2285006, lng: 20.6336084 },
    { lat: 49.2285962, lng: 20.6343535 },
    { lat: 49.2286534, lng: 20.6344744 },
    { lat: 49.2288876, lng: 20.6348539 },
    { lat: 49.2292855, lng: 20.6353065 },
    { lat: 49.229613, lng: 20.6353994 },
    { lat: 49.2298887, lng: 20.6360567 },
    { lat: 49.2300921, lng: 20.6362947 },
    { lat: 49.2304883, lng: 20.6363897 },
    { lat: 49.2311777, lng: 20.6369001 },
    { lat: 49.2316927, lng: 20.6371401 },
    { lat: 49.2319935, lng: 20.6374877 },
    { lat: 49.2322695, lng: 20.6374956 },
    { lat: 49.2328496, lng: 20.6370051 },
    { lat: 49.2333918, lng: 20.6352739 },
    { lat: 49.2323855, lng: 20.6343586 },
    { lat: 49.2316279, lng: 20.6333573 },
    { lat: 49.2312792, lng: 20.632678 },
    { lat: 49.2310955, lng: 20.6316369 },
    { lat: 49.2310152, lng: 20.6307479 },
    { lat: 49.2310325, lng: 20.630133 },
    { lat: 49.2315513, lng: 20.6297556 },
    { lat: 49.2321804, lng: 20.6291907 },
    { lat: 49.2326272, lng: 20.6284043 },
    { lat: 49.2339022, lng: 20.6272322 },
    { lat: 49.2346126, lng: 20.6270437 },
    { lat: 49.2354361, lng: 20.6257812 },
    { lat: 49.2381316, lng: 20.619826 },
    { lat: 49.2384229, lng: 20.6194772 },
    { lat: 49.2389858, lng: 20.6183539 },
    { lat: 49.2392033, lng: 20.6172022 },
    { lat: 49.2393581, lng: 20.6167201 },
    { lat: 49.2399475, lng: 20.6157108 },
    { lat: 49.2409073, lng: 20.6144801 },
    { lat: 49.2412071, lng: 20.6139883 },
    { lat: 49.2417068, lng: 20.6130513 },
    { lat: 49.2421325, lng: 20.6115311 },
    { lat: 49.2428182, lng: 20.6117854 },
    { lat: 49.2441489, lng: 20.6124743 },
    { lat: 49.2457314, lng: 20.6128458 },
    { lat: 49.2460299, lng: 20.6130037 },
    { lat: 49.2467892, lng: 20.6135804 },
    { lat: 49.2470717, lng: 20.6141396 },
    { lat: 49.2474651, lng: 20.6144704 },
    { lat: 49.2484445, lng: 20.6143141 },
    { lat: 49.2502648, lng: 20.6127556 },
    { lat: 49.2507265, lng: 20.6122944 },
    { lat: 49.2512407, lng: 20.6111632 },
    { lat: 49.2516385, lng: 20.6108 },
    { lat: 49.2530303, lng: 20.6112179 },
    { lat: 49.253541, lng: 20.6109565 },
    { lat: 49.254675, lng: 20.6101308 },
    { lat: 49.2556811, lng: 20.6097451 },
    { lat: 49.2561951, lng: 20.609642 },
    { lat: 49.2566487, lng: 20.6097648 },
    { lat: 49.2568852, lng: 20.6094566 },
    { lat: 49.2569302, lng: 20.6092978 },
    { lat: 49.2575284, lng: 20.6083753 },
    { lat: 49.2578041, lng: 20.6078792 },
    { lat: 49.2580785, lng: 20.6072146 },
    { lat: 49.2583303, lng: 20.6068902 },
    { lat: 49.2584061, lng: 20.6066824 },
    { lat: 49.2594591, lng: 20.6059531 },
    { lat: 49.2609639, lng: 20.6051324 },
    { lat: 49.2610706, lng: 20.605185 },
    { lat: 49.2632408, lng: 20.6049252 },
    { lat: 49.2633299, lng: 20.60463 },
    { lat: 49.2643224, lng: 20.6042157 },
    { lat: 49.2646293, lng: 20.6044522 },
    { lat: 49.2649269, lng: 20.6044628 },
    { lat: 49.2651635, lng: 20.6043779 },
  ],
  Hraničné: [
    { lat: 49.369199, lng: 20.6857191 },
    { lat: 49.369125, lng: 20.6864028 },
    { lat: 49.3689246, lng: 20.686972 },
    { lat: 49.3679792, lng: 20.6885312 },
    { lat: 49.3680705, lng: 20.6887577 },
    { lat: 49.3679873, lng: 20.6890484 },
    { lat: 49.3680345, lng: 20.6892085 },
    { lat: 49.3677223, lng: 20.6895768 },
    { lat: 49.3677863, lng: 20.6897004 },
    { lat: 49.3665939, lng: 20.6909328 },
    { lat: 49.3663594, lng: 20.6912387 },
    { lat: 49.3665135, lng: 20.691621 },
    { lat: 49.3665946, lng: 20.6921965 },
    { lat: 49.3665788, lng: 20.6923567 },
    { lat: 49.3668032, lng: 20.6938275 },
    { lat: 49.3671294, lng: 20.6946247 },
    { lat: 49.3669459, lng: 20.6947522 },
    { lat: 49.3667932, lng: 20.6949483 },
    { lat: 49.3667899, lng: 20.6951158 },
    { lat: 49.3667176, lng: 20.6951983 },
    { lat: 49.3667104, lng: 20.6954088 },
    { lat: 49.3663619, lng: 20.6957753 },
    { lat: 49.3660811, lng: 20.695917 },
    { lat: 49.3660924, lng: 20.6960493 },
    { lat: 49.3663938, lng: 20.6968738 },
    { lat: 49.3667181, lng: 20.6975483 },
    { lat: 49.3670218, lng: 20.6984955 },
    { lat: 49.3671233, lng: 20.6986322 },
    { lat: 49.3672917, lng: 20.6992608 },
    { lat: 49.3673149, lng: 20.6994366 },
    { lat: 49.3672662, lng: 20.6998151 },
    { lat: 49.3672974, lng: 20.7007007 },
    { lat: 49.367484, lng: 20.7013306 },
    { lat: 49.3679894, lng: 20.7042706 },
    { lat: 49.3679695, lng: 20.7050791 },
    { lat: 49.3681106, lng: 20.7063059 },
    { lat: 49.3684959, lng: 20.7079203 },
    { lat: 49.3686339, lng: 20.7087035 },
    { lat: 49.3685128, lng: 20.7094202 },
    { lat: 49.3682535, lng: 20.7103687 },
    { lat: 49.3680985, lng: 20.7106986 },
    { lat: 49.3680142, lng: 20.7106745 },
    { lat: 49.3679947, lng: 20.7107234 },
    { lat: 49.3677318, lng: 20.7113507 },
    { lat: 49.3680612, lng: 20.7121405 },
    { lat: 49.3688271, lng: 20.7131151 },
    { lat: 49.3692693, lng: 20.71343 },
    { lat: 49.3692542, lng: 20.7135443 },
    { lat: 49.3697697, lng: 20.7141531 },
    { lat: 49.3711241, lng: 20.7151554 },
    { lat: 49.3715029, lng: 20.7153391 },
    { lat: 49.371757, lng: 20.7156498 },
    { lat: 49.3720107, lng: 20.7157535 },
    { lat: 49.3726811, lng: 20.7143044 },
    { lat: 49.3736983, lng: 20.7144522 },
    { lat: 49.3743632, lng: 20.7144469 },
    { lat: 49.3751482, lng: 20.7151336 },
    { lat: 49.3762797, lng: 20.715592 },
    { lat: 49.3772472, lng: 20.7164732 },
    { lat: 49.3776344, lng: 20.716956 },
    { lat: 49.3782366, lng: 20.7175003 },
    { lat: 49.3782226, lng: 20.7175517 },
    { lat: 49.3786303, lng: 20.7178172 },
    { lat: 49.3792702, lng: 20.7181887 },
    { lat: 49.3801945, lng: 20.7183907 },
    { lat: 49.3807351, lng: 20.7185934 },
    { lat: 49.3810742, lng: 20.7188866 },
    { lat: 49.3817357, lng: 20.7190374 },
    { lat: 49.3824048, lng: 20.719341 },
    { lat: 49.3827745, lng: 20.7195805 },
    { lat: 49.3834822, lng: 20.7201823 },
    { lat: 49.383739, lng: 20.7198722 },
    { lat: 49.383994, lng: 20.7194336 },
    { lat: 49.3845355, lng: 20.7194403 },
    { lat: 49.3849276, lng: 20.7196118 },
    { lat: 49.385501, lng: 20.7196271 },
    { lat: 49.3859086, lng: 20.7197894 },
    { lat: 49.3863485, lng: 20.71985 },
    { lat: 49.3866779, lng: 20.7198425 },
    { lat: 49.387129, lng: 20.7196709 },
    { lat: 49.3871457, lng: 20.7197299 },
    { lat: 49.3877576, lng: 20.7193692 },
    { lat: 49.3881966, lng: 20.7188536 },
    { lat: 49.3884474, lng: 20.7186885 },
    { lat: 49.3888771, lng: 20.7186452 },
    { lat: 49.3895297, lng: 20.7182233 },
    { lat: 49.3901936, lng: 20.7176758 },
    { lat: 49.3904522, lng: 20.7173163 },
    { lat: 49.3907974, lng: 20.717307 },
    { lat: 49.391141, lng: 20.7174749 },
    { lat: 49.391388, lng: 20.7177447 },
    { lat: 49.3916519, lng: 20.7178863 },
    { lat: 49.3919232, lng: 20.7179872 },
    { lat: 49.3919207, lng: 20.7179444 },
    { lat: 49.3919372, lng: 20.71768 },
    { lat: 49.3918593, lng: 20.7169002 },
    { lat: 49.3919294, lng: 20.7161074 },
    { lat: 49.3923267, lng: 20.7153726 },
    { lat: 49.3917936, lng: 20.7140399 },
    { lat: 49.391778, lng: 20.7135613 },
    { lat: 49.3916431, lng: 20.7129582 },
    { lat: 49.3916715, lng: 20.7120337 },
    { lat: 49.3915705, lng: 20.7118495 },
    { lat: 49.3915749, lng: 20.7113635 },
    { lat: 49.3916294, lng: 20.7109615 },
    { lat: 49.3916094, lng: 20.7103586 },
    { lat: 49.3915035, lng: 20.709721 },
    { lat: 49.3913699, lng: 20.7095043 },
    { lat: 49.3911522, lng: 20.7093297 },
    { lat: 49.3910068, lng: 20.7089081 },
    { lat: 49.3909543, lng: 20.7083741 },
    { lat: 49.3903809, lng: 20.7066971 },
    { lat: 49.3903399, lng: 20.7062369 },
    { lat: 49.3903801, lng: 20.7051656 },
    { lat: 49.3902539, lng: 20.7045542 },
    { lat: 49.390283, lng: 20.7043635 },
    { lat: 49.3901831, lng: 20.7040523 },
    { lat: 49.3901475, lng: 20.7036759 },
    { lat: 49.3899872, lng: 20.7032508 },
    { lat: 49.3898389, lng: 20.7025549 },
    { lat: 49.3895052, lng: 20.7019596 },
    { lat: 49.3890736, lng: 20.7007006 },
    { lat: 49.3884603, lng: 20.6997754 },
    { lat: 49.3881655, lng: 20.6992073 },
    { lat: 49.3882341, lng: 20.6984024 },
    { lat: 49.3884366, lng: 20.6975347 },
    { lat: 49.3888046, lng: 20.6968015 },
    { lat: 49.3891501, lng: 20.6964803 },
    { lat: 49.3904017, lng: 20.694823 },
    { lat: 49.3906974, lng: 20.6946211 },
    { lat: 49.3909806, lng: 20.6942924 },
    { lat: 49.3910742, lng: 20.6941039 },
    { lat: 49.3915258, lng: 20.6938208 },
    { lat: 49.3923524, lng: 20.6931023 },
    { lat: 49.3933592, lng: 20.6912361 },
    { lat: 49.3942561, lng: 20.6901774 },
    { lat: 49.3943272, lng: 20.6897054 },
    { lat: 49.3942632, lng: 20.6889428 },
    { lat: 49.3943901, lng: 20.6884286 },
    { lat: 49.3945121, lng: 20.6881821 },
    { lat: 49.3946201, lng: 20.6876416 },
    { lat: 49.3948595, lng: 20.687088 },
    { lat: 49.3949658, lng: 20.6866586 },
    { lat: 49.3951595, lng: 20.6864062 },
    { lat: 49.3957709, lng: 20.6853263 },
    { lat: 49.3960143, lng: 20.6841909 },
    { lat: 49.3961376, lng: 20.6833824 },
    { lat: 49.3962727, lng: 20.6816022 },
    { lat: 49.396363, lng: 20.6812276 },
    { lat: 49.3964879, lng: 20.6809715 },
    { lat: 49.3969219, lng: 20.6807323 },
    { lat: 49.3974665, lng: 20.6806307 },
    { lat: 49.3981316, lng: 20.6802777 },
    { lat: 49.399213, lng: 20.6791907 },
    { lat: 49.3968945, lng: 20.6761137 },
    { lat: 49.395712, lng: 20.6763819 },
    { lat: 49.3942285, lng: 20.6754584 },
    { lat: 49.3925136, lng: 20.6753748 },
    { lat: 49.3909032, lng: 20.6769067 },
    { lat: 49.3891059, lng: 20.6766938 },
    { lat: 49.388924, lng: 20.6767392 },
    { lat: 49.3881341, lng: 20.676711 },
    { lat: 49.3879853, lng: 20.6766446 },
    { lat: 49.3872458, lng: 20.6767032 },
    { lat: 49.3868862, lng: 20.6769462 },
    { lat: 49.3865854, lng: 20.677003 },
    { lat: 49.3863502, lng: 20.676808 },
    { lat: 49.3860596, lng: 20.6769008 },
    { lat: 49.3856748, lng: 20.6766849 },
    { lat: 49.3848233, lng: 20.6765371 },
    { lat: 49.3843109, lng: 20.6765624 },
    { lat: 49.3841014, lng: 20.6766542 },
    { lat: 49.383533, lng: 20.6766213 },
    { lat: 49.3819176, lng: 20.6781725 },
    { lat: 49.381053, lng: 20.6789007 },
    { lat: 49.3801433, lng: 20.6791143 },
    { lat: 49.3790967, lng: 20.6791189 },
    { lat: 49.3788517, lng: 20.679033 },
    { lat: 49.378729, lng: 20.6789056 },
    { lat: 49.3783187, lng: 20.6780622 },
    { lat: 49.3776526, lng: 20.6783881 },
    { lat: 49.377167, lng: 20.678949 },
    { lat: 49.3771764, lng: 20.6790563 },
    { lat: 49.3762537, lng: 20.6798272 },
    { lat: 49.3756943, lng: 20.6800507 },
    { lat: 49.3756865, lng: 20.6805299 },
    { lat: 49.3741037, lng: 20.6831895 },
    { lat: 49.3734957, lng: 20.683858 },
    { lat: 49.3732994, lng: 20.6842246 },
    { lat: 49.3732878, lng: 20.6843759 },
    { lat: 49.3730796, lng: 20.6845647 },
    { lat: 49.3727787, lng: 20.685099 },
    { lat: 49.3711618, lng: 20.6859717 },
    { lat: 49.3709025, lng: 20.6862489 },
    { lat: 49.3702982, lng: 20.6862142 },
    { lat: 49.3692599, lng: 20.6857621 },
    { lat: 49.369199, lng: 20.6857191 },
  ],
  Starina: [
    { lat: 49.3129341, lng: 20.8658681 },
    { lat: 49.3128702, lng: 20.8649071 },
    { lat: 49.3128549, lng: 20.8640596 },
    { lat: 49.3129348, lng: 20.8623672 },
    { lat: 49.3130844, lng: 20.8607678 },
    { lat: 49.3133113, lng: 20.8587944 },
    { lat: 49.3134169, lng: 20.8582568 },
    { lat: 49.3135829, lng: 20.8576177 },
    { lat: 49.313846, lng: 20.8569129 },
    { lat: 49.3139165, lng: 20.8557043 },
    { lat: 49.3145595, lng: 20.8552467 },
    { lat: 49.3149984, lng: 20.8551148 },
    { lat: 49.3151912, lng: 20.854764 },
    { lat: 49.315356, lng: 20.8538191 },
    { lat: 49.3156625, lng: 20.8529632 },
    { lat: 49.3160062, lng: 20.8521805 },
    { lat: 49.3165989, lng: 20.8512106 },
    { lat: 49.3166343, lng: 20.85126 },
    { lat: 49.3171008, lng: 20.8505465 },
    { lat: 49.3172977, lng: 20.8505807 },
    { lat: 49.3182292, lng: 20.8496065 },
    { lat: 49.3185556, lng: 20.8494734 },
    { lat: 49.3186897, lng: 20.8493454 },
    { lat: 49.3189293, lng: 20.8489497 },
    { lat: 49.3190453, lng: 20.8488525 },
    { lat: 49.3198919, lng: 20.8473056 },
    { lat: 49.3220813, lng: 20.8421263 },
    { lat: 49.3224827, lng: 20.8418494 },
    { lat: 49.3229886, lng: 20.8407768 },
    { lat: 49.3232718, lng: 20.840428 },
    { lat: 49.3237236, lng: 20.840677 },
    { lat: 49.3239627, lng: 20.8403285 },
    { lat: 49.324059, lng: 20.8402826 },
    { lat: 49.325167, lng: 20.8403949 },
    { lat: 49.3256122, lng: 20.8403168 },
    { lat: 49.326521, lng: 20.8405968 },
    { lat: 49.3269716, lng: 20.8408434 },
    { lat: 49.3277254, lng: 20.8410943 },
    { lat: 49.3282773, lng: 20.8416681 },
    { lat: 49.3287491, lng: 20.8427029 },
    { lat: 49.3288791, lng: 20.8426846 },
    { lat: 49.3300466, lng: 20.8419672 },
    { lat: 49.3300835, lng: 20.8420603 },
    { lat: 49.3305258, lng: 20.8415663 },
    { lat: 49.3307956, lng: 20.8411627 },
    { lat: 49.3310078, lng: 20.840983 },
    { lat: 49.3316013, lng: 20.8407289 },
    { lat: 49.3316285, lng: 20.8408278 },
    { lat: 49.3317083, lng: 20.8406994 },
    { lat: 49.3319452, lng: 20.8405521 },
    { lat: 49.3320878, lng: 20.8403743 },
    { lat: 49.333375, lng: 20.8393355 },
    { lat: 49.3336281, lng: 20.8386127 },
    { lat: 49.334186, lng: 20.838235 },
    { lat: 49.334112, lng: 20.837902 },
    { lat: 49.334112, lng: 20.837838 },
    { lat: 49.334063, lng: 20.837677 },
    { lat: 49.33402, lng: 20.83733 },
    { lat: 49.334027, lng: 20.837277 },
    { lat: 49.334023, lng: 20.837135 },
    { lat: 49.334005, lng: 20.836958 },
    { lat: 49.334024, lng: 20.836729 },
    { lat: 49.334024, lng: 20.836563 },
    { lat: 49.334139, lng: 20.835957 },
    { lat: 49.334227, lng: 20.835746 },
    { lat: 49.334331, lng: 20.835595 },
    { lat: 49.334549, lng: 20.835394 },
    { lat: 49.334975, lng: 20.834994 },
    { lat: 49.334983, lng: 20.834962 },
    { lat: 49.335149, lng: 20.834822 },
    { lat: 49.335334, lng: 20.834399 },
    { lat: 49.335341, lng: 20.833847 },
    { lat: 49.335311, lng: 20.833276 },
    { lat: 49.335276, lng: 20.833118 },
    { lat: 49.335253, lng: 20.832668 },
    { lat: 49.335231, lng: 20.83239 },
    { lat: 49.335206, lng: 20.832136 },
    { lat: 49.335193, lng: 20.831987 },
    { lat: 49.335142, lng: 20.831441 },
    { lat: 49.33513, lng: 20.831294 },
    { lat: 49.335034, lng: 20.830707 },
    { lat: 49.335019, lng: 20.830626 },
    { lat: 49.334946, lng: 20.830217 },
    { lat: 49.334864, lng: 20.829808 },
    { lat: 49.334845, lng: 20.829705 },
    { lat: 49.334861, lng: 20.829621 },
    { lat: 49.33476, lng: 20.829031 },
    { lat: 49.334749, lng: 20.828979 },
    { lat: 49.334699, lng: 20.828751 },
    { lat: 49.334478, lng: 20.827745 },
    { lat: 49.334486, lng: 20.827644 },
    { lat: 49.334447, lng: 20.826992 },
    { lat: 49.334433, lng: 20.826656 },
    { lat: 49.334429, lng: 20.826423 },
    { lat: 49.334399, lng: 20.826092 },
    { lat: 49.334404, lng: 20.825903 },
    { lat: 49.334498, lng: 20.825757 },
    { lat: 49.334538, lng: 20.825702 },
    { lat: 49.334625, lng: 20.825543 },
    { lat: 49.33476, lng: 20.825308 },
    { lat: 49.334797, lng: 20.825278 },
    { lat: 49.335235, lng: 20.824927 },
    { lat: 49.335342, lng: 20.824901 },
    { lat: 49.335623, lng: 20.824751 },
    { lat: 49.335859, lng: 20.824501 },
    { lat: 49.336058, lng: 20.824311 },
    { lat: 49.336208, lng: 20.82411 },
    { lat: 49.33651, lng: 20.823637 },
    { lat: 49.336534, lng: 20.823607 },
    { lat: 49.336605, lng: 20.823511 },
    { lat: 49.336661, lng: 20.82343 },
    { lat: 49.336883, lng: 20.823193 },
    { lat: 49.336966, lng: 20.823105 },
    { lat: 49.337159, lng: 20.822927 },
    { lat: 49.337246, lng: 20.822801 },
    { lat: 49.337418, lng: 20.822586 },
    { lat: 49.337447, lng: 20.822539 },
    { lat: 49.33758, lng: 20.822367 },
    { lat: 49.337704, lng: 20.822176 },
    { lat: 49.337834, lng: 20.821981 },
    { lat: 49.33795, lng: 20.821797 },
    { lat: 49.338008, lng: 20.821691 },
    { lat: 49.338128, lng: 20.821473 },
    { lat: 49.338233, lng: 20.821273 },
    { lat: 49.338264, lng: 20.821211 },
    { lat: 49.338331, lng: 20.821071 },
    { lat: 49.338416, lng: 20.820891 },
    { lat: 49.338449, lng: 20.820813 },
    { lat: 49.33857, lng: 20.820532 },
    { lat: 49.338602, lng: 20.820472 },
    { lat: 49.338633, lng: 20.820364 },
    { lat: 49.338753, lng: 20.820114 },
    { lat: 49.338808, lng: 20.820057 },
    { lat: 49.338864, lng: 20.81998 },
    { lat: 49.339065, lng: 20.819749 },
    { lat: 49.339126, lng: 20.819684 },
    { lat: 49.339349, lng: 20.819438 },
    { lat: 49.339386, lng: 20.819404 },
    { lat: 49.339618, lng: 20.819222 },
    { lat: 49.339773, lng: 20.819118 },
    { lat: 49.33996, lng: 20.819048 },
    { lat: 49.340104, lng: 20.819011 },
    { lat: 49.340112, lng: 20.819009 },
    { lat: 49.34046, lng: 20.818965 },
    { lat: 49.340494, lng: 20.818959 },
    { lat: 49.340615, lng: 20.818943 },
    { lat: 49.3407, lng: 20.818922 },
    { lat: 49.340905, lng: 20.81891 },
    { lat: 49.341176, lng: 20.818904 },
    { lat: 49.34134, lng: 20.818872 },
    { lat: 49.341731, lng: 20.818893 },
    { lat: 49.341873, lng: 20.818917 },
    { lat: 49.341985, lng: 20.818996 },
    { lat: 49.342161, lng: 20.818961 },
    { lat: 49.3422, lng: 20.818946 },
    { lat: 49.342303, lng: 20.818892 },
    { lat: 49.342446, lng: 20.818817 },
    { lat: 49.342519, lng: 20.818765 },
    { lat: 49.342659, lng: 20.818684 },
    { lat: 49.34269, lng: 20.818646 },
    { lat: 49.342795, lng: 20.81855 },
    { lat: 49.342824, lng: 20.818508 },
    { lat: 49.34294, lng: 20.818355 },
    { lat: 49.343072, lng: 20.818165 },
    { lat: 49.343163, lng: 20.818024 },
    { lat: 49.343212, lng: 20.817946 },
    { lat: 49.343264, lng: 20.817909 },
    { lat: 49.343328, lng: 20.817771 },
    { lat: 49.343372, lng: 20.817643 },
    { lat: 49.343409, lng: 20.817513 },
    { lat: 49.343457, lng: 20.817347 },
    { lat: 49.343528, lng: 20.817106 },
    { lat: 49.343546, lng: 20.817018 },
    { lat: 49.343596, lng: 20.816793 },
    { lat: 49.343644, lng: 20.816524 },
    { lat: 49.343654, lng: 20.816466 },
    { lat: 49.34373, lng: 20.81601 },
    { lat: 49.343774, lng: 20.815753 },
    { lat: 49.343223, lng: 20.8155671 },
    { lat: 49.3420421, lng: 20.8152908 },
    { lat: 49.340985, lng: 20.8148917 },
    { lat: 49.3400423, lng: 20.8141827 },
    { lat: 49.3392752, lng: 20.8130496 },
    { lat: 49.3390538, lng: 20.813223 },
    { lat: 49.3380597, lng: 20.8129488 },
    { lat: 49.3379456, lng: 20.8127428 },
    { lat: 49.3373973, lng: 20.8126328 },
    { lat: 49.3365755, lng: 20.8130911 },
    { lat: 49.3356433, lng: 20.8124615 },
    { lat: 49.3346941, lng: 20.8121812 },
    { lat: 49.3343797, lng: 20.8117135 },
    { lat: 49.3324869, lng: 20.8119139 },
    { lat: 49.3304954, lng: 20.8115088 },
    { lat: 49.3283819, lng: 20.8121037 },
    { lat: 49.327568, lng: 20.8126861 },
    { lat: 49.3265047, lng: 20.8130909 },
    { lat: 49.3256379, lng: 20.8127932 },
    { lat: 49.3254578, lng: 20.8122317 },
    { lat: 49.3252337, lng: 20.8120628 },
    { lat: 49.3250687, lng: 20.81116 },
    { lat: 49.3248373, lng: 20.8110425 },
    { lat: 49.3246472, lng: 20.81078 },
    { lat: 49.3244591, lng: 20.8106475 },
    { lat: 49.3235166, lng: 20.8103693 },
    { lat: 49.321798, lng: 20.8108288 },
    { lat: 49.3194994, lng: 20.8111163 },
    { lat: 49.3165886, lng: 20.8076981 },
    { lat: 49.3164126, lng: 20.8069327 },
    { lat: 49.3163386, lng: 20.8057943 },
    { lat: 49.3151861, lng: 20.8125422 },
    { lat: 49.3145726, lng: 20.8133288 },
    { lat: 49.3138583, lng: 20.8144248 },
    { lat: 49.313739, lng: 20.8145213 },
    { lat: 49.31296, lng: 20.8143836 },
    { lat: 49.3129311, lng: 20.8144277 },
    { lat: 49.3127267, lng: 20.8143089 },
    { lat: 49.3117492, lng: 20.8144544 },
    { lat: 49.3110591, lng: 20.8146462 },
    { lat: 49.3097276, lng: 20.8159892 },
    { lat: 49.3068299, lng: 20.8218156 },
    { lat: 49.305843, lng: 20.8253038 },
    { lat: 49.3059347, lng: 20.8271049 },
    { lat: 49.3053862, lng: 20.8288995 },
    { lat: 49.3048934, lng: 20.8321659 },
    { lat: 49.3050951, lng: 20.8355686 },
    { lat: 49.3049592, lng: 20.8376879 },
    { lat: 49.3047025, lng: 20.8385076 },
    { lat: 49.304291, lng: 20.8401079 },
    { lat: 49.3040482, lng: 20.8407119 },
    { lat: 49.3036791, lng: 20.8422287 },
    { lat: 49.3030817, lng: 20.8430661 },
    { lat: 49.3032434, lng: 20.8447025 },
    { lat: 49.3029341, lng: 20.8458639 },
    { lat: 49.3028344, lng: 20.8481938 },
    { lat: 49.3031606, lng: 20.84938 },
    { lat: 49.3063446, lng: 20.852529 },
    { lat: 49.3088395, lng: 20.8559636 },
    { lat: 49.3090207, lng: 20.8566966 },
    { lat: 49.30862, lng: 20.8589816 },
    { lat: 49.310606, lng: 20.8623539 },
    { lat: 49.3110398, lng: 20.8636029 },
    { lat: 49.3129341, lng: 20.8658681 },
  ],
  ŠarišskéJastrabie: [
    { lat: 49.260353, lng: 20.997189 },
    { lat: 49.2601039, lng: 20.99673 },
    { lat: 49.2601815, lng: 20.9959739 },
    { lat: 49.2599524, lng: 20.9927289 },
    { lat: 49.2597737, lng: 20.9924994 },
    { lat: 49.2596209, lng: 20.9924343 },
    { lat: 49.2592498, lng: 20.9915195 },
    { lat: 49.2592789, lng: 20.9907945 },
    { lat: 49.2590837, lng: 20.9901933 },
    { lat: 49.2586579, lng: 20.989701 },
    { lat: 49.2585751, lng: 20.9888243 },
    { lat: 49.2592696, lng: 20.9877679 },
    { lat: 49.2594778, lng: 20.9869143 },
    { lat: 49.2590468, lng: 20.9852027 },
    { lat: 49.2584663, lng: 20.9839434 },
    { lat: 49.2581224, lng: 20.9812157 },
    { lat: 49.2575945, lng: 20.9792995 },
    { lat: 49.2572357, lng: 20.9787225 },
    { lat: 49.2581773, lng: 20.9774849 },
    { lat: 49.2592069, lng: 20.9766318 },
    { lat: 49.2602464, lng: 20.974907 },
    { lat: 49.260581, lng: 20.9735553 },
    { lat: 49.260067, lng: 20.9713137 },
    { lat: 49.2599653, lng: 20.969903 },
    { lat: 49.2614101, lng: 20.9671739 },
    { lat: 49.2620957, lng: 20.9660526 },
    { lat: 49.2591819, lng: 20.9601939 },
    { lat: 49.2585251, lng: 20.9598372 },
    { lat: 49.2580638, lng: 20.9591944 },
    { lat: 49.2559982, lng: 20.9584968 },
    { lat: 49.2554011, lng: 20.9570857 },
    { lat: 49.2557124, lng: 20.9551733 },
    { lat: 49.2555495, lng: 20.9546686 },
    { lat: 49.2550528, lng: 20.9539417 },
    { lat: 49.2543055, lng: 20.9531947 },
    { lat: 49.2548769, lng: 20.9519284 },
    { lat: 49.2560603, lng: 20.9510334 },
    { lat: 49.257464, lng: 20.9486335 },
    { lat: 49.2574784, lng: 20.9484933 },
    { lat: 49.2571614, lng: 20.9475174 },
    { lat: 49.2571509, lng: 20.9463559 },
    { lat: 49.257548, lng: 20.9450287 },
    { lat: 49.2574761, lng: 20.9424614 },
    { lat: 49.2581371, lng: 20.94024 },
    { lat: 49.2592315, lng: 20.9376144 },
    { lat: 49.2589213, lng: 20.9365496 },
    { lat: 49.2594057, lng: 20.9342632 },
    { lat: 49.2609829, lng: 20.9309355 },
    { lat: 49.2617438, lng: 20.9301631 },
    { lat: 49.2624979, lng: 20.9297553 },
    { lat: 49.2627302, lng: 20.9288295 },
    { lat: 49.2626084, lng: 20.9272722 },
    { lat: 49.2622889, lng: 20.9257548 },
    { lat: 49.2621884, lng: 20.9254765 },
    { lat: 49.2622183, lng: 20.9245878 },
    { lat: 49.2618262, lng: 20.9241009 },
    { lat: 49.2613436, lng: 20.923161 },
    { lat: 49.2604986, lng: 20.9199353 },
    { lat: 49.2599237, lng: 20.9190696 },
    { lat: 49.2604723, lng: 20.9168306 },
    { lat: 49.2604656, lng: 20.9166307 },
    { lat: 49.2606998, lng: 20.9163991 },
    { lat: 49.2613173, lng: 20.9165552 },
    { lat: 49.261522, lng: 20.9161118 },
    { lat: 49.2622174, lng: 20.9161094 },
    { lat: 49.2625086, lng: 20.9157969 },
    { lat: 49.2628825, lng: 20.915905 },
    { lat: 49.2634414, lng: 20.9154559 },
    { lat: 49.2637257, lng: 20.9154788 },
    { lat: 49.2635519, lng: 20.9148599 },
    { lat: 49.2640867, lng: 20.9149295 },
    { lat: 49.2644564, lng: 20.9147499 },
    { lat: 49.265454, lng: 20.9146247 },
    { lat: 49.2654016, lng: 20.9144051 },
    { lat: 49.2653563, lng: 20.9137545 },
    { lat: 49.265167, lng: 20.9128785 },
    { lat: 49.2651843, lng: 20.9123161 },
    { lat: 49.2650625, lng: 20.9120117 },
    { lat: 49.2648955, lng: 20.9108212 },
    { lat: 49.2658062, lng: 20.9101906 },
    { lat: 49.2656674, lng: 20.9097716 },
    { lat: 49.2653893, lng: 20.9089324 },
    { lat: 49.2639158, lng: 20.909024 },
    { lat: 49.2628588, lng: 20.9096224 },
    { lat: 49.261726, lng: 20.9097399 },
    { lat: 49.2604118, lng: 20.9079701 },
    { lat: 49.2597933, lng: 20.9073775 },
    { lat: 49.2598265, lng: 20.9065951 },
    { lat: 49.2575555, lng: 20.9041168 },
    { lat: 49.2570715, lng: 20.9036784 },
    { lat: 49.2571272, lng: 20.9023892 },
    { lat: 49.2565211, lng: 20.9025008 },
    { lat: 49.2567912, lng: 20.9012251 },
    { lat: 49.2561902, lng: 20.9001214 },
    { lat: 49.2556375, lng: 20.9011559 },
    { lat: 49.2550802, lng: 20.9007824 },
    { lat: 49.255538, lng: 20.8983995 },
    { lat: 49.2543774, lng: 20.8978914 },
    { lat: 49.2544049, lng: 20.8965032 },
    { lat: 49.2528626, lng: 20.896419 },
    { lat: 49.2527898, lng: 20.8956292 },
    { lat: 49.2517726, lng: 20.8958687 },
    { lat: 49.2508675, lng: 20.8953911 },
    { lat: 49.2497696, lng: 20.895509 },
    { lat: 49.2493203, lng: 20.8950313 },
    { lat: 49.2483835, lng: 20.8945533 },
    { lat: 49.2479098, lng: 20.894726 },
    { lat: 49.2470238, lng: 20.893311 },
    { lat: 49.2464457, lng: 20.8926234 },
    { lat: 49.2468359, lng: 20.8915069 },
    { lat: 49.2467836, lng: 20.8906267 },
    { lat: 49.2471099, lng: 20.8895178 },
    { lat: 49.2467466, lng: 20.8880892 },
    { lat: 49.2474645, lng: 20.8858503 },
    { lat: 49.2474112, lng: 20.8843419 },
    { lat: 49.2465597, lng: 20.8827868 },
    { lat: 49.2431332, lng: 20.8885741 },
    { lat: 49.242996, lng: 20.8887088 },
    { lat: 49.2412042, lng: 20.8918112 },
    { lat: 49.240801, lng: 20.8911009 },
    { lat: 49.2406364, lng: 20.8910253 },
    { lat: 49.2397675, lng: 20.8882528 },
    { lat: 49.2381063, lng: 20.8851141 },
    { lat: 49.2366856, lng: 20.8834744 },
    { lat: 49.2349619, lng: 20.8830916 },
    { lat: 49.2330867, lng: 20.8837838 },
    { lat: 49.2313093, lng: 20.8845402 },
    { lat: 49.2297419, lng: 20.8846521 },
    { lat: 49.2280809, lng: 20.885296 },
    { lat: 49.226655, lng: 20.886271 },
    { lat: 49.226613, lng: 20.886299 },
    { lat: 49.226415, lng: 20.886433 },
    { lat: 49.226214, lng: 20.88657 },
    { lat: 49.226028, lng: 20.886695 },
    { lat: 49.225843, lng: 20.88682 },
    { lat: 49.225788, lng: 20.886858 },
    { lat: 49.225618, lng: 20.886974 },
    { lat: 49.225574, lng: 20.886812 },
    { lat: 49.225484, lng: 20.886843 },
    { lat: 49.225212, lng: 20.886934 },
    { lat: 49.225035, lng: 20.886969 },
    { lat: 49.224663, lng: 20.887042 },
    { lat: 49.224356, lng: 20.887102 },
    { lat: 49.224181, lng: 20.887296 },
    { lat: 49.223945, lng: 20.887372 },
    { lat: 49.22371, lng: 20.887447 },
    { lat: 49.223476, lng: 20.887522 },
    { lat: 49.223239, lng: 20.887599 },
    { lat: 49.222902, lng: 20.887707 },
    { lat: 49.222561, lng: 20.887817 },
    { lat: 49.222187, lng: 20.887891 },
    { lat: 49.222013, lng: 20.887926 },
    { lat: 49.221811, lng: 20.887931 },
    { lat: 49.221627, lng: 20.887906 },
    { lat: 49.221376, lng: 20.887873 },
    { lat: 49.221371, lng: 20.887873 },
    { lat: 49.22128, lng: 20.887828 },
    { lat: 49.221237, lng: 20.887771 },
    { lat: 49.221162, lng: 20.887742 },
    { lat: 49.221159, lng: 20.887586 },
    { lat: 49.220922, lng: 20.887578 },
    { lat: 49.220552, lng: 20.887545 },
    { lat: 49.220286, lng: 20.887708 },
    { lat: 49.220017, lng: 20.887101 },
    { lat: 49.219844, lng: 20.886316 },
    { lat: 49.21938, lng: 20.886497 },
    { lat: 49.218578, lng: 20.88753 },
    { lat: 49.218547, lng: 20.887484 },
    { lat: 49.218019, lng: 20.887948 },
    { lat: 49.21777, lng: 20.888257 },
    { lat: 49.217766, lng: 20.88797 },
    { lat: 49.217766, lng: 20.88789 },
    { lat: 49.217765, lng: 20.887853 },
    { lat: 49.217772, lng: 20.887804 },
    { lat: 49.218038, lng: 20.885876 },
    { lat: 49.217975, lng: 20.885866 },
    { lat: 49.217872, lng: 20.885849 },
    { lat: 49.21747, lng: 20.886009 },
    { lat: 49.2173, lng: 20.886077 },
    { lat: 49.217161, lng: 20.885552 },
    { lat: 49.2169256, lng: 20.8876387 },
    { lat: 49.2170482, lng: 20.8890716 },
    { lat: 49.2179875, lng: 20.8884891 },
    { lat: 49.2183459, lng: 20.8889408 },
    { lat: 49.2180228, lng: 20.8896672 },
    { lat: 49.2172696, lng: 20.8906009 },
    { lat: 49.2164988, lng: 20.8921725 },
    { lat: 49.2158824, lng: 20.8938554 },
    { lat: 49.2159006, lng: 20.8942906 },
    { lat: 49.2156083, lng: 20.8951701 },
    { lat: 49.2155138, lng: 20.8957024 },
    { lat: 49.2152294, lng: 20.8963471 },
    { lat: 49.2148633, lng: 20.8968079 },
    { lat: 49.2146649, lng: 20.8972349 },
    { lat: 49.214405, lng: 20.897233 },
    { lat: 49.2143413, lng: 20.89762 },
    { lat: 49.2149408, lng: 20.8977108 },
    { lat: 49.215895, lng: 20.8985007 },
    { lat: 49.2160257, lng: 20.8987393 },
    { lat: 49.2161604, lng: 20.8991679 },
    { lat: 49.2162794, lng: 20.8988565 },
    { lat: 49.2165689, lng: 20.8990657 },
    { lat: 49.2165478, lng: 20.8991732 },
    { lat: 49.2166391, lng: 20.8992438 },
    { lat: 49.2166826, lng: 20.8992681 },
    { lat: 49.2167132, lng: 20.8991685 },
    { lat: 49.2168386, lng: 20.899222 },
    { lat: 49.2167229, lng: 20.8997945 },
    { lat: 49.2165554, lng: 20.8998699 },
    { lat: 49.2165618, lng: 20.9000599 },
    { lat: 49.2164995, lng: 20.900113 },
    { lat: 49.2165654, lng: 20.9003332 },
    { lat: 49.2166619, lng: 20.9004275 },
    { lat: 49.217526, lng: 20.9002784 },
    { lat: 49.2180071, lng: 20.9002645 },
    { lat: 49.2180632, lng: 20.9004243 },
    { lat: 49.2182857, lng: 20.9006182 },
    { lat: 49.218341, lng: 20.9005854 },
    { lat: 49.2185372, lng: 20.9009002 },
    { lat: 49.2186994, lng: 20.9009069 },
    { lat: 49.2187862, lng: 20.901074 },
    { lat: 49.2192139, lng: 20.9011139 },
    { lat: 49.2192761, lng: 20.9004163 },
    { lat: 49.2195227, lng: 20.9003509 },
    { lat: 49.2199183, lng: 20.9000034 },
    { lat: 49.2204277, lng: 20.9002566 },
    { lat: 49.2206728, lng: 20.9008192 },
    { lat: 49.2208684, lng: 20.9004075 },
    { lat: 49.2213579, lng: 20.9006434 },
    { lat: 49.2216142, lng: 20.9002284 },
    { lat: 49.2218295, lng: 20.9005064 },
    { lat: 49.2219771, lng: 20.9010817 },
    { lat: 49.2222449, lng: 20.9010784 },
    { lat: 49.2224224, lng: 20.9007022 },
    { lat: 49.2229293, lng: 20.9003478 },
    { lat: 49.2235294, lng: 20.9006241 },
    { lat: 49.2239087, lng: 20.9005205 },
    { lat: 49.2239975, lng: 20.900349 },
    { lat: 49.2238958, lng: 20.9001395 },
    { lat: 49.2242175, lng: 20.8999994 },
    { lat: 49.2244933, lng: 20.9001697 },
    { lat: 49.2244202, lng: 20.9003382 },
    { lat: 49.2244787, lng: 20.9006699 },
    { lat: 49.2246172, lng: 20.9010422 },
    { lat: 49.2248182, lng: 20.9013602 },
    { lat: 49.2249794, lng: 20.9011391 },
    { lat: 49.2250041, lng: 20.9008625 },
    { lat: 49.225033, lng: 20.9004897 },
    { lat: 49.2251951, lng: 20.9001896 },
    { lat: 49.2252509, lng: 20.8999771 },
    { lat: 49.2253612, lng: 20.9000102 },
    { lat: 49.2252844, lng: 20.9001995 },
    { lat: 49.2253284, lng: 20.900401 },
    { lat: 49.2254192, lng: 20.9004306 },
    { lat: 49.2253212, lng: 20.9006932 },
    { lat: 49.2253994, lng: 20.9008707 },
    { lat: 49.2255226, lng: 20.9007559 },
    { lat: 49.2259838, lng: 20.9008141 },
    { lat: 49.2261255, lng: 20.9006449 },
    { lat: 49.2261583, lng: 20.9004137 },
    { lat: 49.2260117, lng: 20.9003409 },
    { lat: 49.2260335, lng: 20.9000472 },
    { lat: 49.2265088, lng: 20.8999959 },
    { lat: 49.2263945, lng: 20.9002436 },
    { lat: 49.2264789, lng: 20.9006766 },
    { lat: 49.226674, lng: 20.9010916 },
    { lat: 49.2269793, lng: 20.9013698 },
    { lat: 49.2270436, lng: 20.9015765 },
    { lat: 49.2272663, lng: 20.9015724 },
    { lat: 49.2272348, lng: 20.9012036 },
    { lat: 49.2273083, lng: 20.9007441 },
    { lat: 49.2272887, lng: 20.9001972 },
    { lat: 49.2271081, lng: 20.900009 },
    { lat: 49.2270607, lng: 20.899541 },
    { lat: 49.2277622, lng: 20.9001191 },
    { lat: 49.2278413, lng: 20.900057 },
    { lat: 49.2278835, lng: 20.9002464 },
    { lat: 49.2277015, lng: 20.9007533 },
    { lat: 49.2277582, lng: 20.900904 },
    { lat: 49.2278741, lng: 20.9008366 },
    { lat: 49.2282774, lng: 20.9008788 },
    { lat: 49.2285301, lng: 20.9008334 },
    { lat: 49.2287965, lng: 20.90072 },
    { lat: 49.2288202, lng: 20.9004553 },
    { lat: 49.2289461, lng: 20.9005534 },
    { lat: 49.2290161, lng: 20.9006916 },
    { lat: 49.2289966, lng: 20.9009478 },
    { lat: 49.2293371, lng: 20.9012322 },
    { lat: 49.2300282, lng: 20.9021748 },
    { lat: 49.2301499, lng: 20.9024501 },
    { lat: 49.2300344, lng: 20.9031254 },
    { lat: 49.2300817, lng: 20.9033594 },
    { lat: 49.2302197, lng: 20.90365 },
    { lat: 49.2302004, lng: 20.9039806 },
    { lat: 49.2300595, lng: 20.9040136 },
    { lat: 49.2300431, lng: 20.9043934 },
    { lat: 49.230253, lng: 20.9045765 },
    { lat: 49.2302981, lng: 20.9050847 },
    { lat: 49.2302505, lng: 20.9053045 },
    { lat: 49.2300798, lng: 20.9053808 },
    { lat: 49.2301042, lng: 20.9057066 },
    { lat: 49.2300034, lng: 20.9059576 },
    { lat: 49.2300396, lng: 20.906227 },
    { lat: 49.2299912, lng: 20.9065362 },
    { lat: 49.230068, lng: 20.9068078 },
    { lat: 49.2301455, lng: 20.9069029 },
    { lat: 49.2301477, lng: 20.9070735 },
    { lat: 49.2299947, lng: 20.9072367 },
    { lat: 49.2300964, lng: 20.9079918 },
    { lat: 49.2302987, lng: 20.9085165 },
    { lat: 49.2302965, lng: 20.9087533 },
    { lat: 49.2301884, lng: 20.9088564 },
    { lat: 49.2301716, lng: 20.9091549 },
    { lat: 49.2302899, lng: 20.9093278 },
    { lat: 49.230179, lng: 20.9096152 },
    { lat: 49.2298543, lng: 20.9099957 },
    { lat: 49.2297886, lng: 20.9105558 },
    { lat: 49.2293932, lng: 20.9109788 },
    { lat: 49.2294048, lng: 20.9114598 },
    { lat: 49.2293115, lng: 20.9117634 },
    { lat: 49.2290488, lng: 20.9119539 },
    { lat: 49.2289293, lng: 20.9121458 },
    { lat: 49.2290076, lng: 20.9127735 },
    { lat: 49.2287522, lng: 20.9135479 },
    { lat: 49.2292102, lng: 20.9147228 },
    { lat: 49.2295587, lng: 20.9158554 },
    { lat: 49.2295959, lng: 20.9162242 },
    { lat: 49.2294227, lng: 20.9166302 },
    { lat: 49.2291818, lng: 20.9169666 },
    { lat: 49.2288425, lng: 20.9177911 },
    { lat: 49.2287789, lng: 20.9187795 },
    { lat: 49.2300702, lng: 20.9206795 },
    { lat: 49.230879, lng: 20.9225783 },
    { lat: 49.2327442, lng: 20.9259586 },
    { lat: 49.2329514, lng: 20.9263011 },
    { lat: 49.2341464, lng: 20.9274383 },
    { lat: 49.235131, lng: 20.9293896 },
    { lat: 49.235194, lng: 20.9315812 },
    { lat: 49.2347568, lng: 20.9345396 },
    { lat: 49.2336501, lng: 20.9364507 },
    { lat: 49.2346015, lng: 20.9372397 },
    { lat: 49.235135, lng: 20.9385207 },
    { lat: 49.2352895, lng: 20.9397206 },
    { lat: 49.235266, lng: 20.9410769 },
    { lat: 49.2351929, lng: 20.9416498 },
    { lat: 49.235308, lng: 20.9424382 },
    { lat: 49.2354912, lng: 20.943172 },
    { lat: 49.2365851, lng: 20.9444255 },
    { lat: 49.2366751, lng: 20.9451039 },
    { lat: 49.2369354, lng: 20.9454154 },
    { lat: 49.2369175, lng: 20.945997 },
    { lat: 49.237072, lng: 20.9470536 },
    { lat: 49.2370726, lng: 20.9477836 },
    { lat: 49.2371226, lng: 20.9482076 },
    { lat: 49.2372399, lng: 20.9487877 },
    { lat: 49.2375911, lng: 20.9499116 },
    { lat: 49.2377334, lng: 20.9508089 },
    { lat: 49.2377346, lng: 20.9525361 },
    { lat: 49.2375258, lng: 20.9530673 },
    { lat: 49.2380906, lng: 20.9525489 },
    { lat: 49.2392766, lng: 20.9523429 },
    { lat: 49.2394003, lng: 20.9525095 },
    { lat: 49.2394089, lng: 20.9532731 },
    { lat: 49.2391261, lng: 20.9541453 },
    { lat: 49.2390034, lng: 20.9550336 },
    { lat: 49.239017, lng: 20.9562186 },
    { lat: 49.2391805, lng: 20.9570462 },
    { lat: 49.23913, lng: 20.9572223 },
    { lat: 49.2393268, lng: 20.9575197 },
    { lat: 49.2398855, lng: 20.9579098 },
    { lat: 49.2401203, lng: 20.9583419 },
    { lat: 49.240269, lng: 20.9588509 },
    { lat: 49.2408004, lng: 20.9593018 },
    { lat: 49.241265, lng: 20.9609292 },
    { lat: 49.2415753, lng: 20.9622444 },
    { lat: 49.241298, lng: 20.9622316 },
    { lat: 49.2412175, lng: 20.9625198 },
    { lat: 49.2414178, lng: 20.9630947 },
    { lat: 49.2414826, lng: 20.9634902 },
    { lat: 49.2417211, lng: 20.9637279 },
    { lat: 49.2419012, lng: 20.9642687 },
    { lat: 49.2421222, lng: 20.9645546 },
    { lat: 49.2422781, lng: 20.9645782 },
    { lat: 49.2424712, lng: 20.9651025 },
    { lat: 49.2428914, lng: 20.966785 },
    { lat: 49.2428928, lng: 20.9674404 },
    { lat: 49.2425493, lng: 20.9687592 },
    { lat: 49.2426689, lng: 20.969451 },
    { lat: 49.2426084, lng: 20.9703125 },
    { lat: 49.2426848, lng: 20.9713973 },
    { lat: 49.2425665, lng: 20.9720428 },
    { lat: 49.2424681, lng: 20.9730856 },
    { lat: 49.2425045, lng: 20.9735373 },
    { lat: 49.2427866, lng: 20.9741459 },
    { lat: 49.243699, lng: 20.9751895 },
    { lat: 49.2432452, lng: 20.9787009 },
    { lat: 49.2423286, lng: 20.9781378 },
    { lat: 49.2415526, lng: 20.9781182 },
    { lat: 49.2415527, lng: 20.9781924 },
    { lat: 49.242292, lng: 20.9789085 },
    { lat: 49.2425627, lng: 20.9794374 },
    { lat: 49.2428942, lng: 20.9796936 },
    { lat: 49.2432582, lng: 20.9804623 },
    { lat: 49.2435561, lng: 20.9812755 },
    { lat: 49.2441777, lng: 20.9823764 },
    { lat: 49.2441969, lng: 20.98276 },
    { lat: 49.2444829, lng: 20.9827928 },
    { lat: 49.2447584, lng: 20.9830969 },
    { lat: 49.2450435, lng: 20.983755 },
    { lat: 49.2451991, lng: 20.9842495 },
    { lat: 49.2456318, lng: 20.986312 },
    { lat: 49.2457516, lng: 20.9869811 },
    { lat: 49.2458612, lng: 20.9881898 },
    { lat: 49.2459703, lng: 20.9885334 },
    { lat: 49.2464076, lng: 20.9908834 },
    { lat: 49.2464338, lng: 20.9916447 },
    { lat: 49.246294, lng: 20.9930487 },
    { lat: 49.2466847, lng: 20.9936725 },
    { lat: 49.2468973, lng: 20.9935676 },
    { lat: 49.2471471, lng: 20.9932164 },
    { lat: 49.2476347, lng: 20.9932644 },
    { lat: 49.2494454, lng: 20.9989626 },
    { lat: 49.2495435, lng: 21.000511 },
    { lat: 49.250283, lng: 21.003295 },
    { lat: 49.250289, lng: 21.003319 },
    { lat: 49.250304, lng: 21.00329 },
    { lat: 49.25049, lng: 21.00329 },
    { lat: 49.250901, lng: 21.003513 },
    { lat: 49.251018, lng: 21.003674 },
    { lat: 49.251366, lng: 21.003887 },
    { lat: 49.251795, lng: 21.004025 },
    { lat: 49.252192, lng: 21.004096 },
    { lat: 49.252235, lng: 21.0041 },
    { lat: 49.252507, lng: 21.004061 },
    { lat: 49.252754, lng: 21.004108 },
    { lat: 49.253065, lng: 21.004079 },
    { lat: 49.253345, lng: 21.004134 },
    { lat: 49.253772, lng: 21.003974 },
    { lat: 49.254006, lng: 21.003952 },
    { lat: 49.254182, lng: 21.004172 },
    { lat: 49.254414, lng: 21.004264 },
    { lat: 49.254857, lng: 21.004311 },
    { lat: 49.255102, lng: 21.004166 },
    { lat: 49.255262, lng: 21.004238 },
    { lat: 49.25526, lng: 21.004265 },
    { lat: 49.255329, lng: 21.004291 },
    { lat: 49.255431, lng: 21.004095 },
    { lat: 49.25559, lng: 21.003818 },
    { lat: 49.255971, lng: 21.003282 },
    { lat: 49.256297, lng: 21.002369 },
    { lat: 49.256338, lng: 21.002228 },
    { lat: 49.256569, lng: 21.001425 },
    { lat: 49.256668, lng: 21.000982 },
    { lat: 49.256772, lng: 21.000719 },
    { lat: 49.256912, lng: 21.000413 },
    { lat: 49.257018, lng: 21.000141 },
    { lat: 49.25716, lng: 20.999653 },
    { lat: 49.257546, lng: 20.999178 },
    { lat: 49.258182, lng: 20.998402 },
    { lat: 49.258367, lng: 20.998229 },
    { lat: 49.258961, lng: 20.997875 },
    { lat: 49.259028, lng: 20.997864 },
    { lat: 49.259358, lng: 20.997831 },
    { lat: 49.259598, lng: 20.997612 },
    { lat: 49.26004, lng: 20.99734 },
    { lat: 49.260105, lng: 20.997213 },
    { lat: 49.260353, lng: 20.997189 },
  ],
  Lacková: [
    { lat: 49.3371165, lng: 20.5679762 },
    { lat: 49.3363677, lng: 20.5674748 },
    { lat: 49.3352014, lng: 20.567537 },
    { lat: 49.3336126, lng: 20.5678955 },
    { lat: 49.3331758, lng: 20.5677371 },
    { lat: 49.3331449, lng: 20.5674189 },
    { lat: 49.3330051, lng: 20.5673824 },
    { lat: 49.332737, lng: 20.5669707 },
    { lat: 49.3323624, lng: 20.5666976 },
    { lat: 49.331432, lng: 20.5672729 },
    { lat: 49.3297356, lng: 20.5677687 },
    { lat: 49.327985, lng: 20.56699 },
    { lat: 49.3271221, lng: 20.5672205 },
    { lat: 49.3268649, lng: 20.5658461 },
    { lat: 49.3258459, lng: 20.5651611 },
    { lat: 49.3250307, lng: 20.5660173 },
    { lat: 49.32473, lng: 20.5665995 },
    { lat: 49.3240275, lng: 20.5667564 },
    { lat: 49.323671, lng: 20.570348 },
    { lat: 49.3229486, lng: 20.5735173 },
    { lat: 49.3223331, lng: 20.5756883 },
    { lat: 49.3200531, lng: 20.5771651 },
    { lat: 49.3199395, lng: 20.5771675 },
    { lat: 49.3196059, lng: 20.577958 },
    { lat: 49.3193169, lng: 20.5780934 },
    { lat: 49.3192613, lng: 20.5782965 },
    { lat: 49.319129, lng: 20.5784077 },
    { lat: 49.3183533, lng: 20.5788067 },
    { lat: 49.3182091, lng: 20.578955 },
    { lat: 49.3176594, lng: 20.5797512 },
    { lat: 49.3176137, lng: 20.5798897 },
    { lat: 49.3171229, lng: 20.5805405 },
    { lat: 49.3169321, lng: 20.5811123 },
    { lat: 49.3165875, lng: 20.5815111 },
    { lat: 49.3165759, lng: 20.5816268 },
    { lat: 49.316369, lng: 20.5819103 },
    { lat: 49.3163755, lng: 20.5819985 },
    { lat: 49.3159173, lng: 20.5824333 },
    { lat: 49.3140419, lng: 20.5845105 },
    { lat: 49.3128158, lng: 20.5853763 },
    { lat: 49.3124253, lng: 20.5859639 },
    { lat: 49.3120887, lng: 20.5868623 },
    { lat: 49.3119757, lng: 20.5873168 },
    { lat: 49.3116665, lng: 20.5857765 },
    { lat: 49.3111335, lng: 20.5855121 },
    { lat: 49.3105587, lng: 20.5849043 },
    { lat: 49.3095738, lng: 20.5854944 },
    { lat: 49.3081989, lng: 20.5857633 },
    { lat: 49.3078798, lng: 20.5856533 },
    { lat: 49.3077523, lng: 20.5853697 },
    { lat: 49.3069655, lng: 20.5853658 },
    { lat: 49.3061049, lng: 20.5854749 },
    { lat: 49.3057918, lng: 20.5856609 },
    { lat: 49.3052508, lng: 20.5858597 },
    { lat: 49.3042799, lng: 20.5858137 },
    { lat: 49.3035427, lng: 20.5859098 },
    { lat: 49.3030029, lng: 20.5861092 },
    { lat: 49.3023974, lng: 20.5867662 },
    { lat: 49.3014157, lng: 20.5865414 },
    { lat: 49.3012152, lng: 20.5866331 },
    { lat: 49.3006104, lng: 20.5866577 },
    { lat: 49.2985264, lng: 20.5856207 },
    { lat: 49.2984289, lng: 20.5858847 },
    { lat: 49.2978285, lng: 20.5861178 },
    { lat: 49.2974724, lng: 20.5875045 },
    { lat: 49.2972028, lng: 20.5883905 },
    { lat: 49.2969864, lng: 20.5889156 },
    { lat: 49.2964208, lng: 20.5893646 },
    { lat: 49.2959881, lng: 20.5900335 },
    { lat: 49.2950942, lng: 20.5917092 },
    { lat: 49.2949185, lng: 20.5914568 },
    { lat: 49.2943413, lng: 20.5911559 },
    { lat: 49.2939159, lng: 20.5908496 },
    { lat: 49.2937267, lng: 20.590347 },
    { lat: 49.293773, lng: 20.5925565 },
    { lat: 49.2935055, lng: 20.5940596 },
    { lat: 49.2938162, lng: 20.5949598 },
    { lat: 49.2943835, lng: 20.5969806 },
    { lat: 49.2943179, lng: 20.5987629 },
    { lat: 49.2958748, lng: 20.5992179 },
    { lat: 49.296949, lng: 20.5997835 },
    { lat: 49.2974707, lng: 20.6002638 },
    { lat: 49.2985866, lng: 20.6015235 },
    { lat: 49.2989774, lng: 20.6021213 },
    { lat: 49.2995212, lng: 20.6034933 },
    { lat: 49.3001089, lng: 20.6058632 },
    { lat: 49.300392, lng: 20.6058512 },
    { lat: 49.300698, lng: 20.6055956 },
    { lat: 49.3009076, lng: 20.6045434 },
    { lat: 49.3012503, lng: 20.6041155 },
    { lat: 49.3024849, lng: 20.6041097 },
    { lat: 49.3035123, lng: 20.6038844 },
    { lat: 49.3038465, lng: 20.6037274 },
    { lat: 49.3045429, lng: 20.6030568 },
    { lat: 49.3050035, lng: 20.6024403 },
    { lat: 49.3056896, lng: 20.6020526 },
    { lat: 49.3059079, lng: 20.60233 },
    { lat: 49.3065415, lng: 20.6017036 },
    { lat: 49.3072, lng: 20.6028889 },
    { lat: 49.3075342, lng: 20.6031301 },
    { lat: 49.3092142, lng: 20.6033987 },
    { lat: 49.3104927, lng: 20.6032016 },
    { lat: 49.3105415, lng: 20.6034313 },
    { lat: 49.3127435, lng: 20.6029364 },
    { lat: 49.3138037, lng: 20.6024373 },
    { lat: 49.3142458, lng: 20.6024562 },
    { lat: 49.3149329, lng: 20.6026604 },
    { lat: 49.315195, lng: 20.6032405 },
    { lat: 49.3171161, lng: 20.6032618 },
    { lat: 49.3177846, lng: 20.6031338 },
    { lat: 49.3184448, lng: 20.6028695 },
    { lat: 49.3187377, lng: 20.60238 },
    { lat: 49.3189155, lng: 20.6021889 },
    { lat: 49.3190547, lng: 20.6017459 },
    { lat: 49.319366, lng: 20.6012592 },
    { lat: 49.3196901, lng: 20.6010694 },
    { lat: 49.3199274, lng: 20.6007505 },
    { lat: 49.3203197, lng: 20.6004904 },
    { lat: 49.3209385, lng: 20.5994815 },
    { lat: 49.3209298, lng: 20.599397 },
    { lat: 49.3210828, lng: 20.5994263 },
    { lat: 49.3210777, lng: 20.5991954 },
    { lat: 49.3211925, lng: 20.5991594 },
    { lat: 49.3212513, lng: 20.5990451 },
    { lat: 49.3214327, lng: 20.5980204 },
    { lat: 49.3216423, lng: 20.597841 },
    { lat: 49.3217559, lng: 20.5978393 },
    { lat: 49.3218061, lng: 20.5976348 },
    { lat: 49.3221147, lng: 20.5972973 },
    { lat: 49.3221385, lng: 20.5970889 },
    { lat: 49.3223072, lng: 20.5965863 },
    { lat: 49.3226658, lng: 20.5963528 },
    { lat: 49.3227687, lng: 20.5959002 },
    { lat: 49.3227254, lng: 20.5957 },
    { lat: 49.3228491, lng: 20.5951667 },
    { lat: 49.3230706, lng: 20.5950046 },
    { lat: 49.3232748, lng: 20.5947347 },
    { lat: 49.3233873, lng: 20.5947941 },
    { lat: 49.3235282, lng: 20.5946119 },
    { lat: 49.3236644, lng: 20.5945495 },
    { lat: 49.3240235, lng: 20.594182 },
    { lat: 49.3242061, lng: 20.593876 },
    { lat: 49.3246827, lng: 20.5930442 },
    { lat: 49.325616, lng: 20.5908728 },
    { lat: 49.3261502, lng: 20.5898025 },
    { lat: 49.3265239, lng: 20.5888405 },
    { lat: 49.3273408, lng: 20.5874925 },
    { lat: 49.3291729, lng: 20.5858657 },
    { lat: 49.3294879, lng: 20.5855173 },
    { lat: 49.3296711, lng: 20.5851708 },
    { lat: 49.3299245, lng: 20.5843668 },
    { lat: 49.3303101, lng: 20.5836679 },
    { lat: 49.3311266, lng: 20.5829351 },
    { lat: 49.3320304, lng: 20.5824054 },
    { lat: 49.3330161, lng: 20.5821109 },
    { lat: 49.333548, lng: 20.5815708 },
    { lat: 49.3346017, lng: 20.58024 },
    { lat: 49.3362499, lng: 20.5778561 },
    { lat: 49.3364212, lng: 20.5769622 },
    { lat: 49.337196, lng: 20.5762921 },
    { lat: 49.3373827, lng: 20.5742442 },
    { lat: 49.3369676, lng: 20.5727332 },
    { lat: 49.3370815, lng: 20.5715005 },
    { lat: 49.337335, lng: 20.5708559 },
    { lat: 49.3372661, lng: 20.5700434 },
    { lat: 49.3371165, lng: 20.5679762 },
  ],
  StaráĽubovňa: [
    { lat: 49.2811623, lng: 20.7124203 },
    { lat: 49.2813249, lng: 20.712563 },
    { lat: 49.2822265, lng: 20.7130419 },
    { lat: 49.2828621, lng: 20.7132554 },
    { lat: 49.2834016, lng: 20.7137147 },
    { lat: 49.2837462, lng: 20.7141688 },
    { lat: 49.2855805, lng: 20.7139671 },
    { lat: 49.2856329, lng: 20.7141103 },
    { lat: 49.2857162, lng: 20.714111 },
    { lat: 49.2858027, lng: 20.7143299 },
    { lat: 49.2858846, lng: 20.7143257 },
    { lat: 49.2859409, lng: 20.7144848 },
    { lat: 49.286046, lng: 20.7145879 },
    { lat: 49.2860938, lng: 20.7147686 },
    { lat: 49.2863772, lng: 20.7150464 },
    { lat: 49.2865769, lng: 20.7150905 },
    { lat: 49.2867367, lng: 20.7152227 },
    { lat: 49.2868675, lng: 20.7156079 },
    { lat: 49.2871326, lng: 20.7156604 },
    { lat: 49.2871007, lng: 20.7158393 },
    { lat: 49.2875249, lng: 20.7163796 },
    { lat: 49.2878541, lng: 20.7166817 },
    { lat: 49.2879437, lng: 20.7166383 },
    { lat: 49.2880497, lng: 20.7167435 },
    { lat: 49.2881608, lng: 20.7170517 },
    { lat: 49.2882392, lng: 20.7171019 },
    { lat: 49.2882747, lng: 20.7173802 },
    { lat: 49.2883821, lng: 20.7175838 },
    { lat: 49.2883849, lng: 20.7178255 },
    { lat: 49.288499, lng: 20.7181258 },
    { lat: 49.2886547, lng: 20.718293 },
    { lat: 49.2887434, lng: 20.7182861 },
    { lat: 49.2886862, lng: 20.7188082 },
    { lat: 49.2888371, lng: 20.7192224 },
    { lat: 49.2889353, lng: 20.7191959 },
    { lat: 49.2889704, lng: 20.7193964 },
    { lat: 49.2891846, lng: 20.71954 },
    { lat: 49.2892816, lng: 20.7195061 },
    { lat: 49.289329, lng: 20.7196086 },
    { lat: 49.2892678, lng: 20.7197921 },
    { lat: 49.2893766, lng: 20.7198423 },
    { lat: 49.2895341, lng: 20.7195185 },
    { lat: 49.2897592, lng: 20.7197416 },
    { lat: 49.2898633, lng: 20.7197587 },
    { lat: 49.2899305, lng: 20.7199443 },
    { lat: 49.2900243, lng: 20.7199575 },
    { lat: 49.2900278, lng: 20.7198281 },
    { lat: 49.2901055, lng: 20.7198307 },
    { lat: 49.2901606, lng: 20.7198944 },
    { lat: 49.2901587, lng: 20.7200965 },
    { lat: 49.2902186, lng: 20.720164 },
    { lat: 49.2904513, lng: 20.7200598 },
    { lat: 49.2906017, lng: 20.7204322 },
    { lat: 49.2906711, lng: 20.7203883 },
    { lat: 49.2906953, lng: 20.7202435 },
    { lat: 49.2908578, lng: 20.7202576 },
    { lat: 49.2909811, lng: 20.7204963 },
    { lat: 49.2910037, lng: 20.7207255 },
    { lat: 49.291083, lng: 20.720807 },
    { lat: 49.2912881, lng: 20.7208107 },
    { lat: 49.291512, lng: 20.7207112 },
    { lat: 49.2916469, lng: 20.7208383 },
    { lat: 49.2917597, lng: 20.7206873 },
    { lat: 49.2917999, lng: 20.7207352 },
    { lat: 49.2917737, lng: 20.7208574 },
    { lat: 49.2919654, lng: 20.721251 },
    { lat: 49.2922844, lng: 20.7215551 },
    { lat: 49.2922106, lng: 20.7221742 },
    { lat: 49.2923041, lng: 20.7222112 },
    { lat: 49.2923263, lng: 20.7223003 },
    { lat: 49.2922616, lng: 20.7225728 },
    { lat: 49.2921867, lng: 20.7226175 },
    { lat: 49.2921338, lng: 20.7227766 },
    { lat: 49.2923824, lng: 20.7227749 },
    { lat: 49.2925055, lng: 20.7226599 },
    { lat: 49.2926274, lng: 20.7227956 },
    { lat: 49.293518, lng: 20.7213958 },
    { lat: 49.2936212, lng: 20.7214814 },
    { lat: 49.2937162, lng: 20.7217138 },
    { lat: 49.2942228, lng: 20.7210151 },
    { lat: 49.2945898, lng: 20.720633 },
    { lat: 49.2948722, lng: 20.7205669 },
    { lat: 49.2963312, lng: 20.7199011 },
    { lat: 49.2964989, lng: 20.7197994 },
    { lat: 49.2965895, lng: 20.7195586 },
    { lat: 49.2975573, lng: 20.7206497 },
    { lat: 49.2977579, lng: 20.7209737 },
    { lat: 49.2977745, lng: 20.721702 },
    { lat: 49.298838, lng: 20.7226299 },
    { lat: 49.2997673, lng: 20.7233211 },
    { lat: 49.3000905, lng: 20.7234508 },
    { lat: 49.3003095, lng: 20.7233744 },
    { lat: 49.300999, lng: 20.7244521 },
    { lat: 49.3010902, lng: 20.7243292 },
    { lat: 49.3011603, lng: 20.724397 },
    { lat: 49.3013191, lng: 20.7240161 },
    { lat: 49.3016196, lng: 20.7240181 },
    { lat: 49.3020259, lng: 20.7243707 },
    { lat: 49.3022166, lng: 20.7243464 },
    { lat: 49.3025879, lng: 20.7244447 },
    { lat: 49.3026585, lng: 20.7243047 },
    { lat: 49.3027265, lng: 20.7243533 },
    { lat: 49.3027069, lng: 20.7244328 },
    { lat: 49.3028122, lng: 20.7245161 },
    { lat: 49.3025705, lng: 20.7252916 },
    { lat: 49.3033693, lng: 20.725862 },
    { lat: 49.3045351, lng: 20.7268491 },
    { lat: 49.3045337, lng: 20.7269719 },
    { lat: 49.304619, lng: 20.7270619 },
    { lat: 49.3049002, lng: 20.7272116 },
    { lat: 49.3050108, lng: 20.7271932 },
    { lat: 49.3052232, lng: 20.7270196 },
    { lat: 49.3052453, lng: 20.7267498 },
    { lat: 49.3052897, lng: 20.7266711 },
    { lat: 49.3054053, lng: 20.7266321 },
    { lat: 49.305526, lng: 20.7267026 },
    { lat: 49.3060193, lng: 20.7271913 },
    { lat: 49.3063824, lng: 20.727343 },
    { lat: 49.3062447, lng: 20.7282261 },
    { lat: 49.3068682, lng: 20.7284841 },
    { lat: 49.3073198, lng: 20.7286177 },
    { lat: 49.3073583, lng: 20.7282426 },
    { lat: 49.3075348, lng: 20.7281037 },
    { lat: 49.3076944, lng: 20.7278122 },
    { lat: 49.3078707, lng: 20.727802 },
    { lat: 49.3084303, lng: 20.728351 },
    { lat: 49.3086337, lng: 20.7290118 },
    { lat: 49.3091402, lng: 20.7290752 },
    { lat: 49.3092525, lng: 20.7284654 },
    { lat: 49.3092814, lng: 20.7273228 },
    { lat: 49.3093388, lng: 20.7271045 },
    { lat: 49.3095344, lng: 20.7271599 },
    { lat: 49.3098299, lng: 20.7268218 },
    { lat: 49.3099223, lng: 20.7261812 },
    { lat: 49.3117641, lng: 20.725556 },
    { lat: 49.3133296, lng: 20.7238778 },
    { lat: 49.3139457, lng: 20.7235426 },
    { lat: 49.314819, lng: 20.7233807 },
    { lat: 49.3152627, lng: 20.7232205 },
    { lat: 49.3155526, lng: 20.7232736 },
    { lat: 49.3164417, lng: 20.72375 },
    { lat: 49.3168488, lng: 20.7238371 },
    { lat: 49.3172742, lng: 20.723546 },
    { lat: 49.3175226, lng: 20.723473 },
    { lat: 49.3176779, lng: 20.7236027 },
    { lat: 49.3179282, lng: 20.7239686 },
    { lat: 49.3181915, lng: 20.7248317 },
    { lat: 49.3183697, lng: 20.7251302 },
    { lat: 49.3186043, lng: 20.7253707 },
    { lat: 49.3191704, lng: 20.7253993 },
    { lat: 49.3192623, lng: 20.7255787 },
    { lat: 49.3192708, lng: 20.7259208 },
    { lat: 49.3194495, lng: 20.7262348 },
    { lat: 49.3195798, lng: 20.7264204 },
    { lat: 49.3197821, lng: 20.7264106 },
    { lat: 49.3199747, lng: 20.7266362 },
    { lat: 49.3194693, lng: 20.7282396 },
    { lat: 49.3191339, lng: 20.7282126 },
    { lat: 49.3190507, lng: 20.7283075 },
    { lat: 49.3190374, lng: 20.7285763 },
    { lat: 49.3185653, lng: 20.7288802 },
    { lat: 49.3186925, lng: 20.7301697 },
    { lat: 49.3185979, lng: 20.7301352 },
    { lat: 49.3184533, lng: 20.7299252 },
    { lat: 49.3181745, lng: 20.7297439 },
    { lat: 49.3181344, lng: 20.7294657 },
    { lat: 49.3180613, lng: 20.7295605 },
    { lat: 49.3180316, lng: 20.7297112 },
    { lat: 49.3179381, lng: 20.7297416 },
    { lat: 49.3179765, lng: 20.7301317 },
    { lat: 49.3180562, lng: 20.730412 },
    { lat: 49.3181126, lng: 20.7303533 },
    { lat: 49.3181329, lng: 20.7305143 },
    { lat: 49.3179615, lng: 20.7306658 },
    { lat: 49.3180542, lng: 20.7307733 },
    { lat: 49.3180815, lng: 20.7309132 },
    { lat: 49.3180102, lng: 20.7312532 },
    { lat: 49.3180942, lng: 20.7312752 },
    { lat: 49.3184952, lng: 20.7309937 },
    { lat: 49.3187505, lng: 20.7314335 },
    { lat: 49.3190025, lng: 20.7316874 },
    { lat: 49.3191929, lng: 20.7320204 },
    { lat: 49.3194464, lng: 20.7336355 },
    { lat: 49.3196301, lng: 20.7330287 },
    { lat: 49.3199733, lng: 20.7324158 },
    { lat: 49.3202947, lng: 20.7316324 },
    { lat: 49.3204797, lng: 20.7307055 },
    { lat: 49.3205335, lng: 20.7298965 },
    { lat: 49.3206974, lng: 20.7297553 },
    { lat: 49.3208791, lng: 20.7297368 },
    { lat: 49.321297, lng: 20.7295218 },
    { lat: 49.3216533, lng: 20.7291874 },
    { lat: 49.3219877, lng: 20.7295114 },
    { lat: 49.322222, lng: 20.7295795 },
    { lat: 49.3225522, lng: 20.7295257 },
    { lat: 49.3226944, lng: 20.7290291 },
    { lat: 49.3233441, lng: 20.7287767 },
    { lat: 49.3236548, lng: 20.7283622 },
    { lat: 49.3234643, lng: 20.7271311 },
    { lat: 49.3250554, lng: 20.7261555 },
    { lat: 49.3259384, lng: 20.7252383 },
    { lat: 49.3269794, lng: 20.7258508 },
    { lat: 49.3298701, lng: 20.7257316 },
    { lat: 49.3309101, lng: 20.7261767 },
    { lat: 49.3317454, lng: 20.7262486 },
    { lat: 49.3320576, lng: 20.7258761 },
    { lat: 49.3323523, lng: 20.7261901 },
    { lat: 49.3326745, lng: 20.7260677 },
    { lat: 49.3332522, lng: 20.7260459 },
    { lat: 49.3350597, lng: 20.7262025 },
    { lat: 49.3368806, lng: 20.7280313 },
    { lat: 49.338146, lng: 20.7284545 },
    { lat: 49.3387181, lng: 20.7283307 },
    { lat: 49.3394568, lng: 20.7277337 },
    { lat: 49.3404651, lng: 20.7273763 },
    { lat: 49.3416438, lng: 20.7270966 },
    { lat: 49.3427901, lng: 20.7269516 },
    { lat: 49.3443035, lng: 20.7235028 },
    { lat: 49.3447213, lng: 20.7215155 },
    { lat: 49.3447457, lng: 20.7198703 },
    { lat: 49.3443313, lng: 20.7175872 },
    { lat: 49.3453648, lng: 20.7158329 },
    { lat: 49.3455036, lng: 20.7157767 },
    { lat: 49.3460242, lng: 20.7149635 },
    { lat: 49.3465557, lng: 20.7150624 },
    { lat: 49.3470735, lng: 20.7139443 },
    { lat: 49.3470205, lng: 20.7136826 },
    { lat: 49.3485513, lng: 20.712758 },
    { lat: 49.3485371, lng: 20.7125758 },
    { lat: 49.3490906, lng: 20.7121186 },
    { lat: 49.3497201, lng: 20.7113945 },
    { lat: 49.3499968, lng: 20.710845 },
    { lat: 49.3503734, lng: 20.7096164 },
    { lat: 49.3503529, lng: 20.7092278 },
    { lat: 49.3502433, lng: 20.7085722 },
    { lat: 49.3504801, lng: 20.708262 },
    { lat: 49.3507282, lng: 20.7076058 },
    { lat: 49.3508166, lng: 20.7075314 },
    { lat: 49.3508169, lng: 20.707406 },
    { lat: 49.3510017, lng: 20.7073478 },
    { lat: 49.3510956, lng: 20.707048 },
    { lat: 49.3513876, lng: 20.7067048 },
    { lat: 49.3513795, lng: 20.7065105 },
    { lat: 49.3515194, lng: 20.7062274 },
    { lat: 49.3515806, lng: 20.7060162 },
    { lat: 49.3515519, lng: 20.7059063 },
    { lat: 49.3515989, lng: 20.7056385 },
    { lat: 49.3516569, lng: 20.7055922 },
    { lat: 49.3514207, lng: 20.70512 },
    { lat: 49.3509172, lng: 20.7044055 },
    { lat: 49.3508421, lng: 20.7039062 },
    { lat: 49.3510124, lng: 20.702287 },
    { lat: 49.3496396, lng: 20.7014927 },
    { lat: 49.3490527, lng: 20.7013249 },
    { lat: 49.3488828, lng: 20.7010664 },
    { lat: 49.3486099, lng: 20.7009868 },
    { lat: 49.3491206, lng: 20.6991282 },
    { lat: 49.3494354, lng: 20.6985787 },
    { lat: 49.3499079, lng: 20.6980906 },
    { lat: 49.3500549, lng: 20.6970553 },
    { lat: 49.3500101, lng: 20.696869 },
    { lat: 49.3501336, lng: 20.695971 },
    { lat: 49.3501675, lng: 20.6953682 },
    { lat: 49.3501064, lng: 20.6933168 },
    { lat: 49.349847, lng: 20.6919349 },
    { lat: 49.3493506, lng: 20.6902394 },
    { lat: 49.3490946, lng: 20.6880291 },
    { lat: 49.3487386, lng: 20.6870001 },
    { lat: 49.3486781, lng: 20.6868235 },
    { lat: 49.3452752, lng: 20.6864001 },
    { lat: 49.3451051, lng: 20.6856915 },
    { lat: 49.3454818, lng: 20.6830715 },
    { lat: 49.3455171, lng: 20.6820978 },
    { lat: 49.3446123, lng: 20.6818505 },
    { lat: 49.3420056, lng: 20.6815394 },
    { lat: 49.3390364, lng: 20.6821405 },
    { lat: 49.3380213, lng: 20.6818956 },
    { lat: 49.3376734, lng: 20.6814155 },
    { lat: 49.3374155, lng: 20.6811528 },
    { lat: 49.3367429, lng: 20.6790401 },
    { lat: 49.3364582, lng: 20.678632 },
    { lat: 49.3362825, lng: 20.6771014 },
    { lat: 49.3355869, lng: 20.6766546 },
    { lat: 49.3354538, lng: 20.6757615 },
    { lat: 49.3302229, lng: 20.6692552 },
    { lat: 49.3296979, lng: 20.6691762 },
    { lat: 49.3291753, lng: 20.668848 },
    { lat: 49.3286893, lng: 20.6677805 },
    { lat: 49.3276604, lng: 20.6648946 },
    { lat: 49.3269016, lng: 20.6603664 },
    { lat: 49.3266879, lng: 20.6597447 },
    { lat: 49.3258364, lng: 20.6594404 },
    { lat: 49.3254686, lng: 20.6582802 },
    { lat: 49.3247489, lng: 20.6551741 },
    { lat: 49.3245559, lng: 20.653611 },
    { lat: 49.3242387, lng: 20.6497056 },
    { lat: 49.3241523, lng: 20.6493083 },
    { lat: 49.3241963, lng: 20.648803 },
    { lat: 49.3244097, lng: 20.648412 },
    { lat: 49.3244062, lng: 20.6483124 },
    { lat: 49.3239563, lng: 20.6482573 },
    { lat: 49.323679, lng: 20.6481863 },
    { lat: 49.3230192, lng: 20.6478355 },
    { lat: 49.3221453, lng: 20.6479158 },
    { lat: 49.3218554, lng: 20.6480277 },
    { lat: 49.3217096, lng: 20.6481994 },
    { lat: 49.3215121, lng: 20.648309 },
    { lat: 49.3207732, lng: 20.6478541 },
    { lat: 49.3201473, lng: 20.6473164 },
    { lat: 49.3198454, lng: 20.6472785 },
    { lat: 49.3194462, lng: 20.6470095 },
    { lat: 49.3192213, lng: 20.6470154 },
    { lat: 49.3189139, lng: 20.6471969 },
    { lat: 49.318815, lng: 20.6474874 },
    { lat: 49.3185052, lng: 20.6477023 },
    { lat: 49.3171307, lng: 20.6483267 },
    { lat: 49.3166279, lng: 20.6483359 },
    { lat: 49.3163117, lng: 20.6480867 },
    { lat: 49.315751, lng: 20.6479626 },
    { lat: 49.3155703, lng: 20.647815 },
    { lat: 49.3146495, lng: 20.6481607 },
    { lat: 49.3140586, lng: 20.6486027 },
    { lat: 49.313794, lng: 20.6490123 },
    { lat: 49.3136828, lng: 20.6490826 },
    { lat: 49.3132983, lng: 20.6489026 },
    { lat: 49.3131653, lng: 20.6490901 },
    { lat: 49.3131346, lng: 20.6492395 },
    { lat: 49.3127432, lng: 20.6495944 },
    { lat: 49.312453, lng: 20.6493809 },
    { lat: 49.3122555, lng: 20.6491477 },
    { lat: 49.3120186, lng: 20.6495088 },
    { lat: 49.3116181, lng: 20.6497324 },
    { lat: 49.3113614, lng: 20.6502997 },
    { lat: 49.3112729, lng: 20.6505968 },
    { lat: 49.3112306, lng: 20.651683 },
    { lat: 49.3107663, lng: 20.6522016 },
    { lat: 49.3104301, lng: 20.6523992 },
    { lat: 49.3103296, lng: 20.6527695 },
    { lat: 49.3103153, lng: 20.6531271 },
    { lat: 49.3104791, lng: 20.6533364 },
    { lat: 49.3105031, lng: 20.6534732 },
    { lat: 49.3102268, lng: 20.6537413 },
    { lat: 49.3099302, lng: 20.6538501 },
    { lat: 49.3098185, lng: 20.6539666 },
    { lat: 49.3098083, lng: 20.6544075 },
    { lat: 49.3094492, lng: 20.6545356 },
    { lat: 49.309408, lng: 20.6549871 },
    { lat: 49.3094523, lng: 20.6550336 },
    { lat: 49.3091216, lng: 20.6553508 },
    { lat: 49.3089577, lng: 20.6557758 },
    { lat: 49.3092861, lng: 20.6577545 },
    { lat: 49.3088549, lng: 20.6582569 },
    { lat: 49.3086541, lng: 20.6587177 },
    { lat: 49.3085812, lng: 20.6592343 },
    { lat: 49.3072615, lng: 20.6607696 },
    { lat: 49.306611, lng: 20.6613999 },
    { lat: 49.3057991, lng: 20.6626565 },
    { lat: 49.3055931, lng: 20.6628139 },
    { lat: 49.3050536, lng: 20.6636279 },
    { lat: 49.3049734, lng: 20.6634016 },
    { lat: 49.3048592, lng: 20.663414 },
    { lat: 49.3034845, lng: 20.6641316 },
    { lat: 49.3032987, lng: 20.6642286 },
    { lat: 49.3028841, lng: 20.6645029 },
    { lat: 49.302823, lng: 20.6643363 },
    { lat: 49.2997666, lng: 20.6656501 },
    { lat: 49.2994259, lng: 20.6654429 },
    { lat: 49.2986379, lng: 20.6660719 },
    { lat: 49.2969991, lng: 20.6658559 },
    { lat: 49.2921083, lng: 20.6658561 },
    { lat: 49.2899874, lng: 20.6667008 },
    { lat: 49.287813, lng: 20.6663912 },
    { lat: 49.2873789, lng: 20.6669405 },
    { lat: 49.2868258, lng: 20.6678822 },
    { lat: 49.2866677, lng: 20.6679927 },
    { lat: 49.2864774, lng: 20.6687481 },
    { lat: 49.2861941, lng: 20.6692991 },
    { lat: 49.2859111, lng: 20.6702014 },
    { lat: 49.285851, lng: 20.6701577 },
    { lat: 49.2856262, lng: 20.671212 },
    { lat: 49.286383, lng: 20.6743536 },
    { lat: 49.2865026, lng: 20.6782347 },
    { lat: 49.2864136, lng: 20.680513 },
    { lat: 49.2862208, lng: 20.6816132 },
    { lat: 49.2863363, lng: 20.6840192 },
    { lat: 49.286296, lng: 20.6864697 },
    { lat: 49.2864922, lng: 20.6872753 },
    { lat: 49.2861816, lng: 20.689662 },
    { lat: 49.2862784, lng: 20.6898502 },
    { lat: 49.2861969, lng: 20.6913994 },
    { lat: 49.2851307, lng: 20.6930442 },
    { lat: 49.2848625, lng: 20.6933249 },
    { lat: 49.2847856, lng: 20.6935393 },
    { lat: 49.2841314, lng: 20.6943894 },
    { lat: 49.2833584, lng: 20.6964345 },
    { lat: 49.2830004, lng: 20.6971629 },
    { lat: 49.2830011, lng: 20.6983984 },
    { lat: 49.2829261, lng: 20.6988881 },
    { lat: 49.2829175, lng: 20.6992771 },
    { lat: 49.2827102, lng: 20.7002612 },
    { lat: 49.2825213, lng: 20.7019923 },
    { lat: 49.2816063, lng: 20.7040016 },
    { lat: 49.2814752, lng: 20.7046928 },
    { lat: 49.2814712, lng: 20.7052155 },
    { lat: 49.2816105, lng: 20.7075677 },
    { lat: 49.2815335, lng: 20.7078784 },
    { lat: 49.2815135, lng: 20.7083296 },
    { lat: 49.2812875, lng: 20.7087812 },
    { lat: 49.280919, lng: 20.7104667 },
    { lat: 49.2811623, lng: 20.7124203 },
  ],
  Hromoš: [
    { lat: 49.2666013, lng: 20.8080134 },
    { lat: 49.2658359, lng: 20.8068048 },
    { lat: 49.2653464, lng: 20.8059493 },
    { lat: 49.2648616, lng: 20.8054044 },
    { lat: 49.2648851, lng: 20.805204 },
    { lat: 49.2648407, lng: 20.8050933 },
    { lat: 49.2644326, lng: 20.8046179 },
    { lat: 49.2642032, lng: 20.8042637 },
    { lat: 49.2638836, lng: 20.8035795 },
    { lat: 49.2648955, lng: 20.8026288 },
    { lat: 49.2643741, lng: 20.801151 },
    { lat: 49.2637625, lng: 20.8001573 },
    { lat: 49.2634682, lng: 20.7994277 },
    { lat: 49.262407, lng: 20.7978306 },
    { lat: 49.2615703, lng: 20.7968071 },
    { lat: 49.2613479, lng: 20.7963634 },
    { lat: 49.2614894, lng: 20.7954399 },
    { lat: 49.2617645, lng: 20.7946696 },
    { lat: 49.2620501, lng: 20.7941103 },
    { lat: 49.2615693, lng: 20.7937772 },
    { lat: 49.2612813, lng: 20.7934559 },
    { lat: 49.2611982, lng: 20.7932972 },
    { lat: 49.2610978, lng: 20.7927056 },
    { lat: 49.2610457, lng: 20.7921051 },
    { lat: 49.261251, lng: 20.7910157 },
    { lat: 49.2613425, lng: 20.7908584 },
    { lat: 49.2613012, lng: 20.7904474 },
    { lat: 49.2613477, lng: 20.7901283 },
    { lat: 49.2614757, lng: 20.789825 },
    { lat: 49.261513, lng: 20.7894706 },
    { lat: 49.2614906, lng: 20.7892468 },
    { lat: 49.2615231, lng: 20.7891145 },
    { lat: 49.26161, lng: 20.7890213 },
    { lat: 49.2614417, lng: 20.7884161 },
    { lat: 49.2610865, lng: 20.7885608 },
    { lat: 49.2607883, lng: 20.7874591 },
    { lat: 49.2605957, lng: 20.786999 },
    { lat: 49.2603213, lng: 20.786615 },
    { lat: 49.2592497, lng: 20.785574 },
    { lat: 49.2590494, lng: 20.785771 },
    { lat: 49.2590238, lng: 20.7858751 },
    { lat: 49.2587544, lng: 20.7862219 },
    { lat: 49.2587519, lng: 20.7864098 },
    { lat: 49.2582693, lng: 20.7873904 },
    { lat: 49.2581476, lng: 20.7875526 },
    { lat: 49.2580093, lng: 20.7876542 },
    { lat: 49.257118, lng: 20.7879474 },
    { lat: 49.2571554, lng: 20.7875012 },
    { lat: 49.2575327, lng: 20.7872805 },
    { lat: 49.2573915, lng: 20.7847597 },
    { lat: 49.25715, lng: 20.7831786 },
    { lat: 49.2569275, lng: 20.7823779 },
    { lat: 49.2567379, lng: 20.7819093 },
    { lat: 49.2562384, lng: 20.7810049 },
    { lat: 49.2563538, lng: 20.7802089 },
    { lat: 49.2564767, lng: 20.777713 },
    { lat: 49.2554438, lng: 20.7778867 },
    { lat: 49.2554439, lng: 20.7779508 },
    { lat: 49.2552285, lng: 20.7780378 },
    { lat: 49.2549075, lng: 20.7780976 },
    { lat: 49.2536653, lng: 20.7777891 },
    { lat: 49.253416, lng: 20.7775081 },
    { lat: 49.2532518, lng: 20.7774803 },
    { lat: 49.2529302, lng: 20.7772602 },
    { lat: 49.2528255, lng: 20.7771299 },
    { lat: 49.2527175, lng: 20.7768119 },
    { lat: 49.2527021, lng: 20.7765566 },
    { lat: 49.2525304, lng: 20.7762181 },
    { lat: 49.2518046, lng: 20.7757681 },
    { lat: 49.251645, lng: 20.7755436 },
    { lat: 49.2517467, lng: 20.774262 },
    { lat: 49.2506665, lng: 20.7735892 },
    { lat: 49.2504502, lng: 20.773779 },
    { lat: 49.2502408, lng: 20.7737448 },
    { lat: 49.2500536, lng: 20.7736064 },
    { lat: 49.2499162, lng: 20.7734396 },
    { lat: 49.2499346, lng: 20.7730919 },
    { lat: 49.2496321, lng: 20.7729733 },
    { lat: 49.2495384, lng: 20.7728699 },
    { lat: 49.2493583, lng: 20.7724443 },
    { lat: 49.2493717, lng: 20.772245 },
    { lat: 49.2493153, lng: 20.7721775 },
    { lat: 49.249332, lng: 20.7717528 },
    { lat: 49.2490594, lng: 20.7716474 },
    { lat: 49.2489967, lng: 20.7718182 },
    { lat: 49.2483723, lng: 20.7714091 },
    { lat: 49.2482356, lng: 20.7717501 },
    { lat: 49.2480635, lng: 20.7718812 },
    { lat: 49.2476527, lng: 20.7708102 },
    { lat: 49.2475297, lng: 20.7698633 },
    { lat: 49.2473108, lng: 20.7691349 },
    { lat: 49.2471725, lng: 20.7692347 },
    { lat: 49.2471167, lng: 20.7690548 },
    { lat: 49.2470182, lng: 20.7690722 },
    { lat: 49.2469352, lng: 20.769147 },
    { lat: 49.2469632, lng: 20.7692405 },
    { lat: 49.2469292, lng: 20.7693957 },
    { lat: 49.246799, lng: 20.7694351 },
    { lat: 49.2467654, lng: 20.7693438 },
    { lat: 49.2466589, lng: 20.7693549 },
    { lat: 49.2465887, lng: 20.769281 },
    { lat: 49.2464552, lng: 20.7695181 },
    { lat: 49.2463789, lng: 20.7694954 },
    { lat: 49.2462297, lng: 20.769737 },
    { lat: 49.2460739, lng: 20.7694596 },
    { lat: 49.2459177, lng: 20.7695324 },
    { lat: 49.2457686, lng: 20.7692599 },
    { lat: 49.2455413, lng: 20.7692625 },
    { lat: 49.2454693, lng: 20.769099 },
    { lat: 49.2452933, lng: 20.7689277 },
    { lat: 49.2453361, lng: 20.7688025 },
    { lat: 49.2452512, lng: 20.7686253 },
    { lat: 49.2450959, lng: 20.7685871 },
    { lat: 49.245075, lng: 20.7684757 },
    { lat: 49.2447304, lng: 20.7686318 },
    { lat: 49.2443306, lng: 20.7682709 },
    { lat: 49.2443034, lng: 20.768098 },
    { lat: 49.2438369, lng: 20.7678278 },
    { lat: 49.2435865, lng: 20.7675873 },
    { lat: 49.2429735, lng: 20.7676009 },
    { lat: 49.2426174, lng: 20.7676824 },
    { lat: 49.2425331, lng: 20.7675931 },
    { lat: 49.2423609, lng: 20.7675588 },
    { lat: 49.2421386, lng: 20.7676081 },
    { lat: 49.2420565, lng: 20.7674462 },
    { lat: 49.2418259, lng: 20.7674483 },
    { lat: 49.2414884, lng: 20.7672909 },
    { lat: 49.2413901, lng: 20.7669862 },
    { lat: 49.2410648, lng: 20.7667291 },
    { lat: 49.2410699, lng: 20.7666508 },
    { lat: 49.2408653, lng: 20.7662683 },
    { lat: 49.2407785, lng: 20.7657797 },
    { lat: 49.2404968, lng: 20.7664833 },
    { lat: 49.2398762, lng: 20.7666363 },
    { lat: 49.2397514, lng: 20.7673496 },
    { lat: 49.2390691, lng: 20.7679957 },
    { lat: 49.2388381, lng: 20.7685698 },
    { lat: 49.2378701, lng: 20.769574 },
    { lat: 49.2375085, lng: 20.770046 },
    { lat: 49.236901, lng: 20.7699962 },
    { lat: 49.2366466, lng: 20.7705423 },
    { lat: 49.236224, lng: 20.7704617 },
    { lat: 49.2357099, lng: 20.7698514 },
    { lat: 49.2351678, lng: 20.7693806 },
    { lat: 49.2335558, lng: 20.7683738 },
    { lat: 49.2321419, lng: 20.7673668 },
    { lat: 49.2311869, lng: 20.7678667 },
    { lat: 49.2303416, lng: 20.7699722 },
    { lat: 49.229996, lng: 20.771523 },
    { lat: 49.230128, lng: 20.774304 },
    { lat: 49.230336, lng: 20.775094 },
    { lat: 49.22977, lng: 20.776524 },
    { lat: 49.229545, lng: 20.777758 },
    { lat: 49.229562, lng: 20.779571 },
    { lat: 49.228858, lng: 20.782648 },
    { lat: 49.228766, lng: 20.783308 },
    { lat: 49.228287, lng: 20.784394 },
    { lat: 49.227531, lng: 20.787205 },
    { lat: 49.227461, lng: 20.78964 },
    { lat: 49.227791, lng: 20.793549 },
    { lat: 49.227186, lng: 20.795616 },
    { lat: 49.227315, lng: 20.796939 },
    { lat: 49.226937, lng: 20.798634 },
    { lat: 49.22683, lng: 20.800922 },
    { lat: 49.226882, lng: 20.804632 },
    { lat: 49.227206, lng: 20.804444 },
    { lat: 49.227285, lng: 20.804887 },
    { lat: 49.227725, lng: 20.805192 },
    { lat: 49.228759, lng: 20.806937 },
    { lat: 49.230717, lng: 20.808793 },
    { lat: 49.230213, lng: 20.811125 },
    { lat: 49.229531, lng: 20.812544 },
    { lat: 49.228704, lng: 20.816551 },
    { lat: 49.229614, lng: 20.818742 },
    { lat: 49.230467, lng: 20.820429 },
    { lat: 49.231304, lng: 20.820453 },
    { lat: 49.231854, lng: 20.821083 },
    { lat: 49.231895, lng: 20.824434 },
    { lat: 49.231796, lng: 20.825399 },
    { lat: 49.2339952, lng: 20.8301911 },
    { lat: 49.2344225, lng: 20.8315952 },
    { lat: 49.2339534, lng: 20.8323255 },
    { lat: 49.2338815, lng: 20.8327894 },
    { lat: 49.2339173, lng: 20.8331128 },
    { lat: 49.2338552, lng: 20.8334077 },
    { lat: 49.2338892, lng: 20.8339031 },
    { lat: 49.2338371, lng: 20.8345181 },
    { lat: 49.2335652, lng: 20.8356181 },
    { lat: 49.2336476, lng: 20.8361222 },
    { lat: 49.2336674, lng: 20.8366688 },
    { lat: 49.2336189, lng: 20.83716 },
    { lat: 49.2333874, lng: 20.8379916 },
    { lat: 49.2323833, lng: 20.839738 },
    { lat: 49.2323766, lng: 20.8399359 },
    { lat: 49.2324843, lng: 20.8400901 },
    { lat: 49.2323531, lng: 20.8404694 },
    { lat: 49.2321637, lng: 20.8404535 },
    { lat: 49.2319221, lng: 20.8417235 },
    { lat: 49.2316811, lng: 20.8424082 },
    { lat: 49.2316754, lng: 20.8426352 },
    { lat: 49.2315186, lng: 20.8429275 },
    { lat: 49.2318667, lng: 20.8429326 },
    { lat: 49.2322698, lng: 20.842706 },
    { lat: 49.2328032, lng: 20.8415489 },
    { lat: 49.2329572, lng: 20.8416818 },
    { lat: 49.2336504, lng: 20.8411152 },
    { lat: 49.235366, lng: 20.8383541 },
    { lat: 49.2368029, lng: 20.8395309 },
    { lat: 49.2382557, lng: 20.8402825 },
    { lat: 49.2386652, lng: 20.8403739 },
    { lat: 49.2387926, lng: 20.8396954 },
    { lat: 49.238829, lng: 20.8395017 },
    { lat: 49.2402813, lng: 20.8395132 },
    { lat: 49.2408955, lng: 20.8385194 },
    { lat: 49.241269, lng: 20.8379152 },
    { lat: 49.2412927, lng: 20.8368626 },
    { lat: 49.2418779, lng: 20.8366312 },
    { lat: 49.2417876, lng: 20.8360991 },
    { lat: 49.2433848, lng: 20.834389 },
    { lat: 49.2451233, lng: 20.8309221 },
    { lat: 49.2459925, lng: 20.8294495 },
    { lat: 49.2486209, lng: 20.8255105 },
    { lat: 49.2485909, lng: 20.8241479 },
    { lat: 49.2487141, lng: 20.8194023 },
    { lat: 49.2481987, lng: 20.8188102 },
    { lat: 49.2486351, lng: 20.8177532 },
    { lat: 49.2486774, lng: 20.8148229 },
    { lat: 49.2487249, lng: 20.8145705 },
    { lat: 49.2488883, lng: 20.8142137 },
    { lat: 49.2495003, lng: 20.8133526 },
    { lat: 49.2495109, lng: 20.8119589 },
    { lat: 49.2505624, lng: 20.8089847 },
    { lat: 49.2523699, lng: 20.8059749 },
    { lat: 49.2537745, lng: 20.8044342 },
    { lat: 49.2544571, lng: 20.8041891 },
    { lat: 49.2550539, lng: 20.8052747 },
    { lat: 49.2548148, lng: 20.8063727 },
    { lat: 49.2556802, lng: 20.8069903 },
    { lat: 49.2563099, lng: 20.8069758 },
    { lat: 49.2568853, lng: 20.8064932 },
    { lat: 49.2590511, lng: 20.8059441 },
    { lat: 49.259791, lng: 20.8055337 },
    { lat: 49.2611101, lng: 20.8055632 },
    { lat: 49.2616567, lng: 20.8059773 },
    { lat: 49.2631216, lng: 20.8055994 },
    { lat: 49.2637813, lng: 20.8062322 },
    { lat: 49.2639084, lng: 20.806458 },
    { lat: 49.264251, lng: 20.8059739 },
    { lat: 49.2644056, lng: 20.8067104 },
    { lat: 49.2644873, lng: 20.8076473 },
    { lat: 49.2648742, lng: 20.8087464 },
    { lat: 49.2665493, lng: 20.8084958 },
    { lat: 49.2666013, lng: 20.8080134 },
  ],
  Orlov: [
    { lat: 49.2833049, lng: 20.8990058 },
    { lat: 49.2833063, lng: 20.8991806 },
    { lat: 49.2838991, lng: 20.9024517 },
    { lat: 49.2843233, lng: 20.9038266 },
    { lat: 49.2854499, lng: 20.9061118 },
    { lat: 49.2852492, lng: 20.9067649 },
    { lat: 49.2864953, lng: 20.9087186 },
    { lat: 49.2868498, lng: 20.9089234 },
    { lat: 49.2877033, lng: 20.9096429 },
    { lat: 49.2879886, lng: 20.9097254 },
    { lat: 49.2895878, lng: 20.9087843 },
    { lat: 49.290466, lng: 20.9084941 },
    { lat: 49.291583, lng: 20.9077717 },
    { lat: 49.2921426, lng: 20.9078137 },
    { lat: 49.2926243, lng: 20.9085201 },
    { lat: 49.2925382, lng: 20.9094077 },
    { lat: 49.293247, lng: 20.9109171 },
    { lat: 49.2938818, lng: 20.9117255 },
    { lat: 49.2939362, lng: 20.9117043 },
    { lat: 49.2942127, lng: 20.9121294 },
    { lat: 49.2943069, lng: 20.9120481 },
    { lat: 49.2945332, lng: 20.9123542 },
    { lat: 49.2946862, lng: 20.9124241 },
    { lat: 49.2950033, lng: 20.9130198 },
    { lat: 49.2954671, lng: 20.9140671 },
    { lat: 49.2957179, lng: 20.9153045 },
    { lat: 49.2960355, lng: 20.9194498 },
    { lat: 49.2960718, lng: 20.9225267 },
    { lat: 49.296252, lng: 20.923752 },
    { lat: 49.296453, lng: 20.923461 },
    { lat: 49.296482, lng: 20.923581 },
    { lat: 49.296505, lng: 20.923631 },
    { lat: 49.296536, lng: 20.923686 },
    { lat: 49.296681, lng: 20.923946 },
    { lat: 49.296896, lng: 20.924104 },
    { lat: 49.296951, lng: 20.924178 },
    { lat: 49.297115, lng: 20.924187 },
    { lat: 49.29724, lng: 20.924105 },
    { lat: 49.297489, lng: 20.924087 },
    { lat: 49.297545, lng: 20.924085 },
    { lat: 49.297613, lng: 20.924091 },
    { lat: 49.297809, lng: 20.924076 },
    { lat: 49.297948, lng: 20.924075 },
    { lat: 49.298261, lng: 20.92411 },
    { lat: 49.298518, lng: 20.924165 },
    { lat: 49.298655, lng: 20.924204 },
    { lat: 49.298806, lng: 20.924253 },
    { lat: 49.299009, lng: 20.924276 },
    { lat: 49.299089, lng: 20.924208 },
    { lat: 49.299223, lng: 20.924125 },
    { lat: 49.299469, lng: 20.923871 },
    { lat: 49.299652, lng: 20.923507 },
    { lat: 49.299759, lng: 20.923422 },
    { lat: 49.299818, lng: 20.923336 },
    { lat: 49.299849, lng: 20.923282 },
    { lat: 49.299946, lng: 20.923068 },
    { lat: 49.300006, lng: 20.922654 },
    { lat: 49.300133, lng: 20.922338 },
    { lat: 49.300226, lng: 20.922268 },
    { lat: 49.30026, lng: 20.922151 },
    { lat: 49.300474, lng: 20.921773 },
    { lat: 49.300508, lng: 20.921757 },
    { lat: 49.300641, lng: 20.921627 },
    { lat: 49.300749, lng: 20.921541 },
    { lat: 49.300938, lng: 20.921417 },
    { lat: 49.301148, lng: 20.921267 },
    { lat: 49.301359, lng: 20.921281 },
    { lat: 49.30173, lng: 20.92117 },
    { lat: 49.302021, lng: 20.920795 },
    { lat: 49.302156, lng: 20.920608 },
    { lat: 49.302345, lng: 20.920279 },
    { lat: 49.302424, lng: 20.920107 },
    { lat: 49.302428, lng: 20.920031 },
    { lat: 49.302563, lng: 20.919769 },
    { lat: 49.302899, lng: 20.919247 },
    { lat: 49.303152, lng: 20.918854 },
    { lat: 49.303262, lng: 20.918741 },
    { lat: 49.303526, lng: 20.918295 },
    { lat: 49.303614, lng: 20.918162 },
    { lat: 49.304017, lng: 20.917673 },
    { lat: 49.304164, lng: 20.917514 },
    { lat: 49.304282, lng: 20.917347 },
    { lat: 49.304425, lng: 20.917063 },
    { lat: 49.304506, lng: 20.916906 },
    { lat: 49.304526, lng: 20.916842 },
    { lat: 49.304659, lng: 20.916459 },
    { lat: 49.304724, lng: 20.916243 },
    { lat: 49.304851, lng: 20.915819 },
    { lat: 49.304944, lng: 20.915501 },
    { lat: 49.305, lng: 20.915289 },
    { lat: 49.305038, lng: 20.914959 },
    { lat: 49.305049, lng: 20.914908 },
    { lat: 49.305079, lng: 20.914694 },
    { lat: 49.305104, lng: 20.914483 },
    { lat: 49.305204, lng: 20.914118 },
    { lat: 49.305315, lng: 20.913713 },
    { lat: 49.30533, lng: 20.913657 },
    { lat: 49.305478, lng: 20.913128 },
    { lat: 49.305531, lng: 20.912961 },
    { lat: 49.30563, lng: 20.912654 },
    { lat: 49.30573, lng: 20.91232 },
    { lat: 49.305773, lng: 20.912212 },
    { lat: 49.305944, lng: 20.91191 },
    { lat: 49.306124, lng: 20.911564 },
    { lat: 49.306266, lng: 20.91134 },
    { lat: 49.306337, lng: 20.911294 },
    { lat: 49.306539, lng: 20.911081 },
    { lat: 49.306596, lng: 20.910989 },
    { lat: 49.306859, lng: 20.910787 },
    { lat: 49.307182, lng: 20.91052 },
    { lat: 49.307375, lng: 20.910382 },
    { lat: 49.307838, lng: 20.910276 },
    { lat: 49.308399, lng: 20.910155 },
    { lat: 49.30846, lng: 20.910139 },
    { lat: 49.308727, lng: 20.910097 },
    { lat: 49.308796, lng: 20.910057 },
    { lat: 49.30906, lng: 20.909993 },
    { lat: 49.309222, lng: 20.909989 },
    { lat: 49.309432, lng: 20.910051 },
    { lat: 49.309531, lng: 20.910067 },
    { lat: 49.309679, lng: 20.910105 },
    { lat: 49.309828, lng: 20.910117 },
    { lat: 49.310119, lng: 20.910238 },
    { lat: 49.310161, lng: 20.91025 },
    { lat: 49.31048, lng: 20.910312 },
    { lat: 49.310538, lng: 20.910315 },
    { lat: 49.31082, lng: 20.910319 },
    { lat: 49.31088, lng: 20.910323 },
    { lat: 49.310925, lng: 20.910327 },
    { lat: 49.311141, lng: 20.910342 },
    { lat: 49.311383, lng: 20.910313 },
    { lat: 49.311474, lng: 20.91024 },
    { lat: 49.31167, lng: 20.910025 },
    { lat: 49.312142, lng: 20.909456 },
    { lat: 49.312319, lng: 20.909183 },
    { lat: 49.312544, lng: 20.9087 },
    { lat: 49.31273, lng: 20.908291 },
    { lat: 49.312758, lng: 20.908241 },
    { lat: 49.312954, lng: 20.907879 },
    { lat: 49.313029, lng: 20.907756 },
    { lat: 49.313132, lng: 20.90758 },
    { lat: 49.313246, lng: 20.907369 },
    { lat: 49.313355, lng: 20.907117 },
    { lat: 49.313449, lng: 20.906916 },
    { lat: 49.313532, lng: 20.906724 },
    { lat: 49.313558, lng: 20.906658 },
    { lat: 49.313695, lng: 20.906423 },
    { lat: 49.313731, lng: 20.906359 },
    { lat: 49.313846, lng: 20.906132 },
    { lat: 49.313873, lng: 20.906082 },
    { lat: 49.313956, lng: 20.905891 },
    { lat: 49.313995, lng: 20.90584 },
    { lat: 49.31408, lng: 20.905683 },
    { lat: 49.314205, lng: 20.905431 },
    { lat: 49.314236, lng: 20.90537 },
    { lat: 49.314269, lng: 20.905294 },
    { lat: 49.314337, lng: 20.905153 },
    { lat: 49.314442, lng: 20.904964 },
    { lat: 49.314589, lng: 20.904681 },
    { lat: 49.314697, lng: 20.904533 },
    { lat: 49.314883, lng: 20.904274 },
    { lat: 49.315103, lng: 20.903919 },
    { lat: 49.315444, lng: 20.903646 },
    { lat: 49.315531, lng: 20.903583 },
    { lat: 49.315616, lng: 20.903514 },
    { lat: 49.315895, lng: 20.90328 },
    { lat: 49.315917, lng: 20.903259 },
    { lat: 49.316044, lng: 20.903184 },
    { lat: 49.316128, lng: 20.903145 },
    { lat: 49.316163, lng: 20.903139 },
    { lat: 49.316289, lng: 20.903102 },
    { lat: 49.316457, lng: 20.903074 },
    { lat: 49.316542, lng: 20.90307 },
    { lat: 49.316705, lng: 20.903079 },
    { lat: 49.316814, lng: 20.903091 },
    { lat: 49.316918, lng: 20.903106 },
    { lat: 49.317045, lng: 20.903127 },
    { lat: 49.317276, lng: 20.903192 },
    { lat: 49.317325, lng: 20.903209 },
    { lat: 49.31746, lng: 20.903212 },
    { lat: 49.31748, lng: 20.903217 },
    { lat: 49.317551, lng: 20.903237 },
    { lat: 49.317569, lng: 20.903244 },
    { lat: 49.317769, lng: 20.903332 },
    { lat: 49.317849, lng: 20.903367 },
    { lat: 49.318012, lng: 20.903427 },
    { lat: 49.318119, lng: 20.903466 },
    { lat: 49.318308, lng: 20.90356 },
    { lat: 49.318353, lng: 20.903613 },
    { lat: 49.318526, lng: 20.903788 },
    { lat: 49.31861, lng: 20.90389 },
    { lat: 49.318636, lng: 20.903919 },
    { lat: 49.318795, lng: 20.904092 },
    { lat: 49.318822, lng: 20.904119 },
    { lat: 49.318924, lng: 20.904309 },
    { lat: 49.318986, lng: 20.904514 },
    { lat: 49.319062, lng: 20.904803 },
    { lat: 49.319096, lng: 20.904944 },
    { lat: 49.319126, lng: 20.905153 },
    { lat: 49.319152, lng: 20.905338 },
    { lat: 49.319159, lng: 20.9054 },
    { lat: 49.319184, lng: 20.905539 },
    { lat: 49.319246, lng: 20.906007 },
    { lat: 49.319178, lng: 20.906658 },
    { lat: 49.319155, lng: 20.90688 },
    { lat: 49.319157, lng: 20.907191 },
    { lat: 49.319189, lng: 20.907323 },
    { lat: 49.319254, lng: 20.907453 },
    { lat: 49.319449, lng: 20.907595 },
    { lat: 49.31958, lng: 20.907616 },
    { lat: 49.319798, lng: 20.907594 },
    { lat: 49.3201, lng: 20.9075 },
    { lat: 49.3203, lng: 20.907421 },
    { lat: 49.320527, lng: 20.907354 },
    { lat: 49.320616, lng: 20.907321 },
    { lat: 49.320714, lng: 20.907296 },
    { lat: 49.320842, lng: 20.90724 },
    { lat: 49.320962, lng: 20.907177 },
    { lat: 49.321141, lng: 20.907098 },
    { lat: 49.321169, lng: 20.90708 },
    { lat: 49.321495, lng: 20.906846 },
    { lat: 49.321509, lng: 20.906837 },
    { lat: 49.321815, lng: 20.906539 },
    { lat: 49.321965, lng: 20.906378 },
    { lat: 49.322014, lng: 20.906324 },
    { lat: 49.322109, lng: 20.9062 },
    { lat: 49.322267, lng: 20.906008 },
    { lat: 49.322336, lng: 20.905919 },
    { lat: 49.32251, lng: 20.905673 },
    { lat: 49.32267, lng: 20.905401 },
    { lat: 49.322688, lng: 20.905369 },
    { lat: 49.3228145, lng: 20.9051156 },
    { lat: 49.3228736, lng: 20.9049806 },
    { lat: 49.32305, lng: 20.904569 },
    { lat: 49.323088, lng: 20.904497 },
    { lat: 49.323299, lng: 20.90408 },
    { lat: 49.323488, lng: 20.903669 },
    { lat: 49.323535, lng: 20.903575 },
    { lat: 49.323647, lng: 20.903307 },
    { lat: 49.323768, lng: 20.903037 },
    { lat: 49.323838, lng: 20.902858 },
    { lat: 49.323928, lng: 20.902578 },
    { lat: 49.324009, lng: 20.902144 },
    { lat: 49.323997, lng: 20.902054 },
    { lat: 49.323992, lng: 20.901873 },
    { lat: 49.323996, lng: 20.90141 },
    { lat: 49.323977, lng: 20.901281 },
    { lat: 49.324035, lng: 20.900878 },
    { lat: 49.32406, lng: 20.900661 },
    { lat: 49.324097, lng: 20.900382 },
    { lat: 49.324116, lng: 20.90011 },
    { lat: 49.324132, lng: 20.900055 },
    { lat: 49.324288, lng: 20.899518 },
    { lat: 49.324307, lng: 20.899457 },
    { lat: 49.324456, lng: 20.899086 },
    { lat: 49.324506, lng: 20.898971 },
    { lat: 49.324715, lng: 20.898529 },
    { lat: 49.324737, lng: 20.898484 },
    { lat: 49.324964, lng: 20.897948 },
    { lat: 49.324982, lng: 20.897905 },
    { lat: 49.325104, lng: 20.897587 },
    { lat: 49.325119, lng: 20.897527 },
    { lat: 49.325218, lng: 20.897357 },
    { lat: 49.325311, lng: 20.897215 },
    { lat: 49.325355, lng: 20.897172 },
    { lat: 49.325161, lng: 20.896981 },
    { lat: 49.325142, lng: 20.896975 },
    { lat: 49.325075, lng: 20.896931 },
    { lat: 49.325061, lng: 20.896926 },
    { lat: 49.324896, lng: 20.896848 },
    { lat: 49.324811, lng: 20.896753 },
    { lat: 49.324788, lng: 20.896657 },
    { lat: 49.324835, lng: 20.896524 },
    { lat: 49.324837, lng: 20.896509 },
    { lat: 49.324839, lng: 20.896281 },
    { lat: 49.32484, lng: 20.896268 },
    { lat: 49.32477, lng: 20.896078 },
    { lat: 49.324766, lng: 20.89606 },
    { lat: 49.324749, lng: 20.895833 },
    { lat: 49.324748, lng: 20.895814 },
    { lat: 49.324716, lng: 20.895582 },
    { lat: 49.324573, lng: 20.895189 },
    { lat: 49.324567, lng: 20.895175 },
    { lat: 49.324438, lng: 20.89495 },
    { lat: 49.32448, lng: 20.894726 },
    { lat: 49.32439, lng: 20.894586 },
    { lat: 49.324357, lng: 20.894533 },
    { lat: 49.324305, lng: 20.893837 },
    { lat: 49.324196, lng: 20.893671 },
    { lat: 49.324159, lng: 20.893487 },
    { lat: 49.324152, lng: 20.89347 },
    { lat: 49.324029, lng: 20.893251 },
    { lat: 49.324066, lng: 20.893122 },
    { lat: 49.324013, lng: 20.893046 },
    { lat: 49.32401, lng: 20.892906 },
    { lat: 49.324052, lng: 20.892705 },
    { lat: 49.324073, lng: 20.892336 },
    { lat: 49.324087, lng: 20.892113 },
    { lat: 49.32401, lng: 20.891962 },
    { lat: 49.324036, lng: 20.891467 },
    { lat: 49.324101, lng: 20.891027 },
    { lat: 49.324058, lng: 20.890799 },
    { lat: 49.323937, lng: 20.890554 },
    { lat: 49.323934, lng: 20.890542 },
    { lat: 49.323905, lng: 20.890265 },
    { lat: 49.323902, lng: 20.890248 },
    { lat: 49.323832, lng: 20.889987 },
    { lat: 49.323735, lng: 20.889915 },
    { lat: 49.32373, lng: 20.889899 },
    { lat: 49.323718, lng: 20.889831 },
    { lat: 49.323719, lng: 20.889807 },
    { lat: 49.323726, lng: 20.889709 },
    { lat: 49.323672, lng: 20.889618 },
    { lat: 49.32348, lng: 20.88926 },
    { lat: 49.323276, lng: 20.889095 },
    { lat: 49.323267, lng: 20.889079 },
    { lat: 49.323221, lng: 20.888947 },
    { lat: 49.323214, lng: 20.888934 },
    { lat: 49.323107, lng: 20.888836 },
    { lat: 49.323033, lng: 20.888506 },
    { lat: 49.323029, lng: 20.888492 },
    { lat: 49.322983, lng: 20.888391 },
    { lat: 49.322947, lng: 20.888352 },
    { lat: 49.322931, lng: 20.888333 },
    { lat: 49.322831, lng: 20.888216 },
    { lat: 49.322647, lng: 20.88794 },
    { lat: 49.322366, lng: 20.887853 },
    { lat: 49.322173, lng: 20.887694 },
    { lat: 49.321997, lng: 20.887499 },
    { lat: 49.321948, lng: 20.887411 },
    { lat: 49.321936, lng: 20.887396 },
    { lat: 49.321692, lng: 20.887188 },
    { lat: 49.321683, lng: 20.887181 },
    { lat: 49.321673, lng: 20.887173 },
    { lat: 49.321564, lng: 20.887094 },
    { lat: 49.321358, lng: 20.887112 },
    { lat: 49.321161, lng: 20.887109 },
    { lat: 49.321075, lng: 20.887025 },
    { lat: 49.321021, lng: 20.887008 },
    { lat: 49.320752, lng: 20.887154 },
    { lat: 49.320639, lng: 20.88711 },
    { lat: 49.320507, lng: 20.887133 },
    { lat: 49.320424, lng: 20.887199 },
    { lat: 49.320271, lng: 20.887157 },
    { lat: 49.319942, lng: 20.886657 },
    { lat: 49.31975, lng: 20.886136 },
    { lat: 49.319623, lng: 20.885875 },
    { lat: 49.319614, lng: 20.885805 },
    { lat: 49.319575, lng: 20.885597 },
    { lat: 49.319572, lng: 20.885581 },
    { lat: 49.319516, lng: 20.885458 },
    { lat: 49.319513, lng: 20.885446 },
    { lat: 49.319478, lng: 20.885182 },
    { lat: 49.319472, lng: 20.885156 },
    { lat: 49.319444, lng: 20.885058 },
    { lat: 49.319421, lng: 20.884995 },
    { lat: 49.319397, lng: 20.88495 },
    { lat: 49.319294, lng: 20.884725 },
    { lat: 49.319281, lng: 20.884696 },
    { lat: 49.319185, lng: 20.88449 },
    { lat: 49.319181, lng: 20.884477 },
    { lat: 49.319095, lng: 20.884187 },
    { lat: 49.319108, lng: 20.88395 },
    { lat: 49.319137, lng: 20.883639 },
    { lat: 49.319132, lng: 20.883564 },
    { lat: 49.319131, lng: 20.88354 },
    { lat: 49.319133, lng: 20.883312 },
    { lat: 49.319134, lng: 20.883294 },
    { lat: 49.319154, lng: 20.883242 },
    { lat: 49.319228, lng: 20.883054 },
    { lat: 49.319248, lng: 20.882892 },
    { lat: 49.319333, lng: 20.882621 },
    { lat: 49.319406, lng: 20.88231 },
    { lat: 49.319432, lng: 20.882222 },
    { lat: 49.3172234, lng: 20.8788896 },
    { lat: 49.3165071, lng: 20.8784072 },
    { lat: 49.3159806, lng: 20.8777454 },
    { lat: 49.3161034, lng: 20.8757816 },
    { lat: 49.3164989, lng: 20.8733669 },
    { lat: 49.3155989, lng: 20.8708108 },
    { lat: 49.315448, lng: 20.8696489 },
    { lat: 49.3157291, lng: 20.8684759 },
    { lat: 49.3150488, lng: 20.8686604 },
    { lat: 49.3146453, lng: 20.868543 },
    { lat: 49.3142431, lng: 20.8683313 },
    { lat: 49.313564, lng: 20.8677491 },
    { lat: 49.3129341, lng: 20.8658681 },
    { lat: 49.3110398, lng: 20.8636029 },
    { lat: 49.310606, lng: 20.8623539 },
    { lat: 49.30862, lng: 20.8589816 },
    { lat: 49.3090207, lng: 20.8566966 },
    { lat: 49.3088395, lng: 20.8559636 },
    { lat: 49.3063446, lng: 20.852529 },
    { lat: 49.3031606, lng: 20.84938 },
    { lat: 49.3028344, lng: 20.8481938 },
    { lat: 49.3029341, lng: 20.8458639 },
    { lat: 49.3032434, lng: 20.8447025 },
    { lat: 49.3030817, lng: 20.8430661 },
    { lat: 49.3027408, lng: 20.8420099 },
    { lat: 49.2966919, lng: 20.8390188 },
    { lat: 49.2959278, lng: 20.83658 },
    { lat: 49.2953158, lng: 20.8336506 },
    { lat: 49.2946305, lng: 20.8326668 },
    { lat: 49.2938145, lng: 20.8320457 },
    { lat: 49.2932776, lng: 20.8317785 },
    { lat: 49.2921737, lng: 20.8317199 },
    { lat: 49.291323, lng: 20.8308623 },
    { lat: 49.288838, lng: 20.8345239 },
    { lat: 49.2873705, lng: 20.8343292 },
    { lat: 49.2858216, lng: 20.8351836 },
    { lat: 49.2854338, lng: 20.8347653 },
    { lat: 49.2850981, lng: 20.8340656 },
    { lat: 49.2843734, lng: 20.832853 },
    { lat: 49.2827464, lng: 20.8330604 },
    { lat: 49.2812627, lng: 20.8331518 },
    { lat: 49.2807804, lng: 20.8335584 },
    { lat: 49.280507, lng: 20.8343581 },
    { lat: 49.2796814, lng: 20.8356872 },
    { lat: 49.2792132, lng: 20.8359192 },
    { lat: 49.2789137, lng: 20.8364456 },
    { lat: 49.2806796, lng: 20.839476 },
    { lat: 49.280814, lng: 20.8402865 },
    { lat: 49.2810102, lng: 20.8406235 },
    { lat: 49.2801355, lng: 20.8423691 },
    { lat: 49.2784576, lng: 20.8451829 },
    { lat: 49.2780195, lng: 20.8460014 },
    { lat: 49.2779388, lng: 20.8466019 },
    { lat: 49.2775954, lng: 20.8469637 },
    { lat: 49.2769081, lng: 20.8482977 },
    { lat: 49.2768187, lng: 20.848879 },
    { lat: 49.2764845, lng: 20.8494755 },
    { lat: 49.2763632, lng: 20.8500125 },
    { lat: 49.2761564, lng: 20.8503814 },
    { lat: 49.2759631, lng: 20.8514533 },
    { lat: 49.2756275, lng: 20.8522012 },
    { lat: 49.2753323, lng: 20.8546812 },
    { lat: 49.2765834, lng: 20.8556935 },
    { lat: 49.2770663, lng: 20.8561887 },
    { lat: 49.2773802, lng: 20.8566825 },
    { lat: 49.2785386, lng: 20.8581518 },
    { lat: 49.2791223, lng: 20.858675 },
    { lat: 49.2799837, lng: 20.8600465 },
    { lat: 49.2805949, lng: 20.8608077 },
    { lat: 49.2809574, lng: 20.8611769 },
    { lat: 49.2815364, lng: 20.8610593 },
    { lat: 49.2812539, lng: 20.861489 },
    { lat: 49.2809364, lng: 20.8618509 },
    { lat: 49.2804692, lng: 20.8622102 },
    { lat: 49.2804362, lng: 20.8623985 },
    { lat: 49.2794943, lng: 20.8625337 },
    { lat: 49.2783732, lng: 20.8629941 },
    { lat: 49.2779665, lng: 20.8630181 },
    { lat: 49.2773741, lng: 20.863437 },
    { lat: 49.2777866, lng: 20.8636545 },
    { lat: 49.2773619, lng: 20.8636773 },
    { lat: 49.2763373, lng: 20.8642566 },
    { lat: 49.2761397, lng: 20.8655043 },
    { lat: 49.2762064, lng: 20.8656771 },
    { lat: 49.2755734, lng: 20.8654594 },
    { lat: 49.2749021, lng: 20.8659108 },
    { lat: 49.2739213, lng: 20.8663294 },
    { lat: 49.273172, lng: 20.8667654 },
    { lat: 49.2715395, lng: 20.8686815 },
    { lat: 49.2708336, lng: 20.8691681 },
    { lat: 49.2707938, lng: 20.8690916 },
    { lat: 49.2707733, lng: 20.8691202 },
    { lat: 49.2709512, lng: 20.8696179 },
    { lat: 49.2708923, lng: 20.8700731 },
    { lat: 49.2709499, lng: 20.8701774 },
    { lat: 49.2721445, lng: 20.8689602 },
    { lat: 49.2726414, lng: 20.8683824 },
    { lat: 49.2733183, lng: 20.8679652 },
    { lat: 49.27399, lng: 20.8676608 },
    { lat: 49.2747575, lng: 20.867488 },
    { lat: 49.2751869, lng: 20.8676062 },
    { lat: 49.2758983, lng: 20.8676771 },
    { lat: 49.2765547, lng: 20.8680829 },
    { lat: 49.2774267, lng: 20.8690416 },
    { lat: 49.2781794, lng: 20.8702008 },
    { lat: 49.2788975, lng: 20.8710625 },
    { lat: 49.2791428, lng: 20.8712605 },
    { lat: 49.2793524, lng: 20.8717036 },
    { lat: 49.2797424, lng: 20.8720516 },
    { lat: 49.2802799, lng: 20.8727686 },
    { lat: 49.2805301, lng: 20.87318 },
    { lat: 49.2811068, lng: 20.8739286 },
    { lat: 49.2815574, lng: 20.8747354 },
    { lat: 49.2816432, lng: 20.8746429 },
    { lat: 49.282875, lng: 20.8748606 },
    { lat: 49.2835248, lng: 20.8748764 },
    { lat: 49.2837503, lng: 20.8759855 },
    { lat: 49.2835684, lng: 20.8762266 },
    { lat: 49.2838869, lng: 20.8774322 },
    { lat: 49.2841476, lng: 20.878685 },
    { lat: 49.2842497, lng: 20.8801727 },
    { lat: 49.2842195, lng: 20.8805132 },
    { lat: 49.2842905, lng: 20.8811497 },
    { lat: 49.284205, lng: 20.8817484 },
    { lat: 49.2839165, lng: 20.885807 },
    { lat: 49.2840343, lng: 20.8866002 },
    { lat: 49.2844036, lng: 20.8867697 },
    { lat: 49.2843508, lng: 20.8869091 },
    { lat: 49.2840055, lng: 20.8867121 },
    { lat: 49.2839843, lng: 20.8872527 },
    { lat: 49.28388, lng: 20.8871931 },
    { lat: 49.2837168, lng: 20.8886631 },
    { lat: 49.2836248, lng: 20.8898117 },
    { lat: 49.2837349, lng: 20.889838 },
    { lat: 49.2837351, lng: 20.8910475 },
    { lat: 49.2834527, lng: 20.8952995 },
    { lat: 49.2832827, lng: 20.8957977 },
    { lat: 49.283223, lng: 20.8964643 },
    { lat: 49.28325, lng: 20.8965292 },
    { lat: 49.2832113, lng: 20.8975268 },
    { lat: 49.2833049, lng: 20.8990058 },
  ],
  RuskáVoľanadPopradom: [
    { lat: 49.277018, lng: 20.990024 },
    { lat: 49.277351, lng: 20.990206 },
    { lat: 49.277439, lng: 20.990411 },
    { lat: 49.277622, lng: 20.991182 },
    { lat: 49.277667, lng: 20.991332 },
    { lat: 49.277734, lng: 20.991382 },
    { lat: 49.277719, lng: 20.991385 },
    { lat: 49.277733, lng: 20.991958 },
    { lat: 49.277676, lng: 20.992373 },
    { lat: 49.277642, lng: 20.992457 },
    { lat: 49.277633, lng: 20.992723 },
    { lat: 49.27759, lng: 20.99287 },
    { lat: 49.277602, lng: 20.993154 },
    { lat: 49.27758, lng: 20.993387 },
    { lat: 49.277465, lng: 20.993912 },
    { lat: 49.277441, lng: 20.994071 },
    { lat: 49.277297, lng: 20.994793 },
    { lat: 49.277297, lng: 20.995049 },
    { lat: 49.277237, lng: 20.995434 },
    { lat: 49.277262, lng: 20.99587 },
    { lat: 49.277442, lng: 20.996509 },
    { lat: 49.277502, lng: 20.996874 },
    { lat: 49.27754, lng: 20.997185 },
    { lat: 49.277577, lng: 20.99741 },
    { lat: 49.277678, lng: 20.997859 },
    { lat: 49.277772, lng: 20.998173 },
    { lat: 49.277735, lng: 20.998816 },
    { lat: 49.277769, lng: 20.999222 },
    { lat: 49.277903, lng: 20.999617 },
    { lat: 49.277838, lng: 20.999867 },
    { lat: 49.277802, lng: 21.000188 },
    { lat: 49.277708, lng: 21.000508 },
    { lat: 49.277802, lng: 21.000734 },
    { lat: 49.27772, lng: 21.001065 },
    { lat: 49.277512, lng: 21.001937 },
    { lat: 49.277511, lng: 21.002168 },
    { lat: 49.277487, lng: 21.002858 },
    { lat: 49.277482, lng: 21.003058 },
    { lat: 49.27747, lng: 21.004069 },
    { lat: 49.277415, lng: 21.004869 },
    { lat: 49.277415, lng: 21.004962 },
    { lat: 49.2810688, lng: 20.9972969 },
    { lat: 49.2811312, lng: 20.996285 },
    { lat: 49.2811122, lng: 20.9956712 },
    { lat: 49.2818722, lng: 20.9950986 },
    { lat: 49.2819701, lng: 20.9951001 },
    { lat: 49.2821565, lng: 20.9949474 },
    { lat: 49.2822374, lng: 20.9947026 },
    { lat: 49.285129, lng: 20.9741746 },
    { lat: 49.2907207, lng: 20.9753656 },
    { lat: 49.2963011, lng: 20.9692782 },
    { lat: 49.2965974, lng: 20.9690166 },
    { lat: 49.2968642, lng: 20.969084 },
    { lat: 49.2970747, lng: 20.9692239 },
    { lat: 49.2975655, lng: 20.9698409 },
    { lat: 49.2977947, lng: 20.9698661 },
    { lat: 49.2979117, lng: 20.9697965 },
    { lat: 49.2979679, lng: 20.969622 },
    { lat: 49.2981255, lng: 20.9695218 },
    { lat: 49.2981358, lng: 20.969428 },
    { lat: 49.2984674, lng: 20.969106 },
    { lat: 49.298801, lng: 20.9688823 },
    { lat: 49.2990376, lng: 20.9688576 },
    { lat: 49.2997939, lng: 20.9690275 },
    { lat: 49.3006583, lng: 20.9686244 },
    { lat: 49.3008311, lng: 20.9686453 },
    { lat: 49.3018885, lng: 20.9703016 },
    { lat: 49.3022715, lng: 20.9698667 },
    { lat: 49.3026827, lng: 20.9696781 },
    { lat: 49.3034841, lng: 20.9690656 },
    { lat: 49.3037507, lng: 20.9687198 },
    { lat: 49.3039653, lng: 20.9682986 },
    { lat: 49.3040489, lng: 20.9680018 },
    { lat: 49.3042874, lng: 20.9676625 },
    { lat: 49.3044607, lng: 20.9675907 },
    { lat: 49.3054245, lng: 20.9675751 },
    { lat: 49.3058118, lng: 20.9673428 },
    { lat: 49.30608, lng: 20.9670809 },
    { lat: 49.306796, lng: 20.9659606 },
    { lat: 49.3072643, lng: 20.9653507 },
    { lat: 49.307623, lng: 20.9650384 },
    { lat: 49.3077442, lng: 20.9647495 },
    { lat: 49.3079706, lng: 20.9646577 },
    { lat: 49.308003, lng: 20.964511 },
    { lat: 49.3082647, lng: 20.9642596 },
    { lat: 49.3082581, lng: 20.9640242 },
    { lat: 49.3081669, lng: 20.9637196 },
    { lat: 49.3080231, lng: 20.9635836 },
    { lat: 49.3079772, lng: 20.9633592 },
    { lat: 49.3077651, lng: 20.9630359 },
    { lat: 49.3076799, lng: 20.9626317 },
    { lat: 49.3077094, lng: 20.962642 },
    { lat: 49.30774, lng: 20.962492 },
    { lat: 49.3075534, lng: 20.9624808 },
    { lat: 49.307323, lng: 20.962566 },
    { lat: 49.307287, lng: 20.962573 },
    { lat: 49.30719, lng: 20.962558 },
    { lat: 49.307173, lng: 20.96255 },
    { lat: 49.306987, lng: 20.962446 },
    { lat: 49.306978, lng: 20.96244 },
    { lat: 49.306759, lng: 20.962237 },
    { lat: 49.306753, lng: 20.962236 },
    { lat: 49.306491, lng: 20.962183 },
    { lat: 49.306481, lng: 20.962179 },
    { lat: 49.306224, lng: 20.961877 },
    { lat: 49.306222, lng: 20.961874 },
    { lat: 49.306049, lng: 20.961403 },
    { lat: 49.306048, lng: 20.961396 },
    { lat: 49.305866, lng: 20.960671 },
    { lat: 49.305816, lng: 20.960391 },
    { lat: 49.30584, lng: 20.960001 },
    { lat: 49.305841, lng: 20.959993 },
    { lat: 49.305885, lng: 20.959419 },
    { lat: 49.30589, lng: 20.959401 },
    { lat: 49.305951, lng: 20.95929 },
    { lat: 49.305957, lng: 20.95928 },
    { lat: 49.305904, lng: 20.95916 },
    { lat: 49.305894, lng: 20.959138 },
    { lat: 49.305958, lng: 20.958772 },
    { lat: 49.305962, lng: 20.958753 },
    { lat: 49.306009, lng: 20.958595 },
    { lat: 49.306013, lng: 20.958581 },
    { lat: 49.305917, lng: 20.958376 },
    { lat: 49.305894, lng: 20.958182 },
    { lat: 49.305889, lng: 20.958169 },
    { lat: 49.305879, lng: 20.95813 },
    { lat: 49.305871, lng: 20.958115 },
    { lat: 49.305709, lng: 20.95796 },
    { lat: 49.305707, lng: 20.957954 },
    { lat: 49.305653, lng: 20.957729 },
    { lat: 49.305647, lng: 20.957711 },
    { lat: 49.305461, lng: 20.957472 },
    { lat: 49.305165, lng: 20.957102 },
    { lat: 49.305159, lng: 20.957097 },
    { lat: 49.304989, lng: 20.956946 },
    { lat: 49.304984, lng: 20.956944 },
    { lat: 49.304875, lng: 20.95691 },
    { lat: 49.304872, lng: 20.956908 },
    { lat: 49.304719, lng: 20.956907 },
    { lat: 49.304713, lng: 20.956907 },
    { lat: 49.30464, lng: 20.956846 },
    { lat: 49.304646, lng: 20.956776 },
    { lat: 49.304548, lng: 20.956595 },
    { lat: 49.30447, lng: 20.956505 },
    { lat: 49.304454, lng: 20.956496 },
    { lat: 49.304439, lng: 20.956354 },
    { lat: 49.304432, lng: 20.956349 },
    { lat: 49.304276, lng: 20.95624 },
    { lat: 49.304267, lng: 20.956234 },
    { lat: 49.304187, lng: 20.956031 },
    { lat: 49.304184, lng: 20.956023 },
    { lat: 49.304115, lng: 20.955742 },
    { lat: 49.304114, lng: 20.955735 },
    { lat: 49.303979, lng: 20.955324 },
    { lat: 49.303977, lng: 20.955318 },
    { lat: 49.303912, lng: 20.955074 },
    { lat: 49.30391, lng: 20.955066 },
    { lat: 49.303778, lng: 20.954908 },
    { lat: 49.303773, lng: 20.954898 },
    { lat: 49.303591, lng: 20.954521 },
    { lat: 49.303588, lng: 20.954513 },
    { lat: 49.303498, lng: 20.954175 },
    { lat: 49.303427, lng: 20.953767 },
    { lat: 49.303423, lng: 20.953749 },
    { lat: 49.303339, lng: 20.953445 },
    { lat: 49.303133, lng: 20.953031 },
    { lat: 49.303047, lng: 20.952884 },
    { lat: 49.302929, lng: 20.952653 },
    { lat: 49.302795, lng: 20.95206 },
    { lat: 49.302739, lng: 20.951699 },
    { lat: 49.3027, lng: 20.951473 },
    { lat: 49.30256, lng: 20.951105 },
    { lat: 49.302405, lng: 20.950671 },
    { lat: 49.302394, lng: 20.950645 },
    { lat: 49.302282, lng: 20.950402 },
    { lat: 49.30212, lng: 20.949965 },
    { lat: 49.302114, lng: 20.949946 },
    { lat: 49.302143, lng: 20.949606 },
    { lat: 49.302142, lng: 20.949579 },
    { lat: 49.30203, lng: 20.94934 },
    { lat: 49.302022, lng: 20.949321 },
    { lat: 49.302035, lng: 20.949189 },
    { lat: 49.302032, lng: 20.949183 },
    { lat: 49.301919, lng: 20.948995 },
    { lat: 49.301922, lng: 20.948965 },
    { lat: 49.302054, lng: 20.948456 },
    { lat: 49.302073, lng: 20.948377 },
    { lat: 49.302155, lng: 20.948056 },
    { lat: 49.30215, lng: 20.947729 },
    { lat: 49.30215, lng: 20.947713 },
    { lat: 49.302143, lng: 20.947229 },
    { lat: 49.302016, lng: 20.946799 },
    { lat: 49.302013, lng: 20.946772 },
    { lat: 49.302041, lng: 20.946663 },
    { lat: 49.302046, lng: 20.946619 },
    { lat: 49.302052, lng: 20.946444 },
    { lat: 49.302056, lng: 20.946424 },
    { lat: 49.302109, lng: 20.946284 },
    { lat: 49.30211, lng: 20.946247 },
    { lat: 49.302047, lng: 20.945909 },
    { lat: 49.302047, lng: 20.945896 },
    { lat: 49.302022, lng: 20.945623 },
    { lat: 49.30202, lng: 20.945616 },
    { lat: 49.302055, lng: 20.945261 },
    { lat: 49.302051, lng: 20.945251 },
    { lat: 49.302, lng: 20.945112 },
    { lat: 49.301984, lng: 20.945119 },
    { lat: 49.301855, lng: 20.945175 },
    { lat: 49.301845, lng: 20.945179 },
    { lat: 49.301776, lng: 20.945079 },
    { lat: 49.301679, lng: 20.944742 },
    { lat: 49.301674, lng: 20.94472 },
    { lat: 49.301736, lng: 20.944227 },
    { lat: 49.301733, lng: 20.944186 },
    { lat: 49.301666, lng: 20.943977 },
    { lat: 49.301632, lng: 20.943763 },
    { lat: 49.301576, lng: 20.943579 },
    { lat: 49.301529, lng: 20.943422 },
    { lat: 49.301527, lng: 20.943417 },
    { lat: 49.301404, lng: 20.943208 },
    { lat: 49.301398, lng: 20.943199 },
    { lat: 49.301139, lng: 20.942837 },
    { lat: 49.300966, lng: 20.942621 },
    { lat: 49.30096, lng: 20.942615 },
    { lat: 49.300811, lng: 20.942604 },
    { lat: 49.300565, lng: 20.94248 },
    { lat: 49.300553, lng: 20.942469 },
    { lat: 49.300448, lng: 20.942338 },
    { lat: 49.300444, lng: 20.942333 },
    { lat: 49.300359, lng: 20.941987 },
    { lat: 49.300351, lng: 20.941979 },
    { lat: 49.300268, lng: 20.9419 },
    { lat: 49.300238, lng: 20.941851 },
    { lat: 49.300112, lng: 20.941578 },
    { lat: 49.300107, lng: 20.941564 },
    { lat: 49.300098, lng: 20.9414 },
    { lat: 49.299941, lng: 20.941142 },
    { lat: 49.299935, lng: 20.94113 },
    { lat: 49.299824, lng: 20.940885 },
    { lat: 49.299818, lng: 20.940869 },
    { lat: 49.299761, lng: 20.940576 },
    { lat: 49.299754, lng: 20.940573 },
    { lat: 49.299578, lng: 20.940503 },
    { lat: 49.299569, lng: 20.940499 },
    { lat: 49.299447, lng: 20.940425 },
    { lat: 49.299438, lng: 20.94042 },
    { lat: 49.2994007, lng: 20.9403936 },
    { lat: 49.299228, lng: 20.940271 },
    { lat: 49.299192, lng: 20.940212 },
    { lat: 49.299189, lng: 20.940206 },
    { lat: 49.299154, lng: 20.940093 },
    { lat: 49.299097, lng: 20.939584 },
    { lat: 49.299045, lng: 20.939368 },
    { lat: 49.298991, lng: 20.939224 },
    { lat: 49.298988, lng: 20.939214 },
    { lat: 49.298948, lng: 20.938875 },
    { lat: 49.298947, lng: 20.938868 },
    { lat: 49.298976, lng: 20.938579 },
    { lat: 49.298977, lng: 20.938546 },
    { lat: 49.298975, lng: 20.938457 },
    { lat: 49.298962, lng: 20.938366 },
    { lat: 49.298954, lng: 20.938357 },
    { lat: 49.298896, lng: 20.938291 },
    { lat: 49.298886, lng: 20.93828 },
    { lat: 49.298826, lng: 20.938251 },
    { lat: 49.298787, lng: 20.938219 },
    { lat: 49.298658, lng: 20.937958 },
    { lat: 49.298549, lng: 20.937831 },
    { lat: 49.298542, lng: 20.937824 },
    { lat: 49.298378, lng: 20.937642 },
    { lat: 49.298263, lng: 20.937416 },
    { lat: 49.298261, lng: 20.937405 },
    { lat: 49.298245, lng: 20.937208 },
    { lat: 49.298245, lng: 20.93719 },
    { lat: 49.298268, lng: 20.937012 },
    { lat: 49.298268, lng: 20.937005 },
    { lat: 49.298286, lng: 20.936773 },
    { lat: 49.298251, lng: 20.936632 },
    { lat: 49.298193, lng: 20.936504 },
    { lat: 49.298155, lng: 20.936368 },
    { lat: 49.298144, lng: 20.936213 },
    { lat: 49.298145, lng: 20.936203 },
    { lat: 49.298166, lng: 20.93606 },
    { lat: 49.298168, lng: 20.936044 },
    { lat: 49.298176, lng: 20.935962 },
    { lat: 49.298156, lng: 20.935742 },
    { lat: 49.298155, lng: 20.935724 },
    { lat: 49.298161, lng: 20.935481 },
    { lat: 49.298162, lng: 20.935465 },
    { lat: 49.298192, lng: 20.935144 },
    { lat: 49.298192, lng: 20.93513 },
    { lat: 49.298168, lng: 20.934963 },
    { lat: 49.298147, lng: 20.934896 },
    { lat: 49.298139, lng: 20.934883 },
    { lat: 49.298108, lng: 20.934827 },
    { lat: 49.298094, lng: 20.934802 },
    { lat: 49.298001, lng: 20.93463 },
    { lat: 49.297947, lng: 20.934485 },
    { lat: 49.297902, lng: 20.93435 },
    { lat: 49.297883, lng: 20.934248 },
    { lat: 49.297879, lng: 20.934207 },
    { lat: 49.297878, lng: 20.934178 },
    { lat: 49.297879, lng: 20.934168 },
    { lat: 49.297883, lng: 20.934065 },
    { lat: 49.297884, lng: 20.934049 },
    { lat: 49.297912, lng: 20.933971 },
    { lat: 49.29792, lng: 20.933954 },
    { lat: 49.297961, lng: 20.933908 },
    { lat: 49.297994, lng: 20.933879 },
    { lat: 49.298094, lng: 20.933819 },
    { lat: 49.298115, lng: 20.93379 },
    { lat: 49.298118, lng: 20.933785 },
    { lat: 49.298131, lng: 20.933701 },
    { lat: 49.298079, lng: 20.933544 },
    { lat: 49.298074, lng: 20.933529 },
    { lat: 49.298161, lng: 20.933379 },
    { lat: 49.298192, lng: 20.93325 },
    { lat: 49.298186, lng: 20.933222 },
    { lat: 49.298166, lng: 20.933151 },
    { lat: 49.298159, lng: 20.933139 },
    { lat: 49.298058, lng: 20.932974 },
    { lat: 49.297951, lng: 20.932707 },
    { lat: 49.297938, lng: 20.93268 },
    { lat: 49.297826, lng: 20.932497 },
    { lat: 49.297746, lng: 20.932335 },
    { lat: 49.297681, lng: 20.932231 },
    { lat: 49.297559, lng: 20.932075 },
    { lat: 49.297548, lng: 20.932065 },
    { lat: 49.297465, lng: 20.932002 },
    { lat: 49.297459, lng: 20.931997 },
    { lat: 49.297278, lng: 20.931938 },
    { lat: 49.297188, lng: 20.931877 },
    { lat: 49.297077, lng: 20.931671 },
    { lat: 49.2964658, lng: 20.9322141 },
    { lat: 49.2968429, lng: 20.9340399 },
    { lat: 49.2969428, lng: 20.9347119 },
    { lat: 49.2969609, lng: 20.935185 },
    { lat: 49.2959967, lng: 20.9363778 },
    { lat: 49.2960059, lng: 20.9369237 },
    { lat: 49.2956845, lng: 20.9371074 },
    { lat: 49.2958804, lng: 20.9382612 },
    { lat: 49.2955343, lng: 20.938279 },
    { lat: 49.2954426, lng: 20.9400898 },
    { lat: 49.2944606, lng: 20.9410174 },
    { lat: 49.2945521, lng: 20.9413027 },
    { lat: 49.293417, lng: 20.9425616 },
    { lat: 49.2931897, lng: 20.9427347 },
    { lat: 49.2931804, lng: 20.9428316 },
    { lat: 49.2926956, lng: 20.9430395 },
    { lat: 49.2926097, lng: 20.943199 },
    { lat: 49.2926548, lng: 20.9434 },
    { lat: 49.292033, lng: 20.9440976 },
    { lat: 49.2911579, lng: 20.9449587 },
    { lat: 49.2906673, lng: 20.9449276 },
    { lat: 49.2895745, lng: 20.9451277 },
    { lat: 49.2887884, lng: 20.9454804 },
    { lat: 49.2877838, lng: 20.9461683 },
    { lat: 49.2869345, lng: 20.9472689 },
    { lat: 49.2861185, lng: 20.9476372 },
    { lat: 49.2854795, lng: 20.9494077 },
    { lat: 49.2852772, lng: 20.9498567 },
    { lat: 49.2851178, lng: 20.9529596 },
    { lat: 49.2847722, lng: 20.9554327 },
    { lat: 49.2843118, lng: 20.9575404 },
    { lat: 49.284439, lng: 20.9587328 },
    { lat: 49.2849194, lng: 20.9595454 },
    { lat: 49.285254, lng: 20.9603364 },
    { lat: 49.2852386, lng: 20.9615052 },
    { lat: 49.2847224, lng: 20.9624406 },
    { lat: 49.2831965, lng: 20.9642123 },
    { lat: 49.2815886, lng: 20.9666395 },
    { lat: 49.2802378, lng: 20.9691815 },
    { lat: 49.2800455, lng: 20.9699075 },
    { lat: 49.2800085, lng: 20.970486 },
    { lat: 49.2796948, lng: 20.9724094 },
    { lat: 49.2791325, lng: 20.9737497 },
    { lat: 49.2788349, lng: 20.9758531 },
    { lat: 49.2790429, lng: 20.977351 },
    { lat: 49.2796829, lng: 20.9777162 },
    { lat: 49.2796081, lng: 20.979685 },
    { lat: 49.2792833, lng: 20.9809283 },
    { lat: 49.2792289, lng: 20.982251 },
    { lat: 49.2779114, lng: 20.983701 },
    { lat: 49.2773802, lng: 20.9845499 },
    { lat: 49.2776881, lng: 20.9869354 },
    { lat: 49.2781849, lng: 20.9890754 },
    { lat: 49.2779107, lng: 20.9906993 },
    { lat: 49.2777103, lng: 20.9902494 },
    { lat: 49.277018, lng: 20.990024 },
  ],
  Hniezdne: [
    { lat: 49.279536, lng: 20.6591508 },
    { lat: 49.2796034, lng: 20.6592418 },
    { lat: 49.2802953, lng: 20.660118 },
    { lat: 49.2810102, lng: 20.6613788 },
    { lat: 49.2816874, lng: 20.662002 },
    { lat: 49.2817869, lng: 20.662492 },
    { lat: 49.2839559, lng: 20.6632498 },
    { lat: 49.2854881, lng: 20.6637851 },
    { lat: 49.2870797, lng: 20.6651127 },
    { lat: 49.287813, lng: 20.6663912 },
    { lat: 49.2899874, lng: 20.6667008 },
    { lat: 49.2921083, lng: 20.6658561 },
    { lat: 49.2969991, lng: 20.6658559 },
    { lat: 49.2986379, lng: 20.6660719 },
    { lat: 49.2994259, lng: 20.6654429 },
    { lat: 49.2997666, lng: 20.6656501 },
    { lat: 49.302823, lng: 20.6643363 },
    { lat: 49.3028841, lng: 20.6645029 },
    { lat: 49.3032987, lng: 20.6642286 },
    { lat: 49.3034845, lng: 20.6641316 },
    { lat: 49.3048592, lng: 20.663414 },
    { lat: 49.3049734, lng: 20.6634016 },
    { lat: 49.3050536, lng: 20.6636279 },
    { lat: 49.3055931, lng: 20.6628139 },
    { lat: 49.3057991, lng: 20.6626565 },
    { lat: 49.306611, lng: 20.6613999 },
    { lat: 49.3072615, lng: 20.6607696 },
    { lat: 49.3085812, lng: 20.6592343 },
    { lat: 49.3086541, lng: 20.6587177 },
    { lat: 49.3088549, lng: 20.6582569 },
    { lat: 49.3092861, lng: 20.6577545 },
    { lat: 49.3089577, lng: 20.6557758 },
    { lat: 49.3091216, lng: 20.6553508 },
    { lat: 49.3094523, lng: 20.6550336 },
    { lat: 49.309408, lng: 20.6549871 },
    { lat: 49.3094492, lng: 20.6545356 },
    { lat: 49.3098083, lng: 20.6544075 },
    { lat: 49.3098185, lng: 20.6539666 },
    { lat: 49.3099302, lng: 20.6538501 },
    { lat: 49.3102268, lng: 20.6537413 },
    { lat: 49.3105031, lng: 20.6534732 },
    { lat: 49.3104791, lng: 20.6533364 },
    { lat: 49.3103153, lng: 20.6531271 },
    { lat: 49.3103296, lng: 20.6527695 },
    { lat: 49.3104301, lng: 20.6523992 },
    { lat: 49.3107663, lng: 20.6522016 },
    { lat: 49.3112306, lng: 20.651683 },
    { lat: 49.3112729, lng: 20.6505968 },
    { lat: 49.3113614, lng: 20.6502997 },
    { lat: 49.3116181, lng: 20.6497324 },
    { lat: 49.3120186, lng: 20.6495088 },
    { lat: 49.3122555, lng: 20.6491477 },
    { lat: 49.312453, lng: 20.6493809 },
    { lat: 49.3127432, lng: 20.6495944 },
    { lat: 49.3131346, lng: 20.6492395 },
    { lat: 49.3131653, lng: 20.6490901 },
    { lat: 49.3132983, lng: 20.6489026 },
    { lat: 49.3136828, lng: 20.6490826 },
    { lat: 49.313794, lng: 20.6490123 },
    { lat: 49.3140586, lng: 20.6486027 },
    { lat: 49.3146495, lng: 20.6481607 },
    { lat: 49.3155703, lng: 20.647815 },
    { lat: 49.315751, lng: 20.6479626 },
    { lat: 49.3163117, lng: 20.6480867 },
    { lat: 49.3166279, lng: 20.6483359 },
    { lat: 49.3171307, lng: 20.6483267 },
    { lat: 49.3185052, lng: 20.6477023 },
    { lat: 49.318815, lng: 20.6474874 },
    { lat: 49.3189139, lng: 20.6471969 },
    { lat: 49.3192213, lng: 20.6470154 },
    { lat: 49.3194462, lng: 20.6470095 },
    { lat: 49.3198454, lng: 20.6472785 },
    { lat: 49.3201473, lng: 20.6473164 },
    { lat: 49.3207732, lng: 20.6478541 },
    { lat: 49.3215121, lng: 20.648309 },
    { lat: 49.3217096, lng: 20.6481994 },
    { lat: 49.3218554, lng: 20.6480277 },
    { lat: 49.3221453, lng: 20.6479158 },
    { lat: 49.3230192, lng: 20.6478355 },
    { lat: 49.323679, lng: 20.6481863 },
    { lat: 49.3239563, lng: 20.6482573 },
    { lat: 49.3244062, lng: 20.6483124 },
    { lat: 49.3253457, lng: 20.6482425 },
    { lat: 49.325586, lng: 20.6483191 },
    { lat: 49.3258135, lng: 20.6481056 },
    { lat: 49.3258194, lng: 20.6478601 },
    { lat: 49.3258621, lng: 20.6477944 },
    { lat: 49.3261168, lng: 20.6477074 },
    { lat: 49.3261208, lng: 20.6475934 },
    { lat: 49.326004, lng: 20.647382 },
    { lat: 49.3260577, lng: 20.6473739 },
    { lat: 49.3275955, lng: 20.6479461 },
    { lat: 49.3277474, lng: 20.6468428 },
    { lat: 49.3278245, lng: 20.6462618 },
    { lat: 49.3271923, lng: 20.6438437 },
    { lat: 49.3273128, lng: 20.6429978 },
    { lat: 49.3273762, lng: 20.6429117 },
    { lat: 49.3271742, lng: 20.6424598 },
    { lat: 49.3269627, lng: 20.6412354 },
    { lat: 49.3268549, lng: 20.6402336 },
    { lat: 49.3269455, lng: 20.6396222 },
    { lat: 49.3269166, lng: 20.6389382 },
    { lat: 49.3264313, lng: 20.6376166 },
    { lat: 49.3260574, lng: 20.6362264 },
    { lat: 49.3257639, lng: 20.6353232 },
    { lat: 49.3246722, lng: 20.6327816 },
    { lat: 49.3240746, lng: 20.6315604 },
    { lat: 49.3240505, lng: 20.630797 },
    { lat: 49.3237865, lng: 20.6307485 },
    { lat: 49.3233144, lng: 20.6309241 },
    { lat: 49.323248, lng: 20.6316164 },
    { lat: 49.3231529, lng: 20.631663 },
    { lat: 49.3224584, lng: 20.6315088 },
    { lat: 49.3214817, lng: 20.6317669 },
    { lat: 49.3214695, lng: 20.6313612 },
    { lat: 49.3205342, lng: 20.6289792 },
    { lat: 49.3199035, lng: 20.627626 },
    { lat: 49.3199931, lng: 20.6265157 },
    { lat: 49.3200557, lng: 20.6263477 },
    { lat: 49.3200226, lng: 20.6263314 },
    { lat: 49.3199836, lng: 20.6256848 },
    { lat: 49.3197319, lng: 20.6256078 },
    { lat: 49.3197578, lng: 20.6255126 },
    { lat: 49.3201623, lng: 20.6252379 },
    { lat: 49.3201, lng: 20.625005 },
    { lat: 49.320107, lng: 20.6246111 },
    { lat: 49.3204071, lng: 20.6246244 },
    { lat: 49.3204768, lng: 20.6245305 },
    { lat: 49.3204382, lng: 20.6243018 },
    { lat: 49.3201839, lng: 20.6239941 },
    { lat: 49.3202417, lng: 20.6234097 },
    { lat: 49.3205327, lng: 20.6234264 },
    { lat: 49.32059, lng: 20.6231383 },
    { lat: 49.3205434, lng: 20.6227532 },
    { lat: 49.3208693, lng: 20.6223307 },
    { lat: 49.3208344, lng: 20.6219832 },
    { lat: 49.320555, lng: 20.6219786 },
    { lat: 49.320501, lng: 20.6218825 },
    { lat: 49.3205191, lng: 20.6215721 },
    { lat: 49.3207157, lng: 20.6215014 },
    { lat: 49.3207535, lng: 20.6214246 },
    { lat: 49.3206891, lng: 20.6211045 },
    { lat: 49.3207351, lng: 20.6210068 },
    { lat: 49.3209206, lng: 20.6210616 },
    { lat: 49.3209843, lng: 20.6207661 },
    { lat: 49.3211461, lng: 20.6203516 },
    { lat: 49.3211642, lng: 20.6197922 },
    { lat: 49.3210354, lng: 20.6194068 },
    { lat: 49.3210732, lng: 20.6192294 },
    { lat: 49.3209894, lng: 20.6189979 },
    { lat: 49.3209961, lng: 20.6188115 },
    { lat: 49.320845, lng: 20.6184923 },
    { lat: 49.3208597, lng: 20.6181143 },
    { lat: 49.3207424, lng: 20.6176738 },
    { lat: 49.3207178, lng: 20.6171805 },
    { lat: 49.320484, lng: 20.616341 },
    { lat: 49.3203746, lng: 20.6162441 },
    { lat: 49.3202658, lng: 20.6156915 },
    { lat: 49.3197425, lng: 20.6144102 },
    { lat: 49.3197693, lng: 20.6139028 },
    { lat: 49.3196428, lng: 20.6135804 },
    { lat: 49.3195823, lng: 20.6135252 },
    { lat: 49.3194863, lng: 20.6135517 },
    { lat: 49.3194528, lng: 20.6134344 },
    { lat: 49.3193742, lng: 20.6134067 },
    { lat: 49.3193669, lng: 20.6131378 },
    { lat: 49.319429, lng: 20.6128067 },
    { lat: 49.3193845, lng: 20.6122267 },
    { lat: 49.3193026, lng: 20.612042 },
    { lat: 49.319274, lng: 20.6115941 },
    { lat: 49.3193754, lng: 20.6113773 },
    { lat: 49.3194485, lng: 20.6106892 },
    { lat: 49.3193497, lng: 20.6101949 },
    { lat: 49.3188138, lng: 20.6095411 },
    { lat: 49.318896, lng: 20.6086678 },
    { lat: 49.3187553, lng: 20.6082897 },
    { lat: 49.3187474, lng: 20.607822 },
    { lat: 49.3186355, lng: 20.6077163 },
    { lat: 49.3185592, lng: 20.6075041 },
    { lat: 49.318616, lng: 20.6072406 },
    { lat: 49.3187409, lng: 20.6071135 },
    { lat: 49.3187649, lng: 20.6069949 },
    { lat: 49.3184741, lng: 20.6065832 },
    { lat: 49.3185155, lng: 20.6061359 },
    { lat: 49.3184642, lng: 20.6058389 },
    { lat: 49.3185327, lng: 20.6053973 },
    { lat: 49.3186434, lng: 20.6050992 },
    { lat: 49.3185945, lng: 20.604958 },
    { lat: 49.3187122, lng: 20.6045414 },
    { lat: 49.3186159, lng: 20.604368 },
    { lat: 49.3186088, lng: 20.6037339 },
    { lat: 49.3186651, lng: 20.6036415 },
    { lat: 49.3187417, lng: 20.6030522 },
    { lat: 49.318677, lng: 20.6025673 },
    { lat: 49.3187377, lng: 20.60238 },
    { lat: 49.3184448, lng: 20.6028695 },
    { lat: 49.3177846, lng: 20.6031338 },
    { lat: 49.3171161, lng: 20.6032618 },
    { lat: 49.315195, lng: 20.6032405 },
    { lat: 49.3149329, lng: 20.6026604 },
    { lat: 49.3142458, lng: 20.6024562 },
    { lat: 49.3138037, lng: 20.6024373 },
    { lat: 49.3127435, lng: 20.6029364 },
    { lat: 49.3105415, lng: 20.6034313 },
    { lat: 49.3104927, lng: 20.6032016 },
    { lat: 49.3092142, lng: 20.6033987 },
    { lat: 49.3075342, lng: 20.6031301 },
    { lat: 49.3072, lng: 20.6028889 },
    { lat: 49.3065415, lng: 20.6017036 },
    { lat: 49.3059079, lng: 20.60233 },
    { lat: 49.3056896, lng: 20.6020526 },
    { lat: 49.3050035, lng: 20.6024403 },
    { lat: 49.3045429, lng: 20.6030568 },
    { lat: 49.3038465, lng: 20.6037274 },
    { lat: 49.3035123, lng: 20.6038844 },
    { lat: 49.3024849, lng: 20.6041097 },
    { lat: 49.3012503, lng: 20.6041155 },
    { lat: 49.3009076, lng: 20.6045434 },
    { lat: 49.300698, lng: 20.6055956 },
    { lat: 49.300392, lng: 20.6058512 },
    { lat: 49.3001089, lng: 20.6058632 },
    { lat: 49.3004291, lng: 20.6074737 },
    { lat: 49.3007787, lng: 20.6100427 },
    { lat: 49.300464, lng: 20.6102435 },
    { lat: 49.2998994, lng: 20.6104487 },
    { lat: 49.3003763, lng: 20.6123703 },
    { lat: 49.3005427, lng: 20.6145205 },
    { lat: 49.2996485, lng: 20.6154456 },
    { lat: 49.2991512, lng: 20.6163287 },
    { lat: 49.2989975, lng: 20.6164996 },
    { lat: 49.2990766, lng: 20.6170729 },
    { lat: 49.3001026, lng: 20.6185573 },
    { lat: 49.2995594, lng: 20.619037 },
    { lat: 49.2962186, lng: 20.6209826 },
    { lat: 49.296289, lng: 20.6216872 },
    { lat: 49.2956669, lng: 20.6218578 },
    { lat: 49.2955909, lng: 20.6213362 },
    { lat: 49.295562, lng: 20.6214115 },
    { lat: 49.2943682, lng: 20.6220489 },
    { lat: 49.2931595, lng: 20.6228419 },
    { lat: 49.2897199, lng: 20.6240095 },
    { lat: 49.2852552, lng: 20.6237227 },
    { lat: 49.2817724, lng: 20.6221228 },
    { lat: 49.279925, lng: 20.6218685 },
    { lat: 49.2793662, lng: 20.6219793 },
    { lat: 49.2786938, lng: 20.6218112 },
    { lat: 49.2785882, lng: 20.6205037 },
    { lat: 49.277504, lng: 20.6198454 },
    { lat: 49.2769792, lng: 20.6201469 },
    { lat: 49.2760015, lng: 20.6197882 },
    { lat: 49.2752707, lng: 20.6190007 },
    { lat: 49.2734845, lng: 20.6173481 },
    { lat: 49.2720934, lng: 20.6165473 },
    { lat: 49.2700455, lng: 20.6136311 },
    { lat: 49.2685058, lng: 20.6122356 },
    { lat: 49.2671982, lng: 20.611906 },
    { lat: 49.2664122, lng: 20.6107741 },
    { lat: 49.2660264, lng: 20.6098755 },
    { lat: 49.2657759, lng: 20.6086758 },
    { lat: 49.2654672, lng: 20.6080155 },
    { lat: 49.2651696, lng: 20.6044556 },
    { lat: 49.2651635, lng: 20.6043779 },
    { lat: 49.2649269, lng: 20.6044628 },
    { lat: 49.2646293, lng: 20.6044522 },
    { lat: 49.2643224, lng: 20.6042157 },
    { lat: 49.2633299, lng: 20.60463 },
    { lat: 49.2632408, lng: 20.6049252 },
    { lat: 49.2610706, lng: 20.605185 },
    { lat: 49.2624527, lng: 20.6082949 },
    { lat: 49.2631045, lng: 20.6091717 },
    { lat: 49.2632972, lng: 20.6095512 },
    { lat: 49.2637343, lng: 20.6108116 },
    { lat: 49.2640216, lng: 20.6145572 },
    { lat: 49.266149, lng: 20.615432 },
    { lat: 49.2673521, lng: 20.614519 },
    { lat: 49.268217, lng: 20.6182743 },
    { lat: 49.2689641, lng: 20.6207383 },
    { lat: 49.2701685, lng: 20.6219236 },
    { lat: 49.2703684, lng: 20.6225366 },
    { lat: 49.2708547, lng: 20.6246965 },
    { lat: 49.2742695, lng: 20.6288766 },
    { lat: 49.2742628, lng: 20.6306314 },
    { lat: 49.274445, lng: 20.6309435 },
    { lat: 49.2747133, lng: 20.6315767 },
    { lat: 49.2750106, lng: 20.633217 },
    { lat: 49.2748354, lng: 20.6373354 },
    { lat: 49.2785249, lng: 20.6414378 },
    { lat: 49.2781457, lng: 20.6449999 },
    { lat: 49.2777347, lng: 20.6461558 },
    { lat: 49.2780588, lng: 20.6487682 },
    { lat: 49.2788119, lng: 20.650186 },
    { lat: 49.2790801, lng: 20.6508861 },
    { lat: 49.2797149, lng: 20.6586773 },
    { lat: 49.279536, lng: 20.6591508 },
  ],
  Plaveč: [
    { lat: 49.2666013, lng: 20.8080134 },
    { lat: 49.2665493, lng: 20.8084958 },
    { lat: 49.2648742, lng: 20.8087464 },
    { lat: 49.2644873, lng: 20.8076473 },
    { lat: 49.2644056, lng: 20.8067104 },
    { lat: 49.264251, lng: 20.8059739 },
    { lat: 49.2639084, lng: 20.806458 },
    { lat: 49.2637813, lng: 20.8062322 },
    { lat: 49.2631216, lng: 20.8055994 },
    { lat: 49.2616567, lng: 20.8059773 },
    { lat: 49.2611101, lng: 20.8055632 },
    { lat: 49.259791, lng: 20.8055337 },
    { lat: 49.2590511, lng: 20.8059441 },
    { lat: 49.2568853, lng: 20.8064932 },
    { lat: 49.2563099, lng: 20.8069758 },
    { lat: 49.2556802, lng: 20.8069903 },
    { lat: 49.2548148, lng: 20.8063727 },
    { lat: 49.2550539, lng: 20.8052747 },
    { lat: 49.2544571, lng: 20.8041891 },
    { lat: 49.2537745, lng: 20.8044342 },
    { lat: 49.2523699, lng: 20.8059749 },
    { lat: 49.2505624, lng: 20.8089847 },
    { lat: 49.2495109, lng: 20.8119589 },
    { lat: 49.2495003, lng: 20.8133526 },
    { lat: 49.2488883, lng: 20.8142137 },
    { lat: 49.2487249, lng: 20.8145705 },
    { lat: 49.2486774, lng: 20.8148229 },
    { lat: 49.2486351, lng: 20.8177532 },
    { lat: 49.2481987, lng: 20.8188102 },
    { lat: 49.2487141, lng: 20.8194023 },
    { lat: 49.2485909, lng: 20.8241479 },
    { lat: 49.2486209, lng: 20.8255105 },
    { lat: 49.2459925, lng: 20.8294495 },
    { lat: 49.2451233, lng: 20.8309221 },
    { lat: 49.2433848, lng: 20.834389 },
    { lat: 49.2417876, lng: 20.8360991 },
    { lat: 49.2418779, lng: 20.8366312 },
    { lat: 49.2412927, lng: 20.8368626 },
    { lat: 49.241269, lng: 20.8379152 },
    { lat: 49.2408955, lng: 20.8385194 },
    { lat: 49.2402813, lng: 20.8395132 },
    { lat: 49.2399901, lng: 20.8399856 },
    { lat: 49.2381251, lng: 20.8430131 },
    { lat: 49.2378862, lng: 20.8445283 },
    { lat: 49.2381777, lng: 20.8454229 },
    { lat: 49.2385177, lng: 20.8469742 },
    { lat: 49.2399129, lng: 20.8502625 },
    { lat: 49.240234, lng: 20.8512023 },
    { lat: 49.2403643, lng: 20.8532127 },
    { lat: 49.2406188, lng: 20.8553289 },
    { lat: 49.2409331, lng: 20.8571572 },
    { lat: 49.2421919, lng: 20.8609178 },
    { lat: 49.2429765, lng: 20.861785 },
    { lat: 49.2441374, lng: 20.861799 },
    { lat: 49.245345, lng: 20.8613982 },
    { lat: 49.2465544, lng: 20.8612524 },
    { lat: 49.2475921, lng: 20.8614526 },
    { lat: 49.2492807, lng: 20.8615278 },
    { lat: 49.2519429, lng: 20.8628081 },
    { lat: 49.2514948, lng: 20.8639951 },
    { lat: 49.2520588, lng: 20.8650896 },
    { lat: 49.2530308, lng: 20.8673683 },
    { lat: 49.2539635, lng: 20.8678598 },
    { lat: 49.2551403, lng: 20.8682149 },
    { lat: 49.2558211, lng: 20.8699025 },
    { lat: 49.256169, lng: 20.8722303 },
    { lat: 49.2569325, lng: 20.8724132 },
    { lat: 49.2581439, lng: 20.8729888 },
    { lat: 49.2593597, lng: 20.8747614 },
    { lat: 49.2594082, lng: 20.8747299 },
    { lat: 49.2609663, lng: 20.873947 },
    { lat: 49.2607743, lng: 20.873564 },
    { lat: 49.260472, lng: 20.8733079 },
    { lat: 49.2614335, lng: 20.8729235 },
    { lat: 49.2622737, lng: 20.8724049 },
    { lat: 49.2629848, lng: 20.8718676 },
    { lat: 49.2631441, lng: 20.8724158 },
    { lat: 49.2647682, lng: 20.8705238 },
    { lat: 49.2663109, lng: 20.8686248 },
    { lat: 49.2670995, lng: 20.8671803 },
    { lat: 49.2674478, lng: 20.8661015 },
    { lat: 49.2680754, lng: 20.8644599 },
    { lat: 49.2681457, lng: 20.8644118 },
    { lat: 49.2691282, lng: 20.8641131 },
    { lat: 49.2693424, lng: 20.8646494 },
    { lat: 49.2693635, lng: 20.8649104 },
    { lat: 49.2693291, lng: 20.86496 },
    { lat: 49.2696905, lng: 20.8661262 },
    { lat: 49.2698414, lng: 20.8664595 },
    { lat: 49.2695985, lng: 20.8666319 },
    { lat: 49.2695131, lng: 20.8666318 },
    { lat: 49.2693518, lng: 20.8672591 },
    { lat: 49.2697972, lng: 20.8675763 },
    { lat: 49.2701993, lng: 20.8682873 },
    { lat: 49.2705802, lng: 20.8685311 },
    { lat: 49.2707938, lng: 20.8690916 },
    { lat: 49.2708336, lng: 20.8691681 },
    { lat: 49.2715395, lng: 20.8686815 },
    { lat: 49.273172, lng: 20.8667654 },
    { lat: 49.2739213, lng: 20.8663294 },
    { lat: 49.2749021, lng: 20.8659108 },
    { lat: 49.2755734, lng: 20.8654594 },
    { lat: 49.2762064, lng: 20.8656771 },
    { lat: 49.2761397, lng: 20.8655043 },
    { lat: 49.2763373, lng: 20.8642566 },
    { lat: 49.2773619, lng: 20.8636773 },
    { lat: 49.2777866, lng: 20.8636545 },
    { lat: 49.2773741, lng: 20.863437 },
    { lat: 49.2779665, lng: 20.8630181 },
    { lat: 49.2783732, lng: 20.8629941 },
    { lat: 49.2794943, lng: 20.8625337 },
    { lat: 49.2804362, lng: 20.8623985 },
    { lat: 49.2804692, lng: 20.8622102 },
    { lat: 49.2809364, lng: 20.8618509 },
    { lat: 49.2812539, lng: 20.861489 },
    { lat: 49.2815364, lng: 20.8610593 },
    { lat: 49.2809574, lng: 20.8611769 },
    { lat: 49.2805949, lng: 20.8608077 },
    { lat: 49.2799837, lng: 20.8600465 },
    { lat: 49.2791223, lng: 20.858675 },
    { lat: 49.2785386, lng: 20.8581518 },
    { lat: 49.2773802, lng: 20.8566825 },
    { lat: 49.2770663, lng: 20.8561887 },
    { lat: 49.2765834, lng: 20.8556935 },
    { lat: 49.2753323, lng: 20.8546812 },
    { lat: 49.2756275, lng: 20.8522012 },
    { lat: 49.2759631, lng: 20.8514533 },
    { lat: 49.2761564, lng: 20.8503814 },
    { lat: 49.2763632, lng: 20.8500125 },
    { lat: 49.2764845, lng: 20.8494755 },
    { lat: 49.2768187, lng: 20.848879 },
    { lat: 49.2769081, lng: 20.8482977 },
    { lat: 49.2775954, lng: 20.8469637 },
    { lat: 49.2779388, lng: 20.8466019 },
    { lat: 49.2780195, lng: 20.8460014 },
    { lat: 49.2784576, lng: 20.8451829 },
    { lat: 49.2801355, lng: 20.8423691 },
    { lat: 49.2810102, lng: 20.8406235 },
    { lat: 49.280814, lng: 20.8402865 },
    { lat: 49.2806796, lng: 20.839476 },
    { lat: 49.2789137, lng: 20.8364456 },
    { lat: 49.2792132, lng: 20.8359192 },
    { lat: 49.2796814, lng: 20.8356872 },
    { lat: 49.280507, lng: 20.8343581 },
    { lat: 49.2807804, lng: 20.8335584 },
    { lat: 49.2812627, lng: 20.8331518 },
    { lat: 49.281089, lng: 20.8324789 },
    { lat: 49.2810239, lng: 20.8322231 },
    { lat: 49.281468, lng: 20.8305589 },
    { lat: 49.2822122, lng: 20.8286259 },
    { lat: 49.2827206, lng: 20.8255616 },
    { lat: 49.2821063, lng: 20.825137 },
    { lat: 49.2810468, lng: 20.823779 },
    { lat: 49.2806375, lng: 20.8236918 },
    { lat: 49.2797535, lng: 20.8229532 },
    { lat: 49.2783549, lng: 20.8211909 },
    { lat: 49.2784344, lng: 20.8208818 },
    { lat: 49.2778801, lng: 20.8196836 },
    { lat: 49.2767706, lng: 20.816216 },
    { lat: 49.2764821, lng: 20.8138442 },
    { lat: 49.276213, lng: 20.8128896 },
    { lat: 49.2767572, lng: 20.8108723 },
    { lat: 49.2758582, lng: 20.811177 },
    { lat: 49.2743938, lng: 20.8114034 },
    { lat: 49.2732136, lng: 20.8107905 },
    { lat: 49.2729366, lng: 20.8086616 },
    { lat: 49.2729478, lng: 20.8082653 },
    { lat: 49.272387, lng: 20.8085832 },
    { lat: 49.2717568, lng: 20.8087809 },
    { lat: 49.2699176, lng: 20.8098314 },
    { lat: 49.2697861, lng: 20.8098348 },
    { lat: 49.2685868, lng: 20.8094917 },
    { lat: 49.2685274, lng: 20.8093674 },
    { lat: 49.267186, lng: 20.8089457 },
    { lat: 49.2666013, lng: 20.8080134 },
  ],
  Lesnica: [
    { lat: 49.387614, lng: 20.450726 },
    { lat: 49.3876712, lng: 20.4515276 },
    { lat: 49.3876069, lng: 20.4517952 },
    { lat: 49.3875578, lng: 20.4525653 },
    { lat: 49.38748, lng: 20.4530712 },
    { lat: 49.3873091, lng: 20.4536177 },
    { lat: 49.3869257, lng: 20.4542681 },
    { lat: 49.3868132, lng: 20.4542364 },
    { lat: 49.386638, lng: 20.4553326 },
    { lat: 49.3865281, lng: 20.4564892 },
    { lat: 49.3862522, lng: 20.4573629 },
    { lat: 49.3864553, lng: 20.4578012 },
    { lat: 49.3864734, lng: 20.457951 },
    { lat: 49.3862541, lng: 20.4597098 },
    { lat: 49.3862061, lng: 20.4607132 },
    { lat: 49.3862576, lng: 20.4620056 },
    { lat: 49.3863455, lng: 20.4625811 },
    { lat: 49.3863582, lng: 20.4637111 },
    { lat: 49.386283, lng: 20.46429 },
    { lat: 49.3862786, lng: 20.4647735 },
    { lat: 49.3864087, lng: 20.4650932 },
    { lat: 49.3866035, lng: 20.4659405 },
    { lat: 49.3865934, lng: 20.4661557 },
    { lat: 49.3866867, lng: 20.4666657 },
    { lat: 49.3866639, lng: 20.4678671 },
    { lat: 49.3867977, lng: 20.4690414 },
    { lat: 49.3867442, lng: 20.4693575 },
    { lat: 49.3861487, lng: 20.4699957 },
    { lat: 49.3857261, lng: 20.4698605 },
    { lat: 49.3854063, lng: 20.4700409 },
    { lat: 49.384993, lng: 20.4716713 },
    { lat: 49.3848582, lng: 20.473463 },
    { lat: 49.3852265, lng: 20.4738313 },
    { lat: 49.3855086, lng: 20.4750299 },
    { lat: 49.3855265, lng: 20.4763006 },
    { lat: 49.3856839, lng: 20.4772987 },
    { lat: 49.3857036, lng: 20.4781028 },
    { lat: 49.3859535, lng: 20.4781623 },
    { lat: 49.386012, lng: 20.478675 },
    { lat: 49.3859143, lng: 20.4791388 },
    { lat: 49.3858505, lng: 20.4799365 },
    { lat: 49.3857605, lng: 20.4802056 },
    { lat: 49.3858451, lng: 20.4803637 },
    { lat: 49.3858317, lng: 20.4805481 },
    { lat: 49.3856118, lng: 20.4812072 },
    { lat: 49.3857018, lng: 20.4812933 },
    { lat: 49.3854997, lng: 20.4820386 },
    { lat: 49.385353, lng: 20.4828792 },
    { lat: 49.3852378, lng: 20.4839906 },
    { lat: 49.3850357, lng: 20.4839161 },
    { lat: 49.384889, lng: 20.4846027 },
    { lat: 49.3848422, lng: 20.4845241 },
    { lat: 49.3845511, lng: 20.4852063 },
    { lat: 49.3843965, lng: 20.4850752 },
    { lat: 49.3842327, lng: 20.485608 },
    { lat: 49.384323, lng: 20.485792 },
    { lat: 49.3843175, lng: 20.4859171 },
    { lat: 49.383973, lng: 20.486888 },
    { lat: 49.3838866, lng: 20.4872944 },
    { lat: 49.3838214, lng: 20.4878461 },
    { lat: 49.3841174, lng: 20.4879638 },
    { lat: 49.384082, lng: 20.4887401 },
    { lat: 49.3841939, lng: 20.4887181 },
    { lat: 49.3842261, lng: 20.4896501 },
    { lat: 49.3843463, lng: 20.4896418 },
    { lat: 49.3842746, lng: 20.4906551 },
    { lat: 49.3845308, lng: 20.4910693 },
    { lat: 49.3842548, lng: 20.4918211 },
    { lat: 49.3843379, lng: 20.4919125 },
    { lat: 49.383738, lng: 20.4933131 },
    { lat: 49.3834639, lng: 20.4932371 },
    { lat: 49.3834046, lng: 20.4932795 },
    { lat: 49.3831567, lng: 20.4944537 },
    { lat: 49.3831447, lng: 20.495048 },
    { lat: 49.3827985, lng: 20.4951521 },
    { lat: 49.3828498, lng: 20.4955039 },
    { lat: 49.382833, lng: 20.4957471 },
    { lat: 49.3829367, lng: 20.496421 },
    { lat: 49.3829101, lng: 20.4974361 },
    { lat: 49.3828603, lng: 20.498041 },
    { lat: 49.3826864, lng: 20.4988434 },
    { lat: 49.3827129, lng: 20.498892 },
    { lat: 49.3824707, lng: 20.5002914 },
    { lat: 49.3823953, lng: 20.5009885 },
    { lat: 49.3823677, lng: 20.5013388 },
    { lat: 49.3824526, lng: 20.501997 },
    { lat: 49.3826684, lng: 20.502323 },
    { lat: 49.3828212, lng: 20.5024677 },
    { lat: 49.382903, lng: 20.5024263 },
    { lat: 49.3829646, lng: 20.5025691 },
    { lat: 49.3830272, lng: 20.503166 },
    { lat: 49.3831575, lng: 20.5032179 },
    { lat: 49.3836633, lng: 20.505117 },
    { lat: 49.3841994, lng: 20.5050073 },
    { lat: 49.3845541, lng: 20.5060718 },
    { lat: 49.3848665, lng: 20.506216 },
    { lat: 49.3850496, lng: 20.5064145 },
    { lat: 49.3852965, lng: 20.5060285 },
    { lat: 49.3855658, lng: 20.5053356 },
    { lat: 49.3857711, lng: 20.5055289 },
    { lat: 49.386007, lng: 20.5049046 },
    { lat: 49.3860405, lng: 20.5049621 },
    { lat: 49.3860966, lng: 20.504799 },
    { lat: 49.3862751, lng: 20.5047268 },
    { lat: 49.3880125, lng: 20.506352 },
    { lat: 49.3880132, lng: 20.5064373 },
    { lat: 49.389414, lng: 20.5073907 },
    { lat: 49.38939, lng: 20.5075319 },
    { lat: 49.390187, lng: 20.5077813 },
    { lat: 49.3904504, lng: 20.5077202 },
    { lat: 49.3905357, lng: 20.5080753 },
    { lat: 49.3907016, lng: 20.5080259 },
    { lat: 49.3907823, lng: 20.5082654 },
    { lat: 49.3912644, lng: 20.5087874 },
    { lat: 49.3918738, lng: 20.5088283 },
    { lat: 49.3926029, lng: 20.5091841 },
    { lat: 49.393354, lng: 20.510986 },
    { lat: 49.3933766, lng: 20.5102036 },
    { lat: 49.3934459, lng: 20.5099362 },
    { lat: 49.393785, lng: 20.509704 },
    { lat: 49.394239, lng: 20.509392 },
    { lat: 49.394256, lng: 20.5093832 },
    { lat: 49.3946709, lng: 20.5091227 },
    { lat: 49.3956154, lng: 20.5086636 },
    { lat: 49.3956642, lng: 20.5082587 },
    { lat: 49.395854, lng: 20.507992 },
    { lat: 49.3964309, lng: 20.5071861 },
    { lat: 49.396507, lng: 20.506995 },
    { lat: 49.3966857, lng: 20.5065554 },
    { lat: 49.3969431, lng: 20.5061568 },
    { lat: 49.3973274, lng: 20.5063306 },
    { lat: 49.3975857, lng: 20.5063117 },
    { lat: 49.3978308, lng: 20.5064307 },
    { lat: 49.39828, lng: 20.506805 },
    { lat: 49.3987906, lng: 20.5072372 },
    { lat: 49.3990646, lng: 20.5073296 },
    { lat: 49.3991909, lng: 20.5069007 },
    { lat: 49.399358, lng: 20.506616 },
    { lat: 49.3994014, lng: 20.5065417 },
    { lat: 49.3996566, lng: 20.5064563 },
    { lat: 49.4000867, lng: 20.5066009 },
    { lat: 49.4003744, lng: 20.5065424 },
    { lat: 49.400419, lng: 20.506532 },
    { lat: 49.40083, lng: 20.506572 },
    { lat: 49.401235, lng: 20.506589 },
    { lat: 49.402007, lng: 20.5066273 },
    { lat: 49.40201, lng: 20.50662 },
    { lat: 49.4024442, lng: 20.5059192 },
    { lat: 49.402749, lng: 20.505591 },
    { lat: 49.402836, lng: 20.505498 },
    { lat: 49.402921, lng: 20.505406 },
    { lat: 49.4029505, lng: 20.505376 },
    { lat: 49.402992, lng: 20.505315 },
    { lat: 49.403063, lng: 20.505213 },
    { lat: 49.403284, lng: 20.504894 },
    { lat: 49.4032896, lng: 20.5048874 },
    { lat: 49.403399, lng: 20.504638 },
    { lat: 49.403478, lng: 20.504467 },
    { lat: 49.4036, lng: 20.504189 },
    { lat: 49.403867, lng: 20.503587 },
    { lat: 49.4039628, lng: 20.5033688 },
    { lat: 49.404002, lng: 20.503054 },
    { lat: 49.404056, lng: 20.502614 },
    { lat: 49.404135, lng: 20.501975 },
    { lat: 49.404163, lng: 20.501748 },
    { lat: 49.404197, lng: 20.501455 },
    { lat: 49.40428, lng: 20.500744 },
    { lat: 49.40431, lng: 20.500488 },
    { lat: 49.4044, lng: 20.500178 },
    { lat: 49.404561, lng: 20.499627 },
    { lat: 49.404685, lng: 20.499201 },
    { lat: 49.404758, lng: 20.499019 },
    { lat: 49.404979, lng: 20.498461 },
    { lat: 49.405007, lng: 20.498368 },
    { lat: 49.405264, lng: 20.497493 },
    { lat: 49.405322, lng: 20.497295 },
    { lat: 49.405367, lng: 20.496886 },
    { lat: 49.405346, lng: 20.496294 },
    { lat: 49.405335, lng: 20.495968 },
    { lat: 49.405346, lng: 20.495047 },
    { lat: 49.405355, lng: 20.494551 },
    { lat: 49.405533, lng: 20.494364 },
    { lat: 49.405991, lng: 20.492975 },
    { lat: 49.406111, lng: 20.492314 },
    { lat: 49.406245, lng: 20.491662 },
    { lat: 49.40634, lng: 20.49073 },
    { lat: 49.406431, lng: 20.490145 },
    { lat: 49.407104, lng: 20.489957 },
    { lat: 49.407511, lng: 20.488761 },
    { lat: 49.408409, lng: 20.488603 },
    { lat: 49.408459, lng: 20.487893 },
    { lat: 49.408705, lng: 20.486654 },
    { lat: 49.408901, lng: 20.486043 },
    { lat: 49.409382, lng: 20.484812 },
    { lat: 49.409524, lng: 20.484356 },
    { lat: 49.409574, lng: 20.483441 },
    { lat: 49.409585, lng: 20.483143 },
    { lat: 49.409666, lng: 20.482812 },
    { lat: 49.409747, lng: 20.482482 },
    { lat: 49.409835, lng: 20.482163 },
    { lat: 49.410048, lng: 20.481387 },
    { lat: 49.410106, lng: 20.481219 },
    { lat: 49.410247, lng: 20.480808 },
    { lat: 49.410285, lng: 20.480701 },
    { lat: 49.410527, lng: 20.480307 },
    { lat: 49.410933, lng: 20.479645 },
    { lat: 49.411283, lng: 20.479317 },
    { lat: 49.411277, lng: 20.478933 },
    { lat: 49.411952, lng: 20.477995 },
    { lat: 49.412514, lng: 20.477293 },
    { lat: 49.41309, lng: 20.47698 },
    { lat: 49.413256, lng: 20.47689 },
    { lat: 49.413286, lng: 20.476874 },
    { lat: 49.413345, lng: 20.476827 },
    { lat: 49.413456, lng: 20.47674 },
    { lat: 49.41356, lng: 20.476659 },
    { lat: 49.413595, lng: 20.476632 },
    { lat: 49.414066, lng: 20.476263 },
    { lat: 49.414185, lng: 20.475636 },
    { lat: 49.414548, lng: 20.47458 },
    { lat: 49.41483, lng: 20.47375 },
    { lat: 49.415111, lng: 20.472828 },
    { lat: 49.415156, lng: 20.472708 },
    { lat: 49.415411, lng: 20.472025 },
    { lat: 49.415436, lng: 20.47196 },
    { lat: 49.415546, lng: 20.470565 },
    { lat: 49.415454, lng: 20.469852 },
    { lat: 49.41553, lng: 20.469549 },
    { lat: 49.415692, lng: 20.468895 },
    { lat: 49.4157776, lng: 20.4682075 },
    { lat: 49.415792, lng: 20.468094 },
    { lat: 49.415837, lng: 20.467606 },
    { lat: 49.415978, lng: 20.466573 },
    { lat: 49.415987, lng: 20.466506 },
    { lat: 49.416004, lng: 20.466195 },
    { lat: 49.416029, lng: 20.465705 },
    { lat: 49.416009, lng: 20.464656 },
    { lat: 49.415972, lng: 20.464209 },
    { lat: 49.415963, lng: 20.464164 },
    { lat: 49.415956, lng: 20.464134 },
    { lat: 49.415843, lng: 20.463601 },
    { lat: 49.41561, lng: 20.463123 },
    { lat: 49.415608, lng: 20.463121 },
    { lat: 49.415481, lng: 20.46281 },
    { lat: 49.415189, lng: 20.462361 },
    { lat: 49.41517, lng: 20.462305 },
    { lat: 49.415096, lng: 20.462089 },
    { lat: 49.414803, lng: 20.461245 },
    { lat: 49.414792, lng: 20.461244 },
    { lat: 49.414565, lng: 20.461215 },
    { lat: 49.414596, lng: 20.460932 },
    { lat: 49.414712, lng: 20.45985 },
    { lat: 49.414847, lng: 20.459566 },
    { lat: 49.415133, lng: 20.458869 },
    { lat: 49.41521, lng: 20.458447 },
    { lat: 49.415218, lng: 20.4577 },
    { lat: 49.415428, lng: 20.456022 },
    { lat: 49.415583, lng: 20.455358 },
    { lat: 49.415288, lng: 20.454913 },
    { lat: 49.415122, lng: 20.454671 },
    { lat: 49.415153, lng: 20.454039 },
    { lat: 49.415049, lng: 20.453597 },
    { lat: 49.415194, lng: 20.452784 },
    { lat: 49.415259, lng: 20.452253 },
    { lat: 49.415212, lng: 20.451638 },
    { lat: 49.415135, lng: 20.451147 },
    { lat: 49.415033, lng: 20.45001 },
    { lat: 49.415025, lng: 20.449918 },
    { lat: 49.415118, lng: 20.44976 },
    { lat: 49.415203, lng: 20.44964 },
    { lat: 49.415689, lng: 20.449076 },
    { lat: 49.415818, lng: 20.448703 },
    { lat: 49.416027, lng: 20.447949 },
    { lat: 49.416146, lng: 20.447259 },
    { lat: 49.416285, lng: 20.446847 },
    { lat: 49.416231, lng: 20.446613 },
    { lat: 49.416227, lng: 20.446595 },
    { lat: 49.41623, lng: 20.446577 },
    { lat: 49.416251, lng: 20.446457 },
    { lat: 49.416291, lng: 20.446224 },
    { lat: 49.416334, lng: 20.445978 },
    { lat: 49.416075, lng: 20.445666 },
    { lat: 49.415857, lng: 20.445555 },
    { lat: 49.415587, lng: 20.44557 },
    { lat: 49.415438, lng: 20.44577 },
    { lat: 49.415253, lng: 20.446058 },
    { lat: 49.415042, lng: 20.446439 },
    { lat: 49.414744, lng: 20.447071 },
    { lat: 49.414357, lng: 20.447771 },
    { lat: 49.414177, lng: 20.448057 },
    { lat: 49.414071, lng: 20.448246 },
    { lat: 49.413917, lng: 20.448578 },
    { lat: 49.413753, lng: 20.448852 },
    { lat: 49.413629, lng: 20.449245 },
    { lat: 49.413578, lng: 20.449452 },
    { lat: 49.413567, lng: 20.449728 },
    { lat: 49.41349, lng: 20.44991 },
    { lat: 49.413379, lng: 20.450115 },
    { lat: 49.413247, lng: 20.450348 },
    { lat: 49.413124, lng: 20.450682 },
    { lat: 49.413053, lng: 20.450881 },
    { lat: 49.412926, lng: 20.4509 },
    { lat: 49.412771, lng: 20.450929 },
    { lat: 49.412599, lng: 20.450784 },
    { lat: 49.412376, lng: 20.450331 },
    { lat: 49.412187, lng: 20.449852 },
    { lat: 49.412076, lng: 20.449194 },
    { lat: 49.411958, lng: 20.448424 },
    { lat: 49.411932, lng: 20.447807 },
    { lat: 49.411907, lng: 20.446917 },
    { lat: 49.411918, lng: 20.446352 },
    { lat: 49.41202, lng: 20.445892 },
    { lat: 49.412132, lng: 20.445538 },
    { lat: 49.412257, lng: 20.445135 },
    { lat: 49.412347, lng: 20.444917 },
    { lat: 49.412584, lng: 20.444533 },
    { lat: 49.412847, lng: 20.444268 },
    { lat: 49.413083, lng: 20.443941 },
    { lat: 49.413326, lng: 20.443661 },
    { lat: 49.41341, lng: 20.443528 },
    { lat: 49.413614, lng: 20.443213 },
    { lat: 49.413911, lng: 20.442633 },
    { lat: 49.413967, lng: 20.442556 },
    { lat: 49.414171, lng: 20.442096 },
    { lat: 49.414274, lng: 20.441738 },
    { lat: 49.414331, lng: 20.441329 },
    { lat: 49.414399, lng: 20.441002 },
    { lat: 49.414472, lng: 20.440313 },
    { lat: 49.414486, lng: 20.439391 },
    { lat: 49.414503, lng: 20.439001 },
    { lat: 49.414584, lng: 20.438639 },
    { lat: 49.414776, lng: 20.438246 },
    { lat: 49.414861, lng: 20.43821 },
    { lat: 49.414947, lng: 20.438113 },
    { lat: 49.415292, lng: 20.437842 },
    { lat: 49.415405, lng: 20.437757 },
    { lat: 49.415753, lng: 20.437503 },
    { lat: 49.41593, lng: 20.437393 },
    { lat: 49.416371, lng: 20.437165 },
    { lat: 49.416654, lng: 20.437084 },
    { lat: 49.416962, lng: 20.437066 },
    { lat: 49.417299, lng: 20.43695 },
    { lat: 49.417523, lng: 20.436721 },
    { lat: 49.417661, lng: 20.436388 },
    { lat: 49.417813, lng: 20.435937 },
    { lat: 49.417941, lng: 20.43534 },
    { lat: 49.417982, lng: 20.43476 },
    { lat: 49.417977, lng: 20.434095 },
    { lat: 49.417917, lng: 20.433569 },
    { lat: 49.417815, lng: 20.433096 },
    { lat: 49.417666, lng: 20.432644 },
    { lat: 49.417581, lng: 20.432295 },
    { lat: 49.4174952, lng: 20.4321115 },
    { lat: 49.417403, lng: 20.431906 },
    { lat: 49.417248, lng: 20.431458 },
    { lat: 49.417145, lng: 20.431171 },
    { lat: 49.41699, lng: 20.430808 },
    { lat: 49.416829, lng: 20.430507 },
    { lat: 49.416602, lng: 20.430205 },
    { lat: 49.416382, lng: 20.430014 },
    { lat: 49.416065, lng: 20.429803 },
    { lat: 49.415723, lng: 20.429592 },
    { lat: 49.415409, lng: 20.42946 },
    { lat: 49.415086, lng: 20.429369 },
    { lat: 49.414739, lng: 20.429303 },
    { lat: 49.414268, lng: 20.429326 },
    { lat: 49.414027, lng: 20.429305 },
    { lat: 49.413762, lng: 20.42929 },
    { lat: 49.413542, lng: 20.429279 },
    { lat: 49.413307, lng: 20.429292 },
    { lat: 49.412897, lng: 20.429249 },
    { lat: 49.412652, lng: 20.429256 },
    { lat: 49.41229, lng: 20.429211 },
    { lat: 49.411984, lng: 20.429235 },
    { lat: 49.411599, lng: 20.429326 },
    { lat: 49.411291, lng: 20.429433 },
    { lat: 49.410922, lng: 20.429478 },
    { lat: 49.410545, lng: 20.429484 },
    { lat: 49.410241, lng: 20.429447 },
    { lat: 49.410012, lng: 20.429426 },
    { lat: 49.409661, lng: 20.429311 },
    { lat: 49.409377, lng: 20.429241 },
    { lat: 49.409053, lng: 20.429116 },
    { lat: 49.408909, lng: 20.429043 },
    { lat: 49.408728, lng: 20.429004 },
    { lat: 49.408494, lng: 20.428781 },
    { lat: 49.408273, lng: 20.428727 },
    { lat: 49.408053, lng: 20.428883 },
    { lat: 49.407858, lng: 20.429126 },
    { lat: 49.407805, lng: 20.42939 },
    { lat: 49.407763, lng: 20.429717 },
    { lat: 49.407811, lng: 20.430111 },
    { lat: 49.407871, lng: 20.430535 },
    { lat: 49.407946, lng: 20.430823 },
    { lat: 49.408106, lng: 20.431207 },
    { lat: 49.408161, lng: 20.43158 },
    { lat: 49.408214, lng: 20.432075 },
    { lat: 49.408217, lng: 20.432525 },
    { lat: 49.408214, lng: 20.432937 },
    { lat: 49.408184, lng: 20.433316 },
    { lat: 49.408173, lng: 20.433652 },
    { lat: 49.408144, lng: 20.434039 },
    { lat: 49.408085, lng: 20.434336 },
    { lat: 49.407984, lng: 20.434707 },
    { lat: 49.407904, lng: 20.435059 },
    { lat: 49.407751, lng: 20.435475 },
    { lat: 49.407596, lng: 20.435821 },
    { lat: 49.407393, lng: 20.436201 },
    { lat: 49.407165, lng: 20.436571 },
    { lat: 49.406875, lng: 20.436868 },
    { lat: 49.406657, lng: 20.436917 },
    { lat: 49.406507, lng: 20.436968 },
    { lat: 49.406275, lng: 20.436988 },
    { lat: 49.40595, lng: 20.436881 },
    { lat: 49.405659, lng: 20.436727 },
    { lat: 49.405432, lng: 20.436628 },
    { lat: 49.405226, lng: 20.436529 },
    { lat: 49.404934, lng: 20.436308 },
    { lat: 49.404712, lng: 20.436119 },
    { lat: 49.404509, lng: 20.435943 },
    { lat: 49.404152, lng: 20.435729 },
    { lat: 49.403992, lng: 20.4356 },
    { lat: 49.403563, lng: 20.435281 },
    { lat: 49.403235, lng: 20.435068 },
    { lat: 49.403003, lng: 20.434932 },
    { lat: 49.402578, lng: 20.43461 },
    { lat: 49.402258, lng: 20.434365 },
    { lat: 49.402062, lng: 20.434202 },
    { lat: 49.401736, lng: 20.43408 },
    { lat: 49.401542, lng: 20.434094 },
    { lat: 49.401453, lng: 20.4340738 },
    { lat: 49.401317, lng: 20.434043 },
    { lat: 49.401091, lng: 20.434042 },
    { lat: 49.400862, lng: 20.4341 },
    { lat: 49.400565, lng: 20.434255 },
    { lat: 49.400337, lng: 20.434376 },
    { lat: 49.400128, lng: 20.434479 },
    { lat: 49.399786, lng: 20.434618 },
    { lat: 49.399542, lng: 20.434705 },
    { lat: 49.399253, lng: 20.434782 },
    { lat: 49.398866, lng: 20.434814 },
    { lat: 49.398707, lng: 20.434747 },
    { lat: 49.398303, lng: 20.434554 },
    { lat: 49.398188, lng: 20.434509 },
    { lat: 49.398058, lng: 20.434487 },
    { lat: 49.397908, lng: 20.434407 },
    { lat: 49.397598, lng: 20.434241 },
    { lat: 49.397496, lng: 20.434608 },
    { lat: 49.39747, lng: 20.434702 },
    { lat: 49.397452, lng: 20.434764 },
    { lat: 49.397438, lng: 20.434839 },
    { lat: 49.397338, lng: 20.435407 },
    { lat: 49.397152, lng: 20.436176 },
    { lat: 49.397126, lng: 20.436289 },
    { lat: 49.396823, lng: 20.437427 },
    { lat: 49.396799, lng: 20.437518 },
    { lat: 49.396531, lng: 20.438528 },
    { lat: 49.396519, lng: 20.438579 },
    { lat: 49.396406, lng: 20.439057 },
    { lat: 49.396196, lng: 20.439912 },
    { lat: 49.396004, lng: 20.440784 },
    { lat: 49.395932, lng: 20.441172 },
    { lat: 49.395775, lng: 20.442234 },
    { lat: 49.395499, lng: 20.443066 },
    { lat: 49.395485, lng: 20.443112 },
    { lat: 49.395434, lng: 20.443266 },
    { lat: 49.394979, lng: 20.443741 },
    { lat: 49.394722, lng: 20.44401 },
    { lat: 49.394399, lng: 20.444345 },
    { lat: 49.394036, lng: 20.444721 },
    { lat: 49.393556, lng: 20.445228 },
    { lat: 49.393142, lng: 20.445647 },
    { lat: 49.392621, lng: 20.446187 },
    { lat: 49.392521, lng: 20.446743 },
    { lat: 49.39234, lng: 20.447443 },
    { lat: 49.39225, lng: 20.447804 },
    { lat: 49.392168, lng: 20.448386 },
    { lat: 49.392134, lng: 20.448653 },
    { lat: 49.392078, lng: 20.448745 },
    { lat: 49.392064, lng: 20.448768 },
    { lat: 49.392022, lng: 20.448832 },
    { lat: 49.391976, lng: 20.4489 },
    { lat: 49.391969, lng: 20.44891 },
    { lat: 49.391926, lng: 20.448976 },
    { lat: 49.391833, lng: 20.449106 },
    { lat: 49.391747, lng: 20.449219 },
    { lat: 49.391654, lng: 20.449349 },
    { lat: 49.39158, lng: 20.449431 },
    { lat: 49.391497, lng: 20.449533 },
    { lat: 49.391427, lng: 20.44962 },
    { lat: 49.391339, lng: 20.449798 },
    { lat: 49.391286, lng: 20.449863 },
    { lat: 49.391195, lng: 20.449916 },
    { lat: 49.391121, lng: 20.450083 },
    { lat: 49.390923, lng: 20.450618 },
    { lat: 49.390805, lng: 20.450774 },
    { lat: 49.390599, lng: 20.451067 },
    { lat: 49.390376, lng: 20.451091 },
    { lat: 49.389838, lng: 20.451503 },
    { lat: 49.389727, lng: 20.451494 },
    { lat: 49.389605, lng: 20.451485 },
    { lat: 49.389471, lng: 20.451474 },
    { lat: 49.389338, lng: 20.451464 },
    { lat: 49.389328, lng: 20.451464 },
    { lat: 49.389213, lng: 20.451486 },
    { lat: 49.389091, lng: 20.45151 },
    { lat: 49.388969, lng: 20.451534 },
    { lat: 49.388781, lng: 20.45157 },
    { lat: 49.388656, lng: 20.451595 },
    { lat: 49.388549, lng: 20.451199 },
    { lat: 49.388082, lng: 20.450867 },
    { lat: 49.387718, lng: 20.450612 },
    { lat: 49.38769, lng: 20.450649 },
    { lat: 49.387614, lng: 20.450726 },
  ],
  Šambron: [
    { lat: 49.2407785, lng: 20.7657797 },
    { lat: 49.2408034, lng: 20.765139 },
    { lat: 49.2412083, lng: 20.764547 },
    { lat: 49.241704, lng: 20.76401 },
    { lat: 49.242843, lng: 20.7632296 },
    { lat: 49.2433848, lng: 20.7634673 },
    { lat: 49.2439259, lng: 20.7630703 },
    { lat: 49.2442176, lng: 20.7625931 },
    { lat: 49.2443487, lng: 20.7621844 },
    { lat: 49.2443966, lng: 20.7617186 },
    { lat: 49.2451095, lng: 20.7604074 },
    { lat: 49.2459029, lng: 20.7594978 },
    { lat: 49.2467069, lng: 20.7579863 },
    { lat: 49.2468494, lng: 20.7572045 },
    { lat: 49.2474163, lng: 20.7531193 },
    { lat: 49.247323, lng: 20.7524118 },
    { lat: 49.2473472, lng: 20.7516863 },
    { lat: 49.2472162, lng: 20.7504555 },
    { lat: 49.2466738, lng: 20.7498414 },
    { lat: 49.2460167, lng: 20.7487536 },
    { lat: 49.2460684, lng: 20.7482955 },
    { lat: 49.2459126, lng: 20.7480891 },
    { lat: 49.2456417, lng: 20.7461701 },
    { lat: 49.2456941, lng: 20.7460541 },
    { lat: 49.2455385, lng: 20.7450858 },
    { lat: 49.2452292, lng: 20.7439636 },
    { lat: 49.2452323, lng: 20.7433105 },
    { lat: 49.2448478, lng: 20.7421682 },
    { lat: 49.2450695, lng: 20.7414865 },
    { lat: 49.2442013, lng: 20.7402276 },
    { lat: 49.2443882, lng: 20.7400009 },
    { lat: 49.2444258, lng: 20.7397878 },
    { lat: 49.2442899, lng: 20.7389905 },
    { lat: 49.2445175, lng: 20.7386808 },
    { lat: 49.2443976, lng: 20.7381787 },
    { lat: 49.2441582, lng: 20.7380246 },
    { lat: 49.2440252, lng: 20.7376024 },
    { lat: 49.2438374, lng: 20.7374182 },
    { lat: 49.2437236, lng: 20.7371601 },
    { lat: 49.2437629, lng: 20.7370215 },
    { lat: 49.2435804, lng: 20.7364697 },
    { lat: 49.2436649, lng: 20.7363176 },
    { lat: 49.2436924, lng: 20.7356547 },
    { lat: 49.2439391, lng: 20.7354061 },
    { lat: 49.2438565, lng: 20.7352288 },
    { lat: 49.2436349, lng: 20.735199 },
    { lat: 49.243355, lng: 20.7342167 },
    { lat: 49.2434058, lng: 20.7338358 },
    { lat: 49.2435027, lng: 20.7337756 },
    { lat: 49.243505, lng: 20.7336803 },
    { lat: 49.243652, lng: 20.7336683 },
    { lat: 49.2436178, lng: 20.7335868 },
    { lat: 49.243386, lng: 20.7334595 },
    { lat: 49.2428078, lng: 20.7321255 },
    { lat: 49.2426491, lng: 20.7322534 },
    { lat: 49.2416485, lng: 20.7318709 },
    { lat: 49.2415929, lng: 20.7323096 },
    { lat: 49.2390069, lng: 20.7312016 },
    { lat: 49.2385398, lng: 20.7311364 },
    { lat: 49.2379907, lng: 20.73031 },
    { lat: 49.2351072, lng: 20.7286491 },
    { lat: 49.2345108, lng: 20.7281414 },
    { lat: 49.2339919, lng: 20.7267925 },
    { lat: 49.2331733, lng: 20.7257941 },
    { lat: 49.2321034, lng: 20.7255536 },
    { lat: 49.2319522, lng: 20.7261171 },
    { lat: 49.2311386, lng: 20.7251597 },
    { lat: 49.2303548, lng: 20.7247982 },
    { lat: 49.2302453, lng: 20.724983 },
    { lat: 49.2296482, lng: 20.7237512 },
    { lat: 49.2292226, lng: 20.7233618 },
    { lat: 49.2289624, lng: 20.7234879 },
    { lat: 49.2286984, lng: 20.7234645 },
    { lat: 49.2285651, lng: 20.7231016 },
    { lat: 49.2281176, lng: 20.7223478 },
    { lat: 49.2280892, lng: 20.7220854 },
    { lat: 49.22796, lng: 20.7218305 },
    { lat: 49.2278062, lng: 20.7218114 },
    { lat: 49.2275692, lng: 20.7214426 },
    { lat: 49.2275235, lng: 20.7211698 },
    { lat: 49.2274131, lng: 20.7210362 },
    { lat: 49.2271423, lng: 20.7208949 },
    { lat: 49.2266018, lng: 20.7202816 },
    { lat: 49.2265366, lng: 20.7200576 },
    { lat: 49.226537, lng: 20.7198141 },
    { lat: 49.2263388, lng: 20.7194886 },
    { lat: 49.2262523, lng: 20.7192029 },
    { lat: 49.2261405, lng: 20.7191029 },
    { lat: 49.2261608, lng: 20.7187822 },
    { lat: 49.2258024, lng: 20.7178393 },
    { lat: 49.2256745, lng: 20.7172156 },
    { lat: 49.2253109, lng: 20.7170171 },
    { lat: 49.2251735, lng: 20.7168245 },
    { lat: 49.2250792, lng: 20.7163252 },
    { lat: 49.2248217, lng: 20.7158173 },
    { lat: 49.2247205, lng: 20.7153758 },
    { lat: 49.2245233, lng: 20.7149858 },
    { lat: 49.2240999, lng: 20.7144365 },
    { lat: 49.2238523, lng: 20.7142123 },
    { lat: 49.2235663, lng: 20.7143085 },
    { lat: 49.2234172, lng: 20.7140067 },
    { lat: 49.2232128, lng: 20.7138892 },
    { lat: 49.2229932, lng: 20.7131528 },
    { lat: 49.2215452, lng: 20.7133009 },
    { lat: 49.2209186, lng: 20.7127009 },
    { lat: 49.2206106, lng: 20.7120388 },
    { lat: 49.2202468, lng: 20.7114958 },
    { lat: 49.2194155, lng: 20.7115403 },
    { lat: 49.2188017, lng: 20.7118526 },
    { lat: 49.2177512, lng: 20.7112659 },
    { lat: 49.2159157, lng: 20.7113767 },
    { lat: 49.2156544, lng: 20.7122318 },
    { lat: 49.2151906, lng: 20.7118162 },
    { lat: 49.2147778, lng: 20.7118883 },
    { lat: 49.2129899, lng: 20.711971 },
    { lat: 49.213074, lng: 20.7130788 },
    { lat: 49.2128575, lng: 20.7138026 },
    { lat: 49.2128485, lng: 20.7142228 },
    { lat: 49.2129018, lng: 20.7155081 },
    { lat: 49.2129839, lng: 20.716143 },
    { lat: 49.212935, lng: 20.7169643 },
    { lat: 49.212781, lng: 20.7175155 },
    { lat: 49.2123956, lng: 20.7183939 },
    { lat: 49.2123813, lng: 20.7188189 },
    { lat: 49.2121907, lng: 20.7189979 },
    { lat: 49.2121727, lng: 20.7191629 },
    { lat: 49.211826, lng: 20.7196061 },
    { lat: 49.2116692, lng: 20.7198907 },
    { lat: 49.2116575, lng: 20.7201264 },
    { lat: 49.2118142, lng: 20.7208733 },
    { lat: 49.2116174, lng: 20.7216159 },
    { lat: 49.2114274, lng: 20.7217795 },
    { lat: 49.2113883, lng: 20.722044 },
    { lat: 49.2110614, lng: 20.7221904 },
    { lat: 49.2108646, lng: 20.7224506 },
    { lat: 49.2108266, lng: 20.7230672 },
    { lat: 49.2105404, lng: 20.7237613 },
    { lat: 49.2104217, lng: 20.7237538 },
    { lat: 49.2103031, lng: 20.7239622 },
    { lat: 49.2101159, lng: 20.7245971 },
    { lat: 49.2089559, lng: 20.7267841 },
    { lat: 49.20832, lng: 20.7277509 },
    { lat: 49.2079624, lng: 20.7279181 },
    { lat: 49.2075306, lng: 20.7278677 },
    { lat: 49.2054779, lng: 20.7281062 },
    { lat: 49.2046462, lng: 20.7279176 },
    { lat: 49.2038283, lng: 20.7274507 },
    { lat: 49.203746, lng: 20.7274781 },
    { lat: 49.2025758, lng: 20.7280003 },
    { lat: 49.2024951, lng: 20.728087 },
    { lat: 49.2021097, lng: 20.7285007 },
    { lat: 49.2016344, lng: 20.729011 },
    { lat: 49.2016016, lng: 20.7290282 },
    { lat: 49.2010232, lng: 20.7293317 },
    { lat: 49.2007675, lng: 20.7297831 },
    { lat: 49.2005491, lng: 20.7301685 },
    { lat: 49.2002907, lng: 20.7306247 },
    { lat: 49.2000983, lng: 20.7307236 },
    { lat: 49.1996889, lng: 20.730934 },
    { lat: 49.1996185, lng: 20.7309702 },
    { lat: 49.1995328, lng: 20.7312705 },
    { lat: 49.1993536, lng: 20.7318977 },
    { lat: 49.1991803, lng: 20.7325047 },
    { lat: 49.1987204, lng: 20.733101 },
    { lat: 49.1983544, lng: 20.7335756 },
    { lat: 49.1983104, lng: 20.7336326 },
    { lat: 49.1979645, lng: 20.7337512 },
    { lat: 49.1977723, lng: 20.7346242 },
    { lat: 49.1976546, lng: 20.7349421 },
    { lat: 49.1975904, lng: 20.7351156 },
    { lat: 49.1975873, lng: 20.7353822 },
    { lat: 49.1975848, lng: 20.735602 },
    { lat: 49.1974927, lng: 20.736217 },
    { lat: 49.1971471, lng: 20.7370504 },
    { lat: 49.1970532, lng: 20.7371646 },
    { lat: 49.1965574, lng: 20.7377672 },
    { lat: 49.1960468, lng: 20.7383877 },
    { lat: 49.1959596, lng: 20.7384937 },
    { lat: 49.1955137, lng: 20.7394086 },
    { lat: 49.1953547, lng: 20.7397347 },
    { lat: 49.194672, lng: 20.7407485 },
    { lat: 49.1945119, lng: 20.7408737 },
    { lat: 49.1939183, lng: 20.7413379 },
    { lat: 49.1928974, lng: 20.7423349 },
    { lat: 49.192563, lng: 20.7427807 },
    { lat: 49.191959, lng: 20.7427004 },
    { lat: 49.1920354, lng: 20.7433682 },
    { lat: 49.1920172, lng: 20.7437843 },
    { lat: 49.1919912, lng: 20.7439102 },
    { lat: 49.1918742, lng: 20.744457 },
    { lat: 49.1918117, lng: 20.7449573 },
    { lat: 49.191752, lng: 20.745245 },
    { lat: 49.1916305, lng: 20.7456947 },
    { lat: 49.191437, lng: 20.7467242 },
    { lat: 49.1913637, lng: 20.747044 },
    { lat: 49.1912918, lng: 20.7474349 },
    { lat: 49.1912639, lng: 20.7478327 },
    { lat: 49.191249, lng: 20.7484306 },
    { lat: 49.1912393, lng: 20.7487686 },
    { lat: 49.1911152, lng: 20.7491112 },
    { lat: 49.1911126, lng: 20.7491377 },
    { lat: 49.19112, lng: 20.7491529 },
    { lat: 49.1913786, lng: 20.7494054 },
    { lat: 49.1916033, lng: 20.749621 },
    { lat: 49.1916798, lng: 20.7497098 },
    { lat: 49.1918665, lng: 20.749935 },
    { lat: 49.1924492, lng: 20.7506354 },
    { lat: 49.1927744, lng: 20.7509487 },
    { lat: 49.1930579, lng: 20.7512067 },
    { lat: 49.1933361, lng: 20.7514396 },
    { lat: 49.1934356, lng: 20.7515442 },
    { lat: 49.1935899, lng: 20.7517091 },
    { lat: 49.1938362, lng: 20.7519607 },
    { lat: 49.1941079, lng: 20.7521956 },
    { lat: 49.1943002, lng: 20.7523527 },
    { lat: 49.1945047, lng: 20.7525099 },
    { lat: 49.1946054, lng: 20.7525813 },
    { lat: 49.1947654, lng: 20.7526867 },
    { lat: 49.194912, lng: 20.7527565 },
    { lat: 49.1952575, lng: 20.7529508 },
    { lat: 49.1956408, lng: 20.7531964 },
    { lat: 49.1959339, lng: 20.753336 },
    { lat: 49.1960756, lng: 20.7534965 },
    { lat: 49.1961794, lng: 20.7535982 },
    { lat: 49.1964793, lng: 20.7537077 },
    { lat: 49.1966835, lng: 20.7537537 },
    { lat: 49.1969458, lng: 20.7537889 },
    { lat: 49.1975023, lng: 20.7539945 },
    { lat: 49.1976108, lng: 20.7541693 },
    { lat: 49.1984141, lng: 20.7539927 },
    { lat: 49.1985789, lng: 20.7539419 },
    { lat: 49.1987711, lng: 20.7538741 },
    { lat: 49.1994274, lng: 20.7535191 },
    { lat: 49.1996358, lng: 20.75349 },
    { lat: 49.200065, lng: 20.7534997 },
    { lat: 49.2003667, lng: 20.7535688 },
    { lat: 49.2007411, lng: 20.7536528 },
    { lat: 49.2012299, lng: 20.7538233 },
    { lat: 49.2017119, lng: 20.7540238 },
    { lat: 49.2021506, lng: 20.7542034 },
    { lat: 49.2025382, lng: 20.7546661 },
    { lat: 49.2027219, lng: 20.7549967 },
    { lat: 49.2030509, lng: 20.7555898 },
    { lat: 49.2032479, lng: 20.7560119 },
    { lat: 49.2035734, lng: 20.7565311 },
    { lat: 49.2038931, lng: 20.7570476 },
    { lat: 49.2046986, lng: 20.758164 },
    { lat: 49.2048039, lng: 20.7583106 },
    { lat: 49.2052452, lng: 20.7589598 },
    { lat: 49.2055493, lng: 20.7592245 },
    { lat: 49.2058093, lng: 20.7594503 },
    { lat: 49.2057253, lng: 20.7596879 },
    { lat: 49.2056915, lng: 20.7600099 },
    { lat: 49.2056316, lng: 20.7600965 },
    { lat: 49.2057101, lng: 20.7607399 },
    { lat: 49.205681, lng: 20.7613247 },
    { lat: 49.2057062, lng: 20.7617845 },
    { lat: 49.2056178, lng: 20.7620296 },
    { lat: 49.20572, lng: 20.7622827 },
    { lat: 49.2058495, lng: 20.7626069 },
    { lat: 49.2059242, lng: 20.7626812 },
    { lat: 49.2065442, lng: 20.7632932 },
    { lat: 49.2065676, lng: 20.7633165 },
    { lat: 49.2069662, lng: 20.763709 },
    { lat: 49.207631, lng: 20.764343 },
    { lat: 49.207709, lng: 20.764419 },
    { lat: 49.208193, lng: 20.764784 },
    { lat: 49.208959, lng: 20.765369 },
    { lat: 49.21124, lng: 20.767068 },
    { lat: 49.21148, lng: 20.766812 },
    { lat: 49.212997, lng: 20.768331 },
    { lat: 49.212948, lng: 20.768617 },
    { lat: 49.21281, lng: 20.769338 },
    { lat: 49.213665, lng: 20.770079 },
    { lat: 49.214262, lng: 20.770146 },
    { lat: 49.215095, lng: 20.770118 },
    { lat: 49.21571, lng: 20.76996 },
    { lat: 49.216504, lng: 20.769739 },
    { lat: 49.215886, lng: 20.771381 },
    { lat: 49.217747, lng: 20.772487 },
    { lat: 49.218519, lng: 20.773303 },
    { lat: 49.218871, lng: 20.77317 },
    { lat: 49.219543, lng: 20.772401 },
    { lat: 49.219958, lng: 20.772142 },
    { lat: 49.22041, lng: 20.771451 },
    { lat: 49.220912, lng: 20.771219 },
    { lat: 49.221522, lng: 20.771045 },
    { lat: 49.221613, lng: 20.770998 },
    { lat: 49.221616, lng: 20.770997 },
    { lat: 49.221637, lng: 20.770999 },
    { lat: 49.221713, lng: 20.771004 },
    { lat: 49.221736, lng: 20.771005 },
    { lat: 49.222175, lng: 20.771348 },
    { lat: 49.223331, lng: 20.771675 },
    { lat: 49.22396, lng: 20.772294 },
    { lat: 49.224698, lng: 20.77199 },
    { lat: 49.225883, lng: 20.771422 },
    { lat: 49.226968, lng: 20.771398 },
    { lat: 49.227392, lng: 20.771347 },
    { lat: 49.227441, lng: 20.771342 },
    { lat: 49.227921, lng: 20.771711 },
    { lat: 49.228509, lng: 20.771658 },
    { lat: 49.229996, lng: 20.771523 },
    { lat: 49.2303416, lng: 20.7699722 },
    { lat: 49.2311869, lng: 20.7678667 },
    { lat: 49.2321419, lng: 20.7673668 },
    { lat: 49.2335558, lng: 20.7683738 },
    { lat: 49.2351678, lng: 20.7693806 },
    { lat: 49.2357099, lng: 20.7698514 },
    { lat: 49.236224, lng: 20.7704617 },
    { lat: 49.2366466, lng: 20.7705423 },
    { lat: 49.236901, lng: 20.7699962 },
    { lat: 49.2375085, lng: 20.770046 },
    { lat: 49.2378701, lng: 20.769574 },
    { lat: 49.2388381, lng: 20.7685698 },
    { lat: 49.2390691, lng: 20.7679957 },
    { lat: 49.2397514, lng: 20.7673496 },
    { lat: 49.2398762, lng: 20.7666363 },
    { lat: 49.2404968, lng: 20.7664833 },
    { lat: 49.2407785, lng: 20.7657797 },
  ],
  Forbasy: [
    { lat: 49.3001089, lng: 20.6058632 },
    { lat: 49.2995212, lng: 20.6034933 },
    { lat: 49.2989774, lng: 20.6021213 },
    { lat: 49.2985866, lng: 20.6015235 },
    { lat: 49.2974707, lng: 20.6002638 },
    { lat: 49.296949, lng: 20.5997835 },
    { lat: 49.2958748, lng: 20.5992179 },
    { lat: 49.2943179, lng: 20.5987629 },
    { lat: 49.2943173, lng: 20.5987631 },
    { lat: 49.2937056, lng: 20.5986569 },
    { lat: 49.2932573, lng: 20.5987128 },
    { lat: 49.2924345, lng: 20.5991813 },
    { lat: 49.2914412, lng: 20.5998866 },
    { lat: 49.291348, lng: 20.5998913 },
    { lat: 49.290928, lng: 20.6001691 },
    { lat: 49.290779, lng: 20.6003144 },
    { lat: 49.2906803, lng: 20.600506 },
    { lat: 49.2900997, lng: 20.6010476 },
    { lat: 49.2896293, lng: 20.6015796 },
    { lat: 49.2895151, lng: 20.6017331 },
    { lat: 49.2887544, lng: 20.6027553 },
    { lat: 49.2883645, lng: 20.6034265 },
    { lat: 49.287207, lng: 20.6050741 },
    { lat: 49.2866032, lng: 20.6055257 },
    { lat: 49.285486, lng: 20.6066675 },
    { lat: 49.2842685, lng: 20.6076443 },
    { lat: 49.2828758, lng: 20.6081649 },
    { lat: 49.2820603, lng: 20.6088131 },
    { lat: 49.2817395, lng: 20.6086545 },
    { lat: 49.2813276, lng: 20.608315 },
    { lat: 49.2807783, lng: 20.6077873 },
    { lat: 49.2806661, lng: 20.6075732 },
    { lat: 49.2804366, lng: 20.60683 },
    { lat: 49.2804975, lng: 20.6064906 },
    { lat: 49.2808929, lng: 20.6053489 },
    { lat: 49.2806816, lng: 20.6052333 },
    { lat: 49.2796265, lng: 20.605096 },
    { lat: 49.2766537, lng: 20.6057856 },
    { lat: 49.2756876, lng: 20.6053423 },
    { lat: 49.2746305, lng: 20.604745 },
    { lat: 49.2735849, lng: 20.6043499 },
    { lat: 49.2698909, lng: 20.6047355 },
    { lat: 49.2680463, lng: 20.6046847 },
    { lat: 49.2677309, lng: 20.604676 },
    { lat: 49.2660445, lng: 20.6043295 },
    { lat: 49.2651635, lng: 20.6043779 },
    { lat: 49.2651696, lng: 20.6044556 },
    { lat: 49.2654672, lng: 20.6080155 },
    { lat: 49.2657759, lng: 20.6086758 },
    { lat: 49.2660264, lng: 20.6098755 },
    { lat: 49.2664122, lng: 20.6107741 },
    { lat: 49.2671982, lng: 20.611906 },
    { lat: 49.2685058, lng: 20.6122356 },
    { lat: 49.2700455, lng: 20.6136311 },
    { lat: 49.2720934, lng: 20.6165473 },
    { lat: 49.2734845, lng: 20.6173481 },
    { lat: 49.2752707, lng: 20.6190007 },
    { lat: 49.2760015, lng: 20.6197882 },
    { lat: 49.2769792, lng: 20.6201469 },
    { lat: 49.277504, lng: 20.6198454 },
    { lat: 49.2785882, lng: 20.6205037 },
    { lat: 49.2786938, lng: 20.6218112 },
    { lat: 49.2793662, lng: 20.6219793 },
    { lat: 49.279925, lng: 20.6218685 },
    { lat: 49.2817724, lng: 20.6221228 },
    { lat: 49.2852552, lng: 20.6237227 },
    { lat: 49.2897199, lng: 20.6240095 },
    { lat: 49.2931595, lng: 20.6228419 },
    { lat: 49.2943682, lng: 20.6220489 },
    { lat: 49.295562, lng: 20.6214115 },
    { lat: 49.2955909, lng: 20.6213362 },
    { lat: 49.2956669, lng: 20.6218578 },
    { lat: 49.296289, lng: 20.6216872 },
    { lat: 49.2962186, lng: 20.6209826 },
    { lat: 49.2995594, lng: 20.619037 },
    { lat: 49.3001026, lng: 20.6185573 },
    { lat: 49.2990766, lng: 20.6170729 },
    { lat: 49.2989975, lng: 20.6164996 },
    { lat: 49.2991512, lng: 20.6163287 },
    { lat: 49.2996485, lng: 20.6154456 },
    { lat: 49.3005427, lng: 20.6145205 },
    { lat: 49.3003763, lng: 20.6123703 },
    { lat: 49.2998994, lng: 20.6104487 },
    { lat: 49.300464, lng: 20.6102435 },
    { lat: 49.3007787, lng: 20.6100427 },
    { lat: 49.3004291, lng: 20.6074737 },
    { lat: 49.3001089, lng: 20.6058632 },
  ],
  Čirč: [
    { lat: 49.260353, lng: 20.997189 },
    { lat: 49.260848, lng: 20.997071 },
    { lat: 49.261644, lng: 20.997039 },
    { lat: 49.26244, lng: 20.995976 },
    { lat: 49.2635, lng: 20.99663 },
    { lat: 49.26454, lng: 20.996564 },
    { lat: 49.265083, lng: 20.996691 },
    { lat: 49.265694, lng: 20.996636 },
    { lat: 49.266828, lng: 20.996222 },
    { lat: 49.267767, lng: 20.996083 },
    { lat: 49.269598, lng: 20.995619 },
    { lat: 49.27092, lng: 20.994868 },
    { lat: 49.272124, lng: 20.992411 },
    { lat: 49.272653, lng: 20.991399 },
    { lat: 49.273017, lng: 20.990944 },
    { lat: 49.273536, lng: 20.990564 },
    { lat: 49.274989, lng: 20.990987 },
    { lat: 49.275175, lng: 20.991352 },
    { lat: 49.277018, lng: 20.990024 },
    { lat: 49.2777103, lng: 20.9902494 },
    { lat: 49.2779107, lng: 20.9906993 },
    { lat: 49.2781849, lng: 20.9890754 },
    { lat: 49.2776881, lng: 20.9869354 },
    { lat: 49.2773802, lng: 20.9845499 },
    { lat: 49.2779114, lng: 20.983701 },
    { lat: 49.2792289, lng: 20.982251 },
    { lat: 49.2792833, lng: 20.9809283 },
    { lat: 49.2796081, lng: 20.979685 },
    { lat: 49.2796829, lng: 20.9777162 },
    { lat: 49.2790429, lng: 20.977351 },
    { lat: 49.2788349, lng: 20.9758531 },
    { lat: 49.2791325, lng: 20.9737497 },
    { lat: 49.2796948, lng: 20.9724094 },
    { lat: 49.2800085, lng: 20.970486 },
    { lat: 49.2800455, lng: 20.9699075 },
    { lat: 49.2802378, lng: 20.9691815 },
    { lat: 49.2815886, lng: 20.9666395 },
    { lat: 49.2831965, lng: 20.9642123 },
    { lat: 49.2847224, lng: 20.9624406 },
    { lat: 49.2852386, lng: 20.9615052 },
    { lat: 49.285254, lng: 20.9603364 },
    { lat: 49.2849194, lng: 20.9595454 },
    { lat: 49.284439, lng: 20.9587328 },
    { lat: 49.2843118, lng: 20.9575404 },
    { lat: 49.2847722, lng: 20.9554327 },
    { lat: 49.2851178, lng: 20.9529596 },
    { lat: 49.2852772, lng: 20.9498567 },
    { lat: 49.2854795, lng: 20.9494077 },
    { lat: 49.2861185, lng: 20.9476372 },
    { lat: 49.2869345, lng: 20.9472689 },
    { lat: 49.2877838, lng: 20.9461683 },
    { lat: 49.2887884, lng: 20.9454804 },
    { lat: 49.2895745, lng: 20.9451277 },
    { lat: 49.2906673, lng: 20.9449276 },
    { lat: 49.2911579, lng: 20.9449587 },
    { lat: 49.292033, lng: 20.9440976 },
    { lat: 49.2926548, lng: 20.9434 },
    { lat: 49.2926097, lng: 20.943199 },
    { lat: 49.2926956, lng: 20.9430395 },
    { lat: 49.2931804, lng: 20.9428316 },
    { lat: 49.2931897, lng: 20.9427347 },
    { lat: 49.293417, lng: 20.9425616 },
    { lat: 49.2945521, lng: 20.9413027 },
    { lat: 49.2944606, lng: 20.9410174 },
    { lat: 49.2954426, lng: 20.9400898 },
    { lat: 49.2955343, lng: 20.938279 },
    { lat: 49.2958804, lng: 20.9382612 },
    { lat: 49.2956845, lng: 20.9371074 },
    { lat: 49.2960059, lng: 20.9369237 },
    { lat: 49.2959967, lng: 20.9363778 },
    { lat: 49.2969609, lng: 20.935185 },
    { lat: 49.2969428, lng: 20.9347119 },
    { lat: 49.2968429, lng: 20.9340399 },
    { lat: 49.2964658, lng: 20.9322141 },
    { lat: 49.297077, lng: 20.931671 },
    { lat: 49.297037, lng: 20.931483 },
    { lat: 49.297062, lng: 20.931267 },
    { lat: 49.297061, lng: 20.931129 },
    { lat: 49.297053, lng: 20.931103 },
    { lat: 49.296969, lng: 20.930896 },
    { lat: 49.296963, lng: 20.930881 },
    { lat: 49.296845, lng: 20.930734 },
    { lat: 49.296839, lng: 20.930731 },
    { lat: 49.296716, lng: 20.930663 },
    { lat: 49.296701, lng: 20.930652 },
    { lat: 49.296661, lng: 20.930612 },
    { lat: 49.296623, lng: 20.930477 },
    { lat: 49.296624, lng: 20.930468 },
    { lat: 49.29664, lng: 20.93029 },
    { lat: 49.29664, lng: 20.93028 },
    { lat: 49.29665, lng: 20.930107 },
    { lat: 49.296649, lng: 20.930096 },
    { lat: 49.296637, lng: 20.929958 },
    { lat: 49.296634, lng: 20.92978 },
    { lat: 49.296595, lng: 20.929504 },
    { lat: 49.296594, lng: 20.929469 },
    { lat: 49.296594, lng: 20.929458 },
    { lat: 49.296598, lng: 20.929402 },
    { lat: 49.296605, lng: 20.929391 },
    { lat: 49.296616, lng: 20.929369 },
    { lat: 49.296626, lng: 20.929353 },
    { lat: 49.296639, lng: 20.929333 },
    { lat: 49.296687, lng: 20.929288 },
    { lat: 49.296699, lng: 20.929278 },
    { lat: 49.296755, lng: 20.929223 },
    { lat: 49.296764, lng: 20.929217 },
    { lat: 49.296791, lng: 20.929136 },
    { lat: 49.296791, lng: 20.929076 },
    { lat: 49.296784, lng: 20.929023 },
    { lat: 49.296762, lng: 20.928946 },
    { lat: 49.296681, lng: 20.928806 },
    { lat: 49.296663, lng: 20.928791 },
    { lat: 49.296646, lng: 20.928785 },
    { lat: 49.296639, lng: 20.928788 },
    { lat: 49.296496, lng: 20.928592 },
    { lat: 49.296435, lng: 20.928476 },
    { lat: 49.296427, lng: 20.928463 },
    { lat: 49.296388, lng: 20.928305 },
    { lat: 49.296386, lng: 20.928285 },
    { lat: 49.296349, lng: 20.928098 },
    { lat: 49.296343, lng: 20.928056 },
    { lat: 49.29633, lng: 20.927913 },
    { lat: 49.296343, lng: 20.927815 },
    { lat: 49.296372, lng: 20.927773 },
    { lat: 49.296362, lng: 20.927591 },
    { lat: 49.296361, lng: 20.927557 },
    { lat: 49.296361, lng: 20.926965 },
    { lat: 49.296361, lng: 20.926947 },
    { lat: 49.296383, lng: 20.926726 },
    { lat: 49.29637, lng: 20.926706 },
    { lat: 49.29628, lng: 20.926587 },
    { lat: 49.296257, lng: 20.926529 },
    { lat: 49.296268, lng: 20.926414 },
    { lat: 49.296261, lng: 20.926206 },
    { lat: 49.296227, lng: 20.926121 },
    { lat: 49.296351, lng: 20.925811 },
    { lat: 49.296053, lng: 20.925638 },
    { lat: 49.2960692, lng: 20.9255434 },
    { lat: 49.296119, lng: 20.9253296 },
    { lat: 49.296158, lng: 20.9251909 },
    { lat: 49.296321, lng: 20.924527 },
    { lat: 49.295969, lng: 20.924084 },
    { lat: 49.296022, lng: 20.924007 },
    { lat: 49.2960462, lng: 20.9239746 },
    { lat: 49.296098, lng: 20.923908 },
    { lat: 49.296252, lng: 20.923752 },
    { lat: 49.2960718, lng: 20.9225267 },
    { lat: 49.2960355, lng: 20.9194498 },
    { lat: 49.2957179, lng: 20.9153045 },
    { lat: 49.2954671, lng: 20.9140671 },
    { lat: 49.2950033, lng: 20.9130198 },
    { lat: 49.2946862, lng: 20.9124241 },
    { lat: 49.2945332, lng: 20.9123542 },
    { lat: 49.2943069, lng: 20.9120481 },
    { lat: 49.2942127, lng: 20.9121294 },
    { lat: 49.2939362, lng: 20.9117043 },
    { lat: 49.2938818, lng: 20.9117255 },
    { lat: 49.293247, lng: 20.9109171 },
    { lat: 49.2925382, lng: 20.9094077 },
    { lat: 49.2926243, lng: 20.9085201 },
    { lat: 49.2921426, lng: 20.9078137 },
    { lat: 49.291583, lng: 20.9077717 },
    { lat: 49.290466, lng: 20.9084941 },
    { lat: 49.2895878, lng: 20.9087843 },
    { lat: 49.2879886, lng: 20.9097254 },
    { lat: 49.2877033, lng: 20.9096429 },
    { lat: 49.2868498, lng: 20.9089234 },
    { lat: 49.2864953, lng: 20.9087186 },
    { lat: 49.2852492, lng: 20.9067649 },
    { lat: 49.2854499, lng: 20.9061118 },
    { lat: 49.2843233, lng: 20.9038266 },
    { lat: 49.2838991, lng: 20.9024517 },
    { lat: 49.2833063, lng: 20.8991806 },
    { lat: 49.2833049, lng: 20.8990058 },
    { lat: 49.28312, lng: 20.898591 },
    { lat: 49.2818857, lng: 20.8981656 },
    { lat: 49.2814775, lng: 20.8983065 },
    { lat: 49.2812525, lng: 20.8983126 },
    { lat: 49.2805166, lng: 20.8982125 },
    { lat: 49.2802979, lng: 20.8980044 },
    { lat: 49.2801891, lng: 20.8979826 },
    { lat: 49.2798642, lng: 20.8981884 },
    { lat: 49.2796817, lng: 20.8981879 },
    { lat: 49.2792039, lng: 20.8979998 },
    { lat: 49.2783881, lng: 20.8979063 },
    { lat: 49.2778931, lng: 20.8980133 },
    { lat: 49.2775523, lng: 20.89795 },
    { lat: 49.2769644, lng: 20.8984691 },
    { lat: 49.2768234, lng: 20.8986679 },
    { lat: 49.2765731, lng: 20.8992888 },
    { lat: 49.2758646, lng: 20.9005425 },
    { lat: 49.275708, lng: 20.9011405 },
    { lat: 49.2754006, lng: 20.9019385 },
    { lat: 49.2751756, lng: 20.9024143 },
    { lat: 49.2747609, lng: 20.9031003 },
    { lat: 49.2744563, lng: 20.9034817 },
    { lat: 49.2735359, lng: 20.9042225 },
    { lat: 49.2727563, lng: 20.9052365 },
    { lat: 49.271661, lng: 20.9061038 },
    { lat: 49.2712018, lng: 20.9066043 },
    { lat: 49.2712424, lng: 20.90671 },
    { lat: 49.2708523, lng: 20.9071943 },
    { lat: 49.2689613, lng: 20.9083587 },
    { lat: 49.2682111, lng: 20.907803 },
    { lat: 49.2679052, lng: 20.9071058 },
    { lat: 49.2671583, lng: 20.9073881 },
    { lat: 49.2668006, lng: 20.9079931 },
    { lat: 49.2662934, lng: 20.9082068 },
    { lat: 49.2658928, lng: 20.9087784 },
    { lat: 49.2653893, lng: 20.9089324 },
    { lat: 49.2656674, lng: 20.9097716 },
    { lat: 49.2658062, lng: 20.9101906 },
    { lat: 49.2648955, lng: 20.9108212 },
    { lat: 49.2650625, lng: 20.9120117 },
    { lat: 49.2651843, lng: 20.9123161 },
    { lat: 49.265167, lng: 20.9128785 },
    { lat: 49.2653563, lng: 20.9137545 },
    { lat: 49.2654016, lng: 20.9144051 },
    { lat: 49.265454, lng: 20.9146247 },
    { lat: 49.2644564, lng: 20.9147499 },
    { lat: 49.2640867, lng: 20.9149295 },
    { lat: 49.2635519, lng: 20.9148599 },
    { lat: 49.2637257, lng: 20.9154788 },
    { lat: 49.2634414, lng: 20.9154559 },
    { lat: 49.2628825, lng: 20.915905 },
    { lat: 49.2625086, lng: 20.9157969 },
    { lat: 49.2622174, lng: 20.9161094 },
    { lat: 49.261522, lng: 20.9161118 },
    { lat: 49.2613173, lng: 20.9165552 },
    { lat: 49.2606998, lng: 20.9163991 },
    { lat: 49.2604656, lng: 20.9166307 },
    { lat: 49.2604723, lng: 20.9168306 },
    { lat: 49.2599237, lng: 20.9190696 },
    { lat: 49.2604986, lng: 20.9199353 },
    { lat: 49.2613436, lng: 20.923161 },
    { lat: 49.2618262, lng: 20.9241009 },
    { lat: 49.2622183, lng: 20.9245878 },
    { lat: 49.2621884, lng: 20.9254765 },
    { lat: 49.2622889, lng: 20.9257548 },
    { lat: 49.2626084, lng: 20.9272722 },
    { lat: 49.2627302, lng: 20.9288295 },
    { lat: 49.2624979, lng: 20.9297553 },
    { lat: 49.2617438, lng: 20.9301631 },
    { lat: 49.2609829, lng: 20.9309355 },
    { lat: 49.2594057, lng: 20.9342632 },
    { lat: 49.2589213, lng: 20.9365496 },
    { lat: 49.2592315, lng: 20.9376144 },
    { lat: 49.2581371, lng: 20.94024 },
    { lat: 49.2574761, lng: 20.9424614 },
    { lat: 49.257548, lng: 20.9450287 },
    { lat: 49.2571509, lng: 20.9463559 },
    { lat: 49.2571614, lng: 20.9475174 },
    { lat: 49.2574784, lng: 20.9484933 },
    { lat: 49.257464, lng: 20.9486335 },
    { lat: 49.2560603, lng: 20.9510334 },
    { lat: 49.2548769, lng: 20.9519284 },
    { lat: 49.2543055, lng: 20.9531947 },
    { lat: 49.2550528, lng: 20.9539417 },
    { lat: 49.2555495, lng: 20.9546686 },
    { lat: 49.2557124, lng: 20.9551733 },
    { lat: 49.2554011, lng: 20.9570857 },
    { lat: 49.2559982, lng: 20.9584968 },
    { lat: 49.2580638, lng: 20.9591944 },
    { lat: 49.2585251, lng: 20.9598372 },
    { lat: 49.2591819, lng: 20.9601939 },
    { lat: 49.2620957, lng: 20.9660526 },
    { lat: 49.2614101, lng: 20.9671739 },
    { lat: 49.2599653, lng: 20.969903 },
    { lat: 49.260067, lng: 20.9713137 },
    { lat: 49.260581, lng: 20.9735553 },
    { lat: 49.2602464, lng: 20.974907 },
    { lat: 49.2592069, lng: 20.9766318 },
    { lat: 49.2581773, lng: 20.9774849 },
    { lat: 49.2572357, lng: 20.9787225 },
    { lat: 49.2575945, lng: 20.9792995 },
    { lat: 49.2581224, lng: 20.9812157 },
    { lat: 49.2584663, lng: 20.9839434 },
    { lat: 49.2590468, lng: 20.9852027 },
    { lat: 49.2594778, lng: 20.9869143 },
    { lat: 49.2592696, lng: 20.9877679 },
    { lat: 49.2585751, lng: 20.9888243 },
    { lat: 49.2586579, lng: 20.989701 },
    { lat: 49.2590837, lng: 20.9901933 },
    { lat: 49.2592789, lng: 20.9907945 },
    { lat: 49.2592498, lng: 20.9915195 },
    { lat: 49.2596209, lng: 20.9924343 },
    { lat: 49.2597737, lng: 20.9924994 },
    { lat: 49.2599524, lng: 20.9927289 },
    { lat: 49.2601815, lng: 20.9959739 },
    { lat: 49.2601039, lng: 20.99673 },
    { lat: 49.260353, lng: 20.997189 },
  ],
  Matysová: [
    { lat: 49.3427901, lng: 20.7269516 },
    { lat: 49.3416438, lng: 20.7270966 },
    { lat: 49.3404651, lng: 20.7273763 },
    { lat: 49.3394568, lng: 20.7277337 },
    { lat: 49.3387181, lng: 20.7283307 },
    { lat: 49.338146, lng: 20.7284545 },
    { lat: 49.3368806, lng: 20.7280313 },
    { lat: 49.3350597, lng: 20.7262025 },
    { lat: 49.3332522, lng: 20.7260459 },
    { lat: 49.3326745, lng: 20.7260677 },
    { lat: 49.3323523, lng: 20.7261901 },
    { lat: 49.3320576, lng: 20.7258761 },
    { lat: 49.3317454, lng: 20.7262486 },
    { lat: 49.3309101, lng: 20.7261767 },
    { lat: 49.3298701, lng: 20.7257316 },
    { lat: 49.3269794, lng: 20.7258508 },
    { lat: 49.3259384, lng: 20.7252383 },
    { lat: 49.3250554, lng: 20.7261555 },
    { lat: 49.3234643, lng: 20.7271311 },
    { lat: 49.3236548, lng: 20.7283622 },
    { lat: 49.3233441, lng: 20.7287767 },
    { lat: 49.3226944, lng: 20.7290291 },
    { lat: 49.3225522, lng: 20.7295257 },
    { lat: 49.322222, lng: 20.7295795 },
    { lat: 49.3219877, lng: 20.7295114 },
    { lat: 49.3216533, lng: 20.7291874 },
    { lat: 49.321297, lng: 20.7295218 },
    { lat: 49.3208791, lng: 20.7297368 },
    { lat: 49.3206974, lng: 20.7297553 },
    { lat: 49.3205335, lng: 20.7298965 },
    { lat: 49.3204797, lng: 20.7307055 },
    { lat: 49.3202947, lng: 20.7316324 },
    { lat: 49.3199733, lng: 20.7324158 },
    { lat: 49.3196301, lng: 20.7330287 },
    { lat: 49.3194464, lng: 20.7336355 },
    { lat: 49.3192679, lng: 20.7346131 },
    { lat: 49.3194395, lng: 20.7351698 },
    { lat: 49.3193632, lng: 20.7358901 },
    { lat: 49.3192481, lng: 20.736847 },
    { lat: 49.319038, lng: 20.7373664 },
    { lat: 49.3186532, lng: 20.7378233 },
    { lat: 49.3176625, lng: 20.7386226 },
    { lat: 49.3173453, lng: 20.7390473 },
    { lat: 49.3169305, lng: 20.739902 },
    { lat: 49.3164896, lng: 20.7411001 },
    { lat: 49.3162929, lng: 20.7413629 },
    { lat: 49.3152223, lng: 20.7422928 },
    { lat: 49.3150168, lng: 20.7426311 },
    { lat: 49.3148523, lng: 20.7432676 },
    { lat: 49.3146943, lng: 20.7435449 },
    { lat: 49.3143693, lng: 20.7436541 },
    { lat: 49.3137844, lng: 20.7443436 },
    { lat: 49.3131335, lng: 20.7448448 },
    { lat: 49.3127027, lng: 20.7446928 },
    { lat: 49.3115801, lng: 20.744296 },
    { lat: 49.3114765, lng: 20.744518 },
    { lat: 49.3106034, lng: 20.7463649 },
    { lat: 49.3101888, lng: 20.7492921 },
    { lat: 49.3100599, lng: 20.7516319 },
    { lat: 49.3094842, lng: 20.753555 },
    { lat: 49.3089332, lng: 20.7559929 },
    { lat: 49.3084827, lng: 20.7570226 },
    { lat: 49.3082632, lng: 20.7592798 },
    { lat: 49.307577, lng: 20.7627241 },
    { lat: 49.3069961, lng: 20.767295 },
    { lat: 49.3070312, lng: 20.7684009 },
    { lat: 49.3075197, lng: 20.7725855 },
    { lat: 49.3072776, lng: 20.7749544 },
    { lat: 49.3068357, lng: 20.7779281 },
    { lat: 49.3068727, lng: 20.7779886 },
    { lat: 49.3073256, lng: 20.7789107 },
    { lat: 49.3087006, lng: 20.7799035 },
    { lat: 49.3102822, lng: 20.7817656 },
    { lat: 49.311098, lng: 20.7832244 },
    { lat: 49.3113587, lng: 20.7829876 },
    { lat: 49.3121759, lng: 20.7831174 },
    { lat: 49.3127364, lng: 20.7834761 },
    { lat: 49.312958, lng: 20.7836025 },
    { lat: 49.3136168, lng: 20.7831614 },
    { lat: 49.3149455, lng: 20.7829807 },
    { lat: 49.3163252, lng: 20.7821616 },
    { lat: 49.3177877, lng: 20.7806474 },
    { lat: 49.3184334, lng: 20.7795947 },
    { lat: 49.3185874, lng: 20.7790028 },
    { lat: 49.3186978, lng: 20.7789073 },
    { lat: 49.3202722, lng: 20.7766294 },
    { lat: 49.3211781, lng: 20.7749998 },
    { lat: 49.3225806, lng: 20.7738326 },
    { lat: 49.3236413, lng: 20.7726457 },
    { lat: 49.3244594, lng: 20.7715325 },
    { lat: 49.3247343, lng: 20.7707962 },
    { lat: 49.3246359, lng: 20.7703239 },
    { lat: 49.325096, lng: 20.7698344 },
    { lat: 49.3253661, lng: 20.7689241 },
    { lat: 49.325315, lng: 20.7687493 },
    { lat: 49.3254268, lng: 20.7684024 },
    { lat: 49.3255187, lng: 20.768395 },
    { lat: 49.325781, lng: 20.7679742 },
    { lat: 49.3258067, lng: 20.767607 },
    { lat: 49.3264802, lng: 20.7673664 },
    { lat: 49.3265936, lng: 20.7659589 },
    { lat: 49.3264094, lng: 20.7650949 },
    { lat: 49.3288506, lng: 20.7630821 },
    { lat: 49.3290404, lng: 20.7626534 },
    { lat: 49.3302015, lng: 20.7616016 },
    { lat: 49.3302085, lng: 20.7605747 },
    { lat: 49.3306399, lng: 20.7598402 },
    { lat: 49.3316115, lng: 20.760789 },
    { lat: 49.3319218, lng: 20.7612282 },
    { lat: 49.3321728, lng: 20.7614864 },
    { lat: 49.3325521, lng: 20.7617471 },
    { lat: 49.3326558, lng: 20.7612691 },
    { lat: 49.3326246, lng: 20.7609209 },
    { lat: 49.3328279, lng: 20.7593854 },
    { lat: 49.3333072, lng: 20.7582149 },
    { lat: 49.3333933, lng: 20.7574901 },
    { lat: 49.3339951, lng: 20.7564757 },
    { lat: 49.3345451, lng: 20.7558108 },
    { lat: 49.3348877, lng: 20.7553069 },
    { lat: 49.3354007, lng: 20.7549504 },
    { lat: 49.3355992, lng: 20.7546678 },
    { lat: 49.3358097, lng: 20.7541833 },
    { lat: 49.3359643, lng: 20.7535835 },
    { lat: 49.33612, lng: 20.7528825 },
    { lat: 49.3361845, lng: 20.7523417 },
    { lat: 49.3362961, lng: 20.7522142 },
    { lat: 49.3366937, lng: 20.7520637 },
    { lat: 49.3372625, lng: 20.7507819 },
    { lat: 49.3374667, lng: 20.7502141 },
    { lat: 49.3376556, lng: 20.7493386 },
    { lat: 49.3377264, lng: 20.7487257 },
    { lat: 49.338857, lng: 20.7456709 },
    { lat: 49.3399437, lng: 20.7452349 },
    { lat: 49.3405962, lng: 20.7452613 },
    { lat: 49.3411179, lng: 20.745693 },
    { lat: 49.3430221, lng: 20.7455264 },
    { lat: 49.3430771, lng: 20.7460102 },
    { lat: 49.3438257, lng: 20.7461371 },
    { lat: 49.3441825, lng: 20.7463996 },
    { lat: 49.344668, lng: 20.7471198 },
    { lat: 49.3455817, lng: 20.7485675 },
    { lat: 49.3457404, lng: 20.7475051 },
    { lat: 49.3462257, lng: 20.7456959 },
    { lat: 49.3463257, lng: 20.7442781 },
    { lat: 49.3463715, lng: 20.7421012 },
    { lat: 49.3464837, lng: 20.7414801 },
    { lat: 49.3468618, lng: 20.7400265 },
    { lat: 49.346911, lng: 20.7384113 },
    { lat: 49.3471166, lng: 20.7378833 },
    { lat: 49.3474016, lng: 20.7368939 },
    { lat: 49.3478743, lng: 20.7366307 },
    { lat: 49.3479578, lng: 20.7358358 },
    { lat: 49.3481482, lng: 20.7347112 },
    { lat: 49.3482883, lng: 20.7346015 },
    { lat: 49.3480595, lng: 20.7341087 },
    { lat: 49.3476318, lng: 20.7334932 },
    { lat: 49.3470169, lng: 20.733197 },
    { lat: 49.3467511, lng: 20.7318073 },
    { lat: 49.346769, lng: 20.7313892 },
    { lat: 49.3466753, lng: 20.7310565 },
    { lat: 49.3467059, lng: 20.7279169 },
    { lat: 49.3465291, lng: 20.7271715 },
    { lat: 49.3457273, lng: 20.7263261 },
    { lat: 49.3447577, lng: 20.7263122 },
    { lat: 49.3447624, lng: 20.7264479 },
    { lat: 49.3440297, lng: 20.7268936 },
    { lat: 49.3436033, lng: 20.7266745 },
    { lat: 49.3427901, lng: 20.7269516 },
  ],
  Litmanová: [
    { lat: 49.401, lng: 20.642894 },
    { lat: 49.400994, lng: 20.642596 },
    { lat: 49.401309, lng: 20.642431 },
    { lat: 49.401993, lng: 20.642214 },
    { lat: 49.402653, lng: 20.642308 },
    { lat: 49.403076, lng: 20.642379 },
    { lat: 49.403851, lng: 20.642222 },
    { lat: 49.404327, lng: 20.642078 },
    { lat: 49.404726, lng: 20.641466 },
    { lat: 49.405199, lng: 20.640984 },
    { lat: 49.405571, lng: 20.640429 },
    { lat: 49.406014, lng: 20.640234 },
    { lat: 49.406353, lng: 20.640008 },
    { lat: 49.406745, lng: 20.639387 },
    { lat: 49.407096, lng: 20.639073 },
    { lat: 49.407333, lng: 20.638706 },
    { lat: 49.407507, lng: 20.638347 },
    { lat: 49.40777, lng: 20.637596 },
    { lat: 49.408463, lng: 20.635972 },
    { lat: 49.408564, lng: 20.635154 },
    { lat: 49.40882, lng: 20.634064 },
    { lat: 49.408818, lng: 20.631649 },
    { lat: 49.409161, lng: 20.629915 },
    { lat: 49.409344, lng: 20.628828 },
    { lat: 49.409833, lng: 20.626567 },
    { lat: 49.41026, lng: 20.625638 },
    { lat: 49.410459, lng: 20.624669 },
    { lat: 49.411095, lng: 20.624231 },
    { lat: 49.411987, lng: 20.623468 },
    { lat: 49.413418, lng: 20.622211 },
    { lat: 49.414533, lng: 20.62183 },
    { lat: 49.416234, lng: 20.622357 },
    { lat: 49.416821, lng: 20.621964 },
    { lat: 49.418027, lng: 20.619653 },
    { lat: 49.417999, lng: 20.619137 },
    { lat: 49.417949, lng: 20.618176 },
    { lat: 49.417881, lng: 20.61562 },
    { lat: 49.417845, lng: 20.614934 },
    { lat: 49.41667, lng: 20.6139 },
    { lat: 49.416001, lng: 20.61281 },
    { lat: 49.414443, lng: 20.611932 },
    { lat: 49.413585, lng: 20.611414 },
    { lat: 49.412454, lng: 20.611274 },
    { lat: 49.412105, lng: 20.611424 },
    { lat: 49.410815, lng: 20.612117 },
    { lat: 49.410002, lng: 20.612259 },
    { lat: 49.409179, lng: 20.611832 },
    { lat: 49.407317, lng: 20.611803 },
    { lat: 49.4057, lng: 20.612586 },
    { lat: 49.403218, lng: 20.613487 },
    { lat: 49.401824, lng: 20.613598 },
    { lat: 49.401838, lng: 20.612223 },
    { lat: 49.402086, lng: 20.611539 },
    { lat: 49.402433, lng: 20.60982 },
    { lat: 49.402381, lng: 20.609115 },
    { lat: 49.401887, lng: 20.607711 },
    { lat: 49.4016956, lng: 20.6068465 },
    { lat: 49.401572, lng: 20.606252 },
    { lat: 49.4013, lng: 20.605774 },
    { lat: 49.400524, lng: 20.605983 },
    { lat: 49.399998, lng: 20.605885 },
    { lat: 49.3996992, lng: 20.6056526 },
    { lat: 49.399354, lng: 20.605411 },
    { lat: 49.39887, lng: 20.605122 },
    { lat: 49.39849, lng: 20.604997 },
    { lat: 49.397732, lng: 20.603688 },
    { lat: 49.39719, lng: 20.60292 },
    { lat: 49.396132, lng: 20.602294 },
    { lat: 49.395303, lng: 20.601786 },
    { lat: 49.394423, lng: 20.600951 },
    { lat: 49.393722, lng: 20.601161 },
    { lat: 49.392917, lng: 20.601367 },
    { lat: 49.39201, lng: 20.601665 },
    { lat: 49.3915683, lng: 20.6018198 },
    { lat: 49.391465, lng: 20.601858 },
    { lat: 49.390907, lng: 20.601708 },
    { lat: 49.390817, lng: 20.601575 },
    { lat: 49.390705, lng: 20.601724 },
    { lat: 49.38972, lng: 20.601357 },
    { lat: 49.389057, lng: 20.600965 },
    { lat: 49.388295, lng: 20.600198 },
    { lat: 49.387751, lng: 20.599102 },
    { lat: 49.387558, lng: 20.598649 },
    { lat: 49.387158, lng: 20.598073 },
    { lat: 49.386969, lng: 20.597925 },
    { lat: 49.386675, lng: 20.597891 },
    { lat: 49.386271, lng: 20.597392 },
    { lat: 49.38595, lng: 20.596892 },
    { lat: 49.385476, lng: 20.596167 },
    { lat: 49.385246, lng: 20.59563 },
    { lat: 49.384974, lng: 20.595202 },
    { lat: 49.384267, lng: 20.594096 },
    { lat: 49.383652, lng: 20.593085 },
    { lat: 49.38303, lng: 20.592135 },
    { lat: 49.3823184, lng: 20.5945096 },
    { lat: 49.3818824, lng: 20.5947482 },
    { lat: 49.3797978, lng: 20.5983375 },
    { lat: 49.3795481, lng: 20.5986845 },
    { lat: 49.3784024, lng: 20.5999701 },
    { lat: 49.3739948, lng: 20.6029705 },
    { lat: 49.372311, lng: 20.6026336 },
    { lat: 49.37143, lng: 20.6045517 },
    { lat: 49.3662737, lng: 20.6071143 },
    { lat: 49.3650738, lng: 20.6068336 },
    { lat: 49.3622579, lng: 20.6065367 },
    { lat: 49.3617669, lng: 20.6074619 },
    { lat: 49.3611238, lng: 20.609266 },
    { lat: 49.360906, lng: 20.6091608 },
    { lat: 49.3594435, lng: 20.612151 },
    { lat: 49.3581641, lng: 20.6141945 },
    { lat: 49.3578698, lng: 20.61493 },
    { lat: 49.3566214, lng: 20.6166198 },
    { lat: 49.3558641, lng: 20.6180395 },
    { lat: 49.3559734, lng: 20.619071 },
    { lat: 49.356347, lng: 20.6207894 },
    { lat: 49.3558662, lng: 20.6217258 },
    { lat: 49.3553886, lng: 20.6255109 },
    { lat: 49.3543158, lng: 20.6283035 },
    { lat: 49.3541872, lng: 20.6288873 },
    { lat: 49.3540516, lng: 20.6289095 },
    { lat: 49.3540804, lng: 20.6289544 },
    { lat: 49.3543926, lng: 20.6294991 },
    { lat: 49.3543709, lng: 20.6296175 },
    { lat: 49.3544375, lng: 20.6298423 },
    { lat: 49.3543839, lng: 20.6302635 },
    { lat: 49.354853, lng: 20.6309473 },
    { lat: 49.3585817, lng: 20.6336327 },
    { lat: 49.3601719, lng: 20.6356999 },
    { lat: 49.3618027, lng: 20.6366107 },
    { lat: 49.3626292, lng: 20.6369653 },
    { lat: 49.3630789, lng: 20.6372328 },
    { lat: 49.3632493, lng: 20.6379996 },
    { lat: 49.3632425, lng: 20.6382776 },
    { lat: 49.3639637, lng: 20.6381766 },
    { lat: 49.3642933, lng: 20.6379218 },
    { lat: 49.364382, lng: 20.638069 },
    { lat: 49.364587, lng: 20.638202 },
    { lat: 49.3648156, lng: 20.6384851 },
    { lat: 49.3648007, lng: 20.6393625 },
    { lat: 49.3649354, lng: 20.6396651 },
    { lat: 49.3649254, lng: 20.6401116 },
    { lat: 49.3650323, lng: 20.6404891 },
    { lat: 49.365541, lng: 20.6407971 },
    { lat: 49.365491, lng: 20.6411343 },
    { lat: 49.3655587, lng: 20.641259 },
    { lat: 49.3657595, lng: 20.6412747 },
    { lat: 49.3660698, lng: 20.6415159 },
    { lat: 49.3666127, lng: 20.6423773 },
    { lat: 49.3673258, lng: 20.6428954 },
    { lat: 49.367536, lng: 20.6432076 },
    { lat: 49.367734, lng: 20.6441145 },
    { lat: 49.3682406, lng: 20.6445626 },
    { lat: 49.36889, lng: 20.645595 },
    { lat: 49.3693216, lng: 20.6468754 },
    { lat: 49.3706223, lng: 20.647996 },
    { lat: 49.372993, lng: 20.6529579 },
    { lat: 49.3739714, lng: 20.6531571 },
    { lat: 49.3741959, lng: 20.6526648 },
    { lat: 49.3744612, lng: 20.652876 },
    { lat: 49.3743673, lng: 20.6534318 },
    { lat: 49.3748167, lng: 20.6536583 },
    { lat: 49.3748543, lng: 20.6534825 },
    { lat: 49.3752387, lng: 20.6536862 },
    { lat: 49.3752926, lng: 20.6539342 },
    { lat: 49.3752117, lng: 20.6540981 },
    { lat: 49.3751945, lng: 20.6544037 },
    { lat: 49.3759412, lng: 20.6545418 },
    { lat: 49.3759235, lng: 20.6552506 },
    { lat: 49.3759708, lng: 20.6553716 },
    { lat: 49.3763067, lng: 20.655419 },
    { lat: 49.3764726, lng: 20.6563837 },
    { lat: 49.3772447, lng: 20.6573929 },
    { lat: 49.3777016, lng: 20.6571672 },
    { lat: 49.3779443, lng: 20.6568384 },
    { lat: 49.3784075, lng: 20.6556578 },
    { lat: 49.3790584, lng: 20.654554 },
    { lat: 49.3794064, lng: 20.6534264 },
    { lat: 49.37979, lng: 20.6517123 },
    { lat: 49.379989, lng: 20.6510417 },
    { lat: 49.3807491, lng: 20.6496635 },
    { lat: 49.3809742, lng: 20.6494427 },
    { lat: 49.3809319, lng: 20.6491421 },
    { lat: 49.381361, lng: 20.6486744 },
    { lat: 49.3823873, lng: 20.6483515 },
    { lat: 49.3832452, lng: 20.6483592 },
    { lat: 49.3833456, lng: 20.6486814 },
    { lat: 49.3835853, lng: 20.6491798 },
    { lat: 49.3837218, lng: 20.6491557 },
    { lat: 49.3838641, lng: 20.649297 },
    { lat: 49.3843502, lng: 20.6501455 },
    { lat: 49.38454, lng: 20.650195 },
    { lat: 49.3847689, lng: 20.6501455 },
    { lat: 49.384896, lng: 20.6500452 },
    { lat: 49.3849369, lng: 20.6498366 },
    { lat: 49.3848834, lng: 20.6495647 },
    { lat: 49.3850329, lng: 20.6494048 },
    { lat: 49.3850727, lng: 20.6492594 },
    { lat: 49.3853733, lng: 20.6492617 },
    { lat: 49.3856924, lng: 20.6494652 },
    { lat: 49.3858051, lng: 20.6491862 },
    { lat: 49.3862285, lng: 20.6488758 },
    { lat: 49.3865174, lng: 20.6487711 },
    { lat: 49.3866563, lng: 20.6488144 },
    { lat: 49.3867014, lng: 20.6486917 },
    { lat: 49.3866367, lng: 20.6485494 },
    { lat: 49.3867264, lng: 20.6484461 },
    { lat: 49.3870488, lng: 20.6483928 },
    { lat: 49.3869721, lng: 20.6481661 },
    { lat: 49.387143, lng: 20.6480712 },
    { lat: 49.3874683, lng: 20.6480057 },
    { lat: 49.3874901, lng: 20.6480827 },
    { lat: 49.387569, lng: 20.64802 },
    { lat: 49.3879901, lng: 20.6479942 },
    { lat: 49.3880201, lng: 20.6480741 },
    { lat: 49.3883358, lng: 20.6480259 },
    { lat: 49.3886302, lng: 20.6478882 },
    { lat: 49.3888123, lng: 20.6475976 },
    { lat: 49.3891861, lng: 20.646465 },
    { lat: 49.3893544, lng: 20.6455956 },
    { lat: 49.3893555, lng: 20.6453108 },
    { lat: 49.389491, lng: 20.6450104 },
    { lat: 49.3895897, lng: 20.6449078 },
    { lat: 49.389683, lng: 20.6451204 },
    { lat: 49.3899382, lng: 20.6447644 },
    { lat: 49.3906815, lng: 20.6449656 },
    { lat: 49.3908533, lng: 20.6451127 },
    { lat: 49.3913474, lng: 20.6453293 },
    { lat: 49.3921326, lng: 20.6454764 },
    { lat: 49.3923297, lng: 20.6455787 },
    { lat: 49.3925657, lng: 20.6455022 },
    { lat: 49.3926449, lng: 20.6453908 },
    { lat: 49.3939852, lng: 20.6445927 },
    { lat: 49.3940991, lng: 20.6444064 },
    { lat: 49.3955505, lng: 20.6429708 },
    { lat: 49.395872, lng: 20.6424209 },
    { lat: 49.396315, lng: 20.6419425 },
    { lat: 49.3965762, lng: 20.6417848 },
    { lat: 49.3969585, lng: 20.6417115 },
    { lat: 49.3971661, lng: 20.6414093 },
    { lat: 49.3976394, lng: 20.641452 },
    { lat: 49.3980806, lng: 20.6418932 },
    { lat: 49.3981832, lng: 20.6417418 },
    { lat: 49.3985716, lng: 20.6415871 },
    { lat: 49.3990062, lng: 20.6416233 },
    { lat: 49.399276, lng: 20.6415501 },
    { lat: 49.3995171, lng: 20.6416824 },
    { lat: 49.3998043, lng: 20.641972 },
    { lat: 49.4000454, lng: 20.6422297 },
    { lat: 49.4001443, lng: 20.6424444 },
    { lat: 49.400727, lng: 20.6428422 },
    { lat: 49.401, lng: 20.642894 },
  ],
  Jarabina: [
    { lat: 49.399213, lng: 20.6791907 },
    { lat: 49.4000868, lng: 20.6776089 },
    { lat: 49.4001398, lng: 20.6758015 },
    { lat: 49.3997617, lng: 20.6720552 },
    { lat: 49.4008317, lng: 20.6704876 },
    { lat: 49.4018835, lng: 20.6687903 },
    { lat: 49.4033841, lng: 20.6673681 },
    { lat: 49.403552, lng: 20.6657 },
    { lat: 49.403474, lng: 20.66532 },
    { lat: 49.403487, lng: 20.664087 },
    { lat: 49.403686, lng: 20.662725 },
    { lat: 49.40364, lng: 20.661601 },
    { lat: 49.40361, lng: 20.660767 },
    { lat: 49.403489, lng: 20.659708 },
    { lat: 49.40307, lng: 20.658621 },
    { lat: 49.402888, lng: 20.658101 },
    { lat: 49.40249, lng: 20.657363 },
    { lat: 49.402062, lng: 20.65634 },
    { lat: 49.401897, lng: 20.655541 },
    { lat: 49.401844, lng: 20.654898 },
    { lat: 49.401669, lng: 20.654311 },
    { lat: 49.401544, lng: 20.653199 },
    { lat: 49.401399, lng: 20.652411 },
    { lat: 49.400977, lng: 20.650829 },
    { lat: 49.400919, lng: 20.649154 },
    { lat: 49.400915, lng: 20.647926 },
    { lat: 49.40067, lng: 20.646988 },
    { lat: 49.400679, lng: 20.646302 },
    { lat: 49.400792, lng: 20.645674 },
    { lat: 49.400995, lng: 20.64449 },
    { lat: 49.401012, lng: 20.643476 },
    { lat: 49.401, lng: 20.642894 },
    { lat: 49.400727, lng: 20.6428422 },
    { lat: 49.4001443, lng: 20.6424444 },
    { lat: 49.4000454, lng: 20.6422297 },
    { lat: 49.3998043, lng: 20.641972 },
    { lat: 49.3995171, lng: 20.6416824 },
    { lat: 49.399276, lng: 20.6415501 },
    { lat: 49.3990062, lng: 20.6416233 },
    { lat: 49.3985716, lng: 20.6415871 },
    { lat: 49.3981832, lng: 20.6417418 },
    { lat: 49.3980806, lng: 20.6418932 },
    { lat: 49.3976394, lng: 20.641452 },
    { lat: 49.3971661, lng: 20.6414093 },
    { lat: 49.3969585, lng: 20.6417115 },
    { lat: 49.3965762, lng: 20.6417848 },
    { lat: 49.396315, lng: 20.6419425 },
    { lat: 49.395872, lng: 20.6424209 },
    { lat: 49.3955505, lng: 20.6429708 },
    { lat: 49.3940991, lng: 20.6444064 },
    { lat: 49.3939852, lng: 20.6445927 },
    { lat: 49.3926449, lng: 20.6453908 },
    { lat: 49.3925657, lng: 20.6455022 },
    { lat: 49.3923297, lng: 20.6455787 },
    { lat: 49.3921326, lng: 20.6454764 },
    { lat: 49.3913474, lng: 20.6453293 },
    { lat: 49.3908533, lng: 20.6451127 },
    { lat: 49.3906815, lng: 20.6449656 },
    { lat: 49.3899382, lng: 20.6447644 },
    { lat: 49.389683, lng: 20.6451204 },
    { lat: 49.3895897, lng: 20.6449078 },
    { lat: 49.389491, lng: 20.6450104 },
    { lat: 49.3893555, lng: 20.6453108 },
    { lat: 49.3893544, lng: 20.6455956 },
    { lat: 49.3891861, lng: 20.646465 },
    { lat: 49.3888123, lng: 20.6475976 },
    { lat: 49.3886302, lng: 20.6478882 },
    { lat: 49.3883358, lng: 20.6480259 },
    { lat: 49.3880201, lng: 20.6480741 },
    { lat: 49.3879901, lng: 20.6479942 },
    { lat: 49.387569, lng: 20.64802 },
    { lat: 49.3874901, lng: 20.6480827 },
    { lat: 49.3874683, lng: 20.6480057 },
    { lat: 49.387143, lng: 20.6480712 },
    { lat: 49.3869721, lng: 20.6481661 },
    { lat: 49.3870488, lng: 20.6483928 },
    { lat: 49.3867264, lng: 20.6484461 },
    { lat: 49.3866367, lng: 20.6485494 },
    { lat: 49.3867014, lng: 20.6486917 },
    { lat: 49.3866563, lng: 20.6488144 },
    { lat: 49.3865174, lng: 20.6487711 },
    { lat: 49.3862285, lng: 20.6488758 },
    { lat: 49.3858051, lng: 20.6491862 },
    { lat: 49.3856924, lng: 20.6494652 },
    { lat: 49.3853733, lng: 20.6492617 },
    { lat: 49.3850727, lng: 20.6492594 },
    { lat: 49.3850329, lng: 20.6494048 },
    { lat: 49.3848834, lng: 20.6495647 },
    { lat: 49.3849369, lng: 20.6498366 },
    { lat: 49.384896, lng: 20.6500452 },
    { lat: 49.3847689, lng: 20.6501455 },
    { lat: 49.38454, lng: 20.650195 },
    { lat: 49.3843502, lng: 20.6501455 },
    { lat: 49.3838641, lng: 20.649297 },
    { lat: 49.3837218, lng: 20.6491557 },
    { lat: 49.3835853, lng: 20.6491798 },
    { lat: 49.3833456, lng: 20.6486814 },
    { lat: 49.3832452, lng: 20.6483592 },
    { lat: 49.3823873, lng: 20.6483515 },
    { lat: 49.381361, lng: 20.6486744 },
    { lat: 49.3809319, lng: 20.6491421 },
    { lat: 49.3809742, lng: 20.6494427 },
    { lat: 49.3807491, lng: 20.6496635 },
    { lat: 49.379989, lng: 20.6510417 },
    { lat: 49.37979, lng: 20.6517123 },
    { lat: 49.3794064, lng: 20.6534264 },
    { lat: 49.3790584, lng: 20.654554 },
    { lat: 49.3784075, lng: 20.6556578 },
    { lat: 49.3779443, lng: 20.6568384 },
    { lat: 49.3777016, lng: 20.6571672 },
    { lat: 49.3772447, lng: 20.6573929 },
    { lat: 49.3764726, lng: 20.6563837 },
    { lat: 49.3763067, lng: 20.655419 },
    { lat: 49.3759708, lng: 20.6553716 },
    { lat: 49.3759235, lng: 20.6552506 },
    { lat: 49.3759412, lng: 20.6545418 },
    { lat: 49.3751945, lng: 20.6544037 },
    { lat: 49.3752117, lng: 20.6540981 },
    { lat: 49.3752926, lng: 20.6539342 },
    { lat: 49.3752387, lng: 20.6536862 },
    { lat: 49.3748543, lng: 20.6534825 },
    { lat: 49.3748167, lng: 20.6536583 },
    { lat: 49.3743673, lng: 20.6534318 },
    { lat: 49.3744612, lng: 20.652876 },
    { lat: 49.3741959, lng: 20.6526648 },
    { lat: 49.3739714, lng: 20.6531571 },
    { lat: 49.372993, lng: 20.6529579 },
    { lat: 49.3706223, lng: 20.647996 },
    { lat: 49.3693216, lng: 20.6468754 },
    { lat: 49.36889, lng: 20.645595 },
    { lat: 49.3682406, lng: 20.6445626 },
    { lat: 49.367734, lng: 20.6441145 },
    { lat: 49.367536, lng: 20.6432076 },
    { lat: 49.3673258, lng: 20.6428954 },
    { lat: 49.3666127, lng: 20.6423773 },
    { lat: 49.3660698, lng: 20.6415159 },
    { lat: 49.3657595, lng: 20.6412747 },
    { lat: 49.3655587, lng: 20.641259 },
    { lat: 49.365491, lng: 20.6411343 },
    { lat: 49.365541, lng: 20.6407971 },
    { lat: 49.3650323, lng: 20.6404891 },
    { lat: 49.3649254, lng: 20.6401116 },
    { lat: 49.3649354, lng: 20.6396651 },
    { lat: 49.3648007, lng: 20.6393625 },
    { lat: 49.3648156, lng: 20.6384851 },
    { lat: 49.364587, lng: 20.638202 },
    { lat: 49.364382, lng: 20.638069 },
    { lat: 49.3642933, lng: 20.6379218 },
    { lat: 49.3639637, lng: 20.6381766 },
    { lat: 49.3632425, lng: 20.6382776 },
    { lat: 49.3632493, lng: 20.6379996 },
    { lat: 49.3630789, lng: 20.6372328 },
    { lat: 49.3626292, lng: 20.6369653 },
    { lat: 49.3618027, lng: 20.6366107 },
    { lat: 49.3601719, lng: 20.6356999 },
    { lat: 49.3585817, lng: 20.6336327 },
    { lat: 49.354853, lng: 20.6309473 },
    { lat: 49.3543839, lng: 20.6302635 },
    { lat: 49.3544375, lng: 20.6298423 },
    { lat: 49.3543709, lng: 20.6296175 },
    { lat: 49.3543926, lng: 20.6294991 },
    { lat: 49.3540804, lng: 20.6289544 },
    { lat: 49.3540516, lng: 20.6289095 },
    { lat: 49.3536617, lng: 20.6299941 },
    { lat: 49.353054, lng: 20.6301828 },
    { lat: 49.3527452, lng: 20.629609 },
    { lat: 49.3526389, lng: 20.6295323 },
    { lat: 49.3525519, lng: 20.6295537 },
    { lat: 49.3504159, lng: 20.6308839 },
    { lat: 49.3495189, lng: 20.6318965 },
    { lat: 49.3495945, lng: 20.6332177 },
    { lat: 49.3490861, lng: 20.6332622 },
    { lat: 49.3477595, lng: 20.6339372 },
    { lat: 49.3472716, lng: 20.6346467 },
    { lat: 49.3468534, lng: 20.6364505 },
    { lat: 49.3458502, lng: 20.6373253 },
    { lat: 49.34561, lng: 20.6378119 },
    { lat: 49.3444328, lng: 20.6385449 },
    { lat: 49.3437167, lng: 20.6382547 },
    { lat: 49.3422737, lng: 20.6370563 },
    { lat: 49.341601, lng: 20.6375109 },
    { lat: 49.3407653, lng: 20.6378145 },
    { lat: 49.3400492, lng: 20.637275 },
    { lat: 49.3397368, lng: 20.6375347 },
    { lat: 49.3391367, lng: 20.6383499 },
    { lat: 49.3376517, lng: 20.6405741 },
    { lat: 49.3367332, lng: 20.6405749 },
    { lat: 49.3359342, lng: 20.6413349 },
    { lat: 49.334744, lng: 20.6419908 },
    { lat: 49.3340568, lng: 20.6422249 },
    { lat: 49.3340032, lng: 20.6425527 },
    { lat: 49.3336419, lng: 20.6433641 },
    { lat: 49.331702, lng: 20.6449918 },
    { lat: 49.3316297, lng: 20.6445139 },
    { lat: 49.3305849, lng: 20.6444768 },
    { lat: 49.3292005, lng: 20.6445436 },
    { lat: 49.3294209, lng: 20.6461752 },
    { lat: 49.3278245, lng: 20.6462618 },
    { lat: 49.3277474, lng: 20.6468428 },
    { lat: 49.3275955, lng: 20.6479461 },
    { lat: 49.3260577, lng: 20.6473739 },
    { lat: 49.326004, lng: 20.647382 },
    { lat: 49.3261208, lng: 20.6475934 },
    { lat: 49.3261168, lng: 20.6477074 },
    { lat: 49.3258621, lng: 20.6477944 },
    { lat: 49.3258194, lng: 20.6478601 },
    { lat: 49.3258135, lng: 20.6481056 },
    { lat: 49.325586, lng: 20.6483191 },
    { lat: 49.3253457, lng: 20.6482425 },
    { lat: 49.3244062, lng: 20.6483124 },
    { lat: 49.3244097, lng: 20.648412 },
    { lat: 49.3241963, lng: 20.648803 },
    { lat: 49.3241523, lng: 20.6493083 },
    { lat: 49.3242387, lng: 20.6497056 },
    { lat: 49.3245559, lng: 20.653611 },
    { lat: 49.3247489, lng: 20.6551741 },
    { lat: 49.3254686, lng: 20.6582802 },
    { lat: 49.3258364, lng: 20.6594404 },
    { lat: 49.3266879, lng: 20.6597447 },
    { lat: 49.3269016, lng: 20.6603664 },
    { lat: 49.3276604, lng: 20.6648946 },
    { lat: 49.3286893, lng: 20.6677805 },
    { lat: 49.3291753, lng: 20.668848 },
    { lat: 49.3296979, lng: 20.6691762 },
    { lat: 49.3302229, lng: 20.6692552 },
    { lat: 49.3354538, lng: 20.6757615 },
    { lat: 49.3355869, lng: 20.6766546 },
    { lat: 49.3362825, lng: 20.6771014 },
    { lat: 49.3364582, lng: 20.678632 },
    { lat: 49.3367429, lng: 20.6790401 },
    { lat: 49.3374155, lng: 20.6811528 },
    { lat: 49.3376734, lng: 20.6814155 },
    { lat: 49.3380213, lng: 20.6818956 },
    { lat: 49.3390364, lng: 20.6821405 },
    { lat: 49.3420056, lng: 20.6815394 },
    { lat: 49.3446123, lng: 20.6818505 },
    { lat: 49.3455171, lng: 20.6820978 },
    { lat: 49.3454818, lng: 20.6830715 },
    { lat: 49.3451051, lng: 20.6856915 },
    { lat: 49.3452752, lng: 20.6864001 },
    { lat: 49.3486781, lng: 20.6868235 },
    { lat: 49.3509003, lng: 20.6870802 },
    { lat: 49.3520752, lng: 20.6869481 },
    { lat: 49.3521703, lng: 20.6866496 },
    { lat: 49.3534001, lng: 20.6857418 },
    { lat: 49.354072, lng: 20.6860598 },
    { lat: 49.3570901, lng: 20.6842608 },
    { lat: 49.3579782, lng: 20.684102 },
    { lat: 49.3580377, lng: 20.6842581 },
    { lat: 49.3584128, lng: 20.6839139 },
    { lat: 49.3596026, lng: 20.6831504 },
    { lat: 49.3602267, lng: 20.6823692 },
    { lat: 49.3606958, lng: 20.6813426 },
    { lat: 49.3611317, lng: 20.6817043 },
    { lat: 49.3617925, lng: 20.6815798 },
    { lat: 49.3625435, lng: 20.6810133 },
    { lat: 49.3633831, lng: 20.6822184 },
    { lat: 49.3645463, lng: 20.6829637 },
    { lat: 49.3652752, lng: 20.682125 },
    { lat: 49.3682038, lng: 20.6845701 },
    { lat: 49.3692082, lng: 20.6856317 },
    { lat: 49.369199, lng: 20.6857191 },
    { lat: 49.3692599, lng: 20.6857621 },
    { lat: 49.3702982, lng: 20.6862142 },
    { lat: 49.3709025, lng: 20.6862489 },
    { lat: 49.3711618, lng: 20.6859717 },
    { lat: 49.3727787, lng: 20.685099 },
    { lat: 49.3730796, lng: 20.6845647 },
    { lat: 49.3732878, lng: 20.6843759 },
    { lat: 49.3732994, lng: 20.6842246 },
    { lat: 49.3734957, lng: 20.683858 },
    { lat: 49.3741037, lng: 20.6831895 },
    { lat: 49.3756865, lng: 20.6805299 },
    { lat: 49.3756943, lng: 20.6800507 },
    { lat: 49.3762537, lng: 20.6798272 },
    { lat: 49.3771764, lng: 20.6790563 },
    { lat: 49.377167, lng: 20.678949 },
    { lat: 49.3776526, lng: 20.6783881 },
    { lat: 49.3783187, lng: 20.6780622 },
    { lat: 49.378729, lng: 20.6789056 },
    { lat: 49.3788517, lng: 20.679033 },
    { lat: 49.3790967, lng: 20.6791189 },
    { lat: 49.3801433, lng: 20.6791143 },
    { lat: 49.381053, lng: 20.6789007 },
    { lat: 49.3819176, lng: 20.6781725 },
    { lat: 49.383533, lng: 20.6766213 },
    { lat: 49.3841014, lng: 20.6766542 },
    { lat: 49.3843109, lng: 20.6765624 },
    { lat: 49.3848233, lng: 20.6765371 },
    { lat: 49.3856748, lng: 20.6766849 },
    { lat: 49.3860596, lng: 20.6769008 },
    { lat: 49.3863502, lng: 20.676808 },
    { lat: 49.3865854, lng: 20.677003 },
    { lat: 49.3868862, lng: 20.6769462 },
    { lat: 49.3872458, lng: 20.6767032 },
    { lat: 49.3879853, lng: 20.6766446 },
    { lat: 49.3881341, lng: 20.676711 },
    { lat: 49.388924, lng: 20.6767392 },
    { lat: 49.3891059, lng: 20.6766938 },
    { lat: 49.3909032, lng: 20.6769067 },
    { lat: 49.3925136, lng: 20.6753748 },
    { lat: 49.3942285, lng: 20.6754584 },
    { lat: 49.395712, lng: 20.6763819 },
    { lat: 49.3968945, lng: 20.6761137 },
    { lat: 49.399213, lng: 20.6791907 },
  ],
  Jakubany: [
    { lat: 49.191959, lng: 20.7427004 },
    { lat: 49.192563, lng: 20.7427807 },
    { lat: 49.1928974, lng: 20.7423349 },
    { lat: 49.1939183, lng: 20.7413379 },
    { lat: 49.1945119, lng: 20.7408737 },
    { lat: 49.194672, lng: 20.7407485 },
    { lat: 49.1953547, lng: 20.7397347 },
    { lat: 49.1955137, lng: 20.7394086 },
    { lat: 49.1959596, lng: 20.7384937 },
    { lat: 49.1960468, lng: 20.7383877 },
    { lat: 49.1965574, lng: 20.7377672 },
    { lat: 49.1970532, lng: 20.7371646 },
    { lat: 49.1971471, lng: 20.7370504 },
    { lat: 49.1974927, lng: 20.736217 },
    { lat: 49.1975848, lng: 20.735602 },
    { lat: 49.1975873, lng: 20.7353822 },
    { lat: 49.1975904, lng: 20.7351156 },
    { lat: 49.1976546, lng: 20.7349421 },
    { lat: 49.1977723, lng: 20.7346242 },
    { lat: 49.1979645, lng: 20.7337512 },
    { lat: 49.1983104, lng: 20.7336326 },
    { lat: 49.1983544, lng: 20.7335756 },
    { lat: 49.1987204, lng: 20.733101 },
    { lat: 49.1991803, lng: 20.7325047 },
    { lat: 49.1993536, lng: 20.7318977 },
    { lat: 49.1995328, lng: 20.7312705 },
    { lat: 49.1996185, lng: 20.7309702 },
    { lat: 49.1996889, lng: 20.730934 },
    { lat: 49.2000983, lng: 20.7307236 },
    { lat: 49.2002907, lng: 20.7306247 },
    { lat: 49.2005491, lng: 20.7301685 },
    { lat: 49.2007675, lng: 20.7297831 },
    { lat: 49.2010232, lng: 20.7293317 },
    { lat: 49.2016016, lng: 20.7290282 },
    { lat: 49.2016344, lng: 20.729011 },
    { lat: 49.2021097, lng: 20.7285007 },
    { lat: 49.2024951, lng: 20.728087 },
    { lat: 49.2025758, lng: 20.7280003 },
    { lat: 49.203746, lng: 20.7274781 },
    { lat: 49.2038283, lng: 20.7274507 },
    { lat: 49.2046462, lng: 20.7279176 },
    { lat: 49.2054779, lng: 20.7281062 },
    { lat: 49.2075306, lng: 20.7278677 },
    { lat: 49.2079624, lng: 20.7279181 },
    { lat: 49.20832, lng: 20.7277509 },
    { lat: 49.2089559, lng: 20.7267841 },
    { lat: 49.2101159, lng: 20.7245971 },
    { lat: 49.2103031, lng: 20.7239622 },
    { lat: 49.2104217, lng: 20.7237538 },
    { lat: 49.2105404, lng: 20.7237613 },
    { lat: 49.2108266, lng: 20.7230672 },
    { lat: 49.2108646, lng: 20.7224506 },
    { lat: 49.2110614, lng: 20.7221904 },
    { lat: 49.2113883, lng: 20.722044 },
    { lat: 49.2114274, lng: 20.7217795 },
    { lat: 49.2116174, lng: 20.7216159 },
    { lat: 49.2118142, lng: 20.7208733 },
    { lat: 49.2116575, lng: 20.7201264 },
    { lat: 49.2116692, lng: 20.7198907 },
    { lat: 49.211826, lng: 20.7196061 },
    { lat: 49.2121727, lng: 20.7191629 },
    { lat: 49.2121907, lng: 20.7189979 },
    { lat: 49.2123813, lng: 20.7188189 },
    { lat: 49.2123956, lng: 20.7183939 },
    { lat: 49.212781, lng: 20.7175155 },
    { lat: 49.212935, lng: 20.7169643 },
    { lat: 49.2129839, lng: 20.716143 },
    { lat: 49.2129018, lng: 20.7155081 },
    { lat: 49.2128485, lng: 20.7142228 },
    { lat: 49.2128575, lng: 20.7138026 },
    { lat: 49.213074, lng: 20.7130788 },
    { lat: 49.2129899, lng: 20.711971 },
    { lat: 49.2147778, lng: 20.7118883 },
    { lat: 49.2151906, lng: 20.7118162 },
    { lat: 49.2156544, lng: 20.7122318 },
    { lat: 49.2159157, lng: 20.7113767 },
    { lat: 49.2177512, lng: 20.7112659 },
    { lat: 49.2188017, lng: 20.7118526 },
    { lat: 49.2194155, lng: 20.7115403 },
    { lat: 49.2202468, lng: 20.7114958 },
    { lat: 49.2206106, lng: 20.7120388 },
    { lat: 49.2209186, lng: 20.7127009 },
    { lat: 49.2215452, lng: 20.7133009 },
    { lat: 49.2229932, lng: 20.7131528 },
    { lat: 49.2232128, lng: 20.7138892 },
    { lat: 49.2234172, lng: 20.7140067 },
    { lat: 49.2235663, lng: 20.7143085 },
    { lat: 49.2238523, lng: 20.7142123 },
    { lat: 49.2240999, lng: 20.7144365 },
    { lat: 49.2245233, lng: 20.7149858 },
    { lat: 49.2247205, lng: 20.7153758 },
    { lat: 49.2248217, lng: 20.7158173 },
    { lat: 49.2250792, lng: 20.7163252 },
    { lat: 49.2251735, lng: 20.7168245 },
    { lat: 49.2253109, lng: 20.7170171 },
    { lat: 49.2256745, lng: 20.7172156 },
    { lat: 49.2258024, lng: 20.7178393 },
    { lat: 49.2261608, lng: 20.7187822 },
    { lat: 49.2261405, lng: 20.7191029 },
    { lat: 49.2262523, lng: 20.7192029 },
    { lat: 49.2263388, lng: 20.7194886 },
    { lat: 49.226537, lng: 20.7198141 },
    { lat: 49.2265366, lng: 20.7200576 },
    { lat: 49.2266018, lng: 20.7202816 },
    { lat: 49.2271423, lng: 20.7208949 },
    { lat: 49.2274131, lng: 20.7210362 },
    { lat: 49.2275235, lng: 20.7211698 },
    { lat: 49.2275692, lng: 20.7214426 },
    { lat: 49.2278062, lng: 20.7218114 },
    { lat: 49.22796, lng: 20.7218305 },
    { lat: 49.2280892, lng: 20.7220854 },
    { lat: 49.2281176, lng: 20.7223478 },
    { lat: 49.2285651, lng: 20.7231016 },
    { lat: 49.2286984, lng: 20.7234645 },
    { lat: 49.2289624, lng: 20.7234879 },
    { lat: 49.2292226, lng: 20.7233618 },
    { lat: 49.2296482, lng: 20.7237512 },
    { lat: 49.2302453, lng: 20.724983 },
    { lat: 49.2303548, lng: 20.7247982 },
    { lat: 49.2311386, lng: 20.7251597 },
    { lat: 49.2319522, lng: 20.7261171 },
    { lat: 49.2321034, lng: 20.7255536 },
    { lat: 49.2331733, lng: 20.7257941 },
    { lat: 49.2339919, lng: 20.7267925 },
    { lat: 49.2345108, lng: 20.7281414 },
    { lat: 49.2351072, lng: 20.7286491 },
    { lat: 49.2379907, lng: 20.73031 },
    { lat: 49.2385398, lng: 20.7311364 },
    { lat: 49.2390069, lng: 20.7312016 },
    { lat: 49.2415929, lng: 20.7323096 },
    { lat: 49.2416485, lng: 20.7318709 },
    { lat: 49.2426491, lng: 20.7322534 },
    { lat: 49.2428078, lng: 20.7321255 },
    { lat: 49.243386, lng: 20.7334595 },
    { lat: 49.2436178, lng: 20.7335868 },
    { lat: 49.243652, lng: 20.7336683 },
    { lat: 49.243505, lng: 20.7336803 },
    { lat: 49.2435027, lng: 20.7337756 },
    { lat: 49.2434058, lng: 20.7338358 },
    { lat: 49.243355, lng: 20.7342167 },
    { lat: 49.2436349, lng: 20.735199 },
    { lat: 49.2438565, lng: 20.7352288 },
    { lat: 49.2439391, lng: 20.7354061 },
    { lat: 49.2436924, lng: 20.7356547 },
    { lat: 49.2436649, lng: 20.7363176 },
    { lat: 49.2435804, lng: 20.7364697 },
    { lat: 49.2437629, lng: 20.7370215 },
    { lat: 49.2437236, lng: 20.7371601 },
    { lat: 49.2438374, lng: 20.7374182 },
    { lat: 49.2440252, lng: 20.7376024 },
    { lat: 49.2441582, lng: 20.7380246 },
    { lat: 49.2443976, lng: 20.7381787 },
    { lat: 49.2445175, lng: 20.7386808 },
    { lat: 49.2442899, lng: 20.7389905 },
    { lat: 49.2451742, lng: 20.7389777 },
    { lat: 49.2457966, lng: 20.7380478 },
    { lat: 49.2459713, lng: 20.7376012 },
    { lat: 49.2469207, lng: 20.7362692 },
    { lat: 49.2486326, lng: 20.7350751 },
    { lat: 49.2487369, lng: 20.7341946 },
    { lat: 49.2494159, lng: 20.7348802 },
    { lat: 49.2495876, lng: 20.7358959 },
    { lat: 49.2499919, lng: 20.7365781 },
    { lat: 49.2511591, lng: 20.7369248 },
    { lat: 49.2511654, lng: 20.7363982 },
    { lat: 49.2511773, lng: 20.7352616 },
    { lat: 49.2514928, lng: 20.7344647 },
    { lat: 49.2523868, lng: 20.7332894 },
    { lat: 49.2530371, lng: 20.7321855 },
    { lat: 49.2536742, lng: 20.7316091 },
    { lat: 49.2540723, lng: 20.7316335 },
    { lat: 49.2546716, lng: 20.731841 },
    { lat: 49.2551014, lng: 20.7321853 },
    { lat: 49.2555256, lng: 20.7318612 },
    { lat: 49.2560192, lng: 20.7312057 },
    { lat: 49.2568707, lng: 20.7302575 },
    { lat: 49.2575301, lng: 20.7300259 },
    { lat: 49.2586962, lng: 20.7290659 },
    { lat: 49.2592858, lng: 20.7289279 },
    { lat: 49.2595354, lng: 20.7285993 },
    { lat: 49.2600664, lng: 20.7282383 },
    { lat: 49.2602856, lng: 20.728202 },
    { lat: 49.2608585, lng: 20.7282001 },
    { lat: 49.2609384, lng: 20.728132 },
    { lat: 49.2609936, lng: 20.7281642 },
    { lat: 49.2609379, lng: 20.7278952 },
    { lat: 49.2605698, lng: 20.7274136 },
    { lat: 49.260156, lng: 20.7263956 },
    { lat: 49.2599729, lng: 20.7262466 },
    { lat: 49.2596879, lng: 20.7255402 },
    { lat: 49.2594298, lng: 20.7252082 },
    { lat: 49.2593772, lng: 20.7249931 },
    { lat: 49.2593047, lng: 20.7240826 },
    { lat: 49.2590391, lng: 20.723726 },
    { lat: 49.258984, lng: 20.7234418 },
    { lat: 49.2584688, lng: 20.7226469 },
    { lat: 49.2589293, lng: 20.7220113 },
    { lat: 49.2587783, lng: 20.721756 },
    { lat: 49.2594905, lng: 20.7205584 },
    { lat: 49.2595607, lng: 20.7188095 },
    { lat: 49.2586225, lng: 20.71668 },
    { lat: 49.2583018, lng: 20.711859 },
    { lat: 49.2591252, lng: 20.7095736 },
    { lat: 49.2593607, lng: 20.705269 },
    { lat: 49.2592448, lng: 20.7018258 },
    { lat: 49.2595638, lng: 20.6995122 },
    { lat: 49.2600171, lng: 20.6956803 },
    { lat: 49.2595721, lng: 20.6951631 },
    { lat: 49.2598482, lng: 20.693293 },
    { lat: 49.2597004, lng: 20.6912161 },
    { lat: 49.2594677, lng: 20.6897603 },
    { lat: 49.2607913, lng: 20.6891221 },
    { lat: 49.2607829, lng: 20.6888533 },
    { lat: 49.2605702, lng: 20.6886683 },
    { lat: 49.2598797, lng: 20.6841727 },
    { lat: 49.2593135, lng: 20.6810637 },
    { lat: 49.2572024, lng: 20.6728106 },
    { lat: 49.2571954, lng: 20.6726735 },
    { lat: 49.2572919, lng: 20.672223 },
    { lat: 49.2573246, lng: 20.6714047 },
    { lat: 49.2572334, lng: 20.6697233 },
    { lat: 49.2570943, lng: 20.6690422 },
    { lat: 49.2567357, lng: 20.6680752 },
    { lat: 49.256091, lng: 20.6652714 },
    { lat: 49.2560424, lng: 20.6649202 },
    { lat: 49.2561066, lng: 20.6644773 },
    { lat: 49.2557967, lng: 20.6648772 },
    { lat: 49.2552939, lng: 20.6648844 },
    { lat: 49.2550483, lng: 20.6646915 },
    { lat: 49.2544053, lng: 20.6645266 },
    { lat: 49.2537453, lng: 20.6646114 },
    { lat: 49.2529773, lng: 20.6643491 },
    { lat: 49.2522905, lng: 20.6643778 },
    { lat: 49.2516856, lng: 20.6637637 },
    { lat: 49.2517797, lng: 20.6633728 },
    { lat: 49.2516638, lng: 20.6629673 },
    { lat: 49.2510531, lng: 20.6622197 },
    { lat: 49.2503403, lng: 20.6603563 },
    { lat: 49.2499428, lng: 20.6599379 },
    { lat: 49.2494845, lng: 20.6599043 },
    { lat: 49.2492945, lng: 20.6593739 },
    { lat: 49.2490575, lng: 20.6591973 },
    { lat: 49.2487876, lng: 20.6584822 },
    { lat: 49.2484894, lng: 20.6581017 },
    { lat: 49.2481384, lng: 20.6574474 },
    { lat: 49.2477159, lng: 20.6570243 },
    { lat: 49.2478107, lng: 20.6551413 },
    { lat: 49.2477856, lng: 20.6550407 },
    { lat: 49.2473101, lng: 20.6551204 },
    { lat: 49.2471111, lng: 20.6552548 },
    { lat: 49.2467677, lng: 20.6550319 },
    { lat: 49.2465139, lng: 20.6550185 },
    { lat: 49.2462682, lng: 20.6547572 },
    { lat: 49.2461902, lng: 20.6545277 },
    { lat: 49.2459672, lng: 20.6543006 },
    { lat: 49.2458389, lng: 20.6539838 },
    { lat: 49.2455566, lng: 20.6537079 },
    { lat: 49.2450138, lng: 20.6534484 },
    { lat: 49.2445943, lng: 20.6531155 },
    { lat: 49.2441828, lng: 20.6526587 },
    { lat: 49.2432237, lng: 20.6517735 },
    { lat: 49.2430625, lng: 20.6518904 },
    { lat: 49.2427462, lng: 20.6518649 },
    { lat: 49.2425331, lng: 20.6520069 },
    { lat: 49.2421908, lng: 20.6525395 },
    { lat: 49.2418811, lng: 20.6524133 },
    { lat: 49.2414106, lng: 20.6524446 },
    { lat: 49.2408436, lng: 20.6522675 },
    { lat: 49.2402357, lng: 20.6522 },
    { lat: 49.2399106, lng: 20.6524897 },
    { lat: 49.2384427, lng: 20.6549342 },
    { lat: 49.2384105, lng: 20.6549877 },
    { lat: 49.238366, lng: 20.6550941 },
    { lat: 49.2380702, lng: 20.655801 },
    { lat: 49.2376994, lng: 20.6562574 },
    { lat: 49.2374318, lng: 20.6567415 },
    { lat: 49.2368494, lng: 20.6583332 },
    { lat: 49.2365147, lng: 20.6586716 },
    { lat: 49.2359334, lng: 20.6597329 },
    { lat: 49.2356038, lng: 20.6598025 },
    { lat: 49.2352935, lng: 20.6599721 },
    { lat: 49.2349398, lng: 20.6604039 },
    { lat: 49.2343492, lng: 20.6613899 },
    { lat: 49.2340625, lng: 20.6615917 },
    { lat: 49.2312902, lng: 20.658666 },
    { lat: 49.2286788, lng: 20.6551654 },
    { lat: 49.2270098, lng: 20.6542996 },
    { lat: 49.2264384, lng: 20.6542172 },
    { lat: 49.2245254, lng: 20.6543885 },
    { lat: 49.2230195, lng: 20.6549281 },
    { lat: 49.2224525, lng: 20.6550697 },
    { lat: 49.2216715, lng: 20.6550426 },
    { lat: 49.2204392, lng: 20.6547372 },
    { lat: 49.2200461, lng: 20.6542255 },
    { lat: 49.2196127, lng: 20.6521143 },
    { lat: 49.2192081, lng: 20.6510976 },
    { lat: 49.2190136, lng: 20.6509399 },
    { lat: 49.2171704, lng: 20.6510856 },
    { lat: 49.2162281, lng: 20.6506777 },
    { lat: 49.2157787, lng: 20.6503213 },
    { lat: 49.2154561, lng: 20.6501804 },
    { lat: 49.2150614, lng: 20.6497808 },
    { lat: 49.2135247, lng: 20.6477605 },
    { lat: 49.213067, lng: 20.6470224 },
    { lat: 49.2129157, lng: 20.6465706 },
    { lat: 49.2125714, lng: 20.6459753 },
    { lat: 49.212559, lng: 20.645536 },
    { lat: 49.2114412, lng: 20.6449391 },
    { lat: 49.2107657, lng: 20.6447838 },
    { lat: 49.2103787, lng: 20.6446015 },
    { lat: 49.2092631, lng: 20.6435008 },
    { lat: 49.2083904, lng: 20.6427992 },
    { lat: 49.2072281, lng: 20.6414937 },
    { lat: 49.2054666, lng: 20.6386766 },
    { lat: 49.2049763, lng: 20.6382856 },
    { lat: 49.2047366, lng: 20.6382257 },
    { lat: 49.2045989, lng: 20.6380874 },
    { lat: 49.2045729, lng: 20.6380867 },
    { lat: 49.2042994, lng: 20.6381492 },
    { lat: 49.2029597, lng: 20.6376154 },
    { lat: 49.2025305, lng: 20.6376052 },
    { lat: 49.2022088, lng: 20.6374554 },
    { lat: 49.2013534, lng: 20.6376432 },
    { lat: 49.2011724, lng: 20.6375877 },
    { lat: 49.1993578, lng: 20.6365196 },
    { lat: 49.1986368, lng: 20.6359453 },
    { lat: 49.1984223, lng: 20.6357411 },
    { lat: 49.1979159, lng: 20.6349927 },
    { lat: 49.1969551, lng: 20.6338751 },
    { lat: 49.1963441, lng: 20.6323519 },
    { lat: 49.1958477, lng: 20.6317283 },
    { lat: 49.1952631, lng: 20.630367 },
    { lat: 49.1941343, lng: 20.6285309 },
    { lat: 49.1938608, lng: 20.6280035 },
    { lat: 49.1936323, lng: 20.6274097 },
    { lat: 49.1933022, lng: 20.6271829 },
    { lat: 49.1930559, lng: 20.6264369 },
    { lat: 49.1929119, lng: 20.6251295 },
    { lat: 49.1929524, lng: 20.6245002 },
    { lat: 49.1928886, lng: 20.6241697 },
    { lat: 49.1921263, lng: 20.6227562 },
    { lat: 49.1915712, lng: 20.6214074 },
    { lat: 49.1913069, lng: 20.6208224 },
    { lat: 49.1910791, lng: 20.620315 },
    { lat: 49.1907943, lng: 20.6198476 },
    { lat: 49.190518, lng: 20.6192043 },
    { lat: 49.1902724, lng: 20.6189809 },
    { lat: 49.1901243, lng: 20.6185312 },
    { lat: 49.1898838, lng: 20.6181364 },
    { lat: 49.1894196, lng: 20.6174056 },
    { lat: 49.1891406, lng: 20.616787 },
    { lat: 49.1888701, lng: 20.6163433 },
    { lat: 49.1887534, lng: 20.6160109 },
    { lat: 49.1885177, lng: 20.6155265 },
    { lat: 49.1866782, lng: 20.6165268 },
    { lat: 49.1866407, lng: 20.6165454 },
    { lat: 49.1851878, lng: 20.6173372 },
    { lat: 49.1849854, lng: 20.6174085 },
    { lat: 49.1845239, lng: 20.6175031 },
    { lat: 49.184459, lng: 20.6175405 },
    { lat: 49.1843354, lng: 20.6176409 },
    { lat: 49.184283, lng: 20.6176859 },
    { lat: 49.1842014, lng: 20.6177381 },
    { lat: 49.1840748, lng: 20.6177862 },
    { lat: 49.1839388, lng: 20.6178534 },
    { lat: 49.183733, lng: 20.6179808 },
    { lat: 49.183535, lng: 20.6181428 },
    { lat: 49.1834664, lng: 20.6182346 },
    { lat: 49.1833794, lng: 20.6183538 },
    { lat: 49.1833521, lng: 20.6185 },
    { lat: 49.1832723, lng: 20.6186604 },
    { lat: 49.1832103, lng: 20.6188146 },
    { lat: 49.183047, lng: 20.6190924 },
    { lat: 49.1829756, lng: 20.6191405 },
    { lat: 49.1827297, lng: 20.6192828 },
    { lat: 49.1823752, lng: 20.6194122 },
    { lat: 49.1822731, lng: 20.6194546 },
    { lat: 49.1821649, lng: 20.6194824 },
    { lat: 49.1820178, lng: 20.6194257 },
    { lat: 49.1815839, lng: 20.619443 },
    { lat: 49.1815426, lng: 20.6194231 },
    { lat: 49.1815081, lng: 20.6194023 },
    { lat: 49.1810474, lng: 20.6193632 },
    { lat: 49.1808287, lng: 20.6192632 },
    { lat: 49.1806493, lng: 20.6192545 },
    { lat: 49.1800867, lng: 20.6189466 },
    { lat: 49.1799297, lng: 20.6188396 },
    { lat: 49.1798796, lng: 20.6188215 },
    { lat: 49.1797324, lng: 20.6187971 },
    { lat: 49.1797161, lng: 20.6188151 },
    { lat: 49.1796207, lng: 20.618695 },
    { lat: 49.1793447, lng: 20.6185962 },
    { lat: 49.1788416, lng: 20.6183491 },
    { lat: 49.1786717, lng: 20.6183231 },
    { lat: 49.1785069, lng: 20.618247 },
    { lat: 49.1782104, lng: 20.6182003 },
    { lat: 49.1780165, lng: 20.6181698 },
    { lat: 49.1778635, lng: 20.618229 },
    { lat: 49.1777869, lng: 20.6182581 },
    { lat: 49.1773259, lng: 20.6183343 },
    { lat: 49.1771413, lng: 20.6183365 },
    { lat: 49.176972, lng: 20.6183266 },
    { lat: 49.1767641, lng: 20.6182713 },
    { lat: 49.176578, lng: 20.6181686 },
    { lat: 49.1765905, lng: 20.6181406 },
    { lat: 49.1764737, lng: 20.6179643 },
    { lat: 49.1762758, lng: 20.6178464 },
    { lat: 49.1761942, lng: 20.6177964 },
    { lat: 49.1757438, lng: 20.6175133 },
    { lat: 49.1754305, lng: 20.6173158 },
    { lat: 49.1748303, lng: 20.6169391 },
    { lat: 49.1736524, lng: 20.6174882 },
    { lat: 49.1732178, lng: 20.6179546 },
    { lat: 49.1719585, lng: 20.6193051 },
    { lat: 49.1719585, lng: 20.6193055 },
    { lat: 49.1719347, lng: 20.6193327 },
    { lat: 49.1716636, lng: 20.6193054 },
    { lat: 49.1712977, lng: 20.6193041 },
    { lat: 49.1708032, lng: 20.6192956 },
    { lat: 49.1706768, lng: 20.6192904 },
    { lat: 49.1705391, lng: 20.6193383 },
    { lat: 49.1703649, lng: 20.6193799 },
    { lat: 49.1700336, lng: 20.6194286 },
    { lat: 49.1698345, lng: 20.6195021 },
    { lat: 49.1696599, lng: 20.619592 },
    { lat: 49.1693439, lng: 20.6196592 },
    { lat: 49.1691589, lng: 20.6195865 },
    { lat: 49.1690584, lng: 20.6195788 },
    { lat: 49.1688015, lng: 20.6196054 },
    { lat: 49.1686452, lng: 20.6196412 },
    { lat: 49.1681839, lng: 20.6198321 },
    { lat: 49.1679684, lng: 20.6199476 },
    { lat: 49.1678618, lng: 20.6200454 },
    { lat: 49.1675577, lng: 20.6202346 },
    { lat: 49.1670129, lng: 20.6203893 },
    { lat: 49.1664554, lng: 20.6204131 },
    { lat: 49.1661763, lng: 20.6204182 },
    { lat: 49.1661173, lng: 20.620403 },
    { lat: 49.165995, lng: 20.6203492 },
    { lat: 49.1658086, lng: 20.6202109 },
    { lat: 49.1655544, lng: 20.6199959 },
    { lat: 49.1654295, lng: 20.6198697 },
    { lat: 49.1653851, lng: 20.6198302 },
    { lat: 49.1652568, lng: 20.6197637 },
    { lat: 49.1651277, lng: 20.6197094 },
    { lat: 49.1650215, lng: 20.6196701 },
    { lat: 49.164876, lng: 20.6196327 },
    { lat: 49.1645361, lng: 20.6195723 },
    { lat: 49.164313, lng: 20.6194432 },
    { lat: 49.1640284, lng: 20.6194484 },
    { lat: 49.1638886, lng: 20.6194443 },
    { lat: 49.163475, lng: 20.6193042 },
    { lat: 49.1633389, lng: 20.6192448 },
    { lat: 49.1632949, lng: 20.619242 },
    { lat: 49.1630395, lng: 20.6192433 },
    { lat: 49.1628621, lng: 20.619197 },
    { lat: 49.1626026, lng: 20.6191188 },
    { lat: 49.1622392, lng: 20.6192151 },
    { lat: 49.1620369, lng: 20.6192889 },
    { lat: 49.161932, lng: 20.6194071 },
    { lat: 49.1618569, lng: 20.6195695 },
    { lat: 49.1618033, lng: 20.6197697 },
    { lat: 49.1615887, lng: 20.6201905 },
    { lat: 49.1614685, lng: 20.6205394 },
    { lat: 49.1612642, lng: 20.6209314 },
    { lat: 49.1611325, lng: 20.6210536 },
    { lat: 49.1608815, lng: 20.6212771 },
    { lat: 49.16074, lng: 20.6214073 },
    { lat: 49.16039, lng: 20.6217572 },
    { lat: 49.1601176, lng: 20.6221573 },
    { lat: 49.1599996, lng: 20.6223831 },
    { lat: 49.1598101, lng: 20.623651 },
    { lat: 49.1598137, lng: 20.6244114 },
    { lat: 49.1598091, lng: 20.6245801 },
    { lat: 49.1598007, lng: 20.6248902 },
    { lat: 49.1597892, lng: 20.6251641 },
    { lat: 49.1597923, lng: 20.6253437 },
    { lat: 49.159826, lng: 20.6257473 },
    { lat: 49.1597958, lng: 20.6260042 },
    { lat: 49.1597057, lng: 20.6262847 },
    { lat: 49.1595744, lng: 20.6267003 },
    { lat: 49.1594514, lng: 20.6270689 },
    { lat: 49.1593984, lng: 20.6272895 },
    { lat: 49.1593746, lng: 20.627467 },
    { lat: 49.1593441, lng: 20.6276948 },
    { lat: 49.1592841, lng: 20.6281425 },
    { lat: 49.1592371, lng: 20.6285263 },
    { lat: 49.1592297, lng: 20.6288197 },
    { lat: 49.1592053, lng: 20.6291451 },
    { lat: 49.159203, lng: 20.6294877 },
    { lat: 49.1592111, lng: 20.6298031 },
    { lat: 49.1592147, lng: 20.6301273 },
    { lat: 49.1591909, lng: 20.6304012 },
    { lat: 49.1591063, lng: 20.6307053 },
    { lat: 49.1590558, lng: 20.6309347 },
    { lat: 49.1588992, lng: 20.6316769 },
    { lat: 49.1587428, lng: 20.6318979 },
    { lat: 49.1586298, lng: 20.6320091 },
    { lat: 49.1584542, lng: 20.6321706 },
    { lat: 49.158013, lng: 20.6324848 },
    { lat: 49.1575812, lng: 20.6325917 },
    { lat: 49.1574459, lng: 20.6326797 },
    { lat: 49.157185, lng: 20.6329698 },
    { lat: 49.1570445, lng: 20.6331621 },
    { lat: 49.1569572, lng: 20.6333967 },
    { lat: 49.1568921, lng: 20.6335608 },
    { lat: 49.1567377, lng: 20.6341146 },
    { lat: 49.1565783, lng: 20.6345041 },
    { lat: 49.1564153, lng: 20.6350098 },
    { lat: 49.1563716, lng: 20.6351123 },
    { lat: 49.1562659, lng: 20.6352427 },
    { lat: 49.1560152, lng: 20.6354338 },
    { lat: 49.155742, lng: 20.6356365 },
    { lat: 49.1553516, lng: 20.6361409 },
    { lat: 49.1551485, lng: 20.6365039 },
    { lat: 49.1548994, lng: 20.6370609 },
    { lat: 49.1548013, lng: 20.6373054 },
    { lat: 49.1546494, lng: 20.6378112 },
    { lat: 49.1545467, lng: 20.6383657 },
    { lat: 49.1544334, lng: 20.638942 },
    { lat: 49.1543446, lng: 20.6393191 },
    { lat: 49.1542972, lng: 20.6394716 },
    { lat: 49.1542024, lng: 20.6397481 },
    { lat: 49.1540941, lng: 20.6400557 },
    { lat: 49.1537632, lng: 20.6407796 },
    { lat: 49.1532851, lng: 20.6418174 },
    { lat: 49.1529193, lng: 20.642136 },
    { lat: 49.1527269, lng: 20.6422636 },
    { lat: 49.1525624, lng: 20.6423521 },
    { lat: 49.1522633, lng: 20.642299 },
    { lat: 49.1520748, lng: 20.6422525 },
    { lat: 49.1524785, lng: 20.6435581 },
    { lat: 49.152967, lng: 20.6452508 },
    { lat: 49.1523248, lng: 20.6453949 },
    { lat: 49.1516322, lng: 20.6456688 },
    { lat: 49.1520316, lng: 20.6469794 },
    { lat: 49.1526169, lng: 20.648896 },
    { lat: 49.1526513, lng: 20.6489413 },
    { lat: 49.1527505, lng: 20.649069 },
    { lat: 49.1532754, lng: 20.6497747 },
    { lat: 49.1550282, lng: 20.6521951 },
    { lat: 49.1550897, lng: 20.6522126 },
    { lat: 49.1554208, lng: 20.6524663 },
    { lat: 49.1558996, lng: 20.6528282 },
    { lat: 49.1564101, lng: 20.6532162 },
    { lat: 49.1568426, lng: 20.6535377 },
    { lat: 49.1575537, lng: 20.6539676 },
    { lat: 49.157565, lng: 20.6539426 },
    { lat: 49.1578017, lng: 20.6544523 },
    { lat: 49.1582985, lng: 20.6553094 },
    { lat: 49.1585475, lng: 20.6557868 },
    { lat: 49.1586392, lng: 20.6563989 },
    { lat: 49.1589217, lng: 20.6574382 },
    { lat: 49.1590803, lng: 20.6578415 },
    { lat: 49.1593579, lng: 20.658522 },
    { lat: 49.1594598, lng: 20.658698 },
    { lat: 49.1598232, lng: 20.6591303 },
    { lat: 49.1602491, lng: 20.6595229 },
    { lat: 49.1605178, lng: 20.6597639 },
    { lat: 49.1610626, lng: 20.6602689 },
    { lat: 49.1619446, lng: 20.6610747 },
    { lat: 49.1620894, lng: 20.6612184 },
    { lat: 49.1625657, lng: 20.6616939 },
    { lat: 49.1636899, lng: 20.6628152 },
    { lat: 49.1642232, lng: 20.6633432 },
    { lat: 49.1642337, lng: 20.6633535 },
    { lat: 49.1642687, lng: 20.6633898 },
    { lat: 49.1643458, lng: 20.6634679 },
    { lat: 49.1647743, lng: 20.6636741 },
    { lat: 49.1651201, lng: 20.6639051 },
    { lat: 49.1655227, lng: 20.6641148 },
    { lat: 49.1660206, lng: 20.6643458 },
    { lat: 49.1663945, lng: 20.6647878 },
    { lat: 49.1666095, lng: 20.6650091 },
    { lat: 49.1668293, lng: 20.6652387 },
    { lat: 49.167172, lng: 20.6656031 },
    { lat: 49.1673442, lng: 20.6657881 },
    { lat: 49.1674876, lng: 20.6659917 },
    { lat: 49.1678089, lng: 20.6664474 },
    { lat: 49.168007, lng: 20.6668572 },
    { lat: 49.1682649, lng: 20.6677729 },
    { lat: 49.1683332, lng: 20.6681709 },
    { lat: 49.1687857, lng: 20.6690881 },
    { lat: 49.1690354, lng: 20.6696872 },
    { lat: 49.1691931, lng: 20.6706041 },
    { lat: 49.1693676, lng: 20.6717069 },
    { lat: 49.1695128, lng: 20.672623 },
    { lat: 49.1696187, lng: 20.6732927 },
    { lat: 49.1697289, lng: 20.673889 },
    { lat: 49.1699044, lng: 20.6752138 },
    { lat: 49.1700208, lng: 20.6761111 },
    { lat: 49.1701085, lng: 20.6766733 },
    { lat: 49.1701653, lng: 20.6770373 },
    { lat: 49.1703761, lng: 20.6778034 },
    { lat: 49.1707221, lng: 20.678418 },
    { lat: 49.1711934, lng: 20.6792607 },
    { lat: 49.1714519, lng: 20.6797254 },
    { lat: 49.1717572, lng: 20.6802365 },
    { lat: 49.1713769, lng: 20.6835647 },
    { lat: 49.1712522, lng: 20.6838614 },
    { lat: 49.170893, lng: 20.6844584 },
    { lat: 49.1708485, lng: 20.6845678 },
    { lat: 49.1708031, lng: 20.6846849 },
    { lat: 49.1706483, lng: 20.6850819 },
    { lat: 49.1705799, lng: 20.6853135 },
    { lat: 49.1705628, lng: 20.6853695 },
    { lat: 49.1703839, lng: 20.6858793 },
    { lat: 49.1703676, lng: 20.6859253 },
    { lat: 49.170285, lng: 20.6862033 },
    { lat: 49.1702578, lng: 20.6866049 },
    { lat: 49.1702188, lng: 20.6871556 },
    { lat: 49.1701674, lng: 20.6882125 },
    { lat: 49.1700973, lng: 20.6888072 },
    { lat: 49.1699835, lng: 20.6895961 },
    { lat: 49.1698913, lng: 20.6899174 },
    { lat: 49.1698743, lng: 20.6899746 },
    { lat: 49.1698422, lng: 20.690087 },
    { lat: 49.169584, lng: 20.6906173 },
    { lat: 49.1695643, lng: 20.6905988 },
    { lat: 49.1693403, lng: 20.6906946 },
    { lat: 49.1680928, lng: 20.6915418 },
    { lat: 49.1677918, lng: 20.6915943 },
    { lat: 49.1676036, lng: 20.6918735 },
    { lat: 49.1674337, lng: 20.6920652 },
    { lat: 49.1671512, lng: 20.6920676 },
    { lat: 49.1668842, lng: 20.6920616 },
    { lat: 49.16645, lng: 20.6921359 },
    { lat: 49.1662164, lng: 20.6929717 },
    { lat: 49.1661325, lng: 20.6932736 },
    { lat: 49.1658753, lng: 20.6942088 },
    { lat: 49.1658526, lng: 20.6942686 },
    { lat: 49.1658193, lng: 20.694348 },
    { lat: 49.1654805, lng: 20.6952039 },
    { lat: 49.1651011, lng: 20.6961593 },
    { lat: 49.1647137, lng: 20.6971343 },
    { lat: 49.1648531, lng: 20.698753 },
    { lat: 49.1648976, lng: 20.6992773 },
    { lat: 49.1652599, lng: 20.6996917 },
    { lat: 49.1663153, lng: 20.700911 },
    { lat: 49.1669607, lng: 20.7016582 },
    { lat: 49.1670515, lng: 20.701772 },
    { lat: 49.1672661, lng: 20.7021108 },
    { lat: 49.1674031, lng: 20.702197 },
    { lat: 49.1676837, lng: 20.7024933 },
    { lat: 49.1678509, lng: 20.7026738 },
    { lat: 49.1681198, lng: 20.7028938 },
    { lat: 49.1685202, lng: 20.7035152 },
    { lat: 49.1688257, lng: 20.7037494 },
    { lat: 49.1691838, lng: 20.7040455 },
    { lat: 49.1697617, lng: 20.7047578 },
    { lat: 49.1700322, lng: 20.7052787 },
    { lat: 49.1701666, lng: 20.7058911 },
    { lat: 49.1703369, lng: 20.7063505 },
    { lat: 49.1706691, lng: 20.7075621 },
    { lat: 49.1708606, lng: 20.7082349 },
    { lat: 49.1709339, lng: 20.7085257 },
    { lat: 49.1713293, lng: 20.7093303 },
    { lat: 49.1715609, lng: 20.7097362 },
    { lat: 49.1716549, lng: 20.7098206 },
    { lat: 49.1718031, lng: 20.7099951 },
    { lat: 49.1723734, lng: 20.7102084 },
    { lat: 49.1726745, lng: 20.7105459 },
    { lat: 49.1729607, lng: 20.7108426 },
    { lat: 49.1734047, lng: 20.7112055 },
    { lat: 49.1736039, lng: 20.711456 },
    { lat: 49.1736642, lng: 20.7115414 },
    { lat: 49.1742361, lng: 20.711992 },
    { lat: 49.1742549, lng: 20.7119498 },
    { lat: 49.1742622, lng: 20.7119298 },
    { lat: 49.1746311, lng: 20.7123704 },
    { lat: 49.1750414, lng: 20.7128624 },
    { lat: 49.1754281, lng: 20.7135786 },
    { lat: 49.1758362, lng: 20.7144643 },
    { lat: 49.176016, lng: 20.7146805 },
    { lat: 49.1761439, lng: 20.7150162 },
    { lat: 49.1763778, lng: 20.7152945 },
    { lat: 49.1765552, lng: 20.7155084 },
    { lat: 49.1768129, lng: 20.7158586 },
    { lat: 49.1770326, lng: 20.7162431 },
    { lat: 49.1771079, lng: 20.7164658 },
    { lat: 49.1771707, lng: 20.7166495 },
    { lat: 49.1774095, lng: 20.7173272 },
    { lat: 49.1775037, lng: 20.7176055 },
    { lat: 49.1776563, lng: 20.7180046 },
    { lat: 49.17794, lng: 20.7187394 },
    { lat: 49.1781249, lng: 20.7194482 },
    { lat: 49.1782208, lng: 20.7199361 },
    { lat: 49.1783346, lng: 20.7210576 },
    { lat: 49.17838, lng: 20.7215158 },
    { lat: 49.1786112, lng: 20.7224885 },
    { lat: 49.1788309, lng: 20.7233894 },
    { lat: 49.1792716, lng: 20.7243046 },
    { lat: 49.1797595, lng: 20.725312 },
    { lat: 49.1801007, lng: 20.7259203 },
    { lat: 49.1803541, lng: 20.726277 },
    { lat: 49.1811267, lng: 20.7267052 },
    { lat: 49.1817166, lng: 20.7268714 },
    { lat: 49.1819522, lng: 20.7269763 },
    { lat: 49.1822977, lng: 20.7271967 },
    { lat: 49.1828618, lng: 20.7275561 },
    { lat: 49.1832939, lng: 20.7278381 },
    { lat: 49.1835024, lng: 20.7280736 },
    { lat: 49.1839431, lng: 20.728537 },
    { lat: 49.1842135, lng: 20.7288632 },
    { lat: 49.1843086, lng: 20.7290991 },
    { lat: 49.1845051, lng: 20.7295029 },
    { lat: 49.1845764, lng: 20.729935 },
    { lat: 49.1846111, lng: 20.7305043 },
    { lat: 49.1847332, lng: 20.73106 },
    { lat: 49.1848545, lng: 20.7315999 },
    { lat: 49.1850498, lng: 20.7320038 },
    { lat: 49.1851319, lng: 20.7322242 },
    { lat: 49.1851397, lng: 20.7324467 },
    { lat: 49.1852303, lng: 20.7328441 },
    { lat: 49.1853451, lng: 20.7331391 },
    { lat: 49.1854849, lng: 20.7333994 },
    { lat: 49.1856428, lng: 20.7337312 },
    { lat: 49.1858945, lng: 20.7342285 },
    { lat: 49.18606, lng: 20.7347094 },
    { lat: 49.1862915, lng: 20.7353119 },
    { lat: 49.1865824, lng: 20.7358053 },
    { lat: 49.1866967, lng: 20.7361784 },
    { lat: 49.1872094, lng: 20.7369635 },
    { lat: 49.1876406, lng: 20.7375081 },
    { lat: 49.1879039, lng: 20.7380811 },
    { lat: 49.1880605, lng: 20.7383774 },
    { lat: 49.1884207, lng: 20.7390526 },
    { lat: 49.1887447, lng: 20.7394332 },
    { lat: 49.1894018, lng: 20.7400709 },
    { lat: 49.1900104, lng: 20.7406708 },
    { lat: 49.1904976, lng: 20.7410453 },
    { lat: 49.1907687, lng: 20.7412719 },
    { lat: 49.1910039, lng: 20.7414942 },
    { lat: 49.1912315, lng: 20.741757 },
    { lat: 49.1913433, lng: 20.7417969 },
    { lat: 49.191737, lng: 20.7422082 },
    { lat: 49.1918244, lng: 20.7423612 },
    { lat: 49.191959, lng: 20.7427004 },
  ],
  Sulín: [
    { lat: 49.3430221, lng: 20.7455264 },
    { lat: 49.343009, lng: 20.7455711 },
    { lat: 49.3425863, lng: 20.7459088 },
    { lat: 49.3425094, lng: 20.746057 },
    { lat: 49.3419863, lng: 20.7475522 },
    { lat: 49.3415336, lng: 20.7492874 },
    { lat: 49.3411109, lng: 20.7498825 },
    { lat: 49.3404782, lng: 20.7510061 },
    { lat: 49.340459, lng: 20.7512957 },
    { lat: 49.3399782, lng: 20.7527729 },
    { lat: 49.3400012, lng: 20.7536907 },
    { lat: 49.3401372, lng: 20.7540763 },
    { lat: 49.3403481, lng: 20.7544417 },
    { lat: 49.340749, lng: 20.7548678 },
    { lat: 49.3414274, lng: 20.7553814 },
    { lat: 49.3420963, lng: 20.7565555 },
    { lat: 49.3423778, lng: 20.7567567 },
    { lat: 49.3422652, lng: 20.7578235 },
    { lat: 49.3423166, lng: 20.7581691 },
    { lat: 49.3428313, lng: 20.7593714 },
    { lat: 49.3427727, lng: 20.7598249 },
    { lat: 49.3425454, lng: 20.7605711 },
    { lat: 49.3424908, lng: 20.7610429 },
    { lat: 49.3429251, lng: 20.7615898 },
    { lat: 49.3430107, lng: 20.7618135 },
    { lat: 49.3433275, lng: 20.7623183 },
    { lat: 49.3435997, lng: 20.7626369 },
    { lat: 49.3438033, lng: 20.7627262 },
    { lat: 49.3443104, lng: 20.7634274 },
    { lat: 49.3444824, lng: 20.7647541 },
    { lat: 49.3444777, lng: 20.7651696 },
    { lat: 49.3447482, lng: 20.7658951 },
    { lat: 49.3447046, lng: 20.7662218 },
    { lat: 49.3447933, lng: 20.7663802 },
    { lat: 49.3447831, lng: 20.7667384 },
    { lat: 49.3449937, lng: 20.7669679 },
    { lat: 49.3451714, lng: 20.7673202 },
    { lat: 49.3452418, lng: 20.7676974 },
    { lat: 49.3453681, lng: 20.7688686 },
    { lat: 49.3455937, lng: 20.7703758 },
    { lat: 49.3455732, lng: 20.7711193 },
    { lat: 49.3458125, lng: 20.7720149 },
    { lat: 49.3470436, lng: 20.7729554 },
    { lat: 49.3477451, lng: 20.7738092 },
    { lat: 49.3481887, lng: 20.7745237 },
    { lat: 49.3483774, lng: 20.7749402 },
    { lat: 49.348608, lng: 20.7752247 },
    { lat: 49.3489544, lng: 20.7759988 },
    { lat: 49.3492225, lng: 20.7762369 },
    { lat: 49.3495953, lng: 20.7766962 },
    { lat: 49.350216, lng: 20.777234 },
    { lat: 49.3504928, lng: 20.7772318 },
    { lat: 49.3511162, lng: 20.7770361 },
    { lat: 49.3515828, lng: 20.7771138 },
    { lat: 49.352328, lng: 20.7780639 },
    { lat: 49.3521657, lng: 20.7787402 },
    { lat: 49.3521408, lng: 20.7794163 },
    { lat: 49.3522088, lng: 20.7807014 },
    { lat: 49.3520266, lng: 20.7815502 },
    { lat: 49.3518956, lng: 20.7818359 },
    { lat: 49.3518721, lng: 20.7826851 },
    { lat: 49.3523478, lng: 20.7831617 },
    { lat: 49.3524451, lng: 20.7834048 },
    { lat: 49.3525705, lng: 20.7839683 },
    { lat: 49.3529511, lng: 20.784796 },
    { lat: 49.3534169, lng: 20.7854121 },
    { lat: 49.3538102, lng: 20.7865641 },
    { lat: 49.3542663, lng: 20.7873901 },
    { lat: 49.3544358, lng: 20.7874772 },
    { lat: 49.3546481, lng: 20.7877575 },
    { lat: 49.3549359, lng: 20.7878144 },
    { lat: 49.3556067, lng: 20.7885606 },
    { lat: 49.3557581, lng: 20.7888065 },
    { lat: 49.3560535, lng: 20.7898365 },
    { lat: 49.3561427, lng: 20.7907546 },
    { lat: 49.3565678, lng: 20.790873 },
    { lat: 49.3575787, lng: 20.7914358 },
    { lat: 49.3578055, lng: 20.7918454 },
    { lat: 49.3586923, lng: 20.7929887 },
    { lat: 49.3591241, lng: 20.7933066 },
    { lat: 49.3596006, lng: 20.7940354 },
    { lat: 49.3599495, lng: 20.7943994 },
    { lat: 49.3602307, lng: 20.7957314 },
    { lat: 49.3604813, lng: 20.7962142 },
    { lat: 49.3609907, lng: 20.7964941 },
    { lat: 49.3613685, lng: 20.7972341 },
    { lat: 49.3612962, lng: 20.7974548 },
    { lat: 49.3614108, lng: 20.7975445 },
    { lat: 49.3613904, lng: 20.7984257 },
    { lat: 49.3615751, lng: 20.79925 },
    { lat: 49.3615596, lng: 20.8019731 },
    { lat: 49.3612411, lng: 20.8037753 },
    { lat: 49.3610693, lng: 20.8045059 },
    { lat: 49.3607319, lng: 20.80523 },
    { lat: 49.3587834, lng: 20.8070535 },
    { lat: 49.3581619, lng: 20.806949 },
    { lat: 49.3579823, lng: 20.8072617 },
    { lat: 49.3570531, lng: 20.8072094 },
    { lat: 49.3565737, lng: 20.8075675 },
    { lat: 49.3551267, lng: 20.8083341 },
    { lat: 49.35361, lng: 20.80857 },
    { lat: 49.353639, lng: 20.808678 },
    { lat: 49.353656, lng: 20.808737 },
    { lat: 49.353665, lng: 20.808783 },
    { lat: 49.353748, lng: 20.808981 },
    { lat: 49.353873, lng: 20.809185 },
    { lat: 49.353942, lng: 20.809298 },
    { lat: 49.353971, lng: 20.809351 },
    { lat: 49.354091, lng: 20.809526 },
    { lat: 49.354164, lng: 20.809635 },
    { lat: 49.35427, lng: 20.809763 },
    { lat: 49.354365, lng: 20.809889 },
    { lat: 49.354447, lng: 20.809986 },
    { lat: 49.354473, lng: 20.81002 },
    { lat: 49.354601, lng: 20.810164 },
    { lat: 49.354673, lng: 20.810314 },
    { lat: 49.354715, lng: 20.810356 },
    { lat: 49.354827, lng: 20.810454 },
    { lat: 49.354845, lng: 20.810472 },
    { lat: 49.35507, lng: 20.810597 },
    { lat: 49.355356, lng: 20.810649 },
    { lat: 49.355682, lng: 20.810641 },
    { lat: 49.355823, lng: 20.810635 },
    { lat: 49.356122, lng: 20.810571 },
    { lat: 49.3563, lng: 20.8105433 },
    { lat: 49.356388, lng: 20.810529 },
    { lat: 49.356421, lng: 20.810526 },
    { lat: 49.356689, lng: 20.810474 },
    { lat: 49.356981, lng: 20.810401 },
    { lat: 49.357341, lng: 20.810307 },
    { lat: 49.357458, lng: 20.810257 },
    { lat: 49.357757, lng: 20.810196 },
    { lat: 49.357932, lng: 20.810132 },
    { lat: 49.358282, lng: 20.810039 },
    { lat: 49.358686, lng: 20.809809 },
    { lat: 49.358788, lng: 20.809739 },
    { lat: 49.358992, lng: 20.809626 },
    { lat: 49.359372, lng: 20.809318 },
    { lat: 49.359434, lng: 20.809256 },
    { lat: 49.359688, lng: 20.808951 },
    { lat: 49.359889, lng: 20.808606 },
    { lat: 49.360171, lng: 20.808133 },
    { lat: 49.3606, lng: 20.807401 },
    { lat: 49.360644, lng: 20.807317 },
    { lat: 49.360899, lng: 20.806812 },
    { lat: 49.3609859, lng: 20.8066121 },
    { lat: 49.3609899, lng: 20.80658 },
    { lat: 49.361067, lng: 20.805976 },
    { lat: 49.361051, lng: 20.805849 },
    { lat: 49.361056, lng: 20.805738 },
    { lat: 49.361071, lng: 20.805632 },
    { lat: 49.361092, lng: 20.805542 },
    { lat: 49.361115, lng: 20.805525 },
    { lat: 49.361192, lng: 20.805075 },
    { lat: 49.361341, lng: 20.80461 },
    { lat: 49.361362, lng: 20.804509 },
    { lat: 49.361376, lng: 20.804389 },
    { lat: 49.361614, lng: 20.803905 },
    { lat: 49.361684, lng: 20.803817 },
    { lat: 49.361827, lng: 20.803671 },
    { lat: 49.361947, lng: 20.803495 },
    { lat: 49.361989, lng: 20.803422 },
    { lat: 49.362002, lng: 20.803347 },
    { lat: 49.362183, lng: 20.802923 },
    { lat: 49.362379, lng: 20.802437 },
    { lat: 49.362562, lng: 20.801843 },
    { lat: 49.362588, lng: 20.801764 },
    { lat: 49.362642, lng: 20.80152 },
    { lat: 49.362764, lng: 20.800987 },
    { lat: 49.362851, lng: 20.800648 },
    { lat: 49.362888, lng: 20.800454 },
    { lat: 49.363, lng: 20.800242 },
    { lat: 49.363058, lng: 20.799951 },
    { lat: 49.363078, lng: 20.799763 },
    { lat: 49.363132, lng: 20.79946 },
    { lat: 49.363136, lng: 20.799362 },
    { lat: 49.363204, lng: 20.798892 },
    { lat: 49.363262, lng: 20.798112 },
    { lat: 49.363319, lng: 20.797166 },
    { lat: 49.363324, lng: 20.796831 },
    { lat: 49.36332, lng: 20.796679 },
    { lat: 49.363306, lng: 20.796438 },
    { lat: 49.363285, lng: 20.795986 },
    { lat: 49.363209, lng: 20.79526 },
    { lat: 49.363194, lng: 20.795032 },
    { lat: 49.363189, lng: 20.79484 },
    { lat: 49.36312, lng: 20.794247 },
    { lat: 49.363054, lng: 20.793925 },
    { lat: 49.363005, lng: 20.793731 },
    { lat: 49.362923, lng: 20.793481 },
    { lat: 49.362694, lng: 20.7929 },
    { lat: 49.362605, lng: 20.792747 },
    { lat: 49.362395, lng: 20.792203 },
    { lat: 49.362177, lng: 20.791668 },
    { lat: 49.362008, lng: 20.791429 },
    { lat: 49.361702, lng: 20.791008 },
    { lat: 49.361346, lng: 20.790355 },
    { lat: 49.361237, lng: 20.790188 },
    { lat: 49.361102, lng: 20.789905 },
    { lat: 49.361024, lng: 20.789686 },
    { lat: 49.36093, lng: 20.789387 },
    { lat: 49.360855, lng: 20.789065 },
    { lat: 49.360795, lng: 20.788697 },
    { lat: 49.360788, lng: 20.788183 },
    { lat: 49.360766, lng: 20.787845 },
    { lat: 49.36079, lng: 20.787622 },
    { lat: 49.360777, lng: 20.787291 },
    { lat: 49.360772, lng: 20.787081 },
    { lat: 49.360757, lng: 20.786877 },
    { lat: 49.360751, lng: 20.786635 },
    { lat: 49.360767, lng: 20.786466 },
    { lat: 49.360772, lng: 20.786037 },
    { lat: 49.360747, lng: 20.785698 },
    { lat: 49.360706, lng: 20.785513 },
    { lat: 49.360603, lng: 20.785017 },
    { lat: 49.360522, lng: 20.784614 },
    { lat: 49.360492, lng: 20.784372 },
    { lat: 49.36048, lng: 20.784177 },
    { lat: 49.360463, lng: 20.783963 },
    { lat: 49.360515, lng: 20.783517 },
    { lat: 49.360628, lng: 20.783217 },
    { lat: 49.360691, lng: 20.783059 },
    { lat: 49.360754, lng: 20.782941 },
    { lat: 49.360853, lng: 20.782766 },
    { lat: 49.361031, lng: 20.782476 },
    { lat: 49.361261, lng: 20.782159 },
    { lat: 49.361505, lng: 20.781818 },
    { lat: 49.361801, lng: 20.781513 },
    { lat: 49.362047, lng: 20.781358 },
    { lat: 49.362373, lng: 20.781187 },
    { lat: 49.362625, lng: 20.781096 },
    { lat: 49.362937, lng: 20.781014 },
    { lat: 49.36323, lng: 20.780959 },
    { lat: 49.363682, lng: 20.780943 },
    { lat: 49.363852, lng: 20.780947 },
    { lat: 49.363924, lng: 20.780955 },
    { lat: 49.36408, lng: 20.780976 },
    { lat: 49.36415, lng: 20.780991 },
    { lat: 49.364451, lng: 20.781066 },
    { lat: 49.364526, lng: 20.78109 },
    { lat: 49.364719, lng: 20.781143 },
    { lat: 49.364877, lng: 20.781212 },
    { lat: 49.36496, lng: 20.781253 },
    { lat: 49.365077, lng: 20.781284 },
    { lat: 49.365183, lng: 20.781323 },
    { lat: 49.365328, lng: 20.781362 },
    { lat: 49.365403, lng: 20.781385 },
    { lat: 49.365553, lng: 20.781428 },
    { lat: 49.365655, lng: 20.781467 },
    { lat: 49.365871, lng: 20.781553 },
    { lat: 49.366031, lng: 20.781618 },
    { lat: 49.366092, lng: 20.781642 },
    { lat: 49.36628, lng: 20.781728 },
    { lat: 49.366382, lng: 20.781843 },
    { lat: 49.3665, lng: 20.781937 },
    { lat: 49.366632, lng: 20.782023 },
    { lat: 49.366705, lng: 20.782071 },
    { lat: 49.36679, lng: 20.782174 },
    { lat: 49.366852, lng: 20.782344 },
    { lat: 49.366936, lng: 20.782448 },
    { lat: 49.366952, lng: 20.78247 },
    { lat: 49.36717, lng: 20.782792 },
    { lat: 49.367304, lng: 20.782996 },
    { lat: 49.367405, lng: 20.783137 },
    { lat: 49.367584, lng: 20.783358 },
    { lat: 49.367629, lng: 20.783435 },
    { lat: 49.367866, lng: 20.783705 },
    { lat: 49.367925, lng: 20.783766 },
    { lat: 49.367971, lng: 20.783817 },
    { lat: 49.368014, lng: 20.783872 },
    { lat: 49.368146, lng: 20.783982 },
    { lat: 49.368266, lng: 20.784109 },
    { lat: 49.368286, lng: 20.784133 },
    { lat: 49.368396, lng: 20.78424 },
    { lat: 49.36855, lng: 20.784377 },
    { lat: 49.368634, lng: 20.784476 },
    { lat: 49.368736, lng: 20.784596 },
    { lat: 49.368837, lng: 20.784761 },
    { lat: 49.368911, lng: 20.784859 },
    { lat: 49.368979, lng: 20.784945 },
    { lat: 49.369112, lng: 20.785092 },
    { lat: 49.369157, lng: 20.78516 },
    { lat: 49.36931, lng: 20.785374 },
    { lat: 49.36944, lng: 20.785537 },
    { lat: 49.369506, lng: 20.785622 },
    { lat: 49.369643, lng: 20.785806 },
    { lat: 49.369708, lng: 20.785892 },
    { lat: 49.369806, lng: 20.785957 },
    { lat: 49.369952, lng: 20.786116 },
    { lat: 49.370095, lng: 20.786296 },
    { lat: 49.370147, lng: 20.786358 },
    { lat: 49.370452, lng: 20.78672 },
    { lat: 49.370466, lng: 20.786737 },
    { lat: 49.370651, lng: 20.786951 },
    { lat: 49.370719, lng: 20.787053 },
    { lat: 49.370751, lng: 20.787093 },
    { lat: 49.370765, lng: 20.787112 },
    { lat: 49.370802, lng: 20.787159 },
    { lat: 49.37099, lng: 20.787615 },
    { lat: 49.37107, lng: 20.787737 },
    { lat: 49.371087, lng: 20.787801 },
    { lat: 49.37133, lng: 20.788181 },
    { lat: 49.371434, lng: 20.788255 },
    { lat: 49.371505, lng: 20.788317 },
    { lat: 49.371598, lng: 20.788349 },
    { lat: 49.371746, lng: 20.788377 },
    { lat: 49.371806, lng: 20.78836 },
    { lat: 49.371957, lng: 20.78838 },
    { lat: 49.372068, lng: 20.788351 },
    { lat: 49.37208, lng: 20.788331 },
    { lat: 49.372175, lng: 20.788309 },
    { lat: 49.372216, lng: 20.788295 },
    { lat: 49.372329, lng: 20.788242 },
    { lat: 49.372392, lng: 20.788203 },
    { lat: 49.372506, lng: 20.788126 },
    { lat: 49.372659, lng: 20.788012 },
    { lat: 49.372788, lng: 20.787885 },
    { lat: 49.37289, lng: 20.787765 },
    { lat: 49.372935, lng: 20.787704 },
    { lat: 49.373036, lng: 20.787548 },
    { lat: 49.373162, lng: 20.78738 },
    { lat: 49.373294, lng: 20.787203 },
    { lat: 49.373308, lng: 20.787189 },
    { lat: 49.373502, lng: 20.786918 },
    { lat: 49.373709, lng: 20.786654 },
    { lat: 49.373732, lng: 20.786627 },
    { lat: 49.373822, lng: 20.786497 },
    { lat: 49.373885, lng: 20.78642 },
    { lat: 49.374025, lng: 20.786199 },
    { lat: 49.374038, lng: 20.786177 },
    { lat: 49.374129, lng: 20.786019 },
    { lat: 49.374138, lng: 20.786001 },
    { lat: 49.374223, lng: 20.785829 },
    { lat: 49.37429, lng: 20.785741 },
    { lat: 49.374351, lng: 20.78553 },
    { lat: 49.3744, lng: 20.785406 },
    { lat: 49.374449, lng: 20.785196 },
    { lat: 49.374464, lng: 20.785114 },
    { lat: 49.374478, lng: 20.785054 },
    { lat: 49.37451, lng: 20.784869 },
    { lat: 49.374566, lng: 20.78448 },
    { lat: 49.374573, lng: 20.784432 },
    { lat: 49.374612, lng: 20.784135 },
    { lat: 49.374638, lng: 20.784021 },
    { lat: 49.374691, lng: 20.783757 },
    { lat: 49.374778, lng: 20.783434 },
    { lat: 49.37483, lng: 20.783233 },
    { lat: 49.374858, lng: 20.78313 },
    { lat: 49.374921, lng: 20.782887 },
    { lat: 49.375008, lng: 20.78261 },
    { lat: 49.375048, lng: 20.782519 },
    { lat: 49.375099, lng: 20.782295 },
    { lat: 49.375124, lng: 20.782056 },
    { lat: 49.375156, lng: 20.781876 },
    { lat: 49.375173, lng: 20.781735 },
    { lat: 49.375183, lng: 20.781591 },
    { lat: 49.37521, lng: 20.781219 },
    { lat: 49.375212, lng: 20.781188 },
    { lat: 49.375223, lng: 20.780939 },
    { lat: 49.375225, lng: 20.780828 },
    { lat: 49.375222, lng: 20.780564 },
    { lat: 49.375221, lng: 20.780423 },
    { lat: 49.375199, lng: 20.780213 },
    { lat: 49.375193, lng: 20.780051 },
    { lat: 49.375191, lng: 20.780012 },
    { lat: 49.375187, lng: 20.779975 },
    { lat: 49.375149, lng: 20.779677 },
    { lat: 49.375144, lng: 20.779621 },
    { lat: 49.375113, lng: 20.779449 },
    { lat: 49.375073, lng: 20.779256 },
    { lat: 49.375059, lng: 20.779156 },
    { lat: 49.374984, lng: 20.778824 },
    { lat: 49.37497, lng: 20.778753 },
    { lat: 49.3749062, lng: 20.778473 },
    { lat: 49.37484, lng: 20.778266 },
    { lat: 49.374822, lng: 20.778208 },
    { lat: 49.374745, lng: 20.777965 },
    { lat: 49.374693, lng: 20.777796 },
    { lat: 49.374655, lng: 20.777623 },
    { lat: 49.374604, lng: 20.777421 },
    { lat: 49.374576, lng: 20.777305 },
    { lat: 49.374573, lng: 20.777288 },
    { lat: 49.374534, lng: 20.777114 },
    { lat: 49.374457, lng: 20.776787 },
    { lat: 49.374413, lng: 20.776632 },
    { lat: 49.374356, lng: 20.776435 },
    { lat: 49.374294, lng: 20.776183 },
    { lat: 49.374293, lng: 20.776172 },
    { lat: 49.374189, lng: 20.775694 },
    { lat: 49.374175, lng: 20.775619 },
    { lat: 49.374137, lng: 20.775438 },
    { lat: 49.374083, lng: 20.775129 },
    { lat: 49.374079, lng: 20.775098 },
    { lat: 49.374042, lng: 20.774858 },
    { lat: 49.374031, lng: 20.774714 },
    { lat: 49.374026, lng: 20.77469 },
    { lat: 49.374004, lng: 20.774561 },
    { lat: 49.373961, lng: 20.774358 },
    { lat: 49.373909, lng: 20.774147 },
    { lat: 49.373877, lng: 20.774006 },
    { lat: 49.373831, lng: 20.773853 },
    { lat: 49.373725, lng: 20.77345 },
    { lat: 49.373659, lng: 20.77325 },
    { lat: 49.373559, lng: 20.772919 },
    { lat: 49.373447, lng: 20.772512 },
    { lat: 49.373203, lng: 20.771589 },
    { lat: 49.373004, lng: 20.771219 },
    { lat: 49.372836, lng: 20.770789 },
    { lat: 49.37278, lng: 20.770529 },
    { lat: 49.37265, lng: 20.770146 },
    { lat: 49.372322, lng: 20.769319 },
    { lat: 49.372171, lng: 20.768777 },
    { lat: 49.371959, lng: 20.768171 },
    { lat: 49.371878, lng: 20.767969 },
    { lat: 49.371604, lng: 20.767127 },
    { lat: 49.371512, lng: 20.766768 },
    { lat: 49.371377, lng: 20.766295 },
    { lat: 49.371191, lng: 20.765447 },
    { lat: 49.371143, lng: 20.765234 },
    { lat: 49.371126, lng: 20.765126 },
    { lat: 49.371008, lng: 20.764678 },
    { lat: 49.370991, lng: 20.764587 },
    { lat: 49.370959, lng: 20.764387 },
    { lat: 49.370931, lng: 20.764206 },
    { lat: 49.3709, lng: 20.763981 },
    { lat: 49.370892, lng: 20.763852 },
    { lat: 49.37089, lng: 20.763787 },
    { lat: 49.370893, lng: 20.76354 },
    { lat: 49.370902, lng: 20.763454 },
    { lat: 49.37092, lng: 20.763264 },
    { lat: 49.370971, lng: 20.763027 },
    { lat: 49.371047, lng: 20.762719 },
    { lat: 49.371085, lng: 20.762583 },
    { lat: 49.371171, lng: 20.762304 },
    { lat: 49.371222, lng: 20.762134 },
    { lat: 49.371318, lng: 20.761931 },
    { lat: 49.371419, lng: 20.761735 },
    { lat: 49.371446, lng: 20.761709 },
    { lat: 49.371545, lng: 20.761537 },
    { lat: 49.371605, lng: 20.76143 },
    { lat: 49.371764, lng: 20.761229 },
    { lat: 49.371793, lng: 20.761192 },
    { lat: 49.371912, lng: 20.761029 },
    { lat: 49.372054, lng: 20.760838 },
    { lat: 49.372145, lng: 20.760711 },
    { lat: 49.372265, lng: 20.760549 },
    { lat: 49.372293, lng: 20.760513 },
    { lat: 49.372505, lng: 20.760246 },
    { lat: 49.372621, lng: 20.760094 },
    { lat: 49.372736, lng: 20.759938 },
    { lat: 49.372883, lng: 20.759735 },
    { lat: 49.372919, lng: 20.759685 },
    { lat: 49.37311, lng: 20.759423 },
    { lat: 49.37315, lng: 20.759365 },
    { lat: 49.373176, lng: 20.759329 },
    { lat: 49.373231, lng: 20.75923 },
    { lat: 49.373368, lng: 20.759029 },
    { lat: 49.373393, lng: 20.758994 },
    { lat: 49.373471, lng: 20.758906 },
    { lat: 49.373688, lng: 20.758638 },
    { lat: 49.373746, lng: 20.758569 },
    { lat: 49.373957, lng: 20.758374 },
    { lat: 49.374209, lng: 20.758133 },
    { lat: 49.374264, lng: 20.758088 },
    { lat: 49.374323, lng: 20.758041 },
    { lat: 49.37444, lng: 20.757957 },
    { lat: 49.374534, lng: 20.757844 },
    { lat: 49.37466, lng: 20.757755 },
    { lat: 49.374867, lng: 20.757674 },
    { lat: 49.37497, lng: 20.757645 },
    { lat: 49.375076, lng: 20.757654 },
    { lat: 49.375281, lng: 20.757594 },
    { lat: 49.375345, lng: 20.757597 },
    { lat: 49.375457, lng: 20.757595 },
    { lat: 49.375482, lng: 20.757599 },
    { lat: 49.375715, lng: 20.757655 },
    { lat: 49.375785, lng: 20.75768 },
    { lat: 49.37592, lng: 20.757728 },
    { lat: 49.376098, lng: 20.757789 },
    { lat: 49.376251, lng: 20.757843 },
    { lat: 49.376419, lng: 20.757902 },
    { lat: 49.376551, lng: 20.757949 },
    { lat: 49.376635, lng: 20.757979 },
    { lat: 49.376818, lng: 20.758055 },
    { lat: 49.377018, lng: 20.758157 },
    { lat: 49.377073, lng: 20.758191 },
    { lat: 49.377157, lng: 20.758268 },
    { lat: 49.377299, lng: 20.758375 },
    { lat: 49.377489, lng: 20.758501 },
    { lat: 49.377547, lng: 20.758531 },
    { lat: 49.377673, lng: 20.758614 },
    { lat: 49.377695, lng: 20.758628 },
    { lat: 49.377754, lng: 20.758658 },
    { lat: 49.377842, lng: 20.758696 },
    { lat: 49.378021, lng: 20.758778 },
    { lat: 49.378102, lng: 20.75882 },
    { lat: 49.378235, lng: 20.758896 },
    { lat: 49.378337, lng: 20.75894 },
    { lat: 49.378524, lng: 20.759035 },
    { lat: 49.378565, lng: 20.759055 },
    { lat: 49.378804, lng: 20.75919 },
    { lat: 49.378819, lng: 20.759198 },
    { lat: 49.378952, lng: 20.759269 },
    { lat: 49.379002, lng: 20.759303 },
    { lat: 49.379057, lng: 20.759318 },
    { lat: 49.379211, lng: 20.759405 },
    { lat: 49.379291, lng: 20.759454 },
    { lat: 49.379385, lng: 20.759497 },
    { lat: 49.379583, lng: 20.759599 },
    { lat: 49.379626, lng: 20.759618 },
    { lat: 49.379815, lng: 20.759732 },
    { lat: 49.379924, lng: 20.75979 },
    { lat: 49.37997, lng: 20.759847 },
    { lat: 49.380102, lng: 20.75992 },
    { lat: 49.380311, lng: 20.759996 },
    { lat: 49.380339, lng: 20.760032 },
    { lat: 49.380395, lng: 20.760069 },
    { lat: 49.380515, lng: 20.760178 },
    { lat: 49.380705, lng: 20.760332 },
    { lat: 49.380771, lng: 20.76039 },
    { lat: 49.381048, lng: 20.760623 },
    { lat: 49.381099, lng: 20.760648 },
    { lat: 49.381618, lng: 20.760941 },
    { lat: 49.381671, lng: 20.760975 },
    { lat: 49.381807, lng: 20.761067 },
    { lat: 49.381956, lng: 20.761158 },
    { lat: 49.382077, lng: 20.761231 },
    { lat: 49.382243, lng: 20.76132 },
    { lat: 49.382433, lng: 20.761416 },
    { lat: 49.382535, lng: 20.761476 },
    { lat: 49.38265, lng: 20.761542 },
    { lat: 49.38272, lng: 20.761578 },
    { lat: 49.38279, lng: 20.761611 },
    { lat: 49.382831, lng: 20.76163 },
    { lat: 49.383003, lng: 20.761696 },
    { lat: 49.383082, lng: 20.761719 },
    { lat: 49.38317, lng: 20.761741 },
    { lat: 49.383325, lng: 20.76175 },
    { lat: 49.383423, lng: 20.761753 },
    { lat: 49.383547, lng: 20.76173 },
    { lat: 49.383643, lng: 20.761713 },
    { lat: 49.38382, lng: 20.761626 },
    { lat: 49.383838, lng: 20.761618 },
    { lat: 49.383976, lng: 20.761544 },
    { lat: 49.384127, lng: 20.761446 },
    { lat: 49.384246, lng: 20.761363 },
    { lat: 49.384299, lng: 20.761323 },
    { lat: 49.384497, lng: 20.761119 },
    { lat: 49.384537, lng: 20.761072 },
    { lat: 49.384661, lng: 20.760903 },
    { lat: 49.384686, lng: 20.760861 },
    { lat: 49.384767, lng: 20.760722 },
    { lat: 49.384851, lng: 20.760555 },
    { lat: 49.384855, lng: 20.760545 },
    { lat: 49.384948, lng: 20.760306 },
    { lat: 49.385002, lng: 20.760121 },
    { lat: 49.385009, lng: 20.760072 },
    { lat: 49.385028, lng: 20.759968 },
    { lat: 49.38504, lng: 20.759889 },
    { lat: 49.385047, lng: 20.75977 },
    { lat: 49.385049, lng: 20.759695 },
    { lat: 49.385088, lng: 20.759344 },
    { lat: 49.38509, lng: 20.75921 },
    { lat: 49.385117, lng: 20.758975 },
    { lat: 49.385156, lng: 20.758675 },
    { lat: 49.385193, lng: 20.758501 },
    { lat: 49.385196, lng: 20.758458 },
    { lat: 49.38532, lng: 20.757927 },
    { lat: 49.385464, lng: 20.757537 },
    { lat: 49.385501, lng: 20.75739 },
    { lat: 49.385551, lng: 20.757139 },
    { lat: 49.385603, lng: 20.756815 },
    { lat: 49.385646, lng: 20.756592 },
    { lat: 49.38564, lng: 20.756489 },
    { lat: 49.38567, lng: 20.75623 },
    { lat: 49.385737, lng: 20.75582 },
    { lat: 49.385783, lng: 20.755642 },
    { lat: 49.385852, lng: 20.755446 },
    { lat: 49.385883, lng: 20.755375 },
    { lat: 49.38611, lng: 20.754964 },
    { lat: 49.386183, lng: 20.754851 },
    { lat: 49.386186, lng: 20.754846 },
    { lat: 49.386309, lng: 20.754687 },
    { lat: 49.38634, lng: 20.754639 },
    { lat: 49.386363, lng: 20.754601 },
    { lat: 49.38641, lng: 20.754531 },
    { lat: 49.386449, lng: 20.754476 },
    { lat: 49.386519, lng: 20.754367 },
    { lat: 49.386549, lng: 20.754329 },
    { lat: 49.386728, lng: 20.754032 },
    { lat: 49.386782, lng: 20.753922 },
    { lat: 49.38686, lng: 20.753759 },
    { lat: 49.386911, lng: 20.753648 },
    { lat: 49.3866893, lng: 20.7534308 },
    { lat: 49.3865978, lng: 20.7531933 },
    { lat: 49.3864499, lng: 20.752985 },
    { lat: 49.3859851, lng: 20.7526942 },
    { lat: 49.3851878, lng: 20.7516107 },
    { lat: 49.3849785, lng: 20.7514523 },
    { lat: 49.3848191, lng: 20.7510737 },
    { lat: 49.3846678, lng: 20.7504487 },
    { lat: 49.3846034, lng: 20.7497976 },
    { lat: 49.3833788, lng: 20.7449808 },
    { lat: 49.3824645, lng: 20.7421287 },
    { lat: 49.3818045, lng: 20.7405661 },
    { lat: 49.3811956, lng: 20.7398573 },
    { lat: 49.3801596, lng: 20.7394877 },
    { lat: 49.3800617, lng: 20.7384535 },
    { lat: 49.3798038, lng: 20.7378414 },
    { lat: 49.3796878, lng: 20.7372909 },
    { lat: 49.3797184, lng: 20.7367477 },
    { lat: 49.3795843, lng: 20.736582 },
    { lat: 49.3793583, lng: 20.7360735 },
    { lat: 49.379056, lng: 20.7358942 },
    { lat: 49.37893, lng: 20.7355393 },
    { lat: 49.3788284, lng: 20.7350487 },
    { lat: 49.3789937, lng: 20.7346241 },
    { lat: 49.3792404, lng: 20.734407 },
    { lat: 49.3793379, lng: 20.7341678 },
    { lat: 49.3792675, lng: 20.7339554 },
    { lat: 49.3793297, lng: 20.7337441 },
    { lat: 49.3791971, lng: 20.733492 },
    { lat: 49.3794618, lng: 20.733204 },
    { lat: 49.3795618, lng: 20.7328808 },
    { lat: 49.3799655, lng: 20.7324785 },
    { lat: 49.3801699, lng: 20.732146 },
    { lat: 49.3803009, lng: 20.7320627 },
    { lat: 49.3806259, lng: 20.7320559 },
    { lat: 49.3806499, lng: 20.7313575 },
    { lat: 49.3810261, lng: 20.7297201 },
    { lat: 49.3806809, lng: 20.7285433 },
    { lat: 49.3803645, lng: 20.7279949 },
    { lat: 49.3803066, lng: 20.7276872 },
    { lat: 49.3804272, lng: 20.7262822 },
    { lat: 49.3805421, lng: 20.7257034 },
    { lat: 49.3806724, lng: 20.725472 },
    { lat: 49.3810459, lng: 20.724497 },
    { lat: 49.3813136, lng: 20.7242423 },
    { lat: 49.3813404, lng: 20.7239103 },
    { lat: 49.3814965, lng: 20.7232891 },
    { lat: 49.3817577, lng: 20.7226539 },
    { lat: 49.3817643, lng: 20.72223 },
    { lat: 49.3819174, lng: 20.721906 },
    { lat: 49.3822469, lng: 20.7213222 },
    { lat: 49.3825446, lng: 20.7209198 },
    { lat: 49.382799, lng: 20.7206673 },
    { lat: 49.3834822, lng: 20.7201823 },
    { lat: 49.3827745, lng: 20.7195805 },
    { lat: 49.3824048, lng: 20.719341 },
    { lat: 49.3817357, lng: 20.7190374 },
    { lat: 49.3810742, lng: 20.7188866 },
    { lat: 49.3807351, lng: 20.7185934 },
    { lat: 49.3801945, lng: 20.7183907 },
    { lat: 49.3792702, lng: 20.7181887 },
    { lat: 49.3786303, lng: 20.7178172 },
    { lat: 49.3782226, lng: 20.7175517 },
    { lat: 49.3782366, lng: 20.7175003 },
    { lat: 49.3776344, lng: 20.716956 },
    { lat: 49.3772472, lng: 20.7164732 },
    { lat: 49.3762797, lng: 20.715592 },
    { lat: 49.3751482, lng: 20.7151336 },
    { lat: 49.3743632, lng: 20.7144469 },
    { lat: 49.3736983, lng: 20.7144522 },
    { lat: 49.3726811, lng: 20.7143044 },
    { lat: 49.3720107, lng: 20.7157535 },
    { lat: 49.371757, lng: 20.7156498 },
    { lat: 49.3715029, lng: 20.7153391 },
    { lat: 49.3711241, lng: 20.7151554 },
    { lat: 49.3697697, lng: 20.7141531 },
    { lat: 49.3692542, lng: 20.7135443 },
    { lat: 49.3692693, lng: 20.71343 },
    { lat: 49.3688271, lng: 20.7131151 },
    { lat: 49.3680612, lng: 20.7121405 },
    { lat: 49.3677318, lng: 20.7113507 },
    { lat: 49.3677185, lng: 20.7113883 },
    { lat: 49.3670838, lng: 20.7110054 },
    { lat: 49.3667398, lng: 20.7108886 },
    { lat: 49.366098, lng: 20.7110737 },
    { lat: 49.3642833, lng: 20.7109329 },
    { lat: 49.3633503, lng: 20.7105519 },
    { lat: 49.3627751, lng: 20.7104613 },
    { lat: 49.3625355, lng: 20.7104616 },
    { lat: 49.3617776, lng: 20.7106418 },
    { lat: 49.3594722, lng: 20.7107917 },
    { lat: 49.358624, lng: 20.7109714 },
    { lat: 49.3580885, lng: 20.7109415 },
    { lat: 49.3571001, lng: 20.7110056 },
    { lat: 49.3560944, lng: 20.7109249 },
    { lat: 49.3554892, lng: 20.7110329 },
    { lat: 49.3549541, lng: 20.7113362 },
    { lat: 49.3546499, lng: 20.7115863 },
    { lat: 49.3543822, lng: 20.7119084 },
    { lat: 49.3531484, lng: 20.7136315 },
    { lat: 49.3531781, lng: 20.7136778 },
    { lat: 49.3527095, lng: 20.7143103 },
    { lat: 49.3522039, lng: 20.7151646 },
    { lat: 49.3518011, lng: 20.715717 },
    { lat: 49.350922, lng: 20.7166428 },
    { lat: 49.3497683, lng: 20.7176071 },
    { lat: 49.3487411, lng: 20.718801 },
    { lat: 49.3480583, lng: 20.718621 },
    { lat: 49.3480018, lng: 20.7187105 },
    { lat: 49.3478169, lng: 20.7187658 },
    { lat: 49.3476093, lng: 20.7187226 },
    { lat: 49.3475274, lng: 20.7189243 },
    { lat: 49.3473193, lng: 20.718828 },
    { lat: 49.3472194, lng: 20.718998 },
    { lat: 49.346906, lng: 20.7188512 },
    { lat: 49.3468281, lng: 20.7187222 },
    { lat: 49.3468413, lng: 20.7184883 },
    { lat: 49.3466118, lng: 20.7181039 },
    { lat: 49.3463761, lng: 20.7179016 },
    { lat: 49.3462993, lng: 20.7177007 },
    { lat: 49.3462382, lng: 20.7176877 },
    { lat: 49.346191, lng: 20.7175331 },
    { lat: 49.3462008, lng: 20.7173608 },
    { lat: 49.3461531, lng: 20.7173529 },
    { lat: 49.3457651, lng: 20.7166537 },
    { lat: 49.3454818, lng: 20.7160547 },
    { lat: 49.3455036, lng: 20.7157767 },
    { lat: 49.3453648, lng: 20.7158329 },
    { lat: 49.3443313, lng: 20.7175872 },
    { lat: 49.3447457, lng: 20.7198703 },
    { lat: 49.3447213, lng: 20.7215155 },
    { lat: 49.3443035, lng: 20.7235028 },
    { lat: 49.3427901, lng: 20.7269516 },
    { lat: 49.3436033, lng: 20.7266745 },
    { lat: 49.3440297, lng: 20.7268936 },
    { lat: 49.3447624, lng: 20.7264479 },
    { lat: 49.3447577, lng: 20.7263122 },
    { lat: 49.3457273, lng: 20.7263261 },
    { lat: 49.3465291, lng: 20.7271715 },
    { lat: 49.3467059, lng: 20.7279169 },
    { lat: 49.3466753, lng: 20.7310565 },
    { lat: 49.346769, lng: 20.7313892 },
    { lat: 49.3467511, lng: 20.7318073 },
    { lat: 49.3470169, lng: 20.733197 },
    { lat: 49.3476318, lng: 20.7334932 },
    { lat: 49.3480595, lng: 20.7341087 },
    { lat: 49.3482883, lng: 20.7346015 },
    { lat: 49.3481482, lng: 20.7347112 },
    { lat: 49.3479578, lng: 20.7358358 },
    { lat: 49.3478743, lng: 20.7366307 },
    { lat: 49.3474016, lng: 20.7368939 },
    { lat: 49.3471166, lng: 20.7378833 },
    { lat: 49.346911, lng: 20.7384113 },
    { lat: 49.3468618, lng: 20.7400265 },
    { lat: 49.3464837, lng: 20.7414801 },
    { lat: 49.3463715, lng: 20.7421012 },
    { lat: 49.3463257, lng: 20.7442781 },
    { lat: 49.3462257, lng: 20.7456959 },
    { lat: 49.3457404, lng: 20.7475051 },
    { lat: 49.3455817, lng: 20.7485675 },
    { lat: 49.344668, lng: 20.7471198 },
    { lat: 49.3441825, lng: 20.7463996 },
    { lat: 49.3438257, lng: 20.7461371 },
    { lat: 49.3430771, lng: 20.7460102 },
    { lat: 49.3430221, lng: 20.7455264 },
  ],
  Legnava: [
    { lat: 49.334186, lng: 20.838235 },
    { lat: 49.3336281, lng: 20.8386127 },
    { lat: 49.333375, lng: 20.8393355 },
    { lat: 49.3320878, lng: 20.8403743 },
    { lat: 49.3319452, lng: 20.8405521 },
    { lat: 49.3317083, lng: 20.8406994 },
    { lat: 49.3316285, lng: 20.8408278 },
    { lat: 49.3316013, lng: 20.8407289 },
    { lat: 49.3310078, lng: 20.840983 },
    { lat: 49.3307956, lng: 20.8411627 },
    { lat: 49.3305258, lng: 20.8415663 },
    { lat: 49.3300835, lng: 20.8420603 },
    { lat: 49.3300466, lng: 20.8419672 },
    { lat: 49.3288791, lng: 20.8426846 },
    { lat: 49.3287491, lng: 20.8427029 },
    { lat: 49.3282773, lng: 20.8416681 },
    { lat: 49.3277254, lng: 20.8410943 },
    { lat: 49.3269716, lng: 20.8408434 },
    { lat: 49.326521, lng: 20.8405968 },
    { lat: 49.3256122, lng: 20.8403168 },
    { lat: 49.325167, lng: 20.8403949 },
    { lat: 49.324059, lng: 20.8402826 },
    { lat: 49.3239627, lng: 20.8403285 },
    { lat: 49.3237236, lng: 20.840677 },
    { lat: 49.3232718, lng: 20.840428 },
    { lat: 49.3229886, lng: 20.8407768 },
    { lat: 49.3224827, lng: 20.8418494 },
    { lat: 49.3220813, lng: 20.8421263 },
    { lat: 49.3198919, lng: 20.8473056 },
    { lat: 49.3190453, lng: 20.8488525 },
    { lat: 49.3189293, lng: 20.8489497 },
    { lat: 49.3186897, lng: 20.8493454 },
    { lat: 49.3185556, lng: 20.8494734 },
    { lat: 49.3182292, lng: 20.8496065 },
    { lat: 49.3172977, lng: 20.8505807 },
    { lat: 49.3171008, lng: 20.8505465 },
    { lat: 49.3166343, lng: 20.85126 },
    { lat: 49.3165989, lng: 20.8512106 },
    { lat: 49.3160062, lng: 20.8521805 },
    { lat: 49.3156625, lng: 20.8529632 },
    { lat: 49.315356, lng: 20.8538191 },
    { lat: 49.3151912, lng: 20.854764 },
    { lat: 49.3149984, lng: 20.8551148 },
    { lat: 49.3145595, lng: 20.8552467 },
    { lat: 49.3139165, lng: 20.8557043 },
    { lat: 49.313846, lng: 20.8569129 },
    { lat: 49.3135829, lng: 20.8576177 },
    { lat: 49.3134169, lng: 20.8582568 },
    { lat: 49.3133113, lng: 20.8587944 },
    { lat: 49.3130844, lng: 20.8607678 },
    { lat: 49.3129348, lng: 20.8623672 },
    { lat: 49.3128549, lng: 20.8640596 },
    { lat: 49.3128702, lng: 20.8649071 },
    { lat: 49.3129341, lng: 20.8658681 },
    { lat: 49.313564, lng: 20.8677491 },
    { lat: 49.3142431, lng: 20.8683313 },
    { lat: 49.3146453, lng: 20.868543 },
    { lat: 49.3150488, lng: 20.8686604 },
    { lat: 49.3157291, lng: 20.8684759 },
    { lat: 49.315448, lng: 20.8696489 },
    { lat: 49.3155989, lng: 20.8708108 },
    { lat: 49.3164989, lng: 20.8733669 },
    { lat: 49.3161034, lng: 20.8757816 },
    { lat: 49.3159806, lng: 20.8777454 },
    { lat: 49.3165071, lng: 20.8784072 },
    { lat: 49.3172234, lng: 20.8788896 },
    { lat: 49.319432, lng: 20.882222 },
    { lat: 49.319523, lng: 20.881918 },
    { lat: 49.319559, lng: 20.881756 },
    { lat: 49.319571, lng: 20.881741 },
    { lat: 49.319742, lng: 20.881532 },
    { lat: 49.319781, lng: 20.881552 },
    { lat: 49.319797, lng: 20.881563 },
    { lat: 49.319802, lng: 20.88155 },
    { lat: 49.319844, lng: 20.881446 },
    { lat: 49.319871, lng: 20.881297 },
    { lat: 49.319872, lng: 20.881193 },
    { lat: 49.319894, lng: 20.88096 },
    { lat: 49.319974, lng: 20.880719 },
    { lat: 49.320115, lng: 20.880339 },
    { lat: 49.320191, lng: 20.879809 },
    { lat: 49.32019, lng: 20.879591 },
    { lat: 49.320189, lng: 20.879575 },
    { lat: 49.320112, lng: 20.879072 },
    { lat: 49.320117, lng: 20.878906 },
    { lat: 49.320202, lng: 20.878409 },
    { lat: 49.320206, lng: 20.878387 },
    { lat: 49.320261, lng: 20.878064 },
    { lat: 49.320256, lng: 20.877877 },
    { lat: 49.320223, lng: 20.877739 },
    { lat: 49.320217, lng: 20.877723 },
    { lat: 49.320157, lng: 20.877586 },
    { lat: 49.320033, lng: 20.877436 },
    { lat: 49.320025, lng: 20.877427 },
    { lat: 49.319961, lng: 20.877145 },
    { lat: 49.31991, lng: 20.876904 },
    { lat: 49.319801, lng: 20.876562 },
    { lat: 49.319674, lng: 20.876348 },
    { lat: 49.319558, lng: 20.87624 },
    { lat: 49.319494, lng: 20.876083 },
    { lat: 49.31949, lng: 20.875867 },
    { lat: 49.319493, lng: 20.87564 },
    { lat: 49.319548, lng: 20.875451 },
    { lat: 49.319599, lng: 20.875408 },
    { lat: 49.319662, lng: 20.875235 },
    { lat: 49.319682, lng: 20.874843 },
    { lat: 49.319721, lng: 20.874111 },
    { lat: 49.319748, lng: 20.873227 },
    { lat: 49.319789, lng: 20.872738 },
    { lat: 49.320513, lng: 20.873121 },
    { lat: 49.320952, lng: 20.873523 },
    { lat: 49.321174, lng: 20.87384 },
    { lat: 49.321452, lng: 20.874252 },
    { lat: 49.321721, lng: 20.875191 },
    { lat: 49.321969, lng: 20.875804 },
    { lat: 49.322037, lng: 20.876023 },
    { lat: 49.322073, lng: 20.87624 },
    { lat: 49.322097, lng: 20.876436 },
    { lat: 49.322119, lng: 20.876632 },
    { lat: 49.32214, lng: 20.87678 },
    { lat: 49.322162, lng: 20.876932 },
    { lat: 49.322153, lng: 20.877123 },
    { lat: 49.322154, lng: 20.877359 },
    { lat: 49.322146, lng: 20.877693 },
    { lat: 49.322137, lng: 20.878091 },
    { lat: 49.322135, lng: 20.878159 },
    { lat: 49.322143, lng: 20.878229 },
    { lat: 49.322199, lng: 20.878482 },
    { lat: 49.322319, lng: 20.878879 },
    { lat: 49.322385, lng: 20.879133 },
    { lat: 49.322394, lng: 20.879237 },
    { lat: 49.322427, lng: 20.879368 },
    { lat: 49.322502, lng: 20.879513 },
    { lat: 49.32255, lng: 20.879581 },
    { lat: 49.322615, lng: 20.879638 },
    { lat: 49.322826, lng: 20.879751 },
    { lat: 49.322985, lng: 20.879837 },
    { lat: 49.323039, lng: 20.879865 },
    { lat: 49.32311, lng: 20.879872 },
    { lat: 49.323173, lng: 20.879878 },
    { lat: 49.323309, lng: 20.879968 },
    { lat: 49.323331, lng: 20.879982 },
    { lat: 49.323467, lng: 20.880029 },
    { lat: 49.323541, lng: 20.880055 },
    { lat: 49.323615, lng: 20.88008 },
    { lat: 49.324242, lng: 20.88045 },
    { lat: 49.324867, lng: 20.880824 },
    { lat: 49.325573, lng: 20.881082 },
    { lat: 49.326316, lng: 20.88086 },
    { lat: 49.326563, lng: 20.880807 },
    { lat: 49.32662, lng: 20.88081 },
    { lat: 49.326676, lng: 20.880802 },
    { lat: 49.326739, lng: 20.880794 },
    { lat: 49.326828, lng: 20.880789 },
    { lat: 49.326924, lng: 20.880783 },
    { lat: 49.327027, lng: 20.880802 },
    { lat: 49.327052, lng: 20.880807 },
    { lat: 49.327149, lng: 20.880827 },
    { lat: 49.327158, lng: 20.880829 },
    { lat: 49.327171, lng: 20.880836 },
    { lat: 49.327195, lng: 20.880855 },
    { lat: 49.327215, lng: 20.880873 },
    { lat: 49.327294, lng: 20.880955 },
    { lat: 49.327372, lng: 20.881037 },
    { lat: 49.327386, lng: 20.88106 },
    { lat: 49.3274, lng: 20.881083 },
    { lat: 49.327435, lng: 20.881143 },
    { lat: 49.327474, lng: 20.881264 },
    { lat: 49.327503, lng: 20.881416 },
    { lat: 49.327576, lng: 20.881545 },
    { lat: 49.327646, lng: 20.881612 },
    { lat: 49.327662, lng: 20.881635 },
    { lat: 49.327731, lng: 20.88175 },
    { lat: 49.327821, lng: 20.88181 },
    { lat: 49.327859, lng: 20.88183 },
    { lat: 49.327867, lng: 20.881832 },
    { lat: 49.327914, lng: 20.881845 },
    { lat: 49.328011, lng: 20.881879 },
    { lat: 49.32841, lng: 20.881993 },
    { lat: 49.328469, lng: 20.882006 },
    { lat: 49.328498, lng: 20.882004 },
    { lat: 49.328527, lng: 20.882001 },
    { lat: 49.328552, lng: 20.881996 },
    { lat: 49.328573, lng: 20.88199 },
    { lat: 49.328653, lng: 20.881919 },
    { lat: 49.328774, lng: 20.881829 },
    { lat: 49.328941, lng: 20.881706 },
    { lat: 49.328983, lng: 20.881677 },
    { lat: 49.329084, lng: 20.881647 },
    { lat: 49.329254, lng: 20.881591 },
    { lat: 49.329426, lng: 20.881533 },
    { lat: 49.329458, lng: 20.881516 },
    { lat: 49.329481, lng: 20.881491 },
    { lat: 49.329506, lng: 20.881427 },
    { lat: 49.329533, lng: 20.881354 },
    { lat: 49.329558, lng: 20.881296 },
    { lat: 49.329592, lng: 20.881223 },
    { lat: 49.329645, lng: 20.88114 },
    { lat: 49.329678, lng: 20.881135 },
    { lat: 49.329719, lng: 20.881152 },
    { lat: 49.329756, lng: 20.881172 },
    { lat: 49.329813, lng: 20.88121 },
    { lat: 49.329912, lng: 20.881275 },
    { lat: 49.329997, lng: 20.881325 },
    { lat: 49.330065, lng: 20.881362 },
    { lat: 49.330137, lng: 20.88138 },
    { lat: 49.33017, lng: 20.88138 },
    { lat: 49.330223, lng: 20.881367 },
    { lat: 49.330336, lng: 20.881266 },
    { lat: 49.330385, lng: 20.88122 },
    { lat: 49.330474, lng: 20.881179 },
    { lat: 49.330558, lng: 20.881182 },
    { lat: 49.330624, lng: 20.881225 },
    { lat: 49.330952, lng: 20.881456 },
    { lat: 49.331122, lng: 20.881565 },
    { lat: 49.331169, lng: 20.881595 },
    { lat: 49.331208, lng: 20.881614 },
    { lat: 49.331234, lng: 20.881619 },
    { lat: 49.33128, lng: 20.881614 },
    { lat: 49.331337, lng: 20.881655 },
    { lat: 49.33139, lng: 20.88171 },
    { lat: 49.331467, lng: 20.881806 },
    { lat: 49.33156, lng: 20.881918 },
    { lat: 49.331655, lng: 20.881994 },
    { lat: 49.331761, lng: 20.88208 },
    { lat: 49.331844, lng: 20.882169 },
    { lat: 49.331926, lng: 20.882242 },
    { lat: 49.332026, lng: 20.882288 },
    { lat: 49.3321, lng: 20.882312 },
    { lat: 49.332169, lng: 20.882335 },
    { lat: 49.332371, lng: 20.882338 },
    { lat: 49.332584, lng: 20.882345 },
    { lat: 49.332734, lng: 20.882361 },
    { lat: 49.332857, lng: 20.882412 },
    { lat: 49.332876, lng: 20.882421 },
    { lat: 49.332912, lng: 20.882426 },
    { lat: 49.333097, lng: 20.882386 },
    { lat: 49.33335, lng: 20.882291 },
    { lat: 49.334414, lng: 20.881256 },
    { lat: 49.334749, lng: 20.879662 },
    { lat: 49.3349144, lng: 20.8791317 },
    { lat: 49.335156, lng: 20.878357 },
    { lat: 49.335294, lng: 20.878132 },
    { lat: 49.335596, lng: 20.877704 },
    { lat: 49.336061, lng: 20.877063 },
    { lat: 49.336686, lng: 20.876524 },
    { lat: 49.337135, lng: 20.876286 },
    { lat: 49.337771, lng: 20.875858 },
    { lat: 49.338061, lng: 20.875383 },
    { lat: 49.338146, lng: 20.875261 },
    { lat: 49.338252, lng: 20.875109 },
    { lat: 49.338306, lng: 20.874799 },
    { lat: 49.338569, lng: 20.874574 },
    { lat: 49.338994, lng: 20.874272 },
    { lat: 49.339469, lng: 20.874024 },
    { lat: 49.339807, lng: 20.873205 },
    { lat: 49.340066, lng: 20.872819 },
    { lat: 49.340627, lng: 20.871943 },
    { lat: 49.3406538, lng: 20.8719233 },
    { lat: 49.340965, lng: 20.871469 },
    { lat: 49.341299, lng: 20.871239 },
    { lat: 49.341456, lng: 20.8714 },
    { lat: 49.341479, lng: 20.871326 },
    { lat: 49.341598, lng: 20.871053 },
    { lat: 49.341603, lng: 20.870965 },
    { lat: 49.341584, lng: 20.870914 },
    { lat: 49.341594, lng: 20.870842 },
    { lat: 49.341693, lng: 20.870626 },
    { lat: 49.341804, lng: 20.870463 },
    { lat: 49.34195, lng: 20.87031 },
    { lat: 49.342046, lng: 20.870264 },
    { lat: 49.342123, lng: 20.870289 },
    { lat: 49.342214, lng: 20.870301 },
    { lat: 49.342356, lng: 20.870346 },
    { lat: 49.342477, lng: 20.870352 },
    { lat: 49.342607, lng: 20.870331 },
    { lat: 49.342745, lng: 20.870261 },
    { lat: 49.342941, lng: 20.870034 },
    { lat: 49.343042, lng: 20.870021 },
    { lat: 49.343254, lng: 20.869866 },
    { lat: 49.343523, lng: 20.869739 },
    { lat: 49.343683, lng: 20.869644 },
    { lat: 49.34384, lng: 20.869551 },
    { lat: 49.343944, lng: 20.869493 },
    { lat: 49.344012, lng: 20.869465 },
    { lat: 49.344132, lng: 20.869413 },
    { lat: 49.344196, lng: 20.869377 },
    { lat: 49.344303, lng: 20.869324 },
    { lat: 49.344488, lng: 20.869248 },
    { lat: 49.344682, lng: 20.869171 },
    { lat: 49.344798, lng: 20.86912 },
    { lat: 49.345026, lng: 20.869003 },
    { lat: 49.345043, lng: 20.86899 },
    { lat: 49.345191, lng: 20.868882 },
    { lat: 49.34532, lng: 20.868777 },
    { lat: 49.345329, lng: 20.868767 },
    { lat: 49.345453, lng: 20.868643 },
    { lat: 49.345625, lng: 20.868476 },
    { lat: 49.345725, lng: 20.868372 },
    { lat: 49.34596, lng: 20.868064 },
    { lat: 49.346077, lng: 20.86788 },
    { lat: 49.346199, lng: 20.867685 },
    { lat: 49.346356, lng: 20.867428 },
    { lat: 49.346483, lng: 20.867255 },
    { lat: 49.34668, lng: 20.866985 },
    { lat: 49.346843, lng: 20.86674 },
    { lat: 49.347109, lng: 20.866226 },
    { lat: 49.347271, lng: 20.865976 },
    { lat: 49.347484, lng: 20.865549 },
    { lat: 49.347499, lng: 20.865503 },
    { lat: 49.3475535, lng: 20.8653228 },
    { lat: 49.347616, lng: 20.865089 },
    { lat: 49.347656, lng: 20.8648967 },
    { lat: 49.347705, lng: 20.864616 },
    { lat: 49.347758, lng: 20.864381 },
    { lat: 49.347767, lng: 20.86432 },
    { lat: 49.347776, lng: 20.864247 },
    { lat: 49.347783, lng: 20.864188 },
    { lat: 49.347797, lng: 20.864069 },
    { lat: 49.347864, lng: 20.86356 },
    { lat: 49.347873, lng: 20.863522 },
    { lat: 49.34795, lng: 20.863165 },
    { lat: 49.347951, lng: 20.863156 },
    { lat: 49.34805, lng: 20.86278 },
    { lat: 49.348081, lng: 20.862632 },
    { lat: 49.348087, lng: 20.862604 },
    { lat: 49.348176, lng: 20.862295 },
    { lat: 49.348222, lng: 20.862184 },
    { lat: 49.348265, lng: 20.86202 },
    { lat: 49.348316, lng: 20.861764 },
    { lat: 49.348316, lng: 20.86172 },
    { lat: 49.348317, lng: 20.861686 },
    { lat: 49.348347, lng: 20.861235 },
    { lat: 49.348399, lng: 20.860288 },
    { lat: 49.348399, lng: 20.860258 },
    { lat: 49.348402, lng: 20.860016 },
    { lat: 49.348398, lng: 20.85973 },
    { lat: 49.348397, lng: 20.859708 },
    { lat: 49.34839, lng: 20.859568 },
    { lat: 49.348388, lng: 20.85954 },
    { lat: 49.348314, lng: 20.858927 },
    { lat: 49.348311, lng: 20.858903 },
    { lat: 49.34828, lng: 20.858729 },
    { lat: 49.348261, lng: 20.858686 },
    { lat: 49.348208, lng: 20.858344 },
    { lat: 49.348195, lng: 20.858266 },
    { lat: 49.348118, lng: 20.857701 },
    { lat: 49.348114, lng: 20.857681 },
    { lat: 49.348033, lng: 20.8573 },
    { lat: 49.348033, lng: 20.857291 },
    { lat: 49.347945, lng: 20.856897 },
    { lat: 49.347941, lng: 20.856877 },
    { lat: 49.347662, lng: 20.855581 },
    { lat: 49.347658, lng: 20.855546 },
    { lat: 49.347594, lng: 20.855326 },
    { lat: 49.34755, lng: 20.855207 },
    { lat: 49.347497, lng: 20.854988 },
    { lat: 49.347433, lng: 20.854738 },
    { lat: 49.347421, lng: 20.854687 },
    { lat: 49.347281, lng: 20.854385 },
    { lat: 49.347164, lng: 20.854179 },
    { lat: 49.347094, lng: 20.854067 },
    { lat: 49.34689, lng: 20.853886 },
    { lat: 49.346747, lng: 20.853735 },
    { lat: 49.346697, lng: 20.85367 },
    { lat: 49.346473, lng: 20.853377 },
    { lat: 49.346411, lng: 20.853325 },
    { lat: 49.346305, lng: 20.853229 },
    { lat: 49.346209, lng: 20.853145 },
    { lat: 49.346041, lng: 20.853018 },
    { lat: 49.346016, lng: 20.853001 },
    { lat: 49.345873, lng: 20.852917 },
    { lat: 49.345709, lng: 20.852822 },
    { lat: 49.345678, lng: 20.852803 },
    { lat: 49.345543, lng: 20.852739 },
    { lat: 49.345409, lng: 20.85269 },
    { lat: 49.345204, lng: 20.852621 },
    { lat: 49.345102, lng: 20.852572 },
    { lat: 49.345067, lng: 20.852561 },
    { lat: 49.345029, lng: 20.852548 },
    { lat: 49.344753, lng: 20.852474 },
    { lat: 49.344651, lng: 20.85246 },
    { lat: 49.344286, lng: 20.852423 },
    { lat: 49.343972, lng: 20.852229 },
    { lat: 49.343781, lng: 20.85211 },
    { lat: 49.34377, lng: 20.8521 },
    { lat: 49.343681, lng: 20.852032 },
    { lat: 49.3436, lng: 20.851966 },
    { lat: 49.34341, lng: 20.85179 },
    { lat: 49.343258, lng: 20.851621 },
    { lat: 49.343157, lng: 20.851495 },
    { lat: 49.343076, lng: 20.851401 },
    { lat: 49.342999, lng: 20.85131 },
    { lat: 49.342955, lng: 20.851185 },
    { lat: 49.342881, lng: 20.851104 },
    { lat: 49.34276, lng: 20.850985 },
    { lat: 49.342653, lng: 20.850885 },
    { lat: 49.342584, lng: 20.85081 },
    { lat: 49.342378, lng: 20.850582 },
    { lat: 49.342305, lng: 20.850504 },
    { lat: 49.342139, lng: 20.850318 },
    { lat: 49.342104, lng: 20.850282 },
    { lat: 49.341884, lng: 20.850047 },
    { lat: 49.34176, lng: 20.849907 },
    { lat: 49.341662, lng: 20.849771 },
    { lat: 49.341608, lng: 20.849692 },
    { lat: 49.341446, lng: 20.849432 },
    { lat: 49.341408, lng: 20.849365 },
    { lat: 49.34131, lng: 20.849194 },
    { lat: 49.341176, lng: 20.848934 },
    { lat: 49.34108, lng: 20.848738 },
    { lat: 49.341016, lng: 20.848596 },
    { lat: 49.340997, lng: 20.848549 },
    { lat: 49.340968, lng: 20.848449 },
    { lat: 49.340918, lng: 20.848317 },
    { lat: 49.3407788, lng: 20.8479435 },
    { lat: 49.340745, lng: 20.847852 },
    { lat: 49.340656, lng: 20.847513 },
    { lat: 49.340628, lng: 20.847389 },
    { lat: 49.340623, lng: 20.847367 },
    { lat: 49.340595, lng: 20.847144 },
    { lat: 49.340575, lng: 20.846967 },
    { lat: 49.340554, lng: 20.846836 },
    { lat: 49.340528, lng: 20.846664 },
    { lat: 49.340517, lng: 20.846374 },
    { lat: 49.340502, lng: 20.846256 },
    { lat: 49.340467, lng: 20.846023 },
    { lat: 49.340414, lng: 20.8457 },
    { lat: 49.340396, lng: 20.8456 },
    { lat: 49.340329, lng: 20.845201 },
    { lat: 49.34025, lng: 20.844898 },
    { lat: 49.340167, lng: 20.844598 },
    { lat: 49.340179, lng: 20.844511 },
    { lat: 49.340155, lng: 20.844431 },
    { lat: 49.340091, lng: 20.844173 },
    { lat: 49.340036, lng: 20.843996 },
    { lat: 49.33993, lng: 20.843682 },
    { lat: 49.339909, lng: 20.843641 },
    { lat: 49.339755, lng: 20.84333 },
    { lat: 49.339744, lng: 20.843306 },
    { lat: 49.339577, lng: 20.843061 },
    { lat: 49.339531, lng: 20.843002 },
    { lat: 49.339464, lng: 20.842921 },
    { lat: 49.339395, lng: 20.842866 },
    { lat: 49.339337, lng: 20.842831 },
    { lat: 49.339148, lng: 20.842744 },
    { lat: 49.339026, lng: 20.842674 },
    { lat: 49.338859, lng: 20.842533 },
    { lat: 49.338764, lng: 20.84242 },
    { lat: 49.338633, lng: 20.84231 },
    { lat: 49.338551, lng: 20.842258 },
    { lat: 49.338382, lng: 20.84216 },
    { lat: 49.338336, lng: 20.842143 },
    { lat: 49.338087, lng: 20.841968 },
    { lat: 49.33798, lng: 20.841881 },
    { lat: 49.337858, lng: 20.8418 },
    { lat: 49.337703, lng: 20.841696 },
    { lat: 49.337343, lng: 20.841437 },
    { lat: 49.337134, lng: 20.841294 },
    { lat: 49.337006, lng: 20.841198 },
    { lat: 49.336951, lng: 20.841166 },
    { lat: 49.336828, lng: 20.841084 },
    { lat: 49.336616, lng: 20.840912 },
    { lat: 49.336563, lng: 20.840888 },
    { lat: 49.336484, lng: 20.84084 },
    { lat: 49.33633, lng: 20.840763 },
    { lat: 49.336193, lng: 20.840698 },
    { lat: 49.336051, lng: 20.84063 },
    { lat: 49.335916, lng: 20.840542 },
    { lat: 49.335735, lng: 20.840419 },
    { lat: 49.335678, lng: 20.840379 },
    { lat: 49.335489, lng: 20.840254 },
    { lat: 49.335481, lng: 20.840249 },
    { lat: 49.335321, lng: 20.840108 },
    { lat: 49.335218, lng: 20.840028 },
    { lat: 49.335105, lng: 20.839916 },
    { lat: 49.334915, lng: 20.839699 },
    { lat: 49.334804, lng: 20.839536 },
    { lat: 49.334735, lng: 20.839411 },
    { lat: 49.334647, lng: 20.839219 },
    { lat: 49.334531, lng: 20.838983 },
    { lat: 49.334368, lng: 20.838636 },
    { lat: 49.334186, lng: 20.838235 },
  ],
  Chmeľnica: [
    { lat: 49.2981963, lng: 20.7571158 },
    { lat: 49.2981361, lng: 20.7566253 },
    { lat: 49.2981339, lng: 20.7557526 },
    { lat: 49.2982126, lng: 20.7552652 },
    { lat: 49.2982998, lng: 20.7550688 },
    { lat: 49.2984111, lng: 20.7548533 },
    { lat: 49.2985631, lng: 20.7546512 },
    { lat: 49.2986821, lng: 20.75445 },
    { lat: 49.2990968, lng: 20.7540469 },
    { lat: 49.2992702, lng: 20.753789 },
    { lat: 49.2993288, lng: 20.7535978 },
    { lat: 49.2995319, lng: 20.7535167 },
    { lat: 49.2995867, lng: 20.7531462 },
    { lat: 49.2998619, lng: 20.7530711 },
    { lat: 49.3000498, lng: 20.7527431 },
    { lat: 49.3003597, lng: 20.7524935 },
    { lat: 49.3004856, lng: 20.7522596 },
    { lat: 49.3008477, lng: 20.7520602 },
    { lat: 49.3009635, lng: 20.7516407 },
    { lat: 49.30118, lng: 20.7516797 },
    { lat: 49.301143, lng: 20.7514577 },
    { lat: 49.3016179, lng: 20.7508462 },
    { lat: 49.3020768, lng: 20.7497347 },
    { lat: 49.3031849, lng: 20.747942 },
    { lat: 49.3038224, lng: 20.7475091 },
    { lat: 49.3039162, lng: 20.7475479 },
    { lat: 49.3041513, lng: 20.7473841 },
    { lat: 49.3042179, lng: 20.7473863 },
    { lat: 49.3043134, lng: 20.7475751 },
    { lat: 49.3044878, lng: 20.7475781 },
    { lat: 49.3047125, lng: 20.7473744 },
    { lat: 49.3050295, lng: 20.7468133 },
    { lat: 49.3052698, lng: 20.7467634 },
    { lat: 49.3055332, lng: 20.746282 },
    { lat: 49.3057653, lng: 20.7461891 },
    { lat: 49.3060162, lng: 20.7458977 },
    { lat: 49.3061884, lng: 20.7458628 },
    { lat: 49.3067964, lng: 20.7454838 },
    { lat: 49.3075118, lng: 20.7452425 },
    { lat: 49.307854, lng: 20.7453776 },
    { lat: 49.3080683, lng: 20.7452927 },
    { lat: 49.3085015, lng: 20.74542 },
    { lat: 49.3085745, lng: 20.7453495 },
    { lat: 49.3087907, lng: 20.7453535 },
    { lat: 49.3089851, lng: 20.7455656 },
    { lat: 49.3093383, lng: 20.7454649 },
    { lat: 49.3095859, lng: 20.7451071 },
    { lat: 49.309684, lng: 20.7451117 },
    { lat: 49.3099105, lng: 20.7449587 },
    { lat: 49.3100193, lng: 20.7450169 },
    { lat: 49.3102464, lng: 20.7448793 },
    { lat: 49.3102873, lng: 20.7447883 },
    { lat: 49.3107605, lng: 20.7444531 },
    { lat: 49.3109577, lng: 20.7442314 },
    { lat: 49.3110484, lng: 20.744316 },
    { lat: 49.3112002, lng: 20.7442448 },
    { lat: 49.3115801, lng: 20.744296 },
    { lat: 49.3127027, lng: 20.7446928 },
    { lat: 49.3131335, lng: 20.7448448 },
    { lat: 49.3137844, lng: 20.7443436 },
    { lat: 49.3143693, lng: 20.7436541 },
    { lat: 49.3146943, lng: 20.7435449 },
    { lat: 49.3148523, lng: 20.7432676 },
    { lat: 49.3150168, lng: 20.7426311 },
    { lat: 49.3152223, lng: 20.7422928 },
    { lat: 49.3162929, lng: 20.7413629 },
    { lat: 49.3164896, lng: 20.7411001 },
    { lat: 49.3169305, lng: 20.739902 },
    { lat: 49.3173453, lng: 20.7390473 },
    { lat: 49.3176625, lng: 20.7386226 },
    { lat: 49.3186532, lng: 20.7378233 },
    { lat: 49.319038, lng: 20.7373664 },
    { lat: 49.3192481, lng: 20.736847 },
    { lat: 49.3193632, lng: 20.7358901 },
    { lat: 49.3194395, lng: 20.7351698 },
    { lat: 49.3192679, lng: 20.7346131 },
    { lat: 49.3194464, lng: 20.7336355 },
    { lat: 49.3191929, lng: 20.7320204 },
    { lat: 49.3190025, lng: 20.7316874 },
    { lat: 49.3187505, lng: 20.7314335 },
    { lat: 49.3184952, lng: 20.7309937 },
    { lat: 49.3180942, lng: 20.7312752 },
    { lat: 49.3180102, lng: 20.7312532 },
    { lat: 49.3180815, lng: 20.7309132 },
    { lat: 49.3180542, lng: 20.7307733 },
    { lat: 49.3179615, lng: 20.7306658 },
    { lat: 49.3181329, lng: 20.7305143 },
    { lat: 49.3181126, lng: 20.7303533 },
    { lat: 49.3180562, lng: 20.730412 },
    { lat: 49.3179765, lng: 20.7301317 },
    { lat: 49.3179381, lng: 20.7297416 },
    { lat: 49.3180316, lng: 20.7297112 },
    { lat: 49.3180613, lng: 20.7295605 },
    { lat: 49.3181344, lng: 20.7294657 },
    { lat: 49.3181745, lng: 20.7297439 },
    { lat: 49.3184533, lng: 20.7299252 },
    { lat: 49.3185979, lng: 20.7301352 },
    { lat: 49.3186925, lng: 20.7301697 },
    { lat: 49.3185653, lng: 20.7288802 },
    { lat: 49.3190374, lng: 20.7285763 },
    { lat: 49.3190507, lng: 20.7283075 },
    { lat: 49.3191339, lng: 20.7282126 },
    { lat: 49.3194693, lng: 20.7282396 },
    { lat: 49.3199747, lng: 20.7266362 },
    { lat: 49.3197821, lng: 20.7264106 },
    { lat: 49.3195798, lng: 20.7264204 },
    { lat: 49.3194495, lng: 20.7262348 },
    { lat: 49.3192708, lng: 20.7259208 },
    { lat: 49.3192623, lng: 20.7255787 },
    { lat: 49.3191704, lng: 20.7253993 },
    { lat: 49.3186043, lng: 20.7253707 },
    { lat: 49.3183697, lng: 20.7251302 },
    { lat: 49.3181915, lng: 20.7248317 },
    { lat: 49.3179282, lng: 20.7239686 },
    { lat: 49.3176779, lng: 20.7236027 },
    { lat: 49.3175226, lng: 20.723473 },
    { lat: 49.3172742, lng: 20.723546 },
    { lat: 49.3168488, lng: 20.7238371 },
    { lat: 49.3164417, lng: 20.72375 },
    { lat: 49.3155526, lng: 20.7232736 },
    { lat: 49.3152627, lng: 20.7232205 },
    { lat: 49.314819, lng: 20.7233807 },
    { lat: 49.3139457, lng: 20.7235426 },
    { lat: 49.3133296, lng: 20.7238778 },
    { lat: 49.3117641, lng: 20.725556 },
    { lat: 49.3099223, lng: 20.7261812 },
    { lat: 49.3098299, lng: 20.7268218 },
    { lat: 49.3095344, lng: 20.7271599 },
    { lat: 49.3093388, lng: 20.7271045 },
    { lat: 49.3092814, lng: 20.7273228 },
    { lat: 49.3092525, lng: 20.7284654 },
    { lat: 49.3091402, lng: 20.7290752 },
    { lat: 49.3086337, lng: 20.7290118 },
    { lat: 49.3084303, lng: 20.728351 },
    { lat: 49.3078707, lng: 20.727802 },
    { lat: 49.3076944, lng: 20.7278122 },
    { lat: 49.3075348, lng: 20.7281037 },
    { lat: 49.3073583, lng: 20.7282426 },
    { lat: 49.3073198, lng: 20.7286177 },
    { lat: 49.3068682, lng: 20.7284841 },
    { lat: 49.3062447, lng: 20.7282261 },
    { lat: 49.3063824, lng: 20.727343 },
    { lat: 49.3060193, lng: 20.7271913 },
    { lat: 49.305526, lng: 20.7267026 },
    { lat: 49.3054053, lng: 20.7266321 },
    { lat: 49.3052897, lng: 20.7266711 },
    { lat: 49.3052453, lng: 20.7267498 },
    { lat: 49.3052232, lng: 20.7270196 },
    { lat: 49.3050108, lng: 20.7271932 },
    { lat: 49.3049002, lng: 20.7272116 },
    { lat: 49.304619, lng: 20.7270619 },
    { lat: 49.3045337, lng: 20.7269719 },
    { lat: 49.3045351, lng: 20.7268491 },
    { lat: 49.3033693, lng: 20.725862 },
    { lat: 49.3025705, lng: 20.7252916 },
    { lat: 49.3028122, lng: 20.7245161 },
    { lat: 49.3027069, lng: 20.7244328 },
    { lat: 49.3027265, lng: 20.7243533 },
    { lat: 49.3026585, lng: 20.7243047 },
    { lat: 49.3025879, lng: 20.7244447 },
    { lat: 49.3022166, lng: 20.7243464 },
    { lat: 49.3020259, lng: 20.7243707 },
    { lat: 49.3016196, lng: 20.7240181 },
    { lat: 49.3013191, lng: 20.7240161 },
    { lat: 49.3011603, lng: 20.724397 },
    { lat: 49.3010902, lng: 20.7243292 },
    { lat: 49.300999, lng: 20.7244521 },
    { lat: 49.3003095, lng: 20.7233744 },
    { lat: 49.3000905, lng: 20.7234508 },
    { lat: 49.2997673, lng: 20.7233211 },
    { lat: 49.298838, lng: 20.7226299 },
    { lat: 49.2977745, lng: 20.721702 },
    { lat: 49.2977579, lng: 20.7209737 },
    { lat: 49.2975573, lng: 20.7206497 },
    { lat: 49.2965895, lng: 20.7195586 },
    { lat: 49.2964989, lng: 20.7197994 },
    { lat: 49.2963312, lng: 20.7199011 },
    { lat: 49.2948722, lng: 20.7205669 },
    { lat: 49.2945898, lng: 20.720633 },
    { lat: 49.2942228, lng: 20.7210151 },
    { lat: 49.2937162, lng: 20.7217138 },
    { lat: 49.2936212, lng: 20.7214814 },
    { lat: 49.293518, lng: 20.7213958 },
    { lat: 49.2926274, lng: 20.7227956 },
    { lat: 49.2925055, lng: 20.7226599 },
    { lat: 49.2923824, lng: 20.7227749 },
    { lat: 49.2921338, lng: 20.7227766 },
    { lat: 49.2921867, lng: 20.7226175 },
    { lat: 49.2922616, lng: 20.7225728 },
    { lat: 49.2923263, lng: 20.7223003 },
    { lat: 49.2923041, lng: 20.7222112 },
    { lat: 49.2922106, lng: 20.7221742 },
    { lat: 49.2922844, lng: 20.7215551 },
    { lat: 49.2919654, lng: 20.721251 },
    { lat: 49.2917737, lng: 20.7208574 },
    { lat: 49.2917999, lng: 20.7207352 },
    { lat: 49.2917597, lng: 20.7206873 },
    { lat: 49.2916469, lng: 20.7208383 },
    { lat: 49.291512, lng: 20.7207112 },
    { lat: 49.2912881, lng: 20.7208107 },
    { lat: 49.291083, lng: 20.720807 },
    { lat: 49.2910037, lng: 20.7207255 },
    { lat: 49.2909811, lng: 20.7204963 },
    { lat: 49.2908578, lng: 20.7202576 },
    { lat: 49.2906953, lng: 20.7202435 },
    { lat: 49.2906711, lng: 20.7203883 },
    { lat: 49.2906017, lng: 20.7204322 },
    { lat: 49.2904513, lng: 20.7200598 },
    { lat: 49.2902186, lng: 20.720164 },
    { lat: 49.2901587, lng: 20.7200965 },
    { lat: 49.2901606, lng: 20.7198944 },
    { lat: 49.2901055, lng: 20.7198307 },
    { lat: 49.2900278, lng: 20.7198281 },
    { lat: 49.2900243, lng: 20.7199575 },
    { lat: 49.2899305, lng: 20.7199443 },
    { lat: 49.2898633, lng: 20.7197587 },
    { lat: 49.2897592, lng: 20.7197416 },
    { lat: 49.2895341, lng: 20.7195185 },
    { lat: 49.2893766, lng: 20.7198423 },
    { lat: 49.2892678, lng: 20.7197921 },
    { lat: 49.289329, lng: 20.7196086 },
    { lat: 49.2892816, lng: 20.7195061 },
    { lat: 49.2891846, lng: 20.71954 },
    { lat: 49.2889704, lng: 20.7193964 },
    { lat: 49.2889353, lng: 20.7191959 },
    { lat: 49.2888371, lng: 20.7192224 },
    { lat: 49.2886862, lng: 20.7188082 },
    { lat: 49.2887434, lng: 20.7182861 },
    { lat: 49.2886547, lng: 20.718293 },
    { lat: 49.288499, lng: 20.7181258 },
    { lat: 49.2883849, lng: 20.7178255 },
    { lat: 49.2883821, lng: 20.7175838 },
    { lat: 49.2882747, lng: 20.7173802 },
    { lat: 49.2882392, lng: 20.7171019 },
    { lat: 49.2881608, lng: 20.7170517 },
    { lat: 49.2880497, lng: 20.7167435 },
    { lat: 49.2879437, lng: 20.7166383 },
    { lat: 49.2878541, lng: 20.7166817 },
    { lat: 49.2875249, lng: 20.7163796 },
    { lat: 49.2871007, lng: 20.7158393 },
    { lat: 49.2871326, lng: 20.7156604 },
    { lat: 49.2868675, lng: 20.7156079 },
    { lat: 49.2867367, lng: 20.7152227 },
    { lat: 49.2865769, lng: 20.7150905 },
    { lat: 49.2863772, lng: 20.7150464 },
    { lat: 49.2860938, lng: 20.7147686 },
    { lat: 49.286046, lng: 20.7145879 },
    { lat: 49.2859409, lng: 20.7144848 },
    { lat: 49.2858846, lng: 20.7143257 },
    { lat: 49.2858027, lng: 20.7143299 },
    { lat: 49.2857162, lng: 20.714111 },
    { lat: 49.2856329, lng: 20.7141103 },
    { lat: 49.2855805, lng: 20.7139671 },
    { lat: 49.2837462, lng: 20.7141688 },
    { lat: 49.2834016, lng: 20.7137147 },
    { lat: 49.2828621, lng: 20.7132554 },
    { lat: 49.2822265, lng: 20.7130419 },
    { lat: 49.2813249, lng: 20.712563 },
    { lat: 49.2811623, lng: 20.7124203 },
    { lat: 49.2812377, lng: 20.71274 },
    { lat: 49.2812629, lng: 20.7131338 },
    { lat: 49.2811844, lng: 20.7138808 },
    { lat: 49.2810002, lng: 20.7142748 },
    { lat: 49.2802775, lng: 20.7144031 },
    { lat: 49.2788537, lng: 20.7153977 },
    { lat: 49.2782683, lng: 20.7159357 },
    { lat: 49.2782677, lng: 20.7164949 },
    { lat: 49.2776846, lng: 20.7183861 },
    { lat: 49.2771545, lng: 20.719731 },
    { lat: 49.2768463, lng: 20.7210833 },
    { lat: 49.2764854, lng: 20.7206789 },
    { lat: 49.2760012, lng: 20.720352 },
    { lat: 49.2758946, lng: 20.7203929 },
    { lat: 49.2757682, lng: 20.720285 },
    { lat: 49.27546, lng: 20.7204823 },
    { lat: 49.2750857, lng: 20.7204344 },
    { lat: 49.2744911, lng: 20.720591 },
    { lat: 49.2744149, lng: 20.720537 },
    { lat: 49.2741305, lng: 20.7206044 },
    { lat: 49.2736908, lng: 20.7205896 },
    { lat: 49.2736267, lng: 20.7203963 },
    { lat: 49.2734262, lng: 20.7203295 },
    { lat: 49.2726603, lng: 20.7205351 },
    { lat: 49.2723698, lng: 20.7205052 },
    { lat: 49.2722698, lng: 20.7205714 },
    { lat: 49.2719857, lng: 20.7204026 },
    { lat: 49.2716594, lng: 20.720083 },
    { lat: 49.2714117, lng: 20.7202108 },
    { lat: 49.2710452, lng: 20.7202495 },
    { lat: 49.2704606, lng: 20.7199186 },
    { lat: 49.2698481, lng: 20.7199477 },
    { lat: 49.269583, lng: 20.7198332 },
    { lat: 49.2693993, lng: 20.7198947 },
    { lat: 49.2691142, lng: 20.7198223 },
    { lat: 49.2679886, lng: 20.7190161 },
    { lat: 49.2675304, lng: 20.7194945 },
    { lat: 49.2671352, lng: 20.719745 },
    { lat: 49.2666369, lng: 20.7197625 },
    { lat: 49.2656171, lng: 20.7204023 },
    { lat: 49.2651489, lng: 20.7209453 },
    { lat: 49.2648517, lng: 20.7220329 },
    { lat: 49.2647535, lng: 20.7221841 },
    { lat: 49.2643064, lng: 20.7226251 },
    { lat: 49.2635629, lng: 20.7231543 },
    { lat: 49.2636781, lng: 20.7239024 },
    { lat: 49.2632654, lng: 20.7243006 },
    { lat: 49.2631053, lng: 20.7243898 },
    { lat: 49.263042, lng: 20.7243128 },
    { lat: 49.2629038, lng: 20.7244211 },
    { lat: 49.262782, lng: 20.7249889 },
    { lat: 49.2628232, lng: 20.7258725 },
    { lat: 49.262672, lng: 20.7262824 },
    { lat: 49.2628257, lng: 20.7280959 },
    { lat: 49.2633265, lng: 20.728251 },
    { lat: 49.2633609, lng: 20.7284597 },
    { lat: 49.2633122, lng: 20.728712 },
    { lat: 49.2632389, lng: 20.728833 },
    { lat: 49.2634694, lng: 20.7292134 },
    { lat: 49.2634882, lng: 20.7295878 },
    { lat: 49.2628182, lng: 20.7300593 },
    { lat: 49.2625478, lng: 20.7300576 },
    { lat: 49.2618179, lng: 20.7297181 },
    { lat: 49.2615907, lng: 20.729688 },
    { lat: 49.2615322, lng: 20.7295941 },
    { lat: 49.2610939, lng: 20.7295535 },
    { lat: 49.2602561, lng: 20.7292509 },
    { lat: 49.2602856, lng: 20.728202 },
    { lat: 49.2600664, lng: 20.7282383 },
    { lat: 49.2595354, lng: 20.7285993 },
    { lat: 49.2592858, lng: 20.7289279 },
    { lat: 49.2586962, lng: 20.7290659 },
    { lat: 49.2575301, lng: 20.7300259 },
    { lat: 49.2568707, lng: 20.7302575 },
    { lat: 49.2560192, lng: 20.7312057 },
    { lat: 49.2555256, lng: 20.7318612 },
    { lat: 49.2551014, lng: 20.7321853 },
    { lat: 49.2546716, lng: 20.731841 },
    { lat: 49.2540723, lng: 20.7316335 },
    { lat: 49.2536742, lng: 20.7316091 },
    { lat: 49.2530371, lng: 20.7321855 },
    { lat: 49.2523868, lng: 20.7332894 },
    { lat: 49.2514928, lng: 20.7344647 },
    { lat: 49.2511773, lng: 20.7352616 },
    { lat: 49.2514364, lng: 20.7352051 },
    { lat: 49.2526326, lng: 20.7352294 },
    { lat: 49.2529551, lng: 20.735118 },
    { lat: 49.2536347, lng: 20.7346881 },
    { lat: 49.2539166, lng: 20.7346751 },
    { lat: 49.254688, lng: 20.7348751 },
    { lat: 49.2551106, lng: 20.734793 },
    { lat: 49.2556338, lng: 20.7348864 },
    { lat: 49.2560968, lng: 20.7347069 },
    { lat: 49.2566659, lng: 20.7345966 },
    { lat: 49.2570879, lng: 20.7344021 },
    { lat: 49.257647, lng: 20.734426 },
    { lat: 49.2582311, lng: 20.7340052 },
    { lat: 49.2587662, lng: 20.7337247 },
    { lat: 49.2591954, lng: 20.7333815 },
    { lat: 49.2594536, lng: 20.7331366 },
    { lat: 49.2600167, lng: 20.7323784 },
    { lat: 49.2600831, lng: 20.7323416 },
    { lat: 49.2602712, lng: 20.7324127 },
    { lat: 49.2608786, lng: 20.7330398 },
    { lat: 49.2610253, lng: 20.7333093 },
    { lat: 49.2612378, lng: 20.7333344 },
    { lat: 49.2614877, lng: 20.7334668 },
    { lat: 49.2616526, lng: 20.7341269 },
    { lat: 49.2617993, lng: 20.7344907 },
    { lat: 49.262134, lng: 20.7347247 },
    { lat: 49.2623162, lng: 20.7349797 },
    { lat: 49.2626956, lng: 20.7351699 },
    { lat: 49.2633235, lng: 20.7351993 },
    { lat: 49.2634811, lng: 20.7351327 },
    { lat: 49.2637211, lng: 20.7352699 },
    { lat: 49.2639787, lng: 20.7356222 },
    { lat: 49.2641403, lng: 20.7371803 },
    { lat: 49.265149, lng: 20.7382159 },
    { lat: 49.265705, lng: 20.7386022 },
    { lat: 49.2661229, lng: 20.739476 },
    { lat: 49.26666, lng: 20.7400654 },
    { lat: 49.2674793, lng: 20.7403841 },
    { lat: 49.2680112, lng: 20.7400494 },
    { lat: 49.2681719, lng: 20.7400377 },
    { lat: 49.2683777, lng: 20.740128 },
    { lat: 49.2694066, lng: 20.7412648 },
    { lat: 49.2698632, lng: 20.7419242 },
    { lat: 49.2705002, lng: 20.7426987 },
    { lat: 49.2710494, lng: 20.7436004 },
    { lat: 49.2717162, lng: 20.7443221 },
    { lat: 49.2719813, lng: 20.7447867 },
    { lat: 49.2723771, lng: 20.7451945 },
    { lat: 49.2725246, lng: 20.7454179 },
    { lat: 49.2728041, lng: 20.7455623 },
    { lat: 49.2731157, lng: 20.7455888 },
    { lat: 49.2734168, lng: 20.7457953 },
    { lat: 49.2739652, lng: 20.7464189 },
    { lat: 49.2748215, lng: 20.74699 },
    { lat: 49.2754349, lng: 20.7471815 },
    { lat: 49.2762016, lng: 20.7477685 },
    { lat: 49.2771507, lng: 20.7491 },
    { lat: 49.2772162, lng: 20.7493351 },
    { lat: 49.2774989, lng: 20.7497237 },
    { lat: 49.2781412, lng: 20.7503873 },
    { lat: 49.2786591, lng: 20.7514623 },
    { lat: 49.2787964, lng: 20.7515225 },
    { lat: 49.2798203, lng: 20.7524511 },
    { lat: 49.2803644, lng: 20.7522337 },
    { lat: 49.2822342, lng: 20.7546336 },
    { lat: 49.2823181, lng: 20.7545254 },
    { lat: 49.283031, lng: 20.7546635 },
    { lat: 49.283385, lng: 20.7551972 },
    { lat: 49.2843485, lng: 20.7555517 },
    { lat: 49.2847266, lng: 20.7556201 },
    { lat: 49.2854429, lng: 20.7557284 },
    { lat: 49.286259, lng: 20.7556605 },
    { lat: 49.2897153, lng: 20.7559803 },
    { lat: 49.29092, lng: 20.7552479 },
    { lat: 49.2917406, lng: 20.7549229 },
    { lat: 49.2921257, lng: 20.7548346 },
    { lat: 49.2930525, lng: 20.7544256 },
    { lat: 49.2938944, lng: 20.754489 },
    { lat: 49.2944832, lng: 20.7545947 },
    { lat: 49.2950342, lng: 20.7554824 },
    { lat: 49.2951204, lng: 20.7565706 },
    { lat: 49.2954347, lng: 20.756026 },
    { lat: 49.2956245, lng: 20.7561101 },
    { lat: 49.2958352, lng: 20.7553743 },
    { lat: 49.2961257, lng: 20.7553791 },
    { lat: 49.2964861, lng: 20.7554482 },
    { lat: 49.2970547, lng: 20.7558116 },
    { lat: 49.2974564, lng: 20.7561911 },
    { lat: 49.2975955, lng: 20.7562176 },
    { lat: 49.2981963, lng: 20.7571158 },
  ],
  VyšnéRužbachy: [
    { lat: 49.3346131, lng: 20.5090218 },
    { lat: 49.3333649, lng: 20.5099129 },
    { lat: 49.3319421, lng: 20.5114392 },
    { lat: 49.331544, lng: 20.5120948 },
    { lat: 49.331045, lng: 20.5124508 },
    { lat: 49.3305849, lng: 20.512583 },
    { lat: 49.3302526, lng: 20.5125763 },
    { lat: 49.3290506, lng: 20.5127977 },
    { lat: 49.3286845, lng: 20.5126698 },
    { lat: 49.3285401, lng: 20.5127545 },
    { lat: 49.3282469, lng: 20.5127978 },
    { lat: 49.3279401, lng: 20.5127557 },
    { lat: 49.3276539, lng: 20.5128375 },
    { lat: 49.3270012, lng: 20.5127549 },
    { lat: 49.3265496, lng: 20.5129018 },
    { lat: 49.3260869, lng: 20.5128092 },
    { lat: 49.3259824, lng: 20.5129684 },
    { lat: 49.3256277, lng: 20.512964 },
    { lat: 49.3253549, lng: 20.5132294 },
    { lat: 49.3249784, lng: 20.5133377 },
    { lat: 49.3249299, lng: 20.5134887 },
    { lat: 49.3247069, lng: 20.5138194 },
    { lat: 49.3243761, lng: 20.51404 },
    { lat: 49.3239365, lng: 20.5145103 },
    { lat: 49.3237044, lng: 20.5145938 },
    { lat: 49.3236347, lng: 20.5147182 },
    { lat: 49.3236575, lng: 20.51494 },
    { lat: 49.323439, lng: 20.5151249 },
    { lat: 49.3229855, lng: 20.5150651 },
    { lat: 49.322881, lng: 20.515134 },
    { lat: 49.322806, lng: 20.5154166 },
    { lat: 49.3227039, lng: 20.5154622 },
    { lat: 49.3225355, lng: 20.515446 },
    { lat: 49.3222855, lng: 20.5155304 },
    { lat: 49.3215711, lng: 20.5154816 },
    { lat: 49.321413, lng: 20.515531 },
    { lat: 49.3212985, lng: 20.5154784 },
    { lat: 49.3211443, lng: 20.5156007 },
    { lat: 49.3210428, lng: 20.5161107 },
    { lat: 49.3209512, lng: 20.516346 },
    { lat: 49.3207698, lng: 20.5164038 },
    { lat: 49.3206676, lng: 20.5169604 },
    { lat: 49.3205078, lng: 20.5173921 },
    { lat: 49.3204932, lng: 20.517668 },
    { lat: 49.3203446, lng: 20.5180027 },
    { lat: 49.3201345, lng: 20.5181969 },
    { lat: 49.3201658, lng: 20.51834 },
    { lat: 49.3203061, lng: 20.5184553 },
    { lat: 49.3201803, lng: 20.51901 },
    { lat: 49.3201946, lng: 20.5191538 },
    { lat: 49.3203304, lng: 20.5193012 },
    { lat: 49.3201715, lng: 20.5198407 },
    { lat: 49.3204392, lng: 20.5200511 },
    { lat: 49.3204508, lng: 20.5201219 },
    { lat: 49.3203917, lng: 20.5203825 },
    { lat: 49.3201867, lng: 20.5206514 },
    { lat: 49.3202536, lng: 20.5210018 },
    { lat: 49.3201823, lng: 20.5212371 },
    { lat: 49.3202057, lng: 20.5214093 },
    { lat: 49.3201576, lng: 20.5215766 },
    { lat: 49.319967, lng: 20.5217496 },
    { lat: 49.3199256, lng: 20.5224262 },
    { lat: 49.3197626, lng: 20.5226773 },
    { lat: 49.3197443, lng: 20.5228839 },
    { lat: 49.3196606, lng: 20.5229954 },
    { lat: 49.3195426, lng: 20.5230308 },
    { lat: 49.319305, lng: 20.5233392 },
    { lat: 49.3190675, lng: 20.5233976 },
    { lat: 49.3188558, lng: 20.5238232 },
    { lat: 49.3184427, lng: 20.524439 },
    { lat: 49.3185206, lng: 20.5251183 },
    { lat: 49.3184666, lng: 20.5252103 },
    { lat: 49.3183001, lng: 20.5252433 },
    { lat: 49.3179541, lng: 20.5255712 },
    { lat: 49.3177075, lng: 20.5259017 },
    { lat: 49.3176855, lng: 20.5260742 },
    { lat: 49.317731, lng: 20.5264507 },
    { lat: 49.317688, lng: 20.5265384 },
    { lat: 49.3174378, lng: 20.5266823 },
    { lat: 49.3174294, lng: 20.5268854 },
    { lat: 49.3173161, lng: 20.527107 },
    { lat: 49.3170825, lng: 20.5273689 },
    { lat: 49.3168385, lng: 20.527436 },
    { lat: 49.3166748, lng: 20.5279113 },
    { lat: 49.3163836, lng: 20.5282607 },
    { lat: 49.3161934, lng: 20.5287227 },
    { lat: 49.3159703, lng: 20.529758 },
    { lat: 49.315691, lng: 20.530001 },
    { lat: 49.3152727, lng: 20.5302242 },
    { lat: 49.3152088, lng: 20.530881 },
    { lat: 49.3149798, lng: 20.5315361 },
    { lat: 49.3148434, lng: 20.5316879 },
    { lat: 49.3146069, lng: 20.531776 },
    { lat: 49.3144263, lng: 20.5320679 },
    { lat: 49.314295, lng: 20.5323841 },
    { lat: 49.3143088, lng: 20.532791 },
    { lat: 49.3141493, lng: 20.5331388 },
    { lat: 49.3140735, lng: 20.53322 },
    { lat: 49.3135867, lng: 20.5333625 },
    { lat: 49.3131355, lng: 20.533739 },
    { lat: 49.3130377, lng: 20.5340476 },
    { lat: 49.3129603, lng: 20.5349746 },
    { lat: 49.3128995, lng: 20.5350646 },
    { lat: 49.3125707, lng: 20.535033 },
    { lat: 49.3124119, lng: 20.5351556 },
    { lat: 49.3122741, lng: 20.5353864 },
    { lat: 49.3122379, lng: 20.5356266 },
    { lat: 49.3122527, lng: 20.5359987 },
    { lat: 49.3121327, lng: 20.5361934 },
    { lat: 49.3120811, lng: 20.5365424 },
    { lat: 49.3117438, lng: 20.5357505 },
    { lat: 49.3104916, lng: 20.5343611 },
    { lat: 49.3101568, lng: 20.5355433 },
    { lat: 49.3098936, lng: 20.536316 },
    { lat: 49.3097834, lng: 20.5364737 },
    { lat: 49.3095905, lng: 20.5366487 },
    { lat: 49.3089752, lng: 20.5368732 },
    { lat: 49.3083035, lng: 20.5374684 },
    { lat: 49.3079738, lng: 20.5378376 },
    { lat: 49.3076943, lng: 20.5384161 },
    { lat: 49.3068363, lng: 20.5393278 },
    { lat: 49.3054754, lng: 20.5397663 },
    { lat: 49.3040418, lng: 20.5406064 },
    { lat: 49.3020935, lng: 20.5390816 },
    { lat: 49.3013491, lng: 20.5398099 },
    { lat: 49.3006549, lng: 20.5394196 },
    { lat: 49.2998718, lng: 20.5400437 },
    { lat: 49.2996737, lng: 20.5405978 },
    { lat: 49.2992864, lng: 20.5405668 },
    { lat: 49.2990385, lng: 20.5406854 },
    { lat: 49.2982923, lng: 20.5399814 },
    { lat: 49.2981456, lng: 20.5399107 },
    { lat: 49.2976933, lng: 20.5399791 },
    { lat: 49.2969707, lng: 20.5394314 },
    { lat: 49.2969282, lng: 20.5394957 },
    { lat: 49.2970513, lng: 20.5398465 },
    { lat: 49.2970186, lng: 20.5399648 },
    { lat: 49.2967932, lng: 20.5402647 },
    { lat: 49.2967859, lng: 20.5403753 },
    { lat: 49.2969055, lng: 20.540601 },
    { lat: 49.2967883, lng: 20.5408725 },
    { lat: 49.2960638, lng: 20.5418666 },
    { lat: 49.2951586, lng: 20.5414916 },
    { lat: 49.2948006, lng: 20.5415266 },
    { lat: 49.292268, lng: 20.5428245 },
    { lat: 49.2919885, lng: 20.5428459 },
    { lat: 49.2918794, lng: 20.5434619 },
    { lat: 49.2914749, lng: 20.543573 },
    { lat: 49.2911245, lng: 20.5441177 },
    { lat: 49.2908965, lng: 20.5453053 },
    { lat: 49.2907559, lng: 20.5465973 },
    { lat: 49.2906874, lng: 20.5484771 },
    { lat: 49.2907296, lng: 20.548489 },
    { lat: 49.2908517, lng: 20.5484414 },
    { lat: 49.290933, lng: 20.5490075 },
    { lat: 49.2908965, lng: 20.549267 },
    { lat: 49.2910492, lng: 20.549901 },
    { lat: 49.2910533, lng: 20.5501978 },
    { lat: 49.2909571, lng: 20.5507099 },
    { lat: 49.2911107, lng: 20.5512442 },
    { lat: 49.291407, lng: 20.5526957 },
    { lat: 49.2917341, lng: 20.5533 },
    { lat: 49.2918709, lng: 20.5531895 },
    { lat: 49.2920128, lng: 20.5534388 },
    { lat: 49.2921284, lng: 20.5538653 },
    { lat: 49.2921401, lng: 20.5540901 },
    { lat: 49.292253, lng: 20.5544083 },
    { lat: 49.292254, lng: 20.5547137 },
    { lat: 49.2923523, lng: 20.5552726 },
    { lat: 49.292467, lng: 20.5555794 },
    { lat: 49.2928341, lng: 20.5561991 },
    { lat: 49.2930057, lng: 20.5572293 },
    { lat: 49.2933326, lng: 20.5581394 },
    { lat: 49.2934682, lng: 20.5583965 },
    { lat: 49.2934349, lng: 20.5588733 },
    { lat: 49.2932077, lng: 20.5596151 },
    { lat: 49.2931198, lng: 20.5602941 },
    { lat: 49.2928503, lng: 20.5609256 },
    { lat: 49.292818, lng: 20.5612421 },
    { lat: 49.2920857, lng: 20.5638526 },
    { lat: 49.2918031, lng: 20.5645002 },
    { lat: 49.2916554, lng: 20.5646156 },
    { lat: 49.2915431, lng: 20.5651759 },
    { lat: 49.2917392, lng: 20.5661762 },
    { lat: 49.2919323, lng: 20.5676734 },
    { lat: 49.2923611, lng: 20.5676077 },
    { lat: 49.2929023, lng: 20.5676304 },
    { lat: 49.2930405, lng: 20.5677193 },
    { lat: 49.2929842, lng: 20.5679354 },
    { lat: 49.2927651, lng: 20.5681294 },
    { lat: 49.2926385, lng: 20.5684557 },
    { lat: 49.2926572, lng: 20.5686265 },
    { lat: 49.2928324, lng: 20.5687422 },
    { lat: 49.2933984, lng: 20.5688274 },
    { lat: 49.2934372, lng: 20.5701398 },
    { lat: 49.2937799, lng: 20.5711418 },
    { lat: 49.2938764, lng: 20.571228 },
    { lat: 49.2940635, lng: 20.571202 },
    { lat: 49.2942374, lng: 20.5713131 },
    { lat: 49.2950071, lng: 20.572634 },
    { lat: 49.2956949, lng: 20.5744301 },
    { lat: 49.2969094, lng: 20.5772425 },
    { lat: 49.2969607, lng: 20.5775696 },
    { lat: 49.29718, lng: 20.5782756 },
    { lat: 49.2972542, lng: 20.5791685 },
    { lat: 49.2974907, lng: 20.5800988 },
    { lat: 49.2974812, lng: 20.580214 },
    { lat: 49.2976123, lng: 20.5807861 },
    { lat: 49.2979548, lng: 20.5818766 },
    { lat: 49.2982736, lng: 20.5830942 },
    { lat: 49.2989476, lng: 20.5849732 },
    { lat: 49.2987333, lng: 20.5851206 },
    { lat: 49.298624, lng: 20.5852994 },
    { lat: 49.2985264, lng: 20.5856207 },
    { lat: 49.3006104, lng: 20.5866577 },
    { lat: 49.3012152, lng: 20.5866331 },
    { lat: 49.3014157, lng: 20.5865414 },
    { lat: 49.3023974, lng: 20.5867662 },
    { lat: 49.3030029, lng: 20.5861092 },
    { lat: 49.3035427, lng: 20.5859098 },
    { lat: 49.3042799, lng: 20.5858137 },
    { lat: 49.3052508, lng: 20.5858597 },
    { lat: 49.3057918, lng: 20.5856609 },
    { lat: 49.3061049, lng: 20.5854749 },
    { lat: 49.3069655, lng: 20.5853658 },
    { lat: 49.3077523, lng: 20.5853697 },
    { lat: 49.3078798, lng: 20.5856533 },
    { lat: 49.3081989, lng: 20.5857633 },
    { lat: 49.3095738, lng: 20.5854944 },
    { lat: 49.3105587, lng: 20.5849043 },
    { lat: 49.3111335, lng: 20.5855121 },
    { lat: 49.3116665, lng: 20.5857765 },
    { lat: 49.3119757, lng: 20.5873168 },
    { lat: 49.3120887, lng: 20.5868623 },
    { lat: 49.3124253, lng: 20.5859639 },
    { lat: 49.3128158, lng: 20.5853763 },
    { lat: 49.3140419, lng: 20.5845105 },
    { lat: 49.3159173, lng: 20.5824333 },
    { lat: 49.3163755, lng: 20.5819985 },
    { lat: 49.316369, lng: 20.5819103 },
    { lat: 49.3165759, lng: 20.5816268 },
    { lat: 49.3165875, lng: 20.5815111 },
    { lat: 49.3169321, lng: 20.5811123 },
    { lat: 49.3171229, lng: 20.5805405 },
    { lat: 49.3176137, lng: 20.5798897 },
    { lat: 49.3176594, lng: 20.5797512 },
    { lat: 49.3182091, lng: 20.578955 },
    { lat: 49.3183533, lng: 20.5788067 },
    { lat: 49.319129, lng: 20.5784077 },
    { lat: 49.3192613, lng: 20.5782965 },
    { lat: 49.3193169, lng: 20.5780934 },
    { lat: 49.3196059, lng: 20.577958 },
    { lat: 49.3199395, lng: 20.5771675 },
    { lat: 49.3200531, lng: 20.5771651 },
    { lat: 49.3223331, lng: 20.5756883 },
    { lat: 49.3229486, lng: 20.5735173 },
    { lat: 49.323671, lng: 20.570348 },
    { lat: 49.3240275, lng: 20.5667564 },
    { lat: 49.32473, lng: 20.5665995 },
    { lat: 49.3250307, lng: 20.5660173 },
    { lat: 49.3258459, lng: 20.5651611 },
    { lat: 49.3268649, lng: 20.5658461 },
    { lat: 49.3271221, lng: 20.5672205 },
    { lat: 49.327985, lng: 20.56699 },
    { lat: 49.3297356, lng: 20.5677687 },
    { lat: 49.331432, lng: 20.5672729 },
    { lat: 49.3323624, lng: 20.5666976 },
    { lat: 49.332737, lng: 20.5669707 },
    { lat: 49.3330051, lng: 20.5673824 },
    { lat: 49.3331449, lng: 20.5674189 },
    { lat: 49.3331758, lng: 20.5677371 },
    { lat: 49.3336126, lng: 20.5678955 },
    { lat: 49.3352014, lng: 20.567537 },
    { lat: 49.3363677, lng: 20.5674748 },
    { lat: 49.3371165, lng: 20.5679762 },
    { lat: 49.3377447, lng: 20.5677624 },
    { lat: 49.3380504, lng: 20.5676587 },
    { lat: 49.3376308, lng: 20.5653795 },
    { lat: 49.3377533, lng: 20.5619751 },
    { lat: 49.3375673, lng: 20.5578928 },
    { lat: 49.3373752, lng: 20.556331 },
    { lat: 49.3377657, lng: 20.553247 },
    { lat: 49.3380321, lng: 20.5478397 },
    { lat: 49.3399692, lng: 20.54528 },
    { lat: 49.3403495, lng: 20.5430651 },
    { lat: 49.3404444, lng: 20.5426426 },
    { lat: 49.3404819, lng: 20.5426224 },
    { lat: 49.3404693, lng: 20.5425867 },
    { lat: 49.3405573, lng: 20.5417307 },
    { lat: 49.3405222, lng: 20.5412038 },
    { lat: 49.3404722, lng: 20.5408496 },
    { lat: 49.3402162, lng: 20.5400687 },
    { lat: 49.340048, lng: 20.539903 },
    { lat: 49.3393778, lng: 20.5397082 },
    { lat: 49.3390988, lng: 20.5395606 },
    { lat: 49.3385523, lng: 20.5390877 },
    { lat: 49.3382369, lng: 20.5385837 },
    { lat: 49.3380347, lng: 20.538417 },
    { lat: 49.3376654, lng: 20.5374596 },
    { lat: 49.3374464, lng: 20.5371373 },
    { lat: 49.3370584, lng: 20.5360125 },
    { lat: 49.336985, lng: 20.535547 },
    { lat: 49.3368541, lng: 20.5352915 },
    { lat: 49.3365861, lng: 20.5349489 },
    { lat: 49.3364147, lng: 20.5344835 },
    { lat: 49.335984, lng: 20.5336661 },
    { lat: 49.3358742, lng: 20.5331896 },
    { lat: 49.3354214, lng: 20.5329423 },
    { lat: 49.334646, lng: 20.531346 },
    { lat: 49.334816, lng: 20.5308556 },
    { lat: 49.3349776, lng: 20.5295267 },
    { lat: 49.3350647, lng: 20.5283168 },
    { lat: 49.3350029, lng: 20.5275836 },
    { lat: 49.3350614, lng: 20.5270335 },
    { lat: 49.3350454, lng: 20.5265331 },
    { lat: 49.3351429, lng: 20.5256934 },
    { lat: 49.3350755, lng: 20.5253314 },
    { lat: 49.3348327, lng: 20.5246354 },
    { lat: 49.3347772, lng: 20.5234937 },
    { lat: 49.3348116, lng: 20.5226829 },
    { lat: 49.3347954, lng: 20.5214159 },
    { lat: 49.3353493, lng: 20.5205635 },
    { lat: 49.334882, lng: 20.5180846 },
    { lat: 49.3347688, lng: 20.5163891 },
    { lat: 49.3346376, lng: 20.5153911 },
    { lat: 49.3345768, lng: 20.5149062 },
    { lat: 49.3346509, lng: 20.5143811 },
    { lat: 49.33502, lng: 20.5133356 },
    { lat: 49.3350131, lng: 20.5127197 },
    { lat: 49.3350757, lng: 20.5117945 },
    { lat: 49.3350187, lng: 20.51135 },
    { lat: 49.3351479, lng: 20.5105804 },
    { lat: 49.3351118, lng: 20.5103006 },
    { lat: 49.3346131, lng: 20.5090218 },
  ],
  Kremná: [
    { lat: 49.3486781, lng: 20.6868235 },
    { lat: 49.3487386, lng: 20.6870001 },
    { lat: 49.3490946, lng: 20.6880291 },
    { lat: 49.3493506, lng: 20.6902394 },
    { lat: 49.349847, lng: 20.6919349 },
    { lat: 49.3501064, lng: 20.6933168 },
    { lat: 49.3501675, lng: 20.6953682 },
    { lat: 49.3501336, lng: 20.695971 },
    { lat: 49.3500101, lng: 20.696869 },
    { lat: 49.3500549, lng: 20.6970553 },
    { lat: 49.3499079, lng: 20.6980906 },
    { lat: 49.3494354, lng: 20.6985787 },
    { lat: 49.3491206, lng: 20.6991282 },
    { lat: 49.3486099, lng: 20.7009868 },
    { lat: 49.3488828, lng: 20.7010664 },
    { lat: 49.3490527, lng: 20.7013249 },
    { lat: 49.3496396, lng: 20.7014927 },
    { lat: 49.3510124, lng: 20.702287 },
    { lat: 49.3508421, lng: 20.7039062 },
    { lat: 49.3509172, lng: 20.7044055 },
    { lat: 49.3514207, lng: 20.70512 },
    { lat: 49.3516569, lng: 20.7055922 },
    { lat: 49.3515989, lng: 20.7056385 },
    { lat: 49.3515519, lng: 20.7059063 },
    { lat: 49.3515806, lng: 20.7060162 },
    { lat: 49.3515194, lng: 20.7062274 },
    { lat: 49.3513795, lng: 20.7065105 },
    { lat: 49.3513876, lng: 20.7067048 },
    { lat: 49.3510956, lng: 20.707048 },
    { lat: 49.3510017, lng: 20.7073478 },
    { lat: 49.3508169, lng: 20.707406 },
    { lat: 49.3508166, lng: 20.7075314 },
    { lat: 49.3507282, lng: 20.7076058 },
    { lat: 49.3504801, lng: 20.708262 },
    { lat: 49.3502433, lng: 20.7085722 },
    { lat: 49.3503529, lng: 20.7092278 },
    { lat: 49.3503734, lng: 20.7096164 },
    { lat: 49.3499968, lng: 20.710845 },
    { lat: 49.3497201, lng: 20.7113945 },
    { lat: 49.3490906, lng: 20.7121186 },
    { lat: 49.3485371, lng: 20.7125758 },
    { lat: 49.3485513, lng: 20.712758 },
    { lat: 49.3470205, lng: 20.7136826 },
    { lat: 49.3470735, lng: 20.7139443 },
    { lat: 49.3465557, lng: 20.7150624 },
    { lat: 49.3460242, lng: 20.7149635 },
    { lat: 49.3455036, lng: 20.7157767 },
    { lat: 49.3454818, lng: 20.7160547 },
    { lat: 49.3457651, lng: 20.7166537 },
    { lat: 49.3461531, lng: 20.7173529 },
    { lat: 49.3462008, lng: 20.7173608 },
    { lat: 49.346191, lng: 20.7175331 },
    { lat: 49.3462382, lng: 20.7176877 },
    { lat: 49.3462993, lng: 20.7177007 },
    { lat: 49.3463761, lng: 20.7179016 },
    { lat: 49.3466118, lng: 20.7181039 },
    { lat: 49.3468413, lng: 20.7184883 },
    { lat: 49.3468281, lng: 20.7187222 },
    { lat: 49.346906, lng: 20.7188512 },
    { lat: 49.3472194, lng: 20.718998 },
    { lat: 49.3473193, lng: 20.718828 },
    { lat: 49.3475274, lng: 20.7189243 },
    { lat: 49.3476093, lng: 20.7187226 },
    { lat: 49.3478169, lng: 20.7187658 },
    { lat: 49.3480018, lng: 20.7187105 },
    { lat: 49.3480583, lng: 20.718621 },
    { lat: 49.3487411, lng: 20.718801 },
    { lat: 49.3497683, lng: 20.7176071 },
    { lat: 49.350922, lng: 20.7166428 },
    { lat: 49.3518011, lng: 20.715717 },
    { lat: 49.3522039, lng: 20.7151646 },
    { lat: 49.3527095, lng: 20.7143103 },
    { lat: 49.3531781, lng: 20.7136778 },
    { lat: 49.3531484, lng: 20.7136315 },
    { lat: 49.3543822, lng: 20.7119084 },
    { lat: 49.3546499, lng: 20.7115863 },
    { lat: 49.3549541, lng: 20.7113362 },
    { lat: 49.3554892, lng: 20.7110329 },
    { lat: 49.3560944, lng: 20.7109249 },
    { lat: 49.3571001, lng: 20.7110056 },
    { lat: 49.3580885, lng: 20.7109415 },
    { lat: 49.358624, lng: 20.7109714 },
    { lat: 49.3594722, lng: 20.7107917 },
    { lat: 49.3617776, lng: 20.7106418 },
    { lat: 49.3625355, lng: 20.7104616 },
    { lat: 49.3627751, lng: 20.7104613 },
    { lat: 49.3633503, lng: 20.7105519 },
    { lat: 49.3642833, lng: 20.7109329 },
    { lat: 49.366098, lng: 20.7110737 },
    { lat: 49.3667398, lng: 20.7108886 },
    { lat: 49.3670838, lng: 20.7110054 },
    { lat: 49.3677185, lng: 20.7113883 },
    { lat: 49.3677318, lng: 20.7113507 },
    { lat: 49.3679947, lng: 20.7107234 },
    { lat: 49.3680142, lng: 20.7106745 },
    { lat: 49.3680985, lng: 20.7106986 },
    { lat: 49.3682535, lng: 20.7103687 },
    { lat: 49.3685128, lng: 20.7094202 },
    { lat: 49.3686339, lng: 20.7087035 },
    { lat: 49.3684959, lng: 20.7079203 },
    { lat: 49.3681106, lng: 20.7063059 },
    { lat: 49.3679695, lng: 20.7050791 },
    { lat: 49.3679894, lng: 20.7042706 },
    { lat: 49.367484, lng: 20.7013306 },
    { lat: 49.3672974, lng: 20.7007007 },
    { lat: 49.3672662, lng: 20.6998151 },
    { lat: 49.3673149, lng: 20.6994366 },
    { lat: 49.3672917, lng: 20.6992608 },
    { lat: 49.3671233, lng: 20.6986322 },
    { lat: 49.3670218, lng: 20.6984955 },
    { lat: 49.3667181, lng: 20.6975483 },
    { lat: 49.3663938, lng: 20.6968738 },
    { lat: 49.3660924, lng: 20.6960493 },
    { lat: 49.3660811, lng: 20.695917 },
    { lat: 49.3663619, lng: 20.6957753 },
    { lat: 49.3667104, lng: 20.6954088 },
    { lat: 49.3667176, lng: 20.6951983 },
    { lat: 49.3667899, lng: 20.6951158 },
    { lat: 49.3667932, lng: 20.6949483 },
    { lat: 49.3669459, lng: 20.6947522 },
    { lat: 49.3671294, lng: 20.6946247 },
    { lat: 49.3668032, lng: 20.6938275 },
    { lat: 49.3665788, lng: 20.6923567 },
    { lat: 49.3665946, lng: 20.6921965 },
    { lat: 49.3665135, lng: 20.691621 },
    { lat: 49.3663594, lng: 20.6912387 },
    { lat: 49.3665939, lng: 20.6909328 },
    { lat: 49.3677863, lng: 20.6897004 },
    { lat: 49.3677223, lng: 20.6895768 },
    { lat: 49.3680345, lng: 20.6892085 },
    { lat: 49.3679873, lng: 20.6890484 },
    { lat: 49.3680705, lng: 20.6887577 },
    { lat: 49.3679792, lng: 20.6885312 },
    { lat: 49.3689246, lng: 20.686972 },
    { lat: 49.369125, lng: 20.6864028 },
    { lat: 49.369199, lng: 20.6857191 },
    { lat: 49.3692082, lng: 20.6856317 },
    { lat: 49.3682038, lng: 20.6845701 },
    { lat: 49.3652752, lng: 20.682125 },
    { lat: 49.3645463, lng: 20.6829637 },
    { lat: 49.3633831, lng: 20.6822184 },
    { lat: 49.3625435, lng: 20.6810133 },
    { lat: 49.3617925, lng: 20.6815798 },
    { lat: 49.3611317, lng: 20.6817043 },
    { lat: 49.3606958, lng: 20.6813426 },
    { lat: 49.3602267, lng: 20.6823692 },
    { lat: 49.3596026, lng: 20.6831504 },
    { lat: 49.3584128, lng: 20.6839139 },
    { lat: 49.3580377, lng: 20.6842581 },
    { lat: 49.3579782, lng: 20.684102 },
    { lat: 49.3570901, lng: 20.6842608 },
    { lat: 49.354072, lng: 20.6860598 },
    { lat: 49.3534001, lng: 20.6857418 },
    { lat: 49.3521703, lng: 20.6866496 },
    { lat: 49.3520752, lng: 20.6869481 },
    { lat: 49.3509003, lng: 20.6870802 },
    { lat: 49.3486781, lng: 20.6868235 },
  ],
};

export default UnitsStaráĽubovňa;
