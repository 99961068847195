import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { FinBroker } from "../../data/FinBroker";
import FIBPreview from "./FIBPreview";

export default function FinBrokers({
  size,
  setSubjectsMenu,
  subjectsMenu,
  indx,
  subMenu,
  setSubMenu,
  setForSale,
  forSale,
  navSmall,
  setNavSmall,
  viewSale,
  setViewSale,
  indxPrev,
  subjects,
  setSubjects,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return (
    <>
      {subjectsMenu.FIB ? (
        <FIBPreview
          subMenu={subMenu}
          setSubMenu={setSubMenu}
          size={size}
          indx={indx}
          subjectsMenu={subjectsMenu}
          setForSale={setForSale}
          forSale={forSale}
          navSmall={navSmall}
          setNavSmall={setNavSmall}
          viewSale={viewSale}
          setViewSale={setViewSale}
          indxPrev={indxPrev}
          setSubjectsMenu={setSubjectsMenu}
          subjects={subjects}
          setSubjects={setSubjects}
        />
      ) : (
        <div
          className={clsx("flex flex-col", {
            "grid sm:grid-cols-2": !isSmallDevice && size.full,
          })}
        >
          {FinBroker?.map((office, index) => (
            <div
              key={index}
              className={clsx(
                "transition-all duration-500 ease-linear min-h-max w-full flex-col justify-center sm:flex overflow-ellipsis p-2",
              )}
            >
              <div
                onClick={() => {
                  setSubjectsMenu({ ...subjectsMenu, REO: false, FI: false, FIB: true, REB: false });
                  indx.current = index;
                }}
                className={clsx(
                  "w-full border border-opacity-50 border-sky-500 h-auto flex flex-col gap-2 cursor-pointer hover:bg-sky-300 rounded-lg shadow-lg shadow-sky-200 text-xs",
                )}
              >
                <div className={clsx("flex flex-row w-full gap-x-2 items-center", {})}>
                  <img src={office?.img} alt="" className="w-1/3 h-24 rounded-l-lg object-contain" />
                  <div className={clsx("w-full flex justify-between", {})}>
                    <div className="flex flex-col w-2/3 h-24 p-[2px] items-start justify-between">
                      <div className="flex flex-col gap-x-2 gap-y-[2px]">
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden">
                          {office?.brokerName}
                        </p>
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden">
                          {office?.name}
                        </p>
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden">{office?.tel}</p>
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden">
                          {office?.email}
                        </p>
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden gap-1">
                          {office?.street} {office?.number}, {office?.psc} {office?.city}
                        </p>
                      </div>
                      <div className="w-full h-auto flex justify-end text-xl font-semibold"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
