import React from "react";

const SelectStatus = (props)  => {

    const setOpen = (event) => {
        props.changeStatus(event.target.value)
       // props.changeIsOpen(false)
        props.resetAvgPrice([])
    }
    
    return(
        <div className={`relative w-screen px-2 sm:max-w-sm ${props.catVal.substring(0, 1)  === '6' || props.catVal === "" ? "hidden" : ""}`}>
            <p className="text-white">Stav:</p>
            <select name="status" value={props.statVal} onChange={(event) => setOpen(event)} className='w-full mb-4 p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600'>
                <option value="">
                    Stav
                </option>
                {props.statuses.map(status => (
                    <option value={status.code} key={status.name}>
                    {status.code} {status.name_diacritics}
                    </option>
                ))}
            </select>
        </div>
    )

}

export default SelectStatus