const UnitsŽilina = {
  Fačkov: [
    { lat: 49.020263, lng: 18.589339 },
    { lat: 49.01962, lng: 18.588247 },
    { lat: 49.019274, lng: 18.587633 },
    { lat: 49.018854, lng: 18.586945 },
    { lat: 49.018612, lng: 18.586367 },
    { lat: 49.01836, lng: 18.586282 },
    { lat: 49.0181204, lng: 18.5863145 },
    { lat: 49.018065, lng: 18.586322 },
    { lat: 49.01774, lng: 18.586404 },
    { lat: 49.017403, lng: 18.586521 },
    { lat: 49.017279, lng: 18.586565 },
    { lat: 49.016886, lng: 18.586663 },
    { lat: 49.016789, lng: 18.5867208 },
    { lat: 49.016678, lng: 18.586787 },
    { lat: 49.016487, lng: 18.58692 },
    { lat: 49.0164322, lng: 18.5869516 },
    { lat: 49.016204, lng: 18.5870821 },
    { lat: 49.0161365, lng: 18.5871233 },
    { lat: 49.0159929, lng: 18.587134 },
    { lat: 49.015908, lng: 18.5871394 },
    { lat: 49.0158745, lng: 18.5871421 },
    { lat: 49.0158555, lng: 18.5871331 },
    { lat: 49.0156943, lng: 18.5870032 },
    { lat: 49.015666, lng: 18.586991 },
    { lat: 49.015507, lng: 18.586849 },
    { lat: 49.015212, lng: 18.586836 },
    { lat: 49.015032, lng: 18.586883 },
    { lat: 49.014818, lng: 18.587013 },
    { lat: 49.014675, lng: 18.587111 },
    { lat: 49.014482, lng: 18.58731 },
    { lat: 49.014231, lng: 18.587131 },
    { lat: 49.014013, lng: 18.586771 },
    { lat: 49.013758, lng: 18.586212 },
    { lat: 49.013668, lng: 18.586089 },
    { lat: 49.013549, lng: 18.586019 },
    { lat: 49.013453, lng: 18.586008 },
    { lat: 49.013114, lng: 18.5858119 },
    { lat: 49.012919, lng: 18.585699 },
    { lat: 49.012621, lng: 18.585522 },
    { lat: 49.012424, lng: 18.585359 },
    { lat: 49.012281, lng: 18.585139 },
    { lat: 49.012196, lng: 18.585033 },
    { lat: 49.011978, lng: 18.584648 },
    { lat: 49.011896, lng: 18.584657 },
    { lat: 49.011762, lng: 18.584571 },
    { lat: 49.011556, lng: 18.584605 },
    { lat: 49.011269, lng: 18.58453 },
    { lat: 49.011124, lng: 18.584413 },
    { lat: 49.010924, lng: 18.584147 },
    { lat: 49.010654, lng: 18.583659 },
    { lat: 49.01038, lng: 18.583191 },
    { lat: 49.010305, lng: 18.583092 },
    { lat: 49.010244, lng: 18.582948 },
    { lat: 49.01015, lng: 18.582832 },
    { lat: 49.009998, lng: 18.582557 },
    { lat: 49.009884, lng: 18.582313 },
    { lat: 49.009797, lng: 18.582056 },
    { lat: 49.009623, lng: 18.581781 },
    { lat: 49.00922, lng: 18.581324 },
    { lat: 49.008705, lng: 18.580594 },
    { lat: 49.008618, lng: 18.580371 },
    { lat: 49.008457, lng: 18.579982 },
    { lat: 49.008367, lng: 18.579832 },
    { lat: 49.008233, lng: 18.579627 },
    { lat: 49.007992, lng: 18.579146 },
    { lat: 49.007884, lng: 18.578972 },
    { lat: 49.007788, lng: 18.578871 },
    { lat: 49.007106, lng: 18.578186 },
    { lat: 49.006925, lng: 18.577959 },
    { lat: 49.006388, lng: 18.5775 },
    { lat: 49.006296, lng: 18.577347 },
    { lat: 49.006212, lng: 18.577342 },
    { lat: 49.006086, lng: 18.577266 },
    { lat: 49.005816, lng: 18.577143 },
    { lat: 49.005712, lng: 18.576965 },
    { lat: 49.005461, lng: 18.576604 },
    { lat: 49.005352, lng: 18.576403 },
    { lat: 49.004948, lng: 18.576256 },
    { lat: 49.004337, lng: 18.576123 },
    { lat: 49.004115, lng: 18.57594 },
    { lat: 49.003969, lng: 18.575876 },
    { lat: 49.00375, lng: 18.575681 },
    { lat: 49.003136, lng: 18.5751 },
    { lat: 49.002568, lng: 18.574594 },
    { lat: 49.001835, lng: 18.573566 },
    { lat: 49.001751, lng: 18.573468 },
    { lat: 49.00169, lng: 18.573349 },
    { lat: 49.001467, lng: 18.57311 },
    { lat: 49.000496, lng: 18.571524 },
    { lat: 49.000156, lng: 18.571168 },
    { lat: 48.999425, lng: 18.570668 },
    { lat: 48.999082, lng: 18.570544 },
    { lat: 48.998908, lng: 18.570239 },
    { lat: 48.998654, lng: 18.570077 },
    { lat: 48.998098, lng: 18.569639 },
    { lat: 48.998001, lng: 18.569107 },
    { lat: 48.997886, lng: 18.568463 },
    { lat: 48.997673, lng: 18.567805 },
    { lat: 48.997587, lng: 18.567547 },
    { lat: 48.997244, lng: 18.566639 },
    { lat: 48.997055, lng: 18.566124 },
    { lat: 48.996948, lng: 18.565832 },
    { lat: 48.99645, lng: 18.565086 },
    { lat: 48.996337, lng: 18.564679 },
    { lat: 48.996123, lng: 18.56392 },
    { lat: 48.995991, lng: 18.563519 },
    { lat: 48.995918, lng: 18.563299 },
    { lat: 48.995817, lng: 18.563003 },
    { lat: 48.995637, lng: 18.562535 },
    { lat: 48.995553, lng: 18.562373 },
    { lat: 48.995438, lng: 18.562121 },
    { lat: 48.995337, lng: 18.561913 },
    { lat: 48.995195, lng: 18.561593 },
    { lat: 48.995009, lng: 18.561188 },
    { lat: 48.994871, lng: 18.560967 },
    { lat: 48.994652, lng: 18.56062 },
    { lat: 48.994593, lng: 18.560061 },
    { lat: 48.994538, lng: 18.559515 },
    { lat: 48.994526, lng: 18.559162 },
    { lat: 48.994515, lng: 18.558855 },
    { lat: 48.994499, lng: 18.558467 },
    { lat: 48.99445, lng: 18.557385 },
    { lat: 48.994439, lng: 18.556569 },
    { lat: 48.994433, lng: 18.556026 },
    { lat: 48.994364, lng: 18.555259 },
    { lat: 48.994115, lng: 18.554558 },
    { lat: 48.994035, lng: 18.554091 },
    { lat: 48.994026, lng: 18.553675 },
    { lat: 48.993847, lng: 18.553329 },
    { lat: 48.9938017, lng: 18.5532169 },
    { lat: 48.99359, lng: 18.552693 },
    { lat: 48.993552, lng: 18.552222 },
    { lat: 48.993387, lng: 18.551886 },
    { lat: 48.993243, lng: 18.551321 },
    { lat: 48.993074, lng: 18.550531 },
    { lat: 48.993023, lng: 18.550297 },
    { lat: 48.99303, lng: 18.549099 },
    { lat: 48.993151, lng: 18.548544 },
    { lat: 48.992985, lng: 18.548032 },
    { lat: 48.992758, lng: 18.547508 },
    { lat: 48.992511, lng: 18.547092 },
    { lat: 48.992326, lng: 18.546806 },
    { lat: 48.992492, lng: 18.545666 },
    { lat: 48.992627, lng: 18.544984 },
    { lat: 48.992808, lng: 18.543975 },
    { lat: 48.992863, lng: 18.543347 },
    { lat: 48.992763, lng: 18.54255 },
    { lat: 48.992685, lng: 18.542002 },
    { lat: 48.992634, lng: 18.541933 },
    { lat: 48.992158, lng: 18.541298 },
    { lat: 48.991344, lng: 18.540153 },
    { lat: 48.991152, lng: 18.539785 },
    { lat: 48.991069, lng: 18.539668 },
    { lat: 48.990904, lng: 18.538878 },
    { lat: 48.990949, lng: 18.538428 },
    { lat: 48.99129, lng: 18.536961 },
    { lat: 48.991416, lng: 18.536324 },
    { lat: 48.991499, lng: 18.5354 },
    { lat: 48.991604, lng: 18.534439 },
    { lat: 48.991707, lng: 18.533453 },
    { lat: 48.991868, lng: 18.53232 },
    { lat: 48.9915403, lng: 18.5325619 },
    { lat: 48.9908986, lng: 18.5330468 },
    { lat: 48.9904372, lng: 18.5333977 },
    { lat: 48.9894986, lng: 18.5341209 },
    { lat: 48.9885832, lng: 18.5349996 },
    { lat: 48.9878138, lng: 18.53556 },
    { lat: 48.9870624, lng: 18.5361182 },
    { lat: 48.9866457, lng: 18.5363575 },
    { lat: 48.9864865, lng: 18.5364981 },
    { lat: 48.9861125, lng: 18.5366972 },
    { lat: 48.9857126, lng: 18.5369327 },
    { lat: 48.9853745, lng: 18.5371873 },
    { lat: 48.9850521, lng: 18.537423 },
    { lat: 48.9846455, lng: 18.5377444 },
    { lat: 48.9846161, lng: 18.5377412 },
    { lat: 48.9842965, lng: 18.5382767 },
    { lat: 48.9841819, lng: 18.5384686 },
    { lat: 48.9838423, lng: 18.5390279 },
    { lat: 48.983715, lng: 18.5393649 },
    { lat: 48.9832377, lng: 18.5416508 },
    { lat: 48.983144, lng: 18.5416876 },
    { lat: 48.9831196, lng: 18.5416968 },
    { lat: 48.9830289, lng: 18.5417326 },
    { lat: 48.9829759, lng: 18.5417528 },
    { lat: 48.9825021, lng: 18.5419378 },
    { lat: 48.9821361, lng: 18.5428412 },
    { lat: 48.9819906, lng: 18.5440462 },
    { lat: 48.9819297, lng: 18.54434 },
    { lat: 48.9818033, lng: 18.5449221 },
    { lat: 48.981709, lng: 18.544931 },
    { lat: 48.9816002, lng: 18.5454618 },
    { lat: 48.9815897, lng: 18.5455159 },
    { lat: 48.981566, lng: 18.5455795 },
    { lat: 48.9815395, lng: 18.5456324 },
    { lat: 48.9810952, lng: 18.5461124 },
    { lat: 48.9807752, lng: 18.5465532 },
    { lat: 48.9806521, lng: 18.5467413 },
    { lat: 48.9805762, lng: 18.5468801 },
    { lat: 48.9805275, lng: 18.5469594 },
    { lat: 48.9803679, lng: 18.5471578 },
    { lat: 48.9801768, lng: 18.5473559 },
    { lat: 48.9799336, lng: 18.5475783 },
    { lat: 48.9798329, lng: 18.5476704 },
    { lat: 48.9796922, lng: 18.5478614 },
    { lat: 48.9796103, lng: 18.5480167 },
    { lat: 48.9795255, lng: 18.5482226 },
    { lat: 48.9792154, lng: 18.5491828 },
    { lat: 48.9791847, lng: 18.5493622 },
    { lat: 48.9791805, lng: 18.5493879 },
    { lat: 48.9791802, lng: 18.549449 },
    { lat: 48.9791791, lng: 18.5495285 },
    { lat: 48.9791796, lng: 18.5495815 },
    { lat: 48.9791815, lng: 18.5496391 },
    { lat: 48.9791839, lng: 18.5497444 },
    { lat: 48.9791844, lng: 18.5498578 },
    { lat: 48.97919, lng: 18.5500219 },
    { lat: 48.9787849, lng: 18.5496032 },
    { lat: 48.9785544, lng: 18.5493455 },
    { lat: 48.9784677, lng: 18.5494731 },
    { lat: 48.9774723, lng: 18.5502772 },
    { lat: 48.9766565, lng: 18.5506851 },
    { lat: 48.9760049, lng: 18.5510046 },
    { lat: 48.9757757, lng: 18.5511227 },
    { lat: 48.9754483, lng: 18.5512663 },
    { lat: 48.9754054, lng: 18.5512863 },
    { lat: 48.9751548, lng: 18.5514074 },
    { lat: 48.9748545, lng: 18.551548 },
    { lat: 48.9747664, lng: 18.5515895 },
    { lat: 48.9747346, lng: 18.5516046 },
    { lat: 48.9746719, lng: 18.551634 },
    { lat: 48.9742478, lng: 18.5518848 },
    { lat: 48.9737712, lng: 18.552311 },
    { lat: 48.9736963, lng: 18.5523853 },
    { lat: 48.9735007, lng: 18.5526065 },
    { lat: 48.9731925, lng: 18.5529556 },
    { lat: 48.9730679, lng: 18.5530893 },
    { lat: 48.972934, lng: 18.5534061 },
    { lat: 48.9731053, lng: 18.553906 },
    { lat: 48.973402, lng: 18.5544678 },
    { lat: 48.9734979, lng: 18.5552005 },
    { lat: 48.9735503, lng: 18.5557088 },
    { lat: 48.9735958, lng: 18.5561526 },
    { lat: 48.9736884, lng: 18.5569255 },
    { lat: 48.9737063, lng: 18.5572382 },
    { lat: 48.9737905, lng: 18.5584193 },
    { lat: 48.9737697, lng: 18.5588689 },
    { lat: 48.9737662, lng: 18.5589493 },
    { lat: 48.9737413, lng: 18.5594896 },
    { lat: 48.9735889, lng: 18.5598385 },
    { lat: 48.9735636, lng: 18.5598966 },
    { lat: 48.9734141, lng: 18.5602391 },
    { lat: 48.9731436, lng: 18.5608671 },
    { lat: 48.9730776, lng: 18.5610221 },
    { lat: 48.9726557, lng: 18.5616693 },
    { lat: 48.9725154, lng: 18.5625578 },
    { lat: 48.9723881, lng: 18.5630192 },
    { lat: 48.9722656, lng: 18.5633634 },
    { lat: 48.972181, lng: 18.563613 },
    { lat: 48.9721199, lng: 18.5637761 },
    { lat: 48.9719596, lng: 18.5642309 },
    { lat: 48.9718688, lng: 18.5647412 },
    { lat: 48.971804, lng: 18.5651072 },
    { lat: 48.9717382, lng: 18.5653118 },
    { lat: 48.9715723, lng: 18.5658104 },
    { lat: 48.9713369, lng: 18.5660856 },
    { lat: 48.9711035, lng: 18.5665926 },
    { lat: 48.970624, lng: 18.5674016 },
    { lat: 48.9703945, lng: 18.5680197 },
    { lat: 48.9702203, lng: 18.5687809 },
    { lat: 48.9700885, lng: 18.5695789 },
    { lat: 48.9699546, lng: 18.5706474 },
    { lat: 48.9698224, lng: 18.5717104 },
    { lat: 48.9695663, lng: 18.5730048 },
    { lat: 48.969835, lng: 18.573528 },
    { lat: 48.970028, lng: 18.573932 },
    { lat: 48.97025, lng: 18.574618 },
    { lat: 48.970377, lng: 18.574988 },
    { lat: 48.970406, lng: 18.57515 },
    { lat: 48.970486, lng: 18.575354 },
    { lat: 48.970695, lng: 18.575929 },
    { lat: 48.970716, lng: 18.575979 },
    { lat: 48.970955, lng: 18.576592 },
    { lat: 48.970982, lng: 18.576654 },
    { lat: 48.971184, lng: 18.577292 },
    { lat: 48.971229, lng: 18.577441 },
    { lat: 48.971243, lng: 18.577496 },
    { lat: 48.971267, lng: 18.577743 },
    { lat: 48.971306, lng: 18.577951 },
    { lat: 48.9713, lng: 18.578041 },
    { lat: 48.97123, lng: 18.578799 },
    { lat: 48.971025, lng: 18.579633 },
    { lat: 48.970938, lng: 18.580266 },
    { lat: 48.970982, lng: 18.58084 },
    { lat: 48.971238, lng: 18.582172 },
    { lat: 48.971413, lng: 18.582933 },
    { lat: 48.971415, lng: 18.583212 },
    { lat: 48.971455, lng: 18.583649 },
    { lat: 48.97148, lng: 18.584045 },
    { lat: 48.971452, lng: 18.584113 },
    { lat: 48.971201, lng: 18.58408 },
    { lat: 48.970505, lng: 18.584032 },
    { lat: 48.970194, lng: 18.584034 },
    { lat: 48.969814, lng: 18.584037 },
    { lat: 48.969462, lng: 18.58404 },
    { lat: 48.968901, lng: 18.584555 },
    { lat: 48.968398, lng: 18.584945 },
    { lat: 48.968162, lng: 18.584956 },
    { lat: 48.96779, lng: 18.584763 },
    { lat: 48.967505, lng: 18.584456 },
    { lat: 48.96733, lng: 18.584465 },
    { lat: 48.967149, lng: 18.58445 },
    { lat: 48.96665, lng: 18.584663 },
    { lat: 48.966217, lng: 18.58505 },
    { lat: 48.965996, lng: 18.585404 },
    { lat: 48.965673, lng: 18.585903 },
    { lat: 48.965143, lng: 18.586485 },
    { lat: 48.964869, lng: 18.586762 },
    { lat: 48.964581, lng: 18.586815 },
    { lat: 48.964169, lng: 18.587074 },
    { lat: 48.963544, lng: 18.58773 },
    { lat: 48.962988, lng: 18.588465 },
    { lat: 48.962707, lng: 18.5885197 },
    { lat: 48.961752, lng: 18.588948 },
    { lat: 48.9613, lng: 18.589454 },
    { lat: 48.960523, lng: 18.589976 },
    { lat: 48.959794, lng: 18.590431 },
    { lat: 48.95925, lng: 18.591197 },
    { lat: 48.95888, lng: 18.591915 },
    { lat: 48.958292, lng: 18.5922 },
    { lat: 48.957835, lng: 18.592578 },
    { lat: 48.957626, lng: 18.592709 },
    { lat: 48.957282, lng: 18.59307 },
    { lat: 48.957074, lng: 18.593247 },
    { lat: 48.957019, lng: 18.593374 },
    { lat: 48.956982, lng: 18.593587 },
    { lat: 48.956906, lng: 18.59423 },
    { lat: 48.956796, lng: 18.595104 },
    { lat: 48.956826, lng: 18.596117 },
    { lat: 48.956648, lng: 18.597581 },
    { lat: 48.956502, lng: 18.600355 },
    { lat: 48.956418, lng: 18.601141 },
    { lat: 48.956529, lng: 18.601165 },
    { lat: 48.957176, lng: 18.601265 },
    { lat: 48.957807, lng: 18.60053 },
    { lat: 48.958018, lng: 18.600398 },
    { lat: 48.958454, lng: 18.600123 },
    { lat: 48.958484, lng: 18.600128 },
    { lat: 48.959259, lng: 18.600258 },
    { lat: 48.960256, lng: 18.599717 },
    { lat: 48.961023, lng: 18.600089 },
    { lat: 48.96128, lng: 18.600262 },
    { lat: 48.961313, lng: 18.600283 },
    { lat: 48.961763, lng: 18.600759 },
    { lat: 48.962623, lng: 18.601667 },
    { lat: 48.962663, lng: 18.60171 },
    { lat: 48.96343, lng: 18.602988 },
    { lat: 48.963602, lng: 18.603087 },
    { lat: 48.963773, lng: 18.603185 },
    { lat: 48.963619, lng: 18.604256 },
    { lat: 48.963677, lng: 18.605073 },
    { lat: 48.963574, lng: 18.605797 },
    { lat: 48.96344, lng: 18.606361 },
    { lat: 48.963392, lng: 18.607286 },
    { lat: 48.963338, lng: 18.607584 },
    { lat: 48.96338, lng: 18.609066 },
    { lat: 48.963669, lng: 18.611065 },
    { lat: 48.9636866, lng: 18.6111897 },
    { lat: 48.963703, lng: 18.611306 },
    { lat: 48.963727, lng: 18.611484 },
    { lat: 48.963765, lng: 18.61176 },
    { lat: 48.963748, lng: 18.613067 },
    { lat: 48.96369, lng: 18.613444 },
    { lat: 48.963565, lng: 18.61518 },
    { lat: 48.963222, lng: 18.615978 },
    { lat: 48.962838, lng: 18.617476 },
    { lat: 48.962522, lng: 18.618312 },
    { lat: 48.962479, lng: 18.618876 },
    { lat: 48.96269, lng: 18.619941 },
    { lat: 48.962866, lng: 18.621414 },
    { lat: 48.963196, lng: 18.622473 },
    { lat: 48.963201, lng: 18.62252 },
    { lat: 48.963267, lng: 18.622868 },
    { lat: 48.963867, lng: 18.623806 },
    { lat: 48.963985, lng: 18.624102 },
    { lat: 48.964097, lng: 18.624369 },
    { lat: 48.964109, lng: 18.624399 },
    { lat: 48.964587, lng: 18.625652 },
    { lat: 48.964704, lng: 18.62596 },
    { lat: 48.964766, lng: 18.626307 },
    { lat: 48.964879, lng: 18.626938 },
    { lat: 48.964881, lng: 18.628145 },
    { lat: 48.965034, lng: 18.631726 },
    { lat: 48.964883, lng: 18.633187 },
    { lat: 48.964796, lng: 18.633357 },
    { lat: 48.964954, lng: 18.633847 },
    { lat: 48.9652568, lng: 18.6344681 },
    { lat: 48.965441, lng: 18.634846 },
    { lat: 48.965882, lng: 18.635474 },
    { lat: 48.966183, lng: 18.635609 },
    { lat: 48.967176, lng: 18.636999 },
    { lat: 48.967285, lng: 18.637145 },
    { lat: 48.967764, lng: 18.637735 },
    { lat: 48.968235, lng: 18.637976 },
    { lat: 48.968983, lng: 18.638022 },
    { lat: 48.969341, lng: 18.638288 },
    { lat: 48.96972, lng: 18.638766 },
    { lat: 48.971065, lng: 18.639734 },
    { lat: 48.971877, lng: 18.640242 },
    { lat: 48.971975, lng: 18.640304 },
    { lat: 48.972569, lng: 18.64069 },
    { lat: 48.973633, lng: 18.642118 },
    { lat: 48.973913, lng: 18.642287 },
    { lat: 48.974981, lng: 18.642252 },
    { lat: 48.975598, lng: 18.64225 },
    { lat: 48.976118, lng: 18.642134 },
    { lat: 48.9775, lng: 18.642551 },
    { lat: 48.978355, lng: 18.642816 },
    { lat: 48.978948, lng: 18.64313 },
    { lat: 48.979384, lng: 18.643142 },
    { lat: 48.979812, lng: 18.642592 },
    { lat: 48.979994, lng: 18.642498 },
    { lat: 48.980326, lng: 18.642342 },
    { lat: 48.981034, lng: 18.641799 },
    { lat: 48.981723, lng: 18.64107 },
    { lat: 48.981713, lng: 18.64293 },
    { lat: 48.982044, lng: 18.643674 },
    { lat: 48.982522, lng: 18.644467 },
    { lat: 48.983439, lng: 18.645417 },
    { lat: 48.984202, lng: 18.64614 },
    { lat: 48.984282, lng: 18.646219 },
    { lat: 48.984722, lng: 18.646905 },
    { lat: 48.98556, lng: 18.648455 },
    { lat: 48.985924, lng: 18.648953 },
    { lat: 48.987193, lng: 18.650062 },
    { lat: 48.98748, lng: 18.650212 },
    { lat: 48.988183, lng: 18.650854 },
    { lat: 48.988322, lng: 18.65099 },
    { lat: 48.988629, lng: 18.6504904 },
    { lat: 48.9889145, lng: 18.6500259 },
    { lat: 48.9897699, lng: 18.6489769 },
    { lat: 48.9904643, lng: 18.6474037 },
    { lat: 48.9914588, lng: 18.6462556 },
    { lat: 48.992022, lng: 18.6465623 },
    { lat: 48.9931083, lng: 18.6469895 },
    { lat: 48.9939826, lng: 18.6468247 },
    { lat: 48.9956018, lng: 18.645864 },
    { lat: 48.996875, lng: 18.6451886 },
    { lat: 48.9976964, lng: 18.6450889 },
    { lat: 48.9986502, lng: 18.6451898 },
    { lat: 48.9993728, lng: 18.6463234 },
    { lat: 49.0003023, lng: 18.646839 },
    { lat: 49.0010866, lng: 18.6471655 },
    { lat: 49.0021201, lng: 18.6461992 },
    { lat: 49.004132, lng: 18.6468739 },
    { lat: 49.0056406, lng: 18.6466321 },
    { lat: 49.0069259, lng: 18.6460988 },
    { lat: 49.0076933, lng: 18.6455584 },
    { lat: 49.0083686, lng: 18.6451703 },
    { lat: 49.0094606, lng: 18.6439713 },
    { lat: 49.0101456, lng: 18.6437479 },
    { lat: 49.0104819, lng: 18.6433044 },
    { lat: 49.0106991, lng: 18.6432115 },
    { lat: 49.0122147, lng: 18.6425947 },
    { lat: 49.0124753, lng: 18.6421505 },
    { lat: 49.012786, lng: 18.6417817 },
    { lat: 49.0130585, lng: 18.6404209 },
    { lat: 49.0130365, lng: 18.6396512 },
    { lat: 49.0151531, lng: 18.6368967 },
    { lat: 49.0151869, lng: 18.6368523 },
    { lat: 49.0156175, lng: 18.6364039 },
    { lat: 49.0159887, lng: 18.6360265 },
    { lat: 49.016167, lng: 18.6347843 },
    { lat: 49.015923, lng: 18.6330434 },
    { lat: 49.0159378, lng: 18.6329654 },
    { lat: 49.0161281, lng: 18.6319631 },
    { lat: 49.0163446, lng: 18.6314546 },
    { lat: 49.0171466, lng: 18.6303603 },
    { lat: 49.0174283, lng: 18.6299352 },
    { lat: 49.0175988, lng: 18.6298122 },
    { lat: 49.0184785, lng: 18.6291774 },
    { lat: 49.0188378, lng: 18.6289178 },
    { lat: 49.0191739, lng: 18.6285385 },
    { lat: 49.0195842, lng: 18.6277948 },
    { lat: 49.0201075, lng: 18.6268461 },
    { lat: 49.0211988, lng: 18.6256763 },
    { lat: 49.0214885, lng: 18.6254421 },
    { lat: 49.022196, lng: 18.6247114 },
    { lat: 49.022296, lng: 18.6246588 },
    { lat: 49.0224168, lng: 18.6247262 },
    { lat: 49.0224973, lng: 18.6245024 },
    { lat: 49.0227003, lng: 18.624353 },
    { lat: 49.0227454, lng: 18.6240376 },
    { lat: 49.0229884, lng: 18.6238139 },
    { lat: 49.023692, lng: 18.6234739 },
    { lat: 49.0244646, lng: 18.6224832 },
    { lat: 49.0245512, lng: 18.6218461 },
    { lat: 49.0248468, lng: 18.6215745 },
    { lat: 49.0250862, lng: 18.6215794 },
    { lat: 49.0252833, lng: 18.6213854 },
    { lat: 49.0256127, lng: 18.6210083 },
    { lat: 49.0256781, lng: 18.6207335 },
    { lat: 49.0258322, lng: 18.6204104 },
    { lat: 49.0258688, lng: 18.6202284 },
    { lat: 49.0259831, lng: 18.6196442 },
    { lat: 49.0260024, lng: 18.6195416 },
    { lat: 49.0259674, lng: 18.6193589 },
    { lat: 49.0259038, lng: 18.6190276 },
    { lat: 49.0258546, lng: 18.6185946 },
    { lat: 49.0257923, lng: 18.6180362 },
    { lat: 49.0257466, lng: 18.6176268 },
    { lat: 49.0256604, lng: 18.6174294 },
    { lat: 49.0257222, lng: 18.6157186 },
    { lat: 49.0257499, lng: 18.6149519 },
    { lat: 49.0264568, lng: 18.6134743 },
    { lat: 49.0267632, lng: 18.6127707 },
    { lat: 49.0273069, lng: 18.6117003 },
    { lat: 49.0278513, lng: 18.6105162 },
    { lat: 49.0275755, lng: 18.6091754 },
    { lat: 49.0275385, lng: 18.6080435 },
    { lat: 49.0277468, lng: 18.6068824 },
    { lat: 49.0278791, lng: 18.606131 },
    { lat: 49.0280685, lng: 18.6044676 },
    { lat: 49.028179, lng: 18.6034558 },
    { lat: 49.0282567, lng: 18.6026975 },
    { lat: 49.0283653, lng: 18.6016957 },
    { lat: 49.028488, lng: 18.600384 },
    { lat: 49.028377, lng: 18.600362 },
    { lat: 49.027921, lng: 18.599644 },
    { lat: 49.027372, lng: 18.598999 },
    { lat: 49.027001, lng: 18.598701 },
    { lat: 49.026201, lng: 18.59869 },
    { lat: 49.025533, lng: 18.598922 },
    { lat: 49.02484, lng: 18.599619 },
    { lat: 49.024233, lng: 18.599602 },
    { lat: 49.024039, lng: 18.599383 },
    { lat: 49.023876, lng: 18.598811 },
    { lat: 49.02384, lng: 18.59799 },
    { lat: 49.023676, lng: 18.59752 },
    { lat: 49.023507, lng: 18.59696 },
    { lat: 49.023115, lng: 18.595888 },
    { lat: 49.022587, lng: 18.595537 },
    { lat: 49.022399, lng: 18.594938 },
    { lat: 49.022177, lng: 18.594741 },
    { lat: 49.021969, lng: 18.594589 },
    { lat: 49.021229, lng: 18.594155 },
    { lat: 49.021126, lng: 18.593616 },
    { lat: 49.021209, lng: 18.592601 },
    { lat: 49.021314, lng: 18.591747 },
    { lat: 49.021334, lng: 18.59102 },
    { lat: 49.020846, lng: 18.590446 },
    { lat: 49.020397, lng: 18.590108 },
    { lat: 49.020263, lng: 18.589339 },
  ],
  Jasenové: [
    { lat: 49.126522, lng: 18.623336 },
    { lat: 49.126441, lng: 18.622855 },
    { lat: 49.125867, lng: 18.62195 },
    { lat: 49.125639, lng: 18.62122 },
    { lat: 49.125896, lng: 18.619885 },
    { lat: 49.126011, lng: 18.618825 },
    { lat: 49.125984, lng: 18.617589 },
    { lat: 49.12611, lng: 18.616789 },
    { lat: 49.126125, lng: 18.616321 },
    { lat: 49.125981, lng: 18.615408 },
    { lat: 49.126063, lng: 18.61453 },
    { lat: 49.126207, lng: 18.613687 },
    { lat: 49.126273, lng: 18.613325 },
    { lat: 49.126193, lng: 18.61216 },
    { lat: 49.126718, lng: 18.610716 },
    { lat: 49.127238, lng: 18.610014 },
    { lat: 49.1274397, lng: 18.6096942 },
    { lat: 49.127578, lng: 18.609475 },
    { lat: 49.126636, lng: 18.609118 },
    { lat: 49.126246, lng: 18.609126 },
    { lat: 49.125932, lng: 18.608897 },
    { lat: 49.125165, lng: 18.60861 },
    { lat: 49.124295, lng: 18.608279 },
    { lat: 49.123782, lng: 18.608084 },
    { lat: 49.1232251, lng: 18.6080314 },
    { lat: 49.123208, lng: 18.607961 },
    { lat: 49.123064, lng: 18.607575 },
    { lat: 49.122765, lng: 18.606947 },
    { lat: 49.122799, lng: 18.606522 },
    { lat: 49.122622, lng: 18.60588 },
    { lat: 49.122335, lng: 18.605117 },
    { lat: 49.121935, lng: 18.605052 },
    { lat: 49.121623, lng: 18.604836 },
    { lat: 49.1212902, lng: 18.6048344 },
    { lat: 49.1208428, lng: 18.6054928 },
    { lat: 49.1207004, lng: 18.6063062 },
    { lat: 49.1204459, lng: 18.6071815 },
    { lat: 49.1200402, lng: 18.6079257 },
    { lat: 49.119571, lng: 18.6082367 },
    { lat: 49.1195058, lng: 18.6080958 },
    { lat: 49.1190072, lng: 18.6078192 },
    { lat: 49.1183742, lng: 18.6082147 },
    { lat: 49.1173379, lng: 18.6088691 },
    { lat: 49.1168827, lng: 18.6091513 },
    { lat: 49.116688, lng: 18.6093278 },
    { lat: 49.1162534, lng: 18.6093411 },
    { lat: 49.1153946, lng: 18.6090985 },
    { lat: 49.1146113, lng: 18.6090265 },
    { lat: 49.1141818, lng: 18.6089902 },
    { lat: 49.1139427, lng: 18.6090933 },
    { lat: 49.1138873, lng: 18.6091135 },
    { lat: 49.1135765, lng: 18.6094175 },
    { lat: 49.1135197, lng: 18.6094721 },
    { lat: 49.112645, lng: 18.6103863 },
    { lat: 49.1119963, lng: 18.6110764 },
    { lat: 49.1110958, lng: 18.6120154 },
    { lat: 49.1106738, lng: 18.6120134 },
    { lat: 49.1101582, lng: 18.611843 },
    { lat: 49.1099261, lng: 18.6117617 },
    { lat: 49.1096311, lng: 18.6114959 },
    { lat: 49.1092161, lng: 18.6113066 },
    { lat: 49.1086908, lng: 18.6108077 },
    { lat: 49.1079931, lng: 18.6104353 },
    { lat: 49.1079466, lng: 18.6104058 },
    { lat: 49.1075378, lng: 18.6100631 },
    { lat: 49.1073485, lng: 18.6095805 },
    { lat: 49.1071606, lng: 18.6092723 },
    { lat: 49.106875, lng: 18.6091191 },
    { lat: 49.1066254, lng: 18.6090928 },
    { lat: 49.1058312, lng: 18.6090845 },
    { lat: 49.105349, lng: 18.6089291 },
    { lat: 49.1052847, lng: 18.6089069 },
    { lat: 49.10511, lng: 18.6093069 },
    { lat: 49.1047861, lng: 18.6097909 },
    { lat: 49.1044922, lng: 18.6103446 },
    { lat: 49.1042394, lng: 18.6108285 },
    { lat: 49.1037802, lng: 18.6117518 },
    { lat: 49.1034903, lng: 18.6123403 },
    { lat: 49.1033439, lng: 18.6127879 },
    { lat: 49.1031296, lng: 18.6130812 },
    { lat: 49.1029141, lng: 18.6134171 },
    { lat: 49.1030104, lng: 18.6136582 },
    { lat: 49.102695, lng: 18.6152702 },
    { lat: 49.1024937, lng: 18.6164592 },
    { lat: 49.1024795, lng: 18.6171891 },
    { lat: 49.1022804, lng: 18.6178109 },
    { lat: 49.1021876, lng: 18.6182885 },
    { lat: 49.1021294, lng: 18.6184642 },
    { lat: 49.1020546, lng: 18.6186711 },
    { lat: 49.1015787, lng: 18.6192429 },
    { lat: 49.1013224, lng: 18.6194489 },
    { lat: 49.1011151, lng: 18.6195135 },
    { lat: 49.1008836, lng: 18.6200558 },
    { lat: 49.1004379, lng: 18.6208128 },
    { lat: 49.1001833, lng: 18.6212403 },
    { lat: 49.1001989, lng: 18.6212794 },
    { lat: 49.1000944, lng: 18.6218142 },
    { lat: 49.0998198, lng: 18.6228596 },
    { lat: 49.0995575, lng: 18.6240509 },
    { lat: 49.0992782, lng: 18.6252639 },
    { lat: 49.0989058, lng: 18.6274967 },
    { lat: 49.0988475, lng: 18.6277987 },
    { lat: 49.0988357, lng: 18.6279589 },
    { lat: 49.0988174, lng: 18.6282095 },
    { lat: 49.0987969, lng: 18.628649 },
    { lat: 49.0987903, lng: 18.6287793 },
    { lat: 49.0987956, lng: 18.6292181 },
    { lat: 49.0988025, lng: 18.629769 },
    { lat: 49.0989595, lng: 18.6302797 },
    { lat: 49.0990205, lng: 18.6304759 },
    { lat: 49.0990366, lng: 18.6306722 },
    { lat: 49.0990272, lng: 18.6308346 },
    { lat: 49.0990249, lng: 18.6308734 },
    { lat: 49.0990083, lng: 18.6311947 },
    { lat: 49.098984, lng: 18.6312484 },
    { lat: 49.0989418, lng: 18.63181 },
    { lat: 49.0989348, lng: 18.6319131 },
    { lat: 49.0989039, lng: 18.6323715 },
    { lat: 49.0988512, lng: 18.6326508 },
    { lat: 49.098834, lng: 18.6328995 },
    { lat: 49.0987851, lng: 18.6336318 },
    { lat: 49.0988415, lng: 18.6337576 },
    { lat: 49.0987946, lng: 18.6344659 },
    { lat: 49.0987852, lng: 18.6346265 },
    { lat: 49.0987306, lng: 18.6354431 },
    { lat: 49.0986703, lng: 18.6364079 },
    { lat: 49.0986148, lng: 18.6372921 },
    { lat: 49.098572, lng: 18.6379694 },
    { lat: 49.0985072, lng: 18.6389135 },
    { lat: 49.0984785, lng: 18.6395825 },
    { lat: 49.0984561, lng: 18.6401168 },
    { lat: 49.098442, lng: 18.640423 },
    { lat: 49.0984175, lng: 18.641256 },
    { lat: 49.0984503, lng: 18.6421247 },
    { lat: 49.0984627, lng: 18.6424647 },
    { lat: 49.0984585, lng: 18.6425765 },
    { lat: 49.0984453, lng: 18.6429643 },
    { lat: 49.0984261, lng: 18.6436191 },
    { lat: 49.0984232, lng: 18.6436944 },
    { lat: 49.098421, lng: 18.6437778 },
    { lat: 49.0984148, lng: 18.6441266 },
    { lat: 49.0984161, lng: 18.6441512 },
    { lat: 49.0984143, lng: 18.6442022 },
    { lat: 49.0984016, lng: 18.6446198 },
    { lat: 49.0983737, lng: 18.6447543 },
    { lat: 49.0983541, lng: 18.6448524 },
    { lat: 49.0983122, lng: 18.6450595 },
    { lat: 49.0983352, lng: 18.645048 },
    { lat: 49.0984223, lng: 18.6450103 },
    { lat: 49.0984642, lng: 18.6449932 },
    { lat: 49.0987694, lng: 18.644857 },
    { lat: 49.098776, lng: 18.6448529 },
    { lat: 49.0988305, lng: 18.6448203 },
    { lat: 49.0989067, lng: 18.644786 },
    { lat: 49.0989946, lng: 18.6447464 },
    { lat: 49.0990144, lng: 18.6447371 },
    { lat: 49.0991686, lng: 18.6446677 },
    { lat: 49.0992314, lng: 18.6446391 },
    { lat: 49.0993041, lng: 18.6446063 },
    { lat: 49.0993752, lng: 18.6445662 },
    { lat: 49.0993787, lng: 18.6445651 },
    { lat: 49.0994965, lng: 18.6445138 },
    { lat: 49.0995318, lng: 18.6444983 },
    { lat: 49.0995593, lng: 18.6444859 },
    { lat: 49.1001137, lng: 18.6442419 },
    { lat: 49.1002568, lng: 18.6442408 },
    { lat: 49.1004224, lng: 18.6442383 },
    { lat: 49.100907, lng: 18.6442329 },
    { lat: 49.1013164, lng: 18.6442291 },
    { lat: 49.1017817, lng: 18.6442207 },
    { lat: 49.101944, lng: 18.644219 },
    { lat: 49.1020591, lng: 18.6442192 },
    { lat: 49.1022706, lng: 18.6442323 },
    { lat: 49.1023587, lng: 18.6442373 },
    { lat: 49.1025443, lng: 18.6442475 },
    { lat: 49.1029677, lng: 18.6442762 },
    { lat: 49.103151, lng: 18.6442881 },
    { lat: 49.1032425, lng: 18.6442934 },
    { lat: 49.1034081, lng: 18.6443089 },
    { lat: 49.1034895, lng: 18.6442939 },
    { lat: 49.1038024, lng: 18.6442405 },
    { lat: 49.1040995, lng: 18.6441836 },
    { lat: 49.1045217, lng: 18.6441287 },
    { lat: 49.104525, lng: 18.6441282 },
    { lat: 49.104725, lng: 18.6440938 },
    { lat: 49.1047487, lng: 18.6440909 },
    { lat: 49.1051706, lng: 18.6440086 },
    { lat: 49.1053697, lng: 18.6439786 },
    { lat: 49.1055705, lng: 18.6439373 },
    { lat: 49.1059253, lng: 18.6436944 },
    { lat: 49.1059501, lng: 18.6436773 },
    { lat: 49.1062398, lng: 18.643481 },
    { lat: 49.1063072, lng: 18.643432 },
    { lat: 49.1066391, lng: 18.6432089 },
    { lat: 49.1070344, lng: 18.6429402 },
    { lat: 49.1074968, lng: 18.6426243 },
    { lat: 49.1076806, lng: 18.6424979 },
    { lat: 49.1077776, lng: 18.6424327 },
    { lat: 49.1080313, lng: 18.6422624 },
    { lat: 49.1080944, lng: 18.6422193 },
    { lat: 49.1082935, lng: 18.6420828 },
    { lat: 49.1084643, lng: 18.6420505 },
    { lat: 49.1089954, lng: 18.6419011 },
    { lat: 49.1093367, lng: 18.6418092 },
    { lat: 49.1097304, lng: 18.6417019 },
    { lat: 49.110047, lng: 18.6416124 },
    { lat: 49.1104094, lng: 18.6417257 },
    { lat: 49.1108023, lng: 18.6419447 },
    { lat: 49.1111846, lng: 18.6421577 },
    { lat: 49.1112321, lng: 18.6421828 },
    { lat: 49.1112726, lng: 18.6422034 },
    { lat: 49.1116314, lng: 18.642396 },
    { lat: 49.1118871, lng: 18.6425368 },
    { lat: 49.1119103, lng: 18.6425516 },
    { lat: 49.1119929, lng: 18.642599 },
    { lat: 49.1120069, lng: 18.6426092 },
    { lat: 49.1123025, lng: 18.6427815 },
    { lat: 49.1125655, lng: 18.6429311 },
    { lat: 49.1127343, lng: 18.6430295 },
    { lat: 49.1128345, lng: 18.6430927 },
    { lat: 49.1129811, lng: 18.6431732 },
    { lat: 49.1130183, lng: 18.64307 },
    { lat: 49.1130439, lng: 18.6429988 },
    { lat: 49.1130952, lng: 18.6428614 },
    { lat: 49.1131022, lng: 18.6428433 },
    { lat: 49.113113, lng: 18.6428142 },
    { lat: 49.1131178, lng: 18.6427978 },
    { lat: 49.1132256, lng: 18.6424918 },
    { lat: 49.113303, lng: 18.6421262 },
    { lat: 49.1134402, lng: 18.6415253 },
    { lat: 49.1136134, lng: 18.6410489 },
    { lat: 49.1136812, lng: 18.6408626 },
    { lat: 49.1137059, lng: 18.6407937 },
    { lat: 49.1138106, lng: 18.6406026 },
    { lat: 49.1139754, lng: 18.640368 },
    { lat: 49.114059, lng: 18.6402479 },
    { lat: 49.1142556, lng: 18.6401051 },
    { lat: 49.1144583, lng: 18.6396549 },
    { lat: 49.1145355, lng: 18.6395791 },
    { lat: 49.1145557, lng: 18.639554 },
    { lat: 49.114631, lng: 18.6394687 },
    { lat: 49.1148508, lng: 18.6392331 },
    { lat: 49.1150429, lng: 18.6387529 },
    { lat: 49.1155336, lng: 18.6389244 },
    { lat: 49.1157735, lng: 18.6387699 },
    { lat: 49.1158986, lng: 18.6385967 },
    { lat: 49.1160734, lng: 18.638218 },
    { lat: 49.1163712, lng: 18.6381981 },
    { lat: 49.1165459, lng: 18.6380879 },
    { lat: 49.1167383, lng: 18.6375961 },
    { lat: 49.116859, lng: 18.6375722 },
    { lat: 49.1168704, lng: 18.6370451 },
    { lat: 49.1170683, lng: 18.636994 },
    { lat: 49.1172655, lng: 18.6367342 },
    { lat: 49.1174109, lng: 18.6365222 },
    { lat: 49.1175612, lng: 18.6363794 },
    { lat: 49.1176697, lng: 18.6360031 },
    { lat: 49.117805, lng: 18.6356194 },
    { lat: 49.1178676, lng: 18.6353132 },
    { lat: 49.1179866, lng: 18.6350944 },
    { lat: 49.1181572, lng: 18.6349505 },
    { lat: 49.1184519, lng: 18.6344064 },
    { lat: 49.1186848, lng: 18.6338037 },
    { lat: 49.1188095, lng: 18.6333681 },
    { lat: 49.1189781, lng: 18.6330639 },
    { lat: 49.1191662, lng: 18.6328235 },
    { lat: 49.1193109, lng: 18.6328744 },
    { lat: 49.1193755, lng: 18.6327742 },
    { lat: 49.1193998, lng: 18.6326402 },
    { lat: 49.1195668, lng: 18.6327225 },
    { lat: 49.1196801, lng: 18.6326498 },
    { lat: 49.1198109, lng: 18.6325238 },
    { lat: 49.120045, lng: 18.6323656 },
    { lat: 49.1202364, lng: 18.6321262 },
    { lat: 49.1204841, lng: 18.6318874 },
    { lat: 49.1205954, lng: 18.6317937 },
    { lat: 49.1206357, lng: 18.6316854 },
    { lat: 49.1206298, lng: 18.6314896 },
    { lat: 49.1206774, lng: 18.6314073 },
    { lat: 49.1208508, lng: 18.6313398 },
    { lat: 49.1209166, lng: 18.6313464 },
    { lat: 49.1210037, lng: 18.631354 },
    { lat: 49.1215416, lng: 18.6314552 },
    { lat: 49.1218311, lng: 18.6316416 },
    { lat: 49.1228019, lng: 18.6327367 },
    { lat: 49.1230324, lng: 18.632917 },
    { lat: 49.1232074, lng: 18.6325587 },
    { lat: 49.1233639, lng: 18.6320687 },
    { lat: 49.1234584, lng: 18.6315965 },
    { lat: 49.12351, lng: 18.6310838 },
    { lat: 49.1235601, lng: 18.630566 },
    { lat: 49.1236633, lng: 18.6305103 },
    { lat: 49.1238388, lng: 18.6304598 },
    { lat: 49.1239642, lng: 18.630463 },
    { lat: 49.124139, lng: 18.630338 },
    { lat: 49.1243764, lng: 18.6301585 },
    { lat: 49.1245086, lng: 18.6299916 },
    { lat: 49.1245711, lng: 18.6299587 },
    { lat: 49.124821, lng: 18.6300761 },
    { lat: 49.1265461, lng: 18.6271924 },
    { lat: 49.1273766, lng: 18.6258699 },
    { lat: 49.1269278, lng: 18.6252178 },
    { lat: 49.1267902, lng: 18.6249191 },
    { lat: 49.126522, lng: 18.623336 },
  ],
  KamennáPoruba: [
    { lat: 49.056428, lng: 18.7327665 },
    { lat: 49.0564436, lng: 18.7327851 },
    { lat: 49.057008, lng: 18.7332454 },
    { lat: 49.0574444, lng: 18.7335703 },
    { lat: 49.0579273, lng: 18.7340045 },
    { lat: 49.058076, lng: 18.7341314 },
    { lat: 49.0582715, lng: 18.7343942 },
    { lat: 49.0585817, lng: 18.7349156 },
    { lat: 49.0589094, lng: 18.7355263 },
    { lat: 49.0593141, lng: 18.7363006 },
    { lat: 49.0600398, lng: 18.7377737 },
    { lat: 49.0604494, lng: 18.7385942 },
    { lat: 49.0607244, lng: 18.7385708 },
    { lat: 49.0612925, lng: 18.7388474 },
    { lat: 49.0619157, lng: 18.7386862 },
    { lat: 49.0631722, lng: 18.7382635 },
    { lat: 49.0642909, lng: 18.7367137 },
    { lat: 49.0646157, lng: 18.7361589 },
    { lat: 49.0650132, lng: 18.7356942 },
    { lat: 49.0652016, lng: 18.7355472 },
    { lat: 49.0665057, lng: 18.7346505 },
    { lat: 49.0672377, lng: 18.733775 },
    { lat: 49.0682208, lng: 18.7331689 },
    { lat: 49.0685714, lng: 18.7329735 },
    { lat: 49.0689512, lng: 18.7328173 },
    { lat: 49.0692775, lng: 18.732569 },
    { lat: 49.0698256, lng: 18.7318597 },
    { lat: 49.0701626, lng: 18.731213 },
    { lat: 49.0704335, lng: 18.7309571 },
    { lat: 49.070872, lng: 18.73057 },
    { lat: 49.0710627, lng: 18.7304202 },
    { lat: 49.0716371, lng: 18.7300425 },
    { lat: 49.0719839, lng: 18.7299446 },
    { lat: 49.0724776, lng: 18.7298565 },
    { lat: 49.0727946, lng: 18.7295791 },
    { lat: 49.0733717, lng: 18.7288255 },
    { lat: 49.0738294, lng: 18.7284109 },
    { lat: 49.0740826, lng: 18.7284045 },
    { lat: 49.0746412, lng: 18.7282819 },
    { lat: 49.0749347, lng: 18.7283944 },
    { lat: 49.0751958, lng: 18.7287263 },
    { lat: 49.0753041, lng: 18.7283872 },
    { lat: 49.0754262, lng: 18.7276616 },
    { lat: 49.0756178, lng: 18.7271642 },
    { lat: 49.0757477, lng: 18.7268901 },
    { lat: 49.0759163, lng: 18.7266207 },
    { lat: 49.0760079, lng: 18.7264754 },
    { lat: 49.076229, lng: 18.7261553 },
    { lat: 49.0766278, lng: 18.7257216 },
    { lat: 49.0768097, lng: 18.7254908 },
    { lat: 49.0771652, lng: 18.7250436 },
    { lat: 49.0773411, lng: 18.724829 },
    { lat: 49.0776154, lng: 18.7246837 },
    { lat: 49.0778239, lng: 18.7245116 },
    { lat: 49.0781347, lng: 18.7241795 },
    { lat: 49.0785464, lng: 18.7237974 },
    { lat: 49.0787392, lng: 18.7236224 },
    { lat: 49.0790175, lng: 18.7233831 },
    { lat: 49.0793308, lng: 18.7231209 },
    { lat: 49.0797129, lng: 18.7229053 },
    { lat: 49.079904, lng: 18.722764 },
    { lat: 49.0800804, lng: 18.7225778 },
    { lat: 49.0802355, lng: 18.7223772 },
    { lat: 49.0803679, lng: 18.7221938 },
    { lat: 49.0805802, lng: 18.7220079 },
    { lat: 49.0807255, lng: 18.7219175 },
    { lat: 49.0810471, lng: 18.7214039 },
    { lat: 49.0813701, lng: 18.7209001 },
    { lat: 49.0815066, lng: 18.7206421 },
    { lat: 49.0818869, lng: 18.7200468 },
    { lat: 49.0823518, lng: 18.7193246 },
    { lat: 49.0827732, lng: 18.7186509 },
    { lat: 49.0832336, lng: 18.7178217 },
    { lat: 49.0836322, lng: 18.7170625 },
    { lat: 49.0839197, lng: 18.7165652 },
    { lat: 49.0845232, lng: 18.7161211 },
    { lat: 49.0848375, lng: 18.7159198 },
    { lat: 49.0853767, lng: 18.7155802 },
    { lat: 49.0857847, lng: 18.7153197 },
    { lat: 49.0860998, lng: 18.7151362 },
    { lat: 49.0863876, lng: 18.7149468 },
    { lat: 49.0871543, lng: 18.7143039 },
    { lat: 49.0878209, lng: 18.7137455 },
    { lat: 49.088418, lng: 18.7133723 },
    { lat: 49.0887912, lng: 18.7131367 },
    { lat: 49.0889582, lng: 18.7130315 },
    { lat: 49.0892951, lng: 18.7128105 },
    { lat: 49.0899543, lng: 18.7122868 },
    { lat: 49.0900825, lng: 18.7117026 },
    { lat: 49.0906016, lng: 18.7110848 },
    { lat: 49.0910572, lng: 18.7098469 },
    { lat: 49.0912821, lng: 18.7085709 },
    { lat: 49.0915349, lng: 18.7077581 },
    { lat: 49.0916798, lng: 18.7073022 },
    { lat: 49.0920684, lng: 18.7063926 },
    { lat: 49.092395, lng: 18.705914 },
    { lat: 49.0925756, lng: 18.7056815 },
    { lat: 49.093333, lng: 18.7047542 },
    { lat: 49.0936231, lng: 18.7043312 },
    { lat: 49.0939611, lng: 18.7038746 },
    { lat: 49.0944339, lng: 18.7032609 },
    { lat: 49.0948096, lng: 18.7027299 },
    { lat: 49.0958533, lng: 18.7011788 },
    { lat: 49.0961251, lng: 18.7009458 },
    { lat: 49.0965501, lng: 18.7005365 },
    { lat: 49.09763, lng: 18.699439 },
    { lat: 49.0982007, lng: 18.6988837 },
    { lat: 49.0982049, lng: 18.698899 },
    { lat: 49.0982542, lng: 18.6988484 },
    { lat: 49.0988431, lng: 18.6982135 },
    { lat: 49.0990973, lng: 18.697987 },
    { lat: 49.099371, lng: 18.6977426 },
    { lat: 49.1007435, lng: 18.6967207 },
    { lat: 49.1015506, lng: 18.6961588 },
    { lat: 49.1019223, lng: 18.6958721 },
    { lat: 49.1019726, lng: 18.6958221 },
    { lat: 49.1020481, lng: 18.6956278 },
    { lat: 49.1021135, lng: 18.6954118 },
    { lat: 49.102132, lng: 18.6953135 },
    { lat: 49.1021161, lng: 18.6949721 },
    { lat: 49.1021155, lng: 18.6948307 },
    { lat: 49.1022268, lng: 18.6942045 },
    { lat: 49.1024512, lng: 18.6941184 },
    { lat: 49.1025957, lng: 18.6940395 },
    { lat: 49.1028734, lng: 18.6937007 },
    { lat: 49.1029778, lng: 18.6935641 },
    { lat: 49.1030963, lng: 18.6934139 },
    { lat: 49.1031627, lng: 18.6933291 },
    { lat: 49.1032589, lng: 18.6932043 },
    { lat: 49.1032697, lng: 18.693153 },
    { lat: 49.1032899, lng: 18.6931293 },
    { lat: 49.1033827, lng: 18.6930114 },
    { lat: 49.1034104, lng: 18.6929767 },
    { lat: 49.1034662, lng: 18.6929058 },
    { lat: 49.1035102, lng: 18.6928615 },
    { lat: 49.1035466, lng: 18.6928254 },
    { lat: 49.1035809, lng: 18.6927913 },
    { lat: 49.1036148, lng: 18.6927713 },
    { lat: 49.103676, lng: 18.6927131 },
    { lat: 49.1036889, lng: 18.6927018 },
    { lat: 49.1037921, lng: 18.6926053 },
    { lat: 49.1038029, lng: 18.6925953 },
    { lat: 49.1038915, lng: 18.6925017 },
    { lat: 49.1039696, lng: 18.6924184 },
    { lat: 49.1040506, lng: 18.6923328 },
    { lat: 49.1042004, lng: 18.6921792 },
    { lat: 49.104301, lng: 18.6920758 },
    { lat: 49.1043295, lng: 18.6920611 },
    { lat: 49.10437, lng: 18.692058 },
    { lat: 49.1044477, lng: 18.6920571 },
    { lat: 49.1045008, lng: 18.6920607 },
    { lat: 49.104571, lng: 18.6920654 },
    { lat: 49.10461, lng: 18.6920736 },
    { lat: 49.1046566, lng: 18.692083 },
    { lat: 49.1047599, lng: 18.692115 },
    { lat: 49.1048376, lng: 18.6921378 },
    { lat: 49.1049143, lng: 18.692158 },
    { lat: 49.1050161, lng: 18.6921844 },
    { lat: 49.1051086, lng: 18.6922087 },
    { lat: 49.1051319, lng: 18.6921795 },
    { lat: 49.1052905, lng: 18.6919787 },
    { lat: 49.1054557, lng: 18.6918797 },
    { lat: 49.1055137, lng: 18.691847 },
    { lat: 49.1055596, lng: 18.6918209 },
    { lat: 49.1055826, lng: 18.691808 },
    { lat: 49.1056109, lng: 18.6918527 },
    { lat: 49.1056871, lng: 18.6919715 },
    { lat: 49.1057064, lng: 18.6919335 },
    { lat: 49.1057404, lng: 18.6918711 },
    { lat: 49.1057835, lng: 18.6917924 },
    { lat: 49.105803, lng: 18.6917943 },
    { lat: 49.1059151, lng: 18.6918054 },
    { lat: 49.1059444, lng: 18.6918054 },
    { lat: 49.1060598, lng: 18.6917254 },
    { lat: 49.1061039, lng: 18.6915796 },
    { lat: 49.1061121, lng: 18.6914757 },
    { lat: 49.1060585, lng: 18.6912933 },
    { lat: 49.1060255, lng: 18.6911832 },
    { lat: 49.1056742, lng: 18.6899989 },
    { lat: 49.1054525, lng: 18.6891313 },
    { lat: 49.1052446, lng: 18.6883131 },
    { lat: 49.1051357, lng: 18.6879155 },
    { lat: 49.1051171, lng: 18.687863 },
    { lat: 49.1051012, lng: 18.6878175 },
    { lat: 49.1048903, lng: 18.6872199 },
    { lat: 49.1047385, lng: 18.6868034 },
    { lat: 49.1047206, lng: 18.6867415 },
    { lat: 49.1046322, lng: 18.6864551 },
    { lat: 49.1045895, lng: 18.6863098 },
    { lat: 49.104558, lng: 18.6862223 },
    { lat: 49.104451, lng: 18.6858834 },
    { lat: 49.1043182, lng: 18.6854415 },
    { lat: 49.1043019, lng: 18.6853931 },
    { lat: 49.1042628, lng: 18.6852694 },
    { lat: 49.1042465, lng: 18.6852164 },
    { lat: 49.1041848, lng: 18.6850328 },
    { lat: 49.1041184, lng: 18.6848232 },
    { lat: 49.1040261, lng: 18.684485 },
    { lat: 49.1038753, lng: 18.6839342 },
    { lat: 49.1038426, lng: 18.6838079 },
    { lat: 49.1038216, lng: 18.6837284 },
    { lat: 49.1037089, lng: 18.6833042 },
    { lat: 49.1036795, lng: 18.6831948 },
    { lat: 49.1036668, lng: 18.683147 },
    { lat: 49.1036499, lng: 18.6830818 },
    { lat: 49.1036372, lng: 18.6830344 },
    { lat: 49.1035779, lng: 18.6821052 },
    { lat: 49.1035213, lng: 18.6813779 },
    { lat: 49.1035173, lng: 18.6804268 },
    { lat: 49.1035138, lng: 18.680339 },
    { lat: 49.1035311, lng: 18.6799871 },
    { lat: 49.1035273, lng: 18.6799321 },
    { lat: 49.1034931, lng: 18.6798003 },
    { lat: 49.1034928, lng: 18.6794544 },
    { lat: 49.1034338, lng: 18.6789117 },
    { lat: 49.1034106, lng: 18.6781112 },
    { lat: 49.1034381, lng: 18.6775003 },
    { lat: 49.103683, lng: 18.6756951 },
    { lat: 49.103684, lng: 18.6751591 },
    { lat: 49.1036146, lng: 18.6747015 },
    { lat: 49.1034509, lng: 18.6743138 },
    { lat: 49.1032677, lng: 18.6740491 },
    { lat: 49.102933, lng: 18.6736284 },
    { lat: 49.102334, lng: 18.6728346 },
    { lat: 49.1016373, lng: 18.6720536 },
    { lat: 49.1015116, lng: 18.6718945 },
    { lat: 49.1014226, lng: 18.6717668 },
    { lat: 49.100437, lng: 18.6708763 },
    { lat: 49.099902, lng: 18.6703982 },
    { lat: 49.0996242, lng: 18.6701549 },
    { lat: 49.0995047, lng: 18.670031 },
    { lat: 49.0993573, lng: 18.6698235 },
    { lat: 49.0992063, lng: 18.6696142 },
    { lat: 49.0987642, lng: 18.668803 },
    { lat: 49.0985513, lng: 18.6683397 },
    { lat: 49.0977034, lng: 18.6677242 },
    { lat: 49.0974356, lng: 18.6675041 },
    { lat: 49.0973016, lng: 18.6673572 },
    { lat: 49.0971478, lng: 18.6671792 },
    { lat: 49.096998, lng: 18.6669479 },
    { lat: 49.0968983, lng: 18.6667705 },
    { lat: 49.0966929, lng: 18.6662984 },
    { lat: 49.0964724, lng: 18.6658199 },
    { lat: 49.0963224, lng: 18.6655004 },
    { lat: 49.0955951, lng: 18.664312 },
    { lat: 49.0955474, lng: 18.6642631 },
    { lat: 49.0951468, lng: 18.6631939 },
    { lat: 49.0948991, lng: 18.6624799 },
    { lat: 49.0946058, lng: 18.6616699 },
    { lat: 49.0947066, lng: 18.6607233 },
    { lat: 49.0947826, lng: 18.6604142 },
    { lat: 49.0948801, lng: 18.6601053 },
    { lat: 49.0939439, lng: 18.6591848 },
    { lat: 49.0939331, lng: 18.6591505 },
    { lat: 49.0939165, lng: 18.6592022 },
    { lat: 49.0938538, lng: 18.659233 },
    { lat: 49.0938334, lng: 18.6592722 },
    { lat: 49.0937529, lng: 18.6592195 },
    { lat: 49.0937398, lng: 18.6591866 },
    { lat: 49.093718, lng: 18.6591979 },
    { lat: 49.0936958, lng: 18.659247 },
    { lat: 49.0937086, lng: 18.6592793 },
    { lat: 49.093683, lng: 18.659304 },
    { lat: 49.0936663, lng: 18.6593514 },
    { lat: 49.0935982, lng: 18.65943 },
    { lat: 49.0935164, lng: 18.6594825 },
    { lat: 49.093625, lng: 18.6596599 },
    { lat: 49.093562, lng: 18.659745 },
    { lat: 49.0935326, lng: 18.6597875 },
    { lat: 49.093424, lng: 18.659821 },
    { lat: 49.0934137, lng: 18.6599252 },
    { lat: 49.0934238, lng: 18.6599473 },
    { lat: 49.09342, lng: 18.660045 },
    { lat: 49.0933978, lng: 18.6600531 },
    { lat: 49.0933829, lng: 18.6600672 },
    { lat: 49.0933058, lng: 18.6598071 },
    { lat: 49.0931633, lng: 18.6599276 },
    { lat: 49.0931197, lng: 18.6600792 },
    { lat: 49.0931052, lng: 18.6601294 },
    { lat: 49.0930199, lng: 18.660431 },
    { lat: 49.0929522, lng: 18.660688 },
    { lat: 49.0928781, lng: 18.6609706 },
    { lat: 49.0927958, lng: 18.6611204 },
    { lat: 49.0927153, lng: 18.6613847 },
    { lat: 49.0926178, lng: 18.6615241 },
    { lat: 49.0926882, lng: 18.6615769 },
    { lat: 49.0927298, lng: 18.6616182 },
    { lat: 49.0925541, lng: 18.6617719 },
    { lat: 49.0925496, lng: 18.661834 },
    { lat: 49.092563, lng: 18.6619176 },
    { lat: 49.0925406, lng: 18.6619632 },
    { lat: 49.0925261, lng: 18.6620269 },
    { lat: 49.0925198, lng: 18.6620601 },
    { lat: 49.0925199, lng: 18.6621468 },
    { lat: 49.0925107, lng: 18.6621824 },
    { lat: 49.0924653, lng: 18.6622625 },
    { lat: 49.0922605, lng: 18.6624198 },
    { lat: 49.0921873, lng: 18.6624662 },
    { lat: 49.0921208, lng: 18.6625931 },
    { lat: 49.092108, lng: 18.662734 },
    { lat: 49.0920826, lng: 18.66279 },
    { lat: 49.0920061, lng: 18.6628548 },
    { lat: 49.0919568, lng: 18.66303 },
    { lat: 49.091958, lng: 18.6630738 },
    { lat: 49.0919364, lng: 18.6631128 },
    { lat: 49.0919487, lng: 18.6631127 },
    { lat: 49.0919532, lng: 18.6631532 },
    { lat: 49.0919261, lng: 18.663177 },
    { lat: 49.0919208, lng: 18.6632251 },
    { lat: 49.0919103, lng: 18.6632394 },
    { lat: 49.09192, lng: 18.6633785 },
    { lat: 49.0919424, lng: 18.6635467 },
    { lat: 49.0918891, lng: 18.6636065 },
    { lat: 49.0918192, lng: 18.6636486 },
    { lat: 49.0918151, lng: 18.6637175 },
    { lat: 49.0917885, lng: 18.6637736 },
    { lat: 49.0917606, lng: 18.6637771 },
    { lat: 49.0917607, lng: 18.6639714 },
    { lat: 49.0917827, lng: 18.6640647 },
    { lat: 49.0918443, lng: 18.6641828 },
    { lat: 49.091823, lng: 18.6642491 },
    { lat: 49.0917748, lng: 18.6643212 },
    { lat: 49.0916599, lng: 18.6643242 },
    { lat: 49.0915525, lng: 18.6643406 },
    { lat: 49.0914705, lng: 18.6643673 },
    { lat: 49.0914407, lng: 18.6644425 },
    { lat: 49.0914879, lng: 18.6646512 },
    { lat: 49.0914817, lng: 18.6647926 },
    { lat: 49.0913857, lng: 18.6648088 },
    { lat: 49.0913553, lng: 18.6648323 },
    { lat: 49.0913211, lng: 18.6648254 },
    { lat: 49.0912748, lng: 18.664844 },
    { lat: 49.0911472, lng: 18.6647985 },
    { lat: 49.0911036, lng: 18.6648057 },
    { lat: 49.091086, lng: 18.6648575 },
    { lat: 49.0910756, lng: 18.6649142 },
    { lat: 49.091166, lng: 18.6650693 },
    { lat: 49.091019, lng: 18.6654213 },
    { lat: 49.0908595, lng: 18.6657108 },
    { lat: 49.090846, lng: 18.6657952 },
    { lat: 49.0908668, lng: 18.6659557 },
    { lat: 49.0908693, lng: 18.6661709 },
    { lat: 49.0908363, lng: 18.6662718 },
    { lat: 49.09072, lng: 18.6665023 },
    { lat: 49.0906832, lng: 18.6665749 },
    { lat: 49.090739, lng: 18.6666504 },
    { lat: 49.0906922, lng: 18.666704 },
    { lat: 49.0906676, lng: 18.6667081 },
    { lat: 49.0906403, lng: 18.666746 },
    { lat: 49.0906069, lng: 18.6667753 },
    { lat: 49.0906055, lng: 18.6668975 },
    { lat: 49.0905771, lng: 18.6669359 },
    { lat: 49.0905802, lng: 18.6669938 },
    { lat: 49.0905361, lng: 18.6670546 },
    { lat: 49.0904821, lng: 18.6670156 },
    { lat: 49.0903958, lng: 18.6671086 },
    { lat: 49.0903203, lng: 18.6670264 },
    { lat: 49.0902699, lng: 18.6670974 },
    { lat: 49.0902513, lng: 18.6671299 },
    { lat: 49.0902446, lng: 18.6671315 },
    { lat: 49.0901346, lng: 18.667245 },
    { lat: 49.0901439, lng: 18.6673144 },
    { lat: 49.090129, lng: 18.6673544 },
    { lat: 49.0899744, lng: 18.667563 },
    { lat: 49.0899019, lng: 18.6675993 },
    { lat: 49.0898722, lng: 18.6676134 },
    { lat: 49.0898178, lng: 18.6675862 },
    { lat: 49.089798, lng: 18.6676189 },
    { lat: 49.0896873, lng: 18.6677415 },
    { lat: 49.0895072, lng: 18.6679421 },
    { lat: 49.0893403, lng: 18.6680911 },
    { lat: 49.0889752, lng: 18.6684123 },
    { lat: 49.0889006, lng: 18.6684772 },
    { lat: 49.0887353, lng: 18.6684439 },
    { lat: 49.0885565, lng: 18.6683479 },
    { lat: 49.08838, lng: 18.6683113 },
    { lat: 49.0882956, lng: 18.6682932 },
    { lat: 49.0882424, lng: 18.6683109 },
    { lat: 49.0880356, lng: 18.6683468 },
    { lat: 49.0879647, lng: 18.6683724 },
    { lat: 49.0877158, lng: 18.6684429 },
    { lat: 49.0875191, lng: 18.6684952 },
    { lat: 49.0874043, lng: 18.6685449 },
    { lat: 49.0871487, lng: 18.6685907 },
    { lat: 49.0869552, lng: 18.6686235 },
    { lat: 49.0867381, lng: 18.6686336 },
    { lat: 49.0866544, lng: 18.6686288 },
    { lat: 49.0866186, lng: 18.6686343 },
    { lat: 49.0858152, lng: 18.6686898 },
    { lat: 49.0853024, lng: 18.6688636 },
    { lat: 49.0851959, lng: 18.6689005 },
    { lat: 49.084693, lng: 18.6694526 },
    { lat: 49.0845299, lng: 18.6696303 },
    { lat: 49.0837567, lng: 18.67017 },
    { lat: 49.0829492, lng: 18.670063 },
    { lat: 49.0827835, lng: 18.6700675 },
    { lat: 49.0827115, lng: 18.6700918 },
    { lat: 49.0825562, lng: 18.6702019 },
    { lat: 49.0824224, lng: 18.6703609 },
    { lat: 49.0823412, lng: 18.6705277 },
    { lat: 49.0821157, lng: 18.6707227 },
    { lat: 49.0820267, lng: 18.6707466 },
    { lat: 49.0818471, lng: 18.6708676 },
    { lat: 49.0817885, lng: 18.6708064 },
    { lat: 49.0816427, lng: 18.670779 },
    { lat: 49.0814718, lng: 18.6707647 },
    { lat: 49.081051, lng: 18.6708689 },
    { lat: 49.0807532, lng: 18.6709077 },
    { lat: 49.0803025, lng: 18.6708986 },
    { lat: 49.0801714, lng: 18.6708507 },
    { lat: 49.0795789, lng: 18.6709256 },
    { lat: 49.0794007, lng: 18.6709691 },
    { lat: 49.0792577, lng: 18.6710582 },
    { lat: 49.0792175, lng: 18.6710844 },
    { lat: 49.078818, lng: 18.671336 },
    { lat: 49.0785833, lng: 18.6715055 },
    { lat: 49.07849, lng: 18.671572 },
    { lat: 49.0782975, lng: 18.6717701 },
    { lat: 49.0781439, lng: 18.6719354 },
    { lat: 49.0779791, lng: 18.6721261 },
    { lat: 49.0779495, lng: 18.6721654 },
    { lat: 49.0776716, lng: 18.6731956 },
    { lat: 49.0772198, lng: 18.6740123 },
    { lat: 49.076793, lng: 18.6742791 },
    { lat: 49.0755727, lng: 18.6748339 },
    { lat: 49.0754471, lng: 18.6751033 },
    { lat: 49.0753489, lng: 18.6754175 },
    { lat: 49.0748477, lng: 18.6771546 },
    { lat: 49.0746662, lng: 18.6778182 },
    { lat: 49.0745354, lng: 18.6782005 },
    { lat: 49.0743226, lng: 18.6787208 },
    { lat: 49.0741236, lng: 18.6791754 },
    { lat: 49.0738425, lng: 18.6799192 },
    { lat: 49.0736009, lng: 18.6818096 },
    { lat: 49.0735955, lng: 18.6820081 },
    { lat: 49.0735837, lng: 18.6822307 },
    { lat: 49.0735409, lng: 18.6829996 },
    { lat: 49.0735025, lng: 18.6835273 },
    { lat: 49.0734815, lng: 18.6837515 },
    { lat: 49.07344, lng: 18.6839914 },
    { lat: 49.073359, lng: 18.6843355 },
    { lat: 49.073295, lng: 18.684489 },
    { lat: 49.0731565, lng: 18.6847074 },
    { lat: 49.0730403, lng: 18.6847479 },
    { lat: 49.0727645, lng: 18.6848425 },
    { lat: 49.0719955, lng: 18.6851439 },
    { lat: 49.0716891, lng: 18.6852768 },
    { lat: 49.0715117, lng: 18.6853762 },
    { lat: 49.0712291, lng: 18.6856615 },
    { lat: 49.0711184, lng: 18.6858308 },
    { lat: 49.0709829, lng: 18.686106 },
    { lat: 49.0709109, lng: 18.6862987 },
    { lat: 49.0704035, lng: 18.6876431 },
    { lat: 49.0701957, lng: 18.6881925 },
    { lat: 49.0701023, lng: 18.6883698 },
    { lat: 49.0699027, lng: 18.688518 },
    { lat: 49.069453, lng: 18.6887788 },
    { lat: 49.0692482, lng: 18.6889355 },
    { lat: 49.06885, lng: 18.6895755 },
    { lat: 49.0688015, lng: 18.6896591 },
    { lat: 49.0682895, lng: 18.6899369 },
    { lat: 49.0680534, lng: 18.6901431 },
    { lat: 49.0678288, lng: 18.690458 },
    { lat: 49.0676285, lng: 18.6909342 },
    { lat: 49.0673479, lng: 18.6914731 },
    { lat: 49.0669738, lng: 18.6919922 },
    { lat: 49.0668296, lng: 18.6921652 },
    { lat: 49.0666856, lng: 18.6922803 },
    { lat: 49.0666246, lng: 18.6923201 },
    { lat: 49.066464, lng: 18.6923977 },
    { lat: 49.0663068, lng: 18.6924723 },
    { lat: 49.0662027, lng: 18.6925502 },
    { lat: 49.066064, lng: 18.6927024 },
    { lat: 49.065876, lng: 18.6929656 },
    { lat: 49.0658488, lng: 18.6930049 },
    { lat: 49.0657571, lng: 18.6931467 },
    { lat: 49.0656968, lng: 18.693286 },
    { lat: 49.0654835, lng: 18.6939454 },
    { lat: 49.0653455, lng: 18.6942587 },
    { lat: 49.0652105, lng: 18.694605 },
    { lat: 49.065092, lng: 18.6949661 },
    { lat: 49.0650174, lng: 18.6953709 },
    { lat: 49.0649386, lng: 18.6963983 },
    { lat: 49.0649028, lng: 18.6967839 },
    { lat: 49.0650176, lng: 18.6977013 },
    { lat: 49.065035, lng: 18.6979455 },
    { lat: 49.0650479, lng: 18.6980831 },
    { lat: 49.0649912, lng: 18.6986111 },
    { lat: 49.0647809, lng: 18.699432 },
    { lat: 49.0644951, lng: 18.7004505 },
    { lat: 49.0644685, lng: 18.7005461 },
    { lat: 49.0655938, lng: 18.7023787 },
    { lat: 49.0655569, lng: 18.7034747 },
    { lat: 49.0662001, lng: 18.7061765 },
    { lat: 49.0657501, lng: 18.7073093 },
    { lat: 49.0664521, lng: 18.7080475 },
    { lat: 49.0661316, lng: 18.7118142 },
    { lat: 49.0665763, lng: 18.7127209 },
    { lat: 49.0675642, lng: 18.7157175 },
    { lat: 49.0649022, lng: 18.7177649 },
    { lat: 49.06471, lng: 18.7177128 },
    { lat: 49.0622688, lng: 18.7199481 },
    { lat: 49.0615241, lng: 18.7206466 },
    { lat: 49.0613008, lng: 18.7208172 },
    { lat: 49.0608419, lng: 18.7209662 },
    { lat: 49.0604882, lng: 18.7226476 },
    { lat: 49.0601301, lng: 18.7237067 },
    { lat: 49.0598648, lng: 18.7242823 },
    { lat: 49.059546, lng: 18.7251046 },
    { lat: 49.0590715, lng: 18.725623 },
    { lat: 49.0590011, lng: 18.7256995 },
    { lat: 49.0586973, lng: 18.7262906 },
    { lat: 49.0582941, lng: 18.7270757 },
    { lat: 49.05831, lng: 18.727379 },
    { lat: 49.0578635, lng: 18.7279554 },
    { lat: 49.0575498, lng: 18.7282323 },
    { lat: 49.0573358, lng: 18.7292231 },
    { lat: 49.057133, lng: 18.7298942 },
    { lat: 49.0568159, lng: 18.7311139 },
    { lat: 49.0566677, lng: 18.7319226 },
    { lat: 49.0564003, lng: 18.7324337 },
    { lat: 49.056428, lng: 18.7327665 },
  ],
  Konská: [
    { lat: 49.1189849, lng: 18.6900253 },
    { lat: 49.1188228, lng: 18.6874659 },
    { lat: 49.1184676, lng: 18.6852691 },
    { lat: 49.1184026, lng: 18.6853627 },
    { lat: 49.117851, lng: 18.6835032 },
    { lat: 49.11796, lng: 18.6834322 },
    { lat: 49.1182243, lng: 18.6833141 },
    { lat: 49.1199589, lng: 18.6826531 },
    { lat: 49.1209283, lng: 18.6820675 },
    { lat: 49.1212817, lng: 18.6818654 },
    { lat: 49.1214079, lng: 18.6817753 },
    { lat: 49.1215012, lng: 18.681729 },
    { lat: 49.1217348, lng: 18.6814947 },
    { lat: 49.1219362, lng: 18.6813407 },
    { lat: 49.1220626, lng: 18.681233 },
    { lat: 49.1229, lng: 18.6805027 },
    { lat: 49.1234037, lng: 18.6800668 },
    { lat: 49.1234441, lng: 18.6800388 },
    { lat: 49.1235996, lng: 18.6799315 },
    { lat: 49.1237525, lng: 18.6798165 },
    { lat: 49.1239183, lng: 18.6797112 },
    { lat: 49.1241841, lng: 18.6795133 },
    { lat: 49.1245297, lng: 18.6792649 },
    { lat: 49.1238167, lng: 18.6765936 },
    { lat: 49.1241618, lng: 18.6765955 },
    { lat: 49.1240287, lng: 18.6761467 },
    { lat: 49.124239, lng: 18.6761551 },
    { lat: 49.1245229, lng: 18.6761922 },
    { lat: 49.1246057, lng: 18.6762256 },
    { lat: 49.12499, lng: 18.6760711 },
    { lat: 49.1251257, lng: 18.6760767 },
    { lat: 49.1251462, lng: 18.676062 },
    { lat: 49.1251865, lng: 18.6760556 },
    { lat: 49.1252502, lng: 18.6760215 },
    { lat: 49.1253156, lng: 18.6759184 },
    { lat: 49.125332, lng: 18.6758836 },
    { lat: 49.1254453, lng: 18.6756799 },
    { lat: 49.1255425, lng: 18.675687 },
    { lat: 49.1256846, lng: 18.6758125 },
    { lat: 49.1257754, lng: 18.6758234 },
    { lat: 49.1258015, lng: 18.6757625 },
    { lat: 49.1258592, lng: 18.6757223 },
    { lat: 49.1259075, lng: 18.6757196 },
    { lat: 49.1258628, lng: 18.6756038 },
    { lat: 49.1252489, lng: 18.6742907 },
    { lat: 49.1250873, lng: 18.6739041 },
    { lat: 49.1250215, lng: 18.6737685 },
    { lat: 49.1249651, lng: 18.673617 },
    { lat: 49.1248739, lng: 18.6733455 },
    { lat: 49.1247508, lng: 18.6729358 },
    { lat: 49.124741, lng: 18.6728794 },
    { lat: 49.1247713, lng: 18.672854 },
    { lat: 49.1248103, lng: 18.6728042 },
    { lat: 49.1249742, lng: 18.6724499 },
    { lat: 49.125092, lng: 18.6722864 },
    { lat: 49.1249775, lng: 18.6720837 },
    { lat: 49.1248098, lng: 18.6716665 },
    { lat: 49.1246435, lng: 18.671168 },
    { lat: 49.1245629, lng: 18.6709443 },
    { lat: 49.1245103, lng: 18.6707142 },
    { lat: 49.1242576, lng: 18.6700348 },
    { lat: 49.12382, lng: 18.6688403 },
    { lat: 49.1235265, lng: 18.668068 },
    { lat: 49.1238291, lng: 18.6679044 },
    { lat: 49.123419, lng: 18.6668455 },
    { lat: 49.1232069, lng: 18.666635 },
    { lat: 49.1231226, lng: 18.66664 },
    { lat: 49.1230308, lng: 18.6665674 },
    { lat: 49.1229356, lng: 18.6666655 },
    { lat: 49.1229057, lng: 18.6665868 },
    { lat: 49.1227117, lng: 18.6667161 },
    { lat: 49.1226028, lng: 18.6667889 },
    { lat: 49.1226335, lng: 18.6669227 },
    { lat: 49.122281, lng: 18.6671868 },
    { lat: 49.1220407, lng: 18.6673744 },
    { lat: 49.121941, lng: 18.6674518 },
    { lat: 49.1218872, lng: 18.6672885 },
    { lat: 49.121859, lng: 18.6672027 },
    { lat: 49.1218387, lng: 18.6671627 },
    { lat: 49.1218075, lng: 18.6671485 },
    { lat: 49.1217764, lng: 18.6671545 },
    { lat: 49.1217167, lng: 18.6672018 },
    { lat: 49.1215424, lng: 18.667336 },
    { lat: 49.1213803, lng: 18.6670023 },
    { lat: 49.1210348, lng: 18.6672926 },
    { lat: 49.1210251, lng: 18.6673006 },
    { lat: 49.1209403, lng: 18.667145 },
    { lat: 49.1206075, lng: 18.6665289 },
    { lat: 49.1205053, lng: 18.6663672 },
    { lat: 49.1196867, lng: 18.665283 },
    { lat: 49.1191869, lng: 18.6656851 },
    { lat: 49.1190197, lng: 18.6654641 },
    { lat: 49.1188127, lng: 18.6652408 },
    { lat: 49.1186209, lng: 18.6650909 },
    { lat: 49.1184178, lng: 18.6649823 },
    { lat: 49.1182939, lng: 18.664964 },
    { lat: 49.1180524, lng: 18.6650028 },
    { lat: 49.1178778, lng: 18.6647319 },
    { lat: 49.1177425, lng: 18.664476 },
    { lat: 49.1175843, lng: 18.6643 },
    { lat: 49.117439, lng: 18.6640061 },
    { lat: 49.1174509, lng: 18.663866 },
    { lat: 49.1173764, lng: 18.6637402 },
    { lat: 49.1172871, lng: 18.663776 },
    { lat: 49.1171211, lng: 18.6635819 },
    { lat: 49.1169525, lng: 18.6634651 },
    { lat: 49.1167015, lng: 18.6630892 },
    { lat: 49.1166455, lng: 18.6629506 },
    { lat: 49.1165591, lng: 18.662873 },
    { lat: 49.1162836, lng: 18.6628659 },
    { lat: 49.1160574, lng: 18.6629186 },
    { lat: 49.1159291, lng: 18.6627087 },
    { lat: 49.1155788, lng: 18.6626751 },
    { lat: 49.1155439, lng: 18.6626546 },
    { lat: 49.11531, lng: 18.6625401 },
    { lat: 49.1151004, lng: 18.662118 },
    { lat: 49.1147703, lng: 18.6614001 },
    { lat: 49.1147318, lng: 18.6611244 },
    { lat: 49.1147501, lng: 18.6610645 },
    { lat: 49.1147242, lng: 18.6606825 },
    { lat: 49.1146409, lng: 18.6602172 },
    { lat: 49.1146175, lng: 18.6600527 },
    { lat: 49.1145553, lng: 18.6597306 },
    { lat: 49.1145875, lng: 18.6595949 },
    { lat: 49.1144272, lng: 18.6590177 },
    { lat: 49.114246, lng: 18.6584712 },
    { lat: 49.1142119, lng: 18.6584041 },
    { lat: 49.1138921, lng: 18.6578139 },
    { lat: 49.1137262, lng: 18.6575787 },
    { lat: 49.1132768, lng: 18.6572104 },
    { lat: 49.1131739, lng: 18.6572043 },
    { lat: 49.1130262, lng: 18.6571588 },
    { lat: 49.1124288, lng: 18.6571694 },
    { lat: 49.1123163, lng: 18.6566829 },
    { lat: 49.1122676, lng: 18.6564034 },
    { lat: 49.112231, lng: 18.6560562 },
    { lat: 49.1121864, lng: 18.6558142 },
    { lat: 49.112163, lng: 18.6556876 },
    { lat: 49.1121601, lng: 18.6556722 },
    { lat: 49.1119587, lng: 18.6556192 },
    { lat: 49.1117842, lng: 18.6556486 },
    { lat: 49.1114857, lng: 18.6555681 },
    { lat: 49.1112672, lng: 18.6555727 },
    { lat: 49.111115, lng: 18.6556573 },
    { lat: 49.1109639, lng: 18.6557008 },
    { lat: 49.110511, lng: 18.6560507 },
    { lat: 49.1104258, lng: 18.6559762 },
    { lat: 49.1103692, lng: 18.6559306 },
    { lat: 49.1099619, lng: 18.6563052 },
    { lat: 49.1098949, lng: 18.6562364 },
    { lat: 49.1096468, lng: 18.6565531 },
    { lat: 49.1094504, lng: 18.6567393 },
    { lat: 49.1091185, lng: 18.6569699 },
    { lat: 49.1088923, lng: 18.6570392 },
    { lat: 49.1086589, lng: 18.6571939 },
    { lat: 49.1086036, lng: 18.6572338 },
    { lat: 49.1082381, lng: 18.6575514 },
    { lat: 49.1079269, lng: 18.6572128 },
    { lat: 49.1076779, lng: 18.6568533 },
    { lat: 49.10696, lng: 18.6582034 },
    { lat: 49.1065108, lng: 18.658195 },
    { lat: 49.1061161, lng: 18.6589833 },
    { lat: 49.1049382, lng: 18.6616713 },
    { lat: 49.1040459, lng: 18.6613244 },
    { lat: 49.1037076, lng: 18.6611979 },
    { lat: 49.1033746, lng: 18.6610852 },
    { lat: 49.1026256, lng: 18.660639 },
    { lat: 49.1005504, lng: 18.65938 },
    { lat: 49.1004155, lng: 18.6592832 },
    { lat: 49.0991474, lng: 18.6585086 },
    { lat: 49.0989617, lng: 18.659026 },
    { lat: 49.0988529, lng: 18.6591438 },
    { lat: 49.0987857, lng: 18.6592755 },
    { lat: 49.0987468, lng: 18.6593933 },
    { lat: 49.0987001, lng: 18.6595552 },
    { lat: 49.0987116, lng: 18.6600267 },
    { lat: 49.0987037, lng: 18.6603055 },
    { lat: 49.0986814, lng: 18.6606681 },
    { lat: 49.0987529, lng: 18.660871 },
    { lat: 49.0987636, lng: 18.6610287 },
    { lat: 49.0988153, lng: 18.6614733 },
    { lat: 49.0987633, lng: 18.6615977 },
    { lat: 49.0985663, lng: 18.6617321 },
    { lat: 49.0985184, lng: 18.6618283 },
    { lat: 49.0984779, lng: 18.6619377 },
    { lat: 49.0984239, lng: 18.6620462 },
    { lat: 49.0983687, lng: 18.6621516 },
    { lat: 49.0982213, lng: 18.6623072 },
    { lat: 49.0981258, lng: 18.6621653 },
    { lat: 49.0980689, lng: 18.6620899 },
    { lat: 49.0979161, lng: 18.6618697 },
    { lat: 49.0978252, lng: 18.6617053 },
    { lat: 49.0962834, lng: 18.6594518 },
    { lat: 49.0957016, lng: 18.6594741 },
    { lat: 49.0954884, lng: 18.6590908 },
    { lat: 49.0948801, lng: 18.6601053 },
    { lat: 49.0947826, lng: 18.6604142 },
    { lat: 49.0947066, lng: 18.6607233 },
    { lat: 49.0946058, lng: 18.6616699 },
    { lat: 49.0948991, lng: 18.6624799 },
    { lat: 49.0951468, lng: 18.6631939 },
    { lat: 49.0955474, lng: 18.6642631 },
    { lat: 49.0955951, lng: 18.664312 },
    { lat: 49.0963224, lng: 18.6655004 },
    { lat: 49.0964724, lng: 18.6658199 },
    { lat: 49.0966929, lng: 18.6662984 },
    { lat: 49.0968983, lng: 18.6667705 },
    { lat: 49.096998, lng: 18.6669479 },
    { lat: 49.0971478, lng: 18.6671792 },
    { lat: 49.0973016, lng: 18.6673572 },
    { lat: 49.0974356, lng: 18.6675041 },
    { lat: 49.0977034, lng: 18.6677242 },
    { lat: 49.0985513, lng: 18.6683397 },
    { lat: 49.0987642, lng: 18.668803 },
    { lat: 49.0992063, lng: 18.6696142 },
    { lat: 49.0993573, lng: 18.6698235 },
    { lat: 49.0995047, lng: 18.670031 },
    { lat: 49.0996242, lng: 18.6701549 },
    { lat: 49.099902, lng: 18.6703982 },
    { lat: 49.100437, lng: 18.6708763 },
    { lat: 49.1014226, lng: 18.6717668 },
    { lat: 49.1015116, lng: 18.6718945 },
    { lat: 49.1016373, lng: 18.6720536 },
    { lat: 49.102334, lng: 18.6728346 },
    { lat: 49.102933, lng: 18.6736284 },
    { lat: 49.1032677, lng: 18.6740491 },
    { lat: 49.1034509, lng: 18.6743138 },
    { lat: 49.1036146, lng: 18.6747015 },
    { lat: 49.103684, lng: 18.6751591 },
    { lat: 49.103683, lng: 18.6756951 },
    { lat: 49.1034381, lng: 18.6775003 },
    { lat: 49.1034106, lng: 18.6781112 },
    { lat: 49.1034338, lng: 18.6789117 },
    { lat: 49.1034928, lng: 18.6794544 },
    { lat: 49.1034931, lng: 18.6798003 },
    { lat: 49.1035273, lng: 18.6799321 },
    { lat: 49.1035311, lng: 18.6799871 },
    { lat: 49.1035138, lng: 18.680339 },
    { lat: 49.1035173, lng: 18.6804268 },
    { lat: 49.1035213, lng: 18.6813779 },
    { lat: 49.1035779, lng: 18.6821052 },
    { lat: 49.1036372, lng: 18.6830344 },
    { lat: 49.1036499, lng: 18.6830818 },
    { lat: 49.1036668, lng: 18.683147 },
    { lat: 49.1036795, lng: 18.6831948 },
    { lat: 49.1037089, lng: 18.6833042 },
    { lat: 49.1038216, lng: 18.6837284 },
    { lat: 49.1038426, lng: 18.6838079 },
    { lat: 49.1038753, lng: 18.6839342 },
    { lat: 49.1040261, lng: 18.684485 },
    { lat: 49.1041184, lng: 18.6848232 },
    { lat: 49.1041848, lng: 18.6850328 },
    { lat: 49.1042465, lng: 18.6852164 },
    { lat: 49.1042628, lng: 18.6852694 },
    { lat: 49.1043019, lng: 18.6853931 },
    { lat: 49.1043182, lng: 18.6854415 },
    { lat: 49.104451, lng: 18.6858834 },
    { lat: 49.104558, lng: 18.6862223 },
    { lat: 49.1045895, lng: 18.6863098 },
    { lat: 49.1046322, lng: 18.6864551 },
    { lat: 49.1047206, lng: 18.6867415 },
    { lat: 49.1047385, lng: 18.6868034 },
    { lat: 49.1048903, lng: 18.6872199 },
    { lat: 49.1051012, lng: 18.6878175 },
    { lat: 49.1051171, lng: 18.687863 },
    { lat: 49.1051357, lng: 18.6879155 },
    { lat: 49.1052446, lng: 18.6883131 },
    { lat: 49.1054525, lng: 18.6891313 },
    { lat: 49.1056742, lng: 18.6899989 },
    { lat: 49.1060255, lng: 18.6911832 },
    { lat: 49.1060585, lng: 18.6912933 },
    { lat: 49.1061121, lng: 18.6914757 },
    { lat: 49.1061039, lng: 18.6915796 },
    { lat: 49.1060598, lng: 18.6917254 },
    { lat: 49.1060867, lng: 18.6919579 },
    { lat: 49.1061784, lng: 18.6924775 },
    { lat: 49.1061932, lng: 18.6926499 },
    { lat: 49.1062304, lng: 18.6928624 },
    { lat: 49.1059785, lng: 18.6932417 },
    { lat: 49.1058396, lng: 18.6938342 },
    { lat: 49.1058329, lng: 18.6938595 },
    { lat: 49.1057049, lng: 18.6943812 },
    { lat: 49.1056858, lng: 18.6944667 },
    { lat: 49.1055518, lng: 18.6945551 },
    { lat: 49.1054316, lng: 18.6946292 },
    { lat: 49.1053822, lng: 18.6949782 },
    { lat: 49.1052526, lng: 18.6952979 },
    { lat: 49.1052495, lng: 18.6957723 },
    { lat: 49.1052884, lng: 18.6958287 },
    { lat: 49.1053275, lng: 18.6958855 },
    { lat: 49.1056088, lng: 18.6962765 },
    { lat: 49.1057759, lng: 18.6966436 },
    { lat: 49.1061101, lng: 18.6961841 },
    { lat: 49.1067447, lng: 18.6955587 },
    { lat: 49.1068989, lng: 18.6954451 },
    { lat: 49.107632, lng: 18.6945689 },
    { lat: 49.1083894, lng: 18.6936242 },
    { lat: 49.108513, lng: 18.6934003 },
    { lat: 49.1089058, lng: 18.6932989 },
    { lat: 49.1093129, lng: 18.6931944 },
    { lat: 49.1095768, lng: 18.6931311 },
    { lat: 49.1097106, lng: 18.6930992 },
    { lat: 49.1101808, lng: 18.6929682 },
    { lat: 49.1109121, lng: 18.6927879 },
    { lat: 49.1113783, lng: 18.6926704 },
    { lat: 49.1127295, lng: 18.692313 },
    { lat: 49.1140398, lng: 18.6918199 },
    { lat: 49.1141819, lng: 18.6917755 },
    { lat: 49.1149606, lng: 18.6915303 },
    { lat: 49.1158417, lng: 18.6912381 },
    { lat: 49.1167263, lng: 18.6909659 },
    { lat: 49.118963, lng: 18.6900351 },
    { lat: 49.1189849, lng: 18.6900253 },
  ],
  Kunerad: [
    { lat: 49.05267, lng: 18.737243 },
    { lat: 49.052629, lng: 18.737656 },
    { lat: 49.052585, lng: 18.738004 },
    { lat: 49.052572, lng: 18.738103 },
    { lat: 49.052536, lng: 18.739224 },
    { lat: 49.052616, lng: 18.739598 },
    { lat: 49.052983, lng: 18.740336 },
    { lat: 49.053051, lng: 18.740608 },
    { lat: 49.053136, lng: 18.740942 },
    { lat: 49.053209, lng: 18.741698 },
    { lat: 49.053189, lng: 18.742453 },
    { lat: 49.052966, lng: 18.743354 },
    { lat: 49.052723, lng: 18.744062 },
    { lat: 49.052573, lng: 18.744559 },
    { lat: 49.052558, lng: 18.745561 },
    { lat: 49.052691, lng: 18.745867 },
    { lat: 49.052978, lng: 18.746287 },
    { lat: 49.053234, lng: 18.746853 },
    { lat: 49.053393, lng: 18.74729 },
    { lat: 49.053477, lng: 18.747757 },
    { lat: 49.053471, lng: 18.748507 },
    { lat: 49.053471, lng: 18.748508 },
    { lat: 49.053506, lng: 18.749314 },
    { lat: 49.053432, lng: 18.749945 },
    { lat: 49.053403, lng: 18.750244 },
    { lat: 49.05338, lng: 18.750323 },
    { lat: 49.053187, lng: 18.750797 },
    { lat: 49.05322, lng: 18.751155 },
    { lat: 49.053176, lng: 18.751554 },
    { lat: 49.053224, lng: 18.751621 },
    { lat: 49.053368, lng: 18.751834 },
    { lat: 49.053927, lng: 18.752726 },
    { lat: 49.054411, lng: 18.75327 },
    { lat: 49.054518, lng: 18.7533102 },
    { lat: 49.055114, lng: 18.753534 },
    { lat: 49.055537, lng: 18.753853 },
    { lat: 49.056025, lng: 18.754379 },
    { lat: 49.056519, lng: 18.754856 },
    { lat: 49.05704, lng: 18.755368 },
    { lat: 49.057582, lng: 18.755587 },
    { lat: 49.057847, lng: 18.755754 },
    { lat: 49.058208, lng: 18.756142 },
    { lat: 49.058309, lng: 18.756297 },
    { lat: 49.0581, lng: 18.756901 },
    { lat: 49.058052, lng: 18.757438 },
    { lat: 49.057975, lng: 18.757818 },
    { lat: 49.057845, lng: 18.7581 },
    { lat: 49.05776, lng: 18.758455 },
    { lat: 49.057353, lng: 18.759165 },
    { lat: 49.057287, lng: 18.759555 },
    { lat: 49.057512, lng: 18.759921 },
    { lat: 49.057719, lng: 18.760263 },
    { lat: 49.057776, lng: 18.760354 },
    { lat: 49.058076, lng: 18.760855 },
    { lat: 49.058222, lng: 18.761492 },
    { lat: 49.058307, lng: 18.762216 },
    { lat: 49.05843, lng: 18.763401 },
    { lat: 49.058434, lng: 18.763438 },
    { lat: 49.058462, lng: 18.763708 },
    { lat: 49.058926, lng: 18.763988 },
    { lat: 49.059047, lng: 18.764209 },
    { lat: 49.059719, lng: 18.765432 },
    { lat: 49.060015, lng: 18.765991 },
    { lat: 49.060048, lng: 18.766056 },
    { lat: 49.060376, lng: 18.766431 },
    { lat: 49.060543, lng: 18.767061 },
    { lat: 49.061057, lng: 18.76844 },
    { lat: 49.061224, lng: 18.768883 },
    { lat: 49.061416, lng: 18.769049 },
    { lat: 49.061513, lng: 18.769108 },
    { lat: 49.061529, lng: 18.769429 },
    { lat: 49.061474, lng: 18.770199 },
    { lat: 49.061302, lng: 18.770618 },
    { lat: 49.061223, lng: 18.771171 },
    { lat: 49.06089, lng: 18.772196 },
    { lat: 49.060723, lng: 18.772747 },
    { lat: 49.06067, lng: 18.773048 },
    { lat: 49.060664, lng: 18.773081 },
    { lat: 49.060539, lng: 18.773387 },
    { lat: 49.060539, lng: 18.774057 },
    { lat: 49.060539, lng: 18.774529 },
    { lat: 49.060613, lng: 18.77509 },
    { lat: 49.060746, lng: 18.775543 },
    { lat: 49.060841, lng: 18.775656 },
    { lat: 49.061003, lng: 18.77585 },
    { lat: 49.061509, lng: 18.776685 },
    { lat: 49.061921, lng: 18.777352 },
    { lat: 49.062245, lng: 18.777837 },
    { lat: 49.063415, lng: 18.779784 },
    { lat: 49.063549, lng: 18.780066 },
    { lat: 49.063613, lng: 18.7802 },
    { lat: 49.063795, lng: 18.780587 },
    { lat: 49.063946, lng: 18.781196 },
    { lat: 49.064215, lng: 18.781935 },
    { lat: 49.064474, lng: 18.782803 },
    { lat: 49.064628, lng: 18.783445 },
    { lat: 49.064807, lng: 18.784244 },
    { lat: 49.064901, lng: 18.784482 },
    { lat: 49.065207, lng: 18.784808 },
    { lat: 49.065872, lng: 18.785308 },
    { lat: 49.066689, lng: 18.785948 },
    { lat: 49.066827, lng: 18.786894 },
    { lat: 49.066847, lng: 18.787019 },
    { lat: 49.067115, lng: 18.788469 },
    { lat: 49.067286, lng: 18.789034 },
    { lat: 49.067299, lng: 18.789079 },
    { lat: 49.067405, lng: 18.789486 },
    { lat: 49.06743, lng: 18.789828 },
    { lat: 49.067456, lng: 18.79019 },
    { lat: 49.067424, lng: 18.790953 },
    { lat: 49.067562, lng: 18.791374 },
    { lat: 49.067859, lng: 18.79187 },
    { lat: 49.068082, lng: 18.792227 },
    { lat: 49.068181, lng: 18.792386 },
    { lat: 49.068182, lng: 18.792387 },
    { lat: 49.068262, lng: 18.792499 },
    { lat: 49.068415, lng: 18.792713 },
    { lat: 49.068812, lng: 18.793397 },
    { lat: 49.068812, lng: 18.793398 },
    { lat: 49.068903, lng: 18.793505 },
    { lat: 49.069033, lng: 18.793658 },
    { lat: 49.069033, lng: 18.793659 },
    { lat: 49.06943, lng: 18.794546 },
    { lat: 49.069638, lng: 18.79475 },
    { lat: 49.069912, lng: 18.794823 },
    { lat: 49.06994, lng: 18.794857 },
    { lat: 49.070202, lng: 18.795172 },
    { lat: 49.070496, lng: 18.795713 },
    { lat: 49.071048, lng: 18.796841 },
    { lat: 49.071184, lng: 18.797124 },
    { lat: 49.071185, lng: 18.797125 },
    { lat: 49.071643, lng: 18.797623 },
    { lat: 49.071672, lng: 18.79766 },
    { lat: 49.072005, lng: 18.798084 },
    { lat: 49.072117, lng: 18.798284 },
    { lat: 49.072169, lng: 18.798377 },
    { lat: 49.07241, lng: 18.798794 },
    { lat: 49.072477, lng: 18.799096 },
    { lat: 49.073482, lng: 18.800191 },
    { lat: 49.073994, lng: 18.801133 },
    { lat: 49.074243, lng: 18.801738 },
    { lat: 49.074337, lng: 18.801945 },
    { lat: 49.074513, lng: 18.802334 },
    { lat: 49.074518, lng: 18.802451 },
    { lat: 49.074532, lng: 18.802783 },
    { lat: 49.074898, lng: 18.80351 },
    { lat: 49.075074, lng: 18.803802 },
    { lat: 49.075359, lng: 18.804173 },
    { lat: 49.07558, lng: 18.804432 },
    { lat: 49.076282, lng: 18.805424 },
    { lat: 49.076283, lng: 18.805425 },
    { lat: 49.076364, lng: 18.805498 },
    { lat: 49.077387, lng: 18.806405 },
    { lat: 49.077388, lng: 18.806405 },
    { lat: 49.077599, lng: 18.80649 },
    { lat: 49.078189, lng: 18.806727 },
    { lat: 49.078509, lng: 18.806816 },
    { lat: 49.079476, lng: 18.806636 },
    { lat: 49.079735, lng: 18.806588 },
    { lat: 49.080045, lng: 18.80652 },
    { lat: 49.080371, lng: 18.808375 },
    { lat: 49.080547, lng: 18.809588 },
    { lat: 49.080773, lng: 18.810759 },
    { lat: 49.080774, lng: 18.81076 },
    { lat: 49.081075, lng: 18.81104 },
    { lat: 49.082145, lng: 18.811906 },
    { lat: 49.08262, lng: 18.812281 },
    { lat: 49.083024, lng: 18.812583 },
    { lat: 49.084344, lng: 18.815606 },
    { lat: 49.084345, lng: 18.815606 },
    { lat: 49.0854167, lng: 18.815586 },
    { lat: 49.085564, lng: 18.815599 },
    { lat: 49.086548, lng: 18.815443 },
    { lat: 49.087632, lng: 18.814587 },
    { lat: 49.088601, lng: 18.814191 },
    { lat: 49.088657, lng: 18.8138685 },
    { lat: 49.0886142, lng: 18.8128282 },
    { lat: 49.0885716, lng: 18.8120681 },
    { lat: 49.0884672, lng: 18.8107916 },
    { lat: 49.0884268, lng: 18.8096428 },
    { lat: 49.0884014, lng: 18.808718 },
    { lat: 49.0884311, lng: 18.808092 },
    { lat: 49.0884519, lng: 18.8071661 },
    { lat: 49.0883616, lng: 18.8064685 },
    { lat: 49.0887463, lng: 18.8056017 },
    { lat: 49.0890823, lng: 18.8041888 },
    { lat: 49.0893441, lng: 18.8035839 },
    { lat: 49.0895249, lng: 18.803156 },
    { lat: 49.0896849, lng: 18.8027421 },
    { lat: 49.0899141, lng: 18.8013748 },
    { lat: 49.0899616, lng: 18.8010744 },
    { lat: 49.0898763, lng: 18.8004964 },
    { lat: 49.0900876, lng: 18.7990035 },
    { lat: 49.0900704, lng: 18.7977611 },
    { lat: 49.0900075, lng: 18.7969588 },
    { lat: 49.0899638, lng: 18.7958801 },
    { lat: 49.0899946, lng: 18.7953457 },
    { lat: 49.0900135, lng: 18.7950981 },
    { lat: 49.0899822, lng: 18.7943647 },
    { lat: 49.0899328, lng: 18.7939591 },
    { lat: 49.0898899, lng: 18.7936735 },
    { lat: 49.0899033, lng: 18.7934316 },
    { lat: 49.0899638, lng: 18.7922766 },
    { lat: 49.0898786, lng: 18.7914144 },
    { lat: 49.0898557, lng: 18.7907146 },
    { lat: 49.0898992, lng: 18.7898379 },
    { lat: 49.0898532, lng: 18.789234 },
    { lat: 49.0899258, lng: 18.7886586 },
    { lat: 49.0902452, lng: 18.7878384 },
    { lat: 49.0902624, lng: 18.7868246 },
    { lat: 49.0902731, lng: 18.7864186 },
    { lat: 49.0903127, lng: 18.7849099 },
    { lat: 49.0902085, lng: 18.7837931 },
    { lat: 49.0903788, lng: 18.7834063 },
    { lat: 49.0907771, lng: 18.7825646 },
    { lat: 49.0910548, lng: 18.7818749 },
    { lat: 49.0911513, lng: 18.7814358 },
    { lat: 49.0913236, lng: 18.7806933 },
    { lat: 49.0916979, lng: 18.779975 },
    { lat: 49.0916951, lng: 18.7789049 },
    { lat: 49.0915756, lng: 18.7782124 },
    { lat: 49.0906268, lng: 18.7769489 },
    { lat: 49.0895863, lng: 18.7745429 },
    { lat: 49.0895603, lng: 18.7744829 },
    { lat: 49.089975, lng: 18.77316 },
    { lat: 49.0899329, lng: 18.7725689 },
    { lat: 49.0897472, lng: 18.7716217 },
    { lat: 49.0896689, lng: 18.7705741 },
    { lat: 49.0894953, lng: 18.7695136 },
    { lat: 49.0898749, lng: 18.7679531 },
    { lat: 49.0900977, lng: 18.7663456 },
    { lat: 49.0899485, lng: 18.7656855 },
    { lat: 49.0897615, lng: 18.7652912 },
    { lat: 49.089714, lng: 18.7651911 },
    { lat: 49.0894776, lng: 18.7640982 },
    { lat: 49.0894758, lng: 18.7634562 },
    { lat: 49.0892245, lng: 18.763006 },
    { lat: 49.0895088, lng: 18.7619516 },
    { lat: 49.089683, lng: 18.7617229 },
    { lat: 49.0900714, lng: 18.7608937 },
    { lat: 49.090501, lng: 18.7599423 },
    { lat: 49.0908264, lng: 18.7591106 },
    { lat: 49.0909318, lng: 18.7589255 },
    { lat: 49.0913909, lng: 18.7585853 },
    { lat: 49.091714, lng: 18.7583573 },
    { lat: 49.0919057, lng: 18.7581009 },
    { lat: 49.0919802, lng: 18.7578447 },
    { lat: 49.0921786, lng: 18.7564178 },
    { lat: 49.0928665, lng: 18.7552068 },
    { lat: 49.093155, lng: 18.7548178 },
    { lat: 49.0934182, lng: 18.7545866 },
    { lat: 49.0936592, lng: 18.7542595 },
    { lat: 49.0936687, lng: 18.7530814 },
    { lat: 49.0938774, lng: 18.7517895 },
    { lat: 49.0938186, lng: 18.751054 },
    { lat: 49.0941213, lng: 18.7498986 },
    { lat: 49.0943455, lng: 18.7492917 },
    { lat: 49.0945218, lng: 18.7488037 },
    { lat: 49.0947966, lng: 18.7484292 },
    { lat: 49.0953016, lng: 18.7475619 },
    { lat: 49.0956143, lng: 18.7463409 },
    { lat: 49.0959663, lng: 18.7454004 },
    { lat: 49.0957917, lng: 18.7448408 },
    { lat: 49.0956114, lng: 18.7444805 },
    { lat: 49.0954952, lng: 18.7441506 },
    { lat: 49.095422, lng: 18.7438537 },
    { lat: 49.0953445, lng: 18.7434299 },
    { lat: 49.0951708, lng: 18.7429328 },
    { lat: 49.0949267, lng: 18.7422352 },
    { lat: 49.0947534, lng: 18.7415521 },
    { lat: 49.0945578, lng: 18.7408533 },
    { lat: 49.0944011, lng: 18.7400811 },
    { lat: 49.0944101, lng: 18.7398659 },
    { lat: 49.0943147, lng: 18.7391172 },
    { lat: 49.0943516, lng: 18.7385103 },
    { lat: 49.0943779, lng: 18.7383031 },
    { lat: 49.0944244, lng: 18.738179 },
    { lat: 49.0945323, lng: 18.7378907 },
    { lat: 49.0945652, lng: 18.7373549 },
    { lat: 49.0947342, lng: 18.7369465 },
    { lat: 49.0947749, lng: 18.7369077 },
    { lat: 49.0949721, lng: 18.7365804 },
    { lat: 49.0954285, lng: 18.7358873 },
    { lat: 49.0959104, lng: 18.7353346 },
    { lat: 49.0962478, lng: 18.7349291 },
    { lat: 49.0963779, lng: 18.7347017 },
    { lat: 49.097099, lng: 18.7340649 },
    { lat: 49.0972665, lng: 18.7337124 },
    { lat: 49.0974157, lng: 18.7330289 },
    { lat: 49.0974273, lng: 18.7319166 },
    { lat: 49.0973613, lng: 18.7313259 },
    { lat: 49.097273, lng: 18.730998 },
    { lat: 49.097245, lng: 18.7300543 },
    { lat: 49.0973885, lng: 18.7295478 },
    { lat: 49.0975414, lng: 18.729066 },
    { lat: 49.0976061, lng: 18.7284309 },
    { lat: 49.0976495, lng: 18.72801 },
    { lat: 49.0977412, lng: 18.7276099 },
    { lat: 49.0979527, lng: 18.7270616 },
    { lat: 49.0983688, lng: 18.7265471 },
    { lat: 49.0985641, lng: 18.7259062 },
    { lat: 49.0988203, lng: 18.7252456 },
    { lat: 49.0991695, lng: 18.7247633 },
    { lat: 49.0991487, lng: 18.7245207 },
    { lat: 49.099078, lng: 18.7239039 },
    { lat: 49.0989663, lng: 18.7230859 },
    { lat: 49.0988153, lng: 18.721884 },
    { lat: 49.0987013, lng: 18.7209215 },
    { lat: 49.0990481, lng: 18.72065 },
    { lat: 49.099306, lng: 18.7206244 },
    { lat: 49.0995576, lng: 18.7206052 },
    { lat: 49.1001824, lng: 18.7206273 },
    { lat: 49.1004515, lng: 18.7206618 },
    { lat: 49.1007125, lng: 18.7207139 },
    { lat: 49.1007301, lng: 18.7207078 },
    { lat: 49.1010677, lng: 18.7205842 },
    { lat: 49.1012985, lng: 18.720578 },
    { lat: 49.1016559, lng: 18.7205769 },
    { lat: 49.1017207, lng: 18.7205867 },
    { lat: 49.1017617, lng: 18.7205932 },
    { lat: 49.101957, lng: 18.7206406 },
    { lat: 49.1020303, lng: 18.7205774 },
    { lat: 49.1020464, lng: 18.7203904 },
    { lat: 49.102082, lng: 18.7202756 },
    { lat: 49.1022244, lng: 18.7202161 },
    { lat: 49.1022699, lng: 18.7201775 },
    { lat: 49.1022928, lng: 18.7200563 },
    { lat: 49.1022882, lng: 18.7199906 },
    { lat: 49.1022085, lng: 18.7198269 },
    { lat: 49.1021909, lng: 18.7195145 },
    { lat: 49.1020971, lng: 18.7194438 },
    { lat: 49.1020683, lng: 18.7193279 },
    { lat: 49.1021612, lng: 18.7191434 },
    { lat: 49.1021776, lng: 18.7190025 },
    { lat: 49.1022612, lng: 18.7187706 },
    { lat: 49.1022003, lng: 18.7187061 },
    { lat: 49.1021716, lng: 18.7186092 },
    { lat: 49.1021813, lng: 18.7184928 },
    { lat: 49.102221, lng: 18.7184333 },
    { lat: 49.102277, lng: 18.7183217 },
    { lat: 49.1022603, lng: 18.718214 },
    { lat: 49.1022919, lng: 18.7180021 },
    { lat: 49.1022596, lng: 18.7178143 },
    { lat: 49.1022149, lng: 18.7177197 },
    { lat: 49.1022131, lng: 18.7175327 },
    { lat: 49.1021279, lng: 18.717392 },
    { lat: 49.1020063, lng: 18.7172035 },
    { lat: 49.1019983, lng: 18.7170522 },
    { lat: 49.1020351, lng: 18.7169797 },
    { lat: 49.1021158, lng: 18.7167748 },
    { lat: 49.1021191, lng: 18.7165585 },
    { lat: 49.1020921, lng: 18.7164135 },
    { lat: 49.1019598, lng: 18.7161676 },
    { lat: 49.1018661, lng: 18.7159314 },
    { lat: 49.1018312, lng: 18.7158018 },
    { lat: 49.1018371, lng: 18.7157662 },
    { lat: 49.1019766, lng: 18.7156336 },
    { lat: 49.1020252, lng: 18.7154827 },
    { lat: 49.1020305, lng: 18.7153917 },
    { lat: 49.1019413, lng: 18.7152802 },
    { lat: 49.1019279, lng: 18.7152185 },
    { lat: 49.1019381, lng: 18.7151568 },
    { lat: 49.1019632, lng: 18.715117 },
    { lat: 49.1020568, lng: 18.7150955 },
    { lat: 49.1020968, lng: 18.7150662 },
    { lat: 49.1021381, lng: 18.7150565 },
    { lat: 49.1021371, lng: 18.7149969 },
    { lat: 49.1020285, lng: 18.714837 },
    { lat: 49.1020055, lng: 18.7147448 },
    { lat: 49.102019, lng: 18.7146787 },
    { lat: 49.1021077, lng: 18.7146305 },
    { lat: 49.1022001, lng: 18.7144321 },
    { lat: 49.1021978, lng: 18.7143042 },
    { lat: 49.1022191, lng: 18.7143041 },
    { lat: 49.1025321, lng: 18.714379 },
    { lat: 49.1025876, lng: 18.7138028 },
    { lat: 49.1026367, lng: 18.7136003 },
    { lat: 49.1026558, lng: 18.7132813 },
    { lat: 49.1026719, lng: 18.7131152 },
    { lat: 49.1027331, lng: 18.7126071 },
    { lat: 49.1027515, lng: 18.7125034 },
    { lat: 49.1027609, lng: 18.7124044 },
    { lat: 49.1027583, lng: 18.7123356 },
    { lat: 49.1027384, lng: 18.7121689 },
    { lat: 49.1027522, lng: 18.7120894 },
    { lat: 49.1027831, lng: 18.7120133 },
    { lat: 49.1027949, lng: 18.711982 },
    { lat: 49.1027904, lng: 18.7118955 },
    { lat: 49.1027872, lng: 18.711835 },
    { lat: 49.1028032, lng: 18.7116442 },
    { lat: 49.102826, lng: 18.7116068 },
    { lat: 49.102857, lng: 18.7115541 },
    { lat: 49.1028356, lng: 18.7112727 },
    { lat: 49.1027814, lng: 18.7105898 },
    { lat: 49.1027542, lng: 18.7103505 },
    { lat: 49.1027831, lng: 18.710257 },
    { lat: 49.1027927, lng: 18.7102242 },
    { lat: 49.1028767, lng: 18.709935 },
    { lat: 49.1029163, lng: 18.7097218 },
    { lat: 49.1029403, lng: 18.7095141 },
    { lat: 49.1029372, lng: 18.7093687 },
    { lat: 49.1029571, lng: 18.7091903 },
    { lat: 49.1029791, lng: 18.7087111 },
    { lat: 49.1029804, lng: 18.7084759 },
    { lat: 49.1029715, lng: 18.7081819 },
    { lat: 49.1028782, lng: 18.7076531 },
    { lat: 49.1028396, lng: 18.7074077 },
    { lat: 49.1028171, lng: 18.7068745 },
    { lat: 49.1028807, lng: 18.7064572 },
    { lat: 49.1029103, lng: 18.7062258 },
    { lat: 49.10292, lng: 18.7058121 },
    { lat: 49.102936, lng: 18.7052998 },
    { lat: 49.1029522, lng: 18.7050517 },
    { lat: 49.1029967, lng: 18.7043754 },
    { lat: 49.1029955, lng: 18.7042273 },
    { lat: 49.1029954, lng: 18.7039469 },
    { lat: 49.102989, lng: 18.7037181 },
    { lat: 49.1028736, lng: 18.7033036 },
    { lat: 49.1027355, lng: 18.7029742 },
    { lat: 49.1030615, lng: 18.7022923 },
    { lat: 49.1038429, lng: 18.7006639 },
    { lat: 49.1039848, lng: 18.7003608 },
    { lat: 49.1041906, lng: 18.6998987 },
    { lat: 49.10435, lng: 18.6995423 },
    { lat: 49.1044379, lng: 18.6993502 },
    { lat: 49.1045066, lng: 18.6992003 },
    { lat: 49.1046522, lng: 18.6989014 },
    { lat: 49.1047853, lng: 18.6986235 },
    { lat: 49.1048184, lng: 18.6984593 },
    { lat: 49.104856, lng: 18.6982765 },
    { lat: 49.104892, lng: 18.6981033 },
    { lat: 49.105058, lng: 18.6977461 },
    { lat: 49.1051701, lng: 18.6974736 },
    { lat: 49.1050243, lng: 18.6970005 },
    { lat: 49.104988, lng: 18.6968671 },
    { lat: 49.1050535, lng: 18.6968044 },
    { lat: 49.1051527, lng: 18.6966116 },
    { lat: 49.1052199, lng: 18.6963672 },
    { lat: 49.1052796, lng: 18.6959586 },
    { lat: 49.1052884, lng: 18.6958287 },
    { lat: 49.1052495, lng: 18.6957723 },
    { lat: 49.1052526, lng: 18.6952979 },
    { lat: 49.1053822, lng: 18.6949782 },
    { lat: 49.1054316, lng: 18.6946292 },
    { lat: 49.1055518, lng: 18.6945551 },
    { lat: 49.1056858, lng: 18.6944667 },
    { lat: 49.1057049, lng: 18.6943812 },
    { lat: 49.1058329, lng: 18.6938595 },
    { lat: 49.1058396, lng: 18.6938342 },
    { lat: 49.1059785, lng: 18.6932417 },
    { lat: 49.1062304, lng: 18.6928624 },
    { lat: 49.1061932, lng: 18.6926499 },
    { lat: 49.1061784, lng: 18.6924775 },
    { lat: 49.1060867, lng: 18.6919579 },
    { lat: 49.1060598, lng: 18.6917254 },
    { lat: 49.1059444, lng: 18.6918054 },
    { lat: 49.1059151, lng: 18.6918054 },
    { lat: 49.105803, lng: 18.6917943 },
    { lat: 49.1057835, lng: 18.6917924 },
    { lat: 49.1057404, lng: 18.6918711 },
    { lat: 49.1057064, lng: 18.6919335 },
    { lat: 49.1056871, lng: 18.6919715 },
    { lat: 49.1056109, lng: 18.6918527 },
    { lat: 49.1055826, lng: 18.691808 },
    { lat: 49.1055596, lng: 18.6918209 },
    { lat: 49.1055137, lng: 18.691847 },
    { lat: 49.1054557, lng: 18.6918797 },
    { lat: 49.1052905, lng: 18.6919787 },
    { lat: 49.1051319, lng: 18.6921795 },
    { lat: 49.1051086, lng: 18.6922087 },
    { lat: 49.1050161, lng: 18.6921844 },
    { lat: 49.1049143, lng: 18.692158 },
    { lat: 49.1048376, lng: 18.6921378 },
    { lat: 49.1047599, lng: 18.692115 },
    { lat: 49.1046566, lng: 18.692083 },
    { lat: 49.10461, lng: 18.6920736 },
    { lat: 49.104571, lng: 18.6920654 },
    { lat: 49.1045008, lng: 18.6920607 },
    { lat: 49.1044477, lng: 18.6920571 },
    { lat: 49.10437, lng: 18.692058 },
    { lat: 49.1043295, lng: 18.6920611 },
    { lat: 49.104301, lng: 18.6920758 },
    { lat: 49.1042004, lng: 18.6921792 },
    { lat: 49.1040506, lng: 18.6923328 },
    { lat: 49.1039696, lng: 18.6924184 },
    { lat: 49.1038915, lng: 18.6925017 },
    { lat: 49.1038029, lng: 18.6925953 },
    { lat: 49.1037921, lng: 18.6926053 },
    { lat: 49.1036889, lng: 18.6927018 },
    { lat: 49.103676, lng: 18.6927131 },
    { lat: 49.1036148, lng: 18.6927713 },
    { lat: 49.1035809, lng: 18.6927913 },
    { lat: 49.1035466, lng: 18.6928254 },
    { lat: 49.1035102, lng: 18.6928615 },
    { lat: 49.1034662, lng: 18.6929058 },
    { lat: 49.1034104, lng: 18.6929767 },
    { lat: 49.1033827, lng: 18.6930114 },
    { lat: 49.1032899, lng: 18.6931293 },
    { lat: 49.1032697, lng: 18.693153 },
    { lat: 49.1032589, lng: 18.6932043 },
    { lat: 49.1031627, lng: 18.6933291 },
    { lat: 49.1030963, lng: 18.6934139 },
    { lat: 49.1029778, lng: 18.6935641 },
    { lat: 49.1028734, lng: 18.6937007 },
    { lat: 49.1025957, lng: 18.6940395 },
    { lat: 49.1024512, lng: 18.6941184 },
    { lat: 49.1022268, lng: 18.6942045 },
    { lat: 49.1021155, lng: 18.6948307 },
    { lat: 49.1021161, lng: 18.6949721 },
    { lat: 49.102132, lng: 18.6953135 },
    { lat: 49.1021135, lng: 18.6954118 },
    { lat: 49.1020481, lng: 18.6956278 },
    { lat: 49.1019726, lng: 18.6958221 },
    { lat: 49.1019223, lng: 18.6958721 },
    { lat: 49.1015506, lng: 18.6961588 },
    { lat: 49.1007435, lng: 18.6967207 },
    { lat: 49.099371, lng: 18.6977426 },
    { lat: 49.0990973, lng: 18.697987 },
    { lat: 49.0988431, lng: 18.6982135 },
    { lat: 49.0982542, lng: 18.6988484 },
    { lat: 49.0982049, lng: 18.698899 },
    { lat: 49.0982007, lng: 18.6988837 },
    { lat: 49.09763, lng: 18.699439 },
    { lat: 49.0965501, lng: 18.7005365 },
    { lat: 49.0961251, lng: 18.7009458 },
    { lat: 49.0958533, lng: 18.7011788 },
    { lat: 49.0948096, lng: 18.7027299 },
    { lat: 49.0944339, lng: 18.7032609 },
    { lat: 49.0939611, lng: 18.7038746 },
    { lat: 49.0936231, lng: 18.7043312 },
    { lat: 49.093333, lng: 18.7047542 },
    { lat: 49.0925756, lng: 18.7056815 },
    { lat: 49.092395, lng: 18.705914 },
    { lat: 49.0920684, lng: 18.7063926 },
    { lat: 49.0916798, lng: 18.7073022 },
    { lat: 49.0915349, lng: 18.7077581 },
    { lat: 49.0912821, lng: 18.7085709 },
    { lat: 49.0910572, lng: 18.7098469 },
    { lat: 49.0906016, lng: 18.7110848 },
    { lat: 49.0900825, lng: 18.7117026 },
    { lat: 49.0899543, lng: 18.7122868 },
    { lat: 49.0892951, lng: 18.7128105 },
    { lat: 49.0889582, lng: 18.7130315 },
    { lat: 49.0887912, lng: 18.7131367 },
    { lat: 49.088418, lng: 18.7133723 },
    { lat: 49.0878209, lng: 18.7137455 },
    { lat: 49.0871543, lng: 18.7143039 },
    { lat: 49.0863876, lng: 18.7149468 },
    { lat: 49.0860998, lng: 18.7151362 },
    { lat: 49.0857847, lng: 18.7153197 },
    { lat: 49.0853767, lng: 18.7155802 },
    { lat: 49.0848375, lng: 18.7159198 },
    { lat: 49.0845232, lng: 18.7161211 },
    { lat: 49.0839197, lng: 18.7165652 },
    { lat: 49.0836322, lng: 18.7170625 },
    { lat: 49.0832336, lng: 18.7178217 },
    { lat: 49.0827732, lng: 18.7186509 },
    { lat: 49.0823518, lng: 18.7193246 },
    { lat: 49.0818869, lng: 18.7200468 },
    { lat: 49.0815066, lng: 18.7206421 },
    { lat: 49.0813701, lng: 18.7209001 },
    { lat: 49.0810471, lng: 18.7214039 },
    { lat: 49.0807255, lng: 18.7219175 },
    { lat: 49.0805802, lng: 18.7220079 },
    { lat: 49.0803679, lng: 18.7221938 },
    { lat: 49.0802355, lng: 18.7223772 },
    { lat: 49.0800804, lng: 18.7225778 },
    { lat: 49.079904, lng: 18.722764 },
    { lat: 49.0797129, lng: 18.7229053 },
    { lat: 49.0793308, lng: 18.7231209 },
    { lat: 49.0790175, lng: 18.7233831 },
    { lat: 49.0787392, lng: 18.7236224 },
    { lat: 49.0785464, lng: 18.7237974 },
    { lat: 49.0781347, lng: 18.7241795 },
    { lat: 49.0778239, lng: 18.7245116 },
    { lat: 49.0776154, lng: 18.7246837 },
    { lat: 49.0773411, lng: 18.724829 },
    { lat: 49.0771652, lng: 18.7250436 },
    { lat: 49.0768097, lng: 18.7254908 },
    { lat: 49.0766278, lng: 18.7257216 },
    { lat: 49.076229, lng: 18.7261553 },
    { lat: 49.0760079, lng: 18.7264754 },
    { lat: 49.0759163, lng: 18.7266207 },
    { lat: 49.0757477, lng: 18.7268901 },
    { lat: 49.0756178, lng: 18.7271642 },
    { lat: 49.0754262, lng: 18.7276616 },
    { lat: 49.0753041, lng: 18.7283872 },
    { lat: 49.0751958, lng: 18.7287263 },
    { lat: 49.0749347, lng: 18.7283944 },
    { lat: 49.0746412, lng: 18.7282819 },
    { lat: 49.0740826, lng: 18.7284045 },
    { lat: 49.0738294, lng: 18.7284109 },
    { lat: 49.0733717, lng: 18.7288255 },
    { lat: 49.0727946, lng: 18.7295791 },
    { lat: 49.0724776, lng: 18.7298565 },
    { lat: 49.0719839, lng: 18.7299446 },
    { lat: 49.0716371, lng: 18.7300425 },
    { lat: 49.0710627, lng: 18.7304202 },
    { lat: 49.070872, lng: 18.73057 },
    { lat: 49.0704335, lng: 18.7309571 },
    { lat: 49.0701626, lng: 18.731213 },
    { lat: 49.0698256, lng: 18.7318597 },
    { lat: 49.0692775, lng: 18.732569 },
    { lat: 49.0689512, lng: 18.7328173 },
    { lat: 49.0685714, lng: 18.7329735 },
    { lat: 49.0682208, lng: 18.7331689 },
    { lat: 49.0672377, lng: 18.733775 },
    { lat: 49.0665057, lng: 18.7346505 },
    { lat: 49.0652016, lng: 18.7355472 },
    { lat: 49.0650132, lng: 18.7356942 },
    { lat: 49.0646157, lng: 18.7361589 },
    { lat: 49.0642909, lng: 18.7367137 },
    { lat: 49.0631722, lng: 18.7382635 },
    { lat: 49.0619157, lng: 18.7386862 },
    { lat: 49.0612925, lng: 18.7388474 },
    { lat: 49.0607244, lng: 18.7385708 },
    { lat: 49.0604494, lng: 18.7385942 },
    { lat: 49.0600398, lng: 18.7377737 },
    { lat: 49.0593141, lng: 18.7363006 },
    { lat: 49.0589094, lng: 18.7355263 },
    { lat: 49.0585817, lng: 18.7349156 },
    { lat: 49.0582715, lng: 18.7343942 },
    { lat: 49.058076, lng: 18.7341314 },
    { lat: 49.0579273, lng: 18.7340045 },
    { lat: 49.0574444, lng: 18.7335703 },
    { lat: 49.057008, lng: 18.7332454 },
    { lat: 49.0564436, lng: 18.7327851 },
    { lat: 49.056428, lng: 18.7327665 },
    { lat: 49.0560874, lng: 18.7332206 },
    { lat: 49.0558611, lng: 18.7331816 },
    { lat: 49.0555494, lng: 18.7333648 },
    { lat: 49.0549866, lng: 18.7337531 },
    { lat: 49.0546592, lng: 18.7340885 },
    { lat: 49.0543444, lng: 18.7344312 },
    { lat: 49.0541207, lng: 18.7347161 },
    { lat: 49.0540567, lng: 18.734857 },
    { lat: 49.053965, lng: 18.735063 },
    { lat: 49.0538429, lng: 18.7353396 },
    { lat: 49.0537379, lng: 18.7354861 },
    { lat: 49.053375, lng: 18.7360055 },
    { lat: 49.052779, lng: 18.7369407 },
    { lat: 49.05267, lng: 18.737243 },
  ],
  LietavskáSvinnáBabkov: [
    { lat: 49.1659417, lng: 18.6491803 },
    { lat: 49.1660553, lng: 18.6489417 },
    { lat: 49.1648656, lng: 18.6462262 },
    { lat: 49.1649046, lng: 18.6461522 },
    { lat: 49.164923, lng: 18.6461157 },
    { lat: 49.1654889, lng: 18.645028 },
    { lat: 49.1660431, lng: 18.64402 },
    { lat: 49.1671448, lng: 18.6419601 },
    { lat: 49.1672209, lng: 18.6418635 },
    { lat: 49.1671897, lng: 18.6417203 },
    { lat: 49.1671691, lng: 18.6416082 },
    { lat: 49.167179, lng: 18.6415007 },
    { lat: 49.1671253, lng: 18.6412338 },
    { lat: 49.1670343, lng: 18.6412162 },
    { lat: 49.1669869, lng: 18.6410271 },
    { lat: 49.1669312, lng: 18.6410408 },
    { lat: 49.1669118, lng: 18.640867 },
    { lat: 49.16583, lng: 18.638846 },
    { lat: 49.1657059, lng: 18.6386336 },
    { lat: 49.1657937, lng: 18.6381813 },
    { lat: 49.1659846, lng: 18.6376164 },
    { lat: 49.1660656, lng: 18.6371674 },
    { lat: 49.1659321, lng: 18.6366293 },
    { lat: 49.1659632, lng: 18.6363447 },
    { lat: 49.1658565, lng: 18.6351481 },
    { lat: 49.1657525, lng: 18.6344236 },
    { lat: 49.1656778, lng: 18.6334855 },
    { lat: 49.1656173, lng: 18.6323278 },
    { lat: 49.1653087, lng: 18.6314673 },
    { lat: 49.1649776, lng: 18.6307756 },
    { lat: 49.1648146, lng: 18.630172 },
    { lat: 49.1645578, lng: 18.6295843 },
    { lat: 49.164395, lng: 18.6285198 },
    { lat: 49.1642577, lng: 18.6284622 },
    { lat: 49.1641388, lng: 18.6284311 },
    { lat: 49.1639697, lng: 18.6283033 },
    { lat: 49.1640122, lng: 18.6275978 },
    { lat: 49.1639776, lng: 18.6270767 },
    { lat: 49.1642185, lng: 18.6259041 },
    { lat: 49.1642969, lng: 18.6252604 },
    { lat: 49.1643608, lng: 18.6247649 },
    { lat: 49.1643584, lng: 18.6244049 },
    { lat: 49.1642579, lng: 18.6241085 },
    { lat: 49.1640596, lng: 18.6235806 },
    { lat: 49.1640276, lng: 18.6229406 },
    { lat: 49.1639759, lng: 18.6225192 },
    { lat: 49.1640121, lng: 18.6220546 },
    { lat: 49.164046, lng: 18.6218039 },
    { lat: 49.1640074, lng: 18.6215647 },
    { lat: 49.1638335, lng: 18.6207797 },
    { lat: 49.1634371, lng: 18.6197114 },
    { lat: 49.1626209, lng: 18.6186404 },
    { lat: 49.162518, lng: 18.6175757 },
    { lat: 49.1622986, lng: 18.6162995 },
    { lat: 49.1629914, lng: 18.6153104 },
    { lat: 49.16296, lng: 18.6147233 },
    { lat: 49.1626624, lng: 18.6125541 },
    { lat: 49.162695, lng: 18.612461 },
    { lat: 49.162298, lng: 18.61233 },
    { lat: 49.16198, lng: 18.611907 },
    { lat: 49.161744, lng: 18.611931 },
    { lat: 49.161577, lng: 18.612121 },
    { lat: 49.160956, lng: 18.612083 },
    { lat: 49.160775, lng: 18.612226 },
    { lat: 49.160501, lng: 18.612248 },
    { lat: 49.15997, lng: 18.612581 },
    { lat: 49.159523, lng: 18.612715 },
    { lat: 49.159172, lng: 18.612839 },
    { lat: 49.158983, lng: 18.612738 },
    { lat: 49.158521, lng: 18.612797 },
    { lat: 49.158217, lng: 18.612643 },
    { lat: 49.157954, lng: 18.612729 },
    { lat: 49.157569, lng: 18.612556 },
    { lat: 49.157144, lng: 18.612666 },
    { lat: 49.156771, lng: 18.612521 },
    { lat: 49.156706, lng: 18.612243 },
    { lat: 49.156617, lng: 18.612073 },
    { lat: 49.155854, lng: 18.611923 },
    { lat: 49.155538, lng: 18.611796 },
    { lat: 49.15543, lng: 18.611847 },
    { lat: 49.155116, lng: 18.611616 },
    { lat: 49.15455, lng: 18.611482 },
    { lat: 49.153432, lng: 18.611694 },
    { lat: 49.153239, lng: 18.611863 },
    { lat: 49.152697, lng: 18.611582 },
    { lat: 49.152497, lng: 18.611579 },
    { lat: 49.151808, lng: 18.611475 },
    { lat: 49.151437, lng: 18.611801 },
    { lat: 49.151201, lng: 18.61166 },
    { lat: 49.151071, lng: 18.611384 },
    { lat: 49.150939, lng: 18.611104 },
    { lat: 49.150587, lng: 18.610787 },
    { lat: 49.150348, lng: 18.61081 },
    { lat: 49.15018, lng: 18.611113 },
    { lat: 49.149834, lng: 18.611265 },
    { lat: 49.149587, lng: 18.611097 },
    { lat: 49.149318, lng: 18.610809 },
    { lat: 49.148872, lng: 18.610238 },
    { lat: 49.148598, lng: 18.610033 },
    { lat: 49.147782, lng: 18.609099 },
    { lat: 49.146922, lng: 18.609793 },
    { lat: 49.146769, lng: 18.609708 },
    { lat: 49.146634, lng: 18.609363 },
    { lat: 49.146236, lng: 18.6091 },
    { lat: 49.145976, lng: 18.609256 },
    { lat: 49.145717, lng: 18.609266 },
    { lat: 49.145343, lng: 18.608794 },
    { lat: 49.144924, lng: 18.609093 },
    { lat: 49.144575, lng: 18.609734 },
    { lat: 49.143559, lng: 18.60953 },
    { lat: 49.143211, lng: 18.609543 },
    { lat: 49.142403, lng: 18.610002 },
    { lat: 49.14221, lng: 18.609997 },
    { lat: 49.141675, lng: 18.610418 },
    { lat: 49.14089, lng: 18.610901 },
    { lat: 49.140724, lng: 18.610754 },
    { lat: 49.140582, lng: 18.610569 },
    { lat: 49.14064, lng: 18.612263 },
    { lat: 49.140524, lng: 18.613293 },
    { lat: 49.140387, lng: 18.614838 },
    { lat: 49.140399, lng: 18.615231 },
    { lat: 49.1404, lng: 18.61526 },
    { lat: 49.14042, lng: 18.615905 },
    { lat: 49.139968, lng: 18.616033 },
    { lat: 49.139609, lng: 18.616526 },
    { lat: 49.139422, lng: 18.616714 },
    { lat: 49.138998, lng: 18.617008 },
    { lat: 49.138279, lng: 18.617509 },
    { lat: 49.137756, lng: 18.618 },
    { lat: 49.136957, lng: 18.618714 },
    { lat: 49.136634, lng: 18.618425 },
    { lat: 49.136343, lng: 18.618302 },
    { lat: 49.136013, lng: 18.618165 },
    { lat: 49.135674, lng: 18.618383 },
    { lat: 49.135403, lng: 18.618705 },
    { lat: 49.13507, lng: 18.618972 },
    { lat: 49.134857, lng: 18.618699 },
    { lat: 49.134536, lng: 18.618485 },
    { lat: 49.13314, lng: 18.617644 },
    { lat: 49.132942, lng: 18.618367 },
    { lat: 49.132884, lng: 18.618518 },
    { lat: 49.132457, lng: 18.619079 },
    { lat: 49.132329, lng: 18.619205 },
    { lat: 49.132097, lng: 18.619369 },
    { lat: 49.131979, lng: 18.619507 },
    { lat: 49.131906, lng: 18.619725 },
    { lat: 49.131838, lng: 18.620129 },
    { lat: 49.131811, lng: 18.620354 },
    { lat: 49.131704, lng: 18.622401 },
    { lat: 49.131731, lng: 18.622663 },
    { lat: 49.131744, lng: 18.622741 },
    { lat: 49.1320187, lng: 18.6234412 },
    { lat: 49.132095, lng: 18.6236442 },
    { lat: 49.1322855, lng: 18.6240643 },
    { lat: 49.1323816, lng: 18.6244082 },
    { lat: 49.1324662, lng: 18.6247496 },
    { lat: 49.1325371, lng: 18.6250603 },
    { lat: 49.1326683, lng: 18.6253847 },
    { lat: 49.1329452, lng: 18.6259893 },
    { lat: 49.1331029, lng: 18.6263904 },
    { lat: 49.1331666, lng: 18.6266717 },
    { lat: 49.1332056, lng: 18.6270044 },
    { lat: 49.1332458, lng: 18.6271633 },
    { lat: 49.1333965, lng: 18.6274734 },
    { lat: 49.1336496, lng: 18.6278411 },
    { lat: 49.1339861, lng: 18.6283361 },
    { lat: 49.1341304, lng: 18.6284634 },
    { lat: 49.1343211, lng: 18.628613 },
    { lat: 49.134604, lng: 18.6287786 },
    { lat: 49.134862, lng: 18.6290254 },
    { lat: 49.1350164, lng: 18.6292177 },
    { lat: 49.1351127, lng: 18.6293516 },
    { lat: 49.1352975, lng: 18.6295264 },
    { lat: 49.1355101, lng: 18.6297184 },
    { lat: 49.1359037, lng: 18.629996 },
    { lat: 49.1360128, lng: 18.6300909 },
    { lat: 49.1360871, lng: 18.6302179 },
    { lat: 49.1361825, lng: 18.6304647 },
    { lat: 49.1362346, lng: 18.6307017 },
    { lat: 49.1363683, lng: 18.6313252 },
    { lat: 49.1364319, lng: 18.6315881 },
    { lat: 49.1366194, lng: 18.6320818 },
    { lat: 49.1368495, lng: 18.6325248 },
    { lat: 49.1369419, lng: 18.6327377 },
    { lat: 49.1370436, lng: 18.6331213 },
    { lat: 49.1371473, lng: 18.6336077 },
    { lat: 49.1373424, lng: 18.6343688 },
    { lat: 49.1374407, lng: 18.6346264 },
    { lat: 49.1375351, lng: 18.6347706 },
    { lat: 49.1376863, lng: 18.6349594 },
    { lat: 49.1379381, lng: 18.6351354 },
    { lat: 49.138266, lng: 18.6353865 },
    { lat: 49.1379972, lng: 18.6360152 },
    { lat: 49.1373665, lng: 18.6375109 },
    { lat: 49.1370427, lng: 18.6381862 },
    { lat: 49.1372698, lng: 18.6384301 },
    { lat: 49.1373959, lng: 18.6386587 },
    { lat: 49.1376314, lng: 18.6391627 },
    { lat: 49.1377621, lng: 18.6394156 },
    { lat: 49.137889, lng: 18.6398069 },
    { lat: 49.1379592, lng: 18.6402563 },
    { lat: 49.1380896, lng: 18.6405874 },
    { lat: 49.1382626, lng: 18.640833 },
    { lat: 49.1384451, lng: 18.6410164 },
    { lat: 49.1385467, lng: 18.6412733 },
    { lat: 49.138642, lng: 18.6417712 },
    { lat: 49.1387643, lng: 18.6420356 },
    { lat: 49.1390851, lng: 18.6424923 },
    { lat: 49.1394253, lng: 18.6425259 },
    { lat: 49.1396695, lng: 18.6429175 },
    { lat: 49.1399443, lng: 18.6432945 },
    { lat: 49.1402015, lng: 18.643762 },
    { lat: 49.1403879, lng: 18.6440858 },
    { lat: 49.1403587, lng: 18.6444929 },
    { lat: 49.1406515, lng: 18.6449905 },
    { lat: 49.1408066, lng: 18.6452148 },
    { lat: 49.1411456, lng: 18.6455267 },
    { lat: 49.141661, lng: 18.645865 },
    { lat: 49.142129, lng: 18.6459074 },
    { lat: 49.1423862, lng: 18.6460146 },
    { lat: 49.142721, lng: 18.6463735 },
    { lat: 49.1433484, lng: 18.64689 },
    { lat: 49.1436786, lng: 18.6472012 },
    { lat: 49.1439707, lng: 18.6474778 },
    { lat: 49.1441479, lng: 18.6476317 },
    { lat: 49.1441976, lng: 18.6481861 },
    { lat: 49.1444192, lng: 18.6489173 },
    { lat: 49.144683, lng: 18.6495101 },
    { lat: 49.1449383, lng: 18.650113 },
    { lat: 49.1450621, lng: 18.6504464 },
    { lat: 49.1451491, lng: 18.6507455 },
    { lat: 49.1452651, lng: 18.6512729 },
    { lat: 49.1453132, lng: 18.651499 },
    { lat: 49.1436384, lng: 18.6523494 },
    { lat: 49.14336, lng: 18.6526056 },
    { lat: 49.1431585, lng: 18.6525065 },
    { lat: 49.1426221, lng: 18.6527698 },
    { lat: 49.1417898, lng: 18.6533839 },
    { lat: 49.1415931, lng: 18.6536931 },
    { lat: 49.1414617, lng: 18.6540684 },
    { lat: 49.1407374, lng: 18.654295 },
    { lat: 49.1402395, lng: 18.6546474 },
    { lat: 49.1395165, lng: 18.6552589 },
    { lat: 49.1386729, lng: 18.6570424 },
    { lat: 49.1378884, lng: 18.6594756 },
    { lat: 49.1376636, lng: 18.6598487 },
    { lat: 49.1371397, lng: 18.6599881 },
    { lat: 49.1370104, lng: 18.6599965 },
    { lat: 49.1367333, lng: 18.6594043 },
    { lat: 49.1365047, lng: 18.6589841 },
    { lat: 49.1365433, lng: 18.6588832 },
    { lat: 49.1356239, lng: 18.6574121 },
    { lat: 49.1356201, lng: 18.6572559 },
    { lat: 49.1353356, lng: 18.6570591 },
    { lat: 49.134722, lng: 18.6574624 },
    { lat: 49.1347596, lng: 18.660934 },
    { lat: 49.1348626, lng: 18.6610671 },
    { lat: 49.1353857, lng: 18.6619164 },
    { lat: 49.13557, lng: 18.6623086 },
    { lat: 49.1357118, lng: 18.6626427 },
    { lat: 49.1357815, lng: 18.6630652 },
    { lat: 49.1358121, lng: 18.6637047 },
    { lat: 49.1358113, lng: 18.664903 },
    { lat: 49.1357819, lng: 18.6652398 },
    { lat: 49.1357295, lng: 18.6658081 },
    { lat: 49.1354128, lng: 18.6672387 },
    { lat: 49.1353804, lng: 18.6681348 },
    { lat: 49.1358302, lng: 18.6700736 },
    { lat: 49.1363823, lng: 18.6708917 },
    { lat: 49.1368442, lng: 18.6713942 },
    { lat: 49.1373602, lng: 18.6719182 },
    { lat: 49.1376577, lng: 18.6722342 },
    { lat: 49.1381289, lng: 18.6729989 },
    { lat: 49.1381485, lng: 18.6730253 },
    { lat: 49.1390024, lng: 18.6746477 },
    { lat: 49.1393732, lng: 18.6761801 },
    { lat: 49.140054, lng: 18.6771536 },
    { lat: 49.1405744, lng: 18.6781252 },
    { lat: 49.1405347, lng: 18.678952 },
    { lat: 49.1407448, lng: 18.6804717 },
    { lat: 49.140967, lng: 18.6815819 },
    { lat: 49.1414076, lng: 18.6826037 },
    { lat: 49.1419776, lng: 18.6838294 },
    { lat: 49.1424173, lng: 18.6844857 },
    { lat: 49.1427086, lng: 18.6859869 },
    { lat: 49.1422548, lng: 18.686835 },
    { lat: 49.141862, lng: 18.687574 },
    { lat: 49.141494, lng: 18.6884008 },
    { lat: 49.141207, lng: 18.6888592 },
    { lat: 49.1418188, lng: 18.6896612 },
    { lat: 49.1421626, lng: 18.6900859 },
    { lat: 49.1422076, lng: 18.6907226 },
    { lat: 49.1423153, lng: 18.6912284 },
    { lat: 49.1423292, lng: 18.6917069 },
    { lat: 49.1422912, lng: 18.6919672 },
    { lat: 49.1422029, lng: 18.6923905 },
    { lat: 49.142168, lng: 18.6926019 },
    { lat: 49.1422036, lng: 18.6927888 },
    { lat: 49.1422736, lng: 18.6934296 },
    { lat: 49.1423009, lng: 18.6937764 },
    { lat: 49.142375, lng: 18.6940897 },
    { lat: 49.1423563, lng: 18.6945278 },
    { lat: 49.142497, lng: 18.6950127 },
    { lat: 49.1426046, lng: 18.6953424 },
    { lat: 49.1426782, lng: 18.6957332 },
    { lat: 49.142799, lng: 18.6959716 },
    { lat: 49.1425963, lng: 18.6965969 },
    { lat: 49.1425905, lng: 18.6970867 },
    { lat: 49.1426592, lng: 18.6973405 },
    { lat: 49.1429438, lng: 18.6977775 },
    { lat: 49.1431063, lng: 18.6979957 },
    { lat: 49.1431655, lng: 18.6986483 },
    { lat: 49.1435128, lng: 18.6993097 },
    { lat: 49.1439636, lng: 18.6997541 },
    { lat: 49.1442456, lng: 18.6998842 },
    { lat: 49.1445814, lng: 18.6998985 },
    { lat: 49.1447473, lng: 18.6999027 },
    { lat: 49.1450674, lng: 18.7001578 },
    { lat: 49.1454371, lng: 18.7006658 },
    { lat: 49.145594, lng: 18.7008815 },
    { lat: 49.1457414, lng: 18.7011768 },
    { lat: 49.1458007, lng: 18.7013814 },
    { lat: 49.1460496, lng: 18.7021243 },
    { lat: 49.146251, lng: 18.7030185 },
    { lat: 49.1464108, lng: 18.7038232 },
    { lat: 49.1465198, lng: 18.7050583 },
    { lat: 49.1468261, lng: 18.7061235 },
    { lat: 49.1469859, lng: 18.7070578 },
    { lat: 49.1473975, lng: 18.7076581 },
    { lat: 49.1480376, lng: 18.7083586 },
    { lat: 49.1487202, lng: 18.7090079 },
    { lat: 49.1495642, lng: 18.7098968 },
    { lat: 49.1503733, lng: 18.7103545 },
    { lat: 49.1505763, lng: 18.7103761 },
    { lat: 49.1504471, lng: 18.7110061 },
    { lat: 49.1504729, lng: 18.7114799 },
    { lat: 49.1505401, lng: 18.7119393 },
    { lat: 49.1508232, lng: 18.7128212 },
    { lat: 49.150948, lng: 18.7136929 },
    { lat: 49.1511653, lng: 18.7139887 },
    { lat: 49.1513727, lng: 18.7144134 },
    { lat: 49.1514261, lng: 18.7145704 },
    { lat: 49.15148, lng: 18.714932 },
    { lat: 49.1516514, lng: 18.7155127 },
    { lat: 49.1517591, lng: 18.7156742 },
    { lat: 49.1518709, lng: 18.7158043 },
    { lat: 49.1519542, lng: 18.7158874 },
    { lat: 49.1521396, lng: 18.7160251 },
    { lat: 49.1522904, lng: 18.716171 },
    { lat: 49.1524357, lng: 18.7163399 },
    { lat: 49.152515, lng: 18.7164112 },
    { lat: 49.1526419, lng: 18.7165279 },
    { lat: 49.1528787, lng: 18.7166997 },
    { lat: 49.1531224, lng: 18.716853 },
    { lat: 49.1532522, lng: 18.7169178 },
    { lat: 49.1534177, lng: 18.7170223 },
    { lat: 49.1535575, lng: 18.7171295 },
    { lat: 49.1537274, lng: 18.7172907 },
    { lat: 49.1538301, lng: 18.7173786 },
    { lat: 49.1541605, lng: 18.7175966 },
    { lat: 49.15445, lng: 18.7178261 },
    { lat: 49.1545812, lng: 18.7178994 },
    { lat: 49.1548712, lng: 18.7180315 },
    { lat: 49.1551577, lng: 18.718181 },
    { lat: 49.1555764, lng: 18.7184015 },
    { lat: 49.1558156, lng: 18.7185773 },
    { lat: 49.1560246, lng: 18.7188184 },
    { lat: 49.1562519, lng: 18.7190479 },
    { lat: 49.156453, lng: 18.7192053 },
    { lat: 49.1566506, lng: 18.7193192 },
    { lat: 49.1567427, lng: 18.7192896 },
    { lat: 49.1569203, lng: 18.7192124 },
    { lat: 49.1578124, lng: 18.7183981 },
    { lat: 49.1581702, lng: 18.7182773 },
    { lat: 49.159588, lng: 18.7176621 },
    { lat: 49.1594044, lng: 18.7173192 },
    { lat: 49.1597576, lng: 18.7163151 },
    { lat: 49.1598519, lng: 18.7161801 },
    { lat: 49.1599467, lng: 18.7133927 },
    { lat: 49.1601504, lng: 18.7122699 },
    { lat: 49.1601652, lng: 18.7121874 },
    { lat: 49.1601769, lng: 18.7121261 },
    { lat: 49.1601885, lng: 18.7120681 },
    { lat: 49.1606122, lng: 18.7097482 },
    { lat: 49.1605207, lng: 18.7089699 },
    { lat: 49.1604853, lng: 18.7080872 },
    { lat: 49.1605455, lng: 18.7074514 },
    { lat: 49.1605957, lng: 18.7069466 },
    { lat: 49.1606488, lng: 18.7062223 },
    { lat: 49.1606106, lng: 18.7056898 },
    { lat: 49.1603441, lng: 18.704676 },
    { lat: 49.1593699, lng: 18.7012926 },
    { lat: 49.1592277, lng: 18.7006871 },
    { lat: 49.1590287, lng: 18.6999516 },
    { lat: 49.1589102, lng: 18.6995413 },
    { lat: 49.1589047, lng: 18.6995221 },
    { lat: 49.158899, lng: 18.6995037 },
    { lat: 49.1587408, lng: 18.6989408 },
    { lat: 49.1584213, lng: 18.6975413 },
    { lat: 49.15852, lng: 18.697122 },
    { lat: 49.1590053, lng: 18.6949695 },
    { lat: 49.1590596, lng: 18.694377 },
    { lat: 49.1591975, lng: 18.6932053 },
    { lat: 49.1595107, lng: 18.6926185 },
    { lat: 49.1593895, lng: 18.6910292 },
    { lat: 49.1596121, lng: 18.6896039 },
    { lat: 49.1597855, lng: 18.6888743 },
    { lat: 49.1597912, lng: 18.6888509 },
    { lat: 49.159819, lng: 18.6887379 },
    { lat: 49.1598612, lng: 18.6885576 },
    { lat: 49.1606596, lng: 18.6859496 },
    { lat: 49.160697, lng: 18.6852482 },
    { lat: 49.1603474, lng: 18.685246 },
    { lat: 49.1601623, lng: 18.6848378 },
    { lat: 49.1603331, lng: 18.6845675 },
    { lat: 49.1600306, lng: 18.684053 },
    { lat: 49.1595803, lng: 18.6832363 },
    { lat: 49.1590506, lng: 18.6820031 },
    { lat: 49.1598664, lng: 18.680582 },
    { lat: 49.1597922, lng: 18.6796878 },
    { lat: 49.1595679, lng: 18.6791997 },
    { lat: 49.1603496, lng: 18.6774332 },
    { lat: 49.1606439, lng: 18.6758966 },
    { lat: 49.1605132, lng: 18.6730422 },
    { lat: 49.1602828, lng: 18.6707961 },
    { lat: 49.1596118, lng: 18.6688591 },
    { lat: 49.1597669, lng: 18.6672952 },
    { lat: 49.159691, lng: 18.6662222 },
    { lat: 49.1595615, lng: 18.6656695 },
    { lat: 49.159319, lng: 18.6647535 },
    { lat: 49.1596388, lng: 18.6638951 },
    { lat: 49.1596475, lng: 18.6633146 },
    { lat: 49.1598319, lng: 18.6623244 },
    { lat: 49.1598067, lng: 18.6617877 },
    { lat: 49.1594956, lng: 18.6607022 },
    { lat: 49.1592589, lng: 18.6601725 },
    { lat: 49.1590401, lng: 18.6599818 },
    { lat: 49.1588016, lng: 18.6597795 },
    { lat: 49.1583385, lng: 18.6591715 },
    { lat: 49.1578346, lng: 18.6588361 },
    { lat: 49.157456, lng: 18.6585412 },
    { lat: 49.1572193, lng: 18.6582894 },
    { lat: 49.1564761, lng: 18.6575231 },
    { lat: 49.1561783, lng: 18.6573069 },
    { lat: 49.1558309, lng: 18.6570752 },
    { lat: 49.1553947, lng: 18.6561549 },
    { lat: 49.1553027, lng: 18.6559607 },
    { lat: 49.1555766, lng: 18.6557035 },
    { lat: 49.1559343, lng: 18.6554299 },
    { lat: 49.156749, lng: 18.6551871 },
    { lat: 49.1571961, lng: 18.6550267 },
    { lat: 49.1577168, lng: 18.654827 },
    { lat: 49.1585928, lng: 18.6545697 },
    { lat: 49.1588986, lng: 18.6544852 },
    { lat: 49.1595338, lng: 18.6543643 },
    { lat: 49.1597213, lng: 18.6549224 },
    { lat: 49.1601057, lng: 18.6546616 },
    { lat: 49.1606646, lng: 18.6541023 },
    { lat: 49.1607728, lng: 18.6538461 },
    { lat: 49.1608666, lng: 18.653766 },
    { lat: 49.1625086, lng: 18.6523829 },
    { lat: 49.1636229, lng: 18.6515326 },
    { lat: 49.1639471, lng: 18.6512071 },
    { lat: 49.1651796, lng: 18.6505025 },
    { lat: 49.1659417, lng: 18.6491803 },
  ],
  MaláČierna: [
    { lat: 49.114003, lng: 18.59042 },
    { lat: 49.113775, lng: 18.590602 },
    { lat: 49.113308, lng: 18.590121 },
    { lat: 49.113255, lng: 18.590119 },
    { lat: 49.113066, lng: 18.590114 },
    { lat: 49.112784, lng: 18.590106 },
    { lat: 49.11034, lng: 18.587609 },
    { lat: 49.109598, lng: 18.587271 },
    { lat: 49.109309, lng: 18.586874 },
    { lat: 49.108266, lng: 18.585774 },
    { lat: 49.107841, lng: 18.585232 },
    { lat: 49.107392, lng: 18.584658 },
    { lat: 49.107366, lng: 18.584609 },
    { lat: 49.107195, lng: 18.584274 },
    { lat: 49.106483, lng: 18.583413 },
    { lat: 49.106131, lng: 18.58286 },
    { lat: 49.105841, lng: 18.582205 },
    { lat: 49.10584, lng: 18.582203 },
    { lat: 49.105037, lng: 18.5809 },
    { lat: 49.104973, lng: 18.580743 },
    { lat: 49.1046979, lng: 18.5808909 },
    { lat: 49.1043981, lng: 18.5810432 },
    { lat: 49.1022255, lng: 18.5829439 },
    { lat: 49.1020752, lng: 18.5833345 },
    { lat: 49.1020075, lng: 18.5835049 },
    { lat: 49.1012175, lng: 18.5843164 },
    { lat: 49.1009757, lng: 18.5848735 },
    { lat: 49.100529, lng: 18.5855421 },
    { lat: 49.1001496, lng: 18.5865442 },
    { lat: 49.1001744, lng: 18.5866481 },
    { lat: 49.1005642, lng: 18.5877513 },
    { lat: 49.1012816, lng: 18.5882799 },
    { lat: 49.0989434, lng: 18.5911967 },
    { lat: 49.0988751, lng: 18.5912668 },
    { lat: 49.0987648, lng: 18.5914194 },
    { lat: 49.0987292, lng: 18.5914516 },
    { lat: 49.0977583, lng: 18.5964333 },
    { lat: 49.0977189, lng: 18.5965624 },
    { lat: 49.0973139, lng: 18.596201 },
    { lat: 49.0971354, lng: 18.5961343 },
    { lat: 49.0966518, lng: 18.5960754 },
    { lat: 49.0963892, lng: 18.5960141 },
    { lat: 49.0957353, lng: 18.5958507 },
    { lat: 49.0952165, lng: 18.5957673 },
    { lat: 49.0947028, lng: 18.5956721 },
    { lat: 49.0943535, lng: 18.5956594 },
    { lat: 49.0941665, lng: 18.5957241 },
    { lat: 49.094, lng: 18.5958805 },
    { lat: 49.0936931, lng: 18.5963617 },
    { lat: 49.0926933, lng: 18.5975062 },
    { lat: 49.0924725, lng: 18.597827 },
    { lat: 49.0927207, lng: 18.5983348 },
    { lat: 49.0928208, lng: 18.5986459 },
    { lat: 49.0928496, lng: 18.5990324 },
    { lat: 49.0928131, lng: 18.5993436 },
    { lat: 49.0925286, lng: 18.6006633 },
    { lat: 49.0924488, lng: 18.600982 },
    { lat: 49.0929977, lng: 18.6012707 },
    { lat: 49.0930735, lng: 18.6015305 },
    { lat: 49.0932178, lng: 18.6009145 },
    { lat: 49.0934931, lng: 18.6012369 },
    { lat: 49.0943833, lng: 18.6023149 },
    { lat: 49.0948577, lng: 18.6030002 },
    { lat: 49.0951546, lng: 18.6034298 },
    { lat: 49.0952464, lng: 18.6035634 },
    { lat: 49.0953559, lng: 18.6036491 },
    { lat: 49.0956394, lng: 18.603868 },
    { lat: 49.0961357, lng: 18.6041191 },
    { lat: 49.0962585, lng: 18.6039289 },
    { lat: 49.0963992, lng: 18.6041767 },
    { lat: 49.0964526, lng: 18.6050676 },
    { lat: 49.096459, lng: 18.6052316 },
    { lat: 49.0965683, lng: 18.606346 },
    { lat: 49.0969248, lng: 18.6070416 },
    { lat: 49.0970271, lng: 18.6073939 },
    { lat: 49.0977333, lng: 18.6078799 },
    { lat: 49.0995997, lng: 18.6090993 },
    { lat: 49.1000764, lng: 18.6089459 },
    { lat: 49.1004114, lng: 18.6090328 },
    { lat: 49.1007234, lng: 18.6089185 },
    { lat: 49.1010419, lng: 18.6089685 },
    { lat: 49.1010924, lng: 18.6091322 },
    { lat: 49.1019086, lng: 18.6091924 },
    { lat: 49.1027846, lng: 18.6111638 },
    { lat: 49.1033439, lng: 18.6127879 },
    { lat: 49.1034903, lng: 18.6123403 },
    { lat: 49.1037802, lng: 18.6117518 },
    { lat: 49.1042394, lng: 18.6108285 },
    { lat: 49.1044922, lng: 18.6103446 },
    { lat: 49.1047861, lng: 18.6097909 },
    { lat: 49.10511, lng: 18.6093069 },
    { lat: 49.1052847, lng: 18.6089069 },
    { lat: 49.105349, lng: 18.6089291 },
    { lat: 49.1058312, lng: 18.6090845 },
    { lat: 49.1066254, lng: 18.6090928 },
    { lat: 49.106875, lng: 18.6091191 },
    { lat: 49.1071606, lng: 18.6092723 },
    { lat: 49.1073485, lng: 18.6095805 },
    { lat: 49.1075378, lng: 18.6100631 },
    { lat: 49.1079466, lng: 18.6104058 },
    { lat: 49.1079931, lng: 18.6104353 },
    { lat: 49.1086908, lng: 18.6108077 },
    { lat: 49.1092161, lng: 18.6113066 },
    { lat: 49.1096311, lng: 18.6114959 },
    { lat: 49.1099261, lng: 18.6117617 },
    { lat: 49.1101582, lng: 18.611843 },
    { lat: 49.1106738, lng: 18.6120134 },
    { lat: 49.1110958, lng: 18.6120154 },
    { lat: 49.1119963, lng: 18.6110764 },
    { lat: 49.112645, lng: 18.6103863 },
    { lat: 49.1135197, lng: 18.6094721 },
    { lat: 49.1135765, lng: 18.6094175 },
    { lat: 49.1138873, lng: 18.6091135 },
    { lat: 49.1139427, lng: 18.6090933 },
    { lat: 49.1141818, lng: 18.6089902 },
    { lat: 49.1146113, lng: 18.6090265 },
    { lat: 49.1153946, lng: 18.6090985 },
    { lat: 49.1162534, lng: 18.6093411 },
    { lat: 49.116688, lng: 18.6093278 },
    { lat: 49.1168827, lng: 18.6091513 },
    { lat: 49.1173379, lng: 18.6088691 },
    { lat: 49.1183742, lng: 18.6082147 },
    { lat: 49.1190072, lng: 18.6078192 },
    { lat: 49.1195058, lng: 18.6080958 },
    { lat: 49.119571, lng: 18.6082367 },
    { lat: 49.1200402, lng: 18.6079257 },
    { lat: 49.1204459, lng: 18.6071815 },
    { lat: 49.1207004, lng: 18.6063062 },
    { lat: 49.1208428, lng: 18.6054928 },
    { lat: 49.1212902, lng: 18.6048344 },
    { lat: 49.121623, lng: 18.604836 },
    { lat: 49.121433, lng: 18.603607 },
    { lat: 49.121451, lng: 18.602868 },
    { lat: 49.120974, lng: 18.602097 },
    { lat: 49.120937, lng: 18.601569 },
    { lat: 49.120837, lng: 18.60103 },
    { lat: 49.120801, lng: 18.600124 },
    { lat: 49.120347, lng: 18.598409 },
    { lat: 49.120272, lng: 18.598161 },
    { lat: 49.119602, lng: 18.59688 },
    { lat: 49.119491, lng: 18.596469 },
    { lat: 49.119605, lng: 18.594116 },
    { lat: 49.119294, lng: 18.593968 },
    { lat: 49.11901, lng: 18.593755 },
    { lat: 49.118265, lng: 18.593575 },
    { lat: 49.117047, lng: 18.592895 },
    { lat: 49.116608, lng: 18.592996 },
    { lat: 49.116218, lng: 18.592897 },
    { lat: 49.116013, lng: 18.592692 },
    { lat: 49.115294, lng: 18.592483 },
    { lat: 49.115156, lng: 18.592404 },
    { lat: 49.114617, lng: 18.591706 },
    { lat: 49.114003, lng: 18.59042 },
  ],
  Rajec: [
    { lat: 49.019228, lng: 18.722777 },
    { lat: 49.019557, lng: 18.723775 },
    { lat: 49.019681, lng: 18.724112 },
    { lat: 49.019968, lng: 18.724891 },
    { lat: 49.020623, lng: 18.72621 },
    { lat: 49.021085, lng: 18.727265 },
    { lat: 49.021315, lng: 18.727875 },
    { lat: 49.021589, lng: 18.728699 },
    { lat: 49.021445, lng: 18.729917 },
    { lat: 49.021759, lng: 18.730914 },
    { lat: 49.022369, lng: 18.732301 },
    { lat: 49.022953, lng: 18.733358 },
    { lat: 49.022992, lng: 18.733429 },
    { lat: 49.02325, lng: 18.734046 },
    { lat: 49.023333, lng: 18.734301 },
    { lat: 49.023352, lng: 18.734359 },
    { lat: 49.023497, lng: 18.734805 },
    { lat: 49.02359, lng: 18.73502 },
    { lat: 49.0238237, lng: 18.7350458 },
    { lat: 49.023889, lng: 18.735053 },
    { lat: 49.023948, lng: 18.735059 },
    { lat: 49.02395, lng: 18.7350879 },
    { lat: 49.02396, lng: 18.735236 },
    { lat: 49.023999, lng: 18.735848 },
    { lat: 49.024413, lng: 18.736394 },
    { lat: 49.024638, lng: 18.73669 },
    { lat: 49.025384, lng: 18.737587 },
    { lat: 49.025521, lng: 18.737948 },
    { lat: 49.025693, lng: 18.738229 },
    { lat: 49.025966, lng: 18.738607 },
    { lat: 49.026182, lng: 18.739027 },
    { lat: 49.026399, lng: 18.73948 },
    { lat: 49.026701, lng: 18.740111 },
    { lat: 49.026755, lng: 18.740243 },
    { lat: 49.027309, lng: 18.740903 },
    { lat: 49.027677, lng: 18.741174 },
    { lat: 49.02824, lng: 18.741423 },
    { lat: 49.028746, lng: 18.741646 },
    { lat: 49.029367, lng: 18.741912 },
    { lat: 49.029506, lng: 18.742092 },
    { lat: 49.030745, lng: 18.743502 },
    { lat: 49.031086, lng: 18.74367 },
    { lat: 49.031334, lng: 18.743437 },
    { lat: 49.031391, lng: 18.743384 },
    { lat: 49.031684, lng: 18.743109 },
    { lat: 49.032265, lng: 18.7423 },
    { lat: 49.032882, lng: 18.741603 },
    { lat: 49.032904, lng: 18.74159 },
    { lat: 49.033627, lng: 18.741758 },
    { lat: 49.033901, lng: 18.741714 },
    { lat: 49.03407, lng: 18.741648 },
    { lat: 49.034301, lng: 18.741559 },
    { lat: 49.034487, lng: 18.741557 },
    { lat: 49.034693, lng: 18.741613 },
    { lat: 49.034871, lng: 18.741837 },
    { lat: 49.035315, lng: 18.741895 },
    { lat: 49.035461, lng: 18.741813 },
    { lat: 49.035699, lng: 18.741833 },
    { lat: 49.035818, lng: 18.741829 },
    { lat: 49.036069, lng: 18.741727 },
    { lat: 49.03646, lng: 18.741034 },
    { lat: 49.036707, lng: 18.740391 },
    { lat: 49.037137, lng: 18.739629 },
    { lat: 49.037263, lng: 18.739381 },
    { lat: 49.037679, lng: 18.738935 },
    { lat: 49.038055, lng: 18.738919 },
    { lat: 49.038169, lng: 18.739142 },
    { lat: 49.03828, lng: 18.739238 },
    { lat: 49.038438, lng: 18.739328 },
    { lat: 49.039215, lng: 18.739289 },
    { lat: 49.039413, lng: 18.739339 },
    { lat: 49.039904, lng: 18.739457 },
    { lat: 49.0404, lng: 18.739439 },
    { lat: 49.040434, lng: 18.739457 },
    { lat: 49.040939, lng: 18.739855 },
    { lat: 49.041174, lng: 18.740171 },
    { lat: 49.041502, lng: 18.740438 },
    { lat: 49.041953, lng: 18.741049 },
    { lat: 49.042101, lng: 18.741118 },
    { lat: 49.042215, lng: 18.741069 },
    { lat: 49.042792, lng: 18.74082 },
    { lat: 49.043478, lng: 18.740522 },
    { lat: 49.043928, lng: 18.74044 },
    { lat: 49.044165, lng: 18.74043 },
    { lat: 49.044589, lng: 18.740259 },
    { lat: 49.045033, lng: 18.740118 },
    { lat: 49.045303, lng: 18.740012 },
    { lat: 49.045544, lng: 18.739792 },
    { lat: 49.045953, lng: 18.739455 },
    { lat: 49.046467, lng: 18.738743 },
    { lat: 49.046703, lng: 18.738506 },
    { lat: 49.046876, lng: 18.738407 },
    { lat: 49.047425, lng: 18.738093 },
    { lat: 49.048298, lng: 18.737805 },
    { lat: 49.048613, lng: 18.737632 },
    { lat: 49.048863, lng: 18.737386 },
    { lat: 49.049687, lng: 18.736921 },
    { lat: 49.050093, lng: 18.736696 },
    { lat: 49.050379, lng: 18.736715 },
    { lat: 49.050751, lng: 18.736871 },
    { lat: 49.05197, lng: 18.73727 },
    { lat: 49.052026, lng: 18.737288 },
    { lat: 49.052119, lng: 18.737319 },
    { lat: 49.05267, lng: 18.737243 },
    { lat: 49.052779, lng: 18.7369407 },
    { lat: 49.053375, lng: 18.7360055 },
    { lat: 49.0537379, lng: 18.7354861 },
    { lat: 49.0538429, lng: 18.7353396 },
    { lat: 49.053965, lng: 18.735063 },
    { lat: 49.0540567, lng: 18.734857 },
    { lat: 49.0541207, lng: 18.7347161 },
    { lat: 49.0543444, lng: 18.7344312 },
    { lat: 49.0546592, lng: 18.7340885 },
    { lat: 49.0549866, lng: 18.7337531 },
    { lat: 49.0555494, lng: 18.7333648 },
    { lat: 49.0558611, lng: 18.7331816 },
    { lat: 49.0560874, lng: 18.7332206 },
    { lat: 49.056428, lng: 18.7327665 },
    { lat: 49.0564003, lng: 18.7324337 },
    { lat: 49.0566677, lng: 18.7319226 },
    { lat: 49.0568159, lng: 18.7311139 },
    { lat: 49.057133, lng: 18.7298942 },
    { lat: 49.0573358, lng: 18.7292231 },
    { lat: 49.0575498, lng: 18.7282323 },
    { lat: 49.0578635, lng: 18.7279554 },
    { lat: 49.05831, lng: 18.727379 },
    { lat: 49.0582941, lng: 18.7270757 },
    { lat: 49.0586973, lng: 18.7262906 },
    { lat: 49.0590011, lng: 18.7256995 },
    { lat: 49.0590715, lng: 18.725623 },
    { lat: 49.059546, lng: 18.7251046 },
    { lat: 49.0598648, lng: 18.7242823 },
    { lat: 49.0601301, lng: 18.7237067 },
    { lat: 49.0604882, lng: 18.7226476 },
    { lat: 49.0608419, lng: 18.7209662 },
    { lat: 49.0613008, lng: 18.7208172 },
    { lat: 49.0615241, lng: 18.7206466 },
    { lat: 49.0622688, lng: 18.7199481 },
    { lat: 49.06471, lng: 18.7177128 },
    { lat: 49.0649022, lng: 18.7177649 },
    { lat: 49.0675642, lng: 18.7157175 },
    { lat: 49.0665763, lng: 18.7127209 },
    { lat: 49.0661316, lng: 18.7118142 },
    { lat: 49.0664521, lng: 18.7080475 },
    { lat: 49.0657501, lng: 18.7073093 },
    { lat: 49.0662001, lng: 18.7061765 },
    { lat: 49.0655569, lng: 18.7034747 },
    { lat: 49.0655938, lng: 18.7023787 },
    { lat: 49.0644685, lng: 18.7005461 },
    { lat: 49.0644951, lng: 18.7004505 },
    { lat: 49.0647809, lng: 18.699432 },
    { lat: 49.0649912, lng: 18.6986111 },
    { lat: 49.0650479, lng: 18.6980831 },
    { lat: 49.065035, lng: 18.6979455 },
    { lat: 49.0650176, lng: 18.6977013 },
    { lat: 49.0649028, lng: 18.6967839 },
    { lat: 49.0649386, lng: 18.6963983 },
    { lat: 49.0650174, lng: 18.6953709 },
    { lat: 49.065092, lng: 18.6949661 },
    { lat: 49.0652105, lng: 18.694605 },
    { lat: 49.0653455, lng: 18.6942587 },
    { lat: 49.0654835, lng: 18.6939454 },
    { lat: 49.0656968, lng: 18.693286 },
    { lat: 49.0657571, lng: 18.6931467 },
    { lat: 49.0658488, lng: 18.6930049 },
    { lat: 49.065876, lng: 18.6929656 },
    { lat: 49.066064, lng: 18.6927024 },
    { lat: 49.0662027, lng: 18.6925502 },
    { lat: 49.0663068, lng: 18.6924723 },
    { lat: 49.066464, lng: 18.6923977 },
    { lat: 49.0666246, lng: 18.6923201 },
    { lat: 49.0666856, lng: 18.6922803 },
    { lat: 49.0668296, lng: 18.6921652 },
    { lat: 49.0669738, lng: 18.6919922 },
    { lat: 49.0673479, lng: 18.6914731 },
    { lat: 49.0676285, lng: 18.6909342 },
    { lat: 49.0678288, lng: 18.690458 },
    { lat: 49.0680534, lng: 18.6901431 },
    { lat: 49.0682895, lng: 18.6899369 },
    { lat: 49.0688015, lng: 18.6896591 },
    { lat: 49.06885, lng: 18.6895755 },
    { lat: 49.0692482, lng: 18.6889355 },
    { lat: 49.069453, lng: 18.6887788 },
    { lat: 49.0699027, lng: 18.688518 },
    { lat: 49.0701023, lng: 18.6883698 },
    { lat: 49.0701957, lng: 18.6881925 },
    { lat: 49.0704035, lng: 18.6876431 },
    { lat: 49.0709109, lng: 18.6862987 },
    { lat: 49.0709829, lng: 18.686106 },
    { lat: 49.0711184, lng: 18.6858308 },
    { lat: 49.0712291, lng: 18.6856615 },
    { lat: 49.0715117, lng: 18.6853762 },
    { lat: 49.0716891, lng: 18.6852768 },
    { lat: 49.0719955, lng: 18.6851439 },
    { lat: 49.0727645, lng: 18.6848425 },
    { lat: 49.072705, lng: 18.68457 },
    { lat: 49.0723983, lng: 18.6840788 },
    { lat: 49.0718045, lng: 18.6835175 },
    { lat: 49.070697, lng: 18.6827914 },
    { lat: 49.0680826, lng: 18.6811434 },
    { lat: 49.0678329, lng: 18.6806226 },
    { lat: 49.0671646, lng: 18.6801626 },
    { lat: 49.0671171, lng: 18.6802029 },
    { lat: 49.0663502, lng: 18.6808623 },
    { lat: 49.0657373, lng: 18.681319 },
    { lat: 49.0649171, lng: 18.6818874 },
    { lat: 49.062631, lng: 18.6835029 },
    { lat: 49.0623931, lng: 18.6835892 },
    { lat: 49.0620839, lng: 18.6838418 },
    { lat: 49.0613362, lng: 18.6842269 },
    { lat: 49.0609743, lng: 18.6844208 },
    { lat: 49.0607545, lng: 18.6850181 },
    { lat: 49.0599767, lng: 18.6855801 },
    { lat: 49.0592785, lng: 18.6863237 },
    { lat: 49.0585262, lng: 18.6869379 },
    { lat: 49.0578041, lng: 18.6872533 },
    { lat: 49.057218, lng: 18.6875336 },
    { lat: 49.0566108, lng: 18.6885899 },
    { lat: 49.0563748, lng: 18.6891819 },
    { lat: 49.0561576, lng: 18.6900258 },
    { lat: 49.0553971, lng: 18.6904414 },
    { lat: 49.0541779, lng: 18.6916344 },
    { lat: 49.0538166, lng: 18.6917326 },
    { lat: 49.0533495, lng: 18.6921137 },
    { lat: 49.0532699, lng: 18.692401 },
    { lat: 49.053017, lng: 18.6925441 },
    { lat: 49.0527236, lng: 18.6926264 },
    { lat: 49.051364, lng: 18.6932946 },
    { lat: 49.0511717, lng: 18.6934127 },
    { lat: 49.050907, lng: 18.6934386 },
    { lat: 49.050429, lng: 18.6934215 },
    { lat: 49.049731, lng: 18.6937223 },
    { lat: 49.0491898, lng: 18.6939358 },
    { lat: 49.0490861, lng: 18.6938295 },
    { lat: 49.0487345, lng: 18.6937287 },
    { lat: 49.0481024, lng: 18.6936536 },
    { lat: 49.0478034, lng: 18.6937362 },
    { lat: 49.0475035, lng: 18.6938663 },
    { lat: 49.0468403, lng: 18.6937562 },
    { lat: 49.0462203, lng: 18.693571 },
    { lat: 49.0456167, lng: 18.6933504 },
    { lat: 49.0454528, lng: 18.693236 },
    { lat: 49.0451775, lng: 18.6933437 },
    { lat: 49.0448185, lng: 18.6935709 },
    { lat: 49.0438702, lng: 18.694362 },
    { lat: 49.0435943, lng: 18.6947308 },
    { lat: 49.0431368, lng: 18.6952724 },
    { lat: 49.0429808, lng: 18.6954996 },
    { lat: 49.0427489, lng: 18.6956559 },
    { lat: 49.0421089, lng: 18.6964153 },
    { lat: 49.041888, lng: 18.6966706 },
    { lat: 49.0410792, lng: 18.697777 },
    { lat: 49.0408054, lng: 18.6981462 },
    { lat: 49.0397935, lng: 18.6979157 },
    { lat: 49.0385065, lng: 18.6976226 },
    { lat: 49.0377448, lng: 18.6982746 },
    { lat: 49.0370194, lng: 18.6984183 },
    { lat: 49.0364694, lng: 18.6988337 },
    { lat: 49.0358493, lng: 18.6992182 },
    { lat: 49.03438, lng: 18.6991576 },
    { lat: 49.0340219, lng: 18.6993796 },
    { lat: 49.033334, lng: 18.6995941 },
    { lat: 49.0329614, lng: 18.6997974 },
    { lat: 49.0314667, lng: 18.7003642 },
    { lat: 49.0306374, lng: 18.7004654 },
    { lat: 49.0300211, lng: 18.7007531 },
    { lat: 49.0298867, lng: 18.7011127 },
    { lat: 49.0299142, lng: 18.7015481 },
    { lat: 49.0298445, lng: 18.7018517 },
    { lat: 49.0301535, lng: 18.7040299 },
    { lat: 49.0302345, lng: 18.7058209 },
    { lat: 49.0300519, lng: 18.7065529 },
    { lat: 49.029954, lng: 18.7081152 },
    { lat: 49.0297531, lng: 18.7095254 },
    { lat: 49.0294821, lng: 18.7108001 },
    { lat: 49.029391, lng: 18.71147 },
    { lat: 49.0291337, lng: 18.7120637 },
    { lat: 49.0289939, lng: 18.7125814 },
    { lat: 49.0286007, lng: 18.7137649 },
    { lat: 49.0275765, lng: 18.7144416 },
    { lat: 49.0264138, lng: 18.7145491 },
    { lat: 49.0255329, lng: 18.7146119 },
    { lat: 49.0251331, lng: 18.7146434 },
    { lat: 49.0248537, lng: 18.7147324 },
    { lat: 49.0244107, lng: 18.7149714 },
    { lat: 49.0238968, lng: 18.7153222 },
    { lat: 49.0233025, lng: 18.7157718 },
    { lat: 49.0226944, lng: 18.7169019 },
    { lat: 49.0220777, lng: 18.7185548 },
    { lat: 49.0217496, lng: 18.719603 },
    { lat: 49.0212334, lng: 18.7201663 },
    { lat: 49.0187041, lng: 18.7214754 },
    { lat: 49.0185833, lng: 18.7222651 },
    { lat: 49.019228, lng: 18.722777 },
  ],
  RajecExt: [
    { lat: 49.0939331, lng: 18.6591505 },
    { lat: 49.0938257, lng: 18.6590414 },
    { lat: 49.0939159, lng: 18.6587417 },
    { lat: 49.0939301, lng: 18.6585643 },
    { lat: 49.0939236, lng: 18.6583136 },
    { lat: 49.0939288, lng: 18.6582237 },
    { lat: 49.0940497, lng: 18.6576521 },
    { lat: 49.0943031, lng: 18.6565399 },
    { lat: 49.0943107, lng: 18.6564522 },
    { lat: 49.094505, lng: 18.655652 },
    { lat: 49.0946716, lng: 18.655114 },
    { lat: 49.0948535, lng: 18.6545695 },
    { lat: 49.094914, lng: 18.6543252 },
    { lat: 49.0949134, lng: 18.6542072 },
    { lat: 49.0947889, lng: 18.6538379 },
    { lat: 49.0945906, lng: 18.6535053 },
    { lat: 49.0944623, lng: 18.6531487 },
    { lat: 49.094307, lng: 18.6526683 },
    { lat: 49.0954284, lng: 18.6502093 },
    { lat: 49.0960113, lng: 18.6482163 },
    { lat: 49.0966126, lng: 18.6463521 },
    { lat: 49.0969302, lng: 18.6464104 },
    { lat: 49.0973378, lng: 18.6465236 },
    { lat: 49.0975166, lng: 18.6465361 },
    { lat: 49.0977824, lng: 18.6466325 },
    { lat: 49.0979248, lng: 18.6467466 },
    { lat: 49.0980365, lng: 18.6462599 },
    { lat: 49.0980508, lng: 18.6460854 },
    { lat: 49.0982252, lng: 18.6454812 },
    { lat: 49.0983122, lng: 18.6450595 },
    { lat: 49.0983541, lng: 18.6448524 },
    { lat: 49.0983737, lng: 18.6447543 },
    { lat: 49.0984016, lng: 18.6446198 },
    { lat: 49.0984143, lng: 18.6442022 },
    { lat: 49.0984161, lng: 18.6441512 },
    { lat: 49.0984148, lng: 18.6441266 },
    { lat: 49.098421, lng: 18.6437778 },
    { lat: 49.0984232, lng: 18.6436944 },
    { lat: 49.0984261, lng: 18.6436191 },
    { lat: 49.0984453, lng: 18.6429643 },
    { lat: 49.0984585, lng: 18.6425765 },
    { lat: 49.0984627, lng: 18.6424647 },
    { lat: 49.0984503, lng: 18.6421247 },
    { lat: 49.0984175, lng: 18.641256 },
    { lat: 49.098442, lng: 18.640423 },
    { lat: 49.0984561, lng: 18.6401168 },
    { lat: 49.0984785, lng: 18.6395825 },
    { lat: 49.0985072, lng: 18.6389135 },
    { lat: 49.098572, lng: 18.6379694 },
    { lat: 49.0986148, lng: 18.6372921 },
    { lat: 49.0986703, lng: 18.6364079 },
    { lat: 49.0987306, lng: 18.6354431 },
    { lat: 49.0987852, lng: 18.6346265 },
    { lat: 49.0987946, lng: 18.6344659 },
    { lat: 49.0988415, lng: 18.6337576 },
    { lat: 49.0987851, lng: 18.6336318 },
    { lat: 49.098834, lng: 18.6328995 },
    { lat: 49.0988512, lng: 18.6326508 },
    { lat: 49.0989039, lng: 18.6323715 },
    { lat: 49.0989348, lng: 18.6319131 },
    { lat: 49.0989418, lng: 18.63181 },
    { lat: 49.098984, lng: 18.6312484 },
    { lat: 49.0990083, lng: 18.6311947 },
    { lat: 49.0990249, lng: 18.6308734 },
    { lat: 49.0990272, lng: 18.6308346 },
    { lat: 49.0990366, lng: 18.6306722 },
    { lat: 49.0990205, lng: 18.6304759 },
    { lat: 49.0989595, lng: 18.6302797 },
    { lat: 49.0988025, lng: 18.629769 },
    { lat: 49.0987956, lng: 18.6292181 },
    { lat: 49.0987903, lng: 18.6287793 },
    { lat: 49.0987969, lng: 18.628649 },
    { lat: 49.0988174, lng: 18.6282095 },
    { lat: 49.0988357, lng: 18.6279589 },
    { lat: 49.0988475, lng: 18.6277987 },
    { lat: 49.0989058, lng: 18.6274967 },
    { lat: 49.0992782, lng: 18.6252639 },
    { lat: 49.0995575, lng: 18.6240509 },
    { lat: 49.0998198, lng: 18.6228596 },
    { lat: 49.1000944, lng: 18.6218142 },
    { lat: 49.1001989, lng: 18.6212794 },
    { lat: 49.1001833, lng: 18.6212403 },
    { lat: 49.1004379, lng: 18.6208128 },
    { lat: 49.1008836, lng: 18.6200558 },
    { lat: 49.1011151, lng: 18.6195135 },
    { lat: 49.1013224, lng: 18.6194489 },
    { lat: 49.1015787, lng: 18.6192429 },
    { lat: 49.1020546, lng: 18.6186711 },
    { lat: 49.1021294, lng: 18.6184642 },
    { lat: 49.1021876, lng: 18.6182885 },
    { lat: 49.1022804, lng: 18.6178109 },
    { lat: 49.1024795, lng: 18.6171891 },
    { lat: 49.1024937, lng: 18.6164592 },
    { lat: 49.102695, lng: 18.6152702 },
    { lat: 49.1030104, lng: 18.6136582 },
    { lat: 49.1029141, lng: 18.6134171 },
    { lat: 49.1031296, lng: 18.6130812 },
    { lat: 49.1033439, lng: 18.6127879 },
    { lat: 49.1027846, lng: 18.6111638 },
    { lat: 49.1019086, lng: 18.6091924 },
    { lat: 49.1010924, lng: 18.6091322 },
    { lat: 49.1010419, lng: 18.6089685 },
    { lat: 49.1007234, lng: 18.6089185 },
    { lat: 49.1004114, lng: 18.6090328 },
    { lat: 49.1000764, lng: 18.6089459 },
    { lat: 49.0995997, lng: 18.6090993 },
    { lat: 49.0977333, lng: 18.6078799 },
    { lat: 49.0970271, lng: 18.6073939 },
    { lat: 49.0965161, lng: 18.6071806 },
    { lat: 49.0960713, lng: 18.6071483 },
    { lat: 49.0950563, lng: 18.6071318 },
    { lat: 49.0944818, lng: 18.6072082 },
    { lat: 49.0930187, lng: 18.6065467 },
    { lat: 49.0928611, lng: 18.6064065 },
    { lat: 49.0919757, lng: 18.606276 },
    { lat: 49.091934, lng: 18.6073734 },
    { lat: 49.091619, lng: 18.6083981 },
    { lat: 49.0914272, lng: 18.6084615 },
    { lat: 49.0910413, lng: 18.6085627 },
    { lat: 49.0903015, lng: 18.6083112 },
    { lat: 49.0899788, lng: 18.6088561 },
    { lat: 49.0887265, lng: 18.6102598 },
    { lat: 49.0885246, lng: 18.6106609 },
    { lat: 49.0881434, lng: 18.6111461 },
    { lat: 49.0879471, lng: 18.6108081 },
    { lat: 49.08799, lng: 18.6101126 },
    { lat: 49.0881142, lng: 18.6093552 },
    { lat: 49.0879221, lng: 18.6066501 },
    { lat: 49.0863802, lng: 18.6065037 },
    { lat: 49.0862728, lng: 18.6061791 },
    { lat: 49.0862841, lng: 18.6061004 },
    { lat: 49.0863239, lng: 18.6059994 },
    { lat: 49.0862911, lng: 18.6059351 },
    { lat: 49.0862747, lng: 18.6059022 },
    { lat: 49.0862314, lng: 18.6058298 },
    { lat: 49.0861916, lng: 18.6057625 },
    { lat: 49.0861472, lng: 18.6056877 },
    { lat: 49.0860578, lng: 18.6055362 },
    { lat: 49.0858065, lng: 18.6049698 },
    { lat: 49.0859509, lng: 18.6047197 },
    { lat: 49.0859145, lng: 18.6039165 },
    { lat: 49.0860728, lng: 18.6034557 },
    { lat: 49.0862756, lng: 18.603369 },
    { lat: 49.0865454, lng: 18.6022376 },
    { lat: 49.0868942, lng: 18.6019899 },
    { lat: 49.0870398, lng: 18.6013425 },
    { lat: 49.0877447, lng: 18.6006892 },
    { lat: 49.088196, lng: 18.6001877 },
    { lat: 49.0885211, lng: 18.5993096 },
    { lat: 49.0885903, lng: 18.5993391 },
    { lat: 49.0887517, lng: 18.5988149 },
    { lat: 49.0888077, lng: 18.5986155 },
    { lat: 49.0889859, lng: 18.598092 },
    { lat: 49.0892888, lng: 18.597284 },
    { lat: 49.0884078, lng: 18.5964169 },
    { lat: 49.0876043, lng: 18.5957169 },
    { lat: 49.0872411, lng: 18.5955063 },
    { lat: 49.0867756, lng: 18.5952349 },
    { lat: 49.0858781, lng: 18.5949068 },
    { lat: 49.0856939, lng: 18.5948337 },
    { lat: 49.0854016, lng: 18.5942839 },
    { lat: 49.0853926, lng: 18.5942667 },
    { lat: 49.0853449, lng: 18.5942363 },
    { lat: 49.0852935, lng: 18.5942034 },
    { lat: 49.0852282, lng: 18.5941626 },
    { lat: 49.084858, lng: 18.593928 },
    { lat: 49.0847539, lng: 18.5937143 },
    { lat: 49.084474, lng: 18.5929723 },
    { lat: 49.0843994, lng: 18.5927585 },
    { lat: 49.0843168, lng: 18.5924838 },
    { lat: 49.0843083, lng: 18.5924265 },
    { lat: 49.0842999, lng: 18.5923751 },
    { lat: 49.0842582, lng: 18.5921277 },
    { lat: 49.0843499, lng: 18.5917316 },
    { lat: 49.0847418, lng: 18.5915558 },
    { lat: 49.0849214, lng: 18.5912874 },
    { lat: 49.0852046, lng: 18.5908653 },
    { lat: 49.0852766, lng: 18.5907578 },
    { lat: 49.085267, lng: 18.5907464 },
    { lat: 49.085238, lng: 18.5907122 },
    { lat: 49.084809, lng: 18.5902011 },
    { lat: 49.0841244, lng: 18.5887099 },
    { lat: 49.0833163, lng: 18.5873548 },
    { lat: 49.0826389, lng: 18.5865875 },
    { lat: 49.082181, lng: 18.586448 },
    { lat: 49.082169, lng: 18.586593 },
    { lat: 49.082088, lng: 18.58665 },
    { lat: 49.081979, lng: 18.587295 },
    { lat: 49.081942, lng: 18.587504 },
    { lat: 49.081547, lng: 18.587662 },
    { lat: 49.081455, lng: 18.587699 },
    { lat: 49.081343, lng: 18.588162 },
    { lat: 49.0813, lng: 18.588336 },
    { lat: 49.081071, lng: 18.588346 },
    { lat: 49.081058, lng: 18.588347 },
    { lat: 49.080888, lng: 18.589461 },
    { lat: 49.080877, lng: 18.589531 },
    { lat: 49.080679, lng: 18.589579 },
    { lat: 49.079618, lng: 18.589336 },
    { lat: 49.079478, lng: 18.589265 },
    { lat: 49.079144, lng: 18.589095 },
    { lat: 49.078593, lng: 18.588816 },
    { lat: 49.078582, lng: 18.58881 },
    { lat: 49.078122, lng: 18.58945 },
    { lat: 49.078068, lng: 18.589558 },
    { lat: 49.077671, lng: 18.590354 },
    { lat: 49.076903, lng: 18.589839 },
    { lat: 49.076864, lng: 18.589908 },
    { lat: 49.076687, lng: 18.59023 },
    { lat: 49.076202, lng: 18.591103 },
    { lat: 49.076034, lng: 18.590996 },
    { lat: 49.075908, lng: 18.591205 },
    { lat: 49.075804, lng: 18.591376 },
    { lat: 49.075802, lng: 18.591382 },
    { lat: 49.075774, lng: 18.591466 },
    { lat: 49.075736, lng: 18.591554 },
    { lat: 49.075634, lng: 18.591751 },
    { lat: 49.075208, lng: 18.592266 },
    { lat: 49.074795, lng: 18.593004 },
    { lat: 49.07476, lng: 18.593068 },
    { lat: 49.074576, lng: 18.593621 },
    { lat: 49.074381, lng: 18.593964 },
    { lat: 49.074338, lng: 18.594041 },
    { lat: 49.073868, lng: 18.594867 },
    { lat: 49.07385, lng: 18.595069 },
    { lat: 49.073796, lng: 18.595678 },
    { lat: 49.073666, lng: 18.595812 },
    { lat: 49.073523, lng: 18.595958 },
    { lat: 49.073157, lng: 18.596576 },
    { lat: 49.073112, lng: 18.596759 },
    { lat: 49.072899, lng: 18.597657 },
    { lat: 49.072743, lng: 18.598923 },
    { lat: 49.072581, lng: 18.598844 },
    { lat: 49.072285, lng: 18.598809 },
    { lat: 49.071062, lng: 18.598318 },
    { lat: 49.069788, lng: 18.597901 },
    { lat: 49.069768, lng: 18.597895 },
    { lat: 49.069471, lng: 18.598654 },
    { lat: 49.068937, lng: 18.600021 },
    { lat: 49.068711, lng: 18.600292 },
    { lat: 49.068513, lng: 18.60046 },
    { lat: 49.068378, lng: 18.60057 },
    { lat: 49.067652, lng: 18.600978 },
    { lat: 49.067215, lng: 18.601223 },
    { lat: 49.067147, lng: 18.601265 },
    { lat: 49.067107, lng: 18.601289 },
    { lat: 49.066794, lng: 18.601483 },
    { lat: 49.06651, lng: 18.601659 },
    { lat: 49.06609, lng: 18.601918 },
    { lat: 49.066085, lng: 18.60192 },
    { lat: 49.064555, lng: 18.602318 },
    { lat: 49.064028, lng: 18.602454 },
    { lat: 49.063704, lng: 18.602539 },
    { lat: 49.063496, lng: 18.602593 },
    { lat: 49.063225, lng: 18.602664 },
    { lat: 49.063121, lng: 18.601945 },
    { lat: 49.062959, lng: 18.601322 },
    { lat: 49.062881, lng: 18.601183 },
    { lat: 49.062873, lng: 18.601168 },
    { lat: 49.062655, lng: 18.600781 },
    { lat: 49.062295, lng: 18.600414 },
    { lat: 49.061794, lng: 18.600019 },
    { lat: 49.061658, lng: 18.599912 },
    { lat: 49.061305, lng: 18.599731 },
    { lat: 49.060989, lng: 18.599608 },
    { lat: 49.060572, lng: 18.59949 },
    { lat: 49.060158, lng: 18.599476 },
    { lat: 49.060119, lng: 18.599504 },
    { lat: 49.05977, lng: 18.599757 },
    { lat: 49.059729, lng: 18.599786 },
    { lat: 49.059438, lng: 18.599846 },
    { lat: 49.058532, lng: 18.600032 },
    { lat: 49.058452, lng: 18.600048 },
    { lat: 49.057764, lng: 18.599887 },
    { lat: 49.057717, lng: 18.599875 },
    { lat: 49.057135, lng: 18.600153 },
    { lat: 49.0570227, lng: 18.6002477 },
    { lat: 49.056673, lng: 18.600563 },
    { lat: 49.055845, lng: 18.601382 },
    { lat: 49.054757, lng: 18.602278 },
    { lat: 49.054434, lng: 18.602543 },
    { lat: 49.054172, lng: 18.602759 },
    { lat: 49.054127, lng: 18.602795 },
    { lat: 49.053638, lng: 18.602621 },
    { lat: 49.053355, lng: 18.602481 },
    { lat: 49.052841, lng: 18.602322 },
    { lat: 49.052634, lng: 18.602434 },
    { lat: 49.0530003, lng: 18.6067141 },
    { lat: 49.0547278, lng: 18.6086278 },
    { lat: 49.0559849, lng: 18.6097963 },
    { lat: 49.0563141, lng: 18.6098799 },
    { lat: 49.0575867, lng: 18.6102015 },
    { lat: 49.0577237, lng: 18.6103193 },
    { lat: 49.0584251, lng: 18.6111576 },
    { lat: 49.0591095, lng: 18.611281 },
    { lat: 49.0592821, lng: 18.6113477 },
    { lat: 49.0602624, lng: 18.6117275 },
    { lat: 49.0619522, lng: 18.6134625 },
    { lat: 49.0620309, lng: 18.6135021 },
    { lat: 49.0622721, lng: 18.6136236 },
    { lat: 49.0624552, lng: 18.613716 },
    { lat: 49.0626372, lng: 18.6138081 },
    { lat: 49.0629259, lng: 18.6139532 },
    { lat: 49.0630105, lng: 18.6139964 },
    { lat: 49.0631009, lng: 18.6140423 },
    { lat: 49.0631241, lng: 18.6140535 },
    { lat: 49.0631307, lng: 18.6140304 },
    { lat: 49.0631422, lng: 18.613993 },
    { lat: 49.0630184, lng: 18.6134915 },
    { lat: 49.0628213, lng: 18.6130541 },
    { lat: 49.0630741, lng: 18.6128455 },
    { lat: 49.0636129, lng: 18.6143587 },
    { lat: 49.0637919, lng: 18.6157035 },
    { lat: 49.0638511, lng: 18.6168503 },
    { lat: 49.063891, lng: 18.6175963 },
    { lat: 49.0637435, lng: 18.6184044 },
    { lat: 49.0637405, lng: 18.6184936 },
    { lat: 49.0637399, lng: 18.6187127 },
    { lat: 49.0637495, lng: 18.6187453 },
    { lat: 49.0637586, lng: 18.6187881 },
    { lat: 49.0638147, lng: 18.6190814 },
    { lat: 49.0638326, lng: 18.6192047 },
    { lat: 49.0640719, lng: 18.6197771 },
    { lat: 49.0644689, lng: 18.6205138 },
    { lat: 49.0645059, lng: 18.6205963 },
    { lat: 49.0646078, lng: 18.6209645 },
    { lat: 49.0647318, lng: 18.6217182 },
    { lat: 49.064821, lng: 18.622061 },
    { lat: 49.0649113, lng: 18.6224419 },
    { lat: 49.0652956, lng: 18.6233661 },
    { lat: 49.0655986, lng: 18.6237383 },
    { lat: 49.0656559, lng: 18.6237312 },
    { lat: 49.0657061, lng: 18.6237242 },
    { lat: 49.0657321, lng: 18.6237243 },
    { lat: 49.06575, lng: 18.6237231 },
    { lat: 49.066105, lng: 18.623781 },
    { lat: 49.0661889, lng: 18.6237691 },
    { lat: 49.0663116, lng: 18.6237421 },
    { lat: 49.0664319, lng: 18.6236945 },
    { lat: 49.06644, lng: 18.6235888 },
    { lat: 49.0664415, lng: 18.6231354 },
    { lat: 49.0666875, lng: 18.6230104 },
    { lat: 49.0672044, lng: 18.6227425 },
    { lat: 49.0676521, lng: 18.6225094 },
    { lat: 49.0675405, lng: 18.6232922 },
    { lat: 49.0675491, lng: 18.6233681 },
    { lat: 49.0680472, lng: 18.6232338 },
    { lat: 49.0684734, lng: 18.6233605 },
    { lat: 49.0685847, lng: 18.6234345 },
    { lat: 49.0688627, lng: 18.6236357 },
    { lat: 49.0689143, lng: 18.6236718 },
    { lat: 49.0689767, lng: 18.6237036 },
    { lat: 49.0694188, lng: 18.6235733 },
    { lat: 49.0698691, lng: 18.6234345 },
    { lat: 49.070002, lng: 18.6234087 },
    { lat: 49.0701302, lng: 18.6234231 },
    { lat: 49.0703567, lng: 18.6234386 },
    { lat: 49.0705547, lng: 18.6234507 },
    { lat: 49.0708744, lng: 18.6233792 },
    { lat: 49.0711217, lng: 18.6234878 },
    { lat: 49.0714174, lng: 18.6237454 },
    { lat: 49.0716369, lng: 18.6242441 },
    { lat: 49.0719189, lng: 18.624861 },
    { lat: 49.0719418, lng: 18.6249269 },
    { lat: 49.0719773, lng: 18.6250423 },
    { lat: 49.0719877, lng: 18.6253036 },
    { lat: 49.0719951, lng: 18.6253573 },
    { lat: 49.0720027, lng: 18.6254157 },
    { lat: 49.0720098, lng: 18.6254605 },
    { lat: 49.0720445, lng: 18.6254552 },
    { lat: 49.0720779, lng: 18.6254485 },
    { lat: 49.0721027, lng: 18.6254464 },
    { lat: 49.0723428, lng: 18.6254258 },
    { lat: 49.072434, lng: 18.6254238 },
    { lat: 49.0724882, lng: 18.6254226 },
    { lat: 49.0726866, lng: 18.6254013 },
    { lat: 49.0730731, lng: 18.6253516 },
    { lat: 49.0732874, lng: 18.6253465 },
    { lat: 49.0733906, lng: 18.625344 },
    { lat: 49.0735796, lng: 18.6254627 },
    { lat: 49.0734817, lng: 18.625886 },
    { lat: 49.0734015, lng: 18.6263053 },
    { lat: 49.0733839, lng: 18.626401 },
    { lat: 49.0733308, lng: 18.6263741 },
    { lat: 49.0732011, lng: 18.6271819 },
    { lat: 49.0731092, lng: 18.6277389 },
    { lat: 49.0730956, lng: 18.6278431 },
    { lat: 49.0730725, lng: 18.6280032 },
    { lat: 49.0730366, lng: 18.62824 },
    { lat: 49.0730237, lng: 18.6283114 },
    { lat: 49.0729511, lng: 18.6288103 },
    { lat: 49.0730457, lng: 18.6288115 },
    { lat: 49.0731868, lng: 18.6288131 },
    { lat: 49.0732635, lng: 18.6288145 },
    { lat: 49.07343, lng: 18.6288874 },
    { lat: 49.0736632, lng: 18.6289898 },
    { lat: 49.0737985, lng: 18.6291994 },
    { lat: 49.0738057, lng: 18.62921 },
    { lat: 49.0738693, lng: 18.6294277 },
    { lat: 49.0738654, lng: 18.6296285 },
    { lat: 49.0738346, lng: 18.629624 },
    { lat: 49.0738163, lng: 18.6297889 },
    { lat: 49.0738357, lng: 18.6297955 },
    { lat: 49.0742322, lng: 18.6299087 },
    { lat: 49.0744385, lng: 18.629977 },
    { lat: 49.0744903, lng: 18.6299941 },
    { lat: 49.0745812, lng: 18.6300278 },
    { lat: 49.0747267, lng: 18.6300706 },
    { lat: 49.0750503, lng: 18.6301723 },
    { lat: 49.0752575, lng: 18.6302361 },
    { lat: 49.0753828, lng: 18.6302747 },
    { lat: 49.0756034, lng: 18.6303743 },
    { lat: 49.0756613, lng: 18.6304004 },
    { lat: 49.0757766, lng: 18.6304484 },
    { lat: 49.0759986, lng: 18.6305475 },
    { lat: 49.0760784, lng: 18.6305862 },
    { lat: 49.0761116, lng: 18.6306024 },
    { lat: 49.0763875, lng: 18.6307369 },
    { lat: 49.0767807, lng: 18.6309163 },
    { lat: 49.0770219, lng: 18.6310221 },
    { lat: 49.077036, lng: 18.6310803 },
    { lat: 49.0771071, lng: 18.6313742 },
    { lat: 49.0771208, lng: 18.6314337 },
    { lat: 49.0771764, lng: 18.6316758 },
    { lat: 49.0772105, lng: 18.6318284 },
    { lat: 49.077246, lng: 18.6322409 },
    { lat: 49.0772513, lng: 18.6323007 },
    { lat: 49.0772596, lng: 18.6323724 },
    { lat: 49.0772676, lng: 18.6324559 },
    { lat: 49.0772415, lng: 18.6324757 },
    { lat: 49.0772242, lng: 18.6324886 },
    { lat: 49.0772035, lng: 18.6325038 },
    { lat: 49.0771518, lng: 18.6325486 },
    { lat: 49.0771237, lng: 18.6325942 },
    { lat: 49.0770503, lng: 18.6326529 },
    { lat: 49.077096, lng: 18.6327293 },
    { lat: 49.077156, lng: 18.6328187 },
    { lat: 49.077213, lng: 18.6328936 },
    { lat: 49.0772815, lng: 18.6329726 },
    { lat: 49.0773376, lng: 18.6330379 },
    { lat: 49.0774127, lng: 18.6331254 },
    { lat: 49.0774581, lng: 18.6331689 },
    { lat: 49.0775732, lng: 18.6332794 },
    { lat: 49.077657, lng: 18.6332701 },
    { lat: 49.0778142, lng: 18.6332528 },
    { lat: 49.0779144, lng: 18.633293 },
    { lat: 49.0779509, lng: 18.6333215 },
    { lat: 49.0780598, lng: 18.633311 },
    { lat: 49.0781764, lng: 18.6332998 },
    { lat: 49.078263, lng: 18.6332923 },
    { lat: 49.0783925, lng: 18.6332698 },
    { lat: 49.0786206, lng: 18.6332981 },
    { lat: 49.0785926, lng: 18.633407 },
    { lat: 49.0784982, lng: 18.6337511 },
    { lat: 49.0784085, lng: 18.6340766 },
    { lat: 49.0785856, lng: 18.6340547 },
    { lat: 49.0787801, lng: 18.6341303 },
    { lat: 49.0789098, lng: 18.6342593 },
    { lat: 49.0790064, lng: 18.6343545 },
    { lat: 49.0789645, lng: 18.6344594 },
    { lat: 49.0787962, lng: 18.6348759 },
    { lat: 49.0785439, lng: 18.6355161 },
    { lat: 49.0783291, lng: 18.6360578 },
    { lat: 49.0782605, lng: 18.6362293 },
    { lat: 49.0782738, lng: 18.6362881 },
    { lat: 49.0781621, lng: 18.6366886 },
    { lat: 49.0779621, lng: 18.6374011 },
    { lat: 49.0778854, lng: 18.6377398 },
    { lat: 49.0777248, lng: 18.6384291 },
    { lat: 49.0775908, lng: 18.6388144 },
    { lat: 49.0774902, lng: 18.6391272 },
    { lat: 49.0774273, lng: 18.6393253 },
    { lat: 49.0772575, lng: 18.6392707 },
    { lat: 49.0768771, lng: 18.6391561 },
    { lat: 49.0767908, lng: 18.6391696 },
    { lat: 49.0766221, lng: 18.6391959 },
    { lat: 49.0765451, lng: 18.6392046 },
    { lat: 49.0765178, lng: 18.6392077 },
    { lat: 49.0763149, lng: 18.6395288 },
    { lat: 49.076222, lng: 18.6396054 },
    { lat: 49.0761248, lng: 18.6396206 },
    { lat: 49.0760266, lng: 18.6396417 },
    { lat: 49.0759179, lng: 18.639676 },
    { lat: 49.0758917, lng: 18.6398185 },
    { lat: 49.0758064, lng: 18.6399344 },
    { lat: 49.0757625, lng: 18.639997 },
    { lat: 49.0757528, lng: 18.6399846 },
    { lat: 49.07569, lng: 18.6399053 },
    { lat: 49.075642, lng: 18.6398749 },
    { lat: 49.0755654, lng: 18.6398329 },
    { lat: 49.0754564, lng: 18.6398193 },
    { lat: 49.0753444, lng: 18.6399389 },
    { lat: 49.0752945, lng: 18.6400401 },
    { lat: 49.0752504, lng: 18.6401452 },
    { lat: 49.0751757, lng: 18.6402029 },
    { lat: 49.0749191, lng: 18.6403796 },
    { lat: 49.0748704, lng: 18.6405881 },
    { lat: 49.0746832, lng: 18.6406764 },
    { lat: 49.0746033, lng: 18.6405784 },
    { lat: 49.0745555, lng: 18.6405198 },
    { lat: 49.0743934, lng: 18.6403164 },
    { lat: 49.0743007, lng: 18.6403955 },
    { lat: 49.0743423, lng: 18.6405414 },
    { lat: 49.0741718, lng: 18.6406472 },
    { lat: 49.0741293, lng: 18.6406735 },
    { lat: 49.0740075, lng: 18.6403741 },
    { lat: 49.0738389, lng: 18.640346 },
    { lat: 49.0737802, lng: 18.6404281 },
    { lat: 49.0733965, lng: 18.6404438 },
    { lat: 49.0733586, lng: 18.640582 },
    { lat: 49.0733172, lng: 18.6407331 },
    { lat: 49.0732856, lng: 18.6409874 },
    { lat: 49.073252, lng: 18.6411825 },
    { lat: 49.073125, lng: 18.6413586 },
    { lat: 49.0729736, lng: 18.6416302 },
    { lat: 49.072661, lng: 18.642218 },
    { lat: 49.0725564, lng: 18.6423914 },
    { lat: 49.0725198, lng: 18.6424909 },
    { lat: 49.0723876, lng: 18.6426744 },
    { lat: 49.0723334, lng: 18.642779 },
    { lat: 49.0722526, lng: 18.6428508 },
    { lat: 49.0721135, lng: 18.6430352 },
    { lat: 49.0720421, lng: 18.6431757 },
    { lat: 49.0719641, lng: 18.6432379 },
    { lat: 49.0718827, lng: 18.6433616 },
    { lat: 49.0718857, lng: 18.6434162 },
    { lat: 49.0718033, lng: 18.643649 },
    { lat: 49.0716223, lng: 18.6444005 },
    { lat: 49.071555, lng: 18.6445351 },
    { lat: 49.0714452, lng: 18.6448867 },
    { lat: 49.0713115, lng: 18.6452572 },
    { lat: 49.0712679, lng: 18.6453079 },
    { lat: 49.0711865, lng: 18.6455366 },
    { lat: 49.0709289, lng: 18.6463306 },
    { lat: 49.0708535, lng: 18.6466319 },
    { lat: 49.0707666, lng: 18.6470739 },
    { lat: 49.0704325, lng: 18.6483237 },
    { lat: 49.0703477, lng: 18.6486967 },
    { lat: 49.0703738, lng: 18.6487428 },
    { lat: 49.0704332, lng: 18.6486283 },
    { lat: 49.0705055, lng: 18.6484643 },
    { lat: 49.0706253, lng: 18.6482999 },
    { lat: 49.0708669, lng: 18.6480479 },
    { lat: 49.0712439, lng: 18.6478446 },
    { lat: 49.0717002, lng: 18.6475383 },
    { lat: 49.0720818, lng: 18.6473425 },
    { lat: 49.0723524, lng: 18.6472036 },
    { lat: 49.0723236, lng: 18.6476622 },
    { lat: 49.072329, lng: 18.647719 },
    { lat: 49.0723337, lng: 18.6477677 },
    { lat: 49.0723384, lng: 18.6478553 },
    { lat: 49.0723362, lng: 18.648089 },
    { lat: 49.0723316, lng: 18.6481935 },
    { lat: 49.0723023, lng: 18.6482554 },
    { lat: 49.0722726, lng: 18.6483109 },
    { lat: 49.0721956, lng: 18.648392 },
    { lat: 49.0721129, lng: 18.6484899 },
    { lat: 49.0720087, lng: 18.6487391 },
    { lat: 49.0720028, lng: 18.6487754 },
    { lat: 49.0719544, lng: 18.6490958 },
    { lat: 49.0719281, lng: 18.649467 },
    { lat: 49.071925, lng: 18.649502 },
    { lat: 49.0719195, lng: 18.6495627 },
    { lat: 49.0718788, lng: 18.6498162 },
    { lat: 49.0718343, lng: 18.6504634 },
    { lat: 49.0718425, lng: 18.6505336 },
    { lat: 49.071881, lng: 18.6509145 },
    { lat: 49.0718817, lng: 18.6510788 },
    { lat: 49.0718645, lng: 18.6512619 },
    { lat: 49.0717244, lng: 18.6516673 },
    { lat: 49.0717217, lng: 18.6516926 },
    { lat: 49.0717096, lng: 18.6518065 },
    { lat: 49.0716561, lng: 18.6521605 },
    { lat: 49.0716055, lng: 18.6523948 },
    { lat: 49.0714606, lng: 18.6527097 },
    { lat: 49.0714034, lng: 18.6528341 },
    { lat: 49.0713967, lng: 18.6528635 },
    { lat: 49.0713411, lng: 18.6531083 },
    { lat: 49.0713262, lng: 18.6531421 },
    { lat: 49.071269, lng: 18.6532575 },
    { lat: 49.0711316, lng: 18.6534913 },
    { lat: 49.071072, lng: 18.6535971 },
    { lat: 49.0710335, lng: 18.6536656 },
    { lat: 49.0709125, lng: 18.6539988 },
    { lat: 49.0709037, lng: 18.6540311 },
    { lat: 49.0708303, lng: 18.6543012 },
    { lat: 49.0707729, lng: 18.6545122 },
    { lat: 49.0707542, lng: 18.6545812 },
    { lat: 49.0707462, lng: 18.6546221 },
    { lat: 49.0707312, lng: 18.6546813 },
    { lat: 49.0707042, lng: 18.6547881 },
    { lat: 49.0706736, lng: 18.6550102 },
    { lat: 49.0705879, lng: 18.6556319 },
    { lat: 49.0704134, lng: 18.6568936 },
    { lat: 49.0703407, lng: 18.6573696 },
    { lat: 49.0703138, lng: 18.6575454 },
    { lat: 49.0703042, lng: 18.6575653 },
    { lat: 49.0702437, lng: 18.6576903 },
    { lat: 49.0702488, lng: 18.6577195 },
    { lat: 49.070251, lng: 18.6577606 },
    { lat: 49.070339, lng: 18.65757 },
    { lat: 49.0703282, lng: 18.6576659 },
    { lat: 49.070182, lng: 18.6582047 },
    { lat: 49.0701684, lng: 18.658265 },
    { lat: 49.0697088, lng: 18.6599963 },
    { lat: 49.0696632, lng: 18.6601792 },
    { lat: 49.0695593, lng: 18.6605599 },
    { lat: 49.069302, lng: 18.6615527 },
    { lat: 49.0692071, lng: 18.6619553 },
    { lat: 49.069155, lng: 18.6622865 },
    { lat: 49.0691725, lng: 18.6625541 },
    { lat: 49.0691836, lng: 18.6626072 },
    { lat: 49.0692914, lng: 18.6631219 },
    { lat: 49.0693235, lng: 18.663275 },
    { lat: 49.0693927, lng: 18.6636223 },
    { lat: 49.0694137, lng: 18.6637017 },
    { lat: 49.0697615, lng: 18.6645153 },
    { lat: 49.0700756, lng: 18.6651842 },
    { lat: 49.0702553, lng: 18.6655103 },
    { lat: 49.0703369, lng: 18.665624 },
    { lat: 49.0704846, lng: 18.6656468 },
    { lat: 49.0705382, lng: 18.665805 },
    { lat: 49.0705919, lng: 18.6657984 },
    { lat: 49.0706088, lng: 18.6659474 },
    { lat: 49.0706816, lng: 18.6661309 },
    { lat: 49.0706838, lng: 18.6663407 },
    { lat: 49.0707144, lng: 18.6664495 },
    { lat: 49.070759, lng: 18.6664001 },
    { lat: 49.0707839, lng: 18.6664409 },
    { lat: 49.0707893, lng: 18.6665431 },
    { lat: 49.0708867, lng: 18.6667229 },
    { lat: 49.0709131, lng: 18.666752 },
    { lat: 49.071127, lng: 18.6668944 },
    { lat: 49.0712216, lng: 18.6669544 },
    { lat: 49.0712921, lng: 18.6670101 },
    { lat: 49.071359, lng: 18.6670666 },
    { lat: 49.0714055, lng: 18.6671059 },
    { lat: 49.0713782, lng: 18.6673977 },
    { lat: 49.0714201, lng: 18.6675918 },
    { lat: 49.0714305, lng: 18.6677485 },
    { lat: 49.0715196, lng: 18.6678105 },
    { lat: 49.0715811, lng: 18.6678645 },
    { lat: 49.0716263, lng: 18.667951 },
    { lat: 49.071756, lng: 18.6680174 },
    { lat: 49.0717235, lng: 18.6681657 },
    { lat: 49.0717812, lng: 18.6682341 },
    { lat: 49.0717772, lng: 18.6682868 },
    { lat: 49.071702, lng: 18.6684018 },
    { lat: 49.0717651, lng: 18.6684418 },
    { lat: 49.0718507, lng: 18.6684382 },
    { lat: 49.0719104, lng: 18.6685887 },
    { lat: 49.0719454, lng: 18.6687355 },
    { lat: 49.0718616, lng: 18.6687735 },
    { lat: 49.0718398, lng: 18.6689546 },
    { lat: 49.0717475, lng: 18.6691307 },
    { lat: 49.0719533, lng: 18.6693525 },
    { lat: 49.0719613, lng: 18.6694191 },
    { lat: 49.0720467, lng: 18.6694964 },
    { lat: 49.0720639, lng: 18.6697371 },
    { lat: 49.0721096, lng: 18.6698372 },
    { lat: 49.0721633, lng: 18.6700821 },
    { lat: 49.0722176, lng: 18.6700865 },
    { lat: 49.0722801, lng: 18.6700702 },
    { lat: 49.072449, lng: 18.6702693 },
    { lat: 49.0724628, lng: 18.6702856 },
    { lat: 49.0725458, lng: 18.6706599 },
    { lat: 49.0725393, lng: 18.6707913 },
    { lat: 49.0725529, lng: 18.6708349 },
    { lat: 49.0726393, lng: 18.6708063 },
    { lat: 49.0727866, lng: 18.6708213 },
    { lat: 49.0735384, lng: 18.6707909 },
    { lat: 49.0736303, lng: 18.6714195 },
    { lat: 49.0737384, lng: 18.6716713 },
    { lat: 49.0737007, lng: 18.6717882 },
    { lat: 49.073549, lng: 18.6719719 },
    { lat: 49.0733738, lng: 18.6723229 },
    { lat: 49.0732819, lng: 18.6724605 },
    { lat: 49.0731178, lng: 18.6726015 },
    { lat: 49.0729752, lng: 18.6727614 },
    { lat: 49.0725663, lng: 18.6731311 },
    { lat: 49.0722843, lng: 18.6733861 },
    { lat: 49.0740754, lng: 18.6762878 },
    { lat: 49.0747256, lng: 18.6770663 },
    { lat: 49.0748477, lng: 18.6771546 },
    { lat: 49.0753489, lng: 18.6754175 },
    { lat: 49.0754471, lng: 18.6751033 },
    { lat: 49.0755727, lng: 18.6748339 },
    { lat: 49.076793, lng: 18.6742791 },
    { lat: 49.0772198, lng: 18.6740123 },
    { lat: 49.0776716, lng: 18.6731956 },
    { lat: 49.0779495, lng: 18.6721654 },
    { lat: 49.0779791, lng: 18.6721261 },
    { lat: 49.0781439, lng: 18.6719354 },
    { lat: 49.0782975, lng: 18.6717701 },
    { lat: 49.07849, lng: 18.671572 },
    { lat: 49.0785833, lng: 18.6715055 },
    { lat: 49.078818, lng: 18.671336 },
    { lat: 49.0792175, lng: 18.6710844 },
    { lat: 49.0792577, lng: 18.6710582 },
    { lat: 49.0794007, lng: 18.6709691 },
    { lat: 49.0795789, lng: 18.6709256 },
    { lat: 49.0801714, lng: 18.6708507 },
    { lat: 49.0803025, lng: 18.6708986 },
    { lat: 49.0807532, lng: 18.6709077 },
    { lat: 49.081051, lng: 18.6708689 },
    { lat: 49.0814718, lng: 18.6707647 },
    { lat: 49.0816427, lng: 18.670779 },
    { lat: 49.0817885, lng: 18.6708064 },
    { lat: 49.0818471, lng: 18.6708676 },
    { lat: 49.0820267, lng: 18.6707466 },
    { lat: 49.0821157, lng: 18.6707227 },
    { lat: 49.0823412, lng: 18.6705277 },
    { lat: 49.0824224, lng: 18.6703609 },
    { lat: 49.0825562, lng: 18.6702019 },
    { lat: 49.0827115, lng: 18.6700918 },
    { lat: 49.0827835, lng: 18.6700675 },
    { lat: 49.0829492, lng: 18.670063 },
    { lat: 49.0837567, lng: 18.67017 },
    { lat: 49.0845299, lng: 18.6696303 },
    { lat: 49.084693, lng: 18.6694526 },
    { lat: 49.0851959, lng: 18.6689005 },
    { lat: 49.0853024, lng: 18.6688636 },
    { lat: 49.0858152, lng: 18.6686898 },
    { lat: 49.0866186, lng: 18.6686343 },
    { lat: 49.0866544, lng: 18.6686288 },
    { lat: 49.0867381, lng: 18.6686336 },
    { lat: 49.0869552, lng: 18.6686235 },
    { lat: 49.0871487, lng: 18.6685907 },
    { lat: 49.0874043, lng: 18.6685449 },
    { lat: 49.0875191, lng: 18.6684952 },
    { lat: 49.0877158, lng: 18.6684429 },
    { lat: 49.0879647, lng: 18.6683724 },
    { lat: 49.0880356, lng: 18.6683468 },
    { lat: 49.0882424, lng: 18.6683109 },
    { lat: 49.0882956, lng: 18.6682932 },
    { lat: 49.08838, lng: 18.6683113 },
    { lat: 49.0885565, lng: 18.6683479 },
    { lat: 49.0887353, lng: 18.6684439 },
    { lat: 49.0889006, lng: 18.6684772 },
    { lat: 49.0889752, lng: 18.6684123 },
    { lat: 49.0893403, lng: 18.6680911 },
    { lat: 49.0895072, lng: 18.6679421 },
    { lat: 49.0896873, lng: 18.6677415 },
    { lat: 49.089798, lng: 18.6676189 },
    { lat: 49.0898178, lng: 18.6675862 },
    { lat: 49.0898722, lng: 18.6676134 },
    { lat: 49.0899019, lng: 18.6675993 },
    { lat: 49.0899744, lng: 18.667563 },
    { lat: 49.090129, lng: 18.6673544 },
    { lat: 49.0901439, lng: 18.6673144 },
    { lat: 49.0901346, lng: 18.667245 },
    { lat: 49.0902446, lng: 18.6671315 },
    { lat: 49.0902513, lng: 18.6671299 },
    { lat: 49.0902699, lng: 18.6670974 },
    { lat: 49.0903203, lng: 18.6670264 },
    { lat: 49.0903958, lng: 18.6671086 },
    { lat: 49.0904821, lng: 18.6670156 },
    { lat: 49.0905361, lng: 18.6670546 },
    { lat: 49.0905802, lng: 18.6669938 },
    { lat: 49.0905771, lng: 18.6669359 },
    { lat: 49.0906055, lng: 18.6668975 },
    { lat: 49.0906069, lng: 18.6667753 },
    { lat: 49.0906403, lng: 18.666746 },
    { lat: 49.0906676, lng: 18.6667081 },
    { lat: 49.0906922, lng: 18.666704 },
    { lat: 49.090739, lng: 18.6666504 },
    { lat: 49.0906832, lng: 18.6665749 },
    { lat: 49.09072, lng: 18.6665023 },
    { lat: 49.0908363, lng: 18.6662718 },
    { lat: 49.0908693, lng: 18.6661709 },
    { lat: 49.0908668, lng: 18.6659557 },
    { lat: 49.090846, lng: 18.6657952 },
    { lat: 49.0908595, lng: 18.6657108 },
    { lat: 49.091019, lng: 18.6654213 },
    { lat: 49.091166, lng: 18.6650693 },
    { lat: 49.0910756, lng: 18.6649142 },
    { lat: 49.091086, lng: 18.6648575 },
    { lat: 49.0911036, lng: 18.6648057 },
    { lat: 49.0911472, lng: 18.6647985 },
    { lat: 49.0912748, lng: 18.664844 },
    { lat: 49.0913211, lng: 18.6648254 },
    { lat: 49.0913553, lng: 18.6648323 },
    { lat: 49.0913857, lng: 18.6648088 },
    { lat: 49.0914817, lng: 18.6647926 },
    { lat: 49.0914879, lng: 18.6646512 },
    { lat: 49.0914407, lng: 18.6644425 },
    { lat: 49.0914705, lng: 18.6643673 },
    { lat: 49.0915525, lng: 18.6643406 },
    { lat: 49.0916599, lng: 18.6643242 },
    { lat: 49.0917748, lng: 18.6643212 },
    { lat: 49.091823, lng: 18.6642491 },
    { lat: 49.0918443, lng: 18.6641828 },
    { lat: 49.0917827, lng: 18.6640647 },
    { lat: 49.0917607, lng: 18.6639714 },
    { lat: 49.0917606, lng: 18.6637771 },
    { lat: 49.0917885, lng: 18.6637736 },
    { lat: 49.0918151, lng: 18.6637175 },
    { lat: 49.0918192, lng: 18.6636486 },
    { lat: 49.0918891, lng: 18.6636065 },
    { lat: 49.0919424, lng: 18.6635467 },
    { lat: 49.09192, lng: 18.6633785 },
    { lat: 49.0919103, lng: 18.6632394 },
    { lat: 49.0919208, lng: 18.6632251 },
    { lat: 49.0919261, lng: 18.663177 },
    { lat: 49.0919532, lng: 18.6631532 },
    { lat: 49.0919487, lng: 18.6631127 },
    { lat: 49.0919364, lng: 18.6631128 },
    { lat: 49.091958, lng: 18.6630738 },
    { lat: 49.0919568, lng: 18.66303 },
    { lat: 49.0920061, lng: 18.6628548 },
    { lat: 49.0920826, lng: 18.66279 },
    { lat: 49.092108, lng: 18.662734 },
    { lat: 49.0921208, lng: 18.6625931 },
    { lat: 49.0921873, lng: 18.6624662 },
    { lat: 49.0922605, lng: 18.6624198 },
    { lat: 49.0924653, lng: 18.6622625 },
    { lat: 49.0925107, lng: 18.6621824 },
    { lat: 49.0925199, lng: 18.6621468 },
    { lat: 49.0925198, lng: 18.6620601 },
    { lat: 49.0925261, lng: 18.6620269 },
    { lat: 49.0925406, lng: 18.6619632 },
    { lat: 49.092563, lng: 18.6619176 },
    { lat: 49.0925496, lng: 18.661834 },
    { lat: 49.0925541, lng: 18.6617719 },
    { lat: 49.0927298, lng: 18.6616182 },
    { lat: 49.0926882, lng: 18.6615769 },
    { lat: 49.0926178, lng: 18.6615241 },
    { lat: 49.0927153, lng: 18.6613847 },
    { lat: 49.0927958, lng: 18.6611204 },
    { lat: 49.0928781, lng: 18.6609706 },
    { lat: 49.0929522, lng: 18.660688 },
    { lat: 49.0930199, lng: 18.660431 },
    { lat: 49.0931052, lng: 18.6601294 },
    { lat: 49.0931197, lng: 18.6600792 },
    { lat: 49.0931633, lng: 18.6599276 },
    { lat: 49.0933058, lng: 18.6598071 },
    { lat: 49.0933829, lng: 18.6600672 },
    { lat: 49.0933978, lng: 18.6600531 },
    { lat: 49.09342, lng: 18.660045 },
    { lat: 49.0934238, lng: 18.6599473 },
    { lat: 49.0934137, lng: 18.6599252 },
    { lat: 49.093424, lng: 18.659821 },
    { lat: 49.0935326, lng: 18.6597875 },
    { lat: 49.093562, lng: 18.659745 },
    { lat: 49.093625, lng: 18.6596599 },
    { lat: 49.0935164, lng: 18.6594825 },
    { lat: 49.0935982, lng: 18.65943 },
    { lat: 49.0936663, lng: 18.6593514 },
    { lat: 49.093683, lng: 18.659304 },
    { lat: 49.0937086, lng: 18.6592793 },
    { lat: 49.0936958, lng: 18.659247 },
    { lat: 49.093718, lng: 18.6591979 },
    { lat: 49.0937398, lng: 18.6591866 },
    { lat: 49.0937529, lng: 18.6592195 },
    { lat: 49.0938334, lng: 18.6592722 },
    { lat: 49.0938538, lng: 18.659233 },
    { lat: 49.0939165, lng: 18.6592022 },
    { lat: 49.0939331, lng: 18.6591505 },
  ],
  RajeckáLesná: [
    { lat: 49.0364694, lng: 18.6988337 },
    { lat: 49.0360769, lng: 18.6983188 },
    { lat: 49.036399, lng: 18.6980944 },
    { lat: 49.0358885, lng: 18.6971414 },
    { lat: 49.0350048, lng: 18.6956988 },
    { lat: 49.0350705, lng: 18.6954917 },
    { lat: 49.0353783, lng: 18.6955982 },
    { lat: 49.0361279, lng: 18.6955271 },
    { lat: 49.0359175, lng: 18.6937621 },
    { lat: 49.0360257, lng: 18.6934141 },
    { lat: 49.0362452, lng: 18.6927083 },
    { lat: 49.0364919, lng: 18.6919149 },
    { lat: 49.0365731, lng: 18.6906358 },
    { lat: 49.0365898, lng: 18.6896733 },
    { lat: 49.036534, lng: 18.6887881 },
    { lat: 49.0365333, lng: 18.688757 },
    { lat: 49.036516, lng: 18.6879327 },
    { lat: 49.0363828, lng: 18.6875511 },
    { lat: 49.0363361, lng: 18.6872617 },
    { lat: 49.0363338, lng: 18.6870492 },
    { lat: 49.0362868, lng: 18.687014 },
    { lat: 49.0361602, lng: 18.6868372 },
    { lat: 49.0357189, lng: 18.6857457 },
    { lat: 49.0355937, lng: 18.6854246 },
    { lat: 49.0355191, lng: 18.6852073 },
    { lat: 49.0354473, lng: 18.6848732 },
    { lat: 49.0352478, lng: 18.6841702 },
    { lat: 49.0352343, lng: 18.6837466 },
    { lat: 49.0353524, lng: 18.6828264 },
    { lat: 49.0352992, lng: 18.6824146 },
    { lat: 49.0351899, lng: 18.6818859 },
    { lat: 49.035168, lng: 18.6816417 },
    { lat: 49.0351657, lng: 18.6813694 },
    { lat: 49.035162, lng: 18.6809358 },
    { lat: 49.035185, lng: 18.6804319 },
    { lat: 49.0351646, lng: 18.6803863 },
    { lat: 49.0351158, lng: 18.6803168 },
    { lat: 49.0349921, lng: 18.680195 },
    { lat: 49.0350158, lng: 18.6801112 },
    { lat: 49.0351103, lng: 18.6797859 },
    { lat: 49.0351255, lng: 18.6797326 },
    { lat: 49.0351431, lng: 18.6796809 },
    { lat: 49.035813, lng: 18.6777272 },
    { lat: 49.0363734, lng: 18.6761284 },
    { lat: 49.0365423, lng: 18.6757375 },
    { lat: 49.0367486, lng: 18.6745249 },
    { lat: 49.036937, lng: 18.6741657 },
    { lat: 49.037985, lng: 18.673255 },
    { lat: 49.0390565, lng: 18.6720258 },
    { lat: 49.0396416, lng: 18.6719127 },
    { lat: 49.0399345, lng: 18.6704633 },
    { lat: 49.0399245, lng: 18.6701899 },
    { lat: 49.0399783, lng: 18.6693324 },
    { lat: 49.039795, lng: 18.6689833 },
    { lat: 49.0398537, lng: 18.6678977 },
    { lat: 49.0399984, lng: 18.6671193 },
    { lat: 49.0401629, lng: 18.6665023 },
    { lat: 49.0413191, lng: 18.6624269 },
    { lat: 49.0413944, lng: 18.662211 },
    { lat: 49.041418, lng: 18.6622297 },
    { lat: 49.0417211, lng: 18.6617556 },
    { lat: 49.0417024, lng: 18.661489 },
    { lat: 49.0420248, lng: 18.6609774 },
    { lat: 49.0422268, lng: 18.6606423 },
    { lat: 49.0430669, lng: 18.6596633 },
    { lat: 49.0431992, lng: 18.659307 },
    { lat: 49.0432276, lng: 18.6589533 },
    { lat: 49.0431856, lng: 18.6586069 },
    { lat: 49.0437389, lng: 18.6582164 },
    { lat: 49.044243, lng: 18.6575149 },
    { lat: 49.0445797, lng: 18.6563852 },
    { lat: 49.0447093, lng: 18.6562528 },
    { lat: 49.0448362, lng: 18.6561149 },
    { lat: 49.0450026, lng: 18.6558924 },
    { lat: 49.045008, lng: 18.6545551 },
    { lat: 49.044976, lng: 18.6543754 },
    { lat: 49.0451579, lng: 18.6539604 },
    { lat: 49.0451082, lng: 18.6535768 },
    { lat: 49.0451002, lng: 18.6535282 },
    { lat: 49.0452417, lng: 18.6534971 },
    { lat: 49.0453548, lng: 18.6534422 },
    { lat: 49.0455381, lng: 18.6532848 },
    { lat: 49.0456163, lng: 18.653204 },
    { lat: 49.0456512, lng: 18.6531174 },
    { lat: 49.0457287, lng: 18.6530909 },
    { lat: 49.0457321, lng: 18.6529863 },
    { lat: 49.0458234, lng: 18.6528815 },
    { lat: 49.0458634, lng: 18.6526559 },
    { lat: 49.0458527, lng: 18.6525831 },
    { lat: 49.0458825, lng: 18.6525263 },
    { lat: 49.0459011, lng: 18.6523917 },
    { lat: 49.0459774, lng: 18.6523823 },
    { lat: 49.0460589, lng: 18.652301 },
    { lat: 49.0461191, lng: 18.6521621 },
    { lat: 49.0461814, lng: 18.6518905 },
    { lat: 49.0461978, lng: 18.6518612 },
    { lat: 49.0463624, lng: 18.6517341 },
    { lat: 49.0464653, lng: 18.6516334 },
    { lat: 49.0465318, lng: 18.651463 },
    { lat: 49.0465562, lng: 18.6512188 },
    { lat: 49.0465555, lng: 18.6509967 },
    { lat: 49.0465397, lng: 18.6508681 },
    { lat: 49.0465352, lng: 18.6506558 },
    { lat: 49.0464047, lng: 18.6502189 },
    { lat: 49.0463079, lng: 18.6499465 },
    { lat: 49.0462967, lng: 18.6499043 },
    { lat: 49.0462931, lng: 18.6498372 },
    { lat: 49.0463609, lng: 18.6496275 },
    { lat: 49.0463925, lng: 18.6495413 },
    { lat: 49.046422, lng: 18.6494384 },
    { lat: 49.0464327, lng: 18.6493645 },
    { lat: 49.0464822, lng: 18.6488119 },
    { lat: 49.0464776, lng: 18.6487082 },
    { lat: 49.0464784, lng: 18.648656 },
    { lat: 49.0464672, lng: 18.6484446 },
    { lat: 49.0464584, lng: 18.6482795 },
    { lat: 49.0464509, lng: 18.6482463 },
    { lat: 49.0464828, lng: 18.6482111 },
    { lat: 49.0466067, lng: 18.648079 },
    { lat: 49.0465991, lng: 18.6480645 },
    { lat: 49.046644, lng: 18.6479536 },
    { lat: 49.0467148, lng: 18.6477996 },
    { lat: 49.0467666, lng: 18.6477339 },
    { lat: 49.0468214, lng: 18.6476848 },
    { lat: 49.046908, lng: 18.6475547 },
    { lat: 49.0469276, lng: 18.6475192 },
    { lat: 49.046938, lng: 18.6475043 },
    { lat: 49.046967, lng: 18.647477 },
    { lat: 49.0469912, lng: 18.6474427 },
    { lat: 49.0470113, lng: 18.6473331 },
    { lat: 49.0469601, lng: 18.6472409 },
    { lat: 49.0470386, lng: 18.6471489 },
    { lat: 49.0470999, lng: 18.6470917 },
    { lat: 49.0471603, lng: 18.6469362 },
    { lat: 49.0472494, lng: 18.6468288 },
    { lat: 49.0476449, lng: 18.6464414 },
    { lat: 49.0477685, lng: 18.646095 },
    { lat: 49.047953, lng: 18.6456578 },
    { lat: 49.0484731, lng: 18.6462157 },
    { lat: 49.0488161, lng: 18.6465563 },
    { lat: 49.048905, lng: 18.6464454 },
    { lat: 49.0489335, lng: 18.6463455 },
    { lat: 49.048984, lng: 18.6462994 },
    { lat: 49.0491261, lng: 18.6461283 },
    { lat: 49.0492392, lng: 18.6459893 },
    { lat: 49.0493519, lng: 18.645837 },
    { lat: 49.0493901, lng: 18.6458128 },
    { lat: 49.0494893, lng: 18.6459818 },
    { lat: 49.0496772, lng: 18.6462085 },
    { lat: 49.0498149, lng: 18.6459762 },
    { lat: 49.0498781, lng: 18.6459148 },
    { lat: 49.0499282, lng: 18.645818 },
    { lat: 49.049945, lng: 18.6457922 },
    { lat: 49.0499956, lng: 18.6457468 },
    { lat: 49.0500334, lng: 18.6457159 },
    { lat: 49.0500647, lng: 18.6456916 },
    { lat: 49.0503228, lng: 18.6455195 },
    { lat: 49.0504543, lng: 18.6454677 },
    { lat: 49.050457, lng: 18.6454343 },
    { lat: 49.0504636, lng: 18.6453896 },
    { lat: 49.0504813, lng: 18.6453572 },
    { lat: 49.0504953, lng: 18.6453059 },
    { lat: 49.0504934, lng: 18.6452486 },
    { lat: 49.0504921, lng: 18.6451844 },
    { lat: 49.0504978, lng: 18.6451179 },
    { lat: 49.0505084, lng: 18.6450641 },
    { lat: 49.0505315, lng: 18.6449911 },
    { lat: 49.0505493, lng: 18.6449616 },
    { lat: 49.0505667, lng: 18.6449296 },
    { lat: 49.0505647, lng: 18.644891 },
    { lat: 49.0505762, lng: 18.6448555 },
    { lat: 49.0506737, lng: 18.6446978 },
    { lat: 49.0506922, lng: 18.644642 },
    { lat: 49.0508536, lng: 18.6443258 },
    { lat: 49.0509144, lng: 18.6442385 },
    { lat: 49.0509946, lng: 18.6441161 },
    { lat: 49.0510346, lng: 18.6440371 },
    { lat: 49.0510691, lng: 18.6439037 },
    { lat: 49.051129, lng: 18.643759 },
    { lat: 49.0512256, lng: 18.6435594 },
    { lat: 49.051308, lng: 18.6432871 },
    { lat: 49.0513903, lng: 18.643053 },
    { lat: 49.0514157, lng: 18.6429168 },
    { lat: 49.0514584, lng: 18.6426807 },
    { lat: 49.0514897, lng: 18.6426344 },
    { lat: 49.0515442, lng: 18.642596 },
    { lat: 49.0516587, lng: 18.642501 },
    { lat: 49.0517665, lng: 18.6424093 },
    { lat: 49.0519422, lng: 18.6422341 },
    { lat: 49.0520789, lng: 18.6420882 },
    { lat: 49.0523107, lng: 18.6418043 },
    { lat: 49.0523566, lng: 18.6416721 },
    { lat: 49.0523686, lng: 18.6415994 },
    { lat: 49.0523959, lng: 18.6414781 },
    { lat: 49.0524358, lng: 18.6413577 },
    { lat: 49.0524735, lng: 18.6412844 },
    { lat: 49.0525265, lng: 18.6411794 },
    { lat: 49.0527294, lng: 18.6409429 },
    { lat: 49.0527464, lng: 18.6409239 },
    { lat: 49.0528115, lng: 18.6407897 },
    { lat: 49.0528894, lng: 18.6407513 },
    { lat: 49.0529185, lng: 18.6407261 },
    { lat: 49.0529653, lng: 18.6406722 },
    { lat: 49.0530851, lng: 18.6405089 },
    { lat: 49.0532859, lng: 18.6402151 },
    { lat: 49.053425, lng: 18.6400085 },
    { lat: 49.0537709, lng: 18.6394703 },
    { lat: 49.0538986, lng: 18.6393298 },
    { lat: 49.0541354, lng: 18.6389884 },
    { lat: 49.0543739, lng: 18.6386846 },
    { lat: 49.0545811, lng: 18.6384217 },
    { lat: 49.0556061, lng: 18.6380194 },
    { lat: 49.0558169, lng: 18.6382487 },
    { lat: 49.0561222, lng: 18.6381161 },
    { lat: 49.0567855, lng: 18.6376965 },
    { lat: 49.0568113, lng: 18.6376714 },
    { lat: 49.0569123, lng: 18.6375576 },
    { lat: 49.0573677, lng: 18.6372129 },
    { lat: 49.057428, lng: 18.6372659 },
    { lat: 49.0575216, lng: 18.6371828 },
    { lat: 49.0575532, lng: 18.6372655 },
    { lat: 49.057596, lng: 18.6372247 },
    { lat: 49.0576413, lng: 18.6373521 },
    { lat: 49.0577326, lng: 18.6372883 },
    { lat: 49.0577718, lng: 18.6372648 },
    { lat: 49.0578983, lng: 18.6372035 },
    { lat: 49.0580807, lng: 18.6371317 },
    { lat: 49.0582679, lng: 18.6370273 },
    { lat: 49.058491, lng: 18.6368146 },
    { lat: 49.0585391, lng: 18.6367414 },
    { lat: 49.0585869, lng: 18.636623 },
    { lat: 49.0585636, lng: 18.6358707 },
    { lat: 49.0584479, lng: 18.6352438 },
    { lat: 49.0581687, lng: 18.6351258 },
    { lat: 49.0580784, lng: 18.6350204 },
    { lat: 49.0578813, lng: 18.6348114 },
    { lat: 49.0578316, lng: 18.6345802 },
    { lat: 49.0575386, lng: 18.6343718 },
    { lat: 49.0572863, lng: 18.6342714 },
    { lat: 49.0569179, lng: 18.6339188 },
    { lat: 49.0568952, lng: 18.6332625 },
    { lat: 49.0566818, lng: 18.6318623 },
    { lat: 49.0566345, lng: 18.6318089 },
    { lat: 49.0564285, lng: 18.6315768 },
    { lat: 49.0563781, lng: 18.6315392 },
    { lat: 49.0557437, lng: 18.6307902 },
    { lat: 49.055865, lng: 18.6307155 },
    { lat: 49.0561703, lng: 18.6304142 },
    { lat: 49.0565108, lng: 18.6300319 },
    { lat: 49.0566809, lng: 18.6298796 },
    { lat: 49.0568833, lng: 18.6296162 },
    { lat: 49.0569618, lng: 18.6294364 },
    { lat: 49.0570845, lng: 18.6292736 },
    { lat: 49.0572503, lng: 18.6290534 },
    { lat: 49.0573598, lng: 18.6288658 },
    { lat: 49.0573783, lng: 18.6287689 },
    { lat: 49.0573809, lng: 18.6287081 },
    { lat: 49.0573838, lng: 18.6286377 },
    { lat: 49.0572486, lng: 18.6286595 },
    { lat: 49.0572116, lng: 18.6286655 },
    { lat: 49.0570119, lng: 18.6287272 },
    { lat: 49.0567636, lng: 18.628892 },
    { lat: 49.0565008, lng: 18.6290216 },
    { lat: 49.0562769, lng: 18.6291791 },
    { lat: 49.0561279, lng: 18.6293227 },
    { lat: 49.0558251, lng: 18.6288477 },
    { lat: 49.0557043, lng: 18.6286573 },
    { lat: 49.0553202, lng: 18.6280539 },
    { lat: 49.0552574, lng: 18.6279761 },
    { lat: 49.0551508, lng: 18.6280062 },
    { lat: 49.0550202, lng: 18.6281359 },
    { lat: 49.0547836, lng: 18.628268 },
    { lat: 49.0546624, lng: 18.6283837 },
    { lat: 49.0544857, lng: 18.6284124 },
    { lat: 49.0543836, lng: 18.628297 },
    { lat: 49.054278, lng: 18.6282622 },
    { lat: 49.0541523, lng: 18.6282943 },
    { lat: 49.0542071, lng: 18.626956 },
    { lat: 49.0544999, lng: 18.6251111 },
    { lat: 49.0545643, lng: 18.6247511 },
    { lat: 49.05488, lng: 18.6240555 },
    { lat: 49.0552185, lng: 18.6235526 },
    { lat: 49.055304, lng: 18.6234021 },
    { lat: 49.0553261, lng: 18.62339 },
    { lat: 49.0554004, lng: 18.6233481 },
    { lat: 49.0554947, lng: 18.6232951 },
    { lat: 49.0555713, lng: 18.6232522 },
    { lat: 49.0557037, lng: 18.6231779 },
    { lat: 49.0560856, lng: 18.623062 },
    { lat: 49.0560926, lng: 18.6230258 },
    { lat: 49.056135, lng: 18.6229113 },
    { lat: 49.0578262, lng: 18.6231328 },
    { lat: 49.0583465, lng: 18.6230237 },
    { lat: 49.059246, lng: 18.6228352 },
    { lat: 49.0596801, lng: 18.6227294 },
    { lat: 49.0598194, lng: 18.6226955 },
    { lat: 49.0603498, lng: 18.6230201 },
    { lat: 49.0609411, lng: 18.6232764 },
    { lat: 49.0610217, lng: 18.6233113 },
    { lat: 49.0615975, lng: 18.6235529 },
    { lat: 49.0618184, lng: 18.623003 },
    { lat: 49.0621608, lng: 18.62255 },
    { lat: 49.0629564, lng: 18.6222213 },
    { lat: 49.063468, lng: 18.622194 },
    { lat: 49.0635482, lng: 18.6224689 },
    { lat: 49.0637126, lng: 18.6226978 },
    { lat: 49.0643842, lng: 18.622592 },
    { lat: 49.0645324, lng: 18.6225686 },
    { lat: 49.0648742, lng: 18.6226989 },
    { lat: 49.0651463, lng: 18.6234213 },
    { lat: 49.0653476, lng: 18.6238528 },
    { lat: 49.0654198, lng: 18.6237665 },
    { lat: 49.0654622, lng: 18.6237152 },
    { lat: 49.0655513, lng: 18.6237384 },
    { lat: 49.0655986, lng: 18.6237383 },
    { lat: 49.0652956, lng: 18.6233661 },
    { lat: 49.0649113, lng: 18.6224419 },
    { lat: 49.064821, lng: 18.622061 },
    { lat: 49.0647318, lng: 18.6217182 },
    { lat: 49.0646078, lng: 18.6209645 },
    { lat: 49.0645059, lng: 18.6205963 },
    { lat: 49.0644689, lng: 18.6205138 },
    { lat: 49.0640719, lng: 18.6197771 },
    { lat: 49.0638326, lng: 18.6192047 },
    { lat: 49.0638147, lng: 18.6190814 },
    { lat: 49.0637586, lng: 18.6187881 },
    { lat: 49.0637495, lng: 18.6187453 },
    { lat: 49.0637399, lng: 18.6187127 },
    { lat: 49.0637405, lng: 18.6184936 },
    { lat: 49.0637435, lng: 18.6184044 },
    { lat: 49.063891, lng: 18.6175963 },
    { lat: 49.0638511, lng: 18.6168503 },
    { lat: 49.0637919, lng: 18.6157035 },
    { lat: 49.0636129, lng: 18.6143587 },
    { lat: 49.0630741, lng: 18.6128455 },
    { lat: 49.0628213, lng: 18.6130541 },
    { lat: 49.0630184, lng: 18.6134915 },
    { lat: 49.0631422, lng: 18.613993 },
    { lat: 49.0631307, lng: 18.6140304 },
    { lat: 49.0631241, lng: 18.6140535 },
    { lat: 49.0631009, lng: 18.6140423 },
    { lat: 49.0630105, lng: 18.6139964 },
    { lat: 49.0629259, lng: 18.6139532 },
    { lat: 49.0626372, lng: 18.6138081 },
    { lat: 49.0624552, lng: 18.613716 },
    { lat: 49.0622721, lng: 18.6136236 },
    { lat: 49.0620309, lng: 18.6135021 },
    { lat: 49.0619522, lng: 18.6134625 },
    { lat: 49.0602624, lng: 18.6117275 },
    { lat: 49.0592821, lng: 18.6113477 },
    { lat: 49.0591095, lng: 18.611281 },
    { lat: 49.0584251, lng: 18.6111576 },
    { lat: 49.0577237, lng: 18.6103193 },
    { lat: 49.0575867, lng: 18.6102015 },
    { lat: 49.0563141, lng: 18.6098799 },
    { lat: 49.0559849, lng: 18.6097963 },
    { lat: 49.0547278, lng: 18.6086278 },
    { lat: 49.0530003, lng: 18.6067141 },
    { lat: 49.052634, lng: 18.602434 },
    { lat: 49.052628, lng: 18.602419 },
    { lat: 49.05259, lng: 18.602327 },
    { lat: 49.052589, lng: 18.602325 },
    { lat: 49.052507, lng: 18.602246 },
    { lat: 49.052278, lng: 18.601765 },
    { lat: 49.052132, lng: 18.601606 },
    { lat: 49.052053, lng: 18.601412 },
    { lat: 49.052003, lng: 18.601342 },
    { lat: 49.051495, lng: 18.60073 },
    { lat: 49.050984, lng: 18.600264 },
    { lat: 49.050773, lng: 18.599762 },
    { lat: 49.050524, lng: 18.599557 },
    { lat: 49.050472, lng: 18.599461 },
    { lat: 49.050442, lng: 18.599301 },
    { lat: 49.050374, lng: 18.59916 },
    { lat: 49.050002, lng: 18.598992 },
    { lat: 49.049851, lng: 18.598674 },
    { lat: 49.049778, lng: 18.598303 },
    { lat: 49.049641, lng: 18.598083 },
    { lat: 49.049462, lng: 18.59726 },
    { lat: 49.049313, lng: 18.597042 },
    { lat: 49.049205, lng: 18.596861 },
    { lat: 49.04913, lng: 18.596783 },
    { lat: 49.049058, lng: 18.596619 },
    { lat: 49.048896, lng: 18.596339 },
    { lat: 49.048649, lng: 18.595939 },
    { lat: 49.048468, lng: 18.595653 },
    { lat: 49.048118, lng: 18.59514 },
    { lat: 49.047493, lng: 18.59553 },
    { lat: 49.046966, lng: 18.595699 },
    { lat: 49.045543, lng: 18.596253 },
    { lat: 49.045094, lng: 18.596433 },
    { lat: 49.045093, lng: 18.596434 },
    { lat: 49.045058, lng: 18.596471 },
    { lat: 49.044929, lng: 18.596606 },
    { lat: 49.044744, lng: 18.596911 },
    { lat: 49.044563, lng: 18.597179 },
    { lat: 49.044315, lng: 18.597523 },
    { lat: 49.043594, lng: 18.59854 },
    { lat: 49.043209, lng: 18.599228 },
    { lat: 49.042618, lng: 18.600195 },
    { lat: 49.041979, lng: 18.60115 },
    { lat: 49.041579, lng: 18.601799 },
    { lat: 49.041074, lng: 18.602614 },
    { lat: 49.040785, lng: 18.602142 },
    { lat: 49.040424, lng: 18.601487 },
    { lat: 49.040214, lng: 18.60115 },
    { lat: 49.039915, lng: 18.601162 },
    { lat: 49.039667, lng: 18.600923 },
    { lat: 49.039523, lng: 18.600829 },
    { lat: 49.039463, lng: 18.600727 },
    { lat: 49.039033, lng: 18.600591 },
    { lat: 49.038616, lng: 18.600689 },
    { lat: 49.038547, lng: 18.600633 },
    { lat: 49.038443, lng: 18.600398 },
    { lat: 49.038143, lng: 18.60013 },
    { lat: 49.037623, lng: 18.600712 },
    { lat: 49.037337, lng: 18.600862 },
    { lat: 49.036704, lng: 18.600568 },
    { lat: 49.036422, lng: 18.600486 },
    { lat: 49.03609, lng: 18.600216 },
    { lat: 49.035802, lng: 18.600168 },
    { lat: 49.035455, lng: 18.600113 },
    { lat: 49.035057, lng: 18.599995 },
    { lat: 49.034307, lng: 18.599711 },
    { lat: 49.033577, lng: 18.599535 },
    { lat: 49.033233, lng: 18.599877 },
    { lat: 49.032896, lng: 18.600313 },
    { lat: 49.03233, lng: 18.600869 },
    { lat: 49.03176, lng: 18.601435 },
    { lat: 49.031479, lng: 18.601522 },
    { lat: 49.030987, lng: 18.601312 },
    { lat: 49.0305332, lng: 18.6011368 },
    { lat: 49.030329, lng: 18.601058 },
    { lat: 49.0302731, lng: 18.6010314 },
    { lat: 49.029842, lng: 18.600826 },
    { lat: 49.028488, lng: 18.600384 },
    { lat: 49.0283653, lng: 18.6016957 },
    { lat: 49.0282567, lng: 18.6026975 },
    { lat: 49.028179, lng: 18.6034558 },
    { lat: 49.0280685, lng: 18.6044676 },
    { lat: 49.0278791, lng: 18.606131 },
    { lat: 49.0277468, lng: 18.6068824 },
    { lat: 49.0275385, lng: 18.6080435 },
    { lat: 49.0275755, lng: 18.6091754 },
    { lat: 49.0278513, lng: 18.6105162 },
    { lat: 49.0273069, lng: 18.6117003 },
    { lat: 49.0267632, lng: 18.6127707 },
    { lat: 49.0264568, lng: 18.6134743 },
    { lat: 49.0257499, lng: 18.6149519 },
    { lat: 49.0257222, lng: 18.6157186 },
    { lat: 49.0256604, lng: 18.6174294 },
    { lat: 49.0257466, lng: 18.6176268 },
    { lat: 49.0257923, lng: 18.6180362 },
    { lat: 49.0258546, lng: 18.6185946 },
    { lat: 49.0259038, lng: 18.6190276 },
    { lat: 49.0259674, lng: 18.6193589 },
    { lat: 49.0260024, lng: 18.6195416 },
    { lat: 49.0259831, lng: 18.6196442 },
    { lat: 49.0258688, lng: 18.6202284 },
    { lat: 49.0258322, lng: 18.6204104 },
    { lat: 49.0256781, lng: 18.6207335 },
    { lat: 49.0256127, lng: 18.6210083 },
    { lat: 49.0252833, lng: 18.6213854 },
    { lat: 49.0250862, lng: 18.6215794 },
    { lat: 49.0248468, lng: 18.6215745 },
    { lat: 49.0245512, lng: 18.6218461 },
    { lat: 49.0244646, lng: 18.6224832 },
    { lat: 49.023692, lng: 18.6234739 },
    { lat: 49.0229884, lng: 18.6238139 },
    { lat: 49.0227454, lng: 18.6240376 },
    { lat: 49.0227003, lng: 18.624353 },
    { lat: 49.0224973, lng: 18.6245024 },
    { lat: 49.0224168, lng: 18.6247262 },
    { lat: 49.022296, lng: 18.6246588 },
    { lat: 49.022196, lng: 18.6247114 },
    { lat: 49.0214885, lng: 18.6254421 },
    { lat: 49.0211988, lng: 18.6256763 },
    { lat: 49.0201075, lng: 18.6268461 },
    { lat: 49.0195842, lng: 18.6277948 },
    { lat: 49.0191739, lng: 18.6285385 },
    { lat: 49.0188378, lng: 18.6289178 },
    { lat: 49.0184785, lng: 18.6291774 },
    { lat: 49.0175988, lng: 18.6298122 },
    { lat: 49.0174283, lng: 18.6299352 },
    { lat: 49.0171466, lng: 18.6303603 },
    { lat: 49.0163446, lng: 18.6314546 },
    { lat: 49.0161281, lng: 18.6319631 },
    { lat: 49.0159378, lng: 18.6329654 },
    { lat: 49.015923, lng: 18.6330434 },
    { lat: 49.016167, lng: 18.6347843 },
    { lat: 49.0159887, lng: 18.6360265 },
    { lat: 49.0156175, lng: 18.6364039 },
    { lat: 49.0151869, lng: 18.6368523 },
    { lat: 49.0151531, lng: 18.6368967 },
    { lat: 49.0130365, lng: 18.6396512 },
    { lat: 49.0130585, lng: 18.6404209 },
    { lat: 49.012786, lng: 18.6417817 },
    { lat: 49.0124753, lng: 18.6421505 },
    { lat: 49.0122147, lng: 18.6425947 },
    { lat: 49.0106991, lng: 18.6432115 },
    { lat: 49.0104819, lng: 18.6433044 },
    { lat: 49.0101456, lng: 18.6437479 },
    { lat: 49.0094606, lng: 18.6439713 },
    { lat: 49.0083686, lng: 18.6451703 },
    { lat: 49.0076933, lng: 18.6455584 },
    { lat: 49.0069259, lng: 18.6460988 },
    { lat: 49.0056406, lng: 18.6466321 },
    { lat: 49.004132, lng: 18.6468739 },
    { lat: 49.0021201, lng: 18.6461992 },
    { lat: 49.0010866, lng: 18.6471655 },
    { lat: 49.0003023, lng: 18.646839 },
    { lat: 48.9993728, lng: 18.6463234 },
    { lat: 48.9986502, lng: 18.6451898 },
    { lat: 48.9976964, lng: 18.6450889 },
    { lat: 48.996875, lng: 18.6451886 },
    { lat: 48.9956018, lng: 18.645864 },
    { lat: 48.9939826, lng: 18.6468247 },
    { lat: 48.9931083, lng: 18.6469895 },
    { lat: 48.992022, lng: 18.6465623 },
    { lat: 48.9914588, lng: 18.6462556 },
    { lat: 48.9904643, lng: 18.6474037 },
    { lat: 48.9897699, lng: 18.6489769 },
    { lat: 48.9889145, lng: 18.6500259 },
    { lat: 48.988629, lng: 18.6504904 },
    { lat: 48.988322, lng: 18.65099 },
    { lat: 48.988338, lng: 18.6511 },
    { lat: 48.988407, lng: 18.652764 },
    { lat: 48.988499, lng: 18.653296 },
    { lat: 48.988835, lng: 18.654386 },
    { lat: 48.989548, lng: 18.655824 },
    { lat: 48.990159, lng: 18.656686 },
    { lat: 48.99094, lng: 18.657544 },
    { lat: 48.991286, lng: 18.65787 },
    { lat: 48.991889, lng: 18.658426 },
    { lat: 48.992145, lng: 18.658646 },
    { lat: 48.992757, lng: 18.659053 },
    { lat: 48.992918, lng: 18.659386 },
    { lat: 48.993576, lng: 18.660497 },
    { lat: 48.994016, lng: 18.660848 },
    { lat: 48.994428, lng: 18.661278 },
    { lat: 48.99482, lng: 18.661587 },
    { lat: 48.995616, lng: 18.662056 },
    { lat: 48.9962735, lng: 18.6630844 },
    { lat: 48.996382, lng: 18.663254 },
    { lat: 48.997253, lng: 18.66509 },
    { lat: 48.997406, lng: 18.665418 },
    { lat: 48.9977581, lng: 18.6665494 },
    { lat: 48.997833, lng: 18.66679 },
    { lat: 48.997893, lng: 18.667243 },
    { lat: 48.998042, lng: 18.667889 },
    { lat: 48.998076, lng: 18.66823 },
    { lat: 48.998132, lng: 18.668745 },
    { lat: 48.998147, lng: 18.669769 },
    { lat: 48.997836, lng: 18.673285 },
    { lat: 48.997704, lng: 18.67394 },
    { lat: 48.9974213, lng: 18.6743268 },
    { lat: 48.997253, lng: 18.674557 },
    { lat: 48.997024, lng: 18.674632 },
    { lat: 48.996673, lng: 18.675007 },
    { lat: 48.996245, lng: 18.675638 },
    { lat: 48.996199, lng: 18.676027 },
    { lat: 48.996228, lng: 18.676434 },
    { lat: 48.99623, lng: 18.676887 },
    { lat: 48.996122, lng: 18.67759 },
    { lat: 48.995978, lng: 18.677889 },
    { lat: 48.99538, lng: 18.678441 },
    { lat: 48.995089, lng: 18.678487 },
    { lat: 48.993668, lng: 18.679202 },
    { lat: 48.993118, lng: 18.679712 },
    { lat: 48.992899, lng: 18.680014 },
    { lat: 48.99282, lng: 18.680129 },
    { lat: 48.992765, lng: 18.681281 },
    { lat: 48.992488, lng: 18.68257 },
    { lat: 48.992217, lng: 18.683389 },
    { lat: 48.991808, lng: 18.68498 },
    { lat: 48.991346, lng: 18.686183 },
    { lat: 48.990939, lng: 18.686775 },
    { lat: 48.990735, lng: 18.687669 },
    { lat: 48.99081, lng: 18.688947 },
    { lat: 48.99093, lng: 18.689923 },
    { lat: 48.991292, lng: 18.690753 },
    { lat: 48.991667, lng: 18.691354 },
    { lat: 48.992387, lng: 18.692492 },
    { lat: 48.992474, lng: 18.692632 },
    { lat: 48.992843, lng: 18.69349 },
    { lat: 48.99264, lng: 18.693707 },
    { lat: 48.99233, lng: 18.694621 },
    { lat: 48.992159, lng: 18.695364 },
    { lat: 48.99182, lng: 18.698112 },
    { lat: 48.991821, lng: 18.699014 },
    { lat: 48.991898, lng: 18.699505 },
    { lat: 48.992502, lng: 18.700414 },
    { lat: 48.992661, lng: 18.700786 },
    { lat: 48.992679, lng: 18.701142 },
    { lat: 48.992514, lng: 18.70194 },
    { lat: 48.99222, lng: 18.702536 },
    { lat: 48.992042, lng: 18.703171 },
    { lat: 48.991995, lng: 18.704747 },
    { lat: 48.992198, lng: 18.705489 },
    { lat: 48.992995, lng: 18.706217 },
    { lat: 48.993246, lng: 18.707344 },
    { lat: 48.993616, lng: 18.709608 },
    { lat: 48.993744, lng: 18.709779 },
    { lat: 48.993942, lng: 18.710271 },
    { lat: 48.993954, lng: 18.710434 },
    { lat: 48.994641, lng: 18.710646 },
    { lat: 48.995228, lng: 18.710885 },
    { lat: 48.995839, lng: 18.711407 },
    { lat: 48.996506, lng: 18.711727 },
    { lat: 48.997402, lng: 18.71201 },
    { lat: 48.997807, lng: 18.712026 },
    { lat: 48.998215, lng: 18.712199 },
    { lat: 48.998862, lng: 18.712484 },
    { lat: 48.999344, lng: 18.71273 },
    { lat: 48.999986, lng: 18.712806 },
    { lat: 49.000416, lng: 18.712874 },
    { lat: 49.001258, lng: 18.712877 },
    { lat: 49.001766, lng: 18.7129467 },
    { lat: 49.0021851, lng: 18.7130042 },
    { lat: 49.002264, lng: 18.713015 },
    { lat: 49.002834, lng: 18.713042 },
    { lat: 49.00299, lng: 18.713225 },
    { lat: 49.003337, lng: 18.713944 },
    { lat: 49.00389, lng: 18.714857 },
    { lat: 49.004598, lng: 18.715991 },
    { lat: 49.005069, lng: 18.716674 },
    { lat: 49.00561, lng: 18.71714 },
    { lat: 49.006182, lng: 18.717379 },
    { lat: 49.006375, lng: 18.717544 },
    { lat: 49.006565, lng: 18.717932 },
    { lat: 49.006772, lng: 18.718524 },
    { lat: 49.006873, lng: 18.718978 },
    { lat: 49.006922, lng: 18.719483 },
    { lat: 49.006892, lng: 18.720234 },
    { lat: 49.006844, lng: 18.720915 },
    { lat: 49.006739, lng: 18.721833 },
    { lat: 49.006642, lng: 18.722606 },
    { lat: 49.006512, lng: 18.723177 },
    { lat: 49.006435, lng: 18.723563 },
    { lat: 49.005791, lng: 18.724653 },
    { lat: 49.005512, lng: 18.724924 },
    { lat: 49.004862, lng: 18.726497 },
    { lat: 49.005725, lng: 18.726606 },
    { lat: 49.005899, lng: 18.726723 },
    { lat: 49.006198, lng: 18.72673 },
    { lat: 49.006231, lng: 18.726683 },
    { lat: 49.006428, lng: 18.726711 },
    { lat: 49.006595, lng: 18.726779 },
    { lat: 49.006866, lng: 18.726825 },
    { lat: 49.00685, lng: 18.72693 },
    { lat: 49.006996, lng: 18.727011 },
    { lat: 49.007086, lng: 18.72711 },
    { lat: 49.00708, lng: 18.727224 },
    { lat: 49.00805, lng: 18.727394 },
    { lat: 49.008377, lng: 18.727461 },
    { lat: 49.008771, lng: 18.727463 },
    { lat: 49.009009, lng: 18.727378 },
    { lat: 49.0090561, lng: 18.7273416 },
    { lat: 49.009563, lng: 18.727392 },
    { lat: 49.009753, lng: 18.727262 },
    { lat: 49.01006, lng: 18.727232 },
    { lat: 49.010633, lng: 18.727279 },
    { lat: 49.010997, lng: 18.727246 },
    { lat: 49.011283, lng: 18.727244 },
    { lat: 49.011727, lng: 18.727341 },
    { lat: 49.012061, lng: 18.727605 },
    { lat: 49.012112, lng: 18.727635 },
    { lat: 49.012538, lng: 18.727884 },
    { lat: 49.013423, lng: 18.728074 },
    { lat: 49.013801, lng: 18.727833 },
    { lat: 49.014077, lng: 18.727619 },
    { lat: 49.014353, lng: 18.727413 },
    { lat: 49.014679, lng: 18.727076 },
    { lat: 49.015097, lng: 18.726543 },
    { lat: 49.015498, lng: 18.72637 },
    { lat: 49.015815, lng: 18.726285 },
    { lat: 49.015872, lng: 18.726268 },
    { lat: 49.016031, lng: 18.72603 },
    { lat: 49.016507, lng: 18.725559 },
    { lat: 49.016816, lng: 18.725212 },
    { lat: 49.017032, lng: 18.724905 },
    { lat: 49.017403, lng: 18.724509 },
    { lat: 49.017407, lng: 18.724387 },
    { lat: 49.017487, lng: 18.724299 },
    { lat: 49.017884, lng: 18.723863 },
    { lat: 49.018425, lng: 18.723605 },
    { lat: 49.018749, lng: 18.723333 },
    { lat: 49.0189476, lng: 18.7230189 },
    { lat: 49.019228, lng: 18.722777 },
    { lat: 49.0185833, lng: 18.7222651 },
    { lat: 49.0187041, lng: 18.7214754 },
    { lat: 49.0212334, lng: 18.7201663 },
    { lat: 49.0217496, lng: 18.719603 },
    { lat: 49.0220777, lng: 18.7185548 },
    { lat: 49.0226944, lng: 18.7169019 },
    { lat: 49.0233025, lng: 18.7157718 },
    { lat: 49.0238968, lng: 18.7153222 },
    { lat: 49.0244107, lng: 18.7149714 },
    { lat: 49.0248537, lng: 18.7147324 },
    { lat: 49.0251331, lng: 18.7146434 },
    { lat: 49.0255329, lng: 18.7146119 },
    { lat: 49.0264138, lng: 18.7145491 },
    { lat: 49.0275765, lng: 18.7144416 },
    { lat: 49.0286007, lng: 18.7137649 },
    { lat: 49.0289939, lng: 18.7125814 },
    { lat: 49.0291337, lng: 18.7120637 },
    { lat: 49.029391, lng: 18.71147 },
    { lat: 49.0294821, lng: 18.7108001 },
    { lat: 49.0297531, lng: 18.7095254 },
    { lat: 49.029954, lng: 18.7081152 },
    { lat: 49.0300519, lng: 18.7065529 },
    { lat: 49.0302345, lng: 18.7058209 },
    { lat: 49.0301535, lng: 18.7040299 },
    { lat: 49.0298445, lng: 18.7018517 },
    { lat: 49.0299142, lng: 18.7015481 },
    { lat: 49.0298867, lng: 18.7011127 },
    { lat: 49.0300211, lng: 18.7007531 },
    { lat: 49.0306374, lng: 18.7004654 },
    { lat: 49.0314667, lng: 18.7003642 },
    { lat: 49.0329614, lng: 18.6997974 },
    { lat: 49.033334, lng: 18.6995941 },
    { lat: 49.0340219, lng: 18.6993796 },
    { lat: 49.03438, lng: 18.6991576 },
    { lat: 49.0358493, lng: 18.6992182 },
    { lat: 49.0364694, lng: 18.6988337 },
  ],
  RajeckéTeplice: [
    { lat: 49.1347596, lng: 18.660934 },
    { lat: 49.1334565, lng: 18.6605636 },
    { lat: 49.1318009, lng: 18.6613265 },
    { lat: 49.1310362, lng: 18.6613663 },
    { lat: 49.1303572, lng: 18.6613214 },
    { lat: 49.1294985, lng: 18.6621034 },
    { lat: 49.1292307, lng: 18.6624083 },
    { lat: 49.128835, lng: 18.6629244 },
    { lat: 49.1289585, lng: 18.6635059 },
    { lat: 49.128973, lng: 18.6635257 },
    { lat: 49.1284784, lng: 18.6639802 },
    { lat: 49.1282985, lng: 18.6641846 },
    { lat: 49.1281759, lng: 18.6643599 },
    { lat: 49.1280418, lng: 18.6645972 },
    { lat: 49.1279621, lng: 18.6646858 },
    { lat: 49.1279087, lng: 18.6647666 },
    { lat: 49.1278873, lng: 18.6651913 },
    { lat: 49.1278061, lng: 18.6654699 },
    { lat: 49.1277857, lng: 18.6655318 },
    { lat: 49.1277015, lng: 18.6657925 },
    { lat: 49.1275864, lng: 18.6661537 },
    { lat: 49.1275229, lng: 18.6663607 },
    { lat: 49.1274224, lng: 18.6666125 },
    { lat: 49.1273875, lng: 18.6664011 },
    { lat: 49.127362, lng: 18.6659649 },
    { lat: 49.1272966, lng: 18.6655772 },
    { lat: 49.1273355, lng: 18.6655048 },
    { lat: 49.127378, lng: 18.6654552 },
    { lat: 49.1276295, lng: 18.6650718 },
    { lat: 49.1276658, lng: 18.6650338 },
    { lat: 49.1276663, lng: 18.6650226 },
    { lat: 49.127891, lng: 18.6646863 },
    { lat: 49.1279977, lng: 18.6637218 },
    { lat: 49.1277733, lng: 18.6633393 },
    { lat: 49.1275262, lng: 18.6628922 },
    { lat: 49.12714, lng: 18.6624324 },
    { lat: 49.127081, lng: 18.6623626 },
    { lat: 49.1272568, lng: 18.6620629 },
    { lat: 49.1273323, lng: 18.6619366 },
    { lat: 49.1273585, lng: 18.6618941 },
    { lat: 49.1275386, lng: 18.6616148 },
    { lat: 49.127276, lng: 18.6610833 },
    { lat: 49.1269252, lng: 18.6600613 },
    { lat: 49.1267993, lng: 18.6599861 },
    { lat: 49.126647, lng: 18.659984 },
    { lat: 49.1264331, lng: 18.65997 },
    { lat: 49.1263965, lng: 18.6598322 },
    { lat: 49.126332, lng: 18.6597221 },
    { lat: 49.1261613, lng: 18.6596545 },
    { lat: 49.1261066, lng: 18.6594693 },
    { lat: 49.1260039, lng: 18.6594892 },
    { lat: 49.125983, lng: 18.6594781 },
    { lat: 49.1259588, lng: 18.6593431 },
    { lat: 49.1259209, lng: 18.6593266 },
    { lat: 49.1258195, lng: 18.6593542 },
    { lat: 49.12568, lng: 18.659339 },
    { lat: 49.1256046, lng: 18.6592766 },
    { lat: 49.1254593, lng: 18.6592588 },
    { lat: 49.1254289, lng: 18.6592835 },
    { lat: 49.1253864, lng: 18.6592441 },
    { lat: 49.1252521, lng: 18.6592433 },
    { lat: 49.1251537, lng: 18.6593883 },
    { lat: 49.1250853, lng: 18.6594162 },
    { lat: 49.1250304, lng: 18.6593801 },
    { lat: 49.124982, lng: 18.6593176 },
    { lat: 49.1249038, lng: 18.6593093 },
    { lat: 49.1248461, lng: 18.6594114 },
    { lat: 49.12477, lng: 18.6594655 },
    { lat: 49.124749, lng: 18.6595188 },
    { lat: 49.124701, lng: 18.6595276 },
    { lat: 49.1245417, lng: 18.6597316 },
    { lat: 49.1244121, lng: 18.6600544 },
    { lat: 49.1243542, lng: 18.6601306 },
    { lat: 49.1242676, lng: 18.6601373 },
    { lat: 49.1241575, lng: 18.6602099 },
    { lat: 49.1241787, lng: 18.6602487 },
    { lat: 49.1245401, lng: 18.6608513 },
    { lat: 49.1246188, lng: 18.6610821 },
    { lat: 49.1245731, lng: 18.6611133 },
    { lat: 49.1244946, lng: 18.6611655 },
    { lat: 49.1250146, lng: 18.6625878 },
    { lat: 49.1251538, lng: 18.6628966 },
    { lat: 49.1254348, lng: 18.663262 },
    { lat: 49.125584, lng: 18.6635674 },
    { lat: 49.1257172, lng: 18.6638462 },
    { lat: 49.1256806, lng: 18.6638778 },
    { lat: 49.1245552, lng: 18.6648581 },
    { lat: 49.1230308, lng: 18.6665674 },
    { lat: 49.1231226, lng: 18.66664 },
    { lat: 49.1232069, lng: 18.666635 },
    { lat: 49.123419, lng: 18.6668455 },
    { lat: 49.1238291, lng: 18.6679044 },
    { lat: 49.1235265, lng: 18.668068 },
    { lat: 49.12382, lng: 18.6688403 },
    { lat: 49.1242576, lng: 18.6700348 },
    { lat: 49.1245103, lng: 18.6707142 },
    { lat: 49.1245629, lng: 18.6709443 },
    { lat: 49.1246435, lng: 18.671168 },
    { lat: 49.1248098, lng: 18.6716665 },
    { lat: 49.1249775, lng: 18.6720837 },
    { lat: 49.125092, lng: 18.6722864 },
    { lat: 49.1249742, lng: 18.6724499 },
    { lat: 49.1248103, lng: 18.6728042 },
    { lat: 49.1247713, lng: 18.672854 },
    { lat: 49.124741, lng: 18.6728794 },
    { lat: 49.1247508, lng: 18.6729358 },
    { lat: 49.1248739, lng: 18.6733455 },
    { lat: 49.1249651, lng: 18.673617 },
    { lat: 49.1250215, lng: 18.6737685 },
    { lat: 49.1250873, lng: 18.6739041 },
    { lat: 49.1252489, lng: 18.6742907 },
    { lat: 49.1258628, lng: 18.6756038 },
    { lat: 49.1259075, lng: 18.6757196 },
    { lat: 49.1258592, lng: 18.6757223 },
    { lat: 49.1258015, lng: 18.6757625 },
    { lat: 49.1257754, lng: 18.6758234 },
    { lat: 49.1256846, lng: 18.6758125 },
    { lat: 49.1255425, lng: 18.675687 },
    { lat: 49.1254453, lng: 18.6756799 },
    { lat: 49.125332, lng: 18.6758836 },
    { lat: 49.1253156, lng: 18.6759184 },
    { lat: 49.1252502, lng: 18.6760215 },
    { lat: 49.1251865, lng: 18.6760556 },
    { lat: 49.1251462, lng: 18.676062 },
    { lat: 49.1251257, lng: 18.6760767 },
    { lat: 49.12499, lng: 18.6760711 },
    { lat: 49.1246057, lng: 18.6762256 },
    { lat: 49.1245229, lng: 18.6761922 },
    { lat: 49.124239, lng: 18.6761551 },
    { lat: 49.1240287, lng: 18.6761467 },
    { lat: 49.1241618, lng: 18.6765955 },
    { lat: 49.1238167, lng: 18.6765936 },
    { lat: 49.1245297, lng: 18.6792649 },
    { lat: 49.1241841, lng: 18.6795133 },
    { lat: 49.1239183, lng: 18.6797112 },
    { lat: 49.1237525, lng: 18.6798165 },
    { lat: 49.1235996, lng: 18.6799315 },
    { lat: 49.1234441, lng: 18.6800388 },
    { lat: 49.1234037, lng: 18.6800668 },
    { lat: 49.1229, lng: 18.6805027 },
    { lat: 49.1220626, lng: 18.681233 },
    { lat: 49.1219362, lng: 18.6813407 },
    { lat: 49.1217348, lng: 18.6814947 },
    { lat: 49.1215012, lng: 18.681729 },
    { lat: 49.1214079, lng: 18.6817753 },
    { lat: 49.1212817, lng: 18.6818654 },
    { lat: 49.1209283, lng: 18.6820675 },
    { lat: 49.1199589, lng: 18.6826531 },
    { lat: 49.1182243, lng: 18.6833141 },
    { lat: 49.11796, lng: 18.6834322 },
    { lat: 49.117851, lng: 18.6835032 },
    { lat: 49.1184026, lng: 18.6853627 },
    { lat: 49.1184676, lng: 18.6852691 },
    { lat: 49.1188228, lng: 18.6874659 },
    { lat: 49.1189849, lng: 18.6900253 },
    { lat: 49.1190812, lng: 18.6912087 },
    { lat: 49.1199716, lng: 18.6907929 },
    { lat: 49.1213332, lng: 18.6901828 },
    { lat: 49.1213399, lng: 18.6901802 },
    { lat: 49.1213609, lng: 18.6901708 },
    { lat: 49.1225908, lng: 18.689633 },
    { lat: 49.1237913, lng: 18.6890873 },
    { lat: 49.1252096, lng: 18.6884367 },
    { lat: 49.1252255, lng: 18.6896154 },
    { lat: 49.1254674, lng: 18.6893045 },
    { lat: 49.1258391, lng: 18.6889162 },
    { lat: 49.126749, lng: 18.6883538 },
    { lat: 49.1267476, lng: 18.6884116 },
    { lat: 49.1267363, lng: 18.6889679 },
    { lat: 49.1267261, lng: 18.6895225 },
    { lat: 49.1267234, lng: 18.6896626 },
    { lat: 49.1267155, lng: 18.6900906 },
    { lat: 49.1267111, lng: 18.6903065 },
    { lat: 49.1267067, lng: 18.6905241 },
    { lat: 49.1267027, lng: 18.6907436 },
    { lat: 49.1266956, lng: 18.6910606 },
    { lat: 49.1269997, lng: 18.6907118 },
    { lat: 49.1270588, lng: 18.691746 },
    { lat: 49.1271937, lng: 18.6915893 },
    { lat: 49.1271892, lng: 18.6917148 },
    { lat: 49.1279472, lng: 18.6908838 },
    { lat: 49.127996, lng: 18.6908278 },
    { lat: 49.1287784, lng: 18.690823 },
    { lat: 49.1287533, lng: 18.6912661 },
    { lat: 49.1297429, lng: 18.691407 },
    { lat: 49.1296593, lng: 18.6933704 },
    { lat: 49.1295589, lng: 18.6954842 },
    { lat: 49.1295079, lng: 18.6963781 },
    { lat: 49.1294754, lng: 18.6971948 },
    { lat: 49.1293475, lng: 18.6991975 },
    { lat: 49.1291907, lng: 18.701828 },
    { lat: 49.1291621, lng: 18.7026572 },
    { lat: 49.1291373, lng: 18.7029357 },
    { lat: 49.1291343, lng: 18.7029638 },
    { lat: 49.1290881, lng: 18.7034776 },
    { lat: 49.129294, lng: 18.7035103 },
    { lat: 49.1293315, lng: 18.7035166 },
    { lat: 49.1299566, lng: 18.703609 },
    { lat: 49.1298125, lng: 18.7050043 },
    { lat: 49.1297012, lng: 18.7059517 },
    { lat: 49.1302021, lng: 18.7060219 },
    { lat: 49.1306759, lng: 18.7061516 },
    { lat: 49.1309585, lng: 18.7062286 },
    { lat: 49.1311523, lng: 18.7062921 },
    { lat: 49.1315378, lng: 18.7064189 },
    { lat: 49.131593, lng: 18.7064348 },
    { lat: 49.1317695, lng: 18.7064928 },
    { lat: 49.1317613, lng: 18.7066332 },
    { lat: 49.1318792, lng: 18.7069692 },
    { lat: 49.1317957, lng: 18.707032 },
    { lat: 49.1318443, lng: 18.707165 },
    { lat: 49.1318808, lng: 18.7073856 },
    { lat: 49.1321919, lng: 18.707918 },
    { lat: 49.1323256, lng: 18.7082279 },
    { lat: 49.1324828, lng: 18.7085159 },
    { lat: 49.1324524, lng: 18.7086251 },
    { lat: 49.1325318, lng: 18.7088895 },
    { lat: 49.1325772, lng: 18.7094511 },
    { lat: 49.1325387, lng: 18.7096229 },
    { lat: 49.1322531, lng: 18.7098947 },
    { lat: 49.1320177, lng: 18.7100513 },
    { lat: 49.1318269, lng: 18.7101517 },
    { lat: 49.1317507, lng: 18.7102493 },
    { lat: 49.131656, lng: 18.7103987 },
    { lat: 49.1316256, lng: 18.7104215 },
    { lat: 49.131554, lng: 18.7104299 },
    { lat: 49.1315117, lng: 18.7104841 },
    { lat: 49.1314902, lng: 18.7105259 },
    { lat: 49.1314492, lng: 18.7106041 },
    { lat: 49.1314228, lng: 18.7106365 },
    { lat: 49.1313797, lng: 18.7106532 },
    { lat: 49.1313201, lng: 18.7106778 },
    { lat: 49.1313012, lng: 18.7107053 },
    { lat: 49.1312662, lng: 18.7108346 },
    { lat: 49.1312738, lng: 18.7109352 },
    { lat: 49.1312605, lng: 18.7110056 },
    { lat: 49.1312382, lng: 18.7110537 },
    { lat: 49.1311531, lng: 18.7111469 },
    { lat: 49.1308326, lng: 18.7112707 },
    { lat: 49.1308029, lng: 18.7113532 },
    { lat: 49.1306681, lng: 18.7114456 },
    { lat: 49.1304608, lng: 18.7116423 },
    { lat: 49.1302587, lng: 18.7116825 },
    { lat: 49.1302566, lng: 18.7117278 },
    { lat: 49.130222, lng: 18.7117115 },
    { lat: 49.1301579, lng: 18.7116977 },
    { lat: 49.1300218, lng: 18.7120262 },
    { lat: 49.129736, lng: 18.7124647 },
    { lat: 49.1296912, lng: 18.7125336 },
    { lat: 49.1296503, lng: 18.7126988 },
    { lat: 49.1295527, lng: 18.7127755 },
    { lat: 49.1295685, lng: 18.7128597 },
    { lat: 49.1297075, lng: 18.71295 },
    { lat: 49.1297402, lng: 18.7130386 },
    { lat: 49.1297344, lng: 18.7130756 },
    { lat: 49.1296919, lng: 18.7131485 },
    { lat: 49.1296569, lng: 18.7131474 },
    { lat: 49.1296246, lng: 18.7131351 },
    { lat: 49.1296257, lng: 18.713175 },
    { lat: 49.1296256, lng: 18.7131951 },
    { lat: 49.1296107, lng: 18.7136003 },
    { lat: 49.1296056, lng: 18.7137363 },
    { lat: 49.1295635, lng: 18.7139236 },
    { lat: 49.1295102, lng: 18.7142189 },
    { lat: 49.1294659, lng: 18.7143201 },
    { lat: 49.1293364, lng: 18.7147284 },
    { lat: 49.1291771, lng: 18.7152171 },
    { lat: 49.1288905, lng: 18.7161346 },
    { lat: 49.1288709, lng: 18.7161709 },
    { lat: 49.1283061, lng: 18.7176146 },
    { lat: 49.1277535, lng: 18.7187723 },
    { lat: 49.1270916, lng: 18.7196931 },
    { lat: 49.1263097, lng: 18.7205435 },
    { lat: 49.125446, lng: 18.7213621 },
    { lat: 49.1252841, lng: 18.7215881 },
    { lat: 49.1250213, lng: 18.7220994 },
    { lat: 49.1248732, lng: 18.7223295 },
    { lat: 49.1240882, lng: 18.723271 },
    { lat: 49.1232313, lng: 18.7242816 },
    { lat: 49.1223744, lng: 18.7257247 },
    { lat: 49.1214292, lng: 18.7272018 },
    { lat: 49.120297, lng: 18.7283409 },
    { lat: 49.1190542, lng: 18.7296497 },
    { lat: 49.1184224, lng: 18.7311288 },
    { lat: 49.118304, lng: 18.7313855 },
    { lat: 49.1173093, lng: 18.7337716 },
    { lat: 49.1163028, lng: 18.7359564 },
    { lat: 49.1159452, lng: 18.7365986 },
    { lat: 49.1155506, lng: 18.7375461 },
    { lat: 49.1156019, lng: 18.737578 },
    { lat: 49.1152357, lng: 18.7385711 },
    { lat: 49.1149911, lng: 18.7388546 },
    { lat: 49.1148892, lng: 18.7390295 },
    { lat: 49.1147324, lng: 18.7392986 },
    { lat: 49.1146719, lng: 18.739325 },
    { lat: 49.1145948, lng: 18.739383 },
    { lat: 49.1143053, lng: 18.7395402 },
    { lat: 49.1141117, lng: 18.7397196 },
    { lat: 49.1137611, lng: 18.7401279 },
    { lat: 49.1136111, lng: 18.7403027 },
    { lat: 49.1134833, lng: 18.7405105 },
    { lat: 49.1133684, lng: 18.7407948 },
    { lat: 49.1130641, lng: 18.7411831 },
    { lat: 49.1124844, lng: 18.7425357 },
    { lat: 49.1118332, lng: 18.7442712 },
    { lat: 49.1118208, lng: 18.7444211 },
    { lat: 49.1118293, lng: 18.7445662 },
    { lat: 49.1118367, lng: 18.7446617 },
    { lat: 49.1118857, lng: 18.7449352 },
    { lat: 49.1118436, lng: 18.7451198 },
    { lat: 49.1117921, lng: 18.7454709 },
    { lat: 49.1117423, lng: 18.7456411 },
    { lat: 49.1116066, lng: 18.7461046 },
    { lat: 49.1114597, lng: 18.7465288 },
    { lat: 49.111258, lng: 18.7468971 },
    { lat: 49.1111007, lng: 18.7472106 },
    { lat: 49.1109512, lng: 18.7475862 },
    { lat: 49.1108799, lng: 18.7478537 },
    { lat: 49.1108615, lng: 18.7479228 },
    { lat: 49.1107611, lng: 18.7483521 },
    { lat: 49.110685, lng: 18.7485944 },
    { lat: 49.1106278, lng: 18.7487765 },
    { lat: 49.1105862, lng: 18.7489986 },
    { lat: 49.1105742, lng: 18.749221 },
    { lat: 49.1105935, lng: 18.7495901 },
    { lat: 49.1105877, lng: 18.7497132 },
    { lat: 49.1104947, lng: 18.7503292 },
    { lat: 49.1104413, lng: 18.7505825 },
    { lat: 49.1103937, lng: 18.7508635 },
    { lat: 49.1104412, lng: 18.7511815 },
    { lat: 49.1102976, lng: 18.7523396 },
    { lat: 49.1103005, lng: 18.7525898 },
    { lat: 49.1102315, lng: 18.7526946 },
    { lat: 49.110158, lng: 18.7528388 },
    { lat: 49.1100486, lng: 18.7532059 },
    { lat: 49.10995, lng: 18.7534795 },
    { lat: 49.1098733, lng: 18.753802 },
    { lat: 49.1098241, lng: 18.7539829 },
    { lat: 49.1096625, lng: 18.7543628 },
    { lat: 49.1093042, lng: 18.755081 },
    { lat: 49.1090472, lng: 18.7555387 },
    { lat: 49.108788, lng: 18.7559232 },
    { lat: 49.1086987, lng: 18.7560557 },
    { lat: 49.1086114, lng: 18.7561819 },
    { lat: 49.1085444, lng: 18.7564257 },
    { lat: 49.1084877, lng: 18.7565214 },
    { lat: 49.1084578, lng: 18.7567313 },
    { lat: 49.1084523, lng: 18.7568966 },
    { lat: 49.1081932, lng: 18.7574281 },
    { lat: 49.1081664, lng: 18.7575713 },
    { lat: 49.1080908, lng: 18.757975 },
    { lat: 49.107967, lng: 18.7585583 },
    { lat: 49.1076475, lng: 18.7594407 },
    { lat: 49.1076301, lng: 18.759519 },
    { lat: 49.10758, lng: 18.759745 },
    { lat: 49.1072528, lng: 18.7607971 },
    { lat: 49.1071552, lng: 18.7611314 },
    { lat: 49.1071153, lng: 18.7613169 },
    { lat: 49.1070986, lng: 18.7614963 },
    { lat: 49.1071303, lng: 18.762018 },
    { lat: 49.1072825, lng: 18.7622306 },
    { lat: 49.1073584, lng: 18.7623038 },
    { lat: 49.1074067, lng: 18.7623646 },
    { lat: 49.1074455, lng: 18.7624603 },
    { lat: 49.1074707, lng: 18.7626167 },
    { lat: 49.1074754, lng: 18.7627939 },
    { lat: 49.1074675, lng: 18.763162 },
    { lat: 49.1074237, lng: 18.7634005 },
    { lat: 49.1071854, lng: 18.7641726 },
    { lat: 49.1071217, lng: 18.7643357 },
    { lat: 49.1070597, lng: 18.7643818 },
    { lat: 49.1069519, lng: 18.7644786 },
    { lat: 49.1067371, lng: 18.7646715 },
    { lat: 49.1065636, lng: 18.7648273 },
    { lat: 49.1064243, lng: 18.7649524 },
    { lat: 49.106417, lng: 18.7650484 },
    { lat: 49.1062435, lng: 18.7652277 },
    { lat: 49.1062064, lng: 18.7653233 },
    { lat: 49.1061591, lng: 18.7654452 },
    { lat: 49.1061127, lng: 18.7656601 },
    { lat: 49.1060655, lng: 18.7658787 },
    { lat: 49.1059971, lng: 18.7661036 },
    { lat: 49.1059929, lng: 18.7663867 },
    { lat: 49.1060008, lng: 18.7667479 },
    { lat: 49.1059882, lng: 18.7669041 },
    { lat: 49.105886, lng: 18.7679572 },
    { lat: 49.1058142, lng: 18.7687288 },
    { lat: 49.1058047, lng: 18.7688981 },
    { lat: 49.1057442, lng: 18.7693413 },
    { lat: 49.1057168, lng: 18.7695735 },
    { lat: 49.1057003, lng: 18.7697957 },
    { lat: 49.1056915, lng: 18.7700807 },
    { lat: 49.1056664, lng: 18.7702472 },
    { lat: 49.1056411, lng: 18.7704114 },
    { lat: 49.1056254, lng: 18.7705245 },
    { lat: 49.1055862, lng: 18.7708079 },
    { lat: 49.105594, lng: 18.7709804 },
    { lat: 49.105608, lng: 18.7711396 },
    { lat: 49.1056711, lng: 18.7714436 },
    { lat: 49.1057935, lng: 18.7718784 },
    { lat: 49.1059561, lng: 18.7724557 },
    { lat: 49.1060227, lng: 18.772823 },
    { lat: 49.1061228, lng: 18.7729711 },
    { lat: 49.1062921, lng: 18.773466 },
    { lat: 49.1063943, lng: 18.7737646 },
    { lat: 49.1065801, lng: 18.7740648 },
    { lat: 49.1066453, lng: 18.7741701 },
    { lat: 49.1067105, lng: 18.7742526 },
    { lat: 49.1067814, lng: 18.7743424 },
    { lat: 49.1068143, lng: 18.774384 },
    { lat: 49.1069924, lng: 18.774555 },
    { lat: 49.1070626, lng: 18.7746049 },
    { lat: 49.1071278, lng: 18.7746413 },
    { lat: 49.107315, lng: 18.7748626 },
    { lat: 49.1074796, lng: 18.775162 },
    { lat: 49.1077039, lng: 18.7753994 },
    { lat: 49.1081442, lng: 18.7762313 },
    { lat: 49.1082137, lng: 18.776419 },
    { lat: 49.1083376, lng: 18.7771528 },
    { lat: 49.1083572, lng: 18.7773142 },
    { lat: 49.1083497, lng: 18.7773973 },
    { lat: 49.1083399, lng: 18.7774328 },
    { lat: 49.1083777, lng: 18.7775977 },
    { lat: 49.1082859, lng: 18.7777416 },
    { lat: 49.1081435, lng: 18.777906 },
    { lat: 49.1078364, lng: 18.7781549 },
    { lat: 49.1076263, lng: 18.7783251 },
    { lat: 49.1072037, lng: 18.7787623 },
    { lat: 49.1070747, lng: 18.7790579 },
    { lat: 49.1068215, lng: 18.7794833 },
    { lat: 49.106537, lng: 18.7798635 },
    { lat: 49.1064032, lng: 18.7800038 },
    { lat: 49.1062701, lng: 18.780089 },
    { lat: 49.1060036, lng: 18.7801215 },
    { lat: 49.1052627, lng: 18.7807259 },
    { lat: 49.1050278, lng: 18.7810663 },
    { lat: 49.1047404, lng: 18.7815703 },
    { lat: 49.1045341, lng: 18.7820274 },
    { lat: 49.1043919, lng: 18.7824497 },
    { lat: 49.1043544, lng: 18.7825612 },
    { lat: 49.1041213, lng: 18.7834991 },
    { lat: 49.1040293, lng: 18.7841795 },
    { lat: 49.1039805, lng: 18.7845406 },
    { lat: 49.1037997, lng: 18.7853351 },
    { lat: 49.1035735, lng: 18.7860148 },
    { lat: 49.1032787, lng: 18.7870951 },
    { lat: 49.1031664, lng: 18.7877301 },
    { lat: 49.1029712, lng: 18.7889434 },
    { lat: 49.1029046, lng: 18.7897035 },
    { lat: 49.1029082, lng: 18.7898373 },
    { lat: 49.1029607, lng: 18.7899145 },
    { lat: 49.1029566, lng: 18.7899635 },
    { lat: 49.1024244, lng: 18.7933963 },
    { lat: 49.1023571, lng: 18.7937481 },
    { lat: 49.1024475, lng: 18.7938628 },
    { lat: 49.102486, lng: 18.7942781 },
    { lat: 49.1021318, lng: 18.7957746 },
    { lat: 49.1023654, lng: 18.795473 },
    { lat: 49.1041556, lng: 18.7950513 },
    { lat: 49.1048223, lng: 18.7944722 },
    { lat: 49.106034, lng: 18.7928328 },
    { lat: 49.1072553, lng: 18.7907761 },
    { lat: 49.1076118, lng: 18.7899979 },
    { lat: 49.1081274, lng: 18.78872 },
    { lat: 49.1086342, lng: 18.7872779 },
    { lat: 49.1090696, lng: 18.7854115 },
    { lat: 49.109904, lng: 18.7832463 },
    { lat: 49.1107682, lng: 18.781562 },
    { lat: 49.1109287, lng: 18.7813436 },
    { lat: 49.1116943, lng: 18.7803016 },
    { lat: 49.1118318, lng: 18.7801145 },
    { lat: 49.1119293, lng: 18.7796354 },
    { lat: 49.1122072, lng: 18.7782698 },
    { lat: 49.1121922, lng: 18.7782017 },
    { lat: 49.1119894, lng: 18.7772843 },
    { lat: 49.1118647, lng: 18.7767199 },
    { lat: 49.1118752, lng: 18.7762379 },
    { lat: 49.1118861, lng: 18.7757357 },
    { lat: 49.111898, lng: 18.7751917 },
    { lat: 49.11191, lng: 18.7746421 },
    { lat: 49.1119146, lng: 18.7744293 },
    { lat: 49.1122666, lng: 18.774075 },
    { lat: 49.1129346, lng: 18.7734026 },
    { lat: 49.1133137, lng: 18.7728404 },
    { lat: 49.1134391, lng: 18.7726545 },
    { lat: 49.1140945, lng: 18.7722124 },
    { lat: 49.1143047, lng: 18.7720706 },
    { lat: 49.1145189, lng: 18.7719261 },
    { lat: 49.1148704, lng: 18.7714437 },
    { lat: 49.115069, lng: 18.7710334 },
    { lat: 49.1151014, lng: 18.7709666 },
    { lat: 49.1151107, lng: 18.7709107 },
    { lat: 49.115173, lng: 18.7705354 },
    { lat: 49.1151984, lng: 18.7703823 },
    { lat: 49.1152104, lng: 18.7703101 },
    { lat: 49.1152913, lng: 18.7701626 },
    { lat: 49.115435, lng: 18.7699007 },
    { lat: 49.1155342, lng: 18.7697199 },
    { lat: 49.1156543, lng: 18.7695009 },
    { lat: 49.1157309, lng: 18.7693867 },
    { lat: 49.1157971, lng: 18.7692879 },
    { lat: 49.1164245, lng: 18.768352 },
    { lat: 49.1166293, lng: 18.7680464 },
    { lat: 49.1171688, lng: 18.7668659 },
    { lat: 49.1161621, lng: 18.7654219 },
    { lat: 49.1157027, lng: 18.7644781 },
    { lat: 49.1155599, lng: 18.7641846 },
    { lat: 49.1153551, lng: 18.7637639 },
    { lat: 49.1146383, lng: 18.7630407 },
    { lat: 49.1143778, lng: 18.7627779 },
    { lat: 49.1143278, lng: 18.7627274 },
    { lat: 49.1142919, lng: 18.7626448 },
    { lat: 49.1142744, lng: 18.7625455 },
    { lat: 49.1140084, lng: 18.7619924 },
    { lat: 49.1139433, lng: 18.7618425 },
    { lat: 49.1136449, lng: 18.7611558 },
    { lat: 49.1136351, lng: 18.7611254 },
    { lat: 49.1131013, lng: 18.7594697 },
    { lat: 49.1130068, lng: 18.7580957 },
    { lat: 49.1130107, lng: 18.7580467 },
    { lat: 49.1130409, lng: 18.7576636 },
    { lat: 49.1131752, lng: 18.7559624 },
    { lat: 49.1134255, lng: 18.7552265 },
    { lat: 49.1136648, lng: 18.754199 },
    { lat: 49.1140157, lng: 18.7531453 },
    { lat: 49.1140297, lng: 18.7524172 },
    { lat: 49.1141425, lng: 18.7516868 },
    { lat: 49.114219, lng: 18.7510811 },
    { lat: 49.1147518, lng: 18.7508318 },
    { lat: 49.1152758, lng: 18.7502877 },
    { lat: 49.1155458, lng: 18.7497106 },
    { lat: 49.1157411, lng: 18.748747 },
    { lat: 49.1158185, lng: 18.7484601 },
    { lat: 49.1167974, lng: 18.7469082 },
    { lat: 49.1195088, lng: 18.7426079 },
    { lat: 49.1201261, lng: 18.7416472 },
    { lat: 49.1206259, lng: 18.7408136 },
    { lat: 49.1212763, lng: 18.7398373 },
    { lat: 49.1213341, lng: 18.7393065 },
    { lat: 49.1218459, lng: 18.7386564 },
    { lat: 49.1229291, lng: 18.7359775 },
    { lat: 49.1241834, lng: 18.7334039 },
    { lat: 49.1243062, lng: 18.7331521 },
    { lat: 49.124333, lng: 18.733097 },
    { lat: 49.1247769, lng: 18.7331312 },
    { lat: 49.1248468, lng: 18.7329778 },
    { lat: 49.1248811, lng: 18.7329025 },
    { lat: 49.1250043, lng: 18.7328821 },
    { lat: 49.1250789, lng: 18.7328698 },
    { lat: 49.1251151, lng: 18.7327998 },
    { lat: 49.1251885, lng: 18.7326577 },
    { lat: 49.1253076, lng: 18.7326943 },
    { lat: 49.125619, lng: 18.7325214 },
    { lat: 49.1257135, lng: 18.732305 },
    { lat: 49.1258159, lng: 18.7322566 },
    { lat: 49.1259121, lng: 18.7322434 },
    { lat: 49.1260578, lng: 18.7323546 },
    { lat: 49.126186, lng: 18.7324556 },
    { lat: 49.1262788, lng: 18.7326543 },
    { lat: 49.1263995, lng: 18.7327418 },
    { lat: 49.1267288, lng: 18.7327633 },
    { lat: 49.1268182, lng: 18.7328781 },
    { lat: 49.1269705, lng: 18.7329204 },
    { lat: 49.1271087, lng: 18.7328471 },
    { lat: 49.1272093, lng: 18.7327468 },
    { lat: 49.1272737, lng: 18.7326826 },
    { lat: 49.1274124, lng: 18.7328353 },
    { lat: 49.127514, lng: 18.7328984 },
    { lat: 49.127634, lng: 18.7329729 },
    { lat: 49.1276971, lng: 18.7330603 },
    { lat: 49.1276941, lng: 18.7332601 },
    { lat: 49.1278457, lng: 18.7332662 },
    { lat: 49.1278524, lng: 18.7330961 },
    { lat: 49.1280049, lng: 18.7330127 },
    { lat: 49.1281605, lng: 18.7330788 },
    { lat: 49.1281929, lng: 18.7331509 },
    { lat: 49.1282536, lng: 18.733286 },
    { lat: 49.1283482, lng: 18.7330682 },
    { lat: 49.1283745, lng: 18.7330077 },
    { lat: 49.1284426, lng: 18.7330566 },
    { lat: 49.1285056, lng: 18.7331019 },
    { lat: 49.1285774, lng: 18.7331445 },
    { lat: 49.1286617, lng: 18.7331946 },
    { lat: 49.1288098, lng: 18.7332885 },
    { lat: 49.1288356, lng: 18.7334846 },
    { lat: 49.1288585, lng: 18.7336585 },
    { lat: 49.128964, lng: 18.7336702 },
    { lat: 49.1289796, lng: 18.7338598 },
    { lat: 49.1290599, lng: 18.733845 },
    { lat: 49.1291599, lng: 18.733955 },
    { lat: 49.1292635, lng: 18.7340691 },
    { lat: 49.1292974, lng: 18.7341568 },
    { lat: 49.129356, lng: 18.7341997 },
    { lat: 49.1294933, lng: 18.7343004 },
    { lat: 49.1295496, lng: 18.7344112 },
    { lat: 49.129653, lng: 18.734336 },
    { lat: 49.1298134, lng: 18.7346236 },
    { lat: 49.1298824, lng: 18.7346279 },
    { lat: 49.1301011, lng: 18.7343697 },
    { lat: 49.1302427, lng: 18.7343564 },
    { lat: 49.130321, lng: 18.7344751 },
    { lat: 49.1302798, lng: 18.7346141 },
    { lat: 49.1303196, lng: 18.7346431 },
    { lat: 49.1304114, lng: 18.7345224 },
    { lat: 49.1306262, lng: 18.7344246 },
    { lat: 49.1307577, lng: 18.7344803 },
    { lat: 49.1308971, lng: 18.7344075 },
    { lat: 49.1310033, lng: 18.7345407 },
    { lat: 49.1311204, lng: 18.7346875 },
    { lat: 49.1312521, lng: 18.7346621 },
    { lat: 49.1313518, lng: 18.73478 },
    { lat: 49.1316844, lng: 18.7346905 },
    { lat: 49.1318429, lng: 18.7346813 },
    { lat: 49.1318847, lng: 18.7345765 },
    { lat: 49.1322569, lng: 18.7345316 },
    { lat: 49.1322002, lng: 18.7340329 },
    { lat: 49.1323073, lng: 18.7339025 },
    { lat: 49.1323518, lng: 18.734107 },
    { lat: 49.1325805, lng: 18.7339297 },
    { lat: 49.1327431, lng: 18.7338037 },
    { lat: 49.1327648, lng: 18.7336599 },
    { lat: 49.1328427, lng: 18.733447 },
    { lat: 49.1329838, lng: 18.7333434 },
    { lat: 49.1329804, lng: 18.7331255 },
    { lat: 49.1330419, lng: 18.7330939 },
    { lat: 49.1331627, lng: 18.7332262 },
    { lat: 49.13322, lng: 18.732836 },
    { lat: 49.1333175, lng: 18.7326952 },
    { lat: 49.1333509, lng: 18.7325389 },
    { lat: 49.1334406, lng: 18.7324203 },
    { lat: 49.1334781, lng: 18.7325759 },
    { lat: 49.133553, lng: 18.7326936 },
    { lat: 49.1336817, lng: 18.732285 },
    { lat: 49.1338494, lng: 18.7323017 },
    { lat: 49.1339238, lng: 18.7321702 },
    { lat: 49.1340102, lng: 18.7321197 },
    { lat: 49.1341011, lng: 18.7319373 },
    { lat: 49.1343134, lng: 18.7319255 },
    { lat: 49.1342581, lng: 18.7317946 },
    { lat: 49.1342835, lng: 18.7315495 },
    { lat: 49.1344061, lng: 18.7312422 },
    { lat: 49.1344636, lng: 18.7312626 },
    { lat: 49.1346811, lng: 18.7312152 },
    { lat: 49.1348722, lng: 18.731032 },
    { lat: 49.1349044, lng: 18.7308722 },
    { lat: 49.1350007, lng: 18.7308158 },
    { lat: 49.135238, lng: 18.7305244 },
    { lat: 49.1351749, lng: 18.730204 },
    { lat: 49.1353502, lng: 18.7302982 },
    { lat: 49.1354154, lng: 18.7301214 },
    { lat: 49.1355585, lng: 18.7299491 },
    { lat: 49.1356707, lng: 18.7299394 },
    { lat: 49.1357407, lng: 18.7297001 },
    { lat: 49.1356837, lng: 18.7294978 },
    { lat: 49.1358314, lng: 18.7294971 },
    { lat: 49.1359337, lng: 18.7293852 },
    { lat: 49.1360431, lng: 18.7293211 },
    { lat: 49.1360686, lng: 18.7292254 },
    { lat: 49.1362926, lng: 18.7289832 },
    { lat: 49.1363122, lng: 18.7287521 },
    { lat: 49.1362826, lng: 18.7284672 },
    { lat: 49.1365171, lng: 18.7282525 },
    { lat: 49.1364595, lng: 18.7280794 },
    { lat: 49.1366361, lng: 18.7277053 },
    { lat: 49.1369011, lng: 18.7277663 },
    { lat: 49.1370772, lng: 18.7274067 },
    { lat: 49.1370193, lng: 18.7272906 },
    { lat: 49.1371596, lng: 18.7271276 },
    { lat: 49.1372224, lng: 18.7269507 },
    { lat: 49.1374027, lng: 18.7266669 },
    { lat: 49.13743, lng: 18.7264789 },
    { lat: 49.1373215, lng: 18.7264121 },
    { lat: 49.1373278, lng: 18.7262922 },
    { lat: 49.1374175, lng: 18.7260698 },
    { lat: 49.1375825, lng: 18.7260764 },
    { lat: 49.1376945, lng: 18.7258232 },
    { lat: 49.1377989, lng: 18.7258814 },
    { lat: 49.1378957, lng: 18.7258747 },
    { lat: 49.1379676, lng: 18.7256145 },
    { lat: 49.1379952, lng: 18.7252813 },
    { lat: 49.1382633, lng: 18.7251885 },
    { lat: 49.1383384, lng: 18.7250943 },
    { lat: 49.1384492, lng: 18.725079 },
    { lat: 49.1385214, lng: 18.7249297 },
    { lat: 49.1385824, lng: 18.7246515 },
    { lat: 49.138606, lng: 18.7245013 },
    { lat: 49.138603, lng: 18.724313 },
    { lat: 49.1386387, lng: 18.7242193 },
    { lat: 49.1387, lng: 18.7241625 },
    { lat: 49.1387204, lng: 18.7240375 },
    { lat: 49.1386597, lng: 18.7236974 },
    { lat: 49.1386754, lng: 18.7236533 },
    { lat: 49.1390433, lng: 18.7235264 },
    { lat: 49.1390264, lng: 18.7232273 },
    { lat: 49.1390031, lng: 18.7231927 },
    { lat: 49.1389185, lng: 18.7232106 },
    { lat: 49.1389293, lng: 18.7231603 },
    { lat: 49.1390236, lng: 18.7229806 },
    { lat: 49.139073, lng: 18.7229775 },
    { lat: 49.139134, lng: 18.7230006 },
    { lat: 49.1391641, lng: 18.7229934 },
    { lat: 49.139241, lng: 18.7228457 },
    { lat: 49.1392378, lng: 18.7226368 },
    { lat: 49.1393203, lng: 18.7223617 },
    { lat: 49.1391465, lng: 18.7220141 },
    { lat: 49.1392313, lng: 18.7218233 },
    { lat: 49.1391466, lng: 18.721502 },
    { lat: 49.1392756, lng: 18.7212492 },
    { lat: 49.1394768, lng: 18.7209823 },
    { lat: 49.1395825, lng: 18.7207404 },
    { lat: 49.1396668, lng: 18.7206897 },
    { lat: 49.1397567, lng: 18.7205351 },
    { lat: 49.1396578, lng: 18.7203703 },
    { lat: 49.1396347, lng: 18.7201873 },
    { lat: 49.1396852, lng: 18.7197082 },
    { lat: 49.1397264, lng: 18.7193772 },
    { lat: 49.1397494, lng: 18.7193247 },
    { lat: 49.1397645, lng: 18.719289 },
    { lat: 49.1397909, lng: 18.7193179 },
    { lat: 49.1401811, lng: 18.7197687 },
    { lat: 49.1402678, lng: 18.719855 },
    { lat: 49.1402749, lng: 18.7198599 },
    { lat: 49.1404373, lng: 18.7195361 },
    { lat: 49.1409578, lng: 18.7199585 },
    { lat: 49.1411341, lng: 18.7200303 },
    { lat: 49.1409126, lng: 18.7205374 },
    { lat: 49.1410269, lng: 18.7206531 },
    { lat: 49.1411101, lng: 18.7207118 },
    { lat: 49.1411808, lng: 18.7207896 },
    { lat: 49.1412336, lng: 18.7208505 },
    { lat: 49.1412443, lng: 18.7208607 },
    { lat: 49.1417884, lng: 18.7200344 },
    { lat: 49.1418509, lng: 18.7199382 },
    { lat: 49.14182, lng: 18.7197993 },
    { lat: 49.1419212, lng: 18.7197502 },
    { lat: 49.1420209, lng: 18.7197143 },
    { lat: 49.1425659, lng: 18.7196172 },
    { lat: 49.143047, lng: 18.7198009 },
    { lat: 49.1434854, lng: 18.7199862 },
    { lat: 49.1438477, lng: 18.7202943 },
    { lat: 49.143991, lng: 18.7205747 },
    { lat: 49.1440736, lng: 18.7211625 },
    { lat: 49.1442311, lng: 18.7211739 },
    { lat: 49.1448794, lng: 18.7212796 },
    { lat: 49.1443991, lng: 18.7199313 },
    { lat: 49.1445363, lng: 18.7182061 },
    { lat: 49.1447639, lng: 18.7174068 },
    { lat: 49.1448882, lng: 18.7168546 },
    { lat: 49.1449778, lng: 18.7161117 },
    { lat: 49.1456502, lng: 18.7156867 },
    { lat: 49.1455227, lng: 18.7153259 },
    { lat: 49.1453357, lng: 18.7148559 },
    { lat: 49.1450743, lng: 18.7144722 },
    { lat: 49.14482, lng: 18.7139067 },
    { lat: 49.144653, lng: 18.7135162 },
    { lat: 49.1444189, lng: 18.7129245 },
    { lat: 49.1441024, lng: 18.71259 },
    { lat: 49.1438309, lng: 18.7122089 },
    { lat: 49.1436635, lng: 18.7119812 },
    { lat: 49.1435349, lng: 18.7117462 },
    { lat: 49.1432637, lng: 18.711457 },
    { lat: 49.142937, lng: 18.7112293 },
    { lat: 49.1424988, lng: 18.7106799 },
    { lat: 49.1423921, lng: 18.7097878 },
    { lat: 49.1422185, lng: 18.7088689 },
    { lat: 49.1420075, lng: 18.7082622 },
    { lat: 49.1418654, lng: 18.7078355 },
    { lat: 49.141899, lng: 18.7072962 },
    { lat: 49.1420089, lng: 18.7068743 },
    { lat: 49.1420698, lng: 18.7064703 },
    { lat: 49.1421522, lng: 18.7062967 },
    { lat: 49.1432349, lng: 18.7053358 },
    { lat: 49.1435591, lng: 18.704985 },
    { lat: 49.1439926, lng: 18.704351 },
    { lat: 49.1446129, lng: 18.7039246 },
    { lat: 49.1450246, lng: 18.7035836 },
    { lat: 49.1451307, lng: 18.7033905 },
    { lat: 49.1453752, lng: 18.7031297 },
    { lat: 49.1456854, lng: 18.7029847 },
    { lat: 49.1461702, lng: 18.7035385 },
    { lat: 49.1464108, lng: 18.7038232 },
    { lat: 49.146251, lng: 18.7030185 },
    { lat: 49.1460496, lng: 18.7021243 },
    { lat: 49.1458007, lng: 18.7013814 },
    { lat: 49.1457414, lng: 18.7011768 },
    { lat: 49.145594, lng: 18.7008815 },
    { lat: 49.1454371, lng: 18.7006658 },
    { lat: 49.1450674, lng: 18.7001578 },
    { lat: 49.1447473, lng: 18.6999027 },
    { lat: 49.1445814, lng: 18.6998985 },
    { lat: 49.1442456, lng: 18.6998842 },
    { lat: 49.1439636, lng: 18.6997541 },
    { lat: 49.1435128, lng: 18.6993097 },
    { lat: 49.1431655, lng: 18.6986483 },
    { lat: 49.1431063, lng: 18.6979957 },
    { lat: 49.1429438, lng: 18.6977775 },
    { lat: 49.1426592, lng: 18.6973405 },
    { lat: 49.1425905, lng: 18.6970867 },
    { lat: 49.1425963, lng: 18.6965969 },
    { lat: 49.142799, lng: 18.6959716 },
    { lat: 49.1426782, lng: 18.6957332 },
    { lat: 49.1426046, lng: 18.6953424 },
    { lat: 49.142497, lng: 18.6950127 },
    { lat: 49.1423563, lng: 18.6945278 },
    { lat: 49.142375, lng: 18.6940897 },
    { lat: 49.1423009, lng: 18.6937764 },
    { lat: 49.1422736, lng: 18.6934296 },
    { lat: 49.1422036, lng: 18.6927888 },
    { lat: 49.142168, lng: 18.6926019 },
    { lat: 49.1422029, lng: 18.6923905 },
    { lat: 49.1422912, lng: 18.6919672 },
    { lat: 49.1423292, lng: 18.6917069 },
    { lat: 49.1423153, lng: 18.6912284 },
    { lat: 49.1422076, lng: 18.6907226 },
    { lat: 49.1421626, lng: 18.6900859 },
    { lat: 49.1418188, lng: 18.6896612 },
    { lat: 49.141207, lng: 18.6888592 },
    { lat: 49.141494, lng: 18.6884008 },
    { lat: 49.141862, lng: 18.687574 },
    { lat: 49.1422548, lng: 18.686835 },
    { lat: 49.1427086, lng: 18.6859869 },
    { lat: 49.1424173, lng: 18.6844857 },
    { lat: 49.1419776, lng: 18.6838294 },
    { lat: 49.1414076, lng: 18.6826037 },
    { lat: 49.140967, lng: 18.6815819 },
    { lat: 49.1407448, lng: 18.6804717 },
    { lat: 49.1405347, lng: 18.678952 },
    { lat: 49.1405744, lng: 18.6781252 },
    { lat: 49.140054, lng: 18.6771536 },
    { lat: 49.1393732, lng: 18.6761801 },
    { lat: 49.1390024, lng: 18.6746477 },
    { lat: 49.1381485, lng: 18.6730253 },
    { lat: 49.1381289, lng: 18.6729989 },
    { lat: 49.1376577, lng: 18.6722342 },
    { lat: 49.1373602, lng: 18.6719182 },
    { lat: 49.1368442, lng: 18.6713942 },
    { lat: 49.1363823, lng: 18.6708917 },
    { lat: 49.1358302, lng: 18.6700736 },
    { lat: 49.1353804, lng: 18.6681348 },
    { lat: 49.1354128, lng: 18.6672387 },
    { lat: 49.1357295, lng: 18.6658081 },
    { lat: 49.1357819, lng: 18.6652398 },
    { lat: 49.1358113, lng: 18.664903 },
    { lat: 49.1358121, lng: 18.6637047 },
    { lat: 49.1357815, lng: 18.6630652 },
    { lat: 49.1357118, lng: 18.6626427 },
    { lat: 49.13557, lng: 18.6623086 },
    { lat: 49.1353857, lng: 18.6619164 },
    { lat: 49.1348626, lng: 18.6610671 },
    { lat: 49.1347596, lng: 18.660934 },
  ],
  Stránske: [
    { lat: 49.1318792, lng: 18.7069692 },
    { lat: 49.1317613, lng: 18.7066332 },
    { lat: 49.1317695, lng: 18.7064928 },
    { lat: 49.131593, lng: 18.7064348 },
    { lat: 49.1315378, lng: 18.7064189 },
    { lat: 49.1311523, lng: 18.7062921 },
    { lat: 49.1309585, lng: 18.7062286 },
    { lat: 49.1306759, lng: 18.7061516 },
    { lat: 49.1302021, lng: 18.7060219 },
    { lat: 49.1297012, lng: 18.7059517 },
    { lat: 49.1298125, lng: 18.7050043 },
    { lat: 49.1299566, lng: 18.703609 },
    { lat: 49.1293315, lng: 18.7035166 },
    { lat: 49.129294, lng: 18.7035103 },
    { lat: 49.1290881, lng: 18.7034776 },
    { lat: 49.1291343, lng: 18.7029638 },
    { lat: 49.1291373, lng: 18.7029357 },
    { lat: 49.1291621, lng: 18.7026572 },
    { lat: 49.1291907, lng: 18.701828 },
    { lat: 49.1293475, lng: 18.6991975 },
    { lat: 49.1294754, lng: 18.6971948 },
    { lat: 49.1295079, lng: 18.6963781 },
    { lat: 49.1295589, lng: 18.6954842 },
    { lat: 49.1296593, lng: 18.6933704 },
    { lat: 49.1297429, lng: 18.691407 },
    { lat: 49.1287533, lng: 18.6912661 },
    { lat: 49.1287784, lng: 18.690823 },
    { lat: 49.127996, lng: 18.6908278 },
    { lat: 49.1279472, lng: 18.6908838 },
    { lat: 49.1271892, lng: 18.6917148 },
    { lat: 49.1271937, lng: 18.6915893 },
    { lat: 49.1270588, lng: 18.691746 },
    { lat: 49.1269997, lng: 18.6907118 },
    { lat: 49.1266956, lng: 18.6910606 },
    { lat: 49.1267027, lng: 18.6907436 },
    { lat: 49.1267067, lng: 18.6905241 },
    { lat: 49.1267111, lng: 18.6903065 },
    { lat: 49.1267155, lng: 18.6900906 },
    { lat: 49.1267234, lng: 18.6896626 },
    { lat: 49.1267261, lng: 18.6895225 },
    { lat: 49.1267363, lng: 18.6889679 },
    { lat: 49.1267476, lng: 18.6884116 },
    { lat: 49.126749, lng: 18.6883538 },
    { lat: 49.1258391, lng: 18.6889162 },
    { lat: 49.1254674, lng: 18.6893045 },
    { lat: 49.1252255, lng: 18.6896154 },
    { lat: 49.1252096, lng: 18.6884367 },
    { lat: 49.1237913, lng: 18.6890873 },
    { lat: 49.1225908, lng: 18.689633 },
    { lat: 49.1213609, lng: 18.6901708 },
    { lat: 49.1213399, lng: 18.6901802 },
    { lat: 49.1213332, lng: 18.6901828 },
    { lat: 49.1199716, lng: 18.6907929 },
    { lat: 49.1190812, lng: 18.6912087 },
    { lat: 49.1189849, lng: 18.6900253 },
    { lat: 49.118963, lng: 18.6900351 },
    { lat: 49.1167263, lng: 18.6909659 },
    { lat: 49.1158417, lng: 18.6912381 },
    { lat: 49.1149606, lng: 18.6915303 },
    { lat: 49.1141819, lng: 18.6917755 },
    { lat: 49.1140398, lng: 18.6918199 },
    { lat: 49.1127295, lng: 18.692313 },
    { lat: 49.1113783, lng: 18.6926704 },
    { lat: 49.1109121, lng: 18.6927879 },
    { lat: 49.1101808, lng: 18.6929682 },
    { lat: 49.1097106, lng: 18.6930992 },
    { lat: 49.1095768, lng: 18.6931311 },
    { lat: 49.1093129, lng: 18.6931944 },
    { lat: 49.1089058, lng: 18.6932989 },
    { lat: 49.108513, lng: 18.6934003 },
    { lat: 49.1083894, lng: 18.6936242 },
    { lat: 49.107632, lng: 18.6945689 },
    { lat: 49.1068989, lng: 18.6954451 },
    { lat: 49.1067447, lng: 18.6955587 },
    { lat: 49.1061101, lng: 18.6961841 },
    { lat: 49.1057759, lng: 18.6966436 },
    { lat: 49.1056088, lng: 18.6962765 },
    { lat: 49.1053275, lng: 18.6958855 },
    { lat: 49.1052884, lng: 18.6958287 },
    { lat: 49.1052796, lng: 18.6959586 },
    { lat: 49.1052199, lng: 18.6963672 },
    { lat: 49.1051527, lng: 18.6966116 },
    { lat: 49.1050535, lng: 18.6968044 },
    { lat: 49.104988, lng: 18.6968671 },
    { lat: 49.1050243, lng: 18.6970005 },
    { lat: 49.1051701, lng: 18.6974736 },
    { lat: 49.105058, lng: 18.6977461 },
    { lat: 49.104892, lng: 18.6981033 },
    { lat: 49.104856, lng: 18.6982765 },
    { lat: 49.1048184, lng: 18.6984593 },
    { lat: 49.1047853, lng: 18.6986235 },
    { lat: 49.1046522, lng: 18.6989014 },
    { lat: 49.1045066, lng: 18.6992003 },
    { lat: 49.1044379, lng: 18.6993502 },
    { lat: 49.10435, lng: 18.6995423 },
    { lat: 49.1041906, lng: 18.6998987 },
    { lat: 49.1039848, lng: 18.7003608 },
    { lat: 49.1038429, lng: 18.7006639 },
    { lat: 49.1030615, lng: 18.7022923 },
    { lat: 49.1027355, lng: 18.7029742 },
    { lat: 49.1028736, lng: 18.7033036 },
    { lat: 49.102989, lng: 18.7037181 },
    { lat: 49.1029954, lng: 18.7039469 },
    { lat: 49.1029955, lng: 18.7042273 },
    { lat: 49.1029967, lng: 18.7043754 },
    { lat: 49.1029522, lng: 18.7050517 },
    { lat: 49.102936, lng: 18.7052998 },
    { lat: 49.10292, lng: 18.7058121 },
    { lat: 49.1029103, lng: 18.7062258 },
    { lat: 49.1028807, lng: 18.7064572 },
    { lat: 49.1028171, lng: 18.7068745 },
    { lat: 49.1028396, lng: 18.7074077 },
    { lat: 49.1028782, lng: 18.7076531 },
    { lat: 49.1029715, lng: 18.7081819 },
    { lat: 49.1029804, lng: 18.7084759 },
    { lat: 49.1029791, lng: 18.7087111 },
    { lat: 49.1029571, lng: 18.7091903 },
    { lat: 49.1029372, lng: 18.7093687 },
    { lat: 49.1029403, lng: 18.7095141 },
    { lat: 49.1029163, lng: 18.7097218 },
    { lat: 49.1028767, lng: 18.709935 },
    { lat: 49.1027927, lng: 18.7102242 },
    { lat: 49.1027831, lng: 18.710257 },
    { lat: 49.1027542, lng: 18.7103505 },
    { lat: 49.1027814, lng: 18.7105898 },
    { lat: 49.1028356, lng: 18.7112727 },
    { lat: 49.102857, lng: 18.7115541 },
    { lat: 49.102826, lng: 18.7116068 },
    { lat: 49.1028032, lng: 18.7116442 },
    { lat: 49.1027872, lng: 18.711835 },
    { lat: 49.1027904, lng: 18.7118955 },
    { lat: 49.1027949, lng: 18.711982 },
    { lat: 49.1027831, lng: 18.7120133 },
    { lat: 49.1027522, lng: 18.7120894 },
    { lat: 49.1027384, lng: 18.7121689 },
    { lat: 49.1027583, lng: 18.7123356 },
    { lat: 49.1027609, lng: 18.7124044 },
    { lat: 49.1027515, lng: 18.7125034 },
    { lat: 49.1027331, lng: 18.7126071 },
    { lat: 49.1026719, lng: 18.7131152 },
    { lat: 49.1026558, lng: 18.7132813 },
    { lat: 49.1026367, lng: 18.7136003 },
    { lat: 49.1025876, lng: 18.7138028 },
    { lat: 49.1025321, lng: 18.714379 },
    { lat: 49.1022191, lng: 18.7143041 },
    { lat: 49.1021978, lng: 18.7143042 },
    { lat: 49.1022001, lng: 18.7144321 },
    { lat: 49.1021077, lng: 18.7146305 },
    { lat: 49.102019, lng: 18.7146787 },
    { lat: 49.1020055, lng: 18.7147448 },
    { lat: 49.1020285, lng: 18.714837 },
    { lat: 49.1021371, lng: 18.7149969 },
    { lat: 49.1021381, lng: 18.7150565 },
    { lat: 49.1020968, lng: 18.7150662 },
    { lat: 49.1020568, lng: 18.7150955 },
    { lat: 49.1019632, lng: 18.715117 },
    { lat: 49.1019381, lng: 18.7151568 },
    { lat: 49.1019279, lng: 18.7152185 },
    { lat: 49.1019413, lng: 18.7152802 },
    { lat: 49.1020305, lng: 18.7153917 },
    { lat: 49.1020252, lng: 18.7154827 },
    { lat: 49.1019766, lng: 18.7156336 },
    { lat: 49.1018371, lng: 18.7157662 },
    { lat: 49.1018312, lng: 18.7158018 },
    { lat: 49.1018661, lng: 18.7159314 },
    { lat: 49.1019598, lng: 18.7161676 },
    { lat: 49.1020921, lng: 18.7164135 },
    { lat: 49.1021191, lng: 18.7165585 },
    { lat: 49.1021158, lng: 18.7167748 },
    { lat: 49.1020351, lng: 18.7169797 },
    { lat: 49.1019983, lng: 18.7170522 },
    { lat: 49.1020063, lng: 18.7172035 },
    { lat: 49.1021279, lng: 18.717392 },
    { lat: 49.1022131, lng: 18.7175327 },
    { lat: 49.1022149, lng: 18.7177197 },
    { lat: 49.1022596, lng: 18.7178143 },
    { lat: 49.1022919, lng: 18.7180021 },
    { lat: 49.1022603, lng: 18.718214 },
    { lat: 49.102277, lng: 18.7183217 },
    { lat: 49.102221, lng: 18.7184333 },
    { lat: 49.1021813, lng: 18.7184928 },
    { lat: 49.1021716, lng: 18.7186092 },
    { lat: 49.1022003, lng: 18.7187061 },
    { lat: 49.1022612, lng: 18.7187706 },
    { lat: 49.1021776, lng: 18.7190025 },
    { lat: 49.1021612, lng: 18.7191434 },
    { lat: 49.1020683, lng: 18.7193279 },
    { lat: 49.1020971, lng: 18.7194438 },
    { lat: 49.1021909, lng: 18.7195145 },
    { lat: 49.1022085, lng: 18.7198269 },
    { lat: 49.1022882, lng: 18.7199906 },
    { lat: 49.1022928, lng: 18.7200563 },
    { lat: 49.1022699, lng: 18.7201775 },
    { lat: 49.1022244, lng: 18.7202161 },
    { lat: 49.102082, lng: 18.7202756 },
    { lat: 49.1020464, lng: 18.7203904 },
    { lat: 49.1020303, lng: 18.7205774 },
    { lat: 49.101957, lng: 18.7206406 },
    { lat: 49.1017617, lng: 18.7205932 },
    { lat: 49.1017207, lng: 18.7205867 },
    { lat: 49.1016559, lng: 18.7205769 },
    { lat: 49.1012985, lng: 18.720578 },
    { lat: 49.1010677, lng: 18.7205842 },
    { lat: 49.1007301, lng: 18.7207078 },
    { lat: 49.1007125, lng: 18.7207139 },
    { lat: 49.1004515, lng: 18.7206618 },
    { lat: 49.1001824, lng: 18.7206273 },
    { lat: 49.0995576, lng: 18.7206052 },
    { lat: 49.099306, lng: 18.7206244 },
    { lat: 49.0990481, lng: 18.72065 },
    { lat: 49.0987013, lng: 18.7209215 },
    { lat: 49.0988153, lng: 18.721884 },
    { lat: 49.0989663, lng: 18.7230859 },
    { lat: 49.099078, lng: 18.7239039 },
    { lat: 49.0991487, lng: 18.7245207 },
    { lat: 49.0991695, lng: 18.7247633 },
    { lat: 49.0988203, lng: 18.7252456 },
    { lat: 49.0985641, lng: 18.7259062 },
    { lat: 49.0983688, lng: 18.7265471 },
    { lat: 49.0979527, lng: 18.7270616 },
    { lat: 49.0977412, lng: 18.7276099 },
    { lat: 49.0976495, lng: 18.72801 },
    { lat: 49.0976061, lng: 18.7284309 },
    { lat: 49.0975414, lng: 18.729066 },
    { lat: 49.0973885, lng: 18.7295478 },
    { lat: 49.097245, lng: 18.7300543 },
    { lat: 49.097273, lng: 18.730998 },
    { lat: 49.0973613, lng: 18.7313259 },
    { lat: 49.0974273, lng: 18.7319166 },
    { lat: 49.0974157, lng: 18.7330289 },
    { lat: 49.0972665, lng: 18.7337124 },
    { lat: 49.097099, lng: 18.7340649 },
    { lat: 49.0963779, lng: 18.7347017 },
    { lat: 49.0962478, lng: 18.7349291 },
    { lat: 49.0959104, lng: 18.7353346 },
    { lat: 49.0954285, lng: 18.7358873 },
    { lat: 49.0949721, lng: 18.7365804 },
    { lat: 49.0947749, lng: 18.7369077 },
    { lat: 49.0947342, lng: 18.7369465 },
    { lat: 49.0945652, lng: 18.7373549 },
    { lat: 49.0945323, lng: 18.7378907 },
    { lat: 49.0944244, lng: 18.738179 },
    { lat: 49.0943779, lng: 18.7383031 },
    { lat: 49.0943516, lng: 18.7385103 },
    { lat: 49.0943147, lng: 18.7391172 },
    { lat: 49.0944101, lng: 18.7398659 },
    { lat: 49.0944011, lng: 18.7400811 },
    { lat: 49.0945578, lng: 18.7408533 },
    { lat: 49.0947534, lng: 18.7415521 },
    { lat: 49.0949267, lng: 18.7422352 },
    { lat: 49.0951708, lng: 18.7429328 },
    { lat: 49.0953445, lng: 18.7434299 },
    { lat: 49.095422, lng: 18.7438537 },
    { lat: 49.0954952, lng: 18.7441506 },
    { lat: 49.0956114, lng: 18.7444805 },
    { lat: 49.0957917, lng: 18.7448408 },
    { lat: 49.0959663, lng: 18.7454004 },
    { lat: 49.0956143, lng: 18.7463409 },
    { lat: 49.0953016, lng: 18.7475619 },
    { lat: 49.0947966, lng: 18.7484292 },
    { lat: 49.0945218, lng: 18.7488037 },
    { lat: 49.0943455, lng: 18.7492917 },
    { lat: 49.0941213, lng: 18.7498986 },
    { lat: 49.0938186, lng: 18.751054 },
    { lat: 49.0938774, lng: 18.7517895 },
    { lat: 49.0936687, lng: 18.7530814 },
    { lat: 49.0936592, lng: 18.7542595 },
    { lat: 49.0934182, lng: 18.7545866 },
    { lat: 49.093155, lng: 18.7548178 },
    { lat: 49.0928665, lng: 18.7552068 },
    { lat: 49.0921786, lng: 18.7564178 },
    { lat: 49.0919802, lng: 18.7578447 },
    { lat: 49.0919057, lng: 18.7581009 },
    { lat: 49.091714, lng: 18.7583573 },
    { lat: 49.0913909, lng: 18.7585853 },
    { lat: 49.0909318, lng: 18.7589255 },
    { lat: 49.0908264, lng: 18.7591106 },
    { lat: 49.090501, lng: 18.7599423 },
    { lat: 49.0900714, lng: 18.7608937 },
    { lat: 49.089683, lng: 18.7617229 },
    { lat: 49.0895088, lng: 18.7619516 },
    { lat: 49.0892245, lng: 18.763006 },
    { lat: 49.0894758, lng: 18.7634562 },
    { lat: 49.0894776, lng: 18.7640982 },
    { lat: 49.089714, lng: 18.7651911 },
    { lat: 49.0897615, lng: 18.7652912 },
    { lat: 49.0899485, lng: 18.7656855 },
    { lat: 49.0900977, lng: 18.7663456 },
    { lat: 49.0898749, lng: 18.7679531 },
    { lat: 49.0894953, lng: 18.7695136 },
    { lat: 49.0896689, lng: 18.7705741 },
    { lat: 49.0897472, lng: 18.7716217 },
    { lat: 49.0899329, lng: 18.7725689 },
    { lat: 49.089975, lng: 18.77316 },
    { lat: 49.0895603, lng: 18.7744829 },
    { lat: 49.0895863, lng: 18.7745429 },
    { lat: 49.0906268, lng: 18.7769489 },
    { lat: 49.0915756, lng: 18.7782124 },
    { lat: 49.0916951, lng: 18.7789049 },
    { lat: 49.0916979, lng: 18.779975 },
    { lat: 49.0913236, lng: 18.7806933 },
    { lat: 49.0911513, lng: 18.7814358 },
    { lat: 49.0910548, lng: 18.7818749 },
    { lat: 49.0907771, lng: 18.7825646 },
    { lat: 49.0903788, lng: 18.7834063 },
    { lat: 49.0902085, lng: 18.7837931 },
    { lat: 49.0903127, lng: 18.7849099 },
    { lat: 49.0902731, lng: 18.7864186 },
    { lat: 49.0902624, lng: 18.7868246 },
    { lat: 49.0902452, lng: 18.7878384 },
    { lat: 49.0899258, lng: 18.7886586 },
    { lat: 49.0898532, lng: 18.789234 },
    { lat: 49.0898992, lng: 18.7898379 },
    { lat: 49.0898557, lng: 18.7907146 },
    { lat: 49.0898786, lng: 18.7914144 },
    { lat: 49.0899638, lng: 18.7922766 },
    { lat: 49.0899033, lng: 18.7934316 },
    { lat: 49.0898899, lng: 18.7936735 },
    { lat: 49.0899328, lng: 18.7939591 },
    { lat: 49.0899822, lng: 18.7943647 },
    { lat: 49.0900135, lng: 18.7950981 },
    { lat: 49.0899946, lng: 18.7953457 },
    { lat: 49.0899638, lng: 18.7958801 },
    { lat: 49.0900075, lng: 18.7969588 },
    { lat: 49.0900704, lng: 18.7977611 },
    { lat: 49.0900876, lng: 18.7990035 },
    { lat: 49.0898763, lng: 18.8004964 },
    { lat: 49.0899616, lng: 18.8010744 },
    { lat: 49.0899141, lng: 18.8013748 },
    { lat: 49.0896849, lng: 18.8027421 },
    { lat: 49.0895249, lng: 18.803156 },
    { lat: 49.0893441, lng: 18.8035839 },
    { lat: 49.0890823, lng: 18.8041888 },
    { lat: 49.0887463, lng: 18.8056017 },
    { lat: 49.0883616, lng: 18.8064685 },
    { lat: 49.0884519, lng: 18.8071661 },
    { lat: 49.0884311, lng: 18.808092 },
    { lat: 49.0884014, lng: 18.808718 },
    { lat: 49.0884268, lng: 18.8096428 },
    { lat: 49.0884672, lng: 18.8107916 },
    { lat: 49.0885716, lng: 18.8120681 },
    { lat: 49.0886142, lng: 18.8128282 },
    { lat: 49.088657, lng: 18.8138685 },
    { lat: 49.088601, lng: 18.814191 },
    { lat: 49.088602, lng: 18.814191 },
    { lat: 49.090676, lng: 18.813806 },
    { lat: 49.091494, lng: 18.813963 },
    { lat: 49.092517, lng: 18.814768 },
    { lat: 49.093588, lng: 18.815879 },
    { lat: 49.093795, lng: 18.816094 },
    { lat: 49.09534, lng: 18.817532 },
    { lat: 49.095408, lng: 18.817596 },
    { lat: 49.095594, lng: 18.817689 },
    { lat: 49.096636, lng: 18.818212 },
    { lat: 49.097857, lng: 18.817762 },
    { lat: 49.098136, lng: 18.81751 },
    { lat: 49.098179, lng: 18.81747 },
    { lat: 49.098621, lng: 18.81707 },
    { lat: 49.09951, lng: 18.816264 },
    { lat: 49.0996233, lng: 18.8150153 },
    { lat: 49.0997279, lng: 18.8139459 },
    { lat: 49.1001251, lng: 18.8121195 },
    { lat: 49.1001704, lng: 18.8118852 },
    { lat: 49.1004908, lng: 18.8098169 },
    { lat: 49.1007847, lng: 18.8079136 },
    { lat: 49.1008069, lng: 18.8072748 },
    { lat: 49.1007341, lng: 18.8055868 },
    { lat: 49.1006999, lng: 18.8040612 },
    { lat: 49.101017, lng: 18.8024761 },
    { lat: 49.1012409, lng: 18.8016852 },
    { lat: 49.1014661, lng: 18.8011338 },
    { lat: 49.1016873, lng: 18.8005746 },
    { lat: 49.1020163, lng: 18.7996322 },
    { lat: 49.1022431, lng: 18.7983753 },
    { lat: 49.1022102, lng: 18.7969797 },
    { lat: 49.1019626, lng: 18.7965345 },
    { lat: 49.1019315, lng: 18.7960222 },
    { lat: 49.1021318, lng: 18.7957746 },
    { lat: 49.102486, lng: 18.7942781 },
    { lat: 49.1024475, lng: 18.7938628 },
    { lat: 49.1023571, lng: 18.7937481 },
    { lat: 49.1024244, lng: 18.7933963 },
    { lat: 49.1029566, lng: 18.7899635 },
    { lat: 49.1029607, lng: 18.7899145 },
    { lat: 49.1029082, lng: 18.7898373 },
    { lat: 49.1029046, lng: 18.7897035 },
    { lat: 49.1029712, lng: 18.7889434 },
    { lat: 49.1031664, lng: 18.7877301 },
    { lat: 49.1032787, lng: 18.7870951 },
    { lat: 49.1035735, lng: 18.7860148 },
    { lat: 49.1037997, lng: 18.7853351 },
    { lat: 49.1039805, lng: 18.7845406 },
    { lat: 49.1040293, lng: 18.7841795 },
    { lat: 49.1041213, lng: 18.7834991 },
    { lat: 49.1043544, lng: 18.7825612 },
    { lat: 49.1043919, lng: 18.7824497 },
    { lat: 49.1045341, lng: 18.7820274 },
    { lat: 49.1047404, lng: 18.7815703 },
    { lat: 49.1050278, lng: 18.7810663 },
    { lat: 49.1052627, lng: 18.7807259 },
    { lat: 49.1060036, lng: 18.7801215 },
    { lat: 49.1062701, lng: 18.780089 },
    { lat: 49.1064032, lng: 18.7800038 },
    { lat: 49.106537, lng: 18.7798635 },
    { lat: 49.1068215, lng: 18.7794833 },
    { lat: 49.1070747, lng: 18.7790579 },
    { lat: 49.1072037, lng: 18.7787623 },
    { lat: 49.1076263, lng: 18.7783251 },
    { lat: 49.1078364, lng: 18.7781549 },
    { lat: 49.1081435, lng: 18.777906 },
    { lat: 49.1082859, lng: 18.7777416 },
    { lat: 49.1083777, lng: 18.7775977 },
    { lat: 49.1083399, lng: 18.7774328 },
    { lat: 49.1083497, lng: 18.7773973 },
    { lat: 49.1083572, lng: 18.7773142 },
    { lat: 49.1083376, lng: 18.7771528 },
    { lat: 49.1082137, lng: 18.776419 },
    { lat: 49.1081442, lng: 18.7762313 },
    { lat: 49.1077039, lng: 18.7753994 },
    { lat: 49.1074796, lng: 18.775162 },
    { lat: 49.107315, lng: 18.7748626 },
    { lat: 49.1071278, lng: 18.7746413 },
    { lat: 49.1070626, lng: 18.7746049 },
    { lat: 49.1069924, lng: 18.774555 },
    { lat: 49.1068143, lng: 18.774384 },
    { lat: 49.1067814, lng: 18.7743424 },
    { lat: 49.1067105, lng: 18.7742526 },
    { lat: 49.1066453, lng: 18.7741701 },
    { lat: 49.1065801, lng: 18.7740648 },
    { lat: 49.1063943, lng: 18.7737646 },
    { lat: 49.1062921, lng: 18.773466 },
    { lat: 49.1061228, lng: 18.7729711 },
    { lat: 49.1060227, lng: 18.772823 },
    { lat: 49.1059561, lng: 18.7724557 },
    { lat: 49.1057935, lng: 18.7718784 },
    { lat: 49.1056711, lng: 18.7714436 },
    { lat: 49.105608, lng: 18.7711396 },
    { lat: 49.105594, lng: 18.7709804 },
    { lat: 49.1055862, lng: 18.7708079 },
    { lat: 49.1056254, lng: 18.7705245 },
    { lat: 49.1056411, lng: 18.7704114 },
    { lat: 49.1056664, lng: 18.7702472 },
    { lat: 49.1056915, lng: 18.7700807 },
    { lat: 49.1057003, lng: 18.7697957 },
    { lat: 49.1057168, lng: 18.7695735 },
    { lat: 49.1057442, lng: 18.7693413 },
    { lat: 49.1058047, lng: 18.7688981 },
    { lat: 49.1058142, lng: 18.7687288 },
    { lat: 49.105886, lng: 18.7679572 },
    { lat: 49.1059882, lng: 18.7669041 },
    { lat: 49.1060008, lng: 18.7667479 },
    { lat: 49.1059929, lng: 18.7663867 },
    { lat: 49.1059971, lng: 18.7661036 },
    { lat: 49.1060655, lng: 18.7658787 },
    { lat: 49.1061127, lng: 18.7656601 },
    { lat: 49.1061591, lng: 18.7654452 },
    { lat: 49.1062064, lng: 18.7653233 },
    { lat: 49.1062435, lng: 18.7652277 },
    { lat: 49.106417, lng: 18.7650484 },
    { lat: 49.1064243, lng: 18.7649524 },
    { lat: 49.1065636, lng: 18.7648273 },
    { lat: 49.1067371, lng: 18.7646715 },
    { lat: 49.1069519, lng: 18.7644786 },
    { lat: 49.1070597, lng: 18.7643818 },
    { lat: 49.1071217, lng: 18.7643357 },
    { lat: 49.1071854, lng: 18.7641726 },
    { lat: 49.1074237, lng: 18.7634005 },
    { lat: 49.1074675, lng: 18.763162 },
    { lat: 49.1074754, lng: 18.7627939 },
    { lat: 49.1074707, lng: 18.7626167 },
    { lat: 49.1074455, lng: 18.7624603 },
    { lat: 49.1074067, lng: 18.7623646 },
    { lat: 49.1073584, lng: 18.7623038 },
    { lat: 49.1072825, lng: 18.7622306 },
    { lat: 49.1071303, lng: 18.762018 },
    { lat: 49.1070986, lng: 18.7614963 },
    { lat: 49.1071153, lng: 18.7613169 },
    { lat: 49.1071552, lng: 18.7611314 },
    { lat: 49.1072528, lng: 18.7607971 },
    { lat: 49.10758, lng: 18.759745 },
    { lat: 49.1076301, lng: 18.759519 },
    { lat: 49.1076475, lng: 18.7594407 },
    { lat: 49.107967, lng: 18.7585583 },
    { lat: 49.1080908, lng: 18.757975 },
    { lat: 49.1081664, lng: 18.7575713 },
    { lat: 49.1081932, lng: 18.7574281 },
    { lat: 49.1084523, lng: 18.7568966 },
    { lat: 49.1084578, lng: 18.7567313 },
    { lat: 49.1084877, lng: 18.7565214 },
    { lat: 49.1085444, lng: 18.7564257 },
    { lat: 49.1086114, lng: 18.7561819 },
    { lat: 49.1086987, lng: 18.7560557 },
    { lat: 49.108788, lng: 18.7559232 },
    { lat: 49.1090472, lng: 18.7555387 },
    { lat: 49.1093042, lng: 18.755081 },
    { lat: 49.1096625, lng: 18.7543628 },
    { lat: 49.1098241, lng: 18.7539829 },
    { lat: 49.1098733, lng: 18.753802 },
    { lat: 49.10995, lng: 18.7534795 },
    { lat: 49.1100486, lng: 18.7532059 },
    { lat: 49.110158, lng: 18.7528388 },
    { lat: 49.1102315, lng: 18.7526946 },
    { lat: 49.1103005, lng: 18.7525898 },
    { lat: 49.1102976, lng: 18.7523396 },
    { lat: 49.1104412, lng: 18.7511815 },
    { lat: 49.1103937, lng: 18.7508635 },
    { lat: 49.1104413, lng: 18.7505825 },
    { lat: 49.1104947, lng: 18.7503292 },
    { lat: 49.1105877, lng: 18.7497132 },
    { lat: 49.1105935, lng: 18.7495901 },
    { lat: 49.1105742, lng: 18.749221 },
    { lat: 49.1105862, lng: 18.7489986 },
    { lat: 49.1106278, lng: 18.7487765 },
    { lat: 49.110685, lng: 18.7485944 },
    { lat: 49.1107611, lng: 18.7483521 },
    { lat: 49.1108615, lng: 18.7479228 },
    { lat: 49.1108799, lng: 18.7478537 },
    { lat: 49.1109512, lng: 18.7475862 },
    { lat: 49.1111007, lng: 18.7472106 },
    { lat: 49.111258, lng: 18.7468971 },
    { lat: 49.1114597, lng: 18.7465288 },
    { lat: 49.1116066, lng: 18.7461046 },
    { lat: 49.1117423, lng: 18.7456411 },
    { lat: 49.1117921, lng: 18.7454709 },
    { lat: 49.1118436, lng: 18.7451198 },
    { lat: 49.1118857, lng: 18.7449352 },
    { lat: 49.1118367, lng: 18.7446617 },
    { lat: 49.1118293, lng: 18.7445662 },
    { lat: 49.1118208, lng: 18.7444211 },
    { lat: 49.1118332, lng: 18.7442712 },
    { lat: 49.1124844, lng: 18.7425357 },
    { lat: 49.1130641, lng: 18.7411831 },
    { lat: 49.1133684, lng: 18.7407948 },
    { lat: 49.1134833, lng: 18.7405105 },
    { lat: 49.1136111, lng: 18.7403027 },
    { lat: 49.1137611, lng: 18.7401279 },
    { lat: 49.1141117, lng: 18.7397196 },
    { lat: 49.1143053, lng: 18.7395402 },
    { lat: 49.1145948, lng: 18.739383 },
    { lat: 49.1146719, lng: 18.739325 },
    { lat: 49.1147324, lng: 18.7392986 },
    { lat: 49.1148892, lng: 18.7390295 },
    { lat: 49.1149911, lng: 18.7388546 },
    { lat: 49.1152357, lng: 18.7385711 },
    { lat: 49.1156019, lng: 18.737578 },
    { lat: 49.1155506, lng: 18.7375461 },
    { lat: 49.1159452, lng: 18.7365986 },
    { lat: 49.1163028, lng: 18.7359564 },
    { lat: 49.1173093, lng: 18.7337716 },
    { lat: 49.118304, lng: 18.7313855 },
    { lat: 49.1184224, lng: 18.7311288 },
    { lat: 49.1190542, lng: 18.7296497 },
    { lat: 49.120297, lng: 18.7283409 },
    { lat: 49.1214292, lng: 18.7272018 },
    { lat: 49.1223744, lng: 18.7257247 },
    { lat: 49.1232313, lng: 18.7242816 },
    { lat: 49.1240882, lng: 18.723271 },
    { lat: 49.1248732, lng: 18.7223295 },
    { lat: 49.1250213, lng: 18.7220994 },
    { lat: 49.1252841, lng: 18.7215881 },
    { lat: 49.125446, lng: 18.7213621 },
    { lat: 49.1263097, lng: 18.7205435 },
    { lat: 49.1270916, lng: 18.7196931 },
    { lat: 49.1277535, lng: 18.7187723 },
    { lat: 49.1283061, lng: 18.7176146 },
    { lat: 49.1288709, lng: 18.7161709 },
    { lat: 49.1288905, lng: 18.7161346 },
    { lat: 49.1291771, lng: 18.7152171 },
    { lat: 49.1293364, lng: 18.7147284 },
    { lat: 49.1294659, lng: 18.7143201 },
    { lat: 49.1295102, lng: 18.7142189 },
    { lat: 49.1295635, lng: 18.7139236 },
    { lat: 49.1296056, lng: 18.7137363 },
    { lat: 49.1296107, lng: 18.7136003 },
    { lat: 49.1296256, lng: 18.7131951 },
    { lat: 49.1296257, lng: 18.713175 },
    { lat: 49.1296246, lng: 18.7131351 },
    { lat: 49.1296569, lng: 18.7131474 },
    { lat: 49.1296919, lng: 18.7131485 },
    { lat: 49.1297344, lng: 18.7130756 },
    { lat: 49.1297402, lng: 18.7130386 },
    { lat: 49.1297075, lng: 18.71295 },
    { lat: 49.1295685, lng: 18.7128597 },
    { lat: 49.1295527, lng: 18.7127755 },
    { lat: 49.1296503, lng: 18.7126988 },
    { lat: 49.1296912, lng: 18.7125336 },
    { lat: 49.129736, lng: 18.7124647 },
    { lat: 49.1300218, lng: 18.7120262 },
    { lat: 49.1301579, lng: 18.7116977 },
    { lat: 49.130222, lng: 18.7117115 },
    { lat: 49.1302566, lng: 18.7117278 },
    { lat: 49.1302587, lng: 18.7116825 },
    { lat: 49.1304608, lng: 18.7116423 },
    { lat: 49.1306681, lng: 18.7114456 },
    { lat: 49.1308029, lng: 18.7113532 },
    { lat: 49.1308326, lng: 18.7112707 },
    { lat: 49.1311531, lng: 18.7111469 },
    { lat: 49.1312382, lng: 18.7110537 },
    { lat: 49.1312605, lng: 18.7110056 },
    { lat: 49.1312738, lng: 18.7109352 },
    { lat: 49.1312662, lng: 18.7108346 },
    { lat: 49.1313012, lng: 18.7107053 },
    { lat: 49.1313201, lng: 18.7106778 },
    { lat: 49.1313797, lng: 18.7106532 },
    { lat: 49.1314228, lng: 18.7106365 },
    { lat: 49.1314492, lng: 18.7106041 },
    { lat: 49.1314902, lng: 18.7105259 },
    { lat: 49.1315117, lng: 18.7104841 },
    { lat: 49.131554, lng: 18.7104299 },
    { lat: 49.1316256, lng: 18.7104215 },
    { lat: 49.131656, lng: 18.7103987 },
    { lat: 49.1317507, lng: 18.7102493 },
    { lat: 49.1318269, lng: 18.7101517 },
    { lat: 49.1320177, lng: 18.7100513 },
    { lat: 49.1322531, lng: 18.7098947 },
    { lat: 49.1325387, lng: 18.7096229 },
    { lat: 49.1325772, lng: 18.7094511 },
    { lat: 49.1325318, lng: 18.7088895 },
    { lat: 49.1324524, lng: 18.7086251 },
    { lat: 49.1324828, lng: 18.7085159 },
    { lat: 49.1323256, lng: 18.7082279 },
    { lat: 49.1321919, lng: 18.707918 },
    { lat: 49.1318808, lng: 18.7073856 },
    { lat: 49.1318443, lng: 18.707165 },
    { lat: 49.1317957, lng: 18.707032 },
    { lat: 49.1318792, lng: 18.7069692 },
  ],
  VeľkáČierna: [
    { lat: 49.09429, lng: 18.561177 },
    { lat: 49.093649, lng: 18.559581 },
    { lat: 49.093213, lng: 18.559288 },
    { lat: 49.092744, lng: 18.558824 },
    { lat: 49.090706, lng: 18.557713 },
    { lat: 49.089008, lng: 18.556941 },
    { lat: 49.088668, lng: 18.556947 },
    { lat: 49.08761, lng: 18.556998 },
    { lat: 49.087588, lng: 18.557 },
    { lat: 49.087854, lng: 18.558171 },
    { lat: 49.088325, lng: 18.560247 },
    { lat: 49.08849, lng: 18.560525 },
    { lat: 49.088564, lng: 18.561058 },
    { lat: 49.088085, lng: 18.562365 },
    { lat: 49.087967, lng: 18.562845 },
    { lat: 49.087838, lng: 18.564041 },
    { lat: 49.08784, lng: 18.564529 },
    { lat: 49.087943, lng: 18.565781 },
    { lat: 49.08791, lng: 18.56637 },
    { lat: 49.087886, lng: 18.566637 },
    { lat: 49.087879, lng: 18.566716 },
    { lat: 49.087754, lng: 18.568073 },
    { lat: 49.087591, lng: 18.568561 },
    { lat: 49.087481, lng: 18.56899 },
    { lat: 49.087367, lng: 18.56964 },
    { lat: 49.087371, lng: 18.571188 },
    { lat: 49.087429, lng: 18.573044 },
    { lat: 49.087375, lng: 18.574391 },
    { lat: 49.08741, lng: 18.574834 },
    { lat: 49.087394, lng: 18.575221 },
    { lat: 49.087123, lng: 18.576207 },
    { lat: 49.0868, lng: 18.57705 },
    { lat: 49.086507, lng: 18.57798 },
    { lat: 49.0862, lng: 18.579029 },
    { lat: 49.085685, lng: 18.580325 },
    { lat: 49.085649, lng: 18.580416 },
    { lat: 49.085532, lng: 18.580388 },
    { lat: 49.085435, lng: 18.580366 },
    { lat: 49.085129, lng: 18.580942 },
    { lat: 49.08501, lng: 18.581068 },
    { lat: 49.084765, lng: 18.581393 },
    { lat: 49.084533, lng: 18.582026 },
    { lat: 49.084093, lng: 18.582884 },
    { lat: 49.084006, lng: 18.583053 },
    { lat: 49.083802, lng: 18.583343 },
    { lat: 49.083771, lng: 18.583456 },
    { lat: 49.083623, lng: 18.583987 },
    { lat: 49.083599, lng: 18.584078 },
    { lat: 49.08359, lng: 18.584106 },
    { lat: 49.083561, lng: 18.584542 },
    { lat: 49.083371, lng: 18.584473 },
    { lat: 49.083272, lng: 18.584527 },
    { lat: 49.083189, lng: 18.584573 },
    { lat: 49.08305, lng: 18.58465 },
    { lat: 49.082904, lng: 18.584896 },
    { lat: 49.082786, lng: 18.585294 },
    { lat: 49.082783, lng: 18.585316 },
    { lat: 49.082661, lng: 18.585976 },
    { lat: 49.082656, lng: 18.586004 },
    { lat: 49.082319, lng: 18.586068 },
    { lat: 49.082273, lng: 18.586077 },
    { lat: 49.082248, lng: 18.586179 },
    { lat: 49.082232, lng: 18.586242 },
    { lat: 49.082181, lng: 18.586448 },
    { lat: 49.0826389, lng: 18.5865875 },
    { lat: 49.0833163, lng: 18.5873548 },
    { lat: 49.0841244, lng: 18.5887099 },
    { lat: 49.084809, lng: 18.5902011 },
    { lat: 49.085238, lng: 18.5907122 },
    { lat: 49.085267, lng: 18.5907464 },
    { lat: 49.0852766, lng: 18.5907578 },
    { lat: 49.0852046, lng: 18.5908653 },
    { lat: 49.0849214, lng: 18.5912874 },
    { lat: 49.0847418, lng: 18.5915558 },
    { lat: 49.0843499, lng: 18.5917316 },
    { lat: 49.0842582, lng: 18.5921277 },
    { lat: 49.0842999, lng: 18.5923751 },
    { lat: 49.0843083, lng: 18.5924265 },
    { lat: 49.0843168, lng: 18.5924838 },
    { lat: 49.0843994, lng: 18.5927585 },
    { lat: 49.084474, lng: 18.5929723 },
    { lat: 49.0847539, lng: 18.5937143 },
    { lat: 49.084858, lng: 18.593928 },
    { lat: 49.0852282, lng: 18.5941626 },
    { lat: 49.0852935, lng: 18.5942034 },
    { lat: 49.0853449, lng: 18.5942363 },
    { lat: 49.0853926, lng: 18.5942667 },
    { lat: 49.0854016, lng: 18.5942839 },
    { lat: 49.0856939, lng: 18.5948337 },
    { lat: 49.0858781, lng: 18.5949068 },
    { lat: 49.0867756, lng: 18.5952349 },
    { lat: 49.0872411, lng: 18.5955063 },
    { lat: 49.0876043, lng: 18.5957169 },
    { lat: 49.0884078, lng: 18.5964169 },
    { lat: 49.0892888, lng: 18.597284 },
    { lat: 49.0889859, lng: 18.598092 },
    { lat: 49.0888077, lng: 18.5986155 },
    { lat: 49.0887517, lng: 18.5988149 },
    { lat: 49.0885903, lng: 18.5993391 },
    { lat: 49.0885211, lng: 18.5993096 },
    { lat: 49.088196, lng: 18.6001877 },
    { lat: 49.0877447, lng: 18.6006892 },
    { lat: 49.0870398, lng: 18.6013425 },
    { lat: 49.0868942, lng: 18.6019899 },
    { lat: 49.0865454, lng: 18.6022376 },
    { lat: 49.0862756, lng: 18.603369 },
    { lat: 49.0860728, lng: 18.6034557 },
    { lat: 49.0859145, lng: 18.6039165 },
    { lat: 49.0859509, lng: 18.6047197 },
    { lat: 49.0858065, lng: 18.6049698 },
    { lat: 49.0860578, lng: 18.6055362 },
    { lat: 49.0861472, lng: 18.6056877 },
    { lat: 49.0861916, lng: 18.6057625 },
    { lat: 49.0862314, lng: 18.6058298 },
    { lat: 49.0862747, lng: 18.6059022 },
    { lat: 49.0862911, lng: 18.6059351 },
    { lat: 49.0863239, lng: 18.6059994 },
    { lat: 49.0862841, lng: 18.6061004 },
    { lat: 49.0862728, lng: 18.6061791 },
    { lat: 49.0863802, lng: 18.6065037 },
    { lat: 49.0879221, lng: 18.6066501 },
    { lat: 49.0881142, lng: 18.6093552 },
    { lat: 49.08799, lng: 18.6101126 },
    { lat: 49.0879471, lng: 18.6108081 },
    { lat: 49.0881434, lng: 18.6111461 },
    { lat: 49.0885246, lng: 18.6106609 },
    { lat: 49.0887265, lng: 18.6102598 },
    { lat: 49.0899788, lng: 18.6088561 },
    { lat: 49.0903015, lng: 18.6083112 },
    { lat: 49.0910413, lng: 18.6085627 },
    { lat: 49.0914272, lng: 18.6084615 },
    { lat: 49.091619, lng: 18.6083981 },
    { lat: 49.091934, lng: 18.6073734 },
    { lat: 49.0919757, lng: 18.606276 },
    { lat: 49.0928611, lng: 18.6064065 },
    { lat: 49.0930187, lng: 18.6065467 },
    { lat: 49.0944818, lng: 18.6072082 },
    { lat: 49.0950563, lng: 18.6071318 },
    { lat: 49.0960713, lng: 18.6071483 },
    { lat: 49.0965161, lng: 18.6071806 },
    { lat: 49.0970271, lng: 18.6073939 },
    { lat: 49.0969248, lng: 18.6070416 },
    { lat: 49.0965683, lng: 18.606346 },
    { lat: 49.096459, lng: 18.6052316 },
    { lat: 49.0964526, lng: 18.6050676 },
    { lat: 49.0963992, lng: 18.6041767 },
    { lat: 49.0962585, lng: 18.6039289 },
    { lat: 49.0961357, lng: 18.6041191 },
    { lat: 49.0956394, lng: 18.603868 },
    { lat: 49.0953559, lng: 18.6036491 },
    { lat: 49.0952464, lng: 18.6035634 },
    { lat: 49.0951546, lng: 18.6034298 },
    { lat: 49.0948577, lng: 18.6030002 },
    { lat: 49.0943833, lng: 18.6023149 },
    { lat: 49.0934931, lng: 18.6012369 },
    { lat: 49.0932178, lng: 18.6009145 },
    { lat: 49.0930735, lng: 18.6015305 },
    { lat: 49.0929977, lng: 18.6012707 },
    { lat: 49.0924488, lng: 18.600982 },
    { lat: 49.0925286, lng: 18.6006633 },
    { lat: 49.0928131, lng: 18.5993436 },
    { lat: 49.0928496, lng: 18.5990324 },
    { lat: 49.0928208, lng: 18.5986459 },
    { lat: 49.0927207, lng: 18.5983348 },
    { lat: 49.0924725, lng: 18.597827 },
    { lat: 49.0926933, lng: 18.5975062 },
    { lat: 49.0936931, lng: 18.5963617 },
    { lat: 49.094, lng: 18.5958805 },
    { lat: 49.0941665, lng: 18.5957241 },
    { lat: 49.0943535, lng: 18.5956594 },
    { lat: 49.0947028, lng: 18.5956721 },
    { lat: 49.0952165, lng: 18.5957673 },
    { lat: 49.0957353, lng: 18.5958507 },
    { lat: 49.0963892, lng: 18.5960141 },
    { lat: 49.0966518, lng: 18.5960754 },
    { lat: 49.0971354, lng: 18.5961343 },
    { lat: 49.0973139, lng: 18.596201 },
    { lat: 49.0977189, lng: 18.5965624 },
    { lat: 49.0977583, lng: 18.5964333 },
    { lat: 49.0987292, lng: 18.5914516 },
    { lat: 49.0987648, lng: 18.5914194 },
    { lat: 49.0988751, lng: 18.5912668 },
    { lat: 49.0989434, lng: 18.5911967 },
    { lat: 49.1012816, lng: 18.5882799 },
    { lat: 49.1005642, lng: 18.5877513 },
    { lat: 49.1001744, lng: 18.5866481 },
    { lat: 49.1001496, lng: 18.5865442 },
    { lat: 49.100529, lng: 18.5855421 },
    { lat: 49.1009757, lng: 18.5848735 },
    { lat: 49.1012175, lng: 18.5843164 },
    { lat: 49.1020075, lng: 18.5835049 },
    { lat: 49.1020752, lng: 18.5833345 },
    { lat: 49.1022255, lng: 18.5829439 },
    { lat: 49.1043981, lng: 18.5810432 },
    { lat: 49.1046979, lng: 18.5808909 },
    { lat: 49.104973, lng: 18.580743 },
    { lat: 49.104928, lng: 18.580584 },
    { lat: 49.104872, lng: 18.580076 },
    { lat: 49.104829, lng: 18.579703 },
    { lat: 49.104604, lng: 18.57892 },
    { lat: 49.104513, lng: 18.578446 },
    { lat: 49.104445, lng: 18.577174 },
    { lat: 49.104001, lng: 18.576639 },
    { lat: 49.103719, lng: 18.576392 },
    { lat: 49.102805, lng: 18.575256 },
    { lat: 49.101509, lng: 18.574554 },
    { lat: 49.101006, lng: 18.574057 },
    { lat: 49.1008937, lng: 18.5723673 },
    { lat: 49.100812, lng: 18.571139 },
    { lat: 49.100777, lng: 18.570557 },
    { lat: 49.100346, lng: 18.569787 },
    { lat: 49.099065, lng: 18.568919 },
    { lat: 49.09788, lng: 18.567647 },
    { lat: 49.09594, lng: 18.564589 },
    { lat: 49.094825, lng: 18.563239 },
    { lat: 49.094232, lng: 18.562448 },
    { lat: 49.094066, lng: 18.562133 },
    { lat: 49.094026, lng: 18.561399 },
    { lat: 49.094177, lng: 18.561271 },
    { lat: 49.09429, lng: 18.561177 },
  ],
  Zbyňov: [
    { lat: 49.1347596, lng: 18.660934 },
    { lat: 49.134722, lng: 18.6574624 },
    { lat: 49.1353356, lng: 18.6570591 },
    { lat: 49.1356201, lng: 18.6572559 },
    { lat: 49.1356239, lng: 18.6574121 },
    { lat: 49.1365433, lng: 18.6588832 },
    { lat: 49.1365047, lng: 18.6589841 },
    { lat: 49.1367333, lng: 18.6594043 },
    { lat: 49.1370104, lng: 18.6599965 },
    { lat: 49.1371397, lng: 18.6599881 },
    { lat: 49.1376636, lng: 18.6598487 },
    { lat: 49.1378884, lng: 18.6594756 },
    { lat: 49.1386729, lng: 18.6570424 },
    { lat: 49.1395165, lng: 18.6552589 },
    { lat: 49.1402395, lng: 18.6546474 },
    { lat: 49.1407374, lng: 18.654295 },
    { lat: 49.1414617, lng: 18.6540684 },
    { lat: 49.1415931, lng: 18.6536931 },
    { lat: 49.1417898, lng: 18.6533839 },
    { lat: 49.1426221, lng: 18.6527698 },
    { lat: 49.1431585, lng: 18.6525065 },
    { lat: 49.14336, lng: 18.6526056 },
    { lat: 49.1436384, lng: 18.6523494 },
    { lat: 49.1453132, lng: 18.651499 },
    { lat: 49.1452651, lng: 18.6512729 },
    { lat: 49.1451491, lng: 18.6507455 },
    { lat: 49.1450621, lng: 18.6504464 },
    { lat: 49.1449383, lng: 18.650113 },
    { lat: 49.144683, lng: 18.6495101 },
    { lat: 49.1444192, lng: 18.6489173 },
    { lat: 49.1441976, lng: 18.6481861 },
    { lat: 49.1441479, lng: 18.6476317 },
    { lat: 49.1439707, lng: 18.6474778 },
    { lat: 49.1436786, lng: 18.6472012 },
    { lat: 49.1433484, lng: 18.64689 },
    { lat: 49.142721, lng: 18.6463735 },
    { lat: 49.1423862, lng: 18.6460146 },
    { lat: 49.142129, lng: 18.6459074 },
    { lat: 49.141661, lng: 18.645865 },
    { lat: 49.1411456, lng: 18.6455267 },
    { lat: 49.1408066, lng: 18.6452148 },
    { lat: 49.1406515, lng: 18.6449905 },
    { lat: 49.1403587, lng: 18.6444929 },
    { lat: 49.1403879, lng: 18.6440858 },
    { lat: 49.1402015, lng: 18.643762 },
    { lat: 49.1399443, lng: 18.6432945 },
    { lat: 49.1396695, lng: 18.6429175 },
    { lat: 49.1394253, lng: 18.6425259 },
    { lat: 49.1390851, lng: 18.6424923 },
    { lat: 49.1387643, lng: 18.6420356 },
    { lat: 49.138642, lng: 18.6417712 },
    { lat: 49.1385467, lng: 18.6412733 },
    { lat: 49.1384451, lng: 18.6410164 },
    { lat: 49.1382626, lng: 18.640833 },
    { lat: 49.1380896, lng: 18.6405874 },
    { lat: 49.1379592, lng: 18.6402563 },
    { lat: 49.137889, lng: 18.6398069 },
    { lat: 49.1377621, lng: 18.6394156 },
    { lat: 49.1376314, lng: 18.6391627 },
    { lat: 49.1373959, lng: 18.6386587 },
    { lat: 49.1372698, lng: 18.6384301 },
    { lat: 49.1370427, lng: 18.6381862 },
    { lat: 49.1373665, lng: 18.6375109 },
    { lat: 49.1379972, lng: 18.6360152 },
    { lat: 49.138266, lng: 18.6353865 },
    { lat: 49.1379381, lng: 18.6351354 },
    { lat: 49.1376863, lng: 18.6349594 },
    { lat: 49.1375351, lng: 18.6347706 },
    { lat: 49.1374407, lng: 18.6346264 },
    { lat: 49.1373424, lng: 18.6343688 },
    { lat: 49.1371473, lng: 18.6336077 },
    { lat: 49.1370436, lng: 18.6331213 },
    { lat: 49.1369419, lng: 18.6327377 },
    { lat: 49.1368495, lng: 18.6325248 },
    { lat: 49.1366194, lng: 18.6320818 },
    { lat: 49.1364319, lng: 18.6315881 },
    { lat: 49.1363683, lng: 18.6313252 },
    { lat: 49.1362346, lng: 18.6307017 },
    { lat: 49.1361825, lng: 18.6304647 },
    { lat: 49.1360871, lng: 18.6302179 },
    { lat: 49.1360128, lng: 18.6300909 },
    { lat: 49.1359037, lng: 18.629996 },
    { lat: 49.1355101, lng: 18.6297184 },
    { lat: 49.1352975, lng: 18.6295264 },
    { lat: 49.1351127, lng: 18.6293516 },
    { lat: 49.1350164, lng: 18.6292177 },
    { lat: 49.134862, lng: 18.6290254 },
    { lat: 49.134604, lng: 18.6287786 },
    { lat: 49.1343211, lng: 18.628613 },
    { lat: 49.1341304, lng: 18.6284634 },
    { lat: 49.1339861, lng: 18.6283361 },
    { lat: 49.1336496, lng: 18.6278411 },
    { lat: 49.1333965, lng: 18.6274734 },
    { lat: 49.1332458, lng: 18.6271633 },
    { lat: 49.1332056, lng: 18.6270044 },
    { lat: 49.1331666, lng: 18.6266717 },
    { lat: 49.1331029, lng: 18.6263904 },
    { lat: 49.1329452, lng: 18.6259893 },
    { lat: 49.1326683, lng: 18.6253847 },
    { lat: 49.1325371, lng: 18.6250603 },
    { lat: 49.1324662, lng: 18.6247496 },
    { lat: 49.1323816, lng: 18.6244082 },
    { lat: 49.1322855, lng: 18.6240643 },
    { lat: 49.132095, lng: 18.6236442 },
    { lat: 49.1320187, lng: 18.6234412 },
    { lat: 49.131744, lng: 18.622741 },
    { lat: 49.130518, lng: 18.622898 },
    { lat: 49.130178, lng: 18.623202 },
    { lat: 49.129869, lng: 18.623362 },
    { lat: 49.129012, lng: 18.623368 },
    { lat: 49.128003, lng: 18.623302 },
    { lat: 49.127753, lng: 18.62328 },
    { lat: 49.127519, lng: 18.623259 },
    { lat: 49.126522, lng: 18.623336 },
    { lat: 49.1267902, lng: 18.6249191 },
    { lat: 49.1269278, lng: 18.6252178 },
    { lat: 49.1273766, lng: 18.6258699 },
    { lat: 49.1265461, lng: 18.6271924 },
    { lat: 49.124821, lng: 18.6300761 },
    { lat: 49.1245711, lng: 18.6299587 },
    { lat: 49.1245086, lng: 18.6299916 },
    { lat: 49.1243764, lng: 18.6301585 },
    { lat: 49.124139, lng: 18.630338 },
    { lat: 49.1239642, lng: 18.630463 },
    { lat: 49.1238388, lng: 18.6304598 },
    { lat: 49.1236633, lng: 18.6305103 },
    { lat: 49.1235601, lng: 18.630566 },
    { lat: 49.12351, lng: 18.6310838 },
    { lat: 49.1234584, lng: 18.6315965 },
    { lat: 49.1233639, lng: 18.6320687 },
    { lat: 49.1232074, lng: 18.6325587 },
    { lat: 49.1230324, lng: 18.632917 },
    { lat: 49.1228019, lng: 18.6327367 },
    { lat: 49.1218311, lng: 18.6316416 },
    { lat: 49.1215416, lng: 18.6314552 },
    { lat: 49.1210037, lng: 18.631354 },
    { lat: 49.1209166, lng: 18.6313464 },
    { lat: 49.1208508, lng: 18.6313398 },
    { lat: 49.1206774, lng: 18.6314073 },
    { lat: 49.1206298, lng: 18.6314896 },
    { lat: 49.1206357, lng: 18.6316854 },
    { lat: 49.1205954, lng: 18.6317937 },
    { lat: 49.1204841, lng: 18.6318874 },
    { lat: 49.1202364, lng: 18.6321262 },
    { lat: 49.120045, lng: 18.6323656 },
    { lat: 49.1198109, lng: 18.6325238 },
    { lat: 49.1196801, lng: 18.6326498 },
    { lat: 49.1195668, lng: 18.6327225 },
    { lat: 49.1193998, lng: 18.6326402 },
    { lat: 49.1193755, lng: 18.6327742 },
    { lat: 49.1193109, lng: 18.6328744 },
    { lat: 49.1191662, lng: 18.6328235 },
    { lat: 49.1189781, lng: 18.6330639 },
    { lat: 49.1188095, lng: 18.6333681 },
    { lat: 49.1186848, lng: 18.6338037 },
    { lat: 49.1184519, lng: 18.6344064 },
    { lat: 49.1181572, lng: 18.6349505 },
    { lat: 49.1179866, lng: 18.6350944 },
    { lat: 49.1178676, lng: 18.6353132 },
    { lat: 49.117805, lng: 18.6356194 },
    { lat: 49.1176697, lng: 18.6360031 },
    { lat: 49.1175612, lng: 18.6363794 },
    { lat: 49.1174109, lng: 18.6365222 },
    { lat: 49.1172655, lng: 18.6367342 },
    { lat: 49.1170683, lng: 18.636994 },
    { lat: 49.1168704, lng: 18.6370451 },
    { lat: 49.116859, lng: 18.6375722 },
    { lat: 49.1167383, lng: 18.6375961 },
    { lat: 49.1165459, lng: 18.6380879 },
    { lat: 49.1163712, lng: 18.6381981 },
    { lat: 49.1160734, lng: 18.638218 },
    { lat: 49.1158986, lng: 18.6385967 },
    { lat: 49.1157735, lng: 18.6387699 },
    { lat: 49.1155336, lng: 18.6389244 },
    { lat: 49.1150429, lng: 18.6387529 },
    { lat: 49.1148508, lng: 18.6392331 },
    { lat: 49.114631, lng: 18.6394687 },
    { lat: 49.1145557, lng: 18.639554 },
    { lat: 49.1145355, lng: 18.6395791 },
    { lat: 49.1144583, lng: 18.6396549 },
    { lat: 49.1142556, lng: 18.6401051 },
    { lat: 49.114059, lng: 18.6402479 },
    { lat: 49.1139754, lng: 18.640368 },
    { lat: 49.1138106, lng: 18.6406026 },
    { lat: 49.1137059, lng: 18.6407937 },
    { lat: 49.1136812, lng: 18.6408626 },
    { lat: 49.1136134, lng: 18.6410489 },
    { lat: 49.1134402, lng: 18.6415253 },
    { lat: 49.113303, lng: 18.6421262 },
    { lat: 49.1132256, lng: 18.6424918 },
    { lat: 49.1131178, lng: 18.6427978 },
    { lat: 49.113113, lng: 18.6428142 },
    { lat: 49.1131022, lng: 18.6428433 },
    { lat: 49.1130952, lng: 18.6428614 },
    { lat: 49.1130439, lng: 18.6429988 },
    { lat: 49.1130183, lng: 18.64307 },
    { lat: 49.1129811, lng: 18.6431732 },
    { lat: 49.1138511, lng: 18.6449875 },
    { lat: 49.1127084, lng: 18.649139 },
    { lat: 49.1126008, lng: 18.649324 },
    { lat: 49.1125438, lng: 18.6495236 },
    { lat: 49.1125936, lng: 18.6496737 },
    { lat: 49.1127229, lng: 18.6501542 },
    { lat: 49.112823, lng: 18.6503838 },
    { lat: 49.1129501, lng: 18.6505686 },
    { lat: 49.1136953, lng: 18.6512202 },
    { lat: 49.1137947, lng: 18.6512893 },
    { lat: 49.1136253, lng: 18.6517936 },
    { lat: 49.1134541, lng: 18.6515649 },
    { lat: 49.112411, lng: 18.6509299 },
    { lat: 49.1123762, lng: 18.6509087 },
    { lat: 49.1122119, lng: 18.6513221 },
    { lat: 49.111991, lng: 18.6517673 },
    { lat: 49.1115365, lng: 18.6515126 },
    { lat: 49.111503, lng: 18.6516266 },
    { lat: 49.1115668, lng: 18.6520392 },
    { lat: 49.1116251, lng: 18.6521396 },
    { lat: 49.1108139, lng: 18.6514354 },
    { lat: 49.1102287, lng: 18.6508632 },
    { lat: 49.1094099, lng: 18.6501395 },
    { lat: 49.1083603, lng: 18.6536303 },
    { lat: 49.1109639, lng: 18.6557008 },
    { lat: 49.111115, lng: 18.6556573 },
    { lat: 49.1112672, lng: 18.6555727 },
    { lat: 49.1114857, lng: 18.6555681 },
    { lat: 49.1117842, lng: 18.6556486 },
    { lat: 49.1119587, lng: 18.6556192 },
    { lat: 49.1121601, lng: 18.6556722 },
    { lat: 49.112163, lng: 18.6556876 },
    { lat: 49.1121864, lng: 18.6558142 },
    { lat: 49.112231, lng: 18.6560562 },
    { lat: 49.1122676, lng: 18.6564034 },
    { lat: 49.1123163, lng: 18.6566829 },
    { lat: 49.1124288, lng: 18.6571694 },
    { lat: 49.1130262, lng: 18.6571588 },
    { lat: 49.1131739, lng: 18.6572043 },
    { lat: 49.1132768, lng: 18.6572104 },
    { lat: 49.1137262, lng: 18.6575787 },
    { lat: 49.1138921, lng: 18.6578139 },
    { lat: 49.1142119, lng: 18.6584041 },
    { lat: 49.114246, lng: 18.6584712 },
    { lat: 49.1144272, lng: 18.6590177 },
    { lat: 49.1145875, lng: 18.6595949 },
    { lat: 49.1145553, lng: 18.6597306 },
    { lat: 49.1146175, lng: 18.6600527 },
    { lat: 49.1146409, lng: 18.6602172 },
    { lat: 49.1147242, lng: 18.6606825 },
    { lat: 49.1147501, lng: 18.6610645 },
    { lat: 49.1147318, lng: 18.6611244 },
    { lat: 49.1147703, lng: 18.6614001 },
    { lat: 49.1151004, lng: 18.662118 },
    { lat: 49.11531, lng: 18.6625401 },
    { lat: 49.1155439, lng: 18.6626546 },
    { lat: 49.1155788, lng: 18.6626751 },
    { lat: 49.1159291, lng: 18.6627087 },
    { lat: 49.1160574, lng: 18.6629186 },
    { lat: 49.1162836, lng: 18.6628659 },
    { lat: 49.1165591, lng: 18.662873 },
    { lat: 49.1166455, lng: 18.6629506 },
    { lat: 49.1167015, lng: 18.6630892 },
    { lat: 49.1169525, lng: 18.6634651 },
    { lat: 49.1171211, lng: 18.6635819 },
    { lat: 49.1172871, lng: 18.663776 },
    { lat: 49.1173764, lng: 18.6637402 },
    { lat: 49.1174509, lng: 18.663866 },
    { lat: 49.117439, lng: 18.6640061 },
    { lat: 49.1175843, lng: 18.6643 },
    { lat: 49.1177425, lng: 18.664476 },
    { lat: 49.1178778, lng: 18.6647319 },
    { lat: 49.1180524, lng: 18.6650028 },
    { lat: 49.1182939, lng: 18.664964 },
    { lat: 49.1184178, lng: 18.6649823 },
    { lat: 49.1186209, lng: 18.6650909 },
    { lat: 49.1188127, lng: 18.6652408 },
    { lat: 49.1190197, lng: 18.6654641 },
    { lat: 49.1191869, lng: 18.6656851 },
    { lat: 49.1196867, lng: 18.665283 },
    { lat: 49.1205053, lng: 18.6663672 },
    { lat: 49.1206075, lng: 18.6665289 },
    { lat: 49.1209403, lng: 18.667145 },
    { lat: 49.1210251, lng: 18.6673006 },
    { lat: 49.1210348, lng: 18.6672926 },
    { lat: 49.1213803, lng: 18.6670023 },
    { lat: 49.1215424, lng: 18.667336 },
    { lat: 49.1217167, lng: 18.6672018 },
    { lat: 49.1217764, lng: 18.6671545 },
    { lat: 49.1218075, lng: 18.6671485 },
    { lat: 49.1218387, lng: 18.6671627 },
    { lat: 49.121859, lng: 18.6672027 },
    { lat: 49.1218872, lng: 18.6672885 },
    { lat: 49.121941, lng: 18.6674518 },
    { lat: 49.1220407, lng: 18.6673744 },
    { lat: 49.122281, lng: 18.6671868 },
    { lat: 49.1226335, lng: 18.6669227 },
    { lat: 49.1226028, lng: 18.6667889 },
    { lat: 49.1227117, lng: 18.6667161 },
    { lat: 49.1229057, lng: 18.6665868 },
    { lat: 49.1229356, lng: 18.6666655 },
    { lat: 49.1230308, lng: 18.6665674 },
    { lat: 49.1245552, lng: 18.6648581 },
    { lat: 49.1256806, lng: 18.6638778 },
    { lat: 49.1257172, lng: 18.6638462 },
    { lat: 49.125584, lng: 18.6635674 },
    { lat: 49.1254348, lng: 18.663262 },
    { lat: 49.1251538, lng: 18.6628966 },
    { lat: 49.1250146, lng: 18.6625878 },
    { lat: 49.1244946, lng: 18.6611655 },
    { lat: 49.1245731, lng: 18.6611133 },
    { lat: 49.1246188, lng: 18.6610821 },
    { lat: 49.1245401, lng: 18.6608513 },
    { lat: 49.1241787, lng: 18.6602487 },
    { lat: 49.1241575, lng: 18.6602099 },
    { lat: 49.1242676, lng: 18.6601373 },
    { lat: 49.1243542, lng: 18.6601306 },
    { lat: 49.1244121, lng: 18.6600544 },
    { lat: 49.1245417, lng: 18.6597316 },
    { lat: 49.124701, lng: 18.6595276 },
    { lat: 49.124749, lng: 18.6595188 },
    { lat: 49.12477, lng: 18.6594655 },
    { lat: 49.1248461, lng: 18.6594114 },
    { lat: 49.1249038, lng: 18.6593093 },
    { lat: 49.124982, lng: 18.6593176 },
    { lat: 49.1250304, lng: 18.6593801 },
    { lat: 49.1250853, lng: 18.6594162 },
    { lat: 49.1251537, lng: 18.6593883 },
    { lat: 49.1252521, lng: 18.6592433 },
    { lat: 49.1253864, lng: 18.6592441 },
    { lat: 49.1254289, lng: 18.6592835 },
    { lat: 49.1254593, lng: 18.6592588 },
    { lat: 49.1256046, lng: 18.6592766 },
    { lat: 49.12568, lng: 18.659339 },
    { lat: 49.1258195, lng: 18.6593542 },
    { lat: 49.1259209, lng: 18.6593266 },
    { lat: 49.1259588, lng: 18.6593431 },
    { lat: 49.125983, lng: 18.6594781 },
    { lat: 49.1260039, lng: 18.6594892 },
    { lat: 49.1261066, lng: 18.6594693 },
    { lat: 49.1261613, lng: 18.6596545 },
    { lat: 49.126332, lng: 18.6597221 },
    { lat: 49.1263965, lng: 18.6598322 },
    { lat: 49.1264331, lng: 18.65997 },
    { lat: 49.126647, lng: 18.659984 },
    { lat: 49.1267993, lng: 18.6599861 },
    { lat: 49.1269252, lng: 18.6600613 },
    { lat: 49.127276, lng: 18.6610833 },
    { lat: 49.1275386, lng: 18.6616148 },
    { lat: 49.1273585, lng: 18.6618941 },
    { lat: 49.1273323, lng: 18.6619366 },
    { lat: 49.1272568, lng: 18.6620629 },
    { lat: 49.127081, lng: 18.6623626 },
    { lat: 49.12714, lng: 18.6624324 },
    { lat: 49.1275262, lng: 18.6628922 },
    { lat: 49.1277733, lng: 18.6633393 },
    { lat: 49.1279977, lng: 18.6637218 },
    { lat: 49.127891, lng: 18.6646863 },
    { lat: 49.1276663, lng: 18.6650226 },
    { lat: 49.1276658, lng: 18.6650338 },
    { lat: 49.1276295, lng: 18.6650718 },
    { lat: 49.127378, lng: 18.6654552 },
    { lat: 49.1273355, lng: 18.6655048 },
    { lat: 49.1272966, lng: 18.6655772 },
    { lat: 49.127362, lng: 18.6659649 },
    { lat: 49.1273875, lng: 18.6664011 },
    { lat: 49.1274224, lng: 18.6666125 },
    { lat: 49.1275229, lng: 18.6663607 },
    { lat: 49.1275864, lng: 18.6661537 },
    { lat: 49.1277015, lng: 18.6657925 },
    { lat: 49.1277857, lng: 18.6655318 },
    { lat: 49.1278061, lng: 18.6654699 },
    { lat: 49.1278873, lng: 18.6651913 },
    { lat: 49.1279087, lng: 18.6647666 },
    { lat: 49.1279621, lng: 18.6646858 },
    { lat: 49.1280418, lng: 18.6645972 },
    { lat: 49.1281759, lng: 18.6643599 },
    { lat: 49.1282985, lng: 18.6641846 },
    { lat: 49.1284784, lng: 18.6639802 },
    { lat: 49.128973, lng: 18.6635257 },
    { lat: 49.1289585, lng: 18.6635059 },
    { lat: 49.128835, lng: 18.6629244 },
    { lat: 49.1292307, lng: 18.6624083 },
    { lat: 49.1294985, lng: 18.6621034 },
    { lat: 49.1303572, lng: 18.6613214 },
    { lat: 49.1310362, lng: 18.6613663 },
    { lat: 49.1318009, lng: 18.6613265 },
    { lat: 49.1334565, lng: 18.6605636 },
    { lat: 49.1347596, lng: 18.660934 },
  ],
  Čičmany: [
    { lat: 48.989415, lng: 18.527061 },
    { lat: 48.989233, lng: 18.526461 },
    { lat: 48.989045, lng: 18.525544 },
    { lat: 48.988516, lng: 18.524896 },
    { lat: 48.988058, lng: 18.523685 },
    { lat: 48.987558, lng: 18.523128 },
    { lat: 48.987076, lng: 18.521938 },
    { lat: 48.987005, lng: 18.521188 },
    { lat: 48.986675, lng: 18.520478 },
    { lat: 48.986195, lng: 18.519428 },
    { lat: 48.98598, lng: 18.51876 },
    { lat: 48.985879, lng: 18.51846 },
    { lat: 48.98577, lng: 18.518026 },
    { lat: 48.985386, lng: 18.51662 },
    { lat: 48.985189, lng: 18.515699 },
    { lat: 48.984696, lng: 18.514572 },
    { lat: 48.9844, lng: 18.513849 },
    { lat: 48.984255, lng: 18.513453 },
    { lat: 48.983889, lng: 18.513141 },
    { lat: 48.983606, lng: 18.51252 },
    { lat: 48.983551, lng: 18.512289 },
    { lat: 48.983289, lng: 18.511648 },
    { lat: 48.983206, lng: 18.510873 },
    { lat: 48.982996, lng: 18.510773 },
    { lat: 48.982895, lng: 18.510639 },
    { lat: 48.982753, lng: 18.510177 },
    { lat: 48.982376, lng: 18.509481 },
    { lat: 48.981907, lng: 18.509071 },
    { lat: 48.981476, lng: 18.508988 },
    { lat: 48.981392, lng: 18.509069 },
    { lat: 48.981094, lng: 18.509351 },
    { lat: 48.980991, lng: 18.50932 },
    { lat: 48.980549, lng: 18.509281 },
    { lat: 48.980162, lng: 18.509181 },
    { lat: 48.979362, lng: 18.509017 },
    { lat: 48.97896, lng: 18.508882 },
    { lat: 48.978121, lng: 18.508712 },
    { lat: 48.977205, lng: 18.508551 },
    { lat: 48.9770075, lng: 18.5085268 },
    { lat: 48.976822, lng: 18.508504 },
    { lat: 48.976788, lng: 18.5085 },
    { lat: 48.976687, lng: 18.508487 },
    { lat: 48.976629, lng: 18.50848 },
    { lat: 48.9761, lng: 18.508197 },
    { lat: 48.97563, lng: 18.508259 },
    { lat: 48.975325, lng: 18.508174 },
    { lat: 48.974534, lng: 18.507514 },
    { lat: 48.974401, lng: 18.507397 },
    { lat: 48.974248, lng: 18.507263 },
    { lat: 48.974045, lng: 18.506917 },
    { lat: 48.973705, lng: 18.506337 },
    { lat: 48.973349, lng: 18.506098 },
    { lat: 48.97288, lng: 18.506066 },
    { lat: 48.972718, lng: 18.506042 },
    { lat: 48.97198, lng: 18.50603 },
    { lat: 48.971033, lng: 18.506223 },
    { lat: 48.970634, lng: 18.50559 },
    { lat: 48.97021, lng: 18.504654 },
    { lat: 48.970078, lng: 18.504267 },
    { lat: 48.969359, lng: 18.503432 },
    { lat: 48.969049, lng: 18.503296 },
    { lat: 48.968619, lng: 18.502909 },
    { lat: 48.968111, lng: 18.502219 },
    { lat: 48.967633, lng: 18.502051 },
    { lat: 48.967282, lng: 18.501999 },
    { lat: 48.966961, lng: 18.501631 },
    { lat: 48.966539, lng: 18.501182 },
    { lat: 48.965911, lng: 18.500031 },
    { lat: 48.965566, lng: 18.4997 },
    { lat: 48.965266, lng: 18.499285 },
    { lat: 48.964981, lng: 18.498622 },
    { lat: 48.964135, lng: 18.497734 },
    { lat: 48.963535, lng: 18.497191 },
    { lat: 48.963541, lng: 18.495873 },
    { lat: 48.963656, lng: 18.494751 },
    { lat: 48.9637085, lng: 18.4945594 },
    { lat: 48.963763, lng: 18.494276 },
    { lat: 48.963794, lng: 18.492984 },
    { lat: 48.963892, lng: 18.492304 },
    { lat: 48.963939, lng: 18.491272 },
    { lat: 48.964079, lng: 18.490466 },
    { lat: 48.96384, lng: 18.489549 },
    { lat: 48.963287, lng: 18.488459 },
    { lat: 48.963137, lng: 18.487725 },
    { lat: 48.963013, lng: 18.487378 },
    { lat: 48.962943, lng: 18.486923 },
    { lat: 48.962876, lng: 18.486603 },
    { lat: 48.962724, lng: 18.486007 },
    { lat: 48.962612, lng: 18.485642 },
    { lat: 48.962618, lng: 18.484937 },
    { lat: 48.962586, lng: 18.484024 },
    { lat: 48.962546, lng: 18.483848 },
    { lat: 48.962137, lng: 18.482739 },
    { lat: 48.961733, lng: 18.481878 },
    { lat: 48.961679, lng: 18.481487 },
    { lat: 48.96156, lng: 18.480822 },
    { lat: 48.961419, lng: 18.480393 },
    { lat: 48.961249, lng: 18.479874 },
    { lat: 48.961155, lng: 18.479666 },
    { lat: 48.960549, lng: 18.479201 },
    { lat: 48.960164, lng: 18.478635 },
    { lat: 48.959852, lng: 18.478101 },
    { lat: 48.959509, lng: 18.477518 },
    { lat: 48.959151, lng: 18.477038 },
    { lat: 48.958861, lng: 18.476608 },
    { lat: 48.9583328, lng: 18.4755676 },
    { lat: 48.958266, lng: 18.475436 },
    { lat: 48.957902, lng: 18.47498 },
    { lat: 48.957615, lng: 18.474757 },
    { lat: 48.957527, lng: 18.474666 },
    { lat: 48.957174, lng: 18.474334 },
    { lat: 48.957078, lng: 18.473734 },
    { lat: 48.956921, lng: 18.472888 },
    { lat: 48.956614, lng: 18.472219 },
    { lat: 48.956741, lng: 18.471765 },
    { lat: 48.956868, lng: 18.471155 },
    { lat: 48.957062, lng: 18.470287 },
    { lat: 48.957048, lng: 18.470222 },
    { lat: 48.956985, lng: 18.469657 },
    { lat: 48.95674, lng: 18.468382 },
    { lat: 48.956571, lng: 18.467521 },
    { lat: 48.956443, lng: 18.466629 },
    { lat: 48.956367, lng: 18.465987 },
    { lat: 48.95628, lng: 18.465388 },
    { lat: 48.95636, lng: 18.464963 },
    { lat: 48.955922, lng: 18.464203 },
    { lat: 48.955799, lng: 18.463954 },
    { lat: 48.955632, lng: 18.463733 },
    { lat: 48.955203, lng: 18.463475 },
    { lat: 48.954925, lng: 18.463108 },
    { lat: 48.954569, lng: 18.46314 },
    { lat: 48.954348, lng: 18.462887 },
    { lat: 48.954199, lng: 18.462987 },
    { lat: 48.954125, lng: 18.463037 },
    { lat: 48.95385, lng: 18.463338 },
    { lat: 48.953661, lng: 18.463439 },
    { lat: 48.953158, lng: 18.463747 },
    { lat: 48.95292, lng: 18.463792 },
    { lat: 48.952696, lng: 18.463855 },
    { lat: 48.952495, lng: 18.463899 },
    { lat: 48.95188, lng: 18.463884 },
    { lat: 48.951602, lng: 18.464051 },
    { lat: 48.951002, lng: 18.463878 },
    { lat: 48.950601, lng: 18.463922 },
    { lat: 48.950263, lng: 18.464184 },
    { lat: 48.950029, lng: 18.464238 },
    { lat: 48.949042, lng: 18.46511 },
    { lat: 48.948559, lng: 18.46555 },
    { lat: 48.948036, lng: 18.46594 },
    { lat: 48.947736, lng: 18.466237 },
    { lat: 48.947447, lng: 18.466638 },
    { lat: 48.947282, lng: 18.467135 },
    { lat: 48.946895, lng: 18.46761 },
    { lat: 48.946261, lng: 18.468527 },
    { lat: 48.945722, lng: 18.469114 },
    { lat: 48.945428, lng: 18.469404 },
    { lat: 48.945257, lng: 18.469651 },
    { lat: 48.944442, lng: 18.470757 },
    { lat: 48.944235, lng: 18.471025 },
    { lat: 48.943894, lng: 18.471321 },
    { lat: 48.943571, lng: 18.472021 },
    { lat: 48.943186, lng: 18.472783 },
    { lat: 48.942916, lng: 18.473008 },
    { lat: 48.942579, lng: 18.473174 },
    { lat: 48.941646, lng: 18.473694 },
    { lat: 48.941331, lng: 18.47378 },
    { lat: 48.941281, lng: 18.473775 },
    { lat: 48.941257, lng: 18.473835 },
    { lat: 48.941217, lng: 18.473941 },
    { lat: 48.941103, lng: 18.47445 },
    { lat: 48.940951, lng: 18.475164 },
    { lat: 48.940855, lng: 18.475363 },
    { lat: 48.940552, lng: 18.475965 },
    { lat: 48.940321, lng: 18.476326 },
    { lat: 48.940197, lng: 18.476642 },
    { lat: 48.940224, lng: 18.47694 },
    { lat: 48.940753, lng: 18.477744 },
    { lat: 48.94089, lng: 18.478015 },
    { lat: 48.940963, lng: 18.478203 },
    { lat: 48.941235, lng: 18.478384 },
    { lat: 48.941377, lng: 18.478865 },
    { lat: 48.94147, lng: 18.479022 },
    { lat: 48.941321, lng: 18.479251 },
    { lat: 48.941149, lng: 18.479341 },
    { lat: 48.940983, lng: 18.47936 },
    { lat: 48.940837, lng: 18.479377 },
    { lat: 48.940411, lng: 18.479207 },
    { lat: 48.939829, lng: 18.479078 },
    { lat: 48.939608, lng: 18.479362 },
    { lat: 48.939627, lng: 18.479377 },
    { lat: 48.939562, lng: 18.479578 },
    { lat: 48.939722, lng: 18.480265 },
    { lat: 48.93982, lng: 18.480801 },
    { lat: 48.939769, lng: 18.481134 },
    { lat: 48.939722, lng: 18.481597 },
    { lat: 48.939823, lng: 18.482577 },
    { lat: 48.939792, lng: 18.482925 },
    { lat: 48.939664, lng: 18.483572 },
    { lat: 48.939702, lng: 18.483936 },
    { lat: 48.939638, lng: 18.484644 },
    { lat: 48.939994, lng: 18.485375 },
    { lat: 48.939946, lng: 18.485976 },
    { lat: 48.939941, lng: 18.486681 },
    { lat: 48.940108, lng: 18.48717 },
    { lat: 48.940415, lng: 18.487529 },
    { lat: 48.940682, lng: 18.487691 },
    { lat: 48.940916, lng: 18.488085 },
    { lat: 48.94118, lng: 18.488826 },
    { lat: 48.941206, lng: 18.489367 },
    { lat: 48.941215, lng: 18.489548 },
    { lat: 48.941219, lng: 18.489637 },
    { lat: 48.941216, lng: 18.489712 },
    { lat: 48.941212, lng: 18.489819 },
    { lat: 48.941197, lng: 18.490215 },
    { lat: 48.941176, lng: 18.490724 },
    { lat: 48.941209, lng: 18.491688 },
    { lat: 48.94121, lng: 18.492693 },
    { lat: 48.94121, lng: 18.493089 },
    { lat: 48.941132, lng: 18.49349 },
    { lat: 48.941025, lng: 18.493708 },
    { lat: 48.940892, lng: 18.493888 },
    { lat: 48.940699, lng: 18.494468 },
    { lat: 48.940707, lng: 18.494742 },
    { lat: 48.940667, lng: 18.495004 },
    { lat: 48.94072, lng: 18.495134 },
    { lat: 48.940475, lng: 18.495814 },
    { lat: 48.940336, lng: 18.496538 },
    { lat: 48.940197, lng: 18.496594 },
    { lat: 48.940141, lng: 18.496616 },
    { lat: 48.93988, lng: 18.49672 },
    { lat: 48.940195, lng: 18.498234 },
    { lat: 48.940734, lng: 18.498318 },
    { lat: 48.940623, lng: 18.500295 },
    { lat: 48.940603, lng: 18.500282 },
    { lat: 48.940571, lng: 18.500791 },
    { lat: 48.940297, lng: 18.501632 },
    { lat: 48.93999, lng: 18.50245 },
    { lat: 48.940102, lng: 18.503134 },
    { lat: 48.940201, lng: 18.503696 },
    { lat: 48.940135, lng: 18.504237 },
    { lat: 48.940015, lng: 18.504779 },
    { lat: 48.939907, lng: 18.505306 },
    { lat: 48.939927, lng: 18.505571 },
    { lat: 48.939935, lng: 18.505913 },
    { lat: 48.939908, lng: 18.506231 },
    { lat: 48.939871, lng: 18.506514 },
    { lat: 48.939762, lng: 18.506784 },
    { lat: 48.939645, lng: 18.506987 },
    { lat: 48.939433, lng: 18.507329 },
    { lat: 48.939406, lng: 18.507372 },
    { lat: 48.939306, lng: 18.507486 },
    { lat: 48.939096, lng: 18.507727 },
    { lat: 48.939004, lng: 18.507869 },
    { lat: 48.938899, lng: 18.508025 },
    { lat: 48.938885, lng: 18.508036 },
    { lat: 48.938649, lng: 18.508212 },
    { lat: 48.938201, lng: 18.508554 },
    { lat: 48.93809, lng: 18.508631 },
    { lat: 48.937853, lng: 18.508794 },
    { lat: 48.937873, lng: 18.509053 },
    { lat: 48.937876, lng: 18.509085 },
    { lat: 48.937875, lng: 18.509091 },
    { lat: 48.937847, lng: 18.509225 },
    { lat: 48.937787, lng: 18.509432 },
    { lat: 48.937784, lng: 18.509445 },
    { lat: 48.937587, lng: 18.5099 },
    { lat: 48.937526, lng: 18.510073 },
    { lat: 48.937304, lng: 18.510687 },
    { lat: 48.937291, lng: 18.510731 },
    { lat: 48.937055, lng: 18.511504 },
    { lat: 48.936929, lng: 18.512005 },
    { lat: 48.936881, lng: 18.512572 },
    { lat: 48.93686, lng: 18.513074 },
    { lat: 48.936835, lng: 18.513327 },
    { lat: 48.936878, lng: 18.513757 },
    { lat: 48.936869, lng: 18.514178 },
    { lat: 48.936878, lng: 18.514441 },
    { lat: 48.936884, lng: 18.515313 },
    { lat: 48.936776, lng: 18.515729 },
    { lat: 48.936777, lng: 18.516095 },
    { lat: 48.936754, lng: 18.516458 },
    { lat: 48.9367, lng: 18.516585 },
    { lat: 48.936468, lng: 18.517538 },
    { lat: 48.936703, lng: 18.517826 },
    { lat: 48.936716, lng: 18.517843 },
    { lat: 48.93673, lng: 18.517875 },
    { lat: 48.936839, lng: 18.518122 },
    { lat: 48.936984, lng: 18.518452 },
    { lat: 48.937112, lng: 18.518873 },
    { lat: 48.937151, lng: 18.519445 },
    { lat: 48.937174, lng: 18.520605 },
    { lat: 48.93719, lng: 18.52155 },
    { lat: 48.937189, lng: 18.522144 },
    { lat: 48.937227, lng: 18.522822 },
    { lat: 48.937227, lng: 18.522829 },
    { lat: 48.937164, lng: 18.523016 },
    { lat: 48.937052, lng: 18.523407 },
    { lat: 48.93735, lng: 18.523842 },
    { lat: 48.937513, lng: 18.52408 },
    { lat: 48.937756, lng: 18.524634 },
    { lat: 48.938002, lng: 18.525377 },
    { lat: 48.93838, lng: 18.526005 },
    { lat: 48.938956, lng: 18.526623 },
    { lat: 48.939146, lng: 18.527047 },
    { lat: 48.939255, lng: 18.527393 },
    { lat: 48.939579, lng: 18.527914 },
    { lat: 48.939762, lng: 18.52821 },
    { lat: 48.939924, lng: 18.528192 },
    { lat: 48.94051, lng: 18.52813 },
    { lat: 48.940896, lng: 18.529102 },
    { lat: 48.941235, lng: 18.530067 },
    { lat: 48.941799, lng: 18.531542 },
    { lat: 48.941916, lng: 18.532156 },
    { lat: 48.942059, lng: 18.532158 },
    { lat: 48.942406, lng: 18.532649 },
    { lat: 48.942624, lng: 18.533299 },
    { lat: 48.942798, lng: 18.533201 },
    { lat: 48.94301, lng: 18.533411 },
    { lat: 48.943045, lng: 18.533446 },
    { lat: 48.9431, lng: 18.5335 },
    { lat: 48.943246, lng: 18.533517 },
    { lat: 48.943404, lng: 18.532541 },
    { lat: 48.94399, lng: 18.533167 },
    { lat: 48.944099, lng: 18.533683 },
    { lat: 48.944905, lng: 18.534856 },
    { lat: 48.945305, lng: 18.535589 },
    { lat: 48.945573, lng: 18.536254 },
    { lat: 48.945509, lng: 18.536514 },
    { lat: 48.946083, lng: 18.537745 },
    { lat: 48.946136, lng: 18.5378073 },
    { lat: 48.946755, lng: 18.538535 },
    { lat: 48.947148, lng: 18.539755 },
    { lat: 48.94721, lng: 18.54016 },
    { lat: 48.947219, lng: 18.540286 },
    { lat: 48.947225, lng: 18.540362 },
    { lat: 48.947246, lng: 18.540645 },
    { lat: 48.947255, lng: 18.540757 },
    { lat: 48.947314, lng: 18.541556 },
    { lat: 48.947351, lng: 18.542549 },
    { lat: 48.9474172, lng: 18.5438526 },
    { lat: 48.94752, lng: 18.546624 },
    { lat: 48.947581, lng: 18.547081 },
    { lat: 48.947833, lng: 18.548667 },
    { lat: 48.947865, lng: 18.549856 },
    { lat: 48.947882, lng: 18.550475 },
    { lat: 48.947897, lng: 18.552819 },
    { lat: 48.947961, lng: 18.553697 },
    { lat: 48.948118, lng: 18.555923 },
    { lat: 48.948241, lng: 18.556778 },
    { lat: 48.948258, lng: 18.556843 },
    { lat: 48.948514, lng: 18.55752 },
    { lat: 48.948961, lng: 18.558668 },
    { lat: 48.949335, lng: 18.559806 },
    { lat: 48.949942, lng: 18.560743 },
    { lat: 48.95045, lng: 18.561415 },
    { lat: 48.951191, lng: 18.562344 },
    { lat: 48.951501, lng: 18.561993 },
    { lat: 48.952066, lng: 18.561481 },
    { lat: 48.952651, lng: 18.561184 },
    { lat: 48.954018, lng: 18.560126 },
    { lat: 48.954056, lng: 18.560099 },
    { lat: 48.95408, lng: 18.560083 },
    { lat: 48.954118, lng: 18.560059 },
    { lat: 48.9543697, lng: 18.5599156 },
    { lat: 48.9544969, lng: 18.5598324 },
    { lat: 48.954727, lng: 18.559712 },
    { lat: 48.954807, lng: 18.559746 },
    { lat: 48.955269, lng: 18.560024 },
    { lat: 48.955731, lng: 18.560705 },
    { lat: 48.956279, lng: 18.561291 },
    { lat: 48.9567072, lng: 18.5615032 },
    { lat: 48.957186, lng: 18.561557 },
    { lat: 48.957561, lng: 18.561585 },
    { lat: 48.958144, lng: 18.562126 },
    { lat: 48.958443, lng: 18.562398 },
    { lat: 48.958976, lng: 18.563167 },
    { lat: 48.959258, lng: 18.563719 },
    { lat: 48.959424, lng: 18.564086 },
    { lat: 48.960002, lng: 18.564216 },
    { lat: 48.960807, lng: 18.564823 },
    { lat: 48.961137, lng: 18.56516 },
    { lat: 48.961436, lng: 18.565454 },
    { lat: 48.962508, lng: 18.56702 },
    { lat: 48.963371, lng: 18.568181 },
    { lat: 48.963911, lng: 18.568578 },
    { lat: 48.964509, lng: 18.569402 },
    { lat: 48.964898, lng: 18.569844 },
    { lat: 48.965209, lng: 18.570186 },
    { lat: 48.965415, lng: 18.571152 },
    { lat: 48.965883, lng: 18.572317 },
    { lat: 48.966257, lng: 18.572927 },
    { lat: 48.966688, lng: 18.57394 },
    { lat: 48.96764, lng: 18.574287 },
    { lat: 48.968855, lng: 18.57309 },
    { lat: 48.969278, lng: 18.573385 },
    { lat: 48.969835, lng: 18.57363 },
    { lat: 48.969835, lng: 18.573528 },
    { lat: 48.9695663, lng: 18.5730048 },
    { lat: 48.9698224, lng: 18.5717104 },
    { lat: 48.9699546, lng: 18.5706474 },
    { lat: 48.9700885, lng: 18.5695789 },
    { lat: 48.9702203, lng: 18.5687809 },
    { lat: 48.9703945, lng: 18.5680197 },
    { lat: 48.970624, lng: 18.5674016 },
    { lat: 48.9711035, lng: 18.5665926 },
    { lat: 48.9713369, lng: 18.5660856 },
    { lat: 48.9715723, lng: 18.5658104 },
    { lat: 48.9717382, lng: 18.5653118 },
    { lat: 48.971804, lng: 18.5651072 },
    { lat: 48.9718688, lng: 18.5647412 },
    { lat: 48.9719596, lng: 18.5642309 },
    { lat: 48.9721199, lng: 18.5637761 },
    { lat: 48.972181, lng: 18.563613 },
    { lat: 48.9722656, lng: 18.5633634 },
    { lat: 48.9723881, lng: 18.5630192 },
    { lat: 48.9725154, lng: 18.5625578 },
    { lat: 48.9726557, lng: 18.5616693 },
    { lat: 48.9730776, lng: 18.5610221 },
    { lat: 48.9731436, lng: 18.5608671 },
    { lat: 48.9734141, lng: 18.5602391 },
    { lat: 48.9735636, lng: 18.5598966 },
    { lat: 48.9735889, lng: 18.5598385 },
    { lat: 48.9737413, lng: 18.5594896 },
    { lat: 48.9737662, lng: 18.5589493 },
    { lat: 48.9737697, lng: 18.5588689 },
    { lat: 48.9737905, lng: 18.5584193 },
    { lat: 48.9737063, lng: 18.5572382 },
    { lat: 48.9736884, lng: 18.5569255 },
    { lat: 48.9735958, lng: 18.5561526 },
    { lat: 48.9735503, lng: 18.5557088 },
    { lat: 48.9734979, lng: 18.5552005 },
    { lat: 48.973402, lng: 18.5544678 },
    { lat: 48.9731053, lng: 18.553906 },
    { lat: 48.972934, lng: 18.5534061 },
    { lat: 48.9730679, lng: 18.5530893 },
    { lat: 48.9731925, lng: 18.5529556 },
    { lat: 48.9735007, lng: 18.5526065 },
    { lat: 48.9736963, lng: 18.5523853 },
    { lat: 48.9737712, lng: 18.552311 },
    { lat: 48.9742478, lng: 18.5518848 },
    { lat: 48.9746719, lng: 18.551634 },
    { lat: 48.9747346, lng: 18.5516046 },
    { lat: 48.9747664, lng: 18.5515895 },
    { lat: 48.9748545, lng: 18.551548 },
    { lat: 48.9751548, lng: 18.5514074 },
    { lat: 48.9754054, lng: 18.5512863 },
    { lat: 48.9754483, lng: 18.5512663 },
    { lat: 48.9757757, lng: 18.5511227 },
    { lat: 48.9760049, lng: 18.5510046 },
    { lat: 48.9766565, lng: 18.5506851 },
    { lat: 48.9774723, lng: 18.5502772 },
    { lat: 48.9784677, lng: 18.5494731 },
    { lat: 48.9785544, lng: 18.5493455 },
    { lat: 48.9787849, lng: 18.5496032 },
    { lat: 48.97919, lng: 18.5500219 },
    { lat: 48.9791844, lng: 18.5498578 },
    { lat: 48.9791839, lng: 18.5497444 },
    { lat: 48.9791815, lng: 18.5496391 },
    { lat: 48.9791796, lng: 18.5495815 },
    { lat: 48.9791791, lng: 18.5495285 },
    { lat: 48.9791802, lng: 18.549449 },
    { lat: 48.9791805, lng: 18.5493879 },
    { lat: 48.9791847, lng: 18.5493622 },
    { lat: 48.9792154, lng: 18.5491828 },
    { lat: 48.9795255, lng: 18.5482226 },
    { lat: 48.9796103, lng: 18.5480167 },
    { lat: 48.9796922, lng: 18.5478614 },
    { lat: 48.9798329, lng: 18.5476704 },
    { lat: 48.9799336, lng: 18.5475783 },
    { lat: 48.9801768, lng: 18.5473559 },
    { lat: 48.9803679, lng: 18.5471578 },
    { lat: 48.9805275, lng: 18.5469594 },
    { lat: 48.9805762, lng: 18.5468801 },
    { lat: 48.9806521, lng: 18.5467413 },
    { lat: 48.9807752, lng: 18.5465532 },
    { lat: 48.9810952, lng: 18.5461124 },
    { lat: 48.9815395, lng: 18.5456324 },
    { lat: 48.981566, lng: 18.5455795 },
    { lat: 48.9815897, lng: 18.5455159 },
    { lat: 48.9816002, lng: 18.5454618 },
    { lat: 48.981709, lng: 18.544931 },
    { lat: 48.9818033, lng: 18.5449221 },
    { lat: 48.9819297, lng: 18.54434 },
    { lat: 48.9819906, lng: 18.5440462 },
    { lat: 48.9821361, lng: 18.5428412 },
    { lat: 48.9825021, lng: 18.5419378 },
    { lat: 48.9829759, lng: 18.5417528 },
    { lat: 48.9830289, lng: 18.5417326 },
    { lat: 48.9831196, lng: 18.5416968 },
    { lat: 48.983144, lng: 18.5416876 },
    { lat: 48.9832377, lng: 18.5416508 },
    { lat: 48.983715, lng: 18.5393649 },
    { lat: 48.9838423, lng: 18.5390279 },
    { lat: 48.9841819, lng: 18.5384686 },
    { lat: 48.9842965, lng: 18.5382767 },
    { lat: 48.9846161, lng: 18.5377412 },
    { lat: 48.9846455, lng: 18.5377444 },
    { lat: 48.9850521, lng: 18.537423 },
    { lat: 48.9853745, lng: 18.5371873 },
    { lat: 48.9857126, lng: 18.5369327 },
    { lat: 48.9861125, lng: 18.5366972 },
    { lat: 48.9864865, lng: 18.5364981 },
    { lat: 48.9866457, lng: 18.5363575 },
    { lat: 48.9870624, lng: 18.5361182 },
    { lat: 48.9878138, lng: 18.53556 },
    { lat: 48.9885832, lng: 18.5349996 },
    { lat: 48.9894986, lng: 18.5341209 },
    { lat: 48.9904372, lng: 18.5333977 },
    { lat: 48.9908986, lng: 18.5330468 },
    { lat: 48.9915403, lng: 18.5325619 },
    { lat: 48.991868, lng: 18.53232 },
    { lat: 48.992021, lng: 18.53197 },
    { lat: 48.992179, lng: 18.531402 },
    { lat: 48.992304, lng: 18.530783 },
    { lat: 48.992261, lng: 18.530743 },
    { lat: 48.991933, lng: 18.530391 },
    { lat: 48.990781, lng: 18.529669 },
    { lat: 48.990107, lng: 18.52905 },
    { lat: 48.989783, lng: 18.528256 },
    { lat: 48.989415, lng: 18.527061 },
  ],
  Ďurčiná: [
    { lat: 49.0585869, lng: 18.636623 },
    { lat: 49.0585391, lng: 18.6367414 },
    { lat: 49.058491, lng: 18.6368146 },
    { lat: 49.0582679, lng: 18.6370273 },
    { lat: 49.0580807, lng: 18.6371317 },
    { lat: 49.0578983, lng: 18.6372035 },
    { lat: 49.0577718, lng: 18.6372648 },
    { lat: 49.0577326, lng: 18.6372883 },
    { lat: 49.0576413, lng: 18.6373521 },
    { lat: 49.057596, lng: 18.6372247 },
    { lat: 49.0575532, lng: 18.6372655 },
    { lat: 49.0575216, lng: 18.6371828 },
    { lat: 49.057428, lng: 18.6372659 },
    { lat: 49.0573677, lng: 18.6372129 },
    { lat: 49.0569123, lng: 18.6375576 },
    { lat: 49.0568113, lng: 18.6376714 },
    { lat: 49.0567855, lng: 18.6376965 },
    { lat: 49.0561222, lng: 18.6381161 },
    { lat: 49.0558169, lng: 18.6382487 },
    { lat: 49.0556061, lng: 18.6380194 },
    { lat: 49.0545811, lng: 18.6384217 },
    { lat: 49.0543739, lng: 18.6386846 },
    { lat: 49.0541354, lng: 18.6389884 },
    { lat: 49.0538986, lng: 18.6393298 },
    { lat: 49.0537709, lng: 18.6394703 },
    { lat: 49.053425, lng: 18.6400085 },
    { lat: 49.0532859, lng: 18.6402151 },
    { lat: 49.0530851, lng: 18.6405089 },
    { lat: 49.0529653, lng: 18.6406722 },
    { lat: 49.0529185, lng: 18.6407261 },
    { lat: 49.0528894, lng: 18.6407513 },
    { lat: 49.0528115, lng: 18.6407897 },
    { lat: 49.0527464, lng: 18.6409239 },
    { lat: 49.0527294, lng: 18.6409429 },
    { lat: 49.0525265, lng: 18.6411794 },
    { lat: 49.0524735, lng: 18.6412844 },
    { lat: 49.0524358, lng: 18.6413577 },
    { lat: 49.0523959, lng: 18.6414781 },
    { lat: 49.0523686, lng: 18.6415994 },
    { lat: 49.0523566, lng: 18.6416721 },
    { lat: 49.0523107, lng: 18.6418043 },
    { lat: 49.0520789, lng: 18.6420882 },
    { lat: 49.0519422, lng: 18.6422341 },
    { lat: 49.0517665, lng: 18.6424093 },
    { lat: 49.0516587, lng: 18.642501 },
    { lat: 49.0515442, lng: 18.642596 },
    { lat: 49.0514897, lng: 18.6426344 },
    { lat: 49.0514584, lng: 18.6426807 },
    { lat: 49.0514157, lng: 18.6429168 },
    { lat: 49.0513903, lng: 18.643053 },
    { lat: 49.051308, lng: 18.6432871 },
    { lat: 49.0512256, lng: 18.6435594 },
    { lat: 49.051129, lng: 18.643759 },
    { lat: 49.0510691, lng: 18.6439037 },
    { lat: 49.0510346, lng: 18.6440371 },
    { lat: 49.0509946, lng: 18.6441161 },
    { lat: 49.0509144, lng: 18.6442385 },
    { lat: 49.0508536, lng: 18.6443258 },
    { lat: 49.0506922, lng: 18.644642 },
    { lat: 49.0506737, lng: 18.6446978 },
    { lat: 49.0505762, lng: 18.6448555 },
    { lat: 49.0505647, lng: 18.644891 },
    { lat: 49.0505667, lng: 18.6449296 },
    { lat: 49.0505493, lng: 18.6449616 },
    { lat: 49.0505315, lng: 18.6449911 },
    { lat: 49.0505084, lng: 18.6450641 },
    { lat: 49.0504978, lng: 18.6451179 },
    { lat: 49.0504921, lng: 18.6451844 },
    { lat: 49.0504934, lng: 18.6452486 },
    { lat: 49.0504953, lng: 18.6453059 },
    { lat: 49.0504813, lng: 18.6453572 },
    { lat: 49.0504636, lng: 18.6453896 },
    { lat: 49.050457, lng: 18.6454343 },
    { lat: 49.0504543, lng: 18.6454677 },
    { lat: 49.0503228, lng: 18.6455195 },
    { lat: 49.0500647, lng: 18.6456916 },
    { lat: 49.0500334, lng: 18.6457159 },
    { lat: 49.0499956, lng: 18.6457468 },
    { lat: 49.049945, lng: 18.6457922 },
    { lat: 49.0499282, lng: 18.645818 },
    { lat: 49.0498781, lng: 18.6459148 },
    { lat: 49.0498149, lng: 18.6459762 },
    { lat: 49.0496772, lng: 18.6462085 },
    { lat: 49.0494893, lng: 18.6459818 },
    { lat: 49.0493901, lng: 18.6458128 },
    { lat: 49.0493519, lng: 18.645837 },
    { lat: 49.0492392, lng: 18.6459893 },
    { lat: 49.0491261, lng: 18.6461283 },
    { lat: 49.048984, lng: 18.6462994 },
    { lat: 49.0489335, lng: 18.6463455 },
    { lat: 49.048905, lng: 18.6464454 },
    { lat: 49.0488161, lng: 18.6465563 },
    { lat: 49.0484731, lng: 18.6462157 },
    { lat: 49.047953, lng: 18.6456578 },
    { lat: 49.0477685, lng: 18.646095 },
    { lat: 49.0476449, lng: 18.6464414 },
    { lat: 49.0472494, lng: 18.6468288 },
    { lat: 49.0471603, lng: 18.6469362 },
    { lat: 49.0470999, lng: 18.6470917 },
    { lat: 49.0470386, lng: 18.6471489 },
    { lat: 49.0469601, lng: 18.6472409 },
    { lat: 49.0470113, lng: 18.6473331 },
    { lat: 49.0469912, lng: 18.6474427 },
    { lat: 49.046967, lng: 18.647477 },
    { lat: 49.046938, lng: 18.6475043 },
    { lat: 49.0469276, lng: 18.6475192 },
    { lat: 49.046908, lng: 18.6475547 },
    { lat: 49.0468214, lng: 18.6476848 },
    { lat: 49.0467666, lng: 18.6477339 },
    { lat: 49.0467148, lng: 18.6477996 },
    { lat: 49.046644, lng: 18.6479536 },
    { lat: 49.0465991, lng: 18.6480645 },
    { lat: 49.0466067, lng: 18.648079 },
    { lat: 49.0464828, lng: 18.6482111 },
    { lat: 49.0464509, lng: 18.6482463 },
    { lat: 49.0464584, lng: 18.6482795 },
    { lat: 49.0464672, lng: 18.6484446 },
    { lat: 49.0464784, lng: 18.648656 },
    { lat: 49.0464776, lng: 18.6487082 },
    { lat: 49.0464822, lng: 18.6488119 },
    { lat: 49.0464327, lng: 18.6493645 },
    { lat: 49.046422, lng: 18.6494384 },
    { lat: 49.0463925, lng: 18.6495413 },
    { lat: 49.0463609, lng: 18.6496275 },
    { lat: 49.0462931, lng: 18.6498372 },
    { lat: 49.0462967, lng: 18.6499043 },
    { lat: 49.0463079, lng: 18.6499465 },
    { lat: 49.0464047, lng: 18.6502189 },
    { lat: 49.0465352, lng: 18.6506558 },
    { lat: 49.0465397, lng: 18.6508681 },
    { lat: 49.0465555, lng: 18.6509967 },
    { lat: 49.0465562, lng: 18.6512188 },
    { lat: 49.0465318, lng: 18.651463 },
    { lat: 49.0464653, lng: 18.6516334 },
    { lat: 49.0463624, lng: 18.6517341 },
    { lat: 49.0461978, lng: 18.6518612 },
    { lat: 49.0461814, lng: 18.6518905 },
    { lat: 49.0461191, lng: 18.6521621 },
    { lat: 49.0460589, lng: 18.652301 },
    { lat: 49.0459774, lng: 18.6523823 },
    { lat: 49.0459011, lng: 18.6523917 },
    { lat: 49.0458825, lng: 18.6525263 },
    { lat: 49.0458527, lng: 18.6525831 },
    { lat: 49.0458634, lng: 18.6526559 },
    { lat: 49.0458234, lng: 18.6528815 },
    { lat: 49.0457321, lng: 18.6529863 },
    { lat: 49.0457287, lng: 18.6530909 },
    { lat: 49.0456512, lng: 18.6531174 },
    { lat: 49.0456163, lng: 18.653204 },
    { lat: 49.0455381, lng: 18.6532848 },
    { lat: 49.0453548, lng: 18.6534422 },
    { lat: 49.0452417, lng: 18.6534971 },
    { lat: 49.0451002, lng: 18.6535282 },
    { lat: 49.0451082, lng: 18.6535768 },
    { lat: 49.0451579, lng: 18.6539604 },
    { lat: 49.044976, lng: 18.6543754 },
    { lat: 49.045008, lng: 18.6545551 },
    { lat: 49.0450026, lng: 18.6558924 },
    { lat: 49.0448362, lng: 18.6561149 },
    { lat: 49.0447093, lng: 18.6562528 },
    { lat: 49.0445797, lng: 18.6563852 },
    { lat: 49.044243, lng: 18.6575149 },
    { lat: 49.0437389, lng: 18.6582164 },
    { lat: 49.0431856, lng: 18.6586069 },
    { lat: 49.0432276, lng: 18.6589533 },
    { lat: 49.0431992, lng: 18.659307 },
    { lat: 49.0430669, lng: 18.6596633 },
    { lat: 49.0422268, lng: 18.6606423 },
    { lat: 49.0420248, lng: 18.6609774 },
    { lat: 49.0417024, lng: 18.661489 },
    { lat: 49.0417211, lng: 18.6617556 },
    { lat: 49.041418, lng: 18.6622297 },
    { lat: 49.0413944, lng: 18.662211 },
    { lat: 49.0413191, lng: 18.6624269 },
    { lat: 49.0401629, lng: 18.6665023 },
    { lat: 49.0399984, lng: 18.6671193 },
    { lat: 49.0398537, lng: 18.6678977 },
    { lat: 49.039795, lng: 18.6689833 },
    { lat: 49.0399783, lng: 18.6693324 },
    { lat: 49.0399245, lng: 18.6701899 },
    { lat: 49.0399345, lng: 18.6704633 },
    { lat: 49.0396416, lng: 18.6719127 },
    { lat: 49.0390565, lng: 18.6720258 },
    { lat: 49.037985, lng: 18.673255 },
    { lat: 49.036937, lng: 18.6741657 },
    { lat: 49.0367486, lng: 18.6745249 },
    { lat: 49.0365423, lng: 18.6757375 },
    { lat: 49.0363734, lng: 18.6761284 },
    { lat: 49.035813, lng: 18.6777272 },
    { lat: 49.0351431, lng: 18.6796809 },
    { lat: 49.0351255, lng: 18.6797326 },
    { lat: 49.0351103, lng: 18.6797859 },
    { lat: 49.0350158, lng: 18.6801112 },
    { lat: 49.0349921, lng: 18.680195 },
    { lat: 49.0351158, lng: 18.6803168 },
    { lat: 49.0351646, lng: 18.6803863 },
    { lat: 49.035185, lng: 18.6804319 },
    { lat: 49.035162, lng: 18.6809358 },
    { lat: 49.0351657, lng: 18.6813694 },
    { lat: 49.035168, lng: 18.6816417 },
    { lat: 49.0351899, lng: 18.6818859 },
    { lat: 49.0352992, lng: 18.6824146 },
    { lat: 49.0353524, lng: 18.6828264 },
    { lat: 49.0352343, lng: 18.6837466 },
    { lat: 49.0352478, lng: 18.6841702 },
    { lat: 49.0354473, lng: 18.6848732 },
    { lat: 49.0355191, lng: 18.6852073 },
    { lat: 49.0355937, lng: 18.6854246 },
    { lat: 49.0357189, lng: 18.6857457 },
    { lat: 49.0361602, lng: 18.6868372 },
    { lat: 49.0362868, lng: 18.687014 },
    { lat: 49.0363338, lng: 18.6870492 },
    { lat: 49.0363361, lng: 18.6872617 },
    { lat: 49.0363828, lng: 18.6875511 },
    { lat: 49.036516, lng: 18.6879327 },
    { lat: 49.0365333, lng: 18.688757 },
    { lat: 49.036534, lng: 18.6887881 },
    { lat: 49.0365898, lng: 18.6896733 },
    { lat: 49.0365731, lng: 18.6906358 },
    { lat: 49.0364919, lng: 18.6919149 },
    { lat: 49.0362452, lng: 18.6927083 },
    { lat: 49.0360257, lng: 18.6934141 },
    { lat: 49.0359175, lng: 18.6937621 },
    { lat: 49.0361279, lng: 18.6955271 },
    { lat: 49.0353783, lng: 18.6955982 },
    { lat: 49.0350705, lng: 18.6954917 },
    { lat: 49.0350048, lng: 18.6956988 },
    { lat: 49.0358885, lng: 18.6971414 },
    { lat: 49.036399, lng: 18.6980944 },
    { lat: 49.0360769, lng: 18.6983188 },
    { lat: 49.0364694, lng: 18.6988337 },
    { lat: 49.0370194, lng: 18.6984183 },
    { lat: 49.0377448, lng: 18.6982746 },
    { lat: 49.0385065, lng: 18.6976226 },
    { lat: 49.0397935, lng: 18.6979157 },
    { lat: 49.0408054, lng: 18.6981462 },
    { lat: 49.0410792, lng: 18.697777 },
    { lat: 49.041888, lng: 18.6966706 },
    { lat: 49.0421089, lng: 18.6964153 },
    { lat: 49.0427489, lng: 18.6956559 },
    { lat: 49.0429808, lng: 18.6954996 },
    { lat: 49.0431368, lng: 18.6952724 },
    { lat: 49.0435943, lng: 18.6947308 },
    { lat: 49.0438702, lng: 18.694362 },
    { lat: 49.0448185, lng: 18.6935709 },
    { lat: 49.0451775, lng: 18.6933437 },
    { lat: 49.0454528, lng: 18.693236 },
    { lat: 49.0456167, lng: 18.6933504 },
    { lat: 49.0462203, lng: 18.693571 },
    { lat: 49.0468403, lng: 18.6937562 },
    { lat: 49.0475035, lng: 18.6938663 },
    { lat: 49.0478034, lng: 18.6937362 },
    { lat: 49.0481024, lng: 18.6936536 },
    { lat: 49.0487345, lng: 18.6937287 },
    { lat: 49.0490861, lng: 18.6938295 },
    { lat: 49.0491898, lng: 18.6939358 },
    { lat: 49.049731, lng: 18.6937223 },
    { lat: 49.050429, lng: 18.6934215 },
    { lat: 49.050907, lng: 18.6934386 },
    { lat: 49.0511717, lng: 18.6934127 },
    { lat: 49.051364, lng: 18.6932946 },
    { lat: 49.0527236, lng: 18.6926264 },
    { lat: 49.053017, lng: 18.6925441 },
    { lat: 49.0532699, lng: 18.692401 },
    { lat: 49.0533495, lng: 18.6921137 },
    { lat: 49.0538166, lng: 18.6917326 },
    { lat: 49.0541779, lng: 18.6916344 },
    { lat: 49.0553971, lng: 18.6904414 },
    { lat: 49.0561576, lng: 18.6900258 },
    { lat: 49.0563748, lng: 18.6891819 },
    { lat: 49.0566108, lng: 18.6885899 },
    { lat: 49.057218, lng: 18.6875336 },
    { lat: 49.0578041, lng: 18.6872533 },
    { lat: 49.0585262, lng: 18.6869379 },
    { lat: 49.0592785, lng: 18.6863237 },
    { lat: 49.0599767, lng: 18.6855801 },
    { lat: 49.0607545, lng: 18.6850181 },
    { lat: 49.0609743, lng: 18.6844208 },
    { lat: 49.0613362, lng: 18.6842269 },
    { lat: 49.0620839, lng: 18.6838418 },
    { lat: 49.0623931, lng: 18.6835892 },
    { lat: 49.062631, lng: 18.6835029 },
    { lat: 49.0649171, lng: 18.6818874 },
    { lat: 49.0657373, lng: 18.681319 },
    { lat: 49.0663502, lng: 18.6808623 },
    { lat: 49.0671171, lng: 18.6802029 },
    { lat: 49.0671646, lng: 18.6801626 },
    { lat: 49.0678329, lng: 18.6806226 },
    { lat: 49.0680826, lng: 18.6811434 },
    { lat: 49.070697, lng: 18.6827914 },
    { lat: 49.0718045, lng: 18.6835175 },
    { lat: 49.0723983, lng: 18.6840788 },
    { lat: 49.072705, lng: 18.68457 },
    { lat: 49.0727645, lng: 18.6848425 },
    { lat: 49.0730403, lng: 18.6847479 },
    { lat: 49.0731565, lng: 18.6847074 },
    { lat: 49.073295, lng: 18.684489 },
    { lat: 49.073359, lng: 18.6843355 },
    { lat: 49.07344, lng: 18.6839914 },
    { lat: 49.0734815, lng: 18.6837515 },
    { lat: 49.0735025, lng: 18.6835273 },
    { lat: 49.0735409, lng: 18.6829996 },
    { lat: 49.0735837, lng: 18.6822307 },
    { lat: 49.0735955, lng: 18.6820081 },
    { lat: 49.0736009, lng: 18.6818096 },
    { lat: 49.0738425, lng: 18.6799192 },
    { lat: 49.0741236, lng: 18.6791754 },
    { lat: 49.0743226, lng: 18.6787208 },
    { lat: 49.0745354, lng: 18.6782005 },
    { lat: 49.0746662, lng: 18.6778182 },
    { lat: 49.0748477, lng: 18.6771546 },
    { lat: 49.0747256, lng: 18.6770663 },
    { lat: 49.0740754, lng: 18.6762878 },
    { lat: 49.0722843, lng: 18.6733861 },
    { lat: 49.0725663, lng: 18.6731311 },
    { lat: 49.0729752, lng: 18.6727614 },
    { lat: 49.0731178, lng: 18.6726015 },
    { lat: 49.0732819, lng: 18.6724605 },
    { lat: 49.0733738, lng: 18.6723229 },
    { lat: 49.073549, lng: 18.6719719 },
    { lat: 49.0737007, lng: 18.6717882 },
    { lat: 49.0737384, lng: 18.6716713 },
    { lat: 49.0736303, lng: 18.6714195 },
    { lat: 49.0735384, lng: 18.6707909 },
    { lat: 49.0727866, lng: 18.6708213 },
    { lat: 49.0726393, lng: 18.6708063 },
    { lat: 49.0725529, lng: 18.6708349 },
    { lat: 49.0725393, lng: 18.6707913 },
    { lat: 49.0725458, lng: 18.6706599 },
    { lat: 49.0724628, lng: 18.6702856 },
    { lat: 49.072449, lng: 18.6702693 },
    { lat: 49.0722801, lng: 18.6700702 },
    { lat: 49.0722176, lng: 18.6700865 },
    { lat: 49.0721633, lng: 18.6700821 },
    { lat: 49.0721096, lng: 18.6698372 },
    { lat: 49.0720639, lng: 18.6697371 },
    { lat: 49.0720467, lng: 18.6694964 },
    { lat: 49.0719613, lng: 18.6694191 },
    { lat: 49.0719533, lng: 18.6693525 },
    { lat: 49.0717475, lng: 18.6691307 },
    { lat: 49.0718398, lng: 18.6689546 },
    { lat: 49.0718616, lng: 18.6687735 },
    { lat: 49.0719454, lng: 18.6687355 },
    { lat: 49.0719104, lng: 18.6685887 },
    { lat: 49.0718507, lng: 18.6684382 },
    { lat: 49.0717651, lng: 18.6684418 },
    { lat: 49.071702, lng: 18.6684018 },
    { lat: 49.0717772, lng: 18.6682868 },
    { lat: 49.0717812, lng: 18.6682341 },
    { lat: 49.0717235, lng: 18.6681657 },
    { lat: 49.071756, lng: 18.6680174 },
    { lat: 49.0716263, lng: 18.667951 },
    { lat: 49.0715811, lng: 18.6678645 },
    { lat: 49.0715196, lng: 18.6678105 },
    { lat: 49.0714305, lng: 18.6677485 },
    { lat: 49.0714201, lng: 18.6675918 },
    { lat: 49.0713782, lng: 18.6673977 },
    { lat: 49.0714055, lng: 18.6671059 },
    { lat: 49.071359, lng: 18.6670666 },
    { lat: 49.0712921, lng: 18.6670101 },
    { lat: 49.0712216, lng: 18.6669544 },
    { lat: 49.071127, lng: 18.6668944 },
    { lat: 49.0709131, lng: 18.666752 },
    { lat: 49.0708867, lng: 18.6667229 },
    { lat: 49.0707893, lng: 18.6665431 },
    { lat: 49.0707839, lng: 18.6664409 },
    { lat: 49.070759, lng: 18.6664001 },
    { lat: 49.0707144, lng: 18.6664495 },
    { lat: 49.0706838, lng: 18.6663407 },
    { lat: 49.0706816, lng: 18.6661309 },
    { lat: 49.0706088, lng: 18.6659474 },
    { lat: 49.0705919, lng: 18.6657984 },
    { lat: 49.0705382, lng: 18.665805 },
    { lat: 49.0704846, lng: 18.6656468 },
    { lat: 49.0703369, lng: 18.665624 },
    { lat: 49.0702553, lng: 18.6655103 },
    { lat: 49.0700756, lng: 18.6651842 },
    { lat: 49.0697615, lng: 18.6645153 },
    { lat: 49.0694137, lng: 18.6637017 },
    { lat: 49.0693927, lng: 18.6636223 },
    { lat: 49.0693235, lng: 18.663275 },
    { lat: 49.0692914, lng: 18.6631219 },
    { lat: 49.0691836, lng: 18.6626072 },
    { lat: 49.0691725, lng: 18.6625541 },
    { lat: 49.069155, lng: 18.6622865 },
    { lat: 49.0692071, lng: 18.6619553 },
    { lat: 49.069302, lng: 18.6615527 },
    { lat: 49.0695593, lng: 18.6605599 },
    { lat: 49.0696632, lng: 18.6601792 },
    { lat: 49.0697088, lng: 18.6599963 },
    { lat: 49.0701684, lng: 18.658265 },
    { lat: 49.070182, lng: 18.6582047 },
    { lat: 49.0703282, lng: 18.6576659 },
    { lat: 49.070339, lng: 18.65757 },
    { lat: 49.070251, lng: 18.6577606 },
    { lat: 49.0702488, lng: 18.6577195 },
    { lat: 49.0702437, lng: 18.6576903 },
    { lat: 49.0703042, lng: 18.6575653 },
    { lat: 49.0703138, lng: 18.6575454 },
    { lat: 49.0703407, lng: 18.6573696 },
    { lat: 49.0704134, lng: 18.6568936 },
    { lat: 49.0705879, lng: 18.6556319 },
    { lat: 49.0706736, lng: 18.6550102 },
    { lat: 49.0707042, lng: 18.6547881 },
    { lat: 49.0707312, lng: 18.6546813 },
    { lat: 49.0707462, lng: 18.6546221 },
    { lat: 49.0707542, lng: 18.6545812 },
    { lat: 49.0707729, lng: 18.6545122 },
    { lat: 49.0708303, lng: 18.6543012 },
    { lat: 49.0709037, lng: 18.6540311 },
    { lat: 49.0709125, lng: 18.6539988 },
    { lat: 49.0710335, lng: 18.6536656 },
    { lat: 49.071072, lng: 18.6535971 },
    { lat: 49.0711316, lng: 18.6534913 },
    { lat: 49.071269, lng: 18.6532575 },
    { lat: 49.0713262, lng: 18.6531421 },
    { lat: 49.0713411, lng: 18.6531083 },
    { lat: 49.0713967, lng: 18.6528635 },
    { lat: 49.0714034, lng: 18.6528341 },
    { lat: 49.0714606, lng: 18.6527097 },
    { lat: 49.0716055, lng: 18.6523948 },
    { lat: 49.0716561, lng: 18.6521605 },
    { lat: 49.0717096, lng: 18.6518065 },
    { lat: 49.0717217, lng: 18.6516926 },
    { lat: 49.0717244, lng: 18.6516673 },
    { lat: 49.0718645, lng: 18.6512619 },
    { lat: 49.0718817, lng: 18.6510788 },
    { lat: 49.071881, lng: 18.6509145 },
    { lat: 49.0718425, lng: 18.6505336 },
    { lat: 49.0718343, lng: 18.6504634 },
    { lat: 49.0718788, lng: 18.6498162 },
    { lat: 49.0719195, lng: 18.6495627 },
    { lat: 49.071925, lng: 18.649502 },
    { lat: 49.0719281, lng: 18.649467 },
    { lat: 49.0719544, lng: 18.6490958 },
    { lat: 49.0720028, lng: 18.6487754 },
    { lat: 49.0720087, lng: 18.6487391 },
    { lat: 49.0721129, lng: 18.6484899 },
    { lat: 49.0721956, lng: 18.648392 },
    { lat: 49.0722726, lng: 18.6483109 },
    { lat: 49.0723023, lng: 18.6482554 },
    { lat: 49.0723316, lng: 18.6481935 },
    { lat: 49.0723362, lng: 18.648089 },
    { lat: 49.0723384, lng: 18.6478553 },
    { lat: 49.0723337, lng: 18.6477677 },
    { lat: 49.072329, lng: 18.647719 },
    { lat: 49.0723236, lng: 18.6476622 },
    { lat: 49.0723524, lng: 18.6472036 },
    { lat: 49.0720818, lng: 18.6473425 },
    { lat: 49.0717002, lng: 18.6475383 },
    { lat: 49.0712439, lng: 18.6478446 },
    { lat: 49.0708669, lng: 18.6480479 },
    { lat: 49.0706253, lng: 18.6482999 },
    { lat: 49.0705055, lng: 18.6484643 },
    { lat: 49.0704332, lng: 18.6486283 },
    { lat: 49.0703738, lng: 18.6487428 },
    { lat: 49.0703477, lng: 18.6486967 },
    { lat: 49.0704325, lng: 18.6483237 },
    { lat: 49.0707666, lng: 18.6470739 },
    { lat: 49.0708535, lng: 18.6466319 },
    { lat: 49.0709289, lng: 18.6463306 },
    { lat: 49.0711865, lng: 18.6455366 },
    { lat: 49.0712679, lng: 18.6453079 },
    { lat: 49.0713115, lng: 18.6452572 },
    { lat: 49.0714452, lng: 18.6448867 },
    { lat: 49.071555, lng: 18.6445351 },
    { lat: 49.0716223, lng: 18.6444005 },
    { lat: 49.0718033, lng: 18.643649 },
    { lat: 49.0718857, lng: 18.6434162 },
    { lat: 49.0718827, lng: 18.6433616 },
    { lat: 49.0719641, lng: 18.6432379 },
    { lat: 49.0720421, lng: 18.6431757 },
    { lat: 49.0721135, lng: 18.6430352 },
    { lat: 49.0722526, lng: 18.6428508 },
    { lat: 49.0723334, lng: 18.642779 },
    { lat: 49.0723876, lng: 18.6426744 },
    { lat: 49.0725198, lng: 18.6424909 },
    { lat: 49.0725564, lng: 18.6423914 },
    { lat: 49.072661, lng: 18.642218 },
    { lat: 49.0729736, lng: 18.6416302 },
    { lat: 49.073125, lng: 18.6413586 },
    { lat: 49.073252, lng: 18.6411825 },
    { lat: 49.0732856, lng: 18.6409874 },
    { lat: 49.0733172, lng: 18.6407331 },
    { lat: 49.0732551, lng: 18.6407408 },
    { lat: 49.0731692, lng: 18.6407514 },
    { lat: 49.072999, lng: 18.640604 },
    { lat: 49.0727147, lng: 18.6406016 },
    { lat: 49.0726966, lng: 18.6406014 },
    { lat: 49.0725946, lng: 18.6405712 },
    { lat: 49.0725071, lng: 18.6404942 },
    { lat: 49.0724391, lng: 18.6404641 },
    { lat: 49.0722491, lng: 18.6402869 },
    { lat: 49.0721319, lng: 18.6402477 },
    { lat: 49.0721149, lng: 18.6402043 },
    { lat: 49.0720848, lng: 18.6401273 },
    { lat: 49.0720958, lng: 18.6400137 },
    { lat: 49.0720835, lng: 18.6399623 },
    { lat: 49.0720754, lng: 18.6399281 },
    { lat: 49.0720431, lng: 18.6399417 },
    { lat: 49.0719564, lng: 18.6399784 },
    { lat: 49.071663, lng: 18.6398308 },
    { lat: 49.0715888, lng: 18.6397468 },
    { lat: 49.071362, lng: 18.639596 },
    { lat: 49.0713392, lng: 18.6395273 },
    { lat: 49.0713367, lng: 18.6394808 },
    { lat: 49.0713064, lng: 18.6394227 },
    { lat: 49.0712301, lng: 18.6394091 },
    { lat: 49.0710588, lng: 18.6392817 },
    { lat: 49.0709653, lng: 18.6391806 },
    { lat: 49.0707106, lng: 18.6390531 },
    { lat: 49.0705616, lng: 18.6389272 },
    { lat: 49.0705608, lng: 18.638902 },
    { lat: 49.0705579, lng: 18.6388137 },
    { lat: 49.0705826, lng: 18.6387721 },
    { lat: 49.070564, lng: 18.6387608 },
    { lat: 49.0705622, lng: 18.6387268 },
    { lat: 49.0705507, lng: 18.6387005 },
    { lat: 49.0704109, lng: 18.6385739 },
    { lat: 49.0701714, lng: 18.6385017 },
    { lat: 49.0699545, lng: 18.6384979 },
    { lat: 49.0698435, lng: 18.6384242 },
    { lat: 49.0698178, lng: 18.6384518 },
    { lat: 49.0697353, lng: 18.6384681 },
    { lat: 49.0697098, lng: 18.6384731 },
    { lat: 49.0696567, lng: 18.6385379 },
    { lat: 49.0696306, lng: 18.6385354 },
    { lat: 49.0696373, lng: 18.6385759 },
    { lat: 49.069612, lng: 18.6386517 },
    { lat: 49.0696006, lng: 18.6387973 },
    { lat: 49.069578, lng: 18.6388167 },
    { lat: 49.0695212, lng: 18.6388111 },
    { lat: 49.0694929, lng: 18.638852 },
    { lat: 49.0694347, lng: 18.6389002 },
    { lat: 49.0693656, lng: 18.6388756 },
    { lat: 49.0691579, lng: 18.6388711 },
    { lat: 49.0690493, lng: 18.6389273 },
    { lat: 49.0689772, lng: 18.6390556 },
    { lat: 49.0689416, lng: 18.6390434 },
    { lat: 49.0688972, lng: 18.6389734 },
    { lat: 49.0688945, lng: 18.6389226 },
    { lat: 49.0689001, lng: 18.6388989 },
    { lat: 49.0688694, lng: 18.6388329 },
    { lat: 49.0687004, lng: 18.6388538 },
    { lat: 49.0685836, lng: 18.6387607 },
    { lat: 49.0685039, lng: 18.6386842 },
    { lat: 49.0683617, lng: 18.6385491 },
    { lat: 49.068321, lng: 18.6385104 },
    { lat: 49.0682324, lng: 18.638476 },
    { lat: 49.0681634, lng: 18.6384942 },
    { lat: 49.0680918, lng: 18.63857 },
    { lat: 49.0680804, lng: 18.6385711 },
    { lat: 49.0680561, lng: 18.6385733 },
    { lat: 49.0680141, lng: 18.6385263 },
    { lat: 49.0679422, lng: 18.6384665 },
    { lat: 49.0679038, lng: 18.6384864 },
    { lat: 49.067805, lng: 18.6384561 },
    { lat: 49.0676954, lng: 18.6383459 },
    { lat: 49.067663, lng: 18.6383276 },
    { lat: 49.0676216, lng: 18.6382961 },
    { lat: 49.0675489, lng: 18.6382227 },
    { lat: 49.0674766, lng: 18.638219 },
    { lat: 49.0674478, lng: 18.6382269 },
    { lat: 49.0674205, lng: 18.6382659 },
    { lat: 49.0673264, lng: 18.6382775 },
    { lat: 49.0671324, lng: 18.6379813 },
    { lat: 49.0669857, lng: 18.6379376 },
    { lat: 49.0669328, lng: 18.637984 },
    { lat: 49.0668255, lng: 18.6380235 },
    { lat: 49.0668171, lng: 18.6380574 },
    { lat: 49.0668135, lng: 18.6380718 },
    { lat: 49.0667835, lng: 18.6380604 },
    { lat: 49.0667587, lng: 18.6380372 },
    { lat: 49.066667, lng: 18.6378604 },
    { lat: 49.0666326, lng: 18.6378319 },
    { lat: 49.0665893, lng: 18.6378659 },
    { lat: 49.066583, lng: 18.6378708 },
    { lat: 49.0664874, lng: 18.6378157 },
    { lat: 49.0664627, lng: 18.6378187 },
    { lat: 49.0664436, lng: 18.6377959 },
    { lat: 49.0663794, lng: 18.6377765 },
    { lat: 49.0663453, lng: 18.6377519 },
    { lat: 49.0663265, lng: 18.6377377 },
    { lat: 49.0662904, lng: 18.6377558 },
    { lat: 49.0662858, lng: 18.637883 },
    { lat: 49.0662501, lng: 18.6379309 },
    { lat: 49.0660408, lng: 18.637751 },
    { lat: 49.0659479, lng: 18.6376802 },
    { lat: 49.0659201, lng: 18.6376685 },
    { lat: 49.065886, lng: 18.6376615 },
    { lat: 49.0658596, lng: 18.6376497 },
    { lat: 49.0657731, lng: 18.6376579 },
    { lat: 49.0657049, lng: 18.6377282 },
    { lat: 49.0655764, lng: 18.6378605 },
    { lat: 49.0654435, lng: 18.6378248 },
    { lat: 49.0653419, lng: 18.6377579 },
    { lat: 49.0652861, lng: 18.6377263 },
    { lat: 49.0651844, lng: 18.6377032 },
    { lat: 49.0650668, lng: 18.6377177 },
    { lat: 49.0650136, lng: 18.6376912 },
    { lat: 49.0648308, lng: 18.637689 },
    { lat: 49.0648233, lng: 18.6377615 },
    { lat: 49.0647987, lng: 18.6377864 },
    { lat: 49.0647684, lng: 18.6378301 },
    { lat: 49.0647291, lng: 18.6378335 },
    { lat: 49.0645231, lng: 18.6377933 },
    { lat: 49.0645001, lng: 18.6377888 },
    { lat: 49.0644501, lng: 18.6377414 },
    { lat: 49.0644022, lng: 18.6377311 },
    { lat: 49.0643207, lng: 18.6377491 },
    { lat: 49.0641766, lng: 18.6377313 },
    { lat: 49.0640742, lng: 18.6376903 },
    { lat: 49.0639785, lng: 18.6377172 },
    { lat: 49.0637645, lng: 18.6376807 },
    { lat: 49.0636976, lng: 18.6375678 },
    { lat: 49.0636654, lng: 18.6375761 },
    { lat: 49.0636074, lng: 18.6375653 },
    { lat: 49.063536, lng: 18.6375385 },
    { lat: 49.0635149, lng: 18.6374835 },
    { lat: 49.0634893, lng: 18.6374867 },
    { lat: 49.0634735, lng: 18.6375106 },
    { lat: 49.063463, lng: 18.6375655 },
    { lat: 49.063456, lng: 18.6376692 },
    { lat: 49.0634383, lng: 18.6376523 },
    { lat: 49.0634326, lng: 18.6376735 },
    { lat: 49.0633935, lng: 18.6376578 },
    { lat: 49.0633459, lng: 18.637613 },
    { lat: 49.0633322, lng: 18.6376298 },
    { lat: 49.0632829, lng: 18.6376359 },
    { lat: 49.0632699, lng: 18.6376044 },
    { lat: 49.0632106, lng: 18.6375693 },
    { lat: 49.0630407, lng: 18.6374292 },
    { lat: 49.0629335, lng: 18.6373852 },
    { lat: 49.0628795, lng: 18.6373876 },
    { lat: 49.0628336, lng: 18.6375015 },
    { lat: 49.0628252, lng: 18.6375356 },
    { lat: 49.0627523, lng: 18.6375582 },
    { lat: 49.0626767, lng: 18.6375816 },
    { lat: 49.0626053, lng: 18.637534 },
    { lat: 49.0625763, lng: 18.637562 },
    { lat: 49.0625522, lng: 18.6375751 },
    { lat: 49.0625301, lng: 18.6375641 },
    { lat: 49.0624941, lng: 18.6375848 },
    { lat: 49.0624542, lng: 18.637598 },
    { lat: 49.0624608, lng: 18.6375829 },
    { lat: 49.0624446, lng: 18.6375588 },
    { lat: 49.0623847, lng: 18.6375488 },
    { lat: 49.0623517, lng: 18.6375386 },
    { lat: 49.0622937, lng: 18.6375271 },
    { lat: 49.0622294, lng: 18.6374858 },
    { lat: 49.0621862, lng: 18.6374823 },
    { lat: 49.062175, lng: 18.6374814 },
    { lat: 49.0621275, lng: 18.6374775 },
    { lat: 49.0620532, lng: 18.6374389 },
    { lat: 49.0619671, lng: 18.637452 },
    { lat: 49.0618998, lng: 18.6373945 },
    { lat: 49.0617948, lng: 18.6374158 },
    { lat: 49.0618047, lng: 18.6375163 },
    { lat: 49.0617755, lng: 18.6375419 },
    { lat: 49.0617254, lng: 18.6375315 },
    { lat: 49.0616518, lng: 18.6373759 },
    { lat: 49.0615148, lng: 18.6373241 },
    { lat: 49.0614661, lng: 18.6372161 },
    { lat: 49.0614337, lng: 18.6372201 },
    { lat: 49.0612865, lng: 18.6374429 },
    { lat: 49.0611805, lng: 18.637401 },
    { lat: 49.0610324, lng: 18.6373315 },
    { lat: 49.0610179, lng: 18.6373952 },
    { lat: 49.0607753, lng: 18.6374557 },
    { lat: 49.0607693, lng: 18.6373867 },
    { lat: 49.0605838, lng: 18.6375243 },
    { lat: 49.0605099, lng: 18.637514 },
    { lat: 49.0605053, lng: 18.6373657 },
    { lat: 49.0602682, lng: 18.6374431 },
    { lat: 49.0601836, lng: 18.6375719 },
    { lat: 49.0601741, lng: 18.6376475 },
    { lat: 49.0599859, lng: 18.6378394 },
    { lat: 49.0598993, lng: 18.6380112 },
    { lat: 49.0598477, lng: 18.6380399 },
    { lat: 49.059598, lng: 18.6381804 },
    { lat: 49.0595171, lng: 18.6382263 },
    { lat: 49.0594277, lng: 18.6383064 },
    { lat: 49.0594113, lng: 18.6382728 },
    { lat: 49.059323, lng: 18.6381765 },
    { lat: 49.0592078, lng: 18.6380509 },
    { lat: 49.0591, lng: 18.6379132 },
    { lat: 49.0585869, lng: 18.636623 },
  ],
  Šuja: [
    { lat: 49.0733172, lng: 18.6407331 },
    { lat: 49.0733586, lng: 18.640582 },
    { lat: 49.0733965, lng: 18.6404438 },
    { lat: 49.0737802, lng: 18.6404281 },
    { lat: 49.0738389, lng: 18.640346 },
    { lat: 49.0740075, lng: 18.6403741 },
    { lat: 49.0741293, lng: 18.6406735 },
    { lat: 49.0741718, lng: 18.6406472 },
    { lat: 49.0743423, lng: 18.6405414 },
    { lat: 49.0743007, lng: 18.6403955 },
    { lat: 49.0743934, lng: 18.6403164 },
    { lat: 49.0745555, lng: 18.6405198 },
    { lat: 49.0746033, lng: 18.6405784 },
    { lat: 49.0746832, lng: 18.6406764 },
    { lat: 49.0748704, lng: 18.6405881 },
    { lat: 49.0749191, lng: 18.6403796 },
    { lat: 49.0751757, lng: 18.6402029 },
    { lat: 49.0752504, lng: 18.6401452 },
    { lat: 49.0752945, lng: 18.6400401 },
    { lat: 49.0753444, lng: 18.6399389 },
    { lat: 49.0754564, lng: 18.6398193 },
    { lat: 49.0755654, lng: 18.6398329 },
    { lat: 49.075642, lng: 18.6398749 },
    { lat: 49.07569, lng: 18.6399053 },
    { lat: 49.0757528, lng: 18.6399846 },
    { lat: 49.0757625, lng: 18.639997 },
    { lat: 49.0758064, lng: 18.6399344 },
    { lat: 49.0758917, lng: 18.6398185 },
    { lat: 49.0759179, lng: 18.639676 },
    { lat: 49.0760266, lng: 18.6396417 },
    { lat: 49.0761248, lng: 18.6396206 },
    { lat: 49.076222, lng: 18.6396054 },
    { lat: 49.0763149, lng: 18.6395288 },
    { lat: 49.0765178, lng: 18.6392077 },
    { lat: 49.0765451, lng: 18.6392046 },
    { lat: 49.0766221, lng: 18.6391959 },
    { lat: 49.0767908, lng: 18.6391696 },
    { lat: 49.0768771, lng: 18.6391561 },
    { lat: 49.0772575, lng: 18.6392707 },
    { lat: 49.0774273, lng: 18.6393253 },
    { lat: 49.0774902, lng: 18.6391272 },
    { lat: 49.0775908, lng: 18.6388144 },
    { lat: 49.0777248, lng: 18.6384291 },
    { lat: 49.0778854, lng: 18.6377398 },
    { lat: 49.0779621, lng: 18.6374011 },
    { lat: 49.0781621, lng: 18.6366886 },
    { lat: 49.0782738, lng: 18.6362881 },
    { lat: 49.0782605, lng: 18.6362293 },
    { lat: 49.0783291, lng: 18.6360578 },
    { lat: 49.0785439, lng: 18.6355161 },
    { lat: 49.0787962, lng: 18.6348759 },
    { lat: 49.0789645, lng: 18.6344594 },
    { lat: 49.0790064, lng: 18.6343545 },
    { lat: 49.0789098, lng: 18.6342593 },
    { lat: 49.0787801, lng: 18.6341303 },
    { lat: 49.0785856, lng: 18.6340547 },
    { lat: 49.0784085, lng: 18.6340766 },
    { lat: 49.0784982, lng: 18.6337511 },
    { lat: 49.0785926, lng: 18.633407 },
    { lat: 49.0786206, lng: 18.6332981 },
    { lat: 49.0783925, lng: 18.6332698 },
    { lat: 49.078263, lng: 18.6332923 },
    { lat: 49.0781764, lng: 18.6332998 },
    { lat: 49.0780598, lng: 18.633311 },
    { lat: 49.0779509, lng: 18.6333215 },
    { lat: 49.0779144, lng: 18.633293 },
    { lat: 49.0778142, lng: 18.6332528 },
    { lat: 49.077657, lng: 18.6332701 },
    { lat: 49.0775732, lng: 18.6332794 },
    { lat: 49.0774581, lng: 18.6331689 },
    { lat: 49.0774127, lng: 18.6331254 },
    { lat: 49.0773376, lng: 18.6330379 },
    { lat: 49.0772815, lng: 18.6329726 },
    { lat: 49.077213, lng: 18.6328936 },
    { lat: 49.077156, lng: 18.6328187 },
    { lat: 49.077096, lng: 18.6327293 },
    { lat: 49.0770503, lng: 18.6326529 },
    { lat: 49.0771237, lng: 18.6325942 },
    { lat: 49.0771518, lng: 18.6325486 },
    { lat: 49.0772035, lng: 18.6325038 },
    { lat: 49.0772242, lng: 18.6324886 },
    { lat: 49.0772415, lng: 18.6324757 },
    { lat: 49.0772676, lng: 18.6324559 },
    { lat: 49.0772596, lng: 18.6323724 },
    { lat: 49.0772513, lng: 18.6323007 },
    { lat: 49.077246, lng: 18.6322409 },
    { lat: 49.0772105, lng: 18.6318284 },
    { lat: 49.0771764, lng: 18.6316758 },
    { lat: 49.0771208, lng: 18.6314337 },
    { lat: 49.0771071, lng: 18.6313742 },
    { lat: 49.077036, lng: 18.6310803 },
    { lat: 49.0770219, lng: 18.6310221 },
    { lat: 49.0767807, lng: 18.6309163 },
    { lat: 49.0763875, lng: 18.6307369 },
    { lat: 49.0761116, lng: 18.6306024 },
    { lat: 49.0760784, lng: 18.6305862 },
    { lat: 49.0759986, lng: 18.6305475 },
    { lat: 49.0757766, lng: 18.6304484 },
    { lat: 49.0756613, lng: 18.6304004 },
    { lat: 49.0756034, lng: 18.6303743 },
    { lat: 49.0753828, lng: 18.6302747 },
    { lat: 49.0752575, lng: 18.6302361 },
    { lat: 49.0750503, lng: 18.6301723 },
    { lat: 49.0747267, lng: 18.6300706 },
    { lat: 49.0745812, lng: 18.6300278 },
    { lat: 49.0744903, lng: 18.6299941 },
    { lat: 49.0744385, lng: 18.629977 },
    { lat: 49.0742322, lng: 18.6299087 },
    { lat: 49.0738357, lng: 18.6297955 },
    { lat: 49.0738163, lng: 18.6297889 },
    { lat: 49.0738346, lng: 18.629624 },
    { lat: 49.0738654, lng: 18.6296285 },
    { lat: 49.0738693, lng: 18.6294277 },
    { lat: 49.0738057, lng: 18.62921 },
    { lat: 49.0737985, lng: 18.6291994 },
    { lat: 49.0736632, lng: 18.6289898 },
    { lat: 49.07343, lng: 18.6288874 },
    { lat: 49.0732635, lng: 18.6288145 },
    { lat: 49.0731868, lng: 18.6288131 },
    { lat: 49.0730457, lng: 18.6288115 },
    { lat: 49.0729511, lng: 18.6288103 },
    { lat: 49.0730237, lng: 18.6283114 },
    { lat: 49.0730366, lng: 18.62824 },
    { lat: 49.0730725, lng: 18.6280032 },
    { lat: 49.0730956, lng: 18.6278431 },
    { lat: 49.0731092, lng: 18.6277389 },
    { lat: 49.0732011, lng: 18.6271819 },
    { lat: 49.0733308, lng: 18.6263741 },
    { lat: 49.0733839, lng: 18.626401 },
    { lat: 49.0734015, lng: 18.6263053 },
    { lat: 49.0734817, lng: 18.625886 },
    { lat: 49.0735796, lng: 18.6254627 },
    { lat: 49.0733906, lng: 18.625344 },
    { lat: 49.0732874, lng: 18.6253465 },
    { lat: 49.0730731, lng: 18.6253516 },
    { lat: 49.0726866, lng: 18.6254013 },
    { lat: 49.0724882, lng: 18.6254226 },
    { lat: 49.072434, lng: 18.6254238 },
    { lat: 49.0723428, lng: 18.6254258 },
    { lat: 49.0721027, lng: 18.6254464 },
    { lat: 49.0720779, lng: 18.6254485 },
    { lat: 49.0720445, lng: 18.6254552 },
    { lat: 49.0720098, lng: 18.6254605 },
    { lat: 49.0720027, lng: 18.6254157 },
    { lat: 49.0719951, lng: 18.6253573 },
    { lat: 49.0719877, lng: 18.6253036 },
    { lat: 49.0719773, lng: 18.6250423 },
    { lat: 49.0719418, lng: 18.6249269 },
    { lat: 49.0719189, lng: 18.624861 },
    { lat: 49.0716369, lng: 18.6242441 },
    { lat: 49.0714174, lng: 18.6237454 },
    { lat: 49.0711217, lng: 18.6234878 },
    { lat: 49.0708744, lng: 18.6233792 },
    { lat: 49.0705547, lng: 18.6234507 },
    { lat: 49.0703567, lng: 18.6234386 },
    { lat: 49.0701302, lng: 18.6234231 },
    { lat: 49.070002, lng: 18.6234087 },
    { lat: 49.0698691, lng: 18.6234345 },
    { lat: 49.0694188, lng: 18.6235733 },
    { lat: 49.0689767, lng: 18.6237036 },
    { lat: 49.0689143, lng: 18.6236718 },
    { lat: 49.0688627, lng: 18.6236357 },
    { lat: 49.0685847, lng: 18.6234345 },
    { lat: 49.0684734, lng: 18.6233605 },
    { lat: 49.0680472, lng: 18.6232338 },
    { lat: 49.0675491, lng: 18.6233681 },
    { lat: 49.0675405, lng: 18.6232922 },
    { lat: 49.0676521, lng: 18.6225094 },
    { lat: 49.0672044, lng: 18.6227425 },
    { lat: 49.0666875, lng: 18.6230104 },
    { lat: 49.0664415, lng: 18.6231354 },
    { lat: 49.06644, lng: 18.6235888 },
    { lat: 49.0664319, lng: 18.6236945 },
    { lat: 49.0663116, lng: 18.6237421 },
    { lat: 49.0661889, lng: 18.6237691 },
    { lat: 49.066105, lng: 18.623781 },
    { lat: 49.06575, lng: 18.6237231 },
    { lat: 49.0657321, lng: 18.6237243 },
    { lat: 49.0657061, lng: 18.6237242 },
    { lat: 49.0656559, lng: 18.6237312 },
    { lat: 49.0655986, lng: 18.6237383 },
    { lat: 49.0655513, lng: 18.6237384 },
    { lat: 49.0654622, lng: 18.6237152 },
    { lat: 49.0654198, lng: 18.6237665 },
    { lat: 49.0653476, lng: 18.6238528 },
    { lat: 49.0651463, lng: 18.6234213 },
    { lat: 49.0648742, lng: 18.6226989 },
    { lat: 49.0645324, lng: 18.6225686 },
    { lat: 49.0643842, lng: 18.622592 },
    { lat: 49.0637126, lng: 18.6226978 },
    { lat: 49.0635482, lng: 18.6224689 },
    { lat: 49.063468, lng: 18.622194 },
    { lat: 49.0629564, lng: 18.6222213 },
    { lat: 49.0621608, lng: 18.62255 },
    { lat: 49.0618184, lng: 18.623003 },
    { lat: 49.0615975, lng: 18.6235529 },
    { lat: 49.0610217, lng: 18.6233113 },
    { lat: 49.0609411, lng: 18.6232764 },
    { lat: 49.0603498, lng: 18.6230201 },
    { lat: 49.0598194, lng: 18.6226955 },
    { lat: 49.0596801, lng: 18.6227294 },
    { lat: 49.059246, lng: 18.6228352 },
    { lat: 49.0583465, lng: 18.6230237 },
    { lat: 49.0578262, lng: 18.6231328 },
    { lat: 49.056135, lng: 18.6229113 },
    { lat: 49.0560926, lng: 18.6230258 },
    { lat: 49.0560856, lng: 18.623062 },
    { lat: 49.0557037, lng: 18.6231779 },
    { lat: 49.0555713, lng: 18.6232522 },
    { lat: 49.0554947, lng: 18.6232951 },
    { lat: 49.0554004, lng: 18.6233481 },
    { lat: 49.0553261, lng: 18.62339 },
    { lat: 49.055304, lng: 18.6234021 },
    { lat: 49.0552185, lng: 18.6235526 },
    { lat: 49.05488, lng: 18.6240555 },
    { lat: 49.0545643, lng: 18.6247511 },
    { lat: 49.0544999, lng: 18.6251111 },
    { lat: 49.0542071, lng: 18.626956 },
    { lat: 49.0541523, lng: 18.6282943 },
    { lat: 49.054278, lng: 18.6282622 },
    { lat: 49.0543836, lng: 18.628297 },
    { lat: 49.0544857, lng: 18.6284124 },
    { lat: 49.0546624, lng: 18.6283837 },
    { lat: 49.0547836, lng: 18.628268 },
    { lat: 49.0550202, lng: 18.6281359 },
    { lat: 49.0551508, lng: 18.6280062 },
    { lat: 49.0552574, lng: 18.6279761 },
    { lat: 49.0553202, lng: 18.6280539 },
    { lat: 49.0557043, lng: 18.6286573 },
    { lat: 49.0558251, lng: 18.6288477 },
    { lat: 49.0561279, lng: 18.6293227 },
    { lat: 49.0562769, lng: 18.6291791 },
    { lat: 49.0565008, lng: 18.6290216 },
    { lat: 49.0567636, lng: 18.628892 },
    { lat: 49.0570119, lng: 18.6287272 },
    { lat: 49.0572116, lng: 18.6286655 },
    { lat: 49.0572486, lng: 18.6286595 },
    { lat: 49.0573838, lng: 18.6286377 },
    { lat: 49.0573809, lng: 18.6287081 },
    { lat: 49.0573783, lng: 18.6287689 },
    { lat: 49.0573598, lng: 18.6288658 },
    { lat: 49.0572503, lng: 18.6290534 },
    { lat: 49.0570845, lng: 18.6292736 },
    { lat: 49.0569618, lng: 18.6294364 },
    { lat: 49.0568833, lng: 18.6296162 },
    { lat: 49.0566809, lng: 18.6298796 },
    { lat: 49.0565108, lng: 18.6300319 },
    { lat: 49.0561703, lng: 18.6304142 },
    { lat: 49.055865, lng: 18.6307155 },
    { lat: 49.0557437, lng: 18.6307902 },
    { lat: 49.0563781, lng: 18.6315392 },
    { lat: 49.0564285, lng: 18.6315768 },
    { lat: 49.0566345, lng: 18.6318089 },
    { lat: 49.0566818, lng: 18.6318623 },
    { lat: 49.0568952, lng: 18.6332625 },
    { lat: 49.0569179, lng: 18.6339188 },
    { lat: 49.0572863, lng: 18.6342714 },
    { lat: 49.0575386, lng: 18.6343718 },
    { lat: 49.0578316, lng: 18.6345802 },
    { lat: 49.0578813, lng: 18.6348114 },
    { lat: 49.0580784, lng: 18.6350204 },
    { lat: 49.0581687, lng: 18.6351258 },
    { lat: 49.0584479, lng: 18.6352438 },
    { lat: 49.0585636, lng: 18.6358707 },
    { lat: 49.0585869, lng: 18.636623 },
    { lat: 49.0591, lng: 18.6379132 },
    { lat: 49.0592078, lng: 18.6380509 },
    { lat: 49.059323, lng: 18.6381765 },
    { lat: 49.0594113, lng: 18.6382728 },
    { lat: 49.0594277, lng: 18.6383064 },
    { lat: 49.0595171, lng: 18.6382263 },
    { lat: 49.059598, lng: 18.6381804 },
    { lat: 49.0598477, lng: 18.6380399 },
    { lat: 49.0598993, lng: 18.6380112 },
    { lat: 49.0599859, lng: 18.6378394 },
    { lat: 49.0601741, lng: 18.6376475 },
    { lat: 49.0601836, lng: 18.6375719 },
    { lat: 49.0602682, lng: 18.6374431 },
    { lat: 49.0605053, lng: 18.6373657 },
    { lat: 49.0605099, lng: 18.637514 },
    { lat: 49.0605838, lng: 18.6375243 },
    { lat: 49.0607693, lng: 18.6373867 },
    { lat: 49.0607753, lng: 18.6374557 },
    { lat: 49.0610179, lng: 18.6373952 },
    { lat: 49.0610324, lng: 18.6373315 },
    { lat: 49.0611805, lng: 18.637401 },
    { lat: 49.0612865, lng: 18.6374429 },
    { lat: 49.0614337, lng: 18.6372201 },
    { lat: 49.0614661, lng: 18.6372161 },
    { lat: 49.0615148, lng: 18.6373241 },
    { lat: 49.0616518, lng: 18.6373759 },
    { lat: 49.0617254, lng: 18.6375315 },
    { lat: 49.0617755, lng: 18.6375419 },
    { lat: 49.0618047, lng: 18.6375163 },
    { lat: 49.0617948, lng: 18.6374158 },
    { lat: 49.0618998, lng: 18.6373945 },
    { lat: 49.0619671, lng: 18.637452 },
    { lat: 49.0620532, lng: 18.6374389 },
    { lat: 49.0621275, lng: 18.6374775 },
    { lat: 49.062175, lng: 18.6374814 },
    { lat: 49.0621862, lng: 18.6374823 },
    { lat: 49.0622294, lng: 18.6374858 },
    { lat: 49.0622937, lng: 18.6375271 },
    { lat: 49.0623517, lng: 18.6375386 },
    { lat: 49.0623847, lng: 18.6375488 },
    { lat: 49.0624446, lng: 18.6375588 },
    { lat: 49.0624608, lng: 18.6375829 },
    { lat: 49.0624542, lng: 18.637598 },
    { lat: 49.0624941, lng: 18.6375848 },
    { lat: 49.0625301, lng: 18.6375641 },
    { lat: 49.0625522, lng: 18.6375751 },
    { lat: 49.0625763, lng: 18.637562 },
    { lat: 49.0626053, lng: 18.637534 },
    { lat: 49.0626767, lng: 18.6375816 },
    { lat: 49.0627523, lng: 18.6375582 },
    { lat: 49.0628252, lng: 18.6375356 },
    { lat: 49.0628336, lng: 18.6375015 },
    { lat: 49.0628795, lng: 18.6373876 },
    { lat: 49.0629335, lng: 18.6373852 },
    { lat: 49.0630407, lng: 18.6374292 },
    { lat: 49.0632106, lng: 18.6375693 },
    { lat: 49.0632699, lng: 18.6376044 },
    { lat: 49.0632829, lng: 18.6376359 },
    { lat: 49.0633322, lng: 18.6376298 },
    { lat: 49.0633459, lng: 18.637613 },
    { lat: 49.0633935, lng: 18.6376578 },
    { lat: 49.0634326, lng: 18.6376735 },
    { lat: 49.0634383, lng: 18.6376523 },
    { lat: 49.063456, lng: 18.6376692 },
    { lat: 49.063463, lng: 18.6375655 },
    { lat: 49.0634735, lng: 18.6375106 },
    { lat: 49.0634893, lng: 18.6374867 },
    { lat: 49.0635149, lng: 18.6374835 },
    { lat: 49.063536, lng: 18.6375385 },
    { lat: 49.0636074, lng: 18.6375653 },
    { lat: 49.0636654, lng: 18.6375761 },
    { lat: 49.0636976, lng: 18.6375678 },
    { lat: 49.0637645, lng: 18.6376807 },
    { lat: 49.0639785, lng: 18.6377172 },
    { lat: 49.0640742, lng: 18.6376903 },
    { lat: 49.0641766, lng: 18.6377313 },
    { lat: 49.0643207, lng: 18.6377491 },
    { lat: 49.0644022, lng: 18.6377311 },
    { lat: 49.0644501, lng: 18.6377414 },
    { lat: 49.0645001, lng: 18.6377888 },
    { lat: 49.0645231, lng: 18.6377933 },
    { lat: 49.0647291, lng: 18.6378335 },
    { lat: 49.0647684, lng: 18.6378301 },
    { lat: 49.0647987, lng: 18.6377864 },
    { lat: 49.0648233, lng: 18.6377615 },
    { lat: 49.0648308, lng: 18.637689 },
    { lat: 49.0650136, lng: 18.6376912 },
    { lat: 49.0650668, lng: 18.6377177 },
    { lat: 49.0651844, lng: 18.6377032 },
    { lat: 49.0652861, lng: 18.6377263 },
    { lat: 49.0653419, lng: 18.6377579 },
    { lat: 49.0654435, lng: 18.6378248 },
    { lat: 49.0655764, lng: 18.6378605 },
    { lat: 49.0657049, lng: 18.6377282 },
    { lat: 49.0657731, lng: 18.6376579 },
    { lat: 49.0658596, lng: 18.6376497 },
    { lat: 49.065886, lng: 18.6376615 },
    { lat: 49.0659201, lng: 18.6376685 },
    { lat: 49.0659479, lng: 18.6376802 },
    { lat: 49.0660408, lng: 18.637751 },
    { lat: 49.0662501, lng: 18.6379309 },
    { lat: 49.0662858, lng: 18.637883 },
    { lat: 49.0662904, lng: 18.6377558 },
    { lat: 49.0663265, lng: 18.6377377 },
    { lat: 49.0663453, lng: 18.6377519 },
    { lat: 49.0663794, lng: 18.6377765 },
    { lat: 49.0664436, lng: 18.6377959 },
    { lat: 49.0664627, lng: 18.6378187 },
    { lat: 49.0664874, lng: 18.6378157 },
    { lat: 49.066583, lng: 18.6378708 },
    { lat: 49.0665893, lng: 18.6378659 },
    { lat: 49.0666326, lng: 18.6378319 },
    { lat: 49.066667, lng: 18.6378604 },
    { lat: 49.0667587, lng: 18.6380372 },
    { lat: 49.0667835, lng: 18.6380604 },
    { lat: 49.0668135, lng: 18.6380718 },
    { lat: 49.0668171, lng: 18.6380574 },
    { lat: 49.0668255, lng: 18.6380235 },
    { lat: 49.0669328, lng: 18.637984 },
    { lat: 49.0669857, lng: 18.6379376 },
    { lat: 49.0671324, lng: 18.6379813 },
    { lat: 49.0673264, lng: 18.6382775 },
    { lat: 49.0674205, lng: 18.6382659 },
    { lat: 49.0674478, lng: 18.6382269 },
    { lat: 49.0674766, lng: 18.638219 },
    { lat: 49.0675489, lng: 18.6382227 },
    { lat: 49.0676216, lng: 18.6382961 },
    { lat: 49.067663, lng: 18.6383276 },
    { lat: 49.0676954, lng: 18.6383459 },
    { lat: 49.067805, lng: 18.6384561 },
    { lat: 49.0679038, lng: 18.6384864 },
    { lat: 49.0679422, lng: 18.6384665 },
    { lat: 49.0680141, lng: 18.6385263 },
    { lat: 49.0680561, lng: 18.6385733 },
    { lat: 49.0680804, lng: 18.6385711 },
    { lat: 49.0680918, lng: 18.63857 },
    { lat: 49.0681634, lng: 18.6384942 },
    { lat: 49.0682324, lng: 18.638476 },
    { lat: 49.068321, lng: 18.6385104 },
    { lat: 49.0683617, lng: 18.6385491 },
    { lat: 49.0685039, lng: 18.6386842 },
    { lat: 49.0685836, lng: 18.6387607 },
    { lat: 49.0687004, lng: 18.6388538 },
    { lat: 49.0688694, lng: 18.6388329 },
    { lat: 49.0689001, lng: 18.6388989 },
    { lat: 49.0688945, lng: 18.6389226 },
    { lat: 49.0688972, lng: 18.6389734 },
    { lat: 49.0689416, lng: 18.6390434 },
    { lat: 49.0689772, lng: 18.6390556 },
    { lat: 49.0690493, lng: 18.6389273 },
    { lat: 49.0691579, lng: 18.6388711 },
    { lat: 49.0693656, lng: 18.6388756 },
    { lat: 49.0694347, lng: 18.6389002 },
    { lat: 49.0694929, lng: 18.638852 },
    { lat: 49.0695212, lng: 18.6388111 },
    { lat: 49.069578, lng: 18.6388167 },
    { lat: 49.0696006, lng: 18.6387973 },
    { lat: 49.069612, lng: 18.6386517 },
    { lat: 49.0696373, lng: 18.6385759 },
    { lat: 49.0696306, lng: 18.6385354 },
    { lat: 49.0696567, lng: 18.6385379 },
    { lat: 49.0697098, lng: 18.6384731 },
    { lat: 49.0697353, lng: 18.6384681 },
    { lat: 49.0698178, lng: 18.6384518 },
    { lat: 49.0698435, lng: 18.6384242 },
    { lat: 49.0699545, lng: 18.6384979 },
    { lat: 49.0701714, lng: 18.6385017 },
    { lat: 49.0704109, lng: 18.6385739 },
    { lat: 49.0705507, lng: 18.6387005 },
    { lat: 49.0705622, lng: 18.6387268 },
    { lat: 49.070564, lng: 18.6387608 },
    { lat: 49.0705826, lng: 18.6387721 },
    { lat: 49.0705579, lng: 18.6388137 },
    { lat: 49.0705608, lng: 18.638902 },
    { lat: 49.0705616, lng: 18.6389272 },
    { lat: 49.0707106, lng: 18.6390531 },
    { lat: 49.0709653, lng: 18.6391806 },
    { lat: 49.0710588, lng: 18.6392817 },
    { lat: 49.0712301, lng: 18.6394091 },
    { lat: 49.0713064, lng: 18.6394227 },
    { lat: 49.0713367, lng: 18.6394808 },
    { lat: 49.0713392, lng: 18.6395273 },
    { lat: 49.071362, lng: 18.639596 },
    { lat: 49.0715888, lng: 18.6397468 },
    { lat: 49.071663, lng: 18.6398308 },
    { lat: 49.0719564, lng: 18.6399784 },
    { lat: 49.0720431, lng: 18.6399417 },
    { lat: 49.0720754, lng: 18.6399281 },
    { lat: 49.0720835, lng: 18.6399623 },
    { lat: 49.0720958, lng: 18.6400137 },
    { lat: 49.0720848, lng: 18.6401273 },
    { lat: 49.0721149, lng: 18.6402043 },
    { lat: 49.0721319, lng: 18.6402477 },
    { lat: 49.0722491, lng: 18.6402869 },
    { lat: 49.0724391, lng: 18.6404641 },
    { lat: 49.0725071, lng: 18.6404942 },
    { lat: 49.0725946, lng: 18.6405712 },
    { lat: 49.0726966, lng: 18.6406014 },
    { lat: 49.0727147, lng: 18.6406016 },
    { lat: 49.072999, lng: 18.640604 },
    { lat: 49.0731692, lng: 18.6407514 },
    { lat: 49.0732551, lng: 18.6407408 },
    { lat: 49.0733172, lng: 18.6407331 },
  ],
  Bitarová: [
    { lat: 49.2087373, lng: 18.6554604 },
    { lat: 49.2081851, lng: 18.6555733 },
    { lat: 49.2078257, lng: 18.6556397 },
    { lat: 49.2073641, lng: 18.6560624 },
    { lat: 49.2070693, lng: 18.65648 },
    { lat: 49.2066447, lng: 18.6570413 },
    { lat: 49.206003, lng: 18.6580207 },
    { lat: 49.205803, lng: 18.6583105 },
    { lat: 49.2051966, lng: 18.6585945 },
    { lat: 49.2042533, lng: 18.6586269 },
    { lat: 49.2036644, lng: 18.6583237 },
    { lat: 49.203454, lng: 18.6583533 },
    { lat: 49.2030044, lng: 18.6583374 },
    { lat: 49.2027021, lng: 18.6583187 },
    { lat: 49.2018608, lng: 18.6583804 },
    { lat: 49.2016742, lng: 18.6584766 },
    { lat: 49.2010452, lng: 18.6583962 },
    { lat: 49.1995929, lng: 18.6580197 },
    { lat: 49.1981947, lng: 18.6595723 },
    { lat: 49.197478, lng: 18.6613537 },
    { lat: 49.1975112, lng: 18.6616594 },
    { lat: 49.1975124, lng: 18.661727 },
    { lat: 49.1975208, lng: 18.6620556 },
    { lat: 49.1974064, lng: 18.6639369 },
    { lat: 49.1970206, lng: 18.6640652 },
    { lat: 49.1969919, lng: 18.66446 },
    { lat: 49.1968224, lng: 18.6659006 },
    { lat: 49.1966408, lng: 18.6663045 },
    { lat: 49.1963681, lng: 18.6669071 },
    { lat: 49.1963584, lng: 18.6683637 },
    { lat: 49.196727, lng: 18.6698551 },
    { lat: 49.1967437, lng: 18.6703193 },
    { lat: 49.1968209, lng: 18.6714165 },
    { lat: 49.1968609, lng: 18.671726 },
    { lat: 49.1968338, lng: 18.6720038 },
    { lat: 49.1969026, lng: 18.6724929 },
    { lat: 49.1970659, lng: 18.672872 },
    { lat: 49.1971517, lng: 18.6732166 },
    { lat: 49.1971215, lng: 18.6735005 },
    { lat: 49.1971571, lng: 18.6738968 },
    { lat: 49.1972522, lng: 18.6744115 },
    { lat: 49.1972918, lng: 18.6745441 },
    { lat: 49.1973369, lng: 18.6746517 },
    { lat: 49.1974006, lng: 18.6747485 },
    { lat: 49.1974831, lng: 18.6748151 },
    { lat: 49.1976278, lng: 18.6749055 },
    { lat: 49.1976841, lng: 18.6749696 },
    { lat: 49.1977217, lng: 18.6750815 },
    { lat: 49.1977256, lng: 18.6752007 },
    { lat: 49.1975404, lng: 18.6776712 },
    { lat: 49.1975061, lng: 18.6780007 },
    { lat: 49.1974047, lng: 18.6785595 },
    { lat: 49.1973752, lng: 18.6788332 },
    { lat: 49.1973756, lng: 18.6789886 },
    { lat: 49.1973931, lng: 18.6791328 },
    { lat: 49.1976104, lng: 18.6801295 },
    { lat: 49.1976792, lng: 18.6803392 },
    { lat: 49.1977677, lng: 18.6805429 },
    { lat: 49.1977811, lng: 18.6805639 },
    { lat: 49.1978119, lng: 18.6806326 },
    { lat: 49.1978245, lng: 18.6806578 },
    { lat: 49.1983175, lng: 18.6815794 },
    { lat: 49.1983811, lng: 18.6816982 },
    { lat: 49.1984763, lng: 18.6819202 },
    { lat: 49.1985507, lng: 18.682131 },
    { lat: 49.1986025, lng: 18.6823013 },
    { lat: 49.1986707, lng: 18.6825237 },
    { lat: 49.1987419, lng: 18.6827216 },
    { lat: 49.1988048, lng: 18.6828657 },
    { lat: 49.1988943, lng: 18.683004 },
    { lat: 49.1989813, lng: 18.6831123 },
    { lat: 49.1996149, lng: 18.683579 },
    { lat: 49.1995854, lng: 18.6832523 },
    { lat: 49.1995941, lng: 18.6831217 },
    { lat: 49.1996287, lng: 18.682944 },
    { lat: 49.1997078, lng: 18.6827334 },
    { lat: 49.1998028, lng: 18.6825454 },
    { lat: 49.1998862, lng: 18.6824364 },
    { lat: 49.2003534, lng: 18.6820396 },
    { lat: 49.2010735, lng: 18.6814307 },
    { lat: 49.2021977, lng: 18.6801656 },
    { lat: 49.202216, lng: 18.6801951 },
    { lat: 49.2028995, lng: 18.6801305 },
    { lat: 49.2033075, lng: 18.6798503 },
    { lat: 49.2034728, lng: 18.6795836 },
    { lat: 49.2036188, lng: 18.6794889 },
    { lat: 49.2036462, lng: 18.6794711 },
    { lat: 49.2039165, lng: 18.679295 },
    { lat: 49.2039287, lng: 18.6792906 },
    { lat: 49.2039883, lng: 18.6792692 },
    { lat: 49.2040405, lng: 18.6792498 },
    { lat: 49.2041212, lng: 18.6792204 },
    { lat: 49.2041522, lng: 18.679209 },
    { lat: 49.2045588, lng: 18.6788806 },
    { lat: 49.2046249, lng: 18.6786373 },
    { lat: 49.2048526, lng: 18.6784434 },
    { lat: 49.2053891, lng: 18.6780944 },
    { lat: 49.2059444, lng: 18.677933 },
    { lat: 49.2060594, lng: 18.6779546 },
    { lat: 49.2064121, lng: 18.677993 },
    { lat: 49.2064644, lng: 18.6779985 },
    { lat: 49.2069682, lng: 18.6780606 },
    { lat: 49.2070178, lng: 18.678079 },
    { lat: 49.2082159, lng: 18.6781549 },
    { lat: 49.2087201, lng: 18.6781982 },
    { lat: 49.2087721, lng: 18.678203 },
    { lat: 49.2088566, lng: 18.6782402 },
    { lat: 49.2095654, lng: 18.6785527 },
    { lat: 49.2103271, lng: 18.6792244 },
    { lat: 49.210642, lng: 18.6796134 },
    { lat: 49.2112756, lng: 18.6799099 },
    { lat: 49.211885, lng: 18.68024 },
    { lat: 49.2119064, lng: 18.6830815 },
    { lat: 49.2119246, lng: 18.6841509 },
    { lat: 49.2119164, lng: 18.6854306 },
    { lat: 49.212643, lng: 18.6854795 },
    { lat: 49.213938, lng: 18.6856356 },
    { lat: 49.2140996, lng: 18.6856616 },
    { lat: 49.2141369, lng: 18.685666 },
    { lat: 49.214324, lng: 18.6856878 },
    { lat: 49.2156052, lng: 18.6858384 },
    { lat: 49.2159922, lng: 18.6858641 },
    { lat: 49.2162256, lng: 18.6866344 },
    { lat: 49.2163264, lng: 18.6868746 },
    { lat: 49.2163584, lng: 18.686841 },
    { lat: 49.2170875, lng: 18.6860845 },
    { lat: 49.2171715, lng: 18.6859876 },
    { lat: 49.2175738, lng: 18.6857654 },
    { lat: 49.217825, lng: 18.6866169 },
    { lat: 49.2181956, lng: 18.6864177 },
    { lat: 49.2184988, lng: 18.6863728 },
    { lat: 49.218767, lng: 18.6861537 },
    { lat: 49.2190995, lng: 18.6860642 },
    { lat: 49.2193337, lng: 18.6860383 },
    { lat: 49.2195799, lng: 18.6859608 },
    { lat: 49.219794, lng: 18.6858941 },
    { lat: 49.2201752, lng: 18.6856784 },
    { lat: 49.2202245, lng: 18.6856503 },
    { lat: 49.2202047, lng: 18.6849565 },
    { lat: 49.2201659, lng: 18.6845175 },
    { lat: 49.2200654, lng: 18.684023 },
    { lat: 49.2199616, lng: 18.6836588 },
    { lat: 49.2198049, lng: 18.6832552 },
    { lat: 49.2197442, lng: 18.6828522 },
    { lat: 49.2197165, lng: 18.6827358 },
    { lat: 49.2196947, lng: 18.68264 },
    { lat: 49.2197155, lng: 18.6825642 },
    { lat: 49.2197567, lng: 18.6824257 },
    { lat: 49.219761, lng: 18.6822307 },
    { lat: 49.2198445, lng: 18.6815913 },
    { lat: 49.2198567, lng: 18.6815003 },
    { lat: 49.2198837, lng: 18.6813263 },
    { lat: 49.219983, lng: 18.6812247 },
    { lat: 49.2200769, lng: 18.680778 },
    { lat: 49.220333, lng: 18.680056 },
    { lat: 49.2207175, lng: 18.6795646 },
    { lat: 49.2207871, lng: 18.6793645 },
    { lat: 49.2208365, lng: 18.6791239 },
    { lat: 49.2208526, lng: 18.6789596 },
    { lat: 49.2209449, lng: 18.6784648 },
    { lat: 49.220668, lng: 18.678241 },
    { lat: 49.2203524, lng: 18.6777112 },
    { lat: 49.2199901, lng: 18.6770206 },
    { lat: 49.2196678, lng: 18.6765112 },
    { lat: 49.2192284, lng: 18.6761558 },
    { lat: 49.218718, lng: 18.6755656 },
    { lat: 49.218443, lng: 18.6751875 },
    { lat: 49.2183904, lng: 18.6748924 },
    { lat: 49.2183596, lng: 18.6739084 },
    { lat: 49.218333, lng: 18.6731051 },
    { lat: 49.2182582, lng: 18.6724577 },
    { lat: 49.2181102, lng: 18.6718526 },
    { lat: 49.2178272, lng: 18.6719848 },
    { lat: 49.2176715, lng: 18.6720033 },
    { lat: 49.2175875, lng: 18.6720136 },
    { lat: 49.217416, lng: 18.6720337 },
    { lat: 49.2173219, lng: 18.6720449 },
    { lat: 49.2172488, lng: 18.6720716 },
    { lat: 49.2170896, lng: 18.6721312 },
    { lat: 49.2169292, lng: 18.6721899 },
    { lat: 49.2168351, lng: 18.672225 },
    { lat: 49.2167826, lng: 18.6722956 },
    { lat: 49.2166562, lng: 18.6724663 },
    { lat: 49.2165289, lng: 18.6726375 },
    { lat: 49.216431, lng: 18.6727693 },
    { lat: 49.2163899, lng: 18.6728238 },
    { lat: 49.2163815, lng: 18.6728349 },
    { lat: 49.2157065, lng: 18.6718448 },
    { lat: 49.2154571, lng: 18.6713287 },
    { lat: 49.2153181, lng: 18.6710864 },
    { lat: 49.2151184, lng: 18.6708307 },
    { lat: 49.2148421, lng: 18.6705711 },
    { lat: 49.2147257, lng: 18.6704203 },
    { lat: 49.2145641, lng: 18.6700059 },
    { lat: 49.2144599, lng: 18.6699123 },
    { lat: 49.2143281, lng: 18.669342 },
    { lat: 49.2142881, lng: 18.6688166 },
    { lat: 49.2142853, lng: 18.6687863 },
    { lat: 49.2142709, lng: 18.6687286 },
    { lat: 49.214255, lng: 18.6686609 },
    { lat: 49.2141963, lng: 18.6685967 },
    { lat: 49.2139265, lng: 18.6683565 },
    { lat: 49.2138851, lng: 18.6683638 },
    { lat: 49.2131284, lng: 18.6686399 },
    { lat: 49.2130314, lng: 18.6686822 },
    { lat: 49.2129288, lng: 18.6687453 },
    { lat: 49.2128539, lng: 18.6688036 },
    { lat: 49.2127172, lng: 18.6689489 },
    { lat: 49.2127019, lng: 18.6689147 },
    { lat: 49.2126931, lng: 18.6688948 },
    { lat: 49.2126815, lng: 18.6688674 },
    { lat: 49.212665, lng: 18.6687915 },
    { lat: 49.2125816, lng: 18.6686633 },
    { lat: 49.2125145, lng: 18.6686099 },
    { lat: 49.2125052, lng: 18.6686031 },
    { lat: 49.212375, lng: 18.6685946 },
    { lat: 49.2123636, lng: 18.6685938 },
    { lat: 49.2123033, lng: 18.6685609 },
    { lat: 49.2122572, lng: 18.6684984 },
    { lat: 49.2122367, lng: 18.6684554 },
    { lat: 49.2120923, lng: 18.6681558 },
    { lat: 49.2120739, lng: 18.668148 },
    { lat: 49.2120012, lng: 18.6681183 },
    { lat: 49.2119851, lng: 18.6681117 },
    { lat: 49.2119616, lng: 18.6679861 },
    { lat: 49.2119296, lng: 18.6679118 },
    { lat: 49.2118627, lng: 18.6678223 },
    { lat: 49.2118009, lng: 18.6677405 },
    { lat: 49.2117824, lng: 18.6676886 },
    { lat: 49.2117584, lng: 18.6676202 },
    { lat: 49.2117265, lng: 18.6675484 },
    { lat: 49.2116582, lng: 18.66745 },
    { lat: 49.2116203, lng: 18.6674175 },
    { lat: 49.2116166, lng: 18.6674147 },
    { lat: 49.2115828, lng: 18.6674312 },
    { lat: 49.2115759, lng: 18.6674089 },
    { lat: 49.2115821, lng: 18.6673151 },
    { lat: 49.2116012, lng: 18.6672255 },
    { lat: 49.211602, lng: 18.6672236 },
    { lat: 49.2116226, lng: 18.6671824 },
    { lat: 49.2116645, lng: 18.6670828 },
    { lat: 49.2116847, lng: 18.6670345 },
    { lat: 49.2117114, lng: 18.6670084 },
    { lat: 49.2117463, lng: 18.6668992 },
    { lat: 49.2118098, lng: 18.6666071 },
    { lat: 49.2118182, lng: 18.6665736 },
    { lat: 49.2118461, lng: 18.6664638 },
    { lat: 49.2119079, lng: 18.6662686 },
    { lat: 49.2119201, lng: 18.6661336 },
    { lat: 49.2119499, lng: 18.6659947 },
    { lat: 49.211965, lng: 18.6659816 },
    { lat: 49.2119941, lng: 18.6659557 },
    { lat: 49.2119968, lng: 18.665859 },
    { lat: 49.2120006, lng: 18.6657381 },
    { lat: 49.2120026, lng: 18.665731 },
    { lat: 49.2120161, lng: 18.6656915 },
    { lat: 49.2120374, lng: 18.6656264 },
    { lat: 49.212103, lng: 18.6653908 },
    { lat: 49.2121474, lng: 18.6652302 },
    { lat: 49.2121755, lng: 18.6651452 },
    { lat: 49.2122438, lng: 18.6649441 },
    { lat: 49.2121876, lng: 18.6647134 },
    { lat: 49.2122407, lng: 18.6645023 },
    { lat: 49.2122496, lng: 18.6642227 },
    { lat: 49.2122351, lng: 18.6635825 },
    { lat: 49.2122694, lng: 18.6633167 },
    { lat: 49.2123833, lng: 18.6630163 },
    { lat: 49.2123981, lng: 18.6626577 },
    { lat: 49.2125053, lng: 18.6622776 },
    { lat: 49.2124658, lng: 18.6620221 },
    { lat: 49.2124295, lng: 18.6619519 },
    { lat: 49.2124773, lng: 18.66181 },
    { lat: 49.2125045, lng: 18.6613024 },
    { lat: 49.2124553, lng: 18.660842 },
    { lat: 49.2124146, lng: 18.6603099 },
    { lat: 49.2124124, lng: 18.6601609 },
    { lat: 49.2123594, lng: 18.6599445 },
    { lat: 49.2123477, lng: 18.6597057 },
    { lat: 49.2121459, lng: 18.6592595 },
    { lat: 49.212133, lng: 18.6584185 },
    { lat: 49.2123946, lng: 18.6576745 },
    { lat: 49.2124186, lng: 18.6575298 },
    { lat: 49.2121947, lng: 18.6557293 },
    { lat: 49.212045, lng: 18.6557366 },
    { lat: 49.2116957, lng: 18.6558699 },
    { lat: 49.2115691, lng: 18.6558841 },
    { lat: 49.2113164, lng: 18.6558598 },
    { lat: 49.211094, lng: 18.6557931 },
    { lat: 49.2110484, lng: 18.6557843 },
    { lat: 49.2108462, lng: 18.6556697 },
    { lat: 49.2106465, lng: 18.655541 },
    { lat: 49.2104867, lng: 18.6554413 },
    { lat: 49.2103199, lng: 18.6553801 },
    { lat: 49.2103291, lng: 18.6552999 },
    { lat: 49.2100791, lng: 18.6552637 },
    { lat: 49.2099008, lng: 18.6552612 },
    { lat: 49.2096163, lng: 18.6553205 },
    { lat: 49.2096138, lng: 18.6553572 },
    { lat: 49.2093203, lng: 18.6554147 },
    { lat: 49.209166, lng: 18.6554388 },
    { lat: 49.2089505, lng: 18.6554615 },
    { lat: 49.2087373, lng: 18.6554604 },
  ],
  Brezany: [
    { lat: 49.1839806, lng: 18.6807202 },
    { lat: 49.1840778, lng: 18.6803589 },
    { lat: 49.1844044, lng: 18.6805199 },
    { lat: 49.1844871, lng: 18.6805282 },
    { lat: 49.1851128, lng: 18.6805904 },
    { lat: 49.1856168, lng: 18.6806918 },
    { lat: 49.1860087, lng: 18.6807698 },
    { lat: 49.1868799, lng: 18.6812309 },
    { lat: 49.187889, lng: 18.6801793 },
    { lat: 49.1891467, lng: 18.6802082 },
    { lat: 49.1898536, lng: 18.6800525 },
    { lat: 49.1904753, lng: 18.6802544 },
    { lat: 49.1909391, lng: 18.6809426 },
    { lat: 49.1909808, lng: 18.6809847 },
    { lat: 49.1909844, lng: 18.680989 },
    { lat: 49.1910093, lng: 18.6810141 },
    { lat: 49.1910225, lng: 18.6810272 },
    { lat: 49.1910583, lng: 18.6810639 },
    { lat: 49.1911095, lng: 18.6811164 },
    { lat: 49.1911275, lng: 18.6811362 },
    { lat: 49.1913152, lng: 18.680591 },
    { lat: 49.191448, lng: 18.6796679 },
    { lat: 49.1916692, lng: 18.678881 },
    { lat: 49.1916907, lng: 18.6788816 },
    { lat: 49.1920794, lng: 18.6788937 },
    { lat: 49.1923311, lng: 18.6788364 },
    { lat: 49.1935034, lng: 18.6784835 },
    { lat: 49.1939009, lng: 18.678368 },
    { lat: 49.1942561, lng: 18.6783679 },
    { lat: 49.1944104, lng: 18.6784254 },
    { lat: 49.194712, lng: 18.6786277 },
    { lat: 49.1951993, lng: 18.6787809 },
    { lat: 49.1952441, lng: 18.6787967 },
    { lat: 49.1953716, lng: 18.6789249 },
    { lat: 49.1953872, lng: 18.6789431 },
    { lat: 49.1954253, lng: 18.6789792 },
    { lat: 49.1955006, lng: 18.6789772 },
    { lat: 49.1955931, lng: 18.6789755 },
    { lat: 49.1956032, lng: 18.678975 },
    { lat: 49.1956573, lng: 18.6789741 },
    { lat: 49.1969322, lng: 18.6789428 },
    { lat: 49.1973436, lng: 18.6788353 },
    { lat: 49.1973752, lng: 18.6788332 },
    { lat: 49.1974047, lng: 18.6785595 },
    { lat: 49.1975061, lng: 18.6780007 },
    { lat: 49.1975404, lng: 18.6776712 },
    { lat: 49.1977256, lng: 18.6752007 },
    { lat: 49.1977217, lng: 18.6750815 },
    { lat: 49.1976841, lng: 18.6749696 },
    { lat: 49.1976278, lng: 18.6749055 },
    { lat: 49.1974831, lng: 18.6748151 },
    { lat: 49.1974006, lng: 18.6747485 },
    { lat: 49.1973369, lng: 18.6746517 },
    { lat: 49.1972918, lng: 18.6745441 },
    { lat: 49.1972522, lng: 18.6744115 },
    { lat: 49.1971571, lng: 18.6738968 },
    { lat: 49.1971215, lng: 18.6735005 },
    { lat: 49.1971517, lng: 18.6732166 },
    { lat: 49.1970659, lng: 18.672872 },
    { lat: 49.1969026, lng: 18.6724929 },
    { lat: 49.1968338, lng: 18.6720038 },
    { lat: 49.1968609, lng: 18.671726 },
    { lat: 49.1968209, lng: 18.6714165 },
    { lat: 49.1967437, lng: 18.6703193 },
    { lat: 49.196727, lng: 18.6698551 },
    { lat: 49.1963584, lng: 18.6683637 },
    { lat: 49.1963681, lng: 18.6669071 },
    { lat: 49.1966408, lng: 18.6663045 },
    { lat: 49.1968224, lng: 18.6659006 },
    { lat: 49.1969919, lng: 18.66446 },
    { lat: 49.1970206, lng: 18.6640652 },
    { lat: 49.1974064, lng: 18.6639369 },
    { lat: 49.1975208, lng: 18.6620556 },
    { lat: 49.1975124, lng: 18.661727 },
    { lat: 49.1975112, lng: 18.6616594 },
    { lat: 49.1974928, lng: 18.6616332 },
    { lat: 49.1963961, lng: 18.6600678 },
    { lat: 49.1955711, lng: 18.6589688 },
    { lat: 49.1953457, lng: 18.658738 },
    { lat: 49.1951804, lng: 18.6583618 },
    { lat: 49.1949572, lng: 18.6578113 },
    { lat: 49.1948971, lng: 18.6574881 },
    { lat: 49.1948593, lng: 18.6572839 },
    { lat: 49.1947128, lng: 18.6564759 },
    { lat: 49.1944294, lng: 18.6549604 },
    { lat: 49.1943947, lng: 18.6546895 },
    { lat: 49.1943865, lng: 18.6540877 },
    { lat: 49.194174, lng: 18.6532492 },
    { lat: 49.1940742, lng: 18.6528955 },
    { lat: 49.1937931, lng: 18.6511295 },
    { lat: 49.1935805, lng: 18.6499474 },
    { lat: 49.1931783, lng: 18.6488516 },
    { lat: 49.1929708, lng: 18.6481932 },
    { lat: 49.1927663, lng: 18.6476747 },
    { lat: 49.1923711, lng: 18.6467298 },
    { lat: 49.191801, lng: 18.6459576 },
    { lat: 49.1912894, lng: 18.6453711 },
    { lat: 49.1911099, lng: 18.645217 },
    { lat: 49.1907161, lng: 18.6452105 },
    { lat: 49.1903903, lng: 18.6468055 },
    { lat: 49.1894149, lng: 18.6487609 },
    { lat: 49.1893616, lng: 18.6487981 },
    { lat: 49.1855478, lng: 18.6514367 },
    { lat: 49.1836992, lng: 18.6554092 },
    { lat: 49.1835833, lng: 18.6555858 },
    { lat: 49.1835133, lng: 18.6556917 },
    { lat: 49.1824166, lng: 18.6573581 },
    { lat: 49.1816023, lng: 18.6585937 },
    { lat: 49.1812998, lng: 18.6601408 },
    { lat: 49.1810678, lng: 18.6608078 },
    { lat: 49.1807316, lng: 18.6615716 },
    { lat: 49.1807035, lng: 18.6616349 },
    { lat: 49.1806793, lng: 18.6616909 },
    { lat: 49.1802741, lng: 18.6622145 },
    { lat: 49.1797199, lng: 18.6627796 },
    { lat: 49.1795565, lng: 18.6630402 },
    { lat: 49.178927, lng: 18.6642918 },
    { lat: 49.1783831, lng: 18.6650062 },
    { lat: 49.179016, lng: 18.6667627 },
    { lat: 49.1795929, lng: 18.6681566 },
    { lat: 49.181092, lng: 18.6711255 },
    { lat: 49.1809394, lng: 18.671224 },
    { lat: 49.1808388, lng: 18.6714133 },
    { lat: 49.1807134, lng: 18.6719208 },
    { lat: 49.1806648, lng: 18.6730486 },
    { lat: 49.180495, lng: 18.6735507 },
    { lat: 49.180491, lng: 18.6740905 },
    { lat: 49.1806516, lng: 18.6754031 },
    { lat: 49.1812643, lng: 18.6756222 },
    { lat: 49.1819739, lng: 18.6777419 },
    { lat: 49.1819608, lng: 18.6777918 },
    { lat: 49.1819123, lng: 18.6779838 },
    { lat: 49.1819567, lng: 18.6782699 },
    { lat: 49.181884, lng: 18.6783643 },
    { lat: 49.1817434, lng: 18.6785449 },
    { lat: 49.1816142, lng: 18.6785539 },
    { lat: 49.1812492, lng: 18.6784315 },
    { lat: 49.1810572, lng: 18.6784905 },
    { lat: 49.1810488, lng: 18.6786732 },
    { lat: 49.1811272, lng: 18.6788323 },
    { lat: 49.1817624, lng: 18.6793915 },
    { lat: 49.182432, lng: 18.6796441 },
    { lat: 49.1829303, lng: 18.6799823 },
    { lat: 49.1831695, lng: 18.6801998 },
    { lat: 49.1835706, lng: 18.6804054 },
    { lat: 49.1839806, lng: 18.6807202 },
  ],
  Divina: [
    { lat: 49.340186, lng: 18.67847 },
    { lat: 49.34016, lng: 18.677924 },
    { lat: 49.340171, lng: 18.677075 },
    { lat: 49.340213, lng: 18.67662 },
    { lat: 49.340254, lng: 18.676165 },
    { lat: 49.340293, lng: 18.675933 },
    { lat: 49.340333, lng: 18.675687 },
    { lat: 49.340435, lng: 18.674933 },
    { lat: 49.340464, lng: 18.674842 },
    { lat: 49.3406, lng: 18.674407 },
    { lat: 49.340743, lng: 18.674276 },
    { lat: 49.34092, lng: 18.674097 },
    { lat: 49.341009, lng: 18.67401 },
    { lat: 49.341244, lng: 18.673781 },
    { lat: 49.34125, lng: 18.673723 },
    { lat: 49.341256, lng: 18.673606 },
    { lat: 49.341257, lng: 18.673582 },
    { lat: 49.3407066, lng: 18.6735775 },
    { lat: 49.3404513, lng: 18.6735255 },
    { lat: 49.3402811, lng: 18.6735298 },
    { lat: 49.3401427, lng: 18.6735762 },
    { lat: 49.3398385, lng: 18.6735617 },
    { lat: 49.338832, lng: 18.6738284 },
    { lat: 49.3384264, lng: 18.6740285 },
    { lat: 49.3383914, lng: 18.6740451 },
    { lat: 49.3379847, lng: 18.6742461 },
    { lat: 49.3370525, lng: 18.6750894 },
    { lat: 49.3335095, lng: 18.6750916 },
    { lat: 49.3334826, lng: 18.6750917 },
    { lat: 49.3310494, lng: 18.6748166 },
    { lat: 49.3302524, lng: 18.6743751 },
    { lat: 49.3301934, lng: 18.6741561 },
    { lat: 49.3290336, lng: 18.6733306 },
    { lat: 49.3284934, lng: 18.673693 },
    { lat: 49.3278099, lng: 18.6736291 },
    { lat: 49.3274701, lng: 18.6736421 },
    { lat: 49.3267125, lng: 18.673771 },
    { lat: 49.3259402, lng: 18.6737973 },
    { lat: 49.3250765, lng: 18.6736938 },
    { lat: 49.3246077, lng: 18.6735466 },
    { lat: 49.3241791, lng: 18.6734383 },
    { lat: 49.3238919, lng: 18.6727822 },
    { lat: 49.3236736, lng: 18.6723001 },
    { lat: 49.3229672, lng: 18.6712623 },
    { lat: 49.3219923, lng: 18.6694988 },
    { lat: 49.3215719, lng: 18.668416 },
    { lat: 49.3205164, lng: 18.6687115 },
    { lat: 49.3197486, lng: 18.6689523 },
    { lat: 49.3193664, lng: 18.6691662 },
    { lat: 49.3191934, lng: 18.6693271 },
    { lat: 49.3188962, lng: 18.669337 },
    { lat: 49.3185514, lng: 18.6693857 },
    { lat: 49.3178891, lng: 18.6693157 },
    { lat: 49.3176542, lng: 18.6691166 },
    { lat: 49.3173149, lng: 18.6690334 },
    { lat: 49.3170583, lng: 18.6686787 },
    { lat: 49.3169169, lng: 18.6686285 },
    { lat: 49.31597, lng: 18.6682922 },
    { lat: 49.3155148, lng: 18.6684706 },
    { lat: 49.3151802, lng: 18.6682194 },
    { lat: 49.3150684, lng: 18.6682184 },
    { lat: 49.3149513, lng: 18.6680901 },
    { lat: 49.3148354, lng: 18.6677126 },
    { lat: 49.3146927, lng: 18.667637 },
    { lat: 49.3146592, lng: 18.6676191 },
    { lat: 49.3144768, lng: 18.667522 },
    { lat: 49.3143362, lng: 18.6676557 },
    { lat: 49.3142432, lng: 18.6670668 },
    { lat: 49.3141485, lng: 18.6670276 },
    { lat: 49.314046, lng: 18.6669846 },
    { lat: 49.3138822, lng: 18.6669162 },
    { lat: 49.313647, lng: 18.6668173 },
    { lat: 49.313512, lng: 18.6667613 },
    { lat: 49.3134137, lng: 18.6666087 },
    { lat: 49.3132002, lng: 18.6662801 },
    { lat: 49.3130344, lng: 18.6661752 },
    { lat: 49.3126159, lng: 18.6661477 },
    { lat: 49.3121489, lng: 18.6658692 },
    { lat: 49.3117919, lng: 18.6657276 },
    { lat: 49.3117713, lng: 18.6657211 },
    { lat: 49.3108306, lng: 18.6654184 },
    { lat: 49.3103424, lng: 18.6648856 },
    { lat: 49.3099987, lng: 18.6646776 },
    { lat: 49.309653, lng: 18.6643234 },
    { lat: 49.309234, lng: 18.6641844 },
    { lat: 49.3087647, lng: 18.6639243 },
    { lat: 49.3080927, lng: 18.6638808 },
    { lat: 49.3075384, lng: 18.6637896 },
    { lat: 49.3067482, lng: 18.6639002 },
    { lat: 49.3058228, lng: 18.6639274 },
    { lat: 49.3053388, lng: 18.6641354 },
    { lat: 49.304887, lng: 18.6642085 },
    { lat: 49.304645, lng: 18.6641766 },
    { lat: 49.3043231, lng: 18.6642742 },
    { lat: 49.3042027, lng: 18.6647242 },
    { lat: 49.3038303, lng: 18.665726 },
    { lat: 49.3031344, lng: 18.6658907 },
    { lat: 49.3025189, lng: 18.6659191 },
    { lat: 49.3020549, lng: 18.6658242 },
    { lat: 49.3017182, lng: 18.6657035 },
    { lat: 49.3013018, lng: 18.6656762 },
    { lat: 49.3011418, lng: 18.6655507 },
    { lat: 49.3010489, lng: 18.665478 },
    { lat: 49.3001466, lng: 18.6641126 },
    { lat: 49.2997975, lng: 18.6644195 },
    { lat: 49.2996451, lng: 18.664478 },
    { lat: 49.2990858, lng: 18.6649475 },
    { lat: 49.2989483, lng: 18.6649883 },
    { lat: 49.2982063, lng: 18.6652082 },
    { lat: 49.2980536, lng: 18.6652831 },
    { lat: 49.2978038, lng: 18.6652738 },
    { lat: 49.2960296, lng: 18.6643688 },
    { lat: 49.2952656, lng: 18.6644231 },
    { lat: 49.2949191, lng: 18.6647379 },
    { lat: 49.2946542, lng: 18.6651012 },
    { lat: 49.294313, lng: 18.6651322 },
    { lat: 49.2938936, lng: 18.6654965 },
    { lat: 49.2935882, lng: 18.6655884 },
    { lat: 49.2930779, lng: 18.6654252 },
    { lat: 49.2924929, lng: 18.664962 },
    { lat: 49.2922592, lng: 18.663463 },
    { lat: 49.2919487, lng: 18.6625374 },
    { lat: 49.2918737, lng: 18.662227 },
    { lat: 49.2918789, lng: 18.6616858 },
    { lat: 49.2918459, lng: 18.6607856 },
    { lat: 49.2917614, lng: 18.6601036 },
    { lat: 49.2915429, lng: 18.6597701 },
    { lat: 49.2912491, lng: 18.6593517 },
    { lat: 49.2910698, lng: 18.6585852 },
    { lat: 49.2908106, lng: 18.6562621 },
    { lat: 49.2904674, lng: 18.6544887 },
    { lat: 49.290283, lng: 18.6526025 },
    { lat: 49.2898131, lng: 18.6515429 },
    { lat: 49.2891535, lng: 18.6515002 },
    { lat: 49.2888556, lng: 18.6515837 },
    { lat: 49.28881, lng: 18.6515734 },
    { lat: 49.288397, lng: 18.6514811 },
    { lat: 49.288113, lng: 18.6509119 },
    { lat: 49.2879634, lng: 18.6503896 },
    { lat: 49.287508, lng: 18.65005 },
    { lat: 49.2865042, lng: 18.649662 },
    { lat: 49.2864561, lng: 18.6496726 },
    { lat: 49.2862991, lng: 18.649773 },
    { lat: 49.2861701, lng: 18.6499118 },
    { lat: 49.286048, lng: 18.6501418 },
    { lat: 49.2857628, lng: 18.6506551 },
    { lat: 49.2856751, lng: 18.6508516 },
    { lat: 49.2854804, lng: 18.6512221 },
    { lat: 49.2853705, lng: 18.6514441 },
    { lat: 49.2852809, lng: 18.651586 },
    { lat: 49.2852478, lng: 18.6524072 },
    { lat: 49.2851747, lng: 18.6527096 },
    { lat: 49.2849966, lng: 18.652948 },
    { lat: 49.2849696, lng: 18.6530135 },
    { lat: 49.2849761, lng: 18.6531984 },
    { lat: 49.2849509, lng: 18.6534681 },
    { lat: 49.2849172, lng: 18.6536846 },
    { lat: 49.2848935, lng: 18.6538736 },
    { lat: 49.2848927, lng: 18.6540067 },
    { lat: 49.2849161, lng: 18.6541313 },
    { lat: 49.2849471, lng: 18.6542716 },
    { lat: 49.2849864, lng: 18.6543707 },
    { lat: 49.2849286, lng: 18.6545773 },
    { lat: 49.2848558, lng: 18.6546304 },
    { lat: 49.2847296, lng: 18.6545907 },
    { lat: 49.2846846, lng: 18.6546154 },
    { lat: 49.2845575, lng: 18.6547503 },
    { lat: 49.2845117, lng: 18.6548221 },
    { lat: 49.284418, lng: 18.6547976 },
    { lat: 49.2842704, lng: 18.6554198 },
    { lat: 49.2842117, lng: 18.6558205 },
    { lat: 49.2845106, lng: 18.656057 },
    { lat: 49.2846127, lng: 18.656195 },
    { lat: 49.2845552, lng: 18.6563224 },
    { lat: 49.2845361, lng: 18.6564129 },
    { lat: 49.2845249, lng: 18.6565808 },
    { lat: 49.2844781, lng: 18.6567235 },
    { lat: 49.2844735, lng: 18.6568061 },
    { lat: 49.2844749, lng: 18.6570024 },
    { lat: 49.2844167, lng: 18.6570956 },
    { lat: 49.2843786, lng: 18.6573578 },
    { lat: 49.2842479, lng: 18.6575268 },
    { lat: 49.2841571, lng: 18.6577547 },
    { lat: 49.2840845, lng: 18.6578739 },
    { lat: 49.2840073, lng: 18.6580142 },
    { lat: 49.2839002, lng: 18.6581607 },
    { lat: 49.2838424, lng: 18.6584565 },
    { lat: 49.2836673, lng: 18.6587238 },
    { lat: 49.2835189, lng: 18.6589697 },
    { lat: 49.2834864, lng: 18.6591019 },
    { lat: 49.2834723, lng: 18.659198 },
    { lat: 49.2834242, lng: 18.6594401 },
    { lat: 49.2834189, lng: 18.6595499 },
    { lat: 49.2834006, lng: 18.6598039 },
    { lat: 49.2833683, lng: 18.6600456 },
    { lat: 49.2833778, lng: 18.6601185 },
    { lat: 49.2833606, lng: 18.6603926 },
    { lat: 49.2832891, lng: 18.6605109 },
    { lat: 49.2832568, lng: 18.6604943 },
    { lat: 49.2831704, lng: 18.6605266 },
    { lat: 49.2831415, lng: 18.6607448 },
    { lat: 49.2830698, lng: 18.6607959 },
    { lat: 49.2828819, lng: 18.6609112 },
    { lat: 49.2828777, lng: 18.6609847 },
    { lat: 49.2828343, lng: 18.6610172 },
    { lat: 49.282808, lng: 18.6611353 },
    { lat: 49.2826774, lng: 18.6612049 },
    { lat: 49.2825423, lng: 18.6613357 },
    { lat: 49.2822393, lng: 18.6613208 },
    { lat: 49.2821489, lng: 18.6612745 },
    { lat: 49.2820971, lng: 18.6614257 },
    { lat: 49.2820614, lng: 18.6614952 },
    { lat: 49.2820149, lng: 18.6615157 },
    { lat: 49.2819266, lng: 18.6615292 },
    { lat: 49.2819076, lng: 18.6617031 },
    { lat: 49.2818904, lng: 18.6617388 },
    { lat: 49.2818355, lng: 18.6617864 },
    { lat: 49.2817929, lng: 18.6618563 },
    { lat: 49.2816953, lng: 18.6619334 },
    { lat: 49.2815945, lng: 18.6620726 },
    { lat: 49.2814659, lng: 18.6622203 },
    { lat: 49.2813192, lng: 18.6621539 },
    { lat: 49.2812182, lng: 18.6621209 },
    { lat: 49.2811706, lng: 18.6622037 },
    { lat: 49.2811279, lng: 18.6622502 },
    { lat: 49.2810755, lng: 18.6622379 },
    { lat: 49.2810176, lng: 18.6621442 },
    { lat: 49.2807993, lng: 18.6622384 },
    { lat: 49.2806827, lng: 18.6622726 },
    { lat: 49.2806206, lng: 18.6622731 },
    { lat: 49.2805528, lng: 18.6623761 },
    { lat: 49.2804549, lng: 18.6625724 },
    { lat: 49.280307, lng: 18.6624195 },
    { lat: 49.2802278, lng: 18.6625856 },
    { lat: 49.2801128, lng: 18.6625009 },
    { lat: 49.2800441, lng: 18.6624581 },
    { lat: 49.2798849, lng: 18.662455 },
    { lat: 49.2797103, lng: 18.6626069 },
    { lat: 49.2796985, lng: 18.6626611 },
    { lat: 49.279382, lng: 18.6627992 },
    { lat: 49.2792023, lng: 18.6627921 },
    { lat: 49.279076, lng: 18.6627683 },
    { lat: 49.2788663, lng: 18.6626844 },
    { lat: 49.2787249, lng: 18.662656 },
    { lat: 49.2785683, lng: 18.6625728 },
    { lat: 49.2785138, lng: 18.6625441 },
    { lat: 49.2784975, lng: 18.6625353 },
    { lat: 49.2778579, lng: 18.662702 },
    { lat: 49.2776926, lng: 18.6628597 },
    { lat: 49.2776145, lng: 18.6630055 },
    { lat: 49.2775838, lng: 18.6630649 },
    { lat: 49.2775664, lng: 18.6630974 },
    { lat: 49.2773027, lng: 18.6632939 },
    { lat: 49.277014, lng: 18.6636984 },
    { lat: 49.2770358, lng: 18.6637253 },
    { lat: 49.2770914, lng: 18.6637939 },
    { lat: 49.2771712, lng: 18.663878 },
    { lat: 49.2772574, lng: 18.6640338 },
    { lat: 49.2773509, lng: 18.6641617 },
    { lat: 49.2773761, lng: 18.6643164 },
    { lat: 49.2774312, lng: 18.6645086 },
    { lat: 49.2774621, lng: 18.6647085 },
    { lat: 49.2775192, lng: 18.6651287 },
    { lat: 49.2776581, lng: 18.6658553 },
    { lat: 49.2777328, lng: 18.66633 },
    { lat: 49.2778394, lng: 18.6663624 },
    { lat: 49.2780137, lng: 18.6663737 },
    { lat: 49.2784385, lng: 18.6658977 },
    { lat: 49.2786529, lng: 18.665623 },
    { lat: 49.2787435, lng: 18.6655653 },
    { lat: 49.2790349, lng: 18.6654693 },
    { lat: 49.2792554, lng: 18.6654602 },
    { lat: 49.2794579, lng: 18.6654915 },
    { lat: 49.2799317, lng: 18.6655187 },
    { lat: 49.280161, lng: 18.6656543 },
    { lat: 49.2802392, lng: 18.6657465 },
    { lat: 49.2802921, lng: 18.6658278 },
    { lat: 49.2803541, lng: 18.6659989 },
    { lat: 49.2803698, lng: 18.6663401 },
    { lat: 49.2804295, lng: 18.6668316 },
    { lat: 49.2804087, lng: 18.6672701 },
    { lat: 49.2803881, lng: 18.6674746 },
    { lat: 49.2803477, lng: 18.6677793 },
    { lat: 49.2804626, lng: 18.6682044 },
    { lat: 49.2806841, lng: 18.6687485 },
    { lat: 49.2807273, lng: 18.669221 },
    { lat: 49.280751, lng: 18.6693698 },
    { lat: 49.2807562, lng: 18.6694685 },
    { lat: 49.2807209, lng: 18.6695693 },
    { lat: 49.2804945, lng: 18.6702586 },
    { lat: 49.2803927, lng: 18.6704871 },
    { lat: 49.2803577, lng: 18.6705323 },
    { lat: 49.2802465, lng: 18.6706457 },
    { lat: 49.2801517, lng: 18.67073 },
    { lat: 49.2800829, lng: 18.6710065 },
    { lat: 49.2800726, lng: 18.6710456 },
    { lat: 49.2800207, lng: 18.6712392 },
    { lat: 49.2800016, lng: 18.6713033 },
    { lat: 49.27997, lng: 18.6714105 },
    { lat: 49.2798742, lng: 18.6716459 },
    { lat: 49.2798259, lng: 18.6717111 },
    { lat: 49.279742, lng: 18.6717694 },
    { lat: 49.2795186, lng: 18.6718941 },
    { lat: 49.2793475, lng: 18.671964 },
    { lat: 49.2792113, lng: 18.6720343 },
    { lat: 49.279161, lng: 18.6720448 },
    { lat: 49.279025, lng: 18.6720955 },
    { lat: 49.278776, lng: 18.6721417 },
    { lat: 49.2786734, lng: 18.6721638 },
    { lat: 49.2785005, lng: 18.6721338 },
    { lat: 49.2784882, lng: 18.6722632 },
    { lat: 49.2784855, lng: 18.6723419 },
    { lat: 49.2784624, lng: 18.6723957 },
    { lat: 49.2783764, lng: 18.672411 },
    { lat: 49.2781688, lng: 18.6723864 },
    { lat: 49.2780817, lng: 18.6724462 },
    { lat: 49.2780987, lng: 18.6728129 },
    { lat: 49.2781124, lng: 18.6728567 },
    { lat: 49.2780822, lng: 18.6729482 },
    { lat: 49.2779569, lng: 18.6731789 },
    { lat: 49.2778829, lng: 18.6733571 },
    { lat: 49.2777749, lng: 18.6734061 },
    { lat: 49.2777508, lng: 18.6734427 },
    { lat: 49.2777075, lng: 18.6735622 },
    { lat: 49.2776282, lng: 18.6736803 },
    { lat: 49.2775328, lng: 18.6737986 },
    { lat: 49.2775889, lng: 18.6739441 },
    { lat: 49.2776306, lng: 18.6739852 },
    { lat: 49.2776593, lng: 18.6740597 },
    { lat: 49.2776902, lng: 18.6741743 },
    { lat: 49.2777383, lng: 18.674254 },
    { lat: 49.2777684, lng: 18.6742868 },
    { lat: 49.2778124, lng: 18.6743099 },
    { lat: 49.2777768, lng: 18.6743833 },
    { lat: 49.2774061, lng: 18.6743892 },
    { lat: 49.2774093, lng: 18.6745564 },
    { lat: 49.2773897, lng: 18.6745921 },
    { lat: 49.2771884, lng: 18.6748357 },
    { lat: 49.2773484, lng: 18.6753906 },
    { lat: 49.2772643, lng: 18.6758037 },
    { lat: 49.2772646, lng: 18.6758546 },
    { lat: 49.2772748, lng: 18.6763261 },
    { lat: 49.2772859, lng: 18.6767907 },
    { lat: 49.2772647, lng: 18.6775456 },
    { lat: 49.2772233, lng: 18.6780018 },
    { lat: 49.2770727, lng: 18.6790136 },
    { lat: 49.2768567, lng: 18.6802219 },
    { lat: 49.2764461, lng: 18.6807525 },
    { lat: 49.2752122, lng: 18.6809654 },
    { lat: 49.274509, lng: 18.6806742 },
    { lat: 49.2742478, lng: 18.6808519 },
    { lat: 49.2736351, lng: 18.6809967 },
    { lat: 49.273084, lng: 18.6814484 },
    { lat: 49.272627, lng: 18.6816813 },
    { lat: 49.272297, lng: 18.6818584 },
    { lat: 49.2719753, lng: 18.682067 },
    { lat: 49.2713493, lng: 18.6824073 },
    { lat: 49.2706577, lng: 18.6825932 },
    { lat: 49.2699105, lng: 18.6829434 },
    { lat: 49.2694249, lng: 18.6833823 },
    { lat: 49.2693699, lng: 18.6834497 },
    { lat: 49.2693255, lng: 18.683505 },
    { lat: 49.2692557, lng: 18.683592 },
    { lat: 49.269276, lng: 18.6836364 },
    { lat: 49.2688306, lng: 18.6839783 },
    { lat: 49.2673167, lng: 18.6846073 },
    { lat: 49.2672709, lng: 18.6846411 },
    { lat: 49.266338, lng: 18.6853635 },
    { lat: 49.2659589, lng: 18.6856575 },
    { lat: 49.2657731, lng: 18.6858136 },
    { lat: 49.2655538, lng: 18.6859973 },
    { lat: 49.2653009, lng: 18.6862017 },
    { lat: 49.2651829, lng: 18.6863404 },
    { lat: 49.2650064, lng: 18.6865867 },
    { lat: 49.2649813, lng: 18.6866223 },
    { lat: 49.2648795, lng: 18.6867883 },
    { lat: 49.2646148, lng: 18.687222 },
    { lat: 49.2643049, lng: 18.6877815 },
    { lat: 49.2636999, lng: 18.6885658 },
    { lat: 49.2636015, lng: 18.6895416 },
    { lat: 49.2636816, lng: 18.6897798 },
    { lat: 49.263789, lng: 18.6904288 },
    { lat: 49.2639754, lng: 18.691163 },
    { lat: 49.2641093, lng: 18.6917362 },
    { lat: 49.2643155, lng: 18.6923738 },
    { lat: 49.2645513, lng: 18.6931045 },
    { lat: 49.2647972, lng: 18.6938792 },
    { lat: 49.2650616, lng: 18.6943374 },
    { lat: 49.2654869, lng: 18.6949828 },
    { lat: 49.2654925, lng: 18.6950269 },
    { lat: 49.265399, lng: 18.6950727 },
    { lat: 49.2654462, lng: 18.6951524 },
    { lat: 49.2654398, lng: 18.6952926 },
    { lat: 49.2654325, lng: 18.6954495 },
    { lat: 49.2654303, lng: 18.6955151 },
    { lat: 49.2654267, lng: 18.6955776 },
    { lat: 49.26558, lng: 18.6956162 },
    { lat: 49.2656178, lng: 18.6958232 },
    { lat: 49.2656329, lng: 18.6958943 },
    { lat: 49.2656462, lng: 18.6960006 },
    { lat: 49.2656527, lng: 18.696037 },
    { lat: 49.2656586, lng: 18.6960648 },
    { lat: 49.2656765, lng: 18.6961666 },
    { lat: 49.265701, lng: 18.6962921 },
    { lat: 49.2657088, lng: 18.696332 },
    { lat: 49.2654622, lng: 18.6964861 },
    { lat: 49.265203, lng: 18.696662 },
    { lat: 49.2651677, lng: 18.6966775 },
    { lat: 49.2651286, lng: 18.6966856 },
    { lat: 49.2650316, lng: 18.6967061 },
    { lat: 49.2650451, lng: 18.6967294 },
    { lat: 49.2650574, lng: 18.696833 },
    { lat: 49.2652202, lng: 18.6968401 },
    { lat: 49.2653719, lng: 18.6967871 },
    { lat: 49.2657647, lng: 18.6965584 },
    { lat: 49.2657642, lng: 18.6965902 },
    { lat: 49.2657627, lng: 18.6966915 },
    { lat: 49.2657623, lng: 18.6967295 },
    { lat: 49.2658249, lng: 18.6968857 },
    { lat: 49.2658537, lng: 18.696962 },
    { lat: 49.2658638, lng: 18.6971127 },
    { lat: 49.2658713, lng: 18.697149 },
    { lat: 49.2659206, lng: 18.6973986 },
    { lat: 49.2659345, lng: 18.6974695 },
    { lat: 49.2659476, lng: 18.6975473 },
    { lat: 49.2659477, lng: 18.6975505 },
    { lat: 49.2659619, lng: 18.6976272 },
    { lat: 49.265972, lng: 18.6976707 },
    { lat: 49.2661474, lng: 18.6976409 },
    { lat: 49.2662184, lng: 18.6976607 },
    { lat: 49.2662254, lng: 18.6976627 },
    { lat: 49.2665061, lng: 18.6976853 },
    { lat: 49.2666819, lng: 18.6976402 },
    { lat: 49.2667113, lng: 18.6977681 },
    { lat: 49.2667361, lng: 18.6978759 },
    { lat: 49.2667961, lng: 18.6981357 },
    { lat: 49.2668606, lng: 18.6984188 },
    { lat: 49.2669841, lng: 18.699011 },
    { lat: 49.2670822, lng: 18.6994832 },
    { lat: 49.2666276, lng: 18.699951 },
    { lat: 49.2669729, lng: 18.7010508 },
    { lat: 49.2671663, lng: 18.7015968 },
    { lat: 49.2673066, lng: 18.7019093 },
    { lat: 49.2676662, lng: 18.7035143 },
    { lat: 49.2678863, lng: 18.704849 },
    { lat: 49.2679565, lng: 18.7059749 },
    { lat: 49.2680021, lng: 18.7072076 },
    { lat: 49.2681619, lng: 18.7080181 },
    { lat: 49.2683119, lng: 18.7084284 },
    { lat: 49.2688372, lng: 18.7097571 },
    { lat: 49.2688393, lng: 18.7102476 },
    { lat: 49.2688469, lng: 18.7103052 },
    { lat: 49.2689866, lng: 18.7119148 },
    { lat: 49.2690405, lng: 18.713223 },
    { lat: 49.2693548, lng: 18.716035 },
    { lat: 49.2701468, lng: 18.7181257 },
    { lat: 49.2704766, lng: 18.722186 },
    { lat: 49.2707824, lng: 18.722773 },
    { lat: 49.2710523, lng: 18.7227154 },
    { lat: 49.2712274, lng: 18.7226561 },
    { lat: 49.2713504, lng: 18.7226147 },
    { lat: 49.2714036, lng: 18.7225967 },
    { lat: 49.2717117, lng: 18.7224925 },
    { lat: 49.272024, lng: 18.722423 },
    { lat: 49.2726, lng: 18.722314 },
    { lat: 49.272612, lng: 18.722459 },
    { lat: 49.272663, lng: 18.723023 },
    { lat: 49.272681, lng: 18.723862 },
    { lat: 49.273483, lng: 18.724114 },
    { lat: 49.273509, lng: 18.724122 },
    { lat: 49.273697, lng: 18.724181 },
    { lat: 49.273706, lng: 18.724184 },
    { lat: 49.273591, lng: 18.724639 },
    { lat: 49.274377, lng: 18.724498 },
    { lat: 49.274375, lng: 18.724486 },
    { lat: 49.274374, lng: 18.724461 },
    { lat: 49.274384, lng: 18.724436 },
    { lat: 49.27456, lng: 18.724353 },
    { lat: 49.274664, lng: 18.724237 },
    { lat: 49.27475, lng: 18.724254 },
    { lat: 49.274853, lng: 18.724151 },
    { lat: 49.274852, lng: 18.724079 },
    { lat: 49.274896, lng: 18.724015 },
    { lat: 49.274934, lng: 18.724008 },
    { lat: 49.275106, lng: 18.723996 },
    { lat: 49.275182, lng: 18.723926 },
    { lat: 49.275273, lng: 18.723866 },
    { lat: 49.275327, lng: 18.723842 },
    { lat: 49.275344, lng: 18.723864 },
    { lat: 49.275378, lng: 18.723844 },
    { lat: 49.275453, lng: 18.723818 },
    { lat: 49.275544, lng: 18.723809 },
    { lat: 49.275601, lng: 18.723857 },
    { lat: 49.275719, lng: 18.723963 },
    { lat: 49.275736, lng: 18.724015 },
    { lat: 49.27581, lng: 18.724062 },
    { lat: 49.275866, lng: 18.72403 },
    { lat: 49.275921, lng: 18.724089 },
    { lat: 49.276055, lng: 18.723957 },
    { lat: 49.276212, lng: 18.724032 },
    { lat: 49.276335, lng: 18.724147 },
    { lat: 49.276392, lng: 18.724156 },
    { lat: 49.276482, lng: 18.724243 },
    { lat: 49.2765, lng: 18.724245 },
    { lat: 49.276524, lng: 18.724256 },
    { lat: 49.27666, lng: 18.724505 },
    { lat: 49.276962, lng: 18.724569 },
    { lat: 49.27706, lng: 18.724524 },
    { lat: 49.277047, lng: 18.7245 },
    { lat: 49.277039, lng: 18.724479 },
    { lat: 49.277032, lng: 18.724464 },
    { lat: 49.276968, lng: 18.723952 },
    { lat: 49.277777, lng: 18.723441 },
    { lat: 49.277767, lng: 18.722525 },
    { lat: 49.277874, lng: 18.721928 },
    { lat: 49.278143, lng: 18.722101 },
    { lat: 49.278222, lng: 18.722151 },
    { lat: 49.278584, lng: 18.722385 },
    { lat: 49.278897, lng: 18.722284 },
    { lat: 49.279346, lng: 18.722346 },
    { lat: 49.279365, lng: 18.722393 },
    { lat: 49.279678, lng: 18.72261 },
    { lat: 49.279729, lng: 18.722536 },
    { lat: 49.279831, lng: 18.721979 },
    { lat: 49.279874, lng: 18.721883 },
    { lat: 49.279883, lng: 18.721291 },
    { lat: 49.280028, lng: 18.721107 },
    { lat: 49.280155, lng: 18.720713 },
    { lat: 49.280299, lng: 18.720891 },
    { lat: 49.280694, lng: 18.721581 },
    { lat: 49.280717, lng: 18.721633 },
    { lat: 49.280783, lng: 18.721748 },
    { lat: 49.280833, lng: 18.721792 },
    { lat: 49.281013, lng: 18.722008 },
    { lat: 49.281139, lng: 18.722189 },
    { lat: 49.281181, lng: 18.72231 },
    { lat: 49.28122, lng: 18.722379 },
    { lat: 49.281319, lng: 18.722615 },
    { lat: 49.281319, lng: 18.7229 },
    { lat: 49.281399, lng: 18.723121 },
    { lat: 49.281502, lng: 18.723249 },
    { lat: 49.281699, lng: 18.723384 },
    { lat: 49.281786, lng: 18.722802 },
    { lat: 49.281785, lng: 18.72212 },
    { lat: 49.282359, lng: 18.721911 },
    { lat: 49.282502, lng: 18.721637 },
    { lat: 49.28266, lng: 18.721252 },
    { lat: 49.28279, lng: 18.720921 },
    { lat: 49.282998, lng: 18.720639 },
    { lat: 49.282984, lng: 18.720309 },
    { lat: 49.28316, lng: 18.7198 },
    { lat: 49.283154, lng: 18.719458 },
    { lat: 49.283162, lng: 18.719409 },
    { lat: 49.283292, lng: 18.718624 },
    { lat: 49.283511, lng: 18.718468 },
    { lat: 49.283676, lng: 18.718374 },
    { lat: 49.283822, lng: 18.718276 },
    { lat: 49.284088, lng: 18.718567 },
    { lat: 49.284457, lng: 18.718453 },
    { lat: 49.284564, lng: 18.718396 },
    { lat: 49.284696, lng: 18.718399 },
    { lat: 49.284896, lng: 18.718517 },
    { lat: 49.284993, lng: 18.718394 },
    { lat: 49.285026, lng: 18.718474 },
    { lat: 49.285043, lng: 18.718502 },
    { lat: 49.285062, lng: 18.718482 },
    { lat: 49.285076, lng: 18.718442 },
    { lat: 49.28511, lng: 18.718533 },
    { lat: 49.285112, lng: 18.718571 },
    { lat: 49.28515, lng: 18.718623 },
    { lat: 49.285164, lng: 18.71861 },
    { lat: 49.285229, lng: 18.718606 },
    { lat: 49.285601, lng: 18.718948 },
    { lat: 49.285671, lng: 18.719106 },
    { lat: 49.285665, lng: 18.719351 },
    { lat: 49.285777, lng: 18.719434 },
    { lat: 49.285851, lng: 18.719519 },
    { lat: 49.286111, lng: 18.71949 },
    { lat: 49.28617, lng: 18.719403 },
    { lat: 49.286194, lng: 18.719397 },
    { lat: 49.286209, lng: 18.719421 },
    { lat: 49.286222, lng: 18.719489 },
    { lat: 49.286317, lng: 18.719526 },
    { lat: 49.286507, lng: 18.719718 },
    { lat: 49.286631, lng: 18.719912 },
    { lat: 49.286646, lng: 18.720026 },
    { lat: 49.286656, lng: 18.720009 },
    { lat: 49.28669, lng: 18.719941 },
    { lat: 49.286677, lng: 18.719571 },
    { lat: 49.286644, lng: 18.719452 },
    { lat: 49.286668, lng: 18.719125 },
    { lat: 49.286683, lng: 18.719111 },
    { lat: 49.28672, lng: 18.718721 },
    { lat: 49.286618, lng: 18.718651 },
    { lat: 49.286585, lng: 18.718428 },
    { lat: 49.286505, lng: 18.718071 },
    { lat: 49.286663, lng: 18.717541 },
    { lat: 49.286925, lng: 18.717138 },
    { lat: 49.287273, lng: 18.71767 },
    { lat: 49.287371, lng: 18.717768 },
    { lat: 49.287385, lng: 18.717563 },
    { lat: 49.287425, lng: 18.717246 },
    { lat: 49.287941, lng: 18.717072 },
    { lat: 49.287958, lng: 18.717041 },
    { lat: 49.287991, lng: 18.716981 },
    { lat: 49.288219, lng: 18.716577 },
    { lat: 49.288711, lng: 18.71653 },
    { lat: 49.289029, lng: 18.716965 },
    { lat: 49.289285, lng: 18.717044 },
    { lat: 49.289386, lng: 18.716796 },
    { lat: 49.289574, lng: 18.716687 },
    { lat: 49.289773, lng: 18.716471 },
    { lat: 49.290017, lng: 18.716147 },
    { lat: 49.290296, lng: 18.715696 },
    { lat: 49.290664, lng: 18.715326 },
    { lat: 49.290854, lng: 18.715124 },
    { lat: 49.291245, lng: 18.715687 },
    { lat: 49.291376, lng: 18.715851 },
    { lat: 49.291441, lng: 18.715893 },
    { lat: 49.291485, lng: 18.715915 },
    { lat: 49.291742, lng: 18.71598 },
    { lat: 49.291957, lng: 18.716043 },
    { lat: 49.292209, lng: 18.716082 },
    { lat: 49.292423, lng: 18.715976 },
    { lat: 49.292666, lng: 18.715473 },
    { lat: 49.292373, lng: 18.714796 },
    { lat: 49.292586, lng: 18.714636 },
    { lat: 49.292875, lng: 18.714392 },
    { lat: 49.293191, lng: 18.714175 },
    { lat: 49.293491, lng: 18.714028 },
    { lat: 49.29347, lng: 18.713799 },
    { lat: 49.293869, lng: 18.713591 },
    { lat: 49.293969, lng: 18.7135 },
    { lat: 49.293826, lng: 18.712989 },
    { lat: 49.294175, lng: 18.712849 },
    { lat: 49.294477, lng: 18.712818 },
    { lat: 49.294492, lng: 18.712816 },
    { lat: 49.294675, lng: 18.713049 },
    { lat: 49.294837, lng: 18.713459 },
    { lat: 49.294912, lng: 18.713606 },
    { lat: 49.294976, lng: 18.7137 },
    { lat: 49.295008, lng: 18.713773 },
    { lat: 49.295066, lng: 18.713906 },
    { lat: 49.295277, lng: 18.714147 },
    { lat: 49.295543, lng: 18.713894 },
    { lat: 49.29568, lng: 18.71312 },
    { lat: 49.295712, lng: 18.713006 },
    { lat: 49.29607, lng: 18.712583 },
    { lat: 49.296171, lng: 18.712557 },
    { lat: 49.2962, lng: 18.712356 },
    { lat: 49.296544, lng: 18.711423 },
    { lat: 49.296435, lng: 18.711136 },
    { lat: 49.296334, lng: 18.710513 },
    { lat: 49.296296, lng: 18.710427 },
    { lat: 49.296135, lng: 18.709606 },
    { lat: 49.296296, lng: 18.709627 },
    { lat: 49.296503, lng: 18.70968 },
    { lat: 49.297002, lng: 18.709674 },
    { lat: 49.297212, lng: 18.709552 },
    { lat: 49.297327, lng: 18.709669 },
    { lat: 49.297402, lng: 18.709815 },
    { lat: 49.2976, lng: 18.709913 },
    { lat: 49.298229, lng: 18.709706 },
    { lat: 49.298415, lng: 18.709835 },
    { lat: 49.298426, lng: 18.710047 },
    { lat: 49.298613, lng: 18.710528 },
    { lat: 49.298764, lng: 18.710757 },
    { lat: 49.299077, lng: 18.711409 },
    { lat: 49.299136, lng: 18.711698 },
    { lat: 49.299637, lng: 18.711039 },
    { lat: 49.299752, lng: 18.710671 },
    { lat: 49.299906, lng: 18.710412 },
    { lat: 49.299927, lng: 18.710229 },
    { lat: 49.299913, lng: 18.710148 },
    { lat: 49.299999, lng: 18.709798 },
    { lat: 49.300013, lng: 18.709619 },
    { lat: 49.300073, lng: 18.709533 },
    { lat: 49.30007, lng: 18.709402 },
    { lat: 49.300147, lng: 18.709304 },
    { lat: 49.300219, lng: 18.709156 },
    { lat: 49.30028, lng: 18.709107 },
    { lat: 49.300489, lng: 18.708861 },
    { lat: 49.300595, lng: 18.708826 },
    { lat: 49.300645, lng: 18.708782 },
    { lat: 49.300729, lng: 18.708709 },
    { lat: 49.300841, lng: 18.708588 },
    { lat: 49.300893, lng: 18.708348 },
    { lat: 49.301003, lng: 18.708171 },
    { lat: 49.301028, lng: 18.708118 },
    { lat: 49.30105, lng: 18.708062 },
    { lat: 49.301367, lng: 18.707415 },
    { lat: 49.301351, lng: 18.707303 },
    { lat: 49.301353, lng: 18.707152 },
    { lat: 49.301415, lng: 18.706647 },
    { lat: 49.301435, lng: 18.706578 },
    { lat: 49.301436, lng: 18.706533 },
    { lat: 49.301405, lng: 18.706458 },
    { lat: 49.301638, lng: 18.706107 },
    { lat: 49.301675, lng: 18.705832 },
    { lat: 49.301815, lng: 18.705765 },
    { lat: 49.302236, lng: 18.705626 },
    { lat: 49.302299, lng: 18.705641 },
    { lat: 49.30245, lng: 18.705745 },
    { lat: 49.302771, lng: 18.705698 },
    { lat: 49.30291, lng: 18.705467 },
    { lat: 49.303051, lng: 18.705461 },
    { lat: 49.303256, lng: 18.705501 },
    { lat: 49.304263, lng: 18.706278 },
    { lat: 49.304423, lng: 18.705889 },
    { lat: 49.304484, lng: 18.705661 },
    { lat: 49.304551, lng: 18.705339 },
    { lat: 49.304728, lng: 18.704804 },
    { lat: 49.304951, lng: 18.703986 },
    { lat: 49.305134, lng: 18.703255 },
    { lat: 49.305242, lng: 18.702405 },
    { lat: 49.305354, lng: 18.701807 },
    { lat: 49.305554, lng: 18.701627 },
    { lat: 49.305673, lng: 18.701618 },
    { lat: 49.306074, lng: 18.701227 },
    { lat: 49.306589, lng: 18.700917 },
    { lat: 49.306736, lng: 18.7009 },
    { lat: 49.306722, lng: 18.701404 },
    { lat: 49.306746, lng: 18.70186 },
    { lat: 49.306827, lng: 18.702595 },
    { lat: 49.306964, lng: 18.702889 },
    { lat: 49.307074, lng: 18.702664 },
    { lat: 49.307293, lng: 18.702881 },
    { lat: 49.307536, lng: 18.702877 },
    { lat: 49.307646, lng: 18.702727 },
    { lat: 49.307775, lng: 18.702639 },
    { lat: 49.307855, lng: 18.702468 },
    { lat: 49.307939, lng: 18.702311 },
    { lat: 49.308027, lng: 18.702117 },
    { lat: 49.30813, lng: 18.702023 },
    { lat: 49.308488, lng: 18.701763 },
    { lat: 49.3086, lng: 18.701761 },
    { lat: 49.308824, lng: 18.701367 },
    { lat: 49.308831, lng: 18.70111 },
    { lat: 49.30895, lng: 18.701084 },
    { lat: 49.309114, lng: 18.700998 },
    { lat: 49.30953, lng: 18.700679 },
    { lat: 49.310042, lng: 18.700497 },
    { lat: 49.310145, lng: 18.700432 },
    { lat: 49.311169, lng: 18.700026 },
    { lat: 49.311498, lng: 18.699901 },
    { lat: 49.311759, lng: 18.699716 },
    { lat: 49.311857, lng: 18.699661 },
    { lat: 49.312009, lng: 18.699527 },
    { lat: 49.312184, lng: 18.699417 },
    { lat: 49.312612, lng: 18.6991 },
    { lat: 49.312788, lng: 18.699001 },
    { lat: 49.313116, lng: 18.698916 },
    { lat: 49.313393, lng: 18.698921 },
    { lat: 49.313571, lng: 18.698913 },
    { lat: 49.314145, lng: 18.699085 },
    { lat: 49.314249, lng: 18.69909 },
    { lat: 49.314337, lng: 18.699084 },
    { lat: 49.314691, lng: 18.699034 },
    { lat: 49.315257, lng: 18.698883 },
    { lat: 49.315432, lng: 18.6989 },
    { lat: 49.315595, lng: 18.698853 },
    { lat: 49.315797, lng: 18.69877 },
    { lat: 49.315801, lng: 18.698727 },
    { lat: 49.315941, lng: 18.698762 },
    { lat: 49.316132, lng: 18.698693 },
    { lat: 49.31633, lng: 18.698686 },
    { lat: 49.316697, lng: 18.698515 },
    { lat: 49.317338, lng: 18.697928 },
    { lat: 49.318189, lng: 18.69722 },
    { lat: 49.318202, lng: 18.697252 },
    { lat: 49.318341, lng: 18.697148 },
    { lat: 49.318667, lng: 18.696948 },
    { lat: 49.318826, lng: 18.696909 },
    { lat: 49.319249, lng: 18.696598 },
    { lat: 49.319288, lng: 18.696569 },
    { lat: 49.319462, lng: 18.696618 },
    { lat: 49.319705, lng: 18.696515 },
    { lat: 49.32001, lng: 18.696536 },
    { lat: 49.320327, lng: 18.696888 },
    { lat: 49.320444, lng: 18.696955 },
    { lat: 49.320695, lng: 18.697027 },
    { lat: 49.320835, lng: 18.697165 },
    { lat: 49.32092, lng: 18.697397 },
    { lat: 49.32124, lng: 18.697372 },
    { lat: 49.321529, lng: 18.697104 },
    { lat: 49.321666, lng: 18.697043 },
    { lat: 49.321906, lng: 18.697064 },
    { lat: 49.322175, lng: 18.697111 },
    { lat: 49.322312, lng: 18.697185 },
    { lat: 49.322454, lng: 18.697341 },
    { lat: 49.322489, lng: 18.697431 },
    { lat: 49.322773, lng: 18.697603 },
    { lat: 49.322901, lng: 18.697411 },
    { lat: 49.323071, lng: 18.697344 },
    { lat: 49.323252, lng: 18.69712 },
    { lat: 49.323336, lng: 18.697029 },
    { lat: 49.3234, lng: 18.696871 },
    { lat: 49.323457, lng: 18.696962 },
    { lat: 49.323553, lng: 18.696989 },
    { lat: 49.323754, lng: 18.696738 },
    { lat: 49.323917, lng: 18.696362 },
    { lat: 49.323975, lng: 18.696178 },
    { lat: 49.324084, lng: 18.696107 },
    { lat: 49.324809, lng: 18.695644 },
    { lat: 49.32479, lng: 18.695078 },
    { lat: 49.324972, lng: 18.694606 },
    { lat: 49.32507, lng: 18.694257 },
    { lat: 49.32523, lng: 18.693331 },
    { lat: 49.325213, lng: 18.693306 },
    { lat: 49.325431, lng: 18.692981 },
    { lat: 49.325919, lng: 18.692128 },
    { lat: 49.326127, lng: 18.691866 },
    { lat: 49.326389, lng: 18.691668 },
    { lat: 49.326469, lng: 18.691498 },
    { lat: 49.326604, lng: 18.691441 },
    { lat: 49.326853, lng: 18.691207 },
    { lat: 49.327054, lng: 18.690962 },
    { lat: 49.327215, lng: 18.690851 },
    { lat: 49.327488, lng: 18.690834 },
    { lat: 49.327573, lng: 18.690999 },
    { lat: 49.327673, lng: 18.691085 },
    { lat: 49.327826, lng: 18.691099 },
    { lat: 49.328185, lng: 18.691296 },
    { lat: 49.328616, lng: 18.691281 },
    { lat: 49.328617, lng: 18.691301 },
    { lat: 49.328889, lng: 18.69119 },
    { lat: 49.329047, lng: 18.690966 },
    { lat: 49.329058, lng: 18.690969 },
    { lat: 49.32927, lng: 18.691581 },
    { lat: 49.329884, lng: 18.690691 },
    { lat: 49.330581, lng: 18.690531 },
    { lat: 49.330841, lng: 18.690605 },
    { lat: 49.331084, lng: 18.69052 },
    { lat: 49.331193, lng: 18.690532 },
    { lat: 49.331636, lng: 18.690393 },
    { lat: 49.331864, lng: 18.690349 },
    { lat: 49.332061, lng: 18.690413 },
    { lat: 49.332301, lng: 18.690598 },
    { lat: 49.332575, lng: 18.689891 },
    { lat: 49.332587, lng: 18.689765 },
    { lat: 49.332706, lng: 18.689585 },
    { lat: 49.332708, lng: 18.689419 },
    { lat: 49.332842, lng: 18.689196 },
    { lat: 49.332934, lng: 18.688984 },
    { lat: 49.333256, lng: 18.688935 },
    { lat: 49.333306, lng: 18.688901 },
    { lat: 49.333361, lng: 18.688709 },
    { lat: 49.333463, lng: 18.687462 },
    { lat: 49.333646, lng: 18.686843 },
    { lat: 49.333547, lng: 18.68673 },
    { lat: 49.333406, lng: 18.684215 },
    { lat: 49.333495, lng: 18.683807 },
    { lat: 49.333731, lng: 18.6832 },
    { lat: 49.333891, lng: 18.683162 },
    { lat: 49.33418, lng: 18.68232 },
    { lat: 49.334237, lng: 18.68254 },
    { lat: 49.334338, lng: 18.68267 },
    { lat: 49.334545, lng: 18.682531 },
    { lat: 49.335036, lng: 18.681891 },
    { lat: 49.335671, lng: 18.681172 },
    { lat: 49.336164, lng: 18.681012 },
    { lat: 49.336411, lng: 18.680402 },
    { lat: 49.338034, lng: 18.680404 },
    { lat: 49.339047, lng: 18.679463 },
    { lat: 49.339976, lng: 18.678591 },
    { lat: 49.340186, lng: 18.67847 },
  ],
  Divinka: [
    { lat: 49.2636015, lng: 18.6895416 },
    { lat: 49.2631155, lng: 18.6885156 },
    { lat: 49.2624655, lng: 18.6886561 },
    { lat: 49.2616858, lng: 18.6877912 },
    { lat: 49.2613931, lng: 18.6874791 },
    { lat: 49.2610691, lng: 18.6874525 },
    { lat: 49.2608926, lng: 18.6874381 },
    { lat: 49.260888, lng: 18.6874372 },
    { lat: 49.2608677, lng: 18.6874361 },
    { lat: 49.2607775, lng: 18.6874154 },
    { lat: 49.2607273, lng: 18.6874043 },
    { lat: 49.2605503, lng: 18.6873643 },
    { lat: 49.2598652, lng: 18.6873095 },
    { lat: 49.2591894, lng: 18.6863416 },
    { lat: 49.2591432, lng: 18.6862751 },
    { lat: 49.259042, lng: 18.6861305 },
    { lat: 49.2590099, lng: 18.6860774 },
    { lat: 49.2590047, lng: 18.686069 },
    { lat: 49.2589516, lng: 18.6859809 },
    { lat: 49.2589393, lng: 18.6859583 },
    { lat: 49.2589353, lng: 18.6859501 },
    { lat: 49.2586172, lng: 18.685178 },
    { lat: 49.257904, lng: 18.6851855 },
    { lat: 49.257476, lng: 18.6848345 },
    { lat: 49.2567606, lng: 18.6843532 },
    { lat: 49.2567101, lng: 18.6843179 },
    { lat: 49.2555246, lng: 18.6865561 },
    { lat: 49.2549442, lng: 18.6877632 },
    { lat: 49.2548471, lng: 18.6880178 },
    { lat: 49.2547479, lng: 18.6884238 },
    { lat: 49.2546758, lng: 18.689068 },
    { lat: 49.2541447, lng: 18.6882774 },
    { lat: 49.2533522, lng: 18.6869539 },
    { lat: 49.2522304, lng: 18.685715 },
    { lat: 49.2517913, lng: 18.684938 },
    { lat: 49.2501308, lng: 18.6867463 },
    { lat: 49.2496965, lng: 18.6874737 },
    { lat: 49.2495686, lng: 18.6888769 },
    { lat: 49.2482078, lng: 18.6920098 },
    { lat: 49.2485249, lng: 18.692417 },
    { lat: 49.249005, lng: 18.6929507 },
    { lat: 49.2497072, lng: 18.6936727 },
    { lat: 49.2501706, lng: 18.694184 },
    { lat: 49.2500746, lng: 18.6946318 },
    { lat: 49.2499999, lng: 18.6950359 },
    { lat: 49.2499378, lng: 18.6953334 },
    { lat: 49.2498922, lng: 18.6955393 },
    { lat: 49.2498243, lng: 18.6958527 },
    { lat: 49.2497608, lng: 18.6961456 },
    { lat: 49.2496932, lng: 18.696429 },
    { lat: 49.2495066, lng: 18.6970417 },
    { lat: 49.2494473, lng: 18.6972839 },
    { lat: 49.249378, lng: 18.6975123 },
    { lat: 49.2493235, lng: 18.6976742 },
    { lat: 49.2492286, lng: 18.6979057 },
    { lat: 49.2490737, lng: 18.6982001 },
    { lat: 49.2488524, lng: 18.6986023 },
    { lat: 49.2486344, lng: 18.6989817 },
    { lat: 49.2483914, lng: 18.6993345 },
    { lat: 49.2479043, lng: 18.6999808 },
    { lat: 49.2470568, lng: 18.7007381 },
    { lat: 49.2471985, lng: 18.7013123 },
    { lat: 49.2474243, lng: 18.70149 },
    { lat: 49.247685, lng: 18.7016843 },
    { lat: 49.2479516, lng: 18.7019271 },
    { lat: 49.2481219, lng: 18.7027447 },
    { lat: 49.2481623, lng: 18.7028924 },
    { lat: 49.2484776, lng: 18.7037853 },
    { lat: 49.2484671, lng: 18.7038 },
    { lat: 49.2487354, lng: 18.7045269 },
    { lat: 49.2487871, lng: 18.7046343 },
    { lat: 49.2488604, lng: 18.7047823 },
    { lat: 49.2489513, lng: 18.7049444 },
    { lat: 49.2490206, lng: 18.7050385 },
    { lat: 49.2490862, lng: 18.7051329 },
    { lat: 49.2491396, lng: 18.7052267 },
    { lat: 49.249182, lng: 18.7053009 },
    { lat: 49.2493446, lng: 18.7054994 },
    { lat: 49.2494194, lng: 18.7055913 },
    { lat: 49.2495627, lng: 18.7058311 },
    { lat: 49.2500221, lng: 18.7065252 },
    { lat: 49.250257, lng: 18.7068549 },
    { lat: 49.2504984, lng: 18.7071996 },
    { lat: 49.2507149, lng: 18.7073294 },
    { lat: 49.2507267, lng: 18.7104316 },
    { lat: 49.2506564, lng: 18.7107889 },
    { lat: 49.2505756, lng: 18.7113121 },
    { lat: 49.2504433, lng: 18.7120155 },
    { lat: 49.2507504, lng: 18.7126656 },
    { lat: 49.251177, lng: 18.7133172 },
    { lat: 49.2516495, lng: 18.7141351 },
    { lat: 49.2519375, lng: 18.7146164 },
    { lat: 49.2524042, lng: 18.7155632 },
    { lat: 49.2526754, lng: 18.7159585 },
    { lat: 49.2529122, lng: 18.7163227 },
    { lat: 49.2531343, lng: 18.7166882 },
    { lat: 49.2536961, lng: 18.7172942 },
    { lat: 49.2541658, lng: 18.7180605 },
    { lat: 49.2544677, lng: 18.7185907 },
    { lat: 49.2546406, lng: 18.718945 },
    { lat: 49.2547017, lng: 18.719183 },
    { lat: 49.2547455, lng: 18.719418 },
    { lat: 49.2548439, lng: 18.7199396 },
    { lat: 49.2548547, lng: 18.7199967 },
    { lat: 49.2548588, lng: 18.720007 },
    { lat: 49.2549461, lng: 18.7202346 },
    { lat: 49.2549711, lng: 18.7203005 },
    { lat: 49.2551316, lng: 18.7207173 },
    { lat: 49.2553861, lng: 18.7212259 },
    { lat: 49.2558072, lng: 18.7219257 },
    { lat: 49.2564017, lng: 18.7229187 },
    { lat: 49.2568105, lng: 18.7234283 },
    { lat: 49.257192, lng: 18.7242972 },
    { lat: 49.2578013, lng: 18.7248834 },
    { lat: 49.2591817, lng: 18.7265022 },
    { lat: 49.2592093, lng: 18.7265165 },
    { lat: 49.2592234, lng: 18.726523 },
    { lat: 49.2592351, lng: 18.7265115 },
    { lat: 49.2592479, lng: 18.7264991 },
    { lat: 49.2603548, lng: 18.7255624 },
    { lat: 49.260888, lng: 18.7249994 },
    { lat: 49.2613109, lng: 18.7246102 },
    { lat: 49.261656, lng: 18.7243102 },
    { lat: 49.2619284, lng: 18.7241747 },
    { lat: 49.2621324, lng: 18.72413 },
    { lat: 49.2623391, lng: 18.7241794 },
    { lat: 49.2629138, lng: 18.7244287 },
    { lat: 49.2633348, lng: 18.7246521 },
    { lat: 49.264578, lng: 18.7252894 },
    { lat: 49.2645734, lng: 18.7253055 },
    { lat: 49.2646317, lng: 18.725324 },
    { lat: 49.264884, lng: 18.7254033 },
    { lat: 49.2656995, lng: 18.7256896 },
    { lat: 49.2661544, lng: 18.7256131 },
    { lat: 49.2664307, lng: 18.7261055 },
    { lat: 49.2668621, lng: 18.7259253 },
    { lat: 49.2671933, lng: 18.7258559 },
    { lat: 49.2672324, lng: 18.7258476 },
    { lat: 49.2672681, lng: 18.7258403 },
    { lat: 49.2672814, lng: 18.7258372 },
    { lat: 49.2673143, lng: 18.7258191 },
    { lat: 49.2673549, lng: 18.7257968 },
    { lat: 49.2687385, lng: 18.7250432 },
    { lat: 49.2690821, lng: 18.7249087 },
    { lat: 49.2694132, lng: 18.7247253 },
    { lat: 49.2705223, lng: 18.7236855 },
    { lat: 49.270483, lng: 18.7228677 },
    { lat: 49.2707824, lng: 18.722773 },
    { lat: 49.2704766, lng: 18.722186 },
    { lat: 49.2701468, lng: 18.7181257 },
    { lat: 49.2693548, lng: 18.716035 },
    { lat: 49.2690405, lng: 18.713223 },
    { lat: 49.2689866, lng: 18.7119148 },
    { lat: 49.2688469, lng: 18.7103052 },
    { lat: 49.2688393, lng: 18.7102476 },
    { lat: 49.2688372, lng: 18.7097571 },
    { lat: 49.2683119, lng: 18.7084284 },
    { lat: 49.2681619, lng: 18.7080181 },
    { lat: 49.2680021, lng: 18.7072076 },
    { lat: 49.2679565, lng: 18.7059749 },
    { lat: 49.2678863, lng: 18.704849 },
    { lat: 49.2676662, lng: 18.7035143 },
    { lat: 49.2673066, lng: 18.7019093 },
    { lat: 49.2671663, lng: 18.7015968 },
    { lat: 49.2669729, lng: 18.7010508 },
    { lat: 49.2666276, lng: 18.699951 },
    { lat: 49.2670822, lng: 18.6994832 },
    { lat: 49.2669841, lng: 18.699011 },
    { lat: 49.2668606, lng: 18.6984188 },
    { lat: 49.2667961, lng: 18.6981357 },
    { lat: 49.2667361, lng: 18.6978759 },
    { lat: 49.2667113, lng: 18.6977681 },
    { lat: 49.2666819, lng: 18.6976402 },
    { lat: 49.2665061, lng: 18.6976853 },
    { lat: 49.2662254, lng: 18.6976627 },
    { lat: 49.2662184, lng: 18.6976607 },
    { lat: 49.2661474, lng: 18.6976409 },
    { lat: 49.265972, lng: 18.6976707 },
    { lat: 49.2659619, lng: 18.6976272 },
    { lat: 49.2659477, lng: 18.6975505 },
    { lat: 49.2659476, lng: 18.6975473 },
    { lat: 49.2659345, lng: 18.6974695 },
    { lat: 49.2659206, lng: 18.6973986 },
    { lat: 49.2658713, lng: 18.697149 },
    { lat: 49.2658638, lng: 18.6971127 },
    { lat: 49.2658537, lng: 18.696962 },
    { lat: 49.2658249, lng: 18.6968857 },
    { lat: 49.2657623, lng: 18.6967295 },
    { lat: 49.2657627, lng: 18.6966915 },
    { lat: 49.2657642, lng: 18.6965902 },
    { lat: 49.2657647, lng: 18.6965584 },
    { lat: 49.2653719, lng: 18.6967871 },
    { lat: 49.2652202, lng: 18.6968401 },
    { lat: 49.2650574, lng: 18.696833 },
    { lat: 49.2650451, lng: 18.6967294 },
    { lat: 49.2650316, lng: 18.6967061 },
    { lat: 49.2651286, lng: 18.6966856 },
    { lat: 49.2651677, lng: 18.6966775 },
    { lat: 49.265203, lng: 18.696662 },
    { lat: 49.2654622, lng: 18.6964861 },
    { lat: 49.2657088, lng: 18.696332 },
    { lat: 49.265701, lng: 18.6962921 },
    { lat: 49.2656765, lng: 18.6961666 },
    { lat: 49.2656586, lng: 18.6960648 },
    { lat: 49.2656527, lng: 18.696037 },
    { lat: 49.2656462, lng: 18.6960006 },
    { lat: 49.2656329, lng: 18.6958943 },
    { lat: 49.2656178, lng: 18.6958232 },
    { lat: 49.26558, lng: 18.6956162 },
    { lat: 49.2654267, lng: 18.6955776 },
    { lat: 49.2654303, lng: 18.6955151 },
    { lat: 49.2654325, lng: 18.6954495 },
    { lat: 49.2654398, lng: 18.6952926 },
    { lat: 49.2654462, lng: 18.6951524 },
    { lat: 49.265399, lng: 18.6950727 },
    { lat: 49.2654925, lng: 18.6950269 },
    { lat: 49.2654869, lng: 18.6949828 },
    { lat: 49.2650616, lng: 18.6943374 },
    { lat: 49.2647972, lng: 18.6938792 },
    { lat: 49.2645513, lng: 18.6931045 },
    { lat: 49.2643155, lng: 18.6923738 },
    { lat: 49.2641093, lng: 18.6917362 },
    { lat: 49.2639754, lng: 18.691163 },
    { lat: 49.263789, lng: 18.6904288 },
    { lat: 49.2636816, lng: 18.6897798 },
    { lat: 49.2636015, lng: 18.6895416 },
  ],
  DlhéPole: [
    { lat: 49.351958, lng: 18.60368 },
    { lat: 49.351934, lng: 18.603411 },
    { lat: 49.351869, lng: 18.60313 },
    { lat: 49.351768, lng: 18.602889 },
    { lat: 49.35154, lng: 18.602606 },
    { lat: 49.351274, lng: 18.602318 },
    { lat: 49.351058, lng: 18.602037 },
    { lat: 49.35086, lng: 18.601738 },
    { lat: 49.350741, lng: 18.601572 },
    { lat: 49.350577, lng: 18.601429 },
    { lat: 49.350237, lng: 18.601168 },
    { lat: 49.349869, lng: 18.600791 },
    { lat: 49.349696, lng: 18.600547 },
    { lat: 49.349517, lng: 18.600332 },
    { lat: 49.349441, lng: 18.600099 },
    { lat: 49.349348, lng: 18.599749 },
    { lat: 49.349299, lng: 18.599526 },
    { lat: 49.349271, lng: 18.599374 },
    { lat: 49.349213, lng: 18.599215 },
    { lat: 49.349123, lng: 18.599013 },
    { lat: 49.349064, lng: 18.598794 },
    { lat: 49.348978, lng: 18.598536 },
    { lat: 49.348881, lng: 18.598415 },
    { lat: 49.348781, lng: 18.598251 },
    { lat: 49.348727, lng: 18.598054 },
    { lat: 49.348646, lng: 18.597563 },
    { lat: 49.348597, lng: 18.597391 },
    { lat: 49.348391, lng: 18.596847 },
    { lat: 49.348339, lng: 18.596563 },
    { lat: 49.348209, lng: 18.595986 },
    { lat: 49.348202, lng: 18.595891 },
    { lat: 49.348161, lng: 18.595717 },
    { lat: 49.3481, lng: 18.595351 },
    { lat: 49.348087, lng: 18.595201 },
    { lat: 49.348065, lng: 18.594881 },
    { lat: 49.347991, lng: 18.594391 },
    { lat: 49.347927, lng: 18.59409 },
    { lat: 49.34791, lng: 18.593764 },
    { lat: 49.34777, lng: 18.593409 },
    { lat: 49.347718, lng: 18.593226 },
    { lat: 49.347611, lng: 18.592937 },
    { lat: 49.347447, lng: 18.592579 },
    { lat: 49.347413, lng: 18.592328 },
    { lat: 49.347384, lng: 18.592006 },
    { lat: 49.347256, lng: 18.59161 },
    { lat: 49.347269, lng: 18.591599 },
    { lat: 49.347159, lng: 18.591118 },
    { lat: 49.347038, lng: 18.590523 },
    { lat: 49.346804, lng: 18.589999 },
    { lat: 49.346354, lng: 18.589267 },
    { lat: 49.346064, lng: 18.588513 },
    { lat: 49.346018, lng: 18.588391 },
    { lat: 49.34571, lng: 18.587983 },
    { lat: 49.345527, lng: 18.587904 },
    { lat: 49.345266, lng: 18.588212 },
    { lat: 49.344991, lng: 18.588746 },
    { lat: 49.344987, lng: 18.588758 },
    { lat: 49.344569, lng: 18.588461 },
    { lat: 49.34439, lng: 18.588433 },
    { lat: 49.343283, lng: 18.589093 },
    { lat: 49.342594, lng: 18.589527 },
    { lat: 49.342258, lng: 18.58968 },
    { lat: 49.341786, lng: 18.589907 },
    { lat: 49.34163, lng: 18.59005 },
    { lat: 49.341491, lng: 18.590248 },
    { lat: 49.341408, lng: 18.59049 },
    { lat: 49.341319, lng: 18.590629 },
    { lat: 49.341171, lng: 18.590802 },
    { lat: 49.340792, lng: 18.591372 },
    { lat: 49.340554, lng: 18.591541 },
    { lat: 49.340349, lng: 18.591738 },
    { lat: 49.339953, lng: 18.592015 },
    { lat: 49.339798, lng: 18.592075 },
    { lat: 49.339198, lng: 18.592068 },
    { lat: 49.338894, lng: 18.592076 },
    { lat: 49.338687, lng: 18.592046 },
    { lat: 49.33849, lng: 18.591991 },
    { lat: 49.33828, lng: 18.591861 },
    { lat: 49.338057, lng: 18.59169 },
    { lat: 49.33759, lng: 18.591335 },
    { lat: 49.337006, lng: 18.590658 },
    { lat: 49.336534, lng: 18.590163 },
    { lat: 49.336175, lng: 18.5895 },
    { lat: 49.335951, lng: 18.589193 },
    { lat: 49.335748, lng: 18.589006 },
    { lat: 49.335755, lng: 18.58898 },
    { lat: 49.335362, lng: 18.588382 },
    { lat: 49.335273, lng: 18.588405 },
    { lat: 49.334821, lng: 18.58852 },
    { lat: 49.334641, lng: 18.58857 },
    { lat: 49.334032, lng: 18.58874 },
    { lat: 49.333463, lng: 18.588873 },
    { lat: 49.333013, lng: 18.588901 },
    { lat: 49.332781, lng: 18.588955 },
    { lat: 49.332626, lng: 18.588991 },
    { lat: 49.332291, lng: 18.58908 },
    { lat: 49.332027, lng: 18.58915 },
    { lat: 49.331994, lng: 18.589159 },
    { lat: 49.331575, lng: 18.58916 },
    { lat: 49.331037, lng: 18.589192 },
    { lat: 49.330933, lng: 18.589222 },
    { lat: 49.330766, lng: 18.58927 },
    { lat: 49.330519, lng: 18.589945 },
    { lat: 49.330226, lng: 18.590737 },
    { lat: 49.330024, lng: 18.591818 },
    { lat: 49.329659, lng: 18.59332 },
    { lat: 49.329385, lng: 18.594168 },
    { lat: 49.329241, lng: 18.594613 },
    { lat: 49.328975, lng: 18.595448 },
    { lat: 49.328929, lng: 18.595593 },
    { lat: 49.328916, lng: 18.595576 },
    { lat: 49.328729, lng: 18.595781 },
    { lat: 49.328627, lng: 18.595921 },
    { lat: 49.32834, lng: 18.596218 },
    { lat: 49.328031, lng: 18.596392 },
    { lat: 49.32788, lng: 18.596457 },
    { lat: 49.327574, lng: 18.596461 },
    { lat: 49.327301, lng: 18.596479 },
    { lat: 49.326948, lng: 18.596641 },
    { lat: 49.326529, lng: 18.597004 },
    { lat: 49.325906, lng: 18.597234 },
    { lat: 49.325241, lng: 18.597447 },
    { lat: 49.324914, lng: 18.597439 },
    { lat: 49.324536, lng: 18.597819 },
    { lat: 49.324225, lng: 18.598025 },
    { lat: 49.324223, lng: 18.598005 },
    { lat: 49.323413, lng: 18.59821 },
    { lat: 49.323231, lng: 18.598211 },
    { lat: 49.322941, lng: 18.598211 },
    { lat: 49.322671, lng: 18.598365 },
    { lat: 49.322364, lng: 18.598596 },
    { lat: 49.321903, lng: 18.599172 },
    { lat: 49.321537, lng: 18.599644 },
    { lat: 49.321173, lng: 18.600141 },
    { lat: 49.320923, lng: 18.600209 },
    { lat: 49.320444, lng: 18.600132 },
    { lat: 49.320329, lng: 18.600154 },
    { lat: 49.320165, lng: 18.600185 },
    { lat: 49.320009, lng: 18.600161 },
    { lat: 49.319805, lng: 18.600052 },
    { lat: 49.319537, lng: 18.59998 },
    { lat: 49.319297, lng: 18.599936 },
    { lat: 49.319086, lng: 18.599949 },
    { lat: 49.31856, lng: 18.599876 },
    { lat: 49.318015, lng: 18.599962 },
    { lat: 49.317901, lng: 18.59991 },
    { lat: 49.317776, lng: 18.599753 },
    { lat: 49.317552, lng: 18.599487 },
    { lat: 49.317118, lng: 18.599319 },
    { lat: 49.316932, lng: 18.599238 },
    { lat: 49.316683, lng: 18.59921 },
    { lat: 49.316244, lng: 18.598907 },
    { lat: 49.316224, lng: 18.598894 },
    { lat: 49.315574, lng: 18.598502 },
    { lat: 49.315353, lng: 18.598281 },
    { lat: 49.315247, lng: 18.598175 },
    { lat: 49.315062, lng: 18.597717 },
    { lat: 49.314685, lng: 18.597183 },
    { lat: 49.314526, lng: 18.597044 },
    { lat: 49.314262, lng: 18.596814 },
    { lat: 49.31386, lng: 18.597204 },
    { lat: 49.313472, lng: 18.597648 },
    { lat: 49.313191, lng: 18.598032 },
    { lat: 49.312836, lng: 18.598587 },
    { lat: 49.312484, lng: 18.598895 },
    { lat: 49.312245, lng: 18.599114 },
    { lat: 49.312035, lng: 18.599143 },
    { lat: 49.311639, lng: 18.599122 },
    { lat: 49.311312, lng: 18.599181 },
    { lat: 49.311074, lng: 18.599274 },
    { lat: 49.310828, lng: 18.599474 },
    { lat: 49.310572, lng: 18.599597 },
    { lat: 49.310419, lng: 18.599818 },
    { lat: 49.309991, lng: 18.600019 },
    { lat: 49.309531, lng: 18.599982 },
    { lat: 49.309263, lng: 18.599912 },
    { lat: 49.308742, lng: 18.599927 },
    { lat: 49.308541, lng: 18.599943 },
    { lat: 49.308158, lng: 18.599917 },
    { lat: 49.307691, lng: 18.6 },
    { lat: 49.30735, lng: 18.600055 },
    { lat: 49.306928, lng: 18.600101 },
    { lat: 49.306471, lng: 18.600034 },
    { lat: 49.306069, lng: 18.600196 },
    { lat: 49.305641, lng: 18.600267 },
    { lat: 49.305252, lng: 18.600284 },
    { lat: 49.305104, lng: 18.600407 },
    { lat: 49.304932, lng: 18.600758 },
    { lat: 49.304423, lng: 18.601313 },
    { lat: 49.304281, lng: 18.60137 },
    { lat: 49.304101, lng: 18.601442 },
    { lat: 49.303267, lng: 18.601731 },
    { lat: 49.302337, lng: 18.602075 },
    { lat: 49.30184, lng: 18.602312 },
    { lat: 49.301381, lng: 18.60257 },
    { lat: 49.300842, lng: 18.602778 },
    { lat: 49.30032, lng: 18.602952 },
    { lat: 49.300144, lng: 18.60304 },
    { lat: 49.300101, lng: 18.603061 },
    { lat: 49.299931, lng: 18.603146 },
    { lat: 49.299239, lng: 18.603659 },
    { lat: 49.299143, lng: 18.603723 },
    { lat: 49.298711, lng: 18.604013 },
    { lat: 49.298253, lng: 18.604236 },
    { lat: 49.298229, lng: 18.604248 },
    { lat: 49.298127, lng: 18.60423 },
    { lat: 49.29738, lng: 18.604636 },
    { lat: 49.296611, lng: 18.605159 },
    { lat: 49.296478, lng: 18.605235 },
    { lat: 49.296396, lng: 18.605282 },
    { lat: 49.295827, lng: 18.605234 },
    { lat: 49.295028, lng: 18.605315 },
    { lat: 49.294613, lng: 18.605368 },
    { lat: 49.294489, lng: 18.605392 },
    { lat: 49.29392, lng: 18.605503 },
    { lat: 49.293279, lng: 18.605532 },
    { lat: 49.293157, lng: 18.605692 },
    { lat: 49.292815, lng: 18.60571 },
    { lat: 49.292494, lng: 18.605824 },
    { lat: 49.292154, lng: 18.606068 },
    { lat: 49.292155, lng: 18.605999 },
    { lat: 49.292157, lng: 18.605818 },
    { lat: 49.291735, lng: 18.605749 },
    { lat: 49.291327, lng: 18.605838 },
    { lat: 49.29087, lng: 18.606031 },
    { lat: 49.290577, lng: 18.60623 },
    { lat: 49.289991, lng: 18.606313 },
    { lat: 49.289009, lng: 18.606605 },
    { lat: 49.288557, lng: 18.606669 },
    { lat: 49.288382, lng: 18.606763 },
    { lat: 49.288321, lng: 18.607033 },
    { lat: 49.288065, lng: 18.607295 },
    { lat: 49.287684, lng: 18.60748 },
    { lat: 49.286952, lng: 18.607025 },
    { lat: 49.28626, lng: 18.606477 },
    { lat: 49.28521, lng: 18.606137 },
    { lat: 49.284998, lng: 18.606133 },
    { lat: 49.284796, lng: 18.606208 },
    { lat: 49.284511, lng: 18.606127 },
    { lat: 49.284366, lng: 18.606107 },
    { lat: 49.284032, lng: 18.606239 },
    { lat: 49.282904, lng: 18.606925 },
    { lat: 49.282225, lng: 18.607458 },
    { lat: 49.28185, lng: 18.607669 },
    { lat: 49.281482, lng: 18.60775 },
    { lat: 49.281046, lng: 18.608041 },
    { lat: 49.280771, lng: 18.608168 },
    { lat: 49.280658, lng: 18.608217 },
    { lat: 49.280144, lng: 18.608607 },
    { lat: 49.279569, lng: 18.608834 },
    { lat: 49.279432, lng: 18.608965 },
    { lat: 49.279442, lng: 18.609495 },
    { lat: 49.279128, lng: 18.610108 },
    { lat: 49.278784, lng: 18.611061 },
    { lat: 49.278578, lng: 18.611565 },
    { lat: 49.278183, lng: 18.611682 },
    { lat: 49.27771, lng: 18.612035 },
    { lat: 49.27743, lng: 18.612725 },
    { lat: 49.277218, lng: 18.613284 },
    { lat: 49.276934, lng: 18.613771 },
    { lat: 49.276598, lng: 18.61413 },
    { lat: 49.276259, lng: 18.614608 },
    { lat: 49.276144, lng: 18.614828 },
    { lat: 49.2772607, lng: 18.6171007 },
    { lat: 49.2775248, lng: 18.6175742 },
    { lat: 49.2779984, lng: 18.6186693 },
    { lat: 49.2783928, lng: 18.6200019 },
    { lat: 49.2785641, lng: 18.6210577 },
    { lat: 49.2788987, lng: 18.6217717 },
    { lat: 49.2794323, lng: 18.6225394 },
    { lat: 49.2797626, lng: 18.6232136 },
    { lat: 49.2799817, lng: 18.6236639 },
    { lat: 49.2802517, lng: 18.6244391 },
    { lat: 49.2805493, lng: 18.6252622 },
    { lat: 49.2807919, lng: 18.6257926 },
    { lat: 49.2808692, lng: 18.6260601 },
    { lat: 49.2810734, lng: 18.6271628 },
    { lat: 49.2810943, lng: 18.6272176 },
    { lat: 49.2811459, lng: 18.6273391 },
    { lat: 49.2812558, lng: 18.6283484 },
    { lat: 49.281289, lng: 18.6295248 },
    { lat: 49.2812833, lng: 18.6302855 },
    { lat: 49.2813544, lng: 18.6312415 },
    { lat: 49.2813693, lng: 18.6314361 },
    { lat: 49.2813757, lng: 18.6316008 },
    { lat: 49.2813957, lng: 18.6320397 },
    { lat: 49.2814208, lng: 18.63262 },
    { lat: 49.2814465, lng: 18.6332092 },
    { lat: 49.2814468, lng: 18.6332561 },
    { lat: 49.2814456, lng: 18.6333401 },
    { lat: 49.2814451, lng: 18.6333744 },
    { lat: 49.2814452, lng: 18.6334398 },
    { lat: 49.2814455, lng: 18.6335073 },
    { lat: 49.2820679, lng: 18.6335063 },
    { lat: 49.2820757, lng: 18.6335068 },
    { lat: 49.2826485, lng: 18.6335054 },
    { lat: 49.2834447, lng: 18.6334391 },
    { lat: 49.2838109, lng: 18.6333272 },
    { lat: 49.2838174, lng: 18.6333253 },
    { lat: 49.2841869, lng: 18.6332119 },
    { lat: 49.2848091, lng: 18.6331853 },
    { lat: 49.2848089, lng: 18.6332427 },
    { lat: 49.2848087, lng: 18.6333237 },
    { lat: 49.2847236, lng: 18.6337591 },
    { lat: 49.2847264, lng: 18.6345329 },
    { lat: 49.284854, lng: 18.63541 },
    { lat: 49.2849314, lng: 18.636378 },
    { lat: 49.285063, lng: 18.6373699 },
    { lat: 49.2851655, lng: 18.6381541 },
    { lat: 49.2852349, lng: 18.6388864 },
    { lat: 49.2856731, lng: 18.6406867 },
    { lat: 49.2858892, lng: 18.6419543 },
    { lat: 49.2861299, lng: 18.6437712 },
    { lat: 49.2861931, lng: 18.6444515 },
    { lat: 49.2861737, lng: 18.6451067 },
    { lat: 49.2860422, lng: 18.6459463 },
    { lat: 49.2858899, lng: 18.6464131 },
    { lat: 49.2857967, lng: 18.6474856 },
    { lat: 49.2858283, lng: 18.6480601 },
    { lat: 49.2858805, lng: 18.6484914 },
    { lat: 49.2862503, lng: 18.6489156 },
    { lat: 49.2865275, lng: 18.6495702 },
    { lat: 49.2865042, lng: 18.649662 },
    { lat: 49.287508, lng: 18.65005 },
    { lat: 49.2879634, lng: 18.6503896 },
    { lat: 49.288113, lng: 18.6509119 },
    { lat: 49.288397, lng: 18.6514811 },
    { lat: 49.28881, lng: 18.6515734 },
    { lat: 49.2888556, lng: 18.6515837 },
    { lat: 49.2891535, lng: 18.6515002 },
    { lat: 49.2898131, lng: 18.6515429 },
    { lat: 49.290283, lng: 18.6526025 },
    { lat: 49.2904674, lng: 18.6544887 },
    { lat: 49.2908106, lng: 18.6562621 },
    { lat: 49.2910698, lng: 18.6585852 },
    { lat: 49.2912491, lng: 18.6593517 },
    { lat: 49.2915429, lng: 18.6597701 },
    { lat: 49.2917614, lng: 18.6601036 },
    { lat: 49.2918459, lng: 18.6607856 },
    { lat: 49.2918789, lng: 18.6616858 },
    { lat: 49.2918737, lng: 18.662227 },
    { lat: 49.2919487, lng: 18.6625374 },
    { lat: 49.2922592, lng: 18.663463 },
    { lat: 49.2924929, lng: 18.664962 },
    { lat: 49.2930779, lng: 18.6654252 },
    { lat: 49.2935882, lng: 18.6655884 },
    { lat: 49.2938936, lng: 18.6654965 },
    { lat: 49.294313, lng: 18.6651322 },
    { lat: 49.2946542, lng: 18.6651012 },
    { lat: 49.2949191, lng: 18.6647379 },
    { lat: 49.2952656, lng: 18.6644231 },
    { lat: 49.2960296, lng: 18.6643688 },
    { lat: 49.2978038, lng: 18.6652738 },
    { lat: 49.2980536, lng: 18.6652831 },
    { lat: 49.2982063, lng: 18.6652082 },
    { lat: 49.2989483, lng: 18.6649883 },
    { lat: 49.2990858, lng: 18.6649475 },
    { lat: 49.2996451, lng: 18.664478 },
    { lat: 49.2997975, lng: 18.6644195 },
    { lat: 49.3001466, lng: 18.6641126 },
    { lat: 49.3010489, lng: 18.665478 },
    { lat: 49.3011418, lng: 18.6655507 },
    { lat: 49.3013018, lng: 18.6656762 },
    { lat: 49.3017182, lng: 18.6657035 },
    { lat: 49.3020549, lng: 18.6658242 },
    { lat: 49.3025189, lng: 18.6659191 },
    { lat: 49.3031344, lng: 18.6658907 },
    { lat: 49.3038303, lng: 18.665726 },
    { lat: 49.3042027, lng: 18.6647242 },
    { lat: 49.3043231, lng: 18.6642742 },
    { lat: 49.304645, lng: 18.6641766 },
    { lat: 49.304887, lng: 18.6642085 },
    { lat: 49.3053388, lng: 18.6641354 },
    { lat: 49.3058228, lng: 18.6639274 },
    { lat: 49.3067482, lng: 18.6639002 },
    { lat: 49.3075384, lng: 18.6637896 },
    { lat: 49.3080927, lng: 18.6638808 },
    { lat: 49.3087647, lng: 18.6639243 },
    { lat: 49.309234, lng: 18.6641844 },
    { lat: 49.309653, lng: 18.6643234 },
    { lat: 49.3099987, lng: 18.6646776 },
    { lat: 49.3103424, lng: 18.6648856 },
    { lat: 49.3108306, lng: 18.6654184 },
    { lat: 49.3117713, lng: 18.6657211 },
    { lat: 49.3117919, lng: 18.6657276 },
    { lat: 49.3121489, lng: 18.6658692 },
    { lat: 49.3126159, lng: 18.6661477 },
    { lat: 49.3130344, lng: 18.6661752 },
    { lat: 49.3132002, lng: 18.6662801 },
    { lat: 49.3134137, lng: 18.6666087 },
    { lat: 49.313512, lng: 18.6667613 },
    { lat: 49.313647, lng: 18.6668173 },
    { lat: 49.3138822, lng: 18.6669162 },
    { lat: 49.314046, lng: 18.6669846 },
    { lat: 49.3141485, lng: 18.6670276 },
    { lat: 49.3142432, lng: 18.6670668 },
    { lat: 49.3143362, lng: 18.6676557 },
    { lat: 49.3144768, lng: 18.667522 },
    { lat: 49.3146592, lng: 18.6676191 },
    { lat: 49.3146927, lng: 18.667637 },
    { lat: 49.3148354, lng: 18.6677126 },
    { lat: 49.3149513, lng: 18.6680901 },
    { lat: 49.3150684, lng: 18.6682184 },
    { lat: 49.3151802, lng: 18.6682194 },
    { lat: 49.3155148, lng: 18.6684706 },
    { lat: 49.31597, lng: 18.6682922 },
    { lat: 49.3169169, lng: 18.6686285 },
    { lat: 49.3170583, lng: 18.6686787 },
    { lat: 49.3173149, lng: 18.6690334 },
    { lat: 49.3176542, lng: 18.6691166 },
    { lat: 49.3178891, lng: 18.6693157 },
    { lat: 49.3185514, lng: 18.6693857 },
    { lat: 49.3188962, lng: 18.669337 },
    { lat: 49.3191934, lng: 18.6693271 },
    { lat: 49.3193664, lng: 18.6691662 },
    { lat: 49.3197486, lng: 18.6689523 },
    { lat: 49.3205164, lng: 18.6687115 },
    { lat: 49.3215719, lng: 18.668416 },
    { lat: 49.3219923, lng: 18.6694988 },
    { lat: 49.3229672, lng: 18.6712623 },
    { lat: 49.3236736, lng: 18.6723001 },
    { lat: 49.3238919, lng: 18.6727822 },
    { lat: 49.3241791, lng: 18.6734383 },
    { lat: 49.3246077, lng: 18.6735466 },
    { lat: 49.3250765, lng: 18.6736938 },
    { lat: 49.3259402, lng: 18.6737973 },
    { lat: 49.3267125, lng: 18.673771 },
    { lat: 49.3274701, lng: 18.6736421 },
    { lat: 49.3278099, lng: 18.6736291 },
    { lat: 49.3284934, lng: 18.673693 },
    { lat: 49.3290336, lng: 18.6733306 },
    { lat: 49.3301934, lng: 18.6741561 },
    { lat: 49.3302524, lng: 18.6743751 },
    { lat: 49.3310494, lng: 18.6748166 },
    { lat: 49.3334826, lng: 18.6750917 },
    { lat: 49.3335095, lng: 18.6750916 },
    { lat: 49.3370525, lng: 18.6750894 },
    { lat: 49.3379847, lng: 18.6742461 },
    { lat: 49.3383914, lng: 18.6740451 },
    { lat: 49.3384264, lng: 18.6740285 },
    { lat: 49.338832, lng: 18.6738284 },
    { lat: 49.3398385, lng: 18.6735617 },
    { lat: 49.3401427, lng: 18.6735762 },
    { lat: 49.3402811, lng: 18.6735298 },
    { lat: 49.3404513, lng: 18.6735255 },
    { lat: 49.3407066, lng: 18.6735775 },
    { lat: 49.341257, lng: 18.673582 },
    { lat: 49.34149, lng: 18.673596 },
    { lat: 49.34174, lng: 18.673716 },
    { lat: 49.342283, lng: 18.67364 },
    { lat: 49.342808, lng: 18.673563 },
    { lat: 49.343988, lng: 18.673276 },
    { lat: 49.344867, lng: 18.672981 },
    { lat: 49.34534, lng: 18.672823 },
    { lat: 49.346224, lng: 18.673606 },
    { lat: 49.346878, lng: 18.67355 },
    { lat: 49.346941, lng: 18.673509 },
    { lat: 49.347816, lng: 18.672942 },
    { lat: 49.348554, lng: 18.672165 },
    { lat: 49.348817, lng: 18.671949 },
    { lat: 49.34904, lng: 18.6717 },
    { lat: 49.349271, lng: 18.671414 },
    { lat: 49.349355, lng: 18.671357 },
    { lat: 49.349709, lng: 18.670884 },
    { lat: 49.351807, lng: 18.670141 },
    { lat: 49.351928, lng: 18.670261 },
    { lat: 49.352362, lng: 18.669574 },
    { lat: 49.352731, lng: 18.668853 },
    { lat: 49.35285, lng: 18.668175 },
    { lat: 49.353064, lng: 18.667251 },
    { lat: 49.353177, lng: 18.667013 },
    { lat: 49.353289, lng: 18.66631 },
    { lat: 49.352882, lng: 18.665244 },
    { lat: 49.352918, lng: 18.665082 },
    { lat: 49.353089, lng: 18.66502 },
    { lat: 49.354393, lng: 18.664553 },
    { lat: 49.354323, lng: 18.664396 },
    { lat: 49.354368, lng: 18.664138 },
    { lat: 49.354375, lng: 18.66362 },
    { lat: 49.354638, lng: 18.662458 },
    { lat: 49.354642, lng: 18.662209 },
    { lat: 49.354679, lng: 18.661999 },
    { lat: 49.354656, lng: 18.661576 },
    { lat: 49.354777, lng: 18.660927 },
    { lat: 49.354959, lng: 18.66052 },
    { lat: 49.355464, lng: 18.65951 },
    { lat: 49.356065, lng: 18.658309 },
    { lat: 49.356171, lng: 18.658201 },
    { lat: 49.356461, lng: 18.658084 },
    { lat: 49.35672, lng: 18.657709 },
    { lat: 49.357211, lng: 18.657254 },
    { lat: 49.35754, lng: 18.657067 },
    { lat: 49.357791, lng: 18.656475 },
    { lat: 49.357924, lng: 18.655761 },
    { lat: 49.358079, lng: 18.655015 },
    { lat: 49.358162, lng: 18.654708 },
    { lat: 49.358179, lng: 18.654188 },
    { lat: 49.358238, lng: 18.653537 },
    { lat: 49.358182, lng: 18.652954 },
    { lat: 49.358154, lng: 18.652521 },
    { lat: 49.358109, lng: 18.651829 },
    { lat: 49.357921, lng: 18.649608 },
    { lat: 49.357906, lng: 18.648832 },
    { lat: 49.358077, lng: 18.647649 },
    { lat: 49.358255, lng: 18.647047 },
    { lat: 49.359008, lng: 18.645772 },
    { lat: 49.359054, lng: 18.645724 },
    { lat: 49.359547, lng: 18.645216 },
    { lat: 49.35957, lng: 18.645179 },
    { lat: 49.359992, lng: 18.644506 },
    { lat: 49.360562, lng: 18.643919 },
    { lat: 49.360604, lng: 18.643877 },
    { lat: 49.360643, lng: 18.643826 },
    { lat: 49.361206, lng: 18.643352 },
    { lat: 49.361954, lng: 18.642841 },
    { lat: 49.362037, lng: 18.642775 },
    { lat: 49.362233, lng: 18.642695 },
    { lat: 49.362778, lng: 18.642483 },
    { lat: 49.362942, lng: 18.642445 },
    { lat: 49.363213, lng: 18.642205 },
    { lat: 49.36383, lng: 18.641403 },
    { lat: 49.363852, lng: 18.641367 },
    { lat: 49.36395, lng: 18.64124 },
    { lat: 49.36427, lng: 18.64045 },
    { lat: 49.364467, lng: 18.639973 },
    { lat: 49.364367, lng: 18.637742 },
    { lat: 49.364332, lng: 18.636022 },
    { lat: 49.364273, lng: 18.634641 },
    { lat: 49.363897, lng: 18.633266 },
    { lat: 49.363443, lng: 18.631961 },
    { lat: 49.363127, lng: 18.631279 },
    { lat: 49.362866, lng: 18.63097 },
    { lat: 49.36233, lng: 18.630565 },
    { lat: 49.362002, lng: 18.630397 },
    { lat: 49.361722, lng: 18.630153 },
    { lat: 49.36088, lng: 18.629661 },
    { lat: 49.360123, lng: 18.629477 },
    { lat: 49.359838, lng: 18.62934 },
    { lat: 49.359232, lng: 18.629001 },
    { lat: 49.358501, lng: 18.629051 },
    { lat: 49.358074, lng: 18.629158 },
    { lat: 49.357229, lng: 18.629168 },
    { lat: 49.356838, lng: 18.628965 },
    { lat: 49.356411, lng: 18.628889 },
    { lat: 49.355538, lng: 18.628462 },
    { lat: 49.355199, lng: 18.628159 },
    { lat: 49.354715, lng: 18.628034 },
    { lat: 49.354253, lng: 18.62784 },
    { lat: 49.35402, lng: 18.627651 },
    { lat: 49.353868, lng: 18.627489 },
    { lat: 49.353525, lng: 18.626978 },
    { lat: 49.353333, lng: 18.626563 },
    { lat: 49.353078, lng: 18.625851 },
    { lat: 49.352827, lng: 18.624854 },
    { lat: 49.352387, lng: 18.624175 },
    { lat: 49.351976, lng: 18.623518 },
    { lat: 49.351688, lng: 18.6232 },
    { lat: 49.351443, lng: 18.622722 },
    { lat: 49.351178, lng: 18.622169 },
    { lat: 49.350644, lng: 18.621828 },
    { lat: 49.3502, lng: 18.621661 },
    { lat: 49.349757, lng: 18.621572 },
    { lat: 49.349423, lng: 18.621268 },
    { lat: 49.349195, lng: 18.620333 },
    { lat: 49.34914, lng: 18.619933 },
    { lat: 49.349215, lng: 18.618874 },
    { lat: 49.349456, lng: 18.618434 },
    { lat: 49.349727, lng: 18.617823 },
    { lat: 49.349891, lng: 18.617594 },
    { lat: 49.349913, lng: 18.617303 },
    { lat: 49.350125, lng: 18.616924 },
    { lat: 49.350268, lng: 18.616334 },
    { lat: 49.350531, lng: 18.615772 },
    { lat: 49.350591, lng: 18.61505 },
    { lat: 49.350677, lng: 18.614413 },
    { lat: 49.350785, lng: 18.614065 },
    { lat: 49.350901, lng: 18.613828 },
    { lat: 49.351071, lng: 18.613585 },
    { lat: 49.351177, lng: 18.613527 },
    { lat: 49.35126, lng: 18.613 },
    { lat: 49.351431, lng: 18.612398 },
    { lat: 49.351568, lng: 18.61187 },
    { lat: 49.351589, lng: 18.611451 },
    { lat: 49.351544, lng: 18.611119 },
    { lat: 49.351537, lng: 18.610708 },
    { lat: 49.351448, lng: 18.61032 },
    { lat: 49.351245, lng: 18.609722 },
    { lat: 49.35101, lng: 18.608916 },
    { lat: 49.350997, lng: 18.608397 },
    { lat: 49.35103, lng: 18.607773 },
    { lat: 49.351128, lng: 18.607416 },
    { lat: 49.351335, lng: 18.607325 },
    { lat: 49.351492, lng: 18.607314 },
    { lat: 49.351748, lng: 18.607157 },
    { lat: 49.351793, lng: 18.607021 },
    { lat: 49.351881, lng: 18.606171 },
    { lat: 49.351893, lng: 18.605708 },
    { lat: 49.351837, lng: 18.605002 },
    { lat: 49.351834, lng: 18.604783 },
    { lat: 49.351849, lng: 18.604566 },
    { lat: 49.351946, lng: 18.603941 },
    { lat: 49.351958, lng: 18.60368 },
  ],
  DolnýHričov: [
    { lat: 49.223578, lng: 18.591938 },
    { lat: 49.223192, lng: 18.591877 },
    { lat: 49.223082, lng: 18.591861 },
    { lat: 49.223042, lng: 18.591906 },
    { lat: 49.22296, lng: 18.591998 },
    { lat: 49.222681, lng: 18.592313 },
    { lat: 49.222648, lng: 18.59235 },
    { lat: 49.222605, lng: 18.5924 },
    { lat: 49.222593, lng: 18.592414 },
    { lat: 49.222572, lng: 18.592439 },
    { lat: 49.222539, lng: 18.592476 },
    { lat: 49.222524, lng: 18.592493 },
    { lat: 49.222498, lng: 18.592525 },
    { lat: 49.222484, lng: 18.59254 },
    { lat: 49.222472, lng: 18.592554 },
    { lat: 49.222283, lng: 18.592772 },
    { lat: 49.222234, lng: 18.592828 },
    { lat: 49.222149, lng: 18.59293 },
    { lat: 49.221952, lng: 18.593166 },
    { lat: 49.221943, lng: 18.593177 },
    { lat: 49.221472, lng: 18.593741 },
    { lat: 49.221465, lng: 18.593751 },
    { lat: 49.221459, lng: 18.593756 },
    { lat: 49.22141, lng: 18.593816 },
    { lat: 49.221405, lng: 18.593821 },
    { lat: 49.221358, lng: 18.593879 },
    { lat: 49.221321, lng: 18.593903 },
    { lat: 49.221314, lng: 18.593908 },
    { lat: 49.221272, lng: 18.593936 },
    { lat: 49.221265, lng: 18.59394 },
    { lat: 49.221263, lng: 18.593941 },
    { lat: 49.221261, lng: 18.593943 },
    { lat: 49.220659, lng: 18.59434 },
    { lat: 49.220599, lng: 18.594387 },
    { lat: 49.220592, lng: 18.594391 },
    { lat: 49.220535, lng: 18.594435 },
    { lat: 49.220532, lng: 18.594438 },
    { lat: 49.220462, lng: 18.594491 },
    { lat: 49.219998, lng: 18.595106 },
    { lat: 49.219909, lng: 18.595168 },
    { lat: 49.219841, lng: 18.595214 },
    { lat: 49.219514, lng: 18.595441 },
    { lat: 49.219484, lng: 18.595463 },
    { lat: 49.218939, lng: 18.596281 },
    { lat: 49.218899, lng: 18.596388 },
    { lat: 49.218868, lng: 18.596475 },
    { lat: 49.218849, lng: 18.596782 },
    { lat: 49.218723, lng: 18.596885 },
    { lat: 49.218938, lng: 18.597897 },
    { lat: 49.218726, lng: 18.59793 },
    { lat: 49.21854, lng: 18.59816 },
    { lat: 49.218531, lng: 18.598343 },
    { lat: 49.218448, lng: 18.59837 },
    { lat: 49.218501, lng: 18.59885 },
    { lat: 49.218475, lng: 18.599671 },
    { lat: 49.218418, lng: 18.59989 },
    { lat: 49.218374, lng: 18.599924 },
    { lat: 49.218399, lng: 18.600154 },
    { lat: 49.218355, lng: 18.60019 },
    { lat: 49.217738, lng: 18.60049 },
    { lat: 49.217582, lng: 18.600644 },
    { lat: 49.216508, lng: 18.600761 },
    { lat: 49.216481, lng: 18.601861 },
    { lat: 49.216102, lng: 18.603469 },
    { lat: 49.215971, lng: 18.604068 },
    { lat: 49.215988, lng: 18.604451 },
    { lat: 49.216075, lng: 18.605175 },
    { lat: 49.216286, lng: 18.606744 },
    { lat: 49.215825, lng: 18.606954 },
    { lat: 49.215556, lng: 18.607076 },
    { lat: 49.215153, lng: 18.607099 },
    { lat: 49.215085, lng: 18.60738 },
    { lat: 49.214395, lng: 18.608542 },
    { lat: 49.2148497, lng: 18.6092641 },
    { lat: 49.2152618, lng: 18.6088517 },
    { lat: 49.2158959, lng: 18.6095514 },
    { lat: 49.2159584, lng: 18.6095613 },
    { lat: 49.2162913, lng: 18.6098846 },
    { lat: 49.2164735, lng: 18.6101664 },
    { lat: 49.2168265, lng: 18.6098519 },
    { lat: 49.2172973, lng: 18.60925 },
    { lat: 49.2178552, lng: 18.6086286 },
    { lat: 49.2185253, lng: 18.60802 },
    { lat: 49.2185413, lng: 18.6080057 },
    { lat: 49.2185586, lng: 18.6079895 },
    { lat: 49.2185672, lng: 18.6079816 },
    { lat: 49.218805, lng: 18.6077662 },
    { lat: 49.2182154, lng: 18.6068431 },
    { lat: 49.2184978, lng: 18.6063869 },
    { lat: 49.2185803, lng: 18.6061165 },
    { lat: 49.2189478, lng: 18.6056267 },
    { lat: 49.2191857, lng: 18.6054787 },
    { lat: 49.2192587, lng: 18.6055577 },
    { lat: 49.2194581, lng: 18.6055307 },
    { lat: 49.2197609, lng: 18.6072965 },
    { lat: 49.219552, lng: 18.6073543 },
    { lat: 49.2192296, lng: 18.6074413 },
    { lat: 49.219043, lng: 18.6089924 },
    { lat: 49.2192949, lng: 18.6092973 },
    { lat: 49.2195701, lng: 18.6098277 },
    { lat: 49.2197908, lng: 18.6103266 },
    { lat: 49.2198229, lng: 18.6104009 },
    { lat: 49.2199887, lng: 18.6109255 },
    { lat: 49.2202221, lng: 18.6111598 },
    { lat: 49.2203459, lng: 18.6112833 },
    { lat: 49.2205902, lng: 18.6116126 },
    { lat: 49.220755, lng: 18.6119125 },
    { lat: 49.2211023, lng: 18.6123975 },
    { lat: 49.2213543, lng: 18.6127003 },
    { lat: 49.2215715, lng: 18.613073 },
    { lat: 49.2216601, lng: 18.6133163 },
    { lat: 49.221993, lng: 18.6145681 },
    { lat: 49.222304, lng: 18.6156396 },
    { lat: 49.2226891, lng: 18.6168563 },
    { lat: 49.2227824, lng: 18.6171492 },
    { lat: 49.222821, lng: 18.6172996 },
    { lat: 49.2228332, lng: 18.6173388 },
    { lat: 49.2228603, lng: 18.6174267 },
    { lat: 49.2229884, lng: 18.6177106 },
    { lat: 49.2230893, lng: 18.6178691 },
    { lat: 49.2231546, lng: 18.6179736 },
    { lat: 49.2233898, lng: 18.6183456 },
    { lat: 49.2235105, lng: 18.6185355 },
    { lat: 49.223552, lng: 18.6185957 },
    { lat: 49.2236214, lng: 18.6185416 },
    { lat: 49.2237415, lng: 18.6184484 },
    { lat: 49.2237989, lng: 18.6184041 },
    { lat: 49.2239417, lng: 18.6183131 },
    { lat: 49.2243114, lng: 18.6180748 },
    { lat: 49.2243802, lng: 18.6183506 },
    { lat: 49.2244618, lng: 18.6182939 },
    { lat: 49.2246784, lng: 18.6179762 },
    { lat: 49.2247003, lng: 18.6179443 },
    { lat: 49.2247828, lng: 18.6182638 },
    { lat: 49.2248124, lng: 18.618841 },
    { lat: 49.2248299, lng: 18.6190853 },
    { lat: 49.2248471, lng: 18.6191506 },
    { lat: 49.2248486, lng: 18.6191613 },
    { lat: 49.2248517, lng: 18.619198 },
    { lat: 49.2248551, lng: 18.619408 },
    { lat: 49.2249875, lng: 18.6196315 },
    { lat: 49.2251203, lng: 18.6199415 },
    { lat: 49.2251291, lng: 18.6199603 },
    { lat: 49.225261, lng: 18.6203588 },
    { lat: 49.2253163, lng: 18.6205941 },
    { lat: 49.22536, lng: 18.620865 },
    { lat: 49.2253982, lng: 18.6211154 },
    { lat: 49.2254321, lng: 18.6214099 },
    { lat: 49.2254597, lng: 18.6216537 },
    { lat: 49.2254697, lng: 18.6219248 },
    { lat: 49.225485, lng: 18.6219825 },
    { lat: 49.2255455, lng: 18.6222081 },
    { lat: 49.2256402, lng: 18.6225037 },
    { lat: 49.2257912, lng: 18.6229266 },
    { lat: 49.225876, lng: 18.6231373 },
    { lat: 49.2258966, lng: 18.6231867 },
    { lat: 49.2259524, lng: 18.6233248 },
    { lat: 49.2260438, lng: 18.6235162 },
    { lat: 49.2260768, lng: 18.6235839 },
    { lat: 49.2261263, lng: 18.6236882 },
    { lat: 49.226067, lng: 18.6237389 },
    { lat: 49.2258783, lng: 18.6238828 },
    { lat: 49.2257993, lng: 18.6239435 },
    { lat: 49.2257461, lng: 18.6239829 },
    { lat: 49.225668, lng: 18.6240424 },
    { lat: 49.2253601, lng: 18.6242744 },
    { lat: 49.2249626, lng: 18.6245792 },
    { lat: 49.2247577, lng: 18.6247345 },
    { lat: 49.2247295, lng: 18.6247564 },
    { lat: 49.2245743, lng: 18.6249564 },
    { lat: 49.2244831, lng: 18.6251698 },
    { lat: 49.2241885, lng: 18.6258638 },
    { lat: 49.2239132, lng: 18.6263933 },
    { lat: 49.2236907, lng: 18.6259825 },
    { lat: 49.2234393, lng: 18.6254549 },
    { lat: 49.2233923, lng: 18.6253575 },
    { lat: 49.2231391, lng: 18.6249213 },
    { lat: 49.2228983, lng: 18.6254841 },
    { lat: 49.2227815, lng: 18.6261268 },
    { lat: 49.222616, lng: 18.6262371 },
    { lat: 49.2225097, lng: 18.6265296 },
    { lat: 49.2224951, lng: 18.6265704 },
    { lat: 49.2224302, lng: 18.6266437 },
    { lat: 49.2217094, lng: 18.6270481 },
    { lat: 49.2215665, lng: 18.6273285 },
    { lat: 49.2214596, lng: 18.6288145 },
    { lat: 49.2213796, lng: 18.6293678 },
    { lat: 49.2213209, lng: 18.6297697 },
    { lat: 49.2207697, lng: 18.6306206 },
    { lat: 49.2200403, lng: 18.6307735 },
    { lat: 49.2194667, lng: 18.6316073 },
    { lat: 49.2192564, lng: 18.6319598 },
    { lat: 49.2191209, lng: 18.6330593 },
    { lat: 49.2188204, lng: 18.6340032 },
    { lat: 49.2188235, lng: 18.6341052 },
    { lat: 49.2188419, lng: 18.6347671 },
    { lat: 49.2188319, lng: 18.6352374 },
    { lat: 49.2187073, lng: 18.6355475 },
    { lat: 49.2187897, lng: 18.6360117 },
    { lat: 49.2186816, lng: 18.6367806 },
    { lat: 49.2185834, lng: 18.6379244 },
    { lat: 49.2183672, lng: 18.6399498 },
    { lat: 49.2182288, lng: 18.6413747 },
    { lat: 49.2182277, lng: 18.6416111 },
    { lat: 49.2182353, lng: 18.6433259 },
    { lat: 49.2188706, lng: 18.6439057 },
    { lat: 49.2189788, lng: 18.6441586 },
    { lat: 49.2190187, lng: 18.6442507 },
    { lat: 49.2191891, lng: 18.6444024 },
    { lat: 49.2192237, lng: 18.6448632 },
    { lat: 49.2192291, lng: 18.6449029 },
    { lat: 49.2193201, lng: 18.6456835 },
    { lat: 49.2195132, lng: 18.6466399 },
    { lat: 49.2195946, lng: 18.6468794 },
    { lat: 49.2196077, lng: 18.6469164 },
    { lat: 49.2197959, lng: 18.647465 },
    { lat: 49.2199952, lng: 18.6479256 },
    { lat: 49.2200644, lng: 18.6480815 },
    { lat: 49.220118, lng: 18.6482005 },
    { lat: 49.2201576, lng: 18.6482886 },
    { lat: 49.2202536, lng: 18.6485026 },
    { lat: 49.2202689, lng: 18.6485375 },
    { lat: 49.2208115, lng: 18.6489434 },
    { lat: 49.2212052, lng: 18.6492407 },
    { lat: 49.2214841, lng: 18.6499726 },
    { lat: 49.2223487, lng: 18.650943 },
    { lat: 49.2237799, lng: 18.6514354 },
    { lat: 49.2248153, lng: 18.6513313 },
    { lat: 49.2258517, lng: 18.651074 },
    { lat: 49.2267943, lng: 18.6508377 },
    { lat: 49.227258, lng: 18.6506523 },
    { lat: 49.227623, lng: 18.6505386 },
    { lat: 49.2278198, lng: 18.6506128 },
    { lat: 49.2281688, lng: 18.6508789 },
    { lat: 49.2287643, lng: 18.6514094 },
    { lat: 49.2291255, lng: 18.6518222 },
    { lat: 49.2300523, lng: 18.6524142 },
    { lat: 49.2305645, lng: 18.6526079 },
    { lat: 49.2310332, lng: 18.6529844 },
    { lat: 49.231274, lng: 18.6533779 },
    { lat: 49.2317891, lng: 18.6538613 },
    { lat: 49.231902, lng: 18.6540358 },
    { lat: 49.2321115, lng: 18.6546259 },
    { lat: 49.2324657, lng: 18.6552674 },
    { lat: 49.2326088, lng: 18.6555196 },
    { lat: 49.2326821, lng: 18.6556509 },
    { lat: 49.2327158, lng: 18.6557099 },
    { lat: 49.2327606, lng: 18.6557892 },
    { lat: 49.2328116, lng: 18.6558782 },
    { lat: 49.2328638, lng: 18.6559706 },
    { lat: 49.2330413, lng: 18.6564044 },
    { lat: 49.2331037, lng: 18.657453 },
    { lat: 49.2331967, lng: 18.6578926 },
    { lat: 49.2331935, lng: 18.6583188 },
    { lat: 49.2332535, lng: 18.6585373 },
    { lat: 49.233489, lng: 18.659344 },
    { lat: 49.2334423, lng: 18.6596756 },
    { lat: 49.2334958, lng: 18.6604084 },
    { lat: 49.2335452, lng: 18.6606188 },
    { lat: 49.2335802, lng: 18.6610436 },
    { lat: 49.2337225, lng: 18.6618574 },
    { lat: 49.233645, lng: 18.6624426 },
    { lat: 49.2337883, lng: 18.6634678 },
    { lat: 49.2338365, lng: 18.6637798 },
    { lat: 49.2339864, lng: 18.6642683 },
    { lat: 49.2341606, lng: 18.6648762 },
    { lat: 49.2342237, lng: 18.6657475 },
    { lat: 49.2342356, lng: 18.6661639 },
    { lat: 49.2342411, lng: 18.6665454 },
    { lat: 49.2342242, lng: 18.6671653 },
    { lat: 49.2341439, lng: 18.6677157 },
    { lat: 49.2341493, lng: 18.6681178 },
    { lat: 49.2339695, lng: 18.6685788 },
    { lat: 49.2339341, lng: 18.6690793 },
    { lat: 49.2339625, lng: 18.6694024 },
    { lat: 49.2340875, lng: 18.6697641 },
    { lat: 49.234214, lng: 18.6701729 },
    { lat: 49.2343723, lng: 18.6707138 },
    { lat: 49.2344739, lng: 18.6712495 },
    { lat: 49.2346018, lng: 18.6717689 },
    { lat: 49.2346684, lng: 18.6723456 },
    { lat: 49.2347852, lng: 18.672682 },
    { lat: 49.2349506, lng: 18.6726732 },
    { lat: 49.2350713, lng: 18.6725879 },
    { lat: 49.2352473, lng: 18.6724843 },
    { lat: 49.2353965, lng: 18.6723826 },
    { lat: 49.2355605, lng: 18.6723516 },
    { lat: 49.2357314, lng: 18.672106 },
    { lat: 49.2359251, lng: 18.6719696 },
    { lat: 49.2361158, lng: 18.6718443 },
    { lat: 49.2363041, lng: 18.6715887 },
    { lat: 49.2365582, lng: 18.6714481 },
    { lat: 49.2368711, lng: 18.6712872 },
    { lat: 49.2372443, lng: 18.6711358 },
    { lat: 49.237736, lng: 18.6708814 },
    { lat: 49.2378521, lng: 18.6707069 },
    { lat: 49.2380693, lng: 18.6707209 },
    { lat: 49.2381424, lng: 18.6708433 },
    { lat: 49.2383381, lng: 18.6707278 },
    { lat: 49.2385166, lng: 18.6707116 },
    { lat: 49.238856, lng: 18.6703295 },
    { lat: 49.2389258, lng: 18.6701996 },
    { lat: 49.2391999, lng: 18.669775 },
    { lat: 49.2397946, lng: 18.6693985 },
    { lat: 49.239953, lng: 18.6690455 },
    { lat: 49.2401654, lng: 18.6686032 },
    { lat: 49.2403787, lng: 18.6682023 },
    { lat: 49.2405276, lng: 18.6680111 },
    { lat: 49.2407406, lng: 18.6678393 },
    { lat: 49.2407192, lng: 18.667345 },
    { lat: 49.2405837, lng: 18.6669578 },
    { lat: 49.2409529, lng: 18.6665215 },
    { lat: 49.2412779, lng: 18.6662915 },
    { lat: 49.2412617, lng: 18.6662383 },
    { lat: 49.2405095, lng: 18.664193 },
    { lat: 49.240763, lng: 18.6638878 },
    { lat: 49.2407852, lng: 18.6638161 },
    { lat: 49.2408505, lng: 18.6636247 },
    { lat: 49.2408983, lng: 18.663438 },
    { lat: 49.2410924, lng: 18.6626767 },
    { lat: 49.2411289, lng: 18.6624914 },
    { lat: 49.2412477, lng: 18.6620065 },
    { lat: 49.2414332, lng: 18.6619132 },
    { lat: 49.2419783, lng: 18.6616442 },
    { lat: 49.2423291, lng: 18.6615164 },
    { lat: 49.2423511, lng: 18.6615086 },
    { lat: 49.2423864, lng: 18.6605543 },
    { lat: 49.242426, lng: 18.6600427 },
    { lat: 49.2424477, lng: 18.6597516 },
    { lat: 49.2424535, lng: 18.6597105 },
    { lat: 49.2424723, lng: 18.6595782 },
    { lat: 49.2424944, lng: 18.65946 },
    { lat: 49.2425924, lng: 18.6589274 },
    { lat: 49.2427689, lng: 18.6581282 },
    { lat: 49.2427967, lng: 18.657801 },
    { lat: 49.2427974, lng: 18.6577926 },
    { lat: 49.2427971, lng: 18.6577865 },
    { lat: 49.2427966, lng: 18.6576079 },
    { lat: 49.242796, lng: 18.657595 },
    { lat: 49.2427947, lng: 18.6574237 },
    { lat: 49.2427947, lng: 18.6572721 },
    { lat: 49.2427942, lng: 18.6571783 },
    { lat: 49.2427937, lng: 18.6571279 },
    { lat: 49.2427902, lng: 18.6569099 },
    { lat: 49.2426642, lng: 18.6559806 },
    { lat: 49.2426033, lng: 18.6551706 },
    { lat: 49.2425385, lng: 18.6543315 },
    { lat: 49.242677, lng: 18.6529656 },
    { lat: 49.2428898, lng: 18.652064 },
    { lat: 49.2430832, lng: 18.6515658 },
    { lat: 49.2434817, lng: 18.6509109 },
    { lat: 49.2435198, lng: 18.6507395 },
    { lat: 49.2436695, lng: 18.6500547 },
    { lat: 49.2436725, lng: 18.649554 },
    { lat: 49.2436704, lng: 18.6493045 },
    { lat: 49.2436659, lng: 18.6490683 },
    { lat: 49.2437234, lng: 18.6488161 },
    { lat: 49.2437271, lng: 18.6485431 },
    { lat: 49.2437657, lng: 18.6481655 },
    { lat: 49.2435946, lng: 18.6474695 },
    { lat: 49.2434421, lng: 18.6469783 },
    { lat: 49.2434139, lng: 18.6468071 },
    { lat: 49.2434054, lng: 18.6467537 },
    { lat: 49.2433936, lng: 18.6466999 },
    { lat: 49.2433505, lng: 18.6465035 },
    { lat: 49.2433296, lng: 18.6464068 },
    { lat: 49.243325, lng: 18.6463825 },
    { lat: 49.2432874, lng: 18.646142 },
    { lat: 49.2433599, lng: 18.6456826 },
    { lat: 49.2434539, lng: 18.6456241 },
    { lat: 49.2438795, lng: 18.645357 },
    { lat: 49.2443417, lng: 18.644697 },
    { lat: 49.2443527, lng: 18.6446668 },
    { lat: 49.244382, lng: 18.644583 },
    { lat: 49.2444026, lng: 18.644526 },
    { lat: 49.2445225, lng: 18.6443007 },
    { lat: 49.2445628, lng: 18.6441419 },
    { lat: 49.2446222, lng: 18.6439036 },
    { lat: 49.2446948, lng: 18.6436156 },
    { lat: 49.244775, lng: 18.6432775 },
    { lat: 49.2448994, lng: 18.6427499 },
    { lat: 49.2449287, lng: 18.6426258 },
    { lat: 49.2450586, lng: 18.6420758 },
    { lat: 49.245087, lng: 18.6419518 },
    { lat: 49.2451202, lng: 18.6418126 },
    { lat: 49.2446997, lng: 18.6413698 },
    { lat: 49.2444131, lng: 18.6410675 },
    { lat: 49.2438741, lng: 18.640369 },
    { lat: 49.2433707, lng: 18.6396171 },
    { lat: 49.243411, lng: 18.639556 },
    { lat: 49.242222, lng: 18.637674 },
    { lat: 49.241354, lng: 18.636041 },
    { lat: 49.240771, lng: 18.634907 },
    { lat: 49.240109, lng: 18.633632 },
    { lat: 49.23944, lng: 18.632116 },
    { lat: 49.239264, lng: 18.6314 },
    { lat: 49.239192, lng: 18.631046 },
    { lat: 49.238964, lng: 18.630391 },
    { lat: 49.238949, lng: 18.630349 },
    { lat: 49.238936, lng: 18.630311 },
    { lat: 49.238747, lng: 18.629579 },
    { lat: 49.238601, lng: 18.629129 },
    { lat: 49.238536, lng: 18.628651 },
    { lat: 49.238402, lng: 18.628058 },
    { lat: 49.238386, lng: 18.627998 },
    { lat: 49.238375, lng: 18.62795 },
    { lat: 49.238088, lng: 18.626797 },
    { lat: 49.23763, lng: 18.625212 },
    { lat: 49.2373, lng: 18.624224 },
    { lat: 49.237322, lng: 18.622955 },
    { lat: 49.237388, lng: 18.621625 },
    { lat: 49.23745, lng: 18.620836 },
    { lat: 49.23743, lng: 18.620207 },
    { lat: 49.237474, lng: 18.61976 },
    { lat: 49.237511, lng: 18.619206 },
    { lat: 49.237526, lng: 18.618646 },
    { lat: 49.237409, lng: 18.617542 },
    { lat: 49.237325, lng: 18.615925 },
    { lat: 49.236871, lng: 18.615361 },
    { lat: 49.236677, lng: 18.61509 },
    { lat: 49.236286, lng: 18.614524 },
    { lat: 49.235689, lng: 18.613456 },
    { lat: 49.235222, lng: 18.612358 },
    { lat: 49.23514, lng: 18.612148 },
    { lat: 49.234768, lng: 18.610802 },
    { lat: 49.234429, lng: 18.608972 },
    { lat: 49.234109, lng: 18.608545 },
    { lat: 49.234073, lng: 18.608505 },
    { lat: 49.233198, lng: 18.6075 },
    { lat: 49.232049, lng: 18.606267 },
    { lat: 49.231957, lng: 18.605979 },
    { lat: 49.231896, lng: 18.605795 },
    { lat: 49.231527, lng: 18.604702 },
    { lat: 49.230716, lng: 18.605268 },
    { lat: 49.229609, lng: 18.604514 },
    { lat: 49.229273, lng: 18.604242 },
    { lat: 49.228478, lng: 18.603673 },
    { lat: 49.227227, lng: 18.602846 },
    { lat: 49.225831, lng: 18.601952 },
    { lat: 49.225446, lng: 18.601546 },
    { lat: 49.225063, lng: 18.601111 },
    { lat: 49.224746, lng: 18.600753 },
    { lat: 49.224502, lng: 18.600167 },
    { lat: 49.224078, lng: 18.599137 },
    { lat: 49.224041, lng: 18.599096 },
    { lat: 49.224523, lng: 18.598688 },
    { lat: 49.224711, lng: 18.598529 },
    { lat: 49.224743, lng: 18.598493 },
    { lat: 49.224775, lng: 18.598457 },
    { lat: 49.225024, lng: 18.598176 },
    { lat: 49.225148, lng: 18.598036 },
    { lat: 49.224165, lng: 18.596169 },
    { lat: 49.22367, lng: 18.59388 },
    { lat: 49.223578, lng: 18.591938 },
  ],
  DolnýHričovExt: [
    { lat: 49.2087373, lng: 18.6554604 },
    { lat: 49.2087528, lng: 18.655408 },
    { lat: 49.208483, lng: 18.65517 },
    { lat: 49.2081301, lng: 18.6544724 },
    { lat: 49.2080481, lng: 18.6537092 },
    { lat: 49.2080126, lng: 18.653085 },
    { lat: 49.2080107, lng: 18.6530495 },
    { lat: 49.2079983, lng: 18.6529226 },
    { lat: 49.2079783, lng: 18.6526293 },
    { lat: 49.2079717, lng: 18.6525908 },
    { lat: 49.2079509, lng: 18.6523222 },
    { lat: 49.2079024, lng: 18.6512825 },
    { lat: 49.2079952, lng: 18.6509248 },
    { lat: 49.208063, lng: 18.6508663 },
    { lat: 49.208053, lng: 18.6508264 },
    { lat: 49.2078624, lng: 18.6502527 },
    { lat: 49.2076915, lng: 18.6497486 },
    { lat: 49.2075817, lng: 18.6494661 },
    { lat: 49.2075382, lng: 18.6493715 },
    { lat: 49.2074161, lng: 18.6490876 },
    { lat: 49.2073959, lng: 18.6490447 },
    { lat: 49.2069682, lng: 18.6482759 },
    { lat: 49.207351, lng: 18.6473709 },
    { lat: 49.2076661, lng: 18.6459574 },
    { lat: 49.2077471, lng: 18.6455914 },
    { lat: 49.2074756, lng: 18.6449187 },
    { lat: 49.2071819, lng: 18.6441017 },
    { lat: 49.2071126, lng: 18.6438996 },
    { lat: 49.2066286, lng: 18.6432179 },
    { lat: 49.2067288, lng: 18.6426007 },
    { lat: 49.2067706, lng: 18.6423236 },
    { lat: 49.2067829, lng: 18.6422795 },
    { lat: 49.2067853, lng: 18.6422168 },
    { lat: 49.2067869, lng: 18.6421639 },
    { lat: 49.2068698, lng: 18.6409209 },
    { lat: 49.2064903, lng: 18.6405707 },
    { lat: 49.2061474, lng: 18.6402492 },
    { lat: 49.2054054, lng: 18.639232 },
    { lat: 49.2050573, lng: 18.6390728 },
    { lat: 49.2050663, lng: 18.63905 },
    { lat: 49.2046485, lng: 18.6387439 },
    { lat: 49.2045571, lng: 18.6387231 },
    { lat: 49.2044347, lng: 18.6387084 },
    { lat: 49.2042272, lng: 18.6387084 },
    { lat: 49.2037003, lng: 18.6387243 },
    { lat: 49.2034631, lng: 18.6386981 },
    { lat: 49.203014, lng: 18.6387136 },
    { lat: 49.202876, lng: 18.6386207 },
    { lat: 49.2026281, lng: 18.6384123 },
    { lat: 49.2023102, lng: 18.6382637 },
    { lat: 49.2020163, lng: 18.637871 },
    { lat: 49.2019371, lng: 18.6378029 },
    { lat: 49.2016243, lng: 18.6374146 },
    { lat: 49.2014467, lng: 18.6371051 },
    { lat: 49.201346, lng: 18.6369304 },
    { lat: 49.2008664, lng: 18.6363501 },
    { lat: 49.2007353, lng: 18.6361128 },
    { lat: 49.2005347, lng: 18.6358632 },
    { lat: 49.2004375, lng: 18.6357955 },
    { lat: 49.2001726, lng: 18.6356487 },
    { lat: 49.1998314, lng: 18.6354457 },
    { lat: 49.1996219, lng: 18.6353858 },
    { lat: 49.1994859, lng: 18.6353533 },
    { lat: 49.1993902, lng: 18.6353352 },
    { lat: 49.199086, lng: 18.6352755 },
    { lat: 49.1989252, lng: 18.6352201 },
    { lat: 49.1985746, lng: 18.6350763 },
    { lat: 49.1983437, lng: 18.634956 },
    { lat: 49.1982908, lng: 18.6349124 },
    { lat: 49.1976398, lng: 18.634015 },
    { lat: 49.1975187, lng: 18.6338631 },
    { lat: 49.1974882, lng: 18.6338402 },
    { lat: 49.1967607, lng: 18.6328607 },
    { lat: 49.1963961, lng: 18.6323686 },
    { lat: 49.196027, lng: 18.6318319 },
    { lat: 49.1958953, lng: 18.6315594 },
    { lat: 49.1957927, lng: 18.6313731 },
    { lat: 49.195589, lng: 18.631231 },
    { lat: 49.1952702, lng: 18.6309651 },
    { lat: 49.1949454, lng: 18.6306649 },
    { lat: 49.1948435, lng: 18.6305755 },
    { lat: 49.194334, lng: 18.630132 },
    { lat: 49.1937908, lng: 18.629587 },
    { lat: 49.1933759, lng: 18.6292126 },
    { lat: 49.1932492, lng: 18.6287358 },
    { lat: 49.1931506, lng: 18.6284336 },
    { lat: 49.1930592, lng: 18.6281781 },
    { lat: 49.1930584, lng: 18.6281392 },
    { lat: 49.1929182, lng: 18.6274553 },
    { lat: 49.1928327, lng: 18.627057 },
    { lat: 49.1926475, lng: 18.6265041 },
    { lat: 49.1924849, lng: 18.6259718 },
    { lat: 49.1920563, lng: 18.6254178 },
    { lat: 49.1916253, lng: 18.6250739 },
    { lat: 49.1916103, lng: 18.6250484 },
    { lat: 49.1912792, lng: 18.6244408 },
    { lat: 49.1912389, lng: 18.6239824 },
    { lat: 49.1911919, lng: 18.6234597 },
    { lat: 49.1910394, lng: 18.6228425 },
    { lat: 49.1910932, lng: 18.6224771 },
    { lat: 49.1910247, lng: 18.6221229 },
    { lat: 49.1907988, lng: 18.6219061 },
    { lat: 49.1904817, lng: 18.6217969 },
    { lat: 49.1901912, lng: 18.621865 },
    { lat: 49.1898809, lng: 18.6219677 },
    { lat: 49.1893821, lng: 18.6219654 },
    { lat: 49.1892151, lng: 18.6217305 },
    { lat: 49.1892816, lng: 18.6208814 },
    { lat: 49.1890443, lng: 18.6203232 },
    { lat: 49.1888881, lng: 18.619955 },
    { lat: 49.1888386, lng: 18.6189429 },
    { lat: 49.1883376, lng: 18.6189175 },
    { lat: 49.1873049, lng: 18.6182031 },
    { lat: 49.1867838, lng: 18.6175438 },
    { lat: 49.1865878, lng: 18.6175725 },
    { lat: 49.1865741, lng: 18.6176088 },
    { lat: 49.1864265, lng: 18.6177396 },
    { lat: 49.1863308, lng: 18.6178272 },
    { lat: 49.1860844, lng: 18.6180283 },
    { lat: 49.186295, lng: 18.6188058 },
    { lat: 49.1863627, lng: 18.619569 },
    { lat: 49.1863811, lng: 18.6199323 },
    { lat: 49.1864679, lng: 18.6202301 },
    { lat: 49.1865549, lng: 18.6206566 },
    { lat: 49.1864904, lng: 18.6210324 },
    { lat: 49.1866729, lng: 18.6214723 },
    { lat: 49.1867378, lng: 18.6221183 },
    { lat: 49.1867087, lng: 18.6224366 },
    { lat: 49.1866721, lng: 18.6230454 },
    { lat: 49.1866144, lng: 18.6236954 },
    { lat: 49.1867688, lng: 18.6243274 },
    { lat: 49.1870202, lng: 18.6250043 },
    { lat: 49.187251, lng: 18.6253748 },
    { lat: 49.1874289, lng: 18.6255215 },
    { lat: 49.1876972, lng: 18.6258552 },
    { lat: 49.1878016, lng: 18.626315 },
    { lat: 49.1879888, lng: 18.626675 },
    { lat: 49.1880883, lng: 18.6274613 },
    { lat: 49.188361, lng: 18.6277527 },
    { lat: 49.1884385, lng: 18.6278322 },
    { lat: 49.1884797, lng: 18.6278645 },
    { lat: 49.1887378, lng: 18.6280528 },
    { lat: 49.1888184, lng: 18.6281272 },
    { lat: 49.1891136, lng: 18.6283946 },
    { lat: 49.1893674, lng: 18.6291405 },
    { lat: 49.1896066, lng: 18.6299448 },
    { lat: 49.1896062, lng: 18.6299585 },
    { lat: 49.189603, lng: 18.6300271 },
    { lat: 49.18959, lng: 18.6302667 },
    { lat: 49.1893725, lng: 18.6310558 },
    { lat: 49.1892119, lng: 18.6320835 },
    { lat: 49.1888674, lng: 18.6333675 },
    { lat: 49.1889195, lng: 18.6340937 },
    { lat: 49.1888719, lng: 18.6353629 },
    { lat: 49.1889539, lng: 18.6365833 },
    { lat: 49.1891873, lng: 18.638173 },
    { lat: 49.1892259, lng: 18.6384548 },
    { lat: 49.1892107, lng: 18.6384675 },
    { lat: 49.1892552, lng: 18.6386632 },
    { lat: 49.1896691, lng: 18.6393854 },
    { lat: 49.1898125, lng: 18.6397725 },
    { lat: 49.1899194, lng: 18.6400842 },
    { lat: 49.1900275, lng: 18.6404642 },
    { lat: 49.1900863, lng: 18.6407215 },
    { lat: 49.1901252, lng: 18.6407964 },
    { lat: 49.190151, lng: 18.6408592 },
    { lat: 49.1904156, lng: 18.6412119 },
    { lat: 49.1906105, lng: 18.6415458 },
    { lat: 49.1908563, lng: 18.6418652 },
    { lat: 49.1909906, lng: 18.6421208 },
    { lat: 49.1910037, lng: 18.6422612 },
    { lat: 49.1909567, lng: 18.642461 },
    { lat: 49.1908827, lng: 18.6426379 },
    { lat: 49.1907978, lng: 18.6434773 },
    { lat: 49.1908003, lng: 18.6435704 },
    { lat: 49.1907634, lng: 18.6435558 },
    { lat: 49.1907161, lng: 18.6452105 },
    { lat: 49.1911099, lng: 18.645217 },
    { lat: 49.1912894, lng: 18.6453711 },
    { lat: 49.191801, lng: 18.6459576 },
    { lat: 49.1923711, lng: 18.6467298 },
    { lat: 49.1927663, lng: 18.6476747 },
    { lat: 49.1929708, lng: 18.6481932 },
    { lat: 49.1931783, lng: 18.6488516 },
    { lat: 49.1935805, lng: 18.6499474 },
    { lat: 49.1937931, lng: 18.6511295 },
    { lat: 49.1940742, lng: 18.6528955 },
    { lat: 49.194174, lng: 18.6532492 },
    { lat: 49.1943865, lng: 18.6540877 },
    { lat: 49.1943947, lng: 18.6546895 },
    { lat: 49.1944294, lng: 18.6549604 },
    { lat: 49.1947128, lng: 18.6564759 },
    { lat: 49.1948593, lng: 18.6572839 },
    { lat: 49.1948971, lng: 18.6574881 },
    { lat: 49.1949572, lng: 18.6578113 },
    { lat: 49.1951804, lng: 18.6583618 },
    { lat: 49.1953457, lng: 18.658738 },
    { lat: 49.1955711, lng: 18.6589688 },
    { lat: 49.1963961, lng: 18.6600678 },
    { lat: 49.1974928, lng: 18.6616332 },
    { lat: 49.1975112, lng: 18.6616594 },
    { lat: 49.197478, lng: 18.6613537 },
    { lat: 49.1981947, lng: 18.6595723 },
    { lat: 49.1995929, lng: 18.6580197 },
    { lat: 49.2010452, lng: 18.6583962 },
    { lat: 49.2016742, lng: 18.6584766 },
    { lat: 49.2018608, lng: 18.6583804 },
    { lat: 49.2027021, lng: 18.6583187 },
    { lat: 49.2030044, lng: 18.6583374 },
    { lat: 49.203454, lng: 18.6583533 },
    { lat: 49.2036644, lng: 18.6583237 },
    { lat: 49.2042533, lng: 18.6586269 },
    { lat: 49.2051966, lng: 18.6585945 },
    { lat: 49.205803, lng: 18.6583105 },
    { lat: 49.206003, lng: 18.6580207 },
    { lat: 49.2066447, lng: 18.6570413 },
    { lat: 49.2070693, lng: 18.65648 },
    { lat: 49.2073641, lng: 18.6560624 },
    { lat: 49.2078257, lng: 18.6556397 },
    { lat: 49.2081851, lng: 18.6555733 },
    { lat: 49.2087373, lng: 18.6554604 },
  ],
  HornýHričov: [
    { lat: 49.256858, lng: 18.635904 },
    { lat: 49.256335, lng: 18.635892 },
    { lat: 49.255707, lng: 18.635983 },
    { lat: 49.255412, lng: 18.636033 },
    { lat: 49.254888, lng: 18.636228 },
    { lat: 49.254896, lng: 18.636282 },
    { lat: 49.255278, lng: 18.639782 },
    { lat: 49.254908, lng: 18.639884 },
    { lat: 49.255246, lng: 18.641905 },
    { lat: 49.255259, lng: 18.641999 },
    { lat: 49.255243, lng: 18.642032 },
    { lat: 49.255215, lng: 18.642087 },
    { lat: 49.2552102, lng: 18.6420959 },
    { lat: 49.255204, lng: 18.642108 },
    { lat: 49.255178, lng: 18.642163 },
    { lat: 49.254815, lng: 18.641763 },
    { lat: 49.254179, lng: 18.641152 },
    { lat: 49.253893, lng: 18.640931 },
    { lat: 49.253261, lng: 18.640491 },
    { lat: 49.252561, lng: 18.640067 },
    { lat: 49.251391, lng: 18.639421 },
    { lat: 49.250529, lng: 18.638964 },
    { lat: 49.250047, lng: 18.638704 },
    { lat: 49.249625, lng: 18.638489 },
    { lat: 49.249367, lng: 18.638256 },
    { lat: 49.249216, lng: 18.638084 },
    { lat: 49.24878, lng: 18.638021 },
    { lat: 49.248172, lng: 18.637675 },
    { lat: 49.24816, lng: 18.637717 },
    { lat: 49.248122, lng: 18.63784 },
    { lat: 49.248111, lng: 18.637874 },
    { lat: 49.24811, lng: 18.63788 },
    { lat: 49.248105, lng: 18.637896 },
    { lat: 49.248102, lng: 18.637905 },
    { lat: 49.24809, lng: 18.637948 },
    { lat: 49.247919, lng: 18.638511 },
    { lat: 49.247896, lng: 18.638582 },
    { lat: 49.247885, lng: 18.63862 },
    { lat: 49.24788, lng: 18.638637 },
    { lat: 49.247866, lng: 18.638684 },
    { lat: 49.247842, lng: 18.638762 },
    { lat: 49.24775, lng: 18.639068 },
    { lat: 49.247549, lng: 18.639061 },
    { lat: 49.245887, lng: 18.639228 },
    { lat: 49.245833, lng: 18.639235 },
    { lat: 49.244424, lng: 18.639422 },
    { lat: 49.244026, lng: 18.639474 },
    { lat: 49.243884, lng: 18.639493 },
    { lat: 49.243652, lng: 18.639524 },
    { lat: 49.243585, lng: 18.639533 },
    { lat: 49.243573, lng: 18.639535 },
    { lat: 49.243538, lng: 18.639539 },
    { lat: 49.243528, lng: 18.63954 },
    { lat: 49.243411, lng: 18.639556 },
    { lat: 49.2433707, lng: 18.6396171 },
    { lat: 49.2438741, lng: 18.640369 },
    { lat: 49.2444131, lng: 18.6410675 },
    { lat: 49.2446997, lng: 18.6413698 },
    { lat: 49.2451202, lng: 18.6418126 },
    { lat: 49.245087, lng: 18.6419518 },
    { lat: 49.2450586, lng: 18.6420758 },
    { lat: 49.2449287, lng: 18.6426258 },
    { lat: 49.2448994, lng: 18.6427499 },
    { lat: 49.244775, lng: 18.6432775 },
    { lat: 49.2446948, lng: 18.6436156 },
    { lat: 49.2446222, lng: 18.6439036 },
    { lat: 49.2445628, lng: 18.6441419 },
    { lat: 49.2445225, lng: 18.6443007 },
    { lat: 49.2444026, lng: 18.644526 },
    { lat: 49.244382, lng: 18.644583 },
    { lat: 49.2443527, lng: 18.6446668 },
    { lat: 49.2443417, lng: 18.644697 },
    { lat: 49.2438795, lng: 18.645357 },
    { lat: 49.2434539, lng: 18.6456241 },
    { lat: 49.2433599, lng: 18.6456826 },
    { lat: 49.2432874, lng: 18.646142 },
    { lat: 49.243325, lng: 18.6463825 },
    { lat: 49.2433296, lng: 18.6464068 },
    { lat: 49.2433505, lng: 18.6465035 },
    { lat: 49.2433936, lng: 18.6466999 },
    { lat: 49.2434054, lng: 18.6467537 },
    { lat: 49.2434139, lng: 18.6468071 },
    { lat: 49.2434421, lng: 18.6469783 },
    { lat: 49.2435946, lng: 18.6474695 },
    { lat: 49.2437657, lng: 18.6481655 },
    { lat: 49.2437271, lng: 18.6485431 },
    { lat: 49.2437234, lng: 18.6488161 },
    { lat: 49.2436659, lng: 18.6490683 },
    { lat: 49.2436704, lng: 18.6493045 },
    { lat: 49.2436725, lng: 18.649554 },
    { lat: 49.2436695, lng: 18.6500547 },
    { lat: 49.2435198, lng: 18.6507395 },
    { lat: 49.2434817, lng: 18.6509109 },
    { lat: 49.2430832, lng: 18.6515658 },
    { lat: 49.2428898, lng: 18.652064 },
    { lat: 49.242677, lng: 18.6529656 },
    { lat: 49.2425385, lng: 18.6543315 },
    { lat: 49.2426033, lng: 18.6551706 },
    { lat: 49.2426642, lng: 18.6559806 },
    { lat: 49.2427902, lng: 18.6569099 },
    { lat: 49.2427937, lng: 18.6571279 },
    { lat: 49.2427942, lng: 18.6571783 },
    { lat: 49.2427947, lng: 18.6572721 },
    { lat: 49.2427947, lng: 18.6574237 },
    { lat: 49.242796, lng: 18.657595 },
    { lat: 49.2427966, lng: 18.6576079 },
    { lat: 49.2427971, lng: 18.6577865 },
    { lat: 49.2427974, lng: 18.6577926 },
    { lat: 49.2427967, lng: 18.657801 },
    { lat: 49.2427689, lng: 18.6581282 },
    { lat: 49.2425924, lng: 18.6589274 },
    { lat: 49.2424944, lng: 18.65946 },
    { lat: 49.2424723, lng: 18.6595782 },
    { lat: 49.2424535, lng: 18.6597105 },
    { lat: 49.2424477, lng: 18.6597516 },
    { lat: 49.242426, lng: 18.6600427 },
    { lat: 49.2423864, lng: 18.6605543 },
    { lat: 49.2423511, lng: 18.6615086 },
    { lat: 49.2423521, lng: 18.6624433 },
    { lat: 49.2426576, lng: 18.663335 },
    { lat: 49.2429541, lng: 18.6639102 },
    { lat: 49.2433368, lng: 18.6647079 },
    { lat: 49.2439574, lng: 18.6657366 },
    { lat: 49.2442038, lng: 18.6666432 },
    { lat: 49.2444809, lng: 18.6675745 },
    { lat: 49.2444919, lng: 18.6676125 },
    { lat: 49.2445152, lng: 18.6676919 },
    { lat: 49.2450103, lng: 18.6693562 },
    { lat: 49.2450374, lng: 18.670191 },
    { lat: 49.2451805, lng: 18.6701896 },
    { lat: 49.2456546, lng: 18.6700481 },
    { lat: 49.2460947, lng: 18.669647 },
    { lat: 49.2467373, lng: 18.6689585 },
    { lat: 49.2469949, lng: 18.6687769 },
    { lat: 49.247194, lng: 18.6684474 },
    { lat: 49.2474588, lng: 18.6679545 },
    { lat: 49.2476681, lng: 18.667859 },
    { lat: 49.2481539, lng: 18.6678316 },
    { lat: 49.2481615, lng: 18.6678274 },
    { lat: 49.2484328, lng: 18.6676716 },
    { lat: 49.2484989, lng: 18.6676385 },
    { lat: 49.2486502, lng: 18.6677076 },
    { lat: 49.2489277, lng: 18.6678149 },
    { lat: 49.2492175, lng: 18.6678174 },
    { lat: 49.2495992, lng: 18.6681234 },
    { lat: 49.249806, lng: 18.6683459 },
    { lat: 49.2497841, lng: 18.66838 },
    { lat: 49.2498179, lng: 18.6684415 },
    { lat: 49.2498942, lng: 18.6685844 },
    { lat: 49.2499412, lng: 18.6686205 },
    { lat: 49.2500058, lng: 18.668671 },
    { lat: 49.2500613, lng: 18.6687151 },
    { lat: 49.2504359, lng: 18.6689573 },
    { lat: 49.2503335, lng: 18.6696457 },
    { lat: 49.2502063, lng: 18.6700505 },
    { lat: 49.2501341, lng: 18.6706478 },
    { lat: 49.2500686, lng: 18.6712024 },
    { lat: 49.2499321, lng: 18.6721654 },
    { lat: 49.2499111, lng: 18.672917 },
    { lat: 49.2497828, lng: 18.6740984 },
    { lat: 49.2497791, lng: 18.6741367 },
    { lat: 49.2493049, lng: 18.676344 },
    { lat: 49.2489768, lng: 18.6773944 },
    { lat: 49.2487473, lng: 18.6780652 },
    { lat: 49.2485544, lng: 18.6783651 },
    { lat: 49.2481441, lng: 18.6789462 },
    { lat: 49.2480978, lng: 18.6790079 },
    { lat: 49.2475226, lng: 18.6800114 },
    { lat: 49.2469272, lng: 18.6811238 },
    { lat: 49.2467794, lng: 18.6819332 },
    { lat: 49.246637, lng: 18.682678 },
    { lat: 49.246004, lng: 18.6831197 },
    { lat: 49.2456379, lng: 18.683382 },
    { lat: 49.2455843, lng: 18.6849118 },
    { lat: 49.2449998, lng: 18.6864253 },
    { lat: 49.2449784, lng: 18.6864679 },
    { lat: 49.2450105, lng: 18.6865196 },
    { lat: 49.2455312, lng: 18.6873706 },
    { lat: 49.2455619, lng: 18.6874191 },
    { lat: 49.2456312, lng: 18.6875153 },
    { lat: 49.24565, lng: 18.6875487 },
    { lat: 49.2456716, lng: 18.6875728 },
    { lat: 49.2457339, lng: 18.6876438 },
    { lat: 49.2457387, lng: 18.6876493 },
    { lat: 49.2457701, lng: 18.6876895 },
    { lat: 49.2458003, lng: 18.6877273 },
    { lat: 49.2458208, lng: 18.6877537 },
    { lat: 49.2459018, lng: 18.6878567 },
    { lat: 49.2459762, lng: 18.6880486 },
    { lat: 49.2461904, lng: 18.6884309 },
    { lat: 49.2462136, lng: 18.6886789 },
    { lat: 49.2462227, lng: 18.6887885 },
    { lat: 49.2462567, lng: 18.6889186 },
    { lat: 49.246437, lng: 18.6893235 },
    { lat: 49.2469403, lng: 18.6905293 },
    { lat: 49.2469926, lng: 18.6906654 },
    { lat: 49.2470847, lng: 18.6907671 },
    { lat: 49.2482078, lng: 18.6920098 },
    { lat: 49.2495686, lng: 18.6888769 },
    { lat: 49.2496965, lng: 18.6874737 },
    { lat: 49.2501308, lng: 18.6867463 },
    { lat: 49.2517913, lng: 18.684938 },
    { lat: 49.2522304, lng: 18.685715 },
    { lat: 49.2533522, lng: 18.6869539 },
    { lat: 49.2541447, lng: 18.6882774 },
    { lat: 49.2546758, lng: 18.689068 },
    { lat: 49.2547479, lng: 18.6884238 },
    { lat: 49.2548471, lng: 18.6880178 },
    { lat: 49.2549442, lng: 18.6877632 },
    { lat: 49.2555246, lng: 18.6865561 },
    { lat: 49.2567101, lng: 18.6843179 },
    { lat: 49.2567606, lng: 18.6843532 },
    { lat: 49.257476, lng: 18.6848345 },
    { lat: 49.257904, lng: 18.6851855 },
    { lat: 49.2586172, lng: 18.685178 },
    { lat: 49.2589353, lng: 18.6859501 },
    { lat: 49.2589393, lng: 18.6859583 },
    { lat: 49.2590538, lng: 18.6858832 },
    { lat: 49.2591499, lng: 18.6858057 },
    { lat: 49.2592539, lng: 18.6857044 },
    { lat: 49.2593784, lng: 18.6855819 },
    { lat: 49.2593892, lng: 18.6855712 },
    { lat: 49.2595421, lng: 18.6853924 },
    { lat: 49.2596968, lng: 18.6851625 },
    { lat: 49.2597389, lng: 18.6850653 },
    { lat: 49.2598528, lng: 18.6848036 },
    { lat: 49.2598709, lng: 18.6847631 },
    { lat: 49.2598344, lng: 18.6845853 },
    { lat: 49.2598798, lng: 18.6845053 },
    { lat: 49.2599506, lng: 18.6843276 },
    { lat: 49.2599663, lng: 18.6842856 },
    { lat: 49.2601016, lng: 18.6838978 },
    { lat: 49.2601199, lng: 18.6838418 },
    { lat: 49.2601546, lng: 18.6837296 },
    { lat: 49.260178, lng: 18.683657 },
    { lat: 49.2602956, lng: 18.6832786 },
    { lat: 49.2608781, lng: 18.6814107 },
    { lat: 49.2613206, lng: 18.6800059 },
    { lat: 49.2614711, lng: 18.6795259 },
    { lat: 49.2617448, lng: 18.6785148 },
    { lat: 49.2617676, lng: 18.6784312 },
    { lat: 49.2619461, lng: 18.6775423 },
    { lat: 49.2614539, lng: 18.6769096 },
    { lat: 49.260953, lng: 18.6762665 },
    { lat: 49.2608926, lng: 18.6761888 },
    { lat: 49.2608622, lng: 18.6761485 },
    { lat: 49.260797, lng: 18.6760648 },
    { lat: 49.2607727, lng: 18.6760342 },
    { lat: 49.2607195, lng: 18.6759653 },
    { lat: 49.2606749, lng: 18.6759083 },
    { lat: 49.2610958, lng: 18.6749968 },
    { lat: 49.2615044, lng: 18.6739138 },
    { lat: 49.26184, lng: 18.6728171 },
    { lat: 49.2621151, lng: 18.6716184 },
    { lat: 49.262293, lng: 18.6703735 },
    { lat: 49.262393, lng: 18.6693238 },
    { lat: 49.2624457, lng: 18.6679734 },
    { lat: 49.2624227, lng: 18.6670014 },
    { lat: 49.2622909, lng: 18.665746 },
    { lat: 49.2621159, lng: 18.6645897 },
    { lat: 49.2618333, lng: 18.6633389 },
    { lat: 49.2613361, lng: 18.6616588 },
    { lat: 49.2608193, lng: 18.6598801 },
    { lat: 49.2606459, lng: 18.6592924 },
    { lat: 49.2602888, lng: 18.6580858 },
    { lat: 49.2598278, lng: 18.6567653 },
    { lat: 49.2597078, lng: 18.6561635 },
    { lat: 49.2591529, lng: 18.654412 },
    { lat: 49.2591062, lng: 18.6542355 },
    { lat: 49.2585602, lng: 18.6524847 },
    { lat: 49.2583818, lng: 18.6519269 },
    { lat: 49.2580078, lng: 18.6507655 },
    { lat: 49.2576144, lng: 18.6495357 },
    { lat: 49.2574828, lng: 18.6491169 },
    { lat: 49.2570714, lng: 18.64782 },
    { lat: 49.2570112, lng: 18.6476426 },
    { lat: 49.2568294, lng: 18.6471499 },
    { lat: 49.2568027, lng: 18.6470702 },
    { lat: 49.2568021, lng: 18.6470374 },
    { lat: 49.2568007, lng: 18.6469253 },
    { lat: 49.2568, lng: 18.646872 },
    { lat: 49.2568, lng: 18.6467655 },
    { lat: 49.2567985, lng: 18.6466534 },
    { lat: 49.2567965, lng: 18.6464226 },
    { lat: 49.2567965, lng: 18.6463778 },
    { lat: 49.2567844, lng: 18.6449156 },
    { lat: 49.2567819, lng: 18.6447675 },
    { lat: 49.2567816, lng: 18.6446943 },
    { lat: 49.2567827, lng: 18.6446924 },
    { lat: 49.2567846, lng: 18.6446268 },
    { lat: 49.2567889, lng: 18.6444941 },
    { lat: 49.2567898, lng: 18.6444658 },
    { lat: 49.2567955, lng: 18.6442976 },
    { lat: 49.2567976, lng: 18.6442515 },
    { lat: 49.2568037, lng: 18.6440897 },
    { lat: 49.2568054, lng: 18.6440162 },
    { lat: 49.2568075, lng: 18.6439697 },
    { lat: 49.2568226, lng: 18.6435343 },
    { lat: 49.25683, lng: 18.6430983 },
    { lat: 49.2568733, lng: 18.6429175 },
    { lat: 49.2571142, lng: 18.6419309 },
    { lat: 49.2573843, lng: 18.6407313 },
    { lat: 49.2575911, lng: 18.6392954 },
    { lat: 49.2575026, lng: 18.6375434 },
    { lat: 49.2573993, lng: 18.6368084 },
    { lat: 49.2571288, lng: 18.6359372 },
    { lat: 49.256858, lng: 18.635904 },
  ],
  HričovskéPodhradie: [
    { lat: 49.2192564, lng: 18.6319598 },
    { lat: 49.2194667, lng: 18.6316073 },
    { lat: 49.2200403, lng: 18.6307735 },
    { lat: 49.2207697, lng: 18.6306206 },
    { lat: 49.2213209, lng: 18.6297697 },
    { lat: 49.2213796, lng: 18.6293678 },
    { lat: 49.2214596, lng: 18.6288145 },
    { lat: 49.2215665, lng: 18.6273285 },
    { lat: 49.2217094, lng: 18.6270481 },
    { lat: 49.2224302, lng: 18.6266437 },
    { lat: 49.2224951, lng: 18.6265704 },
    { lat: 49.2225097, lng: 18.6265296 },
    { lat: 49.222616, lng: 18.6262371 },
    { lat: 49.2227815, lng: 18.6261268 },
    { lat: 49.2228983, lng: 18.6254841 },
    { lat: 49.2231391, lng: 18.6249213 },
    { lat: 49.2233923, lng: 18.6253575 },
    { lat: 49.2234393, lng: 18.6254549 },
    { lat: 49.2236907, lng: 18.6259825 },
    { lat: 49.2239132, lng: 18.6263933 },
    { lat: 49.2241885, lng: 18.6258638 },
    { lat: 49.2244831, lng: 18.6251698 },
    { lat: 49.2245743, lng: 18.6249564 },
    { lat: 49.2247295, lng: 18.6247564 },
    { lat: 49.2247577, lng: 18.6247345 },
    { lat: 49.2249626, lng: 18.6245792 },
    { lat: 49.2253601, lng: 18.6242744 },
    { lat: 49.225668, lng: 18.6240424 },
    { lat: 49.2257461, lng: 18.6239829 },
    { lat: 49.2257993, lng: 18.6239435 },
    { lat: 49.2258783, lng: 18.6238828 },
    { lat: 49.226067, lng: 18.6237389 },
    { lat: 49.2261263, lng: 18.6236882 },
    { lat: 49.2260768, lng: 18.6235839 },
    { lat: 49.2260438, lng: 18.6235162 },
    { lat: 49.2259524, lng: 18.6233248 },
    { lat: 49.2258966, lng: 18.6231867 },
    { lat: 49.225876, lng: 18.6231373 },
    { lat: 49.2257912, lng: 18.6229266 },
    { lat: 49.2256402, lng: 18.6225037 },
    { lat: 49.2255455, lng: 18.6222081 },
    { lat: 49.225485, lng: 18.6219825 },
    { lat: 49.2254697, lng: 18.6219248 },
    { lat: 49.2254597, lng: 18.6216537 },
    { lat: 49.2254321, lng: 18.6214099 },
    { lat: 49.2253982, lng: 18.6211154 },
    { lat: 49.22536, lng: 18.620865 },
    { lat: 49.2253163, lng: 18.6205941 },
    { lat: 49.225261, lng: 18.6203588 },
    { lat: 49.2251291, lng: 18.6199603 },
    { lat: 49.2251203, lng: 18.6199415 },
    { lat: 49.2249875, lng: 18.6196315 },
    { lat: 49.2248551, lng: 18.619408 },
    { lat: 49.2248517, lng: 18.619198 },
    { lat: 49.2248486, lng: 18.6191613 },
    { lat: 49.2248471, lng: 18.6191506 },
    { lat: 49.2248299, lng: 18.6190853 },
    { lat: 49.2248124, lng: 18.618841 },
    { lat: 49.2247828, lng: 18.6182638 },
    { lat: 49.2247003, lng: 18.6179443 },
    { lat: 49.2246784, lng: 18.6179762 },
    { lat: 49.2244618, lng: 18.6182939 },
    { lat: 49.2243802, lng: 18.6183506 },
    { lat: 49.2243114, lng: 18.6180748 },
    { lat: 49.2239417, lng: 18.6183131 },
    { lat: 49.2237989, lng: 18.6184041 },
    { lat: 49.2237415, lng: 18.6184484 },
    { lat: 49.2236214, lng: 18.6185416 },
    { lat: 49.223552, lng: 18.6185957 },
    { lat: 49.2235105, lng: 18.6185355 },
    { lat: 49.2233898, lng: 18.6183456 },
    { lat: 49.2231546, lng: 18.6179736 },
    { lat: 49.2230893, lng: 18.6178691 },
    { lat: 49.2229884, lng: 18.6177106 },
    { lat: 49.2228603, lng: 18.6174267 },
    { lat: 49.2228332, lng: 18.6173388 },
    { lat: 49.222821, lng: 18.6172996 },
    { lat: 49.2227824, lng: 18.6171492 },
    { lat: 49.2226891, lng: 18.6168563 },
    { lat: 49.222304, lng: 18.6156396 },
    { lat: 49.221993, lng: 18.6145681 },
    { lat: 49.2216601, lng: 18.6133163 },
    { lat: 49.2215715, lng: 18.613073 },
    { lat: 49.2213543, lng: 18.6127003 },
    { lat: 49.2211023, lng: 18.6123975 },
    { lat: 49.220755, lng: 18.6119125 },
    { lat: 49.2205902, lng: 18.6116126 },
    { lat: 49.2203459, lng: 18.6112833 },
    { lat: 49.2202221, lng: 18.6111598 },
    { lat: 49.2199887, lng: 18.6109255 },
    { lat: 49.2198229, lng: 18.6104009 },
    { lat: 49.2197908, lng: 18.6103266 },
    { lat: 49.2195701, lng: 18.6098277 },
    { lat: 49.2192949, lng: 18.6092973 },
    { lat: 49.219043, lng: 18.6089924 },
    { lat: 49.2192296, lng: 18.6074413 },
    { lat: 49.219552, lng: 18.6073543 },
    { lat: 49.2197609, lng: 18.6072965 },
    { lat: 49.2194581, lng: 18.6055307 },
    { lat: 49.2192587, lng: 18.6055577 },
    { lat: 49.2191857, lng: 18.6054787 },
    { lat: 49.2189478, lng: 18.6056267 },
    { lat: 49.2185803, lng: 18.6061165 },
    { lat: 49.2184978, lng: 18.6063869 },
    { lat: 49.2182154, lng: 18.6068431 },
    { lat: 49.218805, lng: 18.6077662 },
    { lat: 49.2185672, lng: 18.6079816 },
    { lat: 49.2185586, lng: 18.6079895 },
    { lat: 49.2185413, lng: 18.6080057 },
    { lat: 49.2185253, lng: 18.60802 },
    { lat: 49.2178552, lng: 18.6086286 },
    { lat: 49.2172973, lng: 18.60925 },
    { lat: 49.2168265, lng: 18.6098519 },
    { lat: 49.2164735, lng: 18.6101664 },
    { lat: 49.2162913, lng: 18.6098846 },
    { lat: 49.2159584, lng: 18.6095613 },
    { lat: 49.2158959, lng: 18.6095514 },
    { lat: 49.2152618, lng: 18.6088517 },
    { lat: 49.2148497, lng: 18.6092641 },
    { lat: 49.214395, lng: 18.608542 },
    { lat: 49.214187, lng: 18.608618 },
    { lat: 49.21368, lng: 18.608403 },
    { lat: 49.213254, lng: 18.607713 },
    { lat: 49.212804, lng: 18.60733 },
    { lat: 49.212137, lng: 18.607169 },
    { lat: 49.211649, lng: 18.607093 },
    { lat: 49.210677, lng: 18.607182 },
    { lat: 49.210062, lng: 18.607399 },
    { lat: 49.20965, lng: 18.607713 },
    { lat: 49.209158, lng: 18.608372 },
    { lat: 49.209131, lng: 18.608409 },
    { lat: 49.208976, lng: 18.608762 },
    { lat: 49.208589, lng: 18.609965 },
    { lat: 49.208364, lng: 18.610621 },
    { lat: 49.207947, lng: 18.611496 },
    { lat: 49.207586, lng: 18.611883 },
    { lat: 49.2074913, lng: 18.6125228 },
    { lat: 49.2073576, lng: 18.6133791 },
    { lat: 49.2073967, lng: 18.6147225 },
    { lat: 49.2081728, lng: 18.6149388 },
    { lat: 49.2083732, lng: 18.6148295 },
    { lat: 49.2087464, lng: 18.6156098 },
    { lat: 49.2092221, lng: 18.6165567 },
    { lat: 49.2093567, lng: 18.6168776 },
    { lat: 49.2095417, lng: 18.6171356 },
    { lat: 49.209686, lng: 18.6174297 },
    { lat: 49.210592, lng: 18.6191929 },
    { lat: 49.2108329, lng: 18.6196694 },
    { lat: 49.2111213, lng: 18.6201093 },
    { lat: 49.211197, lng: 18.6202608 },
    { lat: 49.2111834, lng: 18.6204054 },
    { lat: 49.2113, lng: 18.6208811 },
    { lat: 49.2115242, lng: 18.6214026 },
    { lat: 49.2117367, lng: 18.6217319 },
    { lat: 49.2124437, lng: 18.6225557 },
    { lat: 49.213178, lng: 18.6232751 },
    { lat: 49.2137854, lng: 18.6241145 },
    { lat: 49.2144539, lng: 18.6248725 },
    { lat: 49.2150824, lng: 18.6257898 },
    { lat: 49.2156729, lng: 18.6259928 },
    { lat: 49.2158885, lng: 18.6261443 },
    { lat: 49.216149, lng: 18.6264828 },
    { lat: 49.2162787, lng: 18.6266114 },
    { lat: 49.2163121, lng: 18.6266437 },
    { lat: 49.2163346, lng: 18.6266665 },
    { lat: 49.2163715, lng: 18.6267023 },
    { lat: 49.21645, lng: 18.6267799 },
    { lat: 49.2165049, lng: 18.6268341 },
    { lat: 49.216587, lng: 18.6269151 },
    { lat: 49.2167385, lng: 18.6274526 },
    { lat: 49.2169756, lng: 18.6284305 },
    { lat: 49.2171282, lng: 18.6290955 },
    { lat: 49.2177221, lng: 18.6303397 },
    { lat: 49.2181506, lng: 18.6309941 },
    { lat: 49.2189236, lng: 18.6317229 },
    { lat: 49.2192564, lng: 18.6319598 },
  ],
  Hôrky: [
    { lat: 49.2038624, lng: 18.705169 },
    { lat: 49.2041087, lng: 18.7044288 },
    { lat: 49.2041527, lng: 18.7043679 },
    { lat: 49.2043997, lng: 18.7041923 },
    { lat: 49.2048939, lng: 18.7039439 },
    { lat: 49.2053338, lng: 18.7037923 },
    { lat: 49.2056269, lng: 18.7035782 },
    { lat: 49.2057267, lng: 18.7035664 },
    { lat: 49.2057532, lng: 18.7035584 },
    { lat: 49.2068071, lng: 18.703055 },
    { lat: 49.2068424, lng: 18.7030388 },
    { lat: 49.2068709, lng: 18.7030248 },
    { lat: 49.2071594, lng: 18.7029779 },
    { lat: 49.2074288, lng: 18.7029345 },
    { lat: 49.2078393, lng: 18.7028683 },
    { lat: 49.2078748, lng: 18.7028589 },
    { lat: 49.2079104, lng: 18.7028495 },
    { lat: 49.2080213, lng: 18.702815 },
    { lat: 49.2079839, lng: 18.7020362 },
    { lat: 49.2079779, lng: 18.7018812 },
    { lat: 49.2079993, lng: 18.7010883 },
    { lat: 49.2080315, lng: 18.7007749 },
    { lat: 49.2080575, lng: 18.7005874 },
    { lat: 49.2080927, lng: 18.7003538 },
    { lat: 49.2081397, lng: 18.7000075 },
    { lat: 49.2081553, lng: 18.6997019 },
    { lat: 49.2081324, lng: 18.6993297 },
    { lat: 49.2080756, lng: 18.69913 },
    { lat: 49.208016, lng: 18.6989638 },
    { lat: 49.207866, lng: 18.6986644 },
    { lat: 49.2076247, lng: 18.6982998 },
    { lat: 49.2078336, lng: 18.6981743 },
    { lat: 49.2081253, lng: 18.6985306 },
    { lat: 49.2085062, lng: 18.6990829 },
    { lat: 49.2085956, lng: 18.699156 },
    { lat: 49.2088037, lng: 18.6989924 },
    { lat: 49.2089975, lng: 18.698885 },
    { lat: 49.2092432, lng: 18.6987773 },
    { lat: 49.2096906, lng: 18.6986384 },
    { lat: 49.2093057, lng: 18.6975221 },
    { lat: 49.2088437, lng: 18.6963272 },
    { lat: 49.2086667, lng: 18.6959013 },
    { lat: 49.20865, lng: 18.6958615 },
    { lat: 49.2087868, lng: 18.6957178 },
    { lat: 49.2089322, lng: 18.6955658 },
    { lat: 49.2091179, lng: 18.6954088 },
    { lat: 49.2091504, lng: 18.6953832 },
    { lat: 49.2091735, lng: 18.6953735 },
    { lat: 49.2094681, lng: 18.6952068 },
    { lat: 49.2095579, lng: 18.6951568 },
    { lat: 49.209708, lng: 18.6950735 },
    { lat: 49.209722, lng: 18.6951234 },
    { lat: 49.2099434, lng: 18.6958807 },
    { lat: 49.2103284, lng: 18.6956134 },
    { lat: 49.2101415, lng: 18.6948688 },
    { lat: 49.2101358, lng: 18.6948457 },
    { lat: 49.210474, lng: 18.6946491 },
    { lat: 49.2108267, lng: 18.6944941 },
    { lat: 49.2111189, lng: 18.69439 },
    { lat: 49.2113904, lng: 18.6942586 },
    { lat: 49.2116666, lng: 18.6940199 },
    { lat: 49.2118516, lng: 18.6938944 },
    { lat: 49.2121201, lng: 18.6937258 },
    { lat: 49.212483, lng: 18.6935493 },
    { lat: 49.2132086, lng: 18.6932153 },
    { lat: 49.2134192, lng: 18.6930593 },
    { lat: 49.2139605, lng: 18.6928702 },
    { lat: 49.2139644, lng: 18.6928351 },
    { lat: 49.2140052, lng: 18.6926238 },
    { lat: 49.2141072, lng: 18.6922271 },
    { lat: 49.214158, lng: 18.691779 },
    { lat: 49.2141571, lng: 18.6917203 },
    { lat: 49.2141449, lng: 18.6912121 },
    { lat: 49.2144577, lng: 18.6910666 },
    { lat: 49.2146976, lng: 18.6909964 },
    { lat: 49.2148452, lng: 18.6909526 },
    { lat: 49.2148943, lng: 18.6909845 },
    { lat: 49.2150552, lng: 18.6910866 },
    { lat: 49.2151877, lng: 18.6910769 },
    { lat: 49.2151591, lng: 18.6906807 },
    { lat: 49.2151087, lng: 18.690258 },
    { lat: 49.2150231, lng: 18.6897906 },
    { lat: 49.2149074, lng: 18.6893308 },
    { lat: 49.2153491, lng: 18.6888751 },
    { lat: 49.215577, lng: 18.6886159 },
    { lat: 49.2152569, lng: 18.6876581 },
    { lat: 49.2152883, lng: 18.6876352 },
    { lat: 49.2163264, lng: 18.6868746 },
    { lat: 49.2162256, lng: 18.6866344 },
    { lat: 49.2159922, lng: 18.6858641 },
    { lat: 49.2156052, lng: 18.6858384 },
    { lat: 49.214324, lng: 18.6856878 },
    { lat: 49.2141369, lng: 18.685666 },
    { lat: 49.2140996, lng: 18.6856616 },
    { lat: 49.213938, lng: 18.6856356 },
    { lat: 49.212643, lng: 18.6854795 },
    { lat: 49.2119164, lng: 18.6854306 },
    { lat: 49.2119246, lng: 18.6841509 },
    { lat: 49.2119064, lng: 18.6830815 },
    { lat: 49.211885, lng: 18.68024 },
    { lat: 49.2112756, lng: 18.6799099 },
    { lat: 49.210642, lng: 18.6796134 },
    { lat: 49.2103271, lng: 18.6792244 },
    { lat: 49.2095654, lng: 18.6785527 },
    { lat: 49.2088566, lng: 18.6782402 },
    { lat: 49.2087721, lng: 18.678203 },
    { lat: 49.2087201, lng: 18.6781982 },
    { lat: 49.2082159, lng: 18.6781549 },
    { lat: 49.2070178, lng: 18.678079 },
    { lat: 49.2069682, lng: 18.6780606 },
    { lat: 49.2064644, lng: 18.6779985 },
    { lat: 49.2064121, lng: 18.677993 },
    { lat: 49.2060594, lng: 18.6779546 },
    { lat: 49.2059444, lng: 18.677933 },
    { lat: 49.2053891, lng: 18.6780944 },
    { lat: 49.2048526, lng: 18.6784434 },
    { lat: 49.2046249, lng: 18.6786373 },
    { lat: 49.2045588, lng: 18.6788806 },
    { lat: 49.2041522, lng: 18.679209 },
    { lat: 49.2041212, lng: 18.6792204 },
    { lat: 49.2040405, lng: 18.6792498 },
    { lat: 49.2039883, lng: 18.6792692 },
    { lat: 49.2039287, lng: 18.6792906 },
    { lat: 49.2039165, lng: 18.679295 },
    { lat: 49.2036462, lng: 18.6794711 },
    { lat: 49.2036188, lng: 18.6794889 },
    { lat: 49.2034728, lng: 18.6795836 },
    { lat: 49.2033075, lng: 18.6798503 },
    { lat: 49.2028995, lng: 18.6801305 },
    { lat: 49.202216, lng: 18.6801951 },
    { lat: 49.2021977, lng: 18.6801656 },
    { lat: 49.2010735, lng: 18.6814307 },
    { lat: 49.2003534, lng: 18.6820396 },
    { lat: 49.1998862, lng: 18.6824364 },
    { lat: 49.1998028, lng: 18.6825454 },
    { lat: 49.1997078, lng: 18.6827334 },
    { lat: 49.1996287, lng: 18.682944 },
    { lat: 49.1995941, lng: 18.6831217 },
    { lat: 49.1995854, lng: 18.6832523 },
    { lat: 49.1996149, lng: 18.683579 },
    { lat: 49.1996653, lng: 18.6839561 },
    { lat: 49.1996658, lng: 18.6840098 },
    { lat: 49.1996327, lng: 18.6841718 },
    { lat: 49.1995984, lng: 18.6842917 },
    { lat: 49.1994069, lng: 18.6850643 },
    { lat: 49.1993794, lng: 18.6852285 },
    { lat: 49.1993761, lng: 18.6853818 },
    { lat: 49.199519, lng: 18.6878986 },
    { lat: 49.199549, lng: 18.6883586 },
    { lat: 49.199547, lng: 18.6885547 },
    { lat: 49.1995364, lng: 18.6887165 },
    { lat: 49.1995168, lng: 18.688901 },
    { lat: 49.199163, lng: 18.6922432 },
    { lat: 49.1991384, lng: 18.6923779 },
    { lat: 49.1990915, lng: 18.6925152 },
    { lat: 49.1991019, lng: 18.6925825 },
    { lat: 49.1992215, lng: 18.6934394 },
    { lat: 49.1992362, lng: 18.6935079 },
    { lat: 49.1993585, lng: 18.6943724 },
    { lat: 49.1996199, lng: 18.6962083 },
    { lat: 49.1996234, lng: 18.6962342 },
    { lat: 49.1996385, lng: 18.6963275 },
    { lat: 49.1996403, lng: 18.6963399 },
    { lat: 49.20007, lng: 18.6988465 },
    { lat: 49.2000763, lng: 18.6988796 },
    { lat: 49.2000898, lng: 18.6988797 },
    { lat: 49.2002954, lng: 18.6989753 },
    { lat: 49.2006246, lng: 18.6992267 },
    { lat: 49.2008207, lng: 18.6996535 },
    { lat: 49.2007889, lng: 18.7006159 },
    { lat: 49.2010589, lng: 18.7019531 },
    { lat: 49.2012837, lng: 18.7030954 },
    { lat: 49.2012969, lng: 18.7030678 },
    { lat: 49.2019265, lng: 18.7017667 },
    { lat: 49.2021716, lng: 18.7021182 },
    { lat: 49.2022739, lng: 18.7021984 },
    { lat: 49.2022997, lng: 18.7022172 },
    { lat: 49.2027653, lng: 18.7025774 },
    { lat: 49.2030507, lng: 18.7029684 },
    { lat: 49.2030752, lng: 18.7030018 },
    { lat: 49.2033619, lng: 18.7041225 },
    { lat: 49.2035505, lng: 18.7045588 },
    { lat: 49.2037202, lng: 18.7048911 },
    { lat: 49.2038624, lng: 18.705169 },
  ],
  Lietava: [
    { lat: 49.1865741, lng: 18.6176088 },
    { lat: 49.1861183, lng: 18.6169331 },
    { lat: 49.1853986, lng: 18.6176441 },
    { lat: 49.1850043, lng: 18.6181195 },
    { lat: 49.184787, lng: 18.6189516 },
    { lat: 49.1835366, lng: 18.6198228 },
    { lat: 49.1832261, lng: 18.6199442 },
    { lat: 49.1813737, lng: 18.6207963 },
    { lat: 49.1801588, lng: 18.6214049 },
    { lat: 49.1801054, lng: 18.6218001 },
    { lat: 49.1799823, lng: 18.6220699 },
    { lat: 49.1797614, lng: 18.622328 },
    { lat: 49.1792789, lng: 18.6227112 },
    { lat: 49.1791335, lng: 18.6228601 },
    { lat: 49.1789578, lng: 18.6230813 },
    { lat: 49.1787668, lng: 18.6238104 },
    { lat: 49.178777, lng: 18.624065 },
    { lat: 49.1787941, lng: 18.6244916 },
    { lat: 49.1788425, lng: 18.6256575 },
    { lat: 49.1787507, lng: 18.6256238 },
    { lat: 49.1789663, lng: 18.6268588 },
    { lat: 49.1790539, lng: 18.6281424 },
    { lat: 49.1791214, lng: 18.6285626 },
    { lat: 49.1793503, lng: 18.6289203 },
    { lat: 49.1795385, lng: 18.6290643 },
    { lat: 49.1797393, lng: 18.6291292 },
    { lat: 49.1799113, lng: 18.6291868 },
    { lat: 49.1801567, lng: 18.6292595 },
    { lat: 49.1800519, lng: 18.6293903 },
    { lat: 49.1802901, lng: 18.6295631 },
    { lat: 49.1803627, lng: 18.6296132 },
    { lat: 49.1804273, lng: 18.6296625 },
    { lat: 49.1805543, lng: 18.6297812 },
    { lat: 49.1806641, lng: 18.6299161 },
    { lat: 49.1808071, lng: 18.6301021 },
    { lat: 49.1808387, lng: 18.6301461 },
    { lat: 49.1809698, lng: 18.6303804 },
    { lat: 49.1810163, lng: 18.6304727 },
    { lat: 49.1810637, lng: 18.6305779 },
    { lat: 49.1810887, lng: 18.6306242 },
    { lat: 49.1811788, lng: 18.6306437 },
    { lat: 49.1811834, lng: 18.6307073 },
    { lat: 49.1812531, lng: 18.6309391 },
    { lat: 49.1812696, lng: 18.6310985 },
    { lat: 49.181309, lng: 18.6314386 },
    { lat: 49.1809789, lng: 18.6314208 },
    { lat: 49.1810129, lng: 18.631869 },
    { lat: 49.1810228, lng: 18.6320985 },
    { lat: 49.1810328, lng: 18.6325615 },
    { lat: 49.1810438, lng: 18.633 },
    { lat: 49.1810494, lng: 18.633318 },
    { lat: 49.1810611, lng: 18.6339416 },
    { lat: 49.1810507, lng: 18.6339786 },
    { lat: 49.1809899, lng: 18.6339374 },
    { lat: 49.1809855, lng: 18.6343021 },
    { lat: 49.181037, lng: 18.6344839 },
    { lat: 49.1809187, lng: 18.6347217 },
    { lat: 49.1811404, lng: 18.6350511 },
    { lat: 49.1812636, lng: 18.6352031 },
    { lat: 49.181384, lng: 18.6351561 },
    { lat: 49.181469, lng: 18.635204 },
    { lat: 49.1815971, lng: 18.6352624 },
    { lat: 49.181677, lng: 18.635343 },
    { lat: 49.1817233, lng: 18.6354483 },
    { lat: 49.1818814, lng: 18.6355809 },
    { lat: 49.1819545, lng: 18.6356403 },
    { lat: 49.1820964, lng: 18.6358304 },
    { lat: 49.1822326, lng: 18.6359502 },
    { lat: 49.1823848, lng: 18.6361382 },
    { lat: 49.1825276, lng: 18.636449 },
    { lat: 49.1826098, lng: 18.6366807 },
    { lat: 49.1826635, lng: 18.6368413 },
    { lat: 49.1827213, lng: 18.6370613 },
    { lat: 49.1828031, lng: 18.6372184 },
    { lat: 49.1829254, lng: 18.6376118 },
    { lat: 49.1829842, lng: 18.6378687 },
    { lat: 49.1829855, lng: 18.6382525 },
    { lat: 49.1827558, lng: 18.6382604 },
    { lat: 49.1827434, lng: 18.6385795 },
    { lat: 49.1829315, lng: 18.638575 },
    { lat: 49.1828576, lng: 18.6387529 },
    { lat: 49.1827629, lng: 18.6389448 },
    { lat: 49.1826217, lng: 18.6394057 },
    { lat: 49.1825796, lng: 18.6398893 },
    { lat: 49.1826689, lng: 18.6401241 },
    { lat: 49.1826716, lng: 18.6403437 },
    { lat: 49.1826731, lng: 18.6407988 },
    { lat: 49.182549, lng: 18.6409458 },
    { lat: 49.1824736, lng: 18.6408834 },
    { lat: 49.1824471, lng: 18.6409584 },
    { lat: 49.1823911, lng: 18.6410511 },
    { lat: 49.1823378, lng: 18.6410444 },
    { lat: 49.1823282, lng: 18.6412471 },
    { lat: 49.1822989, lng: 18.6414151 },
    { lat: 49.1823376, lng: 18.6414226 },
    { lat: 49.1823978, lng: 18.641515 },
    { lat: 49.18231, lng: 18.6417533 },
    { lat: 49.182327, lng: 18.641926 },
    { lat: 49.1822043, lng: 18.6420775 },
    { lat: 49.182177, lng: 18.6421595 },
    { lat: 49.1822392, lng: 18.6423511 },
    { lat: 49.1823379, lng: 18.6427236 },
    { lat: 49.1822581, lng: 18.6430045 },
    { lat: 49.1822823, lng: 18.6431836 },
    { lat: 49.1823082, lng: 18.6432496 },
    { lat: 49.182383, lng: 18.6433186 },
    { lat: 49.1824313, lng: 18.6435059 },
    { lat: 49.1824272, lng: 18.6435994 },
    { lat: 49.1824547, lng: 18.6436263 },
    { lat: 49.182515, lng: 18.643851 },
    { lat: 49.1825254, lng: 18.6440894 },
    { lat: 49.1824183, lng: 18.6442815 },
    { lat: 49.1823976, lng: 18.644443 },
    { lat: 49.1824334, lng: 18.6446498 },
    { lat: 49.1823881, lng: 18.6448378 },
    { lat: 49.1824047, lng: 18.6450434 },
    { lat: 49.1824242, lng: 18.6452195 },
    { lat: 49.1824514, lng: 18.6452424 },
    { lat: 49.1824717, lng: 18.6453484 },
    { lat: 49.1824564, lng: 18.6454206 },
    { lat: 49.1824795, lng: 18.6454946 },
    { lat: 49.1825147, lng: 18.6457761 },
    { lat: 49.1825106, lng: 18.6458678 },
    { lat: 49.1825105, lng: 18.6462081 },
    { lat: 49.1824889, lng: 18.6464156 },
    { lat: 49.1825571, lng: 18.6465506 },
    { lat: 49.182545, lng: 18.6466667 },
    { lat: 49.1825935, lng: 18.6467689 },
    { lat: 49.1825446, lng: 18.6468726 },
    { lat: 49.1825968, lng: 18.6469837 },
    { lat: 49.1826687, lng: 18.6470419 },
    { lat: 49.1827313, lng: 18.6472425 },
    { lat: 49.1827033, lng: 18.6473505 },
    { lat: 49.1827239, lng: 18.6474637 },
    { lat: 49.182726, lng: 18.6475017 },
    { lat: 49.1827263, lng: 18.6476145 },
    { lat: 49.1826343, lng: 18.6477113 },
    { lat: 49.182646, lng: 18.6478879 },
    { lat: 49.1826754, lng: 18.647952 },
    { lat: 49.1828197, lng: 18.647763 },
    { lat: 49.1828584, lng: 18.6479792 },
    { lat: 49.1828568, lng: 18.6479942 },
    { lat: 49.1828611, lng: 18.6480744 },
    { lat: 49.1828539, lng: 18.6481481 },
    { lat: 49.1828087, lng: 18.6481703 },
    { lat: 49.1828428, lng: 18.6482598 },
    { lat: 49.1827631, lng: 18.6482855 },
    { lat: 49.1827279, lng: 18.648411 },
    { lat: 49.1827652, lng: 18.648433 },
    { lat: 49.1827476, lng: 18.6485066 },
    { lat: 49.1827187, lng: 18.6484903 },
    { lat: 49.1826712, lng: 18.6484068 },
    { lat: 49.1826047, lng: 18.6485563 },
    { lat: 49.182674, lng: 18.6486516 },
    { lat: 49.1826489, lng: 18.6488775 },
    { lat: 49.1826459, lng: 18.649159 },
    { lat: 49.1825943, lng: 18.6494639 },
    { lat: 49.1826333, lng: 18.6494551 },
    { lat: 49.1825587, lng: 18.649875 },
    { lat: 49.1826153, lng: 18.6503492 },
    { lat: 49.1825578, lng: 18.6512215 },
    { lat: 49.182555, lng: 18.6512522 },
    { lat: 49.1824906, lng: 18.6519952 },
    { lat: 49.1824135, lng: 18.6524741 },
    { lat: 49.1821989, lng: 18.6527461 },
    { lat: 49.1819341, lng: 18.6532777 },
    { lat: 49.1818448, lng: 18.6535721 },
    { lat: 49.1819091, lng: 18.6537018 },
    { lat: 49.1820043, lng: 18.6543927 },
    { lat: 49.1818125, lng: 18.6549419 },
    { lat: 49.1817327, lng: 18.6552853 },
    { lat: 49.1816406, lng: 18.6554213 },
    { lat: 49.1813076, lng: 18.6557375 },
    { lat: 49.1811931, lng: 18.6559344 },
    { lat: 49.1808067, lng: 18.656265 },
    { lat: 49.180405, lng: 18.6568142 },
    { lat: 49.1799293, lng: 18.6574562 },
    { lat: 49.1793867, lng: 18.6580887 },
    { lat: 49.1791125, lng: 18.6584249 },
    { lat: 49.1786572, lng: 18.6588152 },
    { lat: 49.1779448, lng: 18.6592037 },
    { lat: 49.1776305, lng: 18.6593824 },
    { lat: 49.1771269, lng: 18.6596672 },
    { lat: 49.1766197, lng: 18.6598848 },
    { lat: 49.1764113, lng: 18.6598672 },
    { lat: 49.1759671, lng: 18.659785 },
    { lat: 49.1758448, lng: 18.6595182 },
    { lat: 49.1757533, lng: 18.6595172 },
    { lat: 49.1757091, lng: 18.6596824 },
    { lat: 49.1755974, lng: 18.6598097 },
    { lat: 49.1751513, lng: 18.6598809 },
    { lat: 49.1749224, lng: 18.660138 },
    { lat: 49.174814, lng: 18.6600494 },
    { lat: 49.1747199, lng: 18.6601291 },
    { lat: 49.1746062, lng: 18.6600033 },
    { lat: 49.1744701, lng: 18.6602219 },
    { lat: 49.1742851, lng: 18.6604328 },
    { lat: 49.1742485, lng: 18.6606601 },
    { lat: 49.1739774, lng: 18.6608207 },
    { lat: 49.1737733, lng: 18.6610979 },
    { lat: 49.1735797, lng: 18.661214 },
    { lat: 49.1735656, lng: 18.6614778 },
    { lat: 49.1737733, lng: 18.6620343 },
    { lat: 49.1737104, lng: 18.6622749 },
    { lat: 49.1734932, lng: 18.6624083 },
    { lat: 49.1733801, lng: 18.6621072 },
    { lat: 49.1732425, lng: 18.6622979 },
    { lat: 49.1732426, lng: 18.6622319 },
    { lat: 49.173228, lng: 18.6622334 },
    { lat: 49.1731374, lng: 18.6621869 },
    { lat: 49.1731009, lng: 18.6621986 },
    { lat: 49.1730094, lng: 18.6621136 },
    { lat: 49.1730513, lng: 18.6616911 },
    { lat: 49.1730292, lng: 18.6615489 },
    { lat: 49.1728584, lng: 18.6610924 },
    { lat: 49.1728307, lng: 18.6608897 },
    { lat: 49.1728528, lng: 18.6606649 },
    { lat: 49.1728366, lng: 18.6606586 },
    { lat: 49.1728228, lng: 18.6605677 },
    { lat: 49.1726798, lng: 18.6604851 },
    { lat: 49.172729, lng: 18.6602455 },
    { lat: 49.1728132, lng: 18.6597698 },
    { lat: 49.1728955, lng: 18.65909 },
    { lat: 49.1730271, lng: 18.6588085 },
    { lat: 49.1730865, lng: 18.6584169 },
    { lat: 49.1730731, lng: 18.6580974 },
    { lat: 49.1730345, lng: 18.6574145 },
    { lat: 49.1729734, lng: 18.6573442 },
    { lat: 49.1727538, lng: 18.657674 },
    { lat: 49.1723813, lng: 18.6577077 },
    { lat: 49.1720253, lng: 18.6571836 },
    { lat: 49.1717455, lng: 18.6569651 },
    { lat: 49.1713083, lng: 18.6569963 },
    { lat: 49.1712267, lng: 18.6569029 },
    { lat: 49.1712979, lng: 18.6566091 },
    { lat: 49.1711653, lng: 18.656425 },
    { lat: 49.1707654, lng: 18.6562869 },
    { lat: 49.1706043, lng: 18.6559493 },
    { lat: 49.1704993, lng: 18.6553903 },
    { lat: 49.1696737, lng: 18.6536281 },
    { lat: 49.1686003, lng: 18.6521233 },
    { lat: 49.1674708, lng: 18.6509628 },
    { lat: 49.1671274, lng: 18.6513544 },
    { lat: 49.1661661, lng: 18.6513667 },
    { lat: 49.1664332, lng: 18.6503896 },
    { lat: 49.1662461, lng: 18.650133 },
    { lat: 49.1664351, lng: 18.6496152 },
    { lat: 49.1659417, lng: 18.6491803 },
    { lat: 49.1651796, lng: 18.6505025 },
    { lat: 49.1639471, lng: 18.6512071 },
    { lat: 49.1636229, lng: 18.6515326 },
    { lat: 49.1625086, lng: 18.6523829 },
    { lat: 49.1608666, lng: 18.653766 },
    { lat: 49.1607728, lng: 18.6538461 },
    { lat: 49.1606646, lng: 18.6541023 },
    { lat: 49.1601057, lng: 18.6546616 },
    { lat: 49.1597213, lng: 18.6549224 },
    { lat: 49.1595338, lng: 18.6543643 },
    { lat: 49.1588986, lng: 18.6544852 },
    { lat: 49.1585928, lng: 18.6545697 },
    { lat: 49.1577168, lng: 18.654827 },
    { lat: 49.1571961, lng: 18.6550267 },
    { lat: 49.156749, lng: 18.6551871 },
    { lat: 49.1559343, lng: 18.6554299 },
    { lat: 49.1555766, lng: 18.6557035 },
    { lat: 49.1553027, lng: 18.6559607 },
    { lat: 49.1553947, lng: 18.6561549 },
    { lat: 49.1558309, lng: 18.6570752 },
    { lat: 49.1561783, lng: 18.6573069 },
    { lat: 49.1564761, lng: 18.6575231 },
    { lat: 49.1572193, lng: 18.6582894 },
    { lat: 49.157456, lng: 18.6585412 },
    { lat: 49.1578346, lng: 18.6588361 },
    { lat: 49.1583385, lng: 18.6591715 },
    { lat: 49.1588016, lng: 18.6597795 },
    { lat: 49.1590401, lng: 18.6599818 },
    { lat: 49.1592589, lng: 18.6601725 },
    { lat: 49.1594956, lng: 18.6607022 },
    { lat: 49.1598067, lng: 18.6617877 },
    { lat: 49.1598319, lng: 18.6623244 },
    { lat: 49.1596475, lng: 18.6633146 },
    { lat: 49.1596388, lng: 18.6638951 },
    { lat: 49.159319, lng: 18.6647535 },
    { lat: 49.1595615, lng: 18.6656695 },
    { lat: 49.159691, lng: 18.6662222 },
    { lat: 49.1597669, lng: 18.6672952 },
    { lat: 49.1596118, lng: 18.6688591 },
    { lat: 49.1602828, lng: 18.6707961 },
    { lat: 49.1605132, lng: 18.6730422 },
    { lat: 49.1606439, lng: 18.6758966 },
    { lat: 49.1603496, lng: 18.6774332 },
    { lat: 49.1595679, lng: 18.6791997 },
    { lat: 49.1597922, lng: 18.6796878 },
    { lat: 49.1598664, lng: 18.680582 },
    { lat: 49.1590506, lng: 18.6820031 },
    { lat: 49.1595803, lng: 18.6832363 },
    { lat: 49.1600306, lng: 18.684053 },
    { lat: 49.1603331, lng: 18.6845675 },
    { lat: 49.1601623, lng: 18.6848378 },
    { lat: 49.1603474, lng: 18.685246 },
    { lat: 49.160697, lng: 18.6852482 },
    { lat: 49.1606596, lng: 18.6859496 },
    { lat: 49.1598612, lng: 18.6885576 },
    { lat: 49.159819, lng: 18.6887379 },
    { lat: 49.1597912, lng: 18.6888509 },
    { lat: 49.1597855, lng: 18.6888743 },
    { lat: 49.1596121, lng: 18.6896039 },
    { lat: 49.1593895, lng: 18.6910292 },
    { lat: 49.1595107, lng: 18.6926185 },
    { lat: 49.1591975, lng: 18.6932053 },
    { lat: 49.1590596, lng: 18.694377 },
    { lat: 49.1590053, lng: 18.6949695 },
    { lat: 49.15852, lng: 18.697122 },
    { lat: 49.1584213, lng: 18.6975413 },
    { lat: 49.1587408, lng: 18.6989408 },
    { lat: 49.1597049, lng: 18.6990426 },
    { lat: 49.1600436, lng: 18.6991564 },
    { lat: 49.1603292, lng: 18.699209 },
    { lat: 49.1605212, lng: 18.6992561 },
    { lat: 49.1610167, lng: 18.6994797 },
    { lat: 49.1615154, lng: 18.6998665 },
    { lat: 49.162042, lng: 18.7005742 },
    { lat: 49.162786, lng: 18.7009119 },
    { lat: 49.1636115, lng: 18.7025617 },
    { lat: 49.1657149, lng: 18.705386 },
    { lat: 49.1665958, lng: 18.7050581 },
    { lat: 49.1669357, lng: 18.7047231 },
    { lat: 49.1672626, lng: 18.7043959 },
    { lat: 49.1674762, lng: 18.7042346 },
    { lat: 49.1675955, lng: 18.7041429 },
    { lat: 49.1683246, lng: 18.7035348 },
    { lat: 49.1686155, lng: 18.7032966 },
    { lat: 49.168884, lng: 18.7030659 },
    { lat: 49.169717, lng: 18.7022035 },
    { lat: 49.1702837, lng: 18.7020206 },
    { lat: 49.1708584, lng: 18.7017113 },
    { lat: 49.1712339, lng: 18.7012614 },
    { lat: 49.1715483, lng: 18.7011742 },
    { lat: 49.1716866, lng: 18.7012704 },
    { lat: 49.1721893, lng: 18.701073 },
    { lat: 49.1725212, lng: 18.7008471 },
    { lat: 49.1731458, lng: 18.7005879 },
    { lat: 49.1735271, lng: 18.7005686 },
    { lat: 49.1737356, lng: 18.7005878 },
    { lat: 49.1745171, lng: 18.7008446 },
    { lat: 49.1750627, lng: 18.701118 },
    { lat: 49.1755542, lng: 18.7015894 },
    { lat: 49.1757986, lng: 18.701881 },
    { lat: 49.1767548, lng: 18.7039582 },
    { lat: 49.1772224, lng: 18.7034991 },
    { lat: 49.1775436, lng: 18.7033023 },
    { lat: 49.1776579, lng: 18.7031405 },
    { lat: 49.1778571, lng: 18.7031129 },
    { lat: 49.1780427, lng: 18.7029745 },
    { lat: 49.1776463, lng: 18.702425 },
    { lat: 49.1773661, lng: 18.7020765 },
    { lat: 49.1774836, lng: 18.7012014 },
    { lat: 49.1771213, lng: 18.7008962 },
    { lat: 49.1772806, lng: 18.7006406 },
    { lat: 49.1773095, lng: 18.6999053 },
    { lat: 49.177268, lng: 18.6996336 },
    { lat: 49.1773052, lng: 18.6993122 },
    { lat: 49.1773723, lng: 18.6988949 },
    { lat: 49.1777273, lng: 18.6977531 },
    { lat: 49.1777642, lng: 18.6976185 },
    { lat: 49.1779658, lng: 18.6969107 },
    { lat: 49.1780135, lng: 18.6967665 },
    { lat: 49.1781554, lng: 18.6963335 },
    { lat: 49.1782389, lng: 18.6959722 },
    { lat: 49.178286, lng: 18.6957534 },
    { lat: 49.178414, lng: 18.695033 },
    { lat: 49.1785025, lng: 18.6947442 },
    { lat: 49.1786235, lng: 18.6942154 },
    { lat: 49.178707, lng: 18.6937683 },
    { lat: 49.1789222, lng: 18.6934679 },
    { lat: 49.1792081, lng: 18.6928796 },
    { lat: 49.1794607, lng: 18.6924928 },
    { lat: 49.1796121, lng: 18.692112 },
    { lat: 49.179675, lng: 18.6917662 },
    { lat: 49.1797318, lng: 18.6913883 },
    { lat: 49.1799358, lng: 18.6913665 },
    { lat: 49.1799629, lng: 18.6911941 },
    { lat: 49.1799758, lng: 18.6911169 },
    { lat: 49.180007, lng: 18.6909195 },
    { lat: 49.1800493, lng: 18.6906511 },
    { lat: 49.1802232, lng: 18.690336 },
    { lat: 49.1800633, lng: 18.6896336 },
    { lat: 49.18084, lng: 18.6887327 },
    { lat: 49.1815551, lng: 18.6880707 },
    { lat: 49.1817712, lng: 18.6875085 },
    { lat: 49.1819715, lng: 18.68722 },
    { lat: 49.1820592, lng: 18.6869832 },
    { lat: 49.1822106, lng: 18.6857685 },
    { lat: 49.1822161, lng: 18.6855948 },
    { lat: 49.1822443, lng: 18.6848661 },
    { lat: 49.1825763, lng: 18.6841728 },
    { lat: 49.1828065, lng: 18.6836172 },
    { lat: 49.1830637, lng: 18.6828534 },
    { lat: 49.1833994, lng: 18.6820966 },
    { lat: 49.1834132, lng: 18.6820621 },
    { lat: 49.1837223, lng: 18.6813374 },
    { lat: 49.1838877, lng: 18.6810719 },
    { lat: 49.1839806, lng: 18.6807202 },
    { lat: 49.1835706, lng: 18.6804054 },
    { lat: 49.1831695, lng: 18.6801998 },
    { lat: 49.1829303, lng: 18.6799823 },
    { lat: 49.182432, lng: 18.6796441 },
    { lat: 49.1817624, lng: 18.6793915 },
    { lat: 49.1811272, lng: 18.6788323 },
    { lat: 49.1810488, lng: 18.6786732 },
    { lat: 49.1810572, lng: 18.6784905 },
    { lat: 49.1812492, lng: 18.6784315 },
    { lat: 49.1816142, lng: 18.6785539 },
    { lat: 49.1817434, lng: 18.6785449 },
    { lat: 49.181884, lng: 18.6783643 },
    { lat: 49.1819567, lng: 18.6782699 },
    { lat: 49.1819123, lng: 18.6779838 },
    { lat: 49.1819608, lng: 18.6777918 },
    { lat: 49.1819739, lng: 18.6777419 },
    { lat: 49.1812643, lng: 18.6756222 },
    { lat: 49.1806516, lng: 18.6754031 },
    { lat: 49.180491, lng: 18.6740905 },
    { lat: 49.180495, lng: 18.6735507 },
    { lat: 49.1806648, lng: 18.6730486 },
    { lat: 49.1807134, lng: 18.6719208 },
    { lat: 49.1808388, lng: 18.6714133 },
    { lat: 49.1809394, lng: 18.671224 },
    { lat: 49.181092, lng: 18.6711255 },
    { lat: 49.1795929, lng: 18.6681566 },
    { lat: 49.179016, lng: 18.6667627 },
    { lat: 49.1783831, lng: 18.6650062 },
    { lat: 49.178927, lng: 18.6642918 },
    { lat: 49.1795565, lng: 18.6630402 },
    { lat: 49.1797199, lng: 18.6627796 },
    { lat: 49.1802741, lng: 18.6622145 },
    { lat: 49.1806793, lng: 18.6616909 },
    { lat: 49.1807035, lng: 18.6616349 },
    { lat: 49.1807316, lng: 18.6615716 },
    { lat: 49.1810678, lng: 18.6608078 },
    { lat: 49.1812998, lng: 18.6601408 },
    { lat: 49.1816023, lng: 18.6585937 },
    { lat: 49.1824166, lng: 18.6573581 },
    { lat: 49.1835133, lng: 18.6556917 },
    { lat: 49.1835833, lng: 18.6555858 },
    { lat: 49.1836992, lng: 18.6554092 },
    { lat: 49.1855478, lng: 18.6514367 },
    { lat: 49.1893616, lng: 18.6487981 },
    { lat: 49.1894149, lng: 18.6487609 },
    { lat: 49.1903903, lng: 18.6468055 },
    { lat: 49.1907161, lng: 18.6452105 },
    { lat: 49.1907634, lng: 18.6435558 },
    { lat: 49.1908003, lng: 18.6435704 },
    { lat: 49.1907978, lng: 18.6434773 },
    { lat: 49.1908827, lng: 18.6426379 },
    { lat: 49.1909567, lng: 18.642461 },
    { lat: 49.1910037, lng: 18.6422612 },
    { lat: 49.1909906, lng: 18.6421208 },
    { lat: 49.1908563, lng: 18.6418652 },
    { lat: 49.1906105, lng: 18.6415458 },
    { lat: 49.1904156, lng: 18.6412119 },
    { lat: 49.190151, lng: 18.6408592 },
    { lat: 49.1901252, lng: 18.6407964 },
    { lat: 49.1900863, lng: 18.6407215 },
    { lat: 49.1900275, lng: 18.6404642 },
    { lat: 49.1899194, lng: 18.6400842 },
    { lat: 49.1898125, lng: 18.6397725 },
    { lat: 49.1896691, lng: 18.6393854 },
    { lat: 49.1892552, lng: 18.6386632 },
    { lat: 49.1892107, lng: 18.6384675 },
    { lat: 49.1892259, lng: 18.6384548 },
    { lat: 49.1891873, lng: 18.638173 },
    { lat: 49.1889539, lng: 18.6365833 },
    { lat: 49.1888719, lng: 18.6353629 },
    { lat: 49.1889195, lng: 18.6340937 },
    { lat: 49.1888674, lng: 18.6333675 },
    { lat: 49.1892119, lng: 18.6320835 },
    { lat: 49.1893725, lng: 18.6310558 },
    { lat: 49.18959, lng: 18.6302667 },
    { lat: 49.189603, lng: 18.6300271 },
    { lat: 49.1896062, lng: 18.6299585 },
    { lat: 49.1896066, lng: 18.6299448 },
    { lat: 49.1893674, lng: 18.6291405 },
    { lat: 49.1891136, lng: 18.6283946 },
    { lat: 49.1888184, lng: 18.6281272 },
    { lat: 49.1887378, lng: 18.6280528 },
    { lat: 49.1884797, lng: 18.6278645 },
    { lat: 49.1884385, lng: 18.6278322 },
    { lat: 49.188361, lng: 18.6277527 },
    { lat: 49.1880883, lng: 18.6274613 },
    { lat: 49.1879888, lng: 18.626675 },
    { lat: 49.1878016, lng: 18.626315 },
    { lat: 49.1876972, lng: 18.6258552 },
    { lat: 49.1874289, lng: 18.6255215 },
    { lat: 49.187251, lng: 18.6253748 },
    { lat: 49.1870202, lng: 18.6250043 },
    { lat: 49.1867688, lng: 18.6243274 },
    { lat: 49.1866144, lng: 18.6236954 },
    { lat: 49.1866721, lng: 18.6230454 },
    { lat: 49.1867087, lng: 18.6224366 },
    { lat: 49.1867378, lng: 18.6221183 },
    { lat: 49.1866729, lng: 18.6214723 },
    { lat: 49.1864904, lng: 18.6210324 },
    { lat: 49.1865549, lng: 18.6206566 },
    { lat: 49.1864679, lng: 18.6202301 },
    { lat: 49.1863811, lng: 18.6199323 },
    { lat: 49.1863627, lng: 18.619569 },
    { lat: 49.186295, lng: 18.6188058 },
    { lat: 49.1860844, lng: 18.6180283 },
    { lat: 49.1863308, lng: 18.6178272 },
    { lat: 49.1864265, lng: 18.6177396 },
    { lat: 49.1865741, lng: 18.6176088 },
  ],
  Ovčiarsko: [
    { lat: 49.2347852, lng: 18.672682 },
    { lat: 49.2346684, lng: 18.6723456 },
    { lat: 49.2346018, lng: 18.6717689 },
    { lat: 49.2344739, lng: 18.6712495 },
    { lat: 49.2343723, lng: 18.6707138 },
    { lat: 49.234214, lng: 18.6701729 },
    { lat: 49.2340875, lng: 18.6697641 },
    { lat: 49.2339625, lng: 18.6694024 },
    { lat: 49.2339341, lng: 18.6690793 },
    { lat: 49.2339695, lng: 18.6685788 },
    { lat: 49.2341493, lng: 18.6681178 },
    { lat: 49.2341439, lng: 18.6677157 },
    { lat: 49.2342242, lng: 18.6671653 },
    { lat: 49.2342411, lng: 18.6665454 },
    { lat: 49.2342356, lng: 18.6661639 },
    { lat: 49.2342237, lng: 18.6657475 },
    { lat: 49.2341606, lng: 18.6648762 },
    { lat: 49.2339864, lng: 18.6642683 },
    { lat: 49.2338365, lng: 18.6637798 },
    { lat: 49.2337883, lng: 18.6634678 },
    { lat: 49.233645, lng: 18.6624426 },
    { lat: 49.2337225, lng: 18.6618574 },
    { lat: 49.2335802, lng: 18.6610436 },
    { lat: 49.2335452, lng: 18.6606188 },
    { lat: 49.2334958, lng: 18.6604084 },
    { lat: 49.2334423, lng: 18.6596756 },
    { lat: 49.233489, lng: 18.659344 },
    { lat: 49.2332535, lng: 18.6585373 },
    { lat: 49.2331935, lng: 18.6583188 },
    { lat: 49.2331967, lng: 18.6578926 },
    { lat: 49.2331037, lng: 18.657453 },
    { lat: 49.2330413, lng: 18.6564044 },
    { lat: 49.2328638, lng: 18.6559706 },
    { lat: 49.2328116, lng: 18.6558782 },
    { lat: 49.2327606, lng: 18.6557892 },
    { lat: 49.2327158, lng: 18.6557099 },
    { lat: 49.2326821, lng: 18.6556509 },
    { lat: 49.2326088, lng: 18.6555196 },
    { lat: 49.2324657, lng: 18.6552674 },
    { lat: 49.2321115, lng: 18.6546259 },
    { lat: 49.231902, lng: 18.6540358 },
    { lat: 49.2317891, lng: 18.6538613 },
    { lat: 49.231274, lng: 18.6533779 },
    { lat: 49.2310332, lng: 18.6529844 },
    { lat: 49.2305645, lng: 18.6526079 },
    { lat: 49.2300523, lng: 18.6524142 },
    { lat: 49.2291255, lng: 18.6518222 },
    { lat: 49.2287643, lng: 18.6514094 },
    { lat: 49.2281688, lng: 18.6508789 },
    { lat: 49.2278198, lng: 18.6506128 },
    { lat: 49.227623, lng: 18.6505386 },
    { lat: 49.227258, lng: 18.6506523 },
    { lat: 49.2267943, lng: 18.6508377 },
    { lat: 49.2258517, lng: 18.651074 },
    { lat: 49.2248153, lng: 18.6513313 },
    { lat: 49.2237799, lng: 18.6514354 },
    { lat: 49.2223487, lng: 18.650943 },
    { lat: 49.2214841, lng: 18.6499726 },
    { lat: 49.2210791, lng: 18.650821 },
    { lat: 49.2207437, lng: 18.6510028 },
    { lat: 49.220382, lng: 18.6507953 },
    { lat: 49.2201181, lng: 18.6506039 },
    { lat: 49.219639, lng: 18.6501606 },
    { lat: 49.2192666, lng: 18.6496276 },
    { lat: 49.2192579, lng: 18.6496099 },
    { lat: 49.2187683, lng: 18.6501599 },
    { lat: 49.2186162, lng: 18.6502671 },
    { lat: 49.2182921, lng: 18.652111 },
    { lat: 49.218, lng: 18.6526399 },
    { lat: 49.2174088, lng: 18.65332 },
    { lat: 49.2169431, lng: 18.653976 },
    { lat: 49.216236, lng: 18.6544479 },
    { lat: 49.2156962, lng: 18.6545571 },
    { lat: 49.2147231, lng: 18.6545189 },
    { lat: 49.2142787, lng: 18.6544988 },
    { lat: 49.2139914, lng: 18.654799 },
    { lat: 49.2129049, lng: 18.6553664 },
    { lat: 49.2121913, lng: 18.6557096 },
    { lat: 49.2121947, lng: 18.6557293 },
    { lat: 49.2124186, lng: 18.6575298 },
    { lat: 49.2123946, lng: 18.6576745 },
    { lat: 49.212133, lng: 18.6584185 },
    { lat: 49.2121459, lng: 18.6592595 },
    { lat: 49.2123477, lng: 18.6597057 },
    { lat: 49.2123594, lng: 18.6599445 },
    { lat: 49.2124124, lng: 18.6601609 },
    { lat: 49.2124146, lng: 18.6603099 },
    { lat: 49.2124553, lng: 18.660842 },
    { lat: 49.2125045, lng: 18.6613024 },
    { lat: 49.2124773, lng: 18.66181 },
    { lat: 49.2124295, lng: 18.6619519 },
    { lat: 49.2124658, lng: 18.6620221 },
    { lat: 49.2125053, lng: 18.6622776 },
    { lat: 49.2123981, lng: 18.6626577 },
    { lat: 49.2123833, lng: 18.6630163 },
    { lat: 49.2122694, lng: 18.6633167 },
    { lat: 49.2122351, lng: 18.6635825 },
    { lat: 49.2122496, lng: 18.6642227 },
    { lat: 49.2122407, lng: 18.6645023 },
    { lat: 49.2121876, lng: 18.6647134 },
    { lat: 49.2122438, lng: 18.6649441 },
    { lat: 49.2121755, lng: 18.6651452 },
    { lat: 49.2121474, lng: 18.6652302 },
    { lat: 49.212103, lng: 18.6653908 },
    { lat: 49.2120374, lng: 18.6656264 },
    { lat: 49.2120161, lng: 18.6656915 },
    { lat: 49.2120026, lng: 18.665731 },
    { lat: 49.2120006, lng: 18.6657381 },
    { lat: 49.2119968, lng: 18.665859 },
    { lat: 49.2119941, lng: 18.6659557 },
    { lat: 49.211965, lng: 18.6659816 },
    { lat: 49.2119499, lng: 18.6659947 },
    { lat: 49.2119201, lng: 18.6661336 },
    { lat: 49.2119079, lng: 18.6662686 },
    { lat: 49.2118461, lng: 18.6664638 },
    { lat: 49.2118182, lng: 18.6665736 },
    { lat: 49.2118098, lng: 18.6666071 },
    { lat: 49.2117463, lng: 18.6668992 },
    { lat: 49.2117114, lng: 18.6670084 },
    { lat: 49.2116847, lng: 18.6670345 },
    { lat: 49.2116645, lng: 18.6670828 },
    { lat: 49.2116226, lng: 18.6671824 },
    { lat: 49.211602, lng: 18.6672236 },
    { lat: 49.2116012, lng: 18.6672255 },
    { lat: 49.2115821, lng: 18.6673151 },
    { lat: 49.2115759, lng: 18.6674089 },
    { lat: 49.2115828, lng: 18.6674312 },
    { lat: 49.2116166, lng: 18.6674147 },
    { lat: 49.2116203, lng: 18.6674175 },
    { lat: 49.2116582, lng: 18.66745 },
    { lat: 49.2117265, lng: 18.6675484 },
    { lat: 49.2117584, lng: 18.6676202 },
    { lat: 49.2117824, lng: 18.6676886 },
    { lat: 49.2118009, lng: 18.6677405 },
    { lat: 49.2118627, lng: 18.6678223 },
    { lat: 49.2119296, lng: 18.6679118 },
    { lat: 49.2119616, lng: 18.6679861 },
    { lat: 49.2119851, lng: 18.6681117 },
    { lat: 49.2120012, lng: 18.6681183 },
    { lat: 49.2120739, lng: 18.668148 },
    { lat: 49.2120923, lng: 18.6681558 },
    { lat: 49.2122367, lng: 18.6684554 },
    { lat: 49.2122572, lng: 18.6684984 },
    { lat: 49.2123033, lng: 18.6685609 },
    { lat: 49.2123636, lng: 18.6685938 },
    { lat: 49.212375, lng: 18.6685946 },
    { lat: 49.2125052, lng: 18.6686031 },
    { lat: 49.2125145, lng: 18.6686099 },
    { lat: 49.2125816, lng: 18.6686633 },
    { lat: 49.212665, lng: 18.6687915 },
    { lat: 49.2126815, lng: 18.6688674 },
    { lat: 49.2126931, lng: 18.6688948 },
    { lat: 49.2127019, lng: 18.6689147 },
    { lat: 49.2127172, lng: 18.6689489 },
    { lat: 49.2128539, lng: 18.6688036 },
    { lat: 49.2129288, lng: 18.6687453 },
    { lat: 49.2130314, lng: 18.6686822 },
    { lat: 49.2131284, lng: 18.6686399 },
    { lat: 49.2138851, lng: 18.6683638 },
    { lat: 49.2139265, lng: 18.6683565 },
    { lat: 49.2141963, lng: 18.6685967 },
    { lat: 49.214255, lng: 18.6686609 },
    { lat: 49.2142709, lng: 18.6687286 },
    { lat: 49.2142853, lng: 18.6687863 },
    { lat: 49.2142881, lng: 18.6688166 },
    { lat: 49.2143281, lng: 18.669342 },
    { lat: 49.2144599, lng: 18.6699123 },
    { lat: 49.2145641, lng: 18.6700059 },
    { lat: 49.2147257, lng: 18.6704203 },
    { lat: 49.2148421, lng: 18.6705711 },
    { lat: 49.2151184, lng: 18.6708307 },
    { lat: 49.2153181, lng: 18.6710864 },
    { lat: 49.2154571, lng: 18.6713287 },
    { lat: 49.2157065, lng: 18.6718448 },
    { lat: 49.2163815, lng: 18.6728349 },
    { lat: 49.2163899, lng: 18.6728238 },
    { lat: 49.216431, lng: 18.6727693 },
    { lat: 49.2165289, lng: 18.6726375 },
    { lat: 49.2166562, lng: 18.6724663 },
    { lat: 49.2167826, lng: 18.6722956 },
    { lat: 49.2168351, lng: 18.672225 },
    { lat: 49.2169292, lng: 18.6721899 },
    { lat: 49.2170896, lng: 18.6721312 },
    { lat: 49.2172488, lng: 18.6720716 },
    { lat: 49.2173219, lng: 18.6720449 },
    { lat: 49.217416, lng: 18.6720337 },
    { lat: 49.2175875, lng: 18.6720136 },
    { lat: 49.2176715, lng: 18.6720033 },
    { lat: 49.2178272, lng: 18.6719848 },
    { lat: 49.2181102, lng: 18.6718526 },
    { lat: 49.2182582, lng: 18.6724577 },
    { lat: 49.218333, lng: 18.6731051 },
    { lat: 49.2183596, lng: 18.6739084 },
    { lat: 49.2183904, lng: 18.6748924 },
    { lat: 49.218443, lng: 18.6751875 },
    { lat: 49.218718, lng: 18.6755656 },
    { lat: 49.2192284, lng: 18.6761558 },
    { lat: 49.2196678, lng: 18.6765112 },
    { lat: 49.2199901, lng: 18.6770206 },
    { lat: 49.2203524, lng: 18.6777112 },
    { lat: 49.220668, lng: 18.678241 },
    { lat: 49.2209449, lng: 18.6784648 },
    { lat: 49.2208526, lng: 18.6789596 },
    { lat: 49.2208365, lng: 18.6791239 },
    { lat: 49.2207871, lng: 18.6793645 },
    { lat: 49.2207175, lng: 18.6795646 },
    { lat: 49.220333, lng: 18.680056 },
    { lat: 49.2200769, lng: 18.680778 },
    { lat: 49.219983, lng: 18.6812247 },
    { lat: 49.2204944, lng: 18.681275 },
    { lat: 49.2207103, lng: 18.6813935 },
    { lat: 49.2208668, lng: 18.6814086 },
    { lat: 49.220921, lng: 18.6814102 },
    { lat: 49.2212374, lng: 18.6812717 },
    { lat: 49.2213313, lng: 18.6812151 },
    { lat: 49.2217065, lng: 18.6813165 },
    { lat: 49.2219808, lng: 18.6813654 },
    { lat: 49.2224026, lng: 18.6815343 },
    { lat: 49.2226973, lng: 18.6816914 },
    { lat: 49.2230791, lng: 18.681897 },
    { lat: 49.2233874, lng: 18.6821185 },
    { lat: 49.2246241, lng: 18.6824755 },
    { lat: 49.2252751, lng: 18.6826091 },
    { lat: 49.2256847, lng: 18.6834488 },
    { lat: 49.2260527, lng: 18.6841796 },
    { lat: 49.2261674, lng: 18.6844726 },
    { lat: 49.2263965, lng: 18.6845869 },
    { lat: 49.2266781, lng: 18.6854778 },
    { lat: 49.226932, lng: 18.6858886 },
    { lat: 49.2280561, lng: 18.6861383 },
    { lat: 49.2290431, lng: 18.686358 },
    { lat: 49.2294903, lng: 18.6866285 },
    { lat: 49.2300512, lng: 18.6869586 },
    { lat: 49.2310583, lng: 18.6874959 },
    { lat: 49.2326461, lng: 18.6880481 },
    { lat: 49.2326518, lng: 18.6880543 },
    { lat: 49.232894, lng: 18.6878344 },
    { lat: 49.2330835, lng: 18.6876624 },
    { lat: 49.2332447, lng: 18.6872962 },
    { lat: 49.2334057, lng: 18.68693 },
    { lat: 49.2335068, lng: 18.686709 },
    { lat: 49.2336081, lng: 18.6864883 },
    { lat: 49.2339709, lng: 18.6859299 },
    { lat: 49.2343301, lng: 18.6853607 },
    { lat: 49.2345762, lng: 18.6852821 },
    { lat: 49.2348236, lng: 18.6852271 },
    { lat: 49.2349804, lng: 18.6850563 },
    { lat: 49.2351284, lng: 18.6850623 },
    { lat: 49.2352284, lng: 18.6849904 },
    { lat: 49.2352262, lng: 18.6849719 },
    { lat: 49.2351456, lng: 18.6846794 },
    { lat: 49.2348167, lng: 18.6836409 },
    { lat: 49.2346206, lng: 18.6828927 },
    { lat: 49.2342648, lng: 18.6816854 },
    { lat: 49.2344888, lng: 18.6810567 },
    { lat: 49.2347792, lng: 18.6802779 },
    { lat: 49.2347209, lng: 18.678724 },
    { lat: 49.2347574, lng: 18.677966 },
    { lat: 49.2347471, lng: 18.676531 },
    { lat: 49.2346959, lng: 18.6754305 },
    { lat: 49.2346374, lng: 18.674493 },
    { lat: 49.2346396, lng: 18.6731258 },
    { lat: 49.2345399, lng: 18.6727544 },
    { lat: 49.2347852, lng: 18.672682 },
  ],
  PaštinaZávada: [
    { lat: 49.207586, lng: 18.611883 },
    { lat: 49.207246, lng: 18.611673 },
    { lat: 49.206249, lng: 18.610848 },
    { lat: 49.20596, lng: 18.610398 },
    { lat: 49.205152, lng: 18.61008 },
    { lat: 49.204061, lng: 18.60988 },
    { lat: 49.203409, lng: 18.60992 },
    { lat: 49.203092, lng: 18.609869 },
    { lat: 49.2028, lng: 18.609918 },
    { lat: 49.201725, lng: 18.609313 },
    { lat: 49.201565, lng: 18.609269 },
    { lat: 49.201421, lng: 18.609165 },
    { lat: 49.2013, lng: 18.6091 },
    { lat: 49.200907, lng: 18.608858 },
    { lat: 49.200517, lng: 18.60901 },
    { lat: 49.2002978, lng: 18.6088676 },
    { lat: 49.200025, lng: 18.608629 },
    { lat: 49.199759, lng: 18.608584 },
    { lat: 49.19943, lng: 18.608601 },
    { lat: 49.198925, lng: 18.608314 },
    { lat: 49.198428, lng: 18.607483 },
    { lat: 49.19806, lng: 18.606909 },
    { lat: 49.197917, lng: 18.606644 },
    { lat: 49.197713, lng: 18.60623 },
    { lat: 49.197541, lng: 18.606073 },
    { lat: 49.19737, lng: 18.605276 },
    { lat: 49.196913, lng: 18.604908 },
    { lat: 49.196501, lng: 18.604767 },
    { lat: 49.195927, lng: 18.604315 },
    { lat: 49.195347, lng: 18.604567 },
    { lat: 49.194667, lng: 18.604778 },
    { lat: 49.194479, lng: 18.603881 },
    { lat: 49.194308, lng: 18.603245 },
    { lat: 49.194025, lng: 18.602688 },
    { lat: 49.193802, lng: 18.601995 },
    { lat: 49.19366, lng: 18.601568 },
    { lat: 49.193195, lng: 18.600475 },
    { lat: 49.193196, lng: 18.600425 },
    { lat: 49.192262, lng: 18.600184 },
    { lat: 49.19179, lng: 18.600182 },
    { lat: 49.191183, lng: 18.600341 },
    { lat: 49.190575, lng: 18.601715 },
    { lat: 49.190482, lng: 18.602154 },
    { lat: 49.190336, lng: 18.602539 },
    { lat: 49.189994, lng: 18.602994 },
    { lat: 49.18949, lng: 18.603415 },
    { lat: 49.189478, lng: 18.603389 },
    { lat: 49.189283, lng: 18.602625 },
    { lat: 49.189059, lng: 18.602224 },
    { lat: 49.188484, lng: 18.601769 },
    { lat: 49.188256, lng: 18.601371 },
    { lat: 49.188028, lng: 18.601137 },
    { lat: 49.187775, lng: 18.600998 },
    { lat: 49.187428, lng: 18.60084 },
    { lat: 49.187096, lng: 18.600725 },
    { lat: 49.187002, lng: 18.600718 },
    { lat: 49.18699, lng: 18.601044 },
    { lat: 49.187112, lng: 18.602051 },
    { lat: 49.187159, lng: 18.602444 },
    { lat: 49.187198, lng: 18.603029 },
    { lat: 49.187327, lng: 18.603391 },
    { lat: 49.187267, lng: 18.603713 },
    { lat: 49.186985, lng: 18.604054 },
    { lat: 49.186765, lng: 18.604406 },
    { lat: 49.18691, lng: 18.604554 },
    { lat: 49.18703, lng: 18.604614 },
    { lat: 49.187031, lng: 18.605432 },
    { lat: 49.186971, lng: 18.606038 },
    { lat: 49.18669, lng: 18.606702 },
    { lat: 49.186421, lng: 18.607079 },
    { lat: 49.18644, lng: 18.607609 },
    { lat: 49.186289, lng: 18.607928 },
    { lat: 49.186001, lng: 18.608112 },
    { lat: 49.185901, lng: 18.608216 },
    { lat: 49.185799, lng: 18.608816 },
    { lat: 49.185674, lng: 18.609173 },
    { lat: 49.185508, lng: 18.60941 },
    { lat: 49.185156, lng: 18.609856 },
    { lat: 49.1852891, lng: 18.6102719 },
    { lat: 49.1853169, lng: 18.6103272 },
    { lat: 49.1853881, lng: 18.6104784 },
    { lat: 49.1854372, lng: 18.6110801 },
    { lat: 49.1856412, lng: 18.6116915 },
    { lat: 49.1856793, lng: 18.6118126 },
    { lat: 49.1857207, lng: 18.6121838 },
    { lat: 49.1860432, lng: 18.6137665 },
    { lat: 49.1861442, lng: 18.6140972 },
    { lat: 49.1860692, lng: 18.6149541 },
    { lat: 49.1859954, lng: 18.6155596 },
    { lat: 49.1858209, lng: 18.6163849 },
    { lat: 49.1859024, lng: 18.6165003 },
    { lat: 49.1861183, lng: 18.6169331 },
    { lat: 49.1865741, lng: 18.6176088 },
    { lat: 49.1865878, lng: 18.6175725 },
    { lat: 49.1867838, lng: 18.6175438 },
    { lat: 49.1873049, lng: 18.6182031 },
    { lat: 49.1883376, lng: 18.6189175 },
    { lat: 49.1888386, lng: 18.6189429 },
    { lat: 49.1888881, lng: 18.619955 },
    { lat: 49.1890443, lng: 18.6203232 },
    { lat: 49.1892816, lng: 18.6208814 },
    { lat: 49.1892151, lng: 18.6217305 },
    { lat: 49.1893821, lng: 18.6219654 },
    { lat: 49.1898809, lng: 18.6219677 },
    { lat: 49.1901912, lng: 18.621865 },
    { lat: 49.1904817, lng: 18.6217969 },
    { lat: 49.1907988, lng: 18.6219061 },
    { lat: 49.1910247, lng: 18.6221229 },
    { lat: 49.1910932, lng: 18.6224771 },
    { lat: 49.1910394, lng: 18.6228425 },
    { lat: 49.1911919, lng: 18.6234597 },
    { lat: 49.1912389, lng: 18.6239824 },
    { lat: 49.1912792, lng: 18.6244408 },
    { lat: 49.1916103, lng: 18.6250484 },
    { lat: 49.1916253, lng: 18.6250739 },
    { lat: 49.1920563, lng: 18.6254178 },
    { lat: 49.1924849, lng: 18.6259718 },
    { lat: 49.1926475, lng: 18.6265041 },
    { lat: 49.1928327, lng: 18.627057 },
    { lat: 49.1929182, lng: 18.6274553 },
    { lat: 49.1930584, lng: 18.6281392 },
    { lat: 49.1930592, lng: 18.6281781 },
    { lat: 49.1931506, lng: 18.6284336 },
    { lat: 49.1932492, lng: 18.6287358 },
    { lat: 49.1933759, lng: 18.6292126 },
    { lat: 49.1937908, lng: 18.629587 },
    { lat: 49.194334, lng: 18.630132 },
    { lat: 49.1948435, lng: 18.6305755 },
    { lat: 49.1949454, lng: 18.6306649 },
    { lat: 49.1952702, lng: 18.6309651 },
    { lat: 49.195589, lng: 18.631231 },
    { lat: 49.1957927, lng: 18.6313731 },
    { lat: 49.1958953, lng: 18.6315594 },
    { lat: 49.196027, lng: 18.6318319 },
    { lat: 49.1963961, lng: 18.6323686 },
    { lat: 49.1967607, lng: 18.6328607 },
    { lat: 49.1974882, lng: 18.6338402 },
    { lat: 49.1975187, lng: 18.6338631 },
    { lat: 49.1976398, lng: 18.634015 },
    { lat: 49.1982908, lng: 18.6349124 },
    { lat: 49.1983437, lng: 18.634956 },
    { lat: 49.1985746, lng: 18.6350763 },
    { lat: 49.1989252, lng: 18.6352201 },
    { lat: 49.199086, lng: 18.6352755 },
    { lat: 49.1993902, lng: 18.6353352 },
    { lat: 49.1994859, lng: 18.6353533 },
    { lat: 49.1996219, lng: 18.6353858 },
    { lat: 49.1998314, lng: 18.6354457 },
    { lat: 49.2001726, lng: 18.6356487 },
    { lat: 49.2004375, lng: 18.6357955 },
    { lat: 49.2005347, lng: 18.6358632 },
    { lat: 49.2007353, lng: 18.6361128 },
    { lat: 49.2008664, lng: 18.6363501 },
    { lat: 49.201346, lng: 18.6369304 },
    { lat: 49.2014467, lng: 18.6371051 },
    { lat: 49.2016243, lng: 18.6374146 },
    { lat: 49.2019371, lng: 18.6378029 },
    { lat: 49.2020163, lng: 18.637871 },
    { lat: 49.2023102, lng: 18.6382637 },
    { lat: 49.2026281, lng: 18.6384123 },
    { lat: 49.202876, lng: 18.6386207 },
    { lat: 49.203014, lng: 18.6387136 },
    { lat: 49.2034631, lng: 18.6386981 },
    { lat: 49.2037003, lng: 18.6387243 },
    { lat: 49.2042272, lng: 18.6387084 },
    { lat: 49.2044347, lng: 18.6387084 },
    { lat: 49.2045571, lng: 18.6387231 },
    { lat: 49.2046485, lng: 18.6387439 },
    { lat: 49.2050663, lng: 18.63905 },
    { lat: 49.2050573, lng: 18.6390728 },
    { lat: 49.2054054, lng: 18.639232 },
    { lat: 49.2061474, lng: 18.6402492 },
    { lat: 49.2064903, lng: 18.6405707 },
    { lat: 49.2068698, lng: 18.6409209 },
    { lat: 49.2067869, lng: 18.6421639 },
    { lat: 49.2067853, lng: 18.6422168 },
    { lat: 49.2067829, lng: 18.6422795 },
    { lat: 49.2067706, lng: 18.6423236 },
    { lat: 49.2067288, lng: 18.6426007 },
    { lat: 49.2066286, lng: 18.6432179 },
    { lat: 49.2071126, lng: 18.6438996 },
    { lat: 49.2071819, lng: 18.6441017 },
    { lat: 49.2074756, lng: 18.6449187 },
    { lat: 49.2077471, lng: 18.6455914 },
    { lat: 49.2076661, lng: 18.6459574 },
    { lat: 49.207351, lng: 18.6473709 },
    { lat: 49.2069682, lng: 18.6482759 },
    { lat: 49.2073959, lng: 18.6490447 },
    { lat: 49.2074161, lng: 18.6490876 },
    { lat: 49.2075382, lng: 18.6493715 },
    { lat: 49.2075817, lng: 18.6494661 },
    { lat: 49.2076915, lng: 18.6497486 },
    { lat: 49.2078624, lng: 18.6502527 },
    { lat: 49.208053, lng: 18.6508264 },
    { lat: 49.208063, lng: 18.6508663 },
    { lat: 49.2079952, lng: 18.6509248 },
    { lat: 49.2079024, lng: 18.6512825 },
    { lat: 49.2079509, lng: 18.6523222 },
    { lat: 49.2079717, lng: 18.6525908 },
    { lat: 49.2079783, lng: 18.6526293 },
    { lat: 49.2079983, lng: 18.6529226 },
    { lat: 49.2080107, lng: 18.6530495 },
    { lat: 49.2080126, lng: 18.653085 },
    { lat: 49.2080481, lng: 18.6537092 },
    { lat: 49.2081301, lng: 18.6544724 },
    { lat: 49.208483, lng: 18.65517 },
    { lat: 49.2087528, lng: 18.655408 },
    { lat: 49.2087373, lng: 18.6554604 },
    { lat: 49.2089505, lng: 18.6554615 },
    { lat: 49.209166, lng: 18.6554388 },
    { lat: 49.2093203, lng: 18.6554147 },
    { lat: 49.2096138, lng: 18.6553572 },
    { lat: 49.2096163, lng: 18.6553205 },
    { lat: 49.2099008, lng: 18.6552612 },
    { lat: 49.2100791, lng: 18.6552637 },
    { lat: 49.2103291, lng: 18.6552999 },
    { lat: 49.2103199, lng: 18.6553801 },
    { lat: 49.2104867, lng: 18.6554413 },
    { lat: 49.2106465, lng: 18.655541 },
    { lat: 49.2108462, lng: 18.6556697 },
    { lat: 49.2110484, lng: 18.6557843 },
    { lat: 49.211094, lng: 18.6557931 },
    { lat: 49.2113164, lng: 18.6558598 },
    { lat: 49.2115691, lng: 18.6558841 },
    { lat: 49.2116957, lng: 18.6558699 },
    { lat: 49.212045, lng: 18.6557366 },
    { lat: 49.2121947, lng: 18.6557293 },
    { lat: 49.2121913, lng: 18.6557096 },
    { lat: 49.2129049, lng: 18.6553664 },
    { lat: 49.2139914, lng: 18.654799 },
    { lat: 49.2142787, lng: 18.6544988 },
    { lat: 49.2147231, lng: 18.6545189 },
    { lat: 49.2156962, lng: 18.6545571 },
    { lat: 49.216236, lng: 18.6544479 },
    { lat: 49.2169431, lng: 18.653976 },
    { lat: 49.2174088, lng: 18.65332 },
    { lat: 49.218, lng: 18.6526399 },
    { lat: 49.2182921, lng: 18.652111 },
    { lat: 49.2186162, lng: 18.6502671 },
    { lat: 49.2187683, lng: 18.6501599 },
    { lat: 49.2192579, lng: 18.6496099 },
    { lat: 49.2192666, lng: 18.6496276 },
    { lat: 49.219639, lng: 18.6501606 },
    { lat: 49.2201181, lng: 18.6506039 },
    { lat: 49.220382, lng: 18.6507953 },
    { lat: 49.2207437, lng: 18.6510028 },
    { lat: 49.2210791, lng: 18.650821 },
    { lat: 49.2214841, lng: 18.6499726 },
    { lat: 49.2212052, lng: 18.6492407 },
    { lat: 49.2208115, lng: 18.6489434 },
    { lat: 49.2202689, lng: 18.6485375 },
    { lat: 49.2202536, lng: 18.6485026 },
    { lat: 49.2201576, lng: 18.6482886 },
    { lat: 49.220118, lng: 18.6482005 },
    { lat: 49.2200644, lng: 18.6480815 },
    { lat: 49.2199952, lng: 18.6479256 },
    { lat: 49.2197959, lng: 18.647465 },
    { lat: 49.2196077, lng: 18.6469164 },
    { lat: 49.2195946, lng: 18.6468794 },
    { lat: 49.2195132, lng: 18.6466399 },
    { lat: 49.2193201, lng: 18.6456835 },
    { lat: 49.2192291, lng: 18.6449029 },
    { lat: 49.2192237, lng: 18.6448632 },
    { lat: 49.2191891, lng: 18.6444024 },
    { lat: 49.2190187, lng: 18.6442507 },
    { lat: 49.2189788, lng: 18.6441586 },
    { lat: 49.2188706, lng: 18.6439057 },
    { lat: 49.2182353, lng: 18.6433259 },
    { lat: 49.2182277, lng: 18.6416111 },
    { lat: 49.2182288, lng: 18.6413747 },
    { lat: 49.2183672, lng: 18.6399498 },
    { lat: 49.2185834, lng: 18.6379244 },
    { lat: 49.2186816, lng: 18.6367806 },
    { lat: 49.2187897, lng: 18.6360117 },
    { lat: 49.2187073, lng: 18.6355475 },
    { lat: 49.2188319, lng: 18.6352374 },
    { lat: 49.2188419, lng: 18.6347671 },
    { lat: 49.2188235, lng: 18.6341052 },
    { lat: 49.2188204, lng: 18.6340032 },
    { lat: 49.2191209, lng: 18.6330593 },
    { lat: 49.2192564, lng: 18.6319598 },
    { lat: 49.2189236, lng: 18.6317229 },
    { lat: 49.2181506, lng: 18.6309941 },
    { lat: 49.2177221, lng: 18.6303397 },
    { lat: 49.2171282, lng: 18.6290955 },
    { lat: 49.2169756, lng: 18.6284305 },
    { lat: 49.2167385, lng: 18.6274526 },
    { lat: 49.216587, lng: 18.6269151 },
    { lat: 49.2165049, lng: 18.6268341 },
    { lat: 49.21645, lng: 18.6267799 },
    { lat: 49.2163715, lng: 18.6267023 },
    { lat: 49.2163346, lng: 18.6266665 },
    { lat: 49.2163121, lng: 18.6266437 },
    { lat: 49.2162787, lng: 18.6266114 },
    { lat: 49.216149, lng: 18.6264828 },
    { lat: 49.2158885, lng: 18.6261443 },
    { lat: 49.2156729, lng: 18.6259928 },
    { lat: 49.2150824, lng: 18.6257898 },
    { lat: 49.2144539, lng: 18.6248725 },
    { lat: 49.2137854, lng: 18.6241145 },
    { lat: 49.213178, lng: 18.6232751 },
    { lat: 49.2124437, lng: 18.6225557 },
    { lat: 49.2117367, lng: 18.6217319 },
    { lat: 49.2115242, lng: 18.6214026 },
    { lat: 49.2113, lng: 18.6208811 },
    { lat: 49.2111834, lng: 18.6204054 },
    { lat: 49.211197, lng: 18.6202608 },
    { lat: 49.2111213, lng: 18.6201093 },
    { lat: 49.2108329, lng: 18.6196694 },
    { lat: 49.210592, lng: 18.6191929 },
    { lat: 49.209686, lng: 18.6174297 },
    { lat: 49.2095417, lng: 18.6171356 },
    { lat: 49.2093567, lng: 18.6168776 },
    { lat: 49.2092221, lng: 18.6165567 },
    { lat: 49.2087464, lng: 18.6156098 },
    { lat: 49.2083732, lng: 18.6148295 },
    { lat: 49.2081728, lng: 18.6149388 },
    { lat: 49.2073967, lng: 18.6147225 },
    { lat: 49.2073576, lng: 18.6133791 },
    { lat: 49.2074913, lng: 18.6125228 },
    { lat: 49.207586, lng: 18.611883 },
  ],
  Podhorie: [
    { lat: 49.162695, lng: 18.612461 },
    { lat: 49.1626624, lng: 18.6125541 },
    { lat: 49.16296, lng: 18.6147233 },
    { lat: 49.1629914, lng: 18.6153104 },
    { lat: 49.1622986, lng: 18.6162995 },
    { lat: 49.162518, lng: 18.6175757 },
    { lat: 49.1626209, lng: 18.6186404 },
    { lat: 49.1634371, lng: 18.6197114 },
    { lat: 49.1638335, lng: 18.6207797 },
    { lat: 49.1640074, lng: 18.6215647 },
    { lat: 49.164046, lng: 18.6218039 },
    { lat: 49.1640121, lng: 18.6220546 },
    { lat: 49.1639759, lng: 18.6225192 },
    { lat: 49.1640276, lng: 18.6229406 },
    { lat: 49.1640596, lng: 18.6235806 },
    { lat: 49.1642579, lng: 18.6241085 },
    { lat: 49.1643584, lng: 18.6244049 },
    { lat: 49.1643608, lng: 18.6247649 },
    { lat: 49.1642969, lng: 18.6252604 },
    { lat: 49.1642185, lng: 18.6259041 },
    { lat: 49.1639776, lng: 18.6270767 },
    { lat: 49.1640122, lng: 18.6275978 },
    { lat: 49.1639697, lng: 18.6283033 },
    { lat: 49.1641388, lng: 18.6284311 },
    { lat: 49.1642577, lng: 18.6284622 },
    { lat: 49.164395, lng: 18.6285198 },
    { lat: 49.1645578, lng: 18.6295843 },
    { lat: 49.1648146, lng: 18.630172 },
    { lat: 49.1649776, lng: 18.6307756 },
    { lat: 49.1653087, lng: 18.6314673 },
    { lat: 49.1656173, lng: 18.6323278 },
    { lat: 49.1656778, lng: 18.6334855 },
    { lat: 49.1657525, lng: 18.6344236 },
    { lat: 49.1658565, lng: 18.6351481 },
    { lat: 49.1659632, lng: 18.6363447 },
    { lat: 49.1659321, lng: 18.6366293 },
    { lat: 49.1660656, lng: 18.6371674 },
    { lat: 49.1659846, lng: 18.6376164 },
    { lat: 49.1657937, lng: 18.6381813 },
    { lat: 49.1657059, lng: 18.6386336 },
    { lat: 49.16583, lng: 18.638846 },
    { lat: 49.1669118, lng: 18.640867 },
    { lat: 49.1669312, lng: 18.6410408 },
    { lat: 49.1669869, lng: 18.6410271 },
    { lat: 49.1670343, lng: 18.6412162 },
    { lat: 49.1671253, lng: 18.6412338 },
    { lat: 49.167179, lng: 18.6415007 },
    { lat: 49.1671691, lng: 18.6416082 },
    { lat: 49.1671897, lng: 18.6417203 },
    { lat: 49.1672209, lng: 18.6418635 },
    { lat: 49.1671448, lng: 18.6419601 },
    { lat: 49.1660431, lng: 18.64402 },
    { lat: 49.1654889, lng: 18.645028 },
    { lat: 49.164923, lng: 18.6461157 },
    { lat: 49.1649046, lng: 18.6461522 },
    { lat: 49.1648656, lng: 18.6462262 },
    { lat: 49.1660553, lng: 18.6489417 },
    { lat: 49.1659417, lng: 18.6491803 },
    { lat: 49.1664351, lng: 18.6496152 },
    { lat: 49.1662461, lng: 18.650133 },
    { lat: 49.1664332, lng: 18.6503896 },
    { lat: 49.1661661, lng: 18.6513667 },
    { lat: 49.1671274, lng: 18.6513544 },
    { lat: 49.1674708, lng: 18.6509628 },
    { lat: 49.1686003, lng: 18.6521233 },
    { lat: 49.1696737, lng: 18.6536281 },
    { lat: 49.1704993, lng: 18.6553903 },
    { lat: 49.1706043, lng: 18.6559493 },
    { lat: 49.1707654, lng: 18.6562869 },
    { lat: 49.1711653, lng: 18.656425 },
    { lat: 49.1712979, lng: 18.6566091 },
    { lat: 49.1712267, lng: 18.6569029 },
    { lat: 49.1713083, lng: 18.6569963 },
    { lat: 49.1717455, lng: 18.6569651 },
    { lat: 49.1720253, lng: 18.6571836 },
    { lat: 49.1723813, lng: 18.6577077 },
    { lat: 49.1727538, lng: 18.657674 },
    { lat: 49.1729734, lng: 18.6573442 },
    { lat: 49.1730345, lng: 18.6574145 },
    { lat: 49.1730731, lng: 18.6580974 },
    { lat: 49.1730865, lng: 18.6584169 },
    { lat: 49.1730271, lng: 18.6588085 },
    { lat: 49.1728955, lng: 18.65909 },
    { lat: 49.1728132, lng: 18.6597698 },
    { lat: 49.172729, lng: 18.6602455 },
    { lat: 49.1726798, lng: 18.6604851 },
    { lat: 49.1728228, lng: 18.6605677 },
    { lat: 49.1728366, lng: 18.6606586 },
    { lat: 49.1728528, lng: 18.6606649 },
    { lat: 49.1728307, lng: 18.6608897 },
    { lat: 49.1728584, lng: 18.6610924 },
    { lat: 49.1730292, lng: 18.6615489 },
    { lat: 49.1730513, lng: 18.6616911 },
    { lat: 49.1730094, lng: 18.6621136 },
    { lat: 49.1731009, lng: 18.6621986 },
    { lat: 49.1731374, lng: 18.6621869 },
    { lat: 49.173228, lng: 18.6622334 },
    { lat: 49.1732426, lng: 18.6622319 },
    { lat: 49.1732425, lng: 18.6622979 },
    { lat: 49.1733801, lng: 18.6621072 },
    { lat: 49.1734932, lng: 18.6624083 },
    { lat: 49.1737104, lng: 18.6622749 },
    { lat: 49.1737733, lng: 18.6620343 },
    { lat: 49.1735656, lng: 18.6614778 },
    { lat: 49.1735797, lng: 18.661214 },
    { lat: 49.1737733, lng: 18.6610979 },
    { lat: 49.1739774, lng: 18.6608207 },
    { lat: 49.1742485, lng: 18.6606601 },
    { lat: 49.1742851, lng: 18.6604328 },
    { lat: 49.1744701, lng: 18.6602219 },
    { lat: 49.1746062, lng: 18.6600033 },
    { lat: 49.1747199, lng: 18.6601291 },
    { lat: 49.174814, lng: 18.6600494 },
    { lat: 49.1749224, lng: 18.660138 },
    { lat: 49.1751513, lng: 18.6598809 },
    { lat: 49.1755974, lng: 18.6598097 },
    { lat: 49.1757091, lng: 18.6596824 },
    { lat: 49.1757533, lng: 18.6595172 },
    { lat: 49.1758448, lng: 18.6595182 },
    { lat: 49.1759671, lng: 18.659785 },
    { lat: 49.1764113, lng: 18.6598672 },
    { lat: 49.1766197, lng: 18.6598848 },
    { lat: 49.1771269, lng: 18.6596672 },
    { lat: 49.1776305, lng: 18.6593824 },
    { lat: 49.1779448, lng: 18.6592037 },
    { lat: 49.1786572, lng: 18.6588152 },
    { lat: 49.1791125, lng: 18.6584249 },
    { lat: 49.1793867, lng: 18.6580887 },
    { lat: 49.1799293, lng: 18.6574562 },
    { lat: 49.180405, lng: 18.6568142 },
    { lat: 49.1808067, lng: 18.656265 },
    { lat: 49.1811931, lng: 18.6559344 },
    { lat: 49.1813076, lng: 18.6557375 },
    { lat: 49.1816406, lng: 18.6554213 },
    { lat: 49.1817327, lng: 18.6552853 },
    { lat: 49.1818125, lng: 18.6549419 },
    { lat: 49.1820043, lng: 18.6543927 },
    { lat: 49.1819091, lng: 18.6537018 },
    { lat: 49.1818448, lng: 18.6535721 },
    { lat: 49.1819341, lng: 18.6532777 },
    { lat: 49.1821989, lng: 18.6527461 },
    { lat: 49.1824135, lng: 18.6524741 },
    { lat: 49.1824906, lng: 18.6519952 },
    { lat: 49.182555, lng: 18.6512522 },
    { lat: 49.1825578, lng: 18.6512215 },
    { lat: 49.1826153, lng: 18.6503492 },
    { lat: 49.1825587, lng: 18.649875 },
    { lat: 49.1826333, lng: 18.6494551 },
    { lat: 49.1825943, lng: 18.6494639 },
    { lat: 49.1826459, lng: 18.649159 },
    { lat: 49.1826489, lng: 18.6488775 },
    { lat: 49.182674, lng: 18.6486516 },
    { lat: 49.1826047, lng: 18.6485563 },
    { lat: 49.1826712, lng: 18.6484068 },
    { lat: 49.1827187, lng: 18.6484903 },
    { lat: 49.1827476, lng: 18.6485066 },
    { lat: 49.1827652, lng: 18.648433 },
    { lat: 49.1827279, lng: 18.648411 },
    { lat: 49.1827631, lng: 18.6482855 },
    { lat: 49.1828428, lng: 18.6482598 },
    { lat: 49.1828087, lng: 18.6481703 },
    { lat: 49.1828539, lng: 18.6481481 },
    { lat: 49.1828611, lng: 18.6480744 },
    { lat: 49.1828568, lng: 18.6479942 },
    { lat: 49.1828584, lng: 18.6479792 },
    { lat: 49.1828197, lng: 18.647763 },
    { lat: 49.1826754, lng: 18.647952 },
    { lat: 49.182646, lng: 18.6478879 },
    { lat: 49.1826343, lng: 18.6477113 },
    { lat: 49.1827263, lng: 18.6476145 },
    { lat: 49.182726, lng: 18.6475017 },
    { lat: 49.1827239, lng: 18.6474637 },
    { lat: 49.1827033, lng: 18.6473505 },
    { lat: 49.1827313, lng: 18.6472425 },
    { lat: 49.1826687, lng: 18.6470419 },
    { lat: 49.1825968, lng: 18.6469837 },
    { lat: 49.1825446, lng: 18.6468726 },
    { lat: 49.1825935, lng: 18.6467689 },
    { lat: 49.182545, lng: 18.6466667 },
    { lat: 49.1825571, lng: 18.6465506 },
    { lat: 49.1824889, lng: 18.6464156 },
    { lat: 49.1825105, lng: 18.6462081 },
    { lat: 49.1825106, lng: 18.6458678 },
    { lat: 49.1825147, lng: 18.6457761 },
    { lat: 49.1824795, lng: 18.6454946 },
    { lat: 49.1824564, lng: 18.6454206 },
    { lat: 49.1824717, lng: 18.6453484 },
    { lat: 49.1824514, lng: 18.6452424 },
    { lat: 49.1824242, lng: 18.6452195 },
    { lat: 49.1824047, lng: 18.6450434 },
    { lat: 49.1823881, lng: 18.6448378 },
    { lat: 49.1824334, lng: 18.6446498 },
    { lat: 49.1823976, lng: 18.644443 },
    { lat: 49.1824183, lng: 18.6442815 },
    { lat: 49.1825254, lng: 18.6440894 },
    { lat: 49.182515, lng: 18.643851 },
    { lat: 49.1824547, lng: 18.6436263 },
    { lat: 49.1824272, lng: 18.6435994 },
    { lat: 49.1824313, lng: 18.6435059 },
    { lat: 49.182383, lng: 18.6433186 },
    { lat: 49.1823082, lng: 18.6432496 },
    { lat: 49.1822823, lng: 18.6431836 },
    { lat: 49.1822581, lng: 18.6430045 },
    { lat: 49.1823379, lng: 18.6427236 },
    { lat: 49.1822392, lng: 18.6423511 },
    { lat: 49.182177, lng: 18.6421595 },
    { lat: 49.1822043, lng: 18.6420775 },
    { lat: 49.182327, lng: 18.641926 },
    { lat: 49.18231, lng: 18.6417533 },
    { lat: 49.1823978, lng: 18.641515 },
    { lat: 49.1823376, lng: 18.6414226 },
    { lat: 49.1822989, lng: 18.6414151 },
    { lat: 49.1823282, lng: 18.6412471 },
    { lat: 49.1823378, lng: 18.6410444 },
    { lat: 49.1823911, lng: 18.6410511 },
    { lat: 49.1824471, lng: 18.6409584 },
    { lat: 49.1824736, lng: 18.6408834 },
    { lat: 49.182549, lng: 18.6409458 },
    { lat: 49.1826731, lng: 18.6407988 },
    { lat: 49.1826716, lng: 18.6403437 },
    { lat: 49.1826689, lng: 18.6401241 },
    { lat: 49.1825796, lng: 18.6398893 },
    { lat: 49.1826217, lng: 18.6394057 },
    { lat: 49.1827629, lng: 18.6389448 },
    { lat: 49.1828576, lng: 18.6387529 },
    { lat: 49.1829315, lng: 18.638575 },
    { lat: 49.1827434, lng: 18.6385795 },
    { lat: 49.1827558, lng: 18.6382604 },
    { lat: 49.1829855, lng: 18.6382525 },
    { lat: 49.1829842, lng: 18.6378687 },
    { lat: 49.1829254, lng: 18.6376118 },
    { lat: 49.1828031, lng: 18.6372184 },
    { lat: 49.1827213, lng: 18.6370613 },
    { lat: 49.1826635, lng: 18.6368413 },
    { lat: 49.1826098, lng: 18.6366807 },
    { lat: 49.1825276, lng: 18.636449 },
    { lat: 49.1823848, lng: 18.6361382 },
    { lat: 49.1822326, lng: 18.6359502 },
    { lat: 49.1820964, lng: 18.6358304 },
    { lat: 49.1819545, lng: 18.6356403 },
    { lat: 49.1818814, lng: 18.6355809 },
    { lat: 49.1817233, lng: 18.6354483 },
    { lat: 49.181677, lng: 18.635343 },
    { lat: 49.1815971, lng: 18.6352624 },
    { lat: 49.181469, lng: 18.635204 },
    { lat: 49.181384, lng: 18.6351561 },
    { lat: 49.1812636, lng: 18.6352031 },
    { lat: 49.1811404, lng: 18.6350511 },
    { lat: 49.1809187, lng: 18.6347217 },
    { lat: 49.181037, lng: 18.6344839 },
    { lat: 49.1809855, lng: 18.6343021 },
    { lat: 49.1809899, lng: 18.6339374 },
    { lat: 49.1810507, lng: 18.6339786 },
    { lat: 49.1810611, lng: 18.6339416 },
    { lat: 49.1810494, lng: 18.633318 },
    { lat: 49.1810438, lng: 18.633 },
    { lat: 49.1810328, lng: 18.6325615 },
    { lat: 49.1810228, lng: 18.6320985 },
    { lat: 49.1810129, lng: 18.631869 },
    { lat: 49.1809789, lng: 18.6314208 },
    { lat: 49.181309, lng: 18.6314386 },
    { lat: 49.1812696, lng: 18.6310985 },
    { lat: 49.1812531, lng: 18.6309391 },
    { lat: 49.1811834, lng: 18.6307073 },
    { lat: 49.1811788, lng: 18.6306437 },
    { lat: 49.1810887, lng: 18.6306242 },
    { lat: 49.1810637, lng: 18.6305779 },
    { lat: 49.1810163, lng: 18.6304727 },
    { lat: 49.1809698, lng: 18.6303804 },
    { lat: 49.1808387, lng: 18.6301461 },
    { lat: 49.1808071, lng: 18.6301021 },
    { lat: 49.1806641, lng: 18.6299161 },
    { lat: 49.1805543, lng: 18.6297812 },
    { lat: 49.1804273, lng: 18.6296625 },
    { lat: 49.1803627, lng: 18.6296132 },
    { lat: 49.1802901, lng: 18.6295631 },
    { lat: 49.1800519, lng: 18.6293903 },
    { lat: 49.1801567, lng: 18.6292595 },
    { lat: 49.1799113, lng: 18.6291868 },
    { lat: 49.1797393, lng: 18.6291292 },
    { lat: 49.1795385, lng: 18.6290643 },
    { lat: 49.1793503, lng: 18.6289203 },
    { lat: 49.1791214, lng: 18.6285626 },
    { lat: 49.1790539, lng: 18.6281424 },
    { lat: 49.1789663, lng: 18.6268588 },
    { lat: 49.1787507, lng: 18.6256238 },
    { lat: 49.1788425, lng: 18.6256575 },
    { lat: 49.1787941, lng: 18.6244916 },
    { lat: 49.178777, lng: 18.624065 },
    { lat: 49.1787668, lng: 18.6238104 },
    { lat: 49.1789578, lng: 18.6230813 },
    { lat: 49.1791335, lng: 18.6228601 },
    { lat: 49.1792789, lng: 18.6227112 },
    { lat: 49.1797614, lng: 18.622328 },
    { lat: 49.1799823, lng: 18.6220699 },
    { lat: 49.1801054, lng: 18.6218001 },
    { lat: 49.1801588, lng: 18.6214049 },
    { lat: 49.1813737, lng: 18.6207963 },
    { lat: 49.1832261, lng: 18.6199442 },
    { lat: 49.1835366, lng: 18.6198228 },
    { lat: 49.184787, lng: 18.6189516 },
    { lat: 49.1850043, lng: 18.6181195 },
    { lat: 49.1853986, lng: 18.6176441 },
    { lat: 49.1861183, lng: 18.6169331 },
    { lat: 49.1859024, lng: 18.6165003 },
    { lat: 49.1858209, lng: 18.6163849 },
    { lat: 49.1859954, lng: 18.6155596 },
    { lat: 49.1860692, lng: 18.6149541 },
    { lat: 49.1861442, lng: 18.6140972 },
    { lat: 49.1860432, lng: 18.6137665 },
    { lat: 49.1857207, lng: 18.6121838 },
    { lat: 49.1856793, lng: 18.6118126 },
    { lat: 49.1856412, lng: 18.6116915 },
    { lat: 49.1854372, lng: 18.6110801 },
    { lat: 49.1853881, lng: 18.6104784 },
    { lat: 49.1853169, lng: 18.6103272 },
    { lat: 49.1852891, lng: 18.6102719 },
    { lat: 49.185156, lng: 18.609856 },
    { lat: 49.184657, lng: 18.608807 },
    { lat: 49.184082, lng: 18.609532 },
    { lat: 49.183716, lng: 18.609949 },
    { lat: 49.183287, lng: 18.610036 },
    { lat: 49.182683, lng: 18.609733 },
    { lat: 49.182514, lng: 18.609713 },
    { lat: 49.181729, lng: 18.609721 },
    { lat: 49.181032, lng: 18.609436 },
    { lat: 49.180747, lng: 18.609423 },
    { lat: 49.180378, lng: 18.609359 },
    { lat: 49.18021, lng: 18.609758 },
    { lat: 49.180139, lng: 18.61007 },
    { lat: 49.179762, lng: 18.610413 },
    { lat: 49.179164, lng: 18.611457 },
    { lat: 49.178912, lng: 18.611805 },
    { lat: 49.178755, lng: 18.611914 },
    { lat: 49.178167, lng: 18.612125 },
    { lat: 49.177974, lng: 18.612066 },
    { lat: 49.177603, lng: 18.611748 },
    { lat: 49.177303, lng: 18.611489 },
    { lat: 49.176921, lng: 18.611351 },
    { lat: 49.176251, lng: 18.611386 },
    { lat: 49.175932, lng: 18.611425 },
    { lat: 49.17566, lng: 18.611301 },
    { lat: 49.17494, lng: 18.611521 },
    { lat: 49.174494, lng: 18.611604 },
    { lat: 49.173971, lng: 18.611779 },
    { lat: 49.173069, lng: 18.612036 },
    { lat: 49.172581, lng: 18.612358 },
    { lat: 49.171795, lng: 18.612173 },
    { lat: 49.171565, lng: 18.612003 },
    { lat: 49.1705, lng: 18.611488 },
    { lat: 49.169866, lng: 18.611822 },
    { lat: 49.169552, lng: 18.611651 },
    { lat: 49.169208, lng: 18.611243 },
    { lat: 49.168852, lng: 18.610998 },
    { lat: 49.168126, lng: 18.610885 },
    { lat: 49.167714, lng: 18.611105 },
    { lat: 49.16744, lng: 18.611205 },
    { lat: 49.166674, lng: 18.611024 },
    { lat: 49.165874, lng: 18.611451 },
    { lat: 49.165518, lng: 18.611543 },
    { lat: 49.164876, lng: 18.611561 },
    { lat: 49.164593, lng: 18.611448 },
    { lat: 49.164243, lng: 18.611413 },
    { lat: 49.163831, lng: 18.611718 },
    { lat: 49.163686, lng: 18.611877 },
    { lat: 49.163534, lng: 18.611979 },
    { lat: 49.163323, lng: 18.612339 },
    { lat: 49.163023, lng: 18.612554 },
    { lat: 49.162803, lng: 18.612492 },
    { lat: 49.162695, lng: 18.612461 },
  ],
  Svederník: [
    { lat: 49.2865042, lng: 18.649662 },
    { lat: 49.2865275, lng: 18.6495702 },
    { lat: 49.2862503, lng: 18.6489156 },
    { lat: 49.2858805, lng: 18.6484914 },
    { lat: 49.2858283, lng: 18.6480601 },
    { lat: 49.2857967, lng: 18.6474856 },
    { lat: 49.2858899, lng: 18.6464131 },
    { lat: 49.2860422, lng: 18.6459463 },
    { lat: 49.2861737, lng: 18.6451067 },
    { lat: 49.2861931, lng: 18.6444515 },
    { lat: 49.2861299, lng: 18.6437712 },
    { lat: 49.2858892, lng: 18.6419543 },
    { lat: 49.2856731, lng: 18.6406867 },
    { lat: 49.2852349, lng: 18.6388864 },
    { lat: 49.2851655, lng: 18.6381541 },
    { lat: 49.285063, lng: 18.6373699 },
    { lat: 49.2849314, lng: 18.636378 },
    { lat: 49.284854, lng: 18.63541 },
    { lat: 49.2847264, lng: 18.6345329 },
    { lat: 49.2847236, lng: 18.6337591 },
    { lat: 49.2848087, lng: 18.6333237 },
    { lat: 49.2848089, lng: 18.6332427 },
    { lat: 49.2848091, lng: 18.6331853 },
    { lat: 49.2841869, lng: 18.6332119 },
    { lat: 49.2838174, lng: 18.6333253 },
    { lat: 49.2838109, lng: 18.6333272 },
    { lat: 49.2834447, lng: 18.6334391 },
    { lat: 49.2826485, lng: 18.6335054 },
    { lat: 49.2820757, lng: 18.6335068 },
    { lat: 49.2820679, lng: 18.6335063 },
    { lat: 49.2814455, lng: 18.6335073 },
    { lat: 49.2814452, lng: 18.6334398 },
    { lat: 49.2814451, lng: 18.6333744 },
    { lat: 49.2814456, lng: 18.6333401 },
    { lat: 49.2814468, lng: 18.6332561 },
    { lat: 49.2814465, lng: 18.6332092 },
    { lat: 49.2814208, lng: 18.63262 },
    { lat: 49.2813957, lng: 18.6320397 },
    { lat: 49.2813757, lng: 18.6316008 },
    { lat: 49.2813693, lng: 18.6314361 },
    { lat: 49.2813544, lng: 18.6312415 },
    { lat: 49.2812833, lng: 18.6302855 },
    { lat: 49.281289, lng: 18.6295248 },
    { lat: 49.2812558, lng: 18.6283484 },
    { lat: 49.2811459, lng: 18.6273391 },
    { lat: 49.2810943, lng: 18.6272176 },
    { lat: 49.2810734, lng: 18.6271628 },
    { lat: 49.2808692, lng: 18.6260601 },
    { lat: 49.2807919, lng: 18.6257926 },
    { lat: 49.2805493, lng: 18.6252622 },
    { lat: 49.2802517, lng: 18.6244391 },
    { lat: 49.2799817, lng: 18.6236639 },
    { lat: 49.2797626, lng: 18.6232136 },
    { lat: 49.2794323, lng: 18.6225394 },
    { lat: 49.2788987, lng: 18.6217717 },
    { lat: 49.2785641, lng: 18.6210577 },
    { lat: 49.2783928, lng: 18.6200019 },
    { lat: 49.2779984, lng: 18.6186693 },
    { lat: 49.2775248, lng: 18.6175742 },
    { lat: 49.2772607, lng: 18.6171007 },
    { lat: 49.276144, lng: 18.614828 },
    { lat: 49.275728, lng: 18.614841 },
    { lat: 49.275097, lng: 18.614977 },
    { lat: 49.274623, lng: 18.614992 },
    { lat: 49.27399, lng: 18.615362 },
    { lat: 49.273779, lng: 18.616311 },
    { lat: 49.273113, lng: 18.617321 },
    { lat: 49.272847, lng: 18.617635 },
    { lat: 49.272327, lng: 18.618362 },
    { lat: 49.27203, lng: 18.618773 },
    { lat: 49.271375, lng: 18.619107 },
    { lat: 49.27126, lng: 18.61911 },
    { lat: 49.271098, lng: 18.619019 },
    { lat: 49.270912, lng: 18.618609 },
    { lat: 49.270812, lng: 18.618565 },
    { lat: 49.270714, lng: 18.618629 },
    { lat: 49.270388, lng: 18.619142 },
    { lat: 49.270246, lng: 18.619221 },
    { lat: 49.269997, lng: 18.619478 },
    { lat: 49.269922, lng: 18.619554 },
    { lat: 49.269345, lng: 18.619877 },
    { lat: 49.269173, lng: 18.619912 },
    { lat: 49.269087, lng: 18.61989 },
    { lat: 49.268777, lng: 18.619813 },
    { lat: 49.268378, lng: 18.620059 },
    { lat: 49.268206, lng: 18.620206 },
    { lat: 49.268096, lng: 18.620333 },
    { lat: 49.267679, lng: 18.620933 },
    { lat: 49.267081, lng: 18.621525 },
    { lat: 49.266544, lng: 18.622044 },
    { lat: 49.266264, lng: 18.622084 },
    { lat: 49.266166, lng: 18.622098 },
    { lat: 49.266067, lng: 18.622239 },
    { lat: 49.265999, lng: 18.622443 },
    { lat: 49.265849, lng: 18.622719 },
    { lat: 49.265646, lng: 18.622861 },
    { lat: 49.264798, lng: 18.622718 },
    { lat: 49.264668, lng: 18.622691 },
    { lat: 49.264543, lng: 18.622765 },
    { lat: 49.264416, lng: 18.62289 },
    { lat: 49.26425, lng: 18.623193 },
    { lat: 49.264065, lng: 18.623661 },
    { lat: 49.26397, lng: 18.624184 },
    { lat: 49.263701, lng: 18.624649 },
    { lat: 49.263517, lng: 18.624784 },
    { lat: 49.263222, lng: 18.624908 },
    { lat: 49.26305, lng: 18.624979 },
    { lat: 49.262945, lng: 18.625046 },
    { lat: 49.262561, lng: 18.625344 },
    { lat: 49.262102, lng: 18.625397 },
    { lat: 49.261847, lng: 18.625477 },
    { lat: 49.261576, lng: 18.625686 },
    { lat: 49.261367, lng: 18.625838 },
    { lat: 49.261231, lng: 18.62591 },
    { lat: 49.261139, lng: 18.625996 },
    { lat: 49.260954, lng: 18.626262 },
    { lat: 49.260744, lng: 18.626523 },
    { lat: 49.260541, lng: 18.626705 },
    { lat: 49.26039, lng: 18.626788 },
    { lat: 49.260157, lng: 18.626793 },
    { lat: 49.259991, lng: 18.626749 },
    { lat: 49.259905, lng: 18.626724 },
    { lat: 49.259774, lng: 18.626729 },
    { lat: 49.259507, lng: 18.626788 },
    { lat: 49.258925, lng: 18.626433 },
    { lat: 49.258792, lng: 18.626428 },
    { lat: 49.258573, lng: 18.626507 },
    { lat: 49.258429, lng: 18.626515 },
    { lat: 49.258293, lng: 18.626583 },
    { lat: 49.257797, lng: 18.626444 },
    { lat: 49.257058, lng: 18.626312 },
    { lat: 49.257246, lng: 18.626551 },
    { lat: 49.257467, lng: 18.626923 },
    { lat: 49.257505, lng: 18.627121 },
    { lat: 49.257661, lng: 18.627178 },
    { lat: 49.257978, lng: 18.627519 },
    { lat: 49.258041, lng: 18.627876 },
    { lat: 49.258072, lng: 18.628033 },
    { lat: 49.258033, lng: 18.628311 },
    { lat: 49.257906, lng: 18.628851 },
    { lat: 49.257565, lng: 18.628445 },
    { lat: 49.257083, lng: 18.628551 },
    { lat: 49.256908, lng: 18.628914 },
    { lat: 49.256866, lng: 18.629694 },
    { lat: 49.256726, lng: 18.630191 },
    { lat: 49.256981, lng: 18.63148 },
    { lat: 49.256885, lng: 18.635225 },
    { lat: 49.256883, lng: 18.635353 },
    { lat: 49.256883, lng: 18.635446 },
    { lat: 49.256882, lng: 18.635472 },
    { lat: 49.256881, lng: 18.635524 },
    { lat: 49.256881, lng: 18.635532 },
    { lat: 49.25688, lng: 18.635585 },
    { lat: 49.256858, lng: 18.635904 },
    { lat: 49.2571288, lng: 18.6359372 },
    { lat: 49.2573993, lng: 18.6368084 },
    { lat: 49.2575026, lng: 18.6375434 },
    { lat: 49.2575911, lng: 18.6392954 },
    { lat: 49.2573843, lng: 18.6407313 },
    { lat: 49.2571142, lng: 18.6419309 },
    { lat: 49.2568733, lng: 18.6429175 },
    { lat: 49.25683, lng: 18.6430983 },
    { lat: 49.2568226, lng: 18.6435343 },
    { lat: 49.2568075, lng: 18.6439697 },
    { lat: 49.2568054, lng: 18.6440162 },
    { lat: 49.2568037, lng: 18.6440897 },
    { lat: 49.2567976, lng: 18.6442515 },
    { lat: 49.2567955, lng: 18.6442976 },
    { lat: 49.2567898, lng: 18.6444658 },
    { lat: 49.2567889, lng: 18.6444941 },
    { lat: 49.2567846, lng: 18.6446268 },
    { lat: 49.2567827, lng: 18.6446924 },
    { lat: 49.2567816, lng: 18.6446943 },
    { lat: 49.2567819, lng: 18.6447675 },
    { lat: 49.2567844, lng: 18.6449156 },
    { lat: 49.2567965, lng: 18.6463778 },
    { lat: 49.2567965, lng: 18.6464226 },
    { lat: 49.2567985, lng: 18.6466534 },
    { lat: 49.2568, lng: 18.6467655 },
    { lat: 49.2568, lng: 18.646872 },
    { lat: 49.2568007, lng: 18.6469253 },
    { lat: 49.2568021, lng: 18.6470374 },
    { lat: 49.2568027, lng: 18.6470702 },
    { lat: 49.2568294, lng: 18.6471499 },
    { lat: 49.2570112, lng: 18.6476426 },
    { lat: 49.2570714, lng: 18.64782 },
    { lat: 49.2574828, lng: 18.6491169 },
    { lat: 49.2576144, lng: 18.6495357 },
    { lat: 49.2580078, lng: 18.6507655 },
    { lat: 49.2583818, lng: 18.6519269 },
    { lat: 49.2585602, lng: 18.6524847 },
    { lat: 49.2591062, lng: 18.6542355 },
    { lat: 49.2591529, lng: 18.654412 },
    { lat: 49.2597078, lng: 18.6561635 },
    { lat: 49.2598278, lng: 18.6567653 },
    { lat: 49.2602888, lng: 18.6580858 },
    { lat: 49.2606459, lng: 18.6592924 },
    { lat: 49.2608193, lng: 18.6598801 },
    { lat: 49.2613361, lng: 18.6616588 },
    { lat: 49.2618333, lng: 18.6633389 },
    { lat: 49.2621159, lng: 18.6645897 },
    { lat: 49.2622909, lng: 18.665746 },
    { lat: 49.2624227, lng: 18.6670014 },
    { lat: 49.2624457, lng: 18.6679734 },
    { lat: 49.262393, lng: 18.6693238 },
    { lat: 49.262293, lng: 18.6703735 },
    { lat: 49.2621151, lng: 18.6716184 },
    { lat: 49.26184, lng: 18.6728171 },
    { lat: 49.2615044, lng: 18.6739138 },
    { lat: 49.2610958, lng: 18.6749968 },
    { lat: 49.2606749, lng: 18.6759083 },
    { lat: 49.2607195, lng: 18.6759653 },
    { lat: 49.2607727, lng: 18.6760342 },
    { lat: 49.260797, lng: 18.6760648 },
    { lat: 49.2608622, lng: 18.6761485 },
    { lat: 49.2608926, lng: 18.6761888 },
    { lat: 49.260953, lng: 18.6762665 },
    { lat: 49.2614539, lng: 18.6769096 },
    { lat: 49.2619461, lng: 18.6775423 },
    { lat: 49.2617676, lng: 18.6784312 },
    { lat: 49.2617448, lng: 18.6785148 },
    { lat: 49.2614711, lng: 18.6795259 },
    { lat: 49.2613206, lng: 18.6800059 },
    { lat: 49.2608781, lng: 18.6814107 },
    { lat: 49.2602956, lng: 18.6832786 },
    { lat: 49.260178, lng: 18.683657 },
    { lat: 49.2601546, lng: 18.6837296 },
    { lat: 49.2601199, lng: 18.6838418 },
    { lat: 49.2601016, lng: 18.6838978 },
    { lat: 49.2599663, lng: 18.6842856 },
    { lat: 49.2599506, lng: 18.6843276 },
    { lat: 49.2598798, lng: 18.6845053 },
    { lat: 49.2598344, lng: 18.6845853 },
    { lat: 49.2598709, lng: 18.6847631 },
    { lat: 49.2598528, lng: 18.6848036 },
    { lat: 49.2597389, lng: 18.6850653 },
    { lat: 49.2596968, lng: 18.6851625 },
    { lat: 49.2595421, lng: 18.6853924 },
    { lat: 49.2593892, lng: 18.6855712 },
    { lat: 49.2593784, lng: 18.6855819 },
    { lat: 49.2592539, lng: 18.6857044 },
    { lat: 49.2591499, lng: 18.6858057 },
    { lat: 49.2590538, lng: 18.6858832 },
    { lat: 49.2589393, lng: 18.6859583 },
    { lat: 49.2589516, lng: 18.6859809 },
    { lat: 49.2590047, lng: 18.686069 },
    { lat: 49.2590099, lng: 18.6860774 },
    { lat: 49.259042, lng: 18.6861305 },
    { lat: 49.2591432, lng: 18.6862751 },
    { lat: 49.2591894, lng: 18.6863416 },
    { lat: 49.2598652, lng: 18.6873095 },
    { lat: 49.2605503, lng: 18.6873643 },
    { lat: 49.2607273, lng: 18.6874043 },
    { lat: 49.2607775, lng: 18.6874154 },
    { lat: 49.2608677, lng: 18.6874361 },
    { lat: 49.260888, lng: 18.6874372 },
    { lat: 49.2608926, lng: 18.6874381 },
    { lat: 49.2610691, lng: 18.6874525 },
    { lat: 49.2613931, lng: 18.6874791 },
    { lat: 49.2616858, lng: 18.6877912 },
    { lat: 49.2624655, lng: 18.6886561 },
    { lat: 49.2631155, lng: 18.6885156 },
    { lat: 49.2636015, lng: 18.6895416 },
    { lat: 49.2636999, lng: 18.6885658 },
    { lat: 49.2643049, lng: 18.6877815 },
    { lat: 49.2646148, lng: 18.687222 },
    { lat: 49.2648795, lng: 18.6867883 },
    { lat: 49.2649813, lng: 18.6866223 },
    { lat: 49.2650064, lng: 18.6865867 },
    { lat: 49.2651829, lng: 18.6863404 },
    { lat: 49.2653009, lng: 18.6862017 },
    { lat: 49.2655538, lng: 18.6859973 },
    { lat: 49.2657731, lng: 18.6858136 },
    { lat: 49.2659589, lng: 18.6856575 },
    { lat: 49.266338, lng: 18.6853635 },
    { lat: 49.2672709, lng: 18.6846411 },
    { lat: 49.2673167, lng: 18.6846073 },
    { lat: 49.2688306, lng: 18.6839783 },
    { lat: 49.269276, lng: 18.6836364 },
    { lat: 49.2692557, lng: 18.683592 },
    { lat: 49.2693255, lng: 18.683505 },
    { lat: 49.2693699, lng: 18.6834497 },
    { lat: 49.2694249, lng: 18.6833823 },
    { lat: 49.2699105, lng: 18.6829434 },
    { lat: 49.2706577, lng: 18.6825932 },
    { lat: 49.2713493, lng: 18.6824073 },
    { lat: 49.2719753, lng: 18.682067 },
    { lat: 49.272297, lng: 18.6818584 },
    { lat: 49.272627, lng: 18.6816813 },
    { lat: 49.273084, lng: 18.6814484 },
    { lat: 49.2736351, lng: 18.6809967 },
    { lat: 49.2742478, lng: 18.6808519 },
    { lat: 49.274509, lng: 18.6806742 },
    { lat: 49.2752122, lng: 18.6809654 },
    { lat: 49.2764461, lng: 18.6807525 },
    { lat: 49.2768567, lng: 18.6802219 },
    { lat: 49.2770727, lng: 18.6790136 },
    { lat: 49.2772233, lng: 18.6780018 },
    { lat: 49.2772647, lng: 18.6775456 },
    { lat: 49.2772859, lng: 18.6767907 },
    { lat: 49.2772748, lng: 18.6763261 },
    { lat: 49.2772646, lng: 18.6758546 },
    { lat: 49.2772643, lng: 18.6758037 },
    { lat: 49.2773484, lng: 18.6753906 },
    { lat: 49.2771884, lng: 18.6748357 },
    { lat: 49.2773897, lng: 18.6745921 },
    { lat: 49.2774093, lng: 18.6745564 },
    { lat: 49.2774061, lng: 18.6743892 },
    { lat: 49.2777768, lng: 18.6743833 },
    { lat: 49.2778124, lng: 18.6743099 },
    { lat: 49.2777684, lng: 18.6742868 },
    { lat: 49.2777383, lng: 18.674254 },
    { lat: 49.2776902, lng: 18.6741743 },
    { lat: 49.2776593, lng: 18.6740597 },
    { lat: 49.2776306, lng: 18.6739852 },
    { lat: 49.2775889, lng: 18.6739441 },
    { lat: 49.2775328, lng: 18.6737986 },
    { lat: 49.2776282, lng: 18.6736803 },
    { lat: 49.2777075, lng: 18.6735622 },
    { lat: 49.2777508, lng: 18.6734427 },
    { lat: 49.2777749, lng: 18.6734061 },
    { lat: 49.2778829, lng: 18.6733571 },
    { lat: 49.2779569, lng: 18.6731789 },
    { lat: 49.2780822, lng: 18.6729482 },
    { lat: 49.2781124, lng: 18.6728567 },
    { lat: 49.2780987, lng: 18.6728129 },
    { lat: 49.2780817, lng: 18.6724462 },
    { lat: 49.2781688, lng: 18.6723864 },
    { lat: 49.2783764, lng: 18.672411 },
    { lat: 49.2784624, lng: 18.6723957 },
    { lat: 49.2784855, lng: 18.6723419 },
    { lat: 49.2784882, lng: 18.6722632 },
    { lat: 49.2785005, lng: 18.6721338 },
    { lat: 49.2786734, lng: 18.6721638 },
    { lat: 49.278776, lng: 18.6721417 },
    { lat: 49.279025, lng: 18.6720955 },
    { lat: 49.279161, lng: 18.6720448 },
    { lat: 49.2792113, lng: 18.6720343 },
    { lat: 49.2793475, lng: 18.671964 },
    { lat: 49.2795186, lng: 18.6718941 },
    { lat: 49.279742, lng: 18.6717694 },
    { lat: 49.2798259, lng: 18.6717111 },
    { lat: 49.2798742, lng: 18.6716459 },
    { lat: 49.27997, lng: 18.6714105 },
    { lat: 49.2800016, lng: 18.6713033 },
    { lat: 49.2800207, lng: 18.6712392 },
    { lat: 49.2800726, lng: 18.6710456 },
    { lat: 49.2800829, lng: 18.6710065 },
    { lat: 49.2801517, lng: 18.67073 },
    { lat: 49.2802465, lng: 18.6706457 },
    { lat: 49.2803577, lng: 18.6705323 },
    { lat: 49.2803927, lng: 18.6704871 },
    { lat: 49.2804945, lng: 18.6702586 },
    { lat: 49.2807209, lng: 18.6695693 },
    { lat: 49.2807562, lng: 18.6694685 },
    { lat: 49.280751, lng: 18.6693698 },
    { lat: 49.2807273, lng: 18.669221 },
    { lat: 49.2806841, lng: 18.6687485 },
    { lat: 49.2804626, lng: 18.6682044 },
    { lat: 49.2803477, lng: 18.6677793 },
    { lat: 49.2803881, lng: 18.6674746 },
    { lat: 49.2804087, lng: 18.6672701 },
    { lat: 49.2804295, lng: 18.6668316 },
    { lat: 49.2803698, lng: 18.6663401 },
    { lat: 49.2803541, lng: 18.6659989 },
    { lat: 49.2802921, lng: 18.6658278 },
    { lat: 49.2802392, lng: 18.6657465 },
    { lat: 49.280161, lng: 18.6656543 },
    { lat: 49.2799317, lng: 18.6655187 },
    { lat: 49.2794579, lng: 18.6654915 },
    { lat: 49.2792554, lng: 18.6654602 },
    { lat: 49.2790349, lng: 18.6654693 },
    { lat: 49.2787435, lng: 18.6655653 },
    { lat: 49.2786529, lng: 18.665623 },
    { lat: 49.2784385, lng: 18.6658977 },
    { lat: 49.2780137, lng: 18.6663737 },
    { lat: 49.2778394, lng: 18.6663624 },
    { lat: 49.2777328, lng: 18.66633 },
    { lat: 49.2776581, lng: 18.6658553 },
    { lat: 49.2775192, lng: 18.6651287 },
    { lat: 49.2774621, lng: 18.6647085 },
    { lat: 49.2774312, lng: 18.6645086 },
    { lat: 49.2773761, lng: 18.6643164 },
    { lat: 49.2773509, lng: 18.6641617 },
    { lat: 49.2772574, lng: 18.6640338 },
    { lat: 49.2771712, lng: 18.663878 },
    { lat: 49.2770914, lng: 18.6637939 },
    { lat: 49.2770358, lng: 18.6637253 },
    { lat: 49.277014, lng: 18.6636984 },
    { lat: 49.2773027, lng: 18.6632939 },
    { lat: 49.2775664, lng: 18.6630974 },
    { lat: 49.2775838, lng: 18.6630649 },
    { lat: 49.2776145, lng: 18.6630055 },
    { lat: 49.2776926, lng: 18.6628597 },
    { lat: 49.2778579, lng: 18.662702 },
    { lat: 49.2784975, lng: 18.6625353 },
    { lat: 49.2785138, lng: 18.6625441 },
    { lat: 49.2785683, lng: 18.6625728 },
    { lat: 49.2787249, lng: 18.662656 },
    { lat: 49.2788663, lng: 18.6626844 },
    { lat: 49.279076, lng: 18.6627683 },
    { lat: 49.2792023, lng: 18.6627921 },
    { lat: 49.279382, lng: 18.6627992 },
    { lat: 49.2796985, lng: 18.6626611 },
    { lat: 49.2797103, lng: 18.6626069 },
    { lat: 49.2798849, lng: 18.662455 },
    { lat: 49.2800441, lng: 18.6624581 },
    { lat: 49.2801128, lng: 18.6625009 },
    { lat: 49.2802278, lng: 18.6625856 },
    { lat: 49.280307, lng: 18.6624195 },
    { lat: 49.2804549, lng: 18.6625724 },
    { lat: 49.2805528, lng: 18.6623761 },
    { lat: 49.2806206, lng: 18.6622731 },
    { lat: 49.2806827, lng: 18.6622726 },
    { lat: 49.2807993, lng: 18.6622384 },
    { lat: 49.2810176, lng: 18.6621442 },
    { lat: 49.2810755, lng: 18.6622379 },
    { lat: 49.2811279, lng: 18.6622502 },
    { lat: 49.2811706, lng: 18.6622037 },
    { lat: 49.2812182, lng: 18.6621209 },
    { lat: 49.2813192, lng: 18.6621539 },
    { lat: 49.2814659, lng: 18.6622203 },
    { lat: 49.2815945, lng: 18.6620726 },
    { lat: 49.2816953, lng: 18.6619334 },
    { lat: 49.2817929, lng: 18.6618563 },
    { lat: 49.2818355, lng: 18.6617864 },
    { lat: 49.2818904, lng: 18.6617388 },
    { lat: 49.2819076, lng: 18.6617031 },
    { lat: 49.2819266, lng: 18.6615292 },
    { lat: 49.2820149, lng: 18.6615157 },
    { lat: 49.2820614, lng: 18.6614952 },
    { lat: 49.2820971, lng: 18.6614257 },
    { lat: 49.2821489, lng: 18.6612745 },
    { lat: 49.2822393, lng: 18.6613208 },
    { lat: 49.2825423, lng: 18.6613357 },
    { lat: 49.2826774, lng: 18.6612049 },
    { lat: 49.282808, lng: 18.6611353 },
    { lat: 49.2828343, lng: 18.6610172 },
    { lat: 49.2828777, lng: 18.6609847 },
    { lat: 49.2828819, lng: 18.6609112 },
    { lat: 49.2830698, lng: 18.6607959 },
    { lat: 49.2831415, lng: 18.6607448 },
    { lat: 49.2831704, lng: 18.6605266 },
    { lat: 49.2832568, lng: 18.6604943 },
    { lat: 49.2832891, lng: 18.6605109 },
    { lat: 49.2833606, lng: 18.6603926 },
    { lat: 49.2833778, lng: 18.6601185 },
    { lat: 49.2833683, lng: 18.6600456 },
    { lat: 49.2834006, lng: 18.6598039 },
    { lat: 49.2834189, lng: 18.6595499 },
    { lat: 49.2834242, lng: 18.6594401 },
    { lat: 49.2834723, lng: 18.659198 },
    { lat: 49.2834864, lng: 18.6591019 },
    { lat: 49.2835189, lng: 18.6589697 },
    { lat: 49.2836673, lng: 18.6587238 },
    { lat: 49.2838424, lng: 18.6584565 },
    { lat: 49.2839002, lng: 18.6581607 },
    { lat: 49.2840073, lng: 18.6580142 },
    { lat: 49.2840845, lng: 18.6578739 },
    { lat: 49.2841571, lng: 18.6577547 },
    { lat: 49.2842479, lng: 18.6575268 },
    { lat: 49.2843786, lng: 18.6573578 },
    { lat: 49.2844167, lng: 18.6570956 },
    { lat: 49.2844749, lng: 18.6570024 },
    { lat: 49.2844735, lng: 18.6568061 },
    { lat: 49.2844781, lng: 18.6567235 },
    { lat: 49.2845249, lng: 18.6565808 },
    { lat: 49.2845361, lng: 18.6564129 },
    { lat: 49.2845552, lng: 18.6563224 },
    { lat: 49.2846127, lng: 18.656195 },
    { lat: 49.2845106, lng: 18.656057 },
    { lat: 49.2842117, lng: 18.6558205 },
    { lat: 49.2842704, lng: 18.6554198 },
    { lat: 49.284418, lng: 18.6547976 },
    { lat: 49.2845117, lng: 18.6548221 },
    { lat: 49.2845575, lng: 18.6547503 },
    { lat: 49.2846846, lng: 18.6546154 },
    { lat: 49.2847296, lng: 18.6545907 },
    { lat: 49.2848558, lng: 18.6546304 },
    { lat: 49.2849286, lng: 18.6545773 },
    { lat: 49.2849864, lng: 18.6543707 },
    { lat: 49.2849471, lng: 18.6542716 },
    { lat: 49.2849161, lng: 18.6541313 },
    { lat: 49.2848927, lng: 18.6540067 },
    { lat: 49.2848935, lng: 18.6538736 },
    { lat: 49.2849172, lng: 18.6536846 },
    { lat: 49.2849509, lng: 18.6534681 },
    { lat: 49.2849761, lng: 18.6531984 },
    { lat: 49.2849696, lng: 18.6530135 },
    { lat: 49.2849966, lng: 18.652948 },
    { lat: 49.2851747, lng: 18.6527096 },
    { lat: 49.2852478, lng: 18.6524072 },
    { lat: 49.2852809, lng: 18.651586 },
    { lat: 49.2853705, lng: 18.6514441 },
    { lat: 49.2854804, lng: 18.6512221 },
    { lat: 49.2856751, lng: 18.6508516 },
    { lat: 49.2857628, lng: 18.6506551 },
    { lat: 49.286048, lng: 18.6501418 },
    { lat: 49.2861701, lng: 18.6499118 },
    { lat: 49.2862991, lng: 18.649773 },
    { lat: 49.2864561, lng: 18.6496726 },
    { lat: 49.2865042, lng: 18.649662 },
  ],
  Rosina: [
    { lat: 49.1964184, lng: 18.7496925 },
    { lat: 49.1962081, lng: 18.7496383 },
    { lat: 49.1960764, lng: 18.7496414 },
    { lat: 49.1958499, lng: 18.7496476 },
    { lat: 49.1955265, lng: 18.7495409 },
    { lat: 49.1949757, lng: 18.7492733 },
    { lat: 49.1947393, lng: 18.7491311 },
    { lat: 49.1944703, lng: 18.7490293 },
    { lat: 49.193687, lng: 18.7489082 },
    { lat: 49.1932753, lng: 18.7487974 },
    { lat: 49.1925389, lng: 18.7489266 },
    { lat: 49.1924091, lng: 18.7489727 },
    { lat: 49.1913863, lng: 18.7493356 },
    { lat: 49.1905505, lng: 18.7496322 },
    { lat: 49.190367, lng: 18.7495936 },
    { lat: 49.1896961, lng: 18.749488 },
    { lat: 49.1890909, lng: 18.7492977 },
    { lat: 49.1878134, lng: 18.7488938 },
    { lat: 49.1859404, lng: 18.7487598 },
    { lat: 49.1859505, lng: 18.7488491 },
    { lat: 49.1860992, lng: 18.7502888 },
    { lat: 49.1848877, lng: 18.7504785 },
    { lat: 49.1840357, lng: 18.7505694 },
    { lat: 49.1836406, lng: 18.7505875 },
    { lat: 49.1833866, lng: 18.7504929 },
    { lat: 49.1832653, lng: 18.7503739 },
    { lat: 49.1831749, lng: 18.7502861 },
    { lat: 49.1831058, lng: 18.7503879 },
    { lat: 49.1828635, lng: 18.7506019 },
    { lat: 49.1825651, lng: 18.750654 },
    { lat: 49.1825138, lng: 18.7506894 },
    { lat: 49.1823898, lng: 18.7507762 },
    { lat: 49.1824589, lng: 18.7511712 },
    { lat: 49.1824398, lng: 18.7511904 },
    { lat: 49.1824128, lng: 18.7512161 },
    { lat: 49.1823462, lng: 18.7512807 },
    { lat: 49.1822444, lng: 18.75138 },
    { lat: 49.1822044, lng: 18.7513928 },
    { lat: 49.1818669, lng: 18.751495 },
    { lat: 49.181681, lng: 18.7515371 },
    { lat: 49.1815496, lng: 18.7515505 },
    { lat: 49.1813916, lng: 18.7518285 },
    { lat: 49.1812724, lng: 18.7518981 },
    { lat: 49.1811313, lng: 18.7518976 },
    { lat: 49.1809487, lng: 18.7519165 },
    { lat: 49.1807868, lng: 18.751907 },
    { lat: 49.1805159, lng: 18.7518145 },
    { lat: 49.1802406, lng: 18.7520281 },
    { lat: 49.1800781, lng: 18.7521773 },
    { lat: 49.1799618, lng: 18.7524541 },
    { lat: 49.1790684, lng: 18.7528391 },
    { lat: 49.1788829, lng: 18.7530015 },
    { lat: 49.178684, lng: 18.7534224 },
    { lat: 49.1787312, lng: 18.7536798 },
    { lat: 49.1786743, lng: 18.7537985 },
    { lat: 49.178487, lng: 18.7538828 },
    { lat: 49.1783314, lng: 18.7538841 },
    { lat: 49.1774846, lng: 18.7535598 },
    { lat: 49.1770977, lng: 18.7533627 },
    { lat: 49.1767432, lng: 18.7532263 },
    { lat: 49.1762469, lng: 18.7529434 },
    { lat: 49.1758188, lng: 18.7526696 },
    { lat: 49.17546, lng: 18.7523863 },
    { lat: 49.1751648, lng: 18.7521079 },
    { lat: 49.1749869, lng: 18.751941 },
    { lat: 49.1748585, lng: 18.7518135 },
    { lat: 49.1744977, lng: 18.751338 },
    { lat: 49.1741289, lng: 18.7510134 },
    { lat: 49.1736687, lng: 18.7505816 },
    { lat: 49.1734826, lng: 18.7504702 },
    { lat: 49.1731758, lng: 18.7502335 },
    { lat: 49.1728022, lng: 18.750355 },
    { lat: 49.1721034, lng: 18.7497778 },
    { lat: 49.1717115, lng: 18.7493331 },
    { lat: 49.1714268, lng: 18.7487183 },
    { lat: 49.1711367, lng: 18.7483669 },
    { lat: 49.1709243, lng: 18.7484266 },
    { lat: 49.1707403, lng: 18.7485715 },
    { lat: 49.1702712, lng: 18.7492627 },
    { lat: 49.1696883, lng: 18.7493267 },
    { lat: 49.169361, lng: 18.7493224 },
    { lat: 49.1692352, lng: 18.7490307 },
    { lat: 49.1688011, lng: 18.7489667 },
    { lat: 49.1686628, lng: 18.7493179 },
    { lat: 49.1683606, lng: 18.749427 },
    { lat: 49.1681556, lng: 18.7494519 },
    { lat: 49.1679905, lng: 18.7493815 },
    { lat: 49.1677994, lng: 18.7493507 },
    { lat: 49.1675665, lng: 18.7492939 },
    { lat: 49.1675192, lng: 18.7492748 },
    { lat: 49.1671577, lng: 18.7498002 },
    { lat: 49.1663729, lng: 18.7499975 },
    { lat: 49.1659949, lng: 18.7502924 },
    { lat: 49.1654851, lng: 18.7500739 },
    { lat: 49.1651015, lng: 18.7498315 },
    { lat: 49.1646686, lng: 18.7498124 },
    { lat: 49.1640853, lng: 18.751277 },
    { lat: 49.1636824, lng: 18.7529967 },
    { lat: 49.1636756, lng: 18.7530202 },
    { lat: 49.1636913, lng: 18.7530172 },
    { lat: 49.1638465, lng: 18.7530521 },
    { lat: 49.1643871, lng: 18.7530376 },
    { lat: 49.1648426, lng: 18.7530584 },
    { lat: 49.1651463, lng: 18.7531509 },
    { lat: 49.16554, lng: 18.7534595 },
    { lat: 49.1658072, lng: 18.7534984 },
    { lat: 49.1661683, lng: 18.7534635 },
    { lat: 49.1664306, lng: 18.7534962 },
    { lat: 49.1668333, lng: 18.7534794 },
    { lat: 49.1672448, lng: 18.7534766 },
    { lat: 49.1675721, lng: 18.75346 },
    { lat: 49.1680673, lng: 18.7534626 },
    { lat: 49.1684706, lng: 18.7534576 },
    { lat: 49.1691592, lng: 18.7539689 },
    { lat: 49.17, lng: 18.7546085 },
    { lat: 49.1702181, lng: 18.7551168 },
    { lat: 49.1703998, lng: 18.7555059 },
    { lat: 49.1706289, lng: 18.7560064 },
    { lat: 49.170985, lng: 18.7566944 },
    { lat: 49.1711963, lng: 18.7572209 },
    { lat: 49.1715243, lng: 18.7578651 },
    { lat: 49.1716806, lng: 18.7582469 },
    { lat: 49.1716988, lng: 18.7582937 },
    { lat: 49.1714432, lng: 18.7582349 },
    { lat: 49.1714319, lng: 18.7583862 },
    { lat: 49.171325, lng: 18.7583711 },
    { lat: 49.1712615, lng: 18.7581262 },
    { lat: 49.1709528, lng: 18.7580667 },
    { lat: 49.1706987, lng: 18.7579728 },
    { lat: 49.1706312, lng: 18.7578671 },
    { lat: 49.1705512, lng: 18.7578689 },
    { lat: 49.1703494, lng: 18.7580631 },
    { lat: 49.1702993, lng: 18.7582039 },
    { lat: 49.1701875, lng: 18.7582247 },
    { lat: 49.1700654, lng: 18.758348 },
    { lat: 49.1699795, lng: 18.758669 },
    { lat: 49.1697232, lng: 18.7588551 },
    { lat: 49.1695564, lng: 18.7588566 },
    { lat: 49.1693563, lng: 18.7588693 },
    { lat: 49.1691369, lng: 18.7589864 },
    { lat: 49.1690763, lng: 18.7588618 },
    { lat: 49.1689299, lng: 18.7590173 },
    { lat: 49.1688113, lng: 18.7589491 },
    { lat: 49.1685805, lng: 18.7588474 },
    { lat: 49.1682714, lng: 18.7588683 },
    { lat: 49.1681302, lng: 18.7587975 },
    { lat: 49.167973, lng: 18.7588335 },
    { lat: 49.1678109, lng: 18.7587915 },
    { lat: 49.1676417, lng: 18.7589641 },
    { lat: 49.167494, lng: 18.7589453 },
    { lat: 49.1671344, lng: 18.758728 },
    { lat: 49.1668687, lng: 18.7588453 },
    { lat: 49.1667755, lng: 18.7587885 },
    { lat: 49.1667868, lng: 18.7589406 },
    { lat: 49.1666659, lng: 18.7589563 },
    { lat: 49.1665479, lng: 18.7590319 },
    { lat: 49.1663997, lng: 18.7589757 },
    { lat: 49.166302, lng: 18.7590506 },
    { lat: 49.1659753, lng: 18.759012 },
    { lat: 49.1657705, lng: 18.7590869 },
    { lat: 49.1657274, lng: 18.7591473 },
    { lat: 49.16556, lng: 18.7592 },
    { lat: 49.1655122, lng: 18.7591719 },
    { lat: 49.1653775, lng: 18.7592257 },
    { lat: 49.1652168, lng: 18.7592575 },
    { lat: 49.1650822, lng: 18.7592716 },
    { lat: 49.1650286, lng: 18.7593462 },
    { lat: 49.164879, lng: 18.7592678 },
    { lat: 49.1646154, lng: 18.7592745 },
    { lat: 49.1644312, lng: 18.7593077 },
    { lat: 49.1643406, lng: 18.7593879 },
    { lat: 49.1642124, lng: 18.7593303 },
    { lat: 49.164044, lng: 18.7591284 },
    { lat: 49.1639855, lng: 18.7589762 },
    { lat: 49.1637892, lng: 18.7588051 },
    { lat: 49.163785, lng: 18.7588524 },
    { lat: 49.1635731, lng: 18.7605204 },
    { lat: 49.1634119, lng: 18.7622958 },
    { lat: 49.1632572, lng: 18.7640646 },
    { lat: 49.1631729, lng: 18.7648279 },
    { lat: 49.1631669, lng: 18.7648628 },
    { lat: 49.1627943, lng: 18.764722 },
    { lat: 49.1623392, lng: 18.7643635 },
    { lat: 49.1617648, lng: 18.7639017 },
    { lat: 49.1613418, lng: 18.7636391 },
    { lat: 49.1606808, lng: 18.7632007 },
    { lat: 49.1605231, lng: 18.7631179 },
    { lat: 49.160414, lng: 18.7630608 },
    { lat: 49.1601126, lng: 18.7628581 },
    { lat: 49.1597651, lng: 18.7626614 },
    { lat: 49.1593892, lng: 18.7624342 },
    { lat: 49.1588887, lng: 18.762243 },
    { lat: 49.1584936, lng: 18.7621925 },
    { lat: 49.1583756, lng: 18.7621773 },
    { lat: 49.1582688, lng: 18.7621639 },
    { lat: 49.1582685, lng: 18.7621989 },
    { lat: 49.15828, lng: 18.7622707 },
    { lat: 49.1583576, lng: 18.7627837 },
    { lat: 49.1583873, lng: 18.7629865 },
    { lat: 49.1583973, lng: 18.7630483 },
    { lat: 49.1585041, lng: 18.7637542 },
    { lat: 49.1587596, lng: 18.7654785 },
    { lat: 49.1587638, lng: 18.7654953 },
    { lat: 49.1588568, lng: 18.7658075 },
    { lat: 49.1588984, lng: 18.7659344 },
    { lat: 49.1589759, lng: 18.766148 },
    { lat: 49.1590763, lng: 18.7663837 },
    { lat: 49.1592258, lng: 18.7667443 },
    { lat: 49.1592999, lng: 18.7669749 },
    { lat: 49.1594068, lng: 18.7672527 },
    { lat: 49.1594999, lng: 18.7675026 },
    { lat: 49.1597041, lng: 18.7680709 },
    { lat: 49.1598141, lng: 18.7683628 },
    { lat: 49.1599002, lng: 18.7685422 },
    { lat: 49.1599204, lng: 18.7685852 },
    { lat: 49.1599413, lng: 18.768636 },
    { lat: 49.1601659, lng: 18.7684488 },
    { lat: 49.1602808, lng: 18.7683596 },
    { lat: 49.1603889, lng: 18.7682701 },
    { lat: 49.1605166, lng: 18.7681682 },
    { lat: 49.1605984, lng: 18.768114 },
    { lat: 49.1606799, lng: 18.7680547 },
    { lat: 49.1607652, lng: 18.7679821 },
    { lat: 49.160873, lng: 18.767907 },
    { lat: 49.1610076, lng: 18.7678277 },
    { lat: 49.1610952, lng: 18.7677814 },
    { lat: 49.161198, lng: 18.7677178 },
    { lat: 49.1612822, lng: 18.7676687 },
    { lat: 49.161366, lng: 18.7676092 },
    { lat: 49.1615293, lng: 18.7674774 },
    { lat: 49.1616775, lng: 18.7673971 },
    { lat: 49.1617524, lng: 18.767388 },
    { lat: 49.1618219, lng: 18.76742 },
    { lat: 49.1619524, lng: 18.7675029 },
    { lat: 49.1620447, lng: 18.7675645 },
    { lat: 49.1621394, lng: 18.7676272 },
    { lat: 49.1622532, lng: 18.7677125 },
    { lat: 49.1623085, lng: 18.7677577 },
    { lat: 49.1623988, lng: 18.7678254 },
    { lat: 49.1625576, lng: 18.7679484 },
    { lat: 49.1628129, lng: 18.7681092 },
    { lat: 49.162982, lng: 18.7681983 },
    { lat: 49.1631876, lng: 18.7683121 },
    { lat: 49.1633465, lng: 18.7683963 },
    { lat: 49.1634809, lng: 18.7684647 },
    { lat: 49.1636533, lng: 18.768549 },
    { lat: 49.1637926, lng: 18.7686053 },
    { lat: 49.1639842, lng: 18.7686678 },
    { lat: 49.1640983, lng: 18.7686731 },
    { lat: 49.1640714, lng: 18.7685906 },
    { lat: 49.1640355, lng: 18.7684649 },
    { lat: 49.163948, lng: 18.7681912 },
    { lat: 49.1638913, lng: 18.7679228 },
    { lat: 49.1638071, lng: 18.7676037 },
    { lat: 49.1638199, lng: 18.7675913 },
    { lat: 49.1638522, lng: 18.7675629 },
    { lat: 49.1638873, lng: 18.7675428 },
    { lat: 49.1639004, lng: 18.7675354 },
    { lat: 49.1639133, lng: 18.7675252 },
    { lat: 49.1639721, lng: 18.7675051 },
    { lat: 49.1640763, lng: 18.767428 },
    { lat: 49.1641689, lng: 18.7673642 },
    { lat: 49.1643321, lng: 18.7672731 },
    { lat: 49.164653, lng: 18.7671135 },
    { lat: 49.1646137, lng: 18.7670087 },
    { lat: 49.1647984, lng: 18.7669161 },
    { lat: 49.1649341, lng: 18.7668586 },
    { lat: 49.1651044, lng: 18.7668387 },
    { lat: 49.1652107, lng: 18.76682 },
    { lat: 49.1654026, lng: 18.7668033 },
    { lat: 49.165761, lng: 18.7668024 },
    { lat: 49.1658394, lng: 18.7668091 },
    { lat: 49.1659553, lng: 18.7668708 },
    { lat: 49.1661151, lng: 18.7669321 },
    { lat: 49.1663098, lng: 18.7669903 },
    { lat: 49.1663636, lng: 18.7670033 },
    { lat: 49.1664707, lng: 18.7670483 },
    { lat: 49.1666342, lng: 18.7670678 },
    { lat: 49.1667696, lng: 18.7670709 },
    { lat: 49.1668068, lng: 18.7670692 },
    { lat: 49.1668553, lng: 18.7670709 },
    { lat: 49.1669156, lng: 18.7670806 },
    { lat: 49.1670284, lng: 18.7671047 },
    { lat: 49.167074, lng: 18.7671143 },
    { lat: 49.1671686, lng: 18.7671746 },
    { lat: 49.167199, lng: 18.7671943 },
    { lat: 49.167281, lng: 18.7672139 },
    { lat: 49.1673016, lng: 18.7672194 },
    { lat: 49.1673976, lng: 18.7672427 },
    { lat: 49.1674414, lng: 18.7672406 },
    { lat: 49.1675664, lng: 18.7672349 },
    { lat: 49.1676034, lng: 18.7672333 },
    { lat: 49.1676873, lng: 18.7672202 },
    { lat: 49.1677668, lng: 18.7672081 },
    { lat: 49.1677872, lng: 18.7672096 },
    { lat: 49.1678789, lng: 18.7672161 },
    { lat: 49.1679444, lng: 18.7672204 },
    { lat: 49.1679698, lng: 18.7672102 },
    { lat: 49.1679798, lng: 18.7672061 },
    { lat: 49.1680471, lng: 18.7671774 },
    { lat: 49.1680582, lng: 18.7671732 },
    { lat: 49.1680813, lng: 18.7671635 },
    { lat: 49.1681019, lng: 18.7671455 },
    { lat: 49.1681364, lng: 18.7671143 },
    { lat: 49.1681474, lng: 18.7670672 },
    { lat: 49.1681547, lng: 18.7670346 },
    { lat: 49.1681961, lng: 18.7668548 },
    { lat: 49.1682931, lng: 18.7668571 },
    { lat: 49.1684445, lng: 18.7668608 },
    { lat: 49.1685822, lng: 18.7668639 },
    { lat: 49.1686262, lng: 18.7668651 },
    { lat: 49.1686682, lng: 18.7669364 },
    { lat: 49.1687263, lng: 18.7670357 },
    { lat: 49.1687858, lng: 18.7671348 },
    { lat: 49.1688462, lng: 18.7672363 },
    { lat: 49.1689055, lng: 18.7673372 },
    { lat: 49.1689651, lng: 18.7674374 },
    { lat: 49.1690157, lng: 18.7675238 },
    { lat: 49.1690193, lng: 18.7675303 },
    { lat: 49.1690775, lng: 18.7676266 },
    { lat: 49.1691281, lng: 18.7677138 },
    { lat: 49.1691319, lng: 18.7677184 },
    { lat: 49.1691913, lng: 18.767819 },
    { lat: 49.1692568, lng: 18.76793 },
    { lat: 49.1693285, lng: 18.7680496 },
    { lat: 49.1693482, lng: 18.7680836 },
    { lat: 49.16939, lng: 18.7681272 },
    { lat: 49.1694662, lng: 18.7682066 },
    { lat: 49.1695354, lng: 18.7682786 },
    { lat: 49.1695426, lng: 18.7682853 },
    { lat: 49.1696153, lng: 18.7683608 },
    { lat: 49.1696272, lng: 18.7683727 },
    { lat: 49.1696892, lng: 18.7684369 },
    { lat: 49.1697416, lng: 18.7684907 },
    { lat: 49.1698595, lng: 18.7686134 },
    { lat: 49.170006, lng: 18.7687639 },
    { lat: 49.1701359, lng: 18.7688977 },
    { lat: 49.170236, lng: 18.7690013 },
    { lat: 49.1702464, lng: 18.7690281 },
    { lat: 49.170366, lng: 18.7693517 },
    { lat: 49.1703722, lng: 18.7693657 },
    { lat: 49.1704861, lng: 18.7696474 },
    { lat: 49.1705364, lng: 18.7697703 },
    { lat: 49.1705871, lng: 18.7698943 },
    { lat: 49.1706155, lng: 18.7699644 },
    { lat: 49.1706647, lng: 18.7700863 },
    { lat: 49.1707825, lng: 18.7703805 },
    { lat: 49.1708474, lng: 18.7705413 },
    { lat: 49.17087, lng: 18.7705855 },
    { lat: 49.1710163, lng: 18.7708867 },
    { lat: 49.1711234, lng: 18.7711 },
    { lat: 49.1711611, lng: 18.7711755 },
    { lat: 49.1711951, lng: 18.7712438 },
    { lat: 49.1712028, lng: 18.7712601 },
    { lat: 49.1712179, lng: 18.7712922 },
    { lat: 49.1712125, lng: 18.7712957 },
    { lat: 49.1712429, lng: 18.7713821 },
    { lat: 49.1712484, lng: 18.7713782 },
    { lat: 49.1712593, lng: 18.7714151 },
    { lat: 49.1713315, lng: 18.7716759 },
    { lat: 49.1713703, lng: 18.7718139 },
    { lat: 49.1715724, lng: 18.7719733 },
    { lat: 49.1718916, lng: 18.7721916 },
    { lat: 49.1720375, lng: 18.7724795 },
    { lat: 49.172051, lng: 18.7725027 },
    { lat: 49.1723291, lng: 18.7730565 },
    { lat: 49.1724115, lng: 18.773279 },
    { lat: 49.1724491, lng: 18.7735018 },
    { lat: 49.1724917, lng: 18.773648 },
    { lat: 49.1725585, lng: 18.7738691 },
    { lat: 49.172591, lng: 18.7739524 },
    { lat: 49.1726192, lng: 18.7740813 },
    { lat: 49.1726374, lng: 18.7741731 },
    { lat: 49.1726798, lng: 18.7743788 },
    { lat: 49.1726904, lng: 18.7744341 },
    { lat: 49.1727299, lng: 18.7745843 },
    { lat: 49.1727558, lng: 18.7746922 },
    { lat: 49.1728057, lng: 18.7748926 },
    { lat: 49.1728219, lng: 18.7749213 },
    { lat: 49.1728412, lng: 18.7750332 },
    { lat: 49.1728662, lng: 18.7751015 },
    { lat: 49.1728745, lng: 18.7751528 },
    { lat: 49.1728968, lng: 18.7752359 },
    { lat: 49.1729193, lng: 18.7753002 },
    { lat: 49.1729544, lng: 18.7754116 },
    { lat: 49.1730051, lng: 18.7755831 },
    { lat: 49.1730564, lng: 18.7757456 },
    { lat: 49.1731134, lng: 18.7759517 },
    { lat: 49.1732414, lng: 18.7763323 },
    { lat: 49.1732992, lng: 18.7765984 },
    { lat: 49.1733046, lng: 18.7766162 },
    { lat: 49.1733637, lng: 18.7767989 },
    { lat: 49.1733798, lng: 18.7768492 },
    { lat: 49.1737336, lng: 18.7777543 },
    { lat: 49.1737779, lng: 18.7778671 },
    { lat: 49.1738432, lng: 18.7783234 },
    { lat: 49.1738586, lng: 18.7786199 },
    { lat: 49.1739253, lng: 18.7798166 },
    { lat: 49.1739406, lng: 18.7800887 },
    { lat: 49.1739701, lng: 18.7806102 },
    { lat: 49.1741547, lng: 18.7813443 },
    { lat: 49.1741736, lng: 18.7814022 },
    { lat: 49.174187, lng: 18.7814647 },
    { lat: 49.1743448, lng: 18.7822004 },
    { lat: 49.1745291, lng: 18.7829742 },
    { lat: 49.1748135, lng: 18.7841291 },
    { lat: 49.1748873, lng: 18.7845718 },
    { lat: 49.1749927, lng: 18.7851915 },
    { lat: 49.1751013, lng: 18.7855442 },
    { lat: 49.1751079, lng: 18.7855622 },
    { lat: 49.17515, lng: 18.7856987 },
    { lat: 49.1754092, lng: 18.7861781 },
    { lat: 49.1756976, lng: 18.7867127 },
    { lat: 49.1761286, lng: 18.788024 },
    { lat: 49.1762735, lng: 18.7889685 },
    { lat: 49.1762872, lng: 18.7891452 },
    { lat: 49.1763369, lng: 18.7900171 },
    { lat: 49.1763394, lng: 18.7900428 },
    { lat: 49.1763395, lng: 18.7900442 },
    { lat: 49.1765291, lng: 18.7905255 },
    { lat: 49.1766532, lng: 18.7908306 },
    { lat: 49.1767978, lng: 18.7910777 },
    { lat: 49.1769225, lng: 18.791263 },
    { lat: 49.1770052, lng: 18.7914041 },
    { lat: 49.1771267, lng: 18.791615 },
    { lat: 49.1774292, lng: 18.7919025 },
    { lat: 49.1775392, lng: 18.792023 },
    { lat: 49.1777998, lng: 18.7922881 },
    { lat: 49.1778206, lng: 18.7923195 },
    { lat: 49.1779611, lng: 18.7921353 },
    { lat: 49.1780805, lng: 18.7918506 },
    { lat: 49.1781722, lng: 18.7916193 },
    { lat: 49.1782776, lng: 18.7913915 },
    { lat: 49.1784506, lng: 18.7910736 },
    { lat: 49.1786691, lng: 18.7903924 },
    { lat: 49.1787754, lng: 18.7902912 },
    { lat: 49.1789441, lng: 18.7900233 },
    { lat: 49.1791161, lng: 18.7898173 },
    { lat: 49.1793016, lng: 18.7896774 },
    { lat: 49.1793732, lng: 18.7897729 },
    { lat: 49.1794864, lng: 18.7896327 },
    { lat: 49.1800264, lng: 18.7890648 },
    { lat: 49.1804291, lng: 18.7886972 },
    { lat: 49.1808987, lng: 18.7881204 },
    { lat: 49.1807219, lng: 18.7874724 },
    { lat: 49.1804791, lng: 18.7869193 },
    { lat: 49.1803378, lng: 18.7863038 },
    { lat: 49.1799584, lng: 18.7855141 },
    { lat: 49.1798737, lng: 18.78462 },
    { lat: 49.1798053, lng: 18.7839107 },
    { lat: 49.1798743, lng: 18.7827018 },
    { lat: 49.180227, lng: 18.7828044 },
    { lat: 49.1805787, lng: 18.7828661 },
    { lat: 49.1809949, lng: 18.7829539 },
    { lat: 49.1818487, lng: 18.7830889 },
    { lat: 49.1824404, lng: 18.7832157 },
    { lat: 49.1830335, lng: 18.7832817 },
    { lat: 49.1839265, lng: 18.7831908 },
    { lat: 49.1847444, lng: 18.7830487 },
    { lat: 49.1856177, lng: 18.7829071 },
    { lat: 49.1858391, lng: 18.7829806 },
    { lat: 49.1860974, lng: 18.7831527 },
    { lat: 49.1862117, lng: 18.7832293 },
    { lat: 49.1866022, lng: 18.7834741 },
    { lat: 49.1868837, lng: 18.7836391 },
    { lat: 49.1868828, lng: 18.7834038 },
    { lat: 49.1869539, lng: 18.7831025 },
    { lat: 49.1870625, lng: 18.7826734 },
    { lat: 49.1870896, lng: 18.7824575 },
    { lat: 49.1871879, lng: 18.781376 },
    { lat: 49.1872563, lng: 18.7805866 },
    { lat: 49.1872638, lng: 18.7805158 },
    { lat: 49.1873114, lng: 18.780041 },
    { lat: 49.1873616, lng: 18.7794247 },
    { lat: 49.1873222, lng: 18.7790805 },
    { lat: 49.1873431, lng: 18.7789586 },
    { lat: 49.1875381, lng: 18.7784138 },
    { lat: 49.1881033, lng: 18.7784002 },
    { lat: 49.189287, lng: 18.7788505 },
    { lat: 49.1896953, lng: 18.7787863 },
    { lat: 49.1900462, lng: 18.7787904 },
    { lat: 49.1905778, lng: 18.7787127 },
    { lat: 49.1909784, lng: 18.7787649 },
    { lat: 49.1912548, lng: 18.7787005 },
    { lat: 49.1915479, lng: 18.7787649 },
    { lat: 49.1920164, lng: 18.7788427 },
    { lat: 49.1923781, lng: 18.7789868 },
    { lat: 49.1931109, lng: 18.7793521 },
    { lat: 49.193352, lng: 18.7794357 },
    { lat: 49.1933727, lng: 18.7794019 },
    { lat: 49.1933768, lng: 18.7793942 },
    { lat: 49.1939665, lng: 18.778415 },
    { lat: 49.1940339, lng: 18.7783034 },
    { lat: 49.1941986, lng: 18.7780895 },
    { lat: 49.1946369, lng: 18.7771705 },
    { lat: 49.1947864, lng: 18.7763553 },
    { lat: 49.1948516, lng: 18.7756822 },
    { lat: 49.1948738, lng: 18.7754596 },
    { lat: 49.1948791, lng: 18.775407 },
    { lat: 49.1949795, lng: 18.7736751 },
    { lat: 49.1949827, lng: 18.7736275 },
    { lat: 49.1949889, lng: 18.7735272 },
    { lat: 49.1950196, lng: 18.7734233 },
    { lat: 49.195024, lng: 18.773404 },
    { lat: 49.1951252, lng: 18.7734404 },
    { lat: 49.1951425, lng: 18.7734459 },
    { lat: 49.195405, lng: 18.7735738 },
    { lat: 49.1959124, lng: 18.7738744 },
    { lat: 49.1961239, lng: 18.7737799 },
    { lat: 49.1963262, lng: 18.7738939 },
    { lat: 49.1967806, lng: 18.7741367 },
    { lat: 49.1968692, lng: 18.774082 },
    { lat: 49.1969533, lng: 18.7740304 },
    { lat: 49.1970816, lng: 18.7739575 },
    { lat: 49.197234, lng: 18.7739398 },
    { lat: 49.197521, lng: 18.7739743 },
    { lat: 49.1977372, lng: 18.773775 },
    { lat: 49.1981495, lng: 18.7735694 },
    { lat: 49.1984103, lng: 18.7734285 },
    { lat: 49.1985505, lng: 18.7733467 },
    { lat: 49.198932, lng: 18.7731826 },
    { lat: 49.1991353, lng: 18.7730101 },
    { lat: 49.1993344, lng: 18.7727646 },
    { lat: 49.1995964, lng: 18.7724499 },
    { lat: 49.2000175, lng: 18.7722186 },
    { lat: 49.2002919, lng: 18.7722243 },
    { lat: 49.2005079, lng: 18.7722396 },
    { lat: 49.2005827, lng: 18.7722453 },
    { lat: 49.2006438, lng: 18.7722495 },
    { lat: 49.2008093, lng: 18.7722002 },
    { lat: 49.2008343, lng: 18.7721637 },
    { lat: 49.2008431, lng: 18.7720923 },
    { lat: 49.2008414, lng: 18.7720828 },
    { lat: 49.2008166, lng: 18.7720364 },
    { lat: 49.2007479, lng: 18.7720205 },
    { lat: 49.2006331, lng: 18.7719562 },
    { lat: 49.2004957, lng: 18.7719822 },
    { lat: 49.2004498, lng: 18.7719672 },
    { lat: 49.2003924, lng: 18.7718634 },
    { lat: 49.2003512, lng: 18.7718071 },
    { lat: 49.2001484, lng: 18.7717008 },
    { lat: 49.2000905, lng: 18.7717147 },
    { lat: 49.1999854, lng: 18.7718428 },
    { lat: 49.1998499, lng: 18.7718815 },
    { lat: 49.1997984, lng: 18.7718445 },
    { lat: 49.1996925, lng: 18.7718213 },
    { lat: 49.1995121, lng: 18.7716502 },
    { lat: 49.1994435, lng: 18.7716765 },
    { lat: 49.1994172, lng: 18.7716667 },
    { lat: 49.1992745, lng: 18.7716123 },
    { lat: 49.1991895, lng: 18.7715807 },
    { lat: 49.199146, lng: 18.7715034 },
    { lat: 49.1990811, lng: 18.7714922 },
    { lat: 49.1989821, lng: 18.7714559 },
    { lat: 49.1989015, lng: 18.7715327 },
    { lat: 49.1988745, lng: 18.7716394 },
    { lat: 49.19881, lng: 18.7716801 },
    { lat: 49.1987714, lng: 18.7716519 },
    { lat: 49.1987204, lng: 18.7716473 },
    { lat: 49.1986644, lng: 18.7715895 },
    { lat: 49.1985993, lng: 18.7714651 },
    { lat: 49.1985198, lng: 18.7714527 },
    { lat: 49.198497, lng: 18.7714915 },
    { lat: 49.1984966, lng: 18.7715705 },
    { lat: 49.1984411, lng: 18.7716112 },
    { lat: 49.1984172, lng: 18.7716068 },
    { lat: 49.1982803, lng: 18.7714684 },
    { lat: 49.1981289, lng: 18.7713749 },
    { lat: 49.1980106, lng: 18.7713763 },
    { lat: 49.1979189, lng: 18.7714563 },
    { lat: 49.1978388, lng: 18.7714793 },
    { lat: 49.1977954, lng: 18.7714676 },
    { lat: 49.1977338, lng: 18.7714736 },
    { lat: 49.1976811, lng: 18.7714375 },
    { lat: 49.1975985, lng: 18.7713883 },
    { lat: 49.1975743, lng: 18.7713379 },
    { lat: 49.1975611, lng: 18.7713413 },
    { lat: 49.1974971, lng: 18.7713469 },
    { lat: 49.1974203, lng: 18.7712797 },
    { lat: 49.1974112, lng: 18.7712369 },
    { lat: 49.1973666, lng: 18.7712001 },
    { lat: 49.1973034, lng: 18.7712214 },
    { lat: 49.1972623, lng: 18.7712351 },
    { lat: 49.1972281, lng: 18.7712266 },
    { lat: 49.1970804, lng: 18.771009 },
    { lat: 49.1970434, lng: 18.7709919 },
    { lat: 49.1970159, lng: 18.7709815 },
    { lat: 49.1968037, lng: 18.77085 },
    { lat: 49.1967629, lng: 18.7707792 },
    { lat: 49.1967331, lng: 18.7706653 },
    { lat: 49.1966939, lng: 18.7706015 },
    { lat: 49.1966791, lng: 18.7705582 },
    { lat: 49.1966749, lng: 18.7705169 },
    { lat: 49.1966784, lng: 18.7704797 },
    { lat: 49.1967035, lng: 18.7704403 },
    { lat: 49.1967241, lng: 18.7704461 },
    { lat: 49.1967392, lng: 18.7704129 },
    { lat: 49.1967294, lng: 18.7703755 },
    { lat: 49.1967045, lng: 18.7702862 },
    { lat: 49.1966849, lng: 18.7702558 },
    { lat: 49.1966278, lng: 18.7702847 },
    { lat: 49.1965747, lng: 18.7703257 },
    { lat: 49.196556, lng: 18.7703168 },
    { lat: 49.1964672, lng: 18.7701909 },
    { lat: 49.196382, lng: 18.7700469 },
    { lat: 49.1963592, lng: 18.7698485 },
    { lat: 49.1963738, lng: 18.7696931 },
    { lat: 49.1963682, lng: 18.7696729 },
    { lat: 49.1963614, lng: 18.7696737 },
    { lat: 49.1963434, lng: 18.7696553 },
    { lat: 49.1963464, lng: 18.7696207 },
    { lat: 49.1963691, lng: 18.7696036 },
    { lat: 49.1963961, lng: 18.7696223 },
    { lat: 49.1964194, lng: 18.7696169 },
    { lat: 49.1964318, lng: 18.769597 },
    { lat: 49.1964377, lng: 18.7694911 },
    { lat: 49.1963871, lng: 18.7694092 },
    { lat: 49.1963487, lng: 18.7691885 },
    { lat: 49.1963565, lng: 18.769149 },
    { lat: 49.1963711, lng: 18.7691429 },
    { lat: 49.1964056, lng: 18.7691164 },
    { lat: 49.1964061, lng: 18.7690568 },
    { lat: 49.1963794, lng: 18.7690207 },
    { lat: 49.1963404, lng: 18.7690107 },
    { lat: 49.1963302, lng: 18.7689885 },
    { lat: 49.1963185, lng: 18.768912 },
    { lat: 49.1962895, lng: 18.768723 },
    { lat: 49.1962937, lng: 18.7686998 },
    { lat: 49.196317, lng: 18.7686706 },
    { lat: 49.1963262, lng: 18.7686515 },
    { lat: 49.1963195, lng: 18.768609 },
    { lat: 49.196312, lng: 18.7685739 },
    { lat: 49.1963141, lng: 18.7685246 },
    { lat: 49.1962946, lng: 18.768435 },
    { lat: 49.1962683, lng: 18.7684058 },
    { lat: 49.1962442, lng: 18.7683751 },
    { lat: 49.19621, lng: 18.7683252 },
    { lat: 49.1961621, lng: 18.7682499 },
    { lat: 49.1961537, lng: 18.7681979 },
    { lat: 49.1961294, lng: 18.7681392 },
    { lat: 49.1961127, lng: 18.7681235 },
    { lat: 49.1960975, lng: 18.7681117 },
    { lat: 49.1960893, lng: 18.7680864 },
    { lat: 49.1960938, lng: 18.767978 },
    { lat: 49.1960816, lng: 18.767938 },
    { lat: 49.1960463, lng: 18.7679088 },
    { lat: 49.1960383, lng: 18.76782 },
    { lat: 49.1960135, lng: 18.7677959 },
    { lat: 49.195983, lng: 18.7678198 },
    { lat: 49.1959507, lng: 18.7678255 },
    { lat: 49.1958925, lng: 18.7677464 },
    { lat: 49.1958809, lng: 18.7677013 },
    { lat: 49.1958559, lng: 18.7676971 },
    { lat: 49.1958335, lng: 18.767697 },
    { lat: 49.1958194, lng: 18.767668 },
    { lat: 49.1958242, lng: 18.7676278 },
    { lat: 49.1958123, lng: 18.7675939 },
    { lat: 49.1957809, lng: 18.7675768 },
    { lat: 49.195758, lng: 18.7675457 },
    { lat: 49.1957579, lng: 18.7675021 },
    { lat: 49.1957819, lng: 18.7674642 },
    { lat: 49.1957848, lng: 18.7674342 },
    { lat: 49.1958251, lng: 18.7674283 },
    { lat: 49.1960361, lng: 18.7668695 },
    { lat: 49.1960422, lng: 18.7668539 },
    { lat: 49.1961176, lng: 18.766654 },
    { lat: 49.1961749, lng: 18.7665022 },
    { lat: 49.1957187, lng: 18.7664457 },
    { lat: 49.1957287, lng: 18.7663565 },
    { lat: 49.1957398, lng: 18.7662654 },
    { lat: 49.1957457, lng: 18.7662063 },
    { lat: 49.1957781, lng: 18.7656583 },
    { lat: 49.1957676, lng: 18.7653286 },
    { lat: 49.1957512, lng: 18.7644926 },
    { lat: 49.195703, lng: 18.7635019 },
    { lat: 49.1957113, lng: 18.7633358 },
    { lat: 49.1957363, lng: 18.7626044 },
    { lat: 49.1958544, lng: 18.7615377 },
    { lat: 49.1958281, lng: 18.7605573 },
    { lat: 49.1958726, lng: 18.7597871 },
    { lat: 49.1960426, lng: 18.7577492 },
    { lat: 49.1960898, lng: 18.7570548 },
    { lat: 49.1960841, lng: 18.7570094 },
    { lat: 49.1960731, lng: 18.7569285 },
    { lat: 49.1960619, lng: 18.7568405 },
    { lat: 49.1961303, lng: 18.7559921 },
    { lat: 49.1962553, lng: 18.7544547 },
    { lat: 49.1963413, lng: 18.7524255 },
    { lat: 49.1963903, lng: 18.7511828 },
    { lat: 49.1964184, lng: 18.7496925 },
  ],
  Žilina: [
    { lat: 49.267195, lng: 18.82729 },
    { lat: 49.267704, lng: 18.826542 },
    { lat: 49.267749, lng: 18.825838 },
    { lat: 49.267746, lng: 18.824905 },
    { lat: 49.267731, lng: 18.824122 },
    { lat: 49.26772, lng: 18.82347 },
    { lat: 49.267857, lng: 18.822501 },
    { lat: 49.267985, lng: 18.821541 },
    { lat: 49.267999, lng: 18.8197 },
    { lat: 49.268006, lng: 18.818708 },
    { lat: 49.268012, lng: 18.817796 },
    { lat: 49.268011, lng: 18.817247 },
    { lat: 49.268006, lng: 18.816515 },
    { lat: 49.267999, lng: 18.815081 },
    { lat: 49.268023, lng: 18.814005 },
    { lat: 49.268021, lng: 18.813117 },
    { lat: 49.267484, lng: 18.81312 },
    { lat: 49.266795, lng: 18.813516 },
    { lat: 49.266546, lng: 18.81381 },
    { lat: 49.266426, lng: 18.813574 },
    { lat: 49.266152, lng: 18.813419 },
    { lat: 49.266109, lng: 18.81094 },
    { lat: 49.265758, lng: 18.810923 },
    { lat: 49.265601, lng: 18.810743 },
    { lat: 49.265393, lng: 18.810505 },
    { lat: 49.265017, lng: 18.810113 },
    { lat: 49.264172, lng: 18.809234 },
    { lat: 49.263799, lng: 18.808855 },
    { lat: 49.263612, lng: 18.808968 },
    { lat: 49.263398, lng: 18.809173 },
    { lat: 49.262616, lng: 18.810052 },
    { lat: 49.26233, lng: 18.809829 },
    { lat: 49.262171, lng: 18.809623 },
    { lat: 49.261685, lng: 18.809974 },
    { lat: 49.261915, lng: 18.810749 },
    { lat: 49.261429, lng: 18.811172 },
    { lat: 49.261104, lng: 18.811455 },
    { lat: 49.260808, lng: 18.811133 },
    { lat: 49.260409, lng: 18.811273 },
    { lat: 49.260377, lng: 18.811676 },
    { lat: 49.260493, lng: 18.811933 },
    { lat: 49.260492, lng: 18.811933 },
    { lat: 49.259738, lng: 18.81256 },
    { lat: 49.259382, lng: 18.811935 },
    { lat: 49.25919, lng: 18.811599 },
    { lat: 49.259148, lng: 18.810893 },
    { lat: 49.258601, lng: 18.810971 },
    { lat: 49.25817, lng: 18.810608 },
    { lat: 49.256616, lng: 18.809287 },
    { lat: 49.256583, lng: 18.809259 },
    { lat: 49.256534, lng: 18.809173 },
    { lat: 49.256456, lng: 18.809035 },
    { lat: 49.256277, lng: 18.808723 },
    { lat: 49.255568, lng: 18.808337 },
    { lat: 49.254821, lng: 18.8077 },
    { lat: 49.254513, lng: 18.806999 },
    { lat: 49.254036, lng: 18.806371 },
    { lat: 49.253644, lng: 18.806276 },
    { lat: 49.253207, lng: 18.806428 },
    { lat: 49.253194, lng: 18.806379 },
    { lat: 49.252927, lng: 18.805316 },
    { lat: 49.252455, lng: 18.803826 },
    { lat: 49.252418, lng: 18.80365 },
    { lat: 49.252222, lng: 18.802891 },
    { lat: 49.252186, lng: 18.802717 },
    { lat: 49.251809, lng: 18.801978 },
    { lat: 49.251119, lng: 18.800144 },
    { lat: 49.250465, lng: 18.80087 },
    { lat: 49.250354, lng: 18.800957 },
    { lat: 49.250191, lng: 18.801115 },
    { lat: 49.250173, lng: 18.801133 },
    { lat: 49.250026, lng: 18.801255 },
    { lat: 49.250022, lng: 18.801258 },
    { lat: 49.249936, lng: 18.801041 },
    { lat: 49.24981, lng: 18.800742 },
    { lat: 49.249797, lng: 18.800754 },
    { lat: 49.249541, lng: 18.801007 },
    { lat: 49.24943, lng: 18.801116 },
    { lat: 49.249281, lng: 18.801083 },
    { lat: 49.249085, lng: 18.80104 },
    { lat: 49.248877, lng: 18.800995 },
    { lat: 49.248769, lng: 18.800972 },
    { lat: 49.248714, lng: 18.801035 },
    { lat: 49.2486507, lng: 18.8011087 },
    { lat: 49.2486259, lng: 18.8010233 },
    { lat: 49.2486137, lng: 18.8009829 },
    { lat: 49.2482998, lng: 18.8011673 },
    { lat: 49.2481924, lng: 18.801425 },
    { lat: 49.2479702, lng: 18.8019902 },
    { lat: 49.2476376, lng: 18.8024486 },
    { lat: 49.2474689, lng: 18.8027877 },
    { lat: 49.2473662, lng: 18.8032183 },
    { lat: 49.2471814, lng: 18.8038116 },
    { lat: 49.2471516, lng: 18.8038025 },
    { lat: 49.2468573, lng: 18.8040419 },
    { lat: 49.246585, lng: 18.8039661 },
    { lat: 49.246144, lng: 18.8040294 },
    { lat: 49.2461568, lng: 18.8041902 },
    { lat: 49.2461868, lng: 18.8046385 },
    { lat: 49.2459056, lng: 18.8045861 },
    { lat: 49.245629, lng: 18.8046664 },
    { lat: 49.2451747, lng: 18.8044526 },
    { lat: 49.2447267, lng: 18.8046622 },
    { lat: 49.2444337, lng: 18.8047762 },
    { lat: 49.2441744, lng: 18.8048374 },
    { lat: 49.2441712, lng: 18.8052798 },
    { lat: 49.2439172, lng: 18.8052289 },
    { lat: 49.2436983, lng: 18.8054693 },
    { lat: 49.2435439, lng: 18.8053796 },
    { lat: 49.2433232, lng: 18.8052334 },
    { lat: 49.2431935, lng: 18.8053894 },
    { lat: 49.2427419, lng: 18.8051349 },
    { lat: 49.242453, lng: 18.8048262 },
    { lat: 49.2421634, lng: 18.8045215 },
    { lat: 49.242027, lng: 18.8044603 },
    { lat: 49.241909, lng: 18.8045312 },
    { lat: 49.2417613, lng: 18.8048604 },
    { lat: 49.2414412, lng: 18.8054916 },
    { lat: 49.2412826, lng: 18.8054132 },
    { lat: 49.2408602, lng: 18.8055746 },
    { lat: 49.2406987, lng: 18.8057456 },
    { lat: 49.2403067, lng: 18.806473 },
    { lat: 49.2401989, lng: 18.8065023 },
    { lat: 49.2399468, lng: 18.8073409 },
    { lat: 49.2397388, lng: 18.8078564 },
    { lat: 49.23963, lng: 18.8081928 },
    { lat: 49.2394617, lng: 18.8085573 },
    { lat: 49.2394029, lng: 18.8087947 },
    { lat: 49.2394359, lng: 18.809127 },
    { lat: 49.2389861, lng: 18.8095225 },
    { lat: 49.2386813, lng: 18.8099976 },
    { lat: 49.23849, lng: 18.8102015 },
    { lat: 49.2379835, lng: 18.8100019 },
    { lat: 49.237773, lng: 18.810312 },
    { lat: 49.2373291, lng: 18.8103647 },
    { lat: 49.2368644, lng: 18.8104878 },
    { lat: 49.2365091, lng: 18.8112305 },
    { lat: 49.2361632, lng: 18.8121962 },
    { lat: 49.2358571, lng: 18.8124056 },
    { lat: 49.2357304, lng: 18.812331 },
    { lat: 49.2355604, lng: 18.8127274 },
    { lat: 49.2352773, lng: 18.8131825 },
    { lat: 49.2352508, lng: 18.8136775 },
    { lat: 49.2351774, lng: 18.8143986 },
    { lat: 49.2352279, lng: 18.8148962 },
    { lat: 49.2353564, lng: 18.8156143 },
    { lat: 49.2355652, lng: 18.8163419 },
    { lat: 49.2356509, lng: 18.8170647 },
    { lat: 49.2354584, lng: 18.81838 },
    { lat: 49.2351129, lng: 18.819203 },
    { lat: 49.2349559, lng: 18.820878 },
    { lat: 49.2345412, lng: 18.8225937 },
    { lat: 49.2343162, lng: 18.8236739 },
    { lat: 49.2342925, lng: 18.8239568 },
    { lat: 49.234341, lng: 18.8246145 },
    { lat: 49.2344156, lng: 18.8252741 },
    { lat: 49.23479, lng: 18.8259774 },
    { lat: 49.2353283, lng: 18.8268199 },
    { lat: 49.2356242, lng: 18.8277892 },
    { lat: 49.2362911, lng: 18.82962 },
    { lat: 49.2366924, lng: 18.830431 },
    { lat: 49.2375702, lng: 18.8316026 },
    { lat: 49.2381983, lng: 18.8321515 },
    { lat: 49.239238, lng: 18.8329444 },
    { lat: 49.2394621, lng: 18.8327201 },
    { lat: 49.2399776, lng: 18.8326381 },
    { lat: 49.2408391, lng: 18.8322659 },
    { lat: 49.2423073, lng: 18.8318548 },
    { lat: 49.2424412, lng: 18.8318258 },
    { lat: 49.2423643, lng: 18.8308365 },
    { lat: 49.2427867, lng: 18.8307385 },
    { lat: 49.2428476, lng: 18.8318582 },
    { lat: 49.2429621, lng: 18.8329859 },
    { lat: 49.24442, lng: 18.8336464 },
    { lat: 49.2446132, lng: 18.8325789 },
    { lat: 49.2448001, lng: 18.8320277 },
    { lat: 49.2449086, lng: 18.8315929 },
    { lat: 49.2449967, lng: 18.8309388 },
    { lat: 49.2451108, lng: 18.8303928 },
    { lat: 49.2451619, lng: 18.830072 },
    { lat: 49.2454035, lng: 18.8290502 },
    { lat: 49.2454163, lng: 18.8289931 },
    { lat: 49.2456273, lng: 18.8280508 },
    { lat: 49.2457648, lng: 18.8281389 },
    { lat: 49.2459895, lng: 18.8272714 },
    { lat: 49.2459963, lng: 18.8269862 },
    { lat: 49.2467078, lng: 18.8278406 },
    { lat: 49.2471183, lng: 18.828586 },
    { lat: 49.2473906, lng: 18.8290578 },
    { lat: 49.2478424, lng: 18.829293 },
    { lat: 49.247911, lng: 18.8293541 },
    { lat: 49.2482251, lng: 18.8296311 },
    { lat: 49.2484936, lng: 18.8296559 },
    { lat: 49.2486525, lng: 18.8296772 },
    { lat: 49.2491069, lng: 18.8298522 },
    { lat: 49.2493273, lng: 18.8301055 },
    { lat: 49.2498691, lng: 18.8295001 },
    { lat: 49.2501949, lng: 18.8298938 },
    { lat: 49.2503929, lng: 18.8295127 },
    { lat: 49.2507242, lng: 18.8295552 },
    { lat: 49.2509662, lng: 18.829524 },
    { lat: 49.2510637, lng: 18.8293802 },
    { lat: 49.2513346, lng: 18.8288366 },
    { lat: 49.2513835, lng: 18.8287185 },
    { lat: 49.2514736, lng: 18.8287795 },
    { lat: 49.2515672, lng: 18.8290007 },
    { lat: 49.2519721, lng: 18.8294862 },
    { lat: 49.2521073, lng: 18.8292625 },
    { lat: 49.252395, lng: 18.8288064 },
    { lat: 49.2530998, lng: 18.8292462 },
    { lat: 49.2536009, lng: 18.8285753 },
    { lat: 49.2536566, lng: 18.8291533 },
    { lat: 49.2539263, lng: 18.8290051 },
    { lat: 49.2540958, lng: 18.8298694 },
    { lat: 49.2546139, lng: 18.8305584 },
    { lat: 49.2549221, lng: 18.8302792 },
    { lat: 49.2553321, lng: 18.8300567 },
    { lat: 49.2556585, lng: 18.8314913 },
    { lat: 49.255956, lng: 18.8324529 },
    { lat: 49.2561579, lng: 18.8333094 },
    { lat: 49.2564811, lng: 18.8345275 },
    { lat: 49.2564771, lng: 18.8357 },
    { lat: 49.2573361, lng: 18.8356533 },
    { lat: 49.2581498, lng: 18.835603 },
    { lat: 49.2591191, lng: 18.8352694 },
    { lat: 49.2589229, lng: 18.834501 },
    { lat: 49.2591404, lng: 18.8337544 },
    { lat: 49.2593142, lng: 18.8333606 },
    { lat: 49.2595014, lng: 18.8326371 },
    { lat: 49.2600147, lng: 18.8330629 },
    { lat: 49.2601947, lng: 18.8323074 },
    { lat: 49.2602711, lng: 18.8316892 },
    { lat: 49.2604257, lng: 18.8311436 },
    { lat: 49.260547, lng: 18.8303876 },
    { lat: 49.2606494, lng: 18.8301186 },
    { lat: 49.2606269, lng: 18.8297264 },
    { lat: 49.2604753, lng: 18.8290573 },
    { lat: 49.2609058, lng: 18.8288558 },
    { lat: 49.2614147, lng: 18.8288239 },
    { lat: 49.2614507, lng: 18.8288217 },
    { lat: 49.2617725, lng: 18.828808 },
    { lat: 49.2621514, lng: 18.8287239 },
    { lat: 49.2627267, lng: 18.8286822 },
    { lat: 49.263194, lng: 18.8286481 },
    { lat: 49.263251, lng: 18.828635 },
    { lat: 49.263252, lng: 18.82863 },
    { lat: 49.263326, lng: 18.8278 },
    { lat: 49.263326, lng: 18.827698 },
    { lat: 49.263328, lng: 18.827364 },
    { lat: 49.263328, lng: 18.82735 },
    { lat: 49.263609, lng: 18.827203 },
    { lat: 49.263664, lng: 18.827175 },
    { lat: 49.263698, lng: 18.827001 },
    { lat: 49.263736, lng: 18.826802 },
    { lat: 49.263936, lng: 18.825829 },
    { lat: 49.264717, lng: 18.826298 },
    { lat: 49.265715, lng: 18.826907 },
    { lat: 49.265763, lng: 18.826949 },
    { lat: 49.266234, lng: 18.827371 },
    { lat: 49.266718, lng: 18.827951 },
    { lat: 49.267195, lng: 18.82729 },
  ],
  ŽilinaExt: [
    { lat: 49.2470568, lng: 18.7007381 },
    { lat: 49.2479043, lng: 18.6999808 },
    { lat: 49.2483914, lng: 18.6993345 },
    { lat: 49.2486344, lng: 18.6989817 },
    { lat: 49.2488524, lng: 18.6986023 },
    { lat: 49.2490737, lng: 18.6982001 },
    { lat: 49.2492286, lng: 18.6979057 },
    { lat: 49.2493235, lng: 18.6976742 },
    { lat: 49.249378, lng: 18.6975123 },
    { lat: 49.2494473, lng: 18.6972839 },
    { lat: 49.2495066, lng: 18.6970417 },
    { lat: 49.2496932, lng: 18.696429 },
    { lat: 49.2497608, lng: 18.6961456 },
    { lat: 49.2498243, lng: 18.6958527 },
    { lat: 49.2498922, lng: 18.6955393 },
    { lat: 49.2499378, lng: 18.6953334 },
    { lat: 49.2499999, lng: 18.6950359 },
    { lat: 49.2500746, lng: 18.6946318 },
    { lat: 49.2501706, lng: 18.694184 },
    { lat: 49.2497072, lng: 18.6936727 },
    { lat: 49.249005, lng: 18.6929507 },
    { lat: 49.2485249, lng: 18.692417 },
    { lat: 49.2482078, lng: 18.6920098 },
    { lat: 49.2470847, lng: 18.6907671 },
    { lat: 49.2469926, lng: 18.6906654 },
    { lat: 49.2469403, lng: 18.6905293 },
    { lat: 49.246437, lng: 18.6893235 },
    { lat: 49.2462567, lng: 18.6889186 },
    { lat: 49.2462227, lng: 18.6887885 },
    { lat: 49.2462136, lng: 18.6886789 },
    { lat: 49.2461904, lng: 18.6884309 },
    { lat: 49.2459762, lng: 18.6880486 },
    { lat: 49.2459018, lng: 18.6878567 },
    { lat: 49.2458208, lng: 18.6877537 },
    { lat: 49.2458003, lng: 18.6877273 },
    { lat: 49.2457701, lng: 18.6876895 },
    { lat: 49.2457387, lng: 18.6876493 },
    { lat: 49.2457339, lng: 18.6876438 },
    { lat: 49.2456716, lng: 18.6875728 },
    { lat: 49.24565, lng: 18.6875487 },
    { lat: 49.2456312, lng: 18.6875153 },
    { lat: 49.2455619, lng: 18.6874191 },
    { lat: 49.2455312, lng: 18.6873706 },
    { lat: 49.2450105, lng: 18.6865196 },
    { lat: 49.2449784, lng: 18.6864679 },
    { lat: 49.2449998, lng: 18.6864253 },
    { lat: 49.2455843, lng: 18.6849118 },
    { lat: 49.2456379, lng: 18.683382 },
    { lat: 49.246004, lng: 18.6831197 },
    { lat: 49.246637, lng: 18.682678 },
    { lat: 49.2467794, lng: 18.6819332 },
    { lat: 49.2469272, lng: 18.6811238 },
    { lat: 49.2475226, lng: 18.6800114 },
    { lat: 49.2480978, lng: 18.6790079 },
    { lat: 49.2481441, lng: 18.6789462 },
    { lat: 49.2485544, lng: 18.6783651 },
    { lat: 49.2487473, lng: 18.6780652 },
    { lat: 49.2489768, lng: 18.6773944 },
    { lat: 49.2493049, lng: 18.676344 },
    { lat: 49.2497791, lng: 18.6741367 },
    { lat: 49.2497828, lng: 18.6740984 },
    { lat: 49.2499111, lng: 18.672917 },
    { lat: 49.2499321, lng: 18.6721654 },
    { lat: 49.2500686, lng: 18.6712024 },
    { lat: 49.2501341, lng: 18.6706478 },
    { lat: 49.2502063, lng: 18.6700505 },
    { lat: 49.2503335, lng: 18.6696457 },
    { lat: 49.2504359, lng: 18.6689573 },
    { lat: 49.2500613, lng: 18.6687151 },
    { lat: 49.2500058, lng: 18.668671 },
    { lat: 49.2499412, lng: 18.6686205 },
    { lat: 49.2498942, lng: 18.6685844 },
    { lat: 49.2498179, lng: 18.6684415 },
    { lat: 49.2497841, lng: 18.66838 },
    { lat: 49.249806, lng: 18.6683459 },
    { lat: 49.2495992, lng: 18.6681234 },
    { lat: 49.2492175, lng: 18.6678174 },
    { lat: 49.2489277, lng: 18.6678149 },
    { lat: 49.2486502, lng: 18.6677076 },
    { lat: 49.2484989, lng: 18.6676385 },
    { lat: 49.2484328, lng: 18.6676716 },
    { lat: 49.2481615, lng: 18.6678274 },
    { lat: 49.2481539, lng: 18.6678316 },
    { lat: 49.2476681, lng: 18.667859 },
    { lat: 49.2474588, lng: 18.6679545 },
    { lat: 49.247194, lng: 18.6684474 },
    { lat: 49.2469949, lng: 18.6687769 },
    { lat: 49.2467373, lng: 18.6689585 },
    { lat: 49.2460947, lng: 18.669647 },
    { lat: 49.2456546, lng: 18.6700481 },
    { lat: 49.2451805, lng: 18.6701896 },
    { lat: 49.2450374, lng: 18.670191 },
    { lat: 49.2450103, lng: 18.6693562 },
    { lat: 49.2445152, lng: 18.6676919 },
    { lat: 49.2444919, lng: 18.6676125 },
    { lat: 49.2444809, lng: 18.6675745 },
    { lat: 49.2442038, lng: 18.6666432 },
    { lat: 49.2439574, lng: 18.6657366 },
    { lat: 49.2433368, lng: 18.6647079 },
    { lat: 49.2429541, lng: 18.6639102 },
    { lat: 49.2426576, lng: 18.663335 },
    { lat: 49.2423521, lng: 18.6624433 },
    { lat: 49.2423511, lng: 18.6615086 },
    { lat: 49.2423291, lng: 18.6615164 },
    { lat: 49.2419783, lng: 18.6616442 },
    { lat: 49.2414332, lng: 18.6619132 },
    { lat: 49.2412477, lng: 18.6620065 },
    { lat: 49.2411289, lng: 18.6624914 },
    { lat: 49.2410924, lng: 18.6626767 },
    { lat: 49.2408983, lng: 18.663438 },
    { lat: 49.2408505, lng: 18.6636247 },
    { lat: 49.2407852, lng: 18.6638161 },
    { lat: 49.240763, lng: 18.6638878 },
    { lat: 49.2405095, lng: 18.664193 },
    { lat: 49.2412617, lng: 18.6662383 },
    { lat: 49.2412779, lng: 18.6662915 },
    { lat: 49.2409529, lng: 18.6665215 },
    { lat: 49.2405837, lng: 18.6669578 },
    { lat: 49.2407192, lng: 18.667345 },
    { lat: 49.2407406, lng: 18.6678393 },
    { lat: 49.2405276, lng: 18.6680111 },
    { lat: 49.2403787, lng: 18.6682023 },
    { lat: 49.2401654, lng: 18.6686032 },
    { lat: 49.239953, lng: 18.6690455 },
    { lat: 49.2397946, lng: 18.6693985 },
    { lat: 49.2391999, lng: 18.669775 },
    { lat: 49.2389258, lng: 18.6701996 },
    { lat: 49.238856, lng: 18.6703295 },
    { lat: 49.2385166, lng: 18.6707116 },
    { lat: 49.2383381, lng: 18.6707278 },
    { lat: 49.2381424, lng: 18.6708433 },
    { lat: 49.2380693, lng: 18.6707209 },
    { lat: 49.2378521, lng: 18.6707069 },
    { lat: 49.237736, lng: 18.6708814 },
    { lat: 49.2372443, lng: 18.6711358 },
    { lat: 49.2368711, lng: 18.6712872 },
    { lat: 49.2365582, lng: 18.6714481 },
    { lat: 49.2363041, lng: 18.6715887 },
    { lat: 49.2361158, lng: 18.6718443 },
    { lat: 49.2359251, lng: 18.6719696 },
    { lat: 49.2357314, lng: 18.672106 },
    { lat: 49.2355605, lng: 18.6723516 },
    { lat: 49.2353965, lng: 18.6723826 },
    { lat: 49.2352473, lng: 18.6724843 },
    { lat: 49.2350713, lng: 18.6725879 },
    { lat: 49.2349506, lng: 18.6726732 },
    { lat: 49.2347852, lng: 18.672682 },
    { lat: 49.2345399, lng: 18.6727544 },
    { lat: 49.2346396, lng: 18.6731258 },
    { lat: 49.2346374, lng: 18.674493 },
    { lat: 49.2346959, lng: 18.6754305 },
    { lat: 49.2347471, lng: 18.676531 },
    { lat: 49.2347574, lng: 18.677966 },
    { lat: 49.2347209, lng: 18.678724 },
    { lat: 49.2347792, lng: 18.6802779 },
    { lat: 49.2344888, lng: 18.6810567 },
    { lat: 49.2342648, lng: 18.6816854 },
    { lat: 49.2346206, lng: 18.6828927 },
    { lat: 49.2348167, lng: 18.6836409 },
    { lat: 49.2351456, lng: 18.6846794 },
    { lat: 49.2352262, lng: 18.6849719 },
    { lat: 49.2352284, lng: 18.6849904 },
    { lat: 49.2351284, lng: 18.6850623 },
    { lat: 49.2349804, lng: 18.6850563 },
    { lat: 49.2348236, lng: 18.6852271 },
    { lat: 49.2345762, lng: 18.6852821 },
    { lat: 49.2343301, lng: 18.6853607 },
    { lat: 49.2339709, lng: 18.6859299 },
    { lat: 49.2336081, lng: 18.6864883 },
    { lat: 49.2335068, lng: 18.686709 },
    { lat: 49.2334057, lng: 18.68693 },
    { lat: 49.2332447, lng: 18.6872962 },
    { lat: 49.2330835, lng: 18.6876624 },
    { lat: 49.232894, lng: 18.6878344 },
    { lat: 49.2326518, lng: 18.6880543 },
    { lat: 49.2326461, lng: 18.6880481 },
    { lat: 49.2310583, lng: 18.6874959 },
    { lat: 49.2300512, lng: 18.6869586 },
    { lat: 49.2294903, lng: 18.6866285 },
    { lat: 49.2290431, lng: 18.686358 },
    { lat: 49.2280561, lng: 18.6861383 },
    { lat: 49.226932, lng: 18.6858886 },
    { lat: 49.2266781, lng: 18.6854778 },
    { lat: 49.2263965, lng: 18.6845869 },
    { lat: 49.2261674, lng: 18.6844726 },
    { lat: 49.2260527, lng: 18.6841796 },
    { lat: 49.2256847, lng: 18.6834488 },
    { lat: 49.2252751, lng: 18.6826091 },
    { lat: 49.2246241, lng: 18.6824755 },
    { lat: 49.2233874, lng: 18.6821185 },
    { lat: 49.2230791, lng: 18.681897 },
    { lat: 49.2226973, lng: 18.6816914 },
    { lat: 49.2224026, lng: 18.6815343 },
    { lat: 49.2219808, lng: 18.6813654 },
    { lat: 49.2217065, lng: 18.6813165 },
    { lat: 49.2213313, lng: 18.6812151 },
    { lat: 49.2212374, lng: 18.6812717 },
    { lat: 49.220921, lng: 18.6814102 },
    { lat: 49.2208668, lng: 18.6814086 },
    { lat: 49.2207103, lng: 18.6813935 },
    { lat: 49.2204944, lng: 18.681275 },
    { lat: 49.219983, lng: 18.6812247 },
    { lat: 49.2198837, lng: 18.6813263 },
    { lat: 49.2198567, lng: 18.6815003 },
    { lat: 49.2198445, lng: 18.6815913 },
    { lat: 49.219761, lng: 18.6822307 },
    { lat: 49.2197567, lng: 18.6824257 },
    { lat: 49.2197155, lng: 18.6825642 },
    { lat: 49.2196947, lng: 18.68264 },
    { lat: 49.2197165, lng: 18.6827358 },
    { lat: 49.2197442, lng: 18.6828522 },
    { lat: 49.2198049, lng: 18.6832552 },
    { lat: 49.2199616, lng: 18.6836588 },
    { lat: 49.2200654, lng: 18.684023 },
    { lat: 49.2201659, lng: 18.6845175 },
    { lat: 49.2202047, lng: 18.6849565 },
    { lat: 49.2202245, lng: 18.6856503 },
    { lat: 49.2201752, lng: 18.6856784 },
    { lat: 49.219794, lng: 18.6858941 },
    { lat: 49.2195799, lng: 18.6859608 },
    { lat: 49.2193337, lng: 18.6860383 },
    { lat: 49.2190995, lng: 18.6860642 },
    { lat: 49.218767, lng: 18.6861537 },
    { lat: 49.2184988, lng: 18.6863728 },
    { lat: 49.2181956, lng: 18.6864177 },
    { lat: 49.217825, lng: 18.6866169 },
    { lat: 49.2175738, lng: 18.6857654 },
    { lat: 49.2171715, lng: 18.6859876 },
    { lat: 49.2170875, lng: 18.6860845 },
    { lat: 49.2163584, lng: 18.686841 },
    { lat: 49.2163264, lng: 18.6868746 },
    { lat: 49.2152883, lng: 18.6876352 },
    { lat: 49.2152569, lng: 18.6876581 },
    { lat: 49.215577, lng: 18.6886159 },
    { lat: 49.2153491, lng: 18.6888751 },
    { lat: 49.2149074, lng: 18.6893308 },
    { lat: 49.2150231, lng: 18.6897906 },
    { lat: 49.2151087, lng: 18.690258 },
    { lat: 49.2151591, lng: 18.6906807 },
    { lat: 49.2151877, lng: 18.6910769 },
    { lat: 49.2150552, lng: 18.6910866 },
    { lat: 49.2148943, lng: 18.6909845 },
    { lat: 49.2148452, lng: 18.6909526 },
    { lat: 49.2146976, lng: 18.6909964 },
    { lat: 49.2144577, lng: 18.6910666 },
    { lat: 49.2141449, lng: 18.6912121 },
    { lat: 49.2141571, lng: 18.6917203 },
    { lat: 49.214158, lng: 18.691779 },
    { lat: 49.2141072, lng: 18.6922271 },
    { lat: 49.2140052, lng: 18.6926238 },
    { lat: 49.2139644, lng: 18.6928351 },
    { lat: 49.2139605, lng: 18.6928702 },
    { lat: 49.2134192, lng: 18.6930593 },
    { lat: 49.2132086, lng: 18.6932153 },
    { lat: 49.212483, lng: 18.6935493 },
    { lat: 49.2121201, lng: 18.6937258 },
    { lat: 49.2118516, lng: 18.6938944 },
    { lat: 49.2116666, lng: 18.6940199 },
    { lat: 49.2113904, lng: 18.6942586 },
    { lat: 49.2111189, lng: 18.69439 },
    { lat: 49.2108267, lng: 18.6944941 },
    { lat: 49.210474, lng: 18.6946491 },
    { lat: 49.2101358, lng: 18.6948457 },
    { lat: 49.2101415, lng: 18.6948688 },
    { lat: 49.2103284, lng: 18.6956134 },
    { lat: 49.2099434, lng: 18.6958807 },
    { lat: 49.209722, lng: 18.6951234 },
    { lat: 49.209708, lng: 18.6950735 },
    { lat: 49.2095579, lng: 18.6951568 },
    { lat: 49.2094681, lng: 18.6952068 },
    { lat: 49.2091735, lng: 18.6953735 },
    { lat: 49.2091504, lng: 18.6953832 },
    { lat: 49.2091179, lng: 18.6954088 },
    { lat: 49.2089322, lng: 18.6955658 },
    { lat: 49.2087868, lng: 18.6957178 },
    { lat: 49.20865, lng: 18.6958615 },
    { lat: 49.2086667, lng: 18.6959013 },
    { lat: 49.2088437, lng: 18.6963272 },
    { lat: 49.2093057, lng: 18.6975221 },
    { lat: 49.2096906, lng: 18.6986384 },
    { lat: 49.2092432, lng: 18.6987773 },
    { lat: 49.2089975, lng: 18.698885 },
    { lat: 49.2088037, lng: 18.6989924 },
    { lat: 49.2085956, lng: 18.699156 },
    { lat: 49.2085062, lng: 18.6990829 },
    { lat: 49.2081253, lng: 18.6985306 },
    { lat: 49.2078336, lng: 18.6981743 },
    { lat: 49.2076247, lng: 18.6982998 },
    { lat: 49.207866, lng: 18.6986644 },
    { lat: 49.208016, lng: 18.6989638 },
    { lat: 49.2080756, lng: 18.69913 },
    { lat: 49.2081324, lng: 18.6993297 },
    { lat: 49.2081553, lng: 18.6997019 },
    { lat: 49.2081397, lng: 18.7000075 },
    { lat: 49.2080927, lng: 18.7003538 },
    { lat: 49.2080575, lng: 18.7005874 },
    { lat: 49.2080315, lng: 18.7007749 },
    { lat: 49.2079993, lng: 18.7010883 },
    { lat: 49.2079779, lng: 18.7018812 },
    { lat: 49.2079839, lng: 18.7020362 },
    { lat: 49.2080213, lng: 18.702815 },
    { lat: 49.2079104, lng: 18.7028495 },
    { lat: 49.2078748, lng: 18.7028589 },
    { lat: 49.2078393, lng: 18.7028683 },
    { lat: 49.2074288, lng: 18.7029345 },
    { lat: 49.2071594, lng: 18.7029779 },
    { lat: 49.2068709, lng: 18.7030248 },
    { lat: 49.2068424, lng: 18.7030388 },
    { lat: 49.2068071, lng: 18.703055 },
    { lat: 49.2057532, lng: 18.7035584 },
    { lat: 49.2057267, lng: 18.7035664 },
    { lat: 49.2056269, lng: 18.7035782 },
    { lat: 49.2053338, lng: 18.7037923 },
    { lat: 49.2048939, lng: 18.7039439 },
    { lat: 49.2043997, lng: 18.7041923 },
    { lat: 49.2041527, lng: 18.7043679 },
    { lat: 49.2041087, lng: 18.7044288 },
    { lat: 49.2038624, lng: 18.705169 },
    { lat: 49.2037202, lng: 18.7048911 },
    { lat: 49.2035505, lng: 18.7045588 },
    { lat: 49.2033619, lng: 18.7041225 },
    { lat: 49.2030752, lng: 18.7030018 },
    { lat: 49.2030507, lng: 18.7029684 },
    { lat: 49.2027653, lng: 18.7025774 },
    { lat: 49.2022997, lng: 18.7022172 },
    { lat: 49.2022739, lng: 18.7021984 },
    { lat: 49.2021716, lng: 18.7021182 },
    { lat: 49.2019265, lng: 18.7017667 },
    { lat: 49.2012969, lng: 18.7030678 },
    { lat: 49.2012837, lng: 18.7030954 },
    { lat: 49.2010589, lng: 18.7019531 },
    { lat: 49.2007889, lng: 18.7006159 },
    { lat: 49.2008207, lng: 18.6996535 },
    { lat: 49.2006246, lng: 18.6992267 },
    { lat: 49.2002954, lng: 18.6989753 },
    { lat: 49.2000898, lng: 18.6988797 },
    { lat: 49.2000763, lng: 18.6988796 },
    { lat: 49.20007, lng: 18.6988465 },
    { lat: 49.1996403, lng: 18.6963399 },
    { lat: 49.1996385, lng: 18.6963275 },
    { lat: 49.1996234, lng: 18.6962342 },
    { lat: 49.1996199, lng: 18.6962083 },
    { lat: 49.1993585, lng: 18.6943724 },
    { lat: 49.1992362, lng: 18.6935079 },
    { lat: 49.1992215, lng: 18.6934394 },
    { lat: 49.1991019, lng: 18.6925825 },
    { lat: 49.1990915, lng: 18.6925152 },
    { lat: 49.1991384, lng: 18.6923779 },
    { lat: 49.199163, lng: 18.6922432 },
    { lat: 49.1995168, lng: 18.688901 },
    { lat: 49.1995364, lng: 18.6887165 },
    { lat: 49.199547, lng: 18.6885547 },
    { lat: 49.199549, lng: 18.6883586 },
    { lat: 49.199519, lng: 18.6878986 },
    { lat: 49.1993761, lng: 18.6853818 },
    { lat: 49.1993794, lng: 18.6852285 },
    { lat: 49.1994069, lng: 18.6850643 },
    { lat: 49.1995984, lng: 18.6842917 },
    { lat: 49.1996327, lng: 18.6841718 },
    { lat: 49.1996658, lng: 18.6840098 },
    { lat: 49.1996653, lng: 18.6839561 },
    { lat: 49.1996149, lng: 18.683579 },
    { lat: 49.1989813, lng: 18.6831123 },
    { lat: 49.1988943, lng: 18.683004 },
    { lat: 49.1988048, lng: 18.6828657 },
    { lat: 49.1987419, lng: 18.6827216 },
    { lat: 49.1986707, lng: 18.6825237 },
    { lat: 49.1986025, lng: 18.6823013 },
    { lat: 49.1985507, lng: 18.682131 },
    { lat: 49.1984763, lng: 18.6819202 },
    { lat: 49.1983811, lng: 18.6816982 },
    { lat: 49.1983175, lng: 18.6815794 },
    { lat: 49.1978245, lng: 18.6806578 },
    { lat: 49.1978119, lng: 18.6806326 },
    { lat: 49.1977811, lng: 18.6805639 },
    { lat: 49.1977677, lng: 18.6805429 },
    { lat: 49.1976792, lng: 18.6803392 },
    { lat: 49.1976104, lng: 18.6801295 },
    { lat: 49.1973931, lng: 18.6791328 },
    { lat: 49.1973756, lng: 18.6789886 },
    { lat: 49.1973752, lng: 18.6788332 },
    { lat: 49.1973436, lng: 18.6788353 },
    { lat: 49.1969322, lng: 18.6789428 },
    { lat: 49.1956573, lng: 18.6789741 },
    { lat: 49.1956032, lng: 18.678975 },
    { lat: 49.1955931, lng: 18.6789755 },
    { lat: 49.1955006, lng: 18.6789772 },
    { lat: 49.1954253, lng: 18.6789792 },
    { lat: 49.1953872, lng: 18.6789431 },
    { lat: 49.1953716, lng: 18.6789249 },
    { lat: 49.1952441, lng: 18.6787967 },
    { lat: 49.1951993, lng: 18.6787809 },
    { lat: 49.194712, lng: 18.6786277 },
    { lat: 49.1944104, lng: 18.6784254 },
    { lat: 49.1942561, lng: 18.6783679 },
    { lat: 49.1939009, lng: 18.678368 },
    { lat: 49.1935034, lng: 18.6784835 },
    { lat: 49.1923311, lng: 18.6788364 },
    { lat: 49.1920794, lng: 18.6788937 },
    { lat: 49.1916907, lng: 18.6788816 },
    { lat: 49.1916692, lng: 18.678881 },
    { lat: 49.191448, lng: 18.6796679 },
    { lat: 49.1913152, lng: 18.680591 },
    { lat: 49.1911275, lng: 18.6811362 },
    { lat: 49.1911095, lng: 18.6811164 },
    { lat: 49.1910583, lng: 18.6810639 },
    { lat: 49.1910225, lng: 18.6810272 },
    { lat: 49.1910093, lng: 18.6810141 },
    { lat: 49.1909844, lng: 18.680989 },
    { lat: 49.1909808, lng: 18.6809847 },
    { lat: 49.1909391, lng: 18.6809426 },
    { lat: 49.1904753, lng: 18.6802544 },
    { lat: 49.1898536, lng: 18.6800525 },
    { lat: 49.1891467, lng: 18.6802082 },
    { lat: 49.187889, lng: 18.6801793 },
    { lat: 49.1868799, lng: 18.6812309 },
    { lat: 49.1860087, lng: 18.6807698 },
    { lat: 49.1856168, lng: 18.6806918 },
    { lat: 49.1851128, lng: 18.6805904 },
    { lat: 49.1844871, lng: 18.6805282 },
    { lat: 49.1844044, lng: 18.6805199 },
    { lat: 49.1840778, lng: 18.6803589 },
    { lat: 49.1839806, lng: 18.6807202 },
    { lat: 49.1838877, lng: 18.6810719 },
    { lat: 49.1837223, lng: 18.6813374 },
    { lat: 49.1834132, lng: 18.6820621 },
    { lat: 49.1833994, lng: 18.6820966 },
    { lat: 49.1830637, lng: 18.6828534 },
    { lat: 49.1828065, lng: 18.6836172 },
    { lat: 49.1825763, lng: 18.6841728 },
    { lat: 49.1822443, lng: 18.6848661 },
    { lat: 49.1822161, lng: 18.6855948 },
    { lat: 49.1822106, lng: 18.6857685 },
    { lat: 49.1820592, lng: 18.6869832 },
    { lat: 49.1819715, lng: 18.68722 },
    { lat: 49.1817712, lng: 18.6875085 },
    { lat: 49.1815551, lng: 18.6880707 },
    { lat: 49.18084, lng: 18.6887327 },
    { lat: 49.1800633, lng: 18.6896336 },
    { lat: 49.1802232, lng: 18.690336 },
    { lat: 49.1800493, lng: 18.6906511 },
    { lat: 49.180007, lng: 18.6909195 },
    { lat: 49.1799758, lng: 18.6911169 },
    { lat: 49.1799629, lng: 18.6911941 },
    { lat: 49.1799358, lng: 18.6913665 },
    { lat: 49.1797318, lng: 18.6913883 },
    { lat: 49.179675, lng: 18.6917662 },
    { lat: 49.1796121, lng: 18.692112 },
    { lat: 49.1794607, lng: 18.6924928 },
    { lat: 49.1792081, lng: 18.6928796 },
    { lat: 49.1789222, lng: 18.6934679 },
    { lat: 49.178707, lng: 18.6937683 },
    { lat: 49.1786235, lng: 18.6942154 },
    { lat: 49.1785025, lng: 18.6947442 },
    { lat: 49.178414, lng: 18.695033 },
    { lat: 49.178286, lng: 18.6957534 },
    { lat: 49.1782389, lng: 18.6959722 },
    { lat: 49.1781554, lng: 18.6963335 },
    { lat: 49.1780135, lng: 18.6967665 },
    { lat: 49.1779658, lng: 18.6969107 },
    { lat: 49.1777642, lng: 18.6976185 },
    { lat: 49.1777273, lng: 18.6977531 },
    { lat: 49.1773723, lng: 18.6988949 },
    { lat: 49.1773052, lng: 18.6993122 },
    { lat: 49.177268, lng: 18.6996336 },
    { lat: 49.1773095, lng: 18.6999053 },
    { lat: 49.1772806, lng: 18.7006406 },
    { lat: 49.1771213, lng: 18.7008962 },
    { lat: 49.1774836, lng: 18.7012014 },
    { lat: 49.1773661, lng: 18.7020765 },
    { lat: 49.1776463, lng: 18.702425 },
    { lat: 49.1780427, lng: 18.7029745 },
    { lat: 49.1778571, lng: 18.7031129 },
    { lat: 49.1776579, lng: 18.7031405 },
    { lat: 49.1775436, lng: 18.7033023 },
    { lat: 49.1777218, lng: 18.7036889 },
    { lat: 49.1778993, lng: 18.7042341 },
    { lat: 49.1781115, lng: 18.7049254 },
    { lat: 49.1785205, lng: 18.7056705 },
    { lat: 49.1787014, lng: 18.7060005 },
    { lat: 49.1789348, lng: 18.7063403 },
    { lat: 49.1794013, lng: 18.7069126 },
    { lat: 49.1795773, lng: 18.7074948 },
    { lat: 49.1797121, lng: 18.707762 },
    { lat: 49.1798952, lng: 18.7079869 },
    { lat: 49.1800322, lng: 18.708124 },
    { lat: 49.1802617, lng: 18.7085429 },
    { lat: 49.1804464, lng: 18.7089017 },
    { lat: 49.180812, lng: 18.7094871 },
    { lat: 49.1811325, lng: 18.7099648 },
    { lat: 49.1811242, lng: 18.710278 },
    { lat: 49.1814189, lng: 18.7105404 },
    { lat: 49.1813937, lng: 18.7106008 },
    { lat: 49.1818428, lng: 18.711012 },
    { lat: 49.1825574, lng: 18.7118211 },
    { lat: 49.1827224, lng: 18.712192 },
    { lat: 49.1826613, lng: 18.7128105 },
    { lat: 49.1827462, lng: 18.7130085 },
    { lat: 49.1835416, lng: 18.7131484 },
    { lat: 49.183792, lng: 18.7132999 },
    { lat: 49.1837368, lng: 18.7142233 },
    { lat: 49.1837957, lng: 18.7154533 },
    { lat: 49.1838437, lng: 18.716106 },
    { lat: 49.1842805, lng: 18.7172109 },
    { lat: 49.1843224, lng: 18.7172991 },
    { lat: 49.1844136, lng: 18.7174917 },
    { lat: 49.1846761, lng: 18.718107 },
    { lat: 49.1847682, lng: 18.7187057 },
    { lat: 49.1846325, lng: 18.718957 },
    { lat: 49.184001, lng: 18.7192558 },
    { lat: 49.1840742, lng: 18.7195125 },
    { lat: 49.184268, lng: 18.7202142 },
    { lat: 49.1844671, lng: 18.7208758 },
    { lat: 49.1844793, lng: 18.720914 },
    { lat: 49.1847548, lng: 18.7215217 },
    { lat: 49.1847601, lng: 18.7215365 },
    { lat: 49.1847721, lng: 18.7215715 },
    { lat: 49.1848115, lng: 18.7216784 },
    { lat: 49.1848719, lng: 18.7218841 },
    { lat: 49.1848827, lng: 18.7219181 },
    { lat: 49.1848963, lng: 18.7219611 },
    { lat: 49.1849032, lng: 18.7219862 },
    { lat: 49.1849086, lng: 18.7220029 },
    { lat: 49.1849491, lng: 18.7221306 },
    { lat: 49.1847574, lng: 18.722255 },
    { lat: 49.1845621, lng: 18.722389 },
    { lat: 49.1845314, lng: 18.7224018 },
    { lat: 49.1841529, lng: 18.7229468 },
    { lat: 49.1839238, lng: 18.7232766 },
    { lat: 49.18386, lng: 18.7233377 },
    { lat: 49.1837717, lng: 18.7234603 },
    { lat: 49.1837394, lng: 18.7235121 },
    { lat: 49.1837493, lng: 18.7235622 },
    { lat: 49.1835222, lng: 18.7237769 },
    { lat: 49.1832699, lng: 18.7239761 },
    { lat: 49.1832419, lng: 18.7239809 },
    { lat: 49.18309, lng: 18.7240067 },
    { lat: 49.183052, lng: 18.7240102 },
    { lat: 49.18288, lng: 18.7240258 },
    { lat: 49.1824754, lng: 18.7240077 },
    { lat: 49.1823782, lng: 18.7240009 },
    { lat: 49.182164, lng: 18.7240259 },
    { lat: 49.1819044, lng: 18.7241077 },
    { lat: 49.1817137, lng: 18.7241897 },
    { lat: 49.1812154, lng: 18.7246449 },
    { lat: 49.1811801, lng: 18.7246809 },
    { lat: 49.1811468, lng: 18.724735 },
    { lat: 49.1809572, lng: 18.7249031 },
    { lat: 49.1808538, lng: 18.7249744 },
    { lat: 49.1808441, lng: 18.7249818 },
    { lat: 49.1807225, lng: 18.7250734 },
    { lat: 49.1805375, lng: 18.7251558 },
    { lat: 49.1799559, lng: 18.7253568 },
    { lat: 49.179945, lng: 18.7253607 },
    { lat: 49.1798384, lng: 18.7253971 },
    { lat: 49.1797152, lng: 18.7254986 },
    { lat: 49.1795796, lng: 18.7256219 },
    { lat: 49.1794656, lng: 18.725767 },
    { lat: 49.1793016, lng: 18.7260386 },
    { lat: 49.1791461, lng: 18.7263434 },
    { lat: 49.178865, lng: 18.7268318 },
    { lat: 49.1786128, lng: 18.7270514 },
    { lat: 49.1784588, lng: 18.7271484 },
    { lat: 49.1784261, lng: 18.727169 },
    { lat: 49.1784217, lng: 18.7271706 },
    { lat: 49.178415, lng: 18.7271732 },
    { lat: 49.1784117, lng: 18.727174 },
    { lat: 49.1783375, lng: 18.7272 },
    { lat: 49.1783207, lng: 18.7272056 },
    { lat: 49.1783143, lng: 18.7272082 },
    { lat: 49.1782964, lng: 18.7272144 },
    { lat: 49.1781714, lng: 18.7272588 },
    { lat: 49.1778077, lng: 18.7274448 },
    { lat: 49.1775986, lng: 18.7275157 },
    { lat: 49.1774058, lng: 18.7275619 },
    { lat: 49.1770527, lng: 18.7276695 },
    { lat: 49.1769191, lng: 18.7277215 },
    { lat: 49.1764142, lng: 18.7276825 },
    { lat: 49.1763992, lng: 18.7278515 },
    { lat: 49.1763755, lng: 18.7279132 },
    { lat: 49.1763475, lng: 18.728024 },
    { lat: 49.1763224, lng: 18.7280862 },
    { lat: 49.1763309, lng: 18.7281392 },
    { lat: 49.1762804, lng: 18.7281854 },
    { lat: 49.176285, lng: 18.7282511 },
    { lat: 49.1762621, lng: 18.7283552 },
    { lat: 49.176203, lng: 18.7284727 },
    { lat: 49.1762008, lng: 18.7285155 },
    { lat: 49.1761496, lng: 18.7286605 },
    { lat: 49.1761505, lng: 18.7286957 },
    { lat: 49.1761439, lng: 18.7287635 },
    { lat: 49.1761226, lng: 18.7287683 },
    { lat: 49.1760936, lng: 18.7288374 },
    { lat: 49.1761227, lng: 18.7289189 },
    { lat: 49.1761208, lng: 18.7289916 },
    { lat: 49.1761003, lng: 18.729073 },
    { lat: 49.1760381, lng: 18.729113 },
    { lat: 49.1760154, lng: 18.7292203 },
    { lat: 49.1760344, lng: 18.729258 },
    { lat: 49.1760409, lng: 18.72936 },
    { lat: 49.1760519, lng: 18.7294433 },
    { lat: 49.1759318, lng: 18.7295596 },
    { lat: 49.1759302, lng: 18.72966 },
    { lat: 49.1759811, lng: 18.7297244 },
    { lat: 49.1759615, lng: 18.7297607 },
    { lat: 49.1759622, lng: 18.7298157 },
    { lat: 49.1759482, lng: 18.7298733 },
    { lat: 49.1759172, lng: 18.7299481 },
    { lat: 49.1758424, lng: 18.7300268 },
    { lat: 49.1758129, lng: 18.7301313 },
    { lat: 49.1758666, lng: 18.7303403 },
    { lat: 49.1758699, lng: 18.7303802 },
    { lat: 49.1758852, lng: 18.7305204 },
    { lat: 49.1757764, lng: 18.7306162 },
    { lat: 49.1757266, lng: 18.730681 },
    { lat: 49.1756809, lng: 18.7307111 },
    { lat: 49.1756524, lng: 18.7307718 },
    { lat: 49.1756475, lng: 18.7308481 },
    { lat: 49.1756139, lng: 18.7308746 },
    { lat: 49.1755574, lng: 18.730893 },
    { lat: 49.1755322, lng: 18.7310391 },
    { lat: 49.1754486, lng: 18.7311838 },
    { lat: 49.1754801, lng: 18.7312488 },
    { lat: 49.1754696, lng: 18.7313509 },
    { lat: 49.1753989, lng: 18.7314399 },
    { lat: 49.1753936, lng: 18.7314921 },
    { lat: 49.1753651, lng: 18.7316149 },
    { lat: 49.1753099, lng: 18.7317207 },
    { lat: 49.1752814, lng: 18.7317415 },
    { lat: 49.1752616, lng: 18.7318138 },
    { lat: 49.1751817, lng: 18.7318816 },
    { lat: 49.1751807, lng: 18.731947 },
    { lat: 49.175122, lng: 18.7320993 },
    { lat: 49.1751035, lng: 18.7321113 },
    { lat: 49.1750542, lng: 18.7321173 },
    { lat: 49.1750471, lng: 18.7321524 },
    { lat: 49.1750794, lng: 18.7321885 },
    { lat: 49.1750667, lng: 18.7323342 },
    { lat: 49.1750414, lng: 18.7323863 },
    { lat: 49.1749537, lng: 18.7324139 },
    { lat: 49.1749312, lng: 18.7324354 },
    { lat: 49.1749161, lng: 18.7326214 },
    { lat: 49.1749404, lng: 18.7327417 },
    { lat: 49.1749275, lng: 18.7328388 },
    { lat: 49.1748802, lng: 18.7328648 },
    { lat: 49.1748442, lng: 18.7329946 },
    { lat: 49.1748407, lng: 18.7331208 },
    { lat: 49.1748256, lng: 18.733176 },
    { lat: 49.1748253, lng: 18.7332376 },
    { lat: 49.1748489, lng: 18.7332773 },
    { lat: 49.1748754, lng: 18.7334586 },
    { lat: 49.174861, lng: 18.7336575 },
    { lat: 49.1748326, lng: 18.7337417 },
    { lat: 49.174809, lng: 18.7338509 },
    { lat: 49.174792, lng: 18.7340414 },
    { lat: 49.1747744, lng: 18.7343315 },
    { lat: 49.1747657, lng: 18.7344241 },
    { lat: 49.174742, lng: 18.7345085 },
    { lat: 49.1747277, lng: 18.7345819 },
    { lat: 49.1747128, lng: 18.7347272 },
    { lat: 49.1745725, lng: 18.7349122 },
    { lat: 49.174554, lng: 18.7350334 },
    { lat: 49.1745384, lng: 18.7350382 },
    { lat: 49.1745282, lng: 18.735123 },
    { lat: 49.1745415, lng: 18.7351593 },
    { lat: 49.1745386, lng: 18.7351686 },
    { lat: 49.1745373, lng: 18.7352113 },
    { lat: 49.1745401, lng: 18.7352182 },
    { lat: 49.174519, lng: 18.7352453 },
    { lat: 49.1744834, lng: 18.7353404 },
    { lat: 49.1744921, lng: 18.7354662 },
    { lat: 49.174479, lng: 18.7356444 },
    { lat: 49.1744538, lng: 18.735787 },
    { lat: 49.1744578, lng: 18.7358452 },
    { lat: 49.1744378, lng: 18.7358693 },
    { lat: 49.1744216, lng: 18.7359307 },
    { lat: 49.1744079, lng: 18.7360528 },
    { lat: 49.1743738, lng: 18.7361358 },
    { lat: 49.1743328, lng: 18.7363182 },
    { lat: 49.1743015, lng: 18.7363721 },
    { lat: 49.1742732, lng: 18.7364083 },
    { lat: 49.1742456, lng: 18.736509 },
    { lat: 49.174241, lng: 18.7365928 },
    { lat: 49.1742267, lng: 18.7366626 },
    { lat: 49.1741935, lng: 18.7368498 },
    { lat: 49.1741727, lng: 18.7369038 },
    { lat: 49.1741807, lng: 18.7369252 },
    { lat: 49.1741174, lng: 18.7373319 },
    { lat: 49.1741229, lng: 18.7373734 },
    { lat: 49.174117, lng: 18.7374328 },
    { lat: 49.1740636, lng: 18.737555 },
    { lat: 49.1740093, lng: 18.7377681 },
    { lat: 49.1739532, lng: 18.7379249 },
    { lat: 49.1739211, lng: 18.7379569 },
    { lat: 49.1738087, lng: 18.738242 },
    { lat: 49.1738029, lng: 18.7382845 },
    { lat: 49.173775, lng: 18.7383117 },
    { lat: 49.1737568, lng: 18.7383932 },
    { lat: 49.1737208, lng: 18.7384819 },
    { lat: 49.1736472, lng: 18.7386916 },
    { lat: 49.1736066, lng: 18.7387783 },
    { lat: 49.1735706, lng: 18.7389081 },
    { lat: 49.1735574, lng: 18.7389988 },
    { lat: 49.1735256, lng: 18.7390603 },
    { lat: 49.1733691, lng: 18.7394963 },
    { lat: 49.1733054, lng: 18.7397019 },
    { lat: 49.1732695, lng: 18.7399005 },
    { lat: 49.1732516, lng: 18.7400321 },
    { lat: 49.1732301, lng: 18.7401825 },
    { lat: 49.1732411, lng: 18.7402388 },
    { lat: 49.1732036, lng: 18.7405151 },
    { lat: 49.1731805, lng: 18.7405262 },
    { lat: 49.1731747, lng: 18.7406318 },
    { lat: 49.1729631, lng: 18.7411343 },
    { lat: 49.1728903, lng: 18.7413165 },
    { lat: 49.1728334, lng: 18.7413483 },
    { lat: 49.1727152, lng: 18.7415047 },
    { lat: 49.1725719, lng: 18.7416529 },
    { lat: 49.1724806, lng: 18.7417188 },
    { lat: 49.1719142, lng: 18.7422522 },
    { lat: 49.1717977, lng: 18.7423511 },
    { lat: 49.1717505, lng: 18.7423795 },
    { lat: 49.1716698, lng: 18.7424539 },
    { lat: 49.1716159, lng: 18.7424759 },
    { lat: 49.1715302, lng: 18.7425004 },
    { lat: 49.1713832, lng: 18.7424895 },
    { lat: 49.1713388, lng: 18.7424815 },
    { lat: 49.1710126, lng: 18.7424109 },
    { lat: 49.1709514, lng: 18.7423611 },
    { lat: 49.1708667, lng: 18.7423598 },
    { lat: 49.1707999, lng: 18.7423107 },
    { lat: 49.1707191, lng: 18.7423205 },
    { lat: 49.1706826, lng: 18.7422925 },
    { lat: 49.1706143, lng: 18.742237 },
    { lat: 49.170584, lng: 18.7421088 },
    { lat: 49.1705376, lng: 18.7420629 },
    { lat: 49.1704955, lng: 18.7420752 },
    { lat: 49.1704824, lng: 18.7419561 },
    { lat: 49.1704519, lng: 18.7419339 },
    { lat: 49.1704315, lng: 18.7419544 },
    { lat: 49.1703974, lng: 18.7419491 },
    { lat: 49.1703247, lng: 18.7419587 },
    { lat: 49.170275, lng: 18.7419128 },
    { lat: 49.1702115, lng: 18.7418856 },
    { lat: 49.1701711, lng: 18.7418685 },
    { lat: 49.1701323, lng: 18.7419035 },
    { lat: 49.1701557, lng: 18.7420027 },
    { lat: 49.1701374, lng: 18.7420878 },
    { lat: 49.1700501, lng: 18.7421211 },
    { lat: 49.1699349, lng: 18.7420731 },
    { lat: 49.1698819, lng: 18.7421174 },
    { lat: 49.1698536, lng: 18.7421778 },
    { lat: 49.1697667, lng: 18.7421776 },
    { lat: 49.1697566, lng: 18.7421114 },
    { lat: 49.1696841, lng: 18.7420853 },
    { lat: 49.1696271, lng: 18.742098 },
    { lat: 49.1695344, lng: 18.7420285 },
    { lat: 49.1693972, lng: 18.7420975 },
    { lat: 49.1693279, lng: 18.7421106 },
    { lat: 49.1692848, lng: 18.7420809 },
    { lat: 49.1691737, lng: 18.7420936 },
    { lat: 49.1690714, lng: 18.7420772 },
    { lat: 49.1690005, lng: 18.7421446 },
    { lat: 49.168952, lng: 18.7421256 },
    { lat: 49.1689112, lng: 18.742185 },
    { lat: 49.1688988, lng: 18.742268 },
    { lat: 49.1688041, lng: 18.7423386 },
    { lat: 49.1687766, lng: 18.7423268 },
    { lat: 49.1686864, lng: 18.7424596 },
    { lat: 49.168643, lng: 18.7424238 },
    { lat: 49.1685848, lng: 18.7424543 },
    { lat: 49.1685332, lng: 18.7426105 },
    { lat: 49.1684836, lng: 18.7426741 },
    { lat: 49.1684052, lng: 18.7426664 },
    { lat: 49.1683627, lng: 18.7426492 },
    { lat: 49.1683293, lng: 18.7426172 },
    { lat: 49.168331, lng: 18.7425637 },
    { lat: 49.1682934, lng: 18.7425755 },
    { lat: 49.1682363, lng: 18.7425193 },
    { lat: 49.1681353, lng: 18.7425078 },
    { lat: 49.1680658, lng: 18.7424709 },
    { lat: 49.167966, lng: 18.742461 },
    { lat: 49.1679235, lng: 18.7424024 },
    { lat: 49.167898, lng: 18.7423663 },
    { lat: 49.1678942, lng: 18.742361 },
    { lat: 49.1678725, lng: 18.7423315 },
    { lat: 49.1678034, lng: 18.7422834 },
    { lat: 49.167736, lng: 18.7421795 },
    { lat: 49.1677404, lng: 18.7420914 },
    { lat: 49.1676646, lng: 18.7420253 },
    { lat: 49.1676153, lng: 18.7420302 },
    { lat: 49.1675503, lng: 18.7419487 },
    { lat: 49.1675024, lng: 18.7419409 },
    { lat: 49.167448, lng: 18.7419583 },
    { lat: 49.167364, lng: 18.7419469 },
    { lat: 49.1672895, lng: 18.7419689 },
    { lat: 49.1671879, lng: 18.741964 },
    { lat: 49.1670865, lng: 18.7419684 },
    { lat: 49.1669071, lng: 18.7420295 },
    { lat: 49.166805, lng: 18.7419929 },
    { lat: 49.1667215, lng: 18.7419515 },
    { lat: 49.166364, lng: 18.7416029 },
    { lat: 49.1663294, lng: 18.7415935 },
    { lat: 49.1662511, lng: 18.7416235 },
    { lat: 49.1661447, lng: 18.7416397 },
    { lat: 49.1661361, lng: 18.7416458 },
    { lat: 49.1660038, lng: 18.7417466 },
    { lat: 49.1648453, lng: 18.7425386 },
    { lat: 49.1645974, lng: 18.7426685 },
    { lat: 49.1646094, lng: 18.7432231 },
    { lat: 49.1643415, lng: 18.7450421 },
    { lat: 49.1643749, lng: 18.7459418 },
    { lat: 49.1641743, lng: 18.7470003 },
    { lat: 49.1641385, lng: 18.7473924 },
    { lat: 49.1640948, lng: 18.7477227 },
    { lat: 49.1640512, lng: 18.7481175 },
    { lat: 49.1639474, lng: 18.7485513 },
    { lat: 49.1638983, lng: 18.7487562 },
    { lat: 49.1637888, lng: 18.7492231 },
    { lat: 49.1637036, lng: 18.7495606 },
    { lat: 49.1635706, lng: 18.7500769 },
    { lat: 49.1634456, lng: 18.7505555 },
    { lat: 49.1633124, lng: 18.7510743 },
    { lat: 49.1640853, lng: 18.751277 },
    { lat: 49.1646686, lng: 18.7498124 },
    { lat: 49.1651015, lng: 18.7498315 },
    { lat: 49.1654851, lng: 18.7500739 },
    { lat: 49.1659949, lng: 18.7502924 },
    { lat: 49.1663729, lng: 18.7499975 },
    { lat: 49.1671577, lng: 18.7498002 },
    { lat: 49.1675192, lng: 18.7492748 },
    { lat: 49.1675665, lng: 18.7492939 },
    { lat: 49.1677994, lng: 18.7493507 },
    { lat: 49.1679905, lng: 18.7493815 },
    { lat: 49.1681556, lng: 18.7494519 },
    { lat: 49.1683606, lng: 18.749427 },
    { lat: 49.1686628, lng: 18.7493179 },
    { lat: 49.1688011, lng: 18.7489667 },
    { lat: 49.1692352, lng: 18.7490307 },
    { lat: 49.169361, lng: 18.7493224 },
    { lat: 49.1696883, lng: 18.7493267 },
    { lat: 49.1702712, lng: 18.7492627 },
    { lat: 49.1707403, lng: 18.7485715 },
    { lat: 49.1709243, lng: 18.7484266 },
    { lat: 49.1711367, lng: 18.7483669 },
    { lat: 49.1714268, lng: 18.7487183 },
    { lat: 49.1717115, lng: 18.7493331 },
    { lat: 49.1721034, lng: 18.7497778 },
    { lat: 49.1728022, lng: 18.750355 },
    { lat: 49.1731758, lng: 18.7502335 },
    { lat: 49.1734826, lng: 18.7504702 },
    { lat: 49.1736687, lng: 18.7505816 },
    { lat: 49.1741289, lng: 18.7510134 },
    { lat: 49.1744977, lng: 18.751338 },
    { lat: 49.1748585, lng: 18.7518135 },
    { lat: 49.1749869, lng: 18.751941 },
    { lat: 49.1751648, lng: 18.7521079 },
    { lat: 49.17546, lng: 18.7523863 },
    { lat: 49.1758188, lng: 18.7526696 },
    { lat: 49.1762469, lng: 18.7529434 },
    { lat: 49.1767432, lng: 18.7532263 },
    { lat: 49.1770977, lng: 18.7533627 },
    { lat: 49.1774846, lng: 18.7535598 },
    { lat: 49.1783314, lng: 18.7538841 },
    { lat: 49.178487, lng: 18.7538828 },
    { lat: 49.1786743, lng: 18.7537985 },
    { lat: 49.1787312, lng: 18.7536798 },
    { lat: 49.178684, lng: 18.7534224 },
    { lat: 49.1788829, lng: 18.7530015 },
    { lat: 49.1790684, lng: 18.7528391 },
    { lat: 49.1799618, lng: 18.7524541 },
    { lat: 49.1800781, lng: 18.7521773 },
    { lat: 49.1802406, lng: 18.7520281 },
    { lat: 49.1805159, lng: 18.7518145 },
    { lat: 49.1807868, lng: 18.751907 },
    { lat: 49.1809487, lng: 18.7519165 },
    { lat: 49.1811313, lng: 18.7518976 },
    { lat: 49.1812724, lng: 18.7518981 },
    { lat: 49.1813916, lng: 18.7518285 },
    { lat: 49.1815496, lng: 18.7515505 },
    { lat: 49.181681, lng: 18.7515371 },
    { lat: 49.1818669, lng: 18.751495 },
    { lat: 49.1822044, lng: 18.7513928 },
    { lat: 49.1822444, lng: 18.75138 },
    { lat: 49.1823462, lng: 18.7512807 },
    { lat: 49.1824128, lng: 18.7512161 },
    { lat: 49.1824398, lng: 18.7511904 },
    { lat: 49.1824589, lng: 18.7511712 },
    { lat: 49.1823898, lng: 18.7507762 },
    { lat: 49.1825138, lng: 18.7506894 },
    { lat: 49.1825651, lng: 18.750654 },
    { lat: 49.1828635, lng: 18.7506019 },
    { lat: 49.1831058, lng: 18.7503879 },
    { lat: 49.1831749, lng: 18.7502861 },
    { lat: 49.1832653, lng: 18.7503739 },
    { lat: 49.1833866, lng: 18.7504929 },
    { lat: 49.1836406, lng: 18.7505875 },
    { lat: 49.1840357, lng: 18.7505694 },
    { lat: 49.1848877, lng: 18.7504785 },
    { lat: 49.1860992, lng: 18.7502888 },
    { lat: 49.1859505, lng: 18.7488491 },
    { lat: 49.1859404, lng: 18.7487598 },
    { lat: 49.1878134, lng: 18.7488938 },
    { lat: 49.1890909, lng: 18.7492977 },
    { lat: 49.1896961, lng: 18.749488 },
    { lat: 49.190367, lng: 18.7495936 },
    { lat: 49.1905505, lng: 18.7496322 },
    { lat: 49.1913863, lng: 18.7493356 },
    { lat: 49.1924091, lng: 18.7489727 },
    { lat: 49.1925389, lng: 18.7489266 },
    { lat: 49.1932753, lng: 18.7487974 },
    { lat: 49.193687, lng: 18.7489082 },
    { lat: 49.1944703, lng: 18.7490293 },
    { lat: 49.1947393, lng: 18.7491311 },
    { lat: 49.1949757, lng: 18.7492733 },
    { lat: 49.1955265, lng: 18.7495409 },
    { lat: 49.1958499, lng: 18.7496476 },
    { lat: 49.1960764, lng: 18.7496414 },
    { lat: 49.1962081, lng: 18.7496383 },
    { lat: 49.1964184, lng: 18.7496925 },
    { lat: 49.1963903, lng: 18.7511828 },
    { lat: 49.1963413, lng: 18.7524255 },
    { lat: 49.1962553, lng: 18.7544547 },
    { lat: 49.1961303, lng: 18.7559921 },
    { lat: 49.1960619, lng: 18.7568405 },
    { lat: 49.1960731, lng: 18.7569285 },
    { lat: 49.1960841, lng: 18.7570094 },
    { lat: 49.1960898, lng: 18.7570548 },
    { lat: 49.1960426, lng: 18.7577492 },
    { lat: 49.1958726, lng: 18.7597871 },
    { lat: 49.1958281, lng: 18.7605573 },
    { lat: 49.1958544, lng: 18.7615377 },
    { lat: 49.1957363, lng: 18.7626044 },
    { lat: 49.1957113, lng: 18.7633358 },
    { lat: 49.195703, lng: 18.7635019 },
    { lat: 49.1957512, lng: 18.7644926 },
    { lat: 49.1957676, lng: 18.7653286 },
    { lat: 49.1957781, lng: 18.7656583 },
    { lat: 49.1957457, lng: 18.7662063 },
    { lat: 49.1957398, lng: 18.7662654 },
    { lat: 49.1957287, lng: 18.7663565 },
    { lat: 49.1957187, lng: 18.7664457 },
    { lat: 49.1961749, lng: 18.7665022 },
    { lat: 49.1961176, lng: 18.766654 },
    { lat: 49.1960422, lng: 18.7668539 },
    { lat: 49.1960361, lng: 18.7668695 },
    { lat: 49.1958251, lng: 18.7674283 },
    { lat: 49.1957848, lng: 18.7674342 },
    { lat: 49.1957819, lng: 18.7674642 },
    { lat: 49.1957579, lng: 18.7675021 },
    { lat: 49.195758, lng: 18.7675457 },
    { lat: 49.1957809, lng: 18.7675768 },
    { lat: 49.1958123, lng: 18.7675939 },
    { lat: 49.1958242, lng: 18.7676278 },
    { lat: 49.1958194, lng: 18.767668 },
    { lat: 49.1958335, lng: 18.767697 },
    { lat: 49.1958559, lng: 18.7676971 },
    { lat: 49.1958809, lng: 18.7677013 },
    { lat: 49.1958925, lng: 18.7677464 },
    { lat: 49.1959507, lng: 18.7678255 },
    { lat: 49.195983, lng: 18.7678198 },
    { lat: 49.1960135, lng: 18.7677959 },
    { lat: 49.1960383, lng: 18.76782 },
    { lat: 49.1960463, lng: 18.7679088 },
    { lat: 49.1960816, lng: 18.767938 },
    { lat: 49.1960938, lng: 18.767978 },
    { lat: 49.1960893, lng: 18.7680864 },
    { lat: 49.1960975, lng: 18.7681117 },
    { lat: 49.1961127, lng: 18.7681235 },
    { lat: 49.1961294, lng: 18.7681392 },
    { lat: 49.1961537, lng: 18.7681979 },
    { lat: 49.1961621, lng: 18.7682499 },
    { lat: 49.19621, lng: 18.7683252 },
    { lat: 49.1962442, lng: 18.7683751 },
    { lat: 49.1962683, lng: 18.7684058 },
    { lat: 49.1962946, lng: 18.768435 },
    { lat: 49.1963141, lng: 18.7685246 },
    { lat: 49.196312, lng: 18.7685739 },
    { lat: 49.1963195, lng: 18.768609 },
    { lat: 49.1963262, lng: 18.7686515 },
    { lat: 49.196317, lng: 18.7686706 },
    { lat: 49.1962937, lng: 18.7686998 },
    { lat: 49.1962895, lng: 18.768723 },
    { lat: 49.1963185, lng: 18.768912 },
    { lat: 49.1963302, lng: 18.7689885 },
    { lat: 49.1963404, lng: 18.7690107 },
    { lat: 49.1963794, lng: 18.7690207 },
    { lat: 49.1964061, lng: 18.7690568 },
    { lat: 49.1964056, lng: 18.7691164 },
    { lat: 49.1963711, lng: 18.7691429 },
    { lat: 49.1963565, lng: 18.769149 },
    { lat: 49.1963487, lng: 18.7691885 },
    { lat: 49.1963871, lng: 18.7694092 },
    { lat: 49.1964377, lng: 18.7694911 },
    { lat: 49.1964318, lng: 18.769597 },
    { lat: 49.1964194, lng: 18.7696169 },
    { lat: 49.1963961, lng: 18.7696223 },
    { lat: 49.1963691, lng: 18.7696036 },
    { lat: 49.1963464, lng: 18.7696207 },
    { lat: 49.1963434, lng: 18.7696553 },
    { lat: 49.1963614, lng: 18.7696737 },
    { lat: 49.1963682, lng: 18.7696729 },
    { lat: 49.1963738, lng: 18.7696931 },
    { lat: 49.1963592, lng: 18.7698485 },
    { lat: 49.196382, lng: 18.7700469 },
    { lat: 49.1964672, lng: 18.7701909 },
    { lat: 49.196556, lng: 18.7703168 },
    { lat: 49.1965747, lng: 18.7703257 },
    { lat: 49.1966278, lng: 18.7702847 },
    { lat: 49.1966849, lng: 18.7702558 },
    { lat: 49.1967045, lng: 18.7702862 },
    { lat: 49.1967294, lng: 18.7703755 },
    { lat: 49.1967392, lng: 18.7704129 },
    { lat: 49.1967241, lng: 18.7704461 },
    { lat: 49.1967035, lng: 18.7704403 },
    { lat: 49.1966784, lng: 18.7704797 },
    { lat: 49.1966749, lng: 18.7705169 },
    { lat: 49.1966791, lng: 18.7705582 },
    { lat: 49.1966939, lng: 18.7706015 },
    { lat: 49.1967331, lng: 18.7706653 },
    { lat: 49.1967629, lng: 18.7707792 },
    { lat: 49.1968037, lng: 18.77085 },
    { lat: 49.1970159, lng: 18.7709815 },
    { lat: 49.1970434, lng: 18.7709919 },
    { lat: 49.1970804, lng: 18.771009 },
    { lat: 49.1972281, lng: 18.7712266 },
    { lat: 49.1972623, lng: 18.7712351 },
    { lat: 49.1973034, lng: 18.7712214 },
    { lat: 49.1973666, lng: 18.7712001 },
    { lat: 49.1974112, lng: 18.7712369 },
    { lat: 49.1974203, lng: 18.7712797 },
    { lat: 49.1974971, lng: 18.7713469 },
    { lat: 49.1975611, lng: 18.7713413 },
    { lat: 49.1975743, lng: 18.7713379 },
    { lat: 49.1975985, lng: 18.7713883 },
    { lat: 49.1976811, lng: 18.7714375 },
    { lat: 49.1977338, lng: 18.7714736 },
    { lat: 49.1977954, lng: 18.7714676 },
    { lat: 49.1978388, lng: 18.7714793 },
    { lat: 49.1979189, lng: 18.7714563 },
    { lat: 49.1980106, lng: 18.7713763 },
    { lat: 49.1981289, lng: 18.7713749 },
    { lat: 49.1982803, lng: 18.7714684 },
    { lat: 49.1984172, lng: 18.7716068 },
    { lat: 49.1984411, lng: 18.7716112 },
    { lat: 49.1984966, lng: 18.7715705 },
    { lat: 49.198497, lng: 18.7714915 },
    { lat: 49.1985198, lng: 18.7714527 },
    { lat: 49.1985993, lng: 18.7714651 },
    { lat: 49.1986644, lng: 18.7715895 },
    { lat: 49.1987204, lng: 18.7716473 },
    { lat: 49.1987714, lng: 18.7716519 },
    { lat: 49.19881, lng: 18.7716801 },
    { lat: 49.1988745, lng: 18.7716394 },
    { lat: 49.1989015, lng: 18.7715327 },
    { lat: 49.1989821, lng: 18.7714559 },
    { lat: 49.1990811, lng: 18.7714922 },
    { lat: 49.199146, lng: 18.7715034 },
    { lat: 49.1991895, lng: 18.7715807 },
    { lat: 49.1992745, lng: 18.7716123 },
    { lat: 49.1994172, lng: 18.7716667 },
    { lat: 49.1994435, lng: 18.7716765 },
    { lat: 49.1995121, lng: 18.7716502 },
    { lat: 49.1996925, lng: 18.7718213 },
    { lat: 49.1997984, lng: 18.7718445 },
    { lat: 49.1998499, lng: 18.7718815 },
    { lat: 49.1999854, lng: 18.7718428 },
    { lat: 49.2000905, lng: 18.7717147 },
    { lat: 49.2001484, lng: 18.7717008 },
    { lat: 49.2003512, lng: 18.7718071 },
    { lat: 49.2003924, lng: 18.7718634 },
    { lat: 49.2004498, lng: 18.7719672 },
    { lat: 49.2004957, lng: 18.7719822 },
    { lat: 49.2006331, lng: 18.7719562 },
    { lat: 49.2007479, lng: 18.7720205 },
    { lat: 49.2008166, lng: 18.7720364 },
    { lat: 49.2008414, lng: 18.7720828 },
    { lat: 49.2008431, lng: 18.7720923 },
    { lat: 49.2008343, lng: 18.7721637 },
    { lat: 49.2008093, lng: 18.7722002 },
    { lat: 49.2006438, lng: 18.7722495 },
    { lat: 49.2005827, lng: 18.7722453 },
    { lat: 49.2005079, lng: 18.7722396 },
    { lat: 49.2002919, lng: 18.7722243 },
    { lat: 49.2000175, lng: 18.7722186 },
    { lat: 49.1995964, lng: 18.7724499 },
    { lat: 49.1993344, lng: 18.7727646 },
    { lat: 49.1991353, lng: 18.7730101 },
    { lat: 49.198932, lng: 18.7731826 },
    { lat: 49.1985505, lng: 18.7733467 },
    { lat: 49.1984103, lng: 18.7734285 },
    { lat: 49.1981495, lng: 18.7735694 },
    { lat: 49.1977372, lng: 18.773775 },
    { lat: 49.197521, lng: 18.7739743 },
    { lat: 49.197234, lng: 18.7739398 },
    { lat: 49.1970816, lng: 18.7739575 },
    { lat: 49.1969533, lng: 18.7740304 },
    { lat: 49.1968692, lng: 18.774082 },
    { lat: 49.1967806, lng: 18.7741367 },
    { lat: 49.1963262, lng: 18.7738939 },
    { lat: 49.1961239, lng: 18.7737799 },
    { lat: 49.1959124, lng: 18.7738744 },
    { lat: 49.195405, lng: 18.7735738 },
    { lat: 49.1951425, lng: 18.7734459 },
    { lat: 49.1951252, lng: 18.7734404 },
    { lat: 49.195024, lng: 18.773404 },
    { lat: 49.1950196, lng: 18.7734233 },
    { lat: 49.1949889, lng: 18.7735272 },
    { lat: 49.1949827, lng: 18.7736275 },
    { lat: 49.1949795, lng: 18.7736751 },
    { lat: 49.1948791, lng: 18.775407 },
    { lat: 49.1948738, lng: 18.7754596 },
    { lat: 49.1948516, lng: 18.7756822 },
    { lat: 49.1947864, lng: 18.7763553 },
    { lat: 49.1946369, lng: 18.7771705 },
    { lat: 49.1941986, lng: 18.7780895 },
    { lat: 49.1940339, lng: 18.7783034 },
    { lat: 49.1939665, lng: 18.778415 },
    { lat: 49.1933768, lng: 18.7793942 },
    { lat: 49.1933727, lng: 18.7794019 },
    { lat: 49.193352, lng: 18.7794357 },
    { lat: 49.1931109, lng: 18.7793521 },
    { lat: 49.1923781, lng: 18.7789868 },
    { lat: 49.1920164, lng: 18.7788427 },
    { lat: 49.1915479, lng: 18.7787649 },
    { lat: 49.1912548, lng: 18.7787005 },
    { lat: 49.1909784, lng: 18.7787649 },
    { lat: 49.1905778, lng: 18.7787127 },
    { lat: 49.1900462, lng: 18.7787904 },
    { lat: 49.1896953, lng: 18.7787863 },
    { lat: 49.189287, lng: 18.7788505 },
    { lat: 49.1881033, lng: 18.7784002 },
    { lat: 49.1875381, lng: 18.7784138 },
    { lat: 49.1873431, lng: 18.7789586 },
    { lat: 49.1873222, lng: 18.7790805 },
    { lat: 49.1873616, lng: 18.7794247 },
    { lat: 49.1873114, lng: 18.780041 },
    { lat: 49.1872638, lng: 18.7805158 },
    { lat: 49.1872563, lng: 18.7805866 },
    { lat: 49.1871879, lng: 18.781376 },
    { lat: 49.1870896, lng: 18.7824575 },
    { lat: 49.1870625, lng: 18.7826734 },
    { lat: 49.1869539, lng: 18.7831025 },
    { lat: 49.1868828, lng: 18.7834038 },
    { lat: 49.1868837, lng: 18.7836391 },
    { lat: 49.1866022, lng: 18.7834741 },
    { lat: 49.1862117, lng: 18.7832293 },
    { lat: 49.1860974, lng: 18.7831527 },
    { lat: 49.1858391, lng: 18.7829806 },
    { lat: 49.1856177, lng: 18.7829071 },
    { lat: 49.1847444, lng: 18.7830487 },
    { lat: 49.1839265, lng: 18.7831908 },
    { lat: 49.1830335, lng: 18.7832817 },
    { lat: 49.1824404, lng: 18.7832157 },
    { lat: 49.1818487, lng: 18.7830889 },
    { lat: 49.1809949, lng: 18.7829539 },
    { lat: 49.1805787, lng: 18.7828661 },
    { lat: 49.180227, lng: 18.7828044 },
    { lat: 49.1798743, lng: 18.7827018 },
    { lat: 49.1798053, lng: 18.7839107 },
    { lat: 49.1798737, lng: 18.78462 },
    { lat: 49.1799584, lng: 18.7855141 },
    { lat: 49.1803378, lng: 18.7863038 },
    { lat: 49.1804791, lng: 18.7869193 },
    { lat: 49.1807219, lng: 18.7874724 },
    { lat: 49.1808987, lng: 18.7881204 },
    { lat: 49.1804291, lng: 18.7886972 },
    { lat: 49.1800264, lng: 18.7890648 },
    { lat: 49.1794864, lng: 18.7896327 },
    { lat: 49.1793732, lng: 18.7897729 },
    { lat: 49.1793016, lng: 18.7896774 },
    { lat: 49.1791161, lng: 18.7898173 },
    { lat: 49.1789441, lng: 18.7900233 },
    { lat: 49.1787754, lng: 18.7902912 },
    { lat: 49.1786691, lng: 18.7903924 },
    { lat: 49.1784506, lng: 18.7910736 },
    { lat: 49.1782776, lng: 18.7913915 },
    { lat: 49.1781722, lng: 18.7916193 },
    { lat: 49.1780805, lng: 18.7918506 },
    { lat: 49.1779611, lng: 18.7921353 },
    { lat: 49.1778206, lng: 18.7923195 },
    { lat: 49.1778404, lng: 18.7923517 },
    { lat: 49.1777346, lng: 18.7924438 },
    { lat: 49.1777343, lng: 18.7925011 },
    { lat: 49.1777091, lng: 18.7925384 },
    { lat: 49.1777021, lng: 18.7925778 },
    { lat: 49.1776792, lng: 18.792591 },
    { lat: 49.1776883, lng: 18.7926584 },
    { lat: 49.1776954, lng: 18.7927303 },
    { lat: 49.177699, lng: 18.7928236 },
    { lat: 49.1776338, lng: 18.7928693 },
    { lat: 49.1776201, lng: 18.792956 },
    { lat: 49.177618, lng: 18.7930204 },
    { lat: 49.1776363, lng: 18.7930726 },
    { lat: 49.177627, lng: 18.7931303 },
    { lat: 49.1776079, lng: 18.7931986 },
    { lat: 49.1775688, lng: 18.79327 },
    { lat: 49.1775148, lng: 18.7933817 },
    { lat: 49.1774794, lng: 18.7934361 },
    { lat: 49.177437, lng: 18.7935292 },
    { lat: 49.1774279, lng: 18.7935512 },
    { lat: 49.1774085, lng: 18.7936364 },
    { lat: 49.1773859, lng: 18.7936791 },
    { lat: 49.1773613, lng: 18.7937473 },
    { lat: 49.1771326, lng: 18.793816 },
    { lat: 49.176958, lng: 18.7938616 },
    { lat: 49.1768334, lng: 18.794006 },
    { lat: 49.1767803, lng: 18.7940437 },
    { lat: 49.1767538, lng: 18.7940116 },
    { lat: 49.1767057, lng: 18.7940401 },
    { lat: 49.1766419, lng: 18.7941368 },
    { lat: 49.1766559, lng: 18.7942779 },
    { lat: 49.1766056, lng: 18.7943063 },
    { lat: 49.1765421, lng: 18.7943446 },
    { lat: 49.1764477, lng: 18.7942633 },
    { lat: 49.1764309, lng: 18.7943554 },
    { lat: 49.1764882, lng: 18.7944801 },
    { lat: 49.1764566, lng: 18.7945217 },
    { lat: 49.1763658, lng: 18.794466 },
    { lat: 49.1763031, lng: 18.7944732 },
    { lat: 49.1762068, lng: 18.7945526 },
    { lat: 49.1761501, lng: 18.7945252 },
    { lat: 49.1760786, lng: 18.7944325 },
    { lat: 49.17605, lng: 18.7944234 },
    { lat: 49.1760309, lng: 18.7944686 },
    { lat: 49.1760363, lng: 18.7945504 },
    { lat: 49.1760025, lng: 18.7945282 },
    { lat: 49.1759713, lng: 18.7944674 },
    { lat: 49.1759348, lng: 18.7944394 },
    { lat: 49.1758819, lng: 18.7944627 },
    { lat: 49.175881, lng: 18.794536 },
    { lat: 49.1758422, lng: 18.7945475 },
    { lat: 49.1758006, lng: 18.7945259 },
    { lat: 49.1757552, lng: 18.7945198 },
    { lat: 49.1757333, lng: 18.7944039 },
    { lat: 49.1756515, lng: 18.7943932 },
    { lat: 49.1756233, lng: 18.7944553 },
    { lat: 49.1756314, lng: 18.7945257 },
    { lat: 49.1756186, lng: 18.7945828 },
    { lat: 49.1755805, lng: 18.7946097 },
    { lat: 49.1755512, lng: 18.7946327 },
    { lat: 49.1755162, lng: 18.7945854 },
    { lat: 49.1754787, lng: 18.7946245 },
    { lat: 49.1754403, lng: 18.7946028 },
    { lat: 49.1754065, lng: 18.7945392 },
    { lat: 49.1753715, lng: 18.794539 },
    { lat: 49.1753615, lng: 18.7946066 },
    { lat: 49.1753212, lng: 18.7945898 },
    { lat: 49.1752965, lng: 18.7946324 },
    { lat: 49.1752536, lng: 18.7946581 },
    { lat: 49.1752127, lng: 18.7946292 },
    { lat: 49.1751825, lng: 18.7946764 },
    { lat: 49.1751494, lng: 18.7947129 },
    { lat: 49.1751112, lng: 18.7946912 },
    { lat: 49.1750683, lng: 18.7947115 },
    { lat: 49.1749871, lng: 18.7947764 },
    { lat: 49.1749595, lng: 18.794854 },
    { lat: 49.174924, lng: 18.7948208 },
    { lat: 49.1749037, lng: 18.7947133 },
    { lat: 49.1748668, lng: 18.7946125 },
    { lat: 49.1748397, lng: 18.7945675 },
    { lat: 49.1747963, lng: 18.7946001 },
    { lat: 49.1747504, lng: 18.7945869 },
    { lat: 49.174715, lng: 18.7945314 },
    { lat: 49.1746853, lng: 18.7945706 },
    { lat: 49.1746648, lng: 18.7945843 },
    { lat: 49.1746288, lng: 18.7946279 },
    { lat: 49.1745775, lng: 18.794642 },
    { lat: 49.1745222, lng: 18.7946418 },
    { lat: 49.1744765, lng: 18.7946073 },
    { lat: 49.1744426, lng: 18.7945415 },
    { lat: 49.1744026, lng: 18.7944685 },
    { lat: 49.174372, lng: 18.7944606 },
    { lat: 49.1743526, lng: 18.7943909 },
    { lat: 49.1743042, lng: 18.7944385 },
    { lat: 49.1742643, lng: 18.7944516 },
    { lat: 49.1742196, lng: 18.7944145 },
    { lat: 49.1741749, lng: 18.7944466 },
    { lat: 49.1741177, lng: 18.7944495 },
    { lat: 49.1740923, lng: 18.7944608 },
    { lat: 49.1740469, lng: 18.7944346 },
    { lat: 49.1740167, lng: 18.7943755 },
    { lat: 49.1740056, lng: 18.7943751 },
    { lat: 49.1739843, lng: 18.7944652 },
    { lat: 49.1739294, lng: 18.7945144 },
    { lat: 49.1738949, lng: 18.7945683 },
    { lat: 49.1738774, lng: 18.7946464 },
    { lat: 49.1738542, lng: 18.7947184 },
    { lat: 49.1738215, lng: 18.7947854 },
    { lat: 49.1737866, lng: 18.7948282 },
    { lat: 49.173743, lng: 18.7948561 },
    { lat: 49.1737178, lng: 18.7949136 },
    { lat: 49.1736965, lng: 18.794963 },
    { lat: 49.1736692, lng: 18.7950193 },
    { lat: 49.173676, lng: 18.7951514 },
    { lat: 49.1736376, lng: 18.7952173 },
    { lat: 49.1736108, lng: 18.7952422 },
    { lat: 49.1735246, lng: 18.7952799 },
    { lat: 49.1735158, lng: 18.795347 },
    { lat: 49.173467, lng: 18.7953853 },
    { lat: 49.1734483, lng: 18.7953702 },
    { lat: 49.1734429, lng: 18.7953529 },
    { lat: 49.173355, lng: 18.7953984 },
    { lat: 49.173314, lng: 18.7954563 },
    { lat: 49.173288, lng: 18.7955658 },
    { lat: 49.1732859, lng: 18.7956986 },
    { lat: 49.1732757, lng: 18.7958069 },
    { lat: 49.1731736, lng: 18.7958783 },
    { lat: 49.1731413, lng: 18.7959121 },
    { lat: 49.1730998, lng: 18.7959135 },
    { lat: 49.17304, lng: 18.7960007 },
    { lat: 49.1730003, lng: 18.7960419 },
    { lat: 49.1729469, lng: 18.7960541 },
    { lat: 49.1729356, lng: 18.7960962 },
    { lat: 49.1728784, lng: 18.7961471 },
    { lat: 49.1729079, lng: 18.7962365 },
    { lat: 49.172894, lng: 18.7963149 },
    { lat: 49.172902, lng: 18.7963835 },
    { lat: 49.1729201, lng: 18.7964271 },
    { lat: 49.1728914, lng: 18.7964388 },
    { lat: 49.1728888, lng: 18.7964799 },
    { lat: 49.1728533, lng: 18.7964881 },
    { lat: 49.1728231, lng: 18.796539 },
    { lat: 49.1727933, lng: 18.7965519 },
    { lat: 49.1728047, lng: 18.7965761 },
    { lat: 49.1728073, lng: 18.7966256 },
    { lat: 49.1727708, lng: 18.7966606 },
    { lat: 49.1727751, lng: 18.7967657 },
    { lat: 49.1727988, lng: 18.7968298 },
    { lat: 49.1727886, lng: 18.7968938 },
    { lat: 49.1727608, lng: 18.7969234 },
    { lat: 49.1726857, lng: 18.7968456 },
    { lat: 49.1726243, lng: 18.7967921 },
    { lat: 49.1726256, lng: 18.7969037 },
    { lat: 49.1726201, lng: 18.7969303 },
    { lat: 49.1726083, lng: 18.796962 },
    { lat: 49.1725723, lng: 18.7969836 },
    { lat: 49.1725379, lng: 18.7970811 },
    { lat: 49.1725036, lng: 18.7970715 },
    { lat: 49.1724533, lng: 18.7970801 },
    { lat: 49.172414, lng: 18.797216 },
    { lat: 49.1723271, lng: 18.7972575 },
    { lat: 49.1720102, lng: 18.7974078 },
    { lat: 49.1719492, lng: 18.7974468 },
    { lat: 49.1719284, lng: 18.7975066 },
    { lat: 49.1719335, lng: 18.7976048 },
    { lat: 49.1719042, lng: 18.7976472 },
    { lat: 49.1718644, lng: 18.7976628 },
    { lat: 49.1718466, lng: 18.7977111 },
    { lat: 49.1718028, lng: 18.7977773 },
    { lat: 49.1717617, lng: 18.7977923 },
    { lat: 49.171711, lng: 18.7978164 },
    { lat: 49.1716827, lng: 18.7978534 },
    { lat: 49.1716513, lng: 18.7978824 },
    { lat: 49.1716201, lng: 18.7978868 },
    { lat: 49.1715223, lng: 18.7979603 },
    { lat: 49.1715089, lng: 18.7980256 },
    { lat: 49.1714854, lng: 18.7980962 },
    { lat: 49.1714656, lng: 18.7981289 },
    { lat: 49.1713675, lng: 18.7982362 },
    { lat: 49.1713552, lng: 18.7981505 },
    { lat: 49.1712869, lng: 18.7982038 },
    { lat: 49.1712568, lng: 18.7981883 },
    { lat: 49.1712293, lng: 18.798201 },
    { lat: 49.1711927, lng: 18.7981924 },
    { lat: 49.1710888, lng: 18.7984723 },
    { lat: 49.1709754, lng: 18.7985912 },
    { lat: 49.1707778, lng: 18.7985844 },
    { lat: 49.170682, lng: 18.7987599 },
    { lat: 49.1705746, lng: 18.7988863 },
    { lat: 49.170413, lng: 18.7988993 },
    { lat: 49.1702998, lng: 18.7990236 },
    { lat: 49.1701817, lng: 18.799201 },
    { lat: 49.1702105, lng: 18.799625 },
    { lat: 49.1701875, lng: 18.799747 },
    { lat: 49.1700923, lng: 18.7998468 },
    { lat: 49.1700707, lng: 18.7999726 },
    { lat: 49.1700808, lng: 18.8000182 },
    { lat: 49.1701457, lng: 18.8001398 },
    { lat: 49.1702195, lng: 18.8002588 },
    { lat: 49.1702778, lng: 18.8003808 },
    { lat: 49.1703084, lng: 18.8005573 },
    { lat: 49.17033, lng: 18.8008246 },
    { lat: 49.1703344, lng: 18.8010601 },
    { lat: 49.1703333, lng: 18.8012797 },
    { lat: 49.1703534, lng: 18.8012758 },
    { lat: 49.1703418, lng: 18.8013583 },
    { lat: 49.170261, lng: 18.8013208 },
    { lat: 49.1702157, lng: 18.8013367 },
    { lat: 49.1701686, lng: 18.8015168 },
    { lat: 49.1701028, lng: 18.8016602 },
    { lat: 49.1699872, lng: 18.8016741 },
    { lat: 49.1698952, lng: 18.8017029 },
    { lat: 49.169846, lng: 18.8017531 },
    { lat: 49.1696869, lng: 18.8017323 },
    { lat: 49.1696171, lng: 18.8016448 },
    { lat: 49.1695562, lng: 18.8016453 },
    { lat: 49.1694465, lng: 18.8017908 },
    { lat: 49.1694475, lng: 18.8019193 },
    { lat: 49.1693508, lng: 18.8027086 },
    { lat: 49.169344, lng: 18.8029274 },
    { lat: 49.1693424, lng: 18.8030256 },
    { lat: 49.169339, lng: 18.803137 },
    { lat: 49.1693463, lng: 18.8031873 },
    { lat: 49.1693145, lng: 18.8032707 },
    { lat: 49.1692783, lng: 18.8033111 },
    { lat: 49.1691647, lng: 18.8036228 },
    { lat: 49.169088, lng: 18.8037524 },
    { lat: 49.1685751, lng: 18.8040174 },
    { lat: 49.1685249, lng: 18.8040483 },
    { lat: 49.1679171, lng: 18.8044201 },
    { lat: 49.1678387, lng: 18.8044948 },
    { lat: 49.1676086, lng: 18.804778 },
    { lat: 49.1673999, lng: 18.8051245 },
    { lat: 49.1672145, lng: 18.8056131 },
    { lat: 49.1669636, lng: 18.8060847 },
    { lat: 49.1668596, lng: 18.8065134 },
    { lat: 49.1664534, lng: 18.8070966 },
    { lat: 49.1662309, lng: 18.8076552 },
    { lat: 49.1659578, lng: 18.8082177 },
    { lat: 49.1654804, lng: 18.8092981 },
    { lat: 49.1653547, lng: 18.8094396 },
    { lat: 49.1650438, lng: 18.8102538 },
    { lat: 49.1649553, lng: 18.8104623 },
    { lat: 49.1648634, lng: 18.8107147 },
    { lat: 49.1646902, lng: 18.8111791 },
    { lat: 49.1643346, lng: 18.8117825 },
    { lat: 49.1639454, lng: 18.8124548 },
    { lat: 49.163681, lng: 18.8129661 },
    { lat: 49.1634955, lng: 18.8133267 },
    { lat: 49.1634354, lng: 18.8134312 },
    { lat: 49.163187, lng: 18.8142757 },
    { lat: 49.1630326, lng: 18.8153665 },
    { lat: 49.1630267, lng: 18.8153848 },
    { lat: 49.1632096, lng: 18.8154983 },
    { lat: 49.1633422, lng: 18.8156179 },
    { lat: 49.1635463, lng: 18.815727 },
    { lat: 49.163759, lng: 18.8157656 },
    { lat: 49.1639083, lng: 18.8157502 },
    { lat: 49.1641301, lng: 18.8159869 },
    { lat: 49.1642916, lng: 18.816036 },
    { lat: 49.1645568, lng: 18.8161252 },
    { lat: 49.1647902, lng: 18.8163022 },
    { lat: 49.1649667, lng: 18.8165768 },
    { lat: 49.1651521, lng: 18.8167585 },
    { lat: 49.1651724, lng: 18.8167171 },
    { lat: 49.1654069, lng: 18.8167138 },
    { lat: 49.1655297, lng: 18.8163658 },
    { lat: 49.1657583, lng: 18.8157218 },
    { lat: 49.1661378, lng: 18.8161536 },
    { lat: 49.1662046, lng: 18.8162209 },
    { lat: 49.1663391, lng: 18.8163586 },
    { lat: 49.1665156, lng: 18.8164834 },
    { lat: 49.1668644, lng: 18.8166386 },
    { lat: 49.1672928, lng: 18.8167035 },
    { lat: 49.1675992, lng: 18.8167845 },
    { lat: 49.1681542, lng: 18.8166624 },
    { lat: 49.1683328, lng: 18.8166236 },
    { lat: 49.168511, lng: 18.8165846 },
    { lat: 49.1686895, lng: 18.8165451 },
    { lat: 49.1688388, lng: 18.8165124 },
    { lat: 49.1689771, lng: 18.8164824 },
    { lat: 49.1692204, lng: 18.8163473 },
    { lat: 49.1693959, lng: 18.8162502 },
    { lat: 49.1695745, lng: 18.8161952 },
    { lat: 49.1699775, lng: 18.8160704 },
    { lat: 49.1702384, lng: 18.8159295 },
    { lat: 49.1703665, lng: 18.8158602 },
    { lat: 49.1704227, lng: 18.8158299 },
    { lat: 49.1704528, lng: 18.8157996 },
    { lat: 49.170565, lng: 18.8156802 },
    { lat: 49.1706045, lng: 18.8156794 },
    { lat: 49.1709706, lng: 18.8157183 },
    { lat: 49.1711628, lng: 18.8156394 },
    { lat: 49.1711996, lng: 18.8156321 },
    { lat: 49.1715528, lng: 18.8156606 },
    { lat: 49.1718076, lng: 18.8156797 },
    { lat: 49.1718545, lng: 18.8156734 },
    { lat: 49.1719751, lng: 18.8155825 },
    { lat: 49.1722843, lng: 18.8154812 },
    { lat: 49.1727642, lng: 18.815496 },
    { lat: 49.1731108, lng: 18.8155217 },
    { lat: 49.1731271, lng: 18.8164733 },
    { lat: 49.173607, lng: 18.8163562 },
    { lat: 49.1742487, lng: 18.8162031 },
    { lat: 49.1750465, lng: 18.8159998 },
    { lat: 49.1750595, lng: 18.8157896 },
    { lat: 49.1757709, lng: 18.8148742 },
    { lat: 49.1761518, lng: 18.8155027 },
    { lat: 49.1761821, lng: 18.8155448 },
    { lat: 49.1762185, lng: 18.8155952 },
    { lat: 49.1762325, lng: 18.8155373 },
    { lat: 49.1762394, lng: 18.8155379 },
    { lat: 49.1769103, lng: 18.8150144 },
    { lat: 49.1775647, lng: 18.8144989 },
    { lat: 49.1776773, lng: 18.8144104 },
    { lat: 49.1778247, lng: 18.8148407 },
    { lat: 49.1777283, lng: 18.8149172 },
    { lat: 49.1779686, lng: 18.8156188 },
    { lat: 49.1780208, lng: 18.8157776 },
    { lat: 49.1783755, lng: 18.8168166 },
    { lat: 49.1783849, lng: 18.8168443 },
    { lat: 49.1783666, lng: 18.8168602 },
    { lat: 49.1783876, lng: 18.8169182 },
    { lat: 49.1783929, lng: 18.8169356 },
    { lat: 49.1785559, lng: 18.8167736 },
    { lat: 49.1788216, lng: 18.8167857 },
    { lat: 49.1790509, lng: 18.8167509 },
    { lat: 49.1793827, lng: 18.8164739 },
    { lat: 49.1796538, lng: 18.8161955 },
    { lat: 49.1798496, lng: 18.8163003 },
    { lat: 49.180197, lng: 18.8159544 },
    { lat: 49.1805335, lng: 18.8159406 },
    { lat: 49.1808086, lng: 18.8157457 },
    { lat: 49.1811202, lng: 18.8157714 },
    { lat: 49.1814767, lng: 18.815775 },
    { lat: 49.1817436, lng: 18.8157289 },
    { lat: 49.1821179, lng: 18.8155095 },
    { lat: 49.1823698, lng: 18.8154273 },
    { lat: 49.182398, lng: 18.8149485 },
    { lat: 49.1824805, lng: 18.8147346 },
    { lat: 49.1825957, lng: 18.8148235 },
    { lat: 49.182771, lng: 18.8145951 },
    { lat: 49.1829898, lng: 18.8141843 },
    { lat: 49.1833333, lng: 18.8139354 },
    { lat: 49.1834543, lng: 18.8138541 },
    { lat: 49.1837117, lng: 18.8137511 },
    { lat: 49.1839648, lng: 18.81372 },
    { lat: 49.1842159, lng: 18.8138415 },
    { lat: 49.184438, lng: 18.8137539 },
    { lat: 49.1844349, lng: 18.8138055 },
    { lat: 49.1844317, lng: 18.8139572 },
    { lat: 49.1843764, lng: 18.8143957 },
    { lat: 49.1843037, lng: 18.8147548 },
    { lat: 49.1842447, lng: 18.8151411 },
    { lat: 49.1841077, lng: 18.815416 },
    { lat: 49.1840583, lng: 18.8158141 },
    { lat: 49.1840976, lng: 18.8161352 },
    { lat: 49.1841848, lng: 18.8163421 },
    { lat: 49.1843898, lng: 18.8165784 },
    { lat: 49.1848477, lng: 18.8162931 },
    { lat: 49.1851872, lng: 18.8162285 },
    { lat: 49.1850515, lng: 18.8173586 },
    { lat: 49.1849217, lng: 18.8184692 },
    { lat: 49.1850704, lng: 18.8186449 },
    { lat: 49.1851569, lng: 18.8196427 },
    { lat: 49.1856696, lng: 18.8198082 },
    { lat: 49.1855114, lng: 18.8209828 },
    { lat: 49.1877055, lng: 18.8229936 },
    { lat: 49.187674, lng: 18.8230799 },
    { lat: 49.1872956, lng: 18.8241815 },
    { lat: 49.1877087, lng: 18.8246287 },
    { lat: 49.1867858, lng: 18.8269386 },
    { lat: 49.1866002, lng: 18.826968 },
    { lat: 49.1862761, lng: 18.8272848 },
    { lat: 49.1861791, lng: 18.8269104 },
    { lat: 49.1859624, lng: 18.8266902 },
    { lat: 49.1851501, lng: 18.8258285 },
    { lat: 49.184838, lng: 18.8264019 },
    { lat: 49.1853715, lng: 18.8280659 },
    { lat: 49.1853965, lng: 18.8283549 },
    { lat: 49.1853283, lng: 18.8286486 },
    { lat: 49.185121, lng: 18.8290292 },
    { lat: 49.1842744, lng: 18.8302106 },
    { lat: 49.1840868, lng: 18.8305088 },
    { lat: 49.1840579, lng: 18.8307368 },
    { lat: 49.1842998, lng: 18.8314467 },
    { lat: 49.1845297, lng: 18.8318834 },
    { lat: 49.1851962, lng: 18.8327 },
    { lat: 49.1850757, lng: 18.833117 },
    { lat: 49.1850558, lng: 18.8331897 },
    { lat: 49.1849292, lng: 18.8337084 },
    { lat: 49.1849227, lng: 18.8337337 },
    { lat: 49.1849131, lng: 18.8337676 },
    { lat: 49.1850605, lng: 18.834047 },
    { lat: 49.1852158, lng: 18.834218 },
    { lat: 49.1854236, lng: 18.8344631 },
    { lat: 49.1856806, lng: 18.8346367 },
    { lat: 49.1859455, lng: 18.8347902 },
    { lat: 49.1860532, lng: 18.8346684 },
    { lat: 49.1863076, lng: 18.8345078 },
    { lat: 49.1866278, lng: 18.8345964 },
    { lat: 49.1868705, lng: 18.8347994 },
    { lat: 49.1870519, lng: 18.8347737 },
    { lat: 49.1873202, lng: 18.8346623 },
    { lat: 49.1875433, lng: 18.8343753 },
    { lat: 49.187635, lng: 18.8344065 },
    { lat: 49.1876588, lng: 18.834604 },
    { lat: 49.1876998, lng: 18.8348987 },
    { lat: 49.1878528, lng: 18.8349341 },
    { lat: 49.187975, lng: 18.8349439 },
    { lat: 49.1880362, lng: 18.8349489 },
    { lat: 49.1882779, lng: 18.834894 },
    { lat: 49.1883567, lng: 18.8349321 },
    { lat: 49.1885582, lng: 18.8349044 },
    { lat: 49.1886987, lng: 18.835027 },
    { lat: 49.188816, lng: 18.8350703 },
    { lat: 49.1890748, lng: 18.8349762 },
    { lat: 49.1893329, lng: 18.8350379 },
    { lat: 49.189374, lng: 18.8352893 },
    { lat: 49.1895284, lng: 18.8356353 },
    { lat: 49.1897217, lng: 18.8356911 },
    { lat: 49.1899522, lng: 18.8357449 },
    { lat: 49.1901457, lng: 18.8356716 },
    { lat: 49.190339, lng: 18.8354614 },
    { lat: 49.190491, lng: 18.8356786 },
    { lat: 49.1907007, lng: 18.8356297 },
    { lat: 49.19094, lng: 18.8357895 },
    { lat: 49.1910919, lng: 18.8359819 },
    { lat: 49.191291, lng: 18.8363016 },
    { lat: 49.1914296, lng: 18.8364104 },
    { lat: 49.191599, lng: 18.8364391 },
    { lat: 49.1917783, lng: 18.8364386 },
    { lat: 49.192041, lng: 18.8358224 },
    { lat: 49.1922103, lng: 18.8354989 },
    { lat: 49.1924179, lng: 18.8352174 },
    { lat: 49.1924061, lng: 18.8348132 },
    { lat: 49.1926982, lng: 18.8343727 },
    { lat: 49.1929137, lng: 18.8339388 },
    { lat: 49.1930306, lng: 18.8336014 },
    { lat: 49.1934164, lng: 18.8327516 },
    { lat: 49.1935007, lng: 18.8325728 },
    { lat: 49.1937325, lng: 18.8321924 },
    { lat: 49.1938407, lng: 18.8320587 },
    { lat: 49.1939533, lng: 18.8317712 },
    { lat: 49.1940783, lng: 18.8316564 },
    { lat: 49.1940754, lng: 18.8316232 },
    { lat: 49.1940859, lng: 18.8314564 },
    { lat: 49.1941723, lng: 18.831555 },
    { lat: 49.1945643, lng: 18.8320105 },
    { lat: 49.1947369, lng: 18.8316416 },
    { lat: 49.1948512, lng: 18.8312547 },
    { lat: 49.1949737, lng: 18.8309625 },
    { lat: 49.1951522, lng: 18.8304879 },
    { lat: 49.1953407, lng: 18.8300294 },
    { lat: 49.1954486, lng: 18.8297382 },
    { lat: 49.195641, lng: 18.8292951 },
    { lat: 49.1958029, lng: 18.8289097 },
    { lat: 49.1960644, lng: 18.8282301 },
    { lat: 49.1962394, lng: 18.8276464 },
    { lat: 49.1963703, lng: 18.8272106 },
    { lat: 49.1964762, lng: 18.8268125 },
    { lat: 49.1965642, lng: 18.8264209 },
    { lat: 49.1966037, lng: 18.8261371 },
    { lat: 49.1966366, lng: 18.8258995 },
    { lat: 49.1966375, lng: 18.8258944 },
    { lat: 49.196633, lng: 18.8257882 },
    { lat: 49.1966178, lng: 18.8253848 },
    { lat: 49.1966059, lng: 18.8250891 },
    { lat: 49.1965964, lng: 18.8248123 },
    { lat: 49.1965873, lng: 18.8245956 },
    { lat: 49.1965787, lng: 18.824539 },
    { lat: 49.1965616, lng: 18.8244217 },
    { lat: 49.1965289, lng: 18.8242057 },
    { lat: 49.1964979, lng: 18.8239978 },
    { lat: 49.1964713, lng: 18.8236556 },
    { lat: 49.1964488, lng: 18.8233529 },
    { lat: 49.1963936, lng: 18.8228505 },
    { lat: 49.1961791, lng: 18.8221224 },
    { lat: 49.1961513, lng: 18.8216055 },
    { lat: 49.1962432, lng: 18.8212032 },
    { lat: 49.1964625, lng: 18.8204479 },
    { lat: 49.1964858, lng: 18.820355 },
    { lat: 49.1966574, lng: 18.8193285 },
    { lat: 49.1968578, lng: 18.8187344 },
    { lat: 49.1968666, lng: 18.8187081 },
    { lat: 49.1970586, lng: 18.8181428 },
    { lat: 49.1970926, lng: 18.8180374 },
    { lat: 49.1971151, lng: 18.817951 },
    { lat: 49.1971327, lng: 18.8178985 },
    { lat: 49.197229, lng: 18.8177556 },
    { lat: 49.1978502, lng: 18.8166845 },
    { lat: 49.1982886, lng: 18.8160455 },
    { lat: 49.1985451, lng: 18.8157277 },
    { lat: 49.1987304, lng: 18.8154512 },
    { lat: 49.199357, lng: 18.8147053 },
    { lat: 49.1999053, lng: 18.8140747 },
    { lat: 49.2002949, lng: 18.8136078 },
    { lat: 49.2008402, lng: 18.8129866 },
    { lat: 49.2009994, lng: 18.8128157 },
    { lat: 49.2018254, lng: 18.8120424 },
    { lat: 49.2025701, lng: 18.8111819 },
    { lat: 49.2027771, lng: 18.8108884 },
    { lat: 49.2035131, lng: 18.8101161 },
    { lat: 49.2041052, lng: 18.8093345 },
    { lat: 49.2044016, lng: 18.8088741 },
    { lat: 49.2046273, lng: 18.8086843 },
    { lat: 49.2047455, lng: 18.8086124 },
    { lat: 49.2051965, lng: 18.8079808 },
    { lat: 49.2054665, lng: 18.8075527 },
    { lat: 49.206037, lng: 18.8064411 },
    { lat: 49.2064835, lng: 18.8053701 },
    { lat: 49.206794, lng: 18.8047265 },
    { lat: 49.2070387, lng: 18.8039386 },
    { lat: 49.20725, lng: 18.8025104 },
    { lat: 49.2074398, lng: 18.8005795 },
    { lat: 49.207429, lng: 18.7991697 },
    { lat: 49.2076574, lng: 18.7980505 },
    { lat: 49.2078228, lng: 18.797499 },
    { lat: 49.2081933, lng: 18.796225 },
    { lat: 49.2084119, lng: 18.7954813 },
    { lat: 49.2086012, lng: 18.7950676 },
    { lat: 49.2088706, lng: 18.7944811 },
    { lat: 49.2089264, lng: 18.7943575 },
    { lat: 49.2095869, lng: 18.7929828 },
    { lat: 49.2099095, lng: 18.7922457 },
    { lat: 49.2102581, lng: 18.7914448 },
    { lat: 49.210616, lng: 18.790518 },
    { lat: 49.2112393, lng: 18.7886853 },
    { lat: 49.2113929, lng: 18.7882527 },
    { lat: 49.2119421, lng: 18.7864956 },
    { lat: 49.2124575, lng: 18.7849752 },
    { lat: 49.2128797, lng: 18.7832882 },
    { lat: 49.2132293, lng: 18.7815071 },
    { lat: 49.2136845, lng: 18.7783496 },
    { lat: 49.213688, lng: 18.7782683 },
    { lat: 49.2136915, lng: 18.7781842 },
    { lat: 49.2136929, lng: 18.778166 },
    { lat: 49.2136984, lng: 18.7780536 },
    { lat: 49.2137022, lng: 18.7779536 },
    { lat: 49.2137143, lng: 18.7776859 },
    { lat: 49.213717, lng: 18.7776293 },
    { lat: 49.2137268, lng: 18.7774089 },
    { lat: 49.2137276, lng: 18.7773788 },
    { lat: 49.2137338, lng: 18.7772396 },
    { lat: 49.214094, lng: 18.7755985 },
    { lat: 49.2141983, lng: 18.7752822 },
    { lat: 49.2143744, lng: 18.7747462 },
    { lat: 49.2144973, lng: 18.7743746 },
    { lat: 49.2145285, lng: 18.7743016 },
    { lat: 49.2145676, lng: 18.7742096 },
    { lat: 49.2145988, lng: 18.7741365 },
    { lat: 49.2147537, lng: 18.7737744 },
    { lat: 49.2148462, lng: 18.7735591 },
    { lat: 49.2148503, lng: 18.7735514 },
    { lat: 49.2149899, lng: 18.7733325 },
    { lat: 49.2150139, lng: 18.7732949 },
    { lat: 49.2151442, lng: 18.7730909 },
    { lat: 49.2151734, lng: 18.7730444 },
    { lat: 49.2151903, lng: 18.773024 },
    { lat: 49.2152747, lng: 18.7729005 },
    { lat: 49.2155039, lng: 18.7726351 },
    { lat: 49.2158703, lng: 18.7722011 },
    { lat: 49.2163566, lng: 18.7718147 },
    { lat: 49.2164912, lng: 18.7717144 },
    { lat: 49.2169073, lng: 18.7715413 },
    { lat: 49.2169558, lng: 18.7715185 },
    { lat: 49.2172391, lng: 18.7713111 },
    { lat: 49.2172795, lng: 18.7712824 },
    { lat: 49.2174756, lng: 18.7711515 },
    { lat: 49.2178908, lng: 18.7709154 },
    { lat: 49.2181638, lng: 18.7708766 },
    { lat: 49.2196501, lng: 18.7704862 },
    { lat: 49.2197953, lng: 18.7705068 },
    { lat: 49.2202656, lng: 18.7705736 },
    { lat: 49.2204957, lng: 18.7707499 },
    { lat: 49.2206158, lng: 18.7708468 },
    { lat: 49.2207377, lng: 18.7709063 },
    { lat: 49.221052, lng: 18.7710598 },
    { lat: 49.2214195, lng: 18.7711888 },
    { lat: 49.2219131, lng: 18.7713342 },
    { lat: 49.2221391, lng: 18.7714735 },
    { lat: 49.2226591, lng: 18.771671 },
    { lat: 49.2228697, lng: 18.7717555 },
    { lat: 49.2230479, lng: 18.771797 },
    { lat: 49.2232168, lng: 18.7718368 },
    { lat: 49.2234257, lng: 18.7718866 },
    { lat: 49.2236723, lng: 18.7719458 },
    { lat: 49.2239487, lng: 18.7720119 },
    { lat: 49.2243605, lng: 18.7720377 },
    { lat: 49.2248579, lng: 18.7720626 },
    { lat: 49.2256444, lng: 18.7719648 },
    { lat: 49.2260585, lng: 18.7716033 },
    { lat: 49.2263043, lng: 18.7709729 },
    { lat: 49.2264374, lng: 18.7687607 },
    { lat: 49.2264357, lng: 18.7684467 },
    { lat: 49.226426, lng: 18.7660708 },
    { lat: 49.2265376, lng: 18.7660504 },
    { lat: 49.2266919, lng: 18.7660216 },
    { lat: 49.2268962, lng: 18.7659845 },
    { lat: 49.2272846, lng: 18.7659749 },
    { lat: 49.2274725, lng: 18.7660755 },
    { lat: 49.2276767, lng: 18.766185 },
    { lat: 49.2280519, lng: 18.7662676 },
    { lat: 49.2281827, lng: 18.7663964 },
    { lat: 49.2285007, lng: 18.7667854 },
    { lat: 49.2288295, lng: 18.766844 },
    { lat: 49.2293348, lng: 18.7670179 },
    { lat: 49.2295823, lng: 18.7671157 },
    { lat: 49.2302545, lng: 18.767487 },
    { lat: 49.2306845, lng: 18.7675827 },
    { lat: 49.2309774, lng: 18.7676623 },
    { lat: 49.2312604, lng: 18.7677705 },
    { lat: 49.2316036, lng: 18.7678639 },
    { lat: 49.231895, lng: 18.7679609 },
    { lat: 49.2319607, lng: 18.7679873 },
    { lat: 49.2319214, lng: 18.7689904 },
    { lat: 49.2321047, lng: 18.7703897 },
    { lat: 49.2322535, lng: 18.7715162 },
    { lat: 49.2323866, lng: 18.7722351 },
    { lat: 49.2324603, lng: 18.7730895 },
    { lat: 49.2326146, lng: 18.7742594 },
    { lat: 49.2327234, lng: 18.7748971 },
    { lat: 49.2327148, lng: 18.7753185 },
    { lat: 49.2328153, lng: 18.7758446 },
    { lat: 49.2329618, lng: 18.7760116 },
    { lat: 49.2329932, lng: 18.7763582 },
    { lat: 49.2330775, lng: 18.7766803 },
    { lat: 49.2331965, lng: 18.7771245 },
    { lat: 49.2333471, lng: 18.777462 },
    { lat: 49.2336664, lng: 18.7788342 },
    { lat: 49.2337862, lng: 18.7795586 },
    { lat: 49.2338011, lng: 18.7797776 },
    { lat: 49.2337804, lng: 18.7807713 },
    { lat: 49.2338993, lng: 18.7815421 },
    { lat: 49.2339769, lng: 18.7825834 },
    { lat: 49.2340682, lng: 18.7835141 },
    { lat: 49.2341926, lng: 18.7848918 },
    { lat: 49.2341424, lng: 18.785359 },
    { lat: 49.2341504, lng: 18.7862738 },
    { lat: 49.23426, lng: 18.7873859 },
    { lat: 49.2339424, lng: 18.7874171 },
    { lat: 49.2338532, lng: 18.7880041 },
    { lat: 49.2339318, lng: 18.7889955 },
    { lat: 49.2341209, lng: 18.7897307 },
    { lat: 49.2342259, lng: 18.7900796 },
    { lat: 49.2342298, lng: 18.7903107 },
    { lat: 49.234161, lng: 18.7905929 },
    { lat: 49.2339841, lng: 18.7912464 },
    { lat: 49.2339981, lng: 18.791734 },
    { lat: 49.2341936, lng: 18.7922213 },
    { lat: 49.234401, lng: 18.7929179 },
    { lat: 49.2344173, lng: 18.7932096 },
    { lat: 49.2342795, lng: 18.7941445 },
    { lat: 49.2341118, lng: 18.7947576 },
    { lat: 49.2337915, lng: 18.7967815 },
    { lat: 49.2338791, lng: 18.79756 },
    { lat: 49.2338738, lng: 18.797806 },
    { lat: 49.2344086, lng: 18.798186 },
    { lat: 49.2346673, lng: 18.7982818 },
    { lat: 49.2355614, lng: 18.7977792 },
    { lat: 49.2359725, lng: 18.7975972 },
    { lat: 49.2366077, lng: 18.7974855 },
    { lat: 49.2371591, lng: 18.7973876 },
    { lat: 49.2375935, lng: 18.7973716 },
    { lat: 49.2378707, lng: 18.7973681 },
    { lat: 49.2382544, lng: 18.7972847 },
    { lat: 49.2388491, lng: 18.798145 },
    { lat: 49.2397201, lng: 18.7991379 },
    { lat: 49.2408353, lng: 18.7992921 },
    { lat: 49.2414792, lng: 18.7987684 },
    { lat: 49.2416458, lng: 18.7987003 },
    { lat: 49.2418171, lng: 18.798545 },
    { lat: 49.2421997, lng: 18.7983993 },
    { lat: 49.2425002, lng: 18.7981934 },
    { lat: 49.2427542, lng: 18.7981111 },
    { lat: 49.2433067, lng: 18.7981535 },
    { lat: 49.2437798, lng: 18.7982563 },
    { lat: 49.2439643, lng: 18.7982863 },
    { lat: 49.2442837, lng: 18.7982492 },
    { lat: 49.2445418, lng: 18.798073 },
    { lat: 49.2450071, lng: 18.7975675 },
    { lat: 49.2453324, lng: 18.7972518 },
    { lat: 49.2457948, lng: 18.7968175 },
    { lat: 49.2461646, lng: 18.7969919 },
    { lat: 49.2463858, lng: 18.7972348 },
    { lat: 49.2465209, lng: 18.7972932 },
    { lat: 49.2466797, lng: 18.797208 },
    { lat: 49.2470462, lng: 18.7969436 },
    { lat: 49.2474106, lng: 18.7966661 },
    { lat: 49.2477012, lng: 18.7963964 },
    { lat: 49.2478634, lng: 18.7961978 },
    { lat: 49.24812, lng: 18.795706 },
    { lat: 49.248271, lng: 18.79543 },
    { lat: 49.248289, lng: 18.795365 },
    { lat: 49.248359, lng: 18.795102 },
    { lat: 49.248459, lng: 18.794897 },
    { lat: 49.24862, lng: 18.794742 },
    { lat: 49.249069, lng: 18.794439 },
    { lat: 49.24944, lng: 18.794188 },
    { lat: 49.249607, lng: 18.793924 },
    { lat: 49.249892, lng: 18.793855 },
    { lat: 49.250195, lng: 18.793669 },
    { lat: 49.250654, lng: 18.79388 },
    { lat: 49.250916, lng: 18.794066 },
    { lat: 49.251818, lng: 18.794555 },
    { lat: 49.252282, lng: 18.792778 },
    { lat: 49.252606, lng: 18.791651 },
    { lat: 49.252836, lng: 18.7909 },
    { lat: 49.253263, lng: 18.789508 },
    { lat: 49.253365, lng: 18.789002 },
    { lat: 49.253641, lng: 18.78782 },
    { lat: 49.253767, lng: 18.787339 },
    { lat: 49.254016, lng: 18.786995 },
    { lat: 49.254223, lng: 18.786006 },
    { lat: 49.254553, lng: 18.78577 },
    { lat: 49.254807, lng: 18.785587 },
    { lat: 49.254976, lng: 18.785203 },
    { lat: 49.255031, lng: 18.784843 },
    { lat: 49.255173, lng: 18.784735 },
    { lat: 49.255187, lng: 18.78473 },
    { lat: 49.255384, lng: 18.784847 },
    { lat: 49.255623, lng: 18.784898 },
    { lat: 49.255727, lng: 18.784884 },
    { lat: 49.255848, lng: 18.784826 },
    { lat: 49.256022, lng: 18.784721 },
    { lat: 49.256188, lng: 18.784572 },
    { lat: 49.2564, lng: 18.78431 },
    { lat: 49.256533, lng: 18.784108 },
    { lat: 49.256633, lng: 18.783882 },
    { lat: 49.256748, lng: 18.783462 },
    { lat: 49.25691, lng: 18.783152 },
    { lat: 49.257161, lng: 18.782857 },
    { lat: 49.257574, lng: 18.782474 },
    { lat: 49.257772, lng: 18.782348 },
    { lat: 49.258007, lng: 18.782313 },
    { lat: 49.258182, lng: 18.782231 },
    { lat: 49.258575, lng: 18.78178 },
    { lat: 49.258887, lng: 18.781376 },
    { lat: 49.259074, lng: 18.781054 },
    { lat: 49.259301, lng: 18.780583 },
    { lat: 49.25944, lng: 18.780164 },
    { lat: 49.259571, lng: 18.779731 },
    { lat: 49.259627, lng: 18.779477 },
    { lat: 49.259707, lng: 18.779177 },
    { lat: 49.259809, lng: 18.778881 },
    { lat: 49.259975, lng: 18.778473 },
    { lat: 49.260154, lng: 18.777868 },
    { lat: 49.260307, lng: 18.777532 },
    { lat: 49.260549, lng: 18.777121 },
    { lat: 49.26069, lng: 18.776907 },
    { lat: 49.260836, lng: 18.776551 },
    { lat: 49.262013, lng: 18.775117 },
    { lat: 49.262439, lng: 18.774732 },
    { lat: 49.262487, lng: 18.774683 },
    { lat: 49.262791, lng: 18.774375 },
    { lat: 49.263357, lng: 18.773909 },
    { lat: 49.263746, lng: 18.773534 },
    { lat: 49.264236, lng: 18.773164 },
    { lat: 49.264435, lng: 18.773035 },
    { lat: 49.264926, lng: 18.772608 },
    { lat: 49.265142, lng: 18.772385 },
    { lat: 49.265374, lng: 18.772359 },
    { lat: 49.265551, lng: 18.772941 },
    { lat: 49.265804, lng: 18.773565 },
    { lat: 49.265924, lng: 18.773792 },
    { lat: 49.266067, lng: 18.774039 },
    { lat: 49.266115, lng: 18.774117 },
    { lat: 49.266517, lng: 18.77467 },
    { lat: 49.266749, lng: 18.774969 },
    { lat: 49.266952, lng: 18.775428 },
    { lat: 49.26717, lng: 18.775979 },
    { lat: 49.267492, lng: 18.776386 },
    { lat: 49.2676, lng: 18.776542 },
    { lat: 49.267796, lng: 18.77691 },
    { lat: 49.268008, lng: 18.777214 },
    { lat: 49.268195, lng: 18.77759 },
    { lat: 49.268355, lng: 18.778056 },
    { lat: 49.268479, lng: 18.778372 },
    { lat: 49.268584, lng: 18.778815 },
    { lat: 49.268879, lng: 18.77943 },
    { lat: 49.26893, lng: 18.7795 },
    { lat: 49.269201, lng: 18.779197 },
    { lat: 49.269208, lng: 18.779189 },
    { lat: 49.269242, lng: 18.779147 },
    { lat: 49.269374, lng: 18.778957 },
    { lat: 49.269493, lng: 18.778737 },
    { lat: 49.269572, lng: 18.778481 },
    { lat: 49.269697, lng: 18.77765 },
    { lat: 49.269763, lng: 18.777145 },
    { lat: 49.269766, lng: 18.777133 },
    { lat: 49.269892, lng: 18.776168 },
    { lat: 49.26997, lng: 18.775441 },
    { lat: 49.269976, lng: 18.775381 },
    { lat: 49.269979, lng: 18.77537 },
    { lat: 49.269986, lng: 18.775294 },
    { lat: 49.270062, lng: 18.774249 },
    { lat: 49.270129, lng: 18.772874 },
    { lat: 49.270182, lng: 18.772888 },
    { lat: 49.270242, lng: 18.772895 },
    { lat: 49.270261, lng: 18.772899 },
    { lat: 49.270308, lng: 18.772912 },
    { lat: 49.270755, lng: 18.772919 },
    { lat: 49.271454, lng: 18.773204 },
    { lat: 49.271867, lng: 18.773464 },
    { lat: 49.2723, lng: 18.773538 },
    { lat: 49.272834, lng: 18.773894 },
    { lat: 49.273153, lng: 18.774051 },
    { lat: 49.273073, lng: 18.773508 },
    { lat: 49.272964, lng: 18.772845 },
    { lat: 49.272889, lng: 18.772002 },
    { lat: 49.272883, lng: 18.771623 },
    { lat: 49.273168, lng: 18.770045 },
    { lat: 49.273461, lng: 18.768717 },
    { lat: 49.273643, lng: 18.767641 },
    { lat: 49.2737, lng: 18.767154 },
    { lat: 49.274001, lng: 18.766053 },
    { lat: 49.274047, lng: 18.765883 },
    { lat: 49.274043, lng: 18.765869 },
    { lat: 49.27401, lng: 18.765799 },
    { lat: 49.273992, lng: 18.765761 },
    { lat: 49.273975, lng: 18.765709 },
    { lat: 49.274237, lng: 18.765463 },
    { lat: 49.275144, lng: 18.764175 },
    { lat: 49.275156, lng: 18.764163 },
    { lat: 49.275161, lng: 18.764158 },
    { lat: 49.275198, lng: 18.764115 },
    { lat: 49.275205, lng: 18.7641 },
    { lat: 49.275286, lng: 18.764006 },
    { lat: 49.275636, lng: 18.763773 },
    { lat: 49.275783, lng: 18.76368 },
    { lat: 49.27608, lng: 18.763491 },
    { lat: 49.276725, lng: 18.763081 },
    { lat: 49.276791, lng: 18.763018 },
    { lat: 49.276871, lng: 18.762918 },
    { lat: 49.27694, lng: 18.762818 },
    { lat: 49.277049, lng: 18.762658 },
    { lat: 49.277058, lng: 18.762646 },
    { lat: 49.277097, lng: 18.762554 },
    { lat: 49.277135, lng: 18.76235 },
    { lat: 49.277154, lng: 18.762247 },
    { lat: 49.2772, lng: 18.762003 },
    { lat: 49.277241, lng: 18.761784 },
    { lat: 49.277294, lng: 18.76151 },
    { lat: 49.277306, lng: 18.761457 },
    { lat: 49.27731, lng: 18.761425 },
    { lat: 49.277547, lng: 18.760733 },
    { lat: 49.27757, lng: 18.76069 },
    { lat: 49.277802, lng: 18.760293 },
    { lat: 49.277821, lng: 18.760261 },
    { lat: 49.27799, lng: 18.759971 },
    { lat: 49.277634, lng: 18.759373 },
    { lat: 49.277632, lng: 18.759369 },
    { lat: 49.277144, lng: 18.758582 },
    { lat: 49.276438, lng: 18.758076 },
    { lat: 49.276229, lng: 18.757685 },
    { lat: 49.275892, lng: 18.757252 },
    { lat: 49.275762, lng: 18.757085 },
    { lat: 49.275226, lng: 18.756596 },
    { lat: 49.275097, lng: 18.756516 },
    { lat: 49.275014, lng: 18.756464 },
    { lat: 49.274699, lng: 18.756261 },
    { lat: 49.274454, lng: 18.756171 },
    { lat: 49.274164, lng: 18.756065 },
    { lat: 49.27402, lng: 18.755908 },
    { lat: 49.273831, lng: 18.755702 },
    { lat: 49.27326, lng: 18.755404 },
    { lat: 49.273078, lng: 18.755309 },
    { lat: 49.273052, lng: 18.755296 },
    { lat: 49.272334, lng: 18.755012 },
    { lat: 49.272238, lng: 18.754974 },
    { lat: 49.271724, lng: 18.754799 },
    { lat: 49.271496, lng: 18.754652 },
    { lat: 49.271116, lng: 18.754479 },
    { lat: 49.271112, lng: 18.754477 },
    { lat: 49.27111, lng: 18.754476 },
    { lat: 49.271066, lng: 18.754509 },
    { lat: 49.27054, lng: 18.754892 },
    { lat: 49.270538, lng: 18.754893 },
    { lat: 49.270393, lng: 18.754674 },
    { lat: 49.268328, lng: 18.751564 },
    { lat: 49.268153, lng: 18.75136 },
    { lat: 49.268065, lng: 18.751323 },
    { lat: 49.267483, lng: 18.751081 },
    { lat: 49.267472, lng: 18.751076 },
    { lat: 49.266713, lng: 18.75075 },
    { lat: 49.266123, lng: 18.750496 },
    { lat: 49.2658152, lng: 18.7502611 },
    { lat: 49.265828, lng: 18.750369 },
    { lat: 49.265827, lng: 18.750361 },
    { lat: 49.265765, lng: 18.750102 },
    { lat: 49.26573, lng: 18.749955 },
    { lat: 49.265708, lng: 18.749866 },
    { lat: 49.265801, lng: 18.749371 },
    { lat: 49.265935, lng: 18.748662 },
    { lat: 49.266008, lng: 18.748274 },
    { lat: 49.266422, lng: 18.741444 },
    { lat: 49.266537, lng: 18.739837 },
    { lat: 49.265868, lng: 18.736956 },
    { lat: 49.265945, lng: 18.731704 },
    { lat: 49.265659, lng: 18.73013 },
    { lat: 49.265717, lng: 18.729085 },
    { lat: 49.267415, lng: 18.729193 },
    { lat: 49.267808, lng: 18.729315 },
    { lat: 49.267983, lng: 18.729177 },
    { lat: 49.268, lng: 18.729163 },
    { lat: 49.268258, lng: 18.728958 },
    { lat: 49.269104, lng: 18.730017 },
    { lat: 49.269451, lng: 18.729954 },
    { lat: 49.269489, lng: 18.729948 },
    { lat: 49.269335, lng: 18.7294 },
    { lat: 49.269512, lng: 18.72905 },
    { lat: 49.269778, lng: 18.728686 },
    { lat: 49.270324, lng: 18.728473 },
    { lat: 49.271435, lng: 18.727804 },
    { lat: 49.271452, lng: 18.727239 },
    { lat: 49.27137, lng: 18.726461 },
    { lat: 49.271592, lng: 18.726514 },
    { lat: 49.271635, lng: 18.726525 },
    { lat: 49.271676, lng: 18.726535 },
    { lat: 49.271734, lng: 18.726483 },
    { lat: 49.272087, lng: 18.726166 },
    { lat: 49.272024, lng: 18.722423 },
    { lat: 49.2717117, lng: 18.7224925 },
    { lat: 49.2714036, lng: 18.7225967 },
    { lat: 49.2713504, lng: 18.7226147 },
    { lat: 49.2712274, lng: 18.7226561 },
    { lat: 49.2710523, lng: 18.7227154 },
    { lat: 49.2707824, lng: 18.722773 },
    { lat: 49.270483, lng: 18.7228677 },
    { lat: 49.2705223, lng: 18.7236855 },
    { lat: 49.2694132, lng: 18.7247253 },
    { lat: 49.2690821, lng: 18.7249087 },
    { lat: 49.2687385, lng: 18.7250432 },
    { lat: 49.2673549, lng: 18.7257968 },
    { lat: 49.2673143, lng: 18.7258191 },
    { lat: 49.2672814, lng: 18.7258372 },
    { lat: 49.2672681, lng: 18.7258403 },
    { lat: 49.2672324, lng: 18.7258476 },
    { lat: 49.2671933, lng: 18.7258559 },
    { lat: 49.2668621, lng: 18.7259253 },
    { lat: 49.2664307, lng: 18.7261055 },
    { lat: 49.2661544, lng: 18.7256131 },
    { lat: 49.2656995, lng: 18.7256896 },
    { lat: 49.264884, lng: 18.7254033 },
    { lat: 49.2646317, lng: 18.725324 },
    { lat: 49.2645734, lng: 18.7253055 },
    { lat: 49.264578, lng: 18.7252894 },
    { lat: 49.2633348, lng: 18.7246521 },
    { lat: 49.2629138, lng: 18.7244287 },
    { lat: 49.2623391, lng: 18.7241794 },
    { lat: 49.2621324, lng: 18.72413 },
    { lat: 49.2619284, lng: 18.7241747 },
    { lat: 49.261656, lng: 18.7243102 },
    { lat: 49.2613109, lng: 18.7246102 },
    { lat: 49.260888, lng: 18.7249994 },
    { lat: 49.2603548, lng: 18.7255624 },
    { lat: 49.2592479, lng: 18.7264991 },
    { lat: 49.2592351, lng: 18.7265115 },
    { lat: 49.2592234, lng: 18.726523 },
    { lat: 49.2592093, lng: 18.7265165 },
    { lat: 49.2591817, lng: 18.7265022 },
    { lat: 49.2578013, lng: 18.7248834 },
    { lat: 49.257192, lng: 18.7242972 },
    { lat: 49.2568105, lng: 18.7234283 },
    { lat: 49.2564017, lng: 18.7229187 },
    { lat: 49.2558072, lng: 18.7219257 },
    { lat: 49.2553861, lng: 18.7212259 },
    { lat: 49.2551316, lng: 18.7207173 },
    { lat: 49.2549711, lng: 18.7203005 },
    { lat: 49.2549461, lng: 18.7202346 },
    { lat: 49.2548588, lng: 18.720007 },
    { lat: 49.2548547, lng: 18.7199967 },
    { lat: 49.2548439, lng: 18.7199396 },
    { lat: 49.2547455, lng: 18.719418 },
    { lat: 49.2547017, lng: 18.719183 },
    { lat: 49.2546406, lng: 18.718945 },
    { lat: 49.2544677, lng: 18.7185907 },
    { lat: 49.2541658, lng: 18.7180605 },
    { lat: 49.2536961, lng: 18.7172942 },
    { lat: 49.2531343, lng: 18.7166882 },
    { lat: 49.2529122, lng: 18.7163227 },
    { lat: 49.2526754, lng: 18.7159585 },
    { lat: 49.2524042, lng: 18.7155632 },
    { lat: 49.2519375, lng: 18.7146164 },
    { lat: 49.2516495, lng: 18.7141351 },
    { lat: 49.251177, lng: 18.7133172 },
    { lat: 49.2507504, lng: 18.7126656 },
    { lat: 49.2504433, lng: 18.7120155 },
    { lat: 49.2505756, lng: 18.7113121 },
    { lat: 49.2506564, lng: 18.7107889 },
    { lat: 49.2507267, lng: 18.7104316 },
    { lat: 49.2507149, lng: 18.7073294 },
    { lat: 49.2504984, lng: 18.7071996 },
    { lat: 49.250257, lng: 18.7068549 },
    { lat: 49.2500221, lng: 18.7065252 },
    { lat: 49.2495627, lng: 18.7058311 },
    { lat: 49.2494194, lng: 18.7055913 },
    { lat: 49.2493446, lng: 18.7054994 },
    { lat: 49.249182, lng: 18.7053009 },
    { lat: 49.2491396, lng: 18.7052267 },
    { lat: 49.2490862, lng: 18.7051329 },
    { lat: 49.2490206, lng: 18.7050385 },
    { lat: 49.2489513, lng: 18.7049444 },
    { lat: 49.2488604, lng: 18.7047823 },
    { lat: 49.2487871, lng: 18.7046343 },
    { lat: 49.2487354, lng: 18.7045269 },
    { lat: 49.2484671, lng: 18.7038 },
    { lat: 49.2484776, lng: 18.7037853 },
    { lat: 49.2481623, lng: 18.7028924 },
    { lat: 49.2481219, lng: 18.7027447 },
    { lat: 49.2479516, lng: 18.7019271 },
    { lat: 49.247685, lng: 18.7016843 },
    { lat: 49.2474243, lng: 18.70149 },
    { lat: 49.2471985, lng: 18.7013123 },
    { lat: 49.2470568, lng: 18.7007381 },
  ],
  LietavskáLúčka: [
    { lat: 49.1784117, lng: 18.727174 },
    { lat: 49.178415, lng: 18.7271732 },
    { lat: 49.1784217, lng: 18.7271706 },
    { lat: 49.1784261, lng: 18.727169 },
    { lat: 49.1784588, lng: 18.7271484 },
    { lat: 49.1786128, lng: 18.7270514 },
    { lat: 49.178865, lng: 18.7268318 },
    { lat: 49.1791461, lng: 18.7263434 },
    { lat: 49.1793016, lng: 18.7260386 },
    { lat: 49.1794656, lng: 18.725767 },
    { lat: 49.1795796, lng: 18.7256219 },
    { lat: 49.1797152, lng: 18.7254986 },
    { lat: 49.1798384, lng: 18.7253971 },
    { lat: 49.179945, lng: 18.7253607 },
    { lat: 49.1799559, lng: 18.7253568 },
    { lat: 49.1805375, lng: 18.7251558 },
    { lat: 49.1807225, lng: 18.7250734 },
    { lat: 49.1808441, lng: 18.7249818 },
    { lat: 49.1808538, lng: 18.7249744 },
    { lat: 49.1809572, lng: 18.7249031 },
    { lat: 49.1811468, lng: 18.724735 },
    { lat: 49.1811801, lng: 18.7246809 },
    { lat: 49.1812154, lng: 18.7246449 },
    { lat: 49.1817137, lng: 18.7241897 },
    { lat: 49.1819044, lng: 18.7241077 },
    { lat: 49.182164, lng: 18.7240259 },
    { lat: 49.1823782, lng: 18.7240009 },
    { lat: 49.1824754, lng: 18.7240077 },
    { lat: 49.18288, lng: 18.7240258 },
    { lat: 49.183052, lng: 18.7240102 },
    { lat: 49.18309, lng: 18.7240067 },
    { lat: 49.1832419, lng: 18.7239809 },
    { lat: 49.1832699, lng: 18.7239761 },
    { lat: 49.1835222, lng: 18.7237769 },
    { lat: 49.1837493, lng: 18.7235622 },
    { lat: 49.1837394, lng: 18.7235121 },
    { lat: 49.1837717, lng: 18.7234603 },
    { lat: 49.18386, lng: 18.7233377 },
    { lat: 49.1839238, lng: 18.7232766 },
    { lat: 49.1841529, lng: 18.7229468 },
    { lat: 49.1845314, lng: 18.7224018 },
    { lat: 49.1845621, lng: 18.722389 },
    { lat: 49.1847574, lng: 18.722255 },
    { lat: 49.1849491, lng: 18.7221306 },
    { lat: 49.1849086, lng: 18.7220029 },
    { lat: 49.1849032, lng: 18.7219862 },
    { lat: 49.1848963, lng: 18.7219611 },
    { lat: 49.1848827, lng: 18.7219181 },
    { lat: 49.1848719, lng: 18.7218841 },
    { lat: 49.1848115, lng: 18.7216784 },
    { lat: 49.1847721, lng: 18.7215715 },
    { lat: 49.1847601, lng: 18.7215365 },
    { lat: 49.1847548, lng: 18.7215217 },
    { lat: 49.1844793, lng: 18.720914 },
    { lat: 49.1844671, lng: 18.7208758 },
    { lat: 49.184268, lng: 18.7202142 },
    { lat: 49.1840742, lng: 18.7195125 },
    { lat: 49.184001, lng: 18.7192558 },
    { lat: 49.1846325, lng: 18.718957 },
    { lat: 49.1847682, lng: 18.7187057 },
    { lat: 49.1846761, lng: 18.718107 },
    { lat: 49.1844136, lng: 18.7174917 },
    { lat: 49.1843224, lng: 18.7172991 },
    { lat: 49.1842805, lng: 18.7172109 },
    { lat: 49.1838437, lng: 18.716106 },
    { lat: 49.1837957, lng: 18.7154533 },
    { lat: 49.1837368, lng: 18.7142233 },
    { lat: 49.183792, lng: 18.7132999 },
    { lat: 49.1835416, lng: 18.7131484 },
    { lat: 49.1827462, lng: 18.7130085 },
    { lat: 49.1826613, lng: 18.7128105 },
    { lat: 49.1827224, lng: 18.712192 },
    { lat: 49.1825574, lng: 18.7118211 },
    { lat: 49.1818428, lng: 18.711012 },
    { lat: 49.1813937, lng: 18.7106008 },
    { lat: 49.1814189, lng: 18.7105404 },
    { lat: 49.1811242, lng: 18.710278 },
    { lat: 49.1811325, lng: 18.7099648 },
    { lat: 49.180812, lng: 18.7094871 },
    { lat: 49.1804464, lng: 18.7089017 },
    { lat: 49.1802617, lng: 18.7085429 },
    { lat: 49.1800322, lng: 18.708124 },
    { lat: 49.1798952, lng: 18.7079869 },
    { lat: 49.1797121, lng: 18.707762 },
    { lat: 49.1795773, lng: 18.7074948 },
    { lat: 49.1794013, lng: 18.7069126 },
    { lat: 49.1789348, lng: 18.7063403 },
    { lat: 49.1787014, lng: 18.7060005 },
    { lat: 49.1785205, lng: 18.7056705 },
    { lat: 49.1781115, lng: 18.7049254 },
    { lat: 49.1778993, lng: 18.7042341 },
    { lat: 49.1777218, lng: 18.7036889 },
    { lat: 49.1775436, lng: 18.7033023 },
    { lat: 49.1772224, lng: 18.7034991 },
    { lat: 49.1767548, lng: 18.7039582 },
    { lat: 49.1757986, lng: 18.701881 },
    { lat: 49.1755542, lng: 18.7015894 },
    { lat: 49.1750627, lng: 18.701118 },
    { lat: 49.1745171, lng: 18.7008446 },
    { lat: 49.1737356, lng: 18.7005878 },
    { lat: 49.1735271, lng: 18.7005686 },
    { lat: 49.1731458, lng: 18.7005879 },
    { lat: 49.1725212, lng: 18.7008471 },
    { lat: 49.1721893, lng: 18.701073 },
    { lat: 49.1716866, lng: 18.7012704 },
    { lat: 49.1715483, lng: 18.7011742 },
    { lat: 49.1712339, lng: 18.7012614 },
    { lat: 49.1708584, lng: 18.7017113 },
    { lat: 49.1702837, lng: 18.7020206 },
    { lat: 49.169717, lng: 18.7022035 },
    { lat: 49.168884, lng: 18.7030659 },
    { lat: 49.1686155, lng: 18.7032966 },
    { lat: 49.1683246, lng: 18.7035348 },
    { lat: 49.1675955, lng: 18.7041429 },
    { lat: 49.1674762, lng: 18.7042346 },
    { lat: 49.1672626, lng: 18.7043959 },
    { lat: 49.1669357, lng: 18.7047231 },
    { lat: 49.1665958, lng: 18.7050581 },
    { lat: 49.1657149, lng: 18.705386 },
    { lat: 49.1636115, lng: 18.7025617 },
    { lat: 49.162786, lng: 18.7009119 },
    { lat: 49.162042, lng: 18.7005742 },
    { lat: 49.1615154, lng: 18.6998665 },
    { lat: 49.1610167, lng: 18.6994797 },
    { lat: 49.1605212, lng: 18.6992561 },
    { lat: 49.1603292, lng: 18.699209 },
    { lat: 49.1600436, lng: 18.6991564 },
    { lat: 49.1597049, lng: 18.6990426 },
    { lat: 49.1587408, lng: 18.6989408 },
    { lat: 49.158899, lng: 18.6995037 },
    { lat: 49.1589047, lng: 18.6995221 },
    { lat: 49.1589102, lng: 18.6995413 },
    { lat: 49.1590287, lng: 18.6999516 },
    { lat: 49.1592277, lng: 18.7006871 },
    { lat: 49.1593699, lng: 18.7012926 },
    { lat: 49.1603441, lng: 18.704676 },
    { lat: 49.1606106, lng: 18.7056898 },
    { lat: 49.1606488, lng: 18.7062223 },
    { lat: 49.1605957, lng: 18.7069466 },
    { lat: 49.1605455, lng: 18.7074514 },
    { lat: 49.1604853, lng: 18.7080872 },
    { lat: 49.1605207, lng: 18.7089699 },
    { lat: 49.1606122, lng: 18.7097482 },
    { lat: 49.1601885, lng: 18.7120681 },
    { lat: 49.1601769, lng: 18.7121261 },
    { lat: 49.1601652, lng: 18.7121874 },
    { lat: 49.1601504, lng: 18.7122699 },
    { lat: 49.1599467, lng: 18.7133927 },
    { lat: 49.1598519, lng: 18.7161801 },
    { lat: 49.1597576, lng: 18.7163151 },
    { lat: 49.1594044, lng: 18.7173192 },
    { lat: 49.159588, lng: 18.7176621 },
    { lat: 49.1581702, lng: 18.7182773 },
    { lat: 49.1578124, lng: 18.7183981 },
    { lat: 49.1569203, lng: 18.7192124 },
    { lat: 49.1571063, lng: 18.7196851 },
    { lat: 49.1560675, lng: 18.7208592 },
    { lat: 49.1557214, lng: 18.721595 },
    { lat: 49.155753, lng: 18.7216797 },
    { lat: 49.1557686, lng: 18.7217196 },
    { lat: 49.1557791, lng: 18.7217493 },
    { lat: 49.1557896, lng: 18.7217776 },
    { lat: 49.1558134, lng: 18.7218439 },
    { lat: 49.1558183, lng: 18.7218526 },
    { lat: 49.1558769, lng: 18.721955 },
    { lat: 49.1559326, lng: 18.72186 },
    { lat: 49.1558655, lng: 18.7217604 },
    { lat: 49.1566183, lng: 18.7213227 },
    { lat: 49.1570803, lng: 18.7210585 },
    { lat: 49.1574454, lng: 18.7208384 },
    { lat: 49.1583553, lng: 18.7203398 },
    { lat: 49.1583319, lng: 18.7233979 },
    { lat: 49.157605, lng: 18.7229551 },
    { lat: 49.1574854, lng: 18.7229095 },
    { lat: 49.1575001, lng: 18.7229748 },
    { lat: 49.157534, lng: 18.7231288 },
    { lat: 49.1577877, lng: 18.723924 },
    { lat: 49.1579665, lng: 18.724301 },
    { lat: 49.1584271, lng: 18.7250638 },
    { lat: 49.1586497, lng: 18.7257396 },
    { lat: 49.1588214, lng: 18.7264324 },
    { lat: 49.1589199, lng: 18.7265036 },
    { lat: 49.1598592, lng: 18.7261556 },
    { lat: 49.1604401, lng: 18.7259461 },
    { lat: 49.1606733, lng: 18.7258932 },
    { lat: 49.1616298, lng: 18.7257546 },
    { lat: 49.1616584, lng: 18.7259302 },
    { lat: 49.161656, lng: 18.7262104 },
    { lat: 49.1615515, lng: 18.7266451 },
    { lat: 49.1614349, lng: 18.7267025 },
    { lat: 49.161303, lng: 18.7267874 },
    { lat: 49.1612876, lng: 18.7268635 },
    { lat: 49.1612232, lng: 18.7269875 },
    { lat: 49.1612151, lng: 18.7270032 },
    { lat: 49.160983, lng: 18.7272912 },
    { lat: 49.1609869, lng: 18.7274922 },
    { lat: 49.1609961, lng: 18.7276445 },
    { lat: 49.1610297, lng: 18.7278343 },
    { lat: 49.1609799, lng: 18.7280032 },
    { lat: 49.1610837, lng: 18.7283062 },
    { lat: 49.1610581, lng: 18.7284621 },
    { lat: 49.1609517, lng: 18.7286725 },
    { lat: 49.1609741, lng: 18.7288373 },
    { lat: 49.1611146, lng: 18.7292598 },
    { lat: 49.1611409, lng: 18.7295275 },
    { lat: 49.1611189, lng: 18.7297064 },
    { lat: 49.1610264, lng: 18.7298366 },
    { lat: 49.1610952, lng: 18.7301831 },
    { lat: 49.1610935, lng: 18.7302543 },
    { lat: 49.1610094, lng: 18.7303103 },
    { lat: 49.1609762, lng: 18.7304066 },
    { lat: 49.1609256, lng: 18.7305414 },
    { lat: 49.1609595, lng: 18.7308022 },
    { lat: 49.1609826, lng: 18.7309805 },
    { lat: 49.1609243, lng: 18.7314412 },
    { lat: 49.1609036, lng: 18.7315594 },
    { lat: 49.1608571, lng: 18.7316879 },
    { lat: 49.16084, lng: 18.731939 },
    { lat: 49.1608218, lng: 18.7321495 },
    { lat: 49.1608285, lng: 18.7321732 },
    { lat: 49.160764, lng: 18.7322743 },
    { lat: 49.1607098, lng: 18.7322939 },
    { lat: 49.1606736, lng: 18.7325105 },
    { lat: 49.1606079, lng: 18.7326097 },
    { lat: 49.1605551, lng: 18.7330892 },
    { lat: 49.1606371, lng: 18.7331671 },
    { lat: 49.1607706, lng: 18.7335411 },
    { lat: 49.1608509, lng: 18.7335043 },
    { lat: 49.1609102, lng: 18.733625 },
    { lat: 49.1609154, lng: 18.7337256 },
    { lat: 49.1609066, lng: 18.7340077 },
    { lat: 49.1611205, lng: 18.734387 },
    { lat: 49.1612832, lng: 18.7346518 },
    { lat: 49.1613616, lng: 18.7348472 },
    { lat: 49.1613908, lng: 18.7349198 },
    { lat: 49.1614088, lng: 18.7350696 },
    { lat: 49.1614968, lng: 18.73522 },
    { lat: 49.1616524, lng: 18.73561 },
    { lat: 49.1617951, lng: 18.7359659 },
    { lat: 49.1617178, lng: 18.7361057 },
    { lat: 49.1616885, lng: 18.7362552 },
    { lat: 49.1617172, lng: 18.7364139 },
    { lat: 49.1616957, lng: 18.7365235 },
    { lat: 49.1617534, lng: 18.7366783 },
    { lat: 49.16184, lng: 18.7367787 },
    { lat: 49.1619107, lng: 18.7367928 },
    { lat: 49.1619088, lng: 18.7368885 },
    { lat: 49.1616711, lng: 18.736978 },
    { lat: 49.1616438, lng: 18.7373816 },
    { lat: 49.1617489, lng: 18.7373677 },
    { lat: 49.1618037, lng: 18.7376158 },
    { lat: 49.1616705, lng: 18.737766 },
    { lat: 49.1616278, lng: 18.7378984 },
    { lat: 49.1615177, lng: 18.7380116 },
    { lat: 49.1614248, lng: 18.7383487 },
    { lat: 49.1614082, lng: 18.7384606 },
    { lat: 49.1613286, lng: 18.7390313 },
    { lat: 49.1612864, lng: 18.7394302 },
    { lat: 49.1612444, lng: 18.7394904 },
    { lat: 49.161186, lng: 18.7396247 },
    { lat: 49.1611737, lng: 18.7397325 },
    { lat: 49.1610651, lng: 18.7399417 },
    { lat: 49.1610424, lng: 18.7400915 },
    { lat: 49.1609923, lng: 18.7401448 },
    { lat: 49.160897, lng: 18.7402663 },
    { lat: 49.1607817, lng: 18.74069 },
    { lat: 49.1608569, lng: 18.7409626 },
    { lat: 49.1608559, lng: 18.7409883 },
    { lat: 49.1608263, lng: 18.7410917 },
    { lat: 49.1608537, lng: 18.7412029 },
    { lat: 49.1610447, lng: 18.7412777 },
    { lat: 49.1610372, lng: 18.7414595 },
    { lat: 49.1609703, lng: 18.7416662 },
    { lat: 49.1610045, lng: 18.7418468 },
    { lat: 49.1609316, lng: 18.7423724 },
    { lat: 49.1609979, lng: 18.7428406 },
    { lat: 49.1610307, lng: 18.7431018 },
    { lat: 49.1610135, lng: 18.7433551 },
    { lat: 49.1610574, lng: 18.7436524 },
    { lat: 49.1611022, lng: 18.7438631 },
    { lat: 49.1610892, lng: 18.7439631 },
    { lat: 49.1609523, lng: 18.7441696 },
    { lat: 49.1608747, lng: 18.7443657 },
    { lat: 49.1608641, lng: 18.7444653 },
    { lat: 49.160818, lng: 18.744682 },
    { lat: 49.1606785, lng: 18.7448622 },
    { lat: 49.1607519, lng: 18.7450814 },
    { lat: 49.1607786, lng: 18.7452666 },
    { lat: 49.160956, lng: 18.7454922 },
    { lat: 49.1609763, lng: 18.745618 },
    { lat: 49.1610676, lng: 18.7458144 },
    { lat: 49.1611303, lng: 18.7458241 },
    { lat: 49.1612672, lng: 18.746505 },
    { lat: 49.1614041, lng: 18.747094 },
    { lat: 49.1612312, lng: 18.747538 },
    { lat: 49.1613085, lng: 18.7477243 },
    { lat: 49.1612763, lng: 18.747926 },
    { lat: 49.1613436, lng: 18.7481794 },
    { lat: 49.1615163, lng: 18.7483137 },
    { lat: 49.1615094, lng: 18.7484839 },
    { lat: 49.1612111, lng: 18.748754 },
    { lat: 49.1611858, lng: 18.7488706 },
    { lat: 49.1609559, lng: 18.7490476 },
    { lat: 49.1609726, lng: 18.749127 },
    { lat: 49.1609325, lng: 18.7492461 },
    { lat: 49.1608867, lng: 18.7492348 },
    { lat: 49.1607743, lng: 18.7493479 },
    { lat: 49.1606804, lng: 18.7495369 },
    { lat: 49.1606303, lng: 18.749722 },
    { lat: 49.1607624, lng: 18.7498541 },
    { lat: 49.1606987, lng: 18.7502124 },
    { lat: 49.1608119, lng: 18.7506922 },
    { lat: 49.1606597, lng: 18.7517106 },
    { lat: 49.1606879, lng: 18.7519698 },
    { lat: 49.1608575, lng: 18.7524115 },
    { lat: 49.1610862, lng: 18.7535518 },
    { lat: 49.1611255, lng: 18.7539156 },
    { lat: 49.1612313, lng: 18.754258 },
    { lat: 49.1612833, lng: 18.7547107 },
    { lat: 49.1612265, lng: 18.7551345 },
    { lat: 49.1618278, lng: 18.7552925 },
    { lat: 49.1626059, lng: 18.7554867 },
    { lat: 49.1627876, lng: 18.7549937 },
    { lat: 49.1631351, lng: 18.7537874 },
    { lat: 49.1635946, lng: 18.7529976 },
    { lat: 49.1636756, lng: 18.7530202 },
    { lat: 49.1636824, lng: 18.7529967 },
    { lat: 49.1640853, lng: 18.751277 },
    { lat: 49.1633124, lng: 18.7510743 },
    { lat: 49.1634456, lng: 18.7505555 },
    { lat: 49.1635706, lng: 18.7500769 },
    { lat: 49.1637036, lng: 18.7495606 },
    { lat: 49.1637888, lng: 18.7492231 },
    { lat: 49.1638983, lng: 18.7487562 },
    { lat: 49.1639474, lng: 18.7485513 },
    { lat: 49.1640512, lng: 18.7481175 },
    { lat: 49.1640948, lng: 18.7477227 },
    { lat: 49.1641385, lng: 18.7473924 },
    { lat: 49.1641743, lng: 18.7470003 },
    { lat: 49.1643749, lng: 18.7459418 },
    { lat: 49.1643415, lng: 18.7450421 },
    { lat: 49.1646094, lng: 18.7432231 },
    { lat: 49.1645974, lng: 18.7426685 },
    { lat: 49.1648453, lng: 18.7425386 },
    { lat: 49.1660038, lng: 18.7417466 },
    { lat: 49.1661361, lng: 18.7416458 },
    { lat: 49.1661447, lng: 18.7416397 },
    { lat: 49.1662511, lng: 18.7416235 },
    { lat: 49.1663294, lng: 18.7415935 },
    { lat: 49.166364, lng: 18.7416029 },
    { lat: 49.1667215, lng: 18.7419515 },
    { lat: 49.166805, lng: 18.7419929 },
    { lat: 49.1669071, lng: 18.7420295 },
    { lat: 49.1670865, lng: 18.7419684 },
    { lat: 49.1671879, lng: 18.741964 },
    { lat: 49.1672895, lng: 18.7419689 },
    { lat: 49.167364, lng: 18.7419469 },
    { lat: 49.167448, lng: 18.7419583 },
    { lat: 49.1675024, lng: 18.7419409 },
    { lat: 49.1675503, lng: 18.7419487 },
    { lat: 49.1676153, lng: 18.7420302 },
    { lat: 49.1676646, lng: 18.7420253 },
    { lat: 49.1677404, lng: 18.7420914 },
    { lat: 49.167736, lng: 18.7421795 },
    { lat: 49.1678034, lng: 18.7422834 },
    { lat: 49.1678725, lng: 18.7423315 },
    { lat: 49.1678942, lng: 18.742361 },
    { lat: 49.167898, lng: 18.7423663 },
    { lat: 49.1679235, lng: 18.7424024 },
    { lat: 49.167966, lng: 18.742461 },
    { lat: 49.1680658, lng: 18.7424709 },
    { lat: 49.1681353, lng: 18.7425078 },
    { lat: 49.1682363, lng: 18.7425193 },
    { lat: 49.1682934, lng: 18.7425755 },
    { lat: 49.168331, lng: 18.7425637 },
    { lat: 49.1683293, lng: 18.7426172 },
    { lat: 49.1683627, lng: 18.7426492 },
    { lat: 49.1684052, lng: 18.7426664 },
    { lat: 49.1684836, lng: 18.7426741 },
    { lat: 49.1685332, lng: 18.7426105 },
    { lat: 49.1685848, lng: 18.7424543 },
    { lat: 49.168643, lng: 18.7424238 },
    { lat: 49.1686864, lng: 18.7424596 },
    { lat: 49.1687766, lng: 18.7423268 },
    { lat: 49.1688041, lng: 18.7423386 },
    { lat: 49.1688988, lng: 18.742268 },
    { lat: 49.1689112, lng: 18.742185 },
    { lat: 49.168952, lng: 18.7421256 },
    { lat: 49.1690005, lng: 18.7421446 },
    { lat: 49.1690714, lng: 18.7420772 },
    { lat: 49.1691737, lng: 18.7420936 },
    { lat: 49.1692848, lng: 18.7420809 },
    { lat: 49.1693279, lng: 18.7421106 },
    { lat: 49.1693972, lng: 18.7420975 },
    { lat: 49.1695344, lng: 18.7420285 },
    { lat: 49.1696271, lng: 18.742098 },
    { lat: 49.1696841, lng: 18.7420853 },
    { lat: 49.1697566, lng: 18.7421114 },
    { lat: 49.1697667, lng: 18.7421776 },
    { lat: 49.1698536, lng: 18.7421778 },
    { lat: 49.1698819, lng: 18.7421174 },
    { lat: 49.1699349, lng: 18.7420731 },
    { lat: 49.1700501, lng: 18.7421211 },
    { lat: 49.1701374, lng: 18.7420878 },
    { lat: 49.1701557, lng: 18.7420027 },
    { lat: 49.1701323, lng: 18.7419035 },
    { lat: 49.1701711, lng: 18.7418685 },
    { lat: 49.1702115, lng: 18.7418856 },
    { lat: 49.170275, lng: 18.7419128 },
    { lat: 49.1703247, lng: 18.7419587 },
    { lat: 49.1703974, lng: 18.7419491 },
    { lat: 49.1704315, lng: 18.7419544 },
    { lat: 49.1704519, lng: 18.7419339 },
    { lat: 49.1704824, lng: 18.7419561 },
    { lat: 49.1704955, lng: 18.7420752 },
    { lat: 49.1705376, lng: 18.7420629 },
    { lat: 49.170584, lng: 18.7421088 },
    { lat: 49.1706143, lng: 18.742237 },
    { lat: 49.1706826, lng: 18.7422925 },
    { lat: 49.1707191, lng: 18.7423205 },
    { lat: 49.1707999, lng: 18.7423107 },
    { lat: 49.1708667, lng: 18.7423598 },
    { lat: 49.1709514, lng: 18.7423611 },
    { lat: 49.1710126, lng: 18.7424109 },
    { lat: 49.1713388, lng: 18.7424815 },
    { lat: 49.1713832, lng: 18.7424895 },
    { lat: 49.1715302, lng: 18.7425004 },
    { lat: 49.1716159, lng: 18.7424759 },
    { lat: 49.1716698, lng: 18.7424539 },
    { lat: 49.1717505, lng: 18.7423795 },
    { lat: 49.1717977, lng: 18.7423511 },
    { lat: 49.1719142, lng: 18.7422522 },
    { lat: 49.1724806, lng: 18.7417188 },
    { lat: 49.1725719, lng: 18.7416529 },
    { lat: 49.1727152, lng: 18.7415047 },
    { lat: 49.1728334, lng: 18.7413483 },
    { lat: 49.1728903, lng: 18.7413165 },
    { lat: 49.1729631, lng: 18.7411343 },
    { lat: 49.1731747, lng: 18.7406318 },
    { lat: 49.1731805, lng: 18.7405262 },
    { lat: 49.1732036, lng: 18.7405151 },
    { lat: 49.1732411, lng: 18.7402388 },
    { lat: 49.1732301, lng: 18.7401825 },
    { lat: 49.1732516, lng: 18.7400321 },
    { lat: 49.1732695, lng: 18.7399005 },
    { lat: 49.1733054, lng: 18.7397019 },
    { lat: 49.1733691, lng: 18.7394963 },
    { lat: 49.1735256, lng: 18.7390603 },
    { lat: 49.1735574, lng: 18.7389988 },
    { lat: 49.1735706, lng: 18.7389081 },
    { lat: 49.1736066, lng: 18.7387783 },
    { lat: 49.1736472, lng: 18.7386916 },
    { lat: 49.1737208, lng: 18.7384819 },
    { lat: 49.1737568, lng: 18.7383932 },
    { lat: 49.173775, lng: 18.7383117 },
    { lat: 49.1738029, lng: 18.7382845 },
    { lat: 49.1738087, lng: 18.738242 },
    { lat: 49.1739211, lng: 18.7379569 },
    { lat: 49.1739532, lng: 18.7379249 },
    { lat: 49.1740093, lng: 18.7377681 },
    { lat: 49.1740636, lng: 18.737555 },
    { lat: 49.174117, lng: 18.7374328 },
    { lat: 49.1741229, lng: 18.7373734 },
    { lat: 49.1741174, lng: 18.7373319 },
    { lat: 49.1741807, lng: 18.7369252 },
    { lat: 49.1741727, lng: 18.7369038 },
    { lat: 49.1741935, lng: 18.7368498 },
    { lat: 49.1742267, lng: 18.7366626 },
    { lat: 49.174241, lng: 18.7365928 },
    { lat: 49.1742456, lng: 18.736509 },
    { lat: 49.1742732, lng: 18.7364083 },
    { lat: 49.1743015, lng: 18.7363721 },
    { lat: 49.1743328, lng: 18.7363182 },
    { lat: 49.1743738, lng: 18.7361358 },
    { lat: 49.1744079, lng: 18.7360528 },
    { lat: 49.1744216, lng: 18.7359307 },
    { lat: 49.1744378, lng: 18.7358693 },
    { lat: 49.1744578, lng: 18.7358452 },
    { lat: 49.1744538, lng: 18.735787 },
    { lat: 49.174479, lng: 18.7356444 },
    { lat: 49.1744921, lng: 18.7354662 },
    { lat: 49.1744834, lng: 18.7353404 },
    { lat: 49.174519, lng: 18.7352453 },
    { lat: 49.1745401, lng: 18.7352182 },
    { lat: 49.1745373, lng: 18.7352113 },
    { lat: 49.1745386, lng: 18.7351686 },
    { lat: 49.1745415, lng: 18.7351593 },
    { lat: 49.1745282, lng: 18.735123 },
    { lat: 49.1745384, lng: 18.7350382 },
    { lat: 49.174554, lng: 18.7350334 },
    { lat: 49.1745725, lng: 18.7349122 },
    { lat: 49.1747128, lng: 18.7347272 },
    { lat: 49.1747277, lng: 18.7345819 },
    { lat: 49.174742, lng: 18.7345085 },
    { lat: 49.1747657, lng: 18.7344241 },
    { lat: 49.1747744, lng: 18.7343315 },
    { lat: 49.174792, lng: 18.7340414 },
    { lat: 49.174809, lng: 18.7338509 },
    { lat: 49.1748326, lng: 18.7337417 },
    { lat: 49.174861, lng: 18.7336575 },
    { lat: 49.1748754, lng: 18.7334586 },
    { lat: 49.1748489, lng: 18.7332773 },
    { lat: 49.1748253, lng: 18.7332376 },
    { lat: 49.1748256, lng: 18.733176 },
    { lat: 49.1748407, lng: 18.7331208 },
    { lat: 49.1748442, lng: 18.7329946 },
    { lat: 49.1748802, lng: 18.7328648 },
    { lat: 49.1749275, lng: 18.7328388 },
    { lat: 49.1749404, lng: 18.7327417 },
    { lat: 49.1749161, lng: 18.7326214 },
    { lat: 49.1749312, lng: 18.7324354 },
    { lat: 49.1749537, lng: 18.7324139 },
    { lat: 49.1750414, lng: 18.7323863 },
    { lat: 49.1750667, lng: 18.7323342 },
    { lat: 49.1750794, lng: 18.7321885 },
    { lat: 49.1750471, lng: 18.7321524 },
    { lat: 49.1750542, lng: 18.7321173 },
    { lat: 49.1751035, lng: 18.7321113 },
    { lat: 49.175122, lng: 18.7320993 },
    { lat: 49.1751807, lng: 18.731947 },
    { lat: 49.1751817, lng: 18.7318816 },
    { lat: 49.1752616, lng: 18.7318138 },
    { lat: 49.1752814, lng: 18.7317415 },
    { lat: 49.1753099, lng: 18.7317207 },
    { lat: 49.1753651, lng: 18.7316149 },
    { lat: 49.1753936, lng: 18.7314921 },
    { lat: 49.1753989, lng: 18.7314399 },
    { lat: 49.1754696, lng: 18.7313509 },
    { lat: 49.1754801, lng: 18.7312488 },
    { lat: 49.1754486, lng: 18.7311838 },
    { lat: 49.1755322, lng: 18.7310391 },
    { lat: 49.1755574, lng: 18.730893 },
    { lat: 49.1756139, lng: 18.7308746 },
    { lat: 49.1756475, lng: 18.7308481 },
    { lat: 49.1756524, lng: 18.7307718 },
    { lat: 49.1756809, lng: 18.7307111 },
    { lat: 49.1757266, lng: 18.730681 },
    { lat: 49.1757764, lng: 18.7306162 },
    { lat: 49.1758852, lng: 18.7305204 },
    { lat: 49.1758699, lng: 18.7303802 },
    { lat: 49.1758666, lng: 18.7303403 },
    { lat: 49.1758129, lng: 18.7301313 },
    { lat: 49.1758424, lng: 18.7300268 },
    { lat: 49.1759172, lng: 18.7299481 },
    { lat: 49.1759482, lng: 18.7298733 },
    { lat: 49.1759622, lng: 18.7298157 },
    { lat: 49.1759615, lng: 18.7297607 },
    { lat: 49.1759811, lng: 18.7297244 },
    { lat: 49.1759302, lng: 18.72966 },
    { lat: 49.1759318, lng: 18.7295596 },
    { lat: 49.1760519, lng: 18.7294433 },
    { lat: 49.1760409, lng: 18.72936 },
    { lat: 49.1760344, lng: 18.729258 },
    { lat: 49.1760154, lng: 18.7292203 },
    { lat: 49.1760381, lng: 18.729113 },
    { lat: 49.1761003, lng: 18.729073 },
    { lat: 49.1761208, lng: 18.7289916 },
    { lat: 49.1761227, lng: 18.7289189 },
    { lat: 49.1760936, lng: 18.7288374 },
    { lat: 49.1761226, lng: 18.7287683 },
    { lat: 49.1761439, lng: 18.7287635 },
    { lat: 49.1761505, lng: 18.7286957 },
    { lat: 49.1761496, lng: 18.7286605 },
    { lat: 49.1762008, lng: 18.7285155 },
    { lat: 49.176203, lng: 18.7284727 },
    { lat: 49.1762621, lng: 18.7283552 },
    { lat: 49.176285, lng: 18.7282511 },
    { lat: 49.1762804, lng: 18.7281854 },
    { lat: 49.1763309, lng: 18.7281392 },
    { lat: 49.1763224, lng: 18.7280862 },
    { lat: 49.1763475, lng: 18.728024 },
    { lat: 49.1763755, lng: 18.7279132 },
    { lat: 49.1763992, lng: 18.7278515 },
    { lat: 49.1764142, lng: 18.7276825 },
    { lat: 49.1769191, lng: 18.7277215 },
    { lat: 49.1770527, lng: 18.7276695 },
    { lat: 49.1774058, lng: 18.7275619 },
    { lat: 49.1775986, lng: 18.7275157 },
    { lat: 49.1778077, lng: 18.7274448 },
    { lat: 49.1781714, lng: 18.7272588 },
    { lat: 49.1782964, lng: 18.7272144 },
    { lat: 49.1783143, lng: 18.7272082 },
    { lat: 49.1783207, lng: 18.7272056 },
    { lat: 49.1783375, lng: 18.7272 },
    { lat: 49.1784117, lng: 18.727174 },
  ],
  Mojš: [
    { lat: 49.20725, lng: 18.8025104 },
    { lat: 49.2070387, lng: 18.8039386 },
    { lat: 49.206794, lng: 18.8047265 },
    { lat: 49.2064835, lng: 18.8053701 },
    { lat: 49.206037, lng: 18.8064411 },
    { lat: 49.2054665, lng: 18.8075527 },
    { lat: 49.2051965, lng: 18.8079808 },
    { lat: 49.2047455, lng: 18.8086124 },
    { lat: 49.2046273, lng: 18.8086843 },
    { lat: 49.2044016, lng: 18.8088741 },
    { lat: 49.2041052, lng: 18.8093345 },
    { lat: 49.2035131, lng: 18.8101161 },
    { lat: 49.2027771, lng: 18.8108884 },
    { lat: 49.2025701, lng: 18.8111819 },
    { lat: 49.2018254, lng: 18.8120424 },
    { lat: 49.2009994, lng: 18.8128157 },
    { lat: 49.2008402, lng: 18.8129866 },
    { lat: 49.2002949, lng: 18.8136078 },
    { lat: 49.1999053, lng: 18.8140747 },
    { lat: 49.199357, lng: 18.8147053 },
    { lat: 49.1987304, lng: 18.8154512 },
    { lat: 49.1985451, lng: 18.8157277 },
    { lat: 49.1982886, lng: 18.8160455 },
    { lat: 49.1978502, lng: 18.8166845 },
    { lat: 49.197229, lng: 18.8177556 },
    { lat: 49.1971327, lng: 18.8178985 },
    { lat: 49.1971151, lng: 18.817951 },
    { lat: 49.1970926, lng: 18.8180374 },
    { lat: 49.1970586, lng: 18.8181428 },
    { lat: 49.1968666, lng: 18.8187081 },
    { lat: 49.1968578, lng: 18.8187344 },
    { lat: 49.1966574, lng: 18.8193285 },
    { lat: 49.1964858, lng: 18.820355 },
    { lat: 49.1964625, lng: 18.8204479 },
    { lat: 49.1962432, lng: 18.8212032 },
    { lat: 49.1961513, lng: 18.8216055 },
    { lat: 49.1961791, lng: 18.8221224 },
    { lat: 49.1963936, lng: 18.8228505 },
    { lat: 49.1964488, lng: 18.8233529 },
    { lat: 49.1964713, lng: 18.8236556 },
    { lat: 49.1964979, lng: 18.8239978 },
    { lat: 49.1965289, lng: 18.8242057 },
    { lat: 49.1965616, lng: 18.8244217 },
    { lat: 49.1965787, lng: 18.824539 },
    { lat: 49.1965873, lng: 18.8245956 },
    { lat: 49.1965964, lng: 18.8248123 },
    { lat: 49.1966059, lng: 18.8250891 },
    { lat: 49.1966178, lng: 18.8253848 },
    { lat: 49.196633, lng: 18.8257882 },
    { lat: 49.1966375, lng: 18.8258944 },
    { lat: 49.1966366, lng: 18.8258995 },
    { lat: 49.1966037, lng: 18.8261371 },
    { lat: 49.1965642, lng: 18.8264209 },
    { lat: 49.1964762, lng: 18.8268125 },
    { lat: 49.1963703, lng: 18.8272106 },
    { lat: 49.1962394, lng: 18.8276464 },
    { lat: 49.1960644, lng: 18.8282301 },
    { lat: 49.1958029, lng: 18.8289097 },
    { lat: 49.195641, lng: 18.8292951 },
    { lat: 49.1954486, lng: 18.8297382 },
    { lat: 49.1953407, lng: 18.8300294 },
    { lat: 49.1951522, lng: 18.8304879 },
    { lat: 49.1949737, lng: 18.8309625 },
    { lat: 49.1948512, lng: 18.8312547 },
    { lat: 49.1947369, lng: 18.8316416 },
    { lat: 49.1945643, lng: 18.8320105 },
    { lat: 49.1949548, lng: 18.8324317 },
    { lat: 49.1950076, lng: 18.8324513 },
    { lat: 49.1952069, lng: 18.8327292 },
    { lat: 49.1952955, lng: 18.8328949 },
    { lat: 49.1953379, lng: 18.8329759 },
    { lat: 49.1953392, lng: 18.8329783 },
    { lat: 49.1953465, lng: 18.8329911 },
    { lat: 49.195374, lng: 18.833043 },
    { lat: 49.1953829, lng: 18.8330596 },
    { lat: 49.1955001, lng: 18.8332813 },
    { lat: 49.195515, lng: 18.8333091 },
    { lat: 49.1955415, lng: 18.8333593 },
    { lat: 49.1957225, lng: 18.8337003 },
    { lat: 49.1958009, lng: 18.8338459 },
    { lat: 49.1958897, lng: 18.8340144 },
    { lat: 49.196022, lng: 18.8342629 },
    { lat: 49.1961394, lng: 18.8344831 },
    { lat: 49.1964101, lng: 18.8346836 },
    { lat: 49.196485, lng: 18.8347384 },
    { lat: 49.1967302, lng: 18.8348342 },
    { lat: 49.1971111, lng: 18.8350052 },
    { lat: 49.1974408, lng: 18.8352357 },
    { lat: 49.1977751, lng: 18.8355068 },
    { lat: 49.1981645, lng: 18.8358478 },
    { lat: 49.198508, lng: 18.836187 },
    { lat: 49.1987101, lng: 18.8364775 },
    { lat: 49.198905, lng: 18.8367152 },
    { lat: 49.1989187, lng: 18.8367193 },
    { lat: 49.1989221, lng: 18.8367222 },
    { lat: 49.1989565, lng: 18.8367296 },
    { lat: 49.1989714, lng: 18.8367343 },
    { lat: 49.1989839, lng: 18.8367375 },
    { lat: 49.1990042, lng: 18.8365634 },
    { lat: 49.1990374, lng: 18.8363965 },
    { lat: 49.1991777, lng: 18.8358353 },
    { lat: 49.1993089, lng: 18.8352755 },
    { lat: 49.1994687, lng: 18.8345013 },
    { lat: 49.1995336, lng: 18.8341413 },
    { lat: 49.1995671, lng: 18.8337832 },
    { lat: 49.1995632, lng: 18.8334048 },
    { lat: 49.1995569, lng: 18.8331946 },
    { lat: 49.1995598, lng: 18.8331593 },
    { lat: 49.1995608, lng: 18.8331361 },
    { lat: 49.1995568, lng: 18.8329026 },
    { lat: 49.1995546, lng: 18.8325989 },
    { lat: 49.1995409, lng: 18.8323781 },
    { lat: 49.1995944, lng: 18.8317753 },
    { lat: 49.1995914, lng: 18.8316931 },
    { lat: 49.1996727, lng: 18.8316765 },
    { lat: 49.1997633, lng: 18.8316592 },
    { lat: 49.1998466, lng: 18.8315695 },
    { lat: 49.2002005, lng: 18.8311514 },
    { lat: 49.2003825, lng: 18.8310066 },
    { lat: 49.2009545, lng: 18.8306203 },
    { lat: 49.2015616, lng: 18.8302151 },
    { lat: 49.2018238, lng: 18.8299843 },
    { lat: 49.2020135, lng: 18.8299009 },
    { lat: 49.2022487, lng: 18.8296092 },
    { lat: 49.202274, lng: 18.8295737 },
    { lat: 49.202437, lng: 18.8293482 },
    { lat: 49.2028116, lng: 18.8288674 },
    { lat: 49.2030893, lng: 18.8285034 },
    { lat: 49.2034256, lng: 18.8280499 },
    { lat: 49.2036086, lng: 18.8278379 },
    { lat: 49.2038149, lng: 18.8276822 },
    { lat: 49.2043108, lng: 18.8274385 },
    { lat: 49.2048666, lng: 18.8271745 },
    { lat: 49.205496, lng: 18.8268715 },
    { lat: 49.2060017, lng: 18.8266204 },
    { lat: 49.2060974, lng: 18.826574 },
    { lat: 49.2061608, lng: 18.8265573 },
    { lat: 49.206291, lng: 18.8265496 },
    { lat: 49.2062888, lng: 18.8265708 },
    { lat: 49.2063017, lng: 18.8265786 },
    { lat: 49.2063164, lng: 18.8265141 },
    { lat: 49.2063729, lng: 18.8265146 },
    { lat: 49.2067446, lng: 18.8265302 },
    { lat: 49.2067652, lng: 18.8265349 },
    { lat: 49.2068633, lng: 18.8265365 },
    { lat: 49.2068882, lng: 18.8265371 },
    { lat: 49.2069489, lng: 18.8265385 },
    { lat: 49.2071887, lng: 18.8264408 },
    { lat: 49.207213, lng: 18.8264311 },
    { lat: 49.2073266, lng: 18.8264712 },
    { lat: 49.2077583, lng: 18.8267511 },
    { lat: 49.2081433, lng: 18.8270251 },
    { lat: 49.2086578, lng: 18.8274445 },
    { lat: 49.2090018, lng: 18.827669 },
    { lat: 49.2094969, lng: 18.827911 },
    { lat: 49.2095132, lng: 18.8278142 },
    { lat: 49.2095271, lng: 18.8277304 },
    { lat: 49.2095989, lng: 18.8274599 },
    { lat: 49.2096511, lng: 18.8272708 },
    { lat: 49.2097709, lng: 18.8268392 },
    { lat: 49.2098557, lng: 18.8265589 },
    { lat: 49.2100342, lng: 18.825998 },
    { lat: 49.2102184, lng: 18.8254375 },
    { lat: 49.2102325, lng: 18.8254037 },
    { lat: 49.2102923, lng: 18.825253 },
    { lat: 49.2103474, lng: 18.8251154 },
    { lat: 49.2103834, lng: 18.8250253 },
    { lat: 49.2104203, lng: 18.8249343 },
    { lat: 49.2104552, lng: 18.8248454 },
    { lat: 49.2105143, lng: 18.8246994 },
    { lat: 49.2105392, lng: 18.8246369 },
    { lat: 49.2105622, lng: 18.8245794 },
    { lat: 49.2106339, lng: 18.8243991 },
    { lat: 49.210657, lng: 18.8243422 },
    { lat: 49.210713, lng: 18.8242046 },
    { lat: 49.2107659, lng: 18.8240724 },
    { lat: 49.2108239, lng: 18.8239276 },
    { lat: 49.210889, lng: 18.8237687 },
    { lat: 49.210894, lng: 18.8237552 },
    { lat: 49.2109629, lng: 18.8235849 },
    { lat: 49.2110371, lng: 18.8234018 },
    { lat: 49.2110671, lng: 18.8233279 },
    { lat: 49.2111107, lng: 18.8232113 },
    { lat: 49.2111821, lng: 18.8230234 },
    { lat: 49.2112346, lng: 18.822883 },
    { lat: 49.2112586, lng: 18.8225364 },
    { lat: 49.21128, lng: 18.8222323 },
    { lat: 49.2112873, lng: 18.8221323 },
    { lat: 49.2113068, lng: 18.8219648 },
    { lat: 49.2113162, lng: 18.8218861 },
    { lat: 49.2113273, lng: 18.8217932 },
    { lat: 49.2113306, lng: 18.8217701 },
    { lat: 49.2113385, lng: 18.8217269 },
    { lat: 49.2113404, lng: 18.8217177 },
    { lat: 49.2113874, lng: 18.82147 },
    { lat: 49.2114004, lng: 18.8213971 },
    { lat: 49.2114175, lng: 18.8213579 },
    { lat: 49.2114551, lng: 18.8212758 },
    { lat: 49.2115339, lng: 18.8210983 },
    { lat: 49.2115725, lng: 18.8210132 },
    { lat: 49.2115813, lng: 18.8209923 },
    { lat: 49.2116116, lng: 18.8209198 },
    { lat: 49.2116785, lng: 18.8207556 },
    { lat: 49.2117467, lng: 18.8205891 },
    { lat: 49.2118056, lng: 18.8204439 },
    { lat: 49.2118157, lng: 18.8204196 },
    { lat: 49.211869, lng: 18.8202935 },
    { lat: 49.2119223, lng: 18.8201677 },
    { lat: 49.2119293, lng: 18.8201521 },
    { lat: 49.211988, lng: 18.8200217 },
    { lat: 49.211992, lng: 18.8200129 },
    { lat: 49.212062, lng: 18.8198801 },
    { lat: 49.2121274, lng: 18.8197525 },
    { lat: 49.2123132, lng: 18.8193955 },
    { lat: 49.2123275, lng: 18.8193671 },
    { lat: 49.2123588, lng: 18.8192958 },
    { lat: 49.2123991, lng: 18.8192023 },
    { lat: 49.2124807, lng: 18.8190154 },
    { lat: 49.2125018, lng: 18.8189671 },
    { lat: 49.212521, lng: 18.8189226 },
    { lat: 49.2125584, lng: 18.8188366 },
    { lat: 49.2125926, lng: 18.8187571 },
    { lat: 49.2126289, lng: 18.8186723 },
    { lat: 49.2126691, lng: 18.8185784 },
    { lat: 49.2126972, lng: 18.8185123 },
    { lat: 49.2127624, lng: 18.818358 },
    { lat: 49.2127755, lng: 18.8183276 },
    { lat: 49.2128265, lng: 18.8181988 },
    { lat: 49.2128693, lng: 18.8180894 },
    { lat: 49.2128853, lng: 18.8180511 },
    { lat: 49.2129265, lng: 18.8179296 },
    { lat: 49.212946, lng: 18.8178721 },
    { lat: 49.2129538, lng: 18.8178488 },
    { lat: 49.2129753, lng: 18.8177846 },
    { lat: 49.2130527, lng: 18.8175582 },
    { lat: 49.2131017, lng: 18.8174145 },
    { lat: 49.2131459, lng: 18.8172862 },
    { lat: 49.2132008, lng: 18.8171267 },
    { lat: 49.2132506, lng: 18.81698 },
    { lat: 49.2132545, lng: 18.8169687 },
    { lat: 49.213286, lng: 18.8168773 },
    { lat: 49.2133185, lng: 18.8167851 },
    { lat: 49.2133852, lng: 18.8165927 },
    { lat: 49.2134186, lng: 18.8164986 },
    { lat: 49.2134489, lng: 18.8164094 },
    { lat: 49.2134893, lng: 18.8162942 },
    { lat: 49.2135198, lng: 18.816208 },
    { lat: 49.2135591, lng: 18.8161629 },
    { lat: 49.2135504, lng: 18.8160993 },
    { lat: 49.2133908, lng: 18.8151729 },
    { lat: 49.213258, lng: 18.8138003 },
    { lat: 49.213268, lng: 18.8137544 },
    { lat: 49.2131585, lng: 18.8125947 },
    { lat: 49.2131527, lng: 18.812524 },
    { lat: 49.2131186, lng: 18.8121483 },
    { lat: 49.2129978, lng: 18.8107913 },
    { lat: 49.2128141, lng: 18.8087627 },
    { lat: 49.2127565, lng: 18.8084761 },
    { lat: 49.2127434, lng: 18.8084203 },
    { lat: 49.2126017, lng: 18.8081012 },
    { lat: 49.2123306, lng: 18.807788 },
    { lat: 49.2123144, lng: 18.8077579 },
    { lat: 49.2122548, lng: 18.8077153 },
    { lat: 49.2119756, lng: 18.8074423 },
    { lat: 49.211844, lng: 18.8073139 },
    { lat: 49.2118384, lng: 18.8073395 },
    { lat: 49.2115899, lng: 18.807175 },
    { lat: 49.2116027, lng: 18.8071222 },
    { lat: 49.2114961, lng: 18.806998 },
    { lat: 49.2114653, lng: 18.8069682 },
    { lat: 49.2111376, lng: 18.8066492 },
    { lat: 49.2106912, lng: 18.8061948 },
    { lat: 49.2105209, lng: 18.8060169 },
    { lat: 49.2101712, lng: 18.8055141 },
    { lat: 49.2100396, lng: 18.8052584 },
    { lat: 49.2100169, lng: 18.8052128 },
    { lat: 49.2098753, lng: 18.8048721 },
    { lat: 49.2098061, lng: 18.8047139 },
    { lat: 49.2095965, lng: 18.8044062 },
    { lat: 49.209194, lng: 18.8039754 },
    { lat: 49.2088062, lng: 18.8036489 },
    { lat: 49.2087733, lng: 18.8036211 },
    { lat: 49.2087049, lng: 18.8035634 },
    { lat: 49.2086345, lng: 18.8035091 },
    { lat: 49.2084583, lng: 18.8033742 },
    { lat: 49.208443, lng: 18.8033602 },
    { lat: 49.2084133, lng: 18.8033345 },
    { lat: 49.2083945, lng: 18.8033188 },
    { lat: 49.208356, lng: 18.8032913 },
    { lat: 49.2083396, lng: 18.8032796 },
    { lat: 49.2081349, lng: 18.8031362 },
    { lat: 49.2081278, lng: 18.8031313 },
    { lat: 49.2080927, lng: 18.8031067 },
    { lat: 49.2080868, lng: 18.8031027 },
    { lat: 49.2080505, lng: 18.8030771 },
    { lat: 49.2077674, lng: 18.8028793 },
    { lat: 49.2077457, lng: 18.8028531 },
    { lat: 49.20725, lng: 18.8025104 },
  ],
  Porúbka: [
    { lat: 49.1464108, lng: 18.7038232 },
    { lat: 49.1461702, lng: 18.7035385 },
    { lat: 49.1456854, lng: 18.7029847 },
    { lat: 49.1453752, lng: 18.7031297 },
    { lat: 49.1451307, lng: 18.7033905 },
    { lat: 49.1450246, lng: 18.7035836 },
    { lat: 49.1446129, lng: 18.7039246 },
    { lat: 49.1439926, lng: 18.704351 },
    { lat: 49.1435591, lng: 18.704985 },
    { lat: 49.1432349, lng: 18.7053358 },
    { lat: 49.1421522, lng: 18.7062967 },
    { lat: 49.1420698, lng: 18.7064703 },
    { lat: 49.1420089, lng: 18.7068743 },
    { lat: 49.141899, lng: 18.7072962 },
    { lat: 49.1418654, lng: 18.7078355 },
    { lat: 49.1420075, lng: 18.7082622 },
    { lat: 49.1422185, lng: 18.7088689 },
    { lat: 49.1423921, lng: 18.7097878 },
    { lat: 49.1424988, lng: 18.7106799 },
    { lat: 49.142937, lng: 18.7112293 },
    { lat: 49.1432637, lng: 18.711457 },
    { lat: 49.1435349, lng: 18.7117462 },
    { lat: 49.1436635, lng: 18.7119812 },
    { lat: 49.1438309, lng: 18.7122089 },
    { lat: 49.1441024, lng: 18.71259 },
    { lat: 49.1444189, lng: 18.7129245 },
    { lat: 49.144653, lng: 18.7135162 },
    { lat: 49.14482, lng: 18.7139067 },
    { lat: 49.1450743, lng: 18.7144722 },
    { lat: 49.1453357, lng: 18.7148559 },
    { lat: 49.1455227, lng: 18.7153259 },
    { lat: 49.1456502, lng: 18.7156867 },
    { lat: 49.1449778, lng: 18.7161117 },
    { lat: 49.1448882, lng: 18.7168546 },
    { lat: 49.1447639, lng: 18.7174068 },
    { lat: 49.1445363, lng: 18.7182061 },
    { lat: 49.1443991, lng: 18.7199313 },
    { lat: 49.1448794, lng: 18.7212796 },
    { lat: 49.1442311, lng: 18.7211739 },
    { lat: 49.1440736, lng: 18.7211625 },
    { lat: 49.143991, lng: 18.7205747 },
    { lat: 49.1438477, lng: 18.7202943 },
    { lat: 49.1434854, lng: 18.7199862 },
    { lat: 49.143047, lng: 18.7198009 },
    { lat: 49.1425659, lng: 18.7196172 },
    { lat: 49.1420209, lng: 18.7197143 },
    { lat: 49.1419212, lng: 18.7197502 },
    { lat: 49.14182, lng: 18.7197993 },
    { lat: 49.1418509, lng: 18.7199382 },
    { lat: 49.1417884, lng: 18.7200344 },
    { lat: 49.1412443, lng: 18.7208607 },
    { lat: 49.1412562, lng: 18.7208755 },
    { lat: 49.1414328, lng: 18.7210571 },
    { lat: 49.1417248, lng: 18.7213802 },
    { lat: 49.1420415, lng: 18.7217183 },
    { lat: 49.1422827, lng: 18.7219958 },
    { lat: 49.1424837, lng: 18.7222371 },
    { lat: 49.1429865, lng: 18.7228565 },
    { lat: 49.1431701, lng: 18.7233924 },
    { lat: 49.1432611, lng: 18.723664 },
    { lat: 49.1433972, lng: 18.7241488 },
    { lat: 49.1436451, lng: 18.7250046 },
    { lat: 49.1437615, lng: 18.7253539 },
    { lat: 49.1438989, lng: 18.7256944 },
    { lat: 49.1441153, lng: 18.7261432 },
    { lat: 49.1442666, lng: 18.7264064 },
    { lat: 49.1444081, lng: 18.7262022 },
    { lat: 49.1444322, lng: 18.7262976 },
    { lat: 49.144358, lng: 18.7265588 },
    { lat: 49.1446258, lng: 18.7269782 },
    { lat: 49.1447992, lng: 18.7272524 },
    { lat: 49.1449063, lng: 18.7274036 },
    { lat: 49.1449636, lng: 18.7275036 },
    { lat: 49.145274, lng: 18.7279221 },
    { lat: 49.1454546, lng: 18.7282247 },
    { lat: 49.1459341, lng: 18.7289814 },
    { lat: 49.1460991, lng: 18.7292041 },
    { lat: 49.1462825, lng: 18.7294115 },
    { lat: 49.146547, lng: 18.7296394 },
    { lat: 49.1469029, lng: 18.7298247 },
    { lat: 49.1469772, lng: 18.7298629 },
    { lat: 49.1471728, lng: 18.7299597 },
    { lat: 49.1473218, lng: 18.7299862 },
    { lat: 49.1476171, lng: 18.7299805 },
    { lat: 49.1480154, lng: 18.7298783 },
    { lat: 49.148018, lng: 18.7297336 },
    { lat: 49.1480574, lng: 18.7296888 },
    { lat: 49.1480944, lng: 18.7298799 },
    { lat: 49.148108, lng: 18.7299459 },
    { lat: 49.148137, lng: 18.7307331 },
    { lat: 49.1480174, lng: 18.7308175 },
    { lat: 49.1479382, lng: 18.7309432 },
    { lat: 49.1479013, lng: 18.7313165 },
    { lat: 49.1475153, lng: 18.7317609 },
    { lat: 49.1473903, lng: 18.7320444 },
    { lat: 49.1472421, lng: 18.7325322 },
    { lat: 49.1472483, lng: 18.7329482 },
    { lat: 49.1470848, lng: 18.7334655 },
    { lat: 49.1470562, lng: 18.733629 },
    { lat: 49.1469778, lng: 18.7338993 },
    { lat: 49.1469963, lng: 18.7342086 },
    { lat: 49.1469634, lng: 18.7344417 },
    { lat: 49.1469723, lng: 18.734656 },
    { lat: 49.1471389, lng: 18.7348652 },
    { lat: 49.1471858, lng: 18.7349427 },
    { lat: 49.1472041, lng: 18.7352701 },
    { lat: 49.147164, lng: 18.7354068 },
    { lat: 49.1471871, lng: 18.7355272 },
    { lat: 49.1473248, lng: 18.735702 },
    { lat: 49.1473342, lng: 18.7357967 },
    { lat: 49.1473197, lng: 18.7359029 },
    { lat: 49.1473186, lng: 18.7360976 },
    { lat: 49.1473016, lng: 18.73627 },
    { lat: 49.1472703, lng: 18.7363405 },
    { lat: 49.1472644, lng: 18.7364431 },
    { lat: 49.1473051, lng: 18.7365931 },
    { lat: 49.1474161, lng: 18.7367319 },
    { lat: 49.1475083, lng: 18.7367697 },
    { lat: 49.147558, lng: 18.7368609 },
    { lat: 49.147588, lng: 18.7369376 },
    { lat: 49.1476148, lng: 18.7371476 },
    { lat: 49.1476196, lng: 18.7372623 },
    { lat: 49.1476514, lng: 18.7373506 },
    { lat: 49.1477354, lng: 18.7374697 },
    { lat: 49.1478244, lng: 18.7376159 },
    { lat: 49.1479039, lng: 18.7377575 },
    { lat: 49.1479843, lng: 18.7379354 },
    { lat: 49.148024, lng: 18.7379634 },
    { lat: 49.1480417, lng: 18.7379976 },
    { lat: 49.1480648, lng: 18.738033 },
    { lat: 49.1480633, lng: 18.7380908 },
    { lat: 49.1480437, lng: 18.7382311 },
    { lat: 49.1480489, lng: 18.7383296 },
    { lat: 49.1480999, lng: 18.7384642 },
    { lat: 49.1481791, lng: 18.7386005 },
    { lat: 49.1482458, lng: 18.7387094 },
    { lat: 49.1483911, lng: 18.7388589 },
    { lat: 49.1484548, lng: 18.7389116 },
    { lat: 49.1485035, lng: 18.7390012 },
    { lat: 49.1485303, lng: 18.7391262 },
    { lat: 49.1485404, lng: 18.7391249 },
    { lat: 49.148568, lng: 18.739114 },
    { lat: 49.148803, lng: 18.7392288 },
    { lat: 49.1492658, lng: 18.7390018 },
    { lat: 49.1496892, lng: 18.7386225 },
    { lat: 49.1502021, lng: 18.7379747 },
    { lat: 49.1506457, lng: 18.7378764 },
    { lat: 49.1509726, lng: 18.7389083 },
    { lat: 49.1509349, lng: 18.7397807 },
    { lat: 49.1512711, lng: 18.7400878 },
    { lat: 49.1513244, lng: 18.7404373 },
    { lat: 49.1513405, lng: 18.7410466 },
    { lat: 49.1516531, lng: 18.7410061 },
    { lat: 49.1517652, lng: 18.7409953 },
    { lat: 49.1519143, lng: 18.7409999 },
    { lat: 49.1521431, lng: 18.741042 },
    { lat: 49.1523953, lng: 18.7411417 },
    { lat: 49.1525064, lng: 18.7412374 },
    { lat: 49.1527057, lng: 18.741448 },
    { lat: 49.1527751, lng: 18.741504 },
    { lat: 49.1530746, lng: 18.7417504 },
    { lat: 49.1536044, lng: 18.7421345 },
    { lat: 49.1542866, lng: 18.741598 },
    { lat: 49.1546139, lng: 18.7415398 },
    { lat: 49.1550887, lng: 18.7417537 },
    { lat: 49.1552286, lng: 18.7417756 },
    { lat: 49.1559415, lng: 18.7419347 },
    { lat: 49.1574889, lng: 18.7422851 },
    { lat: 49.1577944, lng: 18.7436841 },
    { lat: 49.1574266, lng: 18.7437453 },
    { lat: 49.1574091, lng: 18.7452225 },
    { lat: 49.1589482, lng: 18.7451968 },
    { lat: 49.1600731, lng: 18.7451873 },
    { lat: 49.1607519, lng: 18.7450814 },
    { lat: 49.1606785, lng: 18.7448622 },
    { lat: 49.160818, lng: 18.744682 },
    { lat: 49.1608641, lng: 18.7444653 },
    { lat: 49.1608747, lng: 18.7443657 },
    { lat: 49.1609523, lng: 18.7441696 },
    { lat: 49.1610892, lng: 18.7439631 },
    { lat: 49.1611022, lng: 18.7438631 },
    { lat: 49.1610574, lng: 18.7436524 },
    { lat: 49.1610135, lng: 18.7433551 },
    { lat: 49.1610307, lng: 18.7431018 },
    { lat: 49.1609979, lng: 18.7428406 },
    { lat: 49.1609316, lng: 18.7423724 },
    { lat: 49.1610045, lng: 18.7418468 },
    { lat: 49.1609703, lng: 18.7416662 },
    { lat: 49.1610372, lng: 18.7414595 },
    { lat: 49.1610447, lng: 18.7412777 },
    { lat: 49.1608537, lng: 18.7412029 },
    { lat: 49.1608263, lng: 18.7410917 },
    { lat: 49.1608559, lng: 18.7409883 },
    { lat: 49.1608569, lng: 18.7409626 },
    { lat: 49.1607817, lng: 18.74069 },
    { lat: 49.160897, lng: 18.7402663 },
    { lat: 49.1609923, lng: 18.7401448 },
    { lat: 49.1610424, lng: 18.7400915 },
    { lat: 49.1610651, lng: 18.7399417 },
    { lat: 49.1611737, lng: 18.7397325 },
    { lat: 49.161186, lng: 18.7396247 },
    { lat: 49.1612444, lng: 18.7394904 },
    { lat: 49.1612864, lng: 18.7394302 },
    { lat: 49.1613286, lng: 18.7390313 },
    { lat: 49.1614082, lng: 18.7384606 },
    { lat: 49.1614248, lng: 18.7383487 },
    { lat: 49.1615177, lng: 18.7380116 },
    { lat: 49.1616278, lng: 18.7378984 },
    { lat: 49.1616705, lng: 18.737766 },
    { lat: 49.1618037, lng: 18.7376158 },
    { lat: 49.1617489, lng: 18.7373677 },
    { lat: 49.1616438, lng: 18.7373816 },
    { lat: 49.1616711, lng: 18.736978 },
    { lat: 49.1619088, lng: 18.7368885 },
    { lat: 49.1619107, lng: 18.7367928 },
    { lat: 49.16184, lng: 18.7367787 },
    { lat: 49.1617534, lng: 18.7366783 },
    { lat: 49.1616957, lng: 18.7365235 },
    { lat: 49.1617172, lng: 18.7364139 },
    { lat: 49.1616885, lng: 18.7362552 },
    { lat: 49.1617178, lng: 18.7361057 },
    { lat: 49.1617951, lng: 18.7359659 },
    { lat: 49.1616524, lng: 18.73561 },
    { lat: 49.1614968, lng: 18.73522 },
    { lat: 49.1614088, lng: 18.7350696 },
    { lat: 49.1613908, lng: 18.7349198 },
    { lat: 49.1613616, lng: 18.7348472 },
    { lat: 49.1612832, lng: 18.7346518 },
    { lat: 49.1611205, lng: 18.734387 },
    { lat: 49.1609066, lng: 18.7340077 },
    { lat: 49.1609154, lng: 18.7337256 },
    { lat: 49.1609102, lng: 18.733625 },
    { lat: 49.1608509, lng: 18.7335043 },
    { lat: 49.1607706, lng: 18.7335411 },
    { lat: 49.1606371, lng: 18.7331671 },
    { lat: 49.1605551, lng: 18.7330892 },
    { lat: 49.1606079, lng: 18.7326097 },
    { lat: 49.1606736, lng: 18.7325105 },
    { lat: 49.1607098, lng: 18.7322939 },
    { lat: 49.160764, lng: 18.7322743 },
    { lat: 49.1608285, lng: 18.7321732 },
    { lat: 49.1608218, lng: 18.7321495 },
    { lat: 49.16084, lng: 18.731939 },
    { lat: 49.1608571, lng: 18.7316879 },
    { lat: 49.1609036, lng: 18.7315594 },
    { lat: 49.1609243, lng: 18.7314412 },
    { lat: 49.1609826, lng: 18.7309805 },
    { lat: 49.1609595, lng: 18.7308022 },
    { lat: 49.1609256, lng: 18.7305414 },
    { lat: 49.1609762, lng: 18.7304066 },
    { lat: 49.1610094, lng: 18.7303103 },
    { lat: 49.1610935, lng: 18.7302543 },
    { lat: 49.1610952, lng: 18.7301831 },
    { lat: 49.1610264, lng: 18.7298366 },
    { lat: 49.1611189, lng: 18.7297064 },
    { lat: 49.1611409, lng: 18.7295275 },
    { lat: 49.1611146, lng: 18.7292598 },
    { lat: 49.1609741, lng: 18.7288373 },
    { lat: 49.1609517, lng: 18.7286725 },
    { lat: 49.1610581, lng: 18.7284621 },
    { lat: 49.1610837, lng: 18.7283062 },
    { lat: 49.1609799, lng: 18.7280032 },
    { lat: 49.1610297, lng: 18.7278343 },
    { lat: 49.1609961, lng: 18.7276445 },
    { lat: 49.1609869, lng: 18.7274922 },
    { lat: 49.160983, lng: 18.7272912 },
    { lat: 49.1612151, lng: 18.7270032 },
    { lat: 49.1612232, lng: 18.7269875 },
    { lat: 49.1612876, lng: 18.7268635 },
    { lat: 49.161303, lng: 18.7267874 },
    { lat: 49.1614349, lng: 18.7267025 },
    { lat: 49.1615515, lng: 18.7266451 },
    { lat: 49.161656, lng: 18.7262104 },
    { lat: 49.1616584, lng: 18.7259302 },
    { lat: 49.1616298, lng: 18.7257546 },
    { lat: 49.1606733, lng: 18.7258932 },
    { lat: 49.1604401, lng: 18.7259461 },
    { lat: 49.1598592, lng: 18.7261556 },
    { lat: 49.1589199, lng: 18.7265036 },
    { lat: 49.1588214, lng: 18.7264324 },
    { lat: 49.1586497, lng: 18.7257396 },
    { lat: 49.1584271, lng: 18.7250638 },
    { lat: 49.1579665, lng: 18.724301 },
    { lat: 49.1577877, lng: 18.723924 },
    { lat: 49.157534, lng: 18.7231288 },
    { lat: 49.1575001, lng: 18.7229748 },
    { lat: 49.1574854, lng: 18.7229095 },
    { lat: 49.157605, lng: 18.7229551 },
    { lat: 49.1583319, lng: 18.7233979 },
    { lat: 49.1583553, lng: 18.7203398 },
    { lat: 49.1574454, lng: 18.7208384 },
    { lat: 49.1570803, lng: 18.7210585 },
    { lat: 49.1566183, lng: 18.7213227 },
    { lat: 49.1558655, lng: 18.7217604 },
    { lat: 49.1559326, lng: 18.72186 },
    { lat: 49.1558769, lng: 18.721955 },
    { lat: 49.1558183, lng: 18.7218526 },
    { lat: 49.1558134, lng: 18.7218439 },
    { lat: 49.1557896, lng: 18.7217776 },
    { lat: 49.1557791, lng: 18.7217493 },
    { lat: 49.1557686, lng: 18.7217196 },
    { lat: 49.155753, lng: 18.7216797 },
    { lat: 49.1557214, lng: 18.721595 },
    { lat: 49.1560675, lng: 18.7208592 },
    { lat: 49.1571063, lng: 18.7196851 },
    { lat: 49.1569203, lng: 18.7192124 },
    { lat: 49.1567427, lng: 18.7192896 },
    { lat: 49.1566506, lng: 18.7193192 },
    { lat: 49.156453, lng: 18.7192053 },
    { lat: 49.1562519, lng: 18.7190479 },
    { lat: 49.1560246, lng: 18.7188184 },
    { lat: 49.1558156, lng: 18.7185773 },
    { lat: 49.1555764, lng: 18.7184015 },
    { lat: 49.1551577, lng: 18.718181 },
    { lat: 49.1548712, lng: 18.7180315 },
    { lat: 49.1545812, lng: 18.7178994 },
    { lat: 49.15445, lng: 18.7178261 },
    { lat: 49.1541605, lng: 18.7175966 },
    { lat: 49.1538301, lng: 18.7173786 },
    { lat: 49.1537274, lng: 18.7172907 },
    { lat: 49.1535575, lng: 18.7171295 },
    { lat: 49.1534177, lng: 18.7170223 },
    { lat: 49.1532522, lng: 18.7169178 },
    { lat: 49.1531224, lng: 18.716853 },
    { lat: 49.1528787, lng: 18.7166997 },
    { lat: 49.1526419, lng: 18.7165279 },
    { lat: 49.152515, lng: 18.7164112 },
    { lat: 49.1524357, lng: 18.7163399 },
    { lat: 49.1522904, lng: 18.716171 },
    { lat: 49.1521396, lng: 18.7160251 },
    { lat: 49.1519542, lng: 18.7158874 },
    { lat: 49.1518709, lng: 18.7158043 },
    { lat: 49.1517591, lng: 18.7156742 },
    { lat: 49.1516514, lng: 18.7155127 },
    { lat: 49.15148, lng: 18.714932 },
    { lat: 49.1514261, lng: 18.7145704 },
    { lat: 49.1513727, lng: 18.7144134 },
    { lat: 49.1511653, lng: 18.7139887 },
    { lat: 49.150948, lng: 18.7136929 },
    { lat: 49.1508232, lng: 18.7128212 },
    { lat: 49.1505401, lng: 18.7119393 },
    { lat: 49.1504729, lng: 18.7114799 },
    { lat: 49.1504471, lng: 18.7110061 },
    { lat: 49.1505763, lng: 18.7103761 },
    { lat: 49.1503733, lng: 18.7103545 },
    { lat: 49.1495642, lng: 18.7098968 },
    { lat: 49.1487202, lng: 18.7090079 },
    { lat: 49.1480376, lng: 18.7083586 },
    { lat: 49.1473975, lng: 18.7076581 },
    { lat: 49.1469859, lng: 18.7070578 },
    { lat: 49.1468261, lng: 18.7061235 },
    { lat: 49.1465198, lng: 18.7050583 },
    { lat: 49.1464108, lng: 18.7038232 },
  ],
  Strečno: [
    { lat: 49.145675, lng: 18.848505 },
    { lat: 49.146119, lng: 18.848581 },
    { lat: 49.146369, lng: 18.848837 },
    { lat: 49.14676, lng: 18.84968 },
    { lat: 49.147131, lng: 18.850487 },
    { lat: 49.147306, lng: 18.850844 },
    { lat: 49.147836, lng: 18.851922 },
    { lat: 49.14807, lng: 18.852397 },
    { lat: 49.148255, lng: 18.852773 },
    { lat: 49.14827, lng: 18.852804 },
    { lat: 49.148664, lng: 18.853612 },
    { lat: 49.148937, lng: 18.85416 },
    { lat: 49.149155, lng: 18.854597 },
    { lat: 49.149261, lng: 18.854815 },
    { lat: 49.149461, lng: 18.855231 },
    { lat: 49.149494, lng: 18.855299 },
    { lat: 49.149561, lng: 18.855586 },
    { lat: 49.149563, lng: 18.855669 },
    { lat: 49.149553, lng: 18.855862 },
    { lat: 49.149506, lng: 18.856833 },
    { lat: 49.149619, lng: 18.857265 },
    { lat: 49.149655, lng: 18.857733 },
    { lat: 49.149674, lng: 18.857869 },
    { lat: 49.14978, lng: 18.858597 },
    { lat: 49.149812, lng: 18.859016 },
    { lat: 49.149914, lng: 18.859957 },
    { lat: 49.14999, lng: 18.860587 },
    { lat: 49.150167, lng: 18.861471 },
    { lat: 49.150234, lng: 18.862133 },
    { lat: 49.150217, lng: 18.862429 },
    { lat: 49.150206, lng: 18.862645 },
    { lat: 49.150247, lng: 18.86319 },
    { lat: 49.150344, lng: 18.863667 },
    { lat: 49.150397, lng: 18.863926 },
    { lat: 49.150471, lng: 18.864292 },
    { lat: 49.150549, lng: 18.864675 },
    { lat: 49.150398, lng: 18.865223 },
    { lat: 49.150375, lng: 18.865686 },
    { lat: 49.150298, lng: 18.865802 },
    { lat: 49.15011, lng: 18.86626 },
    { lat: 49.149959, lng: 18.866606 },
    { lat: 49.149846, lng: 18.866919 },
    { lat: 49.149609, lng: 18.867316 },
    { lat: 49.149336, lng: 18.867859 },
    { lat: 49.148966, lng: 18.868566 },
    { lat: 49.1485, lng: 18.869718 },
    { lat: 49.148345, lng: 18.870253 },
    { lat: 49.148409, lng: 18.870705 },
    { lat: 49.148448, lng: 18.871312 },
    { lat: 49.148479, lng: 18.871892 },
    { lat: 49.148557, lng: 18.873167 },
    { lat: 49.148565, lng: 18.874245 },
    { lat: 49.148673, lng: 18.874893 },
    { lat: 49.148731, lng: 18.875247 },
    { lat: 49.148836, lng: 18.875976 },
    { lat: 49.148929, lng: 18.876731 },
    { lat: 49.149183, lng: 18.878749 },
    { lat: 49.149326, lng: 18.879246 },
    { lat: 49.149677, lng: 18.880128 },
    { lat: 49.15034, lng: 18.88122 },
    { lat: 49.150355, lng: 18.881934 },
    { lat: 49.150347, lng: 18.882259 },
    { lat: 49.150275, lng: 18.882463 },
    { lat: 49.150087, lng: 18.883332 },
    { lat: 49.150029, lng: 18.883866 },
    { lat: 49.150029, lng: 18.884108 },
    { lat: 49.15013, lng: 18.884658 },
    { lat: 49.150255, lng: 18.884897 },
    { lat: 49.150359, lng: 18.885164 },
    { lat: 49.150417, lng: 18.885341 },
    { lat: 49.150466, lng: 18.885409 },
    { lat: 49.150533, lng: 18.885876 },
    { lat: 49.15054, lng: 18.885933 },
    { lat: 49.15058, lng: 18.886188 },
    { lat: 49.15064, lng: 18.886464 },
    { lat: 49.150678, lng: 18.886603 },
    { lat: 49.150708, lng: 18.88669 },
    { lat: 49.150762, lng: 18.88694 },
    { lat: 49.150817, lng: 18.887013 },
    { lat: 49.150909, lng: 18.887377 },
    { lat: 49.150928, lng: 18.887414 },
    { lat: 49.150956, lng: 18.887493 },
    { lat: 49.1511, lng: 18.887811 },
    { lat: 49.151187, lng: 18.887992 },
    { lat: 49.151211, lng: 18.888045 },
    { lat: 49.151496, lng: 18.8883932 },
    { lat: 49.152228, lng: 18.88745 },
    { lat: 49.152668, lng: 18.887001 },
    { lat: 49.1533586, lng: 18.8859767 },
    { lat: 49.1539545, lng: 18.8856046 },
    { lat: 49.1544009, lng: 18.8855376 },
    { lat: 49.1551189, lng: 18.8855554 },
    { lat: 49.1553893, lng: 18.8855954 },
    { lat: 49.1560453, lng: 18.8857884 },
    { lat: 49.15683, lng: 18.8861221 },
    { lat: 49.1570287, lng: 18.8862559 },
    { lat: 49.1580621, lng: 18.8871315 },
    { lat: 49.1582277, lng: 18.8872847 },
    { lat: 49.1587182, lng: 18.8875057 },
    { lat: 49.1589932, lng: 18.8877004 },
    { lat: 49.1598875, lng: 18.8877294 },
    { lat: 49.1601499, lng: 18.8876586 },
    { lat: 49.1604937, lng: 18.8873454 },
    { lat: 49.1605431, lng: 18.8872106 },
    { lat: 49.1610959, lng: 18.8863305 },
    { lat: 49.1621951, lng: 18.8849175 },
    { lat: 49.1623044, lng: 18.8847395 },
    { lat: 49.1624314, lng: 18.8845328 },
    { lat: 49.1625595, lng: 18.8843259 },
    { lat: 49.1625699, lng: 18.8843077 },
    { lat: 49.1627953, lng: 18.8834473 },
    { lat: 49.1625891, lng: 18.8819688 },
    { lat: 49.1627875, lng: 18.8812823 },
    { lat: 49.163079, lng: 18.8807235 },
    { lat: 49.1636207, lng: 18.8800705 },
    { lat: 49.1640365, lng: 18.8798009 },
    { lat: 49.1643765, lng: 18.8796816 },
    { lat: 49.1648252, lng: 18.8795062 },
    { lat: 49.1652032, lng: 18.8794652 },
    { lat: 49.1652768, lng: 18.8794954 },
    { lat: 49.1656493, lng: 18.8798773 },
    { lat: 49.1658027, lng: 18.8801228 },
    { lat: 49.1658751, lng: 18.8802371 },
    { lat: 49.1659121, lng: 18.8803876 },
    { lat: 49.1659582, lng: 18.8808923 },
    { lat: 49.1658817, lng: 18.8817992 },
    { lat: 49.1657689, lng: 18.8829893 },
    { lat: 49.1655491, lng: 18.8847372 },
    { lat: 49.1652721, lng: 18.885628 },
    { lat: 49.1654278, lng: 18.8877953 },
    { lat: 49.1656677, lng: 18.888718 },
    { lat: 49.1666861, lng: 18.890957 },
    { lat: 49.1671495, lng: 18.8917517 },
    { lat: 49.1677456, lng: 18.892697 },
    { lat: 49.1679474, lng: 18.8929624 },
    { lat: 49.1685224, lng: 18.8936696 },
    { lat: 49.1689822, lng: 18.8941072 },
    { lat: 49.1692736, lng: 18.8943826 },
    { lat: 49.1702905, lng: 18.8951577 },
    { lat: 49.1714841, lng: 18.8960767 },
    { lat: 49.1721217, lng: 18.8964465 },
    { lat: 49.1729597, lng: 18.8968023 },
    { lat: 49.1734441, lng: 18.8969056 },
    { lat: 49.1739856, lng: 18.8968468 },
    { lat: 49.1745626, lng: 18.8965972 },
    { lat: 49.1746492, lng: 18.8965076 },
    { lat: 49.1754995, lng: 18.8955153 },
    { lat: 49.1759193, lng: 18.8949668 },
    { lat: 49.1762018, lng: 18.894316 },
    { lat: 49.1762967, lng: 18.8940089 },
    { lat: 49.1764142, lng: 18.8934829 },
    { lat: 49.1765442, lng: 18.8926499 },
    { lat: 49.1766564, lng: 18.8918236 },
    { lat: 49.1765555, lng: 18.8909246 },
    { lat: 49.1763973, lng: 18.8901433 },
    { lat: 49.1762463, lng: 18.8894157 },
    { lat: 49.1759763, lng: 18.8887861 },
    { lat: 49.1752705, lng: 18.8875946 },
    { lat: 49.1750284, lng: 18.8871793 },
    { lat: 49.1738741, lng: 18.8855928 },
    { lat: 49.1733317, lng: 18.8848202 },
    { lat: 49.173025, lng: 18.8841118 },
    { lat: 49.1728186, lng: 18.8838448 },
    { lat: 49.1726452, lng: 18.8835865 },
    { lat: 49.1722687, lng: 18.8826168 },
    { lat: 49.1720119, lng: 18.8819141 },
    { lat: 49.1719463, lng: 18.8817349 },
    { lat: 49.1718877, lng: 18.8815768 },
    { lat: 49.1714369, lng: 18.8803479 },
    { lat: 49.1710684, lng: 18.8790729 },
    { lat: 49.1707095, lng: 18.8770787 },
    { lat: 49.1706785, lng: 18.8768013 },
    { lat: 49.1706366, lng: 18.8764254 },
    { lat: 49.1705556, lng: 18.8750622 },
    { lat: 49.170538, lng: 18.8727323 },
    { lat: 49.1705623, lng: 18.8710038 },
    { lat: 49.1706397, lng: 18.8702264 },
    { lat: 49.1708486, lng: 18.8682943 },
    { lat: 49.1711196, lng: 18.8674228 },
    { lat: 49.1716835, lng: 18.8660368 },
    { lat: 49.1722801, lng: 18.8647172 },
    { lat: 49.1725204, lng: 18.8643451 },
    { lat: 49.1728304, lng: 18.864146 },
    { lat: 49.1734744, lng: 18.8638639 },
    { lat: 49.174106, lng: 18.8638697 },
    { lat: 49.1745116, lng: 18.863906 },
    { lat: 49.1745873, lng: 18.8640004 },
    { lat: 49.1754963, lng: 18.8641591 },
    { lat: 49.1758938, lng: 18.8641939 },
    { lat: 49.1767132, lng: 18.8642775 },
    { lat: 49.1770034, lng: 18.8643695 },
    { lat: 49.1771463, lng: 18.8644115 },
    { lat: 49.1772879, lng: 18.8644447 },
    { lat: 49.1774898, lng: 18.8644888 },
    { lat: 49.1777894, lng: 18.8644841 },
    { lat: 49.1781388, lng: 18.864548 },
    { lat: 49.1784311, lng: 18.8645957 },
    { lat: 49.178894, lng: 18.8646991 },
    { lat: 49.1795037, lng: 18.8648672 },
    { lat: 49.1795245, lng: 18.8648774 },
    { lat: 49.1798339, lng: 18.8650409 },
    { lat: 49.1805073, lng: 18.8654007 },
    { lat: 49.1810187, lng: 18.8656526 },
    { lat: 49.1814538, lng: 18.8658959 },
    { lat: 49.1815845, lng: 18.8659618 },
    { lat: 49.1816696, lng: 18.8659902 },
    { lat: 49.1817917, lng: 18.8660203 },
    { lat: 49.1819924, lng: 18.8660656 },
    { lat: 49.1822491, lng: 18.8661226 },
    { lat: 49.1825027, lng: 18.8661897 },
    { lat: 49.1827452, lng: 18.8663666 },
    { lat: 49.1830048, lng: 18.8665498 },
    { lat: 49.183108, lng: 18.8666276 },
    { lat: 49.1831482, lng: 18.866661 },
    { lat: 49.1836286, lng: 18.8670165 },
    { lat: 49.1840004, lng: 18.8672965 },
    { lat: 49.1845717, lng: 18.8679526 },
    { lat: 49.1848673, lng: 18.8683081 },
    { lat: 49.1851085, lng: 18.8686127 },
    { lat: 49.1855213, lng: 18.8691212 },
    { lat: 49.1858621, lng: 18.8695261 },
    { lat: 49.186195, lng: 18.8698865 },
    { lat: 49.1863094, lng: 18.8700066 },
    { lat: 49.1863795, lng: 18.8700985 },
    { lat: 49.1864782, lng: 18.8702403 },
    { lat: 49.1865416, lng: 18.8703102 },
    { lat: 49.1866119, lng: 18.8703873 },
    { lat: 49.1869642, lng: 18.8707252 },
    { lat: 49.1872595, lng: 18.8710112 },
    { lat: 49.1877682, lng: 18.8712383 },
    { lat: 49.1878958, lng: 18.8713075 },
    { lat: 49.1880191, lng: 18.8713811 },
    { lat: 49.1883918, lng: 18.8715685 },
    { lat: 49.1884806, lng: 18.871586 },
    { lat: 49.1890461, lng: 18.8717544 },
    { lat: 49.1893566, lng: 18.8718487 },
    { lat: 49.189794, lng: 18.871979 },
    { lat: 49.1901482, lng: 18.8720486 },
    { lat: 49.1905318, lng: 18.8720559 },
    { lat: 49.1909216, lng: 18.8720517 },
    { lat: 49.1922357, lng: 18.8714886 },
    { lat: 49.1928559, lng: 18.8704537 },
    { lat: 49.193115, lng: 18.8697635 },
    { lat: 49.193291, lng: 18.8692184 },
    { lat: 49.1933916, lng: 18.8688708 },
    { lat: 49.1935587, lng: 18.8680593 },
    { lat: 49.1936318, lng: 18.8671302 },
    { lat: 49.1934523, lng: 18.8659991 },
    { lat: 49.1934697, lng: 18.8653928 },
    { lat: 49.1935281, lng: 18.8650387 },
    { lat: 49.1936154, lng: 18.8644973 },
    { lat: 49.1937189, lng: 18.863658 },
    { lat: 49.1937997, lng: 18.8630117 },
    { lat: 49.1940027, lng: 18.8618692 },
    { lat: 49.1942462, lng: 18.8606542 },
    { lat: 49.1943768, lng: 18.859574 },
    { lat: 49.1944469, lng: 18.8585855 },
    { lat: 49.1944911, lng: 18.8578862 },
    { lat: 49.1944527, lng: 18.8566755 },
    { lat: 49.1944484, lng: 18.8563058 },
    { lat: 49.1944253, lng: 18.8558338 },
    { lat: 49.1944431, lng: 18.855544 },
    { lat: 49.194409, lng: 18.8549442 },
    { lat: 49.1943056, lng: 18.8543794 },
    { lat: 49.1942451, lng: 18.8538394 },
    { lat: 49.1941802, lng: 18.8533842 },
    { lat: 49.1940978, lng: 18.8528147 },
    { lat: 49.1939796, lng: 18.852137 },
    { lat: 49.1938519, lng: 18.8516231 },
    { lat: 49.1937609, lng: 18.8511033 },
    { lat: 49.1936725, lng: 18.8504801 },
    { lat: 49.1936356, lng: 18.8499128 },
    { lat: 49.1935763, lng: 18.8492179 },
    { lat: 49.1935241, lng: 18.8481596 },
    { lat: 49.1935286, lng: 18.8478289 },
    { lat: 49.19353, lng: 18.8474606 },
    { lat: 49.1935351, lng: 18.847013 },
    { lat: 49.1935316, lng: 18.8464157 },
    { lat: 49.1935351, lng: 18.8459124 },
    { lat: 49.1935293, lng: 18.8454041 },
    { lat: 49.1935605, lng: 18.8448527 },
    { lat: 49.1935772, lng: 18.8444981 },
    { lat: 49.1935708, lng: 18.8441928 },
    { lat: 49.1935205, lng: 18.8437886 },
    { lat: 49.1934343, lng: 18.8433386 },
    { lat: 49.19344, lng: 18.8431645 },
    { lat: 49.1934854, lng: 18.8427286 },
    { lat: 49.1935033, lng: 18.842398 },
    { lat: 49.1935041, lng: 18.8419992 },
    { lat: 49.1934868, lng: 18.8417039 },
    { lat: 49.1935313, lng: 18.8410158 },
    { lat: 49.193572, lng: 18.8403609 },
    { lat: 49.1931802, lng: 18.8402809 },
    { lat: 49.1929673, lng: 18.8402429 },
    { lat: 49.1920599, lng: 18.8372863 },
    { lat: 49.1920531, lng: 18.8372644 },
    { lat: 49.1918165, lng: 18.8365014 },
    { lat: 49.1917783, lng: 18.8364386 },
    { lat: 49.191599, lng: 18.8364391 },
    { lat: 49.1914296, lng: 18.8364104 },
    { lat: 49.191291, lng: 18.8363016 },
    { lat: 49.1910919, lng: 18.8359819 },
    { lat: 49.19094, lng: 18.8357895 },
    { lat: 49.1907007, lng: 18.8356297 },
    { lat: 49.190491, lng: 18.8356786 },
    { lat: 49.190339, lng: 18.8354614 },
    { lat: 49.1901457, lng: 18.8356716 },
    { lat: 49.1899522, lng: 18.8357449 },
    { lat: 49.1897217, lng: 18.8356911 },
    { lat: 49.1895284, lng: 18.8356353 },
    { lat: 49.189374, lng: 18.8352893 },
    { lat: 49.1893329, lng: 18.8350379 },
    { lat: 49.1890748, lng: 18.8349762 },
    { lat: 49.188816, lng: 18.8350703 },
    { lat: 49.1886987, lng: 18.835027 },
    { lat: 49.1885582, lng: 18.8349044 },
    { lat: 49.1883567, lng: 18.8349321 },
    { lat: 49.1882779, lng: 18.834894 },
    { lat: 49.1880362, lng: 18.8349489 },
    { lat: 49.187975, lng: 18.8349439 },
    { lat: 49.1878528, lng: 18.8349341 },
    { lat: 49.1876998, lng: 18.8348987 },
    { lat: 49.1876588, lng: 18.834604 },
    { lat: 49.187635, lng: 18.8344065 },
    { lat: 49.1875433, lng: 18.8343753 },
    { lat: 49.1873202, lng: 18.8346623 },
    { lat: 49.1870519, lng: 18.8347737 },
    { lat: 49.1868705, lng: 18.8347994 },
    { lat: 49.1866278, lng: 18.8345964 },
    { lat: 49.1863076, lng: 18.8345078 },
    { lat: 49.1860532, lng: 18.8346684 },
    { lat: 49.1859455, lng: 18.8347902 },
    { lat: 49.1856806, lng: 18.8346367 },
    { lat: 49.1854236, lng: 18.8344631 },
    { lat: 49.1852158, lng: 18.834218 },
    { lat: 49.1850605, lng: 18.834047 },
    { lat: 49.1849131, lng: 18.8337676 },
    { lat: 49.1849035, lng: 18.8338009 },
    { lat: 49.1848978, lng: 18.8338232 },
    { lat: 49.1849163, lng: 18.8342477 },
    { lat: 49.1850599, lng: 18.8344951 },
    { lat: 49.1852352, lng: 18.8347927 },
    { lat: 49.1853713, lng: 18.8351376 },
    { lat: 49.1855074, lng: 18.835499 },
    { lat: 49.1855825, lng: 18.8357337 },
    { lat: 49.1856027, lng: 18.8357958 },
    { lat: 49.1856362, lng: 18.8358999 },
    { lat: 49.1857495, lng: 18.8362843 },
    { lat: 49.1858146, lng: 18.8364737 },
    { lat: 49.1857756, lng: 18.8364834 },
    { lat: 49.18574, lng: 18.8364931 },
    { lat: 49.185691, lng: 18.8365061 },
    { lat: 49.1856432, lng: 18.8365187 },
    { lat: 49.1845464, lng: 18.8368068 },
    { lat: 49.1835246, lng: 18.8371981 },
    { lat: 49.1836057, lng: 18.8382282 },
    { lat: 49.1820286, lng: 18.8384711 },
    { lat: 49.1815141, lng: 18.8384231 },
    { lat: 49.1808702, lng: 18.8382248 },
    { lat: 49.180868, lng: 18.8382694 },
    { lat: 49.1808634, lng: 18.8383118 },
    { lat: 49.1808605, lng: 18.8383435 },
    { lat: 49.1808568, lng: 18.8383814 },
    { lat: 49.1807536, lng: 18.8395961 },
    { lat: 49.1807364, lng: 18.8397892 },
    { lat: 49.1807234, lng: 18.8399489 },
    { lat: 49.1802702, lng: 18.8400183 },
    { lat: 49.1798946, lng: 18.840322 },
    { lat: 49.1798029, lng: 18.8404923 },
    { lat: 49.1794758, lng: 18.840641 },
    { lat: 49.1792649, lng: 18.8409463 },
    { lat: 49.1791679, lng: 18.8411885 },
    { lat: 49.1791559, lng: 18.8412386 },
    { lat: 49.1772589, lng: 18.8410547 },
    { lat: 49.1766548, lng: 18.8417598 },
    { lat: 49.1759159, lng: 18.8427402 },
    { lat: 49.1754411, lng: 18.8434827 },
    { lat: 49.1751212, lng: 18.8439707 },
    { lat: 49.1744944, lng: 18.8447426 },
    { lat: 49.1741517, lng: 18.845095 },
    { lat: 49.17383, lng: 18.8447327 },
    { lat: 49.1732246, lng: 18.8442768 },
    { lat: 49.1726678, lng: 18.8436864 },
    { lat: 49.1720822, lng: 18.8429104 },
    { lat: 49.1719329, lng: 18.8431225 },
    { lat: 49.1716207, lng: 18.8436088 },
    { lat: 49.1714902, lng: 18.84381 },
    { lat: 49.171294, lng: 18.8441149 },
    { lat: 49.1712022, lng: 18.8442592 },
    { lat: 49.1710984, lng: 18.8444384 },
    { lat: 49.1708166, lng: 18.8449852 },
    { lat: 49.1707233, lng: 18.8451657 },
    { lat: 49.1703654, lng: 18.8457267 },
    { lat: 49.1699675, lng: 18.8464535 },
    { lat: 49.1695998, lng: 18.8467374 },
    { lat: 49.1693987, lng: 18.847059 },
    { lat: 49.169064, lng: 18.8475422 },
    { lat: 49.1685717, lng: 18.8481001 },
    { lat: 49.1683502, lng: 18.8479154 },
    { lat: 49.167862, lng: 18.8475003 },
    { lat: 49.1675325, lng: 18.8474683 },
    { lat: 49.16708, lng: 18.8476154 },
    { lat: 49.1667167, lng: 18.8477229 },
    { lat: 49.1664437, lng: 18.8478078 },
    { lat: 49.1662483, lng: 18.847799 },
    { lat: 49.1660077, lng: 18.8476412 },
    { lat: 49.1658047, lng: 18.8476175 },
    { lat: 49.1654429, lng: 18.8473768 },
    { lat: 49.165072, lng: 18.8474424 },
    { lat: 49.1646955, lng: 18.8475767 },
    { lat: 49.16433, lng: 18.8476607 },
    { lat: 49.1640326, lng: 18.8479741 },
    { lat: 49.1638071, lng: 18.8482168 },
    { lat: 49.1633269, lng: 18.8483293 },
    { lat: 49.163042, lng: 18.8481825 },
    { lat: 49.1628026, lng: 18.847956 },
    { lat: 49.162312, lng: 18.8476644 },
    { lat: 49.1618447, lng: 18.847307 },
    { lat: 49.1615295, lng: 18.8469426 },
    { lat: 49.1614318, lng: 18.8467053 },
    { lat: 49.1612426, lng: 18.8465377 },
    { lat: 49.1603423, lng: 18.8457342 },
    { lat: 49.1601893, lng: 18.8455439 },
    { lat: 49.1599227, lng: 18.845316 },
    { lat: 49.159633, lng: 18.8449662 },
    { lat: 49.1593293, lng: 18.8450894 },
    { lat: 49.1590035, lng: 18.8451348 },
    { lat: 49.1587579, lng: 18.8449638 },
    { lat: 49.1585346, lng: 18.84481 },
    { lat: 49.1583459, lng: 18.8447159 },
    { lat: 49.1582327, lng: 18.8446595 },
    { lat: 49.1578906, lng: 18.8448074 },
    { lat: 49.1575291, lng: 18.8448578 },
    { lat: 49.1569881, lng: 18.8445781 },
    { lat: 49.1567802, lng: 18.8444826 },
    { lat: 49.1566294, lng: 18.8444869 },
    { lat: 49.1562884, lng: 18.8444535 },
    { lat: 49.1559884, lng: 18.8443896 },
    { lat: 49.155446, lng: 18.8442339 },
    { lat: 49.1546491, lng: 18.8439736 },
    { lat: 49.15384, lng: 18.8447303 },
    { lat: 49.153151, lng: 18.8441783 },
    { lat: 49.1522969, lng: 18.8439486 },
    { lat: 49.1516155, lng: 18.8436763 },
    { lat: 49.1508161, lng: 18.8438083 },
    { lat: 49.1502966, lng: 18.8439698 },
    { lat: 49.1502635, lng: 18.8439834 },
    { lat: 49.1502095, lng: 18.8440093 },
    { lat: 49.1497419, lng: 18.8442345 },
    { lat: 49.1491404, lng: 18.8442898 },
    { lat: 49.1485317, lng: 18.8447534 },
    { lat: 49.1483409, lng: 18.845141 },
    { lat: 49.1479649, lng: 18.8458786 },
    { lat: 49.1475121, lng: 18.8464333 },
    { lat: 49.1470709, lng: 18.846978 },
    { lat: 49.1467139, lng: 18.8474013 },
    { lat: 49.1462686, lng: 18.8478846 },
    { lat: 49.1459338, lng: 18.8482807 },
    { lat: 49.145675, lng: 18.848505 },
  ],
  Stráňavy: [
    { lat: 49.1630267, lng: 18.8153848 },
    { lat: 49.1627189, lng: 18.8155126 },
    { lat: 49.1626917, lng: 18.8155119 },
    { lat: 49.1625731, lng: 18.8154444 },
    { lat: 49.1625531, lng: 18.8154504 },
    { lat: 49.1625338, lng: 18.815488 },
    { lat: 49.1625238, lng: 18.8155382 },
    { lat: 49.1624088, lng: 18.8156262 },
    { lat: 49.162393, lng: 18.815644 },
    { lat: 49.1622781, lng: 18.8158858 },
    { lat: 49.1622652, lng: 18.8159011 },
    { lat: 49.1622443, lng: 18.8159083 },
    { lat: 49.1621851, lng: 18.815879 },
    { lat: 49.1621696, lng: 18.8158852 },
    { lat: 49.1620359, lng: 18.8160684 },
    { lat: 49.1618828, lng: 18.8162003 },
    { lat: 49.1615434, lng: 18.8165963 },
    { lat: 49.1615071, lng: 18.8166122 },
    { lat: 49.1613557, lng: 18.8165623 },
    { lat: 49.1611001, lng: 18.8165688 },
    { lat: 49.1610879, lng: 18.8165743 },
    { lat: 49.1610516, lng: 18.8166132 },
    { lat: 49.1610266, lng: 18.8166742 },
    { lat: 49.1610127, lng: 18.8166881 },
    { lat: 49.1609706, lng: 18.816682 },
    { lat: 49.1609393, lng: 18.816707 },
    { lat: 49.1603225, lng: 18.8170347 },
    { lat: 49.1600876, lng: 18.8171173 },
    { lat: 49.1600776, lng: 18.8171196 },
    { lat: 49.1600095, lng: 18.8173696 },
    { lat: 49.1599456, lng: 18.8175094 },
    { lat: 49.1599336, lng: 18.8175202 },
    { lat: 49.1597872, lng: 18.8175213 },
    { lat: 49.1597769, lng: 18.8175192 },
    { lat: 49.1597677, lng: 18.8175373 },
    { lat: 49.1596765, lng: 18.8178671 },
    { lat: 49.1595799, lng: 18.8180271 },
    { lat: 49.1595632, lng: 18.8180291 },
    { lat: 49.1594786, lng: 18.8180288 },
    { lat: 49.159262, lng: 18.8181348 },
    { lat: 49.1589274, lng: 18.8180993 },
    { lat: 49.1588887, lng: 18.8180903 },
    { lat: 49.1586185, lng: 18.8178586 },
    { lat: 49.1585668, lng: 18.8178195 },
    { lat: 49.1584829, lng: 18.8177899 },
    { lat: 49.1583952, lng: 18.8177936 },
    { lat: 49.1582949, lng: 18.8178838 },
    { lat: 49.1579937, lng: 18.8182046 },
    { lat: 49.1577542, lng: 18.8185046 },
    { lat: 49.1575886, lng: 18.8188793 },
    { lat: 49.1575703, lng: 18.8189147 },
    { lat: 49.1572425, lng: 18.8190308 },
    { lat: 49.1571624, lng: 18.819031 },
    { lat: 49.1570931, lng: 18.8190444 },
    { lat: 49.1569091, lng: 18.8191695 },
    { lat: 49.1565785, lng: 18.8192088 },
    { lat: 49.1562144, lng: 18.819255 },
    { lat: 49.1552851, lng: 18.8194685 },
    { lat: 49.1548794, lng: 18.8196065 },
    { lat: 49.1547648, lng: 18.8195695 },
    { lat: 49.1543387, lng: 18.8200065 },
    { lat: 49.153841, lng: 18.8205687 },
    { lat: 49.1535102, lng: 18.8213648 },
    { lat: 49.1529771, lng: 18.8214133 },
    { lat: 49.1522249, lng: 18.8219376 },
    { lat: 49.1519224, lng: 18.8223255 },
    { lat: 49.1515946, lng: 18.8229181 },
    { lat: 49.1511632, lng: 18.8230156 },
    { lat: 49.1508489, lng: 18.8234992 },
    { lat: 49.1503849, lng: 18.824013 },
    { lat: 49.1502076, lng: 18.8245555 },
    { lat: 49.1499687, lng: 18.82506 },
    { lat: 49.1499674, lng: 18.8252319 },
    { lat: 49.1498793, lng: 18.8255789 },
    { lat: 49.1496422, lng: 18.8261638 },
    { lat: 49.1491798, lng: 18.8266861 },
    { lat: 49.1487924, lng: 18.8273059 },
    { lat: 49.1482382, lng: 18.827381 },
    { lat: 49.1480633, lng: 18.8272478 },
    { lat: 49.1480573, lng: 18.8271772 },
    { lat: 49.1480017, lng: 18.8271943 },
    { lat: 49.1471759, lng: 18.8273995 },
    { lat: 49.1470923, lng: 18.8273768 },
    { lat: 49.1470402, lng: 18.827481 },
    { lat: 49.1464127, lng: 18.8276228 },
    { lat: 49.1461773, lng: 18.8275621 },
    { lat: 49.1456594, lng: 18.8278366 },
    { lat: 49.1453783, lng: 18.828095 },
    { lat: 49.1452371, lng: 18.828179 },
    { lat: 49.1445901, lng: 18.8287924 },
    { lat: 49.1440821, lng: 18.829374 },
    { lat: 49.1437746, lng: 18.8295963 },
    { lat: 49.1433283, lng: 18.8303438 },
    { lat: 49.1432622, lng: 18.8305052 },
    { lat: 49.1429566, lng: 18.8309433 },
    { lat: 49.1426566, lng: 18.8311136 },
    { lat: 49.1423886, lng: 18.8312094 },
    { lat: 49.1422669, lng: 18.8311674 },
    { lat: 49.1418776, lng: 18.8309209 },
    { lat: 49.1415235, lng: 18.8307267 },
    { lat: 49.1411458, lng: 18.8300902 },
    { lat: 49.1409702, lng: 18.8299658 },
    { lat: 49.1407391, lng: 18.8296589 },
    { lat: 49.1405728, lng: 18.8292557 },
    { lat: 49.1405788, lng: 18.8289319 },
    { lat: 49.140708, lng: 18.828337 },
    { lat: 49.1405613, lng: 18.8279617 },
    { lat: 49.1403162, lng: 18.8276669 },
    { lat: 49.1401897, lng: 18.8275546 },
    { lat: 49.1398843, lng: 18.8275152 },
    { lat: 49.1395449, lng: 18.8275822 },
    { lat: 49.1391033, lng: 18.827656 },
    { lat: 49.1385357, lng: 18.8277769 },
    { lat: 49.1373746, lng: 18.8274097 },
    { lat: 49.1371982, lng: 18.827375 },
    { lat: 49.1366181, lng: 18.8275223 },
    { lat: 49.1356302, lng: 18.8275398 },
    { lat: 49.1339132, lng: 18.8275857 },
    { lat: 49.133392, lng: 18.8278238 },
    { lat: 49.1325338, lng: 18.8279915 },
    { lat: 49.1321355, lng: 18.8280115 },
    { lat: 49.132015, lng: 18.8280796 },
    { lat: 49.1313831, lng: 18.8283295 },
    { lat: 49.1307397, lng: 18.8285132 },
    { lat: 49.1303966, lng: 18.8284851 },
    { lat: 49.1302539, lng: 18.8286048 },
    { lat: 49.1300665, lng: 18.8285988 },
    { lat: 49.1300041, lng: 18.8286171 },
    { lat: 49.1295938, lng: 18.8288348 },
    { lat: 49.1291672, lng: 18.8288275 },
    { lat: 49.1291286, lng: 18.8288667 },
    { lat: 49.1290818, lng: 18.828881 },
    { lat: 49.1288273, lng: 18.8289295 },
    { lat: 49.1288271, lng: 18.8289453 },
    { lat: 49.1288246, lng: 18.8289643 },
    { lat: 49.1283281, lng: 18.8287624 },
    { lat: 49.1274875, lng: 18.8281812 },
    { lat: 49.1266785, lng: 18.8279717 },
    { lat: 49.1261127, lng: 18.8278674 },
    { lat: 49.1255426, lng: 18.8277675 },
    { lat: 49.124958, lng: 18.8276468 },
    { lat: 49.1246751, lng: 18.8275179 },
    { lat: 49.1243673, lng: 18.8273852 },
    { lat: 49.124061, lng: 18.827237 },
    { lat: 49.124094, lng: 18.827376 },
    { lat: 49.124359, lng: 18.828501 },
    { lat: 49.124558, lng: 18.82935 },
    { lat: 49.124749, lng: 18.83017 },
    { lat: 49.124764, lng: 18.830764 },
    { lat: 49.12477, lng: 18.831026 },
    { lat: 49.125041, lng: 18.832166 },
    { lat: 49.125405, lng: 18.832908 },
    { lat: 49.126368, lng: 18.834044 },
    { lat: 49.126939, lng: 18.835063 },
    { lat: 49.127108, lng: 18.835295 },
    { lat: 49.127297, lng: 18.835555 },
    { lat: 49.127845, lng: 18.835631 },
    { lat: 49.128573, lng: 18.835936 },
    { lat: 49.129496, lng: 18.836335 },
    { lat: 49.129843, lng: 18.836675 },
    { lat: 49.129874, lng: 18.836706 },
    { lat: 49.130314, lng: 18.838181 },
    { lat: 49.130628, lng: 18.839248 },
    { lat: 49.13073, lng: 18.839596 },
    { lat: 49.131577, lng: 18.841885 },
    { lat: 49.131984, lng: 18.842307 },
    { lat: 49.132167, lng: 18.842774 },
    { lat: 49.132179, lng: 18.842805 },
    { lat: 49.133031, lng: 18.843269 },
    { lat: 49.133074, lng: 18.843293 },
    { lat: 49.13327, lng: 18.843883 },
    { lat: 49.133299, lng: 18.843974 },
    { lat: 49.133852, lng: 18.844919 },
    { lat: 49.134072, lng: 18.845294 },
    { lat: 49.134338, lng: 18.845711 },
    { lat: 49.134597, lng: 18.84592 },
    { lat: 49.135252, lng: 18.845976 },
    { lat: 49.136002, lng: 18.846033 },
    { lat: 49.137005, lng: 18.846106 },
    { lat: 49.137388, lng: 18.8462 },
    { lat: 49.137896, lng: 18.846317 },
    { lat: 49.13878, lng: 18.846468 },
    { lat: 49.138788, lng: 18.846469 },
    { lat: 49.13879, lng: 18.846471 },
    { lat: 49.139269, lng: 18.846688 },
    { lat: 49.13954, lng: 18.847522 },
    { lat: 49.14006, lng: 18.847897 },
    { lat: 49.140373, lng: 18.848087 },
    { lat: 49.140613, lng: 18.847916 },
    { lat: 49.141139, lng: 18.847795 },
    { lat: 49.14144, lng: 18.847716 },
    { lat: 49.142046, lng: 18.847509 },
    { lat: 49.14257, lng: 18.847466 },
    { lat: 49.14283, lng: 18.847652 },
    { lat: 49.14358, lng: 18.847889 },
    { lat: 49.144213, lng: 18.8481 },
    { lat: 49.144882, lng: 18.848288 },
    { lat: 49.145675, lng: 18.848505 },
    { lat: 49.1459338, lng: 18.8482807 },
    { lat: 49.1462686, lng: 18.8478846 },
    { lat: 49.1467139, lng: 18.8474013 },
    { lat: 49.1470709, lng: 18.846978 },
    { lat: 49.1475121, lng: 18.8464333 },
    { lat: 49.1479649, lng: 18.8458786 },
    { lat: 49.1483409, lng: 18.845141 },
    { lat: 49.1485317, lng: 18.8447534 },
    { lat: 49.1491404, lng: 18.8442898 },
    { lat: 49.1497419, lng: 18.8442345 },
    { lat: 49.1502095, lng: 18.8440093 },
    { lat: 49.1502635, lng: 18.8439834 },
    { lat: 49.1502966, lng: 18.8439698 },
    { lat: 49.1508161, lng: 18.8438083 },
    { lat: 49.1516155, lng: 18.8436763 },
    { lat: 49.1522969, lng: 18.8439486 },
    { lat: 49.153151, lng: 18.8441783 },
    { lat: 49.15384, lng: 18.8447303 },
    { lat: 49.1546491, lng: 18.8439736 },
    { lat: 49.155446, lng: 18.8442339 },
    { lat: 49.1559884, lng: 18.8443896 },
    { lat: 49.1562884, lng: 18.8444535 },
    { lat: 49.1566294, lng: 18.8444869 },
    { lat: 49.1567802, lng: 18.8444826 },
    { lat: 49.1569881, lng: 18.8445781 },
    { lat: 49.1575291, lng: 18.8448578 },
    { lat: 49.1578906, lng: 18.8448074 },
    { lat: 49.1582327, lng: 18.8446595 },
    { lat: 49.1583459, lng: 18.8447159 },
    { lat: 49.1585346, lng: 18.84481 },
    { lat: 49.1587579, lng: 18.8449638 },
    { lat: 49.1590035, lng: 18.8451348 },
    { lat: 49.1593293, lng: 18.8450894 },
    { lat: 49.159633, lng: 18.8449662 },
    { lat: 49.1599227, lng: 18.845316 },
    { lat: 49.1601893, lng: 18.8455439 },
    { lat: 49.1603423, lng: 18.8457342 },
    { lat: 49.1612426, lng: 18.8465377 },
    { lat: 49.1614318, lng: 18.8467053 },
    { lat: 49.1615295, lng: 18.8469426 },
    { lat: 49.1618447, lng: 18.847307 },
    { lat: 49.162312, lng: 18.8476644 },
    { lat: 49.1628026, lng: 18.847956 },
    { lat: 49.163042, lng: 18.8481825 },
    { lat: 49.1633269, lng: 18.8483293 },
    { lat: 49.1638071, lng: 18.8482168 },
    { lat: 49.1640326, lng: 18.8479741 },
    { lat: 49.16433, lng: 18.8476607 },
    { lat: 49.1646955, lng: 18.8475767 },
    { lat: 49.165072, lng: 18.8474424 },
    { lat: 49.1654429, lng: 18.8473768 },
    { lat: 49.1658047, lng: 18.8476175 },
    { lat: 49.1660077, lng: 18.8476412 },
    { lat: 49.1662483, lng: 18.847799 },
    { lat: 49.1664437, lng: 18.8478078 },
    { lat: 49.1667167, lng: 18.8477229 },
    { lat: 49.16708, lng: 18.8476154 },
    { lat: 49.1675325, lng: 18.8474683 },
    { lat: 49.167862, lng: 18.8475003 },
    { lat: 49.1683502, lng: 18.8479154 },
    { lat: 49.1685717, lng: 18.8481001 },
    { lat: 49.169064, lng: 18.8475422 },
    { lat: 49.1693987, lng: 18.847059 },
    { lat: 49.1695998, lng: 18.8467374 },
    { lat: 49.1699675, lng: 18.8464535 },
    { lat: 49.1703654, lng: 18.8457267 },
    { lat: 49.1707233, lng: 18.8451657 },
    { lat: 49.1708166, lng: 18.8449852 },
    { lat: 49.1710984, lng: 18.8444384 },
    { lat: 49.1712022, lng: 18.8442592 },
    { lat: 49.171294, lng: 18.8441149 },
    { lat: 49.1714902, lng: 18.84381 },
    { lat: 49.1716207, lng: 18.8436088 },
    { lat: 49.1719329, lng: 18.8431225 },
    { lat: 49.1720822, lng: 18.8429104 },
    { lat: 49.1726678, lng: 18.8436864 },
    { lat: 49.1732246, lng: 18.8442768 },
    { lat: 49.17383, lng: 18.8447327 },
    { lat: 49.1741517, lng: 18.845095 },
    { lat: 49.1744944, lng: 18.8447426 },
    { lat: 49.1751212, lng: 18.8439707 },
    { lat: 49.1754411, lng: 18.8434827 },
    { lat: 49.1759159, lng: 18.8427402 },
    { lat: 49.1766548, lng: 18.8417598 },
    { lat: 49.1772589, lng: 18.8410547 },
    { lat: 49.1791559, lng: 18.8412386 },
    { lat: 49.1791679, lng: 18.8411885 },
    { lat: 49.1792649, lng: 18.8409463 },
    { lat: 49.1794758, lng: 18.840641 },
    { lat: 49.1798029, lng: 18.8404923 },
    { lat: 49.1798946, lng: 18.840322 },
    { lat: 49.1802702, lng: 18.8400183 },
    { lat: 49.1807234, lng: 18.8399489 },
    { lat: 49.1807364, lng: 18.8397892 },
    { lat: 49.1807536, lng: 18.8395961 },
    { lat: 49.1808568, lng: 18.8383814 },
    { lat: 49.1808605, lng: 18.8383435 },
    { lat: 49.1808634, lng: 18.8383118 },
    { lat: 49.180868, lng: 18.8382694 },
    { lat: 49.1808702, lng: 18.8382248 },
    { lat: 49.1815141, lng: 18.8384231 },
    { lat: 49.1820286, lng: 18.8384711 },
    { lat: 49.1836057, lng: 18.8382282 },
    { lat: 49.1835246, lng: 18.8371981 },
    { lat: 49.1845464, lng: 18.8368068 },
    { lat: 49.1856432, lng: 18.8365187 },
    { lat: 49.185691, lng: 18.8365061 },
    { lat: 49.18574, lng: 18.8364931 },
    { lat: 49.1857756, lng: 18.8364834 },
    { lat: 49.1858146, lng: 18.8364737 },
    { lat: 49.1857495, lng: 18.8362843 },
    { lat: 49.1856362, lng: 18.8358999 },
    { lat: 49.1856027, lng: 18.8357958 },
    { lat: 49.1855825, lng: 18.8357337 },
    { lat: 49.1855074, lng: 18.835499 },
    { lat: 49.1853713, lng: 18.8351376 },
    { lat: 49.1852352, lng: 18.8347927 },
    { lat: 49.1850599, lng: 18.8344951 },
    { lat: 49.1849163, lng: 18.8342477 },
    { lat: 49.1848978, lng: 18.8338232 },
    { lat: 49.1849035, lng: 18.8338009 },
    { lat: 49.1849131, lng: 18.8337676 },
    { lat: 49.1849227, lng: 18.8337337 },
    { lat: 49.1849292, lng: 18.8337084 },
    { lat: 49.1850558, lng: 18.8331897 },
    { lat: 49.1850757, lng: 18.833117 },
    { lat: 49.1851962, lng: 18.8327 },
    { lat: 49.1845297, lng: 18.8318834 },
    { lat: 49.1842998, lng: 18.8314467 },
    { lat: 49.1840579, lng: 18.8307368 },
    { lat: 49.1840868, lng: 18.8305088 },
    { lat: 49.1842744, lng: 18.8302106 },
    { lat: 49.185121, lng: 18.8290292 },
    { lat: 49.1853283, lng: 18.8286486 },
    { lat: 49.1853965, lng: 18.8283549 },
    { lat: 49.1853715, lng: 18.8280659 },
    { lat: 49.184838, lng: 18.8264019 },
    { lat: 49.1851501, lng: 18.8258285 },
    { lat: 49.1859624, lng: 18.8266902 },
    { lat: 49.1861791, lng: 18.8269104 },
    { lat: 49.1862761, lng: 18.8272848 },
    { lat: 49.1866002, lng: 18.826968 },
    { lat: 49.1867858, lng: 18.8269386 },
    { lat: 49.1877087, lng: 18.8246287 },
    { lat: 49.1872956, lng: 18.8241815 },
    { lat: 49.187674, lng: 18.8230799 },
    { lat: 49.1877055, lng: 18.8229936 },
    { lat: 49.1855114, lng: 18.8209828 },
    { lat: 49.1856696, lng: 18.8198082 },
    { lat: 49.1851569, lng: 18.8196427 },
    { lat: 49.1850704, lng: 18.8186449 },
    { lat: 49.1849217, lng: 18.8184692 },
    { lat: 49.1850515, lng: 18.8173586 },
    { lat: 49.1851872, lng: 18.8162285 },
    { lat: 49.1848477, lng: 18.8162931 },
    { lat: 49.1843898, lng: 18.8165784 },
    { lat: 49.1841848, lng: 18.8163421 },
    { lat: 49.1840976, lng: 18.8161352 },
    { lat: 49.1840583, lng: 18.8158141 },
    { lat: 49.1841077, lng: 18.815416 },
    { lat: 49.1842447, lng: 18.8151411 },
    { lat: 49.1843037, lng: 18.8147548 },
    { lat: 49.1843764, lng: 18.8143957 },
    { lat: 49.1844317, lng: 18.8139572 },
    { lat: 49.1844349, lng: 18.8138055 },
    { lat: 49.184438, lng: 18.8137539 },
    { lat: 49.1842159, lng: 18.8138415 },
    { lat: 49.1839648, lng: 18.81372 },
    { lat: 49.1837117, lng: 18.8137511 },
    { lat: 49.1834543, lng: 18.8138541 },
    { lat: 49.1833333, lng: 18.8139354 },
    { lat: 49.1829898, lng: 18.8141843 },
    { lat: 49.182771, lng: 18.8145951 },
    { lat: 49.1825957, lng: 18.8148235 },
    { lat: 49.1824805, lng: 18.8147346 },
    { lat: 49.182398, lng: 18.8149485 },
    { lat: 49.1823698, lng: 18.8154273 },
    { lat: 49.1821179, lng: 18.8155095 },
    { lat: 49.1817436, lng: 18.8157289 },
    { lat: 49.1814767, lng: 18.815775 },
    { lat: 49.1811202, lng: 18.8157714 },
    { lat: 49.1808086, lng: 18.8157457 },
    { lat: 49.1805335, lng: 18.8159406 },
    { lat: 49.180197, lng: 18.8159544 },
    { lat: 49.1798496, lng: 18.8163003 },
    { lat: 49.1796538, lng: 18.8161955 },
    { lat: 49.1793827, lng: 18.8164739 },
    { lat: 49.1790509, lng: 18.8167509 },
    { lat: 49.1788216, lng: 18.8167857 },
    { lat: 49.1785559, lng: 18.8167736 },
    { lat: 49.1783929, lng: 18.8169356 },
    { lat: 49.1783876, lng: 18.8169182 },
    { lat: 49.1783666, lng: 18.8168602 },
    { lat: 49.1783849, lng: 18.8168443 },
    { lat: 49.1783755, lng: 18.8168166 },
    { lat: 49.1780208, lng: 18.8157776 },
    { lat: 49.1779686, lng: 18.8156188 },
    { lat: 49.1777283, lng: 18.8149172 },
    { lat: 49.1778247, lng: 18.8148407 },
    { lat: 49.1776773, lng: 18.8144104 },
    { lat: 49.1775647, lng: 18.8144989 },
    { lat: 49.1769103, lng: 18.8150144 },
    { lat: 49.1762394, lng: 18.8155379 },
    { lat: 49.1762325, lng: 18.8155373 },
    { lat: 49.1762185, lng: 18.8155952 },
    { lat: 49.1761821, lng: 18.8155448 },
    { lat: 49.1761518, lng: 18.8155027 },
    { lat: 49.1757709, lng: 18.8148742 },
    { lat: 49.1750595, lng: 18.8157896 },
    { lat: 49.1750465, lng: 18.8159998 },
    { lat: 49.1742487, lng: 18.8162031 },
    { lat: 49.173607, lng: 18.8163562 },
    { lat: 49.1731271, lng: 18.8164733 },
    { lat: 49.1731108, lng: 18.8155217 },
    { lat: 49.1727642, lng: 18.815496 },
    { lat: 49.1722843, lng: 18.8154812 },
    { lat: 49.1719751, lng: 18.8155825 },
    { lat: 49.1718545, lng: 18.8156734 },
    { lat: 49.1718076, lng: 18.8156797 },
    { lat: 49.1715528, lng: 18.8156606 },
    { lat: 49.1711996, lng: 18.8156321 },
    { lat: 49.1711628, lng: 18.8156394 },
    { lat: 49.1709706, lng: 18.8157183 },
    { lat: 49.1706045, lng: 18.8156794 },
    { lat: 49.170565, lng: 18.8156802 },
    { lat: 49.1704528, lng: 18.8157996 },
    { lat: 49.1704227, lng: 18.8158299 },
    { lat: 49.1703665, lng: 18.8158602 },
    { lat: 49.1702384, lng: 18.8159295 },
    { lat: 49.1699775, lng: 18.8160704 },
    { lat: 49.1695745, lng: 18.8161952 },
    { lat: 49.1693959, lng: 18.8162502 },
    { lat: 49.1692204, lng: 18.8163473 },
    { lat: 49.1689771, lng: 18.8164824 },
    { lat: 49.1688388, lng: 18.8165124 },
    { lat: 49.1686895, lng: 18.8165451 },
    { lat: 49.168511, lng: 18.8165846 },
    { lat: 49.1683328, lng: 18.8166236 },
    { lat: 49.1681542, lng: 18.8166624 },
    { lat: 49.1675992, lng: 18.8167845 },
    { lat: 49.1672928, lng: 18.8167035 },
    { lat: 49.1668644, lng: 18.8166386 },
    { lat: 49.1665156, lng: 18.8164834 },
    { lat: 49.1663391, lng: 18.8163586 },
    { lat: 49.1662046, lng: 18.8162209 },
    { lat: 49.1661378, lng: 18.8161536 },
    { lat: 49.1657583, lng: 18.8157218 },
    { lat: 49.1655297, lng: 18.8163658 },
    { lat: 49.1654069, lng: 18.8167138 },
    { lat: 49.1651724, lng: 18.8167171 },
    { lat: 49.1651521, lng: 18.8167585 },
    { lat: 49.1649667, lng: 18.8165768 },
    { lat: 49.1647902, lng: 18.8163022 },
    { lat: 49.1645568, lng: 18.8161252 },
    { lat: 49.1642916, lng: 18.816036 },
    { lat: 49.1641301, lng: 18.8159869 },
    { lat: 49.1639083, lng: 18.8157502 },
    { lat: 49.163759, lng: 18.8157656 },
    { lat: 49.1635463, lng: 18.815727 },
    { lat: 49.1633422, lng: 18.8156179 },
    { lat: 49.1632096, lng: 18.8154983 },
    { lat: 49.1630267, lng: 18.8153848 },
  ],
  TepličkanadVáhom: [
    { lat: 49.24812, lng: 18.795706 },
    { lat: 49.2478634, lng: 18.7961978 },
    { lat: 49.2477012, lng: 18.7963964 },
    { lat: 49.2474106, lng: 18.7966661 },
    { lat: 49.2470462, lng: 18.7969436 },
    { lat: 49.2466797, lng: 18.797208 },
    { lat: 49.2465209, lng: 18.7972932 },
    { lat: 49.2463858, lng: 18.7972348 },
    { lat: 49.2461646, lng: 18.7969919 },
    { lat: 49.2457948, lng: 18.7968175 },
    { lat: 49.2453324, lng: 18.7972518 },
    { lat: 49.2450071, lng: 18.7975675 },
    { lat: 49.2445418, lng: 18.798073 },
    { lat: 49.2442837, lng: 18.7982492 },
    { lat: 49.2439643, lng: 18.7982863 },
    { lat: 49.2437798, lng: 18.7982563 },
    { lat: 49.2433067, lng: 18.7981535 },
    { lat: 49.2427542, lng: 18.7981111 },
    { lat: 49.2425002, lng: 18.7981934 },
    { lat: 49.2421997, lng: 18.7983993 },
    { lat: 49.2418171, lng: 18.798545 },
    { lat: 49.2416458, lng: 18.7987003 },
    { lat: 49.2414792, lng: 18.7987684 },
    { lat: 49.2408353, lng: 18.7992921 },
    { lat: 49.2397201, lng: 18.7991379 },
    { lat: 49.2388491, lng: 18.798145 },
    { lat: 49.2382544, lng: 18.7972847 },
    { lat: 49.2378707, lng: 18.7973681 },
    { lat: 49.2375935, lng: 18.7973716 },
    { lat: 49.2371591, lng: 18.7973876 },
    { lat: 49.2366077, lng: 18.7974855 },
    { lat: 49.2359725, lng: 18.7975972 },
    { lat: 49.2355614, lng: 18.7977792 },
    { lat: 49.2346673, lng: 18.7982818 },
    { lat: 49.2344086, lng: 18.798186 },
    { lat: 49.2338738, lng: 18.797806 },
    { lat: 49.2338791, lng: 18.79756 },
    { lat: 49.2337915, lng: 18.7967815 },
    { lat: 49.2341118, lng: 18.7947576 },
    { lat: 49.2342795, lng: 18.7941445 },
    { lat: 49.2344173, lng: 18.7932096 },
    { lat: 49.234401, lng: 18.7929179 },
    { lat: 49.2341936, lng: 18.7922213 },
    { lat: 49.2339981, lng: 18.791734 },
    { lat: 49.2339841, lng: 18.7912464 },
    { lat: 49.234161, lng: 18.7905929 },
    { lat: 49.2342298, lng: 18.7903107 },
    { lat: 49.2342259, lng: 18.7900796 },
    { lat: 49.2341209, lng: 18.7897307 },
    { lat: 49.2339318, lng: 18.7889955 },
    { lat: 49.2338532, lng: 18.7880041 },
    { lat: 49.2339424, lng: 18.7874171 },
    { lat: 49.23426, lng: 18.7873859 },
    { lat: 49.2341504, lng: 18.7862738 },
    { lat: 49.2341424, lng: 18.785359 },
    { lat: 49.2341926, lng: 18.7848918 },
    { lat: 49.2340682, lng: 18.7835141 },
    { lat: 49.2339769, lng: 18.7825834 },
    { lat: 49.2338993, lng: 18.7815421 },
    { lat: 49.2337804, lng: 18.7807713 },
    { lat: 49.2338011, lng: 18.7797776 },
    { lat: 49.2337862, lng: 18.7795586 },
    { lat: 49.2336664, lng: 18.7788342 },
    { lat: 49.2333471, lng: 18.777462 },
    { lat: 49.2331965, lng: 18.7771245 },
    { lat: 49.2330775, lng: 18.7766803 },
    { lat: 49.2329932, lng: 18.7763582 },
    { lat: 49.2329618, lng: 18.7760116 },
    { lat: 49.2328153, lng: 18.7758446 },
    { lat: 49.2327148, lng: 18.7753185 },
    { lat: 49.2327234, lng: 18.7748971 },
    { lat: 49.2326146, lng: 18.7742594 },
    { lat: 49.2324603, lng: 18.7730895 },
    { lat: 49.2323866, lng: 18.7722351 },
    { lat: 49.2322535, lng: 18.7715162 },
    { lat: 49.2321047, lng: 18.7703897 },
    { lat: 49.2319214, lng: 18.7689904 },
    { lat: 49.2319607, lng: 18.7679873 },
    { lat: 49.231895, lng: 18.7679609 },
    { lat: 49.2316036, lng: 18.7678639 },
    { lat: 49.2312604, lng: 18.7677705 },
    { lat: 49.2309774, lng: 18.7676623 },
    { lat: 49.2306845, lng: 18.7675827 },
    { lat: 49.2302545, lng: 18.767487 },
    { lat: 49.2295823, lng: 18.7671157 },
    { lat: 49.2293348, lng: 18.7670179 },
    { lat: 49.2288295, lng: 18.766844 },
    { lat: 49.2285007, lng: 18.7667854 },
    { lat: 49.2281827, lng: 18.7663964 },
    { lat: 49.2280519, lng: 18.7662676 },
    { lat: 49.2276767, lng: 18.766185 },
    { lat: 49.2274725, lng: 18.7660755 },
    { lat: 49.2272846, lng: 18.7659749 },
    { lat: 49.2268962, lng: 18.7659845 },
    { lat: 49.2266919, lng: 18.7660216 },
    { lat: 49.2265376, lng: 18.7660504 },
    { lat: 49.226426, lng: 18.7660708 },
    { lat: 49.2264357, lng: 18.7684467 },
    { lat: 49.2264374, lng: 18.7687607 },
    { lat: 49.2263043, lng: 18.7709729 },
    { lat: 49.2260585, lng: 18.7716033 },
    { lat: 49.2256444, lng: 18.7719648 },
    { lat: 49.2248579, lng: 18.7720626 },
    { lat: 49.2243605, lng: 18.7720377 },
    { lat: 49.2239487, lng: 18.7720119 },
    { lat: 49.2236723, lng: 18.7719458 },
    { lat: 49.2234257, lng: 18.7718866 },
    { lat: 49.2232168, lng: 18.7718368 },
    { lat: 49.2230479, lng: 18.771797 },
    { lat: 49.2228697, lng: 18.7717555 },
    { lat: 49.2226591, lng: 18.771671 },
    { lat: 49.2221391, lng: 18.7714735 },
    { lat: 49.2219131, lng: 18.7713342 },
    { lat: 49.2214195, lng: 18.7711888 },
    { lat: 49.221052, lng: 18.7710598 },
    { lat: 49.2207377, lng: 18.7709063 },
    { lat: 49.2206158, lng: 18.7708468 },
    { lat: 49.2204957, lng: 18.7707499 },
    { lat: 49.2202656, lng: 18.7705736 },
    { lat: 49.2197953, lng: 18.7705068 },
    { lat: 49.2196501, lng: 18.7704862 },
    { lat: 49.2181638, lng: 18.7708766 },
    { lat: 49.2178908, lng: 18.7709154 },
    { lat: 49.2174756, lng: 18.7711515 },
    { lat: 49.2172795, lng: 18.7712824 },
    { lat: 49.2172391, lng: 18.7713111 },
    { lat: 49.2169558, lng: 18.7715185 },
    { lat: 49.2169073, lng: 18.7715413 },
    { lat: 49.2164912, lng: 18.7717144 },
    { lat: 49.2163566, lng: 18.7718147 },
    { lat: 49.2158703, lng: 18.7722011 },
    { lat: 49.2155039, lng: 18.7726351 },
    { lat: 49.2152747, lng: 18.7729005 },
    { lat: 49.2151903, lng: 18.773024 },
    { lat: 49.2151734, lng: 18.7730444 },
    { lat: 49.2151442, lng: 18.7730909 },
    { lat: 49.2150139, lng: 18.7732949 },
    { lat: 49.2149899, lng: 18.7733325 },
    { lat: 49.2148503, lng: 18.7735514 },
    { lat: 49.2148462, lng: 18.7735591 },
    { lat: 49.2147537, lng: 18.7737744 },
    { lat: 49.2145988, lng: 18.7741365 },
    { lat: 49.2145676, lng: 18.7742096 },
    { lat: 49.2145285, lng: 18.7743016 },
    { lat: 49.2144973, lng: 18.7743746 },
    { lat: 49.2143744, lng: 18.7747462 },
    { lat: 49.2141983, lng: 18.7752822 },
    { lat: 49.214094, lng: 18.7755985 },
    { lat: 49.2137338, lng: 18.7772396 },
    { lat: 49.2137276, lng: 18.7773788 },
    { lat: 49.2137268, lng: 18.7774089 },
    { lat: 49.213717, lng: 18.7776293 },
    { lat: 49.2137143, lng: 18.7776859 },
    { lat: 49.2137022, lng: 18.7779536 },
    { lat: 49.2136984, lng: 18.7780536 },
    { lat: 49.2136929, lng: 18.778166 },
    { lat: 49.2136915, lng: 18.7781842 },
    { lat: 49.213688, lng: 18.7782683 },
    { lat: 49.2136845, lng: 18.7783496 },
    { lat: 49.2132293, lng: 18.7815071 },
    { lat: 49.2128797, lng: 18.7832882 },
    { lat: 49.2124575, lng: 18.7849752 },
    { lat: 49.2119421, lng: 18.7864956 },
    { lat: 49.2113929, lng: 18.7882527 },
    { lat: 49.2112393, lng: 18.7886853 },
    { lat: 49.210616, lng: 18.790518 },
    { lat: 49.2102581, lng: 18.7914448 },
    { lat: 49.2099095, lng: 18.7922457 },
    { lat: 49.2095869, lng: 18.7929828 },
    { lat: 49.2089264, lng: 18.7943575 },
    { lat: 49.2088706, lng: 18.7944811 },
    { lat: 49.2086012, lng: 18.7950676 },
    { lat: 49.2084119, lng: 18.7954813 },
    { lat: 49.2081933, lng: 18.796225 },
    { lat: 49.2078228, lng: 18.797499 },
    { lat: 49.2076574, lng: 18.7980505 },
    { lat: 49.207429, lng: 18.7991697 },
    { lat: 49.2074398, lng: 18.8005795 },
    { lat: 49.20725, lng: 18.8025104 },
    { lat: 49.2077457, lng: 18.8028531 },
    { lat: 49.2077674, lng: 18.8028793 },
    { lat: 49.2080505, lng: 18.8030771 },
    { lat: 49.2080868, lng: 18.8031027 },
    { lat: 49.2080927, lng: 18.8031067 },
    { lat: 49.2081278, lng: 18.8031313 },
    { lat: 49.2081349, lng: 18.8031362 },
    { lat: 49.2083396, lng: 18.8032796 },
    { lat: 49.208356, lng: 18.8032913 },
    { lat: 49.2083945, lng: 18.8033188 },
    { lat: 49.2084133, lng: 18.8033345 },
    { lat: 49.208443, lng: 18.8033602 },
    { lat: 49.2084583, lng: 18.8033742 },
    { lat: 49.2086345, lng: 18.8035091 },
    { lat: 49.2087049, lng: 18.8035634 },
    { lat: 49.2087733, lng: 18.8036211 },
    { lat: 49.2088062, lng: 18.8036489 },
    { lat: 49.209194, lng: 18.8039754 },
    { lat: 49.2095965, lng: 18.8044062 },
    { lat: 49.2098061, lng: 18.8047139 },
    { lat: 49.2098753, lng: 18.8048721 },
    { lat: 49.2100169, lng: 18.8052128 },
    { lat: 49.2100396, lng: 18.8052584 },
    { lat: 49.2101712, lng: 18.8055141 },
    { lat: 49.2105209, lng: 18.8060169 },
    { lat: 49.2106912, lng: 18.8061948 },
    { lat: 49.2111376, lng: 18.8066492 },
    { lat: 49.2114653, lng: 18.8069682 },
    { lat: 49.2114961, lng: 18.806998 },
    { lat: 49.2116027, lng: 18.8071222 },
    { lat: 49.2115899, lng: 18.807175 },
    { lat: 49.2118384, lng: 18.8073395 },
    { lat: 49.211844, lng: 18.8073139 },
    { lat: 49.2119756, lng: 18.8074423 },
    { lat: 49.2122548, lng: 18.8077153 },
    { lat: 49.2123144, lng: 18.8077579 },
    { lat: 49.2123306, lng: 18.807788 },
    { lat: 49.2126017, lng: 18.8081012 },
    { lat: 49.2127434, lng: 18.8084203 },
    { lat: 49.2127565, lng: 18.8084761 },
    { lat: 49.2128141, lng: 18.8087627 },
    { lat: 49.2129978, lng: 18.8107913 },
    { lat: 49.2131186, lng: 18.8121483 },
    { lat: 49.2131527, lng: 18.812524 },
    { lat: 49.2131585, lng: 18.8125947 },
    { lat: 49.213268, lng: 18.8137544 },
    { lat: 49.213258, lng: 18.8138003 },
    { lat: 49.2133908, lng: 18.8151729 },
    { lat: 49.2135504, lng: 18.8160993 },
    { lat: 49.2135591, lng: 18.8161629 },
    { lat: 49.213595, lng: 18.8162227 },
    { lat: 49.2136298, lng: 18.8162413 },
    { lat: 49.2142665, lng: 18.816718 },
    { lat: 49.2148614, lng: 18.817212 },
    { lat: 49.2161877, lng: 18.8182666 },
    { lat: 49.2177328, lng: 18.8192989 },
    { lat: 49.2177284, lng: 18.8193236 },
    { lat: 49.2177463, lng: 18.8193453 },
    { lat: 49.2178172, lng: 18.8194291 },
    { lat: 49.2178401, lng: 18.8194566 },
    { lat: 49.2178492, lng: 18.8194581 },
    { lat: 49.217858, lng: 18.8194321 },
    { lat: 49.2178815, lng: 18.8194516 },
    { lat: 49.2190946, lng: 18.8203241 },
    { lat: 49.2205099, lng: 18.8212928 },
    { lat: 49.2206181, lng: 18.8213801 },
    { lat: 49.2208604, lng: 18.8215739 },
    { lat: 49.2208769, lng: 18.8215874 },
    { lat: 49.2209592, lng: 18.8216533 },
    { lat: 49.2209804, lng: 18.8216706 },
    { lat: 49.2210474, lng: 18.8217228 },
    { lat: 49.221344, lng: 18.8219631 },
    { lat: 49.22186, lng: 18.822243 },
    { lat: 49.223482, lng: 18.8233876 },
    { lat: 49.2237469, lng: 18.8236192 },
    { lat: 49.2239493, lng: 18.8237961 },
    { lat: 49.2241392, lng: 18.8240012 },
    { lat: 49.2244011, lng: 18.8241819 },
    { lat: 49.2246396, lng: 18.8243679 },
    { lat: 49.2246767, lng: 18.8244543 },
    { lat: 49.2247911, lng: 18.8246621 },
    { lat: 49.2250301, lng: 18.8249213 },
    { lat: 49.2251489, lng: 18.8252147 },
    { lat: 49.225305, lng: 18.8254874 },
    { lat: 49.225484, lng: 18.825747 },
    { lat: 49.2254766, lng: 18.8258644 },
    { lat: 49.225761, lng: 18.8261304 },
    { lat: 49.2259269, lng: 18.8264203 },
    { lat: 49.2261525, lng: 18.8265758 },
    { lat: 49.2264032, lng: 18.8267585 },
    { lat: 49.2265418, lng: 18.8267989 },
    { lat: 49.2267668, lng: 18.8268999 },
    { lat: 49.2269618, lng: 18.8270547 },
    { lat: 49.2270903, lng: 18.8272042 },
    { lat: 49.2273203, lng: 18.8274865 },
    { lat: 49.2275716, lng: 18.8275941 },
    { lat: 49.2276669, lng: 18.8276923 },
    { lat: 49.227853, lng: 18.8279344 },
    { lat: 49.2279982, lng: 18.8281075 },
    { lat: 49.2280787, lng: 18.8282894 },
    { lat: 49.2286052, lng: 18.8287966 },
    { lat: 49.2287795, lng: 18.8286108 },
    { lat: 49.2290972, lng: 18.8291744 },
    { lat: 49.2293348, lng: 18.8295857 },
    { lat: 49.2296567, lng: 18.8301437 },
    { lat: 49.2303854, lng: 18.8303911 },
    { lat: 49.230666, lng: 18.8303683 },
    { lat: 49.2312379, lng: 18.8305024 },
    { lat: 49.2321478, lng: 18.8307656 },
    { lat: 49.2323887, lng: 18.8307774 },
    { lat: 49.2328088, lng: 18.8306805 },
    { lat: 49.2334235, lng: 18.8306204 },
    { lat: 49.2340985, lng: 18.8305932 },
    { lat: 49.2342024, lng: 18.8308351 },
    { lat: 49.23463, lng: 18.8307337 },
    { lat: 49.2355896, lng: 18.8300784 },
    { lat: 49.2362911, lng: 18.82962 },
    { lat: 49.2356242, lng: 18.8277892 },
    { lat: 49.2353283, lng: 18.8268199 },
    { lat: 49.23479, lng: 18.8259774 },
    { lat: 49.2344156, lng: 18.8252741 },
    { lat: 49.234341, lng: 18.8246145 },
    { lat: 49.2342925, lng: 18.8239568 },
    { lat: 49.2343162, lng: 18.8236739 },
    { lat: 49.2345412, lng: 18.8225937 },
    { lat: 49.2349559, lng: 18.820878 },
    { lat: 49.2351129, lng: 18.819203 },
    { lat: 49.2354584, lng: 18.81838 },
    { lat: 49.2356509, lng: 18.8170647 },
    { lat: 49.2355652, lng: 18.8163419 },
    { lat: 49.2353564, lng: 18.8156143 },
    { lat: 49.2352279, lng: 18.8148962 },
    { lat: 49.2351774, lng: 18.8143986 },
    { lat: 49.2352508, lng: 18.8136775 },
    { lat: 49.2352773, lng: 18.8131825 },
    { lat: 49.2355604, lng: 18.8127274 },
    { lat: 49.2357304, lng: 18.812331 },
    { lat: 49.2358571, lng: 18.8124056 },
    { lat: 49.2361632, lng: 18.8121962 },
    { lat: 49.2365091, lng: 18.8112305 },
    { lat: 49.2368644, lng: 18.8104878 },
    { lat: 49.2373291, lng: 18.8103647 },
    { lat: 49.237773, lng: 18.810312 },
    { lat: 49.2379835, lng: 18.8100019 },
    { lat: 49.23849, lng: 18.8102015 },
    { lat: 49.2386813, lng: 18.8099976 },
    { lat: 49.2389861, lng: 18.8095225 },
    { lat: 49.2394359, lng: 18.809127 },
    { lat: 49.2394029, lng: 18.8087947 },
    { lat: 49.2394617, lng: 18.8085573 },
    { lat: 49.23963, lng: 18.8081928 },
    { lat: 49.2397388, lng: 18.8078564 },
    { lat: 49.2399468, lng: 18.8073409 },
    { lat: 49.2401989, lng: 18.8065023 },
    { lat: 49.2403067, lng: 18.806473 },
    { lat: 49.2406987, lng: 18.8057456 },
    { lat: 49.2408602, lng: 18.8055746 },
    { lat: 49.2412826, lng: 18.8054132 },
    { lat: 49.2414412, lng: 18.8054916 },
    { lat: 49.2417613, lng: 18.8048604 },
    { lat: 49.241909, lng: 18.8045312 },
    { lat: 49.242027, lng: 18.8044603 },
    { lat: 49.2421634, lng: 18.8045215 },
    { lat: 49.242453, lng: 18.8048262 },
    { lat: 49.2427419, lng: 18.8051349 },
    { lat: 49.2431935, lng: 18.8053894 },
    { lat: 49.2433232, lng: 18.8052334 },
    { lat: 49.2435439, lng: 18.8053796 },
    { lat: 49.2436983, lng: 18.8054693 },
    { lat: 49.2439172, lng: 18.8052289 },
    { lat: 49.2441712, lng: 18.8052798 },
    { lat: 49.2441744, lng: 18.8048374 },
    { lat: 49.2444337, lng: 18.8047762 },
    { lat: 49.2447267, lng: 18.8046622 },
    { lat: 49.2451747, lng: 18.8044526 },
    { lat: 49.245629, lng: 18.8046664 },
    { lat: 49.2459056, lng: 18.8045861 },
    { lat: 49.2461868, lng: 18.8046385 },
    { lat: 49.2461568, lng: 18.8041902 },
    { lat: 49.246144, lng: 18.8040294 },
    { lat: 49.246585, lng: 18.8039661 },
    { lat: 49.2468573, lng: 18.8040419 },
    { lat: 49.2471516, lng: 18.8038025 },
    { lat: 49.2471814, lng: 18.8038116 },
    { lat: 49.2473662, lng: 18.8032183 },
    { lat: 49.2474689, lng: 18.8027877 },
    { lat: 49.2476376, lng: 18.8024486 },
    { lat: 49.2479702, lng: 18.8019902 },
    { lat: 49.2481924, lng: 18.801425 },
    { lat: 49.2482998, lng: 18.8011673 },
    { lat: 49.2486137, lng: 18.8009829 },
    { lat: 49.2486259, lng: 18.8010233 },
    { lat: 49.2486507, lng: 18.8011087 },
    { lat: 49.248714, lng: 18.801035 },
    { lat: 49.248712, lng: 18.801028 },
    { lat: 49.248482, lng: 18.800249 },
    { lat: 49.248241, lng: 18.7999 },
    { lat: 49.247819, lng: 18.799287 },
    { lat: 49.247527, lng: 18.798665 },
    { lat: 49.24758, lng: 18.798585 },
    { lat: 49.247933, lng: 18.798387 },
    { lat: 49.248235, lng: 18.798062 },
    { lat: 49.248844, lng: 18.797025 },
    { lat: 49.24866, lng: 18.796561 },
    { lat: 49.248398, lng: 18.796146 },
    { lat: 49.24812, lng: 18.795706 },
  ],
  Turie: [
    { lat: 49.1587596, lng: 18.7654785 },
    { lat: 49.1585041, lng: 18.7637542 },
    { lat: 49.1583973, lng: 18.7630483 },
    { lat: 49.1583873, lng: 18.7629865 },
    { lat: 49.1583576, lng: 18.7627837 },
    { lat: 49.15828, lng: 18.7622707 },
    { lat: 49.1582685, lng: 18.7621989 },
    { lat: 49.1582688, lng: 18.7621639 },
    { lat: 49.1583756, lng: 18.7621773 },
    { lat: 49.1584936, lng: 18.7621925 },
    { lat: 49.1588887, lng: 18.762243 },
    { lat: 49.1593892, lng: 18.7624342 },
    { lat: 49.1597651, lng: 18.7626614 },
    { lat: 49.1601126, lng: 18.7628581 },
    { lat: 49.160414, lng: 18.7630608 },
    { lat: 49.1605231, lng: 18.7631179 },
    { lat: 49.1606808, lng: 18.7632007 },
    { lat: 49.1613418, lng: 18.7636391 },
    { lat: 49.1617648, lng: 18.7639017 },
    { lat: 49.1623392, lng: 18.7643635 },
    { lat: 49.1627943, lng: 18.764722 },
    { lat: 49.1631669, lng: 18.7648628 },
    { lat: 49.1631729, lng: 18.7648279 },
    { lat: 49.1632572, lng: 18.7640646 },
    { lat: 49.1634119, lng: 18.7622958 },
    { lat: 49.1635731, lng: 18.7605204 },
    { lat: 49.163785, lng: 18.7588524 },
    { lat: 49.1637892, lng: 18.7588051 },
    { lat: 49.1639855, lng: 18.7589762 },
    { lat: 49.164044, lng: 18.7591284 },
    { lat: 49.1642124, lng: 18.7593303 },
    { lat: 49.1643406, lng: 18.7593879 },
    { lat: 49.1644312, lng: 18.7593077 },
    { lat: 49.1646154, lng: 18.7592745 },
    { lat: 49.164879, lng: 18.7592678 },
    { lat: 49.1650286, lng: 18.7593462 },
    { lat: 49.1650822, lng: 18.7592716 },
    { lat: 49.1652168, lng: 18.7592575 },
    { lat: 49.1653775, lng: 18.7592257 },
    { lat: 49.1655122, lng: 18.7591719 },
    { lat: 49.16556, lng: 18.7592 },
    { lat: 49.1657274, lng: 18.7591473 },
    { lat: 49.1657705, lng: 18.7590869 },
    { lat: 49.1659753, lng: 18.759012 },
    { lat: 49.166302, lng: 18.7590506 },
    { lat: 49.1663997, lng: 18.7589757 },
    { lat: 49.1665479, lng: 18.7590319 },
    { lat: 49.1666659, lng: 18.7589563 },
    { lat: 49.1667868, lng: 18.7589406 },
    { lat: 49.1667755, lng: 18.7587885 },
    { lat: 49.1668687, lng: 18.7588453 },
    { lat: 49.1671344, lng: 18.758728 },
    { lat: 49.167494, lng: 18.7589453 },
    { lat: 49.1676417, lng: 18.7589641 },
    { lat: 49.1678109, lng: 18.7587915 },
    { lat: 49.167973, lng: 18.7588335 },
    { lat: 49.1681302, lng: 18.7587975 },
    { lat: 49.1682714, lng: 18.7588683 },
    { lat: 49.1685805, lng: 18.7588474 },
    { lat: 49.1688113, lng: 18.7589491 },
    { lat: 49.1689299, lng: 18.7590173 },
    { lat: 49.1690763, lng: 18.7588618 },
    { lat: 49.1691369, lng: 18.7589864 },
    { lat: 49.1693563, lng: 18.7588693 },
    { lat: 49.1695564, lng: 18.7588566 },
    { lat: 49.1697232, lng: 18.7588551 },
    { lat: 49.1699795, lng: 18.758669 },
    { lat: 49.1700654, lng: 18.758348 },
    { lat: 49.1701875, lng: 18.7582247 },
    { lat: 49.1702993, lng: 18.7582039 },
    { lat: 49.1703494, lng: 18.7580631 },
    { lat: 49.1705512, lng: 18.7578689 },
    { lat: 49.1706312, lng: 18.7578671 },
    { lat: 49.1706987, lng: 18.7579728 },
    { lat: 49.1709528, lng: 18.7580667 },
    { lat: 49.1712615, lng: 18.7581262 },
    { lat: 49.171325, lng: 18.7583711 },
    { lat: 49.1714319, lng: 18.7583862 },
    { lat: 49.1714432, lng: 18.7582349 },
    { lat: 49.1716988, lng: 18.7582937 },
    { lat: 49.1716806, lng: 18.7582469 },
    { lat: 49.1715243, lng: 18.7578651 },
    { lat: 49.1711963, lng: 18.7572209 },
    { lat: 49.170985, lng: 18.7566944 },
    { lat: 49.1706289, lng: 18.7560064 },
    { lat: 49.1703998, lng: 18.7555059 },
    { lat: 49.1702181, lng: 18.7551168 },
    { lat: 49.17, lng: 18.7546085 },
    { lat: 49.1691592, lng: 18.7539689 },
    { lat: 49.1684706, lng: 18.7534576 },
    { lat: 49.1680673, lng: 18.7534626 },
    { lat: 49.1675721, lng: 18.75346 },
    { lat: 49.1672448, lng: 18.7534766 },
    { lat: 49.1668333, lng: 18.7534794 },
    { lat: 49.1664306, lng: 18.7534962 },
    { lat: 49.1661683, lng: 18.7534635 },
    { lat: 49.1658072, lng: 18.7534984 },
    { lat: 49.16554, lng: 18.7534595 },
    { lat: 49.1651463, lng: 18.7531509 },
    { lat: 49.1648426, lng: 18.7530584 },
    { lat: 49.1643871, lng: 18.7530376 },
    { lat: 49.1638465, lng: 18.7530521 },
    { lat: 49.1636913, lng: 18.7530172 },
    { lat: 49.1636756, lng: 18.7530202 },
    { lat: 49.1635946, lng: 18.7529976 },
    { lat: 49.1631351, lng: 18.7537874 },
    { lat: 49.1627876, lng: 18.7549937 },
    { lat: 49.1626059, lng: 18.7554867 },
    { lat: 49.1618278, lng: 18.7552925 },
    { lat: 49.1612265, lng: 18.7551345 },
    { lat: 49.1612833, lng: 18.7547107 },
    { lat: 49.1612313, lng: 18.754258 },
    { lat: 49.1611255, lng: 18.7539156 },
    { lat: 49.1610862, lng: 18.7535518 },
    { lat: 49.1608575, lng: 18.7524115 },
    { lat: 49.1606879, lng: 18.7519698 },
    { lat: 49.1606597, lng: 18.7517106 },
    { lat: 49.1608119, lng: 18.7506922 },
    { lat: 49.1606987, lng: 18.7502124 },
    { lat: 49.1607624, lng: 18.7498541 },
    { lat: 49.1606303, lng: 18.749722 },
    { lat: 49.1606804, lng: 18.7495369 },
    { lat: 49.1607743, lng: 18.7493479 },
    { lat: 49.1608867, lng: 18.7492348 },
    { lat: 49.1609325, lng: 18.7492461 },
    { lat: 49.1609726, lng: 18.749127 },
    { lat: 49.1609559, lng: 18.7490476 },
    { lat: 49.1611858, lng: 18.7488706 },
    { lat: 49.1612111, lng: 18.748754 },
    { lat: 49.1615094, lng: 18.7484839 },
    { lat: 49.1615163, lng: 18.7483137 },
    { lat: 49.1613436, lng: 18.7481794 },
    { lat: 49.1612763, lng: 18.747926 },
    { lat: 49.1613085, lng: 18.7477243 },
    { lat: 49.1612312, lng: 18.747538 },
    { lat: 49.1614041, lng: 18.747094 },
    { lat: 49.1612672, lng: 18.746505 },
    { lat: 49.1611303, lng: 18.7458241 },
    { lat: 49.1610676, lng: 18.7458144 },
    { lat: 49.1609763, lng: 18.745618 },
    { lat: 49.160956, lng: 18.7454922 },
    { lat: 49.1607786, lng: 18.7452666 },
    { lat: 49.1607519, lng: 18.7450814 },
    { lat: 49.1600731, lng: 18.7451873 },
    { lat: 49.1589482, lng: 18.7451968 },
    { lat: 49.1574091, lng: 18.7452225 },
    { lat: 49.1574266, lng: 18.7437453 },
    { lat: 49.1577944, lng: 18.7436841 },
    { lat: 49.1574889, lng: 18.7422851 },
    { lat: 49.1559415, lng: 18.7419347 },
    { lat: 49.1552286, lng: 18.7417756 },
    { lat: 49.1550887, lng: 18.7417537 },
    { lat: 49.1546139, lng: 18.7415398 },
    { lat: 49.1542866, lng: 18.741598 },
    { lat: 49.1536044, lng: 18.7421345 },
    { lat: 49.1530746, lng: 18.7417504 },
    { lat: 49.1527751, lng: 18.741504 },
    { lat: 49.1527057, lng: 18.741448 },
    { lat: 49.1525064, lng: 18.7412374 },
    { lat: 49.1523953, lng: 18.7411417 },
    { lat: 49.1521431, lng: 18.741042 },
    { lat: 49.1519143, lng: 18.7409999 },
    { lat: 49.1517652, lng: 18.7409953 },
    { lat: 49.1516531, lng: 18.7410061 },
    { lat: 49.1513405, lng: 18.7410466 },
    { lat: 49.1513244, lng: 18.7404373 },
    { lat: 49.1512711, lng: 18.7400878 },
    { lat: 49.1509349, lng: 18.7397807 },
    { lat: 49.1509726, lng: 18.7389083 },
    { lat: 49.1506457, lng: 18.7378764 },
    { lat: 49.1502021, lng: 18.7379747 },
    { lat: 49.1496892, lng: 18.7386225 },
    { lat: 49.1492658, lng: 18.7390018 },
    { lat: 49.148803, lng: 18.7392288 },
    { lat: 49.148568, lng: 18.739114 },
    { lat: 49.1485404, lng: 18.7391249 },
    { lat: 49.1485303, lng: 18.7391262 },
    { lat: 49.1485035, lng: 18.7390012 },
    { lat: 49.1484548, lng: 18.7389116 },
    { lat: 49.1483911, lng: 18.7388589 },
    { lat: 49.1482458, lng: 18.7387094 },
    { lat: 49.1481791, lng: 18.7386005 },
    { lat: 49.1480999, lng: 18.7384642 },
    { lat: 49.1480489, lng: 18.7383296 },
    { lat: 49.1480437, lng: 18.7382311 },
    { lat: 49.1480633, lng: 18.7380908 },
    { lat: 49.1480648, lng: 18.738033 },
    { lat: 49.1480417, lng: 18.7379976 },
    { lat: 49.148024, lng: 18.7379634 },
    { lat: 49.1479843, lng: 18.7379354 },
    { lat: 49.1479039, lng: 18.7377575 },
    { lat: 49.1478244, lng: 18.7376159 },
    { lat: 49.1477354, lng: 18.7374697 },
    { lat: 49.1476514, lng: 18.7373506 },
    { lat: 49.1476196, lng: 18.7372623 },
    { lat: 49.1476148, lng: 18.7371476 },
    { lat: 49.147588, lng: 18.7369376 },
    { lat: 49.147558, lng: 18.7368609 },
    { lat: 49.1475083, lng: 18.7367697 },
    { lat: 49.1474161, lng: 18.7367319 },
    { lat: 49.1473051, lng: 18.7365931 },
    { lat: 49.1472644, lng: 18.7364431 },
    { lat: 49.1472703, lng: 18.7363405 },
    { lat: 49.1473016, lng: 18.73627 },
    { lat: 49.1473186, lng: 18.7360976 },
    { lat: 49.1473197, lng: 18.7359029 },
    { lat: 49.1473342, lng: 18.7357967 },
    { lat: 49.1473248, lng: 18.735702 },
    { lat: 49.1471871, lng: 18.7355272 },
    { lat: 49.147164, lng: 18.7354068 },
    { lat: 49.1472041, lng: 18.7352701 },
    { lat: 49.1471858, lng: 18.7349427 },
    { lat: 49.1471389, lng: 18.7348652 },
    { lat: 49.1469723, lng: 18.734656 },
    { lat: 49.1469634, lng: 18.7344417 },
    { lat: 49.1469963, lng: 18.7342086 },
    { lat: 49.1469778, lng: 18.7338993 },
    { lat: 49.1470562, lng: 18.733629 },
    { lat: 49.1470848, lng: 18.7334655 },
    { lat: 49.1472483, lng: 18.7329482 },
    { lat: 49.1472421, lng: 18.7325322 },
    { lat: 49.1473903, lng: 18.7320444 },
    { lat: 49.1475153, lng: 18.7317609 },
    { lat: 49.1479013, lng: 18.7313165 },
    { lat: 49.1479382, lng: 18.7309432 },
    { lat: 49.1480174, lng: 18.7308175 },
    { lat: 49.148137, lng: 18.7307331 },
    { lat: 49.148108, lng: 18.7299459 },
    { lat: 49.1480944, lng: 18.7298799 },
    { lat: 49.1480574, lng: 18.7296888 },
    { lat: 49.148018, lng: 18.7297336 },
    { lat: 49.1480154, lng: 18.7298783 },
    { lat: 49.1476171, lng: 18.7299805 },
    { lat: 49.1473218, lng: 18.7299862 },
    { lat: 49.1471728, lng: 18.7299597 },
    { lat: 49.1469772, lng: 18.7298629 },
    { lat: 49.1469029, lng: 18.7298247 },
    { lat: 49.146547, lng: 18.7296394 },
    { lat: 49.1462825, lng: 18.7294115 },
    { lat: 49.1460991, lng: 18.7292041 },
    { lat: 49.1459341, lng: 18.7289814 },
    { lat: 49.1454546, lng: 18.7282247 },
    { lat: 49.145274, lng: 18.7279221 },
    { lat: 49.1449636, lng: 18.7275036 },
    { lat: 49.1449063, lng: 18.7274036 },
    { lat: 49.1447992, lng: 18.7272524 },
    { lat: 49.1446258, lng: 18.7269782 },
    { lat: 49.144358, lng: 18.7265588 },
    { lat: 49.1444322, lng: 18.7262976 },
    { lat: 49.1444081, lng: 18.7262022 },
    { lat: 49.1442666, lng: 18.7264064 },
    { lat: 49.1441153, lng: 18.7261432 },
    { lat: 49.1438989, lng: 18.7256944 },
    { lat: 49.1437615, lng: 18.7253539 },
    { lat: 49.1436451, lng: 18.7250046 },
    { lat: 49.1433972, lng: 18.7241488 },
    { lat: 49.1432611, lng: 18.723664 },
    { lat: 49.1431701, lng: 18.7233924 },
    { lat: 49.1429865, lng: 18.7228565 },
    { lat: 49.1424837, lng: 18.7222371 },
    { lat: 49.1422827, lng: 18.7219958 },
    { lat: 49.1420415, lng: 18.7217183 },
    { lat: 49.1417248, lng: 18.7213802 },
    { lat: 49.1414328, lng: 18.7210571 },
    { lat: 49.1412562, lng: 18.7208755 },
    { lat: 49.1412443, lng: 18.7208607 },
    { lat: 49.1412336, lng: 18.7208505 },
    { lat: 49.1411808, lng: 18.7207896 },
    { lat: 49.1411101, lng: 18.7207118 },
    { lat: 49.1410269, lng: 18.7206531 },
    { lat: 49.1409126, lng: 18.7205374 },
    { lat: 49.1411341, lng: 18.7200303 },
    { lat: 49.1409578, lng: 18.7199585 },
    { lat: 49.1404373, lng: 18.7195361 },
    { lat: 49.1402749, lng: 18.7198599 },
    { lat: 49.1402678, lng: 18.719855 },
    { lat: 49.1401811, lng: 18.7197687 },
    { lat: 49.1397909, lng: 18.7193179 },
    { lat: 49.1397645, lng: 18.719289 },
    { lat: 49.1397494, lng: 18.7193247 },
    { lat: 49.1397264, lng: 18.7193772 },
    { lat: 49.1396852, lng: 18.7197082 },
    { lat: 49.1396347, lng: 18.7201873 },
    { lat: 49.1396578, lng: 18.7203703 },
    { lat: 49.1397567, lng: 18.7205351 },
    { lat: 49.1396668, lng: 18.7206897 },
    { lat: 49.1395825, lng: 18.7207404 },
    { lat: 49.1394768, lng: 18.7209823 },
    { lat: 49.1392756, lng: 18.7212492 },
    { lat: 49.1391466, lng: 18.721502 },
    { lat: 49.1392313, lng: 18.7218233 },
    { lat: 49.1391465, lng: 18.7220141 },
    { lat: 49.1393203, lng: 18.7223617 },
    { lat: 49.1392378, lng: 18.7226368 },
    { lat: 49.139241, lng: 18.7228457 },
    { lat: 49.1391641, lng: 18.7229934 },
    { lat: 49.139134, lng: 18.7230006 },
    { lat: 49.139073, lng: 18.7229775 },
    { lat: 49.1390236, lng: 18.7229806 },
    { lat: 49.1389293, lng: 18.7231603 },
    { lat: 49.1389185, lng: 18.7232106 },
    { lat: 49.1390031, lng: 18.7231927 },
    { lat: 49.1390264, lng: 18.7232273 },
    { lat: 49.1390433, lng: 18.7235264 },
    { lat: 49.1386754, lng: 18.7236533 },
    { lat: 49.1386597, lng: 18.7236974 },
    { lat: 49.1387204, lng: 18.7240375 },
    { lat: 49.1387, lng: 18.7241625 },
    { lat: 49.1386387, lng: 18.7242193 },
    { lat: 49.138603, lng: 18.724313 },
    { lat: 49.138606, lng: 18.7245013 },
    { lat: 49.1385824, lng: 18.7246515 },
    { lat: 49.1385214, lng: 18.7249297 },
    { lat: 49.1384492, lng: 18.725079 },
    { lat: 49.1383384, lng: 18.7250943 },
    { lat: 49.1382633, lng: 18.7251885 },
    { lat: 49.1379952, lng: 18.7252813 },
    { lat: 49.1379676, lng: 18.7256145 },
    { lat: 49.1378957, lng: 18.7258747 },
    { lat: 49.1377989, lng: 18.7258814 },
    { lat: 49.1376945, lng: 18.7258232 },
    { lat: 49.1375825, lng: 18.7260764 },
    { lat: 49.1374175, lng: 18.7260698 },
    { lat: 49.1373278, lng: 18.7262922 },
    { lat: 49.1373215, lng: 18.7264121 },
    { lat: 49.13743, lng: 18.7264789 },
    { lat: 49.1374027, lng: 18.7266669 },
    { lat: 49.1372224, lng: 18.7269507 },
    { lat: 49.1371596, lng: 18.7271276 },
    { lat: 49.1370193, lng: 18.7272906 },
    { lat: 49.1370772, lng: 18.7274067 },
    { lat: 49.1369011, lng: 18.7277663 },
    { lat: 49.1366361, lng: 18.7277053 },
    { lat: 49.1364595, lng: 18.7280794 },
    { lat: 49.1365171, lng: 18.7282525 },
    { lat: 49.1362826, lng: 18.7284672 },
    { lat: 49.1363122, lng: 18.7287521 },
    { lat: 49.1362926, lng: 18.7289832 },
    { lat: 49.1360686, lng: 18.7292254 },
    { lat: 49.1360431, lng: 18.7293211 },
    { lat: 49.1359337, lng: 18.7293852 },
    { lat: 49.1358314, lng: 18.7294971 },
    { lat: 49.1356837, lng: 18.7294978 },
    { lat: 49.1357407, lng: 18.7297001 },
    { lat: 49.1356707, lng: 18.7299394 },
    { lat: 49.1355585, lng: 18.7299491 },
    { lat: 49.1354154, lng: 18.7301214 },
    { lat: 49.1353502, lng: 18.7302982 },
    { lat: 49.1351749, lng: 18.730204 },
    { lat: 49.135238, lng: 18.7305244 },
    { lat: 49.1350007, lng: 18.7308158 },
    { lat: 49.1349044, lng: 18.7308722 },
    { lat: 49.1348722, lng: 18.731032 },
    { lat: 49.1346811, lng: 18.7312152 },
    { lat: 49.1344636, lng: 18.7312626 },
    { lat: 49.1344061, lng: 18.7312422 },
    { lat: 49.1342835, lng: 18.7315495 },
    { lat: 49.1342581, lng: 18.7317946 },
    { lat: 49.1343134, lng: 18.7319255 },
    { lat: 49.1341011, lng: 18.7319373 },
    { lat: 49.1340102, lng: 18.7321197 },
    { lat: 49.1339238, lng: 18.7321702 },
    { lat: 49.1338494, lng: 18.7323017 },
    { lat: 49.1336817, lng: 18.732285 },
    { lat: 49.133553, lng: 18.7326936 },
    { lat: 49.1334781, lng: 18.7325759 },
    { lat: 49.1334406, lng: 18.7324203 },
    { lat: 49.1333509, lng: 18.7325389 },
    { lat: 49.1333175, lng: 18.7326952 },
    { lat: 49.13322, lng: 18.732836 },
    { lat: 49.1331627, lng: 18.7332262 },
    { lat: 49.1330419, lng: 18.7330939 },
    { lat: 49.1329804, lng: 18.7331255 },
    { lat: 49.1329838, lng: 18.7333434 },
    { lat: 49.1328427, lng: 18.733447 },
    { lat: 49.1327648, lng: 18.7336599 },
    { lat: 49.1327431, lng: 18.7338037 },
    { lat: 49.1325805, lng: 18.7339297 },
    { lat: 49.1323518, lng: 18.734107 },
    { lat: 49.1323073, lng: 18.7339025 },
    { lat: 49.1322002, lng: 18.7340329 },
    { lat: 49.1322569, lng: 18.7345316 },
    { lat: 49.1318847, lng: 18.7345765 },
    { lat: 49.1318429, lng: 18.7346813 },
    { lat: 49.1316844, lng: 18.7346905 },
    { lat: 49.1313518, lng: 18.73478 },
    { lat: 49.1312521, lng: 18.7346621 },
    { lat: 49.1311204, lng: 18.7346875 },
    { lat: 49.1310033, lng: 18.7345407 },
    { lat: 49.1308971, lng: 18.7344075 },
    { lat: 49.1307577, lng: 18.7344803 },
    { lat: 49.1306262, lng: 18.7344246 },
    { lat: 49.1304114, lng: 18.7345224 },
    { lat: 49.1303196, lng: 18.7346431 },
    { lat: 49.1302798, lng: 18.7346141 },
    { lat: 49.130321, lng: 18.7344751 },
    { lat: 49.1302427, lng: 18.7343564 },
    { lat: 49.1301011, lng: 18.7343697 },
    { lat: 49.1298824, lng: 18.7346279 },
    { lat: 49.1298134, lng: 18.7346236 },
    { lat: 49.129653, lng: 18.734336 },
    { lat: 49.1295496, lng: 18.7344112 },
    { lat: 49.1294933, lng: 18.7343004 },
    { lat: 49.129356, lng: 18.7341997 },
    { lat: 49.1292974, lng: 18.7341568 },
    { lat: 49.1292635, lng: 18.7340691 },
    { lat: 49.1291599, lng: 18.733955 },
    { lat: 49.1290599, lng: 18.733845 },
    { lat: 49.1289796, lng: 18.7338598 },
    { lat: 49.128964, lng: 18.7336702 },
    { lat: 49.1288585, lng: 18.7336585 },
    { lat: 49.1288356, lng: 18.7334846 },
    { lat: 49.1288098, lng: 18.7332885 },
    { lat: 49.1286617, lng: 18.7331946 },
    { lat: 49.1285774, lng: 18.7331445 },
    { lat: 49.1285056, lng: 18.7331019 },
    { lat: 49.1284426, lng: 18.7330566 },
    { lat: 49.1283745, lng: 18.7330077 },
    { lat: 49.1283482, lng: 18.7330682 },
    { lat: 49.1282536, lng: 18.733286 },
    { lat: 49.1281929, lng: 18.7331509 },
    { lat: 49.1281605, lng: 18.7330788 },
    { lat: 49.1280049, lng: 18.7330127 },
    { lat: 49.1278524, lng: 18.7330961 },
    { lat: 49.1278457, lng: 18.7332662 },
    { lat: 49.1276941, lng: 18.7332601 },
    { lat: 49.1276971, lng: 18.7330603 },
    { lat: 49.127634, lng: 18.7329729 },
    { lat: 49.127514, lng: 18.7328984 },
    { lat: 49.1274124, lng: 18.7328353 },
    { lat: 49.1272737, lng: 18.7326826 },
    { lat: 49.1272093, lng: 18.7327468 },
    { lat: 49.1271087, lng: 18.7328471 },
    { lat: 49.1269705, lng: 18.7329204 },
    { lat: 49.1268182, lng: 18.7328781 },
    { lat: 49.1267288, lng: 18.7327633 },
    { lat: 49.1263995, lng: 18.7327418 },
    { lat: 49.1262788, lng: 18.7326543 },
    { lat: 49.126186, lng: 18.7324556 },
    { lat: 49.1260578, lng: 18.7323546 },
    { lat: 49.1259121, lng: 18.7322434 },
    { lat: 49.1258159, lng: 18.7322566 },
    { lat: 49.1257135, lng: 18.732305 },
    { lat: 49.125619, lng: 18.7325214 },
    { lat: 49.1253076, lng: 18.7326943 },
    { lat: 49.1251885, lng: 18.7326577 },
    { lat: 49.1251151, lng: 18.7327998 },
    { lat: 49.1250789, lng: 18.7328698 },
    { lat: 49.1250043, lng: 18.7328821 },
    { lat: 49.1248811, lng: 18.7329025 },
    { lat: 49.1248468, lng: 18.7329778 },
    { lat: 49.1247769, lng: 18.7331312 },
    { lat: 49.124333, lng: 18.733097 },
    { lat: 49.1243062, lng: 18.7331521 },
    { lat: 49.1241834, lng: 18.7334039 },
    { lat: 49.1229291, lng: 18.7359775 },
    { lat: 49.1218459, lng: 18.7386564 },
    { lat: 49.1213341, lng: 18.7393065 },
    { lat: 49.1212763, lng: 18.7398373 },
    { lat: 49.1206259, lng: 18.7408136 },
    { lat: 49.1201261, lng: 18.7416472 },
    { lat: 49.1195088, lng: 18.7426079 },
    { lat: 49.1167974, lng: 18.7469082 },
    { lat: 49.1158185, lng: 18.7484601 },
    { lat: 49.1157411, lng: 18.748747 },
    { lat: 49.1155458, lng: 18.7497106 },
    { lat: 49.1152758, lng: 18.7502877 },
    { lat: 49.1147518, lng: 18.7508318 },
    { lat: 49.114219, lng: 18.7510811 },
    { lat: 49.1141425, lng: 18.7516868 },
    { lat: 49.1140297, lng: 18.7524172 },
    { lat: 49.1140157, lng: 18.7531453 },
    { lat: 49.1136648, lng: 18.754199 },
    { lat: 49.1134255, lng: 18.7552265 },
    { lat: 49.1131752, lng: 18.7559624 },
    { lat: 49.1130409, lng: 18.7576636 },
    { lat: 49.1130107, lng: 18.7580467 },
    { lat: 49.1130068, lng: 18.7580957 },
    { lat: 49.1131013, lng: 18.7594697 },
    { lat: 49.1136351, lng: 18.7611254 },
    { lat: 49.1136449, lng: 18.7611558 },
    { lat: 49.1139433, lng: 18.7618425 },
    { lat: 49.1140084, lng: 18.7619924 },
    { lat: 49.1142744, lng: 18.7625455 },
    { lat: 49.1142919, lng: 18.7626448 },
    { lat: 49.1143278, lng: 18.7627274 },
    { lat: 49.1143778, lng: 18.7627779 },
    { lat: 49.1146383, lng: 18.7630407 },
    { lat: 49.1153551, lng: 18.7637639 },
    { lat: 49.1155599, lng: 18.7641846 },
    { lat: 49.1157027, lng: 18.7644781 },
    { lat: 49.1161621, lng: 18.7654219 },
    { lat: 49.1171688, lng: 18.7668659 },
    { lat: 49.1166293, lng: 18.7680464 },
    { lat: 49.1164245, lng: 18.768352 },
    { lat: 49.1157971, lng: 18.7692879 },
    { lat: 49.1157309, lng: 18.7693867 },
    { lat: 49.1156543, lng: 18.7695009 },
    { lat: 49.1155342, lng: 18.7697199 },
    { lat: 49.115435, lng: 18.7699007 },
    { lat: 49.1152913, lng: 18.7701626 },
    { lat: 49.1152104, lng: 18.7703101 },
    { lat: 49.1151984, lng: 18.7703823 },
    { lat: 49.115173, lng: 18.7705354 },
    { lat: 49.1151107, lng: 18.7709107 },
    { lat: 49.1151014, lng: 18.7709666 },
    { lat: 49.115069, lng: 18.7710334 },
    { lat: 49.1148704, lng: 18.7714437 },
    { lat: 49.1145189, lng: 18.7719261 },
    { lat: 49.1143047, lng: 18.7720706 },
    { lat: 49.1140945, lng: 18.7722124 },
    { lat: 49.1134391, lng: 18.7726545 },
    { lat: 49.1133137, lng: 18.7728404 },
    { lat: 49.1129346, lng: 18.7734026 },
    { lat: 49.1122666, lng: 18.774075 },
    { lat: 49.1119146, lng: 18.7744293 },
    { lat: 49.11191, lng: 18.7746421 },
    { lat: 49.111898, lng: 18.7751917 },
    { lat: 49.1118861, lng: 18.7757357 },
    { lat: 49.1118752, lng: 18.7762379 },
    { lat: 49.1118647, lng: 18.7767199 },
    { lat: 49.1119894, lng: 18.7772843 },
    { lat: 49.1121922, lng: 18.7782017 },
    { lat: 49.1122072, lng: 18.7782698 },
    { lat: 49.1119293, lng: 18.7796354 },
    { lat: 49.1118318, lng: 18.7801145 },
    { lat: 49.1116943, lng: 18.7803016 },
    { lat: 49.1109287, lng: 18.7813436 },
    { lat: 49.1107682, lng: 18.781562 },
    { lat: 49.109904, lng: 18.7832463 },
    { lat: 49.1090696, lng: 18.7854115 },
    { lat: 49.1086342, lng: 18.7872779 },
    { lat: 49.1081274, lng: 18.78872 },
    { lat: 49.1076118, lng: 18.7899979 },
    { lat: 49.1072553, lng: 18.7907761 },
    { lat: 49.106034, lng: 18.7928328 },
    { lat: 49.1048223, lng: 18.7944722 },
    { lat: 49.1041556, lng: 18.7950513 },
    { lat: 49.1023654, lng: 18.795473 },
    { lat: 49.1021318, lng: 18.7957746 },
    { lat: 49.1019315, lng: 18.7960222 },
    { lat: 49.1019626, lng: 18.7965345 },
    { lat: 49.1022102, lng: 18.7969797 },
    { lat: 49.1022431, lng: 18.7983753 },
    { lat: 49.1020163, lng: 18.7996322 },
    { lat: 49.1016873, lng: 18.8005746 },
    { lat: 49.1014661, lng: 18.8011338 },
    { lat: 49.1012409, lng: 18.8016852 },
    { lat: 49.101017, lng: 18.8024761 },
    { lat: 49.1006999, lng: 18.8040612 },
    { lat: 49.1007341, lng: 18.8055868 },
    { lat: 49.1008069, lng: 18.8072748 },
    { lat: 49.1007847, lng: 18.8079136 },
    { lat: 49.1004908, lng: 18.8098169 },
    { lat: 49.1001704, lng: 18.8118852 },
    { lat: 49.1001251, lng: 18.8121195 },
    { lat: 49.0997279, lng: 18.8139459 },
    { lat: 49.0996233, lng: 18.8150153 },
    { lat: 49.09951, lng: 18.816264 },
    { lat: 49.099511, lng: 18.816266 },
    { lat: 49.099726, lng: 18.816811 },
    { lat: 49.099939, lng: 18.817354 },
    { lat: 49.09994, lng: 18.817354 },
    { lat: 49.099941, lng: 18.817355 },
    { lat: 49.101006, lng: 18.81766 },
    { lat: 49.101547, lng: 18.817666 },
    { lat: 49.101841, lng: 18.81767 },
    { lat: 49.102031, lng: 18.817672 },
    { lat: 49.102219, lng: 18.817683 },
    { lat: 49.102221, lng: 18.817683 },
    { lat: 49.103361, lng: 18.817746 },
    { lat: 49.104089, lng: 18.817777 },
    { lat: 49.104837, lng: 18.818774 },
    { lat: 49.105418, lng: 18.819984 },
    { lat: 49.10548, lng: 18.820111 },
    { lat: 49.105509, lng: 18.820134 },
    { lat: 49.106236, lng: 18.820734 },
    { lat: 49.10635, lng: 18.820822 },
    { lat: 49.107202, lng: 18.821494 },
    { lat: 49.108054, lng: 18.822164 },
    { lat: 49.108842, lng: 18.822516 },
    { lat: 49.108883, lng: 18.822535 },
    { lat: 49.10889, lng: 18.822534 },
    { lat: 49.109663, lng: 18.822411 },
    { lat: 49.110145, lng: 18.822267 },
    { lat: 49.1109239, lng: 18.8223966 },
    { lat: 49.111263, lng: 18.822453 },
    { lat: 49.112023, lng: 18.822557 },
    { lat: 49.11284, lng: 18.822938 },
    { lat: 49.113494, lng: 18.823648 },
    { lat: 49.113987, lng: 18.824474 },
    { lat: 49.114015, lng: 18.824521 },
    { lat: 49.114047, lng: 18.824553 },
    { lat: 49.114074, lng: 18.82458 },
    { lat: 49.114579, lng: 18.825078 },
    { lat: 49.115394, lng: 18.825882 },
    { lat: 49.116049, lng: 18.826923 },
    { lat: 49.116491, lng: 18.82762 },
    { lat: 49.11655, lng: 18.827712 },
    { lat: 49.1166, lng: 18.827791 },
    { lat: 49.116638, lng: 18.82785 },
    { lat: 49.116638, lng: 18.827851 },
    { lat: 49.116639, lng: 18.827854 },
    { lat: 49.11683, lng: 18.827994 },
    { lat: 49.117229, lng: 18.828284 },
    { lat: 49.117439, lng: 18.828437 },
    { lat: 49.117447, lng: 18.828444 },
    { lat: 49.117482, lng: 18.828452 },
    { lat: 49.11756, lng: 18.828469 },
    { lat: 49.117804, lng: 18.828525 },
    { lat: 49.118053, lng: 18.828425 },
    { lat: 49.118057, lng: 18.828424 },
    { lat: 49.118243, lng: 18.828348 },
    { lat: 49.118559, lng: 18.828361 },
    { lat: 49.118574, lng: 18.828362 },
    { lat: 49.119013, lng: 18.828379 },
    { lat: 49.119612, lng: 18.828291 },
    { lat: 49.119876, lng: 18.828252 },
    { lat: 49.120315, lng: 18.828142 },
    { lat: 49.120686, lng: 18.828051 },
    { lat: 49.120733, lng: 18.828039 },
    { lat: 49.121085, lng: 18.827952 },
    { lat: 49.1217217, lng: 18.8277971 },
    { lat: 49.122273, lng: 18.827663 },
    { lat: 49.123568, lng: 18.82737 },
    { lat: 49.124061, lng: 18.827237 },
    { lat: 49.1245652, lng: 18.8264359 },
    { lat: 49.1246519, lng: 18.8251263 },
    { lat: 49.1245354, lng: 18.8243986 },
    { lat: 49.1243123, lng: 18.8230245 },
    { lat: 49.1241157, lng: 18.8218809 },
    { lat: 49.123881, lng: 18.8204784 },
    { lat: 49.1242708, lng: 18.8188368 },
    { lat: 49.1243741, lng: 18.8180417 },
    { lat: 49.1244795, lng: 18.8176169 },
    { lat: 49.1246464, lng: 18.8171609 },
    { lat: 49.1247966, lng: 18.8172505 },
    { lat: 49.1249628, lng: 18.8174131 },
    { lat: 49.1250552, lng: 18.8174779 },
    { lat: 49.1252931, lng: 18.817696 },
    { lat: 49.1255441, lng: 18.8179896 },
    { lat: 49.1257393, lng: 18.8181047 },
    { lat: 49.1258592, lng: 18.8182635 },
    { lat: 49.1261234, lng: 18.8185098 },
    { lat: 49.1262922, lng: 18.8181965 },
    { lat: 49.126619, lng: 18.8176082 },
    { lat: 49.1267482, lng: 18.8171412 },
    { lat: 49.1267275, lng: 18.8165619 },
    { lat: 49.1268407, lng: 18.8163107 },
    { lat: 49.1269045, lng: 18.8159095 },
    { lat: 49.1273499, lng: 18.8155396 },
    { lat: 49.1273786, lng: 18.8153713 },
    { lat: 49.1273463, lng: 18.8150122 },
    { lat: 49.1273654, lng: 18.8147925 },
    { lat: 49.1273561, lng: 18.8141318 },
    { lat: 49.1272872, lng: 18.8136728 },
    { lat: 49.1272507, lng: 18.8134658 },
    { lat: 49.1271419, lng: 18.8128693 },
    { lat: 49.126957, lng: 18.8122129 },
    { lat: 49.1268512, lng: 18.8118033 },
    { lat: 49.127573, lng: 18.811327 },
    { lat: 49.1283732, lng: 18.810797 },
    { lat: 49.1292506, lng: 18.8099488 },
    { lat: 49.1298717, lng: 18.8095538 },
    { lat: 49.1300612, lng: 18.8094687 },
    { lat: 49.1304368, lng: 18.8093209 },
    { lat: 49.1307037, lng: 18.8092266 },
    { lat: 49.1310669, lng: 18.8087894 },
    { lat: 49.1316771, lng: 18.8077507 },
    { lat: 49.1320499, lng: 18.807326 },
    { lat: 49.1322629, lng: 18.8069983 },
    { lat: 49.1326543, lng: 18.8064122 },
    { lat: 49.1330455, lng: 18.8058456 },
    { lat: 49.1332707, lng: 18.8052521 },
    { lat: 49.1336267, lng: 18.8049187 },
    { lat: 49.134004, lng: 18.803954 },
    { lat: 49.1344792, lng: 18.8030251 },
    { lat: 49.1346013, lng: 18.8020284 },
    { lat: 49.1347681, lng: 18.8014398 },
    { lat: 49.1349246, lng: 18.8008725 },
    { lat: 49.1349375, lng: 18.8008612 },
    { lat: 49.135317, lng: 18.8005239 },
    { lat: 49.1353958, lng: 18.8004528 },
    { lat: 49.1356143, lng: 18.8000383 },
    { lat: 49.1358126, lng: 18.7996418 },
    { lat: 49.1364179, lng: 18.7990521 },
    { lat: 49.1365573, lng: 18.7988708 },
    { lat: 49.1368755, lng: 18.7984414 },
    { lat: 49.13693, lng: 18.7983164 },
    { lat: 49.1370588, lng: 18.7980149 },
    { lat: 49.1372971, lng: 18.7977179 },
    { lat: 49.1379071, lng: 18.7972836 },
    { lat: 49.138421, lng: 18.7974735 },
    { lat: 49.138431, lng: 18.7975162 },
    { lat: 49.1384511, lng: 18.7974893 },
    { lat: 49.138876, lng: 18.7968349 },
    { lat: 49.1390941, lng: 18.7961475 },
    { lat: 49.1394169, lng: 18.7953494 },
    { lat: 49.1397812, lng: 18.7950898 },
    { lat: 49.1399913, lng: 18.7948981 },
    { lat: 49.1402677, lng: 18.7946653 },
    { lat: 49.1400458, lng: 18.7941457 },
    { lat: 49.1396762, lng: 18.7929557 },
    { lat: 49.1392469, lng: 18.7923326 },
    { lat: 49.1392606, lng: 18.7916406 },
    { lat: 49.1393446, lng: 18.7914795 },
    { lat: 49.1395089, lng: 18.7911662 },
    { lat: 49.1397608, lng: 18.7910217 },
    { lat: 49.1402693, lng: 18.7907616 },
    { lat: 49.1406951, lng: 18.7899702 },
    { lat: 49.1407551, lng: 18.7891508 },
    { lat: 49.1409688, lng: 18.7887069 },
    { lat: 49.141617, lng: 18.7883536 },
    { lat: 49.1420172, lng: 18.7879833 },
    { lat: 49.1428356, lng: 18.7869158 },
    { lat: 49.1430555, lng: 18.7864139 },
    { lat: 49.1440129, lng: 18.7855276 },
    { lat: 49.1442755, lng: 18.7850914 },
    { lat: 49.1445092, lng: 18.7842287 },
    { lat: 49.1445766, lng: 18.7837012 },
    { lat: 49.1445901, lng: 18.7832022 },
    { lat: 49.1445581, lng: 18.7827814 },
    { lat: 49.1444983, lng: 18.7818493 },
    { lat: 49.1446252, lng: 18.7807538 },
    { lat: 49.1449175, lng: 18.7800003 },
    { lat: 49.1454955, lng: 18.7796463 },
    { lat: 49.1460383, lng: 18.7789385 },
    { lat: 49.1465042, lng: 18.7784421 },
    { lat: 49.1466697, lng: 18.7782445 },
    { lat: 49.147331, lng: 18.7775379 },
    { lat: 49.1481947, lng: 18.7767131 },
    { lat: 49.1491024, lng: 18.7761924 },
    { lat: 49.1491016, lng: 18.7761993 },
    { lat: 49.1491264, lng: 18.7761772 },
    { lat: 49.1491437, lng: 18.7761413 },
    { lat: 49.1491626, lng: 18.7761152 },
    { lat: 49.1491673, lng: 18.7760966 },
    { lat: 49.1492023, lng: 18.7760506 },
    { lat: 49.1492172, lng: 18.7760384 },
    { lat: 49.1492707, lng: 18.7760262 },
    { lat: 49.1494667, lng: 18.7758468 },
    { lat: 49.14959, lng: 18.7757228 },
    { lat: 49.1496555, lng: 18.7756382 },
    { lat: 49.1496899, lng: 18.7756088 },
    { lat: 49.1499588, lng: 18.7754642 },
    { lat: 49.149975, lng: 18.7754518 },
    { lat: 49.1499833, lng: 18.7754393 },
    { lat: 49.1499978, lng: 18.7754372 },
    { lat: 49.1500665, lng: 18.7754127 },
    { lat: 49.1501014, lng: 18.775389 },
    { lat: 49.1501134, lng: 18.7753832 },
    { lat: 49.1502534, lng: 18.7753634 },
    { lat: 49.1503304, lng: 18.7752803 },
    { lat: 49.1503716, lng: 18.7752277 },
    { lat: 49.1503859, lng: 18.7752026 },
    { lat: 49.1504014, lng: 18.7751503 },
    { lat: 49.1504063, lng: 18.7750953 },
    { lat: 49.1504478, lng: 18.7749801 },
    { lat: 49.1505078, lng: 18.7748972 },
    { lat: 49.1505129, lng: 18.7748865 },
    { lat: 49.1505225, lng: 18.774881 },
    { lat: 49.1505306, lng: 18.7748815 },
    { lat: 49.150572, lng: 18.7748548 },
    { lat: 49.150588, lng: 18.7748363 },
    { lat: 49.1506036, lng: 18.7748139 },
    { lat: 49.1506456, lng: 18.7747307 },
    { lat: 49.1506573, lng: 18.7747145 },
    { lat: 49.1506865, lng: 18.7746259 },
    { lat: 49.1507154, lng: 18.7745554 },
    { lat: 49.1507319, lng: 18.7745452 },
    { lat: 49.1507377, lng: 18.774529 },
    { lat: 49.1507454, lng: 18.7745237 },
    { lat: 49.1507689, lng: 18.7744564 },
    { lat: 49.1507839, lng: 18.7743544 },
    { lat: 49.1508018, lng: 18.7742867 },
    { lat: 49.1508133, lng: 18.7742713 },
    { lat: 49.1508523, lng: 18.7741747 },
    { lat: 49.1508617, lng: 18.7741379 },
    { lat: 49.1508739, lng: 18.774028 },
    { lat: 49.1508917, lng: 18.7739797 },
    { lat: 49.1509957, lng: 18.7738299 },
    { lat: 49.1510133, lng: 18.7737597 },
    { lat: 49.1510553, lng: 18.7737168 },
    { lat: 49.151097, lng: 18.773634 },
    { lat: 49.151145, lng: 18.7736022 },
    { lat: 49.151151, lng: 18.773564 },
    { lat: 49.1512764, lng: 18.7733309 },
    { lat: 49.1513088, lng: 18.7732589 },
    { lat: 49.1513585, lng: 18.773174 },
    { lat: 49.1513919, lng: 18.7731253 },
    { lat: 49.1514254, lng: 18.7730975 },
    { lat: 49.1514842, lng: 18.7729913 },
    { lat: 49.1515149, lng: 18.7729512 },
    { lat: 49.1515533, lng: 18.7729307 },
    { lat: 49.1516045, lng: 18.7728514 },
    { lat: 49.1516682, lng: 18.7728415 },
    { lat: 49.1517197, lng: 18.7728126 },
    { lat: 49.1518636, lng: 18.7727635 },
    { lat: 49.1518763, lng: 18.7727483 },
    { lat: 49.1519365, lng: 18.7727108 },
    { lat: 49.1519669, lng: 18.7726228 },
    { lat: 49.1520057, lng: 18.7725871 },
    { lat: 49.152003, lng: 18.772581 },
    { lat: 49.1519546, lng: 18.7724957 },
    { lat: 49.151289, lng: 18.770908 },
    { lat: 49.1520634, lng: 18.7694973 },
    { lat: 49.1535918, lng: 18.7663879 },
    { lat: 49.1536291, lng: 18.7663048 },
    { lat: 49.1536646, lng: 18.7662259 },
    { lat: 49.1536928, lng: 18.7661624 },
    { lat: 49.153898, lng: 18.7663782 },
    { lat: 49.1542213, lng: 18.7667191 },
    { lat: 49.1549042, lng: 18.7674225 },
    { lat: 49.155061, lng: 18.767598 },
    { lat: 49.1552824, lng: 18.767952 },
    { lat: 49.1555427, lng: 18.7684039 },
    { lat: 49.1557805, lng: 18.7687685 },
    { lat: 49.1558224, lng: 18.7688607 },
    { lat: 49.1560385, lng: 18.7691131 },
    { lat: 49.1560375, lng: 18.7690937 },
    { lat: 49.1560364, lng: 18.7690719 },
    { lat: 49.1560516, lng: 18.7689281 },
    { lat: 49.1560964, lng: 18.768749 },
    { lat: 49.1561225, lng: 18.7686472 },
    { lat: 49.1561119, lng: 18.7681167 },
    { lat: 49.1560415, lng: 18.7678918 },
    { lat: 49.1558804, lng: 18.7673518 },
    { lat: 49.1558791, lng: 18.7673498 },
    { lat: 49.1558986, lng: 18.7672484 },
    { lat: 49.1559196, lng: 18.7671338 },
    { lat: 49.1559515, lng: 18.7670935 },
    { lat: 49.1560174, lng: 18.7670207 },
    { lat: 49.1562666, lng: 18.7664105 },
    { lat: 49.1563588, lng: 18.7662985 },
    { lat: 49.1564903, lng: 18.7662455 },
    { lat: 49.1569326, lng: 18.766577 },
    { lat: 49.1569575, lng: 18.766556 },
    { lat: 49.1573771, lng: 18.766191 },
    { lat: 49.1574074, lng: 18.7662554 },
    { lat: 49.1577106, lng: 18.7669011 },
    { lat: 49.1577474, lng: 18.7668044 },
    { lat: 49.1578083, lng: 18.7665909 },
    { lat: 49.1579532, lng: 18.7662329 },
    { lat: 49.1580856, lng: 18.7659402 },
    { lat: 49.1581786, lng: 18.7658205 },
    { lat: 49.1582663, lng: 18.7657076 },
    { lat: 49.1582853, lng: 18.7656826 },
    { lat: 49.1586272, lng: 18.765554 },
    { lat: 49.1587596, lng: 18.7654785 },
  ],
  Višňové: [
    { lat: 49.1587596, lng: 18.7654785 },
    { lat: 49.1586272, lng: 18.765554 },
    { lat: 49.1582853, lng: 18.7656826 },
    { lat: 49.1582663, lng: 18.7657076 },
    { lat: 49.1581786, lng: 18.7658205 },
    { lat: 49.1580856, lng: 18.7659402 },
    { lat: 49.1579532, lng: 18.7662329 },
    { lat: 49.1578083, lng: 18.7665909 },
    { lat: 49.1577474, lng: 18.7668044 },
    { lat: 49.1577106, lng: 18.7669011 },
    { lat: 49.1574074, lng: 18.7662554 },
    { lat: 49.1573771, lng: 18.766191 },
    { lat: 49.1569575, lng: 18.766556 },
    { lat: 49.1569326, lng: 18.766577 },
    { lat: 49.1564903, lng: 18.7662455 },
    { lat: 49.1563588, lng: 18.7662985 },
    { lat: 49.1562666, lng: 18.7664105 },
    { lat: 49.1560174, lng: 18.7670207 },
    { lat: 49.1559515, lng: 18.7670935 },
    { lat: 49.1559196, lng: 18.7671338 },
    { lat: 49.1558986, lng: 18.7672484 },
    { lat: 49.1558791, lng: 18.7673498 },
    { lat: 49.1558804, lng: 18.7673518 },
    { lat: 49.1560415, lng: 18.7678918 },
    { lat: 49.1561119, lng: 18.7681167 },
    { lat: 49.1561225, lng: 18.7686472 },
    { lat: 49.1560964, lng: 18.768749 },
    { lat: 49.1560516, lng: 18.7689281 },
    { lat: 49.1560364, lng: 18.7690719 },
    { lat: 49.1560375, lng: 18.7690937 },
    { lat: 49.1560385, lng: 18.7691131 },
    { lat: 49.1558224, lng: 18.7688607 },
    { lat: 49.1557805, lng: 18.7687685 },
    { lat: 49.1555427, lng: 18.7684039 },
    { lat: 49.1552824, lng: 18.767952 },
    { lat: 49.155061, lng: 18.767598 },
    { lat: 49.1549042, lng: 18.7674225 },
    { lat: 49.1542213, lng: 18.7667191 },
    { lat: 49.153898, lng: 18.7663782 },
    { lat: 49.1536928, lng: 18.7661624 },
    { lat: 49.1536646, lng: 18.7662259 },
    { lat: 49.1536291, lng: 18.7663048 },
    { lat: 49.1535918, lng: 18.7663879 },
    { lat: 49.1520634, lng: 18.7694973 },
    { lat: 49.151289, lng: 18.770908 },
    { lat: 49.1519546, lng: 18.7724957 },
    { lat: 49.152003, lng: 18.772581 },
    { lat: 49.1520057, lng: 18.7725871 },
    { lat: 49.1519669, lng: 18.7726228 },
    { lat: 49.1519365, lng: 18.7727108 },
    { lat: 49.1518763, lng: 18.7727483 },
    { lat: 49.1518636, lng: 18.7727635 },
    { lat: 49.1517197, lng: 18.7728126 },
    { lat: 49.1516682, lng: 18.7728415 },
    { lat: 49.1516045, lng: 18.7728514 },
    { lat: 49.1515533, lng: 18.7729307 },
    { lat: 49.1515149, lng: 18.7729512 },
    { lat: 49.1514842, lng: 18.7729913 },
    { lat: 49.1514254, lng: 18.7730975 },
    { lat: 49.1513919, lng: 18.7731253 },
    { lat: 49.1513585, lng: 18.773174 },
    { lat: 49.1513088, lng: 18.7732589 },
    { lat: 49.1512764, lng: 18.7733309 },
    { lat: 49.151151, lng: 18.773564 },
    { lat: 49.151145, lng: 18.7736022 },
    { lat: 49.151097, lng: 18.773634 },
    { lat: 49.1510553, lng: 18.7737168 },
    { lat: 49.1510133, lng: 18.7737597 },
    { lat: 49.1509957, lng: 18.7738299 },
    { lat: 49.1508917, lng: 18.7739797 },
    { lat: 49.1508739, lng: 18.774028 },
    { lat: 49.1508617, lng: 18.7741379 },
    { lat: 49.1508523, lng: 18.7741747 },
    { lat: 49.1508133, lng: 18.7742713 },
    { lat: 49.1508018, lng: 18.7742867 },
    { lat: 49.1507839, lng: 18.7743544 },
    { lat: 49.1507689, lng: 18.7744564 },
    { lat: 49.1507454, lng: 18.7745237 },
    { lat: 49.1507377, lng: 18.774529 },
    { lat: 49.1507319, lng: 18.7745452 },
    { lat: 49.1507154, lng: 18.7745554 },
    { lat: 49.1506865, lng: 18.7746259 },
    { lat: 49.1506573, lng: 18.7747145 },
    { lat: 49.1506456, lng: 18.7747307 },
    { lat: 49.1506036, lng: 18.7748139 },
    { lat: 49.150588, lng: 18.7748363 },
    { lat: 49.150572, lng: 18.7748548 },
    { lat: 49.1505306, lng: 18.7748815 },
    { lat: 49.1505225, lng: 18.774881 },
    { lat: 49.1505129, lng: 18.7748865 },
    { lat: 49.1505078, lng: 18.7748972 },
    { lat: 49.1504478, lng: 18.7749801 },
    { lat: 49.1504063, lng: 18.7750953 },
    { lat: 49.1504014, lng: 18.7751503 },
    { lat: 49.1503859, lng: 18.7752026 },
    { lat: 49.1503716, lng: 18.7752277 },
    { lat: 49.1503304, lng: 18.7752803 },
    { lat: 49.1502534, lng: 18.7753634 },
    { lat: 49.1501134, lng: 18.7753832 },
    { lat: 49.1501014, lng: 18.775389 },
    { lat: 49.1500665, lng: 18.7754127 },
    { lat: 49.1499978, lng: 18.7754372 },
    { lat: 49.1499833, lng: 18.7754393 },
    { lat: 49.149975, lng: 18.7754518 },
    { lat: 49.1499588, lng: 18.7754642 },
    { lat: 49.1496899, lng: 18.7756088 },
    { lat: 49.1496555, lng: 18.7756382 },
    { lat: 49.14959, lng: 18.7757228 },
    { lat: 49.1494667, lng: 18.7758468 },
    { lat: 49.1492707, lng: 18.7760262 },
    { lat: 49.1492172, lng: 18.7760384 },
    { lat: 49.1492023, lng: 18.7760506 },
    { lat: 49.1491673, lng: 18.7760966 },
    { lat: 49.1491626, lng: 18.7761152 },
    { lat: 49.1491437, lng: 18.7761413 },
    { lat: 49.1491264, lng: 18.7761772 },
    { lat: 49.1491016, lng: 18.7761993 },
    { lat: 49.1491024, lng: 18.7761924 },
    { lat: 49.1481947, lng: 18.7767131 },
    { lat: 49.147331, lng: 18.7775379 },
    { lat: 49.1466697, lng: 18.7782445 },
    { lat: 49.1465042, lng: 18.7784421 },
    { lat: 49.1460383, lng: 18.7789385 },
    { lat: 49.1454955, lng: 18.7796463 },
    { lat: 49.1449175, lng: 18.7800003 },
    { lat: 49.1446252, lng: 18.7807538 },
    { lat: 49.1444983, lng: 18.7818493 },
    { lat: 49.1445581, lng: 18.7827814 },
    { lat: 49.1445901, lng: 18.7832022 },
    { lat: 49.1445766, lng: 18.7837012 },
    { lat: 49.1445092, lng: 18.7842287 },
    { lat: 49.1442755, lng: 18.7850914 },
    { lat: 49.1440129, lng: 18.7855276 },
    { lat: 49.1430555, lng: 18.7864139 },
    { lat: 49.1428356, lng: 18.7869158 },
    { lat: 49.1420172, lng: 18.7879833 },
    { lat: 49.141617, lng: 18.7883536 },
    { lat: 49.1409688, lng: 18.7887069 },
    { lat: 49.1407551, lng: 18.7891508 },
    { lat: 49.1406951, lng: 18.7899702 },
    { lat: 49.1402693, lng: 18.7907616 },
    { lat: 49.1397608, lng: 18.7910217 },
    { lat: 49.1395089, lng: 18.7911662 },
    { lat: 49.1393446, lng: 18.7914795 },
    { lat: 49.1392606, lng: 18.7916406 },
    { lat: 49.1392469, lng: 18.7923326 },
    { lat: 49.1396762, lng: 18.7929557 },
    { lat: 49.1400458, lng: 18.7941457 },
    { lat: 49.1402677, lng: 18.7946653 },
    { lat: 49.1399913, lng: 18.7948981 },
    { lat: 49.1397812, lng: 18.7950898 },
    { lat: 49.1394169, lng: 18.7953494 },
    { lat: 49.1390941, lng: 18.7961475 },
    { lat: 49.138876, lng: 18.7968349 },
    { lat: 49.1384511, lng: 18.7974893 },
    { lat: 49.138431, lng: 18.7975162 },
    { lat: 49.138421, lng: 18.7974735 },
    { lat: 49.1379071, lng: 18.7972836 },
    { lat: 49.1372971, lng: 18.7977179 },
    { lat: 49.1370588, lng: 18.7980149 },
    { lat: 49.13693, lng: 18.7983164 },
    { lat: 49.1368755, lng: 18.7984414 },
    { lat: 49.1365573, lng: 18.7988708 },
    { lat: 49.1364179, lng: 18.7990521 },
    { lat: 49.1358126, lng: 18.7996418 },
    { lat: 49.1356143, lng: 18.8000383 },
    { lat: 49.1353958, lng: 18.8004528 },
    { lat: 49.135317, lng: 18.8005239 },
    { lat: 49.1349375, lng: 18.8008612 },
    { lat: 49.1349246, lng: 18.8008725 },
    { lat: 49.1347681, lng: 18.8014398 },
    { lat: 49.1346013, lng: 18.8020284 },
    { lat: 49.1344792, lng: 18.8030251 },
    { lat: 49.134004, lng: 18.803954 },
    { lat: 49.1336267, lng: 18.8049187 },
    { lat: 49.1332707, lng: 18.8052521 },
    { lat: 49.1330455, lng: 18.8058456 },
    { lat: 49.1326543, lng: 18.8064122 },
    { lat: 49.1322629, lng: 18.8069983 },
    { lat: 49.1320499, lng: 18.807326 },
    { lat: 49.1316771, lng: 18.8077507 },
    { lat: 49.1310669, lng: 18.8087894 },
    { lat: 49.1307037, lng: 18.8092266 },
    { lat: 49.1304368, lng: 18.8093209 },
    { lat: 49.1300612, lng: 18.8094687 },
    { lat: 49.1298717, lng: 18.8095538 },
    { lat: 49.1292506, lng: 18.8099488 },
    { lat: 49.1283732, lng: 18.810797 },
    { lat: 49.127573, lng: 18.811327 },
    { lat: 49.1268512, lng: 18.8118033 },
    { lat: 49.126957, lng: 18.8122129 },
    { lat: 49.1271419, lng: 18.8128693 },
    { lat: 49.1272507, lng: 18.8134658 },
    { lat: 49.1272872, lng: 18.8136728 },
    { lat: 49.1273561, lng: 18.8141318 },
    { lat: 49.1273654, lng: 18.8147925 },
    { lat: 49.1273463, lng: 18.8150122 },
    { lat: 49.1273786, lng: 18.8153713 },
    { lat: 49.1273499, lng: 18.8155396 },
    { lat: 49.1269045, lng: 18.8159095 },
    { lat: 49.1268407, lng: 18.8163107 },
    { lat: 49.1267275, lng: 18.8165619 },
    { lat: 49.1267482, lng: 18.8171412 },
    { lat: 49.126619, lng: 18.8176082 },
    { lat: 49.1262922, lng: 18.8181965 },
    { lat: 49.1261234, lng: 18.8185098 },
    { lat: 49.1258592, lng: 18.8182635 },
    { lat: 49.1257393, lng: 18.8181047 },
    { lat: 49.1255441, lng: 18.8179896 },
    { lat: 49.1252931, lng: 18.817696 },
    { lat: 49.1250552, lng: 18.8174779 },
    { lat: 49.1249628, lng: 18.8174131 },
    { lat: 49.1247966, lng: 18.8172505 },
    { lat: 49.1246464, lng: 18.8171609 },
    { lat: 49.1244795, lng: 18.8176169 },
    { lat: 49.1243741, lng: 18.8180417 },
    { lat: 49.1242708, lng: 18.8188368 },
    { lat: 49.123881, lng: 18.8204784 },
    { lat: 49.1241157, lng: 18.8218809 },
    { lat: 49.1243123, lng: 18.8230245 },
    { lat: 49.1245354, lng: 18.8243986 },
    { lat: 49.1246519, lng: 18.8251263 },
    { lat: 49.1245652, lng: 18.8264359 },
    { lat: 49.124061, lng: 18.827237 },
    { lat: 49.1243673, lng: 18.8273852 },
    { lat: 49.1246751, lng: 18.8275179 },
    { lat: 49.124958, lng: 18.8276468 },
    { lat: 49.1255426, lng: 18.8277675 },
    { lat: 49.1261127, lng: 18.8278674 },
    { lat: 49.1266785, lng: 18.8279717 },
    { lat: 49.1274875, lng: 18.8281812 },
    { lat: 49.1283281, lng: 18.8287624 },
    { lat: 49.1288246, lng: 18.8289643 },
    { lat: 49.1288271, lng: 18.8289453 },
    { lat: 49.1288273, lng: 18.8289295 },
    { lat: 49.1290818, lng: 18.828881 },
    { lat: 49.1291286, lng: 18.8288667 },
    { lat: 49.1291672, lng: 18.8288275 },
    { lat: 49.1295938, lng: 18.8288348 },
    { lat: 49.1300041, lng: 18.8286171 },
    { lat: 49.1300665, lng: 18.8285988 },
    { lat: 49.1302539, lng: 18.8286048 },
    { lat: 49.1303966, lng: 18.8284851 },
    { lat: 49.1307397, lng: 18.8285132 },
    { lat: 49.1313831, lng: 18.8283295 },
    { lat: 49.132015, lng: 18.8280796 },
    { lat: 49.1321355, lng: 18.8280115 },
    { lat: 49.1325338, lng: 18.8279915 },
    { lat: 49.133392, lng: 18.8278238 },
    { lat: 49.1339132, lng: 18.8275857 },
    { lat: 49.1356302, lng: 18.8275398 },
    { lat: 49.1366181, lng: 18.8275223 },
    { lat: 49.1371982, lng: 18.827375 },
    { lat: 49.1373746, lng: 18.8274097 },
    { lat: 49.1385357, lng: 18.8277769 },
    { lat: 49.1391033, lng: 18.827656 },
    { lat: 49.1395449, lng: 18.8275822 },
    { lat: 49.1398843, lng: 18.8275152 },
    { lat: 49.1401897, lng: 18.8275546 },
    { lat: 49.1403162, lng: 18.8276669 },
    { lat: 49.1405613, lng: 18.8279617 },
    { lat: 49.140708, lng: 18.828337 },
    { lat: 49.1405788, lng: 18.8289319 },
    { lat: 49.1405728, lng: 18.8292557 },
    { lat: 49.1407391, lng: 18.8296589 },
    { lat: 49.1409702, lng: 18.8299658 },
    { lat: 49.1411458, lng: 18.8300902 },
    { lat: 49.1415235, lng: 18.8307267 },
    { lat: 49.1418776, lng: 18.8309209 },
    { lat: 49.1422669, lng: 18.8311674 },
    { lat: 49.1423886, lng: 18.8312094 },
    { lat: 49.1426566, lng: 18.8311136 },
    { lat: 49.1429566, lng: 18.8309433 },
    { lat: 49.1432622, lng: 18.8305052 },
    { lat: 49.1433283, lng: 18.8303438 },
    { lat: 49.1437746, lng: 18.8295963 },
    { lat: 49.1440821, lng: 18.829374 },
    { lat: 49.1445901, lng: 18.8287924 },
    { lat: 49.1452371, lng: 18.828179 },
    { lat: 49.1453783, lng: 18.828095 },
    { lat: 49.1456594, lng: 18.8278366 },
    { lat: 49.1461773, lng: 18.8275621 },
    { lat: 49.1464127, lng: 18.8276228 },
    { lat: 49.1470402, lng: 18.827481 },
    { lat: 49.1470923, lng: 18.8273768 },
    { lat: 49.1471759, lng: 18.8273995 },
    { lat: 49.1480017, lng: 18.8271943 },
    { lat: 49.1480573, lng: 18.8271772 },
    { lat: 49.1480633, lng: 18.8272478 },
    { lat: 49.1482382, lng: 18.827381 },
    { lat: 49.1487924, lng: 18.8273059 },
    { lat: 49.1491798, lng: 18.8266861 },
    { lat: 49.1496422, lng: 18.8261638 },
    { lat: 49.1498793, lng: 18.8255789 },
    { lat: 49.1499674, lng: 18.8252319 },
    { lat: 49.1499687, lng: 18.82506 },
    { lat: 49.1502076, lng: 18.8245555 },
    { lat: 49.1503849, lng: 18.824013 },
    { lat: 49.1508489, lng: 18.8234992 },
    { lat: 49.1511632, lng: 18.8230156 },
    { lat: 49.1515946, lng: 18.8229181 },
    { lat: 49.1519224, lng: 18.8223255 },
    { lat: 49.1522249, lng: 18.8219376 },
    { lat: 49.1529771, lng: 18.8214133 },
    { lat: 49.1535102, lng: 18.8213648 },
    { lat: 49.153841, lng: 18.8205687 },
    { lat: 49.1543387, lng: 18.8200065 },
    { lat: 49.1547648, lng: 18.8195695 },
    { lat: 49.1548794, lng: 18.8196065 },
    { lat: 49.1552851, lng: 18.8194685 },
    { lat: 49.1562144, lng: 18.819255 },
    { lat: 49.1565785, lng: 18.8192088 },
    { lat: 49.1569091, lng: 18.8191695 },
    { lat: 49.1570931, lng: 18.8190444 },
    { lat: 49.1571624, lng: 18.819031 },
    { lat: 49.1572425, lng: 18.8190308 },
    { lat: 49.1575703, lng: 18.8189147 },
    { lat: 49.1575886, lng: 18.8188793 },
    { lat: 49.1577542, lng: 18.8185046 },
    { lat: 49.1579937, lng: 18.8182046 },
    { lat: 49.1582949, lng: 18.8178838 },
    { lat: 49.1583952, lng: 18.8177936 },
    { lat: 49.1584829, lng: 18.8177899 },
    { lat: 49.1585668, lng: 18.8178195 },
    { lat: 49.1586185, lng: 18.8178586 },
    { lat: 49.1588887, lng: 18.8180903 },
    { lat: 49.1589274, lng: 18.8180993 },
    { lat: 49.159262, lng: 18.8181348 },
    { lat: 49.1594786, lng: 18.8180288 },
    { lat: 49.1595632, lng: 18.8180291 },
    { lat: 49.1595799, lng: 18.8180271 },
    { lat: 49.1596765, lng: 18.8178671 },
    { lat: 49.1597677, lng: 18.8175373 },
    { lat: 49.1597769, lng: 18.8175192 },
    { lat: 49.1597872, lng: 18.8175213 },
    { lat: 49.1599336, lng: 18.8175202 },
    { lat: 49.1599456, lng: 18.8175094 },
    { lat: 49.1600095, lng: 18.8173696 },
    { lat: 49.1600776, lng: 18.8171196 },
    { lat: 49.1600876, lng: 18.8171173 },
    { lat: 49.1603225, lng: 18.8170347 },
    { lat: 49.1609393, lng: 18.816707 },
    { lat: 49.1609706, lng: 18.816682 },
    { lat: 49.1610127, lng: 18.8166881 },
    { lat: 49.1610266, lng: 18.8166742 },
    { lat: 49.1610516, lng: 18.8166132 },
    { lat: 49.1610879, lng: 18.8165743 },
    { lat: 49.1611001, lng: 18.8165688 },
    { lat: 49.1613557, lng: 18.8165623 },
    { lat: 49.1615071, lng: 18.8166122 },
    { lat: 49.1615434, lng: 18.8165963 },
    { lat: 49.1618828, lng: 18.8162003 },
    { lat: 49.1620359, lng: 18.8160684 },
    { lat: 49.1621696, lng: 18.8158852 },
    { lat: 49.1621851, lng: 18.815879 },
    { lat: 49.1622443, lng: 18.8159083 },
    { lat: 49.1622652, lng: 18.8159011 },
    { lat: 49.1622781, lng: 18.8158858 },
    { lat: 49.162393, lng: 18.815644 },
    { lat: 49.1624088, lng: 18.8156262 },
    { lat: 49.1625238, lng: 18.8155382 },
    { lat: 49.1625338, lng: 18.815488 },
    { lat: 49.1625531, lng: 18.8154504 },
    { lat: 49.1625731, lng: 18.8154444 },
    { lat: 49.1626917, lng: 18.8155119 },
    { lat: 49.1627189, lng: 18.8155126 },
    { lat: 49.1630267, lng: 18.8153848 },
    { lat: 49.1630326, lng: 18.8153665 },
    { lat: 49.163187, lng: 18.8142757 },
    { lat: 49.1634354, lng: 18.8134312 },
    { lat: 49.1634955, lng: 18.8133267 },
    { lat: 49.163681, lng: 18.8129661 },
    { lat: 49.1639454, lng: 18.8124548 },
    { lat: 49.1643346, lng: 18.8117825 },
    { lat: 49.1646902, lng: 18.8111791 },
    { lat: 49.1648634, lng: 18.8107147 },
    { lat: 49.1649553, lng: 18.8104623 },
    { lat: 49.1650438, lng: 18.8102538 },
    { lat: 49.1653547, lng: 18.8094396 },
    { lat: 49.1654804, lng: 18.8092981 },
    { lat: 49.1659578, lng: 18.8082177 },
    { lat: 49.1662309, lng: 18.8076552 },
    { lat: 49.1664534, lng: 18.8070966 },
    { lat: 49.1668596, lng: 18.8065134 },
    { lat: 49.1669636, lng: 18.8060847 },
    { lat: 49.1672145, lng: 18.8056131 },
    { lat: 49.1673999, lng: 18.8051245 },
    { lat: 49.1676086, lng: 18.804778 },
    { lat: 49.1678387, lng: 18.8044948 },
    { lat: 49.1679171, lng: 18.8044201 },
    { lat: 49.1685249, lng: 18.8040483 },
    { lat: 49.1685751, lng: 18.8040174 },
    { lat: 49.169088, lng: 18.8037524 },
    { lat: 49.1691647, lng: 18.8036228 },
    { lat: 49.1692783, lng: 18.8033111 },
    { lat: 49.1693145, lng: 18.8032707 },
    { lat: 49.1693463, lng: 18.8031873 },
    { lat: 49.169339, lng: 18.803137 },
    { lat: 49.1693424, lng: 18.8030256 },
    { lat: 49.169344, lng: 18.8029274 },
    { lat: 49.1693508, lng: 18.8027086 },
    { lat: 49.1694475, lng: 18.8019193 },
    { lat: 49.1694465, lng: 18.8017908 },
    { lat: 49.1695562, lng: 18.8016453 },
    { lat: 49.1696171, lng: 18.8016448 },
    { lat: 49.1696869, lng: 18.8017323 },
    { lat: 49.169846, lng: 18.8017531 },
    { lat: 49.1698952, lng: 18.8017029 },
    { lat: 49.1699872, lng: 18.8016741 },
    { lat: 49.1701028, lng: 18.8016602 },
    { lat: 49.1701686, lng: 18.8015168 },
    { lat: 49.1702157, lng: 18.8013367 },
    { lat: 49.170261, lng: 18.8013208 },
    { lat: 49.1703418, lng: 18.8013583 },
    { lat: 49.1703534, lng: 18.8012758 },
    { lat: 49.1703333, lng: 18.8012797 },
    { lat: 49.1703344, lng: 18.8010601 },
    { lat: 49.17033, lng: 18.8008246 },
    { lat: 49.1703084, lng: 18.8005573 },
    { lat: 49.1702778, lng: 18.8003808 },
    { lat: 49.1702195, lng: 18.8002588 },
    { lat: 49.1701457, lng: 18.8001398 },
    { lat: 49.1700808, lng: 18.8000182 },
    { lat: 49.1700707, lng: 18.7999726 },
    { lat: 49.1700923, lng: 18.7998468 },
    { lat: 49.1701875, lng: 18.799747 },
    { lat: 49.1702105, lng: 18.799625 },
    { lat: 49.1701817, lng: 18.799201 },
    { lat: 49.1702998, lng: 18.7990236 },
    { lat: 49.170413, lng: 18.7988993 },
    { lat: 49.1705746, lng: 18.7988863 },
    { lat: 49.170682, lng: 18.7987599 },
    { lat: 49.1707778, lng: 18.7985844 },
    { lat: 49.1709754, lng: 18.7985912 },
    { lat: 49.1710888, lng: 18.7984723 },
    { lat: 49.1711927, lng: 18.7981924 },
    { lat: 49.1712293, lng: 18.798201 },
    { lat: 49.1712568, lng: 18.7981883 },
    { lat: 49.1712869, lng: 18.7982038 },
    { lat: 49.1713552, lng: 18.7981505 },
    { lat: 49.1713675, lng: 18.7982362 },
    { lat: 49.1714656, lng: 18.7981289 },
    { lat: 49.1714854, lng: 18.7980962 },
    { lat: 49.1715089, lng: 18.7980256 },
    { lat: 49.1715223, lng: 18.7979603 },
    { lat: 49.1716201, lng: 18.7978868 },
    { lat: 49.1716513, lng: 18.7978824 },
    { lat: 49.1716827, lng: 18.7978534 },
    { lat: 49.171711, lng: 18.7978164 },
    { lat: 49.1717617, lng: 18.7977923 },
    { lat: 49.1718028, lng: 18.7977773 },
    { lat: 49.1718466, lng: 18.7977111 },
    { lat: 49.1718644, lng: 18.7976628 },
    { lat: 49.1719042, lng: 18.7976472 },
    { lat: 49.1719335, lng: 18.7976048 },
    { lat: 49.1719284, lng: 18.7975066 },
    { lat: 49.1719492, lng: 18.7974468 },
    { lat: 49.1720102, lng: 18.7974078 },
    { lat: 49.1723271, lng: 18.7972575 },
    { lat: 49.172414, lng: 18.797216 },
    { lat: 49.1724533, lng: 18.7970801 },
    { lat: 49.1725036, lng: 18.7970715 },
    { lat: 49.1725379, lng: 18.7970811 },
    { lat: 49.1725723, lng: 18.7969836 },
    { lat: 49.1726083, lng: 18.796962 },
    { lat: 49.1726201, lng: 18.7969303 },
    { lat: 49.1726256, lng: 18.7969037 },
    { lat: 49.1726243, lng: 18.7967921 },
    { lat: 49.1726857, lng: 18.7968456 },
    { lat: 49.1727608, lng: 18.7969234 },
    { lat: 49.1727886, lng: 18.7968938 },
    { lat: 49.1727988, lng: 18.7968298 },
    { lat: 49.1727751, lng: 18.7967657 },
    { lat: 49.1727708, lng: 18.7966606 },
    { lat: 49.1728073, lng: 18.7966256 },
    { lat: 49.1728047, lng: 18.7965761 },
    { lat: 49.1727933, lng: 18.7965519 },
    { lat: 49.1728231, lng: 18.796539 },
    { lat: 49.1728533, lng: 18.7964881 },
    { lat: 49.1728888, lng: 18.7964799 },
    { lat: 49.1728914, lng: 18.7964388 },
    { lat: 49.1729201, lng: 18.7964271 },
    { lat: 49.172902, lng: 18.7963835 },
    { lat: 49.172894, lng: 18.7963149 },
    { lat: 49.1729079, lng: 18.7962365 },
    { lat: 49.1728784, lng: 18.7961471 },
    { lat: 49.1729356, lng: 18.7960962 },
    { lat: 49.1729469, lng: 18.7960541 },
    { lat: 49.1730003, lng: 18.7960419 },
    { lat: 49.17304, lng: 18.7960007 },
    { lat: 49.1730998, lng: 18.7959135 },
    { lat: 49.1731413, lng: 18.7959121 },
    { lat: 49.1731736, lng: 18.7958783 },
    { lat: 49.1732757, lng: 18.7958069 },
    { lat: 49.1732859, lng: 18.7956986 },
    { lat: 49.173288, lng: 18.7955658 },
    { lat: 49.173314, lng: 18.7954563 },
    { lat: 49.173355, lng: 18.7953984 },
    { lat: 49.1734429, lng: 18.7953529 },
    { lat: 49.1734483, lng: 18.7953702 },
    { lat: 49.173467, lng: 18.7953853 },
    { lat: 49.1735158, lng: 18.795347 },
    { lat: 49.1735246, lng: 18.7952799 },
    { lat: 49.1736108, lng: 18.7952422 },
    { lat: 49.1736376, lng: 18.7952173 },
    { lat: 49.173676, lng: 18.7951514 },
    { lat: 49.1736692, lng: 18.7950193 },
    { lat: 49.1736965, lng: 18.794963 },
    { lat: 49.1737178, lng: 18.7949136 },
    { lat: 49.173743, lng: 18.7948561 },
    { lat: 49.1737866, lng: 18.7948282 },
    { lat: 49.1738215, lng: 18.7947854 },
    { lat: 49.1738542, lng: 18.7947184 },
    { lat: 49.1738774, lng: 18.7946464 },
    { lat: 49.1738949, lng: 18.7945683 },
    { lat: 49.1739294, lng: 18.7945144 },
    { lat: 49.1739843, lng: 18.7944652 },
    { lat: 49.1740056, lng: 18.7943751 },
    { lat: 49.1740167, lng: 18.7943755 },
    { lat: 49.1740469, lng: 18.7944346 },
    { lat: 49.1740923, lng: 18.7944608 },
    { lat: 49.1741177, lng: 18.7944495 },
    { lat: 49.1741749, lng: 18.7944466 },
    { lat: 49.1742196, lng: 18.7944145 },
    { lat: 49.1742643, lng: 18.7944516 },
    { lat: 49.1743042, lng: 18.7944385 },
    { lat: 49.1743526, lng: 18.7943909 },
    { lat: 49.174372, lng: 18.7944606 },
    { lat: 49.1744026, lng: 18.7944685 },
    { lat: 49.1744426, lng: 18.7945415 },
    { lat: 49.1744765, lng: 18.7946073 },
    { lat: 49.1745222, lng: 18.7946418 },
    { lat: 49.1745775, lng: 18.794642 },
    { lat: 49.1746288, lng: 18.7946279 },
    { lat: 49.1746648, lng: 18.7945843 },
    { lat: 49.1746853, lng: 18.7945706 },
    { lat: 49.174715, lng: 18.7945314 },
    { lat: 49.1747504, lng: 18.7945869 },
    { lat: 49.1747963, lng: 18.7946001 },
    { lat: 49.1748397, lng: 18.7945675 },
    { lat: 49.1748668, lng: 18.7946125 },
    { lat: 49.1749037, lng: 18.7947133 },
    { lat: 49.174924, lng: 18.7948208 },
    { lat: 49.1749595, lng: 18.794854 },
    { lat: 49.1749871, lng: 18.7947764 },
    { lat: 49.1750683, lng: 18.7947115 },
    { lat: 49.1751112, lng: 18.7946912 },
    { lat: 49.1751494, lng: 18.7947129 },
    { lat: 49.1751825, lng: 18.7946764 },
    { lat: 49.1752127, lng: 18.7946292 },
    { lat: 49.1752536, lng: 18.7946581 },
    { lat: 49.1752965, lng: 18.7946324 },
    { lat: 49.1753212, lng: 18.7945898 },
    { lat: 49.1753615, lng: 18.7946066 },
    { lat: 49.1753715, lng: 18.794539 },
    { lat: 49.1754065, lng: 18.7945392 },
    { lat: 49.1754403, lng: 18.7946028 },
    { lat: 49.1754787, lng: 18.7946245 },
    { lat: 49.1755162, lng: 18.7945854 },
    { lat: 49.1755512, lng: 18.7946327 },
    { lat: 49.1755805, lng: 18.7946097 },
    { lat: 49.1756186, lng: 18.7945828 },
    { lat: 49.1756314, lng: 18.7945257 },
    { lat: 49.1756233, lng: 18.7944553 },
    { lat: 49.1756515, lng: 18.7943932 },
    { lat: 49.1757333, lng: 18.7944039 },
    { lat: 49.1757552, lng: 18.7945198 },
    { lat: 49.1758006, lng: 18.7945259 },
    { lat: 49.1758422, lng: 18.7945475 },
    { lat: 49.175881, lng: 18.794536 },
    { lat: 49.1758819, lng: 18.7944627 },
    { lat: 49.1759348, lng: 18.7944394 },
    { lat: 49.1759713, lng: 18.7944674 },
    { lat: 49.1760025, lng: 18.7945282 },
    { lat: 49.1760363, lng: 18.7945504 },
    { lat: 49.1760309, lng: 18.7944686 },
    { lat: 49.17605, lng: 18.7944234 },
    { lat: 49.1760786, lng: 18.7944325 },
    { lat: 49.1761501, lng: 18.7945252 },
    { lat: 49.1762068, lng: 18.7945526 },
    { lat: 49.1763031, lng: 18.7944732 },
    { lat: 49.1763658, lng: 18.794466 },
    { lat: 49.1764566, lng: 18.7945217 },
    { lat: 49.1764882, lng: 18.7944801 },
    { lat: 49.1764309, lng: 18.7943554 },
    { lat: 49.1764477, lng: 18.7942633 },
    { lat: 49.1765421, lng: 18.7943446 },
    { lat: 49.1766056, lng: 18.7943063 },
    { lat: 49.1766559, lng: 18.7942779 },
    { lat: 49.1766419, lng: 18.7941368 },
    { lat: 49.1767057, lng: 18.7940401 },
    { lat: 49.1767538, lng: 18.7940116 },
    { lat: 49.1767803, lng: 18.7940437 },
    { lat: 49.1768334, lng: 18.794006 },
    { lat: 49.176958, lng: 18.7938616 },
    { lat: 49.1771326, lng: 18.793816 },
    { lat: 49.1773613, lng: 18.7937473 },
    { lat: 49.1773859, lng: 18.7936791 },
    { lat: 49.1774085, lng: 18.7936364 },
    { lat: 49.1774279, lng: 18.7935512 },
    { lat: 49.177437, lng: 18.7935292 },
    { lat: 49.1774794, lng: 18.7934361 },
    { lat: 49.1775148, lng: 18.7933817 },
    { lat: 49.1775688, lng: 18.79327 },
    { lat: 49.1776079, lng: 18.7931986 },
    { lat: 49.177627, lng: 18.7931303 },
    { lat: 49.1776363, lng: 18.7930726 },
    { lat: 49.177618, lng: 18.7930204 },
    { lat: 49.1776201, lng: 18.792956 },
    { lat: 49.1776338, lng: 18.7928693 },
    { lat: 49.177699, lng: 18.7928236 },
    { lat: 49.1776954, lng: 18.7927303 },
    { lat: 49.1776883, lng: 18.7926584 },
    { lat: 49.1776792, lng: 18.792591 },
    { lat: 49.1777021, lng: 18.7925778 },
    { lat: 49.1777091, lng: 18.7925384 },
    { lat: 49.1777343, lng: 18.7925011 },
    { lat: 49.1777346, lng: 18.7924438 },
    { lat: 49.1778404, lng: 18.7923517 },
    { lat: 49.1778206, lng: 18.7923195 },
    { lat: 49.1777998, lng: 18.7922881 },
    { lat: 49.1775392, lng: 18.792023 },
    { lat: 49.1774292, lng: 18.7919025 },
    { lat: 49.1771267, lng: 18.791615 },
    { lat: 49.1770052, lng: 18.7914041 },
    { lat: 49.1769225, lng: 18.791263 },
    { lat: 49.1767978, lng: 18.7910777 },
    { lat: 49.1766532, lng: 18.7908306 },
    { lat: 49.1765291, lng: 18.7905255 },
    { lat: 49.1763395, lng: 18.7900442 },
    { lat: 49.1763394, lng: 18.7900428 },
    { lat: 49.1763369, lng: 18.7900171 },
    { lat: 49.1762872, lng: 18.7891452 },
    { lat: 49.1762735, lng: 18.7889685 },
    { lat: 49.1761286, lng: 18.788024 },
    { lat: 49.1756976, lng: 18.7867127 },
    { lat: 49.1754092, lng: 18.7861781 },
    { lat: 49.17515, lng: 18.7856987 },
    { lat: 49.1751079, lng: 18.7855622 },
    { lat: 49.1751013, lng: 18.7855442 },
    { lat: 49.1749927, lng: 18.7851915 },
    { lat: 49.1748873, lng: 18.7845718 },
    { lat: 49.1748135, lng: 18.7841291 },
    { lat: 49.1745291, lng: 18.7829742 },
    { lat: 49.1743448, lng: 18.7822004 },
    { lat: 49.174187, lng: 18.7814647 },
    { lat: 49.1741736, lng: 18.7814022 },
    { lat: 49.1741547, lng: 18.7813443 },
    { lat: 49.1739701, lng: 18.7806102 },
    { lat: 49.1739406, lng: 18.7800887 },
    { lat: 49.1739253, lng: 18.7798166 },
    { lat: 49.1738586, lng: 18.7786199 },
    { lat: 49.1738432, lng: 18.7783234 },
    { lat: 49.1737779, lng: 18.7778671 },
    { lat: 49.1737336, lng: 18.7777543 },
    { lat: 49.1733798, lng: 18.7768492 },
    { lat: 49.1733637, lng: 18.7767989 },
    { lat: 49.1733046, lng: 18.7766162 },
    { lat: 49.1732992, lng: 18.7765984 },
    { lat: 49.1732414, lng: 18.7763323 },
    { lat: 49.1731134, lng: 18.7759517 },
    { lat: 49.1730564, lng: 18.7757456 },
    { lat: 49.1730051, lng: 18.7755831 },
    { lat: 49.1729544, lng: 18.7754116 },
    { lat: 49.1729193, lng: 18.7753002 },
    { lat: 49.1728968, lng: 18.7752359 },
    { lat: 49.1728745, lng: 18.7751528 },
    { lat: 49.1728662, lng: 18.7751015 },
    { lat: 49.1728412, lng: 18.7750332 },
    { lat: 49.1728219, lng: 18.7749213 },
    { lat: 49.1728057, lng: 18.7748926 },
    { lat: 49.1727558, lng: 18.7746922 },
    { lat: 49.1727299, lng: 18.7745843 },
    { lat: 49.1726904, lng: 18.7744341 },
    { lat: 49.1726798, lng: 18.7743788 },
    { lat: 49.1726374, lng: 18.7741731 },
    { lat: 49.1726192, lng: 18.7740813 },
    { lat: 49.172591, lng: 18.7739524 },
    { lat: 49.1725585, lng: 18.7738691 },
    { lat: 49.1724917, lng: 18.773648 },
    { lat: 49.1724491, lng: 18.7735018 },
    { lat: 49.1724115, lng: 18.773279 },
    { lat: 49.1723291, lng: 18.7730565 },
    { lat: 49.172051, lng: 18.7725027 },
    { lat: 49.1720375, lng: 18.7724795 },
    { lat: 49.1718916, lng: 18.7721916 },
    { lat: 49.1715724, lng: 18.7719733 },
    { lat: 49.1713703, lng: 18.7718139 },
    { lat: 49.1713315, lng: 18.7716759 },
    { lat: 49.1712593, lng: 18.7714151 },
    { lat: 49.1712484, lng: 18.7713782 },
    { lat: 49.1712429, lng: 18.7713821 },
    { lat: 49.1712125, lng: 18.7712957 },
    { lat: 49.1712179, lng: 18.7712922 },
    { lat: 49.1712028, lng: 18.7712601 },
    { lat: 49.1711951, lng: 18.7712438 },
    { lat: 49.1711611, lng: 18.7711755 },
    { lat: 49.1711234, lng: 18.7711 },
    { lat: 49.1710163, lng: 18.7708867 },
    { lat: 49.17087, lng: 18.7705855 },
    { lat: 49.1708474, lng: 18.7705413 },
    { lat: 49.1707825, lng: 18.7703805 },
    { lat: 49.1706647, lng: 18.7700863 },
    { lat: 49.1706155, lng: 18.7699644 },
    { lat: 49.1705871, lng: 18.7698943 },
    { lat: 49.1705364, lng: 18.7697703 },
    { lat: 49.1704861, lng: 18.7696474 },
    { lat: 49.1703722, lng: 18.7693657 },
    { lat: 49.170366, lng: 18.7693517 },
    { lat: 49.1702464, lng: 18.7690281 },
    { lat: 49.170236, lng: 18.7690013 },
    { lat: 49.1701359, lng: 18.7688977 },
    { lat: 49.170006, lng: 18.7687639 },
    { lat: 49.1698595, lng: 18.7686134 },
    { lat: 49.1697416, lng: 18.7684907 },
    { lat: 49.1696892, lng: 18.7684369 },
    { lat: 49.1696272, lng: 18.7683727 },
    { lat: 49.1696153, lng: 18.7683608 },
    { lat: 49.1695426, lng: 18.7682853 },
    { lat: 49.1695354, lng: 18.7682786 },
    { lat: 49.1694662, lng: 18.7682066 },
    { lat: 49.16939, lng: 18.7681272 },
    { lat: 49.1693482, lng: 18.7680836 },
    { lat: 49.1693285, lng: 18.7680496 },
    { lat: 49.1692568, lng: 18.76793 },
    { lat: 49.1691913, lng: 18.767819 },
    { lat: 49.1691319, lng: 18.7677184 },
    { lat: 49.1691281, lng: 18.7677138 },
    { lat: 49.1690775, lng: 18.7676266 },
    { lat: 49.1690193, lng: 18.7675303 },
    { lat: 49.1690157, lng: 18.7675238 },
    { lat: 49.1689651, lng: 18.7674374 },
    { lat: 49.1689055, lng: 18.7673372 },
    { lat: 49.1688462, lng: 18.7672363 },
    { lat: 49.1687858, lng: 18.7671348 },
    { lat: 49.1687263, lng: 18.7670357 },
    { lat: 49.1686682, lng: 18.7669364 },
    { lat: 49.1686262, lng: 18.7668651 },
    { lat: 49.1685822, lng: 18.7668639 },
    { lat: 49.1684445, lng: 18.7668608 },
    { lat: 49.1682931, lng: 18.7668571 },
    { lat: 49.1681961, lng: 18.7668548 },
    { lat: 49.1681547, lng: 18.7670346 },
    { lat: 49.1681474, lng: 18.7670672 },
    { lat: 49.1681364, lng: 18.7671143 },
    { lat: 49.1681019, lng: 18.7671455 },
    { lat: 49.1680813, lng: 18.7671635 },
    { lat: 49.1680582, lng: 18.7671732 },
    { lat: 49.1680471, lng: 18.7671774 },
    { lat: 49.1679798, lng: 18.7672061 },
    { lat: 49.1679698, lng: 18.7672102 },
    { lat: 49.1679444, lng: 18.7672204 },
    { lat: 49.1678789, lng: 18.7672161 },
    { lat: 49.1677872, lng: 18.7672096 },
    { lat: 49.1677668, lng: 18.7672081 },
    { lat: 49.1676873, lng: 18.7672202 },
    { lat: 49.1676034, lng: 18.7672333 },
    { lat: 49.1675664, lng: 18.7672349 },
    { lat: 49.1674414, lng: 18.7672406 },
    { lat: 49.1673976, lng: 18.7672427 },
    { lat: 49.1673016, lng: 18.7672194 },
    { lat: 49.167281, lng: 18.7672139 },
    { lat: 49.167199, lng: 18.7671943 },
    { lat: 49.1671686, lng: 18.7671746 },
    { lat: 49.167074, lng: 18.7671143 },
    { lat: 49.1670284, lng: 18.7671047 },
    { lat: 49.1669156, lng: 18.7670806 },
    { lat: 49.1668553, lng: 18.7670709 },
    { lat: 49.1668068, lng: 18.7670692 },
    { lat: 49.1667696, lng: 18.7670709 },
    { lat: 49.1666342, lng: 18.7670678 },
    { lat: 49.1664707, lng: 18.7670483 },
    { lat: 49.1663636, lng: 18.7670033 },
    { lat: 49.1663098, lng: 18.7669903 },
    { lat: 49.1661151, lng: 18.7669321 },
    { lat: 49.1659553, lng: 18.7668708 },
    { lat: 49.1658394, lng: 18.7668091 },
    { lat: 49.165761, lng: 18.7668024 },
    { lat: 49.1654026, lng: 18.7668033 },
    { lat: 49.1652107, lng: 18.76682 },
    { lat: 49.1651044, lng: 18.7668387 },
    { lat: 49.1649341, lng: 18.7668586 },
    { lat: 49.1647984, lng: 18.7669161 },
    { lat: 49.1646137, lng: 18.7670087 },
    { lat: 49.164653, lng: 18.7671135 },
    { lat: 49.1643321, lng: 18.7672731 },
    { lat: 49.1641689, lng: 18.7673642 },
    { lat: 49.1640763, lng: 18.767428 },
    { lat: 49.1639721, lng: 18.7675051 },
    { lat: 49.1639133, lng: 18.7675252 },
    { lat: 49.1639004, lng: 18.7675354 },
    { lat: 49.1638873, lng: 18.7675428 },
    { lat: 49.1638522, lng: 18.7675629 },
    { lat: 49.1638199, lng: 18.7675913 },
    { lat: 49.1638071, lng: 18.7676037 },
    { lat: 49.1638913, lng: 18.7679228 },
    { lat: 49.163948, lng: 18.7681912 },
    { lat: 49.1640355, lng: 18.7684649 },
    { lat: 49.1640714, lng: 18.7685906 },
    { lat: 49.1640983, lng: 18.7686731 },
    { lat: 49.1639842, lng: 18.7686678 },
    { lat: 49.1637926, lng: 18.7686053 },
    { lat: 49.1636533, lng: 18.768549 },
    { lat: 49.1634809, lng: 18.7684647 },
    { lat: 49.1633465, lng: 18.7683963 },
    { lat: 49.1631876, lng: 18.7683121 },
    { lat: 49.162982, lng: 18.7681983 },
    { lat: 49.1628129, lng: 18.7681092 },
    { lat: 49.1625576, lng: 18.7679484 },
    { lat: 49.1623988, lng: 18.7678254 },
    { lat: 49.1623085, lng: 18.7677577 },
    { lat: 49.1622532, lng: 18.7677125 },
    { lat: 49.1621394, lng: 18.7676272 },
    { lat: 49.1620447, lng: 18.7675645 },
    { lat: 49.1619524, lng: 18.7675029 },
    { lat: 49.1618219, lng: 18.76742 },
    { lat: 49.1617524, lng: 18.767388 },
    { lat: 49.1616775, lng: 18.7673971 },
    { lat: 49.1615293, lng: 18.7674774 },
    { lat: 49.161366, lng: 18.7676092 },
    { lat: 49.1612822, lng: 18.7676687 },
    { lat: 49.161198, lng: 18.7677178 },
    { lat: 49.1610952, lng: 18.7677814 },
    { lat: 49.1610076, lng: 18.7678277 },
    { lat: 49.160873, lng: 18.767907 },
    { lat: 49.1607652, lng: 18.7679821 },
    { lat: 49.1606799, lng: 18.7680547 },
    { lat: 49.1605984, lng: 18.768114 },
    { lat: 49.1605166, lng: 18.7681682 },
    { lat: 49.1603889, lng: 18.7682701 },
    { lat: 49.1602808, lng: 18.7683596 },
    { lat: 49.1601659, lng: 18.7684488 },
    { lat: 49.1599413, lng: 18.768636 },
    { lat: 49.1599204, lng: 18.7685852 },
    { lat: 49.1599002, lng: 18.7685422 },
    { lat: 49.1598141, lng: 18.7683628 },
    { lat: 49.1597041, lng: 18.7680709 },
    { lat: 49.1594999, lng: 18.7675026 },
    { lat: 49.1594068, lng: 18.7672527 },
    { lat: 49.1592999, lng: 18.7669749 },
    { lat: 49.1592258, lng: 18.7667443 },
    { lat: 49.1590763, lng: 18.7663837 },
    { lat: 49.1589759, lng: 18.766148 },
    { lat: 49.1588984, lng: 18.7659344 },
    { lat: 49.1588568, lng: 18.7658075 },
    { lat: 49.1587638, lng: 18.7654953 },
    { lat: 49.1587596, lng: 18.7654785 },
  ],
  Belá: [
    { lat: 49.1868938, lng: 18.9886943 },
    { lat: 49.185477, lng: 18.9898892 },
    { lat: 49.1842163, lng: 18.9906493 },
    { lat: 49.1831856, lng: 18.9912286 },
    { lat: 49.1821749, lng: 18.9935834 },
    { lat: 49.182521, lng: 18.994306 },
    { lat: 49.182765, lng: 18.9951 },
    { lat: 49.183103, lng: 18.996091 },
    { lat: 49.183792, lng: 18.997637 },
    { lat: 49.184052, lng: 18.997912 },
    { lat: 49.184618, lng: 18.998285 },
    { lat: 49.185238, lng: 18.998241 },
    { lat: 49.18524, lng: 18.998241 },
    { lat: 49.185723, lng: 18.998433 },
    { lat: 49.186039, lng: 18.99896 },
    { lat: 49.186173, lng: 18.999184 },
    { lat: 49.186342, lng: 18.999716 },
    { lat: 49.187543, lng: 19.001682 },
    { lat: 49.189328, lng: 19.002795 },
    { lat: 49.190818, lng: 19.00365 },
    { lat: 49.191236, lng: 19.0039 },
    { lat: 49.191277, lng: 19.003925 },
    { lat: 49.191293, lng: 19.003934 },
    { lat: 49.191474, lng: 19.004043 },
    { lat: 49.191684, lng: 19.004073 },
    { lat: 49.191708, lng: 19.00406 },
    { lat: 49.191804, lng: 19.004067 },
    { lat: 49.191845, lng: 19.00407 },
    { lat: 49.192473, lng: 19.004494 },
    { lat: 49.192896, lng: 19.00591 },
    { lat: 49.193332, lng: 19.007366 },
    { lat: 49.193564, lng: 19.007768 },
    { lat: 49.19377, lng: 19.008123 },
    { lat: 49.1947028, lng: 19.0076809 },
    { lat: 49.1950973, lng: 19.0074771 },
    { lat: 49.1956211, lng: 19.0073985 },
    { lat: 49.1962954, lng: 19.0073154 },
    { lat: 49.1973528, lng: 19.007156 },
    { lat: 49.1976136, lng: 19.0071663 },
    { lat: 49.1980011, lng: 19.0071999 },
    { lat: 49.1982609, lng: 19.0072796 },
    { lat: 49.1985026, lng: 19.007445 },
    { lat: 49.1989871, lng: 19.0078721 },
    { lat: 49.1993722, lng: 19.0080006 },
    { lat: 49.2001013, lng: 19.008423 },
    { lat: 49.2002753, lng: 19.0084489 },
    { lat: 49.2006705, lng: 19.0085998 },
    { lat: 49.2007903, lng: 19.0086717 },
    { lat: 49.2010885, lng: 19.008797 },
    { lat: 49.2011529, lng: 19.0087329 },
    { lat: 49.2011948, lng: 19.008736 },
    { lat: 49.2012775, lng: 19.0087931 },
    { lat: 49.2013842, lng: 19.0088715 },
    { lat: 49.2015659, lng: 19.0089657 },
    { lat: 49.2016217, lng: 19.0089582 },
    { lat: 49.2017335, lng: 19.0090497 },
    { lat: 49.2018957, lng: 19.0090957 },
    { lat: 49.2020836, lng: 19.0091543 },
    { lat: 49.2021035, lng: 19.0091916 },
    { lat: 49.2021567, lng: 19.0092168 },
    { lat: 49.2023795, lng: 19.0093686 },
    { lat: 49.2026425, lng: 19.009418 },
    { lat: 49.2027918, lng: 19.0094032 },
    { lat: 49.2029008, lng: 19.009402 },
    { lat: 49.2032267, lng: 19.0093339 },
    { lat: 49.2033035, lng: 19.0093152 },
    { lat: 49.2034924, lng: 19.0092799 },
    { lat: 49.2035941, lng: 19.009263 },
    { lat: 49.2038728, lng: 19.0094274 },
    { lat: 49.2040611, lng: 19.0094719 },
    { lat: 49.2042225, lng: 19.0093875 },
    { lat: 49.2043543, lng: 19.0093404 },
    { lat: 49.2044708, lng: 19.0092793 },
    { lat: 49.2045875, lng: 19.0092224 },
    { lat: 49.2046516, lng: 19.0092424 },
    { lat: 49.2047345, lng: 19.0091859 },
    { lat: 49.2048649, lng: 19.0091332 },
    { lat: 49.2049061, lng: 19.0091032 },
    { lat: 49.205004, lng: 19.0090774 },
    { lat: 49.2050856, lng: 19.0090405 },
    { lat: 49.205158, lng: 19.0089773 },
    { lat: 49.2052953, lng: 19.008794 },
    { lat: 49.2053858, lng: 19.0087745 },
    { lat: 49.2055901, lng: 19.0086707 },
    { lat: 49.2057121, lng: 19.0086067 },
    { lat: 49.2057672, lng: 19.0086018 },
    { lat: 49.2058576, lng: 19.0085815 },
    { lat: 49.2061583, lng: 19.0084449 },
    { lat: 49.2065032, lng: 19.008147 },
    { lat: 49.2067537, lng: 19.0080161 },
    { lat: 49.2072242, lng: 19.0077307 },
    { lat: 49.2074986, lng: 19.0075596 },
    { lat: 49.207907, lng: 19.007119 },
    { lat: 49.2082364, lng: 19.0068936 },
    { lat: 49.2082778, lng: 19.0068657 },
    { lat: 49.2085573, lng: 19.0065703 },
    { lat: 49.2088858, lng: 19.0058817 },
    { lat: 49.2090362, lng: 19.0055981 },
    { lat: 49.2090823, lng: 19.0054835 },
    { lat: 49.2093853, lng: 19.0053404 },
    { lat: 49.2096252, lng: 19.0053384 },
    { lat: 49.2097785, lng: 19.0054706 },
    { lat: 49.209951, lng: 19.005586 },
    { lat: 49.2101854, lng: 19.0058083 },
    { lat: 49.2104358, lng: 19.0057427 },
    { lat: 49.2105931, lng: 19.0056234 },
    { lat: 49.2109768, lng: 19.0050682 },
    { lat: 49.211437, lng: 19.0046908 },
    { lat: 49.2121559, lng: 19.0048855 },
    { lat: 49.2138824, lng: 19.0027756 },
    { lat: 49.2149466, lng: 19.0015604 },
    { lat: 49.2155357, lng: 19.0012098 },
    { lat: 49.216162, lng: 19.0007485 },
    { lat: 49.2176751, lng: 19.0000309 },
    { lat: 49.21876, lng: 18.9992315 },
    { lat: 49.2196962, lng: 18.9982017 },
    { lat: 49.2221332, lng: 18.996061 },
    { lat: 49.2228653, lng: 18.9957964 },
    { lat: 49.2236978, lng: 18.9955853 },
    { lat: 49.2238356, lng: 18.9956104 },
    { lat: 49.2252451, lng: 18.9949187 },
    { lat: 49.2259978, lng: 18.9946884 },
    { lat: 49.2268925, lng: 18.9939842 },
    { lat: 49.2269071, lng: 18.9940485 },
    { lat: 49.2269177, lng: 18.9940094 },
    { lat: 49.2269516, lng: 18.9940592 },
    { lat: 49.22699, lng: 18.9941517 },
    { lat: 49.2270909, lng: 18.9941612 },
    { lat: 49.2272058, lng: 18.9943628 },
    { lat: 49.2272281, lng: 18.9944734 },
    { lat: 49.2272378, lng: 18.9945524 },
    { lat: 49.2272483, lng: 18.9947401 },
    { lat: 49.2272304, lng: 18.9948764 },
    { lat: 49.2274539, lng: 18.9952651 },
    { lat: 49.2275253, lng: 18.995365 },
    { lat: 49.2275107, lng: 18.9958154 },
    { lat: 49.2275991, lng: 18.9960709 },
    { lat: 49.2276946, lng: 18.9968984 },
    { lat: 49.2278839, lng: 18.9971447 },
    { lat: 49.2279289, lng: 18.9973926 },
    { lat: 49.228209, lng: 18.9979439 },
    { lat: 49.2283656, lng: 18.9981904 },
    { lat: 49.2284455, lng: 18.9982994 },
    { lat: 49.2285306, lng: 18.9986924 },
    { lat: 49.2285475, lng: 18.9992329 },
    { lat: 49.2285753, lng: 18.9993606 },
    { lat: 49.2285982, lng: 18.9995495 },
    { lat: 49.2285908, lng: 18.9997826 },
    { lat: 49.2286017, lng: 19.0000919 },
    { lat: 49.2286701, lng: 19.0003353 },
    { lat: 49.2287596, lng: 19.0005226 },
    { lat: 49.2287955, lng: 19.0005663 },
    { lat: 49.2289506, lng: 19.0006673 },
    { lat: 49.2292345, lng: 19.0007753 },
    { lat: 49.2294676, lng: 19.0009302 },
    { lat: 49.2295411, lng: 19.0009354 },
    { lat: 49.2297003, lng: 19.0009825 },
    { lat: 49.2298251, lng: 19.0012013 },
    { lat: 49.2299416, lng: 19.001547 },
    { lat: 49.2300178, lng: 19.0020121 },
    { lat: 49.2300993, lng: 19.002562 },
    { lat: 49.2301527, lng: 19.0027975 },
    { lat: 49.2303398, lng: 19.0034924 },
    { lat: 49.2303012, lng: 19.0038739 },
    { lat: 49.2301498, lng: 19.0041815 },
    { lat: 49.2300811, lng: 19.004679 },
    { lat: 49.2302991, lng: 19.0056336 },
    { lat: 49.2304801, lng: 19.0066647 },
    { lat: 49.2306681, lng: 19.007493 },
    { lat: 49.2309324, lng: 19.008249 },
    { lat: 49.2309363, lng: 19.0088015 },
    { lat: 49.2307496, lng: 19.0097838 },
    { lat: 49.2306364, lng: 19.0103366 },
    { lat: 49.2315003, lng: 19.0105921 },
    { lat: 49.2329341, lng: 19.0111535 },
    { lat: 49.2339581, lng: 19.0115943 },
    { lat: 49.2340758, lng: 19.0116462 },
    { lat: 49.2344394, lng: 19.0118193 },
    { lat: 49.2347723, lng: 19.0130226 },
    { lat: 49.2349845, lng: 19.0134589 },
    { lat: 49.2353267, lng: 19.0144862 },
    { lat: 49.2359023, lng: 19.0148637 },
    { lat: 49.2359387, lng: 19.0148255 },
    { lat: 49.2370232, lng: 19.0145988 },
    { lat: 49.2381975, lng: 19.0136898 },
    { lat: 49.2387775, lng: 19.0134312 },
    { lat: 49.239873, lng: 19.0130162 },
    { lat: 49.2400667, lng: 19.0124716 },
    { lat: 49.241092, lng: 19.0122784 },
    { lat: 49.2417144, lng: 19.0121219 },
    { lat: 49.2420098, lng: 19.0120778 },
    { lat: 49.2423144, lng: 19.0119284 },
    { lat: 49.2427185, lng: 19.0116429 },
    { lat: 49.2429247, lng: 19.0115052 },
    { lat: 49.2432326, lng: 19.0115528 },
    { lat: 49.243583, lng: 19.011503 },
    { lat: 49.244362, lng: 19.011776 },
    { lat: 49.244639, lng: 19.0116319 },
    { lat: 49.2450265, lng: 19.011042 },
    { lat: 49.2455762, lng: 19.0109203 },
    { lat: 49.2461117, lng: 19.0111712 },
    { lat: 49.2470445, lng: 19.0124918 },
    { lat: 49.24737, lng: 19.0123057 },
    { lat: 49.2486081, lng: 19.011839 },
    { lat: 49.2490076, lng: 19.0119805 },
    { lat: 49.2492105, lng: 19.011914 },
    { lat: 49.2494041, lng: 19.0118175 },
    { lat: 49.2496178, lng: 19.0116689 },
    { lat: 49.2499647, lng: 19.0118464 },
    { lat: 49.2502415, lng: 19.0116085 },
    { lat: 49.2506426, lng: 19.0113342 },
    { lat: 49.2506767, lng: 19.0114731 },
    { lat: 49.2507675, lng: 19.0114412 },
    { lat: 49.2507859, lng: 19.0115416 },
    { lat: 49.2509965, lng: 19.011468 },
    { lat: 49.251009, lng: 19.0114907 },
    { lat: 49.2512073, lng: 19.0113976 },
    { lat: 49.2513318, lng: 19.0113647 },
    { lat: 49.251523, lng: 19.0113778 },
    { lat: 49.2523983, lng: 19.0111843 },
    { lat: 49.2525247, lng: 19.0110992 },
    { lat: 49.2527536, lng: 19.0105793 },
    { lat: 49.2527762, lng: 19.0106008 },
    { lat: 49.2528345, lng: 19.0108474 },
    { lat: 49.2529458, lng: 19.0110405 },
    { lat: 49.2540672, lng: 19.010858 },
    { lat: 49.2547681, lng: 19.0105119 },
    { lat: 49.2550877, lng: 19.0101622 },
    { lat: 49.2551829, lng: 19.0101525 },
    { lat: 49.2554366, lng: 19.0098108 },
    { lat: 49.2556124, lng: 19.009856 },
    { lat: 49.2558524, lng: 19.0096036 },
    { lat: 49.2559616, lng: 19.0093776 },
    { lat: 49.2562372, lng: 19.0090034 },
    { lat: 49.2563514, lng: 19.0089187 },
    { lat: 49.2566087, lng: 19.0084946 },
    { lat: 49.2567147, lng: 19.008204 },
    { lat: 49.2568271, lng: 19.0080859 },
    { lat: 49.2576732, lng: 19.0086142 },
    { lat: 49.2577921, lng: 19.0088523 },
    { lat: 49.2577917, lng: 19.0093183 },
    { lat: 49.2578567, lng: 19.0094713 },
    { lat: 49.2578975, lng: 19.0099242 },
    { lat: 49.2578518, lng: 19.0105467 },
    { lat: 49.2578294, lng: 19.0107986 },
    { lat: 49.257895, lng: 19.0112999 },
    { lat: 49.2580111, lng: 19.0116859 },
    { lat: 49.2581066, lng: 19.011634 },
    { lat: 49.2582834, lng: 19.0119014 },
    { lat: 49.2584673, lng: 19.0124019 },
    { lat: 49.258657, lng: 19.0128371 },
    { lat: 49.2589955, lng: 19.0136577 },
    { lat: 49.2593065, lng: 19.0143098 },
    { lat: 49.259589, lng: 19.0148209 },
    { lat: 49.2598953, lng: 19.0153371 },
    { lat: 49.2602916, lng: 19.0161821 },
    { lat: 49.2606318, lng: 19.0170852 },
    { lat: 49.2606654, lng: 19.0173524 },
    { lat: 49.2608905, lng: 19.0178196 },
    { lat: 49.2610667, lng: 19.0182557 },
    { lat: 49.2614293, lng: 19.018839 },
    { lat: 49.2615946, lng: 19.019058 },
    { lat: 49.2616819, lng: 19.0192694 },
    { lat: 49.2619644, lng: 19.0194857 },
    { lat: 49.2621389, lng: 19.0196852 },
    { lat: 49.2622325, lng: 19.0199136 },
    { lat: 49.2626022, lng: 19.0196613 },
    { lat: 49.262778, lng: 19.019909 },
    { lat: 49.2628735, lng: 19.019746 },
    { lat: 49.2637332, lng: 19.0207112 },
    { lat: 49.2642634, lng: 19.0213779 },
    { lat: 49.2645784, lng: 19.0218629 },
    { lat: 49.2648092, lng: 19.0224901 },
    { lat: 49.2647618, lng: 19.0228053 },
    { lat: 49.2646764, lng: 19.0232448 },
    { lat: 49.2643514, lng: 19.0240766 },
    { lat: 49.2642545, lng: 19.0244163 },
    { lat: 49.2641162, lng: 19.0259624 },
    { lat: 49.2641151, lng: 19.026348 },
    { lat: 49.263996, lng: 19.0267178 },
    { lat: 49.2639604, lng: 19.0269767 },
    { lat: 49.2639218, lng: 19.0273353 },
    { lat: 49.263959, lng: 19.0281976 },
    { lat: 49.2639805, lng: 19.0283106 },
    { lat: 49.2639787, lng: 19.0286165 },
    { lat: 49.263911, lng: 19.0292694 },
    { lat: 49.2638904, lng: 19.0301723 },
    { lat: 49.2640167, lng: 19.0307647 },
    { lat: 49.2642644, lng: 19.0314817 },
    { lat: 49.2646969, lng: 19.0327024 },
    { lat: 49.2647456, lng: 19.032704 },
    { lat: 49.2649803, lng: 19.0327107 },
    { lat: 49.2650322, lng: 19.0326661 },
    { lat: 49.2650449, lng: 19.0325126 },
    { lat: 49.2650462, lng: 19.0324255 },
    { lat: 49.265054, lng: 19.0322405 },
    { lat: 49.265065, lng: 19.0321483 },
    { lat: 49.265044, lng: 19.0321334 },
    { lat: 49.2650881, lng: 19.0320658 },
    { lat: 49.2651327, lng: 19.0319646 },
    { lat: 49.26511, lng: 19.0318258 },
    { lat: 49.2651895, lng: 19.0317725 },
    { lat: 49.2652222, lng: 19.0317034 },
    { lat: 49.2652323, lng: 19.0316362 },
    { lat: 49.2651945, lng: 19.0315315 },
    { lat: 49.2651838, lng: 19.0314299 },
    { lat: 49.265217, lng: 19.0312792 },
    { lat: 49.2651782, lng: 19.031136 },
    { lat: 49.2651465, lng: 19.0309975 },
    { lat: 49.2651044, lng: 19.0308537 },
    { lat: 49.2651195, lng: 19.0306339 },
    { lat: 49.2651443, lng: 19.0304303 },
    { lat: 49.2651658, lng: 19.030273 },
    { lat: 49.2651636, lng: 19.0300921 },
    { lat: 49.2652177, lng: 19.0297548 },
    { lat: 49.2652667, lng: 19.0295802 },
    { lat: 49.265339, lng: 19.0293339 },
    { lat: 49.2653607, lng: 19.0292513 },
    { lat: 49.265412, lng: 19.0291479 },
    { lat: 49.265482, lng: 19.0288795 },
    { lat: 49.2655153, lng: 19.0287082 },
    { lat: 49.2655581, lng: 19.0285274 },
    { lat: 49.2656105, lng: 19.0283318 },
    { lat: 49.2656956, lng: 19.0280956 },
    { lat: 49.2657184, lng: 19.0279648 },
    { lat: 49.2657277, lng: 19.0277873 },
    { lat: 49.265729, lng: 19.0277221 },
    { lat: 49.2657578, lng: 19.0276693 },
    { lat: 49.2658759, lng: 19.0275697 },
    { lat: 49.2659258, lng: 19.0275091 },
    { lat: 49.2659762, lng: 19.0273928 },
    { lat: 49.2660337, lng: 19.0272998 },
    { lat: 49.2661139, lng: 19.0272125 },
    { lat: 49.2661317, lng: 19.0272094 },
    { lat: 49.2661506, lng: 19.0272271 },
    { lat: 49.2662179, lng: 19.0272178 },
    { lat: 49.2662367, lng: 19.0272347 },
    { lat: 49.2662909, lng: 19.0271898 },
    { lat: 49.2663152, lng: 19.027156 },
    { lat: 49.2663901, lng: 19.0270744 },
    { lat: 49.2664423, lng: 19.0269698 },
    { lat: 49.2665084, lng: 19.0268928 },
    { lat: 49.2665838, lng: 19.0267815 },
    { lat: 49.2666468, lng: 19.0267327 },
    { lat: 49.266715, lng: 19.0266298 },
    { lat: 49.266736, lng: 19.0265072 },
    { lat: 49.2667428, lng: 19.0263967 },
    { lat: 49.2668143, lng: 19.026357 },
    { lat: 49.2669, lng: 19.0262701 },
    { lat: 49.2668822, lng: 19.0261361 },
    { lat: 49.2669673, lng: 19.0260793 },
    { lat: 49.2670482, lng: 19.0260071 },
    { lat: 49.2670649, lng: 19.0258915 },
    { lat: 49.2670483, lng: 19.0258284 },
    { lat: 49.2670435, lng: 19.0257553 },
    { lat: 49.2671129, lng: 19.0256314 },
    { lat: 49.2671679, lng: 19.0255106 },
    { lat: 49.2672383, lng: 19.0254125 },
    { lat: 49.2672735, lng: 19.025346 },
    { lat: 49.2673095, lng: 19.0252996 },
    { lat: 49.2673005, lng: 19.0252331 },
    { lat: 49.2673429, lng: 19.0252019 },
    { lat: 49.2673588, lng: 19.0251599 },
    { lat: 49.2673888, lng: 19.0249655 },
    { lat: 49.2673913, lng: 19.0248829 },
    { lat: 49.2674108, lng: 19.0248424 },
    { lat: 49.267479, lng: 19.024789 },
    { lat: 49.2676213, lng: 19.0244736 },
    { lat: 49.267739, lng: 19.0243942 },
    { lat: 49.2677517, lng: 19.0242411 },
    { lat: 49.2679376, lng: 19.0236514 },
    { lat: 49.2681406, lng: 19.023335 },
    { lat: 49.2682293, lng: 19.0230341 },
    { lat: 49.2683524, lng: 19.0227646 },
    { lat: 49.2683933, lng: 19.0225888 },
    { lat: 49.2684782, lng: 19.022463 },
    { lat: 49.2684887, lng: 19.0224041 },
    { lat: 49.2685125, lng: 19.0222685 },
    { lat: 49.2683364, lng: 19.0217392 },
    { lat: 49.2681554, lng: 19.0214342 },
    { lat: 49.268116, lng: 19.0211656 },
    { lat: 49.2681076, lng: 19.0205218 },
    { lat: 49.2679433, lng: 19.0196901 },
    { lat: 49.2677724, lng: 19.0189509 },
    { lat: 49.2674266, lng: 19.0183868 },
    { lat: 49.267092, lng: 19.0178489 },
    { lat: 49.26691, lng: 19.0174528 },
    { lat: 49.2667979, lng: 19.0170139 },
    { lat: 49.2666284, lng: 19.0163297 },
    { lat: 49.2664389, lng: 19.0156219 },
    { lat: 49.2661466, lng: 19.0152549 },
    { lat: 49.2658999, lng: 19.0149645 },
    { lat: 49.2663217, lng: 19.0142472 },
    { lat: 49.2668831, lng: 19.0137473 },
    { lat: 49.2674142, lng: 19.0133213 },
    { lat: 49.2674293, lng: 19.0135769 },
    { lat: 49.2675936, lng: 19.0135282 },
    { lat: 49.2676164, lng: 19.0137811 },
    { lat: 49.2678386, lng: 19.0137845 },
    { lat: 49.2683818, lng: 19.0137577 },
    { lat: 49.2688199, lng: 19.0137717 },
    { lat: 49.2688435, lng: 19.0135008 },
    { lat: 49.2693517, lng: 19.013677 },
    { lat: 49.2696737, lng: 19.0138716 },
    { lat: 49.2699963, lng: 19.0130414 },
    { lat: 49.2702102, lng: 19.0131724 },
    { lat: 49.2702547, lng: 19.0130694 },
    { lat: 49.270589, lng: 19.0132355 },
    { lat: 49.270819, lng: 19.0133958 },
    { lat: 49.2713319, lng: 19.0137382 },
    { lat: 49.2721742, lng: 19.0153026 },
    { lat: 49.2724756, lng: 19.0150398 },
    { lat: 49.2726637, lng: 19.0155345 },
    { lat: 49.2728113, lng: 19.0154206 },
    { lat: 49.2731109, lng: 19.0152557 },
    { lat: 49.2731312, lng: 19.0155256 },
    { lat: 49.2732029, lng: 19.0158374 },
    { lat: 49.2732613, lng: 19.0159 },
    { lat: 49.2734461, lng: 19.0163713 },
    { lat: 49.2736333, lng: 19.0167145 },
    { lat: 49.2738707, lng: 19.0169331 },
    { lat: 49.2741799, lng: 19.0170931 },
    { lat: 49.2744476, lng: 19.0168811 },
    { lat: 49.2745899, lng: 19.0171353 },
    { lat: 49.2747, lng: 19.0171731 },
    { lat: 49.2748407, lng: 19.0171231 },
    { lat: 49.2748623, lng: 19.0169892 },
    { lat: 49.2748144, lng: 19.0168428 },
    { lat: 49.2750054, lng: 19.0165599 },
    { lat: 49.275121, lng: 19.0165064 },
    { lat: 49.2753025, lng: 19.0165737 },
    { lat: 49.2753816, lng: 19.0165302 },
    { lat: 49.2754202, lng: 19.0162196 },
    { lat: 49.2754891, lng: 19.01597 },
    { lat: 49.2759885, lng: 19.0158585 },
    { lat: 49.2760071, lng: 19.0157336 },
    { lat: 49.2761822, lng: 19.01545 },
    { lat: 49.2761309, lng: 19.015122 },
    { lat: 49.2761041, lng: 19.0149677 },
    { lat: 49.2761245, lng: 19.0148332 },
    { lat: 49.2762588, lng: 19.0145638 },
    { lat: 49.2764499, lng: 19.0145795 },
    { lat: 49.2769125, lng: 19.0144942 },
    { lat: 49.2773539, lng: 19.0137392 },
    { lat: 49.2775895, lng: 19.0134244 },
    { lat: 49.2773486, lng: 19.0129737 },
    { lat: 49.2770815, lng: 19.012956 },
    { lat: 49.2769579, lng: 19.0129596 },
    { lat: 49.2766377, lng: 19.0130041 },
    { lat: 49.2763786, lng: 19.0126021 },
    { lat: 49.2762664, lng: 19.0124566 },
    { lat: 49.2761328, lng: 19.0123765 },
    { lat: 49.2760327, lng: 19.0121979 },
    { lat: 49.2759221, lng: 19.012222 },
    { lat: 49.2758394, lng: 19.0123006 },
    { lat: 49.2759023, lng: 19.0115491 },
    { lat: 49.2757746, lng: 19.0108871 },
    { lat: 49.2759703, lng: 19.0106112 },
    { lat: 49.2763565, lng: 19.0100319 },
    { lat: 49.27657, lng: 19.0095658 },
    { lat: 49.2770301, lng: 19.0085746 },
    { lat: 49.2768138, lng: 19.0082413 },
    { lat: 49.2767101, lng: 19.0081263 },
    { lat: 49.2769682, lng: 19.0080121 },
    { lat: 49.2771883, lng: 19.0079958 },
    { lat: 49.2773333, lng: 19.0079681 },
    { lat: 49.2775356, lng: 19.0079539 },
    { lat: 49.2777304, lng: 19.0079244 },
    { lat: 49.2779589, lng: 19.0079219 },
    { lat: 49.2782438, lng: 19.0080256 },
    { lat: 49.2783847, lng: 19.0080279 },
    { lat: 49.2784635, lng: 19.007979 },
    { lat: 49.2785911, lng: 19.0079371 },
    { lat: 49.2786641, lng: 19.0079795 },
    { lat: 49.2788095, lng: 19.0080911 },
    { lat: 49.2789292, lng: 19.0081421 },
    { lat: 49.2790678, lng: 19.0081838 },
    { lat: 49.2791107, lng: 19.0082297 },
    { lat: 49.2791657, lng: 19.0082944 },
    { lat: 49.2791801, lng: 19.0083126 },
    { lat: 49.2792677, lng: 19.0083504 },
    { lat: 49.2793024, lng: 19.0083879 },
    { lat: 49.2793276, lng: 19.0084662 },
    { lat: 49.279332, lng: 19.0084844 },
    { lat: 49.2793953, lng: 19.0084222 },
    { lat: 49.2794078, lng: 19.0083124 },
    { lat: 49.279424, lng: 19.0081838 },
    { lat: 49.2793888, lng: 19.0079756 },
    { lat: 49.2794348, lng: 19.0078363 },
    { lat: 49.2794815, lng: 19.0077543 },
    { lat: 49.2794632, lng: 19.0075918 },
    { lat: 49.2794697, lng: 19.0074942 },
    { lat: 49.279458, lng: 19.0073739 },
    { lat: 49.2794693, lng: 19.0072827 },
    { lat: 49.2795073, lng: 19.0072108 },
    { lat: 49.2795384, lng: 19.0069956 },
    { lat: 49.2795014, lng: 19.0068879 },
    { lat: 49.2795179, lng: 19.0067893 },
    { lat: 49.2795715, lng: 19.0065978 },
    { lat: 49.2796429, lng: 19.0065624 },
    { lat: 49.2797201, lng: 19.0065472 },
    { lat: 49.2798127, lng: 19.006439 },
    { lat: 49.2798507, lng: 19.0063421 },
    { lat: 49.2798599, lng: 19.0063241 },
    { lat: 49.279889, lng: 19.0063203 },
    { lat: 49.279907, lng: 19.0063207 },
    { lat: 49.2799329, lng: 19.0062473 },
    { lat: 49.2799366, lng: 19.0062122 },
    { lat: 49.2799386, lng: 19.0061159 },
    { lat: 49.2799375, lng: 19.006093 },
    { lat: 49.2799084, lng: 19.0060083 },
    { lat: 49.2799694, lng: 19.0059199 },
    { lat: 49.2800419, lng: 19.0058562 },
    { lat: 49.2801068, lng: 19.0058032 },
    { lat: 49.280163, lng: 19.0058024 },
    { lat: 49.2801823, lng: 19.0058023 },
    { lat: 49.2802193, lng: 19.0057344 },
    { lat: 49.2802888, lng: 19.0056357 },
    { lat: 49.2802767, lng: 19.0055729 },
    { lat: 49.280255, lng: 19.0055873 },
    { lat: 49.2802321, lng: 19.0055593 },
    { lat: 49.2802307, lng: 19.0055082 },
    { lat: 49.2802314, lng: 19.0054973 },
    { lat: 49.2802374, lng: 19.0054157 },
    { lat: 49.2802422, lng: 19.0053548 },
    { lat: 49.2801883, lng: 19.0051322 },
    { lat: 49.2801956, lng: 19.0050089 },
    { lat: 49.2802146, lng: 19.0048688 },
    { lat: 49.2802029, lng: 19.0048373 },
    { lat: 49.2801307, lng: 19.0047681 },
    { lat: 49.2800904, lng: 19.0046414 },
    { lat: 49.2800991, lng: 19.0045656 },
    { lat: 49.2801047, lng: 19.0045411 },
    { lat: 49.2801052, lng: 19.0043945 },
    { lat: 49.2801318, lng: 19.0042025 },
    { lat: 49.2801794, lng: 19.0040056 },
    { lat: 49.2802175, lng: 19.0039553 },
    { lat: 49.2802579, lng: 19.0038607 },
    { lat: 49.2802577, lng: 19.003791 },
    { lat: 49.2802882, lng: 19.0036781 },
    { lat: 49.2802969, lng: 19.0035832 },
    { lat: 49.2802625, lng: 19.003433 },
    { lat: 49.2802443, lng: 19.003342 },
    { lat: 49.280255, lng: 19.0031743 },
    { lat: 49.2802991, lng: 19.0030633 },
    { lat: 49.2802463, lng: 19.0028153 },
    { lat: 49.2803088, lng: 19.0026889 },
    { lat: 49.2803226, lng: 19.0025826 },
    { lat: 49.280346, lng: 19.0024217 },
    { lat: 49.2803181, lng: 19.0022473 },
    { lat: 49.2802825, lng: 19.0021446 },
    { lat: 49.2802024, lng: 19.0019362 },
    { lat: 49.2800982, lng: 19.0017931 },
    { lat: 49.2800986, lng: 19.0016638 },
    { lat: 49.2800683, lng: 19.0015308 },
    { lat: 49.2800317, lng: 19.0013838 },
    { lat: 49.2799737, lng: 19.0013104 },
    { lat: 49.2799614, lng: 19.0011544 },
    { lat: 49.2799307, lng: 19.0011024 },
    { lat: 49.2799142, lng: 19.001065 },
    { lat: 49.2799539, lng: 19.0010044 },
    { lat: 49.279912, lng: 19.0008836 },
    { lat: 49.2798847, lng: 19.0007442 },
    { lat: 49.2799458, lng: 19.0006385 },
    { lat: 49.2798943, lng: 19.0004867 },
    { lat: 49.2799402, lng: 19.0002983 },
    { lat: 49.2799357, lng: 19.0001421 },
    { lat: 49.2799392, lng: 19.0001218 },
    { lat: 49.2800016, lng: 19.0000849 },
    { lat: 49.2800398, lng: 18.9999895 },
    { lat: 49.2800937, lng: 18.9998724 },
    { lat: 49.280098, lng: 18.9998441 },
    { lat: 49.2800802, lng: 18.9997385 },
    { lat: 49.2800359, lng: 18.9995751 },
    { lat: 49.2800022, lng: 18.9993736 },
    { lat: 49.2799542, lng: 18.9991358 },
    { lat: 49.2799316, lng: 18.9990204 },
    { lat: 49.2799416, lng: 18.998904 },
    { lat: 49.2799327, lng: 18.9988159 },
    { lat: 49.2799219, lng: 18.9988027 },
    { lat: 49.2798854, lng: 18.9987709 },
    { lat: 49.2798961, lng: 18.998692 },
    { lat: 49.2799143, lng: 18.9986505 },
    { lat: 49.2800039, lng: 18.9985483 },
    { lat: 49.2800052, lng: 18.9985067 },
    { lat: 49.2800368, lng: 18.9985102 },
    { lat: 49.2800585, lng: 18.9985152 },
    { lat: 49.2799478, lng: 18.9981234 },
    { lat: 49.2797714, lng: 18.9969676 },
    { lat: 49.2792423, lng: 18.995852 },
    { lat: 49.2791916, lng: 18.9949289 },
    { lat: 49.2791419, lng: 18.9942739 },
    { lat: 49.2790849, lng: 18.9938289 },
    { lat: 49.2789473, lng: 18.993221 },
    { lat: 49.2790162, lng: 18.9927469 },
    { lat: 49.2790235, lng: 18.9917697 },
    { lat: 49.2789976, lng: 18.9917713 },
    { lat: 49.2789615, lng: 18.9914321 },
    { lat: 49.2790218, lng: 18.9909712 },
    { lat: 49.2790041, lng: 18.9898948 },
    { lat: 49.2789384, lng: 18.989233 },
    { lat: 49.2789115, lng: 18.9884505 },
    { lat: 49.2788079, lng: 18.9878893 },
    { lat: 49.2786531, lng: 18.9872708 },
    { lat: 49.2786218, lng: 18.9870744 },
    { lat: 49.2785801, lng: 18.9863066 },
    { lat: 49.2785313, lng: 18.9859654 },
    { lat: 49.2783706, lng: 18.9854126 },
    { lat: 49.2781483, lng: 18.9849346 },
    { lat: 49.2780833, lng: 18.984562 },
    { lat: 49.2784122, lng: 18.9838783 },
    { lat: 49.2788129, lng: 18.9831493 },
    { lat: 49.2789864, lng: 18.9828315 },
    { lat: 49.2793696, lng: 18.9823559 },
    { lat: 49.2796272, lng: 18.9819192 },
    { lat: 49.2802478, lng: 18.9804676 },
    { lat: 49.2803116, lng: 18.9802753 },
    { lat: 49.2803153, lng: 18.980103 },
    { lat: 49.2803508, lng: 18.9794637 },
    { lat: 49.2804874, lng: 18.9790837 },
    { lat: 49.2805776, lng: 18.9785886 },
    { lat: 49.2807789, lng: 18.9781498 },
    { lat: 49.2805372, lng: 18.9767262 },
    { lat: 49.280515, lng: 18.9765984 },
    { lat: 49.2804165, lng: 18.9759831 },
    { lat: 49.2802454, lng: 18.9753359 },
    { lat: 49.2800265, lng: 18.9748384 },
    { lat: 49.2799401, lng: 18.9745087 },
    { lat: 49.2798344, lng: 18.9739983 },
    { lat: 49.2797402, lng: 18.9733785 },
    { lat: 49.2796042, lng: 18.9727355 },
    { lat: 49.2795316, lng: 18.9722768 },
    { lat: 49.2792848, lng: 18.9706397 },
    { lat: 49.2790608, lng: 18.9697254 },
    { lat: 49.2788998, lng: 18.9687878 },
    { lat: 49.2784539, lng: 18.967021 },
    { lat: 49.2780291, lng: 18.9658162 },
    { lat: 49.2774456, lng: 18.9643212 },
    { lat: 49.2768908, lng: 18.9632411 },
    { lat: 49.276617, lng: 18.9625923 },
    { lat: 49.2764403, lng: 18.9621278 },
    { lat: 49.2764064, lng: 18.9621256 },
    { lat: 49.2757535, lng: 18.962324 },
    { lat: 49.2752178, lng: 18.9627244 },
    { lat: 49.2750754, lng: 18.9616176 },
    { lat: 49.2748231, lng: 18.9606168 },
    { lat: 49.2747971, lng: 18.9600754 },
    { lat: 49.2747565, lng: 18.959761 },
    { lat: 49.2746119, lng: 18.9597094 },
    { lat: 49.2746365, lng: 18.9595928 },
    { lat: 49.2745778, lng: 18.9594816 },
    { lat: 49.2745085, lng: 18.9594721 },
    { lat: 49.2744637, lng: 18.958895 },
    { lat: 49.2743269, lng: 18.9585471 },
    { lat: 49.2743053, lng: 18.9585229 },
    { lat: 49.2741895, lng: 18.9587741 },
    { lat: 49.2741637, lng: 18.9589154 },
    { lat: 49.2741131, lng: 18.9590488 },
    { lat: 49.2740776, lng: 18.9591938 },
    { lat: 49.2740178, lng: 18.9593051 },
    { lat: 49.2739466, lng: 18.9594586 },
    { lat: 49.2738791, lng: 18.9595965 },
    { lat: 49.2738555, lng: 18.9596683 },
    { lat: 49.2738569, lng: 18.9597371 },
    { lat: 49.2738468, lng: 18.959852 },
    { lat: 49.2738419, lng: 18.9600681 },
    { lat: 49.2738201, lng: 18.9601515 },
    { lat: 49.2737434, lng: 18.9603082 },
    { lat: 49.2736675, lng: 18.9604099 },
    { lat: 49.2735926, lng: 18.960579 },
    { lat: 49.2734228, lng: 18.9608347 },
    { lat: 49.2733034, lng: 18.9609267 },
    { lat: 49.2732006, lng: 18.9611929 },
    { lat: 49.2730946, lng: 18.9612357 },
    { lat: 49.2730126, lng: 18.9613111 },
    { lat: 49.2729438, lng: 18.9614661 },
    { lat: 49.2728567, lng: 18.9614608 },
    { lat: 49.272837, lng: 18.9614505 },
    { lat: 49.2727882, lng: 18.961467 },
    { lat: 49.2727053, lng: 18.9615002 },
    { lat: 49.2726376, lng: 18.9615432 },
    { lat: 49.2725716, lng: 18.9615982 },
    { lat: 49.2725014, lng: 18.9616354 },
    { lat: 49.2722238, lng: 18.9616766 },
    { lat: 49.2721591, lng: 18.9617113 },
    { lat: 49.2719986, lng: 18.9617045 },
    { lat: 49.2718874, lng: 18.9616862 },
    { lat: 49.2717229, lng: 18.9617362 },
    { lat: 49.2715897, lng: 18.9617092 },
    { lat: 49.2713924, lng: 18.961684 },
    { lat: 49.2712726, lng: 18.9617255 },
    { lat: 49.2710604, lng: 18.9617432 },
    { lat: 49.2708563, lng: 18.9617397 },
    { lat: 49.2707703, lng: 18.9617571 },
    { lat: 49.2706351, lng: 18.9618502 },
    { lat: 49.270491, lng: 18.9619873 },
    { lat: 49.2703451, lng: 18.9621106 },
    { lat: 49.2702347, lng: 18.9621802 },
    { lat: 49.2701656, lng: 18.9621967 },
    { lat: 49.2699451, lng: 18.9622738 },
    { lat: 49.2698833, lng: 18.9623237 },
    { lat: 49.269831, lng: 18.9623587 },
    { lat: 49.269733, lng: 18.9624947 },
    { lat: 49.2697203, lng: 18.9624684 },
    { lat: 49.2695814, lng: 18.9619006 },
    { lat: 49.2695143, lng: 18.9613963 },
    { lat: 49.2693041, lng: 18.9612534 },
    { lat: 49.2691058, lng: 18.9610536 },
    { lat: 49.2690015, lng: 18.9610846 },
    { lat: 49.268872, lng: 18.9612659 },
    { lat: 49.2687557, lng: 18.9615976 },
    { lat: 49.2687472, lng: 18.9618098 },
    { lat: 49.2686663, lng: 18.9619723 },
    { lat: 49.2682826, lng: 18.9616286 },
    { lat: 49.2681074, lng: 18.9613954 },
    { lat: 49.2680839, lng: 18.961218 },
    { lat: 49.2676452, lng: 18.9609508 },
    { lat: 49.2676486, lng: 18.9605024 },
    { lat: 49.2674371, lng: 18.9596782 },
    { lat: 49.2671941, lng: 18.9593984 },
    { lat: 49.2671575, lng: 18.9593897 },
    { lat: 49.2671233, lng: 18.95938 },
    { lat: 49.2667562, lng: 18.959278 },
    { lat: 49.2666269, lng: 18.9588113 },
    { lat: 49.2662765, lng: 18.9566635 },
    { lat: 49.2661426, lng: 18.9564269 },
    { lat: 49.2659922, lng: 18.9563706 },
    { lat: 49.2659887, lng: 18.9563493 },
    { lat: 49.2659116, lng: 18.9564024 },
    { lat: 49.265871, lng: 18.9564032 },
    { lat: 49.2658307, lng: 18.9564523 },
    { lat: 49.2657052, lng: 18.9566252 },
    { lat: 49.2655652, lng: 18.9567575 },
    { lat: 49.2652906, lng: 18.9570811 },
    { lat: 49.2648659, lng: 18.9571509 },
    { lat: 49.2645969, lng: 18.9571868 },
    { lat: 49.2645386, lng: 18.9571915 },
    { lat: 49.2643521, lng: 18.9572026 },
    { lat: 49.2640762, lng: 18.9571859 },
    { lat: 49.2638512, lng: 18.9570842 },
    { lat: 49.2636417, lng: 18.9572445 },
    { lat: 49.2633616, lng: 18.9572789 },
    { lat: 49.2632169, lng: 18.9574731 },
    { lat: 49.2631344, lng: 18.9573366 },
    { lat: 49.2630637, lng: 18.9573644 },
    { lat: 49.263035, lng: 18.957375 },
    { lat: 49.2628653, lng: 18.9574527 },
    { lat: 49.262724, lng: 18.9575552 },
    { lat: 49.2626922, lng: 18.9575759 },
    { lat: 49.2625906, lng: 18.9574406 },
    { lat: 49.2624928, lng: 18.9574441 },
    { lat: 49.2622451, lng: 18.9573823 },
    { lat: 49.2621107, lng: 18.9573118 },
    { lat: 49.2618989, lng: 18.9572003 },
    { lat: 49.261761, lng: 18.957376 },
    { lat: 49.2615608, lng: 18.9573176 },
    { lat: 49.2614402, lng: 18.9570942 },
    { lat: 49.2611636, lng: 18.9569499 },
    { lat: 49.2611235, lng: 18.9568936 },
    { lat: 49.261003, lng: 18.9569644 },
    { lat: 49.2608607, lng: 18.9570898 },
    { lat: 49.2608221, lng: 18.9569095 },
    { lat: 49.2607104, lng: 18.956971 },
    { lat: 49.2606334, lng: 18.9567176 },
    { lat: 49.2605992, lng: 18.9566015 },
    { lat: 49.2605206, lng: 18.9565595 },
    { lat: 49.2604606, lng: 18.9566033 },
    { lat: 49.2604247, lng: 18.9564892 },
    { lat: 49.2602495, lng: 18.9565953 },
    { lat: 49.2601287, lng: 18.9564799 },
    { lat: 49.2601303, lng: 18.9563996 },
    { lat: 49.2599518, lng: 18.9560538 },
    { lat: 49.2598768, lng: 18.9559764 },
    { lat: 49.2598241, lng: 18.9559577 },
    { lat: 49.2597541, lng: 18.9558677 },
    { lat: 49.259713, lng: 18.9558823 },
    { lat: 49.2596326, lng: 18.9559181 },
    { lat: 49.2595065, lng: 18.955877 },
    { lat: 49.2594492, lng: 18.9558592 },
    { lat: 49.2594521, lng: 18.9558054 },
    { lat: 49.259444, lng: 18.9557566 },
    { lat: 49.2594324, lng: 18.9556814 },
    { lat: 49.2593806, lng: 18.9554348 },
    { lat: 49.2592902, lng: 18.9545604 },
    { lat: 49.2590675, lng: 18.9537399 },
    { lat: 49.2590103, lng: 18.9535246 },
    { lat: 49.2588754, lng: 18.9533304 },
    { lat: 49.2585982, lng: 18.9529306 },
    { lat: 49.2583429, lng: 18.9525625 },
    { lat: 49.2581205, lng: 18.9522418 },
    { lat: 49.2579504, lng: 18.9519966 },
    { lat: 49.2578457, lng: 18.9518461 },
    { lat: 49.2577486, lng: 18.9517056 },
    { lat: 49.257643, lng: 18.9515541 },
    { lat: 49.2575346, lng: 18.9513972 },
    { lat: 49.2575201, lng: 18.9513755 },
    { lat: 49.257292, lng: 18.952174 },
    { lat: 49.2567903, lng: 18.9518626 },
    { lat: 49.2565574, lng: 18.9517587 },
    { lat: 49.25628, lng: 18.9516924 },
    { lat: 49.2557909, lng: 18.9516716 },
    { lat: 49.2553585, lng: 18.9521292 },
    { lat: 49.2549502, lng: 18.9518802 },
    { lat: 49.2546291, lng: 18.9519497 },
    { lat: 49.2542921, lng: 18.951571 },
    { lat: 49.2539908, lng: 18.9514329 },
    { lat: 49.2539565, lng: 18.9516675 },
    { lat: 49.2538841, lng: 18.9521571 },
    { lat: 49.2538623, lng: 18.9523065 },
    { lat: 49.2530541, lng: 18.9528859 },
    { lat: 49.2534053, lng: 18.9533917 },
    { lat: 49.2539419, lng: 18.953613 },
    { lat: 49.2537319, lng: 18.9538766 },
    { lat: 49.2535817, lng: 18.9547694 },
    { lat: 49.2533793, lng: 18.9549581 },
    { lat: 49.2528608, lng: 18.9553381 },
    { lat: 49.2522705, lng: 18.9556161 },
    { lat: 49.2522541, lng: 18.9556292 },
    { lat: 49.252089, lng: 18.9553054 },
    { lat: 49.2517464, lng: 18.955665 },
    { lat: 49.2510538, lng: 18.9557919 },
    { lat: 49.2502514, lng: 18.9559298 },
    { lat: 49.2494751, lng: 18.955916 },
    { lat: 49.2488144, lng: 18.9556032 },
    { lat: 49.2479228, lng: 18.9552262 },
    { lat: 49.2474155, lng: 18.9553374 },
    { lat: 49.2467781, lng: 18.9552608 },
    { lat: 49.2466258, lng: 18.95492 },
    { lat: 49.2465968, lng: 18.9542782 },
    { lat: 49.2465118, lng: 18.9540922 },
    { lat: 49.2462894, lng: 18.9538611 },
    { lat: 49.2460402, lng: 18.9537248 },
    { lat: 49.2459323, lng: 18.9535296 },
    { lat: 49.2457735, lng: 18.9530218 },
    { lat: 49.2458231, lng: 18.9529102 },
    { lat: 49.2456929, lng: 18.9525846 },
    { lat: 49.2454847, lng: 18.9520118 },
    { lat: 49.2452778, lng: 18.9514599 },
    { lat: 49.2451914, lng: 18.9508664 },
    { lat: 49.2448635, lng: 18.9507754 },
    { lat: 49.2447451, lng: 18.950466 },
    { lat: 49.2446205, lng: 18.9494707 },
    { lat: 49.2443517, lng: 18.9492786 },
    { lat: 49.2442524, lng: 18.9488555 },
    { lat: 49.2440153, lng: 18.9487596 },
    { lat: 49.243785, lng: 18.9479456 },
    { lat: 49.2434841, lng: 18.9466294 },
    { lat: 49.2429287, lng: 18.9462372 },
    { lat: 49.2428295, lng: 18.9462366 },
    { lat: 49.2428449, lng: 18.9460042 },
    { lat: 49.2428109, lng: 18.9455969 },
    { lat: 49.2427599, lng: 18.9451486 },
    { lat: 49.2427084, lng: 18.9448195 },
    { lat: 49.2425669, lng: 18.9441131 },
    { lat: 49.2423929, lng: 18.9431005 },
    { lat: 49.2424035, lng: 18.9428651 },
    { lat: 49.2424934, lng: 18.9421008 },
    { lat: 49.242479, lng: 18.9418149 },
    { lat: 49.2424821, lng: 18.9409591 },
    { lat: 49.2426174, lng: 18.9404903 },
    { lat: 49.2426728, lng: 18.9402958 },
    { lat: 49.2426982, lng: 18.9401254 },
    { lat: 49.242717, lng: 18.9397869 },
    { lat: 49.242679, lng: 18.9394543 },
    { lat: 49.2426271, lng: 18.9392548 },
    { lat: 49.2426084, lng: 18.9390214 },
    { lat: 49.2425809, lng: 18.9387197 },
    { lat: 49.242553, lng: 18.9383914 },
    { lat: 49.242507, lng: 18.9381973 },
    { lat: 49.2424557, lng: 18.9379814 },
    { lat: 49.2424012, lng: 18.9377941 },
    { lat: 49.2423435, lng: 18.9375934 },
    { lat: 49.2423139, lng: 18.9374508 },
    { lat: 49.2422271, lng: 18.9372287 },
    { lat: 49.2421803, lng: 18.9371065 },
    { lat: 49.2420722, lng: 18.9369319 },
    { lat: 49.2420099, lng: 18.9367877 },
    { lat: 49.2419461, lng: 18.9366188 },
    { lat: 49.2417647, lng: 18.9360636 },
    { lat: 49.2414118, lng: 18.935349 },
    { lat: 49.2412932, lng: 18.9351663 },
    { lat: 49.2411739, lng: 18.9350606 },
    { lat: 49.2405101, lng: 18.9347517 },
    { lat: 49.2400859, lng: 18.9342352 },
    { lat: 49.2397622, lng: 18.9333829 },
    { lat: 49.2396348, lng: 18.9332287 },
    { lat: 49.239514, lng: 18.9331153 },
    { lat: 49.2392301, lng: 18.9329622 },
    { lat: 49.2387371, lng: 18.9328223 },
    { lat: 49.2382983, lng: 18.9322791 },
    { lat: 49.2366278, lng: 18.9302133 },
    { lat: 49.2364873, lng: 18.9300416 },
    { lat: 49.2356269, lng: 18.9289911 },
    { lat: 49.2354718, lng: 18.9287143 },
    { lat: 49.2349961, lng: 18.927866 },
    { lat: 49.2342639, lng: 18.9259639 },
    { lat: 49.2335273, lng: 18.9242193 },
    { lat: 49.2318882, lng: 18.9196359 },
    { lat: 49.2318268, lng: 18.9197625 },
    { lat: 49.2311347, lng: 18.9211856 },
    { lat: 49.2310386, lng: 18.9213607 },
    { lat: 49.2305403, lng: 18.9223842 },
    { lat: 49.2305196, lng: 18.9223953 },
    { lat: 49.2304714, lng: 18.9224464 },
    { lat: 49.2304486, lng: 18.9224621 },
    { lat: 49.2303583, lng: 18.92284 },
    { lat: 49.2303111, lng: 18.9229758 },
    { lat: 49.2302778, lng: 18.9230086 },
    { lat: 49.2302183, lng: 18.9230369 },
    { lat: 49.2301641, lng: 18.9230577 },
    { lat: 49.230066, lng: 18.9231469 },
    { lat: 49.2299782, lng: 18.9231937 },
    { lat: 49.2299024, lng: 18.9232326 },
    { lat: 49.2298165, lng: 18.9232485 },
    { lat: 49.2297483, lng: 18.9232825 },
    { lat: 49.2296455, lng: 18.9233701 },
    { lat: 49.2295703, lng: 18.9234659 },
    { lat: 49.2295389, lng: 18.9235565 },
    { lat: 49.22953, lng: 18.9235799 },
    { lat: 49.2295013, lng: 18.9236396 },
    { lat: 49.2294835, lng: 18.9237077 },
    { lat: 49.2294562, lng: 18.9237928 },
    { lat: 49.229469, lng: 18.9238461 },
    { lat: 49.2294979, lng: 18.9240141 },
    { lat: 49.2295137, lng: 18.9241085 },
    { lat: 49.2295078, lng: 18.9241442 },
    { lat: 49.2294894, lng: 18.9241821 },
    { lat: 49.2294588, lng: 18.9242221 },
    { lat: 49.2294181, lng: 18.9242684 },
    { lat: 49.229318, lng: 18.92425 },
    { lat: 49.2293075, lng: 18.9242617 },
    { lat: 49.2292505, lng: 18.9244754 },
    { lat: 49.2291985, lng: 18.924605 },
    { lat: 49.2291545, lng: 18.9247826 },
    { lat: 49.2291687, lng: 18.9249739 },
    { lat: 49.2291863, lng: 18.9250559 },
    { lat: 49.2291864, lng: 18.925211 },
    { lat: 49.2292031, lng: 18.9252746 },
    { lat: 49.2292128, lng: 18.9253781 },
    { lat: 49.229199, lng: 18.925436 },
    { lat: 49.2291855, lng: 18.9254823 },
    { lat: 49.2291754, lng: 18.925507 },
    { lat: 49.2291266, lng: 18.9254767 },
    { lat: 49.2291207, lng: 18.9254719 },
    { lat: 49.2290999, lng: 18.9254163 },
    { lat: 49.2290253, lng: 18.9253927 },
    { lat: 49.2290173, lng: 18.9254355 },
    { lat: 49.229017, lng: 18.9254492 },
    { lat: 49.2290302, lng: 18.9256215 },
    { lat: 49.2290291, lng: 18.9256465 },
    { lat: 49.2289927, lng: 18.9257302 },
    { lat: 49.228955, lng: 18.9257173 },
    { lat: 49.2289205, lng: 18.9257026 },
    { lat: 49.2288309, lng: 18.9256497 },
    { lat: 49.2287109, lng: 18.9256837 },
    { lat: 49.2287104, lng: 18.9256975 },
    { lat: 49.2286937, lng: 18.9258564 },
    { lat: 49.2286238, lng: 18.9258993 },
    { lat: 49.2285852, lng: 18.9259623 },
    { lat: 49.228541, lng: 18.926005 },
    { lat: 49.2285161, lng: 18.9260447 },
    { lat: 49.2284694, lng: 18.9261018 },
    { lat: 49.2284549, lng: 18.9260819 },
    { lat: 49.2284392, lng: 18.926061 },
    { lat: 49.2284423, lng: 18.9261032 },
    { lat: 49.2285729, lng: 18.9270311 },
    { lat: 49.2285723, lng: 18.9270658 },
    { lat: 49.228573, lng: 18.9270791 },
    { lat: 49.2285501, lng: 18.9270951 },
    { lat: 49.2285623, lng: 18.9272708 },
    { lat: 49.2284857, lng: 18.9273152 },
    { lat: 49.2284702, lng: 18.9274111 },
    { lat: 49.2284853, lng: 18.927532 },
    { lat: 49.2284451, lng: 18.9276291 },
    { lat: 49.2284671, lng: 18.9277282 },
    { lat: 49.2284817, lng: 18.9278383 },
    { lat: 49.2284421, lng: 18.9279007 },
    { lat: 49.22842, lng: 18.9281775 },
    { lat: 49.2284142, lng: 18.9281951 },
    { lat: 49.2283803, lng: 18.9282615 },
    { lat: 49.2282988, lng: 18.9283469 },
    { lat: 49.2283044, lng: 18.9283866 },
    { lat: 49.228269, lng: 18.9284665 },
    { lat: 49.2281834, lng: 18.9285589 },
    { lat: 49.2280537, lng: 18.9287369 },
    { lat: 49.2279928, lng: 18.9287798 },
    { lat: 49.2279792, lng: 18.9287814 },
    { lat: 49.2279439, lng: 18.9287271 },
    { lat: 49.227871, lng: 18.9288237 },
    { lat: 49.2278603, lng: 18.9288831 },
    { lat: 49.2278616, lng: 18.9289085 },
    { lat: 49.2278856, lng: 18.928958 },
    { lat: 49.2279043, lng: 18.9290618 },
    { lat: 49.2279372, lng: 18.9291092 },
    { lat: 49.2280377, lng: 18.9291337 },
    { lat: 49.2280346, lng: 18.9291622 },
    { lat: 49.227973, lng: 18.9292799 },
    { lat: 49.227941, lng: 18.9292963 },
    { lat: 49.2279259, lng: 18.9293057 },
    { lat: 49.2278707, lng: 18.9293544 },
    { lat: 49.2277857, lng: 18.9294333 },
    { lat: 49.2277569, lng: 18.9294435 },
    { lat: 49.2277366, lng: 18.9294452 },
    { lat: 49.2277071, lng: 18.9293935 },
    { lat: 49.2276568, lng: 18.9293807 },
    { lat: 49.227641, lng: 18.9293815 },
    { lat: 49.2275844, lng: 18.9293972 },
    { lat: 49.227576, lng: 18.9297206 },
    { lat: 49.227447, lng: 18.9298708 },
    { lat: 49.2273611, lng: 18.9298661 },
    { lat: 49.227342, lng: 18.9299336 },
    { lat: 49.2273403, lng: 18.9299454 },
    { lat: 49.2273638, lng: 18.9299848 },
    { lat: 49.2273759, lng: 18.9300656 },
    { lat: 49.2273755, lng: 18.9300808 },
    { lat: 49.2273561, lng: 18.9301224 },
    { lat: 49.2273508, lng: 18.9301541 },
    { lat: 49.2273387, lng: 18.9302046 },
    { lat: 49.2273123, lng: 18.9302805 },
    { lat: 49.2272491, lng: 18.9303702 },
    { lat: 49.2271504, lng: 18.9303577 },
    { lat: 49.2269425, lng: 18.9304605 },
    { lat: 49.226929, lng: 18.9304603 },
    { lat: 49.2269037, lng: 18.9304496 },
    { lat: 49.2268686, lng: 18.930405 },
    { lat: 49.2268559, lng: 18.9303975 },
    { lat: 49.2268168, lng: 18.9304277 },
    { lat: 49.2268042, lng: 18.9304209 },
    { lat: 49.2267882, lng: 18.9304181 },
    { lat: 49.2267582, lng: 18.9304271 },
    { lat: 49.2267409, lng: 18.9304644 },
    { lat: 49.2267388, lng: 18.9304878 },
    { lat: 49.2267507, lng: 18.9305921 },
    { lat: 49.2267514, lng: 18.9306295 },
    { lat: 49.2266609, lng: 18.9307538 },
    { lat: 49.2266448, lng: 18.9308138 },
    { lat: 49.2266198, lng: 18.9308575 },
    { lat: 49.2265747, lng: 18.9308999 },
    { lat: 49.2265281, lng: 18.9310057 },
    { lat: 49.2263752, lng: 18.9311031 },
    { lat: 49.2263314, lng: 18.9311068 },
    { lat: 49.2262845, lng: 18.9311372 },
    { lat: 49.2261704, lng: 18.9312676 },
    { lat: 49.2261124, lng: 18.9314093 },
    { lat: 49.2260112, lng: 18.9314414 },
    { lat: 49.2260268, lng: 18.931502 },
    { lat: 49.2260272, lng: 18.9315333 },
    { lat: 49.2260267, lng: 18.931546 },
    { lat: 49.2259801, lng: 18.9315822 },
    { lat: 49.2259825, lng: 18.9316054 },
    { lat: 49.2259977, lng: 18.9316411 },
    { lat: 49.2259197, lng: 18.9317242 },
    { lat: 49.2258826, lng: 18.9317048 },
    { lat: 49.2258429, lng: 18.9317416 },
    { lat: 49.2258316, lng: 18.9318309 },
    { lat: 49.2258115, lng: 18.9318596 },
    { lat: 49.2257928, lng: 18.9318917 },
    { lat: 49.2257831, lng: 18.9318774 },
    { lat: 49.2254524, lng: 18.9320917 },
    { lat: 49.2253455, lng: 18.9322312 },
    { lat: 49.2254174, lng: 18.932428 },
    { lat: 49.2254418, lng: 18.9324388 },
    { lat: 49.2254549, lng: 18.9325664 },
    { lat: 49.2254419, lng: 18.9326192 },
    { lat: 49.2253772, lng: 18.9326553 },
    { lat: 49.2253642, lng: 18.9326651 },
    { lat: 49.225337, lng: 18.9326889 },
    { lat: 49.2253224, lng: 18.9327083 },
    { lat: 49.2252994, lng: 18.9327893 },
    { lat: 49.2253015, lng: 18.9328543 },
    { lat: 49.2253176, lng: 18.9329729 },
    { lat: 49.2253173, lng: 18.9330321 },
    { lat: 49.2252922, lng: 18.9332504 },
    { lat: 49.2252988, lng: 18.9333578 },
    { lat: 49.2253095, lng: 18.9334795 },
    { lat: 49.2253976, lng: 18.9337267 },
    { lat: 49.2254735, lng: 18.933826 },
    { lat: 49.2254979, lng: 18.9339082 },
    { lat: 49.2255184, lng: 18.9340418 },
    { lat: 49.2255757, lng: 18.9341934 },
    { lat: 49.2256212, lng: 18.9343838 },
    { lat: 49.2256386, lng: 18.9346397 },
    { lat: 49.2256315, lng: 18.9346755 },
    { lat: 49.2255808, lng: 18.9346966 },
    { lat: 49.2255407, lng: 18.9347966 },
    { lat: 49.2254774, lng: 18.9349732 },
    { lat: 49.2254026, lng: 18.9350318 },
    { lat: 49.2253367, lng: 18.935177 },
    { lat: 49.225303, lng: 18.9353372 },
    { lat: 49.2252454, lng: 18.9355113 },
    { lat: 49.2251605, lng: 18.9355509 },
    { lat: 49.2249943, lng: 18.93574 },
    { lat: 49.2249236, lng: 18.9357898 },
    { lat: 49.2248279, lng: 18.9359966 },
    { lat: 49.2247324, lng: 18.9361582 },
    { lat: 49.2247003, lng: 18.9362392 },
    { lat: 49.2245284, lng: 18.9362732 },
    { lat: 49.2245081, lng: 18.9364267 },
    { lat: 49.2242949, lng: 18.9365791 },
    { lat: 49.2241939, lng: 18.9367036 },
    { lat: 49.224182, lng: 18.9368655 },
    { lat: 49.2240255, lng: 18.9369604 },
    { lat: 49.2239712, lng: 18.9370919 },
    { lat: 49.2238435, lng: 18.9371741 },
    { lat: 49.2237984, lng: 18.9373287 },
    { lat: 49.2236652, lng: 18.9374378 },
    { lat: 49.2236319, lng: 18.9374699 },
    { lat: 49.2235985, lng: 18.9375247 },
    { lat: 49.2235419, lng: 18.9376107 },
    { lat: 49.2234215, lng: 18.9377006 },
    { lat: 49.2233856, lng: 18.9377048 },
    { lat: 49.2233038, lng: 18.9377841 },
    { lat: 49.2232815, lng: 18.9378332 },
    { lat: 49.2232138, lng: 18.9378311 },
    { lat: 49.2231207, lng: 18.9378161 },
    { lat: 49.2230117, lng: 18.9378243 },
    { lat: 49.222893, lng: 18.9379537 },
    { lat: 49.2227249, lng: 18.9379277 },
    { lat: 49.22247, lng: 18.9379689 },
    { lat: 49.2222177, lng: 18.937819 },
    { lat: 49.2218541, lng: 18.9379639 },
    { lat: 49.2216941, lng: 18.937855 },
    { lat: 49.2214897, lng: 18.9379159 },
    { lat: 49.221367, lng: 18.9379841 },
    { lat: 49.2212432, lng: 18.9380989 },
    { lat: 49.2211358, lng: 18.9380683 },
    { lat: 49.2210428, lng: 18.9380609 },
    { lat: 49.2207021, lng: 18.9380794 },
    { lat: 49.2207014, lng: 18.9380888 },
    { lat: 49.2206931, lng: 18.9381677 },
    { lat: 49.2207264, lng: 18.9392057 },
    { lat: 49.2207537, lng: 18.9396573 },
    { lat: 49.2208505, lng: 18.9402771 },
    { lat: 49.2208085, lng: 18.9402964 },
    { lat: 49.2206702, lng: 18.9407015 },
    { lat: 49.2206448, lng: 18.9408484 },
    { lat: 49.2206667, lng: 18.9416851 },
    { lat: 49.2206504, lng: 18.941854 },
    { lat: 49.220036, lng: 18.9427674 },
    { lat: 49.2200514, lng: 18.9428042 },
    { lat: 49.2197963, lng: 18.9428425 },
    { lat: 49.2196831, lng: 18.9428103 },
    { lat: 49.2197415, lng: 18.9430065 },
    { lat: 49.2201093, lng: 18.9435965 },
    { lat: 49.2201245, lng: 18.9436254 },
    { lat: 49.219923, lng: 18.9438778 },
    { lat: 49.2199316, lng: 18.9438948 },
    { lat: 49.2201655, lng: 18.9441951 },
    { lat: 49.2201131, lng: 18.9443405 },
    { lat: 49.2200795, lng: 18.9443903 },
    { lat: 49.2200768, lng: 18.9444919 },
    { lat: 49.2200122, lng: 18.9445514 },
    { lat: 49.2199379, lng: 18.9445991 },
    { lat: 49.2198924, lng: 18.9446571 },
    { lat: 49.2198712, lng: 18.94473 },
    { lat: 49.219709, lng: 18.9451342 },
    { lat: 49.2196497, lng: 18.9454788 },
    { lat: 49.2195366, lng: 18.9460046 },
    { lat: 49.219473, lng: 18.9462667 },
    { lat: 49.2192987, lng: 18.9473897 },
    { lat: 49.2192409, lng: 18.9476312 },
    { lat: 49.2191389, lng: 18.9479556 },
    { lat: 49.2190252, lng: 18.9480944 },
    { lat: 49.21894, lng: 18.9484604 },
    { lat: 49.2188405, lng: 18.9488118 },
    { lat: 49.2187749, lng: 18.9490511 },
    { lat: 49.2187543, lng: 18.9494246 },
    { lat: 49.2187076, lng: 18.9494409 },
    { lat: 49.2186998, lng: 18.9496189 },
    { lat: 49.2186445, lng: 18.9498205 },
    { lat: 49.2182795, lng: 18.9504293 },
    { lat: 49.2179272, lng: 18.9508436 },
    { lat: 49.2177319, lng: 18.9509949 },
    { lat: 49.2175596, lng: 18.9510404 },
    { lat: 49.2172661, lng: 18.9510107 },
    { lat: 49.2171298, lng: 18.9510805 },
    { lat: 49.2169934, lng: 18.9513518 },
    { lat: 49.2167926, lng: 18.9513037 },
    { lat: 49.2166801, lng: 18.9513313 },
    { lat: 49.2166271, lng: 18.9514663 },
    { lat: 49.2166377, lng: 18.9521203 },
    { lat: 49.2167289, lng: 18.9528795 },
    { lat: 49.2166935, lng: 18.9530492 },
    { lat: 49.2165084, lng: 18.9533796 },
    { lat: 49.2162831, lng: 18.953696 },
    { lat: 49.216104, lng: 18.953835 },
    { lat: 49.2158059, lng: 18.9539162 },
    { lat: 49.2156909, lng: 18.9540011 },
    { lat: 49.2156826, lng: 18.9541088 },
    { lat: 49.2157467, lng: 18.9543714 },
    { lat: 49.2157451, lng: 18.9545191 },
    { lat: 49.2156048, lng: 18.9549369 },
    { lat: 49.2156254, lng: 18.9549608 },
    { lat: 49.2157362, lng: 18.9550816 },
    { lat: 49.2159, lng: 18.9553318 },
    { lat: 49.2159671, lng: 18.9555006 },
    { lat: 49.2160197, lng: 18.9555849 },
    { lat: 49.2160431, lng: 18.9556229 },
    { lat: 49.2159688, lng: 18.9557845 },
    { lat: 49.215956, lng: 18.9558394 },
    { lat: 49.2159072, lng: 18.9559245 },
    { lat: 49.2157892, lng: 18.9560444 },
    { lat: 49.2157306, lng: 18.9560873 },
    { lat: 49.2152771, lng: 18.9563036 },
    { lat: 49.2151553, lng: 18.9564192 },
    { lat: 49.2151415, lng: 18.956613 },
    { lat: 49.2150595, lng: 18.9567543 },
    { lat: 49.2149772, lng: 18.9568223 },
    { lat: 49.2148422, lng: 18.9568476 },
    { lat: 49.2147738, lng: 18.9568552 },
    { lat: 49.2147165, lng: 18.9569038 },
    { lat: 49.2146871, lng: 18.9569491 },
    { lat: 49.2146625, lng: 18.957019 },
    { lat: 49.2146406, lng: 18.9570537 },
    { lat: 49.214609, lng: 18.9570967 },
    { lat: 49.2144939, lng: 18.9571842 },
    { lat: 49.2144696, lng: 18.9572184 },
    { lat: 49.2144114, lng: 18.9572714 },
    { lat: 49.2143841, lng: 18.9573312 },
    { lat: 49.214383, lng: 18.95738 },
    { lat: 49.2143406, lng: 18.9574759 },
    { lat: 49.2142842, lng: 18.9575423 },
    { lat: 49.2141952, lng: 18.9575672 },
    { lat: 49.2140718, lng: 18.9576023 },
    { lat: 49.2139769, lng: 18.9577306 },
    { lat: 49.2139047, lng: 18.9577971 },
    { lat: 49.2137386, lng: 18.9579014 },
    { lat: 49.2134155, lng: 18.9580907 },
    { lat: 49.2132383, lng: 18.9581076 },
    { lat: 49.2130504, lng: 18.9581383 },
    { lat: 49.2130511, lng: 18.9581068 },
    { lat: 49.2127376, lng: 18.9581945 },
    { lat: 49.2121717, lng: 18.958417 },
    { lat: 49.2117449, lng: 18.9589629 },
    { lat: 49.2114204, lng: 18.9595945 },
    { lat: 49.2106, lng: 18.960496 },
    { lat: 49.2099338, lng: 18.9617301 },
    { lat: 49.2095434, lng: 18.9621257 },
    { lat: 49.2091967, lng: 18.9622503 },
    { lat: 49.2090436, lng: 18.9623891 },
    { lat: 49.2090823, lng: 18.9625302 },
    { lat: 49.2090895, lng: 18.9632541 },
    { lat: 49.209082, lng: 18.9633963 },
    { lat: 49.2090933, lng: 18.9643808 },
    { lat: 49.2091755, lng: 18.9656822 },
    { lat: 49.209148, lng: 18.9677554 },
    { lat: 49.2091996, lng: 18.9685138 },
    { lat: 49.2092079, lng: 18.9687677 },
    { lat: 49.2092104, lng: 18.970138 },
    { lat: 49.2091969, lng: 18.9713067 },
    { lat: 49.2091554, lng: 18.972902 },
    { lat: 49.2091843, lng: 18.9730962 },
    { lat: 49.2090858, lng: 18.9732246 },
    { lat: 49.2090435, lng: 18.9732846 },
    { lat: 49.2089917, lng: 18.9733579 },
    { lat: 49.2089107, lng: 18.9734727 },
    { lat: 49.2086333, lng: 18.9738656 },
    { lat: 49.2085795, lng: 18.9739116 },
    { lat: 49.208152, lng: 18.9742772 },
    { lat: 49.2080636, lng: 18.9743527 },
    { lat: 49.2079908, lng: 18.974411 },
    { lat: 49.2077103, lng: 18.9746356 },
    { lat: 49.2076373, lng: 18.9747729 },
    { lat: 49.2075697, lng: 18.9748221 },
    { lat: 49.2074131, lng: 18.9749379 },
    { lat: 49.207248, lng: 18.9749464 },
    { lat: 49.2071704, lng: 18.9750395 },
    { lat: 49.2069895, lng: 18.9751349 },
    { lat: 49.2069136, lng: 18.9751748 },
    { lat: 49.2067069, lng: 18.9752838 },
    { lat: 49.2065688, lng: 18.9753733 },
    { lat: 49.2063312, lng: 18.9755275 },
    { lat: 49.2062395, lng: 18.9755869 },
    { lat: 49.2061971, lng: 18.975595 },
    { lat: 49.2056232, lng: 18.9757044 },
    { lat: 49.2054538, lng: 18.9757498 },
    { lat: 49.2052171, lng: 18.9758132 },
    { lat: 49.2051142, lng: 18.97587 },
    { lat: 49.2047356, lng: 18.976079 },
    { lat: 49.2044312, lng: 18.9760524 },
    { lat: 49.2043267, lng: 18.9760432 },
    { lat: 49.2040907, lng: 18.976122 },
    { lat: 49.2040139, lng: 18.9761769 },
    { lat: 49.2038487, lng: 18.9762949 },
    { lat: 49.2035541, lng: 18.9765053 },
    { lat: 49.203311, lng: 18.9768009 },
    { lat: 49.2031186, lng: 18.977035 },
    { lat: 49.2026585, lng: 18.9773501 },
    { lat: 49.2025714, lng: 18.9774098 },
    { lat: 49.2023616, lng: 18.9775644 },
    { lat: 49.2020913, lng: 18.9777823 },
    { lat: 49.2019672, lng: 18.9778824 },
    { lat: 49.2016072, lng: 18.9783157 },
    { lat: 49.2015059, lng: 18.9784375 },
    { lat: 49.201207, lng: 18.9789313 },
    { lat: 49.2010501, lng: 18.9791047 },
    { lat: 49.2008312, lng: 18.9793466 },
    { lat: 49.2006544, lng: 18.9796255 },
    { lat: 49.2005815, lng: 18.9797406 },
    { lat: 49.200365, lng: 18.980346 },
    { lat: 49.2003037, lng: 18.9804942 },
    { lat: 49.1999012, lng: 18.9814674 },
    { lat: 49.199804, lng: 18.9817025 },
    { lat: 49.1996251, lng: 18.9819821 },
    { lat: 49.1995238, lng: 18.9821404 },
    { lat: 49.1991659, lng: 18.9826219 },
    { lat: 49.1988449, lng: 18.9830539 },
    { lat: 49.1985646, lng: 18.984366 },
    { lat: 49.1984992, lng: 18.9846603 },
    { lat: 49.1981314, lng: 18.986316 },
    { lat: 49.1980634, lng: 18.9867363 },
    { lat: 49.198074, lng: 18.9872635 },
    { lat: 49.197908, lng: 18.9882263 },
    { lat: 49.1979404, lng: 18.9886691 },
    { lat: 49.1976592, lng: 18.989176 },
    { lat: 49.1973842, lng: 18.9907509 },
    { lat: 49.1969118, lng: 18.9934724 },
    { lat: 49.196674, lng: 18.9950963 },
    { lat: 49.1965471, lng: 18.9961395 },
    { lat: 49.196437, lng: 18.9970264 },
    { lat: 49.196199, lng: 18.9970225 },
    { lat: 49.1960728, lng: 18.9972729 },
    { lat: 49.1960744, lng: 18.9974832 },
    { lat: 49.1961467, lng: 18.9978702 },
    { lat: 49.1961395, lng: 18.998175 },
    { lat: 49.1961408, lng: 18.9986964 },
    { lat: 49.1960886, lng: 18.9990283 },
    { lat: 49.1959051, lng: 18.9993082 },
    { lat: 49.1958572, lng: 18.9994071 },
    { lat: 49.1957222, lng: 18.9995241 },
    { lat: 49.1956176, lng: 18.999643 },
    { lat: 49.1955117, lng: 18.9995984 },
    { lat: 49.1954421, lng: 18.9994263 },
    { lat: 49.1952383, lng: 18.9990265 },
    { lat: 49.1950125, lng: 18.9989929 },
    { lat: 49.1946904, lng: 18.9990906 },
    { lat: 49.1945215, lng: 18.9992747 },
    { lat: 49.1943533, lng: 18.9994731 },
    { lat: 49.1942336, lng: 18.9996481 },
    { lat: 49.1940491, lng: 18.9999028 },
    { lat: 49.1939978, lng: 19.0001629 },
    { lat: 49.1938421, lng: 19.0002272 },
    { lat: 49.1937888, lng: 19.0004284 },
    { lat: 49.1935617, lng: 19.0005305 },
    { lat: 49.1934315, lng: 19.0006751 },
    { lat: 49.1932189, lng: 19.0009064 },
    { lat: 49.1928744, lng: 19.0010983 },
    { lat: 49.192847, lng: 19.0007789 },
    { lat: 49.1925887, lng: 19.0009971 },
    { lat: 49.1920769, lng: 19.0009298 },
    { lat: 49.1919585, lng: 19.0003907 },
    { lat: 49.1917352, lng: 19.0003219 },
    { lat: 49.1914451, lng: 19.0001993 },
    { lat: 49.1913358, lng: 19.0000448 },
    { lat: 49.1913305, lng: 18.9998695 },
    { lat: 49.1911227, lng: 18.9997942 },
    { lat: 49.19114, lng: 19.0001653 },
    { lat: 49.1909356, lng: 19.000201 },
    { lat: 49.190729, lng: 19.0000855 },
    { lat: 49.1906802, lng: 18.999605 },
    { lat: 49.1907747, lng: 18.9992379 },
    { lat: 49.1907912, lng: 18.9985988 },
    { lat: 49.1905031, lng: 18.9980448 },
    { lat: 49.1904054, lng: 18.9978526 },
    { lat: 49.1897985, lng: 18.9976404 },
    { lat: 49.1898442, lng: 18.9967315 },
    { lat: 49.1890572, lng: 18.9950161 },
    { lat: 49.1887075, lng: 18.9936621 },
    { lat: 49.1880226, lng: 18.9925007 },
    { lat: 49.1871159, lng: 18.9902 },
    { lat: 49.1868938, lng: 18.9886943 },
  ],
  DolnáTižina: [
    { lat: 49.1868938, lng: 18.9886943 },
    { lat: 49.1871159, lng: 18.9902 },
    { lat: 49.1880226, lng: 18.9925007 },
    { lat: 49.1887075, lng: 18.9936621 },
    { lat: 49.1890572, lng: 18.9950161 },
    { lat: 49.1898442, lng: 18.9967315 },
    { lat: 49.1897985, lng: 18.9976404 },
    { lat: 49.1904054, lng: 18.9978526 },
    { lat: 49.1905031, lng: 18.9980448 },
    { lat: 49.1907912, lng: 18.9985988 },
    { lat: 49.1907747, lng: 18.9992379 },
    { lat: 49.1906802, lng: 18.999605 },
    { lat: 49.190729, lng: 19.0000855 },
    { lat: 49.1909356, lng: 19.000201 },
    { lat: 49.19114, lng: 19.0001653 },
    { lat: 49.1911227, lng: 18.9997942 },
    { lat: 49.1913305, lng: 18.9998695 },
    { lat: 49.1913358, lng: 19.0000448 },
    { lat: 49.1914451, lng: 19.0001993 },
    { lat: 49.1917352, lng: 19.0003219 },
    { lat: 49.1919585, lng: 19.0003907 },
    { lat: 49.1920769, lng: 19.0009298 },
    { lat: 49.1925887, lng: 19.0009971 },
    { lat: 49.192847, lng: 19.0007789 },
    { lat: 49.1928744, lng: 19.0010983 },
    { lat: 49.1932189, lng: 19.0009064 },
    { lat: 49.1934315, lng: 19.0006751 },
    { lat: 49.1935617, lng: 19.0005305 },
    { lat: 49.1937888, lng: 19.0004284 },
    { lat: 49.1938421, lng: 19.0002272 },
    { lat: 49.1939978, lng: 19.0001629 },
    { lat: 49.1940491, lng: 18.9999028 },
    { lat: 49.1942336, lng: 18.9996481 },
    { lat: 49.1943533, lng: 18.9994731 },
    { lat: 49.1945215, lng: 18.9992747 },
    { lat: 49.1946904, lng: 18.9990906 },
    { lat: 49.1950125, lng: 18.9989929 },
    { lat: 49.1952383, lng: 18.9990265 },
    { lat: 49.1954421, lng: 18.9994263 },
    { lat: 49.1955117, lng: 18.9995984 },
    { lat: 49.1956176, lng: 18.999643 },
    { lat: 49.1957222, lng: 18.9995241 },
    { lat: 49.1958572, lng: 18.9994071 },
    { lat: 49.1959051, lng: 18.9993082 },
    { lat: 49.1960886, lng: 18.9990283 },
    { lat: 49.1961408, lng: 18.9986964 },
    { lat: 49.1961395, lng: 18.998175 },
    { lat: 49.1961467, lng: 18.9978702 },
    { lat: 49.1960744, lng: 18.9974832 },
    { lat: 49.1960728, lng: 18.9972729 },
    { lat: 49.196199, lng: 18.9970225 },
    { lat: 49.196437, lng: 18.9970264 },
    { lat: 49.1965471, lng: 18.9961395 },
    { lat: 49.196674, lng: 18.9950963 },
    { lat: 49.1969118, lng: 18.9934724 },
    { lat: 49.1973842, lng: 18.9907509 },
    { lat: 49.1976592, lng: 18.989176 },
    { lat: 49.1979404, lng: 18.9886691 },
    { lat: 49.197908, lng: 18.9882263 },
    { lat: 49.198074, lng: 18.9872635 },
    { lat: 49.1980634, lng: 18.9867363 },
    { lat: 49.1981314, lng: 18.986316 },
    { lat: 49.1984992, lng: 18.9846603 },
    { lat: 49.1985646, lng: 18.984366 },
    { lat: 49.1988449, lng: 18.9830539 },
    { lat: 49.1991659, lng: 18.9826219 },
    { lat: 49.1995238, lng: 18.9821404 },
    { lat: 49.1996251, lng: 18.9819821 },
    { lat: 49.199804, lng: 18.9817025 },
    { lat: 49.1999012, lng: 18.9814674 },
    { lat: 49.2003037, lng: 18.9804942 },
    { lat: 49.200365, lng: 18.980346 },
    { lat: 49.2005815, lng: 18.9797406 },
    { lat: 49.2006544, lng: 18.9796255 },
    { lat: 49.2008312, lng: 18.9793466 },
    { lat: 49.2010501, lng: 18.9791047 },
    { lat: 49.201207, lng: 18.9789313 },
    { lat: 49.2015059, lng: 18.9784375 },
    { lat: 49.2016072, lng: 18.9783157 },
    { lat: 49.2019672, lng: 18.9778824 },
    { lat: 49.2020913, lng: 18.9777823 },
    { lat: 49.2023616, lng: 18.9775644 },
    { lat: 49.2025714, lng: 18.9774098 },
    { lat: 49.2026585, lng: 18.9773501 },
    { lat: 49.2031186, lng: 18.977035 },
    { lat: 49.203311, lng: 18.9768009 },
    { lat: 49.2035541, lng: 18.9765053 },
    { lat: 49.2038487, lng: 18.9762949 },
    { lat: 49.2040139, lng: 18.9761769 },
    { lat: 49.2040907, lng: 18.976122 },
    { lat: 49.2043267, lng: 18.9760432 },
    { lat: 49.2044312, lng: 18.9760524 },
    { lat: 49.2047356, lng: 18.976079 },
    { lat: 49.2051142, lng: 18.97587 },
    { lat: 49.2052171, lng: 18.9758132 },
    { lat: 49.2054538, lng: 18.9757498 },
    { lat: 49.2056232, lng: 18.9757044 },
    { lat: 49.2061971, lng: 18.975595 },
    { lat: 49.2062395, lng: 18.9755869 },
    { lat: 49.2063312, lng: 18.9755275 },
    { lat: 49.2065688, lng: 18.9753733 },
    { lat: 49.2067069, lng: 18.9752838 },
    { lat: 49.2069136, lng: 18.9751748 },
    { lat: 49.2069895, lng: 18.9751349 },
    { lat: 49.2071704, lng: 18.9750395 },
    { lat: 49.207248, lng: 18.9749464 },
    { lat: 49.2074131, lng: 18.9749379 },
    { lat: 49.2075697, lng: 18.9748221 },
    { lat: 49.2076373, lng: 18.9747729 },
    { lat: 49.2077103, lng: 18.9746356 },
    { lat: 49.2079908, lng: 18.974411 },
    { lat: 49.2080636, lng: 18.9743527 },
    { lat: 49.208152, lng: 18.9742772 },
    { lat: 49.2085795, lng: 18.9739116 },
    { lat: 49.2086333, lng: 18.9738656 },
    { lat: 49.2089107, lng: 18.9734727 },
    { lat: 49.2089917, lng: 18.9733579 },
    { lat: 49.2090435, lng: 18.9732846 },
    { lat: 49.2090858, lng: 18.9732246 },
    { lat: 49.2091843, lng: 18.9730962 },
    { lat: 49.2091554, lng: 18.972902 },
    { lat: 49.2091969, lng: 18.9713067 },
    { lat: 49.2092104, lng: 18.970138 },
    { lat: 49.2092079, lng: 18.9687677 },
    { lat: 49.2091996, lng: 18.9685138 },
    { lat: 49.209148, lng: 18.9677554 },
    { lat: 49.2091755, lng: 18.9656822 },
    { lat: 49.2090933, lng: 18.9643808 },
    { lat: 49.209082, lng: 18.9633963 },
    { lat: 49.2090895, lng: 18.9632541 },
    { lat: 49.2090823, lng: 18.9625302 },
    { lat: 49.2090436, lng: 18.9623891 },
    { lat: 49.2091967, lng: 18.9622503 },
    { lat: 49.2095434, lng: 18.9621257 },
    { lat: 49.2099338, lng: 18.9617301 },
    { lat: 49.2106, lng: 18.960496 },
    { lat: 49.2114204, lng: 18.9595945 },
    { lat: 49.2117449, lng: 18.9589629 },
    { lat: 49.2121717, lng: 18.958417 },
    { lat: 49.2127376, lng: 18.9581945 },
    { lat: 49.2130511, lng: 18.9581068 },
    { lat: 49.2130504, lng: 18.9581383 },
    { lat: 49.2132383, lng: 18.9581076 },
    { lat: 49.2134155, lng: 18.9580907 },
    { lat: 49.2137386, lng: 18.9579014 },
    { lat: 49.2139047, lng: 18.9577971 },
    { lat: 49.2139769, lng: 18.9577306 },
    { lat: 49.2140718, lng: 18.9576023 },
    { lat: 49.2141952, lng: 18.9575672 },
    { lat: 49.2142842, lng: 18.9575423 },
    { lat: 49.2143406, lng: 18.9574759 },
    { lat: 49.214383, lng: 18.95738 },
    { lat: 49.2143841, lng: 18.9573312 },
    { lat: 49.2144114, lng: 18.9572714 },
    { lat: 49.2144696, lng: 18.9572184 },
    { lat: 49.2144939, lng: 18.9571842 },
    { lat: 49.214609, lng: 18.9570967 },
    { lat: 49.2146406, lng: 18.9570537 },
    { lat: 49.2146625, lng: 18.957019 },
    { lat: 49.2146871, lng: 18.9569491 },
    { lat: 49.2147165, lng: 18.9569038 },
    { lat: 49.2147738, lng: 18.9568552 },
    { lat: 49.2148422, lng: 18.9568476 },
    { lat: 49.2149772, lng: 18.9568223 },
    { lat: 49.2150595, lng: 18.9567543 },
    { lat: 49.2151415, lng: 18.956613 },
    { lat: 49.2151553, lng: 18.9564192 },
    { lat: 49.2152771, lng: 18.9563036 },
    { lat: 49.2157306, lng: 18.9560873 },
    { lat: 49.2157892, lng: 18.9560444 },
    { lat: 49.2159072, lng: 18.9559245 },
    { lat: 49.215956, lng: 18.9558394 },
    { lat: 49.2159688, lng: 18.9557845 },
    { lat: 49.2160431, lng: 18.9556229 },
    { lat: 49.2160197, lng: 18.9555849 },
    { lat: 49.2159671, lng: 18.9555006 },
    { lat: 49.2159, lng: 18.9553318 },
    { lat: 49.2157362, lng: 18.9550816 },
    { lat: 49.2156254, lng: 18.9549608 },
    { lat: 49.2156048, lng: 18.9549369 },
    { lat: 49.2157451, lng: 18.9545191 },
    { lat: 49.2157467, lng: 18.9543714 },
    { lat: 49.2156826, lng: 18.9541088 },
    { lat: 49.2156909, lng: 18.9540011 },
    { lat: 49.2158059, lng: 18.9539162 },
    { lat: 49.216104, lng: 18.953835 },
    { lat: 49.2162831, lng: 18.953696 },
    { lat: 49.2165084, lng: 18.9533796 },
    { lat: 49.2166935, lng: 18.9530492 },
    { lat: 49.2167289, lng: 18.9528795 },
    { lat: 49.2166377, lng: 18.9521203 },
    { lat: 49.2166271, lng: 18.9514663 },
    { lat: 49.2166801, lng: 18.9513313 },
    { lat: 49.2167926, lng: 18.9513037 },
    { lat: 49.2169934, lng: 18.9513518 },
    { lat: 49.2171298, lng: 18.9510805 },
    { lat: 49.2172661, lng: 18.9510107 },
    { lat: 49.2175596, lng: 18.9510404 },
    { lat: 49.2177319, lng: 18.9509949 },
    { lat: 49.2179272, lng: 18.9508436 },
    { lat: 49.2182795, lng: 18.9504293 },
    { lat: 49.2186445, lng: 18.9498205 },
    { lat: 49.2186998, lng: 18.9496189 },
    { lat: 49.2187076, lng: 18.9494409 },
    { lat: 49.2187543, lng: 18.9494246 },
    { lat: 49.2187749, lng: 18.9490511 },
    { lat: 49.2188405, lng: 18.9488118 },
    { lat: 49.21894, lng: 18.9484604 },
    { lat: 49.2190252, lng: 18.9480944 },
    { lat: 49.2191389, lng: 18.9479556 },
    { lat: 49.2192409, lng: 18.9476312 },
    { lat: 49.2192987, lng: 18.9473897 },
    { lat: 49.219473, lng: 18.9462667 },
    { lat: 49.2195366, lng: 18.9460046 },
    { lat: 49.2196497, lng: 18.9454788 },
    { lat: 49.219709, lng: 18.9451342 },
    { lat: 49.2198712, lng: 18.94473 },
    { lat: 49.2198924, lng: 18.9446571 },
    { lat: 49.2199379, lng: 18.9445991 },
    { lat: 49.2200122, lng: 18.9445514 },
    { lat: 49.2200768, lng: 18.9444919 },
    { lat: 49.2200795, lng: 18.9443903 },
    { lat: 49.2201131, lng: 18.9443405 },
    { lat: 49.2201655, lng: 18.9441951 },
    { lat: 49.2199316, lng: 18.9438948 },
    { lat: 49.219923, lng: 18.9438778 },
    { lat: 49.2201245, lng: 18.9436254 },
    { lat: 49.2201093, lng: 18.9435965 },
    { lat: 49.2197415, lng: 18.9430065 },
    { lat: 49.2196831, lng: 18.9428103 },
    { lat: 49.2197963, lng: 18.9428425 },
    { lat: 49.2200514, lng: 18.9428042 },
    { lat: 49.220036, lng: 18.9427674 },
    { lat: 49.2206504, lng: 18.941854 },
    { lat: 49.2206667, lng: 18.9416851 },
    { lat: 49.2206448, lng: 18.9408484 },
    { lat: 49.2206702, lng: 18.9407015 },
    { lat: 49.2208085, lng: 18.9402964 },
    { lat: 49.2208505, lng: 18.9402771 },
    { lat: 49.2207537, lng: 18.9396573 },
    { lat: 49.2207264, lng: 18.9392057 },
    { lat: 49.2206931, lng: 18.9381677 },
    { lat: 49.2207014, lng: 18.9380888 },
    { lat: 49.2207021, lng: 18.9380794 },
    { lat: 49.2210428, lng: 18.9380609 },
    { lat: 49.2211358, lng: 18.9380683 },
    { lat: 49.2212432, lng: 18.9380989 },
    { lat: 49.221367, lng: 18.9379841 },
    { lat: 49.2214897, lng: 18.9379159 },
    { lat: 49.2216941, lng: 18.937855 },
    { lat: 49.2218541, lng: 18.9379639 },
    { lat: 49.2222177, lng: 18.937819 },
    { lat: 49.22247, lng: 18.9379689 },
    { lat: 49.2227249, lng: 18.9379277 },
    { lat: 49.222893, lng: 18.9379537 },
    { lat: 49.2230117, lng: 18.9378243 },
    { lat: 49.2231207, lng: 18.9378161 },
    { lat: 49.2232138, lng: 18.9378311 },
    { lat: 49.2232815, lng: 18.9378332 },
    { lat: 49.2233038, lng: 18.9377841 },
    { lat: 49.2233856, lng: 18.9377048 },
    { lat: 49.2234215, lng: 18.9377006 },
    { lat: 49.2235419, lng: 18.9376107 },
    { lat: 49.2235985, lng: 18.9375247 },
    { lat: 49.2236319, lng: 18.9374699 },
    { lat: 49.2236652, lng: 18.9374378 },
    { lat: 49.2237984, lng: 18.9373287 },
    { lat: 49.2238435, lng: 18.9371741 },
    { lat: 49.2239712, lng: 18.9370919 },
    { lat: 49.2240255, lng: 18.9369604 },
    { lat: 49.224182, lng: 18.9368655 },
    { lat: 49.2241939, lng: 18.9367036 },
    { lat: 49.2242949, lng: 18.9365791 },
    { lat: 49.2245081, lng: 18.9364267 },
    { lat: 49.2245284, lng: 18.9362732 },
    { lat: 49.2247003, lng: 18.9362392 },
    { lat: 49.2247324, lng: 18.9361582 },
    { lat: 49.2248279, lng: 18.9359966 },
    { lat: 49.2249236, lng: 18.9357898 },
    { lat: 49.2249943, lng: 18.93574 },
    { lat: 49.2251605, lng: 18.9355509 },
    { lat: 49.2252454, lng: 18.9355113 },
    { lat: 49.225303, lng: 18.9353372 },
    { lat: 49.2253367, lng: 18.935177 },
    { lat: 49.2254026, lng: 18.9350318 },
    { lat: 49.2254774, lng: 18.9349732 },
    { lat: 49.2255407, lng: 18.9347966 },
    { lat: 49.2255808, lng: 18.9346966 },
    { lat: 49.2256315, lng: 18.9346755 },
    { lat: 49.2256386, lng: 18.9346397 },
    { lat: 49.2256212, lng: 18.9343838 },
    { lat: 49.2255757, lng: 18.9341934 },
    { lat: 49.2255184, lng: 18.9340418 },
    { lat: 49.2254979, lng: 18.9339082 },
    { lat: 49.2254735, lng: 18.933826 },
    { lat: 49.2253976, lng: 18.9337267 },
    { lat: 49.2253095, lng: 18.9334795 },
    { lat: 49.2252988, lng: 18.9333578 },
    { lat: 49.2252922, lng: 18.9332504 },
    { lat: 49.2253173, lng: 18.9330321 },
    { lat: 49.2253176, lng: 18.9329729 },
    { lat: 49.2253015, lng: 18.9328543 },
    { lat: 49.2252994, lng: 18.9327893 },
    { lat: 49.2253224, lng: 18.9327083 },
    { lat: 49.225337, lng: 18.9326889 },
    { lat: 49.2253642, lng: 18.9326651 },
    { lat: 49.2253772, lng: 18.9326553 },
    { lat: 49.2254419, lng: 18.9326192 },
    { lat: 49.2254549, lng: 18.9325664 },
    { lat: 49.2254418, lng: 18.9324388 },
    { lat: 49.2254174, lng: 18.932428 },
    { lat: 49.2253455, lng: 18.9322312 },
    { lat: 49.2254524, lng: 18.9320917 },
    { lat: 49.2251885, lng: 18.9317332 },
    { lat: 49.2250771, lng: 18.9315145 },
    { lat: 49.2250632, lng: 18.9314887 },
    { lat: 49.224878, lng: 18.9311249 },
    { lat: 49.2244771, lng: 18.9306622 },
    { lat: 49.2242276, lng: 18.9303436 },
    { lat: 49.2240237, lng: 18.9300823 },
    { lat: 49.2238755, lng: 18.9298048 },
    { lat: 49.223635, lng: 18.929352 },
    { lat: 49.2233005, lng: 18.9286246 },
    { lat: 49.223077, lng: 18.9281482 },
    { lat: 49.2230638, lng: 18.9281126 },
    { lat: 49.2229546, lng: 18.927803 },
    { lat: 49.2226071, lng: 18.9266412 },
    { lat: 49.2224074, lng: 18.9259438 },
    { lat: 49.222268, lng: 18.9250434 },
    { lat: 49.2221725, lng: 18.9242922 },
    { lat: 49.2223482, lng: 18.9239779 },
    { lat: 49.2221301, lng: 18.9234557 },
    { lat: 49.2219833, lng: 18.9232055 },
    { lat: 49.2215823, lng: 18.9225242 },
    { lat: 49.2214659, lng: 18.9220969 },
    { lat: 49.2213183, lng: 18.9216303 },
    { lat: 49.2210441, lng: 18.9207563 },
    { lat: 49.2210376, lng: 18.9207398 },
    { lat: 49.2209771, lng: 18.9203722 },
    { lat: 49.2207399, lng: 18.9198502 },
    { lat: 49.2207244, lng: 18.9197215 },
    { lat: 49.2207144, lng: 18.9196347 },
    { lat: 49.2206887, lng: 18.9189052 },
    { lat: 49.2206523, lng: 18.9186523 },
    { lat: 49.2205164, lng: 18.918531 },
    { lat: 49.2205023, lng: 18.9185182 },
    { lat: 49.2204633, lng: 18.918486 },
    { lat: 49.2204882, lng: 18.9184405 },
    { lat: 49.2210937, lng: 18.9173075 },
    { lat: 49.2212367, lng: 18.9170392 },
    { lat: 49.2214877, lng: 18.9165432 },
    { lat: 49.2215289, lng: 18.916442 },
    { lat: 49.2215388, lng: 18.9161945 },
    { lat: 49.2215351, lng: 18.9160099 },
    { lat: 49.2215329, lng: 18.9157456 },
    { lat: 49.2215326, lng: 18.9157156 },
    { lat: 49.2215694, lng: 18.9155078 },
    { lat: 49.2216111, lng: 18.9153521 },
    { lat: 49.2219736, lng: 18.9145446 },
    { lat: 49.2220352, lng: 18.9143577 },
    { lat: 49.2221825, lng: 18.9136842 },
    { lat: 49.2222191, lng: 18.9133819 },
    { lat: 49.2222156, lng: 18.9130888 },
    { lat: 49.2221844, lng: 18.912875 },
    { lat: 49.2220995, lng: 18.9123151 },
    { lat: 49.2218726, lng: 18.9110424 },
    { lat: 49.2218257, lng: 18.9107836 },
    { lat: 49.221779, lng: 18.91051 },
    { lat: 49.2217197, lng: 18.910209 },
    { lat: 49.2217155, lng: 18.9101669 },
    { lat: 49.2217145, lng: 18.9100365 },
    { lat: 49.2217186, lng: 18.9099638 },
    { lat: 49.2217517, lng: 18.9098373 },
    { lat: 49.2217819, lng: 18.909745 },
    { lat: 49.2218205, lng: 18.9096622 },
    { lat: 49.222007, lng: 18.9092267 },
    { lat: 49.2220141, lng: 18.9091444 },
    { lat: 49.2220946, lng: 18.9091323 },
    { lat: 49.222106, lng: 18.9091339 },
    { lat: 49.22205, lng: 18.9089627 },
    { lat: 49.2220435, lng: 18.9089458 },
    { lat: 49.2220504, lng: 18.908928 },
    { lat: 49.2220643, lng: 18.9088023 },
    { lat: 49.2221275, lng: 18.9088038 },
    { lat: 49.2222584, lng: 18.9084511 },
    { lat: 49.2222628, lng: 18.9082486 },
    { lat: 49.2222664, lng: 18.9082316 },
    { lat: 49.2222814, lng: 18.9082392 },
    { lat: 49.222329, lng: 18.9082646 },
    { lat: 49.2223388, lng: 18.9082576 },
    { lat: 49.2224218, lng: 18.9082031 },
    { lat: 49.222438, lng: 18.9081911 },
    { lat: 49.2225093, lng: 18.9081296 },
    { lat: 49.2225127, lng: 18.9081263 },
    { lat: 49.2225179, lng: 18.9081221 },
    { lat: 49.2225223, lng: 18.9081154 },
    { lat: 49.2226421, lng: 18.9079263 },
    { lat: 49.2227327, lng: 18.9077558 },
    { lat: 49.2227629, lng: 18.9076859 },
    { lat: 49.2227654, lng: 18.9076647 },
    { lat: 49.222771, lng: 18.9076218 },
    { lat: 49.2227626, lng: 18.9076091 },
    { lat: 49.2226629, lng: 18.9074712 },
    { lat: 49.2226459, lng: 18.9074418 },
    { lat: 49.2226373, lng: 18.9074082 },
    { lat: 49.2226393, lng: 18.907382 },
    { lat: 49.2226892, lng: 18.9072766 },
    { lat: 49.2226922, lng: 18.9072697 },
    { lat: 49.2227032, lng: 18.9072644 },
    { lat: 49.2228291, lng: 18.9072113 },
    { lat: 49.222843, lng: 18.9071985 },
    { lat: 49.2228727, lng: 18.9071589 },
    { lat: 49.2228791, lng: 18.9071506 },
    { lat: 49.2228799, lng: 18.9071472 },
    { lat: 49.2229235, lng: 18.9069599 },
    { lat: 49.2229186, lng: 18.9069504 },
    { lat: 49.2228736, lng: 18.9068648 },
    { lat: 49.2228589, lng: 18.9068215 },
    { lat: 49.2228449, lng: 18.9067463 },
    { lat: 49.2228473, lng: 18.9065704 },
    { lat: 49.2228638, lng: 18.9064959 },
    { lat: 49.2228946, lng: 18.9061723 },
    { lat: 49.2229497, lng: 18.9058801 },
    { lat: 49.222902, lng: 18.9056708 },
    { lat: 49.2229005, lng: 18.9056649 },
    { lat: 49.2229003, lng: 18.9056606 },
    { lat: 49.2228865, lng: 18.9055663 },
    { lat: 49.222848, lng: 18.9052541 },
    { lat: 49.2228478, lng: 18.9052502 },
    { lat: 49.2228475, lng: 18.9052452 },
    { lat: 49.2228009, lng: 18.9050815 },
    { lat: 49.2227363, lng: 18.9049622 },
    { lat: 49.2227215, lng: 18.9049366 },
    { lat: 49.2226774, lng: 18.9048682 },
    { lat: 49.2226686, lng: 18.9048534 },
    { lat: 49.2226328, lng: 18.9047912 },
    { lat: 49.2226304, lng: 18.9047854 },
    { lat: 49.2226027, lng: 18.9046632 },
    { lat: 49.2226031, lng: 18.9046487 },
    { lat: 49.2225779, lng: 18.9044861 },
    { lat: 49.2225825, lng: 18.9044239 },
    { lat: 49.2225822, lng: 18.9043071 },
    { lat: 49.2225666, lng: 18.9042418 },
    { lat: 49.2225665, lng: 18.9042393 },
    { lat: 49.2225112, lng: 18.9041269 },
    { lat: 49.2224842, lng: 18.904042 },
    { lat: 49.2224804, lng: 18.9040324 },
    { lat: 49.2224563, lng: 18.9039815 },
    { lat: 49.2224752, lng: 18.9038426 },
    { lat: 49.2224955, lng: 18.903706 },
    { lat: 49.2224935, lng: 18.9035551 },
    { lat: 49.2224828, lng: 18.9034377 },
    { lat: 49.2224551, lng: 18.9033587 },
    { lat: 49.2224537, lng: 18.9033535 },
    { lat: 49.2224093, lng: 18.903234 },
    { lat: 49.2224211, lng: 18.9031305 },
    { lat: 49.2224388, lng: 18.9030786 },
    { lat: 49.2224571, lng: 18.9029524 },
    { lat: 49.2224871, lng: 18.902811 },
    { lat: 49.2225079, lng: 18.9026448 },
    { lat: 49.2225019, lng: 18.9025269 },
    { lat: 49.2225058, lng: 18.9024456 },
    { lat: 49.222505, lng: 18.9022989 },
    { lat: 49.2225101, lng: 18.9021125 },
    { lat: 49.2225111, lng: 18.9020857 },
    { lat: 49.2225123, lng: 18.9019316 },
    { lat: 49.2225059, lng: 18.9017596 },
    { lat: 49.2225127, lng: 18.9016062 },
    { lat: 49.2225298, lng: 18.9014779 },
    { lat: 49.2225462, lng: 18.9014709 },
    { lat: 49.2225401, lng: 18.9014147 },
    { lat: 49.2223302, lng: 18.900303 },
    { lat: 49.2223257, lng: 18.900279 },
    { lat: 49.2223213, lng: 18.9002622 },
    { lat: 49.2222861, lng: 18.9002765 },
    { lat: 49.2221491, lng: 18.9003981 },
    { lat: 49.2220498, lng: 18.9004849 },
    { lat: 49.2219952, lng: 18.9005667 },
    { lat: 49.2219191, lng: 18.9006655 },
    { lat: 49.2218427, lng: 18.9007366 },
    { lat: 49.22176, lng: 18.9007746 },
    { lat: 49.2216485, lng: 18.9007983 },
    { lat: 49.22162, lng: 18.9008125 },
    { lat: 49.2216048, lng: 18.9008904 },
    { lat: 49.2215947, lng: 18.9010488 },
    { lat: 49.221555, lng: 18.901134 },
    { lat: 49.2214256, lng: 18.9010919 },
    { lat: 49.2213565, lng: 18.9011092 },
    { lat: 49.2212944, lng: 18.9011043 },
    { lat: 49.2211978, lng: 18.901045 },
    { lat: 49.2211284, lng: 18.9009908 },
    { lat: 49.2210735, lng: 18.9009512 },
    { lat: 49.221032, lng: 18.9009352 },
    { lat: 49.2210162, lng: 18.9009342 },
    { lat: 49.2209837, lng: 18.9009597 },
    { lat: 49.2209734, lng: 18.90098 },
    { lat: 49.2209564, lng: 18.9010228 },
    { lat: 49.2209598, lng: 18.9010891 },
    { lat: 49.2209634, lng: 18.9012023 },
    { lat: 49.2209602, lng: 18.9012506 },
    { lat: 49.2208849, lng: 18.90141 },
    { lat: 49.2208634, lng: 18.9014327 },
    { lat: 49.220835, lng: 18.9014509 },
    { lat: 49.220762, lng: 18.9014559 },
    { lat: 49.2207236, lng: 18.9014334 },
    { lat: 49.2206606, lng: 18.9014127 },
    { lat: 49.220564, lng: 18.9013993 },
    { lat: 49.2204905, lng: 18.9013712 },
    { lat: 49.2204422, lng: 18.9013553 },
    { lat: 49.2203721, lng: 18.9013694 },
    { lat: 49.2203376, lng: 18.9013785 },
    { lat: 49.2203054, lng: 18.9014108 },
    { lat: 49.220196, lng: 18.9015677 },
    { lat: 49.2200966, lng: 18.9017403 },
    { lat: 49.2200802, lng: 18.9018177 },
    { lat: 49.2200569, lng: 18.9018482 },
    { lat: 49.2199895, lng: 18.901893 },
    { lat: 49.2199418, lng: 18.901912 },
    { lat: 49.2198596, lng: 18.9019131 },
    { lat: 49.2197567, lng: 18.9018859 },
    { lat: 49.2197019, lng: 18.9018737 },
    { lat: 49.2196395, lng: 18.9018389 },
    { lat: 49.2195207, lng: 18.9017668 },
    { lat: 49.219476, lng: 18.9017515 },
    { lat: 49.2194499, lng: 18.901773 },
    { lat: 49.2194001, lng: 18.901921 },
    { lat: 49.2193529, lng: 18.9020164 },
    { lat: 49.2193111, lng: 18.9020571 },
    { lat: 49.2192589, lng: 18.9020723 },
    { lat: 49.2192041, lng: 18.9020171 },
    { lat: 49.2191574, lng: 18.9020046 },
    { lat: 49.2190934, lng: 18.9019667 },
    { lat: 49.2190582, lng: 18.9019157 },
    { lat: 49.2190442, lng: 18.9018871 },
    { lat: 49.2189891, lng: 18.9018442 },
    { lat: 49.2189865, lng: 18.901834 },
    { lat: 49.2188815, lng: 18.901634 },
    { lat: 49.2188232, lng: 18.9015742 },
    { lat: 49.2187229, lng: 18.9015988 },
    { lat: 49.2184651, lng: 18.9017144 },
    { lat: 49.2183075, lng: 18.9017183 },
    { lat: 49.2182717, lng: 18.9017024 },
    { lat: 49.2182005, lng: 18.9015889 },
    { lat: 49.2181092, lng: 18.901568 },
    { lat: 49.2180558, lng: 18.9016046 },
    { lat: 49.218007, lng: 18.901663 },
    { lat: 49.217948, lng: 18.9018139 },
    { lat: 49.2179161, lng: 18.9019375 },
    { lat: 49.2178776, lng: 18.9020455 },
    { lat: 49.2178515, lng: 18.9020663 },
    { lat: 49.2178122, lng: 18.9021103 },
    { lat: 49.2177245, lng: 18.9021614 },
    { lat: 49.217639, lng: 18.9021207 },
    { lat: 49.2175726, lng: 18.9020773 },
    { lat: 49.2175004, lng: 18.9020805 },
    { lat: 49.2174295, lng: 18.9020564 },
    { lat: 49.2173044, lng: 18.9021466 },
    { lat: 49.2172394, lng: 18.9022434 },
    { lat: 49.2172454, lng: 18.9024072 },
    { lat: 49.217233, lng: 18.9024512 },
    { lat: 49.2172131, lng: 18.9025484 },
    { lat: 49.2171886, lng: 18.9026429 },
    { lat: 49.2171043, lng: 18.9027358 },
    { lat: 49.216964, lng: 18.902746 },
    { lat: 49.2169295, lng: 18.9026913 },
    { lat: 49.2168726, lng: 18.902658 },
    { lat: 49.216729, lng: 18.9026512 },
    { lat: 49.2166331, lng: 18.902734 },
    { lat: 49.216583, lng: 18.9028373 },
    { lat: 49.2165335, lng: 18.9029081 },
    { lat: 49.2164651, lng: 18.9029793 },
    { lat: 49.2163577, lng: 18.903085 },
    { lat: 49.2163397, lng: 18.9031301 },
    { lat: 49.216309, lng: 18.9033014 },
    { lat: 49.21626, lng: 18.9034014 },
    { lat: 49.216188, lng: 18.9034272 },
    { lat: 49.2160042, lng: 18.9034079 },
    { lat: 49.2158903, lng: 18.9034484 },
    { lat: 49.2157819, lng: 18.9034685 },
    { lat: 49.2157095, lng: 18.9035091 },
    { lat: 49.2155759, lng: 18.903631 },
    { lat: 49.2155364, lng: 18.9036948 },
    { lat: 49.2155105, lng: 18.9037397 },
    { lat: 49.2154266, lng: 18.9037991 },
    { lat: 49.2153196, lng: 18.9037995 },
    { lat: 49.215221, lng: 18.9038126 },
    { lat: 49.2151457, lng: 18.9039063 },
    { lat: 49.2150823, lng: 18.9039228 },
    { lat: 49.2150341, lng: 18.903886 },
    { lat: 49.2149696, lng: 18.9038568 },
    { lat: 49.2148991, lng: 18.9038468 },
    { lat: 49.2148318, lng: 18.9038753 },
    { lat: 49.2147602, lng: 18.9039483 },
    { lat: 49.2147166, lng: 18.9040682 },
    { lat: 49.2146465, lng: 18.9042211 },
    { lat: 49.2145749, lng: 18.9042772 },
    { lat: 49.2145021, lng: 18.9042638 },
    { lat: 49.214483, lng: 18.9042643 },
    { lat: 49.214465, lng: 18.9042202 },
    { lat: 49.2144561, lng: 18.9041997 },
    { lat: 49.2144227, lng: 18.9041657 },
    { lat: 49.2142992, lng: 18.904087 },
    { lat: 49.21422, lng: 18.9040581 },
    { lat: 49.2140774, lng: 18.9041569 },
    { lat: 49.2140243, lng: 18.9042245 },
    { lat: 49.2140003, lng: 18.9043741 },
    { lat: 49.2139802, lng: 18.904397 },
    { lat: 49.2139613, lng: 18.9044483 },
    { lat: 49.2139461, lng: 18.9045908 },
    { lat: 49.2138332, lng: 18.9047455 },
    { lat: 49.2136789, lng: 18.9048366 },
    { lat: 49.2135962, lng: 18.9048515 },
    { lat: 49.2134535, lng: 18.9048594 },
    { lat: 49.213393, lng: 18.9049112 },
    { lat: 49.2133244, lng: 18.9050254 },
    { lat: 49.2131713, lng: 18.9051412 },
    { lat: 49.2130405, lng: 18.9051203 },
    { lat: 49.212933, lng: 18.9052455 },
    { lat: 49.212808, lng: 18.905291 },
    { lat: 49.2126885, lng: 18.9052276 },
    { lat: 49.212655, lng: 18.9052096 },
    { lat: 49.2126117, lng: 18.9052223 },
    { lat: 49.2125162, lng: 18.905318 },
    { lat: 49.2124046, lng: 18.9053402 },
    { lat: 49.2123562, lng: 18.9053409 },
    { lat: 49.2122406, lng: 18.9053056 },
    { lat: 49.2120943, lng: 18.9053153 },
    { lat: 49.2120274, lng: 18.9053928 },
    { lat: 49.2119296, lng: 18.9054441 },
    { lat: 49.2118253, lng: 18.9055906 },
    { lat: 49.2117489, lng: 18.9055942 },
    { lat: 49.2116782, lng: 18.9055132 },
    { lat: 49.2116208, lng: 18.9054493 },
    { lat: 49.2115655, lng: 18.9054425 },
    { lat: 49.2114525, lng: 18.9055074 },
    { lat: 49.2109671, lng: 18.9060485 },
    { lat: 49.2102877, lng: 18.907034 },
    { lat: 49.2090765, lng: 18.9088019 },
    { lat: 49.207835, lng: 18.910597 },
    { lat: 49.2074933, lng: 18.9111502 },
    { lat: 49.2071598, lng: 18.9116865 },
    { lat: 49.2068615, lng: 18.9120283 },
    { lat: 49.2070188, lng: 18.912283 },
    { lat: 49.2071519, lng: 18.9124616 },
    { lat: 49.206703, lng: 18.9134377 },
    { lat: 49.2061958, lng: 18.9144806 },
    { lat: 49.2060086, lng: 18.914837 },
    { lat: 49.2055878, lng: 18.9155675 },
    { lat: 49.2054176, lng: 18.9157884 },
    { lat: 49.2048469, lng: 18.917407 },
    { lat: 49.2046453, lng: 18.9179633 },
    { lat: 49.2041242, lng: 18.9192922 },
    { lat: 49.2040503, lng: 18.9206815 },
    { lat: 49.2039671, lng: 18.9211748 },
    { lat: 49.2038338, lng: 18.9219701 },
    { lat: 49.2038732, lng: 18.9231238 },
    { lat: 49.2039153, lng: 18.9248686 },
    { lat: 49.2035985, lng: 18.9262468 },
    { lat: 49.2028971, lng: 18.9274049 },
    { lat: 49.2028347, lng: 18.9282175 },
    { lat: 49.2026113, lng: 18.9287504 },
    { lat: 49.2013601, lng: 18.9308686 },
    { lat: 49.2010462, lng: 18.9312859 },
    { lat: 49.2007882, lng: 18.931621 },
    { lat: 49.2009417, lng: 18.9326517 },
    { lat: 49.2010576, lng: 18.933336 },
    { lat: 49.2009154, lng: 18.9338448 },
    { lat: 49.200732, lng: 18.9346329 },
    { lat: 49.2005741, lng: 18.9352092 },
    { lat: 49.2003567, lng: 18.9356617 },
    { lat: 49.2002226, lng: 18.9367994 },
    { lat: 49.2001016, lng: 18.9369698 },
    { lat: 49.1999997, lng: 18.9375384 },
    { lat: 49.1997904, lng: 18.938062 },
    { lat: 49.1994056, lng: 18.9388341 },
    { lat: 49.1990768, lng: 18.9391522 },
    { lat: 49.1991555, lng: 18.9395048 },
    { lat: 49.19929, lng: 18.9399123 },
    { lat: 49.1991196, lng: 18.9406677 },
    { lat: 49.1990386, lng: 18.9414765 },
    { lat: 49.1989167, lng: 18.9420518 },
    { lat: 49.199017, lng: 18.9422978 },
    { lat: 49.1985425, lng: 18.9430162 },
    { lat: 49.1986497, lng: 18.9443335 },
    { lat: 49.1988526, lng: 18.9444444 },
    { lat: 49.1990296, lng: 18.9446896 },
    { lat: 49.1992256, lng: 18.9451089 },
    { lat: 49.1992393, lng: 18.9461185 },
    { lat: 49.1992242, lng: 18.9465547 },
    { lat: 49.1991686, lng: 18.9472177 },
    { lat: 49.1991167, lng: 18.9475097 },
    { lat: 49.1987142, lng: 18.948583 },
    { lat: 49.1987387, lng: 18.9488199 },
    { lat: 49.1986251, lng: 18.9492752 },
    { lat: 49.1988314, lng: 18.9496316 },
    { lat: 49.1990075, lng: 18.9509542 },
    { lat: 49.1990075, lng: 18.9517541 },
    { lat: 49.1987252, lng: 18.9521059 },
    { lat: 49.1984498, lng: 18.9523452 },
    { lat: 49.1983469, lng: 18.952451 },
    { lat: 49.1982408, lng: 18.9526044 },
    { lat: 49.1978776, lng: 18.9530253 },
    { lat: 49.1975916, lng: 18.9533202 },
    { lat: 49.1976016, lng: 18.9534311 },
    { lat: 49.1973059, lng: 18.9538963 },
    { lat: 49.1970268, lng: 18.9545066 },
    { lat: 49.1966636, lng: 18.9550387 },
    { lat: 49.1963599, lng: 18.9557917 },
    { lat: 49.1961246, lng: 18.9562923 },
    { lat: 49.1956403, lng: 18.9569017 },
    { lat: 49.1948881, lng: 18.9576133 },
    { lat: 49.1944113, lng: 18.9579972 },
    { lat: 49.1941978, lng: 18.958457 },
    { lat: 49.1940647, lng: 18.9588597 },
    { lat: 49.1936631, lng: 18.9602654 },
    { lat: 49.1934573, lng: 18.9606781 },
    { lat: 49.1934041, lng: 18.9607846 },
    { lat: 49.1933208, lng: 18.9613015 },
    { lat: 49.1932926, lng: 18.9615925 },
    { lat: 49.193257, lng: 18.9619592 },
    { lat: 49.1931737, lng: 18.9625468 },
    { lat: 49.1930874, lng: 18.9628698 },
    { lat: 49.1930922, lng: 18.9631663 },
    { lat: 49.1930996, lng: 18.9635147 },
    { lat: 49.1930654, lng: 18.9641545 },
    { lat: 49.1930198, lng: 18.9644356 },
    { lat: 49.1929052, lng: 18.9650924 },
    { lat: 49.1926324, lng: 18.9657217 },
    { lat: 49.1923649, lng: 18.9661203 },
    { lat: 49.1920553, lng: 18.9663934 },
    { lat: 49.1917619, lng: 18.9665442 },
    { lat: 49.1914701, lng: 18.9666177 },
    { lat: 49.1909973, lng: 18.9669448 },
    { lat: 49.190294, lng: 18.967734 },
    { lat: 49.1893113, lng: 18.9685242 },
    { lat: 49.1882615, lng: 18.9695723 },
    { lat: 49.187045, lng: 18.9699083 },
    { lat: 49.1866419, lng: 18.9703195 },
    { lat: 49.1864173, lng: 18.9704946 },
    { lat: 49.1861944, lng: 18.975953 },
    { lat: 49.1872001, lng: 18.9790704 },
    { lat: 49.1874861, lng: 18.9820953 },
    { lat: 49.187749, lng: 18.9843017 },
    { lat: 49.1868938, lng: 18.9886943 },
  ],
  Gbeľany: [
    { lat: 49.249547, lng: 18.876432 },
    { lat: 49.2495226, lng: 18.8765329 },
    { lat: 49.2489761, lng: 18.8760974 },
    { lat: 49.2482714, lng: 18.8763196 },
    { lat: 49.2481401, lng: 18.8763294 },
    { lat: 49.248134, lng: 18.8764106 },
    { lat: 49.247714, lng: 18.8765076 },
    { lat: 49.247481, lng: 18.8763401 },
    { lat: 49.2473582, lng: 18.8762771 },
    { lat: 49.2470193, lng: 18.8763492 },
    { lat: 49.2469749, lng: 18.8760756 },
    { lat: 49.2470489, lng: 18.8748733 },
    { lat: 49.2476121, lng: 18.8747341 },
    { lat: 49.2476272, lng: 18.8746323 },
    { lat: 49.2478628, lng: 18.8746526 },
    { lat: 49.2479238, lng: 18.8741711 },
    { lat: 49.2476757, lng: 18.8741018 },
    { lat: 49.2472712, lng: 18.8735549 },
    { lat: 49.2470212, lng: 18.8734068 },
    { lat: 49.2467288, lng: 18.8730649 },
    { lat: 49.24607, lng: 18.8719117 },
    { lat: 49.2454498, lng: 18.8717117 },
    { lat: 49.2451872, lng: 18.8716272 },
    { lat: 49.2449559, lng: 18.8714685 },
    { lat: 49.244985, lng: 18.8712666 },
    { lat: 49.2443091, lng: 18.8710126 },
    { lat: 49.2442792, lng: 18.8710011 },
    { lat: 49.2442815, lng: 18.8709582 },
    { lat: 49.2442862, lng: 18.8708703 },
    { lat: 49.2441596, lng: 18.8706443 },
    { lat: 49.2437562, lng: 18.8703832 },
    { lat: 49.2435448, lng: 18.8701998 },
    { lat: 49.2432273, lng: 18.8695434 },
    { lat: 49.2431678, lng: 18.8696833 },
    { lat: 49.2429271, lng: 18.8698271 },
    { lat: 49.2428145, lng: 18.8697409 },
    { lat: 49.2425553, lng: 18.8695416 },
    { lat: 49.2425238, lng: 18.8695684 },
    { lat: 49.2423861, lng: 18.8693871 },
    { lat: 49.242343, lng: 18.8693583 },
    { lat: 49.2421502, lng: 18.8692287 },
    { lat: 49.242093, lng: 18.8691904 },
    { lat: 49.2419031, lng: 18.8689601 },
    { lat: 49.2418154, lng: 18.8688543 },
    { lat: 49.2412692, lng: 18.8684699 },
    { lat: 49.241236, lng: 18.8684352 },
    { lat: 49.2409252, lng: 18.868114 },
    { lat: 49.2407891, lng: 18.8682301 },
    { lat: 49.2405148, lng: 18.8679381 },
    { lat: 49.2407147, lng: 18.8675924 },
    { lat: 49.2413822, lng: 18.8664443 },
    { lat: 49.2406079, lng: 18.864949 },
    { lat: 49.2401436, lng: 18.8644509 },
    { lat: 49.239848, lng: 18.8641362 },
    { lat: 49.2395102, lng: 18.8650053 },
    { lat: 49.2384421, lng: 18.863915 },
    { lat: 49.238068, lng: 18.8634555 },
    { lat: 49.2377088, lng: 18.8628486 },
    { lat: 49.2373825, lng: 18.8624246 },
    { lat: 49.2375813, lng: 18.8619877 },
    { lat: 49.2374723, lng: 18.86182 },
    { lat: 49.237426, lng: 18.8616866 },
    { lat: 49.2372045, lng: 18.8612584 },
    { lat: 49.2370745, lng: 18.8610509 },
    { lat: 49.2373881, lng: 18.8606141 },
    { lat: 49.237273, lng: 18.8603904 },
    { lat: 49.2371529, lng: 18.8595872 },
    { lat: 49.2371502, lng: 18.8592938 },
    { lat: 49.2371006, lng: 18.8590504 },
    { lat: 49.2370064, lng: 18.8588852 },
    { lat: 49.2366608, lng: 18.8588081 },
    { lat: 49.2364128, lng: 18.8588735 },
    { lat: 49.2362703, lng: 18.8581386 },
    { lat: 49.2355857, lng: 18.8581124 },
    { lat: 49.235345, lng: 18.8576624 },
    { lat: 49.2353217, lng: 18.8574047 },
    { lat: 49.2352244, lng: 18.8570238 },
    { lat: 49.2348018, lng: 18.8566965 },
    { lat: 49.2346623, lng: 18.8564408 },
    { lat: 49.2346222, lng: 18.856184 },
    { lat: 49.2336325, lng: 18.8550914 },
    { lat: 49.2332544, lng: 18.855256 },
    { lat: 49.2328992, lng: 18.8550383 },
    { lat: 49.2331715, lng: 18.8534631 },
    { lat: 49.2331968, lng: 18.8523943 },
    { lat: 49.2327468, lng: 18.8521984 },
    { lat: 49.2323579, lng: 18.8518661 },
    { lat: 49.2319283, lng: 18.8516256 },
    { lat: 49.2316971, lng: 18.8515818 },
    { lat: 49.2313639, lng: 18.8512154 },
    { lat: 49.2314098, lng: 18.8510555 },
    { lat: 49.231241, lng: 18.850887 },
    { lat: 49.230995, lng: 18.8506422 },
    { lat: 49.2308462, lng: 18.8506438 },
    { lat: 49.230465, lng: 18.8501352 },
    { lat: 49.2291592, lng: 18.8492737 },
    { lat: 49.2289865, lng: 18.8492929 },
    { lat: 49.2276244, lng: 18.8486102 },
    { lat: 49.2263841, lng: 18.8474418 },
    { lat: 49.2264238, lng: 18.8468754 },
    { lat: 49.2263712, lng: 18.8467302 },
    { lat: 49.2261153, lng: 18.8464906 },
    { lat: 49.2256132, lng: 18.8456694 },
    { lat: 49.2252125, lng: 18.8451972 },
    { lat: 49.2245596, lng: 18.8445369 },
    { lat: 49.2240198, lng: 18.8446993 },
    { lat: 49.2240475, lng: 18.8448846 },
    { lat: 49.2239105, lng: 18.8452484 },
    { lat: 49.2238626, lng: 18.8451246 },
    { lat: 49.223825, lng: 18.8452309 },
    { lat: 49.2237603, lng: 18.845046 },
    { lat: 49.2235491, lng: 18.8447076 },
    { lat: 49.2233446, lng: 18.8444136 },
    { lat: 49.2233565, lng: 18.8444933 },
    { lat: 49.2227927, lng: 18.8442304 },
    { lat: 49.222577, lng: 18.8440885 },
    { lat: 49.222396, lng: 18.844055 },
    { lat: 49.2220249, lng: 18.8437664 },
    { lat: 49.2219975, lng: 18.8436752 },
    { lat: 49.2219031, lng: 18.8435718 },
    { lat: 49.2216026, lng: 18.8439955 },
    { lat: 49.2213985, lng: 18.8442587 },
    { lat: 49.221148, lng: 18.844345 },
    { lat: 49.2209941, lng: 18.8442469 },
    { lat: 49.2206943, lng: 18.8439383 },
    { lat: 49.220436, lng: 18.8435649 },
    { lat: 49.2202138, lng: 18.8432762 },
    { lat: 49.2200791, lng: 18.8428942 },
    { lat: 49.219883, lng: 18.8425678 },
    { lat: 49.2195623, lng: 18.8422949 },
    { lat: 49.2195754, lng: 18.8422414 },
    { lat: 49.2198728, lng: 18.8412701 },
    { lat: 49.2200823, lng: 18.8405893 },
    { lat: 49.2198139, lng: 18.8403852 },
    { lat: 49.2196176, lng: 18.8402539 },
    { lat: 49.2193438, lng: 18.8399957 },
    { lat: 49.2190962, lng: 18.8397396 },
    { lat: 49.2187963, lng: 18.8394535 },
    { lat: 49.21829, lng: 18.8389933 },
    { lat: 49.2180022, lng: 18.8387439 },
    { lat: 49.2177476, lng: 18.8385079 },
    { lat: 49.2169886, lng: 18.8378013 },
    { lat: 49.2168207, lng: 18.8382459 },
    { lat: 49.216753, lng: 18.8384178 },
    { lat: 49.2165404, lng: 18.8389785 },
    { lat: 49.2163798, lng: 18.8394497 },
    { lat: 49.2162993, lng: 18.8396624 },
    { lat: 49.2162975, lng: 18.8396683 },
    { lat: 49.2162868, lng: 18.8397028 },
    { lat: 49.2162704, lng: 18.8397564 },
    { lat: 49.216065, lng: 18.8404302 },
    { lat: 49.215888, lng: 18.8402782 },
    { lat: 49.2158715, lng: 18.8402647 },
    { lat: 49.2148634, lng: 18.8394059 },
    { lat: 49.2129351, lng: 18.8377596 },
    { lat: 49.2123649, lng: 18.8373248 },
    { lat: 49.21168, lng: 18.836765 },
    { lat: 49.2110695, lng: 18.8363209 },
    { lat: 49.2110521, lng: 18.8363083 },
    { lat: 49.2109608, lng: 18.8362413 },
    { lat: 49.2107863, lng: 18.8361147 },
    { lat: 49.21071, lng: 18.83606 },
    { lat: 49.2106152, lng: 18.8359899 },
    { lat: 49.2105742, lng: 18.8359605 },
    { lat: 49.2106363, lng: 18.8358081 },
    { lat: 49.2106474, lng: 18.8357848 },
    { lat: 49.210672, lng: 18.8357353 },
    { lat: 49.2108126, lng: 18.8354455 },
    { lat: 49.2109494, lng: 18.8349494 },
    { lat: 49.2111468, lng: 18.834268 },
    { lat: 49.2113409, lng: 18.8335718 },
    { lat: 49.2113474, lng: 18.8335465 },
    { lat: 49.2113573, lng: 18.833519 },
    { lat: 49.2113633, lng: 18.8335024 },
    { lat: 49.2124054, lng: 18.8342531 },
    { lat: 49.2129171, lng: 18.8346242 },
    { lat: 49.2137745, lng: 18.8351349 },
    { lat: 49.2142004, lng: 18.8353709 },
    { lat: 49.2145569, lng: 18.8355954 },
    { lat: 49.214849, lng: 18.8357483 },
    { lat: 49.2152441, lng: 18.8359343 },
    { lat: 49.2156206, lng: 18.8360425 },
    { lat: 49.2160621, lng: 18.8361422 },
    { lat: 49.2164096, lng: 18.8347209 },
    { lat: 49.2166901, lng: 18.8337979 },
    { lat: 49.2166997, lng: 18.833765 },
    { lat: 49.2167259, lng: 18.8336811 },
    { lat: 49.2167724, lng: 18.8335565 },
    { lat: 49.2161182, lng: 18.8328494 },
    { lat: 49.2157869, lng: 18.8325241 },
    { lat: 49.2152992, lng: 18.8320315 },
    { lat: 49.2146246, lng: 18.831364 },
    { lat: 49.2138441, lng: 18.8307478 },
    { lat: 49.2134554, lng: 18.8304028 },
    { lat: 49.2132971, lng: 18.8303064 },
    { lat: 49.2130469, lng: 18.8301322 },
    { lat: 49.212448, lng: 18.8297388 },
    { lat: 49.2124365, lng: 18.8297312 },
    { lat: 49.2122204, lng: 18.8295855 },
    { lat: 49.2116745, lng: 18.8291875 },
    { lat: 49.2114452, lng: 18.8290246 },
    { lat: 49.211191, lng: 18.828842 },
    { lat: 49.2107806, lng: 18.8285814 },
    { lat: 49.210389, lng: 18.8283316 },
    { lat: 49.2098879, lng: 18.8281032 },
    { lat: 49.209704, lng: 18.8280128 },
    { lat: 49.2094969, lng: 18.827911 },
    { lat: 49.2090018, lng: 18.827669 },
    { lat: 49.2086578, lng: 18.8274445 },
    { lat: 49.2081433, lng: 18.8270251 },
    { lat: 49.2077583, lng: 18.8267511 },
    { lat: 49.2073266, lng: 18.8264712 },
    { lat: 49.207213, lng: 18.8264311 },
    { lat: 49.2071887, lng: 18.8264408 },
    { lat: 49.2069489, lng: 18.8265385 },
    { lat: 49.2068882, lng: 18.8265371 },
    { lat: 49.2068633, lng: 18.8265365 },
    { lat: 49.2067652, lng: 18.8265349 },
    { lat: 49.2067446, lng: 18.8265302 },
    { lat: 49.2063729, lng: 18.8265146 },
    { lat: 49.2063164, lng: 18.8265141 },
    { lat: 49.2063017, lng: 18.8265786 },
    { lat: 49.2062888, lng: 18.8265708 },
    { lat: 49.206291, lng: 18.8265496 },
    { lat: 49.2061608, lng: 18.8265573 },
    { lat: 49.2060974, lng: 18.826574 },
    { lat: 49.2060017, lng: 18.8266204 },
    { lat: 49.205496, lng: 18.8268715 },
    { lat: 49.2048666, lng: 18.8271745 },
    { lat: 49.2043108, lng: 18.8274385 },
    { lat: 49.2038149, lng: 18.8276822 },
    { lat: 49.2036086, lng: 18.8278379 },
    { lat: 49.2034256, lng: 18.8280499 },
    { lat: 49.2030893, lng: 18.8285034 },
    { lat: 49.2028116, lng: 18.8288674 },
    { lat: 49.202437, lng: 18.8293482 },
    { lat: 49.202274, lng: 18.8295737 },
    { lat: 49.2022487, lng: 18.8296092 },
    { lat: 49.2020135, lng: 18.8299009 },
    { lat: 49.2018238, lng: 18.8299843 },
    { lat: 49.2015616, lng: 18.8302151 },
    { lat: 49.2009545, lng: 18.8306203 },
    { lat: 49.2003825, lng: 18.8310066 },
    { lat: 49.2002005, lng: 18.8311514 },
    { lat: 49.1998466, lng: 18.8315695 },
    { lat: 49.1997633, lng: 18.8316592 },
    { lat: 49.1996727, lng: 18.8316765 },
    { lat: 49.1995914, lng: 18.8316931 },
    { lat: 49.1995944, lng: 18.8317753 },
    { lat: 49.1995409, lng: 18.8323781 },
    { lat: 49.1995546, lng: 18.8325989 },
    { lat: 49.1995568, lng: 18.8329026 },
    { lat: 49.1995608, lng: 18.8331361 },
    { lat: 49.1995598, lng: 18.8331593 },
    { lat: 49.1995569, lng: 18.8331946 },
    { lat: 49.1995632, lng: 18.8334048 },
    { lat: 49.1995671, lng: 18.8337832 },
    { lat: 49.1995336, lng: 18.8341413 },
    { lat: 49.1994687, lng: 18.8345013 },
    { lat: 49.1993089, lng: 18.8352755 },
    { lat: 49.1991777, lng: 18.8358353 },
    { lat: 49.1990374, lng: 18.8363965 },
    { lat: 49.1990042, lng: 18.8365634 },
    { lat: 49.1989839, lng: 18.8367375 },
    { lat: 49.1989714, lng: 18.8367343 },
    { lat: 49.1989565, lng: 18.8367296 },
    { lat: 49.1989221, lng: 18.8367222 },
    { lat: 49.1989187, lng: 18.8367193 },
    { lat: 49.198905, lng: 18.8367152 },
    { lat: 49.1987101, lng: 18.8364775 },
    { lat: 49.198508, lng: 18.836187 },
    { lat: 49.1981645, lng: 18.8358478 },
    { lat: 49.1977751, lng: 18.8355068 },
    { lat: 49.1974408, lng: 18.8352357 },
    { lat: 49.1971111, lng: 18.8350052 },
    { lat: 49.1967302, lng: 18.8348342 },
    { lat: 49.196485, lng: 18.8347384 },
    { lat: 49.1964101, lng: 18.8346836 },
    { lat: 49.1961394, lng: 18.8344831 },
    { lat: 49.196022, lng: 18.8342629 },
    { lat: 49.1958897, lng: 18.8340144 },
    { lat: 49.1958009, lng: 18.8338459 },
    { lat: 49.1957225, lng: 18.8337003 },
    { lat: 49.1955415, lng: 18.8333593 },
    { lat: 49.195515, lng: 18.8333091 },
    { lat: 49.1955001, lng: 18.8332813 },
    { lat: 49.1953829, lng: 18.8330596 },
    { lat: 49.195374, lng: 18.833043 },
    { lat: 49.1953465, lng: 18.8329911 },
    { lat: 49.1953392, lng: 18.8329783 },
    { lat: 49.1953379, lng: 18.8329759 },
    { lat: 49.1952955, lng: 18.8328949 },
    { lat: 49.1952069, lng: 18.8327292 },
    { lat: 49.1950076, lng: 18.8324513 },
    { lat: 49.1949548, lng: 18.8324317 },
    { lat: 49.1945643, lng: 18.8320105 },
    { lat: 49.1941723, lng: 18.831555 },
    { lat: 49.1940859, lng: 18.8314564 },
    { lat: 49.1940754, lng: 18.8316232 },
    { lat: 49.1940783, lng: 18.8316564 },
    { lat: 49.1939533, lng: 18.8317712 },
    { lat: 49.1938407, lng: 18.8320587 },
    { lat: 49.1937325, lng: 18.8321924 },
    { lat: 49.1935007, lng: 18.8325728 },
    { lat: 49.1934164, lng: 18.8327516 },
    { lat: 49.1930306, lng: 18.8336014 },
    { lat: 49.1929137, lng: 18.8339388 },
    { lat: 49.1926982, lng: 18.8343727 },
    { lat: 49.1924061, lng: 18.8348132 },
    { lat: 49.1924179, lng: 18.8352174 },
    { lat: 49.1922103, lng: 18.8354989 },
    { lat: 49.192041, lng: 18.8358224 },
    { lat: 49.1917783, lng: 18.8364386 },
    { lat: 49.1918165, lng: 18.8365014 },
    { lat: 49.1920531, lng: 18.8372644 },
    { lat: 49.1920599, lng: 18.8372863 },
    { lat: 49.1929673, lng: 18.8402429 },
    { lat: 49.1931802, lng: 18.8402809 },
    { lat: 49.193572, lng: 18.8403609 },
    { lat: 49.1939903, lng: 18.8404457 },
    { lat: 49.1940323, lng: 18.8404533 },
    { lat: 49.1941598, lng: 18.840474 },
    { lat: 49.1947782, lng: 18.8396707 },
    { lat: 49.1949297, lng: 18.8390894 },
    { lat: 49.1950046, lng: 18.8386612 },
    { lat: 49.1950693, lng: 18.8385601 },
    { lat: 49.1951443, lng: 18.8385262 },
    { lat: 49.1952767, lng: 18.8385587 },
    { lat: 49.1957109, lng: 18.8388892 },
    { lat: 49.1961851, lng: 18.8392287 },
    { lat: 49.19644, lng: 18.8394076 },
    { lat: 49.1966014, lng: 18.8395422 },
    { lat: 49.1969802, lng: 18.8398044 },
    { lat: 49.1977604, lng: 18.8403524 },
    { lat: 49.1983585, lng: 18.8407585 },
    { lat: 49.1985833, lng: 18.8408982 },
    { lat: 49.1988427, lng: 18.8410308 },
    { lat: 49.1991034, lng: 18.8411673 },
    { lat: 49.1995252, lng: 18.8413663 },
    { lat: 49.2000123, lng: 18.8416303 },
    { lat: 49.2000747, lng: 18.8417047 },
    { lat: 49.2002261, lng: 18.8418654 },
    { lat: 49.2004473, lng: 18.8420643 },
    { lat: 49.2006725, lng: 18.842258 },
    { lat: 49.2009493, lng: 18.8424661 },
    { lat: 49.2012858, lng: 18.8425188 },
    { lat: 49.2014991, lng: 18.8425676 },
    { lat: 49.2015528, lng: 18.8425373 },
    { lat: 49.2017207, lng: 18.842426 },
    { lat: 49.2019037, lng: 18.8422988 },
    { lat: 49.2019317, lng: 18.8422515 },
    { lat: 49.2020772, lng: 18.8419691 },
    { lat: 49.2022359, lng: 18.8417662 },
    { lat: 49.2022446, lng: 18.8417601 },
    { lat: 49.2022749, lng: 18.8417565 },
    { lat: 49.2024464, lng: 18.8417381 },
    { lat: 49.2024591, lng: 18.8417406 },
    { lat: 49.2026525, lng: 18.8417769 },
    { lat: 49.2028234, lng: 18.8418095 },
    { lat: 49.2028332, lng: 18.8417802 },
    { lat: 49.2028582, lng: 18.841696 },
    { lat: 49.2029095, lng: 18.8417296 },
    { lat: 49.2031304, lng: 18.8418621 },
    { lat: 49.2032125, lng: 18.8419013 },
    { lat: 49.2032006, lng: 18.8422395 },
    { lat: 49.2031913, lng: 18.8423658 },
    { lat: 49.2031117, lng: 18.8426807 },
    { lat: 49.2029373, lng: 18.8434555 },
    { lat: 49.2028624, lng: 18.8438831 },
    { lat: 49.2028091, lng: 18.8440568 },
    { lat: 49.2027956, lng: 18.844102 },
    { lat: 49.2028753, lng: 18.8440298 },
    { lat: 49.2029633, lng: 18.8440088 },
    { lat: 49.2030214, lng: 18.8440404 },
    { lat: 49.203595, lng: 18.8444074 },
    { lat: 49.2039727, lng: 18.8446716 },
    { lat: 49.2044066, lng: 18.8450188 },
    { lat: 49.2048904, lng: 18.8454358 },
    { lat: 49.2052143, lng: 18.845771 },
    { lat: 49.2055037, lng: 18.8460515 },
    { lat: 49.2058502, lng: 18.8463638 },
    { lat: 49.2066868, lng: 18.8470895 },
    { lat: 49.2070944, lng: 18.8474537 },
    { lat: 49.2073971, lng: 18.8477258 },
    { lat: 49.2080788, lng: 18.8484005 },
    { lat: 49.2085291, lng: 18.8488223 },
    { lat: 49.2089445, lng: 18.8492284 },
    { lat: 49.2088518, lng: 18.8495082 },
    { lat: 49.2087156, lng: 18.8498659 },
    { lat: 49.2088852, lng: 18.850031 },
    { lat: 49.2089072, lng: 18.8500619 },
    { lat: 49.2087354, lng: 18.8512404 },
    { lat: 49.2090773, lng: 18.8509597 },
    { lat: 49.2091935, lng: 18.8511799 },
    { lat: 49.2095609, lng: 18.8518817 },
    { lat: 49.209558, lng: 18.8518886 },
    { lat: 49.2096784, lng: 18.852127 },
    { lat: 49.2097258, lng: 18.8522176 },
    { lat: 49.2098245, lng: 18.8524056 },
    { lat: 49.2098957, lng: 18.8525402 },
    { lat: 49.2099522, lng: 18.8526514 },
    { lat: 49.2100449, lng: 18.8528325 },
    { lat: 49.2101464, lng: 18.8530754 },
    { lat: 49.2101699, lng: 18.8531371 },
    { lat: 49.2102776, lng: 18.8534578 },
    { lat: 49.2103224, lng: 18.8536043 },
    { lat: 49.2103818, lng: 18.8538413 },
    { lat: 49.2106085, lng: 18.8546783 },
    { lat: 49.2106276, lng: 18.8547424 },
    { lat: 49.2107998, lng: 18.8550867 },
    { lat: 49.2110623, lng: 18.8555469 },
    { lat: 49.2110694, lng: 18.8556175 },
    { lat: 49.2111247, lng: 18.8557966 },
    { lat: 49.2111638, lng: 18.8560757 },
    { lat: 49.2111813, lng: 18.8570062 },
    { lat: 49.2112192, lng: 18.8572852 },
    { lat: 49.2113501, lng: 18.8574004 },
    { lat: 49.211546, lng: 18.8575025 },
    { lat: 49.212124, lng: 18.8576715 },
    { lat: 49.2128062, lng: 18.8579597 },
    { lat: 49.213138, lng: 18.8581859 },
    { lat: 49.2139035, lng: 18.8587996 },
    { lat: 49.2145713, lng: 18.8594437 },
    { lat: 49.2148933, lng: 18.8598312 },
    { lat: 49.2150639, lng: 18.8601033 },
    { lat: 49.2152842, lng: 18.8604189 },
    { lat: 49.2153908, lng: 18.8605858 },
    { lat: 49.2156335, lng: 18.860568 },
    { lat: 49.2158982, lng: 18.8603211 },
    { lat: 49.2160981, lng: 18.8609035 },
    { lat: 49.2162752, lng: 18.8613664 },
    { lat: 49.21649, lng: 18.8619269 },
    { lat: 49.216573, lng: 18.8621849 },
    { lat: 49.216664, lng: 18.8624857 },
    { lat: 49.2167281, lng: 18.8624611 },
    { lat: 49.2167192, lng: 18.8618487 },
    { lat: 49.2166845, lng: 18.8615203 },
    { lat: 49.2166779, lng: 18.8614154 },
    { lat: 49.2166698, lng: 18.861275 },
    { lat: 49.2166652, lng: 18.8612081 },
    { lat: 49.2166864, lng: 18.8611374 },
    { lat: 49.2167085, lng: 18.8610626 },
    { lat: 49.2167526, lng: 18.8609138 },
    { lat: 49.216791, lng: 18.8607819 },
    { lat: 49.2168418, lng: 18.860606 },
    { lat: 49.21686, lng: 18.8605414 },
    { lat: 49.2169202, lng: 18.8603333 },
    { lat: 49.2169356, lng: 18.8602796 },
    { lat: 49.2170098, lng: 18.8600128 },
    { lat: 49.2170156, lng: 18.8599963 },
    { lat: 49.2170604, lng: 18.8598795 },
    { lat: 49.217136, lng: 18.8596797 },
    { lat: 49.2171459, lng: 18.8596521 },
    { lat: 49.2172986, lng: 18.8592476 },
    { lat: 49.2174228, lng: 18.8591639 },
    { lat: 49.2175406, lng: 18.8590601 },
    { lat: 49.2176698, lng: 18.8588326 },
    { lat: 49.2177256, lng: 18.8586686 },
    { lat: 49.2179558, lng: 18.8585586 },
    { lat: 49.2180574, lng: 18.8584772 },
    { lat: 49.2181817, lng: 18.8584822 },
    { lat: 49.2184241, lng: 18.8587905 },
    { lat: 49.2185743, lng: 18.8589453 },
    { lat: 49.2186018, lng: 18.8588688 },
    { lat: 49.2190367, lng: 18.8590845 },
    { lat: 49.2197765, lng: 18.8595069 },
    { lat: 49.2198742, lng: 18.8589038 },
    { lat: 49.2201949, lng: 18.859022 },
    { lat: 49.2206464, lng: 18.8591182 },
    { lat: 49.2209096, lng: 18.8591729 },
    { lat: 49.2211839, lng: 18.859336 },
    { lat: 49.2213015, lng: 18.8594501 },
    { lat: 49.2213868, lng: 18.8595979 },
    { lat: 49.2214525, lng: 18.859823 },
    { lat: 49.2215145, lng: 18.8598438 },
    { lat: 49.2215871, lng: 18.8600057 },
    { lat: 49.2218875, lng: 18.859972 },
    { lat: 49.2220895, lng: 18.8598015 },
    { lat: 49.2221856, lng: 18.8596295 },
    { lat: 49.2222796, lng: 18.8597957 },
    { lat: 49.2225422, lng: 18.8599213 },
    { lat: 49.2227502, lng: 18.8598027 },
    { lat: 49.2230331, lng: 18.8595785 },
    { lat: 49.223185, lng: 18.8596848 },
    { lat: 49.2233613, lng: 18.8598477 },
    { lat: 49.2234751, lng: 18.8596667 },
    { lat: 49.2236492, lng: 18.8595453 },
    { lat: 49.2238256, lng: 18.85971 },
    { lat: 49.2239772, lng: 18.8598286 },
    { lat: 49.2241205, lng: 18.8600752 },
    { lat: 49.2243893, lng: 18.8599948 },
    { lat: 49.2244459, lng: 18.8600861 },
    { lat: 49.2245588, lng: 18.8600243 },
    { lat: 49.224642, lng: 18.8597121 },
    { lat: 49.2248803, lng: 18.8595856 },
    { lat: 49.2252145, lng: 18.8596153 },
    { lat: 49.225399, lng: 18.859256 },
    { lat: 49.2254653, lng: 18.8591893 },
    { lat: 49.2255779, lng: 18.8590763 },
    { lat: 49.2258553, lng: 18.8589436 },
    { lat: 49.2259053, lng: 18.8588398 },
    { lat: 49.2260201, lng: 18.8587486 },
    { lat: 49.2260832, lng: 18.8587944 },
    { lat: 49.2262732, lng: 18.8590683 },
    { lat: 49.2264278, lng: 18.8592493 },
    { lat: 49.2264764, lng: 18.8590949 },
    { lat: 49.226558, lng: 18.8590368 },
    { lat: 49.2265779, lng: 18.8593374 },
    { lat: 49.2266868, lng: 18.8593933 },
    { lat: 49.2268645, lng: 18.8593844 },
    { lat: 49.2270183, lng: 18.8594396 },
    { lat: 49.227209, lng: 18.859708 },
    { lat: 49.2273952, lng: 18.8595977 },
    { lat: 49.227611, lng: 18.8597613 },
    { lat: 49.2277986, lng: 18.8597469 },
    { lat: 49.2279058, lng: 18.8599491 },
    { lat: 49.2282745, lng: 18.8601908 },
    { lat: 49.228848, lng: 18.8606046 },
    { lat: 49.2288851, lng: 18.8607979 },
    { lat: 49.2293172, lng: 18.8613326 },
    { lat: 49.2293586, lng: 18.8616335 },
    { lat: 49.2295914, lng: 18.8617973 },
    { lat: 49.2297206, lng: 18.8620561 },
    { lat: 49.2300237, lng: 18.862183 },
    { lat: 49.2300621, lng: 18.8624901 },
    { lat: 49.2301304, lng: 18.8627922 },
    { lat: 49.2301244, lng: 18.8628506 },
    { lat: 49.2301259, lng: 18.8630121 },
    { lat: 49.2301555, lng: 18.8631932 },
    { lat: 49.2299651, lng: 18.8634169 },
    { lat: 49.2298114, lng: 18.8638707 },
    { lat: 49.2296536, lng: 18.8642033 },
    { lat: 49.2295508, lng: 18.8644198 },
    { lat: 49.2292351, lng: 18.8646173 },
    { lat: 49.2291556, lng: 18.8660615 },
    { lat: 49.2290948, lng: 18.8667011 },
    { lat: 49.2290556, lng: 18.8674781 },
    { lat: 49.228743, lng: 18.8682661 },
    { lat: 49.2288442, lng: 18.8682818 },
    { lat: 49.2290317, lng: 18.8682905 },
    { lat: 49.2291574, lng: 18.8683448 },
    { lat: 49.2293819, lng: 18.8686557 },
    { lat: 49.2296336, lng: 18.8689958 },
    { lat: 49.2299455, lng: 18.8694024 },
    { lat: 49.2300921, lng: 18.8695577 },
    { lat: 49.2306091, lng: 18.870012 },
    { lat: 49.2307877, lng: 18.8700664 },
    { lat: 49.2309908, lng: 18.8701357 },
    { lat: 49.2314293, lng: 18.8704211 },
    { lat: 49.2317292, lng: 18.8706173 },
    { lat: 49.2321647, lng: 18.8711318 },
    { lat: 49.2325891, lng: 18.8715589 },
    { lat: 49.2331737, lng: 18.8719669 },
    { lat: 49.2335146, lng: 18.8722622 },
    { lat: 49.2336988, lng: 18.8724275 },
    { lat: 49.2349084, lng: 18.8732416 },
    { lat: 49.235121, lng: 18.8733617 },
    { lat: 49.2351648, lng: 18.8734272 },
    { lat: 49.2350125, lng: 18.8740205 },
    { lat: 49.2349602, lng: 18.8744961 },
    { lat: 49.2349191, lng: 18.8748838 },
    { lat: 49.2345419, lng: 18.8755813 },
    { lat: 49.2346198, lng: 18.8756255 },
    { lat: 49.2353887, lng: 18.8760203 },
    { lat: 49.2359384, lng: 18.8764769 },
    { lat: 49.2360581, lng: 18.8766326 },
    { lat: 49.2365044, lng: 18.8768977 },
    { lat: 49.2365917, lng: 18.8770153 },
    { lat: 49.2369237, lng: 18.8772258 },
    { lat: 49.2372, lng: 18.8774444 },
    { lat: 49.2373261, lng: 18.8773966 },
    { lat: 49.2375911, lng: 18.8777749 },
    { lat: 49.2379195, lng: 18.8781785 },
    { lat: 49.2379485, lng: 18.8782188 },
    { lat: 49.238029, lng: 18.8783376 },
    { lat: 49.2381509, lng: 18.8784721 },
    { lat: 49.2381906, lng: 18.8784999 },
    { lat: 49.2383797, lng: 18.8788898 },
    { lat: 49.2384116, lng: 18.8789188 },
    { lat: 49.2389442, lng: 18.8793927 },
    { lat: 49.2391354, lng: 18.8795142 },
    { lat: 49.2394706, lng: 18.8797204 },
    { lat: 49.2395417, lng: 18.8797675 },
    { lat: 49.2395135, lng: 18.8802753 },
    { lat: 49.239399, lng: 18.8807908 },
    { lat: 49.2394749, lng: 18.8808435 },
    { lat: 49.2397456, lng: 18.881022 },
    { lat: 49.2399293, lng: 18.8807562 },
    { lat: 49.2400496, lng: 18.8808603 },
    { lat: 49.2401383, lng: 18.8809634 },
    { lat: 49.2402332, lng: 18.8810773 },
    { lat: 49.2401523, lng: 18.8813023 },
    { lat: 49.2404255, lng: 18.8814196 },
    { lat: 49.2402487, lng: 18.8825952 },
    { lat: 49.2409466, lng: 18.8825999 },
    { lat: 49.2409635, lng: 18.8825983 },
    { lat: 49.2411124, lng: 18.8825997 },
    { lat: 49.2414531, lng: 18.882557 },
    { lat: 49.2419589, lng: 18.8821955 },
    { lat: 49.2420654, lng: 18.8814543 },
    { lat: 49.2420727, lng: 18.8802944 },
    { lat: 49.2420999, lng: 18.880232 },
    { lat: 49.2421341, lng: 18.8801504 },
    { lat: 49.2421988, lng: 18.8800023 },
    { lat: 49.2425645, lng: 18.8796827 },
    { lat: 49.2428281, lng: 18.8799877 },
    { lat: 49.2431569, lng: 18.8805324 },
    { lat: 49.2432257, lng: 18.8804639 },
    { lat: 49.2434717, lng: 18.8804931 },
    { lat: 49.2435576, lng: 18.8804075 },
    { lat: 49.2436462, lng: 18.8801534 },
    { lat: 49.2436274, lng: 18.8798063 },
    { lat: 49.2438896, lng: 18.8797747 },
    { lat: 49.2440465, lng: 18.8797557 },
    { lat: 49.2445895, lng: 18.8799054 },
    { lat: 49.2450684, lng: 18.8801433 },
    { lat: 49.245055, lng: 18.8804076 },
    { lat: 49.2453068, lng: 18.8807269 },
    { lat: 49.2455874, lng: 18.8809887 },
    { lat: 49.2456078, lng: 18.8810552 },
    { lat: 49.2457301, lng: 18.881443 },
    { lat: 49.2460346, lng: 18.8817106 },
    { lat: 49.2463166, lng: 18.88189 },
    { lat: 49.2465903, lng: 18.8823684 },
    { lat: 49.2476641, lng: 18.8830873 },
    { lat: 49.2481053, lng: 18.8832055 },
    { lat: 49.2483955, lng: 18.8833926 },
    { lat: 49.248859, lng: 18.883701 },
    { lat: 49.248881, lng: 18.883422 },
    { lat: 49.248997, lng: 18.882881 },
    { lat: 49.24919, lng: 18.88234 },
    { lat: 49.249806, lng: 18.882246 },
    { lat: 49.250402, lng: 18.881491 },
    { lat: 49.251015, lng: 18.881454 },
    { lat: 49.251877, lng: 18.880428 },
    { lat: 49.252761, lng: 18.880014 },
    { lat: 49.25245, lng: 18.879483 },
    { lat: 49.251875, lng: 18.878532 },
    { lat: 49.251785, lng: 18.878457 },
    { lat: 49.25111, lng: 18.87836 },
    { lat: 49.250996, lng: 18.87819 },
    { lat: 49.250958, lng: 18.877137 },
    { lat: 49.250775, lng: 18.877071 },
    { lat: 49.250416, lng: 18.876941 },
    { lat: 49.249547, lng: 18.876432 },
  ],
  KotrčináLúčka: [
    { lat: 49.262762, lng: 18.850194 },
    { lat: 49.262127, lng: 18.849682 },
    { lat: 49.262288, lng: 18.848879 },
    { lat: 49.262405, lng: 18.848397 },
    { lat: 49.262429, lng: 18.847988 },
    { lat: 49.262411, lng: 18.847664 },
    { lat: 49.262357, lng: 18.847263 },
    { lat: 49.262294, lng: 18.847011 },
    { lat: 49.262131, lng: 18.846521 },
    { lat: 49.261802, lng: 18.845555 },
    { lat: 49.261547, lng: 18.844418 },
    { lat: 49.26118, lng: 18.84386 },
    { lat: 49.26196, lng: 18.843167 },
    { lat: 49.26183, lng: 18.842913 },
    { lat: 49.261277, lng: 18.841847 },
    { lat: 49.260905, lng: 18.841066 },
    { lat: 49.261044, lng: 18.84064 },
    { lat: 49.261145, lng: 18.840119 },
    { lat: 49.261161, lng: 18.839677 },
    { lat: 49.2611287, lng: 18.8395043 },
    { lat: 49.260997, lng: 18.8388 },
    { lat: 49.260752, lng: 18.837897 },
    { lat: 49.26098, lng: 18.838019 },
    { lat: 49.260929, lng: 18.837639 },
    { lat: 49.260823, lng: 18.83684 },
    { lat: 49.261299, lng: 18.835428 },
    { lat: 49.261778, lng: 18.834552 },
    { lat: 49.26224, lng: 18.833708 },
    { lat: 49.262509, lng: 18.8328 },
    { lat: 49.262652, lng: 18.832312 },
    { lat: 49.262944, lng: 18.831309 },
    { lat: 49.263239, lng: 18.830535 },
    { lat: 49.263323, lng: 18.830042 },
    { lat: 49.26326, lng: 18.829182 },
    { lat: 49.263251, lng: 18.828635 },
    { lat: 49.263194, lng: 18.8286481 },
    { lat: 49.2627267, lng: 18.8286822 },
    { lat: 49.2621514, lng: 18.8287239 },
    { lat: 49.2617725, lng: 18.828808 },
    { lat: 49.2614507, lng: 18.8288217 },
    { lat: 49.2614147, lng: 18.8288239 },
    { lat: 49.2609058, lng: 18.8288558 },
    { lat: 49.2604753, lng: 18.8290573 },
    { lat: 49.2606269, lng: 18.8297264 },
    { lat: 49.2606494, lng: 18.8301186 },
    { lat: 49.260547, lng: 18.8303876 },
    { lat: 49.2604257, lng: 18.8311436 },
    { lat: 49.2602711, lng: 18.8316892 },
    { lat: 49.2601947, lng: 18.8323074 },
    { lat: 49.2600147, lng: 18.8330629 },
    { lat: 49.2595014, lng: 18.8326371 },
    { lat: 49.2593142, lng: 18.8333606 },
    { lat: 49.2591404, lng: 18.8337544 },
    { lat: 49.2589229, lng: 18.834501 },
    { lat: 49.2591191, lng: 18.8352694 },
    { lat: 49.2581498, lng: 18.835603 },
    { lat: 49.2573361, lng: 18.8356533 },
    { lat: 49.2564771, lng: 18.8357 },
    { lat: 49.2564811, lng: 18.8345275 },
    { lat: 49.2561579, lng: 18.8333094 },
    { lat: 49.255956, lng: 18.8324529 },
    { lat: 49.2556585, lng: 18.8314913 },
    { lat: 49.2553321, lng: 18.8300567 },
    { lat: 49.2549221, lng: 18.8302792 },
    { lat: 49.2546139, lng: 18.8305584 },
    { lat: 49.2540958, lng: 18.8298694 },
    { lat: 49.2539263, lng: 18.8290051 },
    { lat: 49.2536566, lng: 18.8291533 },
    { lat: 49.2536009, lng: 18.8285753 },
    { lat: 49.2530998, lng: 18.8292462 },
    { lat: 49.252395, lng: 18.8288064 },
    { lat: 49.2521073, lng: 18.8292625 },
    { lat: 49.2519721, lng: 18.8294862 },
    { lat: 49.2515672, lng: 18.8290007 },
    { lat: 49.2514736, lng: 18.8287795 },
    { lat: 49.2513835, lng: 18.8287185 },
    { lat: 49.2513346, lng: 18.8288366 },
    { lat: 49.2510637, lng: 18.8293802 },
    { lat: 49.2509662, lng: 18.829524 },
    { lat: 49.2507242, lng: 18.8295552 },
    { lat: 49.2503929, lng: 18.8295127 },
    { lat: 49.2501949, lng: 18.8298938 },
    { lat: 49.2498691, lng: 18.8295001 },
    { lat: 49.2493273, lng: 18.8301055 },
    { lat: 49.2491069, lng: 18.8298522 },
    { lat: 49.2486525, lng: 18.8296772 },
    { lat: 49.2484936, lng: 18.8296559 },
    { lat: 49.2482251, lng: 18.8296311 },
    { lat: 49.247911, lng: 18.8293541 },
    { lat: 49.2478424, lng: 18.829293 },
    { lat: 49.2473906, lng: 18.8290578 },
    { lat: 49.2471183, lng: 18.828586 },
    { lat: 49.2467078, lng: 18.8278406 },
    { lat: 49.2459963, lng: 18.8269862 },
    { lat: 49.2459895, lng: 18.8272714 },
    { lat: 49.2457648, lng: 18.8281389 },
    { lat: 49.2456273, lng: 18.8280508 },
    { lat: 49.2454163, lng: 18.8289931 },
    { lat: 49.2454035, lng: 18.8290502 },
    { lat: 49.2451619, lng: 18.830072 },
    { lat: 49.2451108, lng: 18.8303928 },
    { lat: 49.2449967, lng: 18.8309388 },
    { lat: 49.2449086, lng: 18.8315929 },
    { lat: 49.2448001, lng: 18.8320277 },
    { lat: 49.2446132, lng: 18.8325789 },
    { lat: 49.24442, lng: 18.8336464 },
    { lat: 49.2429621, lng: 18.8329859 },
    { lat: 49.2428476, lng: 18.8318582 },
    { lat: 49.2427867, lng: 18.8307385 },
    { lat: 49.2423643, lng: 18.8308365 },
    { lat: 49.2424412, lng: 18.8318258 },
    { lat: 49.2423073, lng: 18.8318548 },
    { lat: 49.2408391, lng: 18.8322659 },
    { lat: 49.2399776, lng: 18.8326381 },
    { lat: 49.2394621, lng: 18.8327201 },
    { lat: 49.239238, lng: 18.8329444 },
    { lat: 49.2381983, lng: 18.8321515 },
    { lat: 49.2375702, lng: 18.8316026 },
    { lat: 49.2366924, lng: 18.830431 },
    { lat: 49.2362911, lng: 18.82962 },
    { lat: 49.2355896, lng: 18.8300784 },
    { lat: 49.23463, lng: 18.8307337 },
    { lat: 49.2342024, lng: 18.8308351 },
    { lat: 49.2340985, lng: 18.8305932 },
    { lat: 49.2334235, lng: 18.8306204 },
    { lat: 49.2328088, lng: 18.8306805 },
    { lat: 49.2323887, lng: 18.8307774 },
    { lat: 49.2321478, lng: 18.8307656 },
    { lat: 49.2322411, lng: 18.8313536 },
    { lat: 49.2323171, lng: 18.8321725 },
    { lat: 49.2326177, lng: 18.8322075 },
    { lat: 49.2333962, lng: 18.8326972 },
    { lat: 49.2337837, lng: 18.8330206 },
    { lat: 49.2344309, lng: 18.8335906 },
    { lat: 49.2349621, lng: 18.8341619 },
    { lat: 49.2351992, lng: 18.8345231 },
    { lat: 49.2352453, lng: 18.8345825 },
    { lat: 49.2355207, lng: 18.8349403 },
    { lat: 49.2357368, lng: 18.8352192 },
    { lat: 49.2360097, lng: 18.8355033 },
    { lat: 49.2363566, lng: 18.8358269 },
    { lat: 49.2367922, lng: 18.8361423 },
    { lat: 49.2374476, lng: 18.8365191 },
    { lat: 49.2380238, lng: 18.8371565 },
    { lat: 49.2384048, lng: 18.83755 },
    { lat: 49.2390885, lng: 18.8381757 },
    { lat: 49.2392893, lng: 18.8385705 },
    { lat: 49.2394748, lng: 18.8388471 },
    { lat: 49.2399981, lng: 18.8393289 },
    { lat: 49.2404166, lng: 18.8398817 },
    { lat: 49.2406313, lng: 18.8400472 },
    { lat: 49.2411404, lng: 18.8401013 },
    { lat: 49.2416282, lng: 18.8403157 },
    { lat: 49.2418165, lng: 18.8410596 },
    { lat: 49.2418547, lng: 18.8412102 },
    { lat: 49.2418773, lng: 18.8413006 },
    { lat: 49.2418243, lng: 18.8416945 },
    { lat: 49.2418192, lng: 18.8417297 },
    { lat: 49.2418133, lng: 18.8417672 },
    { lat: 49.2417976, lng: 18.8418986 },
    { lat: 49.2417934, lng: 18.8419266 },
    { lat: 49.2417884, lng: 18.8419618 },
    { lat: 49.2417861, lng: 18.8419827 },
    { lat: 49.2417819, lng: 18.842011 },
    { lat: 49.2417776, lng: 18.8420382 },
    { lat: 49.241787, lng: 18.8421107 },
    { lat: 49.2419251, lng: 18.8433088 },
    { lat: 49.2420755, lng: 18.8436888 },
    { lat: 49.242457, lng: 18.8445106 },
    { lat: 49.242565, lng: 18.8449448 },
    { lat: 49.242667, lng: 18.8453068 },
    { lat: 49.2428712, lng: 18.8459023 },
    { lat: 49.2426987, lng: 18.8465371 },
    { lat: 49.2424556, lng: 18.8469895 },
    { lat: 49.2423864, lng: 18.8472431 },
    { lat: 49.2424369, lng: 18.8477665 },
    { lat: 49.2429703, lng: 18.849285 },
    { lat: 49.2436101, lng: 18.8496447 },
    { lat: 49.243654, lng: 18.8499112 },
    { lat: 49.243674, lng: 18.8502549 },
    { lat: 49.2437204, lng: 18.850787 },
    { lat: 49.2437979, lng: 18.8516799 },
    { lat: 49.2440621, lng: 18.8530095 },
    { lat: 49.2442743, lng: 18.8540003 },
    { lat: 49.2451954, lng: 18.8553005 },
    { lat: 49.2460189, lng: 18.8553111 },
    { lat: 49.2460082, lng: 18.8556934 },
    { lat: 49.2467998, lng: 18.8557659 },
    { lat: 49.2479626, lng: 18.85575 },
    { lat: 49.2479918, lng: 18.8555264 },
    { lat: 49.2485209, lng: 18.8554451 },
    { lat: 49.2492239, lng: 18.8554982 },
    { lat: 49.2495588, lng: 18.8554385 },
    { lat: 49.2501928, lng: 18.8557718 },
    { lat: 49.250276, lng: 18.856035 },
    { lat: 49.250282, lng: 18.856036 },
    { lat: 49.25055, lng: 18.85619 },
    { lat: 49.250838, lng: 18.85635 },
    { lat: 49.251367, lng: 18.856912 },
    { lat: 49.251766, lng: 18.85741 },
    { lat: 49.252021, lng: 18.857324 },
    { lat: 49.252134, lng: 18.857345 },
    { lat: 49.252454, lng: 18.857388 },
    { lat: 49.2527, lng: 18.857348 },
    { lat: 49.252739, lng: 18.857383 },
    { lat: 49.25274, lng: 18.8573838 },
    { lat: 49.252849, lng: 18.857468 },
    { lat: 49.253051, lng: 18.857443 },
    { lat: 49.253276, lng: 18.857403 },
    { lat: 49.253288, lng: 18.857347 },
    { lat: 49.253323, lng: 18.857108 },
    { lat: 49.253463, lng: 18.857145 },
    { lat: 49.253464, lng: 18.857011 },
    { lat: 49.253466, lng: 18.856912 },
    { lat: 49.25347, lng: 18.856401 },
    { lat: 49.25372, lng: 18.856434 },
    { lat: 49.254343, lng: 18.8564 },
    { lat: 49.254381, lng: 18.856395 },
    { lat: 49.254641, lng: 18.856415 },
    { lat: 49.254909, lng: 18.856381 },
    { lat: 49.254984, lng: 18.856368 },
    { lat: 49.2551, lng: 18.856327 },
    { lat: 49.255391, lng: 18.856227 },
    { lat: 49.255417, lng: 18.856218 },
    { lat: 49.255466, lng: 18.8562 },
    { lat: 49.255406, lng: 18.856018 },
    { lat: 49.255709, lng: 18.855844 },
    { lat: 49.255775, lng: 18.855329 },
    { lat: 49.256017, lng: 18.855309 },
    { lat: 49.256266, lng: 18.855302 },
    { lat: 49.256093, lng: 18.854731 },
    { lat: 49.255933, lng: 18.854218 },
    { lat: 49.256109, lng: 18.854076 },
    { lat: 49.256366, lng: 18.853844 },
    { lat: 49.256313, lng: 18.853692 },
    { lat: 49.256436, lng: 18.853554 },
    { lat: 49.256413, lng: 18.853303 },
    { lat: 49.256563, lng: 18.853024 },
    { lat: 49.256627, lng: 18.852901 },
    { lat: 49.257061, lng: 18.853226 },
    { lat: 49.257289, lng: 18.853396 },
    { lat: 49.257417, lng: 18.853485 },
    { lat: 49.257633, lng: 18.85361 },
    { lat: 49.258012, lng: 18.853834 },
    { lat: 49.257976, lng: 18.854019 },
    { lat: 49.258286, lng: 18.854437 },
    { lat: 49.258418, lng: 18.854038 },
    { lat: 49.258624, lng: 18.853514 },
    { lat: 49.25877, lng: 18.853084 },
    { lat: 49.258929, lng: 18.852641 },
    { lat: 49.259114, lng: 18.852426 },
    { lat: 49.259249, lng: 18.852366 },
    { lat: 49.259274, lng: 18.852352 },
    { lat: 49.259824, lng: 18.852083 },
    { lat: 49.260083, lng: 18.851958 },
    { lat: 49.260112, lng: 18.852102 },
    { lat: 49.26036, lng: 18.852006 },
    { lat: 49.260693, lng: 18.851867 },
    { lat: 49.260874, lng: 18.851735 },
    { lat: 49.260946, lng: 18.851662 },
    { lat: 49.260982, lng: 18.851632 },
    { lat: 49.261152, lng: 18.851469 },
    { lat: 49.261493, lng: 18.851277 },
    { lat: 49.261864, lng: 18.851146 },
    { lat: 49.262202, lng: 18.850922 },
    { lat: 49.262513, lng: 18.850588 },
    { lat: 49.262762, lng: 18.850194 },
  ],
  Krasňany: [
    { lat: 49.182145, lng: 18.993521 },
    { lat: 49.1821749, lng: 18.9935834 },
    { lat: 49.1831856, lng: 18.9912286 },
    { lat: 49.1842163, lng: 18.9906493 },
    { lat: 49.185477, lng: 18.9898892 },
    { lat: 49.1868938, lng: 18.9886943 },
    { lat: 49.187749, lng: 18.9843017 },
    { lat: 49.1874861, lng: 18.9820953 },
    { lat: 49.1872001, lng: 18.9790704 },
    { lat: 49.1861944, lng: 18.975953 },
    { lat: 49.1864173, lng: 18.9704946 },
    { lat: 49.1866419, lng: 18.9703195 },
    { lat: 49.187045, lng: 18.9699083 },
    { lat: 49.1882615, lng: 18.9695723 },
    { lat: 49.1893113, lng: 18.9685242 },
    { lat: 49.190294, lng: 18.967734 },
    { lat: 49.1909973, lng: 18.9669448 },
    { lat: 49.1914701, lng: 18.9666177 },
    { lat: 49.1917619, lng: 18.9665442 },
    { lat: 49.1920553, lng: 18.9663934 },
    { lat: 49.1923649, lng: 18.9661203 },
    { lat: 49.1926324, lng: 18.9657217 },
    { lat: 49.1929052, lng: 18.9650924 },
    { lat: 49.1930198, lng: 18.9644356 },
    { lat: 49.1930654, lng: 18.9641545 },
    { lat: 49.1930996, lng: 18.9635147 },
    { lat: 49.1930922, lng: 18.9631663 },
    { lat: 49.1930874, lng: 18.9628698 },
    { lat: 49.1931737, lng: 18.9625468 },
    { lat: 49.193257, lng: 18.9619592 },
    { lat: 49.1932926, lng: 18.9615925 },
    { lat: 49.1933208, lng: 18.9613015 },
    { lat: 49.1934041, lng: 18.9607846 },
    { lat: 49.1934573, lng: 18.9606781 },
    { lat: 49.1936631, lng: 18.9602654 },
    { lat: 49.1940647, lng: 18.9588597 },
    { lat: 49.1941978, lng: 18.958457 },
    { lat: 49.1944113, lng: 18.9579972 },
    { lat: 49.1948881, lng: 18.9576133 },
    { lat: 49.1956403, lng: 18.9569017 },
    { lat: 49.1961246, lng: 18.9562923 },
    { lat: 49.1963599, lng: 18.9557917 },
    { lat: 49.1966636, lng: 18.9550387 },
    { lat: 49.1970268, lng: 18.9545066 },
    { lat: 49.1973059, lng: 18.9538963 },
    { lat: 49.1976016, lng: 18.9534311 },
    { lat: 49.1975916, lng: 18.9533202 },
    { lat: 49.1978776, lng: 18.9530253 },
    { lat: 49.1982408, lng: 18.9526044 },
    { lat: 49.1983469, lng: 18.952451 },
    { lat: 49.1984498, lng: 18.9523452 },
    { lat: 49.1987252, lng: 18.9521059 },
    { lat: 49.1990075, lng: 18.9517541 },
    { lat: 49.1990075, lng: 18.9509542 },
    { lat: 49.1988314, lng: 18.9496316 },
    { lat: 49.1986251, lng: 18.9492752 },
    { lat: 49.1987387, lng: 18.9488199 },
    { lat: 49.1987142, lng: 18.948583 },
    { lat: 49.1991167, lng: 18.9475097 },
    { lat: 49.1991686, lng: 18.9472177 },
    { lat: 49.1992242, lng: 18.9465547 },
    { lat: 49.1992393, lng: 18.9461185 },
    { lat: 49.1992256, lng: 18.9451089 },
    { lat: 49.1990296, lng: 18.9446896 },
    { lat: 49.1988526, lng: 18.9444444 },
    { lat: 49.1986497, lng: 18.9443335 },
    { lat: 49.1985425, lng: 18.9430162 },
    { lat: 49.199017, lng: 18.9422978 },
    { lat: 49.1989167, lng: 18.9420518 },
    { lat: 49.1990386, lng: 18.9414765 },
    { lat: 49.1991196, lng: 18.9406677 },
    { lat: 49.19929, lng: 18.9399123 },
    { lat: 49.1991555, lng: 18.9395048 },
    { lat: 49.1990768, lng: 18.9391522 },
    { lat: 49.1994056, lng: 18.9388341 },
    { lat: 49.1997904, lng: 18.938062 },
    { lat: 49.1999997, lng: 18.9375384 },
    { lat: 49.2001016, lng: 18.9369698 },
    { lat: 49.2002226, lng: 18.9367994 },
    { lat: 49.2003567, lng: 18.9356617 },
    { lat: 49.2005741, lng: 18.9352092 },
    { lat: 49.200732, lng: 18.9346329 },
    { lat: 49.2009154, lng: 18.9338448 },
    { lat: 49.2010576, lng: 18.933336 },
    { lat: 49.2009417, lng: 18.9326517 },
    { lat: 49.2007882, lng: 18.931621 },
    { lat: 49.2010462, lng: 18.9312859 },
    { lat: 49.2013601, lng: 18.9308686 },
    { lat: 49.2026113, lng: 18.9287504 },
    { lat: 49.2028347, lng: 18.9282175 },
    { lat: 49.2028971, lng: 18.9274049 },
    { lat: 49.2035985, lng: 18.9262468 },
    { lat: 49.2039153, lng: 18.9248686 },
    { lat: 49.2038732, lng: 18.9231238 },
    { lat: 49.2038338, lng: 18.9219701 },
    { lat: 49.2039671, lng: 18.9211748 },
    { lat: 49.2040503, lng: 18.9206815 },
    { lat: 49.2041242, lng: 18.9192922 },
    { lat: 49.2046453, lng: 18.9179633 },
    { lat: 49.2048469, lng: 18.917407 },
    { lat: 49.2054176, lng: 18.9157884 },
    { lat: 49.2055878, lng: 18.9155675 },
    { lat: 49.2060086, lng: 18.914837 },
    { lat: 49.2061958, lng: 18.9144806 },
    { lat: 49.206703, lng: 18.9134377 },
    { lat: 49.2071519, lng: 18.9124616 },
    { lat: 49.2070188, lng: 18.912283 },
    { lat: 49.2068615, lng: 18.9120283 },
    { lat: 49.2071598, lng: 18.9116865 },
    { lat: 49.2074933, lng: 18.9111502 },
    { lat: 49.207835, lng: 18.910597 },
    { lat: 49.2090765, lng: 18.9088019 },
    { lat: 49.2102877, lng: 18.907034 },
    { lat: 49.2109671, lng: 18.9060485 },
    { lat: 49.2114525, lng: 18.9055074 },
    { lat: 49.2115655, lng: 18.9054425 },
    { lat: 49.2116208, lng: 18.9054493 },
    { lat: 49.2116782, lng: 18.9055132 },
    { lat: 49.2117489, lng: 18.9055942 },
    { lat: 49.2118253, lng: 18.9055906 },
    { lat: 49.2119296, lng: 18.9054441 },
    { lat: 49.2120274, lng: 18.9053928 },
    { lat: 49.2120943, lng: 18.9053153 },
    { lat: 49.2122406, lng: 18.9053056 },
    { lat: 49.2123562, lng: 18.9053409 },
    { lat: 49.2124046, lng: 18.9053402 },
    { lat: 49.2125162, lng: 18.905318 },
    { lat: 49.2126117, lng: 18.9052223 },
    { lat: 49.212655, lng: 18.9052096 },
    { lat: 49.2126885, lng: 18.9052276 },
    { lat: 49.212808, lng: 18.905291 },
    { lat: 49.212933, lng: 18.9052455 },
    { lat: 49.2130405, lng: 18.9051203 },
    { lat: 49.2131713, lng: 18.9051412 },
    { lat: 49.2133244, lng: 18.9050254 },
    { lat: 49.213393, lng: 18.9049112 },
    { lat: 49.2134535, lng: 18.9048594 },
    { lat: 49.2135962, lng: 18.9048515 },
    { lat: 49.2136789, lng: 18.9048366 },
    { lat: 49.2138332, lng: 18.9047455 },
    { lat: 49.2139461, lng: 18.9045908 },
    { lat: 49.2139613, lng: 18.9044483 },
    { lat: 49.2139802, lng: 18.904397 },
    { lat: 49.2140003, lng: 18.9043741 },
    { lat: 49.2140243, lng: 18.9042245 },
    { lat: 49.2140774, lng: 18.9041569 },
    { lat: 49.21422, lng: 18.9040581 },
    { lat: 49.2142992, lng: 18.904087 },
    { lat: 49.2144227, lng: 18.9041657 },
    { lat: 49.2144561, lng: 18.9041997 },
    { lat: 49.214465, lng: 18.9042202 },
    { lat: 49.214483, lng: 18.9042643 },
    { lat: 49.2145021, lng: 18.9042638 },
    { lat: 49.2145749, lng: 18.9042772 },
    { lat: 49.2146465, lng: 18.9042211 },
    { lat: 49.2147166, lng: 18.9040682 },
    { lat: 49.2147602, lng: 18.9039483 },
    { lat: 49.2148318, lng: 18.9038753 },
    { lat: 49.2148991, lng: 18.9038468 },
    { lat: 49.2149696, lng: 18.9038568 },
    { lat: 49.2150341, lng: 18.903886 },
    { lat: 49.2150823, lng: 18.9039228 },
    { lat: 49.2151457, lng: 18.9039063 },
    { lat: 49.215221, lng: 18.9038126 },
    { lat: 49.2153196, lng: 18.9037995 },
    { lat: 49.2154266, lng: 18.9037991 },
    { lat: 49.2155105, lng: 18.9037397 },
    { lat: 49.2155364, lng: 18.9036948 },
    { lat: 49.2155759, lng: 18.903631 },
    { lat: 49.2157095, lng: 18.9035091 },
    { lat: 49.2157819, lng: 18.9034685 },
    { lat: 49.2158903, lng: 18.9034484 },
    { lat: 49.2160042, lng: 18.9034079 },
    { lat: 49.216188, lng: 18.9034272 },
    { lat: 49.21626, lng: 18.9034014 },
    { lat: 49.216309, lng: 18.9033014 },
    { lat: 49.2163397, lng: 18.9031301 },
    { lat: 49.2163577, lng: 18.903085 },
    { lat: 49.2164651, lng: 18.9029793 },
    { lat: 49.2165335, lng: 18.9029081 },
    { lat: 49.216583, lng: 18.9028373 },
    { lat: 49.2166331, lng: 18.902734 },
    { lat: 49.216729, lng: 18.9026512 },
    { lat: 49.2168726, lng: 18.902658 },
    { lat: 49.2169295, lng: 18.9026913 },
    { lat: 49.216964, lng: 18.902746 },
    { lat: 49.2171043, lng: 18.9027358 },
    { lat: 49.2171886, lng: 18.9026429 },
    { lat: 49.2172131, lng: 18.9025484 },
    { lat: 49.217233, lng: 18.9024512 },
    { lat: 49.2172454, lng: 18.9024072 },
    { lat: 49.2172394, lng: 18.9022434 },
    { lat: 49.2173044, lng: 18.9021466 },
    { lat: 49.2174295, lng: 18.9020564 },
    { lat: 49.2175004, lng: 18.9020805 },
    { lat: 49.2175726, lng: 18.9020773 },
    { lat: 49.217639, lng: 18.9021207 },
    { lat: 49.2177245, lng: 18.9021614 },
    { lat: 49.2178122, lng: 18.9021103 },
    { lat: 49.2178515, lng: 18.9020663 },
    { lat: 49.2178776, lng: 18.9020455 },
    { lat: 49.2179161, lng: 18.9019375 },
    { lat: 49.217948, lng: 18.9018139 },
    { lat: 49.218007, lng: 18.901663 },
    { lat: 49.2180558, lng: 18.9016046 },
    { lat: 49.2181092, lng: 18.901568 },
    { lat: 49.2182005, lng: 18.9015889 },
    { lat: 49.2182717, lng: 18.9017024 },
    { lat: 49.2183075, lng: 18.9017183 },
    { lat: 49.2184651, lng: 18.9017144 },
    { lat: 49.2187229, lng: 18.9015988 },
    { lat: 49.2188232, lng: 18.9015742 },
    { lat: 49.2188815, lng: 18.901634 },
    { lat: 49.2189865, lng: 18.901834 },
    { lat: 49.2189891, lng: 18.9018442 },
    { lat: 49.2190442, lng: 18.9018871 },
    { lat: 49.2190582, lng: 18.9019157 },
    { lat: 49.2190934, lng: 18.9019667 },
    { lat: 49.2191574, lng: 18.9020046 },
    { lat: 49.2192041, lng: 18.9020171 },
    { lat: 49.2192589, lng: 18.9020723 },
    { lat: 49.2193111, lng: 18.9020571 },
    { lat: 49.2193529, lng: 18.9020164 },
    { lat: 49.2194001, lng: 18.901921 },
    { lat: 49.2194499, lng: 18.901773 },
    { lat: 49.219476, lng: 18.9017515 },
    { lat: 49.2195207, lng: 18.9017668 },
    { lat: 49.2196395, lng: 18.9018389 },
    { lat: 49.2197019, lng: 18.9018737 },
    { lat: 49.2197567, lng: 18.9018859 },
    { lat: 49.2198596, lng: 18.9019131 },
    { lat: 49.2199418, lng: 18.901912 },
    { lat: 49.2199895, lng: 18.901893 },
    { lat: 49.2200569, lng: 18.9018482 },
    { lat: 49.2200802, lng: 18.9018177 },
    { lat: 49.2200966, lng: 18.9017403 },
    { lat: 49.220196, lng: 18.9015677 },
    { lat: 49.2203054, lng: 18.9014108 },
    { lat: 49.2203376, lng: 18.9013785 },
    { lat: 49.2203721, lng: 18.9013694 },
    { lat: 49.2204422, lng: 18.9013553 },
    { lat: 49.2204905, lng: 18.9013712 },
    { lat: 49.220564, lng: 18.9013993 },
    { lat: 49.2206606, lng: 18.9014127 },
    { lat: 49.2207236, lng: 18.9014334 },
    { lat: 49.220762, lng: 18.9014559 },
    { lat: 49.220835, lng: 18.9014509 },
    { lat: 49.2208634, lng: 18.9014327 },
    { lat: 49.2208849, lng: 18.90141 },
    { lat: 49.2209602, lng: 18.9012506 },
    { lat: 49.2209634, lng: 18.9012023 },
    { lat: 49.2209598, lng: 18.9010891 },
    { lat: 49.2209564, lng: 18.9010228 },
    { lat: 49.2209734, lng: 18.90098 },
    { lat: 49.2209837, lng: 18.9009597 },
    { lat: 49.2210162, lng: 18.9009342 },
    { lat: 49.221032, lng: 18.9009352 },
    { lat: 49.2210735, lng: 18.9009512 },
    { lat: 49.2211284, lng: 18.9009908 },
    { lat: 49.2211978, lng: 18.901045 },
    { lat: 49.2212944, lng: 18.9011043 },
    { lat: 49.2213565, lng: 18.9011092 },
    { lat: 49.2214256, lng: 18.9010919 },
    { lat: 49.221555, lng: 18.901134 },
    { lat: 49.2215947, lng: 18.9010488 },
    { lat: 49.2216048, lng: 18.9008904 },
    { lat: 49.22162, lng: 18.9008125 },
    { lat: 49.2216485, lng: 18.9007983 },
    { lat: 49.22176, lng: 18.9007746 },
    { lat: 49.2218427, lng: 18.9007366 },
    { lat: 49.2219191, lng: 18.9006655 },
    { lat: 49.2219952, lng: 18.9005667 },
    { lat: 49.2220498, lng: 18.9004849 },
    { lat: 49.2221491, lng: 18.9003981 },
    { lat: 49.2222861, lng: 18.9002765 },
    { lat: 49.2223213, lng: 18.9002622 },
    { lat: 49.2223553, lng: 18.9002427 },
    { lat: 49.222351, lng: 18.900223 },
    { lat: 49.222344, lng: 18.9001982 },
    { lat: 49.2223792, lng: 18.9001786 },
    { lat: 49.2223606, lng: 18.9001021 },
    { lat: 49.2222768, lng: 18.8997406 },
    { lat: 49.2223362, lng: 18.8997108 },
    { lat: 49.2223715, lng: 18.8996933 },
    { lat: 49.2224933, lng: 18.8996319 },
    { lat: 49.222521, lng: 18.8996178 },
    { lat: 49.2225781, lng: 18.899589 },
    { lat: 49.223342, lng: 18.8992052 },
    { lat: 49.2232198, lng: 18.8986837 },
    { lat: 49.2242463, lng: 18.897987 },
    { lat: 49.2242686, lng: 18.897984 },
    { lat: 49.2242833, lng: 18.8979819 },
    { lat: 49.2243627, lng: 18.8979711 },
    { lat: 49.2243065, lng: 18.8973977 },
    { lat: 49.2242474, lng: 18.8965463 },
    { lat: 49.2241819, lng: 18.8959697 },
    { lat: 49.2239576, lng: 18.8947522 },
    { lat: 49.2238081, lng: 18.8938073 },
    { lat: 49.2237728, lng: 18.8931835 },
    { lat: 49.2236798, lng: 18.8921074 },
    { lat: 49.223533, lng: 18.8915957 },
    { lat: 49.2234759, lng: 18.8914261 },
    { lat: 49.2233454, lng: 18.8911227 },
    { lat: 49.2232911, lng: 18.8910087 },
    { lat: 49.2229475, lng: 18.8903483 },
    { lat: 49.2228925, lng: 18.8902426 },
    { lat: 49.222633, lng: 18.8897072 },
    { lat: 49.2225607, lng: 18.8895487 },
    { lat: 49.2224656, lng: 18.8893202 },
    { lat: 49.2225294, lng: 18.8892682 },
    { lat: 49.2225954, lng: 18.8892153 },
    { lat: 49.2222151, lng: 18.8886733 },
    { lat: 49.2218861, lng: 18.8879473 },
    { lat: 49.2217894, lng: 18.887821 },
    { lat: 49.221735, lng: 18.8876154 },
    { lat: 49.2217235, lng: 18.8875905 },
    { lat: 49.2217014, lng: 18.8875314 },
    { lat: 49.2216127, lng: 18.8873407 },
    { lat: 49.2214833, lng: 18.8871238 },
    { lat: 49.2213862, lng: 18.8869658 },
    { lat: 49.2212818, lng: 18.8867347 },
    { lat: 49.2211273, lng: 18.8863127 },
    { lat: 49.2209788, lng: 18.8859624 },
    { lat: 49.220034, lng: 18.8839514 },
    { lat: 49.2200217, lng: 18.8839334 },
    { lat: 49.2194657, lng: 18.8827133 },
    { lat: 49.2190428, lng: 18.8825335 },
    { lat: 49.2180559, lng: 18.8816882 },
    { lat: 49.2173812, lng: 18.8807503 },
    { lat: 49.217257, lng: 18.8806583 },
    { lat: 49.2172146, lng: 18.8806241 },
    { lat: 49.2163277, lng: 18.8800367 },
    { lat: 49.2152109, lng: 18.8796481 },
    { lat: 49.2143139, lng: 18.8795665 },
    { lat: 49.2134183, lng: 18.8794938 },
    { lat: 49.2125252, lng: 18.8794032 },
    { lat: 49.2116276, lng: 18.8793329 },
    { lat: 49.2112396, lng: 18.8793318 },
    { lat: 49.2107375, lng: 18.8793215 },
    { lat: 49.2107017, lng: 18.8793705 },
    { lat: 49.2107605, lng: 18.8797072 },
    { lat: 49.2106786, lng: 18.8797144 },
    { lat: 49.2093553, lng: 18.8798105 },
    { lat: 49.2060613, lng: 18.8800169 },
    { lat: 49.205911, lng: 18.8800304 },
    { lat: 49.2052087, lng: 18.8800776 },
    { lat: 49.2051019, lng: 18.8800827 },
    { lat: 49.2050529, lng: 18.8800525 },
    { lat: 49.2049391, lng: 18.8800552 },
    { lat: 49.2046545, lng: 18.8801543 },
    { lat: 49.2038275, lng: 18.8800543 },
    { lat: 49.203947, lng: 18.8803397 },
    { lat: 49.2039653, lng: 18.880458 },
    { lat: 49.2041505, lng: 18.8806606 },
    { lat: 49.2042502, lng: 18.8810681 },
    { lat: 49.2046436, lng: 18.8814208 },
    { lat: 49.2047102, lng: 18.8815539 },
    { lat: 49.2047191, lng: 18.8816823 },
    { lat: 49.2047419, lng: 18.8818007 },
    { lat: 49.2049663, lng: 18.8821097 },
    { lat: 49.2049494, lng: 18.8823529 },
    { lat: 49.2050811, lng: 18.8826366 },
    { lat: 49.2052079, lng: 18.8828205 },
    { lat: 49.2051855, lng: 18.8828924 },
    { lat: 49.2054191, lng: 18.8836921 },
    { lat: 49.2053721, lng: 18.8841171 },
    { lat: 49.2054032, lng: 18.8842414 },
    { lat: 49.2055721, lng: 18.8843472 },
    { lat: 49.2056212, lng: 18.8844711 },
    { lat: 49.2053612, lng: 18.8849217 },
    { lat: 49.2051072, lng: 18.8853341 },
    { lat: 49.2042103, lng: 18.8858326 },
    { lat: 49.203736, lng: 18.8861738 },
    { lat: 49.2036876, lng: 18.8863267 },
    { lat: 49.2036654, lng: 18.8864003 },
    { lat: 49.2032546, lng: 18.8877648 },
    { lat: 49.2029207, lng: 18.8888421 },
    { lat: 49.2022345, lng: 18.8910414 },
    { lat: 49.2018107, lng: 18.8924795 },
    { lat: 49.2011429, lng: 18.8937153 },
    { lat: 49.2006144, lng: 18.8946292 },
    { lat: 49.2004943, lng: 18.8953224 },
    { lat: 49.2004716, lng: 18.8954523 },
    { lat: 49.200327, lng: 18.8963333 },
    { lat: 49.1995044, lng: 18.8982468 },
    { lat: 49.1991302, lng: 18.8997076 },
    { lat: 49.1986466, lng: 18.9007066 },
    { lat: 49.1982905, lng: 18.9013563 },
    { lat: 49.198217, lng: 18.9014609 },
    { lat: 49.1979569, lng: 18.9017318 },
    { lat: 49.1978223, lng: 18.9018818 },
    { lat: 49.1976094, lng: 18.9021724 },
    { lat: 49.1971939, lng: 18.9030245 },
    { lat: 49.1969941, lng: 18.9033275 },
    { lat: 49.1967997, lng: 18.9035654 },
    { lat: 49.1965501, lng: 18.9038001 },
    { lat: 49.1959336, lng: 18.9041139 },
    { lat: 49.195784, lng: 18.9042102 },
    { lat: 49.1956051, lng: 18.9043954 },
    { lat: 49.1954472, lng: 18.9046347 },
    { lat: 49.1953708, lng: 18.9047725 },
    { lat: 49.1953027, lng: 18.9048544 },
    { lat: 49.195197, lng: 18.9049034 },
    { lat: 49.1949399, lng: 18.905038 },
    { lat: 49.1948277, lng: 18.9052232 },
    { lat: 49.1946826, lng: 18.9058493 },
    { lat: 49.1945811, lng: 18.9058761 },
    { lat: 49.1938974, lng: 18.9065958 },
    { lat: 49.1933932, lng: 18.9063256 },
    { lat: 49.1931918, lng: 18.9060913 },
    { lat: 49.1929892, lng: 18.9060742 },
    { lat: 49.1926603, lng: 18.9058129 },
    { lat: 49.1924801, lng: 18.9057081 },
    { lat: 49.1924346, lng: 18.9056997 },
    { lat: 49.1922371, lng: 18.9056727 },
    { lat: 49.1918546, lng: 18.9056455 },
    { lat: 49.1912454, lng: 18.9058787 },
    { lat: 49.1908894, lng: 18.9062608 },
    { lat: 49.1907543, lng: 18.9065543 },
    { lat: 49.1905949, lng: 18.9068983 },
    { lat: 49.1902663, lng: 18.9073777 },
    { lat: 49.1899933, lng: 18.9085292 },
    { lat: 49.1897537, lng: 18.909224 },
    { lat: 49.1894918, lng: 18.9095282 },
    { lat: 49.1890597, lng: 18.9100122 },
    { lat: 49.188079, lng: 18.9109936 },
    { lat: 49.1878921, lng: 18.9111974 },
    { lat: 49.1872007, lng: 18.9121488 },
    { lat: 49.187365, lng: 18.9124942 },
    { lat: 49.1873435, lng: 18.9127836 },
    { lat: 49.187306, lng: 18.913044 },
    { lat: 49.18736, lng: 18.9132203 },
    { lat: 49.1873352, lng: 18.9132611 },
    { lat: 49.1878714, lng: 18.9149397 },
    { lat: 49.1881508, lng: 18.9158914 },
    { lat: 49.18849, lng: 18.9172213 },
    { lat: 49.188478, lng: 18.9172508 },
    { lat: 49.1883476, lng: 18.9175712 },
    { lat: 49.1880674, lng: 18.9184704 },
    { lat: 49.1875876, lng: 18.9195878 },
    { lat: 49.1844419, lng: 18.9260537 },
    { lat: 49.1841932, lng: 18.9265307 },
    { lat: 49.1836224, lng: 18.927193 },
    { lat: 49.1830612, lng: 18.9275951 },
    { lat: 49.1821637, lng: 18.9286638 },
    { lat: 49.1816226, lng: 18.9289972 },
    { lat: 49.181519, lng: 18.9293345 },
    { lat: 49.1814106, lng: 18.9302882 },
    { lat: 49.181383, lng: 18.9324155 },
    { lat: 49.1814952, lng: 18.9338939 },
    { lat: 49.1814928, lng: 18.934651 },
    { lat: 49.1813985, lng: 18.9350362 },
    { lat: 49.1811692, lng: 18.9351176 },
    { lat: 49.1807455, lng: 18.9351434 },
    { lat: 49.1797547, lng: 18.9362424 },
    { lat: 49.1790472, lng: 18.9369199 },
    { lat: 49.1780029, lng: 18.9372025 },
    { lat: 49.1770967, lng: 18.9377632 },
    { lat: 49.1769371, lng: 18.9380187 },
    { lat: 49.1767081, lng: 18.9383948 },
    { lat: 49.1764018, lng: 18.9391822 },
    { lat: 49.1762194, lng: 18.9402324 },
    { lat: 49.1764223, lng: 18.9415487 },
    { lat: 49.1765319, lng: 18.9445457 },
    { lat: 49.1763894, lng: 18.945806 },
    { lat: 49.1762336, lng: 18.9467626 },
    { lat: 49.1760883, lng: 18.9471411 },
    { lat: 49.1754525, lng: 18.9485959 },
    { lat: 49.1749517, lng: 18.9498874 },
    { lat: 49.1746905, lng: 18.9503613 },
    { lat: 49.1741698, lng: 18.9513436 },
    { lat: 49.1740531, lng: 18.9516709 },
    { lat: 49.1739023, lng: 18.9519005 },
    { lat: 49.1730905, lng: 18.9529038 },
    { lat: 49.171452, lng: 18.956384 },
    { lat: 49.171595, lng: 18.957989 },
    { lat: 49.172038, lng: 18.960317 },
    { lat: 49.1723282, lng: 18.9617079 },
    { lat: 49.17293, lng: 18.961982 },
    { lat: 49.173014, lng: 18.962006 },
    { lat: 49.173462, lng: 18.962131 },
    { lat: 49.173798, lng: 18.962431 },
    { lat: 49.174185, lng: 18.962774 },
    { lat: 49.175661, lng: 18.963951 },
    { lat: 49.176259, lng: 18.964453 },
    { lat: 49.176771, lng: 18.965211 },
    { lat: 49.176782, lng: 18.965218 },
    { lat: 49.17708, lng: 18.965388 },
    { lat: 49.178472, lng: 18.966641 },
    { lat: 49.178862, lng: 18.96702 },
    { lat: 49.179298, lng: 18.967406 },
    { lat: 49.17966, lng: 18.967517 },
    { lat: 49.18029, lng: 18.967714 },
    { lat: 49.180457, lng: 18.967959 },
    { lat: 49.180614, lng: 18.96819 },
    { lat: 49.18105, lng: 18.968869 },
    { lat: 49.18136, lng: 18.969861 },
    { lat: 49.181462, lng: 18.970378 },
    { lat: 49.181548, lng: 18.970813 },
    { lat: 49.18155, lng: 18.97082 },
    { lat: 49.181616, lng: 18.971026 },
    { lat: 49.181636, lng: 18.971092 },
    { lat: 49.181706, lng: 18.971315 },
    { lat: 49.182099, lng: 18.972333 },
    { lat: 49.182134, lng: 18.972424 },
    { lat: 49.182298, lng: 18.972857 },
    { lat: 49.182361, lng: 18.973022 },
    { lat: 49.182589, lng: 18.974163 },
    { lat: 49.182689, lng: 18.9747111 },
    { lat: 49.182709, lng: 18.974821 },
    { lat: 49.182781, lng: 18.975488 },
    { lat: 49.182821, lng: 18.975858 },
    { lat: 49.183053, lng: 18.976928 },
    { lat: 49.1830362, lng: 18.9772189 },
    { lat: 49.183019, lng: 18.977515 },
    { lat: 49.183003, lng: 18.977813 },
    { lat: 49.182981, lng: 18.978345 },
    { lat: 49.182972, lng: 18.978593 },
    { lat: 49.182931, lng: 18.979576 },
    { lat: 49.18291, lng: 18.980273 },
    { lat: 49.182967, lng: 18.980873 },
    { lat: 49.18301, lng: 18.981312 },
    { lat: 49.183073, lng: 18.981966 },
    { lat: 49.183079, lng: 18.982019 },
    { lat: 49.183091, lng: 18.982556 },
    { lat: 49.183138, lng: 18.983128 },
    { lat: 49.183007, lng: 18.984042 },
    { lat: 49.182992, lng: 18.984139 },
    { lat: 49.182916, lng: 18.985202 },
    { lat: 49.182841, lng: 18.985885 },
    { lat: 49.182864, lng: 18.986564 },
    { lat: 49.182863, lng: 18.986569 },
    { lat: 49.182825, lng: 18.986682 },
    { lat: 49.182528, lng: 18.987548 },
    { lat: 49.182611, lng: 18.987977 },
    { lat: 49.182631, lng: 18.988077 },
    { lat: 49.182631, lng: 18.988078 },
    { lat: 49.182553, lng: 18.988526 },
    { lat: 49.182634, lng: 18.989589 },
    { lat: 49.182663, lng: 18.990135 },
    { lat: 49.182617, lng: 18.99086 },
    { lat: 49.182479, lng: 18.991609 },
    { lat: 49.182305, lng: 18.992492 },
    { lat: 49.18216, lng: 18.993423 },
    { lat: 49.182145, lng: 18.993521 },
  ],
  Lysica: [
    { lat: 49.248859, lng: 18.883701 },
    { lat: 49.2483955, lng: 18.8833926 },
    { lat: 49.2481053, lng: 18.8832055 },
    { lat: 49.2476641, lng: 18.8830873 },
    { lat: 49.2465903, lng: 18.8823684 },
    { lat: 49.2463166, lng: 18.88189 },
    { lat: 49.2460346, lng: 18.8817106 },
    { lat: 49.2457301, lng: 18.881443 },
    { lat: 49.2456078, lng: 18.8810552 },
    { lat: 49.2455874, lng: 18.8809887 },
    { lat: 49.2453068, lng: 18.8807269 },
    { lat: 49.245055, lng: 18.8804076 },
    { lat: 49.2450684, lng: 18.8801433 },
    { lat: 49.2445895, lng: 18.8799054 },
    { lat: 49.2440465, lng: 18.8797557 },
    { lat: 49.2438896, lng: 18.8797747 },
    { lat: 49.2436274, lng: 18.8798063 },
    { lat: 49.2436462, lng: 18.8801534 },
    { lat: 49.2435576, lng: 18.8804075 },
    { lat: 49.2434717, lng: 18.8804931 },
    { lat: 49.2432257, lng: 18.8804639 },
    { lat: 49.2431569, lng: 18.8805324 },
    { lat: 49.2428281, lng: 18.8799877 },
    { lat: 49.2425645, lng: 18.8796827 },
    { lat: 49.2421988, lng: 18.8800023 },
    { lat: 49.2421341, lng: 18.8801504 },
    { lat: 49.2420999, lng: 18.880232 },
    { lat: 49.2420727, lng: 18.8802944 },
    { lat: 49.2420654, lng: 18.8814543 },
    { lat: 49.2419589, lng: 18.8821955 },
    { lat: 49.2414531, lng: 18.882557 },
    { lat: 49.2411124, lng: 18.8825997 },
    { lat: 49.240875, lng: 18.8830306 },
    { lat: 49.2405358, lng: 18.883829 },
    { lat: 49.240373, lng: 18.8844813 },
    { lat: 49.2403338, lng: 18.8853068 },
    { lat: 49.2403045, lng: 18.8865205 },
    { lat: 49.2403089, lng: 18.88747 },
    { lat: 49.2402787, lng: 18.8880971 },
    { lat: 49.2401574, lng: 18.8883717 },
    { lat: 49.2397703, lng: 18.8887835 },
    { lat: 49.239527, lng: 18.8892757 },
    { lat: 49.2394523, lng: 18.8894928 },
    { lat: 49.2392928, lng: 18.8898116 },
    { lat: 49.2391876, lng: 18.8900276 },
    { lat: 49.2389919, lng: 18.8902764 },
    { lat: 49.2388658, lng: 18.8904353 },
    { lat: 49.2387382, lng: 18.8906117 },
    { lat: 49.2383664, lng: 18.8911004 },
    { lat: 49.2376376, lng: 18.8911614 },
    { lat: 49.237396, lng: 18.8915945 },
    { lat: 49.2368147, lng: 18.8923115 },
    { lat: 49.2367017, lng: 18.8925086 },
    { lat: 49.2364752, lng: 18.8929103 },
    { lat: 49.2362997, lng: 18.8932032 },
    { lat: 49.2360483, lng: 18.8935776 },
    { lat: 49.2357541, lng: 18.8941328 },
    { lat: 49.2357216, lng: 18.8941172 },
    { lat: 49.2354621, lng: 18.8939817 },
    { lat: 49.2351018, lng: 18.8937661 },
    { lat: 49.2348491, lng: 18.8937225 },
    { lat: 49.2344911, lng: 18.8938191 },
    { lat: 49.2342297, lng: 18.893979 },
    { lat: 49.2337765, lng: 18.8940883 },
    { lat: 49.2333161, lng: 18.8940567 },
    { lat: 49.2332844, lng: 18.8940558 },
    { lat: 49.2331975, lng: 18.8944099 },
    { lat: 49.2332275, lng: 18.8948422 },
    { lat: 49.2334269, lng: 18.8948409 },
    { lat: 49.2338948, lng: 18.8947983 },
    { lat: 49.2341268, lng: 18.8947697 },
    { lat: 49.2341749, lng: 18.894764 },
    { lat: 49.2344222, lng: 18.8947246 },
    { lat: 49.2345631, lng: 18.894745 },
    { lat: 49.2346647, lng: 18.8948145 },
    { lat: 49.2340451, lng: 18.8965995 },
    { lat: 49.23423, lng: 18.8966216 },
    { lat: 49.2345307, lng: 18.8966574 },
    { lat: 49.2348461, lng: 18.896695 },
    { lat: 49.2350548, lng: 18.89672 },
    { lat: 49.2346727, lng: 18.8979198 },
    { lat: 49.2346455, lng: 18.8980031 },
    { lat: 49.2346339, lng: 18.8980442 },
    { lat: 49.2345992, lng: 18.8981609 },
    { lat: 49.2346855, lng: 18.8985244 },
    { lat: 49.2347429, lng: 18.8987658 },
    { lat: 49.2348361, lng: 18.8991585 },
    { lat: 49.2348953, lng: 18.8994119 },
    { lat: 49.2349175, lng: 18.8997603 },
    { lat: 49.2350581, lng: 18.9002675 },
    { lat: 49.2351655, lng: 18.9008273 },
    { lat: 49.23528, lng: 18.9014155 },
    { lat: 49.2352972, lng: 18.9015095 },
    { lat: 49.2353848, lng: 18.9017033 },
    { lat: 49.2353581, lng: 18.9019763 },
    { lat: 49.2353709, lng: 18.9022472 },
    { lat: 49.2355572, lng: 18.9025664 },
    { lat: 49.2356911, lng: 18.9028013 },
    { lat: 49.2357513, lng: 18.9029691 },
    { lat: 49.2360497, lng: 18.9044245 },
    { lat: 49.2361825, lng: 18.9052422 },
    { lat: 49.2363824, lng: 18.9056478 },
    { lat: 49.2364296, lng: 18.905801 },
    { lat: 49.2364924, lng: 18.9063246 },
    { lat: 49.2364817, lng: 18.9064258 },
    { lat: 49.2366139, lng: 18.9067212 },
    { lat: 49.2366096, lng: 18.9072369 },
    { lat: 49.2365795, lng: 18.9075511 },
    { lat: 49.2366026, lng: 18.9077875 },
    { lat: 49.2364015, lng: 18.9080657 },
    { lat: 49.2363905, lng: 18.9082499 },
    { lat: 49.2362548, lng: 18.9086856 },
    { lat: 49.2362617, lng: 18.9089741 },
    { lat: 49.2362284, lng: 18.9091422 },
    { lat: 49.2361779, lng: 18.9091886 },
    { lat: 49.2361102, lng: 18.9092793 },
    { lat: 49.2361148, lng: 18.9094999 },
    { lat: 49.2360724, lng: 18.9095312 },
    { lat: 49.2360075, lng: 18.9095887 },
    { lat: 49.23588, lng: 18.9097622 },
    { lat: 49.2358314, lng: 18.90987 },
    { lat: 49.2357064, lng: 18.91001 },
    { lat: 49.235679, lng: 18.9102005 },
    { lat: 49.2356168, lng: 18.9103056 },
    { lat: 49.2355695, lng: 18.9103094 },
    { lat: 49.2354234, lng: 18.910405 },
    { lat: 49.2353385, lng: 18.9106644 },
    { lat: 49.2353025, lng: 18.9107375 },
    { lat: 49.2352525, lng: 18.9107254 },
    { lat: 49.2352082, lng: 18.9106751 },
    { lat: 49.2351972, lng: 18.9106825 },
    { lat: 49.2351449, lng: 18.910872 },
    { lat: 49.2351436, lng: 18.9109573 },
    { lat: 49.2351987, lng: 18.9111117 },
    { lat: 49.2351663, lng: 18.9111812 },
    { lat: 49.2350986, lng: 18.9112949 },
    { lat: 49.2350154, lng: 18.9113405 },
    { lat: 49.2349441, lng: 18.9113799 },
    { lat: 49.234907, lng: 18.911472 },
    { lat: 49.2349321, lng: 18.9117674 },
    { lat: 49.234906, lng: 18.9118285 },
    { lat: 49.2348017, lng: 18.9119769 },
    { lat: 49.2347768, lng: 18.9119734 },
    { lat: 49.2347024, lng: 18.9119273 },
    { lat: 49.2346594, lng: 18.9118581 },
    { lat: 49.2346379, lng: 18.9118578 },
    { lat: 49.2346275, lng: 18.91196 },
    { lat: 49.2346924, lng: 18.9122438 },
    { lat: 49.234632, lng: 18.9123844 },
    { lat: 49.2344439, lng: 18.9125882 },
    { lat: 49.2343006, lng: 18.9128097 },
    { lat: 49.2342049, lng: 18.9128964 },
    { lat: 49.2341058, lng: 18.912946 },
    { lat: 49.2341006, lng: 18.912978 },
    { lat: 49.234102, lng: 18.9130071 },
    { lat: 49.2341764, lng: 18.9130719 },
    { lat: 49.2341823, lng: 18.9130982 },
    { lat: 49.234175, lng: 18.9132672 },
    { lat: 49.2341157, lng: 18.9133644 },
    { lat: 49.2340949, lng: 18.913373 },
    { lat: 49.2340665, lng: 18.9133728 },
    { lat: 49.2340111, lng: 18.9133476 },
    { lat: 49.2339595, lng: 18.913327 },
    { lat: 49.233919, lng: 18.9133754 },
    { lat: 49.2338771, lng: 18.9135507 },
    { lat: 49.2338945, lng: 18.9136944 },
    { lat: 49.2339471, lng: 18.9138187 },
    { lat: 49.2339515, lng: 18.913859 },
    { lat: 49.2338216, lng: 18.9139667 },
    { lat: 49.2337472, lng: 18.9139892 },
    { lat: 49.2336621, lng: 18.9139784 },
    { lat: 49.2336472, lng: 18.913974 },
    { lat: 49.2334501, lng: 18.9138894 },
    { lat: 49.2333565, lng: 18.9139994 },
    { lat: 49.2332713, lng: 18.914118 },
    { lat: 49.2332606, lng: 18.9141536 },
    { lat: 49.2332583, lng: 18.914173 },
    { lat: 49.2332562, lng: 18.914285 },
    { lat: 49.2332881, lng: 18.9143599 },
    { lat: 49.2332906, lng: 18.9144339 },
    { lat: 49.2332663, lng: 18.9144664 },
    { lat: 49.2331515, lng: 18.9144659 },
    { lat: 49.2330518, lng: 18.9143929 },
    { lat: 49.2329912, lng: 18.9144408 },
    { lat: 49.2329612, lng: 18.914562 },
    { lat: 49.2329508, lng: 18.9146036 },
    { lat: 49.2329392, lng: 18.91464 },
    { lat: 49.2328936, lng: 18.9147439 },
    { lat: 49.2328398, lng: 18.9147484 },
    { lat: 49.2327211, lng: 18.9146716 },
    { lat: 49.2325799, lng: 18.9146464 },
    { lat: 49.2324716, lng: 18.9147137 },
    { lat: 49.2324122, lng: 18.9148275 },
    { lat: 49.2323617, lng: 18.9149702 },
    { lat: 49.2322342, lng: 18.9150553 },
    { lat: 49.2322285, lng: 18.9150542 },
    { lat: 49.2321908, lng: 18.9150413 },
    { lat: 49.2320413, lng: 18.9150294 },
    { lat: 49.2320067, lng: 18.9150591 },
    { lat: 49.231951, lng: 18.9151631 },
    { lat: 49.2319469, lng: 18.9151697 },
    { lat: 49.2318778, lng: 18.9152515 },
    { lat: 49.2318507, lng: 18.9152716 },
    { lat: 49.231798, lng: 18.9153002 },
    { lat: 49.2317767, lng: 18.9153028 },
    { lat: 49.2317632, lng: 18.9153033 },
    { lat: 49.2316697, lng: 18.9153039 },
    { lat: 49.2314724, lng: 18.9153957 },
    { lat: 49.2314272, lng: 18.9154368 },
    { lat: 49.2313361, lng: 18.9155309 },
    { lat: 49.2312664, lng: 18.9156427 },
    { lat: 49.2311932, lng: 18.9156903 },
    { lat: 49.2311226, lng: 18.9157232 },
    { lat: 49.2310538, lng: 18.9157425 },
    { lat: 49.2310492, lng: 18.9157434 },
    { lat: 49.2309751, lng: 18.9157691 },
    { lat: 49.2308826, lng: 18.9158605 },
    { lat: 49.2308482, lng: 18.9158902 },
    { lat: 49.2306603, lng: 18.9159672 },
    { lat: 49.2305809, lng: 18.9160001 },
    { lat: 49.2303076, lng: 18.9160782 },
    { lat: 49.2303322, lng: 18.9160991 },
    { lat: 49.2303688, lng: 18.9161309 },
    { lat: 49.2301486, lng: 18.9163034 },
    { lat: 49.2300609, lng: 18.9163722 },
    { lat: 49.2304477, lng: 18.9166186 },
    { lat: 49.2304761, lng: 18.9166438 },
    { lat: 49.2309693, lng: 18.9174581 },
    { lat: 49.2311334, lng: 18.9178471 },
    { lat: 49.2318882, lng: 18.9196359 },
    { lat: 49.2335273, lng: 18.9242193 },
    { lat: 49.2342639, lng: 18.9259639 },
    { lat: 49.2349961, lng: 18.927866 },
    { lat: 49.2354718, lng: 18.9287143 },
    { lat: 49.2356269, lng: 18.9289911 },
    { lat: 49.2364873, lng: 18.9300416 },
    { lat: 49.2366278, lng: 18.9302133 },
    { lat: 49.2382983, lng: 18.9322791 },
    { lat: 49.2387371, lng: 18.9328223 },
    { lat: 49.2392301, lng: 18.9329622 },
    { lat: 49.239514, lng: 18.9331153 },
    { lat: 49.2396348, lng: 18.9332287 },
    { lat: 49.2397622, lng: 18.9333829 },
    { lat: 49.2400859, lng: 18.9342352 },
    { lat: 49.2405101, lng: 18.9347517 },
    { lat: 49.2411739, lng: 18.9350606 },
    { lat: 49.2412932, lng: 18.9351663 },
    { lat: 49.2414118, lng: 18.935349 },
    { lat: 49.2417647, lng: 18.9360636 },
    { lat: 49.2419461, lng: 18.9366188 },
    { lat: 49.2420099, lng: 18.9367877 },
    { lat: 49.2420722, lng: 18.9369319 },
    { lat: 49.2421803, lng: 18.9371065 },
    { lat: 49.2422271, lng: 18.9372287 },
    { lat: 49.2423139, lng: 18.9374508 },
    { lat: 49.2423435, lng: 18.9375934 },
    { lat: 49.2424012, lng: 18.9377941 },
    { lat: 49.2424557, lng: 18.9379814 },
    { lat: 49.242507, lng: 18.9381973 },
    { lat: 49.242553, lng: 18.9383914 },
    { lat: 49.2425809, lng: 18.9387197 },
    { lat: 49.2426084, lng: 18.9390214 },
    { lat: 49.2426271, lng: 18.9392548 },
    { lat: 49.242679, lng: 18.9394543 },
    { lat: 49.242717, lng: 18.9397869 },
    { lat: 49.2426982, lng: 18.9401254 },
    { lat: 49.2426728, lng: 18.9402958 },
    { lat: 49.2426174, lng: 18.9404903 },
    { lat: 49.2424821, lng: 18.9409591 },
    { lat: 49.242479, lng: 18.9418149 },
    { lat: 49.2424934, lng: 18.9421008 },
    { lat: 49.2424035, lng: 18.9428651 },
    { lat: 49.2423929, lng: 18.9431005 },
    { lat: 49.2425669, lng: 18.9441131 },
    { lat: 49.2427084, lng: 18.9448195 },
    { lat: 49.2427599, lng: 18.9451486 },
    { lat: 49.2428109, lng: 18.9455969 },
    { lat: 49.2428449, lng: 18.9460042 },
    { lat: 49.2428295, lng: 18.9462366 },
    { lat: 49.2429287, lng: 18.9462372 },
    { lat: 49.2434841, lng: 18.9466294 },
    { lat: 49.243785, lng: 18.9479456 },
    { lat: 49.2440153, lng: 18.9487596 },
    { lat: 49.2442524, lng: 18.9488555 },
    { lat: 49.2443517, lng: 18.9492786 },
    { lat: 49.2446205, lng: 18.9494707 },
    { lat: 49.2447451, lng: 18.950466 },
    { lat: 49.2448635, lng: 18.9507754 },
    { lat: 49.2451914, lng: 18.9508664 },
    { lat: 49.2452778, lng: 18.9514599 },
    { lat: 49.2454847, lng: 18.9520118 },
    { lat: 49.2456929, lng: 18.9525846 },
    { lat: 49.2458231, lng: 18.9529102 },
    { lat: 49.2457735, lng: 18.9530218 },
    { lat: 49.2459323, lng: 18.9535296 },
    { lat: 49.2460402, lng: 18.9537248 },
    { lat: 49.2462894, lng: 18.9538611 },
    { lat: 49.2465118, lng: 18.9540922 },
    { lat: 49.2465968, lng: 18.9542782 },
    { lat: 49.2466258, lng: 18.95492 },
    { lat: 49.2467781, lng: 18.9552608 },
    { lat: 49.2474155, lng: 18.9553374 },
    { lat: 49.2479228, lng: 18.9552262 },
    { lat: 49.2488144, lng: 18.9556032 },
    { lat: 49.2494751, lng: 18.955916 },
    { lat: 49.2502514, lng: 18.9559298 },
    { lat: 49.2510538, lng: 18.9557919 },
    { lat: 49.2517464, lng: 18.955665 },
    { lat: 49.252089, lng: 18.9553054 },
    { lat: 49.2522541, lng: 18.9556292 },
    { lat: 49.2522705, lng: 18.9556161 },
    { lat: 49.2528608, lng: 18.9553381 },
    { lat: 49.2533793, lng: 18.9549581 },
    { lat: 49.2535817, lng: 18.9547694 },
    { lat: 49.2537319, lng: 18.9538766 },
    { lat: 49.2539419, lng: 18.953613 },
    { lat: 49.2534053, lng: 18.9533917 },
    { lat: 49.2530541, lng: 18.9528859 },
    { lat: 49.2538623, lng: 18.9523065 },
    { lat: 49.2538841, lng: 18.9521571 },
    { lat: 49.2539565, lng: 18.9516675 },
    { lat: 49.2539908, lng: 18.9514329 },
    { lat: 49.2542921, lng: 18.951571 },
    { lat: 49.2546291, lng: 18.9519497 },
    { lat: 49.2549502, lng: 18.9518802 },
    { lat: 49.2553585, lng: 18.9521292 },
    { lat: 49.2557909, lng: 18.9516716 },
    { lat: 49.25628, lng: 18.9516924 },
    { lat: 49.2565574, lng: 18.9517587 },
    { lat: 49.2567903, lng: 18.9518626 },
    { lat: 49.257292, lng: 18.952174 },
    { lat: 49.2575201, lng: 18.9513755 },
    { lat: 49.2575346, lng: 18.9513972 },
    { lat: 49.257643, lng: 18.9515541 },
    { lat: 49.2577486, lng: 18.9517056 },
    { lat: 49.2578457, lng: 18.9518461 },
    { lat: 49.2579504, lng: 18.9519966 },
    { lat: 49.2581205, lng: 18.9522418 },
    { lat: 49.2583429, lng: 18.9525625 },
    { lat: 49.2585982, lng: 18.9529306 },
    { lat: 49.2588754, lng: 18.9533304 },
    { lat: 49.2590103, lng: 18.9535246 },
    { lat: 49.2590675, lng: 18.9537399 },
    { lat: 49.2592902, lng: 18.9545604 },
    { lat: 49.2593806, lng: 18.9554348 },
    { lat: 49.2594324, lng: 18.9556814 },
    { lat: 49.259444, lng: 18.9557566 },
    { lat: 49.2594521, lng: 18.9558054 },
    { lat: 49.2594492, lng: 18.9558592 },
    { lat: 49.2595065, lng: 18.955877 },
    { lat: 49.2596326, lng: 18.9559181 },
    { lat: 49.259713, lng: 18.9558823 },
    { lat: 49.2597541, lng: 18.9558677 },
    { lat: 49.2598241, lng: 18.9559577 },
    { lat: 49.2598768, lng: 18.9559764 },
    { lat: 49.2599518, lng: 18.9560538 },
    { lat: 49.2601303, lng: 18.9563996 },
    { lat: 49.2601287, lng: 18.9564799 },
    { lat: 49.2602495, lng: 18.9565953 },
    { lat: 49.2604247, lng: 18.9564892 },
    { lat: 49.2604606, lng: 18.9566033 },
    { lat: 49.2605206, lng: 18.9565595 },
    { lat: 49.2605992, lng: 18.9566015 },
    { lat: 49.2606334, lng: 18.9567176 },
    { lat: 49.2607104, lng: 18.956971 },
    { lat: 49.2608221, lng: 18.9569095 },
    { lat: 49.2608607, lng: 18.9570898 },
    { lat: 49.261003, lng: 18.9569644 },
    { lat: 49.2611235, lng: 18.9568936 },
    { lat: 49.2611636, lng: 18.9569499 },
    { lat: 49.2614402, lng: 18.9570942 },
    { lat: 49.2615608, lng: 18.9573176 },
    { lat: 49.261761, lng: 18.957376 },
    { lat: 49.2618989, lng: 18.9572003 },
    { lat: 49.2621107, lng: 18.9573118 },
    { lat: 49.2622451, lng: 18.9573823 },
    { lat: 49.2624928, lng: 18.9574441 },
    { lat: 49.2625906, lng: 18.9574406 },
    { lat: 49.2626922, lng: 18.9575759 },
    { lat: 49.262724, lng: 18.9575552 },
    { lat: 49.2628653, lng: 18.9574527 },
    { lat: 49.263035, lng: 18.957375 },
    { lat: 49.2630637, lng: 18.9573644 },
    { lat: 49.2631344, lng: 18.9573366 },
    { lat: 49.2632169, lng: 18.9574731 },
    { lat: 49.2633616, lng: 18.9572789 },
    { lat: 49.2636417, lng: 18.9572445 },
    { lat: 49.2638512, lng: 18.9570842 },
    { lat: 49.2640762, lng: 18.9571859 },
    { lat: 49.2643521, lng: 18.9572026 },
    { lat: 49.2645386, lng: 18.9571915 },
    { lat: 49.2645969, lng: 18.9571868 },
    { lat: 49.2648659, lng: 18.9571509 },
    { lat: 49.2652906, lng: 18.9570811 },
    { lat: 49.2655652, lng: 18.9567575 },
    { lat: 49.2657052, lng: 18.9566252 },
    { lat: 49.2658307, lng: 18.9564523 },
    { lat: 49.265871, lng: 18.9564032 },
    { lat: 49.2659116, lng: 18.9564024 },
    { lat: 49.2659887, lng: 18.9563493 },
    { lat: 49.2659922, lng: 18.9563706 },
    { lat: 49.2661426, lng: 18.9564269 },
    { lat: 49.2662765, lng: 18.9566635 },
    { lat: 49.2666269, lng: 18.9588113 },
    { lat: 49.2667562, lng: 18.959278 },
    { lat: 49.2671233, lng: 18.95938 },
    { lat: 49.2671575, lng: 18.9593897 },
    { lat: 49.2671941, lng: 18.9593984 },
    { lat: 49.2674371, lng: 18.9596782 },
    { lat: 49.2676486, lng: 18.9605024 },
    { lat: 49.2676452, lng: 18.9609508 },
    { lat: 49.2680839, lng: 18.961218 },
    { lat: 49.2681074, lng: 18.9613954 },
    { lat: 49.2682826, lng: 18.9616286 },
    { lat: 49.2686663, lng: 18.9619723 },
    { lat: 49.2687472, lng: 18.9618098 },
    { lat: 49.2687557, lng: 18.9615976 },
    { lat: 49.268872, lng: 18.9612659 },
    { lat: 49.2690015, lng: 18.9610846 },
    { lat: 49.2691058, lng: 18.9610536 },
    { lat: 49.2693041, lng: 18.9612534 },
    { lat: 49.2695143, lng: 18.9613963 },
    { lat: 49.2695814, lng: 18.9619006 },
    { lat: 49.2697203, lng: 18.9624684 },
    { lat: 49.269733, lng: 18.9624947 },
    { lat: 49.269831, lng: 18.9623587 },
    { lat: 49.2698833, lng: 18.9623237 },
    { lat: 49.2699451, lng: 18.9622738 },
    { lat: 49.2701656, lng: 18.9621967 },
    { lat: 49.2702347, lng: 18.9621802 },
    { lat: 49.2703451, lng: 18.9621106 },
    { lat: 49.270491, lng: 18.9619873 },
    { lat: 49.2706351, lng: 18.9618502 },
    { lat: 49.2707703, lng: 18.9617571 },
    { lat: 49.2708563, lng: 18.9617397 },
    { lat: 49.2710604, lng: 18.9617432 },
    { lat: 49.2712726, lng: 18.9617255 },
    { lat: 49.2713924, lng: 18.961684 },
    { lat: 49.2715897, lng: 18.9617092 },
    { lat: 49.2717229, lng: 18.9617362 },
    { lat: 49.2718874, lng: 18.9616862 },
    { lat: 49.2719986, lng: 18.9617045 },
    { lat: 49.2721591, lng: 18.9617113 },
    { lat: 49.2722238, lng: 18.9616766 },
    { lat: 49.2725014, lng: 18.9616354 },
    { lat: 49.2725716, lng: 18.9615982 },
    { lat: 49.2726376, lng: 18.9615432 },
    { lat: 49.2727053, lng: 18.9615002 },
    { lat: 49.2727882, lng: 18.961467 },
    { lat: 49.272837, lng: 18.9614505 },
    { lat: 49.2728567, lng: 18.9614608 },
    { lat: 49.2729438, lng: 18.9614661 },
    { lat: 49.2730126, lng: 18.9613111 },
    { lat: 49.2730946, lng: 18.9612357 },
    { lat: 49.2732006, lng: 18.9611929 },
    { lat: 49.2733034, lng: 18.9609267 },
    { lat: 49.2734228, lng: 18.9608347 },
    { lat: 49.2735926, lng: 18.960579 },
    { lat: 49.2736675, lng: 18.9604099 },
    { lat: 49.2737434, lng: 18.9603082 },
    { lat: 49.2738201, lng: 18.9601515 },
    { lat: 49.2738419, lng: 18.9600681 },
    { lat: 49.2738468, lng: 18.959852 },
    { lat: 49.2738569, lng: 18.9597371 },
    { lat: 49.2738555, lng: 18.9596683 },
    { lat: 49.2738791, lng: 18.9595965 },
    { lat: 49.2739466, lng: 18.9594586 },
    { lat: 49.2740178, lng: 18.9593051 },
    { lat: 49.2740776, lng: 18.9591938 },
    { lat: 49.2741131, lng: 18.9590488 },
    { lat: 49.2741637, lng: 18.9589154 },
    { lat: 49.2741895, lng: 18.9587741 },
    { lat: 49.2743053, lng: 18.9585229 },
    { lat: 49.2743269, lng: 18.9585471 },
    { lat: 49.2744637, lng: 18.958895 },
    { lat: 49.2745085, lng: 18.9594721 },
    { lat: 49.2745778, lng: 18.9594816 },
    { lat: 49.2746365, lng: 18.9595928 },
    { lat: 49.2746119, lng: 18.9597094 },
    { lat: 49.2747565, lng: 18.959761 },
    { lat: 49.2747971, lng: 18.9600754 },
    { lat: 49.2748231, lng: 18.9606168 },
    { lat: 49.2750754, lng: 18.9616176 },
    { lat: 49.2752178, lng: 18.9627244 },
    { lat: 49.2757535, lng: 18.962324 },
    { lat: 49.2764064, lng: 18.9621256 },
    { lat: 49.2764403, lng: 18.9621278 },
    { lat: 49.2763421, lng: 18.9609825 },
    { lat: 49.2762535, lng: 18.9599397 },
    { lat: 49.2761304, lng: 18.9592393 },
    { lat: 49.2758961, lng: 18.9584797 },
    { lat: 49.2756704, lng: 18.9578953 },
    { lat: 49.2754833, lng: 18.9575819 },
    { lat: 49.2752723, lng: 18.95742 },
    { lat: 49.2752005, lng: 18.9573591 },
    { lat: 49.2751485, lng: 18.9572692 },
    { lat: 49.2751257, lng: 18.9571509 },
    { lat: 49.2750991, lng: 18.9569122 },
    { lat: 49.275112, lng: 18.9566763 },
    { lat: 49.2752026, lng: 18.9558815 },
    { lat: 49.2752256, lng: 18.9550805 },
    { lat: 49.2753295, lng: 18.9545018 },
    { lat: 49.2754509, lng: 18.9541355 },
    { lat: 49.2754881, lng: 18.9540015 },
    { lat: 49.2756323, lng: 18.9532614 },
    { lat: 49.275742, lng: 18.9525314 },
    { lat: 49.2757598, lng: 18.9522567 },
    { lat: 49.275831, lng: 18.9507388 },
    { lat: 49.2762544, lng: 18.949362 },
    { lat: 49.2764196, lng: 18.9489501 },
    { lat: 49.2765775, lng: 18.9484593 },
    { lat: 49.2765933, lng: 18.9483946 },
    { lat: 49.2766962, lng: 18.947838 },
    { lat: 49.2769142, lng: 18.9465067 },
    { lat: 49.2769698, lng: 18.9460452 },
    { lat: 49.2771458, lng: 18.9450847 },
    { lat: 49.2773517, lng: 18.9443599 },
    { lat: 49.2775644, lng: 18.9438619 },
    { lat: 49.2777467, lng: 18.9434277 },
    { lat: 49.2779553, lng: 18.9430305 },
    { lat: 49.2781313, lng: 18.9426509 },
    { lat: 49.2782607, lng: 18.9422893 },
    { lat: 49.2783679, lng: 18.9418662 },
    { lat: 49.2784735, lng: 18.9412576 },
    { lat: 49.278526, lng: 18.9407538 },
    { lat: 49.2785387, lng: 18.9406256 },
    { lat: 49.2785533, lng: 18.9402454 },
    { lat: 49.2785371, lng: 18.9395884 },
    { lat: 49.2785066, lng: 18.9388756 },
    { lat: 49.278496, lng: 18.938328 },
    { lat: 49.2783943, lng: 18.9373868 },
    { lat: 49.2783783, lng: 18.9368683 },
    { lat: 49.2782638, lng: 18.9353169 },
    { lat: 49.277963, lng: 18.9336694 },
    { lat: 49.2778765, lng: 18.9332521 },
    { lat: 49.2775619, lng: 18.9316626 },
    { lat: 49.277759, lng: 18.930911 },
    { lat: 49.277713, lng: 18.930897 },
    { lat: 49.277199, lng: 18.930783 },
    { lat: 49.277034, lng: 18.930747 },
    { lat: 49.277029, lng: 18.930745 },
    { lat: 49.276862, lng: 18.930841 },
    { lat: 49.276858, lng: 18.930844 },
    { lat: 49.276641, lng: 18.929529 },
    { lat: 49.276442, lng: 18.929083 },
    { lat: 49.276311, lng: 18.928895 },
    { lat: 49.27607, lng: 18.928463 },
    { lat: 49.275779, lng: 18.928353 },
    { lat: 49.275599, lng: 18.927869 },
    { lat: 49.275529, lng: 18.927641 },
    { lat: 49.275304, lng: 18.927642 },
    { lat: 49.275096, lng: 18.927642 },
    { lat: 49.274955, lng: 18.927626 },
    { lat: 49.274791, lng: 18.927603 },
    { lat: 49.274724, lng: 18.927593 },
    { lat: 49.274371, lng: 18.927659 },
    { lat: 49.27406, lng: 18.927695 },
    { lat: 49.274049, lng: 18.927728 },
    { lat: 49.274004, lng: 18.927862 },
    { lat: 49.273915, lng: 18.927826 },
    { lat: 49.273702, lng: 18.927739 },
    { lat: 49.273439, lng: 18.927818 },
    { lat: 49.273292, lng: 18.927776 },
    { lat: 49.272736, lng: 18.927629 },
    { lat: 49.272215, lng: 18.927429 },
    { lat: 49.272174, lng: 18.927499 },
    { lat: 49.271961, lng: 18.927873 },
    { lat: 49.271933, lng: 18.927914 },
    { lat: 49.271931, lng: 18.927915 },
    { lat: 49.271932, lng: 18.927916 },
    { lat: 49.272104, lng: 18.928755 },
    { lat: 49.272115, lng: 18.928787 },
    { lat: 49.272117, lng: 18.928791 },
    { lat: 49.271759, lng: 18.928857 },
    { lat: 49.271459, lng: 18.928909 },
    { lat: 49.271111, lng: 18.928369 },
    { lat: 49.270849, lng: 18.928319 },
    { lat: 49.270848, lng: 18.928319 },
    { lat: 49.270795, lng: 18.928331 },
    { lat: 49.270286, lng: 18.928444 },
    { lat: 49.270206, lng: 18.928457 },
    { lat: 49.269947, lng: 18.9285 },
    { lat: 49.269726, lng: 18.928494 },
    { lat: 49.269597, lng: 18.92849 },
    { lat: 49.269593, lng: 18.928487 },
    { lat: 49.26921, lng: 18.92819 },
    { lat: 49.269205, lng: 18.928197 },
    { lat: 49.269065, lng: 18.928408 },
    { lat: 49.269055, lng: 18.928405 },
    { lat: 49.268668, lng: 18.928276 },
    { lat: 49.268408, lng: 18.928191 },
    { lat: 49.268178, lng: 18.928121 },
    { lat: 49.268051, lng: 18.928083 },
    { lat: 49.267643, lng: 18.927824 },
    { lat: 49.267105, lng: 18.92764 },
    { lat: 49.266753, lng: 18.927671 },
    { lat: 49.266327, lng: 18.927708 },
    { lat: 49.265459, lng: 18.927772 },
    { lat: 49.264905, lng: 18.927856 },
    { lat: 49.264117, lng: 18.92769 },
    { lat: 49.264109, lng: 18.927682 },
    { lat: 49.264059, lng: 18.927626 },
    { lat: 49.263355, lng: 18.926841 },
    { lat: 49.263352, lng: 18.926834 },
    { lat: 49.262941, lng: 18.926079 },
    { lat: 49.262746, lng: 18.925182 },
    { lat: 49.262695, lng: 18.924947 },
    { lat: 49.262363, lng: 18.924671 },
    { lat: 49.26229, lng: 18.924376 },
    { lat: 49.262212, lng: 18.923584 },
    { lat: 49.262212, lng: 18.923583 },
    { lat: 49.26225, lng: 18.923001 },
    { lat: 49.262249, lng: 18.923 },
    { lat: 49.261906, lng: 18.921791 },
    { lat: 49.26157, lng: 18.920616 },
    { lat: 49.261547, lng: 18.920536 },
    { lat: 49.261469, lng: 18.920146 },
    { lat: 49.261308, lng: 18.919358 },
    { lat: 49.261115, lng: 18.91856 },
    { lat: 49.260909, lng: 18.917656 },
    { lat: 49.260911, lng: 18.917654 },
    { lat: 49.260921, lng: 18.917586 },
    { lat: 49.260976, lng: 18.91725 },
    { lat: 49.261057, lng: 18.917058 },
    { lat: 49.261202, lng: 18.916723 },
    { lat: 49.261273, lng: 18.916514 },
    { lat: 49.261272, lng: 18.916512 },
    { lat: 49.26034, lng: 18.914974 },
    { lat: 49.259379, lng: 18.913269 },
    { lat: 49.259029, lng: 18.912305 },
    { lat: 49.258876, lng: 18.911935 },
    { lat: 49.258654, lng: 18.911532 },
    { lat: 49.258542, lng: 18.911414 },
    { lat: 49.258433, lng: 18.911322 },
    { lat: 49.258373, lng: 18.911304 },
    { lat: 49.258018, lng: 18.910824 },
    { lat: 49.257634, lng: 18.910603 },
    { lat: 49.257633, lng: 18.910602 },
    { lat: 49.257522, lng: 18.910397 },
    { lat: 49.257496, lng: 18.910348 },
    { lat: 49.257489, lng: 18.910336 },
    { lat: 49.257461, lng: 18.910285 },
    { lat: 49.257428, lng: 18.910186 },
    { lat: 49.25729, lng: 18.909774 },
    { lat: 49.257186, lng: 18.909214 },
    { lat: 49.256353, lng: 18.907548 },
    { lat: 49.256332, lng: 18.907489 },
    { lat: 49.256128, lng: 18.907067 },
    { lat: 49.255944, lng: 18.906594 },
    { lat: 49.255882, lng: 18.906418 },
    { lat: 49.255751, lng: 18.90602 },
    { lat: 49.255668, lng: 18.905516 },
    { lat: 49.255668, lng: 18.905515 },
    { lat: 49.255416, lng: 18.905177 },
    { lat: 49.255358, lng: 18.9051 },
    { lat: 49.254737, lng: 18.904425 },
    { lat: 49.254407, lng: 18.904264 },
    { lat: 49.254284, lng: 18.904205 },
    { lat: 49.253812, lng: 18.903917 },
    { lat: 49.253808, lng: 18.903913 },
    { lat: 49.25331, lng: 18.903539 },
    { lat: 49.252965, lng: 18.903146 },
    { lat: 49.252555, lng: 18.902455 },
    { lat: 49.252207, lng: 18.902015 },
    { lat: 49.251907, lng: 18.901746 },
    { lat: 49.251708, lng: 18.900688 },
    { lat: 49.251708, lng: 18.900685 },
    { lat: 49.251702, lng: 18.900039 },
    { lat: 49.251691, lng: 18.899028 },
    { lat: 49.251263, lng: 18.898257 },
    { lat: 49.25085, lng: 18.89751 },
    { lat: 49.250668, lng: 18.897282 },
    { lat: 49.250615, lng: 18.897217 },
    { lat: 49.250454, lng: 18.896855 },
    { lat: 49.250119, lng: 18.896426 },
    { lat: 49.250115, lng: 18.896422 },
    { lat: 49.249933, lng: 18.896473 },
    { lat: 49.249775, lng: 18.896518 },
    { lat: 49.249773, lng: 18.896519 },
    { lat: 49.249615, lng: 18.895452 },
    { lat: 49.249615, lng: 18.89545 },
    { lat: 49.249629, lng: 18.894688 },
    { lat: 49.249629, lng: 18.894684 },
    { lat: 49.249527, lng: 18.892974 },
    { lat: 49.249527, lng: 18.892972 },
    { lat: 49.249479, lng: 18.891444 },
    { lat: 49.249479, lng: 18.891441 },
    { lat: 49.249426, lng: 18.890498 },
    { lat: 49.249399, lng: 18.888949 },
    { lat: 49.249399, lng: 18.888946 },
    { lat: 49.249344, lng: 18.888017 },
    { lat: 49.249215, lng: 18.8873 },
    { lat: 49.248819, lng: 18.885117 },
    { lat: 49.248819, lng: 18.885115 },
    { lat: 49.248842, lng: 18.884311 },
    { lat: 49.248859, lng: 18.883701 },
  ],
  Nededza: [
    { lat: 49.2321478, lng: 18.8307656 },
    { lat: 49.2312379, lng: 18.8305024 },
    { lat: 49.230666, lng: 18.8303683 },
    { lat: 49.2303854, lng: 18.8303911 },
    { lat: 49.2296567, lng: 18.8301437 },
    { lat: 49.2293348, lng: 18.8295857 },
    { lat: 49.2290972, lng: 18.8291744 },
    { lat: 49.2287795, lng: 18.8286108 },
    { lat: 49.2286052, lng: 18.8287966 },
    { lat: 49.2280787, lng: 18.8282894 },
    { lat: 49.2279982, lng: 18.8281075 },
    { lat: 49.227853, lng: 18.8279344 },
    { lat: 49.2276669, lng: 18.8276923 },
    { lat: 49.2275716, lng: 18.8275941 },
    { lat: 49.2273203, lng: 18.8274865 },
    { lat: 49.2270903, lng: 18.8272042 },
    { lat: 49.2269618, lng: 18.8270547 },
    { lat: 49.2267668, lng: 18.8268999 },
    { lat: 49.2265418, lng: 18.8267989 },
    { lat: 49.2264032, lng: 18.8267585 },
    { lat: 49.2261525, lng: 18.8265758 },
    { lat: 49.2259269, lng: 18.8264203 },
    { lat: 49.225761, lng: 18.8261304 },
    { lat: 49.2254766, lng: 18.8258644 },
    { lat: 49.225484, lng: 18.825747 },
    { lat: 49.225305, lng: 18.8254874 },
    { lat: 49.2251489, lng: 18.8252147 },
    { lat: 49.2250301, lng: 18.8249213 },
    { lat: 49.2247911, lng: 18.8246621 },
    { lat: 49.2246767, lng: 18.8244543 },
    { lat: 49.2246396, lng: 18.8243679 },
    { lat: 49.2244011, lng: 18.8241819 },
    { lat: 49.2241392, lng: 18.8240012 },
    { lat: 49.2239493, lng: 18.8237961 },
    { lat: 49.2237469, lng: 18.8236192 },
    { lat: 49.223482, lng: 18.8233876 },
    { lat: 49.22186, lng: 18.822243 },
    { lat: 49.221344, lng: 18.8219631 },
    { lat: 49.2210474, lng: 18.8217228 },
    { lat: 49.2209804, lng: 18.8216706 },
    { lat: 49.2209592, lng: 18.8216533 },
    { lat: 49.2208769, lng: 18.8215874 },
    { lat: 49.2208604, lng: 18.8215739 },
    { lat: 49.2206181, lng: 18.8213801 },
    { lat: 49.2205099, lng: 18.8212928 },
    { lat: 49.2190946, lng: 18.8203241 },
    { lat: 49.2178815, lng: 18.8194516 },
    { lat: 49.217858, lng: 18.8194321 },
    { lat: 49.2178492, lng: 18.8194581 },
    { lat: 49.2178401, lng: 18.8194566 },
    { lat: 49.2178172, lng: 18.8194291 },
    { lat: 49.2177463, lng: 18.8193453 },
    { lat: 49.2177284, lng: 18.8193236 },
    { lat: 49.2177328, lng: 18.8192989 },
    { lat: 49.2161877, lng: 18.8182666 },
    { lat: 49.2148614, lng: 18.817212 },
    { lat: 49.2142665, lng: 18.816718 },
    { lat: 49.2136298, lng: 18.8162413 },
    { lat: 49.213595, lng: 18.8162227 },
    { lat: 49.2135591, lng: 18.8161629 },
    { lat: 49.2135198, lng: 18.816208 },
    { lat: 49.2134893, lng: 18.8162942 },
    { lat: 49.2134489, lng: 18.8164094 },
    { lat: 49.2134186, lng: 18.8164986 },
    { lat: 49.2133852, lng: 18.8165927 },
    { lat: 49.2133185, lng: 18.8167851 },
    { lat: 49.213286, lng: 18.8168773 },
    { lat: 49.2132545, lng: 18.8169687 },
    { lat: 49.2132506, lng: 18.81698 },
    { lat: 49.2132008, lng: 18.8171267 },
    { lat: 49.2131459, lng: 18.8172862 },
    { lat: 49.2131017, lng: 18.8174145 },
    { lat: 49.2130527, lng: 18.8175582 },
    { lat: 49.2129753, lng: 18.8177846 },
    { lat: 49.2129538, lng: 18.8178488 },
    { lat: 49.212946, lng: 18.8178721 },
    { lat: 49.2129265, lng: 18.8179296 },
    { lat: 49.2128853, lng: 18.8180511 },
    { lat: 49.2128693, lng: 18.8180894 },
    { lat: 49.2128265, lng: 18.8181988 },
    { lat: 49.2127755, lng: 18.8183276 },
    { lat: 49.2127624, lng: 18.818358 },
    { lat: 49.2126972, lng: 18.8185123 },
    { lat: 49.2126691, lng: 18.8185784 },
    { lat: 49.2126289, lng: 18.8186723 },
    { lat: 49.2125926, lng: 18.8187571 },
    { lat: 49.2125584, lng: 18.8188366 },
    { lat: 49.212521, lng: 18.8189226 },
    { lat: 49.2125018, lng: 18.8189671 },
    { lat: 49.2124807, lng: 18.8190154 },
    { lat: 49.2123991, lng: 18.8192023 },
    { lat: 49.2123588, lng: 18.8192958 },
    { lat: 49.2123275, lng: 18.8193671 },
    { lat: 49.2123132, lng: 18.8193955 },
    { lat: 49.2121274, lng: 18.8197525 },
    { lat: 49.212062, lng: 18.8198801 },
    { lat: 49.211992, lng: 18.8200129 },
    { lat: 49.211988, lng: 18.8200217 },
    { lat: 49.2119293, lng: 18.8201521 },
    { lat: 49.2119223, lng: 18.8201677 },
    { lat: 49.211869, lng: 18.8202935 },
    { lat: 49.2118157, lng: 18.8204196 },
    { lat: 49.2118056, lng: 18.8204439 },
    { lat: 49.2117467, lng: 18.8205891 },
    { lat: 49.2116785, lng: 18.8207556 },
    { lat: 49.2116116, lng: 18.8209198 },
    { lat: 49.2115813, lng: 18.8209923 },
    { lat: 49.2115725, lng: 18.8210132 },
    { lat: 49.2115339, lng: 18.8210983 },
    { lat: 49.2114551, lng: 18.8212758 },
    { lat: 49.2114175, lng: 18.8213579 },
    { lat: 49.2114004, lng: 18.8213971 },
    { lat: 49.2113874, lng: 18.82147 },
    { lat: 49.2113404, lng: 18.8217177 },
    { lat: 49.2113385, lng: 18.8217269 },
    { lat: 49.2113306, lng: 18.8217701 },
    { lat: 49.2113273, lng: 18.8217932 },
    { lat: 49.2113162, lng: 18.8218861 },
    { lat: 49.2113068, lng: 18.8219648 },
    { lat: 49.2112873, lng: 18.8221323 },
    { lat: 49.21128, lng: 18.8222323 },
    { lat: 49.2112586, lng: 18.8225364 },
    { lat: 49.2112346, lng: 18.822883 },
    { lat: 49.2111821, lng: 18.8230234 },
    { lat: 49.2111107, lng: 18.8232113 },
    { lat: 49.2110671, lng: 18.8233279 },
    { lat: 49.2110371, lng: 18.8234018 },
    { lat: 49.2109629, lng: 18.8235849 },
    { lat: 49.210894, lng: 18.8237552 },
    { lat: 49.210889, lng: 18.8237687 },
    { lat: 49.2108239, lng: 18.8239276 },
    { lat: 49.2107659, lng: 18.8240724 },
    { lat: 49.210713, lng: 18.8242046 },
    { lat: 49.210657, lng: 18.8243422 },
    { lat: 49.2106339, lng: 18.8243991 },
    { lat: 49.2105622, lng: 18.8245794 },
    { lat: 49.2105392, lng: 18.8246369 },
    { lat: 49.2105143, lng: 18.8246994 },
    { lat: 49.2104552, lng: 18.8248454 },
    { lat: 49.2104203, lng: 18.8249343 },
    { lat: 49.2103834, lng: 18.8250253 },
    { lat: 49.2103474, lng: 18.8251154 },
    { lat: 49.2102923, lng: 18.825253 },
    { lat: 49.2102325, lng: 18.8254037 },
    { lat: 49.2102184, lng: 18.8254375 },
    { lat: 49.2100342, lng: 18.825998 },
    { lat: 49.2098557, lng: 18.8265589 },
    { lat: 49.2097709, lng: 18.8268392 },
    { lat: 49.2096511, lng: 18.8272708 },
    { lat: 49.2095989, lng: 18.8274599 },
    { lat: 49.2095271, lng: 18.8277304 },
    { lat: 49.2095132, lng: 18.8278142 },
    { lat: 49.2094969, lng: 18.827911 },
    { lat: 49.209704, lng: 18.8280128 },
    { lat: 49.2098879, lng: 18.8281032 },
    { lat: 49.210389, lng: 18.8283316 },
    { lat: 49.2107806, lng: 18.8285814 },
    { lat: 49.211191, lng: 18.828842 },
    { lat: 49.2114452, lng: 18.8290246 },
    { lat: 49.2116745, lng: 18.8291875 },
    { lat: 49.2122204, lng: 18.8295855 },
    { lat: 49.2124365, lng: 18.8297312 },
    { lat: 49.212448, lng: 18.8297388 },
    { lat: 49.2130469, lng: 18.8301322 },
    { lat: 49.2132971, lng: 18.8303064 },
    { lat: 49.2134554, lng: 18.8304028 },
    { lat: 49.2138441, lng: 18.8307478 },
    { lat: 49.2146246, lng: 18.831364 },
    { lat: 49.2152992, lng: 18.8320315 },
    { lat: 49.2157869, lng: 18.8325241 },
    { lat: 49.2161182, lng: 18.8328494 },
    { lat: 49.2167724, lng: 18.8335565 },
    { lat: 49.2167259, lng: 18.8336811 },
    { lat: 49.2166997, lng: 18.833765 },
    { lat: 49.2166901, lng: 18.8337979 },
    { lat: 49.2164096, lng: 18.8347209 },
    { lat: 49.2160621, lng: 18.8361422 },
    { lat: 49.2156206, lng: 18.8360425 },
    { lat: 49.2152441, lng: 18.8359343 },
    { lat: 49.214849, lng: 18.8357483 },
    { lat: 49.2145569, lng: 18.8355954 },
    { lat: 49.2142004, lng: 18.8353709 },
    { lat: 49.2137745, lng: 18.8351349 },
    { lat: 49.2129171, lng: 18.8346242 },
    { lat: 49.2124054, lng: 18.8342531 },
    { lat: 49.2113633, lng: 18.8335024 },
    { lat: 49.2113573, lng: 18.833519 },
    { lat: 49.2113474, lng: 18.8335465 },
    { lat: 49.2113409, lng: 18.8335718 },
    { lat: 49.2111468, lng: 18.834268 },
    { lat: 49.2109494, lng: 18.8349494 },
    { lat: 49.2108126, lng: 18.8354455 },
    { lat: 49.210672, lng: 18.8357353 },
    { lat: 49.2106474, lng: 18.8357848 },
    { lat: 49.2106363, lng: 18.8358081 },
    { lat: 49.2105742, lng: 18.8359605 },
    { lat: 49.2106152, lng: 18.8359899 },
    { lat: 49.21071, lng: 18.83606 },
    { lat: 49.2107863, lng: 18.8361147 },
    { lat: 49.2109608, lng: 18.8362413 },
    { lat: 49.2110521, lng: 18.8363083 },
    { lat: 49.2110695, lng: 18.8363209 },
    { lat: 49.21168, lng: 18.836765 },
    { lat: 49.2123649, lng: 18.8373248 },
    { lat: 49.2129351, lng: 18.8377596 },
    { lat: 49.2148634, lng: 18.8394059 },
    { lat: 49.2158715, lng: 18.8402647 },
    { lat: 49.215888, lng: 18.8402782 },
    { lat: 49.216065, lng: 18.8404302 },
    { lat: 49.2162704, lng: 18.8397564 },
    { lat: 49.2162868, lng: 18.8397028 },
    { lat: 49.2162975, lng: 18.8396683 },
    { lat: 49.2162993, lng: 18.8396624 },
    { lat: 49.2163798, lng: 18.8394497 },
    { lat: 49.2165404, lng: 18.8389785 },
    { lat: 49.216753, lng: 18.8384178 },
    { lat: 49.2168207, lng: 18.8382459 },
    { lat: 49.2169886, lng: 18.8378013 },
    { lat: 49.2177476, lng: 18.8385079 },
    { lat: 49.2180022, lng: 18.8387439 },
    { lat: 49.21829, lng: 18.8389933 },
    { lat: 49.2187963, lng: 18.8394535 },
    { lat: 49.2190962, lng: 18.8397396 },
    { lat: 49.2193438, lng: 18.8399957 },
    { lat: 49.2196176, lng: 18.8402539 },
    { lat: 49.2198139, lng: 18.8403852 },
    { lat: 49.2200823, lng: 18.8405893 },
    { lat: 49.2198728, lng: 18.8412701 },
    { lat: 49.2195754, lng: 18.8422414 },
    { lat: 49.2195623, lng: 18.8422949 },
    { lat: 49.219883, lng: 18.8425678 },
    { lat: 49.2200791, lng: 18.8428942 },
    { lat: 49.2202138, lng: 18.8432762 },
    { lat: 49.220436, lng: 18.8435649 },
    { lat: 49.2206943, lng: 18.8439383 },
    { lat: 49.2209941, lng: 18.8442469 },
    { lat: 49.221148, lng: 18.844345 },
    { lat: 49.2213985, lng: 18.8442587 },
    { lat: 49.2216026, lng: 18.8439955 },
    { lat: 49.2219031, lng: 18.8435718 },
    { lat: 49.2219975, lng: 18.8436752 },
    { lat: 49.2220249, lng: 18.8437664 },
    { lat: 49.222396, lng: 18.844055 },
    { lat: 49.222577, lng: 18.8440885 },
    { lat: 49.2227927, lng: 18.8442304 },
    { lat: 49.2233565, lng: 18.8444933 },
    { lat: 49.2233446, lng: 18.8444136 },
    { lat: 49.2235491, lng: 18.8447076 },
    { lat: 49.2237603, lng: 18.845046 },
    { lat: 49.223825, lng: 18.8452309 },
    { lat: 49.2238626, lng: 18.8451246 },
    { lat: 49.2239105, lng: 18.8452484 },
    { lat: 49.2240475, lng: 18.8448846 },
    { lat: 49.2240198, lng: 18.8446993 },
    { lat: 49.2245596, lng: 18.8445369 },
    { lat: 49.2252125, lng: 18.8451972 },
    { lat: 49.2256132, lng: 18.8456694 },
    { lat: 49.2261153, lng: 18.8464906 },
    { lat: 49.2263712, lng: 18.8467302 },
    { lat: 49.2264238, lng: 18.8468754 },
    { lat: 49.2263841, lng: 18.8474418 },
    { lat: 49.2276244, lng: 18.8486102 },
    { lat: 49.2289865, lng: 18.8492929 },
    { lat: 49.2291592, lng: 18.8492737 },
    { lat: 49.230465, lng: 18.8501352 },
    { lat: 49.2308462, lng: 18.8506438 },
    { lat: 49.230995, lng: 18.8506422 },
    { lat: 49.231241, lng: 18.850887 },
    { lat: 49.2314098, lng: 18.8510555 },
    { lat: 49.2313639, lng: 18.8512154 },
    { lat: 49.2316971, lng: 18.8515818 },
    { lat: 49.2319283, lng: 18.8516256 },
    { lat: 49.2323579, lng: 18.8518661 },
    { lat: 49.2327468, lng: 18.8521984 },
    { lat: 49.2331968, lng: 18.8523943 },
    { lat: 49.2331715, lng: 18.8534631 },
    { lat: 49.2328992, lng: 18.8550383 },
    { lat: 49.2332544, lng: 18.855256 },
    { lat: 49.2336325, lng: 18.8550914 },
    { lat: 49.2346222, lng: 18.856184 },
    { lat: 49.2346623, lng: 18.8564408 },
    { lat: 49.2348018, lng: 18.8566965 },
    { lat: 49.2352244, lng: 18.8570238 },
    { lat: 49.2353217, lng: 18.8574047 },
    { lat: 49.235345, lng: 18.8576624 },
    { lat: 49.2355857, lng: 18.8581124 },
    { lat: 49.2362703, lng: 18.8581386 },
    { lat: 49.2364128, lng: 18.8588735 },
    { lat: 49.2366608, lng: 18.8588081 },
    { lat: 49.2370064, lng: 18.8588852 },
    { lat: 49.2371006, lng: 18.8590504 },
    { lat: 49.2371502, lng: 18.8592938 },
    { lat: 49.2371529, lng: 18.8595872 },
    { lat: 49.237273, lng: 18.8603904 },
    { lat: 49.2373881, lng: 18.8606141 },
    { lat: 49.2370745, lng: 18.8610509 },
    { lat: 49.2372045, lng: 18.8612584 },
    { lat: 49.237426, lng: 18.8616866 },
    { lat: 49.2374723, lng: 18.86182 },
    { lat: 49.2375813, lng: 18.8619877 },
    { lat: 49.2373825, lng: 18.8624246 },
    { lat: 49.2377088, lng: 18.8628486 },
    { lat: 49.238068, lng: 18.8634555 },
    { lat: 49.2384421, lng: 18.863915 },
    { lat: 49.2395102, lng: 18.8650053 },
    { lat: 49.239848, lng: 18.8641362 },
    { lat: 49.2401436, lng: 18.8644509 },
    { lat: 49.2406079, lng: 18.864949 },
    { lat: 49.2413822, lng: 18.8664443 },
    { lat: 49.2407147, lng: 18.8675924 },
    { lat: 49.2405148, lng: 18.8679381 },
    { lat: 49.2407891, lng: 18.8682301 },
    { lat: 49.2409252, lng: 18.868114 },
    { lat: 49.241236, lng: 18.8684352 },
    { lat: 49.2412692, lng: 18.8684699 },
    { lat: 49.2418154, lng: 18.8688543 },
    { lat: 49.2419031, lng: 18.8689601 },
    { lat: 49.242093, lng: 18.8691904 },
    { lat: 49.2421502, lng: 18.8692287 },
    { lat: 49.242343, lng: 18.8693583 },
    { lat: 49.2423861, lng: 18.8693871 },
    { lat: 49.2425238, lng: 18.8695684 },
    { lat: 49.2425553, lng: 18.8695416 },
    { lat: 49.2428145, lng: 18.8697409 },
    { lat: 49.2429271, lng: 18.8698271 },
    { lat: 49.2431678, lng: 18.8696833 },
    { lat: 49.2432273, lng: 18.8695434 },
    { lat: 49.2435448, lng: 18.8701998 },
    { lat: 49.2437562, lng: 18.8703832 },
    { lat: 49.2441596, lng: 18.8706443 },
    { lat: 49.2442862, lng: 18.8708703 },
    { lat: 49.2442815, lng: 18.8709582 },
    { lat: 49.2442792, lng: 18.8710011 },
    { lat: 49.2443091, lng: 18.8710126 },
    { lat: 49.244985, lng: 18.8712666 },
    { lat: 49.2449559, lng: 18.8714685 },
    { lat: 49.2451872, lng: 18.8716272 },
    { lat: 49.2454498, lng: 18.8717117 },
    { lat: 49.24607, lng: 18.8719117 },
    { lat: 49.2467288, lng: 18.8730649 },
    { lat: 49.2470212, lng: 18.8734068 },
    { lat: 49.2472712, lng: 18.8735549 },
    { lat: 49.2476757, lng: 18.8741018 },
    { lat: 49.2479238, lng: 18.8741711 },
    { lat: 49.2478628, lng: 18.8746526 },
    { lat: 49.2476272, lng: 18.8746323 },
    { lat: 49.2476121, lng: 18.8747341 },
    { lat: 49.2470489, lng: 18.8748733 },
    { lat: 49.2469749, lng: 18.8760756 },
    { lat: 49.2470193, lng: 18.8763492 },
    { lat: 49.2473582, lng: 18.8762771 },
    { lat: 49.247481, lng: 18.8763401 },
    { lat: 49.247714, lng: 18.8765076 },
    { lat: 49.248134, lng: 18.8764106 },
    { lat: 49.2481401, lng: 18.8763294 },
    { lat: 49.2482714, lng: 18.8763196 },
    { lat: 49.2489761, lng: 18.8760974 },
    { lat: 49.2495226, lng: 18.8765329 },
    { lat: 49.249547, lng: 18.876432 },
    { lat: 49.249046, lng: 18.87596 },
    { lat: 49.248784, lng: 18.87563 },
    { lat: 49.24844, lng: 18.875158 },
    { lat: 49.248342, lng: 18.874957 },
    { lat: 49.248296, lng: 18.874702 },
    { lat: 49.248289, lng: 18.874643 },
    { lat: 49.248165, lng: 18.874296 },
    { lat: 49.248118, lng: 18.874173 },
    { lat: 49.248113, lng: 18.874011 },
    { lat: 49.248074, lng: 18.873798 },
    { lat: 49.248073, lng: 18.87356 },
    { lat: 49.248039, lng: 18.873388 },
    { lat: 49.248056, lng: 18.873082 },
    { lat: 49.248023, lng: 18.872953 },
    { lat: 49.247905, lng: 18.872613 },
    { lat: 49.247587, lng: 18.871938 },
    { lat: 49.247515, lng: 18.871162 },
    { lat: 49.247503, lng: 18.86972 },
    { lat: 49.247635, lng: 18.869576 },
    { lat: 49.247648, lng: 18.869407 },
    { lat: 49.247597, lng: 18.869161 },
    { lat: 49.247715, lng: 18.868847 },
    { lat: 49.24773, lng: 18.868827 },
    { lat: 49.247631, lng: 18.868548 },
    { lat: 49.24779, lng: 18.868455 },
    { lat: 49.247808, lng: 18.868312 },
    { lat: 49.247673, lng: 18.868203 },
    { lat: 49.24769, lng: 18.868043 },
    { lat: 49.24779, lng: 18.867956 },
    { lat: 49.247821, lng: 18.86797 },
    { lat: 49.247901, lng: 18.86791 },
    { lat: 49.247912, lng: 18.867852 },
    { lat: 49.247763, lng: 18.867534 },
    { lat: 49.247786, lng: 18.867432 },
    { lat: 49.247927, lng: 18.867388 },
    { lat: 49.247971, lng: 18.867551 },
    { lat: 49.248031, lng: 18.867598 },
    { lat: 49.248103, lng: 18.86755 },
    { lat: 49.248148, lng: 18.867251 },
    { lat: 49.248153, lng: 18.867198 },
    { lat: 49.24822, lng: 18.866879 },
    { lat: 49.248566, lng: 18.866574 },
    { lat: 49.248651, lng: 18.866314 },
    { lat: 49.248767, lng: 18.866062 },
    { lat: 49.249098, lng: 18.865743 },
    { lat: 49.249472, lng: 18.865289 },
    { lat: 49.249445, lng: 18.865086 },
    { lat: 49.249468, lng: 18.865041 },
    { lat: 49.249505, lng: 18.864973 },
    { lat: 49.249774, lng: 18.864823 },
    { lat: 49.249888, lng: 18.864888 },
    { lat: 49.250029, lng: 18.864757 },
    { lat: 49.250189, lng: 18.864846 },
    { lat: 49.250271, lng: 18.864537 },
    { lat: 49.250415, lng: 18.864226 },
    { lat: 49.250787, lng: 18.863721 },
    { lat: 49.250768, lng: 18.863686 },
    { lat: 49.25077, lng: 18.863682 },
    { lat: 49.250792, lng: 18.863627 },
    { lat: 49.250774, lng: 18.863626 },
    { lat: 49.25075, lng: 18.863616 },
    { lat: 49.250716, lng: 18.863609 },
    { lat: 49.250699, lng: 18.863606 },
    { lat: 49.250671, lng: 18.863602 },
    { lat: 49.250658, lng: 18.8636 },
    { lat: 49.250594, lng: 18.863615 },
    { lat: 49.250562, lng: 18.863604 },
    { lat: 49.250556, lng: 18.8636 },
    { lat: 49.2505, lng: 18.863564 },
    { lat: 49.250446, lng: 18.863529 },
    { lat: 49.250408, lng: 18.863504 },
    { lat: 49.250399, lng: 18.863496 },
    { lat: 49.250279, lng: 18.863448 },
    { lat: 49.250258, lng: 18.863438 },
    { lat: 49.250219, lng: 18.86341 },
    { lat: 49.250152, lng: 18.863366 },
    { lat: 49.250102, lng: 18.86333 },
    { lat: 49.250066, lng: 18.863325 },
    { lat: 49.24999, lng: 18.863309 },
    { lat: 49.249947, lng: 18.8633 },
    { lat: 49.249927, lng: 18.863285 },
    { lat: 49.249909, lng: 18.863271 },
    { lat: 49.249841, lng: 18.863244 },
    { lat: 49.249815, lng: 18.863232 },
    { lat: 49.249773, lng: 18.863214 },
    { lat: 49.249741, lng: 18.863202 },
    { lat: 49.249734, lng: 18.863194 },
    { lat: 49.249687, lng: 18.863136 },
    { lat: 49.249628, lng: 18.86307 },
    { lat: 49.249551, lng: 18.862984 },
    { lat: 49.249477, lng: 18.862999 },
    { lat: 49.249407, lng: 18.863009 },
    { lat: 49.249345, lng: 18.862983 },
    { lat: 49.249286, lng: 18.862957 },
    { lat: 49.249225, lng: 18.862933 },
    { lat: 49.249193, lng: 18.862921 },
    { lat: 49.249174, lng: 18.862923 },
    { lat: 49.24917, lng: 18.862923 },
    { lat: 49.249141, lng: 18.862928 },
    { lat: 49.249067, lng: 18.862962 },
    { lat: 49.248963, lng: 18.863003 },
    { lat: 49.248778, lng: 18.863073 },
    { lat: 49.248777, lng: 18.863074 },
    { lat: 49.248763, lng: 18.863063 },
    { lat: 49.24874, lng: 18.863044 },
    { lat: 49.248645, lng: 18.862982 },
    { lat: 49.248597, lng: 18.862946 },
    { lat: 49.248595, lng: 18.862943 },
    { lat: 49.248584, lng: 18.862935 },
    { lat: 49.248453, lng: 18.862814 },
    { lat: 49.248463, lng: 18.862796 },
    { lat: 49.248488, lng: 18.862733 },
    { lat: 49.248511, lng: 18.86214 },
    { lat: 49.248348, lng: 18.861396 },
    { lat: 49.248287, lng: 18.861116 },
    { lat: 49.248427, lng: 18.860407 },
    { lat: 49.248428, lng: 18.860401 },
    { lat: 49.248598, lng: 18.860441 },
    { lat: 49.248669, lng: 18.860458 },
    { lat: 49.248729, lng: 18.860184 },
    { lat: 49.248752, lng: 18.860092 },
    { lat: 49.248879, lng: 18.859526 },
    { lat: 49.248935, lng: 18.85927 },
    { lat: 49.249032, lng: 18.85886 },
    { lat: 49.24904, lng: 18.85881 },
    { lat: 49.249048, lng: 18.858763 },
    { lat: 49.249581, lng: 18.858867 },
    { lat: 49.249596, lng: 18.858685 },
    { lat: 49.249683, lng: 18.857753 },
    { lat: 49.249696, lng: 18.857664 },
    { lat: 49.249754, lng: 18.857137 },
    { lat: 49.249833, lng: 18.856457 },
    { lat: 49.2499, lng: 18.855949 },
    { lat: 49.2499, lng: 18.855946 },
    { lat: 49.250276, lng: 18.856035 },
    { lat: 49.2501928, lng: 18.8557718 },
    { lat: 49.2495588, lng: 18.8554385 },
    { lat: 49.2492239, lng: 18.8554982 },
    { lat: 49.2485209, lng: 18.8554451 },
    { lat: 49.2479918, lng: 18.8555264 },
    { lat: 49.2479626, lng: 18.85575 },
    { lat: 49.2467998, lng: 18.8557659 },
    { lat: 49.2460082, lng: 18.8556934 },
    { lat: 49.2460189, lng: 18.8553111 },
    { lat: 49.2451954, lng: 18.8553005 },
    { lat: 49.2442743, lng: 18.8540003 },
    { lat: 49.2440621, lng: 18.8530095 },
    { lat: 49.2437979, lng: 18.8516799 },
    { lat: 49.2437204, lng: 18.850787 },
    { lat: 49.243674, lng: 18.8502549 },
    { lat: 49.243654, lng: 18.8499112 },
    { lat: 49.2436101, lng: 18.8496447 },
    { lat: 49.2429703, lng: 18.849285 },
    { lat: 49.2424369, lng: 18.8477665 },
    { lat: 49.2423864, lng: 18.8472431 },
    { lat: 49.2424556, lng: 18.8469895 },
    { lat: 49.2426987, lng: 18.8465371 },
    { lat: 49.2428712, lng: 18.8459023 },
    { lat: 49.242667, lng: 18.8453068 },
    { lat: 49.242565, lng: 18.8449448 },
    { lat: 49.242457, lng: 18.8445106 },
    { lat: 49.2420755, lng: 18.8436888 },
    { lat: 49.2419251, lng: 18.8433088 },
    { lat: 49.241787, lng: 18.8421107 },
    { lat: 49.2417776, lng: 18.8420382 },
    { lat: 49.2417819, lng: 18.842011 },
    { lat: 49.2417861, lng: 18.8419827 },
    { lat: 49.2417884, lng: 18.8419618 },
    { lat: 49.2417934, lng: 18.8419266 },
    { lat: 49.2417976, lng: 18.8418986 },
    { lat: 49.2418133, lng: 18.8417672 },
    { lat: 49.2418192, lng: 18.8417297 },
    { lat: 49.2418243, lng: 18.8416945 },
    { lat: 49.2418773, lng: 18.8413006 },
    { lat: 49.2418547, lng: 18.8412102 },
    { lat: 49.2418165, lng: 18.8410596 },
    { lat: 49.2416282, lng: 18.8403157 },
    { lat: 49.2411404, lng: 18.8401013 },
    { lat: 49.2406313, lng: 18.8400472 },
    { lat: 49.2404166, lng: 18.8398817 },
    { lat: 49.2399981, lng: 18.8393289 },
    { lat: 49.2394748, lng: 18.8388471 },
    { lat: 49.2392893, lng: 18.8385705 },
    { lat: 49.2390885, lng: 18.8381757 },
    { lat: 49.2384048, lng: 18.83755 },
    { lat: 49.2380238, lng: 18.8371565 },
    { lat: 49.2374476, lng: 18.8365191 },
    { lat: 49.2367922, lng: 18.8361423 },
    { lat: 49.2363566, lng: 18.8358269 },
    { lat: 49.2360097, lng: 18.8355033 },
    { lat: 49.2357368, lng: 18.8352192 },
    { lat: 49.2355207, lng: 18.8349403 },
    { lat: 49.2352453, lng: 18.8345825 },
    { lat: 49.2351992, lng: 18.8345231 },
    { lat: 49.2349621, lng: 18.8341619 },
    { lat: 49.2344309, lng: 18.8335906 },
    { lat: 49.2337837, lng: 18.8330206 },
    { lat: 49.2333962, lng: 18.8326972 },
    { lat: 49.2326177, lng: 18.8322075 },
    { lat: 49.2323171, lng: 18.8321725 },
    { lat: 49.2322411, lng: 18.8313536 },
    { lat: 49.2321478, lng: 18.8307656 },
  ],
  NezbudskáLúčka: [
    { lat: 49.168029, lng: 18.956059 },
    { lat: 49.168922, lng: 18.956047 },
    { lat: 49.169469, lng: 18.956004 },
    { lat: 49.169802, lng: 18.955832 },
    { lat: 49.170031, lng: 18.955628 },
    { lat: 49.170204, lng: 18.955634 },
    { lat: 49.170689, lng: 18.955651 },
    { lat: 49.171111, lng: 18.956105 },
    { lat: 49.171115, lng: 18.956109 },
    { lat: 49.171116, lng: 18.95611 },
    { lat: 49.17145, lng: 18.956382 },
    { lat: 49.171452, lng: 18.956384 },
    { lat: 49.1730905, lng: 18.9529038 },
    { lat: 49.1739023, lng: 18.9519005 },
    { lat: 49.1740531, lng: 18.9516709 },
    { lat: 49.1741698, lng: 18.9513436 },
    { lat: 49.1746905, lng: 18.9503613 },
    { lat: 49.1749517, lng: 18.9498874 },
    { lat: 49.1754525, lng: 18.9485959 },
    { lat: 49.1760883, lng: 18.9471411 },
    { lat: 49.1762336, lng: 18.9467626 },
    { lat: 49.1763894, lng: 18.945806 },
    { lat: 49.1765319, lng: 18.9445457 },
    { lat: 49.1764223, lng: 18.9415487 },
    { lat: 49.1762194, lng: 18.9402324 },
    { lat: 49.1764018, lng: 18.9391822 },
    { lat: 49.1767081, lng: 18.9383948 },
    { lat: 49.1769371, lng: 18.9380187 },
    { lat: 49.1770967, lng: 18.9377632 },
    { lat: 49.1780029, lng: 18.9372025 },
    { lat: 49.1771758, lng: 18.9356707 },
    { lat: 49.1767539, lng: 18.9349114 },
    { lat: 49.1766914, lng: 18.9342312 },
    { lat: 49.1766425, lng: 18.9336669 },
    { lat: 49.1767255, lng: 18.9331033 },
    { lat: 49.1760176, lng: 18.9319028 },
    { lat: 49.175768, lng: 18.9314944 },
    { lat: 49.1753424, lng: 18.9303911 },
    { lat: 49.1750439, lng: 18.9294609 },
    { lat: 49.1748824, lng: 18.9291232 },
    { lat: 49.1744925, lng: 18.9282111 },
    { lat: 49.1744421, lng: 18.9280657 },
    { lat: 49.1743717, lng: 18.9278552 },
    { lat: 49.1741369, lng: 18.9268675 },
    { lat: 49.1739887, lng: 18.9257474 },
    { lat: 49.1737567, lng: 18.924817 },
    { lat: 49.1735435, lng: 18.9237709 },
    { lat: 49.17328, lng: 18.9226 },
    { lat: 49.1727554, lng: 18.9217034 },
    { lat: 49.1727355, lng: 18.9215573 },
    { lat: 49.1726473, lng: 18.9208615 },
    { lat: 49.1722586, lng: 18.9188687 },
    { lat: 49.1722664, lng: 18.918087 },
    { lat: 49.1724913, lng: 18.9167225 },
    { lat: 49.1726419, lng: 18.9156688 },
    { lat: 49.1728853, lng: 18.9148473 },
    { lat: 49.1730751, lng: 18.9139882 },
    { lat: 49.1732479, lng: 18.9133953 },
    { lat: 49.1738722, lng: 18.9124113 },
    { lat: 49.1747815, lng: 18.911159 },
    { lat: 49.1749932, lng: 18.9107743 },
    { lat: 49.1751452, lng: 18.9100602 },
    { lat: 49.1754876, lng: 18.9084583 },
    { lat: 49.1756736, lng: 18.9080371 },
    { lat: 49.1761733, lng: 18.9075535 },
    { lat: 49.1765346, lng: 18.9064769 },
    { lat: 49.176753, lng: 18.9060297 },
    { lat: 49.1770297, lng: 18.9042655 },
    { lat: 49.1775566, lng: 18.9028312 },
    { lat: 49.177999, lng: 18.901622 },
    { lat: 49.1782829, lng: 18.9011527 },
    { lat: 49.1784674, lng: 18.9007669 },
    { lat: 49.1786026, lng: 18.9003696 },
    { lat: 49.178888, lng: 18.8983563 },
    { lat: 49.1786627, lng: 18.8969011 },
    { lat: 49.178811, lng: 18.8960929 },
    { lat: 49.1787944, lng: 18.8958545 },
    { lat: 49.1786281, lng: 18.8952475 },
    { lat: 49.1784505, lng: 18.8940206 },
    { lat: 49.1785423, lng: 18.8933643 },
    { lat: 49.1785772, lng: 18.8929641 },
    { lat: 49.1784309, lng: 18.8923061 },
    { lat: 49.1783106, lng: 18.891627 },
    { lat: 49.1782287, lng: 18.8911711 },
    { lat: 49.1783664, lng: 18.8910863 },
    { lat: 49.1788991, lng: 18.89052 },
    { lat: 49.1789193, lng: 18.8905 },
    { lat: 49.178844, lng: 18.8901896 },
    { lat: 49.1788733, lng: 18.8901713 },
    { lat: 49.1788294, lng: 18.8888669 },
    { lat: 49.1786952, lng: 18.8886014 },
    { lat: 49.1786012, lng: 18.8885711 },
    { lat: 49.1785987, lng: 18.8881458 },
    { lat: 49.1783619, lng: 18.8876593 },
    { lat: 49.1783143, lng: 18.887657 },
    { lat: 49.178095, lng: 18.8871137 },
    { lat: 49.1778754, lng: 18.8868976 },
    { lat: 49.1777146, lng: 18.8865066 },
    { lat: 49.1776038, lng: 18.8863262 },
    { lat: 49.1774907, lng: 18.8859666 },
    { lat: 49.1771903, lng: 18.8855151 },
    { lat: 49.1770135, lng: 18.8853184 },
    { lat: 49.1767749, lng: 18.8852372 },
    { lat: 49.1767625, lng: 18.8849511 },
    { lat: 49.1767764, lng: 18.8845804 },
    { lat: 49.1768738, lng: 18.8841051 },
    { lat: 49.1768876, lng: 18.8839132 },
    { lat: 49.1768711, lng: 18.8835663 },
    { lat: 49.176908, lng: 18.8832765 },
    { lat: 49.1769664, lng: 18.8830501 },
    { lat: 49.1770795, lng: 18.8829466 },
    { lat: 49.1773332, lng: 18.8827016 },
    { lat: 49.1773496, lng: 18.8825822 },
    { lat: 49.1774195, lng: 18.8822489 },
    { lat: 49.177515, lng: 18.8819582 },
    { lat: 49.1774558, lng: 18.8815941 },
    { lat: 49.1773284, lng: 18.8813717 },
    { lat: 49.1773214, lng: 18.8811045 },
    { lat: 49.1775278, lng: 18.8808633 },
    { lat: 49.1787576, lng: 18.8806563 },
    { lat: 49.1794881, lng: 18.8808953 },
    { lat: 49.179504, lng: 18.8807687 },
    { lat: 49.1795373, lng: 18.8804941 },
    { lat: 49.179863, lng: 18.880446 },
    { lat: 49.1798637, lng: 18.8806131 },
    { lat: 49.1801103, lng: 18.8806959 },
    { lat: 49.1802487, lng: 18.8804729 },
    { lat: 49.1801667, lng: 18.8804301 },
    { lat: 49.1801941, lng: 18.8803043 },
    { lat: 49.1803335, lng: 18.8803198 },
    { lat: 49.1805724, lng: 18.8798117 },
    { lat: 49.1804393, lng: 18.8796762 },
    { lat: 49.1799248, lng: 18.879626 },
    { lat: 49.1797739, lng: 18.8795635 },
    { lat: 49.1792947, lng: 18.8796731 },
    { lat: 49.1786542, lng: 18.8797377 },
    { lat: 49.1782352, lng: 18.87979 },
    { lat: 49.1780616, lng: 18.8797909 },
    { lat: 49.1778723, lng: 18.8798602 },
    { lat: 49.1778818, lng: 18.8797596 },
    { lat: 49.1780015, lng: 18.8795158 },
    { lat: 49.1781786, lng: 18.8791441 },
    { lat: 49.1783715, lng: 18.8788844 },
    { lat: 49.1786831, lng: 18.8785133 },
    { lat: 49.1789513, lng: 18.8781581 },
    { lat: 49.1789836, lng: 18.8780408 },
    { lat: 49.1789612, lng: 18.877802 },
    { lat: 49.1790409, lng: 18.8769993 },
    { lat: 49.1791509, lng: 18.8761505 },
    { lat: 49.1791572, lng: 18.8760755 },
    { lat: 49.1792049, lng: 18.8760835 },
    { lat: 49.1798883, lng: 18.8764844 },
    { lat: 49.1803484, lng: 18.876726 },
    { lat: 49.1805202, lng: 18.876228 },
    { lat: 49.1804994, lng: 18.8761079 },
    { lat: 49.1802434, lng: 18.8755135 },
    { lat: 49.1802362, lng: 18.8753738 },
    { lat: 49.1802738, lng: 18.8750507 },
    { lat: 49.1805855, lng: 18.8750144 },
    { lat: 49.1806801, lng: 18.8751685 },
    { lat: 49.1807672, lng: 18.8755041 },
    { lat: 49.1809458, lng: 18.8758419 },
    { lat: 49.181046, lng: 18.8757315 },
    { lat: 49.1811111, lng: 18.8754604 },
    { lat: 49.1813249, lng: 18.8753643 },
    { lat: 49.1814506, lng: 18.8752203 },
    { lat: 49.1820204, lng: 18.8749651 },
    { lat: 49.1821537, lng: 18.8747034 },
    { lat: 49.182186, lng: 18.8746578 },
    { lat: 49.1822736, lng: 18.8745339 },
    { lat: 49.1829799, lng: 18.8738389 },
    { lat: 49.1833472, lng: 18.8733703 },
    { lat: 49.1833256, lng: 18.8733224 },
    { lat: 49.1835106, lng: 18.8731277 },
    { lat: 49.1834991, lng: 18.8721516 },
    { lat: 49.1833726, lng: 18.8717749 },
    { lat: 49.1834381, lng: 18.8717988 },
    { lat: 49.1834468, lng: 18.8717928 },
    { lat: 49.1837309, lng: 18.8717907 },
    { lat: 49.1839537, lng: 18.8719389 },
    { lat: 49.1843244, lng: 18.8715369 },
    { lat: 49.1844116, lng: 18.8713889 },
    { lat: 49.1844394, lng: 18.8712731 },
    { lat: 49.1844362, lng: 18.8712284 },
    { lat: 49.1844113, lng: 18.8711625 },
    { lat: 49.1842993, lng: 18.8709776 },
    { lat: 49.1844627, lng: 18.8710631 },
    { lat: 49.1845052, lng: 18.8710811 },
    { lat: 49.1846777, lng: 18.8710998 },
    { lat: 49.1847705, lng: 18.8711555 },
    { lat: 49.1850338, lng: 18.8714525 },
    { lat: 49.1853205, lng: 18.8715892 },
    { lat: 49.1857137, lng: 18.8717604 },
    { lat: 49.1858532, lng: 18.8718 },
    { lat: 49.1859829, lng: 18.8717352 },
    { lat: 49.1860914, lng: 18.8718045 },
    { lat: 49.186099, lng: 18.8717776 },
    { lat: 49.1861116, lng: 18.8716266 },
    { lat: 49.186157, lng: 18.8713869 },
    { lat: 49.1862599, lng: 18.8709554 },
    { lat: 49.1865416, lng: 18.8703102 },
    { lat: 49.1864782, lng: 18.8702403 },
    { lat: 49.1863795, lng: 18.8700985 },
    { lat: 49.1863094, lng: 18.8700066 },
    { lat: 49.186195, lng: 18.8698865 },
    { lat: 49.1858621, lng: 18.8695261 },
    { lat: 49.1855213, lng: 18.8691212 },
    { lat: 49.1851085, lng: 18.8686127 },
    { lat: 49.1848673, lng: 18.8683081 },
    { lat: 49.1845717, lng: 18.8679526 },
    { lat: 49.1840004, lng: 18.8672965 },
    { lat: 49.1836286, lng: 18.8670165 },
    { lat: 49.1831482, lng: 18.866661 },
    { lat: 49.183108, lng: 18.8666276 },
    { lat: 49.1830048, lng: 18.8665498 },
    { lat: 49.1827452, lng: 18.8663666 },
    { lat: 49.1825027, lng: 18.8661897 },
    { lat: 49.1822491, lng: 18.8661226 },
    { lat: 49.1819924, lng: 18.8660656 },
    { lat: 49.1817917, lng: 18.8660203 },
    { lat: 49.1816696, lng: 18.8659902 },
    { lat: 49.1815845, lng: 18.8659618 },
    { lat: 49.1814538, lng: 18.8658959 },
    { lat: 49.1810187, lng: 18.8656526 },
    { lat: 49.1805073, lng: 18.8654007 },
    { lat: 49.1798339, lng: 18.8650409 },
    { lat: 49.1795245, lng: 18.8648774 },
    { lat: 49.1795037, lng: 18.8648672 },
    { lat: 49.178894, lng: 18.8646991 },
    { lat: 49.1784311, lng: 18.8645957 },
    { lat: 49.1781388, lng: 18.864548 },
    { lat: 49.1777894, lng: 18.8644841 },
    { lat: 49.1774898, lng: 18.8644888 },
    { lat: 49.1772879, lng: 18.8644447 },
    { lat: 49.1771463, lng: 18.8644115 },
    { lat: 49.1770034, lng: 18.8643695 },
    { lat: 49.1767132, lng: 18.8642775 },
    { lat: 49.1758938, lng: 18.8641939 },
    { lat: 49.1754963, lng: 18.8641591 },
    { lat: 49.1745873, lng: 18.8640004 },
    { lat: 49.1745116, lng: 18.863906 },
    { lat: 49.174106, lng: 18.8638697 },
    { lat: 49.1734744, lng: 18.8638639 },
    { lat: 49.1728304, lng: 18.864146 },
    { lat: 49.1725204, lng: 18.8643451 },
    { lat: 49.1722801, lng: 18.8647172 },
    { lat: 49.1716835, lng: 18.8660368 },
    { lat: 49.1711196, lng: 18.8674228 },
    { lat: 49.1708486, lng: 18.8682943 },
    { lat: 49.1706397, lng: 18.8702264 },
    { lat: 49.1705623, lng: 18.8710038 },
    { lat: 49.170538, lng: 18.8727323 },
    { lat: 49.1705556, lng: 18.8750622 },
    { lat: 49.1706366, lng: 18.8764254 },
    { lat: 49.1706785, lng: 18.8768013 },
    { lat: 49.1707095, lng: 18.8770787 },
    { lat: 49.1710684, lng: 18.8790729 },
    { lat: 49.1714369, lng: 18.8803479 },
    { lat: 49.1718877, lng: 18.8815768 },
    { lat: 49.1719463, lng: 18.8817349 },
    { lat: 49.1720119, lng: 18.8819141 },
    { lat: 49.1722687, lng: 18.8826168 },
    { lat: 49.1726452, lng: 18.8835865 },
    { lat: 49.1728186, lng: 18.8838448 },
    { lat: 49.173025, lng: 18.8841118 },
    { lat: 49.1733317, lng: 18.8848202 },
    { lat: 49.1738741, lng: 18.8855928 },
    { lat: 49.1750284, lng: 18.8871793 },
    { lat: 49.1752705, lng: 18.8875946 },
    { lat: 49.1759763, lng: 18.8887861 },
    { lat: 49.1762463, lng: 18.8894157 },
    { lat: 49.1763973, lng: 18.8901433 },
    { lat: 49.1765555, lng: 18.8909246 },
    { lat: 49.1766564, lng: 18.8918236 },
    { lat: 49.1765442, lng: 18.8926499 },
    { lat: 49.1764142, lng: 18.8934829 },
    { lat: 49.1762967, lng: 18.8940089 },
    { lat: 49.1762018, lng: 18.894316 },
    { lat: 49.1759193, lng: 18.8949668 },
    { lat: 49.1754995, lng: 18.8955153 },
    { lat: 49.1746492, lng: 18.8965076 },
    { lat: 49.1745626, lng: 18.8965972 },
    { lat: 49.1739856, lng: 18.8968468 },
    { lat: 49.1734441, lng: 18.8969056 },
    { lat: 49.1729597, lng: 18.8968023 },
    { lat: 49.1721217, lng: 18.8964465 },
    { lat: 49.1714841, lng: 18.8960767 },
    { lat: 49.1702905, lng: 18.8951577 },
    { lat: 49.1692736, lng: 18.8943826 },
    { lat: 49.1689822, lng: 18.8941072 },
    { lat: 49.1685224, lng: 18.8936696 },
    { lat: 49.1679474, lng: 18.8929624 },
    { lat: 49.1677456, lng: 18.892697 },
    { lat: 49.1671495, lng: 18.8917517 },
    { lat: 49.1666861, lng: 18.890957 },
    { lat: 49.1656677, lng: 18.888718 },
    { lat: 49.1654278, lng: 18.8877953 },
    { lat: 49.1652721, lng: 18.885628 },
    { lat: 49.1655491, lng: 18.8847372 },
    { lat: 49.1657689, lng: 18.8829893 },
    { lat: 49.1658817, lng: 18.8817992 },
    { lat: 49.1659582, lng: 18.8808923 },
    { lat: 49.1659121, lng: 18.8803876 },
    { lat: 49.1658751, lng: 18.8802371 },
    { lat: 49.1658027, lng: 18.8801228 },
    { lat: 49.1656493, lng: 18.8798773 },
    { lat: 49.1652768, lng: 18.8794954 },
    { lat: 49.1652032, lng: 18.8794652 },
    { lat: 49.1648252, lng: 18.8795062 },
    { lat: 49.1643765, lng: 18.8796816 },
    { lat: 49.1640365, lng: 18.8798009 },
    { lat: 49.1636207, lng: 18.8800705 },
    { lat: 49.163079, lng: 18.8807235 },
    { lat: 49.1627875, lng: 18.8812823 },
    { lat: 49.1625891, lng: 18.8819688 },
    { lat: 49.1627953, lng: 18.8834473 },
    { lat: 49.1625699, lng: 18.8843077 },
    { lat: 49.1625595, lng: 18.8843259 },
    { lat: 49.1624314, lng: 18.8845328 },
    { lat: 49.1623044, lng: 18.8847395 },
    { lat: 49.1621951, lng: 18.8849175 },
    { lat: 49.1610959, lng: 18.8863305 },
    { lat: 49.1605431, lng: 18.8872106 },
    { lat: 49.1604937, lng: 18.8873454 },
    { lat: 49.1601499, lng: 18.8876586 },
    { lat: 49.1598875, lng: 18.8877294 },
    { lat: 49.1589932, lng: 18.8877004 },
    { lat: 49.1587182, lng: 18.8875057 },
    { lat: 49.1582277, lng: 18.8872847 },
    { lat: 49.1580621, lng: 18.8871315 },
    { lat: 49.1570287, lng: 18.8862559 },
    { lat: 49.15683, lng: 18.8861221 },
    { lat: 49.1560453, lng: 18.8857884 },
    { lat: 49.1553893, lng: 18.8855954 },
    { lat: 49.1551189, lng: 18.8855554 },
    { lat: 49.1544009, lng: 18.8855376 },
    { lat: 49.1539545, lng: 18.8856046 },
    { lat: 49.1533586, lng: 18.8859767 },
    { lat: 49.153539, lng: 18.886584 },
    { lat: 49.153552, lng: 18.886571 },
    { lat: 49.15357, lng: 18.886672 },
    { lat: 49.15361, lng: 18.886931 },
    { lat: 49.153718, lng: 18.886934 },
    { lat: 49.153828, lng: 18.887012 },
    { lat: 49.153907, lng: 18.887375 },
    { lat: 49.153922, lng: 18.887442 },
    { lat: 49.153927, lng: 18.88746 },
    { lat: 49.15404, lng: 18.887846 },
    { lat: 49.154265, lng: 18.888122 },
    { lat: 49.154216, lng: 18.888307 },
    { lat: 49.154527, lng: 18.888532 },
    { lat: 49.154652, lng: 18.888547 },
    { lat: 49.154723, lng: 18.888616 },
    { lat: 49.154781, lng: 18.888714 },
    { lat: 49.154804, lng: 18.888755 },
    { lat: 49.154863, lng: 18.888867 },
    { lat: 49.155021, lng: 18.889248 },
    { lat: 49.155077, lng: 18.889384 },
    { lat: 49.155098, lng: 18.889448 },
    { lat: 49.155283, lng: 18.890053 },
    { lat: 49.155379, lng: 18.890088 },
    { lat: 49.155448, lng: 18.890083 },
    { lat: 49.155584, lng: 18.890159 },
    { lat: 49.155625, lng: 18.890223 },
    { lat: 49.155649, lng: 18.890323 },
    { lat: 49.155653, lng: 18.890542 },
    { lat: 49.15568, lng: 18.890627 },
    { lat: 49.155707, lng: 18.890686 },
    { lat: 49.155799, lng: 18.890846 },
    { lat: 49.155777, lng: 18.891029 },
    { lat: 49.155781, lng: 18.891124 },
    { lat: 49.155848, lng: 18.891401 },
    { lat: 49.15605, lng: 18.891671 },
    { lat: 49.156216, lng: 18.891813 },
    { lat: 49.156331, lng: 18.892013 },
    { lat: 49.15633, lng: 18.892085 },
    { lat: 49.156329, lng: 18.892303 },
    { lat: 49.156374, lng: 18.892454 },
    { lat: 49.156556, lng: 18.892472 },
    { lat: 49.156702, lng: 18.892542 },
    { lat: 49.156766, lng: 18.892686 },
    { lat: 49.157078, lng: 18.892663 },
    { lat: 49.15734, lng: 18.892874 },
    { lat: 49.157423, lng: 18.892951 },
    { lat: 49.157567, lng: 18.893109 },
    { lat: 49.157806, lng: 18.89354 },
    { lat: 49.157914, lng: 18.893591 },
    { lat: 49.158011, lng: 18.893908 },
    { lat: 49.158129, lng: 18.89416 },
    { lat: 49.158515, lng: 18.894614 },
    { lat: 49.158763, lng: 18.894726 },
    { lat: 49.158903, lng: 18.895116 },
    { lat: 49.158995, lng: 18.895208 },
    { lat: 49.159145, lng: 18.895104 },
    { lat: 49.159297, lng: 18.895158 },
    { lat: 49.159359, lng: 18.895232 },
    { lat: 49.159426, lng: 18.895406 },
    { lat: 49.159542, lng: 18.89551 },
    { lat: 49.159705, lng: 18.895357 },
    { lat: 49.159817, lng: 18.89535 },
    { lat: 49.160381, lng: 18.895781 },
    { lat: 49.160581, lng: 18.895861 },
    { lat: 49.160685, lng: 18.895989 },
    { lat: 49.160989, lng: 18.896044 },
    { lat: 49.161196, lng: 18.896218 },
    { lat: 49.161354, lng: 18.896277 },
    { lat: 49.161551, lng: 18.896521 },
    { lat: 49.161624, lng: 18.896704 },
    { lat: 49.161749, lng: 18.896877 },
    { lat: 49.161771, lng: 18.897097 },
    { lat: 49.161748, lng: 18.89762 },
    { lat: 49.161463, lng: 18.898174 },
    { lat: 49.161132, lng: 18.898575 },
    { lat: 49.160935, lng: 18.898629 },
    { lat: 49.160729, lng: 18.898775 },
    { lat: 49.160643, lng: 18.898853 },
    { lat: 49.16031, lng: 18.898943 },
    { lat: 49.160003, lng: 18.899637 },
    { lat: 49.159989, lng: 18.899957 },
    { lat: 49.160033, lng: 18.900425 },
    { lat: 49.160033, lng: 18.900576 },
    { lat: 49.160146, lng: 18.900779 },
    { lat: 49.160126, lng: 18.90093 },
    { lat: 49.160173, lng: 18.901073 },
    { lat: 49.160407, lng: 18.901166 },
    { lat: 49.160574, lng: 18.901136 },
    { lat: 49.160641, lng: 18.901177 },
    { lat: 49.160703, lng: 18.901498 },
    { lat: 49.160716, lng: 18.901662 },
    { lat: 49.160587, lng: 18.901909 },
    { lat: 49.160542, lng: 18.902051 },
    { lat: 49.160402, lng: 18.902051 },
    { lat: 49.160328, lng: 18.902096 },
    { lat: 49.160281, lng: 18.902281 },
    { lat: 49.160156, lng: 18.90253 },
    { lat: 49.160066, lng: 18.902778 },
    { lat: 49.159545, lng: 18.903555 },
    { lat: 49.159317, lng: 18.904195 },
    { lat: 49.1592, lng: 18.904344 },
    { lat: 49.15913, lng: 18.904655 },
    { lat: 49.159135, lng: 18.90506 },
    { lat: 49.159087, lng: 18.905438 },
    { lat: 49.159066, lng: 18.905625 },
    { lat: 49.159014, lng: 18.905802 },
    { lat: 49.158993, lng: 18.906094 },
    { lat: 49.159011, lng: 18.90613 },
    { lat: 49.159048, lng: 18.906136 },
    { lat: 49.159058, lng: 18.906169 },
    { lat: 49.158987, lng: 18.90644 },
    { lat: 49.158994, lng: 18.906645 },
    { lat: 49.158914, lng: 18.90691 },
    { lat: 49.158946, lng: 18.907169 },
    { lat: 49.158905, lng: 18.907353 },
    { lat: 49.158866, lng: 18.907898 },
    { lat: 49.158926, lng: 18.90886 },
    { lat: 49.158978, lng: 18.908939 },
    { lat: 49.158981, lng: 18.909123 },
    { lat: 49.159045, lng: 18.909236 },
    { lat: 49.159389, lng: 18.909349 },
    { lat: 49.159486, lng: 18.909478 },
    { lat: 49.159655, lng: 18.909531 },
    { lat: 49.159842, lng: 18.910061 },
    { lat: 49.159957, lng: 18.910587 },
    { lat: 49.160036, lng: 18.910887 },
    { lat: 49.160068, lng: 18.911315 },
    { lat: 49.160125, lng: 18.911464 },
    { lat: 49.160175, lng: 18.911473 },
    { lat: 49.160204, lng: 18.911728 },
    { lat: 49.160266, lng: 18.911866 },
    { lat: 49.160288, lng: 18.912013 },
    { lat: 49.160303, lng: 18.912124 },
    { lat: 49.160314, lng: 18.912201 },
    { lat: 49.160378, lng: 18.91233 },
    { lat: 49.1604, lng: 18.9124 },
    { lat: 49.160412, lng: 18.912548 },
    { lat: 49.160427, lng: 18.912576 },
    { lat: 49.160471, lng: 18.912599 },
    { lat: 49.160622, lng: 18.912895 },
    { lat: 49.160651, lng: 18.91302 },
    { lat: 49.16075, lng: 18.913145 },
    { lat: 49.160799, lng: 18.913129 },
    { lat: 49.160932, lng: 18.913331 },
    { lat: 49.160957, lng: 18.913444 },
    { lat: 49.161097, lng: 18.913613 },
    { lat: 49.161241, lng: 18.913805 },
    { lat: 49.16135, lng: 18.913831 },
    { lat: 49.1615, lng: 18.913924 },
    { lat: 49.161593, lng: 18.914078 },
    { lat: 49.161929, lng: 18.914225 },
    { lat: 49.162129, lng: 18.914419 },
    { lat: 49.162239, lng: 18.914407 },
    { lat: 49.162447, lng: 18.914766 },
    { lat: 49.162738, lng: 18.914881 },
    { lat: 49.162934, lng: 18.914897 },
    { lat: 49.163176, lng: 18.91508 },
    { lat: 49.16345, lng: 18.91543 },
    { lat: 49.163501, lng: 18.915483 },
    { lat: 49.164007, lng: 18.915946 },
    { lat: 49.164114, lng: 18.916114 },
    { lat: 49.164203, lng: 18.916132 },
    { lat: 49.164332, lng: 18.916247 },
    { lat: 49.164409, lng: 18.916215 },
    { lat: 49.164423, lng: 18.916209 },
    { lat: 49.164939, lng: 18.916957 },
    { lat: 49.164996, lng: 18.916964 },
    { lat: 49.165105, lng: 18.917194 },
    { lat: 49.165093, lng: 18.91736 },
    { lat: 49.16517, lng: 18.917528 },
    { lat: 49.165195, lng: 18.917599 },
    { lat: 49.165329, lng: 18.918016 },
    { lat: 49.165437, lng: 18.918344 },
    { lat: 49.165531, lng: 18.918662 },
    { lat: 49.165642, lng: 18.918825 },
    { lat: 49.165756, lng: 18.91909 },
    { lat: 49.165745, lng: 18.919242 },
    { lat: 49.165899, lng: 18.919726 },
    { lat: 49.165982, lng: 18.920299 },
    { lat: 49.165911, lng: 18.920796 },
    { lat: 49.165487, lng: 18.921311 },
    { lat: 49.165414, lng: 18.921606 },
    { lat: 49.165446, lng: 18.921957 },
    { lat: 49.165348, lng: 18.923019 },
    { lat: 49.165391, lng: 18.923123 },
    { lat: 49.165335, lng: 18.923375 },
    { lat: 49.165322, lng: 18.923742 },
    { lat: 49.165295, lng: 18.92387 },
    { lat: 49.165288, lng: 18.924568 },
    { lat: 49.165308, lng: 18.924566 },
    { lat: 49.1654, lng: 18.925126 },
    { lat: 49.165724, lng: 18.925705 },
    { lat: 49.165764, lng: 18.92608 },
    { lat: 49.165997, lng: 18.926293 },
    { lat: 49.166279, lng: 18.926081 },
    { lat: 49.166562, lng: 18.926268 },
    { lat: 49.166864, lng: 18.9267 },
    { lat: 49.167635, lng: 18.92707 },
    { lat: 49.167827, lng: 18.92735 },
    { lat: 49.167996, lng: 18.927342 },
    { lat: 49.168019, lng: 18.927788 },
    { lat: 49.16796, lng: 18.928184 },
    { lat: 49.168037, lng: 18.92859 },
    { lat: 49.168112, lng: 18.928964 },
    { lat: 49.168145, lng: 18.929302 },
    { lat: 49.168281, lng: 18.929897 },
    { lat: 49.168497, lng: 18.930067 },
    { lat: 49.168622, lng: 18.93046 },
    { lat: 49.168627, lng: 18.930647 },
    { lat: 49.168746, lng: 18.930976 },
    { lat: 49.169002, lng: 18.931365 },
    { lat: 49.169063, lng: 18.931696 },
    { lat: 49.169219, lng: 18.93192 },
    { lat: 49.169331, lng: 18.932276 },
    { lat: 49.169333, lng: 18.932744 },
    { lat: 49.169322, lng: 18.932841 },
    { lat: 49.169317, lng: 18.932969 },
    { lat: 49.16931, lng: 18.933124 },
    { lat: 49.16939, lng: 18.933201 },
    { lat: 49.169441, lng: 18.933589 },
    { lat: 49.169438, lng: 18.933863 },
    { lat: 49.169329, lng: 18.933975 },
    { lat: 49.169116, lng: 18.934907 },
    { lat: 49.168749, lng: 18.935647 },
    { lat: 49.168551, lng: 18.936425 },
    { lat: 49.168372, lng: 18.936713 },
    { lat: 49.167943, lng: 18.937399 },
    { lat: 49.167275, lng: 18.93799 },
    { lat: 49.165949, lng: 18.939943 },
    { lat: 49.165236, lng: 18.940918 },
    { lat: 49.16512, lng: 18.941675 },
    { lat: 49.164683, lng: 18.942959 },
    { lat: 49.16453, lng: 18.944738 },
    { lat: 49.164682, lng: 18.946371 },
    { lat: 49.165192, lng: 18.947196 },
    { lat: 49.165704, lng: 18.948173 },
    { lat: 49.166136, lng: 18.948909 },
    { lat: 49.166322, lng: 18.9497 },
    { lat: 49.166453, lng: 18.949924 },
    { lat: 49.166636, lng: 18.950653 },
    { lat: 49.167233, lng: 18.951625 },
    { lat: 49.167604, lng: 18.952144 },
    { lat: 49.167708, lng: 18.952428 },
    { lat: 49.167726, lng: 18.952761 },
    { lat: 49.167808, lng: 18.952867 },
    { lat: 49.168317, lng: 18.954182 },
    { lat: 49.168029, lng: 18.956059 },
  ],
  Stráža: [
    { lat: 49.2223213, lng: 18.9002622 },
    { lat: 49.2223257, lng: 18.900279 },
    { lat: 49.2223302, lng: 18.900303 },
    { lat: 49.2225401, lng: 18.9014147 },
    { lat: 49.2225462, lng: 18.9014709 },
    { lat: 49.2225298, lng: 18.9014779 },
    { lat: 49.2225127, lng: 18.9016062 },
    { lat: 49.2225059, lng: 18.9017596 },
    { lat: 49.2225123, lng: 18.9019316 },
    { lat: 49.2225111, lng: 18.9020857 },
    { lat: 49.2225101, lng: 18.9021125 },
    { lat: 49.222505, lng: 18.9022989 },
    { lat: 49.2225058, lng: 18.9024456 },
    { lat: 49.2225019, lng: 18.9025269 },
    { lat: 49.2225079, lng: 18.9026448 },
    { lat: 49.2224871, lng: 18.902811 },
    { lat: 49.2224571, lng: 18.9029524 },
    { lat: 49.2224388, lng: 18.9030786 },
    { lat: 49.2224211, lng: 18.9031305 },
    { lat: 49.2224093, lng: 18.903234 },
    { lat: 49.2224537, lng: 18.9033535 },
    { lat: 49.2224551, lng: 18.9033587 },
    { lat: 49.2224828, lng: 18.9034377 },
    { lat: 49.2224935, lng: 18.9035551 },
    { lat: 49.2224955, lng: 18.903706 },
    { lat: 49.2224752, lng: 18.9038426 },
    { lat: 49.2224563, lng: 18.9039815 },
    { lat: 49.2224804, lng: 18.9040324 },
    { lat: 49.2224842, lng: 18.904042 },
    { lat: 49.2225112, lng: 18.9041269 },
    { lat: 49.2225665, lng: 18.9042393 },
    { lat: 49.2225666, lng: 18.9042418 },
    { lat: 49.2225822, lng: 18.9043071 },
    { lat: 49.2225825, lng: 18.9044239 },
    { lat: 49.2225779, lng: 18.9044861 },
    { lat: 49.2226031, lng: 18.9046487 },
    { lat: 49.2226027, lng: 18.9046632 },
    { lat: 49.2226304, lng: 18.9047854 },
    { lat: 49.2226328, lng: 18.9047912 },
    { lat: 49.2226686, lng: 18.9048534 },
    { lat: 49.2226774, lng: 18.9048682 },
    { lat: 49.2227215, lng: 18.9049366 },
    { lat: 49.2227363, lng: 18.9049622 },
    { lat: 49.2228009, lng: 18.9050815 },
    { lat: 49.2228475, lng: 18.9052452 },
    { lat: 49.2228478, lng: 18.9052502 },
    { lat: 49.222848, lng: 18.9052541 },
    { lat: 49.2228865, lng: 18.9055663 },
    { lat: 49.2229003, lng: 18.9056606 },
    { lat: 49.2229005, lng: 18.9056649 },
    { lat: 49.222902, lng: 18.9056708 },
    { lat: 49.2229497, lng: 18.9058801 },
    { lat: 49.2228946, lng: 18.9061723 },
    { lat: 49.2228638, lng: 18.9064959 },
    { lat: 49.2228473, lng: 18.9065704 },
    { lat: 49.2228449, lng: 18.9067463 },
    { lat: 49.2228589, lng: 18.9068215 },
    { lat: 49.2228736, lng: 18.9068648 },
    { lat: 49.2229186, lng: 18.9069504 },
    { lat: 49.2229235, lng: 18.9069599 },
    { lat: 49.2228799, lng: 18.9071472 },
    { lat: 49.2228791, lng: 18.9071506 },
    { lat: 49.2228727, lng: 18.9071589 },
    { lat: 49.222843, lng: 18.9071985 },
    { lat: 49.2228291, lng: 18.9072113 },
    { lat: 49.2227032, lng: 18.9072644 },
    { lat: 49.2226922, lng: 18.9072697 },
    { lat: 49.2226892, lng: 18.9072766 },
    { lat: 49.2226393, lng: 18.907382 },
    { lat: 49.2226373, lng: 18.9074082 },
    { lat: 49.2226459, lng: 18.9074418 },
    { lat: 49.2226629, lng: 18.9074712 },
    { lat: 49.2227626, lng: 18.9076091 },
    { lat: 49.222771, lng: 18.9076218 },
    { lat: 49.2227654, lng: 18.9076647 },
    { lat: 49.2227629, lng: 18.9076859 },
    { lat: 49.2227327, lng: 18.9077558 },
    { lat: 49.2226421, lng: 18.9079263 },
    { lat: 49.2225223, lng: 18.9081154 },
    { lat: 49.2225179, lng: 18.9081221 },
    { lat: 49.2225127, lng: 18.9081263 },
    { lat: 49.2225093, lng: 18.9081296 },
    { lat: 49.222438, lng: 18.9081911 },
    { lat: 49.2224218, lng: 18.9082031 },
    { lat: 49.2223388, lng: 18.9082576 },
    { lat: 49.222329, lng: 18.9082646 },
    { lat: 49.2222814, lng: 18.9082392 },
    { lat: 49.2222664, lng: 18.9082316 },
    { lat: 49.2222628, lng: 18.9082486 },
    { lat: 49.2222584, lng: 18.9084511 },
    { lat: 49.2221275, lng: 18.9088038 },
    { lat: 49.2220643, lng: 18.9088023 },
    { lat: 49.2220504, lng: 18.908928 },
    { lat: 49.2220435, lng: 18.9089458 },
    { lat: 49.22205, lng: 18.9089627 },
    { lat: 49.222106, lng: 18.9091339 },
    { lat: 49.2220946, lng: 18.9091323 },
    { lat: 49.2220141, lng: 18.9091444 },
    { lat: 49.222007, lng: 18.9092267 },
    { lat: 49.2218205, lng: 18.9096622 },
    { lat: 49.2217819, lng: 18.909745 },
    { lat: 49.2217517, lng: 18.9098373 },
    { lat: 49.2217186, lng: 18.9099638 },
    { lat: 49.2217145, lng: 18.9100365 },
    { lat: 49.2217155, lng: 18.9101669 },
    { lat: 49.2217197, lng: 18.910209 },
    { lat: 49.221779, lng: 18.91051 },
    { lat: 49.2218257, lng: 18.9107836 },
    { lat: 49.2218726, lng: 18.9110424 },
    { lat: 49.2220995, lng: 18.9123151 },
    { lat: 49.2221844, lng: 18.912875 },
    { lat: 49.2222156, lng: 18.9130888 },
    { lat: 49.2222191, lng: 18.9133819 },
    { lat: 49.2221825, lng: 18.9136842 },
    { lat: 49.2220352, lng: 18.9143577 },
    { lat: 49.2219736, lng: 18.9145446 },
    { lat: 49.2216111, lng: 18.9153521 },
    { lat: 49.2215694, lng: 18.9155078 },
    { lat: 49.2215326, lng: 18.9157156 },
    { lat: 49.2215329, lng: 18.9157456 },
    { lat: 49.2215351, lng: 18.9160099 },
    { lat: 49.2215388, lng: 18.9161945 },
    { lat: 49.2215289, lng: 18.916442 },
    { lat: 49.2214877, lng: 18.9165432 },
    { lat: 49.2212367, lng: 18.9170392 },
    { lat: 49.2210937, lng: 18.9173075 },
    { lat: 49.2204882, lng: 18.9184405 },
    { lat: 49.2204633, lng: 18.918486 },
    { lat: 49.2205023, lng: 18.9185182 },
    { lat: 49.2205164, lng: 18.918531 },
    { lat: 49.2206523, lng: 18.9186523 },
    { lat: 49.2206887, lng: 18.9189052 },
    { lat: 49.2207144, lng: 18.9196347 },
    { lat: 49.2207244, lng: 18.9197215 },
    { lat: 49.2207399, lng: 18.9198502 },
    { lat: 49.2209771, lng: 18.9203722 },
    { lat: 49.2210376, lng: 18.9207398 },
    { lat: 49.2210441, lng: 18.9207563 },
    { lat: 49.2213183, lng: 18.9216303 },
    { lat: 49.2214659, lng: 18.9220969 },
    { lat: 49.2215823, lng: 18.9225242 },
    { lat: 49.2219833, lng: 18.9232055 },
    { lat: 49.2221301, lng: 18.9234557 },
    { lat: 49.2223482, lng: 18.9239779 },
    { lat: 49.2221725, lng: 18.9242922 },
    { lat: 49.222268, lng: 18.9250434 },
    { lat: 49.2224074, lng: 18.9259438 },
    { lat: 49.2226071, lng: 18.9266412 },
    { lat: 49.2229546, lng: 18.927803 },
    { lat: 49.2230638, lng: 18.9281126 },
    { lat: 49.223077, lng: 18.9281482 },
    { lat: 49.2233005, lng: 18.9286246 },
    { lat: 49.223635, lng: 18.929352 },
    { lat: 49.2238755, lng: 18.9298048 },
    { lat: 49.2240237, lng: 18.9300823 },
    { lat: 49.2242276, lng: 18.9303436 },
    { lat: 49.2244771, lng: 18.9306622 },
    { lat: 49.224878, lng: 18.9311249 },
    { lat: 49.2250632, lng: 18.9314887 },
    { lat: 49.2250771, lng: 18.9315145 },
    { lat: 49.2251885, lng: 18.9317332 },
    { lat: 49.2254524, lng: 18.9320917 },
    { lat: 49.2257831, lng: 18.9318774 },
    { lat: 49.2257928, lng: 18.9318917 },
    { lat: 49.2258115, lng: 18.9318596 },
    { lat: 49.2258316, lng: 18.9318309 },
    { lat: 49.2258429, lng: 18.9317416 },
    { lat: 49.2258826, lng: 18.9317048 },
    { lat: 49.2259197, lng: 18.9317242 },
    { lat: 49.2259977, lng: 18.9316411 },
    { lat: 49.2259825, lng: 18.9316054 },
    { lat: 49.2259801, lng: 18.9315822 },
    { lat: 49.2260267, lng: 18.931546 },
    { lat: 49.2260272, lng: 18.9315333 },
    { lat: 49.2260268, lng: 18.931502 },
    { lat: 49.2260112, lng: 18.9314414 },
    { lat: 49.2261124, lng: 18.9314093 },
    { lat: 49.2261704, lng: 18.9312676 },
    { lat: 49.2262845, lng: 18.9311372 },
    { lat: 49.2263314, lng: 18.9311068 },
    { lat: 49.2263752, lng: 18.9311031 },
    { lat: 49.2265281, lng: 18.9310057 },
    { lat: 49.2265747, lng: 18.9308999 },
    { lat: 49.2266198, lng: 18.9308575 },
    { lat: 49.2266448, lng: 18.9308138 },
    { lat: 49.2266609, lng: 18.9307538 },
    { lat: 49.2267514, lng: 18.9306295 },
    { lat: 49.2267507, lng: 18.9305921 },
    { lat: 49.2267388, lng: 18.9304878 },
    { lat: 49.2267409, lng: 18.9304644 },
    { lat: 49.2267582, lng: 18.9304271 },
    { lat: 49.2267882, lng: 18.9304181 },
    { lat: 49.2268042, lng: 18.9304209 },
    { lat: 49.2268168, lng: 18.9304277 },
    { lat: 49.2268559, lng: 18.9303975 },
    { lat: 49.2268686, lng: 18.930405 },
    { lat: 49.2269037, lng: 18.9304496 },
    { lat: 49.226929, lng: 18.9304603 },
    { lat: 49.2269425, lng: 18.9304605 },
    { lat: 49.2271504, lng: 18.9303577 },
    { lat: 49.2272491, lng: 18.9303702 },
    { lat: 49.2273123, lng: 18.9302805 },
    { lat: 49.2273387, lng: 18.9302046 },
    { lat: 49.2273508, lng: 18.9301541 },
    { lat: 49.2273561, lng: 18.9301224 },
    { lat: 49.2273755, lng: 18.9300808 },
    { lat: 49.2273759, lng: 18.9300656 },
    { lat: 49.2273638, lng: 18.9299848 },
    { lat: 49.2273403, lng: 18.9299454 },
    { lat: 49.227342, lng: 18.9299336 },
    { lat: 49.2273611, lng: 18.9298661 },
    { lat: 49.227447, lng: 18.9298708 },
    { lat: 49.227576, lng: 18.9297206 },
    { lat: 49.2275844, lng: 18.9293972 },
    { lat: 49.227641, lng: 18.9293815 },
    { lat: 49.2276568, lng: 18.9293807 },
    { lat: 49.2277071, lng: 18.9293935 },
    { lat: 49.2277366, lng: 18.9294452 },
    { lat: 49.2277569, lng: 18.9294435 },
    { lat: 49.2277857, lng: 18.9294333 },
    { lat: 49.2278707, lng: 18.9293544 },
    { lat: 49.2279259, lng: 18.9293057 },
    { lat: 49.227941, lng: 18.9292963 },
    { lat: 49.227973, lng: 18.9292799 },
    { lat: 49.2280346, lng: 18.9291622 },
    { lat: 49.2280377, lng: 18.9291337 },
    { lat: 49.2279372, lng: 18.9291092 },
    { lat: 49.2279043, lng: 18.9290618 },
    { lat: 49.2278856, lng: 18.928958 },
    { lat: 49.2278616, lng: 18.9289085 },
    { lat: 49.2278603, lng: 18.9288831 },
    { lat: 49.227871, lng: 18.9288237 },
    { lat: 49.2279439, lng: 18.9287271 },
    { lat: 49.2279792, lng: 18.9287814 },
    { lat: 49.2279928, lng: 18.9287798 },
    { lat: 49.2280537, lng: 18.9287369 },
    { lat: 49.2281834, lng: 18.9285589 },
    { lat: 49.228269, lng: 18.9284665 },
    { lat: 49.2283044, lng: 18.9283866 },
    { lat: 49.2282988, lng: 18.9283469 },
    { lat: 49.2283803, lng: 18.9282615 },
    { lat: 49.2284142, lng: 18.9281951 },
    { lat: 49.22842, lng: 18.9281775 },
    { lat: 49.2284421, lng: 18.9279007 },
    { lat: 49.2284817, lng: 18.9278383 },
    { lat: 49.2284671, lng: 18.9277282 },
    { lat: 49.2284451, lng: 18.9276291 },
    { lat: 49.2284853, lng: 18.927532 },
    { lat: 49.2284702, lng: 18.9274111 },
    { lat: 49.2284857, lng: 18.9273152 },
    { lat: 49.2285623, lng: 18.9272708 },
    { lat: 49.2285501, lng: 18.9270951 },
    { lat: 49.228573, lng: 18.9270791 },
    { lat: 49.2285723, lng: 18.9270658 },
    { lat: 49.2285729, lng: 18.9270311 },
    { lat: 49.2284423, lng: 18.9261032 },
    { lat: 49.2284392, lng: 18.926061 },
    { lat: 49.2284549, lng: 18.9260819 },
    { lat: 49.2284694, lng: 18.9261018 },
    { lat: 49.2285161, lng: 18.9260447 },
    { lat: 49.228541, lng: 18.926005 },
    { lat: 49.2285852, lng: 18.9259623 },
    { lat: 49.2286238, lng: 18.9258993 },
    { lat: 49.2286937, lng: 18.9258564 },
    { lat: 49.2287104, lng: 18.9256975 },
    { lat: 49.2287109, lng: 18.9256837 },
    { lat: 49.2288309, lng: 18.9256497 },
    { lat: 49.2289205, lng: 18.9257026 },
    { lat: 49.228955, lng: 18.9257173 },
    { lat: 49.2289927, lng: 18.9257302 },
    { lat: 49.2290291, lng: 18.9256465 },
    { lat: 49.2290302, lng: 18.9256215 },
    { lat: 49.229017, lng: 18.9254492 },
    { lat: 49.2290173, lng: 18.9254355 },
    { lat: 49.2290253, lng: 18.9253927 },
    { lat: 49.2290999, lng: 18.9254163 },
    { lat: 49.2291207, lng: 18.9254719 },
    { lat: 49.2291266, lng: 18.9254767 },
    { lat: 49.2291754, lng: 18.925507 },
    { lat: 49.2291855, lng: 18.9254823 },
    { lat: 49.229199, lng: 18.925436 },
    { lat: 49.2292128, lng: 18.9253781 },
    { lat: 49.2292031, lng: 18.9252746 },
    { lat: 49.2291864, lng: 18.925211 },
    { lat: 49.2291863, lng: 18.9250559 },
    { lat: 49.2291687, lng: 18.9249739 },
    { lat: 49.2291545, lng: 18.9247826 },
    { lat: 49.2291985, lng: 18.924605 },
    { lat: 49.2292505, lng: 18.9244754 },
    { lat: 49.2293075, lng: 18.9242617 },
    { lat: 49.229318, lng: 18.92425 },
    { lat: 49.2294181, lng: 18.9242684 },
    { lat: 49.2294588, lng: 18.9242221 },
    { lat: 49.2294894, lng: 18.9241821 },
    { lat: 49.2295078, lng: 18.9241442 },
    { lat: 49.2295137, lng: 18.9241085 },
    { lat: 49.2294979, lng: 18.9240141 },
    { lat: 49.229469, lng: 18.9238461 },
    { lat: 49.2294562, lng: 18.9237928 },
    { lat: 49.2294835, lng: 18.9237077 },
    { lat: 49.2295013, lng: 18.9236396 },
    { lat: 49.22953, lng: 18.9235799 },
    { lat: 49.2295389, lng: 18.9235565 },
    { lat: 49.2295703, lng: 18.9234659 },
    { lat: 49.2296455, lng: 18.9233701 },
    { lat: 49.2297483, lng: 18.9232825 },
    { lat: 49.2298165, lng: 18.9232485 },
    { lat: 49.2299024, lng: 18.9232326 },
    { lat: 49.2299782, lng: 18.9231937 },
    { lat: 49.230066, lng: 18.9231469 },
    { lat: 49.2301641, lng: 18.9230577 },
    { lat: 49.2302183, lng: 18.9230369 },
    { lat: 49.2302778, lng: 18.9230086 },
    { lat: 49.2303111, lng: 18.9229758 },
    { lat: 49.2303583, lng: 18.92284 },
    { lat: 49.2304486, lng: 18.9224621 },
    { lat: 49.2304714, lng: 18.9224464 },
    { lat: 49.2305196, lng: 18.9223953 },
    { lat: 49.2305403, lng: 18.9223842 },
    { lat: 49.2310386, lng: 18.9213607 },
    { lat: 49.2311347, lng: 18.9211856 },
    { lat: 49.2318268, lng: 18.9197625 },
    { lat: 49.2318882, lng: 18.9196359 },
    { lat: 49.2311334, lng: 18.9178471 },
    { lat: 49.2309693, lng: 18.9174581 },
    { lat: 49.2304761, lng: 18.9166438 },
    { lat: 49.2304477, lng: 18.9166186 },
    { lat: 49.2300609, lng: 18.9163722 },
    { lat: 49.2301486, lng: 18.9163034 },
    { lat: 49.2303688, lng: 18.9161309 },
    { lat: 49.2303322, lng: 18.9160991 },
    { lat: 49.2303076, lng: 18.9160782 },
    { lat: 49.2305809, lng: 18.9160001 },
    { lat: 49.2306603, lng: 18.9159672 },
    { lat: 49.2308482, lng: 18.9158902 },
    { lat: 49.2308826, lng: 18.9158605 },
    { lat: 49.2309751, lng: 18.9157691 },
    { lat: 49.2310492, lng: 18.9157434 },
    { lat: 49.2310538, lng: 18.9157425 },
    { lat: 49.2311226, lng: 18.9157232 },
    { lat: 49.2311932, lng: 18.9156903 },
    { lat: 49.2312664, lng: 18.9156427 },
    { lat: 49.2313361, lng: 18.9155309 },
    { lat: 49.2314272, lng: 18.9154368 },
    { lat: 49.2314724, lng: 18.9153957 },
    { lat: 49.2316697, lng: 18.9153039 },
    { lat: 49.2317632, lng: 18.9153033 },
    { lat: 49.2317767, lng: 18.9153028 },
    { lat: 49.231798, lng: 18.9153002 },
    { lat: 49.2318507, lng: 18.9152716 },
    { lat: 49.2318778, lng: 18.9152515 },
    { lat: 49.2319469, lng: 18.9151697 },
    { lat: 49.231951, lng: 18.9151631 },
    { lat: 49.2320067, lng: 18.9150591 },
    { lat: 49.2320413, lng: 18.9150294 },
    { lat: 49.2321908, lng: 18.9150413 },
    { lat: 49.2322285, lng: 18.9150542 },
    { lat: 49.2322342, lng: 18.9150553 },
    { lat: 49.2323617, lng: 18.9149702 },
    { lat: 49.2324122, lng: 18.9148275 },
    { lat: 49.2324716, lng: 18.9147137 },
    { lat: 49.2325799, lng: 18.9146464 },
    { lat: 49.2327211, lng: 18.9146716 },
    { lat: 49.2328398, lng: 18.9147484 },
    { lat: 49.2328936, lng: 18.9147439 },
    { lat: 49.2329392, lng: 18.91464 },
    { lat: 49.2329508, lng: 18.9146036 },
    { lat: 49.2329612, lng: 18.914562 },
    { lat: 49.2329912, lng: 18.9144408 },
    { lat: 49.2330518, lng: 18.9143929 },
    { lat: 49.2331515, lng: 18.9144659 },
    { lat: 49.2332663, lng: 18.9144664 },
    { lat: 49.2332906, lng: 18.9144339 },
    { lat: 49.2332881, lng: 18.9143599 },
    { lat: 49.2332562, lng: 18.914285 },
    { lat: 49.2332583, lng: 18.914173 },
    { lat: 49.2332606, lng: 18.9141536 },
    { lat: 49.2332713, lng: 18.914118 },
    { lat: 49.2333565, lng: 18.9139994 },
    { lat: 49.2334501, lng: 18.9138894 },
    { lat: 49.2336472, lng: 18.913974 },
    { lat: 49.2336621, lng: 18.9139784 },
    { lat: 49.2337472, lng: 18.9139892 },
    { lat: 49.2338216, lng: 18.9139667 },
    { lat: 49.2339515, lng: 18.913859 },
    { lat: 49.2339471, lng: 18.9138187 },
    { lat: 49.2338945, lng: 18.9136944 },
    { lat: 49.2338771, lng: 18.9135507 },
    { lat: 49.233919, lng: 18.9133754 },
    { lat: 49.2339595, lng: 18.913327 },
    { lat: 49.2340111, lng: 18.9133476 },
    { lat: 49.2340665, lng: 18.9133728 },
    { lat: 49.2340949, lng: 18.913373 },
    { lat: 49.2341157, lng: 18.9133644 },
    { lat: 49.234175, lng: 18.9132672 },
    { lat: 49.2341823, lng: 18.9130982 },
    { lat: 49.2341764, lng: 18.9130719 },
    { lat: 49.234102, lng: 18.9130071 },
    { lat: 49.2341006, lng: 18.912978 },
    { lat: 49.2341058, lng: 18.912946 },
    { lat: 49.2342049, lng: 18.9128964 },
    { lat: 49.2343006, lng: 18.9128097 },
    { lat: 49.2344439, lng: 18.9125882 },
    { lat: 49.234632, lng: 18.9123844 },
    { lat: 49.2346924, lng: 18.9122438 },
    { lat: 49.2346275, lng: 18.91196 },
    { lat: 49.2346379, lng: 18.9118578 },
    { lat: 49.2346594, lng: 18.9118581 },
    { lat: 49.2347024, lng: 18.9119273 },
    { lat: 49.2347768, lng: 18.9119734 },
    { lat: 49.2348017, lng: 18.9119769 },
    { lat: 49.234906, lng: 18.9118285 },
    { lat: 49.2349321, lng: 18.9117674 },
    { lat: 49.234907, lng: 18.911472 },
    { lat: 49.2349441, lng: 18.9113799 },
    { lat: 49.2350154, lng: 18.9113405 },
    { lat: 49.2350986, lng: 18.9112949 },
    { lat: 49.2351663, lng: 18.9111812 },
    { lat: 49.2351987, lng: 18.9111117 },
    { lat: 49.2351436, lng: 18.9109573 },
    { lat: 49.2351449, lng: 18.910872 },
    { lat: 49.2351972, lng: 18.9106825 },
    { lat: 49.2352082, lng: 18.9106751 },
    { lat: 49.2352525, lng: 18.9107254 },
    { lat: 49.2353025, lng: 18.9107375 },
    { lat: 49.2353385, lng: 18.9106644 },
    { lat: 49.2354234, lng: 18.910405 },
    { lat: 49.2355695, lng: 18.9103094 },
    { lat: 49.2356168, lng: 18.9103056 },
    { lat: 49.235679, lng: 18.9102005 },
    { lat: 49.2357064, lng: 18.91001 },
    { lat: 49.2358314, lng: 18.90987 },
    { lat: 49.23588, lng: 18.9097622 },
    { lat: 49.2360075, lng: 18.9095887 },
    { lat: 49.2360724, lng: 18.9095312 },
    { lat: 49.2361148, lng: 18.9094999 },
    { lat: 49.2361102, lng: 18.9092793 },
    { lat: 49.2361779, lng: 18.9091886 },
    { lat: 49.2362284, lng: 18.9091422 },
    { lat: 49.2362617, lng: 18.9089741 },
    { lat: 49.2362548, lng: 18.9086856 },
    { lat: 49.2363905, lng: 18.9082499 },
    { lat: 49.2364015, lng: 18.9080657 },
    { lat: 49.2366026, lng: 18.9077875 },
    { lat: 49.2365795, lng: 18.9075511 },
    { lat: 49.2366096, lng: 18.9072369 },
    { lat: 49.2366139, lng: 18.9067212 },
    { lat: 49.2364817, lng: 18.9064258 },
    { lat: 49.2364924, lng: 18.9063246 },
    { lat: 49.2364296, lng: 18.905801 },
    { lat: 49.2363824, lng: 18.9056478 },
    { lat: 49.2361825, lng: 18.9052422 },
    { lat: 49.2360497, lng: 18.9044245 },
    { lat: 49.2357513, lng: 18.9029691 },
    { lat: 49.2356911, lng: 18.9028013 },
    { lat: 49.2355572, lng: 18.9025664 },
    { lat: 49.2353709, lng: 18.9022472 },
    { lat: 49.2353581, lng: 18.9019763 },
    { lat: 49.2353848, lng: 18.9017033 },
    { lat: 49.2352972, lng: 18.9015095 },
    { lat: 49.23528, lng: 18.9014155 },
    { lat: 49.2351655, lng: 18.9008273 },
    { lat: 49.2350581, lng: 18.9002675 },
    { lat: 49.2349175, lng: 18.8997603 },
    { lat: 49.2348953, lng: 18.8994119 },
    { lat: 49.2348361, lng: 18.8991585 },
    { lat: 49.2347429, lng: 18.8987658 },
    { lat: 49.2346855, lng: 18.8985244 },
    { lat: 49.2345992, lng: 18.8981609 },
    { lat: 49.2346339, lng: 18.8980442 },
    { lat: 49.2346455, lng: 18.8980031 },
    { lat: 49.2346727, lng: 18.8979198 },
    { lat: 49.2350548, lng: 18.89672 },
    { lat: 49.2348461, lng: 18.896695 },
    { lat: 49.2345307, lng: 18.8966574 },
    { lat: 49.23423, lng: 18.8966216 },
    { lat: 49.2340451, lng: 18.8965995 },
    { lat: 49.2346647, lng: 18.8948145 },
    { lat: 49.2345631, lng: 18.894745 },
    { lat: 49.2344222, lng: 18.8947246 },
    { lat: 49.2341749, lng: 18.894764 },
    { lat: 49.2341268, lng: 18.8947697 },
    { lat: 49.2338948, lng: 18.8947983 },
    { lat: 49.2334269, lng: 18.8948409 },
    { lat: 49.2332275, lng: 18.8948422 },
    { lat: 49.2331975, lng: 18.8944099 },
    { lat: 49.2330646, lng: 18.894412 },
    { lat: 49.2328202, lng: 18.8943956 },
    { lat: 49.2326517, lng: 18.8943863 },
    { lat: 49.232281, lng: 18.8943658 },
    { lat: 49.2321059, lng: 18.8943382 },
    { lat: 49.231731, lng: 18.8944134 },
    { lat: 49.2315366, lng: 18.8946691 },
    { lat: 49.23145, lng: 18.8950979 },
    { lat: 49.2314446, lng: 18.8952555 },
    { lat: 49.2312929, lng: 18.895377 },
    { lat: 49.2312444, lng: 18.895487 },
    { lat: 49.2309285, lng: 18.8957932 },
    { lat: 49.2306819, lng: 18.8959549 },
    { lat: 49.2304202, lng: 18.8959531 },
    { lat: 49.2301683, lng: 18.896031 },
    { lat: 49.2299433, lng: 18.8961486 },
    { lat: 49.2297384, lng: 18.8962223 },
    { lat: 49.2294968, lng: 18.8962842 },
    { lat: 49.2292821, lng: 18.896252 },
    { lat: 49.2291188, lng: 18.8962753 },
    { lat: 49.2290115, lng: 18.896116 },
    { lat: 49.2287193, lng: 18.8956247 },
    { lat: 49.2285982, lng: 18.8953537 },
    { lat: 49.2284922, lng: 18.8951502 },
    { lat: 49.228285, lng: 18.8945799 },
    { lat: 49.2282363, lng: 18.8944216 },
    { lat: 49.2280611, lng: 18.894214 },
    { lat: 49.2279723, lng: 18.894085 },
    { lat: 49.2279323, lng: 18.893944 },
    { lat: 49.2276777, lng: 18.8936355 },
    { lat: 49.2275364, lng: 18.8935188 },
    { lat: 49.2274052, lng: 18.893442 },
    { lat: 49.226991, lng: 18.8928383 },
    { lat: 49.2267472, lng: 18.8924542 },
    { lat: 49.226712, lng: 18.8924266 },
    { lat: 49.2264982, lng: 18.8919726 },
    { lat: 49.2264267, lng: 18.8918949 },
    { lat: 49.2262005, lng: 18.8913965 },
    { lat: 49.226034, lng: 18.8911382 },
    { lat: 49.2257168, lng: 18.8905551 },
    { lat: 49.2253771, lng: 18.8903061 },
    { lat: 49.2251762, lng: 18.8901868 },
    { lat: 49.2248371, lng: 18.890125 },
    { lat: 49.224704, lng: 18.8900131 },
    { lat: 49.2245745, lng: 18.8898632 },
    { lat: 49.2244118, lng: 18.8897224 },
    { lat: 49.2243486, lng: 18.8896351 },
    { lat: 49.2242098, lng: 18.8892768 },
    { lat: 49.2239469, lng: 18.8891843 },
    { lat: 49.2237638, lng: 18.8891332 },
    { lat: 49.2234835, lng: 18.889077 },
    { lat: 49.2230789, lng: 18.8890544 },
    { lat: 49.2227548, lng: 18.8890878 },
    { lat: 49.2225954, lng: 18.8892153 },
    { lat: 49.2225294, lng: 18.8892682 },
    { lat: 49.2224656, lng: 18.8893202 },
    { lat: 49.2225607, lng: 18.8895487 },
    { lat: 49.222633, lng: 18.8897072 },
    { lat: 49.2228925, lng: 18.8902426 },
    { lat: 49.2229475, lng: 18.8903483 },
    { lat: 49.2232911, lng: 18.8910087 },
    { lat: 49.2233454, lng: 18.8911227 },
    { lat: 49.2234759, lng: 18.8914261 },
    { lat: 49.223533, lng: 18.8915957 },
    { lat: 49.2236798, lng: 18.8921074 },
    { lat: 49.2237728, lng: 18.8931835 },
    { lat: 49.2238081, lng: 18.8938073 },
    { lat: 49.2239576, lng: 18.8947522 },
    { lat: 49.2241819, lng: 18.8959697 },
    { lat: 49.2242474, lng: 18.8965463 },
    { lat: 49.2243065, lng: 18.8973977 },
    { lat: 49.2243627, lng: 18.8979711 },
    { lat: 49.2242833, lng: 18.8979819 },
    { lat: 49.2242686, lng: 18.897984 },
    { lat: 49.2242463, lng: 18.897987 },
    { lat: 49.2232198, lng: 18.8986837 },
    { lat: 49.223342, lng: 18.8992052 },
    { lat: 49.2225781, lng: 18.899589 },
    { lat: 49.222521, lng: 18.8996178 },
    { lat: 49.2224933, lng: 18.8996319 },
    { lat: 49.2223715, lng: 18.8996933 },
    { lat: 49.2223362, lng: 18.8997108 },
    { lat: 49.2222768, lng: 18.8997406 },
    { lat: 49.2223606, lng: 18.9001021 },
    { lat: 49.2223792, lng: 18.9001786 },
    { lat: 49.222344, lng: 18.9001982 },
    { lat: 49.222351, lng: 18.900223 },
    { lat: 49.2223553, lng: 18.9002427 },
    { lat: 49.2223213, lng: 18.9002622 },
  ],
  Terchová: [
    { lat: 49.307529, lng: 19.004386 },
    { lat: 49.3073021, lng: 19.0035156 },
    { lat: 49.306749, lng: 19.0035707 },
    { lat: 49.3066992, lng: 19.0049865 },
    { lat: 49.3062316, lng: 19.0049041 },
    { lat: 49.3060522, lng: 19.0044009 },
    { lat: 49.3057562, lng: 19.0039603 },
    { lat: 49.3054058, lng: 19.0036927 },
    { lat: 49.3044527, lng: 19.0032772 },
    { lat: 49.3036087, lng: 19.0028078 },
    { lat: 49.3029336, lng: 19.002383 },
    { lat: 49.3025406, lng: 19.0020294 },
    { lat: 49.3022453, lng: 19.0016867 },
    { lat: 49.3019905, lng: 19.0012588 },
    { lat: 49.3013492, lng: 18.9999956 },
    { lat: 49.3011679, lng: 18.9997105 },
    { lat: 49.3008799, lng: 18.9995154 },
    { lat: 49.3003457, lng: 18.9993611 },
    { lat: 49.3002902, lng: 18.9993329 },
    { lat: 49.3000797, lng: 18.9991151 },
    { lat: 49.2998534, lng: 18.9989822 },
    { lat: 49.2994427, lng: 18.9989326 },
    { lat: 49.2992114, lng: 18.9987714 },
    { lat: 49.2982442, lng: 18.9974822 },
    { lat: 49.2959859, lng: 18.9954014 },
    { lat: 49.2935396, lng: 18.9949467 },
    { lat: 49.292134, lng: 18.9955552 },
    { lat: 49.2920513, lng: 18.9954604 },
    { lat: 49.2917811, lng: 18.9951493 },
    { lat: 49.2909086, lng: 18.9943617 },
    { lat: 49.2907199, lng: 18.9943064 },
    { lat: 49.2902093, lng: 18.9944643 },
    { lat: 49.2901558, lng: 18.994481 },
    { lat: 49.2896958, lng: 18.9945459 },
    { lat: 49.2893421, lng: 18.9945959 },
    { lat: 49.2891652, lng: 18.9946863 },
    { lat: 49.2884863, lng: 18.9950227 },
    { lat: 49.2883762, lng: 18.9950515 },
    { lat: 49.2883317, lng: 18.9950632 },
    { lat: 49.2876059, lng: 18.9950894 },
    { lat: 49.2864526, lng: 18.9949735 },
    { lat: 49.28628, lng: 18.9949706 },
    { lat: 49.2860391, lng: 18.9949099 },
    { lat: 49.285819, lng: 18.9948604 },
    { lat: 49.2852725, lng: 18.9947542 },
    { lat: 49.2851566, lng: 18.9947309 },
    { lat: 49.2849583, lng: 18.9946912 },
    { lat: 49.2849311, lng: 18.9946857 },
    { lat: 49.2844843, lng: 18.9945238 },
    { lat: 49.2840777, lng: 18.9943705 },
    { lat: 49.2837825, lng: 18.9942645 },
    { lat: 49.283372, lng: 18.9940991 },
    { lat: 49.2833147, lng: 18.9940837 },
    { lat: 49.2832794, lng: 18.9940774 },
    { lat: 49.2825094, lng: 18.9939369 },
    { lat: 49.2820745, lng: 18.9935588 },
    { lat: 49.2819092, lng: 18.9937066 },
    { lat: 49.2818372, lng: 18.9932348 },
    { lat: 49.2817911, lng: 18.9933229 },
    { lat: 49.2814665, lng: 18.9930575 },
    { lat: 49.2810221, lng: 18.9924659 },
    { lat: 49.2806868, lng: 18.9928142 },
    { lat: 49.2804381, lng: 18.9924851 },
    { lat: 49.2790235, lng: 18.9917697 },
    { lat: 49.2790162, lng: 18.9927469 },
    { lat: 49.2789473, lng: 18.993221 },
    { lat: 49.2790849, lng: 18.9938289 },
    { lat: 49.2791419, lng: 18.9942739 },
    { lat: 49.2791916, lng: 18.9949289 },
    { lat: 49.2792423, lng: 18.995852 },
    { lat: 49.2797714, lng: 18.9969676 },
    { lat: 49.2799478, lng: 18.9981234 },
    { lat: 49.2800585, lng: 18.9985152 },
    { lat: 49.2800368, lng: 18.9985102 },
    { lat: 49.2800052, lng: 18.9985067 },
    { lat: 49.2800039, lng: 18.9985483 },
    { lat: 49.2799143, lng: 18.9986505 },
    { lat: 49.2798961, lng: 18.998692 },
    { lat: 49.2798854, lng: 18.9987709 },
    { lat: 49.2799219, lng: 18.9988027 },
    { lat: 49.2799327, lng: 18.9988159 },
    { lat: 49.2799416, lng: 18.998904 },
    { lat: 49.2799316, lng: 18.9990204 },
    { lat: 49.2799542, lng: 18.9991358 },
    { lat: 49.2800022, lng: 18.9993736 },
    { lat: 49.2800359, lng: 18.9995751 },
    { lat: 49.2800802, lng: 18.9997385 },
    { lat: 49.280098, lng: 18.9998441 },
    { lat: 49.2800937, lng: 18.9998724 },
    { lat: 49.2800398, lng: 18.9999895 },
    { lat: 49.2800016, lng: 19.0000849 },
    { lat: 49.2799392, lng: 19.0001218 },
    { lat: 49.2799357, lng: 19.0001421 },
    { lat: 49.2799402, lng: 19.0002983 },
    { lat: 49.2798943, lng: 19.0004867 },
    { lat: 49.2799458, lng: 19.0006385 },
    { lat: 49.2798847, lng: 19.0007442 },
    { lat: 49.279912, lng: 19.0008836 },
    { lat: 49.2799539, lng: 19.0010044 },
    { lat: 49.2799142, lng: 19.001065 },
    { lat: 49.2799307, lng: 19.0011024 },
    { lat: 49.2799614, lng: 19.0011544 },
    { lat: 49.2799737, lng: 19.0013104 },
    { lat: 49.2800317, lng: 19.0013838 },
    { lat: 49.2800683, lng: 19.0015308 },
    { lat: 49.2800986, lng: 19.0016638 },
    { lat: 49.2800982, lng: 19.0017931 },
    { lat: 49.2802024, lng: 19.0019362 },
    { lat: 49.2802825, lng: 19.0021446 },
    { lat: 49.2803181, lng: 19.0022473 },
    { lat: 49.280346, lng: 19.0024217 },
    { lat: 49.2803226, lng: 19.0025826 },
    { lat: 49.2803088, lng: 19.0026889 },
    { lat: 49.2802463, lng: 19.0028153 },
    { lat: 49.2802991, lng: 19.0030633 },
    { lat: 49.280255, lng: 19.0031743 },
    { lat: 49.2802443, lng: 19.003342 },
    { lat: 49.2802625, lng: 19.003433 },
    { lat: 49.2802969, lng: 19.0035832 },
    { lat: 49.2802882, lng: 19.0036781 },
    { lat: 49.2802577, lng: 19.003791 },
    { lat: 49.2802579, lng: 19.0038607 },
    { lat: 49.2802175, lng: 19.0039553 },
    { lat: 49.2801794, lng: 19.0040056 },
    { lat: 49.2801318, lng: 19.0042025 },
    { lat: 49.2801052, lng: 19.0043945 },
    { lat: 49.2801047, lng: 19.0045411 },
    { lat: 49.2800991, lng: 19.0045656 },
    { lat: 49.2800904, lng: 19.0046414 },
    { lat: 49.2801307, lng: 19.0047681 },
    { lat: 49.2802029, lng: 19.0048373 },
    { lat: 49.2802146, lng: 19.0048688 },
    { lat: 49.2801956, lng: 19.0050089 },
    { lat: 49.2801883, lng: 19.0051322 },
    { lat: 49.2802422, lng: 19.0053548 },
    { lat: 49.2802374, lng: 19.0054157 },
    { lat: 49.2802314, lng: 19.0054973 },
    { lat: 49.2802307, lng: 19.0055082 },
    { lat: 49.2802321, lng: 19.0055593 },
    { lat: 49.280255, lng: 19.0055873 },
    { lat: 49.2802767, lng: 19.0055729 },
    { lat: 49.2802888, lng: 19.0056357 },
    { lat: 49.2802193, lng: 19.0057344 },
    { lat: 49.2801823, lng: 19.0058023 },
    { lat: 49.280163, lng: 19.0058024 },
    { lat: 49.2801068, lng: 19.0058032 },
    { lat: 49.2800419, lng: 19.0058562 },
    { lat: 49.2799694, lng: 19.0059199 },
    { lat: 49.2799084, lng: 19.0060083 },
    { lat: 49.2799375, lng: 19.006093 },
    { lat: 49.2799386, lng: 19.0061159 },
    { lat: 49.2799366, lng: 19.0062122 },
    { lat: 49.2799329, lng: 19.0062473 },
    { lat: 49.279907, lng: 19.0063207 },
    { lat: 49.279889, lng: 19.0063203 },
    { lat: 49.2798599, lng: 19.0063241 },
    { lat: 49.2798507, lng: 19.0063421 },
    { lat: 49.2798127, lng: 19.006439 },
    { lat: 49.2797201, lng: 19.0065472 },
    { lat: 49.2796429, lng: 19.0065624 },
    { lat: 49.2795715, lng: 19.0065978 },
    { lat: 49.2795179, lng: 19.0067893 },
    { lat: 49.2795014, lng: 19.0068879 },
    { lat: 49.2795384, lng: 19.0069956 },
    { lat: 49.2795073, lng: 19.0072108 },
    { lat: 49.2794693, lng: 19.0072827 },
    { lat: 49.279458, lng: 19.0073739 },
    { lat: 49.2794697, lng: 19.0074942 },
    { lat: 49.2794632, lng: 19.0075918 },
    { lat: 49.2794815, lng: 19.0077543 },
    { lat: 49.2794348, lng: 19.0078363 },
    { lat: 49.2793888, lng: 19.0079756 },
    { lat: 49.279424, lng: 19.0081838 },
    { lat: 49.2794078, lng: 19.0083124 },
    { lat: 49.2793953, lng: 19.0084222 },
    { lat: 49.279332, lng: 19.0084844 },
    { lat: 49.2793276, lng: 19.0084662 },
    { lat: 49.2793024, lng: 19.0083879 },
    { lat: 49.2792677, lng: 19.0083504 },
    { lat: 49.2791801, lng: 19.0083126 },
    { lat: 49.2791657, lng: 19.0082944 },
    { lat: 49.2791107, lng: 19.0082297 },
    { lat: 49.2790678, lng: 19.0081838 },
    { lat: 49.2789292, lng: 19.0081421 },
    { lat: 49.2788095, lng: 19.0080911 },
    { lat: 49.2786641, lng: 19.0079795 },
    { lat: 49.2785911, lng: 19.0079371 },
    { lat: 49.2784635, lng: 19.007979 },
    { lat: 49.2783847, lng: 19.0080279 },
    { lat: 49.2782438, lng: 19.0080256 },
    { lat: 49.2779589, lng: 19.0079219 },
    { lat: 49.2777304, lng: 19.0079244 },
    { lat: 49.2775356, lng: 19.0079539 },
    { lat: 49.2773333, lng: 19.0079681 },
    { lat: 49.2771883, lng: 19.0079958 },
    { lat: 49.2769682, lng: 19.0080121 },
    { lat: 49.2767101, lng: 19.0081263 },
    { lat: 49.2768138, lng: 19.0082413 },
    { lat: 49.2770301, lng: 19.0085746 },
    { lat: 49.27657, lng: 19.0095658 },
    { lat: 49.2763565, lng: 19.0100319 },
    { lat: 49.2759703, lng: 19.0106112 },
    { lat: 49.2757746, lng: 19.0108871 },
    { lat: 49.2759023, lng: 19.0115491 },
    { lat: 49.2758394, lng: 19.0123006 },
    { lat: 49.2759221, lng: 19.012222 },
    { lat: 49.2760327, lng: 19.0121979 },
    { lat: 49.2761328, lng: 19.0123765 },
    { lat: 49.2762664, lng: 19.0124566 },
    { lat: 49.2763786, lng: 19.0126021 },
    { lat: 49.2766377, lng: 19.0130041 },
    { lat: 49.2769579, lng: 19.0129596 },
    { lat: 49.2770815, lng: 19.012956 },
    { lat: 49.2773486, lng: 19.0129737 },
    { lat: 49.2775895, lng: 19.0134244 },
    { lat: 49.2773539, lng: 19.0137392 },
    { lat: 49.2769125, lng: 19.0144942 },
    { lat: 49.2764499, lng: 19.0145795 },
    { lat: 49.2762588, lng: 19.0145638 },
    { lat: 49.2761245, lng: 19.0148332 },
    { lat: 49.2761041, lng: 19.0149677 },
    { lat: 49.2761309, lng: 19.015122 },
    { lat: 49.2761822, lng: 19.01545 },
    { lat: 49.2760071, lng: 19.0157336 },
    { lat: 49.2759885, lng: 19.0158585 },
    { lat: 49.2754891, lng: 19.01597 },
    { lat: 49.2754202, lng: 19.0162196 },
    { lat: 49.2753816, lng: 19.0165302 },
    { lat: 49.2753025, lng: 19.0165737 },
    { lat: 49.275121, lng: 19.0165064 },
    { lat: 49.2750054, lng: 19.0165599 },
    { lat: 49.2748144, lng: 19.0168428 },
    { lat: 49.2748623, lng: 19.0169892 },
    { lat: 49.2748407, lng: 19.0171231 },
    { lat: 49.2747, lng: 19.0171731 },
    { lat: 49.2745899, lng: 19.0171353 },
    { lat: 49.2744476, lng: 19.0168811 },
    { lat: 49.2741799, lng: 19.0170931 },
    { lat: 49.2738707, lng: 19.0169331 },
    { lat: 49.2736333, lng: 19.0167145 },
    { lat: 49.2734461, lng: 19.0163713 },
    { lat: 49.2732613, lng: 19.0159 },
    { lat: 49.2732029, lng: 19.0158374 },
    { lat: 49.2731312, lng: 19.0155256 },
    { lat: 49.2731109, lng: 19.0152557 },
    { lat: 49.2728113, lng: 19.0154206 },
    { lat: 49.2726637, lng: 19.0155345 },
    { lat: 49.2724756, lng: 19.0150398 },
    { lat: 49.2721742, lng: 19.0153026 },
    { lat: 49.2713319, lng: 19.0137382 },
    { lat: 49.270819, lng: 19.0133958 },
    { lat: 49.270589, lng: 19.0132355 },
    { lat: 49.2702547, lng: 19.0130694 },
    { lat: 49.2702102, lng: 19.0131724 },
    { lat: 49.2699963, lng: 19.0130414 },
    { lat: 49.2696737, lng: 19.0138716 },
    { lat: 49.2693517, lng: 19.013677 },
    { lat: 49.2688435, lng: 19.0135008 },
    { lat: 49.2688199, lng: 19.0137717 },
    { lat: 49.2683818, lng: 19.0137577 },
    { lat: 49.2678386, lng: 19.0137845 },
    { lat: 49.2676164, lng: 19.0137811 },
    { lat: 49.2675936, lng: 19.0135282 },
    { lat: 49.2674293, lng: 19.0135769 },
    { lat: 49.2674142, lng: 19.0133213 },
    { lat: 49.2668831, lng: 19.0137473 },
    { lat: 49.2663217, lng: 19.0142472 },
    { lat: 49.2658999, lng: 19.0149645 },
    { lat: 49.2661466, lng: 19.0152549 },
    { lat: 49.2664389, lng: 19.0156219 },
    { lat: 49.2666284, lng: 19.0163297 },
    { lat: 49.2667979, lng: 19.0170139 },
    { lat: 49.26691, lng: 19.0174528 },
    { lat: 49.267092, lng: 19.0178489 },
    { lat: 49.2674266, lng: 19.0183868 },
    { lat: 49.2677724, lng: 19.0189509 },
    { lat: 49.2679433, lng: 19.0196901 },
    { lat: 49.2681076, lng: 19.0205218 },
    { lat: 49.268116, lng: 19.0211656 },
    { lat: 49.2681554, lng: 19.0214342 },
    { lat: 49.2683364, lng: 19.0217392 },
    { lat: 49.2685125, lng: 19.0222685 },
    { lat: 49.2684887, lng: 19.0224041 },
    { lat: 49.2684782, lng: 19.022463 },
    { lat: 49.2683933, lng: 19.0225888 },
    { lat: 49.2683524, lng: 19.0227646 },
    { lat: 49.2682293, lng: 19.0230341 },
    { lat: 49.2681406, lng: 19.023335 },
    { lat: 49.2679376, lng: 19.0236514 },
    { lat: 49.2677517, lng: 19.0242411 },
    { lat: 49.267739, lng: 19.0243942 },
    { lat: 49.2676213, lng: 19.0244736 },
    { lat: 49.267479, lng: 19.024789 },
    { lat: 49.2674108, lng: 19.0248424 },
    { lat: 49.2673913, lng: 19.0248829 },
    { lat: 49.2673888, lng: 19.0249655 },
    { lat: 49.2673588, lng: 19.0251599 },
    { lat: 49.2673429, lng: 19.0252019 },
    { lat: 49.2673005, lng: 19.0252331 },
    { lat: 49.2673095, lng: 19.0252996 },
    { lat: 49.2672735, lng: 19.025346 },
    { lat: 49.2672383, lng: 19.0254125 },
    { lat: 49.2671679, lng: 19.0255106 },
    { lat: 49.2671129, lng: 19.0256314 },
    { lat: 49.2670435, lng: 19.0257553 },
    { lat: 49.2670483, lng: 19.0258284 },
    { lat: 49.2670649, lng: 19.0258915 },
    { lat: 49.2670482, lng: 19.0260071 },
    { lat: 49.2669673, lng: 19.0260793 },
    { lat: 49.2668822, lng: 19.0261361 },
    { lat: 49.2669, lng: 19.0262701 },
    { lat: 49.2668143, lng: 19.026357 },
    { lat: 49.2667428, lng: 19.0263967 },
    { lat: 49.266736, lng: 19.0265072 },
    { lat: 49.266715, lng: 19.0266298 },
    { lat: 49.2666468, lng: 19.0267327 },
    { lat: 49.2665838, lng: 19.0267815 },
    { lat: 49.2665084, lng: 19.0268928 },
    { lat: 49.2664423, lng: 19.0269698 },
    { lat: 49.2663901, lng: 19.0270744 },
    { lat: 49.2663152, lng: 19.027156 },
    { lat: 49.2662909, lng: 19.0271898 },
    { lat: 49.2662367, lng: 19.0272347 },
    { lat: 49.2662179, lng: 19.0272178 },
    { lat: 49.2661506, lng: 19.0272271 },
    { lat: 49.2661317, lng: 19.0272094 },
    { lat: 49.2661139, lng: 19.0272125 },
    { lat: 49.2660337, lng: 19.0272998 },
    { lat: 49.2659762, lng: 19.0273928 },
    { lat: 49.2659258, lng: 19.0275091 },
    { lat: 49.2658759, lng: 19.0275697 },
    { lat: 49.2657578, lng: 19.0276693 },
    { lat: 49.265729, lng: 19.0277221 },
    { lat: 49.2657277, lng: 19.0277873 },
    { lat: 49.2657184, lng: 19.0279648 },
    { lat: 49.2656956, lng: 19.0280956 },
    { lat: 49.2656105, lng: 19.0283318 },
    { lat: 49.2655581, lng: 19.0285274 },
    { lat: 49.2655153, lng: 19.0287082 },
    { lat: 49.265482, lng: 19.0288795 },
    { lat: 49.265412, lng: 19.0291479 },
    { lat: 49.2653607, lng: 19.0292513 },
    { lat: 49.265339, lng: 19.0293339 },
    { lat: 49.2652667, lng: 19.0295802 },
    { lat: 49.2652177, lng: 19.0297548 },
    { lat: 49.2651636, lng: 19.0300921 },
    { lat: 49.2651658, lng: 19.030273 },
    { lat: 49.2651443, lng: 19.0304303 },
    { lat: 49.2651195, lng: 19.0306339 },
    { lat: 49.2651044, lng: 19.0308537 },
    { lat: 49.2651465, lng: 19.0309975 },
    { lat: 49.2651782, lng: 19.031136 },
    { lat: 49.265217, lng: 19.0312792 },
    { lat: 49.2651838, lng: 19.0314299 },
    { lat: 49.2651945, lng: 19.0315315 },
    { lat: 49.2652323, lng: 19.0316362 },
    { lat: 49.2652222, lng: 19.0317034 },
    { lat: 49.2651895, lng: 19.0317725 },
    { lat: 49.26511, lng: 19.0318258 },
    { lat: 49.2651327, lng: 19.0319646 },
    { lat: 49.2650881, lng: 19.0320658 },
    { lat: 49.265044, lng: 19.0321334 },
    { lat: 49.265065, lng: 19.0321483 },
    { lat: 49.265054, lng: 19.0322405 },
    { lat: 49.2650462, lng: 19.0324255 },
    { lat: 49.2650449, lng: 19.0325126 },
    { lat: 49.2650322, lng: 19.0326661 },
    { lat: 49.2649803, lng: 19.0327107 },
    { lat: 49.2647456, lng: 19.032704 },
    { lat: 49.2646969, lng: 19.0327024 },
    { lat: 49.2642644, lng: 19.0314817 },
    { lat: 49.2640167, lng: 19.0307647 },
    { lat: 49.2638904, lng: 19.0301723 },
    { lat: 49.263911, lng: 19.0292694 },
    { lat: 49.2639787, lng: 19.0286165 },
    { lat: 49.2639805, lng: 19.0283106 },
    { lat: 49.263959, lng: 19.0281976 },
    { lat: 49.2639218, lng: 19.0273353 },
    { lat: 49.2639604, lng: 19.0269767 },
    { lat: 49.263996, lng: 19.0267178 },
    { lat: 49.2641151, lng: 19.026348 },
    { lat: 49.2641162, lng: 19.0259624 },
    { lat: 49.2642545, lng: 19.0244163 },
    { lat: 49.2643514, lng: 19.0240766 },
    { lat: 49.2646764, lng: 19.0232448 },
    { lat: 49.2647618, lng: 19.0228053 },
    { lat: 49.2648092, lng: 19.0224901 },
    { lat: 49.2645784, lng: 19.0218629 },
    { lat: 49.2642634, lng: 19.0213779 },
    { lat: 49.2637332, lng: 19.0207112 },
    { lat: 49.2628735, lng: 19.019746 },
    { lat: 49.262778, lng: 19.019909 },
    { lat: 49.2626022, lng: 19.0196613 },
    { lat: 49.2622325, lng: 19.0199136 },
    { lat: 49.2621389, lng: 19.0196852 },
    { lat: 49.2619644, lng: 19.0194857 },
    { lat: 49.2616819, lng: 19.0192694 },
    { lat: 49.2615946, lng: 19.019058 },
    { lat: 49.2614293, lng: 19.018839 },
    { lat: 49.2610667, lng: 19.0182557 },
    { lat: 49.2608905, lng: 19.0178196 },
    { lat: 49.2606654, lng: 19.0173524 },
    { lat: 49.2606318, lng: 19.0170852 },
    { lat: 49.2602916, lng: 19.0161821 },
    { lat: 49.2598953, lng: 19.0153371 },
    { lat: 49.259589, lng: 19.0148209 },
    { lat: 49.2593065, lng: 19.0143098 },
    { lat: 49.2589955, lng: 19.0136577 },
    { lat: 49.258657, lng: 19.0128371 },
    { lat: 49.2584673, lng: 19.0124019 },
    { lat: 49.2582834, lng: 19.0119014 },
    { lat: 49.2581066, lng: 19.011634 },
    { lat: 49.2580111, lng: 19.0116859 },
    { lat: 49.257895, lng: 19.0112999 },
    { lat: 49.2578294, lng: 19.0107986 },
    { lat: 49.2578518, lng: 19.0105467 },
    { lat: 49.2578975, lng: 19.0099242 },
    { lat: 49.2578567, lng: 19.0094713 },
    { lat: 49.2577917, lng: 19.0093183 },
    { lat: 49.2577921, lng: 19.0088523 },
    { lat: 49.2576732, lng: 19.0086142 },
    { lat: 49.2568271, lng: 19.0080859 },
    { lat: 49.2567147, lng: 19.008204 },
    { lat: 49.2566087, lng: 19.0084946 },
    { lat: 49.2563514, lng: 19.0089187 },
    { lat: 49.2562372, lng: 19.0090034 },
    { lat: 49.2559616, lng: 19.0093776 },
    { lat: 49.2558524, lng: 19.0096036 },
    { lat: 49.2556124, lng: 19.009856 },
    { lat: 49.2554366, lng: 19.0098108 },
    { lat: 49.2551829, lng: 19.0101525 },
    { lat: 49.2550877, lng: 19.0101622 },
    { lat: 49.2547681, lng: 19.0105119 },
    { lat: 49.2540672, lng: 19.010858 },
    { lat: 49.2529458, lng: 19.0110405 },
    { lat: 49.2528345, lng: 19.0108474 },
    { lat: 49.2527762, lng: 19.0106008 },
    { lat: 49.2527536, lng: 19.0105793 },
    { lat: 49.2525247, lng: 19.0110992 },
    { lat: 49.2523983, lng: 19.0111843 },
    { lat: 49.251523, lng: 19.0113778 },
    { lat: 49.2513318, lng: 19.0113647 },
    { lat: 49.2512073, lng: 19.0113976 },
    { lat: 49.251009, lng: 19.0114907 },
    { lat: 49.2509965, lng: 19.011468 },
    { lat: 49.2507859, lng: 19.0115416 },
    { lat: 49.2507675, lng: 19.0114412 },
    { lat: 49.2506767, lng: 19.0114731 },
    { lat: 49.2506426, lng: 19.0113342 },
    { lat: 49.2502415, lng: 19.0116085 },
    { lat: 49.2499647, lng: 19.0118464 },
    { lat: 49.2496178, lng: 19.0116689 },
    { lat: 49.2494041, lng: 19.0118175 },
    { lat: 49.2492105, lng: 19.011914 },
    { lat: 49.2490076, lng: 19.0119805 },
    { lat: 49.2486081, lng: 19.011839 },
    { lat: 49.24737, lng: 19.0123057 },
    { lat: 49.2470445, lng: 19.0124918 },
    { lat: 49.2461117, lng: 19.0111712 },
    { lat: 49.2455762, lng: 19.0109203 },
    { lat: 49.2450265, lng: 19.011042 },
    { lat: 49.244639, lng: 19.0116319 },
    { lat: 49.244362, lng: 19.011776 },
    { lat: 49.243583, lng: 19.011503 },
    { lat: 49.2432326, lng: 19.0115528 },
    { lat: 49.2429247, lng: 19.0115052 },
    { lat: 49.2427185, lng: 19.0116429 },
    { lat: 49.2423144, lng: 19.0119284 },
    { lat: 49.2420098, lng: 19.0120778 },
    { lat: 49.2417144, lng: 19.0121219 },
    { lat: 49.241092, lng: 19.0122784 },
    { lat: 49.2400667, lng: 19.0124716 },
    { lat: 49.239873, lng: 19.0130162 },
    { lat: 49.2387775, lng: 19.0134312 },
    { lat: 49.2381975, lng: 19.0136898 },
    { lat: 49.2370232, lng: 19.0145988 },
    { lat: 49.2359387, lng: 19.0148255 },
    { lat: 49.2359023, lng: 19.0148637 },
    { lat: 49.2353267, lng: 19.0144862 },
    { lat: 49.2349845, lng: 19.0134589 },
    { lat: 49.2347723, lng: 19.0130226 },
    { lat: 49.2344394, lng: 19.0118193 },
    { lat: 49.2340758, lng: 19.0116462 },
    { lat: 49.2339581, lng: 19.0115943 },
    { lat: 49.2329341, lng: 19.0111535 },
    { lat: 49.2315003, lng: 19.0105921 },
    { lat: 49.2306364, lng: 19.0103366 },
    { lat: 49.2307496, lng: 19.0097838 },
    { lat: 49.2309363, lng: 19.0088015 },
    { lat: 49.2309324, lng: 19.008249 },
    { lat: 49.2306681, lng: 19.007493 },
    { lat: 49.2304801, lng: 19.0066647 },
    { lat: 49.2302991, lng: 19.0056336 },
    { lat: 49.2300811, lng: 19.004679 },
    { lat: 49.2301498, lng: 19.0041815 },
    { lat: 49.2303012, lng: 19.0038739 },
    { lat: 49.2303398, lng: 19.0034924 },
    { lat: 49.2301527, lng: 19.0027975 },
    { lat: 49.2300993, lng: 19.002562 },
    { lat: 49.2300178, lng: 19.0020121 },
    { lat: 49.2299416, lng: 19.001547 },
    { lat: 49.2298251, lng: 19.0012013 },
    { lat: 49.2297003, lng: 19.0009825 },
    { lat: 49.2295411, lng: 19.0009354 },
    { lat: 49.2294676, lng: 19.0009302 },
    { lat: 49.2292345, lng: 19.0007753 },
    { lat: 49.2289506, lng: 19.0006673 },
    { lat: 49.2287955, lng: 19.0005663 },
    { lat: 49.2287596, lng: 19.0005226 },
    { lat: 49.2286701, lng: 19.0003353 },
    { lat: 49.2286017, lng: 19.0000919 },
    { lat: 49.2285908, lng: 18.9997826 },
    { lat: 49.2285982, lng: 18.9995495 },
    { lat: 49.2285753, lng: 18.9993606 },
    { lat: 49.2285475, lng: 18.9992329 },
    { lat: 49.2285306, lng: 18.9986924 },
    { lat: 49.2284455, lng: 18.9982994 },
    { lat: 49.2283656, lng: 18.9981904 },
    { lat: 49.228209, lng: 18.9979439 },
    { lat: 49.2279289, lng: 18.9973926 },
    { lat: 49.2278839, lng: 18.9971447 },
    { lat: 49.2276946, lng: 18.9968984 },
    { lat: 49.2275991, lng: 18.9960709 },
    { lat: 49.2275107, lng: 18.9958154 },
    { lat: 49.2275253, lng: 18.995365 },
    { lat: 49.2274539, lng: 18.9952651 },
    { lat: 49.2272304, lng: 18.9948764 },
    { lat: 49.2272483, lng: 18.9947401 },
    { lat: 49.2272378, lng: 18.9945524 },
    { lat: 49.2272281, lng: 18.9944734 },
    { lat: 49.2272058, lng: 18.9943628 },
    { lat: 49.2270909, lng: 18.9941612 },
    { lat: 49.22699, lng: 18.9941517 },
    { lat: 49.2269516, lng: 18.9940592 },
    { lat: 49.2269177, lng: 18.9940094 },
    { lat: 49.2269071, lng: 18.9940485 },
    { lat: 49.2268925, lng: 18.9939842 },
    { lat: 49.2259978, lng: 18.9946884 },
    { lat: 49.2252451, lng: 18.9949187 },
    { lat: 49.2238356, lng: 18.9956104 },
    { lat: 49.2236978, lng: 18.9955853 },
    { lat: 49.2228653, lng: 18.9957964 },
    { lat: 49.2221332, lng: 18.996061 },
    { lat: 49.2196962, lng: 18.9982017 },
    { lat: 49.21876, lng: 18.9992315 },
    { lat: 49.2176751, lng: 19.0000309 },
    { lat: 49.216162, lng: 19.0007485 },
    { lat: 49.2155357, lng: 19.0012098 },
    { lat: 49.2149466, lng: 19.0015604 },
    { lat: 49.2138824, lng: 19.0027756 },
    { lat: 49.2121559, lng: 19.0048855 },
    { lat: 49.211437, lng: 19.0046908 },
    { lat: 49.2109768, lng: 19.0050682 },
    { lat: 49.2105931, lng: 19.0056234 },
    { lat: 49.2104358, lng: 19.0057427 },
    { lat: 49.2101854, lng: 19.0058083 },
    { lat: 49.209951, lng: 19.005586 },
    { lat: 49.2097785, lng: 19.0054706 },
    { lat: 49.2096252, lng: 19.0053384 },
    { lat: 49.2093853, lng: 19.0053404 },
    { lat: 49.2090823, lng: 19.0054835 },
    { lat: 49.2090362, lng: 19.0055981 },
    { lat: 49.2088858, lng: 19.0058817 },
    { lat: 49.2085573, lng: 19.0065703 },
    { lat: 49.2082778, lng: 19.0068657 },
    { lat: 49.2082364, lng: 19.0068936 },
    { lat: 49.207907, lng: 19.007119 },
    { lat: 49.2074986, lng: 19.0075596 },
    { lat: 49.2072242, lng: 19.0077307 },
    { lat: 49.2067537, lng: 19.0080161 },
    { lat: 49.2065032, lng: 19.008147 },
    { lat: 49.2061583, lng: 19.0084449 },
    { lat: 49.2058576, lng: 19.0085815 },
    { lat: 49.2057672, lng: 19.0086018 },
    { lat: 49.2057121, lng: 19.0086067 },
    { lat: 49.2055901, lng: 19.0086707 },
    { lat: 49.2053858, lng: 19.0087745 },
    { lat: 49.2052953, lng: 19.008794 },
    { lat: 49.205158, lng: 19.0089773 },
    { lat: 49.2050856, lng: 19.0090405 },
    { lat: 49.205004, lng: 19.0090774 },
    { lat: 49.2049061, lng: 19.0091032 },
    { lat: 49.2048649, lng: 19.0091332 },
    { lat: 49.2047345, lng: 19.0091859 },
    { lat: 49.2046516, lng: 19.0092424 },
    { lat: 49.2045875, lng: 19.0092224 },
    { lat: 49.2044708, lng: 19.0092793 },
    { lat: 49.2043543, lng: 19.0093404 },
    { lat: 49.2042225, lng: 19.0093875 },
    { lat: 49.2040611, lng: 19.0094719 },
    { lat: 49.2038728, lng: 19.0094274 },
    { lat: 49.2035941, lng: 19.009263 },
    { lat: 49.2034924, lng: 19.0092799 },
    { lat: 49.2033035, lng: 19.0093152 },
    { lat: 49.2032267, lng: 19.0093339 },
    { lat: 49.2029008, lng: 19.009402 },
    { lat: 49.2027918, lng: 19.0094032 },
    { lat: 49.2026425, lng: 19.009418 },
    { lat: 49.2023795, lng: 19.0093686 },
    { lat: 49.2021567, lng: 19.0092168 },
    { lat: 49.2021035, lng: 19.0091916 },
    { lat: 49.2020836, lng: 19.0091543 },
    { lat: 49.2018957, lng: 19.0090957 },
    { lat: 49.2017335, lng: 19.0090497 },
    { lat: 49.2016217, lng: 19.0089582 },
    { lat: 49.2015659, lng: 19.0089657 },
    { lat: 49.2013842, lng: 19.0088715 },
    { lat: 49.2012775, lng: 19.0087931 },
    { lat: 49.2011948, lng: 19.008736 },
    { lat: 49.2011529, lng: 19.0087329 },
    { lat: 49.2010885, lng: 19.008797 },
    { lat: 49.2007903, lng: 19.0086717 },
    { lat: 49.2006705, lng: 19.0085998 },
    { lat: 49.2002753, lng: 19.0084489 },
    { lat: 49.2001013, lng: 19.008423 },
    { lat: 49.1993722, lng: 19.0080006 },
    { lat: 49.1989871, lng: 19.0078721 },
    { lat: 49.1985026, lng: 19.007445 },
    { lat: 49.1982609, lng: 19.0072796 },
    { lat: 49.1980011, lng: 19.0071999 },
    { lat: 49.1976136, lng: 19.0071663 },
    { lat: 49.1973528, lng: 19.007156 },
    { lat: 49.1962954, lng: 19.0073154 },
    { lat: 49.1956211, lng: 19.0073985 },
    { lat: 49.1950973, lng: 19.0074771 },
    { lat: 49.1947028, lng: 19.0076809 },
    { lat: 49.19377, lng: 19.008123 },
    { lat: 49.193538, lng: 19.009344 },
    { lat: 49.193736, lng: 19.010427 },
    { lat: 49.193986, lng: 19.012023 },
    { lat: 49.194003, lng: 19.012133 },
    { lat: 49.193185, lng: 19.013692 },
    { lat: 49.193117, lng: 19.014286 },
    { lat: 49.193007, lng: 19.015262 },
    { lat: 49.192755, lng: 19.015904 },
    { lat: 49.191811, lng: 19.016865 },
    { lat: 49.191698, lng: 19.016876 },
    { lat: 49.191657, lng: 19.016922 },
    { lat: 49.191205, lng: 19.017477 },
    { lat: 49.191047, lng: 19.01767 },
    { lat: 49.191008, lng: 19.017722 },
    { lat: 49.190885, lng: 19.017872 },
    { lat: 49.190726, lng: 19.018066 },
    { lat: 49.190627, lng: 19.018242 },
    { lat: 49.19062, lng: 19.018274 },
    { lat: 49.190639, lng: 19.018297 },
    { lat: 49.190659, lng: 19.018321 },
    { lat: 49.190912, lng: 19.019124 },
    { lat: 49.191088, lng: 19.01997 },
    { lat: 49.1911, lng: 19.020121 },
    { lat: 49.191104, lng: 19.020177 },
    { lat: 49.191088, lng: 19.020197 },
    { lat: 49.191137, lng: 19.020322 },
    { lat: 49.191185, lng: 19.020446 },
    { lat: 49.191191, lng: 19.020745 },
    { lat: 49.191197, lng: 19.021049 },
    { lat: 49.190849, lng: 19.023009 },
    { lat: 49.190749, lng: 19.023574 },
    { lat: 49.190749, lng: 19.023575 },
    { lat: 49.191008, lng: 19.024485 },
    { lat: 49.191031, lng: 19.024595 },
    { lat: 49.19115, lng: 19.02543 },
    { lat: 49.190826, lng: 19.026316 },
    { lat: 49.190349, lng: 19.028069 },
    { lat: 49.190296, lng: 19.028329 },
    { lat: 49.190105, lng: 19.028411 },
    { lat: 49.189934, lng: 19.028763 },
    { lat: 49.189671, lng: 19.02895 },
    { lat: 49.18893, lng: 19.029476 },
    { lat: 49.188851, lng: 19.029614 },
    { lat: 49.188384, lng: 19.030436 },
    { lat: 49.187955, lng: 19.030592 },
    { lat: 49.187812, lng: 19.030773 },
    { lat: 49.187748, lng: 19.030853 },
    { lat: 49.18749, lng: 19.030837 },
    { lat: 49.188093, lng: 19.031332 },
    { lat: 49.188412, lng: 19.031896 },
    { lat: 49.188562, lng: 19.032164 },
    { lat: 49.188599, lng: 19.03223 },
    { lat: 49.189937, lng: 19.033922 },
    { lat: 49.190959, lng: 19.0352 },
    { lat: 49.191977, lng: 19.0365 },
    { lat: 49.191626, lng: 19.037941 },
    { lat: 49.191247, lng: 19.039497 },
    { lat: 49.190675, lng: 19.040891 },
    { lat: 49.190048, lng: 19.041988 },
    { lat: 49.189531, lng: 19.044273 },
    { lat: 49.189255, lng: 19.044956 },
    { lat: 49.189199, lng: 19.045139 },
    { lat: 49.188969, lng: 19.047664 },
    { lat: 49.189048, lng: 19.049175 },
    { lat: 49.188628, lng: 19.05019 },
    { lat: 49.188158, lng: 19.051324 },
    { lat: 49.187898, lng: 19.051615 },
    { lat: 49.188181, lng: 19.052104 },
    { lat: 49.189094, lng: 19.052594 },
    { lat: 49.189046, lng: 19.053188 },
    { lat: 49.189092, lng: 19.053972 },
    { lat: 49.189811, lng: 19.054974 },
    { lat: 49.190008, lng: 19.055393 },
    { lat: 49.190071, lng: 19.056126 },
    { lat: 49.19056, lng: 19.058263 },
    { lat: 49.19095, lng: 19.059399 },
    { lat: 49.191162, lng: 19.060463 },
    { lat: 49.191318, lng: 19.060714 },
    { lat: 49.191709, lng: 19.060704 },
    { lat: 49.192212, lng: 19.061401 },
    { lat: 49.193886, lng: 19.061594 },
    { lat: 49.194573, lng: 19.061962 },
    { lat: 49.196075, lng: 19.063131 },
    { lat: 49.196579, lng: 19.063309 },
    { lat: 49.197453, lng: 19.063836 },
    { lat: 49.198394, lng: 19.064222 },
    { lat: 49.199989, lng: 19.065015 },
    { lat: 49.200786, lng: 19.065174 },
    { lat: 49.20151, lng: 19.065454 },
    { lat: 49.202856, lng: 19.065242 },
    { lat: 49.203679, lng: 19.065249 },
    { lat: 49.204888, lng: 19.065533 },
    { lat: 49.205127, lng: 19.065841 },
    { lat: 49.205384, lng: 19.066019 },
    { lat: 49.205488, lng: 19.066039 },
    { lat: 49.205734, lng: 19.066086 },
    { lat: 49.206061, lng: 19.065952 },
    { lat: 49.206751, lng: 19.065641 },
    { lat: 49.207285, lng: 19.065143 },
    { lat: 49.208428, lng: 19.063919 },
    { lat: 49.208629, lng: 19.063552 },
    { lat: 49.209095, lng: 19.06335 },
    { lat: 49.209784, lng: 19.063166 },
    { lat: 49.210635, lng: 19.06344 },
    { lat: 49.211024, lng: 19.063348 },
    { lat: 49.211457, lng: 19.064271 },
    { lat: 49.212375, lng: 19.065738 },
    { lat: 49.212588, lng: 19.067474 },
    { lat: 49.212536, lng: 19.069333 },
    { lat: 49.212649, lng: 19.070205 },
    { lat: 49.212818, lng: 19.070684 },
    { lat: 49.212918, lng: 19.072146 },
    { lat: 49.213109, lng: 19.072814 },
    { lat: 49.213288, lng: 19.073169 },
    { lat: 49.21371, lng: 19.074048 },
    { lat: 49.214174, lng: 19.075172 },
    { lat: 49.214707, lng: 19.075861 },
    { lat: 49.215443, lng: 19.076544 },
    { lat: 49.216426, lng: 19.078026 },
    { lat: 49.217217, lng: 19.079802 },
    { lat: 49.21777, lng: 19.081816 },
    { lat: 49.218092, lng: 19.083273 },
    { lat: 49.218029, lng: 19.083387 },
    { lat: 49.217588, lng: 19.084191 },
    { lat: 49.217219, lng: 19.084877 },
    { lat: 49.216905, lng: 19.085457 },
    { lat: 49.216501, lng: 19.086194 },
    { lat: 49.216103, lng: 19.087028 },
    { lat: 49.215807, lng: 19.087651 },
    { lat: 49.215715, lng: 19.087862 },
    { lat: 49.21547, lng: 19.088418 },
    { lat: 49.215097, lng: 19.089257 },
    { lat: 49.214774, lng: 19.090462 },
    { lat: 49.214444, lng: 19.091904 },
    { lat: 49.214373, lng: 19.092069 },
    { lat: 49.213986, lng: 19.092973 },
    { lat: 49.213786, lng: 19.093439 },
    { lat: 49.213422, lng: 19.093815 },
    { lat: 49.212709, lng: 19.094093 },
    { lat: 49.212268, lng: 19.094032 },
    { lat: 49.212001, lng: 19.093993 },
    { lat: 49.211789, lng: 19.093962 },
    { lat: 49.212038, lng: 19.094466 },
    { lat: 49.212291, lng: 19.094977 },
    { lat: 49.212664, lng: 19.095714 },
    { lat: 49.213111, lng: 19.096611 },
    { lat: 49.213891, lng: 19.097555 },
    { lat: 49.214647, lng: 19.098492 },
    { lat: 49.215212, lng: 19.099201 },
    { lat: 49.215726, lng: 19.099826 },
    { lat: 49.2164, lng: 19.100733 },
    { lat: 49.216742, lng: 19.101061 },
    { lat: 49.217207, lng: 19.101024 },
    { lat: 49.217731, lng: 19.101243 },
    { lat: 49.218301, lng: 19.101539 },
    { lat: 49.218841, lng: 19.101946 },
    { lat: 49.219357, lng: 19.102331 },
    { lat: 49.220122, lng: 19.103029 },
    { lat: 49.220713, lng: 19.10323 },
    { lat: 49.221402, lng: 19.10348 },
    { lat: 49.222012, lng: 19.103708 },
    { lat: 49.222637, lng: 19.103935 },
    { lat: 49.223129, lng: 19.104115 },
    { lat: 49.223574, lng: 19.104117 },
    { lat: 49.223956, lng: 19.104113 },
    { lat: 49.224817, lng: 19.104081 },
    { lat: 49.225322, lng: 19.103987 },
    { lat: 49.226587, lng: 19.103812 },
    { lat: 49.227623, lng: 19.103521 },
    { lat: 49.228678, lng: 19.102243 },
    { lat: 49.228712, lng: 19.102201 },
    { lat: 49.231076, lng: 19.099336 },
    { lat: 49.23159, lng: 19.098712 },
    { lat: 49.231741, lng: 19.100019 },
    { lat: 49.232007, lng: 19.10039 },
    { lat: 49.232199, lng: 19.100657 },
    { lat: 49.232283, lng: 19.100773 },
    { lat: 49.232783, lng: 19.101459 },
    { lat: 49.233228, lng: 19.102072 },
    { lat: 49.23359, lng: 19.102578 },
    { lat: 49.233767, lng: 19.102683 },
    { lat: 49.233965, lng: 19.1028 },
    { lat: 49.236164, lng: 19.104101 },
    { lat: 49.236167, lng: 19.104103 },
    { lat: 49.236323, lng: 19.104119 },
    { lat: 49.236433, lng: 19.104131 },
    { lat: 49.236593, lng: 19.104148 },
    { lat: 49.236857, lng: 19.104177 },
    { lat: 49.237631, lng: 19.104535 },
    { lat: 49.237724, lng: 19.104577 },
    { lat: 49.237816, lng: 19.10462 },
    { lat: 49.237817, lng: 19.104623 },
    { lat: 49.238068, lng: 19.105124 },
    { lat: 49.238144, lng: 19.105276 },
    { lat: 49.238145, lng: 19.105278 },
    { lat: 49.239151, lng: 19.105732 },
    { lat: 49.239355, lng: 19.105825 },
    { lat: 49.239809, lng: 19.10604 },
    { lat: 49.240365, lng: 19.106303 },
    { lat: 49.240601, lng: 19.106415 },
    { lat: 49.241002, lng: 19.10664 },
    { lat: 49.241396, lng: 19.106861 },
    { lat: 49.241614, lng: 19.106983 },
    { lat: 49.241667, lng: 19.107013 },
    { lat: 49.241982, lng: 19.107161 },
    { lat: 49.241982, lng: 19.107162 },
    { lat: 49.24223, lng: 19.107279 },
    { lat: 49.242484, lng: 19.107377 },
    { lat: 49.242487, lng: 19.107379 },
    { lat: 49.24249, lng: 19.10738 },
    { lat: 49.242651, lng: 19.107336 },
    { lat: 49.24269, lng: 19.107324 },
    { lat: 49.243172, lng: 19.107198 },
    { lat: 49.243528, lng: 19.107106 },
    { lat: 49.243534, lng: 19.107104 },
    { lat: 49.244113, lng: 19.10691 },
    { lat: 49.24496, lng: 19.106626 },
    { lat: 49.245127, lng: 19.10657 },
    { lat: 49.245338, lng: 19.1065 },
    { lat: 49.245435, lng: 19.106458 },
    { lat: 49.246041, lng: 19.106198 },
    { lat: 49.246043, lng: 19.106198 },
    { lat: 49.246406, lng: 19.106233 },
    { lat: 49.246746, lng: 19.106264 },
    { lat: 49.247276, lng: 19.106314 },
    { lat: 49.247434, lng: 19.106811 },
    { lat: 49.247441, lng: 19.106829 },
    { lat: 49.247593, lng: 19.107435 },
    { lat: 49.247623, lng: 19.107554 },
    { lat: 49.248111, lng: 19.107395 },
    { lat: 49.248589, lng: 19.107354 },
    { lat: 49.250494, lng: 19.107194 },
    { lat: 49.250762, lng: 19.107172 },
    { lat: 49.250952, lng: 19.107156 },
    { lat: 49.251415, lng: 19.107117 },
    { lat: 49.251756, lng: 19.107304 },
    { lat: 49.252795, lng: 19.107875 },
    { lat: 49.252806, lng: 19.107876 },
    { lat: 49.253086, lng: 19.107914 },
    { lat: 49.254061, lng: 19.108046 },
    { lat: 49.25432, lng: 19.108714 },
    { lat: 49.254436, lng: 19.108679 },
    { lat: 49.254989, lng: 19.108519 },
    { lat: 49.255445, lng: 19.108385 },
    { lat: 49.255514, lng: 19.108364 },
    { lat: 49.256117, lng: 19.108507 },
    { lat: 49.256501, lng: 19.108598 },
    { lat: 49.256512, lng: 19.108597 },
    { lat: 49.257485, lng: 19.108526 },
    { lat: 49.258269, lng: 19.108468 },
    { lat: 49.25827, lng: 19.108466 },
    { lat: 49.258566, lng: 19.107856 },
    { lat: 49.258963, lng: 19.107038 },
    { lat: 49.258969, lng: 19.107024 },
    { lat: 49.259094, lng: 19.106803 },
    { lat: 49.259496, lng: 19.1059852 },
    { lat: 49.2598421, lng: 19.1054597 },
    { lat: 49.260413, lng: 19.104919 },
    { lat: 49.260709, lng: 19.104602 },
    { lat: 49.260963, lng: 19.104331 },
    { lat: 49.261456, lng: 19.104066 },
    { lat: 49.261533, lng: 19.104025 },
    { lat: 49.261937, lng: 19.103961 },
    { lat: 49.262149, lng: 19.103927 },
    { lat: 49.262205, lng: 19.103918 },
    { lat: 49.262269, lng: 19.103908 },
    { lat: 49.262747, lng: 19.10411 },
    { lat: 49.262798, lng: 19.104131 },
    { lat: 49.263004, lng: 19.104218 },
    { lat: 49.263255, lng: 19.104324 },
    { lat: 49.263417, lng: 19.104392 },
    { lat: 49.263637, lng: 19.104485 },
    { lat: 49.264095, lng: 19.104677 },
    { lat: 49.26411, lng: 19.104683 },
    { lat: 49.264129, lng: 19.104675 },
    { lat: 49.264135, lng: 19.104672 },
    { lat: 49.264201, lng: 19.104644 },
    { lat: 49.264229, lng: 19.104631 },
    { lat: 49.264267, lng: 19.104616 },
    { lat: 49.264365, lng: 19.104261 },
    { lat: 49.264436, lng: 19.104179 },
    { lat: 49.26501, lng: 19.104088 },
    { lat: 49.265031, lng: 19.104091 },
    { lat: 49.26506, lng: 19.104095 },
    { lat: 49.265347, lng: 19.104137 },
    { lat: 49.265441, lng: 19.10415 },
    { lat: 49.265671, lng: 19.104181 },
    { lat: 49.265829, lng: 19.104202 },
    { lat: 49.266163, lng: 19.104248 },
    { lat: 49.266345, lng: 19.104274 },
    { lat: 49.266449, lng: 19.104287 },
    { lat: 49.266787, lng: 19.104331 },
    { lat: 49.266787, lng: 19.104332 },
    { lat: 49.26704, lng: 19.104685 },
    { lat: 49.267043, lng: 19.104685 },
    { lat: 49.267297, lng: 19.104706 },
    { lat: 49.267316, lng: 19.104708 },
    { lat: 49.267325, lng: 19.104707 },
    { lat: 49.267472, lng: 19.104689 },
    { lat: 49.267523, lng: 19.104683 },
    { lat: 49.267527, lng: 19.104682 },
    { lat: 49.267538, lng: 19.104683 },
    { lat: 49.267783, lng: 19.104696 },
    { lat: 49.268106, lng: 19.104761 },
    { lat: 49.268164, lng: 19.104772 },
    { lat: 49.268178, lng: 19.104775 },
    { lat: 49.268224, lng: 19.104726 },
    { lat: 49.268249, lng: 19.104699 },
    { lat: 49.26836, lng: 19.10462 },
    { lat: 49.268361, lng: 19.104619 },
    { lat: 49.268369, lng: 19.104614 },
    { lat: 49.268693, lng: 19.10458 },
    { lat: 49.268849, lng: 19.104609 },
    { lat: 49.268874, lng: 19.104613 },
    { lat: 49.26906, lng: 19.104647 },
    { lat: 49.269194, lng: 19.104671 },
    { lat: 49.269223, lng: 19.104676 },
    { lat: 49.26929, lng: 19.104664 },
    { lat: 49.269522, lng: 19.10462 },
    { lat: 49.269625, lng: 19.1046 },
    { lat: 49.26965, lng: 19.104595 },
    { lat: 49.270247, lng: 19.10445 },
    { lat: 49.27044, lng: 19.104403 },
    { lat: 49.270899, lng: 19.104291 },
    { lat: 49.270967, lng: 19.104274 },
    { lat: 49.271457, lng: 19.104154 },
    { lat: 49.271544, lng: 19.104133 },
    { lat: 49.271548, lng: 19.104132 },
    { lat: 49.271899, lng: 19.103946 },
    { lat: 49.272063, lng: 19.103859 },
    { lat: 49.272372, lng: 19.103695 },
    { lat: 49.272583, lng: 19.103583 },
    { lat: 49.272594, lng: 19.103578 },
    { lat: 49.273086, lng: 19.103355 },
    { lat: 49.273594, lng: 19.103125 },
    { lat: 49.273602, lng: 19.103122 },
    { lat: 49.274404, lng: 19.102742 },
    { lat: 49.274601, lng: 19.102522 },
    { lat: 49.274906, lng: 19.10218 },
    { lat: 49.275111, lng: 19.10195 },
    { lat: 49.275287, lng: 19.101754 },
    { lat: 49.275493, lng: 19.101299 },
    { lat: 49.275551, lng: 19.101171 },
    { lat: 49.275776, lng: 19.100675 },
    { lat: 49.275886, lng: 19.100434 },
    { lat: 49.275942, lng: 19.100311 },
    { lat: 49.276038, lng: 19.100293 },
    { lat: 49.276283, lng: 19.100247 },
    { lat: 49.276415, lng: 19.100223 },
    { lat: 49.276427, lng: 19.100224 },
    { lat: 49.276876, lng: 19.100284 },
    { lat: 49.277309, lng: 19.100341 },
    { lat: 49.278363, lng: 19.100473 },
    { lat: 49.278387, lng: 19.100476 },
    { lat: 49.279098, lng: 19.100684 },
    { lat: 49.279108, lng: 19.10069 },
    { lat: 49.279158, lng: 19.100719 },
    { lat: 49.279791, lng: 19.101086 },
    { lat: 49.2800275, lng: 19.1012232 },
    { lat: 49.280096, lng: 19.101263 },
    { lat: 49.280431, lng: 19.101458 },
    { lat: 49.28059, lng: 19.10155 },
    { lat: 49.280616, lng: 19.101565 },
    { lat: 49.281039, lng: 19.102013 },
    { lat: 49.281211, lng: 19.102195 },
    { lat: 49.282574, lng: 19.103116 },
    { lat: 49.282768, lng: 19.103176 },
    { lat: 49.283145, lng: 19.103294 },
    { lat: 49.283291, lng: 19.10334 },
    { lat: 49.284094, lng: 19.103591 },
    { lat: 49.284311, lng: 19.103659 },
    { lat: 49.284312, lng: 19.103659 },
    { lat: 49.285401, lng: 19.103673 },
    { lat: 49.285446, lng: 19.103674 },
    { lat: 49.28639, lng: 19.103087 },
    { lat: 49.286789, lng: 19.102757 },
    { lat: 49.287178, lng: 19.102436 },
    { lat: 49.287505, lng: 19.102165 },
    { lat: 49.287521, lng: 19.102148 },
    { lat: 49.287554, lng: 19.102113 },
    { lat: 49.287688, lng: 19.101972 },
    { lat: 49.287926, lng: 19.101885 },
    { lat: 49.287962, lng: 19.101891 },
    { lat: 49.288081, lng: 19.101912 },
    { lat: 49.288336, lng: 19.101956 },
    { lat: 49.288436, lng: 19.102105 },
    { lat: 49.288958, lng: 19.102976 },
    { lat: 49.289186, lng: 19.1033 },
    { lat: 49.289387, lng: 19.103587 },
    { lat: 49.289634, lng: 19.10394 },
    { lat: 49.289671, lng: 19.103992 },
    { lat: 49.289677, lng: 19.104 },
    { lat: 49.289779, lng: 19.10418 },
    { lat: 49.289788, lng: 19.104195 },
    { lat: 49.289935, lng: 19.104386 },
    { lat: 49.290124, lng: 19.104633 },
    { lat: 49.290357, lng: 19.105014 },
    { lat: 49.290362, lng: 19.105021 },
    { lat: 49.290871, lng: 19.10547 },
    { lat: 49.290882, lng: 19.10548 },
    { lat: 49.290882, lng: 19.105481 },
    { lat: 49.291701, lng: 19.105783 },
    { lat: 49.292027, lng: 19.105999 },
    { lat: 49.292178, lng: 19.1061 },
    { lat: 49.292335, lng: 19.106269 },
    { lat: 49.292359, lng: 19.106293 },
    { lat: 49.29313, lng: 19.106731 },
    { lat: 49.293447, lng: 19.106838 },
    { lat: 49.293835, lng: 19.10697 },
    { lat: 49.293876, lng: 19.107003 },
    { lat: 49.294072, lng: 19.107157 },
    { lat: 49.294274, lng: 19.107315 },
    { lat: 49.294786, lng: 19.107729 },
    { lat: 49.29522, lng: 19.10818 },
    { lat: 49.295225, lng: 19.108184 },
    { lat: 49.295283, lng: 19.108274 },
    { lat: 49.295655, lng: 19.10884 },
    { lat: 49.295693, lng: 19.108898 },
    { lat: 49.295697, lng: 19.108906 },
    { lat: 49.295868, lng: 19.108987 },
    { lat: 49.295948, lng: 19.109025 },
    { lat: 49.296164, lng: 19.109128 },
    { lat: 49.296715, lng: 19.10939 },
    { lat: 49.29689, lng: 19.109473 },
    { lat: 49.297149, lng: 19.109547 },
    { lat: 49.297221, lng: 19.109567 },
    { lat: 49.298335, lng: 19.109881 },
    { lat: 49.298335, lng: 19.109883 },
    { lat: 49.298492, lng: 19.109998 },
    { lat: 49.298794, lng: 19.110221 },
    { lat: 49.298886, lng: 19.110376 },
    { lat: 49.299035, lng: 19.110628 },
    { lat: 49.299037, lng: 19.110631 },
    { lat: 49.299334, lng: 19.111016 },
    { lat: 49.299375, lng: 19.111077 },
    { lat: 49.299634, lng: 19.111474 },
    { lat: 49.299763, lng: 19.11163 },
    { lat: 49.299911, lng: 19.112009 },
    { lat: 49.300157, lng: 19.112386 },
    { lat: 49.300269, lng: 19.112561 },
    { lat: 49.300641, lng: 19.114264 },
    { lat: 49.300642, lng: 19.114277 },
    { lat: 49.300647, lng: 19.114303 },
    { lat: 49.30074, lng: 19.114304 },
    { lat: 49.301047, lng: 19.114309 },
    { lat: 49.301799, lng: 19.114353 },
    { lat: 49.301806, lng: 19.114353 },
    { lat: 49.302898, lng: 19.114675 },
    { lat: 49.303014, lng: 19.11475 },
    { lat: 49.303056, lng: 19.114776 },
    { lat: 49.303058, lng: 19.114776 },
    { lat: 49.303568, lng: 19.114615 },
    { lat: 49.303571, lng: 19.114614 },
    { lat: 49.303886, lng: 19.114636 },
    { lat: 49.304285, lng: 19.114664 },
    { lat: 49.30489, lng: 19.11481 },
    { lat: 49.304978, lng: 19.114831 },
    { lat: 49.305056, lng: 19.1151 },
    { lat: 49.305195, lng: 19.115572 },
    { lat: 49.305538, lng: 19.116535 },
    { lat: 49.305915, lng: 19.117293 },
    { lat: 49.306056, lng: 19.118671 },
    { lat: 49.306101, lng: 19.119111 },
    { lat: 49.306272, lng: 19.119772 },
    { lat: 49.306483, lng: 19.120584 },
    { lat: 49.306958, lng: 19.121392 },
    { lat: 49.30702, lng: 19.121499 },
    { lat: 49.307782, lng: 19.122947 },
    { lat: 49.309055, lng: 19.122551 },
    { lat: 49.310216, lng: 19.122118 },
    { lat: 49.310965, lng: 19.121838 },
    { lat: 49.311609, lng: 19.121598 },
    { lat: 49.311656, lng: 19.12148 },
    { lat: 49.311333, lng: 19.121198 },
    { lat: 49.311262, lng: 19.121108 },
    { lat: 49.311147, lng: 19.121013 },
    { lat: 49.311022, lng: 19.120821 },
    { lat: 49.310804, lng: 19.120496 },
    { lat: 49.31071, lng: 19.120417 },
    { lat: 49.310601, lng: 19.120313 },
    { lat: 49.310343, lng: 19.120099 },
    { lat: 49.310011, lng: 19.119609 },
    { lat: 49.309673, lng: 19.119373 },
    { lat: 49.309325, lng: 19.119157 },
    { lat: 49.309034, lng: 19.118811 },
    { lat: 49.308845, lng: 19.118519 },
    { lat: 49.308828, lng: 19.118511 },
    { lat: 49.308623, lng: 19.117958 },
    { lat: 49.308582, lng: 19.117852 },
    { lat: 49.308451, lng: 19.117439 },
    { lat: 49.30833, lng: 19.11711 },
    { lat: 49.308228, lng: 19.116919 },
    { lat: 49.308119, lng: 19.116705 },
    { lat: 49.308015, lng: 19.116382 },
    { lat: 49.307905, lng: 19.115924 },
    { lat: 49.307869, lng: 19.115476 },
    { lat: 49.307719, lng: 19.115096 },
    { lat: 49.307609, lng: 19.114699 },
    { lat: 49.307585, lng: 19.114516 },
    { lat: 49.307646, lng: 19.114157 },
    { lat: 49.307671, lng: 19.113965 },
    { lat: 49.307584, lng: 19.113633 },
    { lat: 49.30766, lng: 19.113383 },
    { lat: 49.308768, lng: 19.113675 },
    { lat: 49.309356, lng: 19.113248 },
    { lat: 49.309304, lng: 19.111954 },
    { lat: 49.309649, lng: 19.111837 },
    { lat: 49.309429, lng: 19.110122 },
    { lat: 49.309409, lng: 19.109887 },
    { lat: 49.309758, lng: 19.10801 },
    { lat: 49.311046, lng: 19.108804 },
    { lat: 49.311141, lng: 19.108871 },
    { lat: 49.311171, lng: 19.108886 },
    { lat: 49.311151, lng: 19.108706 },
    { lat: 49.311169, lng: 19.108266 },
    { lat: 49.311093, lng: 19.107898 },
    { lat: 49.311016, lng: 19.107651 },
    { lat: 49.310978, lng: 19.107252 },
    { lat: 49.311015, lng: 19.106625 },
    { lat: 49.31097, lng: 19.10638 },
    { lat: 49.310897, lng: 19.106095 },
    { lat: 49.310799, lng: 19.105729 },
    { lat: 49.310632, lng: 19.105463 },
    { lat: 49.310608, lng: 19.105358 },
    { lat: 49.310598, lng: 19.105328 },
    { lat: 49.310614, lng: 19.105154 },
    { lat: 49.310715, lng: 19.104774 },
    { lat: 49.310788, lng: 19.104716 },
    { lat: 49.310963, lng: 19.104744 },
    { lat: 49.311039, lng: 19.104744 },
    { lat: 49.311115, lng: 19.104654 },
    { lat: 49.311185, lng: 19.104224 },
    { lat: 49.31119, lng: 19.10393 },
    { lat: 49.311056, lng: 19.103538 },
    { lat: 49.311046, lng: 19.103488 },
    { lat: 49.311844, lng: 19.101777 },
    { lat: 49.311915, lng: 19.10144 },
    { lat: 49.311864, lng: 19.100532 },
    { lat: 49.311895, lng: 19.099649 },
    { lat: 49.311842, lng: 19.099271 },
    { lat: 49.311734, lng: 19.098705 },
    { lat: 49.311719, lng: 19.098362 },
    { lat: 49.311743, lng: 19.098086 },
    { lat: 49.311808, lng: 19.097702 },
    { lat: 49.311783, lng: 19.097688 },
    { lat: 49.311342, lng: 19.09745 },
    { lat: 49.311032, lng: 19.09729 },
    { lat: 49.310183, lng: 19.097179 },
    { lat: 49.309987, lng: 19.097213 },
    { lat: 49.310133, lng: 19.098127 },
    { lat: 49.309814, lng: 19.098383 },
    { lat: 49.309726, lng: 19.098444 },
    { lat: 49.309631, lng: 19.098338 },
    { lat: 49.309583, lng: 19.098282 },
    { lat: 49.309347, lng: 19.098017 },
    { lat: 49.309144, lng: 19.097654 },
    { lat: 49.308989, lng: 19.097381 },
    { lat: 49.308973, lng: 19.097102 },
    { lat: 49.308924, lng: 19.097027 },
    { lat: 49.30877, lng: 19.096784 },
    { lat: 49.308722, lng: 19.096909 },
    { lat: 49.308609, lng: 19.096159 },
    { lat: 49.308806, lng: 19.095427 },
    { lat: 49.308629, lng: 19.094946 },
    { lat: 49.308487, lng: 19.094476 },
    { lat: 49.308391, lng: 19.094653 },
    { lat: 49.308318, lng: 19.094596 },
    { lat: 49.308101, lng: 19.09429 },
    { lat: 49.307935, lng: 19.094053 },
    { lat: 49.307772, lng: 19.094228 },
    { lat: 49.307709, lng: 19.09402 },
    { lat: 49.307674, lng: 19.093739 },
    { lat: 49.307546, lng: 19.093444 },
    { lat: 49.307446, lng: 19.093493 },
    { lat: 49.307308, lng: 19.09357 },
    { lat: 49.307294, lng: 19.0936 },
    { lat: 49.307184, lng: 19.09383 },
    { lat: 49.307183, lng: 19.093851 },
    { lat: 49.307148, lng: 19.093887 },
    { lat: 49.307011, lng: 19.094021 },
    { lat: 49.306947, lng: 19.094086 },
    { lat: 49.306919, lng: 19.09411 },
    { lat: 49.306846, lng: 19.094174 },
    { lat: 49.306778, lng: 19.094235 },
    { lat: 49.306773, lng: 19.094238 },
    { lat: 49.306691, lng: 19.094287 },
    { lat: 49.306605, lng: 19.094338 },
    { lat: 49.306589, lng: 19.094348 },
    { lat: 49.306567, lng: 19.09436 },
    { lat: 49.306515, lng: 19.094383 },
    { lat: 49.306371, lng: 19.094469 },
    { lat: 49.306211, lng: 19.094557 },
    { lat: 49.305988, lng: 19.093091 },
    { lat: 49.305972, lng: 19.092999 },
    { lat: 49.305769, lng: 19.091799 },
    { lat: 49.305734, lng: 19.091609 },
    { lat: 49.305683, lng: 19.091282 },
    { lat: 49.305654, lng: 19.091254 },
    { lat: 49.305638, lng: 19.091247 },
    { lat: 49.305633, lng: 19.091164 },
    { lat: 49.305378, lng: 19.089942 },
    { lat: 49.305335, lng: 19.089275 },
    { lat: 49.305306, lng: 19.088774 },
    { lat: 49.305303, lng: 19.088759 },
    { lat: 49.305179, lng: 19.088149 },
    { lat: 49.305174, lng: 19.088098 },
    { lat: 49.305109, lng: 19.087969 },
    { lat: 49.304856, lng: 19.087325 },
    { lat: 49.304678, lng: 19.08685 },
    { lat: 49.304571, lng: 19.086565 },
    { lat: 49.304453, lng: 19.086231 },
    { lat: 49.304432, lng: 19.086129 },
    { lat: 49.304424, lng: 19.086088 },
    { lat: 49.304352, lng: 19.085689 },
    { lat: 49.30434, lng: 19.085363 },
    { lat: 49.304306, lng: 19.085021 },
    { lat: 49.304208, lng: 19.084548 },
    { lat: 49.304198, lng: 19.084318 },
    { lat: 49.303985, lng: 19.083879 },
    { lat: 49.30379, lng: 19.083302 },
    { lat: 49.303716, lng: 19.08309 },
    { lat: 49.303683, lng: 19.082938 },
    { lat: 49.303676, lng: 19.082908 },
    { lat: 49.303646, lng: 19.082634 },
    { lat: 49.303602, lng: 19.082382 },
    { lat: 49.303343, lng: 19.08131 },
    { lat: 49.303199, lng: 19.080922 },
    { lat: 49.303173, lng: 19.080867 },
    { lat: 49.303074, lng: 19.080429 },
    { lat: 49.302896, lng: 19.080046 },
    { lat: 49.302483, lng: 19.079394 },
    { lat: 49.302426, lng: 19.079064 },
    { lat: 49.302425, lng: 19.079037 },
    { lat: 49.302412, lng: 19.078807 },
    { lat: 49.302434, lng: 19.078685 },
    { lat: 49.302356, lng: 19.07859 },
    { lat: 49.3023, lng: 19.078472 },
    { lat: 49.302245, lng: 19.078357 },
    { lat: 49.3021647, lng: 19.0781199 },
    { lat: 49.302074, lng: 19.078041 },
    { lat: 49.302034, lng: 19.077898 },
    { lat: 49.301976, lng: 19.077394 },
    { lat: 49.301923, lng: 19.076827 },
    { lat: 49.30188, lng: 19.076573 },
    { lat: 49.3018378, lng: 19.076364 },
    { lat: 49.301714, lng: 19.076001 },
    { lat: 49.301675, lng: 19.075715 },
    { lat: 49.30166, lng: 19.075411 },
    { lat: 49.30166, lng: 19.074942 },
    { lat: 49.301684, lng: 19.074551 },
    { lat: 49.301694, lng: 19.074398 },
    { lat: 49.301695, lng: 19.074358 },
    { lat: 49.301695, lng: 19.074239 },
    { lat: 49.301696, lng: 19.074071 },
    { lat: 49.301696, lng: 19.073726 },
    { lat: 49.3017, lng: 19.0737 },
    { lat: 49.301794, lng: 19.073067 },
    { lat: 49.301795, lng: 19.072786 },
    { lat: 49.301795, lng: 19.072647 },
    { lat: 49.301796, lng: 19.072554 },
    { lat: 49.301796, lng: 19.07246 },
    { lat: 49.301797, lng: 19.072381 },
    { lat: 49.301743, lng: 19.07236 },
    { lat: 49.300966, lng: 19.072065 },
    { lat: 49.300807, lng: 19.071975 },
    { lat: 49.300701, lng: 19.071916 },
    { lat: 49.300459, lng: 19.071644 },
    { lat: 49.300159, lng: 19.07113 },
    { lat: 49.300029, lng: 19.070909 },
    { lat: 49.299781, lng: 19.070235 },
    { lat: 49.299763, lng: 19.06982 },
    { lat: 49.299542, lng: 19.069468 },
    { lat: 49.29936, lng: 19.06913 },
    { lat: 49.299359, lng: 19.068834 },
    { lat: 49.299169, lng: 19.068482 },
    { lat: 49.298994, lng: 19.068155 },
    { lat: 49.298675, lng: 19.067561 },
    { lat: 49.298595, lng: 19.06726 },
    { lat: 49.298491, lng: 19.06687 },
    { lat: 49.298456, lng: 19.066737 },
    { lat: 49.29842, lng: 19.066599 },
    { lat: 49.29841, lng: 19.066513 },
    { lat: 49.298395, lng: 19.066368 },
    { lat: 49.298379, lng: 19.066217 },
    { lat: 49.29836, lng: 19.066039 },
    { lat: 49.298476, lng: 19.065375 },
    { lat: 49.298585, lng: 19.064748 },
    { lat: 49.298595, lng: 19.06469 },
    { lat: 49.2986, lng: 19.064665 },
    { lat: 49.29891, lng: 19.064415 },
    { lat: 49.299001, lng: 19.064343 },
    { lat: 49.299169, lng: 19.064331 },
    { lat: 49.299451, lng: 19.064312 },
    { lat: 49.299639, lng: 19.064299 },
    { lat: 49.299689, lng: 19.064313 },
    { lat: 49.299798, lng: 19.064344 },
    { lat: 49.299979, lng: 19.064396 },
    { lat: 49.300128, lng: 19.064439 },
    { lat: 49.300156, lng: 19.064447 },
    { lat: 49.300265, lng: 19.06506 },
    { lat: 49.3004, lng: 19.065196 },
    { lat: 49.300635, lng: 19.065434 },
    { lat: 49.300813, lng: 19.065614 },
    { lat: 49.301144, lng: 19.065948 },
    { lat: 49.301174, lng: 19.065978 },
    { lat: 49.301353, lng: 19.066051 },
    { lat: 49.3014548, lng: 19.065949 },
    { lat: 49.301546, lng: 19.06613 },
    { lat: 49.301605, lng: 19.066131 },
    { lat: 49.301649, lng: 19.066132 },
    { lat: 49.301745, lng: 19.066133 },
    { lat: 49.301832, lng: 19.066134 },
    { lat: 49.301862, lng: 19.066134 },
    { lat: 49.302381, lng: 19.06614 },
    { lat: 49.302452, lng: 19.066157 },
    { lat: 49.302789, lng: 19.066238 },
    { lat: 49.302863, lng: 19.066256 },
    { lat: 49.302912, lng: 19.066267 },
    { lat: 49.303064, lng: 19.066304 },
    { lat: 49.303166, lng: 19.065696 },
    { lat: 49.303254, lng: 19.065181 },
    { lat: 49.303288, lng: 19.065057 },
    { lat: 49.303361, lng: 19.064799 },
    { lat: 49.303539, lng: 19.064494 },
    { lat: 49.303594, lng: 19.064401 },
    { lat: 49.303609, lng: 19.064374 },
    { lat: 49.303645, lng: 19.063651 },
    { lat: 49.303685, lng: 19.06363 },
    { lat: 49.303677, lng: 19.063041 },
    { lat: 49.303645, lng: 19.062872 },
    { lat: 49.303684, lng: 19.062825 },
    { lat: 49.303709, lng: 19.061457 },
    { lat: 49.304008, lng: 19.061283 },
    { lat: 49.304307, lng: 19.061309 },
    { lat: 49.304305, lng: 19.061205 },
    { lat: 49.304293, lng: 19.060503 },
    { lat: 49.303952, lng: 19.059554 },
    { lat: 49.303586, lng: 19.058149 },
    { lat: 49.303904, lng: 19.058033 },
    { lat: 49.303921, lng: 19.057694 },
    { lat: 49.303952, lng: 19.057025 },
    { lat: 49.303964, lng: 19.056771 },
    { lat: 49.303917, lng: 19.056086 },
    { lat: 49.304061, lng: 19.056025 },
    { lat: 49.304285, lng: 19.055932 },
    { lat: 49.304408, lng: 19.055881 },
    { lat: 49.304472, lng: 19.055854 },
    { lat: 49.304659, lng: 19.055777 },
    { lat: 49.304773, lng: 19.05573 },
    { lat: 49.305107, lng: 19.055591 },
    { lat: 49.305092, lng: 19.05548 },
    { lat: 49.305041, lng: 19.055131 },
    { lat: 49.304968, lng: 19.054629 },
    { lat: 49.304935, lng: 19.054397 },
    { lat: 49.304901, lng: 19.054167 },
    { lat: 49.304876, lng: 19.053993 },
    { lat: 49.304287, lng: 19.0533 },
    { lat: 49.304088, lng: 19.052837 },
    { lat: 49.303946, lng: 19.052505 },
    { lat: 49.303872, lng: 19.052344 },
    { lat: 49.303639, lng: 19.051841 },
    { lat: 49.303423, lng: 19.051615 },
    { lat: 49.303064, lng: 19.051241 },
    { lat: 49.302971, lng: 19.051144 },
    { lat: 49.302825, lng: 19.050992 },
    { lat: 49.302763, lng: 19.050928 },
    { lat: 49.302501, lng: 19.050531 },
    { lat: 49.302261, lng: 19.05017 },
    { lat: 49.302226, lng: 19.050116 },
    { lat: 49.302131, lng: 19.049805 },
    { lat: 49.302008, lng: 19.049408 },
    { lat: 49.302157, lng: 19.049214 },
    { lat: 49.301685, lng: 19.048546 },
    { lat: 49.303037, lng: 19.046758 },
    { lat: 49.302992, lng: 19.045856 },
    { lat: 49.302968, lng: 19.045346 },
    { lat: 49.303068, lng: 19.045332 },
    { lat: 49.303265, lng: 19.045305 },
    { lat: 49.303516, lng: 19.045271 },
    { lat: 49.303649, lng: 19.045252 },
    { lat: 49.303777, lng: 19.045396 },
    { lat: 49.30396, lng: 19.045147 },
    { lat: 49.303977, lng: 19.045122 },
    { lat: 49.304096, lng: 19.045186 },
    { lat: 49.304105, lng: 19.04525 },
    { lat: 49.304148, lng: 19.045524 },
    { lat: 49.305056, lng: 19.04503 },
    { lat: 49.305059, lng: 19.045028 },
    { lat: 49.305061, lng: 19.045022 },
    { lat: 49.305421, lng: 19.044093 },
    { lat: 49.305814, lng: 19.044077 },
    { lat: 49.306335, lng: 19.044056 },
    { lat: 49.306377, lng: 19.043672 },
    { lat: 49.308066, lng: 19.041073 },
    { lat: 49.309766, lng: 19.038463 },
    { lat: 49.308052, lng: 19.037051 },
    { lat: 49.307989, lng: 19.035872 },
    { lat: 49.307906, lng: 19.035937 },
    { lat: 49.307629, lng: 19.036148 },
    { lat: 49.307886, lng: 19.036993 },
    { lat: 49.306748, lng: 19.036068 },
    { lat: 49.30606, lng: 19.036113 },
    { lat: 49.305848, lng: 19.035658 },
    { lat: 49.304502, lng: 19.032777 },
    { lat: 49.304478, lng: 19.031856 },
    { lat: 49.304375, lng: 19.031442 },
    { lat: 49.304364, lng: 19.031395 },
    { lat: 49.304109, lng: 19.030367 },
    { lat: 49.30416, lng: 19.030254 },
    { lat: 49.305625, lng: 19.026962 },
    { lat: 49.305965, lng: 19.026198 },
    { lat: 49.305715, lng: 19.02598 },
    { lat: 49.305673, lng: 19.025718 },
    { lat: 49.305589, lng: 19.025188 },
    { lat: 49.305268, lng: 19.024741 },
    { lat: 49.305394, lng: 19.022853 },
    { lat: 49.304694, lng: 19.022394 },
    { lat: 49.305459, lng: 19.022381 },
    { lat: 49.305609, lng: 19.022264 },
    { lat: 49.305981, lng: 19.021972 },
    { lat: 49.306042, lng: 19.021925 },
    { lat: 49.306253, lng: 19.02176 },
    { lat: 49.30729, lng: 19.020948 },
    { lat: 49.307196, lng: 19.019538 },
    { lat: 49.307534, lng: 19.019282 },
    { lat: 49.308014, lng: 19.018334 },
    { lat: 49.308441, lng: 19.018087 },
    { lat: 49.308536, lng: 19.017914 },
    { lat: 49.308813, lng: 19.017412 },
    { lat: 49.308811, lng: 19.016409 },
    { lat: 49.308953, lng: 19.015654 },
    { lat: 49.308951, lng: 19.014913 },
    { lat: 49.308411, lng: 19.012463 },
    { lat: 49.308937, lng: 19.012432 },
    { lat: 49.309905, lng: 19.01384 },
    { lat: 49.30996, lng: 19.013842 },
    { lat: 49.310565, lng: 19.01387 },
    { lat: 49.310824, lng: 19.015128 },
    { lat: 49.310853, lng: 19.015501 },
    { lat: 49.310862, lng: 19.015614 },
    { lat: 49.310901, lng: 19.016104 },
    { lat: 49.311005, lng: 19.015898 },
    { lat: 49.31103, lng: 19.015851 },
    { lat: 49.311098, lng: 19.015717 },
    { lat: 49.311206, lng: 19.015506 },
    { lat: 49.311434, lng: 19.015059 },
    { lat: 49.312209, lng: 19.013403 },
    { lat: 49.312389, lng: 19.013509 },
    { lat: 49.312395, lng: 19.013572 },
    { lat: 49.312599, lng: 19.013415 },
    { lat: 49.312581, lng: 19.012907 },
    { lat: 49.312559, lng: 19.01231 },
    { lat: 49.312522, lng: 19.011244 },
    { lat: 49.31252, lng: 19.011171 },
    { lat: 49.312511, lng: 19.010896 },
    { lat: 49.312507, lng: 19.010799 },
    { lat: 49.311356, lng: 19.009676 },
    { lat: 49.31014, lng: 19.009366 },
    { lat: 49.310101, lng: 19.009052 },
    { lat: 49.309971, lng: 19.008034 },
    { lat: 49.30966, lng: 19.008207 },
    { lat: 49.309316, lng: 19.008177 },
    { lat: 49.309188, lng: 19.008165 },
    { lat: 49.309133, lng: 19.008161 },
    { lat: 49.30909, lng: 19.007965 },
    { lat: 49.308983, lng: 19.007432 },
    { lat: 49.308915, lng: 19.00706 },
    { lat: 49.308668, lng: 19.00649 },
    { lat: 49.308389, lng: 19.005844 },
    { lat: 49.308064, lng: 19.005091 },
    { lat: 49.307529, lng: 19.004386 },
  ],
  Varín: [
    { lat: 49.1780029, lng: 18.9372025 },
    { lat: 49.1790472, lng: 18.9369199 },
    { lat: 49.1797547, lng: 18.9362424 },
    { lat: 49.1807455, lng: 18.9351434 },
    { lat: 49.1811692, lng: 18.9351176 },
    { lat: 49.1813985, lng: 18.9350362 },
    { lat: 49.1814928, lng: 18.934651 },
    { lat: 49.1814952, lng: 18.9338939 },
    { lat: 49.181383, lng: 18.9324155 },
    { lat: 49.1814106, lng: 18.9302882 },
    { lat: 49.181519, lng: 18.9293345 },
    { lat: 49.1816226, lng: 18.9289972 },
    { lat: 49.1821637, lng: 18.9286638 },
    { lat: 49.1830612, lng: 18.9275951 },
    { lat: 49.1836224, lng: 18.927193 },
    { lat: 49.1841932, lng: 18.9265307 },
    { lat: 49.1844419, lng: 18.9260537 },
    { lat: 49.1875876, lng: 18.9195878 },
    { lat: 49.1880674, lng: 18.9184704 },
    { lat: 49.1883476, lng: 18.9175712 },
    { lat: 49.188478, lng: 18.9172508 },
    { lat: 49.18849, lng: 18.9172213 },
    { lat: 49.1881508, lng: 18.9158914 },
    { lat: 49.1878714, lng: 18.9149397 },
    { lat: 49.1873352, lng: 18.9132611 },
    { lat: 49.18736, lng: 18.9132203 },
    { lat: 49.187306, lng: 18.913044 },
    { lat: 49.1873435, lng: 18.9127836 },
    { lat: 49.187365, lng: 18.9124942 },
    { lat: 49.1872007, lng: 18.9121488 },
    { lat: 49.1878921, lng: 18.9111974 },
    { lat: 49.188079, lng: 18.9109936 },
    { lat: 49.1890597, lng: 18.9100122 },
    { lat: 49.1894918, lng: 18.9095282 },
    { lat: 49.1897537, lng: 18.909224 },
    { lat: 49.1899933, lng: 18.9085292 },
    { lat: 49.1902663, lng: 18.9073777 },
    { lat: 49.1905949, lng: 18.9068983 },
    { lat: 49.1907543, lng: 18.9065543 },
    { lat: 49.1908894, lng: 18.9062608 },
    { lat: 49.1912454, lng: 18.9058787 },
    { lat: 49.1918546, lng: 18.9056455 },
    { lat: 49.1922371, lng: 18.9056727 },
    { lat: 49.1924346, lng: 18.9056997 },
    { lat: 49.1924801, lng: 18.9057081 },
    { lat: 49.1926603, lng: 18.9058129 },
    { lat: 49.1929892, lng: 18.9060742 },
    { lat: 49.1931918, lng: 18.9060913 },
    { lat: 49.1933932, lng: 18.9063256 },
    { lat: 49.1938974, lng: 18.9065958 },
    { lat: 49.1945811, lng: 18.9058761 },
    { lat: 49.1946826, lng: 18.9058493 },
    { lat: 49.1948277, lng: 18.9052232 },
    { lat: 49.1949399, lng: 18.905038 },
    { lat: 49.195197, lng: 18.9049034 },
    { lat: 49.1953027, lng: 18.9048544 },
    { lat: 49.1953708, lng: 18.9047725 },
    { lat: 49.1954472, lng: 18.9046347 },
    { lat: 49.1956051, lng: 18.9043954 },
    { lat: 49.195784, lng: 18.9042102 },
    { lat: 49.1959336, lng: 18.9041139 },
    { lat: 49.1965501, lng: 18.9038001 },
    { lat: 49.1967997, lng: 18.9035654 },
    { lat: 49.1969941, lng: 18.9033275 },
    { lat: 49.1971939, lng: 18.9030245 },
    { lat: 49.1976094, lng: 18.9021724 },
    { lat: 49.1978223, lng: 18.9018818 },
    { lat: 49.1979569, lng: 18.9017318 },
    { lat: 49.198217, lng: 18.9014609 },
    { lat: 49.1982905, lng: 18.9013563 },
    { lat: 49.1986466, lng: 18.9007066 },
    { lat: 49.1991302, lng: 18.8997076 },
    { lat: 49.1995044, lng: 18.8982468 },
    { lat: 49.200327, lng: 18.8963333 },
    { lat: 49.2004716, lng: 18.8954523 },
    { lat: 49.2004943, lng: 18.8953224 },
    { lat: 49.2006144, lng: 18.8946292 },
    { lat: 49.2011429, lng: 18.8937153 },
    { lat: 49.2018107, lng: 18.8924795 },
    { lat: 49.2022345, lng: 18.8910414 },
    { lat: 49.2029207, lng: 18.8888421 },
    { lat: 49.2032546, lng: 18.8877648 },
    { lat: 49.2036654, lng: 18.8864003 },
    { lat: 49.2036876, lng: 18.8863267 },
    { lat: 49.203736, lng: 18.8861738 },
    { lat: 49.2042103, lng: 18.8858326 },
    { lat: 49.2051072, lng: 18.8853341 },
    { lat: 49.2053612, lng: 18.8849217 },
    { lat: 49.2056212, lng: 18.8844711 },
    { lat: 49.2055721, lng: 18.8843472 },
    { lat: 49.2054032, lng: 18.8842414 },
    { lat: 49.2053721, lng: 18.8841171 },
    { lat: 49.2054191, lng: 18.8836921 },
    { lat: 49.2051855, lng: 18.8828924 },
    { lat: 49.2052079, lng: 18.8828205 },
    { lat: 49.2050811, lng: 18.8826366 },
    { lat: 49.2049494, lng: 18.8823529 },
    { lat: 49.2049663, lng: 18.8821097 },
    { lat: 49.2047419, lng: 18.8818007 },
    { lat: 49.2047191, lng: 18.8816823 },
    { lat: 49.2047102, lng: 18.8815539 },
    { lat: 49.2046436, lng: 18.8814208 },
    { lat: 49.2042502, lng: 18.8810681 },
    { lat: 49.2041505, lng: 18.8806606 },
    { lat: 49.2039653, lng: 18.880458 },
    { lat: 49.203947, lng: 18.8803397 },
    { lat: 49.2038275, lng: 18.8800543 },
    { lat: 49.2046545, lng: 18.8801543 },
    { lat: 49.2049391, lng: 18.8800552 },
    { lat: 49.2050529, lng: 18.8800525 },
    { lat: 49.2051019, lng: 18.8800827 },
    { lat: 49.2052087, lng: 18.8800776 },
    { lat: 49.205911, lng: 18.8800304 },
    { lat: 49.2060613, lng: 18.8800169 },
    { lat: 49.2093553, lng: 18.8798105 },
    { lat: 49.2106786, lng: 18.8797144 },
    { lat: 49.2107605, lng: 18.8797072 },
    { lat: 49.2107017, lng: 18.8793705 },
    { lat: 49.2107375, lng: 18.8793215 },
    { lat: 49.2112396, lng: 18.8793318 },
    { lat: 49.2116276, lng: 18.8793329 },
    { lat: 49.2125252, lng: 18.8794032 },
    { lat: 49.2134183, lng: 18.8794938 },
    { lat: 49.2143139, lng: 18.8795665 },
    { lat: 49.2152109, lng: 18.8796481 },
    { lat: 49.2163277, lng: 18.8800367 },
    { lat: 49.2172146, lng: 18.8806241 },
    { lat: 49.217257, lng: 18.8806583 },
    { lat: 49.2173812, lng: 18.8807503 },
    { lat: 49.2180559, lng: 18.8816882 },
    { lat: 49.2190428, lng: 18.8825335 },
    { lat: 49.2194657, lng: 18.8827133 },
    { lat: 49.2200217, lng: 18.8839334 },
    { lat: 49.220034, lng: 18.8839514 },
    { lat: 49.2209788, lng: 18.8859624 },
    { lat: 49.2211273, lng: 18.8863127 },
    { lat: 49.2212818, lng: 18.8867347 },
    { lat: 49.2213862, lng: 18.8869658 },
    { lat: 49.2214833, lng: 18.8871238 },
    { lat: 49.2216127, lng: 18.8873407 },
    { lat: 49.2217014, lng: 18.8875314 },
    { lat: 49.2217235, lng: 18.8875905 },
    { lat: 49.221735, lng: 18.8876154 },
    { lat: 49.2217894, lng: 18.887821 },
    { lat: 49.2218861, lng: 18.8879473 },
    { lat: 49.2222151, lng: 18.8886733 },
    { lat: 49.2225954, lng: 18.8892153 },
    { lat: 49.2227548, lng: 18.8890878 },
    { lat: 49.2230789, lng: 18.8890544 },
    { lat: 49.2234835, lng: 18.889077 },
    { lat: 49.2237638, lng: 18.8891332 },
    { lat: 49.2239469, lng: 18.8891843 },
    { lat: 49.2242098, lng: 18.8892768 },
    { lat: 49.2243486, lng: 18.8896351 },
    { lat: 49.2244118, lng: 18.8897224 },
    { lat: 49.2245745, lng: 18.8898632 },
    { lat: 49.224704, lng: 18.8900131 },
    { lat: 49.2248371, lng: 18.890125 },
    { lat: 49.2251762, lng: 18.8901868 },
    { lat: 49.2253771, lng: 18.8903061 },
    { lat: 49.2257168, lng: 18.8905551 },
    { lat: 49.226034, lng: 18.8911382 },
    { lat: 49.2262005, lng: 18.8913965 },
    { lat: 49.2264267, lng: 18.8918949 },
    { lat: 49.2264982, lng: 18.8919726 },
    { lat: 49.226712, lng: 18.8924266 },
    { lat: 49.2267472, lng: 18.8924542 },
    { lat: 49.226991, lng: 18.8928383 },
    { lat: 49.2274052, lng: 18.893442 },
    { lat: 49.2275364, lng: 18.8935188 },
    { lat: 49.2276777, lng: 18.8936355 },
    { lat: 49.2279323, lng: 18.893944 },
    { lat: 49.2279723, lng: 18.894085 },
    { lat: 49.2280611, lng: 18.894214 },
    { lat: 49.2282363, lng: 18.8944216 },
    { lat: 49.228285, lng: 18.8945799 },
    { lat: 49.2284922, lng: 18.8951502 },
    { lat: 49.2285982, lng: 18.8953537 },
    { lat: 49.2287193, lng: 18.8956247 },
    { lat: 49.2290115, lng: 18.896116 },
    { lat: 49.2291188, lng: 18.8962753 },
    { lat: 49.2292821, lng: 18.896252 },
    { lat: 49.2294968, lng: 18.8962842 },
    { lat: 49.2297384, lng: 18.8962223 },
    { lat: 49.2299433, lng: 18.8961486 },
    { lat: 49.2301683, lng: 18.896031 },
    { lat: 49.2304202, lng: 18.8959531 },
    { lat: 49.2306819, lng: 18.8959549 },
    { lat: 49.2309285, lng: 18.8957932 },
    { lat: 49.2312444, lng: 18.895487 },
    { lat: 49.2312929, lng: 18.895377 },
    { lat: 49.2314446, lng: 18.8952555 },
    { lat: 49.23145, lng: 18.8950979 },
    { lat: 49.2315366, lng: 18.8946691 },
    { lat: 49.231731, lng: 18.8944134 },
    { lat: 49.2321059, lng: 18.8943382 },
    { lat: 49.232281, lng: 18.8943658 },
    { lat: 49.2326517, lng: 18.8943863 },
    { lat: 49.2328202, lng: 18.8943956 },
    { lat: 49.2330646, lng: 18.894412 },
    { lat: 49.2331975, lng: 18.8944099 },
    { lat: 49.2332844, lng: 18.8940558 },
    { lat: 49.2333161, lng: 18.8940567 },
    { lat: 49.2337765, lng: 18.8940883 },
    { lat: 49.2342297, lng: 18.893979 },
    { lat: 49.2344911, lng: 18.8938191 },
    { lat: 49.2348491, lng: 18.8937225 },
    { lat: 49.2351018, lng: 18.8937661 },
    { lat: 49.2354621, lng: 18.8939817 },
    { lat: 49.2357216, lng: 18.8941172 },
    { lat: 49.2357541, lng: 18.8941328 },
    { lat: 49.2360483, lng: 18.8935776 },
    { lat: 49.2362997, lng: 18.8932032 },
    { lat: 49.2364752, lng: 18.8929103 },
    { lat: 49.2367017, lng: 18.8925086 },
    { lat: 49.2368147, lng: 18.8923115 },
    { lat: 49.237396, lng: 18.8915945 },
    { lat: 49.2376376, lng: 18.8911614 },
    { lat: 49.2383664, lng: 18.8911004 },
    { lat: 49.2387382, lng: 18.8906117 },
    { lat: 49.2388658, lng: 18.8904353 },
    { lat: 49.2389919, lng: 18.8902764 },
    { lat: 49.2391876, lng: 18.8900276 },
    { lat: 49.2392928, lng: 18.8898116 },
    { lat: 49.2394523, lng: 18.8894928 },
    { lat: 49.239527, lng: 18.8892757 },
    { lat: 49.2397703, lng: 18.8887835 },
    { lat: 49.2401574, lng: 18.8883717 },
    { lat: 49.2402787, lng: 18.8880971 },
    { lat: 49.2403089, lng: 18.88747 },
    { lat: 49.2403045, lng: 18.8865205 },
    { lat: 49.2403338, lng: 18.8853068 },
    { lat: 49.240373, lng: 18.8844813 },
    { lat: 49.2405358, lng: 18.883829 },
    { lat: 49.240875, lng: 18.8830306 },
    { lat: 49.2411124, lng: 18.8825997 },
    { lat: 49.2409635, lng: 18.8825983 },
    { lat: 49.2409466, lng: 18.8825999 },
    { lat: 49.2402487, lng: 18.8825952 },
    { lat: 49.2404255, lng: 18.8814196 },
    { lat: 49.2401523, lng: 18.8813023 },
    { lat: 49.2402332, lng: 18.8810773 },
    { lat: 49.2401383, lng: 18.8809634 },
    { lat: 49.2400496, lng: 18.8808603 },
    { lat: 49.2399293, lng: 18.8807562 },
    { lat: 49.2397456, lng: 18.881022 },
    { lat: 49.2394749, lng: 18.8808435 },
    { lat: 49.239399, lng: 18.8807908 },
    { lat: 49.2395135, lng: 18.8802753 },
    { lat: 49.2395417, lng: 18.8797675 },
    { lat: 49.2394706, lng: 18.8797204 },
    { lat: 49.2391354, lng: 18.8795142 },
    { lat: 49.2389442, lng: 18.8793927 },
    { lat: 49.2384116, lng: 18.8789188 },
    { lat: 49.2383797, lng: 18.8788898 },
    { lat: 49.2381906, lng: 18.8784999 },
    { lat: 49.2381509, lng: 18.8784721 },
    { lat: 49.238029, lng: 18.8783376 },
    { lat: 49.2379485, lng: 18.8782188 },
    { lat: 49.2379195, lng: 18.8781785 },
    { lat: 49.2375911, lng: 18.8777749 },
    { lat: 49.2373261, lng: 18.8773966 },
    { lat: 49.2372, lng: 18.8774444 },
    { lat: 49.2369237, lng: 18.8772258 },
    { lat: 49.2365917, lng: 18.8770153 },
    { lat: 49.2365044, lng: 18.8768977 },
    { lat: 49.2360581, lng: 18.8766326 },
    { lat: 49.2359384, lng: 18.8764769 },
    { lat: 49.2353887, lng: 18.8760203 },
    { lat: 49.2346198, lng: 18.8756255 },
    { lat: 49.2345419, lng: 18.8755813 },
    { lat: 49.2349191, lng: 18.8748838 },
    { lat: 49.2349602, lng: 18.8744961 },
    { lat: 49.2350125, lng: 18.8740205 },
    { lat: 49.2351648, lng: 18.8734272 },
    { lat: 49.235121, lng: 18.8733617 },
    { lat: 49.2349084, lng: 18.8732416 },
    { lat: 49.2336988, lng: 18.8724275 },
    { lat: 49.2335146, lng: 18.8722622 },
    { lat: 49.2331737, lng: 18.8719669 },
    { lat: 49.2325891, lng: 18.8715589 },
    { lat: 49.2321647, lng: 18.8711318 },
    { lat: 49.2317292, lng: 18.8706173 },
    { lat: 49.2314293, lng: 18.8704211 },
    { lat: 49.2309908, lng: 18.8701357 },
    { lat: 49.2307877, lng: 18.8700664 },
    { lat: 49.2306091, lng: 18.870012 },
    { lat: 49.2300921, lng: 18.8695577 },
    { lat: 49.2299455, lng: 18.8694024 },
    { lat: 49.2296336, lng: 18.8689958 },
    { lat: 49.2293819, lng: 18.8686557 },
    { lat: 49.2291574, lng: 18.8683448 },
    { lat: 49.2290317, lng: 18.8682905 },
    { lat: 49.2288442, lng: 18.8682818 },
    { lat: 49.228743, lng: 18.8682661 },
    { lat: 49.2290556, lng: 18.8674781 },
    { lat: 49.2290948, lng: 18.8667011 },
    { lat: 49.2291556, lng: 18.8660615 },
    { lat: 49.2292351, lng: 18.8646173 },
    { lat: 49.2295508, lng: 18.8644198 },
    { lat: 49.2296536, lng: 18.8642033 },
    { lat: 49.2298114, lng: 18.8638707 },
    { lat: 49.2299651, lng: 18.8634169 },
    { lat: 49.2301555, lng: 18.8631932 },
    { lat: 49.2301259, lng: 18.8630121 },
    { lat: 49.2301244, lng: 18.8628506 },
    { lat: 49.2301304, lng: 18.8627922 },
    { lat: 49.2300621, lng: 18.8624901 },
    { lat: 49.2300237, lng: 18.862183 },
    { lat: 49.2297206, lng: 18.8620561 },
    { lat: 49.2295914, lng: 18.8617973 },
    { lat: 49.2293586, lng: 18.8616335 },
    { lat: 49.2293172, lng: 18.8613326 },
    { lat: 49.2288851, lng: 18.8607979 },
    { lat: 49.228848, lng: 18.8606046 },
    { lat: 49.2282745, lng: 18.8601908 },
    { lat: 49.2279058, lng: 18.8599491 },
    { lat: 49.2277986, lng: 18.8597469 },
    { lat: 49.227611, lng: 18.8597613 },
    { lat: 49.2273952, lng: 18.8595977 },
    { lat: 49.227209, lng: 18.859708 },
    { lat: 49.2270183, lng: 18.8594396 },
    { lat: 49.2268645, lng: 18.8593844 },
    { lat: 49.2266868, lng: 18.8593933 },
    { lat: 49.2265779, lng: 18.8593374 },
    { lat: 49.226558, lng: 18.8590368 },
    { lat: 49.2264764, lng: 18.8590949 },
    { lat: 49.2264278, lng: 18.8592493 },
    { lat: 49.2262732, lng: 18.8590683 },
    { lat: 49.2260832, lng: 18.8587944 },
    { lat: 49.2260201, lng: 18.8587486 },
    { lat: 49.2259053, lng: 18.8588398 },
    { lat: 49.2258553, lng: 18.8589436 },
    { lat: 49.2255779, lng: 18.8590763 },
    { lat: 49.2254653, lng: 18.8591893 },
    { lat: 49.225399, lng: 18.859256 },
    { lat: 49.2252145, lng: 18.8596153 },
    { lat: 49.2248803, lng: 18.8595856 },
    { lat: 49.224642, lng: 18.8597121 },
    { lat: 49.2245588, lng: 18.8600243 },
    { lat: 49.2244459, lng: 18.8600861 },
    { lat: 49.2243893, lng: 18.8599948 },
    { lat: 49.2241205, lng: 18.8600752 },
    { lat: 49.2239772, lng: 18.8598286 },
    { lat: 49.2238256, lng: 18.85971 },
    { lat: 49.2236492, lng: 18.8595453 },
    { lat: 49.2234751, lng: 18.8596667 },
    { lat: 49.2233613, lng: 18.8598477 },
    { lat: 49.223185, lng: 18.8596848 },
    { lat: 49.2230331, lng: 18.8595785 },
    { lat: 49.2227502, lng: 18.8598027 },
    { lat: 49.2225422, lng: 18.8599213 },
    { lat: 49.2222796, lng: 18.8597957 },
    { lat: 49.2221856, lng: 18.8596295 },
    { lat: 49.2220895, lng: 18.8598015 },
    { lat: 49.2218875, lng: 18.859972 },
    { lat: 49.2215871, lng: 18.8600057 },
    { lat: 49.2215145, lng: 18.8598438 },
    { lat: 49.2214525, lng: 18.859823 },
    { lat: 49.2213868, lng: 18.8595979 },
    { lat: 49.2213015, lng: 18.8594501 },
    { lat: 49.2211839, lng: 18.859336 },
    { lat: 49.2209096, lng: 18.8591729 },
    { lat: 49.2206464, lng: 18.8591182 },
    { lat: 49.2201949, lng: 18.859022 },
    { lat: 49.2198742, lng: 18.8589038 },
    { lat: 49.2197765, lng: 18.8595069 },
    { lat: 49.2190367, lng: 18.8590845 },
    { lat: 49.2186018, lng: 18.8588688 },
    { lat: 49.2185743, lng: 18.8589453 },
    { lat: 49.2184241, lng: 18.8587905 },
    { lat: 49.2181817, lng: 18.8584822 },
    { lat: 49.2180574, lng: 18.8584772 },
    { lat: 49.2179558, lng: 18.8585586 },
    { lat: 49.2177256, lng: 18.8586686 },
    { lat: 49.2176698, lng: 18.8588326 },
    { lat: 49.2175406, lng: 18.8590601 },
    { lat: 49.2174228, lng: 18.8591639 },
    { lat: 49.2172986, lng: 18.8592476 },
    { lat: 49.2171459, lng: 18.8596521 },
    { lat: 49.217136, lng: 18.8596797 },
    { lat: 49.2170604, lng: 18.8598795 },
    { lat: 49.2170156, lng: 18.8599963 },
    { lat: 49.2170098, lng: 18.8600128 },
    { lat: 49.2169356, lng: 18.8602796 },
    { lat: 49.2169202, lng: 18.8603333 },
    { lat: 49.21686, lng: 18.8605414 },
    { lat: 49.2168418, lng: 18.860606 },
    { lat: 49.216791, lng: 18.8607819 },
    { lat: 49.2167526, lng: 18.8609138 },
    { lat: 49.2167085, lng: 18.8610626 },
    { lat: 49.2166864, lng: 18.8611374 },
    { lat: 49.2166652, lng: 18.8612081 },
    { lat: 49.2166698, lng: 18.861275 },
    { lat: 49.2166779, lng: 18.8614154 },
    { lat: 49.2166845, lng: 18.8615203 },
    { lat: 49.2167192, lng: 18.8618487 },
    { lat: 49.2167281, lng: 18.8624611 },
    { lat: 49.216664, lng: 18.8624857 },
    { lat: 49.216573, lng: 18.8621849 },
    { lat: 49.21649, lng: 18.8619269 },
    { lat: 49.2162752, lng: 18.8613664 },
    { lat: 49.2160981, lng: 18.8609035 },
    { lat: 49.2158982, lng: 18.8603211 },
    { lat: 49.2156335, lng: 18.860568 },
    { lat: 49.2153908, lng: 18.8605858 },
    { lat: 49.2152842, lng: 18.8604189 },
    { lat: 49.2150639, lng: 18.8601033 },
    { lat: 49.2148933, lng: 18.8598312 },
    { lat: 49.2145713, lng: 18.8594437 },
    { lat: 49.2139035, lng: 18.8587996 },
    { lat: 49.213138, lng: 18.8581859 },
    { lat: 49.2128062, lng: 18.8579597 },
    { lat: 49.212124, lng: 18.8576715 },
    { lat: 49.211546, lng: 18.8575025 },
    { lat: 49.2113501, lng: 18.8574004 },
    { lat: 49.2112192, lng: 18.8572852 },
    { lat: 49.2111813, lng: 18.8570062 },
    { lat: 49.2111638, lng: 18.8560757 },
    { lat: 49.2111247, lng: 18.8557966 },
    { lat: 49.2110694, lng: 18.8556175 },
    { lat: 49.2110623, lng: 18.8555469 },
    { lat: 49.2107998, lng: 18.8550867 },
    { lat: 49.2106276, lng: 18.8547424 },
    { lat: 49.2106085, lng: 18.8546783 },
    { lat: 49.2103818, lng: 18.8538413 },
    { lat: 49.2103224, lng: 18.8536043 },
    { lat: 49.2102776, lng: 18.8534578 },
    { lat: 49.2101699, lng: 18.8531371 },
    { lat: 49.2101464, lng: 18.8530754 },
    { lat: 49.2100449, lng: 18.8528325 },
    { lat: 49.2099522, lng: 18.8526514 },
    { lat: 49.2098957, lng: 18.8525402 },
    { lat: 49.2098245, lng: 18.8524056 },
    { lat: 49.2097258, lng: 18.8522176 },
    { lat: 49.2096784, lng: 18.852127 },
    { lat: 49.209558, lng: 18.8518886 },
    { lat: 49.2095609, lng: 18.8518817 },
    { lat: 49.2091935, lng: 18.8511799 },
    { lat: 49.2090773, lng: 18.8509597 },
    { lat: 49.2087354, lng: 18.8512404 },
    { lat: 49.2089072, lng: 18.8500619 },
    { lat: 49.2088852, lng: 18.850031 },
    { lat: 49.2087156, lng: 18.8498659 },
    { lat: 49.2088518, lng: 18.8495082 },
    { lat: 49.2089445, lng: 18.8492284 },
    { lat: 49.2085291, lng: 18.8488223 },
    { lat: 49.2080788, lng: 18.8484005 },
    { lat: 49.2073971, lng: 18.8477258 },
    { lat: 49.2070944, lng: 18.8474537 },
    { lat: 49.2066868, lng: 18.8470895 },
    { lat: 49.2058502, lng: 18.8463638 },
    { lat: 49.2055037, lng: 18.8460515 },
    { lat: 49.2052143, lng: 18.845771 },
    { lat: 49.2048904, lng: 18.8454358 },
    { lat: 49.2044066, lng: 18.8450188 },
    { lat: 49.2039727, lng: 18.8446716 },
    { lat: 49.203595, lng: 18.8444074 },
    { lat: 49.2030214, lng: 18.8440404 },
    { lat: 49.2029633, lng: 18.8440088 },
    { lat: 49.2028753, lng: 18.8440298 },
    { lat: 49.2027956, lng: 18.844102 },
    { lat: 49.2028091, lng: 18.8440568 },
    { lat: 49.2028624, lng: 18.8438831 },
    { lat: 49.2029373, lng: 18.8434555 },
    { lat: 49.2031117, lng: 18.8426807 },
    { lat: 49.2031913, lng: 18.8423658 },
    { lat: 49.2032006, lng: 18.8422395 },
    { lat: 49.2032125, lng: 18.8419013 },
    { lat: 49.2031304, lng: 18.8418621 },
    { lat: 49.2029095, lng: 18.8417296 },
    { lat: 49.2028582, lng: 18.841696 },
    { lat: 49.2028332, lng: 18.8417802 },
    { lat: 49.2028234, lng: 18.8418095 },
    { lat: 49.2026525, lng: 18.8417769 },
    { lat: 49.2024591, lng: 18.8417406 },
    { lat: 49.2024464, lng: 18.8417381 },
    { lat: 49.2022749, lng: 18.8417565 },
    { lat: 49.2022446, lng: 18.8417601 },
    { lat: 49.2022359, lng: 18.8417662 },
    { lat: 49.2020772, lng: 18.8419691 },
    { lat: 49.2019317, lng: 18.8422515 },
    { lat: 49.2019037, lng: 18.8422988 },
    { lat: 49.2017207, lng: 18.842426 },
    { lat: 49.2015528, lng: 18.8425373 },
    { lat: 49.2014991, lng: 18.8425676 },
    { lat: 49.2012858, lng: 18.8425188 },
    { lat: 49.2009493, lng: 18.8424661 },
    { lat: 49.2006725, lng: 18.842258 },
    { lat: 49.2004473, lng: 18.8420643 },
    { lat: 49.2002261, lng: 18.8418654 },
    { lat: 49.2000747, lng: 18.8417047 },
    { lat: 49.2000123, lng: 18.8416303 },
    { lat: 49.1995252, lng: 18.8413663 },
    { lat: 49.1991034, lng: 18.8411673 },
    { lat: 49.1988427, lng: 18.8410308 },
    { lat: 49.1985833, lng: 18.8408982 },
    { lat: 49.1983585, lng: 18.8407585 },
    { lat: 49.1977604, lng: 18.8403524 },
    { lat: 49.1969802, lng: 18.8398044 },
    { lat: 49.1966014, lng: 18.8395422 },
    { lat: 49.19644, lng: 18.8394076 },
    { lat: 49.1961851, lng: 18.8392287 },
    { lat: 49.1957109, lng: 18.8388892 },
    { lat: 49.1952767, lng: 18.8385587 },
    { lat: 49.1951443, lng: 18.8385262 },
    { lat: 49.1950693, lng: 18.8385601 },
    { lat: 49.1950046, lng: 18.8386612 },
    { lat: 49.1949297, lng: 18.8390894 },
    { lat: 49.1947782, lng: 18.8396707 },
    { lat: 49.1941598, lng: 18.840474 },
    { lat: 49.1940323, lng: 18.8404533 },
    { lat: 49.1939903, lng: 18.8404457 },
    { lat: 49.193572, lng: 18.8403609 },
    { lat: 49.1935313, lng: 18.8410158 },
    { lat: 49.1934868, lng: 18.8417039 },
    { lat: 49.1935041, lng: 18.8419992 },
    { lat: 49.1935033, lng: 18.842398 },
    { lat: 49.1934854, lng: 18.8427286 },
    { lat: 49.19344, lng: 18.8431645 },
    { lat: 49.1934343, lng: 18.8433386 },
    { lat: 49.1935205, lng: 18.8437886 },
    { lat: 49.1935708, lng: 18.8441928 },
    { lat: 49.1935772, lng: 18.8444981 },
    { lat: 49.1935605, lng: 18.8448527 },
    { lat: 49.1935293, lng: 18.8454041 },
    { lat: 49.1935351, lng: 18.8459124 },
    { lat: 49.1935316, lng: 18.8464157 },
    { lat: 49.1935351, lng: 18.847013 },
    { lat: 49.19353, lng: 18.8474606 },
    { lat: 49.1935286, lng: 18.8478289 },
    { lat: 49.1935241, lng: 18.8481596 },
    { lat: 49.1935763, lng: 18.8492179 },
    { lat: 49.1936356, lng: 18.8499128 },
    { lat: 49.1936725, lng: 18.8504801 },
    { lat: 49.1937609, lng: 18.8511033 },
    { lat: 49.1938519, lng: 18.8516231 },
    { lat: 49.1939796, lng: 18.852137 },
    { lat: 49.1940978, lng: 18.8528147 },
    { lat: 49.1941802, lng: 18.8533842 },
    { lat: 49.1942451, lng: 18.8538394 },
    { lat: 49.1943056, lng: 18.8543794 },
    { lat: 49.194409, lng: 18.8549442 },
    { lat: 49.1944431, lng: 18.855544 },
    { lat: 49.1944253, lng: 18.8558338 },
    { lat: 49.1944484, lng: 18.8563058 },
    { lat: 49.1944527, lng: 18.8566755 },
    { lat: 49.1944911, lng: 18.8578862 },
    { lat: 49.1944469, lng: 18.8585855 },
    { lat: 49.1943768, lng: 18.859574 },
    { lat: 49.1942462, lng: 18.8606542 },
    { lat: 49.1940027, lng: 18.8618692 },
    { lat: 49.1937997, lng: 18.8630117 },
    { lat: 49.1937189, lng: 18.863658 },
    { lat: 49.1936154, lng: 18.8644973 },
    { lat: 49.1935281, lng: 18.8650387 },
    { lat: 49.1934697, lng: 18.8653928 },
    { lat: 49.1934523, lng: 18.8659991 },
    { lat: 49.1936318, lng: 18.8671302 },
    { lat: 49.1935587, lng: 18.8680593 },
    { lat: 49.1933916, lng: 18.8688708 },
    { lat: 49.193291, lng: 18.8692184 },
    { lat: 49.193115, lng: 18.8697635 },
    { lat: 49.1928559, lng: 18.8704537 },
    { lat: 49.1922357, lng: 18.8714886 },
    { lat: 49.1909216, lng: 18.8720517 },
    { lat: 49.1905318, lng: 18.8720559 },
    { lat: 49.1901482, lng: 18.8720486 },
    { lat: 49.189794, lng: 18.871979 },
    { lat: 49.1893566, lng: 18.8718487 },
    { lat: 49.1890461, lng: 18.8717544 },
    { lat: 49.1884806, lng: 18.871586 },
    { lat: 49.1883918, lng: 18.8715685 },
    { lat: 49.1880191, lng: 18.8713811 },
    { lat: 49.1878958, lng: 18.8713075 },
    { lat: 49.1877682, lng: 18.8712383 },
    { lat: 49.1872595, lng: 18.8710112 },
    { lat: 49.1869642, lng: 18.8707252 },
    { lat: 49.1866119, lng: 18.8703873 },
    { lat: 49.1865416, lng: 18.8703102 },
    { lat: 49.1862599, lng: 18.8709554 },
    { lat: 49.186157, lng: 18.8713869 },
    { lat: 49.1861116, lng: 18.8716266 },
    { lat: 49.186099, lng: 18.8717776 },
    { lat: 49.1860914, lng: 18.8718045 },
    { lat: 49.1859829, lng: 18.8717352 },
    { lat: 49.1858532, lng: 18.8718 },
    { lat: 49.1857137, lng: 18.8717604 },
    { lat: 49.1853205, lng: 18.8715892 },
    { lat: 49.1850338, lng: 18.8714525 },
    { lat: 49.1847705, lng: 18.8711555 },
    { lat: 49.1846777, lng: 18.8710998 },
    { lat: 49.1845052, lng: 18.8710811 },
    { lat: 49.1844627, lng: 18.8710631 },
    { lat: 49.1842993, lng: 18.8709776 },
    { lat: 49.1844113, lng: 18.8711625 },
    { lat: 49.1844362, lng: 18.8712284 },
    { lat: 49.1844394, lng: 18.8712731 },
    { lat: 49.1844116, lng: 18.8713889 },
    { lat: 49.1843244, lng: 18.8715369 },
    { lat: 49.1839537, lng: 18.8719389 },
    { lat: 49.1837309, lng: 18.8717907 },
    { lat: 49.1834468, lng: 18.8717928 },
    { lat: 49.1834381, lng: 18.8717988 },
    { lat: 49.1833726, lng: 18.8717749 },
    { lat: 49.1834991, lng: 18.8721516 },
    { lat: 49.1835106, lng: 18.8731277 },
    { lat: 49.1833256, lng: 18.8733224 },
    { lat: 49.1833472, lng: 18.8733703 },
    { lat: 49.1829799, lng: 18.8738389 },
    { lat: 49.1822736, lng: 18.8745339 },
    { lat: 49.182186, lng: 18.8746578 },
    { lat: 49.1821537, lng: 18.8747034 },
    { lat: 49.1820204, lng: 18.8749651 },
    { lat: 49.1814506, lng: 18.8752203 },
    { lat: 49.1813249, lng: 18.8753643 },
    { lat: 49.1811111, lng: 18.8754604 },
    { lat: 49.181046, lng: 18.8757315 },
    { lat: 49.1809458, lng: 18.8758419 },
    { lat: 49.1807672, lng: 18.8755041 },
    { lat: 49.1806801, lng: 18.8751685 },
    { lat: 49.1805855, lng: 18.8750144 },
    { lat: 49.1802738, lng: 18.8750507 },
    { lat: 49.1802362, lng: 18.8753738 },
    { lat: 49.1802434, lng: 18.8755135 },
    { lat: 49.1804994, lng: 18.8761079 },
    { lat: 49.1805202, lng: 18.876228 },
    { lat: 49.1803484, lng: 18.876726 },
    { lat: 49.1798883, lng: 18.8764844 },
    { lat: 49.1792049, lng: 18.8760835 },
    { lat: 49.1791572, lng: 18.8760755 },
    { lat: 49.1791509, lng: 18.8761505 },
    { lat: 49.1790409, lng: 18.8769993 },
    { lat: 49.1789612, lng: 18.877802 },
    { lat: 49.1789836, lng: 18.8780408 },
    { lat: 49.1789513, lng: 18.8781581 },
    { lat: 49.1786831, lng: 18.8785133 },
    { lat: 49.1783715, lng: 18.8788844 },
    { lat: 49.1781786, lng: 18.8791441 },
    { lat: 49.1780015, lng: 18.8795158 },
    { lat: 49.1778818, lng: 18.8797596 },
    { lat: 49.1778723, lng: 18.8798602 },
    { lat: 49.1780616, lng: 18.8797909 },
    { lat: 49.1782352, lng: 18.87979 },
    { lat: 49.1786542, lng: 18.8797377 },
    { lat: 49.1792947, lng: 18.8796731 },
    { lat: 49.1797739, lng: 18.8795635 },
    { lat: 49.1799248, lng: 18.879626 },
    { lat: 49.1804393, lng: 18.8796762 },
    { lat: 49.1805724, lng: 18.8798117 },
    { lat: 49.1803335, lng: 18.8803198 },
    { lat: 49.1801941, lng: 18.8803043 },
    { lat: 49.1801667, lng: 18.8804301 },
    { lat: 49.1802487, lng: 18.8804729 },
    { lat: 49.1801103, lng: 18.8806959 },
    { lat: 49.1798637, lng: 18.8806131 },
    { lat: 49.179863, lng: 18.880446 },
    { lat: 49.1795373, lng: 18.8804941 },
    { lat: 49.179504, lng: 18.8807687 },
    { lat: 49.1794881, lng: 18.8808953 },
    { lat: 49.1787576, lng: 18.8806563 },
    { lat: 49.1775278, lng: 18.8808633 },
    { lat: 49.1773214, lng: 18.8811045 },
    { lat: 49.1773284, lng: 18.8813717 },
    { lat: 49.1774558, lng: 18.8815941 },
    { lat: 49.177515, lng: 18.8819582 },
    { lat: 49.1774195, lng: 18.8822489 },
    { lat: 49.1773496, lng: 18.8825822 },
    { lat: 49.1773332, lng: 18.8827016 },
    { lat: 49.1770795, lng: 18.8829466 },
    { lat: 49.1769664, lng: 18.8830501 },
    { lat: 49.176908, lng: 18.8832765 },
    { lat: 49.1768711, lng: 18.8835663 },
    { lat: 49.1768876, lng: 18.8839132 },
    { lat: 49.1768738, lng: 18.8841051 },
    { lat: 49.1767764, lng: 18.8845804 },
    { lat: 49.1767625, lng: 18.8849511 },
    { lat: 49.1767749, lng: 18.8852372 },
    { lat: 49.1770135, lng: 18.8853184 },
    { lat: 49.1771903, lng: 18.8855151 },
    { lat: 49.1774907, lng: 18.8859666 },
    { lat: 49.1776038, lng: 18.8863262 },
    { lat: 49.1777146, lng: 18.8865066 },
    { lat: 49.1778754, lng: 18.8868976 },
    { lat: 49.178095, lng: 18.8871137 },
    { lat: 49.1783143, lng: 18.887657 },
    { lat: 49.1783619, lng: 18.8876593 },
    { lat: 49.1785987, lng: 18.8881458 },
    { lat: 49.1786012, lng: 18.8885711 },
    { lat: 49.1786952, lng: 18.8886014 },
    { lat: 49.1788294, lng: 18.8888669 },
    { lat: 49.1788733, lng: 18.8901713 },
    { lat: 49.178844, lng: 18.8901896 },
    { lat: 49.1789193, lng: 18.8905 },
    { lat: 49.1788991, lng: 18.89052 },
    { lat: 49.1783664, lng: 18.8910863 },
    { lat: 49.1782287, lng: 18.8911711 },
    { lat: 49.1783106, lng: 18.891627 },
    { lat: 49.1784309, lng: 18.8923061 },
    { lat: 49.1785772, lng: 18.8929641 },
    { lat: 49.1785423, lng: 18.8933643 },
    { lat: 49.1784505, lng: 18.8940206 },
    { lat: 49.1786281, lng: 18.8952475 },
    { lat: 49.1787944, lng: 18.8958545 },
    { lat: 49.178811, lng: 18.8960929 },
    { lat: 49.1786627, lng: 18.8969011 },
    { lat: 49.178888, lng: 18.8983563 },
    { lat: 49.1786026, lng: 18.9003696 },
    { lat: 49.1784674, lng: 18.9007669 },
    { lat: 49.1782829, lng: 18.9011527 },
    { lat: 49.177999, lng: 18.901622 },
    { lat: 49.1775566, lng: 18.9028312 },
    { lat: 49.1770297, lng: 18.9042655 },
    { lat: 49.176753, lng: 18.9060297 },
    { lat: 49.1765346, lng: 18.9064769 },
    { lat: 49.1761733, lng: 18.9075535 },
    { lat: 49.1756736, lng: 18.9080371 },
    { lat: 49.1754876, lng: 18.9084583 },
    { lat: 49.1751452, lng: 18.9100602 },
    { lat: 49.1749932, lng: 18.9107743 },
    { lat: 49.1747815, lng: 18.911159 },
    { lat: 49.1738722, lng: 18.9124113 },
    { lat: 49.1732479, lng: 18.9133953 },
    { lat: 49.1730751, lng: 18.9139882 },
    { lat: 49.1728853, lng: 18.9148473 },
    { lat: 49.1726419, lng: 18.9156688 },
    { lat: 49.1724913, lng: 18.9167225 },
    { lat: 49.1722664, lng: 18.918087 },
    { lat: 49.1722586, lng: 18.9188687 },
    { lat: 49.1726473, lng: 18.9208615 },
    { lat: 49.1727355, lng: 18.9215573 },
    { lat: 49.1727554, lng: 18.9217034 },
    { lat: 49.17328, lng: 18.9226 },
    { lat: 49.1735435, lng: 18.9237709 },
    { lat: 49.1737567, lng: 18.924817 },
    { lat: 49.1739887, lng: 18.9257474 },
    { lat: 49.1741369, lng: 18.9268675 },
    { lat: 49.1743717, lng: 18.9278552 },
    { lat: 49.1744421, lng: 18.9280657 },
    { lat: 49.1744925, lng: 18.9282111 },
    { lat: 49.1748824, lng: 18.9291232 },
    { lat: 49.1750439, lng: 18.9294609 },
    { lat: 49.1753424, lng: 18.9303911 },
    { lat: 49.175768, lng: 18.9314944 },
    { lat: 49.1760176, lng: 18.9319028 },
    { lat: 49.1767255, lng: 18.9331033 },
    { lat: 49.1766425, lng: 18.9336669 },
    { lat: 49.1766914, lng: 18.9342312 },
    { lat: 49.1767539, lng: 18.9349114 },
    { lat: 49.1771758, lng: 18.9356707 },
    { lat: 49.1780029, lng: 18.9372025 },
  ],
  Kľače: [
    { lat: 49.0983122, lng: 18.6450595 },
    { lat: 49.0982252, lng: 18.6454812 },
    { lat: 49.0980508, lng: 18.6460854 },
    { lat: 49.0980365, lng: 18.6462599 },
    { lat: 49.0979248, lng: 18.6467466 },
    { lat: 49.0977824, lng: 18.6466325 },
    { lat: 49.0975166, lng: 18.6465361 },
    { lat: 49.0973378, lng: 18.6465236 },
    { lat: 49.0969302, lng: 18.6464104 },
    { lat: 49.0966126, lng: 18.6463521 },
    { lat: 49.0960113, lng: 18.6482163 },
    { lat: 49.0954284, lng: 18.6502093 },
    { lat: 49.094307, lng: 18.6526683 },
    { lat: 49.0944623, lng: 18.6531487 },
    { lat: 49.0945906, lng: 18.6535053 },
    { lat: 49.0947889, lng: 18.6538379 },
    { lat: 49.0949134, lng: 18.6542072 },
    { lat: 49.094914, lng: 18.6543252 },
    { lat: 49.0948535, lng: 18.6545695 },
    { lat: 49.0946716, lng: 18.655114 },
    { lat: 49.094505, lng: 18.655652 },
    { lat: 49.0943107, lng: 18.6564522 },
    { lat: 49.0943031, lng: 18.6565399 },
    { lat: 49.0940497, lng: 18.6576521 },
    { lat: 49.0939288, lng: 18.6582237 },
    { lat: 49.0939236, lng: 18.6583136 },
    { lat: 49.0939301, lng: 18.6585643 },
    { lat: 49.0939159, lng: 18.6587417 },
    { lat: 49.0938257, lng: 18.6590414 },
    { lat: 49.0939331, lng: 18.6591505 },
    { lat: 49.0939439, lng: 18.6591848 },
    { lat: 49.0948801, lng: 18.6601053 },
    { lat: 49.0954884, lng: 18.6590908 },
    { lat: 49.0957016, lng: 18.6594741 },
    { lat: 49.0962834, lng: 18.6594518 },
    { lat: 49.0978252, lng: 18.6617053 },
    { lat: 49.0979161, lng: 18.6618697 },
    { lat: 49.0980689, lng: 18.6620899 },
    { lat: 49.0981258, lng: 18.6621653 },
    { lat: 49.0982213, lng: 18.6623072 },
    { lat: 49.0983687, lng: 18.6621516 },
    { lat: 49.0984239, lng: 18.6620462 },
    { lat: 49.0984779, lng: 18.6619377 },
    { lat: 49.0985184, lng: 18.6618283 },
    { lat: 49.0985663, lng: 18.6617321 },
    { lat: 49.0987633, lng: 18.6615977 },
    { lat: 49.0988153, lng: 18.6614733 },
    { lat: 49.0987636, lng: 18.6610287 },
    { lat: 49.0987529, lng: 18.660871 },
    { lat: 49.0986814, lng: 18.6606681 },
    { lat: 49.0987037, lng: 18.6603055 },
    { lat: 49.0987116, lng: 18.6600267 },
    { lat: 49.0987001, lng: 18.6595552 },
    { lat: 49.0987468, lng: 18.6593933 },
    { lat: 49.0987857, lng: 18.6592755 },
    { lat: 49.0988529, lng: 18.6591438 },
    { lat: 49.0989617, lng: 18.659026 },
    { lat: 49.0991474, lng: 18.6585086 },
    { lat: 49.1004155, lng: 18.6592832 },
    { lat: 49.1005504, lng: 18.65938 },
    { lat: 49.1026256, lng: 18.660639 },
    { lat: 49.1033746, lng: 18.6610852 },
    { lat: 49.1037076, lng: 18.6611979 },
    { lat: 49.1040459, lng: 18.6613244 },
    { lat: 49.1049382, lng: 18.6616713 },
    { lat: 49.1061161, lng: 18.6589833 },
    { lat: 49.1065108, lng: 18.658195 },
    { lat: 49.10696, lng: 18.6582034 },
    { lat: 49.1076779, lng: 18.6568533 },
    { lat: 49.1079269, lng: 18.6572128 },
    { lat: 49.1082381, lng: 18.6575514 },
    { lat: 49.1086036, lng: 18.6572338 },
    { lat: 49.1086589, lng: 18.6571939 },
    { lat: 49.1088923, lng: 18.6570392 },
    { lat: 49.1091185, lng: 18.6569699 },
    { lat: 49.1094504, lng: 18.6567393 },
    { lat: 49.1096468, lng: 18.6565531 },
    { lat: 49.1098949, lng: 18.6562364 },
    { lat: 49.1099619, lng: 18.6563052 },
    { lat: 49.1103692, lng: 18.6559306 },
    { lat: 49.1104258, lng: 18.6559762 },
    { lat: 49.110511, lng: 18.6560507 },
    { lat: 49.1109639, lng: 18.6557008 },
    { lat: 49.1083603, lng: 18.6536303 },
    { lat: 49.1094099, lng: 18.6501395 },
    { lat: 49.1102287, lng: 18.6508632 },
    { lat: 49.1108139, lng: 18.6514354 },
    { lat: 49.1116251, lng: 18.6521396 },
    { lat: 49.1115668, lng: 18.6520392 },
    { lat: 49.111503, lng: 18.6516266 },
    { lat: 49.1115365, lng: 18.6515126 },
    { lat: 49.111991, lng: 18.6517673 },
    { lat: 49.1122119, lng: 18.6513221 },
    { lat: 49.1123762, lng: 18.6509087 },
    { lat: 49.112411, lng: 18.6509299 },
    { lat: 49.1134541, lng: 18.6515649 },
    { lat: 49.1136253, lng: 18.6517936 },
    { lat: 49.1137947, lng: 18.6512893 },
    { lat: 49.1136953, lng: 18.6512202 },
    { lat: 49.1129501, lng: 18.6505686 },
    { lat: 49.112823, lng: 18.6503838 },
    { lat: 49.1127229, lng: 18.6501542 },
    { lat: 49.1125936, lng: 18.6496737 },
    { lat: 49.1125438, lng: 18.6495236 },
    { lat: 49.1126008, lng: 18.649324 },
    { lat: 49.1127084, lng: 18.649139 },
    { lat: 49.1138511, lng: 18.6449875 },
    { lat: 49.1129811, lng: 18.6431732 },
    { lat: 49.1128345, lng: 18.6430927 },
    { lat: 49.1127343, lng: 18.6430295 },
    { lat: 49.1125655, lng: 18.6429311 },
    { lat: 49.1123025, lng: 18.6427815 },
    { lat: 49.1120069, lng: 18.6426092 },
    { lat: 49.1119929, lng: 18.642599 },
    { lat: 49.1119103, lng: 18.6425516 },
    { lat: 49.1118871, lng: 18.6425368 },
    { lat: 49.1116314, lng: 18.642396 },
    { lat: 49.1112726, lng: 18.6422034 },
    { lat: 49.1112321, lng: 18.6421828 },
    { lat: 49.1111846, lng: 18.6421577 },
    { lat: 49.1108023, lng: 18.6419447 },
    { lat: 49.1104094, lng: 18.6417257 },
    { lat: 49.110047, lng: 18.6416124 },
    { lat: 49.1097304, lng: 18.6417019 },
    { lat: 49.1093367, lng: 18.6418092 },
    { lat: 49.1089954, lng: 18.6419011 },
    { lat: 49.1084643, lng: 18.6420505 },
    { lat: 49.1082935, lng: 18.6420828 },
    { lat: 49.1080944, lng: 18.6422193 },
    { lat: 49.1080313, lng: 18.6422624 },
    { lat: 49.1077776, lng: 18.6424327 },
    { lat: 49.1076806, lng: 18.6424979 },
    { lat: 49.1074968, lng: 18.6426243 },
    { lat: 49.1070344, lng: 18.6429402 },
    { lat: 49.1066391, lng: 18.6432089 },
    { lat: 49.1063072, lng: 18.643432 },
    { lat: 49.1062398, lng: 18.643481 },
    { lat: 49.1059501, lng: 18.6436773 },
    { lat: 49.1059253, lng: 18.6436944 },
    { lat: 49.1055705, lng: 18.6439373 },
    { lat: 49.1053697, lng: 18.6439786 },
    { lat: 49.1051706, lng: 18.6440086 },
    { lat: 49.1047487, lng: 18.6440909 },
    { lat: 49.104725, lng: 18.6440938 },
    { lat: 49.104525, lng: 18.6441282 },
    { lat: 49.1045217, lng: 18.6441287 },
    { lat: 49.1040995, lng: 18.6441836 },
    { lat: 49.1038024, lng: 18.6442405 },
    { lat: 49.1034895, lng: 18.6442939 },
    { lat: 49.1034081, lng: 18.6443089 },
    { lat: 49.1032425, lng: 18.6442934 },
    { lat: 49.103151, lng: 18.6442881 },
    { lat: 49.1029677, lng: 18.6442762 },
    { lat: 49.1025443, lng: 18.6442475 },
    { lat: 49.1023587, lng: 18.6442373 },
    { lat: 49.1022706, lng: 18.6442323 },
    { lat: 49.1020591, lng: 18.6442192 },
    { lat: 49.101944, lng: 18.644219 },
    { lat: 49.1017817, lng: 18.6442207 },
    { lat: 49.1013164, lng: 18.6442291 },
    { lat: 49.100907, lng: 18.6442329 },
    { lat: 49.1004224, lng: 18.6442383 },
    { lat: 49.1002568, lng: 18.6442408 },
    { lat: 49.1001137, lng: 18.6442419 },
    { lat: 49.0995593, lng: 18.6444859 },
    { lat: 49.0995318, lng: 18.6444983 },
    { lat: 49.0994965, lng: 18.6445138 },
    { lat: 49.0993787, lng: 18.6445651 },
    { lat: 49.0993752, lng: 18.6445662 },
    { lat: 49.0993041, lng: 18.6446063 },
    { lat: 49.0992314, lng: 18.6446391 },
    { lat: 49.0991686, lng: 18.6446677 },
    { lat: 49.0990144, lng: 18.6447371 },
    { lat: 49.0989946, lng: 18.6447464 },
    { lat: 49.0989067, lng: 18.644786 },
    { lat: 49.0988305, lng: 18.6448203 },
    { lat: 49.098776, lng: 18.6448529 },
    { lat: 49.0987694, lng: 18.644857 },
    { lat: 49.0984642, lng: 18.6449932 },
    { lat: 49.0984223, lng: 18.6450103 },
    { lat: 49.0983352, lng: 18.645048 },
    { lat: 49.0983122, lng: 18.6450595 },
  ],
  Lutiše: [
    { lat: 49.307529, lng: 19.004386 },
    { lat: 49.308548, lng: 19.00294 },
    { lat: 49.308586, lng: 19.002886 },
    { lat: 49.308291, lng: 19.00038 },
    { lat: 49.308428, lng: 18.99936 },
    { lat: 49.30847, lng: 18.999118 },
    { lat: 49.308298, lng: 18.998174 },
    { lat: 49.308091, lng: 18.99752 },
    { lat: 49.308043, lng: 18.997003 },
    { lat: 49.307923, lng: 18.996265 },
    { lat: 49.30795, lng: 18.995962 },
    { lat: 49.30804, lng: 18.994935 },
    { lat: 49.308163, lng: 18.994358 },
    { lat: 49.308324, lng: 18.993932 },
    { lat: 49.308337, lng: 18.993648 },
    { lat: 49.308358, lng: 18.993176 },
    { lat: 49.308363, lng: 18.992606 },
    { lat: 49.307983, lng: 18.990849 },
    { lat: 49.307523, lng: 18.990674 },
    { lat: 49.307512, lng: 18.99067 },
    { lat: 49.307463, lng: 18.990267 },
    { lat: 49.307481, lng: 18.989713 },
    { lat: 49.307483, lng: 18.98963 },
    { lat: 49.307277, lng: 18.988913 },
    { lat: 49.307253, lng: 18.988829 },
    { lat: 49.307317, lng: 18.988608 },
    { lat: 49.308171, lng: 18.985699 },
    { lat: 49.308224, lng: 18.984742 },
    { lat: 49.308465, lng: 18.984417 },
    { lat: 49.308753, lng: 18.984369 },
    { lat: 49.308758, lng: 18.983234 },
    { lat: 49.309111, lng: 18.982881 },
    { lat: 49.309156, lng: 18.982859 },
    { lat: 49.310127, lng: 18.982985 },
    { lat: 49.31065, lng: 18.98246 },
    { lat: 49.311168, lng: 18.982513 },
    { lat: 49.312554, lng: 18.982688 },
    { lat: 49.31319, lng: 18.982518 },
    { lat: 49.313905, lng: 18.982338 },
    { lat: 49.314442, lng: 18.982282 },
    { lat: 49.3145, lng: 18.982276 },
    { lat: 49.314677, lng: 18.982259 },
    { lat: 49.314695, lng: 18.982241 },
    { lat: 49.315127, lng: 18.98181 },
    { lat: 49.314863, lng: 18.98137 },
    { lat: 49.3147, lng: 18.980903 },
    { lat: 49.314704, lng: 18.98042 },
    { lat: 49.314948, lng: 18.97899 },
    { lat: 49.315004, lng: 18.978739 },
    { lat: 49.315305, lng: 18.978191 },
    { lat: 49.315658, lng: 18.977542 },
    { lat: 49.315596, lng: 18.977479 },
    { lat: 49.315361, lng: 18.976495 },
    { lat: 49.31532, lng: 18.976324 },
    { lat: 49.315214, lng: 18.975874 },
    { lat: 49.315169, lng: 18.975682 },
    { lat: 49.315239, lng: 18.975059 },
    { lat: 49.31541, lng: 18.97354 },
    { lat: 49.315424, lng: 18.973402 },
    { lat: 49.315539, lng: 18.973094 },
    { lat: 49.315666, lng: 18.972751 },
    { lat: 49.315672, lng: 18.97273 },
    { lat: 49.316416, lng: 18.972224 },
    { lat: 49.316449, lng: 18.972202 },
    { lat: 49.315959, lng: 18.970878 },
    { lat: 49.315893, lng: 18.96511 },
    { lat: 49.315736, lng: 18.963084 },
    { lat: 49.315607, lng: 18.962777 },
    { lat: 49.315571, lng: 18.962692 },
    { lat: 49.315127, lng: 18.961636 },
    { lat: 49.314923, lng: 18.960358 },
    { lat: 49.314324, lng: 18.958607 },
    { lat: 49.314155, lng: 18.958151 },
    { lat: 49.313749, lng: 18.957059 },
    { lat: 49.313749, lng: 18.95697 },
    { lat: 49.313743, lng: 18.955785 },
    { lat: 49.31332, lng: 18.954494 },
    { lat: 49.31289, lng: 18.95389 },
    { lat: 49.312795, lng: 18.95376 },
    { lat: 49.312789, lng: 18.95375 },
    { lat: 49.312086, lng: 18.952626 },
    { lat: 49.311706, lng: 18.952168 },
    { lat: 49.311369, lng: 18.951715 },
    { lat: 49.31116, lng: 18.951181 },
    { lat: 49.310843, lng: 18.949602 },
    { lat: 49.310656, lng: 18.949061 },
    { lat: 49.310516, lng: 18.948718 },
    { lat: 49.310186, lng: 18.947911 },
    { lat: 49.310155, lng: 18.947782 },
    { lat: 49.310098, lng: 18.947418 },
    { lat: 49.309919, lng: 18.946849 },
    { lat: 49.309849, lng: 18.946628 },
    { lat: 49.309846, lng: 18.946552 },
    { lat: 49.309842, lng: 18.946406 },
    { lat: 49.309836, lng: 18.946196 },
    { lat: 49.309832, lng: 18.946058 },
    { lat: 49.309828, lng: 18.945912 },
    { lat: 49.309824, lng: 18.945794 },
    { lat: 49.309821, lng: 18.945696 },
    { lat: 49.30982, lng: 18.945649 },
    { lat: 49.309829, lng: 18.945592 },
    { lat: 49.309844, lng: 18.945501 },
    { lat: 49.309873, lng: 18.945319 },
    { lat: 49.309903, lng: 18.945142 },
    { lat: 49.309913, lng: 18.945081 },
    { lat: 49.309929, lng: 18.944985 },
    { lat: 49.30994, lng: 18.944914 },
    { lat: 49.309974, lng: 18.944703 },
    { lat: 49.309822, lng: 18.944102 },
    { lat: 49.309797, lng: 18.944005 },
    { lat: 49.309808, lng: 18.943925 },
    { lat: 49.309823, lng: 18.943796 },
    { lat: 49.309827, lng: 18.94377 },
    { lat: 49.309844, lng: 18.943636 },
    { lat: 49.309843, lng: 18.943619 },
    { lat: 49.309817, lng: 18.943374 },
    { lat: 49.309791, lng: 18.943158 },
    { lat: 49.30971, lng: 18.94247 },
    { lat: 49.309691, lng: 18.942312 },
    { lat: 49.309672, lng: 18.942145 },
    { lat: 49.309678, lng: 18.942103 },
    { lat: 49.309681, lng: 18.942089 },
    { lat: 49.309675, lng: 18.942076 },
    { lat: 49.309635, lng: 18.941995 },
    { lat: 49.309609, lng: 18.941944 },
    { lat: 49.309586, lng: 18.941896 },
    { lat: 49.309543, lng: 18.941809 },
    { lat: 49.309518, lng: 18.941759 },
    { lat: 49.309475, lng: 18.941675 },
    { lat: 49.30941, lng: 18.941536 },
    { lat: 49.309376, lng: 18.941463 },
    { lat: 49.309305, lng: 18.941313 },
    { lat: 49.309248, lng: 18.941193 },
    { lat: 49.309219, lng: 18.941035 },
    { lat: 49.309162, lng: 18.940724 },
    { lat: 49.309138, lng: 18.940593 },
    { lat: 49.309121, lng: 18.940496 },
    { lat: 49.30911, lng: 18.940442 },
    { lat: 49.309087, lng: 18.940316 },
    { lat: 49.309059, lng: 18.940163 },
    { lat: 49.309021, lng: 18.939953 },
    { lat: 49.308873, lng: 18.939921 },
    { lat: 49.308733, lng: 18.939747 },
    { lat: 49.30859, lng: 18.939568 },
    { lat: 49.308393, lng: 18.939322 },
    { lat: 49.307893, lng: 18.938866 },
    { lat: 49.30781, lng: 18.93879 },
    { lat: 49.307808, lng: 18.938788 },
    { lat: 49.307146, lng: 18.938911 },
    { lat: 49.305707, lng: 18.938526 },
    { lat: 49.30457, lng: 18.938221 },
    { lat: 49.304561, lng: 18.938218 },
    { lat: 49.304358, lng: 18.937967 },
    { lat: 49.304321, lng: 18.93792 },
    { lat: 49.30371, lng: 18.937161 },
    { lat: 49.303693, lng: 18.937162 },
    { lat: 49.302624, lng: 18.937225 },
    { lat: 49.302034, lng: 18.936544 },
    { lat: 49.302029, lng: 18.936539 },
    { lat: 49.302027, lng: 18.936538 },
    { lat: 49.300966, lng: 18.936291 },
    { lat: 49.300694, lng: 18.935999 },
    { lat: 49.300688, lng: 18.935985 },
    { lat: 49.300332, lng: 18.935227 },
    { lat: 49.300076, lng: 18.934752 },
    { lat: 49.299887, lng: 18.934402 },
    { lat: 49.29936, lng: 18.933343 },
    { lat: 49.298988, lng: 18.932597 },
    { lat: 49.298727, lng: 18.932124 },
    { lat: 49.298141, lng: 18.931058 },
    { lat: 49.298154, lng: 18.931019 },
    { lat: 49.299418, lng: 18.927467 },
    { lat: 49.299466, lng: 18.925888 },
    { lat: 49.299789, lng: 18.923746 },
    { lat: 49.299825, lng: 18.923507 },
    { lat: 49.300449, lng: 18.92105 },
    { lat: 49.300491, lng: 18.920884 },
    { lat: 49.300493, lng: 18.920875 },
    { lat: 49.300664, lng: 18.920569 },
    { lat: 49.300975, lng: 18.920017 },
    { lat: 49.302231, lng: 18.917779 },
    { lat: 49.302162, lng: 18.916678 },
    { lat: 49.302858, lng: 18.916081 },
    { lat: 49.302686, lng: 18.914371 },
    { lat: 49.302666, lng: 18.913451 },
    { lat: 49.302732, lng: 18.913418 },
    { lat: 49.302771, lng: 18.912406 },
    { lat: 49.305355, lng: 18.911784 },
    { lat: 49.305332, lng: 18.912134 },
    { lat: 49.30533, lng: 18.91216 },
    { lat: 49.305341, lng: 18.912249 },
    { lat: 49.305624, lng: 18.912222 },
    { lat: 49.305741, lng: 18.912026 },
    { lat: 49.305757, lng: 18.912013 },
    { lat: 49.306523, lng: 18.911403 },
    { lat: 49.306757, lng: 18.911463 },
    { lat: 49.306783, lng: 18.911471 },
    { lat: 49.306922, lng: 18.911521 },
    { lat: 49.307573, lng: 18.911706 },
    { lat: 49.307656, lng: 18.911725 },
    { lat: 49.307925, lng: 18.911744 },
    { lat: 49.308122, lng: 18.911764 },
    { lat: 49.308239, lng: 18.911776 },
    { lat: 49.308315, lng: 18.911784 },
    { lat: 49.308538, lng: 18.911778 },
    { lat: 49.308768, lng: 18.911753 },
    { lat: 49.308991, lng: 18.911722 },
    { lat: 49.309284, lng: 18.91168 },
    { lat: 49.309285, lng: 18.91168 },
    { lat: 49.309345, lng: 18.91167 },
    { lat: 49.309375, lng: 18.911666 },
    { lat: 49.309537, lng: 18.911646 },
    { lat: 49.309692, lng: 18.911617 },
    { lat: 49.309851, lng: 18.911576 },
    { lat: 49.310011, lng: 18.911506 },
    { lat: 49.310346, lng: 18.911345 },
    { lat: 49.310404, lng: 18.911317 },
    { lat: 49.311729, lng: 18.910906 },
    { lat: 49.312463, lng: 18.910347 },
    { lat: 49.3129, lng: 18.90984 },
    { lat: 49.313098, lng: 18.90943 },
    { lat: 49.313372, lng: 18.909277 },
    { lat: 49.313766, lng: 18.908834 },
    { lat: 49.314023, lng: 18.908389 },
    { lat: 49.31422, lng: 18.907545 },
    { lat: 49.314336, lng: 18.906248 },
    { lat: 49.315023, lng: 18.905505 },
    { lat: 49.315461, lng: 18.90415 },
    { lat: 49.314446, lng: 18.90279 },
    { lat: 49.313799, lng: 18.902146 },
    { lat: 49.312473, lng: 18.901999 },
    { lat: 49.311376, lng: 18.902719 },
    { lat: 49.310613, lng: 18.902984 },
    { lat: 49.309972, lng: 18.902858 },
    { lat: 49.309475, lng: 18.902778 },
    { lat: 49.309119, lng: 18.902034 },
    { lat: 49.308763, lng: 18.90108 },
    { lat: 49.308342, lng: 18.900657 },
    { lat: 49.307978, lng: 18.900466 },
    { lat: 49.307549, lng: 18.900284 },
    { lat: 49.307008, lng: 18.900106 },
    { lat: 49.306311, lng: 18.899782 },
    { lat: 49.305731, lng: 18.899352 },
    { lat: 49.305701, lng: 18.899329 },
    { lat: 49.305664, lng: 18.899279 },
    { lat: 49.305305, lng: 18.898786 },
    { lat: 49.305095, lng: 18.898497 },
    { lat: 49.305023, lng: 18.898396 },
    { lat: 49.304242, lng: 18.898137 },
    { lat: 49.303528, lng: 18.898098 },
    { lat: 49.302911, lng: 18.898336 },
    { lat: 49.302894, lng: 18.898327 },
    { lat: 49.30229, lng: 18.89801 },
    { lat: 49.301818, lng: 18.897904 },
    { lat: 49.301816, lng: 18.897909 },
    { lat: 49.301313, lng: 18.898921 },
    { lat: 49.300973, lng: 18.8991 },
    { lat: 49.300503, lng: 18.899758 },
    { lat: 49.300351, lng: 18.90056 },
    { lat: 49.300346, lng: 18.900672 },
    { lat: 49.300275, lng: 18.902194 },
    { lat: 49.300284, lng: 18.90221 },
    { lat: 49.300576, lng: 18.902716 },
    { lat: 49.300698, lng: 18.903017 },
    { lat: 49.300697, lng: 18.903051 },
    { lat: 49.300677, lng: 18.903653 },
    { lat: 49.300672, lng: 18.903791 },
    { lat: 49.300668, lng: 18.90393 },
    { lat: 49.300644, lng: 18.904594 },
    { lat: 49.300652, lng: 18.905304 },
    { lat: 49.300652, lng: 18.905331 },
    { lat: 49.300633, lng: 18.905977 },
    { lat: 49.300528, lng: 18.906122 },
    { lat: 49.300086, lng: 18.906065 },
    { lat: 49.300053, lng: 18.906061 },
    { lat: 49.299682, lng: 18.906584 },
    { lat: 49.299278, lng: 18.907207 },
    { lat: 49.299098, lng: 18.9077 },
    { lat: 49.298905, lng: 18.907781 },
    { lat: 49.298895, lng: 18.907825 },
    { lat: 49.298752, lng: 18.908478 },
    { lat: 49.298627, lng: 18.909082 },
    { lat: 49.298565, lng: 18.909589 },
    { lat: 49.298269, lng: 18.910386 },
    { lat: 49.298171, lng: 18.91065 },
    { lat: 49.297605, lng: 18.912403 },
    { lat: 49.297693, lng: 18.912914 },
    { lat: 49.297703, lng: 18.913785 },
    { lat: 49.297772, lng: 18.914218 },
    { lat: 49.298023, lng: 18.915782 },
    { lat: 49.298075, lng: 18.91627 },
    { lat: 49.298051, lng: 18.916581 },
    { lat: 49.297999, lng: 18.916893 },
    { lat: 49.297945, lng: 18.917213 },
    { lat: 49.297795, lng: 18.917411 },
    { lat: 49.297747, lng: 18.917475 },
    { lat: 49.297314, lng: 18.917673 },
    { lat: 49.297068, lng: 18.917766 },
    { lat: 49.297068, lng: 18.917768 },
    { lat: 49.296874, lng: 18.918249 },
    { lat: 49.296452, lng: 18.918955 },
    { lat: 49.296449, lng: 18.918957 },
    { lat: 49.296306, lng: 18.919075 },
    { lat: 49.296241, lng: 18.919129 },
    { lat: 49.296206, lng: 18.919137 },
    { lat: 49.295867, lng: 18.919221 },
    { lat: 49.295682, lng: 18.919267 },
    { lat: 49.295641, lng: 18.919258 },
    { lat: 49.295527, lng: 18.919232 },
    { lat: 49.295469, lng: 18.919247 },
    { lat: 49.295434, lng: 18.919254 },
    { lat: 49.295047, lng: 18.919348 },
    { lat: 49.294703, lng: 18.919627 },
    { lat: 49.294103, lng: 18.920114 },
    { lat: 49.293959, lng: 18.920145 },
    { lat: 49.293561, lng: 18.92023 },
    { lat: 49.293254, lng: 18.920386 },
    { lat: 49.293211, lng: 18.920408 },
    { lat: 49.293192, lng: 18.920407 },
    { lat: 49.293057, lng: 18.920405 },
    { lat: 49.292809, lng: 18.920401 },
    { lat: 49.292756, lng: 18.920401 },
    { lat: 49.292546, lng: 18.920457 },
    { lat: 49.292111, lng: 18.92059 },
    { lat: 49.29167, lng: 18.920763 },
    { lat: 49.291385, lng: 18.921979 },
    { lat: 49.29135, lng: 18.922605 },
    { lat: 49.291349, lng: 18.92264 },
    { lat: 49.291342, lng: 18.9227 },
    { lat: 49.291288, lng: 18.923119 },
    { lat: 49.291092, lng: 18.924691 },
    { lat: 49.291077, lng: 18.925581 },
    { lat: 49.291075, lng: 18.925586 },
    { lat: 49.290805, lng: 18.926085 },
    { lat: 49.290508, lng: 18.926635 },
    { lat: 49.290467, lng: 18.926674 },
    { lat: 49.289747, lng: 18.927368 },
    { lat: 49.289582, lng: 18.927675 },
    { lat: 49.289337, lng: 18.928618 },
    { lat: 49.288968, lng: 18.929072 },
    { lat: 49.28871, lng: 18.929515 },
    { lat: 49.288499, lng: 18.929608 },
    { lat: 49.288356, lng: 18.929672 },
    { lat: 49.287587, lng: 18.930119 },
    { lat: 49.287452, lng: 18.930414 },
    { lat: 49.287264, lng: 18.930463 },
    { lat: 49.28704, lng: 18.93052 },
    { lat: 49.286722, lng: 18.930303 },
    { lat: 49.286416, lng: 18.930139 },
    { lat: 49.286103, lng: 18.929913 },
    { lat: 49.285196, lng: 18.929626 },
    { lat: 49.285043, lng: 18.929786 },
    { lat: 49.284767, lng: 18.930077 },
    { lat: 49.284637, lng: 18.929965 },
    { lat: 49.284401, lng: 18.929763 },
    { lat: 49.283816, lng: 18.929466 },
    { lat: 49.283208, lng: 18.929144 },
    { lat: 49.282593, lng: 18.928881 },
    { lat: 49.282099, lng: 18.928682 },
    { lat: 49.281693, lng: 18.928973 },
    { lat: 49.280472, lng: 18.928773 },
    { lat: 49.280059, lng: 18.928542 },
    { lat: 49.279495, lng: 18.928307 },
    { lat: 49.27926, lng: 18.92808 },
    { lat: 49.279153, lng: 18.928189 },
    { lat: 49.279025, lng: 18.928318 },
    { lat: 49.279006, lng: 18.928337 },
    { lat: 49.278917, lng: 18.928427 },
    { lat: 49.278808, lng: 18.928542 },
    { lat: 49.278249, lng: 18.929915 },
    { lat: 49.277958, lng: 18.930645 },
    { lat: 49.277902, lng: 18.930785 },
    { lat: 49.277789, lng: 18.930885 },
    { lat: 49.277759, lng: 18.930911 },
    { lat: 49.2775619, lng: 18.9316626 },
    { lat: 49.2778765, lng: 18.9332521 },
    { lat: 49.277963, lng: 18.9336694 },
    { lat: 49.2782638, lng: 18.9353169 },
    { lat: 49.2783783, lng: 18.9368683 },
    { lat: 49.2783943, lng: 18.9373868 },
    { lat: 49.278496, lng: 18.938328 },
    { lat: 49.2785066, lng: 18.9388756 },
    { lat: 49.2785371, lng: 18.9395884 },
    { lat: 49.2785533, lng: 18.9402454 },
    { lat: 49.2785387, lng: 18.9406256 },
    { lat: 49.278526, lng: 18.9407538 },
    { lat: 49.2784735, lng: 18.9412576 },
    { lat: 49.2783679, lng: 18.9418662 },
    { lat: 49.2782607, lng: 18.9422893 },
    { lat: 49.2781313, lng: 18.9426509 },
    { lat: 49.2779553, lng: 18.9430305 },
    { lat: 49.2777467, lng: 18.9434277 },
    { lat: 49.2775644, lng: 18.9438619 },
    { lat: 49.2773517, lng: 18.9443599 },
    { lat: 49.2771458, lng: 18.9450847 },
    { lat: 49.2769698, lng: 18.9460452 },
    { lat: 49.2769142, lng: 18.9465067 },
    { lat: 49.2766962, lng: 18.947838 },
    { lat: 49.2765933, lng: 18.9483946 },
    { lat: 49.2765775, lng: 18.9484593 },
    { lat: 49.2764196, lng: 18.9489501 },
    { lat: 49.2762544, lng: 18.949362 },
    { lat: 49.275831, lng: 18.9507388 },
    { lat: 49.2757598, lng: 18.9522567 },
    { lat: 49.275742, lng: 18.9525314 },
    { lat: 49.2756323, lng: 18.9532614 },
    { lat: 49.2754881, lng: 18.9540015 },
    { lat: 49.2754509, lng: 18.9541355 },
    { lat: 49.2753295, lng: 18.9545018 },
    { lat: 49.2752256, lng: 18.9550805 },
    { lat: 49.2752026, lng: 18.9558815 },
    { lat: 49.275112, lng: 18.9566763 },
    { lat: 49.2750991, lng: 18.9569122 },
    { lat: 49.2751257, lng: 18.9571509 },
    { lat: 49.2751485, lng: 18.9572692 },
    { lat: 49.2752005, lng: 18.9573591 },
    { lat: 49.2752723, lng: 18.95742 },
    { lat: 49.2754833, lng: 18.9575819 },
    { lat: 49.2756704, lng: 18.9578953 },
    { lat: 49.2758961, lng: 18.9584797 },
    { lat: 49.2761304, lng: 18.9592393 },
    { lat: 49.2762535, lng: 18.9599397 },
    { lat: 49.2763421, lng: 18.9609825 },
    { lat: 49.2764403, lng: 18.9621278 },
    { lat: 49.276617, lng: 18.9625923 },
    { lat: 49.2768908, lng: 18.9632411 },
    { lat: 49.2774456, lng: 18.9643212 },
    { lat: 49.2780291, lng: 18.9658162 },
    { lat: 49.2784539, lng: 18.967021 },
    { lat: 49.2788998, lng: 18.9687878 },
    { lat: 49.2790608, lng: 18.9697254 },
    { lat: 49.2792848, lng: 18.9706397 },
    { lat: 49.2795316, lng: 18.9722768 },
    { lat: 49.2796042, lng: 18.9727355 },
    { lat: 49.2797402, lng: 18.9733785 },
    { lat: 49.2798344, lng: 18.9739983 },
    { lat: 49.2799401, lng: 18.9745087 },
    { lat: 49.2800265, lng: 18.9748384 },
    { lat: 49.2802454, lng: 18.9753359 },
    { lat: 49.2804165, lng: 18.9759831 },
    { lat: 49.280515, lng: 18.9765984 },
    { lat: 49.2805372, lng: 18.9767262 },
    { lat: 49.2807789, lng: 18.9781498 },
    { lat: 49.2805776, lng: 18.9785886 },
    { lat: 49.2804874, lng: 18.9790837 },
    { lat: 49.2803508, lng: 18.9794637 },
    { lat: 49.2803153, lng: 18.980103 },
    { lat: 49.2803116, lng: 18.9802753 },
    { lat: 49.2802478, lng: 18.9804676 },
    { lat: 49.2796272, lng: 18.9819192 },
    { lat: 49.2793696, lng: 18.9823559 },
    { lat: 49.2789864, lng: 18.9828315 },
    { lat: 49.2788129, lng: 18.9831493 },
    { lat: 49.2784122, lng: 18.9838783 },
    { lat: 49.2780833, lng: 18.984562 },
    { lat: 49.2781483, lng: 18.9849346 },
    { lat: 49.2783706, lng: 18.9854126 },
    { lat: 49.2785313, lng: 18.9859654 },
    { lat: 49.2785801, lng: 18.9863066 },
    { lat: 49.2786218, lng: 18.9870744 },
    { lat: 49.2786531, lng: 18.9872708 },
    { lat: 49.2788079, lng: 18.9878893 },
    { lat: 49.2789115, lng: 18.9884505 },
    { lat: 49.2789384, lng: 18.989233 },
    { lat: 49.2790041, lng: 18.9898948 },
    { lat: 49.2790218, lng: 18.9909712 },
    { lat: 49.2789615, lng: 18.9914321 },
    { lat: 49.2789976, lng: 18.9917713 },
    { lat: 49.2790235, lng: 18.9917697 },
    { lat: 49.2804381, lng: 18.9924851 },
    { lat: 49.2806868, lng: 18.9928142 },
    { lat: 49.2810221, lng: 18.9924659 },
    { lat: 49.2814665, lng: 18.9930575 },
    { lat: 49.2817911, lng: 18.9933229 },
    { lat: 49.2818372, lng: 18.9932348 },
    { lat: 49.2819092, lng: 18.9937066 },
    { lat: 49.2820745, lng: 18.9935588 },
    { lat: 49.2825094, lng: 18.9939369 },
    { lat: 49.2832794, lng: 18.9940774 },
    { lat: 49.2833147, lng: 18.9940837 },
    { lat: 49.283372, lng: 18.9940991 },
    { lat: 49.2837825, lng: 18.9942645 },
    { lat: 49.2840777, lng: 18.9943705 },
    { lat: 49.2844843, lng: 18.9945238 },
    { lat: 49.2849311, lng: 18.9946857 },
    { lat: 49.2849583, lng: 18.9946912 },
    { lat: 49.2851566, lng: 18.9947309 },
    { lat: 49.2852725, lng: 18.9947542 },
    { lat: 49.285819, lng: 18.9948604 },
    { lat: 49.2860391, lng: 18.9949099 },
    { lat: 49.28628, lng: 18.9949706 },
    { lat: 49.2864526, lng: 18.9949735 },
    { lat: 49.2876059, lng: 18.9950894 },
    { lat: 49.2883317, lng: 18.9950632 },
    { lat: 49.2883762, lng: 18.9950515 },
    { lat: 49.2884863, lng: 18.9950227 },
    { lat: 49.2891652, lng: 18.9946863 },
    { lat: 49.2893421, lng: 18.9945959 },
    { lat: 49.2896958, lng: 18.9945459 },
    { lat: 49.2901558, lng: 18.994481 },
    { lat: 49.2902093, lng: 18.9944643 },
    { lat: 49.2907199, lng: 18.9943064 },
    { lat: 49.2909086, lng: 18.9943617 },
    { lat: 49.2917811, lng: 18.9951493 },
    { lat: 49.2920513, lng: 18.9954604 },
    { lat: 49.292134, lng: 18.9955552 },
    { lat: 49.2935396, lng: 18.9949467 },
    { lat: 49.2959859, lng: 18.9954014 },
    { lat: 49.2982442, lng: 18.9974822 },
    { lat: 49.2992114, lng: 18.9987714 },
    { lat: 49.2994427, lng: 18.9989326 },
    { lat: 49.2998534, lng: 18.9989822 },
    { lat: 49.3000797, lng: 18.9991151 },
    { lat: 49.3002902, lng: 18.9993329 },
    { lat: 49.3003457, lng: 18.9993611 },
    { lat: 49.3008799, lng: 18.9995154 },
    { lat: 49.3011679, lng: 18.9997105 },
    { lat: 49.3013492, lng: 18.9999956 },
    { lat: 49.3019905, lng: 19.0012588 },
    { lat: 49.3022453, lng: 19.0016867 },
    { lat: 49.3025406, lng: 19.0020294 },
    { lat: 49.3029336, lng: 19.002383 },
    { lat: 49.3036087, lng: 19.0028078 },
    { lat: 49.3044527, lng: 19.0032772 },
    { lat: 49.3054058, lng: 19.0036927 },
    { lat: 49.3057562, lng: 19.0039603 },
    { lat: 49.3060522, lng: 19.0044009 },
    { lat: 49.3062316, lng: 19.0049041 },
    { lat: 49.3066992, lng: 19.0049865 },
    { lat: 49.306749, lng: 19.0035707 },
    { lat: 49.3073021, lng: 19.0035156 },
    { lat: 49.307529, lng: 19.004386 },
  ],
};

export default UnitsŽilina;
