import CountrySlovakia from "../constants/regions";
import { Polygon } from "@react-google-maps/api";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

export default function RegionsPolygons({
  priceMap,
  map,
  setPMResult,
  PMResult,
  genAvgPrice,
  setRegDisUni,
  regDisUni,
  PMHistory,
  setPMHistory,
}) {
  const [showTooltip, setShowTooltip] = useState({ active: false, name: "", x: 0, y: 0 });

  const defaultColor = "#7551b0";
  const green3 = "#007500";
  const green1 = "#00A300";
  const green2 = "#2EFF2E";
  const yellow1 = "#D1D100";
  const yellow2 = "#FFFF2E";
  const orange1 = "#FFA500";
  // const orange2 = "#FFB52E";
  const red1 = "#FF0000";
  const red2 = "#D10000";

  const setColor = (key) => {
    if (Object.keys(genAvgPrice).length > 0) {
      if (genAvgPrice.hasOwnProperty(key)) {
        if (genAvgPrice[key]?.avgBytM2 >= 100 && genAvgPrice[key]?.avgBytM2 <= 800) {
          return green1;
        } else if (genAvgPrice[key]?.avgBytM2 > 800 && genAvgPrice[key]?.avgBytM2 <= 1600) {
          return green2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1600 && genAvgPrice[key]?.avgBytM2 <= 2300) {
          return yellow1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2300 && genAvgPrice[key]?.avgBytM2 <= 3100) {
          return yellow2;
        } else if (genAvgPrice[key]?.avgBytM2 > 3100 && genAvgPrice[key]?.avgBytM2 <= 3900) {
          return orange1;
        } else if (genAvgPrice[key]?.avgBytM2 > 3900 && genAvgPrice[key]?.avgBytM2 <= 4700) {
          return red1;
        } else if (genAvgPrice[key]?.avgBytM2 > 4700) {
          return red2;
        }
        return green3;
      } else return defaultColor;
    } else return defaultColor;
  };

  const convertLatLngToPoint = (latLng, map) => {
    const scale = Math.pow(2, map.getZoom());
    const nw = new window.google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng(),
    );
    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(latLng);
    const pixelOffset = new window.google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
    );
    return pixelOffset;
  };

  return priceMap.region ? (
    <>
      {Object.keys(CountrySlovakia).map((key) => {
        return (
          <Polygon
            key={key}
            paths={CountrySlovakia[key]}
            options={{
              strokeColor: setColor(key),
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: setColor(key),
              fillOpacity: 0.35,
            }}
            onClick={() => {
              // setPriceMap({ ...priceMap, district: true, districtName: key, region: false });
              setPMResult({
                ...PMResult,
                active: true,
                region: key.replace("Region", ""),
                // data: {
                //   avgBytM2: genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                // },
                data: genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                parent: null,
              });
              setRegDisUni({ ...regDisUni, region: true, district: false, unit: false });
              setPMHistory({ ...PMHistory, region: key.replace("Region", "") });
              // unitAvgPrice("", key);
              // setCenter(key);
              // setZoom(9);
            }}
            onMouseOver={(e) => {
              const latLng = e.latLng;
              const point = convertLatLngToPoint(latLng, map);
              setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
            }}
            onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
          />
        );
      })}
      {/* icon trhova cena pre */}
      {/*  kraj  */}
      {/* za dom: cena €*/}
      {/* za m² bytu: cena €*/}
      {/* za m² pozemku: cena €*/}
      {showTooltip.active ? (
        <div
          className="absolute mb-2 h-auto w-auto p-2 bg-sky-200 text-start text-sm rounded-lg opacity-100 transition-opacity duration-300 shadow-2xl"
          style={{ top: showTooltip.y + 10, left: showTooltip.x + 10 }}
        >
          <div className="flex flex-col">
            <div className="flex flex-row gap-1 justify-between items-center">
              Trhová cena pre
              <FontAwesomeIcon icon={faTags} />
            </div>
            <div>
              {genAvgPrice[showTooltip.name]?.obec
                ?.replace("Region", "kraj ")
                .replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ")}
            </div>
            <div>za dom: {genAvgPrice[showTooltip.name]?.avgDom} €</div>
            <div>za m² bytu: {genAvgPrice[showTooltip.name]?.avgBytM2} €</div>
            <div>za m² pozemku: {genAvgPrice[showTooltip.name]?.avgPozemokM2} €</div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
}
