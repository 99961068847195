import { useState } from "react";
import { Polygon } from "@react-google-maps/api";
import UnitsBardejov from "../constants/Units/RegionPresov/UnitsBardejov";
import UnitsHumenné from "../constants/Units/RegionPresov/UnitsHumenne";
import UnitsKežmarok from "../constants/Units/RegionPresov/UnitsKezmarok";
import UnitsLevoča from "../constants/Units/RegionPresov/UnitsLevoča";
import UnitsMedzilaborce from "../constants/Units/RegionPresov/UnitsMedzilaborce";
import UnitsPoprad from "../constants/Units/RegionPresov/UnitsPoprad";
import UnitsPrešov from "../constants/Units/RegionPresov/UnitsPresov";
import UnitsSabinov from "../constants/Units/RegionPresov/UnitsSabinov";
import UnitsSnina from "../constants/Units/RegionPresov/UnitsSnina";
import UnitsStaráĽubovňa from "../constants/Units/RegionPresov/UnitsStaraLubovna";
import UnitsStropkov from "../constants/Units/RegionPresov/UnitsStropkov";
import UnitsSvidník from "../constants/Units/RegionPresov/UnitsSvidnik";
import UnitsVranovNadTopľou from "../constants/Units/RegionPresov/UnitsVranovNadToplou";
import { averagePriceService } from "../../services/averagePriceService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

export default function UnitsofPresovRegion({
  priceMap,
  genAvgPrice,
  setPMResult,
  PMResult,
  map,
  PMHistory,
  setPMHistory,
}) {
  const [showTooltip, setShowTooltip] = useState({ active: false, name: "", x: 0, y: 0 });

  const defaultColor = "#7551b0";
  const green3 = "#007500";
  const green1 = "#00A300";
  const green2 = "#2EFF2E";
  const yellow1 = "#D1D100";
  const yellow2 = "#FFFF2E";
  const orange1 = "#FFA500";
  // const orange2 = "#FFB52E";
  const red1 = "#FF0000";
  const red2 = "#D10000";

  const setColor = (key) => {
    // console.log("length = ", Object.keys(genAvgPrice).length);
    if (Object.keys(genAvgPrice).length > 0) {
      // for (const k of Object.keys(genAvgPrice)) {
      if (genAvgPrice.hasOwnProperty(key)) {
        if (genAvgPrice[key]?.avgBytM2 >= 700 && genAvgPrice[key]?.avgBytM2 <= 1000) {
          return green1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1000 && genAvgPrice[key]?.avgBytM2 <= 1300) {
          return green2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1300 && genAvgPrice[key]?.avgBytM2 <= 1600) {
          return yellow1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1600 && genAvgPrice[key]?.avgBytM2 <= 1800) {
          return yellow2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1800 && genAvgPrice[key]?.avgBytM2 <= 2000) {
          return orange1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2000 && genAvgPrice[key]?.avgBytM2 <= 2100) {
          return red1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2100) {
          return red2;
        }
        return green3;
      } else return defaultColor;
      // }
    } else return defaultColor;
  };

  const unitAvgPrice = async (parent, key) => {
    // console.log("parent = ", parent, "|  key = ", key);
    const request = await averagePriceService.getUnitAvgPrice(decodeURIComponent(parent), decodeURIComponent(key));
    const response = await request.json();
    setPMResult({ ...PMResult, active: true, data: response });
    // console.log("response = ", response);
  };

  const convertLatLngToPoint = (latLng, map) => {
    const scale = Math.pow(2, map.getZoom());
    const nw = new window.google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng(),
    );
    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(latLng);
    const pixelOffset = new window.google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
    );
    return pixelOffset;
  };

  return priceMap.city ? (
    <>
      {Object.keys(UnitsBardejov).map((key) => {
        if (priceMap.cityName === "DistrictBardejov") {
          return (
            <Polygon
              key={key}
              paths={UnitsBardejov[key]}
              options={{
                // strokeColor: "#2d3740",
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Bardejov", key);
                setPMHistory({ ...PMHistory, city: "Bardejov " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsHumenné).map((key) => {
        if (priceMap.cityName === "DistrictHumenné") {
          return (
            <Polygon
              key={key}
              paths={UnitsHumenné[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Humenné", key);
                setPMHistory({ ...PMHistory, city: "Humenné " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsKežmarok).map((key) => {
        if (priceMap.cityName === "DistrictKežmarok") {
          return (
            <Polygon
              key={key}
              paths={UnitsKežmarok[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Kežmarok", key);
                setPMHistory({ ...PMHistory, city: "Kežmarok " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsLevoča).map((key) => {
        if (priceMap.cityName === "DistrictLevoča") {
          return (
            <Polygon
              key={key}
              paths={UnitsLevoča[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Levoča", key);
                setPMHistory({ ...PMHistory, city: "Levoča " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsMedzilaborce).map((key) => {
        if (priceMap.cityName === "DistrictMedzilaborce") {
          return (
            <Polygon
              key={key}
              paths={UnitsMedzilaborce[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Medzilaborce", key);
                setPMHistory({ ...PMHistory, city: "Medzilaborce " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsPoprad).map((key) => {
        if (priceMap.cityName === "DistrictPoprad") {
          return (
            <Polygon
              key={key}
              paths={UnitsPoprad[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Poprad", key);
                setPMHistory({ ...PMHistory, city: "Poprad " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsPrešov).map((key) => {
        if (priceMap.cityName === "DistrictPrešov") {
          return (
            <Polygon
              key={key}
              paths={UnitsPrešov[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Prešov", key);
                setPMHistory({ ...PMHistory, city: "Prešov " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsSabinov).map((key) => {
        if (priceMap.cityName === "DistrictSabinov") {
          return (
            <Polygon
              key={key}
              paths={UnitsSabinov[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Sabinov", key);
                setPMHistory({ ...PMHistory, city: "Sabinov " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsSnina).map((key) => {
        if (priceMap.cityName === "DistrictSnina") {
          return (
            <Polygon
              key={key}
              paths={UnitsSnina[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Snina", key);
                setPMHistory({ ...PMHistory, city: "Snina " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsStaráĽubovňa).map((key) => {
        if (priceMap.cityName === "DistrictStaráĽubovňa") {
          return (
            <Polygon
              key={key}
              paths={UnitsStaráĽubovňa[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("StaráĽubovňa", key);
                setPMHistory({ ...PMHistory, city: "StaráĽubovňa " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsStropkov).map((key) => {
        if (priceMap.cityName === "DistrictStropkov") {
          return (
            <Polygon
              key={key}
              paths={UnitsStropkov[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Stropkov", key);
                setPMHistory({ ...PMHistory, city: "Stropkov " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsSvidník).map((key) => {
        if (priceMap.cityName === "DistrictSvidník") {
          return (
            <Polygon
              key={key}
              paths={UnitsSvidník[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Svidník", key);
                setPMHistory({ ...PMHistory, city: "Svidník " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsVranovNadTopľou).map((key) => {
        if (priceMap.cityName === "DistrictVranovnadTopľou") {
          return (
            <Polygon
              key={key}
              paths={UnitsVranovNadTopľou[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("VranovnadTopľou", key);
                setPMHistory({ ...PMHistory, city: "VranovnadTopľou " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {showTooltip.active ? (
        <div
          className="absolute mb-2 h-auto w-auto p-2 bg-sky-200 text-center text-sm rounded-lg opacity-100 transition-opacity duration-300"
          style={{ top: showTooltip.y + 10, left: showTooltip.x + 10 }}
        >
          <div className="flex flex-col">
            <div className="flex flex-row gap-1 justify-between items-center">
              Trhová cena pre
              <FontAwesomeIcon icon={faTags} />
            </div>
            <div>
              {genAvgPrice[showTooltip.name]?.obec
                .replace("nad", " nad")
                .replace("pod", " pod")
                .replace("pri", " pri")
                .replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ")
                .replace("Ext", "")
                .replace("1", "")}
            </div>
            <div>za dom: {genAvgPrice[showTooltip.name]?.avgDom} €</div>
            <div>za m² bytu: {genAvgPrice[showTooltip.name]?.avgBytM2} €</div>
            <div>za m² pozemku: {genAvgPrice[showTooltip.name]?.avgPozemokM2} €</div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
}
