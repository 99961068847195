const UnitsDetva = {
  VígľašskáHutaKalinka: [
    { lat: 48.4855868, lng: 19.3108627 },
    { lat: 48.4857524, lng: 19.3108405 },
    { lat: 48.4861441, lng: 19.3102909 },
    { lat: 48.4867812, lng: 19.3103414 },
    { lat: 48.4873561, lng: 19.3100124 },
    { lat: 48.4881018, lng: 19.3093248 },
    { lat: 48.4875325, lng: 19.3086885 },
    { lat: 48.4872014, lng: 19.3082221 },
    { lat: 48.4878672, lng: 19.3076085 },
    { lat: 48.4884311, lng: 19.3069808 },
    { lat: 48.4882175, lng: 19.3066885 },
    { lat: 48.4881405, lng: 19.306467 },
    { lat: 48.4877378, lng: 19.3057607 },
    { lat: 48.487828, lng: 19.3051944 },
    { lat: 48.4879841, lng: 19.3050428 },
    { lat: 48.4881407, lng: 19.3050432 },
    { lat: 48.4880797, lng: 19.3041704 },
    { lat: 48.4882957, lng: 19.3041156 },
    { lat: 48.4884061, lng: 19.303739 },
    { lat: 48.4899789, lng: 19.3037483 },
    { lat: 48.4901739, lng: 19.3038199 },
    { lat: 48.4906186, lng: 19.3043244 },
    { lat: 48.4908889, lng: 19.3043895 },
    { lat: 48.4908127, lng: 19.3024761 },
    { lat: 48.491064, lng: 19.3021467 },
    { lat: 48.4913764, lng: 19.3007957 },
    { lat: 48.4919378, lng: 19.2998324 },
    { lat: 48.4908979, lng: 19.2979436 },
    { lat: 48.4907412, lng: 19.2968925 },
    { lat: 48.490905, lng: 19.296875 },
    { lat: 48.4913576, lng: 19.2963762 },
    { lat: 48.4913251, lng: 19.2961903 },
    { lat: 48.4912223, lng: 19.2959975 },
    { lat: 48.4912111, lng: 19.2955539 },
    { lat: 48.4907147, lng: 19.2948649 },
    { lat: 48.4909152, lng: 19.2932031 },
    { lat: 48.4908981, lng: 19.2919108 },
    { lat: 48.4911665, lng: 19.2917497 },
    { lat: 48.4912362, lng: 19.2916089 },
    { lat: 48.4913341, lng: 19.2915573 },
    { lat: 48.4912429, lng: 19.2913029 },
    { lat: 48.4912581, lng: 19.2911268 },
    { lat: 48.4915868, lng: 19.2905767 },
    { lat: 48.4920605, lng: 19.2913789 },
    { lat: 48.4925709, lng: 19.2920095 },
    { lat: 48.4945396, lng: 19.2895647 },
    { lat: 48.4940135, lng: 19.2891996 },
    { lat: 48.493918, lng: 19.2890419 },
    { lat: 48.493473, lng: 19.2886488 },
    { lat: 48.4928721, lng: 19.2894375 },
    { lat: 48.4929515, lng: 19.2891936 },
    { lat: 48.4927082, lng: 19.289174 },
    { lat: 48.4925979, lng: 19.2890801 },
    { lat: 48.4923606, lng: 19.2886028 },
    { lat: 48.4921837, lng: 19.2884412 },
    { lat: 48.4922523, lng: 19.2883694 },
    { lat: 48.4921942, lng: 19.2882365 },
    { lat: 48.4921123, lng: 19.2882685 },
    { lat: 48.4921422, lng: 19.2881437 },
    { lat: 48.4919841, lng: 19.2879264 },
    { lat: 48.4920079, lng: 19.287857 },
    { lat: 48.4921298, lng: 19.2879543 },
    { lat: 48.4922141, lng: 19.2875975 },
    { lat: 48.4922646, lng: 19.2876147 },
    { lat: 48.4922994, lng: 19.2875185 },
    { lat: 48.492558, lng: 19.2873219 },
    { lat: 48.4924665, lng: 19.2870799 },
    { lat: 48.4923077, lng: 19.2869672 },
    { lat: 48.4922171, lng: 19.2869831 },
    { lat: 48.4918464, lng: 19.2868242 },
    { lat: 48.4925961, lng: 19.2853988 },
    { lat: 48.4926629, lng: 19.285476 },
    { lat: 48.4926991, lng: 19.2854088 },
    { lat: 48.4929349, lng: 19.2854115 },
    { lat: 48.4928555, lng: 19.2855673 },
    { lat: 48.4931963, lng: 19.2867181 },
    { lat: 48.493754, lng: 19.2876735 },
    { lat: 48.4942115, lng: 19.2874569 },
    { lat: 48.4943853, lng: 19.2874419 },
    { lat: 48.4945243, lng: 19.2875411 },
    { lat: 48.4947032, lng: 19.2875578 },
    { lat: 48.4948429, lng: 19.2880451 },
    { lat: 48.4949276, lng: 19.2892196 },
    { lat: 48.494914, lng: 19.2892911 },
    { lat: 48.4945611, lng: 19.2896814 },
    { lat: 48.4946363, lng: 19.2897955 },
    { lat: 48.4946549, lng: 19.2900163 },
    { lat: 48.4945735, lng: 19.2902434 },
    { lat: 48.4949119, lng: 19.2909513 },
    { lat: 48.4949702, lng: 19.2912502 },
    { lat: 48.4949606, lng: 19.2915423 },
    { lat: 48.4950498, lng: 19.2915454 },
    { lat: 48.4950979, lng: 19.2908816 },
    { lat: 48.495241, lng: 19.290248 },
    { lat: 48.4956253, lng: 19.2905518 },
    { lat: 48.4955375, lng: 19.2921216 },
    { lat: 48.4955886, lng: 19.2938406 },
    { lat: 48.4960569, lng: 19.2935927 },
    { lat: 48.4961755, lng: 19.2936423 },
    { lat: 48.4963871, lng: 19.2941082 },
    { lat: 48.4965498, lng: 19.2949104 },
    { lat: 48.4965315, lng: 19.2952575 },
    { lat: 48.4966764, lng: 19.2959215 },
    { lat: 48.4970483, lng: 19.2956884 },
    { lat: 48.497164, lng: 19.2955403 },
    { lat: 48.4972125, lng: 19.295352 },
    { lat: 48.4971472, lng: 19.2950503 },
    { lat: 48.497339, lng: 19.2947024 },
    { lat: 48.4972849, lng: 19.294467 },
    { lat: 48.4973018, lng: 19.294097 },
    { lat: 48.4972457, lng: 19.2939617 },
    { lat: 48.4977725, lng: 19.2930321 },
    { lat: 48.4978389, lng: 19.2930511 },
    { lat: 48.4980598, lng: 19.2928415 },
    { lat: 48.4975504, lng: 19.2919073 },
    { lat: 48.4973729, lng: 19.2914298 },
    { lat: 48.4981713, lng: 19.2912244 },
    { lat: 48.4989441, lng: 19.2914247 },
    { lat: 48.4991158, lng: 19.2911295 },
    { lat: 48.4986798, lng: 19.2901304 },
    { lat: 48.4986045, lng: 19.2894797 },
    { lat: 48.4986328, lng: 19.2890132 },
    { lat: 48.4988755, lng: 19.2889966 },
    { lat: 48.4992651, lng: 19.2886609 },
    { lat: 48.499566, lng: 19.2882634 },
    { lat: 48.4998392, lng: 19.2881092 },
    { lat: 48.5004225, lng: 19.2880476 },
    { lat: 48.5005375, lng: 19.2881911 },
    { lat: 48.5005478, lng: 19.2880993 },
    { lat: 48.5007875, lng: 19.28807 },
    { lat: 48.5009348, lng: 19.287922 },
    { lat: 48.5008376, lng: 19.2876831 },
    { lat: 48.5008358, lng: 19.2864507 },
    { lat: 48.5016868, lng: 19.2861708 },
    { lat: 48.5018708, lng: 19.2857559 },
    { lat: 48.5020794, lng: 19.2855013 },
    { lat: 48.5020249, lng: 19.2852372 },
    { lat: 48.5023909, lng: 19.2847355 },
    { lat: 48.5027097, lng: 19.2844309 },
    { lat: 48.5030847, lng: 19.2838621 },
    { lat: 48.502691, lng: 19.2827553 },
    { lat: 48.5025951, lng: 19.2820076 },
    { lat: 48.5023916, lng: 19.2811775 },
    { lat: 48.5022848, lng: 19.2803877 },
    { lat: 48.502359, lng: 19.279366 },
    { lat: 48.502565, lng: 19.278375 },
    { lat: 48.502574, lng: 19.278328 },
    { lat: 48.502769, lng: 19.277388 },
    { lat: 48.503011, lng: 19.275591 },
    { lat: 48.503013, lng: 19.27517 },
    { lat: 48.504919, lng: 19.273387 },
    { lat: 48.504957, lng: 19.273352 },
    { lat: 48.506009, lng: 19.272368 },
    { lat: 48.506038, lng: 19.27234 },
    { lat: 48.506079, lng: 19.272303 },
    { lat: 48.506628, lng: 19.271789 },
    { lat: 48.506179, lng: 19.269205 },
    { lat: 48.506171, lng: 19.269168 },
    { lat: 48.505742, lng: 19.266702 },
    { lat: 48.505913, lng: 19.266412 },
    { lat: 48.505978, lng: 19.266181 },
    { lat: 48.506117, lng: 19.266077 },
    { lat: 48.50628, lng: 19.265815 },
    { lat: 48.506361, lng: 19.265243 },
    { lat: 48.506407, lng: 19.26443 },
    { lat: 48.506786, lng: 19.263785 },
    { lat: 48.506812, lng: 19.263375 },
    { lat: 48.506887, lng: 19.263061 },
    { lat: 48.507031, lng: 19.262774 },
    { lat: 48.507182, lng: 19.262554 },
    { lat: 48.507212, lng: 19.26215 },
    { lat: 48.507448, lng: 19.261609 },
    { lat: 48.507536, lng: 19.26133 },
    { lat: 48.507587, lng: 19.260508 },
    { lat: 48.507529, lng: 19.260346 },
    { lat: 48.507361, lng: 19.260257 },
    { lat: 48.50714, lng: 19.260332 },
    { lat: 48.506891, lng: 19.260217 },
    { lat: 48.506758, lng: 19.260214 },
    { lat: 48.506534, lng: 19.260116 },
    { lat: 48.506428, lng: 19.260115 },
    { lat: 48.506324, lng: 19.260151 },
    { lat: 48.506222, lng: 19.260326 },
    { lat: 48.50607, lng: 19.260714 },
    { lat: 48.505846, lng: 19.260799 },
    { lat: 48.505687, lng: 19.260777 },
    { lat: 48.505061, lng: 19.26046 },
    { lat: 48.505012, lng: 19.260435 },
    { lat: 48.504935, lng: 19.260396 },
    { lat: 48.504855, lng: 19.260452 },
    { lat: 48.50472, lng: 19.260706 },
    { lat: 48.504415, lng: 19.260883 },
    { lat: 48.5042, lng: 19.261045 },
    { lat: 48.504049, lng: 19.261016 },
    { lat: 48.504051, lng: 19.261361 },
    { lat: 48.50405, lng: 19.261424 },
    { lat: 48.503554, lng: 19.261037 },
    { lat: 48.503359, lng: 19.26111 },
    { lat: 48.503154, lng: 19.261111 },
    { lat: 48.502841, lng: 19.261026 },
    { lat: 48.502136, lng: 19.261479 },
    { lat: 48.501803, lng: 19.261555 },
    { lat: 48.500993, lng: 19.262024 },
    { lat: 48.500584, lng: 19.26266 },
    { lat: 48.500425, lng: 19.262507 },
    { lat: 48.500366, lng: 19.262451 },
    { lat: 48.499658, lng: 19.262767 },
    { lat: 48.499669, lng: 19.262922 },
    { lat: 48.499392, lng: 19.263037 },
    { lat: 48.499079, lng: 19.263396 },
    { lat: 48.498998, lng: 19.263703 },
    { lat: 48.49905, lng: 19.264127 },
    { lat: 48.499083, lng: 19.264202 },
    { lat: 48.499242, lng: 19.264552 },
    { lat: 48.49898, lng: 19.264862 },
    { lat: 48.498653, lng: 19.264701 },
    { lat: 48.498455, lng: 19.264652 },
    { lat: 48.4981, lng: 19.26461 },
    { lat: 48.497867, lng: 19.264099 },
    { lat: 48.497841, lng: 19.264035 },
    { lat: 48.497746, lng: 19.263828 },
    { lat: 48.497313, lng: 19.262869 },
    { lat: 48.496957, lng: 19.262897 },
    { lat: 48.496832, lng: 19.262533 },
    { lat: 48.496579, lng: 19.262319 },
    { lat: 48.496258, lng: 19.262049 },
    { lat: 48.496375, lng: 19.261347 },
    { lat: 48.496379, lng: 19.261313 },
    { lat: 48.496416, lng: 19.261039 },
    { lat: 48.496564, lng: 19.261049 },
    { lat: 48.496543, lng: 19.261299 },
    { lat: 48.496626, lng: 19.261336 },
    { lat: 48.496694, lng: 19.261289 },
    { lat: 48.496815, lng: 19.261311 },
    { lat: 48.496897, lng: 19.26145 },
    { lat: 48.496931, lng: 19.261285 },
    { lat: 48.497046, lng: 19.261175 },
    { lat: 48.497132, lng: 19.261171 },
    { lat: 48.497216, lng: 19.261239 },
    { lat: 48.497417, lng: 19.261223 },
    { lat: 48.497505, lng: 19.261288 },
    { lat: 48.497632, lng: 19.261221 },
    { lat: 48.497829, lng: 19.261285 },
    { lat: 48.497912, lng: 19.26138 },
    { lat: 48.497965, lng: 19.261371 },
    { lat: 48.497938, lng: 19.261066 },
    { lat: 48.497937, lng: 19.260542 },
    { lat: 48.498147, lng: 19.26044 },
    { lat: 48.498286, lng: 19.260485 },
    { lat: 48.498347, lng: 19.260504 },
    { lat: 48.49843, lng: 19.260531 },
    { lat: 48.498504, lng: 19.260555 },
    { lat: 48.498738, lng: 19.260757 },
    { lat: 48.499432, lng: 19.260428 },
    { lat: 48.499571, lng: 19.259489 },
    { lat: 48.499515, lng: 19.25892 },
    { lat: 48.499527, lng: 19.258706 },
    { lat: 48.499479, lng: 19.258509 },
    { lat: 48.499471, lng: 19.258474 },
    { lat: 48.499447, lng: 19.258372 },
    { lat: 48.499634, lng: 19.258076 },
    { lat: 48.499692, lng: 19.257923 },
    { lat: 48.499792, lng: 19.257659 },
    { lat: 48.500007, lng: 19.256937 },
    { lat: 48.500037, lng: 19.256688 },
    { lat: 48.50015, lng: 19.256094 },
    { lat: 48.500164, lng: 19.255573 },
    { lat: 48.500115, lng: 19.255194 },
    { lat: 48.500174, lng: 19.254884 },
    { lat: 48.500184, lng: 19.254671 },
    { lat: 48.50013, lng: 19.254316 },
    { lat: 48.500145, lng: 19.254127 },
    { lat: 48.500141, lng: 19.253843 },
    { lat: 48.500083, lng: 19.25313 },
    { lat: 48.500147, lng: 19.252784 },
    { lat: 48.500244, lng: 19.252609 },
    { lat: 48.500327, lng: 19.252295 },
    { lat: 48.500388, lng: 19.252129 },
    { lat: 48.500471, lng: 19.251901 },
    { lat: 48.500673, lng: 19.251164 },
    { lat: 48.500797, lng: 19.251009 },
    { lat: 48.501023, lng: 19.251016 },
    { lat: 48.501089, lng: 19.250934 },
    { lat: 48.501106, lng: 19.250818 },
    { lat: 48.501114, lng: 19.25076 },
    { lat: 48.501124, lng: 19.250697 },
    { lat: 48.501201, lng: 19.250541 },
    { lat: 48.501268, lng: 19.250308 },
    { lat: 48.50126, lng: 19.250203 },
    { lat: 48.501264, lng: 19.250068 },
    { lat: 48.50137, lng: 19.249717 },
    { lat: 48.501406, lng: 19.249294 },
    { lat: 48.501663, lng: 19.247857 },
    { lat: 48.501765, lng: 19.247583 },
    { lat: 48.501815, lng: 19.247315 },
    { lat: 48.50194, lng: 19.247065 },
    { lat: 48.501992, lng: 19.246836 },
    { lat: 48.502093, lng: 19.246546 },
    { lat: 48.502109, lng: 19.246413 },
    { lat: 48.502068, lng: 19.246289 },
    { lat: 48.502037, lng: 19.246134 },
    { lat: 48.50189, lng: 19.245883 },
    { lat: 48.501887, lng: 19.245725 },
    { lat: 48.501801, lng: 19.245131 },
    { lat: 48.501823, lng: 19.244807 },
    { lat: 48.501885, lng: 19.244425 },
    { lat: 48.501878, lng: 19.24441 },
    { lat: 48.501863, lng: 19.244382 },
    { lat: 48.501642, lng: 19.243945 },
    { lat: 48.501578, lng: 19.243595 },
    { lat: 48.501513, lng: 19.243343 },
    { lat: 48.501697, lng: 19.242935 },
    { lat: 48.501847, lng: 19.242778 },
    { lat: 48.50177, lng: 19.242537 },
    { lat: 48.50204, lng: 19.241556 },
    { lat: 48.501092, lng: 19.241961 },
    { lat: 48.5003, lng: 19.24244 },
    { lat: 48.499866, lng: 19.242574 },
    { lat: 48.499566, lng: 19.242717 },
    { lat: 48.4993709, lng: 19.2427429 },
    { lat: 48.498861, lng: 19.242883 },
    { lat: 48.498365, lng: 19.243217 },
    { lat: 48.497885, lng: 19.243422 },
    { lat: 48.497601, lng: 19.243477 },
    { lat: 48.497386, lng: 19.243331 },
    { lat: 48.497236, lng: 19.242826 },
    { lat: 48.497115, lng: 19.242611 },
    { lat: 48.496841, lng: 19.242298 },
    { lat: 48.496592, lng: 19.242095 },
    { lat: 48.496049, lng: 19.241863 },
    { lat: 48.495589, lng: 19.241851 },
    { lat: 48.495378, lng: 19.24188 },
    { lat: 48.495346, lng: 19.241815 },
    { lat: 48.495228, lng: 19.241576 },
    { lat: 48.494505, lng: 19.240124 },
    { lat: 48.494273, lng: 19.239651 },
    { lat: 48.493624, lng: 19.238462 },
    { lat: 48.493273, lng: 19.238186 },
    { lat: 48.492353, lng: 19.238136 },
    { lat: 48.492208, lng: 19.238123 },
    { lat: 48.491947, lng: 19.238113 },
    { lat: 48.491629, lng: 19.238023 },
    { lat: 48.491349, lng: 19.237828 },
    { lat: 48.491017, lng: 19.237554 },
    { lat: 48.490474, lng: 19.237249 },
    { lat: 48.490092, lng: 19.237543 },
    { lat: 48.489592, lng: 19.23782 },
    { lat: 48.489306, lng: 19.238017 },
    { lat: 48.489055, lng: 19.238155 },
    { lat: 48.488723, lng: 19.238333 },
    { lat: 48.488449, lng: 19.238486 },
    { lat: 48.488257, lng: 19.238471 },
    { lat: 48.488093, lng: 19.238501 },
    { lat: 48.487693, lng: 19.238848 },
    { lat: 48.487287, lng: 19.238252 },
    { lat: 48.486929, lng: 19.237747 },
    { lat: 48.48671, lng: 19.237485 },
    { lat: 48.486675, lng: 19.237463 },
    { lat: 48.486125, lng: 19.237719 },
    { lat: 48.485899, lng: 19.237625 },
    { lat: 48.485744, lng: 19.237664 },
    { lat: 48.485471, lng: 19.238084 },
    { lat: 48.485148, lng: 19.238391 },
    { lat: 48.484907, lng: 19.238579 },
    { lat: 48.484617, lng: 19.239081 },
    { lat: 48.484439, lng: 19.239534 },
    { lat: 48.484269, lng: 19.239872 },
    { lat: 48.4842, lng: 19.240251 },
    { lat: 48.484368, lng: 19.240979 },
    { lat: 48.484281, lng: 19.241487 },
    { lat: 48.484257, lng: 19.241625 },
    { lat: 48.484148, lng: 19.241639 },
    { lat: 48.483162, lng: 19.242977 },
    { lat: 48.483155, lng: 19.243239 },
    { lat: 48.482864, lng: 19.243905 },
    { lat: 48.482618, lng: 19.244522 },
    { lat: 48.482462, lng: 19.244907 },
    { lat: 48.482265, lng: 19.245376 },
    { lat: 48.482126, lng: 19.24567 },
    { lat: 48.481931, lng: 19.246118 },
    { lat: 48.481664, lng: 19.246701 },
    { lat: 48.481211, lng: 19.246778 },
    { lat: 48.4797, lng: 19.247574 },
    { lat: 48.478771, lng: 19.247416 },
    { lat: 48.478101, lng: 19.247331 },
    { lat: 48.477666, lng: 19.247389 },
    { lat: 48.477632, lng: 19.247393 },
    { lat: 48.477475, lng: 19.247414 },
    { lat: 48.47661, lng: 19.247929 },
    { lat: 48.476235, lng: 19.248459 },
    { lat: 48.475943, lng: 19.249058 },
    { lat: 48.475707, lng: 19.249456 },
    { lat: 48.474978, lng: 19.250047 },
    { lat: 48.474597, lng: 19.250364 },
    { lat: 48.474206, lng: 19.250703 },
    { lat: 48.473946, lng: 19.250926 },
    { lat: 48.473704, lng: 19.251211 },
    { lat: 48.473514, lng: 19.251429 },
    { lat: 48.473189, lng: 19.251808 },
    { lat: 48.472852, lng: 19.252221 },
    { lat: 48.472417, lng: 19.253278 },
    { lat: 48.472142, lng: 19.254164 },
    { lat: 48.471618, lng: 19.254818 },
    { lat: 48.471295, lng: 19.255146 },
    { lat: 48.47092, lng: 19.255477 },
    { lat: 48.470777, lng: 19.25629 },
    { lat: 48.470311, lng: 19.257234 },
    { lat: 48.470314, lng: 19.258371 },
    { lat: 48.471117, lng: 19.259567 },
    { lat: 48.471868, lng: 19.260879 },
    { lat: 48.471654, lng: 19.261032 },
    { lat: 48.470914, lng: 19.262099 },
    { lat: 48.470803, lng: 19.262993 },
    { lat: 48.470656, lng: 19.263674 },
    { lat: 48.470479, lng: 19.264151 },
    { lat: 48.470681, lng: 19.264837 },
    { lat: 48.470675, lng: 19.265267 },
    { lat: 48.470596, lng: 19.265733 },
    { lat: 48.47062, lng: 19.26597 },
    { lat: 48.470668, lng: 19.266353 },
    { lat: 48.47073, lng: 19.266813 },
    { lat: 48.470817, lng: 19.267496 },
    { lat: 48.47096, lng: 19.268409 },
    { lat: 48.471112, lng: 19.268957 },
    { lat: 48.47128, lng: 19.269372 },
    { lat: 48.471495, lng: 19.269789 },
    { lat: 48.471534, lng: 19.269881 },
    { lat: 48.471615, lng: 19.270095 },
    { lat: 48.47175, lng: 19.270797 },
    { lat: 48.471778, lng: 19.271362 },
    { lat: 48.471752, lng: 19.27182 },
    { lat: 48.47147, lng: 19.272646 },
    { lat: 48.471194, lng: 19.273075 },
    { lat: 48.470666, lng: 19.274201 },
    { lat: 48.470357, lng: 19.274882 },
    { lat: 48.470206, lng: 19.27522 },
    { lat: 48.470125, lng: 19.275482 },
    { lat: 48.470067, lng: 19.275578 },
    { lat: 48.469993, lng: 19.2757 },
    { lat: 48.46984, lng: 19.275952 },
    { lat: 48.469188, lng: 19.276423 },
    { lat: 48.468903, lng: 19.276608 },
    { lat: 48.468336, lng: 19.276887 },
    { lat: 48.468089, lng: 19.277414 },
    { lat: 48.467855, lng: 19.278721 },
    { lat: 48.4684163, lng: 19.2802465 },
    { lat: 48.4687397, lng: 19.2831188 },
    { lat: 48.4685856, lng: 19.2843145 },
    { lat: 48.4670434, lng: 19.2861781 },
    { lat: 48.4663679, lng: 19.2881556 },
    { lat: 48.4663861, lng: 19.2885589 },
    { lat: 48.466182, lng: 19.2897483 },
    { lat: 48.4664717, lng: 19.2908724 },
    { lat: 48.466702, lng: 19.2943366 },
    { lat: 48.4669657, lng: 19.2951816 },
    { lat: 48.4669101, lng: 19.2957346 },
    { lat: 48.4667426, lng: 19.2960949 },
    { lat: 48.4670637, lng: 19.2975648 },
    { lat: 48.4673124, lng: 19.2979317 },
    { lat: 48.4675051, lng: 19.2990785 },
    { lat: 48.467991, lng: 19.3011841 },
    { lat: 48.4687126, lng: 19.3028797 },
    { lat: 48.4694749, lng: 19.3042648 },
    { lat: 48.4698952, lng: 19.3053688 },
    { lat: 48.4702722, lng: 19.306175 },
    { lat: 48.4706748, lng: 19.3068601 },
    { lat: 48.4710587, lng: 19.307717 },
    { lat: 48.4718923, lng: 19.3074481 },
    { lat: 48.4726541, lng: 19.3075628 },
    { lat: 48.4740806, lng: 19.3092897 },
    { lat: 48.4748388, lng: 19.3100087 },
    { lat: 48.4755305, lng: 19.3109843 },
    { lat: 48.4765716, lng: 19.3128331 },
    { lat: 48.4772368, lng: 19.3147631 },
    { lat: 48.483836, lng: 19.3104369 },
    { lat: 48.4840453, lng: 19.3107336 },
    { lat: 48.4847045, lng: 19.310517 },
    { lat: 48.4855868, lng: 19.3108627 },
  ],
  Kriváň: [
    { lat: 48.5425345, lng: 19.4381131 },
    { lat: 48.5421288, lng: 19.4358129 },
    { lat: 48.5421363, lng: 19.4353742 },
    { lat: 48.5420158, lng: 19.4351363 },
    { lat: 48.5420147, lng: 19.4326348 },
    { lat: 48.5423422, lng: 19.4326035 },
    { lat: 48.5422323, lng: 19.4304528 },
    { lat: 48.5427114, lng: 19.4303353 },
    { lat: 48.5427035, lng: 19.4299766 },
    { lat: 48.5406356, lng: 19.4310616 },
    { lat: 48.5395969, lng: 19.4290543 },
    { lat: 48.5393227, lng: 19.4282794 },
    { lat: 48.5386805, lng: 19.4276091 },
    { lat: 48.538368, lng: 19.4269543 },
    { lat: 48.5375871, lng: 19.4258301 },
    { lat: 48.5371817, lng: 19.4265063 },
    { lat: 48.536675, lng: 19.4263273 },
    { lat: 48.5365136, lng: 19.4261045 },
    { lat: 48.5367069, lng: 19.4256141 },
    { lat: 48.5366607, lng: 19.4252275 },
    { lat: 48.5357775, lng: 19.4236762 },
    { lat: 48.5353034, lng: 19.4222733 },
    { lat: 48.5351303, lng: 19.419577 },
    { lat: 48.5318229, lng: 19.4186958 },
    { lat: 48.5317197, lng: 19.4185849 },
    { lat: 48.5318414, lng: 19.4183265 },
    { lat: 48.5306952, lng: 19.4180832 },
    { lat: 48.5299961, lng: 19.4180764 },
    { lat: 48.5299799, lng: 19.4181905 },
    { lat: 48.5297458, lng: 19.4184096 },
    { lat: 48.5295141, lng: 19.4183237 },
    { lat: 48.5293372, lng: 19.4197634 },
    { lat: 48.5290719, lng: 19.4230369 },
    { lat: 48.5282726, lng: 19.4230791 },
    { lat: 48.5282389, lng: 19.4229618 },
    { lat: 48.5264675, lng: 19.4231029 },
    { lat: 48.5257553, lng: 19.4231596 },
    { lat: 48.5257507, lng: 19.4231128 },
    { lat: 48.5253329, lng: 19.4231424 },
    { lat: 48.5242976, lng: 19.4229373 },
    { lat: 48.5242992, lng: 19.4228724 },
    { lat: 48.5235234, lng: 19.4226262 },
    { lat: 48.523452, lng: 19.4227387 },
    { lat: 48.5235129, lng: 19.4231215 },
    { lat: 48.5234289, lng: 19.423444 },
    { lat: 48.5228193, lng: 19.4247633 },
    { lat: 48.5224772, lng: 19.426214 },
    { lat: 48.52183, lng: 19.4262558 },
    { lat: 48.5212619, lng: 19.4264454 },
    { lat: 48.5208088, lng: 19.4265178 },
    { lat: 48.5207287, lng: 19.426612 },
    { lat: 48.5207961, lng: 19.4267758 },
    { lat: 48.5207784, lng: 19.4268563 },
    { lat: 48.5205119, lng: 19.4270855 },
    { lat: 48.519649, lng: 19.4282673 },
    { lat: 48.5195522, lng: 19.4285851 },
    { lat: 48.5192623, lng: 19.4290792 },
    { lat: 48.5189408, lng: 19.4294813 },
    { lat: 48.518182, lng: 19.4301685 },
    { lat: 48.5175217, lng: 19.4310407 },
    { lat: 48.5170179, lng: 19.4314967 },
    { lat: 48.5168726, lng: 19.4315211 },
    { lat: 48.5168568, lng: 19.4325771 },
    { lat: 48.5168951, lng: 19.432744 },
    { lat: 48.5166699, lng: 19.4329635 },
    { lat: 48.5167403, lng: 19.4330498 },
    { lat: 48.5166956, lng: 19.4331982 },
    { lat: 48.5165779, lng: 19.4331677 },
    { lat: 48.5165038, lng: 19.4334088 },
    { lat: 48.5161351, lng: 19.4333843 },
    { lat: 48.515849, lng: 19.4337485 },
    { lat: 48.5157859, lng: 19.4336276 },
    { lat: 48.515792, lng: 19.4344317 },
    { lat: 48.5156465, lng: 19.4348137 },
    { lat: 48.5158588, lng: 19.4350595 },
    { lat: 48.515551, lng: 19.435517 },
    { lat: 48.5156083, lng: 19.4357064 },
    { lat: 48.515149, lng: 19.4362451 },
    { lat: 48.5151245, lng: 19.4363927 },
    { lat: 48.5138832, lng: 19.439076 },
    { lat: 48.5143917, lng: 19.4397521 },
    { lat: 48.5145011, lng: 19.4398287 },
    { lat: 48.5147944, lng: 19.4398327 },
    { lat: 48.5147763, lng: 19.4401226 },
    { lat: 48.5143692, lng: 19.4409801 },
    { lat: 48.5141996, lng: 19.4414282 },
    { lat: 48.5140283, lng: 19.4420774 },
    { lat: 48.5147408, lng: 19.4422978 },
    { lat: 48.5148132, lng: 19.4427338 },
    { lat: 48.514787, lng: 19.4428005 },
    { lat: 48.5152078, lng: 19.4428597 },
    { lat: 48.5155608, lng: 19.44313 },
    { lat: 48.5153606, lng: 19.443666 },
    { lat: 48.5159753, lng: 19.4446384 },
    { lat: 48.5171811, lng: 19.4460354 },
    { lat: 48.5179309, lng: 19.4472697 },
    { lat: 48.5188634, lng: 19.4476603 },
    { lat: 48.5189802, lng: 19.4484968 },
    { lat: 48.5199866, lng: 19.4487353 },
    { lat: 48.519868, lng: 19.449674 },
    { lat: 48.5197384, lng: 19.4502508 },
    { lat: 48.5193072, lng: 19.4515581 },
    { lat: 48.519031, lng: 19.4522326 },
    { lat: 48.5188513, lng: 19.4530173 },
    { lat: 48.5187384, lng: 19.4563904 },
    { lat: 48.5191253, lng: 19.4573121 },
    { lat: 48.5193767, lng: 19.4583509 },
    { lat: 48.519422, lng: 19.4589628 },
    { lat: 48.5191997, lng: 19.4601392 },
    { lat: 48.5190018, lng: 19.4620065 },
    { lat: 48.5189308, lng: 19.4631874 },
    { lat: 48.5189211, lng: 19.4642855 },
    { lat: 48.5195691, lng: 19.4655913 },
    { lat: 48.520104, lng: 19.467394 },
    { lat: 48.5207967, lng: 19.4687122 },
    { lat: 48.5210727, lng: 19.4695561 },
    { lat: 48.521459, lng: 19.4702999 },
    { lat: 48.5219236, lng: 19.4720756 },
    { lat: 48.5229983, lng: 19.4730074 },
    { lat: 48.5237957, lng: 19.47392 },
    { lat: 48.5242011, lng: 19.4742004 },
    { lat: 48.5248239, lng: 19.4742897 },
    { lat: 48.525611, lng: 19.4736449 },
    { lat: 48.5262462, lng: 19.4732266 },
    { lat: 48.5269161, lng: 19.473067 },
    { lat: 48.5276561, lng: 19.473273 },
    { lat: 48.5289022, lng: 19.4728816 },
    { lat: 48.5303813, lng: 19.4735457 },
    { lat: 48.531133, lng: 19.4743428 },
    { lat: 48.5308428, lng: 19.4739425 },
    { lat: 48.5319002, lng: 19.4716786 },
    { lat: 48.5313144, lng: 19.4710777 },
    { lat: 48.5316143, lng: 19.4692423 },
    { lat: 48.5318888, lng: 19.4678749 },
    { lat: 48.5329737, lng: 19.4688593 },
    { lat: 48.5330807, lng: 19.4677447 },
    { lat: 48.5341974, lng: 19.4682482 },
    { lat: 48.5347093, lng: 19.4682304 },
    { lat: 48.5352099, lng: 19.4680424 },
    { lat: 48.535879, lng: 19.465836 },
    { lat: 48.5392449, lng: 19.4567431 },
    { lat: 48.5408124, lng: 19.457585 },
    { lat: 48.5408521, lng: 19.4574951 },
    { lat: 48.5409424, lng: 19.4575936 },
    { lat: 48.5411478, lng: 19.4571917 },
    { lat: 48.5424183, lng: 19.4586234 },
    { lat: 48.5424323, lng: 19.4585387 },
    { lat: 48.5424606, lng: 19.4585631 },
    { lat: 48.5424503, lng: 19.4586556 },
    { lat: 48.542808, lng: 19.4591245 },
    { lat: 48.5449848, lng: 19.4549401 },
    { lat: 48.5442741, lng: 19.4545837 },
    { lat: 48.5448829, lng: 19.4513349 },
    { lat: 48.5450572, lng: 19.4497596 },
    { lat: 48.5450562, lng: 19.4494044 },
    { lat: 48.5453083, lng: 19.4482598 },
    { lat: 48.5451517, lng: 19.4481896 },
    { lat: 48.5437703, lng: 19.4464867 },
    { lat: 48.5435593, lng: 19.4465298 },
    { lat: 48.5429127, lng: 19.4461954 },
    { lat: 48.5426479, lng: 19.4478151 },
    { lat: 48.5423471, lng: 19.4477449 },
    { lat: 48.5426581, lng: 19.4461744 },
    { lat: 48.5428196, lng: 19.4461598 },
    { lat: 48.5428384, lng: 19.4460824 },
    { lat: 48.5428159, lng: 19.4458383 },
    { lat: 48.543458, lng: 19.443209 },
    { lat: 48.5435402, lng: 19.442388 },
    { lat: 48.5438083, lng: 19.4412552 },
    { lat: 48.5436471, lng: 19.4405101 },
    { lat: 48.5434905, lng: 19.4406054 },
    { lat: 48.5429843, lng: 19.4392322 },
    { lat: 48.5428636, lng: 19.439303 },
    { lat: 48.5427599, lng: 19.4385558 },
    { lat: 48.5425543, lng: 19.4385936 },
    { lat: 48.5425752, lng: 19.4383439 },
    { lat: 48.5425345, lng: 19.4381131 },
  ],
  Detva: [
    { lat: 48.5695567, lng: 19.3773992 },
    { lat: 48.5682204, lng: 19.3772657 },
    { lat: 48.5680186, lng: 19.3770743 },
    { lat: 48.5680275, lng: 19.3770028 },
    { lat: 48.5679389, lng: 19.3768213 },
    { lat: 48.5676712, lng: 19.3768533 },
    { lat: 48.5675674, lng: 19.3771363 },
    { lat: 48.567487, lng: 19.376725 },
    { lat: 48.5673491, lng: 19.3765007 },
    { lat: 48.5672004, lng: 19.3764342 },
    { lat: 48.5670905, lng: 19.3764923 },
    { lat: 48.566887, lng: 19.3762882 },
    { lat: 48.5662076, lng: 19.3767182 },
    { lat: 48.5656074, lng: 19.3758045 },
    { lat: 48.5641713, lng: 19.3739678 },
    { lat: 48.5621352, lng: 19.3718147 },
    { lat: 48.5600809, lng: 19.3705656 },
    { lat: 48.559787, lng: 19.3711851 },
    { lat: 48.5591697, lng: 19.3706737 },
    { lat: 48.5585816, lng: 19.3709366 },
    { lat: 48.5579229, lng: 19.3708893 },
    { lat: 48.5575159, lng: 19.370592 },
    { lat: 48.5570265, lng: 19.3711336 },
    { lat: 48.5570108, lng: 19.3710417 },
    { lat: 48.556795, lng: 19.371099 },
    { lat: 48.556577, lng: 19.37337 },
    { lat: 48.555843, lng: 19.374161 },
    { lat: 48.55555, lng: 19.374625 },
    { lat: 48.555316, lng: 19.374995 },
    { lat: 48.555265, lng: 19.375076 },
    { lat: 48.555138, lng: 19.375276 },
    { lat: 48.555021, lng: 19.37546 },
    { lat: 48.55493, lng: 19.375605 },
    { lat: 48.554839, lng: 19.375686 },
    { lat: 48.554656, lng: 19.37585 },
    { lat: 48.554618, lng: 19.375884 },
    { lat: 48.554497, lng: 19.375992 },
    { lat: 48.554209, lng: 19.376361 },
    { lat: 48.553749, lng: 19.37695 },
    { lat: 48.553693, lng: 19.377021 },
    { lat: 48.553022, lng: 19.377881 },
    { lat: 48.552856, lng: 19.37812 },
    { lat: 48.551368, lng: 19.380266 },
    { lat: 48.548759, lng: 19.384308 },
    { lat: 48.547905, lng: 19.386063 },
    { lat: 48.5474568, lng: 19.3844125 },
    { lat: 48.5466453, lng: 19.3804606 },
    { lat: 48.5451548, lng: 19.3788551 },
    { lat: 48.5444936, lng: 19.3783887 },
    { lat: 48.5439085, lng: 19.3783114 },
    { lat: 48.5437286, lng: 19.3783436 },
    { lat: 48.5429813, lng: 19.3778203 },
    { lat: 48.5429184, lng: 19.3777534 },
    { lat: 48.5424195, lng: 19.3783096 },
    { lat: 48.5421776, lng: 19.3786771 },
    { lat: 48.542106, lng: 19.3790895 },
    { lat: 48.5424694, lng: 19.3800048 },
    { lat: 48.5422793, lng: 19.3803688 },
    { lat: 48.5420879, lng: 19.3809712 },
    { lat: 48.5420436, lng: 19.3813924 },
    { lat: 48.5420893, lng: 19.3819488 },
    { lat: 48.5423506, lng: 19.3819243 },
    { lat: 48.5425904, lng: 19.3816769 },
    { lat: 48.5426608, lng: 19.3817384 },
    { lat: 48.5427468, lng: 19.3820083 },
    { lat: 48.5429471, lng: 19.3820985 },
    { lat: 48.5429123, lng: 19.3822662 },
    { lat: 48.5426207, lng: 19.382273 },
    { lat: 48.5425683, lng: 19.3828328 },
    { lat: 48.5423998, lng: 19.3829633 },
    { lat: 48.5418298, lng: 19.3827321 },
    { lat: 48.5417712, lng: 19.3832065 },
    { lat: 48.5421532, lng: 19.3835613 },
    { lat: 48.5421587, lng: 19.3840124 },
    { lat: 48.5422487, lng: 19.3843421 },
    { lat: 48.5421073, lng: 19.3846635 },
    { lat: 48.5422314, lng: 19.3853099 },
    { lat: 48.5424139, lng: 19.3853071 },
    { lat: 48.5427502, lng: 19.3851269 },
    { lat: 48.5429446, lng: 19.3853273 },
    { lat: 48.5431598, lng: 19.3873262 },
    { lat: 48.5432343, lng: 19.388737 },
    { lat: 48.5427906, lng: 19.3886722 },
    { lat: 48.5426814, lng: 19.3887963 },
    { lat: 48.5427517, lng: 19.388829 },
    { lat: 48.5427662, lng: 19.388991 },
    { lat: 48.5424036, lng: 19.3886646 },
    { lat: 48.5413591, lng: 19.3911948 },
    { lat: 48.5399902, lng: 19.3907537 },
    { lat: 48.5397823, lng: 19.3898447 },
    { lat: 48.5396848, lng: 19.3894183 },
    { lat: 48.5394803, lng: 19.38941 },
    { lat: 48.5394647, lng: 19.3884812 },
    { lat: 48.5394193, lng: 19.3880953 },
    { lat: 48.5394405, lng: 19.3877544 },
    { lat: 48.5394705, lng: 19.3875556 },
    { lat: 48.5396258, lng: 19.387243 },
    { lat: 48.5396858, lng: 19.3870093 },
    { lat: 48.5397169, lng: 19.3861446 },
    { lat: 48.5396921, lng: 19.3856191 },
    { lat: 48.5394262, lng: 19.3844068 },
    { lat: 48.5394722, lng: 19.3838072 },
    { lat: 48.5394254, lng: 19.3835337 },
    { lat: 48.5394915, lng: 19.3826884 },
    { lat: 48.5394306, lng: 19.3824502 },
    { lat: 48.5380639, lng: 19.3828409 },
    { lat: 48.5380699, lng: 19.3830377 },
    { lat: 48.5378416, lng: 19.3831237 },
    { lat: 48.5378048, lng: 19.3829143 },
    { lat: 48.5362328, lng: 19.3832033 },
    { lat: 48.5362148, lng: 19.3815665 },
    { lat: 48.5360763, lng: 19.3804042 },
    { lat: 48.5359721, lng: 19.3799627 },
    { lat: 48.5359619, lng: 19.379319 },
    { lat: 48.5360381, lng: 19.3792639 },
    { lat: 48.5359852, lng: 19.378575 },
    { lat: 48.5360215, lng: 19.3785534 },
    { lat: 48.5357642, lng: 19.3769579 },
    { lat: 48.5358153, lng: 19.3769382 },
    { lat: 48.5358572, lng: 19.3753956 },
    { lat: 48.5356717, lng: 19.3746719 },
    { lat: 48.5351615, lng: 19.3730877 },
    { lat: 48.5348733, lng: 19.3725227 },
    { lat: 48.5333138, lng: 19.3702534 },
    { lat: 48.5319198, lng: 19.3694717 },
    { lat: 48.5317088, lng: 19.3692989 },
    { lat: 48.5310429, lng: 19.3684026 },
    { lat: 48.5305492, lng: 19.3678779 },
    { lat: 48.5294534, lng: 19.3670194 },
    { lat: 48.5286561, lng: 19.3670093 },
    { lat: 48.5281572, lng: 19.3667803 },
    { lat: 48.5279909, lng: 19.3671716 },
    { lat: 48.5280233, lng: 19.3677843 },
    { lat: 48.5272337, lng: 19.3683849 },
    { lat: 48.5270152, lng: 19.3683647 },
    { lat: 48.5268518, lng: 19.3682702 },
    { lat: 48.5266117, lng: 19.3683676 },
    { lat: 48.5264783, lng: 19.3685469 },
    { lat: 48.5263824, lng: 19.3685466 },
    { lat: 48.5262371, lng: 19.3687154 },
    { lat: 48.5259346, lng: 19.3687505 },
    { lat: 48.5256008, lng: 19.3691015 },
    { lat: 48.5254188, lng: 19.3694972 },
    { lat: 48.5251924, lng: 19.3697577 },
    { lat: 48.5244106, lng: 19.3700754 },
    { lat: 48.5242341, lng: 19.3702786 },
    { lat: 48.5237867, lng: 19.3705646 },
    { lat: 48.5236267, lng: 19.3708008 },
    { lat: 48.5231877, lng: 19.3705725 },
    { lat: 48.5228624, lng: 19.3708699 },
    { lat: 48.5225897, lng: 19.3711808 },
    { lat: 48.522394, lng: 19.3715912 },
    { lat: 48.5223423, lng: 19.3719999 },
    { lat: 48.5220061, lng: 19.3726536 },
    { lat: 48.5215772, lng: 19.3728569 },
    { lat: 48.5213, lng: 19.3733812 },
    { lat: 48.5208352, lng: 19.3736104 },
    { lat: 48.52041, lng: 19.373723 },
    { lat: 48.5199825, lng: 19.3736889 },
    { lat: 48.5190534, lng: 19.3738731 },
    { lat: 48.5187264, lng: 19.3742488 },
    { lat: 48.51814, lng: 19.3750766 },
    { lat: 48.5177235, lng: 19.3758942 },
    { lat: 48.5160464, lng: 19.3771498 },
    { lat: 48.5160982, lng: 19.3766789 },
    { lat: 48.5160377, lng: 19.3762104 },
    { lat: 48.5160893, lng: 19.375848 },
    { lat: 48.5164551, lng: 19.3747916 },
    { lat: 48.5166491, lng: 19.3744359 },
    { lat: 48.5167888, lng: 19.373936 },
    { lat: 48.5172622, lng: 19.3733451 },
    { lat: 48.5176499, lng: 19.3726557 },
    { lat: 48.5178474, lng: 19.3719465 },
    { lat: 48.5177233, lng: 19.371043 },
    { lat: 48.5173986, lng: 19.3696652 },
    { lat: 48.5170635, lng: 19.3692284 },
    { lat: 48.5163255, lng: 19.3686178 },
    { lat: 48.5146451, lng: 19.3679778 },
    { lat: 48.5143597, lng: 19.3680206 },
    { lat: 48.5143349, lng: 19.3677823 },
    { lat: 48.5141488, lng: 19.3678073 },
    { lat: 48.5139097, lng: 19.3676802 },
    { lat: 48.5137882, lng: 19.3672599 },
    { lat: 48.512625, lng: 19.3670716 },
    { lat: 48.5122868, lng: 19.3667593 },
    { lat: 48.5116797, lng: 19.3663636 },
    { lat: 48.5116735, lng: 19.3666348 },
    { lat: 48.5115313, lng: 19.3672266 },
    { lat: 48.5112645, lng: 19.3677804 },
    { lat: 48.5111161, lng: 19.3678326 },
    { lat: 48.5110152, lng: 19.3685081 },
    { lat: 48.5109284, lng: 19.3687005 },
    { lat: 48.5111203, lng: 19.3690364 },
    { lat: 48.5111475, lng: 19.3691846 },
    { lat: 48.5107163, lng: 19.3696444 },
    { lat: 48.5105489, lng: 19.3699197 },
    { lat: 48.5103327, lng: 19.3706777 },
    { lat: 48.5099505, lng: 19.3713874 },
    { lat: 48.5097617, lng: 19.3716153 },
    { lat: 48.5095258, lng: 19.3717508 },
    { lat: 48.5093939, lng: 19.3720106 },
    { lat: 48.5091837, lng: 19.3722106 },
    { lat: 48.5087229, lng: 19.3734985 },
    { lat: 48.5084313, lng: 19.3739088 },
    { lat: 48.508321, lng: 19.3741477 },
    { lat: 48.5085512, lng: 19.3743199 },
    { lat: 48.508704, lng: 19.374575 },
    { lat: 48.5091831, lng: 19.3756425 },
    { lat: 48.5091544, lng: 19.3760835 },
    { lat: 48.5090869, lng: 19.3763971 },
    { lat: 48.5088337, lng: 19.3770701 },
    { lat: 48.5085972, lng: 19.377027 },
    { lat: 48.5083303, lng: 19.3771957 },
    { lat: 48.5082737, lng: 19.3773837 },
    { lat: 48.5081956, lng: 19.378087 },
    { lat: 48.5068284, lng: 19.3787055 },
    { lat: 48.5069045, lng: 19.3790751 },
    { lat: 48.5068908, lng: 19.3793336 },
    { lat: 48.5066608, lng: 19.380566 },
    { lat: 48.5065809, lng: 19.3807825 },
    { lat: 48.5063598, lng: 19.381709 },
    { lat: 48.5043058, lng: 19.3847158 },
    { lat: 48.5038588, lng: 19.3853008 },
    { lat: 48.5035363, lng: 19.3853883 },
    { lat: 48.50279, lng: 19.3859119 },
    { lat: 48.5020233, lng: 19.3865951 },
    { lat: 48.5006617, lng: 19.3873758 },
    { lat: 48.4990575, lng: 19.3879891 },
    { lat: 48.4983308, lng: 19.3881626 },
    { lat: 48.4979061, lng: 19.3881164 },
    { lat: 48.4972306, lng: 19.3897534 },
    { lat: 48.497013, lng: 19.3909185 },
    { lat: 48.4971198, lng: 19.3912191 },
    { lat: 48.496982, lng: 19.3918809 },
    { lat: 48.4971046, lng: 19.3924015 },
    { lat: 48.4971493, lng: 19.3929159 },
    { lat: 48.4970125, lng: 19.393803 },
    { lat: 48.4970034, lng: 19.3938622 },
    { lat: 48.4964892, lng: 19.3943189 },
    { lat: 48.4963751, lng: 19.3944202 },
    { lat: 48.495893, lng: 19.394719 },
    { lat: 48.4952823, lng: 19.3950975 },
    { lat: 48.4947769, lng: 19.3953812 },
    { lat: 48.4945202, lng: 19.3955252 },
    { lat: 48.4943733, lng: 19.3956077 },
    { lat: 48.494028, lng: 19.3957175 },
    { lat: 48.4934864, lng: 19.3960164 },
    { lat: 48.4934134, lng: 19.3959958 },
    { lat: 48.4936178, lng: 19.3950557 },
    { lat: 48.4930421, lng: 19.394513 },
    { lat: 48.492618, lng: 19.394289 },
    { lat: 48.4926614, lng: 19.3938978 },
    { lat: 48.4924078, lng: 19.39356 },
    { lat: 48.4921335, lng: 19.3930539 },
    { lat: 48.4919504, lng: 19.3928518 },
    { lat: 48.4917417, lng: 19.3927292 },
    { lat: 48.4916444, lng: 19.3917917 },
    { lat: 48.4910983, lng: 19.3916107 },
    { lat: 48.4889106, lng: 19.3905862 },
    { lat: 48.4880975, lng: 19.3908122 },
    { lat: 48.487727, lng: 19.3918211 },
    { lat: 48.487503, lng: 19.392708 },
    { lat: 48.4872841, lng: 19.3932736 },
    { lat: 48.486078, lng: 19.39342 },
    { lat: 48.485887, lng: 19.393783 },
    { lat: 48.485335, lng: 19.394866 },
    { lat: 48.485321, lng: 19.394854 },
    { lat: 48.485288, lng: 19.394843 },
    { lat: 48.485197, lng: 19.395019 },
    { lat: 48.485112, lng: 19.395187 },
    { lat: 48.485018, lng: 19.395398 },
    { lat: 48.484806, lng: 19.395771 },
    { lat: 48.484708, lng: 19.395973 },
    { lat: 48.484544, lng: 19.396633 },
    { lat: 48.484378, lng: 19.397414 },
    { lat: 48.484342, lng: 19.397728 },
    { lat: 48.484312, lng: 19.397889 },
    { lat: 48.484174, lng: 19.398305 },
    { lat: 48.48395, lng: 19.399001 },
    { lat: 48.483834, lng: 19.39955 },
    { lat: 48.483821, lng: 19.399784 },
    { lat: 48.483811, lng: 19.400107 },
    { lat: 48.483811, lng: 19.400563 },
    { lat: 48.483798, lng: 19.400924 },
    { lat: 48.483687, lng: 19.401657 },
    { lat: 48.483409, lng: 19.402256 },
    { lat: 48.483336, lng: 19.402403 },
    { lat: 48.483341, lng: 19.402525 },
    { lat: 48.483377, lng: 19.402803 },
    { lat: 48.483477, lng: 19.403375 },
    { lat: 48.483493, lng: 19.403578 },
    { lat: 48.483491, lng: 19.403668 },
    { lat: 48.483372, lng: 19.404124 },
    { lat: 48.483297, lng: 19.404389 },
    { lat: 48.483207, lng: 19.40485 },
    { lat: 48.483012, lng: 19.405847 },
    { lat: 48.482896, lng: 19.406376 },
    { lat: 48.482741, lng: 19.406798 },
    { lat: 48.482604, lng: 19.407426 },
    { lat: 48.482517, lng: 19.407651 },
    { lat: 48.482076, lng: 19.408352 },
    { lat: 48.482043, lng: 19.408423 },
    { lat: 48.482282, lng: 19.408713 },
    { lat: 48.482298, lng: 19.408731 },
    { lat: 48.482581, lng: 19.409075 },
    { lat: 48.482947, lng: 19.40962 },
    { lat: 48.483415, lng: 19.41039 },
    { lat: 48.483486, lng: 19.41076 },
    { lat: 48.483639, lng: 19.411575 },
    { lat: 48.483613, lng: 19.411643 },
    { lat: 48.483299, lng: 19.412442 },
    { lat: 48.483177, lng: 19.412959 },
    { lat: 48.483492, lng: 19.413231 },
    { lat: 48.483773, lng: 19.413207 },
    { lat: 48.484622, lng: 19.414338 },
    { lat: 48.484646, lng: 19.414382 },
    { lat: 48.484902, lng: 19.414674 },
    { lat: 48.485065, lng: 19.414807 },
    { lat: 48.485682, lng: 19.41549 },
    { lat: 48.485701, lng: 19.415508 },
    { lat: 48.485857, lng: 19.415734 },
    { lat: 48.48603, lng: 19.415986 },
    { lat: 48.486068, lng: 19.41623 },
    { lat: 48.485787, lng: 19.416739 },
    { lat: 48.486109, lng: 19.417385 },
    { lat: 48.486197, lng: 19.417558 },
    { lat: 48.48645, lng: 19.418056 },
    { lat: 48.486561, lng: 19.418275 },
    { lat: 48.486729, lng: 19.4185 },
    { lat: 48.486756, lng: 19.418687 },
    { lat: 48.486898, lng: 19.419406 },
    { lat: 48.486915, lng: 19.420096 },
    { lat: 48.48698, lng: 19.420261 },
    { lat: 48.487049, lng: 19.42035 },
    { lat: 48.487084, lng: 19.420387 },
    { lat: 48.48715, lng: 19.420746 },
    { lat: 48.487224, lng: 19.421009 },
    { lat: 48.487466, lng: 19.421573 },
    { lat: 48.487488, lng: 19.421587 },
    { lat: 48.4879178, lng: 19.4223529 },
    { lat: 48.4883677, lng: 19.4229104 },
    { lat: 48.4893246, lng: 19.4246617 },
    { lat: 48.4902325, lng: 19.4253925 },
    { lat: 48.4910335, lng: 19.4258861 },
    { lat: 48.4916755, lng: 19.4261198 },
    { lat: 48.4921628, lng: 19.4261073 },
    { lat: 48.493665, lng: 19.4268941 },
    { lat: 48.4946666, lng: 19.4277746 },
    { lat: 48.4947213, lng: 19.4282893 },
    { lat: 48.4946545, lng: 19.4296971 },
    { lat: 48.4948474, lng: 19.4298471 },
    { lat: 48.4950066, lng: 19.4296621 },
    { lat: 48.4953717, lng: 19.4307365 },
    { lat: 48.4954094, lng: 19.4309667 },
    { lat: 48.4951949, lng: 19.4311508 },
    { lat: 48.4951176, lng: 19.431714 },
    { lat: 48.4952358, lng: 19.4320674 },
    { lat: 48.4954481, lng: 19.4320061 },
    { lat: 48.4960077, lng: 19.4324974 },
    { lat: 48.4960368, lng: 19.4327793 },
    { lat: 48.4959956, lng: 19.4333469 },
    { lat: 48.4962803, lng: 19.4342238 },
    { lat: 48.4962681, lng: 19.4346845 },
    { lat: 48.4982187, lng: 19.4366484 },
    { lat: 48.4990704, lng: 19.4373592 },
    { lat: 48.4996683, lng: 19.437735 },
    { lat: 48.5001836, lng: 19.4379578 },
    { lat: 48.5007305, lng: 19.43777 },
    { lat: 48.5012637, lng: 19.4374852 },
    { lat: 48.5019405, lng: 19.4376166 },
    { lat: 48.5020234, lng: 19.4378217 },
    { lat: 48.5023834, lng: 19.4380706 },
    { lat: 48.5028348, lng: 19.4401535 },
    { lat: 48.5029656, lng: 19.4403685 },
    { lat: 48.5031136, lng: 19.4404473 },
    { lat: 48.503271, lng: 19.4406577 },
    { lat: 48.5036005, lng: 19.4413893 },
    { lat: 48.5047379, lng: 19.4426681 },
    { lat: 48.5057616, lng: 19.4435187 },
    { lat: 48.5060659, lng: 19.4434966 },
    { lat: 48.5063097, lng: 19.4433546 },
    { lat: 48.5064663, lng: 19.4432363 },
    { lat: 48.5067504, lng: 19.4432416 },
    { lat: 48.506835, lng: 19.4432842 },
    { lat: 48.5074774, lng: 19.443942 },
    { lat: 48.5077762, lng: 19.4444566 },
    { lat: 48.5078478, lng: 19.4446855 },
    { lat: 48.5080684, lng: 19.4459774 },
    { lat: 48.5083552, lng: 19.4463626 },
    { lat: 48.5086393, lng: 19.4466058 },
    { lat: 48.5087318, lng: 19.4467555 },
    { lat: 48.5088533, lng: 19.4472065 },
    { lat: 48.5094266, lng: 19.447875 },
    { lat: 48.5097707, lng: 19.447906 },
    { lat: 48.5105367, lng: 19.4477772 },
    { lat: 48.5105434, lng: 19.4475369 },
    { lat: 48.5111354, lng: 19.4470804 },
    { lat: 48.5113465, lng: 19.4471603 },
    { lat: 48.5120028, lng: 19.4461382 },
    { lat: 48.5128804, lng: 19.4449272 },
    { lat: 48.5134403, lng: 19.4446546 },
    { lat: 48.5138457, lng: 19.4441687 },
    { lat: 48.5141378, lng: 19.4434513 },
    { lat: 48.5145077, lng: 19.4433121 },
    { lat: 48.514787, lng: 19.4428005 },
    { lat: 48.5148132, lng: 19.4427338 },
    { lat: 48.5147408, lng: 19.4422978 },
    { lat: 48.5140283, lng: 19.4420774 },
    { lat: 48.5141996, lng: 19.4414282 },
    { lat: 48.5143692, lng: 19.4409801 },
    { lat: 48.5147763, lng: 19.4401226 },
    { lat: 48.5147944, lng: 19.4398327 },
    { lat: 48.5145011, lng: 19.4398287 },
    { lat: 48.5143917, lng: 19.4397521 },
    { lat: 48.5138832, lng: 19.439076 },
    { lat: 48.5151245, lng: 19.4363927 },
    { lat: 48.515149, lng: 19.4362451 },
    { lat: 48.5156083, lng: 19.4357064 },
    { lat: 48.515551, lng: 19.435517 },
    { lat: 48.5158588, lng: 19.4350595 },
    { lat: 48.5156465, lng: 19.4348137 },
    { lat: 48.515792, lng: 19.4344317 },
    { lat: 48.5157859, lng: 19.4336276 },
    { lat: 48.515849, lng: 19.4337485 },
    { lat: 48.5161351, lng: 19.4333843 },
    { lat: 48.5165038, lng: 19.4334088 },
    { lat: 48.5165779, lng: 19.4331677 },
    { lat: 48.5166956, lng: 19.4331982 },
    { lat: 48.5167403, lng: 19.4330498 },
    { lat: 48.5166699, lng: 19.4329635 },
    { lat: 48.5168951, lng: 19.432744 },
    { lat: 48.5168568, lng: 19.4325771 },
    { lat: 48.5168726, lng: 19.4315211 },
    { lat: 48.5170179, lng: 19.4314967 },
    { lat: 48.5175217, lng: 19.4310407 },
    { lat: 48.518182, lng: 19.4301685 },
    { lat: 48.5189408, lng: 19.4294813 },
    { lat: 48.5192623, lng: 19.4290792 },
    { lat: 48.5195522, lng: 19.4285851 },
    { lat: 48.519649, lng: 19.4282673 },
    { lat: 48.5205119, lng: 19.4270855 },
    { lat: 48.5207784, lng: 19.4268563 },
    { lat: 48.5207961, lng: 19.4267758 },
    { lat: 48.5207287, lng: 19.426612 },
    { lat: 48.5208088, lng: 19.4265178 },
    { lat: 48.5212619, lng: 19.4264454 },
    { lat: 48.52183, lng: 19.4262558 },
    { lat: 48.5224772, lng: 19.426214 },
    { lat: 48.5228193, lng: 19.4247633 },
    { lat: 48.5234289, lng: 19.423444 },
    { lat: 48.5235129, lng: 19.4231215 },
    { lat: 48.523452, lng: 19.4227387 },
    { lat: 48.5235234, lng: 19.4226262 },
    { lat: 48.5242992, lng: 19.4228724 },
    { lat: 48.5242976, lng: 19.4229373 },
    { lat: 48.5253329, lng: 19.4231424 },
    { lat: 48.5257507, lng: 19.4231128 },
    { lat: 48.5257553, lng: 19.4231596 },
    { lat: 48.5264675, lng: 19.4231029 },
    { lat: 48.5282389, lng: 19.4229618 },
    { lat: 48.5282726, lng: 19.4230791 },
    { lat: 48.5290719, lng: 19.4230369 },
    { lat: 48.5293372, lng: 19.4197634 },
    { lat: 48.5295141, lng: 19.4183237 },
    { lat: 48.5297458, lng: 19.4184096 },
    { lat: 48.5299799, lng: 19.4181905 },
    { lat: 48.5299961, lng: 19.4180764 },
    { lat: 48.5306952, lng: 19.4180832 },
    { lat: 48.5318414, lng: 19.4183265 },
    { lat: 48.5317197, lng: 19.4185849 },
    { lat: 48.5318229, lng: 19.4186958 },
    { lat: 48.5351303, lng: 19.419577 },
    { lat: 48.5353034, lng: 19.4222733 },
    { lat: 48.5357775, lng: 19.4236762 },
    { lat: 48.5366607, lng: 19.4252275 },
    { lat: 48.5367069, lng: 19.4256141 },
    { lat: 48.5365136, lng: 19.4261045 },
    { lat: 48.536675, lng: 19.4263273 },
    { lat: 48.5371817, lng: 19.4265063 },
    { lat: 48.5375871, lng: 19.4258301 },
    { lat: 48.538368, lng: 19.4269543 },
    { lat: 48.5386805, lng: 19.4276091 },
    { lat: 48.5393227, lng: 19.4282794 },
    { lat: 48.5395969, lng: 19.4290543 },
    { lat: 48.5406356, lng: 19.4310616 },
    { lat: 48.5427035, lng: 19.4299766 },
    { lat: 48.5427114, lng: 19.4303353 },
    { lat: 48.5422323, lng: 19.4304528 },
    { lat: 48.5423422, lng: 19.4326035 },
    { lat: 48.5420147, lng: 19.4326348 },
    { lat: 48.5420158, lng: 19.4351363 },
    { lat: 48.5421363, lng: 19.4353742 },
    { lat: 48.5421288, lng: 19.4358129 },
    { lat: 48.5425345, lng: 19.4381131 },
    { lat: 48.5425752, lng: 19.4383439 },
    { lat: 48.5425543, lng: 19.4385936 },
    { lat: 48.5427599, lng: 19.4385558 },
    { lat: 48.5428636, lng: 19.439303 },
    { lat: 48.5429843, lng: 19.4392322 },
    { lat: 48.5434905, lng: 19.4406054 },
    { lat: 48.5436471, lng: 19.4405101 },
    { lat: 48.5438083, lng: 19.4412552 },
    { lat: 48.5435402, lng: 19.442388 },
    { lat: 48.543458, lng: 19.443209 },
    { lat: 48.5428159, lng: 19.4458383 },
    { lat: 48.5428384, lng: 19.4460824 },
    { lat: 48.5428196, lng: 19.4461598 },
    { lat: 48.5426581, lng: 19.4461744 },
    { lat: 48.5423471, lng: 19.4477449 },
    { lat: 48.5426479, lng: 19.4478151 },
    { lat: 48.5429127, lng: 19.4461954 },
    { lat: 48.5435593, lng: 19.4465298 },
    { lat: 48.5437703, lng: 19.4464867 },
    { lat: 48.5451517, lng: 19.4481896 },
    { lat: 48.5453083, lng: 19.4482598 },
    { lat: 48.5457363, lng: 19.4486914 },
    { lat: 48.5472046, lng: 19.4495521 },
    { lat: 48.5477149, lng: 19.449986 },
    { lat: 48.5488237, lng: 19.4467753 },
    { lat: 48.5490166, lng: 19.4468494 },
    { lat: 48.549491, lng: 19.4441996 },
    { lat: 48.5521012, lng: 19.4466099 },
    { lat: 48.5526176, lng: 19.4470019 },
    { lat: 48.5528526, lng: 19.4475678 },
    { lat: 48.553485, lng: 19.448633 },
    { lat: 48.5533391, lng: 19.4488602 },
    { lat: 48.5533562, lng: 19.4489395 },
    { lat: 48.5540313, lng: 19.4485534 },
    { lat: 48.5540118, lng: 19.4493679 },
    { lat: 48.5542321, lng: 19.4493275 },
    { lat: 48.5541392, lng: 19.4500417 },
    { lat: 48.5541882, lng: 19.4501517 },
    { lat: 48.5542785, lng: 19.4502041 },
    { lat: 48.5544117, lng: 19.4501334 },
    { lat: 48.5546013, lng: 19.4505021 },
    { lat: 48.554807, lng: 19.4501989 },
    { lat: 48.554977, lng: 19.4497629 },
    { lat: 48.5550922, lng: 19.449672 },
    { lat: 48.5552948, lng: 19.4498113 },
    { lat: 48.5552247, lng: 19.4501211 },
    { lat: 48.5554129, lng: 19.4502413 },
    { lat: 48.55564, lng: 19.4505172 },
    { lat: 48.5560842, lng: 19.4507144 },
    { lat: 48.5566635, lng: 19.4510995 },
    { lat: 48.5568385, lng: 19.4511798 },
    { lat: 48.55741, lng: 19.4511793 },
    { lat: 48.5577529, lng: 19.4506079 },
    { lat: 48.558203, lng: 19.4509309 },
    { lat: 48.5581824, lng: 19.4509964 },
    { lat: 48.5586836, lng: 19.4513776 },
    { lat: 48.5588115, lng: 19.451025 },
    { lat: 48.5597258, lng: 19.4518516 },
    { lat: 48.5601692, lng: 19.4523447 },
    { lat: 48.5600622, lng: 19.4524659 },
    { lat: 48.5597976, lng: 19.4531522 },
    { lat: 48.5597345, lng: 19.4534641 },
    { lat: 48.5594966, lng: 19.4541134 },
    { lat: 48.5594516, lng: 19.4544493 },
    { lat: 48.5597976, lng: 19.4546941 },
    { lat: 48.5599096, lng: 19.4545082 },
    { lat: 48.5608232, lng: 19.4549863 },
    { lat: 48.5608866, lng: 19.454796 },
    { lat: 48.5621549, lng: 19.4555778 },
    { lat: 48.5626512, lng: 19.4557828 },
    { lat: 48.5625927, lng: 19.4561441 },
    { lat: 48.5626177, lng: 19.4563652 },
    { lat: 48.562734, lng: 19.4564692 },
    { lat: 48.562745, lng: 19.4568018 },
    { lat: 48.5626784, lng: 19.4569174 },
    { lat: 48.5628131, lng: 19.4573183 },
    { lat: 48.5627641, lng: 19.4574288 },
    { lat: 48.5631563, lng: 19.4579327 },
    { lat: 48.5635873, lng: 19.458187 },
    { lat: 48.5637325, lng: 19.4581858 },
    { lat: 48.5640454, lng: 19.4585387 },
    { lat: 48.5642054, lng: 19.4590171 },
    { lat: 48.5644166, lng: 19.4594661 },
    { lat: 48.5644923, lng: 19.4595382 },
    { lat: 48.5648526, lng: 19.4595327 },
    { lat: 48.5651729, lng: 19.4596607 },
    { lat: 48.565506, lng: 19.4596991 },
    { lat: 48.5658696, lng: 19.4596862 },
    { lat: 48.5659117, lng: 19.4594558 },
    { lat: 48.5661778, lng: 19.459313 },
    { lat: 48.5672185, lng: 19.458937 },
    { lat: 48.5678092, lng: 19.4588232 },
    { lat: 48.5675437, lng: 19.4576216 },
    { lat: 48.5676427, lng: 19.4575019 },
    { lat: 48.5675056, lng: 19.4573623 },
    { lat: 48.5676563, lng: 19.4566333 },
    { lat: 48.5676347, lng: 19.456531 },
    { lat: 48.5676867, lng: 19.4564411 },
    { lat: 48.5679372, lng: 19.4562256 },
    { lat: 48.5687868, lng: 19.4564164 },
    { lat: 48.5687854, lng: 19.4557351 },
    { lat: 48.5688397, lng: 19.4556176 },
    { lat: 48.5690151, lng: 19.4554648 },
    { lat: 48.5696441, lng: 19.4553964 },
    { lat: 48.5697795, lng: 19.4554739 },
    { lat: 48.5706315, lng: 19.4555444 },
    { lat: 48.5706739, lng: 19.4553445 },
    { lat: 48.571315, lng: 19.4552413 },
    { lat: 48.5713435, lng: 19.4550316 },
    { lat: 48.5717, lng: 19.4551867 },
    { lat: 48.5719554, lng: 19.4553913 },
    { lat: 48.5718843, lng: 19.4556799 },
    { lat: 48.5721986, lng: 19.4558267 },
    { lat: 48.5723772, lng: 19.4558839 },
    { lat: 48.5723852, lng: 19.4557667 },
    { lat: 48.5724842, lng: 19.4557612 },
    { lat: 48.5735674, lng: 19.4562464 },
    { lat: 48.5736995, lng: 19.4561316 },
    { lat: 48.5739618, lng: 19.4561259 },
    { lat: 48.5738592, lng: 19.4572122 },
    { lat: 48.5737784, lng: 19.4576567 },
    { lat: 48.5744298, lng: 19.4577735 },
    { lat: 48.5748636, lng: 19.4576279 },
    { lat: 48.5748934, lng: 19.4574457 },
    { lat: 48.5753935, lng: 19.4574201 },
    { lat: 48.5762523, lng: 19.4576085 },
    { lat: 48.577115, lng: 19.4580797 },
    { lat: 48.5772191, lng: 19.4576551 },
    { lat: 48.5774671, lng: 19.4578022 },
    { lat: 48.5774451, lng: 19.457912 },
    { lat: 48.5776207, lng: 19.4580016 },
    { lat: 48.5776489, lng: 19.4580993 },
    { lat: 48.5777808, lng: 19.4581732 },
    { lat: 48.5777919, lng: 19.4582653 },
    { lat: 48.5780665, lng: 19.4585014 },
    { lat: 48.578381, lng: 19.4590567 },
    { lat: 48.5784882, lng: 19.4590621 },
    { lat: 48.5785567, lng: 19.4591534 },
    { lat: 48.5791081, lng: 19.4592277 },
    { lat: 48.579263, lng: 19.4593138 },
    { lat: 48.5793213, lng: 19.4592845 },
    { lat: 48.5794082, lng: 19.4589949 },
    { lat: 48.5800231, lng: 19.4592276 },
    { lat: 48.5801266, lng: 19.4593195 },
    { lat: 48.5802259, lng: 19.4590577 },
    { lat: 48.5802047, lng: 19.4583859 },
    { lat: 48.5807023, lng: 19.4587157 },
    { lat: 48.580915, lng: 19.4589278 },
    { lat: 48.5812125, lng: 19.4592657 },
    { lat: 48.5816249, lng: 19.4599855 },
    { lat: 48.5818491, lng: 19.4601795 },
    { lat: 48.5821015, lng: 19.4602745 },
    { lat: 48.582818, lng: 19.4601947 },
    { lat: 48.5834083, lng: 19.4603842 },
    { lat: 48.5835137, lng: 19.4613636 },
    { lat: 48.5840322, lng: 19.4614372 },
    { lat: 48.5845098, lng: 19.4616012 },
    { lat: 48.5849189, lng: 19.4618462 },
    { lat: 48.5852497, lng: 19.4618354 },
    { lat: 48.5855569, lng: 19.4617295 },
    { lat: 48.5863103, lng: 19.4618616 },
    { lat: 48.5864504, lng: 19.4612409 },
    { lat: 48.5868296, lng: 19.4601908 },
    { lat: 48.5872476, lng: 19.4594855 },
    { lat: 48.5874443, lng: 19.4601745 },
    { lat: 48.5878395, lng: 19.4612055 },
    { lat: 48.5878648, lng: 19.4621373 },
    { lat: 48.5877722, lng: 19.4627857 },
    { lat: 48.5877594, lng: 19.463211 },
    { lat: 48.5879154, lng: 19.4636558 },
    { lat: 48.5881455, lng: 19.4639507 },
    { lat: 48.5883843, lng: 19.4638767 },
    { lat: 48.5892676, lng: 19.464257 },
    { lat: 48.5896894, lng: 19.4642878 },
    { lat: 48.5904548, lng: 19.4644862 },
    { lat: 48.590662, lng: 19.4643376 },
    { lat: 48.590987, lng: 19.4643736 },
    { lat: 48.591303, lng: 19.4642626 },
    { lat: 48.5918082, lng: 19.4644373 },
    { lat: 48.5918765, lng: 19.4642595 },
    { lat: 48.591829, lng: 19.4639915 },
    { lat: 48.5915238, lng: 19.4633902 },
    { lat: 48.5911805, lng: 19.4619936 },
    { lat: 48.5911344, lng: 19.4617087 },
    { lat: 48.5911447, lng: 19.4613225 },
    { lat: 48.590967, lng: 19.46097 },
    { lat: 48.5904369, lng: 19.4607435 },
    { lat: 48.589687, lng: 19.4602754 },
    { lat: 48.5897435, lng: 19.4598401 },
    { lat: 48.5903086, lng: 19.4592922 },
    { lat: 48.5904602, lng: 19.4588984 },
    { lat: 48.5907049, lng: 19.4586806 },
    { lat: 48.5921644, lng: 19.4585858 },
    { lat: 48.5928369, lng: 19.458413 },
    { lat: 48.5938225, lng: 19.4582794 },
    { lat: 48.5938925, lng: 19.4580427 },
    { lat: 48.5939866, lng: 19.4580551 },
    { lat: 48.5950503, lng: 19.4586284 },
    { lat: 48.5955886, lng: 19.4590809 },
    { lat: 48.5962241, lng: 19.4593902 },
    { lat: 48.5964744, lng: 19.4596125 },
    { lat: 48.596631, lng: 19.4599744 },
    { lat: 48.597287, lng: 19.4604137 },
    { lat: 48.5973801, lng: 19.4604038 },
    { lat: 48.597742, lng: 19.4608685 },
    { lat: 48.5979066, lng: 19.4609387 },
    { lat: 48.5981473, lng: 19.4612197 },
    { lat: 48.5983228, lng: 19.4613368 },
    { lat: 48.598379, lng: 19.4614786 },
    { lat: 48.598557, lng: 19.4615936 },
    { lat: 48.5988592, lng: 19.4619362 },
    { lat: 48.5993783, lng: 19.462317 },
    { lat: 48.5997185, lng: 19.4628919 },
    { lat: 48.5998016, lng: 19.4629575 },
    { lat: 48.5998795, lng: 19.4631544 },
    { lat: 48.6001733, lng: 19.4633444 },
    { lat: 48.6002521, lng: 19.4636821 },
    { lat: 48.6003709, lng: 19.4639305 },
    { lat: 48.6005429, lng: 19.4640886 },
    { lat: 48.6006742, lng: 19.4644439 },
    { lat: 48.6035124, lng: 19.4608279 },
    { lat: 48.6036907, lng: 19.4609992 },
    { lat: 48.6040938, lng: 19.461232 },
    { lat: 48.6044389, lng: 19.4619896 },
    { lat: 48.604747, lng: 19.46224 },
    { lat: 48.6055402, lng: 19.4631769 },
    { lat: 48.6052775, lng: 19.4634198 },
    { lat: 48.6050646, lng: 19.4632743 },
    { lat: 48.6049858, lng: 19.4636156 },
    { lat: 48.6048203, lng: 19.4638592 },
    { lat: 48.6048663, lng: 19.4644885 },
    { lat: 48.605134, lng: 19.4643801 },
    { lat: 48.6051663, lng: 19.4647067 },
    { lat: 48.6052902, lng: 19.4651665 },
    { lat: 48.6051218, lng: 19.4654251 },
    { lat: 48.6051908, lng: 19.4659394 },
    { lat: 48.6051595, lng: 19.4661835 },
    { lat: 48.6051894, lng: 19.4668024 },
    { lat: 48.6052936, lng: 19.4671271 },
    { lat: 48.605405, lng: 19.467125 },
    { lat: 48.6055097, lng: 19.4672702 },
    { lat: 48.6057373, lng: 19.467391 },
    { lat: 48.6057875, lng: 19.4672565 },
    { lat: 48.6065988, lng: 19.4672782 },
    { lat: 48.6066487, lng: 19.4673558 },
    { lat: 48.6067604, lng: 19.4672144 },
    { lat: 48.6070303, lng: 19.4670833 },
    { lat: 48.6071322, lng: 19.4671409 },
    { lat: 48.6072928, lng: 19.4672991 },
    { lat: 48.6073521, lng: 19.4674566 },
    { lat: 48.6070972, lng: 19.468059 },
    { lat: 48.6071464, lng: 19.4681713 },
    { lat: 48.6072025, lng: 19.4682391 },
    { lat: 48.6075178, lng: 19.4681858 },
    { lat: 48.6075171, lng: 19.4680484 },
    { lat: 48.6077129, lng: 19.4680893 },
    { lat: 48.6078337, lng: 19.4682649 },
    { lat: 48.6078843, lng: 19.4685042 },
    { lat: 48.6077206, lng: 19.4687177 },
    { lat: 48.6078092, lng: 19.4688804 },
    { lat: 48.6078945, lng: 19.4692577 },
    { lat: 48.6080236, lng: 19.4695151 },
    { lat: 48.6083209, lng: 19.4696314 },
    { lat: 48.6086216, lng: 19.4694336 },
    { lat: 48.6087651, lng: 19.4694167 },
    { lat: 48.6092062, lng: 19.4690623 },
    { lat: 48.6096406, lng: 19.4689522 },
    { lat: 48.6097429, lng: 19.4688253 },
    { lat: 48.6097055, lng: 19.4682663 },
    { lat: 48.6097729, lng: 19.468065 },
    { lat: 48.6102999, lng: 19.4681549 },
    { lat: 48.6111019, lng: 19.4681192 },
    { lat: 48.6115548, lng: 19.4677326 },
    { lat: 48.6117395, lng: 19.4677287 },
    { lat: 48.611948, lng: 19.4676073 },
    { lat: 48.6119848, lng: 19.4674278 },
    { lat: 48.6118605, lng: 19.4671058 },
    { lat: 48.612118, lng: 19.4668802 },
    { lat: 48.6121619, lng: 19.4666359 },
    { lat: 48.6125056, lng: 19.4664406 },
    { lat: 48.612648, lng: 19.4662318 },
    { lat: 48.6129915, lng: 19.4667575 },
    { lat: 48.6131788, lng: 19.4669556 },
    { lat: 48.6139901, lng: 19.4665435 },
    { lat: 48.6139749, lng: 19.4662862 },
    { lat: 48.6138323, lng: 19.465964 },
    { lat: 48.6139981, lng: 19.4656501 },
    { lat: 48.6141702, lng: 19.4658862 },
    { lat: 48.6143601, lng: 19.4655826 },
    { lat: 48.6138554, lng: 19.4646894 },
    { lat: 48.6135678, lng: 19.4643961 },
    { lat: 48.6137615, lng: 19.4638855 },
    { lat: 48.6138418, lng: 19.4632872 },
    { lat: 48.6136909, lng: 19.4628299 },
    { lat: 48.6137577, lng: 19.4626882 },
    { lat: 48.6142442, lng: 19.462586 },
    { lat: 48.6143595, lng: 19.4624267 },
    { lat: 48.6142138, lng: 19.4619795 },
    { lat: 48.6139472, lng: 19.461579 },
    { lat: 48.6139954, lng: 19.4606062 },
    { lat: 48.6135217, lng: 19.4604039 },
    { lat: 48.6133163, lng: 19.460208 },
    { lat: 48.6142961, lng: 19.4591283 },
    { lat: 48.614716, lng: 19.4584395 },
    { lat: 48.6154817, lng: 19.4575819 },
    { lat: 48.6170984, lng: 19.4555914 },
    { lat: 48.6181478, lng: 19.4545312 },
    { lat: 48.6190855, lng: 19.4532292 },
    { lat: 48.6204982, lng: 19.4514531 },
    { lat: 48.6207703, lng: 19.4510292 },
    { lat: 48.6217355, lng: 19.4499304 },
    { lat: 48.6221804, lng: 19.4492231 },
    { lat: 48.6236362, lng: 19.4476018 },
    { lat: 48.6247138, lng: 19.4463025 },
    { lat: 48.6257883, lng: 19.4452766 },
    { lat: 48.6263171, lng: 19.4443882 },
    { lat: 48.6263951, lng: 19.4442823 },
    { lat: 48.6269453, lng: 19.4435348 },
    { lat: 48.627609, lng: 19.442743 },
    { lat: 48.627199, lng: 19.44224 },
    { lat: 48.626505, lng: 19.44187 },
    { lat: 48.626128, lng: 19.441386 },
    { lat: 48.625969, lng: 19.441144 },
    { lat: 48.625722, lng: 19.440878 },
    { lat: 48.625461, lng: 19.440712 },
    { lat: 48.624785, lng: 19.440278 },
    { lat: 48.624363, lng: 19.440061 },
    { lat: 48.624105, lng: 19.439938 },
    { lat: 48.623738, lng: 19.439792 },
    { lat: 48.623353, lng: 19.439611 },
    { lat: 48.62306, lng: 19.439365 },
    { lat: 48.622716, lng: 19.438984 },
    { lat: 48.622534, lng: 19.438851 },
    { lat: 48.622192, lng: 19.438498 },
    { lat: 48.621763, lng: 19.438163 },
    { lat: 48.621306, lng: 19.437755 },
    { lat: 48.621165, lng: 19.437615 },
    { lat: 48.620817, lng: 19.437187 },
    { lat: 48.62057, lng: 19.436831 },
    { lat: 48.619983, lng: 19.436278 },
    { lat: 48.619532, lng: 19.435866 },
    { lat: 48.61933, lng: 19.435702 },
    { lat: 48.619203, lng: 19.435589 },
    { lat: 48.619136, lng: 19.435542 },
    { lat: 48.619076, lng: 19.435471 },
    { lat: 48.619002, lng: 19.435507 },
    { lat: 48.618902, lng: 19.435505 },
    { lat: 48.618699, lng: 19.43563 },
    { lat: 48.618623, lng: 19.435627 },
    { lat: 48.618496, lng: 19.435726 },
    { lat: 48.618394, lng: 19.435699 },
    { lat: 48.618155, lng: 19.435434 },
    { lat: 48.617811, lng: 19.435126 },
    { lat: 48.617686, lng: 19.435189 },
    { lat: 48.61732, lng: 19.435049 },
    { lat: 48.617117, lng: 19.435058 },
    { lat: 48.616952, lng: 19.434996 },
    { lat: 48.616765, lng: 19.43499 },
    { lat: 48.616535, lng: 19.434918 },
    { lat: 48.616038, lng: 19.434822 },
    { lat: 48.615403, lng: 19.434479 },
    { lat: 48.615115, lng: 19.434252 },
    { lat: 48.614809, lng: 19.433871 },
    { lat: 48.614545, lng: 19.433467 },
    { lat: 48.614394, lng: 19.433242 },
    { lat: 48.614342, lng: 19.432927 },
    { lat: 48.6142, lng: 19.432441 },
    { lat: 48.614162, lng: 19.432286 },
    { lat: 48.613969, lng: 19.431928 },
    { lat: 48.61387, lng: 19.431547 },
    { lat: 48.613636, lng: 19.430853 },
    { lat: 48.613542, lng: 19.430635 },
    { lat: 48.613412, lng: 19.43029 },
    { lat: 48.613247, lng: 19.430066 },
    { lat: 48.613074, lng: 19.429765 },
    { lat: 48.613, lng: 19.429643 },
    { lat: 48.612514, lng: 19.429067 },
    { lat: 48.612153, lng: 19.428679 },
    { lat: 48.611977, lng: 19.428494 },
    { lat: 48.611667, lng: 19.428316 },
    { lat: 48.611498, lng: 19.428181 },
    { lat: 48.611008, lng: 19.427799 },
    { lat: 48.610795, lng: 19.42771 },
    { lat: 48.610634, lng: 19.427706 },
    { lat: 48.610449, lng: 19.427749 },
    { lat: 48.610186, lng: 19.427738 },
    { lat: 48.609802, lng: 19.427607 },
    { lat: 48.609575, lng: 19.427599 },
    { lat: 48.609317, lng: 19.427524 },
    { lat: 48.60908, lng: 19.427392 },
    { lat: 48.608977, lng: 19.427338 },
    { lat: 48.608673, lng: 19.427115 },
    { lat: 48.60857, lng: 19.42699 },
    { lat: 48.608333, lng: 19.426786 },
    { lat: 48.608087, lng: 19.426323 },
    { lat: 48.60793, lng: 19.425953 },
    { lat: 48.607881, lng: 19.425607 },
    { lat: 48.607819, lng: 19.425197 },
    { lat: 48.607838, lng: 19.425012 },
    { lat: 48.607844, lng: 19.424732 },
    { lat: 48.607872, lng: 19.424535 },
    { lat: 48.607953, lng: 19.424374 },
    { lat: 48.608084, lng: 19.42417 },
    { lat: 48.608184, lng: 19.423936 },
    { lat: 48.60826, lng: 19.423649 },
    { lat: 48.608455, lng: 19.423249 },
    { lat: 48.608532, lng: 19.422976 },
    { lat: 48.608534, lng: 19.422905 },
    { lat: 48.608575, lng: 19.422804 },
    { lat: 48.608676, lng: 19.422744 },
    { lat: 48.608781, lng: 19.422509 },
    { lat: 48.608807, lng: 19.422401 },
    { lat: 48.608909, lng: 19.422285 },
    { lat: 48.608974, lng: 19.422052 },
    { lat: 48.608954, lng: 19.421869 },
    { lat: 48.609017, lng: 19.421589 },
    { lat: 48.609013, lng: 19.421542 },
    { lat: 48.60915, lng: 19.42116 },
    { lat: 48.609089, lng: 19.42088 },
    { lat: 48.609139, lng: 19.420416 },
    { lat: 48.609178, lng: 19.420287 },
    { lat: 48.609176, lng: 19.420127 },
    { lat: 48.609239, lng: 19.4199 },
    { lat: 48.60911, lng: 19.41878 },
    { lat: 48.609053, lng: 19.418461 },
    { lat: 48.608863, lng: 19.418019 },
    { lat: 48.608757, lng: 19.417942 },
    { lat: 48.608644, lng: 19.417463 },
    { lat: 48.608534, lng: 19.417105 },
    { lat: 48.608393, lng: 19.417046 },
    { lat: 48.608288, lng: 19.416959 },
    { lat: 48.608239, lng: 19.416848 },
    { lat: 48.608236, lng: 19.416713 },
    { lat: 48.608025, lng: 19.416469 },
    { lat: 48.6081004, lng: 19.4149278 },
    { lat: 48.6083572, lng: 19.4141368 },
    { lat: 48.608269, lng: 19.4124066 },
    { lat: 48.6073246, lng: 19.411879 },
    { lat: 48.6065332, lng: 19.4117525 },
    { lat: 48.6057953, lng: 19.4109671 },
    { lat: 48.6055334, lng: 19.41055 },
    { lat: 48.6053336, lng: 19.4088186 },
    { lat: 48.6052844, lng: 19.4088032 },
    { lat: 48.605099, lng: 19.4083163 },
    { lat: 48.6046999, lng: 19.4078544 },
    { lat: 48.6043064, lng: 19.4075169 },
    { lat: 48.6040775, lng: 19.4050227 },
    { lat: 48.6043658, lng: 19.4027259 },
    { lat: 48.6039925, lng: 19.4011371 },
    { lat: 48.6037937, lng: 19.399646 },
    { lat: 48.6034504, lng: 19.397985 },
    { lat: 48.6028373, lng: 19.3968382 },
    { lat: 48.6020877, lng: 19.3959703 },
    { lat: 48.6013332, lng: 19.3952458 },
    { lat: 48.6009686, lng: 19.3946624 },
    { lat: 48.6004406, lng: 19.3935027 },
    { lat: 48.5998638, lng: 19.3929799 },
    { lat: 48.5979336, lng: 19.3905091 },
    { lat: 48.597452, lng: 19.3901435 },
    { lat: 48.5962311, lng: 19.3889212 },
    { lat: 48.5962632, lng: 19.3881202 },
    { lat: 48.5959478, lng: 19.3878842 },
    { lat: 48.5942428, lng: 19.3828356 },
    { lat: 48.5937145, lng: 19.3816477 },
    { lat: 48.5932462, lng: 19.3811829 },
    { lat: 48.5935298, lng: 19.3806529 },
    { lat: 48.5930357, lng: 19.3798554 },
    { lat: 48.5924677, lng: 19.3790713 },
    { lat: 48.592242, lng: 19.3788485 },
    { lat: 48.5917945, lng: 19.3787044 },
    { lat: 48.5915847, lng: 19.3785137 },
    { lat: 48.5906099, lng: 19.3769898 },
    { lat: 48.5901726, lng: 19.3773704 },
    { lat: 48.5900481, lng: 19.3772154 },
    { lat: 48.5894274, lng: 19.3779875 },
    { lat: 48.5888908, lng: 19.3787711 },
    { lat: 48.5882608, lng: 19.378124 },
    { lat: 48.5883172, lng: 19.3788666 },
    { lat: 48.5878378, lng: 19.3793821 },
    { lat: 48.5873196, lng: 19.3796695 },
    { lat: 48.5865532, lng: 19.378956 },
    { lat: 48.5862369, lng: 19.3797539 },
    { lat: 48.5858096, lng: 19.3799423 },
    { lat: 48.5851625, lng: 19.3804099 },
    { lat: 48.5847447, lng: 19.3804897 },
    { lat: 48.5845074, lng: 19.3802877 },
    { lat: 48.5840075, lng: 19.3795854 },
    { lat: 48.5825853, lng: 19.3791314 },
    { lat: 48.5810545, lng: 19.3783211 },
    { lat: 48.5808522, lng: 19.3779289 },
    { lat: 48.578519, lng: 19.3772534 },
    { lat: 48.5778421, lng: 19.3773828 },
    { lat: 48.5747936, lng: 19.378556 },
    { lat: 48.572768, lng: 19.3788567 },
    { lat: 48.5721678, lng: 19.3793248 },
    { lat: 48.5714432, lng: 19.3792533 },
    { lat: 48.5708637, lng: 19.3778686 },
    { lat: 48.5695567, lng: 19.3773992 },
  ],
  DetvianskaHuta: [
    { lat: 48.5924767, lng: 19.6353998 },
    { lat: 48.593288, lng: 19.6335533 },
    { lat: 48.5953135, lng: 19.6285725 },
    { lat: 48.5957943, lng: 19.6294106 },
    { lat: 48.5968625, lng: 19.6299343 },
    { lat: 48.5971026, lng: 19.6294889 },
    { lat: 48.5984425, lng: 19.6292201 },
    { lat: 48.599144, lng: 19.6279331 },
    { lat: 48.5991253, lng: 19.6274192 },
    { lat: 48.5988856, lng: 19.6266296 },
    { lat: 48.5988565, lng: 19.626084 },
    { lat: 48.5986116, lng: 19.6257283 },
    { lat: 48.5988009, lng: 19.6250257 },
    { lat: 48.5987482, lng: 19.6245331 },
    { lat: 48.599053, lng: 19.6236972 },
    { lat: 48.5989358, lng: 19.6232244 },
    { lat: 48.5990469, lng: 19.6231646 },
    { lat: 48.5992723, lng: 19.6221706 },
    { lat: 48.5990692, lng: 19.6213399 },
    { lat: 48.5989415, lng: 19.6213075 },
    { lat: 48.598866, lng: 19.620557 },
    { lat: 48.5988854, lng: 19.6194194 },
    { lat: 48.59869, lng: 19.6184619 },
    { lat: 48.5983578, lng: 19.6174971 },
    { lat: 48.5985506, lng: 19.6174221 },
    { lat: 48.5979693, lng: 19.6161551 },
    { lat: 48.5980533, lng: 19.615919 },
    { lat: 48.5980466, lng: 19.615474 },
    { lat: 48.5979286, lng: 19.614436 },
    { lat: 48.5976191, lng: 19.6131243 },
    { lat: 48.5974698, lng: 19.611668 },
    { lat: 48.5971176, lng: 19.611034 },
    { lat: 48.5966106, lng: 19.6103863 },
    { lat: 48.5962423, lng: 19.610142 },
    { lat: 48.59564, lng: 19.6098576 },
    { lat: 48.5949713, lng: 19.6091733 },
    { lat: 48.5942712, lng: 19.6086698 },
    { lat: 48.5936183, lng: 19.6087302 },
    { lat: 48.5931539, lng: 19.6088785 },
    { lat: 48.5923142, lng: 19.6103421 },
    { lat: 48.5922271, lng: 19.6108313 },
    { lat: 48.5920661, lng: 19.609765 },
    { lat: 48.5924091, lng: 19.6091015 },
    { lat: 48.5924389, lng: 19.6087172 },
    { lat: 48.5926444, lng: 19.6081546 },
    { lat: 48.5926235, lng: 19.6074181 },
    { lat: 48.5925275, lng: 19.6069407 },
    { lat: 48.5921918, lng: 19.6059274 },
    { lat: 48.5917542, lng: 19.6040774 },
    { lat: 48.5910609, lng: 19.6031768 },
    { lat: 48.5902892, lng: 19.6026872 },
    { lat: 48.5900813, lng: 19.6022018 },
    { lat: 48.5901583, lng: 19.6017856 },
    { lat: 48.5907886, lng: 19.6006781 },
    { lat: 48.5911925, lng: 19.6004883 },
    { lat: 48.5921958, lng: 19.6002563 },
    { lat: 48.592822, lng: 19.6002697 },
    { lat: 48.5929431, lng: 19.599636 },
    { lat: 48.592395, lng: 19.5993078 },
    { lat: 48.5919187, lng: 19.5992124 },
    { lat: 48.5916649, lng: 19.5990841 },
    { lat: 48.5913725, lng: 19.5986235 },
    { lat: 48.591197, lng: 19.5986807 },
    { lat: 48.5911562, lng: 19.5982016 },
    { lat: 48.5909847, lng: 19.5981951 },
    { lat: 48.5910047, lng: 19.5978933 },
    { lat: 48.5910973, lng: 19.5978727 },
    { lat: 48.5910586, lng: 19.5977169 },
    { lat: 48.5909382, lng: 19.5975976 },
    { lat: 48.5906897, lng: 19.5980066 },
    { lat: 48.5905385, lng: 19.5983753 },
    { lat: 48.5888156, lng: 19.5979775 },
    { lat: 48.5877565, lng: 19.5978815 },
    { lat: 48.5871926, lng: 19.5973843 },
    { lat: 48.5869402, lng: 19.5971708 },
    { lat: 48.5863409, lng: 19.5966334 },
    { lat: 48.5862666, lng: 19.5965892 },
    { lat: 48.5858128, lng: 19.5963196 },
    { lat: 48.5831083, lng: 19.5955475 },
    { lat: 48.5818234, lng: 19.5952431 },
    { lat: 48.5820517, lng: 19.5947643 },
    { lat: 48.5823644, lng: 19.5947513 },
    { lat: 48.5831514, lng: 19.593223 },
    { lat: 48.5828829, lng: 19.5928222 },
    { lat: 48.5832658, lng: 19.5915801 },
    { lat: 48.5838189, lng: 19.5919714 },
    { lat: 48.5839021, lng: 19.5915428 },
    { lat: 48.5839864, lng: 19.5906234 },
    { lat: 48.5843175, lng: 19.5891342 },
    { lat: 48.5845458, lng: 19.5884287 },
    { lat: 48.5849425, lng: 19.5877631 },
    { lat: 48.5844858, lng: 19.587359 },
    { lat: 48.5841254, lng: 19.5866681 },
    { lat: 48.5840728, lng: 19.5861088 },
    { lat: 48.5841732, lng: 19.5851709 },
    { lat: 48.5840321, lng: 19.584768 },
    { lat: 48.5839151, lng: 19.5846723 },
    { lat: 48.5838823, lng: 19.58487 },
    { lat: 48.5802625, lng: 19.5829797 },
    { lat: 48.5804574, lng: 19.5828065 },
    { lat: 48.5802425, lng: 19.5817992 },
    { lat: 48.5801854, lng: 19.5811161 },
    { lat: 48.5800483, lng: 19.5806791 },
    { lat: 48.5799307, lng: 19.580498 },
    { lat: 48.5797799, lng: 19.5800798 },
    { lat: 48.5796989, lng: 19.5796402 },
    { lat: 48.5796157, lng: 19.5782858 },
    { lat: 48.5790375, lng: 19.577988 },
    { lat: 48.5790236, lng: 19.578103 },
    { lat: 48.5788005, lng: 19.5781756 },
    { lat: 48.578682, lng: 19.5764171 },
    { lat: 48.5785217, lng: 19.5763596 },
    { lat: 48.5784746, lng: 19.5760941 },
    { lat: 48.5783413, lng: 19.5761641 },
    { lat: 48.576369, lng: 19.575808 },
    { lat: 48.5717163, lng: 19.5747479 },
    { lat: 48.5717327, lng: 19.5751081 },
    { lat: 48.571607, lng: 19.5753652 },
    { lat: 48.5707128, lng: 19.5755009 },
    { lat: 48.5705715, lng: 19.5757148 },
    { lat: 48.5702691, lng: 19.5758781 },
    { lat: 48.5700707, lng: 19.5754315 },
    { lat: 48.56969, lng: 19.5754624 },
    { lat: 48.5692304, lng: 19.5756353 },
    { lat: 48.5691355, lng: 19.5755586 },
    { lat: 48.5689915, lng: 19.5742304 },
    { lat: 48.5684963, lng: 19.5741682 },
    { lat: 48.5681472, lng: 19.5741493 },
    { lat: 48.566532, lng: 19.575204 },
    { lat: 48.5661526, lng: 19.5748181 },
    { lat: 48.5651439, lng: 19.5736753 },
    { lat: 48.5655153, lng: 19.5727018 },
    { lat: 48.5653494, lng: 19.5723778 },
    { lat: 48.565643, lng: 19.571775 },
    { lat: 48.5657419, lng: 19.5717704 },
    { lat: 48.5659319, lng: 19.5712875 },
    { lat: 48.5662923, lng: 19.5708648 },
    { lat: 48.566618, lng: 19.5692891 },
    { lat: 48.5667635, lng: 19.5689246 },
    { lat: 48.5671768, lng: 19.5682011 },
    { lat: 48.5673093, lng: 19.5680945 },
    { lat: 48.56685, lng: 19.5677327 },
    { lat: 48.5666178, lng: 19.5668406 },
    { lat: 48.5667876, lng: 19.5658233 },
    { lat: 48.5668241, lng: 19.5650947 },
    { lat: 48.5660935, lng: 19.5648148 },
    { lat: 48.5655166, lng: 19.5639308 },
    { lat: 48.5654029, lng: 19.5636082 },
    { lat: 48.5651673, lng: 19.5633875 },
    { lat: 48.564956, lng: 19.5630067 },
    { lat: 48.5647016, lng: 19.5628154 },
    { lat: 48.5644662, lng: 19.5624482 },
    { lat: 48.5642338, lng: 19.5621951 },
    { lat: 48.5637528, lng: 19.5619523 },
    { lat: 48.5635771, lng: 19.5617813 },
    { lat: 48.5631668, lng: 19.5616083 },
    { lat: 48.5623422, lng: 19.5625474 },
    { lat: 48.5619157, lng: 19.5627835 },
    { lat: 48.5619055, lng: 19.563253 },
    { lat: 48.5609521, lng: 19.564061 },
    { lat: 48.5599438, lng: 19.5636737 },
    { lat: 48.5597327, lng: 19.5639372 },
    { lat: 48.5589187, lng: 19.5645183 },
    { lat: 48.5588422, lng: 19.5650413 },
    { lat: 48.558458, lng: 19.5650915 },
    { lat: 48.5583468, lng: 19.5660318 },
    { lat: 48.5577089, lng: 19.5660561 },
    { lat: 48.5571394, lng: 19.5664915 },
    { lat: 48.5567273, lng: 19.5667228 },
    { lat: 48.5564971, lng: 19.5679209 },
    { lat: 48.5562547, lng: 19.5678204 },
    { lat: 48.5561009, lng: 19.5684745 },
    { lat: 48.5558703, lng: 19.5687044 },
    { lat: 48.5551659, lng: 19.5685541 },
    { lat: 48.5545601, lng: 19.5690843 },
    { lat: 48.5545282, lng: 19.5687529 },
    { lat: 48.5537715, lng: 19.5694311 },
    { lat: 48.5526523, lng: 19.5705864 },
    { lat: 48.5521384, lng: 19.5703359 },
    { lat: 48.5517321, lng: 19.5700277 },
    { lat: 48.5516501, lng: 19.5701098 },
    { lat: 48.5516562, lng: 19.570218 },
    { lat: 48.5515935, lng: 19.570349 },
    { lat: 48.551431, lng: 19.570416 },
    { lat: 48.5514093, lng: 19.5704837 },
    { lat: 48.5512334, lng: 19.5698162 },
    { lat: 48.55069, lng: 19.5692427 },
    { lat: 48.5503138, lng: 19.5680425 },
    { lat: 48.5503323, lng: 19.5673382 },
    { lat: 48.5503337, lng: 19.5673153 },
    { lat: 48.549938, lng: 19.5675085 },
    { lat: 48.5490794, lng: 19.5675137 },
    { lat: 48.5486416, lng: 19.5679414 },
    { lat: 48.5482793, lng: 19.5684474 },
    { lat: 48.5479435, lng: 19.5687194 },
    { lat: 48.5474913, lng: 19.568958 },
    { lat: 48.5455027, lng: 19.5685208 },
    { lat: 48.5447507, lng: 19.5679505 },
    { lat: 48.544776, lng: 19.56789 },
    { lat: 48.544146, lng: 19.56781 },
    { lat: 48.544122, lng: 19.567807 },
    { lat: 48.543175, lng: 19.567687 },
    { lat: 48.542708, lng: 19.567595 },
    { lat: 48.542324, lng: 19.567396 },
    { lat: 48.541995, lng: 19.567388 },
    { lat: 48.541907, lng: 19.567375 },
    { lat: 48.541888, lng: 19.567382 },
    { lat: 48.541874, lng: 19.567437 },
    { lat: 48.54145, lng: 19.567659 },
    { lat: 48.540883, lng: 19.56774 },
    { lat: 48.540593, lng: 19.567651 },
    { lat: 48.540383, lng: 19.567597 },
    { lat: 48.540124, lng: 19.567528 },
    { lat: 48.539977, lng: 19.567567 },
    { lat: 48.539315, lng: 19.56748 },
    { lat: 48.53918, lng: 19.567474 },
    { lat: 48.539118, lng: 19.567452 },
    { lat: 48.538588, lng: 19.567796 },
    { lat: 48.538391, lng: 19.567924 },
    { lat: 48.538201, lng: 19.568146 },
    { lat: 48.537995, lng: 19.568389 },
    { lat: 48.537784, lng: 19.568549 },
    { lat: 48.537179, lng: 19.568792 },
    { lat: 48.536723, lng: 19.569086 },
    { lat: 48.536442, lng: 19.569108 },
    { lat: 48.536532, lng: 19.569639 },
    { lat: 48.53645, lng: 19.571005 },
    { lat: 48.536377, lng: 19.571752 },
    { lat: 48.536224, lng: 19.572143 },
    { lat: 48.535949, lng: 19.573026 },
    { lat: 48.536, lng: 19.573631 },
    { lat: 48.536182, lng: 19.57417 },
    { lat: 48.536453, lng: 19.57492 },
    { lat: 48.536688, lng: 19.575604 },
    { lat: 48.536962, lng: 19.576146 },
    { lat: 48.5371068, lng: 19.5763271 },
    { lat: 48.538605, lng: 19.5760166 },
    { lat: 48.5390474, lng: 19.57623 },
    { lat: 48.5395391, lng: 19.5761642 },
    { lat: 48.5397182, lng: 19.5760646 },
    { lat: 48.5409511, lng: 19.5757438 },
    { lat: 48.541557, lng: 19.5753927 },
    { lat: 48.5420934, lng: 19.5751682 },
    { lat: 48.5424837, lng: 19.5751317 },
    { lat: 48.5432773, lng: 19.5749137 },
    { lat: 48.5438451, lng: 19.5749619 },
    { lat: 48.544276, lng: 19.5752409 },
    { lat: 48.5445174, lng: 19.5754958 },
    { lat: 48.5452314, lng: 19.5770403 },
    { lat: 48.5454496, lng: 19.577352 },
    { lat: 48.5475147, lng: 19.5783528 },
    { lat: 48.5476613, lng: 19.5784816 },
    { lat: 48.5477992, lng: 19.5788113 },
    { lat: 48.5478452, lng: 19.5805343 },
    { lat: 48.5479809, lng: 19.5815061 },
    { lat: 48.5482068, lng: 19.5819913 },
    { lat: 48.5488398, lng: 19.5828228 },
    { lat: 48.5491893, lng: 19.5831714 },
    { lat: 48.5493579, lng: 19.5832639 },
    { lat: 48.5497043, lng: 19.5832494 },
    { lat: 48.550709, lng: 19.5824705 },
    { lat: 48.5511863, lng: 19.5823133 },
    { lat: 48.5516075, lng: 19.5824092 },
    { lat: 48.552261, lng: 19.5828011 },
    { lat: 48.5529621, lng: 19.5830025 },
    { lat: 48.5546287, lng: 19.5826302 },
    { lat: 48.5549912, lng: 19.5827958 },
    { lat: 48.5553532, lng: 19.5832464 },
    { lat: 48.5555181, lng: 19.5833578 },
    { lat: 48.5563261, lng: 19.5836047 },
    { lat: 48.557213, lng: 19.5836769 },
    { lat: 48.5575813, lng: 19.5837754 },
    { lat: 48.5583612, lng: 19.5843955 },
    { lat: 48.5591703, lng: 19.5848376 },
    { lat: 48.5596016, lng: 19.585401 },
    { lat: 48.5599926, lng: 19.5862666 },
    { lat: 48.5601395, lng: 19.5866708 },
    { lat: 48.5601797, lng: 19.5869107 },
    { lat: 48.5602861, lng: 19.5887278 },
    { lat: 48.560254, lng: 19.5900053 },
    { lat: 48.5604969, lng: 19.5907871 },
    { lat: 48.5608242, lng: 19.5922096 },
    { lat: 48.5607195, lng: 19.5947878 },
    { lat: 48.5607585, lng: 19.5950484 },
    { lat: 48.561584, lng: 19.5966988 },
    { lat: 48.5616903, lng: 19.5973026 },
    { lat: 48.5617655, lng: 19.5990308 },
    { lat: 48.5618096, lng: 19.5990331 },
    { lat: 48.5622097, lng: 19.5999566 },
    { lat: 48.5626066, lng: 19.6006303 },
    { lat: 48.5626535, lng: 19.6011634 },
    { lat: 48.5629066, lng: 19.602323 },
    { lat: 48.5631398, lng: 19.6029671 },
    { lat: 48.5653352, lng: 19.6074703 },
    { lat: 48.5657833, lng: 19.6078842 },
    { lat: 48.5665039, lng: 19.6084141 },
    { lat: 48.5670957, lng: 19.6084467 },
    { lat: 48.5688862, lng: 19.6080518 },
    { lat: 48.5692766, lng: 19.6083073 },
    { lat: 48.5698787, lng: 19.6091351 },
    { lat: 48.5701876, lng: 19.6098597 },
    { lat: 48.5702646, lng: 19.6101428 },
    { lat: 48.5702145, lng: 19.6104533 },
    { lat: 48.5698877, lng: 19.6111736 },
    { lat: 48.5698605, lng: 19.6114425 },
    { lat: 48.569901, lng: 19.6119916 },
    { lat: 48.5701312, lng: 19.6132171 },
    { lat: 48.5718518, lng: 19.6172465 },
    { lat: 48.5722558, lng: 19.6183567 },
    { lat: 48.5722409, lng: 19.61855 },
    { lat: 48.5724458, lng: 19.6191694 },
    { lat: 48.5724913, lng: 19.6194501 },
    { lat: 48.5724788, lng: 19.6211158 },
    { lat: 48.5725551, lng: 19.6214079 },
    { lat: 48.5727846, lng: 19.6218 },
    { lat: 48.573171, lng: 19.6221933 },
    { lat: 48.5743949, lng: 19.6229006 },
    { lat: 48.5746664, lng: 19.6238708 },
    { lat: 48.5747575, lng: 19.6244653 },
    { lat: 48.5748469, lng: 19.6252418 },
    { lat: 48.5747839, lng: 19.6261919 },
    { lat: 48.5749295, lng: 19.6269686 },
    { lat: 48.5752224, lng: 19.6278445 },
    { lat: 48.5760811, lng: 19.628914 },
    { lat: 48.5773091, lng: 19.6299584 },
    { lat: 48.5781443, lng: 19.6296154 },
    { lat: 48.5781565, lng: 19.6294619 },
    { lat: 48.5783342, lng: 19.6295269 },
    { lat: 48.5792038, lng: 19.6291232 },
    { lat: 48.5796345, lng: 19.6290303 },
    { lat: 48.5804049, lng: 19.6293217 },
    { lat: 48.5811932, lng: 19.6293142 },
    { lat: 48.5815321, lng: 19.6295826 },
    { lat: 48.5821624, lng: 19.6304368 },
    { lat: 48.5837847, lng: 19.6305808 },
    { lat: 48.5838884, lng: 19.6319531 },
    { lat: 48.5844737, lng: 19.6338423 },
    { lat: 48.5846967, lng: 19.6343622 },
    { lat: 48.5849277, lng: 19.6346101 },
    { lat: 48.5853694, lng: 19.6352631 },
    { lat: 48.5855344, lng: 19.6353971 },
    { lat: 48.5857158, lng: 19.6354728 },
    { lat: 48.5864118, lng: 19.6353399 },
    { lat: 48.5876666, lng: 19.6357011 },
    { lat: 48.5885784, lng: 19.6349357 },
    { lat: 48.5887779, lng: 19.6346633 },
    { lat: 48.5892134, lng: 19.6343976 },
    { lat: 48.5896663, lng: 19.6342982 },
    { lat: 48.5908259, lng: 19.6347962 },
    { lat: 48.5919326, lng: 19.6351259 },
    { lat: 48.5922903, lng: 19.6353619 },
    { lat: 48.5924767, lng: 19.6353998 },
  ],
  Korytárky: [
    { lat: 48.5644923, lng: 19.4595382 },
    { lat: 48.5644166, lng: 19.4594661 },
    { lat: 48.5642054, lng: 19.4590171 },
    { lat: 48.5640454, lng: 19.4585387 },
    { lat: 48.5637325, lng: 19.4581858 },
    { lat: 48.5635873, lng: 19.458187 },
    { lat: 48.5631563, lng: 19.4579327 },
    { lat: 48.5627641, lng: 19.4574288 },
    { lat: 48.5628131, lng: 19.4573183 },
    { lat: 48.5626784, lng: 19.4569174 },
    { lat: 48.562745, lng: 19.4568018 },
    { lat: 48.562734, lng: 19.4564692 },
    { lat: 48.5626177, lng: 19.4563652 },
    { lat: 48.5625927, lng: 19.4561441 },
    { lat: 48.5626512, lng: 19.4557828 },
    { lat: 48.5621549, lng: 19.4555778 },
    { lat: 48.5608866, lng: 19.454796 },
    { lat: 48.5608232, lng: 19.4549863 },
    { lat: 48.5599096, lng: 19.4545082 },
    { lat: 48.5597976, lng: 19.4546941 },
    { lat: 48.5594516, lng: 19.4544493 },
    { lat: 48.5594966, lng: 19.4541134 },
    { lat: 48.5597345, lng: 19.4534641 },
    { lat: 48.5597976, lng: 19.4531522 },
    { lat: 48.5600622, lng: 19.4524659 },
    { lat: 48.5601692, lng: 19.4523447 },
    { lat: 48.5597258, lng: 19.4518516 },
    { lat: 48.5588115, lng: 19.451025 },
    { lat: 48.5586836, lng: 19.4513776 },
    { lat: 48.5581824, lng: 19.4509964 },
    { lat: 48.558203, lng: 19.4509309 },
    { lat: 48.5577529, lng: 19.4506079 },
    { lat: 48.55741, lng: 19.4511793 },
    { lat: 48.5568385, lng: 19.4511798 },
    { lat: 48.5566635, lng: 19.4510995 },
    { lat: 48.5560842, lng: 19.4507144 },
    { lat: 48.55564, lng: 19.4505172 },
    { lat: 48.5554129, lng: 19.4502413 },
    { lat: 48.5552247, lng: 19.4501211 },
    { lat: 48.5552948, lng: 19.4498113 },
    { lat: 48.5550922, lng: 19.449672 },
    { lat: 48.554977, lng: 19.4497629 },
    { lat: 48.554807, lng: 19.4501989 },
    { lat: 48.5546013, lng: 19.4505021 },
    { lat: 48.5544117, lng: 19.4501334 },
    { lat: 48.5542785, lng: 19.4502041 },
    { lat: 48.5541882, lng: 19.4501517 },
    { lat: 48.5541392, lng: 19.4500417 },
    { lat: 48.5542321, lng: 19.4493275 },
    { lat: 48.5540118, lng: 19.4493679 },
    { lat: 48.5540313, lng: 19.4485534 },
    { lat: 48.5533562, lng: 19.4489395 },
    { lat: 48.5533391, lng: 19.4488602 },
    { lat: 48.553485, lng: 19.448633 },
    { lat: 48.5528526, lng: 19.4475678 },
    { lat: 48.5526176, lng: 19.4470019 },
    { lat: 48.5521012, lng: 19.4466099 },
    { lat: 48.549491, lng: 19.4441996 },
    { lat: 48.5490166, lng: 19.4468494 },
    { lat: 48.5488237, lng: 19.4467753 },
    { lat: 48.5477149, lng: 19.449986 },
    { lat: 48.5472046, lng: 19.4495521 },
    { lat: 48.5457363, lng: 19.4486914 },
    { lat: 48.5453083, lng: 19.4482598 },
    { lat: 48.5450562, lng: 19.4494044 },
    { lat: 48.5450572, lng: 19.4497596 },
    { lat: 48.5448829, lng: 19.4513349 },
    { lat: 48.5442741, lng: 19.4545837 },
    { lat: 48.5449848, lng: 19.4549401 },
    { lat: 48.542808, lng: 19.4591245 },
    { lat: 48.5424503, lng: 19.4586556 },
    { lat: 48.5424606, lng: 19.4585631 },
    { lat: 48.5424323, lng: 19.4585387 },
    { lat: 48.5424183, lng: 19.4586234 },
    { lat: 48.5411478, lng: 19.4571917 },
    { lat: 48.5409424, lng: 19.4575936 },
    { lat: 48.5408521, lng: 19.4574951 },
    { lat: 48.5408124, lng: 19.457585 },
    { lat: 48.5392449, lng: 19.4567431 },
    { lat: 48.535879, lng: 19.465836 },
    { lat: 48.5352099, lng: 19.4680424 },
    { lat: 48.5347093, lng: 19.4682304 },
    { lat: 48.5341974, lng: 19.4682482 },
    { lat: 48.5330807, lng: 19.4677447 },
    { lat: 48.5329737, lng: 19.4688593 },
    { lat: 48.5318888, lng: 19.4678749 },
    { lat: 48.5316143, lng: 19.4692423 },
    { lat: 48.5313144, lng: 19.4710777 },
    { lat: 48.5319002, lng: 19.4716786 },
    { lat: 48.5308428, lng: 19.4739425 },
    { lat: 48.531133, lng: 19.4743428 },
    { lat: 48.5323596, lng: 19.4755148 },
    { lat: 48.5331174, lng: 19.4771483 },
    { lat: 48.5340095, lng: 19.4794672 },
    { lat: 48.5348169, lng: 19.4813403 },
    { lat: 48.5350213, lng: 19.4821505 },
    { lat: 48.5350749, lng: 19.4830605 },
    { lat: 48.5348336, lng: 19.4850743 },
    { lat: 48.5347804, lng: 19.4858925 },
    { lat: 48.5348997, lng: 19.4869555 },
    { lat: 48.5350995, lng: 19.487597 },
    { lat: 48.5354845, lng: 19.4883864 },
    { lat: 48.5366102, lng: 19.4894037 },
    { lat: 48.5367846, lng: 19.4897178 },
    { lat: 48.5369828, lng: 19.4907314 },
    { lat: 48.5371475, lng: 19.4910995 },
    { lat: 48.5373175, lng: 19.4913121 },
    { lat: 48.5375156, lng: 19.4914039 },
    { lat: 48.5382512, lng: 19.4913522 },
    { lat: 48.5387451, lng: 19.4915018 },
    { lat: 48.5392273, lng: 19.4919148 },
    { lat: 48.5393637, lng: 19.4925687 },
    { lat: 48.5396509, lng: 19.4931498 },
    { lat: 48.539915, lng: 19.4933773 },
    { lat: 48.5405991, lng: 19.4934526 },
    { lat: 48.5410806, lng: 19.4937014 },
    { lat: 48.5413854, lng: 19.4941238 },
    { lat: 48.54159, lng: 19.4946496 },
    { lat: 48.5417269, lng: 19.4956107 },
    { lat: 48.541992, lng: 19.4965367 },
    { lat: 48.5422965, lng: 19.4971252 },
    { lat: 48.5425278, lng: 19.4973257 },
    { lat: 48.5434934, lng: 19.4975953 },
    { lat: 48.5436855, lng: 19.4976489 },
    { lat: 48.543873, lng: 19.4976328 },
    { lat: 48.5441407, lng: 19.4972344 },
    { lat: 48.5446641, lng: 19.4966132 },
    { lat: 48.5449763, lng: 19.4963229 },
    { lat: 48.5456949, lng: 19.496119 },
    { lat: 48.5467112, lng: 19.4966285 },
    { lat: 48.5480078, lng: 19.4976602 },
    { lat: 48.5486281, lng: 19.4977711 },
    { lat: 48.5487865, lng: 19.4982688 },
    { lat: 48.54847, lng: 19.4985634 },
    { lat: 48.5499356, lng: 19.5003027 },
    { lat: 48.5503707, lng: 19.5013767 },
    { lat: 48.5507792, lng: 19.5028245 },
    { lat: 48.5513873, lng: 19.504107 },
    { lat: 48.5515907, lng: 19.5043891 },
    { lat: 48.5519074, lng: 19.5043384 },
    { lat: 48.5526536, lng: 19.50444 },
    { lat: 48.5528882, lng: 19.5039067 },
    { lat: 48.5531008, lng: 19.5035863 },
    { lat: 48.5534766, lng: 19.5033537 },
    { lat: 48.5542634, lng: 19.5030868 },
    { lat: 48.5545473, lng: 19.5027212 },
    { lat: 48.5550935, lng: 19.5022263 },
    { lat: 48.5558108, lng: 19.502045 },
    { lat: 48.5561333, lng: 19.5020485 },
    { lat: 48.5563252, lng: 19.5021528 },
    { lat: 48.5566997, lng: 19.5029886 },
    { lat: 48.5567405, lng: 19.5034774 },
    { lat: 48.5566965, lng: 19.5048708 },
    { lat: 48.5567785, lng: 19.5053968 },
    { lat: 48.5568479, lng: 19.5056069 },
    { lat: 48.5573747, lng: 19.5065188 },
    { lat: 48.5574883, lng: 19.5069783 },
    { lat: 48.5574565, lng: 19.508681 },
    { lat: 48.5573839, lng: 19.5094506 },
    { lat: 48.5576604, lng: 19.5094554 },
    { lat: 48.5584184, lng: 19.5075495 },
    { lat: 48.5589409, lng: 19.5065868 },
    { lat: 48.5592041, lng: 19.5058943 },
    { lat: 48.5592613, lng: 19.5056197 },
    { lat: 48.559234, lng: 19.5053016 },
    { lat: 48.5595462, lng: 19.503616 },
    { lat: 48.5592352, lng: 19.5022079 },
    { lat: 48.5591798, lng: 19.500986 },
    { lat: 48.558562, lng: 19.4990566 },
    { lat: 48.55828, lng: 19.4979813 },
    { lat: 48.5580003, lng: 19.4965923 },
    { lat: 48.5573743, lng: 19.4964088 },
    { lat: 48.5569035, lng: 19.4963395 },
    { lat: 48.5567011, lng: 19.4963753 },
    { lat: 48.5565189, lng: 19.496091 },
    { lat: 48.5562378, lng: 19.4961757 },
    { lat: 48.5560291, lng: 19.495806 },
    { lat: 48.555903, lng: 19.4958349 },
    { lat: 48.5557409, lng: 19.4957684 },
    { lat: 48.5553131, lng: 19.4949959 },
    { lat: 48.555521, lng: 19.4939367 },
    { lat: 48.5557638, lng: 19.4932709 },
    { lat: 48.5565088, lng: 19.4916909 },
    { lat: 48.5575742, lng: 19.4898556 },
    { lat: 48.5577788, lng: 19.4894043 },
    { lat: 48.5584305, lng: 19.4882478 },
    { lat: 48.5584955, lng: 19.4882132 },
    { lat: 48.5586682, lng: 19.4889734 },
    { lat: 48.5588232, lng: 19.4893253 },
    { lat: 48.5592392, lng: 19.489792 },
    { lat: 48.5599653, lng: 19.4884159 },
    { lat: 48.5619601, lng: 19.4857467 },
    { lat: 48.5624426, lng: 19.4849733 },
    { lat: 48.562486, lng: 19.4848157 },
    { lat: 48.5622857, lng: 19.4846266 },
    { lat: 48.5622646, lng: 19.4843655 },
    { lat: 48.5624453, lng: 19.4832105 },
    { lat: 48.5624115, lng: 19.4825078 },
    { lat: 48.5623245, lng: 19.4819178 },
    { lat: 48.5620009, lng: 19.481092 },
    { lat: 48.5619791, lng: 19.4808869 },
    { lat: 48.5620338, lng: 19.480662 },
    { lat: 48.5619349, lng: 19.4800317 },
    { lat: 48.5623934, lng: 19.4793742 },
    { lat: 48.5618962, lng: 19.4787414 },
    { lat: 48.5624275, lng: 19.4772014 },
    { lat: 48.5619976, lng: 19.4767789 },
    { lat: 48.5613009, lng: 19.4759705 },
    { lat: 48.5613135, lng: 19.4759254 },
    { lat: 48.5610842, lng: 19.4757479 },
    { lat: 48.5605049, lng: 19.4750672 },
    { lat: 48.5604314, lng: 19.4749195 },
    { lat: 48.5597338, lng: 19.4741888 },
    { lat: 48.5600819, lng: 19.4737518 },
    { lat: 48.5600811, lng: 19.4736391 },
    { lat: 48.5592411, lng: 19.4727559 },
    { lat: 48.5593024, lng: 19.4726206 },
    { lat: 48.5591584, lng: 19.4725309 },
    { lat: 48.5590931, lng: 19.4723855 },
    { lat: 48.5587419, lng: 19.472973 },
    { lat: 48.5580574, lng: 19.4727994 },
    { lat: 48.5576335, lng: 19.4725038 },
    { lat: 48.5568583, lng: 19.4710307 },
    { lat: 48.5566089, lng: 19.4709225 },
    { lat: 48.5565689, lng: 19.4706479 },
    { lat: 48.5559176, lng: 19.4700692 },
    { lat: 48.5556856, lng: 19.4699963 },
    { lat: 48.5555244, lng: 19.4696088 },
    { lat: 48.5552598, lng: 19.4694682 },
    { lat: 48.5549867, lng: 19.468903 },
    { lat: 48.5548704, lng: 19.4687804 },
    { lat: 48.5544362, lng: 19.4684571 },
    { lat: 48.5538451, lng: 19.4678437 },
    { lat: 48.5535145, lng: 19.4676157 },
    { lat: 48.5529312, lng: 19.4673569 },
    { lat: 48.5528484, lng: 19.4673496 },
    { lat: 48.5526717, lng: 19.467474 },
    { lat: 48.5526191, lng: 19.467359 },
    { lat: 48.5527263, lng: 19.466877 },
    { lat: 48.5525912, lng: 19.4664964 },
    { lat: 48.5524219, lng: 19.4662739 },
    { lat: 48.5522705, lng: 19.4661935 },
    { lat: 48.5522145, lng: 19.4662485 },
    { lat: 48.5519419, lng: 19.4660329 },
    { lat: 48.5515632, lng: 19.4659346 },
    { lat: 48.5513619, lng: 19.4657243 },
    { lat: 48.5512891, lng: 19.4656164 },
    { lat: 48.5514715, lng: 19.4653197 },
    { lat: 48.5512114, lng: 19.464602 },
    { lat: 48.5510674, lng: 19.4644867 },
    { lat: 48.5511809, lng: 19.4640645 },
    { lat: 48.5513341, lng: 19.4640426 },
    { lat: 48.5513888, lng: 19.463743 },
    { lat: 48.5513529, lng: 19.463358 },
    { lat: 48.5516723, lng: 19.4632937 },
    { lat: 48.5520917, lng: 19.4633695 },
    { lat: 48.5524812, lng: 19.4636751 },
    { lat: 48.5527858, lng: 19.4630514 },
    { lat: 48.5524429, lng: 19.4621307 },
    { lat: 48.5529929, lng: 19.46138 },
    { lat: 48.5538924, lng: 19.460515 },
    { lat: 48.5538626, lng: 19.4604075 },
    { lat: 48.5540241, lng: 19.4602954 },
    { lat: 48.5548195, lng: 19.4601945 },
    { lat: 48.5552883, lng: 19.4603878 },
    { lat: 48.5560954, lng: 19.4603411 },
    { lat: 48.5560524, lng: 19.4601938 },
    { lat: 48.5561319, lng: 19.4598666 },
    { lat: 48.5565226, lng: 19.4595698 },
    { lat: 48.5569488, lng: 19.4594283 },
    { lat: 48.5570351, lng: 19.459613 },
    { lat: 48.5573816, lng: 19.4595266 },
    { lat: 48.5577714, lng: 19.4595966 },
    { lat: 48.5580314, lng: 19.4594698 },
    { lat: 48.5590257, lng: 19.4593041 },
    { lat: 48.5593294, lng: 19.4593915 },
    { lat: 48.5593403, lng: 19.4596739 },
    { lat: 48.5594264, lng: 19.4597089 },
    { lat: 48.5595749, lng: 19.4594843 },
    { lat: 48.559716, lng: 19.4587628 },
    { lat: 48.5598864, lng: 19.4590098 },
    { lat: 48.5601996, lng: 19.4590802 },
    { lat: 48.5606072, lng: 19.4593131 },
    { lat: 48.5606588, lng: 19.4594777 },
    { lat: 48.5607474, lng: 19.4595658 },
    { lat: 48.5610617, lng: 19.4596108 },
    { lat: 48.5611059, lng: 19.4598334 },
    { lat: 48.5612905, lng: 19.459853 },
    { lat: 48.5612983, lng: 19.4601155 },
    { lat: 48.5615416, lng: 19.4601883 },
    { lat: 48.5615541, lng: 19.4602634 },
    { lat: 48.5617728, lng: 19.4604559 },
    { lat: 48.5619424, lng: 19.4607357 },
    { lat: 48.562299, lng: 19.4609385 },
    { lat: 48.5626395, lng: 19.4613785 },
    { lat: 48.5629113, lng: 19.460679 },
    { lat: 48.5630153, lng: 19.4609288 },
    { lat: 48.5631555, lng: 19.461084 },
    { lat: 48.5633402, lng: 19.4607667 },
    { lat: 48.5637709, lng: 19.4603859 },
    { lat: 48.5638789, lng: 19.4605239 },
    { lat: 48.5644705, lng: 19.4595828 },
    { lat: 48.5644923, lng: 19.4595382 },
  ],
  Hriňová: [
    { lat: 48.624893, lng: 19.637798 },
    { lat: 48.625278, lng: 19.637969 },
    { lat: 48.625861, lng: 19.638086 },
    { lat: 48.626212, lng: 19.63805 },
    { lat: 48.626789, lng: 19.637872 },
    { lat: 48.627305, lng: 19.637609 },
    { lat: 48.627604, lng: 19.637527 },
    { lat: 48.627813, lng: 19.637407 },
    { lat: 48.628126, lng: 19.637141 },
    { lat: 48.628612, lng: 19.637026 },
    { lat: 48.629349, lng: 19.636925 },
    { lat: 48.629585, lng: 19.636991 },
    { lat: 48.629766, lng: 19.637096 },
    { lat: 48.630026, lng: 19.637124 },
    { lat: 48.630374, lng: 19.637303 },
    { lat: 48.630839, lng: 19.637746 },
    { lat: 48.631118, lng: 19.63811 },
    { lat: 48.63165, lng: 19.638655 },
    { lat: 48.631947, lng: 19.639091 },
    { lat: 48.632076, lng: 19.63929 },
    { lat: 48.632127, lng: 19.639826 },
    { lat: 48.632237, lng: 19.640231 },
    { lat: 48.632272, lng: 19.640365 },
    { lat: 48.6325362, lng: 19.641165 },
    { lat: 48.6325794, lng: 19.6414869 },
    { lat: 48.6325991, lng: 19.6419031 },
    { lat: 48.632685, lng: 19.642299 },
    { lat: 48.6328, lng: 19.642857 },
    { lat: 48.633423, lng: 19.644423 },
    { lat: 48.634026, lng: 19.645485 },
    { lat: 48.634754, lng: 19.645978 },
    { lat: 48.636541, lng: 19.646735 },
    { lat: 48.637566, lng: 19.646793 },
    { lat: 48.637953, lng: 19.646833 },
    { lat: 48.638209, lng: 19.646806 },
    { lat: 48.638376, lng: 19.646812 },
    { lat: 48.639229, lng: 19.646881 },
    { lat: 48.639367, lng: 19.646865 },
    { lat: 48.6394, lng: 19.646872 },
    { lat: 48.639766, lng: 19.646903 },
    { lat: 48.639971, lng: 19.646896 },
    { lat: 48.640157, lng: 19.646844 },
    { lat: 48.640251, lng: 19.646825 },
    { lat: 48.640412, lng: 19.646758 },
    { lat: 48.640412, lng: 19.646776 },
    { lat: 48.640664, lng: 19.646702 },
    { lat: 48.640794, lng: 19.646687 },
    { lat: 48.640875, lng: 19.646685 },
    { lat: 48.640914, lng: 19.646638 },
    { lat: 48.641021, lng: 19.646651 },
    { lat: 48.64113, lng: 19.646662 },
    { lat: 48.64117, lng: 19.646669 },
    { lat: 48.641218, lng: 19.646664 },
    { lat: 48.641321, lng: 19.646635 },
    { lat: 48.641481, lng: 19.646593 },
    { lat: 48.641572, lng: 19.646349 },
    { lat: 48.641601, lng: 19.64636 },
    { lat: 48.641714, lng: 19.646175 },
    { lat: 48.641807, lng: 19.646038 },
    { lat: 48.641929, lng: 19.645919 },
    { lat: 48.642163, lng: 19.64578 },
    { lat: 48.642269, lng: 19.645573 },
    { lat: 48.642306, lng: 19.645375 },
    { lat: 48.642362, lng: 19.645395 },
    { lat: 48.642525, lng: 19.644935 },
    { lat: 48.642578, lng: 19.644801 },
    { lat: 48.642656, lng: 19.644662 },
    { lat: 48.642681, lng: 19.644613 },
    { lat: 48.642809, lng: 19.644465 },
    { lat: 48.642836, lng: 19.644434 },
    { lat: 48.64297, lng: 19.644281 },
    { lat: 48.643012, lng: 19.644215 },
    { lat: 48.642645, lng: 19.643214 },
    { lat: 48.642562, lng: 19.642677 },
    { lat: 48.642406, lng: 19.641381 },
    { lat: 48.642413, lng: 19.641101 },
    { lat: 48.642719, lng: 19.638731 },
    { lat: 48.642659, lng: 19.638086 },
    { lat: 48.641579, lng: 19.634608 },
    { lat: 48.641827, lng: 19.633768 },
    { lat: 48.642405, lng: 19.632886 },
    { lat: 48.642364, lng: 19.632093 },
    { lat: 48.641877, lng: 19.630341 },
    { lat: 48.641858, lng: 19.629525 },
    { lat: 48.642042, lng: 19.628976 },
    { lat: 48.642089, lng: 19.628629 },
    { lat: 48.642151, lng: 19.628307 },
    { lat: 48.642254, lng: 19.627902 },
    { lat: 48.642359, lng: 19.627569 },
    { lat: 48.642394, lng: 19.627473 },
    { lat: 48.642598, lng: 19.626915 },
    { lat: 48.642873, lng: 19.626144 },
    { lat: 48.64296, lng: 19.625935 },
    { lat: 48.64293, lng: 19.623521 },
    { lat: 48.64328, lng: 19.622716 },
    { lat: 48.643741, lng: 19.622063 },
    { lat: 48.644476, lng: 19.621404 },
    { lat: 48.644727, lng: 19.621156 },
    { lat: 48.644781, lng: 19.621095 },
    { lat: 48.644921, lng: 19.620939 },
    { lat: 48.644984, lng: 19.620868 },
    { lat: 48.645033, lng: 19.620772 },
    { lat: 48.646037, lng: 19.618942 },
    { lat: 48.646013, lng: 19.618311 },
    { lat: 48.645822, lng: 19.617181 },
    { lat: 48.645541, lng: 19.616419 },
    { lat: 48.645237, lng: 19.615778 },
    { lat: 48.645145, lng: 19.614685 },
    { lat: 48.645285, lng: 19.614139 },
    { lat: 48.645411, lng: 19.613242 },
    { lat: 48.645201, lng: 19.610095 },
    { lat: 48.645249, lng: 19.608978 },
    { lat: 48.645806, lng: 19.607574 },
    { lat: 48.646531, lng: 19.606632 },
    { lat: 48.64704, lng: 19.606012 },
    { lat: 48.647259, lng: 19.604486 },
    { lat: 48.647556, lng: 19.603556 },
    { lat: 48.648127, lng: 19.604129 },
    { lat: 48.649416, lng: 19.603478 },
    { lat: 48.649972, lng: 19.602725 },
    { lat: 48.650648, lng: 19.601654 },
    { lat: 48.651286, lng: 19.599749 },
    { lat: 48.651446, lng: 19.599115 },
    { lat: 48.651606, lng: 19.598629 },
    { lat: 48.652053, lng: 19.596638 },
    { lat: 48.652907, lng: 19.594301 },
    { lat: 48.653573, lng: 19.593118 },
    { lat: 48.652862, lng: 19.591915 },
    { lat: 48.652148, lng: 19.590297 },
    { lat: 48.652501, lng: 19.589112 },
    { lat: 48.65283, lng: 19.587766 },
    { lat: 48.65329, lng: 19.586889 },
    { lat: 48.653368, lng: 19.58641 },
    { lat: 48.65327, lng: 19.584485 },
    { lat: 48.653037, lng: 19.583508 },
    { lat: 48.653541, lng: 19.581062 },
    { lat: 48.654347, lng: 19.578254 },
    { lat: 48.654605, lng: 19.573581 },
    { lat: 48.654582, lng: 19.572172 },
    { lat: 48.655074, lng: 19.569885 },
    { lat: 48.655562, lng: 19.568977 },
    { lat: 48.656087, lng: 19.568407 },
    { lat: 48.657082, lng: 19.567837 },
    { lat: 48.657771, lng: 19.566876 },
    { lat: 48.658398, lng: 19.566519 },
    { lat: 48.658589, lng: 19.565672 },
    { lat: 48.658793, lng: 19.564388 },
    { lat: 48.658872, lng: 19.563644 },
    { lat: 48.658647, lng: 19.562561 },
    { lat: 48.658192, lng: 19.561413 },
    { lat: 48.657892, lng: 19.560208 },
    { lat: 48.656803, lng: 19.55834 },
    { lat: 48.655981, lng: 19.556746 },
    { lat: 48.65556, lng: 19.556391 },
    { lat: 48.655243, lng: 19.556308 },
    { lat: 48.655542, lng: 19.556095 },
    { lat: 48.656403, lng: 19.555189 },
    { lat: 48.657374, lng: 19.55396 },
    { lat: 48.657627, lng: 19.553123 },
    { lat: 48.657681, lng: 19.552258 },
    { lat: 48.657728, lng: 19.551892 },
    { lat: 48.657792, lng: 19.551077 },
    { lat: 48.65792, lng: 19.550559 },
    { lat: 48.658145, lng: 19.548269 },
    { lat: 48.658272, lng: 19.546916 },
    { lat: 48.658313, lng: 19.546512 },
    { lat: 48.65844, lng: 19.54565 },
    { lat: 48.658537, lng: 19.544501 },
    { lat: 48.658663, lng: 19.543866 },
    { lat: 48.658872, lng: 19.54305 },
    { lat: 48.659107, lng: 19.541956 },
    { lat: 48.659567, lng: 19.541238 },
    { lat: 48.660308, lng: 19.540611 },
    { lat: 48.660798, lng: 19.540168 },
    { lat: 48.660985, lng: 19.53991 },
    { lat: 48.661485, lng: 19.538804 },
    { lat: 48.661465, lng: 19.537365 },
    { lat: 48.661465, lng: 19.536056 },
    { lat: 48.661431, lng: 19.53449 },
    { lat: 48.66142, lng: 19.53388 },
    { lat: 48.66138, lng: 19.533132 },
    { lat: 48.661141, lng: 19.532216 },
    { lat: 48.660793, lng: 19.531174 },
    { lat: 48.660753, lng: 19.530511 },
    { lat: 48.660669, lng: 19.528907 },
    { lat: 48.660682, lng: 19.528543 },
    { lat: 48.660811, lng: 19.527372 },
    { lat: 48.660854, lng: 19.526339 },
    { lat: 48.660904, lng: 19.525407 },
    { lat: 48.661021, lng: 19.523846 },
    { lat: 48.660947, lng: 19.523024 },
    { lat: 48.661196, lng: 19.521287 },
    { lat: 48.660879, lng: 19.519211 },
    { lat: 48.660611, lng: 19.518532 },
    { lat: 48.660165, lng: 19.517515 },
    { lat: 48.659655, lng: 19.51659 },
    { lat: 48.659143, lng: 19.516003 },
    { lat: 48.658531, lng: 19.515903 },
    { lat: 48.658402, lng: 19.515517 },
    { lat: 48.658521, lng: 19.514617 },
    { lat: 48.658417, lng: 19.513471 },
    { lat: 48.658623, lng: 19.512447 },
    { lat: 48.658826, lng: 19.511056 },
    { lat: 48.658976, lng: 19.510422 },
    { lat: 48.659146, lng: 19.509286 },
    { lat: 48.659091, lng: 19.50896 },
    { lat: 48.658745, lng: 19.50714 },
    { lat: 48.658402, lng: 19.506564 },
    { lat: 48.658342, lng: 19.505645 },
    { lat: 48.657655, lng: 19.504461 },
    { lat: 48.657251, lng: 19.503947 },
    { lat: 48.65709, lng: 19.502955 },
    { lat: 48.656811, lng: 19.501559 },
    { lat: 48.656749, lng: 19.500563 },
    { lat: 48.656712, lng: 19.499798 },
    { lat: 48.655867, lng: 19.4986 },
    { lat: 48.655317, lng: 19.498495 },
    { lat: 48.655155, lng: 19.498173 },
    { lat: 48.654754, lng: 19.497375 },
    { lat: 48.654576, lng: 19.496437 },
    { lat: 48.654471, lng: 19.49523 },
    { lat: 48.654299, lng: 19.494255 },
    { lat: 48.654331, lng: 19.493537 },
    { lat: 48.654474, lng: 19.492971 },
    { lat: 48.654714, lng: 19.492175 },
    { lat: 48.654855, lng: 19.491237 },
    { lat: 48.654916, lng: 19.490255 },
    { lat: 48.654955, lng: 19.489949 },
    { lat: 48.655073, lng: 19.489029 },
    { lat: 48.655313, lng: 19.487821 },
    { lat: 48.655362, lng: 19.486798 },
    { lat: 48.655336, lng: 19.486546 },
    { lat: 48.655175, lng: 19.486087 },
    { lat: 48.655023, lng: 19.485654 },
    { lat: 48.654755, lng: 19.484491 },
    { lat: 48.653933, lng: 19.483299 },
    { lat: 48.653186, lng: 19.482246 },
    { lat: 48.652753, lng: 19.481211 },
    { lat: 48.65261, lng: 19.480867 },
    { lat: 48.652495, lng: 19.479751 },
    { lat: 48.652102, lng: 19.478711 },
    { lat: 48.652017, lng: 19.478487 },
    { lat: 48.65166, lng: 19.477488 },
    { lat: 48.651307, lng: 19.476971 },
    { lat: 48.650724, lng: 19.476119 },
    { lat: 48.650454, lng: 19.475646 },
    { lat: 48.650202, lng: 19.475203 },
    { lat: 48.649591, lng: 19.473755 },
    { lat: 48.649461, lng: 19.472998 },
    { lat: 48.649392, lng: 19.472391 },
    { lat: 48.649372, lng: 19.472302 },
    { lat: 48.649195, lng: 19.471502 },
    { lat: 48.648999, lng: 19.471171 },
    { lat: 48.64899, lng: 19.471156 },
    { lat: 48.648344, lng: 19.470203 },
    { lat: 48.647459, lng: 19.469 },
    { lat: 48.647234, lng: 19.468744 },
    { lat: 48.646993, lng: 19.468818 },
    { lat: 48.646764, lng: 19.468889 },
    { lat: 48.646106, lng: 19.468628 },
    { lat: 48.645518, lng: 19.468223 },
    { lat: 48.645319, lng: 19.468089 },
    { lat: 48.644932, lng: 19.466919 },
    { lat: 48.644864, lng: 19.466381 },
    { lat: 48.644796, lng: 19.465271 },
    { lat: 48.64486, lng: 19.464779 },
    { lat: 48.644658, lng: 19.46447 },
    { lat: 48.644521, lng: 19.463503 },
    { lat: 48.644377, lng: 19.462485 },
    { lat: 48.643648, lng: 19.461312 },
    { lat: 48.643171, lng: 19.460649 },
    { lat: 48.643039, lng: 19.460275 },
    { lat: 48.64287, lng: 19.459796 },
    { lat: 48.642428, lng: 19.459194 },
    { lat: 48.642379, lng: 19.459127 },
    { lat: 48.642369, lng: 19.459114 },
    { lat: 48.641993, lng: 19.45873 },
    { lat: 48.641246, lng: 19.458093 },
    { lat: 48.640724, lng: 19.457228 },
    { lat: 48.640604, lng: 19.456936 },
    { lat: 48.640373, lng: 19.456376 },
    { lat: 48.639407, lng: 19.455535 },
    { lat: 48.638485, lng: 19.454577 },
    { lat: 48.638308, lng: 19.454393 },
    { lat: 48.637399, lng: 19.453107 },
    { lat: 48.636686, lng: 19.452141 },
    { lat: 48.636561, lng: 19.451857 },
    { lat: 48.636316, lng: 19.451311 },
    { lat: 48.636102, lng: 19.450093 },
    { lat: 48.635979, lng: 19.449326 },
    { lat: 48.635642, lng: 19.448817 },
    { lat: 48.635629, lng: 19.448797 },
    { lat: 48.635228, lng: 19.448332 },
    { lat: 48.634916, lng: 19.448243 },
    { lat: 48.634711, lng: 19.448185 },
    { lat: 48.634046, lng: 19.448303 },
    { lat: 48.633286, lng: 19.448196 },
    { lat: 48.632396, lng: 19.448216 },
    { lat: 48.632208, lng: 19.448502 },
    { lat: 48.632152, lng: 19.448586 },
    { lat: 48.63189, lng: 19.448845 },
    { lat: 48.631407, lng: 19.448149 },
    { lat: 48.630739, lng: 19.44772 },
    { lat: 48.630646, lng: 19.447504 },
    { lat: 48.629885, lng: 19.445744 },
    { lat: 48.629794, lng: 19.445567 },
    { lat: 48.629274, lng: 19.44457 },
    { lat: 48.629073, lng: 19.444459 },
    { lat: 48.628615, lng: 19.444068 },
    { lat: 48.628248, lng: 19.443696 },
    { lat: 48.62819, lng: 19.443665 },
    { lat: 48.627609, lng: 19.442743 },
    { lat: 48.6269453, lng: 19.4435348 },
    { lat: 48.6263951, lng: 19.4442823 },
    { lat: 48.6263171, lng: 19.4443882 },
    { lat: 48.6257883, lng: 19.4452766 },
    { lat: 48.6247138, lng: 19.4463025 },
    { lat: 48.6236362, lng: 19.4476018 },
    { lat: 48.6221804, lng: 19.4492231 },
    { lat: 48.6217355, lng: 19.4499304 },
    { lat: 48.6207703, lng: 19.4510292 },
    { lat: 48.6204982, lng: 19.4514531 },
    { lat: 48.6190855, lng: 19.4532292 },
    { lat: 48.6181478, lng: 19.4545312 },
    { lat: 48.6170984, lng: 19.4555914 },
    { lat: 48.6154817, lng: 19.4575819 },
    { lat: 48.614716, lng: 19.4584395 },
    { lat: 48.6142961, lng: 19.4591283 },
    { lat: 48.6133163, lng: 19.460208 },
    { lat: 48.6135217, lng: 19.4604039 },
    { lat: 48.6139954, lng: 19.4606062 },
    { lat: 48.6139472, lng: 19.461579 },
    { lat: 48.6142138, lng: 19.4619795 },
    { lat: 48.6143595, lng: 19.4624267 },
    { lat: 48.6142442, lng: 19.462586 },
    { lat: 48.6137577, lng: 19.4626882 },
    { lat: 48.6136909, lng: 19.4628299 },
    { lat: 48.6138418, lng: 19.4632872 },
    { lat: 48.6137615, lng: 19.4638855 },
    { lat: 48.6135678, lng: 19.4643961 },
    { lat: 48.6138554, lng: 19.4646894 },
    { lat: 48.6143601, lng: 19.4655826 },
    { lat: 48.6141702, lng: 19.4658862 },
    { lat: 48.6139981, lng: 19.4656501 },
    { lat: 48.6138323, lng: 19.465964 },
    { lat: 48.6139749, lng: 19.4662862 },
    { lat: 48.6139901, lng: 19.4665435 },
    { lat: 48.6131788, lng: 19.4669556 },
    { lat: 48.6129915, lng: 19.4667575 },
    { lat: 48.612648, lng: 19.4662318 },
    { lat: 48.6125056, lng: 19.4664406 },
    { lat: 48.6121619, lng: 19.4666359 },
    { lat: 48.612118, lng: 19.4668802 },
    { lat: 48.6118605, lng: 19.4671058 },
    { lat: 48.6119848, lng: 19.4674278 },
    { lat: 48.611948, lng: 19.4676073 },
    { lat: 48.6117395, lng: 19.4677287 },
    { lat: 48.6115548, lng: 19.4677326 },
    { lat: 48.6111019, lng: 19.4681192 },
    { lat: 48.6102999, lng: 19.4681549 },
    { lat: 48.6097729, lng: 19.468065 },
    { lat: 48.6097055, lng: 19.4682663 },
    { lat: 48.6097429, lng: 19.4688253 },
    { lat: 48.6096406, lng: 19.4689522 },
    { lat: 48.6092062, lng: 19.4690623 },
    { lat: 48.6087651, lng: 19.4694167 },
    { lat: 48.6086216, lng: 19.4694336 },
    { lat: 48.6083209, lng: 19.4696314 },
    { lat: 48.6080236, lng: 19.4695151 },
    { lat: 48.6078945, lng: 19.4692577 },
    { lat: 48.6078092, lng: 19.4688804 },
    { lat: 48.6077206, lng: 19.4687177 },
    { lat: 48.6078843, lng: 19.4685042 },
    { lat: 48.6078337, lng: 19.4682649 },
    { lat: 48.6077129, lng: 19.4680893 },
    { lat: 48.6075171, lng: 19.4680484 },
    { lat: 48.6075178, lng: 19.4681858 },
    { lat: 48.6072025, lng: 19.4682391 },
    { lat: 48.6071464, lng: 19.4681713 },
    { lat: 48.6070972, lng: 19.468059 },
    { lat: 48.6073521, lng: 19.4674566 },
    { lat: 48.6072928, lng: 19.4672991 },
    { lat: 48.6071322, lng: 19.4671409 },
    { lat: 48.6070303, lng: 19.4670833 },
    { lat: 48.6067604, lng: 19.4672144 },
    { lat: 48.6066487, lng: 19.4673558 },
    { lat: 48.6065988, lng: 19.4672782 },
    { lat: 48.6057875, lng: 19.4672565 },
    { lat: 48.6057373, lng: 19.467391 },
    { lat: 48.6055097, lng: 19.4672702 },
    { lat: 48.605405, lng: 19.467125 },
    { lat: 48.6052936, lng: 19.4671271 },
    { lat: 48.6051894, lng: 19.4668024 },
    { lat: 48.6051595, lng: 19.4661835 },
    { lat: 48.6051908, lng: 19.4659394 },
    { lat: 48.6051218, lng: 19.4654251 },
    { lat: 48.6052902, lng: 19.4651665 },
    { lat: 48.6051663, lng: 19.4647067 },
    { lat: 48.605134, lng: 19.4643801 },
    { lat: 48.6048663, lng: 19.4644885 },
    { lat: 48.6048203, lng: 19.4638592 },
    { lat: 48.6049858, lng: 19.4636156 },
    { lat: 48.6050646, lng: 19.4632743 },
    { lat: 48.6052775, lng: 19.4634198 },
    { lat: 48.6055402, lng: 19.4631769 },
    { lat: 48.604747, lng: 19.46224 },
    { lat: 48.6044389, lng: 19.4619896 },
    { lat: 48.6040938, lng: 19.461232 },
    { lat: 48.6036907, lng: 19.4609992 },
    { lat: 48.6035124, lng: 19.4608279 },
    { lat: 48.6006742, lng: 19.4644439 },
    { lat: 48.6005429, lng: 19.4640886 },
    { lat: 48.6003709, lng: 19.4639305 },
    { lat: 48.6002521, lng: 19.4636821 },
    { lat: 48.6001733, lng: 19.4633444 },
    { lat: 48.5998795, lng: 19.4631544 },
    { lat: 48.5998016, lng: 19.4629575 },
    { lat: 48.5997185, lng: 19.4628919 },
    { lat: 48.5993783, lng: 19.462317 },
    { lat: 48.5988592, lng: 19.4619362 },
    { lat: 48.598557, lng: 19.4615936 },
    { lat: 48.598379, lng: 19.4614786 },
    { lat: 48.5983228, lng: 19.4613368 },
    { lat: 48.5981473, lng: 19.4612197 },
    { lat: 48.5979066, lng: 19.4609387 },
    { lat: 48.597742, lng: 19.4608685 },
    { lat: 48.5973801, lng: 19.4604038 },
    { lat: 48.597287, lng: 19.4604137 },
    { lat: 48.596631, lng: 19.4599744 },
    { lat: 48.5964744, lng: 19.4596125 },
    { lat: 48.5962241, lng: 19.4593902 },
    { lat: 48.5955886, lng: 19.4590809 },
    { lat: 48.5950503, lng: 19.4586284 },
    { lat: 48.5939866, lng: 19.4580551 },
    { lat: 48.5938925, lng: 19.4580427 },
    { lat: 48.5938225, lng: 19.4582794 },
    { lat: 48.5928369, lng: 19.458413 },
    { lat: 48.5921644, lng: 19.4585858 },
    { lat: 48.5907049, lng: 19.4586806 },
    { lat: 48.5904602, lng: 19.4588984 },
    { lat: 48.5903086, lng: 19.4592922 },
    { lat: 48.5897435, lng: 19.4598401 },
    { lat: 48.589687, lng: 19.4602754 },
    { lat: 48.5904369, lng: 19.4607435 },
    { lat: 48.590967, lng: 19.46097 },
    { lat: 48.5911447, lng: 19.4613225 },
    { lat: 48.5911344, lng: 19.4617087 },
    { lat: 48.5911805, lng: 19.4619936 },
    { lat: 48.5915238, lng: 19.4633902 },
    { lat: 48.591829, lng: 19.4639915 },
    { lat: 48.5918765, lng: 19.4642595 },
    { lat: 48.5918082, lng: 19.4644373 },
    { lat: 48.591303, lng: 19.4642626 },
    { lat: 48.590987, lng: 19.4643736 },
    { lat: 48.590662, lng: 19.4643376 },
    { lat: 48.5904548, lng: 19.4644862 },
    { lat: 48.5896894, lng: 19.4642878 },
    { lat: 48.5892676, lng: 19.464257 },
    { lat: 48.5883843, lng: 19.4638767 },
    { lat: 48.5881455, lng: 19.4639507 },
    { lat: 48.5879154, lng: 19.4636558 },
    { lat: 48.5877594, lng: 19.463211 },
    { lat: 48.5877722, lng: 19.4627857 },
    { lat: 48.5878648, lng: 19.4621373 },
    { lat: 48.5878395, lng: 19.4612055 },
    { lat: 48.5874443, lng: 19.4601745 },
    { lat: 48.5872476, lng: 19.4594855 },
    { lat: 48.5868296, lng: 19.4601908 },
    { lat: 48.5864504, lng: 19.4612409 },
    { lat: 48.5863103, lng: 19.4618616 },
    { lat: 48.5855569, lng: 19.4617295 },
    { lat: 48.5852497, lng: 19.4618354 },
    { lat: 48.5849189, lng: 19.4618462 },
    { lat: 48.5845098, lng: 19.4616012 },
    { lat: 48.5840322, lng: 19.4614372 },
    { lat: 48.5835137, lng: 19.4613636 },
    { lat: 48.5834083, lng: 19.4603842 },
    { lat: 48.582818, lng: 19.4601947 },
    { lat: 48.5821015, lng: 19.4602745 },
    { lat: 48.5818491, lng: 19.4601795 },
    { lat: 48.5816249, lng: 19.4599855 },
    { lat: 48.5812125, lng: 19.4592657 },
    { lat: 48.580915, lng: 19.4589278 },
    { lat: 48.5807023, lng: 19.4587157 },
    { lat: 48.5802047, lng: 19.4583859 },
    { lat: 48.5802259, lng: 19.4590577 },
    { lat: 48.5801266, lng: 19.4593195 },
    { lat: 48.5800231, lng: 19.4592276 },
    { lat: 48.5794082, lng: 19.4589949 },
    { lat: 48.5793213, lng: 19.4592845 },
    { lat: 48.579263, lng: 19.4593138 },
    { lat: 48.5791081, lng: 19.4592277 },
    { lat: 48.5785567, lng: 19.4591534 },
    { lat: 48.5784882, lng: 19.4590621 },
    { lat: 48.578381, lng: 19.4590567 },
    { lat: 48.5780665, lng: 19.4585014 },
    { lat: 48.5777919, lng: 19.4582653 },
    { lat: 48.5777808, lng: 19.4581732 },
    { lat: 48.5776489, lng: 19.4580993 },
    { lat: 48.5776207, lng: 19.4580016 },
    { lat: 48.5774451, lng: 19.457912 },
    { lat: 48.5774671, lng: 19.4578022 },
    { lat: 48.5772191, lng: 19.4576551 },
    { lat: 48.577115, lng: 19.4580797 },
    { lat: 48.5762523, lng: 19.4576085 },
    { lat: 48.5753935, lng: 19.4574201 },
    { lat: 48.5748934, lng: 19.4574457 },
    { lat: 48.5748636, lng: 19.4576279 },
    { lat: 48.5744298, lng: 19.4577735 },
    { lat: 48.5737784, lng: 19.4576567 },
    { lat: 48.5738592, lng: 19.4572122 },
    { lat: 48.5739618, lng: 19.4561259 },
    { lat: 48.5736995, lng: 19.4561316 },
    { lat: 48.5735674, lng: 19.4562464 },
    { lat: 48.5724842, lng: 19.4557612 },
    { lat: 48.5723852, lng: 19.4557667 },
    { lat: 48.5723772, lng: 19.4558839 },
    { lat: 48.5721986, lng: 19.4558267 },
    { lat: 48.5718843, lng: 19.4556799 },
    { lat: 48.5719554, lng: 19.4553913 },
    { lat: 48.5717, lng: 19.4551867 },
    { lat: 48.5713435, lng: 19.4550316 },
    { lat: 48.571315, lng: 19.4552413 },
    { lat: 48.5706739, lng: 19.4553445 },
    { lat: 48.5706315, lng: 19.4555444 },
    { lat: 48.5697795, lng: 19.4554739 },
    { lat: 48.5696441, lng: 19.4553964 },
    { lat: 48.5690151, lng: 19.4554648 },
    { lat: 48.5688397, lng: 19.4556176 },
    { lat: 48.5687854, lng: 19.4557351 },
    { lat: 48.5687868, lng: 19.4564164 },
    { lat: 48.5679372, lng: 19.4562256 },
    { lat: 48.5676867, lng: 19.4564411 },
    { lat: 48.5676347, lng: 19.456531 },
    { lat: 48.5676563, lng: 19.4566333 },
    { lat: 48.5675056, lng: 19.4573623 },
    { lat: 48.5676427, lng: 19.4575019 },
    { lat: 48.5675437, lng: 19.4576216 },
    { lat: 48.5678092, lng: 19.4588232 },
    { lat: 48.5672185, lng: 19.458937 },
    { lat: 48.5661778, lng: 19.459313 },
    { lat: 48.5659117, lng: 19.4594558 },
    { lat: 48.5658696, lng: 19.4596862 },
    { lat: 48.565506, lng: 19.4596991 },
    { lat: 48.5651729, lng: 19.4596607 },
    { lat: 48.5648526, lng: 19.4595327 },
    { lat: 48.5644923, lng: 19.4595382 },
    { lat: 48.5644705, lng: 19.4595828 },
    { lat: 48.5638789, lng: 19.4605239 },
    { lat: 48.5637709, lng: 19.4603859 },
    { lat: 48.5633402, lng: 19.4607667 },
    { lat: 48.5631555, lng: 19.461084 },
    { lat: 48.5630153, lng: 19.4609288 },
    { lat: 48.5629113, lng: 19.460679 },
    { lat: 48.5626395, lng: 19.4613785 },
    { lat: 48.562299, lng: 19.4609385 },
    { lat: 48.5619424, lng: 19.4607357 },
    { lat: 48.5617728, lng: 19.4604559 },
    { lat: 48.5615541, lng: 19.4602634 },
    { lat: 48.5615416, lng: 19.4601883 },
    { lat: 48.5612983, lng: 19.4601155 },
    { lat: 48.5612905, lng: 19.459853 },
    { lat: 48.5611059, lng: 19.4598334 },
    { lat: 48.5610617, lng: 19.4596108 },
    { lat: 48.5607474, lng: 19.4595658 },
    { lat: 48.5606588, lng: 19.4594777 },
    { lat: 48.5606072, lng: 19.4593131 },
    { lat: 48.5601996, lng: 19.4590802 },
    { lat: 48.5598864, lng: 19.4590098 },
    { lat: 48.559716, lng: 19.4587628 },
    { lat: 48.5595749, lng: 19.4594843 },
    { lat: 48.5594264, lng: 19.4597089 },
    { lat: 48.5593403, lng: 19.4596739 },
    { lat: 48.5593294, lng: 19.4593915 },
    { lat: 48.5590257, lng: 19.4593041 },
    { lat: 48.5580314, lng: 19.4594698 },
    { lat: 48.5577714, lng: 19.4595966 },
    { lat: 48.5573816, lng: 19.4595266 },
    { lat: 48.5570351, lng: 19.459613 },
    { lat: 48.5569488, lng: 19.4594283 },
    { lat: 48.5565226, lng: 19.4595698 },
    { lat: 48.5561319, lng: 19.4598666 },
    { lat: 48.5560524, lng: 19.4601938 },
    { lat: 48.5560954, lng: 19.4603411 },
    { lat: 48.5552883, lng: 19.4603878 },
    { lat: 48.5548195, lng: 19.4601945 },
    { lat: 48.5540241, lng: 19.4602954 },
    { lat: 48.5538626, lng: 19.4604075 },
    { lat: 48.5538924, lng: 19.460515 },
    { lat: 48.5529929, lng: 19.46138 },
    { lat: 48.5524429, lng: 19.4621307 },
    { lat: 48.5527858, lng: 19.4630514 },
    { lat: 48.5524812, lng: 19.4636751 },
    { lat: 48.5520917, lng: 19.4633695 },
    { lat: 48.5516723, lng: 19.4632937 },
    { lat: 48.5513529, lng: 19.463358 },
    { lat: 48.5513888, lng: 19.463743 },
    { lat: 48.5513341, lng: 19.4640426 },
    { lat: 48.5511809, lng: 19.4640645 },
    { lat: 48.5510674, lng: 19.4644867 },
    { lat: 48.5512114, lng: 19.464602 },
    { lat: 48.5514715, lng: 19.4653197 },
    { lat: 48.5512891, lng: 19.4656164 },
    { lat: 48.5513619, lng: 19.4657243 },
    { lat: 48.5515632, lng: 19.4659346 },
    { lat: 48.5519419, lng: 19.4660329 },
    { lat: 48.5522145, lng: 19.4662485 },
    { lat: 48.5522705, lng: 19.4661935 },
    { lat: 48.5524219, lng: 19.4662739 },
    { lat: 48.5525912, lng: 19.4664964 },
    { lat: 48.5527263, lng: 19.466877 },
    { lat: 48.5526191, lng: 19.467359 },
    { lat: 48.5526717, lng: 19.467474 },
    { lat: 48.5528484, lng: 19.4673496 },
    { lat: 48.5529312, lng: 19.4673569 },
    { lat: 48.5535145, lng: 19.4676157 },
    { lat: 48.5538451, lng: 19.4678437 },
    { lat: 48.5544362, lng: 19.4684571 },
    { lat: 48.5548704, lng: 19.4687804 },
    { lat: 48.5549867, lng: 19.468903 },
    { lat: 48.5552598, lng: 19.4694682 },
    { lat: 48.5555244, lng: 19.4696088 },
    { lat: 48.5556856, lng: 19.4699963 },
    { lat: 48.5559176, lng: 19.4700692 },
    { lat: 48.5565689, lng: 19.4706479 },
    { lat: 48.5566089, lng: 19.4709225 },
    { lat: 48.5568583, lng: 19.4710307 },
    { lat: 48.5576335, lng: 19.4725038 },
    { lat: 48.5580574, lng: 19.4727994 },
    { lat: 48.5587419, lng: 19.472973 },
    { lat: 48.5590931, lng: 19.4723855 },
    { lat: 48.5591584, lng: 19.4725309 },
    { lat: 48.5593024, lng: 19.4726206 },
    { lat: 48.5592411, lng: 19.4727559 },
    { lat: 48.5600811, lng: 19.4736391 },
    { lat: 48.5600819, lng: 19.4737518 },
    { lat: 48.5597338, lng: 19.4741888 },
    { lat: 48.5604314, lng: 19.4749195 },
    { lat: 48.5605049, lng: 19.4750672 },
    { lat: 48.5610842, lng: 19.4757479 },
    { lat: 48.5613135, lng: 19.4759254 },
    { lat: 48.5613009, lng: 19.4759705 },
    { lat: 48.5619976, lng: 19.4767789 },
    { lat: 48.5624275, lng: 19.4772014 },
    { lat: 48.5618962, lng: 19.4787414 },
    { lat: 48.5623934, lng: 19.4793742 },
    { lat: 48.5619349, lng: 19.4800317 },
    { lat: 48.5620338, lng: 19.480662 },
    { lat: 48.5619791, lng: 19.4808869 },
    { lat: 48.5620009, lng: 19.481092 },
    { lat: 48.5623245, lng: 19.4819178 },
    { lat: 48.5624115, lng: 19.4825078 },
    { lat: 48.5624453, lng: 19.4832105 },
    { lat: 48.5622646, lng: 19.4843655 },
    { lat: 48.5622857, lng: 19.4846266 },
    { lat: 48.562486, lng: 19.4848157 },
    { lat: 48.5624426, lng: 19.4849733 },
    { lat: 48.5619601, lng: 19.4857467 },
    { lat: 48.5599653, lng: 19.4884159 },
    { lat: 48.5592392, lng: 19.489792 },
    { lat: 48.5588232, lng: 19.4893253 },
    { lat: 48.5586682, lng: 19.4889734 },
    { lat: 48.5584955, lng: 19.4882132 },
    { lat: 48.5584305, lng: 19.4882478 },
    { lat: 48.5577788, lng: 19.4894043 },
    { lat: 48.5575742, lng: 19.4898556 },
    { lat: 48.5565088, lng: 19.4916909 },
    { lat: 48.5557638, lng: 19.4932709 },
    { lat: 48.555521, lng: 19.4939367 },
    { lat: 48.5553131, lng: 19.4949959 },
    { lat: 48.5557409, lng: 19.4957684 },
    { lat: 48.555903, lng: 19.4958349 },
    { lat: 48.5560291, lng: 19.495806 },
    { lat: 48.5562378, lng: 19.4961757 },
    { lat: 48.5565189, lng: 19.496091 },
    { lat: 48.5567011, lng: 19.4963753 },
    { lat: 48.5569035, lng: 19.4963395 },
    { lat: 48.5573743, lng: 19.4964088 },
    { lat: 48.5580003, lng: 19.4965923 },
    { lat: 48.55828, lng: 19.4979813 },
    { lat: 48.558562, lng: 19.4990566 },
    { lat: 48.5591798, lng: 19.500986 },
    { lat: 48.5592352, lng: 19.5022079 },
    { lat: 48.5595462, lng: 19.503616 },
    { lat: 48.559234, lng: 19.5053016 },
    { lat: 48.5592613, lng: 19.5056197 },
    { lat: 48.5592041, lng: 19.5058943 },
    { lat: 48.5589409, lng: 19.5065868 },
    { lat: 48.5584184, lng: 19.5075495 },
    { lat: 48.5576604, lng: 19.5094554 },
    { lat: 48.5573839, lng: 19.5094506 },
    { lat: 48.5573568, lng: 19.5096921 },
    { lat: 48.5576332, lng: 19.5105998 },
    { lat: 48.5577601, lng: 19.5112236 },
    { lat: 48.5578868, lng: 19.5122597 },
    { lat: 48.5578372, lng: 19.5133083 },
    { lat: 48.5577715, lng: 19.5136446 },
    { lat: 48.5574698, lng: 19.5136965 },
    { lat: 48.5572848, lng: 19.5136241 },
    { lat: 48.5567128, lng: 19.5136072 },
    { lat: 48.5549563, lng: 19.5145163 },
    { lat: 48.5539687, lng: 19.515778 },
    { lat: 48.5531263, lng: 19.5166221 },
    { lat: 48.5526252, lng: 19.5177011 },
    { lat: 48.5536625, lng: 19.5194226 },
    { lat: 48.5537023, lng: 19.5203572 },
    { lat: 48.5535076, lng: 19.5213895 },
    { lat: 48.5534844, lng: 19.5218896 },
    { lat: 48.5532583, lng: 19.5232457 },
    { lat: 48.5531909, lng: 19.5243483 },
    { lat: 48.553052, lng: 19.5256391 },
    { lat: 48.5521152, lng: 19.5278591 },
    { lat: 48.5522361, lng: 19.5285717 },
    { lat: 48.5527245, lng: 19.5305151 },
    { lat: 48.5530677, lng: 19.5337396 },
    { lat: 48.5530423, lng: 19.5339674 },
    { lat: 48.5532129, lng: 19.5345916 },
    { lat: 48.5537367, lng: 19.5352238 },
    { lat: 48.554151, lng: 19.5354811 },
    { lat: 48.554089, lng: 19.5357519 },
    { lat: 48.5546078, lng: 19.5374051 },
    { lat: 48.5545344, lng: 19.5378418 },
    { lat: 48.5546377, lng: 19.539278 },
    { lat: 48.5549423, lng: 19.5398534 },
    { lat: 48.5549135, lng: 19.5402079 },
    { lat: 48.5543007, lng: 19.5416316 },
    { lat: 48.5538574, lng: 19.5422865 },
    { lat: 48.552405, lng: 19.5450618 },
    { lat: 48.5520868, lng: 19.5461851 },
    { lat: 48.5522294, lng: 19.5480099 },
    { lat: 48.5523344, lng: 19.5484097 },
    { lat: 48.5522922, lng: 19.5486183 },
    { lat: 48.5524478, lng: 19.549677 },
    { lat: 48.5524879, lng: 19.5508957 },
    { lat: 48.5524572, lng: 19.551627 },
    { lat: 48.552219, lng: 19.5545641 },
    { lat: 48.5524184, lng: 19.5552305 },
    { lat: 48.5524885, lng: 19.5562419 },
    { lat: 48.5524743, lng: 19.5566212 },
    { lat: 48.5522446, lng: 19.5570928 },
    { lat: 48.5521888, lng: 19.557746 },
    { lat: 48.5521675, lng: 19.5589771 },
    { lat: 48.5520288, lng: 19.5594432 },
    { lat: 48.5519382, lng: 19.5599769 },
    { lat: 48.551333, lng: 19.562065 },
    { lat: 48.5513383, lng: 19.5624304 },
    { lat: 48.5515752, lng: 19.5636169 },
    { lat: 48.5516121, lng: 19.5640267 },
    { lat: 48.5515971, lng: 19.5645604 },
    { lat: 48.5513238, lng: 19.5661032 },
    { lat: 48.5511778, lng: 19.5663663 },
    { lat: 48.5509306, lng: 19.5667924 },
    { lat: 48.5503337, lng: 19.5673153 },
    { lat: 48.5503323, lng: 19.5673382 },
    { lat: 48.5503138, lng: 19.5680425 },
    { lat: 48.55069, lng: 19.5692427 },
    { lat: 48.5512334, lng: 19.5698162 },
    { lat: 48.5514093, lng: 19.5704837 },
    { lat: 48.551431, lng: 19.570416 },
    { lat: 48.5515935, lng: 19.570349 },
    { lat: 48.5516562, lng: 19.570218 },
    { lat: 48.5516501, lng: 19.5701098 },
    { lat: 48.5517321, lng: 19.5700277 },
    { lat: 48.5521384, lng: 19.5703359 },
    { lat: 48.5526523, lng: 19.5705864 },
    { lat: 48.5537715, lng: 19.5694311 },
    { lat: 48.5545282, lng: 19.5687529 },
    { lat: 48.5545601, lng: 19.5690843 },
    { lat: 48.5551659, lng: 19.5685541 },
    { lat: 48.5558703, lng: 19.5687044 },
    { lat: 48.5561009, lng: 19.5684745 },
    { lat: 48.5562547, lng: 19.5678204 },
    { lat: 48.5564971, lng: 19.5679209 },
    { lat: 48.5567273, lng: 19.5667228 },
    { lat: 48.5571394, lng: 19.5664915 },
    { lat: 48.5577089, lng: 19.5660561 },
    { lat: 48.5583468, lng: 19.5660318 },
    { lat: 48.558458, lng: 19.5650915 },
    { lat: 48.5588422, lng: 19.5650413 },
    { lat: 48.5589187, lng: 19.5645183 },
    { lat: 48.5597327, lng: 19.5639372 },
    { lat: 48.5599438, lng: 19.5636737 },
    { lat: 48.5609521, lng: 19.564061 },
    { lat: 48.5619055, lng: 19.563253 },
    { lat: 48.5619157, lng: 19.5627835 },
    { lat: 48.5623422, lng: 19.5625474 },
    { lat: 48.5631668, lng: 19.5616083 },
    { lat: 48.5635771, lng: 19.5617813 },
    { lat: 48.5637528, lng: 19.5619523 },
    { lat: 48.5642338, lng: 19.5621951 },
    { lat: 48.5644662, lng: 19.5624482 },
    { lat: 48.5647016, lng: 19.5628154 },
    { lat: 48.564956, lng: 19.5630067 },
    { lat: 48.5651673, lng: 19.5633875 },
    { lat: 48.5654029, lng: 19.5636082 },
    { lat: 48.5655166, lng: 19.5639308 },
    { lat: 48.5660935, lng: 19.5648148 },
    { lat: 48.5668241, lng: 19.5650947 },
    { lat: 48.5667876, lng: 19.5658233 },
    { lat: 48.5666178, lng: 19.5668406 },
    { lat: 48.56685, lng: 19.5677327 },
    { lat: 48.5673093, lng: 19.5680945 },
    { lat: 48.5671768, lng: 19.5682011 },
    { lat: 48.5667635, lng: 19.5689246 },
    { lat: 48.566618, lng: 19.5692891 },
    { lat: 48.5662923, lng: 19.5708648 },
    { lat: 48.5659319, lng: 19.5712875 },
    { lat: 48.5657419, lng: 19.5717704 },
    { lat: 48.565643, lng: 19.571775 },
    { lat: 48.5653494, lng: 19.5723778 },
    { lat: 48.5655153, lng: 19.5727018 },
    { lat: 48.5651439, lng: 19.5736753 },
    { lat: 48.5661526, lng: 19.5748181 },
    { lat: 48.566532, lng: 19.575204 },
    { lat: 48.5681472, lng: 19.5741493 },
    { lat: 48.5684963, lng: 19.5741682 },
    { lat: 48.5689915, lng: 19.5742304 },
    { lat: 48.5691355, lng: 19.5755586 },
    { lat: 48.5692304, lng: 19.5756353 },
    { lat: 48.56969, lng: 19.5754624 },
    { lat: 48.5700707, lng: 19.5754315 },
    { lat: 48.5702691, lng: 19.5758781 },
    { lat: 48.5705715, lng: 19.5757148 },
    { lat: 48.5707128, lng: 19.5755009 },
    { lat: 48.571607, lng: 19.5753652 },
    { lat: 48.5717327, lng: 19.5751081 },
    { lat: 48.5717163, lng: 19.5747479 },
    { lat: 48.576369, lng: 19.575808 },
    { lat: 48.5783413, lng: 19.5761641 },
    { lat: 48.5784746, lng: 19.5760941 },
    { lat: 48.5785217, lng: 19.5763596 },
    { lat: 48.578682, lng: 19.5764171 },
    { lat: 48.5788005, lng: 19.5781756 },
    { lat: 48.5790236, lng: 19.578103 },
    { lat: 48.5790375, lng: 19.577988 },
    { lat: 48.5796157, lng: 19.5782858 },
    { lat: 48.5796989, lng: 19.5796402 },
    { lat: 48.5797799, lng: 19.5800798 },
    { lat: 48.5799307, lng: 19.580498 },
    { lat: 48.5800483, lng: 19.5806791 },
    { lat: 48.5801854, lng: 19.5811161 },
    { lat: 48.5802425, lng: 19.5817992 },
    { lat: 48.5804574, lng: 19.5828065 },
    { lat: 48.5802625, lng: 19.5829797 },
    { lat: 48.5838823, lng: 19.58487 },
    { lat: 48.5839151, lng: 19.5846723 },
    { lat: 48.5840321, lng: 19.584768 },
    { lat: 48.5841732, lng: 19.5851709 },
    { lat: 48.5840728, lng: 19.5861088 },
    { lat: 48.5841254, lng: 19.5866681 },
    { lat: 48.5844858, lng: 19.587359 },
    { lat: 48.5849425, lng: 19.5877631 },
    { lat: 48.5845458, lng: 19.5884287 },
    { lat: 48.5843175, lng: 19.5891342 },
    { lat: 48.5839864, lng: 19.5906234 },
    { lat: 48.5839021, lng: 19.5915428 },
    { lat: 48.5838189, lng: 19.5919714 },
    { lat: 48.5832658, lng: 19.5915801 },
    { lat: 48.5828829, lng: 19.5928222 },
    { lat: 48.5831514, lng: 19.593223 },
    { lat: 48.5823644, lng: 19.5947513 },
    { lat: 48.5820517, lng: 19.5947643 },
    { lat: 48.5818234, lng: 19.5952431 },
    { lat: 48.5831083, lng: 19.5955475 },
    { lat: 48.5858128, lng: 19.5963196 },
    { lat: 48.5862666, lng: 19.5965892 },
    { lat: 48.5863409, lng: 19.5966334 },
    { lat: 48.5869402, lng: 19.5971708 },
    { lat: 48.5871926, lng: 19.5973843 },
    { lat: 48.5877565, lng: 19.5978815 },
    { lat: 48.5888156, lng: 19.5979775 },
    { lat: 48.5905385, lng: 19.5983753 },
    { lat: 48.5906897, lng: 19.5980066 },
    { lat: 48.5909382, lng: 19.5975976 },
    { lat: 48.5910586, lng: 19.5977169 },
    { lat: 48.5910973, lng: 19.5978727 },
    { lat: 48.5910047, lng: 19.5978933 },
    { lat: 48.5909847, lng: 19.5981951 },
    { lat: 48.5911562, lng: 19.5982016 },
    { lat: 48.591197, lng: 19.5986807 },
    { lat: 48.5913725, lng: 19.5986235 },
    { lat: 48.5916649, lng: 19.5990841 },
    { lat: 48.5919187, lng: 19.5992124 },
    { lat: 48.592395, lng: 19.5993078 },
    { lat: 48.5929431, lng: 19.599636 },
    { lat: 48.592822, lng: 19.6002697 },
    { lat: 48.5921958, lng: 19.6002563 },
    { lat: 48.5911925, lng: 19.6004883 },
    { lat: 48.5907886, lng: 19.6006781 },
    { lat: 48.5901583, lng: 19.6017856 },
    { lat: 48.5900813, lng: 19.6022018 },
    { lat: 48.5902892, lng: 19.6026872 },
    { lat: 48.5910609, lng: 19.6031768 },
    { lat: 48.5917542, lng: 19.6040774 },
    { lat: 48.5921918, lng: 19.6059274 },
    { lat: 48.5925275, lng: 19.6069407 },
    { lat: 48.5926235, lng: 19.6074181 },
    { lat: 48.5926444, lng: 19.6081546 },
    { lat: 48.5924389, lng: 19.6087172 },
    { lat: 48.5924091, lng: 19.6091015 },
    { lat: 48.5920661, lng: 19.609765 },
    { lat: 48.5922271, lng: 19.6108313 },
    { lat: 48.5923142, lng: 19.6103421 },
    { lat: 48.5931539, lng: 19.6088785 },
    { lat: 48.5936183, lng: 19.6087302 },
    { lat: 48.5942712, lng: 19.6086698 },
    { lat: 48.5949713, lng: 19.6091733 },
    { lat: 48.59564, lng: 19.6098576 },
    { lat: 48.5962423, lng: 19.610142 },
    { lat: 48.5966106, lng: 19.6103863 },
    { lat: 48.5971176, lng: 19.611034 },
    { lat: 48.5974698, lng: 19.611668 },
    { lat: 48.5976191, lng: 19.6131243 },
    { lat: 48.5979286, lng: 19.614436 },
    { lat: 48.5980466, lng: 19.615474 },
    { lat: 48.5980533, lng: 19.615919 },
    { lat: 48.5979693, lng: 19.6161551 },
    { lat: 48.5985506, lng: 19.6174221 },
    { lat: 48.5983578, lng: 19.6174971 },
    { lat: 48.59869, lng: 19.6184619 },
    { lat: 48.5988854, lng: 19.6194194 },
    { lat: 48.598866, lng: 19.620557 },
    { lat: 48.5989415, lng: 19.6213075 },
    { lat: 48.5990692, lng: 19.6213399 },
    { lat: 48.5992723, lng: 19.6221706 },
    { lat: 48.5990469, lng: 19.6231646 },
    { lat: 48.5989358, lng: 19.6232244 },
    { lat: 48.599053, lng: 19.6236972 },
    { lat: 48.5987482, lng: 19.6245331 },
    { lat: 48.5988009, lng: 19.6250257 },
    { lat: 48.5986116, lng: 19.6257283 },
    { lat: 48.5988565, lng: 19.626084 },
    { lat: 48.5988856, lng: 19.6266296 },
    { lat: 48.5991253, lng: 19.6274192 },
    { lat: 48.599144, lng: 19.6279331 },
    { lat: 48.5984425, lng: 19.6292201 },
    { lat: 48.5971026, lng: 19.6294889 },
    { lat: 48.5968625, lng: 19.6299343 },
    { lat: 48.5957943, lng: 19.6294106 },
    { lat: 48.5953135, lng: 19.6285725 },
    { lat: 48.593288, lng: 19.6335533 },
    { lat: 48.5924767, lng: 19.6353998 },
    { lat: 48.5936511, lng: 19.636254 },
    { lat: 48.5942477, lng: 19.6369429 },
    { lat: 48.5948268, lng: 19.6365436 },
    { lat: 48.5966293, lng: 19.6344841 },
    { lat: 48.5976161, lng: 19.6346538 },
    { lat: 48.5981418, lng: 19.6351147 },
    { lat: 48.5984929, lng: 19.6353051 },
    { lat: 48.5989993, lng: 19.6354647 },
    { lat: 48.5995665, lng: 19.6358281 },
    { lat: 48.6002597, lng: 19.6368062 },
    { lat: 48.6005813, lng: 19.6373884 },
    { lat: 48.6009582, lng: 19.6378492 },
    { lat: 48.6020219, lng: 19.6396514 },
    { lat: 48.6029601, lng: 19.6409434 },
    { lat: 48.6030713, lng: 19.6413371 },
    { lat: 48.6029149, lng: 19.6420428 },
    { lat: 48.6028534, lng: 19.6431776 },
    { lat: 48.6025971, lng: 19.6446973 },
    { lat: 48.6035866, lng: 19.6444751 },
    { lat: 48.6043365, lng: 19.6440868 },
    { lat: 48.6051365, lng: 19.6440089 },
    { lat: 48.6059346, lng: 19.6434437 },
    { lat: 48.6065106, lng: 19.6434449 },
    { lat: 48.6073348, lng: 19.643612 },
    { lat: 48.6076558, lng: 19.6440804 },
    { lat: 48.6080266, lng: 19.6441354 },
    { lat: 48.6083322, lng: 19.6438916 },
    { lat: 48.6090356, lng: 19.6418666 },
    { lat: 48.6093453, lng: 19.6414878 },
    { lat: 48.6103908, lng: 19.6404329 },
    { lat: 48.6110237, lng: 19.6397004 },
    { lat: 48.6114704, lng: 19.6390604 },
    { lat: 48.6120092, lng: 19.6385152 },
    { lat: 48.6126973, lng: 19.6368815 },
    { lat: 48.6131586, lng: 19.6370945 },
    { lat: 48.6136817, lng: 19.6374732 },
    { lat: 48.6140471, lng: 19.6381323 },
    { lat: 48.6147969, lng: 19.6382649 },
    { lat: 48.6151842, lng: 19.6383046 },
    { lat: 48.6154124, lng: 19.6386248 },
    { lat: 48.6157446, lng: 19.641121 },
    { lat: 48.6161249, lng: 19.6419068 },
    { lat: 48.6163224, lng: 19.6421907 },
    { lat: 48.6164872, lng: 19.6422479 },
    { lat: 48.6170613, lng: 19.642036 },
    { lat: 48.6174739, lng: 19.6420917 },
    { lat: 48.6179591, lng: 19.6422792 },
    { lat: 48.6184924, lng: 19.6420632 },
    { lat: 48.6188016, lng: 19.6416975 },
    { lat: 48.6191076, lng: 19.6411566 },
    { lat: 48.6195408, lng: 19.6407472 },
    { lat: 48.6198851, lng: 19.6402853 },
    { lat: 48.6205072, lng: 19.6399395 },
    { lat: 48.6211833, lng: 19.6397367 },
    { lat: 48.6217894, lng: 19.6396365 },
    { lat: 48.6221735, lng: 19.6394041 },
    { lat: 48.6225567, lng: 19.6391312 },
    { lat: 48.6231728, lng: 19.6383518 },
    { lat: 48.6237715, lng: 19.6377612 },
    { lat: 48.6241182, lng: 19.6375273 },
    { lat: 48.624893, lng: 19.637798 },
  ],
  SlatinskéLazy: [
    { lat: 48.502359, lng: 19.279366 },
    { lat: 48.5022848, lng: 19.2803877 },
    { lat: 48.5023916, lng: 19.2811775 },
    { lat: 48.5025951, lng: 19.2820076 },
    { lat: 48.502691, lng: 19.2827553 },
    { lat: 48.5030847, lng: 19.2838621 },
    { lat: 48.5027097, lng: 19.2844309 },
    { lat: 48.5023909, lng: 19.2847355 },
    { lat: 48.5020249, lng: 19.2852372 },
    { lat: 48.5020794, lng: 19.2855013 },
    { lat: 48.5018708, lng: 19.2857559 },
    { lat: 48.5016868, lng: 19.2861708 },
    { lat: 48.5008358, lng: 19.2864507 },
    { lat: 48.5008376, lng: 19.2876831 },
    { lat: 48.5009348, lng: 19.287922 },
    { lat: 48.5007875, lng: 19.28807 },
    { lat: 48.5005478, lng: 19.2880993 },
    { lat: 48.5005375, lng: 19.2881911 },
    { lat: 48.5004225, lng: 19.2880476 },
    { lat: 48.4998392, lng: 19.2881092 },
    { lat: 48.499566, lng: 19.2882634 },
    { lat: 48.4992651, lng: 19.2886609 },
    { lat: 48.4988755, lng: 19.2889966 },
    { lat: 48.4986328, lng: 19.2890132 },
    { lat: 48.4986045, lng: 19.2894797 },
    { lat: 48.4986798, lng: 19.2901304 },
    { lat: 48.4991158, lng: 19.2911295 },
    { lat: 48.4989441, lng: 19.2914247 },
    { lat: 48.4981713, lng: 19.2912244 },
    { lat: 48.4973729, lng: 19.2914298 },
    { lat: 48.4975504, lng: 19.2919073 },
    { lat: 48.4980598, lng: 19.2928415 },
    { lat: 48.4978389, lng: 19.2930511 },
    { lat: 48.4977725, lng: 19.2930321 },
    { lat: 48.4972457, lng: 19.2939617 },
    { lat: 48.4973018, lng: 19.294097 },
    { lat: 48.4972849, lng: 19.294467 },
    { lat: 48.497339, lng: 19.2947024 },
    { lat: 48.4971472, lng: 19.2950503 },
    { lat: 48.4972125, lng: 19.295352 },
    { lat: 48.497164, lng: 19.2955403 },
    { lat: 48.4970483, lng: 19.2956884 },
    { lat: 48.4966764, lng: 19.2959215 },
    { lat: 48.4965315, lng: 19.2952575 },
    { lat: 48.4965498, lng: 19.2949104 },
    { lat: 48.4963871, lng: 19.2941082 },
    { lat: 48.4961755, lng: 19.2936423 },
    { lat: 48.4960569, lng: 19.2935927 },
    { lat: 48.4955886, lng: 19.2938406 },
    { lat: 48.4955375, lng: 19.2921216 },
    { lat: 48.4956253, lng: 19.2905518 },
    { lat: 48.495241, lng: 19.290248 },
    { lat: 48.4950979, lng: 19.2908816 },
    { lat: 48.4950498, lng: 19.2915454 },
    { lat: 48.4949606, lng: 19.2915423 },
    { lat: 48.4949702, lng: 19.2912502 },
    { lat: 48.4949119, lng: 19.2909513 },
    { lat: 48.4945735, lng: 19.2902434 },
    { lat: 48.4946549, lng: 19.2900163 },
    { lat: 48.4946363, lng: 19.2897955 },
    { lat: 48.4945611, lng: 19.2896814 },
    { lat: 48.494914, lng: 19.2892911 },
    { lat: 48.4949276, lng: 19.2892196 },
    { lat: 48.4948429, lng: 19.2880451 },
    { lat: 48.4947032, lng: 19.2875578 },
    { lat: 48.4945243, lng: 19.2875411 },
    { lat: 48.4943853, lng: 19.2874419 },
    { lat: 48.4942115, lng: 19.2874569 },
    { lat: 48.493754, lng: 19.2876735 },
    { lat: 48.4931963, lng: 19.2867181 },
    { lat: 48.4928555, lng: 19.2855673 },
    { lat: 48.4929349, lng: 19.2854115 },
    { lat: 48.4926991, lng: 19.2854088 },
    { lat: 48.4926629, lng: 19.285476 },
    { lat: 48.4925961, lng: 19.2853988 },
    { lat: 48.4918464, lng: 19.2868242 },
    { lat: 48.4922171, lng: 19.2869831 },
    { lat: 48.4923077, lng: 19.2869672 },
    { lat: 48.4924665, lng: 19.2870799 },
    { lat: 48.492558, lng: 19.2873219 },
    { lat: 48.4922994, lng: 19.2875185 },
    { lat: 48.4922646, lng: 19.2876147 },
    { lat: 48.4922141, lng: 19.2875975 },
    { lat: 48.4921298, lng: 19.2879543 },
    { lat: 48.4920079, lng: 19.287857 },
    { lat: 48.4919841, lng: 19.2879264 },
    { lat: 48.4921422, lng: 19.2881437 },
    { lat: 48.4921123, lng: 19.2882685 },
    { lat: 48.4921942, lng: 19.2882365 },
    { lat: 48.4922523, lng: 19.2883694 },
    { lat: 48.4921837, lng: 19.2884412 },
    { lat: 48.4923606, lng: 19.2886028 },
    { lat: 48.4925979, lng: 19.2890801 },
    { lat: 48.4927082, lng: 19.289174 },
    { lat: 48.4929515, lng: 19.2891936 },
    { lat: 48.4928721, lng: 19.2894375 },
    { lat: 48.493473, lng: 19.2886488 },
    { lat: 48.493918, lng: 19.2890419 },
    { lat: 48.4940135, lng: 19.2891996 },
    { lat: 48.4945396, lng: 19.2895647 },
    { lat: 48.4925709, lng: 19.2920095 },
    { lat: 48.4920605, lng: 19.2913789 },
    { lat: 48.4915868, lng: 19.2905767 },
    { lat: 48.4912581, lng: 19.2911268 },
    { lat: 48.4912429, lng: 19.2913029 },
    { lat: 48.4913341, lng: 19.2915573 },
    { lat: 48.4912362, lng: 19.2916089 },
    { lat: 48.4911665, lng: 19.2917497 },
    { lat: 48.4908981, lng: 19.2919108 },
    { lat: 48.4909152, lng: 19.2932031 },
    { lat: 48.4907147, lng: 19.2948649 },
    { lat: 48.4912111, lng: 19.2955539 },
    { lat: 48.4912223, lng: 19.2959975 },
    { lat: 48.4913251, lng: 19.2961903 },
    { lat: 48.4913576, lng: 19.2963762 },
    { lat: 48.490905, lng: 19.296875 },
    { lat: 48.4907412, lng: 19.2968925 },
    { lat: 48.4908979, lng: 19.2979436 },
    { lat: 48.4919378, lng: 19.2998324 },
    { lat: 48.4913764, lng: 19.3007957 },
    { lat: 48.491064, lng: 19.3021467 },
    { lat: 48.4908127, lng: 19.3024761 },
    { lat: 48.4908889, lng: 19.3043895 },
    { lat: 48.4906186, lng: 19.3043244 },
    { lat: 48.4901739, lng: 19.3038199 },
    { lat: 48.4899789, lng: 19.3037483 },
    { lat: 48.4884061, lng: 19.303739 },
    { lat: 48.4882957, lng: 19.3041156 },
    { lat: 48.4880797, lng: 19.3041704 },
    { lat: 48.4881407, lng: 19.3050432 },
    { lat: 48.4879841, lng: 19.3050428 },
    { lat: 48.487828, lng: 19.3051944 },
    { lat: 48.4877378, lng: 19.3057607 },
    { lat: 48.4881405, lng: 19.306467 },
    { lat: 48.4882175, lng: 19.3066885 },
    { lat: 48.4884311, lng: 19.3069808 },
    { lat: 48.4878672, lng: 19.3076085 },
    { lat: 48.4872014, lng: 19.3082221 },
    { lat: 48.4875325, lng: 19.3086885 },
    { lat: 48.4881018, lng: 19.3093248 },
    { lat: 48.4873561, lng: 19.3100124 },
    { lat: 48.4867812, lng: 19.3103414 },
    { lat: 48.4861441, lng: 19.3102909 },
    { lat: 48.4857524, lng: 19.3108405 },
    { lat: 48.4855868, lng: 19.3108627 },
    { lat: 48.4865396, lng: 19.3146509 },
    { lat: 48.486879, lng: 19.3156156 },
    { lat: 48.4876563, lng: 19.3183347 },
    { lat: 48.4877064, lng: 19.3188871 },
    { lat: 48.4875967, lng: 19.3190667 },
    { lat: 48.4876449, lng: 19.3195539 },
    { lat: 48.4877983, lng: 19.319932 },
    { lat: 48.4878657, lng: 19.3202584 },
    { lat: 48.4877558, lng: 19.3202919 },
    { lat: 48.4877618, lng: 19.3203713 },
    { lat: 48.487643, lng: 19.320431 },
    { lat: 48.487968, lng: 19.3216642 },
    { lat: 48.4881994, lng: 19.3214341 },
    { lat: 48.4883079, lng: 19.3214657 },
    { lat: 48.4884839, lng: 19.3218225 },
    { lat: 48.4888122, lng: 19.3218022 },
    { lat: 48.488831, lng: 19.3221697 },
    { lat: 48.4889911, lng: 19.3223616 },
    { lat: 48.489275, lng: 19.3228979 },
    { lat: 48.4895583, lng: 19.3230495 },
    { lat: 48.4900633, lng: 19.3230043 },
    { lat: 48.4901897, lng: 19.3222753 },
    { lat: 48.490531, lng: 19.3223399 },
    { lat: 48.4907236, lng: 19.3235858 },
    { lat: 48.4903538, lng: 19.323326 },
    { lat: 48.4902215, lng: 19.3236011 },
    { lat: 48.4900316, lng: 19.3238214 },
    { lat: 48.4901604, lng: 19.3240413 },
    { lat: 48.4901932, lng: 19.3239954 },
    { lat: 48.4904309, lng: 19.3241363 },
    { lat: 48.4905589, lng: 19.3241004 },
    { lat: 48.4907153, lng: 19.3244466 },
    { lat: 48.4909671, lng: 19.3246694 },
    { lat: 48.4913511, lng: 19.3246893 },
    { lat: 48.4914634, lng: 19.324514 },
    { lat: 48.4915703, lng: 19.3245546 },
    { lat: 48.4920972, lng: 19.3240718 },
    { lat: 48.4922295, lng: 19.3240564 },
    { lat: 48.4925565, lng: 19.3237782 },
    { lat: 48.4927583, lng: 19.3237805 },
    { lat: 48.4928193, lng: 19.323689 },
    { lat: 48.4930306, lng: 19.3237943 },
    { lat: 48.4930456, lng: 19.3237085 },
    { lat: 48.4932165, lng: 19.323653 },
    { lat: 48.4941326, lng: 19.323689 },
    { lat: 48.4942564, lng: 19.3239464 },
    { lat: 48.4947842, lng: 19.3240729 },
    { lat: 48.4951311, lng: 19.3234311 },
    { lat: 48.4954099, lng: 19.3232996 },
    { lat: 48.4960141, lng: 19.3233446 },
    { lat: 48.4967215, lng: 19.3236648 },
    { lat: 48.4967355, lng: 19.3235084 },
    { lat: 48.4972992, lng: 19.3224763 },
    { lat: 48.497438, lng: 19.3222932 },
    { lat: 48.4978164, lng: 19.3222151 },
    { lat: 48.4979567, lng: 19.3207464 },
    { lat: 48.4984066, lng: 19.3205391 },
    { lat: 48.4988501, lng: 19.3193736 },
    { lat: 48.4991617, lng: 19.3192424 },
    { lat: 48.4993883, lng: 19.3199243 },
    { lat: 48.4994353, lng: 19.3202238 },
    { lat: 48.4994987, lng: 19.3202322 },
    { lat: 48.4999353, lng: 19.3208988 },
    { lat: 48.5000981, lng: 19.3209988 },
    { lat: 48.5010722, lng: 19.3197582 },
    { lat: 48.5012704, lng: 19.3193358 },
    { lat: 48.5014716, lng: 19.3193001 },
    { lat: 48.5016385, lng: 19.3193918 },
    { lat: 48.5021266, lng: 19.3197703 },
    { lat: 48.5021098, lng: 19.3198667 },
    { lat: 48.5022039, lng: 19.3199489 },
    { lat: 48.502316, lng: 19.3198663 },
    { lat: 48.5026294, lng: 19.3201024 },
    { lat: 48.5031325, lng: 19.319971 },
    { lat: 48.5032618, lng: 19.3197999 },
    { lat: 48.5034238, lng: 19.3197208 },
    { lat: 48.5036976, lng: 19.3194611 },
    { lat: 48.5049758, lng: 19.317371 },
    { lat: 48.5060531, lng: 19.31847 },
    { lat: 48.5062353, lng: 19.3185792 },
    { lat: 48.5063228, lng: 19.3184041 },
    { lat: 48.5062749, lng: 19.3182294 },
    { lat: 48.5062799, lng: 19.3180277 },
    { lat: 48.5066083, lng: 19.3168624 },
    { lat: 48.5066133, lng: 19.3167062 },
    { lat: 48.5070494, lng: 19.3168458 },
    { lat: 48.5071366, lng: 19.3169245 },
    { lat: 48.5072019, lng: 19.3167592 },
    { lat: 48.5079391, lng: 19.3172983 },
    { lat: 48.5092367, lng: 19.3151192 },
    { lat: 48.5092825, lng: 19.3151846 },
    { lat: 48.5096228, lng: 19.3149656 },
    { lat: 48.509958, lng: 19.3145538 },
    { lat: 48.5094284, lng: 19.313573 },
    { lat: 48.5095662, lng: 19.3134816 },
    { lat: 48.5097915, lng: 19.3129623 },
    { lat: 48.5098702, lng: 19.31291 },
    { lat: 48.5101576, lng: 19.313145 },
    { lat: 48.5105257, lng: 19.3135611 },
    { lat: 48.5107699, lng: 19.3135758 },
    { lat: 48.5109666, lng: 19.3137759 },
    { lat: 48.5111933, lng: 19.3141554 },
    { lat: 48.5112189, lng: 19.314571 },
    { lat: 48.5116026, lng: 19.3150018 },
    { lat: 48.5119185, lng: 19.3148654 },
    { lat: 48.5122874, lng: 19.3152992 },
    { lat: 48.512618, lng: 19.3152135 },
    { lat: 48.5129483, lng: 19.3153529 },
    { lat: 48.5130952, lng: 19.3156693 },
    { lat: 48.5131645, lng: 19.315725 },
    { lat: 48.5132884, lng: 19.3156536 },
    { lat: 48.5134739, lng: 19.3158514 },
    { lat: 48.5137667, lng: 19.3160371 },
    { lat: 48.5138149, lng: 19.3163828 },
    { lat: 48.5139117, lng: 19.3165009 },
    { lat: 48.5142044, lng: 19.3163735 },
    { lat: 48.5143845, lng: 19.3165328 },
    { lat: 48.5144615, lng: 19.3163072 },
    { lat: 48.5144756, lng: 19.3160593 },
    { lat: 48.5146981, lng: 19.3129419 },
    { lat: 48.5149458, lng: 19.3084739 },
    { lat: 48.5154709, lng: 19.3085927 },
    { lat: 48.5204805, lng: 19.3091409 },
    { lat: 48.520255, lng: 19.3059464 },
    { lat: 48.5198221, lng: 19.3014443 },
    { lat: 48.5198588, lng: 19.3013137 },
    { lat: 48.5179127, lng: 19.3015567 },
    { lat: 48.5170512, lng: 19.3018361 },
    { lat: 48.5167041, lng: 19.3015085 },
    { lat: 48.5156231, lng: 19.3018598 },
    { lat: 48.5158861, lng: 19.3025447 },
    { lat: 48.5155033, lng: 19.3026486 },
    { lat: 48.51272, lng: 19.2994512 },
    { lat: 48.5129883, lng: 19.2991958 },
    { lat: 48.5132098, lng: 19.299092 },
    { lat: 48.5137994, lng: 19.2989496 },
    { lat: 48.5149467, lng: 19.2990208 },
    { lat: 48.5151926, lng: 19.2987856 },
    { lat: 48.515311, lng: 19.2984393 },
    { lat: 48.5150619, lng: 19.2969132 },
    { lat: 48.5149264, lng: 19.2954142 },
    { lat: 48.5149616, lng: 19.2949467 },
    { lat: 48.5153896, lng: 19.2936518 },
    { lat: 48.5162246, lng: 19.2928792 },
    { lat: 48.5167097, lng: 19.2918084 },
    { lat: 48.5169025, lng: 19.2915744 },
    { lat: 48.5178791, lng: 19.2913956 },
    { lat: 48.5188024, lng: 19.2902776 },
    { lat: 48.5190456, lng: 19.2897821 },
    { lat: 48.519508, lng: 19.289408 },
    { lat: 48.518771, lng: 19.289265 },
    { lat: 48.51862, lng: 19.289227 },
    { lat: 48.518635, lng: 19.289083 },
    { lat: 48.518249, lng: 19.289098 },
    { lat: 48.517772, lng: 19.288946 },
    { lat: 48.51693, lng: 19.288875 },
    { lat: 48.516411, lng: 19.288952 },
    { lat: 48.51614, lng: 19.289043 },
    { lat: 48.515648, lng: 19.289117 },
    { lat: 48.515156, lng: 19.289395 },
    { lat: 48.514862, lng: 19.289359 },
    { lat: 48.514553, lng: 19.289311 },
    { lat: 48.514187, lng: 19.289215 },
    { lat: 48.513802, lng: 19.288884 },
    { lat: 48.513533, lng: 19.288554 },
    { lat: 48.512833, lng: 19.288149 },
    { lat: 48.512663, lng: 19.288079 },
    { lat: 48.511703, lng: 19.287634 },
    { lat: 48.510599, lng: 19.286995 },
    { lat: 48.510147, lng: 19.28678 },
    { lat: 48.509858, lng: 19.286675 },
    { lat: 48.509545, lng: 19.286357 },
    { lat: 48.509119, lng: 19.286125 },
    { lat: 48.508321, lng: 19.285099 },
    { lat: 48.50746, lng: 19.284447 },
    { lat: 48.507103, lng: 19.283116 },
    { lat: 48.506522, lng: 19.281998 },
    { lat: 48.506283, lng: 19.281638 },
    { lat: 48.505786, lng: 19.281516 },
    { lat: 48.50536, lng: 19.281516 },
    { lat: 48.50507, lng: 19.281464 },
    { lat: 48.503925, lng: 19.281523 },
    { lat: 48.503505, lng: 19.280876 },
    { lat: 48.503459, lng: 19.280825 },
    { lat: 48.503428, lng: 19.280792 },
    { lat: 48.502844, lng: 19.280155 },
    { lat: 48.502691, lng: 19.279905 },
    { lat: 48.502359, lng: 19.279366 },
  ],
  Vígľaš: [
    { lat: 48.547905, lng: 19.386063 },
    { lat: 48.548109, lng: 19.385342 },
    { lat: 48.548233, lng: 19.384724 },
    { lat: 48.548315, lng: 19.38407 },
    { lat: 48.548363, lng: 19.383563 },
    { lat: 48.548374, lng: 19.382747 },
    { lat: 48.548424, lng: 19.382748 },
    { lat: 48.5483761, lng: 19.3825477 },
    { lat: 48.548577, lng: 19.381985 },
    { lat: 48.548607, lng: 19.381561 },
    { lat: 48.548545, lng: 19.380923 },
    { lat: 48.54854, lng: 19.380774 },
    { lat: 48.548537, lng: 19.380665 },
    { lat: 48.548635, lng: 19.380356 },
    { lat: 48.548738, lng: 19.379856 },
    { lat: 48.548773, lng: 19.379363 },
    { lat: 48.54886, lng: 19.378526 },
    { lat: 48.548813, lng: 19.378157 },
    { lat: 48.5488001, lng: 19.3779355 },
    { lat: 48.548776, lng: 19.377523 },
    { lat: 48.548737, lng: 19.376794 },
    { lat: 48.54875, lng: 19.37612 },
    { lat: 48.548724, lng: 19.376041 },
    { lat: 48.548763, lng: 19.374856 },
    { lat: 48.548687, lng: 19.374219 },
    { lat: 48.548598, lng: 19.373775 },
    { lat: 48.548637, lng: 19.373567 },
    { lat: 48.548597, lng: 19.373357 },
    { lat: 48.548457, lng: 19.373024 },
    { lat: 48.548392, lng: 19.372728 },
    { lat: 48.548211, lng: 19.372328 },
    { lat: 48.548125, lng: 19.371973 },
    { lat: 48.548226, lng: 19.371385 },
    { lat: 48.548499, lng: 19.370614 },
    { lat: 48.548636, lng: 19.370376 },
    { lat: 48.549045, lng: 19.369976 },
    { lat: 48.54912, lng: 19.369753 },
    { lat: 48.549354, lng: 19.369342 },
    { lat: 48.549393, lng: 19.369178 },
    { lat: 48.550066, lng: 19.369475 },
    { lat: 48.550185, lng: 19.369474 },
    { lat: 48.550572, lng: 19.369484 },
    { lat: 48.550593, lng: 19.369487 },
    { lat: 48.551516, lng: 19.369556 },
    { lat: 48.551758, lng: 19.369339 },
    { lat: 48.552123, lng: 19.369145 },
    { lat: 48.552151, lng: 19.369134 },
    { lat: 48.552594, lng: 19.368862 },
    { lat: 48.553223, lng: 19.368434 },
    { lat: 48.554536, lng: 19.367416 },
    { lat: 48.55519, lng: 19.36685 },
    { lat: 48.555442, lng: 19.366618 },
    { lat: 48.555536, lng: 19.36653 },
    { lat: 48.555565, lng: 19.366503 },
    { lat: 48.555739, lng: 19.366343 },
    { lat: 48.55608, lng: 19.366313 },
    { lat: 48.557384, lng: 19.364842 },
    { lat: 48.558253, lng: 19.363863 },
    { lat: 48.558335, lng: 19.363739 },
    { lat: 48.558391, lng: 19.36365 },
    { lat: 48.558476, lng: 19.363521 },
    { lat: 48.558796, lng: 19.363026 },
    { lat: 48.558865, lng: 19.36292 },
    { lat: 48.559003, lng: 19.362685 },
    { lat: 48.559183, lng: 19.362412 },
    { lat: 48.55916, lng: 19.362378 },
    { lat: 48.559309, lng: 19.362153 },
    { lat: 48.559312, lng: 19.362163 },
    { lat: 48.559352, lng: 19.362095 },
    { lat: 48.559409, lng: 19.361823 },
    { lat: 48.560137, lng: 19.358316 },
    { lat: 48.560353, lng: 19.357026 },
    { lat: 48.560439, lng: 19.356003 },
    { lat: 48.560465, lng: 19.355682 },
    { lat: 48.560506, lng: 19.355189 },
    { lat: 48.560591, lng: 19.3544 },
    { lat: 48.560476, lng: 19.354368 },
    { lat: 48.560478, lng: 19.354317 },
    { lat: 48.56048, lng: 19.354272 },
    { lat: 48.560601, lng: 19.354157 },
    { lat: 48.560608, lng: 19.354104 },
    { lat: 48.560719, lng: 19.353323 },
    { lat: 48.56089, lng: 19.351948 },
    { lat: 48.560901, lng: 19.351829 },
    { lat: 48.561098, lng: 19.350572 },
    { lat: 48.561117, lng: 19.350458 },
    { lat: 48.561422, lng: 19.348676 },
    { lat: 48.561427, lng: 19.348651 },
    { lat: 48.561549, lng: 19.348075 },
    { lat: 48.561853, lng: 19.346646 },
    { lat: 48.56216, lng: 19.345282 },
    { lat: 48.562168, lng: 19.345239 },
    { lat: 48.562266, lng: 19.344792 },
    { lat: 48.56231, lng: 19.34459 },
    { lat: 48.562269, lng: 19.3444 },
    { lat: 48.562287, lng: 19.344304 },
    { lat: 48.562378, lng: 19.344288 },
    { lat: 48.562427, lng: 19.343933 },
    { lat: 48.562465, lng: 19.3437 },
    { lat: 48.562518, lng: 19.341785 },
    { lat: 48.562535, lng: 19.340038 },
    { lat: 48.562514, lng: 19.33824 },
    { lat: 48.562465, lng: 19.337673 },
    { lat: 48.562349, lng: 19.33717 },
    { lat: 48.562172, lng: 19.336723 },
    { lat: 48.56188, lng: 19.336153 },
    { lat: 48.561675, lng: 19.33573 },
    { lat: 48.561184, lng: 19.334698 },
    { lat: 48.560954, lng: 19.334207 },
    { lat: 48.560838, lng: 19.334012 },
    { lat: 48.560499, lng: 19.333453 },
    { lat: 48.560525, lng: 19.33337 },
    { lat: 48.560542, lng: 19.333273 },
    { lat: 48.560555, lng: 19.333188 },
    { lat: 48.560566, lng: 19.333104 },
    { lat: 48.560578, lng: 19.333045 },
    { lat: 48.560655, lng: 19.332661 },
    { lat: 48.560679, lng: 19.33262 },
    { lat: 48.560704, lng: 19.332555 },
    { lat: 48.560224, lng: 19.331915 },
    { lat: 48.55997, lng: 19.331338 },
    { lat: 48.560174, lng: 19.331134 },
    { lat: 48.560337, lng: 19.331291 },
    { lat: 48.560598, lng: 19.33135 },
    { lat: 48.561527, lng: 19.331575 },
    { lat: 48.562044, lng: 19.3317 },
    { lat: 48.562673, lng: 19.331852 },
    { lat: 48.562676, lng: 19.331787 },
    { lat: 48.562677, lng: 19.331737 },
    { lat: 48.562692, lng: 19.331687 },
    { lat: 48.563209, lng: 19.330306 },
    { lat: 48.564153, lng: 19.327776 },
    { lat: 48.564667, lng: 19.327835 },
    { lat: 48.565451, lng: 19.328108 },
    { lat: 48.565487, lng: 19.328111 },
    { lat: 48.565713, lng: 19.328083 },
    { lat: 48.566329, lng: 19.327969 },
    { lat: 48.567014, lng: 19.327841 },
    { lat: 48.567303, lng: 19.327897 },
    { lat: 48.5677, lng: 19.328196 },
    { lat: 48.567749, lng: 19.328145 },
    { lat: 48.567779, lng: 19.328076 },
    { lat: 48.567792, lng: 19.328035 },
    { lat: 48.56806, lng: 19.328212 },
    { lat: 48.568216, lng: 19.32826 },
    { lat: 48.568449, lng: 19.328104 },
    { lat: 48.568951, lng: 19.327699 },
    { lat: 48.569046, lng: 19.327619 },
    { lat: 48.569466, lng: 19.327269 },
    { lat: 48.570032, lng: 19.326824 },
    { lat: 48.570472, lng: 19.326555 },
    { lat: 48.571082, lng: 19.326295 },
    { lat: 48.571116, lng: 19.326284 },
    { lat: 48.57122, lng: 19.326239 },
    { lat: 48.571344, lng: 19.326193 },
    { lat: 48.5714527, lng: 19.3261652 },
    { lat: 48.57263, lng: 19.325673 },
    { lat: 48.573265, lng: 19.325488 },
    { lat: 48.573488, lng: 19.325423 },
    { lat: 48.573679, lng: 19.325444 },
    { lat: 48.573868, lng: 19.32556 },
    { lat: 48.574136, lng: 19.32573 },
    { lat: 48.574172, lng: 19.325753 },
    { lat: 48.574317, lng: 19.325843 },
    { lat: 48.57442, lng: 19.325904 },
    { lat: 48.574457, lng: 19.325926 },
    { lat: 48.574568, lng: 19.325993 },
    { lat: 48.574712, lng: 19.326097 },
    { lat: 48.574738, lng: 19.326129 },
    { lat: 48.575045, lng: 19.325633 },
    { lat: 48.575163, lng: 19.325449 },
    { lat: 48.575332, lng: 19.325186 },
    { lat: 48.576499, lng: 19.323217 },
    { lat: 48.577216, lng: 19.321983 },
    { lat: 48.577273, lng: 19.321725 },
    { lat: 48.577332, lng: 19.321378 },
    { lat: 48.577531, lng: 19.319651 },
    { lat: 48.577718, lng: 19.317552 },
    { lat: 48.577289, lng: 19.317535 },
    { lat: 48.577223, lng: 19.316322 },
    { lat: 48.577211, lng: 19.316288 },
    { lat: 48.577177, lng: 19.316232 },
    { lat: 48.576876, lng: 19.315571 },
    { lat: 48.576856, lng: 19.315531 },
    { lat: 48.576835, lng: 19.315491 },
    { lat: 48.576553, lng: 19.314896 },
    { lat: 48.576557, lng: 19.31485 },
    { lat: 48.576953, lng: 19.311076 },
    { lat: 48.577235, lng: 19.308347 },
    { lat: 48.577524, lng: 19.305794 },
    { lat: 48.577558, lng: 19.305606 },
    { lat: 48.577562, lng: 19.305579 },
    { lat: 48.577566, lng: 19.305555 },
    { lat: 48.577577, lng: 19.305378 },
    { lat: 48.577784, lng: 19.303341 },
    { lat: 48.57804, lng: 19.300835 },
    { lat: 48.578043, lng: 19.300766 },
    { lat: 48.578046, lng: 19.300704 },
    { lat: 48.578053, lng: 19.300646 },
    { lat: 48.578002, lng: 19.300633 },
    { lat: 48.578155, lng: 19.298272 },
    { lat: 48.578325, lng: 19.296272 },
    { lat: 48.57839, lng: 19.295458 },
    { lat: 48.57838, lng: 19.295344 },
    { lat: 48.578233, lng: 19.295319 },
    { lat: 48.578028, lng: 19.29535 },
    { lat: 48.577928, lng: 19.295408 },
    { lat: 48.577366, lng: 19.295905 },
    { lat: 48.576344, lng: 19.296874 },
    { lat: 48.576134, lng: 19.297081 },
    { lat: 48.575943, lng: 19.297187 },
    { lat: 48.575364, lng: 19.297291 },
    { lat: 48.575269, lng: 19.29727 },
    { lat: 48.575125, lng: 19.297239 },
    { lat: 48.574814, lng: 19.296974 },
    { lat: 48.57473, lng: 19.296948 },
    { lat: 48.574415, lng: 19.296977 },
    { lat: 48.574418, lng: 19.296903 },
    { lat: 48.574424, lng: 19.296717 },
    { lat: 48.57447, lng: 19.294587 },
    { lat: 48.572785, lng: 19.29407 },
    { lat: 48.571784, lng: 19.293768 },
    { lat: 48.569898, lng: 19.293148 },
    { lat: 48.569286, lng: 19.292937 },
    { lat: 48.567737, lng: 19.292497 },
    { lat: 48.566363, lng: 19.292131 },
    { lat: 48.566356, lng: 19.292103 },
    { lat: 48.566344, lng: 19.292058 },
    { lat: 48.566174, lng: 19.291429 },
    { lat: 48.565841, lng: 19.291212 },
    { lat: 48.565986, lng: 19.290721 },
    { lat: 48.565552, lng: 19.290613 },
    { lat: 48.565527, lng: 19.290617 },
    { lat: 48.565496, lng: 19.29062 },
    { lat: 48.565368, lng: 19.289843 },
    { lat: 48.565182, lng: 19.289723 },
    { lat: 48.56483, lng: 19.288619 },
    { lat: 48.56507, lng: 19.288605 },
    { lat: 48.565146, lng: 19.287883 },
    { lat: 48.56509, lng: 19.287789 },
    { lat: 48.564953, lng: 19.287618 },
    { lat: 48.564821, lng: 19.286785 },
    { lat: 48.564535, lng: 19.286206 },
    { lat: 48.564506, lng: 19.285912 },
    { lat: 48.564689, lng: 19.285492 },
    { lat: 48.564593, lng: 19.285373 },
    { lat: 48.56408, lng: 19.284745 },
    { lat: 48.563613, lng: 19.284427 },
    { lat: 48.563356, lng: 19.284474 },
    { lat: 48.562816, lng: 19.284182 },
    { lat: 48.561877, lng: 19.284474 },
    { lat: 48.560445, lng: 19.285105 },
    { lat: 48.56032, lng: 19.28516 },
    { lat: 48.560282, lng: 19.285209 },
    { lat: 48.559866, lng: 19.285759 },
    { lat: 48.559792, lng: 19.285838 },
    { lat: 48.559785, lng: 19.285846 },
    { lat: 48.55977, lng: 19.285861 },
    { lat: 48.559686, lng: 19.285953 },
    { lat: 48.559533, lng: 19.285936 },
    { lat: 48.559462, lng: 19.285927 },
    { lat: 48.559413, lng: 19.285922 },
    { lat: 48.559383, lng: 19.285919 },
    { lat: 48.559314, lng: 19.28591 },
    { lat: 48.559236, lng: 19.285901 },
    { lat: 48.559173, lng: 19.285894 },
    { lat: 48.559145, lng: 19.285921 },
    { lat: 48.559056, lng: 19.286006 },
    { lat: 48.558992, lng: 19.286065 },
    { lat: 48.558923, lng: 19.286131 },
    { lat: 48.558894, lng: 19.286158 },
    { lat: 48.558735, lng: 19.286309 },
    { lat: 48.558354, lng: 19.286548 },
    { lat: 48.558467, lng: 19.286294 },
    { lat: 48.55859, lng: 19.286086 },
    { lat: 48.558608, lng: 19.286056 },
    { lat: 48.558633, lng: 19.286013 },
    { lat: 48.558582, lng: 19.286049 },
    { lat: 48.558545, lng: 19.286076 },
    { lat: 48.557579, lng: 19.286771 },
    { lat: 48.557405, lng: 19.286674 },
    { lat: 48.5574, lng: 19.286671 },
    { lat: 48.557266, lng: 19.286518 },
    { lat: 48.557172, lng: 19.286319 },
    { lat: 48.55684, lng: 19.285524 },
    { lat: 48.556706, lng: 19.284689 },
    { lat: 48.556697, lng: 19.284622 },
    { lat: 48.556673, lng: 19.284453 },
    { lat: 48.556659, lng: 19.284383 },
    { lat: 48.556718, lng: 19.284081 },
    { lat: 48.555109, lng: 19.280261 },
    { lat: 48.554984, lng: 19.279965 },
    { lat: 48.55491, lng: 19.279729 },
    { lat: 48.554842, lng: 19.279488 },
    { lat: 48.554826, lng: 19.279242 },
    { lat: 48.554961, lng: 19.278178 },
    { lat: 48.554873, lng: 19.278108 },
    { lat: 48.554758, lng: 19.278016 },
    { lat: 48.554769, lng: 19.27797 },
    { lat: 48.554775, lng: 19.277945 },
    { lat: 48.554786, lng: 19.2779 },
    { lat: 48.554782, lng: 19.277898 },
    { lat: 48.554579, lng: 19.27787 },
    { lat: 48.554576, lng: 19.27787 },
    { lat: 48.55457, lng: 19.2779 },
    { lat: 48.554566, lng: 19.277927 },
    { lat: 48.554559, lng: 19.277971 },
    { lat: 48.554303, lng: 19.278103 },
    { lat: 48.554194, lng: 19.278878 },
    { lat: 48.554183, lng: 19.278934 },
    { lat: 48.554169, lng: 19.279012 },
    { lat: 48.554157, lng: 19.279091 },
    { lat: 48.554135, lng: 19.279196 },
    { lat: 48.554117, lng: 19.279577 },
    { lat: 48.554135, lng: 19.279579 },
    { lat: 48.554289, lng: 19.279604 },
    { lat: 48.554202, lng: 19.279999 },
    { lat: 48.553025, lng: 19.280079 },
    { lat: 48.550704, lng: 19.280238 },
    { lat: 48.550345, lng: 19.280238 },
    { lat: 48.55001, lng: 19.280274 },
    { lat: 48.54981, lng: 19.280754 },
    { lat: 48.5499, lng: 19.281283 },
    { lat: 48.550136, lng: 19.281824 },
    { lat: 48.550284, lng: 19.282294 },
    { lat: 48.550472, lng: 19.28287 },
    { lat: 48.550664, lng: 19.28345 },
    { lat: 48.550718, lng: 19.283889 },
    { lat: 48.550712, lng: 19.284402 },
    { lat: 48.550884, lng: 19.285021 },
    { lat: 48.550866, lng: 19.285019 },
    { lat: 48.550842, lng: 19.285014 },
    { lat: 48.550722, lng: 19.284995 },
    { lat: 48.550682, lng: 19.284988 },
    { lat: 48.550725, lng: 19.285396 },
    { lat: 48.550511, lng: 19.285106 },
    { lat: 48.550282, lng: 19.284651 },
    { lat: 48.550158, lng: 19.284123 },
    { lat: 48.549255, lng: 19.284578 },
    { lat: 48.549156, lng: 19.28314 },
    { lat: 48.548845, lng: 19.282145 },
    { lat: 48.548293, lng: 19.282262 },
    { lat: 48.548105, lng: 19.28153 },
    { lat: 48.548083, lng: 19.281543 },
    { lat: 48.547602, lng: 19.281816 },
    { lat: 48.546904, lng: 19.281386 },
    { lat: 48.546002, lng: 19.281515 },
    { lat: 48.545968, lng: 19.281535 },
    { lat: 48.544664, lng: 19.282603 },
    { lat: 48.544375, lng: 19.282995 },
    { lat: 48.543867, lng: 19.283476 },
    { lat: 48.543251, lng: 19.284098 },
    { lat: 48.542963, lng: 19.284312 },
    { lat: 48.542907, lng: 19.284499 },
    { lat: 48.541709, lng: 19.286892 },
    { lat: 48.541281, lng: 19.287759 },
    { lat: 48.541041, lng: 19.288029 },
    { lat: 48.539942, lng: 19.288993 },
    { lat: 48.539533, lng: 19.289291 },
    { lat: 48.539156, lng: 19.289583 },
    { lat: 48.538879, lng: 19.28895 },
    { lat: 48.538897, lng: 19.288917 },
    { lat: 48.538905, lng: 19.288887 },
    { lat: 48.539316, lng: 19.288014 },
    { lat: 48.539313, lng: 19.287937 },
    { lat: 48.53929, lng: 19.287333 },
    { lat: 48.539449, lng: 19.286794 },
    { lat: 48.540424, lng: 19.285238 },
    { lat: 48.540571, lng: 19.284747 },
    { lat: 48.540522, lng: 19.284688 },
    { lat: 48.540414, lng: 19.284598 },
    { lat: 48.53987, lng: 19.284185 },
    { lat: 48.539531, lng: 19.283984 },
    { lat: 48.539374, lng: 19.283891 },
    { lat: 48.538723, lng: 19.283503 },
    { lat: 48.533691, lng: 19.27543 },
    { lat: 48.532324, lng: 19.276161 },
    { lat: 48.531721, lng: 19.276635 },
    { lat: 48.530187, lng: 19.277495 },
    { lat: 48.530145, lng: 19.277521 },
    { lat: 48.529769, lng: 19.277867 },
    { lat: 48.5296, lng: 19.278157 },
    { lat: 48.529478, lng: 19.278541 },
    { lat: 48.529366, lng: 19.278881 },
    { lat: 48.529255, lng: 19.279098 },
    { lat: 48.528765, lng: 19.279617 },
    { lat: 48.528445, lng: 19.280295 },
    { lat: 48.528231, lng: 19.280815 },
    { lat: 48.528075, lng: 19.281286 },
    { lat: 48.52797, lng: 19.28172 },
    { lat: 48.52791, lng: 19.281877 },
    { lat: 48.527634, lng: 19.282237 },
    { lat: 48.52752, lng: 19.28236 },
    { lat: 48.527417, lng: 19.282464 },
    { lat: 48.527314, lng: 19.282691 },
    { lat: 48.527124, lng: 19.282922 },
    { lat: 48.527055, lng: 19.283025 },
    { lat: 48.526904, lng: 19.283152 },
    { lat: 48.526698, lng: 19.283392 },
    { lat: 48.52629, lng: 19.283758 },
    { lat: 48.526113, lng: 19.284123 },
    { lat: 48.526068, lng: 19.284155 },
    { lat: 48.524958, lng: 19.28495 },
    { lat: 48.524526, lng: 19.285764 },
    { lat: 48.524492, lng: 19.285847 },
    { lat: 48.524318, lng: 19.286153 },
    { lat: 48.523737, lng: 19.286513 },
    { lat: 48.522774, lng: 19.286923 },
    { lat: 48.521916, lng: 19.28718 },
    { lat: 48.521666, lng: 19.287585 },
    { lat: 48.521472, lng: 19.288217 },
    { lat: 48.521079, lng: 19.288904 },
    { lat: 48.520436, lng: 19.289893 },
    { lat: 48.520224, lng: 19.289769 },
    { lat: 48.519508, lng: 19.289408 },
    { lat: 48.5190456, lng: 19.2897821 },
    { lat: 48.5188024, lng: 19.2902776 },
    { lat: 48.5178791, lng: 19.2913956 },
    { lat: 48.5169025, lng: 19.2915744 },
    { lat: 48.5167097, lng: 19.2918084 },
    { lat: 48.5162246, lng: 19.2928792 },
    { lat: 48.5153896, lng: 19.2936518 },
    { lat: 48.5149616, lng: 19.2949467 },
    { lat: 48.5149264, lng: 19.2954142 },
    { lat: 48.5150619, lng: 19.2969132 },
    { lat: 48.515311, lng: 19.2984393 },
    { lat: 48.5151926, lng: 19.2987856 },
    { lat: 48.5149467, lng: 19.2990208 },
    { lat: 48.5137994, lng: 19.2989496 },
    { lat: 48.5132098, lng: 19.299092 },
    { lat: 48.5129883, lng: 19.2991958 },
    { lat: 48.51272, lng: 19.2994512 },
    { lat: 48.5155033, lng: 19.3026486 },
    { lat: 48.5158861, lng: 19.3025447 },
    { lat: 48.5156231, lng: 19.3018598 },
    { lat: 48.5167041, lng: 19.3015085 },
    { lat: 48.5170512, lng: 19.3018361 },
    { lat: 48.5179127, lng: 19.3015567 },
    { lat: 48.5198588, lng: 19.3013137 },
    { lat: 48.5198221, lng: 19.3014443 },
    { lat: 48.520255, lng: 19.3059464 },
    { lat: 48.5204805, lng: 19.3091409 },
    { lat: 48.5154709, lng: 19.3085927 },
    { lat: 48.5149458, lng: 19.3084739 },
    { lat: 48.5146981, lng: 19.3129419 },
    { lat: 48.5144756, lng: 19.3160593 },
    { lat: 48.5144615, lng: 19.3163072 },
    { lat: 48.5151984, lng: 19.3161102 },
    { lat: 48.5157923, lng: 19.3162053 },
    { lat: 48.5159935, lng: 19.3164273 },
    { lat: 48.5163975, lng: 19.3165576 },
    { lat: 48.5165545, lng: 19.3171513 },
    { lat: 48.5170475, lng: 19.3178184 },
    { lat: 48.5172156, lng: 19.3181965 },
    { lat: 48.517272, lng: 19.3186185 },
    { lat: 48.51721, lng: 19.3188588 },
    { lat: 48.51733, lng: 19.3191006 },
    { lat: 48.5173368, lng: 19.3195969 },
    { lat: 48.5174427, lng: 19.3203043 },
    { lat: 48.5175556, lng: 19.3206842 },
    { lat: 48.5177717, lng: 19.3222076 },
    { lat: 48.5174896, lng: 19.3228558 },
    { lat: 48.5178844, lng: 19.3232186 },
    { lat: 48.5181201, lng: 19.3245596 },
    { lat: 48.5180848, lng: 19.3251206 },
    { lat: 48.5184347, lng: 19.3269431 },
    { lat: 48.5194245, lng: 19.3336385 },
    { lat: 48.5162918, lng: 19.3343624 },
    { lat: 48.5147191, lng: 19.336029 },
    { lat: 48.5141595, lng: 19.3375262 },
    { lat: 48.5136331, lng: 19.3397441 },
    { lat: 48.5133141, lng: 19.3394825 },
    { lat: 48.5132173, lng: 19.3405719 },
    { lat: 48.5147726, lng: 19.3443486 },
    { lat: 48.5151074, lng: 19.347139 },
    { lat: 48.5146379, lng: 19.3479078 },
    { lat: 48.5150714, lng: 19.3483711 },
    { lat: 48.5166106, lng: 19.3494267 },
    { lat: 48.5186336, lng: 19.3485704 },
    { lat: 48.5186888, lng: 19.3486181 },
    { lat: 48.5194799, lng: 19.3482155 },
    { lat: 48.5202885, lng: 19.3483671 },
    { lat: 48.5203491, lng: 19.3505595 },
    { lat: 48.5204429, lng: 19.3509184 },
    { lat: 48.5205104, lng: 19.3531435 },
    { lat: 48.5214014, lng: 19.3536767 },
    { lat: 48.5220472, lng: 19.3538427 },
    { lat: 48.5230988, lng: 19.3540079 },
    { lat: 48.5238973, lng: 19.3540615 },
    { lat: 48.5241539, lng: 19.3540086 },
    { lat: 48.5263837, lng: 19.3529771 },
    { lat: 48.5266883, lng: 19.3527686 },
    { lat: 48.5272313, lng: 19.3520283 },
    { lat: 48.5276027, lng: 19.3518524 },
    { lat: 48.5290915, lng: 19.3516372 },
    { lat: 48.5302435, lng: 19.3513336 },
    { lat: 48.5317983, lng: 19.3504655 },
    { lat: 48.5323788, lng: 19.3500675 },
    { lat: 48.5346164, lng: 19.3478964 },
    { lat: 48.5351551, lng: 19.3472568 },
    { lat: 48.5352528, lng: 19.3472017 },
    { lat: 48.5363087, lng: 19.347577 },
    { lat: 48.5384058, lng: 19.3478056 },
    { lat: 48.5389103, lng: 19.3477009 },
    { lat: 48.5396816, lng: 19.3468575 },
    { lat: 48.5406127, lng: 19.344589 },
    { lat: 48.5406203, lng: 19.343614 },
    { lat: 48.5405558, lng: 19.3435149 },
    { lat: 48.5406765, lng: 19.3431142 },
    { lat: 48.5405329, lng: 19.3418255 },
    { lat: 48.5405387, lng: 19.3409086 },
    { lat: 48.5403611, lng: 19.3401845 },
    { lat: 48.5403104, lng: 19.3387459 },
    { lat: 48.5406525, lng: 19.3376756 },
    { lat: 48.541429, lng: 19.3367041 },
    { lat: 48.5415943, lng: 19.3365802 },
    { lat: 48.5420732, lng: 19.3364092 },
    { lat: 48.54293, lng: 19.3355865 },
    { lat: 48.5438314, lng: 19.334214 },
    { lat: 48.5440975, lng: 19.3339803 },
    { lat: 48.5450592, lng: 19.3335848 },
    { lat: 48.545695, lng: 19.3334198 },
    { lat: 48.545695, lng: 19.3334895 },
    { lat: 48.5453091, lng: 19.3335986 },
    { lat: 48.5452952, lng: 19.3337826 },
    { lat: 48.5456913, lng: 19.3337678 },
    { lat: 48.5456381, lng: 19.3345874 },
    { lat: 48.5455314, lng: 19.3349753 },
    { lat: 48.5456453, lng: 19.3355193 },
    { lat: 48.5456222, lng: 19.3367127 },
    { lat: 48.5456595, lng: 19.3371885 },
    { lat: 48.5455815, lng: 19.3376527 },
    { lat: 48.5450284, lng: 19.3385577 },
    { lat: 48.5447056, lng: 19.3395143 },
    { lat: 48.5446645, lng: 19.3408025 },
    { lat: 48.5445606, lng: 19.3420747 },
    { lat: 48.5444451, lng: 19.3428922 },
    { lat: 48.5434919, lng: 19.3486711 },
    { lat: 48.5431769, lng: 19.3503136 },
    { lat: 48.5430781, lng: 19.3520273 },
    { lat: 48.5431067, lng: 19.3537842 },
    { lat: 48.5429853, lng: 19.3548363 },
    { lat: 48.542618, lng: 19.3566897 },
    { lat: 48.5422934, lng: 19.3579656 },
    { lat: 48.5415594, lng: 19.3614934 },
    { lat: 48.541382, lng: 19.3629043 },
    { lat: 48.5413665, lng: 19.3640743 },
    { lat: 48.5414813, lng: 19.3651137 },
    { lat: 48.5416598, lng: 19.3660484 },
    { lat: 48.5438401, lng: 19.3747509 },
    { lat: 48.5439797, lng: 19.3757478 },
    { lat: 48.543891, lng: 19.3771356 },
    { lat: 48.5437286, lng: 19.3783436 },
    { lat: 48.5439085, lng: 19.3783114 },
    { lat: 48.5444936, lng: 19.3783887 },
    { lat: 48.5451548, lng: 19.3788551 },
    { lat: 48.5466453, lng: 19.3804606 },
    { lat: 48.5474568, lng: 19.3844125 },
    { lat: 48.547905, lng: 19.386063 },
  ],
  StaráHuta: [
    { lat: 48.4934134, lng: 19.3959958 },
    { lat: 48.4934864, lng: 19.3960164 },
    { lat: 48.494028, lng: 19.3957175 },
    { lat: 48.4943733, lng: 19.3956077 },
    { lat: 48.4945202, lng: 19.3955252 },
    { lat: 48.4947769, lng: 19.3953812 },
    { lat: 48.4952823, lng: 19.3950975 },
    { lat: 48.495893, lng: 19.394719 },
    { lat: 48.4963751, lng: 19.3944202 },
    { lat: 48.4964892, lng: 19.3943189 },
    { lat: 48.4970034, lng: 19.3938622 },
    { lat: 48.4970125, lng: 19.393803 },
    { lat: 48.4971493, lng: 19.3929159 },
    { lat: 48.4971046, lng: 19.3924015 },
    { lat: 48.496982, lng: 19.3918809 },
    { lat: 48.4971198, lng: 19.3912191 },
    { lat: 48.497013, lng: 19.3909185 },
    { lat: 48.4972306, lng: 19.3897534 },
    { lat: 48.4979061, lng: 19.3881164 },
    { lat: 48.4983308, lng: 19.3881626 },
    { lat: 48.4990575, lng: 19.3879891 },
    { lat: 48.5006617, lng: 19.3873758 },
    { lat: 48.5020233, lng: 19.3865951 },
    { lat: 48.50279, lng: 19.3859119 },
    { lat: 48.5035363, lng: 19.3853883 },
    { lat: 48.5038588, lng: 19.3853008 },
    { lat: 48.5043058, lng: 19.3847158 },
    { lat: 48.5063598, lng: 19.381709 },
    { lat: 48.5065809, lng: 19.3807825 },
    { lat: 48.5064557, lng: 19.3807067 },
    { lat: 48.5064147, lng: 19.3804396 },
    { lat: 48.5063573, lng: 19.3803708 },
    { lat: 48.5064283, lng: 19.3799658 },
    { lat: 48.5065931, lng: 19.3799495 },
    { lat: 48.5066512, lng: 19.3798644 },
    { lat: 48.5066226, lng: 19.3794505 },
    { lat: 48.5064312, lng: 19.3790744 },
    { lat: 48.5060214, lng: 19.3792986 },
    { lat: 48.5059385, lng: 19.3792389 },
    { lat: 48.4994625, lng: 19.3843817 },
    { lat: 48.4994061, lng: 19.3842136 },
    { lat: 48.4992231, lng: 19.3840588 },
    { lat: 48.4992351, lng: 19.3838379 },
    { lat: 48.4993023, lng: 19.3838061 },
    { lat: 48.4994108, lng: 19.3834292 },
    { lat: 48.4993255, lng: 19.38332 },
    { lat: 48.4994466, lng: 19.3829495 },
    { lat: 48.4994928, lng: 19.3823532 },
    { lat: 48.4994356, lng: 19.3818846 },
    { lat: 48.4995498, lng: 19.3815581 },
    { lat: 48.4993994, lng: 19.3815242 },
    { lat: 48.4989, lng: 19.3816169 },
    { lat: 48.498519, lng: 19.3819037 },
    { lat: 48.498462, lng: 19.3741635 },
    { lat: 48.5010156, lng: 19.372921 },
    { lat: 48.5008506, lng: 19.3723507 },
    { lat: 48.5002025, lng: 19.3701117 },
    { lat: 48.4977132, lng: 19.3627924 },
    { lat: 48.4972591, lng: 19.3595978 },
    { lat: 48.4965419, lng: 19.359023 },
    { lat: 48.4961733, lng: 19.3586431 },
    { lat: 48.4956467, lng: 19.3583523 },
    { lat: 48.4945569, lng: 19.3579527 },
    { lat: 48.4941952, lng: 19.3577628 },
    { lat: 48.4937872, lng: 19.3574337 },
    { lat: 48.4932246, lng: 19.3566742 },
    { lat: 48.4927382, lng: 19.3556653 },
    { lat: 48.4925704, lng: 19.3555043 },
    { lat: 48.4921466, lng: 19.3553144 },
    { lat: 48.49164, lng: 19.3553239 },
    { lat: 48.4913516, lng: 19.3556364 },
    { lat: 48.4910499, lng: 19.3558572 },
    { lat: 48.4903499, lng: 19.3561406 },
    { lat: 48.4899652, lng: 19.3558001 },
    { lat: 48.4893456, lng: 19.355099 },
    { lat: 48.4891471, lng: 19.3546894 },
    { lat: 48.4890365, lng: 19.3543116 },
    { lat: 48.4889395, lng: 19.353411 },
    { lat: 48.4888266, lng: 19.3531429 },
    { lat: 48.4885675, lng: 19.3527917 },
    { lat: 48.4882932, lng: 19.3526213 },
    { lat: 48.4882434, lng: 19.3524472 },
    { lat: 48.4880522, lng: 19.352223 },
    { lat: 48.4876376, lng: 19.3515355 },
    { lat: 48.4872845, lng: 19.3501833 },
    { lat: 48.4870302, lng: 19.3498867 },
    { lat: 48.4868773, lng: 19.349608 },
    { lat: 48.4866968, lng: 19.3495567 },
    { lat: 48.4865886, lng: 19.3492998 },
    { lat: 48.4863806, lng: 19.346854 },
    { lat: 48.4857102, lng: 19.3367534 },
    { lat: 48.4851812, lng: 19.3345059 },
    { lat: 48.4829459, lng: 19.3318388 },
    { lat: 48.4825367, lng: 19.3311104 },
    { lat: 48.4823459, lng: 19.3302777 },
    { lat: 48.4825137, lng: 19.3281772 },
    { lat: 48.4824593, lng: 19.3276538 },
    { lat: 48.4823409, lng: 19.3275575 },
    { lat: 48.4817982, lng: 19.3266511 },
    { lat: 48.481299, lng: 19.3259477 },
    { lat: 48.4808651, lng: 19.3251734 },
    { lat: 48.4803566, lng: 19.3236654 },
    { lat: 48.4801573, lng: 19.3232234 },
    { lat: 48.4798058, lng: 19.3222174 },
    { lat: 48.4794303, lng: 19.3215774 },
    { lat: 48.4791356, lng: 19.3206229 },
    { lat: 48.4781749, lng: 19.3189952 },
    { lat: 48.4777901, lng: 19.3171279 },
    { lat: 48.4776483, lng: 19.3156044 },
    { lat: 48.4772368, lng: 19.3147631 },
    { lat: 48.4765716, lng: 19.3128331 },
    { lat: 48.4755305, lng: 19.3109843 },
    { lat: 48.4748388, lng: 19.3100087 },
    { lat: 48.4740806, lng: 19.3092897 },
    { lat: 48.4726541, lng: 19.3075628 },
    { lat: 48.4718923, lng: 19.3074481 },
    { lat: 48.4710587, lng: 19.307717 },
    { lat: 48.4706748, lng: 19.3068601 },
    { lat: 48.4702722, lng: 19.306175 },
    { lat: 48.4698952, lng: 19.3053688 },
    { lat: 48.4694749, lng: 19.3042648 },
    { lat: 48.4687126, lng: 19.3028797 },
    { lat: 48.467991, lng: 19.3011841 },
    { lat: 48.4675051, lng: 19.2990785 },
    { lat: 48.4673124, lng: 19.2979317 },
    { lat: 48.4670637, lng: 19.2975648 },
    { lat: 48.4667426, lng: 19.2960949 },
    { lat: 48.4669101, lng: 19.2957346 },
    { lat: 48.4669657, lng: 19.2951816 },
    { lat: 48.466702, lng: 19.2943366 },
    { lat: 48.4664717, lng: 19.2908724 },
    { lat: 48.466182, lng: 19.2897483 },
    { lat: 48.4663861, lng: 19.2885589 },
    { lat: 48.4663679, lng: 19.2881556 },
    { lat: 48.4670434, lng: 19.2861781 },
    { lat: 48.4685856, lng: 19.2843145 },
    { lat: 48.4687397, lng: 19.2831188 },
    { lat: 48.4684163, lng: 19.2802465 },
    { lat: 48.467855, lng: 19.278721 },
    { lat: 48.466971, lng: 19.278984 },
    { lat: 48.466258, lng: 19.279044 },
    { lat: 48.466154, lng: 19.279058 },
    { lat: 48.465308, lng: 19.27909 },
    { lat: 48.464449, lng: 19.279113 },
    { lat: 48.463644, lng: 19.278997 },
    { lat: 48.463012, lng: 19.278877 },
    { lat: 48.461448, lng: 19.277722 },
    { lat: 48.459855, lng: 19.276648 },
    { lat: 48.459331, lng: 19.276202 },
    { lat: 48.459058, lng: 19.275937 },
    { lat: 48.459045, lng: 19.27596 },
    { lat: 48.458833, lng: 19.275731 },
    { lat: 48.458636, lng: 19.275602 },
    { lat: 48.458392, lng: 19.275438 },
    { lat: 48.458128, lng: 19.275287 },
    { lat: 48.457893, lng: 19.275153 },
    { lat: 48.457581, lng: 19.275083 },
    { lat: 48.457477, lng: 19.275046 },
    { lat: 48.45731, lng: 19.274922 },
    { lat: 48.457172, lng: 19.274873 },
    { lat: 48.456821, lng: 19.274739 },
    { lat: 48.456329, lng: 19.274677 },
    { lat: 48.455847, lng: 19.274653 },
    { lat: 48.455157, lng: 19.274722 },
    { lat: 48.454968, lng: 19.274732 },
    { lat: 48.454794, lng: 19.274695 },
    { lat: 48.454518, lng: 19.274659 },
    { lat: 48.454432, lng: 19.274637 },
    { lat: 48.454205, lng: 19.274562 },
    { lat: 48.453941, lng: 19.274452 },
    { lat: 48.453767, lng: 19.274378 },
    { lat: 48.453556, lng: 19.274341 },
    { lat: 48.453232, lng: 19.274357 },
    { lat: 48.452895, lng: 19.274337 },
    { lat: 48.452583, lng: 19.274336 },
    { lat: 48.452371, lng: 19.274352 },
    { lat: 48.452202, lng: 19.274398 },
    { lat: 48.452035, lng: 19.274363 },
    { lat: 48.451752, lng: 19.274286 },
    { lat: 48.451588, lng: 19.27427 },
    { lat: 48.451237, lng: 19.274313 },
    { lat: 48.451003, lng: 19.27428 },
    { lat: 48.450903, lng: 19.27427 },
    { lat: 48.450724, lng: 19.274288 },
    { lat: 48.450536, lng: 19.274343 },
    { lat: 48.450187, lng: 19.274448 },
    { lat: 48.450021, lng: 19.274538 },
    { lat: 48.449887, lng: 19.274653 },
    { lat: 48.449737, lng: 19.274685 },
    { lat: 48.449535, lng: 19.274677 },
    { lat: 48.44935, lng: 19.27472 },
    { lat: 48.449012, lng: 19.27487 },
    { lat: 48.448893, lng: 19.274932 },
    { lat: 48.448603, lng: 19.275043 },
    { lat: 48.448542, lng: 19.275063 },
    { lat: 48.448363, lng: 19.275092 },
    { lat: 48.448362, lng: 19.275116 },
    { lat: 48.446058, lng: 19.275309 },
    { lat: 48.4448, lng: 19.275404 },
    { lat: 48.444006, lng: 19.275639 },
    { lat: 48.443981, lng: 19.27564 },
    { lat: 48.444389, lng: 19.278726 },
    { lat: 48.444225, lng: 19.279926 },
    { lat: 48.443789, lng: 19.281962 },
    { lat: 48.442825, lng: 19.284215 },
    { lat: 48.442182, lng: 19.285704 },
    { lat: 48.441895, lng: 19.287848 },
    { lat: 48.441707, lng: 19.288892 },
    { lat: 48.442148, lng: 19.290743 },
    { lat: 48.443235, lng: 19.293954 },
    { lat: 48.443227, lng: 19.293967 },
    { lat: 48.442751, lng: 19.295114 },
    { lat: 48.441917, lng: 19.295606 },
    { lat: 48.441337, lng: 19.295938 },
    { lat: 48.439431, lng: 19.297233 },
    { lat: 48.439243, lng: 19.297803 },
    { lat: 48.439062, lng: 19.298199 },
    { lat: 48.438775, lng: 19.299595 },
    { lat: 48.438456, lng: 19.301435 },
    { lat: 48.438208, lng: 19.302466 },
    { lat: 48.438123, lng: 19.302834 },
    { lat: 48.438006, lng: 19.303417 },
    { lat: 48.43784, lng: 19.303761 },
    { lat: 48.437749, lng: 19.303967 },
    { lat: 48.43766, lng: 19.304534 },
    { lat: 48.437346, lng: 19.306177 },
    { lat: 48.437174, lng: 19.30699 },
    { lat: 48.437028, lng: 19.307737 },
    { lat: 48.436523, lng: 19.309411 },
    { lat: 48.437556, lng: 19.310067 },
    { lat: 48.438347, lng: 19.311398 },
    { lat: 48.4387703, lng: 19.3107421 },
    { lat: 48.438925, lng: 19.3102792 },
    { lat: 48.4389897, lng: 19.309821 },
    { lat: 48.4389578, lng: 19.3090846 },
    { lat: 48.4390985, lng: 19.3079334 },
    { lat: 48.4392394, lng: 19.3077254 },
    { lat: 48.4394077, lng: 19.3076407 },
    { lat: 48.439515, lng: 19.3075044 },
    { lat: 48.4398475, lng: 19.3074786 },
    { lat: 48.4400748, lng: 19.3075874 },
    { lat: 48.4408369, lng: 19.3084791 },
    { lat: 48.4409876, lng: 19.3084734 },
    { lat: 48.4410937, lng: 19.3085481 },
    { lat: 48.4417963, lng: 19.3093781 },
    { lat: 48.442301, lng: 19.3096967 },
    { lat: 48.4428593, lng: 19.3098459 },
    { lat: 48.4434376, lng: 19.309869 },
    { lat: 48.4434864, lng: 19.3096901 },
    { lat: 48.4436612, lng: 19.3095526 },
    { lat: 48.4437135, lng: 19.3091375 },
    { lat: 48.4436018, lng: 19.3084113 },
    { lat: 48.44341, lng: 19.3084978 },
    { lat: 48.4434398, lng: 19.3082545 },
    { lat: 48.4435122, lng: 19.3080937 },
    { lat: 48.443209, lng: 19.3068525 },
    { lat: 48.4431267, lng: 19.3059821 },
    { lat: 48.4431925, lng: 19.3055723 },
    { lat: 48.4435588, lng: 19.3050568 },
    { lat: 48.4439336, lng: 19.3051638 },
    { lat: 48.4442342, lng: 19.3054563 },
    { lat: 48.4449521, lng: 19.3059257 },
    { lat: 48.4451687, lng: 19.3060225 },
    { lat: 48.4453016, lng: 19.3058785 },
    { lat: 48.4454747, lng: 19.3060113 },
    { lat: 48.4455402, lng: 19.3059644 },
    { lat: 48.4456268, lng: 19.3061041 },
    { lat: 48.4457452, lng: 19.3053986 },
    { lat: 48.4462367, lng: 19.3056586 },
    { lat: 48.4465905, lng: 19.3064098 },
    { lat: 48.4469978, lng: 19.3064408 },
    { lat: 48.4470593, lng: 19.3059367 },
    { lat: 48.4475746, lng: 19.3056116 },
    { lat: 48.4477963, lng: 19.3052747 },
    { lat: 48.4489283, lng: 19.3045566 },
    { lat: 48.4485376, lng: 19.3034867 },
    { lat: 48.4496898, lng: 19.302864 },
    { lat: 48.4502133, lng: 19.3040675 },
    { lat: 48.4504107, lng: 19.3061096 },
    { lat: 48.4515857, lng: 19.3062095 },
    { lat: 48.4514885, lng: 19.3055256 },
    { lat: 48.4513636, lng: 19.3050595 },
    { lat: 48.451892, lng: 19.3043785 },
    { lat: 48.4520831, lng: 19.3044138 },
    { lat: 48.4522847, lng: 19.3039204 },
    { lat: 48.4524359, lng: 19.3027272 },
    { lat: 48.4524847, lng: 19.3027351 },
    { lat: 48.4516961, lng: 19.2983416 },
    { lat: 48.4550672, lng: 19.2992008 },
    { lat: 48.4568651, lng: 19.2997405 },
    { lat: 48.4571374, lng: 19.3018387 },
    { lat: 48.4542406, lng: 19.3070871 },
    { lat: 48.4544739, lng: 19.3076497 },
    { lat: 48.4547213, lng: 19.3077798 },
    { lat: 48.4548189, lng: 19.3084982 },
    { lat: 48.4550127, lng: 19.3080478 },
    { lat: 48.4553383, lng: 19.3066169 },
    { lat: 48.4558774, lng: 19.3055673 },
    { lat: 48.4567103, lng: 19.304464 },
    { lat: 48.4570381, lng: 19.3039436 },
    { lat: 48.4573939, lng: 19.3035615 },
    { lat: 48.4575631, lng: 19.3031901 },
    { lat: 48.4574332, lng: 19.3029504 },
    { lat: 48.457512, lng: 19.3028555 },
    { lat: 48.4576369, lng: 19.3030166 },
    { lat: 48.4579149, lng: 19.3028397 },
    { lat: 48.4588318, lng: 19.3011221 },
    { lat: 48.4592078, lng: 19.3008276 },
    { lat: 48.4594578, lng: 19.3004705 },
    { lat: 48.4595139, lng: 19.3005375 },
    { lat: 48.4594553, lng: 19.3006796 },
    { lat: 48.4596021, lng: 19.3007801 },
    { lat: 48.4595786, lng: 19.3008505 },
    { lat: 48.4596883, lng: 19.3010008 },
    { lat: 48.459835, lng: 19.3014035 },
    { lat: 48.4599794, lng: 19.3014969 },
    { lat: 48.4599755, lng: 19.3017466 },
    { lat: 48.4597754, lng: 19.3020596 },
    { lat: 48.459347, lng: 19.3036261 },
    { lat: 48.4592105, lng: 19.3047954 },
    { lat: 48.4591809, lng: 19.3057511 },
    { lat: 48.4592085, lng: 19.306138 },
    { lat: 48.4592879, lng: 19.3060072 },
    { lat: 48.4593264, lng: 19.3060552 },
    { lat: 48.4592243, lng: 19.3063732 },
    { lat: 48.4593557, lng: 19.3074846 },
    { lat: 48.4595769, lng: 19.3082433 },
    { lat: 48.4596064, lng: 19.3086918 },
    { lat: 48.4597324, lng: 19.30885 },
    { lat: 48.4598547, lng: 19.30938 },
    { lat: 48.4601745, lng: 19.30989 },
    { lat: 48.4604701, lng: 19.3099666 },
    { lat: 48.4603697, lng: 19.3102659 },
    { lat: 48.4607301, lng: 19.3107321 },
    { lat: 48.4607958, lng: 19.3106887 },
    { lat: 48.4615393, lng: 19.311363 },
    { lat: 48.4619188, lng: 19.3115433 },
    { lat: 48.4621639, lng: 19.3115554 },
    { lat: 48.4639634, lng: 19.3120618 },
    { lat: 48.4645474, lng: 19.3126728 },
    { lat: 48.4647493, lng: 19.3127904 },
    { lat: 48.4652744, lng: 19.3133773 },
    { lat: 48.4657242, lng: 19.3136836 },
    { lat: 48.4658682, lng: 19.3139575 },
    { lat: 48.4664129, lng: 19.3145728 },
    { lat: 48.4665769, lng: 19.3151495 },
    { lat: 48.4665842, lng: 19.3156069 },
    { lat: 48.4665332, lng: 19.3158834 },
    { lat: 48.4663217, lng: 19.3159842 },
    { lat: 48.4663043, lng: 19.3161139 },
    { lat: 48.466122, lng: 19.3162378 },
    { lat: 48.465724, lng: 19.3161684 },
    { lat: 48.464332, lng: 19.3156741 },
    { lat: 48.4640338, lng: 19.3156369 },
    { lat: 48.4634417, lng: 19.3157963 },
    { lat: 48.4630449, lng: 19.3163313 },
    { lat: 48.4630018, lng: 19.3164482 },
    { lat: 48.4630817, lng: 19.3166569 },
    { lat: 48.4630333, lng: 19.316726 },
    { lat: 48.4629676, lng: 19.3166007 },
    { lat: 48.4628866, lng: 19.3170733 },
    { lat: 48.4628789, lng: 19.3174055 },
    { lat: 48.4630051, lng: 19.3183475 },
    { lat: 48.4630377, lng: 19.3193774 },
    { lat: 48.4630112, lng: 19.3201438 },
    { lat: 48.4631083, lng: 19.3202182 },
    { lat: 48.4631198, lng: 19.3203839 },
    { lat: 48.4630673, lng: 19.3210082 },
    { lat: 48.4628741, lng: 19.3211126 },
    { lat: 48.4626284, lng: 19.3222192 },
    { lat: 48.4616284, lng: 19.3252699 },
    { lat: 48.4615684, lng: 19.3255704 },
    { lat: 48.4615676, lng: 19.3258596 },
    { lat: 48.4616659, lng: 19.3262193 },
    { lat: 48.461772, lng: 19.3261279 },
    { lat: 48.4618356, lng: 19.3262077 },
    { lat: 48.461858, lng: 19.3264872 },
    { lat: 48.4620546, lng: 19.3265448 },
    { lat: 48.4646928, lng: 19.3264384 },
    { lat: 48.4651487, lng: 19.3264746 },
    { lat: 48.4653747, lng: 19.3265584 },
    { lat: 48.4657623, lng: 19.3268147 },
    { lat: 48.4660322, lng: 19.3270835 },
    { lat: 48.4663264, lng: 19.3275304 },
    { lat: 48.4665159, lng: 19.327957 },
    { lat: 48.4667822, lng: 19.3287628 },
    { lat: 48.4664792, lng: 19.3290048 },
    { lat: 48.4664934, lng: 19.3290906 },
    { lat: 48.4664237, lng: 19.3292331 },
    { lat: 48.4665674, lng: 19.3297624 },
    { lat: 48.4666452, lng: 19.3302572 },
    { lat: 48.4668092, lng: 19.3305563 },
    { lat: 48.4664424, lng: 19.3313195 },
    { lat: 48.4663979, lng: 19.3314955 },
    { lat: 48.4663636, lng: 19.3321715 },
    { lat: 48.4666847, lng: 19.3324258 },
    { lat: 48.4664031, lng: 19.3331837 },
    { lat: 48.4661747, lng: 19.3335433 },
    { lat: 48.4656373, lng: 19.3337641 },
    { lat: 48.4655733, lng: 19.3339823 },
    { lat: 48.4658471, lng: 19.334472 },
    { lat: 48.4659023, lng: 19.3344518 },
    { lat: 48.4658754, lng: 19.3348525 },
    { lat: 48.4661419, lng: 19.3355453 },
    { lat: 48.466216, lng: 19.3358442 },
    { lat: 48.4662366, lng: 19.3362315 },
    { lat: 48.466539, lng: 19.3374621 },
    { lat: 48.4665812, lng: 19.3382514 },
    { lat: 48.4665122, lng: 19.3384343 },
    { lat: 48.4665464, lng: 19.3388436 },
    { lat: 48.4664329, lng: 19.3395119 },
    { lat: 48.4664422, lng: 19.3399882 },
    { lat: 48.4664029, lng: 19.3401345 },
    { lat: 48.4662867, lng: 19.3404369 },
    { lat: 48.4659605, lng: 19.3408305 },
    { lat: 48.4658134, lng: 19.3410985 },
    { lat: 48.4656819, lng: 19.341248 },
    { lat: 48.4653709, lng: 19.341418 },
    { lat: 48.4653708, lng: 19.3416726 },
    { lat: 48.4651798, lng: 19.3420662 },
    { lat: 48.4649295, lng: 19.3423919 },
    { lat: 48.4647137, lng: 19.3424049 },
    { lat: 48.4644475, lng: 19.3425175 },
    { lat: 48.4642998, lng: 19.3427543 },
    { lat: 48.4641089, lng: 19.3428666 },
    { lat: 48.463902, lng: 19.3431087 },
    { lat: 48.4640915, lng: 19.3435602 },
    { lat: 48.4643728, lng: 19.3437184 },
    { lat: 48.4644669, lng: 19.3439629 },
    { lat: 48.4643659, lng: 19.3440434 },
    { lat: 48.4643691, lng: 19.3441095 },
    { lat: 48.4636967, lng: 19.3442879 },
    { lat: 48.463263, lng: 19.3441742 },
    { lat: 48.4621829, lng: 19.3441893 },
    { lat: 48.4615372, lng: 19.3439568 },
    { lat: 48.4606095, lng: 19.3440765 },
    { lat: 48.4603825, lng: 19.3441851 },
    { lat: 48.4598923, lng: 19.3447253 },
    { lat: 48.459177, lng: 19.3458209 },
    { lat: 48.4593126, lng: 19.3461824 },
    { lat: 48.4594541, lng: 19.3462686 },
    { lat: 48.4595837, lng: 19.3465245 },
    { lat: 48.4597824, lng: 19.3462289 },
    { lat: 48.4601508, lng: 19.3472361 },
    { lat: 48.4597873, lng: 19.3478893 },
    { lat: 48.4595775, lng: 19.3485472 },
    { lat: 48.4595968, lng: 19.3487383 },
    { lat: 48.4596629, lng: 19.3488462 },
    { lat: 48.4596154, lng: 19.3491027 },
    { lat: 48.4599567, lng: 19.3496188 },
    { lat: 48.4601869, lng: 19.3497204 },
    { lat: 48.4601255, lng: 19.3502256 },
    { lat: 48.4599508, lng: 19.3502274 },
    { lat: 48.4596096, lng: 19.3500909 },
    { lat: 48.459587, lng: 19.3502815 },
    { lat: 48.4610338, lng: 19.3513088 },
    { lat: 48.4613511, lng: 19.3518214 },
    { lat: 48.4615529, lng: 19.3524137 },
    { lat: 48.4616094, lng: 19.3527954 },
    { lat: 48.4613022, lng: 19.3528757 },
    { lat: 48.4606291, lng: 19.3534859 },
    { lat: 48.4600476, lng: 19.3537684 },
    { lat: 48.4595598, lng: 19.3534442 },
    { lat: 48.4593498, lng: 19.3534587 },
    { lat: 48.4589755, lng: 19.3560369 },
    { lat: 48.4587786, lng: 19.3558546 },
    { lat: 48.458544, lng: 19.3554722 },
    { lat: 48.4583728, lng: 19.3553344 },
    { lat: 48.4582476, lng: 19.3555422 },
    { lat: 48.4586127, lng: 19.355941 },
    { lat: 48.4585386, lng: 19.3563055 },
    { lat: 48.4582924, lng: 19.3564822 },
    { lat: 48.4582176, lng: 19.3569232 },
    { lat: 48.4584398, lng: 19.3569276 },
    { lat: 48.4587253, lng: 19.3574089 },
    { lat: 48.4585455, lng: 19.3575853 },
    { lat: 48.4580595, lng: 19.35834 },
    { lat: 48.4579723, lng: 19.3577148 },
    { lat: 48.4579746, lng: 19.3570047 },
    { lat: 48.4576531, lng: 19.3567844 },
    { lat: 48.4572743, lng: 19.3567599 },
    { lat: 48.4570214, lng: 19.356965 },
    { lat: 48.456842, lng: 19.3568877 },
    { lat: 48.456806, lng: 19.356985 },
    { lat: 48.456297, lng: 19.3568751 },
    { lat: 48.4558204, lng: 19.3562663 },
    { lat: 48.4556127, lng: 19.3550548 },
    { lat: 48.4555205, lng: 19.3549646 },
    { lat: 48.4554518, lng: 19.3551034 },
    { lat: 48.4555777, lng: 19.3556874 },
    { lat: 48.4549004, lng: 19.3558583 },
    { lat: 48.4542081, lng: 19.3556144 },
    { lat: 48.4541788, lng: 19.3557326 },
    { lat: 48.4543566, lng: 19.3561541 },
    { lat: 48.4540944, lng: 19.3567515 },
    { lat: 48.4539122, lng: 19.3573541 },
    { lat: 48.453569, lng: 19.3574334 },
    { lat: 48.4534828, lng: 19.3577799 },
    { lat: 48.4530604, lng: 19.3577398 },
    { lat: 48.4531495, lng: 19.3573589 },
    { lat: 48.452926, lng: 19.3572825 },
    { lat: 48.4529418, lng: 19.356855 },
    { lat: 48.4522666, lng: 19.356976 },
    { lat: 48.4524277, lng: 19.3578481 },
    { lat: 48.4552925, lng: 19.3579852 },
    { lat: 48.4554878, lng: 19.3591008 },
    { lat: 48.4557574, lng: 19.3599616 },
    { lat: 48.4559096, lng: 19.3595571 },
    { lat: 48.4568053, lng: 19.3594799 },
    { lat: 48.4570877, lng: 19.3593742 },
    { lat: 48.4571697, lng: 19.359865 },
    { lat: 48.4572441, lng: 19.3598907 },
    { lat: 48.4573796, lng: 19.3604144 },
    { lat: 48.4575913, lng: 19.3619043 },
    { lat: 48.4580052, lng: 19.3618619 },
    { lat: 48.4581515, lng: 19.3624491 },
    { lat: 48.4573626, lng: 19.3632076 },
    { lat: 48.457301, lng: 19.363477 },
    { lat: 48.4570362, lng: 19.3639518 },
    { lat: 48.456797, lng: 19.3642062 },
    { lat: 48.4572393, lng: 19.3655197 },
    { lat: 48.4569279, lng: 19.3661539 },
    { lat: 48.4567978, lng: 19.3666867 },
    { lat: 48.4569717, lng: 19.3668114 },
    { lat: 48.4569769, lng: 19.3668759 },
    { lat: 48.456416, lng: 19.367094 },
    { lat: 48.456451, lng: 19.367863 },
    { lat: 48.456523, lng: 19.368606 },
    { lat: 48.456545, lng: 19.36896 },
    { lat: 48.456516, lng: 19.369958 },
    { lat: 48.456484, lng: 19.370645 },
    { lat: 48.456488, lng: 19.370953 },
    { lat: 48.456493, lng: 19.37109 },
    { lat: 48.456501, lng: 19.371383 },
    { lat: 48.456535, lng: 19.371732 },
    { lat: 48.456603, lng: 19.37351 },
    { lat: 48.456629, lng: 19.374301 },
    { lat: 48.456681, lng: 19.375555 },
    { lat: 48.45674, lng: 19.376693 },
    { lat: 48.456806, lng: 19.377632 },
    { lat: 48.456846, lng: 19.378477 },
    { lat: 48.456878, lng: 19.379228 },
    { lat: 48.456939, lng: 19.379976 },
    { lat: 48.456952, lng: 19.380302 },
    { lat: 48.456956, lng: 19.380617 },
    { lat: 48.456971, lng: 19.381316 },
    { lat: 48.456991, lng: 19.382033 },
    { lat: 48.457079, lng: 19.383675 },
    { lat: 48.457083, lng: 19.383737 },
    { lat: 48.457172, lng: 19.385566 },
    { lat: 48.457208, lng: 19.386298 },
    { lat: 48.457239, lng: 19.386564 },
    { lat: 48.45724, lng: 19.386969 },
    { lat: 48.457297, lng: 19.388221 },
    { lat: 48.457398, lng: 19.390275 },
    { lat: 48.457552, lng: 19.393067 },
    { lat: 48.4576, lng: 19.393811 },
    { lat: 48.457777, lng: 19.395169 },
    { lat: 48.457783, lng: 19.395239 },
    { lat: 48.457808, lng: 19.395754 },
    { lat: 48.457816, lng: 19.395819 },
    { lat: 48.457833, lng: 19.395809 },
    { lat: 48.457848, lng: 19.395788 },
    { lat: 48.45785, lng: 19.395566 },
    { lat: 48.457855, lng: 19.39553 },
    { lat: 48.458073, lng: 19.395018 },
    { lat: 48.458101, lng: 19.395011 },
    { lat: 48.458277, lng: 19.395046 },
    { lat: 48.45831, lng: 19.395042 },
    { lat: 48.458651, lng: 19.394593 },
    { lat: 48.458968, lng: 19.394382 },
    { lat: 48.459242, lng: 19.394053 },
    { lat: 48.459399, lng: 19.39394 },
    { lat: 48.45993, lng: 19.393076 },
    { lat: 48.460705, lng: 19.392436 },
    { lat: 48.461623, lng: 19.39077 },
    { lat: 48.461636, lng: 19.390736 },
    { lat: 48.46164, lng: 19.390612 },
    { lat: 48.46182, lng: 19.390503 },
    { lat: 48.461832, lng: 19.390487 },
    { lat: 48.462147, lng: 19.389893 },
    { lat: 48.462211, lng: 19.389 },
    { lat: 48.462219, lng: 19.388954 },
    { lat: 48.462382, lng: 19.388496 },
    { lat: 48.462458, lng: 19.388336 },
    { lat: 48.462593, lng: 19.387672 },
    { lat: 48.462699, lng: 19.387454 },
    { lat: 48.462924, lng: 19.386996 },
    { lat: 48.463111, lng: 19.386679 },
    { lat: 48.463188, lng: 19.386195 },
    { lat: 48.463392, lng: 19.385932 },
    { lat: 48.463856, lng: 19.385113 },
    { lat: 48.463883, lng: 19.384547 },
    { lat: 48.464484, lng: 19.383987 },
    { lat: 48.465025, lng: 19.383692 },
    { lat: 48.465094, lng: 19.383552 },
    { lat: 48.465106, lng: 19.383543 },
    { lat: 48.465205, lng: 19.383555 },
    { lat: 48.465314, lng: 19.383411 },
    { lat: 48.46534, lng: 19.383383 },
    { lat: 48.465838, lng: 19.383208 },
    { lat: 48.466039, lng: 19.383071 },
    { lat: 48.466059, lng: 19.383067 },
    { lat: 48.466347, lng: 19.383201 },
    { lat: 48.466753, lng: 19.38318 },
    { lat: 48.466986, lng: 19.383091 },
    { lat: 48.467499, lng: 19.382657 },
    { lat: 48.467635, lng: 19.382681 },
    { lat: 48.467653, lng: 19.382723 },
    { lat: 48.468622, lng: 19.384324 },
    { lat: 48.474365, lng: 19.393792 },
    { lat: 48.474979, lng: 19.394805 },
    { lat: 48.475009, lng: 19.394761 },
    { lat: 48.475259, lng: 19.394031 },
    { lat: 48.4755, lng: 19.393346 },
    { lat: 48.475752, lng: 19.392597 },
    { lat: 48.475922, lng: 19.39211 },
    { lat: 48.47605, lng: 19.391773 },
    { lat: 48.47623, lng: 19.391304 },
    { lat: 48.476315, lng: 19.39109 },
    { lat: 48.476553, lng: 19.390454 },
    { lat: 48.47659, lng: 19.390357 },
    { lat: 48.476723, lng: 19.390012 },
    { lat: 48.476753, lng: 19.389923 },
    { lat: 48.476916, lng: 19.38951 },
    { lat: 48.477426, lng: 19.388139 },
    { lat: 48.477511, lng: 19.387909 },
    { lat: 48.477927, lng: 19.386824 },
    { lat: 48.478363, lng: 19.385687 },
    { lat: 48.478389, lng: 19.385611 },
    { lat: 48.478457, lng: 19.385424 },
    { lat: 48.478743, lng: 19.384677 },
    { lat: 48.478882, lng: 19.384309 },
    { lat: 48.479041, lng: 19.383854 },
    { lat: 48.479332, lng: 19.383019 },
    { lat: 48.479384, lng: 19.382859 },
    { lat: 48.479675, lng: 19.382007 },
    { lat: 48.480053, lng: 19.380929 },
    { lat: 48.480495, lng: 19.379638 },
    { lat: 48.48106, lng: 19.37799 },
    { lat: 48.481109, lng: 19.377848 },
    { lat: 48.481189, lng: 19.37696 },
    { lat: 48.481213, lng: 19.376745 },
    { lat: 48.481244, lng: 19.376437 },
    { lat: 48.48128, lng: 19.376066 },
    { lat: 48.481361, lng: 19.375254 },
    { lat: 48.481541, lng: 19.373334 },
    { lat: 48.481699, lng: 19.371602 },
    { lat: 48.481708, lng: 19.371484 },
    { lat: 48.481724, lng: 19.371309 },
    { lat: 48.482491, lng: 19.371306 },
    { lat: 48.482785, lng: 19.371001 },
    { lat: 48.483093, lng: 19.370866 },
    { lat: 48.483536, lng: 19.370665 },
    { lat: 48.483685, lng: 19.370649 },
    { lat: 48.483728, lng: 19.370644 },
    { lat: 48.483817, lng: 19.370639 },
    { lat: 48.483872, lng: 19.370635 },
    { lat: 48.483993, lng: 19.370624 },
    { lat: 48.484035, lng: 19.370621 },
    { lat: 48.48431, lng: 19.369198 },
    { lat: 48.484304, lng: 19.36901 },
    { lat: 48.484258, lng: 19.368824 },
    { lat: 48.484266, lng: 19.368526 },
    { lat: 48.484462, lng: 19.368002 },
    { lat: 48.484983, lng: 19.36702 },
    { lat: 48.485667, lng: 19.366492 },
    { lat: 48.486371, lng: 19.365782 },
    { lat: 48.487073, lng: 19.364696 },
    { lat: 48.487458, lng: 19.364368 },
    { lat: 48.487935, lng: 19.363775 },
    { lat: 48.48815, lng: 19.363555 },
    { lat: 48.488313, lng: 19.364888 },
    { lat: 48.488425, lng: 19.36667 },
    { lat: 48.488441, lng: 19.366989 },
    { lat: 48.488511, lng: 19.367567 },
    { lat: 48.488575, lng: 19.367934 },
    { lat: 48.488569, lng: 19.368317 },
    { lat: 48.488723, lng: 19.369983 },
    { lat: 48.488824, lng: 19.370994 },
    { lat: 48.488461, lng: 19.373994 },
    { lat: 48.488369, lng: 19.374844 },
    { lat: 48.488179, lng: 19.376566 },
    { lat: 48.487894, lng: 19.379101 },
    { lat: 48.487629, lng: 19.381392 },
    { lat: 48.4876071, lng: 19.3815566 },
    { lat: 48.487356, lng: 19.383442 },
    { lat: 48.487207, lng: 19.384652 },
    { lat: 48.487066, lng: 19.385716 },
    { lat: 48.486994, lng: 19.386313 },
    { lat: 48.486988, lng: 19.386358 },
    { lat: 48.48697, lng: 19.386498 },
    { lat: 48.486963, lng: 19.386548 },
    { lat: 48.486887, lng: 19.387156 },
    { lat: 48.486871, lng: 19.387264 },
    { lat: 48.486654, lng: 19.388857 },
    { lat: 48.486477, lng: 19.3902 },
    { lat: 48.48638, lng: 19.390912 },
    { lat: 48.486302, lng: 19.391522 },
    { lat: 48.486247, lng: 19.392004 },
    { lat: 48.486098, lng: 19.393228 },
    { lat: 48.486078, lng: 19.39342 },
    { lat: 48.4872841, lng: 19.3932736 },
    { lat: 48.487503, lng: 19.392708 },
    { lat: 48.487727, lng: 19.3918211 },
    { lat: 48.4880975, lng: 19.3908122 },
    { lat: 48.4889106, lng: 19.3905862 },
    { lat: 48.4910983, lng: 19.3916107 },
    { lat: 48.4916444, lng: 19.3917917 },
    { lat: 48.4917417, lng: 19.3927292 },
    { lat: 48.4919504, lng: 19.3928518 },
    { lat: 48.4921335, lng: 19.3930539 },
    { lat: 48.4924078, lng: 19.39356 },
    { lat: 48.4926614, lng: 19.3938978 },
    { lat: 48.492618, lng: 19.394289 },
    { lat: 48.4930421, lng: 19.394513 },
    { lat: 48.4936178, lng: 19.3950557 },
    { lat: 48.4934134, lng: 19.3959958 },
  ],
  Látky: [
    { lat: 48.624893, lng: 19.637798 },
    { lat: 48.6241182, lng: 19.6375273 },
    { lat: 48.6237715, lng: 19.6377612 },
    { lat: 48.6231728, lng: 19.6383518 },
    { lat: 48.6225567, lng: 19.6391312 },
    { lat: 48.6221735, lng: 19.6394041 },
    { lat: 48.6217894, lng: 19.6396365 },
    { lat: 48.6211833, lng: 19.6397367 },
    { lat: 48.6205072, lng: 19.6399395 },
    { lat: 48.6198851, lng: 19.6402853 },
    { lat: 48.6195408, lng: 19.6407472 },
    { lat: 48.6191076, lng: 19.6411566 },
    { lat: 48.6188016, lng: 19.6416975 },
    { lat: 48.6184924, lng: 19.6420632 },
    { lat: 48.6179591, lng: 19.6422792 },
    { lat: 48.6174739, lng: 19.6420917 },
    { lat: 48.6170613, lng: 19.642036 },
    { lat: 48.6164872, lng: 19.6422479 },
    { lat: 48.6163224, lng: 19.6421907 },
    { lat: 48.6161249, lng: 19.6419068 },
    { lat: 48.6157446, lng: 19.641121 },
    { lat: 48.6154124, lng: 19.6386248 },
    { lat: 48.6151842, lng: 19.6383046 },
    { lat: 48.6147969, lng: 19.6382649 },
    { lat: 48.6140471, lng: 19.6381323 },
    { lat: 48.6136817, lng: 19.6374732 },
    { lat: 48.6131586, lng: 19.6370945 },
    { lat: 48.6126973, lng: 19.6368815 },
    { lat: 48.6120092, lng: 19.6385152 },
    { lat: 48.6114704, lng: 19.6390604 },
    { lat: 48.6110237, lng: 19.6397004 },
    { lat: 48.6103908, lng: 19.6404329 },
    { lat: 48.6093453, lng: 19.6414878 },
    { lat: 48.6090356, lng: 19.6418666 },
    { lat: 48.6083322, lng: 19.6438916 },
    { lat: 48.6080266, lng: 19.6441354 },
    { lat: 48.6076558, lng: 19.6440804 },
    { lat: 48.6073348, lng: 19.643612 },
    { lat: 48.6065106, lng: 19.6434449 },
    { lat: 48.6059346, lng: 19.6434437 },
    { lat: 48.6051365, lng: 19.6440089 },
    { lat: 48.6043365, lng: 19.6440868 },
    { lat: 48.6035866, lng: 19.6444751 },
    { lat: 48.6025971, lng: 19.6446973 },
    { lat: 48.6028534, lng: 19.6431776 },
    { lat: 48.6029149, lng: 19.6420428 },
    { lat: 48.6030713, lng: 19.6413371 },
    { lat: 48.6029601, lng: 19.6409434 },
    { lat: 48.6020219, lng: 19.6396514 },
    { lat: 48.6009582, lng: 19.6378492 },
    { lat: 48.6005813, lng: 19.6373884 },
    { lat: 48.6002597, lng: 19.6368062 },
    { lat: 48.5995665, lng: 19.6358281 },
    { lat: 48.5989993, lng: 19.6354647 },
    { lat: 48.5984929, lng: 19.6353051 },
    { lat: 48.5981418, lng: 19.6351147 },
    { lat: 48.5976161, lng: 19.6346538 },
    { lat: 48.5966293, lng: 19.6344841 },
    { lat: 48.5948268, lng: 19.6365436 },
    { lat: 48.5942477, lng: 19.6369429 },
    { lat: 48.5936511, lng: 19.636254 },
    { lat: 48.5924767, lng: 19.6353998 },
    { lat: 48.5922903, lng: 19.6353619 },
    { lat: 48.5919326, lng: 19.6351259 },
    { lat: 48.5908259, lng: 19.6347962 },
    { lat: 48.5896663, lng: 19.6342982 },
    { lat: 48.5892134, lng: 19.6343976 },
    { lat: 48.5887779, lng: 19.6346633 },
    { lat: 48.5885784, lng: 19.6349357 },
    { lat: 48.5876666, lng: 19.6357011 },
    { lat: 48.5864118, lng: 19.6353399 },
    { lat: 48.5857158, lng: 19.6354728 },
    { lat: 48.5855344, lng: 19.6353971 },
    { lat: 48.5853694, lng: 19.6352631 },
    { lat: 48.5849277, lng: 19.6346101 },
    { lat: 48.5846967, lng: 19.6343622 },
    { lat: 48.5844737, lng: 19.6338423 },
    { lat: 48.5838884, lng: 19.6319531 },
    { lat: 48.5837847, lng: 19.6305808 },
    { lat: 48.5821624, lng: 19.6304368 },
    { lat: 48.5815321, lng: 19.6295826 },
    { lat: 48.5811932, lng: 19.6293142 },
    { lat: 48.5804049, lng: 19.6293217 },
    { lat: 48.5796345, lng: 19.6290303 },
    { lat: 48.5792038, lng: 19.6291232 },
    { lat: 48.5783342, lng: 19.6295269 },
    { lat: 48.5781565, lng: 19.6294619 },
    { lat: 48.5781443, lng: 19.6296154 },
    { lat: 48.5773091, lng: 19.6299584 },
    { lat: 48.5760811, lng: 19.628914 },
    { lat: 48.5752224, lng: 19.6278445 },
    { lat: 48.5749295, lng: 19.6269686 },
    { lat: 48.5747839, lng: 19.6261919 },
    { lat: 48.5748469, lng: 19.6252418 },
    { lat: 48.5747575, lng: 19.6244653 },
    { lat: 48.5746664, lng: 19.6238708 },
    { lat: 48.5743949, lng: 19.6229006 },
    { lat: 48.573171, lng: 19.6221933 },
    { lat: 48.5727846, lng: 19.6218 },
    { lat: 48.5725551, lng: 19.6214079 },
    { lat: 48.5724788, lng: 19.6211158 },
    { lat: 48.5724913, lng: 19.6194501 },
    { lat: 48.5724458, lng: 19.6191694 },
    { lat: 48.5722409, lng: 19.61855 },
    { lat: 48.5722558, lng: 19.6183567 },
    { lat: 48.5718518, lng: 19.6172465 },
    { lat: 48.5701312, lng: 19.6132171 },
    { lat: 48.569901, lng: 19.6119916 },
    { lat: 48.5698605, lng: 19.6114425 },
    { lat: 48.5698877, lng: 19.6111736 },
    { lat: 48.5702145, lng: 19.6104533 },
    { lat: 48.5702646, lng: 19.6101428 },
    { lat: 48.5701876, lng: 19.6098597 },
    { lat: 48.5698787, lng: 19.6091351 },
    { lat: 48.5692766, lng: 19.6083073 },
    { lat: 48.5688862, lng: 19.6080518 },
    { lat: 48.5670957, lng: 19.6084467 },
    { lat: 48.5665039, lng: 19.6084141 },
    { lat: 48.5657833, lng: 19.6078842 },
    { lat: 48.5653352, lng: 19.6074703 },
    { lat: 48.5631398, lng: 19.6029671 },
    { lat: 48.5629066, lng: 19.602323 },
    { lat: 48.5626535, lng: 19.6011634 },
    { lat: 48.5626066, lng: 19.6006303 },
    { lat: 48.5622097, lng: 19.5999566 },
    { lat: 48.5618096, lng: 19.5990331 },
    { lat: 48.5617655, lng: 19.5990308 },
    { lat: 48.5616903, lng: 19.5973026 },
    { lat: 48.561584, lng: 19.5966988 },
    { lat: 48.5607585, lng: 19.5950484 },
    { lat: 48.5607195, lng: 19.5947878 },
    { lat: 48.5608242, lng: 19.5922096 },
    { lat: 48.5604969, lng: 19.5907871 },
    { lat: 48.560254, lng: 19.5900053 },
    { lat: 48.5602861, lng: 19.5887278 },
    { lat: 48.5601797, lng: 19.5869107 },
    { lat: 48.5601395, lng: 19.5866708 },
    { lat: 48.5599926, lng: 19.5862666 },
    { lat: 48.5596016, lng: 19.585401 },
    { lat: 48.5591703, lng: 19.5848376 },
    { lat: 48.5583612, lng: 19.5843955 },
    { lat: 48.5575813, lng: 19.5837754 },
    { lat: 48.557213, lng: 19.5836769 },
    { lat: 48.5563261, lng: 19.5836047 },
    { lat: 48.5555181, lng: 19.5833578 },
    { lat: 48.5553532, lng: 19.5832464 },
    { lat: 48.5549912, lng: 19.5827958 },
    { lat: 48.5546287, lng: 19.5826302 },
    { lat: 48.5529621, lng: 19.5830025 },
    { lat: 48.552261, lng: 19.5828011 },
    { lat: 48.5516075, lng: 19.5824092 },
    { lat: 48.5511863, lng: 19.5823133 },
    { lat: 48.550709, lng: 19.5824705 },
    { lat: 48.5497043, lng: 19.5832494 },
    { lat: 48.5493579, lng: 19.5832639 },
    { lat: 48.5491893, lng: 19.5831714 },
    { lat: 48.5488398, lng: 19.5828228 },
    { lat: 48.5482068, lng: 19.5819913 },
    { lat: 48.5479809, lng: 19.5815061 },
    { lat: 48.5478452, lng: 19.5805343 },
    { lat: 48.5477992, lng: 19.5788113 },
    { lat: 48.5476613, lng: 19.5784816 },
    { lat: 48.5475147, lng: 19.5783528 },
    { lat: 48.5454496, lng: 19.577352 },
    { lat: 48.5452314, lng: 19.5770403 },
    { lat: 48.5445174, lng: 19.5754958 },
    { lat: 48.544276, lng: 19.5752409 },
    { lat: 48.5438451, lng: 19.5749619 },
    { lat: 48.5432773, lng: 19.5749137 },
    { lat: 48.5424837, lng: 19.5751317 },
    { lat: 48.5420934, lng: 19.5751682 },
    { lat: 48.541557, lng: 19.5753927 },
    { lat: 48.5409511, lng: 19.5757438 },
    { lat: 48.5397182, lng: 19.5760646 },
    { lat: 48.5395391, lng: 19.5761642 },
    { lat: 48.5390474, lng: 19.57623 },
    { lat: 48.538605, lng: 19.5760166 },
    { lat: 48.5371068, lng: 19.5763271 },
    { lat: 48.536962, lng: 19.576146 },
    { lat: 48.537029, lng: 19.577199 },
    { lat: 48.537072, lng: 19.578236 },
    { lat: 48.537076, lng: 19.578318 },
    { lat: 48.537135, lng: 19.578946 },
    { lat: 48.537135, lng: 19.579481 },
    { lat: 48.537089, lng: 19.580384 },
    { lat: 48.537111, lng: 19.580469 },
    { lat: 48.53725, lng: 19.58101 },
    { lat: 48.537542, lng: 19.581866 },
    { lat: 48.537754, lng: 19.582264 },
    { lat: 48.537981, lng: 19.583231 },
    { lat: 48.538051, lng: 19.583743 },
    { lat: 48.538066, lng: 19.584229 },
    { lat: 48.537976, lng: 19.584755 },
    { lat: 48.537884, lng: 19.585073 },
    { lat: 48.537619, lng: 19.58584 },
    { lat: 48.537494, lng: 19.586652 },
    { lat: 48.537425, lng: 19.587097 },
    { lat: 48.537203, lng: 19.588211 },
    { lat: 48.537106, lng: 19.588748 },
    { lat: 48.537051, lng: 19.588939 },
    { lat: 48.536921, lng: 19.589308 },
    { lat: 48.536805, lng: 19.589886 },
    { lat: 48.536709, lng: 19.590446 },
    { lat: 48.536584, lng: 19.590952 },
    { lat: 48.536473, lng: 19.591343 },
    { lat: 48.536407, lng: 19.59156 },
    { lat: 48.536451, lng: 19.591755 },
    { lat: 48.536567, lng: 19.591975 },
    { lat: 48.536792, lng: 19.592202 },
    { lat: 48.536916, lng: 19.592392 },
    { lat: 48.537353, lng: 19.593404 },
    { lat: 48.537512, lng: 19.593881 },
    { lat: 48.537611, lng: 19.594114 },
    { lat: 48.537683, lng: 19.594388 },
    { lat: 48.53774, lng: 19.594814 },
    { lat: 48.53783, lng: 19.595885 },
    { lat: 48.537856, lng: 19.597137 },
    { lat: 48.537971, lng: 19.598237 },
    { lat: 48.538203, lng: 19.599531 },
    { lat: 48.538256, lng: 19.599891 },
    { lat: 48.538452, lng: 19.601227 },
    { lat: 48.538468, lng: 19.601812 },
    { lat: 48.538467, lng: 19.602625 },
    { lat: 48.538494, lng: 19.602918 },
    { lat: 48.538565, lng: 19.603172 },
    { lat: 48.538737, lng: 19.603553 },
    { lat: 48.538922, lng: 19.603803 },
    { lat: 48.539112, lng: 19.603922 },
    { lat: 48.539286, lng: 19.603974 },
    { lat: 48.539368, lng: 19.604048 },
    { lat: 48.539419, lng: 19.604124 },
    { lat: 48.539654, lng: 19.60508 },
    { lat: 48.539654, lng: 19.605393 },
    { lat: 48.539481, lng: 19.606723 },
    { lat: 48.539414, lng: 19.607241 },
    { lat: 48.539316, lng: 19.607637 },
    { lat: 48.539113, lng: 19.608335 },
    { lat: 48.538957, lng: 19.608875 },
    { lat: 48.538904, lng: 19.609008 },
    { lat: 48.538524, lng: 19.609964 },
    { lat: 48.538148, lng: 19.610869 },
    { lat: 48.538006, lng: 19.611226 },
    { lat: 48.53793, lng: 19.611402 },
    { lat: 48.537821, lng: 19.611656 },
    { lat: 48.537681, lng: 19.611952 },
    { lat: 48.537557, lng: 19.612249 },
    { lat: 48.537317, lng: 19.612798 },
    { lat: 48.536682, lng: 19.614157 },
    { lat: 48.536362, lng: 19.615029 },
    { lat: 48.536033, lng: 19.615819 },
    { lat: 48.535706, lng: 19.616259 },
    { lat: 48.535363, lng: 19.616805 },
    { lat: 48.535073, lng: 19.617097 },
    { lat: 48.534866, lng: 19.617375 },
    { lat: 48.534855, lng: 19.617389 },
    { lat: 48.534586, lng: 19.617752 },
    { lat: 48.534498, lng: 19.617869 },
    { lat: 48.534091, lng: 19.618146 },
    { lat: 48.533759, lng: 19.618347 },
    { lat: 48.533664, lng: 19.618391 },
    { lat: 48.533393, lng: 19.618515 },
    { lat: 48.532947, lng: 19.618753 },
    { lat: 48.532716, lng: 19.618766 },
    { lat: 48.532501, lng: 19.618861 },
    { lat: 48.53222, lng: 19.618992 },
    { lat: 48.531838, lng: 19.619228 },
    { lat: 48.531479, lng: 19.619496 },
    { lat: 48.531291, lng: 19.619637 },
    { lat: 48.530807, lng: 19.61979 },
    { lat: 48.530376, lng: 19.619859 },
    { lat: 48.529824, lng: 19.620041 },
    { lat: 48.529226, lng: 19.6202 },
    { lat: 48.528914, lng: 19.620138 },
    { lat: 48.528313, lng: 19.620316 },
    { lat: 48.527843, lng: 19.620359 },
    { lat: 48.527442, lng: 19.620611 },
    { lat: 48.527227, lng: 19.620702 },
    { lat: 48.526849, lng: 19.620782 },
    { lat: 48.52651, lng: 19.620936 },
    { lat: 48.526169, lng: 19.62116 },
    { lat: 48.525631, lng: 19.621438 },
    { lat: 48.525168, lng: 19.621691 },
    { lat: 48.524896, lng: 19.622145 },
    { lat: 48.524601, lng: 19.622551 },
    { lat: 48.524277, lng: 19.622991 },
    { lat: 48.523936, lng: 19.62347 },
    { lat: 48.523718, lng: 19.623805 },
    { lat: 48.52331, lng: 19.62413 },
    { lat: 48.522591, lng: 19.624527 },
    { lat: 48.522117, lng: 19.624859 },
    { lat: 48.52171, lng: 19.624852 },
    { lat: 48.521382, lng: 19.624848 },
    { lat: 48.520737, lng: 19.624937 },
    { lat: 48.520438, lng: 19.624991 },
    { lat: 48.520294, lng: 19.625014 },
    { lat: 48.519706, lng: 19.625201 },
    { lat: 48.519183, lng: 19.625464 },
    { lat: 48.519019, lng: 19.625704 },
    { lat: 48.519966, lng: 19.626309 },
    { lat: 48.520347, lng: 19.626926 },
    { lat: 48.520608, lng: 19.626943 },
    { lat: 48.520718, lng: 19.627038 },
    { lat: 48.521309, lng: 19.627555 },
    { lat: 48.521867, lng: 19.628649 },
    { lat: 48.52211, lng: 19.628537 },
    { lat: 48.522672, lng: 19.630684 },
    { lat: 48.523768, lng: 19.629792 },
    { lat: 48.524243, lng: 19.630505 },
    { lat: 48.524974, lng: 19.630824 },
    { lat: 48.526152, lng: 19.629643 },
    { lat: 48.527809, lng: 19.630379 },
    { lat: 48.527933, lng: 19.629615 },
    { lat: 48.535382, lng: 19.632756 },
    { lat: 48.539492, lng: 19.634372 },
    { lat: 48.539357, lng: 19.634608 },
    { lat: 48.540477, lng: 19.637961 },
    { lat: 48.540448, lng: 19.638906 },
    { lat: 48.540475, lng: 19.639507 },
    { lat: 48.541065, lng: 19.642826 },
    { lat: 48.542679, lng: 19.639802 },
    { lat: 48.545486, lng: 19.641053 },
    { lat: 48.546083, lng: 19.64332 },
    { lat: 48.546094, lng: 19.643371 },
    { lat: 48.546161, lng: 19.643618 },
    { lat: 48.545308, lng: 19.64535 },
    { lat: 48.54583, lng: 19.647645 },
    { lat: 48.54663, lng: 19.648214 },
    { lat: 48.549534, lng: 19.646744 },
    { lat: 48.55083, lng: 19.648078 },
    { lat: 48.550894, lng: 19.648114 },
    { lat: 48.552899, lng: 19.650203 },
    { lat: 48.552037, lng: 19.653088 },
    { lat: 48.55345, lng: 19.653828 },
    { lat: 48.553482, lng: 19.653845 },
    { lat: 48.553743, lng: 19.653982 },
    { lat: 48.55352, lng: 19.655148 },
    { lat: 48.55351, lng: 19.655498 },
    { lat: 48.553308, lng: 19.656232 },
    { lat: 48.555862, lng: 19.653761 },
    { lat: 48.555895, lng: 19.653731 },
    { lat: 48.563167, lng: 19.6467 },
    { lat: 48.563214, lng: 19.646655 },
    { lat: 48.563267, lng: 19.646604 },
    { lat: 48.56343, lng: 19.646674 },
    { lat: 48.563555, lng: 19.646727 },
    { lat: 48.564215, lng: 19.647008 },
    { lat: 48.564547, lng: 19.648594 },
    { lat: 48.565044, lng: 19.650271 },
    { lat: 48.56437, lng: 19.651185 },
    { lat: 48.563357, lng: 19.650168 },
    { lat: 48.5627, lng: 19.649854 },
    { lat: 48.562224, lng: 19.650053 },
    { lat: 48.562207, lng: 19.650593 },
    { lat: 48.562331, lng: 19.651237 },
    { lat: 48.563555, lng: 19.652199 },
    { lat: 48.563318, lng: 19.65372 },
    { lat: 48.563289, lng: 19.654579 },
    { lat: 48.562624, lng: 19.654634 },
    { lat: 48.562478, lng: 19.654997 },
    { lat: 48.562712, lng: 19.655661 },
    { lat: 48.562674, lng: 19.655848 },
    { lat: 48.562792, lng: 19.656425 },
    { lat: 48.562918, lng: 19.65642 },
    { lat: 48.56308, lng: 19.656808 },
    { lat: 48.563493, lng: 19.657017 },
    { lat: 48.56434, lng: 19.657333 },
    { lat: 48.564046, lng: 19.658144 },
    { lat: 48.563583, lng: 19.658191 },
    { lat: 48.563489, lng: 19.658537 },
    { lat: 48.563989, lng: 19.658982 },
    { lat: 48.564661, lng: 19.659906 },
    { lat: 48.564712, lng: 19.659975 },
    { lat: 48.564827, lng: 19.660134 },
    { lat: 48.565162, lng: 19.659594 },
    { lat: 48.565588, lng: 19.659774 },
    { lat: 48.565842, lng: 19.660152 },
    { lat: 48.565865, lng: 19.661587 },
    { lat: 48.566311, lng: 19.662467 },
    { lat: 48.566581, lng: 19.662654 },
    { lat: 48.567381, lng: 19.662581 },
    { lat: 48.56719, lng: 19.663414 },
    { lat: 48.567612, lng: 19.66434 },
    { lat: 48.568202, lng: 19.66373 },
    { lat: 48.568776, lng: 19.664321 },
    { lat: 48.568755, lng: 19.66449 },
    { lat: 48.567778, lng: 19.665258 },
    { lat: 48.567935, lng: 19.66677 },
    { lat: 48.568605, lng: 19.666297 },
    { lat: 48.568961, lng: 19.667114 },
    { lat: 48.568969, lng: 19.667659 },
    { lat: 48.569071, lng: 19.668235 },
    { lat: 48.569083, lng: 19.66879 },
    { lat: 48.569334, lng: 19.669448 },
    { lat: 48.569715, lng: 19.669322 },
    { lat: 48.569713, lng: 19.669449 },
    { lat: 48.570493, lng: 19.669214 },
    { lat: 48.570576, lng: 19.669107 },
    { lat: 48.571075, lng: 19.668958 },
    { lat: 48.571111, lng: 19.668947 },
    { lat: 48.571369, lng: 19.669839 },
    { lat: 48.572115, lng: 19.6701 },
    { lat: 48.572791, lng: 19.671375 },
    { lat: 48.572971, lng: 19.672613 },
    { lat: 48.57339, lng: 19.673416 },
    { lat: 48.573805, lng: 19.673891 },
    { lat: 48.574016, lng: 19.675364 },
    { lat: 48.575072, lng: 19.677646 },
    { lat: 48.572934, lng: 19.681495 },
    { lat: 48.573093, lng: 19.68233 },
    { lat: 48.573124, lng: 19.682997 },
    { lat: 48.573644, lng: 19.682976 },
    { lat: 48.573502, lng: 19.683387 },
    { lat: 48.573573, lng: 19.684051 },
    { lat: 48.573793, lng: 19.683739 },
    { lat: 48.574117, lng: 19.684877 },
    { lat: 48.57415, lng: 19.685007 },
    { lat: 48.574262, lng: 19.68543 },
    { lat: 48.574378, lng: 19.687186 },
    { lat: 48.573535, lng: 19.688128 },
    { lat: 48.573145, lng: 19.68849 },
    { lat: 48.572813, lng: 19.68876 },
    { lat: 48.573067, lng: 19.689024 },
    { lat: 48.574403, lng: 19.690568 },
    { lat: 48.575962, lng: 19.692747 },
    { lat: 48.575368, lng: 19.692776 },
    { lat: 48.57526, lng: 19.692846 },
    { lat: 48.574842, lng: 19.695039 },
    { lat: 48.575274, lng: 19.695444 },
    { lat: 48.575906, lng: 19.696751 },
    { lat: 48.576912, lng: 19.697142 },
    { lat: 48.577012, lng: 19.699999 },
    { lat: 48.576634, lng: 19.701123 },
    { lat: 48.577372, lng: 19.701475 },
    { lat: 48.577544, lng: 19.703099 },
    { lat: 48.578233, lng: 19.70412 },
    { lat: 48.578452, lng: 19.706084 },
    { lat: 48.578233, lng: 19.707139 },
    { lat: 48.578847, lng: 19.712012 },
    { lat: 48.582532, lng: 19.710601 },
    { lat: 48.582596, lng: 19.710576 },
    { lat: 48.582911, lng: 19.710511 },
    { lat: 48.582323, lng: 19.711587 },
    { lat: 48.581863, lng: 19.712872 },
    { lat: 48.582034, lng: 19.714443 },
    { lat: 48.582564, lng: 19.715258 },
    { lat: 48.583088, lng: 19.71548 },
    { lat: 48.583229, lng: 19.714817 },
    { lat: 48.584771, lng: 19.715607 },
    { lat: 48.585408, lng: 19.713775 },
    { lat: 48.585895, lng: 19.713006 },
    { lat: 48.586347, lng: 19.711072 },
    { lat: 48.586634, lng: 19.711047 },
    { lat: 48.586616, lng: 19.710632 },
    { lat: 48.587141, lng: 19.710208 },
    { lat: 48.587279, lng: 19.710426 },
    { lat: 48.587342, lng: 19.710265 },
    { lat: 48.587433, lng: 19.710178 },
    { lat: 48.588257, lng: 19.709012 },
    { lat: 48.588623, lng: 19.709944 },
    { lat: 48.588832, lng: 19.70923 },
    { lat: 48.589437, lng: 19.709616 },
    { lat: 48.589871, lng: 19.708433 },
    { lat: 48.593825, lng: 19.709054 },
    { lat: 48.59411, lng: 19.709652 },
    { lat: 48.594163, lng: 19.709719 },
    { lat: 48.59517, lng: 19.711336 },
    { lat: 48.592624, lng: 19.71357 },
    { lat: 48.592858, lng: 19.713995 },
    { lat: 48.593362, lng: 19.714272 },
    { lat: 48.593498, lng: 19.715294 },
    { lat: 48.593414, lng: 19.715315 },
    { lat: 48.59344, lng: 19.715894 },
    { lat: 48.593419, lng: 19.715974 },
    { lat: 48.593382, lng: 19.716106 },
    { lat: 48.59334, lng: 19.716258 },
    { lat: 48.593407, lng: 19.71635 },
    { lat: 48.593629, lng: 19.716656 },
    { lat: 48.593648, lng: 19.716705 },
    { lat: 48.594282, lng: 19.716222 },
    { lat: 48.594323, lng: 19.7163 },
    { lat: 48.596741, lng: 19.721231 },
    { lat: 48.596766, lng: 19.721316 },
    { lat: 48.596838, lng: 19.721142 },
    { lat: 48.596913, lng: 19.720961 },
    { lat: 48.596968, lng: 19.720823 },
    { lat: 48.597408, lng: 19.719648 },
    { lat: 48.597718, lng: 19.719107 },
    { lat: 48.59796, lng: 19.718755 },
    { lat: 48.598191, lng: 19.718444 },
    { lat: 48.598304, lng: 19.718292 },
    { lat: 48.598486, lng: 19.717911 },
    { lat: 48.598677, lng: 19.717659 },
    { lat: 48.59899, lng: 19.717193 },
    { lat: 48.599017, lng: 19.717154 },
    { lat: 48.59929, lng: 19.716785 },
    { lat: 48.599463, lng: 19.716532 },
    { lat: 48.599814, lng: 19.715828 },
    { lat: 48.600065, lng: 19.715166 },
    { lat: 48.600228, lng: 19.714774 },
    { lat: 48.600456, lng: 19.714273 },
    { lat: 48.600721, lng: 19.713701 },
    { lat: 48.60083, lng: 19.713228 },
    { lat: 48.600952, lng: 19.712802 },
    { lat: 48.601074, lng: 19.712268 },
    { lat: 48.601193, lng: 19.711545 },
    { lat: 48.601334, lng: 19.710973 },
    { lat: 48.601447, lng: 19.710472 },
    { lat: 48.601593, lng: 19.7097 },
    { lat: 48.601818, lng: 19.708695 },
    { lat: 48.601955, lng: 19.708129 },
    { lat: 48.602006, lng: 19.707607 },
    { lat: 48.602105, lng: 19.706661 },
    { lat: 48.602223, lng: 19.7058 },
    { lat: 48.602668, lng: 19.70469 },
    { lat: 48.603094, lng: 19.704311 },
    { lat: 48.603104, lng: 19.704302 },
    { lat: 48.603345, lng: 19.704108 },
    { lat: 48.603856, lng: 19.703699 },
    { lat: 48.604539, lng: 19.703236 },
    { lat: 48.605008, lng: 19.702887 },
    { lat: 48.605432, lng: 19.702358 },
    { lat: 48.6055126, lng: 19.7022703 },
    { lat: 48.605571, lng: 19.702208 },
    { lat: 48.605998, lng: 19.701743 },
    { lat: 48.606379, lng: 19.701585 },
    { lat: 48.606619, lng: 19.70151 },
    { lat: 48.607132, lng: 19.701093 },
    { lat: 48.607528, lng: 19.700639 },
    { lat: 48.607833, lng: 19.699982 },
    { lat: 48.608165, lng: 19.699415 },
    { lat: 48.608402, lng: 19.698946 },
    { lat: 48.608668, lng: 19.698244 },
    { lat: 48.608935, lng: 19.69753 },
    { lat: 48.609137, lng: 19.696961 },
    { lat: 48.609203, lng: 19.696773 },
    { lat: 48.609348, lng: 19.696354 },
    { lat: 48.609508, lng: 19.695891 },
    { lat: 48.609757, lng: 19.695462 },
    { lat: 48.609787, lng: 19.69541 },
    { lat: 48.609868, lng: 19.695271 },
    { lat: 48.610295, lng: 19.694537 },
    { lat: 48.610606, lng: 19.694055 },
    { lat: 48.611036, lng: 19.693463 },
    { lat: 48.611417, lng: 19.693036 },
    { lat: 48.611662, lng: 19.692781 },
    { lat: 48.612182, lng: 19.691958 },
    { lat: 48.612207, lng: 19.69202 },
    { lat: 48.612597, lng: 19.691628 },
    { lat: 48.612977, lng: 19.691385 },
    { lat: 48.613263, lng: 19.691074 },
    { lat: 48.613211, lng: 19.691033 },
    { lat: 48.613475, lng: 19.690956 },
    { lat: 48.613865, lng: 19.69037 },
    { lat: 48.614279, lng: 19.689809 },
    { lat: 48.614292, lng: 19.689552 },
    { lat: 48.614317, lng: 19.68905 },
    { lat: 48.614361, lng: 19.688166 },
    { lat: 48.61439, lng: 19.686811 },
    { lat: 48.614448, lng: 19.686132 },
    { lat: 48.614414, lng: 19.68538 },
    { lat: 48.614534, lng: 19.684796 },
    { lat: 48.614021, lng: 19.683388 },
    { lat: 48.613878, lng: 19.6826 },
    { lat: 48.613851, lng: 19.681665 },
    { lat: 48.61393, lng: 19.68105 },
    { lat: 48.614273, lng: 19.680023 },
    { lat: 48.61451, lng: 19.679492 },
    { lat: 48.61466, lng: 19.679085 },
    { lat: 48.614686, lng: 19.678711 },
    { lat: 48.614683, lng: 19.678672 },
    { lat: 48.614823, lng: 19.678252 },
    { lat: 48.61489, lng: 19.677741 },
    { lat: 48.615329, lng: 19.676819 },
    { lat: 48.615393, lng: 19.676676 },
    { lat: 48.615507, lng: 19.676415 },
    { lat: 48.615497, lng: 19.67626 },
    { lat: 48.615493, lng: 19.676205 },
    { lat: 48.615456, lng: 19.675679 },
    { lat: 48.615485, lng: 19.675315 },
    { lat: 48.615537, lng: 19.675208 },
    { lat: 48.615668, lng: 19.674941 },
    { lat: 48.615948, lng: 19.674497 },
    { lat: 48.615991, lng: 19.674462 },
    { lat: 48.616435, lng: 19.674106 },
    { lat: 48.616733, lng: 19.673709 },
    { lat: 48.616978, lng: 19.673341 },
    { lat: 48.617703, lng: 19.672849 },
    { lat: 48.618678, lng: 19.672444 },
    { lat: 48.618863, lng: 19.672386 },
    { lat: 48.619098, lng: 19.672311 },
    { lat: 48.619159, lng: 19.672291 },
    { lat: 48.619614, lng: 19.672159 },
    { lat: 48.61975, lng: 19.672091 },
    { lat: 48.619914, lng: 19.671982 },
    { lat: 48.620066, lng: 19.671807 },
    { lat: 48.620217, lng: 19.671589 },
    { lat: 48.620421, lng: 19.671234 },
    { lat: 48.620454, lng: 19.671149 },
    { lat: 48.62047, lng: 19.670955 },
    { lat: 48.620398, lng: 19.670118 },
    { lat: 48.620408, lng: 19.66953 },
    { lat: 48.620529, lng: 19.669027 },
    { lat: 48.620577, lng: 19.668609 },
    { lat: 48.620802, lng: 19.668175 },
    { lat: 48.620829, lng: 19.667772 },
    { lat: 48.620976, lng: 19.666945 },
    { lat: 48.621065, lng: 19.666538 },
    { lat: 48.621466, lng: 19.664973 },
    { lat: 48.621443, lng: 19.664958 },
    { lat: 48.62174, lng: 19.66409 },
    { lat: 48.621914, lng: 19.66364 },
    { lat: 48.622069, lng: 19.663212 },
    { lat: 48.622157, lng: 19.663064 },
    { lat: 48.622192, lng: 19.663004 },
    { lat: 48.622335, lng: 19.662765 },
    { lat: 48.622734, lng: 19.662256 },
    { lat: 48.62312, lng: 19.661802 },
    { lat: 48.623547, lng: 19.661374 },
    { lat: 48.623605, lng: 19.661315 },
    { lat: 48.623655, lng: 19.661249 },
    { lat: 48.623673, lng: 19.661272 },
    { lat: 48.623707, lng: 19.66115 },
    { lat: 48.623847, lng: 19.660646 },
    { lat: 48.623886, lng: 19.660236 },
    { lat: 48.623791, lng: 19.659171 },
    { lat: 48.623773, lng: 19.658584 },
    { lat: 48.623833, lng: 19.657726 },
    { lat: 48.623885, lng: 19.657468 },
    { lat: 48.6239281, lng: 19.6571659 },
    { lat: 48.62431, lng: 19.655546 },
    { lat: 48.624226, lng: 19.654811 },
    { lat: 48.624116, lng: 19.65441 },
    { lat: 48.624076, lng: 19.654222 },
    { lat: 48.624084, lng: 19.654008 },
    { lat: 48.624677, lng: 19.652887 },
    { lat: 48.624789, lng: 19.652675 },
    { lat: 48.624807, lng: 19.652076 },
    { lat: 48.62487, lng: 19.651796 },
    { lat: 48.624866, lng: 19.65114 },
    { lat: 48.624842, lng: 19.650065 },
    { lat: 48.624891, lng: 19.649699 },
    { lat: 48.624912, lng: 19.64966 },
    { lat: 48.62502, lng: 19.649451 },
    { lat: 48.624899, lng: 19.648689 },
    { lat: 48.624842, lng: 19.648332 },
    { lat: 48.624655, lng: 19.648002 },
    { lat: 48.624639, lng: 19.647975 },
    { lat: 48.624551, lng: 19.64782 },
    { lat: 48.62463, lng: 19.647242 },
    { lat: 48.624655, lng: 19.646571 },
    { lat: 48.624823, lng: 19.646035 },
    { lat: 48.624872, lng: 19.645907 },
    { lat: 48.624902, lng: 19.64583 },
    { lat: 48.624881, lng: 19.645556 },
    { lat: 48.624904, lng: 19.645283 },
    { lat: 48.62488, lng: 19.64444 },
    { lat: 48.624876, lng: 19.644298 },
    { lat: 48.625042, lng: 19.643417 },
    { lat: 48.624888, lng: 19.642821 },
    { lat: 48.624822, lng: 19.642584 },
    { lat: 48.624813, lng: 19.64255 },
    { lat: 48.624813, lng: 19.642546 },
    { lat: 48.624757, lng: 19.641931 },
    { lat: 48.624731, lng: 19.641312 },
    { lat: 48.624685, lng: 19.640755 },
    { lat: 48.62478, lng: 19.640275 },
    { lat: 48.624959, lng: 19.639387 },
    { lat: 48.624861, lng: 19.638367 },
    { lat: 48.624889, lng: 19.637852 },
    { lat: 48.624893, lng: 19.637798 },
  ],
  Podkriváň: [
    { lat: 48.505208, lng: 19.496994 },
    { lat: 48.50527, lng: 19.496987 },
    { lat: 48.505343, lng: 19.496963 },
    { lat: 48.505447, lng: 19.49693 },
    { lat: 48.505602, lng: 19.496886 },
    { lat: 48.505725, lng: 19.496831 },
    { lat: 48.505762, lng: 19.496831 },
    { lat: 48.505808, lng: 19.496855 },
    { lat: 48.505826, lng: 19.496867 },
    { lat: 48.505871, lng: 19.49691 },
    { lat: 48.505907, lng: 19.496952 },
    { lat: 48.505931, lng: 19.49698 },
    { lat: 48.506002, lng: 19.497088 },
    { lat: 48.506105, lng: 19.497261 },
    { lat: 48.506331, lng: 19.497377 },
    { lat: 48.506477, lng: 19.497417 },
    { lat: 48.506863, lng: 19.497493 },
    { lat: 48.506971, lng: 19.497563 },
    { lat: 48.507046, lng: 19.497713 },
    { lat: 48.507017, lng: 19.498087 },
    { lat: 48.507184, lng: 19.498389 },
    { lat: 48.507265, lng: 19.498737 },
    { lat: 48.507426, lng: 19.499064 },
    { lat: 48.50759, lng: 19.499257 },
    { lat: 48.508011, lng: 19.49954 },
    { lat: 48.508803, lng: 19.499911 },
    { lat: 48.508859, lng: 19.499934 },
    { lat: 48.509031, lng: 19.499952 },
    { lat: 48.509202, lng: 19.499889 },
    { lat: 48.50941, lng: 19.499679 },
    { lat: 48.509632, lng: 19.499478 },
    { lat: 48.50994, lng: 19.499304 },
    { lat: 48.510037, lng: 19.499237 },
    { lat: 48.510212, lng: 19.499177 },
    { lat: 48.510326, lng: 19.499168 },
    { lat: 48.510418, lng: 19.499182 },
    { lat: 48.510497, lng: 19.499211 },
    { lat: 48.510546, lng: 19.499249 },
    { lat: 48.510852, lng: 19.499455 },
    { lat: 48.511128, lng: 19.499662 },
    { lat: 48.5116, lng: 19.500036 },
    { lat: 48.512024, lng: 19.500455 },
    { lat: 48.512296, lng: 19.500583 },
    { lat: 48.512726, lng: 19.500711 },
    { lat: 48.513141, lng: 19.500674 },
    { lat: 48.513575, lng: 19.500503 },
    { lat: 48.513811, lng: 19.500468 },
    { lat: 48.514104, lng: 19.500483 },
    { lat: 48.514357, lng: 19.500571 },
    { lat: 48.514506, lng: 19.500679 },
    { lat: 48.514615, lng: 19.500887 },
    { lat: 48.514945, lng: 19.501327 },
    { lat: 48.515255, lng: 19.501421 },
    { lat: 48.515495, lng: 19.501389 },
    { lat: 48.515733, lng: 19.501296 },
    { lat: 48.515985, lng: 19.501227 },
    { lat: 48.516029, lng: 19.501211 },
    { lat: 48.516066, lng: 19.501183 },
    { lat: 48.516213, lng: 19.501088 },
    { lat: 48.516639, lng: 19.500735 },
    { lat: 48.516935, lng: 19.500736 },
    { lat: 48.517129, lng: 19.500751 },
    { lat: 48.517919, lng: 19.500815 },
    { lat: 48.518058, lng: 19.500781 },
    { lat: 48.518125, lng: 19.500761 },
    { lat: 48.518291, lng: 19.500649 },
    { lat: 48.518599, lng: 19.500712 },
    { lat: 48.518941, lng: 19.500468 },
    { lat: 48.519105, lng: 19.500374 },
    { lat: 48.519269, lng: 19.500324 },
    { lat: 48.519464, lng: 19.500332 },
    { lat: 48.519606, lng: 19.500381 },
    { lat: 48.520085, lng: 19.500645 },
    { lat: 48.520242, lng: 19.500672 },
    { lat: 48.520801, lng: 19.500945 },
    { lat: 48.520941, lng: 19.501076 },
    { lat: 48.521105, lng: 19.501336 },
    { lat: 48.521231, lng: 19.501552 },
    { lat: 48.521187, lng: 19.501916 },
    { lat: 48.521326, lng: 19.502625 },
    { lat: 48.521555, lng: 19.502814 },
    { lat: 48.521624, lng: 19.502896 },
    { lat: 48.521785, lng: 19.503084 },
    { lat: 48.521948, lng: 19.503821 },
    { lat: 48.522507, lng: 19.504506 },
    { lat: 48.52268, lng: 19.504696 },
    { lat: 48.522773, lng: 19.504821 },
    { lat: 48.522785, lng: 19.504844 },
    { lat: 48.522772, lng: 19.504868 },
    { lat: 48.522759, lng: 19.505191 },
    { lat: 48.522966, lng: 19.505965 },
    { lat: 48.523156, lng: 19.506598 },
    { lat: 48.523966, lng: 19.508003 },
    { lat: 48.524265, lng: 19.508297 },
    { lat: 48.525515, lng: 19.51108 },
    { lat: 48.526046, lng: 19.512621 },
    { lat: 48.525847, lng: 19.514414 },
    { lat: 48.526204, lng: 19.515869 },
    { lat: 48.526574, lng: 19.516416 },
    { lat: 48.526915, lng: 19.518482 },
    { lat: 48.527071, lng: 19.519109 },
    { lat: 48.527565, lng: 19.521075 },
    { lat: 48.527298, lng: 19.522889 },
    { lat: 48.527217, lng: 19.522896 },
    { lat: 48.526959, lng: 19.522759 },
    { lat: 48.526422, lng: 19.52307 },
    { lat: 48.526339, lng: 19.522906 },
    { lat: 48.52587, lng: 19.52303 },
    { lat: 48.525489, lng: 19.522657 },
    { lat: 48.525106, lng: 19.522636 },
    { lat: 48.525054, lng: 19.522743 },
    { lat: 48.525077, lng: 19.52282 },
    { lat: 48.525033, lng: 19.522988 },
    { lat: 48.524828, lng: 19.522973 },
    { lat: 48.524741, lng: 19.523282 },
    { lat: 48.524866, lng: 19.523681 },
    { lat: 48.524874, lng: 19.524054 },
    { lat: 48.525114, lng: 19.524482 },
    { lat: 48.525148, lng: 19.524684 },
    { lat: 48.525237, lng: 19.5247 },
    { lat: 48.525244, lng: 19.524831 },
    { lat: 48.525305, lng: 19.52488 },
    { lat: 48.525297, lng: 19.525249 },
    { lat: 48.525443, lng: 19.525364 },
    { lat: 48.525587, lng: 19.52526 },
    { lat: 48.525972, lng: 19.525583 },
    { lat: 48.525975, lng: 19.525688 },
    { lat: 48.526332, lng: 19.525818 },
    { lat: 48.526326, lng: 19.525623 },
    { lat: 48.526478, lng: 19.525755 },
    { lat: 48.526601, lng: 19.526185 },
    { lat: 48.526703, lng: 19.526793 },
    { lat: 48.52687, lng: 19.527134 },
    { lat: 48.526787, lng: 19.528031 },
    { lat: 48.526856, lng: 19.528521 },
    { lat: 48.527081, lng: 19.528431 },
    { lat: 48.527195, lng: 19.528053 },
    { lat: 48.527466, lng: 19.528052 },
    { lat: 48.527613, lng: 19.527636 },
    { lat: 48.528114, lng: 19.527957 },
    { lat: 48.528338, lng: 19.528027 },
    { lat: 48.52835, lng: 19.52794 },
    { lat: 48.529016, lng: 19.528089 },
    { lat: 48.529477, lng: 19.527986 },
    { lat: 48.52986, lng: 19.528861 },
    { lat: 48.529917, lng: 19.528955 },
    { lat: 48.530055, lng: 19.529099 },
    { lat: 48.530229, lng: 19.528952 },
    { lat: 48.530603, lng: 19.529039 },
    { lat: 48.530888, lng: 19.530922 },
    { lat: 48.53087, lng: 19.531058 },
    { lat: 48.530989, lng: 19.531571 },
    { lat: 48.531919, lng: 19.531345 },
    { lat: 48.53209, lng: 19.53109 },
    { lat: 48.532189, lng: 19.531199 },
    { lat: 48.532313, lng: 19.531567 },
    { lat: 48.532508, lng: 19.532621 },
    { lat: 48.532485, lng: 19.532688 },
    { lat: 48.53289, lng: 19.535096 },
    { lat: 48.533078, lng: 19.535667 },
    { lat: 48.533368, lng: 19.535806 },
    { lat: 48.53342, lng: 19.535842 },
    { lat: 48.533636, lng: 19.535976 },
    { lat: 48.533709, lng: 19.536269 },
    { lat: 48.534305, lng: 19.536714 },
    { lat: 48.534407, lng: 19.536431 },
    { lat: 48.534647, lng: 19.536494 },
    { lat: 48.534757, lng: 19.536458 },
    { lat: 48.534809, lng: 19.536691 },
    { lat: 48.534881, lng: 19.536735 },
    { lat: 48.53484, lng: 19.537217 },
    { lat: 48.535126, lng: 19.537579 },
    { lat: 48.535651, lng: 19.537946 },
    { lat: 48.535684, lng: 19.537966 },
    { lat: 48.537362, lng: 19.538985 },
    { lat: 48.538975, lng: 19.540364 },
    { lat: 48.539256, lng: 19.541134 },
    { lat: 48.539789, lng: 19.541655 },
    { lat: 48.540356, lng: 19.543017 },
    { lat: 48.54039, lng: 19.543121 },
    { lat: 48.540518, lng: 19.543679 },
    { lat: 48.540675, lng: 19.544993 },
    { lat: 48.540615, lng: 19.545764 },
    { lat: 48.540939, lng: 19.548838 },
    { lat: 48.540935, lng: 19.548896 },
    { lat: 48.541333, lng: 19.549354 },
    { lat: 48.541405, lng: 19.549719 },
    { lat: 48.541459, lng: 19.549939 },
    { lat: 48.541492, lng: 19.550222 },
    { lat: 48.541518, lng: 19.550681 },
    { lat: 48.541099, lng: 19.550612 },
    { lat: 48.540606, lng: 19.550903 },
    { lat: 48.540213, lng: 19.551296 },
    { lat: 48.540334, lng: 19.551709 },
    { lat: 48.540563, lng: 19.551676 },
    { lat: 48.540666, lng: 19.552003 },
    { lat: 48.540968, lng: 19.552338 },
    { lat: 48.541036, lng: 19.55243 },
    { lat: 48.541411, lng: 19.553467 },
    { lat: 48.541468, lng: 19.553638 },
    { lat: 48.541716, lng: 19.554585 },
    { lat: 48.541979, lng: 19.554879 },
    { lat: 48.54273, lng: 19.556214 },
    { lat: 48.542947, lng: 19.556935 },
    { lat: 48.542972, lng: 19.557222 },
    { lat: 48.54297, lng: 19.557294 },
    { lat: 48.542964, lng: 19.557551 },
    { lat: 48.54296, lng: 19.557754 },
    { lat: 48.542834, lng: 19.558197 },
    { lat: 48.542506, lng: 19.558786 },
    { lat: 48.542369, lng: 19.558942 },
    { lat: 48.542036, lng: 19.55932 },
    { lat: 48.542009, lng: 19.559351 },
    { lat: 48.541793, lng: 19.55949 },
    { lat: 48.541768, lng: 19.559506 },
    { lat: 48.541568, lng: 19.559635 },
    { lat: 48.541334, lng: 19.55973 },
    { lat: 48.54121, lng: 19.559931 },
    { lat: 48.540565, lng: 19.560979 },
    { lat: 48.540651, lng: 19.56265 },
    { lat: 48.540681, lng: 19.562706 },
    { lat: 48.540844, lng: 19.563048 },
    { lat: 48.541462, lng: 19.56238 },
    { lat: 48.54155, lng: 19.562344 },
    { lat: 48.541594, lng: 19.56231 },
    { lat: 48.542107, lng: 19.56209 },
    { lat: 48.542775, lng: 19.561579 },
    { lat: 48.542974, lng: 19.562176 },
    { lat: 48.543307, lng: 19.562346 },
    { lat: 48.543375, lng: 19.563076 },
    { lat: 48.543866, lng: 19.562758 },
    { lat: 48.544383, lng: 19.562569 },
    { lat: 48.544551, lng: 19.563021 },
    { lat: 48.544663, lng: 19.563397 },
    { lat: 48.544882, lng: 19.564132 },
    { lat: 48.544969, lng: 19.564077 },
    { lat: 48.5451, lng: 19.564514 },
    { lat: 48.545276, lng: 19.564499 },
    { lat: 48.545417, lng: 19.565012 },
    { lat: 48.544542, lng: 19.565828 },
    { lat: 48.544744, lng: 19.566846 },
    { lat: 48.545073, lng: 19.566932 },
    { lat: 48.544776, lng: 19.56789 },
    { lat: 48.5447507, lng: 19.5679505 },
    { lat: 48.5455027, lng: 19.5685208 },
    { lat: 48.5474913, lng: 19.568958 },
    { lat: 48.5479435, lng: 19.5687194 },
    { lat: 48.5482793, lng: 19.5684474 },
    { lat: 48.5486416, lng: 19.5679414 },
    { lat: 48.5490794, lng: 19.5675137 },
    { lat: 48.549938, lng: 19.5675085 },
    { lat: 48.5503337, lng: 19.5673153 },
    { lat: 48.5509306, lng: 19.5667924 },
    { lat: 48.5511778, lng: 19.5663663 },
    { lat: 48.5513238, lng: 19.5661032 },
    { lat: 48.5515971, lng: 19.5645604 },
    { lat: 48.5516121, lng: 19.5640267 },
    { lat: 48.5515752, lng: 19.5636169 },
    { lat: 48.5513383, lng: 19.5624304 },
    { lat: 48.551333, lng: 19.562065 },
    { lat: 48.5519382, lng: 19.5599769 },
    { lat: 48.5520288, lng: 19.5594432 },
    { lat: 48.5521675, lng: 19.5589771 },
    { lat: 48.5521888, lng: 19.557746 },
    { lat: 48.5522446, lng: 19.5570928 },
    { lat: 48.5524743, lng: 19.5566212 },
    { lat: 48.5524885, lng: 19.5562419 },
    { lat: 48.5524184, lng: 19.5552305 },
    { lat: 48.552219, lng: 19.5545641 },
    { lat: 48.5524572, lng: 19.551627 },
    { lat: 48.5524879, lng: 19.5508957 },
    { lat: 48.5524478, lng: 19.549677 },
    { lat: 48.5522922, lng: 19.5486183 },
    { lat: 48.5523344, lng: 19.5484097 },
    { lat: 48.5522294, lng: 19.5480099 },
    { lat: 48.5520868, lng: 19.5461851 },
    { lat: 48.552405, lng: 19.5450618 },
    { lat: 48.5538574, lng: 19.5422865 },
    { lat: 48.5543007, lng: 19.5416316 },
    { lat: 48.5549135, lng: 19.5402079 },
    { lat: 48.5549423, lng: 19.5398534 },
    { lat: 48.5546377, lng: 19.539278 },
    { lat: 48.5545344, lng: 19.5378418 },
    { lat: 48.5546078, lng: 19.5374051 },
    { lat: 48.554089, lng: 19.5357519 },
    { lat: 48.554151, lng: 19.5354811 },
    { lat: 48.5537367, lng: 19.5352238 },
    { lat: 48.5532129, lng: 19.5345916 },
    { lat: 48.5530423, lng: 19.5339674 },
    { lat: 48.5530677, lng: 19.5337396 },
    { lat: 48.5527245, lng: 19.5305151 },
    { lat: 48.5522361, lng: 19.5285717 },
    { lat: 48.5521152, lng: 19.5278591 },
    { lat: 48.553052, lng: 19.5256391 },
    { lat: 48.5531909, lng: 19.5243483 },
    { lat: 48.5532583, lng: 19.5232457 },
    { lat: 48.5534844, lng: 19.5218896 },
    { lat: 48.5535076, lng: 19.5213895 },
    { lat: 48.5537023, lng: 19.5203572 },
    { lat: 48.5536625, lng: 19.5194226 },
    { lat: 48.5526252, lng: 19.5177011 },
    { lat: 48.5531263, lng: 19.5166221 },
    { lat: 48.5539687, lng: 19.515778 },
    { lat: 48.5549563, lng: 19.5145163 },
    { lat: 48.5567128, lng: 19.5136072 },
    { lat: 48.5572848, lng: 19.5136241 },
    { lat: 48.5574698, lng: 19.5136965 },
    { lat: 48.5577715, lng: 19.5136446 },
    { lat: 48.5578372, lng: 19.5133083 },
    { lat: 48.5578868, lng: 19.5122597 },
    { lat: 48.5577601, lng: 19.5112236 },
    { lat: 48.5576332, lng: 19.5105998 },
    { lat: 48.5573568, lng: 19.5096921 },
    { lat: 48.5573839, lng: 19.5094506 },
    { lat: 48.5574565, lng: 19.508681 },
    { lat: 48.5574883, lng: 19.5069783 },
    { lat: 48.5573747, lng: 19.5065188 },
    { lat: 48.5568479, lng: 19.5056069 },
    { lat: 48.5567785, lng: 19.5053968 },
    { lat: 48.5566965, lng: 19.5048708 },
    { lat: 48.5567405, lng: 19.5034774 },
    { lat: 48.5566997, lng: 19.5029886 },
    { lat: 48.5563252, lng: 19.5021528 },
    { lat: 48.5561333, lng: 19.5020485 },
    { lat: 48.5558108, lng: 19.502045 },
    { lat: 48.5550935, lng: 19.5022263 },
    { lat: 48.5545473, lng: 19.5027212 },
    { lat: 48.5542634, lng: 19.5030868 },
    { lat: 48.5534766, lng: 19.5033537 },
    { lat: 48.5531008, lng: 19.5035863 },
    { lat: 48.5528882, lng: 19.5039067 },
    { lat: 48.5526536, lng: 19.50444 },
    { lat: 48.5519074, lng: 19.5043384 },
    { lat: 48.5515907, lng: 19.5043891 },
    { lat: 48.5513873, lng: 19.504107 },
    { lat: 48.5507792, lng: 19.5028245 },
    { lat: 48.5503707, lng: 19.5013767 },
    { lat: 48.5499356, lng: 19.5003027 },
    { lat: 48.54847, lng: 19.4985634 },
    { lat: 48.5487865, lng: 19.4982688 },
    { lat: 48.5486281, lng: 19.4977711 },
    { lat: 48.5480078, lng: 19.4976602 },
    { lat: 48.5467112, lng: 19.4966285 },
    { lat: 48.5456949, lng: 19.496119 },
    { lat: 48.5449763, lng: 19.4963229 },
    { lat: 48.5446641, lng: 19.4966132 },
    { lat: 48.5441407, lng: 19.4972344 },
    { lat: 48.543873, lng: 19.4976328 },
    { lat: 48.5436855, lng: 19.4976489 },
    { lat: 48.5434934, lng: 19.4975953 },
    { lat: 48.5425278, lng: 19.4973257 },
    { lat: 48.5422965, lng: 19.4971252 },
    { lat: 48.541992, lng: 19.4965367 },
    { lat: 48.5417269, lng: 19.4956107 },
    { lat: 48.54159, lng: 19.4946496 },
    { lat: 48.5413854, lng: 19.4941238 },
    { lat: 48.5410806, lng: 19.4937014 },
    { lat: 48.5405991, lng: 19.4934526 },
    { lat: 48.539915, lng: 19.4933773 },
    { lat: 48.5396509, lng: 19.4931498 },
    { lat: 48.5393637, lng: 19.4925687 },
    { lat: 48.5392273, lng: 19.4919148 },
    { lat: 48.5387451, lng: 19.4915018 },
    { lat: 48.5382512, lng: 19.4913522 },
    { lat: 48.5375156, lng: 19.4914039 },
    { lat: 48.5373175, lng: 19.4913121 },
    { lat: 48.5371475, lng: 19.4910995 },
    { lat: 48.5369828, lng: 19.4907314 },
    { lat: 48.5367846, lng: 19.4897178 },
    { lat: 48.5366102, lng: 19.4894037 },
    { lat: 48.5354845, lng: 19.4883864 },
    { lat: 48.5350995, lng: 19.487597 },
    { lat: 48.5348997, lng: 19.4869555 },
    { lat: 48.5347804, lng: 19.4858925 },
    { lat: 48.5348336, lng: 19.4850743 },
    { lat: 48.5350749, lng: 19.4830605 },
    { lat: 48.5350213, lng: 19.4821505 },
    { lat: 48.5348169, lng: 19.4813403 },
    { lat: 48.5340095, lng: 19.4794672 },
    { lat: 48.5331174, lng: 19.4771483 },
    { lat: 48.5323596, lng: 19.4755148 },
    { lat: 48.531133, lng: 19.4743428 },
    { lat: 48.5303813, lng: 19.4735457 },
    { lat: 48.5289022, lng: 19.4728816 },
    { lat: 48.5276561, lng: 19.473273 },
    { lat: 48.5269161, lng: 19.473067 },
    { lat: 48.5262462, lng: 19.4732266 },
    { lat: 48.525611, lng: 19.4736449 },
    { lat: 48.5248239, lng: 19.4742897 },
    { lat: 48.5242011, lng: 19.4742004 },
    { lat: 48.5237957, lng: 19.47392 },
    { lat: 48.5229983, lng: 19.4730074 },
    { lat: 48.5219236, lng: 19.4720756 },
    { lat: 48.521459, lng: 19.4702999 },
    { lat: 48.5210727, lng: 19.4695561 },
    { lat: 48.5207967, lng: 19.4687122 },
    { lat: 48.520104, lng: 19.467394 },
    { lat: 48.5195691, lng: 19.4655913 },
    { lat: 48.5189211, lng: 19.4642855 },
    { lat: 48.5189308, lng: 19.4631874 },
    { lat: 48.5190018, lng: 19.4620065 },
    { lat: 48.5191997, lng: 19.4601392 },
    { lat: 48.519422, lng: 19.4589628 },
    { lat: 48.5193767, lng: 19.4583509 },
    { lat: 48.5191253, lng: 19.4573121 },
    { lat: 48.5187384, lng: 19.4563904 },
    { lat: 48.5188513, lng: 19.4530173 },
    { lat: 48.519031, lng: 19.4522326 },
    { lat: 48.5193072, lng: 19.4515581 },
    { lat: 48.5197384, lng: 19.4502508 },
    { lat: 48.519868, lng: 19.449674 },
    { lat: 48.5199866, lng: 19.4487353 },
    { lat: 48.5189802, lng: 19.4484968 },
    { lat: 48.5188634, lng: 19.4476603 },
    { lat: 48.5179309, lng: 19.4472697 },
    { lat: 48.5171811, lng: 19.4460354 },
    { lat: 48.5159753, lng: 19.4446384 },
    { lat: 48.5153606, lng: 19.443666 },
    { lat: 48.5155608, lng: 19.44313 },
    { lat: 48.5152078, lng: 19.4428597 },
    { lat: 48.514787, lng: 19.4428005 },
    { lat: 48.5145077, lng: 19.4433121 },
    { lat: 48.5141378, lng: 19.4434513 },
    { lat: 48.5138457, lng: 19.4441687 },
    { lat: 48.5134403, lng: 19.4446546 },
    { lat: 48.5128804, lng: 19.4449272 },
    { lat: 48.5120028, lng: 19.4461382 },
    { lat: 48.5113465, lng: 19.4471603 },
    { lat: 48.5111354, lng: 19.4470804 },
    { lat: 48.5105434, lng: 19.4475369 },
    { lat: 48.5105367, lng: 19.4477772 },
    { lat: 48.5097707, lng: 19.447906 },
    { lat: 48.5094266, lng: 19.447875 },
    { lat: 48.5088533, lng: 19.4472065 },
    { lat: 48.5087318, lng: 19.4467555 },
    { lat: 48.5086393, lng: 19.4466058 },
    { lat: 48.5083552, lng: 19.4463626 },
    { lat: 48.5080684, lng: 19.4459774 },
    { lat: 48.5078478, lng: 19.4446855 },
    { lat: 48.5077762, lng: 19.4444566 },
    { lat: 48.5074774, lng: 19.443942 },
    { lat: 48.506835, lng: 19.4432842 },
    { lat: 48.5067504, lng: 19.4432416 },
    { lat: 48.5064663, lng: 19.4432363 },
    { lat: 48.5063097, lng: 19.4433546 },
    { lat: 48.5060659, lng: 19.4434966 },
    { lat: 48.5057616, lng: 19.4435187 },
    { lat: 48.5047379, lng: 19.4426681 },
    { lat: 48.5036005, lng: 19.4413893 },
    { lat: 48.503271, lng: 19.4406577 },
    { lat: 48.5031136, lng: 19.4404473 },
    { lat: 48.5029656, lng: 19.4403685 },
    { lat: 48.5028348, lng: 19.4401535 },
    { lat: 48.5023834, lng: 19.4380706 },
    { lat: 48.5020234, lng: 19.4378217 },
    { lat: 48.5019405, lng: 19.4376166 },
    { lat: 48.5012637, lng: 19.4374852 },
    { lat: 48.5007305, lng: 19.43777 },
    { lat: 48.5001836, lng: 19.4379578 },
    { lat: 48.4996683, lng: 19.437735 },
    { lat: 48.4990704, lng: 19.4373592 },
    { lat: 48.4982187, lng: 19.4366484 },
    { lat: 48.4962681, lng: 19.4346845 },
    { lat: 48.4962803, lng: 19.4342238 },
    { lat: 48.4959956, lng: 19.4333469 },
    { lat: 48.4960368, lng: 19.4327793 },
    { lat: 48.4960077, lng: 19.4324974 },
    { lat: 48.4954481, lng: 19.4320061 },
    { lat: 48.4952358, lng: 19.4320674 },
    { lat: 48.4951176, lng: 19.431714 },
    { lat: 48.4951949, lng: 19.4311508 },
    { lat: 48.4954094, lng: 19.4309667 },
    { lat: 48.4953717, lng: 19.4307365 },
    { lat: 48.4950066, lng: 19.4296621 },
    { lat: 48.4948474, lng: 19.4298471 },
    { lat: 48.4946545, lng: 19.4296971 },
    { lat: 48.4947213, lng: 19.4282893 },
    { lat: 48.4946666, lng: 19.4277746 },
    { lat: 48.493665, lng: 19.4268941 },
    { lat: 48.4921628, lng: 19.4261073 },
    { lat: 48.4916755, lng: 19.4261198 },
    { lat: 48.4910335, lng: 19.4258861 },
    { lat: 48.4902325, lng: 19.4253925 },
    { lat: 48.4893246, lng: 19.4246617 },
    { lat: 48.4883677, lng: 19.4229104 },
    { lat: 48.4879178, lng: 19.4223529 },
    { lat: 48.487488, lng: 19.421587 },
    { lat: 48.487716, lng: 19.422162 },
    { lat: 48.487689, lng: 19.422192 },
    { lat: 48.487646, lng: 19.422239 },
    { lat: 48.487629, lng: 19.422258 },
    { lat: 48.48653, lng: 19.42346 },
    { lat: 48.486513, lng: 19.423479 },
    { lat: 48.486479, lng: 19.423516 },
    { lat: 48.486454, lng: 19.423542 },
    { lat: 48.486342, lng: 19.423666 },
    { lat: 48.486307, lng: 19.423705 },
    { lat: 48.486282, lng: 19.423732 },
    { lat: 48.486087, lng: 19.424058 },
    { lat: 48.486083, lng: 19.424776 },
    { lat: 48.485658, lng: 19.425686 },
    { lat: 48.485096, lng: 19.42642 },
    { lat: 48.48488, lng: 19.426487 },
    { lat: 48.484397, lng: 19.426821 },
    { lat: 48.483969, lng: 19.427421 },
    { lat: 48.483294, lng: 19.427767 },
    { lat: 48.482903, lng: 19.427456 },
    { lat: 48.482757, lng: 19.427397 },
    { lat: 48.482404, lng: 19.428531 },
    { lat: 48.4821, lng: 19.429469 },
    { lat: 48.481787, lng: 19.430088 },
    { lat: 48.482526, lng: 19.430967 },
    { lat: 48.482518, lng: 19.430997 },
    { lat: 48.48251, lng: 19.431017 },
    { lat: 48.48306, lng: 19.431269 },
    { lat: 48.483012, lng: 19.431587 },
    { lat: 48.482955, lng: 19.431965 },
    { lat: 48.483077, lng: 19.432434 },
    { lat: 48.483099, lng: 19.432458 },
    { lat: 48.483739, lng: 19.432894 },
    { lat: 48.483954, lng: 19.432985 },
    { lat: 48.484326, lng: 19.433099 },
    { lat: 48.484694, lng: 19.433078 },
    { lat: 48.485059, lng: 19.433295 },
    { lat: 48.485132, lng: 19.433581 },
    { lat: 48.485206, lng: 19.433647 },
    { lat: 48.485533, lng: 19.433693 },
    { lat: 48.485584, lng: 19.434236 },
    { lat: 48.486157, lng: 19.434148 },
    { lat: 48.486859, lng: 19.434364 },
    { lat: 48.487096, lng: 19.434771 },
    { lat: 48.487332, lng: 19.435103 },
    { lat: 48.487532, lng: 19.43537 },
    { lat: 48.487567, lng: 19.43542 },
    { lat: 48.487539, lng: 19.435759 },
    { lat: 48.489267, lng: 19.43824 },
    { lat: 48.489885, lng: 19.438699 },
    { lat: 48.49031, lng: 19.438736 },
    { lat: 48.490875, lng: 19.438785 },
    { lat: 48.492093, lng: 19.43876 },
    { lat: 48.493021, lng: 19.439466 },
    { lat: 48.493616, lng: 19.439792 },
    { lat: 48.494294, lng: 19.440065 },
    { lat: 48.495039, lng: 19.440267 },
    { lat: 48.496019, lng: 19.440796 },
    { lat: 48.496591, lng: 19.440722 },
    { lat: 48.497578, lng: 19.441024 },
    { lat: 48.498158, lng: 19.441529 },
    { lat: 48.499025, lng: 19.441716 },
    { lat: 48.499055, lng: 19.441723 },
    { lat: 48.500406, lng: 19.441837 },
    { lat: 48.500958, lng: 19.442463 },
    { lat: 48.501426, lng: 19.44287 },
    { lat: 48.501932, lng: 19.442823 },
    { lat: 48.502625, lng: 19.443372 },
    { lat: 48.503073, lng: 19.443873 },
    { lat: 48.503502, lng: 19.444342 },
    { lat: 48.503836, lng: 19.444834 },
    { lat: 48.503933, lng: 19.444967 },
    { lat: 48.504144, lng: 19.445293 },
    { lat: 48.503982, lng: 19.446723 },
    { lat: 48.503172, lng: 19.447335 },
    { lat: 48.502874, lng: 19.447783 },
    { lat: 48.502619, lng: 19.448076 },
    { lat: 48.501668, lng: 19.448968 },
    { lat: 48.501194, lng: 19.449441 },
    { lat: 48.500756, lng: 19.449794 },
    { lat: 48.500375, lng: 19.450162 },
    { lat: 48.500249, lng: 19.450163 },
    { lat: 48.500183, lng: 19.45027 },
    { lat: 48.500077, lng: 19.450257 },
    { lat: 48.500052, lng: 19.450353 },
    { lat: 48.499967, lng: 19.450347 },
    { lat: 48.499844, lng: 19.45036 },
    { lat: 48.499827, lng: 19.450362 },
    { lat: 48.499634, lng: 19.450382 },
    { lat: 48.499613, lng: 19.450448 },
    { lat: 48.499517, lng: 19.450753 },
    { lat: 48.499251, lng: 19.451373 },
    { lat: 48.49917, lng: 19.452002 },
    { lat: 48.499151, lng: 19.452075 },
    { lat: 48.499133, lng: 19.452144 },
    { lat: 48.498791, lng: 19.453422 },
    { lat: 48.498585, lng: 19.454561 },
    { lat: 48.498576, lng: 19.454613 },
    { lat: 48.49857, lng: 19.454665 },
    { lat: 48.498563, lng: 19.454719 },
    { lat: 48.498424, lng: 19.45581 },
    { lat: 48.49861, lng: 19.456372 },
    { lat: 48.497866, lng: 19.456197 },
    { lat: 48.497514, lng: 19.456017 },
    { lat: 48.497091, lng: 19.455754 },
    { lat: 48.496647, lng: 19.455448 },
    { lat: 48.495911, lng: 19.45536 },
    { lat: 48.49491, lng: 19.455163 },
    { lat: 48.494277, lng: 19.455022 },
    { lat: 48.493771, lng: 19.455252 },
    { lat: 48.49291, lng: 19.455673 },
    { lat: 48.492628, lng: 19.4556 },
    { lat: 48.492243, lng: 19.455689 },
    { lat: 48.492193, lng: 19.455692 },
    { lat: 48.49182, lng: 19.4556 },
    { lat: 48.491376, lng: 19.455623 },
    { lat: 48.491126, lng: 19.455633 },
    { lat: 48.490786, lng: 19.456164 },
    { lat: 48.490632, lng: 19.456657 },
    { lat: 48.490478, lng: 19.45718 },
    { lat: 48.490215, lng: 19.457768 },
    { lat: 48.490181, lng: 19.458142 },
    { lat: 48.490001, lng: 19.458993 },
    { lat: 48.489955, lng: 19.460351 },
    { lat: 48.489938, lng: 19.461229 },
    { lat: 48.489935, lng: 19.461974 },
    { lat: 48.490504, lng: 19.46353 },
    { lat: 48.490297, lng: 19.463802 },
    { lat: 48.490845, lng: 19.464602 },
    { lat: 48.491064, lng: 19.465576 },
    { lat: 48.491207, lng: 19.465796 },
    { lat: 48.491103, lng: 19.46617 },
    { lat: 48.491637, lng: 19.466534 },
    { lat: 48.491583, lng: 19.467131 },
    { lat: 48.491772, lng: 19.467335 },
    { lat: 48.491917, lng: 19.467879 },
    { lat: 48.492255, lng: 19.467671 },
    { lat: 48.49239, lng: 19.46832 },
    { lat: 48.492887, lng: 19.468539 },
    { lat: 48.492797, lng: 19.469433 },
    { lat: 48.493015, lng: 19.46992 },
    { lat: 48.493284, lng: 19.470249 },
    { lat: 48.493392, lng: 19.471048 },
    { lat: 48.49373, lng: 19.470799 },
    { lat: 48.493831, lng: 19.471567 },
    { lat: 48.494592, lng: 19.472207 },
    { lat: 48.494642, lng: 19.472236 },
    { lat: 48.494925, lng: 19.472401 },
    { lat: 48.495165, lng: 19.472486 },
    { lat: 48.49543, lng: 19.472515 },
    { lat: 48.495586, lng: 19.472521 },
    { lat: 48.496391, lng: 19.472462 },
    { lat: 48.496392, lng: 19.472206 },
    { lat: 48.497143, lng: 19.472242 },
    { lat: 48.497265, lng: 19.47225 },
    { lat: 48.497892, lng: 19.472952 },
    { lat: 48.498199, lng: 19.473301 },
    { lat: 48.498591, lng: 19.473734 },
    { lat: 48.499088, lng: 19.474315 },
    { lat: 48.499192, lng: 19.474432 },
    { lat: 48.499234, lng: 19.474482 },
    { lat: 48.499364, lng: 19.474687 },
    { lat: 48.500218, lng: 19.476005 },
    { lat: 48.500745, lng: 19.476801 },
    { lat: 48.501019, lng: 19.477204 },
    { lat: 48.50114, lng: 19.477395 },
    { lat: 48.5013, lng: 19.47757 },
    { lat: 48.501326, lng: 19.47761 },
    { lat: 48.50202, lng: 19.478409 },
    { lat: 48.501989, lng: 19.478801 },
    { lat: 48.501947, lng: 19.479438 },
    { lat: 48.501438, lng: 19.480382 },
    { lat: 48.50147, lng: 19.480605 },
    { lat: 48.501638, lng: 19.481571 },
    { lat: 48.501978, lng: 19.482144 },
    { lat: 48.502543, lng: 19.482759 },
    { lat: 48.502686, lng: 19.482988 },
    { lat: 48.502082, lng: 19.483904 },
    { lat: 48.501973, lng: 19.484214 },
    { lat: 48.502034, lng: 19.484353 },
    { lat: 48.502075, lng: 19.485111 },
    { lat: 48.502463, lng: 19.485298 },
    { lat: 48.503272, lng: 19.4858 },
    { lat: 48.504071, lng: 19.486458 },
    { lat: 48.504267, lng: 19.486883 },
    { lat: 48.504044, lng: 19.487295 },
    { lat: 48.504161, lng: 19.4884 },
    { lat: 48.504208, lng: 19.489373 },
    { lat: 48.50477, lng: 19.489221 },
    { lat: 48.504815, lng: 19.488999 },
    { lat: 48.505107, lng: 19.48917 },
    { lat: 48.505158, lng: 19.489312 },
    { lat: 48.504997, lng: 19.489439 },
    { lat: 48.505107, lng: 19.49005 },
    { lat: 48.505297, lng: 19.490934 },
    { lat: 48.5054, lng: 19.491579 },
    { lat: 48.505541, lng: 19.491879 },
    { lat: 48.505565, lng: 19.492727 },
    { lat: 48.505388, lng: 19.492858 },
    { lat: 48.505304, lng: 19.49324 },
    { lat: 48.505289, lng: 19.49374 },
    { lat: 48.505236, lng: 19.495075 },
    { lat: 48.505211, lng: 19.495663 },
    { lat: 48.50521, lng: 19.495723 },
    { lat: 48.505201, lng: 19.495923 },
    { lat: 48.505194, lng: 19.496889 },
    { lat: 48.505203, lng: 19.496933 },
    { lat: 48.505208, lng: 19.496994 },
  ],
  Klokoč: [
    { lat: 48.4855868, lng: 19.3108627 },
    { lat: 48.4847045, lng: 19.310517 },
    { lat: 48.4840453, lng: 19.3107336 },
    { lat: 48.483836, lng: 19.3104369 },
    { lat: 48.4772368, lng: 19.3147631 },
    { lat: 48.4776483, lng: 19.3156044 },
    { lat: 48.4777901, lng: 19.3171279 },
    { lat: 48.4781749, lng: 19.3189952 },
    { lat: 48.4791356, lng: 19.3206229 },
    { lat: 48.4794303, lng: 19.3215774 },
    { lat: 48.4798058, lng: 19.3222174 },
    { lat: 48.4801573, lng: 19.3232234 },
    { lat: 48.4803566, lng: 19.3236654 },
    { lat: 48.4808651, lng: 19.3251734 },
    { lat: 48.481299, lng: 19.3259477 },
    { lat: 48.4817982, lng: 19.3266511 },
    { lat: 48.4823409, lng: 19.3275575 },
    { lat: 48.4824593, lng: 19.3276538 },
    { lat: 48.4825137, lng: 19.3281772 },
    { lat: 48.4823459, lng: 19.3302777 },
    { lat: 48.4825367, lng: 19.3311104 },
    { lat: 48.4829459, lng: 19.3318388 },
    { lat: 48.4851812, lng: 19.3345059 },
    { lat: 48.4857102, lng: 19.3367534 },
    { lat: 48.4863806, lng: 19.346854 },
    { lat: 48.4865886, lng: 19.3492998 },
    { lat: 48.4866968, lng: 19.3495567 },
    { lat: 48.4868773, lng: 19.349608 },
    { lat: 48.4870302, lng: 19.3498867 },
    { lat: 48.4872845, lng: 19.3501833 },
    { lat: 48.4876376, lng: 19.3515355 },
    { lat: 48.4880522, lng: 19.352223 },
    { lat: 48.4882434, lng: 19.3524472 },
    { lat: 48.4882932, lng: 19.3526213 },
    { lat: 48.4885675, lng: 19.3527917 },
    { lat: 48.4888266, lng: 19.3531429 },
    { lat: 48.4889395, lng: 19.353411 },
    { lat: 48.4890365, lng: 19.3543116 },
    { lat: 48.4891471, lng: 19.3546894 },
    { lat: 48.4893456, lng: 19.355099 },
    { lat: 48.4899652, lng: 19.3558001 },
    { lat: 48.4903499, lng: 19.3561406 },
    { lat: 48.4910499, lng: 19.3558572 },
    { lat: 48.4913516, lng: 19.3556364 },
    { lat: 48.49164, lng: 19.3553239 },
    { lat: 48.4921466, lng: 19.3553144 },
    { lat: 48.4925704, lng: 19.3555043 },
    { lat: 48.4927382, lng: 19.3556653 },
    { lat: 48.4932246, lng: 19.3566742 },
    { lat: 48.4937872, lng: 19.3574337 },
    { lat: 48.4941952, lng: 19.3577628 },
    { lat: 48.4945569, lng: 19.3579527 },
    { lat: 48.4956467, lng: 19.3583523 },
    { lat: 48.4961733, lng: 19.3586431 },
    { lat: 48.4965419, lng: 19.359023 },
    { lat: 48.4972591, lng: 19.3595978 },
    { lat: 48.4980693, lng: 19.3597828 },
    { lat: 48.4984878, lng: 19.3596925 },
    { lat: 48.4989928, lng: 19.3596982 },
    { lat: 48.4997603, lng: 19.3595526 },
    { lat: 48.5001363, lng: 19.3594044 },
    { lat: 48.5006005, lng: 19.3586173 },
    { lat: 48.5011114, lng: 19.3589802 },
    { lat: 48.5014831, lng: 19.3589238 },
    { lat: 48.5019489, lng: 19.358956 },
    { lat: 48.5025445, lng: 19.3588001 },
    { lat: 48.5031945, lng: 19.358488 },
    { lat: 48.5043254, lng: 19.3581177 },
    { lat: 48.505687, lng: 19.3564355 },
    { lat: 48.5063615, lng: 19.3555008 },
    { lat: 48.5067625, lng: 19.3550702 },
    { lat: 48.5071758, lng: 19.354845 },
    { lat: 48.5075498, lng: 19.3545353 },
    { lat: 48.5078464, lng: 19.3541137 },
    { lat: 48.5081859, lng: 19.3537639 },
    { lat: 48.5082186, lng: 19.3536214 },
    { lat: 48.5085373, lng: 19.3530976 },
    { lat: 48.5085127, lng: 19.353027 },
    { lat: 48.5085519, lng: 19.35286 },
    { lat: 48.5087498, lng: 19.3523531 },
    { lat: 48.5090543, lng: 19.3518094 },
    { lat: 48.5094593, lng: 19.3506819 },
    { lat: 48.5095683, lng: 19.3503677 },
    { lat: 48.5095833, lng: 19.3496214 },
    { lat: 48.5096988, lng: 19.3494443 },
    { lat: 48.51015, lng: 19.3495193 },
    { lat: 48.5110035, lng: 19.3487447 },
    { lat: 48.5117386, lng: 19.3479938 },
    { lat: 48.513108, lng: 19.3462459 },
    { lat: 48.5139138, lng: 19.3455811 },
    { lat: 48.5147726, lng: 19.3443486 },
    { lat: 48.5132173, lng: 19.3405719 },
    { lat: 48.5133141, lng: 19.3394825 },
    { lat: 48.5136331, lng: 19.3397441 },
    { lat: 48.5141595, lng: 19.3375262 },
    { lat: 48.5147191, lng: 19.336029 },
    { lat: 48.5162918, lng: 19.3343624 },
    { lat: 48.5194245, lng: 19.3336385 },
    { lat: 48.5184347, lng: 19.3269431 },
    { lat: 48.5180848, lng: 19.3251206 },
    { lat: 48.5181201, lng: 19.3245596 },
    { lat: 48.5178844, lng: 19.3232186 },
    { lat: 48.5174896, lng: 19.3228558 },
    { lat: 48.5177717, lng: 19.3222076 },
    { lat: 48.5175556, lng: 19.3206842 },
    { lat: 48.5174427, lng: 19.3203043 },
    { lat: 48.5173368, lng: 19.3195969 },
    { lat: 48.51733, lng: 19.3191006 },
    { lat: 48.51721, lng: 19.3188588 },
    { lat: 48.517272, lng: 19.3186185 },
    { lat: 48.5172156, lng: 19.3181965 },
    { lat: 48.5170475, lng: 19.3178184 },
    { lat: 48.5165545, lng: 19.3171513 },
    { lat: 48.5163975, lng: 19.3165576 },
    { lat: 48.5159935, lng: 19.3164273 },
    { lat: 48.5157923, lng: 19.3162053 },
    { lat: 48.5151984, lng: 19.3161102 },
    { lat: 48.5144615, lng: 19.3163072 },
    { lat: 48.5143845, lng: 19.3165328 },
    { lat: 48.5142044, lng: 19.3163735 },
    { lat: 48.5139117, lng: 19.3165009 },
    { lat: 48.5138149, lng: 19.3163828 },
    { lat: 48.5137667, lng: 19.3160371 },
    { lat: 48.5134739, lng: 19.3158514 },
    { lat: 48.5132884, lng: 19.3156536 },
    { lat: 48.5131645, lng: 19.315725 },
    { lat: 48.5130952, lng: 19.3156693 },
    { lat: 48.5129483, lng: 19.3153529 },
    { lat: 48.512618, lng: 19.3152135 },
    { lat: 48.5122874, lng: 19.3152992 },
    { lat: 48.5119185, lng: 19.3148654 },
    { lat: 48.5116026, lng: 19.3150018 },
    { lat: 48.5112189, lng: 19.314571 },
    { lat: 48.5111933, lng: 19.3141554 },
    { lat: 48.5109666, lng: 19.3137759 },
    { lat: 48.5107699, lng: 19.3135758 },
    { lat: 48.5105257, lng: 19.3135611 },
    { lat: 48.5101576, lng: 19.313145 },
    { lat: 48.5098702, lng: 19.31291 },
    { lat: 48.5097915, lng: 19.3129623 },
    { lat: 48.5095662, lng: 19.3134816 },
    { lat: 48.5094284, lng: 19.313573 },
    { lat: 48.509958, lng: 19.3145538 },
    { lat: 48.5096228, lng: 19.3149656 },
    { lat: 48.5092825, lng: 19.3151846 },
    { lat: 48.5092367, lng: 19.3151192 },
    { lat: 48.5079391, lng: 19.3172983 },
    { lat: 48.5072019, lng: 19.3167592 },
    { lat: 48.5071366, lng: 19.3169245 },
    { lat: 48.5070494, lng: 19.3168458 },
    { lat: 48.5066133, lng: 19.3167062 },
    { lat: 48.5066083, lng: 19.3168624 },
    { lat: 48.5062799, lng: 19.3180277 },
    { lat: 48.5062749, lng: 19.3182294 },
    { lat: 48.5063228, lng: 19.3184041 },
    { lat: 48.5062353, lng: 19.3185792 },
    { lat: 48.5060531, lng: 19.31847 },
    { lat: 48.5049758, lng: 19.317371 },
    { lat: 48.5036976, lng: 19.3194611 },
    { lat: 48.5034238, lng: 19.3197208 },
    { lat: 48.5032618, lng: 19.3197999 },
    { lat: 48.5031325, lng: 19.319971 },
    { lat: 48.5026294, lng: 19.3201024 },
    { lat: 48.502316, lng: 19.3198663 },
    { lat: 48.5022039, lng: 19.3199489 },
    { lat: 48.5021098, lng: 19.3198667 },
    { lat: 48.5021266, lng: 19.3197703 },
    { lat: 48.5016385, lng: 19.3193918 },
    { lat: 48.5014716, lng: 19.3193001 },
    { lat: 48.5012704, lng: 19.3193358 },
    { lat: 48.5010722, lng: 19.3197582 },
    { lat: 48.5000981, lng: 19.3209988 },
    { lat: 48.4999353, lng: 19.3208988 },
    { lat: 48.4994987, lng: 19.3202322 },
    { lat: 48.4994353, lng: 19.3202238 },
    { lat: 48.4993883, lng: 19.3199243 },
    { lat: 48.4991617, lng: 19.3192424 },
    { lat: 48.4988501, lng: 19.3193736 },
    { lat: 48.4984066, lng: 19.3205391 },
    { lat: 48.4979567, lng: 19.3207464 },
    { lat: 48.4978164, lng: 19.3222151 },
    { lat: 48.497438, lng: 19.3222932 },
    { lat: 48.4972992, lng: 19.3224763 },
    { lat: 48.4967355, lng: 19.3235084 },
    { lat: 48.4967215, lng: 19.3236648 },
    { lat: 48.4960141, lng: 19.3233446 },
    { lat: 48.4954099, lng: 19.3232996 },
    { lat: 48.4951311, lng: 19.3234311 },
    { lat: 48.4947842, lng: 19.3240729 },
    { lat: 48.4942564, lng: 19.3239464 },
    { lat: 48.4941326, lng: 19.323689 },
    { lat: 48.4932165, lng: 19.323653 },
    { lat: 48.4930456, lng: 19.3237085 },
    { lat: 48.4930306, lng: 19.3237943 },
    { lat: 48.4928193, lng: 19.323689 },
    { lat: 48.4927583, lng: 19.3237805 },
    { lat: 48.4925565, lng: 19.3237782 },
    { lat: 48.4922295, lng: 19.3240564 },
    { lat: 48.4920972, lng: 19.3240718 },
    { lat: 48.4915703, lng: 19.3245546 },
    { lat: 48.4914634, lng: 19.324514 },
    { lat: 48.4913511, lng: 19.3246893 },
    { lat: 48.4909671, lng: 19.3246694 },
    { lat: 48.4907153, lng: 19.3244466 },
    { lat: 48.4905589, lng: 19.3241004 },
    { lat: 48.4904309, lng: 19.3241363 },
    { lat: 48.4901932, lng: 19.3239954 },
    { lat: 48.4901604, lng: 19.3240413 },
    { lat: 48.4900316, lng: 19.3238214 },
    { lat: 48.4902215, lng: 19.3236011 },
    { lat: 48.4903538, lng: 19.323326 },
    { lat: 48.4907236, lng: 19.3235858 },
    { lat: 48.490531, lng: 19.3223399 },
    { lat: 48.4901897, lng: 19.3222753 },
    { lat: 48.4900633, lng: 19.3230043 },
    { lat: 48.4895583, lng: 19.3230495 },
    { lat: 48.489275, lng: 19.3228979 },
    { lat: 48.4889911, lng: 19.3223616 },
    { lat: 48.488831, lng: 19.3221697 },
    { lat: 48.4888122, lng: 19.3218022 },
    { lat: 48.4884839, lng: 19.3218225 },
    { lat: 48.4883079, lng: 19.3214657 },
    { lat: 48.4881994, lng: 19.3214341 },
    { lat: 48.487968, lng: 19.3216642 },
    { lat: 48.487643, lng: 19.320431 },
    { lat: 48.4877618, lng: 19.3203713 },
    { lat: 48.4877558, lng: 19.3202919 },
    { lat: 48.4878657, lng: 19.3202584 },
    { lat: 48.4877983, lng: 19.319932 },
    { lat: 48.4876449, lng: 19.3195539 },
    { lat: 48.4875967, lng: 19.3190667 },
    { lat: 48.4877064, lng: 19.3188871 },
    { lat: 48.4876563, lng: 19.3183347 },
    { lat: 48.486879, lng: 19.3156156 },
    { lat: 48.4865396, lng: 19.3146509 },
    { lat: 48.4855868, lng: 19.3108627 },
  ],
  Dúbravy: [
    { lat: 48.5695567, lng: 19.3773992 },
    { lat: 48.5708637, lng: 19.3778686 },
    { lat: 48.5714432, lng: 19.3792533 },
    { lat: 48.5721678, lng: 19.3793248 },
    { lat: 48.572768, lng: 19.3788567 },
    { lat: 48.5747936, lng: 19.378556 },
    { lat: 48.5778421, lng: 19.3773828 },
    { lat: 48.578519, lng: 19.3772534 },
    { lat: 48.5808522, lng: 19.3779289 },
    { lat: 48.5810545, lng: 19.3783211 },
    { lat: 48.5825853, lng: 19.3791314 },
    { lat: 48.5840075, lng: 19.3795854 },
    { lat: 48.5845074, lng: 19.3802877 },
    { lat: 48.5847447, lng: 19.3804897 },
    { lat: 48.5851625, lng: 19.3804099 },
    { lat: 48.5858096, lng: 19.3799423 },
    { lat: 48.5862369, lng: 19.3797539 },
    { lat: 48.5865532, lng: 19.378956 },
    { lat: 48.5873196, lng: 19.3796695 },
    { lat: 48.5878378, lng: 19.3793821 },
    { lat: 48.5883172, lng: 19.3788666 },
    { lat: 48.5882608, lng: 19.378124 },
    { lat: 48.5888908, lng: 19.3787711 },
    { lat: 48.5894274, lng: 19.3779875 },
    { lat: 48.5900481, lng: 19.3772154 },
    { lat: 48.5901726, lng: 19.3773704 },
    { lat: 48.5906099, lng: 19.3769898 },
    { lat: 48.5915847, lng: 19.3785137 },
    { lat: 48.5917945, lng: 19.3787044 },
    { lat: 48.592242, lng: 19.3788485 },
    { lat: 48.5924677, lng: 19.3790713 },
    { lat: 48.5930357, lng: 19.3798554 },
    { lat: 48.5935298, lng: 19.3806529 },
    { lat: 48.5932462, lng: 19.3811829 },
    { lat: 48.5937145, lng: 19.3816477 },
    { lat: 48.5942428, lng: 19.3828356 },
    { lat: 48.5959478, lng: 19.3878842 },
    { lat: 48.5962632, lng: 19.3881202 },
    { lat: 48.5962311, lng: 19.3889212 },
    { lat: 48.597452, lng: 19.3901435 },
    { lat: 48.5979336, lng: 19.3905091 },
    { lat: 48.5998638, lng: 19.3929799 },
    { lat: 48.6004406, lng: 19.3935027 },
    { lat: 48.6009686, lng: 19.3946624 },
    { lat: 48.6013332, lng: 19.3952458 },
    { lat: 48.6020877, lng: 19.3959703 },
    { lat: 48.6028373, lng: 19.3968382 },
    { lat: 48.6034504, lng: 19.397985 },
    { lat: 48.6037937, lng: 19.399646 },
    { lat: 48.6039925, lng: 19.4011371 },
    { lat: 48.6043658, lng: 19.4027259 },
    { lat: 48.6040775, lng: 19.4050227 },
    { lat: 48.6043064, lng: 19.4075169 },
    { lat: 48.6046999, lng: 19.4078544 },
    { lat: 48.605099, lng: 19.4083163 },
    { lat: 48.6052844, lng: 19.4088032 },
    { lat: 48.6053336, lng: 19.4088186 },
    { lat: 48.6055334, lng: 19.41055 },
    { lat: 48.6057953, lng: 19.4109671 },
    { lat: 48.6065332, lng: 19.4117525 },
    { lat: 48.6073246, lng: 19.411879 },
    { lat: 48.608269, lng: 19.4124066 },
    { lat: 48.6083572, lng: 19.4141368 },
    { lat: 48.6081004, lng: 19.4149278 },
    { lat: 48.608025, lng: 19.416469 },
    { lat: 48.608879, lng: 19.416963 },
    { lat: 48.608887, lng: 19.417165 },
    { lat: 48.608958, lng: 19.417348 },
    { lat: 48.608985, lng: 19.417418 },
    { lat: 48.609252, lng: 19.418063 },
    { lat: 48.609614, lng: 19.418478 },
    { lat: 48.609931, lng: 19.418659 },
    { lat: 48.610066, lng: 19.418358 },
    { lat: 48.610118, lng: 19.418181 },
    { lat: 48.610105, lng: 19.418109 },
    { lat: 48.610195, lng: 19.417473 },
    { lat: 48.610187, lng: 19.417318 },
    { lat: 48.610175, lng: 19.417075 },
    { lat: 48.6101, lng: 19.416736 },
    { lat: 48.610034, lng: 19.416297 },
    { lat: 48.60987, lng: 19.415775 },
    { lat: 48.609854, lng: 19.415328 },
    { lat: 48.609776, lng: 19.41516 },
    { lat: 48.609653, lng: 19.414822 },
    { lat: 48.609488, lng: 19.414408 },
    { lat: 48.609279, lng: 19.413085 },
    { lat: 48.609289, lng: 19.412424 },
    { lat: 48.609509, lng: 19.412174 },
    { lat: 48.610261, lng: 19.411573 },
    { lat: 48.610501, lng: 19.412855 },
    { lat: 48.610576, lng: 19.413572 },
    { lat: 48.610875, lng: 19.413606 },
    { lat: 48.611017, lng: 19.413769 },
    { lat: 48.611116, lng: 19.413783 },
    { lat: 48.611423, lng: 19.414277 },
    { lat: 48.611461, lng: 19.414659 },
    { lat: 48.611457, lng: 19.414916 },
    { lat: 48.611914, lng: 19.414413 },
    { lat: 48.611995, lng: 19.41438 },
    { lat: 48.612275, lng: 19.41361 },
    { lat: 48.612692, lng: 19.4128 },
    { lat: 48.612933, lng: 19.412111 },
    { lat: 48.6131, lng: 19.411743 },
    { lat: 48.613326, lng: 19.411331 },
    { lat: 48.613802, lng: 19.410522 },
    { lat: 48.613994, lng: 19.410101 },
    { lat: 48.614115, lng: 19.409893 },
    { lat: 48.614187, lng: 19.409793 },
    { lat: 48.614279, lng: 19.409709 },
    { lat: 48.614348, lng: 19.409681 },
    { lat: 48.614391, lng: 19.409664 },
    { lat: 48.614592, lng: 19.409584 },
    { lat: 48.614706, lng: 19.409534 },
    { lat: 48.614785, lng: 19.409464 },
    { lat: 48.61493, lng: 19.409243 },
    { lat: 48.615139, lng: 19.409133 },
    { lat: 48.615197, lng: 19.409049 },
    { lat: 48.615242, lng: 19.408924 },
    { lat: 48.615265, lng: 19.408178 },
    { lat: 48.615392, lng: 19.407908 },
    { lat: 48.615632, lng: 19.407637 },
    { lat: 48.615762, lng: 19.407375 },
    { lat: 48.61591, lng: 19.407123 },
    { lat: 48.616033, lng: 19.406957 },
    { lat: 48.61617, lng: 19.40682 },
    { lat: 48.61636, lng: 19.406689 },
    { lat: 48.616542, lng: 19.406592 },
    { lat: 48.616718, lng: 19.406558 },
    { lat: 48.617086, lng: 19.406551 },
    { lat: 48.617573, lng: 19.406503 },
    { lat: 48.617705, lng: 19.405591 },
    { lat: 48.617803, lng: 19.404153 },
    { lat: 48.617236, lng: 19.403886 },
    { lat: 48.616837, lng: 19.403727 },
    { lat: 48.616655, lng: 19.403622 },
    { lat: 48.616398, lng: 19.403255 },
    { lat: 48.616283, lng: 19.403191 },
    { lat: 48.616023, lng: 19.402807 },
    { lat: 48.616004, lng: 19.4025 },
    { lat: 48.616113, lng: 19.402439 },
    { lat: 48.616184, lng: 19.402223 },
    { lat: 48.616147, lng: 19.401975 },
    { lat: 48.615869, lng: 19.401778 },
    { lat: 48.615737, lng: 19.401543 },
    { lat: 48.615664, lng: 19.40123 },
    { lat: 48.615678, lng: 19.401154 },
    { lat: 48.615689, lng: 19.400997 },
    { lat: 48.615723, lng: 19.400715 },
    { lat: 48.615573, lng: 19.400541 },
    { lat: 48.61588, lng: 19.399164 },
    { lat: 48.616027, lng: 19.399233 },
    { lat: 48.616284, lng: 19.399248 },
    { lat: 48.616462, lng: 19.399459 },
    { lat: 48.616753, lng: 19.398661 },
    { lat: 48.616867, lng: 19.398417 },
    { lat: 48.617006, lng: 19.397897 },
    { lat: 48.617049, lng: 19.397497 },
    { lat: 48.616916, lng: 19.397 },
    { lat: 48.617002, lng: 19.396451 },
    { lat: 48.616948, lng: 19.396109 },
    { lat: 48.616908, lng: 19.39564 },
    { lat: 48.617, lng: 19.395379 },
    { lat: 48.616995, lng: 19.395058 },
    { lat: 48.617038, lng: 19.394853 },
    { lat: 48.617088, lng: 19.394665 },
    { lat: 48.617003, lng: 19.394157 },
    { lat: 48.616917, lng: 19.394102 },
    { lat: 48.616989, lng: 19.394077 },
    { lat: 48.617027, lng: 19.394021 },
    { lat: 48.617079, lng: 19.393945 },
    { lat: 48.617148, lng: 19.393846 },
    { lat: 48.617154, lng: 19.393835 },
    { lat: 48.617235, lng: 19.39372 },
    { lat: 48.617261, lng: 19.393615 },
    { lat: 48.617417, lng: 19.393866 },
    { lat: 48.617596, lng: 19.394301 },
    { lat: 48.617619, lng: 19.394436 },
    { lat: 48.617667, lng: 19.394407 },
    { lat: 48.617939, lng: 19.394242 },
    { lat: 48.618028, lng: 19.394093 },
    { lat: 48.618262, lng: 19.393933 },
    { lat: 48.618605, lng: 19.393813 },
    { lat: 48.619092, lng: 19.393676 },
    { lat: 48.619447, lng: 19.393609 },
    { lat: 48.619477, lng: 19.393526 },
    { lat: 48.619477, lng: 19.393433 },
    { lat: 48.619412, lng: 19.393069 },
    { lat: 48.619252, lng: 19.392721 },
    { lat: 48.619359, lng: 19.392077 },
    { lat: 48.619477, lng: 19.391806 },
    { lat: 48.619731, lng: 19.39076 },
    { lat: 48.619441, lng: 19.390698 },
    { lat: 48.619461, lng: 19.390498 },
    { lat: 48.619594, lng: 19.389988 },
    { lat: 48.619667, lng: 19.389626 },
    { lat: 48.619915, lng: 19.388704 },
    { lat: 48.61988, lng: 19.388498 },
    { lat: 48.619481, lng: 19.388168 },
    { lat: 48.618689, lng: 19.387664 },
    { lat: 48.618206, lng: 19.387423 },
    { lat: 48.618684, lng: 19.387324 },
    { lat: 48.619089, lng: 19.387071 },
    { lat: 48.619327, lng: 19.386954 },
    { lat: 48.619452, lng: 19.386681 },
    { lat: 48.619444, lng: 19.386098 },
    { lat: 48.619284, lng: 19.385531 },
    { lat: 48.61969, lng: 19.38548 },
    { lat: 48.620114, lng: 19.385455 },
    { lat: 48.62016, lng: 19.384924 },
    { lat: 48.620191, lng: 19.384694 },
    { lat: 48.620242, lng: 19.384299 },
    { lat: 48.620335, lng: 19.38382 },
    { lat: 48.620343, lng: 19.383482 },
    { lat: 48.620351, lng: 19.383145 },
    { lat: 48.620354, lng: 19.382595 },
    { lat: 48.620553, lng: 19.381929 },
    { lat: 48.620516, lng: 19.381923 },
    { lat: 48.620378, lng: 19.3819 },
    { lat: 48.620201, lng: 19.381891 },
    { lat: 48.619755, lng: 19.381868 },
    { lat: 48.619634, lng: 19.380331 },
    { lat: 48.619255, lng: 19.380135 },
    { lat: 48.61845, lng: 19.379807 },
    { lat: 48.618045, lng: 19.379502 },
    { lat: 48.617495, lng: 19.379487 },
    { lat: 48.617366, lng: 19.378373 },
    { lat: 48.617304, lng: 19.378203 },
    { lat: 48.616998, lng: 19.37731 },
    { lat: 48.616931, lng: 19.376981 },
    { lat: 48.616827, lng: 19.376327 },
    { lat: 48.616652, lng: 19.375744 },
    { lat: 48.616277, lng: 19.375402 },
    { lat: 48.615637, lng: 19.374708 },
    { lat: 48.615169, lng: 19.374152 },
    { lat: 48.614828, lng: 19.373793 },
    { lat: 48.614392, lng: 19.373591 },
    { lat: 48.614068, lng: 19.373584 },
    { lat: 48.613694, lng: 19.373644 },
    { lat: 48.613404, lng: 19.373816 },
    { lat: 48.612789, lng: 19.373349 },
    { lat: 48.611915, lng: 19.371601 },
    { lat: 48.613622, lng: 19.370797 },
    { lat: 48.613499, lng: 19.370391 },
    { lat: 48.613669, lng: 19.369416 },
    { lat: 48.613497, lng: 19.368435 },
    { lat: 48.613355, lng: 19.367785 },
    { lat: 48.613238, lng: 19.367034 },
    { lat: 48.613057, lng: 19.367112 },
    { lat: 48.61304, lng: 19.365508 },
    { lat: 48.61284, lng: 19.36495 },
    { lat: 48.612069, lng: 19.36392 },
    { lat: 48.611648, lng: 19.363404 },
    { lat: 48.611307, lng: 19.362963 },
    { lat: 48.611591, lng: 19.362682 },
    { lat: 48.611614, lng: 19.36266 },
    { lat: 48.610472, lng: 19.361571 },
    { lat: 48.610335, lng: 19.360869 },
    { lat: 48.609913, lng: 19.360282 },
    { lat: 48.609771, lng: 19.36008 },
    { lat: 48.609401, lng: 19.359613 },
    { lat: 48.609475, lng: 19.35911 },
    { lat: 48.610054, lng: 19.358003 },
    { lat: 48.609118, lng: 19.357713 },
    { lat: 48.608645, lng: 19.357404 },
    { lat: 48.60763, lng: 19.356556 },
    { lat: 48.606581, lng: 19.355692 },
    { lat: 48.605486, lng: 19.354789 },
    { lat: 48.605394, lng: 19.35466 },
    { lat: 48.605234, lng: 19.354436 },
    { lat: 48.605256, lng: 19.354197 },
    { lat: 48.60529, lng: 19.353803 },
    { lat: 48.60532, lng: 19.353451 },
    { lat: 48.605433, lng: 19.352108 },
    { lat: 48.605479, lng: 19.350878 },
    { lat: 48.605737, lng: 19.349937 },
    { lat: 48.605909, lng: 19.349312 },
    { lat: 48.606145, lng: 19.3482 },
    { lat: 48.606543, lng: 19.347711 },
    { lat: 48.606575, lng: 19.347453 },
    { lat: 48.606749, lng: 19.346048 },
    { lat: 48.606681, lng: 19.344573 },
    { lat: 48.606661, lng: 19.344124 },
    { lat: 48.60657, lng: 19.343905 },
    { lat: 48.606538, lng: 19.343827 },
    { lat: 48.606356, lng: 19.343383 },
    { lat: 48.60628, lng: 19.343086 },
    { lat: 48.606162, lng: 19.342628 },
    { lat: 48.606076, lng: 19.341369 },
    { lat: 48.606161, lng: 19.339995 },
    { lat: 48.605894, lng: 19.33798 },
    { lat: 48.606018, lng: 19.336624 },
    { lat: 48.606153, lng: 19.335684 },
    { lat: 48.606312, lng: 19.33519 },
    { lat: 48.607059, lng: 19.333546 },
    { lat: 48.60694, lng: 19.332566 },
    { lat: 48.606717, lng: 19.331492 },
    { lat: 48.606762, lng: 19.331221 },
    { lat: 48.606394, lng: 19.329625 },
    { lat: 48.606399, lng: 19.329504 },
    { lat: 48.606417, lng: 19.329012 },
    { lat: 48.605824, lng: 19.328844 },
    { lat: 48.605313, lng: 19.329631 },
    { lat: 48.604868, lng: 19.330099 },
    { lat: 48.604726, lng: 19.329188 },
    { lat: 48.60449, lng: 19.329278 },
    { lat: 48.604373, lng: 19.328501 },
    { lat: 48.603457, lng: 19.329346 },
    { lat: 48.602675, lng: 19.330432 },
    { lat: 48.602391, lng: 19.330905 },
    { lat: 48.602165, lng: 19.331136 },
    { lat: 48.601649, lng: 19.33169 },
    { lat: 48.601505, lng: 19.331455 },
    { lat: 48.601075, lng: 19.331482 },
    { lat: 48.600719, lng: 19.331135 },
    { lat: 48.600679, lng: 19.331126 },
    { lat: 48.600626, lng: 19.331116 },
    { lat: 48.600452, lng: 19.331081 },
    { lat: 48.600393, lng: 19.329749 },
    { lat: 48.600399, lng: 19.329329 },
    { lat: 48.600401, lng: 19.329153 },
    { lat: 48.600408, lng: 19.328603 },
    { lat: 48.600233, lng: 19.327737 },
    { lat: 48.60026, lng: 19.327221 },
    { lat: 48.598614, lng: 19.324258 },
    { lat: 48.598358, lng: 19.32399 },
    { lat: 48.596548, lng: 19.322599 },
    { lat: 48.59393, lng: 19.320577 },
    { lat: 48.591057, lng: 19.318358 },
    { lat: 48.589797, lng: 19.321412 },
    { lat: 48.588975, lng: 19.323351 },
    { lat: 48.588255, lng: 19.325337 },
    { lat: 48.588189, lng: 19.325597 },
    { lat: 48.587987, lng: 19.326374 },
    { lat: 48.587616, lng: 19.328384 },
    { lat: 48.587012, lng: 19.331736 },
    { lat: 48.58697, lng: 19.331711 },
    { lat: 48.586887, lng: 19.331661 },
    { lat: 48.586857, lng: 19.331644 },
    { lat: 48.586838, lng: 19.331633 },
    { lat: 48.586746, lng: 19.331578 },
    { lat: 48.586705, lng: 19.331553 },
    { lat: 48.586263, lng: 19.33129 },
    { lat: 48.586251, lng: 19.331283 },
    { lat: 48.586146, lng: 19.331643 },
    { lat: 48.586113, lng: 19.331758 },
    { lat: 48.585799, lng: 19.331515 },
    { lat: 48.585666, lng: 19.331829 },
    { lat: 48.585602, lng: 19.331981 },
    { lat: 48.585336, lng: 19.332611 },
    { lat: 48.585781, lng: 19.333082 },
    { lat: 48.585721, lng: 19.333232 },
    { lat: 48.585643, lng: 19.333426 },
    { lat: 48.585577, lng: 19.333591 },
    { lat: 48.585566, lng: 19.333579 },
    { lat: 48.58543, lng: 19.333415 },
    { lat: 48.585284, lng: 19.33324 },
    { lat: 48.58514, lng: 19.333068 },
    { lat: 48.584999, lng: 19.3329 },
    { lat: 48.584867, lng: 19.33274 },
    { lat: 48.584748, lng: 19.332599 },
    { lat: 48.584613, lng: 19.332436 },
    { lat: 48.584489, lng: 19.332287 },
    { lat: 48.58436, lng: 19.332132 },
    { lat: 48.584206, lng: 19.331948 },
    { lat: 48.584067, lng: 19.331782 },
    { lat: 48.583693, lng: 19.332867 },
    { lat: 48.583564, lng: 19.332754 },
    { lat: 48.583476, lng: 19.332676 },
    { lat: 48.583227, lng: 19.333207 },
    { lat: 48.583212, lng: 19.333248 },
    { lat: 48.583179, lng: 19.333318 },
    { lat: 48.583164, lng: 19.333344 },
    { lat: 48.584406, lng: 19.334707 },
    { lat: 48.584372, lng: 19.334782 },
    { lat: 48.584313, lng: 19.334916 },
    { lat: 48.584295, lng: 19.334956 },
    { lat: 48.584246, lng: 19.335066 },
    { lat: 48.584119, lng: 19.335353 },
    { lat: 48.583927, lng: 19.335786 },
    { lat: 48.583013, lng: 19.334855 },
    { lat: 48.582779, lng: 19.335424 },
    { lat: 48.582607, lng: 19.335841 },
    { lat: 48.582475, lng: 19.336163 },
    { lat: 48.58287, lng: 19.336983 },
    { lat: 48.582887, lng: 19.337006 },
    { lat: 48.583961, lng: 19.336782 },
    { lat: 48.583843, lng: 19.336893 },
    { lat: 48.583775, lng: 19.337099 },
    { lat: 48.58372, lng: 19.337525 },
    { lat: 48.583484, lng: 19.337727 },
    { lat: 48.583713, lng: 19.337861 },
    { lat: 48.584031, lng: 19.338045 },
    { lat: 48.584135, lng: 19.338081 },
    { lat: 48.584186, lng: 19.338132 },
    { lat: 48.584242, lng: 19.338217 },
    { lat: 48.584164, lng: 19.338282 },
    { lat: 48.584163, lng: 19.338381 },
    { lat: 48.584133, lng: 19.338535 },
    { lat: 48.58369, lng: 19.339241 },
    { lat: 48.58336, lng: 19.340425 },
    { lat: 48.583297, lng: 19.340873 },
    { lat: 48.583313, lng: 19.341059 },
    { lat: 48.583284, lng: 19.341081 },
    { lat: 48.583241, lng: 19.341097 },
    { lat: 48.583178, lng: 19.341145 },
    { lat: 48.582627, lng: 19.341777 },
    { lat: 48.581074, lng: 19.343759 },
    { lat: 48.580503, lng: 19.344715 },
    { lat: 48.579993, lng: 19.346656 },
    { lat: 48.57975, lng: 19.347042 },
    { lat: 48.579674, lng: 19.347193 },
    { lat: 48.57952, lng: 19.348944 },
    { lat: 48.5794115, lng: 19.3494153 },
    { lat: 48.579375, lng: 19.349574 },
    { lat: 48.578919, lng: 19.350901 },
    { lat: 48.578902, lng: 19.350949 },
    { lat: 48.578337, lng: 19.353915 },
    { lat: 48.578183, lng: 19.354386 },
    { lat: 48.577765, lng: 19.355255 },
    { lat: 48.57768, lng: 19.355498 },
    { lat: 48.57689, lng: 19.358359 },
    { lat: 48.576828, lng: 19.35848 },
    { lat: 48.576678, lng: 19.35877 },
    { lat: 48.575482, lng: 19.362613 },
    { lat: 48.575444, lng: 19.362736 },
    { lat: 48.574655, lng: 19.363604 },
    { lat: 48.573957, lng: 19.364682 },
    { lat: 48.573869, lng: 19.364739 },
    { lat: 48.573859, lng: 19.364804 },
    { lat: 48.573794, lng: 19.364847 },
    { lat: 48.573192, lng: 19.365255 },
    { lat: 48.572597, lng: 19.366341 },
    { lat: 48.57228, lng: 19.367255 },
    { lat: 48.572228, lng: 19.367207 },
    { lat: 48.571773, lng: 19.366889 },
    { lat: 48.571761, lng: 19.36688 },
    { lat: 48.571123, lng: 19.366479 },
    { lat: 48.57076, lng: 19.36625 },
    { lat: 48.570759, lng: 19.366059 },
    { lat: 48.57071, lng: 19.365786 },
    { lat: 48.570356, lng: 19.364582 },
    { lat: 48.570182, lng: 19.364008 },
    { lat: 48.570075, lng: 19.363424 },
    { lat: 48.569981, lng: 19.362773 },
    { lat: 48.569784, lng: 19.362448 },
    { lat: 48.569538, lng: 19.362248 },
    { lat: 48.569101, lng: 19.362075 },
    { lat: 48.568689, lng: 19.361567 },
    { lat: 48.5682, lng: 19.360839 },
    { lat: 48.567747, lng: 19.360426 },
    { lat: 48.567624, lng: 19.358962 },
    { lat: 48.567451, lng: 19.358826 },
    { lat: 48.567078, lng: 19.358532 },
    { lat: 48.565397, lng: 19.359066 },
    { lat: 48.564692, lng: 19.359368 },
    { lat: 48.564445, lng: 19.359474 },
    { lat: 48.563863, lng: 19.359724 },
    { lat: 48.563789, lng: 19.359756 },
    { lat: 48.563689, lng: 19.359798 },
    { lat: 48.563647, lng: 19.359816 },
    { lat: 48.563624, lng: 19.359975 },
    { lat: 48.563605, lng: 19.359968 },
    { lat: 48.563511, lng: 19.359933 },
    { lat: 48.563273, lng: 19.360195 },
    { lat: 48.563242, lng: 19.360441 },
    { lat: 48.56257, lng: 19.361271 },
    { lat: 48.561494, lng: 19.362601 },
    { lat: 48.560922, lng: 19.363486 },
    { lat: 48.559792, lng: 19.365356 },
    { lat: 48.559581, lng: 19.365678 },
    { lat: 48.559149, lng: 19.366475 },
    { lat: 48.558982, lng: 19.366735 },
    { lat: 48.558965, lng: 19.366709 },
    { lat: 48.558641, lng: 19.367217 },
    { lat: 48.55865, lng: 19.367233 },
    { lat: 48.558587, lng: 19.367331 },
    { lat: 48.558577, lng: 19.367316 },
    { lat: 48.558518, lng: 19.367406 },
    { lat: 48.55853, lng: 19.367425 },
    { lat: 48.558249, lng: 19.367871 },
    { lat: 48.557769, lng: 19.368863 },
    { lat: 48.557168, lng: 19.370101 },
    { lat: 48.556795, lng: 19.371099 },
    { lat: 48.5570108, lng: 19.3710417 },
    { lat: 48.5570265, lng: 19.3711336 },
    { lat: 48.5575159, lng: 19.370592 },
    { lat: 48.5579229, lng: 19.3708893 },
    { lat: 48.5585816, lng: 19.3709366 },
    { lat: 48.5591697, lng: 19.3706737 },
    { lat: 48.559787, lng: 19.3711851 },
    { lat: 48.5600809, lng: 19.3705656 },
    { lat: 48.5621352, lng: 19.3718147 },
    { lat: 48.5641713, lng: 19.3739678 },
    { lat: 48.5656074, lng: 19.3758045 },
    { lat: 48.5662076, lng: 19.3767182 },
    { lat: 48.566887, lng: 19.3762882 },
    { lat: 48.5670905, lng: 19.3764923 },
    { lat: 48.5672004, lng: 19.3764342 },
    { lat: 48.5673491, lng: 19.3765007 },
    { lat: 48.567487, lng: 19.376725 },
    { lat: 48.5675674, lng: 19.3771363 },
    { lat: 48.5676712, lng: 19.3768533 },
    { lat: 48.5679389, lng: 19.3768213 },
    { lat: 48.5680275, lng: 19.3770028 },
    { lat: 48.5680186, lng: 19.3770743 },
    { lat: 48.5682204, lng: 19.3772657 },
    { lat: 48.5695567, lng: 19.3773992 },
  ],
  Stožok: [
    { lat: 48.4972591, lng: 19.3595978 },
    { lat: 48.4977132, lng: 19.3627924 },
    { lat: 48.5002025, lng: 19.3701117 },
    { lat: 48.5008506, lng: 19.3723507 },
    { lat: 48.5010156, lng: 19.372921 },
    { lat: 48.498462, lng: 19.3741635 },
    { lat: 48.498519, lng: 19.3819037 },
    { lat: 48.4989, lng: 19.3816169 },
    { lat: 48.4993994, lng: 19.3815242 },
    { lat: 48.4995498, lng: 19.3815581 },
    { lat: 48.4994356, lng: 19.3818846 },
    { lat: 48.4994928, lng: 19.3823532 },
    { lat: 48.4994466, lng: 19.3829495 },
    { lat: 48.4993255, lng: 19.38332 },
    { lat: 48.4994108, lng: 19.3834292 },
    { lat: 48.4993023, lng: 19.3838061 },
    { lat: 48.4992351, lng: 19.3838379 },
    { lat: 48.4992231, lng: 19.3840588 },
    { lat: 48.4994061, lng: 19.3842136 },
    { lat: 48.4994625, lng: 19.3843817 },
    { lat: 48.5059385, lng: 19.3792389 },
    { lat: 48.5060214, lng: 19.3792986 },
    { lat: 48.5064312, lng: 19.3790744 },
    { lat: 48.5066226, lng: 19.3794505 },
    { lat: 48.5066512, lng: 19.3798644 },
    { lat: 48.5065931, lng: 19.3799495 },
    { lat: 48.5064283, lng: 19.3799658 },
    { lat: 48.5063573, lng: 19.3803708 },
    { lat: 48.5064147, lng: 19.3804396 },
    { lat: 48.5064557, lng: 19.3807067 },
    { lat: 48.5065809, lng: 19.3807825 },
    { lat: 48.5066608, lng: 19.380566 },
    { lat: 48.5068908, lng: 19.3793336 },
    { lat: 48.5069045, lng: 19.3790751 },
    { lat: 48.5068284, lng: 19.3787055 },
    { lat: 48.5081956, lng: 19.378087 },
    { lat: 48.5082737, lng: 19.3773837 },
    { lat: 48.5083303, lng: 19.3771957 },
    { lat: 48.5085972, lng: 19.377027 },
    { lat: 48.5088337, lng: 19.3770701 },
    { lat: 48.5090869, lng: 19.3763971 },
    { lat: 48.5091544, lng: 19.3760835 },
    { lat: 48.5091831, lng: 19.3756425 },
    { lat: 48.508704, lng: 19.374575 },
    { lat: 48.5085512, lng: 19.3743199 },
    { lat: 48.508321, lng: 19.3741477 },
    { lat: 48.5084313, lng: 19.3739088 },
    { lat: 48.5087229, lng: 19.3734985 },
    { lat: 48.5091837, lng: 19.3722106 },
    { lat: 48.5093939, lng: 19.3720106 },
    { lat: 48.5095258, lng: 19.3717508 },
    { lat: 48.5097617, lng: 19.3716153 },
    { lat: 48.5099505, lng: 19.3713874 },
    { lat: 48.5103327, lng: 19.3706777 },
    { lat: 48.5105489, lng: 19.3699197 },
    { lat: 48.5107163, lng: 19.3696444 },
    { lat: 48.5111475, lng: 19.3691846 },
    { lat: 48.5111203, lng: 19.3690364 },
    { lat: 48.5109284, lng: 19.3687005 },
    { lat: 48.5110152, lng: 19.3685081 },
    { lat: 48.5111161, lng: 19.3678326 },
    { lat: 48.5112645, lng: 19.3677804 },
    { lat: 48.5115313, lng: 19.3672266 },
    { lat: 48.5116735, lng: 19.3666348 },
    { lat: 48.5116797, lng: 19.3663636 },
    { lat: 48.5122868, lng: 19.3667593 },
    { lat: 48.512625, lng: 19.3670716 },
    { lat: 48.5137882, lng: 19.3672599 },
    { lat: 48.5139097, lng: 19.3676802 },
    { lat: 48.5141488, lng: 19.3678073 },
    { lat: 48.5143349, lng: 19.3677823 },
    { lat: 48.5143597, lng: 19.3680206 },
    { lat: 48.5146451, lng: 19.3679778 },
    { lat: 48.5163255, lng: 19.3686178 },
    { lat: 48.5170635, lng: 19.3692284 },
    { lat: 48.5173986, lng: 19.3696652 },
    { lat: 48.5177233, lng: 19.371043 },
    { lat: 48.5178474, lng: 19.3719465 },
    { lat: 48.5176499, lng: 19.3726557 },
    { lat: 48.5172622, lng: 19.3733451 },
    { lat: 48.5167888, lng: 19.373936 },
    { lat: 48.5166491, lng: 19.3744359 },
    { lat: 48.5164551, lng: 19.3747916 },
    { lat: 48.5160893, lng: 19.375848 },
    { lat: 48.5160377, lng: 19.3762104 },
    { lat: 48.5160982, lng: 19.3766789 },
    { lat: 48.5160464, lng: 19.3771498 },
    { lat: 48.5177235, lng: 19.3758942 },
    { lat: 48.51814, lng: 19.3750766 },
    { lat: 48.5187264, lng: 19.3742488 },
    { lat: 48.5190534, lng: 19.3738731 },
    { lat: 48.5199825, lng: 19.3736889 },
    { lat: 48.52041, lng: 19.373723 },
    { lat: 48.5208352, lng: 19.3736104 },
    { lat: 48.5213, lng: 19.3733812 },
    { lat: 48.5215772, lng: 19.3728569 },
    { lat: 48.5220061, lng: 19.3726536 },
    { lat: 48.5223423, lng: 19.3719999 },
    { lat: 48.522394, lng: 19.3715912 },
    { lat: 48.5225897, lng: 19.3711808 },
    { lat: 48.5228624, lng: 19.3708699 },
    { lat: 48.5231877, lng: 19.3705725 },
    { lat: 48.5236267, lng: 19.3708008 },
    { lat: 48.5237867, lng: 19.3705646 },
    { lat: 48.5242341, lng: 19.3702786 },
    { lat: 48.5244106, lng: 19.3700754 },
    { lat: 48.5251924, lng: 19.3697577 },
    { lat: 48.5254188, lng: 19.3694972 },
    { lat: 48.5256008, lng: 19.3691015 },
    { lat: 48.5259346, lng: 19.3687505 },
    { lat: 48.5262371, lng: 19.3687154 },
    { lat: 48.5263824, lng: 19.3685466 },
    { lat: 48.5264783, lng: 19.3685469 },
    { lat: 48.5266117, lng: 19.3683676 },
    { lat: 48.5268518, lng: 19.3682702 },
    { lat: 48.5270152, lng: 19.3683647 },
    { lat: 48.5272337, lng: 19.3683849 },
    { lat: 48.5280233, lng: 19.3677843 },
    { lat: 48.5279909, lng: 19.3671716 },
    { lat: 48.5281572, lng: 19.3667803 },
    { lat: 48.5286561, lng: 19.3670093 },
    { lat: 48.5294534, lng: 19.3670194 },
    { lat: 48.5305492, lng: 19.3678779 },
    { lat: 48.5310429, lng: 19.3684026 },
    { lat: 48.5317088, lng: 19.3692989 },
    { lat: 48.5319198, lng: 19.3694717 },
    { lat: 48.5333138, lng: 19.3702534 },
    { lat: 48.5348733, lng: 19.3725227 },
    { lat: 48.5351615, lng: 19.3730877 },
    { lat: 48.5356717, lng: 19.3746719 },
    { lat: 48.5358572, lng: 19.3753956 },
    { lat: 48.5358153, lng: 19.3769382 },
    { lat: 48.5357642, lng: 19.3769579 },
    { lat: 48.5360215, lng: 19.3785534 },
    { lat: 48.5359852, lng: 19.378575 },
    { lat: 48.5360381, lng: 19.3792639 },
    { lat: 48.5359619, lng: 19.379319 },
    { lat: 48.5359721, lng: 19.3799627 },
    { lat: 48.5360763, lng: 19.3804042 },
    { lat: 48.5362148, lng: 19.3815665 },
    { lat: 48.5362328, lng: 19.3832033 },
    { lat: 48.5378048, lng: 19.3829143 },
    { lat: 48.5378416, lng: 19.3831237 },
    { lat: 48.5380699, lng: 19.3830377 },
    { lat: 48.5380639, lng: 19.3828409 },
    { lat: 48.5394306, lng: 19.3824502 },
    { lat: 48.5394915, lng: 19.3826884 },
    { lat: 48.5394254, lng: 19.3835337 },
    { lat: 48.5394722, lng: 19.3838072 },
    { lat: 48.5394262, lng: 19.3844068 },
    { lat: 48.5396921, lng: 19.3856191 },
    { lat: 48.5397169, lng: 19.3861446 },
    { lat: 48.5396858, lng: 19.3870093 },
    { lat: 48.5396258, lng: 19.387243 },
    { lat: 48.5394705, lng: 19.3875556 },
    { lat: 48.5394405, lng: 19.3877544 },
    { lat: 48.5394193, lng: 19.3880953 },
    { lat: 48.5394647, lng: 19.3884812 },
    { lat: 48.5394803, lng: 19.38941 },
    { lat: 48.5396848, lng: 19.3894183 },
    { lat: 48.5397823, lng: 19.3898447 },
    { lat: 48.5399902, lng: 19.3907537 },
    { lat: 48.5413591, lng: 19.3911948 },
    { lat: 48.5424036, lng: 19.3886646 },
    { lat: 48.5427662, lng: 19.388991 },
    { lat: 48.5427517, lng: 19.388829 },
    { lat: 48.5426814, lng: 19.3887963 },
    { lat: 48.5427906, lng: 19.3886722 },
    { lat: 48.5432343, lng: 19.388737 },
    { lat: 48.5431598, lng: 19.3873262 },
    { lat: 48.5429446, lng: 19.3853273 },
    { lat: 48.5427502, lng: 19.3851269 },
    { lat: 48.5424139, lng: 19.3853071 },
    { lat: 48.5422314, lng: 19.3853099 },
    { lat: 48.5421073, lng: 19.3846635 },
    { lat: 48.5422487, lng: 19.3843421 },
    { lat: 48.5421587, lng: 19.3840124 },
    { lat: 48.5421532, lng: 19.3835613 },
    { lat: 48.5417712, lng: 19.3832065 },
    { lat: 48.5418298, lng: 19.3827321 },
    { lat: 48.5423998, lng: 19.3829633 },
    { lat: 48.5425683, lng: 19.3828328 },
    { lat: 48.5426207, lng: 19.382273 },
    { lat: 48.5429123, lng: 19.3822662 },
    { lat: 48.5429471, lng: 19.3820985 },
    { lat: 48.5427468, lng: 19.3820083 },
    { lat: 48.5426608, lng: 19.3817384 },
    { lat: 48.5425904, lng: 19.3816769 },
    { lat: 48.5423506, lng: 19.3819243 },
    { lat: 48.5420893, lng: 19.3819488 },
    { lat: 48.5420436, lng: 19.3813924 },
    { lat: 48.5420879, lng: 19.3809712 },
    { lat: 48.5422793, lng: 19.3803688 },
    { lat: 48.5424694, lng: 19.3800048 },
    { lat: 48.542106, lng: 19.3790895 },
    { lat: 48.5421776, lng: 19.3786771 },
    { lat: 48.5424195, lng: 19.3783096 },
    { lat: 48.5429184, lng: 19.3777534 },
    { lat: 48.5429813, lng: 19.3778203 },
    { lat: 48.5437286, lng: 19.3783436 },
    { lat: 48.543891, lng: 19.3771356 },
    { lat: 48.5439797, lng: 19.3757478 },
    { lat: 48.5438401, lng: 19.3747509 },
    { lat: 48.5416598, lng: 19.3660484 },
    { lat: 48.5414813, lng: 19.3651137 },
    { lat: 48.5413665, lng: 19.3640743 },
    { lat: 48.541382, lng: 19.3629043 },
    { lat: 48.5415594, lng: 19.3614934 },
    { lat: 48.5422934, lng: 19.3579656 },
    { lat: 48.542618, lng: 19.3566897 },
    { lat: 48.5429853, lng: 19.3548363 },
    { lat: 48.5431067, lng: 19.3537842 },
    { lat: 48.5430781, lng: 19.3520273 },
    { lat: 48.5431769, lng: 19.3503136 },
    { lat: 48.5434919, lng: 19.3486711 },
    { lat: 48.5444451, lng: 19.3428922 },
    { lat: 48.5445606, lng: 19.3420747 },
    { lat: 48.5446645, lng: 19.3408025 },
    { lat: 48.5447056, lng: 19.3395143 },
    { lat: 48.5450284, lng: 19.3385577 },
    { lat: 48.5455815, lng: 19.3376527 },
    { lat: 48.5456595, lng: 19.3371885 },
    { lat: 48.5456222, lng: 19.3367127 },
    { lat: 48.5456453, lng: 19.3355193 },
    { lat: 48.5455314, lng: 19.3349753 },
    { lat: 48.5456381, lng: 19.3345874 },
    { lat: 48.5456913, lng: 19.3337678 },
    { lat: 48.5452952, lng: 19.3337826 },
    { lat: 48.5453091, lng: 19.3335986 },
    { lat: 48.545695, lng: 19.3334895 },
    { lat: 48.545695, lng: 19.3334198 },
    { lat: 48.5450592, lng: 19.3335848 },
    { lat: 48.5440975, lng: 19.3339803 },
    { lat: 48.5438314, lng: 19.334214 },
    { lat: 48.54293, lng: 19.3355865 },
    { lat: 48.5420732, lng: 19.3364092 },
    { lat: 48.5415943, lng: 19.3365802 },
    { lat: 48.541429, lng: 19.3367041 },
    { lat: 48.5406525, lng: 19.3376756 },
    { lat: 48.5403104, lng: 19.3387459 },
    { lat: 48.5403611, lng: 19.3401845 },
    { lat: 48.5405387, lng: 19.3409086 },
    { lat: 48.5405329, lng: 19.3418255 },
    { lat: 48.5406765, lng: 19.3431142 },
    { lat: 48.5405558, lng: 19.3435149 },
    { lat: 48.5406203, lng: 19.343614 },
    { lat: 48.5406127, lng: 19.344589 },
    { lat: 48.5396816, lng: 19.3468575 },
    { lat: 48.5389103, lng: 19.3477009 },
    { lat: 48.5384058, lng: 19.3478056 },
    { lat: 48.5363087, lng: 19.347577 },
    { lat: 48.5352528, lng: 19.3472017 },
    { lat: 48.5351551, lng: 19.3472568 },
    { lat: 48.5346164, lng: 19.3478964 },
    { lat: 48.5323788, lng: 19.3500675 },
    { lat: 48.5317983, lng: 19.3504655 },
    { lat: 48.5302435, lng: 19.3513336 },
    { lat: 48.5290915, lng: 19.3516372 },
    { lat: 48.5276027, lng: 19.3518524 },
    { lat: 48.5272313, lng: 19.3520283 },
    { lat: 48.5266883, lng: 19.3527686 },
    { lat: 48.5263837, lng: 19.3529771 },
    { lat: 48.5241539, lng: 19.3540086 },
    { lat: 48.5238973, lng: 19.3540615 },
    { lat: 48.5230988, lng: 19.3540079 },
    { lat: 48.5220472, lng: 19.3538427 },
    { lat: 48.5214014, lng: 19.3536767 },
    { lat: 48.5205104, lng: 19.3531435 },
    { lat: 48.5204429, lng: 19.3509184 },
    { lat: 48.5203491, lng: 19.3505595 },
    { lat: 48.5202885, lng: 19.3483671 },
    { lat: 48.5194799, lng: 19.3482155 },
    { lat: 48.5186888, lng: 19.3486181 },
    { lat: 48.5186336, lng: 19.3485704 },
    { lat: 48.5166106, lng: 19.3494267 },
    { lat: 48.5150714, lng: 19.3483711 },
    { lat: 48.5146379, lng: 19.3479078 },
    { lat: 48.5151074, lng: 19.347139 },
    { lat: 48.5147726, lng: 19.3443486 },
    { lat: 48.5139138, lng: 19.3455811 },
    { lat: 48.513108, lng: 19.3462459 },
    { lat: 48.5117386, lng: 19.3479938 },
    { lat: 48.5110035, lng: 19.3487447 },
    { lat: 48.51015, lng: 19.3495193 },
    { lat: 48.5096988, lng: 19.3494443 },
    { lat: 48.5095833, lng: 19.3496214 },
    { lat: 48.5095683, lng: 19.3503677 },
    { lat: 48.5094593, lng: 19.3506819 },
    { lat: 48.5090543, lng: 19.3518094 },
    { lat: 48.5087498, lng: 19.3523531 },
    { lat: 48.5085519, lng: 19.35286 },
    { lat: 48.5085127, lng: 19.353027 },
    { lat: 48.5085373, lng: 19.3530976 },
    { lat: 48.5082186, lng: 19.3536214 },
    { lat: 48.5081859, lng: 19.3537639 },
    { lat: 48.5078464, lng: 19.3541137 },
    { lat: 48.5075498, lng: 19.3545353 },
    { lat: 48.5071758, lng: 19.354845 },
    { lat: 48.5067625, lng: 19.3550702 },
    { lat: 48.5063615, lng: 19.3555008 },
    { lat: 48.505687, lng: 19.3564355 },
    { lat: 48.5043254, lng: 19.3581177 },
    { lat: 48.5031945, lng: 19.358488 },
    { lat: 48.5025445, lng: 19.3588001 },
    { lat: 48.5019489, lng: 19.358956 },
    { lat: 48.5014831, lng: 19.3589238 },
    { lat: 48.5011114, lng: 19.3589802 },
    { lat: 48.5006005, lng: 19.3586173 },
    { lat: 48.5001363, lng: 19.3594044 },
    { lat: 48.4997603, lng: 19.3595526 },
    { lat: 48.4989928, lng: 19.3596982 },
    { lat: 48.4984878, lng: 19.3596925 },
    { lat: 48.4980693, lng: 19.3597828 },
    { lat: 48.4972591, lng: 19.3595978 },
  ],
  HornýTisovník: [
    { lat: 48.456416, lng: 19.367094 },
    { lat: 48.4569769, lng: 19.3668759 },
    { lat: 48.4569717, lng: 19.3668114 },
    { lat: 48.4567978, lng: 19.3666867 },
    { lat: 48.4569279, lng: 19.3661539 },
    { lat: 48.4572393, lng: 19.3655197 },
    { lat: 48.456797, lng: 19.3642062 },
    { lat: 48.4570362, lng: 19.3639518 },
    { lat: 48.457301, lng: 19.363477 },
    { lat: 48.4573626, lng: 19.3632076 },
    { lat: 48.4581515, lng: 19.3624491 },
    { lat: 48.4580052, lng: 19.3618619 },
    { lat: 48.4575913, lng: 19.3619043 },
    { lat: 48.4573796, lng: 19.3604144 },
    { lat: 48.4572441, lng: 19.3598907 },
    { lat: 48.4571697, lng: 19.359865 },
    { lat: 48.4570877, lng: 19.3593742 },
    { lat: 48.4568053, lng: 19.3594799 },
    { lat: 48.4559096, lng: 19.3595571 },
    { lat: 48.4557574, lng: 19.3599616 },
    { lat: 48.4554878, lng: 19.3591008 },
    { lat: 48.4552925, lng: 19.3579852 },
    { lat: 48.4524277, lng: 19.3578481 },
    { lat: 48.4522666, lng: 19.356976 },
    { lat: 48.4529418, lng: 19.356855 },
    { lat: 48.452926, lng: 19.3572825 },
    { lat: 48.4531495, lng: 19.3573589 },
    { lat: 48.4530604, lng: 19.3577398 },
    { lat: 48.4534828, lng: 19.3577799 },
    { lat: 48.453569, lng: 19.3574334 },
    { lat: 48.4539122, lng: 19.3573541 },
    { lat: 48.4540944, lng: 19.3567515 },
    { lat: 48.4543566, lng: 19.3561541 },
    { lat: 48.4541788, lng: 19.3557326 },
    { lat: 48.4542081, lng: 19.3556144 },
    { lat: 48.4549004, lng: 19.3558583 },
    { lat: 48.4555777, lng: 19.3556874 },
    { lat: 48.4554518, lng: 19.3551034 },
    { lat: 48.4555205, lng: 19.3549646 },
    { lat: 48.4556127, lng: 19.3550548 },
    { lat: 48.4558204, lng: 19.3562663 },
    { lat: 48.456297, lng: 19.3568751 },
    { lat: 48.456806, lng: 19.356985 },
    { lat: 48.456842, lng: 19.3568877 },
    { lat: 48.4570214, lng: 19.356965 },
    { lat: 48.4572743, lng: 19.3567599 },
    { lat: 48.4576531, lng: 19.3567844 },
    { lat: 48.4579746, lng: 19.3570047 },
    { lat: 48.4579723, lng: 19.3577148 },
    { lat: 48.4580595, lng: 19.35834 },
    { lat: 48.4585455, lng: 19.3575853 },
    { lat: 48.4587253, lng: 19.3574089 },
    { lat: 48.4584398, lng: 19.3569276 },
    { lat: 48.4582176, lng: 19.3569232 },
    { lat: 48.4582924, lng: 19.3564822 },
    { lat: 48.4585386, lng: 19.3563055 },
    { lat: 48.4586127, lng: 19.355941 },
    { lat: 48.4582476, lng: 19.3555422 },
    { lat: 48.4583728, lng: 19.3553344 },
    { lat: 48.458544, lng: 19.3554722 },
    { lat: 48.4587786, lng: 19.3558546 },
    { lat: 48.4589755, lng: 19.3560369 },
    { lat: 48.4593498, lng: 19.3534587 },
    { lat: 48.4595598, lng: 19.3534442 },
    { lat: 48.4600476, lng: 19.3537684 },
    { lat: 48.4606291, lng: 19.3534859 },
    { lat: 48.4613022, lng: 19.3528757 },
    { lat: 48.4616094, lng: 19.3527954 },
    { lat: 48.4615529, lng: 19.3524137 },
    { lat: 48.4613511, lng: 19.3518214 },
    { lat: 48.4610338, lng: 19.3513088 },
    { lat: 48.459587, lng: 19.3502815 },
    { lat: 48.4596096, lng: 19.3500909 },
    { lat: 48.4599508, lng: 19.3502274 },
    { lat: 48.4601255, lng: 19.3502256 },
    { lat: 48.4601869, lng: 19.3497204 },
    { lat: 48.4599567, lng: 19.3496188 },
    { lat: 48.4596154, lng: 19.3491027 },
    { lat: 48.4596629, lng: 19.3488462 },
    { lat: 48.4595968, lng: 19.3487383 },
    { lat: 48.4595775, lng: 19.3485472 },
    { lat: 48.4597873, lng: 19.3478893 },
    { lat: 48.4601508, lng: 19.3472361 },
    { lat: 48.4597824, lng: 19.3462289 },
    { lat: 48.4595837, lng: 19.3465245 },
    { lat: 48.4594541, lng: 19.3462686 },
    { lat: 48.4593126, lng: 19.3461824 },
    { lat: 48.459177, lng: 19.3458209 },
    { lat: 48.4598923, lng: 19.3447253 },
    { lat: 48.4603825, lng: 19.3441851 },
    { lat: 48.4606095, lng: 19.3440765 },
    { lat: 48.4615372, lng: 19.3439568 },
    { lat: 48.4621829, lng: 19.3441893 },
    { lat: 48.463263, lng: 19.3441742 },
    { lat: 48.4636967, lng: 19.3442879 },
    { lat: 48.4643691, lng: 19.3441095 },
    { lat: 48.4643659, lng: 19.3440434 },
    { lat: 48.4644669, lng: 19.3439629 },
    { lat: 48.4643728, lng: 19.3437184 },
    { lat: 48.4640915, lng: 19.3435602 },
    { lat: 48.463902, lng: 19.3431087 },
    { lat: 48.4641089, lng: 19.3428666 },
    { lat: 48.4642998, lng: 19.3427543 },
    { lat: 48.4644475, lng: 19.3425175 },
    { lat: 48.4647137, lng: 19.3424049 },
    { lat: 48.4649295, lng: 19.3423919 },
    { lat: 48.4651798, lng: 19.3420662 },
    { lat: 48.4653708, lng: 19.3416726 },
    { lat: 48.4653709, lng: 19.341418 },
    { lat: 48.4656819, lng: 19.341248 },
    { lat: 48.4658134, lng: 19.3410985 },
    { lat: 48.4659605, lng: 19.3408305 },
    { lat: 48.4662867, lng: 19.3404369 },
    { lat: 48.4664029, lng: 19.3401345 },
    { lat: 48.4664422, lng: 19.3399882 },
    { lat: 48.4664329, lng: 19.3395119 },
    { lat: 48.4665464, lng: 19.3388436 },
    { lat: 48.4665122, lng: 19.3384343 },
    { lat: 48.4665812, lng: 19.3382514 },
    { lat: 48.466539, lng: 19.3374621 },
    { lat: 48.4662366, lng: 19.3362315 },
    { lat: 48.466216, lng: 19.3358442 },
    { lat: 48.4661419, lng: 19.3355453 },
    { lat: 48.4658754, lng: 19.3348525 },
    { lat: 48.4659023, lng: 19.3344518 },
    { lat: 48.4658471, lng: 19.334472 },
    { lat: 48.4655733, lng: 19.3339823 },
    { lat: 48.4656373, lng: 19.3337641 },
    { lat: 48.4661747, lng: 19.3335433 },
    { lat: 48.4664031, lng: 19.3331837 },
    { lat: 48.4666847, lng: 19.3324258 },
    { lat: 48.4663636, lng: 19.3321715 },
    { lat: 48.4663979, lng: 19.3314955 },
    { lat: 48.4664424, lng: 19.3313195 },
    { lat: 48.4668092, lng: 19.3305563 },
    { lat: 48.4666452, lng: 19.3302572 },
    { lat: 48.4665674, lng: 19.3297624 },
    { lat: 48.4664237, lng: 19.3292331 },
    { lat: 48.4664934, lng: 19.3290906 },
    { lat: 48.4664792, lng: 19.3290048 },
    { lat: 48.4667822, lng: 19.3287628 },
    { lat: 48.4665159, lng: 19.327957 },
    { lat: 48.4663264, lng: 19.3275304 },
    { lat: 48.4660322, lng: 19.3270835 },
    { lat: 48.4657623, lng: 19.3268147 },
    { lat: 48.4653747, lng: 19.3265584 },
    { lat: 48.4651487, lng: 19.3264746 },
    { lat: 48.4646928, lng: 19.3264384 },
    { lat: 48.4620546, lng: 19.3265448 },
    { lat: 48.461858, lng: 19.3264872 },
    { lat: 48.4618356, lng: 19.3262077 },
    { lat: 48.461772, lng: 19.3261279 },
    { lat: 48.4616659, lng: 19.3262193 },
    { lat: 48.4615676, lng: 19.3258596 },
    { lat: 48.4615684, lng: 19.3255704 },
    { lat: 48.4616284, lng: 19.3252699 },
    { lat: 48.4626284, lng: 19.3222192 },
    { lat: 48.4628741, lng: 19.3211126 },
    { lat: 48.4630673, lng: 19.3210082 },
    { lat: 48.4631198, lng: 19.3203839 },
    { lat: 48.4631083, lng: 19.3202182 },
    { lat: 48.4630112, lng: 19.3201438 },
    { lat: 48.4630377, lng: 19.3193774 },
    { lat: 48.4630051, lng: 19.3183475 },
    { lat: 48.4628789, lng: 19.3174055 },
    { lat: 48.4628866, lng: 19.3170733 },
    { lat: 48.4629676, lng: 19.3166007 },
    { lat: 48.4630333, lng: 19.316726 },
    { lat: 48.4630817, lng: 19.3166569 },
    { lat: 48.4630018, lng: 19.3164482 },
    { lat: 48.4630449, lng: 19.3163313 },
    { lat: 48.4634417, lng: 19.3157963 },
    { lat: 48.4640338, lng: 19.3156369 },
    { lat: 48.464332, lng: 19.3156741 },
    { lat: 48.465724, lng: 19.3161684 },
    { lat: 48.466122, lng: 19.3162378 },
    { lat: 48.4663043, lng: 19.3161139 },
    { lat: 48.4663217, lng: 19.3159842 },
    { lat: 48.4665332, lng: 19.3158834 },
    { lat: 48.4665842, lng: 19.3156069 },
    { lat: 48.4665769, lng: 19.3151495 },
    { lat: 48.4664129, lng: 19.3145728 },
    { lat: 48.4658682, lng: 19.3139575 },
    { lat: 48.4657242, lng: 19.3136836 },
    { lat: 48.4652744, lng: 19.3133773 },
    { lat: 48.4647493, lng: 19.3127904 },
    { lat: 48.4645474, lng: 19.3126728 },
    { lat: 48.4639634, lng: 19.3120618 },
    { lat: 48.4621639, lng: 19.3115554 },
    { lat: 48.4619188, lng: 19.3115433 },
    { lat: 48.4615393, lng: 19.311363 },
    { lat: 48.4607958, lng: 19.3106887 },
    { lat: 48.4607301, lng: 19.3107321 },
    { lat: 48.4603697, lng: 19.3102659 },
    { lat: 48.4604701, lng: 19.3099666 },
    { lat: 48.4601745, lng: 19.30989 },
    { lat: 48.4598547, lng: 19.30938 },
    { lat: 48.4597324, lng: 19.30885 },
    { lat: 48.4596064, lng: 19.3086918 },
    { lat: 48.4595769, lng: 19.3082433 },
    { lat: 48.4593557, lng: 19.3074846 },
    { lat: 48.4592243, lng: 19.3063732 },
    { lat: 48.4593264, lng: 19.3060552 },
    { lat: 48.4592879, lng: 19.3060072 },
    { lat: 48.4592085, lng: 19.306138 },
    { lat: 48.4591809, lng: 19.3057511 },
    { lat: 48.4592105, lng: 19.3047954 },
    { lat: 48.459347, lng: 19.3036261 },
    { lat: 48.4597754, lng: 19.3020596 },
    { lat: 48.4599755, lng: 19.3017466 },
    { lat: 48.4599794, lng: 19.3014969 },
    { lat: 48.459835, lng: 19.3014035 },
    { lat: 48.4596883, lng: 19.3010008 },
    { lat: 48.4595786, lng: 19.3008505 },
    { lat: 48.4596021, lng: 19.3007801 },
    { lat: 48.4594553, lng: 19.3006796 },
    { lat: 48.4595139, lng: 19.3005375 },
    { lat: 48.4594578, lng: 19.3004705 },
    { lat: 48.4592078, lng: 19.3008276 },
    { lat: 48.4588318, lng: 19.3011221 },
    { lat: 48.4579149, lng: 19.3028397 },
    { lat: 48.4576369, lng: 19.3030166 },
    { lat: 48.457512, lng: 19.3028555 },
    { lat: 48.4574332, lng: 19.3029504 },
    { lat: 48.4575631, lng: 19.3031901 },
    { lat: 48.4573939, lng: 19.3035615 },
    { lat: 48.4570381, lng: 19.3039436 },
    { lat: 48.4567103, lng: 19.304464 },
    { lat: 48.4558774, lng: 19.3055673 },
    { lat: 48.4553383, lng: 19.3066169 },
    { lat: 48.4550127, lng: 19.3080478 },
    { lat: 48.4548189, lng: 19.3084982 },
    { lat: 48.4547213, lng: 19.3077798 },
    { lat: 48.4544739, lng: 19.3076497 },
    { lat: 48.4542406, lng: 19.3070871 },
    { lat: 48.4571374, lng: 19.3018387 },
    { lat: 48.4568651, lng: 19.2997405 },
    { lat: 48.4550672, lng: 19.2992008 },
    { lat: 48.4516961, lng: 19.2983416 },
    { lat: 48.4524847, lng: 19.3027351 },
    { lat: 48.4524359, lng: 19.3027272 },
    { lat: 48.4522847, lng: 19.3039204 },
    { lat: 48.4520831, lng: 19.3044138 },
    { lat: 48.451892, lng: 19.3043785 },
    { lat: 48.4513636, lng: 19.3050595 },
    { lat: 48.4514885, lng: 19.3055256 },
    { lat: 48.4515857, lng: 19.3062095 },
    { lat: 48.4504107, lng: 19.3061096 },
    { lat: 48.4502133, lng: 19.3040675 },
    { lat: 48.4496898, lng: 19.302864 },
    { lat: 48.4485376, lng: 19.3034867 },
    { lat: 48.4489283, lng: 19.3045566 },
    { lat: 48.4477963, lng: 19.3052747 },
    { lat: 48.4475746, lng: 19.3056116 },
    { lat: 48.4470593, lng: 19.3059367 },
    { lat: 48.4469978, lng: 19.3064408 },
    { lat: 48.4465905, lng: 19.3064098 },
    { lat: 48.4462367, lng: 19.3056586 },
    { lat: 48.4457452, lng: 19.3053986 },
    { lat: 48.4456268, lng: 19.3061041 },
    { lat: 48.4455402, lng: 19.3059644 },
    { lat: 48.4454747, lng: 19.3060113 },
    { lat: 48.4453016, lng: 19.3058785 },
    { lat: 48.4451687, lng: 19.3060225 },
    { lat: 48.4449521, lng: 19.3059257 },
    { lat: 48.4442342, lng: 19.3054563 },
    { lat: 48.4439336, lng: 19.3051638 },
    { lat: 48.4435588, lng: 19.3050568 },
    { lat: 48.4431925, lng: 19.3055723 },
    { lat: 48.4431267, lng: 19.3059821 },
    { lat: 48.443209, lng: 19.3068525 },
    { lat: 48.4435122, lng: 19.3080937 },
    { lat: 48.4434398, lng: 19.3082545 },
    { lat: 48.44341, lng: 19.3084978 },
    { lat: 48.4436018, lng: 19.3084113 },
    { lat: 48.4437135, lng: 19.3091375 },
    { lat: 48.4436612, lng: 19.3095526 },
    { lat: 48.4434864, lng: 19.3096901 },
    { lat: 48.4434376, lng: 19.309869 },
    { lat: 48.4428593, lng: 19.3098459 },
    { lat: 48.442301, lng: 19.3096967 },
    { lat: 48.4417963, lng: 19.3093781 },
    { lat: 48.4410937, lng: 19.3085481 },
    { lat: 48.4409876, lng: 19.3084734 },
    { lat: 48.4408369, lng: 19.3084791 },
    { lat: 48.4400748, lng: 19.3075874 },
    { lat: 48.4398475, lng: 19.3074786 },
    { lat: 48.439515, lng: 19.3075044 },
    { lat: 48.4394077, lng: 19.3076407 },
    { lat: 48.4392394, lng: 19.3077254 },
    { lat: 48.4390985, lng: 19.3079334 },
    { lat: 48.4389578, lng: 19.3090846 },
    { lat: 48.4389897, lng: 19.309821 },
    { lat: 48.438925, lng: 19.3102792 },
    { lat: 48.4387703, lng: 19.3107421 },
    { lat: 48.438347, lng: 19.311398 },
    { lat: 48.438567, lng: 19.311768 },
    { lat: 48.43852, lng: 19.311918 },
    { lat: 48.438016, lng: 19.31309 },
    { lat: 48.437794, lng: 19.313813 },
    { lat: 48.437875, lng: 19.314217 },
    { lat: 48.437911, lng: 19.314341 },
    { lat: 48.438325, lng: 19.315759 },
    { lat: 48.437946, lng: 19.316582 },
    { lat: 48.437979, lng: 19.318039 },
    { lat: 48.437963, lng: 19.31805 },
    { lat: 48.438341, lng: 19.319242 },
    { lat: 48.438378, lng: 19.319542 },
    { lat: 48.438289, lng: 19.320895 },
    { lat: 48.438147, lng: 19.321541 },
    { lat: 48.438115, lng: 19.321632 },
    { lat: 48.438057, lng: 19.321761 },
    { lat: 48.438076, lng: 19.321778 },
    { lat: 48.438071, lng: 19.321791 },
    { lat: 48.436294, lng: 19.323477 },
    { lat: 48.436001, lng: 19.323703 },
    { lat: 48.434917, lng: 19.32455 },
    { lat: 48.434136, lng: 19.325503 },
    { lat: 48.434116, lng: 19.326377 },
    { lat: 48.434102, lng: 19.326408 },
    { lat: 48.433305, lng: 19.328012 },
    { lat: 48.432591, lng: 19.329698 },
    { lat: 48.43265, lng: 19.330286 },
    { lat: 48.432221, lng: 19.331943 },
    { lat: 48.431681, lng: 19.332725 },
    { lat: 48.430055, lng: 19.331431 },
    { lat: 48.429085, lng: 19.331532 },
    { lat: 48.428551, lng: 19.331975 },
    { lat: 48.428281, lng: 19.332199 },
    { lat: 48.427174, lng: 19.33368 },
    { lat: 48.426484, lng: 19.336481 },
    { lat: 48.426212, lng: 19.336804 },
    { lat: 48.426133, lng: 19.336901 },
    { lat: 48.425942, lng: 19.337178 },
    { lat: 48.42575, lng: 19.3374 },
    { lat: 48.425629, lng: 19.337605 },
    { lat: 48.425441, lng: 19.337676 },
    { lat: 48.425278, lng: 19.337716 },
    { lat: 48.424858, lng: 19.33773 },
    { lat: 48.424688, lng: 19.337863 },
    { lat: 48.424411, lng: 19.338021 },
    { lat: 48.423999, lng: 19.338186 },
    { lat: 48.423351, lng: 19.338428 },
    { lat: 48.422795, lng: 19.338735 },
    { lat: 48.422635, lng: 19.338823 },
    { lat: 48.422454, lng: 19.338922 },
    { lat: 48.422153, lng: 19.339092 },
    { lat: 48.421037, lng: 19.341889 },
    { lat: 48.420348, lng: 19.34242 },
    { lat: 48.419919, lng: 19.342607 },
    { lat: 48.418723, lng: 19.343127 },
    { lat: 48.41813, lng: 19.342976 },
    { lat: 48.418053, lng: 19.342972 },
    { lat: 48.417837, lng: 19.342964 },
    { lat: 48.417631, lng: 19.34303 },
    { lat: 48.417346, lng: 19.343051 },
    { lat: 48.417304, lng: 19.343053 },
    { lat: 48.417073, lng: 19.343005 },
    { lat: 48.417061, lng: 19.343005 },
    { lat: 48.416647, lng: 19.343141 },
    { lat: 48.416546, lng: 19.343175 },
    { lat: 48.416418, lng: 19.343203 },
    { lat: 48.416368, lng: 19.343229 },
    { lat: 48.416325, lng: 19.34324 },
    { lat: 48.416249, lng: 19.34326 },
    { lat: 48.41615, lng: 19.34329 },
    { lat: 48.416083, lng: 19.343312 },
    { lat: 48.415735, lng: 19.343522 },
    { lat: 48.415103, lng: 19.343913 },
    { lat: 48.414814, lng: 19.344462 },
    { lat: 48.414686, lng: 19.34462 },
    { lat: 48.414493, lng: 19.344825 },
    { lat: 48.414356, lng: 19.344906 },
    { lat: 48.414075, lng: 19.344928 },
    { lat: 48.414041, lng: 19.344918 },
    { lat: 48.413585, lng: 19.344851 },
    { lat: 48.412971, lng: 19.344951 },
    { lat: 48.412555, lng: 19.345118 },
    { lat: 48.412736, lng: 19.345656 },
    { lat: 48.41262, lng: 19.345779 },
    { lat: 48.412351, lng: 19.346053 },
    { lat: 48.412345, lng: 19.346428 },
    { lat: 48.412306, lng: 19.346994 },
    { lat: 48.412207, lng: 19.347481 },
    { lat: 48.41212, lng: 19.347498 },
    { lat: 48.412021, lng: 19.34752 },
    { lat: 48.411917, lng: 19.347546 },
    { lat: 48.411857, lng: 19.347603 },
    { lat: 48.411853, lng: 19.347605 },
    { lat: 48.411655, lng: 19.347629 },
    { lat: 48.411524, lng: 19.347625 },
    { lat: 48.411336, lng: 19.347618 },
    { lat: 48.411198, lng: 19.347612 },
    { lat: 48.411135, lng: 19.347594 },
    { lat: 48.411021, lng: 19.347561 },
    { lat: 48.410938, lng: 19.347538 },
    { lat: 48.410712, lng: 19.347479 },
    { lat: 48.410516, lng: 19.347522 },
    { lat: 48.410234, lng: 19.347725 },
    { lat: 48.409782, lng: 19.348158 },
    { lat: 48.40927, lng: 19.348585 },
    { lat: 48.408708, lng: 19.348989 },
    { lat: 48.408637, lng: 19.349037 },
    { lat: 48.408517, lng: 19.349108 },
    { lat: 48.40803, lng: 19.349403 },
    { lat: 48.407966, lng: 19.349465 },
    { lat: 48.407835, lng: 19.349308 },
    { lat: 48.407549, lng: 19.34905 },
    { lat: 48.40721, lng: 19.348894 },
    { lat: 48.407021, lng: 19.348769 },
    { lat: 48.406753, lng: 19.348469 },
    { lat: 48.406564, lng: 19.348452 },
    { lat: 48.406299, lng: 19.348478 },
    { lat: 48.406068, lng: 19.348413 },
    { lat: 48.405919, lng: 19.348348 },
    { lat: 48.405845, lng: 19.348344 },
    { lat: 48.405834, lng: 19.34834 },
    { lat: 48.405511, lng: 19.348383 },
    { lat: 48.405015, lng: 19.348637 },
    { lat: 48.404725, lng: 19.348735 },
    { lat: 48.40443, lng: 19.348576 },
    { lat: 48.404085, lng: 19.34878 },
    { lat: 48.403668, lng: 19.348785 },
    { lat: 48.402754, lng: 19.349064 },
    { lat: 48.401903, lng: 19.348875 },
    { lat: 48.400628, lng: 19.349268 },
    { lat: 48.400487, lng: 19.349311 },
    { lat: 48.400059, lng: 19.349535 },
    { lat: 48.39956, lng: 19.349872 },
    { lat: 48.399148, lng: 19.350073 },
    { lat: 48.398675, lng: 19.350271 },
    { lat: 48.398434, lng: 19.350482 },
    { lat: 48.398171, lng: 19.35046 },
    { lat: 48.397838, lng: 19.350651 },
    { lat: 48.397359, lng: 19.350838 },
    { lat: 48.39714, lng: 19.350867 },
    { lat: 48.396832, lng: 19.351014 },
    { lat: 48.396424, lng: 19.351223 },
    { lat: 48.395842, lng: 19.351458 },
    { lat: 48.394899, lng: 19.351705 },
    { lat: 48.394456, lng: 19.351672 },
    { lat: 48.394188, lng: 19.351641 },
    { lat: 48.393755, lng: 19.351535 },
    { lat: 48.393226, lng: 19.351716 },
    { lat: 48.392613, lng: 19.351942 },
    { lat: 48.392332, lng: 19.352201 },
    { lat: 48.391919, lng: 19.352421 },
    { lat: 48.39157, lng: 19.35269 },
    { lat: 48.391009, lng: 19.353128 },
    { lat: 48.390754, lng: 19.353323 },
    { lat: 48.390097, lng: 19.353532 },
    { lat: 48.389551, lng: 19.353701 },
    { lat: 48.389395, lng: 19.353762 },
    { lat: 48.389343, lng: 19.353782 },
    { lat: 48.389294, lng: 19.353803 },
    { lat: 48.389208, lng: 19.353833 },
    { lat: 48.389227, lng: 19.353975 },
    { lat: 48.388971, lng: 19.354062 },
    { lat: 48.388531, lng: 19.354332 },
    { lat: 48.387561, lng: 19.354718 },
    { lat: 48.387195, lng: 19.355592 },
    { lat: 48.386689, lng: 19.356162 },
    { lat: 48.386168, lng: 19.35707 },
    { lat: 48.385894, lng: 19.357682 },
    { lat: 48.385472, lng: 19.358302 },
    { lat: 48.38522, lng: 19.358138 },
    { lat: 48.384663, lng: 19.358026 },
    { lat: 48.383813, lng: 19.358272 },
    { lat: 48.383682, lng: 19.358338 },
    { lat: 48.383583, lng: 19.358387 },
    { lat: 48.38352, lng: 19.358418 },
    { lat: 48.383254, lng: 19.35855 },
    { lat: 48.38332, lng: 19.358818 },
    { lat: 48.383263, lng: 19.359241 },
    { lat: 48.383265, lng: 19.359714 },
    { lat: 48.383266, lng: 19.359948 },
    { lat: 48.383266, lng: 19.359983 },
    { lat: 48.383254, lng: 19.36053 },
    { lat: 48.383251, lng: 19.361442 },
    { lat: 48.383247, lng: 19.362339 },
    { lat: 48.383231, lng: 19.362459 },
    { lat: 48.383156, lng: 19.362863 },
    { lat: 48.383147, lng: 19.36291 },
    { lat: 48.382945, lng: 19.364257 },
    { lat: 48.382941, lng: 19.364293 },
    { lat: 48.382867, lng: 19.364715 },
    { lat: 48.382803, lng: 19.365091 },
    { lat: 48.382759, lng: 19.365626 },
    { lat: 48.382749, lng: 19.365794 },
    { lat: 48.382674, lng: 19.366113 },
    { lat: 48.382587, lng: 19.366478 },
    { lat: 48.38256, lng: 19.366587 },
    { lat: 48.382518, lng: 19.366642 },
    { lat: 48.382385, lng: 19.366816 },
    { lat: 48.382395, lng: 19.367013 },
    { lat: 48.382421, lng: 19.367559 },
    { lat: 48.382435, lng: 19.36786 },
    { lat: 48.382518, lng: 19.368137 },
    { lat: 48.382607, lng: 19.368659 },
    { lat: 48.382805, lng: 19.369811 },
    { lat: 48.38271, lng: 19.370433 },
    { lat: 48.382901, lng: 19.371133 },
    { lat: 48.382907, lng: 19.371594 },
    { lat: 48.382912, lng: 19.371939 },
    { lat: 48.383038, lng: 19.372187 },
    { lat: 48.383094, lng: 19.372299 },
    { lat: 48.383107, lng: 19.372323 },
    { lat: 48.383124, lng: 19.372355 },
    { lat: 48.383539, lng: 19.373198 },
    { lat: 48.383791, lng: 19.373702 },
    { lat: 48.384066, lng: 19.374051 },
    { lat: 48.384117, lng: 19.374116 },
    { lat: 48.384143, lng: 19.374148 },
    { lat: 48.384269, lng: 19.374305 },
    { lat: 48.384333, lng: 19.374384 },
    { lat: 48.384344, lng: 19.374399 },
    { lat: 48.384539, lng: 19.374868 },
    { lat: 48.384561, lng: 19.374972 },
    { lat: 48.384569, lng: 19.375047 },
    { lat: 48.384582, lng: 19.375121 },
    { lat: 48.384614, lng: 19.375329 },
    { lat: 48.38462, lng: 19.375406 },
    { lat: 48.384635, lng: 19.375514 },
    { lat: 48.384645, lng: 19.375594 },
    { lat: 48.384737, lng: 19.376222 },
    { lat: 48.384926, lng: 19.376882 },
    { lat: 48.385484, lng: 19.377887 },
    { lat: 48.385572, lng: 19.377979 },
    { lat: 48.385655, lng: 19.378057 },
    { lat: 48.385723, lng: 19.378122 },
    { lat: 48.385808, lng: 19.378201 },
    { lat: 48.385912, lng: 19.378299 },
    { lat: 48.386398, lng: 19.378671 },
    { lat: 48.386422, lng: 19.378706 },
    { lat: 48.386499, lng: 19.378848 },
    { lat: 48.386591, lng: 19.379016 },
    { lat: 48.386686, lng: 19.379192 },
    { lat: 48.386746, lng: 19.379259 },
    { lat: 48.386843, lng: 19.379479 },
    { lat: 48.387021, lng: 19.3797 },
    { lat: 48.387053, lng: 19.379741 },
    { lat: 48.387084, lng: 19.379791 },
    { lat: 48.387351, lng: 19.380224 },
    { lat: 48.387515, lng: 19.380421 },
    { lat: 48.387581, lng: 19.380501 },
    { lat: 48.387699, lng: 19.380766 },
    { lat: 48.387856, lng: 19.381012 },
    { lat: 48.387911, lng: 19.381097 },
    { lat: 48.387938, lng: 19.381139 },
    { lat: 48.388032, lng: 19.38127 },
    { lat: 48.388102, lng: 19.381368 },
    { lat: 48.388151, lng: 19.381436 },
    { lat: 48.388255, lng: 19.381581 },
    { lat: 48.388295, lng: 19.381637 },
    { lat: 48.388313, lng: 19.381662 },
    { lat: 48.388415, lng: 19.381801 },
    { lat: 48.388449, lng: 19.381849 },
    { lat: 48.388572, lng: 19.382018 },
    { lat: 48.388597, lng: 19.382052 },
    { lat: 48.388631, lng: 19.382103 },
    { lat: 48.388793, lng: 19.382337 },
    { lat: 48.388823, lng: 19.382371 },
    { lat: 48.388851, lng: 19.382401 },
    { lat: 48.389051, lng: 19.38262 },
    { lat: 48.389185, lng: 19.382639 },
    { lat: 48.389524, lng: 19.382687 },
    { lat: 48.390282, lng: 19.383346 },
    { lat: 48.39046, lng: 19.384144 },
    { lat: 48.390724, lng: 19.384589 },
    { lat: 48.390805, lng: 19.384718 },
    { lat: 48.390876, lng: 19.384831 },
    { lat: 48.390989, lng: 19.385014 },
    { lat: 48.391069, lng: 19.38517 },
    { lat: 48.391291, lng: 19.385619 },
    { lat: 48.391437, lng: 19.385895 },
    { lat: 48.391818, lng: 19.387557 },
    { lat: 48.392178, lng: 19.387519 },
    { lat: 48.392465, lng: 19.387691 },
    { lat: 48.392781, lng: 19.388142 },
    { lat: 48.392833, lng: 19.388247 },
    { lat: 48.393442, lng: 19.389457 },
    { lat: 48.393935, lng: 19.390436 },
    { lat: 48.394006, lng: 19.390577 },
    { lat: 48.394012, lng: 19.390804 },
    { lat: 48.394021, lng: 19.39122 },
    { lat: 48.394092, lng: 19.391238 },
    { lat: 48.394417, lng: 19.391323 },
    { lat: 48.3949, lng: 19.39166 },
    { lat: 48.395649, lng: 19.391845 },
    { lat: 48.396427, lng: 19.392911 },
    { lat: 48.397204, lng: 19.393384 },
    { lat: 48.397529, lng: 19.393185 },
    { lat: 48.397648, lng: 19.393622 },
    { lat: 48.39778, lng: 19.393827 },
    { lat: 48.39804, lng: 19.393646 },
    { lat: 48.398145, lng: 19.393902 },
    { lat: 48.398324, lng: 19.394336 },
    { lat: 48.398678, lng: 19.394236 },
    { lat: 48.398921, lng: 19.394185 },
    { lat: 48.39919, lng: 19.394079 },
    { lat: 48.399473, lng: 19.394702 },
    { lat: 48.399662, lng: 19.394601 },
    { lat: 48.399797, lng: 19.394375 },
    { lat: 48.40011, lng: 19.394118 },
    { lat: 48.400378, lng: 19.394005 },
    { lat: 48.400503, lng: 19.393985 },
    { lat: 48.400945, lng: 19.393919 },
    { lat: 48.401414, lng: 19.393855 },
    { lat: 48.401679, lng: 19.393756 },
    { lat: 48.402249, lng: 19.393114 },
    { lat: 48.402547, lng: 19.392778 },
    { lat: 48.402545, lng: 19.39273 },
    { lat: 48.402364, lng: 19.392648 },
    { lat: 48.402369, lng: 19.392039 },
    { lat: 48.402416, lng: 19.391783 },
    { lat: 48.402473, lng: 19.3915 },
    { lat: 48.402559, lng: 19.390814 },
    { lat: 48.402986, lng: 19.39072 },
    { lat: 48.403462, lng: 19.390613 },
    { lat: 48.40428, lng: 19.390243 },
    { lat: 48.404567, lng: 19.390166 },
    { lat: 48.404584, lng: 19.390155 },
    { lat: 48.404685, lng: 19.390169 },
    { lat: 48.404826, lng: 19.390195 },
    { lat: 48.404946, lng: 19.39036 },
    { lat: 48.405073, lng: 19.390527 },
    { lat: 48.405425, lng: 19.390461 },
    { lat: 48.405658, lng: 19.390285 },
    { lat: 48.406103, lng: 19.389699 },
    { lat: 48.406595, lng: 19.389459 },
    { lat: 48.406956, lng: 19.389203 },
    { lat: 48.407214, lng: 19.389041 },
    { lat: 48.407699, lng: 19.388764 },
    { lat: 48.408768, lng: 19.388487 },
    { lat: 48.409269, lng: 19.388691 },
    { lat: 48.410062, lng: 19.388986 },
    { lat: 48.410386, lng: 19.388988 },
    { lat: 48.410546, lng: 19.389036 },
    { lat: 48.410875, lng: 19.389137 },
    { lat: 48.411512, lng: 19.388933 },
    { lat: 48.411744, lng: 19.38886 },
    { lat: 48.411989, lng: 19.388761 },
    { lat: 48.412115, lng: 19.38871 },
    { lat: 48.412758, lng: 19.3888 },
    { lat: 48.414032, lng: 19.388951 },
    { lat: 48.414518, lng: 19.389172 },
    { lat: 48.414744, lng: 19.389192 },
    { lat: 48.414848, lng: 19.389284 },
    { lat: 48.414879, lng: 19.389317 },
    { lat: 48.414783, lng: 19.389626 },
    { lat: 48.415228, lng: 19.389973 },
    { lat: 48.415435, lng: 19.390134 },
    { lat: 48.416439, lng: 19.390826 },
    { lat: 48.416661, lng: 19.390908 },
    { lat: 48.417256, lng: 19.390837 },
    { lat: 48.417661, lng: 19.390788 },
    { lat: 48.417719, lng: 19.390782 },
    { lat: 48.417808, lng: 19.390833 },
    { lat: 48.417908, lng: 19.390985 },
    { lat: 48.418107, lng: 19.390966 },
    { lat: 48.419015, lng: 19.390904 },
    { lat: 48.419459, lng: 19.390754 },
    { lat: 48.419713, lng: 19.390595 },
    { lat: 48.419905, lng: 19.390468 },
    { lat: 48.420056, lng: 19.390419 },
    { lat: 48.420457, lng: 19.390438 },
    { lat: 48.420979, lng: 19.389961 },
    { lat: 48.421372, lng: 19.389617 },
    { lat: 48.421574, lng: 19.389344 },
    { lat: 48.421963, lng: 19.388985 },
    { lat: 48.422215, lng: 19.388757 },
    { lat: 48.422392, lng: 19.388537 },
    { lat: 48.42258, lng: 19.38836 },
    { lat: 48.422957, lng: 19.387933 },
    { lat: 48.423366, lng: 19.387576 },
    { lat: 48.423691, lng: 19.387308 },
    { lat: 48.423881, lng: 19.387171 },
    { lat: 48.424163, lng: 19.387025 },
    { lat: 48.424153, lng: 19.386963 },
    { lat: 48.42445, lng: 19.386884 },
    { lat: 48.425313, lng: 19.386839 },
    { lat: 48.425723, lng: 19.38678 },
    { lat: 48.426272, lng: 19.386696 },
    { lat: 48.42654, lng: 19.386648 },
    { lat: 48.42663, lng: 19.386554 },
    { lat: 48.426743, lng: 19.386445 },
    { lat: 48.426882, lng: 19.386197 },
    { lat: 48.427096, lng: 19.385724 },
    { lat: 48.427468, lng: 19.385229 },
    { lat: 48.427723, lng: 19.385003 },
    { lat: 48.427733, lng: 19.385075 },
    { lat: 48.428054, lng: 19.384662 },
    { lat: 48.428583, lng: 19.384393 },
    { lat: 48.429211, lng: 19.384312 },
    { lat: 48.429467, lng: 19.384531 },
    { lat: 48.429699, lng: 19.384406 },
    { lat: 48.429778, lng: 19.384476 },
    { lat: 48.429951, lng: 19.384318 },
    { lat: 48.430003, lng: 19.384214 },
    { lat: 48.430045, lng: 19.384141 },
    { lat: 48.430123, lng: 19.383998 },
    { lat: 48.430307, lng: 19.383739 },
    { lat: 48.430627, lng: 19.38321 },
    { lat: 48.43062, lng: 19.382964 },
    { lat: 48.430669, lng: 19.382652 },
    { lat: 48.430793, lng: 19.382495 },
    { lat: 48.430906, lng: 19.382408 },
    { lat: 48.431004, lng: 19.381978 },
    { lat: 48.431268, lng: 19.381644 },
    { lat: 48.431371, lng: 19.381502 },
    { lat: 48.4315, lng: 19.381226 },
    { lat: 48.431554, lng: 19.381339 },
    { lat: 48.431518, lng: 19.381725 },
    { lat: 48.431554, lng: 19.382006 },
    { lat: 48.431724, lng: 19.382229 },
    { lat: 48.432052, lng: 19.382287 },
    { lat: 48.432456, lng: 19.382122 },
    { lat: 48.432498, lng: 19.38168 },
    { lat: 48.432799, lng: 19.381458 },
    { lat: 48.432796, lng: 19.380678 },
    { lat: 48.433115, lng: 19.380545 },
    { lat: 48.433332, lng: 19.380464 },
    { lat: 48.433759, lng: 19.380345 },
    { lat: 48.434031, lng: 19.380163 },
    { lat: 48.435157, lng: 19.379859 },
    { lat: 48.435569, lng: 19.37997 },
    { lat: 48.435584, lng: 19.380106 },
    { lat: 48.436062, lng: 19.380059 },
    { lat: 48.43622, lng: 19.379982 },
    { lat: 48.436494, lng: 19.37975 },
    { lat: 48.436952, lng: 19.379235 },
    { lat: 48.437083, lng: 19.379333 },
    { lat: 48.437211, lng: 19.379494 },
    { lat: 48.437484, lng: 19.379249 },
    { lat: 48.437762, lng: 19.378962 },
    { lat: 48.437915, lng: 19.378725 },
    { lat: 48.437814, lng: 19.378196 },
    { lat: 48.437977, lng: 19.377915 },
    { lat: 48.438937, lng: 19.376494 },
    { lat: 48.439514, lng: 19.375669 },
    { lat: 48.43963, lng: 19.375372 },
    { lat: 48.439831, lng: 19.37548 },
    { lat: 48.440125, lng: 19.375821 },
    { lat: 48.44043, lng: 19.375663 },
    { lat: 48.440742, lng: 19.375395 },
    { lat: 48.441335, lng: 19.374775 },
    { lat: 48.441539, lng: 19.374658 },
    { lat: 48.441929, lng: 19.374755 },
    { lat: 48.442222, lng: 19.37465 },
    { lat: 48.442424, lng: 19.374489 },
    { lat: 48.442604, lng: 19.374308 },
    { lat: 48.44285, lng: 19.373858 },
    { lat: 48.442983, lng: 19.373578 },
    { lat: 48.44364, lng: 19.37347 },
    { lat: 48.44382, lng: 19.373023 },
    { lat: 48.444067, lng: 19.372319 },
    { lat: 48.444143, lng: 19.371913 },
    { lat: 48.444329, lng: 19.371339 },
    { lat: 48.444571, lng: 19.371357 },
    { lat: 48.444652, lng: 19.371368 },
    { lat: 48.444912, lng: 19.370803 },
    { lat: 48.445025, lng: 19.370541 },
    { lat: 48.445159, lng: 19.370181 },
    { lat: 48.445331, lng: 19.369751 },
    { lat: 48.445668, lng: 19.368927 },
    { lat: 48.445817, lng: 19.368495 },
    { lat: 48.445921, lng: 19.368243 },
    { lat: 48.446312, lng: 19.367666 },
    { lat: 48.446347, lng: 19.367649 },
    { lat: 48.446408, lng: 19.367585 },
    { lat: 48.446805, lng: 19.367215 },
    { lat: 48.447116, lng: 19.366897 },
    { lat: 48.447446, lng: 19.366524 },
    { lat: 48.44752, lng: 19.366475 },
    { lat: 48.447964, lng: 19.366153 },
    { lat: 48.448301, lng: 19.365741 },
    { lat: 48.448553, lng: 19.365448 },
    { lat: 48.448591, lng: 19.365551 },
    { lat: 48.448607, lng: 19.365604 },
    { lat: 48.448844, lng: 19.365474 },
    { lat: 48.449136, lng: 19.365396 },
    { lat: 48.449154, lng: 19.365435 },
    { lat: 48.449317, lng: 19.365801 },
    { lat: 48.449647, lng: 19.365824 },
    { lat: 48.4502, lng: 19.365915 },
    { lat: 48.450557, lng: 19.366008 },
    { lat: 48.450579, lng: 19.366009 },
    { lat: 48.450563, lng: 19.366277 },
    { lat: 48.450501, lng: 19.366783 },
    { lat: 48.450478, lng: 19.36706 },
    { lat: 48.450425, lng: 19.367533 },
    { lat: 48.45048, lng: 19.367788 },
    { lat: 48.450844, lng: 19.368788 },
    { lat: 48.450948, lng: 19.369235 },
    { lat: 48.45102, lng: 19.369486 },
    { lat: 48.451157, lng: 19.36987 },
    { lat: 48.451338, lng: 19.370334 },
    { lat: 48.45154, lng: 19.370339 },
    { lat: 48.451956, lng: 19.370338 },
    { lat: 48.452138, lng: 19.370843 },
    { lat: 48.452382, lng: 19.371527 },
    { lat: 48.452592, lng: 19.371922 },
    { lat: 48.452732, lng: 19.372102 },
    { lat: 48.452867, lng: 19.371972 },
    { lat: 48.45309, lng: 19.371797 },
    { lat: 48.453549, lng: 19.371449 },
    { lat: 48.453818, lng: 19.371037 },
    { lat: 48.453933, lng: 19.370988 },
    { lat: 48.453971, lng: 19.370836 },
    { lat: 48.454007, lng: 19.370777 },
    { lat: 48.454294, lng: 19.370689 },
    { lat: 48.454308, lng: 19.370561 },
    { lat: 48.454479, lng: 19.370296 },
    { lat: 48.454634, lng: 19.370167 },
    { lat: 48.455002, lng: 19.369592 },
    { lat: 48.455117, lng: 19.369495 },
    { lat: 48.455215, lng: 19.369328 },
    { lat: 48.455076, lng: 19.368992 },
    { lat: 48.455071, lng: 19.368416 },
    { lat: 48.455233, lng: 19.368364 },
    { lat: 48.455659, lng: 19.368156 },
    { lat: 48.455846, lng: 19.3679 },
    { lat: 48.456011, lng: 19.367514 },
    { lat: 48.456239, lng: 19.367179 },
    { lat: 48.456416, lng: 19.367094 },
  ],
};

export default UnitsDetva;
