import { useMediaQuery } from "usehooks-ts";
import { InfoWindow, Marker } from "@react-google-maps/api";
import REBrokersLight from "../../Images/Pins/REBropkersLight.png";
import REBrokersDark from "../../Images/Pins/REBropkersDark.png";
import { REBrokers } from "../../data/RealEstateBrokers";

export default function REBrokersPins({
  subjects,
  mouseOver,
  setMouseOver,
  view,
  previewREB,
  navSmall,
  setNavSmall,
  indx,
  setSubjects,
  subjectsMenu,
  setSubjectsMenu,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const showDetails = (index) => {
    if (window.location.pathname.includes("share")) {
      window.history.replaceState(null, "", "/");
    }
    indx.current = index;
    setSubjects({ ...subjects, ROffice: false, RBroker: true, FInstitution: false, FBroker: false });
    setSubjectsMenu({ ...subjectsMenu, REO: false, FI: false, FIB: false, REB: true });
    setNavSmall({
      ...navSmall,
      properties: false,
      subjects: true,
      helper: false,
      options: false,
      login: false,
    });
  };

  return subjects.RBroker ? (
    <>
      {REBrokers?.map((item, index) => (
        // <Fragment key={uuidv4()}>
        <Marker
          key={index}
          onClick={() => {
            if (isSmallDevice) {
              showDetails(index);
            }
          }}
          // position={{ lat: Number(item?.lat), lng: Number(item?.lng) }}
          position={{ lat: Number(item?.lat), lng: Number(item?.lng) }}
          onMouseOver={() => previewREB(index)}
          // onMouseOut={() => setMouseOver({ ...mouseOver, previewREB: false, indexREB: null })}
          label={{
            text: item.name,
            color: view === "hybrid" ? "#fff" : "#5491f5",
            fontSize: "14px",
            className: `absolute top-9 left-3 p-1 + ${view === "hybrid" ? "specialtext" : "specialtextWhite"}`,
          }}
          icon={{
            url: REBrokersLight,
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        >
          {mouseOver.previewREB && mouseOver.indexREB === index ? (
            <InfoWindow position={{ lat: Number(item?.lat), lng: Number(item?.lng) }}>
              <div
                onMouseLeave={() => setMouseOver({ ...mouseOver, previewREB: false, indexREB: null })}
                onClick={() => showDetails(index)}
                className="absolute bottom-1 w-60 h-52 z-50 cursor-pointer text-lg flex flex-col justify-start items-center rounded-lg"
              >
                <div className="w-60 h-40 bg-sky-100 z-50 text-lg flex flex-col justify-center items-center rounded-lg">
                  <div className="w-full h-1/2 rounded-t-lg">
                    <img src={item?.url} alt="" className="w-full h-full object-contain rounded-t-lg" />
                  </div>
                  <div className="w-full h-1/2 flex flex-col justify-start p-1 text-base">
                    <p className="font-medium ">{item?.name}</p>
                    <p className="text-sm">{item?.companyName}</p>
                    <p className="text-sm">
                      {item?.city} {item?.number}
                    </p>
                  </div>
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
        // </Fragment>
      ))}
    </>
  ) : null;
}
