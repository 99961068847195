export default function SelectLvNum({ propList, lvNumber, setLvNumber }) {
  return propList?.List instanceof Array ? (
    lvNumber === null ? (
      <div className="w-full flex flex-col justify-center items-center">
        <div>Zvoľ číslo listu vlastníctva</div>
        {propList?.List?.map((value, index) => {
          return (
            <div
              onClick={() => setLvNumber(index)}
              key={index}
              className="h-10 border-2 border-gray-300 w-[78%] rounded-lg mb-1 flex items-center justify-center cursor-pointer"
            >
              <div>{value.cislo_listu_vlastnictva}</div>
            </div>
          );
        })}
      </div>
    ) : null
  ) : null;
}
