import { motion } from "framer-motion";
import PropertyEssence from "./LvParts/PropertyEssence";
import Owners from "./LvParts/Owners";
import Tarchy from "./LvParts/Tarchy";

export default function LV({ subMenu, historyItem, lvSubMenu, setLvSubMenu, previous }) {
  return subMenu?.lv ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-auto flex flex-col items-start overflow-x-scroll text-xs"
      initial={{ x: previous === "" ? null : -500 }}
      animate={{ x: previous === "" ? null : 0 }}
      transition={{ duration: previous === "" ? null : 0.5 }}
    >
      <>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col p-3 w-full items-center">
            <p>Okres</p>
            <p className="font-bold">{historyItem?.response?.List?.nazov_okresu}</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <p>Obec</p>
            <p className="font-bold">{historyItem?.response?.List?.nazov_obce}</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <p>Katastrálne územie</p>
            <p className="font-bold">{historyItem?.response?.List?.nazov_obce}</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <p>Údaje platné k</p>
            <p className="font-bold">{historyItem?.response?.List?.stav_ku_dnu}</p>
          </div>
        </div>
        <div className="flex justify-center text-center py-8">
          <h1 className="text-xl font-medium uppercase">
            Náhľad listu vlastníctva č. {historyItem?.response?.List?.cislo_listu_vlastnictva}
          </h1>
        </div>
        <PropertyEssence setLvSubMenu={setLvSubMenu} lvSubMenu={lvSubMenu} historyItem={historyItem} />
        <Owners setLvSubMenu={setLvSubMenu} lvSubMenu={lvSubMenu} historyItem={historyItem} />
        <Tarchy setLvSubMenu={setLvSubMenu} lvSubMenu={lvSubMenu} historyItem={historyItem} />
      </>
    </motion.div>
  ) : null;
}
