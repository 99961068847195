const UnitsBardejov = {
  Kružlov: [
    { lat: 49.3173415, lng: 21.0995065 },
    { lat: 49.3171499, lng: 21.0996066 },
    { lat: 49.3167323, lng: 21.0993178 },
    { lat: 49.3163877, lng: 21.09924 },
    { lat: 49.3162676, lng: 21.0990729 },
    { lat: 49.3158626, lng: 21.0991446 },
    { lat: 49.3149472, lng: 21.0988479 },
    { lat: 49.3145357, lng: 21.098366 },
    { lat: 49.3138519, lng: 21.0978764 },
    { lat: 49.3135782, lng: 21.0977684 },
    { lat: 49.3133853, lng: 21.0975393 },
    { lat: 49.3127556, lng: 21.0972696 },
    { lat: 49.3124097, lng: 21.0972565 },
    { lat: 49.3121035, lng: 21.0973619 },
    { lat: 49.3117295, lng: 21.097591 },
    { lat: 49.3114911, lng: 21.0976348 },
    { lat: 49.3108259, lng: 21.0973086 },
    { lat: 49.3102862, lng: 21.0971851 },
    { lat: 49.3094726, lng: 21.0971084 },
    { lat: 49.3088392, lng: 21.0974308 },
    { lat: 49.308586, lng: 21.0979286 },
    { lat: 49.308532, lng: 21.0987711 },
    { lat: 49.3083153, lng: 21.0991053 },
    { lat: 49.3080697, lng: 21.0993083 },
    { lat: 49.3078743, lng: 21.0993546 },
    { lat: 49.3075992, lng: 21.0995868 },
    { lat: 49.3073888, lng: 21.0999097 },
    { lat: 49.3070013, lng: 21.1001726 },
    { lat: 49.30666, lng: 21.1002763 },
    { lat: 49.3062563, lng: 21.1006 },
    { lat: 49.3058836, lng: 21.1011218 },
    { lat: 49.3052238, lng: 21.1017793 },
    { lat: 49.3043415, lng: 21.1033004 },
    { lat: 49.3033382, lng: 21.104516 },
    { lat: 49.3028916, lng: 21.1047684 },
    { lat: 49.3016872, lng: 21.1050355 },
    { lat: 49.3005815, lng: 21.1049101 },
    { lat: 49.2999508, lng: 21.1050284 },
    { lat: 49.2990554, lng: 21.1049396 },
    { lat: 49.2987497, lng: 21.1050547 },
    { lat: 49.2979776, lng: 21.1055776 },
    { lat: 49.2975091, lng: 21.1058158 },
    { lat: 49.2967201, lng: 21.1070831 },
    { lat: 49.2966266, lng: 21.1073968 },
    { lat: 49.2965049, lng: 21.1076042 },
    { lat: 49.2958093, lng: 21.1084116 },
    { lat: 49.2954916, lng: 21.1086461 },
    { lat: 49.2950885, lng: 21.1087103 },
    { lat: 49.2936188, lng: 21.1100911 },
    { lat: 49.2933221, lng: 21.1102437 },
    { lat: 49.2928035, lng: 21.1107109 },
    { lat: 49.2914562, lng: 21.1115139 },
    { lat: 49.2901531, lng: 21.1127502 },
    { lat: 49.2886349, lng: 21.1148719 },
    { lat: 49.2883633, lng: 21.1153549 },
    { lat: 49.2879794, lng: 21.1155911 },
    { lat: 49.2876262, lng: 21.1159201 },
    { lat: 49.2869474, lng: 21.1162263 },
    { lat: 49.2860451, lng: 21.1168383 },
    { lat: 49.2852494, lng: 21.1175414 },
    { lat: 49.2851015, lng: 21.1179183 },
    { lat: 49.2848189, lng: 21.1178415 },
    { lat: 49.2832353, lng: 21.1185782 },
    { lat: 49.2855947, lng: 21.1246706 },
    { lat: 49.2858846, lng: 21.1260459 },
    { lat: 49.2861668, lng: 21.1264691 },
    { lat: 49.286218, lng: 21.1266621 },
    { lat: 49.2866381, lng: 21.1273837 },
    { lat: 49.2872184, lng: 21.1280841 },
    { lat: 49.2879347, lng: 21.1295942 },
    { lat: 49.2880273, lng: 21.1300321 },
    { lat: 49.2881996, lng: 21.1303593 },
    { lat: 49.2883786, lng: 21.1310382 },
    { lat: 49.2885387, lng: 21.1311958 },
    { lat: 49.2887769, lng: 21.1320023 },
    { lat: 49.2888209, lng: 21.132499 },
    { lat: 49.2894789, lng: 21.1329594 },
    { lat: 49.2898403, lng: 21.1332864 },
    { lat: 49.289789, lng: 21.133333 },
    { lat: 49.2901619, lng: 21.1340968 },
    { lat: 49.290765, lng: 21.1357686 },
    { lat: 49.2908733, lng: 21.1362051 },
    { lat: 49.2911233, lng: 21.1367822 },
    { lat: 49.2920099, lng: 21.1358566 },
    { lat: 49.2926711, lng: 21.1348388 },
    { lat: 49.2932345, lng: 21.1343282 },
    { lat: 49.2937817, lng: 21.133659 },
    { lat: 49.2946315, lng: 21.133062 },
    { lat: 49.2950472, lng: 21.1326156 },
    { lat: 49.2968629, lng: 21.1311396 },
    { lat: 49.2976251, lng: 21.1310169 },
    { lat: 49.2983155, lng: 21.1309922 },
    { lat: 49.2986166, lng: 21.1310525 },
    { lat: 49.299103, lng: 21.1313483 },
    { lat: 49.2999256, lng: 21.1321659 },
    { lat: 49.3003665, lng: 21.1330181 },
    { lat: 49.301104, lng: 21.1338874 },
    { lat: 49.3013327, lng: 21.134141 },
    { lat: 49.3018937, lng: 21.1345917 },
    { lat: 49.3024666, lng: 21.1354555 },
    { lat: 49.3027974, lng: 21.1356623 },
    { lat: 49.3025155, lng: 21.136221 },
    { lat: 49.3021675, lng: 21.1366333 },
    { lat: 49.302357, lng: 21.1368299 },
    { lat: 49.3022008, lng: 21.1372993 },
    { lat: 49.3018031, lng: 21.1389235 },
    { lat: 49.301839, lng: 21.1391357 },
    { lat: 49.3019391, lng: 21.1393479 },
    { lat: 49.3018922, lng: 21.1396117 },
    { lat: 49.3021834, lng: 21.1401722 },
    { lat: 49.3023255, lng: 21.1405721 },
    { lat: 49.3023016, lng: 21.1409225 },
    { lat: 49.3022598, lng: 21.14113 },
    { lat: 49.3021439, lng: 21.1413142 },
    { lat: 49.3021062, lng: 21.1416141 },
    { lat: 49.3014996, lng: 21.1425065 },
    { lat: 49.3018114, lng: 21.1430406 },
    { lat: 49.3022059, lng: 21.144068 },
    { lat: 49.301705, lng: 21.1448127 },
    { lat: 49.3017071, lng: 21.1450887 },
    { lat: 49.3018203, lng: 21.1454345 },
    { lat: 49.3020938, lng: 21.1456902 },
    { lat: 49.3022334, lng: 21.1459734 },
    { lat: 49.3023242, lng: 21.1464993 },
    { lat: 49.3025067, lng: 21.1469697 },
    { lat: 49.3026257, lng: 21.1478617 },
    { lat: 49.3023865, lng: 21.1482337 },
    { lat: 49.3023401, lng: 21.1487978 },
    { lat: 49.3024582, lng: 21.1500086 },
    { lat: 49.3024156, lng: 21.1500496 },
    { lat: 49.3024922, lng: 21.1505528 },
    { lat: 49.3025171, lng: 21.1515623 },
    { lat: 49.3023126, lng: 21.1518233 },
    { lat: 49.3029451, lng: 21.15286 },
    { lat: 49.3048434, lng: 21.1546547 },
    { lat: 49.3047451, lng: 21.1549335 },
    { lat: 49.3046282, lng: 21.1549727 },
    { lat: 49.3044929, lng: 21.1554979 },
    { lat: 49.3044461, lng: 21.1556232 },
    { lat: 49.3043646, lng: 21.1556793 },
    { lat: 49.305085, lng: 21.1566556 },
    { lat: 49.3055726, lng: 21.1573478 },
    { lat: 49.3059952, lng: 21.1569746 },
    { lat: 49.3065454, lng: 21.1566472 },
    { lat: 49.3070244, lng: 21.1564901 },
    { lat: 49.3075699, lng: 21.1564515 },
    { lat: 49.3081312, lng: 21.1557991 },
    { lat: 49.3098866, lng: 21.1549796 },
    { lat: 49.3103095, lng: 21.1548635 },
    { lat: 49.3111973, lng: 21.1548625 },
    { lat: 49.3125011, lng: 21.15374 },
    { lat: 49.3141363, lng: 21.1525307 },
    { lat: 49.3152339, lng: 21.150968 },
    { lat: 49.3154568, lng: 21.1505452 },
    { lat: 49.3158337, lng: 21.1492187 },
    { lat: 49.3160813, lng: 21.1485386 },
    { lat: 49.316823, lng: 21.147111 },
    { lat: 49.3173959, lng: 21.1461844 },
    { lat: 49.3180059, lng: 21.1453309 },
    { lat: 49.3182873, lng: 21.1448315 },
    { lat: 49.3184589, lng: 21.1444549 },
    { lat: 49.3184613, lng: 21.1440374 },
    { lat: 49.3183455, lng: 21.1432878 },
    { lat: 49.3184046, lng: 21.1426259 },
    { lat: 49.3184561, lng: 21.1422989 },
    { lat: 49.3186827, lng: 21.141633 },
    { lat: 49.3190851, lng: 21.1410082 },
    { lat: 49.3194903, lng: 21.1405119 },
    { lat: 49.3203756, lng: 21.1389374 },
    { lat: 49.3204282, lng: 21.1388535 },
    { lat: 49.3205921, lng: 21.1374869 },
    { lat: 49.3206431, lng: 21.1374291 },
    { lat: 49.3204133, lng: 21.1360281 },
    { lat: 49.3200625, lng: 21.1358563 },
    { lat: 49.3199355, lng: 21.136001 },
    { lat: 49.3196999, lng: 21.1355603 },
    { lat: 49.3190137, lng: 21.1336466 },
    { lat: 49.3188614, lng: 21.1334905 },
    { lat: 49.318847, lng: 21.1329956 },
    { lat: 49.318622, lng: 21.1327114 },
    { lat: 49.3181575, lng: 21.1316871 },
    { lat: 49.3177135, lng: 21.131383 },
    { lat: 49.3174268, lng: 21.1309663 },
    { lat: 49.3170341, lng: 21.1296496 },
    { lat: 49.3170091, lng: 21.1293864 },
    { lat: 49.3167918, lng: 21.129062 },
    { lat: 49.3167523, lng: 21.1284914 },
    { lat: 49.316854, lng: 21.1274711 },
    { lat: 49.3167764, lng: 21.1274696 },
    { lat: 49.3169685, lng: 21.1273941 },
    { lat: 49.3170997, lng: 21.1265909 },
    { lat: 49.3170511, lng: 21.1264787 },
    { lat: 49.3171077, lng: 21.1259962 },
    { lat: 49.3170988, lng: 21.1240355 },
    { lat: 49.317289, lng: 21.123014 },
    { lat: 49.3172812, lng: 21.1228335 },
    { lat: 49.3169344, lng: 21.1226114 },
    { lat: 49.3169865, lng: 21.1225232 },
    { lat: 49.3174946, lng: 21.1202301 },
    { lat: 49.317421, lng: 21.1200347 },
    { lat: 49.3173374, lng: 21.1202 },
    { lat: 49.3180019, lng: 21.1167053 },
    { lat: 49.318091, lng: 21.1159027 },
    { lat: 49.3178457, lng: 21.1150826 },
    { lat: 49.3187452, lng: 21.1120477 },
    { lat: 49.3191913, lng: 21.1081655 },
    { lat: 49.3191506, lng: 21.1064623 },
    { lat: 49.3190078, lng: 21.105155 },
    { lat: 49.3184307, lng: 21.1035344 },
    { lat: 49.318371, lng: 21.1029722 },
    { lat: 49.3184098, lng: 21.1020286 },
    { lat: 49.3182936, lng: 21.1010666 },
    { lat: 49.3182185, lng: 21.1007573 },
    { lat: 49.3180296, lng: 21.1003739 },
    { lat: 49.3177195, lng: 21.0998906 },
    { lat: 49.317347, lng: 21.0995375 },
    { lat: 49.3173415, lng: 21.0995065 },
  ],
  Stebník: [
    { lat: 49.3962485, lng: 21.2702723 },
    { lat: 49.3961619, lng: 21.2672327 },
    { lat: 49.3966112, lng: 21.2659895 },
    { lat: 49.3964738, lng: 21.2646483 },
    { lat: 49.3964487, lng: 21.2631954 },
    { lat: 49.3969327, lng: 21.2624959 },
    { lat: 49.3973242, lng: 21.2614211 },
    { lat: 49.3980065, lng: 21.2592923 },
    { lat: 49.3981951, lng: 21.2590964 },
    { lat: 49.3986103, lng: 21.2575083 },
    { lat: 49.3985152, lng: 21.2569733 },
    { lat: 49.3990385, lng: 21.254022 },
    { lat: 49.3987168, lng: 21.2533612 },
    { lat: 49.3987027, lng: 21.2527904 },
    { lat: 49.398854, lng: 21.2524021 },
    { lat: 49.3988939, lng: 21.2513036 },
    { lat: 49.398668, lng: 21.2506167 },
    { lat: 49.3986316, lng: 21.250011 },
    { lat: 49.397314, lng: 21.2508167 },
    { lat: 49.39694, lng: 21.2505816 },
    { lat: 49.3961249, lng: 21.2506822 },
    { lat: 49.3964337, lng: 21.2495916 },
    { lat: 49.3965656, lng: 21.2494861 },
    { lat: 49.3966272, lng: 21.2492183 },
    { lat: 49.3968666, lng: 21.2486167 },
    { lat: 49.393919, lng: 21.2494749 },
    { lat: 49.3933866, lng: 21.2496889 },
    { lat: 49.3924348, lng: 21.2502068 },
    { lat: 49.3917393, lng: 21.2504607 },
    { lat: 49.3908084, lng: 21.250591 },
    { lat: 49.3908183, lng: 21.2504346 },
    { lat: 49.3905619, lng: 21.2503717 },
    { lat: 49.3903723, lng: 21.2495415 },
    { lat: 49.3903037, lng: 21.2490246 },
    { lat: 49.3900643, lng: 21.2482959 },
    { lat: 49.3901654, lng: 21.2467413 },
    { lat: 49.390572, lng: 21.2459071 },
    { lat: 49.3903759, lng: 21.244536 },
    { lat: 49.3906001, lng: 21.2439546 },
    { lat: 49.3906754, lng: 21.2434035 },
    { lat: 49.3905638, lng: 21.2431384 },
    { lat: 49.3906477, lng: 21.2421279 },
    { lat: 49.3905546, lng: 21.2417352 },
    { lat: 49.3907904, lng: 21.2413119 },
    { lat: 49.3905858, lng: 21.2408737 },
    { lat: 49.3912689, lng: 21.24 },
    { lat: 49.391166, lng: 21.2393934 },
    { lat: 49.3915009, lng: 21.238467 },
    { lat: 49.3914482, lng: 21.2376997 },
    { lat: 49.3922905, lng: 21.2364352 },
    { lat: 49.3926744, lng: 21.2357043 },
    { lat: 49.3936517, lng: 21.2343377 },
    { lat: 49.3944507, lng: 21.2340446 },
    { lat: 49.3951658, lng: 21.2338834 },
    { lat: 49.3968323, lng: 21.2347023 },
    { lat: 49.3971313, lng: 21.2353093 },
    { lat: 49.3970254, lng: 21.23641 },
    { lat: 49.3978718, lng: 21.2379654 },
    { lat: 49.3989842, lng: 21.2376564 },
    { lat: 49.3993581, lng: 21.2372657 },
    { lat: 49.3997252, lng: 21.2374969 },
    { lat: 49.4001547, lng: 21.2372684 },
    { lat: 49.4005017, lng: 21.2372442 },
    { lat: 49.4010855, lng: 21.2373156 },
    { lat: 49.4015875, lng: 21.2376498 },
    { lat: 49.4018626, lng: 21.2380791 },
    { lat: 49.4020896, lng: 21.2391311 },
    { lat: 49.4020818, lng: 21.2399091 },
    { lat: 49.4023678, lng: 21.2403571 },
    { lat: 49.4025708, lng: 21.2412905 },
    { lat: 49.4026348, lng: 21.2421728 },
    { lat: 49.4030624, lng: 21.2424308 },
    { lat: 49.4034379, lng: 21.2425288 },
    { lat: 49.4044284, lng: 21.2424299 },
    { lat: 49.4057129, lng: 21.2416837 },
    { lat: 49.4073774, lng: 21.2401174 },
    { lat: 49.4077299, lng: 21.2395718 },
    { lat: 49.4083315, lng: 21.2390838 },
    { lat: 49.4089269, lng: 21.2385023 },
    { lat: 49.4111964, lng: 21.2359745 },
    { lat: 49.4124351, lng: 21.2345065 },
    { lat: 49.416928, lng: 21.2295549 },
    { lat: 49.4194501, lng: 21.226426 },
    { lat: 49.4190706, lng: 21.2262582 },
    { lat: 49.4189593, lng: 21.2262665 },
    { lat: 49.41828, lng: 21.2266794 },
    { lat: 49.4178787, lng: 21.2270282 },
    { lat: 49.4175725, lng: 21.2269951 },
    { lat: 49.4171687, lng: 21.227053 },
    { lat: 49.4169518, lng: 21.2272548 },
    { lat: 49.416731, lng: 21.2267451 },
    { lat: 49.4165957, lng: 21.2261772 },
    { lat: 49.416265, lng: 21.2256358 },
    { lat: 49.4160932, lng: 21.2252024 },
    { lat: 49.4159947, lng: 21.2251832 },
    { lat: 49.4157462, lng: 21.2247102 },
    { lat: 49.4156141, lng: 21.2243591 },
    { lat: 49.4152811, lng: 21.2239862 },
    { lat: 49.415129, lng: 21.223866 },
    { lat: 49.4147752, lng: 21.2235794 },
    { lat: 49.4142491, lng: 21.2227902 },
    { lat: 49.4141595, lng: 21.2225531 },
    { lat: 49.41414, lng: 21.222505 },
    { lat: 49.41398, lng: 21.222138 },
    { lat: 49.4138047, lng: 21.2217223 },
    { lat: 49.4135589, lng: 21.2215532 },
    { lat: 49.4134122, lng: 21.2211773 },
    { lat: 49.4131757, lng: 21.2209567 },
    { lat: 49.4129488, lng: 21.2206653 },
    { lat: 49.412808, lng: 21.220655 },
    { lat: 49.4123929, lng: 21.2201171 },
    { lat: 49.4123222, lng: 21.2197289 },
    { lat: 49.4123312, lng: 21.2193976 },
    { lat: 49.4121527, lng: 21.2189297 },
    { lat: 49.412096, lng: 21.218814 },
    { lat: 49.4120949, lng: 21.2188088 },
    { lat: 49.411533, lng: 21.217836 },
    { lat: 49.4115295, lng: 21.2178285 },
    { lat: 49.4111003, lng: 21.2171338 },
    { lat: 49.4107252, lng: 21.2165916 },
    { lat: 49.4103606, lng: 21.2161939 },
    { lat: 49.4097546, lng: 21.2152777 },
    { lat: 49.4091011, lng: 21.2140802 },
    { lat: 49.4089009, lng: 21.2136301 },
    { lat: 49.4084961, lng: 21.2132078 },
    { lat: 49.4084095, lng: 21.2130226 },
    { lat: 49.4079558, lng: 21.2122994 },
    { lat: 49.40795, lng: 21.212293 },
    { lat: 49.407674, lng: 21.212044 },
    { lat: 49.407064, lng: 21.211478 },
    { lat: 49.4070499, lng: 21.2114646 },
    { lat: 49.4059509, lng: 21.2105632 },
    { lat: 49.4056454, lng: 21.2102136 },
    { lat: 49.405638, lng: 21.210207 },
    { lat: 49.405162, lng: 21.209831 },
    { lat: 49.4050589, lng: 21.2097423 },
    { lat: 49.4047453, lng: 21.2094806 },
    { lat: 49.404402, lng: 21.2092615 },
    { lat: 49.4040259, lng: 21.2091285 },
    { lat: 49.4039267, lng: 21.2090922 },
    { lat: 49.403416, lng: 21.2085202 },
    { lat: 49.4031534, lng: 21.2083973 },
    { lat: 49.40303, lng: 21.2083083 },
    { lat: 49.402482, lng: 21.2081942 },
    { lat: 49.4020762, lng: 21.2090064 },
    { lat: 49.4018807, lng: 21.209708 },
    { lat: 49.4015554, lng: 21.2097038 },
    { lat: 49.3986344, lng: 21.2088924 },
    { lat: 49.3980197, lng: 21.2081191 },
    { lat: 49.3973666, lng: 21.2076325 },
    { lat: 49.3969321, lng: 21.2070744 },
    { lat: 49.3963209, lng: 21.205935 },
    { lat: 49.3958297, lng: 21.2066808 },
    { lat: 49.395222, lng: 21.2073722 },
    { lat: 49.3946631, lng: 21.2075272 },
    { lat: 49.393956, lng: 21.2085664 },
    { lat: 49.3937166, lng: 21.2090837 },
    { lat: 49.3933594, lng: 21.2094332 },
    { lat: 49.3931804, lng: 21.2098312 },
    { lat: 49.3928294, lng: 21.2114752 },
    { lat: 49.3928181, lng: 21.211795 },
    { lat: 49.3926184, lng: 21.2121859 },
    { lat: 49.3923621, lng: 21.2132873 },
    { lat: 49.3922013, lng: 21.2136522 },
    { lat: 49.3921584, lng: 21.2145262 },
    { lat: 49.3918402, lng: 21.2152626 },
    { lat: 49.3909578, lng: 21.2162778 },
    { lat: 49.3904831, lng: 21.2163901 },
    { lat: 49.3902192, lng: 21.2163702 },
    { lat: 49.3894547, lng: 21.2165656 },
    { lat: 49.3881817, lng: 21.2177206 },
    { lat: 49.3879476, lng: 21.217467 },
    { lat: 49.3871453, lng: 21.2173549 },
    { lat: 49.38668, lng: 21.2169667 },
    { lat: 49.3862275, lng: 21.2167065 },
    { lat: 49.3853741, lng: 21.2159224 },
    { lat: 49.3844488, lng: 21.2159767 },
    { lat: 49.3834309, lng: 21.216397 },
    { lat: 49.3831213, lng: 21.2166097 },
    { lat: 49.3814372, lng: 21.2165429 },
    { lat: 49.3808246, lng: 21.2172199 },
    { lat: 49.3786305, lng: 21.2183477 },
    { lat: 49.3781195, lng: 21.2187797 },
    { lat: 49.3778008, lng: 21.2193588 },
    { lat: 49.3773075, lng: 21.2198523 },
    { lat: 49.3767902, lng: 21.2200052 },
    { lat: 49.3749962, lng: 21.2198459 },
    { lat: 49.3742186, lng: 21.219845 },
    { lat: 49.3737607, lng: 21.2197358 },
    { lat: 49.3736183, lng: 21.2199281 },
    { lat: 49.3735051, lng: 21.2200948 },
    { lat: 49.3722894, lng: 21.2212036 },
    { lat: 49.3705323, lng: 21.2217002 },
    { lat: 49.3701052, lng: 21.2219628 },
    { lat: 49.3695526, lng: 21.2226937 },
    { lat: 49.3692222, lng: 21.2236963 },
    { lat: 49.3687502, lng: 21.2263865 },
    { lat: 49.3688179, lng: 21.2272698 },
    { lat: 49.3687517, lng: 21.2274941 },
    { lat: 49.3683984, lng: 21.2282661 },
    { lat: 49.367731, lng: 21.2294673 },
    { lat: 49.3674651, lng: 21.229858 },
    { lat: 49.3666265, lng: 21.230574 },
    { lat: 49.366363, lng: 21.2305337 },
    { lat: 49.3662816, lng: 21.2308533 },
    { lat: 49.3658804, lng: 21.2312317 },
    { lat: 49.3656568, lng: 21.2316431 },
    { lat: 49.3649739, lng: 21.2322016 },
    { lat: 49.3643017, lng: 21.2329536 },
    { lat: 49.3639588, lng: 21.2337352 },
    { lat: 49.3636863, lng: 21.2341667 },
    { lat: 49.362397, lng: 21.235193 },
    { lat: 49.3617835, lng: 21.23581 },
    { lat: 49.3611776, lng: 21.2366347 },
    { lat: 49.3600161, lng: 21.238384 },
    { lat: 49.3597046, lng: 21.2390908 },
    { lat: 49.3597038, lng: 21.2394644 },
    { lat: 49.3594662, lng: 21.2400154 },
    { lat: 49.3592301, lng: 21.2407889 },
    { lat: 49.3592363, lng: 21.2419857 },
    { lat: 49.359321, lng: 21.2424034 },
    { lat: 49.3591732, lng: 21.2429743 },
    { lat: 49.3592767, lng: 21.2432661 },
    { lat: 49.3594118, lng: 21.2440054 },
    { lat: 49.3595228, lng: 21.2454622 },
    { lat: 49.3578867, lng: 21.2461227 },
    { lat: 49.356112, lng: 21.247842 },
    { lat: 49.3552088, lng: 21.2489133 },
    { lat: 49.3549138, lng: 21.2490991 },
    { lat: 49.3546386, lng: 21.2491115 },
    { lat: 49.3547314, lng: 21.2493489 },
    { lat: 49.3567796, lng: 21.2546337 },
    { lat: 49.358918, lng: 21.2612603 },
    { lat: 49.3592887, lng: 21.2621712 },
    { lat: 49.3598613, lng: 21.2640889 },
    { lat: 49.3600019, lng: 21.264899 },
    { lat: 49.3602421, lng: 21.2656558 },
    { lat: 49.3602384, lng: 21.2658665 },
    { lat: 49.3607058, lng: 21.2661826 },
    { lat: 49.3607581, lng: 21.2662731 },
    { lat: 49.3619682, lng: 21.2704074 },
    { lat: 49.3632255, lng: 21.2729068 },
    { lat: 49.3633314, lng: 21.2732419 },
    { lat: 49.3648927, lng: 21.2745636 },
    { lat: 49.3650333, lng: 21.2743034 },
    { lat: 49.3662573, lng: 21.2753416 },
    { lat: 49.3661958, lng: 21.2755381 },
    { lat: 49.3665669, lng: 21.2758337 },
    { lat: 49.3692082, lng: 21.2812043 },
    { lat: 49.3695267, lng: 21.2814665 },
    { lat: 49.3706708, lng: 21.2807188 },
    { lat: 49.3710114, lng: 21.2812622 },
    { lat: 49.3719416, lng: 21.2818489 },
    { lat: 49.372088, lng: 21.282301 },
    { lat: 49.3731375, lng: 21.283261 },
    { lat: 49.3738502, lng: 21.2836823 },
    { lat: 49.3741906, lng: 21.2837047 },
    { lat: 49.3755963, lng: 21.2841279 },
    { lat: 49.3758908, lng: 21.2842698 },
    { lat: 49.3761101, lng: 21.2844879 },
    { lat: 49.3775755, lng: 21.2843233 },
    { lat: 49.3782889, lng: 21.284733 },
    { lat: 49.378949, lng: 21.2846866 },
    { lat: 49.3795604, lng: 21.2843988 },
    { lat: 49.3805575, lng: 21.2822899 },
    { lat: 49.3805672, lng: 21.2818686 },
    { lat: 49.3807797, lng: 21.2817567 },
    { lat: 49.3807448, lng: 21.2814883 },
    { lat: 49.3811257, lng: 21.2809575 },
    { lat: 49.3813031, lng: 21.2804925 },
    { lat: 49.3814354, lng: 21.279888 },
    { lat: 49.3817704, lng: 21.2792488 },
    { lat: 49.3821524, lng: 21.2788515 },
    { lat: 49.3824821, lng: 21.2787869 },
    { lat: 49.3830322, lng: 21.2783358 },
    { lat: 49.3834745, lng: 21.2782668 },
    { lat: 49.3839024, lng: 21.2779378 },
    { lat: 49.3855716, lng: 21.278298 },
    { lat: 49.3860742, lng: 21.2782178 },
    { lat: 49.3863879, lng: 21.2786195 },
    { lat: 49.3869603, lng: 21.2780115 },
    { lat: 49.3887351, lng: 21.2766162 },
    { lat: 49.3893177, lng: 21.2759767 },
    { lat: 49.3894306, lng: 21.275433 },
    { lat: 49.3897852, lng: 21.2752536 },
    { lat: 49.390078, lng: 21.2749351 },
    { lat: 49.3903087, lng: 21.2749317 },
    { lat: 49.392251, lng: 21.2717512 },
    { lat: 49.3940989, lng: 21.2704323 },
    { lat: 49.395042, lng: 21.2699595 },
    { lat: 49.3962485, lng: 21.2702723 },
  ],
  Snakov: [
    { lat: 49.344486, lng: 21.009496 },
    { lat: 49.3438091, lng: 21.011332 },
    { lat: 49.3430284, lng: 21.0118261 },
    { lat: 49.3429063, lng: 21.0133989 },
    { lat: 49.3404859, lng: 21.021353 },
    { lat: 49.3399441, lng: 21.0220673 },
    { lat: 49.3389035, lng: 21.0228379 },
    { lat: 49.3361573, lng: 21.0225497 },
    { lat: 49.3354447, lng: 21.0229182 },
    { lat: 49.3348134, lng: 21.0228876 },
    { lat: 49.3343388, lng: 21.0225795 },
    { lat: 49.333804, lng: 21.0224358 },
    { lat: 49.3332587, lng: 21.0227653 },
    { lat: 49.3325101, lng: 21.0236897 },
    { lat: 49.3323587, lng: 21.0253664 },
    { lat: 49.3315994, lng: 21.0262443 },
    { lat: 49.3312892, lng: 21.0268066 },
    { lat: 49.330325, lng: 21.0278172 },
    { lat: 49.3300307, lng: 21.0278039 },
    { lat: 49.3295202, lng: 21.0294082 },
    { lat: 49.3291772, lng: 21.0295494 },
    { lat: 49.3291229, lng: 21.0299994 },
    { lat: 49.3280413, lng: 21.0306484 },
    { lat: 49.3271302, lng: 21.0323281 },
    { lat: 49.3266196, lng: 21.0327208 },
    { lat: 49.3264168, lng: 21.0333012 },
    { lat: 49.3256471, lng: 21.033475 },
    { lat: 49.325029, lng: 21.0346078 },
    { lat: 49.3247518, lng: 21.0347061 },
    { lat: 49.3246114, lng: 21.0352997 },
    { lat: 49.3240041, lng: 21.0355897 },
    { lat: 49.3228335, lng: 21.0386387 },
    { lat: 49.3221341, lng: 21.0393533 },
    { lat: 49.3222352, lng: 21.0396271 },
    { lat: 49.3211591, lng: 21.0408979 },
    { lat: 49.3214727, lng: 21.0415174 },
    { lat: 49.3209179, lng: 21.0417239 },
    { lat: 49.3206298, lng: 21.0427291 },
    { lat: 49.3204136, lng: 21.0428699 },
    { lat: 49.3201002, lng: 21.0434758 },
    { lat: 49.319922, lng: 21.0436754 },
    { lat: 49.3198529, lng: 21.0441467 },
    { lat: 49.319853, lng: 21.0444937 },
    { lat: 49.3196532, lng: 21.0448277 },
    { lat: 49.3195717, lng: 21.0451582 },
    { lat: 49.3193677, lng: 21.0456025 },
    { lat: 49.3189289, lng: 21.0461277 },
    { lat: 49.3184994, lng: 21.0476366 },
    { lat: 49.3186715, lng: 21.0478757 },
    { lat: 49.3186988, lng: 21.048027 },
    { lat: 49.3184016, lng: 21.048127 },
    { lat: 49.3181817, lng: 21.048772 },
    { lat: 49.3183511, lng: 21.0492126 },
    { lat: 49.3185405, lng: 21.0500272 },
    { lat: 49.3184692, lng: 21.0504261 },
    { lat: 49.3181195, lng: 21.0504017 },
    { lat: 49.3177766, lng: 21.0507601 },
    { lat: 49.3178127, lng: 21.0510078 },
    { lat: 49.3176457, lng: 21.0510943 },
    { lat: 49.3175988, lng: 21.0513507 },
    { lat: 49.3176662, lng: 21.0517024 },
    { lat: 49.3174413, lng: 21.0522568 },
    { lat: 49.3170099, lng: 21.0527356 },
    { lat: 49.3170571, lng: 21.0531912 },
    { lat: 49.3174522, lng: 21.0536133 },
    { lat: 49.3174409, lng: 21.0539936 },
    { lat: 49.3171937, lng: 21.0540061 },
    { lat: 49.3169953, lng: 21.0543757 },
    { lat: 49.3168962, lng: 21.0550386 },
    { lat: 49.3170474, lng: 21.0554344 },
    { lat: 49.3167634, lng: 21.0555626 },
    { lat: 49.3165729, lng: 21.0560765 },
    { lat: 49.316398, lng: 21.0561673 },
    { lat: 49.3162537, lng: 21.0565042 },
    { lat: 49.316173, lng: 21.0569698 },
    { lat: 49.3163181, lng: 21.0573178 },
    { lat: 49.3162484, lng: 21.0574576 },
    { lat: 49.3165572, lng: 21.0577492 },
    { lat: 49.3165795, lng: 21.0579581 },
    { lat: 49.3164392, lng: 21.0583817 },
    { lat: 49.31609, lng: 21.0585134 },
    { lat: 49.3156798, lng: 21.0592289 },
    { lat: 49.3154717, lng: 21.0600727 },
    { lat: 49.3152625, lng: 21.0604243 },
    { lat: 49.3156367, lng: 21.0616307 },
    { lat: 49.3159355, lng: 21.0616854 },
    { lat: 49.3161478, lng: 21.0615691 },
    { lat: 49.3161838, lng: 21.0618806 },
    { lat: 49.3159967, lng: 21.0621482 },
    { lat: 49.3160243, lng: 21.0623766 },
    { lat: 49.3163784, lng: 21.062645 },
    { lat: 49.3162955, lng: 21.0630462 },
    { lat: 49.3164808, lng: 21.06338 },
    { lat: 49.3159918, lng: 21.0636094 },
    { lat: 49.3158649, lng: 21.0640045 },
    { lat: 49.3158137, lng: 21.0644719 },
    { lat: 49.3152524, lng: 21.065458 },
    { lat: 49.3148772, lng: 21.0658974 },
    { lat: 49.3149135, lng: 21.0669241 },
    { lat: 49.3151902, lng: 21.0679049 },
    { lat: 49.3151975, lng: 21.0683358 },
    { lat: 49.316008, lng: 21.0699971 },
    { lat: 49.3172468, lng: 21.070237 },
    { lat: 49.3178434, lng: 21.0697506 },
    { lat: 49.3185289, lng: 21.069584 },
    { lat: 49.3188908, lng: 21.0702346 },
    { lat: 49.3192482, lng: 21.0702565 },
    { lat: 49.319348, lng: 21.07028 },
    { lat: 49.320125, lng: 21.0690009 },
    { lat: 49.3203132, lng: 21.06838 },
    { lat: 49.3204644, lng: 21.0685274 },
    { lat: 49.3213745, lng: 21.0670485 },
    { lat: 49.3225473, lng: 21.0660126 },
    { lat: 49.3227299, lng: 21.0659488 },
    { lat: 49.3230252, lng: 21.0654753 },
    { lat: 49.3231784, lng: 21.0654071 },
    { lat: 49.3233792, lng: 21.0651474 },
    { lat: 49.3236134, lng: 21.0650825 },
    { lat: 49.3239489, lng: 21.0648379 },
    { lat: 49.3247513, lng: 21.0646989 },
    { lat: 49.3249211, lng: 21.0647651 },
    { lat: 49.3251102, lng: 21.0646968 },
    { lat: 49.3253188, lng: 21.0649895 },
    { lat: 49.3255611, lng: 21.0646856 },
    { lat: 49.3255687, lng: 21.0647806 },
    { lat: 49.3262001, lng: 21.0658049 },
    { lat: 49.3264334, lng: 21.0655388 },
    { lat: 49.3265638, lng: 21.0659493 },
    { lat: 49.3271879, lng: 21.0663926 },
    { lat: 49.3272881, lng: 21.0661809 },
    { lat: 49.3273226, lng: 21.0664182 },
    { lat: 49.3274931, lng: 21.0665439 },
    { lat: 49.3275503, lng: 21.0663552 },
    { lat: 49.3279014, lng: 21.0663934 },
    { lat: 49.3281903, lng: 21.0666271 },
    { lat: 49.328527, lng: 21.0663193 },
    { lat: 49.3288296, lng: 21.0664246 },
    { lat: 49.3294197, lng: 21.0658412 },
    { lat: 49.3299506, lng: 21.0654837 },
    { lat: 49.3306061, lng: 21.0650812 },
    { lat: 49.3314959, lng: 21.0646512 },
    { lat: 49.3321865, lng: 21.0646133 },
    { lat: 49.3323216, lng: 21.0642983 },
    { lat: 49.3336368, lng: 21.0640445 },
    { lat: 49.335669, lng: 21.0631662 },
    { lat: 49.3373774, lng: 21.0613542 },
    { lat: 49.3386939, lng: 21.0602998 },
    { lat: 49.3390011, lng: 21.0602326 },
    { lat: 49.3393408, lng: 21.0600558 },
    { lat: 49.3397942, lng: 21.0599808 },
    { lat: 49.3405528, lng: 21.0597392 },
    { lat: 49.3405952, lng: 21.0594885 },
    { lat: 49.3413113, lng: 21.0571877 },
    { lat: 49.341591, lng: 21.0567949 },
    { lat: 49.3419967, lng: 21.0569516 },
    { lat: 49.3423594, lng: 21.0565799 },
    { lat: 49.3428805, lng: 21.0565771 },
    { lat: 49.3435199, lng: 21.0563772 },
    { lat: 49.3441677, lng: 21.055455 },
    { lat: 49.346179, lng: 21.0551245 },
    { lat: 49.3471856, lng: 21.0552091 },
    { lat: 49.3473494, lng: 21.0545283 },
    { lat: 49.3493567, lng: 21.053516 },
    { lat: 49.3514132, lng: 21.0527268 },
    { lat: 49.3530881, lng: 21.0517979 },
    { lat: 49.3538936, lng: 21.0516233 },
    { lat: 49.3558788, lng: 21.0521104 },
    { lat: 49.3559983, lng: 21.0498503 },
    { lat: 49.3563517, lng: 21.0497517 },
    { lat: 49.35669, lng: 21.0499846 },
    { lat: 49.3571967, lng: 21.0508975 },
    { lat: 49.3592475, lng: 21.0494873 },
    { lat: 49.3596961, lng: 21.0509006 },
    { lat: 49.3602755, lng: 21.0507258 },
    { lat: 49.3603978, lng: 21.0503292 },
    { lat: 49.3603416, lng: 21.0492164 },
    { lat: 49.3609268, lng: 21.0489371 },
    { lat: 49.3620652, lng: 21.0505287 },
    { lat: 49.3631089, lng: 21.0497458 },
    { lat: 49.3638259, lng: 21.0501331 },
    { lat: 49.363212, lng: 21.052224 },
    { lat: 49.363756, lng: 21.051237 },
    { lat: 49.3641005, lng: 21.0506211 },
    { lat: 49.364106, lng: 21.050617 },
    { lat: 49.3646582, lng: 21.0501236 },
    { lat: 49.3651856, lng: 21.0495036 },
    { lat: 49.365192, lng: 21.049497 },
    { lat: 49.3656484, lng: 21.0489285 },
    { lat: 49.365836, lng: 21.048085 },
    { lat: 49.3658373, lng: 21.0480788 },
    { lat: 49.3659024, lng: 21.0476451 },
    { lat: 49.3655438, lng: 21.0471113 },
    { lat: 49.3654854, lng: 21.0467398 },
    { lat: 49.3654801, lng: 21.0464515 },
    { lat: 49.3655278, lng: 21.0459028 },
    { lat: 49.3654648, lng: 21.0455219 },
    { lat: 49.3654049, lng: 21.0451907 },
    { lat: 49.365402, lng: 21.045181 },
    { lat: 49.365387, lng: 21.04493 },
    { lat: 49.365359, lng: 21.044527 },
    { lat: 49.3653554, lng: 21.0444494 },
    { lat: 49.365354, lng: 21.044412 },
    { lat: 49.365356, lng: 21.044098 },
    { lat: 49.36536, lng: 21.043896 },
    { lat: 49.3653591, lng: 21.0438804 },
    { lat: 49.365354, lng: 21.043863 },
    { lat: 49.3652376, lng: 21.0434413 },
    { lat: 49.3649168, lng: 21.0428798 },
    { lat: 49.3648814, lng: 21.0425476 },
    { lat: 49.3647603, lng: 21.0423232 },
    { lat: 49.3644116, lng: 21.0419543 },
    { lat: 49.3638732, lng: 21.0414464 },
    { lat: 49.363861, lng: 21.041439 },
    { lat: 49.3636303, lng: 21.0413155 },
    { lat: 49.3634103, lng: 21.0410612 },
    { lat: 49.3633783, lng: 21.0410375 },
    { lat: 49.3629452, lng: 21.039885 },
    { lat: 49.3621993, lng: 21.0393709 },
    { lat: 49.3620196, lng: 21.0385477 },
    { lat: 49.3619162, lng: 21.0382093 },
    { lat: 49.3615943, lng: 21.037686 },
    { lat: 49.3614814, lng: 21.0370998 },
    { lat: 49.361479, lng: 21.037095 },
    { lat: 49.3614812, lng: 21.0364246 },
    { lat: 49.3610335, lng: 21.0355536 },
    { lat: 49.3605077, lng: 21.0349167 },
    { lat: 49.3601831, lng: 21.0348708 },
    { lat: 49.359649, lng: 21.034926 },
    { lat: 49.3591816, lng: 21.0347679 },
    { lat: 49.3584784, lng: 21.0337861 },
    { lat: 49.3584272, lng: 21.0337117 },
    { lat: 49.358121, lng: 21.032908 },
    { lat: 49.358051, lng: 21.032128 },
    { lat: 49.35796, lng: 21.031145 },
    { lat: 49.357779, lng: 21.0305 },
    { lat: 49.357512, lng: 21.029543 },
    { lat: 49.357383, lng: 21.028717 },
    { lat: 49.357052, lng: 21.02771 },
    { lat: 49.356902, lng: 21.027269 },
    { lat: 49.356491, lng: 21.026587 },
    { lat: 49.355874, lng: 21.025868 },
    { lat: 49.355264, lng: 21.025505 },
    { lat: 49.354334, lng: 21.025279 },
    { lat: 49.353498, lng: 21.025071 },
    { lat: 49.353084, lng: 21.024521 },
    { lat: 49.352109, lng: 21.023223 },
    { lat: 49.351979, lng: 21.021836 },
    { lat: 49.351677, lng: 21.021343 },
    { lat: 49.351149, lng: 21.020695 },
    { lat: 49.350867, lng: 21.020168 },
    { lat: 49.350636, lng: 21.019732 },
    { lat: 49.349532, lng: 21.018524 },
    { lat: 49.349171, lng: 21.018185 },
    { lat: 49.348175, lng: 21.017639 },
    { lat: 49.347635, lng: 21.017298 },
    { lat: 49.346899, lng: 21.016251 },
    { lat: 49.347063, lng: 21.015637 },
    { lat: 49.347439, lng: 21.014648 },
    { lat: 49.347887, lng: 21.013348 },
    { lat: 49.34778, lng: 21.0123 },
    { lat: 49.347564, lng: 21.011312 },
    { lat: 49.346369, lng: 21.010555 },
    { lat: 49.345435, lng: 21.009187 },
    { lat: 49.344486, lng: 21.009496 },
  ],
  Osikov: [
    { lat: 49.1771929, lng: 21.2861046 },
    { lat: 49.1789934, lng: 21.2857717 },
    { lat: 49.1789772, lng: 21.2853164 },
    { lat: 49.1790834, lng: 21.2844713 },
    { lat: 49.1790589, lng: 21.2837426 },
    { lat: 49.1792858, lng: 21.282954 },
    { lat: 49.1795593, lng: 21.2824721 },
    { lat: 49.179819, lng: 21.2816924 },
    { lat: 49.1800564, lng: 21.2788749 },
    { lat: 49.1804836, lng: 21.2766417 },
    { lat: 49.1808243, lng: 21.2742729 },
    { lat: 49.181533, lng: 21.2726045 },
    { lat: 49.18214, lng: 21.2706962 },
    { lat: 49.1818265, lng: 21.2698663 },
    { lat: 49.1820278, lng: 21.2694916 },
    { lat: 49.1823146, lng: 21.2694173 },
    { lat: 49.1825433, lng: 21.2692 },
    { lat: 49.183985, lng: 21.2655652 },
    { lat: 49.1844751, lng: 21.2602068 },
    { lat: 49.1849428, lng: 21.258538 },
    { lat: 49.1860186, lng: 21.2552664 },
    { lat: 49.1873542, lng: 21.2505262 },
    { lat: 49.1882146, lng: 21.2474683 },
    { lat: 49.185877, lng: 21.2475992 },
    { lat: 49.1854236, lng: 21.2476377 },
    { lat: 49.1853494, lng: 21.2479326 },
    { lat: 49.1836819, lng: 21.2477563 },
    { lat: 49.1828531, lng: 21.2471105 },
    { lat: 49.1823444, lng: 21.2461548 },
    { lat: 49.1821115, lng: 21.2458553 },
    { lat: 49.1818423, lng: 21.2457432 },
    { lat: 49.1817021, lng: 21.2455705 },
    { lat: 49.1812302, lng: 21.2439871 },
    { lat: 49.1795501, lng: 21.2445287 },
    { lat: 49.1794407, lng: 21.2443354 },
    { lat: 49.179348, lng: 21.2439885 },
    { lat: 49.1791381, lng: 21.2438255 },
    { lat: 49.1791574, lng: 21.2436479 },
    { lat: 49.1789048, lng: 21.2424589 },
    { lat: 49.1787941, lng: 21.2423003 },
    { lat: 49.1785859, lng: 21.2421853 },
    { lat: 49.178416, lng: 21.2417831 },
    { lat: 49.1785067, lng: 21.2412944 },
    { lat: 49.1775424, lng: 21.2413662 },
    { lat: 49.1755239, lng: 21.2418173 },
    { lat: 49.1740987, lng: 21.2423025 },
    { lat: 49.173388, lng: 21.2426112 },
    { lat: 49.1728558, lng: 21.2427233 },
    { lat: 49.1727429, lng: 21.2434402 },
    { lat: 49.1724723, lng: 21.2422152 },
    { lat: 49.1725943, lng: 21.2415366 },
    { lat: 49.1725269, lng: 21.241573 },
    { lat: 49.1725432, lng: 21.2413007 },
    { lat: 49.1726375, lng: 21.2411062 },
    { lat: 49.1723922, lng: 21.2405991 },
    { lat: 49.1723255, lng: 21.2399328 },
    { lat: 49.172425, lng: 21.2398009 },
    { lat: 49.1724165, lng: 21.2396322 },
    { lat: 49.1724851, lng: 21.2395172 },
    { lat: 49.1724235, lng: 21.2392023 },
    { lat: 49.1725844, lng: 21.2392044 },
    { lat: 49.1725997, lng: 21.2391123 },
    { lat: 49.1725056, lng: 21.2389429 },
    { lat: 49.1725159, lng: 21.2385883 },
    { lat: 49.1724272, lng: 21.2378204 },
    { lat: 49.1723606, lng: 21.2376324 },
    { lat: 49.1725601, lng: 21.2371639 },
    { lat: 49.1727531, lng: 21.236894 },
    { lat: 49.1725816, lng: 21.2366614 },
    { lat: 49.1725897, lng: 21.2364113 },
    { lat: 49.1726907, lng: 21.2361455 },
    { lat: 49.1726659, lng: 21.2358919 },
    { lat: 49.1727549, lng: 21.2357787 },
    { lat: 49.1727125, lng: 21.2355361 },
    { lat: 49.1728093, lng: 21.2355404 },
    { lat: 49.172848, lng: 21.2354096 },
    { lat: 49.1727925, lng: 21.2352844 },
    { lat: 49.1726695, lng: 21.2352037 },
    { lat: 49.1727682, lng: 21.2350085 },
    { lat: 49.1727985, lng: 21.2347528 },
    { lat: 49.1728953, lng: 21.2345327 },
    { lat: 49.1727823, lng: 21.2342941 },
    { lat: 49.1728653, lng: 21.2340357 },
    { lat: 49.17287, lng: 21.2336361 },
    { lat: 49.1727946, lng: 21.2332028 },
    { lat: 49.1729467, lng: 21.2328722 },
    { lat: 49.1727843, lng: 21.2323146 },
    { lat: 49.1729392, lng: 21.2321557 },
    { lat: 49.1729431, lng: 21.2318083 },
    { lat: 49.1730759, lng: 21.2316248 },
    { lat: 49.1729164, lng: 21.2313471 },
    { lat: 49.1730247, lng: 21.2308056 },
    { lat: 49.1730055, lng: 21.2306563 },
    { lat: 49.173076, lng: 21.2300989 },
    { lat: 49.1731601, lng: 21.2299436 },
    { lat: 49.1732389, lng: 21.229104 },
    { lat: 49.1733991, lng: 21.2287864 },
    { lat: 49.1733774, lng: 21.2285255 },
    { lat: 49.1734614, lng: 21.2281052 },
    { lat: 49.1733533, lng: 21.2278903 },
    { lat: 49.1734329, lng: 21.2276936 },
    { lat: 49.1734397, lng: 21.2275489 },
    { lat: 49.1733193, lng: 21.2271142 },
    { lat: 49.1733333, lng: 21.2266913 },
    { lat: 49.1732448, lng: 21.2265581 },
    { lat: 49.1732629, lng: 21.2264569 },
    { lat: 49.1731294, lng: 21.2258058 },
    { lat: 49.1730141, lng: 21.2257235 },
    { lat: 49.1730376, lng: 21.2253133 },
    { lat: 49.1728787, lng: 21.2251636 },
    { lat: 49.1728223, lng: 21.2249407 },
    { lat: 49.1729271, lng: 21.2246929 },
    { lat: 49.1728437, lng: 21.2245019 },
    { lat: 49.1728298, lng: 21.2240519 },
    { lat: 49.1729814, lng: 21.2237846 },
    { lat: 49.1731465, lng: 21.2231599 },
    { lat: 49.1736105, lng: 21.2224404 },
    { lat: 49.1740641, lng: 21.2215314 },
    { lat: 49.1741726, lng: 21.2207162 },
    { lat: 49.1742207, lng: 21.2196863 },
    { lat: 49.1742873, lng: 21.2192166 },
    { lat: 49.1742708, lng: 21.2187973 },
    { lat: 49.1744214, lng: 21.2179187 },
    { lat: 49.174377, lng: 21.2173148 },
    { lat: 49.1741007, lng: 21.2166061 },
    { lat: 49.1742806, lng: 21.2145655 },
    { lat: 49.1747114, lng: 21.2129586 },
    { lat: 49.1746792, lng: 21.2117741 },
    { lat: 49.1748427, lng: 21.2113124 },
    { lat: 49.1746764, lng: 21.210923 },
    { lat: 49.174651, lng: 21.2107209 },
    { lat: 49.174692, lng: 21.2104835 },
    { lat: 49.1746086, lng: 21.2098235 },
    { lat: 49.1745351, lng: 21.2095997 },
    { lat: 49.1743629, lng: 21.2078546 },
    { lat: 49.1745054, lng: 21.2047964 },
    { lat: 49.1750636, lng: 21.2017908 },
    { lat: 49.175083, lng: 21.2014384 },
    { lat: 49.1754506, lng: 21.2000491 },
    { lat: 49.175377, lng: 21.199809 },
    { lat: 49.175097, lng: 21.20015 },
    { lat: 49.174466, lng: 21.20088 },
    { lat: 49.174148, lng: 21.201566 },
    { lat: 49.173721, lng: 21.202133 },
    { lat: 49.173375, lng: 21.202406 },
    { lat: 49.173149, lng: 21.202814 },
    { lat: 49.17295, lng: 21.202981 },
    { lat: 49.172369, lng: 21.202822 },
    { lat: 49.172112, lng: 21.202771 },
    { lat: 49.171937, lng: 21.202799 },
    { lat: 49.171501, lng: 21.203006 },
    { lat: 49.171189, lng: 21.203032 },
    { lat: 49.170807, lng: 21.203208 },
    { lat: 49.170566, lng: 21.203426 },
    { lat: 49.170396, lng: 21.203477 },
    { lat: 49.17017, lng: 21.203453 },
    { lat: 49.169995, lng: 21.203384 },
    { lat: 49.169654, lng: 21.203245 },
    { lat: 49.169561, lng: 21.203239 },
    { lat: 49.169464, lng: 21.20329 },
    { lat: 49.169242, lng: 21.203521 },
    { lat: 49.168906, lng: 21.203773 },
    { lat: 49.168601, lng: 21.204079 },
    { lat: 49.168114, lng: 21.204515 },
    { lat: 49.167943, lng: 21.204659 },
    { lat: 49.167674, lng: 21.204838 },
    { lat: 49.166626, lng: 21.205996 },
    { lat: 49.166353, lng: 21.206299 },
    { lat: 49.165896, lng: 21.206716 },
    { lat: 49.165208, lng: 21.2075 },
    { lat: 49.165153, lng: 21.207645 },
    { lat: 49.165025, lng: 21.207843 },
    { lat: 49.164799, lng: 21.208022 },
    { lat: 49.164714, lng: 21.208163 },
    { lat: 49.164444, lng: 21.208395 },
    { lat: 49.164273, lng: 21.208613 },
    { lat: 49.164054, lng: 21.20906 },
    { lat: 49.164007, lng: 21.20947 },
    { lat: 49.163973, lng: 21.2096 },
    { lat: 49.163753, lng: 21.209966 },
    { lat: 49.163617, lng: 21.210327 },
    { lat: 49.163401, lng: 21.211172 },
    { lat: 49.163426, lng: 21.211663 },
    { lat: 49.163425, lng: 21.212364 },
    { lat: 49.163452, lng: 21.212568 },
    { lat: 49.163277, lng: 21.213195 },
    { lat: 49.163293, lng: 21.213938 },
    { lat: 49.163294, lng: 21.21414 },
    { lat: 49.163199, lng: 21.214604 },
    { lat: 49.163132, lng: 21.214783 },
    { lat: 49.162697, lng: 21.216321 },
    { lat: 49.162606, lng: 21.216814 },
    { lat: 49.162311, lng: 21.21768 },
    { lat: 49.162324, lng: 21.217924 },
    { lat: 49.162289, lng: 21.218037 },
    { lat: 49.162175, lng: 21.218155 },
    { lat: 49.16204, lng: 21.218389 },
    { lat: 49.161912, lng: 21.218684 },
    { lat: 49.161825, lng: 21.218943 },
    { lat: 49.161729, lng: 21.2192 },
    { lat: 49.161498, lng: 21.219575 },
    { lat: 49.161376, lng: 21.219937 },
    { lat: 49.161277, lng: 21.220106 },
    { lat: 49.160932, lng: 21.220666 },
    { lat: 49.160449, lng: 21.221121 },
    { lat: 49.1599, lng: 21.221902 },
    { lat: 49.15935, lng: 21.222502 },
    { lat: 49.159353, lng: 21.222573 },
    { lat: 49.159292, lng: 21.223694 },
    { lat: 49.159155, lng: 21.224338 },
    { lat: 49.15913, lng: 21.224408 },
    { lat: 49.158945, lng: 21.224728 },
    { lat: 49.158835, lng: 21.225046 },
    { lat: 49.158836, lng: 21.225192 },
    { lat: 49.158668, lng: 21.225565 },
    { lat: 49.158637, lng: 21.225632 },
    { lat: 49.158607, lng: 21.225698 },
    { lat: 49.158273, lng: 21.226144 },
    { lat: 49.157788, lng: 21.227418 },
    { lat: 49.157562, lng: 21.227847 },
    { lat: 49.157453, lng: 21.228058 },
    { lat: 49.157383, lng: 21.228264 },
    { lat: 49.157353, lng: 21.228529 },
    { lat: 49.15739, lng: 21.228735 },
    { lat: 49.157311, lng: 21.229383 },
    { lat: 49.157311, lng: 21.22987 },
    { lat: 49.157353, lng: 21.230081 },
    { lat: 49.157473, lng: 21.230272 },
    { lat: 49.157551, lng: 21.230552 },
    { lat: 49.15752, lng: 21.230806 },
    { lat: 49.157564, lng: 21.230983 },
    { lat: 49.157571, lng: 21.231 },
    { lat: 49.157592, lng: 21.231067 },
    { lat: 49.157368, lng: 21.231299 },
    { lat: 49.157338, lng: 21.231554 },
    { lat: 49.156969, lng: 21.2322 },
    { lat: 49.156791, lng: 21.232712 },
    { lat: 49.156666, lng: 21.232892 },
    { lat: 49.156637, lng: 21.233091 },
    { lat: 49.156262, lng: 21.234025 },
    { lat: 49.156218, lng: 21.234232 },
    { lat: 49.156055, lng: 21.234423 },
    { lat: 49.156, lng: 21.234776 },
    { lat: 49.155604, lng: 21.235559 },
    { lat: 49.155341, lng: 21.236315 },
    { lat: 49.155125, lng: 21.237121 },
    { lat: 49.155098, lng: 21.238072 },
    { lat: 49.155175, lng: 21.238239 },
    { lat: 49.155087, lng: 21.238889 },
    { lat: 49.155104, lng: 21.239023 },
    { lat: 49.154882, lng: 21.239336 },
    { lat: 49.154851, lng: 21.2397 },
    { lat: 49.154646, lng: 21.239942 },
    { lat: 49.154612, lng: 21.240074 },
    { lat: 49.154493, lng: 21.240205 },
    { lat: 49.154299, lng: 21.240626 },
    { lat: 49.154048, lng: 21.240997 },
    { lat: 49.154049, lng: 21.241164 },
    { lat: 49.1541, lng: 21.241261 },
    { lat: 49.154105, lng: 21.241522 },
    { lat: 49.154046, lng: 21.241592 },
    { lat: 49.154017, lng: 21.241578 },
    { lat: 49.153899, lng: 21.241653 },
    { lat: 49.153896, lng: 21.24172 },
    { lat: 49.153991, lng: 21.241957 },
    { lat: 49.153997, lng: 21.242004 },
    { lat: 49.153992, lng: 21.242274 },
    { lat: 49.153986, lng: 21.242449 },
    { lat: 49.15395, lng: 21.242808 },
    { lat: 49.154047, lng: 21.242866 },
    { lat: 49.154107, lng: 21.243003 },
    { lat: 49.154421, lng: 21.24341 },
    { lat: 49.154509, lng: 21.243347 },
    { lat: 49.1546, lng: 21.243594 },
    { lat: 49.154715, lng: 21.243904 },
    { lat: 49.154741, lng: 21.243901 },
    { lat: 49.154776, lng: 21.243798 },
    { lat: 49.154906, lng: 21.243852 },
    { lat: 49.15494, lng: 21.244436 },
    { lat: 49.154986, lng: 21.244563 },
    { lat: 49.155035, lng: 21.244648 },
    { lat: 49.155187, lng: 21.244844 },
    { lat: 49.155426, lng: 21.245404 },
    { lat: 49.155387, lng: 21.245609 },
    { lat: 49.155404, lng: 21.245858 },
    { lat: 49.155541, lng: 21.245909 },
    { lat: 49.155579, lng: 21.24594 },
    { lat: 49.155601, lng: 21.245966 },
    { lat: 49.155633, lng: 21.245974 },
    { lat: 49.155562, lng: 21.245994 },
    { lat: 49.15554, lng: 21.245968 },
    { lat: 49.155361, lng: 21.246152 },
    { lat: 49.155356, lng: 21.246188 },
    { lat: 49.155363, lng: 21.246223 },
    { lat: 49.15534, lng: 21.246236 },
    { lat: 49.155282, lng: 21.246284 },
    { lat: 49.155233, lng: 21.246286 },
    { lat: 49.155184, lng: 21.246313 },
    { lat: 49.15514, lng: 21.246335 },
    { lat: 49.155045, lng: 21.246342 },
    { lat: 49.15498, lng: 21.246389 },
    { lat: 49.154876, lng: 21.246427 },
    { lat: 49.154827, lng: 21.24651 },
    { lat: 49.154743, lng: 21.24653 },
    { lat: 49.154667, lng: 21.24658 },
    { lat: 49.154642, lng: 21.246583 },
    { lat: 49.154596, lng: 21.246588 },
    { lat: 49.15441, lng: 21.246723 },
    { lat: 49.154259, lng: 21.246785 },
    { lat: 49.154134, lng: 21.246931 },
    { lat: 49.154059, lng: 21.247033 },
    { lat: 49.15394, lng: 21.247163 },
    { lat: 49.153864, lng: 21.247269 },
    { lat: 49.15365, lng: 21.247389 },
    { lat: 49.153584, lng: 21.247486 },
    { lat: 49.153293, lng: 21.247648 },
    { lat: 49.153019, lng: 21.247999 },
    { lat: 49.152049, lng: 21.250335 },
    { lat: 49.152042, lng: 21.250373 },
    { lat: 49.151935, lng: 21.250623 },
    { lat: 49.151732, lng: 21.2511 },
    { lat: 49.151627, lng: 21.251144 },
    { lat: 49.151597, lng: 21.251332 },
    { lat: 49.151595, lng: 21.251549 },
    { lat: 49.151589, lng: 21.251785 },
    { lat: 49.151684, lng: 21.251832 },
    { lat: 49.151713, lng: 21.251881 },
    { lat: 49.151715, lng: 21.252084 },
    { lat: 49.151929, lng: 21.252128 },
    { lat: 49.151959, lng: 21.252396 },
    { lat: 49.152204, lng: 21.252887 },
    { lat: 49.15211, lng: 21.25304 },
    { lat: 49.152146, lng: 21.253131 },
    { lat: 49.152135, lng: 21.25341 },
    { lat: 49.152064, lng: 21.253596 },
    { lat: 49.152058, lng: 21.253874 },
    { lat: 49.152125, lng: 21.254003 },
    { lat: 49.152221, lng: 21.254112 },
    { lat: 49.152231, lng: 21.25415 },
    { lat: 49.152144, lng: 21.254307 },
    { lat: 49.152166, lng: 21.254462 },
    { lat: 49.152161, lng: 21.25465 },
    { lat: 49.15213, lng: 21.254747 },
    { lat: 49.151932, lng: 21.254809 },
    { lat: 49.15187, lng: 21.254989 },
    { lat: 49.151859, lng: 21.255271 },
    { lat: 49.151843, lng: 21.255338 },
    { lat: 49.151768, lng: 21.255561 },
    { lat: 49.151775, lng: 21.255806 },
    { lat: 49.151733, lng: 21.255864 },
    { lat: 49.151695, lng: 21.256085 },
    { lat: 49.151731, lng: 21.256341 },
    { lat: 49.151687, lng: 21.256599 },
    { lat: 49.151773, lng: 21.256916 },
    { lat: 49.151688, lng: 21.257117 },
    { lat: 49.151673, lng: 21.257528 },
    { lat: 49.151773, lng: 21.257814 },
    { lat: 49.151509, lng: 21.258908 },
    { lat: 49.151358, lng: 21.259348 },
    { lat: 49.151377, lng: 21.260028 },
    { lat: 49.15146, lng: 21.260355 },
    { lat: 49.151291, lng: 21.260957 },
    { lat: 49.151332, lng: 21.261434 },
    { lat: 49.151197, lng: 21.261754 },
    { lat: 49.151423, lng: 21.262173 },
    { lat: 49.151352, lng: 21.263189 },
    { lat: 49.151404, lng: 21.263175 },
    { lat: 49.151504, lng: 21.263256 },
    { lat: 49.151605, lng: 21.26335 },
    { lat: 49.151827, lng: 21.263122 },
    { lat: 49.152255, lng: 21.263259 },
    { lat: 49.152941, lng: 21.263389 },
    { lat: 49.153109, lng: 21.263403 },
    { lat: 49.153291, lng: 21.263403 },
    { lat: 49.153669, lng: 21.263378 },
    { lat: 49.153702, lng: 21.26345 },
    { lat: 49.153775, lng: 21.263636 },
    { lat: 49.153773, lng: 21.263926 },
    { lat: 49.153826, lng: 21.263992 },
    { lat: 49.154431, lng: 21.26404 },
    { lat: 49.15459, lng: 21.264222 },
    { lat: 49.154632, lng: 21.264375 },
    { lat: 49.154621, lng: 21.264433 },
    { lat: 49.154633, lng: 21.264481 },
    { lat: 49.154691, lng: 21.2646 },
    { lat: 49.154754, lng: 21.264679 },
    { lat: 49.155032, lng: 21.264768 },
    { lat: 49.155206, lng: 21.265035 },
    { lat: 49.155172, lng: 21.265643 },
    { lat: 49.155053, lng: 21.266071 },
    { lat: 49.155075, lng: 21.266136 },
    { lat: 49.155129, lng: 21.266165 },
    { lat: 49.155204, lng: 21.266197 },
    { lat: 49.155226, lng: 21.26627 },
    { lat: 49.155236, lng: 21.26637 },
    { lat: 49.155235, lng: 21.266477 },
    { lat: 49.155178, lng: 21.266541 },
    { lat: 49.154854, lng: 21.266675 },
    { lat: 49.154751, lng: 21.267255 },
    { lat: 49.154798, lng: 21.26794 },
    { lat: 49.154747, lng: 21.268166 },
    { lat: 49.154439, lng: 21.268453 },
    { lat: 49.154362, lng: 21.268854 },
    { lat: 49.154463, lng: 21.26922 },
    { lat: 49.154497, lng: 21.269596 },
    { lat: 49.154463, lng: 21.269636 },
    { lat: 49.154456, lng: 21.269522 },
    { lat: 49.154428, lng: 21.269374 },
    { lat: 49.154322, lng: 21.269487 },
    { lat: 49.154225, lng: 21.269493 },
    { lat: 49.154176, lng: 21.269727 },
    { lat: 49.154117, lng: 21.2701 },
    { lat: 49.154112, lng: 21.270309 },
    { lat: 49.154144, lng: 21.270451 },
    { lat: 49.154314, lng: 21.270835 },
    { lat: 49.154283, lng: 21.270952 },
    { lat: 49.153986, lng: 21.271137 },
    { lat: 49.153912, lng: 21.271631 },
    { lat: 49.153945, lng: 21.271789 },
    { lat: 49.153992, lng: 21.272332 },
    { lat: 49.153996, lng: 21.272574 },
    { lat: 49.153961, lng: 21.272655 },
    { lat: 49.153821, lng: 21.272757 },
    { lat: 49.153187, lng: 21.274226 },
    { lat: 49.152822, lng: 21.274388 },
    { lat: 49.152578, lng: 21.274803 },
    { lat: 49.152581, lng: 21.275332 },
    { lat: 49.152522, lng: 21.275469 },
    { lat: 49.152684, lng: 21.275488 },
    { lat: 49.152352, lng: 21.276367 },
    { lat: 49.152328, lng: 21.276605 },
    { lat: 49.152319, lng: 21.276703 },
    { lat: 49.152333, lng: 21.276762 },
    { lat: 49.152007, lng: 21.2771 },
    { lat: 49.1519, lng: 21.277202 },
    { lat: 49.151739, lng: 21.278005 },
    { lat: 49.151952, lng: 21.278118 },
    { lat: 49.151919, lng: 21.278313 },
    { lat: 49.151787, lng: 21.27853 },
    { lat: 49.151675, lng: 21.278886 },
    { lat: 49.151624, lng: 21.27904 },
    { lat: 49.151473, lng: 21.279132 },
    { lat: 49.151234, lng: 21.279783 },
    { lat: 49.151295, lng: 21.27987 },
    { lat: 49.151215, lng: 21.279962 },
    { lat: 49.151088, lng: 21.280221 },
    { lat: 49.15105, lng: 21.28049 },
    { lat: 49.150967, lng: 21.280662 },
    { lat: 49.150815, lng: 21.28064 },
    { lat: 49.150751, lng: 21.280503 },
    { lat: 49.150394, lng: 21.281251 },
    { lat: 49.150154, lng: 21.281883 },
    { lat: 49.150092, lng: 21.28198 },
    { lat: 49.149917, lng: 21.282128 },
    { lat: 49.149773, lng: 21.282133 },
    { lat: 49.149623, lng: 21.282141 },
    { lat: 49.1498554, lng: 21.2825661 },
    { lat: 49.1501195, lng: 21.2828443 },
    { lat: 49.1506646, lng: 21.2830445 },
    { lat: 49.1509537, lng: 21.2829687 },
    { lat: 49.1516476, lng: 21.282589 },
    { lat: 49.1518948, lng: 21.2823591 },
    { lat: 49.152196, lng: 21.281864 },
    { lat: 49.1523204, lng: 21.2813276 },
    { lat: 49.1526423, lng: 21.280555 },
    { lat: 49.153172, lng: 21.2796976 },
    { lat: 49.1538492, lng: 21.2792481 },
    { lat: 49.1540746, lng: 21.278922 },
    { lat: 49.1544907, lng: 21.2787732 },
    { lat: 49.1550386, lng: 21.2783997 },
    { lat: 49.1557703, lng: 21.2780751 },
    { lat: 49.1563147, lng: 21.2779582 },
    { lat: 49.1563419, lng: 21.2779995 },
    { lat: 49.1565811, lng: 21.277686 },
    { lat: 49.1604388, lng: 21.276416 },
    { lat: 49.1613944, lng: 21.2764979 },
    { lat: 49.1633225, lng: 21.2772528 },
    { lat: 49.1638522, lng: 21.2770625 },
    { lat: 49.1651678, lng: 21.277292 },
    { lat: 49.1655118, lng: 21.277352 },
    { lat: 49.1663223, lng: 21.2779926 },
    { lat: 49.1670697, lng: 21.2787789 },
    { lat: 49.1671382, lng: 21.279513 },
    { lat: 49.1675087, lng: 21.280486 },
    { lat: 49.1675974, lng: 21.281108 },
    { lat: 49.1681065, lng: 21.282534 },
    { lat: 49.1686699, lng: 21.2831137 },
    { lat: 49.169161, lng: 21.2834606 },
    { lat: 49.1693547, lng: 21.283836 },
    { lat: 49.1701325, lng: 21.2840374 },
    { lat: 49.1710398, lng: 21.2841204 },
    { lat: 49.1710534, lng: 21.2847181 },
    { lat: 49.172297, lng: 21.2848759 },
    { lat: 49.173342, lng: 21.2855271 },
    { lat: 49.1737717, lng: 21.285637 },
    { lat: 49.1746264, lng: 21.2857313 },
    { lat: 49.1758225, lng: 21.285809 },
    { lat: 49.1772126, lng: 21.2857885 },
    { lat: 49.1771929, lng: 21.2861046 },
  ],
  Lukavica: [
    { lat: 49.2495134, lng: 21.2981259 },
    { lat: 49.2482622, lng: 21.2995409 },
    { lat: 49.2466448, lng: 21.3003769 },
    { lat: 49.2450953, lng: 21.3025002 },
    { lat: 49.2431618, lng: 21.3039043 },
    { lat: 49.2431342, lng: 21.3042948 },
    { lat: 49.2453116, lng: 21.3063892 },
    { lat: 49.2467795, lng: 21.3081259 },
    { lat: 49.2483432, lng: 21.3101009 },
    { lat: 49.2494379, lng: 21.3116707 },
    { lat: 49.2498557, lng: 21.3125781 },
    { lat: 49.250757, lng: 21.3136256 },
    { lat: 49.2497646, lng: 21.3164018 },
    { lat: 49.2485003, lng: 21.3180233 },
    { lat: 49.2487469, lng: 21.3189269 },
    { lat: 49.2468794, lng: 21.3235086 },
    { lat: 49.24619, lng: 21.3256957 },
    { lat: 49.2458824, lng: 21.3286907 },
    { lat: 49.2460292, lng: 21.3299456 },
    { lat: 49.2450775, lng: 21.3322019 },
    { lat: 49.2442479, lng: 21.3331356 },
    { lat: 49.2442428, lng: 21.3337139 },
    { lat: 49.2445376, lng: 21.3337186 },
    { lat: 49.2453979, lng: 21.3358456 },
    { lat: 49.2453478, lng: 21.3360172 },
    { lat: 49.2457712, lng: 21.3364807 },
    { lat: 49.247004, lng: 21.3386175 },
    { lat: 49.2471825, lng: 21.3389036 },
    { lat: 49.2473664, lng: 21.3392189 },
    { lat: 49.248755, lng: 21.3413014 },
    { lat: 49.2497087, lng: 21.3412177 },
    { lat: 49.2504982, lng: 21.3408646 },
    { lat: 49.2519544, lng: 21.3397771 },
    { lat: 49.2525704, lng: 21.3390822 },
    { lat: 49.2533998, lng: 21.3396173 },
    { lat: 49.2547571, lng: 21.3388726 },
    { lat: 49.2560303, lng: 21.3384821 },
    { lat: 49.2585705, lng: 21.3382856 },
    { lat: 49.2595326, lng: 21.3390784 },
    { lat: 49.2609844, lng: 21.3407129 },
    { lat: 49.2618853, lng: 21.3419102 },
    { lat: 49.262285, lng: 21.3421476 },
    { lat: 49.2626966, lng: 21.342258 },
    { lat: 49.2635342, lng: 21.3423502 },
    { lat: 49.2638546, lng: 21.3423027 },
    { lat: 49.2640634, lng: 21.342361 },
    { lat: 49.2648309, lng: 21.3429729 },
    { lat: 49.2654073, lng: 21.3413206 },
    { lat: 49.2658803, lng: 21.3392255 },
    { lat: 49.2670158, lng: 21.3383282 },
    { lat: 49.2676566, lng: 21.3377413 },
    { lat: 49.2681041, lng: 21.337174 },
    { lat: 49.2692142, lng: 21.3353481 },
    { lat: 49.2692604, lng: 21.3348974 },
    { lat: 49.271618, lng: 21.3320151 },
    { lat: 49.2749556, lng: 21.330607 },
    { lat: 49.2760766, lng: 21.3297514 },
    { lat: 49.2773043, lng: 21.3286682 },
    { lat: 49.2790898, lng: 21.3281001 },
    { lat: 49.2791863, lng: 21.3278674 },
    { lat: 49.2806852, lng: 21.3267532 },
    { lat: 49.2818242, lng: 21.3270587 },
    { lat: 49.2803143, lng: 21.3251063 },
    { lat: 49.2765759, lng: 21.3208776 },
    { lat: 49.2752816, lng: 21.3203377 },
    { lat: 49.274839, lng: 21.3191983 },
    { lat: 49.2733675, lng: 21.3175535 },
    { lat: 49.2730787, lng: 21.3149484 },
    { lat: 49.2738467, lng: 21.3125333 },
    { lat: 49.2748998, lng: 21.3111502 },
    { lat: 49.2729387, lng: 21.3083481 },
    { lat: 49.2729043, lng: 21.3081754 },
    { lat: 49.2721508, lng: 21.3067658 },
    { lat: 49.2706546, lng: 21.3028565 },
    { lat: 49.2702135, lng: 21.3015803 },
    { lat: 49.2694078, lng: 21.2988523 },
    { lat: 49.2686577, lng: 21.2969668 },
    { lat: 49.2683846, lng: 21.2972382 },
    { lat: 49.268024, lng: 21.2977807 },
    { lat: 49.2676463, lng: 21.2977572 },
    { lat: 49.2675302, lng: 21.2979348 },
    { lat: 49.2673803, lng: 21.2994113 },
    { lat: 49.2670181, lng: 21.2997932 },
    { lat: 49.2670041, lng: 21.3000702 },
    { lat: 49.2671597, lng: 21.3002354 },
    { lat: 49.267159, lng: 21.3005078 },
    { lat: 49.2670527, lng: 21.3010485 },
    { lat: 49.2670067, lng: 21.3010543 },
    { lat: 49.2667962, lng: 21.3016131 },
    { lat: 49.2667372, lng: 21.3016328 },
    { lat: 49.2667197, lng: 21.3014989 },
    { lat: 49.2666439, lng: 21.3002137 },
    { lat: 49.2664855, lng: 21.3002623 },
    { lat: 49.2657751, lng: 21.3001377 },
    { lat: 49.2650971, lng: 21.3003022 },
    { lat: 49.2642218, lng: 21.3002291 },
    { lat: 49.2625532, lng: 21.302096 },
    { lat: 49.26138, lng: 21.3026272 },
    { lat: 49.2595828, lng: 21.3039212 },
    { lat: 49.2584407, lng: 21.3067526 },
    { lat: 49.2574111, lng: 21.3074593 },
    { lat: 49.2565457, lng: 21.3073037 },
    { lat: 49.2565784, lng: 21.306707 },
    { lat: 49.2546818, lng: 21.305941 },
    { lat: 49.2538399, lng: 21.3042911 },
    { lat: 49.2527642, lng: 21.3018941 },
    { lat: 49.2521974, lng: 21.3002304 },
    { lat: 49.2514534, lng: 21.2991018 },
    { lat: 49.2504538, lng: 21.2989399 },
    { lat: 49.2499223, lng: 21.2986953 },
    { lat: 49.2495134, lng: 21.2981259 },
  ],
  Kučín: [
    { lat: 49.2151283, lng: 21.4487099 },
    { lat: 49.2142972, lng: 21.4487976 },
    { lat: 49.2134086, lng: 21.4493092 },
    { lat: 49.2124214, lng: 21.4497546 },
    { lat: 49.212099, lng: 21.4497756 },
    { lat: 49.2101616, lng: 21.4492315 },
    { lat: 49.2101569, lng: 21.4494182 },
    { lat: 49.2095249, lng: 21.4491952 },
    { lat: 49.2092882, lng: 21.4491774 },
    { lat: 49.2092968, lng: 21.4488148 },
    { lat: 49.2092435, lng: 21.4486428 },
    { lat: 49.2090819, lng: 21.4486648 },
    { lat: 49.2089551, lng: 21.4489011 },
    { lat: 49.2086289, lng: 21.4487098 },
    { lat: 49.2086103, lng: 21.4488474 },
    { lat: 49.2079769, lng: 21.4487289 },
    { lat: 49.2069475, lng: 21.4484237 },
    { lat: 49.2046967, lng: 21.4470793 },
    { lat: 49.2040166, lng: 21.4450391 },
    { lat: 49.2030015, lng: 21.4437882 },
    { lat: 49.2025483, lng: 21.4435151 },
    { lat: 49.2020433, lng: 21.4434823 },
    { lat: 49.1979036, lng: 21.4419546 },
    { lat: 49.1975688, lng: 21.4414639 },
    { lat: 49.1972375, lng: 21.4414481 },
    { lat: 49.1999736, lng: 21.4383085 },
    { lat: 49.1987686, lng: 21.4376481 },
    { lat: 49.1983186, lng: 21.4378365 },
    { lat: 49.1972222, lng: 21.4389453 },
    { lat: 49.1966647, lng: 21.438307 },
    { lat: 49.1955905, lng: 21.4402848 },
    { lat: 49.1955148, lng: 21.4410892 },
    { lat: 49.1956381, lng: 21.4419666 },
    { lat: 49.195239, lng: 21.4419349 },
    { lat: 49.1949294, lng: 21.4420486 },
    { lat: 49.1945383, lng: 21.4420254 },
    { lat: 49.1940256, lng: 21.4426205 },
    { lat: 49.193993, lng: 21.4428547 },
    { lat: 49.1940657, lng: 21.4437261 },
    { lat: 49.193932, lng: 21.444165 },
    { lat: 49.1936801, lng: 21.4446349 },
    { lat: 49.1931581, lng: 21.4451766 },
    { lat: 49.1917116, lng: 21.4461983 },
    { lat: 49.1917023, lng: 21.446342 },
    { lat: 49.1903209, lng: 21.4497351 },
    { lat: 49.1910184, lng: 21.450237 },
    { lat: 49.1906254, lng: 21.4510743 },
    { lat: 49.1915887, lng: 21.4529914 },
    { lat: 49.1915182, lng: 21.4532447 },
    { lat: 49.1916084, lng: 21.4534034 },
    { lat: 49.1915566, lng: 21.4536339 },
    { lat: 49.1916978, lng: 21.4537726 },
    { lat: 49.1917131, lng: 21.4539118 },
    { lat: 49.1919029, lng: 21.4541314 },
    { lat: 49.1920643, lng: 21.4544627 },
    { lat: 49.1921975, lng: 21.4545915 },
    { lat: 49.19219, lng: 21.4547258 },
    { lat: 49.1922686, lng: 21.4548755 },
    { lat: 49.1923776, lng: 21.4547952 },
    { lat: 49.1924811, lng: 21.455026 },
    { lat: 49.1926736, lng: 21.4551828 },
    { lat: 49.1926654, lng: 21.4554068 },
    { lat: 49.1929458, lng: 21.4557289 },
    { lat: 49.1929465, lng: 21.4558335 },
    { lat: 49.1932035, lng: 21.4564307 },
    { lat: 49.1934971, lng: 21.4565879 },
    { lat: 49.1935313, lng: 21.4571105 },
    { lat: 49.1937422, lng: 21.4573211 },
    { lat: 49.1936814, lng: 21.4576302 },
    { lat: 49.1939055, lng: 21.458462 },
    { lat: 49.1938997, lng: 21.4589228 },
    { lat: 49.1939983, lng: 21.4596083 },
    { lat: 49.1939005, lng: 21.4597656 },
    { lat: 49.1939338, lng: 21.4601371 },
    { lat: 49.1938576, lng: 21.4602311 },
    { lat: 49.1939571, lng: 21.460365 },
    { lat: 49.1940329, lng: 21.4607258 },
    { lat: 49.1941537, lng: 21.4617922 },
    { lat: 49.1942601, lng: 21.4618948 },
    { lat: 49.1942426, lng: 21.4620301 },
    { lat: 49.1943082, lng: 21.4623534 },
    { lat: 49.1947194, lng: 21.4635082 },
    { lat: 49.194821, lng: 21.4635547 },
    { lat: 49.1948889, lng: 21.4639656 },
    { lat: 49.1948484, lng: 21.464041 },
    { lat: 49.1949306, lng: 21.4641592 },
    { lat: 49.1950093, lng: 21.4645507 },
    { lat: 49.1950996, lng: 21.4646727 },
    { lat: 49.1952603, lng: 21.4651782 },
    { lat: 49.1952619, lng: 21.4655443 },
    { lat: 49.1953242, lng: 21.4656772 },
    { lat: 49.1955162, lng: 21.4667528 },
    { lat: 49.1955983, lng: 21.4669535 },
    { lat: 49.1955899, lng: 21.4673238 },
    { lat: 49.1957873, lng: 21.467893 },
    { lat: 49.1958171, lng: 21.4682954 },
    { lat: 49.1958963, lng: 21.4683958 },
    { lat: 49.1958366, lng: 21.4688178 },
    { lat: 49.1958484, lng: 21.4690318 },
    { lat: 49.1957825, lng: 21.4692895 },
    { lat: 49.1958096, lng: 21.469838 },
    { lat: 49.1957749, lng: 21.4702296 },
    { lat: 49.1954131, lng: 21.4709557 },
    { lat: 49.1955096, lng: 21.4712618 },
    { lat: 49.1954882, lng: 21.472082 },
    { lat: 49.195601, lng: 21.4727173 },
    { lat: 49.1952445, lng: 21.4729156 },
    { lat: 49.1949695, lng: 21.4729434 },
    { lat: 49.1947933, lng: 21.4733088 },
    { lat: 49.1945786, lng: 21.4734336 },
    { lat: 49.1941297, lng: 21.4734654 },
    { lat: 49.1936791, lng: 21.4739243 },
    { lat: 49.193632, lng: 21.4740088 },
    { lat: 49.1936073, lng: 21.4743539 },
    { lat: 49.1936635, lng: 21.4749819 },
    { lat: 49.1935978, lng: 21.4751233 },
    { lat: 49.1936089, lng: 21.4756262 },
    { lat: 49.193557, lng: 21.476051 },
    { lat: 49.193573, lng: 21.476064 },
    { lat: 49.194, lng: 21.476405 },
    { lat: 49.194152, lng: 21.476489 },
    { lat: 49.194728, lng: 21.476797 },
    { lat: 49.195124, lng: 21.477264 },
    { lat: 49.195653, lng: 21.477739 },
    { lat: 49.196373, lng: 21.477657 },
    { lat: 49.196832, lng: 21.477737 },
    { lat: 49.197177, lng: 21.478233 },
    { lat: 49.197405, lng: 21.478409 },
    { lat: 49.197809, lng: 21.478698 },
    { lat: 49.19782, lng: 21.47868 },
    { lat: 49.19884, lng: 21.478826 },
    { lat: 49.199169, lng: 21.478902 },
    { lat: 49.19981, lng: 21.480128 },
    { lat: 49.200062, lng: 21.480553 },
    { lat: 49.200243, lng: 21.480827 },
    { lat: 49.200451, lng: 21.481153 },
    { lat: 49.200551, lng: 21.481322 },
    { lat: 49.200766, lng: 21.481594 },
    { lat: 49.200964, lng: 21.481958 },
    { lat: 49.201079, lng: 21.48218 },
    { lat: 49.201179, lng: 21.482371 },
    { lat: 49.201391, lng: 21.484117 },
    { lat: 49.201483, lng: 21.484354 },
    { lat: 49.201707, lng: 21.484935 },
    { lat: 49.201808, lng: 21.485303 },
    { lat: 49.202224, lng: 21.486172 },
    { lat: 49.202327, lng: 21.486404 },
    { lat: 49.202577, lng: 21.486932 },
    { lat: 49.202787, lng: 21.487193 },
    { lat: 49.203147, lng: 21.487764 },
    { lat: 49.203559, lng: 21.487826 },
    { lat: 49.204707, lng: 21.487731 },
    { lat: 49.2048909, lng: 21.4871639 },
    { lat: 49.2058497, lng: 21.4863088 },
    { lat: 49.2063532, lng: 21.4856647 },
    { lat: 49.2069182, lng: 21.4851258 },
    { lat: 49.2077248, lng: 21.4848907 },
    { lat: 49.2086269, lng: 21.4843671 },
    { lat: 49.209549, lng: 21.483415 },
    { lat: 49.21163, lng: 21.4821041 },
    { lat: 49.2124519, lng: 21.4812198 },
    { lat: 49.2128965, lng: 21.480998 },
    { lat: 49.21322, lng: 21.4811304 },
    { lat: 49.2136831, lng: 21.4797432 },
    { lat: 49.2141206, lng: 21.478991 },
    { lat: 49.2146464, lng: 21.4789285 },
    { lat: 49.2157353, lng: 21.4793149 },
    { lat: 49.2161961, lng: 21.4793752 },
    { lat: 49.2162315, lng: 21.4791231 },
    { lat: 49.2167092, lng: 21.4792236 },
    { lat: 49.2168687, lng: 21.4790557 },
    { lat: 49.2183328, lng: 21.4793887 },
    { lat: 49.2197628, lng: 21.4799363 },
    { lat: 49.2222069, lng: 21.4797597 },
    { lat: 49.2221738, lng: 21.4796647 },
    { lat: 49.2216752, lng: 21.4781695 },
    { lat: 49.2210782, lng: 21.4748053 },
    { lat: 49.2213896, lng: 21.4747621 },
    { lat: 49.2216432, lng: 21.4748593 },
    { lat: 49.2218215, lng: 21.474721 },
    { lat: 49.2223075, lng: 21.4747601 },
    { lat: 49.2225226, lng: 21.4750751 },
    { lat: 49.2226111, lng: 21.4749442 },
    { lat: 49.2229149, lng: 21.4748289 },
    { lat: 49.223083, lng: 21.4744916 },
    { lat: 49.2231011, lng: 21.4742582 },
    { lat: 49.2209923, lng: 21.467828 },
    { lat: 49.2207403, lng: 21.4672377 },
    { lat: 49.2203819, lng: 21.4665912 },
    { lat: 49.2197937, lng: 21.465231 },
    { lat: 49.2160676, lng: 21.4666369 },
    { lat: 49.2144318, lng: 21.4597676 },
    { lat: 49.2152316, lng: 21.4598765 },
    { lat: 49.2160758, lng: 21.459737 },
    { lat: 49.2158352, lng: 21.4579505 },
    { lat: 49.2154878, lng: 21.4526973 },
    { lat: 49.2151544, lng: 21.4489137 },
    { lat: 49.2151283, lng: 21.4487099 },
  ],
  Komárov: [
    { lat: 49.2818242, lng: 21.3270587 },
    { lat: 49.2806852, lng: 21.3267532 },
    { lat: 49.2791863, lng: 21.3278674 },
    { lat: 49.2790898, lng: 21.3281001 },
    { lat: 49.2773043, lng: 21.3286682 },
    { lat: 49.2760766, lng: 21.3297514 },
    { lat: 49.2749556, lng: 21.330607 },
    { lat: 49.271618, lng: 21.3320151 },
    { lat: 49.2692604, lng: 21.3348974 },
    { lat: 49.2692142, lng: 21.3353481 },
    { lat: 49.2681041, lng: 21.337174 },
    { lat: 49.2676566, lng: 21.3377413 },
    { lat: 49.2670158, lng: 21.3383282 },
    { lat: 49.2658803, lng: 21.3392255 },
    { lat: 49.2654073, lng: 21.3413206 },
    { lat: 49.2648309, lng: 21.3429729 },
    { lat: 49.2640426, lng: 21.3456695 },
    { lat: 49.2624019, lng: 21.3524974 },
    { lat: 49.2615894, lng: 21.355257 },
    { lat: 49.2601326, lng: 21.3596968 },
    { lat: 49.2610316, lng: 21.359435 },
    { lat: 49.2613968, lng: 21.3596493 },
    { lat: 49.2628076, lng: 21.3609695 },
    { lat: 49.2648198, lng: 21.36243 },
    { lat: 49.2653673, lng: 21.3630615 },
    { lat: 49.2656127, lng: 21.3636739 },
    { lat: 49.2661979, lng: 21.366188 },
    { lat: 49.2672358, lng: 21.3665907 },
    { lat: 49.2689944, lng: 21.3657969 },
    { lat: 49.2714602, lng: 21.3656267 },
    { lat: 49.2719086, lng: 21.3660634 },
    { lat: 49.2722708, lng: 21.366248 },
    { lat: 49.2729073, lng: 21.3657061 },
    { lat: 49.2733626, lng: 21.3650145 },
    { lat: 49.2740375, lng: 21.3648911 },
    { lat: 49.2744745, lng: 21.3654078 },
    { lat: 49.2751789, lng: 21.3651011 },
    { lat: 49.275785, lng: 21.3653226 },
    { lat: 49.2768867, lng: 21.3651015 },
    { lat: 49.2772554, lng: 21.3660385 },
    { lat: 49.2772474, lng: 21.36611 },
    { lat: 49.2773077, lng: 21.3661336 },
    { lat: 49.2773169, lng: 21.3660613 },
    { lat: 49.2774145, lng: 21.366323 },
    { lat: 49.2774266, lng: 21.3670752 },
    { lat: 49.2781134, lng: 21.3675452 },
    { lat: 49.2790341, lng: 21.3663371 },
    { lat: 49.2797999, lng: 21.3669357 },
    { lat: 49.2802599, lng: 21.3665857 },
    { lat: 49.2808981, lng: 21.3664446 },
    { lat: 49.2825686, lng: 21.3676185 },
    { lat: 49.2835301, lng: 21.3685539 },
    { lat: 49.2837255, lng: 21.3682734 },
    { lat: 49.2837957, lng: 21.3683676 },
    { lat: 49.284124, lng: 21.3677103 },
    { lat: 49.2841719, lng: 21.3671231 },
    { lat: 49.2844369, lng: 21.3662701 },
    { lat: 49.2846293, lng: 21.3660773 },
    { lat: 49.2847763, lng: 21.3657637 },
    { lat: 49.2847733, lng: 21.3653596 },
    { lat: 49.2848826, lng: 21.3646806 },
    { lat: 49.285032, lng: 21.3639116 },
    { lat: 49.285092, lng: 21.3639983 },
    { lat: 49.2849985, lng: 21.363728 },
    { lat: 49.2850071, lng: 21.3634877 },
    { lat: 49.2850731, lng: 21.3633271 },
    { lat: 49.2851992, lng: 21.3633663 },
    { lat: 49.2851385, lng: 21.3632115 },
    { lat: 49.2858754, lng: 21.3625698 },
    { lat: 49.2860262, lng: 21.3623064 },
    { lat: 49.2861784, lng: 21.3622 },
    { lat: 49.286207, lng: 21.3620243 },
    { lat: 49.2863743, lng: 21.3621255 },
    { lat: 49.2866108, lng: 21.3617199 },
    { lat: 49.2868, lng: 21.3617961 },
    { lat: 49.287051, lng: 21.3614281 },
    { lat: 49.2871933, lng: 21.3614395 },
    { lat: 49.2870678, lng: 21.3612701 },
    { lat: 49.2875432, lng: 21.3609074 },
    { lat: 49.2896728, lng: 21.3582841 },
    { lat: 49.2900706, lng: 21.3586505 },
    { lat: 49.2901106, lng: 21.358554 },
    { lat: 49.2900524, lng: 21.3584895 },
    { lat: 49.2901385, lng: 21.3583174 },
    { lat: 49.2903387, lng: 21.3583979 },
    { lat: 49.2905221, lng: 21.3583171 },
    { lat: 49.2904498, lng: 21.3581224 },
    { lat: 49.290335, lng: 21.3580442 },
    { lat: 49.2904321, lng: 21.357787 },
    { lat: 49.2905887, lng: 21.357757 },
    { lat: 49.2909096, lng: 21.3579179 },
    { lat: 49.2909207, lng: 21.3577207 },
    { lat: 49.2908295, lng: 21.3575713 },
    { lat: 49.292026, lng: 21.355916 },
    { lat: 49.2922664, lng: 21.3548818 },
    { lat: 49.2929215, lng: 21.3527677 },
    { lat: 49.2931564, lng: 21.3518574 },
    { lat: 49.2933594, lng: 21.3506599 },
    { lat: 49.2936298, lng: 21.3502951 },
    { lat: 49.2939595, lng: 21.3493854 },
    { lat: 49.294155, lng: 21.3491105 },
    { lat: 49.2947209, lng: 21.34771 },
    { lat: 49.2951512, lng: 21.3475445 },
    { lat: 49.2954728, lng: 21.3470777 },
    { lat: 49.2974786, lng: 21.3450326 },
    { lat: 49.2976393, lng: 21.3448693 },
    { lat: 49.2977299, lng: 21.3432321 },
    { lat: 49.296777, lng: 21.3417639 },
    { lat: 49.2951996, lng: 21.3404769 },
    { lat: 49.2943397, lng: 21.3394082 },
    { lat: 49.2940783, lng: 21.3393608 },
    { lat: 49.2938944, lng: 21.3388473 },
    { lat: 49.292801, lng: 21.3372388 },
    { lat: 49.2914448, lng: 21.3348802 },
    { lat: 49.2904369, lng: 21.3335704 },
    { lat: 49.2899675, lng: 21.3321733 },
    { lat: 49.289109, lng: 21.3310755 },
    { lat: 49.2877648, lng: 21.3292029 },
    { lat: 49.2872865, lng: 21.3287159 },
    { lat: 49.2865406, lng: 21.3294266 },
    { lat: 49.2859943, lng: 21.3297124 },
    { lat: 49.2854532, lng: 21.3296402 },
    { lat: 49.2835583, lng: 21.3289506 },
    { lat: 49.2824895, lng: 21.3274829 },
    { lat: 49.2820924, lng: 21.3272299 },
    { lat: 49.2818242, lng: 21.3270587 },
  ],
  Kožany: [
    { lat: 49.214294, lng: 21.521042 },
    { lat: 49.214553, lng: 21.520773 },
    { lat: 49.214801, lng: 21.520459 },
    { lat: 49.214992, lng: 21.520255 },
    { lat: 49.215227, lng: 21.519941 },
    { lat: 49.215484, lng: 21.519656 },
    { lat: 49.215653, lng: 21.519493 },
    { lat: 49.215823, lng: 21.519382 },
    { lat: 49.216021, lng: 21.519147 },
    { lat: 49.216242, lng: 21.518909 },
    { lat: 49.216469, lng: 21.518604 },
    { lat: 49.217019, lng: 21.517971 },
    { lat: 49.217238, lng: 21.517746 },
    { lat: 49.217378, lng: 21.51759 },
    { lat: 49.217639, lng: 21.517523 },
    { lat: 49.217903, lng: 21.5174 },
    { lat: 49.218112, lng: 21.517258 },
    { lat: 49.218164, lng: 21.517237 },
    { lat: 49.218312, lng: 21.517152 },
    { lat: 49.218433, lng: 21.517055 },
    { lat: 49.218554, lng: 21.517025 },
    { lat: 49.218748, lng: 21.516854 },
    { lat: 49.218887, lng: 21.516722 },
    { lat: 49.218951, lng: 21.516626 },
    { lat: 49.219012, lng: 21.516452 },
    { lat: 49.219114, lng: 21.516207 },
    { lat: 49.219271, lng: 21.515871 },
    { lat: 49.219343, lng: 21.515437 },
    { lat: 49.219368, lng: 21.515352 },
    { lat: 49.219455, lng: 21.515166 },
    { lat: 49.219696, lng: 21.51469 },
    { lat: 49.219762, lng: 21.514509 },
    { lat: 49.219796, lng: 21.514335 },
    { lat: 49.219845, lng: 21.513727 },
    { lat: 49.220028, lng: 21.512411 },
    { lat: 49.22029, lng: 21.511689 },
    { lat: 49.220676, lng: 21.511223 },
    { lat: 49.220951, lng: 21.510779 },
    { lat: 49.2212, lng: 21.510537 },
    { lat: 49.2206484, lng: 21.5093924 },
    { lat: 49.2209914, lng: 21.5084789 },
    { lat: 49.2215126, lng: 21.5073826 },
    { lat: 49.2217709, lng: 21.5066155 },
    { lat: 49.2220564, lng: 21.5054574 },
    { lat: 49.2221976, lng: 21.5039746 },
    { lat: 49.2224499, lng: 21.5039481 },
    { lat: 49.2228284, lng: 21.5037621 },
    { lat: 49.2230413, lng: 21.5021575 },
    { lat: 49.2229299, lng: 21.5015224 },
    { lat: 49.2232155, lng: 21.4994994 },
    { lat: 49.2233774, lng: 21.4987454 },
    { lat: 49.2233724, lng: 21.4984542 },
    { lat: 49.2231736, lng: 21.4976403 },
    { lat: 49.2231268, lng: 21.4969016 },
    { lat: 49.2228906, lng: 21.4959175 },
    { lat: 49.2227063, lng: 21.4953344 },
    { lat: 49.2223798, lng: 21.4952852 },
    { lat: 49.2223015, lng: 21.4926227 },
    { lat: 49.2223204, lng: 21.4920632 },
    { lat: 49.2224175, lng: 21.49188 },
    { lat: 49.2228396, lng: 21.4914895 },
    { lat: 49.2238475, lng: 21.4909376 },
    { lat: 49.2233602, lng: 21.4875885 },
    { lat: 49.2233722, lng: 21.487375 },
    { lat: 49.2234574, lng: 21.4869734 },
    { lat: 49.2237239, lng: 21.4864596 },
    { lat: 49.2245058, lng: 21.4845696 },
    { lat: 49.2244676, lng: 21.4844117 },
    { lat: 49.2242975, lng: 21.4841268 },
    { lat: 49.2242932, lng: 21.4837448 },
    { lat: 49.2244065, lng: 21.48358 },
    { lat: 49.2244489, lng: 21.4834036 },
    { lat: 49.2244456, lng: 21.4830587 },
    { lat: 49.2241547, lng: 21.4813796 },
    { lat: 49.2238989, lng: 21.4795621 },
    { lat: 49.2236898, lng: 21.4796106 },
    { lat: 49.2234594, lng: 21.4795018 },
    { lat: 49.2221738, lng: 21.4796647 },
    { lat: 49.2222069, lng: 21.4797597 },
    { lat: 49.2197628, lng: 21.4799363 },
    { lat: 49.2183328, lng: 21.4793887 },
    { lat: 49.2168687, lng: 21.4790557 },
    { lat: 49.2167092, lng: 21.4792236 },
    { lat: 49.2162315, lng: 21.4791231 },
    { lat: 49.2161961, lng: 21.4793752 },
    { lat: 49.2157353, lng: 21.4793149 },
    { lat: 49.2146464, lng: 21.4789285 },
    { lat: 49.2141206, lng: 21.478991 },
    { lat: 49.2136831, lng: 21.4797432 },
    { lat: 49.21322, lng: 21.4811304 },
    { lat: 49.2128965, lng: 21.480998 },
    { lat: 49.2124519, lng: 21.4812198 },
    { lat: 49.21163, lng: 21.4821041 },
    { lat: 49.209549, lng: 21.483415 },
    { lat: 49.2086269, lng: 21.4843671 },
    { lat: 49.2077248, lng: 21.4848907 },
    { lat: 49.2069182, lng: 21.4851258 },
    { lat: 49.2063532, lng: 21.4856647 },
    { lat: 49.2058497, lng: 21.4863088 },
    { lat: 49.2048909, lng: 21.4871639 },
    { lat: 49.204707, lng: 21.487731 },
    { lat: 49.204277, lng: 21.488407 },
    { lat: 49.203509, lng: 21.489015 },
    { lat: 49.203189, lng: 21.489482 },
    { lat: 49.203089, lng: 21.489929 },
    { lat: 49.202938, lng: 21.49058 },
    { lat: 49.202872, lng: 21.490898 },
    { lat: 49.2029, lng: 21.491676 },
    { lat: 49.20294, lng: 21.4922 },
    { lat: 49.20269, lng: 21.493409 },
    { lat: 49.202676, lng: 21.493517 },
    { lat: 49.202585, lng: 21.494227 },
    { lat: 49.202406, lng: 21.495145 },
    { lat: 49.20213, lng: 21.49558 },
    { lat: 49.201998, lng: 21.496423 },
    { lat: 49.202103, lng: 21.496978 },
    { lat: 49.202407, lng: 21.498068 },
    { lat: 49.202289, lng: 21.498387 },
    { lat: 49.202263, lng: 21.499407 },
    { lat: 49.201744, lng: 21.501134 },
    { lat: 49.201865, lng: 21.501359 },
    { lat: 49.203209, lng: 21.500471 },
    { lat: 49.203246, lng: 21.500733 },
    { lat: 49.203336, lng: 21.500861 },
    { lat: 49.203355, lng: 21.501013 },
    { lat: 49.20352, lng: 21.501152 },
    { lat: 49.203557, lng: 21.501391 },
    { lat: 49.203442, lng: 21.501772 },
    { lat: 49.203345, lng: 21.501619 },
    { lat: 49.20335, lng: 21.501752 },
    { lat: 49.20338, lng: 21.502158 },
    { lat: 49.20336, lng: 21.5023 },
    { lat: 49.203351, lng: 21.502431 },
    { lat: 49.203319, lng: 21.502565 },
    { lat: 49.203387, lng: 21.502663 },
    { lat: 49.203405, lng: 21.502989 },
    { lat: 49.203413, lng: 21.503119 },
    { lat: 49.20342, lng: 21.503296 },
    { lat: 49.203443, lng: 21.503452 },
    { lat: 49.203519, lng: 21.503585 },
    { lat: 49.203606, lng: 21.503656 },
    { lat: 49.20367, lng: 21.503651 },
    { lat: 49.203918, lng: 21.503759 },
    { lat: 49.203792, lng: 21.503884 },
    { lat: 49.203712, lng: 21.50395 },
    { lat: 49.203753, lng: 21.504036 },
    { lat: 49.203678, lng: 21.504107 },
    { lat: 49.203855, lng: 21.504202 },
    { lat: 49.203854, lng: 21.504277 },
    { lat: 49.203869, lng: 21.504379 },
    { lat: 49.203964, lng: 21.504308 },
    { lat: 49.20403, lng: 21.504457 },
    { lat: 49.204091, lng: 21.504627 },
    { lat: 49.204135, lng: 21.504873 },
    { lat: 49.204183, lng: 21.505017 },
    { lat: 49.204263, lng: 21.505102 },
    { lat: 49.20429, lng: 21.505157 },
    { lat: 49.204316, lng: 21.505225 },
    { lat: 49.204503, lng: 21.505047 },
    { lat: 49.204532, lng: 21.505108 },
    { lat: 49.204625, lng: 21.505196 },
    { lat: 49.204728, lng: 21.505295 },
    { lat: 49.20483, lng: 21.505414 },
    { lat: 49.204814, lng: 21.505554 },
    { lat: 49.204786, lng: 21.505931 },
    { lat: 49.204858, lng: 21.50598 },
    { lat: 49.20495, lng: 21.505968 },
    { lat: 49.205159, lng: 21.505748 },
    { lat: 49.205207, lng: 21.505885 },
    { lat: 49.205228, lng: 21.505956 },
    { lat: 49.205152, lng: 21.506021 },
    { lat: 49.205065, lng: 21.506158 },
    { lat: 49.204951, lng: 21.506389 },
    { lat: 49.204954, lng: 21.50649 },
    { lat: 49.204711, lng: 21.506737 },
    { lat: 49.204731, lng: 21.506829 },
    { lat: 49.204774, lng: 21.506916 },
    { lat: 49.204797, lng: 21.50699 },
    { lat: 49.205011, lng: 21.507623 },
    { lat: 49.205035, lng: 21.507735 },
    { lat: 49.205104, lng: 21.507912 },
    { lat: 49.205188, lng: 21.508063 },
    { lat: 49.205255, lng: 21.508195 },
    { lat: 49.205317, lng: 21.508321 },
    { lat: 49.205366, lng: 21.508418 },
    { lat: 49.20539, lng: 21.508536 },
    { lat: 49.205433, lng: 21.508753 },
    { lat: 49.205466, lng: 21.508917 },
    { lat: 49.205492, lng: 21.50907 },
    { lat: 49.205551, lng: 21.509319 },
    { lat: 49.205592, lng: 21.509521 },
    { lat: 49.205617, lng: 21.509648 },
    { lat: 49.205641, lng: 21.509771 },
    { lat: 49.205668, lng: 21.50989 },
    { lat: 49.205704, lng: 21.510031 },
    { lat: 49.205786, lng: 21.510374 },
    { lat: 49.205818, lng: 21.510538 },
    { lat: 49.205849, lng: 21.510705 },
    { lat: 49.206207, lng: 21.510649 },
    { lat: 49.206344, lng: 21.511254 },
    { lat: 49.206369, lng: 21.5113 },
    { lat: 49.206852, lng: 21.512133 },
    { lat: 49.207495, lng: 21.512981 },
    { lat: 49.20755, lng: 21.513151 },
    { lat: 49.20766, lng: 21.513308 },
    { lat: 49.208131, lng: 21.514338 },
    { lat: 49.208273, lng: 21.514475 },
    { lat: 49.208566, lng: 21.514997 },
    { lat: 49.210387, lng: 21.516088 },
    { lat: 49.210486, lng: 21.516142 },
    { lat: 49.210743, lng: 21.516221 },
    { lat: 49.210945, lng: 21.516293 },
    { lat: 49.211279, lng: 21.516297 },
    { lat: 49.211588, lng: 21.516276 },
    { lat: 49.212019, lng: 21.516209 },
    { lat: 49.21222, lng: 21.516186 },
    { lat: 49.212281, lng: 21.516208 },
    { lat: 49.21247, lng: 21.516407 },
    { lat: 49.212624, lng: 21.516623 },
    { lat: 49.212828, lng: 21.516788 },
    { lat: 49.213031, lng: 21.516883 },
    { lat: 49.213509, lng: 21.517139 },
    { lat: 49.213553, lng: 21.517237 },
    { lat: 49.213563, lng: 21.517294 },
    { lat: 49.213576, lng: 21.517702 },
    { lat: 49.213502, lng: 21.518114 },
    { lat: 49.213413, lng: 21.518467 },
    { lat: 49.213638, lng: 21.518599 },
    { lat: 49.213671, lng: 21.51863 },
    { lat: 49.213716, lng: 21.518785 },
    { lat: 49.213768, lng: 21.518959 },
    { lat: 49.213815, lng: 21.519191 },
    { lat: 49.213834, lng: 21.519499 },
    { lat: 49.213808, lng: 21.519827 },
    { lat: 49.213979, lng: 21.520335 },
    { lat: 49.214018, lng: 21.52044 },
    { lat: 49.214117, lng: 21.520685 },
    { lat: 49.214209, lng: 21.520871 },
    { lat: 49.214266, lng: 21.520975 },
    { lat: 49.214294, lng: 21.521042 },
  ],
  Hankovce: [
    { lat: 49.2406771, lng: 21.3931357 },
    { lat: 49.2399843, lng: 21.3916158 },
    { lat: 49.2384315, lng: 21.3880144 },
    { lat: 49.236411, lng: 21.383892 },
    { lat: 49.234026, lng: 21.3856612 },
    { lat: 49.2315575, lng: 21.3869155 },
    { lat: 49.2299602, lng: 21.387693 },
    { lat: 49.2280049, lng: 21.3881213 },
    { lat: 49.2285014, lng: 21.385189 },
    { lat: 49.2290484, lng: 21.3830573 },
    { lat: 49.2284254, lng: 21.3828352 },
    { lat: 49.2266243, lng: 21.3818888 },
    { lat: 49.225042, lng: 21.3819911 },
    { lat: 49.2244688, lng: 21.3811342 },
    { lat: 49.2242411, lng: 21.3810466 },
    { lat: 49.2236362, lng: 21.3816746 },
    { lat: 49.2221108, lng: 21.3821365 },
    { lat: 49.2213308, lng: 21.3826602 },
    { lat: 49.2195973, lng: 21.3829759 },
    { lat: 49.2194161, lng: 21.3828588 },
    { lat: 49.2183768, lng: 21.383248 },
    { lat: 49.2177881, lng: 21.3833126 },
    { lat: 49.2165741, lng: 21.3838167 },
    { lat: 49.2132213, lng: 21.3862147 },
    { lat: 49.2130185, lng: 21.3859828 },
    { lat: 49.2120935, lng: 21.3867689 },
    { lat: 49.2118054, lng: 21.3871151 },
    { lat: 49.2103879, lng: 21.3884553 },
    { lat: 49.2087961, lng: 21.3904117 },
    { lat: 49.2076251, lng: 21.3911443 },
    { lat: 49.206124, lng: 21.3919446 },
    { lat: 49.2041974, lng: 21.3927336 },
    { lat: 49.2025989, lng: 21.3938477 },
    { lat: 49.2011201, lng: 21.3946366 },
    { lat: 49.1997842, lng: 21.3951235 },
    { lat: 49.1985246, lng: 21.3957922 },
    { lat: 49.1971435, lng: 21.3963069 },
    { lat: 49.1955424, lng: 21.3994039 },
    { lat: 49.1937611, lng: 21.4016952 },
    { lat: 49.1923295, lng: 21.4027161 },
    { lat: 49.1921105, lng: 21.4026929 },
    { lat: 49.1924163, lng: 21.4037948 },
    { lat: 49.1922665, lng: 21.4043295 },
    { lat: 49.1922493, lng: 21.4050846 },
    { lat: 49.192413, lng: 21.405603 },
    { lat: 49.1924178, lng: 21.4060311 },
    { lat: 49.1919665, lng: 21.4070993 },
    { lat: 49.1917671, lng: 21.4072881 },
    { lat: 49.19162, lng: 21.4075593 },
    { lat: 49.1916587, lng: 21.4078855 },
    { lat: 49.1915527, lng: 21.4084168 },
    { lat: 49.1913049, lng: 21.4086765 },
    { lat: 49.1909841, lng: 21.4087088 },
    { lat: 49.1910469, lng: 21.4088991 },
    { lat: 49.1914581, lng: 21.4095682 },
    { lat: 49.1915696, lng: 21.4100351 },
    { lat: 49.191544, lng: 21.4103818 },
    { lat: 49.1916402, lng: 21.4110199 },
    { lat: 49.1923926, lng: 21.4116679 },
    { lat: 49.1926903, lng: 21.4114559 },
    { lat: 49.1927771, lng: 21.4114957 },
    { lat: 49.1931964, lng: 21.4114124 },
    { lat: 49.1935953, lng: 21.4118155 },
    { lat: 49.1949823, lng: 21.4116548 },
    { lat: 49.1961932, lng: 21.4120479 },
    { lat: 49.1978105, lng: 21.4111045 },
    { lat: 49.2000093, lng: 21.4172753 },
    { lat: 49.2005828, lng: 21.4179605 },
    { lat: 49.2014683, lng: 21.4174571 },
    { lat: 49.2030248, lng: 21.4174728 },
    { lat: 49.2064435, lng: 21.4191606 },
    { lat: 49.2067382, lng: 21.4189661 },
    { lat: 49.2080134, lng: 21.4186735 },
    { lat: 49.2096354, lng: 21.4179299 },
    { lat: 49.2103306, lng: 21.4177653 },
    { lat: 49.2104022, lng: 21.4177561 },
    { lat: 49.2114626, lng: 21.4176174 },
    { lat: 49.212289, lng: 21.4173693 },
    { lat: 49.2135255, lng: 21.4165273 },
    { lat: 49.2149517, lng: 21.4160437 },
    { lat: 49.2160328, lng: 21.415042 },
    { lat: 49.2180811, lng: 21.414219 },
    { lat: 49.2184021, lng: 21.4139557 },
    { lat: 49.2192319, lng: 21.4134806 },
    { lat: 49.2203336, lng: 21.4122998 },
    { lat: 49.2217187, lng: 21.4113013 },
    { lat: 49.2231382, lng: 21.4106325 },
    { lat: 49.2253528, lng: 21.4098466 },
    { lat: 49.2255213, lng: 21.4103368 },
    { lat: 49.2258482, lng: 21.4105489 },
    { lat: 49.2260965, lng: 21.4102703 },
    { lat: 49.2270861, lng: 21.4104036 },
    { lat: 49.2277715, lng: 21.4103007 },
    { lat: 49.22843, lng: 21.4105021 },
    { lat: 49.2288718, lng: 21.4104853 },
    { lat: 49.2293344, lng: 21.4101492 },
    { lat: 49.22971, lng: 21.4096815 },
    { lat: 49.2300914, lng: 21.4094128 },
    { lat: 49.2304163, lng: 21.4089072 },
    { lat: 49.2308938, lng: 21.4076837 },
    { lat: 49.2309718, lng: 21.4071618 },
    { lat: 49.2311989, lng: 21.4066952 },
    { lat: 49.2313707, lng: 21.4064937 },
    { lat: 49.2315833, lng: 21.4059187 },
    { lat: 49.2319647, lng: 21.4053033 },
    { lat: 49.2325033, lng: 21.4041033 },
    { lat: 49.2326672, lng: 21.4034757 },
    { lat: 49.2329277, lng: 21.4032999 },
    { lat: 49.2331721, lng: 21.4024692 },
    { lat: 49.2338052, lng: 21.4014182 },
    { lat: 49.2339845, lng: 21.4008979 },
    { lat: 49.2348483, lng: 21.40031 },
    { lat: 49.2356305, lng: 21.3995944 },
    { lat: 49.2363256, lng: 21.3993563 },
    { lat: 49.2374097, lng: 21.3992609 },
    { lat: 49.2378789, lng: 21.398001 },
    { lat: 49.2388878, lng: 21.3968791 },
    { lat: 49.239413, lng: 21.3961799 },
    { lat: 49.2399381, lng: 21.394712 },
    { lat: 49.2404216, lng: 21.3939399 },
    { lat: 49.2406771, lng: 21.3931357 },
  ],
  Kobyly: [
    { lat: 49.2240991, lng: 21.2707878 },
    { lat: 49.2235259, lng: 21.2698807 },
    { lat: 49.2232331, lng: 21.2700224 },
    { lat: 49.222871, lng: 21.2703966 },
    { lat: 49.2225783, lng: 21.2703936 },
    { lat: 49.2217243, lng: 21.2707249 },
    { lat: 49.2211705, lng: 21.2707592 },
    { lat: 49.220782, lng: 21.2708933 },
    { lat: 49.2206775, lng: 21.2708597 },
    { lat: 49.2204276, lng: 21.2704876 },
    { lat: 49.2202225, lng: 21.2704064 },
    { lat: 49.2197228, lng: 21.2703645 },
    { lat: 49.2194344, lng: 21.2704231 },
    { lat: 49.219102, lng: 21.2710363 },
    { lat: 49.217446, lng: 21.2721976 },
    { lat: 49.2149987, lng: 21.2742615 },
    { lat: 49.213993, lng: 21.2756764 },
    { lat: 49.213353, lng: 21.2769062 },
    { lat: 49.2123975, lng: 21.2770437 },
    { lat: 49.2101723, lng: 21.277093 },
    { lat: 49.2099897, lng: 21.2773064 },
    { lat: 49.2097039, lng: 21.2798645 },
    { lat: 49.2097405, lng: 21.2801397 },
    { lat: 49.2096217, lng: 21.2808938 },
    { lat: 49.2092927, lng: 21.281472 },
    { lat: 49.2093349, lng: 21.2818613 },
    { lat: 49.2092679, lng: 21.2824703 },
    { lat: 49.2091015, lng: 21.2830344 },
    { lat: 49.2087093, lng: 21.2838284 },
    { lat: 49.2088151, lng: 21.2848303 },
    { lat: 49.2089512, lng: 21.2853148 },
    { lat: 49.2092526, lng: 21.2860089 },
    { lat: 49.2089899, lng: 21.2864719 },
    { lat: 49.208961, lng: 21.2866694 },
    { lat: 49.2084485, lng: 21.2869539 },
    { lat: 49.2071868, lng: 21.2879409 },
    { lat: 49.2063174, lng: 21.288731 },
    { lat: 49.2043304, lng: 21.2901821 },
    { lat: 49.2040057, lng: 21.2920193 },
    { lat: 49.2038723, lng: 21.292852 },
    { lat: 49.203869, lng: 21.2939677 },
    { lat: 49.2046806, lng: 21.2958767 },
    { lat: 49.2048136, lng: 21.2969997 },
    { lat: 49.204259, lng: 21.2975588 },
    { lat: 49.2069814, lng: 21.3046192 },
    { lat: 49.2071698, lng: 21.3048218 },
    { lat: 49.2067365, lng: 21.3053388 },
    { lat: 49.2032276, lng: 21.3086759 },
    { lat: 49.2018396, lng: 21.3095149 },
    { lat: 49.2016257, lng: 21.3098124 },
    { lat: 49.2015329, lng: 21.3101287 },
    { lat: 49.2011675, lng: 21.3107016 },
    { lat: 49.200928, lng: 21.3106716 },
    { lat: 49.2002216, lng: 21.3113141 },
    { lat: 49.1999331, lng: 21.3111041 },
    { lat: 49.1997468, lng: 21.3109401 },
    { lat: 49.1995647, lng: 21.3115386 },
    { lat: 49.2004861, lng: 21.3127283 },
    { lat: 49.200822, lng: 21.3127875 },
    { lat: 49.2009754, lng: 21.3129102 },
    { lat: 49.201414, lng: 21.3135077 },
    { lat: 49.2013476, lng: 21.3145907 },
    { lat: 49.2009493, lng: 21.3164608 },
    { lat: 49.2007632, lng: 21.3181675 },
    { lat: 49.2006789, lng: 21.31858 },
    { lat: 49.2007347, lng: 21.3191658 },
    { lat: 49.2008547, lng: 21.3197834 },
    { lat: 49.2009299, lng: 21.3199656 },
    { lat: 49.2010456, lng: 21.3208272 },
    { lat: 49.2011868, lng: 21.3212498 },
    { lat: 49.2012252, lng: 21.3216307 },
    { lat: 49.2012953, lng: 21.3226152 },
    { lat: 49.2011217, lng: 21.3229436 },
    { lat: 49.2009815, lng: 21.3234813 },
    { lat: 49.2009934, lng: 21.3240252 },
    { lat: 49.2011816, lng: 21.3248608 },
    { lat: 49.2013959, lng: 21.3254089 },
    { lat: 49.2021608, lng: 21.3266753 },
    { lat: 49.2020785, lng: 21.3271567 },
    { lat: 49.2021627, lng: 21.3277528 },
    { lat: 49.2025379, lng: 21.3287071 },
    { lat: 49.2028443, lng: 21.3292071 },
    { lat: 49.203011, lng: 21.3295862 },
    { lat: 49.2034172, lng: 21.3298921 },
    { lat: 49.2036266, lng: 21.3301222 },
    { lat: 49.2039523, lng: 21.3310753 },
    { lat: 49.204196, lng: 21.3315837 },
    { lat: 49.2045987, lng: 21.3321129 },
    { lat: 49.2049403, lng: 21.3326951 },
    { lat: 49.2051022, lng: 21.3343851 },
    { lat: 49.2050968, lng: 21.336158 },
    { lat: 49.2052439, lng: 21.336491 },
    { lat: 49.2054723, lng: 21.3367173 },
    { lat: 49.206483, lng: 21.3373209 },
    { lat: 49.2073743, lng: 21.3366539 },
    { lat: 49.2087932, lng: 21.3355651 },
    { lat: 49.2091063, lng: 21.3352029 },
    { lat: 49.2095321, lng: 21.3345802 },
    { lat: 49.2102803, lng: 21.3343456 },
    { lat: 49.2107459, lng: 21.3345337 },
    { lat: 49.2111574, lng: 21.3345986 },
    { lat: 49.2115339, lng: 21.3343935 },
    { lat: 49.212672, lng: 21.3341875 },
    { lat: 49.2130383, lng: 21.333791 },
    { lat: 49.2136192, lng: 21.3339959 },
    { lat: 49.2146446, lng: 21.3341926 },
    { lat: 49.2152151, lng: 21.3338188 },
    { lat: 49.2158563, lng: 21.333556 },
    { lat: 49.2169047, lng: 21.332979 },
    { lat: 49.2177683, lng: 21.3319449 },
    { lat: 49.2185529, lng: 21.3318095 },
    { lat: 49.2192091, lng: 21.3314783 },
    { lat: 49.2193838, lng: 21.3316065 },
    { lat: 49.2198666, lng: 21.3311556 },
    { lat: 49.2200255, lng: 21.3307137 },
    { lat: 49.2202013, lng: 21.3304977 },
    { lat: 49.2198021, lng: 21.3293385 },
    { lat: 49.219463, lng: 21.3251984 },
    { lat: 49.2204695, lng: 21.3250662 },
    { lat: 49.2228835, lng: 21.3236243 },
    { lat: 49.2225295, lng: 21.322364 },
    { lat: 49.2230514, lng: 21.3214168 },
    { lat: 49.2247067, lng: 21.3195551 },
    { lat: 49.2253328, lng: 21.3190804 },
    { lat: 49.2262273, lng: 21.3185613 },
    { lat: 49.2266993, lng: 21.3187622 },
    { lat: 49.2275617, lng: 21.3184491 },
    { lat: 49.227722, lng: 21.3184609 },
    { lat: 49.2283714, lng: 21.318018 },
    { lat: 49.2295954, lng: 21.3166319 },
    { lat: 49.2310541, lng: 21.3151472 },
    { lat: 49.2314727, lng: 21.3141026 },
    { lat: 49.2320592, lng: 21.3143722 },
    { lat: 49.2330607, lng: 21.3127304 },
    { lat: 49.2332465, lng: 21.3111659 },
    { lat: 49.2339115, lng: 21.3105353 },
    { lat: 49.2355156, lng: 21.3107823 },
    { lat: 49.2370443, lng: 21.3065526 },
    { lat: 49.23725, lng: 21.306392 },
    { lat: 49.2391854, lng: 21.3035574 },
    { lat: 49.2402767, lng: 21.3022178 },
    { lat: 49.2399666, lng: 21.3020445 },
    { lat: 49.2400486, lng: 21.3012155 },
    { lat: 49.2398856, lng: 21.3008539 },
    { lat: 49.2397144, lng: 21.3006939 },
    { lat: 49.2394886, lng: 21.3003302 },
    { lat: 49.239299, lng: 21.2997898 },
    { lat: 49.2389714, lng: 21.2997887 },
    { lat: 49.2386324, lng: 21.2995929 },
    { lat: 49.2378629, lng: 21.2989947 },
    { lat: 49.237579, lng: 21.2989092 },
    { lat: 49.2374579, lng: 21.2989973 },
    { lat: 49.2369582, lng: 21.2988845 },
    { lat: 49.236594, lng: 21.2989728 },
    { lat: 49.2365279, lng: 21.2983473 },
    { lat: 49.2363595, lng: 21.2980636 },
    { lat: 49.2360307, lng: 21.2969367 },
    { lat: 49.2351544, lng: 21.2963853 },
    { lat: 49.2350348, lng: 21.2961492 },
    { lat: 49.234963, lng: 21.2956365 },
    { lat: 49.2349506, lng: 21.2949306 },
    { lat: 49.2347862, lng: 21.293956 },
    { lat: 49.2347615, lng: 21.2933262 },
    { lat: 49.2346779, lng: 21.2930563 },
    { lat: 49.2338887, lng: 21.2931159 },
    { lat: 49.2333788, lng: 21.2933312 },
    { lat: 49.2326027, lng: 21.2915177 },
    { lat: 49.2322017, lng: 21.2908331 },
    { lat: 49.232075, lng: 21.2907369 },
    { lat: 49.2317944, lng: 21.2903164 },
    { lat: 49.2316313, lng: 21.2902023 },
    { lat: 49.2315869, lng: 21.2885565 },
    { lat: 49.231373, lng: 21.2863919 },
    { lat: 49.2314417, lng: 21.2862877 },
    { lat: 49.2309015, lng: 21.2852037 },
    { lat: 49.2309507, lng: 21.2848589 },
    { lat: 49.2306775, lng: 21.2838298 },
    { lat: 49.2302269, lng: 21.2825148 },
    { lat: 49.2296386, lng: 21.2811146 },
    { lat: 49.2288723, lng: 21.2795174 },
    { lat: 49.2287311, lng: 21.2793146 },
    { lat: 49.2284106, lng: 21.2793596 },
    { lat: 49.2281629, lng: 21.2789056 },
    { lat: 49.2280932, lng: 21.278575 },
    { lat: 49.2280835, lng: 21.2781861 },
    { lat: 49.2279187, lng: 21.2773885 },
    { lat: 49.2275534, lng: 21.2762904 },
    { lat: 49.2274655, lng: 21.2758823 },
    { lat: 49.2272938, lng: 21.2755618 },
    { lat: 49.2270703, lng: 21.2748354 },
    { lat: 49.226798, lng: 21.2742772 },
    { lat: 49.2258567, lng: 21.2747007 },
    { lat: 49.2253042, lng: 21.273629 },
    { lat: 49.2247009, lng: 21.2714805 },
    { lat: 49.2244668, lng: 21.2714795 },
    { lat: 49.2241915, lng: 21.2707877 },
    { lat: 49.2240991, lng: 21.2707878 },
  ],
  Šiba: [
    { lat: 49.2278366, lng: 21.1586582 },
    { lat: 49.2276872, lng: 21.1589558 },
    { lat: 49.2275392, lng: 21.1593248 },
    { lat: 49.2275017, lng: 21.1595281 },
    { lat: 49.2275393, lng: 21.159758 },
    { lat: 49.2273853, lng: 21.1600853 },
    { lat: 49.2266631, lng: 21.1603441 },
    { lat: 49.2264705, lng: 21.1605163 },
    { lat: 49.2264151, lng: 21.1607587 },
    { lat: 49.2258355, lng: 21.1616212 },
    { lat: 49.2256707, lng: 21.161998 },
    { lat: 49.2252228, lng: 21.1626414 },
    { lat: 49.2249366, lng: 21.1628437 },
    { lat: 49.224332, lng: 21.1638737 },
    { lat: 49.2241703, lng: 21.1640255 },
    { lat: 49.2239261, lng: 21.1638864 },
    { lat: 49.2238659, lng: 21.1639373 },
    { lat: 49.223783, lng: 21.1644528 },
    { lat: 49.2234296, lng: 21.1646647 },
    { lat: 49.2232222, lng: 21.1646129 },
    { lat: 49.2227699, lng: 21.1650866 },
    { lat: 49.2226774, lng: 21.1653339 },
    { lat: 49.222498, lng: 21.1654921 },
    { lat: 49.2221209, lng: 21.1655589 },
    { lat: 49.2218728, lng: 21.1656843 },
    { lat: 49.2217283, lng: 21.1663162 },
    { lat: 49.2217443, lng: 21.166437 },
    { lat: 49.2216754, lng: 21.1664939 },
    { lat: 49.2216192, lng: 21.1664635 },
    { lat: 49.2213685, lng: 21.1658234 },
    { lat: 49.2210625, lng: 21.1655005 },
    { lat: 49.2208956, lng: 21.1657064 },
    { lat: 49.2203006, lng: 21.1657848 },
    { lat: 49.2199655, lng: 21.1655051 },
    { lat: 49.2196252, lng: 21.1655282 },
    { lat: 49.2194775, lng: 21.1654501 },
    { lat: 49.2190599, lng: 21.1654406 },
    { lat: 49.2187141, lng: 21.1656848 },
    { lat: 49.2185212, lng: 21.1657367 },
    { lat: 49.2181708, lng: 21.165579 },
    { lat: 49.218058, lng: 21.1653587 },
    { lat: 49.2179035, lng: 21.1653121 },
    { lat: 49.2177589, lng: 21.1654389 },
    { lat: 49.2176182, lng: 21.1652598 },
    { lat: 49.2174862, lng: 21.1652846 },
    { lat: 49.2173107, lng: 21.1655368 },
    { lat: 49.2171956, lng: 21.1661006 },
    { lat: 49.217035, lng: 21.1663636 },
    { lat: 49.2164227, lng: 21.1665005 },
    { lat: 49.2163206, lng: 21.1666883 },
    { lat: 49.216214, lng: 21.1671251 },
    { lat: 49.2158773, lng: 21.1674798 },
    { lat: 49.2159225, lng: 21.1679884 },
    { lat: 49.2158103, lng: 21.1682147 },
    { lat: 49.2155635, lng: 21.1682701 },
    { lat: 49.2155329, lng: 21.1683393 },
    { lat: 49.215568, lng: 21.1685272 },
    { lat: 49.2154863, lng: 21.1686814 },
    { lat: 49.2151801, lng: 21.1686779 },
    { lat: 49.2148554, lng: 21.1685486 },
    { lat: 49.21478, lng: 21.1683706 },
    { lat: 49.2144813, lng: 21.1690338 },
    { lat: 49.2140006, lng: 21.1694569 },
    { lat: 49.2139045, lng: 21.1696284 },
    { lat: 49.2138608, lng: 21.1698821 },
    { lat: 49.2137558, lng: 21.1699448 },
    { lat: 49.2132169, lng: 21.1698067 },
    { lat: 49.2130979, lng: 21.1696675 },
    { lat: 49.2129629, lng: 21.1697392 },
    { lat: 49.2127697, lng: 21.1695705 },
    { lat: 49.2127396, lng: 21.1696173 },
    { lat: 49.2127715, lng: 21.1698454 },
    { lat: 49.2127217, lng: 21.1699105 },
    { lat: 49.2125443, lng: 21.1698504 },
    { lat: 49.2116638, lng: 21.1699392 },
    { lat: 49.2115257, lng: 21.1700536 },
    { lat: 49.2112225, lng: 21.1700394 },
    { lat: 49.2111379, lng: 21.1702158 },
    { lat: 49.2109041, lng: 21.1704362 },
    { lat: 49.2105938, lng: 21.1711294 },
    { lat: 49.2103555, lng: 21.1713509 },
    { lat: 49.2101641, lng: 21.1712407 },
    { lat: 49.209913, lng: 21.1712338 },
    { lat: 49.2096861, lng: 21.1714281 },
    { lat: 49.2090702, lng: 21.171594 },
    { lat: 49.2089881, lng: 21.1718483 },
    { lat: 49.2088456, lng: 21.1718598 },
    { lat: 49.2086504, lng: 21.1720284 },
    { lat: 49.2085996, lng: 21.1721959 },
    { lat: 49.2083703, lng: 21.1721705 },
    { lat: 49.2084164, lng: 21.1723194 },
    { lat: 49.2081604, lng: 21.17269 },
    { lat: 49.2080657, lng: 21.1726865 },
    { lat: 49.2079885, lng: 21.1729523 },
    { lat: 49.2078151, lng: 21.1729491 },
    { lat: 49.2077758, lng: 21.17302 },
    { lat: 49.2076438, lng: 21.1729767 },
    { lat: 49.2071836, lng: 21.1734083 },
    { lat: 49.2071701, lng: 21.173694 },
    { lat: 49.2071271, lng: 21.173795 },
    { lat: 49.2069155, lng: 21.173755 },
    { lat: 49.2068784, lng: 21.1738668 },
    { lat: 49.2067107, lng: 21.1739409 },
    { lat: 49.2064886, lng: 21.1738189 },
    { lat: 49.2063282, lng: 21.1738698 },
    { lat: 49.2060568, lng: 21.1741861 },
    { lat: 49.2059226, lng: 21.1742463 },
    { lat: 49.2057981, lng: 21.1744436 },
    { lat: 49.2055953, lng: 21.1744724 },
    { lat: 49.2052259, lng: 21.1748527 },
    { lat: 49.204508, lng: 21.1750738 },
    { lat: 49.2044732, lng: 21.1752506 },
    { lat: 49.204283, lng: 21.1753274 },
    { lat: 49.2034046, lng: 21.1831534 },
    { lat: 49.2039254, lng: 21.1831487 },
    { lat: 49.2041209, lng: 21.1830294 },
    { lat: 49.2048512, lng: 21.1829554 },
    { lat: 49.2052773, lng: 21.1829795 },
    { lat: 49.2057059, lng: 21.1834831 },
    { lat: 49.2066001, lng: 21.1842245 },
    { lat: 49.2067641, lng: 21.1846128 },
    { lat: 49.2070143, lng: 21.1849534 },
    { lat: 49.2074887, lng: 21.1852651 },
    { lat: 49.2077147, lng: 21.1852915 },
    { lat: 49.208067, lng: 21.1858727 },
    { lat: 49.2087324, lng: 21.1866431 },
    { lat: 49.2091427, lng: 21.1872791 },
    { lat: 49.2096787, lng: 21.1877562 },
    { lat: 49.2102913, lng: 21.1884232 },
    { lat: 49.2105626, lng: 21.1889351 },
    { lat: 49.2109573, lng: 21.190296 },
    { lat: 49.211789, lng: 21.1924329 },
    { lat: 49.2123345, lng: 21.1934072 },
    { lat: 49.2131761, lng: 21.1944825 },
    { lat: 49.2139629, lng: 21.1958316 },
    { lat: 49.2139916, lng: 21.1960653 },
    { lat: 49.2146573, lng: 21.1982965 },
    { lat: 49.2149328, lng: 21.1987599 },
    { lat: 49.2151818, lng: 21.1993549 },
    { lat: 49.2154706, lng: 21.1997778 },
    { lat: 49.2155558, lng: 21.2001956 },
    { lat: 49.2155642, lng: 21.2005441 },
    { lat: 49.2158319, lng: 21.2009038 },
    { lat: 49.2161535, lng: 21.2009337 },
    { lat: 49.2166336, lng: 21.20135 },
    { lat: 49.2167834, lng: 21.2015728 },
    { lat: 49.2168493, lng: 21.201812 },
    { lat: 49.2171804, lng: 21.2022938 },
    { lat: 49.2173344, lng: 21.202688 },
    { lat: 49.2172948, lng: 21.203221 },
    { lat: 49.2174139, lng: 21.2034341 },
    { lat: 49.2175975, lng: 21.2035533 },
    { lat: 49.2178228, lng: 21.2035953 },
    { lat: 49.217804, lng: 21.2037853 },
    { lat: 49.2181163, lng: 21.2038808 },
    { lat: 49.2187608, lng: 21.204273 },
    { lat: 49.2191844, lng: 21.2045728 },
    { lat: 49.2193894, lng: 21.2048333 },
    { lat: 49.2195489, lng: 21.2053679 },
    { lat: 49.2198644, lng: 21.2058166 },
    { lat: 49.2201057, lng: 21.2067109 },
    { lat: 49.2201325, lng: 21.2074603 },
    { lat: 49.2204753, lng: 21.2082883 },
    { lat: 49.2212746, lng: 21.210547 },
    { lat: 49.2215731, lng: 21.2109973 },
    { lat: 49.2220452, lng: 21.2125068 },
    { lat: 49.2221177, lng: 21.2130265 },
    { lat: 49.2221864, lng: 21.2132141 },
    { lat: 49.2224802, lng: 21.2137995 },
    { lat: 49.2225434, lng: 21.2140595 },
    { lat: 49.222787, lng: 21.2142979 },
    { lat: 49.2228457, lng: 21.2144877 },
    { lat: 49.2233471, lng: 21.2153061 },
    { lat: 49.2234315, lng: 21.2155258 },
    { lat: 49.2235797, lng: 21.2163852 },
    { lat: 49.2233955, lng: 21.2181836 },
    { lat: 49.2233747, lng: 21.2191819 },
    { lat: 49.2232192, lng: 21.2200164 },
    { lat: 49.2232113, lng: 21.220341 },
    { lat: 49.2231389, lng: 21.2205463 },
    { lat: 49.2231775, lng: 21.2206001 },
    { lat: 49.2231572, lng: 21.2208121 },
    { lat: 49.2232247, lng: 21.2208839 },
    { lat: 49.2231893, lng: 21.2214157 },
    { lat: 49.2232671, lng: 21.2217812 },
    { lat: 49.2232706, lng: 21.2222255 },
    { lat: 49.223404, lng: 21.2223894 },
    { lat: 49.2234337, lng: 21.2225878 },
    { lat: 49.2235518, lng: 21.2226952 },
    { lat: 49.2236504, lng: 21.2230467 },
    { lat: 49.2237322, lng: 21.2236585 },
    { lat: 49.2238629, lng: 21.2239195 },
    { lat: 49.2239351, lng: 21.224212 },
    { lat: 49.2239114, lng: 21.2245046 },
    { lat: 49.2237979, lng: 21.2248403 },
    { lat: 49.2237205, lng: 21.2256112 },
    { lat: 49.2236142, lng: 21.2261369 },
    { lat: 49.2235, lng: 21.2264507 },
    { lat: 49.2234954, lng: 21.2266685 },
    { lat: 49.2235813, lng: 21.2268996 },
    { lat: 49.223417, lng: 21.2270849 },
    { lat: 49.2234322, lng: 21.2273567 },
    { lat: 49.2233068, lng: 21.2276699 },
    { lat: 49.2230393, lng: 21.2278194 },
    { lat: 49.2229923, lng: 21.2279002 },
    { lat: 49.222925, lng: 21.2283489 },
    { lat: 49.222748, lng: 21.2285952 },
    { lat: 49.2227418, lng: 21.2289006 },
    { lat: 49.2228073, lng: 21.2291298 },
    { lat: 49.2227748, lng: 21.229207 },
    { lat: 49.222828, lng: 21.2292954 },
    { lat: 49.2227798, lng: 21.2294839 },
    { lat: 49.2226469, lng: 21.2294779 },
    { lat: 49.2226192, lng: 21.2299648 },
    { lat: 49.2224364, lng: 21.2301677 },
    { lat: 49.2225118, lng: 21.2304207 },
    { lat: 49.2224502, lng: 21.2306544 },
    { lat: 49.2225352, lng: 21.2309651 },
    { lat: 49.2223953, lng: 21.2311001 },
    { lat: 49.2223708, lng: 21.2312843 },
    { lat: 49.2222288, lng: 21.2313219 },
    { lat: 49.2221326, lng: 21.2316272 },
    { lat: 49.2221672, lng: 21.2320261 },
    { lat: 49.2222972, lng: 21.232043 },
    { lat: 49.2222031, lng: 21.2322417 },
    { lat: 49.2220904, lng: 21.2323034 },
    { lat: 49.2220238, lng: 21.2327351 },
    { lat: 49.2220744, lng: 21.2328099 },
    { lat: 49.2219359, lng: 21.2329127 },
    { lat: 49.2218891, lng: 21.2331173 },
    { lat: 49.221943, lng: 21.2331444 },
    { lat: 49.2220043, lng: 21.2330522 },
    { lat: 49.2220941, lng: 21.2333046 },
    { lat: 49.2222777, lng: 21.2333465 },
    { lat: 49.2223026, lng: 21.233497 },
    { lat: 49.2222215, lng: 21.2336741 },
    { lat: 49.2223684, lng: 21.2336971 },
    { lat: 49.2224274, lng: 21.2338609 },
    { lat: 49.2225082, lng: 21.2339268 },
    { lat: 49.2225395, lng: 21.2340322 },
    { lat: 49.2224731, lng: 21.23425 },
    { lat: 49.2225778, lng: 21.2342875 },
    { lat: 49.2226149, lng: 21.2343979 },
    { lat: 49.2225795, lng: 21.2347043 },
    { lat: 49.2227661, lng: 21.2346995 },
    { lat: 49.2228208, lng: 21.2347985 },
    { lat: 49.2228238, lng: 21.234897 },
    { lat: 49.2226916, lng: 21.2351282 },
    { lat: 49.2227001, lng: 21.2352586 },
    { lat: 49.2227955, lng: 21.2353259 },
    { lat: 49.2229473, lng: 21.2353244 },
    { lat: 49.2230414, lng: 21.2354224 },
    { lat: 49.2231505, lng: 21.2353829 },
    { lat: 49.2232239, lng: 21.2358287 },
    { lat: 49.2235416, lng: 21.2362455 },
    { lat: 49.223582, lng: 21.236203 },
    { lat: 49.2237298, lng: 21.236286 },
    { lat: 49.223859, lng: 21.2365014 },
    { lat: 49.2238708, lng: 21.2367017 },
    { lat: 49.223704, lng: 21.2369117 },
    { lat: 49.2236482, lng: 21.2369294 },
    { lat: 49.2236032, lng: 21.236853 },
    { lat: 49.223501, lng: 21.2369338 },
    { lat: 49.2235255, lng: 21.2371095 },
    { lat: 49.2237404, lng: 21.2371153 },
    { lat: 49.2238054, lng: 21.2375416 },
    { lat: 49.2236686, lng: 21.2377393 },
    { lat: 49.2235892, lng: 21.2377565 },
    { lat: 49.2235391, lng: 21.2381748 },
    { lat: 49.2236621, lng: 21.2382646 },
    { lat: 49.2237643, lng: 21.2386587 },
    { lat: 49.2237567, lng: 21.2387726 },
    { lat: 49.2236225, lng: 21.2389093 },
    { lat: 49.2237467, lng: 21.239039 },
    { lat: 49.2240417, lng: 21.2396306 },
    { lat: 49.2238729, lng: 21.2399595 },
    { lat: 49.2239425, lng: 21.2401366 },
    { lat: 49.2239315, lng: 21.2402166 },
    { lat: 49.2238237, lng: 21.2404076 },
    { lat: 49.2237542, lng: 21.2406696 },
    { lat: 49.2239783, lng: 21.2409347 },
    { lat: 49.2239211, lng: 21.2411616 },
    { lat: 49.2239403, lng: 21.2413798 },
    { lat: 49.2238202, lng: 21.2416138 },
    { lat: 49.2237063, lng: 21.2416075 },
    { lat: 49.2236177, lng: 21.2417632 },
    { lat: 49.2237626, lng: 21.2421204 },
    { lat: 49.2237025, lng: 21.2422504 },
    { lat: 49.2238062, lng: 21.242365 },
    { lat: 49.2239102, lng: 21.2423097 },
    { lat: 49.2238841, lng: 21.2420826 },
    { lat: 49.2240513, lng: 21.2419914 },
    { lat: 49.2241309, lng: 21.2420962 },
    { lat: 49.2241287, lng: 21.2422386 },
    { lat: 49.2240868, lng: 21.2422337 },
    { lat: 49.2240384, lng: 21.2424556 },
    { lat: 49.2241127, lng: 21.2426026 },
    { lat: 49.224114, lng: 21.2431598 },
    { lat: 49.2240471, lng: 21.2432459 },
    { lat: 49.2240517, lng: 21.2433644 },
    { lat: 49.2239898, lng: 21.2434689 },
    { lat: 49.2237935, lng: 21.2436003 },
    { lat: 49.2238452, lng: 21.2437434 },
    { lat: 49.2238121, lng: 21.2438376 },
    { lat: 49.2236911, lng: 21.2438905 },
    { lat: 49.2236167, lng: 21.2441101 },
    { lat: 49.2236366, lng: 21.2443221 },
    { lat: 49.2235619, lng: 21.2447069 },
    { lat: 49.2237131, lng: 21.2450164 },
    { lat: 49.2235239, lng: 21.2454897 },
    { lat: 49.2235396, lng: 21.2457394 },
    { lat: 49.2236896, lng: 21.2458266 },
    { lat: 49.2237514, lng: 21.246004 },
    { lat: 49.223647, lng: 21.2461965 },
    { lat: 49.2236009, lng: 21.2464169 },
    { lat: 49.2236153, lng: 21.2465947 },
    { lat: 49.2236794, lng: 21.2466679 },
    { lat: 49.2236932, lng: 21.2467901 },
    { lat: 49.2235982, lng: 21.2469891 },
    { lat: 49.2236634, lng: 21.2471385 },
    { lat: 49.2236495, lng: 21.2474537 },
    { lat: 49.2237259, lng: 21.2475638 },
    { lat: 49.2237565, lng: 21.2477539 },
    { lat: 49.2236435, lng: 21.2478811 },
    { lat: 49.2237166, lng: 21.248175 },
    { lat: 49.2236934, lng: 21.2482966 },
    { lat: 49.2237579, lng: 21.2483449 },
    { lat: 49.2239785, lng: 21.2489343 },
    { lat: 49.2241612, lng: 21.2496095 },
    { lat: 49.2241445, lng: 21.2497262 },
    { lat: 49.2240451, lng: 21.2498248 },
    { lat: 49.2240015, lng: 21.2499776 },
    { lat: 49.2240763, lng: 21.2501105 },
    { lat: 49.2239584, lng: 21.2501884 },
    { lat: 49.2239823, lng: 21.2503066 },
    { lat: 49.2238728, lng: 21.2505167 },
    { lat: 49.2239498, lng: 21.2507521 },
    { lat: 49.2238863, lng: 21.2509189 },
    { lat: 49.2239041, lng: 21.2511697 },
    { lat: 49.2240077, lng: 21.2514344 },
    { lat: 49.2239739, lng: 21.251579 },
    { lat: 49.2238953, lng: 21.2516592 },
    { lat: 49.2239219, lng: 21.2518966 },
    { lat: 49.2238816, lng: 21.2519823 },
    { lat: 49.2237835, lng: 21.2520138 },
    { lat: 49.2237774, lng: 21.2521442 },
    { lat: 49.2237022, lng: 21.2522939 },
    { lat: 49.2236483, lng: 21.2523715 },
    { lat: 49.2236824, lng: 21.2523857 },
    { lat: 49.2236611, lng: 21.2524221 },
    { lat: 49.2237345, lng: 21.2523913 },
    { lat: 49.2241935, lng: 21.2520281 },
    { lat: 49.224323, lng: 21.2517359 },
    { lat: 49.2250129, lng: 21.2529546 },
    { lat: 49.2259368, lng: 21.2540964 },
    { lat: 49.2264737, lng: 21.2547612 },
    { lat: 49.2271388, lng: 21.2531881 },
    { lat: 49.2281925, lng: 21.2508833 },
    { lat: 49.2282514, lng: 21.2503892 },
    { lat: 49.2285332, lng: 21.2497066 },
    { lat: 49.2289694, lng: 21.2480734 },
    { lat: 49.2308047, lng: 21.244559 },
    { lat: 49.2309517, lng: 21.2443616 },
    { lat: 49.2330785, lng: 21.2423876 },
    { lat: 49.2346009, lng: 21.2411391 },
    { lat: 49.2363342, lng: 21.2409896 },
    { lat: 49.2364214, lng: 21.2410683 },
    { lat: 49.2365623, lng: 21.2413087 },
    { lat: 49.2367634, lng: 21.2414114 },
    { lat: 49.2369723, lng: 21.2412778 },
    { lat: 49.2372009, lng: 21.2412585 },
    { lat: 49.2374604, lng: 21.2413358 },
    { lat: 49.2378607, lng: 21.2414343 },
    { lat: 49.2381798, lng: 21.2413841 },
    { lat: 49.2381453, lng: 21.2407359 },
    { lat: 49.2382127, lng: 21.2399277 },
    { lat: 49.2381648, lng: 21.2392812 },
    { lat: 49.2380986, lng: 21.2391372 },
    { lat: 49.2380824, lng: 21.2388333 },
    { lat: 49.2373551, lng: 21.2377919 },
    { lat: 49.2373583, lng: 21.2375374 },
    { lat: 49.2372732, lng: 21.2372151 },
    { lat: 49.2373125, lng: 21.2366283 },
    { lat: 49.2372259, lng: 21.2363738 },
    { lat: 49.2372823, lng: 21.2358703 },
    { lat: 49.2372386, lng: 21.2357287 },
    { lat: 49.2373133, lng: 21.2355588 },
    { lat: 49.2373026, lng: 21.2353942 },
    { lat: 49.2373952, lng: 21.2351845 },
    { lat: 49.2374599, lng: 21.2346142 },
    { lat: 49.2378841, lng: 21.2332637 },
    { lat: 49.2384693, lng: 21.232024 },
    { lat: 49.2386601, lng: 21.2319721 },
    { lat: 49.2387158, lng: 21.2318432 },
    { lat: 49.2387076, lng: 21.2317224 },
    { lat: 49.2391198, lng: 21.2310706 },
    { lat: 49.239259, lng: 21.2309212 },
    { lat: 49.239572, lng: 21.230781 },
    { lat: 49.2396458, lng: 21.2306889 },
    { lat: 49.2397067, lng: 21.2304743 },
    { lat: 49.2398837, lng: 21.2303056 },
    { lat: 49.239957, lng: 21.230089 },
    { lat: 49.2405, lng: 21.2298213 },
    { lat: 49.2406065, lng: 21.2301338 },
    { lat: 49.2406975, lng: 21.230133 },
    { lat: 49.2408382, lng: 21.2299839 },
    { lat: 49.2409663, lng: 21.2295433 },
    { lat: 49.2411452, lng: 21.229394 },
    { lat: 49.2413032, lng: 21.2294485 },
    { lat: 49.241653, lng: 21.2299891 },
    { lat: 49.2422017, lng: 21.229685 },
    { lat: 49.2425674, lng: 21.2292849 },
    { lat: 49.2428211, lng: 21.2291547 },
    { lat: 49.2429994, lng: 21.2292107 },
    { lat: 49.2431778, lng: 21.2294813 },
    { lat: 49.2433408, lng: 21.2292946 },
    { lat: 49.2434376, lng: 21.2290769 },
    { lat: 49.2437693, lng: 21.2289991 },
    { lat: 49.2442891, lng: 21.2293993 },
    { lat: 49.2444417, lng: 21.2292392 },
    { lat: 49.2447894, lng: 21.2286859 },
    { lat: 49.2452552, lng: 21.2286917 },
    { lat: 49.2453157, lng: 21.2283517 },
    { lat: 49.2454828, lng: 21.2282932 },
    { lat: 49.2454875, lng: 21.2282317 },
    { lat: 49.245525, lng: 21.2279898 },
    { lat: 49.2454812, lng: 21.2276613 },
    { lat: 49.2449101, lng: 21.2268031 },
    { lat: 49.2444815, lng: 21.2255273 },
    { lat: 49.244086, lng: 21.2248513 },
    { lat: 49.2439073, lng: 21.2246377 },
    { lat: 49.2438226, lng: 21.2247064 },
    { lat: 49.2433024, lng: 21.2238475 },
    { lat: 49.2427774, lng: 21.2220767 },
    { lat: 49.2425506, lng: 21.2206741 },
    { lat: 49.2426121, lng: 21.2204436 },
    { lat: 49.2425621, lng: 21.2200597 },
    { lat: 49.2426671, lng: 21.2197125 },
    { lat: 49.2426242, lng: 21.2197415 },
    { lat: 49.2424606, lng: 21.2196132 },
    { lat: 49.2423341, lng: 21.2193807 },
    { lat: 49.2423522, lng: 21.2191359 },
    { lat: 49.2426367, lng: 21.2189429 },
    { lat: 49.2426357, lng: 21.2188029 },
    { lat: 49.24248, lng: 21.2184947 },
    { lat: 49.2424371, lng: 21.2182727 },
    { lat: 49.2426125, lng: 21.2181582 },
    { lat: 49.2426219, lng: 21.2179194 },
    { lat: 49.2425097, lng: 21.2177064 },
    { lat: 49.2425334, lng: 21.2175682 },
    { lat: 49.2426193, lng: 21.2174706 },
    { lat: 49.2425465, lng: 21.2172648 },
    { lat: 49.2425167, lng: 21.216775 },
    { lat: 49.2425559, lng: 21.2157847 },
    { lat: 49.2423057, lng: 21.2155539 },
    { lat: 49.2423692, lng: 21.2153833 },
    { lat: 49.2421693, lng: 21.21507 },
    { lat: 49.2421239, lng: 21.2145857 },
    { lat: 49.2418673, lng: 21.214624 },
    { lat: 49.2418272, lng: 21.2144537 },
    { lat: 49.2418984, lng: 21.2142108 },
    { lat: 49.2417222, lng: 21.2140006 },
    { lat: 49.2416972, lng: 21.2131544 },
    { lat: 49.2415472, lng: 21.2129253 },
    { lat: 49.2415575, lng: 21.2127165 },
    { lat: 49.2414652, lng: 21.212058 },
    { lat: 49.2414962, lng: 21.2117874 },
    { lat: 49.2413906, lng: 21.2115754 },
    { lat: 49.2413532, lng: 21.2112065 },
    { lat: 49.2412268, lng: 21.211266 },
    { lat: 49.241179, lng: 21.2111773 },
    { lat: 49.2411713, lng: 21.2110338 },
    { lat: 49.2413228, lng: 21.2110221 },
    { lat: 49.2413021, lng: 21.2108954 },
    { lat: 49.2412051, lng: 21.2108923 },
    { lat: 49.2411442, lng: 21.210815 },
    { lat: 49.2411544, lng: 21.2107091 },
    { lat: 49.2410768, lng: 21.2106016 },
    { lat: 49.2409126, lng: 21.2105692 },
    { lat: 49.2406822, lng: 21.2103229 },
    { lat: 49.2404522, lng: 21.2102351 },
    { lat: 49.2403313, lng: 21.2100036 },
    { lat: 49.2401848, lng: 21.2099534 },
    { lat: 49.2400709, lng: 21.2097646 },
    { lat: 49.2393382, lng: 21.2093955 },
    { lat: 49.2391929, lng: 21.2089897 },
    { lat: 49.2390932, lng: 21.2084571 },
    { lat: 49.2378025, lng: 21.2033027 },
    { lat: 49.2369867, lng: 21.2012358 },
    { lat: 49.2369308, lng: 21.2009233 },
    { lat: 49.236744, lng: 21.2005285 },
    { lat: 49.2367917, lng: 21.1996702 },
    { lat: 49.2369103, lng: 21.1991028 },
    { lat: 49.2369538, lng: 21.1986563 },
    { lat: 49.2369718, lng: 21.197932 },
    { lat: 49.2371598, lng: 21.1968856 },
    { lat: 49.237152, lng: 21.1964908 },
    { lat: 49.2370503, lng: 21.1959364 },
    { lat: 49.2368923, lng: 21.1955561 },
    { lat: 49.2367339, lng: 21.1949828 },
    { lat: 49.2367716, lng: 21.1937003 },
    { lat: 49.23672, lng: 21.1930941 },
    { lat: 49.236206, lng: 21.1916183 },
    { lat: 49.2361419, lng: 21.1909665 },
    { lat: 49.2359215, lng: 21.1899627 },
    { lat: 49.2359186, lng: 21.189686 },
    { lat: 49.2359843, lng: 21.1892974 },
    { lat: 49.2359729, lng: 21.1886447 },
    { lat: 49.2360678, lng: 21.1876115 },
    { lat: 49.235965, lng: 21.1871657 },
    { lat: 49.235827, lng: 21.1861642 },
    { lat: 49.2357727, lng: 21.1856187 },
    { lat: 49.235816, lng: 21.18516 },
    { lat: 49.2356727, lng: 21.1850415 },
    { lat: 49.2354923, lng: 21.1847068 },
    { lat: 49.2350897, lng: 21.1834124 },
    { lat: 49.2351704, lng: 21.183368 },
    { lat: 49.2350282, lng: 21.1831429 },
    { lat: 49.2346267, lng: 21.1814894 },
    { lat: 49.2343103, lng: 21.1810808 },
    { lat: 49.2337645, lng: 21.1798606 },
    { lat: 49.2335178, lng: 21.1797061 },
    { lat: 49.2333315, lng: 21.1793585 },
    { lat: 49.2329966, lng: 21.1771379 },
    { lat: 49.232871, lng: 21.1766127 },
    { lat: 49.2323494, lng: 21.1763771 },
    { lat: 49.2322649, lng: 21.1758032 },
    { lat: 49.2318837, lng: 21.1752315 },
    { lat: 49.2317835, lng: 21.1748686 },
    { lat: 49.2316617, lng: 21.173999 },
    { lat: 49.2316401, lng: 21.1733773 },
    { lat: 49.2316708, lng: 21.1729548 },
    { lat: 49.2316192, lng: 21.1724578 },
    { lat: 49.2313143, lng: 21.1716688 },
    { lat: 49.2310755, lng: 21.1698615 },
    { lat: 49.2311106, lng: 21.1684388 },
    { lat: 49.2311698, lng: 21.1681681 },
    { lat: 49.2310905, lng: 21.1676151 },
    { lat: 49.2311274, lng: 21.1668123 },
    { lat: 49.2310885, lng: 21.1665797 },
    { lat: 49.2312038, lng: 21.1660236 },
    { lat: 49.2309085, lng: 21.1650738 },
    { lat: 49.2306412, lng: 21.1647318 },
    { lat: 49.2302837, lng: 21.1641268 },
    { lat: 49.230198, lng: 21.1635145 },
    { lat: 49.2300184, lng: 21.1627445 },
    { lat: 49.2297649, lng: 21.1620822 },
    { lat: 49.2293925, lng: 21.1613663 },
    { lat: 49.2290514, lng: 21.1610075 },
    { lat: 49.2287283, lng: 21.1608176 },
    { lat: 49.2283822, lng: 21.1599054 },
    { lat: 49.228176, lng: 21.1591133 },
    { lat: 49.2278366, lng: 21.1586582 },
  ],
  Bogliarka: [
    { lat: 49.2832353, lng: 21.1185782 },
    { lat: 49.2830432, lng: 21.1185859 },
    { lat: 49.2824567, lng: 21.1184369 },
    { lat: 49.2821939, lng: 21.1166795 },
    { lat: 49.2813545, lng: 21.1164656 },
    { lat: 49.2813549, lng: 21.1163376 },
    { lat: 49.2811054, lng: 21.1163132 },
    { lat: 49.2803467, lng: 21.1175438 },
    { lat: 49.280072, lng: 21.116972 },
    { lat: 49.279966, lng: 21.1162918 },
    { lat: 49.2796908, lng: 21.1154194 },
    { lat: 49.2793827, lng: 21.1149319 },
    { lat: 49.2781927, lng: 21.1134589 },
    { lat: 49.2775733, lng: 21.1125235 },
    { lat: 49.2770528, lng: 21.1115509 },
    { lat: 49.2760327, lng: 21.11089 },
    { lat: 49.2753408, lng: 21.1105756 },
    { lat: 49.2748785, lng: 21.1104755 },
    { lat: 49.2747697, lng: 21.110561 },
    { lat: 49.2746493, lng: 21.1109134 },
    { lat: 49.2746285, lng: 21.111817 },
    { lat: 49.2745478, lng: 21.1123917 },
    { lat: 49.2746437, lng: 21.1126891 },
    { lat: 49.2745079, lng: 21.1132974 },
    { lat: 49.2745427, lng: 21.1134336 },
    { lat: 49.2743427, lng: 21.1136268 },
    { lat: 49.2743292, lng: 21.1142264 },
    { lat: 49.2741874, lng: 21.1144387 },
    { lat: 49.2737933, lng: 21.1149995 },
    { lat: 49.2736188, lng: 21.1149274 },
    { lat: 49.2732232, lng: 21.1150104 },
    { lat: 49.2728083, lng: 21.1155187 },
    { lat: 49.2727261, lng: 21.1160113 },
    { lat: 49.2723106, lng: 21.1163218 },
    { lat: 49.2721992, lng: 21.1166021 },
    { lat: 49.2721494, lng: 21.1169117 },
    { lat: 49.2721963, lng: 21.1171486 },
    { lat: 49.2720685, lng: 21.117379 },
    { lat: 49.2717044, lng: 21.1172161 },
    { lat: 49.2716342, lng: 21.1173139 },
    { lat: 49.2715109, lng: 21.117754 },
    { lat: 49.2712338, lng: 21.118207 },
    { lat: 49.2710805, lng: 21.1183387 },
    { lat: 49.2708369, lng: 21.1184277 },
    { lat: 49.2705235, lng: 21.1188001 },
    { lat: 49.2703711, lng: 21.1187819 },
    { lat: 49.270159, lng: 21.119016 },
    { lat: 49.2699496, lng: 21.1193634 },
    { lat: 49.2698274, lng: 21.119416 },
    { lat: 49.2697304, lng: 21.1196585 },
    { lat: 49.2696484, lng: 21.1195551 },
    { lat: 49.2695763, lng: 21.1196988 },
    { lat: 49.2687224, lng: 21.1202333 },
    { lat: 49.2684342, lng: 21.120125 },
    { lat: 49.2681722, lng: 21.1208377 },
    { lat: 49.2680121, lng: 21.1207587 },
    { lat: 49.2678904, lng: 21.1209281 },
    { lat: 49.2678221, lng: 21.1212935 },
    { lat: 49.2677201, lng: 21.1214848 },
    { lat: 49.2676156, lng: 21.121566 },
    { lat: 49.2674741, lng: 21.1215333 },
    { lat: 49.2673328, lng: 21.1216215 },
    { lat: 49.2671801, lng: 21.1218782 },
    { lat: 49.2668855, lng: 21.1220312 },
    { lat: 49.2668661, lng: 21.1222287 },
    { lat: 49.2665085, lng: 21.1224576 },
    { lat: 49.2663169, lng: 21.1228332 },
    { lat: 49.2659681, lng: 21.1232255 },
    { lat: 49.2655562, lng: 21.1233302 },
    { lat: 49.2654416, lng: 21.1233012 },
    { lat: 49.265392, lng: 21.1238327 },
    { lat: 49.2651833, lng: 21.1238168 },
    { lat: 49.2649262, lng: 21.1243305 },
    { lat: 49.2647651, lng: 21.1244689 },
    { lat: 49.2647743, lng: 21.124759 },
    { lat: 49.2647019, lng: 21.1251027 },
    { lat: 49.2642286, lng: 21.1251539 },
    { lat: 49.2641111, lng: 21.1254552 },
    { lat: 49.2639011, lng: 21.125608 },
    { lat: 49.2636524, lng: 21.1262122 },
    { lat: 49.2633544, lng: 21.1262876 },
    { lat: 49.2632898, lng: 21.1269892 },
    { lat: 49.2631713, lng: 21.1272599 },
    { lat: 49.2632732, lng: 21.1275658 },
    { lat: 49.262975, lng: 21.127742 },
    { lat: 49.262908, lng: 21.1279354 },
    { lat: 49.2627415, lng: 21.1280446 },
    { lat: 49.2625918, lng: 21.1285082 },
    { lat: 49.2624693, lng: 21.1285789 },
    { lat: 49.2624576, lng: 21.1288874 },
    { lat: 49.2623, lng: 21.1290248 },
    { lat: 49.2620861, lng: 21.1294867 },
    { lat: 49.261888, lng: 21.1291655 },
    { lat: 49.2617399, lng: 21.1292856 },
    { lat: 49.2615965, lng: 21.1293056 },
    { lat: 49.2615418, lng: 21.1294609 },
    { lat: 49.2614874, lng: 21.1294859 },
    { lat: 49.2612102, lng: 21.1292949 },
    { lat: 49.2610259, lng: 21.1293358 },
    { lat: 49.2608797, lng: 21.1294111 },
    { lat: 49.2609549, lng: 21.1295519 },
    { lat: 49.2609449, lng: 21.1297641 },
    { lat: 49.2605996, lng: 21.1297038 },
    { lat: 49.260567, lng: 21.1297765 },
    { lat: 49.2602191, lng: 21.1299445 },
    { lat: 49.2601393, lng: 21.1296957 },
    { lat: 49.2598598, lng: 21.1297884 },
    { lat: 49.2597805, lng: 21.1297045 },
    { lat: 49.2594674, lng: 21.1296628 },
    { lat: 49.2593271, lng: 21.1297063 },
    { lat: 49.2592289, lng: 21.129554 },
    { lat: 49.2590841, lng: 21.129493 },
    { lat: 49.2586926, lng: 21.1296411 },
    { lat: 49.2584844, lng: 21.129498 },
    { lat: 49.2583792, lng: 21.1295137 },
    { lat: 49.2583322, lng: 21.1295939 },
    { lat: 49.2580093, lng: 21.1297388 },
    { lat: 49.2578059, lng: 21.1296385 },
    { lat: 49.2575862, lng: 21.1297369 },
    { lat: 49.2574999, lng: 21.1296385 },
    { lat: 49.2573286, lng: 21.1296367 },
    { lat: 49.2571387, lng: 21.1294983 },
    { lat: 49.2569876, lng: 21.1295992 },
    { lat: 49.2568883, lng: 21.1295511 },
    { lat: 49.2566976, lng: 21.1296822 },
    { lat: 49.2561707, lng: 21.129528 },
    { lat: 49.2561027, lng: 21.129586 },
    { lat: 49.2559158, lng: 21.1295797 },
    { lat: 49.2557671, lng: 21.1297556 },
    { lat: 49.2554906, lng: 21.1296237 },
    { lat: 49.2553919, lng: 21.1298057 },
    { lat: 49.2553037, lng: 21.1298659 },
    { lat: 49.2550392, lng: 21.1298296 },
    { lat: 49.2549496, lng: 21.1301654 },
    { lat: 49.2547582, lng: 21.1301608 },
    { lat: 49.2547325, lng: 21.130309 },
    { lat: 49.2545765, lng: 21.1304614 },
    { lat: 49.254526, lng: 21.1308559 },
    { lat: 49.2549593, lng: 21.1314317 },
    { lat: 49.2557319, lng: 21.1351896 },
    { lat: 49.2560142, lng: 21.1389607 },
    { lat: 49.2560203, lng: 21.1391933 },
    { lat: 49.2559239, lng: 21.1395225 },
    { lat: 49.2559992, lng: 21.1403775 },
    { lat: 49.2559661, lng: 21.1412201 },
    { lat: 49.2560046, lng: 21.1417212 },
    { lat: 49.2562822, lng: 21.1434281 },
    { lat: 49.2562894, lng: 21.1440476 },
    { lat: 49.2560777, lng: 21.1446478 },
    { lat: 49.2562283, lng: 21.1458933 },
    { lat: 49.2562329, lng: 21.1467521 },
    { lat: 49.2562831, lng: 21.1472081 },
    { lat: 49.2568232, lng: 21.1483878 },
    { lat: 49.2569007, lng: 21.1486692 },
    { lat: 49.2571243, lng: 21.1490466 },
    { lat: 49.2535935, lng: 21.1538901 },
    { lat: 49.2534911, lng: 21.1540983 },
    { lat: 49.2534445, lng: 21.1541907 },
    { lat: 49.2534938, lng: 21.1542206 },
    { lat: 49.2540442, lng: 21.1547249 },
    { lat: 49.2544617, lng: 21.1554107 },
    { lat: 49.2548177, lng: 21.1562427 },
    { lat: 49.2552289, lng: 21.1568372 },
    { lat: 49.2556499, lng: 21.1579515 },
    { lat: 49.2559722, lng: 21.15843 },
    { lat: 49.2572205, lng: 21.1596999 },
    { lat: 49.2573582, lng: 21.1597824 },
    { lat: 49.2577745, lng: 21.1598532 },
    { lat: 49.2580198, lng: 21.1599991 },
    { lat: 49.2582074, lng: 21.1602372 },
    { lat: 49.2582535, lng: 21.1605252 },
    { lat: 49.2582017, lng: 21.161166 },
    { lat: 49.2582524, lng: 21.161386 },
    { lat: 49.2587116, lng: 21.1617486 },
    { lat: 49.2591929, lng: 21.1619911 },
    { lat: 49.2593048, lng: 21.1621155 },
    { lat: 49.2596174, lng: 21.1631129 },
    { lat: 49.2605553, lng: 21.1643509 },
    { lat: 49.2611114, lng: 21.1660115 },
    { lat: 49.2612419, lng: 21.1663305 },
    { lat: 49.2614742, lng: 21.1666712 },
    { lat: 49.2620068, lng: 21.1671882 },
    { lat: 49.2626049, lng: 21.1675307 },
    { lat: 49.2628097, lng: 21.167604 },
    { lat: 49.263387, lng: 21.1675672 },
    { lat: 49.2639499, lng: 21.1676442 },
    { lat: 49.2642839, lng: 21.167565 },
    { lat: 49.2647831, lng: 21.1671971 },
    { lat: 49.2649373, lng: 21.1669105 },
    { lat: 49.2650962, lng: 21.1663444 },
    { lat: 49.2652325, lng: 21.1661654 },
    { lat: 49.2653988, lng: 21.1661587 },
    { lat: 49.2656318, lng: 21.1663027 },
    { lat: 49.2660752, lng: 21.166383 },
    { lat: 49.266438, lng: 21.1665367 },
    { lat: 49.2668072, lng: 21.1664379 },
    { lat: 49.266932, lng: 21.1663283 },
    { lat: 49.2674061, lng: 21.1656184 },
    { lat: 49.2684518, lng: 21.1636891 },
    { lat: 49.2687208, lng: 21.163335 },
    { lat: 49.2690838, lng: 21.1621723 },
    { lat: 49.2693788, lng: 21.161603 },
    { lat: 49.2696356, lng: 21.1612102 },
    { lat: 49.2703953, lng: 21.1603236 },
    { lat: 49.2710995, lng: 21.1592374 },
    { lat: 49.2714964, lng: 21.1587669 },
    { lat: 49.2719482, lng: 21.1584585 },
    { lat: 49.2736463, lng: 21.1559541 },
    { lat: 49.2738702, lng: 21.155742 },
    { lat: 49.2751326, lng: 21.1549809 },
    { lat: 49.2756072, lng: 21.1548304 },
    { lat: 49.2759106, lng: 21.1548867 },
    { lat: 49.2761546, lng: 21.1548497 },
    { lat: 49.2767852, lng: 21.1545061 },
    { lat: 49.2776511, lng: 21.1544851 },
    { lat: 49.2783901, lng: 21.1537951 },
    { lat: 49.279228, lng: 21.1527778 },
    { lat: 49.2796666, lng: 21.1521632 },
    { lat: 49.2798728, lng: 21.1517779 },
    { lat: 49.2806397, lng: 21.1502291 },
    { lat: 49.2808565, lng: 21.149639 },
    { lat: 49.2815298, lng: 21.1486921 },
    { lat: 49.2817522, lng: 21.1480994 },
    { lat: 49.2819227, lng: 21.1473754 },
    { lat: 49.2827219, lng: 21.1459174 },
    { lat: 49.2831688, lng: 21.1447423 },
    { lat: 49.2835655, lng: 21.1439742 },
    { lat: 49.2845766, lng: 21.1429558 },
    { lat: 49.2854204, lng: 21.1419208 },
    { lat: 49.2855893, lng: 21.1417815 },
    { lat: 49.2863986, lng: 21.1413945 },
    { lat: 49.2869474, lng: 21.140919 },
    { lat: 49.2881328, lng: 21.1400433 },
    { lat: 49.2890156, lng: 21.1391676 },
    { lat: 49.2894544, lng: 21.1384586 },
    { lat: 49.2899743, lng: 21.1380994 },
    { lat: 49.2905748, lng: 21.1373695 },
    { lat: 49.2911233, lng: 21.1367822 },
    { lat: 49.2908733, lng: 21.1362051 },
    { lat: 49.290765, lng: 21.1357686 },
    { lat: 49.2901619, lng: 21.1340968 },
    { lat: 49.289789, lng: 21.133333 },
    { lat: 49.2898403, lng: 21.1332864 },
    { lat: 49.2894789, lng: 21.1329594 },
    { lat: 49.2888209, lng: 21.132499 },
    { lat: 49.2887769, lng: 21.1320023 },
    { lat: 49.2885387, lng: 21.1311958 },
    { lat: 49.2883786, lng: 21.1310382 },
    { lat: 49.2881996, lng: 21.1303593 },
    { lat: 49.2880273, lng: 21.1300321 },
    { lat: 49.2879347, lng: 21.1295942 },
    { lat: 49.2872184, lng: 21.1280841 },
    { lat: 49.2866381, lng: 21.1273837 },
    { lat: 49.286218, lng: 21.1266621 },
    { lat: 49.2861668, lng: 21.1264691 },
    { lat: 49.2858846, lng: 21.1260459 },
    { lat: 49.2855947, lng: 21.1246706 },
    { lat: 49.2832353, lng: 21.1185782 },
  ],
  Beloveža: [
    { lat: 49.2976393, lng: 21.3448693 },
    { lat: 49.2974786, lng: 21.3450326 },
    { lat: 49.2954728, lng: 21.3470777 },
    { lat: 49.2951512, lng: 21.3475445 },
    { lat: 49.2947209, lng: 21.34771 },
    { lat: 49.294155, lng: 21.3491105 },
    { lat: 49.2939595, lng: 21.3493854 },
    { lat: 49.2936298, lng: 21.3502951 },
    { lat: 49.2933594, lng: 21.3506599 },
    { lat: 49.2931564, lng: 21.3518574 },
    { lat: 49.2929215, lng: 21.3527677 },
    { lat: 49.2922664, lng: 21.3548818 },
    { lat: 49.292026, lng: 21.355916 },
    { lat: 49.2908295, lng: 21.3575713 },
    { lat: 49.2909207, lng: 21.3577207 },
    { lat: 49.2909096, lng: 21.3579179 },
    { lat: 49.2905887, lng: 21.357757 },
    { lat: 49.2904321, lng: 21.357787 },
    { lat: 49.290335, lng: 21.3580442 },
    { lat: 49.2904498, lng: 21.3581224 },
    { lat: 49.2905221, lng: 21.3583171 },
    { lat: 49.2903387, lng: 21.3583979 },
    { lat: 49.2901385, lng: 21.3583174 },
    { lat: 49.2900524, lng: 21.3584895 },
    { lat: 49.2901106, lng: 21.358554 },
    { lat: 49.2900706, lng: 21.3586505 },
    { lat: 49.2896728, lng: 21.3582841 },
    { lat: 49.2875432, lng: 21.3609074 },
    { lat: 49.2870678, lng: 21.3612701 },
    { lat: 49.2871933, lng: 21.3614395 },
    { lat: 49.287051, lng: 21.3614281 },
    { lat: 49.2868, lng: 21.3617961 },
    { lat: 49.2866108, lng: 21.3617199 },
    { lat: 49.2863743, lng: 21.3621255 },
    { lat: 49.286207, lng: 21.3620243 },
    { lat: 49.2861784, lng: 21.3622 },
    { lat: 49.2860262, lng: 21.3623064 },
    { lat: 49.2858754, lng: 21.3625698 },
    { lat: 49.2851385, lng: 21.3632115 },
    { lat: 49.2851992, lng: 21.3633663 },
    { lat: 49.2850731, lng: 21.3633271 },
    { lat: 49.2850071, lng: 21.3634877 },
    { lat: 49.2849985, lng: 21.363728 },
    { lat: 49.285092, lng: 21.3639983 },
    { lat: 49.285032, lng: 21.3639116 },
    { lat: 49.2848826, lng: 21.3646806 },
    { lat: 49.2847733, lng: 21.3653596 },
    { lat: 49.2847763, lng: 21.3657637 },
    { lat: 49.2846293, lng: 21.3660773 },
    { lat: 49.2844369, lng: 21.3662701 },
    { lat: 49.2841719, lng: 21.3671231 },
    { lat: 49.284124, lng: 21.3677103 },
    { lat: 49.2837957, lng: 21.3683676 },
    { lat: 49.2844683, lng: 21.3693872 },
    { lat: 49.2846349, lng: 21.3693526 },
    { lat: 49.2847758, lng: 21.3690145 },
    { lat: 49.2848611, lng: 21.369013 },
    { lat: 49.2849391, lng: 21.3692923 },
    { lat: 49.2851817, lng: 21.3695076 },
    { lat: 49.2859089, lng: 21.3693255 },
    { lat: 49.2860428, lng: 21.3698611 },
    { lat: 49.2861998, lng: 21.3699948 },
    { lat: 49.2863161, lng: 21.3698152 },
    { lat: 49.2864392, lng: 21.3700226 },
    { lat: 49.2864819, lng: 21.3702871 },
    { lat: 49.2866582, lng: 21.3705794 },
    { lat: 49.2868265, lng: 21.3711376 },
    { lat: 49.2871832, lng: 21.3718641 },
    { lat: 49.2872123, lng: 21.3720495 },
    { lat: 49.2872915, lng: 21.3719853 },
    { lat: 49.28755, lng: 21.372166 },
    { lat: 49.2875426, lng: 21.372257 },
    { lat: 49.2873438, lng: 21.3725764 },
    { lat: 49.2874165, lng: 21.3727876 },
    { lat: 49.2872681, lng: 21.3732865 },
    { lat: 49.2872916, lng: 21.3733621 },
    { lat: 49.2874781, lng: 21.3732364 },
    { lat: 49.2875343, lng: 21.3733911 },
    { lat: 49.2874862, lng: 21.3735879 },
    { lat: 49.2875235, lng: 21.3737439 },
    { lat: 49.2876701, lng: 21.3739897 },
    { lat: 49.2876622, lng: 21.374178 },
    { lat: 49.2874524, lng: 21.3742318 },
    { lat: 49.2877877, lng: 21.3749612 },
    { lat: 49.2878247, lng: 21.3751439 },
    { lat: 49.2879801, lng: 21.3752669 },
    { lat: 49.2881298, lng: 21.3755374 },
    { lat: 49.2888089, lng: 21.3759414 },
    { lat: 49.2889694, lng: 21.3759217 },
    { lat: 49.2891996, lng: 21.3757591 },
    { lat: 49.289632, lng: 21.3757358 },
    { lat: 49.2898284, lng: 21.3758725 },
    { lat: 49.2900401, lng: 21.3758723 },
    { lat: 49.2901953, lng: 21.3757203 },
    { lat: 49.29043, lng: 21.3761259 },
    { lat: 49.290579, lng: 21.3762543 },
    { lat: 49.291039, lng: 21.3762143 },
    { lat: 49.2909404, lng: 21.3775623 },
    { lat: 49.2912367, lng: 21.3777311 },
    { lat: 49.2909927, lng: 21.3781841 },
    { lat: 49.2913772, lng: 21.3783299 },
    { lat: 49.2912864, lng: 21.3787276 },
    { lat: 49.2913294, lng: 21.3801069 },
    { lat: 49.2912578, lng: 21.3806208 },
    { lat: 49.2912693, lng: 21.3811628 },
    { lat: 49.2911992, lng: 21.3817991 },
    { lat: 49.2906846, lng: 21.3842271 },
    { lat: 49.2906105, lng: 21.3841937 },
    { lat: 49.2905372, lng: 21.3842672 },
    { lat: 49.2903668, lng: 21.3846696 },
    { lat: 49.2904731, lng: 21.3847229 },
    { lat: 49.2903773, lng: 21.3849544 },
    { lat: 49.2901952, lng: 21.3851114 },
    { lat: 49.2901537, lng: 21.3853035 },
    { lat: 49.2898516, lng: 21.385396 },
    { lat: 49.289762, lng: 21.3855633 },
    { lat: 49.2895718, lng: 21.3855641 },
    { lat: 49.2892805, lng: 21.3858725 },
    { lat: 49.2893371, lng: 21.3860348 },
    { lat: 49.2895549, lng: 21.386329 },
    { lat: 49.2902467, lng: 21.3863964 },
    { lat: 49.2916916, lng: 21.387098 },
    { lat: 49.2918294, lng: 21.3871919 },
    { lat: 49.2919928, lng: 21.3872387 },
    { lat: 49.2923949, lng: 21.3875658 },
    { lat: 49.2929962, lng: 21.3878809 },
    { lat: 49.2934763, lng: 21.3882534 },
    { lat: 49.2938132, lng: 21.3884318 },
    { lat: 49.2943368, lng: 21.3884783 },
    { lat: 49.2949182, lng: 21.388433 },
    { lat: 49.2959055, lng: 21.3887998 },
    { lat: 49.2959097, lng: 21.3887166 },
    { lat: 49.2960655, lng: 21.3886926 },
    { lat: 49.2962684, lng: 21.3880168 },
    { lat: 49.2964352, lng: 21.3876834 },
    { lat: 49.2968859, lng: 21.3868223 },
    { lat: 49.2976909, lng: 21.3855038 },
    { lat: 49.2985509, lng: 21.3844933 },
    { lat: 49.2990953, lng: 21.3841418 },
    { lat: 49.2996854, lng: 21.383661 },
    { lat: 49.3002443, lng: 21.3835793 },
    { lat: 49.3004441, lng: 21.3837523 },
    { lat: 49.3007807, lng: 21.3836862 },
    { lat: 49.3009275, lng: 21.3838174 },
    { lat: 49.3011026, lng: 21.3838396 },
    { lat: 49.301132, lng: 21.3842706 },
    { lat: 49.3012234, lng: 21.3842758 },
    { lat: 49.301302, lng: 21.3843832 },
    { lat: 49.3014816, lng: 21.3844127 },
    { lat: 49.301669, lng: 21.3846226 },
    { lat: 49.3017965, lng: 21.384563 },
    { lat: 49.3019181, lng: 21.3847547 },
    { lat: 49.302097, lng: 21.3845589 },
    { lat: 49.3023048, lng: 21.3844368 },
    { lat: 49.3023376, lng: 21.3842911 },
    { lat: 49.302402, lng: 21.3842625 },
    { lat: 49.3024888, lng: 21.3848398 },
    { lat: 49.3026956, lng: 21.3845274 },
    { lat: 49.3028319, lng: 21.3840018 },
    { lat: 49.3028898, lng: 21.384248 },
    { lat: 49.3030602, lng: 21.3842222 },
    { lat: 49.3030565, lng: 21.3841349 },
    { lat: 49.3033484, lng: 21.3841912 },
    { lat: 49.3037353, lng: 21.3845287 },
    { lat: 49.3040606, lng: 21.3845344 },
    { lat: 49.3048012, lng: 21.3843551 },
    { lat: 49.3050684, lng: 21.3844546 },
    { lat: 49.3053653, lng: 21.3844075 },
    { lat: 49.30547, lng: 21.3844486 },
    { lat: 49.3055788, lng: 21.3844382 },
    { lat: 49.3055803, lng: 21.3841432 },
    { lat: 49.3058857, lng: 21.3840829 },
    { lat: 49.306692, lng: 21.3837324 },
    { lat: 49.3069356, lng: 21.3837081 },
    { lat: 49.3070262, lng: 21.3834646 },
    { lat: 49.3075698, lng: 21.3832786 },
    { lat: 49.3079895, lng: 21.3820624 },
    { lat: 49.308186, lng: 21.382011 },
    { lat: 49.3082314, lng: 21.3820937 },
    { lat: 49.3083578, lng: 21.3817288 },
    { lat: 49.3087017, lng: 21.3812926 },
    { lat: 49.3104164, lng: 21.378094 },
    { lat: 49.3113466, lng: 21.3759864 },
    { lat: 49.3116047, lng: 21.3756989 },
    { lat: 49.3118016, lng: 21.3755807 },
    { lat: 49.3118907, lng: 21.3753225 },
    { lat: 49.311913, lng: 21.3744419 },
    { lat: 49.3132827, lng: 21.3728584 },
    { lat: 49.3137552, lng: 21.37282 },
    { lat: 49.3140549, lng: 21.3725245 },
    { lat: 49.3151146, lng: 21.3708083 },
    { lat: 49.3161609, lng: 21.3699693 },
    { lat: 49.3170403, lng: 21.3697632 },
    { lat: 49.3180106, lng: 21.3700095 },
    { lat: 49.3192471, lng: 21.3704715 },
    { lat: 49.3204261, lng: 21.3700845 },
    { lat: 49.3212176, lng: 21.3699937 },
    { lat: 49.3215373, lng: 21.3703042 },
    { lat: 49.3215479, lng: 21.3702403 },
    { lat: 49.3217171, lng: 21.3689605 },
    { lat: 49.321984, lng: 21.368061 },
    { lat: 49.3225431, lng: 21.3669486 },
    { lat: 49.3232353, lng: 21.3663095 },
    { lat: 49.3234953, lng: 21.36548 },
    { lat: 49.3235774, lng: 21.3651403 },
    { lat: 49.3237497, lng: 21.3636943 },
    { lat: 49.3237239, lng: 21.3627908 },
    { lat: 49.3236567, lng: 21.362266 },
    { lat: 49.3241738, lng: 21.360579 },
    { lat: 49.3245041, lng: 21.3599909 },
    { lat: 49.3251187, lng: 21.3591317 },
    { lat: 49.3254644, lng: 21.358035 },
    { lat: 49.3252648, lng: 21.3574587 },
    { lat: 49.3245667, lng: 21.3562267 },
    { lat: 49.3235107, lng: 21.353807 },
    { lat: 49.322912, lng: 21.3515656 },
    { lat: 49.3225115, lng: 21.3503213 },
    { lat: 49.3216515, lng: 21.3487906 },
    { lat: 49.3206212, lng: 21.3475516 },
    { lat: 49.3190431, lng: 21.3454059 },
    { lat: 49.318119, lng: 21.3444817 },
    { lat: 49.3152247, lng: 21.3454143 },
    { lat: 49.3147303, lng: 21.3446777 },
    { lat: 49.3146743, lng: 21.3443787 },
    { lat: 49.314309, lng: 21.344129 },
    { lat: 49.3140479, lng: 21.3435579 },
    { lat: 49.3138109, lng: 21.3432704 },
    { lat: 49.313423, lng: 21.3410891 },
    { lat: 49.3124807, lng: 21.3393666 },
    { lat: 49.3122553, lng: 21.338827 },
    { lat: 49.311493, lng: 21.3373714 },
    { lat: 49.3103606, lng: 21.3363755 },
    { lat: 49.3098108, lng: 21.3362718 },
    { lat: 49.3090951, lng: 21.3359276 },
    { lat: 49.3083026, lng: 21.3353141 },
    { lat: 49.3071313, lng: 21.3348536 },
    { lat: 49.305091, lng: 21.336987 },
    { lat: 49.3039502, lng: 21.3375587 },
    { lat: 49.303853, lng: 21.3379192 },
    { lat: 49.3035103, lng: 21.3386119 },
    { lat: 49.3033636, lng: 21.339243 },
    { lat: 49.3029912, lng: 21.3403361 },
    { lat: 49.3027584, lng: 21.3408654 },
    { lat: 49.3019798, lng: 21.3419984 },
    { lat: 49.3008282, lng: 21.3428826 },
    { lat: 49.3003031, lng: 21.343086 },
    { lat: 49.2998291, lng: 21.3433743 },
    { lat: 49.2995678, lng: 21.3441258 },
    { lat: 49.2992491, lng: 21.3442601 },
    { lat: 49.2991568, lng: 21.3445268 },
    { lat: 49.2989564, lng: 21.3442163 },
    { lat: 49.2988364, lng: 21.3443451 },
    { lat: 49.2986292, lng: 21.3443775 },
    { lat: 49.2976393, lng: 21.3448693 },
  ],
  StebníckaHuta: [
    { lat: 49.3962485, lng: 21.2702723 },
    { lat: 49.3962782, lng: 21.2700636 },
    { lat: 49.3970488, lng: 21.2692819 },
    { lat: 49.397168, lng: 21.2686875 },
    { lat: 49.3979536, lng: 21.2677714 },
    { lat: 49.3981207, lng: 21.2674514 },
    { lat: 49.3983534, lng: 21.2673369 },
    { lat: 49.3987517, lng: 21.2674103 },
    { lat: 49.3992016, lng: 21.2672547 },
    { lat: 49.3998272, lng: 21.2668564 },
    { lat: 49.4007993, lng: 21.2659638 },
    { lat: 49.401151, lng: 21.2667248 },
    { lat: 49.4014466, lng: 21.2670881 },
    { lat: 49.4015938, lng: 21.2668948 },
    { lat: 49.4023803, lng: 21.2670421 },
    { lat: 49.4031199, lng: 21.2676574 },
    { lat: 49.4045751, lng: 21.268257 },
    { lat: 49.404859, lng: 21.2680468 },
    { lat: 49.4050885, lng: 21.2680116 },
    { lat: 49.4053366, lng: 21.2678834 },
    { lat: 49.405881, lng: 21.2678055 },
    { lat: 49.4074721, lng: 21.2672925 },
    { lat: 49.4085494, lng: 21.2674285 },
    { lat: 49.4090234, lng: 21.2676562 },
    { lat: 49.4093479, lng: 21.2676364 },
    { lat: 49.4095703, lng: 21.2665131 },
    { lat: 49.4099262, lng: 21.2660414 },
    { lat: 49.4103693, lng: 21.2659603 },
    { lat: 49.4106636, lng: 21.2660578 },
    { lat: 49.410805, lng: 21.2663036 },
    { lat: 49.4112885, lng: 21.2665177 },
    { lat: 49.4116553, lng: 21.2669612 },
    { lat: 49.4119292, lng: 21.2671997 },
    { lat: 49.4121797, lng: 21.2666662 },
    { lat: 49.4126632, lng: 21.2660669 },
    { lat: 49.4134179, lng: 21.2653941 },
    { lat: 49.4136182, lng: 21.2650644 },
    { lat: 49.4145044, lng: 21.2646726 },
    { lat: 49.4158831, lng: 21.2646967 },
    { lat: 49.4167582, lng: 21.2635368 },
    { lat: 49.4171032, lng: 21.2631934 },
    { lat: 49.4175759, lng: 21.2630451 },
    { lat: 49.4178021, lng: 21.2627521 },
    { lat: 49.4180068, lng: 21.2627173 },
    { lat: 49.4180853, lng: 21.2628172 },
    { lat: 49.4184585, lng: 21.2628075 },
    { lat: 49.4187966, lng: 21.2630793 },
    { lat: 49.419879, lng: 21.2620501 },
    { lat: 49.4203153, lng: 21.2617823 },
    { lat: 49.4207527, lng: 21.2613661 },
    { lat: 49.4218412, lng: 21.2604979 },
    { lat: 49.4224129, lng: 21.2602084 },
    { lat: 49.4232071, lng: 21.259422 },
    { lat: 49.4235441, lng: 21.258932 },
    { lat: 49.4242315, lng: 21.2577425 },
    { lat: 49.424813, lng: 21.257149 },
    { lat: 49.4264071, lng: 21.2559228 },
    { lat: 49.4268747, lng: 21.2556841 },
    { lat: 49.4285714, lng: 21.2540933 },
    { lat: 49.4290717, lng: 21.2533841 },
    { lat: 49.4292847, lng: 21.2528743 },
    { lat: 49.429366, lng: 21.2523729 },
    { lat: 49.4297004, lng: 21.2521585 },
    { lat: 49.4298012, lng: 21.251844 },
    { lat: 49.4302757, lng: 21.2510994 },
    { lat: 49.4308119, lng: 21.2510465 },
    { lat: 49.4316985, lng: 21.2495757 },
    { lat: 49.4317985, lng: 21.2488636 },
    { lat: 49.4318136, lng: 21.2472283 },
    { lat: 49.432222, lng: 21.2464883 },
    { lat: 49.4324395, lng: 21.2456889 },
    { lat: 49.432476, lng: 21.2445986 },
    { lat: 49.4326512, lng: 21.2439285 },
    { lat: 49.4330303, lng: 21.2431089 },
    { lat: 49.4332629, lng: 21.2424422 },
    { lat: 49.4338616, lng: 21.2414499 },
    { lat: 49.4341837, lng: 21.2406693 },
    { lat: 49.4343897, lng: 21.2402084 },
    { lat: 49.4347344, lng: 21.2396844 },
    { lat: 49.4349803, lng: 21.2391962 },
    { lat: 49.4352819, lng: 21.2383007 },
    { lat: 49.435284, lng: 21.238293 },
    { lat: 49.4354985, lng: 21.2377241 },
    { lat: 49.4352986, lng: 21.2373924 },
    { lat: 49.4354089, lng: 21.237002 },
    { lat: 49.4359374, lng: 21.2361072 },
    { lat: 49.436553, lng: 21.2355969 },
    { lat: 49.4370419, lng: 21.2349104 },
    { lat: 49.437508, lng: 21.2341026 },
    { lat: 49.436759, lng: 21.2334792 },
    { lat: 49.436688, lng: 21.232932 },
    { lat: 49.4365452, lng: 21.2327486 },
    { lat: 49.4359011, lng: 21.232181 },
    { lat: 49.4354573, lng: 21.2318971 },
    { lat: 49.4350106, lng: 21.232127 },
    { lat: 49.4344233, lng: 21.2322455 },
    { lat: 49.4340764, lng: 21.2324965 },
    { lat: 49.4335892, lng: 21.2323351 },
    { lat: 49.4334583, lng: 21.2323893 },
    { lat: 49.4330251, lng: 21.2320903 },
    { lat: 49.432458, lng: 21.231496 },
    { lat: 49.4324552, lng: 21.2314913 },
    { lat: 49.432083, lng: 21.231122 },
    { lat: 49.432075, lng: 21.2311115 },
    { lat: 49.4316799, lng: 21.2306373 },
    { lat: 49.4311894, lng: 21.230158 },
    { lat: 49.4307234, lng: 21.2298255 },
    { lat: 49.430461, lng: 21.2294822 },
    { lat: 49.4301088, lng: 21.2292681 },
    { lat: 49.4297244, lng: 21.2291243 },
    { lat: 49.4292352, lng: 21.2287762 },
    { lat: 49.4286689, lng: 21.2281032 },
    { lat: 49.4284389, lng: 21.2276469 },
    { lat: 49.4280172, lng: 21.2270912 },
    { lat: 49.4281412, lng: 21.2267281 },
    { lat: 49.4279024, lng: 21.2263347 },
    { lat: 49.427898, lng: 21.226329 },
    { lat: 49.4275228, lng: 21.2256305 },
    { lat: 49.4270487, lng: 21.224629 },
    { lat: 49.4267321, lng: 21.2240629 },
    { lat: 49.4264984, lng: 21.2241262 },
    { lat: 49.4261998, lng: 21.2237031 },
    { lat: 49.4260447, lng: 21.2238228 },
    { lat: 49.4255179, lng: 21.2234581 },
    { lat: 49.4252512, lng: 21.2236169 },
    { lat: 49.424999, lng: 21.2237284 },
    { lat: 49.4246783, lng: 21.2236138 },
    { lat: 49.4240613, lng: 21.2243096 },
    { lat: 49.4236745, lng: 21.224509 },
    { lat: 49.4235635, lng: 21.2243062 },
    { lat: 49.4232465, lng: 21.2244301 },
    { lat: 49.4228879, lng: 21.2249153 },
    { lat: 49.4225446, lng: 21.2251992 },
    { lat: 49.4223729, lng: 21.2254428 },
    { lat: 49.4222728, lng: 21.225707 },
    { lat: 49.4220524, lng: 21.2259584 },
    { lat: 49.4218439, lng: 21.2263911 },
    { lat: 49.4215528, lng: 21.2265191 },
    { lat: 49.4214289, lng: 21.22666 },
    { lat: 49.4204772, lng: 21.2268669 },
    { lat: 49.4204209, lng: 21.2267562 },
    { lat: 49.4201962, lng: 21.2268242 },
    { lat: 49.4199054, lng: 21.2270558 },
    { lat: 49.4195872, lng: 21.2268362 },
    { lat: 49.4195929, lng: 21.2266802 },
    { lat: 49.4194501, lng: 21.226426 },
    { lat: 49.416928, lng: 21.2295549 },
    { lat: 49.4124351, lng: 21.2345065 },
    { lat: 49.4111964, lng: 21.2359745 },
    { lat: 49.4089269, lng: 21.2385023 },
    { lat: 49.4083315, lng: 21.2390838 },
    { lat: 49.4077299, lng: 21.2395718 },
    { lat: 49.4073774, lng: 21.2401174 },
    { lat: 49.4057129, lng: 21.2416837 },
    { lat: 49.4044284, lng: 21.2424299 },
    { lat: 49.4034379, lng: 21.2425288 },
    { lat: 49.4030624, lng: 21.2424308 },
    { lat: 49.4026348, lng: 21.2421728 },
    { lat: 49.4025708, lng: 21.2412905 },
    { lat: 49.4023678, lng: 21.2403571 },
    { lat: 49.4020818, lng: 21.2399091 },
    { lat: 49.4020896, lng: 21.2391311 },
    { lat: 49.4018626, lng: 21.2380791 },
    { lat: 49.4015875, lng: 21.2376498 },
    { lat: 49.4010855, lng: 21.2373156 },
    { lat: 49.4005017, lng: 21.2372442 },
    { lat: 49.4001547, lng: 21.2372684 },
    { lat: 49.3997252, lng: 21.2374969 },
    { lat: 49.3993581, lng: 21.2372657 },
    { lat: 49.3989842, lng: 21.2376564 },
    { lat: 49.3978718, lng: 21.2379654 },
    { lat: 49.3970254, lng: 21.23641 },
    { lat: 49.3971313, lng: 21.2353093 },
    { lat: 49.3968323, lng: 21.2347023 },
    { lat: 49.3951658, lng: 21.2338834 },
    { lat: 49.3944507, lng: 21.2340446 },
    { lat: 49.3936517, lng: 21.2343377 },
    { lat: 49.3926744, lng: 21.2357043 },
    { lat: 49.3922905, lng: 21.2364352 },
    { lat: 49.3914482, lng: 21.2376997 },
    { lat: 49.3915009, lng: 21.238467 },
    { lat: 49.391166, lng: 21.2393934 },
    { lat: 49.3912689, lng: 21.24 },
    { lat: 49.3905858, lng: 21.2408737 },
    { lat: 49.3907904, lng: 21.2413119 },
    { lat: 49.3905546, lng: 21.2417352 },
    { lat: 49.3906477, lng: 21.2421279 },
    { lat: 49.3905638, lng: 21.2431384 },
    { lat: 49.3906754, lng: 21.2434035 },
    { lat: 49.3906001, lng: 21.2439546 },
    { lat: 49.3903759, lng: 21.244536 },
    { lat: 49.390572, lng: 21.2459071 },
    { lat: 49.3901654, lng: 21.2467413 },
    { lat: 49.3900643, lng: 21.2482959 },
    { lat: 49.3903037, lng: 21.2490246 },
    { lat: 49.3903723, lng: 21.2495415 },
    { lat: 49.3905619, lng: 21.2503717 },
    { lat: 49.3908183, lng: 21.2504346 },
    { lat: 49.3908084, lng: 21.250591 },
    { lat: 49.3917393, lng: 21.2504607 },
    { lat: 49.3924348, lng: 21.2502068 },
    { lat: 49.3933866, lng: 21.2496889 },
    { lat: 49.393919, lng: 21.2494749 },
    { lat: 49.3968666, lng: 21.2486167 },
    { lat: 49.3966272, lng: 21.2492183 },
    { lat: 49.3965656, lng: 21.2494861 },
    { lat: 49.3964337, lng: 21.2495916 },
    { lat: 49.3961249, lng: 21.2506822 },
    { lat: 49.39694, lng: 21.2505816 },
    { lat: 49.397314, lng: 21.2508167 },
    { lat: 49.3986316, lng: 21.250011 },
    { lat: 49.398668, lng: 21.2506167 },
    { lat: 49.3988939, lng: 21.2513036 },
    { lat: 49.398854, lng: 21.2524021 },
    { lat: 49.3987027, lng: 21.2527904 },
    { lat: 49.3987168, lng: 21.2533612 },
    { lat: 49.3990385, lng: 21.254022 },
    { lat: 49.3985152, lng: 21.2569733 },
    { lat: 49.3986103, lng: 21.2575083 },
    { lat: 49.3981951, lng: 21.2590964 },
    { lat: 49.3980065, lng: 21.2592923 },
    { lat: 49.3973242, lng: 21.2614211 },
    { lat: 49.3969327, lng: 21.2624959 },
    { lat: 49.3964487, lng: 21.2631954 },
    { lat: 49.3964738, lng: 21.2646483 },
    { lat: 49.3966112, lng: 21.2659895 },
    { lat: 49.3961619, lng: 21.2672327 },
    { lat: 49.3962485, lng: 21.2702723 },
  ],
  Kochanovce: [
    { lat: 49.1833521, lng: 21.380657 },
    { lat: 49.1841493, lng: 21.3837666 },
    { lat: 49.1864012, lng: 21.3886702 },
    { lat: 49.1869765, lng: 21.3892149 },
    { lat: 49.1874406, lng: 21.3892763 },
    { lat: 49.1875716, lng: 21.3909359 },
    { lat: 49.1884205, lng: 21.3924072 },
    { lat: 49.1892235, lng: 21.3958777 },
    { lat: 49.1898265, lng: 21.3971724 },
    { lat: 49.1900132, lng: 21.3978621 },
    { lat: 49.1900789, lng: 21.3984134 },
    { lat: 49.1900733, lng: 21.3991749 },
    { lat: 49.1904593, lng: 21.4005996 },
    { lat: 49.1909941, lng: 21.4002641 },
    { lat: 49.191374, lng: 21.4004051 },
    { lat: 49.191427, lng: 21.4008687 },
    { lat: 49.1917331, lng: 21.4012557 },
    { lat: 49.1917993, lng: 21.4015213 },
    { lat: 49.1916817, lng: 21.4019139 },
    { lat: 49.1921109, lng: 21.4024008 },
    { lat: 49.1921105, lng: 21.4026929 },
    { lat: 49.1923295, lng: 21.4027161 },
    { lat: 49.1937611, lng: 21.4016952 },
    { lat: 49.1955424, lng: 21.3994039 },
    { lat: 49.1946173, lng: 21.3979432 },
    { lat: 49.1921172, lng: 21.3976682 },
    { lat: 49.1914555, lng: 21.3973987 },
    { lat: 49.19139, lng: 21.397314 },
    { lat: 49.1913852, lng: 21.3967661 },
    { lat: 49.1912728, lng: 21.3956989 },
    { lat: 49.1915589, lng: 21.3952156 },
    { lat: 49.1917651, lng: 21.3942274 },
    { lat: 49.1918879, lng: 21.394001 },
    { lat: 49.1919038, lng: 21.3937805 },
    { lat: 49.1917947, lng: 21.393398 },
    { lat: 49.1916076, lng: 21.3931917 },
    { lat: 49.1914019, lng: 21.3931634 },
    { lat: 49.1912284, lng: 21.3932702 },
    { lat: 49.1911634, lng: 21.393088 },
    { lat: 49.1912853, lng: 21.3927994 },
    { lat: 49.1914854, lng: 21.3927487 },
    { lat: 49.1921512, lng: 21.3921735 },
    { lat: 49.1922049, lng: 21.3918184 },
    { lat: 49.1924101, lng: 21.3916374 },
    { lat: 49.193051, lng: 21.3915521 },
    { lat: 49.193336, lng: 21.3916718 },
    { lat: 49.1952251, lng: 21.3915215 },
    { lat: 49.1953297, lng: 21.3909107 },
    { lat: 49.1954513, lng: 21.390566 },
    { lat: 49.1955647, lng: 21.3899085 },
    { lat: 49.1955635, lng: 21.3896762 },
    { lat: 49.1960104, lng: 21.3881986 },
    { lat: 49.1959823, lng: 21.3878951 },
    { lat: 49.1960013, lng: 21.3877801 },
    { lat: 49.1960672, lng: 21.387718 },
    { lat: 49.1960149, lng: 21.3875133 },
    { lat: 49.1960517, lng: 21.3872726 },
    { lat: 49.1959495, lng: 21.387012 },
    { lat: 49.1960335, lng: 21.3867274 },
    { lat: 49.1960097, lng: 21.3863847 },
    { lat: 49.1962947, lng: 21.3858241 },
    { lat: 49.1963389, lng: 21.3854523 },
    { lat: 49.1965958, lng: 21.3846651 },
    { lat: 49.1962632, lng: 21.3843717 },
    { lat: 49.196299, lng: 21.3841696 },
    { lat: 49.1966045, lng: 21.3838871 },
    { lat: 49.196605, lng: 21.3837151 },
    { lat: 49.1964852, lng: 21.3836981 },
    { lat: 49.1963588, lng: 21.3834881 },
    { lat: 49.1963914, lng: 21.3833387 },
    { lat: 49.1966654, lng: 21.383013 },
    { lat: 49.1967506, lng: 21.3826639 },
    { lat: 49.1966115, lng: 21.3824868 },
    { lat: 49.1966348, lng: 21.3821348 },
    { lat: 49.1968946, lng: 21.382014 },
    { lat: 49.1969651, lng: 21.3814645 },
    { lat: 49.1968804, lng: 21.3811095 },
    { lat: 49.1968897, lng: 21.3806656 },
    { lat: 49.1970624, lng: 21.3803429 },
    { lat: 49.1971536, lng: 21.3799988 },
    { lat: 49.1970492, lng: 21.3798175 },
    { lat: 49.1968424, lng: 21.3797126 },
    { lat: 49.1967045, lng: 21.3791547 },
    { lat: 49.1963684, lng: 21.3787515 },
    { lat: 49.1959033, lng: 21.3783151 },
    { lat: 49.1959935, lng: 21.3780546 },
    { lat: 49.1958598, lng: 21.3772971 },
    { lat: 49.1958621, lng: 21.376616 },
    { lat: 49.1957212, lng: 21.3764918 },
    { lat: 49.1955623, lng: 21.3760711 },
    { lat: 49.1956475, lng: 21.3752921 },
    { lat: 49.1953412, lng: 21.3740709 },
    { lat: 49.1952552, lng: 21.3728393 },
    { lat: 49.195024, lng: 21.3719807 },
    { lat: 49.1950735, lng: 21.3704595 },
    { lat: 49.1957965, lng: 21.3702967 },
    { lat: 49.1966797, lng: 21.3699868 },
    { lat: 49.1968708, lng: 21.370065 },
    { lat: 49.1972268, lng: 21.3697361 },
    { lat: 49.1981899, lng: 21.3693542 },
    { lat: 49.1984798, lng: 21.3693435 },
    { lat: 49.1996548, lng: 21.3689333 },
    { lat: 49.2012602, lng: 21.3682828 },
    { lat: 49.2014736, lng: 21.3680447 },
    { lat: 49.2015874, lng: 21.3680116 },
    { lat: 49.2021724, lng: 21.367479 },
    { lat: 49.2024295, lng: 21.3675009 },
    { lat: 49.2024395, lng: 21.3667327 },
    { lat: 49.2028345, lng: 21.3641976 },
    { lat: 49.2027611, lng: 21.3633956 },
    { lat: 49.2028042, lng: 21.3620326 },
    { lat: 49.2027678, lng: 21.3614594 },
    { lat: 49.2025434, lng: 21.3599608 },
    { lat: 49.2024845, lng: 21.3600268 },
    { lat: 49.2019987, lng: 21.3601104 },
    { lat: 49.2016349, lng: 21.3604342 },
    { lat: 49.2014593, lng: 21.3611911 },
    { lat: 49.2009679, lng: 21.3621084 },
    { lat: 49.2006016, lng: 21.3631149 },
    { lat: 49.2003044, lng: 21.363642 },
    { lat: 49.2001197, lng: 21.3637429 },
    { lat: 49.19971, lng: 21.3638165 },
    { lat: 49.1998993, lng: 21.364504 },
    { lat: 49.2001011, lng: 21.3649016 },
    { lat: 49.2002523, lng: 21.3653337 },
    { lat: 49.1994009, lng: 21.3656832 },
    { lat: 49.1983759, lng: 21.3667128 },
    { lat: 49.197766, lng: 21.3671593 },
    { lat: 49.1971831, lng: 21.3675384 },
    { lat: 49.1966226, lng: 21.3677998 },
    { lat: 49.1959315, lng: 21.3683213 },
    { lat: 49.1947969, lng: 21.3690259 },
    { lat: 49.1940505, lng: 21.3664671 },
    { lat: 49.1942836, lng: 21.3661722 },
    { lat: 49.193728, lng: 21.364886 },
    { lat: 49.1945645, lng: 21.3642573 },
    { lat: 49.1952593, lng: 21.3633693 },
    { lat: 49.1966947, lng: 21.3617342 },
    { lat: 49.1967849, lng: 21.3614758 },
    { lat: 49.1965025, lng: 21.3609456 },
    { lat: 49.1962449, lng: 21.360186 },
    { lat: 49.1961478, lng: 21.3600617 },
    { lat: 49.1961302, lng: 21.3595892 },
    { lat: 49.1969834, lng: 21.3576673 },
    { lat: 49.196981, lng: 21.3566738 },
    { lat: 49.1970925, lng: 21.3553979 },
    { lat: 49.1968147, lng: 21.3545306 },
    { lat: 49.1965613, lng: 21.3541046 },
    { lat: 49.196734, lng: 21.3539351 },
    { lat: 49.1966853, lng: 21.3537719 },
    { lat: 49.1966157, lng: 21.3538184 },
    { lat: 49.1957614, lng: 21.351573 },
    { lat: 49.1950945, lng: 21.3502622 },
    { lat: 49.1949506, lng: 21.3504193 },
    { lat: 49.1938425, lng: 21.3500098 },
    { lat: 49.1938277, lng: 21.3497403 },
    { lat: 49.1935818, lng: 21.3496084 },
    { lat: 49.1931794, lng: 21.3497669 },
    { lat: 49.1921517, lng: 21.3504732 },
    { lat: 49.1920248, lng: 21.3506676 },
    { lat: 49.1920719, lng: 21.3509668 },
    { lat: 49.1919582, lng: 21.3513078 },
    { lat: 49.1916902, lng: 21.3513325 },
    { lat: 49.1914673, lng: 21.3512509 },
    { lat: 49.1908787, lng: 21.3519266 },
    { lat: 49.1905538, lng: 21.3525063 },
    { lat: 49.1902902, lng: 21.3521879 },
    { lat: 49.1899769, lng: 21.3521733 },
    { lat: 49.1896621, lng: 21.3515379 },
    { lat: 49.1887566, lng: 21.3513604 },
    { lat: 49.1885466, lng: 21.3514911 },
    { lat: 49.1879495, lng: 21.3498728 },
    { lat: 49.1872939, lng: 21.3498917 },
    { lat: 49.1869473, lng: 21.3502211 },
    { lat: 49.1862595, lng: 21.3501012 },
    { lat: 49.1858806, lng: 21.3497703 },
    { lat: 49.1857369, lng: 21.349781 },
    { lat: 49.1855328, lng: 21.3487492 },
    { lat: 49.1839647, lng: 21.3472883 },
    { lat: 49.182536, lng: 21.3488387 },
    { lat: 49.1812289, lng: 21.3491164 },
    { lat: 49.1801943, lng: 21.3504228 },
    { lat: 49.1796558, lng: 21.351871 },
    { lat: 49.1793738, lng: 21.3517359 },
    { lat: 49.1790658, lng: 21.3526114 },
    { lat: 49.1791082, lng: 21.3528246 },
    { lat: 49.1790799, lng: 21.3529345 },
    { lat: 49.178972, lng: 21.3530085 },
    { lat: 49.1788474, lng: 21.3535918 },
    { lat: 49.1789232, lng: 21.3538624 },
    { lat: 49.1788882, lng: 21.3541299 },
    { lat: 49.1787601, lng: 21.3543556 },
    { lat: 49.1787445, lng: 21.3547041 },
    { lat: 49.1785895, lng: 21.3549393 },
    { lat: 49.1786014, lng: 21.3551467 },
    { lat: 49.1785159, lng: 21.3553022 },
    { lat: 49.1785099, lng: 21.3557083 },
    { lat: 49.178005, lng: 21.3568876 },
    { lat: 49.1779352, lng: 21.3573078 },
    { lat: 49.1776896, lng: 21.3581264 },
    { lat: 49.1768071, lng: 21.3593634 },
    { lat: 49.17497, lng: 21.3612601 },
    { lat: 49.1751116, lng: 21.3624715 },
    { lat: 49.1750088, lng: 21.3632072 },
    { lat: 49.1742693, lng: 21.3641487 },
    { lat: 49.1752765, lng: 21.3652202 },
    { lat: 49.1764081, lng: 21.3669182 },
    { lat: 49.1769107, lng: 21.3661859 },
    { lat: 49.1778724, lng: 21.3672325 },
    { lat: 49.1784262, lng: 21.3664866 },
    { lat: 49.1800653, lng: 21.3684475 },
    { lat: 49.1809946, lng: 21.369239 },
    { lat: 49.1813284, lng: 21.3694203 },
    { lat: 49.1815405, lng: 21.3697042 },
    { lat: 49.1811841, lng: 21.3704722 },
    { lat: 49.1813157, lng: 21.3708152 },
    { lat: 49.1820295, lng: 21.3723168 },
    { lat: 49.1835083, lng: 21.3740359 },
    { lat: 49.1830944, lng: 21.3755628 },
    { lat: 49.1829867, lng: 21.3766316 },
    { lat: 49.1833521, lng: 21.380657 },
  ],
  VyšnýTvarožec: [
    { lat: 49.3906036, lng: 21.1686792 },
    { lat: 49.3890407, lng: 21.1705545 },
    { lat: 49.3882917, lng: 21.1717218 },
    { lat: 49.3881807, lng: 21.1721637 },
    { lat: 49.3878895, lng: 21.1727503 },
    { lat: 49.3873648, lng: 21.1733854 },
    { lat: 49.3870609, lng: 21.1733473 },
    { lat: 49.3864408, lng: 21.1737022 },
    { lat: 49.3853239, lng: 21.1748353 },
    { lat: 49.3849753, lng: 21.1750598 },
    { lat: 49.3842561, lng: 21.1759188 },
    { lat: 49.3840071, lng: 21.1769949 },
    { lat: 49.3842051, lng: 21.1776052 },
    { lat: 49.3838094, lng: 21.1786634 },
    { lat: 49.3824305, lng: 21.1809584 },
    { lat: 49.3822171, lng: 21.1814027 },
    { lat: 49.3815644, lng: 21.1823424 },
    { lat: 49.3810461, lng: 21.1832946 },
    { lat: 49.3803848, lng: 21.1847151 },
    { lat: 49.3798486, lng: 21.1856769 },
    { lat: 49.3792721, lng: 21.1864599 },
    { lat: 49.3787508, lng: 21.1870263 },
    { lat: 49.3785451, lng: 21.1875035 },
    { lat: 49.3776763, lng: 21.1885884 },
    { lat: 49.3767936, lng: 21.1917964 },
    { lat: 49.3768244, lng: 21.1919159 },
    { lat: 49.3765988, lng: 21.1938569 },
    { lat: 49.3763527, lng: 21.1953189 },
    { lat: 49.376103, lng: 21.1961538 },
    { lat: 49.3759521, lng: 21.1973181 },
    { lat: 49.3755965, lng: 21.1989151 },
    { lat: 49.3755363, lng: 21.199582 },
    { lat: 49.3754581, lng: 21.1997065 },
    { lat: 49.3754152, lng: 21.2000722 },
    { lat: 49.3752478, lng: 21.2005165 },
    { lat: 49.3751615, lng: 21.201687 },
    { lat: 49.3752556, lng: 21.2022225 },
    { lat: 49.3752051, lng: 21.2028775 },
    { lat: 49.3749538, lng: 21.2040671 },
    { lat: 49.3747849, lng: 21.2058501 },
    { lat: 49.3740225, lng: 21.208225 },
    { lat: 49.3742934, lng: 21.2107675 },
    { lat: 49.3747339, lng: 21.2127173 },
    { lat: 49.3748006, lng: 21.2151429 },
    { lat: 49.3746175, lng: 21.2164611 },
    { lat: 49.3743677, lng: 21.2174526 },
    { lat: 49.3737875, lng: 21.219215 },
    { lat: 49.3737607, lng: 21.2197358 },
    { lat: 49.3742186, lng: 21.219845 },
    { lat: 49.3749962, lng: 21.2198459 },
    { lat: 49.3767902, lng: 21.2200052 },
    { lat: 49.3773075, lng: 21.2198523 },
    { lat: 49.3778008, lng: 21.2193588 },
    { lat: 49.3781195, lng: 21.2187797 },
    { lat: 49.3786305, lng: 21.2183477 },
    { lat: 49.3808246, lng: 21.2172199 },
    { lat: 49.3814372, lng: 21.2165429 },
    { lat: 49.3831213, lng: 21.2166097 },
    { lat: 49.3834309, lng: 21.216397 },
    { lat: 49.3844488, lng: 21.2159767 },
    { lat: 49.3853741, lng: 21.2159224 },
    { lat: 49.3862275, lng: 21.2167065 },
    { lat: 49.38668, lng: 21.2169667 },
    { lat: 49.3871453, lng: 21.2173549 },
    { lat: 49.3879476, lng: 21.217467 },
    { lat: 49.3881817, lng: 21.2177206 },
    { lat: 49.3894547, lng: 21.2165656 },
    { lat: 49.3902192, lng: 21.2163702 },
    { lat: 49.3904831, lng: 21.2163901 },
    { lat: 49.3909578, lng: 21.2162778 },
    { lat: 49.3918402, lng: 21.2152626 },
    { lat: 49.3921584, lng: 21.2145262 },
    { lat: 49.3922013, lng: 21.2136522 },
    { lat: 49.3923621, lng: 21.2132873 },
    { lat: 49.3926184, lng: 21.2121859 },
    { lat: 49.3928181, lng: 21.211795 },
    { lat: 49.3928294, lng: 21.2114752 },
    { lat: 49.3931804, lng: 21.2098312 },
    { lat: 49.3933594, lng: 21.2094332 },
    { lat: 49.3937166, lng: 21.2090837 },
    { lat: 49.393956, lng: 21.2085664 },
    { lat: 49.3946631, lng: 21.2075272 },
    { lat: 49.395222, lng: 21.2073722 },
    { lat: 49.3958297, lng: 21.2066808 },
    { lat: 49.3963209, lng: 21.205935 },
    { lat: 49.3969321, lng: 21.2070744 },
    { lat: 49.3973666, lng: 21.2076325 },
    { lat: 49.3980197, lng: 21.2081191 },
    { lat: 49.3986344, lng: 21.2088924 },
    { lat: 49.4015554, lng: 21.2097038 },
    { lat: 49.4018807, lng: 21.209708 },
    { lat: 49.4020762, lng: 21.2090064 },
    { lat: 49.402482, lng: 21.2081942 },
    { lat: 49.4025063, lng: 21.2080328 },
    { lat: 49.4029261, lng: 21.2068383 },
    { lat: 49.4031089, lng: 21.2063474 },
    { lat: 49.4032406, lng: 21.2058703 },
    { lat: 49.403243, lng: 21.205859 },
    { lat: 49.4034555, lng: 21.2052098 },
    { lat: 49.4037836, lng: 21.2039442 },
    { lat: 49.403807, lng: 21.203852 },
    { lat: 49.403916, lng: 21.2034646 },
    { lat: 49.4043033, lng: 21.2028017 },
    { lat: 49.4046371, lng: 21.2018413 },
    { lat: 49.4045561, lng: 21.201509 },
    { lat: 49.4044161, lng: 21.2010808 },
    { lat: 49.4042993, lng: 21.200957 },
    { lat: 49.4041843, lng: 21.200683 },
    { lat: 49.4042259, lng: 21.200286 },
    { lat: 49.40408, lng: 21.1999058 },
    { lat: 49.4039618, lng: 21.1996901 },
    { lat: 49.403947, lng: 21.1994262 },
    { lat: 49.403835, lng: 21.1989427 },
    { lat: 49.403845, lng: 21.1983865 },
    { lat: 49.4039471, lng: 21.1982777 },
    { lat: 49.4038503, lng: 21.197939 },
    { lat: 49.4035643, lng: 21.1973901 },
    { lat: 49.403509, lng: 21.1970484 },
    { lat: 49.4032639, lng: 21.1965334 },
    { lat: 49.4029382, lng: 21.1960288 },
    { lat: 49.4029382, lng: 21.195904 },
    { lat: 49.4026512, lng: 21.1952312 },
    { lat: 49.4025689, lng: 21.1950695 },
    { lat: 49.4026339, lng: 21.1948971 },
    { lat: 49.402636, lng: 21.194501 },
    { lat: 49.4026343, lng: 21.1944924 },
    { lat: 49.4025824, lng: 21.1941868 },
    { lat: 49.402218, lng: 21.1937353 },
    { lat: 49.4017329, lng: 21.1929289 },
    { lat: 49.4013632, lng: 21.1925564 },
    { lat: 49.4009926, lng: 21.1921278 },
    { lat: 49.40128, lng: 21.190663 },
    { lat: 49.4012819, lng: 21.1906576 },
    { lat: 49.4013663, lng: 21.1900506 },
    { lat: 49.401366, lng: 21.190039 },
    { lat: 49.4013651, lng: 21.1890885 },
    { lat: 49.4016239, lng: 21.1881334 },
    { lat: 49.40163, lng: 21.188109 },
    { lat: 49.4017446, lng: 21.1876106 },
    { lat: 49.4016935, lng: 21.1874583 },
    { lat: 49.4019036, lng: 21.1867759 },
    { lat: 49.4024475, lng: 21.1849022 },
    { lat: 49.4024134, lng: 21.1847312 },
    { lat: 49.4027423, lng: 21.1842241 },
    { lat: 49.402835, lng: 21.1835504 },
    { lat: 49.4021531, lng: 21.1821552 },
    { lat: 49.4020036, lng: 21.1819274 },
    { lat: 49.4021273, lng: 21.1816734 },
    { lat: 49.4020952, lng: 21.1814704 },
    { lat: 49.4021665, lng: 21.1810125 },
    { lat: 49.402129, lng: 21.1804716 },
    { lat: 49.4021684, lng: 21.1800294 },
    { lat: 49.402028, lng: 21.1774298 },
    { lat: 49.4018033, lng: 21.1763544 },
    { lat: 49.4015714, lng: 21.1759946 },
    { lat: 49.4003707, lng: 21.1757334 },
    { lat: 49.3999399, lng: 21.1754893 },
    { lat: 49.3990429, lng: 21.1752583 },
    { lat: 49.3979922, lng: 21.1747278 },
    { lat: 49.3971256, lng: 21.1741421 },
    { lat: 49.3965572, lng: 21.1736671 },
    { lat: 49.3958408, lng: 21.1734977 },
    { lat: 49.3952418, lng: 21.1735238 },
    { lat: 49.3946862, lng: 21.173135 },
    { lat: 49.3942668, lng: 21.1724537 },
    { lat: 49.3938584, lng: 21.1721292 },
    { lat: 49.3934242, lng: 21.1714526 },
    { lat: 49.3931914, lng: 21.1709791 },
    { lat: 49.3929593, lng: 21.1699284 },
    { lat: 49.39258, lng: 21.1686207 },
    { lat: 49.3924648, lng: 21.1678168 },
    { lat: 49.3920588, lng: 21.1667441 },
    { lat: 49.3914273, lng: 21.1680322 },
    { lat: 49.3906036, lng: 21.1686792 },
  ],
  Dubinné: [
    { lat: 49.2366195, lng: 21.4083071 },
    { lat: 49.2363093, lng: 21.4088244 },
    { lat: 49.2357979, lng: 21.4096053 },
    { lat: 49.2352331, lng: 21.4103423 },
    { lat: 49.2347694, lng: 21.4113334 },
    { lat: 49.2341952, lng: 21.4122798 },
    { lat: 49.2336448, lng: 21.4137769 },
    { lat: 49.2335365, lng: 21.4145709 },
    { lat: 49.2328295, lng: 21.4150597 },
    { lat: 49.2322464, lng: 21.4160067 },
    { lat: 49.2320135, lng: 21.4165488 },
    { lat: 49.231168, lng: 21.4177595 },
    { lat: 49.2311695, lng: 21.4180038 },
    { lat: 49.2312631, lng: 21.4180947 },
    { lat: 49.2315203, lng: 21.4184973 },
    { lat: 49.2320173, lng: 21.4200741 },
    { lat: 49.2318946, lng: 21.4209904 },
    { lat: 49.2319751, lng: 21.421899 },
    { lat: 49.2322349, lng: 21.4225164 },
    { lat: 49.2327629, lng: 21.4230688 },
    { lat: 49.2334792, lng: 21.4241805 },
    { lat: 49.2334663, lng: 21.4253971 },
    { lat: 49.2336217, lng: 21.4259414 },
    { lat: 49.2337753, lng: 21.4261948 },
    { lat: 49.2341475, lng: 21.4271186 },
    { lat: 49.2347048, lng: 21.4289064 },
    { lat: 49.235484, lng: 21.429434 },
    { lat: 49.2355966, lng: 21.4299354 },
    { lat: 49.2358375, lng: 21.4315691 },
    { lat: 49.2360197, lng: 21.4318379 },
    { lat: 49.2365723, lng: 21.4332388 },
    { lat: 49.2366203, lng: 21.43361 },
    { lat: 49.238384, lng: 21.4401493 },
    { lat: 49.2382977, lng: 21.4410945 },
    { lat: 49.2396926, lng: 21.4457076 },
    { lat: 49.2405797, lng: 21.4452243 },
    { lat: 49.2407187, lng: 21.4455559 },
    { lat: 49.2410881, lng: 21.4452206 },
    { lat: 49.24121, lng: 21.4456595 },
    { lat: 49.2410914, lng: 21.4459113 },
    { lat: 49.2411791, lng: 21.446264 },
    { lat: 49.2411173, lng: 21.4463827 },
    { lat: 49.241208, lng: 21.4468382 },
    { lat: 49.241384, lng: 21.4470477 },
    { lat: 49.2414004, lng: 21.4472637 },
    { lat: 49.2420209, lng: 21.4488051 },
    { lat: 49.2428637, lng: 21.4516483 },
    { lat: 49.2440273, lng: 21.4558269 },
    { lat: 49.2446016, lng: 21.4572912 },
    { lat: 49.2448983, lng: 21.4581787 },
    { lat: 49.2454327, lng: 21.4594621 },
    { lat: 49.2457226, lng: 21.4600789 },
    { lat: 49.245748, lng: 21.4599782 },
    { lat: 49.2460618, lng: 21.4596704 },
    { lat: 49.2464831, lng: 21.4589838 },
    { lat: 49.2468959, lng: 21.4590569 },
    { lat: 49.2469433, lng: 21.4588272 },
    { lat: 49.2468512, lng: 21.4581269 },
    { lat: 49.2469143, lng: 21.4576649 },
    { lat: 49.2468788, lng: 21.4572521 },
    { lat: 49.2466632, lng: 21.4569137 },
    { lat: 49.2465171, lng: 21.4562206 },
    { lat: 49.246714, lng: 21.4563788 },
    { lat: 49.2469035, lng: 21.4563513 },
    { lat: 49.2464116, lng: 21.4546321 },
    { lat: 49.2463711, lng: 21.4540472 },
    { lat: 49.246528, lng: 21.4538691 },
    { lat: 49.2463965, lng: 21.4534063 },
    { lat: 49.2466342, lng: 21.4532217 },
    { lat: 49.2467633, lng: 21.4526297 },
    { lat: 49.2468558, lng: 21.4528701 },
    { lat: 49.2472383, lng: 21.4527507 },
    { lat: 49.2475911, lng: 21.4525008 },
    { lat: 49.2477764, lng: 21.4512052 },
    { lat: 49.2479437, lng: 21.4509249 },
    { lat: 49.2476429, lng: 21.450557 },
    { lat: 49.2476884, lng: 21.449676 },
    { lat: 49.2478086, lng: 21.4494403 },
    { lat: 49.2477979, lng: 21.4492652 },
    { lat: 49.2476471, lng: 21.4491029 },
    { lat: 49.2475223, lng: 21.4491095 },
    { lat: 49.2473556, lng: 21.4489123 },
    { lat: 49.2477315, lng: 21.4487937 },
    { lat: 49.248141, lng: 21.4482874 },
    { lat: 49.2486344, lng: 21.4482711 },
    { lat: 49.2486247, lng: 21.4479354 },
    { lat: 49.2488481, lng: 21.4478421 },
    { lat: 49.2502352, lng: 21.4483863 },
    { lat: 49.2522739, lng: 21.4489723 },
    { lat: 49.2525857, lng: 21.4489419 },
    { lat: 49.2529851, lng: 21.448782 },
    { lat: 49.2528978, lng: 21.4473585 },
    { lat: 49.2547132, lng: 21.4467195 },
    { lat: 49.2542438, lng: 21.4441141 },
    { lat: 49.2538502, lng: 21.4429928 },
    { lat: 49.2554561, lng: 21.4419587 },
    { lat: 49.255334, lng: 21.4417389 },
    { lat: 49.2552951, lng: 21.440517 },
    { lat: 49.2555211, lng: 21.4401653 },
    { lat: 49.2556333, lng: 21.4401156 },
    { lat: 49.2557741, lng: 21.4399231 },
    { lat: 49.2562321, lng: 21.4395787 },
    { lat: 49.2564272, lng: 21.4393624 },
    { lat: 49.2570502, lng: 21.4390015 },
    { lat: 49.2573088, lng: 21.4386832 },
    { lat: 49.2578979, lng: 21.4375848 },
    { lat: 49.2582861, lng: 21.4371169 },
    { lat: 49.2584902, lng: 21.4369467 },
    { lat: 49.2588834, lng: 21.4368048 },
    { lat: 49.2605355, lng: 21.4349265 },
    { lat: 49.2617994, lng: 21.4340095 },
    { lat: 49.2621607, lng: 21.4340549 },
    { lat: 49.2627508, lng: 21.4339601 },
    { lat: 49.2636396, lng: 21.4336106 },
    { lat: 49.2642812, lng: 21.4331582 },
    { lat: 49.2648562, lng: 21.4330051 },
    { lat: 49.2654737, lng: 21.4327294 },
    { lat: 49.2658163, lng: 21.432521 },
    { lat: 49.2661728, lng: 21.4314683 },
    { lat: 49.2664776, lng: 21.4308111 },
    { lat: 49.2667608, lng: 21.4294391 },
    { lat: 49.2669934, lng: 21.4291621 },
    { lat: 49.2668505, lng: 21.4273032 },
    { lat: 49.2666252, lng: 21.4269406 },
    { lat: 49.2665526, lng: 21.4256061 },
    { lat: 49.2665786, lng: 21.4243344 },
    { lat: 49.2655134, lng: 21.4238409 },
    { lat: 49.2654657, lng: 21.4236344 },
    { lat: 49.264719, lng: 21.4219092 },
    { lat: 49.2644459, lng: 21.4213795 },
    { lat: 49.2642656, lng: 21.4207084 },
    { lat: 49.2641778, lng: 21.4196621 },
    { lat: 49.2635071, lng: 21.418314 },
    { lat: 49.2631081, lng: 21.4177414 },
    { lat: 49.2623874, lng: 21.4163708 },
    { lat: 49.2615624, lng: 21.4180963 },
    { lat: 49.25996, lng: 21.4192028 },
    { lat: 49.2584887, lng: 21.4196736 },
    { lat: 49.2574709, lng: 21.4204572 },
    { lat: 49.2571755, lng: 21.4205947 },
    { lat: 49.25687, lng: 21.4206544 },
    { lat: 49.2565283, lng: 21.4205858 },
    { lat: 49.2562428, lng: 21.4207471 },
    { lat: 49.2556377, lng: 21.4214467 },
    { lat: 49.2552371, lng: 21.4217509 },
    { lat: 49.2541173, lng: 21.4220111 },
    { lat: 49.2532585, lng: 21.419951 },
    { lat: 49.2526005, lng: 21.419417 },
    { lat: 49.2510259, lng: 21.4183181 },
    { lat: 49.2509598, lng: 21.4181688 },
    { lat: 49.248297, lng: 21.4162901 },
    { lat: 49.2480242, lng: 21.4159255 },
    { lat: 49.2477532, lng: 21.4157366 },
    { lat: 49.2476031, lng: 21.4157219 },
    { lat: 49.2471944, lng: 21.4163321 },
    { lat: 49.2466508, lng: 21.4162845 },
    { lat: 49.2461924, lng: 21.4159999 },
    { lat: 49.2457699, lng: 21.4154734 },
    { lat: 49.2451584, lng: 21.4149897 },
    { lat: 49.2443914, lng: 21.4138104 },
    { lat: 49.2434543, lng: 21.4127716 },
    { lat: 49.2432895, lng: 21.4127526 },
    { lat: 49.242975, lng: 21.4121986 },
    { lat: 49.242271, lng: 21.4105525 },
    { lat: 49.2419581, lng: 21.4105832 },
    { lat: 49.2416379, lng: 21.4101776 },
    { lat: 49.2415731, lng: 21.4095346 },
    { lat: 49.2397284, lng: 21.4095546 },
    { lat: 49.2393716, lng: 21.4093186 },
    { lat: 49.2387044, lng: 21.4099683 },
    { lat: 49.2380478, lng: 21.4098327 },
    { lat: 49.2369982, lng: 21.4093315 },
    { lat: 49.2367138, lng: 21.4082675 },
    { lat: 49.2366195, lng: 21.4083071 },
  ],
  Lipová: [
    { lat: 49.2485438, lng: 21.4814852 },
    { lat: 49.2486613, lng: 21.4818565 },
    { lat: 49.2489131, lng: 21.4833831 },
    { lat: 49.2491009, lng: 21.4840137 },
    { lat: 49.2490871, lng: 21.484431 },
    { lat: 49.2488251, lng: 21.4853404 },
    { lat: 49.2485212, lng: 21.4872607 },
    { lat: 49.2488853, lng: 21.48898 },
    { lat: 49.2492145, lng: 21.4899905 },
    { lat: 49.249317, lng: 21.489995 },
    { lat: 49.249554, lng: 21.490424 },
    { lat: 49.249884, lng: 21.491097 },
    { lat: 49.250154, lng: 21.491652 },
    { lat: 49.250635, lng: 21.492438 },
    { lat: 49.251209, lng: 21.493057 },
    { lat: 49.251367, lng: 21.493112 },
    { lat: 49.251473, lng: 21.493048 },
    { lat: 49.251615, lng: 21.493044 },
    { lat: 49.252014, lng: 21.492819 },
    { lat: 49.252209, lng: 21.492948 },
    { lat: 49.252454, lng: 21.492828 },
    { lat: 49.252494, lng: 21.492871 },
    { lat: 49.253294, lng: 21.49379 },
    { lat: 49.253311, lng: 21.493808 },
    { lat: 49.253841, lng: 21.494418 },
    { lat: 49.253452, lng: 21.49513 },
    { lat: 49.253645, lng: 21.495401 },
    { lat: 49.254212, lng: 21.496184 },
    { lat: 49.25485, lng: 21.497093 },
    { lat: 49.255074, lng: 21.497401 },
    { lat: 49.255567, lng: 21.496815 },
    { lat: 49.255929, lng: 21.496464 },
    { lat: 49.256149, lng: 21.496441 },
    { lat: 49.256866, lng: 21.496795 },
    { lat: 49.257295, lng: 21.496981 },
    { lat: 49.257445, lng: 21.49722 },
    { lat: 49.258181, lng: 21.498094 },
    { lat: 49.258876, lng: 21.498404 },
    { lat: 49.259345, lng: 21.498582 },
    { lat: 49.260385, lng: 21.498993 },
    { lat: 49.261206, lng: 21.499176 },
    { lat: 49.262886, lng: 21.499672 },
    { lat: 49.262917, lng: 21.499748 },
    { lat: 49.263922, lng: 21.499644 },
    { lat: 49.265249, lng: 21.499504 },
    { lat: 49.265979, lng: 21.498772 },
    { lat: 49.266824, lng: 21.498085 },
    { lat: 49.267338, lng: 21.497663 },
    { lat: 49.268123, lng: 21.497165 },
    { lat: 49.26902, lng: 21.496501 },
    { lat: 49.269854, lng: 21.495955 },
    { lat: 49.27024, lng: 21.49559 },
    { lat: 49.270003, lng: 21.495036 },
    { lat: 49.269668, lng: 21.494402 },
    { lat: 49.269519, lng: 21.493903 },
    { lat: 49.270686, lng: 21.492382 },
    { lat: 49.270712, lng: 21.492227 },
    { lat: 49.27106, lng: 21.491869 },
    { lat: 49.27127, lng: 21.491796 },
    { lat: 49.271319, lng: 21.491778 },
    { lat: 49.271279, lng: 21.491535 },
    { lat: 49.27119, lng: 21.490463 },
    { lat: 49.271208, lng: 21.490282 },
    { lat: 49.271312, lng: 21.489732 },
    { lat: 49.271188, lng: 21.489644 },
    { lat: 49.271191, lng: 21.489304 },
    { lat: 49.271185, lng: 21.488931 },
    { lat: 49.271149, lng: 21.488552 },
    { lat: 49.271157, lng: 21.48837 },
    { lat: 49.271109, lng: 21.488333 },
    { lat: 49.271136, lng: 21.488304 },
    { lat: 49.271379, lng: 21.486966 },
    { lat: 49.2703079, lng: 21.4873359 },
    { lat: 49.2702111, lng: 21.4869771 },
    { lat: 49.2702216, lng: 21.4866781 },
    { lat: 49.2695328, lng: 21.4839079 },
    { lat: 49.2691987, lng: 21.4831214 },
    { lat: 49.2688873, lng: 21.4822239 },
    { lat: 49.2690187, lng: 21.4816679 },
    { lat: 49.2689395, lng: 21.4814963 },
    { lat: 49.2686569, lng: 21.4801142 },
    { lat: 49.2679276, lng: 21.4780169 },
    { lat: 49.2678622, lng: 21.4769953 },
    { lat: 49.268024, lng: 21.4758875 },
    { lat: 49.2672079, lng: 21.4756349 },
    { lat: 49.2661585, lng: 21.4757213 },
    { lat: 49.2648684, lng: 21.4747151 },
    { lat: 49.2648598, lng: 21.4746552 },
    { lat: 49.2637499, lng: 21.4745451 },
    { lat: 49.2634853, lng: 21.4746161 },
    { lat: 49.2627342, lng: 21.4736953 },
    { lat: 49.260928, lng: 21.4719497 },
    { lat: 49.2595876, lng: 21.4702101 },
    { lat: 49.2591668, lng: 21.4683261 },
    { lat: 49.2585031, lng: 21.4691501 },
    { lat: 49.2570129, lng: 21.4704613 },
    { lat: 49.2568536, lng: 21.4705159 },
    { lat: 49.256201, lng: 21.4709807 },
    { lat: 49.2553789, lng: 21.4717003 },
    { lat: 49.253432, lng: 21.4728878 },
    { lat: 49.2527337, lng: 21.4733721 },
    { lat: 49.2519016, lng: 21.4740588 },
    { lat: 49.2515665, lng: 21.4742592 },
    { lat: 49.2498526, lng: 21.4777706 },
    { lat: 49.2487384, lng: 21.4803835 },
    { lat: 49.2485438, lng: 21.4814852 },
  ],
  Koprivnica: [
    { lat: 49.1833521, lng: 21.380657 },
    { lat: 49.1829867, lng: 21.3766316 },
    { lat: 49.1830944, lng: 21.3755628 },
    { lat: 49.1835083, lng: 21.3740359 },
    { lat: 49.1820295, lng: 21.3723168 },
    { lat: 49.1813157, lng: 21.3708152 },
    { lat: 49.1811841, lng: 21.3704722 },
    { lat: 49.1815405, lng: 21.3697042 },
    { lat: 49.1813284, lng: 21.3694203 },
    { lat: 49.1809946, lng: 21.369239 },
    { lat: 49.1800653, lng: 21.3684475 },
    { lat: 49.1784262, lng: 21.3664866 },
    { lat: 49.1778724, lng: 21.3672325 },
    { lat: 49.1769107, lng: 21.3661859 },
    { lat: 49.1764081, lng: 21.3669182 },
    { lat: 49.1752765, lng: 21.3652202 },
    { lat: 49.1742693, lng: 21.3641487 },
    { lat: 49.1735877, lng: 21.3657474 },
    { lat: 49.1718492, lng: 21.3651784 },
    { lat: 49.1702509, lng: 21.3643992 },
    { lat: 49.1692013, lng: 21.3645151 },
    { lat: 49.1682227, lng: 21.3642221 },
    { lat: 49.167545, lng: 21.363681 },
    { lat: 49.1660004, lng: 21.3653549 },
    { lat: 49.1651044, lng: 21.3666694 },
    { lat: 49.1649004, lng: 21.3668125 },
    { lat: 49.1648348, lng: 21.3672546 },
    { lat: 49.1642031, lng: 21.3686744 },
    { lat: 49.1640563, lng: 21.3691861 },
    { lat: 49.1631889, lng: 21.3677496 },
    { lat: 49.1621177, lng: 21.369456 },
    { lat: 49.1608581, lng: 21.3723628 },
    { lat: 49.1603481, lng: 21.3716318 },
    { lat: 49.1594746, lng: 21.3733252 },
    { lat: 49.1592851, lng: 21.3731204 },
    { lat: 49.1584145, lng: 21.374798 },
    { lat: 49.1581498, lng: 21.3750945 },
    { lat: 49.1569583, lng: 21.3758943 },
    { lat: 49.1568198, lng: 21.3758521 },
    { lat: 49.1564836, lng: 21.3769278 },
    { lat: 49.1564803, lng: 21.3788623 },
    { lat: 49.1565086, lng: 21.379286 },
    { lat: 49.1569484, lng: 21.3821758 },
    { lat: 49.1560407, lng: 21.3829118 },
    { lat: 49.1559359, lng: 21.3829057 },
    { lat: 49.156008, lng: 21.3808553 },
    { lat: 49.1557058, lng: 21.3808333 },
    { lat: 49.1555252, lng: 21.3832829 },
    { lat: 49.1557466, lng: 21.385294 },
    { lat: 49.1555295, lng: 21.3873187 },
    { lat: 49.1551682, lng: 21.3891458 },
    { lat: 49.1550836, lng: 21.3890981 },
    { lat: 49.1545278, lng: 21.3903191 },
    { lat: 49.1544992, lng: 21.3905724 },
    { lat: 49.1538385, lng: 21.3923955 },
    { lat: 49.1536305, lng: 21.3932411 },
    { lat: 49.1534953, lng: 21.3937723 },
    { lat: 49.1533668, lng: 21.393917 },
    { lat: 49.1533137, lng: 21.394211 },
    { lat: 49.1533561, lng: 21.3945471 },
    { lat: 49.1530928, lng: 21.3953095 },
    { lat: 49.1531121, lng: 21.3961166 },
    { lat: 49.1528173, lng: 21.3966907 },
    { lat: 49.15285, lng: 21.3973458 },
    { lat: 49.1525104, lng: 21.3982651 },
    { lat: 49.1521699, lng: 21.3986633 },
    { lat: 49.1520333, lng: 21.3990698 },
    { lat: 49.1521162, lng: 21.3992827 },
    { lat: 49.1521522, lng: 21.4005059 },
    { lat: 49.1519538, lng: 21.4036717 },
    { lat: 49.1512579, lng: 21.4066475 },
    { lat: 49.1508502, lng: 21.4079728 },
    { lat: 49.1508231, lng: 21.4083183 },
    { lat: 49.15134, lng: 21.4093348 },
    { lat: 49.1517147, lng: 21.4098283 },
    { lat: 49.152463, lng: 21.4105649 },
    { lat: 49.1524429, lng: 21.4107981 },
    { lat: 49.1529209, lng: 21.4117493 },
    { lat: 49.1531892, lng: 21.4124593 },
    { lat: 49.1533937, lng: 21.412605 },
    { lat: 49.1535754, lng: 21.4126292 },
    { lat: 49.1534815, lng: 21.4131798 },
    { lat: 49.1529212, lng: 21.4143772 },
    { lat: 49.1524932, lng: 21.4149372 },
    { lat: 49.1518144, lng: 21.4155415 },
    { lat: 49.150411, lng: 21.4173331 },
    { lat: 49.1502387, lng: 21.4177164 },
    { lat: 49.1498434, lng: 21.417928 },
    { lat: 49.1499326, lng: 21.4182124 },
    { lat: 49.1500696, lng: 21.4183986 },
    { lat: 49.1504708, lng: 21.4199607 },
    { lat: 49.1505203, lng: 21.4203412 },
    { lat: 49.150858, lng: 21.4213531 },
    { lat: 49.1507067, lng: 21.4217565 },
    { lat: 49.150603, lng: 21.4226312 },
    { lat: 49.1512606, lng: 21.4246217 },
    { lat: 49.1498398, lng: 21.4237041 },
    { lat: 49.1497198, lng: 21.4239894 },
    { lat: 49.149507, lng: 21.4239819 },
    { lat: 49.1492451, lng: 21.4246052 },
    { lat: 49.1492577, lng: 21.4248459 },
    { lat: 49.1490256, lng: 21.4251384 },
    { lat: 49.1487933, lng: 21.4260527 },
    { lat: 49.1485017, lng: 21.4267722 },
    { lat: 49.1483849, lng: 21.42694 },
    { lat: 49.1483068, lng: 21.4275362 },
    { lat: 49.1481095, lng: 21.428029 },
    { lat: 49.1480504, lng: 21.4283949 },
    { lat: 49.1478795, lng: 21.4286311 },
    { lat: 49.1477671, lng: 21.4289823 },
    { lat: 49.1475926, lng: 21.4292058 },
    { lat: 49.1476277, lng: 21.4292948 },
    { lat: 49.1477038, lng: 21.4293173 },
    { lat: 49.1476243, lng: 21.429447 },
    { lat: 49.1475176, lng: 21.4293752 },
    { lat: 49.1473637, lng: 21.4294187 },
    { lat: 49.1472655, lng: 21.4292212 },
    { lat: 49.1471759, lng: 21.4294238 },
    { lat: 49.1470397, lng: 21.429386 },
    { lat: 49.1467395, lng: 21.4294706 },
    { lat: 49.1465992, lng: 21.4296731 },
    { lat: 49.1462807, lng: 21.4295513 },
    { lat: 49.1461766, lng: 21.4296838 },
    { lat: 49.146093, lng: 21.4295582 },
    { lat: 49.1459681, lng: 21.4296321 },
    { lat: 49.1458448, lng: 21.429804 },
    { lat: 49.145862, lng: 21.4299739 },
    { lat: 49.1455367, lng: 21.4304282 },
    { lat: 49.1454101, lng: 21.4304868 },
    { lat: 49.1451917, lng: 21.4303693 },
    { lat: 49.1451683, lng: 21.4304895 },
    { lat: 49.145023, lng: 21.4305992 },
    { lat: 49.1448404, lng: 21.4306274 },
    { lat: 49.1444686, lng: 21.4305304 },
    { lat: 49.1442999, lng: 21.4307279 },
    { lat: 49.1442595, lng: 21.4306933 },
    { lat: 49.1442732, lng: 21.4304692 },
    { lat: 49.14391, lng: 21.4307489 },
    { lat: 49.1439353, lng: 21.43096 },
    { lat: 49.1438111, lng: 21.4312548 },
    { lat: 49.1433254, lng: 21.4312287 },
    { lat: 49.1433286, lng: 21.4313798 },
    { lat: 49.1432382, lng: 21.43152 },
    { lat: 49.1431273, lng: 21.4314193 },
    { lat: 49.1429905, lng: 21.4314771 },
    { lat: 49.1428951, lng: 21.4319072 },
    { lat: 49.1426963, lng: 21.4319149 },
    { lat: 49.1425176, lng: 21.4321588 },
    { lat: 49.1424293, lng: 21.4321371 },
    { lat: 49.1423136, lng: 21.4325675 },
    { lat: 49.1421034, lng: 21.4326924 },
    { lat: 49.1420905, lng: 21.4329078 },
    { lat: 49.1421856, lng: 21.4331943 },
    { lat: 49.1419339, lng: 21.4333259 },
    { lat: 49.1418133, lng: 21.433314 },
    { lat: 49.1418328, lng: 21.4336745 },
    { lat: 49.1417758, lng: 21.4337679 },
    { lat: 49.1418596, lng: 21.4338675 },
    { lat: 49.1417667, lng: 21.4339557 },
    { lat: 49.1415796, lng: 21.4339842 },
    { lat: 49.1415494, lng: 21.4340653 },
    { lat: 49.1416179, lng: 21.4342554 },
    { lat: 49.141571, lng: 21.4343865 },
    { lat: 49.1413952, lng: 21.4343729 },
    { lat: 49.1413577, lng: 21.4348674 },
    { lat: 49.1411874, lng: 21.4345792 },
    { lat: 49.1411096, lng: 21.4346125 },
    { lat: 49.1408794, lng: 21.4351344 },
    { lat: 49.1406936, lng: 21.4351663 },
    { lat: 49.1405387, lng: 21.4356058 },
    { lat: 49.1402979, lng: 21.4356825 },
    { lat: 49.13998, lng: 21.4363109 },
    { lat: 49.139959, lng: 21.436563 },
    { lat: 49.139981, lng: 21.436558 },
    { lat: 49.140069, lng: 21.436509 },
    { lat: 49.140135, lng: 21.436286 },
    { lat: 49.140247, lng: 21.436405 },
    { lat: 49.140432, lng: 21.436408 },
    { lat: 49.140501, lng: 21.43647 },
    { lat: 49.140518, lng: 21.436473 },
    { lat: 49.140591, lng: 21.436353 },
    { lat: 49.140653, lng: 21.436375 },
    { lat: 49.140681, lng: 21.436248 },
    { lat: 49.140841, lng: 21.436241 },
    { lat: 49.140965, lng: 21.436334 },
    { lat: 49.141048, lng: 21.436177 },
    { lat: 49.141149, lng: 21.436219 },
    { lat: 49.141221, lng: 21.436184 },
    { lat: 49.141246, lng: 21.436073 },
    { lat: 49.141307, lng: 21.436085 },
    { lat: 49.141337, lng: 21.435917 },
    { lat: 49.141377, lng: 21.435891 },
    { lat: 49.141472, lng: 21.435972 },
    { lat: 49.141706, lng: 21.435984 },
    { lat: 49.141824, lng: 21.435966 },
    { lat: 49.141863, lng: 21.436081 },
    { lat: 49.142177, lng: 21.43626 },
    { lat: 49.142585, lng: 21.43729 },
    { lat: 49.142638, lng: 21.437535 },
    { lat: 49.142646, lng: 21.437742 },
    { lat: 49.142882, lng: 21.43882 },
    { lat: 49.143049, lng: 21.439534 },
    { lat: 49.143265, lng: 21.440107 },
    { lat: 49.143401, lng: 21.440309 },
    { lat: 49.143865, lng: 21.441458 },
    { lat: 49.144667, lng: 21.442135 },
    { lat: 49.144962, lng: 21.441949 },
    { lat: 49.145116, lng: 21.442123 },
    { lat: 49.145174, lng: 21.442401 },
    { lat: 49.145169, lng: 21.44297 },
    { lat: 49.14527, lng: 21.44332 },
    { lat: 49.145302, lng: 21.443596 },
    { lat: 49.145473, lng: 21.443959 },
    { lat: 49.145461, lng: 21.445208 },
    { lat: 49.145475, lng: 21.44522 },
    { lat: 49.145386, lng: 21.44577 },
    { lat: 49.145376, lng: 21.445837 },
    { lat: 49.145377, lng: 21.445857 },
    { lat: 49.145457, lng: 21.446697 },
    { lat: 49.145737, lng: 21.447647 },
    { lat: 49.1480683, lng: 21.4448773 },
    { lat: 49.1492782, lng: 21.4431824 },
    { lat: 49.1497318, lng: 21.4426892 },
    { lat: 49.1507361, lng: 21.4412791 },
    { lat: 49.1552584, lng: 21.4356763 },
    { lat: 49.159136, lng: 21.4304749 },
    { lat: 49.1592079, lng: 21.4305469 },
    { lat: 49.1596416, lng: 21.4292946 },
    { lat: 49.1598708, lng: 21.4278904 },
    { lat: 49.1597926, lng: 21.4269463 },
    { lat: 49.1603845, lng: 21.4267972 },
    { lat: 49.1606627, lng: 21.4265378 },
    { lat: 49.1608565, lng: 21.426465 },
    { lat: 49.1616232, lng: 21.4259471 },
    { lat: 49.1619837, lng: 21.4254607 },
    { lat: 49.1630123, lng: 21.4251631 },
    { lat: 49.1630539, lng: 21.4260889 },
    { lat: 49.1632831, lng: 21.4261531 },
    { lat: 49.1633406, lng: 21.4288211 },
    { lat: 49.1637738, lng: 21.4287055 },
    { lat: 49.1637734, lng: 21.4288037 },
    { lat: 49.1639937, lng: 21.4287995 },
    { lat: 49.1644051, lng: 21.4290571 },
    { lat: 49.1644271, lng: 21.4269172 },
    { lat: 49.1642595, lng: 21.4256084 },
    { lat: 49.1644278, lng: 21.425588 },
    { lat: 49.1648958, lng: 21.4259697 },
    { lat: 49.1651002, lng: 21.4260005 },
    { lat: 49.1651153, lng: 21.4261727 },
    { lat: 49.1651856, lng: 21.4261511 },
    { lat: 49.1652344, lng: 21.4266281 },
    { lat: 49.1654996, lng: 21.4265353 },
    { lat: 49.1657121, lng: 21.4258748 },
    { lat: 49.1662432, lng: 21.4251859 },
    { lat: 49.1665492, lng: 21.4259144 },
    { lat: 49.1670503, lng: 21.4247763 },
    { lat: 49.1685262, lng: 21.4224488 },
    { lat: 49.1684371, lng: 21.4222495 },
    { lat: 49.168686, lng: 21.4215671 },
    { lat: 49.1689112, lng: 21.4219175 },
    { lat: 49.1695853, lng: 21.4211925 },
    { lat: 49.169392, lng: 21.4206202 },
    { lat: 49.1696367, lng: 21.4204084 },
    { lat: 49.1695083, lng: 21.4200241 },
    { lat: 49.1701807, lng: 21.4197026 },
    { lat: 49.1702006, lng: 21.4197592 },
    { lat: 49.1715047, lng: 21.4193829 },
    { lat: 49.171515, lng: 21.4194318 },
    { lat: 49.1722434, lng: 21.4191763 },
    { lat: 49.1723122, lng: 21.4194097 },
    { lat: 49.1725673, lng: 21.4192784 },
    { lat: 49.1735949, lng: 21.4190259 },
    { lat: 49.1736861, lng: 21.4194123 },
    { lat: 49.1743666, lng: 21.4188677 },
    { lat: 49.1748316, lng: 21.4194176 },
    { lat: 49.175232, lng: 21.4191045 },
    { lat: 49.1754583, lng: 21.4195746 },
    { lat: 49.1761966, lng: 21.4186178 },
    { lat: 49.1762642, lng: 21.4187049 },
    { lat: 49.1770219, lng: 21.4176045 },
    { lat: 49.1771137, lng: 21.4178169 },
    { lat: 49.1775241, lng: 21.4173056 },
    { lat: 49.177757, lng: 21.4169194 },
    { lat: 49.1778487, lng: 21.4165165 },
    { lat: 49.179001, lng: 21.4134482 },
    { lat: 49.1788861, lng: 21.4129065 },
    { lat: 49.1799186, lng: 21.4110481 },
    { lat: 49.1797295, lng: 21.4107416 },
    { lat: 49.1798112, lng: 21.4105278 },
    { lat: 49.1796511, lng: 21.4103661 },
    { lat: 49.179744, lng: 21.4101184 },
    { lat: 49.1805956, lng: 21.4090797 },
    { lat: 49.1805394, lng: 21.4090036 },
    { lat: 49.1808448, lng: 21.4086771 },
    { lat: 49.1809455, lng: 21.4087746 },
    { lat: 49.1816618, lng: 21.4071846 },
    { lat: 49.1823072, lng: 21.4054013 },
    { lat: 49.1819073, lng: 21.4053844 },
    { lat: 49.1817502, lng: 21.4000715 },
    { lat: 49.1815572, lng: 21.3997869 },
    { lat: 49.1814179, lng: 21.3987193 },
    { lat: 49.1814063, lng: 21.3983673 },
    { lat: 49.1815005, lng: 21.3980501 },
    { lat: 49.1816478, lng: 21.3977739 },
    { lat: 49.1814678, lng: 21.3972025 },
    { lat: 49.1813913, lng: 21.3970854 },
    { lat: 49.1813718, lng: 21.3964207 },
    { lat: 49.1815608, lng: 21.3943182 },
    { lat: 49.1818647, lng: 21.3898506 },
    { lat: 49.1824102, lng: 21.3837418 },
    { lat: 49.1823782, lng: 21.3830801 },
    { lat: 49.1822812, lng: 21.3823415 },
    { lat: 49.1825095, lng: 21.3818486 },
    { lat: 49.1833521, lng: 21.380657 },
  ],
  NižnáVoľa: [
    { lat: 49.2318307, lng: 21.353798 },
    { lat: 49.2309881, lng: 21.3515315 },
    { lat: 49.2306778, lng: 21.3498444 },
    { lat: 49.2291728, lng: 21.3481161 },
    { lat: 49.2278239, lng: 21.3469901 },
    { lat: 49.227656, lng: 21.3480015 },
    { lat: 49.2265761, lng: 21.3483875 },
    { lat: 49.2262259, lng: 21.3483758 },
    { lat: 49.2261726, lng: 21.3471171 },
    { lat: 49.2257656, lng: 21.3468624 },
    { lat: 49.2252444, lng: 21.3469659 },
    { lat: 49.2246793, lng: 21.3472215 },
    { lat: 49.2239194, lng: 21.3485666 },
    { lat: 49.2232261, lng: 21.3494264 },
    { lat: 49.2217173, lng: 21.3501801 },
    { lat: 49.2220149, lng: 21.3489569 },
    { lat: 49.2209692, lng: 21.348518 },
    { lat: 49.2205654, lng: 21.3485608 },
    { lat: 49.2202539, lng: 21.3482656 },
    { lat: 49.2197144, lng: 21.3491586 },
    { lat: 49.2192756, lng: 21.3482076 },
    { lat: 49.2187105, lng: 21.3489213 },
    { lat: 49.2179718, lng: 21.3482974 },
    { lat: 49.2165065, lng: 21.34968 },
    { lat: 49.2162251, lng: 21.349408 },
    { lat: 49.2140703, lng: 21.3480431 },
    { lat: 49.2130817, lng: 21.3489618 },
    { lat: 49.2124544, lng: 21.3481164 },
    { lat: 49.2113295, lng: 21.3483852 },
    { lat: 49.2101627, lng: 21.3469476 },
    { lat: 49.2077766, lng: 21.3456804 },
    { lat: 49.2072432, lng: 21.3488891 },
    { lat: 49.2064035, lng: 21.3505364 },
    { lat: 49.2047973, lng: 21.3523634 },
    { lat: 49.2045592, lng: 21.3525703 },
    { lat: 49.204535, lng: 21.3532308 },
    { lat: 49.2046832, lng: 21.354432 },
    { lat: 49.2050383, lng: 21.3557809 },
    { lat: 49.205572, lng: 21.357379 },
    { lat: 49.207729, lng: 21.3621135 },
    { lat: 49.2092383, lng: 21.3642179 },
    { lat: 49.2095623, lng: 21.3674781 },
    { lat: 49.2103313, lng: 21.3668561 },
    { lat: 49.2103708, lng: 21.3681104 },
    { lat: 49.2109722, lng: 21.3703364 },
    { lat: 49.2101219, lng: 21.3725873 },
    { lat: 49.2109502, lng: 21.3741917 },
    { lat: 49.210641, lng: 21.3761005 },
    { lat: 49.2112383, lng: 21.3782642 },
    { lat: 49.2108858, lng: 21.3785584 },
    { lat: 49.2110332, lng: 21.3797815 },
    { lat: 49.2114206, lng: 21.3811659 },
    { lat: 49.2111197, lng: 21.3816836 },
    { lat: 49.2121302, lng: 21.3840879 },
    { lat: 49.2130185, lng: 21.3859828 },
    { lat: 49.2132213, lng: 21.3862147 },
    { lat: 49.2165741, lng: 21.3838167 },
    { lat: 49.2177881, lng: 21.3833126 },
    { lat: 49.2183768, lng: 21.383248 },
    { lat: 49.2194161, lng: 21.3828588 },
    { lat: 49.2195973, lng: 21.3829759 },
    { lat: 49.2213308, lng: 21.3826602 },
    { lat: 49.2221108, lng: 21.3821365 },
    { lat: 49.2236362, lng: 21.3816746 },
    { lat: 49.2242411, lng: 21.3810466 },
    { lat: 49.2244688, lng: 21.3811342 },
    { lat: 49.225042, lng: 21.3819911 },
    { lat: 49.2266243, lng: 21.3818888 },
    { lat: 49.2284254, lng: 21.3828352 },
    { lat: 49.2290484, lng: 21.3830573 },
    { lat: 49.2285014, lng: 21.385189 },
    { lat: 49.2280049, lng: 21.3881213 },
    { lat: 49.2299602, lng: 21.387693 },
    { lat: 49.2315575, lng: 21.3869155 },
    { lat: 49.234026, lng: 21.3856612 },
    { lat: 49.236411, lng: 21.383892 },
    { lat: 49.2369607, lng: 21.3829299 },
    { lat: 49.236442, lng: 21.3790364 },
    { lat: 49.2360708, lng: 21.3770939 },
    { lat: 49.2358826, lng: 21.3755594 },
    { lat: 49.2352341, lng: 21.3739505 },
    { lat: 49.2345867, lng: 21.3730975 },
    { lat: 49.2338766, lng: 21.3717582 },
    { lat: 49.2342759, lng: 21.3712219 },
    { lat: 49.2335829, lng: 21.3691708 },
    { lat: 49.2331398, lng: 21.3668605 },
    { lat: 49.2320983, lng: 21.366266 },
    { lat: 49.2320334, lng: 21.3660491 },
    { lat: 49.2319253, lng: 21.3643756 },
    { lat: 49.2319954, lng: 21.3631309 },
    { lat: 49.2318787, lng: 21.3623498 },
    { lat: 49.231297, lng: 21.3598865 },
    { lat: 49.2310383, lng: 21.357384 },
    { lat: 49.2318307, lng: 21.353798 },
  ],
  Malcov: [
    { lat: 49.3216516, lng: 21.0794036 },
    { lat: 49.3207638, lng: 21.0794788 },
    { lat: 49.3205157, lng: 21.0792103 },
    { lat: 49.3199312, lng: 21.0789183 },
    { lat: 49.3192954, lng: 21.0776205 },
    { lat: 49.3186833, lng: 21.0770932 },
    { lat: 49.3182252, lng: 21.0760714 },
    { lat: 49.3174889, lng: 21.0757093 },
    { lat: 49.3177567, lng: 21.0752488 },
    { lat: 49.317883, lng: 21.0741003 },
    { lat: 49.3189958, lng: 21.0726608 },
    { lat: 49.3192482, lng: 21.0702565 },
    { lat: 49.3188908, lng: 21.0702346 },
    { lat: 49.3185289, lng: 21.069584 },
    { lat: 49.3178434, lng: 21.0697506 },
    { lat: 49.3172468, lng: 21.070237 },
    { lat: 49.316008, lng: 21.0699971 },
    { lat: 49.3151975, lng: 21.0683358 },
    { lat: 49.3151902, lng: 21.0679049 },
    { lat: 49.3149135, lng: 21.0669241 },
    { lat: 49.3148772, lng: 21.0658974 },
    { lat: 49.3152524, lng: 21.065458 },
    { lat: 49.3158137, lng: 21.0644719 },
    { lat: 49.3158649, lng: 21.0640045 },
    { lat: 49.3159918, lng: 21.0636094 },
    { lat: 49.3164808, lng: 21.06338 },
    { lat: 49.3162955, lng: 21.0630462 },
    { lat: 49.3163784, lng: 21.062645 },
    { lat: 49.3160243, lng: 21.0623766 },
    { lat: 49.3159967, lng: 21.0621482 },
    { lat: 49.3161838, lng: 21.0618806 },
    { lat: 49.3161478, lng: 21.0615691 },
    { lat: 49.3159355, lng: 21.0616854 },
    { lat: 49.3156367, lng: 21.0616307 },
    { lat: 49.3152625, lng: 21.0604243 },
    { lat: 49.3154717, lng: 21.0600727 },
    { lat: 49.3156798, lng: 21.0592289 },
    { lat: 49.31609, lng: 21.0585134 },
    { lat: 49.3164392, lng: 21.0583817 },
    { lat: 49.3165795, lng: 21.0579581 },
    { lat: 49.3165572, lng: 21.0577492 },
    { lat: 49.3162484, lng: 21.0574576 },
    { lat: 49.3163181, lng: 21.0573178 },
    { lat: 49.316173, lng: 21.0569698 },
    { lat: 49.3162537, lng: 21.0565042 },
    { lat: 49.316398, lng: 21.0561673 },
    { lat: 49.3165729, lng: 21.0560765 },
    { lat: 49.3167634, lng: 21.0555626 },
    { lat: 49.3170474, lng: 21.0554344 },
    { lat: 49.3168962, lng: 21.0550386 },
    { lat: 49.3169953, lng: 21.0543757 },
    { lat: 49.3171937, lng: 21.0540061 },
    { lat: 49.3174409, lng: 21.0539936 },
    { lat: 49.3174522, lng: 21.0536133 },
    { lat: 49.3170571, lng: 21.0531912 },
    { lat: 49.3170099, lng: 21.0527356 },
    { lat: 49.3174413, lng: 21.0522568 },
    { lat: 49.3176662, lng: 21.0517024 },
    { lat: 49.3175988, lng: 21.0513507 },
    { lat: 49.3176457, lng: 21.0510943 },
    { lat: 49.3178127, lng: 21.0510078 },
    { lat: 49.3177766, lng: 21.0507601 },
    { lat: 49.3181195, lng: 21.0504017 },
    { lat: 49.3184692, lng: 21.0504261 },
    { lat: 49.3185405, lng: 21.0500272 },
    { lat: 49.3183511, lng: 21.0492126 },
    { lat: 49.3181817, lng: 21.048772 },
    { lat: 49.3184016, lng: 21.048127 },
    { lat: 49.3186988, lng: 21.048027 },
    { lat: 49.3186715, lng: 21.0478757 },
    { lat: 49.3184994, lng: 21.0476366 },
    { lat: 49.3189289, lng: 21.0461277 },
    { lat: 49.3193677, lng: 21.0456025 },
    { lat: 49.3195717, lng: 21.0451582 },
    { lat: 49.3196532, lng: 21.0448277 },
    { lat: 49.319853, lng: 21.0444937 },
    { lat: 49.3198529, lng: 21.0441467 },
    { lat: 49.319922, lng: 21.0436754 },
    { lat: 49.3201002, lng: 21.0434758 },
    { lat: 49.3204136, lng: 21.0428699 },
    { lat: 49.3206298, lng: 21.0427291 },
    { lat: 49.3209179, lng: 21.0417239 },
    { lat: 49.3214727, lng: 21.0415174 },
    { lat: 49.3211591, lng: 21.0408979 },
    { lat: 49.3222352, lng: 21.0396271 },
    { lat: 49.3221341, lng: 21.0393533 },
    { lat: 49.3228335, lng: 21.0386387 },
    { lat: 49.3240041, lng: 21.0355897 },
    { lat: 49.3246114, lng: 21.0352997 },
    { lat: 49.3247518, lng: 21.0347061 },
    { lat: 49.325029, lng: 21.0346078 },
    { lat: 49.3256471, lng: 21.033475 },
    { lat: 49.3264168, lng: 21.0333012 },
    { lat: 49.3266196, lng: 21.0327208 },
    { lat: 49.3271302, lng: 21.0323281 },
    { lat: 49.3280413, lng: 21.0306484 },
    { lat: 49.3291229, lng: 21.0299994 },
    { lat: 49.3291772, lng: 21.0295494 },
    { lat: 49.3295202, lng: 21.0294082 },
    { lat: 49.3300307, lng: 21.0278039 },
    { lat: 49.330325, lng: 21.0278172 },
    { lat: 49.3312892, lng: 21.0268066 },
    { lat: 49.3315994, lng: 21.0262443 },
    { lat: 49.3323587, lng: 21.0253664 },
    { lat: 49.3325101, lng: 21.0236897 },
    { lat: 49.3332587, lng: 21.0227653 },
    { lat: 49.333804, lng: 21.0224358 },
    { lat: 49.3343388, lng: 21.0225795 },
    { lat: 49.3348134, lng: 21.0228876 },
    { lat: 49.3354447, lng: 21.0229182 },
    { lat: 49.3361573, lng: 21.0225497 },
    { lat: 49.3389035, lng: 21.0228379 },
    { lat: 49.3399441, lng: 21.0220673 },
    { lat: 49.3404859, lng: 21.021353 },
    { lat: 49.3429063, lng: 21.0133989 },
    { lat: 49.3430284, lng: 21.0118261 },
    { lat: 49.3438091, lng: 21.011332 },
    { lat: 49.344486, lng: 21.009496 },
    { lat: 49.34398, lng: 21.009043 },
    { lat: 49.343338, lng: 21.008482 },
    { lat: 49.342754, lng: 21.008159 },
    { lat: 49.342105, lng: 21.007831 },
    { lat: 49.341506, lng: 21.007532 },
    { lat: 49.3414089, lng: 21.0074816 },
    { lat: 49.340826, lng: 21.007179 },
    { lat: 49.340167, lng: 21.006952 },
    { lat: 49.339549, lng: 21.00697 },
    { lat: 49.338353, lng: 21.007987 },
    { lat: 49.3378274, lng: 21.0102081 },
    { lat: 49.3378528, lng: 21.0102282 },
    { lat: 49.3377235, lng: 21.0105812 },
    { lat: 49.3374214, lng: 21.0109107 },
    { lat: 49.3370504, lng: 21.011026 },
    { lat: 49.3355873, lng: 21.0118096 },
    { lat: 49.3351039, lng: 21.0121727 },
    { lat: 49.3341343, lng: 21.0140112 },
    { lat: 49.3341079, lng: 21.0139324 },
    { lat: 49.330936, lng: 21.0175667 },
    { lat: 49.3303732, lng: 21.0176702 },
    { lat: 49.3301332, lng: 21.0177975 },
    { lat: 49.3298167, lng: 21.0180983 },
    { lat: 49.3294104, lng: 21.0186485 },
    { lat: 49.3291256, lng: 21.0189267 },
    { lat: 49.3285209, lng: 21.0200555 },
    { lat: 49.3282175, lng: 21.0204116 },
    { lat: 49.3278621, lng: 21.0205931 },
    { lat: 49.3275041, lng: 21.0215209 },
    { lat: 49.326641, lng: 21.0227743 },
    { lat: 49.3263586, lng: 21.0232915 },
    { lat: 49.3257313, lng: 21.0241394 },
    { lat: 49.3253143, lng: 21.0246748 },
    { lat: 49.3249007, lng: 21.0250382 },
    { lat: 49.3246801, lng: 21.0254543 },
    { lat: 49.3240321, lng: 21.0259401 },
    { lat: 49.3232895, lng: 21.0267816 },
    { lat: 49.3221882, lng: 21.0274483 },
    { lat: 49.3210419, lng: 21.0283636 },
    { lat: 49.3205413, lng: 21.0286473 },
    { lat: 49.3195659, lng: 21.0295883 },
    { lat: 49.3172151, lng: 21.0313615 },
    { lat: 49.3169841, lng: 21.0316258 },
    { lat: 49.3157169, lng: 21.0322796 },
    { lat: 49.3153348, lng: 21.0324006 },
    { lat: 49.3142107, lng: 21.0334417 },
    { lat: 49.3135392, lng: 21.0335601 },
    { lat: 49.3133246, lng: 21.0338415 },
    { lat: 49.3132634, lng: 21.0340781 },
    { lat: 49.3131322, lng: 21.0341926 },
    { lat: 49.3130241, lng: 21.0340872 },
    { lat: 49.3124034, lng: 21.0344124 },
    { lat: 49.3123064, lng: 21.0343025 },
    { lat: 49.3121631, lng: 21.0343246 },
    { lat: 49.31207, lng: 21.0345083 },
    { lat: 49.3119425, lng: 21.0345707 },
    { lat: 49.3088721, lng: 21.035589 },
    { lat: 49.3088564, lng: 21.0358023 },
    { lat: 49.3083911, lng: 21.0359897 },
    { lat: 49.3077814, lng: 21.0363497 },
    { lat: 49.307755, lng: 21.0365418 },
    { lat: 49.3075328, lng: 21.0365229 },
    { lat: 49.3068251, lng: 21.0369441 },
    { lat: 49.3058997, lng: 21.0373488 },
    { lat: 49.2983118, lng: 21.0335641 },
    { lat: 49.2982701, lng: 21.0336015 },
    { lat: 49.2972099, lng: 21.0330805 },
    { lat: 49.294972, lng: 21.0322565 },
    { lat: 49.2928223, lng: 21.0313467 },
    { lat: 49.2927929, lng: 21.0309209 },
    { lat: 49.2927131, lng: 21.0307514 },
    { lat: 49.2928482, lng: 21.0302328 },
    { lat: 49.2927988, lng: 21.029787 },
    { lat: 49.2928378, lng: 21.0296373 },
    { lat: 49.2926593, lng: 21.0293689 },
    { lat: 49.2926334, lng: 21.0291974 },
    { lat: 49.2925441, lng: 21.0290459 },
    { lat: 49.2922893, lng: 21.0287877 },
    { lat: 49.2920283, lng: 21.0283752 },
    { lat: 49.2911057, lng: 21.0275865 },
    { lat: 49.2907613, lng: 21.0270629 },
    { lat: 49.2904725, lng: 21.0269402 },
    { lat: 49.2901733, lng: 21.0269067 },
    { lat: 49.2898761, lng: 21.0267299 },
    { lat: 49.2893631, lng: 21.0260454 },
    { lat: 49.2883613, lng: 21.0244451 },
    { lat: 49.2876121, lng: 21.0243507 },
    { lat: 49.2875496, lng: 21.0238057 },
    { lat: 49.2873083, lng: 21.0233121 },
    { lat: 49.2867695, lng: 21.0232118 },
    { lat: 49.2867341, lng: 21.0233341 },
    { lat: 49.2865926, lng: 21.0228251 },
    { lat: 49.2861332, lng: 21.0222663 },
    { lat: 49.2856987, lng: 21.0215012 },
    { lat: 49.2851073, lng: 21.0208926 },
    { lat: 49.2846658, lng: 21.0212618 },
    { lat: 49.2843452, lng: 21.0214376 },
    { lat: 49.2828871, lng: 21.0205522 },
    { lat: 49.28256, lng: 21.0202844 },
    { lat: 49.2824031, lng: 21.020412 },
    { lat: 49.282202, lng: 21.0203859 },
    { lat: 49.2808043, lng: 21.0194191 },
    { lat: 49.2804478, lng: 21.0193579 },
    { lat: 49.280236, lng: 21.0194487 },
    { lat: 49.2799753, lng: 21.0197068 },
    { lat: 49.2794596, lng: 21.0198012 },
    { lat: 49.2793196, lng: 21.0199549 },
    { lat: 49.2791826, lng: 21.0203831 },
    { lat: 49.2790529, lng: 21.020615 },
    { lat: 49.2788785, lng: 21.0207562 },
    { lat: 49.2785011, lng: 21.020848 },
    { lat: 49.2782147, lng: 21.0211037 },
    { lat: 49.2776696, lng: 21.0211275 },
    { lat: 49.2773471, lng: 21.0216599 },
    { lat: 49.277241, lng: 21.0217173 },
    { lat: 49.2766589, lng: 21.0218834 },
    { lat: 49.2757182, lng: 21.0218875 },
    { lat: 49.2752439, lng: 21.0215884 },
    { lat: 49.2748124, lng: 21.0214378 },
    { lat: 49.274492, lng: 21.02107 },
    { lat: 49.2742933, lng: 21.0207024 },
    { lat: 49.2740655, lng: 21.0197899 },
    { lat: 49.274007, lng: 21.019377 },
    { lat: 49.274024, lng: 21.0188938 },
    { lat: 49.2740876, lng: 21.0185369 },
    { lat: 49.2740314, lng: 21.01802 },
    { lat: 49.2739443, lng: 21.0179023 },
    { lat: 49.2732435, lng: 21.017911 },
    { lat: 49.2730858, lng: 21.0179698 },
    { lat: 49.2718423, lng: 21.0188728 },
    { lat: 49.2716264, lng: 21.018775 },
    { lat: 49.2714536, lng: 21.0187907 },
    { lat: 49.2710403, lng: 21.0189547 },
    { lat: 49.2706315, lng: 21.0192165 },
    { lat: 49.2700627, lng: 21.0192014 },
    { lat: 49.2692668, lng: 21.0189852 },
    { lat: 49.2689292, lng: 21.0187496 },
    { lat: 49.2688256, lng: 21.0185362 },
    { lat: 49.2686786, lng: 21.0179268 },
    { lat: 49.2682485, lng: 21.0172962 },
    { lat: 49.267939, lng: 21.0172293 },
    { lat: 49.2675687, lng: 21.0169148 },
    { lat: 49.267199, lng: 21.0167584 },
    { lat: 49.2669822, lng: 21.0165302 },
    { lat: 49.26627, lng: 21.0167383 },
    { lat: 49.265874, lng: 21.0169544 },
    { lat: 49.2654271, lng: 21.0172951 },
    { lat: 49.265383, lng: 21.0179452 },
    { lat: 49.2653978, lng: 21.0184363 },
    { lat: 49.2650471, lng: 21.0193462 },
    { lat: 49.2648827, lng: 21.0204455 },
    { lat: 49.264992, lng: 21.0214854 },
    { lat: 49.2653621, lng: 21.0225898 },
    { lat: 49.2655666, lng: 21.0234463 },
    { lat: 49.2656742, lng: 21.0241206 },
    { lat: 49.2659571, lng: 21.0249312 },
    { lat: 49.2658227, lng: 21.0256401 },
    { lat: 49.2656379, lng: 21.0262613 },
    { lat: 49.2651214, lng: 21.0267801 },
    { lat: 49.2648064, lng: 21.0274699 },
    { lat: 49.2642666, lng: 21.028869 },
    { lat: 49.2641093, lng: 21.0301241 },
    { lat: 49.2642085, lng: 21.0312033 },
    { lat: 49.2644665, lng: 21.0322507 },
    { lat: 49.2640762, lng: 21.0327091 },
    { lat: 49.2640499, lng: 21.0328718 },
    { lat: 49.2637481, lng: 21.0333814 },
    { lat: 49.2635256, lng: 21.0330776 },
    { lat: 49.2633621, lng: 21.033317 },
    { lat: 49.2633894, lng: 21.0338738 },
    { lat: 49.2630457, lng: 21.0346259 },
    { lat: 49.2626841, lng: 21.034681 },
    { lat: 49.2623154, lng: 21.0358032 },
    { lat: 49.2627126, lng: 21.0367075 },
    { lat: 49.2629958, lng: 21.0371811 },
    { lat: 49.2631544, lng: 21.0375921 },
    { lat: 49.2632603, lng: 21.0377012 },
    { lat: 49.2634385, lng: 21.0381648 },
    { lat: 49.2635276, lng: 21.0387234 },
    { lat: 49.2635007, lng: 21.0393181 },
    { lat: 49.2634511, lng: 21.0398696 },
    { lat: 49.2633325, lng: 21.0403805 },
    { lat: 49.2631018, lng: 21.0410411 },
    { lat: 49.262994, lng: 21.0411202 },
    { lat: 49.2620743, lng: 21.041001 },
    { lat: 49.2617533, lng: 21.0416198 },
    { lat: 49.2615879, lng: 21.0423144 },
    { lat: 49.2615844, lng: 21.0428691 },
    { lat: 49.2616322, lng: 21.04313 },
    { lat: 49.2618688, lng: 21.043765 },
    { lat: 49.2624059, lng: 21.044645 },
    { lat: 49.2628824, lng: 21.0451812 },
    { lat: 49.2633587, lng: 21.0454637 },
    { lat: 49.2637683, lng: 21.0458514 },
    { lat: 49.2638958, lng: 21.045893 },
    { lat: 49.2646372, lng: 21.046585 },
    { lat: 49.2647784, lng: 21.0468096 },
    { lat: 49.2652157, lng: 21.0478054 },
    { lat: 49.2652634, lng: 21.0482659 },
    { lat: 49.2653539, lng: 21.048423 },
    { lat: 49.2655454, lng: 21.0483956 },
    { lat: 49.2657414, lng: 21.0480835 },
    { lat: 49.2658892, lng: 21.0479949 },
    { lat: 49.2660464, lng: 21.0480223 },
    { lat: 49.2662137, lng: 21.048286 },
    { lat: 49.2663985, lng: 21.0484306 },
    { lat: 49.2667191, lng: 21.0489808 },
    { lat: 49.2671707, lng: 21.049516 },
    { lat: 49.2673184, lng: 21.0498397 },
    { lat: 49.2672951, lng: 21.0503722 },
    { lat: 49.2673349, lng: 21.0513645 },
    { lat: 49.2675531, lng: 21.0516709 },
    { lat: 49.2683473, lng: 21.051593 },
    { lat: 49.2688194, lng: 21.0514031 },
    { lat: 49.2692863, lng: 21.0513647 },
    { lat: 49.2695935, lng: 21.0512247 },
    { lat: 49.2700729, lng: 21.051399 },
    { lat: 49.2702557, lng: 21.051658 },
    { lat: 49.2702898, lng: 21.051845 },
    { lat: 49.2706815, lng: 21.0517066 },
    { lat: 49.27096, lng: 21.0518593 },
    { lat: 49.2718559, lng: 21.0533284 },
    { lat: 49.2723315, lng: 21.0539117 },
    { lat: 49.2733384, lng: 21.0541096 },
    { lat: 49.2735897, lng: 21.0546479 },
    { lat: 49.2738223, lng: 21.0554034 },
    { lat: 49.2740191, lng: 21.0563724 },
    { lat: 49.2747386, lng: 21.0565276 },
    { lat: 49.2758765, lng: 21.0573 },
    { lat: 49.2764086, lng: 21.0571554 },
    { lat: 49.2773707, lng: 21.0567679 },
    { lat: 49.2782785, lng: 21.0568631 },
    { lat: 49.2795576, lng: 21.0572003 },
    { lat: 49.280091, lng: 21.0575523 },
    { lat: 49.2810448, lng: 21.0573954 },
    { lat: 49.2812757, lng: 21.0574425 },
    { lat: 49.2818941, lng: 21.0573213 },
    { lat: 49.2829504, lng: 21.0575503 },
    { lat: 49.283153, lng: 21.057765 },
    { lat: 49.283479, lng: 21.058523 },
    { lat: 49.2835797, lng: 21.0589162 },
    { lat: 49.2835437, lng: 21.0589193 },
    { lat: 49.2835197, lng: 21.0591179 },
    { lat: 49.2843324, lng: 21.0598613 },
    { lat: 49.2853649, lng: 21.0611677 },
    { lat: 49.2860769, lng: 21.061927 },
    { lat: 49.2864529, lng: 21.0619661 },
    { lat: 49.2870084, lng: 21.0618555 },
    { lat: 49.287709, lng: 21.0619065 },
    { lat: 49.2882779, lng: 21.062027 },
    { lat: 49.2887404, lng: 21.0622049 },
    { lat: 49.2888188, lng: 21.0623353 },
    { lat: 49.2898852, lng: 21.0627395 },
    { lat: 49.2902485, lng: 21.0635443 },
    { lat: 49.2903326, lng: 21.0638775 },
    { lat: 49.2904223, lng: 21.0646051 },
    { lat: 49.2904096, lng: 21.0650167 },
    { lat: 49.2928186, lng: 21.0652516 },
    { lat: 49.2928325, lng: 21.065407 },
    { lat: 49.2933036, lng: 21.0654999 },
    { lat: 49.2932395, lng: 21.0658829 },
    { lat: 49.2935522, lng: 21.0665051 },
    { lat: 49.2941938, lng: 21.0664457 },
    { lat: 49.2943301, lng: 21.0669024 },
    { lat: 49.2945034, lng: 21.0668342 },
    { lat: 49.2947366, lng: 21.0674004 },
    { lat: 49.2954978, lng: 21.0672386 },
    { lat: 49.2961978, lng: 21.0682918 },
    { lat: 49.2964505, lng: 21.0690148 },
    { lat: 49.2967022, lng: 21.0695252 },
    { lat: 49.2967107, lng: 21.06976 },
    { lat: 49.2968004, lng: 21.069989 },
    { lat: 49.2972865, lng: 21.0709412 },
    { lat: 49.2973247, lng: 21.0712188 },
    { lat: 49.2974747, lng: 21.0715429 },
    { lat: 49.2975515, lng: 21.0722817 },
    { lat: 49.2977009, lng: 21.0725592 },
    { lat: 49.2977213, lng: 21.0729817 },
    { lat: 49.2978212, lng: 21.0733979 },
    { lat: 49.2976793, lng: 21.0736415 },
    { lat: 49.2976641, lng: 21.0741084 },
    { lat: 49.2976587, lng: 21.0742278 },
    { lat: 49.2981566, lng: 21.0742384 },
    { lat: 49.2988379, lng: 21.0741148 },
    { lat: 49.2993099, lng: 21.0741346 },
    { lat: 49.2999424, lng: 21.0743067 },
    { lat: 49.3014753, lng: 21.075158 },
    { lat: 49.3025344, lng: 21.0759693 },
    { lat: 49.3030513, lng: 21.0761962 },
    { lat: 49.3037241, lng: 21.0761905 },
    { lat: 49.3039639, lng: 21.0761249 },
    { lat: 49.3053234, lng: 21.0753562 },
    { lat: 49.3055501, lng: 21.0752991 },
    { lat: 49.3057036, lng: 21.0752851 },
    { lat: 49.3057684, lng: 21.0757624 },
    { lat: 49.3076645, lng: 21.0752682 },
    { lat: 49.3081299, lng: 21.0750468 },
    { lat: 49.3081916, lng: 21.0749343 },
    { lat: 49.3090946, lng: 21.0750552 },
    { lat: 49.3094428, lng: 21.075279 },
    { lat: 49.3108013, lng: 21.0766572 },
    { lat: 49.3116504, lng: 21.0771709 },
    { lat: 49.3125921, lng: 21.078073 },
    { lat: 49.3135596, lng: 21.0784556 },
    { lat: 49.3140124, lng: 21.0788181 },
    { lat: 49.3142147, lng: 21.0790601 },
    { lat: 49.3145426, lng: 21.0796688 },
    { lat: 49.3154826, lng: 21.0820709 },
    { lat: 49.3158425, lng: 21.0824847 },
    { lat: 49.3167245, lng: 21.0832861 },
    { lat: 49.3171801, lng: 21.0838085 },
    { lat: 49.3172647, lng: 21.0839548 },
    { lat: 49.3175063, lng: 21.0850054 },
    { lat: 49.3175248, lng: 21.0850888 },
    { lat: 49.317843, lng: 21.0849755 },
    { lat: 49.3179832, lng: 21.0848532 },
    { lat: 49.3185438, lng: 21.0840534 },
    { lat: 49.3190129, lng: 21.0836958 },
    { lat: 49.3192486, lng: 21.0836042 },
    { lat: 49.3200682, lng: 21.082601 },
    { lat: 49.3203896, lng: 21.0820918 },
    { lat: 49.3208016, lng: 21.0809762 },
    { lat: 49.3210828, lng: 21.0803751 },
    { lat: 49.3214034, lng: 21.0799817 },
    { lat: 49.3216516, lng: 21.0794036 },
  ],
  Chmeľová: [
    { lat: 49.4208782, lng: 21.2823595 },
    { lat: 49.4204926, lng: 21.2815499 },
    { lat: 49.4198436, lng: 21.2804377 },
    { lat: 49.4189758, lng: 21.2800166 },
    { lat: 49.4187142, lng: 21.2800352 },
    { lat: 49.4188131, lng: 21.2794004 },
    { lat: 49.4184601, lng: 21.2787357 },
    { lat: 49.4171586, lng: 21.2770362 },
    { lat: 49.4163917, lng: 21.27593 },
    { lat: 49.4151319, lng: 21.2743852 },
    { lat: 49.4150006, lng: 21.2743241 },
    { lat: 49.4149901, lng: 21.2740083 },
    { lat: 49.414036, lng: 21.2735252 },
    { lat: 49.4140757, lng: 21.2733518 },
    { lat: 49.4139798, lng: 21.2731563 },
    { lat: 49.4139922, lng: 21.2729765 },
    { lat: 49.4139338, lng: 21.2726826 },
    { lat: 49.4138895, lng: 21.2727078 },
    { lat: 49.4133798, lng: 21.2694345 },
    { lat: 49.412713, lng: 21.268781 },
    { lat: 49.4123181, lng: 21.2685223 },
    { lat: 49.4119292, lng: 21.2671997 },
    { lat: 49.4116553, lng: 21.2669612 },
    { lat: 49.4112885, lng: 21.2665177 },
    { lat: 49.410805, lng: 21.2663036 },
    { lat: 49.4106636, lng: 21.2660578 },
    { lat: 49.4103693, lng: 21.2659603 },
    { lat: 49.4099262, lng: 21.2660414 },
    { lat: 49.4095703, lng: 21.2665131 },
    { lat: 49.4093479, lng: 21.2676364 },
    { lat: 49.4090234, lng: 21.2676562 },
    { lat: 49.4085494, lng: 21.2674285 },
    { lat: 49.4074721, lng: 21.2672925 },
    { lat: 49.405881, lng: 21.2678055 },
    { lat: 49.4053366, lng: 21.2678834 },
    { lat: 49.4050885, lng: 21.2680116 },
    { lat: 49.404859, lng: 21.2680468 },
    { lat: 49.4045751, lng: 21.268257 },
    { lat: 49.4031199, lng: 21.2676574 },
    { lat: 49.4023803, lng: 21.2670421 },
    { lat: 49.4015938, lng: 21.2668948 },
    { lat: 49.4014466, lng: 21.2670881 },
    { lat: 49.401151, lng: 21.2667248 },
    { lat: 49.4007993, lng: 21.2659638 },
    { lat: 49.3998272, lng: 21.2668564 },
    { lat: 49.3992016, lng: 21.2672547 },
    { lat: 49.3987517, lng: 21.2674103 },
    { lat: 49.3983534, lng: 21.2673369 },
    { lat: 49.3981207, lng: 21.2674514 },
    { lat: 49.3979536, lng: 21.2677714 },
    { lat: 49.397168, lng: 21.2686875 },
    { lat: 49.3970488, lng: 21.2692819 },
    { lat: 49.3962782, lng: 21.2700636 },
    { lat: 49.3962485, lng: 21.2702723 },
    { lat: 49.395042, lng: 21.2699595 },
    { lat: 49.3940989, lng: 21.2704323 },
    { lat: 49.392251, lng: 21.2717512 },
    { lat: 49.3903087, lng: 21.2749317 },
    { lat: 49.390078, lng: 21.2749351 },
    { lat: 49.3897852, lng: 21.2752536 },
    { lat: 49.3894306, lng: 21.275433 },
    { lat: 49.3893177, lng: 21.2759767 },
    { lat: 49.3887351, lng: 21.2766162 },
    { lat: 49.3869603, lng: 21.2780115 },
    { lat: 49.3863879, lng: 21.2786195 },
    { lat: 49.3863161, lng: 21.2803683 },
    { lat: 49.3862005, lng: 21.2817207 },
    { lat: 49.3862781, lng: 21.2819387 },
    { lat: 49.386402, lng: 21.2845464 },
    { lat: 49.386368, lng: 21.2873319 },
    { lat: 49.3862348, lng: 21.288956 },
    { lat: 49.3863375, lng: 21.2897501 },
    { lat: 49.3863524, lng: 21.2904699 },
    { lat: 49.3862414, lng: 21.2908533 },
    { lat: 49.3864786, lng: 21.2959229 },
    { lat: 49.3866315, lng: 21.2973347 },
    { lat: 49.3870755, lng: 21.2979631 },
    { lat: 49.3872545, lng: 21.2984091 },
    { lat: 49.3874496, lng: 21.2986117 },
    { lat: 49.3880906, lng: 21.300773 },
    { lat: 49.3893479, lng: 21.3042692 },
    { lat: 49.3892165, lng: 21.3052128 },
    { lat: 49.3891918, lng: 21.3082154 },
    { lat: 49.389786, lng: 21.3113677 },
    { lat: 49.3897497, lng: 21.3148826 },
    { lat: 49.3896781, lng: 21.3156486 },
    { lat: 49.3900406, lng: 21.3197145 },
    { lat: 49.3901079, lng: 21.3250106 },
    { lat: 49.3900835, lng: 21.3260012 },
    { lat: 49.3913354, lng: 21.3261828 },
    { lat: 49.3917355, lng: 21.3261681 },
    { lat: 49.39191, lng: 21.3269263 },
    { lat: 49.3927977, lng: 21.3280971 },
    { lat: 49.3943387, lng: 21.3303835 },
    { lat: 49.3944075, lng: 21.330841 },
    { lat: 49.3941176, lng: 21.3322118 },
    { lat: 49.3934356, lng: 21.3333377 },
    { lat: 49.3941198, lng: 21.334178 },
    { lat: 49.3945506, lng: 21.3338698 },
    { lat: 49.3952886, lng: 21.3338272 },
    { lat: 49.3969312, lng: 21.3338686 },
    { lat: 49.3982977, lng: 21.3329469 },
    { lat: 49.3997169, lng: 21.3338319 },
    { lat: 49.4005418, lng: 21.3339158 },
    { lat: 49.4030986, lng: 21.3349544 },
    { lat: 49.4035738, lng: 21.3350479 },
    { lat: 49.4051482, lng: 21.3355567 },
    { lat: 49.40637, lng: 21.3362332 },
    { lat: 49.4075027, lng: 21.3362605 },
    { lat: 49.4075369, lng: 21.3360898 },
    { lat: 49.4071818, lng: 21.3345911 },
    { lat: 49.4071996, lng: 21.333907 },
    { lat: 49.4071042, lng: 21.3331972 },
    { lat: 49.4068715, lng: 21.3322951 },
    { lat: 49.4068022, lng: 21.331598 },
    { lat: 49.4067683, lng: 21.3303424 },
    { lat: 49.4068268, lng: 21.3286836 },
    { lat: 49.4068297, lng: 21.3263325 },
    { lat: 49.406746, lng: 21.3245872 },
    { lat: 49.4067388, lng: 21.3231787 },
    { lat: 49.4066476, lng: 21.3218144 },
    { lat: 49.4066899, lng: 21.3200776 },
    { lat: 49.4068029, lng: 21.3194872 },
    { lat: 49.4077073, lng: 21.3166312 },
    { lat: 49.4079231, lng: 21.3157601 },
    { lat: 49.408543, lng: 21.3142489 },
    { lat: 49.4101124, lng: 21.3077544 },
    { lat: 49.4103654, lng: 21.3058414 },
    { lat: 49.4105917, lng: 21.3035647 },
    { lat: 49.4108924, lng: 21.30309 },
    { lat: 49.4114437, lng: 21.3015952 },
    { lat: 49.4121301, lng: 21.3003576 },
    { lat: 49.4133527, lng: 21.2984754 },
    { lat: 49.415035, lng: 21.2957254 },
    { lat: 49.4174266, lng: 21.2916028 },
    { lat: 49.4185854, lng: 21.2898551 },
    { lat: 49.4194062, lng: 21.2874497 },
    { lat: 49.4204902, lng: 21.2849277 },
    { lat: 49.4207277, lng: 21.2840406 },
    { lat: 49.4205692, lng: 21.2838606 },
    { lat: 49.4208782, lng: 21.2823595 },
  ],
  Regetovka: [
    { lat: 49.4208782, lng: 21.2823595 },
    { lat: 49.4211961, lng: 21.2828216 },
    { lat: 49.42204, lng: 21.284058 },
    { lat: 49.4226003, lng: 21.2849843 },
    { lat: 49.4247677, lng: 21.2872043 },
    { lat: 49.4252112, lng: 21.2874724 },
    { lat: 49.4262452, lng: 21.2873232 },
    { lat: 49.426889, lng: 21.2874857 },
    { lat: 49.4271222, lng: 21.2876412 },
    { lat: 49.4272881, lng: 21.2882952 },
    { lat: 49.427543, lng: 21.2886502 },
    { lat: 49.4277984, lng: 21.2887231 },
    { lat: 49.428098, lng: 21.2889636 },
    { lat: 49.4296662, lng: 21.2886568 },
    { lat: 49.4300243, lng: 21.288484 },
    { lat: 49.4301584, lng: 21.288224 },
    { lat: 49.4303742, lng: 21.2881082 },
    { lat: 49.4307703, lng: 21.2875874 },
    { lat: 49.4310744, lng: 21.2875235 },
    { lat: 49.4312191, lng: 21.2873278 },
    { lat: 49.4315982, lng: 21.2871003 },
    { lat: 49.4338332, lng: 21.2843202 },
    { lat: 49.4341794, lng: 21.2834592 },
    { lat: 49.434694, lng: 21.2826136 },
    { lat: 49.4356893, lng: 21.2813683 },
    { lat: 49.4360973, lng: 21.2810227 },
    { lat: 49.4370567, lng: 21.2806461 },
    { lat: 49.4385657, lng: 21.280353 },
    { lat: 49.4397679, lng: 21.2795224 },
    { lat: 49.4405989, lng: 21.2785757 },
    { lat: 49.4415778, lng: 21.2773162 },
    { lat: 49.4428656, lng: 21.2749967 },
    { lat: 49.4430849, lng: 21.2744691 },
    { lat: 49.4436898, lng: 21.2733844 },
    { lat: 49.4440461, lng: 21.272514 },
    { lat: 49.4445867, lng: 21.2708282 },
    { lat: 49.4449982, lng: 21.2697423 },
    { lat: 49.4454991, lng: 21.2687108 },
    { lat: 49.4465929, lng: 21.2669355 },
    { lat: 49.4469846, lng: 21.2659192 },
    { lat: 49.4472089, lng: 21.2640579 },
    { lat: 49.447227, lng: 21.263144 },
    { lat: 49.4472278, lng: 21.2631299 },
    { lat: 49.447025, lng: 21.261665 },
    { lat: 49.446794, lng: 21.260012 },
    { lat: 49.446743, lng: 21.259648 },
    { lat: 49.4466924, lng: 21.2592777 },
    { lat: 49.446347, lng: 21.2585653 },
    { lat: 49.4460447, lng: 21.2581295 },
    { lat: 49.4458963, lng: 21.2579133 },
    { lat: 49.4456127, lng: 21.2577359 },
    { lat: 49.4448782, lng: 21.2566468 },
    { lat: 49.4441137, lng: 21.2557952 },
    { lat: 49.4434059, lng: 21.255096 },
    { lat: 49.4430122, lng: 21.2544477 },
    { lat: 49.4427155, lng: 21.2538252 },
    { lat: 49.442364, lng: 21.2534737 },
    { lat: 49.4423011, lng: 21.2533488 },
    { lat: 49.442132, lng: 21.2530077 },
    { lat: 49.4415657, lng: 21.2522564 },
    { lat: 49.4413557, lng: 21.2516912 },
    { lat: 49.4411885, lng: 21.2515382 },
    { lat: 49.4405301, lng: 21.2501139 },
    { lat: 49.4404663, lng: 21.24983 },
    { lat: 49.4399434, lng: 21.2487609 },
    { lat: 49.439683, lng: 21.248122 },
    { lat: 49.4395514, lng: 21.2477981 },
    { lat: 49.4393663, lng: 21.247067 },
    { lat: 49.439266, lng: 21.246891 },
    { lat: 49.4392126, lng: 21.246795 },
    { lat: 49.4391501, lng: 21.2467526 },
    { lat: 49.4388903, lng: 21.2466649 },
    { lat: 49.4383038, lng: 21.2462819 },
    { lat: 49.4378523, lng: 21.2458768 },
    { lat: 49.4375358, lng: 21.245713 },
    { lat: 49.4373198, lng: 21.2454959 },
    { lat: 49.4371168, lng: 21.2450844 },
    { lat: 49.436889, lng: 21.2447687 },
    { lat: 49.4365645, lng: 21.2442075 },
    { lat: 49.436299, lng: 21.2438558 },
    { lat: 49.436294, lng: 21.243852 },
    { lat: 49.436075, lng: 21.243522 },
    { lat: 49.4355024, lng: 21.2426655 },
    { lat: 49.4352594, lng: 21.2420771 },
    { lat: 49.4350462, lng: 21.2417769 },
    { lat: 49.4348092, lng: 21.2415636 },
    { lat: 49.434387, lng: 21.241014 },
    { lat: 49.4343851, lng: 21.2410083 },
    { lat: 49.4341837, lng: 21.2406693 },
    { lat: 49.4338616, lng: 21.2414499 },
    { lat: 49.4332629, lng: 21.2424422 },
    { lat: 49.4330303, lng: 21.2431089 },
    { lat: 49.4326512, lng: 21.2439285 },
    { lat: 49.432476, lng: 21.2445986 },
    { lat: 49.4324395, lng: 21.2456889 },
    { lat: 49.432222, lng: 21.2464883 },
    { lat: 49.4318136, lng: 21.2472283 },
    { lat: 49.4317985, lng: 21.2488636 },
    { lat: 49.4316985, lng: 21.2495757 },
    { lat: 49.4308119, lng: 21.2510465 },
    { lat: 49.4302757, lng: 21.2510994 },
    { lat: 49.4298012, lng: 21.251844 },
    { lat: 49.4297004, lng: 21.2521585 },
    { lat: 49.429366, lng: 21.2523729 },
    { lat: 49.4292847, lng: 21.2528743 },
    { lat: 49.4290717, lng: 21.2533841 },
    { lat: 49.4285714, lng: 21.2540933 },
    { lat: 49.4268747, lng: 21.2556841 },
    { lat: 49.4264071, lng: 21.2559228 },
    { lat: 49.424813, lng: 21.257149 },
    { lat: 49.4242315, lng: 21.2577425 },
    { lat: 49.4235441, lng: 21.258932 },
    { lat: 49.4232071, lng: 21.259422 },
    { lat: 49.4224129, lng: 21.2602084 },
    { lat: 49.4218412, lng: 21.2604979 },
    { lat: 49.4207527, lng: 21.2613661 },
    { lat: 49.4203153, lng: 21.2617823 },
    { lat: 49.419879, lng: 21.2620501 },
    { lat: 49.4187966, lng: 21.2630793 },
    { lat: 49.4184585, lng: 21.2628075 },
    { lat: 49.4180853, lng: 21.2628172 },
    { lat: 49.4180068, lng: 21.2627173 },
    { lat: 49.4178021, lng: 21.2627521 },
    { lat: 49.4175759, lng: 21.2630451 },
    { lat: 49.4171032, lng: 21.2631934 },
    { lat: 49.4167582, lng: 21.2635368 },
    { lat: 49.4158831, lng: 21.2646967 },
    { lat: 49.4145044, lng: 21.2646726 },
    { lat: 49.4136182, lng: 21.2650644 },
    { lat: 49.4134179, lng: 21.2653941 },
    { lat: 49.4126632, lng: 21.2660669 },
    { lat: 49.4121797, lng: 21.2666662 },
    { lat: 49.4119292, lng: 21.2671997 },
    { lat: 49.4123181, lng: 21.2685223 },
    { lat: 49.412713, lng: 21.268781 },
    { lat: 49.4133798, lng: 21.2694345 },
    { lat: 49.4138895, lng: 21.2727078 },
    { lat: 49.4139338, lng: 21.2726826 },
    { lat: 49.4139922, lng: 21.2729765 },
    { lat: 49.4139798, lng: 21.2731563 },
    { lat: 49.4140757, lng: 21.2733518 },
    { lat: 49.414036, lng: 21.2735252 },
    { lat: 49.4149901, lng: 21.2740083 },
    { lat: 49.4150006, lng: 21.2743241 },
    { lat: 49.4151319, lng: 21.2743852 },
    { lat: 49.4163917, lng: 21.27593 },
    { lat: 49.4171586, lng: 21.2770362 },
    { lat: 49.4184601, lng: 21.2787357 },
    { lat: 49.4188131, lng: 21.2794004 },
    { lat: 49.4187142, lng: 21.2800352 },
    { lat: 49.4189758, lng: 21.2800166 },
    { lat: 49.4198436, lng: 21.2804377 },
    { lat: 49.4204926, lng: 21.2815499 },
    { lat: 49.4208782, lng: 21.2823595 },
  ],
  Tročany: [
    { lat: 49.1710088, lng: 21.3207335 },
    { lat: 49.1708166, lng: 21.3228416 },
    { lat: 49.1712486, lng: 21.3230225 },
    { lat: 49.1715772, lng: 21.3226931 },
    { lat: 49.1720922, lng: 21.326014 },
    { lat: 49.1710649, lng: 21.326501 },
    { lat: 49.1708631, lng: 21.3267445 },
    { lat: 49.170561, lng: 21.3274024 },
    { lat: 49.1705145, lng: 21.3279907 },
    { lat: 49.1705721, lng: 21.3290144 },
    { lat: 49.170955, lng: 21.3297 },
    { lat: 49.1711312, lng: 21.3302113 },
    { lat: 49.172484, lng: 21.3309728 },
    { lat: 49.171612, lng: 21.3331872 },
    { lat: 49.1715205, lng: 21.3342644 },
    { lat: 49.173885, lng: 21.3352929 },
    { lat: 49.1743621, lng: 21.3351885 },
    { lat: 49.1747455, lng: 21.3350212 },
    { lat: 49.1754983, lng: 21.336076 },
    { lat: 49.1763678, lng: 21.3369743 },
    { lat: 49.1758168, lng: 21.3376197 },
    { lat: 49.1762221, lng: 21.3386448 },
    { lat: 49.1764981, lng: 21.3390348 },
    { lat: 49.1765325, lng: 21.3394291 },
    { lat: 49.1764856, lng: 21.3401243 },
    { lat: 49.176944, lng: 21.3417568 },
    { lat: 49.1771539, lng: 21.3418141 },
    { lat: 49.1774214, lng: 21.3444805 },
    { lat: 49.1774246, lng: 21.3457156 },
    { lat: 49.1789278, lng: 21.3489214 },
    { lat: 49.179423, lng: 21.3504357 },
    { lat: 49.1796558, lng: 21.351871 },
    { lat: 49.1801943, lng: 21.3504228 },
    { lat: 49.1812289, lng: 21.3491164 },
    { lat: 49.182536, lng: 21.3488387 },
    { lat: 49.1839647, lng: 21.3472883 },
    { lat: 49.1855328, lng: 21.3487492 },
    { lat: 49.1857369, lng: 21.349781 },
    { lat: 49.1858806, lng: 21.3497703 },
    { lat: 49.1862595, lng: 21.3501012 },
    { lat: 49.1869473, lng: 21.3502211 },
    { lat: 49.1872939, lng: 21.3498917 },
    { lat: 49.1879495, lng: 21.3498728 },
    { lat: 49.1885466, lng: 21.3514911 },
    { lat: 49.1887566, lng: 21.3513604 },
    { lat: 49.1896621, lng: 21.3515379 },
    { lat: 49.1899769, lng: 21.3521733 },
    { lat: 49.1902902, lng: 21.3521879 },
    { lat: 49.1905538, lng: 21.3525063 },
    { lat: 49.1908787, lng: 21.3519266 },
    { lat: 49.1914673, lng: 21.3512509 },
    { lat: 49.1916902, lng: 21.3513325 },
    { lat: 49.1919582, lng: 21.3513078 },
    { lat: 49.1920719, lng: 21.3509668 },
    { lat: 49.1920248, lng: 21.3506676 },
    { lat: 49.1921517, lng: 21.3504732 },
    { lat: 49.1931794, lng: 21.3497669 },
    { lat: 49.1935818, lng: 21.3496084 },
    { lat: 49.1938277, lng: 21.3497403 },
    { lat: 49.1938425, lng: 21.3500098 },
    { lat: 49.1949506, lng: 21.3504193 },
    { lat: 49.1950945, lng: 21.3502622 },
    { lat: 49.1957614, lng: 21.351573 },
    { lat: 49.1965006, lng: 21.3504043 },
    { lat: 49.1971532, lng: 21.3501738 },
    { lat: 49.1978586, lng: 21.3498309 },
    { lat: 49.1986222, lng: 21.3491408 },
    { lat: 49.1993718, lng: 21.3482825 },
    { lat: 49.2000625, lng: 21.3470674 },
    { lat: 49.2005638, lng: 21.3464522 },
    { lat: 49.2010064, lng: 21.3457418 },
    { lat: 49.201628, lng: 21.3449334 },
    { lat: 49.2021003, lng: 21.344626 },
    { lat: 49.2029547, lng: 21.3439208 },
    { lat: 49.2040045, lng: 21.3441476 },
    { lat: 49.2049289, lng: 21.3441683 },
    { lat: 49.2066325, lng: 21.3433439 },
    { lat: 49.2067997, lng: 21.3430699 },
    { lat: 49.2072505, lng: 21.3431332 },
    { lat: 49.2070573, lng: 21.3401985 },
    { lat: 49.206483, lng: 21.3373209 },
    { lat: 49.2054723, lng: 21.3367173 },
    { lat: 49.2052439, lng: 21.336491 },
    { lat: 49.2050968, lng: 21.336158 },
    { lat: 49.2051022, lng: 21.3343851 },
    { lat: 49.2049403, lng: 21.3326951 },
    { lat: 49.2045987, lng: 21.3321129 },
    { lat: 49.204196, lng: 21.3315837 },
    { lat: 49.2039523, lng: 21.3310753 },
    { lat: 49.2036266, lng: 21.3301222 },
    { lat: 49.2034172, lng: 21.3298921 },
    { lat: 49.203011, lng: 21.3295862 },
    { lat: 49.2028443, lng: 21.3292071 },
    { lat: 49.2025379, lng: 21.3287071 },
    { lat: 49.2021627, lng: 21.3277528 },
    { lat: 49.2020785, lng: 21.3271567 },
    { lat: 49.2021608, lng: 21.3266753 },
    { lat: 49.2013959, lng: 21.3254089 },
    { lat: 49.2011816, lng: 21.3248608 },
    { lat: 49.2009934, lng: 21.3240252 },
    { lat: 49.2009815, lng: 21.3234813 },
    { lat: 49.2011217, lng: 21.3229436 },
    { lat: 49.2012953, lng: 21.3226152 },
    { lat: 49.2012252, lng: 21.3216307 },
    { lat: 49.2011868, lng: 21.3212498 },
    { lat: 49.2010456, lng: 21.3208272 },
    { lat: 49.2009299, lng: 21.3199656 },
    { lat: 49.2008547, lng: 21.3197834 },
    { lat: 49.2007347, lng: 21.3191658 },
    { lat: 49.2006789, lng: 21.31858 },
    { lat: 49.2007632, lng: 21.3181675 },
    { lat: 49.2009493, lng: 21.3164608 },
    { lat: 49.2013476, lng: 21.3145907 },
    { lat: 49.201414, lng: 21.3135077 },
    { lat: 49.2009754, lng: 21.3129102 },
    { lat: 49.200822, lng: 21.3127875 },
    { lat: 49.2004861, lng: 21.3127283 },
    { lat: 49.1995647, lng: 21.3115386 },
    { lat: 49.1997468, lng: 21.3109401 },
    { lat: 49.1994974, lng: 21.310423 },
    { lat: 49.19927, lng: 21.3102734 },
    { lat: 49.1988615, lng: 21.3103834 },
    { lat: 49.19658, lng: 21.3094979 },
    { lat: 49.1949407, lng: 21.3097266 },
    { lat: 49.1944667, lng: 21.3101635 },
    { lat: 49.1944182, lng: 21.3104623 },
    { lat: 49.1937913, lng: 21.3108218 },
    { lat: 49.1931521, lng: 21.3113073 },
    { lat: 49.1925198, lng: 21.3116481 },
    { lat: 49.1923821, lng: 21.3118571 },
    { lat: 49.1914754, lng: 21.3120505 },
    { lat: 49.1908237, lng: 21.311853 },
    { lat: 49.1901661, lng: 21.3122531 },
    { lat: 49.1897148, lng: 21.3120952 },
    { lat: 49.1875368, lng: 21.3125162 },
    { lat: 49.1873871, lng: 21.3128533 },
    { lat: 49.186915, lng: 21.3130551 },
    { lat: 49.1868714, lng: 21.3134761 },
    { lat: 49.1863997, lng: 21.3136915 },
    { lat: 49.1860524, lng: 21.3136684 },
    { lat: 49.1858673, lng: 21.3133163 },
    { lat: 49.185883, lng: 21.3128976 },
    { lat: 49.1857643, lng: 21.312848 },
    { lat: 49.1855424, lng: 21.3129092 },
    { lat: 49.1854865, lng: 21.3130728 },
    { lat: 49.1852008, lng: 21.3133321 },
    { lat: 49.1848985, lng: 21.3130892 },
    { lat: 49.184475, lng: 21.3129614 },
    { lat: 49.1842637, lng: 21.3125215 },
    { lat: 49.1840667, lng: 21.3128936 },
    { lat: 49.1833842, lng: 21.3130551 },
    { lat: 49.1831597, lng: 21.3129982 },
    { lat: 49.1830419, lng: 21.3125244 },
    { lat: 49.1827181, lng: 21.3125363 },
    { lat: 49.1824712, lng: 21.3122551 },
    { lat: 49.1822697, lng: 21.3123667 },
    { lat: 49.1821884, lng: 21.3119063 },
    { lat: 49.1817276, lng: 21.3123727 },
    { lat: 49.1808985, lng: 21.3122294 },
    { lat: 49.1806497, lng: 21.3118904 },
    { lat: 49.1805847, lng: 21.3115238 },
    { lat: 49.1807211, lng: 21.3112337 },
    { lat: 49.180881, lng: 21.3112024 },
    { lat: 49.1805319, lng: 21.3107042 },
    { lat: 49.180107, lng: 21.3104938 },
    { lat: 49.1802778, lng: 21.3111609 },
    { lat: 49.1802972, lng: 21.3116878 },
    { lat: 49.1803608, lng: 21.3118254 },
    { lat: 49.1801473, lng: 21.3122842 },
    { lat: 49.1796726, lng: 21.3130318 },
    { lat: 49.1801014, lng: 21.3139678 },
    { lat: 49.1803046, lng: 21.314702 },
    { lat: 49.1801985, lng: 21.3152902 },
    { lat: 49.1796072, lng: 21.3158341 },
    { lat: 49.1796328, lng: 21.3166038 },
    { lat: 49.1797597, lng: 21.3173444 },
    { lat: 49.1800626, lng: 21.3177238 },
    { lat: 49.1800961, lng: 21.3180919 },
    { lat: 49.1801657, lng: 21.3180867 },
    { lat: 49.1802711, lng: 21.31822 },
    { lat: 49.180422, lng: 21.318527 },
    { lat: 49.1803421, lng: 21.3185995 },
    { lat: 49.1803036, lng: 21.3190032 },
    { lat: 49.1798955, lng: 21.3189418 },
    { lat: 49.1792784, lng: 21.3176147 },
    { lat: 49.1784456, lng: 21.3177237 },
    { lat: 49.1781862, lng: 21.3178875 },
    { lat: 49.1778168, lng: 21.3182507 },
    { lat: 49.1776347, lng: 21.3189676 },
    { lat: 49.1770862, lng: 21.3198828 },
    { lat: 49.176218, lng: 21.3208393 },
    { lat: 49.174849, lng: 21.321221 },
    { lat: 49.1745607, lng: 21.3207671 },
    { lat: 49.1733651, lng: 21.3222043 },
    { lat: 49.1719731, lng: 21.321782 },
    { lat: 49.1710088, lng: 21.3207335 },
  ],
  Poliakovce: [
    { lat: 49.2374097, lng: 21.3992609 },
    { lat: 49.2372729, lng: 21.3995903 },
    { lat: 49.2369559, lng: 21.4004351 },
    { lat: 49.236167, lng: 21.4021165 },
    { lat: 49.2361024, lng: 21.4034829 },
    { lat: 49.2366843, lng: 21.4066051 },
    { lat: 49.2367109, lng: 21.407741 },
    { lat: 49.2366195, lng: 21.4083071 },
    { lat: 49.2367138, lng: 21.4082675 },
    { lat: 49.2369982, lng: 21.4093315 },
    { lat: 49.2380478, lng: 21.4098327 },
    { lat: 49.2387044, lng: 21.4099683 },
    { lat: 49.2393716, lng: 21.4093186 },
    { lat: 49.2397284, lng: 21.4095546 },
    { lat: 49.2415731, lng: 21.4095346 },
    { lat: 49.2416379, lng: 21.4101776 },
    { lat: 49.2419581, lng: 21.4105832 },
    { lat: 49.242271, lng: 21.4105525 },
    { lat: 49.242975, lng: 21.4121986 },
    { lat: 49.2432895, lng: 21.4127526 },
    { lat: 49.2434543, lng: 21.4127716 },
    { lat: 49.2443914, lng: 21.4138104 },
    { lat: 49.2451584, lng: 21.4149897 },
    { lat: 49.2457699, lng: 21.4154734 },
    { lat: 49.2461924, lng: 21.4159999 },
    { lat: 49.2466508, lng: 21.4162845 },
    { lat: 49.2471944, lng: 21.4163321 },
    { lat: 49.2476031, lng: 21.4157219 },
    { lat: 49.2477532, lng: 21.4157366 },
    { lat: 49.2480242, lng: 21.4159255 },
    { lat: 49.248297, lng: 21.4162901 },
    { lat: 49.2509598, lng: 21.4181688 },
    { lat: 49.2510259, lng: 21.4183181 },
    { lat: 49.2526005, lng: 21.419417 },
    { lat: 49.2532585, lng: 21.419951 },
    { lat: 49.2541173, lng: 21.4220111 },
    { lat: 49.2552371, lng: 21.4217509 },
    { lat: 49.2556377, lng: 21.4214467 },
    { lat: 49.2562428, lng: 21.4207471 },
    { lat: 49.2565283, lng: 21.4205858 },
    { lat: 49.25687, lng: 21.4206544 },
    { lat: 49.2571755, lng: 21.4205947 },
    { lat: 49.2574709, lng: 21.4204572 },
    { lat: 49.2584887, lng: 21.4196736 },
    { lat: 49.25996, lng: 21.4192028 },
    { lat: 49.2615624, lng: 21.4180963 },
    { lat: 49.2623874, lng: 21.4163708 },
    { lat: 49.2631081, lng: 21.4177414 },
    { lat: 49.2656145, lng: 21.4141523 },
    { lat: 49.2663927, lng: 21.411714 },
    { lat: 49.2672898, lng: 21.4099945 },
    { lat: 49.2683948, lng: 21.4081563 },
    { lat: 49.2692088, lng: 21.4072926 },
    { lat: 49.2707391, lng: 21.4048775 },
    { lat: 49.2697899, lng: 21.4042722 },
    { lat: 49.2685133, lng: 21.4031685 },
    { lat: 49.2682046, lng: 21.4016592 },
    { lat: 49.267542, lng: 21.4004349 },
    { lat: 49.2675426, lng: 21.3997641 },
    { lat: 49.2663557, lng: 21.3981118 },
    { lat: 49.2660085, lng: 21.3984342 },
    { lat: 49.2658692, lng: 21.3984045 },
    { lat: 49.2658458, lng: 21.3984504 },
    { lat: 49.2653893, lng: 21.3982702 },
    { lat: 49.2642686, lng: 21.3974236 },
    { lat: 49.2646146, lng: 21.3941022 },
    { lat: 49.2646883, lng: 21.3928244 },
    { lat: 49.2647451, lng: 21.3873311 },
    { lat: 49.2644173, lng: 21.3870146 },
    { lat: 49.2628377, lng: 21.3850273 },
    { lat: 49.2630085, lng: 21.3839503 },
    { lat: 49.2622026, lng: 21.383134 },
    { lat: 49.2602168, lng: 21.3813013 },
    { lat: 49.2587207, lng: 21.3788338 },
    { lat: 49.2570907, lng: 21.3758322 },
    { lat: 49.2551773, lng: 21.3737497 },
    { lat: 49.254082, lng: 21.3755151 },
    { lat: 49.2535099, lng: 21.3765249 },
    { lat: 49.2530571, lng: 21.3776946 },
    { lat: 49.2522274, lng: 21.378932 },
    { lat: 49.2518052, lng: 21.3800282 },
    { lat: 49.251442, lng: 21.3806067 },
    { lat: 49.2496546, lng: 21.3824883 },
    { lat: 49.2451699, lng: 21.3882924 },
    { lat: 49.2441127, lng: 21.3894903 },
    { lat: 49.2416255, lng: 21.3920671 },
    { lat: 49.2406771, lng: 21.3931357 },
    { lat: 49.2404216, lng: 21.3939399 },
    { lat: 49.2399381, lng: 21.394712 },
    { lat: 49.239413, lng: 21.3961799 },
    { lat: 49.2388878, lng: 21.3968791 },
    { lat: 49.2378789, lng: 21.398001 },
    { lat: 49.2374097, lng: 21.3992609 },
  ],
  Lukov: [
    { lat: 49.277018, lng: 20.990024 },
    { lat: 49.275175, lng: 20.991352 },
    { lat: 49.274989, lng: 20.990987 },
    { lat: 49.273536, lng: 20.990564 },
    { lat: 49.273017, lng: 20.990944 },
    { lat: 49.272653, lng: 20.991399 },
    { lat: 49.272124, lng: 20.992411 },
    { lat: 49.27092, lng: 20.994868 },
    { lat: 49.269598, lng: 20.995619 },
    { lat: 49.267767, lng: 20.996083 },
    { lat: 49.266828, lng: 20.996222 },
    { lat: 49.265694, lng: 20.996636 },
    { lat: 49.265083, lng: 20.996691 },
    { lat: 49.26454, lng: 20.996564 },
    { lat: 49.2635, lng: 20.99663 },
    { lat: 49.26244, lng: 20.995976 },
    { lat: 49.261644, lng: 20.997039 },
    { lat: 49.260848, lng: 20.997071 },
    { lat: 49.260353, lng: 20.997189 },
    { lat: 49.260105, lng: 20.997213 },
    { lat: 49.26004, lng: 20.99734 },
    { lat: 49.259598, lng: 20.997612 },
    { lat: 49.259358, lng: 20.997831 },
    { lat: 49.259028, lng: 20.997864 },
    { lat: 49.258961, lng: 20.997875 },
    { lat: 49.258367, lng: 20.998229 },
    { lat: 49.258182, lng: 20.998402 },
    { lat: 49.257546, lng: 20.999178 },
    { lat: 49.25716, lng: 20.999653 },
    { lat: 49.257018, lng: 21.000141 },
    { lat: 49.256912, lng: 21.000413 },
    { lat: 49.256772, lng: 21.000719 },
    { lat: 49.256668, lng: 21.000982 },
    { lat: 49.256569, lng: 21.001425 },
    { lat: 49.256338, lng: 21.002228 },
    { lat: 49.256297, lng: 21.002369 },
    { lat: 49.255971, lng: 21.003282 },
    { lat: 49.25559, lng: 21.003818 },
    { lat: 49.255431, lng: 21.004095 },
    { lat: 49.255329, lng: 21.004291 },
    { lat: 49.2558153, lng: 21.0054004 },
    { lat: 49.2557762, lng: 21.0058577 },
    { lat: 49.2559757, lng: 21.0067583 },
    { lat: 49.2562508, lng: 21.0072597 },
    { lat: 49.2565123, lng: 21.0078886 },
    { lat: 49.2567684, lng: 21.0089776 },
    { lat: 49.2572321, lng: 21.0101132 },
    { lat: 49.2573754, lng: 21.0106438 },
    { lat: 49.2576138, lng: 21.0110846 },
    { lat: 49.2589903, lng: 21.0124025 },
    { lat: 49.2600476, lng: 21.013873 },
    { lat: 49.2601692, lng: 21.0141145 },
    { lat: 49.2603842, lng: 21.0150108 },
    { lat: 49.2603857, lng: 21.0155411 },
    { lat: 49.2607088, lng: 21.0176823 },
    { lat: 49.2610254, lng: 21.0182505 },
    { lat: 49.2610663, lng: 21.0197434 },
    { lat: 49.2608735, lng: 21.0209068 },
    { lat: 49.260777, lng: 21.0211202 },
    { lat: 49.2606344, lng: 21.0212759 },
    { lat: 49.2601831, lng: 21.0223781 },
    { lat: 49.2598217, lng: 21.0229605 },
    { lat: 49.2595769, lng: 21.0237 },
    { lat: 49.2594848, lng: 21.0242597 },
    { lat: 49.2598478, lng: 21.0252478 },
    { lat: 49.2601965, lng: 21.0257953 },
    { lat: 49.2606008, lng: 21.0274667 },
    { lat: 49.260475, lng: 21.0289657 },
    { lat: 49.2601707, lng: 21.029436 },
    { lat: 49.2595302, lng: 21.0308494 },
    { lat: 49.2591872, lng: 21.0318244 },
    { lat: 49.2586994, lng: 21.0324692 },
    { lat: 49.2579382, lng: 21.0333177 },
    { lat: 49.2568672, lng: 21.0340603 },
    { lat: 49.2566189, lng: 21.0343818 },
    { lat: 49.2564908, lng: 21.0349103 },
    { lat: 49.2562903, lng: 21.0362527 },
    { lat: 49.2558862, lng: 21.0367066 },
    { lat: 49.2550952, lng: 21.0370515 },
    { lat: 49.2545026, lng: 21.0374815 },
    { lat: 49.2533295, lng: 21.0377817 },
    { lat: 49.252832, lng: 21.0377398 },
    { lat: 49.2528516, lng: 21.0380686 },
    { lat: 49.2527404, lng: 21.0384564 },
    { lat: 49.2525133, lng: 21.0389523 },
    { lat: 49.2522152, lng: 21.0399252 },
    { lat: 49.2524336, lng: 21.0410402 },
    { lat: 49.2525256, lng: 21.0418629 },
    { lat: 49.2523782, lng: 21.0434281 },
    { lat: 49.2522347, lng: 21.0440002 },
    { lat: 49.2522384, lng: 21.0442899 },
    { lat: 49.2519829, lng: 21.0450506 },
    { lat: 49.2521839, lng: 21.0468179 },
    { lat: 49.2524732, lng: 21.0473749 },
    { lat: 49.2528898, lng: 21.0478687 },
    { lat: 49.253249, lng: 21.0481554 },
    { lat: 49.2539104, lng: 21.0490518 },
    { lat: 49.2539464, lng: 21.0490859 },
    { lat: 49.2545325, lng: 21.0499495 },
    { lat: 49.2546502, lng: 21.0501758 },
    { lat: 49.2546754, lng: 21.051446 },
    { lat: 49.2546426, lng: 21.0518228 },
    { lat: 49.2546754, lng: 21.0521737 },
    { lat: 49.2549359, lng: 21.0528519 },
    { lat: 49.2553301, lng: 21.0534919 },
    { lat: 49.2553937, lng: 21.0538549 },
    { lat: 49.2555488, lng: 21.054094 },
    { lat: 49.2557181, lng: 21.0545604 },
    { lat: 49.2559113, lng: 21.0548985 },
    { lat: 49.2559914, lng: 21.055328 },
    { lat: 49.2560559, lng: 21.0561049 },
    { lat: 49.2566817, lng: 21.0575356 },
    { lat: 49.25688, lng: 21.0577552 },
    { lat: 49.2571037, lng: 21.0578555 },
    { lat: 49.257305, lng: 21.0581599 },
    { lat: 49.2573411, lng: 21.0583063 },
    { lat: 49.2572893, lng: 21.0583736 },
    { lat: 49.2573088, lng: 21.0586651 },
    { lat: 49.2574762, lng: 21.0588614 },
    { lat: 49.2579349, lng: 21.0597928 },
    { lat: 49.2578496, lng: 21.0603202 },
    { lat: 49.2577975, lng: 21.0604152 },
    { lat: 49.2578273, lng: 21.0609231 },
    { lat: 49.2578039, lng: 21.061354 },
    { lat: 49.2577167, lng: 21.061648 },
    { lat: 49.2576785, lng: 21.0626961 },
    { lat: 49.2577224, lng: 21.0632887 },
    { lat: 49.2580942, lng: 21.0653305 },
    { lat: 49.2581691, lng: 21.0662958 },
    { lat: 49.2583324, lng: 21.0671403 },
    { lat: 49.2583731, lng: 21.0680905 },
    { lat: 49.2583426, lng: 21.0687186 },
    { lat: 49.258187, lng: 21.0693745 },
    { lat: 49.257844, lng: 21.0701824 },
    { lat: 49.2577873, lng: 21.0704136 },
    { lat: 49.2576649, lng: 21.0705975 },
    { lat: 49.2575932, lng: 21.0711681 },
    { lat: 49.2576238, lng: 21.0714162 },
    { lat: 49.2578223, lng: 21.0718549 },
    { lat: 49.2579655, lng: 21.0720041 },
    { lat: 49.2580956, lng: 21.0717825 },
    { lat: 49.2582911, lng: 21.0716274 },
    { lat: 49.2585524, lng: 21.0718502 },
    { lat: 49.2587581, lng: 21.0721882 },
    { lat: 49.25884, lng: 21.0724271 },
    { lat: 49.2593943, lng: 21.0724442 },
    { lat: 49.2612028, lng: 21.0731164 },
    { lat: 49.2621546, lng: 21.0736031 },
    { lat: 49.2618807, lng: 21.0743197 },
    { lat: 49.2614157, lng: 21.0750546 },
    { lat: 49.2614283, lng: 21.0756903 },
    { lat: 49.2613254, lng: 21.0757067 },
    { lat: 49.2615619, lng: 21.0781318 },
    { lat: 49.2621017, lng: 21.0790004 },
    { lat: 49.2624582, lng: 21.0793841 },
    { lat: 49.2625768, lng: 21.079639 },
    { lat: 49.2626542, lng: 21.0800529 },
    { lat: 49.2627939, lng: 21.0803368 },
    { lat: 49.2633934, lng: 21.0821915 },
    { lat: 49.2634399, lng: 21.0826582 },
    { lat: 49.2632605, lng: 21.0834841 },
    { lat: 49.2627382, lng: 21.084714 },
    { lat: 49.2624618, lng: 21.0850063 },
    { lat: 49.2623683, lng: 21.0857401 },
    { lat: 49.2619743, lng: 21.0867203 },
    { lat: 49.2619501, lng: 21.0872316 },
    { lat: 49.2620941, lng: 21.0874716 },
    { lat: 49.2624186, lng: 21.0884378 },
    { lat: 49.2626093, lng: 21.0891957 },
    { lat: 49.2626006, lng: 21.0893459 },
    { lat: 49.2627257, lng: 21.0896287 },
    { lat: 49.2627219, lng: 21.0901086 },
    { lat: 49.2628472, lng: 21.0910771 },
    { lat: 49.2633924, lng: 21.0928851 },
    { lat: 49.2641803, lng: 21.0949316 },
    { lat: 49.2643907, lng: 21.095851 },
    { lat: 49.2645068, lng: 21.1013219 },
    { lat: 49.2642983, lng: 21.1014524 },
    { lat: 49.2635417, lng: 21.1016925 },
    { lat: 49.2633607, lng: 21.1018735 },
    { lat: 49.2628732, lng: 21.1021079 },
    { lat: 49.2622075, lng: 21.1027595 },
    { lat: 49.2616502, lng: 21.1034582 },
    { lat: 49.2612304, lng: 21.1041285 },
    { lat: 49.2609474, lng: 21.1041809 },
    { lat: 49.2602612, lng: 21.1049273 },
    { lat: 49.2599505, lng: 21.1054206 },
    { lat: 49.2596491, lng: 21.1057442 },
    { lat: 49.2592534, lng: 21.1058993 },
    { lat: 49.259088, lng: 21.1058644 },
    { lat: 49.2586889, lng: 21.1055573 },
    { lat: 49.2584973, lng: 21.1055452 },
    { lat: 49.2582157, lng: 21.1056771 },
    { lat: 49.2578979, lng: 21.1059482 },
    { lat: 49.2577691, lng: 21.1061455 },
    { lat: 49.2576578, lng: 21.1072418 },
    { lat: 49.2574802, lng: 21.1080641 },
    { lat: 49.2571361, lng: 21.1085284 },
    { lat: 49.2570734, lng: 21.1088242 },
    { lat: 49.2566434, lng: 21.109635 },
    { lat: 49.2563653, lng: 21.1107609 },
    { lat: 49.2559288, lng: 21.1119692 },
    { lat: 49.2557761, lng: 21.1122229 },
    { lat: 49.2556167, lng: 21.1129142 },
    { lat: 49.2551714, lng: 21.1137347 },
    { lat: 49.2545687, lng: 21.1153436 },
    { lat: 49.2540812, lng: 21.116151 },
    { lat: 49.2538662, lng: 21.1167465 },
    { lat: 49.2537348, lng: 21.1173618 },
    { lat: 49.2534625, lng: 21.1177858 },
    { lat: 49.2532932, lng: 21.1181957 },
    { lat: 49.2532019, lng: 21.1186163 },
    { lat: 49.2530374, lng: 21.1188576 },
    { lat: 49.2528901, lng: 21.1194629 },
    { lat: 49.2527663, lng: 21.1206356 },
    { lat: 49.2527029, lng: 21.120869 },
    { lat: 49.2522076, lng: 21.1211845 },
    { lat: 49.2514846, lng: 21.1220907 },
    { lat: 49.2513663, lng: 21.1223299 },
    { lat: 49.2508656, lng: 21.1228987 },
    { lat: 49.2506126, lng: 21.1234426 },
    { lat: 49.2503656, lng: 21.1235282 },
    { lat: 49.2502407, lng: 21.1237381 },
    { lat: 49.249975, lng: 21.1239133 },
    { lat: 49.2497119, lng: 21.1243171 },
    { lat: 49.2495149, lng: 21.1255909 },
    { lat: 49.2492938, lng: 21.1262135 },
    { lat: 49.2500007, lng: 21.126145 },
    { lat: 49.2513552, lng: 21.1264891 },
    { lat: 49.2524479, lng: 21.1263561 },
    { lat: 49.2526656, lng: 21.1262236 },
    { lat: 49.2528269, lng: 21.1263118 },
    { lat: 49.2533699, lng: 21.1268978 },
    { lat: 49.2535447, lng: 21.1273715 },
    { lat: 49.253817, lng: 21.1279047 },
    { lat: 49.2541799, lng: 21.1291257 },
    { lat: 49.2543568, lng: 21.1293144 },
    { lat: 49.2545811, lng: 21.1299643 },
    { lat: 49.2547112, lng: 21.1299925 },
    { lat: 49.2547582, lng: 21.1301608 },
    { lat: 49.2549496, lng: 21.1301654 },
    { lat: 49.2550392, lng: 21.1298296 },
    { lat: 49.2553037, lng: 21.1298659 },
    { lat: 49.2553919, lng: 21.1298057 },
    { lat: 49.2554906, lng: 21.1296237 },
    { lat: 49.2557671, lng: 21.1297556 },
    { lat: 49.2559158, lng: 21.1295797 },
    { lat: 49.2561027, lng: 21.129586 },
    { lat: 49.2561707, lng: 21.129528 },
    { lat: 49.2566976, lng: 21.1296822 },
    { lat: 49.2568883, lng: 21.1295511 },
    { lat: 49.2569876, lng: 21.1295992 },
    { lat: 49.2571387, lng: 21.1294983 },
    { lat: 49.2573286, lng: 21.1296367 },
    { lat: 49.2574999, lng: 21.1296385 },
    { lat: 49.2575862, lng: 21.1297369 },
    { lat: 49.2578059, lng: 21.1296385 },
    { lat: 49.2580093, lng: 21.1297388 },
    { lat: 49.2583322, lng: 21.1295939 },
    { lat: 49.2583792, lng: 21.1295137 },
    { lat: 49.2584844, lng: 21.129498 },
    { lat: 49.2586926, lng: 21.1296411 },
    { lat: 49.2590841, lng: 21.129493 },
    { lat: 49.2592289, lng: 21.129554 },
    { lat: 49.2593271, lng: 21.1297063 },
    { lat: 49.2594674, lng: 21.1296628 },
    { lat: 49.2597805, lng: 21.1297045 },
    { lat: 49.2598598, lng: 21.1297884 },
    { lat: 49.2601393, lng: 21.1296957 },
    { lat: 49.2602191, lng: 21.1299445 },
    { lat: 49.260567, lng: 21.1297765 },
    { lat: 49.2605996, lng: 21.1297038 },
    { lat: 49.2609449, lng: 21.1297641 },
    { lat: 49.2609549, lng: 21.1295519 },
    { lat: 49.2608797, lng: 21.1294111 },
    { lat: 49.2610259, lng: 21.1293358 },
    { lat: 49.2612102, lng: 21.1292949 },
    { lat: 49.2614874, lng: 21.1294859 },
    { lat: 49.2615418, lng: 21.1294609 },
    { lat: 49.2615965, lng: 21.1293056 },
    { lat: 49.2617399, lng: 21.1292856 },
    { lat: 49.261888, lng: 21.1291655 },
    { lat: 49.2620861, lng: 21.1294867 },
    { lat: 49.2623, lng: 21.1290248 },
    { lat: 49.2624576, lng: 21.1288874 },
    { lat: 49.2624693, lng: 21.1285789 },
    { lat: 49.2625918, lng: 21.1285082 },
    { lat: 49.2627415, lng: 21.1280446 },
    { lat: 49.262908, lng: 21.1279354 },
    { lat: 49.262975, lng: 21.127742 },
    { lat: 49.2632732, lng: 21.1275658 },
    { lat: 49.2631713, lng: 21.1272599 },
    { lat: 49.2632898, lng: 21.1269892 },
    { lat: 49.2633544, lng: 21.1262876 },
    { lat: 49.2636524, lng: 21.1262122 },
    { lat: 49.2639011, lng: 21.125608 },
    { lat: 49.2641111, lng: 21.1254552 },
    { lat: 49.2642286, lng: 21.1251539 },
    { lat: 49.2647019, lng: 21.1251027 },
    { lat: 49.2647743, lng: 21.124759 },
    { lat: 49.2647651, lng: 21.1244689 },
    { lat: 49.2649262, lng: 21.1243305 },
    { lat: 49.2651833, lng: 21.1238168 },
    { lat: 49.265392, lng: 21.1238327 },
    { lat: 49.2654416, lng: 21.1233012 },
    { lat: 49.2655562, lng: 21.1233302 },
    { lat: 49.2659681, lng: 21.1232255 },
    { lat: 49.2663169, lng: 21.1228332 },
    { lat: 49.2665085, lng: 21.1224576 },
    { lat: 49.2668661, lng: 21.1222287 },
    { lat: 49.2668855, lng: 21.1220312 },
    { lat: 49.2671801, lng: 21.1218782 },
    { lat: 49.2673328, lng: 21.1216215 },
    { lat: 49.2674741, lng: 21.1215333 },
    { lat: 49.2676156, lng: 21.121566 },
    { lat: 49.2677201, lng: 21.1214848 },
    { lat: 49.2678221, lng: 21.1212935 },
    { lat: 49.2678904, lng: 21.1209281 },
    { lat: 49.2680121, lng: 21.1207587 },
    { lat: 49.2681722, lng: 21.1208377 },
    { lat: 49.2684342, lng: 21.120125 },
    { lat: 49.2687224, lng: 21.1202333 },
    { lat: 49.2695763, lng: 21.1196988 },
    { lat: 49.2696484, lng: 21.1195551 },
    { lat: 49.2697304, lng: 21.1196585 },
    { lat: 49.2698274, lng: 21.119416 },
    { lat: 49.2699496, lng: 21.1193634 },
    { lat: 49.270159, lng: 21.119016 },
    { lat: 49.2703711, lng: 21.1187819 },
    { lat: 49.2705235, lng: 21.1188001 },
    { lat: 49.2708369, lng: 21.1184277 },
    { lat: 49.2710805, lng: 21.1183387 },
    { lat: 49.2712338, lng: 21.118207 },
    { lat: 49.2715109, lng: 21.117754 },
    { lat: 49.2716342, lng: 21.1173139 },
    { lat: 49.2717044, lng: 21.1172161 },
    { lat: 49.2720685, lng: 21.117379 },
    { lat: 49.2721963, lng: 21.1171486 },
    { lat: 49.2721494, lng: 21.1169117 },
    { lat: 49.2721992, lng: 21.1166021 },
    { lat: 49.2723106, lng: 21.1163218 },
    { lat: 49.2727261, lng: 21.1160113 },
    { lat: 49.2728083, lng: 21.1155187 },
    { lat: 49.2732232, lng: 21.1150104 },
    { lat: 49.2736188, lng: 21.1149274 },
    { lat: 49.2737933, lng: 21.1149995 },
    { lat: 49.2741874, lng: 21.1144387 },
    { lat: 49.2743292, lng: 21.1142264 },
    { lat: 49.2743427, lng: 21.1136268 },
    { lat: 49.2745427, lng: 21.1134336 },
    { lat: 49.2745079, lng: 21.1132974 },
    { lat: 49.2746437, lng: 21.1126891 },
    { lat: 49.2745478, lng: 21.1123917 },
    { lat: 49.2746285, lng: 21.111817 },
    { lat: 49.2746493, lng: 21.1109134 },
    { lat: 49.2747697, lng: 21.110561 },
    { lat: 49.2748785, lng: 21.1104755 },
    { lat: 49.2753408, lng: 21.1105756 },
    { lat: 49.2760327, lng: 21.11089 },
    { lat: 49.2770528, lng: 21.1115509 },
    { lat: 49.2775733, lng: 21.1125235 },
    { lat: 49.2781927, lng: 21.1134589 },
    { lat: 49.2793827, lng: 21.1149319 },
    { lat: 49.2796908, lng: 21.1154194 },
    { lat: 49.279966, lng: 21.1162918 },
    { lat: 49.280072, lng: 21.116972 },
    { lat: 49.2803467, lng: 21.1175438 },
    { lat: 49.2811054, lng: 21.1163132 },
    { lat: 49.2813549, lng: 21.1163376 },
    { lat: 49.2813545, lng: 21.1164656 },
    { lat: 49.2821939, lng: 21.1166795 },
    { lat: 49.2824567, lng: 21.1184369 },
    { lat: 49.2830432, lng: 21.1185859 },
    { lat: 49.2832353, lng: 21.1185782 },
    { lat: 49.2848189, lng: 21.1178415 },
    { lat: 49.2851015, lng: 21.1179183 },
    { lat: 49.2852494, lng: 21.1175414 },
    { lat: 49.2860451, lng: 21.1168383 },
    { lat: 49.2869474, lng: 21.1162263 },
    { lat: 49.2876262, lng: 21.1159201 },
    { lat: 49.2879794, lng: 21.1155911 },
    { lat: 49.2883633, lng: 21.1153549 },
    { lat: 49.2886349, lng: 21.1148719 },
    { lat: 49.2901531, lng: 21.1127502 },
    { lat: 49.2914562, lng: 21.1115139 },
    { lat: 49.2928035, lng: 21.1107109 },
    { lat: 49.2933221, lng: 21.1102437 },
    { lat: 49.2936188, lng: 21.1100911 },
    { lat: 49.2950885, lng: 21.1087103 },
    { lat: 49.2954916, lng: 21.1086461 },
    { lat: 49.2958093, lng: 21.1084116 },
    { lat: 49.2965049, lng: 21.1076042 },
    { lat: 49.2966266, lng: 21.1073968 },
    { lat: 49.2967201, lng: 21.1070831 },
    { lat: 49.2975091, lng: 21.1058158 },
    { lat: 49.2979776, lng: 21.1055776 },
    { lat: 49.2987497, lng: 21.1050547 },
    { lat: 49.2990554, lng: 21.1049396 },
    { lat: 49.2999508, lng: 21.1050284 },
    { lat: 49.3005815, lng: 21.1049101 },
    { lat: 49.3016872, lng: 21.1050355 },
    { lat: 49.3028916, lng: 21.1047684 },
    { lat: 49.3033382, lng: 21.104516 },
    { lat: 49.3043415, lng: 21.1033004 },
    { lat: 49.3052238, lng: 21.1017793 },
    { lat: 49.3058836, lng: 21.1011218 },
    { lat: 49.3062563, lng: 21.1006 },
    { lat: 49.30666, lng: 21.1002763 },
    { lat: 49.3070013, lng: 21.1001726 },
    { lat: 49.3073888, lng: 21.0999097 },
    { lat: 49.3075992, lng: 21.0995868 },
    { lat: 49.3078743, lng: 21.0993546 },
    { lat: 49.3080697, lng: 21.0993083 },
    { lat: 49.3083153, lng: 21.0991053 },
    { lat: 49.308532, lng: 21.0987711 },
    { lat: 49.308586, lng: 21.0979286 },
    { lat: 49.3088392, lng: 21.0974308 },
    { lat: 49.3094726, lng: 21.0971084 },
    { lat: 49.3102862, lng: 21.0971851 },
    { lat: 49.3108259, lng: 21.0973086 },
    { lat: 49.3114911, lng: 21.0976348 },
    { lat: 49.3117295, lng: 21.097591 },
    { lat: 49.3121035, lng: 21.0973619 },
    { lat: 49.3124097, lng: 21.0972565 },
    { lat: 49.3127556, lng: 21.0972696 },
    { lat: 49.3133853, lng: 21.0975393 },
    { lat: 49.3135782, lng: 21.0977684 },
    { lat: 49.3138519, lng: 21.0978764 },
    { lat: 49.3145357, lng: 21.098366 },
    { lat: 49.3149472, lng: 21.0988479 },
    { lat: 49.3158626, lng: 21.0991446 },
    { lat: 49.3162676, lng: 21.0990729 },
    { lat: 49.3163877, lng: 21.09924 },
    { lat: 49.3167323, lng: 21.0993178 },
    { lat: 49.3171499, lng: 21.0996066 },
    { lat: 49.3173415, lng: 21.0995065 },
    { lat: 49.3172318, lng: 21.0989271 },
    { lat: 49.317108, lng: 21.097513 },
    { lat: 49.3171011, lng: 21.0966598 },
    { lat: 49.3171798, lng: 21.0951426 },
    { lat: 49.3172612, lng: 21.0940959 },
    { lat: 49.31755, lng: 21.0919672 },
    { lat: 49.3180752, lng: 21.0897382 },
    { lat: 49.3181397, lng: 21.0880604 },
    { lat: 49.3177975, lng: 21.0867904 },
    { lat: 49.3173897, lng: 21.0859985 },
    { lat: 49.317125, lng: 21.0852547 },
    { lat: 49.3175248, lng: 21.0850888 },
    { lat: 49.3175063, lng: 21.0850054 },
    { lat: 49.3172647, lng: 21.0839548 },
    { lat: 49.3171801, lng: 21.0838085 },
    { lat: 49.3167245, lng: 21.0832861 },
    { lat: 49.3158425, lng: 21.0824847 },
    { lat: 49.3154826, lng: 21.0820709 },
    { lat: 49.3145426, lng: 21.0796688 },
    { lat: 49.3142147, lng: 21.0790601 },
    { lat: 49.3140124, lng: 21.0788181 },
    { lat: 49.3135596, lng: 21.0784556 },
    { lat: 49.3125921, lng: 21.078073 },
    { lat: 49.3116504, lng: 21.0771709 },
    { lat: 49.3108013, lng: 21.0766572 },
    { lat: 49.3094428, lng: 21.075279 },
    { lat: 49.3090946, lng: 21.0750552 },
    { lat: 49.3081916, lng: 21.0749343 },
    { lat: 49.3081299, lng: 21.0750468 },
    { lat: 49.3076645, lng: 21.0752682 },
    { lat: 49.3057684, lng: 21.0757624 },
    { lat: 49.3057036, lng: 21.0752851 },
    { lat: 49.3055501, lng: 21.0752991 },
    { lat: 49.3053234, lng: 21.0753562 },
    { lat: 49.3039639, lng: 21.0761249 },
    { lat: 49.3037241, lng: 21.0761905 },
    { lat: 49.3030513, lng: 21.0761962 },
    { lat: 49.3025344, lng: 21.0759693 },
    { lat: 49.3014753, lng: 21.075158 },
    { lat: 49.2999424, lng: 21.0743067 },
    { lat: 49.2993099, lng: 21.0741346 },
    { lat: 49.2988379, lng: 21.0741148 },
    { lat: 49.2981566, lng: 21.0742384 },
    { lat: 49.2976587, lng: 21.0742278 },
    { lat: 49.2976641, lng: 21.0741084 },
    { lat: 49.2976793, lng: 21.0736415 },
    { lat: 49.2978212, lng: 21.0733979 },
    { lat: 49.2977213, lng: 21.0729817 },
    { lat: 49.2977009, lng: 21.0725592 },
    { lat: 49.2975515, lng: 21.0722817 },
    { lat: 49.2974747, lng: 21.0715429 },
    { lat: 49.2973247, lng: 21.0712188 },
    { lat: 49.2972865, lng: 21.0709412 },
    { lat: 49.2968004, lng: 21.069989 },
    { lat: 49.2967107, lng: 21.06976 },
    { lat: 49.2967022, lng: 21.0695252 },
    { lat: 49.2964505, lng: 21.0690148 },
    { lat: 49.2961978, lng: 21.0682918 },
    { lat: 49.2954978, lng: 21.0672386 },
    { lat: 49.2947366, lng: 21.0674004 },
    { lat: 49.2945034, lng: 21.0668342 },
    { lat: 49.2943301, lng: 21.0669024 },
    { lat: 49.2941938, lng: 21.0664457 },
    { lat: 49.2935522, lng: 21.0665051 },
    { lat: 49.2932395, lng: 21.0658829 },
    { lat: 49.2933036, lng: 21.0654999 },
    { lat: 49.2928325, lng: 21.065407 },
    { lat: 49.2928186, lng: 21.0652516 },
    { lat: 49.2904096, lng: 21.0650167 },
    { lat: 49.2904223, lng: 21.0646051 },
    { lat: 49.2903326, lng: 21.0638775 },
    { lat: 49.2902485, lng: 21.0635443 },
    { lat: 49.2898852, lng: 21.0627395 },
    { lat: 49.2888188, lng: 21.0623353 },
    { lat: 49.2887404, lng: 21.0622049 },
    { lat: 49.2882779, lng: 21.062027 },
    { lat: 49.287709, lng: 21.0619065 },
    { lat: 49.2870084, lng: 21.0618555 },
    { lat: 49.2864529, lng: 21.0619661 },
    { lat: 49.2860769, lng: 21.061927 },
    { lat: 49.2853649, lng: 21.0611677 },
    { lat: 49.2843324, lng: 21.0598613 },
    { lat: 49.2835197, lng: 21.0591179 },
    { lat: 49.2835437, lng: 21.0589193 },
    { lat: 49.2835797, lng: 21.0589162 },
    { lat: 49.283479, lng: 21.058523 },
    { lat: 49.283153, lng: 21.057765 },
    { lat: 49.2829504, lng: 21.0575503 },
    { lat: 49.2818941, lng: 21.0573213 },
    { lat: 49.2812757, lng: 21.0574425 },
    { lat: 49.2810448, lng: 21.0573954 },
    { lat: 49.280091, lng: 21.0575523 },
    { lat: 49.2795576, lng: 21.0572003 },
    { lat: 49.2782785, lng: 21.0568631 },
    { lat: 49.2773707, lng: 21.0567679 },
    { lat: 49.2764086, lng: 21.0571554 },
    { lat: 49.2758765, lng: 21.0573 },
    { lat: 49.2747386, lng: 21.0565276 },
    { lat: 49.2740191, lng: 21.0563724 },
    { lat: 49.2738223, lng: 21.0554034 },
    { lat: 49.2735897, lng: 21.0546479 },
    { lat: 49.2733384, lng: 21.0541096 },
    { lat: 49.2723315, lng: 21.0539117 },
    { lat: 49.2718559, lng: 21.0533284 },
    { lat: 49.27096, lng: 21.0518593 },
    { lat: 49.2706815, lng: 21.0517066 },
    { lat: 49.2702898, lng: 21.051845 },
    { lat: 49.2702557, lng: 21.051658 },
    { lat: 49.2700729, lng: 21.051399 },
    { lat: 49.2695935, lng: 21.0512247 },
    { lat: 49.2692863, lng: 21.0513647 },
    { lat: 49.2688194, lng: 21.0514031 },
    { lat: 49.2683473, lng: 21.051593 },
    { lat: 49.2675531, lng: 21.0516709 },
    { lat: 49.2673349, lng: 21.0513645 },
    { lat: 49.2672951, lng: 21.0503722 },
    { lat: 49.2673184, lng: 21.0498397 },
    { lat: 49.2671707, lng: 21.049516 },
    { lat: 49.2667191, lng: 21.0489808 },
    { lat: 49.2663985, lng: 21.0484306 },
    { lat: 49.2662137, lng: 21.048286 },
    { lat: 49.2660464, lng: 21.0480223 },
    { lat: 49.2658892, lng: 21.0479949 },
    { lat: 49.2657414, lng: 21.0480835 },
    { lat: 49.2655454, lng: 21.0483956 },
    { lat: 49.2653539, lng: 21.048423 },
    { lat: 49.2652634, lng: 21.0482659 },
    { lat: 49.2652157, lng: 21.0478054 },
    { lat: 49.2647784, lng: 21.0468096 },
    { lat: 49.2646372, lng: 21.046585 },
    { lat: 49.2638958, lng: 21.045893 },
    { lat: 49.2637683, lng: 21.0458514 },
    { lat: 49.2633587, lng: 21.0454637 },
    { lat: 49.2628824, lng: 21.0451812 },
    { lat: 49.2624059, lng: 21.044645 },
    { lat: 49.2618688, lng: 21.043765 },
    { lat: 49.2616322, lng: 21.04313 },
    { lat: 49.2615844, lng: 21.0428691 },
    { lat: 49.2615879, lng: 21.0423144 },
    { lat: 49.2617533, lng: 21.0416198 },
    { lat: 49.2620743, lng: 21.041001 },
    { lat: 49.262994, lng: 21.0411202 },
    { lat: 49.2631018, lng: 21.0410411 },
    { lat: 49.2633325, lng: 21.0403805 },
    { lat: 49.2634511, lng: 21.0398696 },
    { lat: 49.2635007, lng: 21.0393181 },
    { lat: 49.2635276, lng: 21.0387234 },
    { lat: 49.2634385, lng: 21.0381648 },
    { lat: 49.2632603, lng: 21.0377012 },
    { lat: 49.2631544, lng: 21.0375921 },
    { lat: 49.2629958, lng: 21.0371811 },
    { lat: 49.2627126, lng: 21.0367075 },
    { lat: 49.2623154, lng: 21.0358032 },
    { lat: 49.2626841, lng: 21.034681 },
    { lat: 49.2630457, lng: 21.0346259 },
    { lat: 49.2633894, lng: 21.0338738 },
    { lat: 49.2633621, lng: 21.033317 },
    { lat: 49.2635256, lng: 21.0330776 },
    { lat: 49.2637481, lng: 21.0333814 },
    { lat: 49.2640499, lng: 21.0328718 },
    { lat: 49.2640762, lng: 21.0327091 },
    { lat: 49.2644665, lng: 21.0322507 },
    { lat: 49.2642085, lng: 21.0312033 },
    { lat: 49.2641093, lng: 21.0301241 },
    { lat: 49.2642666, lng: 21.028869 },
    { lat: 49.2648064, lng: 21.0274699 },
    { lat: 49.2651214, lng: 21.0267801 },
    { lat: 49.2656379, lng: 21.0262613 },
    { lat: 49.2658227, lng: 21.0256401 },
    { lat: 49.2659571, lng: 21.0249312 },
    { lat: 49.2656742, lng: 21.0241206 },
    { lat: 49.2655666, lng: 21.0234463 },
    { lat: 49.2653621, lng: 21.0225898 },
    { lat: 49.264992, lng: 21.0214854 },
    { lat: 49.2648827, lng: 21.0204455 },
    { lat: 49.2650471, lng: 21.0193462 },
    { lat: 49.2653978, lng: 21.0184363 },
    { lat: 49.265383, lng: 21.0179452 },
    { lat: 49.2654271, lng: 21.0172951 },
    { lat: 49.265874, lng: 21.0169544 },
    { lat: 49.26627, lng: 21.0167383 },
    { lat: 49.2669822, lng: 21.0165302 },
    { lat: 49.267199, lng: 21.0167584 },
    { lat: 49.2675687, lng: 21.0169148 },
    { lat: 49.267939, lng: 21.0172293 },
    { lat: 49.2682485, lng: 21.0172962 },
    { lat: 49.2686786, lng: 21.0179268 },
    { lat: 49.2688256, lng: 21.0185362 },
    { lat: 49.2689292, lng: 21.0187496 },
    { lat: 49.2692668, lng: 21.0189852 },
    { lat: 49.2700627, lng: 21.0192014 },
    { lat: 49.2706315, lng: 21.0192165 },
    { lat: 49.2710403, lng: 21.0189547 },
    { lat: 49.2714536, lng: 21.0187907 },
    { lat: 49.2716264, lng: 21.018775 },
    { lat: 49.2718423, lng: 21.0188728 },
    { lat: 49.2730858, lng: 21.0179698 },
    { lat: 49.2732435, lng: 21.017911 },
    { lat: 49.2739443, lng: 21.0179023 },
    { lat: 49.273965, lng: 21.0178802 },
    { lat: 49.2741239, lng: 21.0177102 },
    { lat: 49.2744168, lng: 21.0175809 },
    { lat: 49.2747553, lng: 21.0173032 },
    { lat: 49.2748938, lng: 21.0173049 },
    { lat: 49.2752928, lng: 21.0170435 },
    { lat: 49.2755575, lng: 21.0166435 },
    { lat: 49.2755967, lng: 21.0165003 },
    { lat: 49.2757607, lng: 21.0164878 },
    { lat: 49.2759079, lng: 21.016274 },
    { lat: 49.2760801, lng: 21.0162404 },
    { lat: 49.276428, lng: 21.015727 },
    { lat: 49.2766013, lng: 21.0156557 },
    { lat: 49.2768484, lng: 21.0153648 },
    { lat: 49.2769813, lng: 21.0150573 },
    { lat: 49.2772146, lng: 21.0150024 },
    { lat: 49.2780162, lng: 21.0142522 },
    { lat: 49.2782744, lng: 21.0138898 },
    { lat: 49.2785431, lng: 21.0138484 },
    { lat: 49.2787341, lng: 21.0135613 },
    { lat: 49.278642, lng: 21.011729 },
    { lat: 49.278597, lng: 21.011676 },
    { lat: 49.278541, lng: 21.011608 },
    { lat: 49.278501, lng: 21.011186 },
    { lat: 49.278512, lng: 21.010751 },
    { lat: 49.278425, lng: 21.010603 },
    { lat: 49.277835, lng: 21.008431 },
    { lat: 49.277881, lng: 21.00818 },
    { lat: 49.277766, lng: 21.00797 },
    { lat: 49.277743, lng: 21.007707 },
    { lat: 49.277644, lng: 21.00708 },
    { lat: 49.277558, lng: 21.006839 },
    { lat: 49.2774, lng: 21.005897 },
    { lat: 49.277401, lng: 21.004961 },
    { lat: 49.277415, lng: 21.004962 },
    { lat: 49.277415, lng: 21.004869 },
    { lat: 49.27747, lng: 21.004069 },
    { lat: 49.277482, lng: 21.003058 },
    { lat: 49.277487, lng: 21.002858 },
    { lat: 49.277511, lng: 21.002168 },
    { lat: 49.277512, lng: 21.001937 },
    { lat: 49.27772, lng: 21.001065 },
    { lat: 49.277802, lng: 21.000734 },
    { lat: 49.277708, lng: 21.000508 },
    { lat: 49.277802, lng: 21.000188 },
    { lat: 49.277838, lng: 20.999867 },
    { lat: 49.277903, lng: 20.999617 },
    { lat: 49.277769, lng: 20.999222 },
    { lat: 49.277735, lng: 20.998816 },
    { lat: 49.277772, lng: 20.998173 },
    { lat: 49.277678, lng: 20.997859 },
    { lat: 49.277577, lng: 20.99741 },
    { lat: 49.27754, lng: 20.997185 },
    { lat: 49.277502, lng: 20.996874 },
    { lat: 49.277442, lng: 20.996509 },
    { lat: 49.277262, lng: 20.99587 },
    { lat: 49.277237, lng: 20.995434 },
    { lat: 49.277297, lng: 20.995049 },
    { lat: 49.277297, lng: 20.994793 },
    { lat: 49.277441, lng: 20.994071 },
    { lat: 49.277465, lng: 20.993912 },
    { lat: 49.27758, lng: 20.993387 },
    { lat: 49.277602, lng: 20.993154 },
    { lat: 49.27759, lng: 20.99287 },
    { lat: 49.277633, lng: 20.992723 },
    { lat: 49.277642, lng: 20.992457 },
    { lat: 49.277676, lng: 20.992373 },
    { lat: 49.277733, lng: 20.991958 },
    { lat: 49.277719, lng: 20.991385 },
    { lat: 49.277734, lng: 20.991382 },
    { lat: 49.277667, lng: 20.991332 },
    { lat: 49.277622, lng: 20.991182 },
    { lat: 49.277439, lng: 20.990411 },
    { lat: 49.277351, lng: 20.990206 },
    { lat: 49.277018, lng: 20.990024 },
  ],
  Rokytov: [
    { lat: 49.332881, lng: 21.1856384 },
    { lat: 49.3324141, lng: 21.1854913 },
    { lat: 49.3319943, lng: 21.1846928 },
    { lat: 49.3317177, lng: 21.184412 },
    { lat: 49.3316502, lng: 21.1844862 },
    { lat: 49.3314797, lng: 21.1843922 },
    { lat: 49.3309121, lng: 21.1839826 },
    { lat: 49.3308211, lng: 21.1839817 },
    { lat: 49.3299185, lng: 21.1834385 },
    { lat: 49.3295977, lng: 21.183108 },
    { lat: 49.3293151, lng: 21.1829208 },
    { lat: 49.3282011, lng: 21.1824486 },
    { lat: 49.3275215, lng: 21.1820129 },
    { lat: 49.3273875, lng: 21.1818658 },
    { lat: 49.3260159, lng: 21.1810729 },
    { lat: 49.3239581, lng: 21.1805001 },
    { lat: 49.3233516, lng: 21.1805065 },
    { lat: 49.3232092, lng: 21.1804455 },
    { lat: 49.3230047, lng: 21.1801354 },
    { lat: 49.3212354, lng: 21.179644 },
    { lat: 49.3176426, lng: 21.1782412 },
    { lat: 49.3166886, lng: 21.1775989 },
    { lat: 49.3158442, lng: 21.1768937 },
    { lat: 49.3130426, lng: 21.1770102 },
    { lat: 49.3126028, lng: 21.1769388 },
    { lat: 49.3123996, lng: 21.1767055 },
    { lat: 49.3118882, lng: 21.1766177 },
    { lat: 49.3116605, lng: 21.1766708 },
    { lat: 49.3112879, lng: 21.1762021 },
    { lat: 49.3109599, lng: 21.1762149 },
    { lat: 49.3096921, lng: 21.1755826 },
    { lat: 49.3092425, lng: 21.1755606 },
    { lat: 49.308905, lng: 21.175454 },
    { lat: 49.3085357, lng: 21.1754824 },
    { lat: 49.308238, lng: 21.1753566 },
    { lat: 49.3080256, lng: 21.1751075 },
    { lat: 49.3076739, lng: 21.1749831 },
    { lat: 49.3072328, lng: 21.1749465 },
    { lat: 49.3069962, lng: 21.1747939 },
    { lat: 49.3063988, lng: 21.1742159 },
    { lat: 49.305505, lng: 21.1730933 },
    { lat: 49.3054935, lng: 21.1730787 },
    { lat: 49.3053647, lng: 21.1736063 },
    { lat: 49.3048507, lng: 21.1746542 },
    { lat: 49.3044963, lng: 21.1750944 },
    { lat: 49.3039312, lng: 21.1756621 },
    { lat: 49.3033324, lng: 21.1757249 },
    { lat: 49.3032714, lng: 21.1758956 },
    { lat: 49.3031049, lng: 21.175682 },
    { lat: 49.3029893, lng: 21.1777125 },
    { lat: 49.3024592, lng: 21.1798724 },
    { lat: 49.3021449, lng: 21.1803634 },
    { lat: 49.3019847, lng: 21.1809671 },
    { lat: 49.3013179, lng: 21.1828967 },
    { lat: 49.3012125, lng: 21.1831914 },
    { lat: 49.3010482, lng: 21.1834496 },
    { lat: 49.3007752, lng: 21.1842173 },
    { lat: 49.3005492, lng: 21.1846581 },
    { lat: 49.3003408, lng: 21.184863 },
    { lat: 49.2997498, lng: 21.1851854 },
    { lat: 49.29918, lng: 21.1858936 },
    { lat: 49.2987942, lng: 21.1862562 },
    { lat: 49.2979737, lng: 21.1874087 },
    { lat: 49.2976408, lng: 21.1880296 },
    { lat: 49.2976535, lng: 21.1880381 },
    { lat: 49.2978006, lng: 21.1883165 },
    { lat: 49.2981797, lng: 21.188679 },
    { lat: 49.2983625, lng: 21.188948 },
    { lat: 49.298243, lng: 21.1893349 },
    { lat: 49.2985441, lng: 21.1896451 },
    { lat: 49.2990391, lng: 21.1898986 },
    { lat: 49.299254, lng: 21.1900759 },
    { lat: 49.2998284, lng: 21.1901004 },
    { lat: 49.3002011, lng: 21.1903935 },
    { lat: 49.3010247, lng: 21.1912657 },
    { lat: 49.3013707, lng: 21.1914586 },
    { lat: 49.3016664, lng: 21.1918157 },
    { lat: 49.3021827, lng: 21.1919578 },
    { lat: 49.3024328, lng: 21.1918938 },
    { lat: 49.3025743, lng: 21.1919542 },
    { lat: 49.302742, lng: 21.1922845 },
    { lat: 49.3033651, lng: 21.1927419 },
    { lat: 49.3034752, lng: 21.1927061 },
    { lat: 49.3035888, lng: 21.1929528 },
    { lat: 49.3038261, lng: 21.1932074 },
    { lat: 49.3038351, lng: 21.1933824 },
    { lat: 49.3040154, lng: 21.1939336 },
    { lat: 49.3040144, lng: 21.1945185 },
    { lat: 49.3043405, lng: 21.1950191 },
    { lat: 49.3045324, lng: 21.1949352 },
    { lat: 49.3046999, lng: 21.1949678 },
    { lat: 49.3049783, lng: 21.1952706 },
    { lat: 49.3051283, lng: 21.1952114 },
    { lat: 49.3056379, lng: 21.1953872 },
    { lat: 49.3059685, lng: 21.1956311 },
    { lat: 49.3061152, lng: 21.1958609 },
    { lat: 49.3063356, lng: 21.195934 },
    { lat: 49.3065738, lng: 21.1962463 },
    { lat: 49.3067511, lng: 21.196299 },
    { lat: 49.3069692, lng: 21.1966268 },
    { lat: 49.3072672, lng: 21.1973412 },
    { lat: 49.3072793, lng: 21.1976248 },
    { lat: 49.307418, lng: 21.1981427 },
    { lat: 49.3075442, lng: 21.1989128 },
    { lat: 49.3076624, lng: 21.1991271 },
    { lat: 49.3078742, lng: 21.2001845 },
    { lat: 49.3080727, lng: 21.2004953 },
    { lat: 49.3094575, lng: 21.2007714 },
    { lat: 49.3104616, lng: 21.2011198 },
    { lat: 49.3111648, lng: 21.2014403 },
    { lat: 49.3119707, lng: 21.2013025 },
    { lat: 49.3126825, lng: 21.2014305 },
    { lat: 49.3128696, lng: 21.20152 },
    { lat: 49.3132743, lng: 21.2018636 },
    { lat: 49.3134885, lng: 21.2021727 },
    { lat: 49.3137752, lng: 21.2026759 },
    { lat: 49.3142633, lng: 21.2037574 },
    { lat: 49.3148036, lng: 21.2044908 },
    { lat: 49.3149406, lng: 21.2046284 },
    { lat: 49.3156018, lng: 21.2050104 },
    { lat: 49.316097, lng: 21.2051963 },
    { lat: 49.3166546, lng: 21.2052183 },
    { lat: 49.3169777, lng: 21.2051138 },
    { lat: 49.3183282, lng: 21.2041074 },
    { lat: 49.3191896, lng: 21.2038722 },
    { lat: 49.3194598, lng: 21.2036229 },
    { lat: 49.3209186, lng: 21.2025877 },
    { lat: 49.3213294, lng: 21.2024075 },
    { lat: 49.3222693, lng: 21.2021272 },
    { lat: 49.322895, lng: 21.2021316 },
    { lat: 49.323361, lng: 21.202433 },
    { lat: 49.3234513, lng: 21.202004 },
    { lat: 49.3237292, lng: 21.2012729 },
    { lat: 49.3240385, lng: 21.2008757 },
    { lat: 49.32436, lng: 21.200826 },
    { lat: 49.3247512, lng: 21.2004474 },
    { lat: 49.3248709, lng: 21.1997743 },
    { lat: 49.325037, lng: 21.199505 },
    { lat: 49.32503, lng: 21.199249 },
    { lat: 49.325191, lng: 21.1988824 },
    { lat: 49.3253835, lng: 21.1988172 },
    { lat: 49.3259453, lng: 21.198349 },
    { lat: 49.3262127, lng: 21.19823 },
    { lat: 49.3264681, lng: 21.197942 },
    { lat: 49.3266192, lng: 21.1979166 },
    { lat: 49.3270044, lng: 21.1976347 },
    { lat: 49.3271394, lng: 21.1970231 },
    { lat: 49.3272956, lng: 21.1969418 },
    { lat: 49.3277329, lng: 21.1951835 },
    { lat: 49.3280013, lng: 21.1943754 },
    { lat: 49.329032, lng: 21.1918012 },
    { lat: 49.3298429, lng: 21.1903403 },
    { lat: 49.3302013, lng: 21.1895971 },
    { lat: 49.3304687, lng: 21.1887147 },
    { lat: 49.330628, lng: 21.1883416 },
    { lat: 49.3316059, lng: 21.1872905 },
    { lat: 49.3320207, lng: 21.1860477 },
    { lat: 49.332881, lng: 21.1856384 },
  ],
  Frička: [
    { lat: 49.38839, lng: 21.084273 },
    { lat: 49.3889007, lng: 21.0846834 },
    { lat: 49.389669, lng: 21.0864366 },
    { lat: 49.3905901, lng: 21.0875907 },
    { lat: 49.3912021, lng: 21.0886438 },
    { lat: 49.3918917, lng: 21.0895501 },
    { lat: 49.3921383, lng: 21.090015 },
    { lat: 49.3919849, lng: 21.0906391 },
    { lat: 49.3923035, lng: 21.091773 },
    { lat: 49.3924942, lng: 21.0932073 },
    { lat: 49.3945965, lng: 21.0936433 },
    { lat: 49.3953035, lng: 21.0942154 },
    { lat: 49.3962092, lng: 21.095096 },
    { lat: 49.397655, lng: 21.0962946 },
    { lat: 49.3977931, lng: 21.0969891 },
    { lat: 49.3981351, lng: 21.0971305 },
    { lat: 49.3983637, lng: 21.0973125 },
    { lat: 49.398454, lng: 21.0969354 },
    { lat: 49.3990065, lng: 21.095729 },
    { lat: 49.399008, lng: 21.0956364 },
    { lat: 49.4000269, lng: 21.0959403 },
    { lat: 49.4004954, lng: 21.0964075 },
    { lat: 49.4029159, lng: 21.0954639 },
    { lat: 49.4036344, lng: 21.0955529 },
    { lat: 49.4037256, lng: 21.0936795 },
    { lat: 49.4051539, lng: 21.093409 },
    { lat: 49.4052549, lng: 21.0932255 },
    { lat: 49.4055199, lng: 21.0934904 },
    { lat: 49.4056922, lng: 21.0934977 },
    { lat: 49.4058644, lng: 21.0933215 },
    { lat: 49.4058022, lng: 21.0932698 },
    { lat: 49.4061302, lng: 21.0932583 },
    { lat: 49.4068863, lng: 21.0928335 },
    { lat: 49.4071601, lng: 21.0923447 },
    { lat: 49.4074382, lng: 21.092029 },
    { lat: 49.4077219, lng: 21.0919366 },
    { lat: 49.4083133, lng: 21.0922049 },
    { lat: 49.408545, lng: 21.0921272 },
    { lat: 49.4086849, lng: 21.09221 },
    { lat: 49.4086777, lng: 21.0917701 },
    { lat: 49.4095697, lng: 21.0909126 },
    { lat: 49.4095725, lng: 21.0907917 },
    { lat: 49.4098653, lng: 21.0907984 },
    { lat: 49.4105785, lng: 21.0897328 },
    { lat: 49.4108853, lng: 21.0896502 },
    { lat: 49.4120441, lng: 21.0891153 },
    { lat: 49.4134051, lng: 21.0881485 },
    { lat: 49.414619, lng: 21.0861982 },
    { lat: 49.414702, lng: 21.0855552 },
    { lat: 49.4152849, lng: 21.0852375 },
    { lat: 49.4164695, lng: 21.0844562 },
    { lat: 49.4184585, lng: 21.0833149 },
    { lat: 49.4192763, lng: 21.0816929 },
    { lat: 49.420652, lng: 21.0799447 },
    { lat: 49.4208497, lng: 21.0782459 },
    { lat: 49.4210407, lng: 21.0778564 },
    { lat: 49.4213479, lng: 21.0775727 },
    { lat: 49.4234919, lng: 21.0764212 },
    { lat: 49.4239705, lng: 21.075903 },
    { lat: 49.4245037, lng: 21.0746683 },
    { lat: 49.4246291, lng: 21.0740541 },
    { lat: 49.4249824, lng: 21.0732106 },
    { lat: 49.425041, lng: 21.073177 },
    { lat: 49.4249582, lng: 21.0726241 },
    { lat: 49.4245118, lng: 21.071691 },
    { lat: 49.423927, lng: 21.0706481 },
    { lat: 49.4235368, lng: 21.0697512 },
    { lat: 49.42353, lng: 21.069737 },
    { lat: 49.4231999, lng: 21.0689419 },
    { lat: 49.4230763, lng: 21.0684966 },
    { lat: 49.4230207, lng: 21.0684147 },
    { lat: 49.4229723, lng: 21.0683164 },
    { lat: 49.4229641, lng: 21.0682187 },
    { lat: 49.422942, lng: 21.068146 },
    { lat: 49.422909, lng: 21.068066 },
    { lat: 49.4228988, lng: 21.068027 },
    { lat: 49.42289, lng: 21.067958 },
    { lat: 49.422888, lng: 21.067912 },
    { lat: 49.422888, lng: 21.067889 },
    { lat: 49.4228913, lng: 21.067796 },
    { lat: 49.4228833, lng: 21.0677525 },
    { lat: 49.422843, lng: 21.067669 },
    { lat: 49.4228407, lng: 21.0676645 },
    { lat: 49.422835, lng: 21.067639 },
    { lat: 49.4228269, lng: 21.0675872 },
    { lat: 49.42283, lng: 21.0675156 },
    { lat: 49.422828, lng: 21.067509 },
    { lat: 49.4228194, lng: 21.0674461 },
    { lat: 49.422821, lng: 21.067439 },
    { lat: 49.4227939, lng: 21.0673893 },
    { lat: 49.4227546, lng: 21.0673551 },
    { lat: 49.422749, lng: 21.067352 },
    { lat: 49.4227087, lng: 21.0673398 },
    { lat: 49.42269, lng: 21.067293 },
    { lat: 49.4226827, lng: 21.067281 },
    { lat: 49.4226309, lng: 21.067233 },
    { lat: 49.4226133, lng: 21.0672061 },
    { lat: 49.4225882, lng: 21.0671425 },
    { lat: 49.4225704, lng: 21.0670657 },
    { lat: 49.4225462, lng: 21.0670274 },
    { lat: 49.422544, lng: 21.067023 },
    { lat: 49.4224894, lng: 21.0668594 },
    { lat: 49.422487, lng: 21.066854 },
    { lat: 49.422316, lng: 21.066522 },
    { lat: 49.422166, lng: 21.06622 },
    { lat: 49.422158, lng: 21.0662055 },
    { lat: 49.4218153, lng: 21.0656642 },
    { lat: 49.4214322, lng: 21.065297 },
    { lat: 49.421152, lng: 21.0646494 },
    { lat: 49.420865, lng: 21.0640759 },
    { lat: 49.4204774, lng: 21.0637265 },
    { lat: 49.4205455, lng: 21.0631582 },
    { lat: 49.420742, lng: 21.062814 },
    { lat: 49.4208564, lng: 21.0626158 },
    { lat: 49.4211653, lng: 21.0624174 },
    { lat: 49.4215268, lng: 21.0617612 },
    { lat: 49.4217117, lng: 21.061383 },
    { lat: 49.4217847, lng: 21.0610002 },
    { lat: 49.4216948, lng: 21.0604209 },
    { lat: 49.4216596, lng: 21.0599358 },
    { lat: 49.421657, lng: 21.059916 },
    { lat: 49.4216494, lng: 21.0591811 },
    { lat: 49.421736, lng: 21.0588757 },
    { lat: 49.4217505, lng: 21.0579371 },
    { lat: 49.4216154, lng: 21.0568885 },
    { lat: 49.4216797, lng: 21.0564734 },
    { lat: 49.4215277, lng: 21.0557054 },
    { lat: 49.4213132, lng: 21.0552684 },
    { lat: 49.4212279, lng: 21.0547115 },
    { lat: 49.420897, lng: 21.05387 },
    { lat: 49.420558, lng: 21.052941 },
    { lat: 49.420279, lng: 21.052052 },
    { lat: 49.420035, lng: 21.050936 },
    { lat: 49.419768, lng: 21.050074 },
    { lat: 49.419392, lng: 21.048728 },
    { lat: 49.419105, lng: 21.048612 },
    { lat: 49.418835, lng: 21.048251 },
    { lat: 49.418672, lng: 21.048219 },
    { lat: 49.418339, lng: 21.047543 },
    { lat: 49.418097, lng: 21.047513 },
    { lat: 49.417366, lng: 21.047435 },
    { lat: 49.416945, lng: 21.047217 },
    { lat: 49.416426, lng: 21.047222 },
    { lat: 49.416181, lng: 21.047294 },
    { lat: 49.415515, lng: 21.047432 },
    { lat: 49.415165, lng: 21.047591 },
    { lat: 49.414845, lng: 21.047853 },
    { lat: 49.414554, lng: 21.04853 },
    { lat: 49.414121, lng: 21.049671 },
    { lat: 49.413305, lng: 21.050402 },
    { lat: 49.412615, lng: 21.050236 },
    { lat: 49.412136, lng: 21.049892 },
    { lat: 49.411446, lng: 21.050144 },
    { lat: 49.411063, lng: 21.050083 },
    { lat: 49.410859, lng: 21.050041 },
    { lat: 49.410121, lng: 21.050562 },
    { lat: 49.409754, lng: 21.051234 },
    { lat: 49.409133, lng: 21.051538 },
    { lat: 49.408606, lng: 21.051604 },
    { lat: 49.407966, lng: 21.05188 },
    { lat: 49.407617, lng: 21.051834 },
    { lat: 49.407388, lng: 21.05194 },
    { lat: 49.406622, lng: 21.052702 },
    { lat: 49.406189, lng: 21.053024 },
    { lat: 49.405581, lng: 21.053614 },
    { lat: 49.404738, lng: 21.054197 },
    { lat: 49.40443, lng: 21.054181 },
    { lat: 49.404219, lng: 21.054315 },
    { lat: 49.404052, lng: 21.05458 },
    { lat: 49.403695, lng: 21.055607 },
    { lat: 49.402961, lng: 21.05759 },
    { lat: 49.402661, lng: 21.058145 },
    { lat: 49.402654, lng: 21.058554 },
    { lat: 49.402281, lng: 21.060204 },
    { lat: 49.402169, lng: 21.061243 },
    { lat: 49.402078, lng: 21.062069 },
    { lat: 49.402069, lng: 21.062668 },
    { lat: 49.401994, lng: 21.063277 },
    { lat: 49.402109, lng: 21.064351 },
    { lat: 49.402432, lng: 21.065894 },
    { lat: 49.402244, lng: 21.066884 },
    { lat: 49.402083, lng: 21.06723 },
    { lat: 49.40165, lng: 21.067812 },
    { lat: 49.401473, lng: 21.068243 },
    { lat: 49.400585, lng: 21.068755 },
    { lat: 49.399858, lng: 21.069009 },
    { lat: 49.39923, lng: 21.068717 },
    { lat: 49.398958, lng: 21.068169 },
    { lat: 49.398617, lng: 21.067874 },
    { lat: 49.398191, lng: 21.067655 },
    { lat: 49.398109, lng: 21.067613 },
    { lat: 49.397339, lng: 21.067227 },
    { lat: 49.397143, lng: 21.067504 },
    { lat: 49.3962177, lng: 21.0672069 },
    { lat: 49.3955159, lng: 21.0679564 },
    { lat: 49.395434, lng: 21.068181 },
    { lat: 49.3952999, lng: 21.0685387 },
    { lat: 49.395288, lng: 21.068573 },
    { lat: 49.3949579, lng: 21.0694084 },
    { lat: 49.394508, lng: 21.070966 },
    { lat: 49.394459, lng: 21.07113 },
    { lat: 49.394214, lng: 21.071974 },
    { lat: 49.3942089, lng: 21.0719913 },
    { lat: 49.3938166, lng: 21.0735448 },
    { lat: 49.3932707, lng: 21.0750617 },
    { lat: 49.3928647, lng: 21.0753282 },
    { lat: 49.3923321, lng: 21.0760236 },
    { lat: 49.391961, lng: 21.076611 },
    { lat: 49.3919494, lng: 21.0766278 },
    { lat: 49.39152, lng: 21.077258 },
    { lat: 49.3914978, lng: 21.0772901 },
    { lat: 49.391488, lng: 21.077309 },
    { lat: 49.391066, lng: 21.078103 },
    { lat: 49.390956, lng: 21.078319 },
    { lat: 49.3909141, lng: 21.0783915 },
    { lat: 49.390909, lng: 21.078396 },
    { lat: 49.390598, lng: 21.078827 },
    { lat: 49.3902882, lng: 21.0792494 },
    { lat: 49.390155, lng: 21.079576 },
    { lat: 49.38994, lng: 21.080091 },
    { lat: 49.3899373, lng: 21.0801001 },
    { lat: 49.3898142, lng: 21.0806049 },
    { lat: 49.3896747, lng: 21.0811021 },
    { lat: 49.3897174, lng: 21.0814238 },
    { lat: 49.389234, lng: 21.08249 },
    { lat: 49.3892241, lng: 21.082512 },
    { lat: 49.389165, lng: 21.082662 },
    { lat: 49.3889502, lng: 21.0832024 },
    { lat: 49.388945, lng: 21.083218 },
    { lat: 49.3888879, lng: 21.0833725 },
    { lat: 49.388884, lng: 21.083385 },
    { lat: 49.388654, lng: 21.08379 },
    { lat: 49.38839, lng: 21.084273 },
  ],
  Cigeľka: [
    { lat: 49.3915752, lng: 21.1504288 },
    { lat: 49.391419, lng: 21.1511544 },
    { lat: 49.3909532, lng: 21.1519104 },
    { lat: 49.3903756, lng: 21.1526497 },
    { lat: 49.3901543, lng: 21.1537037 },
    { lat: 49.3901171, lng: 21.1554587 },
    { lat: 49.3899933, lng: 21.1564983 },
    { lat: 49.3897272, lng: 21.1578853 },
    { lat: 49.3893971, lng: 21.1590922 },
    { lat: 49.3889991, lng: 21.1602202 },
    { lat: 49.3888606, lng: 21.1603907 },
    { lat: 49.3886152, lng: 21.1609267 },
    { lat: 49.3880987, lng: 21.1622194 },
    { lat: 49.388067, lng: 21.1626161 },
    { lat: 49.3877032, lng: 21.1635743 },
    { lat: 49.3876001, lng: 21.1638759 },
    { lat: 49.3880259, lng: 21.1646136 },
    { lat: 49.3886024, lng: 21.165414 },
    { lat: 49.3896727, lng: 21.1674406 },
    { lat: 49.3906036, lng: 21.1686792 },
    { lat: 49.3914273, lng: 21.1680322 },
    { lat: 49.3920588, lng: 21.1667441 },
    { lat: 49.3924648, lng: 21.1678168 },
    { lat: 49.39258, lng: 21.1686207 },
    { lat: 49.3929593, lng: 21.1699284 },
    { lat: 49.3931914, lng: 21.1709791 },
    { lat: 49.3934242, lng: 21.1714526 },
    { lat: 49.3938584, lng: 21.1721292 },
    { lat: 49.3942668, lng: 21.1724537 },
    { lat: 49.3946862, lng: 21.173135 },
    { lat: 49.3952418, lng: 21.1735238 },
    { lat: 49.3958408, lng: 21.1734977 },
    { lat: 49.3965572, lng: 21.1736671 },
    { lat: 49.3971256, lng: 21.1741421 },
    { lat: 49.3979922, lng: 21.1747278 },
    { lat: 49.3990429, lng: 21.1752583 },
    { lat: 49.3999399, lng: 21.1754893 },
    { lat: 49.4003707, lng: 21.1757334 },
    { lat: 49.4015714, lng: 21.1759946 },
    { lat: 49.4018033, lng: 21.1763544 },
    { lat: 49.402028, lng: 21.1774298 },
    { lat: 49.4021684, lng: 21.1800294 },
    { lat: 49.4022797, lng: 21.1801566 },
    { lat: 49.4028107, lng: 21.1803053 },
    { lat: 49.4031911, lng: 21.1805838 },
    { lat: 49.403483, lng: 21.1808468 },
    { lat: 49.4039713, lng: 21.181426 },
    { lat: 49.4040834, lng: 21.181398 },
    { lat: 49.404325, lng: 21.181383 },
    { lat: 49.4043297, lng: 21.1813843 },
    { lat: 49.404607, lng: 21.181357 },
    { lat: 49.4046196, lng: 21.1813584 },
    { lat: 49.404632, lng: 21.181357 },
    { lat: 49.4051489, lng: 21.1814074 },
    { lat: 49.405647, lng: 21.181321 },
    { lat: 49.406135, lng: 21.181232 },
    { lat: 49.4067926, lng: 21.1811188 },
    { lat: 49.4071104, lng: 21.1810271 },
    { lat: 49.407126, lng: 21.18102 },
    { lat: 49.4071331, lng: 21.1810171 },
    { lat: 49.407509, lng: 21.1806884 },
    { lat: 49.4076522, lng: 21.180358 },
    { lat: 49.407967, lng: 21.1802016 },
    { lat: 49.4080071, lng: 21.1801703 },
    { lat: 49.4083131, lng: 21.1798741 },
    { lat: 49.4085337, lng: 21.1798119 },
    { lat: 49.408539, lng: 21.179811 },
    { lat: 49.4085442, lng: 21.1798044 },
    { lat: 49.4087272, lng: 21.1796907 },
    { lat: 49.4090281, lng: 21.1794218 },
    { lat: 49.409234, lng: 21.1791095 },
    { lat: 49.409315, lng: 21.1788669 },
    { lat: 49.4094911, lng: 21.1787437 },
    { lat: 49.4095727, lng: 21.1783916 },
    { lat: 49.409714, lng: 21.1775819 },
    { lat: 49.4098019, lng: 21.1772257 },
    { lat: 49.4100923, lng: 21.1766723 },
    { lat: 49.4102807, lng: 21.1761616 },
    { lat: 49.4106992, lng: 21.1755789 },
    { lat: 49.4108842, lng: 21.1754876 },
    { lat: 49.4113542, lng: 21.1747032 },
    { lat: 49.4115865, lng: 21.1741874 },
    { lat: 49.4117299, lng: 21.1739641 },
    { lat: 49.411872, lng: 21.1740448 },
    { lat: 49.4119348, lng: 21.1736932 },
    { lat: 49.4120992, lng: 21.1730203 },
    { lat: 49.4121248, lng: 21.1722987 },
    { lat: 49.412307, lng: 21.1718887 },
    { lat: 49.4126141, lng: 21.1714917 },
    { lat: 49.4131594, lng: 21.1701016 },
    { lat: 49.4133211, lng: 21.169897 },
    { lat: 49.4136292, lng: 21.1700106 },
    { lat: 49.41401, lng: 21.1694904 },
    { lat: 49.4144476, lng: 21.1690879 },
    { lat: 49.4148613, lng: 21.168692 },
    { lat: 49.4152207, lng: 21.1684737 },
    { lat: 49.4155474, lng: 21.1680961 },
    { lat: 49.4155435, lng: 21.1681491 },
    { lat: 49.4155868, lng: 21.1680446 },
    { lat: 49.4161333, lng: 21.1672801 },
    { lat: 49.416153, lng: 21.16716 },
    { lat: 49.416149, lng: 21.167154 },
    { lat: 49.4161153, lng: 21.1670913 },
    { lat: 49.4165502, lng: 21.1664803 },
    { lat: 49.4168219, lng: 21.1661367 },
    { lat: 49.4172518, lng: 21.1658961 },
    { lat: 49.4177273, lng: 21.1653104 },
    { lat: 49.417739, lng: 21.165298 },
    { lat: 49.4183401, lng: 21.1647104 },
    { lat: 49.41863, lng: 21.163549 },
    { lat: 49.418736, lng: 21.163126 },
    { lat: 49.4187503, lng: 21.1630655 },
    { lat: 49.418985, lng: 21.162037 },
    { lat: 49.419027, lng: 21.161853 },
    { lat: 49.419245, lng: 21.1608866 },
    { lat: 49.4194031, lng: 21.160594 },
    { lat: 49.4194094, lng: 21.1605473 },
    { lat: 49.419659, lng: 21.1600671 },
    { lat: 49.4197627, lng: 21.159038 },
    { lat: 49.4199199, lng: 21.1586558 },
    { lat: 49.419929, lng: 21.15863 },
    { lat: 49.419947, lng: 21.158536 },
    { lat: 49.4200055, lng: 21.1583177 },
    { lat: 49.4199285, lng: 21.1577901 },
    { lat: 49.4199383, lng: 21.1576503 },
    { lat: 49.419938, lng: 21.157645 },
    { lat: 49.419966, lng: 21.157566 },
    { lat: 49.420073, lng: 21.157243 },
    { lat: 49.4200778, lng: 21.1572268 },
    { lat: 49.4201832, lng: 21.1570378 },
    { lat: 49.420186, lng: 21.15703 },
    { lat: 49.4202864, lng: 21.1568919 },
    { lat: 49.420304, lng: 21.156865 },
    { lat: 49.420401, lng: 21.156705 },
    { lat: 49.4204718, lng: 21.1566007 },
    { lat: 49.4204492, lng: 21.1564617 },
    { lat: 49.4209919, lng: 21.1552287 },
    { lat: 49.4212959, lng: 21.1550458 },
    { lat: 49.4213652, lng: 21.1550252 },
    { lat: 49.4214257, lng: 21.1548765 },
    { lat: 49.4215832, lng: 21.1547661 },
    { lat: 49.4218001, lng: 21.1546774 },
    { lat: 49.4222972, lng: 21.154644 },
    { lat: 49.4224299, lng: 21.1546583 },
    { lat: 49.4227809, lng: 21.1547732 },
    { lat: 49.422967, lng: 21.154808 },
    { lat: 49.4232215, lng: 21.1548562 },
    { lat: 49.4235915, lng: 21.1550961 },
    { lat: 49.4238055, lng: 21.155174 },
    { lat: 49.4241554, lng: 21.1553953 },
    { lat: 49.4242591, lng: 21.1554222 },
    { lat: 49.4243535, lng: 21.1553994 },
    { lat: 49.424391, lng: 21.155336 },
    { lat: 49.4245025, lng: 21.1551778 },
    { lat: 49.424509, lng: 21.155167 },
    { lat: 49.424798, lng: 21.1547934 },
    { lat: 49.4248, lng: 21.154788 },
    { lat: 49.4249628, lng: 21.1546297 },
    { lat: 49.4252242, lng: 21.1544027 },
    { lat: 49.4254461, lng: 21.1540466 },
    { lat: 49.4254091, lng: 21.1539572 },
    { lat: 49.4255053, lng: 21.153671 },
    { lat: 49.4257195, lng: 21.1535632 },
    { lat: 49.4259684, lng: 21.1534727 },
    { lat: 49.426135, lng: 21.1532148 },
    { lat: 49.4264272, lng: 21.1530096 },
    { lat: 49.4267207, lng: 21.1525507 },
    { lat: 49.4269582, lng: 21.1522517 },
    { lat: 49.427032, lng: 21.1519869 },
    { lat: 49.4272507, lng: 21.1514661 },
    { lat: 49.427542, lng: 21.1509557 },
    { lat: 49.4276498, lng: 21.150461 },
    { lat: 49.427657, lng: 21.150429 },
    { lat: 49.4277984, lng: 21.1496809 },
    { lat: 49.4279254, lng: 21.1492145 },
    { lat: 49.4279478, lng: 21.1489065 },
    { lat: 49.428382, lng: 21.1482163 },
    { lat: 49.4283778, lng: 21.1480779 },
    { lat: 49.428835, lng: 21.14728 },
    { lat: 49.4291697, lng: 21.1466865 },
    { lat: 49.4293364, lng: 21.1456062 },
    { lat: 49.4294955, lng: 21.145163 },
    { lat: 49.429617, lng: 21.1445416 },
    { lat: 49.4297994, lng: 21.1439955 },
    { lat: 49.4301802, lng: 21.1431079 },
    { lat: 49.4303194, lng: 21.1425735 },
    { lat: 49.4305304, lng: 21.1415741 },
    { lat: 49.4306484, lng: 21.1403974 },
    { lat: 49.4314043, lng: 21.1390359 },
    { lat: 49.4318989, lng: 21.1378882 },
    { lat: 49.4320453, lng: 21.1374368 },
    { lat: 49.43243, lng: 21.1365099 },
    { lat: 49.4327691, lng: 21.1361244 },
    { lat: 49.433221, lng: 21.135234 },
    { lat: 49.4332246, lng: 21.1352261 },
    { lat: 49.4334373, lng: 21.1347303 },
    { lat: 49.4335259, lng: 21.1342943 },
    { lat: 49.4336253, lng: 21.1330177 },
    { lat: 49.434269, lng: 21.131858 },
    { lat: 49.4342813, lng: 21.1318361 },
    { lat: 49.434455, lng: 21.131167 },
    { lat: 49.434824, lng: 21.129693 },
    { lat: 49.434841, lng: 21.1296378 },
    { lat: 49.4349865, lng: 21.1291575 },
    { lat: 49.4350089, lng: 21.1283663 },
    { lat: 49.4351258, lng: 21.1273693 },
    { lat: 49.435139, lng: 21.127252 },
    { lat: 49.4351469, lng: 21.1272188 },
    { lat: 49.4352582, lng: 21.1267164 },
    { lat: 49.4356654, lng: 21.1257426 },
    { lat: 49.435685, lng: 21.125695 },
    { lat: 49.4360209, lng: 21.1248496 },
    { lat: 49.4363983, lng: 21.1242538 },
    { lat: 49.4365799, lng: 21.1240235 },
    { lat: 49.4363554, lng: 21.1236422 },
    { lat: 49.4360593, lng: 21.1230473 },
    { lat: 49.4357051, lng: 21.1218048 },
    { lat: 49.4353222, lng: 21.1210915 },
    { lat: 49.435067, lng: 21.1204932 },
    { lat: 49.4348299, lng: 21.1202151 },
    { lat: 49.434815, lng: 21.120199 },
    { lat: 49.434425, lng: 21.119875 },
    { lat: 49.433855, lng: 21.119384 },
    { lat: 49.4337806, lng: 21.1193238 },
    { lat: 49.4334152, lng: 21.1190268 },
    { lat: 49.4333156, lng: 21.1189377 },
    { lat: 49.4330916, lng: 21.1188336 },
    { lat: 49.4330143, lng: 21.1188082 },
    { lat: 49.4328374, lng: 21.1187168 },
    { lat: 49.4327475, lng: 21.1185987 },
    { lat: 49.43267, lng: 21.1185111 },
    { lat: 49.4326202, lng: 21.1184947 },
    { lat: 49.4324672, lng: 21.1184775 },
    { lat: 49.431904, lng: 21.1183301 },
    { lat: 49.4318295, lng: 21.1183005 },
    { lat: 49.4313661, lng: 21.1180494 },
    { lat: 49.4307213, lng: 21.117551 },
    { lat: 49.4304549, lng: 21.117383 },
    { lat: 49.430043, lng: 21.1173332 },
    { lat: 49.4296604, lng: 21.116854 },
    { lat: 49.4290615, lng: 21.1163386 },
    { lat: 49.4286918, lng: 21.1160938 },
    { lat: 49.4280825, lng: 21.1155256 },
    { lat: 49.42779, lng: 21.1152382 },
    { lat: 49.4273407, lng: 21.1150223 },
    { lat: 49.4271855, lng: 21.1150771 },
    { lat: 49.4267285, lng: 21.1149253 },
    { lat: 49.426478, lng: 21.114918 },
    { lat: 49.4260969, lng: 21.1149042 },
    { lat: 49.4254269, lng: 21.1145444 },
    { lat: 49.425402, lng: 21.114533 },
    { lat: 49.424568, lng: 21.114061 },
    { lat: 49.4244299, lng: 21.1139825 },
    { lat: 49.424228, lng: 21.113877 },
    { lat: 49.4239391, lng: 21.113728 },
    { lat: 49.424041, lng: 21.113278 },
    { lat: 49.424173, lng: 21.112741 },
    { lat: 49.424174, lng: 21.1127265 },
    { lat: 49.4242332, lng: 21.1119122 },
    { lat: 49.4244462, lng: 21.1101354 },
    { lat: 49.4246271, lng: 21.1094197 },
    { lat: 49.424773, lng: 21.108673 },
    { lat: 49.4247746, lng: 21.1086668 },
    { lat: 49.4249642, lng: 21.1077696 },
    { lat: 49.4252881, lng: 21.1072089 },
    { lat: 49.4253008, lng: 21.1066478 },
    { lat: 49.4254892, lng: 21.1062918 },
    { lat: 49.4254611, lng: 21.1059041 },
    { lat: 49.425856, lng: 21.1048337 },
    { lat: 49.425862, lng: 21.1048074 },
    { lat: 49.425929, lng: 21.104512 },
    { lat: 49.426154, lng: 21.103764 },
    { lat: 49.423887, lng: 21.1037914 },
    { lat: 49.4229336, lng: 21.1016872 },
    { lat: 49.422524, lng: 21.1018291 },
    { lat: 49.4215125, lng: 21.1017988 },
    { lat: 49.4202456, lng: 21.1024377 },
    { lat: 49.4186454, lng: 21.104554 },
    { lat: 49.4177162, lng: 21.1059071 },
    { lat: 49.4172008, lng: 21.1068317 },
    { lat: 49.4159448, lng: 21.1083091 },
    { lat: 49.4145208, lng: 21.110099 },
    { lat: 49.4133739, lng: 21.1117282 },
    { lat: 49.4123345, lng: 21.1130863 },
    { lat: 49.4115724, lng: 21.1137863 },
    { lat: 49.4104572, lng: 21.1143466 },
    { lat: 49.4096207, lng: 21.1144739 },
    { lat: 49.4081196, lng: 21.1154329 },
    { lat: 49.4069048, lng: 21.1160873 },
    { lat: 49.4061805, lng: 21.1169505 },
    { lat: 49.40573, lng: 21.1175741 },
    { lat: 49.4054747, lng: 21.1192888 },
    { lat: 49.4050934, lng: 21.1197872 },
    { lat: 49.4036932, lng: 21.1209852 },
    { lat: 49.4030277, lng: 21.1225702 },
    { lat: 49.4013097, lng: 21.1236779 },
    { lat: 49.4007705, lng: 21.1256273 },
    { lat: 49.4004117, lng: 21.128451 },
    { lat: 49.4000459, lng: 21.1301967 },
    { lat: 49.3999554, lng: 21.1309618 },
    { lat: 49.3999834, lng: 21.1316714 },
    { lat: 49.3995413, lng: 21.1334957 },
    { lat: 49.3995007, lng: 21.1342108 },
    { lat: 49.3990379, lng: 21.1358368 },
    { lat: 49.3986538, lng: 21.1368638 },
    { lat: 49.3984061, lng: 21.1384323 },
    { lat: 49.3986413, lng: 21.1395067 },
    { lat: 49.3985606, lng: 21.1400513 },
    { lat: 49.3980596, lng: 21.1412238 },
    { lat: 49.3979433, lng: 21.1416045 },
    { lat: 49.3978435, lng: 21.1416472 },
    { lat: 49.3974091, lng: 21.1425055 },
    { lat: 49.3974825, lng: 21.1429824 },
    { lat: 49.3971024, lng: 21.1467473 },
    { lat: 49.3967826, lng: 21.1481035 },
    { lat: 49.3964956, lng: 21.1497091 },
    { lat: 49.3960775, lng: 21.1505871 },
    { lat: 49.3938824, lng: 21.1506631 },
    { lat: 49.3930598, lng: 21.1505904 },
    { lat: 49.3919098, lng: 21.1505887 },
    { lat: 49.3916219, lng: 21.1504513 },
    { lat: 49.3915752, lng: 21.1504288 },
  ],
  Vaniškovce: [
    { lat: 49.1771929, lng: 21.2861046 },
    { lat: 49.1772126, lng: 21.2857885 },
    { lat: 49.1758225, lng: 21.285809 },
    { lat: 49.1746264, lng: 21.2857313 },
    { lat: 49.1737717, lng: 21.285637 },
    { lat: 49.173342, lng: 21.2855271 },
    { lat: 49.172297, lng: 21.2848759 },
    { lat: 49.1710534, lng: 21.2847181 },
    { lat: 49.1710398, lng: 21.2841204 },
    { lat: 49.1701325, lng: 21.2840374 },
    { lat: 49.1693547, lng: 21.283836 },
    { lat: 49.169161, lng: 21.2834606 },
    { lat: 49.1686699, lng: 21.2831137 },
    { lat: 49.1681065, lng: 21.282534 },
    { lat: 49.1675974, lng: 21.281108 },
    { lat: 49.1675087, lng: 21.280486 },
    { lat: 49.1671382, lng: 21.279513 },
    { lat: 49.1670697, lng: 21.2787789 },
    { lat: 49.1663223, lng: 21.2779926 },
    { lat: 49.1655118, lng: 21.277352 },
    { lat: 49.1651678, lng: 21.277292 },
    { lat: 49.1638522, lng: 21.2770625 },
    { lat: 49.1633225, lng: 21.2772528 },
    { lat: 49.1613944, lng: 21.2764979 },
    { lat: 49.1604388, lng: 21.276416 },
    { lat: 49.1565811, lng: 21.277686 },
    { lat: 49.1563419, lng: 21.2779995 },
    { lat: 49.1563147, lng: 21.2779582 },
    { lat: 49.1557703, lng: 21.2780751 },
    { lat: 49.1550386, lng: 21.2783997 },
    { lat: 49.1544907, lng: 21.2787732 },
    { lat: 49.1540746, lng: 21.278922 },
    { lat: 49.1538492, lng: 21.2792481 },
    { lat: 49.153172, lng: 21.2796976 },
    { lat: 49.1526423, lng: 21.280555 },
    { lat: 49.1523204, lng: 21.2813276 },
    { lat: 49.152196, lng: 21.281864 },
    { lat: 49.1523798, lng: 21.2820646 },
    { lat: 49.1529428, lng: 21.2819306 },
    { lat: 49.1540171, lng: 21.2820618 },
    { lat: 49.154644, lng: 21.2820307 },
    { lat: 49.1546394, lng: 21.2822123 },
    { lat: 49.1553267, lng: 21.2842433 },
    { lat: 49.155737, lng: 21.2858216 },
    { lat: 49.1558602, lng: 21.2868022 },
    { lat: 49.1558952, lng: 21.2887701 },
    { lat: 49.1561203, lng: 21.2898165 },
    { lat: 49.1563246, lng: 21.2910568 },
    { lat: 49.1565455, lng: 21.2930015 },
    { lat: 49.1565763, lng: 21.294795 },
    { lat: 49.1567508, lng: 21.2972575 },
    { lat: 49.1567518, lng: 21.2991037 },
    { lat: 49.1567083, lng: 21.2992323 },
    { lat: 49.1566437, lng: 21.3000762 },
    { lat: 49.1566679, lng: 21.3014273 },
    { lat: 49.1562831, lng: 21.30221 },
    { lat: 49.1565462, lng: 21.3023946 },
    { lat: 49.1574755, lng: 21.302723 },
    { lat: 49.1574328, lng: 21.3030585 },
    { lat: 49.157112, lng: 21.3031648 },
    { lat: 49.1569433, lng: 21.3040247 },
    { lat: 49.1568309, lng: 21.3048475 },
    { lat: 49.1567412, lng: 21.307133 },
    { lat: 49.1567782, lng: 21.30821 },
    { lat: 49.1574811, lng: 21.3083498 },
    { lat: 49.1573946, lng: 21.3092114 },
    { lat: 49.1572765, lng: 21.3098057 },
    { lat: 49.1589809, lng: 21.3122584 },
    { lat: 49.1603754, lng: 21.3138023 },
    { lat: 49.1606062, lng: 21.3141068 },
    { lat: 49.1610236, lng: 21.3138435 },
    { lat: 49.1611191, lng: 21.313428 },
    { lat: 49.1608967, lng: 21.3124334 },
    { lat: 49.1605027, lng: 21.3121284 },
    { lat: 49.1605029, lng: 21.3110941 },
    { lat: 49.1609053, lng: 21.309663 },
    { lat: 49.1614054, lng: 21.3091236 },
    { lat: 49.1616188, lng: 21.3085459 },
    { lat: 49.1618652, lng: 21.3081008 },
    { lat: 49.1625514, lng: 21.3100704 },
    { lat: 49.1647314, lng: 21.3087098 },
    { lat: 49.1666611, lng: 21.3077345 },
    { lat: 49.1682117, lng: 21.3066221 },
    { lat: 49.1689618, lng: 21.3056777 },
    { lat: 49.1697059, lng: 21.3061674 },
    { lat: 49.170903, lng: 21.3054191 },
    { lat: 49.1734578, lng: 21.3033546 },
    { lat: 49.1754661, lng: 21.3035012 },
    { lat: 49.1768451, lng: 21.3022172 },
    { lat: 49.1781263, lng: 21.300422 },
    { lat: 49.1773664, lng: 21.298879 },
    { lat: 49.179703, lng: 21.2974529 },
    { lat: 49.1804016, lng: 21.2963366 },
    { lat: 49.1799168, lng: 21.2951843 },
    { lat: 49.1797223, lng: 21.2936669 },
    { lat: 49.1793787, lng: 21.2928384 },
    { lat: 49.1791598, lng: 21.2927412 },
    { lat: 49.1788881, lng: 21.2909042 },
    { lat: 49.1789186, lng: 21.2908765 },
    { lat: 49.1788741, lng: 21.2898503 },
    { lat: 49.1790728, lng: 21.2881319 },
    { lat: 49.178984, lng: 21.28769 },
    { lat: 49.1788318, lng: 21.2876414 },
    { lat: 49.1787985, lng: 21.2874676 },
    { lat: 49.1785668, lng: 21.2874685 },
    { lat: 49.178363, lng: 21.2877317 },
    { lat: 49.1783766, lng: 21.2878066 },
    { lat: 49.1782829, lng: 21.2878654 },
    { lat: 49.178193, lng: 21.287538 },
    { lat: 49.1775872, lng: 21.2877847 },
    { lat: 49.1775616, lng: 21.2876081 },
    { lat: 49.1772454, lng: 21.2872722 },
    { lat: 49.1772016, lng: 21.2865697 },
    { lat: 49.1771929, lng: 21.2861046 },
  ],
  Richvald: [
    { lat: 49.3054935, lng: 21.1730787 },
    { lat: 49.3054475, lng: 21.1726551 },
    { lat: 49.3053913, lng: 21.1722615 },
    { lat: 49.3052493, lng: 21.1723256 },
    { lat: 49.3040803, lng: 21.1713152 },
    { lat: 49.3008315, lng: 21.1682146 },
    { lat: 49.3004616, lng: 21.1680066 },
    { lat: 49.2997705, lng: 21.1679302 },
    { lat: 49.2993962, lng: 21.1677236 },
    { lat: 49.2988023, lng: 21.1675233 },
    { lat: 49.2983492, lng: 21.1674573 },
    { lat: 49.2980511, lng: 21.1672448 },
    { lat: 49.2977469, lng: 21.1679146 },
    { lat: 49.297797, lng: 21.1680068 },
    { lat: 49.2977888, lng: 21.1684606 },
    { lat: 49.2977162, lng: 21.1685921 },
    { lat: 49.2977141, lng: 21.1687048 },
    { lat: 49.2976225, lng: 21.1687605 },
    { lat: 49.29758, lng: 21.1688718 },
    { lat: 49.2976493, lng: 21.1691126 },
    { lat: 49.2975533, lng: 21.169496 },
    { lat: 49.2976438, lng: 21.1696534 },
    { lat: 49.2973845, lng: 21.1701836 },
    { lat: 49.2970463, lng: 21.1698727 },
    { lat: 49.2956349, lng: 21.1698284 },
    { lat: 49.2947294, lng: 21.1704051 },
    { lat: 49.2946339, lng: 21.171388 },
    { lat: 49.2935737, lng: 21.1709478 },
    { lat: 49.2933184, lng: 21.1705944 },
    { lat: 49.2928436, lng: 21.1697661 },
    { lat: 49.2927765, lng: 21.1695604 },
    { lat: 49.292185, lng: 21.1700071 },
    { lat: 49.2919314, lng: 21.1699597 },
    { lat: 49.2914618, lng: 21.1704817 },
    { lat: 49.2909758, lng: 21.1707061 },
    { lat: 49.2908451, lng: 21.1702308 },
    { lat: 49.2909737, lng: 21.1687629 },
    { lat: 49.290639, lng: 21.1688945 },
    { lat: 49.2902852, lng: 21.167942 },
    { lat: 49.2898173, lng: 21.1669809 },
    { lat: 49.2873854, lng: 21.1628733 },
    { lat: 49.2853747, lng: 21.1597581 },
    { lat: 49.2850407, lng: 21.1594387 },
    { lat: 49.2816805, lng: 21.1544467 },
    { lat: 49.2809567, lng: 21.1535476 },
    { lat: 49.2801358, lng: 21.1522682 },
    { lat: 49.2798728, lng: 21.1517779 },
    { lat: 49.2796666, lng: 21.1521632 },
    { lat: 49.279228, lng: 21.1527778 },
    { lat: 49.2783901, lng: 21.1537951 },
    { lat: 49.2776511, lng: 21.1544851 },
    { lat: 49.2767852, lng: 21.1545061 },
    { lat: 49.2761546, lng: 21.1548497 },
    { lat: 49.2759106, lng: 21.1548867 },
    { lat: 49.2756072, lng: 21.1548304 },
    { lat: 49.2751326, lng: 21.1549809 },
    { lat: 49.2738702, lng: 21.155742 },
    { lat: 49.2736463, lng: 21.1559541 },
    { lat: 49.2719482, lng: 21.1584585 },
    { lat: 49.2714964, lng: 21.1587669 },
    { lat: 49.2710995, lng: 21.1592374 },
    { lat: 49.2703953, lng: 21.1603236 },
    { lat: 49.2696356, lng: 21.1612102 },
    { lat: 49.2693788, lng: 21.161603 },
    { lat: 49.2690838, lng: 21.1621723 },
    { lat: 49.2687208, lng: 21.163335 },
    { lat: 49.2684518, lng: 21.1636891 },
    { lat: 49.2674061, lng: 21.1656184 },
    { lat: 49.266932, lng: 21.1663283 },
    { lat: 49.2668072, lng: 21.1664379 },
    { lat: 49.266438, lng: 21.1665367 },
    { lat: 49.2660752, lng: 21.166383 },
    { lat: 49.2656318, lng: 21.1663027 },
    { lat: 49.2653988, lng: 21.1661587 },
    { lat: 49.2652325, lng: 21.1661654 },
    { lat: 49.2650962, lng: 21.1663444 },
    { lat: 49.2649373, lng: 21.1669105 },
    { lat: 49.2647831, lng: 21.1671971 },
    { lat: 49.2642839, lng: 21.167565 },
    { lat: 49.2639499, lng: 21.1676442 },
    { lat: 49.263387, lng: 21.1675672 },
    { lat: 49.2628097, lng: 21.167604 },
    { lat: 49.2626049, lng: 21.1675307 },
    { lat: 49.2620068, lng: 21.1671882 },
    { lat: 49.2614742, lng: 21.1666712 },
    { lat: 49.2612419, lng: 21.1663305 },
    { lat: 49.2611114, lng: 21.1660115 },
    { lat: 49.2605553, lng: 21.1643509 },
    { lat: 49.2596174, lng: 21.1631129 },
    { lat: 49.2593048, lng: 21.1621155 },
    { lat: 49.2591929, lng: 21.1619911 },
    { lat: 49.2587116, lng: 21.1617486 },
    { lat: 49.2582524, lng: 21.161386 },
    { lat: 49.2582017, lng: 21.161166 },
    { lat: 49.2582535, lng: 21.1605252 },
    { lat: 49.2582074, lng: 21.1602372 },
    { lat: 49.2580198, lng: 21.1599991 },
    { lat: 49.2577745, lng: 21.1598532 },
    { lat: 49.2573582, lng: 21.1597824 },
    { lat: 49.2572205, lng: 21.1596999 },
    { lat: 49.2559722, lng: 21.15843 },
    { lat: 49.2556499, lng: 21.1579515 },
    { lat: 49.2552289, lng: 21.1568372 },
    { lat: 49.2548177, lng: 21.1562427 },
    { lat: 49.2544617, lng: 21.1554107 },
    { lat: 49.2540442, lng: 21.1547249 },
    { lat: 49.2534938, lng: 21.1542206 },
    { lat: 49.2534445, lng: 21.1541907 },
    { lat: 49.2530626, lng: 21.1539993 },
    { lat: 49.2527509, lng: 21.1539296 },
    { lat: 49.2515867, lng: 21.1541926 },
    { lat: 49.2512268, lng: 21.1540926 },
    { lat: 49.2509144, lng: 21.1536437 },
    { lat: 49.2506686, lng: 21.1536232 },
    { lat: 49.2505417, lng: 21.1535237 },
    { lat: 49.2503194, lng: 21.1530771 },
    { lat: 49.2498819, lng: 21.1531564 },
    { lat: 49.2495643, lng: 21.1528577 },
    { lat: 49.2490621, lng: 21.1521303 },
    { lat: 49.2485603, lng: 21.1517766 },
    { lat: 49.2479224, lng: 21.1509205 },
    { lat: 49.2474876, lng: 21.1509074 },
    { lat: 49.247116, lng: 21.1507186 },
    { lat: 49.2466866, lng: 21.1509507 },
    { lat: 49.246313, lng: 21.1508737 },
    { lat: 49.2461988, lng: 21.1509329 },
    { lat: 49.2458566, lng: 21.1507124 },
    { lat: 49.2455688, lng: 21.1504378 },
    { lat: 49.2451894, lng: 21.1502506 },
    { lat: 49.2450186, lng: 21.1502563 },
    { lat: 49.2449622, lng: 21.150008 },
    { lat: 49.2442032, lng: 21.1505244 },
    { lat: 49.2439191, lng: 21.1504678 },
    { lat: 49.2431451, lng: 21.150042 },
    { lat: 49.2428838, lng: 21.1500349 },
    { lat: 49.2429053, lng: 21.1501479 },
    { lat: 49.2425867, lng: 21.1503953 },
    { lat: 49.2424918, lng: 21.1508092 },
    { lat: 49.2420709, lng: 21.1510296 },
    { lat: 49.2418312, lng: 21.1512416 },
    { lat: 49.2409583, lng: 21.1516752 },
    { lat: 49.2402409, lng: 21.151905 },
    { lat: 49.2396279, lng: 21.1516673 },
    { lat: 49.2391745, lng: 21.1535296 },
    { lat: 49.2389831, lng: 21.154852 },
    { lat: 49.2389381, lng: 21.1558481 },
    { lat: 49.2390754, lng: 21.1567812 },
    { lat: 49.2390121, lng: 21.1569875 },
    { lat: 49.2391192, lng: 21.1572822 },
    { lat: 49.2391636, lng: 21.1579091 },
    { lat: 49.2394277, lng: 21.1592218 },
    { lat: 49.2395322, lng: 21.1600425 },
    { lat: 49.2397155, lng: 21.16058 },
    { lat: 49.2397571, lng: 21.1619429 },
    { lat: 49.2400034, lng: 21.1639833 },
    { lat: 49.2403936, lng: 21.1650867 },
    { lat: 49.2406599, lng: 21.1656481 },
    { lat: 49.2408826, lng: 21.1658922 },
    { lat: 49.2410999, lng: 21.1663628 },
    { lat: 49.2412902, lng: 21.1663702 },
    { lat: 49.2413891, lng: 21.1665444 },
    { lat: 49.241495, lng: 21.1668691 },
    { lat: 49.2413729, lng: 21.1671794 },
    { lat: 49.2415478, lng: 21.167803 },
    { lat: 49.2417811, lng: 21.1682131 },
    { lat: 49.241857, lng: 21.1686948 },
    { lat: 49.2421127, lng: 21.1692001 },
    { lat: 49.2429182, lng: 21.1702429 },
    { lat: 49.2431227, lng: 21.1711694 },
    { lat: 49.2434269, lng: 21.1715779 },
    { lat: 49.243623, lng: 21.1720498 },
    { lat: 49.2437991, lng: 21.1722833 },
    { lat: 49.2443681, lng: 21.1724878 },
    { lat: 49.2454424, lng: 21.1730223 },
    { lat: 49.2460965, lng: 21.172886 },
    { lat: 49.2462198, lng: 21.1729782 },
    { lat: 49.2467288, lng: 21.1736769 },
    { lat: 49.2468072, lng: 21.1739136 },
    { lat: 49.2469577, lng: 21.1740891 },
    { lat: 49.2470867, lng: 21.1746484 },
    { lat: 49.2473696, lng: 21.1754904 },
    { lat: 49.2481912, lng: 21.1765813 },
    { lat: 49.2484845, lng: 21.1768887 },
    { lat: 49.248586, lng: 21.1770726 },
    { lat: 49.248687, lng: 21.1774621 },
    { lat: 49.2490742, lng: 21.1780066 },
    { lat: 49.249513, lng: 21.1787598 },
    { lat: 49.2501758, lng: 21.1795911 },
    { lat: 49.2505312, lng: 21.1806364 },
    { lat: 49.2507163, lng: 21.1809766 },
    { lat: 49.2512063, lng: 21.1815335 },
    { lat: 49.2514261, lng: 21.1820124 },
    { lat: 49.2516382, lng: 21.1835519 },
    { lat: 49.2519223, lng: 21.1846086 },
    { lat: 49.2520144, lng: 21.1851127 },
    { lat: 49.2521861, lng: 21.1855314 },
    { lat: 49.2525149, lng: 21.18669 },
    { lat: 49.2526526, lng: 21.1868875 },
    { lat: 49.2529756, lng: 21.1876136 },
    { lat: 49.2542342, lng: 21.1898678 },
    { lat: 49.2543367, lng: 21.1904201 },
    { lat: 49.2546446, lng: 21.1913412 },
    { lat: 49.2551297, lng: 21.1918517 },
    { lat: 49.2555878, lng: 21.1920393 },
    { lat: 49.2559714, lng: 21.1918864 },
    { lat: 49.2568951, lng: 21.1922477 },
    { lat: 49.2571265, lng: 21.192599 },
    { lat: 49.2572407, lng: 21.1930864 },
    { lat: 49.2575934, lng: 21.1937907 },
    { lat: 49.257977, lng: 21.1942218 },
    { lat: 49.2586743, lng: 21.1952263 },
    { lat: 49.2588128, lng: 21.1952292 },
    { lat: 49.2589305, lng: 21.1954271 },
    { lat: 49.2591925, lng: 21.1955735 },
    { lat: 49.2594545, lng: 21.1961035 },
    { lat: 49.2592937, lng: 21.1963634 },
    { lat: 49.2592934, lng: 21.196537 },
    { lat: 49.2594253, lng: 21.1966536 },
    { lat: 49.2593987, lng: 21.1968054 },
    { lat: 49.2592137, lng: 21.1969002 },
    { lat: 49.2592455, lng: 21.1972009 },
    { lat: 49.2595155, lng: 21.197204 },
    { lat: 49.2595421, lng: 21.1973321 },
    { lat: 49.2594494, lng: 21.1976112 },
    { lat: 49.2592757, lng: 21.1977405 },
    { lat: 49.2592187, lng: 21.1980404 },
    { lat: 49.2591894, lng: 21.1982569 },
    { lat: 49.2592395, lng: 21.1983537 },
    { lat: 49.2592745, lng: 21.1987125 },
    { lat: 49.259201, lng: 21.1989978 },
    { lat: 49.2592849, lng: 21.1993736 },
    { lat: 49.259092, lng: 21.1997993 },
    { lat: 49.2590715, lng: 21.2000796 },
    { lat: 49.259195, lng: 21.2004677 },
    { lat: 49.2591208, lng: 21.2006125 },
    { lat: 49.2591066, lng: 21.200915 },
    { lat: 49.2592209, lng: 21.2012623 },
    { lat: 49.2591912, lng: 21.2017962 },
    { lat: 49.2592682, lng: 21.201961 },
    { lat: 49.2588238, lng: 21.2026142 },
    { lat: 49.2587111, lng: 21.2030028 },
    { lat: 49.2586498, lng: 21.2033142 },
    { lat: 49.2586584, lng: 21.2042077 },
    { lat: 49.2572499, lng: 21.2054905 },
    { lat: 49.2575028, lng: 21.2062402 },
    { lat: 49.2575038, lng: 21.2063489 },
    { lat: 49.2569044, lng: 21.2069031 },
    { lat: 49.2567298, lng: 21.2071895 },
    { lat: 49.256613, lng: 21.2077023 },
    { lat: 49.2564569, lng: 21.2090565 },
    { lat: 49.2563538, lng: 21.2109987 },
    { lat: 49.2564577, lng: 21.2125722 },
    { lat: 49.2563592, lng: 21.2128416 },
    { lat: 49.2563541, lng: 21.2140938 },
    { lat: 49.256428, lng: 21.2148157 },
    { lat: 49.2563688, lng: 21.2150448 },
    { lat: 49.2563987, lng: 21.2154299 },
    { lat: 49.2563249, lng: 21.2160631 },
    { lat: 49.2567128, lng: 21.2172267 },
    { lat: 49.2567826, lng: 21.2176622 },
    { lat: 49.256888, lng: 21.2178283 },
    { lat: 49.2569893, lng: 21.2187421 },
    { lat: 49.2571039, lng: 21.2189957 },
    { lat: 49.2571904, lng: 21.2194978 },
    { lat: 49.2571466, lng: 21.219788 },
    { lat: 49.2572602, lng: 21.2201941 },
    { lat: 49.2572887, lng: 21.2207097 },
    { lat: 49.2572564, lng: 21.221306 },
    { lat: 49.2572053, lng: 21.2214383 },
    { lat: 49.2572616, lng: 21.2217271 },
    { lat: 49.2574124, lng: 21.2219857 },
    { lat: 49.2573356, lng: 21.222706 },
    { lat: 49.2573441, lng: 21.2230584 },
    { lat: 49.257077, lng: 21.2240978 },
    { lat: 49.2570105, lng: 21.2246807 },
    { lat: 49.2571029, lng: 21.2262551 },
    { lat: 49.2571786, lng: 21.2265591 },
    { lat: 49.2571681, lng: 21.2266939 },
    { lat: 49.2570529, lng: 21.2267901 },
    { lat: 49.2570487, lng: 21.2269042 },
    { lat: 49.2570523, lng: 21.2271694 },
    { lat: 49.2571681, lng: 21.2273455 },
    { lat: 49.2570826, lng: 21.2278999 },
    { lat: 49.2571358, lng: 21.2280197 },
    { lat: 49.2571663, lng: 21.2284191 },
    { lat: 49.2570816, lng: 21.2287008 },
    { lat: 49.257164, lng: 21.2289355 },
    { lat: 49.2571568, lng: 21.2291749 },
    { lat: 49.2571068, lng: 21.2296349 },
    { lat: 49.2573566, lng: 21.2303262 },
    { lat: 49.257488, lng: 21.2304288 },
    { lat: 49.2575239, lng: 21.2300638 },
    { lat: 49.2576005, lng: 21.2298407 },
    { lat: 49.2575848, lng: 21.2295519 },
    { lat: 49.2576641, lng: 21.22924 },
    { lat: 49.2575998, lng: 21.2291279 },
    { lat: 49.2576277, lng: 21.2285333 },
    { lat: 49.2576, lng: 21.228071 },
    { lat: 49.2575456, lng: 21.2279495 },
    { lat: 49.2576591, lng: 21.2278005 },
    { lat: 49.2580418, lng: 21.2282074 },
    { lat: 49.2579131, lng: 21.2285214 },
    { lat: 49.2579956, lng: 21.2286038 },
    { lat: 49.2580765, lng: 21.2283822 },
    { lat: 49.2583674, lng: 21.2279304 },
    { lat: 49.2582725, lng: 21.2277647 },
    { lat: 49.2583687, lng: 21.2274962 },
    { lat: 49.2584576, lng: 21.2274196 },
    { lat: 49.2585592, lng: 21.227506 },
    { lat: 49.2587613, lng: 21.2273077 },
    { lat: 49.2588177, lng: 21.2273847 },
    { lat: 49.2589249, lng: 21.2272895 },
    { lat: 49.259149, lng: 21.227255 },
    { lat: 49.2593892, lng: 21.2269077 },
    { lat: 49.2595133, lng: 21.2265886 },
    { lat: 49.2594022, lng: 21.2263434 },
    { lat: 49.2594483, lng: 21.2258656 },
    { lat: 49.2592971, lng: 21.2257482 },
    { lat: 49.2594636, lng: 21.225055 },
    { lat: 49.2595086, lng: 21.225014 },
    { lat: 49.2597048, lng: 21.2253197 },
    { lat: 49.259876, lng: 21.2251756 },
    { lat: 49.2602595, lng: 21.2251732 },
    { lat: 49.2604962, lng: 21.2250791 },
    { lat: 49.260546, lng: 21.2249728 },
    { lat: 49.260668, lng: 21.2247025 },
    { lat: 49.2606981, lng: 21.2242846 },
    { lat: 49.2609451, lng: 21.2238323 },
    { lat: 49.2615694, lng: 21.2233158 },
    { lat: 49.2619043, lng: 21.2233043 },
    { lat: 49.2619683, lng: 21.2237367 },
    { lat: 49.2630957, lng: 21.2233724 },
    { lat: 49.2640864, lng: 21.2231728 },
    { lat: 49.2646964, lng: 21.2229502 },
    { lat: 49.2655131, lng: 21.2223293 },
    { lat: 49.2658937, lng: 21.2218937 },
    { lat: 49.2660801, lng: 21.2215928 },
    { lat: 49.2664004, lng: 21.2207788 },
    { lat: 49.2666528, lng: 21.2210417 },
    { lat: 49.2668704, lng: 21.2206981 },
    { lat: 49.2671465, lng: 21.2206379 },
    { lat: 49.2672623, lng: 21.2203784 },
    { lat: 49.2672358, lng: 21.2197029 },
    { lat: 49.2672932, lng: 21.2194073 },
    { lat: 49.2674431, lng: 21.2191299 },
    { lat: 49.2676639, lng: 21.219462 },
    { lat: 49.2677486, lng: 21.2203891 },
    { lat: 49.2678696, lng: 21.2211309 },
    { lat: 49.2681265, lng: 21.2212194 },
    { lat: 49.2683455, lng: 21.2211345 },
    { lat: 49.2686646, lng: 21.2214801 },
    { lat: 49.2690268, lng: 21.2217358 },
    { lat: 49.269325, lng: 21.2217323 },
    { lat: 49.2694303, lng: 21.2216054 },
    { lat: 49.2696722, lng: 21.2216428 },
    { lat: 49.2698456, lng: 21.2215013 },
    { lat: 49.2699812, lng: 21.2215247 },
    { lat: 49.2702398, lng: 21.2217003 },
    { lat: 49.2704799, lng: 21.2210524 },
    { lat: 49.2706574, lng: 21.2208281 },
    { lat: 49.2709578, lng: 21.2209354 },
    { lat: 49.2710527, lng: 21.2208425 },
    { lat: 49.2711591, lng: 21.2205685 },
    { lat: 49.2713494, lng: 21.2204229 },
    { lat: 49.2717537, lng: 21.2204731 },
    { lat: 49.2719136, lng: 21.2201848 },
    { lat: 49.2720913, lng: 21.2202902 },
    { lat: 49.2721273, lng: 21.2204036 },
    { lat: 49.2722466, lng: 21.2204022 },
    { lat: 49.2725903, lng: 21.2201998 },
    { lat: 49.2728732, lng: 21.2199232 },
    { lat: 49.2736619, lng: 21.219923 },
    { lat: 49.273888, lng: 21.2197784 },
    { lat: 49.2741615, lng: 21.2197414 },
    { lat: 49.2756969, lng: 21.2199457 },
    { lat: 49.2760595, lng: 21.2202125 },
    { lat: 49.2768395, lng: 21.2199592 },
    { lat: 49.2775836, lng: 21.2193174 },
    { lat: 49.2781477, lng: 21.2191844 },
    { lat: 49.2788786, lng: 21.2191271 },
    { lat: 49.2791272, lng: 21.218978 },
    { lat: 49.2793563, lng: 21.2192577 },
    { lat: 49.2800357, lng: 21.2197979 },
    { lat: 49.2803247, lng: 21.2199 },
    { lat: 49.2817718, lng: 21.2196533 },
    { lat: 49.2820167, lng: 21.2194576 },
    { lat: 49.2823514, lng: 21.2196155 },
    { lat: 49.2826012, lng: 21.2195416 },
    { lat: 49.2836819, lng: 21.2203619 },
    { lat: 49.2843707, lng: 21.2206244 },
    { lat: 49.284591, lng: 21.2198858 },
    { lat: 49.2850412, lng: 21.2188741 },
    { lat: 49.2854481, lng: 21.2182745 },
    { lat: 49.2862421, lng: 21.2164763 },
    { lat: 49.286221, lng: 21.2164505 },
    { lat: 49.2866154, lng: 21.2154484 },
    { lat: 49.2869014, lng: 21.2148684 },
    { lat: 49.2874621, lng: 21.2131341 },
    { lat: 49.2880789, lng: 21.2109225 },
    { lat: 49.288909, lng: 21.2089467 },
    { lat: 49.289511, lng: 21.20698 },
    { lat: 49.2900335, lng: 21.2057635 },
    { lat: 49.2913268, lng: 21.2035254 },
    { lat: 49.2916502, lng: 21.2027384 },
    { lat: 49.2913618, lng: 21.2023646 },
    { lat: 49.2912502, lng: 21.2019225 },
    { lat: 49.2919788, lng: 21.1999843 },
    { lat: 49.2924107, lng: 21.1991751 },
    { lat: 49.2928345, lng: 21.1981923 },
    { lat: 49.2930692, lng: 21.1972654 },
    { lat: 49.2932255, lng: 21.1962468 },
    { lat: 49.293901, lng: 21.1949566 },
    { lat: 49.2961089, lng: 21.1919593 },
    { lat: 49.2966014, lng: 21.1911134 },
    { lat: 49.2970468, lng: 21.1900918 },
    { lat: 49.2973174, lng: 21.1888845 },
    { lat: 49.2976408, lng: 21.1880296 },
    { lat: 49.2979737, lng: 21.1874087 },
    { lat: 49.2987942, lng: 21.1862562 },
    { lat: 49.29918, lng: 21.1858936 },
    { lat: 49.2997498, lng: 21.1851854 },
    { lat: 49.3003408, lng: 21.184863 },
    { lat: 49.3005492, lng: 21.1846581 },
    { lat: 49.3007752, lng: 21.1842173 },
    { lat: 49.3010482, lng: 21.1834496 },
    { lat: 49.3012125, lng: 21.1831914 },
    { lat: 49.3013179, lng: 21.1828967 },
    { lat: 49.3019847, lng: 21.1809671 },
    { lat: 49.3021449, lng: 21.1803634 },
    { lat: 49.3024592, lng: 21.1798724 },
    { lat: 49.3029893, lng: 21.1777125 },
    { lat: 49.3031049, lng: 21.175682 },
    { lat: 49.3032714, lng: 21.1758956 },
    { lat: 49.3033324, lng: 21.1757249 },
    { lat: 49.3039312, lng: 21.1756621 },
    { lat: 49.3044963, lng: 21.1750944 },
    { lat: 49.3048507, lng: 21.1746542 },
    { lat: 49.3053647, lng: 21.1736063 },
    { lat: 49.3054935, lng: 21.1730787 },
  ],
  Porúbka: [
    { lat: 49.1999736, lng: 21.4383085 },
    { lat: 49.2005334, lng: 21.4369718 },
    { lat: 49.2010214, lng: 21.4364186 },
    { lat: 49.2014623, lng: 21.4360635 },
    { lat: 49.2020534, lng: 21.4360815 },
    { lat: 49.2026061, lng: 21.4363182 },
    { lat: 49.2030793, lng: 21.4367727 },
    { lat: 49.2042496, lng: 21.4327177 },
    { lat: 49.2042503, lng: 21.432465 },
    { lat: 49.2046411, lng: 21.4326008 },
    { lat: 49.2054294, lng: 21.429914 },
    { lat: 49.206536, lng: 21.4290877 },
    { lat: 49.2072818, lng: 21.429194 },
    { lat: 49.2075895, lng: 21.4283707 },
    { lat: 49.2078316, lng: 21.4274882 },
    { lat: 49.2085617, lng: 21.4254361 },
    { lat: 49.2096764, lng: 21.4225681 },
    { lat: 49.2103306, lng: 21.4177653 },
    { lat: 49.2096354, lng: 21.4179299 },
    { lat: 49.2080134, lng: 21.4186735 },
    { lat: 49.2067382, lng: 21.4189661 },
    { lat: 49.2064435, lng: 21.4191606 },
    { lat: 49.2030248, lng: 21.4174728 },
    { lat: 49.2014683, lng: 21.4174571 },
    { lat: 49.2005828, lng: 21.4179605 },
    { lat: 49.2000093, lng: 21.4172753 },
    { lat: 49.1978105, lng: 21.4111045 },
    { lat: 49.1961932, lng: 21.4120479 },
    { lat: 49.1949823, lng: 21.4116548 },
    { lat: 49.1935953, lng: 21.4118155 },
    { lat: 49.1931964, lng: 21.4114124 },
    { lat: 49.1927771, lng: 21.4114957 },
    { lat: 49.1929139, lng: 21.412063 },
    { lat: 49.1923208, lng: 21.4131279 },
    { lat: 49.1922956, lng: 21.4152351 },
    { lat: 49.1924897, lng: 21.4151003 },
    { lat: 49.1926564, lng: 21.4154152 },
    { lat: 49.1926077, lng: 21.4163283 },
    { lat: 49.1927903, lng: 21.417762 },
    { lat: 49.1925245, lng: 21.4182171 },
    { lat: 49.1920853, lng: 21.4185812 },
    { lat: 49.1901391, lng: 21.4173549 },
    { lat: 49.1900262, lng: 21.4175424 },
    { lat: 49.1901062, lng: 21.4205502 },
    { lat: 49.1899794, lng: 21.4208329 },
    { lat: 49.1889456, lng: 21.4222632 },
    { lat: 49.1853622, lng: 21.4264654 },
    { lat: 49.1854486, lng: 21.4270031 },
    { lat: 49.1854955, lng: 21.4272944 },
    { lat: 49.186368, lng: 21.4271655 },
    { lat: 49.1871286, lng: 21.4270528 },
    { lat: 49.1887257, lng: 21.4266823 },
    { lat: 49.1887647, lng: 21.4274037 },
    { lat: 49.1903952, lng: 21.4275651 },
    { lat: 49.1915419, lng: 21.4286967 },
    { lat: 49.193713, lng: 21.4362682 },
    { lat: 49.1939797, lng: 21.4373924 },
    { lat: 49.1943921, lng: 21.4378789 },
    { lat: 49.1940979, lng: 21.4386783 },
    { lat: 49.1955905, lng: 21.4402848 },
    { lat: 49.1966647, lng: 21.438307 },
    { lat: 49.1972222, lng: 21.4389453 },
    { lat: 49.1983186, lng: 21.4378365 },
    { lat: 49.1987686, lng: 21.4376481 },
    { lat: 49.1999736, lng: 21.4383085 },
  ],
  Mikulášová: [
    { lat: 49.3939338, lng: 21.4079999 },
    { lat: 49.394899, lng: 21.407655 },
    { lat: 49.3950616, lng: 21.4070537 },
    { lat: 49.395357, lng: 21.4049237 },
    { lat: 49.3955278, lng: 21.4027554 },
    { lat: 49.3963702, lng: 21.3999757 },
    { lat: 49.3969692, lng: 21.3983789 },
    { lat: 49.3975008, lng: 21.3967816 },
    { lat: 49.3975493, lng: 21.3957882 },
    { lat: 49.3974512, lng: 21.3950061 },
    { lat: 49.3974089, lng: 21.3937563 },
    { lat: 49.3976846, lng: 21.3935254 },
    { lat: 49.3979419, lng: 21.3930533 },
    { lat: 49.3981749, lng: 21.3928306 },
    { lat: 49.3984513, lng: 21.3913209 },
    { lat: 49.3986163, lng: 21.3914262 },
    { lat: 49.3988285, lng: 21.3913722 },
    { lat: 49.3987839, lng: 21.3908855 },
    { lat: 49.3993994, lng: 21.3910064 },
    { lat: 49.4010502, lng: 21.3898272 },
    { lat: 49.401805, lng: 21.3899793 },
    { lat: 49.4027161, lng: 21.3896298 },
    { lat: 49.4029826, lng: 21.3892003 },
    { lat: 49.4033264, lng: 21.3885672 },
    { lat: 49.4040421, lng: 21.3869674 },
    { lat: 49.4043126, lng: 21.3854926 },
    { lat: 49.4042954, lng: 21.385091 },
    { lat: 49.4044759, lng: 21.3846154 },
    { lat: 49.4044768, lng: 21.3843422 },
    { lat: 49.4043512, lng: 21.383813 },
    { lat: 49.4043454, lng: 21.3830049 },
    { lat: 49.4041871, lng: 21.3826325 },
    { lat: 49.4041184, lng: 21.3821326 },
    { lat: 49.4038031, lng: 21.3821211 },
    { lat: 49.4037413, lng: 21.3820455 },
    { lat: 49.4036335, lng: 21.3811372 },
    { lat: 49.4035035, lng: 21.3807744 },
    { lat: 49.4034991, lng: 21.380623 },
    { lat: 49.4032393, lng: 21.380663 },
    { lat: 49.4019491, lng: 21.3817801 },
    { lat: 49.401834, lng: 21.3819611 },
    { lat: 49.4013722, lng: 21.3820179 },
    { lat: 49.4012318, lng: 21.381869 },
    { lat: 49.4010056, lng: 21.38217 },
    { lat: 49.400638, lng: 21.3822253 },
    { lat: 49.4004167, lng: 21.3823472 },
    { lat: 49.4002993, lng: 21.382563 },
    { lat: 49.4003018, lng: 21.3826491 },
    { lat: 49.399939, lng: 21.3829386 },
    { lat: 49.3998586, lng: 21.3829629 },
    { lat: 49.3994897, lng: 21.3827713 },
    { lat: 49.3992104, lng: 21.3829495 },
    { lat: 49.3991289, lng: 21.3830884 },
    { lat: 49.3988176, lng: 21.3831424 },
    { lat: 49.3986313, lng: 21.3830042 },
    { lat: 49.3982065, lng: 21.3831321 },
    { lat: 49.3980611, lng: 21.3829666 },
    { lat: 49.3979322, lng: 21.3831745 },
    { lat: 49.3978429, lng: 21.3830871 },
    { lat: 49.3975867, lng: 21.3831294 },
    { lat: 49.3972361, lng: 21.3836816 },
    { lat: 49.3969577, lng: 21.3837066 },
    { lat: 49.3966974, lng: 21.3838821 },
    { lat: 49.3965287, lng: 21.3841924 },
    { lat: 49.3962964, lng: 21.3843244 },
    { lat: 49.39585, lng: 21.3850591 },
    { lat: 49.3954268, lng: 21.3840224 },
    { lat: 49.3951488, lng: 21.3831711 },
    { lat: 49.3948974, lng: 21.3821547 },
    { lat: 49.3946108, lng: 21.3806348 },
    { lat: 49.3940495, lng: 21.3781189 },
    { lat: 49.3938269, lng: 21.3782343 },
    { lat: 49.39347, lng: 21.3791821 },
    { lat: 49.3931645, lng: 21.3792401 },
    { lat: 49.3930765, lng: 21.3791154 },
    { lat: 49.3927264, lng: 21.379374 },
    { lat: 49.3925356, lng: 21.3792011 },
    { lat: 49.3921221, lng: 21.3792886 },
    { lat: 49.3919416, lng: 21.3796622 },
    { lat: 49.3918581, lng: 21.3799583 },
    { lat: 49.3915965, lng: 21.3803596 },
    { lat: 49.3915928, lng: 21.3805375 },
    { lat: 49.3914152, lng: 21.3808091 },
    { lat: 49.3913256, lng: 21.3808648 },
    { lat: 49.3911214, lng: 21.3806871 },
    { lat: 49.3905573, lng: 21.380561 },
    { lat: 49.3902273, lng: 21.3807773 },
    { lat: 49.3898421, lng: 21.3808021 },
    { lat: 49.3897936, lng: 21.3810597 },
    { lat: 49.3892825, lng: 21.3815103 },
    { lat: 49.3893635, lng: 21.3811699 },
    { lat: 49.3891763, lng: 21.3811951 },
    { lat: 49.3889737, lng: 21.3810701 },
    { lat: 49.388556, lng: 21.3817536 },
    { lat: 49.3881695, lng: 21.3809638 },
    { lat: 49.3880069, lng: 21.381135 },
    { lat: 49.3878518, lng: 21.3809106 },
    { lat: 49.3869351, lng: 21.3809139 },
    { lat: 49.3863387, lng: 21.3797308 },
    { lat: 49.3855972, lng: 21.3776926 },
    { lat: 49.3855437, lng: 21.3771416 },
    { lat: 49.3854361, lng: 21.37685 },
    { lat: 49.3852398, lng: 21.3766043 },
    { lat: 49.3849697, lng: 21.3764449 },
    { lat: 49.3847829, lng: 21.3762101 },
    { lat: 49.3844191, lng: 21.3755134 },
    { lat: 49.3812315, lng: 21.3738152 },
    { lat: 49.380856, lng: 21.3736736 },
    { lat: 49.3806536, lng: 21.3734117 },
    { lat: 49.3802026, lng: 21.3737975 },
    { lat: 49.3795824, lng: 21.3740946 },
    { lat: 49.3773781, lng: 21.375493 },
    { lat: 49.3769125, lng: 21.3759177 },
    { lat: 49.3768179, lng: 21.3763329 },
    { lat: 49.3766862, lng: 21.3765269 },
    { lat: 49.3761219, lng: 21.3766893 },
    { lat: 49.3751532, lng: 21.3766909 },
    { lat: 49.3745068, lng: 21.3773222 },
    { lat: 49.3732244, lng: 21.3771335 },
    { lat: 49.3717117, lng: 21.3778723 },
    { lat: 49.3709595, lng: 21.3785001 },
    { lat: 49.3704313, lng: 21.378755 },
    { lat: 49.3703239, lng: 21.3789656 },
    { lat: 49.3701944, lng: 21.3790363 },
    { lat: 49.3699702, lng: 21.3795969 },
    { lat: 49.3698877, lng: 21.3796986 },
    { lat: 49.369895, lng: 21.379836 },
    { lat: 49.3699979, lng: 21.3798495 },
    { lat: 49.3699197, lng: 21.3801 },
    { lat: 49.3693733, lng: 21.3804132 },
    { lat: 49.3693955, lng: 21.3812871 },
    { lat: 49.3692924, lng: 21.3813404 },
    { lat: 49.369285, lng: 21.381469 },
    { lat: 49.369529, lng: 21.381736 },
    { lat: 49.369905, lng: 21.381899 },
    { lat: 49.370097, lng: 21.382749 },
    { lat: 49.369804, lng: 21.383521 },
    { lat: 49.369544, lng: 21.384419 },
    { lat: 49.36963, lng: 21.385114 },
    { lat: 49.369545, lng: 21.385356 },
    { lat: 49.369302, lng: 21.385527 },
    { lat: 49.369236, lng: 21.385845 },
    { lat: 49.369352, lng: 21.386453 },
    { lat: 49.36935, lng: 21.387234 },
    { lat: 49.36954, lng: 21.387825 },
    { lat: 49.369011, lng: 21.388385 },
    { lat: 49.369108, lng: 21.388729 },
    { lat: 49.36905, lng: 21.389235 },
    { lat: 49.368912, lng: 21.389732 },
    { lat: 49.369075, lng: 21.390066 },
    { lat: 49.36936, lng: 21.390716 },
    { lat: 49.369172, lng: 21.391583 },
    { lat: 49.368785, lng: 21.391687 },
    { lat: 49.369006, lng: 21.392098 },
    { lat: 49.36904, lng: 21.392635 },
    { lat: 49.368955, lng: 21.392693 },
    { lat: 49.36879, lng: 21.392671 },
    { lat: 49.368596, lng: 21.392554 },
    { lat: 49.368408, lng: 21.392579 },
    { lat: 49.368253, lng: 21.392706 },
    { lat: 49.368169, lng: 21.392974 },
    { lat: 49.368249, lng: 21.39335 },
    { lat: 49.368212, lng: 21.393751 },
    { lat: 49.368031, lng: 21.393823 },
    { lat: 49.368033, lng: 21.393902 },
    { lat: 49.368236, lng: 21.394012 },
    { lat: 49.368272, lng: 21.394201 },
    { lat: 49.368273, lng: 21.394291 },
    { lat: 49.368874, lng: 21.394614 },
    { lat: 49.368698, lng: 21.395618 },
    { lat: 49.368878, lng: 21.395985 },
    { lat: 49.369032, lng: 21.396568 },
    { lat: 49.36937, lng: 21.397209 },
    { lat: 49.369724, lng: 21.397577 },
    { lat: 49.370005, lng: 21.398117 },
    { lat: 49.370004, lng: 21.398357 },
    { lat: 49.3711, lng: 21.398162 },
    { lat: 49.371676, lng: 21.398395 },
    { lat: 49.371773, lng: 21.398535 },
    { lat: 49.371832, lng: 21.39879 },
    { lat: 49.371807, lng: 21.398935 },
    { lat: 49.371939, lng: 21.399587 },
    { lat: 49.371948, lng: 21.400188 },
    { lat: 49.372176, lng: 21.402325 },
    { lat: 49.37214, lng: 21.402421 },
    { lat: 49.372193, lng: 21.402913 },
    { lat: 49.372313, lng: 21.403142 },
    { lat: 49.372348, lng: 21.403311 },
    { lat: 49.372252, lng: 21.403521 },
    { lat: 49.372153, lng: 21.403603 },
    { lat: 49.372132, lng: 21.403725 },
    { lat: 49.372087, lng: 21.403786 },
    { lat: 49.372089, lng: 21.403849 },
    { lat: 49.372131, lng: 21.403984 },
    { lat: 49.372086, lng: 21.404203 },
    { lat: 49.372219, lng: 21.404235 },
    { lat: 49.372251, lng: 21.404594 },
    { lat: 49.372325, lng: 21.404671 },
    { lat: 49.372353, lng: 21.404912 },
    { lat: 49.372398, lng: 21.405934 },
    { lat: 49.372328, lng: 21.406193 },
    { lat: 49.372191, lng: 21.406323 },
    { lat: 49.372165, lng: 21.406474 },
    { lat: 49.372313, lng: 21.407514 },
    { lat: 49.372123, lng: 21.408531 },
    { lat: 49.372019, lng: 21.409483 },
    { lat: 49.372018, lng: 21.410462 },
    { lat: 49.371802, lng: 21.411246 },
    { lat: 49.371805, lng: 21.411754 },
    { lat: 49.372401, lng: 21.412283 },
    { lat: 49.372907, lng: 21.413203 },
    { lat: 49.373008, lng: 21.413327 },
    { lat: 49.374025, lng: 21.413912 },
    { lat: 49.374504, lng: 21.41442 },
    { lat: 49.375077, lng: 21.414501 },
    { lat: 49.375779, lng: 21.415044 },
    { lat: 49.37632, lng: 21.41534 },
    { lat: 49.377158, lng: 21.415388 },
    { lat: 49.377952, lng: 21.415835 },
    { lat: 49.378483, lng: 21.415892 },
    { lat: 49.378513, lng: 21.415932 },
    { lat: 49.379048, lng: 21.416673 },
    { lat: 49.379864, lng: 21.416895 },
    { lat: 49.380376, lng: 21.417016 },
    { lat: 49.380418, lng: 21.417025 },
    { lat: 49.380607, lng: 21.41707 },
    { lat: 49.381272, lng: 21.417421 },
    { lat: 49.381521, lng: 21.418279 },
    { lat: 49.381457, lng: 21.420055 },
    { lat: 49.382974, lng: 21.417749 },
    { lat: 49.384228, lng: 21.416181 },
    { lat: 49.38494, lng: 21.415344 },
    { lat: 49.386734, lng: 21.4141809 },
    { lat: 49.3902822, lng: 21.4115533 },
    { lat: 49.3913724, lng: 21.4113628 },
    { lat: 49.3916561, lng: 21.4108331 },
    { lat: 49.3925272, lng: 21.4104618 },
    { lat: 49.3927637, lng: 21.4098472 },
    { lat: 49.3939338, lng: 21.4079999 },
  ],
  Brezov: [
    { lat: 49.153522, lng: 21.517486 },
    { lat: 49.153593, lng: 21.517283 },
    { lat: 49.153506, lng: 21.517092 },
    { lat: 49.153442, lng: 21.516194 },
    { lat: 49.153462, lng: 21.51573 },
    { lat: 49.153387, lng: 21.51479 },
    { lat: 49.153294, lng: 21.514585 },
    { lat: 49.15398, lng: 21.512978 },
    { lat: 49.153836, lng: 21.512255 },
    { lat: 49.153832, lng: 21.512178 },
    { lat: 49.153868, lng: 21.511539 },
    { lat: 49.154065, lng: 21.51112 },
    { lat: 49.154851, lng: 21.510705 },
    { lat: 49.15561, lng: 21.510656 },
    { lat: 49.155648, lng: 21.510621 },
    { lat: 49.155997, lng: 21.510299 },
    { lat: 49.156442, lng: 21.509589 },
    { lat: 49.156734, lng: 21.50898 },
    { lat: 49.157126, lng: 21.508449 },
    { lat: 49.15731, lng: 21.508004 },
    { lat: 49.157557, lng: 21.50765 },
    { lat: 49.157838, lng: 21.50739 },
    { lat: 49.158067, lng: 21.506777 },
    { lat: 49.158497, lng: 21.506152 },
    { lat: 49.158528, lng: 21.505688 },
    { lat: 49.158714, lng: 21.505389 },
    { lat: 49.158783, lng: 21.504927 },
    { lat: 49.158943, lng: 21.504587 },
    { lat: 49.158979, lng: 21.504574 },
    { lat: 49.158869, lng: 21.503881 },
    { lat: 49.159039, lng: 21.503466 },
    { lat: 49.159016, lng: 21.502966 },
    { lat: 49.15889, lng: 21.502689 },
    { lat: 49.158847, lng: 21.502462 },
    { lat: 49.158889, lng: 21.502329 },
    { lat: 49.159029, lng: 21.502056 },
    { lat: 49.159164, lng: 21.501968 },
    { lat: 49.159299, lng: 21.501475 },
    { lat: 49.159169, lng: 21.501189 },
    { lat: 49.159109, lng: 21.50111 },
    { lat: 49.158926, lng: 21.501037 },
    { lat: 49.158882, lng: 21.500841 },
    { lat: 49.158607, lng: 21.500464 },
    { lat: 49.158378, lng: 21.499912 },
    { lat: 49.1583, lng: 21.499475 },
    { lat: 49.158284, lng: 21.499391 },
    { lat: 49.158354, lng: 21.499317 },
    { lat: 49.1581214, lng: 21.4991758 },
    { lat: 49.1579007, lng: 21.4991686 },
    { lat: 49.1571926, lng: 21.4987572 },
    { lat: 49.1571636, lng: 21.4985697 },
    { lat: 49.1574886, lng: 21.4979691 },
    { lat: 49.1566246, lng: 21.4977011 },
    { lat: 49.1567904, lng: 21.4972383 },
    { lat: 49.1569689, lng: 21.4969514 },
    { lat: 49.1570296, lng: 21.4966753 },
    { lat: 49.1557248, lng: 21.4957781 },
    { lat: 49.1552009, lng: 21.4955149 },
    { lat: 49.1552607, lng: 21.4952888 },
    { lat: 49.1545531, lng: 21.4950109 },
    { lat: 49.1542891, lng: 21.4947908 },
    { lat: 49.1541682, lng: 21.4945764 },
    { lat: 49.1538273, lng: 21.4941803 },
    { lat: 49.1524423, lng: 21.4917775 },
    { lat: 49.1504312, lng: 21.4869626 },
    { lat: 49.147628, lng: 21.483512 },
    { lat: 49.147236, lng: 21.48397 },
    { lat: 49.146632, lng: 21.48427 },
    { lat: 49.146314, lng: 21.484319 },
    { lat: 49.146226, lng: 21.484373 },
    { lat: 49.146217, lng: 21.484388 },
    { lat: 49.144825, lng: 21.482765 },
    { lat: 49.144582, lng: 21.482459 },
    { lat: 49.144541, lng: 21.482421 },
    { lat: 49.144461, lng: 21.482665 },
    { lat: 49.144054, lng: 21.483899 },
    { lat: 49.143639, lng: 21.484754 },
    { lat: 49.143283, lng: 21.485395 },
    { lat: 49.142814, lng: 21.486075 },
    { lat: 49.14233, lng: 21.48646 },
    { lat: 49.141219, lng: 21.48628 },
    { lat: 49.140755, lng: 21.485835 },
    { lat: 49.140363, lng: 21.486693 },
    { lat: 49.139355, lng: 21.488656 },
    { lat: 49.139505, lng: 21.48885 },
    { lat: 49.138974, lng: 21.490089 },
    { lat: 49.138489, lng: 21.489597 },
    { lat: 49.138203, lng: 21.489606 },
    { lat: 49.138166, lng: 21.489597 },
    { lat: 49.136252, lng: 21.489894 },
    { lat: 49.135946, lng: 21.489835 },
    { lat: 49.135882, lng: 21.491218 },
    { lat: 49.1357332, lng: 21.4916628 },
    { lat: 49.135629, lng: 21.4919641 },
    { lat: 49.134292, lng: 21.492019 },
    { lat: 49.131622, lng: 21.491954 },
    { lat: 49.131574, lng: 21.491966 },
    { lat: 49.129362, lng: 21.491868 },
    { lat: 49.128485, lng: 21.491884 },
    { lat: 49.128394, lng: 21.492612 },
    { lat: 49.12843, lng: 21.493606 },
    { lat: 49.12852, lng: 21.493977 },
    { lat: 49.1285991, lng: 21.4948038 },
    { lat: 49.1288785, lng: 21.4951481 },
    { lat: 49.1293093, lng: 21.4954832 },
    { lat: 49.1294831, lng: 21.4956066 },
    { lat: 49.1296904, lng: 21.4955934 },
    { lat: 49.130092, lng: 21.495396 },
    { lat: 49.130114, lng: 21.495471 },
    { lat: 49.130902, lng: 21.498004 },
    { lat: 49.13224, lng: 21.501836 },
    { lat: 49.132274, lng: 21.502022 },
    { lat: 49.13257, lng: 21.503634 },
    { lat: 49.132596, lng: 21.503765 },
    { lat: 49.133553, lng: 21.507295 },
    { lat: 49.133766, lng: 21.508085 },
    { lat: 49.134314, lng: 21.510063 },
    { lat: 49.134503, lng: 21.512766 },
    { lat: 49.134509, lng: 21.512855 },
    { lat: 49.13403, lng: 21.512699 },
    { lat: 49.134641, lng: 21.514111 },
    { lat: 49.135167, lng: 21.515626 },
    { lat: 49.135508, lng: 21.516296 },
    { lat: 49.135541, lng: 21.516422 },
    { lat: 49.135183, lng: 21.516848 },
    { lat: 49.134724, lng: 21.516572 },
    { lat: 49.134576, lng: 21.516609 },
    { lat: 49.134211, lng: 21.517209 },
    { lat: 49.133989, lng: 21.517131 },
    { lat: 49.133553, lng: 21.517217 },
    { lat: 49.133341, lng: 21.517766 },
    { lat: 49.132935, lng: 21.518323 },
    { lat: 49.132604, lng: 21.518335 },
    { lat: 49.132478, lng: 21.518896 },
    { lat: 49.132378, lng: 21.519014 },
    { lat: 49.132087, lng: 21.518839 },
    { lat: 49.131534, lng: 21.519207 },
    { lat: 49.131206, lng: 21.519702 },
    { lat: 49.130619, lng: 21.520098 },
    { lat: 49.129259, lng: 21.520545 },
    { lat: 49.128875, lng: 21.52078 },
    { lat: 49.128169, lng: 21.521209 },
    { lat: 49.128, lng: 21.522431 },
    { lat: 49.128008, lng: 21.522478 },
    { lat: 49.127811, lng: 21.522682 },
    { lat: 49.127587, lng: 21.523014 },
    { lat: 49.12757, lng: 21.52294 },
    { lat: 49.127247, lng: 21.523338 },
    { lat: 49.126894, lng: 21.523807 },
    { lat: 49.126713, lng: 21.524049 },
    { lat: 49.126644, lng: 21.524141 },
    { lat: 49.126584, lng: 21.524221 },
    { lat: 49.126231, lng: 21.526019 },
    { lat: 49.126289, lng: 21.525917 },
    { lat: 49.126368, lng: 21.526575 },
    { lat: 49.126814, lng: 21.526987 },
    { lat: 49.127006, lng: 21.527017 },
    { lat: 49.127331, lng: 21.526839 },
    { lat: 49.127379, lng: 21.526655 },
    { lat: 49.12735, lng: 21.526506 },
    { lat: 49.127351, lng: 21.526253 },
    { lat: 49.12742, lng: 21.526019 },
    { lat: 49.127439, lng: 21.525956 },
    { lat: 49.127446, lng: 21.52593 },
    { lat: 49.12805, lng: 21.525734 },
    { lat: 49.128219, lng: 21.525938 },
    { lat: 49.12824, lng: 21.525962 },
    { lat: 49.128239, lng: 21.525975 },
    { lat: 49.128235, lng: 21.526041 },
    { lat: 49.128205, lng: 21.526635 },
    { lat: 49.128196, lng: 21.526876 },
    { lat: 49.128448, lng: 21.526839 },
    { lat: 49.12886, lng: 21.526497 },
    { lat: 49.129573, lng: 21.52665 },
    { lat: 49.129689, lng: 21.527099 },
    { lat: 49.129824, lng: 21.527505 },
    { lat: 49.129937, lng: 21.527616 },
    { lat: 49.130111, lng: 21.527552 },
    { lat: 49.130197, lng: 21.527286 },
    { lat: 49.130329, lng: 21.527179 },
    { lat: 49.130611, lng: 21.527237 },
    { lat: 49.13066, lng: 21.527402 },
    { lat: 49.130478, lng: 21.527797 },
    { lat: 49.130478, lng: 21.527963 },
    { lat: 49.13061, lng: 21.528176 },
    { lat: 49.13085, lng: 21.528344 },
    { lat: 49.131026, lng: 21.528346 },
    { lat: 49.131269, lng: 21.528129 },
    { lat: 49.131356, lng: 21.528172 },
    { lat: 49.131396, lng: 21.528357 },
    { lat: 49.131322, lng: 21.528643 },
    { lat: 49.131325, lng: 21.528866 },
    { lat: 49.131638, lng: 21.529246 },
    { lat: 49.13178, lng: 21.529094 },
    { lat: 49.131897, lng: 21.529152 },
    { lat: 49.132064, lng: 21.529508 },
    { lat: 49.13208, lng: 21.529768 },
    { lat: 49.132009, lng: 21.530142 },
    { lat: 49.132166, lng: 21.530383 },
    { lat: 49.132239, lng: 21.530711 },
    { lat: 49.13235, lng: 21.530875 },
    { lat: 49.132767, lng: 21.53065 },
    { lat: 49.132859, lng: 21.530693 },
    { lat: 49.133013, lng: 21.531021 },
    { lat: 49.133033, lng: 21.531435 },
    { lat: 49.132925, lng: 21.531702 },
    { lat: 49.132858, lng: 21.532086 },
    { lat: 49.133485, lng: 21.534193 },
    { lat: 49.133486, lng: 21.534698 },
    { lat: 49.1336, lng: 21.535414 },
    { lat: 49.133723, lng: 21.535775 },
    { lat: 49.133696, lng: 21.53607 },
    { lat: 49.133749, lng: 21.536384 },
    { lat: 49.133859, lng: 21.536351 },
    { lat: 49.133903, lng: 21.536315 },
    { lat: 49.134113, lng: 21.536307 },
    { lat: 49.134635, lng: 21.536481 },
    { lat: 49.134975, lng: 21.536404 },
    { lat: 49.135177, lng: 21.536451 },
    { lat: 49.135406, lng: 21.536623 },
    { lat: 49.135827, lng: 21.536714 },
    { lat: 49.136021, lng: 21.536793 },
    { lat: 49.136429, lng: 21.536742 },
    { lat: 49.136465, lng: 21.536629 },
    { lat: 49.136871, lng: 21.53629 },
    { lat: 49.136856, lng: 21.536229 },
    { lat: 49.137045, lng: 21.535195 },
    { lat: 49.136908, lng: 21.535124 },
    { lat: 49.136346, lng: 21.534367 },
    { lat: 49.136311, lng: 21.534135 },
    { lat: 49.136089, lng: 21.53347 },
    { lat: 49.135822, lng: 21.53322 },
    { lat: 49.135137, lng: 21.532146 },
    { lat: 49.135267, lng: 21.531954 },
    { lat: 49.135787, lng: 21.532097 },
    { lat: 49.136032, lng: 21.531966 },
    { lat: 49.136229, lng: 21.532258 },
    { lat: 49.136358, lng: 21.532229 },
    { lat: 49.136796, lng: 21.531783 },
    { lat: 49.136931, lng: 21.531922 },
    { lat: 49.137429, lng: 21.531146 },
    { lat: 49.137516, lng: 21.530371 },
    { lat: 49.137614, lng: 21.528878 },
    { lat: 49.137073, lng: 21.528483 },
    { lat: 49.136666, lng: 21.528289 },
    { lat: 49.136309, lng: 21.527952 },
    { lat: 49.135755, lng: 21.527429 },
    { lat: 49.135003, lng: 21.526844 },
    { lat: 49.134714, lng: 21.526619 },
    { lat: 49.13446, lng: 21.525934 },
    { lat: 49.134416, lng: 21.525567 },
    { lat: 49.134323, lng: 21.525258 },
    { lat: 49.133305, lng: 21.525273 },
    { lat: 49.133206, lng: 21.525444 },
    { lat: 49.132908, lng: 21.525963 },
    { lat: 49.131944, lng: 21.525419 },
    { lat: 49.131538, lng: 21.524973 },
    { lat: 49.131442, lng: 21.525034 },
    { lat: 49.131595, lng: 21.525563 },
    { lat: 49.131441, lng: 21.525781 },
    { lat: 49.131009, lng: 21.525166 },
    { lat: 49.131645, lng: 21.524824 },
    { lat: 49.131646, lng: 21.524777 },
    { lat: 49.131888, lng: 21.524187 },
    { lat: 49.13185, lng: 21.523857 },
    { lat: 49.132257, lng: 21.523655 },
    { lat: 49.132918, lng: 21.523709 },
    { lat: 49.133608, lng: 21.523977 },
    { lat: 49.133679, lng: 21.523647 },
    { lat: 49.133686, lng: 21.52361 },
    { lat: 49.133696, lng: 21.523538 },
    { lat: 49.133813, lng: 21.523211 },
    { lat: 49.132799, lng: 21.52305 },
    { lat: 49.132571, lng: 21.523086 },
    { lat: 49.131889, lng: 21.523432 },
    { lat: 49.131381, lng: 21.52378 },
    { lat: 49.131074, lng: 21.524145 },
    { lat: 49.130968, lng: 21.524053 },
    { lat: 49.130633, lng: 21.52415 },
    { lat: 49.130529, lng: 21.524133 },
    { lat: 49.12984, lng: 21.524291 },
    { lat: 49.128778, lng: 21.524294 },
    { lat: 49.12864, lng: 21.524313 },
    { lat: 49.128087, lng: 21.524391 },
    { lat: 49.127581, lng: 21.524356 },
    { lat: 49.127313, lng: 21.524191 },
    { lat: 49.127211, lng: 21.524073 },
    { lat: 49.127425, lng: 21.524075 },
    { lat: 49.127641, lng: 21.524021 },
    { lat: 49.128225, lng: 21.523642 },
    { lat: 49.128652, lng: 21.523364 },
    { lat: 49.128921, lng: 21.522969 },
    { lat: 49.12932, lng: 21.522672 },
    { lat: 49.130261, lng: 21.522182 },
    { lat: 49.131722, lng: 21.521525 },
    { lat: 49.132536, lng: 21.521224 },
    { lat: 49.132981, lng: 21.520988 },
    { lat: 49.133525, lng: 21.520923 },
    { lat: 49.133914, lng: 21.520797 },
    { lat: 49.134158, lng: 21.520772 },
    { lat: 49.134397, lng: 21.520642 },
    { lat: 49.134857, lng: 21.520579 },
    { lat: 49.1353, lng: 21.520366 },
    { lat: 49.135716, lng: 21.520344 },
    { lat: 49.136043, lng: 21.520474 },
    { lat: 49.136174, lng: 21.520461 },
    { lat: 49.136454, lng: 21.520182 },
    { lat: 49.136692, lng: 21.520158 },
    { lat: 49.13704, lng: 21.520205 },
    { lat: 49.13716, lng: 21.520116 },
    { lat: 49.13759, lng: 21.519977 },
    { lat: 49.137775, lng: 21.520004 },
    { lat: 49.138018, lng: 21.519954 },
    { lat: 49.13939, lng: 21.51927 },
    { lat: 49.139396, lng: 21.519298 },
    { lat: 49.140852, lng: 21.517961 },
    { lat: 49.140919, lng: 21.518125 },
    { lat: 49.141738, lng: 21.518172 },
    { lat: 49.141898, lng: 21.519547 },
    { lat: 49.142086, lng: 21.519459 },
    { lat: 49.142515, lng: 21.519461 },
    { lat: 49.142573, lng: 21.519761 },
    { lat: 49.143033, lng: 21.52011 },
    { lat: 49.143438, lng: 21.52032 },
    { lat: 49.143666, lng: 21.520368 },
    { lat: 49.143921, lng: 21.520495 },
    { lat: 49.144117, lng: 21.520593 },
    { lat: 49.144335, lng: 21.520564 },
    { lat: 49.14453, lng: 21.520462 },
    { lat: 49.144219, lng: 21.519875 },
    { lat: 49.144207, lng: 21.51931 },
    { lat: 49.144272, lng: 21.519055 },
    { lat: 49.14486, lng: 21.51903 },
    { lat: 49.144903, lng: 21.518346 },
    { lat: 49.145076, lng: 21.518275 },
    { lat: 49.145271, lng: 21.518289 },
    { lat: 49.145465, lng: 21.518548 },
    { lat: 49.145634, lng: 21.518672 },
    { lat: 49.146395, lng: 21.518792 },
    { lat: 49.14675, lng: 21.519074 },
    { lat: 49.147204, lng: 21.519691 },
    { lat: 49.147566, lng: 21.519982 },
    { lat: 49.148254, lng: 21.520457 },
    { lat: 49.148075, lng: 21.520974 },
    { lat: 49.148064, lng: 21.521212 },
    { lat: 49.147885, lng: 21.521457 },
    { lat: 49.147357, lng: 21.52189 },
    { lat: 49.146921, lng: 21.522017 },
    { lat: 49.146873, lng: 21.522217 },
    { lat: 49.146601, lng: 21.522427 },
    { lat: 49.146775, lng: 21.522619 },
    { lat: 49.146678, lng: 21.522872 },
    { lat: 49.146474, lng: 21.523042 },
    { lat: 49.146288, lng: 21.522984 },
    { lat: 49.146056, lng: 21.522781 },
    { lat: 49.145573, lng: 21.52367 },
    { lat: 49.14552, lng: 21.52388 },
    { lat: 49.145338, lng: 21.52401 },
    { lat: 49.145234, lng: 21.524248 },
    { lat: 49.14493, lng: 21.524435 },
    { lat: 49.144591, lng: 21.524229 },
    { lat: 49.14447, lng: 21.524374 },
    { lat: 49.144321, lng: 21.524311 },
    { lat: 49.144197, lng: 21.524439 },
    { lat: 49.143973, lng: 21.524669 },
    { lat: 49.143693, lng: 21.52468 },
    { lat: 49.143665, lng: 21.525357 },
    { lat: 49.143724, lng: 21.525635 },
    { lat: 49.143656, lng: 21.526427 },
    { lat: 49.143345, lng: 21.527066 },
    { lat: 49.143336, lng: 21.527784 },
    { lat: 49.143264, lng: 21.528284 },
    { lat: 49.143167, lng: 21.528954 },
    { lat: 49.143171, lng: 21.529018 },
    { lat: 49.143529, lng: 21.529253 },
    { lat: 49.143873, lng: 21.529284 },
    { lat: 49.14496, lng: 21.529163 },
    { lat: 49.14524, lng: 21.529006 },
    { lat: 49.145588, lng: 21.529071 },
    { lat: 49.146044, lng: 21.52918 },
    { lat: 49.146036, lng: 21.529051 },
    { lat: 49.146134, lng: 21.528825 },
    { lat: 49.146269, lng: 21.528746 },
    { lat: 49.146274, lng: 21.528517 },
    { lat: 49.146491, lng: 21.527562 },
    { lat: 49.146318, lng: 21.527344 },
    { lat: 49.14641, lng: 21.526485 },
    { lat: 49.146756, lng: 21.525817 },
    { lat: 49.147057, lng: 21.525871 },
    { lat: 49.147207, lng: 21.525327 },
    { lat: 49.148316, lng: 21.525339 },
    { lat: 49.148443, lng: 21.525341 },
    { lat: 49.149074, lng: 21.525059 },
    { lat: 49.149161, lng: 21.525202 },
    { lat: 49.150114, lng: 21.524941 },
    { lat: 49.150584, lng: 21.52518 },
    { lat: 49.151511, lng: 21.525488 },
    { lat: 49.151485, lng: 21.524386 },
    { lat: 49.151731, lng: 21.52364 },
    { lat: 49.151845, lng: 21.522734 },
    { lat: 49.152176, lng: 21.521777 },
    { lat: 49.152208, lng: 21.521197 },
    { lat: 49.152232, lng: 21.520756 },
    { lat: 49.152564, lng: 21.519852 },
    { lat: 49.152779, lng: 21.519691 },
    { lat: 49.152874, lng: 21.51931 },
    { lat: 49.153045, lng: 21.519043 },
    { lat: 49.153249, lng: 21.518596 },
    { lat: 49.153271, lng: 21.518019 },
    { lat: 49.153479, lng: 21.517486 },
    { lat: 49.153522, lng: 21.517486 },
  ],
  Hrabovec: [
    { lat: 49.2551773, lng: 21.3737497 },
    { lat: 49.2570907, lng: 21.3758322 },
    { lat: 49.2587207, lng: 21.3788338 },
    { lat: 49.2602168, lng: 21.3813013 },
    { lat: 49.2622026, lng: 21.383134 },
    { lat: 49.2630085, lng: 21.3839503 },
    { lat: 49.2628377, lng: 21.3850273 },
    { lat: 49.2644173, lng: 21.3870146 },
    { lat: 49.2647451, lng: 21.3873311 },
    { lat: 49.2646883, lng: 21.3928244 },
    { lat: 49.2646146, lng: 21.3941022 },
    { lat: 49.2642686, lng: 21.3974236 },
    { lat: 49.2653893, lng: 21.3982702 },
    { lat: 49.2658458, lng: 21.3984504 },
    { lat: 49.2658692, lng: 21.3984045 },
    { lat: 49.2660085, lng: 21.3984342 },
    { lat: 49.2663557, lng: 21.3981118 },
    { lat: 49.2675426, lng: 21.3997641 },
    { lat: 49.267542, lng: 21.4004349 },
    { lat: 49.2682046, lng: 21.4016592 },
    { lat: 49.2685133, lng: 21.4031685 },
    { lat: 49.2697899, lng: 21.4042722 },
    { lat: 49.2707391, lng: 21.4048775 },
    { lat: 49.2712952, lng: 21.4036901 },
    { lat: 49.2728427, lng: 21.4013727 },
    { lat: 49.2746767, lng: 21.3988428 },
    { lat: 49.2754181, lng: 21.3986457 },
    { lat: 49.2756935, lng: 21.3987605 },
    { lat: 49.276382, lng: 21.3987566 },
    { lat: 49.2775102, lng: 21.3997092 },
    { lat: 49.277359, lng: 21.400655 },
    { lat: 49.2778242, lng: 21.4008988 },
    { lat: 49.2774861, lng: 21.4021828 },
    { lat: 49.2783396, lng: 21.4032479 },
    { lat: 49.2785343, lng: 21.4033297 },
    { lat: 49.2789409, lng: 21.4033019 },
    { lat: 49.2799199, lng: 21.4040807 },
    { lat: 49.2800763, lng: 21.4037698 },
    { lat: 49.2804421, lng: 21.4035891 },
    { lat: 49.2806604, lng: 21.4036614 },
    { lat: 49.2812038, lng: 21.4045067 },
    { lat: 49.2817225, lng: 21.4032838 },
    { lat: 49.2836417, lng: 21.3992395 },
    { lat: 49.2841541, lng: 21.3978353 },
    { lat: 49.2845425, lng: 21.3969308 },
    { lat: 49.2848833, lng: 21.3963895 },
    { lat: 49.2852959, lng: 21.3949986 },
    { lat: 49.2864361, lng: 21.392714 },
    { lat: 49.2866859, lng: 21.3926399 },
    { lat: 49.2883376, lng: 21.3902717 },
    { lat: 49.2889518, lng: 21.3896558 },
    { lat: 49.2902256, lng: 21.3887 },
    { lat: 49.2909277, lng: 21.3882855 },
    { lat: 49.2914106, lng: 21.3878389 },
    { lat: 49.2915892, lng: 21.3873678 },
    { lat: 49.2916916, lng: 21.387098 },
    { lat: 49.2902467, lng: 21.3863964 },
    { lat: 49.2895549, lng: 21.386329 },
    { lat: 49.2893371, lng: 21.3860348 },
    { lat: 49.2892805, lng: 21.3858725 },
    { lat: 49.2895718, lng: 21.3855641 },
    { lat: 49.289762, lng: 21.3855633 },
    { lat: 49.2898516, lng: 21.385396 },
    { lat: 49.2901537, lng: 21.3853035 },
    { lat: 49.2901952, lng: 21.3851114 },
    { lat: 49.2903773, lng: 21.3849544 },
    { lat: 49.2904731, lng: 21.3847229 },
    { lat: 49.2903668, lng: 21.3846696 },
    { lat: 49.2905372, lng: 21.3842672 },
    { lat: 49.2906105, lng: 21.3841937 },
    { lat: 49.2906846, lng: 21.3842271 },
    { lat: 49.2911992, lng: 21.3817991 },
    { lat: 49.2912693, lng: 21.3811628 },
    { lat: 49.2912578, lng: 21.3806208 },
    { lat: 49.2913294, lng: 21.3801069 },
    { lat: 49.2912864, lng: 21.3787276 },
    { lat: 49.2913772, lng: 21.3783299 },
    { lat: 49.2909927, lng: 21.3781841 },
    { lat: 49.2912367, lng: 21.3777311 },
    { lat: 49.2909404, lng: 21.3775623 },
    { lat: 49.291039, lng: 21.3762143 },
    { lat: 49.290579, lng: 21.3762543 },
    { lat: 49.29043, lng: 21.3761259 },
    { lat: 49.2901953, lng: 21.3757203 },
    { lat: 49.2900401, lng: 21.3758723 },
    { lat: 49.2898284, lng: 21.3758725 },
    { lat: 49.289632, lng: 21.3757358 },
    { lat: 49.2891996, lng: 21.3757591 },
    { lat: 49.2889694, lng: 21.3759217 },
    { lat: 49.2888089, lng: 21.3759414 },
    { lat: 49.2881298, lng: 21.3755374 },
    { lat: 49.2879801, lng: 21.3752669 },
    { lat: 49.2878247, lng: 21.3751439 },
    { lat: 49.2877877, lng: 21.3749612 },
    { lat: 49.2874524, lng: 21.3742318 },
    { lat: 49.2876622, lng: 21.374178 },
    { lat: 49.2876701, lng: 21.3739897 },
    { lat: 49.2875235, lng: 21.3737439 },
    { lat: 49.2874862, lng: 21.3735879 },
    { lat: 49.2875343, lng: 21.3733911 },
    { lat: 49.2874781, lng: 21.3732364 },
    { lat: 49.2872916, lng: 21.3733621 },
    { lat: 49.2872681, lng: 21.3732865 },
    { lat: 49.2874165, lng: 21.3727876 },
    { lat: 49.2873438, lng: 21.3725764 },
    { lat: 49.2875426, lng: 21.372257 },
    { lat: 49.28755, lng: 21.372166 },
    { lat: 49.2872915, lng: 21.3719853 },
    { lat: 49.2872123, lng: 21.3720495 },
    { lat: 49.2871832, lng: 21.3718641 },
    { lat: 49.2868265, lng: 21.3711376 },
    { lat: 49.2866582, lng: 21.3705794 },
    { lat: 49.2864819, lng: 21.3702871 },
    { lat: 49.2864392, lng: 21.3700226 },
    { lat: 49.2863161, lng: 21.3698152 },
    { lat: 49.2861998, lng: 21.3699948 },
    { lat: 49.2860428, lng: 21.3698611 },
    { lat: 49.2859089, lng: 21.3693255 },
    { lat: 49.2851817, lng: 21.3695076 },
    { lat: 49.2849391, lng: 21.3692923 },
    { lat: 49.2848611, lng: 21.369013 },
    { lat: 49.2847758, lng: 21.3690145 },
    { lat: 49.2846349, lng: 21.3693526 },
    { lat: 49.2844683, lng: 21.3693872 },
    { lat: 49.2837957, lng: 21.3683676 },
    { lat: 49.2837255, lng: 21.3682734 },
    { lat: 49.2835301, lng: 21.3685539 },
    { lat: 49.2825686, lng: 21.3676185 },
    { lat: 49.2808981, lng: 21.3664446 },
    { lat: 49.2802599, lng: 21.3665857 },
    { lat: 49.2797999, lng: 21.3669357 },
    { lat: 49.2790341, lng: 21.3663371 },
    { lat: 49.2781134, lng: 21.3675452 },
    { lat: 49.2774266, lng: 21.3670752 },
    { lat: 49.2774145, lng: 21.366323 },
    { lat: 49.2773169, lng: 21.3660613 },
    { lat: 49.2773077, lng: 21.3661336 },
    { lat: 49.2772474, lng: 21.36611 },
    { lat: 49.2772554, lng: 21.3660385 },
    { lat: 49.2768867, lng: 21.3651015 },
    { lat: 49.275785, lng: 21.3653226 },
    { lat: 49.2751789, lng: 21.3651011 },
    { lat: 49.2744745, lng: 21.3654078 },
    { lat: 49.2740375, lng: 21.3648911 },
    { lat: 49.2733626, lng: 21.3650145 },
    { lat: 49.2729073, lng: 21.3657061 },
    { lat: 49.2722708, lng: 21.366248 },
    { lat: 49.2719086, lng: 21.3660634 },
    { lat: 49.2714602, lng: 21.3656267 },
    { lat: 49.2689944, lng: 21.3657969 },
    { lat: 49.2672358, lng: 21.3665907 },
    { lat: 49.2661979, lng: 21.366188 },
    { lat: 49.2656127, lng: 21.3636739 },
    { lat: 49.2653673, lng: 21.3630615 },
    { lat: 49.2648198, lng: 21.36243 },
    { lat: 49.2628076, lng: 21.3609695 },
    { lat: 49.2613968, lng: 21.3596493 },
    { lat: 49.2610316, lng: 21.359435 },
    { lat: 49.2601326, lng: 21.3596968 },
    { lat: 49.2597532, lng: 21.3610552 },
    { lat: 49.2594041, lng: 21.3625995 },
    { lat: 49.2585789, lng: 21.364905 },
    { lat: 49.2578334, lng: 21.3660577 },
    { lat: 49.2574803, lng: 21.3681572 },
    { lat: 49.2572515, lng: 21.3692425 },
    { lat: 49.2569189, lng: 21.3701303 },
    { lat: 49.2566718, lng: 21.3704888 },
    { lat: 49.2565254, lng: 21.3711652 },
    { lat: 49.2560897, lng: 21.3719146 },
    { lat: 49.2555273, lng: 21.3726054 },
    { lat: 49.2553893, lng: 21.3729221 },
    { lat: 49.255353, lng: 21.3732578 },
    { lat: 49.2551773, lng: 21.3737497 },
  ],
  Janovce: [
    { lat: 49.1804016, lng: 21.2963366 },
    { lat: 49.179703, lng: 21.2974529 },
    { lat: 49.1773664, lng: 21.298879 },
    { lat: 49.1781263, lng: 21.300422 },
    { lat: 49.1768451, lng: 21.3022172 },
    { lat: 49.1754661, lng: 21.3035012 },
    { lat: 49.1734578, lng: 21.3033546 },
    { lat: 49.170903, lng: 21.3054191 },
    { lat: 49.1697059, lng: 21.3061674 },
    { lat: 49.1689618, lng: 21.3056777 },
    { lat: 49.1682117, lng: 21.3066221 },
    { lat: 49.1666611, lng: 21.3077345 },
    { lat: 49.1647314, lng: 21.3087098 },
    { lat: 49.1625514, lng: 21.3100704 },
    { lat: 49.1618652, lng: 21.3081008 },
    { lat: 49.1616188, lng: 21.3085459 },
    { lat: 49.1614054, lng: 21.3091236 },
    { lat: 49.1609053, lng: 21.309663 },
    { lat: 49.1605029, lng: 21.3110941 },
    { lat: 49.1605027, lng: 21.3121284 },
    { lat: 49.1608967, lng: 21.3124334 },
    { lat: 49.1611191, lng: 21.313428 },
    { lat: 49.1610236, lng: 21.3138435 },
    { lat: 49.1606062, lng: 21.3141068 },
    { lat: 49.1606286, lng: 21.3167236 },
    { lat: 49.1606298, lng: 21.3168339 },
    { lat: 49.1613132, lng: 21.3162874 },
    { lat: 49.1615259, lng: 21.3164364 },
    { lat: 49.1621899, lng: 21.3156573 },
    { lat: 49.1627333, lng: 21.3152457 },
    { lat: 49.1629448, lng: 21.3152064 },
    { lat: 49.1635515, lng: 21.3146221 },
    { lat: 49.1647153, lng: 21.3139927 },
    { lat: 49.1650077, lng: 21.3136202 },
    { lat: 49.1651226, lng: 21.3133561 },
    { lat: 49.1655726, lng: 21.3128367 },
    { lat: 49.1661729, lng: 21.3125135 },
    { lat: 49.1670097, lng: 21.3125088 },
    { lat: 49.1673004, lng: 21.3118106 },
    { lat: 49.1675403, lng: 21.3116672 },
    { lat: 49.1677998, lng: 21.311804 },
    { lat: 49.1677985, lng: 21.3125027 },
    { lat: 49.1678668, lng: 21.313284 },
    { lat: 49.1681619, lng: 21.3132166 },
    { lat: 49.1683517, lng: 21.3138052 },
    { lat: 49.1688438, lng: 21.3166171 },
    { lat: 49.1672616, lng: 21.317584 },
    { lat: 49.1661826, lng: 21.3184055 },
    { lat: 49.1664196, lng: 21.3193321 },
    { lat: 49.167723, lng: 21.3197522 },
    { lat: 49.1696998, lng: 21.3202713 },
    { lat: 49.1710893, lng: 21.3204521 },
    { lat: 49.1710088, lng: 21.3207335 },
    { lat: 49.1719731, lng: 21.321782 },
    { lat: 49.1733651, lng: 21.3222043 },
    { lat: 49.1745607, lng: 21.3207671 },
    { lat: 49.174849, lng: 21.321221 },
    { lat: 49.176218, lng: 21.3208393 },
    { lat: 49.1770862, lng: 21.3198828 },
    { lat: 49.1776347, lng: 21.3189676 },
    { lat: 49.1778168, lng: 21.3182507 },
    { lat: 49.1781862, lng: 21.3178875 },
    { lat: 49.1784456, lng: 21.3177237 },
    { lat: 49.1792784, lng: 21.3176147 },
    { lat: 49.1798955, lng: 21.3189418 },
    { lat: 49.1803036, lng: 21.3190032 },
    { lat: 49.1803421, lng: 21.3185995 },
    { lat: 49.180422, lng: 21.318527 },
    { lat: 49.1802711, lng: 21.31822 },
    { lat: 49.1801657, lng: 21.3180867 },
    { lat: 49.1800961, lng: 21.3180919 },
    { lat: 49.1800626, lng: 21.3177238 },
    { lat: 49.1797597, lng: 21.3173444 },
    { lat: 49.1796328, lng: 21.3166038 },
    { lat: 49.1796072, lng: 21.3158341 },
    { lat: 49.1801985, lng: 21.3152902 },
    { lat: 49.1803046, lng: 21.314702 },
    { lat: 49.1801014, lng: 21.3139678 },
    { lat: 49.1796726, lng: 21.3130318 },
    { lat: 49.1801473, lng: 21.3122842 },
    { lat: 49.1803608, lng: 21.3118254 },
    { lat: 49.1802972, lng: 21.3116878 },
    { lat: 49.1802778, lng: 21.3111609 },
    { lat: 49.180107, lng: 21.3104938 },
    { lat: 49.1805319, lng: 21.3107042 },
    { lat: 49.180881, lng: 21.3112024 },
    { lat: 49.1807211, lng: 21.3112337 },
    { lat: 49.1805847, lng: 21.3115238 },
    { lat: 49.1806497, lng: 21.3118904 },
    { lat: 49.1808985, lng: 21.3122294 },
    { lat: 49.1817276, lng: 21.3123727 },
    { lat: 49.1821884, lng: 21.3119063 },
    { lat: 49.1822697, lng: 21.3123667 },
    { lat: 49.1824712, lng: 21.3122551 },
    { lat: 49.1827181, lng: 21.3125363 },
    { lat: 49.1830419, lng: 21.3125244 },
    { lat: 49.1831597, lng: 21.3129982 },
    { lat: 49.1833842, lng: 21.3130551 },
    { lat: 49.1840667, lng: 21.3128936 },
    { lat: 49.1842637, lng: 21.3125215 },
    { lat: 49.184475, lng: 21.3129614 },
    { lat: 49.1848985, lng: 21.3130892 },
    { lat: 49.1852008, lng: 21.3133321 },
    { lat: 49.1854865, lng: 21.3130728 },
    { lat: 49.1855424, lng: 21.3129092 },
    { lat: 49.1857643, lng: 21.312848 },
    { lat: 49.185883, lng: 21.3128976 },
    { lat: 49.1858673, lng: 21.3133163 },
    { lat: 49.1860524, lng: 21.3136684 },
    { lat: 49.1863997, lng: 21.3136915 },
    { lat: 49.1868714, lng: 21.3134761 },
    { lat: 49.186915, lng: 21.3130551 },
    { lat: 49.1873871, lng: 21.3128533 },
    { lat: 49.1875368, lng: 21.3125162 },
    { lat: 49.1897148, lng: 21.3120952 },
    { lat: 49.1901661, lng: 21.3122531 },
    { lat: 49.1908237, lng: 21.311853 },
    { lat: 49.1914754, lng: 21.3120505 },
    { lat: 49.1923821, lng: 21.3118571 },
    { lat: 49.1925198, lng: 21.3116481 },
    { lat: 49.1931521, lng: 21.3113073 },
    { lat: 49.1937913, lng: 21.3108218 },
    { lat: 49.1944182, lng: 21.3104623 },
    { lat: 49.1944667, lng: 21.3101635 },
    { lat: 49.1949407, lng: 21.3097266 },
    { lat: 49.19658, lng: 21.3094979 },
    { lat: 49.1988615, lng: 21.3103834 },
    { lat: 49.19927, lng: 21.3102734 },
    { lat: 49.1994974, lng: 21.310423 },
    { lat: 49.1997468, lng: 21.3109401 },
    { lat: 49.1999331, lng: 21.3111041 },
    { lat: 49.2002216, lng: 21.3113141 },
    { lat: 49.200928, lng: 21.3106716 },
    { lat: 49.2011675, lng: 21.3107016 },
    { lat: 49.2015329, lng: 21.3101287 },
    { lat: 49.2016257, lng: 21.3098124 },
    { lat: 49.2018396, lng: 21.3095149 },
    { lat: 49.2032276, lng: 21.3086759 },
    { lat: 49.2067365, lng: 21.3053388 },
    { lat: 49.2071698, lng: 21.3048218 },
    { lat: 49.2069814, lng: 21.3046192 },
    { lat: 49.204259, lng: 21.2975588 },
    { lat: 49.2048136, lng: 21.2969997 },
    { lat: 49.2046806, lng: 21.2958767 },
    { lat: 49.203869, lng: 21.2939677 },
    { lat: 49.2038723, lng: 21.292852 },
    { lat: 49.2040057, lng: 21.2920193 },
    { lat: 49.2030666, lng: 21.2909938 },
    { lat: 49.2008558, lng: 21.292484 },
    { lat: 49.1998373, lng: 21.2898415 },
    { lat: 49.1993652, lng: 21.288741 },
    { lat: 49.1987573, lng: 21.2887332 },
    { lat: 49.1981304, lng: 21.2890282 },
    { lat: 49.1965019, lng: 21.2903852 },
    { lat: 49.1951574, lng: 21.2913154 },
    { lat: 49.1929894, lng: 21.2930743 },
    { lat: 49.1935771, lng: 21.294723 },
    { lat: 49.1928555, lng: 21.2949674 },
    { lat: 49.1914521, lng: 21.294922 },
    { lat: 49.1896523, lng: 21.295869 },
    { lat: 49.1894624, lng: 21.295873 },
    { lat: 49.1895936, lng: 21.2962656 },
    { lat: 49.1885786, lng: 21.296672 },
    { lat: 49.1872921, lng: 21.2968125 },
    { lat: 49.1857632, lng: 21.2975256 },
    { lat: 49.1857649, lng: 21.2971388 },
    { lat: 49.1848797, lng: 21.297595 },
    { lat: 49.1846684, lng: 21.2975246 },
    { lat: 49.1834174, lng: 21.2966048 },
    { lat: 49.1819393, lng: 21.2965762 },
    { lat: 49.1804016, lng: 21.2963366 },
  ],
  Tarnov: [
    { lat: 49.3378131, lng: 21.1836575 },
    { lat: 49.3375034, lng: 21.1835397 },
    { lat: 49.3370601, lng: 21.1829633 },
    { lat: 49.3360522, lng: 21.1819234 },
    { lat: 49.3354105, lng: 21.1810469 },
    { lat: 49.3353176, lng: 21.1808505 },
    { lat: 49.3352429, lng: 21.1808372 },
    { lat: 49.3347865, lng: 21.1802726 },
    { lat: 49.3341251, lng: 21.1791581 },
    { lat: 49.3328823, lng: 21.1768686 },
    { lat: 49.3323005, lng: 21.1745206 },
    { lat: 49.3310795, lng: 21.1716623 },
    { lat: 49.3310851, lng: 21.1715569 },
    { lat: 49.3306639, lng: 21.1704278 },
    { lat: 49.3302062, lng: 21.1687436 },
    { lat: 49.3300919, lng: 21.1680388 },
    { lat: 49.3295989, lng: 21.1660924 },
    { lat: 49.329158, lng: 21.1645872 },
    { lat: 49.329142, lng: 21.1639321 },
    { lat: 49.3289506, lng: 21.1631745 },
    { lat: 49.3284168, lng: 21.1632634 },
    { lat: 49.3284027, lng: 21.1631757 },
    { lat: 49.3283469, lng: 21.163192 },
    { lat: 49.3282434, lng: 21.1628695 },
    { lat: 49.3281682, lng: 21.1629075 },
    { lat: 49.3280745, lng: 21.1631543 },
    { lat: 49.3278421, lng: 21.1631724 },
    { lat: 49.3276631, lng: 21.1633446 },
    { lat: 49.3276009, lng: 21.1633001 },
    { lat: 49.3273965, lng: 21.1635283 },
    { lat: 49.327051, lng: 21.1637058 },
    { lat: 49.3268528, lng: 21.1635198 },
    { lat: 49.3268711, lng: 21.1632385 },
    { lat: 49.3268277, lng: 21.1631858 },
    { lat: 49.3267713, lng: 21.1632151 },
    { lat: 49.3267192, lng: 21.1631017 },
    { lat: 49.3261809, lng: 21.1629438 },
    { lat: 49.3260484, lng: 21.1630199 },
    { lat: 49.3259331, lng: 21.1628974 },
    { lat: 49.3253566, lng: 21.1631027 },
    { lat: 49.3250944, lng: 21.1589695 },
    { lat: 49.3248193, lng: 21.1564743 },
    { lat: 49.3246551, lng: 21.153392 },
    { lat: 49.324648, lng: 21.150621 },
    { lat: 49.3246975, lng: 21.1500875 },
    { lat: 49.3249554, lng: 21.1488773 },
    { lat: 49.3252549, lng: 21.1481295 },
    { lat: 49.3252355, lng: 21.1479776 },
    { lat: 49.3251779, lng: 21.1470757 },
    { lat: 49.3253584, lng: 21.1449798 },
    { lat: 49.3252278, lng: 21.143084 },
    { lat: 49.3252879, lng: 21.1421345 },
    { lat: 49.3252659, lng: 21.141757 },
    { lat: 49.3250132, lng: 21.1416309 },
    { lat: 49.324893, lng: 21.1414251 },
    { lat: 49.3248422, lng: 21.1414509 },
    { lat: 49.3245154, lng: 21.1407903 },
    { lat: 49.3243608, lng: 21.1401744 },
    { lat: 49.3244478, lng: 21.1398588 },
    { lat: 49.3243572, lng: 21.1395935 },
    { lat: 49.3241619, lng: 21.1394644 },
    { lat: 49.3239242, lng: 21.1391738 },
    { lat: 49.3236296, lng: 21.1386473 },
    { lat: 49.3234567, lng: 21.13812 },
    { lat: 49.3230867, lng: 21.1378793 },
    { lat: 49.3230853, lng: 21.1376565 },
    { lat: 49.3224262, lng: 21.1369137 },
    { lat: 49.3223273, lng: 21.1365985 },
    { lat: 49.3224012, lng: 21.1362262 },
    { lat: 49.3227284, lng: 21.1355076 },
    { lat: 49.322521, lng: 21.1352109 },
    { lat: 49.322412, lng: 21.1350354 },
    { lat: 49.3221929, lng: 21.1353344 },
    { lat: 49.3219698, lng: 21.1354666 },
    { lat: 49.3218869, lng: 21.1352617 },
    { lat: 49.321546, lng: 21.1355473 },
    { lat: 49.3204282, lng: 21.1388535 },
    { lat: 49.3203756, lng: 21.1389374 },
    { lat: 49.3194903, lng: 21.1405119 },
    { lat: 49.3190851, lng: 21.1410082 },
    { lat: 49.3186827, lng: 21.141633 },
    { lat: 49.3184561, lng: 21.1422989 },
    { lat: 49.3184046, lng: 21.1426259 },
    { lat: 49.3183455, lng: 21.1432878 },
    { lat: 49.3184613, lng: 21.1440374 },
    { lat: 49.3184589, lng: 21.1444549 },
    { lat: 49.3182873, lng: 21.1448315 },
    { lat: 49.3180059, lng: 21.1453309 },
    { lat: 49.3173959, lng: 21.1461844 },
    { lat: 49.316823, lng: 21.147111 },
    { lat: 49.3160813, lng: 21.1485386 },
    { lat: 49.3158337, lng: 21.1492187 },
    { lat: 49.3154568, lng: 21.1505452 },
    { lat: 49.3152339, lng: 21.150968 },
    { lat: 49.3141363, lng: 21.1525307 },
    { lat: 49.3125011, lng: 21.15374 },
    { lat: 49.3111973, lng: 21.1548625 },
    { lat: 49.3103095, lng: 21.1548635 },
    { lat: 49.3098866, lng: 21.1549796 },
    { lat: 49.3081312, lng: 21.1557991 },
    { lat: 49.3075699, lng: 21.1564515 },
    { lat: 49.3070244, lng: 21.1564901 },
    { lat: 49.3065454, lng: 21.1566472 },
    { lat: 49.3059952, lng: 21.1569746 },
    { lat: 49.3055726, lng: 21.1573478 },
    { lat: 49.3051776, lng: 21.157887 },
    { lat: 49.304794, lng: 21.1585347 },
    { lat: 49.3050014, lng: 21.1596203 },
    { lat: 49.3049833, lng: 21.1604824 },
    { lat: 49.3049022, lng: 21.1608359 },
    { lat: 49.3049081, lng: 21.1612419 },
    { lat: 49.3054853, lng: 21.1621004 },
    { lat: 49.3057458, lng: 21.1627289 },
    { lat: 49.3064504, lng: 21.1637025 },
    { lat: 49.3059492, lng: 21.1650243 },
    { lat: 49.305183, lng: 21.1672858 },
    { lat: 49.3048916, lng: 21.1677918 },
    { lat: 49.3050725, lng: 21.168932 },
    { lat: 49.3052286, lng: 21.1695744 },
    { lat: 49.305296, lng: 21.1695662 },
    { lat: 49.3053913, lng: 21.1722615 },
    { lat: 49.3054475, lng: 21.1726551 },
    { lat: 49.3054935, lng: 21.1730787 },
    { lat: 49.305505, lng: 21.1730933 },
    { lat: 49.3063988, lng: 21.1742159 },
    { lat: 49.3069962, lng: 21.1747939 },
    { lat: 49.3072328, lng: 21.1749465 },
    { lat: 49.3076739, lng: 21.1749831 },
    { lat: 49.3080256, lng: 21.1751075 },
    { lat: 49.308238, lng: 21.1753566 },
    { lat: 49.3085357, lng: 21.1754824 },
    { lat: 49.308905, lng: 21.175454 },
    { lat: 49.3092425, lng: 21.1755606 },
    { lat: 49.3096921, lng: 21.1755826 },
    { lat: 49.3109599, lng: 21.1762149 },
    { lat: 49.3112879, lng: 21.1762021 },
    { lat: 49.3116605, lng: 21.1766708 },
    { lat: 49.3118882, lng: 21.1766177 },
    { lat: 49.3123996, lng: 21.1767055 },
    { lat: 49.3126028, lng: 21.1769388 },
    { lat: 49.3130426, lng: 21.1770102 },
    { lat: 49.3158442, lng: 21.1768937 },
    { lat: 49.3166886, lng: 21.1775989 },
    { lat: 49.3176426, lng: 21.1782412 },
    { lat: 49.3212354, lng: 21.179644 },
    { lat: 49.3230047, lng: 21.1801354 },
    { lat: 49.3232092, lng: 21.1804455 },
    { lat: 49.3233516, lng: 21.1805065 },
    { lat: 49.3239581, lng: 21.1805001 },
    { lat: 49.3260159, lng: 21.1810729 },
    { lat: 49.3273875, lng: 21.1818658 },
    { lat: 49.3275215, lng: 21.1820129 },
    { lat: 49.3282011, lng: 21.1824486 },
    { lat: 49.3293151, lng: 21.1829208 },
    { lat: 49.3295977, lng: 21.183108 },
    { lat: 49.3299185, lng: 21.1834385 },
    { lat: 49.3308211, lng: 21.1839817 },
    { lat: 49.3309121, lng: 21.1839826 },
    { lat: 49.3314797, lng: 21.1843922 },
    { lat: 49.3316502, lng: 21.1844862 },
    { lat: 49.3317177, lng: 21.184412 },
    { lat: 49.3319943, lng: 21.1846928 },
    { lat: 49.3324141, lng: 21.1854913 },
    { lat: 49.332881, lng: 21.1856384 },
    { lat: 49.3330787, lng: 21.1852614 },
    { lat: 49.3334711, lng: 21.1854013 },
    { lat: 49.3340144, lng: 21.1854317 },
    { lat: 49.3346467, lng: 21.1853531 },
    { lat: 49.3348372, lng: 21.185226 },
    { lat: 49.3353059, lng: 21.1853156 },
    { lat: 49.3355741, lng: 21.1850752 },
    { lat: 49.3358604, lng: 21.1849093 },
    { lat: 49.3360346, lng: 21.1849002 },
    { lat: 49.3362191, lng: 21.1847529 },
    { lat: 49.3364079, lng: 21.1846757 },
    { lat: 49.3366744, lng: 21.1847461 },
    { lat: 49.337349, lng: 21.1841878 },
    { lat: 49.3378035, lng: 21.1836762 },
    { lat: 49.3378131, lng: 21.1836575 },
  ],
  ŠarišskéČierne: [
    { lat: 49.3570024, lng: 21.3539833 },
    { lat: 49.3564785, lng: 21.3538891 },
    { lat: 49.3562975, lng: 21.3540002 },
    { lat: 49.3551089, lng: 21.3538699 },
    { lat: 49.3534791, lng: 21.3534299 },
    { lat: 49.3479189, lng: 21.3531542 },
    { lat: 49.3464269, lng: 21.3530014 },
    { lat: 49.3454998, lng: 21.3530355 },
    { lat: 49.344666, lng: 21.3533002 },
    { lat: 49.3443655, lng: 21.3532955 },
    { lat: 49.3429309, lng: 21.3536298 },
    { lat: 49.3428469, lng: 21.3548999 },
    { lat: 49.3422508, lng: 21.3558181 },
    { lat: 49.3402405, lng: 21.35795 },
    { lat: 49.3393547, lng: 21.3591884 },
    { lat: 49.338583, lng: 21.3598704 },
    { lat: 49.3378201, lng: 21.360817 },
    { lat: 49.337474, lng: 21.361064 },
    { lat: 49.336289, lng: 21.3623053 },
    { lat: 49.3349975, lng: 21.3633321 },
    { lat: 49.3330164, lng: 21.3639524 },
    { lat: 49.3319233, lng: 21.3637009 },
    { lat: 49.3304218, lng: 21.3629125 },
    { lat: 49.3289427, lng: 21.3631535 },
    { lat: 49.3275803, lng: 21.3631231 },
    { lat: 49.3263704, lng: 21.3623009 },
    { lat: 49.3257386, lng: 21.3621264 },
    { lat: 49.3239658, lng: 21.3627124 },
    { lat: 49.3237239, lng: 21.3627908 },
    { lat: 49.3237497, lng: 21.3636943 },
    { lat: 49.3235774, lng: 21.3651403 },
    { lat: 49.3234953, lng: 21.36548 },
    { lat: 49.3232353, lng: 21.3663095 },
    { lat: 49.3225431, lng: 21.3669486 },
    { lat: 49.321984, lng: 21.368061 },
    { lat: 49.3217171, lng: 21.3689605 },
    { lat: 49.3215479, lng: 21.3702403 },
    { lat: 49.3215373, lng: 21.3703042 },
    { lat: 49.3232321, lng: 21.3708528 },
    { lat: 49.3240713, lng: 21.3712647 },
    { lat: 49.3245033, lng: 21.3721773 },
    { lat: 49.3248646, lng: 21.3731406 },
    { lat: 49.3247566, lng: 21.3742049 },
    { lat: 49.3252566, lng: 21.3755197 },
    { lat: 49.3255498, lng: 21.3766461 },
    { lat: 49.3258209, lng: 21.3789005 },
    { lat: 49.3259967, lng: 21.3819268 },
    { lat: 49.3255204, lng: 21.3835571 },
    { lat: 49.3258561, lng: 21.3851037 },
    { lat: 49.326081, lng: 21.3879676 },
    { lat: 49.3260211, lng: 21.3887999 },
    { lat: 49.3262166, lng: 21.3901765 },
    { lat: 49.3253803, lng: 21.3909258 },
    { lat: 49.3249788, lng: 21.3925003 },
    { lat: 49.3244558, lng: 21.3935022 },
    { lat: 49.3239015, lng: 21.3941285 },
    { lat: 49.323848, lng: 21.394503 },
    { lat: 49.324737, lng: 21.395453 },
    { lat: 49.325303, lng: 21.396503 },
    { lat: 49.325842, lng: 21.396777 },
    { lat: 49.326481, lng: 21.397077 },
    { lat: 49.327049, lng: 21.397318 },
    { lat: 49.327981, lng: 21.397981 },
    { lat: 49.328272, lng: 21.398228 },
    { lat: 49.328486, lng: 21.398278 },
    { lat: 49.328462, lng: 21.398384 },
    { lat: 49.328455, lng: 21.398415 },
    { lat: 49.32832, lng: 21.39918 },
    { lat: 49.328707, lng: 21.401628 },
    { lat: 49.328932, lng: 21.402681 },
    { lat: 49.329098, lng: 21.403459 },
    { lat: 49.329631, lng: 21.404462 },
    { lat: 49.329643, lng: 21.404562 },
    { lat: 49.329779, lng: 21.405633 },
    { lat: 49.329822, lng: 21.405939 },
    { lat: 49.330049, lng: 21.40614 },
    { lat: 49.33008, lng: 21.406193 },
    { lat: 49.330023, lng: 21.406248 },
    { lat: 49.329887, lng: 21.406288 },
    { lat: 49.329831, lng: 21.406369 },
    { lat: 49.329898, lng: 21.406508 },
    { lat: 49.32997, lng: 21.406593 },
    { lat: 49.329998, lng: 21.406663 },
    { lat: 49.329973, lng: 21.406775 },
    { lat: 49.330027, lng: 21.406846 },
    { lat: 49.329996, lng: 21.406974 },
    { lat: 49.330016, lng: 21.407132 },
    { lat: 49.329991, lng: 21.407222 },
    { lat: 49.330018, lng: 21.407376 },
    { lat: 49.330061, lng: 21.407484 },
    { lat: 49.330094, lng: 21.407681 },
    { lat: 49.330179, lng: 21.407799 },
    { lat: 49.33023, lng: 21.407932 },
    { lat: 49.330116, lng: 21.408235 },
    { lat: 49.329948, lng: 21.408414 },
    { lat: 49.329946, lng: 21.408439 },
    { lat: 49.32994, lng: 21.408528 },
    { lat: 49.329895, lng: 21.408475 },
    { lat: 49.329715, lng: 21.408396 },
    { lat: 49.32963, lng: 21.408709 },
    { lat: 49.329715, lng: 21.408874 },
    { lat: 49.329591, lng: 21.409149 },
    { lat: 49.329622, lng: 21.409366 },
    { lat: 49.329652, lng: 21.409411 },
    { lat: 49.329735, lng: 21.409427 },
    { lat: 49.329773, lng: 21.409424 },
    { lat: 49.329813, lng: 21.409516 },
    { lat: 49.329832, lng: 21.409989 },
    { lat: 49.329909, lng: 21.410153 },
    { lat: 49.329961, lng: 21.410317 },
    { lat: 49.329991, lng: 21.410397 },
    { lat: 49.330041, lng: 21.410468 },
    { lat: 49.330108, lng: 21.410462 },
    { lat: 49.330175, lng: 21.410678 },
    { lat: 49.330172, lng: 21.410872 },
    { lat: 49.331535, lng: 21.412362 },
    { lat: 49.331687, lng: 21.412529 },
    { lat: 49.332001, lng: 21.412993 },
    { lat: 49.33202, lng: 21.413018 },
    { lat: 49.332035, lng: 21.413038 },
    { lat: 49.332119, lng: 21.413148 },
    { lat: 49.332157, lng: 21.413209 },
    { lat: 49.332173, lng: 21.413237 },
    { lat: 49.332221, lng: 21.413373 },
    { lat: 49.332262, lng: 21.413531 },
    { lat: 49.33231, lng: 21.413636 },
    { lat: 49.332565, lng: 21.413868 },
    { lat: 49.332686, lng: 21.413647 },
    { lat: 49.333119, lng: 21.413798 },
    { lat: 49.3336, lng: 21.413703 },
    { lat: 49.333851, lng: 21.413894 },
    { lat: 49.336356, lng: 21.416277 },
    { lat: 49.337304, lng: 21.416852 },
    { lat: 49.337725, lng: 21.417106 },
    { lat: 49.337687, lng: 21.417184 },
    { lat: 49.337371, lng: 21.418367 },
    { lat: 49.337288, lng: 21.419393 },
    { lat: 49.337932, lng: 21.420022 },
    { lat: 49.338216, lng: 21.420294 },
    { lat: 49.338719, lng: 21.420866 },
    { lat: 49.339039, lng: 21.422174 },
    { lat: 49.340049, lng: 21.422511 },
    { lat: 49.341276, lng: 21.423531 },
    { lat: 49.341366, lng: 21.423606 },
    { lat: 49.341527, lng: 21.42374 },
    { lat: 49.34275, lng: 21.423426 },
    { lat: 49.342922, lng: 21.423494 },
    { lat: 49.343391, lng: 21.423679 },
    { lat: 49.343415, lng: 21.423689 },
    { lat: 49.343422, lng: 21.423691 },
    { lat: 49.344527, lng: 21.423897 },
    { lat: 49.34456, lng: 21.42389 },
    { lat: 49.344817, lng: 21.422902 },
    { lat: 49.344914, lng: 21.422268 },
    { lat: 49.345001, lng: 21.421395 },
    { lat: 49.345232, lng: 21.420425 },
    { lat: 49.345618, lng: 21.418812 },
    { lat: 49.345661, lng: 21.418634 },
    { lat: 49.345812, lng: 21.417976 },
    { lat: 49.345972, lng: 21.417239 },
    { lat: 49.346335, lng: 21.416405 },
    { lat: 49.34676, lng: 21.41526 },
    { lat: 49.347264, lng: 21.413884 },
    { lat: 49.347447, lng: 21.4127 },
    { lat: 49.347801, lng: 21.412118 },
    { lat: 49.347805, lng: 21.411693 },
    { lat: 49.348322, lng: 21.410274 },
    { lat: 49.348391, lng: 21.409148 },
    { lat: 49.349161, lng: 21.407976 },
    { lat: 49.349695, lng: 21.407202 },
    { lat: 49.350277, lng: 21.406424 },
    { lat: 49.350453, lng: 21.40624 },
    { lat: 49.351005, lng: 21.405045 },
    { lat: 49.351191, lng: 21.4049 },
    { lat: 49.352581, lng: 21.40374 },
    { lat: 49.352754, lng: 21.403289 },
    { lat: 49.352998, lng: 21.402646 },
    { lat: 49.353154, lng: 21.402123 },
    { lat: 49.353371, lng: 21.400683 },
    { lat: 49.353332, lng: 21.39909 },
    { lat: 49.353586, lng: 21.39713 },
    { lat: 49.353637, lng: 21.396079 },
    { lat: 49.353918, lng: 21.39534 },
    { lat: 49.353926, lng: 21.395317 },
    { lat: 49.354125, lng: 21.395235 },
    { lat: 49.354265, lng: 21.395192 },
    { lat: 49.354311, lng: 21.395173 },
    { lat: 49.354405, lng: 21.395124 },
    { lat: 49.354453, lng: 21.395089 },
    { lat: 49.354589, lng: 21.394891 },
    { lat: 49.354603, lng: 21.394849 },
    { lat: 49.354611, lng: 21.394802 },
    { lat: 49.354612, lng: 21.394764 },
    { lat: 49.354609, lng: 21.394726 },
    { lat: 49.3546, lng: 21.394706 },
    { lat: 49.354445, lng: 21.394537 },
    { lat: 49.354433, lng: 21.394486 },
    { lat: 49.354465, lng: 21.39442 },
    { lat: 49.354521, lng: 21.39438 },
    { lat: 49.354594, lng: 21.394385 },
    { lat: 49.354639, lng: 21.394388 },
    { lat: 49.354643, lng: 21.394396 },
    { lat: 49.354692, lng: 21.394388 },
    { lat: 49.354737, lng: 21.394354 },
    { lat: 49.354796, lng: 21.394309 },
    { lat: 49.354855, lng: 21.394253 },
    { lat: 49.354938, lng: 21.394132 },
    { lat: 49.354965, lng: 21.394095 },
    { lat: 49.354997, lng: 21.39407 },
    { lat: 49.355025, lng: 21.39407 },
    { lat: 49.355029, lng: 21.394053 },
    { lat: 49.355063, lng: 21.393926 },
    { lat: 49.355064, lng: 21.393921 },
    { lat: 49.355071, lng: 21.3939 },
    { lat: 49.355105, lng: 21.393775 },
    { lat: 49.355112, lng: 21.393752 },
    { lat: 49.355429, lng: 21.392613 },
    { lat: 49.355819, lng: 21.390977 },
    { lat: 49.35607, lng: 21.390315 },
    { lat: 49.3559, lng: 21.388816 },
    { lat: 49.355695, lng: 21.387029 },
    { lat: 49.355667, lng: 21.386633 },
    { lat: 49.355934, lng: 21.38653 },
    { lat: 49.356066, lng: 21.386525 },
    { lat: 49.356214, lng: 21.386598 },
    { lat: 49.3567, lng: 21.386353 },
    { lat: 49.356733, lng: 21.38627 },
    { lat: 49.357279, lng: 21.384927 },
    { lat: 49.357177, lng: 21.382997 },
    { lat: 49.357507, lng: 21.382881 },
    { lat: 49.35803, lng: 21.381349 },
    { lat: 49.358943, lng: 21.381065 },
    { lat: 49.359337, lng: 21.379818 },
    { lat: 49.359393, lng: 21.378438 },
    { lat: 49.359408, lng: 21.378209 },
    { lat: 49.35946, lng: 21.37738 },
    { lat: 49.359637, lng: 21.376902 },
    { lat: 49.360363, lng: 21.375923 },
    { lat: 49.360723, lng: 21.375048 },
    { lat: 49.361289, lng: 21.374901 },
    { lat: 49.361766, lng: 21.374058 },
    { lat: 49.361975, lng: 21.373595 },
    { lat: 49.362474, lng: 21.372052 },
    { lat: 49.362658, lng: 21.37144 },
    { lat: 49.362752, lng: 21.371116 },
    { lat: 49.363081, lng: 21.369982 },
    { lat: 49.363214, lng: 21.368938 },
    { lat: 49.3632459, lng: 21.3680323 },
    { lat: 49.3636421, lng: 21.365795 },
    { lat: 49.3622928, lng: 21.365895 },
    { lat: 49.3596959, lng: 21.3665701 },
    { lat: 49.3595335, lng: 21.3667477 },
    { lat: 49.3588213, lng: 21.3669786 },
    { lat: 49.3582926, lng: 21.3670527 },
    { lat: 49.3574199, lng: 21.3671045 },
    { lat: 49.3571541, lng: 21.3668298 },
    { lat: 49.3562292, lng: 21.3666687 },
    { lat: 49.3551611, lng: 21.3639904 },
    { lat: 49.3549859, lng: 21.3632392 },
    { lat: 49.3555196, lng: 21.3626497 },
    { lat: 49.3556936, lng: 21.3621789 },
    { lat: 49.3557796, lng: 21.3621202 },
    { lat: 49.3557795, lng: 21.3619676 },
    { lat: 49.3553983, lng: 21.3618959 },
    { lat: 49.3558322, lng: 21.361502 },
    { lat: 49.3561926, lng: 21.3610612 },
    { lat: 49.3562071, lng: 21.3609046 },
    { lat: 49.3559965, lng: 21.3600971 },
    { lat: 49.3559098, lng: 21.3594459 },
    { lat: 49.356599, lng: 21.3590115 },
    { lat: 49.3565724, lng: 21.3588798 },
    { lat: 49.3566444, lng: 21.357089 },
    { lat: 49.3566098, lng: 21.3562263 },
    { lat: 49.3569379, lng: 21.3546155 },
    { lat: 49.3569446, lng: 21.3543082 },
    { lat: 49.3570024, lng: 21.3539833 },
  ],
  Varadka: [
    { lat: 49.4029826, lng: 21.3892003 },
    { lat: 49.403455, lng: 21.3896587 },
    { lat: 49.4047644, lng: 21.3906498 },
    { lat: 49.405445, lng: 21.3913424 },
    { lat: 49.4053657, lng: 21.3916264 },
    { lat: 49.4054484, lng: 21.3917642 },
    { lat: 49.4057072, lng: 21.3918815 },
    { lat: 49.4056556, lng: 21.3921166 },
    { lat: 49.4058012, lng: 21.3924848 },
    { lat: 49.4058631, lng: 21.3925217 },
    { lat: 49.4058607, lng: 21.3926624 },
    { lat: 49.4061132, lng: 21.3929543 },
    { lat: 49.4061472, lng: 21.3930759 },
    { lat: 49.4061187, lng: 21.3931414 },
    { lat: 49.4064854, lng: 21.3935631 },
    { lat: 49.40654, lng: 21.3932725 },
    { lat: 49.4067948, lng: 21.3932961 },
    { lat: 49.4068674, lng: 21.3923919 },
    { lat: 49.4070222, lng: 21.3916504 },
    { lat: 49.4075688, lng: 21.3926443 },
    { lat: 49.4106537, lng: 21.3962181 },
    { lat: 49.4124052, lng: 21.3981416 },
    { lat: 49.4135535, lng: 21.399513 },
    { lat: 49.4137797, lng: 21.3995886 },
    { lat: 49.413907, lng: 21.3997923 },
    { lat: 49.4140623, lng: 21.3998712 },
    { lat: 49.4141039, lng: 21.3999883 },
    { lat: 49.4152815, lng: 21.4003599 },
    { lat: 49.4161564, lng: 21.4013137 },
    { lat: 49.4162433, lng: 21.4015852 },
    { lat: 49.4165809, lng: 21.4019812 },
    { lat: 49.4168356, lng: 21.4021477 },
    { lat: 49.4171903, lng: 21.4021215 },
    { lat: 49.4176757, lng: 21.4027589 },
    { lat: 49.4183684, lng: 21.4032053 },
    { lat: 49.4194613, lng: 21.4022613 },
    { lat: 49.4199899, lng: 21.4014054 },
    { lat: 49.4206362, lng: 21.4014684 },
    { lat: 49.4209178, lng: 21.4015929 },
    { lat: 49.4230096, lng: 21.4058116 },
    { lat: 49.4232545, lng: 21.4074199 },
    { lat: 49.4236987, lng: 21.407649 },
    { lat: 49.4245276, lng: 21.4085636 },
    { lat: 49.4248839, lng: 21.4088673 },
    { lat: 49.4256701, lng: 21.4085323 },
    { lat: 49.4256929, lng: 21.408441 },
    { lat: 49.425696, lng: 21.408411 },
    { lat: 49.425788, lng: 21.408196 },
    { lat: 49.425852, lng: 21.408007 },
    { lat: 49.4258805, lng: 21.4079522 },
    { lat: 49.4260088, lng: 21.4076696 },
    { lat: 49.42609, lng: 21.407602 },
    { lat: 49.426145, lng: 21.4075684 },
    { lat: 49.4262108, lng: 21.4074774 },
    { lat: 49.4262712, lng: 21.407329 },
    { lat: 49.4263304, lng: 21.4072592 },
    { lat: 49.4264222, lng: 21.4070831 },
    { lat: 49.426581, lng: 21.406644 },
    { lat: 49.4266209, lng: 21.4065337 },
    { lat: 49.4266383, lng: 21.4065119 },
    { lat: 49.4267465, lng: 21.4064497 },
    { lat: 49.426834, lng: 21.406341 },
    { lat: 49.4268869, lng: 21.4062826 },
    { lat: 49.4269894, lng: 21.4062141 },
    { lat: 49.42705, lng: 21.4062024 },
    { lat: 49.427284, lng: 21.406243 },
    { lat: 49.4275302, lng: 21.4062853 },
    { lat: 49.4276177, lng: 21.4062624 },
    { lat: 49.4276739, lng: 21.4062505 },
    { lat: 49.4278401, lng: 21.4061886 },
    { lat: 49.4280015, lng: 21.4061595 },
    { lat: 49.4280911, lng: 21.4061759 },
    { lat: 49.4281486, lng: 21.4061509 },
    { lat: 49.4282349, lng: 21.4061389 },
    { lat: 49.4282707, lng: 21.4061521 },
    { lat: 49.428295, lng: 21.406157 },
    { lat: 49.428361, lng: 21.406198 },
    { lat: 49.4284193, lng: 21.4062186 },
    { lat: 49.4287186, lng: 21.4059481 },
    { lat: 49.4291873, lng: 21.4058667 },
    { lat: 49.4295566, lng: 21.4055343 },
    { lat: 49.4302328, lng: 21.4054285 },
    { lat: 49.4308898, lng: 21.4050937 },
    { lat: 49.4311026, lng: 21.4049258 },
    { lat: 49.4313141, lng: 21.4048461 },
    { lat: 49.4315902, lng: 21.4047712 },
    { lat: 49.4317621, lng: 21.4047717 },
    { lat: 49.4317751, lng: 21.4047734 },
    { lat: 49.431819, lng: 21.404716 },
    { lat: 49.431989, lng: 21.404486 },
    { lat: 49.4321801, lng: 21.4042457 },
    { lat: 49.4321887, lng: 21.4042374 },
    { lat: 49.4322241, lng: 21.4041667 },
    { lat: 49.4323698, lng: 21.4037447 },
    { lat: 49.4326398, lng: 21.4033303 },
    { lat: 49.432834, lng: 21.4031631 },
    { lat: 49.4329312, lng: 21.4029085 },
    { lat: 49.4332135, lng: 21.4023075 },
    { lat: 49.4334177, lng: 21.4020172 },
    { lat: 49.4333647, lng: 21.4016714 },
    { lat: 49.4337855, lng: 21.4008018 },
    { lat: 49.4338087, lng: 21.4004549 },
    { lat: 49.4339299, lng: 21.4000608 },
    { lat: 49.4339669, lng: 21.3997685 },
    { lat: 49.433898, lng: 21.399165 },
    { lat: 49.4338384, lng: 21.3986687 },
    { lat: 49.4338417, lng: 21.3986625 },
    { lat: 49.4338413, lng: 21.3986572 },
    { lat: 49.4337313, lng: 21.3984552 },
    { lat: 49.4338123, lng: 21.3977493 },
    { lat: 49.4338868, lng: 21.3974492 },
    { lat: 49.433897, lng: 21.397225 },
    { lat: 49.4339006, lng: 21.3971302 },
    { lat: 49.4339135, lng: 21.3969466 },
    { lat: 49.4339623, lng: 21.3966426 },
    { lat: 49.4341407, lng: 21.3960006 },
    { lat: 49.4341331, lng: 21.3954997 },
    { lat: 49.4341362, lng: 21.3954876 },
    { lat: 49.4343141, lng: 21.3947007 },
    { lat: 49.434475, lng: 21.394054 },
    { lat: 49.434478, lng: 21.3940353 },
    { lat: 49.4345127, lng: 21.3933943 },
    { lat: 49.4344253, lng: 21.3932078 },
    { lat: 49.4330679, lng: 21.3925441 },
    { lat: 49.4328656, lng: 21.3922788 },
    { lat: 49.4327086, lng: 21.3914912 },
    { lat: 49.4330562, lng: 21.3901522 },
    { lat: 49.4331706, lng: 21.3897618 },
    { lat: 49.433429, lng: 21.3893239 },
    { lat: 49.4339657, lng: 21.3887517 },
    { lat: 49.4340557, lng: 21.3885979 },
    { lat: 49.4341513, lng: 21.3884359 },
    { lat: 49.4341977, lng: 21.3883461 },
    { lat: 49.4343377, lng: 21.3880778 },
    { lat: 49.4344187, lng: 21.3876464 },
    { lat: 49.4344383, lng: 21.3870975 },
    { lat: 49.4345217, lng: 21.3864654 },
    { lat: 49.434521, lng: 21.386042 },
    { lat: 49.4345225, lng: 21.3860205 },
    { lat: 49.4346145, lng: 21.3839885 },
    { lat: 49.434616, lng: 21.383934 },
    { lat: 49.434612, lng: 21.382737 },
    { lat: 49.4346141, lng: 21.3827076 },
    { lat: 49.434624, lng: 21.382493 },
    { lat: 49.434631, lng: 21.382364 },
    { lat: 49.4346386, lng: 21.3822512 },
    { lat: 49.434594, lng: 21.381875 },
    { lat: 49.4345755, lng: 21.3817007 },
    { lat: 49.4344549, lng: 21.3810469 },
    { lat: 49.4344335, lng: 21.3809538 },
    { lat: 49.4343947, lng: 21.3807733 },
    { lat: 49.434391, lng: 21.380765 },
    { lat: 49.4343223, lng: 21.3805148 },
    { lat: 49.43432, lng: 21.380508 },
    { lat: 49.434212, lng: 21.38018 },
    { lat: 49.4341973, lng: 21.3801301 },
    { lat: 49.434184, lng: 21.380102 },
    { lat: 49.4341187, lng: 21.3799586 },
    { lat: 49.434116, lng: 21.379954 },
    { lat: 49.4340405, lng: 21.379826 },
    { lat: 49.434036, lng: 21.379821 },
    { lat: 49.4337703, lng: 21.3794745 },
    { lat: 49.433766, lng: 21.379471 },
    { lat: 49.4335899, lng: 21.3793005 },
    { lat: 49.4334847, lng: 21.379163 },
    { lat: 49.4334501, lng: 21.3791085 },
    { lat: 49.4333628, lng: 21.3786703 },
    { lat: 49.4333191, lng: 21.3784227 },
    { lat: 49.4332915, lng: 21.3783081 },
    { lat: 49.433287, lng: 21.378297 },
    { lat: 49.433151, lng: 21.377038 },
    { lat: 49.4330722, lng: 21.3763267 },
    { lat: 49.433068, lng: 21.376323 },
    { lat: 49.4329873, lng: 21.3762249 },
    { lat: 49.432982, lng: 21.376218 },
    { lat: 49.432971, lng: 21.3761991 },
    { lat: 49.432962, lng: 21.376184 },
    { lat: 49.432953, lng: 21.376158 },
    { lat: 49.4329159, lng: 21.3760735 },
    { lat: 49.432917, lng: 21.375713 },
    { lat: 49.4329172, lng: 21.3757013 },
    { lat: 49.4329107, lng: 21.3756426 },
    { lat: 49.4328869, lng: 21.3755916 },
    { lat: 49.4328869, lng: 21.3755625 },
    { lat: 49.4328645, lng: 21.3754273 },
    { lat: 49.432829, lng: 21.375313 },
    { lat: 49.432801, lng: 21.37524 },
    { lat: 49.4327674, lng: 21.3751208 },
    { lat: 49.432767, lng: 21.375113 },
    { lat: 49.432762, lng: 21.375057 },
    { lat: 49.432765, lng: 21.374984 },
    { lat: 49.4327616, lng: 21.374926 },
    { lat: 49.4327618, lng: 21.37492 },
    { lat: 49.4327523, lng: 21.3748901 },
    { lat: 49.4327113, lng: 21.374829 },
    { lat: 49.4326845, lng: 21.3747646 },
    { lat: 49.4326496, lng: 21.3745665 },
    { lat: 49.432632, lng: 21.374513 },
    { lat: 49.4326286, lng: 21.3745021 },
    { lat: 49.432555, lng: 21.374135 },
    { lat: 49.4325188, lng: 21.3739256 },
    { lat: 49.432518, lng: 21.373913 },
    { lat: 49.4321077, lng: 21.3733031 },
    { lat: 49.431868, lng: 21.3724974 },
    { lat: 49.4316202, lng: 21.3726801 },
    { lat: 49.4315169, lng: 21.3726084 },
    { lat: 49.4294369, lng: 21.3752102 },
    { lat: 49.428428, lng: 21.3770969 },
    { lat: 49.4277064, lng: 21.376544 },
    { lat: 49.425925, lng: 21.3740787 },
    { lat: 49.4254637, lng: 21.3736972 },
    { lat: 49.4241551, lng: 21.3722319 },
    { lat: 49.4204283, lng: 21.3676702 },
    { lat: 49.4186471, lng: 21.3652182 },
    { lat: 49.4182894, lng: 21.3651437 },
    { lat: 49.4178002, lng: 21.3636274 },
    { lat: 49.4142303, lng: 21.35928 },
    { lat: 49.414143, lng: 21.3596774 },
    { lat: 49.4141305, lng: 21.3602423 },
    { lat: 49.414001, lng: 21.3607644 },
    { lat: 49.4138638, lng: 21.3616444 },
    { lat: 49.4130674, lng: 21.3643164 },
    { lat: 49.4128274, lng: 21.3648356 },
    { lat: 49.4126476, lng: 21.3653785 },
    { lat: 49.4121528, lng: 21.3664126 },
    { lat: 49.4119283, lng: 21.3670406 },
    { lat: 49.411755, lng: 21.3673836 },
    { lat: 49.4111356, lng: 21.368195 },
    { lat: 49.4109326, lng: 21.3686015 },
    { lat: 49.4104179, lng: 21.3693446 },
    { lat: 49.4086859, lng: 21.3716175 },
    { lat: 49.4084575, lng: 21.3719617 },
    { lat: 49.4079821, lng: 21.3729339 },
    { lat: 49.4074217, lng: 21.3738784 },
    { lat: 49.4065585, lng: 21.3749775 },
    { lat: 49.4062716, lng: 21.3754735 },
    { lat: 49.4057613, lng: 21.3767104 },
    { lat: 49.4056513, lng: 21.377068 },
    { lat: 49.4056363, lng: 21.3773191 },
    { lat: 49.4056664, lng: 21.377347 },
    { lat: 49.4055475, lng: 21.3776659 },
    { lat: 49.4052262, lng: 21.3780259 },
    { lat: 49.405156, lng: 21.3783562 },
    { lat: 49.4048954, lng: 21.3788251 },
    { lat: 49.4047072, lng: 21.3793124 },
    { lat: 49.4043845, lng: 21.3797068 },
    { lat: 49.4043206, lng: 21.3798994 },
    { lat: 49.4034991, lng: 21.380623 },
    { lat: 49.4035035, lng: 21.3807744 },
    { lat: 49.4036335, lng: 21.3811372 },
    { lat: 49.4037413, lng: 21.3820455 },
    { lat: 49.4038031, lng: 21.3821211 },
    { lat: 49.4041184, lng: 21.3821326 },
    { lat: 49.4041871, lng: 21.3826325 },
    { lat: 49.4043454, lng: 21.3830049 },
    { lat: 49.4043512, lng: 21.383813 },
    { lat: 49.4044768, lng: 21.3843422 },
    { lat: 49.4044759, lng: 21.3846154 },
    { lat: 49.4042954, lng: 21.385091 },
    { lat: 49.4043126, lng: 21.3854926 },
    { lat: 49.4040421, lng: 21.3869674 },
    { lat: 49.4033264, lng: 21.3885672 },
    { lat: 49.4029826, lng: 21.3892003 },
  ],
  Kľušov: [
    { lat: 49.2240991, lng: 21.2707878 },
    { lat: 49.2241915, lng: 21.2707877 },
    { lat: 49.2244668, lng: 21.2714795 },
    { lat: 49.2247009, lng: 21.2714805 },
    { lat: 49.2253042, lng: 21.273629 },
    { lat: 49.2258567, lng: 21.2747007 },
    { lat: 49.226798, lng: 21.2742772 },
    { lat: 49.2270703, lng: 21.2748354 },
    { lat: 49.2272938, lng: 21.2755618 },
    { lat: 49.2274655, lng: 21.2758823 },
    { lat: 49.2275534, lng: 21.2762904 },
    { lat: 49.2279187, lng: 21.2773885 },
    { lat: 49.2280835, lng: 21.2781861 },
    { lat: 49.2280932, lng: 21.278575 },
    { lat: 49.2281629, lng: 21.2789056 },
    { lat: 49.2284106, lng: 21.2793596 },
    { lat: 49.2287311, lng: 21.2793146 },
    { lat: 49.2288723, lng: 21.2795174 },
    { lat: 49.2296386, lng: 21.2811146 },
    { lat: 49.2302269, lng: 21.2825148 },
    { lat: 49.2306775, lng: 21.2838298 },
    { lat: 49.2309507, lng: 21.2848589 },
    { lat: 49.2309015, lng: 21.2852037 },
    { lat: 49.2314417, lng: 21.2862877 },
    { lat: 49.231373, lng: 21.2863919 },
    { lat: 49.2315869, lng: 21.2885565 },
    { lat: 49.2316313, lng: 21.2902023 },
    { lat: 49.2317944, lng: 21.2903164 },
    { lat: 49.232075, lng: 21.2907369 },
    { lat: 49.2322017, lng: 21.2908331 },
    { lat: 49.2326027, lng: 21.2915177 },
    { lat: 49.2333788, lng: 21.2933312 },
    { lat: 49.2338887, lng: 21.2931159 },
    { lat: 49.2346779, lng: 21.2930563 },
    { lat: 49.2347615, lng: 21.2933262 },
    { lat: 49.2347862, lng: 21.293956 },
    { lat: 49.2349506, lng: 21.2949306 },
    { lat: 49.234963, lng: 21.2956365 },
    { lat: 49.2350348, lng: 21.2961492 },
    { lat: 49.2351544, lng: 21.2963853 },
    { lat: 49.2360307, lng: 21.2969367 },
    { lat: 49.2363595, lng: 21.2980636 },
    { lat: 49.2365279, lng: 21.2983473 },
    { lat: 49.236594, lng: 21.2989728 },
    { lat: 49.2369582, lng: 21.2988845 },
    { lat: 49.2374579, lng: 21.2989973 },
    { lat: 49.237579, lng: 21.2989092 },
    { lat: 49.2378629, lng: 21.2989947 },
    { lat: 49.2386324, lng: 21.2995929 },
    { lat: 49.2389714, lng: 21.2997887 },
    { lat: 49.239299, lng: 21.2997898 },
    { lat: 49.2394886, lng: 21.3003302 },
    { lat: 49.2397144, lng: 21.3006939 },
    { lat: 49.2398856, lng: 21.3008539 },
    { lat: 49.2400486, lng: 21.3012155 },
    { lat: 49.2399666, lng: 21.3020445 },
    { lat: 49.2402767, lng: 21.3022178 },
    { lat: 49.2421186, lng: 21.3040083 },
    { lat: 49.2431342, lng: 21.3042948 },
    { lat: 49.2431618, lng: 21.3039043 },
    { lat: 49.2450953, lng: 21.3025002 },
    { lat: 49.2466448, lng: 21.3003769 },
    { lat: 49.2482622, lng: 21.2995409 },
    { lat: 49.2495134, lng: 21.2981259 },
    { lat: 49.2497702, lng: 21.2979465 },
    { lat: 49.2498619, lng: 21.297072 },
    { lat: 49.2497698, lng: 21.2965919 },
    { lat: 49.249853, lng: 21.2954357 },
    { lat: 49.2501871, lng: 21.2951766 },
    { lat: 49.2504064, lng: 21.2948351 },
    { lat: 49.2506773, lng: 21.2941169 },
    { lat: 49.2506445, lng: 21.2933366 },
    { lat: 49.2507894, lng: 21.2929126 },
    { lat: 49.2507595, lng: 21.2927832 },
    { lat: 49.2508365, lng: 21.2922429 },
    { lat: 49.2510148, lng: 21.2914023 },
    { lat: 49.251776, lng: 21.289638 },
    { lat: 49.252128, lng: 21.2891147 },
    { lat: 49.2525918, lng: 21.2882679 },
    { lat: 49.2531329, lng: 21.2876685 },
    { lat: 49.2535245, lng: 21.2869262 },
    { lat: 49.2538747, lng: 21.286493 },
    { lat: 49.2540564, lng: 21.2861721 },
    { lat: 49.2542474, lng: 21.2856843 },
    { lat: 49.2552559, lng: 21.2844722 },
    { lat: 49.2554114, lng: 21.2842205 },
    { lat: 49.2562551, lng: 21.2823572 },
    { lat: 49.2567542, lng: 21.2807085 },
    { lat: 49.2571869, lng: 21.2789146 },
    { lat: 49.257365, lng: 21.2783663 },
    { lat: 49.2574836, lng: 21.2781954 },
    { lat: 49.2577625, lng: 21.2773432 },
    { lat: 49.2582473, lng: 21.2762631 },
    { lat: 49.2582655, lng: 21.2759387 },
    { lat: 49.2581812, lng: 21.2750902 },
    { lat: 49.2581839, lng: 21.2746585 },
    { lat: 49.2580871, lng: 21.2742511 },
    { lat: 49.2582499, lng: 21.2735745 },
    { lat: 49.2583982, lng: 21.2733407 },
    { lat: 49.2584183, lng: 21.2732258 },
    { lat: 49.2585245, lng: 21.2731685 },
    { lat: 49.2585991, lng: 21.2729907 },
    { lat: 49.2587952, lng: 21.2730459 },
    { lat: 49.2588961, lng: 21.2729581 },
    { lat: 49.2590466, lng: 21.272505 },
    { lat: 49.2591641, lng: 21.2724066 },
    { lat: 49.2592486, lng: 21.2720739 },
    { lat: 49.2594914, lng: 21.2718873 },
    { lat: 49.2602337, lng: 21.2708735 },
    { lat: 49.2607228, lng: 21.2704983 },
    { lat: 49.261101, lng: 21.2700586 },
    { lat: 49.2614258, lng: 21.2697891 },
    { lat: 49.261471, lng: 21.268239 },
    { lat: 49.2615231, lng: 21.2681394 },
    { lat: 49.2614633, lng: 21.2676534 },
    { lat: 49.261477, lng: 21.2672529 },
    { lat: 49.2613825, lng: 21.2669966 },
    { lat: 49.2613836, lng: 21.2664068 },
    { lat: 49.2612688, lng: 21.2652643 },
    { lat: 49.2613405, lng: 21.2649159 },
    { lat: 49.2613107, lng: 21.2644191 },
    { lat: 49.2612624, lng: 21.2642035 },
    { lat: 49.2610521, lng: 21.2638002 },
    { lat: 49.2610564, lng: 21.2635002 },
    { lat: 49.2609317, lng: 21.2630623 },
    { lat: 49.260655, lng: 21.2624012 },
    { lat: 49.2605686, lng: 21.2618554 },
    { lat: 49.2598941, lng: 21.2608757 },
    { lat: 49.2595025, lng: 21.2602141 },
    { lat: 49.2594113, lng: 21.2592886 },
    { lat: 49.2591084, lng: 21.2584338 },
    { lat: 49.2591663, lng: 21.2580813 },
    { lat: 49.2593438, lng: 21.2577041 },
    { lat: 49.259546, lng: 21.257475 },
    { lat: 49.2596664, lng: 21.257109 },
    { lat: 49.2597425, lng: 21.2567606 },
    { lat: 49.259696, lng: 21.256421 },
    { lat: 49.2598729, lng: 21.2561674 },
    { lat: 49.2599424, lng: 21.2557572 },
    { lat: 49.2599388, lng: 21.2554544 },
    { lat: 49.2598499, lng: 21.2551204 },
    { lat: 49.2598537, lng: 21.254874 },
    { lat: 49.2597433, lng: 21.2542875 },
    { lat: 49.2597783, lng: 21.2541435 },
    { lat: 49.2597127, lng: 21.2540177 },
    { lat: 49.2597226, lng: 21.2538275 },
    { lat: 49.2595953, lng: 21.2534872 },
    { lat: 49.2592864, lng: 21.2528842 },
    { lat: 49.2592445, lng: 21.2523937 },
    { lat: 49.259283, lng: 21.252218 },
    { lat: 49.2591973, lng: 21.2516614 },
    { lat: 49.2590614, lng: 21.2517039 },
    { lat: 49.2589588, lng: 21.2519184 },
    { lat: 49.2581939, lng: 21.2515955 },
    { lat: 49.258251, lng: 21.2510727 },
    { lat: 49.2582405, lng: 21.2503962 },
    { lat: 49.2583454, lng: 21.2494489 },
    { lat: 49.2583242, lng: 21.2492742 },
    { lat: 49.2584596, lng: 21.2491745 },
    { lat: 49.2586755, lng: 21.2491328 },
    { lat: 49.2591117, lng: 21.248018 },
    { lat: 49.2595645, lng: 21.2462348 },
    { lat: 49.2595022, lng: 21.2460804 },
    { lat: 49.2594996, lng: 21.2452178 },
    { lat: 49.2595892, lng: 21.2437409 },
    { lat: 49.2599137, lng: 21.2430192 },
    { lat: 49.2600256, lng: 21.24168 },
    { lat: 49.2602818, lng: 21.2409005 },
    { lat: 49.2605548, lng: 21.2392339 },
    { lat: 49.2613699, lng: 21.2366839 },
    { lat: 49.2626609, lng: 21.2344628 },
    { lat: 49.2635016, lng: 21.23337 },
    { lat: 49.2633176, lng: 21.2329526 },
    { lat: 49.2630435, lng: 21.2325267 },
    { lat: 49.2630897, lng: 21.2323425 },
    { lat: 49.263177, lng: 21.232146 },
    { lat: 49.2635319, lng: 21.2320542 },
    { lat: 49.2639354, lng: 21.2317129 },
    { lat: 49.2641139, lng: 21.2314731 },
    { lat: 49.2642041, lng: 21.231226 },
    { lat: 49.2641736, lng: 21.2309691 },
    { lat: 49.2642242, lng: 21.2302694 },
    { lat: 49.2645543, lng: 21.2293724 },
    { lat: 49.2642652, lng: 21.2292663 },
    { lat: 49.2640442, lng: 21.2289572 },
    { lat: 49.2637525, lng: 21.2289487 },
    { lat: 49.2634765, lng: 21.2284987 },
    { lat: 49.2632451, lng: 21.2285795 },
    { lat: 49.2625578, lng: 21.2285496 },
    { lat: 49.2622844, lng: 21.2281874 },
    { lat: 49.2620631, lng: 21.2276171 },
    { lat: 49.2616896, lng: 21.2272855 },
    { lat: 49.2616528, lng: 21.227116 },
    { lat: 49.2616964, lng: 21.2269248 },
    { lat: 49.2616663, lng: 21.2268574 },
    { lat: 49.2610833, lng: 21.2266393 },
    { lat: 49.2607773, lng: 21.2263824 },
    { lat: 49.2606229, lng: 21.2260462 },
    { lat: 49.260546, lng: 21.2249728 },
    { lat: 49.2604962, lng: 21.2250791 },
    { lat: 49.2602595, lng: 21.2251732 },
    { lat: 49.259876, lng: 21.2251756 },
    { lat: 49.2597048, lng: 21.2253197 },
    { lat: 49.2595086, lng: 21.225014 },
    { lat: 49.2594636, lng: 21.225055 },
    { lat: 49.2592971, lng: 21.2257482 },
    { lat: 49.2594483, lng: 21.2258656 },
    { lat: 49.2594022, lng: 21.2263434 },
    { lat: 49.2595133, lng: 21.2265886 },
    { lat: 49.2593892, lng: 21.2269077 },
    { lat: 49.259149, lng: 21.227255 },
    { lat: 49.2589249, lng: 21.2272895 },
    { lat: 49.2588177, lng: 21.2273847 },
    { lat: 49.2587613, lng: 21.2273077 },
    { lat: 49.2585592, lng: 21.227506 },
    { lat: 49.2584576, lng: 21.2274196 },
    { lat: 49.2583687, lng: 21.2274962 },
    { lat: 49.2582725, lng: 21.2277647 },
    { lat: 49.2583674, lng: 21.2279304 },
    { lat: 49.2580765, lng: 21.2283822 },
    { lat: 49.2579956, lng: 21.2286038 },
    { lat: 49.2579131, lng: 21.2285214 },
    { lat: 49.2580418, lng: 21.2282074 },
    { lat: 49.2576591, lng: 21.2278005 },
    { lat: 49.2575456, lng: 21.2279495 },
    { lat: 49.2576, lng: 21.228071 },
    { lat: 49.2576277, lng: 21.2285333 },
    { lat: 49.2575998, lng: 21.2291279 },
    { lat: 49.2576641, lng: 21.22924 },
    { lat: 49.2575848, lng: 21.2295519 },
    { lat: 49.2576005, lng: 21.2298407 },
    { lat: 49.2575239, lng: 21.2300638 },
    { lat: 49.257488, lng: 21.2304288 },
    { lat: 49.2573566, lng: 21.2303262 },
    { lat: 49.2571068, lng: 21.2296349 },
    { lat: 49.2568673, lng: 21.229713 },
    { lat: 49.2568515, lng: 21.230043 },
    { lat: 49.2569182, lng: 21.2301268 },
    { lat: 49.2570486, lng: 21.2307759 },
    { lat: 49.2569512, lng: 21.2309378 },
    { lat: 49.2569849, lng: 21.2310817 },
    { lat: 49.257025, lng: 21.2312128 },
    { lat: 49.257095, lng: 21.2312585 },
    { lat: 49.2572441, lng: 21.2316826 },
    { lat: 49.2571387, lng: 21.2318437 },
    { lat: 49.2570884, lng: 21.2321092 },
    { lat: 49.2569674, lng: 21.2321232 },
    { lat: 49.2568487, lng: 21.2319185 },
    { lat: 49.2568011, lng: 21.2319425 },
    { lat: 49.2567711, lng: 21.2317739 },
    { lat: 49.2566259, lng: 21.2316683 },
    { lat: 49.2564689, lng: 21.2317916 },
    { lat: 49.2564329, lng: 21.2319026 },
    { lat: 49.2566055, lng: 21.2323927 },
    { lat: 49.2568258, lng: 21.2325318 },
    { lat: 49.2568224, lng: 21.2331895 },
    { lat: 49.2566696, lng: 21.2345531 },
    { lat: 49.2564052, lng: 21.2348091 },
    { lat: 49.2562306, lng: 21.2348364 },
    { lat: 49.2562405, lng: 21.2350133 },
    { lat: 49.2561798, lng: 21.235049 },
    { lat: 49.2562585, lng: 21.235821 },
    { lat: 49.2565384, lng: 21.2366893 },
    { lat: 49.2565915, lng: 21.2366228 },
    { lat: 49.2566346, lng: 21.2367058 },
    { lat: 49.2563699, lng: 21.2371001 },
    { lat: 49.2561577, lng: 21.2372212 },
    { lat: 49.2560229, lng: 21.2372313 },
    { lat: 49.255904, lng: 21.2371375 },
    { lat: 49.2558197, lng: 21.2373935 },
    { lat: 49.2557414, lng: 21.2374481 },
    { lat: 49.2552602, lng: 21.2373868 },
    { lat: 49.2545413, lng: 21.2375338 },
    { lat: 49.2540842, lng: 21.2377042 },
    { lat: 49.2535802, lng: 21.2377457 },
    { lat: 49.2535483, lng: 21.237623 },
    { lat: 49.253562, lng: 21.2373756 },
    { lat: 49.2536199, lng: 21.2372119 },
    { lat: 49.2536112, lng: 21.237 },
    { lat: 49.2535123, lng: 21.2368198 },
    { lat: 49.2533609, lng: 21.2366859 },
    { lat: 49.2530094, lng: 21.2365652 },
    { lat: 49.251718, lng: 21.2355164 },
    { lat: 49.2516919, lng: 21.2349193 },
    { lat: 49.2515776, lng: 21.2343533 },
    { lat: 49.2514223, lng: 21.2341653 },
    { lat: 49.2511897, lng: 21.2340318 },
    { lat: 49.2507938, lng: 21.2339979 },
    { lat: 49.2506596, lng: 21.2337377 },
    { lat: 49.2505899, lng: 21.2334106 },
    { lat: 49.2504246, lng: 21.2333029 },
    { lat: 49.2501144, lng: 21.2332366 },
    { lat: 49.250066, lng: 21.2330211 },
    { lat: 49.2501342, lng: 21.232714 },
    { lat: 49.2501166, lng: 21.2323288 },
    { lat: 49.2499754, lng: 21.232268 },
    { lat: 49.249442, lng: 21.2325256 },
    { lat: 49.2495117, lng: 21.2324079 },
    { lat: 49.2490565, lng: 21.2326109 },
    { lat: 49.2488113, lng: 21.2324351 },
    { lat: 49.2487704, lng: 21.2322338 },
    { lat: 49.2488218, lng: 21.2321466 },
    { lat: 49.2490813, lng: 21.2320995 },
    { lat: 49.2491137, lng: 21.2319884 },
    { lat: 49.2491304, lng: 21.2315712 },
    { lat: 49.2489512, lng: 21.2312714 },
    { lat: 49.2487834, lng: 21.2313072 },
    { lat: 49.2484427, lng: 21.231711 },
    { lat: 49.2483796, lng: 21.2317159 },
    { lat: 49.2482428, lng: 21.2315849 },
    { lat: 49.2482531, lng: 21.230602 },
    { lat: 49.2481508, lng: 21.2299045 },
    { lat: 49.2481134, lng: 21.2298607 },
    { lat: 49.2479092, lng: 21.229955 },
    { lat: 49.247789, lng: 21.2298563 },
    { lat: 49.2476136, lng: 21.2293041 },
    { lat: 49.2471876, lng: 21.2289242 },
    { lat: 49.2471011, lng: 21.2286743 },
    { lat: 49.2471148, lng: 21.2281691 },
    { lat: 49.2469217, lng: 21.2279615 },
    { lat: 49.2469781, lng: 21.2277802 },
    { lat: 49.2465152, lng: 21.2277325 },
    { lat: 49.2459617, lng: 21.227845 },
    { lat: 49.2459029, lng: 21.2279778 },
    { lat: 49.245927, lng: 21.2283236 },
    { lat: 49.2458148, lng: 21.228332 },
    { lat: 49.2454828, lng: 21.2282932 },
    { lat: 49.2453157, lng: 21.2283517 },
    { lat: 49.2452552, lng: 21.2286917 },
    { lat: 49.2447894, lng: 21.2286859 },
    { lat: 49.2444417, lng: 21.2292392 },
    { lat: 49.2442891, lng: 21.2293993 },
    { lat: 49.2437693, lng: 21.2289991 },
    { lat: 49.2434376, lng: 21.2290769 },
    { lat: 49.2433408, lng: 21.2292946 },
    { lat: 49.2431778, lng: 21.2294813 },
    { lat: 49.2429994, lng: 21.2292107 },
    { lat: 49.2428211, lng: 21.2291547 },
    { lat: 49.2425674, lng: 21.2292849 },
    { lat: 49.2422017, lng: 21.229685 },
    { lat: 49.241653, lng: 21.2299891 },
    { lat: 49.2413032, lng: 21.2294485 },
    { lat: 49.2411452, lng: 21.229394 },
    { lat: 49.2409663, lng: 21.2295433 },
    { lat: 49.2408382, lng: 21.2299839 },
    { lat: 49.2406975, lng: 21.230133 },
    { lat: 49.2406065, lng: 21.2301338 },
    { lat: 49.2405, lng: 21.2298213 },
    { lat: 49.239957, lng: 21.230089 },
    { lat: 49.2398837, lng: 21.2303056 },
    { lat: 49.2397067, lng: 21.2304743 },
    { lat: 49.2396458, lng: 21.2306889 },
    { lat: 49.239572, lng: 21.230781 },
    { lat: 49.239259, lng: 21.2309212 },
    { lat: 49.2391198, lng: 21.2310706 },
    { lat: 49.2387076, lng: 21.2317224 },
    { lat: 49.2387158, lng: 21.2318432 },
    { lat: 49.2386601, lng: 21.2319721 },
    { lat: 49.2384693, lng: 21.232024 },
    { lat: 49.2378841, lng: 21.2332637 },
    { lat: 49.2374599, lng: 21.2346142 },
    { lat: 49.2373952, lng: 21.2351845 },
    { lat: 49.2373026, lng: 21.2353942 },
    { lat: 49.2373133, lng: 21.2355588 },
    { lat: 49.2372386, lng: 21.2357287 },
    { lat: 49.2372823, lng: 21.2358703 },
    { lat: 49.2372259, lng: 21.2363738 },
    { lat: 49.2373125, lng: 21.2366283 },
    { lat: 49.2372732, lng: 21.2372151 },
    { lat: 49.2373583, lng: 21.2375374 },
    { lat: 49.2373551, lng: 21.2377919 },
    { lat: 49.2380824, lng: 21.2388333 },
    { lat: 49.2380986, lng: 21.2391372 },
    { lat: 49.2381648, lng: 21.2392812 },
    { lat: 49.2382127, lng: 21.2399277 },
    { lat: 49.2381453, lng: 21.2407359 },
    { lat: 49.2381798, lng: 21.2413841 },
    { lat: 49.2378607, lng: 21.2414343 },
    { lat: 49.2374604, lng: 21.2413358 },
    { lat: 49.2372009, lng: 21.2412585 },
    { lat: 49.2369723, lng: 21.2412778 },
    { lat: 49.2367634, lng: 21.2414114 },
    { lat: 49.2365623, lng: 21.2413087 },
    { lat: 49.2364214, lng: 21.2410683 },
    { lat: 49.2363342, lng: 21.2409896 },
    { lat: 49.2346009, lng: 21.2411391 },
    { lat: 49.2330785, lng: 21.2423876 },
    { lat: 49.2309517, lng: 21.2443616 },
    { lat: 49.2308047, lng: 21.244559 },
    { lat: 49.2289694, lng: 21.2480734 },
    { lat: 49.2285332, lng: 21.2497066 },
    { lat: 49.2282514, lng: 21.2503892 },
    { lat: 49.2281925, lng: 21.2508833 },
    { lat: 49.2271388, lng: 21.2531881 },
    { lat: 49.2264737, lng: 21.2547612 },
    { lat: 49.2263522, lng: 21.2554582 },
    { lat: 49.2268556, lng: 21.2566528 },
    { lat: 49.2280955, lng: 21.2586043 },
    { lat: 49.2280166, lng: 21.2597781 },
    { lat: 49.2281278, lng: 21.261468 },
    { lat: 49.2278498, lng: 21.2628249 },
    { lat: 49.2277809, lng: 21.2635883 },
    { lat: 49.2276138, lng: 21.2642396 },
    { lat: 49.2269826, lng: 21.2657857 },
    { lat: 49.226726, lng: 21.2665643 },
    { lat: 49.226597, lng: 21.2668326 },
    { lat: 49.2257017, lng: 21.2673967 },
    { lat: 49.2252061, lng: 21.2685972 },
    { lat: 49.2246813, lng: 21.2695416 },
    { lat: 49.224466, lng: 21.2702707 },
    { lat: 49.2240991, lng: 21.2707878 },
  ],
  Smilno: [
    { lat: 49.3880069, lng: 21.381135 },
    { lat: 49.3879718, lng: 21.3809334 },
    { lat: 49.3879324, lng: 21.3807822 },
    { lat: 49.3876796, lng: 21.3803696 },
    { lat: 49.387783, lng: 21.380102 },
    { lat: 49.3881109, lng: 21.3802687 },
    { lat: 49.3883277, lng: 21.3800655 },
    { lat: 49.3883699, lng: 21.3795538 },
    { lat: 49.3880572, lng: 21.3793273 },
    { lat: 49.3880582, lng: 21.3789744 },
    { lat: 49.3883702, lng: 21.3787939 },
    { lat: 49.3886604, lng: 21.3782957 },
    { lat: 49.3885429, lng: 21.3779719 },
    { lat: 49.3886186, lng: 21.3773427 },
    { lat: 49.3889966, lng: 21.3772241 },
    { lat: 49.3897661, lng: 21.3766079 },
    { lat: 49.3900382, lng: 21.3758011 },
    { lat: 49.3898053, lng: 21.3752653 },
    { lat: 49.3898574, lng: 21.3750508 },
    { lat: 49.3904845, lng: 21.3743765 },
    { lat: 49.3905689, lng: 21.3742266 },
    { lat: 49.3904391, lng: 21.373982 },
    { lat: 49.3904314, lng: 21.3737193 },
    { lat: 49.3906062, lng: 21.3735475 },
    { lat: 49.3906633, lng: 21.3733793 },
    { lat: 49.3906191, lng: 21.3730248 },
    { lat: 49.3908474, lng: 21.3728367 },
    { lat: 49.3911202, lng: 21.3719026 },
    { lat: 49.3914691, lng: 21.3711811 },
    { lat: 49.3916277, lng: 21.3706435 },
    { lat: 49.391594, lng: 21.3700369 },
    { lat: 49.3913006, lng: 21.3698875 },
    { lat: 49.3912788, lng: 21.3696441 },
    { lat: 49.3916539, lng: 21.3686716 },
    { lat: 49.391635, lng: 21.3681409 },
    { lat: 49.3917784, lng: 21.3677427 },
    { lat: 49.3917832, lng: 21.3675628 },
    { lat: 49.391681, lng: 21.3671116 },
    { lat: 49.3924361, lng: 21.3657067 },
    { lat: 49.3925116, lng: 21.3653663 },
    { lat: 49.3925544, lng: 21.3648021 },
    { lat: 49.3928719, lng: 21.3647287 },
    { lat: 49.3927824, lng: 21.3641011 },
    { lat: 49.3930179, lng: 21.363504 },
    { lat: 49.3931772, lng: 21.3633322 },
    { lat: 49.3933166, lng: 21.3630639 },
    { lat: 49.3934551, lng: 21.3623489 },
    { lat: 49.3936081, lng: 21.3621584 },
    { lat: 49.3937793, lng: 21.3621289 },
    { lat: 49.3939812, lng: 21.3618346 },
    { lat: 49.3942648, lng: 21.3617228 },
    { lat: 49.3944768, lng: 21.3617832 },
    { lat: 49.3948074, lng: 21.3613221 },
    { lat: 49.3949932, lng: 21.3612117 },
    { lat: 49.395098, lng: 21.3610636 },
    { lat: 49.3952116, lng: 21.3607595 },
    { lat: 49.3956146, lng: 21.3600478 },
    { lat: 49.3967698, lng: 21.3573369 },
    { lat: 49.397111, lng: 21.3568938 },
    { lat: 49.3976002, lng: 21.3567338 },
    { lat: 49.3976912, lng: 21.3566144 },
    { lat: 49.3976045, lng: 21.356611 },
    { lat: 49.3988945, lng: 21.3552701 },
    { lat: 49.3994451, lng: 21.3547503 },
    { lat: 49.3998801, lng: 21.3544722 },
    { lat: 49.4000672, lng: 21.3541818 },
    { lat: 49.4009765, lng: 21.3531265 },
    { lat: 49.4017423, lng: 21.3518642 },
    { lat: 49.4022253, lng: 21.3512659 },
    { lat: 49.4025331, lng: 21.3510241 },
    { lat: 49.40282, lng: 21.3509518 },
    { lat: 49.403539, lng: 21.3504584 },
    { lat: 49.4037342, lng: 21.3504001 },
    { lat: 49.4039044, lng: 21.3501068 },
    { lat: 49.4038896, lng: 21.3500301 },
    { lat: 49.4040867, lng: 21.3499564 },
    { lat: 49.4045207, lng: 21.3494569 },
    { lat: 49.4053585, lng: 21.3480391 },
    { lat: 49.4058446, lng: 21.3470211 },
    { lat: 49.4064788, lng: 21.346172 },
    { lat: 49.4068452, lng: 21.345826 },
    { lat: 49.4072558, lng: 21.3452894 },
    { lat: 49.4077379, lng: 21.3442907 },
    { lat: 49.408082, lng: 21.3429921 },
    { lat: 49.408461, lng: 21.3419262 },
    { lat: 49.408411, lng: 21.3416508 },
    { lat: 49.4076931, lng: 21.339182 },
    { lat: 49.4082355, lng: 21.3385454 },
    { lat: 49.4085079, lng: 21.338358 },
    { lat: 49.4082965, lng: 21.3380599 },
    { lat: 49.4082415, lng: 21.3371634 },
    { lat: 49.4075027, lng: 21.3362605 },
    { lat: 49.40637, lng: 21.3362332 },
    { lat: 49.4051482, lng: 21.3355567 },
    { lat: 49.4035738, lng: 21.3350479 },
    { lat: 49.4030986, lng: 21.3349544 },
    { lat: 49.4005418, lng: 21.3339158 },
    { lat: 49.3997169, lng: 21.3338319 },
    { lat: 49.3982977, lng: 21.3329469 },
    { lat: 49.3969312, lng: 21.3338686 },
    { lat: 49.3952886, lng: 21.3338272 },
    { lat: 49.3945506, lng: 21.3338698 },
    { lat: 49.3941198, lng: 21.334178 },
    { lat: 49.3934356, lng: 21.3333377 },
    { lat: 49.3941176, lng: 21.3322118 },
    { lat: 49.3944075, lng: 21.330841 },
    { lat: 49.3943387, lng: 21.3303835 },
    { lat: 49.3927977, lng: 21.3280971 },
    { lat: 49.39191, lng: 21.3269263 },
    { lat: 49.3917355, lng: 21.3261681 },
    { lat: 49.3913354, lng: 21.3261828 },
    { lat: 49.3900835, lng: 21.3260012 },
    { lat: 49.3900837, lng: 21.3261637 },
    { lat: 49.3897283, lng: 21.3261994 },
    { lat: 49.389248, lng: 21.326435 },
    { lat: 49.38907, lng: 21.3264572 },
    { lat: 49.3886415, lng: 21.3263215 },
    { lat: 49.388215, lng: 21.3263907 },
    { lat: 49.3878512, lng: 21.3266012 },
    { lat: 49.3873976, lng: 21.3269739 },
    { lat: 49.3870639, lng: 21.3269875 },
    { lat: 49.3868055, lng: 21.3271125 },
    { lat: 49.386101, lng: 21.3268799 },
    { lat: 49.3859273, lng: 21.3266385 },
    { lat: 49.3858059, lng: 21.326569 },
    { lat: 49.3852332, lng: 21.3267554 },
    { lat: 49.3850745, lng: 21.3268641 },
    { lat: 49.3846791, lng: 21.3268076 },
    { lat: 49.3842077, lng: 21.3268844 },
    { lat: 49.3836666, lng: 21.3271108 },
    { lat: 49.3833248, lng: 21.3273489 },
    { lat: 49.3824139, lng: 21.3276597 },
    { lat: 49.3820652, lng: 21.3281171 },
    { lat: 49.381981, lng: 21.3280447 },
    { lat: 49.3817679, lng: 21.3276501 },
    { lat: 49.3814712, lng: 21.3273217 },
    { lat: 49.3813791, lng: 21.3272935 },
    { lat: 49.381125, lng: 21.3275962 },
    { lat: 49.3808673, lng: 21.3277132 },
    { lat: 49.3806397, lng: 21.3280404 },
    { lat: 49.3805693, lng: 21.3280132 },
    { lat: 49.3804584, lng: 21.3278058 },
    { lat: 49.3803374, lng: 21.3277883 },
    { lat: 49.3802013, lng: 21.3279018 },
    { lat: 49.3800257, lng: 21.3279365 },
    { lat: 49.3799065, lng: 21.3280878 },
    { lat: 49.379719, lng: 21.3281739 },
    { lat: 49.3793539, lng: 21.3286093 },
    { lat: 49.3789148, lng: 21.3286693 },
    { lat: 49.3781287, lng: 21.3290627 },
    { lat: 49.3778702, lng: 21.3294051 },
    { lat: 49.3772838, lng: 21.3297419 },
    { lat: 49.3771348, lng: 21.3299083 },
    { lat: 49.3771685, lng: 21.3304778 },
    { lat: 49.377042, lng: 21.3306875 },
    { lat: 49.3770181, lng: 21.3308256 },
    { lat: 49.3771168, lng: 21.3313448 },
    { lat: 49.3769089, lng: 21.3313525 },
    { lat: 49.376718, lng: 21.3317811 },
    { lat: 49.3763488, lng: 21.3321134 },
    { lat: 49.3759494, lng: 21.3326415 },
    { lat: 49.3757324, lng: 21.3326855 },
    { lat: 49.3754428, lng: 21.3324783 },
    { lat: 49.375274, lng: 21.3324786 },
    { lat: 49.3749874, lng: 21.3327189 },
    { lat: 49.3748178, lng: 21.3331402 },
    { lat: 49.374793, lng: 21.3334412 },
    { lat: 49.3748386, lng: 21.3337991 },
    { lat: 49.3747389, lng: 21.3341873 },
    { lat: 49.3744868, lng: 21.3343028 },
    { lat: 49.3744052, lng: 21.334549 },
    { lat: 49.3740688, lng: 21.3345808 },
    { lat: 49.3739503, lng: 21.3346869 },
    { lat: 49.3737401, lng: 21.3350706 },
    { lat: 49.3737104, lng: 21.3354641 },
    { lat: 49.3735693, lng: 21.3357938 },
    { lat: 49.3732242, lng: 21.3361422 },
    { lat: 49.3730281, lng: 21.3366205 },
    { lat: 49.3725789, lng: 21.3371382 },
    { lat: 49.372122, lng: 21.3381525 },
    { lat: 49.3720317, lng: 21.3382216 },
    { lat: 49.3718429, lng: 21.3382254 },
    { lat: 49.3717137, lng: 21.3383504 },
    { lat: 49.3717015, lng: 21.3381631 },
    { lat: 49.3707324, lng: 21.3388723 },
    { lat: 49.3706631, lng: 21.3391877 },
    { lat: 49.3705202, lng: 21.3391861 },
    { lat: 49.3703911, lng: 21.338929 },
    { lat: 49.3696404, lng: 21.3388472 },
    { lat: 49.369449, lng: 21.3386916 },
    { lat: 49.3693509, lng: 21.3387912 },
    { lat: 49.3692873, lng: 21.3386982 },
    { lat: 49.3693187, lng: 21.3384321 },
    { lat: 49.3690866, lng: 21.3382689 },
    { lat: 49.3689022, lng: 21.3383074 },
    { lat: 49.3687023, lng: 21.3379455 },
    { lat: 49.3681811, lng: 21.3376285 },
    { lat: 49.3680989, lng: 21.3373649 },
    { lat: 49.3681545, lng: 21.337156 },
    { lat: 49.3683032, lng: 21.3371626 },
    { lat: 49.3682992, lng: 21.3370293 },
    { lat: 49.3681741, lng: 21.3368326 },
    { lat: 49.3678743, lng: 21.3365496 },
    { lat: 49.3674294, lng: 21.3365742 },
    { lat: 49.36737, lng: 21.336313 },
    { lat: 49.3670301, lng: 21.3355158 },
    { lat: 49.3665718, lng: 21.3353497 },
    { lat: 49.3663391, lng: 21.3356991 },
    { lat: 49.3657318, lng: 21.3357055 },
    { lat: 49.3653524, lng: 21.3363363 },
    { lat: 49.3651772, lng: 21.3367637 },
    { lat: 49.3650122, lng: 21.3366304 },
    { lat: 49.3643352, lng: 21.3380347 },
    { lat: 49.3639098, lng: 21.3412453 },
    { lat: 49.3633143, lng: 21.3420636 },
    { lat: 49.3628144, lng: 21.3425017 },
    { lat: 49.3619401, lng: 21.3443934 },
    { lat: 49.3615494, lng: 21.3456292 },
    { lat: 49.3616479, lng: 21.3466351 },
    { lat: 49.3614105, lng: 21.3471686 },
    { lat: 49.361312, lng: 21.3478666 },
    { lat: 49.3610001, lng: 21.3486915 },
    { lat: 49.3611027, lng: 21.348885 },
    { lat: 49.3611157, lng: 21.3491732 },
    { lat: 49.3612145, lng: 21.3494364 },
    { lat: 49.3611449, lng: 21.3494333 },
    { lat: 49.36105, lng: 21.3495388 },
    { lat: 49.3609151, lng: 21.3498405 },
    { lat: 49.3605147, lng: 21.3498435 },
    { lat: 49.3597835, lng: 21.3504621 },
    { lat: 49.3596911, lng: 21.3507963 },
    { lat: 49.3592533, lng: 21.3512072 },
    { lat: 49.3590939, lng: 21.351899 },
    { lat: 49.3577566, lng: 21.3527442 },
    { lat: 49.3574285, lng: 21.3530648 },
    { lat: 49.3570509, lng: 21.3538775 },
    { lat: 49.3570024, lng: 21.3539833 },
    { lat: 49.3569446, lng: 21.3543082 },
    { lat: 49.3569379, lng: 21.3546155 },
    { lat: 49.3566098, lng: 21.3562263 },
    { lat: 49.3566444, lng: 21.357089 },
    { lat: 49.3565724, lng: 21.3588798 },
    { lat: 49.356599, lng: 21.3590115 },
    { lat: 49.3559098, lng: 21.3594459 },
    { lat: 49.3559965, lng: 21.3600971 },
    { lat: 49.3562071, lng: 21.3609046 },
    { lat: 49.3561926, lng: 21.3610612 },
    { lat: 49.3558322, lng: 21.361502 },
    { lat: 49.3553983, lng: 21.3618959 },
    { lat: 49.3557795, lng: 21.3619676 },
    { lat: 49.3557796, lng: 21.3621202 },
    { lat: 49.3556936, lng: 21.3621789 },
    { lat: 49.3555196, lng: 21.3626497 },
    { lat: 49.3549859, lng: 21.3632392 },
    { lat: 49.3551611, lng: 21.3639904 },
    { lat: 49.3562292, lng: 21.3666687 },
    { lat: 49.3571541, lng: 21.3668298 },
    { lat: 49.3574199, lng: 21.3671045 },
    { lat: 49.3582926, lng: 21.3670527 },
    { lat: 49.3588213, lng: 21.3669786 },
    { lat: 49.3595335, lng: 21.3667477 },
    { lat: 49.3596959, lng: 21.3665701 },
    { lat: 49.3622928, lng: 21.365895 },
    { lat: 49.3636421, lng: 21.365795 },
    { lat: 49.3632459, lng: 21.3680323 },
    { lat: 49.363214, lng: 21.368938 },
    { lat: 49.363424, lng: 21.369342 },
    { lat: 49.363748, lng: 21.369848 },
    { lat: 49.363949, lng: 21.369742 },
    { lat: 49.364213, lng: 21.369664 },
    { lat: 49.364561, lng: 21.369743 },
    { lat: 49.364723, lng: 21.369836 },
    { lat: 49.365146, lng: 21.370207 },
    { lat: 49.365285, lng: 21.370449 },
    { lat: 49.365531, lng: 21.3711 },
    { lat: 49.365819, lng: 21.371722 },
    { lat: 49.366169, lng: 21.372237 },
    { lat: 49.366312, lng: 21.372349 },
    { lat: 49.366643, lng: 21.372467 },
    { lat: 49.367055, lng: 21.372583 },
    { lat: 49.367279, lng: 21.372487 },
    { lat: 49.367753, lng: 21.372485 },
    { lat: 49.367859, lng: 21.372614 },
    { lat: 49.368146, lng: 21.372058 },
    { lat: 49.368433, lng: 21.372364 },
    { lat: 49.368515, lng: 21.372373 },
    { lat: 49.368218, lng: 21.373144 },
    { lat: 49.368268, lng: 21.373292 },
    { lat: 49.36843, lng: 21.373247 },
    { lat: 49.368534, lng: 21.373762 },
    { lat: 49.368492, lng: 21.373949 },
    { lat: 49.368596, lng: 21.374236 },
    { lat: 49.368573, lng: 21.374397 },
    { lat: 49.368629, lng: 21.37453 },
    { lat: 49.368617, lng: 21.375306 },
    { lat: 49.368735, lng: 21.375598 },
    { lat: 49.368695, lng: 21.376138 },
    { lat: 49.368608, lng: 21.376296 },
    { lat: 49.368638, lng: 21.376469 },
    { lat: 49.368536, lng: 21.377035 },
    { lat: 49.368205, lng: 21.377264 },
    { lat: 49.368058, lng: 21.377769 },
    { lat: 49.368154, lng: 21.378283 },
    { lat: 49.368321, lng: 21.378369 },
    { lat: 49.368342, lng: 21.378524 },
    { lat: 49.368232, lng: 21.378792 },
    { lat: 49.368294, lng: 21.379189 },
    { lat: 49.368545, lng: 21.37928 },
    { lat: 49.368798, lng: 21.379135 },
    { lat: 49.368921, lng: 21.379228 },
    { lat: 49.369076, lng: 21.379814 },
    { lat: 49.369239, lng: 21.380195 },
    { lat: 49.369342, lng: 21.380337 },
    { lat: 49.369261, lng: 21.380875 },
    { lat: 49.369287, lng: 21.381129 },
    { lat: 49.369139, lng: 21.381218 },
    { lat: 49.369285, lng: 21.381469 },
    { lat: 49.3692924, lng: 21.3813404 },
    { lat: 49.3693955, lng: 21.3812871 },
    { lat: 49.3693733, lng: 21.3804132 },
    { lat: 49.3699197, lng: 21.3801 },
    { lat: 49.3699979, lng: 21.3798495 },
    { lat: 49.369895, lng: 21.379836 },
    { lat: 49.3698877, lng: 21.3796986 },
    { lat: 49.3699702, lng: 21.3795969 },
    { lat: 49.3701944, lng: 21.3790363 },
    { lat: 49.3703239, lng: 21.3789656 },
    { lat: 49.3704313, lng: 21.378755 },
    { lat: 49.3709595, lng: 21.3785001 },
    { lat: 49.3717117, lng: 21.3778723 },
    { lat: 49.3732244, lng: 21.3771335 },
    { lat: 49.3745068, lng: 21.3773222 },
    { lat: 49.3751532, lng: 21.3766909 },
    { lat: 49.3761219, lng: 21.3766893 },
    { lat: 49.3766862, lng: 21.3765269 },
    { lat: 49.3768179, lng: 21.3763329 },
    { lat: 49.3769125, lng: 21.3759177 },
    { lat: 49.3773781, lng: 21.375493 },
    { lat: 49.3795824, lng: 21.3740946 },
    { lat: 49.3802026, lng: 21.3737975 },
    { lat: 49.3806536, lng: 21.3734117 },
    { lat: 49.380856, lng: 21.3736736 },
    { lat: 49.3812315, lng: 21.3738152 },
    { lat: 49.3844191, lng: 21.3755134 },
    { lat: 49.3847829, lng: 21.3762101 },
    { lat: 49.3849697, lng: 21.3764449 },
    { lat: 49.3852398, lng: 21.3766043 },
    { lat: 49.3854361, lng: 21.37685 },
    { lat: 49.3855437, lng: 21.3771416 },
    { lat: 49.3855972, lng: 21.3776926 },
    { lat: 49.3863387, lng: 21.3797308 },
    { lat: 49.3869351, lng: 21.3809139 },
    { lat: 49.3878518, lng: 21.3809106 },
    { lat: 49.3880069, lng: 21.381135 },
  ],
  Hutka: [
    { lat: 49.38494, lng: 21.415344 },
    { lat: 49.387448, lng: 21.418828 },
    { lat: 49.387982, lng: 21.419568 },
    { lat: 49.388798, lng: 21.421642 },
    { lat: 49.389438, lng: 21.424304 },
    { lat: 49.389711, lng: 21.425168 },
    { lat: 49.390309, lng: 21.42706 },
    { lat: 49.391201, lng: 21.428566 },
    { lat: 49.392196, lng: 21.430139 },
    { lat: 49.393267, lng: 21.43196 },
    { lat: 49.393862, lng: 21.432713 },
    { lat: 49.394211, lng: 21.433154 },
    { lat: 49.394485, lng: 21.433455 },
    { lat: 49.394689, lng: 21.433678 },
    { lat: 49.39486, lng: 21.433865 },
    { lat: 49.394872, lng: 21.433879 },
    { lat: 49.394894, lng: 21.433903 },
    { lat: 49.394968, lng: 21.433983 },
    { lat: 49.394987, lng: 21.434005 },
    { lat: 49.395475, lng: 21.434573 },
    { lat: 49.395555, lng: 21.434666 },
    { lat: 49.396613, lng: 21.435897 },
    { lat: 49.397089, lng: 21.436526 },
    { lat: 49.397725, lng: 21.437364 },
    { lat: 49.39817, lng: 21.438104 },
    { lat: 49.398603, lng: 21.43814 },
    { lat: 49.399367, lng: 21.438767 },
    { lat: 49.400318, lng: 21.439439 },
    { lat: 49.400318, lng: 21.439744 },
    { lat: 49.401085, lng: 21.440148 },
    { lat: 49.403183, lng: 21.440249 },
    { lat: 49.40339, lng: 21.440103 },
    { lat: 49.403547, lng: 21.439992 },
    { lat: 49.404751, lng: 21.440372 },
    { lat: 49.404724, lng: 21.4405 },
    { lat: 49.406293, lng: 21.441808 },
    { lat: 49.408634, lng: 21.442956 },
    { lat: 49.40973, lng: 21.442978 },
    { lat: 49.40977, lng: 21.442978 },
    { lat: 49.41007, lng: 21.442984 },
    { lat: 49.410442, lng: 21.443164 },
    { lat: 49.4113, lng: 21.443579 },
    { lat: 49.411471, lng: 21.443662 },
    { lat: 49.411514, lng: 21.443682 },
    { lat: 49.4122501, lng: 21.4448414 },
    { lat: 49.4124291, lng: 21.4441864 },
    { lat: 49.4124068, lng: 21.4439411 },
    { lat: 49.4124676, lng: 21.4435027 },
    { lat: 49.412469, lng: 21.443473 },
    { lat: 49.4124647, lng: 21.4426482 },
    { lat: 49.412763, lng: 21.4413601 },
    { lat: 49.4127972, lng: 21.4403818 },
    { lat: 49.4129031, lng: 21.4397015 },
    { lat: 49.4128727, lng: 21.4385294 },
    { lat: 49.4127691, lng: 21.438193 },
    { lat: 49.412757, lng: 21.437996 },
    { lat: 49.4126706, lng: 21.4363956 },
    { lat: 49.412667, lng: 21.436365 },
    { lat: 49.41271, lng: 21.43612 },
    { lat: 49.412749, lng: 21.435799 },
    { lat: 49.412756, lng: 21.43569 },
    { lat: 49.4127697, lng: 21.4356777 },
    { lat: 49.412755, lng: 21.435595 },
    { lat: 49.4127206, lng: 21.435374 },
    { lat: 49.412284, lng: 21.4340831 },
    { lat: 49.412613, lng: 21.4329629 },
    { lat: 49.4134491, lng: 21.4314415 },
    { lat: 49.413451, lng: 21.43143 },
    { lat: 49.413522, lng: 21.431213 },
    { lat: 49.4136458, lng: 21.4308562 },
    { lat: 49.413657, lng: 21.43082 },
    { lat: 49.4137888, lng: 21.4305513 },
    { lat: 49.413792, lng: 21.43054 },
    { lat: 49.4139781, lng: 21.4302796 },
    { lat: 49.414164, lng: 21.430165 },
    { lat: 49.4123005, lng: 21.4283174 },
    { lat: 49.4122138, lng: 21.4283591 },
    { lat: 49.4115869, lng: 21.4275275 },
    { lat: 49.4116111, lng: 21.4273932 },
    { lat: 49.4112884, lng: 21.4269712 },
    { lat: 49.4100128, lng: 21.4261256 },
    { lat: 49.4096203, lng: 21.4260916 },
    { lat: 49.4091706, lng: 21.4258271 },
    { lat: 49.4083459, lng: 21.4255881 },
    { lat: 49.407478, lng: 21.4255757 },
    { lat: 49.4068423, lng: 21.4254178 },
    { lat: 49.4060345, lng: 21.4249487 },
    { lat: 49.404005, lng: 21.4246788 },
    { lat: 49.4035594, lng: 21.4245564 },
    { lat: 49.403568, lng: 21.4243072 },
    { lat: 49.4033894, lng: 21.424012 },
    { lat: 49.4032348, lng: 21.4241923 },
    { lat: 49.4028476, lng: 21.4236765 },
    { lat: 49.4013962, lng: 21.4220463 },
    { lat: 49.4012334, lng: 21.4220173 },
    { lat: 49.4006437, lng: 21.4211924 },
    { lat: 49.3978578, lng: 21.4154952 },
    { lat: 49.3960975, lng: 21.4127191 },
    { lat: 49.3957305, lng: 21.4115527 },
    { lat: 49.3948365, lng: 21.4100225 },
    { lat: 49.3939338, lng: 21.4079999 },
    { lat: 49.3927637, lng: 21.4098472 },
    { lat: 49.3925272, lng: 21.4104618 },
    { lat: 49.3916561, lng: 21.4108331 },
    { lat: 49.3913724, lng: 21.4113628 },
    { lat: 49.3902822, lng: 21.4115533 },
    { lat: 49.386734, lng: 21.4141809 },
    { lat: 49.38494, lng: 21.415344 },
  ],
  Hrabské: [
    { lat: 49.3216516, lng: 21.0794036 },
    { lat: 49.3220952, lng: 21.0797672 },
    { lat: 49.3226095, lng: 21.0801885 },
    { lat: 49.3230376, lng: 21.0804487 },
    { lat: 49.3231984, lng: 21.0804471 },
    { lat: 49.3233292, lng: 21.0803503 },
    { lat: 49.3240294, lng: 21.0808833 },
    { lat: 49.324044, lng: 21.0812358 },
    { lat: 49.32491, lng: 21.0822737 },
    { lat: 49.3252054, lng: 21.0821881 },
    { lat: 49.3252726, lng: 21.0823898 },
    { lat: 49.3257593, lng: 21.0826895 },
    { lat: 49.3259415, lng: 21.0824757 },
    { lat: 49.3261323, lng: 21.0827774 },
    { lat: 49.3264515, lng: 21.083571 },
    { lat: 49.3265192, lng: 21.0841031 },
    { lat: 49.3264107, lng: 21.084302 },
    { lat: 49.3268096, lng: 21.0851607 },
    { lat: 49.327355, lng: 21.0860309 },
    { lat: 49.3276165, lng: 21.0862606 },
    { lat: 49.3281955, lng: 21.0865946 },
    { lat: 49.3296949, lng: 21.0879508 },
    { lat: 49.3304829, lng: 21.0884882 },
    { lat: 49.3315023, lng: 21.0896773 },
    { lat: 49.3322223, lng: 21.0908055 },
    { lat: 49.3329055, lng: 21.091266 },
    { lat: 49.3342488, lng: 21.0926684 },
    { lat: 49.3345222, lng: 21.0928421 },
    { lat: 49.3351211, lng: 21.0935555 },
    { lat: 49.3355969, lng: 21.0943621 },
    { lat: 49.3361065, lng: 21.0950637 },
    { lat: 49.3361431, lng: 21.0952216 },
    { lat: 49.336301, lng: 21.0953747 },
    { lat: 49.3363468, lng: 21.0953308 },
    { lat: 49.3364544, lng: 21.0954227 },
    { lat: 49.3366986, lng: 21.095354 },
    { lat: 49.3368602, lng: 21.0954069 },
    { lat: 49.3373994, lng: 21.0953468 },
    { lat: 49.3375551, lng: 21.0951782 },
    { lat: 49.337772, lng: 21.0958072 },
    { lat: 49.3380224, lng: 21.0967808 },
    { lat: 49.3382503, lng: 21.0965866 },
    { lat: 49.3385732, lng: 21.0966192 },
    { lat: 49.3390306, lng: 21.0972378 },
    { lat: 49.3394694, lng: 21.0975558 },
    { lat: 49.3397368, lng: 21.0976073 },
    { lat: 49.3399079, lng: 21.0982127 },
    { lat: 49.3404885, lng: 21.0984223 },
    { lat: 49.3407293, lng: 21.0986021 },
    { lat: 49.3409874, lng: 21.0983328 },
    { lat: 49.3410422, lng: 21.0981125 },
    { lat: 49.3411799, lng: 21.0979795 },
    { lat: 49.3412066, lng: 21.0978332 },
    { lat: 49.3412892, lng: 21.0978137 },
    { lat: 49.3413119, lng: 21.0975991 },
    { lat: 49.3417172, lng: 21.0972967 },
    { lat: 49.3424533, lng: 21.0960964 },
    { lat: 49.34279, lng: 21.0956447 },
    { lat: 49.3428919, lng: 21.0954162 },
    { lat: 49.3446763, lng: 21.0939625 },
    { lat: 49.3448709, lng: 21.0936455 },
    { lat: 49.3459683, lng: 21.0929825 },
    { lat: 49.3465911, lng: 21.0922953 },
    { lat: 49.3466074, lng: 21.0922075 },
    { lat: 49.3467773, lng: 21.092206 },
    { lat: 49.346999, lng: 21.0919241 },
    { lat: 49.3472946, lng: 21.0917006 },
    { lat: 49.3473021, lng: 21.0915803 },
    { lat: 49.3473604, lng: 21.0915088 },
    { lat: 49.3476224, lng: 21.0914282 },
    { lat: 49.3478359, lng: 21.0909975 },
    { lat: 49.348389, lng: 21.0902019 },
    { lat: 49.348536, lng: 21.0902645 },
    { lat: 49.348653, lng: 21.0902287 },
    { lat: 49.3487876, lng: 21.0900353 },
    { lat: 49.3489473, lng: 21.0896472 },
    { lat: 49.3492035, lng: 21.0893848 },
    { lat: 49.3492574, lng: 21.0891025 },
    { lat: 49.3495334, lng: 21.0889721 },
    { lat: 49.3496776, lng: 21.0885928 },
    { lat: 49.3498223, lng: 21.0886477 },
    { lat: 49.3499641, lng: 21.0884364 },
    { lat: 49.3501579, lng: 21.0883414 },
    { lat: 49.3502616, lng: 21.0880568 },
    { lat: 49.3504592, lng: 21.0877269 },
    { lat: 49.3506584, lng: 21.0877312 },
    { lat: 49.3509062, lng: 21.0874939 },
    { lat: 49.3510264, lng: 21.0870269 },
    { lat: 49.3512326, lng: 21.0872099 },
    { lat: 49.3513623, lng: 21.087149 },
    { lat: 49.3514931, lng: 21.0871915 },
    { lat: 49.3515648, lng: 21.0871483 },
    { lat: 49.3516662, lng: 21.0868682 },
    { lat: 49.3520811, lng: 21.086827 },
    { lat: 49.3521257, lng: 21.0866388 },
    { lat: 49.3526606, lng: 21.0868984 },
    { lat: 49.3527685, lng: 21.0868239 },
    { lat: 49.3528295, lng: 21.0866208 },
    { lat: 49.352902, lng: 21.0865952 },
    { lat: 49.3530265, lng: 21.0866905 },
    { lat: 49.3531935, lng: 21.0869516 },
    { lat: 49.3533167, lng: 21.0867919 },
    { lat: 49.3533728, lng: 21.0867913 },
    { lat: 49.3534703, lng: 21.0868847 },
    { lat: 49.3535282, lng: 21.0870735 },
    { lat: 49.3537761, lng: 21.0871921 },
    { lat: 49.3541944, lng: 21.0881314 },
    { lat: 49.354161, lng: 21.0882819 },
    { lat: 49.3542899, lng: 21.0884466 },
    { lat: 49.3543667, lng: 21.0888433 },
    { lat: 49.354543, lng: 21.0890056 },
    { lat: 49.3547052, lng: 21.08901 },
    { lat: 49.3548092, lng: 21.0892022 },
    { lat: 49.3550059, lng: 21.0893377 },
    { lat: 49.3551224, lng: 21.0892878 },
    { lat: 49.355328, lng: 21.0894118 },
    { lat: 49.3554369, lng: 21.0892336 },
    { lat: 49.3557521, lng: 21.0890609 },
    { lat: 49.3557963, lng: 21.0889248 },
    { lat: 49.3559284, lng: 21.0888362 },
    { lat: 49.3560028, lng: 21.0886649 },
    { lat: 49.3561375, lng: 21.0886209 },
    { lat: 49.356183, lng: 21.0884966 },
    { lat: 49.3563425, lng: 21.0883432 },
    { lat: 49.3565368, lng: 21.0879467 },
    { lat: 49.3566535, lng: 21.0879654 },
    { lat: 49.3567594, lng: 21.0875456 },
    { lat: 49.3570625, lng: 21.0874251 },
    { lat: 49.3570424, lng: 21.0871793 },
    { lat: 49.3571037, lng: 21.0870246 },
    { lat: 49.3572187, lng: 21.0871687 },
    { lat: 49.3574432, lng: 21.0870807 },
    { lat: 49.3574632, lng: 21.0869984 },
    { lat: 49.3576425, lng: 21.0869059 },
    { lat: 49.3576907, lng: 21.0867983 },
    { lat: 49.3579417, lng: 21.0866611 },
    { lat: 49.3581605, lng: 21.0866765 },
    { lat: 49.3584104, lng: 21.0864986 },
    { lat: 49.3586446, lng: 21.0864316 },
    { lat: 49.3588934, lng: 21.0862271 },
    { lat: 49.3589671, lng: 21.0862457 },
    { lat: 49.3590507, lng: 21.086116 },
    { lat: 49.360179, lng: 21.0856073 },
    { lat: 49.3603562, lng: 21.0855905 },
    { lat: 49.3610379, lng: 21.086012 },
    { lat: 49.3610294, lng: 21.0862029 },
    { lat: 49.3612452, lng: 21.0877098 },
    { lat: 49.3611911, lng: 21.0883038 },
    { lat: 49.36125, lng: 21.0893004 },
    { lat: 49.3611809, lng: 21.0894998 },
    { lat: 49.3612422, lng: 21.0898678 },
    { lat: 49.3612357, lng: 21.090121 },
    { lat: 49.3614301, lng: 21.090783 },
    { lat: 49.3618062, lng: 21.0915368 },
    { lat: 49.362359, lng: 21.0910168 },
    { lat: 49.362681, lng: 21.0908071 },
    { lat: 49.3633755, lng: 21.0898995 },
    { lat: 49.3635722, lng: 21.0897538 },
    { lat: 49.3641072, lng: 21.0888866 },
    { lat: 49.3645265, lng: 21.0872497 },
    { lat: 49.3651015, lng: 21.0860593 },
    { lat: 49.3651548, lng: 21.085946 },
    { lat: 49.364686, lng: 21.085357 },
    { lat: 49.364633, lng: 21.085295 },
    { lat: 49.364223, lng: 21.0847765 },
    { lat: 49.364019, lng: 21.083881 },
    { lat: 49.3637844, lng: 21.0828448 },
    { lat: 49.364062, lng: 21.081691 },
    { lat: 49.3640683, lng: 21.081665 },
    { lat: 49.364014, lng: 21.080541 },
    { lat: 49.3639247, lng: 21.0789581 },
    { lat: 49.363919, lng: 21.078951 },
    { lat: 49.3636513, lng: 21.0785479 },
    { lat: 49.3630498, lng: 21.0779691 },
    { lat: 49.362595, lng: 21.077638 },
    { lat: 49.36258, lng: 21.0776277 },
    { lat: 49.362572, lng: 21.077627 },
    { lat: 49.3619877, lng: 21.0776512 },
    { lat: 49.3612951, lng: 21.0780813 },
    { lat: 49.3606243, lng: 21.0777397 },
    { lat: 49.3603953, lng: 21.077404 },
    { lat: 49.3600928, lng: 21.0770547 },
    { lat: 49.3600653, lng: 21.0770443 },
    { lat: 49.360028, lng: 21.076961 },
    { lat: 49.359992, lng: 21.076878 },
    { lat: 49.3593162, lng: 21.0753304 },
    { lat: 49.3595288, lng: 21.0748083 },
    { lat: 49.3596589, lng: 21.0743559 },
    { lat: 49.3601608, lng: 21.0736084 },
    { lat: 49.360164, lng: 21.073604 },
    { lat: 49.3605524, lng: 21.0731548 },
    { lat: 49.3609869, lng: 21.0730277 },
    { lat: 49.3611545, lng: 21.0728747 },
    { lat: 49.3614942, lng: 21.0725948 },
    { lat: 49.3617195, lng: 21.0721357 },
    { lat: 49.3620178, lng: 21.0716805 },
    { lat: 49.3626481, lng: 21.0708566 },
    { lat: 49.3629057, lng: 21.0702925 },
    { lat: 49.36291, lng: 21.070282 },
    { lat: 49.363217, lng: 21.0698 },
    { lat: 49.363695, lng: 21.069037 },
    { lat: 49.3636995, lng: 21.069032 },
    { lat: 49.3644115, lng: 21.0682508 },
    { lat: 49.3648666, lng: 21.067238 },
    { lat: 49.3651693, lng: 21.0666513 },
    { lat: 49.3654833, lng: 21.0663099 },
    { lat: 49.3655316, lng: 21.066206 },
    { lat: 49.3653475, lng: 21.0659764 },
    { lat: 49.3651047, lng: 21.0653947 },
    { lat: 49.3647962, lng: 21.0643974 },
    { lat: 49.3645827, lng: 21.0641567 },
    { lat: 49.3644966, lng: 21.0637432 },
    { lat: 49.3644825, lng: 21.0633394 },
    { lat: 49.364496, lng: 21.063086 },
    { lat: 49.364499, lng: 21.063018 },
    { lat: 49.3645277, lng: 21.0625027 },
    { lat: 49.3643782, lng: 21.0620918 },
    { lat: 49.364053, lng: 21.0616068 },
    { lat: 49.3638641, lng: 21.0604591 },
    { lat: 49.3636538, lng: 21.0600448 },
    { lat: 49.3634413, lng: 21.059405 },
    { lat: 49.3628644, lng: 21.0581078 },
    { lat: 49.3627072, lng: 21.0569367 },
    { lat: 49.3626341, lng: 21.0566197 },
    { lat: 49.3622512, lng: 21.0558284 },
    { lat: 49.3621033, lng: 21.0551829 },
    { lat: 49.3618332, lng: 21.054447 },
    { lat: 49.362618, lng: 21.053192 },
    { lat: 49.3631588, lng: 21.0523165 },
    { lat: 49.363212, lng: 21.052224 },
    { lat: 49.3638259, lng: 21.0501331 },
    { lat: 49.3631089, lng: 21.0497458 },
    { lat: 49.3620652, lng: 21.0505287 },
    { lat: 49.3609268, lng: 21.0489371 },
    { lat: 49.3603416, lng: 21.0492164 },
    { lat: 49.3603978, lng: 21.0503292 },
    { lat: 49.3602755, lng: 21.0507258 },
    { lat: 49.3596961, lng: 21.0509006 },
    { lat: 49.3592475, lng: 21.0494873 },
    { lat: 49.3571967, lng: 21.0508975 },
    { lat: 49.35669, lng: 21.0499846 },
    { lat: 49.3563517, lng: 21.0497517 },
    { lat: 49.3559983, lng: 21.0498503 },
    { lat: 49.3558788, lng: 21.0521104 },
    { lat: 49.3538936, lng: 21.0516233 },
    { lat: 49.3530881, lng: 21.0517979 },
    { lat: 49.3514132, lng: 21.0527268 },
    { lat: 49.3493567, lng: 21.053516 },
    { lat: 49.3473494, lng: 21.0545283 },
    { lat: 49.3471856, lng: 21.0552091 },
    { lat: 49.346179, lng: 21.0551245 },
    { lat: 49.3441677, lng: 21.055455 },
    { lat: 49.3435199, lng: 21.0563772 },
    { lat: 49.3428805, lng: 21.0565771 },
    { lat: 49.3423594, lng: 21.0565799 },
    { lat: 49.3419967, lng: 21.0569516 },
    { lat: 49.341591, lng: 21.0567949 },
    { lat: 49.3413113, lng: 21.0571877 },
    { lat: 49.3405952, lng: 21.0594885 },
    { lat: 49.3405528, lng: 21.0597392 },
    { lat: 49.3397942, lng: 21.0599808 },
    { lat: 49.3393408, lng: 21.0600558 },
    { lat: 49.3390011, lng: 21.0602326 },
    { lat: 49.3386939, lng: 21.0602998 },
    { lat: 49.3373774, lng: 21.0613542 },
    { lat: 49.335669, lng: 21.0631662 },
    { lat: 49.3336368, lng: 21.0640445 },
    { lat: 49.3323216, lng: 21.0642983 },
    { lat: 49.3321865, lng: 21.0646133 },
    { lat: 49.3314959, lng: 21.0646512 },
    { lat: 49.3306061, lng: 21.0650812 },
    { lat: 49.3299506, lng: 21.0654837 },
    { lat: 49.3294197, lng: 21.0658412 },
    { lat: 49.3288296, lng: 21.0664246 },
    { lat: 49.328527, lng: 21.0663193 },
    { lat: 49.3281903, lng: 21.0666271 },
    { lat: 49.3279014, lng: 21.0663934 },
    { lat: 49.3275503, lng: 21.0663552 },
    { lat: 49.3274931, lng: 21.0665439 },
    { lat: 49.3273226, lng: 21.0664182 },
    { lat: 49.3272881, lng: 21.0661809 },
    { lat: 49.3271879, lng: 21.0663926 },
    { lat: 49.3265638, lng: 21.0659493 },
    { lat: 49.3264334, lng: 21.0655388 },
    { lat: 49.3262001, lng: 21.0658049 },
    { lat: 49.3255687, lng: 21.0647806 },
    { lat: 49.3255611, lng: 21.0646856 },
    { lat: 49.3253188, lng: 21.0649895 },
    { lat: 49.3251102, lng: 21.0646968 },
    { lat: 49.3249211, lng: 21.0647651 },
    { lat: 49.3247513, lng: 21.0646989 },
    { lat: 49.3239489, lng: 21.0648379 },
    { lat: 49.3236134, lng: 21.0650825 },
    { lat: 49.3233792, lng: 21.0651474 },
    { lat: 49.3231784, lng: 21.0654071 },
    { lat: 49.3230252, lng: 21.0654753 },
    { lat: 49.3227299, lng: 21.0659488 },
    { lat: 49.3225473, lng: 21.0660126 },
    { lat: 49.3213745, lng: 21.0670485 },
    { lat: 49.3204644, lng: 21.0685274 },
    { lat: 49.3203132, lng: 21.06838 },
    { lat: 49.320125, lng: 21.0690009 },
    { lat: 49.319348, lng: 21.07028 },
    { lat: 49.3192482, lng: 21.0702565 },
    { lat: 49.3189958, lng: 21.0726608 },
    { lat: 49.317883, lng: 21.0741003 },
    { lat: 49.3177567, lng: 21.0752488 },
    { lat: 49.3174889, lng: 21.0757093 },
    { lat: 49.3182252, lng: 21.0760714 },
    { lat: 49.3186833, lng: 21.0770932 },
    { lat: 49.3192954, lng: 21.0776205 },
    { lat: 49.3199312, lng: 21.0789183 },
    { lat: 49.3205157, lng: 21.0792103 },
    { lat: 49.3207638, lng: 21.0794788 },
    { lat: 49.3216516, lng: 21.0794036 },
  ],
  Buclovany: [
    { lat: 49.1513045, lng: 21.3496578 },
    { lat: 49.151324, lng: 21.3503508 },
    { lat: 49.1512138, lng: 21.3508782 },
    { lat: 49.1508101, lng: 21.351986 },
    { lat: 49.1502251, lng: 21.352814 },
    { lat: 49.1496571, lng: 21.3530115 },
    { lat: 49.1491075, lng: 21.3529949 },
    { lat: 49.1488282, lng: 21.3527935 },
    { lat: 49.148657, lng: 21.3523349 },
    { lat: 49.1483842, lng: 21.3522449 },
    { lat: 49.1481925, lng: 21.3517791 },
    { lat: 49.1480324, lng: 21.351692 },
    { lat: 49.1475612, lng: 21.350755 },
    { lat: 49.1461201, lng: 21.3519309 },
    { lat: 49.1465608, lng: 21.3547504 },
    { lat: 49.1473542, lng: 21.3584629 },
    { lat: 49.1461432, lng: 21.358566 },
    { lat: 49.1457741, lng: 21.3584902 },
    { lat: 49.1462371, lng: 21.3596791 },
    { lat: 49.146537, lng: 21.3600742 },
    { lat: 49.1468174, lng: 21.3611869 },
    { lat: 49.1471399, lng: 21.3614702 },
    { lat: 49.1472679, lng: 21.3619537 },
    { lat: 49.146428, lng: 21.3626152 },
    { lat: 49.1479513, lng: 21.3642805 },
    { lat: 49.1478481, lng: 21.3660307 },
    { lat: 49.1469159, lng: 21.3674946 },
    { lat: 49.1456793, lng: 21.3676776 },
    { lat: 49.1464918, lng: 21.3693147 },
    { lat: 49.1471725, lng: 21.3708269 },
    { lat: 49.1478947, lng: 21.3738965 },
    { lat: 49.1482165, lng: 21.3737853 },
    { lat: 49.1484272, lng: 21.3751991 },
    { lat: 49.1484721, lng: 21.3760697 },
    { lat: 49.1481439, lng: 21.3775292 },
    { lat: 49.1479149, lng: 21.3799807 },
    { lat: 49.1476943, lng: 21.3804678 },
    { lat: 49.1475452, lng: 21.3814057 },
    { lat: 49.1474078, lng: 21.3827348 },
    { lat: 49.1471396, lng: 21.3832169 },
    { lat: 49.1470692, lng: 21.3839919 },
    { lat: 49.1470891, lng: 21.3846285 },
    { lat: 49.147841, lng: 21.3839498 },
    { lat: 49.148385, lng: 21.3829769 },
    { lat: 49.1487542, lng: 21.3824501 },
    { lat: 49.150054, lng: 21.3839424 },
    { lat: 49.1516938, lng: 21.3859448 },
    { lat: 49.152467, lng: 21.3870517 },
    { lat: 49.1533176, lng: 21.38809 },
    { lat: 49.1529277, lng: 21.3887544 },
    { lat: 49.1538792, lng: 21.389654 },
    { lat: 49.1532129, lng: 21.3916299 },
    { lat: 49.1528128, lng: 21.3923345 },
    { lat: 49.152893, lng: 21.3924228 },
    { lat: 49.1536305, lng: 21.3932411 },
    { lat: 49.1538385, lng: 21.3923955 },
    { lat: 49.1544992, lng: 21.3905724 },
    { lat: 49.1545278, lng: 21.3903191 },
    { lat: 49.1550836, lng: 21.3890981 },
    { lat: 49.1551682, lng: 21.3891458 },
    { lat: 49.1555295, lng: 21.3873187 },
    { lat: 49.1557466, lng: 21.385294 },
    { lat: 49.1555252, lng: 21.3832829 },
    { lat: 49.1557058, lng: 21.3808333 },
    { lat: 49.156008, lng: 21.3808553 },
    { lat: 49.1559359, lng: 21.3829057 },
    { lat: 49.1560407, lng: 21.3829118 },
    { lat: 49.1569484, lng: 21.3821758 },
    { lat: 49.1565086, lng: 21.379286 },
    { lat: 49.1564803, lng: 21.3788623 },
    { lat: 49.1564836, lng: 21.3769278 },
    { lat: 49.1568198, lng: 21.3758521 },
    { lat: 49.1569583, lng: 21.3758943 },
    { lat: 49.1581498, lng: 21.3750945 },
    { lat: 49.1584145, lng: 21.374798 },
    { lat: 49.1592851, lng: 21.3731204 },
    { lat: 49.1594746, lng: 21.3733252 },
    { lat: 49.1603481, lng: 21.3716318 },
    { lat: 49.1608581, lng: 21.3723628 },
    { lat: 49.1621177, lng: 21.369456 },
    { lat: 49.1631889, lng: 21.3677496 },
    { lat: 49.1640563, lng: 21.3691861 },
    { lat: 49.1642031, lng: 21.3686744 },
    { lat: 49.1648348, lng: 21.3672546 },
    { lat: 49.1649004, lng: 21.3668125 },
    { lat: 49.1651044, lng: 21.3666694 },
    { lat: 49.1660004, lng: 21.3653549 },
    { lat: 49.167545, lng: 21.363681 },
    { lat: 49.1682227, lng: 21.3642221 },
    { lat: 49.1692013, lng: 21.3645151 },
    { lat: 49.1702509, lng: 21.3643992 },
    { lat: 49.1718492, lng: 21.3651784 },
    { lat: 49.1735877, lng: 21.3657474 },
    { lat: 49.1742693, lng: 21.3641487 },
    { lat: 49.1734795, lng: 21.36347 },
    { lat: 49.1726982, lng: 21.3630737 },
    { lat: 49.1701386, lng: 21.3621682 },
    { lat: 49.1700083, lng: 21.3615764 },
    { lat: 49.1698606, lng: 21.3612619 },
    { lat: 49.1698888, lng: 21.3607267 },
    { lat: 49.1698549, lng: 21.3605791 },
    { lat: 49.1697292, lng: 21.3605453 },
    { lat: 49.1696164, lng: 21.3603119 },
    { lat: 49.1696461, lng: 21.3599481 },
    { lat: 49.1693203, lng: 21.3598541 },
    { lat: 49.1697885, lng: 21.3576967 },
    { lat: 49.1692386, lng: 21.3560857 },
    { lat: 49.1677131, lng: 21.3571114 },
    { lat: 49.1675691, lng: 21.3572965 },
    { lat: 49.1670347, lng: 21.3574506 },
    { lat: 49.1668097, lng: 21.3578283 },
    { lat: 49.1665625, lng: 21.3578008 },
    { lat: 49.1664128, lng: 21.3579118 },
    { lat: 49.1658479, lng: 21.358597 },
    { lat: 49.1656366, lng: 21.358 },
    { lat: 49.1651339, lng: 21.3590307 },
    { lat: 49.1647228, lng: 21.3591674 },
    { lat: 49.1645243, lng: 21.3597531 },
    { lat: 49.1642807, lng: 21.3600367 },
    { lat: 49.1639886, lng: 21.3598631 },
    { lat: 49.1633361, lng: 21.3589961 },
    { lat: 49.1625545, lng: 21.357117 },
    { lat: 49.1617178, lng: 21.3556229 },
    { lat: 49.1585398, lng: 21.3536779 },
    { lat: 49.1578875, lng: 21.3534574 },
    { lat: 49.1579211, lng: 21.3529953 },
    { lat: 49.1576624, lng: 21.3529302 },
    { lat: 49.157451, lng: 21.3527435 },
    { lat: 49.1572836, lng: 21.3527511 },
    { lat: 49.1570595, lng: 21.3525884 },
    { lat: 49.1571408, lng: 21.351963 },
    { lat: 49.157259, lng: 21.351476 },
    { lat: 49.1570641, lng: 21.3513906 },
    { lat: 49.1569119, lng: 21.3511179 },
    { lat: 49.1556511, lng: 21.3507199 },
    { lat: 49.1546356, lng: 21.3505861 },
    { lat: 49.1531538, lng: 21.3501277 },
    { lat: 49.1513045, lng: 21.3496578 },
  ],
  Fričkovce: [
    { lat: 49.1882146, lng: 21.2474683 },
    { lat: 49.1885609, lng: 21.2468047 },
    { lat: 49.1886084, lng: 21.246847 },
    { lat: 49.1903087, lng: 21.2467374 },
    { lat: 49.191732, lng: 21.2464012 },
    { lat: 49.1920654, lng: 21.2462346 },
    { lat: 49.1925942, lng: 21.2459709 },
    { lat: 49.1931378, lng: 21.2451654 },
    { lat: 49.1931246, lng: 21.244853 },
    { lat: 49.1932157, lng: 21.2447004 },
    { lat: 49.1931594, lng: 21.2444411 },
    { lat: 49.1932212, lng: 21.2443341 },
    { lat: 49.1932761, lng: 21.2443946 },
    { lat: 49.1933523, lng: 21.2443514 },
    { lat: 49.1934145, lng: 21.2441016 },
    { lat: 49.193475, lng: 21.2440922 },
    { lat: 49.1935878, lng: 21.2438888 },
    { lat: 49.193485, lng: 21.243795 },
    { lat: 49.1935587, lng: 21.2435606 },
    { lat: 49.1937567, lng: 21.2434436 },
    { lat: 49.1938627, lng: 21.2430194 },
    { lat: 49.1941759, lng: 21.2424465 },
    { lat: 49.1942039, lng: 21.2419214 },
    { lat: 49.19433, lng: 21.2413065 },
    { lat: 49.1945096, lng: 21.2397531 },
    { lat: 49.1947905, lng: 21.239189 },
    { lat: 49.1947628, lng: 21.2391705 },
    { lat: 49.1948693, lng: 21.2387535 },
    { lat: 49.1953655, lng: 21.2369921 },
    { lat: 49.1957491, lng: 21.2358586 },
    { lat: 49.1958281, lng: 21.2352845 },
    { lat: 49.1960027, lng: 21.2345593 },
    { lat: 49.196233, lng: 21.2342132 },
    { lat: 49.1965631, lng: 21.23353 },
    { lat: 49.1966323, lng: 21.2331516 },
    { lat: 49.1967308, lng: 21.2320011 },
    { lat: 49.1967465, lng: 21.2310491 },
    { lat: 49.1966591, lng: 21.2306589 },
    { lat: 49.1961326, lng: 21.2290542 },
    { lat: 49.1960562, lng: 21.2286179 },
    { lat: 49.1961346, lng: 21.2285 },
    { lat: 49.1962059, lng: 21.2280038 },
    { lat: 49.1962796, lng: 21.2279114 },
    { lat: 49.1965929, lng: 21.2262864 },
    { lat: 49.1966886, lng: 21.2255912 },
    { lat: 49.1966651, lng: 21.2250493 },
    { lat: 49.1965856, lng: 21.2245898 },
    { lat: 49.1963695, lng: 21.22393 },
    { lat: 49.1962932, lng: 21.2232835 },
    { lat: 49.1961132, lng: 21.2224794 },
    { lat: 49.1960622, lng: 21.2216685 },
    { lat: 49.1959377, lng: 21.2213936 },
    { lat: 49.1957762, lng: 21.2207896 },
    { lat: 49.1955205, lng: 21.2201989 },
    { lat: 49.1956475, lng: 21.2199767 },
    { lat: 49.195596, lng: 21.2197653 },
    { lat: 49.1953856, lng: 21.2193311 },
    { lat: 49.1952902, lng: 21.2188993 },
    { lat: 49.1954068, lng: 21.2185952 },
    { lat: 49.1955738, lng: 21.2175143 },
    { lat: 49.1955844, lng: 21.2166997 },
    { lat: 49.1954064, lng: 21.2150553 },
    { lat: 49.1952366, lng: 21.2142537 },
    { lat: 49.1950076, lng: 21.2135438 },
    { lat: 49.1948142, lng: 21.2127195 },
    { lat: 49.1947673, lng: 21.2117835 },
    { lat: 49.194459, lng: 21.2106918 },
    { lat: 49.1936809, lng: 21.2072625 },
    { lat: 49.1930475, lng: 21.2048344 },
    { lat: 49.1928247, lng: 21.2041447 },
    { lat: 49.1924869, lng: 21.2033413 },
    { lat: 49.1924639, lng: 21.2025349 },
    { lat: 49.1919098, lng: 21.2020414 },
    { lat: 49.1913609, lng: 21.2013274 },
    { lat: 49.1913879, lng: 21.2009304 },
    { lat: 49.1912655, lng: 21.2008317 },
    { lat: 49.1912752, lng: 21.2004914 },
    { lat: 49.1906428, lng: 21.1998364 },
    { lat: 49.1901664, lng: 21.1991661 },
    { lat: 49.189972, lng: 21.1986337 },
    { lat: 49.1895764, lng: 21.1982929 },
    { lat: 49.1894356, lng: 21.1981027 },
    { lat: 49.1893898, lng: 21.1978963 },
    { lat: 49.1894673, lng: 21.1971668 },
    { lat: 49.1890663, lng: 21.195676 },
    { lat: 49.1888895, lng: 21.1952689 },
    { lat: 49.1887807, lng: 21.1946275 },
    { lat: 49.1887291, lng: 21.193842 },
    { lat: 49.1887341, lng: 21.1922688 },
    { lat: 49.1884075, lng: 21.1917921 },
    { lat: 49.187974, lng: 21.1915303 },
    { lat: 49.1877641, lng: 21.1911495 },
    { lat: 49.1874657, lng: 21.1901624 },
    { lat: 49.1872959, lng: 21.1893391 },
    { lat: 49.187275, lng: 21.1883797 },
    { lat: 49.1871772, lng: 21.1876616 },
    { lat: 49.1869382, lng: 21.1872872 },
    { lat: 49.1866462, lng: 21.1870138 },
    { lat: 49.1865229, lng: 21.1867814 },
    { lat: 49.1865252, lng: 21.1864528 },
    { lat: 49.186385, lng: 21.1858212 },
    { lat: 49.1862206, lng: 21.1853504 },
    { lat: 49.1853256, lng: 21.1837528 },
    { lat: 49.1850803, lng: 21.1832076 },
    { lat: 49.1850083, lng: 21.18281 },
    { lat: 49.1844208, lng: 21.1826635 },
    { lat: 49.1837454, lng: 21.1817572 },
    { lat: 49.1832567, lng: 21.1809895 },
    { lat: 49.1848181, lng: 21.1788914 },
    { lat: 49.18459, lng: 21.178261 },
    { lat: 49.184125, lng: 21.179342 },
    { lat: 49.183711, lng: 21.179776 },
    { lat: 49.183235, lng: 21.180123 },
    { lat: 49.182083, lng: 21.18073 },
    { lat: 49.181824, lng: 21.180717 },
    { lat: 49.180857, lng: 21.181082 },
    { lat: 49.180513, lng: 21.181119 },
    { lat: 49.180199, lng: 21.181206 },
    { lat: 49.180095, lng: 21.181306 },
    { lat: 49.179952, lng: 21.181635 },
    { lat: 49.179733, lng: 21.181934 },
    { lat: 49.179354, lng: 21.182227 },
    { lat: 49.179014, lng: 21.182679 },
    { lat: 49.178898, lng: 21.182884 },
    { lat: 49.178646, lng: 21.183208 },
    { lat: 49.178164, lng: 21.183722 },
    { lat: 49.178034, lng: 21.184024 },
    { lat: 49.177751, lng: 21.184712 },
    { lat: 49.177413, lng: 21.185709 },
    { lat: 49.177216, lng: 21.186902 },
    { lat: 49.177097, lng: 21.187231 },
    { lat: 49.176981, lng: 21.187699 },
    { lat: 49.176873, lng: 21.188123 },
    { lat: 49.176867, lng: 21.188159 },
    { lat: 49.176854, lng: 21.188777 },
    { lat: 49.176815, lng: 21.189287 },
    { lat: 49.176834, lng: 21.189774 },
    { lat: 49.176901, lng: 21.190596 },
    { lat: 49.176997, lng: 21.191081 },
    { lat: 49.177051, lng: 21.191681 },
    { lat: 49.177019, lng: 21.19224 },
    { lat: 49.177072, lng: 21.192694 },
    { lat: 49.177355, lng: 21.193236 },
    { lat: 49.177412, lng: 21.193623 },
    { lat: 49.177501, lng: 21.193886 },
    { lat: 49.177503, lng: 21.194079 },
    { lat: 49.177816, lng: 21.194817 },
    { lat: 49.177542, lng: 21.195263 },
    { lat: 49.177434, lng: 21.195595 },
    { lat: 49.177137, lng: 21.196065 },
    { lat: 49.177045, lng: 21.1962762 },
    { lat: 49.176611, lng: 21.197425 },
    { lat: 49.17642, lng: 21.197874 },
    { lat: 49.176339, lng: 21.198261 },
    { lat: 49.17626, lng: 21.198516 },
    { lat: 49.176043, lng: 21.199074 },
    { lat: 49.175776, lng: 21.199403 },
    { lat: 49.175377, lng: 21.199809 },
    { lat: 49.1754506, lng: 21.2000491 },
    { lat: 49.175083, lng: 21.2014384 },
    { lat: 49.1750636, lng: 21.2017908 },
    { lat: 49.1745054, lng: 21.2047964 },
    { lat: 49.1743629, lng: 21.2078546 },
    { lat: 49.1745351, lng: 21.2095997 },
    { lat: 49.1746086, lng: 21.2098235 },
    { lat: 49.174692, lng: 21.2104835 },
    { lat: 49.174651, lng: 21.2107209 },
    { lat: 49.1746764, lng: 21.210923 },
    { lat: 49.1748427, lng: 21.2113124 },
    { lat: 49.1746792, lng: 21.2117741 },
    { lat: 49.1747114, lng: 21.2129586 },
    { lat: 49.1742806, lng: 21.2145655 },
    { lat: 49.1741007, lng: 21.2166061 },
    { lat: 49.174377, lng: 21.2173148 },
    { lat: 49.1744214, lng: 21.2179187 },
    { lat: 49.1742708, lng: 21.2187973 },
    { lat: 49.1742873, lng: 21.2192166 },
    { lat: 49.1742207, lng: 21.2196863 },
    { lat: 49.1741726, lng: 21.2207162 },
    { lat: 49.1740641, lng: 21.2215314 },
    { lat: 49.1736105, lng: 21.2224404 },
    { lat: 49.1731465, lng: 21.2231599 },
    { lat: 49.1729814, lng: 21.2237846 },
    { lat: 49.1728298, lng: 21.2240519 },
    { lat: 49.1728437, lng: 21.2245019 },
    { lat: 49.1729271, lng: 21.2246929 },
    { lat: 49.1728223, lng: 21.2249407 },
    { lat: 49.1728787, lng: 21.2251636 },
    { lat: 49.1730376, lng: 21.2253133 },
    { lat: 49.1730141, lng: 21.2257235 },
    { lat: 49.1731294, lng: 21.2258058 },
    { lat: 49.1732629, lng: 21.2264569 },
    { lat: 49.1732448, lng: 21.2265581 },
    { lat: 49.1733333, lng: 21.2266913 },
    { lat: 49.1733193, lng: 21.2271142 },
    { lat: 49.1734397, lng: 21.2275489 },
    { lat: 49.1734329, lng: 21.2276936 },
    { lat: 49.1733533, lng: 21.2278903 },
    { lat: 49.1734614, lng: 21.2281052 },
    { lat: 49.1733774, lng: 21.2285255 },
    { lat: 49.1733991, lng: 21.2287864 },
    { lat: 49.1732389, lng: 21.229104 },
    { lat: 49.1731601, lng: 21.2299436 },
    { lat: 49.173076, lng: 21.2300989 },
    { lat: 49.1730055, lng: 21.2306563 },
    { lat: 49.1730247, lng: 21.2308056 },
    { lat: 49.1729164, lng: 21.2313471 },
    { lat: 49.1730759, lng: 21.2316248 },
    { lat: 49.1729431, lng: 21.2318083 },
    { lat: 49.1729392, lng: 21.2321557 },
    { lat: 49.1727843, lng: 21.2323146 },
    { lat: 49.1729467, lng: 21.2328722 },
    { lat: 49.1727946, lng: 21.2332028 },
    { lat: 49.17287, lng: 21.2336361 },
    { lat: 49.1728653, lng: 21.2340357 },
    { lat: 49.1727823, lng: 21.2342941 },
    { lat: 49.1728953, lng: 21.2345327 },
    { lat: 49.1727985, lng: 21.2347528 },
    { lat: 49.1727682, lng: 21.2350085 },
    { lat: 49.1726695, lng: 21.2352037 },
    { lat: 49.1727925, lng: 21.2352844 },
    { lat: 49.172848, lng: 21.2354096 },
    { lat: 49.1728093, lng: 21.2355404 },
    { lat: 49.1727125, lng: 21.2355361 },
    { lat: 49.1727549, lng: 21.2357787 },
    { lat: 49.1726659, lng: 21.2358919 },
    { lat: 49.1726907, lng: 21.2361455 },
    { lat: 49.1725897, lng: 21.2364113 },
    { lat: 49.1725816, lng: 21.2366614 },
    { lat: 49.1727531, lng: 21.236894 },
    { lat: 49.1725601, lng: 21.2371639 },
    { lat: 49.1723606, lng: 21.2376324 },
    { lat: 49.1724272, lng: 21.2378204 },
    { lat: 49.1725159, lng: 21.2385883 },
    { lat: 49.1725056, lng: 21.2389429 },
    { lat: 49.1725997, lng: 21.2391123 },
    { lat: 49.1725844, lng: 21.2392044 },
    { lat: 49.1724235, lng: 21.2392023 },
    { lat: 49.1724851, lng: 21.2395172 },
    { lat: 49.1724165, lng: 21.2396322 },
    { lat: 49.172425, lng: 21.2398009 },
    { lat: 49.1723255, lng: 21.2399328 },
    { lat: 49.1723922, lng: 21.2405991 },
    { lat: 49.1726375, lng: 21.2411062 },
    { lat: 49.1725432, lng: 21.2413007 },
    { lat: 49.1725269, lng: 21.241573 },
    { lat: 49.1725943, lng: 21.2415366 },
    { lat: 49.1724723, lng: 21.2422152 },
    { lat: 49.1727429, lng: 21.2434402 },
    { lat: 49.1728558, lng: 21.2427233 },
    { lat: 49.173388, lng: 21.2426112 },
    { lat: 49.1740987, lng: 21.2423025 },
    { lat: 49.1755239, lng: 21.2418173 },
    { lat: 49.1775424, lng: 21.2413662 },
    { lat: 49.1785067, lng: 21.2412944 },
    { lat: 49.178416, lng: 21.2417831 },
    { lat: 49.1785859, lng: 21.2421853 },
    { lat: 49.1787941, lng: 21.2423003 },
    { lat: 49.1789048, lng: 21.2424589 },
    { lat: 49.1791574, lng: 21.2436479 },
    { lat: 49.1791381, lng: 21.2438255 },
    { lat: 49.179348, lng: 21.2439885 },
    { lat: 49.1794407, lng: 21.2443354 },
    { lat: 49.1795501, lng: 21.2445287 },
    { lat: 49.1812302, lng: 21.2439871 },
    { lat: 49.1817021, lng: 21.2455705 },
    { lat: 49.1818423, lng: 21.2457432 },
    { lat: 49.1821115, lng: 21.2458553 },
    { lat: 49.1823444, lng: 21.2461548 },
    { lat: 49.1828531, lng: 21.2471105 },
    { lat: 49.1836819, lng: 21.2477563 },
    { lat: 49.1853494, lng: 21.2479326 },
    { lat: 49.1854236, lng: 21.2476377 },
    { lat: 49.185877, lng: 21.2475992 },
    { lat: 49.1882146, lng: 21.2474683 },
  ],
  NižnýTvarožec: [
    { lat: 49.3477274, lng: 21.1778829 },
    { lat: 49.3477463, lng: 21.1779151 },
    { lat: 49.3488243, lng: 21.1801172 },
    { lat: 49.3491642, lng: 21.1812802 },
    { lat: 49.3495816, lng: 21.1820693 },
    { lat: 49.3502389, lng: 21.1835221 },
    { lat: 49.3510179, lng: 21.1848779 },
    { lat: 49.3522196, lng: 21.1886356 },
    { lat: 49.3528551, lng: 21.1900063 },
    { lat: 49.3529639, lng: 21.1906087 },
    { lat: 49.3536666, lng: 21.1914624 },
    { lat: 49.3542169, lng: 21.192228 },
    { lat: 49.3545493, lng: 21.1930376 },
    { lat: 49.3550896, lng: 21.1932271 },
    { lat: 49.3550927, lng: 21.1934391 },
    { lat: 49.3555874, lng: 21.194041 },
    { lat: 49.3566236, lng: 21.1950571 },
    { lat: 49.3573925, lng: 21.1956503 },
    { lat: 49.3574674, lng: 21.1959213 },
    { lat: 49.3578684, lng: 21.196591 },
    { lat: 49.3584652, lng: 21.1983496 },
    { lat: 49.3594911, lng: 21.1989963 },
    { lat: 49.3599941, lng: 21.1997229 },
    { lat: 49.3604493, lng: 21.2000306 },
    { lat: 49.3613792, lng: 21.2010276 },
    { lat: 49.3619195, lng: 21.2030359 },
    { lat: 49.3621226, lng: 21.2041082 },
    { lat: 49.3621912, lng: 21.2048392 },
    { lat: 49.3622515, lng: 21.2066738 },
    { lat: 49.3617992, lng: 21.2082057 },
    { lat: 49.3593942, lng: 21.2148936 },
    { lat: 49.3590454, lng: 21.2159913 },
    { lat: 49.3590372, lng: 21.2163825 },
    { lat: 49.3596191, lng: 21.2175244 },
    { lat: 49.3597608, lng: 21.2179213 },
    { lat: 49.3606545, lng: 21.219135 },
    { lat: 49.361089, lng: 21.2203504 },
    { lat: 49.361552, lng: 21.2219342 },
    { lat: 49.3615645, lng: 21.222525 },
    { lat: 49.3618561, lng: 21.2244688 },
    { lat: 49.3619839, lng: 21.2247133 },
    { lat: 49.3621781, lng: 21.2256841 },
    { lat: 49.3623905, lng: 21.2263752 },
    { lat: 49.3627846, lng: 21.2268937 },
    { lat: 49.3639361, lng: 21.2281872 },
    { lat: 49.3647676, lng: 21.2288847 },
    { lat: 49.3655252, lng: 21.2298157 },
    { lat: 49.3661197, lng: 21.2301253 },
    { lat: 49.366363, lng: 21.2305337 },
    { lat: 49.3666265, lng: 21.230574 },
    { lat: 49.3674651, lng: 21.229858 },
    { lat: 49.367731, lng: 21.2294673 },
    { lat: 49.3683984, lng: 21.2282661 },
    { lat: 49.3687517, lng: 21.2274941 },
    { lat: 49.3688179, lng: 21.2272698 },
    { lat: 49.3687502, lng: 21.2263865 },
    { lat: 49.3692222, lng: 21.2236963 },
    { lat: 49.3695526, lng: 21.2226937 },
    { lat: 49.3701052, lng: 21.2219628 },
    { lat: 49.3705323, lng: 21.2217002 },
    { lat: 49.3722894, lng: 21.2212036 },
    { lat: 49.3735051, lng: 21.2200948 },
    { lat: 49.3736183, lng: 21.2199281 },
    { lat: 49.3737607, lng: 21.2197358 },
    { lat: 49.3737875, lng: 21.219215 },
    { lat: 49.3743677, lng: 21.2174526 },
    { lat: 49.3746175, lng: 21.2164611 },
    { lat: 49.3748006, lng: 21.2151429 },
    { lat: 49.3747339, lng: 21.2127173 },
    { lat: 49.3742934, lng: 21.2107675 },
    { lat: 49.3740225, lng: 21.208225 },
    { lat: 49.3747849, lng: 21.2058501 },
    { lat: 49.3749538, lng: 21.2040671 },
    { lat: 49.3752051, lng: 21.2028775 },
    { lat: 49.3752556, lng: 21.2022225 },
    { lat: 49.3751615, lng: 21.201687 },
    { lat: 49.3752478, lng: 21.2005165 },
    { lat: 49.3754152, lng: 21.2000722 },
    { lat: 49.3754581, lng: 21.1997065 },
    { lat: 49.3755363, lng: 21.199582 },
    { lat: 49.3755965, lng: 21.1989151 },
    { lat: 49.3759521, lng: 21.1973181 },
    { lat: 49.376103, lng: 21.1961538 },
    { lat: 49.3763527, lng: 21.1953189 },
    { lat: 49.3765988, lng: 21.1938569 },
    { lat: 49.3768244, lng: 21.1919159 },
    { lat: 49.3767936, lng: 21.1917964 },
    { lat: 49.3776763, lng: 21.1885884 },
    { lat: 49.3785451, lng: 21.1875035 },
    { lat: 49.3787508, lng: 21.1870263 },
    { lat: 49.3792721, lng: 21.1864599 },
    { lat: 49.3798486, lng: 21.1856769 },
    { lat: 49.3803848, lng: 21.1847151 },
    { lat: 49.3810461, lng: 21.1832946 },
    { lat: 49.3815644, lng: 21.1823424 },
    { lat: 49.3822171, lng: 21.1814027 },
    { lat: 49.3824305, lng: 21.1809584 },
    { lat: 49.3838094, lng: 21.1786634 },
    { lat: 49.3842051, lng: 21.1776052 },
    { lat: 49.3840071, lng: 21.1769949 },
    { lat: 49.3842561, lng: 21.1759188 },
    { lat: 49.3849753, lng: 21.1750598 },
    { lat: 49.3853239, lng: 21.1748353 },
    { lat: 49.3864408, lng: 21.1737022 },
    { lat: 49.3870609, lng: 21.1733473 },
    { lat: 49.3873648, lng: 21.1733854 },
    { lat: 49.3878895, lng: 21.1727503 },
    { lat: 49.3881807, lng: 21.1721637 },
    { lat: 49.3882917, lng: 21.1717218 },
    { lat: 49.3890407, lng: 21.1705545 },
    { lat: 49.3906036, lng: 21.1686792 },
    { lat: 49.3896727, lng: 21.1674406 },
    { lat: 49.3886024, lng: 21.165414 },
    { lat: 49.3880259, lng: 21.1646136 },
    { lat: 49.3876001, lng: 21.1638759 },
    { lat: 49.3870605, lng: 21.1643601 },
    { lat: 49.3855888, lng: 21.1660893 },
    { lat: 49.3852899, lng: 21.1663554 },
    { lat: 49.3846248, lng: 21.1674681 },
    { lat: 49.3839276, lng: 21.168382 },
    { lat: 49.3834983, lng: 21.1686099 },
    { lat: 49.3831613, lng: 21.1689933 },
    { lat: 49.3814046, lng: 21.1657731 },
    { lat: 49.3809787, lng: 21.1652861 },
    { lat: 49.3803207, lng: 21.164897 },
    { lat: 49.3784296, lng: 21.1641166 },
    { lat: 49.3774159, lng: 21.1639768 },
    { lat: 49.3765116, lng: 21.1646758 },
    { lat: 49.3760527, lng: 21.1647116 },
    { lat: 49.3755309, lng: 21.1654428 },
    { lat: 49.3749471, lng: 21.1665678 },
    { lat: 49.3746252, lng: 21.1668149 },
    { lat: 49.3738889, lng: 21.1669064 },
    { lat: 49.372582, lng: 21.1666061 },
    { lat: 49.3722963, lng: 21.1661721 },
    { lat: 49.3715348, lng: 21.1656776 },
    { lat: 49.3705149, lng: 21.1653018 },
    { lat: 49.370059, lng: 21.1652211 },
    { lat: 49.3694772, lng: 21.164971 },
    { lat: 49.3691418, lng: 21.1650432 },
    { lat: 49.3688845, lng: 21.1650162 },
    { lat: 49.3682057, lng: 21.165223 },
    { lat: 49.3671487, lng: 21.1645976 },
    { lat: 49.3661397, lng: 21.1644957 },
    { lat: 49.3656824, lng: 21.1639787 },
    { lat: 49.3648576, lng: 21.1644464 },
    { lat: 49.3641251, lng: 21.1639799 },
    { lat: 49.36364, lng: 21.1638281 },
    { lat: 49.3625134, lng: 21.162884 },
    { lat: 49.3610203, lng: 21.1631383 },
    { lat: 49.3602836, lng: 21.1640471 },
    { lat: 49.3599319, lng: 21.1635603 },
    { lat: 49.359264, lng: 21.1638991 },
    { lat: 49.3584356, lng: 21.1638909 },
    { lat: 49.3581407, lng: 21.1639616 },
    { lat: 49.3571259, lng: 21.1645786 },
    { lat: 49.3565088, lng: 21.1642759 },
    { lat: 49.3556827, lng: 21.1637275 },
    { lat: 49.354915, lng: 21.1630648 },
    { lat: 49.3546263, lng: 21.1640201 },
    { lat: 49.35305, lng: 21.1634823 },
    { lat: 49.3530422, lng: 21.1635204 },
    { lat: 49.3529345, lng: 21.1650814 },
    { lat: 49.3528126, lng: 21.1653163 },
    { lat: 49.3528342, lng: 21.1654699 },
    { lat: 49.3527819, lng: 21.1656304 },
    { lat: 49.3529167, lng: 21.1660959 },
    { lat: 49.3528952, lng: 21.1661992 },
    { lat: 49.3524906, lng: 21.1667125 },
    { lat: 49.352297, lng: 21.1671425 },
    { lat: 49.3522146, lng: 21.1676066 },
    { lat: 49.3523169, lng: 21.1681433 },
    { lat: 49.3522752, lng: 21.1684994 },
    { lat: 49.3523627, lng: 21.1687478 },
    { lat: 49.3522911, lng: 21.1690053 },
    { lat: 49.3522931, lng: 21.1692896 },
    { lat: 49.352179, lng: 21.1696769 },
    { lat: 49.3519787, lng: 21.1698901 },
    { lat: 49.3518032, lng: 21.1703555 },
    { lat: 49.3517656, lng: 21.1707752 },
    { lat: 49.3515479, lng: 21.1712635 },
    { lat: 49.3514242, lng: 21.1717364 },
    { lat: 49.3512717, lng: 21.1718573 },
    { lat: 49.3512544, lng: 21.1724212 },
    { lat: 49.3510367, lng: 21.1728735 },
    { lat: 49.351021, lng: 21.1733456 },
    { lat: 49.3507342, lng: 21.1737809 },
    { lat: 49.3507581, lng: 21.1740769 },
    { lat: 49.3506014, lng: 21.174389 },
    { lat: 49.3504424, lng: 21.1744559 },
    { lat: 49.3502104, lng: 21.1750593 },
    { lat: 49.3501488, lng: 21.1753926 },
    { lat: 49.3498636, lng: 21.1759923 },
    { lat: 49.3495251, lng: 21.176034 },
    { lat: 49.349193, lng: 21.1762099 },
    { lat: 49.3490418, lng: 21.1764811 },
    { lat: 49.3490235, lng: 21.1767997 },
    { lat: 49.348834, lng: 21.1770694 },
    { lat: 49.3487482, lng: 21.1773857 },
    { lat: 49.3482716, lng: 21.1776937 },
    { lat: 49.3477274, lng: 21.1778829 },
  ],
  Šašová: [
    { lat: 49.2658163, lng: 21.432521 },
    { lat: 49.2654737, lng: 21.4327294 },
    { lat: 49.2648562, lng: 21.4330051 },
    { lat: 49.2642812, lng: 21.4331582 },
    { lat: 49.2636396, lng: 21.4336106 },
    { lat: 49.2627508, lng: 21.4339601 },
    { lat: 49.2621607, lng: 21.4340549 },
    { lat: 49.2617994, lng: 21.4340095 },
    { lat: 49.2605355, lng: 21.4349265 },
    { lat: 49.2588834, lng: 21.4368048 },
    { lat: 49.2584902, lng: 21.4369467 },
    { lat: 49.2582861, lng: 21.4371169 },
    { lat: 49.2578979, lng: 21.4375848 },
    { lat: 49.2573088, lng: 21.4386832 },
    { lat: 49.2570502, lng: 21.4390015 },
    { lat: 49.2564272, lng: 21.4393624 },
    { lat: 49.2562321, lng: 21.4395787 },
    { lat: 49.2557741, lng: 21.4399231 },
    { lat: 49.2556333, lng: 21.4401156 },
    { lat: 49.2555211, lng: 21.4401653 },
    { lat: 49.2552951, lng: 21.440517 },
    { lat: 49.255334, lng: 21.4417389 },
    { lat: 49.2554561, lng: 21.4419587 },
    { lat: 49.2538502, lng: 21.4429928 },
    { lat: 49.2542438, lng: 21.4441141 },
    { lat: 49.2547132, lng: 21.4467195 },
    { lat: 49.2528978, lng: 21.4473585 },
    { lat: 49.2529851, lng: 21.448782 },
    { lat: 49.2525857, lng: 21.4489419 },
    { lat: 49.2522739, lng: 21.4489723 },
    { lat: 49.2502352, lng: 21.4483863 },
    { lat: 49.2488481, lng: 21.4478421 },
    { lat: 49.2486247, lng: 21.4479354 },
    { lat: 49.2486344, lng: 21.4482711 },
    { lat: 49.248141, lng: 21.4482874 },
    { lat: 49.2477315, lng: 21.4487937 },
    { lat: 49.2473556, lng: 21.4489123 },
    { lat: 49.2475223, lng: 21.4491095 },
    { lat: 49.2476471, lng: 21.4491029 },
    { lat: 49.2477979, lng: 21.4492652 },
    { lat: 49.2478086, lng: 21.4494403 },
    { lat: 49.2476884, lng: 21.449676 },
    { lat: 49.2476429, lng: 21.450557 },
    { lat: 49.2479437, lng: 21.4509249 },
    { lat: 49.2477764, lng: 21.4512052 },
    { lat: 49.2475911, lng: 21.4525008 },
    { lat: 49.2472383, lng: 21.4527507 },
    { lat: 49.2468558, lng: 21.4528701 },
    { lat: 49.2467633, lng: 21.4526297 },
    { lat: 49.2466342, lng: 21.4532217 },
    { lat: 49.2463965, lng: 21.4534063 },
    { lat: 49.246528, lng: 21.4538691 },
    { lat: 49.2463711, lng: 21.4540472 },
    { lat: 49.2464116, lng: 21.4546321 },
    { lat: 49.2469035, lng: 21.4563513 },
    { lat: 49.246714, lng: 21.4563788 },
    { lat: 49.2465171, lng: 21.4562206 },
    { lat: 49.2466632, lng: 21.4569137 },
    { lat: 49.2468788, lng: 21.4572521 },
    { lat: 49.2469143, lng: 21.4576649 },
    { lat: 49.2468512, lng: 21.4581269 },
    { lat: 49.2469433, lng: 21.4588272 },
    { lat: 49.2468959, lng: 21.4590569 },
    { lat: 49.2464831, lng: 21.4589838 },
    { lat: 49.2460618, lng: 21.4596704 },
    { lat: 49.245748, lng: 21.4599782 },
    { lat: 49.2457226, lng: 21.4600789 },
    { lat: 49.2458132, lng: 21.460564 },
    { lat: 49.2459179, lng: 21.4620128 },
    { lat: 49.2460392, lng: 21.4623219 },
    { lat: 49.2462508, lng: 21.4640055 },
    { lat: 49.2459994, lng: 21.4639843 },
    { lat: 49.2457062, lng: 21.4635308 },
    { lat: 49.2454992, lng: 21.4638418 },
    { lat: 49.2447477, lng: 21.4656451 },
    { lat: 49.2448149, lng: 21.4658682 },
    { lat: 49.2450366, lng: 21.4660996 },
    { lat: 49.2454087, lng: 21.4667981 },
    { lat: 49.2456112, lng: 21.4670293 },
    { lat: 49.2455949, lng: 21.4672069 },
    { lat: 49.2457207, lng: 21.4673594 },
    { lat: 49.246294, lng: 21.4675791 },
    { lat: 49.2463403, lng: 21.4680931 },
    { lat: 49.2461482, lng: 21.4685259 },
    { lat: 49.2458169, lng: 21.4698441 },
    { lat: 49.244395, lng: 21.4725932 },
    { lat: 49.245183, lng: 21.4747647 },
    { lat: 49.2456965, lng: 21.4758027 },
    { lat: 49.2458239, lng: 21.4789043 },
    { lat: 49.2460382, lng: 21.4796382 },
    { lat: 49.2463266, lng: 21.4800773 },
    { lat: 49.2464453, lng: 21.4803769 },
    { lat: 49.2473272, lng: 21.4806485 },
    { lat: 49.2481908, lng: 21.4810626 },
    { lat: 49.2485438, lng: 21.4814852 },
    { lat: 49.2487384, lng: 21.4803835 },
    { lat: 49.2498526, lng: 21.4777706 },
    { lat: 49.2515665, lng: 21.4742592 },
    { lat: 49.2519016, lng: 21.4740588 },
    { lat: 49.2527337, lng: 21.4733721 },
    { lat: 49.253432, lng: 21.4728878 },
    { lat: 49.2553789, lng: 21.4717003 },
    { lat: 49.256201, lng: 21.4709807 },
    { lat: 49.2568536, lng: 21.4705159 },
    { lat: 49.2570129, lng: 21.4704613 },
    { lat: 49.2585031, lng: 21.4691501 },
    { lat: 49.2591668, lng: 21.4683261 },
    { lat: 49.2607034, lng: 21.4658954 },
    { lat: 49.2610314, lng: 21.4648576 },
    { lat: 49.2616047, lng: 21.4635969 },
    { lat: 49.2624992, lng: 21.4593417 },
    { lat: 49.2627063, lng: 21.4586016 },
    { lat: 49.2627233, lng: 21.4575129 },
    { lat: 49.2632417, lng: 21.4559835 },
    { lat: 49.2632534, lng: 21.4553757 },
    { lat: 49.263454, lng: 21.4548035 },
    { lat: 49.2637796, lng: 21.4520121 },
    { lat: 49.2639877, lng: 21.4512236 },
    { lat: 49.2641792, lng: 21.4506869 },
    { lat: 49.2642452, lng: 21.4502156 },
    { lat: 49.2642586, lng: 21.4490774 },
    { lat: 49.2643849, lng: 21.4488978 },
    { lat: 49.2646615, lng: 21.4488085 },
    { lat: 49.2645721, lng: 21.448638 },
    { lat: 49.2648205, lng: 21.4483169 },
    { lat: 49.2650743, lng: 21.4481834 },
    { lat: 49.265261, lng: 21.4478311 },
    { lat: 49.2653079, lng: 21.4468859 },
    { lat: 49.2652002, lng: 21.4468607 },
    { lat: 49.2654385, lng: 21.4464617 },
    { lat: 49.2655582, lng: 21.4460901 },
    { lat: 49.2656612, lng: 21.4459935 },
    { lat: 49.2659004, lng: 21.4459827 },
    { lat: 49.2662396, lng: 21.4456475 },
    { lat: 49.2665984, lng: 21.4449442 },
    { lat: 49.2670969, lng: 21.4444047 },
    { lat: 49.2672416, lng: 21.444392 },
    { lat: 49.267396, lng: 21.4440902 },
    { lat: 49.2674896, lng: 21.4437074 },
    { lat: 49.2675928, lng: 21.4436564 },
    { lat: 49.2677189, lng: 21.4437801 },
    { lat: 49.2680509, lng: 21.4436669 },
    { lat: 49.2682001, lng: 21.4434144 },
    { lat: 49.2682506, lng: 21.4434135 },
    { lat: 49.2686025, lng: 21.4430136 },
    { lat: 49.26886, lng: 21.4431716 },
    { lat: 49.2694464, lng: 21.4431003 },
    { lat: 49.2728433, lng: 21.4396964 },
    { lat: 49.2742792, lng: 21.4371062 },
    { lat: 49.2754308, lng: 21.4353438 },
    { lat: 49.2756028, lng: 21.4345675 },
    { lat: 49.2763796, lng: 21.432919 },
    { lat: 49.2759405, lng: 21.4320112 },
    { lat: 49.2753903, lng: 21.4310382 },
    { lat: 49.2748471, lng: 21.4304298 },
    { lat: 49.2743974, lng: 21.4300946 },
    { lat: 49.2738157, lng: 21.4291569 },
    { lat: 49.2734465, lng: 21.4288083 },
    { lat: 49.2727001, lng: 21.4284828 },
    { lat: 49.2723248, lng: 21.4289227 },
    { lat: 49.2720572, lng: 21.4295051 },
    { lat: 49.2713237, lng: 21.4305165 },
    { lat: 49.2712216, lng: 21.4308329 },
    { lat: 49.2711155, lng: 21.4308952 },
    { lat: 49.2700447, lng: 21.4312939 },
    { lat: 49.2694777, lng: 21.4310243 },
    { lat: 49.2686687, lng: 21.4318729 },
    { lat: 49.2678417, lng: 21.432531 },
    { lat: 49.267015, lng: 21.431989 },
    { lat: 49.2665185, lng: 21.432446 },
    { lat: 49.2658163, lng: 21.432521 },
  ],
  Hertník: [
    { lat: 49.204283, lng: 21.1753274 },
    { lat: 49.2037504, lng: 21.175278 },
    { lat: 49.2033175, lng: 21.1748187 },
    { lat: 49.2030753, lng: 21.1742761 },
    { lat: 49.2022393, lng: 21.173233 },
    { lat: 49.201356, lng: 21.1725839 },
    { lat: 49.2010574, lng: 21.1724606 },
    { lat: 49.2005069, lng: 21.1717052 },
    { lat: 49.1998471, lng: 21.1706115 },
    { lat: 49.1995464, lng: 21.1698494 },
    { lat: 49.2009163, lng: 21.1697178 },
    { lat: 49.201339, lng: 21.1697452 },
    { lat: 49.2015033, lng: 21.1696386 },
    { lat: 49.2010407, lng: 21.1669012 },
    { lat: 49.2008758, lng: 21.1667434 },
    { lat: 49.2007441, lng: 21.16649 },
    { lat: 49.2005163, lng: 21.1663328 },
    { lat: 49.2001998, lng: 21.1662465 },
    { lat: 49.1990881, lng: 21.1664688 },
    { lat: 49.1987901, lng: 21.1666111 },
    { lat: 49.1979808, lng: 21.1672211 },
    { lat: 49.1975975, lng: 21.1673743 },
    { lat: 49.197034, lng: 21.1677076 },
    { lat: 49.1968762, lng: 21.1677361 },
    { lat: 49.1964488, lng: 21.1679961 },
    { lat: 49.1963154, lng: 21.1680429 },
    { lat: 49.196087, lng: 21.167711 },
    { lat: 49.196076, lng: 21.167698 },
    { lat: 49.196038, lng: 21.167765 },
    { lat: 49.195851, lng: 21.168005 },
    { lat: 49.195731, lng: 21.168122 },
    { lat: 49.195121, lng: 21.168432 },
    { lat: 49.194759, lng: 21.168596 },
    { lat: 49.194262, lng: 21.168784 },
    { lat: 49.193651, lng: 21.169072 },
    { lat: 49.193355, lng: 21.169115 },
    { lat: 49.192912, lng: 21.169393 },
    { lat: 49.192749, lng: 21.169557 },
    { lat: 49.192531, lng: 21.169606 },
    { lat: 49.191642, lng: 21.169868 },
    { lat: 49.191293, lng: 21.169872 },
    { lat: 49.191054, lng: 21.169787 },
    { lat: 49.190878, lng: 21.169558 },
    { lat: 49.190715, lng: 21.169465 },
    { lat: 49.190218, lng: 21.169333 },
    { lat: 49.189985, lng: 21.169184 },
    { lat: 49.189742, lng: 21.169011 },
    { lat: 49.189434, lng: 21.168918 },
    { lat: 49.189113, lng: 21.168936 },
    { lat: 49.189005, lng: 21.169007 },
    { lat: 49.188858, lng: 21.169198 },
    { lat: 49.188643, lng: 21.169682 },
    { lat: 49.188343, lng: 21.1705 },
    { lat: 49.187836, lng: 21.171291 },
    { lat: 49.187223, lng: 21.171831 },
    { lat: 49.186517, lng: 21.172242 },
    { lat: 49.186153, lng: 21.172557 },
    { lat: 49.185935, lng: 21.172925 },
    { lat: 49.185734, lng: 21.173355 },
    { lat: 49.185572, lng: 21.173676 },
    { lat: 49.185474, lng: 21.17386 },
    { lat: 49.185375, lng: 21.174082 },
    { lat: 49.185177, lng: 21.174606 },
    { lat: 49.185167, lng: 21.174631 },
    { lat: 49.185159, lng: 21.174651 },
    { lat: 49.185185, lng: 21.174651 },
    { lat: 49.185153, lng: 21.175236 },
    { lat: 49.18517, lng: 21.175582 },
    { lat: 49.185075, lng: 21.176469 },
    { lat: 49.185032, lng: 21.176786 },
    { lat: 49.184905, lng: 21.177412 },
    { lat: 49.18459, lng: 21.178261 },
    { lat: 49.1848181, lng: 21.1788914 },
    { lat: 49.1832567, lng: 21.1809895 },
    { lat: 49.1837454, lng: 21.1817572 },
    { lat: 49.1844208, lng: 21.1826635 },
    { lat: 49.1850083, lng: 21.18281 },
    { lat: 49.1850803, lng: 21.1832076 },
    { lat: 49.1853256, lng: 21.1837528 },
    { lat: 49.1862206, lng: 21.1853504 },
    { lat: 49.186385, lng: 21.1858212 },
    { lat: 49.1865252, lng: 21.1864528 },
    { lat: 49.1865229, lng: 21.1867814 },
    { lat: 49.1866462, lng: 21.1870138 },
    { lat: 49.1869382, lng: 21.1872872 },
    { lat: 49.1871772, lng: 21.1876616 },
    { lat: 49.187275, lng: 21.1883797 },
    { lat: 49.1872959, lng: 21.1893391 },
    { lat: 49.1874657, lng: 21.1901624 },
    { lat: 49.1877641, lng: 21.1911495 },
    { lat: 49.187974, lng: 21.1915303 },
    { lat: 49.1884075, lng: 21.1917921 },
    { lat: 49.1887341, lng: 21.1922688 },
    { lat: 49.1887291, lng: 21.193842 },
    { lat: 49.1887807, lng: 21.1946275 },
    { lat: 49.1888895, lng: 21.1952689 },
    { lat: 49.1890663, lng: 21.195676 },
    { lat: 49.1894673, lng: 21.1971668 },
    { lat: 49.1893898, lng: 21.1978963 },
    { lat: 49.1894356, lng: 21.1981027 },
    { lat: 49.1895764, lng: 21.1982929 },
    { lat: 49.189972, lng: 21.1986337 },
    { lat: 49.1901664, lng: 21.1991661 },
    { lat: 49.1906428, lng: 21.1998364 },
    { lat: 49.1912752, lng: 21.2004914 },
    { lat: 49.1912655, lng: 21.2008317 },
    { lat: 49.1913879, lng: 21.2009304 },
    { lat: 49.1913609, lng: 21.2013274 },
    { lat: 49.1919098, lng: 21.2020414 },
    { lat: 49.1924639, lng: 21.2025349 },
    { lat: 49.1924869, lng: 21.2033413 },
    { lat: 49.1928247, lng: 21.2041447 },
    { lat: 49.1930475, lng: 21.2048344 },
    { lat: 49.1936809, lng: 21.2072625 },
    { lat: 49.194459, lng: 21.2106918 },
    { lat: 49.1947673, lng: 21.2117835 },
    { lat: 49.1948142, lng: 21.2127195 },
    { lat: 49.1950076, lng: 21.2135438 },
    { lat: 49.1952366, lng: 21.2142537 },
    { lat: 49.1954064, lng: 21.2150553 },
    { lat: 49.1955844, lng: 21.2166997 },
    { lat: 49.1955738, lng: 21.2175143 },
    { lat: 49.1954068, lng: 21.2185952 },
    { lat: 49.1952902, lng: 21.2188993 },
    { lat: 49.1953856, lng: 21.2193311 },
    { lat: 49.195596, lng: 21.2197653 },
    { lat: 49.1956475, lng: 21.2199767 },
    { lat: 49.1955205, lng: 21.2201989 },
    { lat: 49.1957762, lng: 21.2207896 },
    { lat: 49.1959377, lng: 21.2213936 },
    { lat: 49.1960622, lng: 21.2216685 },
    { lat: 49.1961132, lng: 21.2224794 },
    { lat: 49.1962932, lng: 21.2232835 },
    { lat: 49.1963695, lng: 21.22393 },
    { lat: 49.1965856, lng: 21.2245898 },
    { lat: 49.1966651, lng: 21.2250493 },
    { lat: 49.1966886, lng: 21.2255912 },
    { lat: 49.1965929, lng: 21.2262864 },
    { lat: 49.1962796, lng: 21.2279114 },
    { lat: 49.1962059, lng: 21.2280038 },
    { lat: 49.1961346, lng: 21.2285 },
    { lat: 49.1960562, lng: 21.2286179 },
    { lat: 49.1961326, lng: 21.2290542 },
    { lat: 49.1966591, lng: 21.2306589 },
    { lat: 49.1967465, lng: 21.2310491 },
    { lat: 49.1967308, lng: 21.2320011 },
    { lat: 49.1966323, lng: 21.2331516 },
    { lat: 49.1965631, lng: 21.23353 },
    { lat: 49.196233, lng: 21.2342132 },
    { lat: 49.1960027, lng: 21.2345593 },
    { lat: 49.1958281, lng: 21.2352845 },
    { lat: 49.1957491, lng: 21.2358586 },
    { lat: 49.1953655, lng: 21.2369921 },
    { lat: 49.1948693, lng: 21.2387535 },
    { lat: 49.1947628, lng: 21.2391705 },
    { lat: 49.1947905, lng: 21.239189 },
    { lat: 49.1945096, lng: 21.2397531 },
    { lat: 49.19433, lng: 21.2413065 },
    { lat: 49.1942039, lng: 21.2419214 },
    { lat: 49.1941759, lng: 21.2424465 },
    { lat: 49.1938627, lng: 21.2430194 },
    { lat: 49.1937567, lng: 21.2434436 },
    { lat: 49.1935587, lng: 21.2435606 },
    { lat: 49.193485, lng: 21.243795 },
    { lat: 49.1935878, lng: 21.2438888 },
    { lat: 49.193475, lng: 21.2440922 },
    { lat: 49.1934145, lng: 21.2441016 },
    { lat: 49.1933523, lng: 21.2443514 },
    { lat: 49.1932761, lng: 21.2443946 },
    { lat: 49.1932212, lng: 21.2443341 },
    { lat: 49.1931594, lng: 21.2444411 },
    { lat: 49.1932157, lng: 21.2447004 },
    { lat: 49.1931246, lng: 21.244853 },
    { lat: 49.1931378, lng: 21.2451654 },
    { lat: 49.1925942, lng: 21.2459709 },
    { lat: 49.1935059, lng: 21.2463518 },
    { lat: 49.1935708, lng: 21.2466688 },
    { lat: 49.1937136, lng: 21.2468803 },
    { lat: 49.1937288, lng: 21.2472278 },
    { lat: 49.1938324, lng: 21.2474165 },
    { lat: 49.1944481, lng: 21.2478244 },
    { lat: 49.1950363, lng: 21.2484087 },
    { lat: 49.1951101, lng: 21.2485758 },
    { lat: 49.1950979, lng: 21.2491703 },
    { lat: 49.1990791, lng: 21.254502 },
    { lat: 49.2004049, lng: 21.2554239 },
    { lat: 49.2024191, lng: 21.2566754 },
    { lat: 49.2024193, lng: 21.2570921 },
    { lat: 49.202479, lng: 21.2571998 },
    { lat: 49.2032478, lng: 21.2569888 },
    { lat: 49.2038652, lng: 21.2570524 },
    { lat: 49.2039224, lng: 21.2569368 },
    { lat: 49.204733, lng: 21.2571342 },
    { lat: 49.2055109, lng: 21.2571831 },
    { lat: 49.2069944, lng: 21.2575636 },
    { lat: 49.20962, lng: 21.2579531 },
    { lat: 49.2120514, lng: 21.2586477 },
    { lat: 49.2131469, lng: 21.2590728 },
    { lat: 49.2133801, lng: 21.2592272 },
    { lat: 49.2136115, lng: 21.2592128 },
    { lat: 49.2150371, lng: 21.2594687 },
    { lat: 49.2150127, lng: 21.259812 },
    { lat: 49.2180093, lng: 21.2598204 },
    { lat: 49.2186421, lng: 21.2596105 },
    { lat: 49.2190417, lng: 21.2596155 },
    { lat: 49.2220764, lng: 21.2557961 },
    { lat: 49.2227195, lng: 21.25526 },
    { lat: 49.2230456, lng: 21.2544434 },
    { lat: 49.2230439, lng: 21.2541717 },
    { lat: 49.2230933, lng: 21.2541689 },
    { lat: 49.2231865, lng: 21.2537152 },
    { lat: 49.2234468, lng: 21.2532927 },
    { lat: 49.2234102, lng: 21.2527885 },
    { lat: 49.2234559, lng: 21.2525573 },
    { lat: 49.2236041, lng: 21.2524395 },
    { lat: 49.2236483, lng: 21.2523715 },
    { lat: 49.2237022, lng: 21.2522939 },
    { lat: 49.2237774, lng: 21.2521442 },
    { lat: 49.2237835, lng: 21.2520138 },
    { lat: 49.2238816, lng: 21.2519823 },
    { lat: 49.2239219, lng: 21.2518966 },
    { lat: 49.2238953, lng: 21.2516592 },
    { lat: 49.2239739, lng: 21.251579 },
    { lat: 49.2240077, lng: 21.2514344 },
    { lat: 49.2239041, lng: 21.2511697 },
    { lat: 49.2238863, lng: 21.2509189 },
    { lat: 49.2239498, lng: 21.2507521 },
    { lat: 49.2238728, lng: 21.2505167 },
    { lat: 49.2239823, lng: 21.2503066 },
    { lat: 49.2239584, lng: 21.2501884 },
    { lat: 49.2240763, lng: 21.2501105 },
    { lat: 49.2240015, lng: 21.2499776 },
    { lat: 49.2240451, lng: 21.2498248 },
    { lat: 49.2241445, lng: 21.2497262 },
    { lat: 49.2241612, lng: 21.2496095 },
    { lat: 49.2239785, lng: 21.2489343 },
    { lat: 49.2237579, lng: 21.2483449 },
    { lat: 49.2236934, lng: 21.2482966 },
    { lat: 49.2237166, lng: 21.248175 },
    { lat: 49.2236435, lng: 21.2478811 },
    { lat: 49.2237565, lng: 21.2477539 },
    { lat: 49.2237259, lng: 21.2475638 },
    { lat: 49.2236495, lng: 21.2474537 },
    { lat: 49.2236634, lng: 21.2471385 },
    { lat: 49.2235982, lng: 21.2469891 },
    { lat: 49.2236932, lng: 21.2467901 },
    { lat: 49.2236794, lng: 21.2466679 },
    { lat: 49.2236153, lng: 21.2465947 },
    { lat: 49.2236009, lng: 21.2464169 },
    { lat: 49.223647, lng: 21.2461965 },
    { lat: 49.2237514, lng: 21.246004 },
    { lat: 49.2236896, lng: 21.2458266 },
    { lat: 49.2235396, lng: 21.2457394 },
    { lat: 49.2235239, lng: 21.2454897 },
    { lat: 49.2237131, lng: 21.2450164 },
    { lat: 49.2235619, lng: 21.2447069 },
    { lat: 49.2236366, lng: 21.2443221 },
    { lat: 49.2236167, lng: 21.2441101 },
    { lat: 49.2236911, lng: 21.2438905 },
    { lat: 49.2238121, lng: 21.2438376 },
    { lat: 49.2238452, lng: 21.2437434 },
    { lat: 49.2237935, lng: 21.2436003 },
    { lat: 49.2239898, lng: 21.2434689 },
    { lat: 49.2240517, lng: 21.2433644 },
    { lat: 49.2240471, lng: 21.2432459 },
    { lat: 49.224114, lng: 21.2431598 },
    { lat: 49.2241127, lng: 21.2426026 },
    { lat: 49.2240384, lng: 21.2424556 },
    { lat: 49.2240868, lng: 21.2422337 },
    { lat: 49.2241287, lng: 21.2422386 },
    { lat: 49.2241309, lng: 21.2420962 },
    { lat: 49.2240513, lng: 21.2419914 },
    { lat: 49.2238841, lng: 21.2420826 },
    { lat: 49.2239102, lng: 21.2423097 },
    { lat: 49.2238062, lng: 21.242365 },
    { lat: 49.2237025, lng: 21.2422504 },
    { lat: 49.2237626, lng: 21.2421204 },
    { lat: 49.2236177, lng: 21.2417632 },
    { lat: 49.2237063, lng: 21.2416075 },
    { lat: 49.2238202, lng: 21.2416138 },
    { lat: 49.2239403, lng: 21.2413798 },
    { lat: 49.2239211, lng: 21.2411616 },
    { lat: 49.2239783, lng: 21.2409347 },
    { lat: 49.2237542, lng: 21.2406696 },
    { lat: 49.2238237, lng: 21.2404076 },
    { lat: 49.2239315, lng: 21.2402166 },
    { lat: 49.2239425, lng: 21.2401366 },
    { lat: 49.2238729, lng: 21.2399595 },
    { lat: 49.2240417, lng: 21.2396306 },
    { lat: 49.2237467, lng: 21.239039 },
    { lat: 49.2236225, lng: 21.2389093 },
    { lat: 49.2237567, lng: 21.2387726 },
    { lat: 49.2237643, lng: 21.2386587 },
    { lat: 49.2236621, lng: 21.2382646 },
    { lat: 49.2235391, lng: 21.2381748 },
    { lat: 49.2235892, lng: 21.2377565 },
    { lat: 49.2236686, lng: 21.2377393 },
    { lat: 49.2238054, lng: 21.2375416 },
    { lat: 49.2237404, lng: 21.2371153 },
    { lat: 49.2235255, lng: 21.2371095 },
    { lat: 49.223501, lng: 21.2369338 },
    { lat: 49.2236032, lng: 21.236853 },
    { lat: 49.2236482, lng: 21.2369294 },
    { lat: 49.223704, lng: 21.2369117 },
    { lat: 49.2238708, lng: 21.2367017 },
    { lat: 49.223859, lng: 21.2365014 },
    { lat: 49.2237298, lng: 21.236286 },
    { lat: 49.223582, lng: 21.236203 },
    { lat: 49.2235416, lng: 21.2362455 },
    { lat: 49.2232239, lng: 21.2358287 },
    { lat: 49.2231505, lng: 21.2353829 },
    { lat: 49.2230414, lng: 21.2354224 },
    { lat: 49.2229473, lng: 21.2353244 },
    { lat: 49.2227955, lng: 21.2353259 },
    { lat: 49.2227001, lng: 21.2352586 },
    { lat: 49.2226916, lng: 21.2351282 },
    { lat: 49.2228238, lng: 21.234897 },
    { lat: 49.2228208, lng: 21.2347985 },
    { lat: 49.2227661, lng: 21.2346995 },
    { lat: 49.2225795, lng: 21.2347043 },
    { lat: 49.2226149, lng: 21.2343979 },
    { lat: 49.2225778, lng: 21.2342875 },
    { lat: 49.2224731, lng: 21.23425 },
    { lat: 49.2225395, lng: 21.2340322 },
    { lat: 49.2225082, lng: 21.2339268 },
    { lat: 49.2224274, lng: 21.2338609 },
    { lat: 49.2223684, lng: 21.2336971 },
    { lat: 49.2222215, lng: 21.2336741 },
    { lat: 49.2223026, lng: 21.233497 },
    { lat: 49.2222777, lng: 21.2333465 },
    { lat: 49.2220941, lng: 21.2333046 },
    { lat: 49.2220043, lng: 21.2330522 },
    { lat: 49.221943, lng: 21.2331444 },
    { lat: 49.2218891, lng: 21.2331173 },
    { lat: 49.2219359, lng: 21.2329127 },
    { lat: 49.2220744, lng: 21.2328099 },
    { lat: 49.2220238, lng: 21.2327351 },
    { lat: 49.2220904, lng: 21.2323034 },
    { lat: 49.2222031, lng: 21.2322417 },
    { lat: 49.2222972, lng: 21.232043 },
    { lat: 49.2221672, lng: 21.2320261 },
    { lat: 49.2221326, lng: 21.2316272 },
    { lat: 49.2222288, lng: 21.2313219 },
    { lat: 49.2223708, lng: 21.2312843 },
    { lat: 49.2223953, lng: 21.2311001 },
    { lat: 49.2225352, lng: 21.2309651 },
    { lat: 49.2224502, lng: 21.2306544 },
    { lat: 49.2225118, lng: 21.2304207 },
    { lat: 49.2224364, lng: 21.2301677 },
    { lat: 49.2226192, lng: 21.2299648 },
    { lat: 49.2226469, lng: 21.2294779 },
    { lat: 49.2227798, lng: 21.2294839 },
    { lat: 49.222828, lng: 21.2292954 },
    { lat: 49.2227748, lng: 21.229207 },
    { lat: 49.2228073, lng: 21.2291298 },
    { lat: 49.2227418, lng: 21.2289006 },
    { lat: 49.222748, lng: 21.2285952 },
    { lat: 49.222925, lng: 21.2283489 },
    { lat: 49.2229923, lng: 21.2279002 },
    { lat: 49.2230393, lng: 21.2278194 },
    { lat: 49.2233068, lng: 21.2276699 },
    { lat: 49.2234322, lng: 21.2273567 },
    { lat: 49.223417, lng: 21.2270849 },
    { lat: 49.2235813, lng: 21.2268996 },
    { lat: 49.2234954, lng: 21.2266685 },
    { lat: 49.2235, lng: 21.2264507 },
    { lat: 49.2236142, lng: 21.2261369 },
    { lat: 49.2237205, lng: 21.2256112 },
    { lat: 49.2237979, lng: 21.2248403 },
    { lat: 49.2239114, lng: 21.2245046 },
    { lat: 49.2239351, lng: 21.224212 },
    { lat: 49.2238629, lng: 21.2239195 },
    { lat: 49.2237322, lng: 21.2236585 },
    { lat: 49.2236504, lng: 21.2230467 },
    { lat: 49.2235518, lng: 21.2226952 },
    { lat: 49.2234337, lng: 21.2225878 },
    { lat: 49.223404, lng: 21.2223894 },
    { lat: 49.2232706, lng: 21.2222255 },
    { lat: 49.2232671, lng: 21.2217812 },
    { lat: 49.2231893, lng: 21.2214157 },
    { lat: 49.2232247, lng: 21.2208839 },
    { lat: 49.2231572, lng: 21.2208121 },
    { lat: 49.2231775, lng: 21.2206001 },
    { lat: 49.2231389, lng: 21.2205463 },
    { lat: 49.2232113, lng: 21.220341 },
    { lat: 49.2232192, lng: 21.2200164 },
    { lat: 49.2233747, lng: 21.2191819 },
    { lat: 49.2233955, lng: 21.2181836 },
    { lat: 49.2235797, lng: 21.2163852 },
    { lat: 49.2234315, lng: 21.2155258 },
    { lat: 49.2233471, lng: 21.2153061 },
    { lat: 49.2228457, lng: 21.2144877 },
    { lat: 49.222787, lng: 21.2142979 },
    { lat: 49.2225434, lng: 21.2140595 },
    { lat: 49.2224802, lng: 21.2137995 },
    { lat: 49.2221864, lng: 21.2132141 },
    { lat: 49.2221177, lng: 21.2130265 },
    { lat: 49.2220452, lng: 21.2125068 },
    { lat: 49.2215731, lng: 21.2109973 },
    { lat: 49.2212746, lng: 21.210547 },
    { lat: 49.2204753, lng: 21.2082883 },
    { lat: 49.2201325, lng: 21.2074603 },
    { lat: 49.2201057, lng: 21.2067109 },
    { lat: 49.2198644, lng: 21.2058166 },
    { lat: 49.2195489, lng: 21.2053679 },
    { lat: 49.2193894, lng: 21.2048333 },
    { lat: 49.2191844, lng: 21.2045728 },
    { lat: 49.2187608, lng: 21.204273 },
    { lat: 49.2181163, lng: 21.2038808 },
    { lat: 49.217804, lng: 21.2037853 },
    { lat: 49.2178228, lng: 21.2035953 },
    { lat: 49.2175975, lng: 21.2035533 },
    { lat: 49.2174139, lng: 21.2034341 },
    { lat: 49.2172948, lng: 21.203221 },
    { lat: 49.2173344, lng: 21.202688 },
    { lat: 49.2171804, lng: 21.2022938 },
    { lat: 49.2168493, lng: 21.201812 },
    { lat: 49.2167834, lng: 21.2015728 },
    { lat: 49.2166336, lng: 21.20135 },
    { lat: 49.2161535, lng: 21.2009337 },
    { lat: 49.2158319, lng: 21.2009038 },
    { lat: 49.2155642, lng: 21.2005441 },
    { lat: 49.2155558, lng: 21.2001956 },
    { lat: 49.2154706, lng: 21.1997778 },
    { lat: 49.2151818, lng: 21.1993549 },
    { lat: 49.2149328, lng: 21.1987599 },
    { lat: 49.2146573, lng: 21.1982965 },
    { lat: 49.2139916, lng: 21.1960653 },
    { lat: 49.2139629, lng: 21.1958316 },
    { lat: 49.2131761, lng: 21.1944825 },
    { lat: 49.2123345, lng: 21.1934072 },
    { lat: 49.211789, lng: 21.1924329 },
    { lat: 49.2109573, lng: 21.190296 },
    { lat: 49.2105626, lng: 21.1889351 },
    { lat: 49.2102913, lng: 21.1884232 },
    { lat: 49.2096787, lng: 21.1877562 },
    { lat: 49.2091427, lng: 21.1872791 },
    { lat: 49.2087324, lng: 21.1866431 },
    { lat: 49.208067, lng: 21.1858727 },
    { lat: 49.2077147, lng: 21.1852915 },
    { lat: 49.2074887, lng: 21.1852651 },
    { lat: 49.2070143, lng: 21.1849534 },
    { lat: 49.2067641, lng: 21.1846128 },
    { lat: 49.2066001, lng: 21.1842245 },
    { lat: 49.2057059, lng: 21.1834831 },
    { lat: 49.2052773, lng: 21.1829795 },
    { lat: 49.2048512, lng: 21.1829554 },
    { lat: 49.2041209, lng: 21.1830294 },
    { lat: 49.2039254, lng: 21.1831487 },
    { lat: 49.2034046, lng: 21.1831534 },
    { lat: 49.204283, lng: 21.1753274 },
  ],
  Kríže: [
    { lat: 49.2492938, lng: 21.1262135 },
    { lat: 49.2489828, lng: 21.1264436 },
    { lat: 49.2481285, lng: 21.1289996 },
    { lat: 49.2474858, lng: 21.130061 },
    { lat: 49.2472735, lng: 21.1302503 },
    { lat: 49.2465909, lng: 21.1313621 },
    { lat: 49.2463624, lng: 21.1318238 },
    { lat: 49.246103, lng: 21.1321296 },
    { lat: 49.245543, lng: 21.132176 },
    { lat: 49.2444755, lng: 21.1319054 },
    { lat: 49.2442536, lng: 21.1319355 },
    { lat: 49.2438493, lng: 21.1322747 },
    { lat: 49.2435289, lng: 21.1322825 },
    { lat: 49.2428646, lng: 21.1317758 },
    { lat: 49.2418306, lng: 21.1311052 },
    { lat: 49.2416493, lng: 21.1311059 },
    { lat: 49.2415466, lng: 21.1310174 },
    { lat: 49.2408031, lng: 21.131203 },
    { lat: 49.2406487, lng: 21.1307728 },
    { lat: 49.2405155, lng: 21.1300804 },
    { lat: 49.2405095, lng: 21.1296091 },
    { lat: 49.2404452, lng: 21.1293196 },
    { lat: 49.2400747, lng: 21.1287441 },
    { lat: 49.2398176, lng: 21.1281919 },
    { lat: 49.2396499, lng: 21.1279836 },
    { lat: 49.239232, lng: 21.1280681 },
    { lat: 49.2384539, lng: 21.1280935 },
    { lat: 49.238055, lng: 21.1278273 },
    { lat: 49.237825, lng: 21.1277719 },
    { lat: 49.2369452, lng: 21.1279212 },
    { lat: 49.2365871, lng: 21.1278428 },
    { lat: 49.2360395, lng: 21.1275711 },
    { lat: 49.2352323, lng: 21.1275241 },
    { lat: 49.2346424, lng: 21.1275882 },
    { lat: 49.2343981, lng: 21.1275504 },
    { lat: 49.233785, lng: 21.1270952 },
    { lat: 49.2331947, lng: 21.1264666 },
    { lat: 49.2329759, lng: 21.1261316 },
    { lat: 49.2327855, lng: 21.125378 },
    { lat: 49.2323537, lng: 21.1248929 },
    { lat: 49.2323542, lng: 21.1244845 },
    { lat: 49.2318689, lng: 21.1242618 },
    { lat: 49.2317771, lng: 21.1239925 },
    { lat: 49.2314696, lng: 21.1240508 },
    { lat: 49.2314077, lng: 21.1242992 },
    { lat: 49.2311866, lng: 21.1243901 },
    { lat: 49.2307515, lng: 21.1233032 },
    { lat: 49.2304658, lng: 21.1227719 },
    { lat: 49.2299887, lng: 21.1222034 },
    { lat: 49.2295617, lng: 21.1215894 },
    { lat: 49.2294143, lng: 21.1212701 },
    { lat: 49.2289884, lng: 21.1207184 },
    { lat: 49.2288192, lng: 21.1205282 },
    { lat: 49.2284732, lng: 21.1203021 },
    { lat: 49.2282421, lng: 21.1202882 },
    { lat: 49.2281532, lng: 21.1202207 },
    { lat: 49.226929, lng: 21.1202897 },
    { lat: 49.2266373, lng: 21.1199624 },
    { lat: 49.2263169, lng: 21.1198323 },
    { lat: 49.2256492, lng: 21.1199232 },
    { lat: 49.2255156, lng: 21.1200072 },
    { lat: 49.2253373, lng: 21.119956 },
    { lat: 49.2251566, lng: 21.119722 },
    { lat: 49.2245638, lng: 21.119516 },
    { lat: 49.2242315, lng: 21.1193282 },
    { lat: 49.2241174, lng: 21.1191365 },
    { lat: 49.2235309, lng: 21.1189545 },
    { lat: 49.223223, lng: 21.1189348 },
    { lat: 49.2224905, lng: 21.1192893 },
    { lat: 49.2222954, lng: 21.1193088 },
    { lat: 49.2222027, lng: 21.1190838 },
    { lat: 49.221681, lng: 21.118311 },
    { lat: 49.221326, lng: 21.119251 },
    { lat: 49.220853, lng: 21.120115 },
    { lat: 49.220602, lng: 21.120688 },
    { lat: 49.220338, lng: 21.120866 },
    { lat: 49.220277, lng: 21.120933 },
    { lat: 49.220098, lng: 21.121213 },
    { lat: 49.219538, lng: 21.121503 },
    { lat: 49.219244, lng: 21.121774 },
    { lat: 49.218819, lng: 21.122038 },
    { lat: 49.218609, lng: 21.122228 },
    { lat: 49.218277, lng: 21.122714 },
    { lat: 49.21803, lng: 21.122961 },
    { lat: 49.217544, lng: 21.123575 },
    { lat: 49.217006, lng: 21.124097 },
    { lat: 49.216792, lng: 21.124409 },
    { lat: 49.216679, lng: 21.124526 },
    { lat: 49.216597, lng: 21.124545 },
    { lat: 49.215839, lng: 21.125012 },
    { lat: 49.215456, lng: 21.125461 },
    { lat: 49.214964, lng: 21.12591 },
    { lat: 49.213986, lng: 21.125475 },
    { lat: 49.213705, lng: 21.125382 },
    { lat: 49.213548, lng: 21.125304 },
    { lat: 49.213442, lng: 21.125326 },
    { lat: 49.212559, lng: 21.1259 },
    { lat: 49.212598, lng: 21.126962 },
    { lat: 49.21253, lng: 21.127712 },
    { lat: 49.212474, lng: 21.128168 },
    { lat: 49.212475, lng: 21.128889 },
    { lat: 49.212402, lng: 21.129088 },
    { lat: 49.212084, lng: 21.130264 },
    { lat: 49.21198, lng: 21.130561 },
    { lat: 49.21175, lng: 21.130882 },
    { lat: 49.211557, lng: 21.131246 },
    { lat: 49.211296, lng: 21.131574 },
    { lat: 49.211146, lng: 21.132002 },
    { lat: 49.21094, lng: 21.132316 },
    { lat: 49.210684, lng: 21.132613 },
    { lat: 49.210502, lng: 21.132726 },
    { lat: 49.210263, lng: 21.133148 },
    { lat: 49.209951, lng: 21.133655 },
    { lat: 49.209728, lng: 21.134039 },
    { lat: 49.209553, lng: 21.13426 },
    { lat: 49.209358, lng: 21.134704 },
    { lat: 49.208999, lng: 21.13529 },
    { lat: 49.208828, lng: 21.135626 },
    { lat: 49.208786, lng: 21.135978 },
    { lat: 49.208848, lng: 21.136282 },
    { lat: 49.208855, lng: 21.136579 },
    { lat: 49.208707, lng: 21.136843 },
    { lat: 49.208664, lng: 21.137209 },
    { lat: 49.208538, lng: 21.137768 },
    { lat: 49.208521, lng: 21.138025 },
    { lat: 49.208157, lng: 21.139031 },
    { lat: 49.207831, lng: 21.139467 },
    { lat: 49.207603, lng: 21.139642 },
    { lat: 49.207274, lng: 21.13966 },
    { lat: 49.206832, lng: 21.139626 },
    { lat: 49.206479, lng: 21.139702 },
    { lat: 49.205938, lng: 21.139672 },
    { lat: 49.205539, lng: 21.139705 },
    { lat: 49.205284, lng: 21.1398 },
    { lat: 49.205092, lng: 21.139959 },
    { lat: 49.204933, lng: 21.140172 },
    { lat: 49.204735, lng: 21.140346 },
    { lat: 49.204459, lng: 21.140514 },
    { lat: 49.203463, lng: 21.140974 },
    { lat: 49.20301, lng: 21.141121 },
    { lat: 49.202414, lng: 21.141175 },
    { lat: 49.202142, lng: 21.141235 },
    { lat: 49.201759, lng: 21.141096 },
    { lat: 49.201657, lng: 21.141088 },
    { lat: 49.201624, lng: 21.141448 },
    { lat: 49.201432, lng: 21.141784 },
    { lat: 49.200586, lng: 21.143619 },
    { lat: 49.20038, lng: 21.144002 },
    { lat: 49.200052, lng: 21.14457 },
    { lat: 49.199381, lng: 21.145447 },
    { lat: 49.198654, lng: 21.146304 },
    { lat: 49.198619, lng: 21.146318 },
    { lat: 49.198666, lng: 21.147017 },
    { lat: 49.198505, lng: 21.148195 },
    { lat: 49.198306, lng: 21.149106 },
    { lat: 49.198247, lng: 21.149811 },
    { lat: 49.198272, lng: 21.150575 },
    { lat: 49.198352, lng: 21.151425 },
    { lat: 49.198395, lng: 21.151647 },
    { lat: 49.198536, lng: 21.152645 },
    { lat: 49.198608, lng: 21.152986 },
    { lat: 49.198834, lng: 21.153544 },
    { lat: 49.198887, lng: 21.15383 },
    { lat: 49.198832, lng: 21.15415 },
    { lat: 49.198395, lng: 21.155768 },
    { lat: 49.19803, lng: 21.15654 },
    { lat: 49.197923, lng: 21.156725 },
    { lat: 49.197877, lng: 21.157183 },
    { lat: 49.197632, lng: 21.157653 },
    { lat: 49.1971, lng: 21.158384 },
    { lat: 49.196908, lng: 21.158585 },
    { lat: 49.196592, lng: 21.159521 },
    { lat: 49.19657, lng: 21.159785 },
    { lat: 49.196215, lng: 21.161036 },
    { lat: 49.196097, lng: 21.161663 },
    { lat: 49.196126, lng: 21.16206 },
    { lat: 49.19612, lng: 21.16247 },
    { lat: 49.196172, lng: 21.163197 },
    { lat: 49.196203, lng: 21.163403 },
    { lat: 49.196315, lng: 21.163857 },
    { lat: 49.196432, lng: 21.164138 },
    { lat: 49.196421, lng: 21.164409 },
    { lat: 49.196435, lng: 21.16495 },
    { lat: 49.196571, lng: 21.165885 },
    { lat: 49.196464, lng: 21.166493 },
    { lat: 49.196428, lng: 21.166615 },
    { lat: 49.196365, lng: 21.166932 },
    { lat: 49.196266, lng: 21.167171 },
    { lat: 49.19619, lng: 21.167506 },
    { lat: 49.196087, lng: 21.167711 },
    { lat: 49.1963154, lng: 21.1680429 },
    { lat: 49.1964488, lng: 21.1679961 },
    { lat: 49.1968762, lng: 21.1677361 },
    { lat: 49.197034, lng: 21.1677076 },
    { lat: 49.1975975, lng: 21.1673743 },
    { lat: 49.1979808, lng: 21.1672211 },
    { lat: 49.1987901, lng: 21.1666111 },
    { lat: 49.1990881, lng: 21.1664688 },
    { lat: 49.2001998, lng: 21.1662465 },
    { lat: 49.2005163, lng: 21.1663328 },
    { lat: 49.2007441, lng: 21.16649 },
    { lat: 49.2008758, lng: 21.1667434 },
    { lat: 49.2010407, lng: 21.1669012 },
    { lat: 49.2015033, lng: 21.1696386 },
    { lat: 49.201339, lng: 21.1697452 },
    { lat: 49.2009163, lng: 21.1697178 },
    { lat: 49.1995464, lng: 21.1698494 },
    { lat: 49.1998471, lng: 21.1706115 },
    { lat: 49.2005069, lng: 21.1717052 },
    { lat: 49.2010574, lng: 21.1724606 },
    { lat: 49.201356, lng: 21.1725839 },
    { lat: 49.2022393, lng: 21.173233 },
    { lat: 49.2030753, lng: 21.1742761 },
    { lat: 49.2033175, lng: 21.1748187 },
    { lat: 49.2037504, lng: 21.175278 },
    { lat: 49.204283, lng: 21.1753274 },
    { lat: 49.2044732, lng: 21.1752506 },
    { lat: 49.204508, lng: 21.1750738 },
    { lat: 49.2052259, lng: 21.1748527 },
    { lat: 49.2055953, lng: 21.1744724 },
    { lat: 49.2057981, lng: 21.1744436 },
    { lat: 49.2059226, lng: 21.1742463 },
    { lat: 49.2060568, lng: 21.1741861 },
    { lat: 49.2063282, lng: 21.1738698 },
    { lat: 49.2064886, lng: 21.1738189 },
    { lat: 49.2067107, lng: 21.1739409 },
    { lat: 49.2068784, lng: 21.1738668 },
    { lat: 49.2069155, lng: 21.173755 },
    { lat: 49.2071271, lng: 21.173795 },
    { lat: 49.2071701, lng: 21.173694 },
    { lat: 49.2071836, lng: 21.1734083 },
    { lat: 49.2076438, lng: 21.1729767 },
    { lat: 49.2077758, lng: 21.17302 },
    { lat: 49.2078151, lng: 21.1729491 },
    { lat: 49.2079885, lng: 21.1729523 },
    { lat: 49.2080657, lng: 21.1726865 },
    { lat: 49.2081604, lng: 21.17269 },
    { lat: 49.2084164, lng: 21.1723194 },
    { lat: 49.2083703, lng: 21.1721705 },
    { lat: 49.2085996, lng: 21.1721959 },
    { lat: 49.2086504, lng: 21.1720284 },
    { lat: 49.2088456, lng: 21.1718598 },
    { lat: 49.2089881, lng: 21.1718483 },
    { lat: 49.2090702, lng: 21.171594 },
    { lat: 49.2096861, lng: 21.1714281 },
    { lat: 49.209913, lng: 21.1712338 },
    { lat: 49.2101641, lng: 21.1712407 },
    { lat: 49.2103555, lng: 21.1713509 },
    { lat: 49.2105938, lng: 21.1711294 },
    { lat: 49.2109041, lng: 21.1704362 },
    { lat: 49.2111379, lng: 21.1702158 },
    { lat: 49.2112225, lng: 21.1700394 },
    { lat: 49.2115257, lng: 21.1700536 },
    { lat: 49.2116638, lng: 21.1699392 },
    { lat: 49.2125443, lng: 21.1698504 },
    { lat: 49.2127217, lng: 21.1699105 },
    { lat: 49.2127715, lng: 21.1698454 },
    { lat: 49.2127396, lng: 21.1696173 },
    { lat: 49.2127697, lng: 21.1695705 },
    { lat: 49.2129629, lng: 21.1697392 },
    { lat: 49.2130979, lng: 21.1696675 },
    { lat: 49.2132169, lng: 21.1698067 },
    { lat: 49.2137558, lng: 21.1699448 },
    { lat: 49.2138608, lng: 21.1698821 },
    { lat: 49.2139045, lng: 21.1696284 },
    { lat: 49.2140006, lng: 21.1694569 },
    { lat: 49.2144813, lng: 21.1690338 },
    { lat: 49.21478, lng: 21.1683706 },
    { lat: 49.2148554, lng: 21.1685486 },
    { lat: 49.2151801, lng: 21.1686779 },
    { lat: 49.2154863, lng: 21.1686814 },
    { lat: 49.215568, lng: 21.1685272 },
    { lat: 49.2155329, lng: 21.1683393 },
    { lat: 49.2155635, lng: 21.1682701 },
    { lat: 49.2158103, lng: 21.1682147 },
    { lat: 49.2159225, lng: 21.1679884 },
    { lat: 49.2158773, lng: 21.1674798 },
    { lat: 49.216214, lng: 21.1671251 },
    { lat: 49.2163206, lng: 21.1666883 },
    { lat: 49.2164227, lng: 21.1665005 },
    { lat: 49.217035, lng: 21.1663636 },
    { lat: 49.2171956, lng: 21.1661006 },
    { lat: 49.2173107, lng: 21.1655368 },
    { lat: 49.2174862, lng: 21.1652846 },
    { lat: 49.2176182, lng: 21.1652598 },
    { lat: 49.2177589, lng: 21.1654389 },
    { lat: 49.2179035, lng: 21.1653121 },
    { lat: 49.218058, lng: 21.1653587 },
    { lat: 49.2181708, lng: 21.165579 },
    { lat: 49.2185212, lng: 21.1657367 },
    { lat: 49.2187141, lng: 21.1656848 },
    { lat: 49.2190599, lng: 21.1654406 },
    { lat: 49.2194775, lng: 21.1654501 },
    { lat: 49.2196252, lng: 21.1655282 },
    { lat: 49.2199655, lng: 21.1655051 },
    { lat: 49.2203006, lng: 21.1657848 },
    { lat: 49.2208956, lng: 21.1657064 },
    { lat: 49.2210625, lng: 21.1655005 },
    { lat: 49.2213685, lng: 21.1658234 },
    { lat: 49.2216192, lng: 21.1664635 },
    { lat: 49.2216754, lng: 21.1664939 },
    { lat: 49.2217443, lng: 21.166437 },
    { lat: 49.2217283, lng: 21.1663162 },
    { lat: 49.2218728, lng: 21.1656843 },
    { lat: 49.2221209, lng: 21.1655589 },
    { lat: 49.222498, lng: 21.1654921 },
    { lat: 49.2226774, lng: 21.1653339 },
    { lat: 49.2227699, lng: 21.1650866 },
    { lat: 49.2232222, lng: 21.1646129 },
    { lat: 49.2234296, lng: 21.1646647 },
    { lat: 49.223783, lng: 21.1644528 },
    { lat: 49.2238659, lng: 21.1639373 },
    { lat: 49.2239261, lng: 21.1638864 },
    { lat: 49.2241703, lng: 21.1640255 },
    { lat: 49.224332, lng: 21.1638737 },
    { lat: 49.2249366, lng: 21.1628437 },
    { lat: 49.2252228, lng: 21.1626414 },
    { lat: 49.2256707, lng: 21.161998 },
    { lat: 49.2258355, lng: 21.1616212 },
    { lat: 49.2264151, lng: 21.1607587 },
    { lat: 49.2264705, lng: 21.1605163 },
    { lat: 49.2266631, lng: 21.1603441 },
    { lat: 49.2273853, lng: 21.1600853 },
    { lat: 49.2275393, lng: 21.159758 },
    { lat: 49.2275017, lng: 21.1595281 },
    { lat: 49.2275392, lng: 21.1593248 },
    { lat: 49.2276872, lng: 21.1589558 },
    { lat: 49.2278366, lng: 21.1586582 },
    { lat: 49.2280678, lng: 21.1580249 },
    { lat: 49.228268, lng: 21.1577021 },
    { lat: 49.2283951, lng: 21.1573792 },
    { lat: 49.2289131, lng: 21.1568523 },
    { lat: 49.2292646, lng: 21.1556139 },
    { lat: 49.229648, lng: 21.1549229 },
    { lat: 49.230988, lng: 21.1541344 },
    { lat: 49.2317902, lng: 21.1539467 },
    { lat: 49.2323736, lng: 21.1542179 },
    { lat: 49.2327513, lng: 21.1542748 },
    { lat: 49.2338096, lng: 21.1546815 },
    { lat: 49.2339134, lng: 21.1546616 },
    { lat: 49.2344362, lng: 21.1541822 },
    { lat: 49.2350706, lng: 21.1524915 },
    { lat: 49.2355716, lng: 21.1518905 },
    { lat: 49.2363693, lng: 21.1513887 },
    { lat: 49.2366672, lng: 21.1504828 },
    { lat: 49.2367453, lng: 21.1498551 },
    { lat: 49.2371659, lng: 21.1495246 },
    { lat: 49.2377968, lng: 21.1492964 },
    { lat: 49.238499, lng: 21.1494076 },
    { lat: 49.2390329, lng: 21.1495975 },
    { lat: 49.2393319, lng: 21.1503771 },
    { lat: 49.2396946, lng: 21.1507078 },
    { lat: 49.2397236, lng: 21.1513074 },
    { lat: 49.2396279, lng: 21.1516673 },
    { lat: 49.2402409, lng: 21.151905 },
    { lat: 49.2409583, lng: 21.1516752 },
    { lat: 49.2418312, lng: 21.1512416 },
    { lat: 49.2420709, lng: 21.1510296 },
    { lat: 49.2424918, lng: 21.1508092 },
    { lat: 49.2425867, lng: 21.1503953 },
    { lat: 49.2429053, lng: 21.1501479 },
    { lat: 49.2428838, lng: 21.1500349 },
    { lat: 49.2431451, lng: 21.150042 },
    { lat: 49.2439191, lng: 21.1504678 },
    { lat: 49.2442032, lng: 21.1505244 },
    { lat: 49.2449622, lng: 21.150008 },
    { lat: 49.2450186, lng: 21.1502563 },
    { lat: 49.2451894, lng: 21.1502506 },
    { lat: 49.2455688, lng: 21.1504378 },
    { lat: 49.2458566, lng: 21.1507124 },
    { lat: 49.2461988, lng: 21.1509329 },
    { lat: 49.246313, lng: 21.1508737 },
    { lat: 49.2466866, lng: 21.1509507 },
    { lat: 49.247116, lng: 21.1507186 },
    { lat: 49.2474876, lng: 21.1509074 },
    { lat: 49.2479224, lng: 21.1509205 },
    { lat: 49.2485603, lng: 21.1517766 },
    { lat: 49.2490621, lng: 21.1521303 },
    { lat: 49.2495643, lng: 21.1528577 },
    { lat: 49.2498819, lng: 21.1531564 },
    { lat: 49.2503194, lng: 21.1530771 },
    { lat: 49.2505417, lng: 21.1535237 },
    { lat: 49.2506686, lng: 21.1536232 },
    { lat: 49.2509144, lng: 21.1536437 },
    { lat: 49.2512268, lng: 21.1540926 },
    { lat: 49.2515867, lng: 21.1541926 },
    { lat: 49.2527509, lng: 21.1539296 },
    { lat: 49.2530626, lng: 21.1539993 },
    { lat: 49.2534445, lng: 21.1541907 },
    { lat: 49.2534911, lng: 21.1540983 },
    { lat: 49.2535935, lng: 21.1538901 },
    { lat: 49.2571243, lng: 21.1490466 },
    { lat: 49.2569007, lng: 21.1486692 },
    { lat: 49.2568232, lng: 21.1483878 },
    { lat: 49.2562831, lng: 21.1472081 },
    { lat: 49.2562329, lng: 21.1467521 },
    { lat: 49.2562283, lng: 21.1458933 },
    { lat: 49.2560777, lng: 21.1446478 },
    { lat: 49.2562894, lng: 21.1440476 },
    { lat: 49.2562822, lng: 21.1434281 },
    { lat: 49.2560046, lng: 21.1417212 },
    { lat: 49.2559661, lng: 21.1412201 },
    { lat: 49.2559992, lng: 21.1403775 },
    { lat: 49.2559239, lng: 21.1395225 },
    { lat: 49.2560203, lng: 21.1391933 },
    { lat: 49.2560142, lng: 21.1389607 },
    { lat: 49.2557319, lng: 21.1351896 },
    { lat: 49.2549593, lng: 21.1314317 },
    { lat: 49.254526, lng: 21.1308559 },
    { lat: 49.2545765, lng: 21.1304614 },
    { lat: 49.2547325, lng: 21.130309 },
    { lat: 49.2547582, lng: 21.1301608 },
    { lat: 49.2547112, lng: 21.1299925 },
    { lat: 49.2545811, lng: 21.1299643 },
    { lat: 49.2543568, lng: 21.1293144 },
    { lat: 49.2541799, lng: 21.1291257 },
    { lat: 49.253817, lng: 21.1279047 },
    { lat: 49.2535447, lng: 21.1273715 },
    { lat: 49.2533699, lng: 21.1268978 },
    { lat: 49.2528269, lng: 21.1263118 },
    { lat: 49.2526656, lng: 21.1262236 },
    { lat: 49.2524479, lng: 21.1263561 },
    { lat: 49.2513552, lng: 21.1264891 },
    { lat: 49.2500007, lng: 21.126145 },
    { lat: 49.2492938, lng: 21.1262135 },
  ],
  Raslavice: [
    { lat: 49.1461201, lng: 21.3519309 },
    { lat: 49.1475612, lng: 21.350755 },
    { lat: 49.1480324, lng: 21.351692 },
    { lat: 49.1481925, lng: 21.3517791 },
    { lat: 49.1483842, lng: 21.3522449 },
    { lat: 49.148657, lng: 21.3523349 },
    { lat: 49.1488282, lng: 21.3527935 },
    { lat: 49.1491075, lng: 21.3529949 },
    { lat: 49.1496571, lng: 21.3530115 },
    { lat: 49.1502251, lng: 21.352814 },
    { lat: 49.1508101, lng: 21.351986 },
    { lat: 49.1512138, lng: 21.3508782 },
    { lat: 49.151324, lng: 21.3503508 },
    { lat: 49.1513045, lng: 21.3496578 },
    { lat: 49.151061, lng: 21.3496448 },
    { lat: 49.1509837, lng: 21.3493863 },
    { lat: 49.1509601, lng: 21.3489728 },
    { lat: 49.1516288, lng: 21.3480048 },
    { lat: 49.1519234, lng: 21.3474395 },
    { lat: 49.1514207, lng: 21.3465779 },
    { lat: 49.1509626, lng: 21.3459684 },
    { lat: 49.1506913, lng: 21.3463762 },
    { lat: 49.1504601, lng: 21.3460622 },
    { lat: 49.1502558, lng: 21.3455836 },
    { lat: 49.1498793, lng: 21.3457104 },
    { lat: 49.1496932, lng: 21.3455828 },
    { lat: 49.1495474, lng: 21.3449553 },
    { lat: 49.1495646, lng: 21.3447841 },
    { lat: 49.1494082, lng: 21.344401 },
    { lat: 49.1495937, lng: 21.3442893 },
    { lat: 49.1499879, lng: 21.3434374 },
    { lat: 49.1500774, lng: 21.3427788 },
    { lat: 49.1505484, lng: 21.3425699 },
    { lat: 49.1507989, lng: 21.3405627 },
    { lat: 49.1514915, lng: 21.3393047 },
    { lat: 49.1519876, lng: 21.3390764 },
    { lat: 49.1528463, lng: 21.3396145 },
    { lat: 49.1528716, lng: 21.3395125 },
    { lat: 49.1525971, lng: 21.3382041 },
    { lat: 49.1522356, lng: 21.3378536 },
    { lat: 49.1522068, lng: 21.3376795 },
    { lat: 49.1528082, lng: 21.3372145 },
    { lat: 49.1529472, lng: 21.3363285 },
    { lat: 49.1517323, lng: 21.3348721 },
    { lat: 49.1524405, lng: 21.334738 },
    { lat: 49.1524257, lng: 21.3341704 },
    { lat: 49.1529322, lng: 21.3342505 },
    { lat: 49.1533274, lng: 21.3341446 },
    { lat: 49.1535818, lng: 21.3338113 },
    { lat: 49.1537717, lng: 21.3342891 },
    { lat: 49.1549892, lng: 21.333765 },
    { lat: 49.1553954, lng: 21.3339931 },
    { lat: 49.1553701, lng: 21.3343532 },
    { lat: 49.1554435, lng: 21.3344732 },
    { lat: 49.1554788, lng: 21.3352332 },
    { lat: 49.1558914, lng: 21.3354137 },
    { lat: 49.1565381, lng: 21.3346885 },
    { lat: 49.1569265, lng: 21.3345483 },
    { lat: 49.1572164, lng: 21.3343099 },
    { lat: 49.1571867, lng: 21.3326827 },
    { lat: 49.1581443, lng: 21.331333 },
    { lat: 49.1577506, lng: 21.3306181 },
    { lat: 49.1578505, lng: 21.3304652 },
    { lat: 49.1580694, lng: 21.3289048 },
    { lat: 49.158355, lng: 21.3282245 },
    { lat: 49.1586229, lng: 21.327897 },
    { lat: 49.1589443, lng: 21.327615 },
    { lat: 49.1590912, lng: 21.327379 },
    { lat: 49.1593404, lng: 21.3274328 },
    { lat: 49.1597098, lng: 21.3273294 },
    { lat: 49.1598985, lng: 21.3278425 },
    { lat: 49.16009, lng: 21.3277727 },
    { lat: 49.1601908, lng: 21.3275726 },
    { lat: 49.1602427, lng: 21.3272048 },
    { lat: 49.1605958, lng: 21.3258795 },
    { lat: 49.1606789, lng: 21.3257274 },
    { lat: 49.1623022, lng: 21.3251531 },
    { lat: 49.162726, lng: 21.3251786 },
    { lat: 49.165757, lng: 21.3234852 },
    { lat: 49.1688681, lng: 21.3216495 },
    { lat: 49.1701473, lng: 21.3210077 },
    { lat: 49.1710088, lng: 21.3207335 },
    { lat: 49.1710893, lng: 21.3204521 },
    { lat: 49.1696998, lng: 21.3202713 },
    { lat: 49.167723, lng: 21.3197522 },
    { lat: 49.1664196, lng: 21.3193321 },
    { lat: 49.1661826, lng: 21.3184055 },
    { lat: 49.1672616, lng: 21.317584 },
    { lat: 49.1688438, lng: 21.3166171 },
    { lat: 49.1683517, lng: 21.3138052 },
    { lat: 49.1681619, lng: 21.3132166 },
    { lat: 49.1678668, lng: 21.313284 },
    { lat: 49.1677985, lng: 21.3125027 },
    { lat: 49.1677998, lng: 21.311804 },
    { lat: 49.1675403, lng: 21.3116672 },
    { lat: 49.1673004, lng: 21.3118106 },
    { lat: 49.1670097, lng: 21.3125088 },
    { lat: 49.1661729, lng: 21.3125135 },
    { lat: 49.1655726, lng: 21.3128367 },
    { lat: 49.1651226, lng: 21.3133561 },
    { lat: 49.1650077, lng: 21.3136202 },
    { lat: 49.1647153, lng: 21.3139927 },
    { lat: 49.1635515, lng: 21.3146221 },
    { lat: 49.1629448, lng: 21.3152064 },
    { lat: 49.1627333, lng: 21.3152457 },
    { lat: 49.1621899, lng: 21.3156573 },
    { lat: 49.1615259, lng: 21.3164364 },
    { lat: 49.1613132, lng: 21.3162874 },
    { lat: 49.1606298, lng: 21.3168339 },
    { lat: 49.1606286, lng: 21.3167236 },
    { lat: 49.1606062, lng: 21.3141068 },
    { lat: 49.1603754, lng: 21.3138023 },
    { lat: 49.1589809, lng: 21.3122584 },
    { lat: 49.1572765, lng: 21.3098057 },
    { lat: 49.1573946, lng: 21.3092114 },
    { lat: 49.1574811, lng: 21.3083498 },
    { lat: 49.1567782, lng: 21.30821 },
    { lat: 49.1567412, lng: 21.307133 },
    { lat: 49.1568309, lng: 21.3048475 },
    { lat: 49.1569433, lng: 21.3040247 },
    { lat: 49.157112, lng: 21.3031648 },
    { lat: 49.1574328, lng: 21.3030585 },
    { lat: 49.1574755, lng: 21.302723 },
    { lat: 49.1565462, lng: 21.3023946 },
    { lat: 49.1562831, lng: 21.30221 },
    { lat: 49.1566679, lng: 21.3014273 },
    { lat: 49.1566437, lng: 21.3000762 },
    { lat: 49.1567083, lng: 21.2992323 },
    { lat: 49.1567518, lng: 21.2991037 },
    { lat: 49.1567508, lng: 21.2972575 },
    { lat: 49.1565763, lng: 21.294795 },
    { lat: 49.1565455, lng: 21.2930015 },
    { lat: 49.1563246, lng: 21.2910568 },
    { lat: 49.1561203, lng: 21.2898165 },
    { lat: 49.1558952, lng: 21.2887701 },
    { lat: 49.1558602, lng: 21.2868022 },
    { lat: 49.155737, lng: 21.2858216 },
    { lat: 49.1553267, lng: 21.2842433 },
    { lat: 49.1546394, lng: 21.2822123 },
    { lat: 49.154644, lng: 21.2820307 },
    { lat: 49.1540171, lng: 21.2820618 },
    { lat: 49.1529428, lng: 21.2819306 },
    { lat: 49.1523798, lng: 21.2820646 },
    { lat: 49.152196, lng: 21.281864 },
    { lat: 49.1518948, lng: 21.2823591 },
    { lat: 49.1516476, lng: 21.282589 },
    { lat: 49.1509537, lng: 21.2829687 },
    { lat: 49.1506646, lng: 21.2830445 },
    { lat: 49.1501195, lng: 21.2828443 },
    { lat: 49.1498554, lng: 21.2825661 },
    { lat: 49.149623, lng: 21.282141 },
    { lat: 49.149482, lng: 21.282323 },
    { lat: 49.148901, lng: 21.282955 },
    { lat: 49.148594, lng: 21.283521 },
    { lat: 49.148447, lng: 21.283768 },
    { lat: 49.148257, lng: 21.284078 },
    { lat: 49.147925, lng: 21.285562 },
    { lat: 49.147823, lng: 21.285726 },
    { lat: 49.147707, lng: 21.28575 },
    { lat: 49.147676, lng: 21.285814 },
    { lat: 49.147663, lng: 21.286758 },
    { lat: 49.14754, lng: 21.287469 },
    { lat: 49.14738, lng: 21.28807 },
    { lat: 49.147384, lng: 21.288852 },
    { lat: 49.147246, lng: 21.289256 },
    { lat: 49.147316, lng: 21.289787 },
    { lat: 49.147469, lng: 21.290226 },
    { lat: 49.14747, lng: 21.290572 },
    { lat: 49.147403, lng: 21.290722 },
    { lat: 49.147215, lng: 21.290964 },
    { lat: 49.146773, lng: 21.291173 },
    { lat: 49.146709, lng: 21.291378 },
    { lat: 49.146655, lng: 21.291689 },
    { lat: 49.146634, lng: 21.29187 },
    { lat: 49.146442, lng: 21.29216 },
    { lat: 49.146278, lng: 21.292323 },
    { lat: 49.146148, lng: 21.29255 },
    { lat: 49.146199, lng: 21.292634 },
    { lat: 49.146155, lng: 21.292773 },
    { lat: 49.146098, lng: 21.292848 },
    { lat: 49.14605, lng: 21.293126 },
    { lat: 49.145948, lng: 21.293324 },
    { lat: 49.14574, lng: 21.293062 },
    { lat: 49.145525, lng: 21.293114 },
    { lat: 49.145279, lng: 21.293314 },
    { lat: 49.145218, lng: 21.293409 },
    { lat: 49.144999, lng: 21.293945 },
    { lat: 49.144642, lng: 21.294325 },
    { lat: 49.144368, lng: 21.294474 },
    { lat: 49.14373, lng: 21.294564 },
    { lat: 49.143261, lng: 21.294395 },
    { lat: 49.143134, lng: 21.294406 },
    { lat: 49.142986, lng: 21.294456 },
    { lat: 49.142883, lng: 21.294518 },
    { lat: 49.14282, lng: 21.294646 },
    { lat: 49.142717, lng: 21.294863 },
    { lat: 49.142676, lng: 21.294995 },
    { lat: 49.142515, lng: 21.295224 },
    { lat: 49.142282, lng: 21.295124 },
    { lat: 49.14206, lng: 21.294834 },
    { lat: 49.141688, lng: 21.294827 },
    { lat: 49.14163, lng: 21.294915 },
    { lat: 49.141547, lng: 21.295003 },
    { lat: 49.141517, lng: 21.294975 },
    { lat: 49.141451, lng: 21.294956 },
    { lat: 49.141197, lng: 21.2944 },
    { lat: 49.140971, lng: 21.294073 },
    { lat: 49.140779, lng: 21.294087 },
    { lat: 49.140469, lng: 21.294527 },
    { lat: 49.14028, lng: 21.294517 },
    { lat: 49.140091, lng: 21.294323 },
    { lat: 49.139921, lng: 21.294019 },
    { lat: 49.139689, lng: 21.293648 },
    { lat: 49.139501, lng: 21.293704 },
    { lat: 49.139046, lng: 21.294019 },
    { lat: 49.138701, lng: 21.294232 },
    { lat: 49.138627, lng: 21.294305 },
    { lat: 49.13848, lng: 21.294395 },
    { lat: 49.13844, lng: 21.294471 },
    { lat: 49.13835, lng: 21.294754 },
    { lat: 49.138245, lng: 21.294714 },
    { lat: 49.138186, lng: 21.2947 },
    { lat: 49.138126, lng: 21.294685 },
    { lat: 49.137971, lng: 21.294647 },
    { lat: 49.137731, lng: 21.294478 },
    { lat: 49.137494, lng: 21.294415 },
    { lat: 49.137316, lng: 21.294244 },
    { lat: 49.137032, lng: 21.2943 },
    { lat: 49.136587, lng: 21.294145 },
    { lat: 49.136392, lng: 21.293964 },
    { lat: 49.135976, lng: 21.293833 },
    { lat: 49.135604, lng: 21.293855 },
    { lat: 49.135236, lng: 21.293833 },
    { lat: 49.13433, lng: 21.29446 },
    { lat: 49.134299, lng: 21.294883 },
    { lat: 49.133822, lng: 21.29493 },
    { lat: 49.132989, lng: 21.294878 },
    { lat: 49.132951, lng: 21.294876 },
    { lat: 49.132799, lng: 21.294866 },
    { lat: 49.132122, lng: 21.294437 },
    { lat: 49.132096, lng: 21.294389 },
    { lat: 49.131887, lng: 21.294481 },
    { lat: 49.131545, lng: 21.294578 },
    { lat: 49.130958, lng: 21.294929 },
    { lat: 49.130642, lng: 21.295006 },
    { lat: 49.130322, lng: 21.294997 },
    { lat: 49.130079, lng: 21.294942 },
    { lat: 49.12966, lng: 21.29497 },
    { lat: 49.129555, lng: 21.295024 },
    { lat: 49.129511, lng: 21.295174 },
    { lat: 49.129488, lng: 21.295187 },
    { lat: 49.129364, lng: 21.295043 },
    { lat: 49.129171, lng: 21.295269 },
    { lat: 49.129016, lng: 21.295384 },
    { lat: 49.128815, lng: 21.295454 },
    { lat: 49.128542, lng: 21.295616 },
    { lat: 49.128375, lng: 21.295447 },
    { lat: 49.128055, lng: 21.295451 },
    { lat: 49.127985, lng: 21.295277 },
    { lat: 49.127869, lng: 21.295084 },
    { lat: 49.127729, lng: 21.295001 },
    { lat: 49.127481, lng: 21.295033 },
    { lat: 49.127328, lng: 21.294913 },
    { lat: 49.127181, lng: 21.294895 },
    { lat: 49.127156, lng: 21.294887 },
    { lat: 49.12715, lng: 21.294943 },
    { lat: 49.127154, lng: 21.295084 },
    { lat: 49.126993, lng: 21.295685 },
    { lat: 49.126855, lng: 21.296129 },
    { lat: 49.126881, lng: 21.296296 },
    { lat: 49.126769, lng: 21.29641 },
    { lat: 49.126775, lng: 21.296477 },
    { lat: 49.126871, lng: 21.296649 },
    { lat: 49.126839, lng: 21.296746 },
    { lat: 49.126666, lng: 21.296762 },
    { lat: 49.126377, lng: 21.297242 },
    { lat: 49.126218, lng: 21.297047 },
    { lat: 49.126088, lng: 21.297102 },
    { lat: 49.125961, lng: 21.297231 },
    { lat: 49.125839, lng: 21.297601 },
    { lat: 49.125517, lng: 21.298017 },
    { lat: 49.125381, lng: 21.298332 },
    { lat: 49.125334, lng: 21.298893 },
    { lat: 49.125142, lng: 21.298898 },
    { lat: 49.124952, lng: 21.299198 },
    { lat: 49.124541, lng: 21.299648 },
    { lat: 49.124713, lng: 21.299939 },
    { lat: 49.124696, lng: 21.30005 },
    { lat: 49.124543, lng: 21.300265 },
    { lat: 49.124458, lng: 21.300765 },
    { lat: 49.124341, lng: 21.301216 },
    { lat: 49.124297, lng: 21.301251 },
    { lat: 49.123949, lng: 21.301215 },
    { lat: 49.124007, lng: 21.301561 },
    { lat: 49.123916, lng: 21.301716 },
    { lat: 49.1239, lng: 21.301814 },
    { lat: 49.123916, lng: 21.302014 },
    { lat: 49.123798, lng: 21.302319 },
    { lat: 49.123722, lng: 21.302991 },
    { lat: 49.123772, lng: 21.303252 },
    { lat: 49.12383, lng: 21.303364 },
    { lat: 49.123791, lng: 21.303541 },
    { lat: 49.123842, lng: 21.303945 },
    { lat: 49.12376, lng: 21.30432 },
    { lat: 49.123302, lng: 21.305308 },
    { lat: 49.122967, lng: 21.305919 },
    { lat: 49.12273, lng: 21.306555 },
    { lat: 49.122564, lng: 21.306945 },
    { lat: 49.122203, lng: 21.307487 },
    { lat: 49.12187, lng: 21.307628 },
    { lat: 49.121774, lng: 21.307939 },
    { lat: 49.121643, lng: 21.308113 },
    { lat: 49.121278, lng: 21.308158 },
    { lat: 49.121107, lng: 21.308423 },
    { lat: 49.120769, lng: 21.308765 },
    { lat: 49.120624, lng: 21.309113 },
    { lat: 49.120484, lng: 21.309211 },
    { lat: 49.120489, lng: 21.309269 },
    { lat: 49.12012, lng: 21.309533 },
    { lat: 49.11987, lng: 21.309776 },
    { lat: 49.119582, lng: 21.310118 },
    { lat: 49.119502, lng: 21.310243 },
    { lat: 49.119284, lng: 21.310405 },
    { lat: 49.119264, lng: 21.310416 },
    { lat: 49.119239, lng: 21.310623 },
    { lat: 49.119197, lng: 21.310815 },
    { lat: 49.119311, lng: 21.310933 },
    { lat: 49.119456, lng: 21.310923 },
    { lat: 49.11966, lng: 21.311049 },
    { lat: 49.119678, lng: 21.311122 },
    { lat: 49.11988, lng: 21.311087 },
    { lat: 49.120213, lng: 21.310944 },
    { lat: 49.120678, lng: 21.310631 },
    { lat: 49.121013, lng: 21.310377 },
    { lat: 49.121451, lng: 21.310177 },
    { lat: 49.12192, lng: 21.310021 },
    { lat: 49.122221, lng: 21.310028 },
    { lat: 49.122214, lng: 21.310086 },
    { lat: 49.122206, lng: 21.310121 },
    { lat: 49.122196, lng: 21.310169 },
    { lat: 49.122189, lng: 21.310182 },
    { lat: 49.122208, lng: 21.310352 },
    { lat: 49.122388, lng: 21.310533 },
    { lat: 49.122563, lng: 21.310707 },
    { lat: 49.122788, lng: 21.311143 },
    { lat: 49.123193, lng: 21.311668 },
    { lat: 49.123359, lng: 21.311754 },
    { lat: 49.123612, lng: 21.311762 },
    { lat: 49.123831, lng: 21.31177 },
    { lat: 49.124102, lng: 21.311555 },
    { lat: 49.124304, lng: 21.311396 },
    { lat: 49.124449, lng: 21.311287 },
    { lat: 49.124868, lng: 21.311238 },
    { lat: 49.125277, lng: 21.311018 },
    { lat: 49.126173, lng: 21.310536 },
    { lat: 49.126448, lng: 21.310459 },
    { lat: 49.126652, lng: 21.310332 },
    { lat: 49.126915, lng: 21.310326 },
    { lat: 49.12709, lng: 21.310417 },
    { lat: 49.127285, lng: 21.310477 },
    { lat: 49.127494, lng: 21.310688 },
    { lat: 49.127719, lng: 21.310861 },
    { lat: 49.128194, lng: 21.311024 },
    { lat: 49.128385, lng: 21.311161 },
    { lat: 49.128749, lng: 21.311709 },
    { lat: 49.128723, lng: 21.311863 },
    { lat: 49.128716, lng: 21.311913 },
    { lat: 49.128702, lng: 21.312151 },
    { lat: 49.128715, lng: 21.312424 },
    { lat: 49.128895, lng: 21.313255 },
    { lat: 49.128865, lng: 21.313447 },
    { lat: 49.12889, lng: 21.31362 },
    { lat: 49.129246, lng: 21.314155 },
    { lat: 49.129396, lng: 21.31402 },
    { lat: 49.129478, lng: 21.313975 },
    { lat: 49.129509, lng: 21.313959 },
    { lat: 49.129651, lng: 21.313997 },
    { lat: 49.129728, lng: 21.314077 },
    { lat: 49.129583, lng: 21.314254 },
    { lat: 49.12955, lng: 21.314295 },
    { lat: 49.129458, lng: 21.314495 },
    { lat: 49.12989, lng: 21.315128 },
    { lat: 49.130097, lng: 21.315431 },
    { lat: 49.130405, lng: 21.315926 },
    { lat: 49.13033, lng: 21.316024 },
    { lat: 49.130282, lng: 21.316085 },
    { lat: 49.130238, lng: 21.316148 },
    { lat: 49.13016, lng: 21.316966 },
    { lat: 49.130335, lng: 21.317344 },
    { lat: 49.130576, lng: 21.317455 },
    { lat: 49.130657, lng: 21.317281 },
    { lat: 49.130806, lng: 21.317155 },
    { lat: 49.131066, lng: 21.316937 },
    { lat: 49.131321, lng: 21.316775 },
    { lat: 49.13163, lng: 21.316903 },
    { lat: 49.131785, lng: 21.316927 },
    { lat: 49.132143, lng: 21.317055 },
    { lat: 49.132373, lng: 21.317152 },
    { lat: 49.132471, lng: 21.317183 },
    { lat: 49.132453, lng: 21.317243 },
    { lat: 49.132349, lng: 21.317498 },
    { lat: 49.132302, lng: 21.317626 },
    { lat: 49.132263, lng: 21.317675 },
    { lat: 49.132252, lng: 21.317739 },
    { lat: 49.131881, lng: 21.318048 },
    { lat: 49.131847, lng: 21.318149 },
    { lat: 49.131811, lng: 21.318175 },
    { lat: 49.131851, lng: 21.318306 },
    { lat: 49.131812, lng: 21.318583 },
    { lat: 49.131764, lng: 21.31886 },
    { lat: 49.131672, lng: 21.318974 },
    { lat: 49.131646, lng: 21.319337 },
    { lat: 49.131423, lng: 21.319626 },
    { lat: 49.131301, lng: 21.319409 },
    { lat: 49.131165, lng: 21.319633 },
    { lat: 49.130952, lng: 21.319782 },
    { lat: 49.13106, lng: 21.319929 },
    { lat: 49.131076, lng: 21.320013 },
    { lat: 49.130939, lng: 21.320381 },
    { lat: 49.130697, lng: 21.320463 },
    { lat: 49.130718, lng: 21.32081 },
    { lat: 49.130624, lng: 21.32109 },
    { lat: 49.130445, lng: 21.321133 },
    { lat: 49.130417, lng: 21.321548 },
    { lat: 49.130223, lng: 21.321887 },
    { lat: 49.130082, lng: 21.322305 },
    { lat: 49.129984, lng: 21.322384 },
    { lat: 49.129898, lng: 21.322678 },
    { lat: 49.129686, lng: 21.322861 },
    { lat: 49.12957, lng: 21.323119 },
    { lat: 49.129569, lng: 21.323695 },
    { lat: 49.12958, lng: 21.323921 },
    { lat: 49.129538, lng: 21.324088 },
    { lat: 49.129531, lng: 21.324358 },
    { lat: 49.12948, lng: 21.324395 },
    { lat: 49.129366, lng: 21.324715 },
    { lat: 49.129347, lng: 21.325054 },
    { lat: 49.12929, lng: 21.325074 },
    { lat: 49.129234, lng: 21.325247 },
    { lat: 49.129256, lng: 21.325538 },
    { lat: 49.129185, lng: 21.325719 },
    { lat: 49.129189, lng: 21.32615 },
    { lat: 49.12915, lng: 21.326468 },
    { lat: 49.129136, lng: 21.326841 },
    { lat: 49.129019, lng: 21.327224 },
    { lat: 49.128987, lng: 21.327459 },
    { lat: 49.128859, lng: 21.327533 },
    { lat: 49.128737, lng: 21.327755 },
    { lat: 49.128638, lng: 21.328135 },
    { lat: 49.128645, lng: 21.328366 },
    { lat: 49.128598, lng: 21.328516 },
    { lat: 49.128491, lng: 21.3286 },
    { lat: 49.12845, lng: 21.328789 },
    { lat: 49.128212, lng: 21.329089 },
    { lat: 49.127869, lng: 21.329258 },
    { lat: 49.127782, lng: 21.329417 },
    { lat: 49.127559, lng: 21.329471 },
    { lat: 49.127183, lng: 21.329866 },
    { lat: 49.126963, lng: 21.330132 },
    { lat: 49.126658, lng: 21.330711 },
    { lat: 49.126522, lng: 21.330832 },
    { lat: 49.126371, lng: 21.331169 },
    { lat: 49.126249, lng: 21.331393 },
    { lat: 49.126015, lng: 21.331568 },
    { lat: 49.125944, lng: 21.331795 },
    { lat: 49.125849, lng: 21.331952 },
    { lat: 49.125645, lng: 21.332303 },
    { lat: 49.125273, lng: 21.332489 },
    { lat: 49.124965, lng: 21.332584 },
    { lat: 49.124666, lng: 21.332672 },
    { lat: 49.124213, lng: 21.332791 },
    { lat: 49.124079, lng: 21.332888 },
    { lat: 49.123986, lng: 21.333351 },
    { lat: 49.124004, lng: 21.33344 },
    { lat: 49.124316, lng: 21.333879 },
    { lat: 49.124513, lng: 21.334209 },
    { lat: 49.125212, lng: 21.335266 },
    { lat: 49.125327, lng: 21.335705 },
    { lat: 49.125401, lng: 21.336083 },
    { lat: 49.125427, lng: 21.336485 },
    { lat: 49.12553, lng: 21.336861 },
    { lat: 49.125558, lng: 21.337128 },
    { lat: 49.125577, lng: 21.337131 },
    { lat: 49.125552, lng: 21.337393 },
    { lat: 49.125469, lng: 21.337601 },
    { lat: 49.125403, lng: 21.337812 },
    { lat: 49.125357, lng: 21.337896 },
    { lat: 49.125231, lng: 21.338006 },
    { lat: 49.125052, lng: 21.338146 },
    { lat: 49.124911, lng: 21.338389 },
    { lat: 49.124762, lng: 21.338722 },
    { lat: 49.124745, lng: 21.338707 },
    { lat: 49.124812, lng: 21.339023 },
    { lat: 49.124796, lng: 21.339272 },
    { lat: 49.124729, lng: 21.339424 },
    { lat: 49.124705, lng: 21.339978 },
    { lat: 49.124592, lng: 21.340409 },
    { lat: 49.124731, lng: 21.340606 },
    { lat: 49.124527, lng: 21.341817 },
    { lat: 49.124458, lng: 21.341972 },
    { lat: 49.124079, lng: 21.342473 },
    { lat: 49.1239, lng: 21.34294 },
    { lat: 49.123556, lng: 21.343224 },
    { lat: 49.123459, lng: 21.343417 },
    { lat: 49.122808, lng: 21.343852 },
    { lat: 49.122483, lng: 21.344204 },
    { lat: 49.122234, lng: 21.344485 },
    { lat: 49.121972, lng: 21.344918 },
    { lat: 49.121792, lng: 21.345427 },
    { lat: 49.121599, lng: 21.345708 },
    { lat: 49.121596, lng: 21.345868 },
    { lat: 49.121567, lng: 21.346594 },
    { lat: 49.121069, lng: 21.346905 },
    { lat: 49.121076, lng: 21.346933 },
    { lat: 49.12079, lng: 21.347093 },
    { lat: 49.120496, lng: 21.347221 },
    { lat: 49.119947, lng: 21.347458 },
    { lat: 49.119586, lng: 21.347655 },
    { lat: 49.119327, lng: 21.347792 },
    { lat: 49.118697, lng: 21.348029 },
    { lat: 49.118478, lng: 21.34841 },
    { lat: 49.118215, lng: 21.348662 },
    { lat: 49.117675, lng: 21.349087 },
    { lat: 49.117243, lng: 21.349543 },
    { lat: 49.117206, lng: 21.349582 },
    { lat: 49.117187, lng: 21.349593 },
    { lat: 49.117138, lng: 21.349601 },
    { lat: 49.117106, lng: 21.349603 },
    { lat: 49.1170967, lng: 21.3496144 },
    { lat: 49.1230723, lng: 21.3561355 },
    { lat: 49.1233814, lng: 21.3561601 },
    { lat: 49.1234439, lng: 21.3563351 },
    { lat: 49.1242412, lng: 21.3563152 },
    { lat: 49.1251874, lng: 21.3560856 },
    { lat: 49.1278514, lng: 21.3565667 },
    { lat: 49.1288431, lng: 21.3557437 },
    { lat: 49.1293356, lng: 21.3558519 },
    { lat: 49.1301871, lng: 21.355761 },
    { lat: 49.1307193, lng: 21.3550811 },
    { lat: 49.131196, lng: 21.3551825 },
    { lat: 49.1331655, lng: 21.3559937 },
    { lat: 49.133417, lng: 21.3589978 },
    { lat: 49.1341273, lng: 21.3590112 },
    { lat: 49.1390979, lng: 21.3522716 },
    { lat: 49.1400726, lng: 21.3533796 },
    { lat: 49.1411536, lng: 21.3521217 },
    { lat: 49.1413993, lng: 21.3525147 },
    { lat: 49.1423005, lng: 21.3522486 },
    { lat: 49.1423807, lng: 21.3526369 },
    { lat: 49.1426362, lng: 21.352943 },
    { lat: 49.1433221, lng: 21.3520197 },
    { lat: 49.1437925, lng: 21.3541305 },
    { lat: 49.1450981, lng: 21.35305 },
    { lat: 49.1461201, lng: 21.3519309 },
  ],
  VyšnýKručov: [
    { lat: 49.1762642, lng: 21.4187049 },
    { lat: 49.1761966, lng: 21.4186178 },
    { lat: 49.1754583, lng: 21.4195746 },
    { lat: 49.175232, lng: 21.4191045 },
    { lat: 49.1748316, lng: 21.4194176 },
    { lat: 49.1743666, lng: 21.4188677 },
    { lat: 49.1736861, lng: 21.4194123 },
    { lat: 49.1735949, lng: 21.4190259 },
    { lat: 49.1725673, lng: 21.4192784 },
    { lat: 49.1723122, lng: 21.4194097 },
    { lat: 49.1722434, lng: 21.4191763 },
    { lat: 49.171515, lng: 21.4194318 },
    { lat: 49.1715047, lng: 21.4193829 },
    { lat: 49.1702006, lng: 21.4197592 },
    { lat: 49.1701807, lng: 21.4197026 },
    { lat: 49.1695083, lng: 21.4200241 },
    { lat: 49.1696367, lng: 21.4204084 },
    { lat: 49.169392, lng: 21.4206202 },
    { lat: 49.1695853, lng: 21.4211925 },
    { lat: 49.1689112, lng: 21.4219175 },
    { lat: 49.168686, lng: 21.4215671 },
    { lat: 49.1684371, lng: 21.4222495 },
    { lat: 49.1685262, lng: 21.4224488 },
    { lat: 49.1670503, lng: 21.4247763 },
    { lat: 49.1665492, lng: 21.4259144 },
    { lat: 49.1662432, lng: 21.4251859 },
    { lat: 49.1657121, lng: 21.4258748 },
    { lat: 49.1654996, lng: 21.4265353 },
    { lat: 49.1652344, lng: 21.4266281 },
    { lat: 49.1651856, lng: 21.4261511 },
    { lat: 49.1651153, lng: 21.4261727 },
    { lat: 49.1651002, lng: 21.4260005 },
    { lat: 49.1648958, lng: 21.4259697 },
    { lat: 49.1644278, lng: 21.425588 },
    { lat: 49.1642595, lng: 21.4256084 },
    { lat: 49.1644271, lng: 21.4269172 },
    { lat: 49.1644051, lng: 21.4290571 },
    { lat: 49.1639937, lng: 21.4287995 },
    { lat: 49.1637734, lng: 21.4288037 },
    { lat: 49.1637738, lng: 21.4287055 },
    { lat: 49.1633406, lng: 21.4288211 },
    { lat: 49.1632831, lng: 21.4261531 },
    { lat: 49.1630539, lng: 21.4260889 },
    { lat: 49.1630123, lng: 21.4251631 },
    { lat: 49.1619837, lng: 21.4254607 },
    { lat: 49.1616232, lng: 21.4259471 },
    { lat: 49.1608565, lng: 21.426465 },
    { lat: 49.1606627, lng: 21.4265378 },
    { lat: 49.1603845, lng: 21.4267972 },
    { lat: 49.1597926, lng: 21.4269463 },
    { lat: 49.1598708, lng: 21.4278904 },
    { lat: 49.1596416, lng: 21.4292946 },
    { lat: 49.1592079, lng: 21.4305469 },
    { lat: 49.159136, lng: 21.4304749 },
    { lat: 49.1552584, lng: 21.4356763 },
    { lat: 49.1507361, lng: 21.4412791 },
    { lat: 49.1497318, lng: 21.4426892 },
    { lat: 49.1492782, lng: 21.4431824 },
    { lat: 49.1480683, lng: 21.4448773 },
    { lat: 49.145737, lng: 21.447647 },
    { lat: 49.145774, lng: 21.448098 },
    { lat: 49.14621, lng: 21.447762 },
    { lat: 49.146622, lng: 21.447446 },
    { lat: 49.146807, lng: 21.447445 },
    { lat: 49.146884, lng: 21.447387 },
    { lat: 49.147062, lng: 21.447408 },
    { lat: 49.147507, lng: 21.447365 },
    { lat: 49.147835, lng: 21.44744 },
    { lat: 49.148176, lng: 21.447753 },
    { lat: 49.148254, lng: 21.44795 },
    { lat: 49.148471, lng: 21.448087 },
    { lat: 49.148733, lng: 21.44806 },
    { lat: 49.148875, lng: 21.448161 },
    { lat: 49.148949, lng: 21.448171 },
    { lat: 49.149027, lng: 21.448129 },
    { lat: 49.149253, lng: 21.44815 },
    { lat: 49.149317, lng: 21.44826 },
    { lat: 49.149499, lng: 21.448339 },
    { lat: 49.149633, lng: 21.448325 },
    { lat: 49.149762, lng: 21.448365 },
    { lat: 49.15028, lng: 21.448361 },
    { lat: 49.15067, lng: 21.448555 },
    { lat: 49.15086, lng: 21.448717 },
    { lat: 49.150946, lng: 21.449033 },
    { lat: 49.151132, lng: 21.44919 },
    { lat: 49.151355, lng: 21.449206 },
    { lat: 49.151518, lng: 21.449328 },
    { lat: 49.151597, lng: 21.449273 },
    { lat: 49.151981, lng: 21.449369 },
    { lat: 49.152421, lng: 21.44953 },
    { lat: 49.152748, lng: 21.449439 },
    { lat: 49.152905, lng: 21.449538 },
    { lat: 49.152972, lng: 21.449715 },
    { lat: 49.153412, lng: 21.450288 },
    { lat: 49.153494, lng: 21.450305 },
    { lat: 49.153729, lng: 21.450557 },
    { lat: 49.15377, lng: 21.450739 },
    { lat: 49.153841, lng: 21.450768 },
    { lat: 49.153933, lng: 21.451056 },
    { lat: 49.154052, lng: 21.45111 },
    { lat: 49.154111, lng: 21.451146 },
    { lat: 49.154138, lng: 21.451309 },
    { lat: 49.154241, lng: 21.451374 },
    { lat: 49.154314, lng: 21.451462 },
    { lat: 49.154326, lng: 21.451668 },
    { lat: 49.154351, lng: 21.451762 },
    { lat: 49.154282, lng: 21.451903 },
    { lat: 49.154316, lng: 21.45211 },
    { lat: 49.154356, lng: 21.452174 },
    { lat: 49.154397, lng: 21.452435 },
    { lat: 49.154477, lng: 21.45257 },
    { lat: 49.154432, lng: 21.452685 },
    { lat: 49.15444, lng: 21.452743 },
    { lat: 49.154462, lng: 21.452747 },
    { lat: 49.154497, lng: 21.452726 },
    { lat: 49.154474, lng: 21.452881 },
    { lat: 49.154497, lng: 21.453056 },
    { lat: 49.154521, lng: 21.453316 },
    { lat: 49.15456, lng: 21.453543 },
    { lat: 49.154569, lng: 21.453766 },
    { lat: 49.154565, lng: 21.453955 },
    { lat: 49.154589, lng: 21.454097 },
    { lat: 49.154656, lng: 21.45417 },
    { lat: 49.154565, lng: 21.454409 },
    { lat: 49.154613, lng: 21.454619 },
    { lat: 49.154593, lng: 21.454776 },
    { lat: 49.154643, lng: 21.455053 },
    { lat: 49.154597, lng: 21.455213 },
    { lat: 49.154605, lng: 21.45534 },
    { lat: 49.154643, lng: 21.455432 },
    { lat: 49.154679, lng: 21.455489 },
    { lat: 49.154623, lng: 21.456057 },
    { lat: 49.154665, lng: 21.456207 },
    { lat: 49.154982, lng: 21.456532 },
    { lat: 49.155075, lng: 21.456859 },
    { lat: 49.155147, lng: 21.457007 },
    { lat: 49.155127, lng: 21.457171 },
    { lat: 49.155047, lng: 21.457273 },
    { lat: 49.155088, lng: 21.457431 },
    { lat: 49.15518, lng: 21.457506 },
    { lat: 49.155409, lng: 21.457592 },
    { lat: 49.15547, lng: 21.457731 },
    { lat: 49.155494, lng: 21.45774 },
    { lat: 49.15561, lng: 21.45772 },
    { lat: 49.155684, lng: 21.457789 },
    { lat: 49.155733, lng: 21.457956 },
    { lat: 49.156138, lng: 21.458434 },
    { lat: 49.156242, lng: 21.45832 },
    { lat: 49.156271, lng: 21.458251 },
    { lat: 49.156247, lng: 21.459119 },
    { lat: 49.1564274, lng: 21.4583147 },
    { lat: 49.1568342, lng: 21.4575178 },
    { lat: 49.1573314, lng: 21.455415 },
    { lat: 49.1583384, lng: 21.4532372 },
    { lat: 49.1586156, lng: 21.4531704 },
    { lat: 49.1592555, lng: 21.4524217 },
    { lat: 49.1596868, lng: 21.4520091 },
    { lat: 49.1613388, lng: 21.4501196 },
    { lat: 49.1627405, lng: 21.4482838 },
    { lat: 49.1628222, lng: 21.4473756 },
    { lat: 49.1629382, lng: 21.4468396 },
    { lat: 49.1626317, lng: 21.4461625 },
    { lat: 49.1625848, lng: 21.4457903 },
    { lat: 49.1642058, lng: 21.4444947 },
    { lat: 49.1645535, lng: 21.4443066 },
    { lat: 49.1648338, lng: 21.4434985 },
    { lat: 49.1659717, lng: 21.442423 },
    { lat: 49.1663885, lng: 21.4433332 },
    { lat: 49.1673282, lng: 21.4422994 },
    { lat: 49.1683428, lng: 21.4424392 },
    { lat: 49.1699122, lng: 21.441512 },
    { lat: 49.1697112, lng: 21.4405149 },
    { lat: 49.1697465, lng: 21.4401418 },
    { lat: 49.1695748, lng: 21.4396177 },
    { lat: 49.17052, lng: 21.4386136 },
    { lat: 49.1713819, lng: 21.4381879 },
    { lat: 49.1713827, lng: 21.4379419 },
    { lat: 49.1729497, lng: 21.4373189 },
    { lat: 49.1743504, lng: 21.4366172 },
    { lat: 49.1753679, lng: 21.4359727 },
    { lat: 49.1752135, lng: 21.433411 },
    { lat: 49.1751044, lng: 21.4332583 },
    { lat: 49.1751003, lng: 21.4327714 },
    { lat: 49.1751707, lng: 21.4323293 },
    { lat: 49.1753263, lng: 21.431948 },
    { lat: 49.175344, lng: 21.4315919 },
    { lat: 49.175429, lng: 21.431192 },
    { lat: 49.1754201, lng: 21.4306932 },
    { lat: 49.17557, lng: 21.4301224 },
    { lat: 49.1758756, lng: 21.4267095 },
    { lat: 49.1764775, lng: 21.4243863 },
    { lat: 49.1765157, lng: 21.4243887 },
    { lat: 49.1768397, lng: 21.4230419 },
    { lat: 49.1768578, lng: 21.4227717 },
    { lat: 49.1769667, lng: 21.422723 },
    { lat: 49.1768005, lng: 21.4221977 },
    { lat: 49.1773704, lng: 21.4217566 },
    { lat: 49.1771221, lng: 21.4210694 },
    { lat: 49.1762642, lng: 21.4187049 },
  ],
  Kurima: [
    { lat: 49.2457226, lng: 21.4600789 },
    { lat: 49.2454327, lng: 21.4594621 },
    { lat: 49.2448983, lng: 21.4581787 },
    { lat: 49.2446016, lng: 21.4572912 },
    { lat: 49.2440273, lng: 21.4558269 },
    { lat: 49.2428637, lng: 21.4516483 },
    { lat: 49.2420209, lng: 21.4488051 },
    { lat: 49.2414004, lng: 21.4472637 },
    { lat: 49.241384, lng: 21.4470477 },
    { lat: 49.241208, lng: 21.4468382 },
    { lat: 49.2411173, lng: 21.4463827 },
    { lat: 49.2411791, lng: 21.446264 },
    { lat: 49.2410914, lng: 21.4459113 },
    { lat: 49.24121, lng: 21.4456595 },
    { lat: 49.2410881, lng: 21.4452206 },
    { lat: 49.2407187, lng: 21.4455559 },
    { lat: 49.2405797, lng: 21.4452243 },
    { lat: 49.2396926, lng: 21.4457076 },
    { lat: 49.2382977, lng: 21.4410945 },
    { lat: 49.238384, lng: 21.4401493 },
    { lat: 49.2366203, lng: 21.43361 },
    { lat: 49.2365723, lng: 21.4332388 },
    { lat: 49.2360197, lng: 21.4318379 },
    { lat: 49.2358375, lng: 21.4315691 },
    { lat: 49.2355966, lng: 21.4299354 },
    { lat: 49.235484, lng: 21.429434 },
    { lat: 49.2347048, lng: 21.4289064 },
    { lat: 49.2341475, lng: 21.4271186 },
    { lat: 49.2337753, lng: 21.4261948 },
    { lat: 49.2336217, lng: 21.4259414 },
    { lat: 49.2334663, lng: 21.4253971 },
    { lat: 49.2334792, lng: 21.4241805 },
    { lat: 49.2327629, lng: 21.4230688 },
    { lat: 49.2322349, lng: 21.4225164 },
    { lat: 49.2319751, lng: 21.421899 },
    { lat: 49.2318946, lng: 21.4209904 },
    { lat: 49.2320173, lng: 21.4200741 },
    { lat: 49.2315203, lng: 21.4184973 },
    { lat: 49.2312631, lng: 21.4180947 },
    { lat: 49.2310303, lng: 21.4183247 },
    { lat: 49.2289403, lng: 21.4199983 },
    { lat: 49.2282749, lng: 21.4208272 },
    { lat: 49.2268581, lng: 21.4221316 },
    { lat: 49.2261556, lng: 21.4231207 },
    { lat: 49.2259069, lng: 21.4237369 },
    { lat: 49.2257485, lng: 21.4239305 },
    { lat: 49.225736, lng: 21.4241265 },
    { lat: 49.2256199, lng: 21.4243871 },
    { lat: 49.2248624, lng: 21.4256519 },
    { lat: 49.2237479, lng: 21.4273254 },
    { lat: 49.2231534, lng: 21.4279668 },
    { lat: 49.2226422, lng: 21.4286868 },
    { lat: 49.2212529, lng: 21.4312063 },
    { lat: 49.2209529, lng: 21.4318435 },
    { lat: 49.2198442, lng: 21.4334854 },
    { lat: 49.2188342, lng: 21.435277 },
    { lat: 49.2187199, lng: 21.4355615 },
    { lat: 49.2187708, lng: 21.4357657 },
    { lat: 49.2184348, lng: 21.436364 },
    { lat: 49.2185351, lng: 21.4369129 },
    { lat: 49.2182771, lng: 21.4373651 },
    { lat: 49.2183434, lng: 21.4376341 },
    { lat: 49.2177938, lng: 21.4383879 },
    { lat: 49.2181141, lng: 21.4388373 },
    { lat: 49.2176789, lng: 21.439506 },
    { lat: 49.2178601, lng: 21.4405169 },
    { lat: 49.2180522, lng: 21.4409729 },
    { lat: 49.2177246, lng: 21.4413578 },
    { lat: 49.217425, lng: 21.4414583 },
    { lat: 49.2175869, lng: 21.4417299 },
    { lat: 49.2173625, lng: 21.4420567 },
    { lat: 49.2173129, lng: 21.4424038 },
    { lat: 49.2174762, lng: 21.4426454 },
    { lat: 49.217392, lng: 21.4428814 },
    { lat: 49.217301, lng: 21.4435872 },
    { lat: 49.2172138, lng: 21.4435643 },
    { lat: 49.2172937, lng: 21.4442297 },
    { lat: 49.2169582, lng: 21.4444899 },
    { lat: 49.2169071, lng: 21.4448259 },
    { lat: 49.2170653, lng: 21.4452334 },
    { lat: 49.2170552, lng: 21.4454313 },
    { lat: 49.2173583, lng: 21.4455649 },
    { lat: 49.2171937, lng: 21.4459784 },
    { lat: 49.2172005, lng: 21.4462957 },
    { lat: 49.2169742, lng: 21.4465183 },
    { lat: 49.2172358, lng: 21.4470106 },
    { lat: 49.2172496, lng: 21.4472105 },
    { lat: 49.2151283, lng: 21.4487099 },
    { lat: 49.2151544, lng: 21.4489137 },
    { lat: 49.2154878, lng: 21.4526973 },
    { lat: 49.2158352, lng: 21.4579505 },
    { lat: 49.2160758, lng: 21.459737 },
    { lat: 49.2152316, lng: 21.4598765 },
    { lat: 49.2144318, lng: 21.4597676 },
    { lat: 49.2160676, lng: 21.4666369 },
    { lat: 49.2197937, lng: 21.465231 },
    { lat: 49.2203819, lng: 21.4665912 },
    { lat: 49.2207403, lng: 21.4672377 },
    { lat: 49.2209923, lng: 21.467828 },
    { lat: 49.2231011, lng: 21.4742582 },
    { lat: 49.223083, lng: 21.4744916 },
    { lat: 49.2229149, lng: 21.4748289 },
    { lat: 49.2226111, lng: 21.4749442 },
    { lat: 49.2225226, lng: 21.4750751 },
    { lat: 49.2223075, lng: 21.4747601 },
    { lat: 49.2218215, lng: 21.474721 },
    { lat: 49.2216432, lng: 21.4748593 },
    { lat: 49.2213896, lng: 21.4747621 },
    { lat: 49.2210782, lng: 21.4748053 },
    { lat: 49.2216752, lng: 21.4781695 },
    { lat: 49.2221738, lng: 21.4796647 },
    { lat: 49.2234594, lng: 21.4795018 },
    { lat: 49.2236898, lng: 21.4796106 },
    { lat: 49.2238989, lng: 21.4795621 },
    { lat: 49.2241547, lng: 21.4813796 },
    { lat: 49.2244456, lng: 21.4830587 },
    { lat: 49.2244489, lng: 21.4834036 },
    { lat: 49.2244065, lng: 21.48358 },
    { lat: 49.2242932, lng: 21.4837448 },
    { lat: 49.2242975, lng: 21.4841268 },
    { lat: 49.2244676, lng: 21.4844117 },
    { lat: 49.2245058, lng: 21.4845696 },
    { lat: 49.2237239, lng: 21.4864596 },
    { lat: 49.2234574, lng: 21.4869734 },
    { lat: 49.2233722, lng: 21.487375 },
    { lat: 49.2233602, lng: 21.4875885 },
    { lat: 49.2238475, lng: 21.4909376 },
    { lat: 49.2228396, lng: 21.4914895 },
    { lat: 49.2224175, lng: 21.49188 },
    { lat: 49.2223204, lng: 21.4920632 },
    { lat: 49.2223015, lng: 21.4926227 },
    { lat: 49.2223798, lng: 21.4952852 },
    { lat: 49.2227063, lng: 21.4953344 },
    { lat: 49.2228906, lng: 21.4959175 },
    { lat: 49.2231268, lng: 21.4969016 },
    { lat: 49.2231736, lng: 21.4976403 },
    { lat: 49.2233724, lng: 21.4984542 },
    { lat: 49.2233774, lng: 21.4987454 },
    { lat: 49.2232155, lng: 21.4994994 },
    { lat: 49.2229299, lng: 21.5015224 },
    { lat: 49.2230413, lng: 21.5021575 },
    { lat: 49.2228284, lng: 21.5037621 },
    { lat: 49.2224499, lng: 21.5039481 },
    { lat: 49.2221976, lng: 21.5039746 },
    { lat: 49.2220564, lng: 21.5054574 },
    { lat: 49.2217709, lng: 21.5066155 },
    { lat: 49.2215126, lng: 21.5073826 },
    { lat: 49.2209914, lng: 21.5084789 },
    { lat: 49.2206484, lng: 21.5093924 },
    { lat: 49.2212, lng: 21.510537 },
    { lat: 49.221521, lng: 21.511119 },
    { lat: 49.222696, lng: 21.511503 },
    { lat: 49.223529, lng: 21.511351 },
    { lat: 49.224433, lng: 21.510658 },
    { lat: 49.224523, lng: 21.510654 },
    { lat: 49.225733, lng: 21.510591 },
    { lat: 49.226398, lng: 21.5111 },
    { lat: 49.228262, lng: 21.511078 },
    { lat: 49.229261, lng: 21.511921 },
    { lat: 49.230306, lng: 21.511638 },
    { lat: 49.232231, lng: 21.51104 },
    { lat: 49.233646, lng: 21.510595 },
    { lat: 49.23386, lng: 21.510677 },
    { lat: 49.23434, lng: 21.510859 },
    { lat: 49.235245, lng: 21.510371 },
    { lat: 49.235006, lng: 21.509889 },
    { lat: 49.235226, lng: 21.509685 },
    { lat: 49.236324, lng: 21.508628 },
    { lat: 49.236853, lng: 21.507735 },
    { lat: 49.237198, lng: 21.507245 },
    { lat: 49.237721, lng: 21.506687 },
    { lat: 49.238604, lng: 21.506015 },
    { lat: 49.238796, lng: 21.505632 },
    { lat: 49.239091, lng: 21.505251 },
    { lat: 49.23919, lng: 21.504732 },
    { lat: 49.239885, lng: 21.503483 },
    { lat: 49.240474, lng: 21.502756 },
    { lat: 49.240743, lng: 21.502584 },
    { lat: 49.241752, lng: 21.502028 },
    { lat: 49.242314, lng: 21.502163 },
    { lat: 49.242627, lng: 21.501787 },
    { lat: 49.243212, lng: 21.501585 },
    { lat: 49.243633, lng: 21.500884 },
    { lat: 49.244047, lng: 21.50048 },
    { lat: 49.244128, lng: 21.500522 },
    { lat: 49.244543, lng: 21.499877 },
    { lat: 49.244602, lng: 21.499562 },
    { lat: 49.244684, lng: 21.499166 },
    { lat: 49.244876, lng: 21.498055 },
    { lat: 49.244883, lng: 21.496992 },
    { lat: 49.245473, lng: 21.496415 },
    { lat: 49.245821, lng: 21.496086 },
    { lat: 49.24667, lng: 21.495413 },
    { lat: 49.247164, lng: 21.494727 },
    { lat: 49.247634, lng: 21.493848 },
    { lat: 49.248354, lng: 21.492844 },
    { lat: 49.249011, lng: 21.491354 },
    { lat: 49.249317, lng: 21.489995 },
    { lat: 49.2492145, lng: 21.4899905 },
    { lat: 49.2488853, lng: 21.48898 },
    { lat: 49.2485212, lng: 21.4872607 },
    { lat: 49.2488251, lng: 21.4853404 },
    { lat: 49.2490871, lng: 21.484431 },
    { lat: 49.2491009, lng: 21.4840137 },
    { lat: 49.2489131, lng: 21.4833831 },
    { lat: 49.2486613, lng: 21.4818565 },
    { lat: 49.2485438, lng: 21.4814852 },
    { lat: 49.2481908, lng: 21.4810626 },
    { lat: 49.2473272, lng: 21.4806485 },
    { lat: 49.2464453, lng: 21.4803769 },
    { lat: 49.2463266, lng: 21.4800773 },
    { lat: 49.2460382, lng: 21.4796382 },
    { lat: 49.2458239, lng: 21.4789043 },
    { lat: 49.2456965, lng: 21.4758027 },
    { lat: 49.245183, lng: 21.4747647 },
    { lat: 49.244395, lng: 21.4725932 },
    { lat: 49.2458169, lng: 21.4698441 },
    { lat: 49.2461482, lng: 21.4685259 },
    { lat: 49.2463403, lng: 21.4680931 },
    { lat: 49.246294, lng: 21.4675791 },
    { lat: 49.2457207, lng: 21.4673594 },
    { lat: 49.2455949, lng: 21.4672069 },
    { lat: 49.2456112, lng: 21.4670293 },
    { lat: 49.2454087, lng: 21.4667981 },
    { lat: 49.2450366, lng: 21.4660996 },
    { lat: 49.2448149, lng: 21.4658682 },
    { lat: 49.2447477, lng: 21.4656451 },
    { lat: 49.2454992, lng: 21.4638418 },
    { lat: 49.2457062, lng: 21.4635308 },
    { lat: 49.2459994, lng: 21.4639843 },
    { lat: 49.2462508, lng: 21.4640055 },
    { lat: 49.2460392, lng: 21.4623219 },
    { lat: 49.2459179, lng: 21.4620128 },
    { lat: 49.2458132, lng: 21.460564 },
    { lat: 49.2457226, lng: 21.4600789 },
  ],
  Gaboltov: [
    { lat: 49.3915752, lng: 21.1504288 },
    { lat: 49.3914691, lng: 21.1497365 },
    { lat: 49.3906606, lng: 21.1486402 },
    { lat: 49.3903371, lng: 21.1476581 },
    { lat: 49.3900395, lng: 21.1464602 },
    { lat: 49.3890411, lng: 21.1441174 },
    { lat: 49.3881423, lng: 21.1431586 },
    { lat: 49.387949, lng: 21.1428066 },
    { lat: 49.3877265, lng: 21.1422101 },
    { lat: 49.3876732, lng: 21.1416982 },
    { lat: 49.3874836, lng: 21.1407175 },
    { lat: 49.3869545, lng: 21.1399518 },
    { lat: 49.3866752, lng: 21.1382316 },
    { lat: 49.3867683, lng: 21.1378653 },
    { lat: 49.387018, lng: 21.1374669 },
    { lat: 49.3872801, lng: 21.1372182 },
    { lat: 49.3873414, lng: 21.1370536 },
    { lat: 49.3874199, lng: 21.1359729 },
    { lat: 49.3873839, lng: 21.1354757 },
    { lat: 49.3846304, lng: 21.134504 },
    { lat: 49.3840939, lng: 21.1342302 },
    { lat: 49.3838434, lng: 21.1343462 },
    { lat: 49.3836942, lng: 21.1338256 },
    { lat: 49.3836199, lng: 21.1331799 },
    { lat: 49.3834865, lng: 21.1326303 },
    { lat: 49.3827859, lng: 21.1311765 },
    { lat: 49.3826735, lng: 21.1309995 },
    { lat: 49.3823953, lng: 21.1309251 },
    { lat: 49.3822921, lng: 21.130793 },
    { lat: 49.3813642, lng: 21.1290206 },
    { lat: 49.3807904, lng: 21.1292028 },
    { lat: 49.3803306, lng: 21.1295009 },
    { lat: 49.3800482, lng: 21.1291093 },
    { lat: 49.3797886, lng: 21.1282647 },
    { lat: 49.3794222, lng: 21.1273172 },
    { lat: 49.379378, lng: 21.1270528 },
    { lat: 49.3793938, lng: 21.126277 },
    { lat: 49.3791647, lng: 21.1256454 },
    { lat: 49.3791605, lng: 21.1250108 },
    { lat: 49.3786829, lng: 21.1244637 },
    { lat: 49.3784876, lng: 21.1243356 },
    { lat: 49.3783362, lng: 21.1242814 },
    { lat: 49.3777351, lng: 21.1244206 },
    { lat: 49.3776331, lng: 21.1236857 },
    { lat: 49.3774228, lng: 21.1231177 },
    { lat: 49.3774959, lng: 21.1230083 },
    { lat: 49.3775165, lng: 21.1228096 },
    { lat: 49.3771819, lng: 21.1220468 },
    { lat: 49.3769898, lng: 21.1214533 },
    { lat: 49.3770123, lng: 21.1206666 },
    { lat: 49.3768905, lng: 21.1198067 },
    { lat: 49.3765082, lng: 21.1192458 },
    { lat: 49.3761356, lng: 21.1182454 },
    { lat: 49.3754703, lng: 21.1169236 },
    { lat: 49.3753855, lng: 21.1169783 },
    { lat: 49.375218, lng: 21.1168124 },
    { lat: 49.3752612, lng: 21.1166806 },
    { lat: 49.3752352, lng: 21.1164305 },
    { lat: 49.374696, lng: 21.1152114 },
    { lat: 49.3748865, lng: 21.1148321 },
    { lat: 49.3746461, lng: 21.113571 },
    { lat: 49.3746913, lng: 21.1132578 },
    { lat: 49.3745736, lng: 21.1128773 },
    { lat: 49.3745426, lng: 21.1121162 },
    { lat: 49.373939, lng: 21.1105755 },
    { lat: 49.3740024, lng: 21.1099526 },
    { lat: 49.374164, lng: 21.109443 },
    { lat: 49.3740898, lng: 21.1087671 },
    { lat: 49.3742297, lng: 21.108215 },
    { lat: 49.3746951, lng: 21.108124 },
    { lat: 49.37511, lng: 21.1081863 },
    { lat: 49.3755029, lng: 21.1063179 },
    { lat: 49.3758245, lng: 21.1055306 },
    { lat: 49.375987, lng: 21.1049771 },
    { lat: 49.3764011, lng: 21.1041047 },
    { lat: 49.3755624, lng: 21.1034079 },
    { lat: 49.3745882, lng: 21.1025066 },
    { lat: 49.3744708, lng: 21.1023768 },
    { lat: 49.3743023, lng: 21.1019245 },
    { lat: 49.373638, lng: 21.1030455 },
    { lat: 49.373793, lng: 21.1042054 },
    { lat: 49.3734762, lng: 21.1051816 },
    { lat: 49.3727905, lng: 21.1062253 },
    { lat: 49.3721544, lng: 21.1079496 },
    { lat: 49.3721249, lng: 21.1086184 },
    { lat: 49.372152, lng: 21.1089092 },
    { lat: 49.3714481, lng: 21.1097018 },
    { lat: 49.3713233, lng: 21.1100928 },
    { lat: 49.3712735, lng: 21.1107215 },
    { lat: 49.3709981, lng: 21.1111237 },
    { lat: 49.3709734, lng: 21.1112298 },
    { lat: 49.3707166, lng: 21.11154 },
    { lat: 49.3703821, lng: 21.1124506 },
    { lat: 49.3702803, lng: 21.1123639 },
    { lat: 49.3700955, lng: 21.1124603 },
    { lat: 49.3697901, lng: 21.1128019 },
    { lat: 49.3693271, lng: 21.1137108 },
    { lat: 49.3681905, lng: 21.1146611 },
    { lat: 49.3680291, lng: 21.1148959 },
    { lat: 49.3671676, lng: 21.1157993 },
    { lat: 49.3666322, lng: 21.1166569 },
    { lat: 49.3664629, lng: 21.1171421 },
    { lat: 49.3643308, lng: 21.1184258 },
    { lat: 49.3640598, lng: 21.1184672 },
    { lat: 49.3638936, lng: 21.118721 },
    { lat: 49.3636075, lng: 21.118713 },
    { lat: 49.3633363, lng: 21.1192097 },
    { lat: 49.3630238, lng: 21.1193271 },
    { lat: 49.3625453, lng: 21.1193533 },
    { lat: 49.3618531, lng: 21.1198111 },
    { lat: 49.3617055, lng: 21.1199827 },
    { lat: 49.3612042, lng: 21.1199687 },
    { lat: 49.3611089, lng: 21.1202552 },
    { lat: 49.3607094, lng: 21.1204025 },
    { lat: 49.3604527, lng: 21.1205786 },
    { lat: 49.3599841, lng: 21.1204863 },
    { lat: 49.3597546, lng: 21.120774 },
    { lat: 49.3594607, lng: 21.1208377 },
    { lat: 49.3593629, lng: 21.1209796 },
    { lat: 49.3593202, lng: 21.1212315 },
    { lat: 49.3588153, lng: 21.1217733 },
    { lat: 49.3584638, lng: 21.1216578 },
    { lat: 49.3584799, lng: 21.1217422 },
    { lat: 49.3582754, lng: 21.1224934 },
    { lat: 49.3586825, lng: 21.1235906 },
    { lat: 49.3590409, lng: 21.1242853 },
    { lat: 49.3590637, lng: 21.1245056 },
    { lat: 49.3589313, lng: 21.1246973 },
    { lat: 49.3593079, lng: 21.1260408 },
    { lat: 49.3586713, lng: 21.1270128 },
    { lat: 49.3585307, lng: 21.1274333 },
    { lat: 49.3582736, lng: 21.1279155 },
    { lat: 49.3576906, lng: 21.1286673 },
    { lat: 49.3576125, lng: 21.1286485 },
    { lat: 49.3573666, lng: 21.1288457 },
    { lat: 49.357103, lng: 21.1291898 },
    { lat: 49.3564541, lng: 21.1302355 },
    { lat: 49.3562839, lng: 21.1306903 },
    { lat: 49.3552161, lng: 21.132329 },
    { lat: 49.3540198, lng: 21.1345019 },
    { lat: 49.3532103, lng: 21.1362351 },
    { lat: 49.3534807, lng: 21.1365988 },
    { lat: 49.3528558, lng: 21.1379454 },
    { lat: 49.3524274, lng: 21.1392025 },
    { lat: 49.3515452, lng: 21.1410267 },
    { lat: 49.3507399, lng: 21.1423945 },
    { lat: 49.3503887, lng: 21.143178 },
    { lat: 49.3498361, lng: 21.1448233 },
    { lat: 49.3498759, lng: 21.1452257 },
    { lat: 49.3497413, lng: 21.1459232 },
    { lat: 49.3500295, lng: 21.1465357 },
    { lat: 49.3501489, lng: 21.1468586 },
    { lat: 49.3494667, lng: 21.1479591 },
    { lat: 49.3495229, lng: 21.1488547 },
    { lat: 49.3496359, lng: 21.1493015 },
    { lat: 49.3495232, lng: 21.1503662 },
    { lat: 49.3499671, lng: 21.1527142 },
    { lat: 49.349952, lng: 21.1536006 },
    { lat: 49.3500284, lng: 21.1547066 },
    { lat: 49.3503436, lng: 21.1566822 },
    { lat: 49.3499996, lng: 21.1568417 },
    { lat: 49.3493621, lng: 21.1577551 },
    { lat: 49.3501165, lng: 21.1594213 },
    { lat: 49.3509745, lng: 21.1610539 },
    { lat: 49.3514713, lng: 21.1622955 },
    { lat: 49.3515965, lng: 21.1624841 },
    { lat: 49.3524939, lng: 21.1632224 },
    { lat: 49.35305, lng: 21.1634823 },
    { lat: 49.3546263, lng: 21.1640201 },
    { lat: 49.354915, lng: 21.1630648 },
    { lat: 49.3556827, lng: 21.1637275 },
    { lat: 49.3565088, lng: 21.1642759 },
    { lat: 49.3571259, lng: 21.1645786 },
    { lat: 49.3581407, lng: 21.1639616 },
    { lat: 49.3584356, lng: 21.1638909 },
    { lat: 49.359264, lng: 21.1638991 },
    { lat: 49.3599319, lng: 21.1635603 },
    { lat: 49.3602836, lng: 21.1640471 },
    { lat: 49.3610203, lng: 21.1631383 },
    { lat: 49.3625134, lng: 21.162884 },
    { lat: 49.36364, lng: 21.1638281 },
    { lat: 49.3641251, lng: 21.1639799 },
    { lat: 49.3648576, lng: 21.1644464 },
    { lat: 49.3656824, lng: 21.1639787 },
    { lat: 49.3661397, lng: 21.1644957 },
    { lat: 49.3671487, lng: 21.1645976 },
    { lat: 49.3682057, lng: 21.165223 },
    { lat: 49.3688845, lng: 21.1650162 },
    { lat: 49.3691418, lng: 21.1650432 },
    { lat: 49.3694772, lng: 21.164971 },
    { lat: 49.370059, lng: 21.1652211 },
    { lat: 49.3705149, lng: 21.1653018 },
    { lat: 49.3715348, lng: 21.1656776 },
    { lat: 49.3722963, lng: 21.1661721 },
    { lat: 49.372582, lng: 21.1666061 },
    { lat: 49.3738889, lng: 21.1669064 },
    { lat: 49.3746252, lng: 21.1668149 },
    { lat: 49.3749471, lng: 21.1665678 },
    { lat: 49.3755309, lng: 21.1654428 },
    { lat: 49.3760527, lng: 21.1647116 },
    { lat: 49.3765116, lng: 21.1646758 },
    { lat: 49.3774159, lng: 21.1639768 },
    { lat: 49.3784296, lng: 21.1641166 },
    { lat: 49.3803207, lng: 21.164897 },
    { lat: 49.3809787, lng: 21.1652861 },
    { lat: 49.3814046, lng: 21.1657731 },
    { lat: 49.3831613, lng: 21.1689933 },
    { lat: 49.3834983, lng: 21.1686099 },
    { lat: 49.3839276, lng: 21.168382 },
    { lat: 49.3846248, lng: 21.1674681 },
    { lat: 49.3852899, lng: 21.1663554 },
    { lat: 49.3855888, lng: 21.1660893 },
    { lat: 49.3870605, lng: 21.1643601 },
    { lat: 49.3876001, lng: 21.1638759 },
    { lat: 49.3877032, lng: 21.1635743 },
    { lat: 49.388067, lng: 21.1626161 },
    { lat: 49.3880987, lng: 21.1622194 },
    { lat: 49.3886152, lng: 21.1609267 },
    { lat: 49.3888606, lng: 21.1603907 },
    { lat: 49.3889991, lng: 21.1602202 },
    { lat: 49.3893971, lng: 21.1590922 },
    { lat: 49.3897272, lng: 21.1578853 },
    { lat: 49.3899933, lng: 21.1564983 },
    { lat: 49.3901171, lng: 21.1554587 },
    { lat: 49.3901543, lng: 21.1537037 },
    { lat: 49.3903756, lng: 21.1526497 },
    { lat: 49.3909532, lng: 21.1519104 },
    { lat: 49.391419, lng: 21.1511544 },
    { lat: 49.3915752, lng: 21.1504288 },
  ],
  Hažlín: [
    { lat: 49.2756028, lng: 21.4345675 },
    { lat: 49.2756993, lng: 21.4350978 },
    { lat: 49.2762377, lng: 21.4380722 },
    { lat: 49.2760604, lng: 21.4389724 },
    { lat: 49.2764012, lng: 21.4395143 },
    { lat: 49.2768417, lng: 21.4406991 },
    { lat: 49.2771651, lng: 21.4418869 },
    { lat: 49.278269, lng: 21.4452804 },
    { lat: 49.2782448, lng: 21.4454946 },
    { lat: 49.2785982, lng: 21.4467845 },
    { lat: 49.2789419, lng: 21.4477688 },
    { lat: 49.2788987, lng: 21.4478319 },
    { lat: 49.278976, lng: 21.4480919 },
    { lat: 49.2790414, lng: 21.4483005 },
    { lat: 49.2791873, lng: 21.4485194 },
    { lat: 49.2793641, lng: 21.4487558 },
    { lat: 49.2795608, lng: 21.448905 },
    { lat: 49.2799187, lng: 21.4490304 },
    { lat: 49.2813145, lng: 21.4486273 },
    { lat: 49.2814206, lng: 21.4486335 },
    { lat: 49.2817574, lng: 21.4488861 },
    { lat: 49.2820809, lng: 21.4487904 },
    { lat: 49.2823064, lng: 21.4488104 },
    { lat: 49.2827912, lng: 21.4486901 },
    { lat: 49.2833247, lng: 21.4483817 },
    { lat: 49.2835533, lng: 21.4483954 },
    { lat: 49.2837872, lng: 21.449246 },
    { lat: 49.2850608, lng: 21.4499485 },
    { lat: 49.285777, lng: 21.450635 },
    { lat: 49.2866175, lng: 21.4512263 },
    { lat: 49.2871416, lng: 21.4518403 },
    { lat: 49.2874401, lng: 21.4520541 },
    { lat: 49.287572, lng: 21.4522542 },
    { lat: 49.2877194, lng: 21.4529586 },
    { lat: 49.287748, lng: 21.452906 },
    { lat: 49.287718, lng: 21.452681 },
    { lat: 49.287808, lng: 21.452599 },
    { lat: 49.288035, lng: 21.452585 },
    { lat: 49.288379, lng: 21.452479 },
    { lat: 49.288563, lng: 21.452565 },
    { lat: 49.288939, lng: 21.452498 },
    { lat: 49.289272, lng: 21.452283 },
    { lat: 49.289568, lng: 21.45226 },
    { lat: 49.289794, lng: 21.452249 },
    { lat: 49.28983, lng: 21.452369 },
    { lat: 49.290202, lng: 21.452474 },
    { lat: 49.290546, lng: 21.45252 },
    { lat: 49.290687, lng: 21.452596 },
    { lat: 49.291001, lng: 21.452989 },
    { lat: 49.291174, lng: 21.453195 },
    { lat: 49.291363, lng: 21.45344 },
    { lat: 49.291451, lng: 21.453511 },
    { lat: 49.291566, lng: 21.453533 },
    { lat: 49.291627, lng: 21.453513 },
    { lat: 49.291697, lng: 21.453229 },
    { lat: 49.291812, lng: 21.452901 },
    { lat: 49.291922, lng: 21.452713 },
    { lat: 49.292036, lng: 21.452605 },
    { lat: 49.292098, lng: 21.452558 },
    { lat: 49.292248, lng: 21.452444 },
    { lat: 49.292313, lng: 21.452375 },
    { lat: 49.292413, lng: 21.452296 },
    { lat: 49.292506, lng: 21.452197 },
    { lat: 49.292545, lng: 21.452122 },
    { lat: 49.292739, lng: 21.451893 },
    { lat: 49.292899, lng: 21.451794 },
    { lat: 49.292974, lng: 21.451767 },
    { lat: 49.293005, lng: 21.451802 },
    { lat: 49.29301, lng: 21.451858 },
    { lat: 49.293008, lng: 21.451915 },
    { lat: 49.293, lng: 21.452004 },
    { lat: 49.293074, lng: 21.452076 },
    { lat: 49.293186, lng: 21.452092 },
    { lat: 49.293201, lng: 21.452074 },
    { lat: 49.293387, lng: 21.451858 },
    { lat: 49.293532, lng: 21.451664 },
    { lat: 49.293609, lng: 21.451577 },
    { lat: 49.29369, lng: 21.451474 },
    { lat: 49.293751, lng: 21.451404 },
    { lat: 49.293786, lng: 21.451378 },
    { lat: 49.293842, lng: 21.451293 },
    { lat: 49.293891, lng: 21.451244 },
    { lat: 49.293956, lng: 21.451227 },
    { lat: 49.294061, lng: 21.451134 },
    { lat: 49.294236, lng: 21.451001 },
    { lat: 49.294252, lng: 21.450954 },
    { lat: 49.294409, lng: 21.45085 },
    { lat: 49.294479, lng: 21.450797 },
    { lat: 49.294602, lng: 21.450679 },
    { lat: 49.294759, lng: 21.450488 },
    { lat: 49.294895, lng: 21.45017 },
    { lat: 49.294997, lng: 21.450061 },
    { lat: 49.295094, lng: 21.45002 },
    { lat: 49.295146, lng: 21.450121 },
    { lat: 49.295223, lng: 21.450412 },
    { lat: 49.295342, lng: 21.450614 },
    { lat: 49.29543, lng: 21.450693 },
    { lat: 49.295717, lng: 21.450638 },
    { lat: 49.295844, lng: 21.450537 },
    { lat: 49.295875, lng: 21.450492 },
    { lat: 49.296048, lng: 21.449924 },
    { lat: 49.296153, lng: 21.449304 },
    { lat: 49.296217, lng: 21.449048 },
    { lat: 49.296271, lng: 21.44893 },
    { lat: 49.296513, lng: 21.448527 },
    { lat: 49.296545, lng: 21.448562 },
    { lat: 49.296675, lng: 21.448698 },
    { lat: 49.296839, lng: 21.448841 },
    { lat: 49.297076, lng: 21.449341 },
    { lat: 49.297255, lng: 21.449775 },
    { lat: 49.297303, lng: 21.449816 },
    { lat: 49.297498, lng: 21.450109 },
    { lat: 49.297913, lng: 21.450423 },
    { lat: 49.298329, lng: 21.450636 },
    { lat: 49.298649, lng: 21.450688 },
    { lat: 49.298797, lng: 21.450721 },
    { lat: 49.299119, lng: 21.450851 },
    { lat: 49.299283, lng: 21.450997 },
    { lat: 49.299408, lng: 21.451154 },
    { lat: 49.299521, lng: 21.451291 },
    { lat: 49.299627, lng: 21.451407 },
    { lat: 49.299697, lng: 21.451471 },
    { lat: 49.299903, lng: 21.451649 },
    { lat: 49.299996, lng: 21.45155 },
    { lat: 49.300109, lng: 21.45147 },
    { lat: 49.300262, lng: 21.451379 },
    { lat: 49.300622, lng: 21.45122 },
    { lat: 49.300895, lng: 21.451134 },
    { lat: 49.30119, lng: 21.451069 },
    { lat: 49.302217, lng: 21.450804 },
    { lat: 49.302344, lng: 21.450781 },
    { lat: 49.302418, lng: 21.450755 },
    { lat: 49.302576, lng: 21.450714 },
    { lat: 49.302733, lng: 21.450659 },
    { lat: 49.302756, lng: 21.450722 },
    { lat: 49.302952, lng: 21.450637 },
    { lat: 49.303072, lng: 21.450565 },
    { lat: 49.303128, lng: 21.450546 },
    { lat: 49.303174, lng: 21.450498 },
    { lat: 49.303247, lng: 21.450456 },
    { lat: 49.303373, lng: 21.450355 },
    { lat: 49.303544, lng: 21.450244 },
    { lat: 49.30352, lng: 21.450175 },
    { lat: 49.303565, lng: 21.450106 },
    { lat: 49.303574, lng: 21.450013 },
    { lat: 49.303621, lng: 21.44981 },
    { lat: 49.303643, lng: 21.449717 },
    { lat: 49.303646, lng: 21.449635 },
    { lat: 49.303717, lng: 21.449625 },
    { lat: 49.303809, lng: 21.449541 },
    { lat: 49.303848, lng: 21.449489 },
    { lat: 49.304158, lng: 21.44952 },
    { lat: 49.304373, lng: 21.449483 },
    { lat: 49.304489, lng: 21.449451 },
    { lat: 49.304539, lng: 21.4494 },
    { lat: 49.304608, lng: 21.449374 },
    { lat: 49.304664, lng: 21.449365 },
    { lat: 49.304884, lng: 21.449327 },
    { lat: 49.305103, lng: 21.44946 },
    { lat: 49.305206, lng: 21.449273 },
    { lat: 49.305225, lng: 21.44924 },
    { lat: 49.305305, lng: 21.449164 },
    { lat: 49.305455, lng: 21.44904 },
    { lat: 49.305648, lng: 21.449078 },
    { lat: 49.305721, lng: 21.448985 },
    { lat: 49.305756, lng: 21.44891 },
    { lat: 49.30606, lng: 21.448885 },
    { lat: 49.306174, lng: 21.44887 },
    { lat: 49.306315, lng: 21.448912 },
    { lat: 49.306335, lng: 21.44895 },
    { lat: 49.306455, lng: 21.448957 },
    { lat: 49.306515, lng: 21.449064 },
    { lat: 49.306521, lng: 21.449074 },
    { lat: 49.306954, lng: 21.44883 },
    { lat: 49.306982, lng: 21.448814 },
    { lat: 49.307005, lng: 21.448774 },
    { lat: 49.307071, lng: 21.448787 },
    { lat: 49.307458, lng: 21.448549 },
    { lat: 49.307729, lng: 21.448417 },
    { lat: 49.307866, lng: 21.448359 },
    { lat: 49.308212, lng: 21.448214 },
    { lat: 49.308396, lng: 21.448136 },
    { lat: 49.308594, lng: 21.448044 },
    { lat: 49.308799, lng: 21.447939 },
    { lat: 49.308907, lng: 21.447864 },
    { lat: 49.308966, lng: 21.447801 },
    { lat: 49.309024, lng: 21.447738 },
    { lat: 49.309291, lng: 21.447442 },
    { lat: 49.309339, lng: 21.447377 },
    { lat: 49.309379, lng: 21.44734 },
    { lat: 49.309514, lng: 21.447179 },
    { lat: 49.309582, lng: 21.447112 },
    { lat: 49.30969, lng: 21.446987 },
    { lat: 49.309813, lng: 21.446867 },
    { lat: 49.309832, lng: 21.446804 },
    { lat: 49.309875, lng: 21.446666 },
    { lat: 49.309969, lng: 21.446494 },
    { lat: 49.309996, lng: 21.446456 },
    { lat: 49.310104, lng: 21.446186 },
    { lat: 49.310193, lng: 21.446089 },
    { lat: 49.310309, lng: 21.445879 },
    { lat: 49.310427, lng: 21.44567 },
    { lat: 49.31041, lng: 21.445652 },
    { lat: 49.310336, lng: 21.445582 },
    { lat: 49.310256, lng: 21.445451 },
    { lat: 49.310224, lng: 21.445428 },
    { lat: 49.310181, lng: 21.445355 },
    { lat: 49.310101, lng: 21.44529 },
    { lat: 49.310032, lng: 21.445264 },
    { lat: 49.310001, lng: 21.445193 },
    { lat: 49.309969, lng: 21.445172 },
    { lat: 49.30991, lng: 21.445156 },
    { lat: 49.309877, lng: 21.445084 },
    { lat: 49.309814, lng: 21.44506 },
    { lat: 49.309763, lng: 21.445121 },
    { lat: 49.309696, lng: 21.445004 },
    { lat: 49.30963, lng: 21.444891 },
    { lat: 49.309612, lng: 21.444839 },
    { lat: 49.309576, lng: 21.444766 },
    { lat: 49.309545, lng: 21.444722 },
    { lat: 49.309523, lng: 21.444671 },
    { lat: 49.30951, lng: 21.444627 },
    { lat: 49.309481, lng: 21.444571 },
    { lat: 49.309426, lng: 21.444475 },
    { lat: 49.309393, lng: 21.444427 },
    { lat: 49.309366, lng: 21.444367 },
    { lat: 49.309321, lng: 21.444287 },
    { lat: 49.309277, lng: 21.444218 },
    { lat: 49.309194, lng: 21.444061 },
    { lat: 49.309167, lng: 21.444009 },
    { lat: 49.309127, lng: 21.443952 },
    { lat: 49.309076, lng: 21.443846 },
    { lat: 49.309019, lng: 21.443728 },
    { lat: 49.309, lng: 21.443706 },
    { lat: 49.309011, lng: 21.44366 },
    { lat: 49.308949, lng: 21.443583 },
    { lat: 49.308909, lng: 21.443524 },
    { lat: 49.308855, lng: 21.443445 },
    { lat: 49.308821, lng: 21.443383 },
    { lat: 49.308803, lng: 21.443359 },
    { lat: 49.308756, lng: 21.443302 },
    { lat: 49.308694, lng: 21.443205 },
    { lat: 49.308645, lng: 21.443113 },
    { lat: 49.308684, lng: 21.443015 },
    { lat: 49.308743, lng: 21.442975 },
    { lat: 49.308818, lng: 21.442971 },
    { lat: 49.308868, lng: 21.442898 },
    { lat: 49.308907, lng: 21.442834 },
    { lat: 49.309038, lng: 21.442624 },
    { lat: 49.308782, lng: 21.442137 },
    { lat: 49.308613, lng: 21.441782 },
    { lat: 49.308549, lng: 21.441681 },
    { lat: 49.308521, lng: 21.441627 },
    { lat: 49.308414, lng: 21.44146 },
    { lat: 49.308248, lng: 21.441256 },
    { lat: 49.307914, lng: 21.440886 },
    { lat: 49.307779, lng: 21.440715 },
    { lat: 49.307771, lng: 21.440704 },
    { lat: 49.307764, lng: 21.440688 },
    { lat: 49.307675, lng: 21.440459 },
    { lat: 49.307625, lng: 21.440329 },
    { lat: 49.307533, lng: 21.440144 },
    { lat: 49.307519, lng: 21.439974 },
    { lat: 49.30743, lng: 21.439836 },
    { lat: 49.307041, lng: 21.439509 },
    { lat: 49.306971, lng: 21.438903 },
    { lat: 49.306961, lng: 21.438867 },
    { lat: 49.30692, lng: 21.438777 },
    { lat: 49.306881, lng: 21.438721 },
    { lat: 49.306842, lng: 21.438633 },
    { lat: 49.306835, lng: 21.438622 },
    { lat: 49.306791, lng: 21.438504 },
    { lat: 49.306753, lng: 21.438333 },
    { lat: 49.30685, lng: 21.438197 },
    { lat: 49.306768, lng: 21.438041 },
    { lat: 49.306702, lng: 21.438065 },
    { lat: 49.306696, lng: 21.437953 },
    { lat: 49.306672, lng: 21.437797 },
    { lat: 49.306604, lng: 21.43782 },
    { lat: 49.306616, lng: 21.437651 },
    { lat: 49.306536, lng: 21.437585 },
    { lat: 49.30649, lng: 21.437505 },
    { lat: 49.306457, lng: 21.437434 },
    { lat: 49.306392, lng: 21.437265 },
    { lat: 49.306369, lng: 21.437218 },
    { lat: 49.306327, lng: 21.437107 },
    { lat: 49.30628, lng: 21.436975 },
    { lat: 49.306224, lng: 21.436851 },
    { lat: 49.306179, lng: 21.436724 },
    { lat: 49.306179, lng: 21.436659 },
    { lat: 49.306237, lng: 21.436616 },
    { lat: 49.306206, lng: 21.436522 },
    { lat: 49.306136, lng: 21.436283 },
    { lat: 49.306116, lng: 21.436195 },
    { lat: 49.306065, lng: 21.436021 },
    { lat: 49.306043, lng: 21.435944 },
    { lat: 49.306019, lng: 21.435822 },
    { lat: 49.30601, lng: 21.435779 },
    { lat: 49.305995, lng: 21.435563 },
    { lat: 49.305987, lng: 21.435441 },
    { lat: 49.305982, lng: 21.435384 },
    { lat: 49.305965, lng: 21.435327 },
    { lat: 49.305943, lng: 21.435243 },
    { lat: 49.306, lng: 21.435037 },
    { lat: 49.306064, lng: 21.434912 },
    { lat: 49.306345, lng: 21.434624 },
    { lat: 49.306563, lng: 21.434376 },
    { lat: 49.306682, lng: 21.434228 },
    { lat: 49.306692, lng: 21.434216 },
    { lat: 49.306693, lng: 21.434214 },
    { lat: 49.306703, lng: 21.434201 },
    { lat: 49.307028, lng: 21.433703 },
    { lat: 49.307087, lng: 21.433614 },
    { lat: 49.307364, lng: 21.433192 },
    { lat: 49.307651, lng: 21.432812 },
    { lat: 49.307669, lng: 21.432786 },
    { lat: 49.307833, lng: 21.432544 },
    { lat: 49.30804, lng: 21.43237 },
    { lat: 49.308366, lng: 21.432208 },
    { lat: 49.308659, lng: 21.432033 },
    { lat: 49.308914, lng: 21.431885 },
    { lat: 49.308973, lng: 21.431851 },
    { lat: 49.309539, lng: 21.431395 },
    { lat: 49.309787, lng: 21.431215 },
    { lat: 49.310004, lng: 21.431108 },
    { lat: 49.310292, lng: 21.430947 },
    { lat: 49.310558, lng: 21.430758 },
    { lat: 49.311035, lng: 21.430197 },
    { lat: 49.311146, lng: 21.429941 },
    { lat: 49.31117, lng: 21.429891 },
    { lat: 49.311298, lng: 21.429653 },
    { lat: 49.311762, lng: 21.428258 },
    { lat: 49.311884, lng: 21.427935 },
    { lat: 49.311942, lng: 21.42778 },
    { lat: 49.312149, lng: 21.427352 },
    { lat: 49.3124, lng: 21.426862 },
    { lat: 49.312528, lng: 21.426365 },
    { lat: 49.31406, lng: 21.424529 },
    { lat: 49.314294, lng: 21.424192 },
    { lat: 49.314675, lng: 21.423095 },
    { lat: 49.314756, lng: 21.423169 },
    { lat: 49.314907, lng: 21.422751 },
    { lat: 49.315467, lng: 21.421276 },
    { lat: 49.315733, lng: 21.420608 },
    { lat: 49.31609, lng: 21.419498 },
    { lat: 49.316586, lng: 21.4175 },
    { lat: 49.317177, lng: 21.415643 },
    { lat: 49.317455, lng: 21.412971 },
    { lat: 49.317679, lng: 21.412213 },
    { lat: 49.31791, lng: 21.41162 },
    { lat: 49.319018, lng: 21.409168 },
    { lat: 49.319533, lng: 21.408462 },
    { lat: 49.319894, lng: 21.407967 },
    { lat: 49.320507, lng: 21.406351 },
    { lat: 49.320701, lng: 21.403984 },
    { lat: 49.3211, lng: 21.402138 },
    { lat: 49.321303, lng: 21.400819 },
    { lat: 49.321625, lng: 21.399911 },
    { lat: 49.322044, lng: 21.398929 },
    { lat: 49.322641, lng: 21.397605 },
    { lat: 49.323072, lng: 21.396612 },
    { lat: 49.32331, lng: 21.396293 },
    { lat: 49.323692, lng: 21.395626 },
    { lat: 49.323785, lng: 21.394891 },
    { lat: 49.323848, lng: 21.394503 },
    { lat: 49.3239015, lng: 21.3941285 },
    { lat: 49.3244558, lng: 21.3935022 },
    { lat: 49.3249788, lng: 21.3925003 },
    { lat: 49.3253803, lng: 21.3909258 },
    { lat: 49.3262166, lng: 21.3901765 },
    { lat: 49.3260211, lng: 21.3887999 },
    { lat: 49.326081, lng: 21.3879676 },
    { lat: 49.3258561, lng: 21.3851037 },
    { lat: 49.3255204, lng: 21.3835571 },
    { lat: 49.3259967, lng: 21.3819268 },
    { lat: 49.3258209, lng: 21.3789005 },
    { lat: 49.3255498, lng: 21.3766461 },
    { lat: 49.3252566, lng: 21.3755197 },
    { lat: 49.3247566, lng: 21.3742049 },
    { lat: 49.3248646, lng: 21.3731406 },
    { lat: 49.3245033, lng: 21.3721773 },
    { lat: 49.3240713, lng: 21.3712647 },
    { lat: 49.3232321, lng: 21.3708528 },
    { lat: 49.3215373, lng: 21.3703042 },
    { lat: 49.3212176, lng: 21.3699937 },
    { lat: 49.3204261, lng: 21.3700845 },
    { lat: 49.3192471, lng: 21.3704715 },
    { lat: 49.3180106, lng: 21.3700095 },
    { lat: 49.3170403, lng: 21.3697632 },
    { lat: 49.3161609, lng: 21.3699693 },
    { lat: 49.3151146, lng: 21.3708083 },
    { lat: 49.3140549, lng: 21.3725245 },
    { lat: 49.3137552, lng: 21.37282 },
    { lat: 49.3132827, lng: 21.3728584 },
    { lat: 49.311913, lng: 21.3744419 },
    { lat: 49.3118907, lng: 21.3753225 },
    { lat: 49.3118016, lng: 21.3755807 },
    { lat: 49.3116047, lng: 21.3756989 },
    { lat: 49.3113466, lng: 21.3759864 },
    { lat: 49.3104164, lng: 21.378094 },
    { lat: 49.3087017, lng: 21.3812926 },
    { lat: 49.3083578, lng: 21.3817288 },
    { lat: 49.3082314, lng: 21.3820937 },
    { lat: 49.308186, lng: 21.382011 },
    { lat: 49.3079895, lng: 21.3820624 },
    { lat: 49.3075698, lng: 21.3832786 },
    { lat: 49.3070262, lng: 21.3834646 },
    { lat: 49.3069356, lng: 21.3837081 },
    { lat: 49.306692, lng: 21.3837324 },
    { lat: 49.3058857, lng: 21.3840829 },
    { lat: 49.3055803, lng: 21.3841432 },
    { lat: 49.3055788, lng: 21.3844382 },
    { lat: 49.30547, lng: 21.3844486 },
    { lat: 49.3053653, lng: 21.3844075 },
    { lat: 49.3050684, lng: 21.3844546 },
    { lat: 49.3048012, lng: 21.3843551 },
    { lat: 49.3040606, lng: 21.3845344 },
    { lat: 49.3037353, lng: 21.3845287 },
    { lat: 49.3033484, lng: 21.3841912 },
    { lat: 49.3030565, lng: 21.3841349 },
    { lat: 49.3030602, lng: 21.3842222 },
    { lat: 49.3028898, lng: 21.384248 },
    { lat: 49.3028319, lng: 21.3840018 },
    { lat: 49.3026956, lng: 21.3845274 },
    { lat: 49.3024888, lng: 21.3848398 },
    { lat: 49.302402, lng: 21.3842625 },
    { lat: 49.3023376, lng: 21.3842911 },
    { lat: 49.3023048, lng: 21.3844368 },
    { lat: 49.302097, lng: 21.3845589 },
    { lat: 49.3019181, lng: 21.3847547 },
    { lat: 49.3017965, lng: 21.384563 },
    { lat: 49.301669, lng: 21.3846226 },
    { lat: 49.3014816, lng: 21.3844127 },
    { lat: 49.301302, lng: 21.3843832 },
    { lat: 49.3012234, lng: 21.3842758 },
    { lat: 49.301132, lng: 21.3842706 },
    { lat: 49.3011026, lng: 21.3838396 },
    { lat: 49.3009275, lng: 21.3838174 },
    { lat: 49.3007807, lng: 21.3836862 },
    { lat: 49.3004441, lng: 21.3837523 },
    { lat: 49.3002443, lng: 21.3835793 },
    { lat: 49.2996854, lng: 21.383661 },
    { lat: 49.2990953, lng: 21.3841418 },
    { lat: 49.2985509, lng: 21.3844933 },
    { lat: 49.2976909, lng: 21.3855038 },
    { lat: 49.2968859, lng: 21.3868223 },
    { lat: 49.2964352, lng: 21.3876834 },
    { lat: 49.2962684, lng: 21.3880168 },
    { lat: 49.2960655, lng: 21.3886926 },
    { lat: 49.2959097, lng: 21.3887166 },
    { lat: 49.2959055, lng: 21.3887998 },
    { lat: 49.2949182, lng: 21.388433 },
    { lat: 49.2943368, lng: 21.3884783 },
    { lat: 49.2938132, lng: 21.3884318 },
    { lat: 49.2934763, lng: 21.3882534 },
    { lat: 49.2929962, lng: 21.3878809 },
    { lat: 49.2923949, lng: 21.3875658 },
    { lat: 49.2919928, lng: 21.3872387 },
    { lat: 49.2918294, lng: 21.3871919 },
    { lat: 49.2916916, lng: 21.387098 },
    { lat: 49.2915892, lng: 21.3873678 },
    { lat: 49.2914106, lng: 21.3878389 },
    { lat: 49.2909277, lng: 21.3882855 },
    { lat: 49.2902256, lng: 21.3887 },
    { lat: 49.2889518, lng: 21.3896558 },
    { lat: 49.2883376, lng: 21.3902717 },
    { lat: 49.2866859, lng: 21.3926399 },
    { lat: 49.2864361, lng: 21.392714 },
    { lat: 49.2852959, lng: 21.3949986 },
    { lat: 49.2848833, lng: 21.3963895 },
    { lat: 49.2845425, lng: 21.3969308 },
    { lat: 49.2841541, lng: 21.3978353 },
    { lat: 49.2836417, lng: 21.3992395 },
    { lat: 49.2817225, lng: 21.4032838 },
    { lat: 49.2812038, lng: 21.4045067 },
    { lat: 49.2819397, lng: 21.406046 },
    { lat: 49.2823474, lng: 21.4074127 },
    { lat: 49.282882, lng: 21.4081096 },
    { lat: 49.283122, lng: 21.408779 },
    { lat: 49.2831513, lng: 21.4092429 },
    { lat: 49.2832744, lng: 21.4099528 },
    { lat: 49.2832181, lng: 21.4111801 },
    { lat: 49.2828743, lng: 21.413084 },
    { lat: 49.2823566, lng: 21.4136908 },
    { lat: 49.2815709, lng: 21.4150241 },
    { lat: 49.2814236, lng: 21.4151073 },
    { lat: 49.2810036, lng: 21.4160974 },
    { lat: 49.2808426, lng: 21.4167941 },
    { lat: 49.2804884, lng: 21.4178406 },
    { lat: 49.2791451, lng: 21.4210465 },
    { lat: 49.2780312, lng: 21.4254846 },
    { lat: 49.2775671, lng: 21.4286002 },
    { lat: 49.2773783, lng: 21.4303862 },
    { lat: 49.2770855, lng: 21.4316932 },
    { lat: 49.276361, lng: 21.4323906 },
    { lat: 49.2763796, lng: 21.432919 },
    { lat: 49.2756028, lng: 21.4345675 },
  ],
  Hervartov: [
    { lat: 49.2278366, lng: 21.1586582 },
    { lat: 49.228176, lng: 21.1591133 },
    { lat: 49.2283822, lng: 21.1599054 },
    { lat: 49.2287283, lng: 21.1608176 },
    { lat: 49.2290514, lng: 21.1610075 },
    { lat: 49.2293925, lng: 21.1613663 },
    { lat: 49.2297649, lng: 21.1620822 },
    { lat: 49.2300184, lng: 21.1627445 },
    { lat: 49.230198, lng: 21.1635145 },
    { lat: 49.2302837, lng: 21.1641268 },
    { lat: 49.2306412, lng: 21.1647318 },
    { lat: 49.2309085, lng: 21.1650738 },
    { lat: 49.2312038, lng: 21.1660236 },
    { lat: 49.2310885, lng: 21.1665797 },
    { lat: 49.2311274, lng: 21.1668123 },
    { lat: 49.2310905, lng: 21.1676151 },
    { lat: 49.2311698, lng: 21.1681681 },
    { lat: 49.2311106, lng: 21.1684388 },
    { lat: 49.2310755, lng: 21.1698615 },
    { lat: 49.2313143, lng: 21.1716688 },
    { lat: 49.2316192, lng: 21.1724578 },
    { lat: 49.2316708, lng: 21.1729548 },
    { lat: 49.2316401, lng: 21.1733773 },
    { lat: 49.2316617, lng: 21.173999 },
    { lat: 49.2317835, lng: 21.1748686 },
    { lat: 49.2318837, lng: 21.1752315 },
    { lat: 49.2322649, lng: 21.1758032 },
    { lat: 49.2323494, lng: 21.1763771 },
    { lat: 49.232871, lng: 21.1766127 },
    { lat: 49.2329966, lng: 21.1771379 },
    { lat: 49.2333315, lng: 21.1793585 },
    { lat: 49.2335178, lng: 21.1797061 },
    { lat: 49.2337645, lng: 21.1798606 },
    { lat: 49.2343103, lng: 21.1810808 },
    { lat: 49.2346267, lng: 21.1814894 },
    { lat: 49.2350282, lng: 21.1831429 },
    { lat: 49.2351704, lng: 21.183368 },
    { lat: 49.2350897, lng: 21.1834124 },
    { lat: 49.2354923, lng: 21.1847068 },
    { lat: 49.2356727, lng: 21.1850415 },
    { lat: 49.235816, lng: 21.18516 },
    { lat: 49.2357727, lng: 21.1856187 },
    { lat: 49.235827, lng: 21.1861642 },
    { lat: 49.235965, lng: 21.1871657 },
    { lat: 49.2360678, lng: 21.1876115 },
    { lat: 49.2359729, lng: 21.1886447 },
    { lat: 49.2359843, lng: 21.1892974 },
    { lat: 49.2359186, lng: 21.189686 },
    { lat: 49.2359215, lng: 21.1899627 },
    { lat: 49.2361419, lng: 21.1909665 },
    { lat: 49.236206, lng: 21.1916183 },
    { lat: 49.23672, lng: 21.1930941 },
    { lat: 49.2367716, lng: 21.1937003 },
    { lat: 49.2367339, lng: 21.1949828 },
    { lat: 49.2368923, lng: 21.1955561 },
    { lat: 49.2370503, lng: 21.1959364 },
    { lat: 49.237152, lng: 21.1964908 },
    { lat: 49.2371598, lng: 21.1968856 },
    { lat: 49.2369718, lng: 21.197932 },
    { lat: 49.2369538, lng: 21.1986563 },
    { lat: 49.2369103, lng: 21.1991028 },
    { lat: 49.2367917, lng: 21.1996702 },
    { lat: 49.236744, lng: 21.2005285 },
    { lat: 49.2369308, lng: 21.2009233 },
    { lat: 49.2369867, lng: 21.2012358 },
    { lat: 49.2378025, lng: 21.2033027 },
    { lat: 49.2390932, lng: 21.2084571 },
    { lat: 49.2391929, lng: 21.2089897 },
    { lat: 49.2393382, lng: 21.2093955 },
    { lat: 49.2400709, lng: 21.2097646 },
    { lat: 49.2401848, lng: 21.2099534 },
    { lat: 49.2403313, lng: 21.2100036 },
    { lat: 49.2404522, lng: 21.2102351 },
    { lat: 49.2406822, lng: 21.2103229 },
    { lat: 49.2409126, lng: 21.2105692 },
    { lat: 49.2410768, lng: 21.2106016 },
    { lat: 49.2411544, lng: 21.2107091 },
    { lat: 49.2411442, lng: 21.210815 },
    { lat: 49.2412051, lng: 21.2108923 },
    { lat: 49.2413021, lng: 21.2108954 },
    { lat: 49.2413228, lng: 21.2110221 },
    { lat: 49.2411713, lng: 21.2110338 },
    { lat: 49.241179, lng: 21.2111773 },
    { lat: 49.2412268, lng: 21.211266 },
    { lat: 49.2413532, lng: 21.2112065 },
    { lat: 49.2413906, lng: 21.2115754 },
    { lat: 49.2414962, lng: 21.2117874 },
    { lat: 49.2414652, lng: 21.212058 },
    { lat: 49.2415575, lng: 21.2127165 },
    { lat: 49.2415472, lng: 21.2129253 },
    { lat: 49.2416972, lng: 21.2131544 },
    { lat: 49.2417222, lng: 21.2140006 },
    { lat: 49.2418984, lng: 21.2142108 },
    { lat: 49.2418272, lng: 21.2144537 },
    { lat: 49.2418673, lng: 21.214624 },
    { lat: 49.2421239, lng: 21.2145857 },
    { lat: 49.2421693, lng: 21.21507 },
    { lat: 49.2423692, lng: 21.2153833 },
    { lat: 49.2423057, lng: 21.2155539 },
    { lat: 49.2425559, lng: 21.2157847 },
    { lat: 49.2425167, lng: 21.216775 },
    { lat: 49.2425465, lng: 21.2172648 },
    { lat: 49.2426193, lng: 21.2174706 },
    { lat: 49.2425334, lng: 21.2175682 },
    { lat: 49.2425097, lng: 21.2177064 },
    { lat: 49.2426219, lng: 21.2179194 },
    { lat: 49.2426125, lng: 21.2181582 },
    { lat: 49.2424371, lng: 21.2182727 },
    { lat: 49.24248, lng: 21.2184947 },
    { lat: 49.2426357, lng: 21.2188029 },
    { lat: 49.2426367, lng: 21.2189429 },
    { lat: 49.2423522, lng: 21.2191359 },
    { lat: 49.2423341, lng: 21.2193807 },
    { lat: 49.2424606, lng: 21.2196132 },
    { lat: 49.2426242, lng: 21.2197415 },
    { lat: 49.2426671, lng: 21.2197125 },
    { lat: 49.2425621, lng: 21.2200597 },
    { lat: 49.2426121, lng: 21.2204436 },
    { lat: 49.2425506, lng: 21.2206741 },
    { lat: 49.2427774, lng: 21.2220767 },
    { lat: 49.2433024, lng: 21.2238475 },
    { lat: 49.2438226, lng: 21.2247064 },
    { lat: 49.2439073, lng: 21.2246377 },
    { lat: 49.244086, lng: 21.2248513 },
    { lat: 49.2444815, lng: 21.2255273 },
    { lat: 49.2449101, lng: 21.2268031 },
    { lat: 49.2454812, lng: 21.2276613 },
    { lat: 49.245525, lng: 21.2279898 },
    { lat: 49.2454875, lng: 21.2282317 },
    { lat: 49.2454828, lng: 21.2282932 },
    { lat: 49.2458148, lng: 21.228332 },
    { lat: 49.245927, lng: 21.2283236 },
    { lat: 49.2459029, lng: 21.2279778 },
    { lat: 49.2459617, lng: 21.227845 },
    { lat: 49.2465152, lng: 21.2277325 },
    { lat: 49.2469781, lng: 21.2277802 },
    { lat: 49.2469217, lng: 21.2279615 },
    { lat: 49.2471148, lng: 21.2281691 },
    { lat: 49.2471011, lng: 21.2286743 },
    { lat: 49.2471876, lng: 21.2289242 },
    { lat: 49.2476136, lng: 21.2293041 },
    { lat: 49.247789, lng: 21.2298563 },
    { lat: 49.2479092, lng: 21.229955 },
    { lat: 49.2481134, lng: 21.2298607 },
    { lat: 49.2481508, lng: 21.2299045 },
    { lat: 49.2482531, lng: 21.230602 },
    { lat: 49.2482428, lng: 21.2315849 },
    { lat: 49.2483796, lng: 21.2317159 },
    { lat: 49.2484427, lng: 21.231711 },
    { lat: 49.2487834, lng: 21.2313072 },
    { lat: 49.2489512, lng: 21.2312714 },
    { lat: 49.2491304, lng: 21.2315712 },
    { lat: 49.2491137, lng: 21.2319884 },
    { lat: 49.2490813, lng: 21.2320995 },
    { lat: 49.2488218, lng: 21.2321466 },
    { lat: 49.2487704, lng: 21.2322338 },
    { lat: 49.2488113, lng: 21.2324351 },
    { lat: 49.2490565, lng: 21.2326109 },
    { lat: 49.2495117, lng: 21.2324079 },
    { lat: 49.249442, lng: 21.2325256 },
    { lat: 49.2499754, lng: 21.232268 },
    { lat: 49.2501166, lng: 21.2323288 },
    { lat: 49.2501342, lng: 21.232714 },
    { lat: 49.250066, lng: 21.2330211 },
    { lat: 49.2501144, lng: 21.2332366 },
    { lat: 49.2504246, lng: 21.2333029 },
    { lat: 49.2505899, lng: 21.2334106 },
    { lat: 49.2506596, lng: 21.2337377 },
    { lat: 49.2507938, lng: 21.2339979 },
    { lat: 49.2511897, lng: 21.2340318 },
    { lat: 49.2514223, lng: 21.2341653 },
    { lat: 49.2515776, lng: 21.2343533 },
    { lat: 49.2516919, lng: 21.2349193 },
    { lat: 49.251718, lng: 21.2355164 },
    { lat: 49.2530094, lng: 21.2365652 },
    { lat: 49.2533609, lng: 21.2366859 },
    { lat: 49.2535123, lng: 21.2368198 },
    { lat: 49.2536112, lng: 21.237 },
    { lat: 49.2536199, lng: 21.2372119 },
    { lat: 49.253562, lng: 21.2373756 },
    { lat: 49.2535483, lng: 21.237623 },
    { lat: 49.2535802, lng: 21.2377457 },
    { lat: 49.2540842, lng: 21.2377042 },
    { lat: 49.2545413, lng: 21.2375338 },
    { lat: 49.2552602, lng: 21.2373868 },
    { lat: 49.2557414, lng: 21.2374481 },
    { lat: 49.2558197, lng: 21.2373935 },
    { lat: 49.255904, lng: 21.2371375 },
    { lat: 49.2560229, lng: 21.2372313 },
    { lat: 49.2561577, lng: 21.2372212 },
    { lat: 49.2563699, lng: 21.2371001 },
    { lat: 49.2566346, lng: 21.2367058 },
    { lat: 49.2565915, lng: 21.2366228 },
    { lat: 49.2565384, lng: 21.2366893 },
    { lat: 49.2562585, lng: 21.235821 },
    { lat: 49.2561798, lng: 21.235049 },
    { lat: 49.2562405, lng: 21.2350133 },
    { lat: 49.2562306, lng: 21.2348364 },
    { lat: 49.2564052, lng: 21.2348091 },
    { lat: 49.2566696, lng: 21.2345531 },
    { lat: 49.2568224, lng: 21.2331895 },
    { lat: 49.2568258, lng: 21.2325318 },
    { lat: 49.2566055, lng: 21.2323927 },
    { lat: 49.2564329, lng: 21.2319026 },
    { lat: 49.2564689, lng: 21.2317916 },
    { lat: 49.2566259, lng: 21.2316683 },
    { lat: 49.2567711, lng: 21.2317739 },
    { lat: 49.2568011, lng: 21.2319425 },
    { lat: 49.2568487, lng: 21.2319185 },
    { lat: 49.2569674, lng: 21.2321232 },
    { lat: 49.2570884, lng: 21.2321092 },
    { lat: 49.2571387, lng: 21.2318437 },
    { lat: 49.2572441, lng: 21.2316826 },
    { lat: 49.257095, lng: 21.2312585 },
    { lat: 49.257025, lng: 21.2312128 },
    { lat: 49.2569849, lng: 21.2310817 },
    { lat: 49.2569512, lng: 21.2309378 },
    { lat: 49.2570486, lng: 21.2307759 },
    { lat: 49.2569182, lng: 21.2301268 },
    { lat: 49.2568515, lng: 21.230043 },
    { lat: 49.2568673, lng: 21.229713 },
    { lat: 49.2571068, lng: 21.2296349 },
    { lat: 49.2571568, lng: 21.2291749 },
    { lat: 49.257164, lng: 21.2289355 },
    { lat: 49.2570816, lng: 21.2287008 },
    { lat: 49.2571663, lng: 21.2284191 },
    { lat: 49.2571358, lng: 21.2280197 },
    { lat: 49.2570826, lng: 21.2278999 },
    { lat: 49.2571681, lng: 21.2273455 },
    { lat: 49.2570523, lng: 21.2271694 },
    { lat: 49.2570487, lng: 21.2269042 },
    { lat: 49.2570529, lng: 21.2267901 },
    { lat: 49.2571681, lng: 21.2266939 },
    { lat: 49.2571786, lng: 21.2265591 },
    { lat: 49.2571029, lng: 21.2262551 },
    { lat: 49.2570105, lng: 21.2246807 },
    { lat: 49.257077, lng: 21.2240978 },
    { lat: 49.2573441, lng: 21.2230584 },
    { lat: 49.2573356, lng: 21.222706 },
    { lat: 49.2574124, lng: 21.2219857 },
    { lat: 49.2572616, lng: 21.2217271 },
    { lat: 49.2572053, lng: 21.2214383 },
    { lat: 49.2572564, lng: 21.221306 },
    { lat: 49.2572887, lng: 21.2207097 },
    { lat: 49.2572602, lng: 21.2201941 },
    { lat: 49.2571466, lng: 21.219788 },
    { lat: 49.2571904, lng: 21.2194978 },
    { lat: 49.2571039, lng: 21.2189957 },
    { lat: 49.2569893, lng: 21.2187421 },
    { lat: 49.256888, lng: 21.2178283 },
    { lat: 49.2567826, lng: 21.2176622 },
    { lat: 49.2567128, lng: 21.2172267 },
    { lat: 49.2563249, lng: 21.2160631 },
    { lat: 49.2563987, lng: 21.2154299 },
    { lat: 49.2563688, lng: 21.2150448 },
    { lat: 49.256428, lng: 21.2148157 },
    { lat: 49.2563541, lng: 21.2140938 },
    { lat: 49.2563592, lng: 21.2128416 },
    { lat: 49.2564577, lng: 21.2125722 },
    { lat: 49.2563538, lng: 21.2109987 },
    { lat: 49.2564569, lng: 21.2090565 },
    { lat: 49.256613, lng: 21.2077023 },
    { lat: 49.2567298, lng: 21.2071895 },
    { lat: 49.2569044, lng: 21.2069031 },
    { lat: 49.2575038, lng: 21.2063489 },
    { lat: 49.2575028, lng: 21.2062402 },
    { lat: 49.2572499, lng: 21.2054905 },
    { lat: 49.2586584, lng: 21.2042077 },
    { lat: 49.2586498, lng: 21.2033142 },
    { lat: 49.2587111, lng: 21.2030028 },
    { lat: 49.2588238, lng: 21.2026142 },
    { lat: 49.2592682, lng: 21.201961 },
    { lat: 49.2591912, lng: 21.2017962 },
    { lat: 49.2592209, lng: 21.2012623 },
    { lat: 49.2591066, lng: 21.200915 },
    { lat: 49.2591208, lng: 21.2006125 },
    { lat: 49.259195, lng: 21.2004677 },
    { lat: 49.2590715, lng: 21.2000796 },
    { lat: 49.259092, lng: 21.1997993 },
    { lat: 49.2592849, lng: 21.1993736 },
    { lat: 49.259201, lng: 21.1989978 },
    { lat: 49.2592745, lng: 21.1987125 },
    { lat: 49.2592395, lng: 21.1983537 },
    { lat: 49.2591894, lng: 21.1982569 },
    { lat: 49.2592187, lng: 21.1980404 },
    { lat: 49.2592757, lng: 21.1977405 },
    { lat: 49.2594494, lng: 21.1976112 },
    { lat: 49.2595421, lng: 21.1973321 },
    { lat: 49.2595155, lng: 21.197204 },
    { lat: 49.2592455, lng: 21.1972009 },
    { lat: 49.2592137, lng: 21.1969002 },
    { lat: 49.2593987, lng: 21.1968054 },
    { lat: 49.2594253, lng: 21.1966536 },
    { lat: 49.2592934, lng: 21.196537 },
    { lat: 49.2592937, lng: 21.1963634 },
    { lat: 49.2594545, lng: 21.1961035 },
    { lat: 49.2591925, lng: 21.1955735 },
    { lat: 49.2589305, lng: 21.1954271 },
    { lat: 49.2588128, lng: 21.1952292 },
    { lat: 49.2586743, lng: 21.1952263 },
    { lat: 49.257977, lng: 21.1942218 },
    { lat: 49.2575934, lng: 21.1937907 },
    { lat: 49.2572407, lng: 21.1930864 },
    { lat: 49.2571265, lng: 21.192599 },
    { lat: 49.2568951, lng: 21.1922477 },
    { lat: 49.2559714, lng: 21.1918864 },
    { lat: 49.2555878, lng: 21.1920393 },
    { lat: 49.2551297, lng: 21.1918517 },
    { lat: 49.2546446, lng: 21.1913412 },
    { lat: 49.2543367, lng: 21.1904201 },
    { lat: 49.2542342, lng: 21.1898678 },
    { lat: 49.2529756, lng: 21.1876136 },
    { lat: 49.2526526, lng: 21.1868875 },
    { lat: 49.2525149, lng: 21.18669 },
    { lat: 49.2521861, lng: 21.1855314 },
    { lat: 49.2520144, lng: 21.1851127 },
    { lat: 49.2519223, lng: 21.1846086 },
    { lat: 49.2516382, lng: 21.1835519 },
    { lat: 49.2514261, lng: 21.1820124 },
    { lat: 49.2512063, lng: 21.1815335 },
    { lat: 49.2507163, lng: 21.1809766 },
    { lat: 49.2505312, lng: 21.1806364 },
    { lat: 49.2501758, lng: 21.1795911 },
    { lat: 49.249513, lng: 21.1787598 },
    { lat: 49.2490742, lng: 21.1780066 },
    { lat: 49.248687, lng: 21.1774621 },
    { lat: 49.248586, lng: 21.1770726 },
    { lat: 49.2484845, lng: 21.1768887 },
    { lat: 49.2481912, lng: 21.1765813 },
    { lat: 49.2473696, lng: 21.1754904 },
    { lat: 49.2470867, lng: 21.1746484 },
    { lat: 49.2469577, lng: 21.1740891 },
    { lat: 49.2468072, lng: 21.1739136 },
    { lat: 49.2467288, lng: 21.1736769 },
    { lat: 49.2462198, lng: 21.1729782 },
    { lat: 49.2460965, lng: 21.172886 },
    { lat: 49.2454424, lng: 21.1730223 },
    { lat: 49.2443681, lng: 21.1724878 },
    { lat: 49.2437991, lng: 21.1722833 },
    { lat: 49.243623, lng: 21.1720498 },
    { lat: 49.2434269, lng: 21.1715779 },
    { lat: 49.2431227, lng: 21.1711694 },
    { lat: 49.2429182, lng: 21.1702429 },
    { lat: 49.2421127, lng: 21.1692001 },
    { lat: 49.241857, lng: 21.1686948 },
    { lat: 49.2417811, lng: 21.1682131 },
    { lat: 49.2415478, lng: 21.167803 },
    { lat: 49.2413729, lng: 21.1671794 },
    { lat: 49.241495, lng: 21.1668691 },
    { lat: 49.2413891, lng: 21.1665444 },
    { lat: 49.2412902, lng: 21.1663702 },
    { lat: 49.2410999, lng: 21.1663628 },
    { lat: 49.2408826, lng: 21.1658922 },
    { lat: 49.2406599, lng: 21.1656481 },
    { lat: 49.2403936, lng: 21.1650867 },
    { lat: 49.2400034, lng: 21.1639833 },
    { lat: 49.2397571, lng: 21.1619429 },
    { lat: 49.2397155, lng: 21.16058 },
    { lat: 49.2395322, lng: 21.1600425 },
    { lat: 49.2394277, lng: 21.1592218 },
    { lat: 49.2391636, lng: 21.1579091 },
    { lat: 49.2391192, lng: 21.1572822 },
    { lat: 49.2390121, lng: 21.1569875 },
    { lat: 49.2390754, lng: 21.1567812 },
    { lat: 49.2389381, lng: 21.1558481 },
    { lat: 49.2389831, lng: 21.154852 },
    { lat: 49.2391745, lng: 21.1535296 },
    { lat: 49.2396279, lng: 21.1516673 },
    { lat: 49.2397236, lng: 21.1513074 },
    { lat: 49.2396946, lng: 21.1507078 },
    { lat: 49.2393319, lng: 21.1503771 },
    { lat: 49.2390329, lng: 21.1495975 },
    { lat: 49.238499, lng: 21.1494076 },
    { lat: 49.2377968, lng: 21.1492964 },
    { lat: 49.2371659, lng: 21.1495246 },
    { lat: 49.2367453, lng: 21.1498551 },
    { lat: 49.2366672, lng: 21.1504828 },
    { lat: 49.2363693, lng: 21.1513887 },
    { lat: 49.2355716, lng: 21.1518905 },
    { lat: 49.2350706, lng: 21.1524915 },
    { lat: 49.2344362, lng: 21.1541822 },
    { lat: 49.2339134, lng: 21.1546616 },
    { lat: 49.2338096, lng: 21.1546815 },
    { lat: 49.2327513, lng: 21.1542748 },
    { lat: 49.2323736, lng: 21.1542179 },
    { lat: 49.2317902, lng: 21.1539467 },
    { lat: 49.230988, lng: 21.1541344 },
    { lat: 49.229648, lng: 21.1549229 },
    { lat: 49.2292646, lng: 21.1556139 },
    { lat: 49.2289131, lng: 21.1568523 },
    { lat: 49.2283951, lng: 21.1573792 },
    { lat: 49.228268, lng: 21.1577021 },
    { lat: 49.2280678, lng: 21.1580249 },
    { lat: 49.2278366, lng: 21.1586582 },
  ],
  Gerlachov: [
    { lat: 49.322521, lng: 21.1352109 },
    { lat: 49.322876, lng: 21.1346544 },
    { lat: 49.3241058, lng: 21.1316918 },
    { lat: 49.3243146, lng: 21.1314278 },
    { lat: 49.325201, lng: 21.1306369 },
    { lat: 49.3262351, lng: 21.1302841 },
    { lat: 49.3269696, lng: 21.1297759 },
    { lat: 49.3271057, lng: 21.1298084 },
    { lat: 49.3272963, lng: 21.1297149 },
    { lat: 49.3276003, lng: 21.1292238 },
    { lat: 49.3280297, lng: 21.1288515 },
    { lat: 49.3287174, lng: 21.1279283 },
    { lat: 49.329029, lng: 21.1268586 },
    { lat: 49.3291614, lng: 21.1266685 },
    { lat: 49.3293379, lng: 21.1260255 },
    { lat: 49.3292459, lng: 21.1257466 },
    { lat: 49.3289999, lng: 21.1254105 },
    { lat: 49.3288549, lng: 21.1249486 },
    { lat: 49.3289387, lng: 21.1240059 },
    { lat: 49.3291114, lng: 21.1236352 },
    { lat: 49.3291887, lng: 21.1230529 },
    { lat: 49.3291834, lng: 21.1228852 },
    { lat: 49.3289549, lng: 21.1222813 },
    { lat: 49.3290776, lng: 21.1219273 },
    { lat: 49.3293188, lng: 21.1216493 },
    { lat: 49.3293701, lng: 21.1212439 },
    { lat: 49.3293844, lng: 21.1210553 },
    { lat: 49.3292843, lng: 21.1201752 },
    { lat: 49.3294074, lng: 21.1198991 },
    { lat: 49.3298142, lng: 21.1193497 },
    { lat: 49.3302329, lng: 21.1184326 },
    { lat: 49.3307077, lng: 21.1177891 },
    { lat: 49.3311555, lng: 21.117009 },
    { lat: 49.3314476, lng: 21.1168517 },
    { lat: 49.3315165, lng: 21.1167571 },
    { lat: 49.3319874, lng: 21.1159176 },
    { lat: 49.3323019, lng: 21.1151203 },
    { lat: 49.3327553, lng: 21.1145527 },
    { lat: 49.3331468, lng: 21.114406 },
    { lat: 49.3336579, lng: 21.1144485 },
    { lat: 49.334016, lng: 21.114669 },
    { lat: 49.3343415, lng: 21.1145002 },
    { lat: 49.3349209, lng: 21.11393 },
    { lat: 49.3352064, lng: 21.1137807 },
    { lat: 49.3356601, lng: 21.1137163 },
    { lat: 49.3359615, lng: 21.1138866 },
    { lat: 49.3361692, lng: 21.1141898 },
    { lat: 49.336371, lng: 21.1139886 },
    { lat: 49.336445, lng: 21.1138398 },
    { lat: 49.3365789, lng: 21.11381 },
    { lat: 49.3368254, lng: 21.1138404 },
    { lat: 49.3373203, lng: 21.1141219 },
    { lat: 49.337532, lng: 21.113986 },
    { lat: 49.3379984, lng: 21.1142466 },
    { lat: 49.3385728, lng: 21.1140162 },
    { lat: 49.3386558, lng: 21.1140465 },
    { lat: 49.3388339, lng: 21.1137742 },
    { lat: 49.3391728, lng: 21.1138804 },
    { lat: 49.3393924, lng: 21.1143501 },
    { lat: 49.339567, lng: 21.1144295 },
    { lat: 49.3397681, lng: 21.1140263 },
    { lat: 49.3401705, lng: 21.1139048 },
    { lat: 49.3403401, lng: 21.1136461 },
    { lat: 49.3404448, lng: 21.1132955 },
    { lat: 49.3408728, lng: 21.1131341 },
    { lat: 49.3409814, lng: 21.113182 },
    { lat: 49.3412056, lng: 21.1130841 },
    { lat: 49.341699, lng: 21.112823 },
    { lat: 49.3418458, lng: 21.1125212 },
    { lat: 49.3421617, lng: 21.1122964 },
    { lat: 49.3426458, lng: 21.1122016 },
    { lat: 49.3426916, lng: 21.1119044 },
    { lat: 49.34296, lng: 21.1117076 },
    { lat: 49.3433602, lng: 21.1116649 },
    { lat: 49.3436083, lng: 21.1112552 },
    { lat: 49.3439158, lng: 21.1112288 },
    { lat: 49.3440677, lng: 21.1109078 },
    { lat: 49.3442752, lng: 21.1107448 },
    { lat: 49.3446044, lng: 21.110873 },
    { lat: 49.3447437, lng: 21.1106843 },
    { lat: 49.3448592, lng: 21.1102835 },
    { lat: 49.3449621, lng: 21.1102274 },
    { lat: 49.3451188, lng: 21.1102743 },
    { lat: 49.3452542, lng: 21.1101815 },
    { lat: 49.3456214, lng: 21.1094472 },
    { lat: 49.3457127, lng: 21.1091312 },
    { lat: 49.3458075, lng: 21.1091089 },
    { lat: 49.3460378, lng: 21.1086678 },
    { lat: 49.3462345, lng: 21.1085867 },
    { lat: 49.3463738, lng: 21.1084085 },
    { lat: 49.346464, lng: 21.1081665 },
    { lat: 49.3466532, lng: 21.1080655 },
    { lat: 49.3467291, lng: 21.1077624 },
    { lat: 49.3469972, lng: 21.1076259 },
    { lat: 49.346932, lng: 21.1072756 },
    { lat: 49.3469876, lng: 21.1070795 },
    { lat: 49.3471012, lng: 21.1069678 },
    { lat: 49.3472673, lng: 21.1066367 },
    { lat: 49.3473776, lng: 21.1062853 },
    { lat: 49.3476538, lng: 21.1060897 },
    { lat: 49.3477735, lng: 21.1058556 },
    { lat: 49.3478614, lng: 21.1055065 },
    { lat: 49.3484305, lng: 21.1048217 },
    { lat: 49.3485112, lng: 21.1044963 },
    { lat: 49.348749, lng: 21.1044335 },
    { lat: 49.3491828, lng: 21.1038879 },
    { lat: 49.3495111, lng: 21.1036989 },
    { lat: 49.3498753, lng: 21.1033682 },
    { lat: 49.3501681, lng: 21.1034128 },
    { lat: 49.3503372, lng: 21.1032139 },
    { lat: 49.3504691, lng: 21.10315 },
    { lat: 49.3507404, lng: 21.1032572 },
    { lat: 49.3508515, lng: 21.1031388 },
    { lat: 49.3510204, lng: 21.1031121 },
    { lat: 49.3511753, lng: 21.1032738 },
    { lat: 49.3514637, lng: 21.1030018 },
    { lat: 49.3516909, lng: 21.1032127 },
    { lat: 49.351838, lng: 21.1030941 },
    { lat: 49.3521697, lng: 21.1030925 },
    { lat: 49.3524128, lng: 21.1028762 },
    { lat: 49.3527014, lng: 21.102897 },
    { lat: 49.3530054, lng: 21.1025812 },
    { lat: 49.3532682, lng: 21.1026356 },
    { lat: 49.3534325, lng: 21.1023541 },
    { lat: 49.3536472, lng: 21.102455 },
    { lat: 49.3537276, lng: 21.1022924 },
    { lat: 49.3542266, lng: 21.1018466 },
    { lat: 49.3543258, lng: 21.1016453 },
    { lat: 49.3545177, lng: 21.1016289 },
    { lat: 49.3550404, lng: 21.1009394 },
    { lat: 49.3550109, lng: 21.100789 },
    { lat: 49.3554856, lng: 21.0994439 },
    { lat: 49.3556603, lng: 21.0990584 },
    { lat: 49.3557877, lng: 21.0989626 },
    { lat: 49.3560834, lng: 21.0984222 },
    { lat: 49.3562568, lng: 21.0982493 },
    { lat: 49.3565574, lng: 21.0972703 },
    { lat: 49.3569864, lng: 21.0966448 },
    { lat: 49.3574719, lng: 21.0960577 },
    { lat: 49.3577067, lng: 21.0959012 },
    { lat: 49.3582288, lng: 21.0960427 },
    { lat: 49.3587995, lng: 21.0956243 },
    { lat: 49.3589938, lng: 21.0954022 },
    { lat: 49.3592331, lng: 21.0952836 },
    { lat: 49.3595248, lng: 21.0948315 },
    { lat: 49.3595391, lng: 21.0947185 },
    { lat: 49.3598108, lng: 21.094239 },
    { lat: 49.3599794, lng: 21.0937737 },
    { lat: 49.3609945, lng: 21.0918365 },
    { lat: 49.3614692, lng: 21.0918678 },
    { lat: 49.3616847, lng: 21.0917802 },
    { lat: 49.3618104, lng: 21.0915567 },
    { lat: 49.3622237, lng: 21.0915706 },
    { lat: 49.3627139, lng: 21.0913108 },
    { lat: 49.363937, lng: 21.0904269 },
    { lat: 49.3651262, lng: 21.0901437 },
    { lat: 49.365137, lng: 21.090112 },
    { lat: 49.3652713, lng: 21.0897191 },
    { lat: 49.3655336, lng: 21.0888594 },
    { lat: 49.3656863, lng: 21.0874812 },
    { lat: 49.365617, lng: 21.0870772 },
    { lat: 49.3654533, lng: 21.086643 },
    { lat: 49.3651015, lng: 21.0860593 },
    { lat: 49.3645265, lng: 21.0872497 },
    { lat: 49.3641072, lng: 21.0888866 },
    { lat: 49.3635722, lng: 21.0897538 },
    { lat: 49.3633755, lng: 21.0898995 },
    { lat: 49.362681, lng: 21.0908071 },
    { lat: 49.362359, lng: 21.0910168 },
    { lat: 49.3618062, lng: 21.0915368 },
    { lat: 49.3614301, lng: 21.090783 },
    { lat: 49.3612357, lng: 21.090121 },
    { lat: 49.3612422, lng: 21.0898678 },
    { lat: 49.3611809, lng: 21.0894998 },
    { lat: 49.36125, lng: 21.0893004 },
    { lat: 49.3611911, lng: 21.0883038 },
    { lat: 49.3612452, lng: 21.0877098 },
    { lat: 49.3610294, lng: 21.0862029 },
    { lat: 49.3610379, lng: 21.086012 },
    { lat: 49.3603562, lng: 21.0855905 },
    { lat: 49.360179, lng: 21.0856073 },
    { lat: 49.3590507, lng: 21.086116 },
    { lat: 49.3589671, lng: 21.0862457 },
    { lat: 49.3588934, lng: 21.0862271 },
    { lat: 49.3586446, lng: 21.0864316 },
    { lat: 49.3584104, lng: 21.0864986 },
    { lat: 49.3581605, lng: 21.0866765 },
    { lat: 49.3579417, lng: 21.0866611 },
    { lat: 49.3576907, lng: 21.0867983 },
    { lat: 49.3576425, lng: 21.0869059 },
    { lat: 49.3574632, lng: 21.0869984 },
    { lat: 49.3574432, lng: 21.0870807 },
    { lat: 49.3572187, lng: 21.0871687 },
    { lat: 49.3571037, lng: 21.0870246 },
    { lat: 49.3570424, lng: 21.0871793 },
    { lat: 49.3570625, lng: 21.0874251 },
    { lat: 49.3567594, lng: 21.0875456 },
    { lat: 49.3566535, lng: 21.0879654 },
    { lat: 49.3565368, lng: 21.0879467 },
    { lat: 49.3563425, lng: 21.0883432 },
    { lat: 49.356183, lng: 21.0884966 },
    { lat: 49.3561375, lng: 21.0886209 },
    { lat: 49.3560028, lng: 21.0886649 },
    { lat: 49.3559284, lng: 21.0888362 },
    { lat: 49.3557963, lng: 21.0889248 },
    { lat: 49.3557521, lng: 21.0890609 },
    { lat: 49.3554369, lng: 21.0892336 },
    { lat: 49.355328, lng: 21.0894118 },
    { lat: 49.3551224, lng: 21.0892878 },
    { lat: 49.3550059, lng: 21.0893377 },
    { lat: 49.3548092, lng: 21.0892022 },
    { lat: 49.3547052, lng: 21.08901 },
    { lat: 49.354543, lng: 21.0890056 },
    { lat: 49.3543667, lng: 21.0888433 },
    { lat: 49.3542899, lng: 21.0884466 },
    { lat: 49.354161, lng: 21.0882819 },
    { lat: 49.3541944, lng: 21.0881314 },
    { lat: 49.3537761, lng: 21.0871921 },
    { lat: 49.3535282, lng: 21.0870735 },
    { lat: 49.3534703, lng: 21.0868847 },
    { lat: 49.3533728, lng: 21.0867913 },
    { lat: 49.3533167, lng: 21.0867919 },
    { lat: 49.3531935, lng: 21.0869516 },
    { lat: 49.3530265, lng: 21.0866905 },
    { lat: 49.352902, lng: 21.0865952 },
    { lat: 49.3528295, lng: 21.0866208 },
    { lat: 49.3527685, lng: 21.0868239 },
    { lat: 49.3526606, lng: 21.0868984 },
    { lat: 49.3521257, lng: 21.0866388 },
    { lat: 49.3520811, lng: 21.086827 },
    { lat: 49.3516662, lng: 21.0868682 },
    { lat: 49.3515648, lng: 21.0871483 },
    { lat: 49.3514931, lng: 21.0871915 },
    { lat: 49.3513623, lng: 21.087149 },
    { lat: 49.3512326, lng: 21.0872099 },
    { lat: 49.3510264, lng: 21.0870269 },
    { lat: 49.3509062, lng: 21.0874939 },
    { lat: 49.3506584, lng: 21.0877312 },
    { lat: 49.3504592, lng: 21.0877269 },
    { lat: 49.3502616, lng: 21.0880568 },
    { lat: 49.3501579, lng: 21.0883414 },
    { lat: 49.3499641, lng: 21.0884364 },
    { lat: 49.3498223, lng: 21.0886477 },
    { lat: 49.3496776, lng: 21.0885928 },
    { lat: 49.3495334, lng: 21.0889721 },
    { lat: 49.3492574, lng: 21.0891025 },
    { lat: 49.3492035, lng: 21.0893848 },
    { lat: 49.3489473, lng: 21.0896472 },
    { lat: 49.3487876, lng: 21.0900353 },
    { lat: 49.348653, lng: 21.0902287 },
    { lat: 49.348536, lng: 21.0902645 },
    { lat: 49.348389, lng: 21.0902019 },
    { lat: 49.3478359, lng: 21.0909975 },
    { lat: 49.3476224, lng: 21.0914282 },
    { lat: 49.3473604, lng: 21.0915088 },
    { lat: 49.3473021, lng: 21.0915803 },
    { lat: 49.3472946, lng: 21.0917006 },
    { lat: 49.346999, lng: 21.0919241 },
    { lat: 49.3467773, lng: 21.092206 },
    { lat: 49.3466074, lng: 21.0922075 },
    { lat: 49.3465911, lng: 21.0922953 },
    { lat: 49.3459683, lng: 21.0929825 },
    { lat: 49.3448709, lng: 21.0936455 },
    { lat: 49.3446763, lng: 21.0939625 },
    { lat: 49.3428919, lng: 21.0954162 },
    { lat: 49.34279, lng: 21.0956447 },
    { lat: 49.3424533, lng: 21.0960964 },
    { lat: 49.3417172, lng: 21.0972967 },
    { lat: 49.3413119, lng: 21.0975991 },
    { lat: 49.3412892, lng: 21.0978137 },
    { lat: 49.3412066, lng: 21.0978332 },
    { lat: 49.3411799, lng: 21.0979795 },
    { lat: 49.3410422, lng: 21.0981125 },
    { lat: 49.3409874, lng: 21.0983328 },
    { lat: 49.3407293, lng: 21.0986021 },
    { lat: 49.3404885, lng: 21.0984223 },
    { lat: 49.3399079, lng: 21.0982127 },
    { lat: 49.3397368, lng: 21.0976073 },
    { lat: 49.3394694, lng: 21.0975558 },
    { lat: 49.3390306, lng: 21.0972378 },
    { lat: 49.3385732, lng: 21.0966192 },
    { lat: 49.3382503, lng: 21.0965866 },
    { lat: 49.3380224, lng: 21.0967808 },
    { lat: 49.337772, lng: 21.0958072 },
    { lat: 49.3375551, lng: 21.0951782 },
    { lat: 49.3373994, lng: 21.0953468 },
    { lat: 49.3368602, lng: 21.0954069 },
    { lat: 49.3366986, lng: 21.095354 },
    { lat: 49.3364544, lng: 21.0954227 },
    { lat: 49.3363468, lng: 21.0953308 },
    { lat: 49.336301, lng: 21.0953747 },
    { lat: 49.3361431, lng: 21.0952216 },
    { lat: 49.3361065, lng: 21.0950637 },
    { lat: 49.3355969, lng: 21.0943621 },
    { lat: 49.3351211, lng: 21.0935555 },
    { lat: 49.3345222, lng: 21.0928421 },
    { lat: 49.3342488, lng: 21.0926684 },
    { lat: 49.3329055, lng: 21.091266 },
    { lat: 49.3322223, lng: 21.0908055 },
    { lat: 49.3315023, lng: 21.0896773 },
    { lat: 49.3304829, lng: 21.0884882 },
    { lat: 49.3296949, lng: 21.0879508 },
    { lat: 49.3281955, lng: 21.0865946 },
    { lat: 49.3276165, lng: 21.0862606 },
    { lat: 49.327355, lng: 21.0860309 },
    { lat: 49.3268096, lng: 21.0851607 },
    { lat: 49.3264107, lng: 21.084302 },
    { lat: 49.3265192, lng: 21.0841031 },
    { lat: 49.3264515, lng: 21.083571 },
    { lat: 49.3261323, lng: 21.0827774 },
    { lat: 49.3259415, lng: 21.0824757 },
    { lat: 49.3257593, lng: 21.0826895 },
    { lat: 49.3252726, lng: 21.0823898 },
    { lat: 49.3252054, lng: 21.0821881 },
    { lat: 49.32491, lng: 21.0822737 },
    { lat: 49.324044, lng: 21.0812358 },
    { lat: 49.3240294, lng: 21.0808833 },
    { lat: 49.3233292, lng: 21.0803503 },
    { lat: 49.3231984, lng: 21.0804471 },
    { lat: 49.3230376, lng: 21.0804487 },
    { lat: 49.3226095, lng: 21.0801885 },
    { lat: 49.3220952, lng: 21.0797672 },
    { lat: 49.3216516, lng: 21.0794036 },
    { lat: 49.3214034, lng: 21.0799817 },
    { lat: 49.3210828, lng: 21.0803751 },
    { lat: 49.3208016, lng: 21.0809762 },
    { lat: 49.3203896, lng: 21.0820918 },
    { lat: 49.3200682, lng: 21.082601 },
    { lat: 49.3192486, lng: 21.0836042 },
    { lat: 49.3190129, lng: 21.0836958 },
    { lat: 49.3185438, lng: 21.0840534 },
    { lat: 49.3179832, lng: 21.0848532 },
    { lat: 49.317843, lng: 21.0849755 },
    { lat: 49.3175248, lng: 21.0850888 },
    { lat: 49.317125, lng: 21.0852547 },
    { lat: 49.3173897, lng: 21.0859985 },
    { lat: 49.3177975, lng: 21.0867904 },
    { lat: 49.3181397, lng: 21.0880604 },
    { lat: 49.3180752, lng: 21.0897382 },
    { lat: 49.31755, lng: 21.0919672 },
    { lat: 49.3172612, lng: 21.0940959 },
    { lat: 49.3171798, lng: 21.0951426 },
    { lat: 49.3171011, lng: 21.0966598 },
    { lat: 49.317108, lng: 21.097513 },
    { lat: 49.3172318, lng: 21.0989271 },
    { lat: 49.3173415, lng: 21.0995065 },
    { lat: 49.317347, lng: 21.0995375 },
    { lat: 49.3177195, lng: 21.0998906 },
    { lat: 49.3180296, lng: 21.1003739 },
    { lat: 49.3182185, lng: 21.1007573 },
    { lat: 49.3182936, lng: 21.1010666 },
    { lat: 49.3184098, lng: 21.1020286 },
    { lat: 49.318371, lng: 21.1029722 },
    { lat: 49.3184307, lng: 21.1035344 },
    { lat: 49.3190078, lng: 21.105155 },
    { lat: 49.3191506, lng: 21.1064623 },
    { lat: 49.3191913, lng: 21.1081655 },
    { lat: 49.3187452, lng: 21.1120477 },
    { lat: 49.3178457, lng: 21.1150826 },
    { lat: 49.318091, lng: 21.1159027 },
    { lat: 49.3180019, lng: 21.1167053 },
    { lat: 49.3173374, lng: 21.1202 },
    { lat: 49.317421, lng: 21.1200347 },
    { lat: 49.3174946, lng: 21.1202301 },
    { lat: 49.3169865, lng: 21.1225232 },
    { lat: 49.3169344, lng: 21.1226114 },
    { lat: 49.3172812, lng: 21.1228335 },
    { lat: 49.317289, lng: 21.123014 },
    { lat: 49.3170988, lng: 21.1240355 },
    { lat: 49.3171077, lng: 21.1259962 },
    { lat: 49.3170511, lng: 21.1264787 },
    { lat: 49.3170997, lng: 21.1265909 },
    { lat: 49.3169685, lng: 21.1273941 },
    { lat: 49.3167764, lng: 21.1274696 },
    { lat: 49.316854, lng: 21.1274711 },
    { lat: 49.3167523, lng: 21.1284914 },
    { lat: 49.3167918, lng: 21.129062 },
    { lat: 49.3170091, lng: 21.1293864 },
    { lat: 49.3170341, lng: 21.1296496 },
    { lat: 49.3174268, lng: 21.1309663 },
    { lat: 49.3177135, lng: 21.131383 },
    { lat: 49.3181575, lng: 21.1316871 },
    { lat: 49.318622, lng: 21.1327114 },
    { lat: 49.318847, lng: 21.1329956 },
    { lat: 49.3188614, lng: 21.1334905 },
    { lat: 49.3190137, lng: 21.1336466 },
    { lat: 49.3196999, lng: 21.1355603 },
    { lat: 49.3199355, lng: 21.136001 },
    { lat: 49.3200625, lng: 21.1358563 },
    { lat: 49.3204133, lng: 21.1360281 },
    { lat: 49.3206431, lng: 21.1374291 },
    { lat: 49.3205921, lng: 21.1374869 },
    { lat: 49.3204282, lng: 21.1388535 },
    { lat: 49.321546, lng: 21.1355473 },
    { lat: 49.3218869, lng: 21.1352617 },
    { lat: 49.3219698, lng: 21.1354666 },
    { lat: 49.3221929, lng: 21.1353344 },
    { lat: 49.322412, lng: 21.1350354 },
    { lat: 49.322521, lng: 21.1352109 },
  ],
  Becherov: [
    { lat: 49.4146802, lng: 21.35471 },
    { lat: 49.4149369, lng: 21.354032 },
    { lat: 49.415758, lng: 21.3534181 },
    { lat: 49.4171468, lng: 21.3516111 },
    { lat: 49.4177459, lng: 21.3511376 },
    { lat: 49.4188814, lng: 21.3498219 },
    { lat: 49.4194056, lng: 21.3494027 },
    { lat: 49.4210325, lng: 21.347894 },
    { lat: 49.421639, lng: 21.3470549 },
    { lat: 49.4221977, lng: 21.3460611 },
    { lat: 49.4224316, lng: 21.3458265 },
    { lat: 49.4235727, lng: 21.345152 },
    { lat: 49.4257939, lng: 21.3416828 },
    { lat: 49.426089, lng: 21.3411344 },
    { lat: 49.4266363, lng: 21.3401842 },
    { lat: 49.4272022, lng: 21.3390656 },
    { lat: 49.4273515, lng: 21.3387881 },
    { lat: 49.4277981, lng: 21.3377673 },
    { lat: 49.4283909, lng: 21.3378047 },
    { lat: 49.4289274, lng: 21.3368509 },
    { lat: 49.4293387, lng: 21.3364216 },
    { lat: 49.4304524, lng: 21.3353974 },
    { lat: 49.4312595, lng: 21.3341977 },
    { lat: 49.4319992, lng: 21.332969 },
    { lat: 49.4325459, lng: 21.3315766 },
    { lat: 49.4333479, lng: 21.3313068 },
    { lat: 49.435328, lng: 21.3286275 },
    { lat: 49.4364498, lng: 21.32663 },
    { lat: 49.4366311, lng: 21.325959 },
    { lat: 49.4369696, lng: 21.3250474 },
    { lat: 49.4377121, lng: 21.3242155 },
    { lat: 49.4379212, lng: 21.3235772 },
    { lat: 49.438002, lng: 21.3231565 },
    { lat: 49.438305, lng: 21.322486 },
    { lat: 49.4392766, lng: 21.3226628 },
    { lat: 49.4397562, lng: 21.3221827 },
    { lat: 49.4399475, lng: 21.3218489 },
    { lat: 49.4400247, lng: 21.3219064 },
    { lat: 49.4406898, lng: 21.3219085 },
    { lat: 49.4408311, lng: 21.3217051 },
    { lat: 49.4410544, lng: 21.3216518 },
    { lat: 49.4415623, lng: 21.3219328 },
    { lat: 49.4423341, lng: 21.3233664 },
    { lat: 49.4426446, lng: 21.3233283 },
    { lat: 49.4427479, lng: 21.3231319 },
    { lat: 49.4426655, lng: 21.3229685 },
    { lat: 49.4429172, lng: 21.3224678 },
    { lat: 49.4432986, lng: 21.3226193 },
    { lat: 49.4438308, lng: 21.3226621 },
    { lat: 49.4440787, lng: 21.3230916 },
    { lat: 49.4442142, lng: 21.3235955 },
    { lat: 49.4443594, lng: 21.323295 },
    { lat: 49.4446645, lng: 21.3231542 },
    { lat: 49.4450278, lng: 21.3228839 },
    { lat: 49.4455164, lng: 21.3228576 },
    { lat: 49.4460306, lng: 21.3212797 },
    { lat: 49.4460712, lng: 21.3209814 },
    { lat: 49.4463134, lng: 21.3205041 },
    { lat: 49.4464431, lng: 21.3200626 },
    { lat: 49.4470829, lng: 21.3192568 },
    { lat: 49.4479065, lng: 21.3165123 },
    { lat: 49.4478985, lng: 21.3162136 },
    { lat: 49.4482473, lng: 21.3151167 },
    { lat: 49.4486951, lng: 21.3142989 },
    { lat: 49.4488426, lng: 21.3136585 },
    { lat: 49.4492488, lng: 21.3131266 },
    { lat: 49.449097, lng: 21.3128981 },
    { lat: 49.4491362, lng: 21.3128211 },
    { lat: 49.4491671, lng: 21.3127637 },
    { lat: 49.4493783, lng: 21.3118581 },
    { lat: 49.4492359, lng: 21.3116869 },
    { lat: 49.4490912, lng: 21.3111387 },
    { lat: 49.4491052, lng: 21.3107279 },
    { lat: 49.448898, lng: 21.309871 },
    { lat: 49.4488977, lng: 21.3098652 },
    { lat: 49.4486469, lng: 21.3087445 },
    { lat: 49.4484886, lng: 21.3082554 },
    { lat: 49.4482921, lng: 21.3078551 },
    { lat: 49.4479956, lng: 21.3071819 },
    { lat: 49.4484024, lng: 21.306836 },
    { lat: 49.4485417, lng: 21.3064902 },
    { lat: 49.4486904, lng: 21.3056696 },
    { lat: 49.448691, lng: 21.305662 },
    { lat: 49.448762, lng: 21.305499 },
    { lat: 49.4489928, lng: 21.3049619 },
    { lat: 49.448994, lng: 21.304953 },
    { lat: 49.449064, lng: 21.304671 },
    { lat: 49.4491282, lng: 21.3044217 },
    { lat: 49.449186, lng: 21.304202 },
    { lat: 49.4492996, lng: 21.3037859 },
    { lat: 49.4500721, lng: 21.3027477 },
    { lat: 49.4505584, lng: 21.3018136 },
    { lat: 49.4506455, lng: 21.3016875 },
    { lat: 49.4505873, lng: 21.3015946 },
    { lat: 49.4507299, lng: 21.3013789 },
    { lat: 49.4507893, lng: 21.301471 },
    { lat: 49.450888, lng: 21.3013662 },
    { lat: 49.4508932, lng: 21.3013591 },
    { lat: 49.4512162, lng: 21.3009192 },
    { lat: 49.4512801, lng: 21.3004818 },
    { lat: 49.4514493, lng: 21.299822 },
    { lat: 49.451504, lng: 21.299673 },
    { lat: 49.4515923, lng: 21.2994393 },
    { lat: 49.4518682, lng: 21.2988912 },
    { lat: 49.4518191, lng: 21.2986353 },
    { lat: 49.4519504, lng: 21.2981505 },
    { lat: 49.4519094, lng: 21.2980151 },
    { lat: 49.4523691, lng: 21.2968388 },
    { lat: 49.4523921, lng: 21.2963807 },
    { lat: 49.4529176, lng: 21.2949497 },
    { lat: 49.453177, lng: 21.2947983 },
    { lat: 49.4536624, lng: 21.2938071 },
    { lat: 49.4539966, lng: 21.2930511 },
    { lat: 49.4542283, lng: 21.29264 },
    { lat: 49.4543531, lng: 21.2927521 },
    { lat: 49.4544925, lng: 21.2924091 },
    { lat: 49.454414, lng: 21.2921809 },
    { lat: 49.4545803, lng: 21.2917605 },
    { lat: 49.4545645, lng: 21.2915175 },
    { lat: 49.4548889, lng: 21.2905877 },
    { lat: 49.4551283, lng: 21.2902559 },
    { lat: 49.4557544, lng: 21.2893876 },
    { lat: 49.455766, lng: 21.289371 },
    { lat: 49.455802, lng: 21.289317 },
    { lat: 49.456158, lng: 21.288778 },
    { lat: 49.4562678, lng: 21.2886124 },
    { lat: 49.456358, lng: 21.288474 },
    { lat: 49.4563643, lng: 21.2884627 },
    { lat: 49.4569511, lng: 21.2873745 },
    { lat: 49.457013, lng: 21.287203 },
    { lat: 49.4571734, lng: 21.2867603 },
    { lat: 49.4575784, lng: 21.2860238 },
    { lat: 49.458123, lng: 21.2847625 },
    { lat: 49.4584689, lng: 21.2837271 },
    { lat: 49.458501, lng: 21.283631 },
    { lat: 49.4585673, lng: 21.2834236 },
    { lat: 49.458569, lng: 21.283414 },
    { lat: 49.4587401, lng: 21.2826662 },
    { lat: 49.4588, lng: 21.28223 },
    { lat: 49.4589355, lng: 21.2812562 },
    { lat: 49.4588684, lng: 21.2811331 },
    { lat: 49.4590533, lng: 21.2802847 },
    { lat: 49.4593637, lng: 21.2800292 },
    { lat: 49.4596322, lng: 21.2797816 },
    { lat: 49.4599976, lng: 21.2795375 },
    { lat: 49.4601118, lng: 21.2794607 },
    { lat: 49.4602889, lng: 21.2790807 },
    { lat: 49.4605854, lng: 21.2784436 },
    { lat: 49.4608196, lng: 21.2777514 },
    { lat: 49.4609398, lng: 21.2775246 },
    { lat: 49.4607364, lng: 21.2766055 },
    { lat: 49.4606755, lng: 21.2759954 },
    { lat: 49.460517, lng: 21.2750345 },
    { lat: 49.460515, lng: 21.275029 },
    { lat: 49.4604092, lng: 21.2744405 },
    { lat: 49.4601991, lng: 21.2738886 },
    { lat: 49.460118, lng: 21.273483 },
    { lat: 49.4601057, lng: 21.27342 },
    { lat: 49.460098, lng: 21.273387 },
    { lat: 49.4599531, lng: 21.2727041 },
    { lat: 49.4597692, lng: 21.2720306 },
    { lat: 49.4595115, lng: 21.2715798 },
    { lat: 49.4593344, lng: 21.2711496 },
    { lat: 49.4591822, lng: 21.2709075 },
    { lat: 49.4590773, lng: 21.2705571 },
    { lat: 49.4584141, lng: 21.269345 },
    { lat: 49.458409, lng: 21.269339 },
    { lat: 49.457915, lng: 21.268558 },
    { lat: 49.4579131, lng: 21.2685515 },
    { lat: 49.4575423, lng: 21.2678143 },
    { lat: 49.4571872, lng: 21.2673438 },
    { lat: 49.456405, lng: 21.267372 },
    { lat: 49.4564126, lng: 21.2673426 },
    { lat: 49.45606, lng: 21.2673759 },
    { lat: 49.456042, lng: 21.26737 },
    { lat: 49.4555264, lng: 21.2671525 },
    { lat: 49.4554224, lng: 21.2671102 },
    { lat: 49.454954, lng: 21.267042 },
    { lat: 49.4546241, lng: 21.2669908 },
    { lat: 49.4543401, lng: 21.266996 },
    { lat: 49.4535734, lng: 21.2665089 },
    { lat: 49.452902, lng: 21.266324 },
    { lat: 49.4524361, lng: 21.2661132 },
    { lat: 49.4520084, lng: 21.265805 },
    { lat: 49.4514383, lng: 21.2660399 },
    { lat: 49.4512811, lng: 21.2659249 },
    { lat: 49.4510743, lng: 21.2659642 },
    { lat: 49.4504808, lng: 21.2662758 },
    { lat: 49.4500321, lng: 21.2662858 },
    { lat: 49.4498424, lng: 21.2662229 },
    { lat: 49.4495471, lng: 21.2661892 },
    { lat: 49.4491122, lng: 21.2663091 },
    { lat: 49.4486186, lng: 21.2661282 },
    { lat: 49.44856, lng: 21.266101 },
    { lat: 49.447416, lng: 21.2655448 },
    { lat: 49.4469846, lng: 21.2659192 },
    { lat: 49.4465929, lng: 21.2669355 },
    { lat: 49.4454991, lng: 21.2687108 },
    { lat: 49.4449982, lng: 21.2697423 },
    { lat: 49.4445867, lng: 21.2708282 },
    { lat: 49.4440461, lng: 21.272514 },
    { lat: 49.4436898, lng: 21.2733844 },
    { lat: 49.4430849, lng: 21.2744691 },
    { lat: 49.4428656, lng: 21.2749967 },
    { lat: 49.4415778, lng: 21.2773162 },
    { lat: 49.4405989, lng: 21.2785757 },
    { lat: 49.4397679, lng: 21.2795224 },
    { lat: 49.4385657, lng: 21.280353 },
    { lat: 49.4370567, lng: 21.2806461 },
    { lat: 49.4360973, lng: 21.2810227 },
    { lat: 49.4356893, lng: 21.2813683 },
    { lat: 49.434694, lng: 21.2826136 },
    { lat: 49.4341794, lng: 21.2834592 },
    { lat: 49.4338332, lng: 21.2843202 },
    { lat: 49.4315982, lng: 21.2871003 },
    { lat: 49.4312191, lng: 21.2873278 },
    { lat: 49.4310744, lng: 21.2875235 },
    { lat: 49.4307703, lng: 21.2875874 },
    { lat: 49.4303742, lng: 21.2881082 },
    { lat: 49.4301584, lng: 21.288224 },
    { lat: 49.4300243, lng: 21.288484 },
    { lat: 49.4296662, lng: 21.2886568 },
    { lat: 49.428098, lng: 21.2889636 },
    { lat: 49.4277984, lng: 21.2887231 },
    { lat: 49.427543, lng: 21.2886502 },
    { lat: 49.4272881, lng: 21.2882952 },
    { lat: 49.4271222, lng: 21.2876412 },
    { lat: 49.426889, lng: 21.2874857 },
    { lat: 49.4262452, lng: 21.2873232 },
    { lat: 49.4252112, lng: 21.2874724 },
    { lat: 49.4247677, lng: 21.2872043 },
    { lat: 49.4226003, lng: 21.2849843 },
    { lat: 49.42204, lng: 21.284058 },
    { lat: 49.4211961, lng: 21.2828216 },
    { lat: 49.4208782, lng: 21.2823595 },
    { lat: 49.4205692, lng: 21.2838606 },
    { lat: 49.4207277, lng: 21.2840406 },
    { lat: 49.4204902, lng: 21.2849277 },
    { lat: 49.4194062, lng: 21.2874497 },
    { lat: 49.4185854, lng: 21.2898551 },
    { lat: 49.4174266, lng: 21.2916028 },
    { lat: 49.415035, lng: 21.2957254 },
    { lat: 49.4133527, lng: 21.2984754 },
    { lat: 49.4121301, lng: 21.3003576 },
    { lat: 49.4114437, lng: 21.3015952 },
    { lat: 49.4108924, lng: 21.30309 },
    { lat: 49.4105917, lng: 21.3035647 },
    { lat: 49.4103654, lng: 21.3058414 },
    { lat: 49.4101124, lng: 21.3077544 },
    { lat: 49.408543, lng: 21.3142489 },
    { lat: 49.4079231, lng: 21.3157601 },
    { lat: 49.4077073, lng: 21.3166312 },
    { lat: 49.4068029, lng: 21.3194872 },
    { lat: 49.4066899, lng: 21.3200776 },
    { lat: 49.4066476, lng: 21.3218144 },
    { lat: 49.4067388, lng: 21.3231787 },
    { lat: 49.406746, lng: 21.3245872 },
    { lat: 49.4068297, lng: 21.3263325 },
    { lat: 49.4068268, lng: 21.3286836 },
    { lat: 49.4067683, lng: 21.3303424 },
    { lat: 49.4068022, lng: 21.331598 },
    { lat: 49.4068715, lng: 21.3322951 },
    { lat: 49.4071042, lng: 21.3331972 },
    { lat: 49.4071996, lng: 21.333907 },
    { lat: 49.4071818, lng: 21.3345911 },
    { lat: 49.4075369, lng: 21.3360898 },
    { lat: 49.4075027, lng: 21.3362605 },
    { lat: 49.4082415, lng: 21.3371634 },
    { lat: 49.4082965, lng: 21.3380599 },
    { lat: 49.4085079, lng: 21.338358 },
    { lat: 49.4082355, lng: 21.3385454 },
    { lat: 49.4076931, lng: 21.339182 },
    { lat: 49.408411, lng: 21.3416508 },
    { lat: 49.4088173, lng: 21.3419931 },
    { lat: 49.4088796, lng: 21.3421611 },
    { lat: 49.409392, lng: 21.3425538 },
    { lat: 49.4095211, lng: 21.3424718 },
    { lat: 49.4098657, lng: 21.3425186 },
    { lat: 49.4099097, lng: 21.3430542 },
    { lat: 49.4105377, lng: 21.3445358 },
    { lat: 49.4106044, lng: 21.3473956 },
    { lat: 49.4107304, lng: 21.347996 },
    { lat: 49.4107613, lng: 21.348736 },
    { lat: 49.4110319, lng: 21.3492891 },
    { lat: 49.4113101, lng: 21.3496386 },
    { lat: 49.4116979, lng: 21.3510702 },
    { lat: 49.4125289, lng: 21.352161 },
    { lat: 49.4130167, lng: 21.3523294 },
    { lat: 49.414318, lng: 21.3540303 },
    { lat: 49.4146802, lng: 21.35471 },
  ],
  Petrová: [
    { lat: 49.3915752, lng: 21.1504288 },
    { lat: 49.3916219, lng: 21.1504513 },
    { lat: 49.3919098, lng: 21.1505887 },
    { lat: 49.3930598, lng: 21.1505904 },
    { lat: 49.3938824, lng: 21.1506631 },
    { lat: 49.3960775, lng: 21.1505871 },
    { lat: 49.3964956, lng: 21.1497091 },
    { lat: 49.3967826, lng: 21.1481035 },
    { lat: 49.3971024, lng: 21.1467473 },
    { lat: 49.3974825, lng: 21.1429824 },
    { lat: 49.3974091, lng: 21.1425055 },
    { lat: 49.3978435, lng: 21.1416472 },
    { lat: 49.3979433, lng: 21.1416045 },
    { lat: 49.3980596, lng: 21.1412238 },
    { lat: 49.3985606, lng: 21.1400513 },
    { lat: 49.3986413, lng: 21.1395067 },
    { lat: 49.3984061, lng: 21.1384323 },
    { lat: 49.3986538, lng: 21.1368638 },
    { lat: 49.3990379, lng: 21.1358368 },
    { lat: 49.3995007, lng: 21.1342108 },
    { lat: 49.3995413, lng: 21.1334957 },
    { lat: 49.3999834, lng: 21.1316714 },
    { lat: 49.3999554, lng: 21.1309618 },
    { lat: 49.4000459, lng: 21.1301967 },
    { lat: 49.4004117, lng: 21.128451 },
    { lat: 49.4007705, lng: 21.1256273 },
    { lat: 49.4013097, lng: 21.1236779 },
    { lat: 49.4030277, lng: 21.1225702 },
    { lat: 49.4036932, lng: 21.1209852 },
    { lat: 49.4050934, lng: 21.1197872 },
    { lat: 49.4054747, lng: 21.1192888 },
    { lat: 49.40573, lng: 21.1175741 },
    { lat: 49.4061805, lng: 21.1169505 },
    { lat: 49.4069048, lng: 21.1160873 },
    { lat: 49.4081196, lng: 21.1154329 },
    { lat: 49.4096207, lng: 21.1144739 },
    { lat: 49.4104572, lng: 21.1143466 },
    { lat: 49.4115724, lng: 21.1137863 },
    { lat: 49.4123345, lng: 21.1130863 },
    { lat: 49.4133739, lng: 21.1117282 },
    { lat: 49.4145208, lng: 21.110099 },
    { lat: 49.4159448, lng: 21.1083091 },
    { lat: 49.4172008, lng: 21.1068317 },
    { lat: 49.4177162, lng: 21.1059071 },
    { lat: 49.4186454, lng: 21.104554 },
    { lat: 49.4202456, lng: 21.1024377 },
    { lat: 49.4215125, lng: 21.1017988 },
    { lat: 49.422524, lng: 21.1018291 },
    { lat: 49.4229336, lng: 21.1016872 },
    { lat: 49.423887, lng: 21.1037914 },
    { lat: 49.426154, lng: 21.103764 },
    { lat: 49.426485, lng: 21.102473 },
    { lat: 49.4265042, lng: 21.1024287 },
    { lat: 49.426753, lng: 21.1018733 },
    { lat: 49.426757, lng: 21.101864 },
    { lat: 49.427145, lng: 21.101221 },
    { lat: 49.427654, lng: 21.100365 },
    { lat: 49.4276592, lng: 21.1003577 },
    { lat: 49.4282719, lng: 21.0995462 },
    { lat: 49.42856, lng: 21.098986 },
    { lat: 49.42889, lng: 21.0983477 },
    { lat: 49.429177, lng: 21.0974841 },
    { lat: 49.4293271, lng: 21.0968694 },
    { lat: 49.429488, lng: 21.0963873 },
    { lat: 49.4294292, lng: 21.0958382 },
    { lat: 49.4292939, lng: 21.0950621 },
    { lat: 49.429292, lng: 21.095054 },
    { lat: 49.4291114, lng: 21.0941537 },
    { lat: 49.4290169, lng: 21.0934966 },
    { lat: 49.4287303, lng: 21.0924391 },
    { lat: 49.428726, lng: 21.092426 },
    { lat: 49.428533, lng: 21.0916758 },
    { lat: 49.428377, lng: 21.0909471 },
    { lat: 49.4279471, lng: 21.090172 },
    { lat: 49.4277559, lng: 21.0890677 },
    { lat: 49.427618, lng: 21.0883546 },
    { lat: 49.4274783, lng: 21.087698 },
    { lat: 49.4273192, lng: 21.0871451 },
    { lat: 49.427045, lng: 21.0866209 },
    { lat: 49.4265884, lng: 21.085651 },
    { lat: 49.4263259, lng: 21.0845462 },
    { lat: 49.426322, lng: 21.08453 },
    { lat: 49.4261262, lng: 21.0836433 },
    { lat: 49.426125, lng: 21.083636 },
    { lat: 49.4261202, lng: 21.0836252 },
    { lat: 49.425863, lng: 21.083181 },
    { lat: 49.4257939, lng: 21.0830625 },
    { lat: 49.425569, lng: 21.082693 },
    { lat: 49.4250173, lng: 21.0817845 },
    { lat: 49.425252, lng: 21.080626 },
    { lat: 49.4254367, lng: 21.0797036 },
    { lat: 49.425438, lng: 21.079689 },
    { lat: 49.4255511, lng: 21.0785402 },
    { lat: 49.4254546, lng: 21.0779064 },
    { lat: 49.4252984, lng: 21.0770275 },
    { lat: 49.4254133, lng: 21.0765466 },
    { lat: 49.4255563, lng: 21.0759834 },
    { lat: 49.4254633, lng: 21.0749101 },
    { lat: 49.425394, lng: 21.0747424 },
    { lat: 49.425117, lng: 21.0736802 },
    { lat: 49.425041, lng: 21.073177 },
    { lat: 49.4249824, lng: 21.0732106 },
    { lat: 49.4246291, lng: 21.0740541 },
    { lat: 49.4245037, lng: 21.0746683 },
    { lat: 49.4239705, lng: 21.075903 },
    { lat: 49.4234919, lng: 21.0764212 },
    { lat: 49.4213479, lng: 21.0775727 },
    { lat: 49.4210407, lng: 21.0778564 },
    { lat: 49.4208497, lng: 21.0782459 },
    { lat: 49.420652, lng: 21.0799447 },
    { lat: 49.4192763, lng: 21.0816929 },
    { lat: 49.4184585, lng: 21.0833149 },
    { lat: 49.4164695, lng: 21.0844562 },
    { lat: 49.4152849, lng: 21.0852375 },
    { lat: 49.414702, lng: 21.0855552 },
    { lat: 49.414619, lng: 21.0861982 },
    { lat: 49.4134051, lng: 21.0881485 },
    { lat: 49.4120441, lng: 21.0891153 },
    { lat: 49.4108853, lng: 21.0896502 },
    { lat: 49.4105785, lng: 21.0897328 },
    { lat: 49.4098653, lng: 21.0907984 },
    { lat: 49.4095725, lng: 21.0907917 },
    { lat: 49.4095697, lng: 21.0909126 },
    { lat: 49.4086777, lng: 21.0917701 },
    { lat: 49.4086849, lng: 21.09221 },
    { lat: 49.408545, lng: 21.0921272 },
    { lat: 49.4083133, lng: 21.0922049 },
    { lat: 49.4077219, lng: 21.0919366 },
    { lat: 49.4074382, lng: 21.092029 },
    { lat: 49.4071601, lng: 21.0923447 },
    { lat: 49.4068863, lng: 21.0928335 },
    { lat: 49.4061302, lng: 21.0932583 },
    { lat: 49.4058022, lng: 21.0932698 },
    { lat: 49.4058644, lng: 21.0933215 },
    { lat: 49.4056922, lng: 21.0934977 },
    { lat: 49.4055199, lng: 21.0934904 },
    { lat: 49.4052549, lng: 21.0932255 },
    { lat: 49.4051539, lng: 21.093409 },
    { lat: 49.4037256, lng: 21.0936795 },
    { lat: 49.4036344, lng: 21.0955529 },
    { lat: 49.4029159, lng: 21.0954639 },
    { lat: 49.4004954, lng: 21.0964075 },
    { lat: 49.4000269, lng: 21.0959403 },
    { lat: 49.399008, lng: 21.0956364 },
    { lat: 49.3990065, lng: 21.095729 },
    { lat: 49.398454, lng: 21.0969354 },
    { lat: 49.3983637, lng: 21.0973125 },
    { lat: 49.3981351, lng: 21.0971305 },
    { lat: 49.3977931, lng: 21.0969891 },
    { lat: 49.397655, lng: 21.0962946 },
    { lat: 49.3962092, lng: 21.095096 },
    { lat: 49.3953035, lng: 21.0942154 },
    { lat: 49.3945965, lng: 21.0936433 },
    { lat: 49.3924942, lng: 21.0932073 },
    { lat: 49.3923035, lng: 21.091773 },
    { lat: 49.3919849, lng: 21.0906391 },
    { lat: 49.3921383, lng: 21.090015 },
    { lat: 49.3918917, lng: 21.0895501 },
    { lat: 49.3912021, lng: 21.0886438 },
    { lat: 49.3905901, lng: 21.0875907 },
    { lat: 49.389669, lng: 21.0864366 },
    { lat: 49.3889007, lng: 21.0846834 },
    { lat: 49.38839, lng: 21.084273 },
    { lat: 49.3883855, lng: 21.0842762 },
    { lat: 49.38737, lng: 21.0850744 },
    { lat: 49.3867207, lng: 21.0859535 },
    { lat: 49.386228, lng: 21.086646 },
    { lat: 49.3859885, lng: 21.0869816 },
    { lat: 49.3856833, lng: 21.0876061 },
    { lat: 49.385289, lng: 21.088051 },
    { lat: 49.3851603, lng: 21.0881961 },
    { lat: 49.385154, lng: 21.088202 },
    { lat: 49.38505, lng: 21.088328 },
    { lat: 49.3850408, lng: 21.0883375 },
    { lat: 49.3845549, lng: 21.0885006 },
    { lat: 49.3837847, lng: 21.0893952 },
    { lat: 49.3836176, lng: 21.0900049 },
    { lat: 49.3836666, lng: 21.0906549 },
    { lat: 49.3840126, lng: 21.0919348 },
    { lat: 49.383823, lng: 21.092367 },
    { lat: 49.3836981, lng: 21.0926525 },
    { lat: 49.38327, lng: 21.093715 },
    { lat: 49.383148, lng: 21.094015 },
    { lat: 49.3830713, lng: 21.0942029 },
    { lat: 49.382915, lng: 21.094427 },
    { lat: 49.3824314, lng: 21.0951162 },
    { lat: 49.3818896, lng: 21.0959629 },
    { lat: 49.3812173, lng: 21.0967946 },
    { lat: 49.3808454, lng: 21.097648 },
    { lat: 49.3806123, lng: 21.0979757 },
    { lat: 49.380596, lng: 21.098002 },
    { lat: 49.3805868, lng: 21.0980173 },
    { lat: 49.3800166, lng: 21.0991949 },
    { lat: 49.379427, lng: 21.100114 },
    { lat: 49.3792706, lng: 21.1003556 },
    { lat: 49.3787931, lng: 21.100821 },
    { lat: 49.3782359, lng: 21.1016497 },
    { lat: 49.3777189, lng: 21.1022047 },
    { lat: 49.3773374, lng: 21.1029602 },
    { lat: 49.3764011, lng: 21.1041047 },
    { lat: 49.375987, lng: 21.1049771 },
    { lat: 49.3758245, lng: 21.1055306 },
    { lat: 49.3755029, lng: 21.1063179 },
    { lat: 49.37511, lng: 21.1081863 },
    { lat: 49.3746951, lng: 21.108124 },
    { lat: 49.3742297, lng: 21.108215 },
    { lat: 49.3740898, lng: 21.1087671 },
    { lat: 49.374164, lng: 21.109443 },
    { lat: 49.3740024, lng: 21.1099526 },
    { lat: 49.373939, lng: 21.1105755 },
    { lat: 49.3745426, lng: 21.1121162 },
    { lat: 49.3745736, lng: 21.1128773 },
    { lat: 49.3746913, lng: 21.1132578 },
    { lat: 49.3746461, lng: 21.113571 },
    { lat: 49.3748865, lng: 21.1148321 },
    { lat: 49.374696, lng: 21.1152114 },
    { lat: 49.3752352, lng: 21.1164305 },
    { lat: 49.3752612, lng: 21.1166806 },
    { lat: 49.375218, lng: 21.1168124 },
    { lat: 49.3753855, lng: 21.1169783 },
    { lat: 49.3754703, lng: 21.1169236 },
    { lat: 49.3761356, lng: 21.1182454 },
    { lat: 49.3765082, lng: 21.1192458 },
    { lat: 49.3768905, lng: 21.1198067 },
    { lat: 49.3770123, lng: 21.1206666 },
    { lat: 49.3769898, lng: 21.1214533 },
    { lat: 49.3771819, lng: 21.1220468 },
    { lat: 49.3775165, lng: 21.1228096 },
    { lat: 49.3774959, lng: 21.1230083 },
    { lat: 49.3774228, lng: 21.1231177 },
    { lat: 49.3776331, lng: 21.1236857 },
    { lat: 49.3777351, lng: 21.1244206 },
    { lat: 49.3783362, lng: 21.1242814 },
    { lat: 49.3784876, lng: 21.1243356 },
    { lat: 49.3786829, lng: 21.1244637 },
    { lat: 49.3791605, lng: 21.1250108 },
    { lat: 49.3791647, lng: 21.1256454 },
    { lat: 49.3793938, lng: 21.126277 },
    { lat: 49.379378, lng: 21.1270528 },
    { lat: 49.3794222, lng: 21.1273172 },
    { lat: 49.3797886, lng: 21.1282647 },
    { lat: 49.3800482, lng: 21.1291093 },
    { lat: 49.3803306, lng: 21.1295009 },
    { lat: 49.3807904, lng: 21.1292028 },
    { lat: 49.3813642, lng: 21.1290206 },
    { lat: 49.3822921, lng: 21.130793 },
    { lat: 49.3823953, lng: 21.1309251 },
    { lat: 49.3826735, lng: 21.1309995 },
    { lat: 49.3827859, lng: 21.1311765 },
    { lat: 49.3834865, lng: 21.1326303 },
    { lat: 49.3836199, lng: 21.1331799 },
    { lat: 49.3836942, lng: 21.1338256 },
    { lat: 49.3838434, lng: 21.1343462 },
    { lat: 49.3840939, lng: 21.1342302 },
    { lat: 49.3846304, lng: 21.134504 },
    { lat: 49.3873839, lng: 21.1354757 },
    { lat: 49.3874199, lng: 21.1359729 },
    { lat: 49.3873414, lng: 21.1370536 },
    { lat: 49.3872801, lng: 21.1372182 },
    { lat: 49.387018, lng: 21.1374669 },
    { lat: 49.3867683, lng: 21.1378653 },
    { lat: 49.3866752, lng: 21.1382316 },
    { lat: 49.3869545, lng: 21.1399518 },
    { lat: 49.3874836, lng: 21.1407175 },
    { lat: 49.3876732, lng: 21.1416982 },
    { lat: 49.3877265, lng: 21.1422101 },
    { lat: 49.387949, lng: 21.1428066 },
    { lat: 49.3881423, lng: 21.1431586 },
    { lat: 49.3890411, lng: 21.1441174 },
    { lat: 49.3900395, lng: 21.1464602 },
    { lat: 49.3903371, lng: 21.1476581 },
    { lat: 49.3906606, lng: 21.1486402 },
    { lat: 49.3914691, lng: 21.1497365 },
    { lat: 49.3915752, lng: 21.1504288 },
  ],
  Lenartov: [
    { lat: 49.2739443, lng: 21.0179023 },
    { lat: 49.2740314, lng: 21.01802 },
    { lat: 49.2740876, lng: 21.0185369 },
    { lat: 49.274024, lng: 21.0188938 },
    { lat: 49.274007, lng: 21.019377 },
    { lat: 49.2740655, lng: 21.0197899 },
    { lat: 49.2742933, lng: 21.0207024 },
    { lat: 49.274492, lng: 21.02107 },
    { lat: 49.2748124, lng: 21.0214378 },
    { lat: 49.2752439, lng: 21.0215884 },
    { lat: 49.2757182, lng: 21.0218875 },
    { lat: 49.2766589, lng: 21.0218834 },
    { lat: 49.277241, lng: 21.0217173 },
    { lat: 49.2773471, lng: 21.0216599 },
    { lat: 49.2776696, lng: 21.0211275 },
    { lat: 49.2782147, lng: 21.0211037 },
    { lat: 49.2785011, lng: 21.020848 },
    { lat: 49.2788785, lng: 21.0207562 },
    { lat: 49.2790529, lng: 21.020615 },
    { lat: 49.2791826, lng: 21.0203831 },
    { lat: 49.2793196, lng: 21.0199549 },
    { lat: 49.2794596, lng: 21.0198012 },
    { lat: 49.2799753, lng: 21.0197068 },
    { lat: 49.280236, lng: 21.0194487 },
    { lat: 49.2804478, lng: 21.0193579 },
    { lat: 49.2808043, lng: 21.0194191 },
    { lat: 49.282202, lng: 21.0203859 },
    { lat: 49.2824031, lng: 21.020412 },
    { lat: 49.28256, lng: 21.0202844 },
    { lat: 49.2828871, lng: 21.0205522 },
    { lat: 49.2843452, lng: 21.0214376 },
    { lat: 49.2846658, lng: 21.0212618 },
    { lat: 49.2851073, lng: 21.0208926 },
    { lat: 49.2856987, lng: 21.0215012 },
    { lat: 49.2861332, lng: 21.0222663 },
    { lat: 49.2865926, lng: 21.0228251 },
    { lat: 49.2867341, lng: 21.0233341 },
    { lat: 49.2867695, lng: 21.0232118 },
    { lat: 49.2873083, lng: 21.0233121 },
    { lat: 49.2875496, lng: 21.0238057 },
    { lat: 49.2876121, lng: 21.0243507 },
    { lat: 49.2883613, lng: 21.0244451 },
    { lat: 49.2893631, lng: 21.0260454 },
    { lat: 49.2898761, lng: 21.0267299 },
    { lat: 49.2901733, lng: 21.0269067 },
    { lat: 49.2904725, lng: 21.0269402 },
    { lat: 49.2907613, lng: 21.0270629 },
    { lat: 49.2911057, lng: 21.0275865 },
    { lat: 49.2920283, lng: 21.0283752 },
    { lat: 49.2922893, lng: 21.0287877 },
    { lat: 49.2925441, lng: 21.0290459 },
    { lat: 49.2926334, lng: 21.0291974 },
    { lat: 49.2926593, lng: 21.0293689 },
    { lat: 49.2928378, lng: 21.0296373 },
    { lat: 49.2927988, lng: 21.029787 },
    { lat: 49.2928482, lng: 21.0302328 },
    { lat: 49.2927131, lng: 21.0307514 },
    { lat: 49.2927929, lng: 21.0309209 },
    { lat: 49.2928223, lng: 21.0313467 },
    { lat: 49.294972, lng: 21.0322565 },
    { lat: 49.2972099, lng: 21.0330805 },
    { lat: 49.2982701, lng: 21.0336015 },
    { lat: 49.2983118, lng: 21.0335641 },
    { lat: 49.3058997, lng: 21.0373488 },
    { lat: 49.3068251, lng: 21.0369441 },
    { lat: 49.3075328, lng: 21.0365229 },
    { lat: 49.307755, lng: 21.0365418 },
    { lat: 49.3077814, lng: 21.0363497 },
    { lat: 49.3083911, lng: 21.0359897 },
    { lat: 49.3088564, lng: 21.0358023 },
    { lat: 49.3088721, lng: 21.035589 },
    { lat: 49.3119425, lng: 21.0345707 },
    { lat: 49.31207, lng: 21.0345083 },
    { lat: 49.3121631, lng: 21.0343246 },
    { lat: 49.3123064, lng: 21.0343025 },
    { lat: 49.3124034, lng: 21.0344124 },
    { lat: 49.3130241, lng: 21.0340872 },
    { lat: 49.3131322, lng: 21.0341926 },
    { lat: 49.3132634, lng: 21.0340781 },
    { lat: 49.3133246, lng: 21.0338415 },
    { lat: 49.3135392, lng: 21.0335601 },
    { lat: 49.3142107, lng: 21.0334417 },
    { lat: 49.3153348, lng: 21.0324006 },
    { lat: 49.3157169, lng: 21.0322796 },
    { lat: 49.3169841, lng: 21.0316258 },
    { lat: 49.3172151, lng: 21.0313615 },
    { lat: 49.3195659, lng: 21.0295883 },
    { lat: 49.3205413, lng: 21.0286473 },
    { lat: 49.3210419, lng: 21.0283636 },
    { lat: 49.3221882, lng: 21.0274483 },
    { lat: 49.3232895, lng: 21.0267816 },
    { lat: 49.3240321, lng: 21.0259401 },
    { lat: 49.3246801, lng: 21.0254543 },
    { lat: 49.3249007, lng: 21.0250382 },
    { lat: 49.3253143, lng: 21.0246748 },
    { lat: 49.3257313, lng: 21.0241394 },
    { lat: 49.3263586, lng: 21.0232915 },
    { lat: 49.326641, lng: 21.0227743 },
    { lat: 49.3275041, lng: 21.0215209 },
    { lat: 49.3278621, lng: 21.0205931 },
    { lat: 49.3282175, lng: 21.0204116 },
    { lat: 49.3285209, lng: 21.0200555 },
    { lat: 49.3291256, lng: 21.0189267 },
    { lat: 49.3294104, lng: 21.0186485 },
    { lat: 49.3298167, lng: 21.0180983 },
    { lat: 49.3301332, lng: 21.0177975 },
    { lat: 49.3303732, lng: 21.0176702 },
    { lat: 49.330936, lng: 21.0175667 },
    { lat: 49.3341079, lng: 21.0139324 },
    { lat: 49.3341343, lng: 21.0140112 },
    { lat: 49.3351039, lng: 21.0121727 },
    { lat: 49.3355873, lng: 21.0118096 },
    { lat: 49.3370504, lng: 21.011026 },
    { lat: 49.3374214, lng: 21.0109107 },
    { lat: 49.3377235, lng: 21.0105812 },
    { lat: 49.3378528, lng: 21.0102282 },
    { lat: 49.3378274, lng: 21.0102081 },
    { lat: 49.338353, lng: 21.007987 },
    { lat: 49.3375433, lng: 21.0086911 },
    { lat: 49.337065, lng: 21.009107 },
    { lat: 49.336572, lng: 21.009166 },
    { lat: 49.336228, lng: 21.009121 },
    { lat: 49.33575, lng: 21.008924 },
    { lat: 49.335366, lng: 21.008422 },
    { lat: 49.335133, lng: 21.008274 },
    { lat: 49.334852, lng: 21.008091 },
    { lat: 49.334356, lng: 21.006905 },
    { lat: 49.333924, lng: 21.005874 },
    { lat: 49.333511, lng: 21.004853 },
    { lat: 49.333141, lng: 21.004069 },
    { lat: 49.333059, lng: 21.002998 },
    { lat: 49.333044, lng: 21.000747 },
    { lat: 49.333167, lng: 20.999763 },
    { lat: 49.333283, lng: 20.998812 },
    { lat: 49.333182, lng: 20.998224 },
    { lat: 49.332897, lng: 20.996699 },
    { lat: 49.33244, lng: 20.995716 },
    { lat: 49.331895, lng: 20.994897 },
    { lat: 49.331432, lng: 20.994377 },
    { lat: 49.33058, lng: 20.993532 },
    { lat: 49.330148, lng: 20.993068 },
    { lat: 49.329827, lng: 20.991738 },
    { lat: 49.329813, lng: 20.991686 },
    { lat: 49.329714, lng: 20.99141 },
    { lat: 49.32956, lng: 20.991131 },
    { lat: 49.329528, lng: 20.990778 },
    { lat: 49.329395, lng: 20.99058 },
    { lat: 49.328906, lng: 20.989857 },
    { lat: 49.328672, lng: 20.989603 },
    { lat: 49.328523, lng: 20.989261 },
    { lat: 49.328446, lng: 20.989065 },
    { lat: 49.3284294, lng: 20.9890644 },
    { lat: 49.327799, lng: 20.989001 },
    { lat: 49.32738, lng: 20.988748 },
    { lat: 49.327052, lng: 20.988197 },
    { lat: 49.326844, lng: 20.988085 },
    { lat: 49.326573, lng: 20.988165 },
    { lat: 49.326345, lng: 20.988274 },
    { lat: 49.326144, lng: 20.988492 },
    { lat: 49.325771, lng: 20.988947 },
    { lat: 49.325551, lng: 20.989189 },
    { lat: 49.325378, lng: 20.98924 },
    { lat: 49.325208, lng: 20.989054 },
    { lat: 49.325042, lng: 20.989043 },
    { lat: 49.32481, lng: 20.988891 },
    { lat: 49.324239, lng: 20.989213 },
    { lat: 49.324229, lng: 20.989213 },
    { lat: 49.323973, lng: 20.989219 },
    { lat: 49.323669, lng: 20.989034 },
    { lat: 49.323233, lng: 20.988183 },
    { lat: 49.322772, lng: 20.988336 },
    { lat: 49.322756, lng: 20.988344 },
    { lat: 49.322244, lng: 20.988694 },
    { lat: 49.322039, lng: 20.988916 },
    { lat: 49.32178, lng: 20.989284 },
    { lat: 49.321451, lng: 20.989475 },
    { lat: 49.320727, lng: 20.98965 },
    { lat: 49.31979, lng: 20.989898 },
    { lat: 49.319497, lng: 20.989898 },
    { lat: 49.319214, lng: 20.990135 },
    { lat: 49.318703, lng: 20.99057 },
    { lat: 49.317995, lng: 20.991305 },
    { lat: 49.317534, lng: 20.991971 },
    { lat: 49.316924, lng: 20.992413 },
    { lat: 49.316621, lng: 20.992917 },
    { lat: 49.316386, lng: 20.993218 },
    { lat: 49.316068, lng: 20.993485 },
    { lat: 49.315871, lng: 20.993673 },
    { lat: 49.315551, lng: 20.994 },
    { lat: 49.31512, lng: 20.993915 },
    { lat: 49.314824, lng: 20.993783 },
    { lat: 49.31452, lng: 20.993989 },
    { lat: 49.313958, lng: 20.994114 },
    { lat: 49.313922, lng: 20.994118 },
    { lat: 49.313217, lng: 20.994131 },
    { lat: 49.31268, lng: 20.994138 },
    { lat: 49.311951, lng: 20.993729 },
    { lat: 49.311783, lng: 20.993208 },
    { lat: 49.311654, lng: 20.992827 },
    { lat: 49.311472, lng: 20.992472 },
    { lat: 49.311448, lng: 20.99244 },
    { lat: 49.311028, lng: 20.991956 },
    { lat: 49.310831, lng: 20.991681 },
    { lat: 49.31057, lng: 20.991467 },
    { lat: 49.310533, lng: 20.991428 },
    { lat: 49.31021, lng: 20.991018 },
    { lat: 49.309694, lng: 20.990685 },
    { lat: 49.309512, lng: 20.990171 },
    { lat: 49.309152, lng: 20.989935 },
    { lat: 49.308861, lng: 20.989287 },
    { lat: 49.308705, lng: 20.988678 },
    { lat: 49.308689, lng: 20.988619 },
    { lat: 49.308664, lng: 20.988538 },
    { lat: 49.308598, lng: 20.988346 },
    { lat: 49.308594, lng: 20.988327 },
    { lat: 49.308546, lng: 20.988098 },
    { lat: 49.308453, lng: 20.987682 },
    { lat: 49.308446, lng: 20.98767 },
    { lat: 49.308272, lng: 20.987386 },
    { lat: 49.308252, lng: 20.987356 },
    { lat: 49.307849, lng: 20.986908 },
    { lat: 49.307433, lng: 20.986788 },
    { lat: 49.306651, lng: 20.986569 },
    { lat: 49.306637, lng: 20.986559 },
    { lat: 49.306621, lng: 20.986545 },
    { lat: 49.306589, lng: 20.986567 },
    { lat: 49.3064944, lng: 20.9865237 },
    { lat: 49.30634, lng: 20.986453 },
    { lat: 49.306219, lng: 20.9867 },
    { lat: 49.30594, lng: 20.987037 },
    { lat: 49.305759, lng: 20.987224 },
    { lat: 49.305651, lng: 20.987419 },
    { lat: 49.305647, lng: 20.987495 },
    { lat: 49.305477, lng: 20.987786 },
    { lat: 49.305372, lng: 20.98794 },
    { lat: 49.305221, lng: 20.98785 },
    { lat: 49.305084, lng: 20.987929 },
    { lat: 49.304531, lng: 20.98825 },
    { lat: 49.304484, lng: 20.988318 },
    { lat: 49.304362, lng: 20.988277 },
    { lat: 49.304255, lng: 20.988326 },
    { lat: 49.304126, lng: 20.988417 },
    { lat: 49.303974, lng: 20.988539 },
    { lat: 49.303698, lng: 20.988708 },
    { lat: 49.303379, lng: 20.988903 },
    { lat: 49.303165, lng: 20.989034 },
    { lat: 49.302221, lng: 20.989545 },
    { lat: 49.301867, lng: 20.989783 },
    { lat: 49.301577, lng: 20.989918 },
    { lat: 49.301235, lng: 20.989982 },
    { lat: 49.301075, lng: 20.989988 },
    { lat: 49.300904, lng: 20.990004 },
    { lat: 49.300501, lng: 20.990149 },
    { lat: 49.300157, lng: 20.990395 },
    { lat: 49.299896, lng: 20.990434 },
    { lat: 49.299677, lng: 20.990647 },
    { lat: 49.299349, lng: 20.990999 },
    { lat: 49.298831, lng: 20.991631 },
    { lat: 49.298606, lng: 20.991829 },
    { lat: 49.29837, lng: 20.99203 },
    { lat: 49.298246, lng: 20.992113 },
    { lat: 49.297683, lng: 20.992534 },
    { lat: 49.297261, lng: 20.993549 },
    { lat: 49.296986, lng: 20.994237 },
    { lat: 49.296896, lng: 20.994371 },
    { lat: 49.296731, lng: 20.994961 },
    { lat: 49.296751, lng: 20.995573 },
    { lat: 49.296477, lng: 20.995785 },
    { lat: 49.296292, lng: 20.995972 },
    { lat: 49.296208, lng: 20.996091 },
    { lat: 49.295716, lng: 20.99629 },
    { lat: 49.295764, lng: 20.997153 },
    { lat: 49.29573, lng: 20.997471 },
    { lat: 49.295445, lng: 20.998486 },
    { lat: 49.295361, lng: 20.998672 },
    { lat: 49.295323, lng: 20.998786 },
    { lat: 49.295267, lng: 20.99882 },
    { lat: 49.295162, lng: 20.998886 },
    { lat: 49.295074, lng: 20.999131 },
    { lat: 49.294788, lng: 20.999553 },
    { lat: 49.294566, lng: 20.999679 },
    { lat: 49.293231, lng: 21.000121 },
    { lat: 49.292687, lng: 21.000358 },
    { lat: 49.292542, lng: 21.00036 },
    { lat: 49.292334, lng: 21.000463 },
    { lat: 49.292037, lng: 21.00054 },
    { lat: 49.291987, lng: 21.000591 },
    { lat: 49.291648, lng: 21.00087 },
    { lat: 49.291479, lng: 21.000841 },
    { lat: 49.290836, lng: 21.001387 },
    { lat: 49.290787, lng: 21.001423 },
    { lat: 49.290771, lng: 21.001437 },
    { lat: 49.290678, lng: 21.001518 },
    { lat: 49.289889, lng: 21.002234 },
    { lat: 49.289563, lng: 21.002676 },
    { lat: 49.289177, lng: 21.003454 },
    { lat: 49.288918, lng: 21.004024 },
    { lat: 49.28889, lng: 21.004078 },
    { lat: 49.288028, lng: 21.004716 },
    { lat: 49.286571, lng: 21.005734 },
    { lat: 49.285844, lng: 21.006104 },
    { lat: 49.285687, lng: 21.00633 },
    { lat: 49.285287, lng: 21.006833 },
    { lat: 49.28512, lng: 21.007175 },
    { lat: 49.284881, lng: 21.007512 },
    { lat: 49.284509, lng: 21.008241 },
    { lat: 49.284257, lng: 21.008574 },
    { lat: 49.283899, lng: 21.009065 },
    { lat: 49.283737, lng: 21.00959 },
    { lat: 49.283587, lng: 21.010558 },
    { lat: 49.28348, lng: 21.010852 },
    { lat: 49.284006, lng: 21.013648 },
    { lat: 49.284081, lng: 21.014332 },
    { lat: 49.283979, lng: 21.015159 },
    { lat: 49.283977, lng: 21.015214 },
    { lat: 49.283966, lng: 21.015353 },
    { lat: 49.283956, lng: 21.015411 },
    { lat: 49.28377, lng: 21.015407 },
    { lat: 49.283672, lng: 21.015431 },
    { lat: 49.283493, lng: 21.015565 },
    { lat: 49.28343, lng: 21.01556 },
    { lat: 49.283103, lng: 21.015408 },
    { lat: 49.283039, lng: 21.015622 },
    { lat: 49.28271, lng: 21.01564 },
    { lat: 49.282647, lng: 21.015384 },
    { lat: 49.282435, lng: 21.015343 },
    { lat: 49.282422, lng: 21.014947 },
    { lat: 49.282327, lng: 21.01477 },
    { lat: 49.282143, lng: 21.01467 },
    { lat: 49.28204, lng: 21.014559 },
    { lat: 49.28178, lng: 21.014089 },
    { lat: 49.28173, lng: 21.013835 },
    { lat: 49.281544, lng: 21.013653 },
    { lat: 49.281367, lng: 21.013667 },
    { lat: 49.281217, lng: 21.013604 },
    { lat: 49.281072, lng: 21.013363 },
    { lat: 49.280978, lng: 21.013166 },
    { lat: 49.28088, lng: 21.01313 },
    { lat: 49.280794, lng: 21.013191 },
    { lat: 49.280691, lng: 21.013358 },
    { lat: 49.280582, lng: 21.013369 },
    { lat: 49.280472, lng: 21.013036 },
    { lat: 49.280225, lng: 21.012645 },
    { lat: 49.280006, lng: 21.01264 },
    { lat: 49.279787, lng: 21.012688 },
    { lat: 49.279426, lng: 21.012601 },
    { lat: 49.279056, lng: 21.012356 },
    { lat: 49.278955, lng: 21.012045 },
    { lat: 49.278642, lng: 21.011729 },
    { lat: 49.2787341, lng: 21.0135613 },
    { lat: 49.2785431, lng: 21.0138484 },
    { lat: 49.2782744, lng: 21.0138898 },
    { lat: 49.2780162, lng: 21.0142522 },
    { lat: 49.2772146, lng: 21.0150024 },
    { lat: 49.2769813, lng: 21.0150573 },
    { lat: 49.2768484, lng: 21.0153648 },
    { lat: 49.2766013, lng: 21.0156557 },
    { lat: 49.276428, lng: 21.015727 },
    { lat: 49.2760801, lng: 21.0162404 },
    { lat: 49.2759079, lng: 21.016274 },
    { lat: 49.2757607, lng: 21.0164878 },
    { lat: 49.2755967, lng: 21.0165003 },
    { lat: 49.2755575, lng: 21.0166435 },
    { lat: 49.2752928, lng: 21.0170435 },
    { lat: 49.2748938, lng: 21.0173049 },
    { lat: 49.2747553, lng: 21.0173032 },
    { lat: 49.2744168, lng: 21.0175809 },
    { lat: 49.2741239, lng: 21.0177102 },
    { lat: 49.273965, lng: 21.0178802 },
    { lat: 49.2739443, lng: 21.0179023 },
  ],
  Zborov: [
    { lat: 49.3900835, lng: 21.3260012 },
    { lat: 49.3901079, lng: 21.3250106 },
    { lat: 49.3900406, lng: 21.3197145 },
    { lat: 49.3896781, lng: 21.3156486 },
    { lat: 49.3897497, lng: 21.3148826 },
    { lat: 49.389786, lng: 21.3113677 },
    { lat: 49.3891918, lng: 21.3082154 },
    { lat: 49.3892165, lng: 21.3052128 },
    { lat: 49.3893479, lng: 21.3042692 },
    { lat: 49.3880906, lng: 21.300773 },
    { lat: 49.3874496, lng: 21.2986117 },
    { lat: 49.3872545, lng: 21.2984091 },
    { lat: 49.3870755, lng: 21.2979631 },
    { lat: 49.3866315, lng: 21.2973347 },
    { lat: 49.3864786, lng: 21.2959229 },
    { lat: 49.3862414, lng: 21.2908533 },
    { lat: 49.3863524, lng: 21.2904699 },
    { lat: 49.3863375, lng: 21.2897501 },
    { lat: 49.3862348, lng: 21.288956 },
    { lat: 49.386368, lng: 21.2873319 },
    { lat: 49.386402, lng: 21.2845464 },
    { lat: 49.3862781, lng: 21.2819387 },
    { lat: 49.3862005, lng: 21.2817207 },
    { lat: 49.3863161, lng: 21.2803683 },
    { lat: 49.3863879, lng: 21.2786195 },
    { lat: 49.3860742, lng: 21.2782178 },
    { lat: 49.3855716, lng: 21.278298 },
    { lat: 49.3839024, lng: 21.2779378 },
    { lat: 49.3834745, lng: 21.2782668 },
    { lat: 49.3830322, lng: 21.2783358 },
    { lat: 49.3824821, lng: 21.2787869 },
    { lat: 49.3821524, lng: 21.2788515 },
    { lat: 49.3817704, lng: 21.2792488 },
    { lat: 49.3814354, lng: 21.279888 },
    { lat: 49.3813031, lng: 21.2804925 },
    { lat: 49.3811257, lng: 21.2809575 },
    { lat: 49.3807448, lng: 21.2814883 },
    { lat: 49.3807797, lng: 21.2817567 },
    { lat: 49.3805672, lng: 21.2818686 },
    { lat: 49.3805575, lng: 21.2822899 },
    { lat: 49.3795604, lng: 21.2843988 },
    { lat: 49.378949, lng: 21.2846866 },
    { lat: 49.3782889, lng: 21.284733 },
    { lat: 49.3775755, lng: 21.2843233 },
    { lat: 49.3761101, lng: 21.2844879 },
    { lat: 49.3758908, lng: 21.2842698 },
    { lat: 49.3755963, lng: 21.2841279 },
    { lat: 49.3741906, lng: 21.2837047 },
    { lat: 49.3738502, lng: 21.2836823 },
    { lat: 49.3731375, lng: 21.283261 },
    { lat: 49.372088, lng: 21.282301 },
    { lat: 49.3719416, lng: 21.2818489 },
    { lat: 49.3710114, lng: 21.2812622 },
    { lat: 49.3706708, lng: 21.2807188 },
    { lat: 49.3695267, lng: 21.2814665 },
    { lat: 49.3692082, lng: 21.2812043 },
    { lat: 49.3665669, lng: 21.2758337 },
    { lat: 49.3661958, lng: 21.2755381 },
    { lat: 49.3662573, lng: 21.2753416 },
    { lat: 49.3650333, lng: 21.2743034 },
    { lat: 49.3648927, lng: 21.2745636 },
    { lat: 49.3633314, lng: 21.2732419 },
    { lat: 49.3632255, lng: 21.2729068 },
    { lat: 49.3619682, lng: 21.2704074 },
    { lat: 49.3607581, lng: 21.2662731 },
    { lat: 49.3607058, lng: 21.2661826 },
    { lat: 49.3602384, lng: 21.2658665 },
    { lat: 49.3602421, lng: 21.2656558 },
    { lat: 49.3600019, lng: 21.264899 },
    { lat: 49.3598613, lng: 21.2640889 },
    { lat: 49.3592887, lng: 21.2621712 },
    { lat: 49.358918, lng: 21.2612603 },
    { lat: 49.3567796, lng: 21.2546337 },
    { lat: 49.3547314, lng: 21.2493489 },
    { lat: 49.3546386, lng: 21.2491115 },
    { lat: 49.3541545, lng: 21.2494321 },
    { lat: 49.3541574, lng: 21.2498708 },
    { lat: 49.3541615, lng: 21.2505057 },
    { lat: 49.3537295, lng: 21.2522387 },
    { lat: 49.3536796, lng: 21.2534766 },
    { lat: 49.3537293, lng: 21.2538156 },
    { lat: 49.3536193, lng: 21.2552267 },
    { lat: 49.3530509, lng: 21.2568117 },
    { lat: 49.3524143, lng: 21.2578092 },
    { lat: 49.3518772, lng: 21.2585376 },
    { lat: 49.3511353, lng: 21.2591128 },
    { lat: 49.3503896, lng: 21.2610799 },
    { lat: 49.3504341, lng: 21.264022 },
    { lat: 49.3503409, lng: 21.2647696 },
    { lat: 49.3503783, lng: 21.2650686 },
    { lat: 49.3499085, lng: 21.2719391 },
    { lat: 49.3500608, lng: 21.2766249 },
    { lat: 49.3500444, lng: 21.2802779 },
    { lat: 49.349462, lng: 21.283373 },
    { lat: 49.3490938, lng: 21.2849668 },
    { lat: 49.3490455, lng: 21.2856364 },
    { lat: 49.3489582, lng: 21.2857292 },
    { lat: 49.3485075, lng: 21.2873082 },
    { lat: 49.3484519, lng: 21.2880736 },
    { lat: 49.3483467, lng: 21.2884307 },
    { lat: 49.3484668, lng: 21.2893074 },
    { lat: 49.3498623, lng: 21.2915251 },
    { lat: 49.3501542, lng: 21.2918766 },
    { lat: 49.3501799, lng: 21.2923154 },
    { lat: 49.3503283, lng: 21.2926827 },
    { lat: 49.3497285, lng: 21.2977497 },
    { lat: 49.3492485, lng: 21.3029543 },
    { lat: 49.3491102, lng: 21.3037493 },
    { lat: 49.3471379, lng: 21.3085376 },
    { lat: 49.3472486, lng: 21.308964 },
    { lat: 49.3478567, lng: 21.309697 },
    { lat: 49.3485809, lng: 21.3114056 },
    { lat: 49.348521, lng: 21.3121431 },
    { lat: 49.3486571, lng: 21.3131595 },
    { lat: 49.3487735, lng: 21.3133614 },
    { lat: 49.3488438, lng: 21.313639 },
    { lat: 49.3490843, lng: 21.3140854 },
    { lat: 49.3492394, lng: 21.3146757 },
    { lat: 49.3492871, lng: 21.3152519 },
    { lat: 49.3494959, lng: 21.3161362 },
    { lat: 49.3495522, lng: 21.3172293 },
    { lat: 49.3495161, lng: 21.3174874 },
    { lat: 49.3496189, lng: 21.3178028 },
    { lat: 49.3494286, lng: 21.3194527 },
    { lat: 49.3493903, lng: 21.3201248 },
    { lat: 49.3494355, lng: 21.3206211 },
    { lat: 49.3493854, lng: 21.3209403 },
    { lat: 49.349514, lng: 21.3217783 },
    { lat: 49.3495336, lng: 21.3222825 },
    { lat: 49.3493295, lng: 21.3234267 },
    { lat: 49.3494011, lng: 21.324622 },
    { lat: 49.3493348, lng: 21.3258655 },
    { lat: 49.3493923, lng: 21.3265431 },
    { lat: 49.3496124, lng: 21.3270604 },
    { lat: 49.3496563, lng: 21.3274337 },
    { lat: 49.3495468, lng: 21.3282107 },
    { lat: 49.3493605, lng: 21.3288715 },
    { lat: 49.349484, lng: 21.3291242 },
    { lat: 49.3509789, lng: 21.3310729 },
    { lat: 49.3516319, lng: 21.3315426 },
    { lat: 49.3519822, lng: 21.3320472 },
    { lat: 49.3523443, lng: 21.3323791 },
    { lat: 49.3532957, lng: 21.3325482 },
    { lat: 49.354087, lng: 21.3332078 },
    { lat: 49.3545978, lng: 21.3339939 },
    { lat: 49.3550317, lng: 21.3351539 },
    { lat: 49.3551276, lng: 21.3366778 },
    { lat: 49.3552676, lng: 21.337933 },
    { lat: 49.3554366, lng: 21.3388127 },
    { lat: 49.3554601, lng: 21.3401302 },
    { lat: 49.3555529, lng: 21.3407925 },
    { lat: 49.3557917, lng: 21.341782 },
    { lat: 49.3560235, lng: 21.3422047 },
    { lat: 49.3566204, lng: 21.345367 },
    { lat: 49.3565615, lng: 21.3461037 },
    { lat: 49.3566849, lng: 21.3473475 },
    { lat: 49.3565825, lng: 21.3483681 },
    { lat: 49.3566748, lng: 21.3489366 },
    { lat: 49.3568452, lng: 21.349521 },
    { lat: 49.3568684, lng: 21.349889 },
    { lat: 49.3568091, lng: 21.3508864 },
    { lat: 49.3570687, lng: 21.3523481 },
    { lat: 49.3569776, lng: 21.3530376 },
    { lat: 49.3570509, lng: 21.3538775 },
    { lat: 49.3574285, lng: 21.3530648 },
    { lat: 49.3577566, lng: 21.3527442 },
    { lat: 49.3590939, lng: 21.351899 },
    { lat: 49.3592533, lng: 21.3512072 },
    { lat: 49.3596911, lng: 21.3507963 },
    { lat: 49.3597835, lng: 21.3504621 },
    { lat: 49.3605147, lng: 21.3498435 },
    { lat: 49.3609151, lng: 21.3498405 },
    { lat: 49.36105, lng: 21.3495388 },
    { lat: 49.3611449, lng: 21.3494333 },
    { lat: 49.3612145, lng: 21.3494364 },
    { lat: 49.3611157, lng: 21.3491732 },
    { lat: 49.3611027, lng: 21.348885 },
    { lat: 49.3610001, lng: 21.3486915 },
    { lat: 49.361312, lng: 21.3478666 },
    { lat: 49.3614105, lng: 21.3471686 },
    { lat: 49.3616479, lng: 21.3466351 },
    { lat: 49.3615494, lng: 21.3456292 },
    { lat: 49.3619401, lng: 21.3443934 },
    { lat: 49.3628144, lng: 21.3425017 },
    { lat: 49.3633143, lng: 21.3420636 },
    { lat: 49.3639098, lng: 21.3412453 },
    { lat: 49.3643352, lng: 21.3380347 },
    { lat: 49.3650122, lng: 21.3366304 },
    { lat: 49.3651772, lng: 21.3367637 },
    { lat: 49.3653524, lng: 21.3363363 },
    { lat: 49.3657318, lng: 21.3357055 },
    { lat: 49.3663391, lng: 21.3356991 },
    { lat: 49.3665718, lng: 21.3353497 },
    { lat: 49.3670301, lng: 21.3355158 },
    { lat: 49.36737, lng: 21.336313 },
    { lat: 49.3674294, lng: 21.3365742 },
    { lat: 49.3678743, lng: 21.3365496 },
    { lat: 49.3681741, lng: 21.3368326 },
    { lat: 49.3682992, lng: 21.3370293 },
    { lat: 49.3683032, lng: 21.3371626 },
    { lat: 49.3681545, lng: 21.337156 },
    { lat: 49.3680989, lng: 21.3373649 },
    { lat: 49.3681811, lng: 21.3376285 },
    { lat: 49.3687023, lng: 21.3379455 },
    { lat: 49.3689022, lng: 21.3383074 },
    { lat: 49.3690866, lng: 21.3382689 },
    { lat: 49.3693187, lng: 21.3384321 },
    { lat: 49.3692873, lng: 21.3386982 },
    { lat: 49.3693509, lng: 21.3387912 },
    { lat: 49.369449, lng: 21.3386916 },
    { lat: 49.3696404, lng: 21.3388472 },
    { lat: 49.3703911, lng: 21.338929 },
    { lat: 49.3705202, lng: 21.3391861 },
    { lat: 49.3706631, lng: 21.3391877 },
    { lat: 49.3707324, lng: 21.3388723 },
    { lat: 49.3717015, lng: 21.3381631 },
    { lat: 49.3717137, lng: 21.3383504 },
    { lat: 49.3718429, lng: 21.3382254 },
    { lat: 49.3720317, lng: 21.3382216 },
    { lat: 49.372122, lng: 21.3381525 },
    { lat: 49.3725789, lng: 21.3371382 },
    { lat: 49.3730281, lng: 21.3366205 },
    { lat: 49.3732242, lng: 21.3361422 },
    { lat: 49.3735693, lng: 21.3357938 },
    { lat: 49.3737104, lng: 21.3354641 },
    { lat: 49.3737401, lng: 21.3350706 },
    { lat: 49.3739503, lng: 21.3346869 },
    { lat: 49.3740688, lng: 21.3345808 },
    { lat: 49.3744052, lng: 21.334549 },
    { lat: 49.3744868, lng: 21.3343028 },
    { lat: 49.3747389, lng: 21.3341873 },
    { lat: 49.3748386, lng: 21.3337991 },
    { lat: 49.374793, lng: 21.3334412 },
    { lat: 49.3748178, lng: 21.3331402 },
    { lat: 49.3749874, lng: 21.3327189 },
    { lat: 49.375274, lng: 21.3324786 },
    { lat: 49.3754428, lng: 21.3324783 },
    { lat: 49.3757324, lng: 21.3326855 },
    { lat: 49.3759494, lng: 21.3326415 },
    { lat: 49.3763488, lng: 21.3321134 },
    { lat: 49.376718, lng: 21.3317811 },
    { lat: 49.3769089, lng: 21.3313525 },
    { lat: 49.3771168, lng: 21.3313448 },
    { lat: 49.3770181, lng: 21.3308256 },
    { lat: 49.377042, lng: 21.3306875 },
    { lat: 49.3771685, lng: 21.3304778 },
    { lat: 49.3771348, lng: 21.3299083 },
    { lat: 49.3772838, lng: 21.3297419 },
    { lat: 49.3778702, lng: 21.3294051 },
    { lat: 49.3781287, lng: 21.3290627 },
    { lat: 49.3789148, lng: 21.3286693 },
    { lat: 49.3793539, lng: 21.3286093 },
    { lat: 49.379719, lng: 21.3281739 },
    { lat: 49.3799065, lng: 21.3280878 },
    { lat: 49.3800257, lng: 21.3279365 },
    { lat: 49.3802013, lng: 21.3279018 },
    { lat: 49.3803374, lng: 21.3277883 },
    { lat: 49.3804584, lng: 21.3278058 },
    { lat: 49.3805693, lng: 21.3280132 },
    { lat: 49.3806397, lng: 21.3280404 },
    { lat: 49.3808673, lng: 21.3277132 },
    { lat: 49.381125, lng: 21.3275962 },
    { lat: 49.3813791, lng: 21.3272935 },
    { lat: 49.3814712, lng: 21.3273217 },
    { lat: 49.3817679, lng: 21.3276501 },
    { lat: 49.381981, lng: 21.3280447 },
    { lat: 49.3820652, lng: 21.3281171 },
    { lat: 49.3824139, lng: 21.3276597 },
    { lat: 49.3833248, lng: 21.3273489 },
    { lat: 49.3836666, lng: 21.3271108 },
    { lat: 49.3842077, lng: 21.3268844 },
    { lat: 49.3846791, lng: 21.3268076 },
    { lat: 49.3850745, lng: 21.3268641 },
    { lat: 49.3852332, lng: 21.3267554 },
    { lat: 49.3858059, lng: 21.326569 },
    { lat: 49.3859273, lng: 21.3266385 },
    { lat: 49.386101, lng: 21.3268799 },
    { lat: 49.3868055, lng: 21.3271125 },
    { lat: 49.3870639, lng: 21.3269875 },
    { lat: 49.3873976, lng: 21.3269739 },
    { lat: 49.3878512, lng: 21.3266012 },
    { lat: 49.388215, lng: 21.3263907 },
    { lat: 49.3886415, lng: 21.3263215 },
    { lat: 49.38907, lng: 21.3264572 },
    { lat: 49.389248, lng: 21.326435 },
    { lat: 49.3897283, lng: 21.3261994 },
    { lat: 49.3900837, lng: 21.3261637 },
    { lat: 49.3900835, lng: 21.3260012 },
  ],
  Mokroluh: [
    { lat: 49.323361, lng: 21.202433 },
    { lat: 49.322895, lng: 21.2021316 },
    { lat: 49.3222693, lng: 21.2021272 },
    { lat: 49.3213294, lng: 21.2024075 },
    { lat: 49.3209186, lng: 21.2025877 },
    { lat: 49.3194598, lng: 21.2036229 },
    { lat: 49.3191896, lng: 21.2038722 },
    { lat: 49.3183282, lng: 21.2041074 },
    { lat: 49.3169777, lng: 21.2051138 },
    { lat: 49.3166546, lng: 21.2052183 },
    { lat: 49.316097, lng: 21.2051963 },
    { lat: 49.3156018, lng: 21.2050104 },
    { lat: 49.3149406, lng: 21.2046284 },
    { lat: 49.3148036, lng: 21.2044908 },
    { lat: 49.3142633, lng: 21.2037574 },
    { lat: 49.3137752, lng: 21.2026759 },
    { lat: 49.3134885, lng: 21.2021727 },
    { lat: 49.3132743, lng: 21.2018636 },
    { lat: 49.3128696, lng: 21.20152 },
    { lat: 49.3126825, lng: 21.2014305 },
    { lat: 49.3119707, lng: 21.2013025 },
    { lat: 49.3111648, lng: 21.2014403 },
    { lat: 49.3104616, lng: 21.2011198 },
    { lat: 49.3094575, lng: 21.2007714 },
    { lat: 49.3080727, lng: 21.2004953 },
    { lat: 49.3078742, lng: 21.2001845 },
    { lat: 49.3076624, lng: 21.1991271 },
    { lat: 49.3075442, lng: 21.1989128 },
    { lat: 49.307418, lng: 21.1981427 },
    { lat: 49.3072793, lng: 21.1976248 },
    { lat: 49.3072672, lng: 21.1973412 },
    { lat: 49.3069692, lng: 21.1966268 },
    { lat: 49.3067511, lng: 21.196299 },
    { lat: 49.3065738, lng: 21.1962463 },
    { lat: 49.3063356, lng: 21.195934 },
    { lat: 49.3061152, lng: 21.1958609 },
    { lat: 49.3059685, lng: 21.1956311 },
    { lat: 49.3056379, lng: 21.1953872 },
    { lat: 49.3051283, lng: 21.1952114 },
    { lat: 49.3049783, lng: 21.1952706 },
    { lat: 49.3046999, lng: 21.1949678 },
    { lat: 49.3045324, lng: 21.1949352 },
    { lat: 49.3043405, lng: 21.1950191 },
    { lat: 49.3040144, lng: 21.1945185 },
    { lat: 49.3040154, lng: 21.1939336 },
    { lat: 49.3038351, lng: 21.1933824 },
    { lat: 49.3038261, lng: 21.1932074 },
    { lat: 49.3035888, lng: 21.1929528 },
    { lat: 49.3034752, lng: 21.1927061 },
    { lat: 49.3033651, lng: 21.1927419 },
    { lat: 49.302742, lng: 21.1922845 },
    { lat: 49.3025743, lng: 21.1919542 },
    { lat: 49.3024328, lng: 21.1918938 },
    { lat: 49.3021827, lng: 21.1919578 },
    { lat: 49.3016664, lng: 21.1918157 },
    { lat: 49.3013707, lng: 21.1914586 },
    { lat: 49.3010247, lng: 21.1912657 },
    { lat: 49.3002011, lng: 21.1903935 },
    { lat: 49.2998284, lng: 21.1901004 },
    { lat: 49.299254, lng: 21.1900759 },
    { lat: 49.2990391, lng: 21.1898986 },
    { lat: 49.2985441, lng: 21.1896451 },
    { lat: 49.298243, lng: 21.1893349 },
    { lat: 49.2983625, lng: 21.188948 },
    { lat: 49.2981797, lng: 21.188679 },
    { lat: 49.2978006, lng: 21.1883165 },
    { lat: 49.2976535, lng: 21.1880381 },
    { lat: 49.2976408, lng: 21.1880296 },
    { lat: 49.2973174, lng: 21.1888845 },
    { lat: 49.2970468, lng: 21.1900918 },
    { lat: 49.2966014, lng: 21.1911134 },
    { lat: 49.2961089, lng: 21.1919593 },
    { lat: 49.293901, lng: 21.1949566 },
    { lat: 49.2932255, lng: 21.1962468 },
    { lat: 49.2930692, lng: 21.1972654 },
    { lat: 49.2928345, lng: 21.1981923 },
    { lat: 49.2924107, lng: 21.1991751 },
    { lat: 49.2919788, lng: 21.1999843 },
    { lat: 49.2912502, lng: 21.2019225 },
    { lat: 49.2913618, lng: 21.2023646 },
    { lat: 49.2916502, lng: 21.2027384 },
    { lat: 49.2913268, lng: 21.2035254 },
    { lat: 49.2900335, lng: 21.2057635 },
    { lat: 49.289511, lng: 21.20698 },
    { lat: 49.288909, lng: 21.2089467 },
    { lat: 49.2880789, lng: 21.2109225 },
    { lat: 49.2874621, lng: 21.2131341 },
    { lat: 49.2869014, lng: 21.2148684 },
    { lat: 49.2866154, lng: 21.2154484 },
    { lat: 49.286221, lng: 21.2164505 },
    { lat: 49.2862421, lng: 21.2164763 },
    { lat: 49.2871355, lng: 21.2175314 },
    { lat: 49.2877718, lng: 21.2176596 },
    { lat: 49.2881365, lng: 21.2180673 },
    { lat: 49.2884439, lng: 21.218257 },
    { lat: 49.2887667, lng: 21.2180676 },
    { lat: 49.288805, lng: 21.2183993 },
    { lat: 49.2895321, lng: 21.2188097 },
    { lat: 49.2896245, lng: 21.2191365 },
    { lat: 49.2894683, lng: 21.2197678 },
    { lat: 49.289544, lng: 21.220432 },
    { lat: 49.2895335, lng: 21.2211869 },
    { lat: 49.2895805, lng: 21.2214333 },
    { lat: 49.2898083, lng: 21.2213722 },
    { lat: 49.2899443, lng: 21.2214096 },
    { lat: 49.2901491, lng: 21.2215909 },
    { lat: 49.2905647, lng: 21.2216796 },
    { lat: 49.2909372, lng: 21.2216405 },
    { lat: 49.2913529, lng: 21.2214036 },
    { lat: 49.2916428, lng: 21.2214624 },
    { lat: 49.2922205, lng: 21.2217675 },
    { lat: 49.2928588, lng: 21.2222893 },
    { lat: 49.2933183, lng: 21.2222721 },
    { lat: 49.2937127, lng: 21.2221103 },
    { lat: 49.2938282, lng: 21.2222389 },
    { lat: 49.294266, lng: 21.2219849 },
    { lat: 49.2944492, lng: 21.2217627 },
    { lat: 49.294766, lng: 21.2217463 },
    { lat: 49.2955126, lng: 21.2212195 },
    { lat: 49.2956321, lng: 21.2211893 },
    { lat: 49.2956588, lng: 21.2210291 },
    { lat: 49.2959371, lng: 21.2208655 },
    { lat: 49.2962211, lng: 21.2210196 },
    { lat: 49.2967505, lng: 21.2209684 },
    { lat: 49.2968585, lng: 21.2208936 },
    { lat: 49.2969493, lng: 21.2210637 },
    { lat: 49.2972936, lng: 21.2211798 },
    { lat: 49.2974413, lng: 21.2214428 },
    { lat: 49.2977943, lng: 21.2215738 },
    { lat: 49.2980354, lng: 21.221992 },
    { lat: 49.298105, lng: 21.2220241 },
    { lat: 49.2984666, lng: 21.2220647 },
    { lat: 49.2986339, lng: 21.2220227 },
    { lat: 49.29876, lng: 21.222169 },
    { lat: 49.2989929, lng: 21.222021 },
    { lat: 49.2992598, lng: 21.2220299 },
    { lat: 49.2998499, lng: 21.2221579 },
    { lat: 49.2999276, lng: 21.222307 },
    { lat: 49.3001767, lng: 21.2223164 },
    { lat: 49.3002445, lng: 21.2223981 },
    { lat: 49.3002633, lng: 21.2225716 },
    { lat: 49.3003954, lng: 21.2225906 },
    { lat: 49.3006759, lng: 21.2232525 },
    { lat: 49.3008544, lng: 21.2234974 },
    { lat: 49.3010294, lng: 21.2234073 },
    { lat: 49.3014131, lng: 21.2235563 },
    { lat: 49.3013797, lng: 21.2236734 },
    { lat: 49.3014516, lng: 21.2236736 },
    { lat: 49.3013618, lng: 21.224302 },
    { lat: 49.3014978, lng: 21.2249495 },
    { lat: 49.3017544, lng: 21.2250994 },
    { lat: 49.3030086, lng: 21.2253481 },
    { lat: 49.3044498, lng: 21.225634 },
    { lat: 49.3059018, lng: 21.2258334 },
    { lat: 49.3060649, lng: 21.2257939 },
    { lat: 49.3064723, lng: 21.2260137 },
    { lat: 49.3066251, lng: 21.2263356 },
    { lat: 49.3067775, lng: 21.2262395 },
    { lat: 49.3072957, lng: 21.2262606 },
    { lat: 49.3076092, lng: 21.2264364 },
    { lat: 49.3080472, lng: 21.2264409 },
    { lat: 49.3083567, lng: 21.226666 },
    { lat: 49.3085089, lng: 21.2269334 },
    { lat: 49.3086883, lng: 21.226985 },
    { lat: 49.3089223, lng: 21.2273479 },
    { lat: 49.30919, lng: 21.2276041 },
    { lat: 49.3092555, lng: 21.2278259 },
    { lat: 49.3093789, lng: 21.2278109 },
    { lat: 49.3102198, lng: 21.2281925 },
    { lat: 49.3105775, lng: 21.2280773 },
    { lat: 49.3116194, lng: 21.228047 },
    { lat: 49.3118833, lng: 21.2279855 },
    { lat: 49.3126735, lng: 21.228735 },
    { lat: 49.3129233, lng: 21.2290989 },
    { lat: 49.3131384, lng: 21.2291747 },
    { lat: 49.3134215, lng: 21.2291276 },
    { lat: 49.3139389, lng: 21.2287178 },
    { lat: 49.314629, lng: 21.2286253 },
    { lat: 49.3147866, lng: 21.2282601 },
    { lat: 49.3149799, lng: 21.2280284 },
    { lat: 49.3157927, lng: 21.227641 },
    { lat: 49.3160769, lng: 21.2276663 },
    { lat: 49.316742, lng: 21.2287293 },
    { lat: 49.3170462, lng: 21.2289216 },
    { lat: 49.318063, lng: 21.229018 },
    { lat: 49.318542, lng: 21.229338 },
    { lat: 49.3188986, lng: 21.2293795 },
    { lat: 49.3191623, lng: 21.2296436 },
    { lat: 49.3193599, lng: 21.2295219 },
    { lat: 49.3196347, lng: 21.2294549 },
    { lat: 49.3199129, lng: 21.2297227 },
    { lat: 49.3202502, lng: 21.2297522 },
    { lat: 49.3205238, lng: 21.2293173 },
    { lat: 49.3207255, lng: 21.2291831 },
    { lat: 49.3211289, lng: 21.2293445 },
    { lat: 49.3212956, lng: 21.2293138 },
    { lat: 49.3216228, lng: 21.2296022 },
    { lat: 49.3219105, lng: 21.2293362 },
    { lat: 49.322166, lng: 21.2288277 },
    { lat: 49.3231745, lng: 21.2275572 },
    { lat: 49.3235824, lng: 21.2272107 },
    { lat: 49.3244833, lng: 21.2253351 },
    { lat: 49.3246256, lng: 21.2248703 },
    { lat: 49.3247001, lng: 21.2247701 },
    { lat: 49.3250145, lng: 21.2241624 },
    { lat: 49.3251269, lng: 21.2237221 },
    { lat: 49.3252441, lng: 21.2219404 },
    { lat: 49.3255619, lng: 21.2211888 },
    { lat: 49.3256685, lng: 21.220706 },
    { lat: 49.3255712, lng: 21.2202506 },
    { lat: 49.3256655, lng: 21.2199152 },
    { lat: 49.3256209, lng: 21.2179914 },
    { lat: 49.3257436, lng: 21.2175529 },
    { lat: 49.325672, lng: 21.2174253 },
    { lat: 49.325611, lng: 21.2157394 },
    { lat: 49.3257116, lng: 21.2141474 },
    { lat: 49.325603, lng: 21.2136153 },
    { lat: 49.3253522, lng: 21.2131529 },
    { lat: 49.3249369, lng: 21.2126758 },
    { lat: 49.3246857, lng: 21.2127325 },
    { lat: 49.3246448, lng: 21.2118131 },
    { lat: 49.3249435, lng: 21.2111339 },
    { lat: 49.3249478, lng: 21.2107314 },
    { lat: 49.32469, lng: 21.2084332 },
    { lat: 49.3244593, lng: 21.2079935 },
    { lat: 49.3243494, lng: 21.2070258 },
    { lat: 49.3246107, lng: 21.2063795 },
    { lat: 49.324783, lng: 21.2061268 },
    { lat: 49.3249498, lng: 21.2055888 },
    { lat: 49.3249834, lng: 21.2043495 },
    { lat: 49.3248903, lng: 21.2037415 },
    { lat: 49.3249116, lng: 21.2027913 },
    { lat: 49.3249691, lng: 21.2023665 },
    { lat: 49.3237855, lng: 21.2029068 },
    { lat: 49.323361, lng: 21.202433 },
  ],
  Zlaté: [
    { lat: 49.323361, lng: 21.202433 },
    { lat: 49.3237855, lng: 21.2029068 },
    { lat: 49.3249691, lng: 21.2023665 },
    { lat: 49.3249116, lng: 21.2027913 },
    { lat: 49.3248903, lng: 21.2037415 },
    { lat: 49.3249834, lng: 21.2043495 },
    { lat: 49.3249498, lng: 21.2055888 },
    { lat: 49.324783, lng: 21.2061268 },
    { lat: 49.3246107, lng: 21.2063795 },
    { lat: 49.3243494, lng: 21.2070258 },
    { lat: 49.3244593, lng: 21.2079935 },
    { lat: 49.32469, lng: 21.2084332 },
    { lat: 49.3249478, lng: 21.2107314 },
    { lat: 49.3249435, lng: 21.2111339 },
    { lat: 49.3246448, lng: 21.2118131 },
    { lat: 49.3246857, lng: 21.2127325 },
    { lat: 49.3249369, lng: 21.2126758 },
    { lat: 49.3253522, lng: 21.2131529 },
    { lat: 49.325603, lng: 21.2136153 },
    { lat: 49.3257116, lng: 21.2141474 },
    { lat: 49.325611, lng: 21.2157394 },
    { lat: 49.325672, lng: 21.2174253 },
    { lat: 49.3257436, lng: 21.2175529 },
    { lat: 49.3256209, lng: 21.2179914 },
    { lat: 49.3256655, lng: 21.2199152 },
    { lat: 49.3255712, lng: 21.2202506 },
    { lat: 49.3256685, lng: 21.220706 },
    { lat: 49.3255619, lng: 21.2211888 },
    { lat: 49.3252441, lng: 21.2219404 },
    { lat: 49.3251269, lng: 21.2237221 },
    { lat: 49.3250145, lng: 21.2241624 },
    { lat: 49.3247001, lng: 21.2247701 },
    { lat: 49.3257398, lng: 21.2276946 },
    { lat: 49.3273585, lng: 21.231013 },
    { lat: 49.3273862, lng: 21.2323587 },
    { lat: 49.3275305, lng: 21.2324053 },
    { lat: 49.3278908, lng: 21.2323361 },
    { lat: 49.3285696, lng: 21.2321275 },
    { lat: 49.3298387, lng: 21.2316488 },
    { lat: 49.3320433, lng: 21.2306706 },
    { lat: 49.3329566, lng: 21.2309894 },
    { lat: 49.3329039, lng: 21.2312938 },
    { lat: 49.3331624, lng: 21.2313877 },
    { lat: 49.3329614, lng: 21.2326497 },
    { lat: 49.3325093, lng: 21.2330239 },
    { lat: 49.3320345, lng: 21.2352977 },
    { lat: 49.3318835, lng: 21.2358035 },
    { lat: 49.3318234, lng: 21.236227 },
    { lat: 49.3317046, lng: 21.2371573 },
    { lat: 49.3317653, lng: 21.2372287 },
    { lat: 49.3316945, lng: 21.237342 },
    { lat: 49.3317595, lng: 21.2374463 },
    { lat: 49.3320288, lng: 21.2376023 },
    { lat: 49.3322669, lng: 21.2376238 },
    { lat: 49.3324639, lng: 21.2375592 },
    { lat: 49.332569, lng: 21.2373882 },
    { lat: 49.3326277, lng: 21.2370654 },
    { lat: 49.3328948, lng: 21.2368276 },
    { lat: 49.3330882, lng: 21.2364526 },
    { lat: 49.3332133, lng: 21.2363101 },
    { lat: 49.3334837, lng: 21.2363643 },
    { lat: 49.3337004, lng: 21.2365637 },
    { lat: 49.3344201, lng: 21.2368829 },
    { lat: 49.3344428, lng: 21.237399 },
    { lat: 49.334472, lng: 21.2374738 },
    { lat: 49.3346059, lng: 21.2375118 },
    { lat: 49.3347084, lng: 21.2372576 },
    { lat: 49.3348701, lng: 21.2373167 },
    { lat: 49.3349061, lng: 21.2371778 },
    { lat: 49.3350019, lng: 21.2370724 },
    { lat: 49.3351601, lng: 21.2371635 },
    { lat: 49.3353403, lng: 21.2371736 },
    { lat: 49.3356407, lng: 21.2368739 },
    { lat: 49.3357846, lng: 21.2368686 },
    { lat: 49.3357755, lng: 21.2370879 },
    { lat: 49.3358394, lng: 21.2372248 },
    { lat: 49.3359711, lng: 21.2372301 },
    { lat: 49.3362452, lng: 21.2370668 },
    { lat: 49.3363077, lng: 21.2371662 },
    { lat: 49.3363963, lng: 21.2371884 },
    { lat: 49.3365602, lng: 21.236849 },
    { lat: 49.3367151, lng: 21.2367664 },
    { lat: 49.336853, lng: 21.236925 },
    { lat: 49.337045, lng: 21.2369502 },
    { lat: 49.3373429, lng: 21.2367635 },
    { lat: 49.3377361, lng: 21.2366693 },
    { lat: 49.337858, lng: 21.2367904 },
    { lat: 49.3380037, lng: 21.2366594 },
    { lat: 49.3381573, lng: 21.2366794 },
    { lat: 49.3382748, lng: 21.2365508 },
    { lat: 49.3383474, lng: 21.2365662 },
    { lat: 49.3383927, lng: 21.2367603 },
    { lat: 49.3384741, lng: 21.2368472 },
    { lat: 49.3385303, lng: 21.2367035 },
    { lat: 49.3387222, lng: 21.2366479 },
    { lat: 49.3390309, lng: 21.2368057 },
    { lat: 49.3391639, lng: 21.2365588 },
    { lat: 49.339422, lng: 21.2365713 },
    { lat: 49.3396406, lng: 21.2363975 },
    { lat: 49.3397635, lng: 21.2361534 },
    { lat: 49.3399553, lng: 21.2359473 },
    { lat: 49.340136, lng: 21.2359672 },
    { lat: 49.34033, lng: 21.2361344 },
    { lat: 49.3404627, lng: 21.2360556 },
    { lat: 49.3404988, lng: 21.2359112 },
    { lat: 49.3410675, lng: 21.2357123 },
    { lat: 49.3412385, lng: 21.2358147 },
    { lat: 49.3414065, lng: 21.2357572 },
    { lat: 49.3421044, lng: 21.2359095 },
    { lat: 49.3422173, lng: 21.2360006 },
    { lat: 49.3424221, lng: 21.2360353 },
    { lat: 49.3426976, lng: 21.2363265 },
    { lat: 49.3428221, lng: 21.2363476 },
    { lat: 49.343006, lng: 21.2365108 },
    { lat: 49.3431064, lng: 21.2367785 },
    { lat: 49.343645, lng: 21.2373497 },
    { lat: 49.3439969, lng: 21.2376366 },
    { lat: 49.3444239, lng: 21.2377311 },
    { lat: 49.3447292, lng: 21.2380004 },
    { lat: 49.3448404, lng: 21.2382114 },
    { lat: 49.3450914, lng: 21.2383226 },
    { lat: 49.3454544, lng: 21.2386733 },
    { lat: 49.3456576, lng: 21.2390282 },
    { lat: 49.3456729, lng: 21.239825 },
    { lat: 49.3458208, lng: 21.2401662 },
    { lat: 49.3461714, lng: 21.2407441 },
    { lat: 49.3465542, lng: 21.2409717 },
    { lat: 49.3470881, lng: 21.2409912 },
    { lat: 49.3488411, lng: 21.2400003 },
    { lat: 49.3497082, lng: 21.239697 },
    { lat: 49.3502825, lng: 21.2392718 },
    { lat: 49.350328, lng: 21.2391404 },
    { lat: 49.3512284, lng: 21.2382319 },
    { lat: 49.3512757, lng: 21.2380831 },
    { lat: 49.3515993, lng: 21.2377026 },
    { lat: 49.3517759, lng: 21.237626 },
    { lat: 49.3519006, lng: 21.2374719 },
    { lat: 49.3531159, lng: 21.2368227 },
    { lat: 49.3533398, lng: 21.2367795 },
    { lat: 49.3544486, lng: 21.2370157 },
    { lat: 49.3547875, lng: 21.2370267 },
    { lat: 49.3552392, lng: 21.236895 },
    { lat: 49.355526, lng: 21.2369303 },
    { lat: 49.3559282, lng: 21.2365434 },
    { lat: 49.3565168, lng: 21.236177 },
    { lat: 49.3597046, lng: 21.2390908 },
    { lat: 49.3600161, lng: 21.238384 },
    { lat: 49.3611776, lng: 21.2366347 },
    { lat: 49.3617835, lng: 21.23581 },
    { lat: 49.362397, lng: 21.235193 },
    { lat: 49.3636863, lng: 21.2341667 },
    { lat: 49.3639588, lng: 21.2337352 },
    { lat: 49.3643017, lng: 21.2329536 },
    { lat: 49.3649739, lng: 21.2322016 },
    { lat: 49.3656568, lng: 21.2316431 },
    { lat: 49.3658804, lng: 21.2312317 },
    { lat: 49.3662816, lng: 21.2308533 },
    { lat: 49.366363, lng: 21.2305337 },
    { lat: 49.3661197, lng: 21.2301253 },
    { lat: 49.3655252, lng: 21.2298157 },
    { lat: 49.3647676, lng: 21.2288847 },
    { lat: 49.3639361, lng: 21.2281872 },
    { lat: 49.3627846, lng: 21.2268937 },
    { lat: 49.3623905, lng: 21.2263752 },
    { lat: 49.3621781, lng: 21.2256841 },
    { lat: 49.3619839, lng: 21.2247133 },
    { lat: 49.3618561, lng: 21.2244688 },
    { lat: 49.3615645, lng: 21.222525 },
    { lat: 49.361552, lng: 21.2219342 },
    { lat: 49.361089, lng: 21.2203504 },
    { lat: 49.3606545, lng: 21.219135 },
    { lat: 49.3597608, lng: 21.2179213 },
    { lat: 49.3596191, lng: 21.2175244 },
    { lat: 49.3590372, lng: 21.2163825 },
    { lat: 49.3590454, lng: 21.2159913 },
    { lat: 49.3593942, lng: 21.2148936 },
    { lat: 49.3617992, lng: 21.2082057 },
    { lat: 49.3622515, lng: 21.2066738 },
    { lat: 49.3621912, lng: 21.2048392 },
    { lat: 49.3621226, lng: 21.2041082 },
    { lat: 49.3619195, lng: 21.2030359 },
    { lat: 49.3613792, lng: 21.2010276 },
    { lat: 49.3604493, lng: 21.2000306 },
    { lat: 49.3599941, lng: 21.1997229 },
    { lat: 49.3594911, lng: 21.1989963 },
    { lat: 49.3584652, lng: 21.1983496 },
    { lat: 49.3578684, lng: 21.196591 },
    { lat: 49.3574674, lng: 21.1959213 },
    { lat: 49.3573925, lng: 21.1956503 },
    { lat: 49.3566236, lng: 21.1950571 },
    { lat: 49.3555874, lng: 21.194041 },
    { lat: 49.3550927, lng: 21.1934391 },
    { lat: 49.3550896, lng: 21.1932271 },
    { lat: 49.3545493, lng: 21.1930376 },
    { lat: 49.3542169, lng: 21.192228 },
    { lat: 49.3536666, lng: 21.1914624 },
    { lat: 49.3529639, lng: 21.1906087 },
    { lat: 49.3528551, lng: 21.1900063 },
    { lat: 49.3522196, lng: 21.1886356 },
    { lat: 49.3510179, lng: 21.1848779 },
    { lat: 49.3502389, lng: 21.1835221 },
    { lat: 49.3495816, lng: 21.1820693 },
    { lat: 49.3491642, lng: 21.1812802 },
    { lat: 49.3488243, lng: 21.1801172 },
    { lat: 49.3477463, lng: 21.1779151 },
    { lat: 49.3477274, lng: 21.1778829 },
    { lat: 49.3477257, lng: 21.1779032 },
    { lat: 49.3476004, lng: 21.1781062 },
    { lat: 49.3474791, lng: 21.1781858 },
    { lat: 49.3474097, lng: 21.1785928 },
    { lat: 49.3472539, lng: 21.1786847 },
    { lat: 49.3470704, lng: 21.1789681 },
    { lat: 49.3469249, lng: 21.1790299 },
    { lat: 49.3466619, lng: 21.1795858 },
    { lat: 49.3466567, lng: 21.1797734 },
    { lat: 49.3464243, lng: 21.1800805 },
    { lat: 49.3462718, lng: 21.1804598 },
    { lat: 49.3459705, lng: 21.1809004 },
    { lat: 49.3456212, lng: 21.180853 },
    { lat: 49.3454836, lng: 21.1810214 },
    { lat: 49.345254, lng: 21.1810537 },
    { lat: 49.3451879, lng: 21.1812097 },
    { lat: 49.344397, lng: 21.1815031 },
    { lat: 49.3439166, lng: 21.1814306 },
    { lat: 49.3436021, lng: 21.1812727 },
    { lat: 49.34324, lng: 21.1812367 },
    { lat: 49.3431275, lng: 21.1813124 },
    { lat: 49.3430205, lng: 21.1812383 },
    { lat: 49.3420901, lng: 21.1814963 },
    { lat: 49.3418163, lng: 21.1816996 },
    { lat: 49.3415525, lng: 21.1817892 },
    { lat: 49.3411299, lng: 21.1818399 },
    { lat: 49.3405673, lng: 21.1821357 },
    { lat: 49.3394682, lng: 21.1814898 },
    { lat: 49.3390042, lng: 21.1822324 },
    { lat: 49.3384169, lng: 21.1825978 },
    { lat: 49.3380814, lng: 21.1834611 },
    { lat: 49.3378131, lng: 21.1836575 },
    { lat: 49.3378035, lng: 21.1836762 },
    { lat: 49.337349, lng: 21.1841878 },
    { lat: 49.3366744, lng: 21.1847461 },
    { lat: 49.3364079, lng: 21.1846757 },
    { lat: 49.3362191, lng: 21.1847529 },
    { lat: 49.3360346, lng: 21.1849002 },
    { lat: 49.3358604, lng: 21.1849093 },
    { lat: 49.3355741, lng: 21.1850752 },
    { lat: 49.3353059, lng: 21.1853156 },
    { lat: 49.3348372, lng: 21.185226 },
    { lat: 49.3346467, lng: 21.1853531 },
    { lat: 49.3340144, lng: 21.1854317 },
    { lat: 49.3334711, lng: 21.1854013 },
    { lat: 49.3330787, lng: 21.1852614 },
    { lat: 49.332881, lng: 21.1856384 },
    { lat: 49.3320207, lng: 21.1860477 },
    { lat: 49.3316059, lng: 21.1872905 },
    { lat: 49.330628, lng: 21.1883416 },
    { lat: 49.3304687, lng: 21.1887147 },
    { lat: 49.3302013, lng: 21.1895971 },
    { lat: 49.3298429, lng: 21.1903403 },
    { lat: 49.329032, lng: 21.1918012 },
    { lat: 49.3280013, lng: 21.1943754 },
    { lat: 49.3277329, lng: 21.1951835 },
    { lat: 49.3272956, lng: 21.1969418 },
    { lat: 49.3271394, lng: 21.1970231 },
    { lat: 49.3270044, lng: 21.1976347 },
    { lat: 49.3266192, lng: 21.1979166 },
    { lat: 49.3264681, lng: 21.197942 },
    { lat: 49.3262127, lng: 21.19823 },
    { lat: 49.3259453, lng: 21.198349 },
    { lat: 49.3253835, lng: 21.1988172 },
    { lat: 49.325191, lng: 21.1988824 },
    { lat: 49.32503, lng: 21.199249 },
    { lat: 49.325037, lng: 21.199505 },
    { lat: 49.3248709, lng: 21.1997743 },
    { lat: 49.3247512, lng: 21.2004474 },
    { lat: 49.32436, lng: 21.200826 },
    { lat: 49.3240385, lng: 21.2008757 },
    { lat: 49.3237292, lng: 21.2012729 },
    { lat: 49.3234513, lng: 21.202004 },
    { lat: 49.323361, lng: 21.202433 },
  ],
  Kurov: [
    { lat: 49.3252549, lng: 21.1481295 },
    { lat: 49.3260705, lng: 21.147875 },
    { lat: 49.3269934, lng: 21.147919 },
    { lat: 49.3280684, lng: 21.1481454 },
    { lat: 49.3291389, lng: 21.1486554 },
    { lat: 49.3297672, lng: 21.1487423 },
    { lat: 49.3299025, lng: 21.1495069 },
    { lat: 49.3298828, lng: 21.1496992 },
    { lat: 49.3297485, lng: 21.1500839 },
    { lat: 49.3298896, lng: 21.150921 },
    { lat: 49.3304133, lng: 21.1508987 },
    { lat: 49.3306227, lng: 21.1507563 },
    { lat: 49.3328383, lng: 21.1501146 },
    { lat: 49.3339608, lng: 21.1496001 },
    { lat: 49.3347148, lng: 21.1493513 },
    { lat: 49.3353615, lng: 21.1489815 },
    { lat: 49.3358394, lng: 21.1488669 },
    { lat: 49.3361873, lng: 21.1489088 },
    { lat: 49.3367055, lng: 21.1488231 },
    { lat: 49.3380727, lng: 21.1491774 },
    { lat: 49.3390847, lng: 21.1489752 },
    { lat: 49.3395087, lng: 21.1490754 },
    { lat: 49.3402317, lng: 21.1493837 },
    { lat: 49.3407917, lng: 21.14955 },
    { lat: 49.3409714, lng: 21.1495437 },
    { lat: 49.3410499, lng: 21.149679 },
    { lat: 49.3417057, lng: 21.1497397 },
    { lat: 49.3427375, lng: 21.149995 },
    { lat: 49.3432107, lng: 21.1501879 },
    { lat: 49.3436309, lng: 21.1499856 },
    { lat: 49.3438189, lng: 21.1499877 },
    { lat: 49.3441604, lng: 21.1498341 },
    { lat: 49.3444875, lng: 21.1497844 },
    { lat: 49.3451328, lng: 21.1491952 },
    { lat: 49.3455757, lng: 21.1489277 },
    { lat: 49.3456587, lng: 21.148958 },
    { lat: 49.3467175, lng: 21.1487058 },
    { lat: 49.347213, lng: 21.1486794 },
    { lat: 49.3477191, lng: 21.1485631 },
    { lat: 49.3481919, lng: 21.1482883 },
    { lat: 49.3488118, lng: 21.1477471 },
    { lat: 49.349099, lng: 21.1473557 },
    { lat: 49.3493588, lng: 21.1471077 },
    { lat: 49.3494073, lng: 21.1468205 },
    { lat: 49.3496504, lng: 21.1460714 },
    { lat: 49.3497413, lng: 21.1459232 },
    { lat: 49.3498759, lng: 21.1452257 },
    { lat: 49.3498361, lng: 21.1448233 },
    { lat: 49.3503887, lng: 21.143178 },
    { lat: 49.3507399, lng: 21.1423945 },
    { lat: 49.3515452, lng: 21.1410267 },
    { lat: 49.3524274, lng: 21.1392025 },
    { lat: 49.3528558, lng: 21.1379454 },
    { lat: 49.3534807, lng: 21.1365988 },
    { lat: 49.3532103, lng: 21.1362351 },
    { lat: 49.3540198, lng: 21.1345019 },
    { lat: 49.3552161, lng: 21.132329 },
    { lat: 49.3562839, lng: 21.1306903 },
    { lat: 49.3564541, lng: 21.1302355 },
    { lat: 49.357103, lng: 21.1291898 },
    { lat: 49.3573666, lng: 21.1288457 },
    { lat: 49.3576125, lng: 21.1286485 },
    { lat: 49.3576906, lng: 21.1286673 },
    { lat: 49.3582736, lng: 21.1279155 },
    { lat: 49.3585307, lng: 21.1274333 },
    { lat: 49.3586713, lng: 21.1270128 },
    { lat: 49.3593079, lng: 21.1260408 },
    { lat: 49.3589313, lng: 21.1246973 },
    { lat: 49.3590637, lng: 21.1245056 },
    { lat: 49.3590409, lng: 21.1242853 },
    { lat: 49.3586825, lng: 21.1235906 },
    { lat: 49.3582754, lng: 21.1224934 },
    { lat: 49.3584799, lng: 21.1217422 },
    { lat: 49.3584638, lng: 21.1216578 },
    { lat: 49.3588153, lng: 21.1217733 },
    { lat: 49.3593202, lng: 21.1212315 },
    { lat: 49.3593629, lng: 21.1209796 },
    { lat: 49.3594607, lng: 21.1208377 },
    { lat: 49.3597546, lng: 21.120774 },
    { lat: 49.3599841, lng: 21.1204863 },
    { lat: 49.3604527, lng: 21.1205786 },
    { lat: 49.3607094, lng: 21.1204025 },
    { lat: 49.3611089, lng: 21.1202552 },
    { lat: 49.3612042, lng: 21.1199687 },
    { lat: 49.3617055, lng: 21.1199827 },
    { lat: 49.3618531, lng: 21.1198111 },
    { lat: 49.3625453, lng: 21.1193533 },
    { lat: 49.3630238, lng: 21.1193271 },
    { lat: 49.3633363, lng: 21.1192097 },
    { lat: 49.3636075, lng: 21.118713 },
    { lat: 49.3638936, lng: 21.118721 },
    { lat: 49.3640598, lng: 21.1184672 },
    { lat: 49.3643308, lng: 21.1184258 },
    { lat: 49.3664629, lng: 21.1171421 },
    { lat: 49.3666322, lng: 21.1166569 },
    { lat: 49.3671676, lng: 21.1157993 },
    { lat: 49.3680291, lng: 21.1148959 },
    { lat: 49.3681905, lng: 21.1146611 },
    { lat: 49.3693271, lng: 21.1137108 },
    { lat: 49.3697901, lng: 21.1128019 },
    { lat: 49.3700955, lng: 21.1124603 },
    { lat: 49.3702803, lng: 21.1123639 },
    { lat: 49.3703821, lng: 21.1124506 },
    { lat: 49.3707166, lng: 21.11154 },
    { lat: 49.3709734, lng: 21.1112298 },
    { lat: 49.3709981, lng: 21.1111237 },
    { lat: 49.3712735, lng: 21.1107215 },
    { lat: 49.3713233, lng: 21.1100928 },
    { lat: 49.3714481, lng: 21.1097018 },
    { lat: 49.372152, lng: 21.1089092 },
    { lat: 49.3721249, lng: 21.1086184 },
    { lat: 49.3721544, lng: 21.1079496 },
    { lat: 49.3727905, lng: 21.1062253 },
    { lat: 49.3734762, lng: 21.1051816 },
    { lat: 49.373793, lng: 21.1042054 },
    { lat: 49.373638, lng: 21.1030455 },
    { lat: 49.3743023, lng: 21.1019245 },
    { lat: 49.3740789, lng: 21.1011631 },
    { lat: 49.373816, lng: 21.1008541 },
    { lat: 49.3735942, lng: 21.1007637 },
    { lat: 49.3730124, lng: 21.1004892 },
    { lat: 49.3724884, lng: 21.1001242 },
    { lat: 49.3718185, lng: 21.0999211 },
    { lat: 49.37136, lng: 21.0998407 },
    { lat: 49.3709751, lng: 21.0996266 },
    { lat: 49.3703992, lng: 21.0990857 },
    { lat: 49.370017, lng: 21.098461 },
    { lat: 49.3699298, lng: 21.098316 },
    { lat: 49.3697893, lng: 21.0979686 },
    { lat: 49.3693974, lng: 21.0972224 },
    { lat: 49.3690951, lng: 21.0967772 },
    { lat: 49.3686526, lng: 21.0968068 },
    { lat: 49.3680024, lng: 21.096633 },
    { lat: 49.3672759, lng: 21.096074 },
    { lat: 49.3665189, lng: 21.0952739 },
    { lat: 49.365979, lng: 21.0949411 },
    { lat: 49.365428, lng: 21.094443 },
    { lat: 49.3650563, lng: 21.0941092 },
    { lat: 49.3652441, lng: 21.0934154 },
    { lat: 49.3651159, lng: 21.092427 },
    { lat: 49.3650089, lng: 21.0914243 },
    { lat: 49.3647524, lng: 21.0909403 },
    { lat: 49.3651262, lng: 21.0901437 },
    { lat: 49.363937, lng: 21.0904269 },
    { lat: 49.3627139, lng: 21.0913108 },
    { lat: 49.3622237, lng: 21.0915706 },
    { lat: 49.3618104, lng: 21.0915567 },
    { lat: 49.3616847, lng: 21.0917802 },
    { lat: 49.3614692, lng: 21.0918678 },
    { lat: 49.3609945, lng: 21.0918365 },
    { lat: 49.3599794, lng: 21.0937737 },
    { lat: 49.3598108, lng: 21.094239 },
    { lat: 49.3595391, lng: 21.0947185 },
    { lat: 49.3595248, lng: 21.0948315 },
    { lat: 49.3592331, lng: 21.0952836 },
    { lat: 49.3589938, lng: 21.0954022 },
    { lat: 49.3587995, lng: 21.0956243 },
    { lat: 49.3582288, lng: 21.0960427 },
    { lat: 49.3577067, lng: 21.0959012 },
    { lat: 49.3574719, lng: 21.0960577 },
    { lat: 49.3569864, lng: 21.0966448 },
    { lat: 49.3565574, lng: 21.0972703 },
    { lat: 49.3562568, lng: 21.0982493 },
    { lat: 49.3560834, lng: 21.0984222 },
    { lat: 49.3557877, lng: 21.0989626 },
    { lat: 49.3556603, lng: 21.0990584 },
    { lat: 49.3554856, lng: 21.0994439 },
    { lat: 49.3550109, lng: 21.100789 },
    { lat: 49.3550404, lng: 21.1009394 },
    { lat: 49.3545177, lng: 21.1016289 },
    { lat: 49.3543258, lng: 21.1016453 },
    { lat: 49.3542266, lng: 21.1018466 },
    { lat: 49.3537276, lng: 21.1022924 },
    { lat: 49.3536472, lng: 21.102455 },
    { lat: 49.3534325, lng: 21.1023541 },
    { lat: 49.3532682, lng: 21.1026356 },
    { lat: 49.3530054, lng: 21.1025812 },
    { lat: 49.3527014, lng: 21.102897 },
    { lat: 49.3524128, lng: 21.1028762 },
    { lat: 49.3521697, lng: 21.1030925 },
    { lat: 49.351838, lng: 21.1030941 },
    { lat: 49.3516909, lng: 21.1032127 },
    { lat: 49.3514637, lng: 21.1030018 },
    { lat: 49.3511753, lng: 21.1032738 },
    { lat: 49.3510204, lng: 21.1031121 },
    { lat: 49.3508515, lng: 21.1031388 },
    { lat: 49.3507404, lng: 21.1032572 },
    { lat: 49.3504691, lng: 21.10315 },
    { lat: 49.3503372, lng: 21.1032139 },
    { lat: 49.3501681, lng: 21.1034128 },
    { lat: 49.3498753, lng: 21.1033682 },
    { lat: 49.3495111, lng: 21.1036989 },
    { lat: 49.3491828, lng: 21.1038879 },
    { lat: 49.348749, lng: 21.1044335 },
    { lat: 49.3485112, lng: 21.1044963 },
    { lat: 49.3484305, lng: 21.1048217 },
    { lat: 49.3478614, lng: 21.1055065 },
    { lat: 49.3477735, lng: 21.1058556 },
    { lat: 49.3476538, lng: 21.1060897 },
    { lat: 49.3473776, lng: 21.1062853 },
    { lat: 49.3472673, lng: 21.1066367 },
    { lat: 49.3471012, lng: 21.1069678 },
    { lat: 49.3469876, lng: 21.1070795 },
    { lat: 49.346932, lng: 21.1072756 },
    { lat: 49.3469972, lng: 21.1076259 },
    { lat: 49.3467291, lng: 21.1077624 },
    { lat: 49.3466532, lng: 21.1080655 },
    { lat: 49.346464, lng: 21.1081665 },
    { lat: 49.3463738, lng: 21.1084085 },
    { lat: 49.3462345, lng: 21.1085867 },
    { lat: 49.3460378, lng: 21.1086678 },
    { lat: 49.3458075, lng: 21.1091089 },
    { lat: 49.3457127, lng: 21.1091312 },
    { lat: 49.3456214, lng: 21.1094472 },
    { lat: 49.3452542, lng: 21.1101815 },
    { lat: 49.3451188, lng: 21.1102743 },
    { lat: 49.3449621, lng: 21.1102274 },
    { lat: 49.3448592, lng: 21.1102835 },
    { lat: 49.3447437, lng: 21.1106843 },
    { lat: 49.3446044, lng: 21.110873 },
    { lat: 49.3442752, lng: 21.1107448 },
    { lat: 49.3440677, lng: 21.1109078 },
    { lat: 49.3439158, lng: 21.1112288 },
    { lat: 49.3436083, lng: 21.1112552 },
    { lat: 49.3433602, lng: 21.1116649 },
    { lat: 49.34296, lng: 21.1117076 },
    { lat: 49.3426916, lng: 21.1119044 },
    { lat: 49.3426458, lng: 21.1122016 },
    { lat: 49.3421617, lng: 21.1122964 },
    { lat: 49.3418458, lng: 21.1125212 },
    { lat: 49.341699, lng: 21.112823 },
    { lat: 49.3412056, lng: 21.1130841 },
    { lat: 49.3409814, lng: 21.113182 },
    { lat: 49.3408728, lng: 21.1131341 },
    { lat: 49.3404448, lng: 21.1132955 },
    { lat: 49.3403401, lng: 21.1136461 },
    { lat: 49.3401705, lng: 21.1139048 },
    { lat: 49.3397681, lng: 21.1140263 },
    { lat: 49.339567, lng: 21.1144295 },
    { lat: 49.3393924, lng: 21.1143501 },
    { lat: 49.3391728, lng: 21.1138804 },
    { lat: 49.3388339, lng: 21.1137742 },
    { lat: 49.3386558, lng: 21.1140465 },
    { lat: 49.3385728, lng: 21.1140162 },
    { lat: 49.3379984, lng: 21.1142466 },
    { lat: 49.337532, lng: 21.113986 },
    { lat: 49.3373203, lng: 21.1141219 },
    { lat: 49.3368254, lng: 21.1138404 },
    { lat: 49.3365789, lng: 21.11381 },
    { lat: 49.336445, lng: 21.1138398 },
    { lat: 49.336371, lng: 21.1139886 },
    { lat: 49.3361692, lng: 21.1141898 },
    { lat: 49.3359615, lng: 21.1138866 },
    { lat: 49.3356601, lng: 21.1137163 },
    { lat: 49.3352064, lng: 21.1137807 },
    { lat: 49.3349209, lng: 21.11393 },
    { lat: 49.3343415, lng: 21.1145002 },
    { lat: 49.334016, lng: 21.114669 },
    { lat: 49.3336579, lng: 21.1144485 },
    { lat: 49.3331468, lng: 21.114406 },
    { lat: 49.3327553, lng: 21.1145527 },
    { lat: 49.3323019, lng: 21.1151203 },
    { lat: 49.3319874, lng: 21.1159176 },
    { lat: 49.3315165, lng: 21.1167571 },
    { lat: 49.3314476, lng: 21.1168517 },
    { lat: 49.3311555, lng: 21.117009 },
    { lat: 49.3307077, lng: 21.1177891 },
    { lat: 49.3302329, lng: 21.1184326 },
    { lat: 49.3298142, lng: 21.1193497 },
    { lat: 49.3294074, lng: 21.1198991 },
    { lat: 49.3292843, lng: 21.1201752 },
    { lat: 49.3293844, lng: 21.1210553 },
    { lat: 49.3293701, lng: 21.1212439 },
    { lat: 49.3293188, lng: 21.1216493 },
    { lat: 49.3290776, lng: 21.1219273 },
    { lat: 49.3289549, lng: 21.1222813 },
    { lat: 49.3291834, lng: 21.1228852 },
    { lat: 49.3291887, lng: 21.1230529 },
    { lat: 49.3291114, lng: 21.1236352 },
    { lat: 49.3289387, lng: 21.1240059 },
    { lat: 49.3288549, lng: 21.1249486 },
    { lat: 49.3289999, lng: 21.1254105 },
    { lat: 49.3292459, lng: 21.1257466 },
    { lat: 49.3293379, lng: 21.1260255 },
    { lat: 49.3291614, lng: 21.1266685 },
    { lat: 49.329029, lng: 21.1268586 },
    { lat: 49.3287174, lng: 21.1279283 },
    { lat: 49.3280297, lng: 21.1288515 },
    { lat: 49.3276003, lng: 21.1292238 },
    { lat: 49.3272963, lng: 21.1297149 },
    { lat: 49.3271057, lng: 21.1298084 },
    { lat: 49.3269696, lng: 21.1297759 },
    { lat: 49.3262351, lng: 21.1302841 },
    { lat: 49.325201, lng: 21.1306369 },
    { lat: 49.3243146, lng: 21.1314278 },
    { lat: 49.3241058, lng: 21.1316918 },
    { lat: 49.322876, lng: 21.1346544 },
    { lat: 49.322521, lng: 21.1352109 },
    { lat: 49.3227284, lng: 21.1355076 },
    { lat: 49.3224012, lng: 21.1362262 },
    { lat: 49.3223273, lng: 21.1365985 },
    { lat: 49.3224262, lng: 21.1369137 },
    { lat: 49.3230853, lng: 21.1376565 },
    { lat: 49.3230867, lng: 21.1378793 },
    { lat: 49.3234567, lng: 21.13812 },
    { lat: 49.3236296, lng: 21.1386473 },
    { lat: 49.3239242, lng: 21.1391738 },
    { lat: 49.3241619, lng: 21.1394644 },
    { lat: 49.3243572, lng: 21.1395935 },
    { lat: 49.3244478, lng: 21.1398588 },
    { lat: 49.3243608, lng: 21.1401744 },
    { lat: 49.3245154, lng: 21.1407903 },
    { lat: 49.3248422, lng: 21.1414509 },
    { lat: 49.324893, lng: 21.1414251 },
    { lat: 49.3250132, lng: 21.1416309 },
    { lat: 49.3252659, lng: 21.141757 },
    { lat: 49.3252879, lng: 21.1421345 },
    { lat: 49.3252278, lng: 21.143084 },
    { lat: 49.3253584, lng: 21.1449798 },
    { lat: 49.3251779, lng: 21.1470757 },
    { lat: 49.3252355, lng: 21.1479776 },
    { lat: 49.3252549, lng: 21.1481295 },
  ],
  Stuľany: [
    { lat: 49.1470891, lng: 21.3846285 },
    { lat: 49.1469089, lng: 21.3855358 },
    { lat: 49.1468031, lng: 21.3854971 },
    { lat: 49.1466136, lng: 21.3852502 },
    { lat: 49.1464523, lng: 21.3852016 },
    { lat: 49.1461892, lng: 21.3852836 },
    { lat: 49.1459095, lng: 21.385218 },
    { lat: 49.1457113, lng: 21.3849099 },
    { lat: 49.1451795, lng: 21.3848885 },
    { lat: 49.1442254, lng: 21.3854187 },
    { lat: 49.1442012, lng: 21.385324 },
    { lat: 49.1425482, lng: 21.3860045 },
    { lat: 49.1425895, lng: 21.3862608 },
    { lat: 49.1402169, lng: 21.3871881 },
    { lat: 49.1398321, lng: 21.3872659 },
    { lat: 49.1398536, lng: 21.3894008 },
    { lat: 49.1395631, lng: 21.3893607 },
    { lat: 49.1395303, lng: 21.3892393 },
    { lat: 49.1395139, lng: 21.3905143 },
    { lat: 49.1392056, lng: 21.3921148 },
    { lat: 49.1389933, lng: 21.3927355 },
    { lat: 49.1388108, lng: 21.3924246 },
    { lat: 49.138459, lng: 21.3908331 },
    { lat: 49.1380633, lng: 21.3901577 },
    { lat: 49.137153, lng: 21.3891668 },
    { lat: 49.1356928, lng: 21.3880329 },
    { lat: 49.1353801, lng: 21.3876864 },
    { lat: 49.1350051, lng: 21.3869959 },
    { lat: 49.1344173, lng: 21.3865954 },
    { lat: 49.1335154, lng: 21.3856597 },
    { lat: 49.1331091, lng: 21.3856157 },
    { lat: 49.1325397, lng: 21.386032 },
    { lat: 49.1324228, lng: 21.3859632 },
    { lat: 49.1321682, lng: 21.3863306 },
    { lat: 49.1317469, lng: 21.386164 },
    { lat: 49.1315476, lng: 21.3861525 },
    { lat: 49.1313144, lng: 21.3859939 },
    { lat: 49.1308861, lng: 21.3858918 },
    { lat: 49.1305931, lng: 21.3859194 },
    { lat: 49.1306125, lng: 21.385811 },
    { lat: 49.1299062, lng: 21.3857495 },
    { lat: 49.1297973, lng: 21.3858694 },
    { lat: 49.1295911, lng: 21.3857779 },
    { lat: 49.129086, lng: 21.386367 },
    { lat: 49.128899, lng: 21.386586 },
    { lat: 49.12889, lng: 21.386709 },
    { lat: 49.128886, lng: 21.386804 },
    { lat: 49.128819, lng: 21.387058 },
    { lat: 49.128606, lng: 21.387296 },
    { lat: 49.128415, lng: 21.387763 },
    { lat: 49.12881, lng: 21.388344 },
    { lat: 49.128684, lng: 21.389204 },
    { lat: 49.128681, lng: 21.389635 },
    { lat: 49.128669, lng: 21.390442 },
    { lat: 49.128693, lng: 21.391132 },
    { lat: 49.128771, lng: 21.391178 },
    { lat: 49.128752, lng: 21.394188 },
    { lat: 49.129128, lng: 21.394115 },
    { lat: 49.129209, lng: 21.395073 },
    { lat: 49.129051, lng: 21.397303 },
    { lat: 49.129262, lng: 21.398155 },
    { lat: 49.129473, lng: 21.399008 },
    { lat: 49.129679, lng: 21.39897 },
    { lat: 49.129826, lng: 21.398898 },
    { lat: 49.130039, lng: 21.400834 },
    { lat: 49.1306, lng: 21.401021 },
    { lat: 49.130472, lng: 21.401508 },
    { lat: 49.13071, lng: 21.401578 },
    { lat: 49.130451, lng: 21.402713 },
    { lat: 49.130712, lng: 21.402874 },
    { lat: 49.130048, lng: 21.404792 },
    { lat: 49.130221, lng: 21.404869 },
    { lat: 49.129596, lng: 21.406943 },
    { lat: 49.129388, lng: 21.407635 },
    { lat: 49.12904, lng: 21.411916 },
    { lat: 49.129262, lng: 21.411995 },
    { lat: 49.129144, lng: 21.413185 },
    { lat: 49.128839, lng: 21.413141 },
    { lat: 49.128974, lng: 21.41435 },
    { lat: 49.129077, lng: 21.414955 },
    { lat: 49.128807, lng: 21.415137 },
    { lat: 49.129486, lng: 21.417346 },
    { lat: 49.129527, lng: 21.41748 },
    { lat: 49.129553, lng: 21.417564 },
    { lat: 49.129626, lng: 21.4178 },
    { lat: 49.129768, lng: 21.418172 },
    { lat: 49.129701, lng: 21.418255 },
    { lat: 49.130069, lng: 21.419067 },
    { lat: 49.130227, lng: 21.419563 },
    { lat: 49.130407, lng: 21.420322 },
    { lat: 49.130877, lng: 21.421704 },
    { lat: 49.131124, lng: 21.422453 },
    { lat: 49.131148, lng: 21.422431 },
    { lat: 49.131312, lng: 21.422801 },
    { lat: 49.131436, lng: 21.423164 },
    { lat: 49.130378, lng: 21.424334 },
    { lat: 49.130915, lng: 21.425476 },
    { lat: 49.131382, lng: 21.425929 },
    { lat: 49.132395, lng: 21.427168 },
    { lat: 49.132424, lng: 21.427203 },
    { lat: 49.132381, lng: 21.427467 },
    { lat: 49.132408, lng: 21.427889 },
    { lat: 49.132731, lng: 21.428663 },
    { lat: 49.132984, lng: 21.429174 },
    { lat: 49.133123, lng: 21.42938 },
    { lat: 49.133346, lng: 21.429859 },
    { lat: 49.133685, lng: 21.4303 },
    { lat: 49.134247, lng: 21.431314 },
    { lat: 49.134423, lng: 21.431743 },
    { lat: 49.1345432, lng: 21.4321127 },
    { lat: 49.134918, lng: 21.43307 },
    { lat: 49.135301, lng: 21.434025 },
    { lat: 49.135572, lng: 21.434859 },
    { lat: 49.13583, lng: 21.435999 },
    { lat: 49.136128, lng: 21.436738 },
    { lat: 49.137393, lng: 21.439232 },
    { lat: 49.13742, lng: 21.43929 },
    { lat: 49.13776, lng: 21.43895 },
    { lat: 49.137755, lng: 21.438768 },
    { lat: 49.137718, lng: 21.43852 },
    { lat: 49.137873, lng: 21.438417 },
    { lat: 49.13808, lng: 21.438625 },
    { lat: 49.138209, lng: 21.438518 },
    { lat: 49.13827, lng: 21.438381 },
    { lat: 49.138269, lng: 21.437898 },
    { lat: 49.138342, lng: 21.437648 },
    { lat: 49.138456, lng: 21.437665 },
    { lat: 49.138558, lng: 21.437808 },
    { lat: 49.138744, lng: 21.437571 },
    { lat: 49.138913, lng: 21.437167 },
    { lat: 49.13905, lng: 21.437182 },
    { lat: 49.139274, lng: 21.436964 },
    { lat: 49.139462, lng: 21.436474 },
    { lat: 49.13958, lng: 21.436466 },
    { lat: 49.139877, lng: 21.436586 },
    { lat: 49.139943, lng: 21.436566 },
    { lat: 49.139959, lng: 21.436563 },
    { lat: 49.13998, lng: 21.4363109 },
    { lat: 49.1402979, lng: 21.4356825 },
    { lat: 49.1405387, lng: 21.4356058 },
    { lat: 49.1406936, lng: 21.4351663 },
    { lat: 49.1408794, lng: 21.4351344 },
    { lat: 49.1411096, lng: 21.4346125 },
    { lat: 49.1411874, lng: 21.4345792 },
    { lat: 49.1413577, lng: 21.4348674 },
    { lat: 49.1413952, lng: 21.4343729 },
    { lat: 49.141571, lng: 21.4343865 },
    { lat: 49.1416179, lng: 21.4342554 },
    { lat: 49.1415494, lng: 21.4340653 },
    { lat: 49.1415796, lng: 21.4339842 },
    { lat: 49.1417667, lng: 21.4339557 },
    { lat: 49.1418596, lng: 21.4338675 },
    { lat: 49.1417758, lng: 21.4337679 },
    { lat: 49.1418328, lng: 21.4336745 },
    { lat: 49.1418133, lng: 21.433314 },
    { lat: 49.1419339, lng: 21.4333259 },
    { lat: 49.1421856, lng: 21.4331943 },
    { lat: 49.1420905, lng: 21.4329078 },
    { lat: 49.1421034, lng: 21.4326924 },
    { lat: 49.1423136, lng: 21.4325675 },
    { lat: 49.1424293, lng: 21.4321371 },
    { lat: 49.1425176, lng: 21.4321588 },
    { lat: 49.1426963, lng: 21.4319149 },
    { lat: 49.1428951, lng: 21.4319072 },
    { lat: 49.1429905, lng: 21.4314771 },
    { lat: 49.1431273, lng: 21.4314193 },
    { lat: 49.1432382, lng: 21.43152 },
    { lat: 49.1433286, lng: 21.4313798 },
    { lat: 49.1433254, lng: 21.4312287 },
    { lat: 49.1438111, lng: 21.4312548 },
    { lat: 49.1439353, lng: 21.43096 },
    { lat: 49.14391, lng: 21.4307489 },
    { lat: 49.1442732, lng: 21.4304692 },
    { lat: 49.1442595, lng: 21.4306933 },
    { lat: 49.1442999, lng: 21.4307279 },
    { lat: 49.1444686, lng: 21.4305304 },
    { lat: 49.1448404, lng: 21.4306274 },
    { lat: 49.145023, lng: 21.4305992 },
    { lat: 49.1451683, lng: 21.4304895 },
    { lat: 49.1451917, lng: 21.4303693 },
    { lat: 49.1454101, lng: 21.4304868 },
    { lat: 49.1455367, lng: 21.4304282 },
    { lat: 49.145862, lng: 21.4299739 },
    { lat: 49.1458448, lng: 21.429804 },
    { lat: 49.1459681, lng: 21.4296321 },
    { lat: 49.146093, lng: 21.4295582 },
    { lat: 49.1461766, lng: 21.4296838 },
    { lat: 49.1462807, lng: 21.4295513 },
    { lat: 49.1465992, lng: 21.4296731 },
    { lat: 49.1467395, lng: 21.4294706 },
    { lat: 49.1470397, lng: 21.429386 },
    { lat: 49.1471759, lng: 21.4294238 },
    { lat: 49.1472655, lng: 21.4292212 },
    { lat: 49.1473637, lng: 21.4294187 },
    { lat: 49.1475176, lng: 21.4293752 },
    { lat: 49.1476243, lng: 21.429447 },
    { lat: 49.1477038, lng: 21.4293173 },
    { lat: 49.1476277, lng: 21.4292948 },
    { lat: 49.1475926, lng: 21.4292058 },
    { lat: 49.1477671, lng: 21.4289823 },
    { lat: 49.1478795, lng: 21.4286311 },
    { lat: 49.1480504, lng: 21.4283949 },
    { lat: 49.1481095, lng: 21.428029 },
    { lat: 49.1483068, lng: 21.4275362 },
    { lat: 49.1483849, lng: 21.42694 },
    { lat: 49.1485017, lng: 21.4267722 },
    { lat: 49.1487933, lng: 21.4260527 },
    { lat: 49.1490256, lng: 21.4251384 },
    { lat: 49.1492577, lng: 21.4248459 },
    { lat: 49.1492451, lng: 21.4246052 },
    { lat: 49.149507, lng: 21.4239819 },
    { lat: 49.1497198, lng: 21.4239894 },
    { lat: 49.1498398, lng: 21.4237041 },
    { lat: 49.1512606, lng: 21.4246217 },
    { lat: 49.150603, lng: 21.4226312 },
    { lat: 49.1507067, lng: 21.4217565 },
    { lat: 49.150858, lng: 21.4213531 },
    { lat: 49.1505203, lng: 21.4203412 },
    { lat: 49.1504708, lng: 21.4199607 },
    { lat: 49.1500696, lng: 21.4183986 },
    { lat: 49.1499326, lng: 21.4182124 },
    { lat: 49.1498434, lng: 21.417928 },
    { lat: 49.1502387, lng: 21.4177164 },
    { lat: 49.150411, lng: 21.4173331 },
    { lat: 49.1518144, lng: 21.4155415 },
    { lat: 49.1524932, lng: 21.4149372 },
    { lat: 49.1529212, lng: 21.4143772 },
    { lat: 49.1534815, lng: 21.4131798 },
    { lat: 49.1535754, lng: 21.4126292 },
    { lat: 49.1533937, lng: 21.412605 },
    { lat: 49.1531892, lng: 21.4124593 },
    { lat: 49.1529209, lng: 21.4117493 },
    { lat: 49.1524429, lng: 21.4107981 },
    { lat: 49.152463, lng: 21.4105649 },
    { lat: 49.1517147, lng: 21.4098283 },
    { lat: 49.15134, lng: 21.4093348 },
    { lat: 49.1508231, lng: 21.4083183 },
    { lat: 49.1508502, lng: 21.4079728 },
    { lat: 49.1512579, lng: 21.4066475 },
    { lat: 49.1519538, lng: 21.4036717 },
    { lat: 49.1521522, lng: 21.4005059 },
    { lat: 49.1521162, lng: 21.3992827 },
    { lat: 49.1520333, lng: 21.3990698 },
    { lat: 49.1521699, lng: 21.3986633 },
    { lat: 49.1525104, lng: 21.3982651 },
    { lat: 49.15285, lng: 21.3973458 },
    { lat: 49.1528173, lng: 21.3966907 },
    { lat: 49.1531121, lng: 21.3961166 },
    { lat: 49.1530928, lng: 21.3953095 },
    { lat: 49.1533561, lng: 21.3945471 },
    { lat: 49.1533137, lng: 21.394211 },
    { lat: 49.1533668, lng: 21.393917 },
    { lat: 49.1534953, lng: 21.3937723 },
    { lat: 49.1536305, lng: 21.3932411 },
    { lat: 49.152893, lng: 21.3924228 },
    { lat: 49.1528128, lng: 21.3923345 },
    { lat: 49.1532129, lng: 21.3916299 },
    { lat: 49.1538792, lng: 21.389654 },
    { lat: 49.1529277, lng: 21.3887544 },
    { lat: 49.1533176, lng: 21.38809 },
    { lat: 49.152467, lng: 21.3870517 },
    { lat: 49.1516938, lng: 21.3859448 },
    { lat: 49.150054, lng: 21.3839424 },
    { lat: 49.1487542, lng: 21.3824501 },
    { lat: 49.148385, lng: 21.3829769 },
    { lat: 49.147841, lng: 21.3839498 },
    { lat: 49.1470891, lng: 21.3846285 },
  ],
  Andrejová: [
    { lat: 49.3219401, lng: 21.3133694 },
    { lat: 49.3216084, lng: 21.3138966 },
    { lat: 49.3215165, lng: 21.3141742 },
    { lat: 49.321228, lng: 21.3160689 },
    { lat: 49.3211071, lng: 21.3174768 },
    { lat: 49.32113, lng: 21.3179426 },
    { lat: 49.3209012, lng: 21.3186782 },
    { lat: 49.3207679, lng: 21.3187702 },
    { lat: 49.3206915, lng: 21.318966 },
    { lat: 49.3207488, lng: 21.3192663 },
    { lat: 49.3206778, lng: 21.3194838 },
    { lat: 49.3204009, lng: 21.3199684 },
    { lat: 49.3204207, lng: 21.3205895 },
    { lat: 49.3203791, lng: 21.3208925 },
    { lat: 49.3204269, lng: 21.3210555 },
    { lat: 49.32028, lng: 21.3219768 },
    { lat: 49.3202385, lng: 21.3226621 },
    { lat: 49.3201727, lng: 21.3228654 },
    { lat: 49.3201526, lng: 21.3235867 },
    { lat: 49.3203276, lng: 21.3257074 },
    { lat: 49.3202621, lng: 21.3263013 },
    { lat: 49.3203258, lng: 21.3265883 },
    { lat: 49.3201532, lng: 21.3274064 },
    { lat: 49.3202215, lng: 21.3279563 },
    { lat: 49.3201529, lng: 21.3294615 },
    { lat: 49.3200979, lng: 21.3298776 },
    { lat: 49.3198368, lng: 21.330893 },
    { lat: 49.3196517, lng: 21.330986 },
    { lat: 49.3193267, lng: 21.3322754 },
    { lat: 49.3195067, lng: 21.3322736 },
    { lat: 49.3196418, lng: 21.3337486 },
    { lat: 49.3194364, lng: 21.3341042 },
    { lat: 49.3193709, lng: 21.3348077 },
    { lat: 49.3194715, lng: 21.3349377 },
    { lat: 49.3193949, lng: 21.336253 },
    { lat: 49.3193459, lng: 21.336428 },
    { lat: 49.3194053, lng: 21.3369093 },
    { lat: 49.3193699, lng: 21.3369645 },
    { lat: 49.3193089, lng: 21.337637 },
    { lat: 49.3193517, lng: 21.338162 },
    { lat: 49.3195022, lng: 21.3385706 },
    { lat: 49.3194802, lng: 21.3390428 },
    { lat: 49.3195231, lng: 21.3395011 },
    { lat: 49.3193166, lng: 21.340235 },
    { lat: 49.3192202, lng: 21.3411556 },
    { lat: 49.319005, lng: 21.3418212 },
    { lat: 49.3189653, lng: 21.3421525 },
    { lat: 49.3188621, lng: 21.3424627 },
    { lat: 49.3187236, lng: 21.3435185 },
    { lat: 49.3188008, lng: 21.344713 },
    { lat: 49.3190431, lng: 21.3454059 },
    { lat: 49.3206212, lng: 21.3475516 },
    { lat: 49.3216515, lng: 21.3487906 },
    { lat: 49.3225115, lng: 21.3503213 },
    { lat: 49.322912, lng: 21.3515656 },
    { lat: 49.3235107, lng: 21.353807 },
    { lat: 49.3245667, lng: 21.3562267 },
    { lat: 49.3252648, lng: 21.3574587 },
    { lat: 49.3254644, lng: 21.358035 },
    { lat: 49.3251187, lng: 21.3591317 },
    { lat: 49.3245041, lng: 21.3599909 },
    { lat: 49.3241738, lng: 21.360579 },
    { lat: 49.3236567, lng: 21.362266 },
    { lat: 49.3237239, lng: 21.3627908 },
    { lat: 49.3239658, lng: 21.3627124 },
    { lat: 49.3257386, lng: 21.3621264 },
    { lat: 49.3263704, lng: 21.3623009 },
    { lat: 49.3275803, lng: 21.3631231 },
    { lat: 49.3289427, lng: 21.3631535 },
    { lat: 49.3304218, lng: 21.3629125 },
    { lat: 49.3319233, lng: 21.3637009 },
    { lat: 49.3330164, lng: 21.3639524 },
    { lat: 49.3349975, lng: 21.3633321 },
    { lat: 49.336289, lng: 21.3623053 },
    { lat: 49.337474, lng: 21.361064 },
    { lat: 49.3378201, lng: 21.360817 },
    { lat: 49.338583, lng: 21.3598704 },
    { lat: 49.3393547, lng: 21.3591884 },
    { lat: 49.3402405, lng: 21.35795 },
    { lat: 49.3422508, lng: 21.3558181 },
    { lat: 49.3428469, lng: 21.3548999 },
    { lat: 49.3429309, lng: 21.3536298 },
    { lat: 49.3443655, lng: 21.3532955 },
    { lat: 49.344666, lng: 21.3533002 },
    { lat: 49.3454998, lng: 21.3530355 },
    { lat: 49.3464269, lng: 21.3530014 },
    { lat: 49.3479189, lng: 21.3531542 },
    { lat: 49.3534791, lng: 21.3534299 },
    { lat: 49.3551089, lng: 21.3538699 },
    { lat: 49.3562975, lng: 21.3540002 },
    { lat: 49.3564785, lng: 21.3538891 },
    { lat: 49.3570024, lng: 21.3539833 },
    { lat: 49.3570509, lng: 21.3538775 },
    { lat: 49.3569776, lng: 21.3530376 },
    { lat: 49.3570687, lng: 21.3523481 },
    { lat: 49.3568091, lng: 21.3508864 },
    { lat: 49.3568684, lng: 21.349889 },
    { lat: 49.3568452, lng: 21.349521 },
    { lat: 49.3566748, lng: 21.3489366 },
    { lat: 49.3565825, lng: 21.3483681 },
    { lat: 49.3566849, lng: 21.3473475 },
    { lat: 49.3565615, lng: 21.3461037 },
    { lat: 49.3566204, lng: 21.345367 },
    { lat: 49.3560235, lng: 21.3422047 },
    { lat: 49.3557917, lng: 21.341782 },
    { lat: 49.3555529, lng: 21.3407925 },
    { lat: 49.3554601, lng: 21.3401302 },
    { lat: 49.3554366, lng: 21.3388127 },
    { lat: 49.3552676, lng: 21.337933 },
    { lat: 49.3551276, lng: 21.3366778 },
    { lat: 49.3550317, lng: 21.3351539 },
    { lat: 49.3545978, lng: 21.3339939 },
    { lat: 49.354087, lng: 21.3332078 },
    { lat: 49.3532957, lng: 21.3325482 },
    { lat: 49.3523443, lng: 21.3323791 },
    { lat: 49.3519822, lng: 21.3320472 },
    { lat: 49.3516319, lng: 21.3315426 },
    { lat: 49.3509789, lng: 21.3310729 },
    { lat: 49.349484, lng: 21.3291242 },
    { lat: 49.3493605, lng: 21.3288715 },
    { lat: 49.3495468, lng: 21.3282107 },
    { lat: 49.3496563, lng: 21.3274337 },
    { lat: 49.3496124, lng: 21.3270604 },
    { lat: 49.3493923, lng: 21.3265431 },
    { lat: 49.3493348, lng: 21.3258655 },
    { lat: 49.3494011, lng: 21.324622 },
    { lat: 49.3493295, lng: 21.3234267 },
    { lat: 49.3495336, lng: 21.3222825 },
    { lat: 49.349514, lng: 21.3217783 },
    { lat: 49.3493854, lng: 21.3209403 },
    { lat: 49.3494355, lng: 21.3206211 },
    { lat: 49.3493903, lng: 21.3201248 },
    { lat: 49.3494286, lng: 21.3194527 },
    { lat: 49.3496189, lng: 21.3178028 },
    { lat: 49.3495161, lng: 21.3174874 },
    { lat: 49.3495522, lng: 21.3172293 },
    { lat: 49.3494959, lng: 21.3161362 },
    { lat: 49.3492871, lng: 21.3152519 },
    { lat: 49.3492394, lng: 21.3146757 },
    { lat: 49.3490843, lng: 21.3140854 },
    { lat: 49.3488438, lng: 21.313639 },
    { lat: 49.3487735, lng: 21.3133614 },
    { lat: 49.3486571, lng: 21.3131595 },
    { lat: 49.348521, lng: 21.3121431 },
    { lat: 49.3485809, lng: 21.3114056 },
    { lat: 49.3478567, lng: 21.309697 },
    { lat: 49.3472486, lng: 21.308964 },
    { lat: 49.3471379, lng: 21.3085376 },
    { lat: 49.3470386, lng: 21.3086045 },
    { lat: 49.3464212, lng: 21.3095895 },
    { lat: 49.3449463, lng: 21.3098889 },
    { lat: 49.344572, lng: 21.3101249 },
    { lat: 49.3436078, lng: 21.3105373 },
    { lat: 49.3431936, lng: 21.3105711 },
    { lat: 49.3423804, lng: 21.3108434 },
    { lat: 49.3415219, lng: 21.3106924 },
    { lat: 49.3400811, lng: 21.3108139 },
    { lat: 49.339058, lng: 21.3111392 },
    { lat: 49.3389095, lng: 21.311063 },
    { lat: 49.3385933, lng: 21.3112098 },
    { lat: 49.3374591, lng: 21.3120692 },
    { lat: 49.3371869, lng: 21.312369 },
    { lat: 49.3368916, lng: 21.3128065 },
    { lat: 49.3367009, lng: 21.3136858 },
    { lat: 49.3365559, lng: 21.3141029 },
    { lat: 49.3362688, lng: 21.3142873 },
    { lat: 49.3350548, lng: 21.3155584 },
    { lat: 49.3345215, lng: 21.3160454 },
    { lat: 49.3340871, lng: 21.3163454 },
    { lat: 49.3338828, lng: 21.3165878 },
    { lat: 49.3331039, lng: 21.3169094 },
    { lat: 49.332858, lng: 21.316926 },
    { lat: 49.3323999, lng: 21.3167698 },
    { lat: 49.3321686, lng: 21.3169336 },
    { lat: 49.3317667, lng: 21.3174539 },
    { lat: 49.331292, lng: 21.3177195 },
    { lat: 49.3294195, lng: 21.318158 },
    { lat: 49.3292958, lng: 21.3180505 },
    { lat: 49.3289359, lng: 21.3179416 },
    { lat: 49.3287407, lng: 21.3178085 },
    { lat: 49.3281834, lng: 21.3176456 },
    { lat: 49.3269692, lng: 21.3174502 },
    { lat: 49.3262902, lng: 21.3171353 },
    { lat: 49.3251302, lng: 21.3162692 },
    { lat: 49.3245263, lng: 21.3159816 },
    { lat: 49.3237752, lng: 21.3153562 },
    { lat: 49.3236069, lng: 21.3151123 },
    { lat: 49.3233896, lng: 21.3150688 },
    { lat: 49.3233105, lng: 21.3148677 },
    { lat: 49.3230848, lng: 21.3145827 },
    { lat: 49.3228483, lng: 21.314613 },
    { lat: 49.3225595, lng: 21.3144014 },
    { lat: 49.322308, lng: 21.3141522 },
    { lat: 49.3219401, lng: 21.3133694 },
  ],
  Lascov: [
    { lat: 49.147628, lng: 21.483512 },
    { lat: 49.1504312, lng: 21.4869626 },
    { lat: 49.1524423, lng: 21.4917775 },
    { lat: 49.1538273, lng: 21.4941803 },
    { lat: 49.1541682, lng: 21.4945764 },
    { lat: 49.1542891, lng: 21.4947908 },
    { lat: 49.1545531, lng: 21.4950109 },
    { lat: 49.1552607, lng: 21.4952888 },
    { lat: 49.1552009, lng: 21.4955149 },
    { lat: 49.1557248, lng: 21.4957781 },
    { lat: 49.1570296, lng: 21.4966753 },
    { lat: 49.1569689, lng: 21.4969514 },
    { lat: 49.1567904, lng: 21.4972383 },
    { lat: 49.1566246, lng: 21.4977011 },
    { lat: 49.1574886, lng: 21.4979691 },
    { lat: 49.1571636, lng: 21.4985697 },
    { lat: 49.1571926, lng: 21.4987572 },
    { lat: 49.1579007, lng: 21.4991686 },
    { lat: 49.1581214, lng: 21.4991758 },
    { lat: 49.158354, lng: 21.499317 },
    { lat: 49.158436, lng: 21.499356 },
    { lat: 49.15857, lng: 21.49932 },
    { lat: 49.158696, lng: 21.499325 },
    { lat: 49.15873, lng: 21.499313 },
    { lat: 49.15879, lng: 21.499134 },
    { lat: 49.158913, lng: 21.498899 },
    { lat: 49.159087, lng: 21.498771 },
    { lat: 49.159305, lng: 21.498647 },
    { lat: 49.159427, lng: 21.498533 },
    { lat: 49.159552, lng: 21.498556 },
    { lat: 49.159753, lng: 21.498587 },
    { lat: 49.159899, lng: 21.498571 },
    { lat: 49.160014, lng: 21.498601 },
    { lat: 49.160217, lng: 21.498378 },
    { lat: 49.160248, lng: 21.49815 },
    { lat: 49.160415, lng: 21.498032 },
    { lat: 49.160466, lng: 21.497981 },
    { lat: 49.160757, lng: 21.497855 },
    { lat: 49.160934, lng: 21.497745 },
    { lat: 49.161031, lng: 21.497601 },
    { lat: 49.161216, lng: 21.49751 },
    { lat: 49.161385, lng: 21.497324 },
    { lat: 49.161707, lng: 21.497248 },
    { lat: 49.161963, lng: 21.497379 },
    { lat: 49.162189, lng: 21.497436 },
    { lat: 49.162475, lng: 21.497486 },
    { lat: 49.162657, lng: 21.497488 },
    { lat: 49.162821, lng: 21.497786 },
    { lat: 49.16308, lng: 21.497711 },
    { lat: 49.16323, lng: 21.497622 },
    { lat: 49.163361, lng: 21.497472 },
    { lat: 49.163453, lng: 21.497401 },
    { lat: 49.163464, lng: 21.497392 },
    { lat: 49.163519, lng: 21.497349 },
    { lat: 49.163743, lng: 21.497355 },
    { lat: 49.163924, lng: 21.49746 },
    { lat: 49.164035, lng: 21.497411 },
    { lat: 49.164166, lng: 21.497516 },
    { lat: 49.164352, lng: 21.497487 },
    { lat: 49.164518, lng: 21.497527 },
    { lat: 49.164667, lng: 21.497493 },
    { lat: 49.16491, lng: 21.497546 },
    { lat: 49.165313, lng: 21.497479 },
    { lat: 49.165585, lng: 21.497525 },
    { lat: 49.165634, lng: 21.497862 },
    { lat: 49.165564, lng: 21.498239 },
    { lat: 49.165739, lng: 21.498874 },
    { lat: 49.165812, lng: 21.498884 },
    { lat: 49.166013, lng: 21.499769 },
    { lat: 49.166098, lng: 21.499894 },
    { lat: 49.16621, lng: 21.499858 },
    { lat: 49.166414, lng: 21.500668 },
    { lat: 49.166695, lng: 21.500809 },
    { lat: 49.16674, lng: 21.500832 },
    { lat: 49.167612, lng: 21.501269 },
    { lat: 49.167954, lng: 21.501437 },
    { lat: 49.168052, lng: 21.501442 },
    { lat: 49.168246, lng: 21.501452 },
    { lat: 49.170135, lng: 21.500921 },
    { lat: 49.171203, lng: 21.501448 },
    { lat: 49.171297, lng: 21.501242 },
    { lat: 49.171653, lng: 21.501493 },
    { lat: 49.172762, lng: 21.501904 },
    { lat: 49.172855, lng: 21.502416 },
    { lat: 49.173054, lng: 21.502949 },
    { lat: 49.173386, lng: 21.503544 },
    { lat: 49.173496, lng: 21.50401 },
    { lat: 49.173575, lng: 21.504204 },
    { lat: 49.173717, lng: 21.504337 },
    { lat: 49.173919, lng: 21.50452 },
    { lat: 49.174423, lng: 21.504731 },
    { lat: 49.174706, lng: 21.504353 },
    { lat: 49.174725, lng: 21.504342 },
    { lat: 49.174782, lng: 21.503922 },
    { lat: 49.174757, lng: 21.503627 },
    { lat: 49.174769, lng: 21.503389 },
    { lat: 49.174868, lng: 21.503248 },
    { lat: 49.174886, lng: 21.503239 },
    { lat: 49.174883, lng: 21.503201 },
    { lat: 49.174747, lng: 21.502933 },
    { lat: 49.174689, lng: 21.502765 },
    { lat: 49.174619, lng: 21.502497 },
    { lat: 49.17463, lng: 21.502403 },
    { lat: 49.174686, lng: 21.502195 },
    { lat: 49.174671, lng: 21.502095 },
    { lat: 49.174683, lng: 21.502045 },
    { lat: 49.17476, lng: 21.501965 },
    { lat: 49.174753, lng: 21.501886 },
    { lat: 49.17472, lng: 21.501844 },
    { lat: 49.174685, lng: 21.50181 },
    { lat: 49.174644, lng: 21.501765 },
    { lat: 49.1746, lng: 21.501634 },
    { lat: 49.174529, lng: 21.501416 },
    { lat: 49.174492, lng: 21.501296 },
    { lat: 49.174545, lng: 21.500705 },
    { lat: 49.174538, lng: 21.500675 },
    { lat: 49.174405, lng: 21.500376 },
    { lat: 49.174197, lng: 21.500103 },
    { lat: 49.173902, lng: 21.499753 },
    { lat: 49.173767, lng: 21.499652 },
    { lat: 49.173645, lng: 21.499603 },
    { lat: 49.173483, lng: 21.499176 },
    { lat: 49.173486, lng: 21.499147 },
    { lat: 49.173488, lng: 21.499129 },
    { lat: 49.17349, lng: 21.499108 },
    { lat: 49.17349, lng: 21.498975 },
    { lat: 49.173542, lng: 21.498764 },
    { lat: 49.173609, lng: 21.498226 },
    { lat: 49.173441, lng: 21.498086 },
    { lat: 49.173022, lng: 21.497966 },
    { lat: 49.172759, lng: 21.498263 },
    { lat: 49.172745, lng: 21.498281 },
    { lat: 49.172571, lng: 21.498094 },
    { lat: 49.172227, lng: 21.497932 },
    { lat: 49.172072, lng: 21.497829 },
    { lat: 49.172057, lng: 21.497802 },
    { lat: 49.172036, lng: 21.497833 },
    { lat: 49.172015, lng: 21.497858 },
    { lat: 49.171864, lng: 21.4976 },
    { lat: 49.171788, lng: 21.497395 },
    { lat: 49.171765, lng: 21.497235 },
    { lat: 49.171826, lng: 21.49713 },
    { lat: 49.171844, lng: 21.497105 },
    { lat: 49.171784, lng: 21.496856 },
    { lat: 49.171604, lng: 21.496591 },
    { lat: 49.171319, lng: 21.496165 },
    { lat: 49.171227, lng: 21.495995 },
    { lat: 49.171213, lng: 21.495963 },
    { lat: 49.171172, lng: 21.495872 },
    { lat: 49.171106, lng: 21.495834 },
    { lat: 49.170974, lng: 21.495479 },
    { lat: 49.170916, lng: 21.495294 },
    { lat: 49.170905, lng: 21.495134 },
    { lat: 49.170905, lng: 21.494874 },
    { lat: 49.170897, lng: 21.494617 },
    { lat: 49.170854, lng: 21.494432 },
    { lat: 49.170726, lng: 21.49398 },
    { lat: 49.170781, lng: 21.493955 },
    { lat: 49.170748, lng: 21.493808 },
    { lat: 49.170722, lng: 21.493758 },
    { lat: 49.170642, lng: 21.493639 },
    { lat: 49.170651, lng: 21.4936 },
    { lat: 49.170711, lng: 21.493532 },
    { lat: 49.170726, lng: 21.493445 },
    { lat: 49.170668, lng: 21.493169 },
    { lat: 49.17079, lng: 21.492884 },
    { lat: 49.17083, lng: 21.492487 },
    { lat: 49.170848, lng: 21.492434 },
    { lat: 49.170846, lng: 21.492183 },
    { lat: 49.1709, lng: 21.492106 },
    { lat: 49.171081, lng: 21.492308 },
    { lat: 49.171133, lng: 21.492266 },
    { lat: 49.171204, lng: 21.491889 },
    { lat: 49.171225, lng: 21.491686 },
    { lat: 49.171225, lng: 21.491506 },
    { lat: 49.171153, lng: 21.490946 },
    { lat: 49.171231, lng: 21.490797 },
    { lat: 49.171262, lng: 21.490499 },
    { lat: 49.171381, lng: 21.490266 },
    { lat: 49.171402, lng: 21.490115 },
    { lat: 49.171417, lng: 21.490074 },
    { lat: 49.171525, lng: 21.489988 },
    { lat: 49.171556, lng: 21.489686 },
    { lat: 49.171677, lng: 21.48958 },
    { lat: 49.171715, lng: 21.489465 },
    { lat: 49.171725, lng: 21.489384 },
    { lat: 49.171807, lng: 21.489337 },
    { lat: 49.171885, lng: 21.489232 },
    { lat: 49.171867, lng: 21.489182 },
    { lat: 49.171765, lng: 21.489083 },
    { lat: 49.171762, lng: 21.489031 },
    { lat: 49.171941, lng: 21.488734 },
    { lat: 49.17222, lng: 21.488014 },
    { lat: 49.172237, lng: 21.488 },
    { lat: 49.17231, lng: 21.488014 },
    { lat: 49.172422, lng: 21.487479 },
    { lat: 49.172487, lng: 21.487353 },
    { lat: 49.172549, lng: 21.487119 },
    { lat: 49.172831, lng: 21.486547 },
    { lat: 49.172847, lng: 21.486543 },
    { lat: 49.172927, lng: 21.486518 },
    { lat: 49.172972, lng: 21.486335 },
    { lat: 49.172928, lng: 21.486214 },
    { lat: 49.172934, lng: 21.486183 },
    { lat: 49.173199, lng: 21.485789 },
    { lat: 49.173283, lng: 21.485735 },
    { lat: 49.173364, lng: 21.485592 },
    { lat: 49.173408, lng: 21.485261 },
    { lat: 49.173427, lng: 21.485239 },
    { lat: 49.173523, lng: 21.485243 },
    { lat: 49.173604, lng: 21.485126 },
    { lat: 49.173703, lng: 21.484954 },
    { lat: 49.173735, lng: 21.48487 },
    { lat: 49.173789, lng: 21.484609 },
    { lat: 49.17379, lng: 21.484563 },
    { lat: 49.173775, lng: 21.484534 },
    { lat: 49.173678, lng: 21.484476 },
    { lat: 49.173747, lng: 21.484166 },
    { lat: 49.173824, lng: 21.484048 },
    { lat: 49.173923, lng: 21.483755 },
    { lat: 49.174, lng: 21.483707 },
    { lat: 49.174086, lng: 21.483469 },
    { lat: 49.174062, lng: 21.483342 },
    { lat: 49.17409, lng: 21.483312 },
    { lat: 49.174151, lng: 21.483305 },
    { lat: 49.174189, lng: 21.483222 },
    { lat: 49.174166, lng: 21.483019 },
    { lat: 49.172383, lng: 21.4810184 },
    { lat: 49.1727928, lng: 21.4804456 },
    { lat: 49.1714357, lng: 21.4783126 },
    { lat: 49.1710688, lng: 21.4776047 },
    { lat: 49.1690893, lng: 21.4744525 },
    { lat: 49.1683868, lng: 21.4738138 },
    { lat: 49.1663193, lng: 21.4713983 },
    { lat: 49.1660283, lng: 21.4711808 },
    { lat: 49.1641623, lng: 21.4681121 },
    { lat: 49.1630431, lng: 21.4675709 },
    { lat: 49.1632925, lng: 21.4669312 },
    { lat: 49.161211, lng: 21.4649741 },
    { lat: 49.15842, lng: 21.463437 },
    { lat: 49.155467, lng: 21.463784 },
    { lat: 49.155279, lng: 21.463948 },
    { lat: 49.154896, lng: 21.465018 },
    { lat: 49.154665, lng: 21.465428 },
    { lat: 49.153959, lng: 21.466335 },
    { lat: 49.153429, lng: 21.467001 },
    { lat: 49.15309, lng: 21.467571 },
    { lat: 49.152609, lng: 21.468647 },
    { lat: 49.152186, lng: 21.469584 },
    { lat: 49.151724, lng: 21.470467 },
    { lat: 49.151334, lng: 21.471076 },
    { lat: 49.151137, lng: 21.47136 },
    { lat: 49.151254, lng: 21.471565 },
    { lat: 49.151642, lng: 21.47218 },
    { lat: 49.150659, lng: 21.473677 },
    { lat: 49.149998, lng: 21.472721 },
    { lat: 49.149871, lng: 21.472536 },
    { lat: 49.14982, lng: 21.472667 },
    { lat: 49.149428, lng: 21.474118 },
    { lat: 49.149121, lng: 21.475304 },
    { lat: 49.148822, lng: 21.476082 },
    { lat: 49.148264, lng: 21.477226 },
    { lat: 49.147492, lng: 21.478213 },
    { lat: 49.146706, lng: 21.479136 },
    { lat: 49.147011, lng: 21.479529 },
    { lat: 49.148239, lng: 21.481129 },
    { lat: 49.147925, lng: 21.482747 },
    { lat: 49.147628, lng: 21.483512 },
  ],
  VyšnáVoľa: [
    { lat: 49.2648309, lng: 21.3429729 },
    { lat: 49.2640634, lng: 21.342361 },
    { lat: 49.2638546, lng: 21.3423027 },
    { lat: 49.2635342, lng: 21.3423502 },
    { lat: 49.2626966, lng: 21.342258 },
    { lat: 49.262285, lng: 21.3421476 },
    { lat: 49.2618853, lng: 21.3419102 },
    { lat: 49.2609844, lng: 21.3407129 },
    { lat: 49.2595326, lng: 21.3390784 },
    { lat: 49.2585705, lng: 21.3382856 },
    { lat: 49.2560303, lng: 21.3384821 },
    { lat: 49.2547571, lng: 21.3388726 },
    { lat: 49.2533998, lng: 21.3396173 },
    { lat: 49.2525704, lng: 21.3390822 },
    { lat: 49.2519544, lng: 21.3397771 },
    { lat: 49.2504982, lng: 21.3408646 },
    { lat: 49.2497087, lng: 21.3412177 },
    { lat: 49.248755, lng: 21.3413014 },
    { lat: 49.2473664, lng: 21.3392189 },
    { lat: 49.2471825, lng: 21.3389036 },
    { lat: 49.2459194, lng: 21.3403856 },
    { lat: 49.2452888, lng: 21.3406723 },
    { lat: 49.2439879, lng: 21.3417978 },
    { lat: 49.243122, lng: 21.3432073 },
    { lat: 49.2420282, lng: 21.342977 },
    { lat: 49.2413368, lng: 21.3442364 },
    { lat: 49.2405968, lng: 21.3457981 },
    { lat: 49.2391286, lng: 21.345984 },
    { lat: 49.2373732, lng: 21.3482462 },
    { lat: 49.2362791, lng: 21.3492547 },
    { lat: 49.2352, lng: 21.3500382 },
    { lat: 49.2336049, lng: 21.3503192 },
    { lat: 49.2318307, lng: 21.353798 },
    { lat: 49.2310383, lng: 21.357384 },
    { lat: 49.231297, lng: 21.3598865 },
    { lat: 49.2318787, lng: 21.3623498 },
    { lat: 49.2319954, lng: 21.3631309 },
    { lat: 49.2319253, lng: 21.3643756 },
    { lat: 49.2320334, lng: 21.3660491 },
    { lat: 49.2320983, lng: 21.366266 },
    { lat: 49.2331398, lng: 21.3668605 },
    { lat: 49.2335829, lng: 21.3691708 },
    { lat: 49.2342759, lng: 21.3712219 },
    { lat: 49.2338766, lng: 21.3717582 },
    { lat: 49.2345867, lng: 21.3730975 },
    { lat: 49.2352341, lng: 21.3739505 },
    { lat: 49.2358826, lng: 21.3755594 },
    { lat: 49.2360708, lng: 21.3770939 },
    { lat: 49.236442, lng: 21.3790364 },
    { lat: 49.2369607, lng: 21.3829299 },
    { lat: 49.236411, lng: 21.383892 },
    { lat: 49.2384315, lng: 21.3880144 },
    { lat: 49.2399843, lng: 21.3916158 },
    { lat: 49.2406771, lng: 21.3931357 },
    { lat: 49.2416255, lng: 21.3920671 },
    { lat: 49.2441127, lng: 21.3894903 },
    { lat: 49.2451699, lng: 21.3882924 },
    { lat: 49.2496546, lng: 21.3824883 },
    { lat: 49.251442, lng: 21.3806067 },
    { lat: 49.2518052, lng: 21.3800282 },
    { lat: 49.2522274, lng: 21.378932 },
    { lat: 49.2530571, lng: 21.3776946 },
    { lat: 49.2535099, lng: 21.3765249 },
    { lat: 49.254082, lng: 21.3755151 },
    { lat: 49.2551773, lng: 21.3737497 },
    { lat: 49.255353, lng: 21.3732578 },
    { lat: 49.2553893, lng: 21.3729221 },
    { lat: 49.2555273, lng: 21.3726054 },
    { lat: 49.2560897, lng: 21.3719146 },
    { lat: 49.2565254, lng: 21.3711652 },
    { lat: 49.2566718, lng: 21.3704888 },
    { lat: 49.2569189, lng: 21.3701303 },
    { lat: 49.2572515, lng: 21.3692425 },
    { lat: 49.2574803, lng: 21.3681572 },
    { lat: 49.2578334, lng: 21.3660577 },
    { lat: 49.2585789, lng: 21.364905 },
    { lat: 49.2594041, lng: 21.3625995 },
    { lat: 49.2597532, lng: 21.3610552 },
    { lat: 49.2601326, lng: 21.3596968 },
    { lat: 49.2615894, lng: 21.355257 },
    { lat: 49.2624019, lng: 21.3524974 },
    { lat: 49.2640426, lng: 21.3456695 },
    { lat: 49.2648309, lng: 21.3429729 },
  ],
  Lopúchov: [
    { lat: 49.1470891, lng: 21.3846285 },
    { lat: 49.1470692, lng: 21.3839919 },
    { lat: 49.1471396, lng: 21.3832169 },
    { lat: 49.1474078, lng: 21.3827348 },
    { lat: 49.1475452, lng: 21.3814057 },
    { lat: 49.1476943, lng: 21.3804678 },
    { lat: 49.1479149, lng: 21.3799807 },
    { lat: 49.1481439, lng: 21.3775292 },
    { lat: 49.1484721, lng: 21.3760697 },
    { lat: 49.1484272, lng: 21.3751991 },
    { lat: 49.1482165, lng: 21.3737853 },
    { lat: 49.1478947, lng: 21.3738965 },
    { lat: 49.1471725, lng: 21.3708269 },
    { lat: 49.1464918, lng: 21.3693147 },
    { lat: 49.1456793, lng: 21.3676776 },
    { lat: 49.1469159, lng: 21.3674946 },
    { lat: 49.1478481, lng: 21.3660307 },
    { lat: 49.1479513, lng: 21.3642805 },
    { lat: 49.146428, lng: 21.3626152 },
    { lat: 49.1472679, lng: 21.3619537 },
    { lat: 49.1471399, lng: 21.3614702 },
    { lat: 49.1468174, lng: 21.3611869 },
    { lat: 49.146537, lng: 21.3600742 },
    { lat: 49.1462371, lng: 21.3596791 },
    { lat: 49.1457741, lng: 21.3584902 },
    { lat: 49.1461432, lng: 21.358566 },
    { lat: 49.1473542, lng: 21.3584629 },
    { lat: 49.1465608, lng: 21.3547504 },
    { lat: 49.1461201, lng: 21.3519309 },
    { lat: 49.1450981, lng: 21.35305 },
    { lat: 49.1437925, lng: 21.3541305 },
    { lat: 49.1433221, lng: 21.3520197 },
    { lat: 49.1426362, lng: 21.352943 },
    { lat: 49.1423807, lng: 21.3526369 },
    { lat: 49.1423005, lng: 21.3522486 },
    { lat: 49.1413993, lng: 21.3525147 },
    { lat: 49.1411536, lng: 21.3521217 },
    { lat: 49.1400726, lng: 21.3533796 },
    { lat: 49.1390979, lng: 21.3522716 },
    { lat: 49.1341273, lng: 21.3590112 },
    { lat: 49.133417, lng: 21.3589978 },
    { lat: 49.1331655, lng: 21.3559937 },
    { lat: 49.131196, lng: 21.3551825 },
    { lat: 49.1307193, lng: 21.3550811 },
    { lat: 49.1301871, lng: 21.355761 },
    { lat: 49.1293356, lng: 21.3558519 },
    { lat: 49.1288431, lng: 21.3557437 },
    { lat: 49.1278514, lng: 21.3565667 },
    { lat: 49.1251874, lng: 21.3560856 },
    { lat: 49.1242412, lng: 21.3563152 },
    { lat: 49.1234439, lng: 21.3563351 },
    { lat: 49.1233814, lng: 21.3561601 },
    { lat: 49.1230723, lng: 21.3561355 },
    { lat: 49.1170967, lng: 21.3496144 },
    { lat: 49.117106, lng: 21.349603 },
    { lat: 49.117024, lng: 21.34959 },
    { lat: 49.116928, lng: 21.349617 },
    { lat: 49.116795, lng: 21.349795 },
    { lat: 49.116712, lng: 21.349867 },
    { lat: 49.116583, lng: 21.349888 },
    { lat: 49.116334, lng: 21.350023 },
    { lat: 49.116291, lng: 21.350064 },
    { lat: 49.116113, lng: 21.350047 },
    { lat: 49.115764, lng: 21.350263 },
    { lat: 49.115228, lng: 21.350244 },
    { lat: 49.11504, lng: 21.35016 },
    { lat: 49.114894, lng: 21.350188 },
    { lat: 49.114813, lng: 21.350191 },
    { lat: 49.114623, lng: 21.350306 },
    { lat: 49.114294, lng: 21.350432 },
    { lat: 49.11414, lng: 21.3504 },
    { lat: 49.113967, lng: 21.350231 },
    { lat: 49.113929, lng: 21.350388 },
    { lat: 49.113467, lng: 21.351133 },
    { lat: 49.113746, lng: 21.35156 },
    { lat: 49.113598, lng: 21.35209 },
    { lat: 49.113558, lng: 21.352369 },
    { lat: 49.11312, lng: 21.353395 },
    { lat: 49.113191, lng: 21.353717 },
    { lat: 49.113335, lng: 21.354072 },
    { lat: 49.11344, lng: 21.35435 },
    { lat: 49.113431, lng: 21.354556 },
    { lat: 49.113261, lng: 21.354834 },
    { lat: 49.113272, lng: 21.355213 },
    { lat: 49.113177, lng: 21.355614 },
    { lat: 49.113072, lng: 21.355811 },
    { lat: 49.113052, lng: 21.355881 },
    { lat: 49.112883, lng: 21.355742 },
    { lat: 49.112748, lng: 21.355766 },
    { lat: 49.112731, lng: 21.35577 },
    { lat: 49.112928, lng: 21.356447 },
    { lat: 49.113012, lng: 21.356616 },
    { lat: 49.113018, lng: 21.356903 },
    { lat: 49.113091, lng: 21.35707 },
    { lat: 49.113298, lng: 21.357145 },
    { lat: 49.113387, lng: 21.357365 },
    { lat: 49.113425, lng: 21.357619 },
    { lat: 49.11347, lng: 21.357897 },
    { lat: 49.113452, lng: 21.35821 },
    { lat: 49.113524, lng: 21.358435 },
    { lat: 49.11367, lng: 21.358596 },
    { lat: 49.113769, lng: 21.358795 },
    { lat: 49.113777, lng: 21.359068 },
    { lat: 49.11375, lng: 21.359211 },
    { lat: 49.11379, lng: 21.359448 },
    { lat: 49.113986, lng: 21.359753 },
    { lat: 49.114013, lng: 21.36006 },
    { lat: 49.114116, lng: 21.360183 },
    { lat: 49.114162, lng: 21.360375 },
    { lat: 49.114162, lng: 21.360731 },
    { lat: 49.114161, lng: 21.360998 },
    { lat: 49.114102, lng: 21.361208 },
    { lat: 49.114107, lng: 21.361475 },
    { lat: 49.114039, lng: 21.361593 },
    { lat: 49.113954, lng: 21.361845 },
    { lat: 49.11399, lng: 21.362004 },
    { lat: 49.114025, lng: 21.362114 },
    { lat: 49.113967, lng: 21.362441 },
    { lat: 49.113913, lng: 21.36266 },
    { lat: 49.11406, lng: 21.362907 },
    { lat: 49.113872, lng: 21.36317 },
    { lat: 49.114033, lng: 21.363447 },
    { lat: 49.114151, lng: 21.363603 },
    { lat: 49.114181, lng: 21.363903 },
    { lat: 49.114321, lng: 21.363852 },
    { lat: 49.114438, lng: 21.364094 },
    { lat: 49.114583, lng: 21.364344 },
    { lat: 49.114703, lng: 21.364292 },
    { lat: 49.114759, lng: 21.36437 },
    { lat: 49.114736, lng: 21.364506 },
    { lat: 49.114866, lng: 21.364633 },
    { lat: 49.11492, lng: 21.364719 },
    { lat: 49.114875, lng: 21.364856 },
    { lat: 49.114809, lng: 21.365148 },
    { lat: 49.114845, lng: 21.365375 },
    { lat: 49.114899, lng: 21.365817 },
    { lat: 49.114998, lng: 21.365964 },
    { lat: 49.115033, lng: 21.366312 },
    { lat: 49.114896, lng: 21.366458 },
    { lat: 49.114818, lng: 21.366707 },
    { lat: 49.114932, lng: 21.36699 },
    { lat: 49.114909, lng: 21.367286 },
    { lat: 49.1149443, lng: 21.3673522 },
    { lat: 49.1149505, lng: 21.3676106 },
    { lat: 49.1150036, lng: 21.3677817 },
    { lat: 49.115052, lng: 21.3681853 },
    { lat: 49.1150933, lng: 21.3684507 },
    { lat: 49.1151559, lng: 21.3685966 },
    { lat: 49.1152048, lng: 21.3690381 },
    { lat: 49.1150858, lng: 21.3692311 },
    { lat: 49.115176, lng: 21.369345 },
    { lat: 49.115179, lng: 21.369361 },
    { lat: 49.1151649, lng: 21.3696103 },
    { lat: 49.115255, lng: 21.36975 },
    { lat: 49.115258, lng: 21.369822 },
    { lat: 49.115452, lng: 21.371053 },
    { lat: 49.1156722, lng: 21.3715287 },
    { lat: 49.1158521, lng: 21.3721432 },
    { lat: 49.1160994, lng: 21.372732 },
    { lat: 49.1162238, lng: 21.3732782 },
    { lat: 49.1163654, lng: 21.3741267 },
    { lat: 49.116417, lng: 21.374567 },
    { lat: 49.116445, lng: 21.374689 },
    { lat: 49.11634, lng: 21.376487 },
    { lat: 49.11613, lng: 21.377382 },
    { lat: 49.116126, lng: 21.377405 },
    { lat: 49.116792, lng: 21.377745 },
    { lat: 49.117361, lng: 21.377824 },
    { lat: 49.11875, lng: 21.377372 },
    { lat: 49.118788, lng: 21.37736 },
    { lat: 49.11884, lng: 21.377343 },
    { lat: 49.118843, lng: 21.377366 },
    { lat: 49.119006, lng: 21.377819 },
    { lat: 49.119182, lng: 21.378268 },
    { lat: 49.119375, lng: 21.378635 },
    { lat: 49.119505, lng: 21.379358 },
    { lat: 49.11955, lng: 21.379436 },
    { lat: 49.119578, lng: 21.379483 },
    { lat: 49.119736, lng: 21.379752 },
    { lat: 49.120147, lng: 21.379398 },
    { lat: 49.12026, lng: 21.379697 },
    { lat: 49.120765, lng: 21.381013 },
    { lat: 49.121183, lng: 21.381619 },
    { lat: 49.121054, lng: 21.38324 },
    { lat: 49.121223, lng: 21.383292 },
    { lat: 49.121038, lng: 21.384268 },
    { lat: 49.121474, lng: 21.384664 },
    { lat: 49.12144, lng: 21.384808 },
    { lat: 49.121814, lng: 21.385021 },
    { lat: 49.123012, lng: 21.385168 },
    { lat: 49.123454, lng: 21.384907 },
    { lat: 49.125501, lng: 21.385639 },
    { lat: 49.126726, lng: 21.385797 },
    { lat: 49.128282, lng: 21.385805 },
    { lat: 49.128492, lng: 21.385848 },
    { lat: 49.128513, lng: 21.385933 },
    { lat: 49.128664, lng: 21.38605 },
    { lat: 49.128731, lng: 21.386265 },
    { lat: 49.128863, lng: 21.386326 },
    { lat: 49.129086, lng: 21.386367 },
    { lat: 49.1295911, lng: 21.3857779 },
    { lat: 49.1297973, lng: 21.3858694 },
    { lat: 49.1299062, lng: 21.3857495 },
    { lat: 49.1306125, lng: 21.385811 },
    { lat: 49.1305931, lng: 21.3859194 },
    { lat: 49.1308861, lng: 21.3858918 },
    { lat: 49.1313144, lng: 21.3859939 },
    { lat: 49.1315476, lng: 21.3861525 },
    { lat: 49.1317469, lng: 21.386164 },
    { lat: 49.1321682, lng: 21.3863306 },
    { lat: 49.1324228, lng: 21.3859632 },
    { lat: 49.1325397, lng: 21.386032 },
    { lat: 49.1331091, lng: 21.3856157 },
    { lat: 49.1335154, lng: 21.3856597 },
    { lat: 49.1344173, lng: 21.3865954 },
    { lat: 49.1350051, lng: 21.3869959 },
    { lat: 49.1353801, lng: 21.3876864 },
    { lat: 49.1356928, lng: 21.3880329 },
    { lat: 49.137153, lng: 21.3891668 },
    { lat: 49.1380633, lng: 21.3901577 },
    { lat: 49.138459, lng: 21.3908331 },
    { lat: 49.1388108, lng: 21.3924246 },
    { lat: 49.1389933, lng: 21.3927355 },
    { lat: 49.1392056, lng: 21.3921148 },
    { lat: 49.1395139, lng: 21.3905143 },
    { lat: 49.1395303, lng: 21.3892393 },
    { lat: 49.1395631, lng: 21.3893607 },
    { lat: 49.1398536, lng: 21.3894008 },
    { lat: 49.1398321, lng: 21.3872659 },
    { lat: 49.1402169, lng: 21.3871881 },
    { lat: 49.1425895, lng: 21.3862608 },
    { lat: 49.1425482, lng: 21.3860045 },
    { lat: 49.1442012, lng: 21.385324 },
    { lat: 49.1442254, lng: 21.3854187 },
    { lat: 49.1451795, lng: 21.3848885 },
    { lat: 49.1457113, lng: 21.3849099 },
    { lat: 49.1459095, lng: 21.385218 },
    { lat: 49.1461892, lng: 21.3852836 },
    { lat: 49.1464523, lng: 21.3852016 },
    { lat: 49.1466136, lng: 21.3852502 },
    { lat: 49.1468031, lng: 21.3854971 },
    { lat: 49.1469089, lng: 21.3855358 },
    { lat: 49.1470891, lng: 21.3846285 },
  ],
  Ortuťová: [
    { lat: 49.2756028, lng: 21.4345675 },
    { lat: 49.2754308, lng: 21.4353438 },
    { lat: 49.2742792, lng: 21.4371062 },
    { lat: 49.2728433, lng: 21.4396964 },
    { lat: 49.2694464, lng: 21.4431003 },
    { lat: 49.26886, lng: 21.4431716 },
    { lat: 49.2686025, lng: 21.4430136 },
    { lat: 49.2682506, lng: 21.4434135 },
    { lat: 49.2682001, lng: 21.4434144 },
    { lat: 49.2680509, lng: 21.4436669 },
    { lat: 49.2677189, lng: 21.4437801 },
    { lat: 49.2675928, lng: 21.4436564 },
    { lat: 49.2674896, lng: 21.4437074 },
    { lat: 49.267396, lng: 21.4440902 },
    { lat: 49.2672416, lng: 21.444392 },
    { lat: 49.2670969, lng: 21.4444047 },
    { lat: 49.2665984, lng: 21.4449442 },
    { lat: 49.2662396, lng: 21.4456475 },
    { lat: 49.2659004, lng: 21.4459827 },
    { lat: 49.2656612, lng: 21.4459935 },
    { lat: 49.2655582, lng: 21.4460901 },
    { lat: 49.2654385, lng: 21.4464617 },
    { lat: 49.2652002, lng: 21.4468607 },
    { lat: 49.2653079, lng: 21.4468859 },
    { lat: 49.265261, lng: 21.4478311 },
    { lat: 49.2650743, lng: 21.4481834 },
    { lat: 49.2648205, lng: 21.4483169 },
    { lat: 49.2645721, lng: 21.448638 },
    { lat: 49.2646615, lng: 21.4488085 },
    { lat: 49.2643849, lng: 21.4488978 },
    { lat: 49.2642586, lng: 21.4490774 },
    { lat: 49.2642452, lng: 21.4502156 },
    { lat: 49.2641792, lng: 21.4506869 },
    { lat: 49.2639877, lng: 21.4512236 },
    { lat: 49.2637796, lng: 21.4520121 },
    { lat: 49.263454, lng: 21.4548035 },
    { lat: 49.2632534, lng: 21.4553757 },
    { lat: 49.2632417, lng: 21.4559835 },
    { lat: 49.2627233, lng: 21.4575129 },
    { lat: 49.2627063, lng: 21.4586016 },
    { lat: 49.2624992, lng: 21.4593417 },
    { lat: 49.2616047, lng: 21.4635969 },
    { lat: 49.2610314, lng: 21.4648576 },
    { lat: 49.2607034, lng: 21.4658954 },
    { lat: 49.2591668, lng: 21.4683261 },
    { lat: 49.2595876, lng: 21.4702101 },
    { lat: 49.260928, lng: 21.4719497 },
    { lat: 49.2627342, lng: 21.4736953 },
    { lat: 49.2634853, lng: 21.4746161 },
    { lat: 49.2637499, lng: 21.4745451 },
    { lat: 49.2648598, lng: 21.4746552 },
    { lat: 49.2648684, lng: 21.4747151 },
    { lat: 49.2661585, lng: 21.4757213 },
    { lat: 49.2672079, lng: 21.4756349 },
    { lat: 49.268024, lng: 21.4758875 },
    { lat: 49.2678622, lng: 21.4769953 },
    { lat: 49.2679276, lng: 21.4780169 },
    { lat: 49.2686569, lng: 21.4801142 },
    { lat: 49.2689395, lng: 21.4814963 },
    { lat: 49.2690187, lng: 21.4816679 },
    { lat: 49.2688873, lng: 21.4822239 },
    { lat: 49.2691987, lng: 21.4831214 },
    { lat: 49.2695328, lng: 21.4839079 },
    { lat: 49.2702216, lng: 21.4866781 },
    { lat: 49.2702111, lng: 21.4869771 },
    { lat: 49.2703079, lng: 21.4873359 },
    { lat: 49.271379, lng: 21.486966 },
    { lat: 49.271384, lng: 21.486893 },
    { lat: 49.271443, lng: 21.486868 },
    { lat: 49.271435, lng: 21.486575 },
    { lat: 49.271778, lng: 21.486413 },
    { lat: 49.271795, lng: 21.48612 },
    { lat: 49.271971, lng: 21.486037 },
    { lat: 49.272001, lng: 21.485906 },
    { lat: 49.272199, lng: 21.485932 },
    { lat: 49.272227, lng: 21.485561 },
    { lat: 49.272382, lng: 21.485498 },
    { lat: 49.272443, lng: 21.485372 },
    { lat: 49.272431, lng: 21.485087 },
    { lat: 49.272507, lng: 21.485077 },
    { lat: 49.272766, lng: 21.485045 },
    { lat: 49.272943, lng: 21.484932 },
    { lat: 49.272958, lng: 21.48482 },
    { lat: 49.273265, lng: 21.484689 },
    { lat: 49.273313, lng: 21.484627 },
    { lat: 49.273385, lng: 21.484615 },
    { lat: 49.273463, lng: 21.484458 },
    { lat: 49.273856, lng: 21.484387 },
    { lat: 49.273967, lng: 21.484131 },
    { lat: 49.274093, lng: 21.484001 },
    { lat: 49.274412, lng: 21.483872 },
    { lat: 49.274525, lng: 21.483967 },
    { lat: 49.274537, lng: 21.483691 },
    { lat: 49.274613, lng: 21.483703 },
    { lat: 49.274673, lng: 21.483602 },
    { lat: 49.274768, lng: 21.483592 },
    { lat: 49.274943, lng: 21.483765 },
    { lat: 49.275054, lng: 21.483566 },
    { lat: 49.275095, lng: 21.483528 },
    { lat: 49.275118, lng: 21.483587 },
    { lat: 49.275175, lng: 21.483586 },
    { lat: 49.275193, lng: 21.48356 },
    { lat: 49.275256, lng: 21.483489 },
    { lat: 49.275402, lng: 21.483412 },
    { lat: 49.275468, lng: 21.483461 },
    { lat: 49.275716, lng: 21.483328 },
    { lat: 49.275655, lng: 21.48313 },
    { lat: 49.275744, lng: 21.48308 },
    { lat: 49.27585, lng: 21.483264 },
    { lat: 49.276053, lng: 21.482955 },
    { lat: 49.27604, lng: 21.482851 },
    { lat: 49.276004, lng: 21.482859 },
    { lat: 49.276001, lng: 21.482791 },
    { lat: 49.276125, lng: 21.482731 },
    { lat: 49.276241, lng: 21.482879 },
    { lat: 49.27634, lng: 21.482969 },
    { lat: 49.276747, lng: 21.482603 },
    { lat: 49.277053, lng: 21.482235 },
    { lat: 49.276991, lng: 21.482106 },
    { lat: 49.277136, lng: 21.481687 },
    { lat: 49.277251, lng: 21.4816 },
    { lat: 49.277373, lng: 21.481681 },
    { lat: 49.2776, lng: 21.481768 },
    { lat: 49.278025, lng: 21.48146 },
    { lat: 49.278063, lng: 21.481318 },
    { lat: 49.278061, lng: 21.481249 },
    { lat: 49.277938, lng: 21.481186 },
    { lat: 49.277861, lng: 21.481029 },
    { lat: 49.27812, lng: 21.480889 },
    { lat: 49.27832, lng: 21.480809 },
    { lat: 49.278546, lng: 21.480828 },
    { lat: 49.278711, lng: 21.4807 },
    { lat: 49.278713, lng: 21.48046 },
    { lat: 49.278835, lng: 21.480461 },
    { lat: 49.278974, lng: 21.480423 },
    { lat: 49.27901, lng: 21.480257 },
    { lat: 49.27895, lng: 21.480083 },
    { lat: 49.27887, lng: 21.47988 },
    { lat: 49.279159, lng: 21.479448 },
    { lat: 49.279347, lng: 21.479117 },
    { lat: 49.279669, lng: 21.478945 },
    { lat: 49.279771, lng: 21.478809 },
    { lat: 49.279926, lng: 21.478516 },
    { lat: 49.280036, lng: 21.478542 },
    { lat: 49.280008, lng: 21.478354 },
    { lat: 49.280112, lng: 21.478213 },
    { lat: 49.28048, lng: 21.47785 },
    { lat: 49.28057, lng: 21.477707 },
    { lat: 49.280851, lng: 21.477066 },
    { lat: 49.280911, lng: 21.476839 },
    { lat: 49.281009, lng: 21.476731 },
    { lat: 49.281252, lng: 21.476167 },
    { lat: 49.281528, lng: 21.475469 },
    { lat: 49.281698, lng: 21.475205 },
    { lat: 49.281715, lng: 21.47506 },
    { lat: 49.281611, lng: 21.474895 },
    { lat: 49.281685, lng: 21.47465 },
    { lat: 49.281736, lng: 21.474545 },
    { lat: 49.281788, lng: 21.474298 },
    { lat: 49.28181, lng: 21.474011 },
    { lat: 49.281837, lng: 21.473665 },
    { lat: 49.281947, lng: 21.473134 },
    { lat: 49.281963, lng: 21.472965 },
    { lat: 49.281939, lng: 21.47283 },
    { lat: 49.281705, lng: 21.472866 },
    { lat: 49.281839, lng: 21.472236 },
    { lat: 49.281733, lng: 21.471981 },
    { lat: 49.281578, lng: 21.471414 },
    { lat: 49.281104, lng: 21.471023 },
    { lat: 49.280768, lng: 21.470739 },
    { lat: 49.280584, lng: 21.470754 },
    { lat: 49.280454, lng: 21.470668 },
    { lat: 49.280072, lng: 21.470683 },
    { lat: 49.280042, lng: 21.470591 },
    { lat: 49.280004, lng: 21.47023 },
    { lat: 49.280009, lng: 21.470019 },
    { lat: 49.279997, lng: 21.469719 },
    { lat: 49.279986, lng: 21.469516 },
    { lat: 49.279964, lng: 21.469359 },
    { lat: 49.279935, lng: 21.469174 },
    { lat: 49.279949, lng: 21.469015 },
    { lat: 49.279927, lng: 21.46879 },
    { lat: 49.279917, lng: 21.468697 },
    { lat: 49.279899, lng: 21.4685 },
    { lat: 49.279908, lng: 21.468152 },
    { lat: 49.279863, lng: 21.467515 },
    { lat: 49.27986, lng: 21.467448 },
    { lat: 49.279853, lng: 21.467256 },
    { lat: 49.279851, lng: 21.467186 },
    { lat: 49.279857, lng: 21.46695 },
    { lat: 49.279896, lng: 21.466236 },
    { lat: 49.279919, lng: 21.465715 },
    { lat: 49.279816, lng: 21.465729 },
    { lat: 49.279781, lng: 21.465528 },
    { lat: 49.280002, lng: 21.465258 },
    { lat: 49.279997, lng: 21.465013 },
    { lat: 49.279899, lng: 21.464493 },
    { lat: 49.27988, lng: 21.464263 },
    { lat: 49.279947, lng: 21.464011 },
    { lat: 49.279946, lng: 21.463743 },
    { lat: 49.279898, lng: 21.463542 },
    { lat: 49.27995, lng: 21.463107 },
    { lat: 49.280256, lng: 21.462799 },
    { lat: 49.280626, lng: 21.462346 },
    { lat: 49.280693, lng: 21.462125 },
    { lat: 49.280794, lng: 21.461627 },
    { lat: 49.280808, lng: 21.461419 },
    { lat: 49.280785, lng: 21.461081 },
    { lat: 49.280758, lng: 21.460867 },
    { lat: 49.280884, lng: 21.460546 },
    { lat: 49.281041, lng: 21.460299 },
    { lat: 49.280989, lng: 21.460125 },
    { lat: 49.281076, lng: 21.459959 },
    { lat: 49.281188, lng: 21.459804 },
    { lat: 49.281374, lng: 21.459332 },
    { lat: 49.281946, lng: 21.458561 },
    { lat: 49.282338, lng: 21.458289 },
    { lat: 49.283466, lng: 21.458348 },
    { lat: 49.283765, lng: 21.458285 },
    { lat: 49.284066, lng: 21.458185 },
    { lat: 49.284472, lng: 21.458016 },
    { lat: 49.284559, lng: 21.457943 },
    { lat: 49.284726, lng: 21.457915 },
    { lat: 49.284964, lng: 21.457918 },
    { lat: 49.285128, lng: 21.457817 },
    { lat: 49.28525, lng: 21.457822 },
    { lat: 49.285867, lng: 21.457452 },
    { lat: 49.285966, lng: 21.457403 },
    { lat: 49.287119, lng: 21.455807 },
    { lat: 49.288118, lng: 21.454538 },
    { lat: 49.287987, lng: 21.453741 },
    { lat: 49.287743, lng: 21.453086 },
    { lat: 49.287748, lng: 21.452906 },
    { lat: 49.2877194, lng: 21.4529586 },
    { lat: 49.287572, lng: 21.4522542 },
    { lat: 49.2874401, lng: 21.4520541 },
    { lat: 49.2871416, lng: 21.4518403 },
    { lat: 49.2866175, lng: 21.4512263 },
    { lat: 49.285777, lng: 21.450635 },
    { lat: 49.2850608, lng: 21.4499485 },
    { lat: 49.2837872, lng: 21.449246 },
    { lat: 49.2835533, lng: 21.4483954 },
    { lat: 49.2833247, lng: 21.4483817 },
    { lat: 49.2827912, lng: 21.4486901 },
    { lat: 49.2823064, lng: 21.4488104 },
    { lat: 49.2820809, lng: 21.4487904 },
    { lat: 49.2817574, lng: 21.4488861 },
    { lat: 49.2814206, lng: 21.4486335 },
    { lat: 49.2813145, lng: 21.4486273 },
    { lat: 49.2799187, lng: 21.4490304 },
    { lat: 49.2795608, lng: 21.448905 },
    { lat: 49.2793641, lng: 21.4487558 },
    { lat: 49.2791873, lng: 21.4485194 },
    { lat: 49.2790414, lng: 21.4483005 },
    { lat: 49.278976, lng: 21.4480919 },
    { lat: 49.2788987, lng: 21.4478319 },
    { lat: 49.2789419, lng: 21.4477688 },
    { lat: 49.2785982, lng: 21.4467845 },
    { lat: 49.2782448, lng: 21.4454946 },
    { lat: 49.278269, lng: 21.4452804 },
    { lat: 49.2771651, lng: 21.4418869 },
    { lat: 49.2768417, lng: 21.4406991 },
    { lat: 49.2764012, lng: 21.4395143 },
    { lat: 49.2760604, lng: 21.4389724 },
    { lat: 49.2762377, lng: 21.4380722 },
    { lat: 49.2756993, lng: 21.4350978 },
    { lat: 49.2756028, lng: 21.4345675 },
  ],
  Harhaj: [
    { lat: 49.1921105, lng: 21.4026929 },
    { lat: 49.1921109, lng: 21.4024008 },
    { lat: 49.1916817, lng: 21.4019139 },
    { lat: 49.1917993, lng: 21.4015213 },
    { lat: 49.1917331, lng: 21.4012557 },
    { lat: 49.191427, lng: 21.4008687 },
    { lat: 49.191374, lng: 21.4004051 },
    { lat: 49.1909941, lng: 21.4002641 },
    { lat: 49.1904593, lng: 21.4005996 },
    { lat: 49.1900733, lng: 21.3991749 },
    { lat: 49.1900789, lng: 21.3984134 },
    { lat: 49.1900132, lng: 21.3978621 },
    { lat: 49.1898265, lng: 21.3971724 },
    { lat: 49.1892235, lng: 21.3958777 },
    { lat: 49.1884205, lng: 21.3924072 },
    { lat: 49.1875716, lng: 21.3909359 },
    { lat: 49.1874406, lng: 21.3892763 },
    { lat: 49.1869765, lng: 21.3892149 },
    { lat: 49.1864012, lng: 21.3886702 },
    { lat: 49.1841493, lng: 21.3837666 },
    { lat: 49.1833521, lng: 21.380657 },
    { lat: 49.1825095, lng: 21.3818486 },
    { lat: 49.1822812, lng: 21.3823415 },
    { lat: 49.1823782, lng: 21.3830801 },
    { lat: 49.1824102, lng: 21.3837418 },
    { lat: 49.1818647, lng: 21.3898506 },
    { lat: 49.1815608, lng: 21.3943182 },
    { lat: 49.1813718, lng: 21.3964207 },
    { lat: 49.1813913, lng: 21.3970854 },
    { lat: 49.1814678, lng: 21.3972025 },
    { lat: 49.1816478, lng: 21.3977739 },
    { lat: 49.1815005, lng: 21.3980501 },
    { lat: 49.1814063, lng: 21.3983673 },
    { lat: 49.1814179, lng: 21.3987193 },
    { lat: 49.1815572, lng: 21.3997869 },
    { lat: 49.1817502, lng: 21.4000715 },
    { lat: 49.1819073, lng: 21.4053844 },
    { lat: 49.1823072, lng: 21.4054013 },
    { lat: 49.1816618, lng: 21.4071846 },
    { lat: 49.1809455, lng: 21.4087746 },
    { lat: 49.1808448, lng: 21.4086771 },
    { lat: 49.1805394, lng: 21.4090036 },
    { lat: 49.1805956, lng: 21.4090797 },
    { lat: 49.179744, lng: 21.4101184 },
    { lat: 49.1796511, lng: 21.4103661 },
    { lat: 49.1798112, lng: 21.4105278 },
    { lat: 49.1797295, lng: 21.4107416 },
    { lat: 49.1799186, lng: 21.4110481 },
    { lat: 49.1788861, lng: 21.4129065 },
    { lat: 49.179001, lng: 21.4134482 },
    { lat: 49.1778487, lng: 21.4165165 },
    { lat: 49.177757, lng: 21.4169194 },
    { lat: 49.1775241, lng: 21.4173056 },
    { lat: 49.1771137, lng: 21.4178169 },
    { lat: 49.1770219, lng: 21.4176045 },
    { lat: 49.1762642, lng: 21.4187049 },
    { lat: 49.1771221, lng: 21.4210694 },
    { lat: 49.1773704, lng: 21.4217566 },
    { lat: 49.1768005, lng: 21.4221977 },
    { lat: 49.1769667, lng: 21.422723 },
    { lat: 49.1768578, lng: 21.4227717 },
    { lat: 49.1768397, lng: 21.4230419 },
    { lat: 49.1765157, lng: 21.4243887 },
    { lat: 49.1764775, lng: 21.4243863 },
    { lat: 49.1758756, lng: 21.4267095 },
    { lat: 49.17557, lng: 21.4301224 },
    { lat: 49.1754201, lng: 21.4306932 },
    { lat: 49.175429, lng: 21.431192 },
    { lat: 49.175344, lng: 21.4315919 },
    { lat: 49.1753263, lng: 21.431948 },
    { lat: 49.1751707, lng: 21.4323293 },
    { lat: 49.1751003, lng: 21.4327714 },
    { lat: 49.1751044, lng: 21.4332583 },
    { lat: 49.1752135, lng: 21.433411 },
    { lat: 49.1753679, lng: 21.4359727 },
    { lat: 49.175409, lng: 21.4363425 },
    { lat: 49.1759049, lng: 21.4373838 },
    { lat: 49.1757016, lng: 21.4383944 },
    { lat: 49.1769684, lng: 21.4389417 },
    { lat: 49.1782753, lng: 21.4420644 },
    { lat: 49.1789615, lng: 21.4432232 },
    { lat: 49.1794448, lng: 21.4437487 },
    { lat: 49.1803366, lng: 21.4439356 },
    { lat: 49.1822782, lng: 21.4426376 },
    { lat: 49.182494, lng: 21.4420558 },
    { lat: 49.1826316, lng: 21.4418322 },
    { lat: 49.1826765, lng: 21.4416346 },
    { lat: 49.1824689, lng: 21.4415713 },
    { lat: 49.1832257, lng: 21.4381934 },
    { lat: 49.1832137, lng: 21.4368059 },
    { lat: 49.1832761, lng: 21.4341187 },
    { lat: 49.1833525, lng: 21.4341502 },
    { lat: 49.1834537, lng: 21.4339175 },
    { lat: 49.1843466, lng: 21.4314635 },
    { lat: 49.1851492, lng: 21.432294 },
    { lat: 49.1861481, lng: 21.4335981 },
    { lat: 49.1861401, lng: 21.4323992 },
    { lat: 49.1864928, lng: 21.4313696 },
    { lat: 49.1865346, lng: 21.4309499 },
    { lat: 49.1868925, lng: 21.4299531 },
    { lat: 49.1868553, lng: 21.4294067 },
    { lat: 49.186792, lng: 21.4291654 },
    { lat: 49.186451, lng: 21.4288155 },
    { lat: 49.1859316, lng: 21.4289032 },
    { lat: 49.1852744, lng: 21.4291758 },
    { lat: 49.1849235, lng: 21.4290571 },
    { lat: 49.1847686, lng: 21.4286464 },
    { lat: 49.1847521, lng: 21.4278538 },
    { lat: 49.1850113, lng: 21.4273226 },
    { lat: 49.1852001, lng: 21.4274727 },
    { lat: 49.1854955, lng: 21.4272944 },
    { lat: 49.1854486, lng: 21.4270031 },
    { lat: 49.1853622, lng: 21.4264654 },
    { lat: 49.1889456, lng: 21.4222632 },
    { lat: 49.1899794, lng: 21.4208329 },
    { lat: 49.1901062, lng: 21.4205502 },
    { lat: 49.1900262, lng: 21.4175424 },
    { lat: 49.1901391, lng: 21.4173549 },
    { lat: 49.1920853, lng: 21.4185812 },
    { lat: 49.1925245, lng: 21.4182171 },
    { lat: 49.1927903, lng: 21.417762 },
    { lat: 49.1926077, lng: 21.4163283 },
    { lat: 49.1926564, lng: 21.4154152 },
    { lat: 49.1924897, lng: 21.4151003 },
    { lat: 49.1922956, lng: 21.4152351 },
    { lat: 49.1923208, lng: 21.4131279 },
    { lat: 49.1929139, lng: 21.412063 },
    { lat: 49.1927771, lng: 21.4114957 },
    { lat: 49.1926903, lng: 21.4114559 },
    { lat: 49.1923926, lng: 21.4116679 },
    { lat: 49.1916402, lng: 21.4110199 },
    { lat: 49.191544, lng: 21.4103818 },
    { lat: 49.1915696, lng: 21.4100351 },
    { lat: 49.1914581, lng: 21.4095682 },
    { lat: 49.1910469, lng: 21.4088991 },
    { lat: 49.1909841, lng: 21.4087088 },
    { lat: 49.1913049, lng: 21.4086765 },
    { lat: 49.1915527, lng: 21.4084168 },
    { lat: 49.1916587, lng: 21.4078855 },
    { lat: 49.19162, lng: 21.4075593 },
    { lat: 49.1917671, lng: 21.4072881 },
    { lat: 49.1919665, lng: 21.4070993 },
    { lat: 49.1924178, lng: 21.4060311 },
    { lat: 49.192413, lng: 21.405603 },
    { lat: 49.1922493, lng: 21.4050846 },
    { lat: 49.1922665, lng: 21.4043295 },
    { lat: 49.1924163, lng: 21.4037948 },
    { lat: 49.1921105, lng: 21.4026929 },
  ],
  Krivé: [
    { lat: 49.2911233, lng: 21.1367822 },
    { lat: 49.2905748, lng: 21.1373695 },
    { lat: 49.2899743, lng: 21.1380994 },
    { lat: 49.2894544, lng: 21.1384586 },
    { lat: 49.2890156, lng: 21.1391676 },
    { lat: 49.2881328, lng: 21.1400433 },
    { lat: 49.2869474, lng: 21.140919 },
    { lat: 49.2863986, lng: 21.1413945 },
    { lat: 49.2855893, lng: 21.1417815 },
    { lat: 49.2854204, lng: 21.1419208 },
    { lat: 49.2845766, lng: 21.1429558 },
    { lat: 49.2835655, lng: 21.1439742 },
    { lat: 49.2831688, lng: 21.1447423 },
    { lat: 49.2827219, lng: 21.1459174 },
    { lat: 49.2819227, lng: 21.1473754 },
    { lat: 49.2817522, lng: 21.1480994 },
    { lat: 49.2815298, lng: 21.1486921 },
    { lat: 49.2808565, lng: 21.149639 },
    { lat: 49.2806397, lng: 21.1502291 },
    { lat: 49.2798728, lng: 21.1517779 },
    { lat: 49.2801358, lng: 21.1522682 },
    { lat: 49.2809567, lng: 21.1535476 },
    { lat: 49.2816805, lng: 21.1544467 },
    { lat: 49.2850407, lng: 21.1594387 },
    { lat: 49.2853747, lng: 21.1597581 },
    { lat: 49.2873854, lng: 21.1628733 },
    { lat: 49.2898173, lng: 21.1669809 },
    { lat: 49.2902852, lng: 21.167942 },
    { lat: 49.290639, lng: 21.1688945 },
    { lat: 49.2909737, lng: 21.1687629 },
    { lat: 49.2908451, lng: 21.1702308 },
    { lat: 49.2909758, lng: 21.1707061 },
    { lat: 49.2914618, lng: 21.1704817 },
    { lat: 49.2919314, lng: 21.1699597 },
    { lat: 49.292185, lng: 21.1700071 },
    { lat: 49.2927765, lng: 21.1695604 },
    { lat: 49.2928436, lng: 21.1697661 },
    { lat: 49.2933184, lng: 21.1705944 },
    { lat: 49.2935737, lng: 21.1709478 },
    { lat: 49.2946339, lng: 21.171388 },
    { lat: 49.2947294, lng: 21.1704051 },
    { lat: 49.2956349, lng: 21.1698284 },
    { lat: 49.2970463, lng: 21.1698727 },
    { lat: 49.2973845, lng: 21.1701836 },
    { lat: 49.2976438, lng: 21.1696534 },
    { lat: 49.2975533, lng: 21.169496 },
    { lat: 49.2976493, lng: 21.1691126 },
    { lat: 49.29758, lng: 21.1688718 },
    { lat: 49.2976225, lng: 21.1687605 },
    { lat: 49.2977141, lng: 21.1687048 },
    { lat: 49.2977162, lng: 21.1685921 },
    { lat: 49.2977888, lng: 21.1684606 },
    { lat: 49.297797, lng: 21.1680068 },
    { lat: 49.2977469, lng: 21.1679146 },
    { lat: 49.2980511, lng: 21.1672448 },
    { lat: 49.2983492, lng: 21.1674573 },
    { lat: 49.2988023, lng: 21.1675233 },
    { lat: 49.2993962, lng: 21.1677236 },
    { lat: 49.2997705, lng: 21.1679302 },
    { lat: 49.3004616, lng: 21.1680066 },
    { lat: 49.3008315, lng: 21.1682146 },
    { lat: 49.3040803, lng: 21.1713152 },
    { lat: 49.3052493, lng: 21.1723256 },
    { lat: 49.3053913, lng: 21.1722615 },
    { lat: 49.305296, lng: 21.1695662 },
    { lat: 49.3052286, lng: 21.1695744 },
    { lat: 49.3050725, lng: 21.168932 },
    { lat: 49.3048916, lng: 21.1677918 },
    { lat: 49.305183, lng: 21.1672858 },
    { lat: 49.3059492, lng: 21.1650243 },
    { lat: 49.3064504, lng: 21.1637025 },
    { lat: 49.3057458, lng: 21.1627289 },
    { lat: 49.3054853, lng: 21.1621004 },
    { lat: 49.3049081, lng: 21.1612419 },
    { lat: 49.3049022, lng: 21.1608359 },
    { lat: 49.3049833, lng: 21.1604824 },
    { lat: 49.3050014, lng: 21.1596203 },
    { lat: 49.304794, lng: 21.1585347 },
    { lat: 49.3051776, lng: 21.157887 },
    { lat: 49.3055726, lng: 21.1573478 },
    { lat: 49.305085, lng: 21.1566556 },
    { lat: 49.3043646, lng: 21.1556793 },
    { lat: 49.3044461, lng: 21.1556232 },
    { lat: 49.3044929, lng: 21.1554979 },
    { lat: 49.3046282, lng: 21.1549727 },
    { lat: 49.3047451, lng: 21.1549335 },
    { lat: 49.3048434, lng: 21.1546547 },
    { lat: 49.3029451, lng: 21.15286 },
    { lat: 49.3023126, lng: 21.1518233 },
    { lat: 49.3025171, lng: 21.1515623 },
    { lat: 49.3024922, lng: 21.1505528 },
    { lat: 49.3024156, lng: 21.1500496 },
    { lat: 49.3024582, lng: 21.1500086 },
    { lat: 49.3023401, lng: 21.1487978 },
    { lat: 49.3023865, lng: 21.1482337 },
    { lat: 49.3026257, lng: 21.1478617 },
    { lat: 49.3025067, lng: 21.1469697 },
    { lat: 49.3023242, lng: 21.1464993 },
    { lat: 49.3022334, lng: 21.1459734 },
    { lat: 49.3020938, lng: 21.1456902 },
    { lat: 49.3018203, lng: 21.1454345 },
    { lat: 49.3017071, lng: 21.1450887 },
    { lat: 49.301705, lng: 21.1448127 },
    { lat: 49.3022059, lng: 21.144068 },
    { lat: 49.3018114, lng: 21.1430406 },
    { lat: 49.3014996, lng: 21.1425065 },
    { lat: 49.3021062, lng: 21.1416141 },
    { lat: 49.3021439, lng: 21.1413142 },
    { lat: 49.3022598, lng: 21.14113 },
    { lat: 49.3023016, lng: 21.1409225 },
    { lat: 49.3023255, lng: 21.1405721 },
    { lat: 49.3021834, lng: 21.1401722 },
    { lat: 49.3018922, lng: 21.1396117 },
    { lat: 49.3019391, lng: 21.1393479 },
    { lat: 49.301839, lng: 21.1391357 },
    { lat: 49.3018031, lng: 21.1389235 },
    { lat: 49.3022008, lng: 21.1372993 },
    { lat: 49.302357, lng: 21.1368299 },
    { lat: 49.3021675, lng: 21.1366333 },
    { lat: 49.3025155, lng: 21.136221 },
    { lat: 49.3027974, lng: 21.1356623 },
    { lat: 49.3024666, lng: 21.1354555 },
    { lat: 49.3018937, lng: 21.1345917 },
    { lat: 49.3013327, lng: 21.134141 },
    { lat: 49.301104, lng: 21.1338874 },
    { lat: 49.3003665, lng: 21.1330181 },
    { lat: 49.2999256, lng: 21.1321659 },
    { lat: 49.299103, lng: 21.1313483 },
    { lat: 49.2986166, lng: 21.1310525 },
    { lat: 49.2983155, lng: 21.1309922 },
    { lat: 49.2976251, lng: 21.1310169 },
    { lat: 49.2968629, lng: 21.1311396 },
    { lat: 49.2950472, lng: 21.1326156 },
    { lat: 49.2946315, lng: 21.133062 },
    { lat: 49.2937817, lng: 21.133659 },
    { lat: 49.2932345, lng: 21.1343282 },
    { lat: 49.2926711, lng: 21.1348388 },
    { lat: 49.2920099, lng: 21.1358566 },
    { lat: 49.2911233, lng: 21.1367822 },
  ],
  Rešov: [
    { lat: 49.2072505, lng: 21.3431332 },
    { lat: 49.2077766, lng: 21.3456804 },
    { lat: 49.2101627, lng: 21.3469476 },
    { lat: 49.2113295, lng: 21.3483852 },
    { lat: 49.2124544, lng: 21.3481164 },
    { lat: 49.2130817, lng: 21.3489618 },
    { lat: 49.2140703, lng: 21.3480431 },
    { lat: 49.2162251, lng: 21.349408 },
    { lat: 49.2165065, lng: 21.34968 },
    { lat: 49.2179718, lng: 21.3482974 },
    { lat: 49.2187105, lng: 21.3489213 },
    { lat: 49.2192756, lng: 21.3482076 },
    { lat: 49.2197144, lng: 21.3491586 },
    { lat: 49.2202539, lng: 21.3482656 },
    { lat: 49.2205654, lng: 21.3485608 },
    { lat: 49.2209692, lng: 21.348518 },
    { lat: 49.2220149, lng: 21.3489569 },
    { lat: 49.2217173, lng: 21.3501801 },
    { lat: 49.2232261, lng: 21.3494264 },
    { lat: 49.2239194, lng: 21.3485666 },
    { lat: 49.2246793, lng: 21.3472215 },
    { lat: 49.2252444, lng: 21.3469659 },
    { lat: 49.2257656, lng: 21.3468624 },
    { lat: 49.2261726, lng: 21.3471171 },
    { lat: 49.2262259, lng: 21.3483758 },
    { lat: 49.2265761, lng: 21.3483875 },
    { lat: 49.227656, lng: 21.3480015 },
    { lat: 49.2278239, lng: 21.3469901 },
    { lat: 49.2291728, lng: 21.3481161 },
    { lat: 49.2306778, lng: 21.3498444 },
    { lat: 49.2309881, lng: 21.3515315 },
    { lat: 49.2318307, lng: 21.353798 },
    { lat: 49.2336049, lng: 21.3503192 },
    { lat: 49.2352, lng: 21.3500382 },
    { lat: 49.2362791, lng: 21.3492547 },
    { lat: 49.2373732, lng: 21.3482462 },
    { lat: 49.2391286, lng: 21.345984 },
    { lat: 49.2405968, lng: 21.3457981 },
    { lat: 49.2413368, lng: 21.3442364 },
    { lat: 49.2420282, lng: 21.342977 },
    { lat: 49.243122, lng: 21.3432073 },
    { lat: 49.2439879, lng: 21.3417978 },
    { lat: 49.2452888, lng: 21.3406723 },
    { lat: 49.2459194, lng: 21.3403856 },
    { lat: 49.2471825, lng: 21.3389036 },
    { lat: 49.247004, lng: 21.3386175 },
    { lat: 49.2457712, lng: 21.3364807 },
    { lat: 49.2453478, lng: 21.3360172 },
    { lat: 49.2453979, lng: 21.3358456 },
    { lat: 49.2445376, lng: 21.3337186 },
    { lat: 49.2442428, lng: 21.3337139 },
    { lat: 49.2442479, lng: 21.3331356 },
    { lat: 49.2450775, lng: 21.3322019 },
    { lat: 49.2460292, lng: 21.3299456 },
    { lat: 49.2458824, lng: 21.3286907 },
    { lat: 49.24619, lng: 21.3256957 },
    { lat: 49.2468794, lng: 21.3235086 },
    { lat: 49.2487469, lng: 21.3189269 },
    { lat: 49.2485003, lng: 21.3180233 },
    { lat: 49.2497646, lng: 21.3164018 },
    { lat: 49.250757, lng: 21.3136256 },
    { lat: 49.2498557, lng: 21.3125781 },
    { lat: 49.2494379, lng: 21.3116707 },
    { lat: 49.2483432, lng: 21.3101009 },
    { lat: 49.2467795, lng: 21.3081259 },
    { lat: 49.2453116, lng: 21.3063892 },
    { lat: 49.2431342, lng: 21.3042948 },
    { lat: 49.2421186, lng: 21.3040083 },
    { lat: 49.2402767, lng: 21.3022178 },
    { lat: 49.2391854, lng: 21.3035574 },
    { lat: 49.23725, lng: 21.306392 },
    { lat: 49.2370443, lng: 21.3065526 },
    { lat: 49.2355156, lng: 21.3107823 },
    { lat: 49.2339115, lng: 21.3105353 },
    { lat: 49.2332465, lng: 21.3111659 },
    { lat: 49.2330607, lng: 21.3127304 },
    { lat: 49.2320592, lng: 21.3143722 },
    { lat: 49.2314727, lng: 21.3141026 },
    { lat: 49.2310541, lng: 21.3151472 },
    { lat: 49.2295954, lng: 21.3166319 },
    { lat: 49.2283714, lng: 21.318018 },
    { lat: 49.227722, lng: 21.3184609 },
    { lat: 49.2275617, lng: 21.3184491 },
    { lat: 49.2266993, lng: 21.3187622 },
    { lat: 49.2262273, lng: 21.3185613 },
    { lat: 49.2253328, lng: 21.3190804 },
    { lat: 49.2247067, lng: 21.3195551 },
    { lat: 49.2230514, lng: 21.3214168 },
    { lat: 49.2225295, lng: 21.322364 },
    { lat: 49.2228835, lng: 21.3236243 },
    { lat: 49.2204695, lng: 21.3250662 },
    { lat: 49.219463, lng: 21.3251984 },
    { lat: 49.2198021, lng: 21.3293385 },
    { lat: 49.2202013, lng: 21.3304977 },
    { lat: 49.2200255, lng: 21.3307137 },
    { lat: 49.2198666, lng: 21.3311556 },
    { lat: 49.2193838, lng: 21.3316065 },
    { lat: 49.2192091, lng: 21.3314783 },
    { lat: 49.2185529, lng: 21.3318095 },
    { lat: 49.2177683, lng: 21.3319449 },
    { lat: 49.2169047, lng: 21.332979 },
    { lat: 49.2158563, lng: 21.333556 },
    { lat: 49.2152151, lng: 21.3338188 },
    { lat: 49.2146446, lng: 21.3341926 },
    { lat: 49.2136192, lng: 21.3339959 },
    { lat: 49.2130383, lng: 21.333791 },
    { lat: 49.212672, lng: 21.3341875 },
    { lat: 49.2115339, lng: 21.3343935 },
    { lat: 49.2111574, lng: 21.3345986 },
    { lat: 49.2107459, lng: 21.3345337 },
    { lat: 49.2102803, lng: 21.3343456 },
    { lat: 49.2095321, lng: 21.3345802 },
    { lat: 49.2091063, lng: 21.3352029 },
    { lat: 49.2087932, lng: 21.3355651 },
    { lat: 49.2073743, lng: 21.3366539 },
    { lat: 49.206483, lng: 21.3373209 },
    { lat: 49.2070573, lng: 21.3401985 },
    { lat: 49.2072505, lng: 21.3431332 },
  ],
  Bardejov: [
    { lat: 49.3247001, lng: 21.2247701 },
    { lat: 49.3246256, lng: 21.2248703 },
    { lat: 49.3244833, lng: 21.2253351 },
    { lat: 49.3235824, lng: 21.2272107 },
    { lat: 49.3231745, lng: 21.2275572 },
    { lat: 49.322166, lng: 21.2288277 },
    { lat: 49.3219105, lng: 21.2293362 },
    { lat: 49.3216228, lng: 21.2296022 },
    { lat: 49.3212956, lng: 21.2293138 },
    { lat: 49.3211289, lng: 21.2293445 },
    { lat: 49.3207255, lng: 21.2291831 },
    { lat: 49.3205238, lng: 21.2293173 },
    { lat: 49.3202502, lng: 21.2297522 },
    { lat: 49.3199129, lng: 21.2297227 },
    { lat: 49.3196347, lng: 21.2294549 },
    { lat: 49.3193599, lng: 21.2295219 },
    { lat: 49.3191623, lng: 21.2296436 },
    { lat: 49.3188986, lng: 21.2293795 },
    { lat: 49.318542, lng: 21.229338 },
    { lat: 49.318063, lng: 21.229018 },
    { lat: 49.3170462, lng: 21.2289216 },
    { lat: 49.316742, lng: 21.2287293 },
    { lat: 49.3160769, lng: 21.2276663 },
    { lat: 49.3157927, lng: 21.227641 },
    { lat: 49.3149799, lng: 21.2280284 },
    { lat: 49.3147866, lng: 21.2282601 },
    { lat: 49.314629, lng: 21.2286253 },
    { lat: 49.3139389, lng: 21.2287178 },
    { lat: 49.3134215, lng: 21.2291276 },
    { lat: 49.3131384, lng: 21.2291747 },
    { lat: 49.3129233, lng: 21.2290989 },
    { lat: 49.3126735, lng: 21.228735 },
    { lat: 49.3118833, lng: 21.2279855 },
    { lat: 49.3116194, lng: 21.228047 },
    { lat: 49.3105775, lng: 21.2280773 },
    { lat: 49.3102198, lng: 21.2281925 },
    { lat: 49.3093789, lng: 21.2278109 },
    { lat: 49.3092555, lng: 21.2278259 },
    { lat: 49.30919, lng: 21.2276041 },
    { lat: 49.3089223, lng: 21.2273479 },
    { lat: 49.3086883, lng: 21.226985 },
    { lat: 49.3085089, lng: 21.2269334 },
    { lat: 49.3083567, lng: 21.226666 },
    { lat: 49.3080472, lng: 21.2264409 },
    { lat: 49.3076092, lng: 21.2264364 },
    { lat: 49.3072957, lng: 21.2262606 },
    { lat: 49.3067775, lng: 21.2262395 },
    { lat: 49.3066251, lng: 21.2263356 },
    { lat: 49.3064723, lng: 21.2260137 },
    { lat: 49.3060649, lng: 21.2257939 },
    { lat: 49.3059018, lng: 21.2258334 },
    { lat: 49.3044498, lng: 21.225634 },
    { lat: 49.3030086, lng: 21.2253481 },
    { lat: 49.3017544, lng: 21.2250994 },
    { lat: 49.3014978, lng: 21.2249495 },
    { lat: 49.3013618, lng: 21.224302 },
    { lat: 49.3014516, lng: 21.2236736 },
    { lat: 49.3013797, lng: 21.2236734 },
    { lat: 49.3014131, lng: 21.2235563 },
    { lat: 49.3010294, lng: 21.2234073 },
    { lat: 49.3008544, lng: 21.2234974 },
    { lat: 49.3006759, lng: 21.2232525 },
    { lat: 49.3003954, lng: 21.2225906 },
    { lat: 49.3002633, lng: 21.2225716 },
    { lat: 49.3002445, lng: 21.2223981 },
    { lat: 49.3001767, lng: 21.2223164 },
    { lat: 49.2999276, lng: 21.222307 },
    { lat: 49.2998499, lng: 21.2221579 },
    { lat: 49.2992598, lng: 21.2220299 },
    { lat: 49.2989929, lng: 21.222021 },
    { lat: 49.29876, lng: 21.222169 },
    { lat: 49.2986339, lng: 21.2220227 },
    { lat: 49.2984666, lng: 21.2220647 },
    { lat: 49.298105, lng: 21.2220241 },
    { lat: 49.2980354, lng: 21.221992 },
    { lat: 49.2977943, lng: 21.2215738 },
    { lat: 49.2974413, lng: 21.2214428 },
    { lat: 49.2972936, lng: 21.2211798 },
    { lat: 49.2969493, lng: 21.2210637 },
    { lat: 49.2968585, lng: 21.2208936 },
    { lat: 49.2967505, lng: 21.2209684 },
    { lat: 49.2962211, lng: 21.2210196 },
    { lat: 49.2959371, lng: 21.2208655 },
    { lat: 49.2956588, lng: 21.2210291 },
    { lat: 49.2956321, lng: 21.2211893 },
    { lat: 49.2955126, lng: 21.2212195 },
    { lat: 49.294766, lng: 21.2217463 },
    { lat: 49.2944492, lng: 21.2217627 },
    { lat: 49.294266, lng: 21.2219849 },
    { lat: 49.2938282, lng: 21.2222389 },
    { lat: 49.2937127, lng: 21.2221103 },
    { lat: 49.2933183, lng: 21.2222721 },
    { lat: 49.2928588, lng: 21.2222893 },
    { lat: 49.2922205, lng: 21.2217675 },
    { lat: 49.2916428, lng: 21.2214624 },
    { lat: 49.2913529, lng: 21.2214036 },
    { lat: 49.2909372, lng: 21.2216405 },
    { lat: 49.2905647, lng: 21.2216796 },
    { lat: 49.2901491, lng: 21.2215909 },
    { lat: 49.2899443, lng: 21.2214096 },
    { lat: 49.2898083, lng: 21.2213722 },
    { lat: 49.2895805, lng: 21.2214333 },
    { lat: 49.2895335, lng: 21.2211869 },
    { lat: 49.289544, lng: 21.220432 },
    { lat: 49.2894683, lng: 21.2197678 },
    { lat: 49.2896245, lng: 21.2191365 },
    { lat: 49.2895321, lng: 21.2188097 },
    { lat: 49.288805, lng: 21.2183993 },
    { lat: 49.2887667, lng: 21.2180676 },
    { lat: 49.2884439, lng: 21.218257 },
    { lat: 49.2881365, lng: 21.2180673 },
    { lat: 49.2877718, lng: 21.2176596 },
    { lat: 49.2871355, lng: 21.2175314 },
    { lat: 49.2862421, lng: 21.2164763 },
    { lat: 49.2854481, lng: 21.2182745 },
    { lat: 49.2850412, lng: 21.2188741 },
    { lat: 49.284591, lng: 21.2198858 },
    { lat: 49.2843707, lng: 21.2206244 },
    { lat: 49.2836819, lng: 21.2203619 },
    { lat: 49.2826012, lng: 21.2195416 },
    { lat: 49.2823514, lng: 21.2196155 },
    { lat: 49.2820167, lng: 21.2194576 },
    { lat: 49.2817718, lng: 21.2196533 },
    { lat: 49.2803247, lng: 21.2199 },
    { lat: 49.2800357, lng: 21.2197979 },
    { lat: 49.2793563, lng: 21.2192577 },
    { lat: 49.2791272, lng: 21.218978 },
    { lat: 49.2788786, lng: 21.2191271 },
    { lat: 49.2781477, lng: 21.2191844 },
    { lat: 49.2775836, lng: 21.2193174 },
    { lat: 49.2768395, lng: 21.2199592 },
    { lat: 49.2760595, lng: 21.2202125 },
    { lat: 49.2756969, lng: 21.2199457 },
    { lat: 49.2741615, lng: 21.2197414 },
    { lat: 49.273888, lng: 21.2197784 },
    { lat: 49.2736619, lng: 21.219923 },
    { lat: 49.2728732, lng: 21.2199232 },
    { lat: 49.2725903, lng: 21.2201998 },
    { lat: 49.2722466, lng: 21.2204022 },
    { lat: 49.2721273, lng: 21.2204036 },
    { lat: 49.2720913, lng: 21.2202902 },
    { lat: 49.2719136, lng: 21.2201848 },
    { lat: 49.2717537, lng: 21.2204731 },
    { lat: 49.2713494, lng: 21.2204229 },
    { lat: 49.2711591, lng: 21.2205685 },
    { lat: 49.2710527, lng: 21.2208425 },
    { lat: 49.2709578, lng: 21.2209354 },
    { lat: 49.2706574, lng: 21.2208281 },
    { lat: 49.2704799, lng: 21.2210524 },
    { lat: 49.2702398, lng: 21.2217003 },
    { lat: 49.2699812, lng: 21.2215247 },
    { lat: 49.2698456, lng: 21.2215013 },
    { lat: 49.2696722, lng: 21.2216428 },
    { lat: 49.2694303, lng: 21.2216054 },
    { lat: 49.269325, lng: 21.2217323 },
    { lat: 49.2690268, lng: 21.2217358 },
    { lat: 49.2686646, lng: 21.2214801 },
    { lat: 49.2683455, lng: 21.2211345 },
    { lat: 49.2681265, lng: 21.2212194 },
    { lat: 49.2678696, lng: 21.2211309 },
    { lat: 49.2677486, lng: 21.2203891 },
    { lat: 49.2676639, lng: 21.219462 },
    { lat: 49.2674431, lng: 21.2191299 },
    { lat: 49.2672932, lng: 21.2194073 },
    { lat: 49.2672358, lng: 21.2197029 },
    { lat: 49.2672623, lng: 21.2203784 },
    { lat: 49.2671465, lng: 21.2206379 },
    { lat: 49.2668704, lng: 21.2206981 },
    { lat: 49.2666528, lng: 21.2210417 },
    { lat: 49.2664004, lng: 21.2207788 },
    { lat: 49.2660801, lng: 21.2215928 },
    { lat: 49.2658937, lng: 21.2218937 },
    { lat: 49.2655131, lng: 21.2223293 },
    { lat: 49.2646964, lng: 21.2229502 },
    { lat: 49.2640864, lng: 21.2231728 },
    { lat: 49.2630957, lng: 21.2233724 },
    { lat: 49.2619683, lng: 21.2237367 },
    { lat: 49.2619043, lng: 21.2233043 },
    { lat: 49.2615694, lng: 21.2233158 },
    { lat: 49.2609451, lng: 21.2238323 },
    { lat: 49.2606981, lng: 21.2242846 },
    { lat: 49.260668, lng: 21.2247025 },
    { lat: 49.260546, lng: 21.2249728 },
    { lat: 49.2606229, lng: 21.2260462 },
    { lat: 49.2607773, lng: 21.2263824 },
    { lat: 49.2610833, lng: 21.2266393 },
    { lat: 49.2616663, lng: 21.2268574 },
    { lat: 49.2616964, lng: 21.2269248 },
    { lat: 49.2616528, lng: 21.227116 },
    { lat: 49.2616896, lng: 21.2272855 },
    { lat: 49.2620631, lng: 21.2276171 },
    { lat: 49.2622844, lng: 21.2281874 },
    { lat: 49.2625578, lng: 21.2285496 },
    { lat: 49.2632451, lng: 21.2285795 },
    { lat: 49.2634765, lng: 21.2284987 },
    { lat: 49.2637525, lng: 21.2289487 },
    { lat: 49.2640442, lng: 21.2289572 },
    { lat: 49.2642652, lng: 21.2292663 },
    { lat: 49.2645543, lng: 21.2293724 },
    { lat: 49.2642242, lng: 21.2302694 },
    { lat: 49.2641736, lng: 21.2309691 },
    { lat: 49.2642041, lng: 21.231226 },
    { lat: 49.2641139, lng: 21.2314731 },
    { lat: 49.2639354, lng: 21.2317129 },
    { lat: 49.2635319, lng: 21.2320542 },
    { lat: 49.263177, lng: 21.232146 },
    { lat: 49.2630897, lng: 21.2323425 },
    { lat: 49.2630435, lng: 21.2325267 },
    { lat: 49.2633176, lng: 21.2329526 },
    { lat: 49.2635016, lng: 21.23337 },
    { lat: 49.2626609, lng: 21.2344628 },
    { lat: 49.2613699, lng: 21.2366839 },
    { lat: 49.2605548, lng: 21.2392339 },
    { lat: 49.2602818, lng: 21.2409005 },
    { lat: 49.2600256, lng: 21.24168 },
    { lat: 49.2599137, lng: 21.2430192 },
    { lat: 49.2595892, lng: 21.2437409 },
    { lat: 49.2594996, lng: 21.2452178 },
    { lat: 49.2595022, lng: 21.2460804 },
    { lat: 49.2595645, lng: 21.2462348 },
    { lat: 49.2591117, lng: 21.248018 },
    { lat: 49.2586755, lng: 21.2491328 },
    { lat: 49.2584596, lng: 21.2491745 },
    { lat: 49.2583242, lng: 21.2492742 },
    { lat: 49.2583454, lng: 21.2494489 },
    { lat: 49.2582405, lng: 21.2503962 },
    { lat: 49.258251, lng: 21.2510727 },
    { lat: 49.2581939, lng: 21.2515955 },
    { lat: 49.2589588, lng: 21.2519184 },
    { lat: 49.2590614, lng: 21.2517039 },
    { lat: 49.2591973, lng: 21.2516614 },
    { lat: 49.259283, lng: 21.252218 },
    { lat: 49.2592445, lng: 21.2523937 },
    { lat: 49.2592864, lng: 21.2528842 },
    { lat: 49.2595953, lng: 21.2534872 },
    { lat: 49.2597226, lng: 21.2538275 },
    { lat: 49.2597127, lng: 21.2540177 },
    { lat: 49.2597783, lng: 21.2541435 },
    { lat: 49.2597433, lng: 21.2542875 },
    { lat: 49.2598537, lng: 21.254874 },
    { lat: 49.2598499, lng: 21.2551204 },
    { lat: 49.2599388, lng: 21.2554544 },
    { lat: 49.2599424, lng: 21.2557572 },
    { lat: 49.2598729, lng: 21.2561674 },
    { lat: 49.259696, lng: 21.256421 },
    { lat: 49.2597425, lng: 21.2567606 },
    { lat: 49.2596664, lng: 21.257109 },
    { lat: 49.259546, lng: 21.257475 },
    { lat: 49.2593438, lng: 21.2577041 },
    { lat: 49.2591663, lng: 21.2580813 },
    { lat: 49.2591084, lng: 21.2584338 },
    { lat: 49.2594113, lng: 21.2592886 },
    { lat: 49.2595025, lng: 21.2602141 },
    { lat: 49.2598941, lng: 21.2608757 },
    { lat: 49.2605686, lng: 21.2618554 },
    { lat: 49.260655, lng: 21.2624012 },
    { lat: 49.2609317, lng: 21.2630623 },
    { lat: 49.2610564, lng: 21.2635002 },
    { lat: 49.2610521, lng: 21.2638002 },
    { lat: 49.2612624, lng: 21.2642035 },
    { lat: 49.2613107, lng: 21.2644191 },
    { lat: 49.2613405, lng: 21.2649159 },
    { lat: 49.2612688, lng: 21.2652643 },
    { lat: 49.2613836, lng: 21.2664068 },
    { lat: 49.2613825, lng: 21.2669966 },
    { lat: 49.261477, lng: 21.2672529 },
    { lat: 49.2614633, lng: 21.2676534 },
    { lat: 49.2615231, lng: 21.2681394 },
    { lat: 49.261471, lng: 21.268239 },
    { lat: 49.2614258, lng: 21.2697891 },
    { lat: 49.261101, lng: 21.2700586 },
    { lat: 49.2607228, lng: 21.2704983 },
    { lat: 49.2602337, lng: 21.2708735 },
    { lat: 49.2594914, lng: 21.2718873 },
    { lat: 49.2592486, lng: 21.2720739 },
    { lat: 49.2591641, lng: 21.2724066 },
    { lat: 49.2590466, lng: 21.272505 },
    { lat: 49.2588961, lng: 21.2729581 },
    { lat: 49.2587952, lng: 21.2730459 },
    { lat: 49.2585991, lng: 21.2729907 },
    { lat: 49.2585245, lng: 21.2731685 },
    { lat: 49.2584183, lng: 21.2732258 },
    { lat: 49.2583982, lng: 21.2733407 },
    { lat: 49.2582499, lng: 21.2735745 },
    { lat: 49.2580871, lng: 21.2742511 },
    { lat: 49.2581839, lng: 21.2746585 },
    { lat: 49.2581812, lng: 21.2750902 },
    { lat: 49.2582655, lng: 21.2759387 },
    { lat: 49.2582473, lng: 21.2762631 },
    { lat: 49.2577625, lng: 21.2773432 },
    { lat: 49.2574836, lng: 21.2781954 },
    { lat: 49.257365, lng: 21.2783663 },
    { lat: 49.2571869, lng: 21.2789146 },
    { lat: 49.2567542, lng: 21.2807085 },
    { lat: 49.2562551, lng: 21.2823572 },
    { lat: 49.2554114, lng: 21.2842205 },
    { lat: 49.2552559, lng: 21.2844722 },
    { lat: 49.2542474, lng: 21.2856843 },
    { lat: 49.2540564, lng: 21.2861721 },
    { lat: 49.2538747, lng: 21.286493 },
    { lat: 49.2535245, lng: 21.2869262 },
    { lat: 49.2531329, lng: 21.2876685 },
    { lat: 49.2525918, lng: 21.2882679 },
    { lat: 49.252128, lng: 21.2891147 },
    { lat: 49.251776, lng: 21.289638 },
    { lat: 49.2510148, lng: 21.2914023 },
    { lat: 49.2508365, lng: 21.2922429 },
    { lat: 49.2507595, lng: 21.2927832 },
    { lat: 49.2507894, lng: 21.2929126 },
    { lat: 49.2506445, lng: 21.2933366 },
    { lat: 49.2506773, lng: 21.2941169 },
    { lat: 49.2504064, lng: 21.2948351 },
    { lat: 49.2501871, lng: 21.2951766 },
    { lat: 49.249853, lng: 21.2954357 },
    { lat: 49.2497698, lng: 21.2965919 },
    { lat: 49.2498619, lng: 21.297072 },
    { lat: 49.2497702, lng: 21.2979465 },
    { lat: 49.2495134, lng: 21.2981259 },
    { lat: 49.2499223, lng: 21.2986953 },
    { lat: 49.2504538, lng: 21.2989399 },
    { lat: 49.2514534, lng: 21.2991018 },
    { lat: 49.2521974, lng: 21.3002304 },
    { lat: 49.2527642, lng: 21.3018941 },
    { lat: 49.2538399, lng: 21.3042911 },
    { lat: 49.2546818, lng: 21.305941 },
    { lat: 49.2565784, lng: 21.306707 },
    { lat: 49.2565457, lng: 21.3073037 },
    { lat: 49.2574111, lng: 21.3074593 },
    { lat: 49.2584407, lng: 21.3067526 },
    { lat: 49.2595828, lng: 21.3039212 },
    { lat: 49.26138, lng: 21.3026272 },
    { lat: 49.2625532, lng: 21.302096 },
    { lat: 49.2642218, lng: 21.3002291 },
    { lat: 49.2650971, lng: 21.3003022 },
    { lat: 49.2657751, lng: 21.3001377 },
    { lat: 49.2664855, lng: 21.3002623 },
    { lat: 49.2666439, lng: 21.3002137 },
    { lat: 49.2667197, lng: 21.3014989 },
    { lat: 49.2667372, lng: 21.3016328 },
    { lat: 49.2667962, lng: 21.3016131 },
    { lat: 49.2670067, lng: 21.3010543 },
    { lat: 49.2670527, lng: 21.3010485 },
    { lat: 49.267159, lng: 21.3005078 },
    { lat: 49.2671597, lng: 21.3002354 },
    { lat: 49.2670041, lng: 21.3000702 },
    { lat: 49.2670181, lng: 21.2997932 },
    { lat: 49.2673803, lng: 21.2994113 },
    { lat: 49.2675302, lng: 21.2979348 },
    { lat: 49.2676463, lng: 21.2977572 },
    { lat: 49.268024, lng: 21.2977807 },
    { lat: 49.2683846, lng: 21.2972382 },
    { lat: 49.2686577, lng: 21.2969668 },
    { lat: 49.2694078, lng: 21.2988523 },
    { lat: 49.2702135, lng: 21.3015803 },
    { lat: 49.2706546, lng: 21.3028565 },
    { lat: 49.2721508, lng: 21.3067658 },
    { lat: 49.2729043, lng: 21.3081754 },
    { lat: 49.2729387, lng: 21.3083481 },
    { lat: 49.2748998, lng: 21.3111502 },
    { lat: 49.2738467, lng: 21.3125333 },
    { lat: 49.2730787, lng: 21.3149484 },
    { lat: 49.2733675, lng: 21.3175535 },
    { lat: 49.274839, lng: 21.3191983 },
    { lat: 49.2752816, lng: 21.3203377 },
    { lat: 49.2765759, lng: 21.3208776 },
    { lat: 49.2803143, lng: 21.3251063 },
    { lat: 49.2818242, lng: 21.3270587 },
    { lat: 49.2820924, lng: 21.3272299 },
    { lat: 49.2824895, lng: 21.3274829 },
    { lat: 49.2835583, lng: 21.3289506 },
    { lat: 49.2854532, lng: 21.3296402 },
    { lat: 49.2859943, lng: 21.3297124 },
    { lat: 49.2865406, lng: 21.3294266 },
    { lat: 49.2872865, lng: 21.3287159 },
    { lat: 49.2877648, lng: 21.3292029 },
    { lat: 49.289109, lng: 21.3310755 },
    { lat: 49.2899675, lng: 21.3321733 },
    { lat: 49.2904369, lng: 21.3335704 },
    { lat: 49.2914448, lng: 21.3348802 },
    { lat: 49.292801, lng: 21.3372388 },
    { lat: 49.2938944, lng: 21.3388473 },
    { lat: 49.2940783, lng: 21.3393608 },
    { lat: 49.2943397, lng: 21.3394082 },
    { lat: 49.2951996, lng: 21.3404769 },
    { lat: 49.296777, lng: 21.3417639 },
    { lat: 49.2977299, lng: 21.3432321 },
    { lat: 49.2976393, lng: 21.3448693 },
    { lat: 49.2986292, lng: 21.3443775 },
    { lat: 49.2988364, lng: 21.3443451 },
    { lat: 49.2989564, lng: 21.3442163 },
    { lat: 49.2991568, lng: 21.3445268 },
    { lat: 49.2992491, lng: 21.3442601 },
    { lat: 49.2995678, lng: 21.3441258 },
    { lat: 49.2998291, lng: 21.3433743 },
    { lat: 49.3003031, lng: 21.343086 },
    { lat: 49.3008282, lng: 21.3428826 },
    { lat: 49.3019798, lng: 21.3419984 },
    { lat: 49.3027584, lng: 21.3408654 },
    { lat: 49.3029912, lng: 21.3403361 },
    { lat: 49.3033636, lng: 21.339243 },
    { lat: 49.3035103, lng: 21.3386119 },
    { lat: 49.303853, lng: 21.3379192 },
    { lat: 49.3039502, lng: 21.3375587 },
    { lat: 49.305091, lng: 21.336987 },
    { lat: 49.3071313, lng: 21.3348536 },
    { lat: 49.3083026, lng: 21.3353141 },
    { lat: 49.3090951, lng: 21.3359276 },
    { lat: 49.3098108, lng: 21.3362718 },
    { lat: 49.3103606, lng: 21.3363755 },
    { lat: 49.311493, lng: 21.3373714 },
    { lat: 49.3122553, lng: 21.338827 },
    { lat: 49.3124807, lng: 21.3393666 },
    { lat: 49.313423, lng: 21.3410891 },
    { lat: 49.3138109, lng: 21.3432704 },
    { lat: 49.3140479, lng: 21.3435579 },
    { lat: 49.314309, lng: 21.344129 },
    { lat: 49.3146743, lng: 21.3443787 },
    { lat: 49.3147303, lng: 21.3446777 },
    { lat: 49.3152247, lng: 21.3454143 },
    { lat: 49.318119, lng: 21.3444817 },
    { lat: 49.3190431, lng: 21.3454059 },
    { lat: 49.3188008, lng: 21.344713 },
    { lat: 49.3187236, lng: 21.3435185 },
    { lat: 49.3188621, lng: 21.3424627 },
    { lat: 49.3189653, lng: 21.3421525 },
    { lat: 49.319005, lng: 21.3418212 },
    { lat: 49.3192202, lng: 21.3411556 },
    { lat: 49.3193166, lng: 21.340235 },
    { lat: 49.3195231, lng: 21.3395011 },
    { lat: 49.3194802, lng: 21.3390428 },
    { lat: 49.3195022, lng: 21.3385706 },
    { lat: 49.3193517, lng: 21.338162 },
    { lat: 49.3193089, lng: 21.337637 },
    { lat: 49.3193699, lng: 21.3369645 },
    { lat: 49.3194053, lng: 21.3369093 },
    { lat: 49.3193459, lng: 21.336428 },
    { lat: 49.3193949, lng: 21.336253 },
    { lat: 49.3194715, lng: 21.3349377 },
    { lat: 49.3193709, lng: 21.3348077 },
    { lat: 49.3194364, lng: 21.3341042 },
    { lat: 49.3196418, lng: 21.3337486 },
    { lat: 49.3195067, lng: 21.3322736 },
    { lat: 49.3193267, lng: 21.3322754 },
    { lat: 49.3196517, lng: 21.330986 },
    { lat: 49.3198368, lng: 21.330893 },
    { lat: 49.3200979, lng: 21.3298776 },
    { lat: 49.3201529, lng: 21.3294615 },
    { lat: 49.3202215, lng: 21.3279563 },
    { lat: 49.3201532, lng: 21.3274064 },
    { lat: 49.3203258, lng: 21.3265883 },
    { lat: 49.3202621, lng: 21.3263013 },
    { lat: 49.3203276, lng: 21.3257074 },
    { lat: 49.3201526, lng: 21.3235867 },
    { lat: 49.3201727, lng: 21.3228654 },
    { lat: 49.3202385, lng: 21.3226621 },
    { lat: 49.32028, lng: 21.3219768 },
    { lat: 49.3204269, lng: 21.3210555 },
    { lat: 49.3203791, lng: 21.3208925 },
    { lat: 49.3204207, lng: 21.3205895 },
    { lat: 49.3204009, lng: 21.3199684 },
    { lat: 49.3206778, lng: 21.3194838 },
    { lat: 49.3207488, lng: 21.3192663 },
    { lat: 49.3206915, lng: 21.318966 },
    { lat: 49.3207679, lng: 21.3187702 },
    { lat: 49.3209012, lng: 21.3186782 },
    { lat: 49.32113, lng: 21.3179426 },
    { lat: 49.3211071, lng: 21.3174768 },
    { lat: 49.321228, lng: 21.3160689 },
    { lat: 49.3215165, lng: 21.3141742 },
    { lat: 49.3216084, lng: 21.3138966 },
    { lat: 49.3219401, lng: 21.3133694 },
    { lat: 49.322308, lng: 21.3141522 },
    { lat: 49.3225595, lng: 21.3144014 },
    { lat: 49.3228483, lng: 21.314613 },
    { lat: 49.3230848, lng: 21.3145827 },
    { lat: 49.3233105, lng: 21.3148677 },
    { lat: 49.3233896, lng: 21.3150688 },
    { lat: 49.3236069, lng: 21.3151123 },
    { lat: 49.3237752, lng: 21.3153562 },
    { lat: 49.3245263, lng: 21.3159816 },
    { lat: 49.3251302, lng: 21.3162692 },
    { lat: 49.3262902, lng: 21.3171353 },
    { lat: 49.3269692, lng: 21.3174502 },
    { lat: 49.3281834, lng: 21.3176456 },
    { lat: 49.3287407, lng: 21.3178085 },
    { lat: 49.3289359, lng: 21.3179416 },
    { lat: 49.3292958, lng: 21.3180505 },
    { lat: 49.3294195, lng: 21.318158 },
    { lat: 49.331292, lng: 21.3177195 },
    { lat: 49.3317667, lng: 21.3174539 },
    { lat: 49.3321686, lng: 21.3169336 },
    { lat: 49.3323999, lng: 21.3167698 },
    { lat: 49.332858, lng: 21.316926 },
    { lat: 49.3331039, lng: 21.3169094 },
    { lat: 49.3338828, lng: 21.3165878 },
    { lat: 49.3340871, lng: 21.3163454 },
    { lat: 49.3345215, lng: 21.3160454 },
    { lat: 49.3350548, lng: 21.3155584 },
    { lat: 49.3362688, lng: 21.3142873 },
    { lat: 49.3365559, lng: 21.3141029 },
    { lat: 49.3367009, lng: 21.3136858 },
    { lat: 49.3368916, lng: 21.3128065 },
    { lat: 49.3371869, lng: 21.312369 },
    { lat: 49.3374591, lng: 21.3120692 },
    { lat: 49.3385933, lng: 21.3112098 },
    { lat: 49.3389095, lng: 21.311063 },
    { lat: 49.339058, lng: 21.3111392 },
    { lat: 49.3400811, lng: 21.3108139 },
    { lat: 49.3415219, lng: 21.3106924 },
    { lat: 49.3423804, lng: 21.3108434 },
    { lat: 49.3431936, lng: 21.3105711 },
    { lat: 49.3436078, lng: 21.3105373 },
    { lat: 49.344572, lng: 21.3101249 },
    { lat: 49.3449463, lng: 21.3098889 },
    { lat: 49.3464212, lng: 21.3095895 },
    { lat: 49.3470386, lng: 21.3086045 },
    { lat: 49.3471379, lng: 21.3085376 },
    { lat: 49.3491102, lng: 21.3037493 },
    { lat: 49.3492485, lng: 21.3029543 },
    { lat: 49.3497285, lng: 21.2977497 },
    { lat: 49.3503283, lng: 21.2926827 },
    { lat: 49.3501799, lng: 21.2923154 },
    { lat: 49.3501542, lng: 21.2918766 },
    { lat: 49.3498623, lng: 21.2915251 },
    { lat: 49.3484668, lng: 21.2893074 },
    { lat: 49.3483467, lng: 21.2884307 },
    { lat: 49.3484519, lng: 21.2880736 },
    { lat: 49.3485075, lng: 21.2873082 },
    { lat: 49.3489582, lng: 21.2857292 },
    { lat: 49.3490455, lng: 21.2856364 },
    { lat: 49.3490938, lng: 21.2849668 },
    { lat: 49.349462, lng: 21.283373 },
    { lat: 49.3500444, lng: 21.2802779 },
    { lat: 49.3500608, lng: 21.2766249 },
    { lat: 49.3499085, lng: 21.2719391 },
    { lat: 49.3503783, lng: 21.2650686 },
    { lat: 49.3503409, lng: 21.2647696 },
    { lat: 49.3504341, lng: 21.264022 },
    { lat: 49.3503896, lng: 21.2610799 },
    { lat: 49.3511353, lng: 21.2591128 },
    { lat: 49.3518772, lng: 21.2585376 },
    { lat: 49.3524143, lng: 21.2578092 },
    { lat: 49.3530509, lng: 21.2568117 },
    { lat: 49.3536193, lng: 21.2552267 },
    { lat: 49.3537293, lng: 21.2538156 },
    { lat: 49.3536796, lng: 21.2534766 },
    { lat: 49.3537295, lng: 21.2522387 },
    { lat: 49.3541615, lng: 21.2505057 },
    { lat: 49.3541574, lng: 21.2498708 },
    { lat: 49.3541545, lng: 21.2494321 },
    { lat: 49.3546386, lng: 21.2491115 },
    { lat: 49.3549138, lng: 21.2490991 },
    { lat: 49.3552088, lng: 21.2489133 },
    { lat: 49.356112, lng: 21.247842 },
    { lat: 49.3578867, lng: 21.2461227 },
    { lat: 49.3595228, lng: 21.2454622 },
    { lat: 49.3594118, lng: 21.2440054 },
    { lat: 49.3592767, lng: 21.2432661 },
    { lat: 49.3591732, lng: 21.2429743 },
    { lat: 49.359321, lng: 21.2424034 },
    { lat: 49.3592363, lng: 21.2419857 },
    { lat: 49.3592301, lng: 21.2407889 },
    { lat: 49.3594662, lng: 21.2400154 },
    { lat: 49.3597038, lng: 21.2394644 },
    { lat: 49.3597046, lng: 21.2390908 },
    { lat: 49.3565168, lng: 21.236177 },
    { lat: 49.3559282, lng: 21.2365434 },
    { lat: 49.355526, lng: 21.2369303 },
    { lat: 49.3552392, lng: 21.236895 },
    { lat: 49.3547875, lng: 21.2370267 },
    { lat: 49.3544486, lng: 21.2370157 },
    { lat: 49.3533398, lng: 21.2367795 },
    { lat: 49.3531159, lng: 21.2368227 },
    { lat: 49.3519006, lng: 21.2374719 },
    { lat: 49.3517759, lng: 21.237626 },
    { lat: 49.3515993, lng: 21.2377026 },
    { lat: 49.3512757, lng: 21.2380831 },
    { lat: 49.3512284, lng: 21.2382319 },
    { lat: 49.350328, lng: 21.2391404 },
    { lat: 49.3502825, lng: 21.2392718 },
    { lat: 49.3497082, lng: 21.239697 },
    { lat: 49.3488411, lng: 21.2400003 },
    { lat: 49.3470881, lng: 21.2409912 },
    { lat: 49.3465542, lng: 21.2409717 },
    { lat: 49.3461714, lng: 21.2407441 },
    { lat: 49.3458208, lng: 21.2401662 },
    { lat: 49.3456729, lng: 21.239825 },
    { lat: 49.3456576, lng: 21.2390282 },
    { lat: 49.3454544, lng: 21.2386733 },
    { lat: 49.3450914, lng: 21.2383226 },
    { lat: 49.3448404, lng: 21.2382114 },
    { lat: 49.3447292, lng: 21.2380004 },
    { lat: 49.3444239, lng: 21.2377311 },
    { lat: 49.3439969, lng: 21.2376366 },
    { lat: 49.343645, lng: 21.2373497 },
    { lat: 49.3431064, lng: 21.2367785 },
    { lat: 49.343006, lng: 21.2365108 },
    { lat: 49.3428221, lng: 21.2363476 },
    { lat: 49.3426976, lng: 21.2363265 },
    { lat: 49.3424221, lng: 21.2360353 },
    { lat: 49.3422173, lng: 21.2360006 },
    { lat: 49.3421044, lng: 21.2359095 },
    { lat: 49.3414065, lng: 21.2357572 },
    { lat: 49.3412385, lng: 21.2358147 },
    { lat: 49.3410675, lng: 21.2357123 },
    { lat: 49.3404988, lng: 21.2359112 },
    { lat: 49.3404627, lng: 21.2360556 },
    { lat: 49.34033, lng: 21.2361344 },
    { lat: 49.340136, lng: 21.2359672 },
    { lat: 49.3399553, lng: 21.2359473 },
    { lat: 49.3397635, lng: 21.2361534 },
    { lat: 49.3396406, lng: 21.2363975 },
    { lat: 49.339422, lng: 21.2365713 },
    { lat: 49.3391639, lng: 21.2365588 },
    { lat: 49.3390309, lng: 21.2368057 },
    { lat: 49.3387222, lng: 21.2366479 },
    { lat: 49.3385303, lng: 21.2367035 },
    { lat: 49.3384741, lng: 21.2368472 },
    { lat: 49.3383927, lng: 21.2367603 },
    { lat: 49.3383474, lng: 21.2365662 },
    { lat: 49.3382748, lng: 21.2365508 },
    { lat: 49.3381573, lng: 21.2366794 },
    { lat: 49.3380037, lng: 21.2366594 },
    { lat: 49.337858, lng: 21.2367904 },
    { lat: 49.3377361, lng: 21.2366693 },
    { lat: 49.3373429, lng: 21.2367635 },
    { lat: 49.337045, lng: 21.2369502 },
    { lat: 49.336853, lng: 21.236925 },
    { lat: 49.3367151, lng: 21.2367664 },
    { lat: 49.3365602, lng: 21.236849 },
    { lat: 49.3363963, lng: 21.2371884 },
    { lat: 49.3363077, lng: 21.2371662 },
    { lat: 49.3362452, lng: 21.2370668 },
    { lat: 49.3359711, lng: 21.2372301 },
    { lat: 49.3358394, lng: 21.2372248 },
    { lat: 49.3357755, lng: 21.2370879 },
    { lat: 49.3357846, lng: 21.2368686 },
    { lat: 49.3356407, lng: 21.2368739 },
    { lat: 49.3353403, lng: 21.2371736 },
    { lat: 49.3351601, lng: 21.2371635 },
    { lat: 49.3350019, lng: 21.2370724 },
    { lat: 49.3349061, lng: 21.2371778 },
    { lat: 49.3348701, lng: 21.2373167 },
    { lat: 49.3347084, lng: 21.2372576 },
    { lat: 49.3346059, lng: 21.2375118 },
    { lat: 49.334472, lng: 21.2374738 },
    { lat: 49.3344428, lng: 21.237399 },
    { lat: 49.3344201, lng: 21.2368829 },
    { lat: 49.3337004, lng: 21.2365637 },
    { lat: 49.3334837, lng: 21.2363643 },
    { lat: 49.3332133, lng: 21.2363101 },
    { lat: 49.3330882, lng: 21.2364526 },
    { lat: 49.3328948, lng: 21.2368276 },
    { lat: 49.3326277, lng: 21.2370654 },
    { lat: 49.332569, lng: 21.2373882 },
    { lat: 49.3324639, lng: 21.2375592 },
    { lat: 49.3322669, lng: 21.2376238 },
    { lat: 49.3320288, lng: 21.2376023 },
    { lat: 49.3317595, lng: 21.2374463 },
    { lat: 49.3316945, lng: 21.237342 },
    { lat: 49.3317653, lng: 21.2372287 },
    { lat: 49.3317046, lng: 21.2371573 },
    { lat: 49.3318234, lng: 21.236227 },
    { lat: 49.3318835, lng: 21.2358035 },
    { lat: 49.3320345, lng: 21.2352977 },
    { lat: 49.3325093, lng: 21.2330239 },
    { lat: 49.3329614, lng: 21.2326497 },
    { lat: 49.3331624, lng: 21.2313877 },
    { lat: 49.3329039, lng: 21.2312938 },
    { lat: 49.3329566, lng: 21.2309894 },
    { lat: 49.3320433, lng: 21.2306706 },
    { lat: 49.3298387, lng: 21.2316488 },
    { lat: 49.3285696, lng: 21.2321275 },
    { lat: 49.3278908, lng: 21.2323361 },
    { lat: 49.3275305, lng: 21.2324053 },
    { lat: 49.3273862, lng: 21.2323587 },
    { lat: 49.3273585, lng: 21.231013 },
    { lat: 49.3257398, lng: 21.2276946 },
    { lat: 49.3247001, lng: 21.2247701 },
  ],
  Abrahámovce: [
    { lat: 49.1710088, lng: 21.3207335 },
    { lat: 49.1701473, lng: 21.3210077 },
    { lat: 49.1688681, lng: 21.3216495 },
    { lat: 49.165757, lng: 21.3234852 },
    { lat: 49.162726, lng: 21.3251786 },
    { lat: 49.1623022, lng: 21.3251531 },
    { lat: 49.1606789, lng: 21.3257274 },
    { lat: 49.1605958, lng: 21.3258795 },
    { lat: 49.1602427, lng: 21.3272048 },
    { lat: 49.1601908, lng: 21.3275726 },
    { lat: 49.16009, lng: 21.3277727 },
    { lat: 49.1598985, lng: 21.3278425 },
    { lat: 49.1597098, lng: 21.3273294 },
    { lat: 49.1593404, lng: 21.3274328 },
    { lat: 49.1590912, lng: 21.327379 },
    { lat: 49.1589443, lng: 21.327615 },
    { lat: 49.1586229, lng: 21.327897 },
    { lat: 49.158355, lng: 21.3282245 },
    { lat: 49.1580694, lng: 21.3289048 },
    { lat: 49.1578505, lng: 21.3304652 },
    { lat: 49.1577506, lng: 21.3306181 },
    { lat: 49.1581443, lng: 21.331333 },
    { lat: 49.1571867, lng: 21.3326827 },
    { lat: 49.1572164, lng: 21.3343099 },
    { lat: 49.1569265, lng: 21.3345483 },
    { lat: 49.1565381, lng: 21.3346885 },
    { lat: 49.1558914, lng: 21.3354137 },
    { lat: 49.1554788, lng: 21.3352332 },
    { lat: 49.1554435, lng: 21.3344732 },
    { lat: 49.1553701, lng: 21.3343532 },
    { lat: 49.1553954, lng: 21.3339931 },
    { lat: 49.1549892, lng: 21.333765 },
    { lat: 49.1537717, lng: 21.3342891 },
    { lat: 49.1535818, lng: 21.3338113 },
    { lat: 49.1533274, lng: 21.3341446 },
    { lat: 49.1529322, lng: 21.3342505 },
    { lat: 49.1524257, lng: 21.3341704 },
    { lat: 49.1524405, lng: 21.334738 },
    { lat: 49.1517323, lng: 21.3348721 },
    { lat: 49.1529472, lng: 21.3363285 },
    { lat: 49.1528082, lng: 21.3372145 },
    { lat: 49.1522068, lng: 21.3376795 },
    { lat: 49.1522356, lng: 21.3378536 },
    { lat: 49.1525971, lng: 21.3382041 },
    { lat: 49.1528716, lng: 21.3395125 },
    { lat: 49.1528463, lng: 21.3396145 },
    { lat: 49.1519876, lng: 21.3390764 },
    { lat: 49.1514915, lng: 21.3393047 },
    { lat: 49.1507989, lng: 21.3405627 },
    { lat: 49.1505484, lng: 21.3425699 },
    { lat: 49.1500774, lng: 21.3427788 },
    { lat: 49.1499879, lng: 21.3434374 },
    { lat: 49.1495937, lng: 21.3442893 },
    { lat: 49.1494082, lng: 21.344401 },
    { lat: 49.1495646, lng: 21.3447841 },
    { lat: 49.1495474, lng: 21.3449553 },
    { lat: 49.1496932, lng: 21.3455828 },
    { lat: 49.1498793, lng: 21.3457104 },
    { lat: 49.1502558, lng: 21.3455836 },
    { lat: 49.1504601, lng: 21.3460622 },
    { lat: 49.1506913, lng: 21.3463762 },
    { lat: 49.1509626, lng: 21.3459684 },
    { lat: 49.1514207, lng: 21.3465779 },
    { lat: 49.1519234, lng: 21.3474395 },
    { lat: 49.1516288, lng: 21.3480048 },
    { lat: 49.1509601, lng: 21.3489728 },
    { lat: 49.1509837, lng: 21.3493863 },
    { lat: 49.151061, lng: 21.3496448 },
    { lat: 49.1513045, lng: 21.3496578 },
    { lat: 49.1531538, lng: 21.3501277 },
    { lat: 49.1546356, lng: 21.3505861 },
    { lat: 49.1556511, lng: 21.3507199 },
    { lat: 49.1569119, lng: 21.3511179 },
    { lat: 49.1570641, lng: 21.3513906 },
    { lat: 49.157259, lng: 21.351476 },
    { lat: 49.1571408, lng: 21.351963 },
    { lat: 49.1570595, lng: 21.3525884 },
    { lat: 49.1572836, lng: 21.3527511 },
    { lat: 49.157451, lng: 21.3527435 },
    { lat: 49.1576624, lng: 21.3529302 },
    { lat: 49.1579211, lng: 21.3529953 },
    { lat: 49.1578875, lng: 21.3534574 },
    { lat: 49.1585398, lng: 21.3536779 },
    { lat: 49.1617178, lng: 21.3556229 },
    { lat: 49.1625545, lng: 21.357117 },
    { lat: 49.1633361, lng: 21.3589961 },
    { lat: 49.1639886, lng: 21.3598631 },
    { lat: 49.1642807, lng: 21.3600367 },
    { lat: 49.1645243, lng: 21.3597531 },
    { lat: 49.1647228, lng: 21.3591674 },
    { lat: 49.1651339, lng: 21.3590307 },
    { lat: 49.1656366, lng: 21.358 },
    { lat: 49.1658479, lng: 21.358597 },
    { lat: 49.1664128, lng: 21.3579118 },
    { lat: 49.1665625, lng: 21.3578008 },
    { lat: 49.1668097, lng: 21.3578283 },
    { lat: 49.1670347, lng: 21.3574506 },
    { lat: 49.1675691, lng: 21.3572965 },
    { lat: 49.1677131, lng: 21.3571114 },
    { lat: 49.1692386, lng: 21.3560857 },
    { lat: 49.1697885, lng: 21.3576967 },
    { lat: 49.1693203, lng: 21.3598541 },
    { lat: 49.1696461, lng: 21.3599481 },
    { lat: 49.1696164, lng: 21.3603119 },
    { lat: 49.1697292, lng: 21.3605453 },
    { lat: 49.1698549, lng: 21.3605791 },
    { lat: 49.1698888, lng: 21.3607267 },
    { lat: 49.1698606, lng: 21.3612619 },
    { lat: 49.1700083, lng: 21.3615764 },
    { lat: 49.1701386, lng: 21.3621682 },
    { lat: 49.1726982, lng: 21.3630737 },
    { lat: 49.1734795, lng: 21.36347 },
    { lat: 49.1742693, lng: 21.3641487 },
    { lat: 49.1750088, lng: 21.3632072 },
    { lat: 49.1751116, lng: 21.3624715 },
    { lat: 49.17497, lng: 21.3612601 },
    { lat: 49.1768071, lng: 21.3593634 },
    { lat: 49.1776896, lng: 21.3581264 },
    { lat: 49.1779352, lng: 21.3573078 },
    { lat: 49.178005, lng: 21.3568876 },
    { lat: 49.1785099, lng: 21.3557083 },
    { lat: 49.1785159, lng: 21.3553022 },
    { lat: 49.1786014, lng: 21.3551467 },
    { lat: 49.1785895, lng: 21.3549393 },
    { lat: 49.1787445, lng: 21.3547041 },
    { lat: 49.1787601, lng: 21.3543556 },
    { lat: 49.1788882, lng: 21.3541299 },
    { lat: 49.1789232, lng: 21.3538624 },
    { lat: 49.1788474, lng: 21.3535918 },
    { lat: 49.178972, lng: 21.3530085 },
    { lat: 49.1790799, lng: 21.3529345 },
    { lat: 49.1791082, lng: 21.3528246 },
    { lat: 49.1790658, lng: 21.3526114 },
    { lat: 49.1793738, lng: 21.3517359 },
    { lat: 49.1796558, lng: 21.351871 },
    { lat: 49.179423, lng: 21.3504357 },
    { lat: 49.1789278, lng: 21.3489214 },
    { lat: 49.1774246, lng: 21.3457156 },
    { lat: 49.1774214, lng: 21.3444805 },
    { lat: 49.1771539, lng: 21.3418141 },
    { lat: 49.176944, lng: 21.3417568 },
    { lat: 49.1764856, lng: 21.3401243 },
    { lat: 49.1765325, lng: 21.3394291 },
    { lat: 49.1764981, lng: 21.3390348 },
    { lat: 49.1762221, lng: 21.3386448 },
    { lat: 49.1758168, lng: 21.3376197 },
    { lat: 49.1763678, lng: 21.3369743 },
    { lat: 49.1754983, lng: 21.336076 },
    { lat: 49.1747455, lng: 21.3350212 },
    { lat: 49.1743621, lng: 21.3351885 },
    { lat: 49.173885, lng: 21.3352929 },
    { lat: 49.1715205, lng: 21.3342644 },
    { lat: 49.171612, lng: 21.3331872 },
    { lat: 49.172484, lng: 21.3309728 },
    { lat: 49.1711312, lng: 21.3302113 },
    { lat: 49.170955, lng: 21.3297 },
    { lat: 49.1705721, lng: 21.3290144 },
    { lat: 49.1705145, lng: 21.3279907 },
    { lat: 49.170561, lng: 21.3274024 },
    { lat: 49.1708631, lng: 21.3267445 },
    { lat: 49.1710649, lng: 21.326501 },
    { lat: 49.1720922, lng: 21.326014 },
    { lat: 49.1715772, lng: 21.3226931 },
    { lat: 49.1712486, lng: 21.3230225 },
    { lat: 49.1708166, lng: 21.3228416 },
    { lat: 49.1710088, lng: 21.3207335 },
  ],
  NižnáPolianka: [
    { lat: 49.4029826, lng: 21.3892003 },
    { lat: 49.4027161, lng: 21.3896298 },
    { lat: 49.401805, lng: 21.3899793 },
    { lat: 49.4010502, lng: 21.3898272 },
    { lat: 49.3993994, lng: 21.3910064 },
    { lat: 49.3987839, lng: 21.3908855 },
    { lat: 49.3988285, lng: 21.3913722 },
    { lat: 49.3986163, lng: 21.3914262 },
    { lat: 49.3984513, lng: 21.3913209 },
    { lat: 49.3981749, lng: 21.3928306 },
    { lat: 49.3979419, lng: 21.3930533 },
    { lat: 49.3976846, lng: 21.3935254 },
    { lat: 49.3974089, lng: 21.3937563 },
    { lat: 49.3974512, lng: 21.3950061 },
    { lat: 49.3975493, lng: 21.3957882 },
    { lat: 49.3975008, lng: 21.3967816 },
    { lat: 49.3969692, lng: 21.3983789 },
    { lat: 49.3963702, lng: 21.3999757 },
    { lat: 49.3955278, lng: 21.4027554 },
    { lat: 49.395357, lng: 21.4049237 },
    { lat: 49.3950616, lng: 21.4070537 },
    { lat: 49.394899, lng: 21.407655 },
    { lat: 49.3939338, lng: 21.4079999 },
    { lat: 49.3948365, lng: 21.4100225 },
    { lat: 49.3957305, lng: 21.4115527 },
    { lat: 49.3960975, lng: 21.4127191 },
    { lat: 49.3978578, lng: 21.4154952 },
    { lat: 49.4006437, lng: 21.4211924 },
    { lat: 49.4012334, lng: 21.4220173 },
    { lat: 49.4013962, lng: 21.4220463 },
    { lat: 49.4028476, lng: 21.4236765 },
    { lat: 49.4032348, lng: 21.4241923 },
    { lat: 49.4033894, lng: 21.424012 },
    { lat: 49.403568, lng: 21.4243072 },
    { lat: 49.4035594, lng: 21.4245564 },
    { lat: 49.404005, lng: 21.4246788 },
    { lat: 49.4060345, lng: 21.4249487 },
    { lat: 49.4068423, lng: 21.4254178 },
    { lat: 49.407478, lng: 21.4255757 },
    { lat: 49.4083459, lng: 21.4255881 },
    { lat: 49.4091706, lng: 21.4258271 },
    { lat: 49.4096203, lng: 21.4260916 },
    { lat: 49.4100128, lng: 21.4261256 },
    { lat: 49.4112884, lng: 21.4269712 },
    { lat: 49.4116111, lng: 21.4273932 },
    { lat: 49.4115869, lng: 21.4275275 },
    { lat: 49.4122138, lng: 21.4283591 },
    { lat: 49.4123005, lng: 21.4283174 },
    { lat: 49.414164, lng: 21.430165 },
    { lat: 49.414491, lng: 21.429967 },
    { lat: 49.4146085, lng: 21.4298977 },
    { lat: 49.4147846, lng: 21.4296807 },
    { lat: 49.4152767, lng: 21.4286264 },
    { lat: 49.4155418, lng: 21.4279189 },
    { lat: 49.4157681, lng: 21.4276347 },
    { lat: 49.416048, lng: 21.426675 },
    { lat: 49.416081, lng: 21.4265697 },
    { lat: 49.416089, lng: 21.426525 },
    { lat: 49.4163018, lng: 21.4256234 },
    { lat: 49.4163318, lng: 21.4255999 },
    { lat: 49.4164777, lng: 21.4252923 },
    { lat: 49.4166068, lng: 21.4251295 },
    { lat: 49.416667, lng: 21.424469 },
    { lat: 49.4166755, lng: 21.4244113 },
    { lat: 49.416676, lng: 21.424398 },
    { lat: 49.4167263, lng: 21.4242593 },
    { lat: 49.416744, lng: 21.424121 },
    { lat: 49.4167639, lng: 21.4239626 },
    { lat: 49.4168393, lng: 21.4239814 },
    { lat: 49.416947, lng: 21.423943 },
    { lat: 49.4169577, lng: 21.4239426 },
    { lat: 49.4170889, lng: 21.4238773 },
    { lat: 49.417144, lng: 21.423799 },
    { lat: 49.417236, lng: 21.423697 },
    { lat: 49.417363, lng: 21.42354 },
    { lat: 49.4173719, lng: 21.423536 },
    { lat: 49.417502, lng: 21.423335 },
    { lat: 49.4175934, lng: 21.423197 },
    { lat: 49.4175941, lng: 21.4231807 },
    { lat: 49.4177378, lng: 21.4227845 },
    { lat: 49.417739, lng: 21.422773 },
    { lat: 49.4178317, lng: 21.4223808 },
    { lat: 49.4179819, lng: 21.4218826 },
    { lat: 49.4180783, lng: 21.4217784 },
    { lat: 49.4180955, lng: 21.4217435 },
    { lat: 49.4181436, lng: 21.4217075 },
    { lat: 49.4181699, lng: 21.4216795 },
    { lat: 49.418194, lng: 21.421643 },
    { lat: 49.4183283, lng: 21.4214354 },
    { lat: 49.418339, lng: 21.42141 },
    { lat: 49.4183759, lng: 21.4213387 },
    { lat: 49.4183741, lng: 21.4213287 },
    { lat: 49.418415, lng: 21.421285 },
    { lat: 49.4184837, lng: 21.4211993 },
    { lat: 49.4185467, lng: 21.4210261 },
    { lat: 49.4186173, lng: 21.420885 },
    { lat: 49.4186391, lng: 21.4207897 },
    { lat: 49.4186539, lng: 21.420755 },
    { lat: 49.418661, lng: 21.420738 },
    { lat: 49.418735, lng: 21.420678 },
    { lat: 49.4187912, lng: 21.4206244 },
    { lat: 49.418786, lng: 21.420615 },
    { lat: 49.418829, lng: 21.420578 },
    { lat: 49.4188429, lng: 21.4205635 },
    { lat: 49.4188751, lng: 21.4204907 },
    { lat: 49.418958, lng: 21.420308 },
    { lat: 49.419052, lng: 21.42013 },
    { lat: 49.419126, lng: 21.4199781 },
    { lat: 49.419132, lng: 21.419953 },
    { lat: 49.4192251, lng: 21.4196809 },
    { lat: 49.4193092, lng: 21.419512 },
    { lat: 49.4193141, lng: 21.4194927 },
    { lat: 49.419375, lng: 21.419339 },
    { lat: 49.419411, lng: 21.419265 },
    { lat: 49.419427, lng: 21.4192186 },
    { lat: 49.41945, lng: 21.419026 },
    { lat: 49.419466, lng: 21.418846 },
    { lat: 49.4194776, lng: 21.4187525 },
    { lat: 49.419504, lng: 21.418679 },
    { lat: 49.419545, lng: 21.4185625 },
    { lat: 49.4196364, lng: 21.4184018 },
    { lat: 49.4197185, lng: 21.4182699 },
    { lat: 49.41977, lng: 21.418161 },
    { lat: 49.4198272, lng: 21.4180358 },
    { lat: 49.4198366, lng: 21.4179868 },
    { lat: 49.419904, lng: 21.417848 },
    { lat: 49.41991, lng: 21.4178401 },
    { lat: 49.4199531, lng: 21.4176737 },
    { lat: 49.4199748, lng: 21.4176032 },
    { lat: 49.420013, lng: 21.417543 },
    { lat: 49.4200283, lng: 21.4175246 },
    { lat: 49.42007, lng: 21.417489 },
    { lat: 49.4201571, lng: 21.4174227 },
    { lat: 49.4201778, lng: 21.4174126 },
    { lat: 49.420271, lng: 21.417283 },
    { lat: 49.4204701, lng: 21.4169917 },
    { lat: 49.4204916, lng: 21.4169758 },
    { lat: 49.420512, lng: 21.416961 },
    { lat: 49.420598, lng: 21.416924 },
    { lat: 49.4207001, lng: 21.41688 },
    { lat: 49.4207486, lng: 21.416861 },
    { lat: 49.4208381, lng: 21.4167547 },
    { lat: 49.4208475, lng: 21.4167508 },
    { lat: 49.42086, lng: 21.416743 },
    { lat: 49.420918, lng: 21.416766 },
    { lat: 49.4211882, lng: 21.4168304 },
    { lat: 49.4211981, lng: 21.4168287 },
    { lat: 49.4213753, lng: 21.4168391 },
    { lat: 49.4214301, lng: 21.4168787 },
    { lat: 49.4215241, lng: 21.4169387 },
    { lat: 49.4215482, lng: 21.4169527 },
    { lat: 49.421555, lng: 21.416942 },
    { lat: 49.4217462, lng: 21.4167912 },
    { lat: 49.421885, lng: 21.416522 },
    { lat: 49.4219581, lng: 21.4163801 },
    { lat: 49.421964, lng: 21.416355 },
    { lat: 49.4221236, lng: 21.4159393 },
    { lat: 49.422134, lng: 21.415931 },
    { lat: 49.4222532, lng: 21.4159103 },
    { lat: 49.4223218, lng: 21.4158522 },
    { lat: 49.422356, lng: 21.415811 },
    { lat: 49.4223797, lng: 21.4157826 },
    { lat: 49.422476, lng: 21.415728 },
    { lat: 49.422542, lng: 21.4156995 },
    { lat: 49.422581, lng: 21.415642 },
    { lat: 49.4226101, lng: 21.4155887 },
    { lat: 49.4226203, lng: 21.4155881 },
    { lat: 49.4227257, lng: 21.4155039 },
    { lat: 49.4227968, lng: 21.4154788 },
    { lat: 49.4228338, lng: 21.4153676 },
    { lat: 49.422836, lng: 21.415349 },
    { lat: 49.422938, lng: 21.415108 },
    { lat: 49.422979, lng: 21.415002 },
    { lat: 49.422971, lng: 21.41495 },
    { lat: 49.4229511, lng: 21.4148477 },
    { lat: 49.4229537, lng: 21.4148377 },
    { lat: 49.422962, lng: 21.414798 },
    { lat: 49.4230113, lng: 21.4146692 },
    { lat: 49.423018, lng: 21.414647 },
    { lat: 49.423018, lng: 21.414591 },
    { lat: 49.4230491, lng: 21.414391 },
    { lat: 49.423052, lng: 21.414366 },
    { lat: 49.4230799, lng: 21.4142659 },
    { lat: 49.423153, lng: 21.414025 },
    { lat: 49.4231621, lng: 21.4139637 },
    { lat: 49.4231752, lng: 21.4139522 },
    { lat: 49.4232912, lng: 21.4137673 },
    { lat: 49.423314, lng: 21.413679 },
    { lat: 49.4233491, lng: 21.413555 },
    { lat: 49.4234067, lng: 21.4134282 },
    { lat: 49.423409, lng: 21.413415 },
    { lat: 49.423506, lng: 21.413121 },
    { lat: 49.4235862, lng: 21.4128898 },
    { lat: 49.4236112, lng: 21.4126859 },
    { lat: 49.4236631, lng: 21.412574 },
    { lat: 49.423803, lng: 21.412383 },
    { lat: 49.4238984, lng: 21.4122587 },
    { lat: 49.4239779, lng: 21.4121962 },
    { lat: 49.4240274, lng: 21.4121162 },
    { lat: 49.424124, lng: 21.4117947 },
    { lat: 49.4242172, lng: 21.4115917 },
    { lat: 49.4242768, lng: 21.4112897 },
    { lat: 49.4244841, lng: 21.4107996 },
    { lat: 49.424488, lng: 21.410783 },
    { lat: 49.4244988, lng: 21.4107802 },
    { lat: 49.4245731, lng: 21.4107239 },
    { lat: 49.4247208, lng: 21.4104803 },
    { lat: 49.424742, lng: 21.410438 },
    { lat: 49.4248321, lng: 21.4102604 },
    { lat: 49.424925, lng: 21.410042 },
    { lat: 49.425109, lng: 21.409599 },
    { lat: 49.4251746, lng: 21.4094584 },
    { lat: 49.425184, lng: 21.409432 },
    { lat: 49.425247, lng: 21.409314 },
    { lat: 49.4252889, lng: 21.4092383 },
    { lat: 49.425291, lng: 21.409224 },
    { lat: 49.4253954, lng: 21.4089862 },
    { lat: 49.4256158, lng: 21.4086453 },
    { lat: 49.425618, lng: 21.408635 },
    { lat: 49.4256701, lng: 21.4085323 },
    { lat: 49.4248839, lng: 21.4088673 },
    { lat: 49.4245276, lng: 21.4085636 },
    { lat: 49.4236987, lng: 21.407649 },
    { lat: 49.4232545, lng: 21.4074199 },
    { lat: 49.4230096, lng: 21.4058116 },
    { lat: 49.4209178, lng: 21.4015929 },
    { lat: 49.4206362, lng: 21.4014684 },
    { lat: 49.4199899, lng: 21.4014054 },
    { lat: 49.4194613, lng: 21.4022613 },
    { lat: 49.4183684, lng: 21.4032053 },
    { lat: 49.4176757, lng: 21.4027589 },
    { lat: 49.4171903, lng: 21.4021215 },
    { lat: 49.4168356, lng: 21.4021477 },
    { lat: 49.4165809, lng: 21.4019812 },
    { lat: 49.4162433, lng: 21.4015852 },
    { lat: 49.4161564, lng: 21.4013137 },
    { lat: 49.4152815, lng: 21.4003599 },
    { lat: 49.4141039, lng: 21.3999883 },
    { lat: 49.4140623, lng: 21.3998712 },
    { lat: 49.413907, lng: 21.3997923 },
    { lat: 49.4137797, lng: 21.3995886 },
    { lat: 49.4135535, lng: 21.399513 },
    { lat: 49.4124052, lng: 21.3981416 },
    { lat: 49.4106537, lng: 21.3962181 },
    { lat: 49.4075688, lng: 21.3926443 },
    { lat: 49.4070222, lng: 21.3916504 },
    { lat: 49.4068674, lng: 21.3923919 },
    { lat: 49.4067948, lng: 21.3932961 },
    { lat: 49.40654, lng: 21.3932725 },
    { lat: 49.4064854, lng: 21.3935631 },
    { lat: 49.4061187, lng: 21.3931414 },
    { lat: 49.4061472, lng: 21.3930759 },
    { lat: 49.4061132, lng: 21.3929543 },
    { lat: 49.4058607, lng: 21.3926624 },
    { lat: 49.4058631, lng: 21.3925217 },
    { lat: 49.4058012, lng: 21.3924848 },
    { lat: 49.4056556, lng: 21.3921166 },
    { lat: 49.4057072, lng: 21.3918815 },
    { lat: 49.4054484, lng: 21.3917642 },
    { lat: 49.4053657, lng: 21.3916264 },
    { lat: 49.405445, lng: 21.3913424 },
    { lat: 49.4047644, lng: 21.3906498 },
    { lat: 49.403455, lng: 21.3896587 },
    { lat: 49.4029826, lng: 21.3892003 },
  ],
  Nemcovce: [
    { lat: 49.2374097, lng: 21.3992609 },
    { lat: 49.2363256, lng: 21.3993563 },
    { lat: 49.2356305, lng: 21.3995944 },
    { lat: 49.2348483, lng: 21.40031 },
    { lat: 49.2339845, lng: 21.4008979 },
    { lat: 49.2338052, lng: 21.4014182 },
    { lat: 49.2331721, lng: 21.4024692 },
    { lat: 49.2329277, lng: 21.4032999 },
    { lat: 49.2326672, lng: 21.4034757 },
    { lat: 49.2325033, lng: 21.4041033 },
    { lat: 49.2319647, lng: 21.4053033 },
    { lat: 49.2315833, lng: 21.4059187 },
    { lat: 49.2313707, lng: 21.4064937 },
    { lat: 49.2311989, lng: 21.4066952 },
    { lat: 49.2309718, lng: 21.4071618 },
    { lat: 49.2308938, lng: 21.4076837 },
    { lat: 49.2304163, lng: 21.4089072 },
    { lat: 49.2300914, lng: 21.4094128 },
    { lat: 49.22971, lng: 21.4096815 },
    { lat: 49.2293344, lng: 21.4101492 },
    { lat: 49.2288718, lng: 21.4104853 },
    { lat: 49.22843, lng: 21.4105021 },
    { lat: 49.2277715, lng: 21.4103007 },
    { lat: 49.2270861, lng: 21.4104036 },
    { lat: 49.2260965, lng: 21.4102703 },
    { lat: 49.2258482, lng: 21.4105489 },
    { lat: 49.2255213, lng: 21.4103368 },
    { lat: 49.2253528, lng: 21.4098466 },
    { lat: 49.2231382, lng: 21.4106325 },
    { lat: 49.2217187, lng: 21.4113013 },
    { lat: 49.2203336, lng: 21.4122998 },
    { lat: 49.2192319, lng: 21.4134806 },
    { lat: 49.2184021, lng: 21.4139557 },
    { lat: 49.2180811, lng: 21.414219 },
    { lat: 49.2160328, lng: 21.415042 },
    { lat: 49.2149517, lng: 21.4160437 },
    { lat: 49.2135255, lng: 21.4165273 },
    { lat: 49.212289, lng: 21.4173693 },
    { lat: 49.2114626, lng: 21.4176174 },
    { lat: 49.2104022, lng: 21.4177561 },
    { lat: 49.2103306, lng: 21.4177653 },
    { lat: 49.2096764, lng: 21.4225681 },
    { lat: 49.2085617, lng: 21.4254361 },
    { lat: 49.2078316, lng: 21.4274882 },
    { lat: 49.2075895, lng: 21.4283707 },
    { lat: 49.2072818, lng: 21.429194 },
    { lat: 49.206536, lng: 21.4290877 },
    { lat: 49.2054294, lng: 21.429914 },
    { lat: 49.2046411, lng: 21.4326008 },
    { lat: 49.2042503, lng: 21.432465 },
    { lat: 49.2042496, lng: 21.4327177 },
    { lat: 49.2030793, lng: 21.4367727 },
    { lat: 49.2026061, lng: 21.4363182 },
    { lat: 49.2020534, lng: 21.4360815 },
    { lat: 49.2014623, lng: 21.4360635 },
    { lat: 49.2010214, lng: 21.4364186 },
    { lat: 49.2005334, lng: 21.4369718 },
    { lat: 49.1999736, lng: 21.4383085 },
    { lat: 49.1972375, lng: 21.4414481 },
    { lat: 49.1975688, lng: 21.4414639 },
    { lat: 49.1979036, lng: 21.4419546 },
    { lat: 49.2020433, lng: 21.4434823 },
    { lat: 49.2025483, lng: 21.4435151 },
    { lat: 49.2030015, lng: 21.4437882 },
    { lat: 49.2040166, lng: 21.4450391 },
    { lat: 49.2046967, lng: 21.4470793 },
    { lat: 49.2069475, lng: 21.4484237 },
    { lat: 49.2079769, lng: 21.4487289 },
    { lat: 49.2086103, lng: 21.4488474 },
    { lat: 49.2086289, lng: 21.4487098 },
    { lat: 49.2089551, lng: 21.4489011 },
    { lat: 49.2090819, lng: 21.4486648 },
    { lat: 49.2092435, lng: 21.4486428 },
    { lat: 49.2092968, lng: 21.4488148 },
    { lat: 49.2092882, lng: 21.4491774 },
    { lat: 49.2095249, lng: 21.4491952 },
    { lat: 49.2101569, lng: 21.4494182 },
    { lat: 49.2101616, lng: 21.4492315 },
    { lat: 49.212099, lng: 21.4497756 },
    { lat: 49.2124214, lng: 21.4497546 },
    { lat: 49.2134086, lng: 21.4493092 },
    { lat: 49.2142972, lng: 21.4487976 },
    { lat: 49.2151283, lng: 21.4487099 },
    { lat: 49.2172496, lng: 21.4472105 },
    { lat: 49.2172358, lng: 21.4470106 },
    { lat: 49.2169742, lng: 21.4465183 },
    { lat: 49.2172005, lng: 21.4462957 },
    { lat: 49.2171937, lng: 21.4459784 },
    { lat: 49.2173583, lng: 21.4455649 },
    { lat: 49.2170552, lng: 21.4454313 },
    { lat: 49.2170653, lng: 21.4452334 },
    { lat: 49.2169071, lng: 21.4448259 },
    { lat: 49.2169582, lng: 21.4444899 },
    { lat: 49.2172937, lng: 21.4442297 },
    { lat: 49.2172138, lng: 21.4435643 },
    { lat: 49.217301, lng: 21.4435872 },
    { lat: 49.217392, lng: 21.4428814 },
    { lat: 49.2174762, lng: 21.4426454 },
    { lat: 49.2173129, lng: 21.4424038 },
    { lat: 49.2173625, lng: 21.4420567 },
    { lat: 49.2175869, lng: 21.4417299 },
    { lat: 49.217425, lng: 21.4414583 },
    { lat: 49.2177246, lng: 21.4413578 },
    { lat: 49.2180522, lng: 21.4409729 },
    { lat: 49.2178601, lng: 21.4405169 },
    { lat: 49.2176789, lng: 21.439506 },
    { lat: 49.2181141, lng: 21.4388373 },
    { lat: 49.2177938, lng: 21.4383879 },
    { lat: 49.2183434, lng: 21.4376341 },
    { lat: 49.2182771, lng: 21.4373651 },
    { lat: 49.2185351, lng: 21.4369129 },
    { lat: 49.2184348, lng: 21.436364 },
    { lat: 49.2187708, lng: 21.4357657 },
    { lat: 49.2187199, lng: 21.4355615 },
    { lat: 49.2188342, lng: 21.435277 },
    { lat: 49.2198442, lng: 21.4334854 },
    { lat: 49.2209529, lng: 21.4318435 },
    { lat: 49.2212529, lng: 21.4312063 },
    { lat: 49.2226422, lng: 21.4286868 },
    { lat: 49.2231534, lng: 21.4279668 },
    { lat: 49.2237479, lng: 21.4273254 },
    { lat: 49.2248624, lng: 21.4256519 },
    { lat: 49.2256199, lng: 21.4243871 },
    { lat: 49.225736, lng: 21.4241265 },
    { lat: 49.2257485, lng: 21.4239305 },
    { lat: 49.2259069, lng: 21.4237369 },
    { lat: 49.2261556, lng: 21.4231207 },
    { lat: 49.2268581, lng: 21.4221316 },
    { lat: 49.2282749, lng: 21.4208272 },
    { lat: 49.2289403, lng: 21.4199983 },
    { lat: 49.2310303, lng: 21.4183247 },
    { lat: 49.2312631, lng: 21.4180947 },
    { lat: 49.2311695, lng: 21.4180038 },
    { lat: 49.231168, lng: 21.4177595 },
    { lat: 49.2320135, lng: 21.4165488 },
    { lat: 49.2322464, lng: 21.4160067 },
    { lat: 49.2328295, lng: 21.4150597 },
    { lat: 49.2335365, lng: 21.4145709 },
    { lat: 49.2336448, lng: 21.4137769 },
    { lat: 49.2341952, lng: 21.4122798 },
    { lat: 49.2347694, lng: 21.4113334 },
    { lat: 49.2352331, lng: 21.4103423 },
    { lat: 49.2357979, lng: 21.4096053 },
    { lat: 49.2363093, lng: 21.4088244 },
    { lat: 49.2366195, lng: 21.4083071 },
    { lat: 49.2367109, lng: 21.407741 },
    { lat: 49.2366843, lng: 21.4066051 },
    { lat: 49.2361024, lng: 21.4034829 },
    { lat: 49.236167, lng: 21.4021165 },
    { lat: 49.2369559, lng: 21.4004351 },
    { lat: 49.2372729, lng: 21.3995903 },
    { lat: 49.2374097, lng: 21.3992609 },
  ],
  VyšnáPolianka: [
    { lat: 49.4146802, lng: 21.35471 },
    { lat: 49.4146117, lng: 21.3548326 },
    { lat: 49.4148066, lng: 21.3553329 },
    { lat: 49.4140242, lng: 21.3560756 },
    { lat: 49.4137678, lng: 21.3564215 },
    { lat: 49.4132234, lng: 21.3569916 },
    { lat: 49.4132403, lng: 21.3570681 },
    { lat: 49.4130357, lng: 21.3577226 },
    { lat: 49.4137671, lng: 21.3585227 },
    { lat: 49.4142634, lng: 21.3591791 },
    { lat: 49.4142303, lng: 21.35928 },
    { lat: 49.4178002, lng: 21.3636274 },
    { lat: 49.4182894, lng: 21.3651437 },
    { lat: 49.4186471, lng: 21.3652182 },
    { lat: 49.4204283, lng: 21.3676702 },
    { lat: 49.4241551, lng: 21.3722319 },
    { lat: 49.4254637, lng: 21.3736972 },
    { lat: 49.425925, lng: 21.3740787 },
    { lat: 49.4277064, lng: 21.376544 },
    { lat: 49.428428, lng: 21.3770969 },
    { lat: 49.4294369, lng: 21.3752102 },
    { lat: 49.4315169, lng: 21.3726084 },
    { lat: 49.4316202, lng: 21.3726801 },
    { lat: 49.431868, lng: 21.3724974 },
    { lat: 49.4321077, lng: 21.3733031 },
    { lat: 49.432518, lng: 21.373913 },
    { lat: 49.43257, lng: 21.373561 },
    { lat: 49.4325724, lng: 21.373551 },
    { lat: 49.4326054, lng: 21.3732638 },
    { lat: 49.4326239, lng: 21.3732272 },
    { lat: 49.4326406, lng: 21.3731448 },
    { lat: 49.432649, lng: 21.373029 },
    { lat: 49.4326534, lng: 21.3729853 },
    { lat: 49.432648, lng: 21.372936 },
    { lat: 49.4326368, lng: 21.3727984 },
    { lat: 49.4326443, lng: 21.3727513 },
    { lat: 49.4329869, lng: 21.3723647 },
    { lat: 49.43327, lng: 21.3720889 },
    { lat: 49.4333719, lng: 21.3720337 },
    { lat: 49.4334947, lng: 21.3719063 },
    { lat: 49.4336454, lng: 21.3717891 },
    { lat: 49.4338004, lng: 21.3715331 },
    { lat: 49.433885, lng: 21.371494 },
    { lat: 49.4340753, lng: 21.3714332 },
    { lat: 49.435403, lng: 21.370818 },
    { lat: 49.435512, lng: 21.370771 },
    { lat: 49.4359759, lng: 21.3705666 },
    { lat: 49.435982, lng: 21.370567 },
    { lat: 49.4363317, lng: 21.3705112 },
    { lat: 49.4366664, lng: 21.3702295 },
    { lat: 49.4370898, lng: 21.3701223 },
    { lat: 49.4373607, lng: 21.3698749 },
    { lat: 49.4379736, lng: 21.3693517 },
    { lat: 49.4382822, lng: 21.3692445 },
    { lat: 49.4386466, lng: 21.3689836 },
    { lat: 49.4389937, lng: 21.3686626 },
    { lat: 49.4391183, lng: 21.3684956 },
    { lat: 49.4392183, lng: 21.3681855 },
    { lat: 49.43962, lng: 21.3676227 },
    { lat: 49.4397812, lng: 21.3671117 },
    { lat: 49.4400601, lng: 21.3668679 },
    { lat: 49.440323, lng: 21.366342 },
    { lat: 49.4403733, lng: 21.3662427 },
    { lat: 49.440694, lng: 21.3657095 },
    { lat: 49.440917, lng: 21.3652617 },
    { lat: 49.4413312, lng: 21.3647225 },
    { lat: 49.4416601, lng: 21.3642063 },
    { lat: 49.441742, lng: 21.3638496 },
    { lat: 49.4418881, lng: 21.3635968 },
    { lat: 49.4421674, lng: 21.3629107 },
    { lat: 49.4423132, lng: 21.3623223 },
    { lat: 49.4424416, lng: 21.3616225 },
    { lat: 49.4423074, lng: 21.3611765 },
    { lat: 49.4425235, lng: 21.3607067 },
    { lat: 49.4425937, lng: 21.3598889 },
    { lat: 49.4427593, lng: 21.3591812 },
    { lat: 49.4429866, lng: 21.3580591 },
    { lat: 49.4430564, lng: 21.357437 },
    { lat: 49.442925, lng: 21.3570659 },
    { lat: 49.4428654, lng: 21.3567082 },
    { lat: 49.4429887, lng: 21.3564082 },
    { lat: 49.4433798, lng: 21.3558523 },
    { lat: 49.4435041, lng: 21.3556469 },
    { lat: 49.4438309, lng: 21.3544806 },
    { lat: 49.444301, lng: 21.3537985 },
    { lat: 49.4444777, lng: 21.3533649 },
    { lat: 49.4440669, lng: 21.3530394 },
    { lat: 49.4427594, lng: 21.3515086 },
    { lat: 49.4414264, lng: 21.3487443 },
    { lat: 49.4406446, lng: 21.3484814 },
    { lat: 49.4394767, lng: 21.3469621 },
    { lat: 49.4384184, lng: 21.3457605 },
    { lat: 49.4351285, lng: 21.3425978 },
    { lat: 49.4346814, lng: 21.3422842 },
    { lat: 49.4332566, lng: 21.3406779 },
    { lat: 49.4322918, lng: 21.339062 },
    { lat: 49.4306675, lng: 21.3360428 },
    { lat: 49.4304524, lng: 21.3353974 },
    { lat: 49.4293387, lng: 21.3364216 },
    { lat: 49.4289274, lng: 21.3368509 },
    { lat: 49.4283909, lng: 21.3378047 },
    { lat: 49.4277981, lng: 21.3377673 },
    { lat: 49.4273515, lng: 21.3387881 },
    { lat: 49.4272022, lng: 21.3390656 },
    { lat: 49.4266363, lng: 21.3401842 },
    { lat: 49.426089, lng: 21.3411344 },
    { lat: 49.4257939, lng: 21.3416828 },
    { lat: 49.4235727, lng: 21.345152 },
    { lat: 49.4224316, lng: 21.3458265 },
    { lat: 49.4221977, lng: 21.3460611 },
    { lat: 49.421639, lng: 21.3470549 },
    { lat: 49.4210325, lng: 21.347894 },
    { lat: 49.4194056, lng: 21.3494027 },
    { lat: 49.4188814, lng: 21.3498219 },
    { lat: 49.4177459, lng: 21.3511376 },
    { lat: 49.4171468, lng: 21.3516111 },
    { lat: 49.415758, lng: 21.3534181 },
    { lat: 49.4149369, lng: 21.354032 },
    { lat: 49.4146802, lng: 21.35471 },
  ],
  Jedlinka: [
    { lat: 49.408411, lng: 21.3416508 },
    { lat: 49.408461, lng: 21.3419262 },
    { lat: 49.408082, lng: 21.3429921 },
    { lat: 49.4077379, lng: 21.3442907 },
    { lat: 49.4072558, lng: 21.3452894 },
    { lat: 49.4068452, lng: 21.345826 },
    { lat: 49.4064788, lng: 21.346172 },
    { lat: 49.4058446, lng: 21.3470211 },
    { lat: 49.4053585, lng: 21.3480391 },
    { lat: 49.4045207, lng: 21.3494569 },
    { lat: 49.4040867, lng: 21.3499564 },
    { lat: 49.4038896, lng: 21.3500301 },
    { lat: 49.4039044, lng: 21.3501068 },
    { lat: 49.4037342, lng: 21.3504001 },
    { lat: 49.403539, lng: 21.3504584 },
    { lat: 49.40282, lng: 21.3509518 },
    { lat: 49.4025331, lng: 21.3510241 },
    { lat: 49.4022253, lng: 21.3512659 },
    { lat: 49.4017423, lng: 21.3518642 },
    { lat: 49.4009765, lng: 21.3531265 },
    { lat: 49.4000672, lng: 21.3541818 },
    { lat: 49.3998801, lng: 21.3544722 },
    { lat: 49.3994451, lng: 21.3547503 },
    { lat: 49.3988945, lng: 21.3552701 },
    { lat: 49.3976045, lng: 21.356611 },
    { lat: 49.3976912, lng: 21.3566144 },
    { lat: 49.3976002, lng: 21.3567338 },
    { lat: 49.397111, lng: 21.3568938 },
    { lat: 49.3967698, lng: 21.3573369 },
    { lat: 49.3956146, lng: 21.3600478 },
    { lat: 49.3952116, lng: 21.3607595 },
    { lat: 49.395098, lng: 21.3610636 },
    { lat: 49.3949932, lng: 21.3612117 },
    { lat: 49.3948074, lng: 21.3613221 },
    { lat: 49.3944768, lng: 21.3617832 },
    { lat: 49.3942648, lng: 21.3617228 },
    { lat: 49.3939812, lng: 21.3618346 },
    { lat: 49.3937793, lng: 21.3621289 },
    { lat: 49.3936081, lng: 21.3621584 },
    { lat: 49.3934551, lng: 21.3623489 },
    { lat: 49.3933166, lng: 21.3630639 },
    { lat: 49.3931772, lng: 21.3633322 },
    { lat: 49.3930179, lng: 21.363504 },
    { lat: 49.3927824, lng: 21.3641011 },
    { lat: 49.3928719, lng: 21.3647287 },
    { lat: 49.3925544, lng: 21.3648021 },
    { lat: 49.3925116, lng: 21.3653663 },
    { lat: 49.3924361, lng: 21.3657067 },
    { lat: 49.391681, lng: 21.3671116 },
    { lat: 49.3917832, lng: 21.3675628 },
    { lat: 49.3917784, lng: 21.3677427 },
    { lat: 49.391635, lng: 21.3681409 },
    { lat: 49.3916539, lng: 21.3686716 },
    { lat: 49.3912788, lng: 21.3696441 },
    { lat: 49.3913006, lng: 21.3698875 },
    { lat: 49.391594, lng: 21.3700369 },
    { lat: 49.3916277, lng: 21.3706435 },
    { lat: 49.3914691, lng: 21.3711811 },
    { lat: 49.3911202, lng: 21.3719026 },
    { lat: 49.3908474, lng: 21.3728367 },
    { lat: 49.3906191, lng: 21.3730248 },
    { lat: 49.3906633, lng: 21.3733793 },
    { lat: 49.3906062, lng: 21.3735475 },
    { lat: 49.3904314, lng: 21.3737193 },
    { lat: 49.3904391, lng: 21.373982 },
    { lat: 49.3905689, lng: 21.3742266 },
    { lat: 49.3904845, lng: 21.3743765 },
    { lat: 49.3898574, lng: 21.3750508 },
    { lat: 49.3898053, lng: 21.3752653 },
    { lat: 49.3900382, lng: 21.3758011 },
    { lat: 49.3897661, lng: 21.3766079 },
    { lat: 49.3889966, lng: 21.3772241 },
    { lat: 49.3886186, lng: 21.3773427 },
    { lat: 49.3885429, lng: 21.3779719 },
    { lat: 49.3886604, lng: 21.3782957 },
    { lat: 49.3883702, lng: 21.3787939 },
    { lat: 49.3880582, lng: 21.3789744 },
    { lat: 49.3880572, lng: 21.3793273 },
    { lat: 49.3883699, lng: 21.3795538 },
    { lat: 49.3883277, lng: 21.3800655 },
    { lat: 49.3881109, lng: 21.3802687 },
    { lat: 49.387783, lng: 21.380102 },
    { lat: 49.3876796, lng: 21.3803696 },
    { lat: 49.3879324, lng: 21.3807822 },
    { lat: 49.3879718, lng: 21.3809334 },
    { lat: 49.3880069, lng: 21.381135 },
    { lat: 49.3881695, lng: 21.3809638 },
    { lat: 49.388556, lng: 21.3817536 },
    { lat: 49.3889737, lng: 21.3810701 },
    { lat: 49.3891763, lng: 21.3811951 },
    { lat: 49.3893635, lng: 21.3811699 },
    { lat: 49.3892825, lng: 21.3815103 },
    { lat: 49.3897936, lng: 21.3810597 },
    { lat: 49.3898421, lng: 21.3808021 },
    { lat: 49.3902273, lng: 21.3807773 },
    { lat: 49.3905573, lng: 21.380561 },
    { lat: 49.3911214, lng: 21.3806871 },
    { lat: 49.3913256, lng: 21.3808648 },
    { lat: 49.3914152, lng: 21.3808091 },
    { lat: 49.3915928, lng: 21.3805375 },
    { lat: 49.3915965, lng: 21.3803596 },
    { lat: 49.3918581, lng: 21.3799583 },
    { lat: 49.3919416, lng: 21.3796622 },
    { lat: 49.3921221, lng: 21.3792886 },
    { lat: 49.3925356, lng: 21.3792011 },
    { lat: 49.3927264, lng: 21.379374 },
    { lat: 49.3930765, lng: 21.3791154 },
    { lat: 49.3931645, lng: 21.3792401 },
    { lat: 49.39347, lng: 21.3791821 },
    { lat: 49.3938269, lng: 21.3782343 },
    { lat: 49.3940495, lng: 21.3781189 },
    { lat: 49.3946108, lng: 21.3806348 },
    { lat: 49.3948974, lng: 21.3821547 },
    { lat: 49.3951488, lng: 21.3831711 },
    { lat: 49.3954268, lng: 21.3840224 },
    { lat: 49.39585, lng: 21.3850591 },
    { lat: 49.3962964, lng: 21.3843244 },
    { lat: 49.3965287, lng: 21.3841924 },
    { lat: 49.3966974, lng: 21.3838821 },
    { lat: 49.3969577, lng: 21.3837066 },
    { lat: 49.3972361, lng: 21.3836816 },
    { lat: 49.3975867, lng: 21.3831294 },
    { lat: 49.3978429, lng: 21.3830871 },
    { lat: 49.3979322, lng: 21.3831745 },
    { lat: 49.3980611, lng: 21.3829666 },
    { lat: 49.3982065, lng: 21.3831321 },
    { lat: 49.3986313, lng: 21.3830042 },
    { lat: 49.3988176, lng: 21.3831424 },
    { lat: 49.3991289, lng: 21.3830884 },
    { lat: 49.3992104, lng: 21.3829495 },
    { lat: 49.3994897, lng: 21.3827713 },
    { lat: 49.3998586, lng: 21.3829629 },
    { lat: 49.399939, lng: 21.3829386 },
    { lat: 49.4003018, lng: 21.3826491 },
    { lat: 49.4002993, lng: 21.382563 },
    { lat: 49.4004167, lng: 21.3823472 },
    { lat: 49.400638, lng: 21.3822253 },
    { lat: 49.4010056, lng: 21.38217 },
    { lat: 49.4012318, lng: 21.381869 },
    { lat: 49.4013722, lng: 21.3820179 },
    { lat: 49.401834, lng: 21.3819611 },
    { lat: 49.4019491, lng: 21.3817801 },
    { lat: 49.4032393, lng: 21.380663 },
    { lat: 49.4034991, lng: 21.380623 },
    { lat: 49.4043206, lng: 21.3798994 },
    { lat: 49.4043845, lng: 21.3797068 },
    { lat: 49.4047072, lng: 21.3793124 },
    { lat: 49.4048954, lng: 21.3788251 },
    { lat: 49.405156, lng: 21.3783562 },
    { lat: 49.4052262, lng: 21.3780259 },
    { lat: 49.4055475, lng: 21.3776659 },
    { lat: 49.4056664, lng: 21.377347 },
    { lat: 49.4056363, lng: 21.3773191 },
    { lat: 49.4056513, lng: 21.377068 },
    { lat: 49.4057613, lng: 21.3767104 },
    { lat: 49.4062716, lng: 21.3754735 },
    { lat: 49.4065585, lng: 21.3749775 },
    { lat: 49.4074217, lng: 21.3738784 },
    { lat: 49.4079821, lng: 21.3729339 },
    { lat: 49.4084575, lng: 21.3719617 },
    { lat: 49.4086859, lng: 21.3716175 },
    { lat: 49.4104179, lng: 21.3693446 },
    { lat: 49.4109326, lng: 21.3686015 },
    { lat: 49.4111356, lng: 21.368195 },
    { lat: 49.411755, lng: 21.3673836 },
    { lat: 49.4119283, lng: 21.3670406 },
    { lat: 49.4121528, lng: 21.3664126 },
    { lat: 49.4126476, lng: 21.3653785 },
    { lat: 49.4128274, lng: 21.3648356 },
    { lat: 49.4130674, lng: 21.3643164 },
    { lat: 49.4138638, lng: 21.3616444 },
    { lat: 49.414001, lng: 21.3607644 },
    { lat: 49.4141305, lng: 21.3602423 },
    { lat: 49.414143, lng: 21.3596774 },
    { lat: 49.4142303, lng: 21.35928 },
    { lat: 49.4142634, lng: 21.3591791 },
    { lat: 49.4137671, lng: 21.3585227 },
    { lat: 49.4130357, lng: 21.3577226 },
    { lat: 49.4132403, lng: 21.3570681 },
    { lat: 49.4132234, lng: 21.3569916 },
    { lat: 49.4137678, lng: 21.3564215 },
    { lat: 49.4140242, lng: 21.3560756 },
    { lat: 49.4148066, lng: 21.3553329 },
    { lat: 49.4146117, lng: 21.3548326 },
    { lat: 49.4146802, lng: 21.35471 },
    { lat: 49.414318, lng: 21.3540303 },
    { lat: 49.4130167, lng: 21.3523294 },
    { lat: 49.4125289, lng: 21.352161 },
    { lat: 49.4116979, lng: 21.3510702 },
    { lat: 49.4113101, lng: 21.3496386 },
    { lat: 49.4110319, lng: 21.3492891 },
    { lat: 49.4107613, lng: 21.348736 },
    { lat: 49.4107304, lng: 21.347996 },
    { lat: 49.4106044, lng: 21.3473956 },
    { lat: 49.4105377, lng: 21.3445358 },
    { lat: 49.4099097, lng: 21.3430542 },
    { lat: 49.4098657, lng: 21.3425186 },
    { lat: 49.4095211, lng: 21.3424718 },
    { lat: 49.409392, lng: 21.3425538 },
    { lat: 49.4088796, lng: 21.3421611 },
    { lat: 49.4088173, lng: 21.3419931 },
    { lat: 49.408411, lng: 21.3416508 },
  ],
  Oľšavce: [
    { lat: 49.2130185, lng: 21.3859828 },
    { lat: 49.2121302, lng: 21.3840879 },
    { lat: 49.2111197, lng: 21.3816836 },
    { lat: 49.2114206, lng: 21.3811659 },
    { lat: 49.2110332, lng: 21.3797815 },
    { lat: 49.2108858, lng: 21.3785584 },
    { lat: 49.2112383, lng: 21.3782642 },
    { lat: 49.210641, lng: 21.3761005 },
    { lat: 49.2109502, lng: 21.3741917 },
    { lat: 49.2101219, lng: 21.3725873 },
    { lat: 49.2109722, lng: 21.3703364 },
    { lat: 49.2103708, lng: 21.3681104 },
    { lat: 49.2103313, lng: 21.3668561 },
    { lat: 49.2095623, lng: 21.3674781 },
    { lat: 49.2092383, lng: 21.3642179 },
    { lat: 49.207729, lng: 21.3621135 },
    { lat: 49.205572, lng: 21.357379 },
    { lat: 49.2050383, lng: 21.3557809 },
    { lat: 49.2046832, lng: 21.354432 },
    { lat: 49.204535, lng: 21.3532308 },
    { lat: 49.2045592, lng: 21.3525703 },
    { lat: 49.2047973, lng: 21.3523634 },
    { lat: 49.2064035, lng: 21.3505364 },
    { lat: 49.2072432, lng: 21.3488891 },
    { lat: 49.2077766, lng: 21.3456804 },
    { lat: 49.2072505, lng: 21.3431332 },
    { lat: 49.2067997, lng: 21.3430699 },
    { lat: 49.2066325, lng: 21.3433439 },
    { lat: 49.2049289, lng: 21.3441683 },
    { lat: 49.2040045, lng: 21.3441476 },
    { lat: 49.2029547, lng: 21.3439208 },
    { lat: 49.2021003, lng: 21.344626 },
    { lat: 49.201628, lng: 21.3449334 },
    { lat: 49.2010064, lng: 21.3457418 },
    { lat: 49.2005638, lng: 21.3464522 },
    { lat: 49.2000625, lng: 21.3470674 },
    { lat: 49.1993718, lng: 21.3482825 },
    { lat: 49.1986222, lng: 21.3491408 },
    { lat: 49.1978586, lng: 21.3498309 },
    { lat: 49.1971532, lng: 21.3501738 },
    { lat: 49.1965006, lng: 21.3504043 },
    { lat: 49.1957614, lng: 21.351573 },
    { lat: 49.1966157, lng: 21.3538184 },
    { lat: 49.1966853, lng: 21.3537719 },
    { lat: 49.196734, lng: 21.3539351 },
    { lat: 49.1965613, lng: 21.3541046 },
    { lat: 49.1968147, lng: 21.3545306 },
    { lat: 49.1970925, lng: 21.3553979 },
    { lat: 49.196981, lng: 21.3566738 },
    { lat: 49.1969834, lng: 21.3576673 },
    { lat: 49.1961302, lng: 21.3595892 },
    { lat: 49.1961478, lng: 21.3600617 },
    { lat: 49.1962449, lng: 21.360186 },
    { lat: 49.1965025, lng: 21.3609456 },
    { lat: 49.1967849, lng: 21.3614758 },
    { lat: 49.1966947, lng: 21.3617342 },
    { lat: 49.1952593, lng: 21.3633693 },
    { lat: 49.1945645, lng: 21.3642573 },
    { lat: 49.193728, lng: 21.364886 },
    { lat: 49.1942836, lng: 21.3661722 },
    { lat: 49.1940505, lng: 21.3664671 },
    { lat: 49.1947969, lng: 21.3690259 },
    { lat: 49.1959315, lng: 21.3683213 },
    { lat: 49.1966226, lng: 21.3677998 },
    { lat: 49.1971831, lng: 21.3675384 },
    { lat: 49.197766, lng: 21.3671593 },
    { lat: 49.1983759, lng: 21.3667128 },
    { lat: 49.1994009, lng: 21.3656832 },
    { lat: 49.2002523, lng: 21.3653337 },
    { lat: 49.2001011, lng: 21.3649016 },
    { lat: 49.1998993, lng: 21.364504 },
    { lat: 49.19971, lng: 21.3638165 },
    { lat: 49.2001197, lng: 21.3637429 },
    { lat: 49.2003044, lng: 21.363642 },
    { lat: 49.2006016, lng: 21.3631149 },
    { lat: 49.2009679, lng: 21.3621084 },
    { lat: 49.2014593, lng: 21.3611911 },
    { lat: 49.2016349, lng: 21.3604342 },
    { lat: 49.2019987, lng: 21.3601104 },
    { lat: 49.2024845, lng: 21.3600268 },
    { lat: 49.2025434, lng: 21.3599608 },
    { lat: 49.2027678, lng: 21.3614594 },
    { lat: 49.2028042, lng: 21.3620326 },
    { lat: 49.2027611, lng: 21.3633956 },
    { lat: 49.2028345, lng: 21.3641976 },
    { lat: 49.2024395, lng: 21.3667327 },
    { lat: 49.2024295, lng: 21.3675009 },
    { lat: 49.2021724, lng: 21.367479 },
    { lat: 49.2015874, lng: 21.3680116 },
    { lat: 49.2014736, lng: 21.3680447 },
    { lat: 49.2012602, lng: 21.3682828 },
    { lat: 49.1996548, lng: 21.3689333 },
    { lat: 49.1984798, lng: 21.3693435 },
    { lat: 49.1981899, lng: 21.3693542 },
    { lat: 49.1972268, lng: 21.3697361 },
    { lat: 49.1968708, lng: 21.370065 },
    { lat: 49.1966797, lng: 21.3699868 },
    { lat: 49.1957965, lng: 21.3702967 },
    { lat: 49.1950735, lng: 21.3704595 },
    { lat: 49.195024, lng: 21.3719807 },
    { lat: 49.1952552, lng: 21.3728393 },
    { lat: 49.1953412, lng: 21.3740709 },
    { lat: 49.1956475, lng: 21.3752921 },
    { lat: 49.1955623, lng: 21.3760711 },
    { lat: 49.1957212, lng: 21.3764918 },
    { lat: 49.1958621, lng: 21.376616 },
    { lat: 49.1958598, lng: 21.3772971 },
    { lat: 49.1959935, lng: 21.3780546 },
    { lat: 49.1959033, lng: 21.3783151 },
    { lat: 49.1963684, lng: 21.3787515 },
    { lat: 49.1967045, lng: 21.3791547 },
    { lat: 49.1968424, lng: 21.3797126 },
    { lat: 49.1970492, lng: 21.3798175 },
    { lat: 49.1971536, lng: 21.3799988 },
    { lat: 49.1970624, lng: 21.3803429 },
    { lat: 49.1968897, lng: 21.3806656 },
    { lat: 49.1968804, lng: 21.3811095 },
    { lat: 49.1969651, lng: 21.3814645 },
    { lat: 49.1968946, lng: 21.382014 },
    { lat: 49.1966348, lng: 21.3821348 },
    { lat: 49.1966115, lng: 21.3824868 },
    { lat: 49.1967506, lng: 21.3826639 },
    { lat: 49.1966654, lng: 21.383013 },
    { lat: 49.1963914, lng: 21.3833387 },
    { lat: 49.1963588, lng: 21.3834881 },
    { lat: 49.1964852, lng: 21.3836981 },
    { lat: 49.196605, lng: 21.3837151 },
    { lat: 49.1966045, lng: 21.3838871 },
    { lat: 49.196299, lng: 21.3841696 },
    { lat: 49.1962632, lng: 21.3843717 },
    { lat: 49.1965958, lng: 21.3846651 },
    { lat: 49.1963389, lng: 21.3854523 },
    { lat: 49.1962947, lng: 21.3858241 },
    { lat: 49.1960097, lng: 21.3863847 },
    { lat: 49.1960335, lng: 21.3867274 },
    { lat: 49.1959495, lng: 21.387012 },
    { lat: 49.1960517, lng: 21.3872726 },
    { lat: 49.1960149, lng: 21.3875133 },
    { lat: 49.1960672, lng: 21.387718 },
    { lat: 49.1960013, lng: 21.3877801 },
    { lat: 49.1959823, lng: 21.3878951 },
    { lat: 49.1960104, lng: 21.3881986 },
    { lat: 49.1955635, lng: 21.3896762 },
    { lat: 49.1955647, lng: 21.3899085 },
    { lat: 49.1954513, lng: 21.390566 },
    { lat: 49.1953297, lng: 21.3909107 },
    { lat: 49.1952251, lng: 21.3915215 },
    { lat: 49.193336, lng: 21.3916718 },
    { lat: 49.193051, lng: 21.3915521 },
    { lat: 49.1924101, lng: 21.3916374 },
    { lat: 49.1922049, lng: 21.3918184 },
    { lat: 49.1921512, lng: 21.3921735 },
    { lat: 49.1914854, lng: 21.3927487 },
    { lat: 49.1912853, lng: 21.3927994 },
    { lat: 49.1911634, lng: 21.393088 },
    { lat: 49.1912284, lng: 21.3932702 },
    { lat: 49.1914019, lng: 21.3931634 },
    { lat: 49.1916076, lng: 21.3931917 },
    { lat: 49.1917947, lng: 21.393398 },
    { lat: 49.1919038, lng: 21.3937805 },
    { lat: 49.1918879, lng: 21.394001 },
    { lat: 49.1917651, lng: 21.3942274 },
    { lat: 49.1915589, lng: 21.3952156 },
    { lat: 49.1912728, lng: 21.3956989 },
    { lat: 49.1913852, lng: 21.3967661 },
    { lat: 49.19139, lng: 21.397314 },
    { lat: 49.1914555, lng: 21.3973987 },
    { lat: 49.1921172, lng: 21.3976682 },
    { lat: 49.1946173, lng: 21.3979432 },
    { lat: 49.1955424, lng: 21.3994039 },
    { lat: 49.1971435, lng: 21.3963069 },
    { lat: 49.1985246, lng: 21.3957922 },
    { lat: 49.1997842, lng: 21.3951235 },
    { lat: 49.2011201, lng: 21.3946366 },
    { lat: 49.2025989, lng: 21.3938477 },
    { lat: 49.2041974, lng: 21.3927336 },
    { lat: 49.206124, lng: 21.3919446 },
    { lat: 49.2076251, lng: 21.3911443 },
    { lat: 49.2087961, lng: 21.3904117 },
    { lat: 49.2103879, lng: 21.3884553 },
    { lat: 49.2118054, lng: 21.3871151 },
    { lat: 49.2120935, lng: 21.3867689 },
    { lat: 49.2130185, lng: 21.3859828 },
  ],
  Ondavka: [
    { lat: 49.4479065, lng: 21.3165123 },
    { lat: 49.4470829, lng: 21.3192568 },
    { lat: 49.4464431, lng: 21.3200626 },
    { lat: 49.4463134, lng: 21.3205041 },
    { lat: 49.4460712, lng: 21.3209814 },
    { lat: 49.4460306, lng: 21.3212797 },
    { lat: 49.4455164, lng: 21.3228576 },
    { lat: 49.4450278, lng: 21.3228839 },
    { lat: 49.4446645, lng: 21.3231542 },
    { lat: 49.4443594, lng: 21.323295 },
    { lat: 49.4442142, lng: 21.3235955 },
    { lat: 49.4440787, lng: 21.3230916 },
    { lat: 49.4438308, lng: 21.3226621 },
    { lat: 49.4432986, lng: 21.3226193 },
    { lat: 49.4429172, lng: 21.3224678 },
    { lat: 49.4426655, lng: 21.3229685 },
    { lat: 49.4427479, lng: 21.3231319 },
    { lat: 49.4426446, lng: 21.3233283 },
    { lat: 49.4423341, lng: 21.3233664 },
    { lat: 49.4415623, lng: 21.3219328 },
    { lat: 49.4410544, lng: 21.3216518 },
    { lat: 49.4408311, lng: 21.3217051 },
    { lat: 49.4406898, lng: 21.3219085 },
    { lat: 49.4400247, lng: 21.3219064 },
    { lat: 49.4399475, lng: 21.3218489 },
    { lat: 49.4397562, lng: 21.3221827 },
    { lat: 49.4392766, lng: 21.3226628 },
    { lat: 49.438305, lng: 21.322486 },
    { lat: 49.438002, lng: 21.3231565 },
    { lat: 49.4379212, lng: 21.3235772 },
    { lat: 49.4377121, lng: 21.3242155 },
    { lat: 49.4369696, lng: 21.3250474 },
    { lat: 49.4366311, lng: 21.325959 },
    { lat: 49.4364498, lng: 21.32663 },
    { lat: 49.435328, lng: 21.3286275 },
    { lat: 49.4333479, lng: 21.3313068 },
    { lat: 49.4325459, lng: 21.3315766 },
    { lat: 49.4319992, lng: 21.332969 },
    { lat: 49.4312595, lng: 21.3341977 },
    { lat: 49.4304524, lng: 21.3353974 },
    { lat: 49.4306675, lng: 21.3360428 },
    { lat: 49.4322918, lng: 21.339062 },
    { lat: 49.4332566, lng: 21.3406779 },
    { lat: 49.4346814, lng: 21.3422842 },
    { lat: 49.4351285, lng: 21.3425978 },
    { lat: 49.4384184, lng: 21.3457605 },
    { lat: 49.4394767, lng: 21.3469621 },
    { lat: 49.4406446, lng: 21.3484814 },
    { lat: 49.4414264, lng: 21.3487443 },
    { lat: 49.4427594, lng: 21.3515086 },
    { lat: 49.4440669, lng: 21.3530394 },
    { lat: 49.4444777, lng: 21.3533649 },
    { lat: 49.4445874, lng: 21.3530167 },
    { lat: 49.4447581, lng: 21.3526177 },
    { lat: 49.4448948, lng: 21.3519715 },
    { lat: 49.445225, lng: 21.351452 },
    { lat: 49.4456514, lng: 21.350773 },
    { lat: 49.446072, lng: 21.350108 },
    { lat: 49.4460765, lng: 21.3501031 },
    { lat: 49.4464113, lng: 21.3496608 },
    { lat: 49.4464646, lng: 21.3495061 },
    { lat: 49.4467532, lng: 21.3492028 },
    { lat: 49.4469948, lng: 21.3488384 },
    { lat: 49.4473719, lng: 21.3485366 },
    { lat: 49.4476491, lng: 21.3481726 },
    { lat: 49.4481804, lng: 21.3478884 },
    { lat: 49.4482974, lng: 21.3476982 },
    { lat: 49.4486223, lng: 21.3473756 },
    { lat: 49.4487405, lng: 21.3470049 },
    { lat: 49.4487422, lng: 21.3469522 },
    { lat: 49.4493641, lng: 21.3465737 },
    { lat: 49.4494808, lng: 21.3463539 },
    { lat: 49.4495015, lng: 21.3457097 },
    { lat: 49.4495085, lng: 21.3455848 },
    { lat: 49.4496598, lng: 21.3453285 },
    { lat: 49.450025, lng: 21.345075 },
    { lat: 49.4500408, lng: 21.345067 },
    { lat: 49.450054, lng: 21.345052 },
    { lat: 49.4504234, lng: 21.344684 },
    { lat: 49.450544, lng: 21.344218 },
    { lat: 49.4505467, lng: 21.3442139 },
    { lat: 49.4507361, lng: 21.3434977 },
    { lat: 49.4510005, lng: 21.3425178 },
    { lat: 49.4509926, lng: 21.3420017 },
    { lat: 49.450991, lng: 21.341991 },
    { lat: 49.4509729, lng: 21.3415828 },
    { lat: 49.451048, lng: 21.341044 },
    { lat: 49.4510052, lng: 21.3403587 },
    { lat: 49.4509176, lng: 21.3395641 },
    { lat: 49.4510046, lng: 21.3392761 },
    { lat: 49.4510925, lng: 21.338908 },
    { lat: 49.4511135, lng: 21.3385556 },
    { lat: 49.451333, lng: 21.337512 },
    { lat: 49.4514246, lng: 21.3370856 },
    { lat: 49.4514231, lng: 21.3370797 },
    { lat: 49.451288, lng: 21.336563 },
    { lat: 49.451161, lng: 21.33605 },
    { lat: 49.4511167, lng: 21.3358823 },
    { lat: 49.4508544, lng: 21.3349046 },
    { lat: 49.4508594, lng: 21.3346862 },
    { lat: 49.450861, lng: 21.334583 },
    { lat: 49.450956, lng: 21.333362 },
    { lat: 49.4509656, lng: 21.3329933 },
    { lat: 49.450972, lng: 21.332846 },
    { lat: 49.4509419, lng: 21.3325603 },
    { lat: 49.4508246, lng: 21.3321717 },
    { lat: 49.4504559, lng: 21.3315918 },
    { lat: 49.4502057, lng: 21.3310898 },
    { lat: 49.4501391, lng: 21.3309506 },
    { lat: 49.4495267, lng: 21.3293969 },
    { lat: 49.4497528, lng: 21.3277629 },
    { lat: 49.449879, lng: 21.3272136 },
    { lat: 49.4499265, lng: 21.3267762 },
    { lat: 49.4501026, lng: 21.3257776 },
    { lat: 49.450088, lng: 21.3251632 },
    { lat: 49.4500342, lng: 21.3248181 },
    { lat: 49.450032, lng: 21.324806 },
    { lat: 49.45001, lng: 21.324542 },
    { lat: 49.4499977, lng: 21.3243948 },
    { lat: 49.449994, lng: 21.324384 },
    { lat: 49.4498876, lng: 21.3240017 },
    { lat: 49.4498795, lng: 21.3239305 },
    { lat: 49.4499366, lng: 21.3232323 },
    { lat: 49.4499275, lng: 21.3231279 },
    { lat: 49.4498919, lng: 21.3230331 },
    { lat: 49.449714, lng: 21.322659 },
    { lat: 49.4497115, lng: 21.3226527 },
    { lat: 49.4492161, lng: 21.3215122 },
    { lat: 49.449145, lng: 21.3214063 },
    { lat: 49.449153, lng: 21.3209874 },
    { lat: 49.4492574, lng: 21.3207052 },
    { lat: 49.4493773, lng: 21.3198722 },
    { lat: 49.4493293, lng: 21.319467 },
    { lat: 49.449325, lng: 21.31944 },
    { lat: 49.4493208, lng: 21.3194188 },
    { lat: 49.449166, lng: 21.318755 },
    { lat: 49.4491638, lng: 21.3187378 },
    { lat: 49.4491003, lng: 21.318399 },
    { lat: 49.4487566, lng: 21.3178485 },
    { lat: 49.4483593, lng: 21.3173772 },
    { lat: 49.4479065, lng: 21.3165123 },
  ],
  Brezovka: [
    { lat: 49.2707391, lng: 21.4048775 },
    { lat: 49.2692088, lng: 21.4072926 },
    { lat: 49.2683948, lng: 21.4081563 },
    { lat: 49.2672898, lng: 21.4099945 },
    { lat: 49.2663927, lng: 21.411714 },
    { lat: 49.2656145, lng: 21.4141523 },
    { lat: 49.2631081, lng: 21.4177414 },
    { lat: 49.2635071, lng: 21.418314 },
    { lat: 49.2641778, lng: 21.4196621 },
    { lat: 49.2642656, lng: 21.4207084 },
    { lat: 49.2644459, lng: 21.4213795 },
    { lat: 49.264719, lng: 21.4219092 },
    { lat: 49.2654657, lng: 21.4236344 },
    { lat: 49.2655134, lng: 21.4238409 },
    { lat: 49.2665786, lng: 21.4243344 },
    { lat: 49.2665526, lng: 21.4256061 },
    { lat: 49.2666252, lng: 21.4269406 },
    { lat: 49.2668505, lng: 21.4273032 },
    { lat: 49.2669934, lng: 21.4291621 },
    { lat: 49.2667608, lng: 21.4294391 },
    { lat: 49.2664776, lng: 21.4308111 },
    { lat: 49.2661728, lng: 21.4314683 },
    { lat: 49.2658163, lng: 21.432521 },
    { lat: 49.2665185, lng: 21.432446 },
    { lat: 49.267015, lng: 21.431989 },
    { lat: 49.2678417, lng: 21.432531 },
    { lat: 49.2686687, lng: 21.4318729 },
    { lat: 49.2694777, lng: 21.4310243 },
    { lat: 49.2700447, lng: 21.4312939 },
    { lat: 49.2711155, lng: 21.4308952 },
    { lat: 49.2712216, lng: 21.4308329 },
    { lat: 49.2713237, lng: 21.4305165 },
    { lat: 49.2720572, lng: 21.4295051 },
    { lat: 49.2723248, lng: 21.4289227 },
    { lat: 49.2727001, lng: 21.4284828 },
    { lat: 49.2734465, lng: 21.4288083 },
    { lat: 49.2738157, lng: 21.4291569 },
    { lat: 49.2743974, lng: 21.4300946 },
    { lat: 49.2748471, lng: 21.4304298 },
    { lat: 49.2753903, lng: 21.4310382 },
    { lat: 49.2759405, lng: 21.4320112 },
    { lat: 49.2763796, lng: 21.432919 },
    { lat: 49.276361, lng: 21.4323906 },
    { lat: 49.2770855, lng: 21.4316932 },
    { lat: 49.2773783, lng: 21.4303862 },
    { lat: 49.2775671, lng: 21.4286002 },
    { lat: 49.2780312, lng: 21.4254846 },
    { lat: 49.2791451, lng: 21.4210465 },
    { lat: 49.2804884, lng: 21.4178406 },
    { lat: 49.2808426, lng: 21.4167941 },
    { lat: 49.2810036, lng: 21.4160974 },
    { lat: 49.2814236, lng: 21.4151073 },
    { lat: 49.2815709, lng: 21.4150241 },
    { lat: 49.2823566, lng: 21.4136908 },
    { lat: 49.2828743, lng: 21.413084 },
    { lat: 49.2832181, lng: 21.4111801 },
    { lat: 49.2832744, lng: 21.4099528 },
    { lat: 49.2831513, lng: 21.4092429 },
    { lat: 49.283122, lng: 21.408779 },
    { lat: 49.282882, lng: 21.4081096 },
    { lat: 49.2823474, lng: 21.4074127 },
    { lat: 49.2819397, lng: 21.406046 },
    { lat: 49.2812038, lng: 21.4045067 },
    { lat: 49.2806604, lng: 21.4036614 },
    { lat: 49.2804421, lng: 21.4035891 },
    { lat: 49.2800763, lng: 21.4037698 },
    { lat: 49.2799199, lng: 21.4040807 },
    { lat: 49.2789409, lng: 21.4033019 },
    { lat: 49.2785343, lng: 21.4033297 },
    { lat: 49.2783396, lng: 21.4032479 },
    { lat: 49.2774861, lng: 21.4021828 },
    { lat: 49.2778242, lng: 21.4008988 },
    { lat: 49.277359, lng: 21.400655 },
    { lat: 49.2775102, lng: 21.3997092 },
    { lat: 49.276382, lng: 21.3987566 },
    { lat: 49.2756935, lng: 21.3987605 },
    { lat: 49.2754181, lng: 21.3986457 },
    { lat: 49.2746767, lng: 21.3988428 },
    { lat: 49.2728427, lng: 21.4013727 },
    { lat: 49.2712952, lng: 21.4036901 },
    { lat: 49.2707391, lng: 21.4048775 },
  ],
  Livov: [
    { lat: 49.2492938, lng: 21.1262135 },
    { lat: 49.2495149, lng: 21.1255909 },
    { lat: 49.2497119, lng: 21.1243171 },
    { lat: 49.249975, lng: 21.1239133 },
    { lat: 49.2502407, lng: 21.1237381 },
    { lat: 49.2503656, lng: 21.1235282 },
    { lat: 49.2506126, lng: 21.1234426 },
    { lat: 49.2508656, lng: 21.1228987 },
    { lat: 49.2513663, lng: 21.1223299 },
    { lat: 49.2514846, lng: 21.1220907 },
    { lat: 49.2522076, lng: 21.1211845 },
    { lat: 49.2527029, lng: 21.120869 },
    { lat: 49.2527663, lng: 21.1206356 },
    { lat: 49.2528901, lng: 21.1194629 },
    { lat: 49.2530374, lng: 21.1188576 },
    { lat: 49.2532019, lng: 21.1186163 },
    { lat: 49.2532932, lng: 21.1181957 },
    { lat: 49.2534625, lng: 21.1177858 },
    { lat: 49.2537348, lng: 21.1173618 },
    { lat: 49.2538662, lng: 21.1167465 },
    { lat: 49.2540812, lng: 21.116151 },
    { lat: 49.2545687, lng: 21.1153436 },
    { lat: 49.2551714, lng: 21.1137347 },
    { lat: 49.2556167, lng: 21.1129142 },
    { lat: 49.2557761, lng: 21.1122229 },
    { lat: 49.2559288, lng: 21.1119692 },
    { lat: 49.2563653, lng: 21.1107609 },
    { lat: 49.2566434, lng: 21.109635 },
    { lat: 49.2570734, lng: 21.1088242 },
    { lat: 49.2571361, lng: 21.1085284 },
    { lat: 49.2574802, lng: 21.1080641 },
    { lat: 49.2576578, lng: 21.1072418 },
    { lat: 49.2577691, lng: 21.1061455 },
    { lat: 49.2578979, lng: 21.1059482 },
    { lat: 49.2582157, lng: 21.1056771 },
    { lat: 49.2584973, lng: 21.1055452 },
    { lat: 49.2586889, lng: 21.1055573 },
    { lat: 49.259088, lng: 21.1058644 },
    { lat: 49.2592534, lng: 21.1058993 },
    { lat: 49.2596491, lng: 21.1057442 },
    { lat: 49.2599505, lng: 21.1054206 },
    { lat: 49.2602612, lng: 21.1049273 },
    { lat: 49.2609474, lng: 21.1041809 },
    { lat: 49.2612304, lng: 21.1041285 },
    { lat: 49.2616502, lng: 21.1034582 },
    { lat: 49.2622075, lng: 21.1027595 },
    { lat: 49.2628732, lng: 21.1021079 },
    { lat: 49.2633607, lng: 21.1018735 },
    { lat: 49.2635417, lng: 21.1016925 },
    { lat: 49.2642983, lng: 21.1014524 },
    { lat: 49.2645068, lng: 21.1013219 },
    { lat: 49.2643907, lng: 21.095851 },
    { lat: 49.2641803, lng: 21.0949316 },
    { lat: 49.2633924, lng: 21.0928851 },
    { lat: 49.2628472, lng: 21.0910771 },
    { lat: 49.2627219, lng: 21.0901086 },
    { lat: 49.2627257, lng: 21.0896287 },
    { lat: 49.2626006, lng: 21.0893459 },
    { lat: 49.2626093, lng: 21.0891957 },
    { lat: 49.2624186, lng: 21.0884378 },
    { lat: 49.2620941, lng: 21.0874716 },
    { lat: 49.2619501, lng: 21.0872316 },
    { lat: 49.2619743, lng: 21.0867203 },
    { lat: 49.2623683, lng: 21.0857401 },
    { lat: 49.2624618, lng: 21.0850063 },
    { lat: 49.2627382, lng: 21.084714 },
    { lat: 49.2632605, lng: 21.0834841 },
    { lat: 49.2634399, lng: 21.0826582 },
    { lat: 49.2633934, lng: 21.0821915 },
    { lat: 49.2627939, lng: 21.0803368 },
    { lat: 49.2626542, lng: 21.0800529 },
    { lat: 49.2625768, lng: 21.079639 },
    { lat: 49.2624582, lng: 21.0793841 },
    { lat: 49.2621017, lng: 21.0790004 },
    { lat: 49.2615619, lng: 21.0781318 },
    { lat: 49.2613254, lng: 21.0757067 },
    { lat: 49.2614283, lng: 21.0756903 },
    { lat: 49.2614157, lng: 21.0750546 },
    { lat: 49.2618807, lng: 21.0743197 },
    { lat: 49.2621546, lng: 21.0736031 },
    { lat: 49.2612028, lng: 21.0731164 },
    { lat: 49.2593943, lng: 21.0724442 },
    { lat: 49.25884, lng: 21.0724271 },
    { lat: 49.2587581, lng: 21.0721882 },
    { lat: 49.2585524, lng: 21.0718502 },
    { lat: 49.2582911, lng: 21.0716274 },
    { lat: 49.2580956, lng: 21.0717825 },
    { lat: 49.2579655, lng: 21.0720041 },
    { lat: 49.2578223, lng: 21.0718549 },
    { lat: 49.2576238, lng: 21.0714162 },
    { lat: 49.2575932, lng: 21.0711681 },
    { lat: 49.2576649, lng: 21.0705975 },
    { lat: 49.2577873, lng: 21.0704136 },
    { lat: 49.257844, lng: 21.0701824 },
    { lat: 49.258187, lng: 21.0693745 },
    { lat: 49.2583426, lng: 21.0687186 },
    { lat: 49.2583731, lng: 21.0680905 },
    { lat: 49.2583324, lng: 21.0671403 },
    { lat: 49.2581691, lng: 21.0662958 },
    { lat: 49.2580942, lng: 21.0653305 },
    { lat: 49.2577224, lng: 21.0632887 },
    { lat: 49.2576785, lng: 21.0626961 },
    { lat: 49.2577167, lng: 21.061648 },
    { lat: 49.2578039, lng: 21.061354 },
    { lat: 49.2578273, lng: 21.0609231 },
    { lat: 49.2577975, lng: 21.0604152 },
    { lat: 49.2578496, lng: 21.0603202 },
    { lat: 49.2579349, lng: 21.0597928 },
    { lat: 49.2574762, lng: 21.0588614 },
    { lat: 49.2573088, lng: 21.0586651 },
    { lat: 49.2572893, lng: 21.0583736 },
    { lat: 49.2573411, lng: 21.0583063 },
    { lat: 49.257305, lng: 21.0581599 },
    { lat: 49.2571037, lng: 21.0578555 },
    { lat: 49.25688, lng: 21.0577552 },
    { lat: 49.2566817, lng: 21.0575356 },
    { lat: 49.2560559, lng: 21.0561049 },
    { lat: 49.2559914, lng: 21.055328 },
    { lat: 49.2559113, lng: 21.0548985 },
    { lat: 49.2557181, lng: 21.0545604 },
    { lat: 49.2555488, lng: 21.054094 },
    { lat: 49.2553937, lng: 21.0538549 },
    { lat: 49.2553301, lng: 21.0534919 },
    { lat: 49.2549359, lng: 21.0528519 },
    { lat: 49.2546754, lng: 21.0521737 },
    { lat: 49.2546426, lng: 21.0518228 },
    { lat: 49.2546754, lng: 21.051446 },
    { lat: 49.2546502, lng: 21.0501758 },
    { lat: 49.2545325, lng: 21.0499495 },
    { lat: 49.2539464, lng: 21.0490859 },
    { lat: 49.2538262, lng: 21.0497495 },
    { lat: 49.2533386, lng: 21.0501919 },
    { lat: 49.2528319, lng: 21.0505 },
    { lat: 49.251863, lng: 21.0509167 },
    { lat: 49.2514746, lng: 21.0513985 },
    { lat: 49.2512012, lng: 21.0521331 },
    { lat: 49.2506557, lng: 21.0525247 },
    { lat: 49.2500771, lng: 21.0526566 },
    { lat: 49.2489595, lng: 21.0542163 },
    { lat: 49.2483569, lng: 21.0555296 },
    { lat: 49.248094, lng: 21.0555735 },
    { lat: 49.2478853, lng: 21.0537122 },
    { lat: 49.2475062, lng: 21.0526033 },
    { lat: 49.2473691, lng: 21.0524332 },
    { lat: 49.2466807, lng: 21.0519574 },
    { lat: 49.2463346, lng: 21.0515156 },
    { lat: 49.2461226, lng: 21.0509441 },
    { lat: 49.2458594, lng: 21.0505371 },
    { lat: 49.2451729, lng: 21.0499737 },
    { lat: 49.2449637, lng: 21.0496296 },
    { lat: 49.2448137, lng: 21.0490315 },
    { lat: 49.2445365, lng: 21.0483908 },
    { lat: 49.2444619, lng: 21.0478925 },
    { lat: 49.2445059, lng: 21.0474832 },
    { lat: 49.2452125, lng: 21.0465741 },
    { lat: 49.2452941, lng: 21.0463479 },
    { lat: 49.2451477, lng: 21.0460672 },
    { lat: 49.2451926, lng: 21.0455743 },
    { lat: 49.2451712, lng: 21.0450864 },
    { lat: 49.244898, lng: 21.0446748 },
    { lat: 49.244738, lng: 21.0445976 },
    { lat: 49.2444424, lng: 21.0442305 },
    { lat: 49.2439542, lng: 21.0439959 },
    { lat: 49.2435684, lng: 21.0440034 },
    { lat: 49.2435057, lng: 21.0436987 },
    { lat: 49.2434035, lng: 21.0434608 },
    { lat: 49.2432125, lng: 21.0432278 },
    { lat: 49.2427687, lng: 21.0433161 },
    { lat: 49.2424396, lng: 21.0432682 },
    { lat: 49.2422692, lng: 21.0431467 },
    { lat: 49.242176, lng: 21.0428779 },
    { lat: 49.2420907, lng: 21.0427766 },
    { lat: 49.2418007, lng: 21.0429596 },
    { lat: 49.2416486, lng: 21.0428212 },
    { lat: 49.2414067, lng: 21.042962 },
    { lat: 49.2412265, lng: 21.0429241 },
    { lat: 49.240903, lng: 21.0427299 },
    { lat: 49.2408838, lng: 21.0426251 },
    { lat: 49.2406759, lng: 21.0425612 },
    { lat: 49.2403634, lng: 21.0426468 },
    { lat: 49.2401727, lng: 21.0425261 },
    { lat: 49.2396637, lng: 21.0424065 },
    { lat: 49.2392321, lng: 21.0420404 },
    { lat: 49.2389178, lng: 21.0420995 },
    { lat: 49.2387082, lng: 21.0418838 },
    { lat: 49.238395, lng: 21.0418757 },
    { lat: 49.2383102, lng: 21.0419347 },
    { lat: 49.2379908, lng: 21.0416636 },
    { lat: 49.2378849, lng: 21.0417263 },
    { lat: 49.2377801, lng: 21.0416896 },
    { lat: 49.2374787, lng: 21.0414466 },
    { lat: 49.2370724, lng: 21.0415486 },
    { lat: 49.2369696, lng: 21.0414346 },
    { lat: 49.2368011, lng: 21.04138 },
    { lat: 49.2366211, lng: 21.041479 },
    { lat: 49.2363622, lng: 21.0414129 },
    { lat: 49.236265, lng: 21.0415358 },
    { lat: 49.2358736, lng: 21.0414361 },
    { lat: 49.2355177, lng: 21.0416002 },
    { lat: 49.235293, lng: 21.0414422 },
    { lat: 49.2351225, lng: 21.0417338 },
    { lat: 49.2350047, lng: 21.0416793 },
    { lat: 49.2348389, lng: 21.0417732 },
    { lat: 49.2346809, lng: 21.0419613 },
    { lat: 49.2340349, lng: 21.042139 },
    { lat: 49.2340318, lng: 21.0420096 },
    { lat: 49.2337204, lng: 21.0421567 },
    { lat: 49.2333834, lng: 21.0421432 },
    { lat: 49.2332638, lng: 21.0423727 },
    { lat: 49.232896, lng: 21.0426237 },
    { lat: 49.2328079, lng: 21.0425396 },
    { lat: 49.2325509, lng: 21.0426714 },
    { lat: 49.2324716, lng: 21.0425823 },
    { lat: 49.2322701, lng: 21.0425482 },
    { lat: 49.2322201, lng: 21.042673 },
    { lat: 49.2320259, lng: 21.0426826 },
    { lat: 49.2317442, lng: 21.0430222 },
    { lat: 49.2314952, lng: 21.0430425 },
    { lat: 49.2313694, lng: 21.043259 },
    { lat: 49.2313054, lng: 21.043512 },
    { lat: 49.23121, lng: 21.0435494 },
    { lat: 49.2310118, lng: 21.0439994 },
    { lat: 49.230896, lng: 21.0439318 },
    { lat: 49.2306429, lng: 21.0439725 },
    { lat: 49.2304196, lng: 21.0441273 },
    { lat: 49.230399, lng: 21.0442934 },
    { lat: 49.2303108, lng: 21.0444513 },
    { lat: 49.230148, lng: 21.0444283 },
    { lat: 49.230111, lng: 21.0447116 },
    { lat: 49.2299318, lng: 21.044772 },
    { lat: 49.2297378, lng: 21.0451445 },
    { lat: 49.2292537, lng: 21.0451015 },
    { lat: 49.2291293, lng: 21.0453961 },
    { lat: 49.2286543, lng: 21.0454964 },
    { lat: 49.2283868, lng: 21.0457845 },
    { lat: 49.2282267, lng: 21.0457775 },
    { lat: 49.2280123, lng: 21.0459319 },
    { lat: 49.2278106, lng: 21.0459565 },
    { lat: 49.2276373, lng: 21.0458558 },
    { lat: 49.2269306, lng: 21.0458224 },
    { lat: 49.2267191, lng: 21.0456112 },
    { lat: 49.2264762, lng: 21.0455798 },
    { lat: 49.2260673, lng: 21.0454004 },
    { lat: 49.2258779, lng: 21.0454183 },
    { lat: 49.2257273, lng: 21.0453264 },
    { lat: 49.2255313, lng: 21.0454592 },
    { lat: 49.2251803, lng: 21.0453608 },
    { lat: 49.2250211, lng: 21.0454463 },
    { lat: 49.2245858, lng: 21.0452069 },
    { lat: 49.2244865, lng: 21.0453379 },
    { lat: 49.2243262, lng: 21.0453612 },
    { lat: 49.2240414, lng: 21.0450435 },
    { lat: 49.2238627, lng: 21.0451525 },
    { lat: 49.2238175, lng: 21.0451101 },
    { lat: 49.2235925, lng: 21.0452917 },
    { lat: 49.223388, lng: 21.0453359 },
    { lat: 49.2232064, lng: 21.0454947 },
    { lat: 49.2230125, lng: 21.0454125 },
    { lat: 49.2229844, lng: 21.0454834 },
    { lat: 49.2227356, lng: 21.0456224 },
    { lat: 49.222575, lng: 21.0456302 },
    { lat: 49.2224808, lng: 21.0457803 },
    { lat: 49.222306, lng: 21.0459083 },
    { lat: 49.2221754, lng: 21.0461493 },
    { lat: 49.2220977, lng: 21.0461472 },
    { lat: 49.2220299, lng: 21.0463478 },
    { lat: 49.2218442, lng: 21.0464159 },
    { lat: 49.2217566, lng: 21.0465578 },
    { lat: 49.2216236, lng: 21.0464203 },
    { lat: 49.2215033, lng: 21.0464549 },
    { lat: 49.2213739, lng: 21.0465954 },
    { lat: 49.2213058, lng: 21.0465386 },
    { lat: 49.2212082, lng: 21.0466565 },
    { lat: 49.2209164, lng: 21.0466832 },
    { lat: 49.2208335, lng: 21.0465836 },
    { lat: 49.2207141, lng: 21.0467186 },
    { lat: 49.2202998, lng: 21.0468586 },
    { lat: 49.2201477, lng: 21.0467502 },
    { lat: 49.2192387, lng: 21.0468904 },
    { lat: 49.2190857, lng: 21.0468256 },
    { lat: 49.2188736, lng: 21.0464946 },
    { lat: 49.2185508, lng: 21.0463275 },
    { lat: 49.218512, lng: 21.0462069 },
    { lat: 49.2180129, lng: 21.0458785 },
    { lat: 49.2178289, lng: 21.0459251 },
    { lat: 49.2177651, lng: 21.0458334 },
    { lat: 49.216903, lng: 21.045247 },
    { lat: 49.216793, lng: 21.045472 },
    { lat: 49.216714, lng: 21.04574 },
    { lat: 49.216685, lng: 21.046097 },
    { lat: 49.216688, lng: 21.046259 },
    { lat: 49.216697, lng: 21.046455 },
    { lat: 49.216656, lng: 21.046638 },
    { lat: 49.216653, lng: 21.046842 },
    { lat: 49.216654, lng: 21.047082 },
    { lat: 49.216644, lng: 21.047295 },
    { lat: 49.216705, lng: 21.047667 },
    { lat: 49.216747, lng: 21.047861 },
    { lat: 49.216835, lng: 21.048313 },
    { lat: 49.216956, lng: 21.048649 },
    { lat: 49.21708, lng: 21.048869 },
    { lat: 49.217114, lng: 21.048879 },
    { lat: 49.217254, lng: 21.048921 },
    { lat: 49.217403, lng: 21.049178 },
    { lat: 49.217542, lng: 21.049256 },
    { lat: 49.217615, lng: 21.049438 },
    { lat: 49.21769, lng: 21.049567 },
    { lat: 49.217805, lng: 21.049641 },
    { lat: 49.217856, lng: 21.049783 },
    { lat: 49.217767, lng: 21.049992 },
    { lat: 49.217737, lng: 21.050259 },
    { lat: 49.217792, lng: 21.050482 },
    { lat: 49.217835, lng: 21.050601 },
    { lat: 49.218228, lng: 21.050865 },
    { lat: 49.218587, lng: 21.050958 },
    { lat: 49.218815, lng: 21.051061 },
    { lat: 49.219, lng: 21.051182 },
    { lat: 49.219069, lng: 21.051435 },
    { lat: 49.219158, lng: 21.052019 },
    { lat: 49.219223, lng: 21.052348 },
    { lat: 49.219355, lng: 21.052916 },
    { lat: 49.21966, lng: 21.053038 },
    { lat: 49.2198, lng: 21.053057 },
    { lat: 49.219927, lng: 21.053213 },
    { lat: 49.220037, lng: 21.053256 },
    { lat: 49.220182, lng: 21.053454 },
    { lat: 49.220343, lng: 21.053662 },
    { lat: 49.22058, lng: 21.053825 },
    { lat: 49.220845, lng: 21.053716 },
    { lat: 49.221187, lng: 21.054214 },
    { lat: 49.221436, lng: 21.054474 },
    { lat: 49.222127, lng: 21.055205 },
    { lat: 49.22215, lng: 21.055322 },
    { lat: 49.222242, lng: 21.055693 },
    { lat: 49.222282, lng: 21.055902 },
    { lat: 49.222306, lng: 21.056095 },
    { lat: 49.222333, lng: 21.056418 },
    { lat: 49.222279, lng: 21.056482 },
    { lat: 49.222215, lng: 21.056779 },
    { lat: 49.222122, lng: 21.057035 },
    { lat: 49.221539, lng: 21.05809 },
    { lat: 49.22148, lng: 21.058165 },
    { lat: 49.221424, lng: 21.058223 },
    { lat: 49.221195, lng: 21.058622 },
    { lat: 49.220878, lng: 21.059398 },
    { lat: 49.220683, lng: 21.059774 },
    { lat: 49.220529, lng: 21.060277 },
    { lat: 49.220492, lng: 21.060386 },
    { lat: 49.220187, lng: 21.060937 },
    { lat: 49.219945, lng: 21.061515 },
    { lat: 49.219558, lng: 21.062226 },
    { lat: 49.219124, lng: 21.062633 },
    { lat: 49.218687, lng: 21.063073 },
    { lat: 49.218281, lng: 21.063142 },
    { lat: 49.217848, lng: 21.063384 },
    { lat: 49.217536, lng: 21.064028 },
    { lat: 49.217208, lng: 21.064743 },
    { lat: 49.216978, lng: 21.06573 },
    { lat: 49.216932, lng: 21.066077 },
    { lat: 49.216851, lng: 21.066521 },
    { lat: 49.216842, lng: 21.066848 },
    { lat: 49.21682, lng: 21.067091 },
    { lat: 49.216739, lng: 21.06734 },
    { lat: 49.216689, lng: 21.067508 },
    { lat: 49.216438, lng: 21.068051 },
    { lat: 49.216244, lng: 21.068412 },
    { lat: 49.215947, lng: 21.06904 },
    { lat: 49.215901, lng: 21.069137 },
    { lat: 49.215863, lng: 21.069217 },
    { lat: 49.215729, lng: 21.069605 },
    { lat: 49.215312, lng: 21.070186 },
    { lat: 49.215115, lng: 21.070539 },
    { lat: 49.214976, lng: 21.071129 },
    { lat: 49.214795, lng: 21.071632 },
    { lat: 49.214499, lng: 21.072437 },
    { lat: 49.214178, lng: 21.072927 },
    { lat: 49.213789, lng: 21.07336 },
    { lat: 49.213263, lng: 21.074011 },
    { lat: 49.213262, lng: 21.073988 },
    { lat: 49.213111, lng: 21.074063 },
    { lat: 49.212908, lng: 21.074133 },
    { lat: 49.21288, lng: 21.074141 },
    { lat: 49.212787, lng: 21.07419 },
    { lat: 49.212754, lng: 21.074329 },
    { lat: 49.212726, lng: 21.075066 },
    { lat: 49.21264, lng: 21.075223 },
    { lat: 49.212576, lng: 21.07551 },
    { lat: 49.212564, lng: 21.075762 },
    { lat: 49.212425, lng: 21.076462 },
    { lat: 49.212411, lng: 21.076835 },
    { lat: 49.212457, lng: 21.077316 },
    { lat: 49.212411, lng: 21.077646 },
    { lat: 49.212527, lng: 21.077961 },
    { lat: 49.212612, lng: 21.078317 },
    { lat: 49.212609, lng: 21.078734 },
    { lat: 49.212632, lng: 21.078953 },
    { lat: 49.212672, lng: 21.07908 },
    { lat: 49.212886, lng: 21.079289 },
    { lat: 49.212975, lng: 21.079552 },
    { lat: 49.213124, lng: 21.079825 },
    { lat: 49.213176, lng: 21.080247 },
    { lat: 49.213362, lng: 21.080653 },
    { lat: 49.213372, lng: 21.080698 },
    { lat: 49.213424, lng: 21.080941 },
    { lat: 49.213612, lng: 21.081541 },
    { lat: 49.213588, lng: 21.082193 },
    { lat: 49.213702, lng: 21.082405 },
    { lat: 49.21378, lng: 21.08273 },
    { lat: 49.213713, lng: 21.083122 },
    { lat: 49.213876, lng: 21.083567 },
    { lat: 49.214154, lng: 21.083833 },
    { lat: 49.21429, lng: 21.084247 },
    { lat: 49.214457, lng: 21.084673 },
    { lat: 49.214473, lng: 21.084724 },
    { lat: 49.214498, lng: 21.084805 },
    { lat: 49.214533, lng: 21.084917 },
    { lat: 49.214552, lng: 21.084984 },
    { lat: 49.214603, lng: 21.085138 },
    { lat: 49.214752, lng: 21.085436 },
    { lat: 49.214849, lng: 21.085822 },
    { lat: 49.214913, lng: 21.085981 },
    { lat: 49.215047, lng: 21.086307 },
    { lat: 49.215327, lng: 21.086804 },
    { lat: 49.215481, lng: 21.087096 },
    { lat: 49.215624, lng: 21.087458 },
    { lat: 49.215803, lng: 21.08781 },
    { lat: 49.216012, lng: 21.088102 },
    { lat: 49.216066, lng: 21.088224 },
    { lat: 49.216107, lng: 21.088527 },
    { lat: 49.216064, lng: 21.089129 },
    { lat: 49.216047, lng: 21.089125 },
    { lat: 49.215716, lng: 21.091649 },
    { lat: 49.215715, lng: 21.092041 },
    { lat: 49.215713, lng: 21.092903 },
    { lat: 49.215839, lng: 21.093106 },
    { lat: 49.216083, lng: 21.093174 },
    { lat: 49.216287, lng: 21.093406 },
    { lat: 49.21669, lng: 21.093806 },
    { lat: 49.216839, lng: 21.093955 },
    { lat: 49.216942, lng: 21.094466 },
    { lat: 49.217057, lng: 21.094492 },
    { lat: 49.217156, lng: 21.094679 },
    { lat: 49.217234, lng: 21.095073 },
    { lat: 49.21728, lng: 21.095012 },
    { lat: 49.217344, lng: 21.095224 },
    { lat: 49.217509, lng: 21.095386 },
    { lat: 49.217558, lng: 21.095594 },
    { lat: 49.217465, lng: 21.095642 },
    { lat: 49.217604, lng: 21.096194 },
    { lat: 49.217611, lng: 21.096749 },
    { lat: 49.217589, lng: 21.097106 },
    { lat: 49.217584, lng: 21.097489 },
    { lat: 49.21752, lng: 21.097936 },
    { lat: 49.217464, lng: 21.098151 },
    { lat: 49.217396, lng: 21.098486 },
    { lat: 49.217313, lng: 21.098756 },
    { lat: 49.217286, lng: 21.098932 },
    { lat: 49.217451, lng: 21.099035 },
    { lat: 49.217563, lng: 21.099238 },
    { lat: 49.217611, lng: 21.099412 },
    { lat: 49.217758, lng: 21.0996 },
    { lat: 49.21785, lng: 21.099766 },
    { lat: 49.218112, lng: 21.100094 },
    { lat: 49.218248, lng: 21.100126 },
    { lat: 49.21833, lng: 21.100346 },
    { lat: 49.218386, lng: 21.100687 },
    { lat: 49.218526, lng: 21.100744 },
    { lat: 49.218608, lng: 21.100937 },
    { lat: 49.218704, lng: 21.101268 },
    { lat: 49.218831, lng: 21.101542 },
    { lat: 49.218911, lng: 21.101792 },
    { lat: 49.219068, lng: 21.102036 },
    { lat: 49.219143, lng: 21.102415 },
    { lat: 49.219249, lng: 21.102722 },
    { lat: 49.219264, lng: 21.10289 },
    { lat: 49.219246, lng: 21.104338 },
    { lat: 49.219038, lng: 21.104779 },
    { lat: 49.218965, lng: 21.105297 },
    { lat: 49.218771, lng: 21.105928 },
    { lat: 49.218728, lng: 21.106192 },
    { lat: 49.218643, lng: 21.106417 },
    { lat: 49.218579, lng: 21.106467 },
    { lat: 49.218504, lng: 21.106738 },
    { lat: 49.218489, lng: 21.107256 },
    { lat: 49.218452, lng: 21.107453 },
    { lat: 49.218573, lng: 21.107884 },
    { lat: 49.218764, lng: 21.108424 },
    { lat: 49.218749, lng: 21.109099 },
    { lat: 49.218792, lng: 21.109659 },
    { lat: 49.218926, lng: 21.110066 },
    { lat: 49.219069, lng: 21.11032 },
    { lat: 49.219193, lng: 21.110801 },
    { lat: 49.219181, lng: 21.111138 },
    { lat: 49.219144, lng: 21.111439 },
    { lat: 49.219142, lng: 21.111515 },
    { lat: 49.219198, lng: 21.111915 },
    { lat: 49.219305, lng: 21.112068 },
    { lat: 49.219365, lng: 21.112626 },
    { lat: 49.219474, lng: 21.112999 },
    { lat: 49.219567, lng: 21.113227 },
    { lat: 49.219586, lng: 21.113533 },
    { lat: 49.219719, lng: 21.113664 },
    { lat: 49.219751, lng: 21.113876 },
    { lat: 49.219893, lng: 21.114196 },
    { lat: 49.220127, lng: 21.11444 },
    { lat: 49.220485, lng: 21.114719 },
    { lat: 49.220927, lng: 21.115095 },
    { lat: 49.221246, lng: 21.115672 },
    { lat: 49.221449, lng: 21.11638 },
    { lat: 49.221611, lng: 21.117504 },
    { lat: 49.221645, lng: 21.117956 },
    { lat: 49.221681, lng: 21.118311 },
    { lat: 49.2222027, lng: 21.1190838 },
    { lat: 49.2222954, lng: 21.1193088 },
    { lat: 49.2224905, lng: 21.1192893 },
    { lat: 49.223223, lng: 21.1189348 },
    { lat: 49.2235309, lng: 21.1189545 },
    { lat: 49.2241174, lng: 21.1191365 },
    { lat: 49.2242315, lng: 21.1193282 },
    { lat: 49.2245638, lng: 21.119516 },
    { lat: 49.2251566, lng: 21.119722 },
    { lat: 49.2253373, lng: 21.119956 },
    { lat: 49.2255156, lng: 21.1200072 },
    { lat: 49.2256492, lng: 21.1199232 },
    { lat: 49.2263169, lng: 21.1198323 },
    { lat: 49.2266373, lng: 21.1199624 },
    { lat: 49.226929, lng: 21.1202897 },
    { lat: 49.2281532, lng: 21.1202207 },
    { lat: 49.2282421, lng: 21.1202882 },
    { lat: 49.2284732, lng: 21.1203021 },
    { lat: 49.2288192, lng: 21.1205282 },
    { lat: 49.2289884, lng: 21.1207184 },
    { lat: 49.2294143, lng: 21.1212701 },
    { lat: 49.2295617, lng: 21.1215894 },
    { lat: 49.2299887, lng: 21.1222034 },
    { lat: 49.2304658, lng: 21.1227719 },
    { lat: 49.2307515, lng: 21.1233032 },
    { lat: 49.2311866, lng: 21.1243901 },
    { lat: 49.2314077, lng: 21.1242992 },
    { lat: 49.2314696, lng: 21.1240508 },
    { lat: 49.2317771, lng: 21.1239925 },
    { lat: 49.2318689, lng: 21.1242618 },
    { lat: 49.2323542, lng: 21.1244845 },
    { lat: 49.2323537, lng: 21.1248929 },
    { lat: 49.2327855, lng: 21.125378 },
    { lat: 49.2329759, lng: 21.1261316 },
    { lat: 49.2331947, lng: 21.1264666 },
    { lat: 49.233785, lng: 21.1270952 },
    { lat: 49.2343981, lng: 21.1275504 },
    { lat: 49.2346424, lng: 21.1275882 },
    { lat: 49.2352323, lng: 21.1275241 },
    { lat: 49.2360395, lng: 21.1275711 },
    { lat: 49.2365871, lng: 21.1278428 },
    { lat: 49.2369452, lng: 21.1279212 },
    { lat: 49.237825, lng: 21.1277719 },
    { lat: 49.238055, lng: 21.1278273 },
    { lat: 49.2384539, lng: 21.1280935 },
    { lat: 49.239232, lng: 21.1280681 },
    { lat: 49.2396499, lng: 21.1279836 },
    { lat: 49.2398176, lng: 21.1281919 },
    { lat: 49.2400747, lng: 21.1287441 },
    { lat: 49.2404452, lng: 21.1293196 },
    { lat: 49.2405095, lng: 21.1296091 },
    { lat: 49.2405155, lng: 21.1300804 },
    { lat: 49.2406487, lng: 21.1307728 },
    { lat: 49.2408031, lng: 21.131203 },
    { lat: 49.2415466, lng: 21.1310174 },
    { lat: 49.2416493, lng: 21.1311059 },
    { lat: 49.2418306, lng: 21.1311052 },
    { lat: 49.2428646, lng: 21.1317758 },
    { lat: 49.2435289, lng: 21.1322825 },
    { lat: 49.2438493, lng: 21.1322747 },
    { lat: 49.2442536, lng: 21.1319355 },
    { lat: 49.2444755, lng: 21.1319054 },
    { lat: 49.245543, lng: 21.132176 },
    { lat: 49.246103, lng: 21.1321296 },
    { lat: 49.2463624, lng: 21.1318238 },
    { lat: 49.2465909, lng: 21.1313621 },
    { lat: 49.2472735, lng: 21.1302503 },
    { lat: 49.2474858, lng: 21.130061 },
    { lat: 49.2481285, lng: 21.1289996 },
    { lat: 49.2489828, lng: 21.1264436 },
    { lat: 49.2492938, lng: 21.1262135 },
  ],
  Bartošovce: [
    { lat: 49.2236483, lng: 21.2523715 },
    { lat: 49.2236041, lng: 21.2524395 },
    { lat: 49.2234559, lng: 21.2525573 },
    { lat: 49.2234102, lng: 21.2527885 },
    { lat: 49.2234468, lng: 21.2532927 },
    { lat: 49.2231865, lng: 21.2537152 },
    { lat: 49.2230933, lng: 21.2541689 },
    { lat: 49.2230439, lng: 21.2541717 },
    { lat: 49.2230456, lng: 21.2544434 },
    { lat: 49.2227195, lng: 21.25526 },
    { lat: 49.2220764, lng: 21.2557961 },
    { lat: 49.2190417, lng: 21.2596155 },
    { lat: 49.2186421, lng: 21.2596105 },
    { lat: 49.2180093, lng: 21.2598204 },
    { lat: 49.2150127, lng: 21.259812 },
    { lat: 49.2150371, lng: 21.2594687 },
    { lat: 49.2136115, lng: 21.2592128 },
    { lat: 49.2133801, lng: 21.2592272 },
    { lat: 49.2131469, lng: 21.2590728 },
    { lat: 49.2120514, lng: 21.2586477 },
    { lat: 49.20962, lng: 21.2579531 },
    { lat: 49.2069944, lng: 21.2575636 },
    { lat: 49.2055109, lng: 21.2571831 },
    { lat: 49.204733, lng: 21.2571342 },
    { lat: 49.2039224, lng: 21.2569368 },
    { lat: 49.2038652, lng: 21.2570524 },
    { lat: 49.2032478, lng: 21.2569888 },
    { lat: 49.202479, lng: 21.2571998 },
    { lat: 49.2024193, lng: 21.2570921 },
    { lat: 49.2024191, lng: 21.2566754 },
    { lat: 49.2004049, lng: 21.2554239 },
    { lat: 49.1990791, lng: 21.254502 },
    { lat: 49.1950979, lng: 21.2491703 },
    { lat: 49.1951101, lng: 21.2485758 },
    { lat: 49.1950363, lng: 21.2484087 },
    { lat: 49.1944481, lng: 21.2478244 },
    { lat: 49.1938324, lng: 21.2474165 },
    { lat: 49.1937288, lng: 21.2472278 },
    { lat: 49.1937136, lng: 21.2468803 },
    { lat: 49.1935708, lng: 21.2466688 },
    { lat: 49.1935059, lng: 21.2463518 },
    { lat: 49.1925942, lng: 21.2459709 },
    { lat: 49.1920654, lng: 21.2462346 },
    { lat: 49.191732, lng: 21.2464012 },
    { lat: 49.1903087, lng: 21.2467374 },
    { lat: 49.1886084, lng: 21.246847 },
    { lat: 49.1885609, lng: 21.2468047 },
    { lat: 49.1882146, lng: 21.2474683 },
    { lat: 49.1873542, lng: 21.2505262 },
    { lat: 49.1860186, lng: 21.2552664 },
    { lat: 49.1849428, lng: 21.258538 },
    { lat: 49.1844751, lng: 21.2602068 },
    { lat: 49.183985, lng: 21.2655652 },
    { lat: 49.1825433, lng: 21.2692 },
    { lat: 49.1823146, lng: 21.2694173 },
    { lat: 49.1820278, lng: 21.2694916 },
    { lat: 49.1818265, lng: 21.2698663 },
    { lat: 49.18214, lng: 21.2706962 },
    { lat: 49.181533, lng: 21.2726045 },
    { lat: 49.1808243, lng: 21.2742729 },
    { lat: 49.1804836, lng: 21.2766417 },
    { lat: 49.1800564, lng: 21.2788749 },
    { lat: 49.179819, lng: 21.2816924 },
    { lat: 49.1795593, lng: 21.2824721 },
    { lat: 49.1792858, lng: 21.282954 },
    { lat: 49.1790589, lng: 21.2837426 },
    { lat: 49.1790834, lng: 21.2844713 },
    { lat: 49.1789772, lng: 21.2853164 },
    { lat: 49.1789934, lng: 21.2857717 },
    { lat: 49.1771929, lng: 21.2861046 },
    { lat: 49.1772016, lng: 21.2865697 },
    { lat: 49.1772454, lng: 21.2872722 },
    { lat: 49.1775616, lng: 21.2876081 },
    { lat: 49.1775872, lng: 21.2877847 },
    { lat: 49.178193, lng: 21.287538 },
    { lat: 49.1782829, lng: 21.2878654 },
    { lat: 49.1783766, lng: 21.2878066 },
    { lat: 49.178363, lng: 21.2877317 },
    { lat: 49.1785668, lng: 21.2874685 },
    { lat: 49.1787985, lng: 21.2874676 },
    { lat: 49.1788318, lng: 21.2876414 },
    { lat: 49.178984, lng: 21.28769 },
    { lat: 49.1790728, lng: 21.2881319 },
    { lat: 49.1788741, lng: 21.2898503 },
    { lat: 49.1789186, lng: 21.2908765 },
    { lat: 49.1788881, lng: 21.2909042 },
    { lat: 49.1791598, lng: 21.2927412 },
    { lat: 49.1793787, lng: 21.2928384 },
    { lat: 49.1797223, lng: 21.2936669 },
    { lat: 49.1799168, lng: 21.2951843 },
    { lat: 49.1804016, lng: 21.2963366 },
    { lat: 49.1819393, lng: 21.2965762 },
    { lat: 49.1834174, lng: 21.2966048 },
    { lat: 49.1846684, lng: 21.2975246 },
    { lat: 49.1848797, lng: 21.297595 },
    { lat: 49.1857649, lng: 21.2971388 },
    { lat: 49.1857632, lng: 21.2975256 },
    { lat: 49.1872921, lng: 21.2968125 },
    { lat: 49.1885786, lng: 21.296672 },
    { lat: 49.1895936, lng: 21.2962656 },
    { lat: 49.1894624, lng: 21.295873 },
    { lat: 49.1896523, lng: 21.295869 },
    { lat: 49.1914521, lng: 21.294922 },
    { lat: 49.1928555, lng: 21.2949674 },
    { lat: 49.1935771, lng: 21.294723 },
    { lat: 49.1929894, lng: 21.2930743 },
    { lat: 49.1951574, lng: 21.2913154 },
    { lat: 49.1965019, lng: 21.2903852 },
    { lat: 49.1981304, lng: 21.2890282 },
    { lat: 49.1987573, lng: 21.2887332 },
    { lat: 49.1993652, lng: 21.288741 },
    { lat: 49.1998373, lng: 21.2898415 },
    { lat: 49.2008558, lng: 21.292484 },
    { lat: 49.2030666, lng: 21.2909938 },
    { lat: 49.2040057, lng: 21.2920193 },
    { lat: 49.2043304, lng: 21.2901821 },
    { lat: 49.2063174, lng: 21.288731 },
    { lat: 49.2071868, lng: 21.2879409 },
    { lat: 49.2084485, lng: 21.2869539 },
    { lat: 49.208961, lng: 21.2866694 },
    { lat: 49.2089899, lng: 21.2864719 },
    { lat: 49.2092526, lng: 21.2860089 },
    { lat: 49.2089512, lng: 21.2853148 },
    { lat: 49.2088151, lng: 21.2848303 },
    { lat: 49.2087093, lng: 21.2838284 },
    { lat: 49.2091015, lng: 21.2830344 },
    { lat: 49.2092679, lng: 21.2824703 },
    { lat: 49.2093349, lng: 21.2818613 },
    { lat: 49.2092927, lng: 21.281472 },
    { lat: 49.2096217, lng: 21.2808938 },
    { lat: 49.2097405, lng: 21.2801397 },
    { lat: 49.2097039, lng: 21.2798645 },
    { lat: 49.2099897, lng: 21.2773064 },
    { lat: 49.2101723, lng: 21.277093 },
    { lat: 49.2123975, lng: 21.2770437 },
    { lat: 49.213353, lng: 21.2769062 },
    { lat: 49.213993, lng: 21.2756764 },
    { lat: 49.2149987, lng: 21.2742615 },
    { lat: 49.217446, lng: 21.2721976 },
    { lat: 49.219102, lng: 21.2710363 },
    { lat: 49.2194344, lng: 21.2704231 },
    { lat: 49.2197228, lng: 21.2703645 },
    { lat: 49.2202225, lng: 21.2704064 },
    { lat: 49.2204276, lng: 21.2704876 },
    { lat: 49.2206775, lng: 21.2708597 },
    { lat: 49.220782, lng: 21.2708933 },
    { lat: 49.2211705, lng: 21.2707592 },
    { lat: 49.2217243, lng: 21.2707249 },
    { lat: 49.2225783, lng: 21.2703936 },
    { lat: 49.222871, lng: 21.2703966 },
    { lat: 49.2232331, lng: 21.2700224 },
    { lat: 49.2235259, lng: 21.2698807 },
    { lat: 49.2240991, lng: 21.2707878 },
    { lat: 49.224466, lng: 21.2702707 },
    { lat: 49.2246813, lng: 21.2695416 },
    { lat: 49.2252061, lng: 21.2685972 },
    { lat: 49.2257017, lng: 21.2673967 },
    { lat: 49.226597, lng: 21.2668326 },
    { lat: 49.226726, lng: 21.2665643 },
    { lat: 49.2269826, lng: 21.2657857 },
    { lat: 49.2276138, lng: 21.2642396 },
    { lat: 49.2277809, lng: 21.2635883 },
    { lat: 49.2278498, lng: 21.2628249 },
    { lat: 49.2281278, lng: 21.261468 },
    { lat: 49.2280166, lng: 21.2597781 },
    { lat: 49.2280955, lng: 21.2586043 },
    { lat: 49.2268556, lng: 21.2566528 },
    { lat: 49.2263522, lng: 21.2554582 },
    { lat: 49.2264737, lng: 21.2547612 },
    { lat: 49.2259368, lng: 21.2540964 },
    { lat: 49.2250129, lng: 21.2529546 },
    { lat: 49.224323, lng: 21.2517359 },
    { lat: 49.2241935, lng: 21.2520281 },
    { lat: 49.2237345, lng: 21.2523913 },
    { lat: 49.2236611, lng: 21.2524221 },
    { lat: 49.2236824, lng: 21.2523857 },
    { lat: 49.2236483, lng: 21.2523715 },
  ],
  Sveržov: [
    { lat: 49.3497413, lng: 21.1459232 },
    { lat: 49.3496504, lng: 21.1460714 },
    { lat: 49.3494073, lng: 21.1468205 },
    { lat: 49.3493588, lng: 21.1471077 },
    { lat: 49.349099, lng: 21.1473557 },
    { lat: 49.3488118, lng: 21.1477471 },
    { lat: 49.3481919, lng: 21.1482883 },
    { lat: 49.3477191, lng: 21.1485631 },
    { lat: 49.347213, lng: 21.1486794 },
    { lat: 49.3467175, lng: 21.1487058 },
    { lat: 49.3456587, lng: 21.148958 },
    { lat: 49.3455757, lng: 21.1489277 },
    { lat: 49.3451328, lng: 21.1491952 },
    { lat: 49.3444875, lng: 21.1497844 },
    { lat: 49.3441604, lng: 21.1498341 },
    { lat: 49.3438189, lng: 21.1499877 },
    { lat: 49.3436309, lng: 21.1499856 },
    { lat: 49.3432107, lng: 21.1501879 },
    { lat: 49.3427375, lng: 21.149995 },
    { lat: 49.3417057, lng: 21.1497397 },
    { lat: 49.3410499, lng: 21.149679 },
    { lat: 49.3409714, lng: 21.1495437 },
    { lat: 49.3407917, lng: 21.14955 },
    { lat: 49.3402317, lng: 21.1493837 },
    { lat: 49.3395087, lng: 21.1490754 },
    { lat: 49.3390847, lng: 21.1489752 },
    { lat: 49.3380727, lng: 21.1491774 },
    { lat: 49.3367055, lng: 21.1488231 },
    { lat: 49.3361873, lng: 21.1489088 },
    { lat: 49.3358394, lng: 21.1488669 },
    { lat: 49.3353615, lng: 21.1489815 },
    { lat: 49.3347148, lng: 21.1493513 },
    { lat: 49.3339608, lng: 21.1496001 },
    { lat: 49.3328383, lng: 21.1501146 },
    { lat: 49.3306227, lng: 21.1507563 },
    { lat: 49.3304133, lng: 21.1508987 },
    { lat: 49.3298896, lng: 21.150921 },
    { lat: 49.3297485, lng: 21.1500839 },
    { lat: 49.3298828, lng: 21.1496992 },
    { lat: 49.3299025, lng: 21.1495069 },
    { lat: 49.3297672, lng: 21.1487423 },
    { lat: 49.3291389, lng: 21.1486554 },
    { lat: 49.3280684, lng: 21.1481454 },
    { lat: 49.3269934, lng: 21.147919 },
    { lat: 49.3260705, lng: 21.147875 },
    { lat: 49.3252549, lng: 21.1481295 },
    { lat: 49.3249554, lng: 21.1488773 },
    { lat: 49.3246975, lng: 21.1500875 },
    { lat: 49.324648, lng: 21.150621 },
    { lat: 49.3246551, lng: 21.153392 },
    { lat: 49.3248193, lng: 21.1564743 },
    { lat: 49.3250944, lng: 21.1589695 },
    { lat: 49.3253566, lng: 21.1631027 },
    { lat: 49.3259331, lng: 21.1628974 },
    { lat: 49.3260484, lng: 21.1630199 },
    { lat: 49.3261809, lng: 21.1629438 },
    { lat: 49.3267192, lng: 21.1631017 },
    { lat: 49.3267713, lng: 21.1632151 },
    { lat: 49.3268277, lng: 21.1631858 },
    { lat: 49.3268711, lng: 21.1632385 },
    { lat: 49.3268528, lng: 21.1635198 },
    { lat: 49.327051, lng: 21.1637058 },
    { lat: 49.3273965, lng: 21.1635283 },
    { lat: 49.3276009, lng: 21.1633001 },
    { lat: 49.3276631, lng: 21.1633446 },
    { lat: 49.3278421, lng: 21.1631724 },
    { lat: 49.3280745, lng: 21.1631543 },
    { lat: 49.3281682, lng: 21.1629075 },
    { lat: 49.3282434, lng: 21.1628695 },
    { lat: 49.3283469, lng: 21.163192 },
    { lat: 49.3284027, lng: 21.1631757 },
    { lat: 49.3284168, lng: 21.1632634 },
    { lat: 49.3289506, lng: 21.1631745 },
    { lat: 49.329142, lng: 21.1639321 },
    { lat: 49.329158, lng: 21.1645872 },
    { lat: 49.3295989, lng: 21.1660924 },
    { lat: 49.3300919, lng: 21.1680388 },
    { lat: 49.3302062, lng: 21.1687436 },
    { lat: 49.3306639, lng: 21.1704278 },
    { lat: 49.3310851, lng: 21.1715569 },
    { lat: 49.3310795, lng: 21.1716623 },
    { lat: 49.3323005, lng: 21.1745206 },
    { lat: 49.3328823, lng: 21.1768686 },
    { lat: 49.3341251, lng: 21.1791581 },
    { lat: 49.3347865, lng: 21.1802726 },
    { lat: 49.3352429, lng: 21.1808372 },
    { lat: 49.3353176, lng: 21.1808505 },
    { lat: 49.3354105, lng: 21.1810469 },
    { lat: 49.3360522, lng: 21.1819234 },
    { lat: 49.3370601, lng: 21.1829633 },
    { lat: 49.3375034, lng: 21.1835397 },
    { lat: 49.3378131, lng: 21.1836575 },
    { lat: 49.3380814, lng: 21.1834611 },
    { lat: 49.3384169, lng: 21.1825978 },
    { lat: 49.3390042, lng: 21.1822324 },
    { lat: 49.3394682, lng: 21.1814898 },
    { lat: 49.3405673, lng: 21.1821357 },
    { lat: 49.3411299, lng: 21.1818399 },
    { lat: 49.3415525, lng: 21.1817892 },
    { lat: 49.3418163, lng: 21.1816996 },
    { lat: 49.3420901, lng: 21.1814963 },
    { lat: 49.3430205, lng: 21.1812383 },
    { lat: 49.3431275, lng: 21.1813124 },
    { lat: 49.34324, lng: 21.1812367 },
    { lat: 49.3436021, lng: 21.1812727 },
    { lat: 49.3439166, lng: 21.1814306 },
    { lat: 49.344397, lng: 21.1815031 },
    { lat: 49.3451879, lng: 21.1812097 },
    { lat: 49.345254, lng: 21.1810537 },
    { lat: 49.3454836, lng: 21.1810214 },
    { lat: 49.3456212, lng: 21.180853 },
    { lat: 49.3459705, lng: 21.1809004 },
    { lat: 49.3462718, lng: 21.1804598 },
    { lat: 49.3464243, lng: 21.1800805 },
    { lat: 49.3466567, lng: 21.1797734 },
    { lat: 49.3466619, lng: 21.1795858 },
    { lat: 49.3469249, lng: 21.1790299 },
    { lat: 49.3470704, lng: 21.1789681 },
    { lat: 49.3472539, lng: 21.1786847 },
    { lat: 49.3474097, lng: 21.1785928 },
    { lat: 49.3474791, lng: 21.1781858 },
    { lat: 49.3476004, lng: 21.1781062 },
    { lat: 49.3477257, lng: 21.1779032 },
    { lat: 49.3477274, lng: 21.1778829 },
    { lat: 49.3482716, lng: 21.1776937 },
    { lat: 49.3487482, lng: 21.1773857 },
    { lat: 49.348834, lng: 21.1770694 },
    { lat: 49.3490235, lng: 21.1767997 },
    { lat: 49.3490418, lng: 21.1764811 },
    { lat: 49.349193, lng: 21.1762099 },
    { lat: 49.3495251, lng: 21.176034 },
    { lat: 49.3498636, lng: 21.1759923 },
    { lat: 49.3501488, lng: 21.1753926 },
    { lat: 49.3502104, lng: 21.1750593 },
    { lat: 49.3504424, lng: 21.1744559 },
    { lat: 49.3506014, lng: 21.174389 },
    { lat: 49.3507581, lng: 21.1740769 },
    { lat: 49.3507342, lng: 21.1737809 },
    { lat: 49.351021, lng: 21.1733456 },
    { lat: 49.3510367, lng: 21.1728735 },
    { lat: 49.3512544, lng: 21.1724212 },
    { lat: 49.3512717, lng: 21.1718573 },
    { lat: 49.3514242, lng: 21.1717364 },
    { lat: 49.3515479, lng: 21.1712635 },
    { lat: 49.3517656, lng: 21.1707752 },
    { lat: 49.3518032, lng: 21.1703555 },
    { lat: 49.3519787, lng: 21.1698901 },
    { lat: 49.352179, lng: 21.1696769 },
    { lat: 49.3522931, lng: 21.1692896 },
    { lat: 49.3522911, lng: 21.1690053 },
    { lat: 49.3523627, lng: 21.1687478 },
    { lat: 49.3522752, lng: 21.1684994 },
    { lat: 49.3523169, lng: 21.1681433 },
    { lat: 49.3522146, lng: 21.1676066 },
    { lat: 49.352297, lng: 21.1671425 },
    { lat: 49.3524906, lng: 21.1667125 },
    { lat: 49.3528952, lng: 21.1661992 },
    { lat: 49.3529167, lng: 21.1660959 },
    { lat: 49.3527819, lng: 21.1656304 },
    { lat: 49.3528342, lng: 21.1654699 },
    { lat: 49.3528126, lng: 21.1653163 },
    { lat: 49.3529345, lng: 21.1650814 },
    { lat: 49.3530422, lng: 21.1635204 },
    { lat: 49.35305, lng: 21.1634823 },
    { lat: 49.3524939, lng: 21.1632224 },
    { lat: 49.3515965, lng: 21.1624841 },
    { lat: 49.3514713, lng: 21.1622955 },
    { lat: 49.3509745, lng: 21.1610539 },
    { lat: 49.3501165, lng: 21.1594213 },
    { lat: 49.3493621, lng: 21.1577551 },
    { lat: 49.3499996, lng: 21.1568417 },
    { lat: 49.3503436, lng: 21.1566822 },
    { lat: 49.3500284, lng: 21.1547066 },
    { lat: 49.349952, lng: 21.1536006 },
    { lat: 49.3499671, lng: 21.1527142 },
    { lat: 49.3495232, lng: 21.1503662 },
    { lat: 49.3496359, lng: 21.1493015 },
    { lat: 49.3495229, lng: 21.1488547 },
    { lat: 49.3494667, lng: 21.1479591 },
    { lat: 49.3501489, lng: 21.1468586 },
    { lat: 49.3500295, lng: 21.1465357 },
    { lat: 49.3497413, lng: 21.1459232 },
  ],
  LivovskáHuta: [
    { lat: 49.2539464, lng: 21.0490859 },
    { lat: 49.2539104, lng: 21.0490518 },
    { lat: 49.253249, lng: 21.0481554 },
    { lat: 49.2528898, lng: 21.0478687 },
    { lat: 49.2524732, lng: 21.0473749 },
    { lat: 49.2521839, lng: 21.0468179 },
    { lat: 49.2519829, lng: 21.0450506 },
    { lat: 49.2522384, lng: 21.0442899 },
    { lat: 49.2522347, lng: 21.0440002 },
    { lat: 49.2523782, lng: 21.0434281 },
    { lat: 49.2525256, lng: 21.0418629 },
    { lat: 49.2524336, lng: 21.0410402 },
    { lat: 49.2522152, lng: 21.0399252 },
    { lat: 49.2525133, lng: 21.0389523 },
    { lat: 49.2527404, lng: 21.0384564 },
    { lat: 49.2528516, lng: 21.0380686 },
    { lat: 49.252832, lng: 21.0377398 },
    { lat: 49.2533295, lng: 21.0377817 },
    { lat: 49.2545026, lng: 21.0374815 },
    { lat: 49.2550952, lng: 21.0370515 },
    { lat: 49.2558862, lng: 21.0367066 },
    { lat: 49.2562903, lng: 21.0362527 },
    { lat: 49.2564908, lng: 21.0349103 },
    { lat: 49.2566189, lng: 21.0343818 },
    { lat: 49.2568672, lng: 21.0340603 },
    { lat: 49.2579382, lng: 21.0333177 },
    { lat: 49.2586994, lng: 21.0324692 },
    { lat: 49.2591872, lng: 21.0318244 },
    { lat: 49.2595302, lng: 21.0308494 },
    { lat: 49.2601707, lng: 21.029436 },
    { lat: 49.260475, lng: 21.0289657 },
    { lat: 49.2606008, lng: 21.0274667 },
    { lat: 49.2601965, lng: 21.0257953 },
    { lat: 49.2598478, lng: 21.0252478 },
    { lat: 49.2594848, lng: 21.0242597 },
    { lat: 49.2595769, lng: 21.0237 },
    { lat: 49.2598217, lng: 21.0229605 },
    { lat: 49.2601831, lng: 21.0223781 },
    { lat: 49.2606344, lng: 21.0212759 },
    { lat: 49.260777, lng: 21.0211202 },
    { lat: 49.2608735, lng: 21.0209068 },
    { lat: 49.2610663, lng: 21.0197434 },
    { lat: 49.2610254, lng: 21.0182505 },
    { lat: 49.2607088, lng: 21.0176823 },
    { lat: 49.2603857, lng: 21.0155411 },
    { lat: 49.2603842, lng: 21.0150108 },
    { lat: 49.2601692, lng: 21.0141145 },
    { lat: 49.2600476, lng: 21.013873 },
    { lat: 49.2589903, lng: 21.0124025 },
    { lat: 49.2576138, lng: 21.0110846 },
    { lat: 49.2573754, lng: 21.0106438 },
    { lat: 49.2572321, lng: 21.0101132 },
    { lat: 49.2567684, lng: 21.0089776 },
    { lat: 49.2565123, lng: 21.0078886 },
    { lat: 49.2562508, lng: 21.0072597 },
    { lat: 49.2559757, lng: 21.0067583 },
    { lat: 49.2557762, lng: 21.0058577 },
    { lat: 49.2558153, lng: 21.0054004 },
    { lat: 49.255329, lng: 21.004291 },
    { lat: 49.25526, lng: 21.004265 },
    { lat: 49.255262, lng: 21.004238 },
    { lat: 49.255102, lng: 21.004166 },
    { lat: 49.254857, lng: 21.004311 },
    { lat: 49.254414, lng: 21.004264 },
    { lat: 49.254182, lng: 21.004172 },
    { lat: 49.254006, lng: 21.003952 },
    { lat: 49.253772, lng: 21.003974 },
    { lat: 49.253345, lng: 21.004134 },
    { lat: 49.253065, lng: 21.004079 },
    { lat: 49.252754, lng: 21.004108 },
    { lat: 49.252507, lng: 21.004061 },
    { lat: 49.252235, lng: 21.0041 },
    { lat: 49.252192, lng: 21.004096 },
    { lat: 49.251795, lng: 21.004025 },
    { lat: 49.251366, lng: 21.003887 },
    { lat: 49.251018, lng: 21.003674 },
    { lat: 49.250901, lng: 21.003513 },
    { lat: 49.25049, lng: 21.00329 },
    { lat: 49.250304, lng: 21.00329 },
    { lat: 49.250289, lng: 21.003319 },
    { lat: 49.250283, lng: 21.003295 },
    { lat: 49.25016, lng: 21.003261 },
    { lat: 49.249885, lng: 21.003245 },
    { lat: 49.24971, lng: 21.003271 },
    { lat: 49.249238, lng: 21.003111 },
    { lat: 49.249032, lng: 21.003148 },
    { lat: 49.24879, lng: 21.003046 },
    { lat: 49.248485, lng: 21.00301 },
    { lat: 49.248383, lng: 21.002916 },
    { lat: 49.247768, lng: 21.002744 },
    { lat: 49.247414, lng: 21.002434 },
    { lat: 49.247167, lng: 21.002589 },
    { lat: 49.247077, lng: 21.002698 },
    { lat: 49.246801, lng: 21.002886 },
    { lat: 49.246666, lng: 21.002898 },
    { lat: 49.246539, lng: 21.002866 },
    { lat: 49.246065, lng: 21.003086 },
    { lat: 49.245802, lng: 21.003302 },
    { lat: 49.24562, lng: 21.003547 },
    { lat: 49.245482, lng: 21.003652 },
    { lat: 49.245304, lng: 21.003677 },
    { lat: 49.245007, lng: 21.003614 },
    { lat: 49.244834, lng: 21.003504 },
    { lat: 49.244619, lng: 21.003462 },
    { lat: 49.244461, lng: 21.003517 },
    { lat: 49.244333, lng: 21.003601 },
    { lat: 49.24403, lng: 21.003496 },
    { lat: 49.243743, lng: 21.003466 },
    { lat: 49.243573, lng: 21.003375 },
    { lat: 49.243356, lng: 21.003298 },
    { lat: 49.243293, lng: 21.003292 },
    { lat: 49.243149, lng: 21.003218 },
    { lat: 49.242898, lng: 21.003154 },
    { lat: 49.242414, lng: 21.002912 },
    { lat: 49.242113, lng: 21.002925 },
    { lat: 49.24178, lng: 21.002839 },
    { lat: 49.241439, lng: 21.002943 },
    { lat: 49.241214, lng: 21.00307 },
    { lat: 49.241025, lng: 21.003047 },
    { lat: 49.240719, lng: 21.0029 },
    { lat: 49.240316, lng: 21.002654 },
    { lat: 49.239869, lng: 21.002583 },
    { lat: 49.239721, lng: 21.002463 },
    { lat: 49.239596, lng: 21.002138 },
    { lat: 49.2392, lng: 21.001676 },
    { lat: 49.238722, lng: 21.001195 },
    { lat: 49.238378, lng: 21.001043 },
    { lat: 49.238066, lng: 21.000829 },
    { lat: 49.237808, lng: 21.000532 },
    { lat: 49.23762, lng: 20.999958 },
    { lat: 49.2375, lng: 20.9994 },
    { lat: 49.237327, lng: 20.999048 },
    { lat: 49.236993, lng: 20.998771 },
    { lat: 49.236637, lng: 20.998664 },
    { lat: 49.236329, lng: 20.998612 },
    { lat: 49.236112, lng: 20.998413 },
    { lat: 49.235651, lng: 20.998111 },
    { lat: 49.235362, lng: 20.997494 },
    { lat: 49.235242, lng: 20.997273 },
    { lat: 49.235228, lng: 20.997288 },
    { lat: 49.235101, lng: 20.997069 },
    { lat: 49.234939, lng: 20.997024 },
    { lat: 49.234812, lng: 20.996784 },
    { lat: 49.234511, lng: 20.996519 },
    { lat: 49.234272, lng: 20.996179 },
    { lat: 49.234074, lng: 20.995878 },
    { lat: 49.233682, lng: 20.994985 },
    { lat: 49.233617, lng: 20.994577 },
    { lat: 49.233417, lng: 20.994066 },
    { lat: 49.233057, lng: 20.993362 },
    { lat: 49.232787, lng: 20.992901 },
    { lat: 49.232658, lng: 20.992553 },
    { lat: 49.232663, lng: 20.992631 },
    { lat: 49.232121, lng: 20.993027 },
    { lat: 49.231807, lng: 20.993376 },
    { lat: 49.230872, lng: 20.994004 },
    { lat: 49.230453, lng: 20.994443 },
    { lat: 49.230324, lng: 20.99521 },
    { lat: 49.230207, lng: 20.99624 },
    { lat: 49.230153, lng: 20.996577 },
    { lat: 49.229967, lng: 20.997514 },
    { lat: 49.229688, lng: 20.999096 },
    { lat: 49.229344, lng: 21.000268 },
    { lat: 49.229325, lng: 21.00081 },
    { lat: 49.229337, lng: 21.000833 },
    { lat: 49.229234, lng: 21.001002 },
    { lat: 49.228763, lng: 21.001309 },
    { lat: 49.228502, lng: 21.001574 },
    { lat: 49.228331, lng: 21.001845 },
    { lat: 49.228234, lng: 21.002083 },
    { lat: 49.227835, lng: 21.00227 },
    { lat: 49.227572, lng: 21.002483 },
    { lat: 49.22738, lng: 21.002591 },
    { lat: 49.227221, lng: 21.002759 },
    { lat: 49.226874, lng: 21.003058 },
    { lat: 49.226583, lng: 21.003247 },
    { lat: 49.226447, lng: 21.003429 },
    { lat: 49.226285, lng: 21.003557 },
    { lat: 49.226084, lng: 21.003713 },
    { lat: 49.225715, lng: 21.003983 },
    { lat: 49.22541, lng: 21.004275 },
    { lat: 49.225018, lng: 21.004379 },
    { lat: 49.224706, lng: 21.004494 },
    { lat: 49.224507, lng: 21.00451 },
    { lat: 49.224178, lng: 21.004453 },
    { lat: 49.223847, lng: 21.004511 },
    { lat: 49.223656, lng: 21.004602 },
    { lat: 49.223399, lng: 21.004719 },
    { lat: 49.2231, lng: 21.00498 },
    { lat: 49.222781, lng: 21.005387 },
    { lat: 49.222423, lng: 21.005398 },
    { lat: 49.222408, lng: 21.00544 },
    { lat: 49.222349, lng: 21.005592 },
    { lat: 49.222233, lng: 21.005895 },
    { lat: 49.222081, lng: 21.006446 },
    { lat: 49.221983, lng: 21.006726 },
    { lat: 49.222013, lng: 21.006905 },
    { lat: 49.222012, lng: 21.007249 },
    { lat: 49.222014, lng: 21.007617 },
    { lat: 49.221991, lng: 21.007978 },
    { lat: 49.22199, lng: 21.00799 },
    { lat: 49.221775, lng: 21.008605 },
    { lat: 49.221762, lng: 21.008635 },
    { lat: 49.221745, lng: 21.008672 },
    { lat: 49.221585, lng: 21.009019 },
    { lat: 49.221568, lng: 21.009378 },
    { lat: 49.221549, lng: 21.009438 },
    { lat: 49.221439, lng: 21.009804 },
    { lat: 49.221428, lng: 21.009814 },
    { lat: 49.221124, lng: 21.010112 },
    { lat: 49.221081, lng: 21.010156 },
    { lat: 49.221004, lng: 21.010217 },
    { lat: 49.220904, lng: 21.010297 },
    { lat: 49.220888, lng: 21.010318 },
    { lat: 49.220701, lng: 21.01056 },
    { lat: 49.220684, lng: 21.010583 },
    { lat: 49.220505, lng: 21.010823 },
    { lat: 49.220489, lng: 21.01084 },
    { lat: 49.220282, lng: 21.011046 },
    { lat: 49.220077, lng: 21.01125 },
    { lat: 49.220027, lng: 21.011275 },
    { lat: 49.219813, lng: 21.011387 },
    { lat: 49.219801, lng: 21.011411 },
    { lat: 49.219682, lng: 21.011628 },
    { lat: 49.219665, lng: 21.011645 },
    { lat: 49.219547, lng: 21.011764 },
    { lat: 49.219386, lng: 21.011923 },
    { lat: 49.219384, lng: 21.011925 },
    { lat: 49.219212, lng: 21.012152 },
    { lat: 49.219083, lng: 21.012321 },
    { lat: 49.218928, lng: 21.012525 },
    { lat: 49.218923, lng: 21.01253 },
    { lat: 49.218785, lng: 21.012781 },
    { lat: 49.218616, lng: 21.013088 },
    { lat: 49.218613, lng: 21.013106 },
    { lat: 49.218548, lng: 21.013468 },
    { lat: 49.218567, lng: 21.013876 },
    { lat: 49.21844, lng: 21.014539 },
    { lat: 49.21838, lng: 21.014853 },
    { lat: 49.218424, lng: 21.015431 },
    { lat: 49.218415, lng: 21.015798 },
    { lat: 49.218408, lng: 21.015814 },
    { lat: 49.218296, lng: 21.016099 },
    { lat: 49.218197, lng: 21.016172 },
    { lat: 49.218172, lng: 21.016205 },
    { lat: 49.218061, lng: 21.016349 },
    { lat: 49.217842, lng: 21.01651 },
    { lat: 49.21723, lng: 21.017147 },
    { lat: 49.216923, lng: 21.017395 },
    { lat: 49.216445, lng: 21.017976 },
    { lat: 49.215999, lng: 21.018235 },
    { lat: 49.215634, lng: 21.018649 },
    { lat: 49.215299, lng: 21.019552 },
    { lat: 49.215205, lng: 21.019862 },
    { lat: 49.21475, lng: 21.020352 },
    { lat: 49.214643, lng: 21.020542 },
    { lat: 49.214517, lng: 21.020887 },
    { lat: 49.214468, lng: 21.021292 },
    { lat: 49.214469, lng: 21.021996 },
    { lat: 49.214511, lng: 21.022842 },
    { lat: 49.214481, lng: 21.023566 },
    { lat: 49.214613, lng: 21.025404 },
    { lat: 49.214537, lng: 21.025889 },
    { lat: 49.214572, lng: 21.026248 },
    { lat: 49.214618, lng: 21.026479 },
    { lat: 49.214607, lng: 21.026683 },
    { lat: 49.214459, lng: 21.027017 },
    { lat: 49.214342, lng: 21.027669 },
    { lat: 49.214264, lng: 21.028292 },
    { lat: 49.214246, lng: 21.028296 },
    { lat: 49.214319, lng: 21.028518 },
    { lat: 49.214327, lng: 21.028544 },
    { lat: 49.214407, lng: 21.028783 },
    { lat: 49.214471, lng: 21.028977 },
    { lat: 49.214748, lng: 21.02956 },
    { lat: 49.214916, lng: 21.02978 },
    { lat: 49.215097, lng: 21.030019 },
    { lat: 49.215159, lng: 21.030099 },
    { lat: 49.215668, lng: 21.030807 },
    { lat: 49.215846, lng: 21.031155 },
    { lat: 49.215995, lng: 21.031444 },
    { lat: 49.216251, lng: 21.031851 },
    { lat: 49.21668, lng: 21.032172 },
    { lat: 49.216911, lng: 21.03222 },
    { lat: 49.217001, lng: 21.032156 },
    { lat: 49.217317, lng: 21.031898 },
    { lat: 49.21739, lng: 21.031914 },
    { lat: 49.217479, lng: 21.032057 },
    { lat: 49.21748, lng: 21.032094 },
    { lat: 49.217545, lng: 21.033246 },
    { lat: 49.21762, lng: 21.03357 },
    { lat: 49.217755, lng: 21.033891 },
    { lat: 49.218252, lng: 21.034422 },
    { lat: 49.21846, lng: 21.034539 },
    { lat: 49.218952, lng: 21.03478 },
    { lat: 49.218893, lng: 21.034994 },
    { lat: 49.218844, lng: 21.035006 },
    { lat: 49.218829, lng: 21.035034 },
    { lat: 49.218779, lng: 21.035127 },
    { lat: 49.218814, lng: 21.035295 },
    { lat: 49.218763, lng: 21.035703 },
    { lat: 49.218806, lng: 21.0357 },
    { lat: 49.218691, lng: 21.036389 },
    { lat: 49.218765, lng: 21.036534 },
    { lat: 49.218732, lng: 21.037036 },
    { lat: 49.218937, lng: 21.037206 },
    { lat: 49.218964, lng: 21.037354 },
    { lat: 49.219034, lng: 21.037371 },
    { lat: 49.218945, lng: 21.038039 },
    { lat: 49.218909, lng: 21.03839 },
    { lat: 49.218775, lng: 21.0386 },
    { lat: 49.218725, lng: 21.038833 },
    { lat: 49.2188, lng: 21.038884 },
    { lat: 49.218777, lng: 21.038981 },
    { lat: 49.218715, lng: 21.038967 },
    { lat: 49.218661, lng: 21.039212 },
    { lat: 49.218716, lng: 21.039247 },
    { lat: 49.218674, lng: 21.0394 },
    { lat: 49.218533, lng: 21.039737 },
    { lat: 49.218266, lng: 21.040289 },
    { lat: 49.218166, lng: 21.040627 },
    { lat: 49.218046, lng: 21.040826 },
    { lat: 49.217945, lng: 21.041676 },
    { lat: 49.217772, lng: 21.042089 },
    { lat: 49.217644, lng: 21.042149 },
    { lat: 49.217602, lng: 21.042285 },
    { lat: 49.217478, lng: 21.042688 },
    { lat: 49.21748, lng: 21.042951 },
    { lat: 49.217398, lng: 21.043327 },
    { lat: 49.217218, lng: 21.043514 },
    { lat: 49.217245, lng: 21.043635 },
    { lat: 49.2172, lng: 21.043912 },
    { lat: 49.217181, lng: 21.044004 },
    { lat: 49.217143, lng: 21.044249 },
    { lat: 49.217094, lng: 21.044501 },
    { lat: 49.216935, lng: 21.044962 },
    { lat: 49.216903, lng: 21.045247 },
    { lat: 49.2177651, lng: 21.0458334 },
    { lat: 49.2178289, lng: 21.0459251 },
    { lat: 49.2180129, lng: 21.0458785 },
    { lat: 49.218512, lng: 21.0462069 },
    { lat: 49.2185508, lng: 21.0463275 },
    { lat: 49.2188736, lng: 21.0464946 },
    { lat: 49.2190857, lng: 21.0468256 },
    { lat: 49.2192387, lng: 21.0468904 },
    { lat: 49.2201477, lng: 21.0467502 },
    { lat: 49.2202998, lng: 21.0468586 },
    { lat: 49.2207141, lng: 21.0467186 },
    { lat: 49.2208335, lng: 21.0465836 },
    { lat: 49.2209164, lng: 21.0466832 },
    { lat: 49.2212082, lng: 21.0466565 },
    { lat: 49.2213058, lng: 21.0465386 },
    { lat: 49.2213739, lng: 21.0465954 },
    { lat: 49.2215033, lng: 21.0464549 },
    { lat: 49.2216236, lng: 21.0464203 },
    { lat: 49.2217566, lng: 21.0465578 },
    { lat: 49.2218442, lng: 21.0464159 },
    { lat: 49.2220299, lng: 21.0463478 },
    { lat: 49.2220977, lng: 21.0461472 },
    { lat: 49.2221754, lng: 21.0461493 },
    { lat: 49.222306, lng: 21.0459083 },
    { lat: 49.2224808, lng: 21.0457803 },
    { lat: 49.222575, lng: 21.0456302 },
    { lat: 49.2227356, lng: 21.0456224 },
    { lat: 49.2229844, lng: 21.0454834 },
    { lat: 49.2230125, lng: 21.0454125 },
    { lat: 49.2232064, lng: 21.0454947 },
    { lat: 49.223388, lng: 21.0453359 },
    { lat: 49.2235925, lng: 21.0452917 },
    { lat: 49.2238175, lng: 21.0451101 },
    { lat: 49.2238627, lng: 21.0451525 },
    { lat: 49.2240414, lng: 21.0450435 },
    { lat: 49.2243262, lng: 21.0453612 },
    { lat: 49.2244865, lng: 21.0453379 },
    { lat: 49.2245858, lng: 21.0452069 },
    { lat: 49.2250211, lng: 21.0454463 },
    { lat: 49.2251803, lng: 21.0453608 },
    { lat: 49.2255313, lng: 21.0454592 },
    { lat: 49.2257273, lng: 21.0453264 },
    { lat: 49.2258779, lng: 21.0454183 },
    { lat: 49.2260673, lng: 21.0454004 },
    { lat: 49.2264762, lng: 21.0455798 },
    { lat: 49.2267191, lng: 21.0456112 },
    { lat: 49.2269306, lng: 21.0458224 },
    { lat: 49.2276373, lng: 21.0458558 },
    { lat: 49.2278106, lng: 21.0459565 },
    { lat: 49.2280123, lng: 21.0459319 },
    { lat: 49.2282267, lng: 21.0457775 },
    { lat: 49.2283868, lng: 21.0457845 },
    { lat: 49.2286543, lng: 21.0454964 },
    { lat: 49.2291293, lng: 21.0453961 },
    { lat: 49.2292537, lng: 21.0451015 },
    { lat: 49.2297378, lng: 21.0451445 },
    { lat: 49.2299318, lng: 21.044772 },
    { lat: 49.230111, lng: 21.0447116 },
    { lat: 49.230148, lng: 21.0444283 },
    { lat: 49.2303108, lng: 21.0444513 },
    { lat: 49.230399, lng: 21.0442934 },
    { lat: 49.2304196, lng: 21.0441273 },
    { lat: 49.2306429, lng: 21.0439725 },
    { lat: 49.230896, lng: 21.0439318 },
    { lat: 49.2310118, lng: 21.0439994 },
    { lat: 49.23121, lng: 21.0435494 },
    { lat: 49.2313054, lng: 21.043512 },
    { lat: 49.2313694, lng: 21.043259 },
    { lat: 49.2314952, lng: 21.0430425 },
    { lat: 49.2317442, lng: 21.0430222 },
    { lat: 49.2320259, lng: 21.0426826 },
    { lat: 49.2322201, lng: 21.042673 },
    { lat: 49.2322701, lng: 21.0425482 },
    { lat: 49.2324716, lng: 21.0425823 },
    { lat: 49.2325509, lng: 21.0426714 },
    { lat: 49.2328079, lng: 21.0425396 },
    { lat: 49.232896, lng: 21.0426237 },
    { lat: 49.2332638, lng: 21.0423727 },
    { lat: 49.2333834, lng: 21.0421432 },
    { lat: 49.2337204, lng: 21.0421567 },
    { lat: 49.2340318, lng: 21.0420096 },
    { lat: 49.2340349, lng: 21.042139 },
    { lat: 49.2346809, lng: 21.0419613 },
    { lat: 49.2348389, lng: 21.0417732 },
    { lat: 49.2350047, lng: 21.0416793 },
    { lat: 49.2351225, lng: 21.0417338 },
    { lat: 49.235293, lng: 21.0414422 },
    { lat: 49.2355177, lng: 21.0416002 },
    { lat: 49.2358736, lng: 21.0414361 },
    { lat: 49.236265, lng: 21.0415358 },
    { lat: 49.2363622, lng: 21.0414129 },
    { lat: 49.2366211, lng: 21.041479 },
    { lat: 49.2368011, lng: 21.04138 },
    { lat: 49.2369696, lng: 21.0414346 },
    { lat: 49.2370724, lng: 21.0415486 },
    { lat: 49.2374787, lng: 21.0414466 },
    { lat: 49.2377801, lng: 21.0416896 },
    { lat: 49.2378849, lng: 21.0417263 },
    { lat: 49.2379908, lng: 21.0416636 },
    { lat: 49.2383102, lng: 21.0419347 },
    { lat: 49.238395, lng: 21.0418757 },
    { lat: 49.2387082, lng: 21.0418838 },
    { lat: 49.2389178, lng: 21.0420995 },
    { lat: 49.2392321, lng: 21.0420404 },
    { lat: 49.2396637, lng: 21.0424065 },
    { lat: 49.2401727, lng: 21.0425261 },
    { lat: 49.2403634, lng: 21.0426468 },
    { lat: 49.2406759, lng: 21.0425612 },
    { lat: 49.2408838, lng: 21.0426251 },
    { lat: 49.240903, lng: 21.0427299 },
    { lat: 49.2412265, lng: 21.0429241 },
    { lat: 49.2414067, lng: 21.042962 },
    { lat: 49.2416486, lng: 21.0428212 },
    { lat: 49.2418007, lng: 21.0429596 },
    { lat: 49.2420907, lng: 21.0427766 },
    { lat: 49.242176, lng: 21.0428779 },
    { lat: 49.2422692, lng: 21.0431467 },
    { lat: 49.2424396, lng: 21.0432682 },
    { lat: 49.2427687, lng: 21.0433161 },
    { lat: 49.2432125, lng: 21.0432278 },
    { lat: 49.2434035, lng: 21.0434608 },
    { lat: 49.2435057, lng: 21.0436987 },
    { lat: 49.2435684, lng: 21.0440034 },
    { lat: 49.2439542, lng: 21.0439959 },
    { lat: 49.2444424, lng: 21.0442305 },
    { lat: 49.244738, lng: 21.0445976 },
    { lat: 49.244898, lng: 21.0446748 },
    { lat: 49.2451712, lng: 21.0450864 },
    { lat: 49.2451926, lng: 21.0455743 },
    { lat: 49.2451477, lng: 21.0460672 },
    { lat: 49.2452941, lng: 21.0463479 },
    { lat: 49.2452125, lng: 21.0465741 },
    { lat: 49.2445059, lng: 21.0474832 },
    { lat: 49.2444619, lng: 21.0478925 },
    { lat: 49.2445365, lng: 21.0483908 },
    { lat: 49.2448137, lng: 21.0490315 },
    { lat: 49.2449637, lng: 21.0496296 },
    { lat: 49.2451729, lng: 21.0499737 },
    { lat: 49.2458594, lng: 21.0505371 },
    { lat: 49.2461226, lng: 21.0509441 },
    { lat: 49.2463346, lng: 21.0515156 },
    { lat: 49.2466807, lng: 21.0519574 },
    { lat: 49.2473691, lng: 21.0524332 },
    { lat: 49.2475062, lng: 21.0526033 },
    { lat: 49.2478853, lng: 21.0537122 },
    { lat: 49.248094, lng: 21.0555735 },
    { lat: 49.2483569, lng: 21.0555296 },
    { lat: 49.2489595, lng: 21.0542163 },
    { lat: 49.2500771, lng: 21.0526566 },
    { lat: 49.2506557, lng: 21.0525247 },
    { lat: 49.2512012, lng: 21.0521331 },
    { lat: 49.2514746, lng: 21.0513985 },
    { lat: 49.251863, lng: 21.0509167 },
    { lat: 49.2528319, lng: 21.0505 },
    { lat: 49.2533386, lng: 21.0501919 },
    { lat: 49.2538262, lng: 21.0497495 },
    { lat: 49.2539464, lng: 21.0490859 },
  ],
  Marhaň: [
    { lat: 49.174166, lng: 21.483019 },
    { lat: 49.174221, lng: 21.482866 },
    { lat: 49.174261, lng: 21.482817 },
    { lat: 49.174304, lng: 21.482724 },
    { lat: 49.174306, lng: 21.482605 },
    { lat: 49.174364, lng: 21.482485 },
    { lat: 49.174383, lng: 21.482372 },
    { lat: 49.174421, lng: 21.482252 },
    { lat: 49.174445, lng: 21.482086 },
    { lat: 49.174605, lng: 21.481811 },
    { lat: 49.17463, lng: 21.481617 },
    { lat: 49.174685, lng: 21.481447 },
    { lat: 49.174761, lng: 21.481279 },
    { lat: 49.17481, lng: 21.481197 },
    { lat: 49.174975, lng: 21.480922 },
    { lat: 49.175059, lng: 21.480694 },
    { lat: 49.175099, lng: 21.480423 },
    { lat: 49.175137, lng: 21.480327 },
    { lat: 49.175195, lng: 21.480007 },
    { lat: 49.175368, lng: 21.479729 },
    { lat: 49.175473, lng: 21.479349 },
    { lat: 49.175564, lng: 21.479291 },
    { lat: 49.175667, lng: 21.479042 },
    { lat: 49.17568, lng: 21.479031 },
    { lat: 49.175771, lng: 21.479041 },
    { lat: 49.175874, lng: 21.478947 },
    { lat: 49.176069, lng: 21.478727 },
    { lat: 49.176179, lng: 21.478638 },
    { lat: 49.176352, lng: 21.478179 },
    { lat: 49.176441, lng: 21.478079 },
    { lat: 49.176507, lng: 21.477975 },
    { lat: 49.176506, lng: 21.477823 },
    { lat: 49.176658, lng: 21.477696 },
    { lat: 49.17674, lng: 21.477709 },
    { lat: 49.176934, lng: 21.477419 },
    { lat: 49.176974, lng: 21.477384 },
    { lat: 49.17704, lng: 21.477342 },
    { lat: 49.177417, lng: 21.477008 },
    { lat: 49.177917, lng: 21.476763 },
    { lat: 49.178017, lng: 21.476602 },
    { lat: 49.178235, lng: 21.476632 },
    { lat: 49.178421, lng: 21.476548 },
    { lat: 49.17856, lng: 21.476646 },
    { lat: 49.178592, lng: 21.476626 },
    { lat: 49.179349, lng: 21.476423 },
    { lat: 49.17973, lng: 21.47649 },
    { lat: 49.180198, lng: 21.476457 },
    { lat: 49.180305, lng: 21.476382 },
    { lat: 49.180523, lng: 21.476544 },
    { lat: 49.180695, lng: 21.476486 },
    { lat: 49.180745, lng: 21.476426 },
    { lat: 49.180845, lng: 21.476471 },
    { lat: 49.180964, lng: 21.476713 },
    { lat: 49.181071, lng: 21.476819 },
    { lat: 49.181213, lng: 21.47683 },
    { lat: 49.181309, lng: 21.476854 },
    { lat: 49.181338, lng: 21.47684 },
    { lat: 49.181428, lng: 21.476846 },
    { lat: 49.181459, lng: 21.476799 },
    { lat: 49.181497, lng: 21.476819 },
    { lat: 49.181551, lng: 21.476792 },
    { lat: 49.18166, lng: 21.476772 },
    { lat: 49.181971, lng: 21.476547 },
    { lat: 49.181996, lng: 21.47651 },
    { lat: 49.182015, lng: 21.476512 },
    { lat: 49.182116, lng: 21.476456 },
    { lat: 49.18215, lng: 21.476403 },
    { lat: 49.182186, lng: 21.47641 },
    { lat: 49.182245, lng: 21.47637 },
    { lat: 49.182507, lng: 21.476383 },
    { lat: 49.182628, lng: 21.476346 },
    { lat: 49.182721, lng: 21.476412 },
    { lat: 49.182767, lng: 21.476464 },
    { lat: 49.182812, lng: 21.476404 },
    { lat: 49.183002, lng: 21.476378 },
    { lat: 49.183106, lng: 21.476464 },
    { lat: 49.183173, lng: 21.476459 },
    { lat: 49.183328, lng: 21.476569 },
    { lat: 49.183516, lng: 21.476788 },
    { lat: 49.18378, lng: 21.476982 },
    { lat: 49.183841, lng: 21.477116 },
    { lat: 49.183902, lng: 21.477146 },
    { lat: 49.183991, lng: 21.477282 },
    { lat: 49.184039, lng: 21.477298 },
    { lat: 49.184085, lng: 21.477263 },
    { lat: 49.184124, lng: 21.477334 },
    { lat: 49.184239, lng: 21.477334 },
    { lat: 49.184324, lng: 21.477426 },
    { lat: 49.184414, lng: 21.47756 },
    { lat: 49.184461, lng: 21.477869 },
    { lat: 49.184529, lng: 21.477999 },
    { lat: 49.184652, lng: 21.478086 },
    { lat: 49.184684, lng: 21.478261 },
    { lat: 49.184703, lng: 21.478314 },
    { lat: 49.185319, lng: 21.478899 },
    { lat: 49.185418, lng: 21.479085 },
    { lat: 49.185731, lng: 21.478673 },
    { lat: 49.185851, lng: 21.478881 },
    { lat: 49.186192, lng: 21.479506 },
    { lat: 49.186414, lng: 21.479309 },
    { lat: 49.186707, lng: 21.478999 },
    { lat: 49.186804, lng: 21.478841 },
    { lat: 49.187328, lng: 21.47826 },
    { lat: 49.187634, lng: 21.477903 },
    { lat: 49.187842, lng: 21.47764 },
    { lat: 49.187957, lng: 21.477535 },
    { lat: 49.188326, lng: 21.477352 },
    { lat: 49.188327, lng: 21.477377 },
    { lat: 49.188423, lng: 21.477399 },
    { lat: 49.188601, lng: 21.477341 },
    { lat: 49.188769, lng: 21.47734 },
    { lat: 49.188848, lng: 21.477314 },
    { lat: 49.189348, lng: 21.477015 },
    { lat: 49.189576, lng: 21.477082 },
    { lat: 49.189909, lng: 21.477352 },
    { lat: 49.190001, lng: 21.477355 },
    { lat: 49.190117, lng: 21.477295 },
    { lat: 49.190297, lng: 21.477242 },
    { lat: 49.1904, lng: 21.477212 },
    { lat: 49.19087, lng: 21.477121 },
    { lat: 49.191193, lng: 21.47701 },
    { lat: 49.191317, lng: 21.476961 },
    { lat: 49.191422, lng: 21.476873 },
    { lat: 49.191565, lng: 21.476802 },
    { lat: 49.19174, lng: 21.476835 },
    { lat: 49.19183, lng: 21.476757 },
    { lat: 49.19195, lng: 21.47659 },
    { lat: 49.192009, lng: 21.476564 },
    { lat: 49.192153, lng: 21.476588 },
    { lat: 49.192406, lng: 21.476465 },
    { lat: 49.192529, lng: 21.476352 },
    { lat: 49.192652, lng: 21.476281 },
    { lat: 49.192847, lng: 21.476203 },
    { lat: 49.193114, lng: 21.476352 },
    { lat: 49.193184, lng: 21.476337 },
    { lat: 49.193557, lng: 21.476051 },
    { lat: 49.1936089, lng: 21.4756262 },
    { lat: 49.1935978, lng: 21.4751233 },
    { lat: 49.1936635, lng: 21.4749819 },
    { lat: 49.1936073, lng: 21.4743539 },
    { lat: 49.193632, lng: 21.4740088 },
    { lat: 49.1936791, lng: 21.4739243 },
    { lat: 49.1941297, lng: 21.4734654 },
    { lat: 49.1945786, lng: 21.4734336 },
    { lat: 49.1947933, lng: 21.4733088 },
    { lat: 49.1949695, lng: 21.4729434 },
    { lat: 49.1952445, lng: 21.4729156 },
    { lat: 49.195601, lng: 21.4727173 },
    { lat: 49.1954882, lng: 21.472082 },
    { lat: 49.1955096, lng: 21.4712618 },
    { lat: 49.1954131, lng: 21.4709557 },
    { lat: 49.1957749, lng: 21.4702296 },
    { lat: 49.1958096, lng: 21.469838 },
    { lat: 49.1957825, lng: 21.4692895 },
    { lat: 49.1958484, lng: 21.4690318 },
    { lat: 49.1958366, lng: 21.4688178 },
    { lat: 49.1958963, lng: 21.4683958 },
    { lat: 49.1958171, lng: 21.4682954 },
    { lat: 49.1957873, lng: 21.467893 },
    { lat: 49.1955899, lng: 21.4673238 },
    { lat: 49.1955983, lng: 21.4669535 },
    { lat: 49.1955162, lng: 21.4667528 },
    { lat: 49.1953242, lng: 21.4656772 },
    { lat: 49.1952619, lng: 21.4655443 },
    { lat: 49.1952603, lng: 21.4651782 },
    { lat: 49.1950996, lng: 21.4646727 },
    { lat: 49.1950093, lng: 21.4645507 },
    { lat: 49.1949306, lng: 21.4641592 },
    { lat: 49.1948484, lng: 21.464041 },
    { lat: 49.1948889, lng: 21.4639656 },
    { lat: 49.194821, lng: 21.4635547 },
    { lat: 49.1947194, lng: 21.4635082 },
    { lat: 49.1943082, lng: 21.4623534 },
    { lat: 49.1942426, lng: 21.4620301 },
    { lat: 49.1942601, lng: 21.4618948 },
    { lat: 49.1941537, lng: 21.4617922 },
    { lat: 49.1940329, lng: 21.4607258 },
    { lat: 49.1939571, lng: 21.460365 },
    { lat: 49.1938576, lng: 21.4602311 },
    { lat: 49.1939338, lng: 21.4601371 },
    { lat: 49.1939005, lng: 21.4597656 },
    { lat: 49.1939983, lng: 21.4596083 },
    { lat: 49.1938997, lng: 21.4589228 },
    { lat: 49.1939055, lng: 21.458462 },
    { lat: 49.1936814, lng: 21.4576302 },
    { lat: 49.1937422, lng: 21.4573211 },
    { lat: 49.1935313, lng: 21.4571105 },
    { lat: 49.1934971, lng: 21.4565879 },
    { lat: 49.1932035, lng: 21.4564307 },
    { lat: 49.1929465, lng: 21.4558335 },
    { lat: 49.1929458, lng: 21.4557289 },
    { lat: 49.1926654, lng: 21.4554068 },
    { lat: 49.1926736, lng: 21.4551828 },
    { lat: 49.1924811, lng: 21.455026 },
    { lat: 49.1923776, lng: 21.4547952 },
    { lat: 49.1922686, lng: 21.4548755 },
    { lat: 49.19219, lng: 21.4547258 },
    { lat: 49.1921975, lng: 21.4545915 },
    { lat: 49.1920643, lng: 21.4544627 },
    { lat: 49.1919029, lng: 21.4541314 },
    { lat: 49.1917131, lng: 21.4539118 },
    { lat: 49.1916978, lng: 21.4537726 },
    { lat: 49.1915566, lng: 21.4536339 },
    { lat: 49.1916084, lng: 21.4534034 },
    { lat: 49.1915182, lng: 21.4532447 },
    { lat: 49.1915887, lng: 21.4529914 },
    { lat: 49.1906254, lng: 21.4510743 },
    { lat: 49.1910184, lng: 21.450237 },
    { lat: 49.1903209, lng: 21.4497351 },
    { lat: 49.1917023, lng: 21.446342 },
    { lat: 49.1917116, lng: 21.4461983 },
    { lat: 49.1931581, lng: 21.4451766 },
    { lat: 49.1936801, lng: 21.4446349 },
    { lat: 49.193932, lng: 21.444165 },
    { lat: 49.1940657, lng: 21.4437261 },
    { lat: 49.193993, lng: 21.4428547 },
    { lat: 49.1940256, lng: 21.4426205 },
    { lat: 49.1945383, lng: 21.4420254 },
    { lat: 49.1949294, lng: 21.4420486 },
    { lat: 49.195239, lng: 21.4419349 },
    { lat: 49.1956381, lng: 21.4419666 },
    { lat: 49.1955148, lng: 21.4410892 },
    { lat: 49.1955905, lng: 21.4402848 },
    { lat: 49.1940979, lng: 21.4386783 },
    { lat: 49.1943921, lng: 21.4378789 },
    { lat: 49.1939797, lng: 21.4373924 },
    { lat: 49.193713, lng: 21.4362682 },
    { lat: 49.1915419, lng: 21.4286967 },
    { lat: 49.1903952, lng: 21.4275651 },
    { lat: 49.1887647, lng: 21.4274037 },
    { lat: 49.1887257, lng: 21.4266823 },
    { lat: 49.1871286, lng: 21.4270528 },
    { lat: 49.186368, lng: 21.4271655 },
    { lat: 49.1854955, lng: 21.4272944 },
    { lat: 49.1852001, lng: 21.4274727 },
    { lat: 49.1850113, lng: 21.4273226 },
    { lat: 49.1847521, lng: 21.4278538 },
    { lat: 49.1847686, lng: 21.4286464 },
    { lat: 49.1849235, lng: 21.4290571 },
    { lat: 49.1852744, lng: 21.4291758 },
    { lat: 49.1859316, lng: 21.4289032 },
    { lat: 49.186451, lng: 21.4288155 },
    { lat: 49.186792, lng: 21.4291654 },
    { lat: 49.1868553, lng: 21.4294067 },
    { lat: 49.1868925, lng: 21.4299531 },
    { lat: 49.1865346, lng: 21.4309499 },
    { lat: 49.1864928, lng: 21.4313696 },
    { lat: 49.1861401, lng: 21.4323992 },
    { lat: 49.1861481, lng: 21.4335981 },
    { lat: 49.1851492, lng: 21.432294 },
    { lat: 49.1843466, lng: 21.4314635 },
    { lat: 49.1834537, lng: 21.4339175 },
    { lat: 49.1833525, lng: 21.4341502 },
    { lat: 49.1832761, lng: 21.4341187 },
    { lat: 49.1832137, lng: 21.4368059 },
    { lat: 49.1832257, lng: 21.4381934 },
    { lat: 49.1824689, lng: 21.4415713 },
    { lat: 49.1826765, lng: 21.4416346 },
    { lat: 49.1826316, lng: 21.4418322 },
    { lat: 49.182494, lng: 21.4420558 },
    { lat: 49.1822782, lng: 21.4426376 },
    { lat: 49.1803366, lng: 21.4439356 },
    { lat: 49.1794448, lng: 21.4437487 },
    { lat: 49.1789615, lng: 21.4432232 },
    { lat: 49.1782753, lng: 21.4420644 },
    { lat: 49.1769684, lng: 21.4389417 },
    { lat: 49.1757016, lng: 21.4383944 },
    { lat: 49.1759049, lng: 21.4373838 },
    { lat: 49.175409, lng: 21.4363425 },
    { lat: 49.1753679, lng: 21.4359727 },
    { lat: 49.1743504, lng: 21.4366172 },
    { lat: 49.1729497, lng: 21.4373189 },
    { lat: 49.1713827, lng: 21.4379419 },
    { lat: 49.1713819, lng: 21.4381879 },
    { lat: 49.17052, lng: 21.4386136 },
    { lat: 49.1695748, lng: 21.4396177 },
    { lat: 49.1697465, lng: 21.4401418 },
    { lat: 49.1697112, lng: 21.4405149 },
    { lat: 49.1699122, lng: 21.441512 },
    { lat: 49.1683428, lng: 21.4424392 },
    { lat: 49.1673282, lng: 21.4422994 },
    { lat: 49.1663885, lng: 21.4433332 },
    { lat: 49.1659717, lng: 21.442423 },
    { lat: 49.1648338, lng: 21.4434985 },
    { lat: 49.1645535, lng: 21.4443066 },
    { lat: 49.1642058, lng: 21.4444947 },
    { lat: 49.1625848, lng: 21.4457903 },
    { lat: 49.1626317, lng: 21.4461625 },
    { lat: 49.1629382, lng: 21.4468396 },
    { lat: 49.1628222, lng: 21.4473756 },
    { lat: 49.1627405, lng: 21.4482838 },
    { lat: 49.1613388, lng: 21.4501196 },
    { lat: 49.1596868, lng: 21.4520091 },
    { lat: 49.1592555, lng: 21.4524217 },
    { lat: 49.1586156, lng: 21.4531704 },
    { lat: 49.1583384, lng: 21.4532372 },
    { lat: 49.1573314, lng: 21.455415 },
    { lat: 49.1568342, lng: 21.4575178 },
    { lat: 49.1564274, lng: 21.4583147 },
    { lat: 49.156247, lng: 21.459119 },
    { lat: 49.156223, lng: 21.459987 },
    { lat: 49.157498, lng: 21.460564 },
    { lat: 49.158631, lng: 21.461193 },
    { lat: 49.159132, lng: 21.461888 },
    { lat: 49.15842, lng: 21.463437 },
    { lat: 49.161211, lng: 21.4649741 },
    { lat: 49.1632925, lng: 21.4669312 },
    { lat: 49.1630431, lng: 21.4675709 },
    { lat: 49.1641623, lng: 21.4681121 },
    { lat: 49.1660283, lng: 21.4711808 },
    { lat: 49.1663193, lng: 21.4713983 },
    { lat: 49.1683868, lng: 21.4738138 },
    { lat: 49.1690893, lng: 21.4744525 },
    { lat: 49.1710688, lng: 21.4776047 },
    { lat: 49.1714357, lng: 21.4783126 },
    { lat: 49.1727928, lng: 21.4804456 },
    { lat: 49.172383, lng: 21.4810184 },
    { lat: 49.174166, lng: 21.483019 },
  ],
};

export default UnitsBardejov;
