import { Fragment, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useMediaQuery } from "usehooks-ts";
import historyDark from "../../Images/Pins/historyDark.png";
import historyFlatDark from "../../Images/Pins/historyFlatDark.png";
import { Marker } from "@react-google-maps/api";

export default function HistoryPin({ visibilityOfMarkers, historyItems, currentIndex, setHistoryInfo, historyInfo }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const druhStavby = useRef("");
  const historyItem = useRef("");

  function icon(index) {
    if (historyItems[index].response?.List?.length !== undefined) {
      historyItem.current = historyItems[index].response?.List[0];
      if (historyItems[index].response?.List[0]?.table_stavby?.length === undefined) {
        druhStavby.current = historyItems[index].response?.List[0]?.table_stavby?.druh_stavby;
      } else {
        druhStavby.current = historyItems[index].response?.List[0]?.table_stavby[0]?.druh_stavby;
      }
    } else {
      historyItem.current = historyItems[index].response?.List;
      // console.log("druh stavby = ", historyItems[index].response?.List?.table_stavby?.length);
      if (historyItems[index].response?.List?.table_stavby?.length === undefined) {
        druhStavby.current = historyItems[index].response?.List?.table_stavby?.druh_stavby;
      } else {
        druhStavby.current = historyItems[index].response?.List?.table_stavby[0]?.druh_stavby;
      }
    }
    if (historyItem.current?.hasOwnProperty("table_stavby")) {
      if (druhStavby.current === "9") {
        return historyFlatDark;
      } else {
        return historyDark;
      }
    } else {
      return historyDark;
    }
  }

  return visibilityOfMarkers === "singleHistoryPin" ? (
    <>
      {[...historyItems]
        .filter((historyItems, item) => item === currentIndex)
        .map((historyItem) => (
          <Fragment key={uuidv4()}>
            <Marker
              onClick={() => {
                if (isSmallDevice) {
                  setHistoryInfo(!historyInfo);
                }
              }}
              key={uuidv4()}
              position={{ lat: historyItem.latAndLng.lat, lng: historyItem.latAndLng.lng }}
              icon={{ url: icon(currentIndex), scaledSize: new window.google.maps.Size(50, 50) }}
            ></Marker>
          </Fragment>
        ))}
    </>
  ) : null;
}
