import IonIcon from "@reacticons/ionicons";
import { motion } from "framer-motion";
import Telegram from "../../Images/telegram.png";
import Twitter from "../../Images/twitter.png";
import clsx from "clsx";
// import { useMediaQuery } from "usehooks-ts";
import { activityService } from "../../services/activityService";

export default function Share({ subMenu, size, items, active }) {
  // const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  // console.log("items = ", items);

  const copyToClipboard = () => {
    if (active?.includes("forSale")) {
      navigator.clipboard.writeText("localhost:3000/share/" + items.id + "/" + items.lat + "/" + items.lng);
    }
    if (active?.includes("history")) {
      const baseUrl = "localhost:3000/shareHistory/";
      const location = items.location ? items.location : "";
      const number = items.number ? items.number : "";
      const psc = items.psc ? items.psc.replace(/\s+/g, "") : "";
      const lvNum = items.lvNum ? items.lvNum : "";
      const flat = items?.flat ? items.flat : "";
      const entrance = items?.entrance ? items.entrance.replace(/\s+/g, "") : "";
      const street = items?.street ? items.street : "";
      const lat = items.latAndLng.lat ? items.latAndLng.lat : "";
      const lng = items.latAndLng.lng ? items.latAndLng.lng : "";

      const url = `${baseUrl}${location}_${number}_${psc}_${lvNum}_${flat}_${entrance}_${street}/${lat}/${lng}`;
      navigator.clipboard.writeText(url);
    } else {
      console.log("in progress");
    }
  };

  const saveActivity = async (activity) => {
    const email = JSON.parse(localStorage.getItem("userData"))?.m;
    const request = JSON.parse(JSON.stringify({ email: email, activityId: activity }));
    return await activityService.saveActivity(request);
  };

  return subMenu?.share ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-full flex flex-col items-start pt-1"
      initial={{ x: 500 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-base w-full flex flex-col items-center font-semibold gap-2">
        <button
          onClick={() => {
            copyToClipboard();
            saveActivity("31");
            // window.open(
            //   "https://www.facebook.com/sharer/sharer.php?u=localhost:3000/share/" +
            //     forSaleItems.id +
            //     "/" +
            //     forSaleItems.lat +
            //     "/" +
            //     forSaleItems.lng,
            //   "_blank",
            // );
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-blue-200 rounded-lg", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <IonIcon name="logo-facebook" className="!w-5 !h-5" />
          Facebook
        </button>
        <button
          onClick={() => {
            copyToClipboard();
            saveActivity("35");
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-green-200 rounded-lg", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <IonIcon name="logo-whatsapp" className="!w-5 !h-5" />
          WhatsApp
        </button>
        <button
          onClick={() => {
            copyToClipboard();
            saveActivity("34");
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-blue-200 rounded-lg", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={Telegram} alt="" className="w-5 h-5" /> Telegram
        </button>
        <button
          onClick={() => {
            copyToClipboard();
            saveActivity("32");
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-purple-200 rounded-lg", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <IonIcon name="logo-instagram" className="!w-5 !h-5" /> Instagram
        </button>
        <button
          onClick={() => {
            copyToClipboard();
            saveActivity("33");
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-blue-200 rounded-lg", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={Twitter} alt="" className="w-5 h-5" /> Twitter
        </button>
      </div>
    </motion.div>
  ) : null;
}
