import { React } from "react";

export default function HistoryTarchy({ historyItem }) {
  // console.log("historyItem = ", historyItem);
  function getTableBodyTarchy() {
    if (historyItem?.response?.List?.tarchy instanceof Array) {
      return !historyItem ? null : (
        <tbody>
          {historyItem?.response?.List?.tarchy?.map((item, index) => {
            return (
              <tr key={index} className="border border-opacity-50 border-sky-500">
                <td className="text-center w-40">
                  Poradové č. vlastníka <br />
                  {item?.poradove_c_vlastnika_}
                </td>
                <td className="text-center">{item?.tarcha}</td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    if (historyItem?.response?.List?.tarchy instanceof Object) {
      return (
        <tbody>
          <tr className="border border-opacity-50 border-sky-500">
            <td className="text-center w-40">
              Poradové č. vlastníka <br />
              {historyItem?.response?.List?.tarchy?.poradove_c_vlastnika_}
            </td>
            <td className="text-center">{historyItem?.response?.List?.tarchy?.tarcha}</td>
          </tr>
        </tbody>
      );
    }
  }

  return (
    <>
      <div className="flex flex-col overflow-scroll">
        <table className="w-full">{getTableBodyTarchy()}</table>
      </div>
    </>
  );
}
