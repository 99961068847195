import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import FsTarchy from "./FsTarchy";

export default function FsTarcha({ lvSubMenu, setLvSubMenu, lv }) {
  return (
    <>
      <div className="">
        <div className="p-2 flex flex-row gap-x-2 font-bold">
          <p className="text-lg uppercase text-sky-500">Časť C: Ťarchy</p>
          <button
            onClick={() => setLvSubMenu({ ...lvSubMenu, tarchy: !lvSubMenu.tarchy })}
            className={`flex items-start z-10 w-8 p-1 text-black`}
          >
            {lvSubMenu.tarchy ? (
              <FontAwesomeIcon className="h-6" icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon className="h-6" icon={faChevronDown} />
            )}{" "}
          </button>
        </div>
      </div>
      <AnimatePresence>
        {lvSubMenu.tarchy ? (
          <motion.div
            className="flex flex-col overflow-hidden px-2"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0 }}
          >
            <FsTarchy lv={lv} />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
}
