const UnitsZlatéMoravce = {
  Hostie: [
    { lat: 48.4829107, lng: 18.4711848 },
    { lat: 48.4829608, lng: 18.4712731 },
    { lat: 48.4836719, lng: 18.471936 },
    { lat: 48.4840415, lng: 18.4721091 },
    { lat: 48.4843549, lng: 18.4724702 },
    { lat: 48.484617, lng: 18.4726233 },
    { lat: 48.4848017, lng: 18.4726162 },
    { lat: 48.4854284, lng: 18.4730949 },
    { lat: 48.4856105, lng: 18.4731474 },
    { lat: 48.4864494, lng: 18.4730999 },
    { lat: 48.4870828, lng: 18.4734334 },
    { lat: 48.4873755, lng: 18.4733192 },
    { lat: 48.4878039, lng: 18.4734786 },
    { lat: 48.4879731, lng: 18.4736184 },
    { lat: 48.4881398, lng: 18.473637 },
    { lat: 48.4882684, lng: 18.4737784 },
    { lat: 48.4885419, lng: 18.4739092 },
    { lat: 48.4889864, lng: 18.4740523 },
    { lat: 48.4894745, lng: 18.4743723 },
    { lat: 48.4901942, lng: 18.474088 },
    { lat: 48.4904705, lng: 18.4741266 },
    { lat: 48.4907739, lng: 18.4743283 },
    { lat: 48.4911761, lng: 18.4744632 },
    { lat: 48.4914378, lng: 18.474706 },
    { lat: 48.4915679, lng: 18.4749173 },
    { lat: 48.4920253, lng: 18.4751527 },
    { lat: 48.4924619, lng: 18.4750927 },
    { lat: 48.4926898, lng: 18.475498 },
    { lat: 48.4928325, lng: 18.475876 },
    { lat: 48.4931202, lng: 18.4762831 },
    { lat: 48.4933778, lng: 18.4764959 },
    { lat: 48.4937425, lng: 18.4764598 },
    { lat: 48.4938477, lng: 18.4763026 },
    { lat: 48.4939797, lng: 18.4764066 },
    { lat: 48.4941447, lng: 18.475882 },
    { lat: 48.494288, lng: 18.4756339 },
    { lat: 48.4946267, lng: 18.4753567 },
    { lat: 48.4954336, lng: 18.4743028 },
    { lat: 48.4957554, lng: 18.4740608 },
    { lat: 48.4961529, lng: 18.473602 },
    { lat: 48.4963294, lng: 18.473592 },
    { lat: 48.496358, lng: 18.4736389 },
    { lat: 48.4957411, lng: 18.4744994 },
    { lat: 48.4939843, lng: 18.4765287 },
    { lat: 48.4939739, lng: 18.4768103 },
    { lat: 48.4940528, lng: 18.4770952 },
    { lat: 48.4943921, lng: 18.4772939 },
    { lat: 48.4946091, lng: 18.4773186 },
    { lat: 48.4948006, lng: 18.4776603 },
    { lat: 48.4950726, lng: 18.4780969 },
    { lat: 48.4955667, lng: 18.4779715 },
    { lat: 48.4958936, lng: 18.4778221 },
    { lat: 48.4963882, lng: 18.4778743 },
    { lat: 48.4966703, lng: 18.477812 },
    { lat: 48.4971881, lng: 18.4779342 },
    { lat: 48.497292, lng: 18.4778764 },
    { lat: 48.4981259, lng: 18.4780632 },
    { lat: 48.4987371, lng: 18.4786515 },
    { lat: 48.4987912, lng: 18.4788556 },
    { lat: 48.4990371, lng: 18.4790404 },
    { lat: 48.4992713, lng: 18.4793771 },
    { lat: 48.4994991, lng: 18.4796014 },
    { lat: 48.4999134, lng: 18.4803612 },
    { lat: 48.5004752, lng: 18.4806882 },
    { lat: 48.5012015, lng: 18.4809096 },
    { lat: 48.5016315, lng: 18.4812588 },
    { lat: 48.5029191, lng: 18.4815725 },
    { lat: 48.5032595, lng: 18.4815676 },
    { lat: 48.5042674, lng: 18.4818444 },
    { lat: 48.5048344, lng: 18.481899 },
    { lat: 48.5052946, lng: 18.4815077 },
    { lat: 48.5055511, lng: 18.4813927 },
    { lat: 48.5057736, lng: 18.4811557 },
    { lat: 48.506065, lng: 18.4805899 },
    { lat: 48.5063882, lng: 18.4805178 },
    { lat: 48.5059548, lng: 18.4812906 },
    { lat: 48.5055438, lng: 18.4816071 },
    { lat: 48.5050335, lng: 18.4821082 },
    { lat: 48.5052158, lng: 18.4824525 },
    { lat: 48.5056227, lng: 18.4828124 },
    { lat: 48.5058301, lng: 18.4831343 },
    { lat: 48.5063879, lng: 18.4836554 },
    { lat: 48.5065197, lng: 18.4837143 },
    { lat: 48.5067478, lng: 18.4840209 },
    { lat: 48.5069775, lng: 18.4841177 },
    { lat: 48.5075852, lng: 18.4850289 },
    { lat: 48.5077481, lng: 18.4853621 },
    { lat: 48.5080915, lng: 18.4857425 },
    { lat: 48.5086748, lng: 18.48619 },
    { lat: 48.5089343, lng: 18.4866001 },
    { lat: 48.508918, lng: 18.486664 },
    { lat: 48.509091, lng: 18.487032 },
    { lat: 48.509242, lng: 18.487305 },
    { lat: 48.509419, lng: 18.487417 },
    { lat: 48.509593, lng: 18.487413 },
    { lat: 48.509852, lng: 18.487412 },
    { lat: 48.510054, lng: 18.487459 },
    { lat: 48.510361, lng: 18.487538 },
    { lat: 48.510598, lng: 18.487715 },
    { lat: 48.510769, lng: 18.48789 },
    { lat: 48.510947, lng: 18.488044 },
    { lat: 48.511146, lng: 18.488146 },
    { lat: 48.511447, lng: 18.488522 },
    { lat: 48.511615, lng: 18.488909 },
    { lat: 48.511657, lng: 18.488963 },
    { lat: 48.511842, lng: 18.489099 },
    { lat: 48.511924, lng: 18.489202 },
    { lat: 48.5120487, lng: 18.4892338 },
    { lat: 48.5124052, lng: 18.4891309 },
    { lat: 48.5125011, lng: 18.4889488 },
    { lat: 48.5127827, lng: 18.488856 },
    { lat: 48.5128789, lng: 18.489067 },
    { lat: 48.5131724, lng: 18.4890737 },
    { lat: 48.5132936, lng: 18.4888753 },
    { lat: 48.5133993, lng: 18.4888497 },
    { lat: 48.5135671, lng: 18.4885206 },
    { lat: 48.5138671, lng: 18.4883325 },
    { lat: 48.5139639, lng: 18.4880863 },
    { lat: 48.5140448, lng: 18.4880573 },
    { lat: 48.5141786, lng: 18.487804 },
    { lat: 48.5144185, lng: 18.487616 },
    { lat: 48.5148903, lng: 18.4870062 },
    { lat: 48.5151732, lng: 18.4867752 },
    { lat: 48.515248, lng: 18.4866402 },
    { lat: 48.5157371, lng: 18.4862641 },
    { lat: 48.5159378, lng: 18.4859981 },
    { lat: 48.5164329, lng: 18.4855857 },
    { lat: 48.5165981, lng: 18.4855928 },
    { lat: 48.5167274, lng: 18.4853236 },
    { lat: 48.5170631, lng: 18.4851489 },
    { lat: 48.5171547, lng: 18.4849492 },
    { lat: 48.5173631, lng: 18.4848235 },
    { lat: 48.5174495, lng: 18.4847102 },
    { lat: 48.5174808, lng: 18.4845632 },
    { lat: 48.5176431, lng: 18.4844191 },
    { lat: 48.5176678, lng: 18.4842128 },
    { lat: 48.517789, lng: 18.4838942 },
    { lat: 48.5179649, lng: 18.4838312 },
    { lat: 48.5179787, lng: 18.483653 },
    { lat: 48.5180701, lng: 18.4835519 },
    { lat: 48.518249, lng: 18.4835423 },
    { lat: 48.5182345, lng: 18.4830762 },
    { lat: 48.51842, lng: 18.4827185 },
    { lat: 48.5186533, lng: 18.4824659 },
    { lat: 48.5187238, lng: 18.4822532 },
    { lat: 48.5187914, lng: 18.4823344 },
    { lat: 48.5188382, lng: 18.4826085 },
    { lat: 48.5188439, lng: 18.4831017 },
    { lat: 48.5189751, lng: 18.4831483 },
    { lat: 48.5192645, lng: 18.4830764 },
    { lat: 48.5194496, lng: 18.4830985 },
    { lat: 48.5195956, lng: 18.4830282 },
    { lat: 48.5197916, lng: 18.4827756 },
    { lat: 48.5199988, lng: 18.4827479 },
    { lat: 48.5202495, lng: 18.4825923 },
    { lat: 48.5205361, lng: 18.4821408 },
    { lat: 48.5207216, lng: 18.4821315 },
    { lat: 48.520643, lng: 18.4817919 },
    { lat: 48.5206631, lng: 18.4816242 },
    { lat: 48.5210899, lng: 18.4810584 },
    { lat: 48.5213969, lng: 18.4808174 },
    { lat: 48.521491, lng: 18.4804377 },
    { lat: 48.521457, lng: 18.4803128 },
    { lat: 48.5216976, lng: 18.4799923 },
    { lat: 48.5218436, lng: 18.4796947 },
    { lat: 48.5218088, lng: 18.4794506 },
    { lat: 48.5220197, lng: 18.479062 },
    { lat: 48.5220778, lng: 18.4784408 },
    { lat: 48.5222181, lng: 18.4779984 },
    { lat: 48.5223, lng: 18.4778906 },
    { lat: 48.522337, lng: 18.4770508 },
    { lat: 48.5198508, lng: 18.4668847 },
    { lat: 48.5198886, lng: 18.4605844 },
    { lat: 48.5199827, lng: 18.4575814 },
    { lat: 48.5196167, lng: 18.4582241 },
    { lat: 48.5195552, lng: 18.4588453 },
    { lat: 48.5193852, lng: 18.4588315 },
    { lat: 48.5192633, lng: 18.4592332 },
    { lat: 48.518696, lng: 18.4594436 },
    { lat: 48.5183318, lng: 18.4599513 },
    { lat: 48.5180327, lng: 18.4596644 },
    { lat: 48.5178623, lng: 18.459321 },
    { lat: 48.5183162, lng: 18.4585744 },
    { lat: 48.5185302, lng: 18.4580611 },
    { lat: 48.5190654, lng: 18.4573743 },
    { lat: 48.5194178, lng: 18.4571179 },
    { lat: 48.51981, lng: 18.4565227 },
    { lat: 48.5196709, lng: 18.4557841 },
    { lat: 48.5192172, lng: 18.4541769 },
    { lat: 48.5189752, lng: 18.4535785 },
    { lat: 48.5188947, lng: 18.4510896 },
    { lat: 48.5187532, lng: 18.4497812 },
    { lat: 48.5168884, lng: 18.4480766 },
    { lat: 48.5161483, lng: 18.4477506 },
    { lat: 48.5155659, lng: 18.4476453 },
    { lat: 48.5146122, lng: 18.4469033 },
    { lat: 48.5130479, lng: 18.4462011 },
    { lat: 48.5118948, lng: 18.4477997 },
    { lat: 48.5114281, lng: 18.4476471 },
    { lat: 48.5094177, lng: 18.4461825 },
    { lat: 48.5092213, lng: 18.4461994 },
    { lat: 48.5057712, lng: 18.4455252 },
    { lat: 48.5033571, lng: 18.4452835 },
    { lat: 48.4997163, lng: 18.4481603 },
    { lat: 48.4969197, lng: 18.4504738 },
    { lat: 48.4954036, lng: 18.4516323 },
    { lat: 48.4950997, lng: 18.45223 },
    { lat: 48.4944851, lng: 18.4527622 },
    { lat: 48.4937671, lng: 18.453791 },
    { lat: 48.4935809, lng: 18.4541968 },
    { lat: 48.4934609, lng: 18.4546532 },
    { lat: 48.4934949, lng: 18.45482 },
    { lat: 48.4934258, lng: 18.45504 },
    { lat: 48.4935035, lng: 18.4551362 },
    { lat: 48.4929175, lng: 18.4556377 },
    { lat: 48.4923644, lng: 18.456282 },
    { lat: 48.4921895, lng: 18.4562997 },
    { lat: 48.4919436, lng: 18.4564824 },
    { lat: 48.4919202, lng: 18.4567126 },
    { lat: 48.491797, lng: 18.4567064 },
    { lat: 48.4916034, lng: 18.4571263 },
    { lat: 48.4914587, lng: 18.4573628 },
    { lat: 48.4911318, lng: 18.4577289 },
    { lat: 48.4908718, lng: 18.4576997 },
    { lat: 48.4900621, lng: 18.4584188 },
    { lat: 48.4896759, lng: 18.4588588 },
    { lat: 48.4895022, lng: 18.4591403 },
    { lat: 48.4891478, lng: 18.4594038 },
    { lat: 48.4889844, lng: 18.4593643 },
    { lat: 48.4886156, lng: 18.459471 },
    { lat: 48.4877542, lng: 18.4595387 },
    { lat: 48.4871048, lng: 18.4598699 },
    { lat: 48.4867278, lng: 18.4601686 },
    { lat: 48.4864868, lng: 18.4602394 },
    { lat: 48.4860353, lng: 18.4604992 },
    { lat: 48.4853313, lng: 18.4611305 },
    { lat: 48.4851918, lng: 18.4611754 },
    { lat: 48.4846511, lng: 18.4618393 },
    { lat: 48.4845468, lng: 18.4619118 },
    { lat: 48.4844817, lng: 18.4618787 },
    { lat: 48.484321, lng: 18.4620134 },
    { lat: 48.4840071, lng: 18.4625158 },
    { lat: 48.4837526, lng: 18.4626839 },
    { lat: 48.4834792, lng: 18.4629621 },
    { lat: 48.4829371, lng: 18.4639291 },
    { lat: 48.4827854, lng: 18.4643865 },
    { lat: 48.4827653, lng: 18.4653459 },
    { lat: 48.4828017, lng: 18.4659636 },
    { lat: 48.4826914, lng: 18.4667789 },
    { lat: 48.4825499, lng: 18.4668106 },
    { lat: 48.482441, lng: 18.4669828 },
    { lat: 48.4824173, lng: 18.467187 },
    { lat: 48.4824848, lng: 18.4677937 },
    { lat: 48.4824536, lng: 18.4680035 },
    { lat: 48.4819842, lng: 18.4699149 },
    { lat: 48.4819887, lng: 18.4702397 },
    { lat: 48.4825925, lng: 18.4709566 },
    { lat: 48.4829107, lng: 18.4711848 },
  ],
  HostieExt: [
    { lat: 48.4314163, lng: 18.4250461 },
    { lat: 48.4314364, lng: 18.4250635 },
    { lat: 48.4322642, lng: 18.4257647 },
    { lat: 48.4323878, lng: 18.4252298 },
    { lat: 48.4325777, lng: 18.4252566 },
    { lat: 48.4330783, lng: 18.4256354 },
    { lat: 48.433961, lng: 18.4254037 },
    { lat: 48.4342812, lng: 18.4256795 },
    { lat: 48.4345005, lng: 18.4257719 },
    { lat: 48.4349826, lng: 18.4256326 },
    { lat: 48.4352815, lng: 18.4257966 },
    { lat: 48.4356241, lng: 18.4257879 },
    { lat: 48.43619, lng: 18.4262871 },
    { lat: 48.436279, lng: 18.4263263 },
    { lat: 48.4364058, lng: 18.4262517 },
    { lat: 48.4379811, lng: 18.4276141 },
    { lat: 48.4380374, lng: 18.4277548 },
    { lat: 48.4380684, lng: 18.4281453 },
    { lat: 48.4378935, lng: 18.4282043 },
    { lat: 48.4375326, lng: 18.4293625 },
    { lat: 48.4374064, lng: 18.4293628 },
    { lat: 48.4371515, lng: 18.4291842 },
    { lat: 48.436774, lng: 18.4286048 },
    { lat: 48.4362586, lng: 18.4281425 },
    { lat: 48.4360898, lng: 18.4281496 },
    { lat: 48.4354508, lng: 18.4279598 },
    { lat: 48.4349085, lng: 18.4275827 },
    { lat: 48.4348465, lng: 18.4276829 },
    { lat: 48.4345283, lng: 18.4279027 },
    { lat: 48.4342472, lng: 18.4278398 },
    { lat: 48.4341739, lng: 18.4276778 },
    { lat: 48.4341031, lng: 18.4272398 },
    { lat: 48.4339827, lng: 18.4270227 },
    { lat: 48.4336096, lng: 18.4268871 },
    { lat: 48.4332732, lng: 18.4271071 },
    { lat: 48.43322, lng: 18.4270815 },
    { lat: 48.4328612, lng: 18.4274852 },
    { lat: 48.432779, lng: 18.4274689 },
    { lat: 48.4327627, lng: 18.4275403 },
    { lat: 48.4327689, lng: 18.4281125 },
    { lat: 48.4328983, lng: 18.4289255 },
    { lat: 48.4330104, lng: 18.4295936 },
    { lat: 48.4334064, lng: 18.4305204 },
    { lat: 48.4335655, lng: 18.4308054 },
    { lat: 48.4337129, lng: 18.4311132 },
    { lat: 48.4338611, lng: 18.4314122 },
    { lat: 48.4339793, lng: 18.4316525 },
    { lat: 48.4342272, lng: 18.4319457 },
    { lat: 48.4344536, lng: 18.4321642 },
    { lat: 48.4350512, lng: 18.4323624 },
    { lat: 48.4352941, lng: 18.4325095 },
    { lat: 48.4355915, lng: 18.432844 },
    { lat: 48.4357475, lng: 18.4331142 },
    { lat: 48.4359978, lng: 18.433817 },
    { lat: 48.4358959, lng: 18.4339527 },
    { lat: 48.4359939, lng: 18.4344385 },
    { lat: 48.4362222, lng: 18.4362038 },
    { lat: 48.4360915, lng: 18.4372981 },
    { lat: 48.4362606, lng: 18.4384908 },
    { lat: 48.4361852, lng: 18.4388402 },
    { lat: 48.4359771, lng: 18.4393347 },
    { lat: 48.4357147, lng: 18.4407006 },
    { lat: 48.4354578, lng: 18.4410079 },
    { lat: 48.4353593, lng: 18.4412455 },
    { lat: 48.4353128, lng: 18.4415015 },
    { lat: 48.4353414, lng: 18.4421814 },
    { lat: 48.4354952, lng: 18.4424082 },
    { lat: 48.4355969, lng: 18.4427962 },
    { lat: 48.4355433, lng: 18.4439593 },
    { lat: 48.4354533, lng: 18.4443084 },
    { lat: 48.4357477, lng: 18.4453034 },
    { lat: 48.4359738, lng: 18.4456154 },
    { lat: 48.4360304, lng: 18.4458424 },
    { lat: 48.4365498, lng: 18.4463352 },
    { lat: 48.4379603, lng: 18.4471313 },
    { lat: 48.4382635, lng: 18.4475344 },
    { lat: 48.4385849, lng: 18.4484195 },
    { lat: 48.4388059, lng: 18.4483377 },
    { lat: 48.4390613, lng: 18.4487526 },
    { lat: 48.4390899, lng: 18.4490027 },
    { lat: 48.4393978, lng: 18.4493438 },
    { lat: 48.44007, lng: 18.4507405 },
    { lat: 48.4402709, lng: 18.4513938 },
    { lat: 48.4405981, lng: 18.4520232 },
    { lat: 48.4409638, lng: 18.4524526 },
    { lat: 48.4411696, lng: 18.4522985 },
    { lat: 48.4412807, lng: 18.4524512 },
    { lat: 48.4413694, lng: 18.452402 },
    { lat: 48.4414992, lng: 18.4524827 },
    { lat: 48.4416117, lng: 18.4528437 },
    { lat: 48.4418308, lng: 18.453152 },
    { lat: 48.4418487, lng: 18.4532912 },
    { lat: 48.4419326, lng: 18.4533215 },
    { lat: 48.442086, lng: 18.4530947 },
    { lat: 48.4423034, lng: 18.4532934 },
    { lat: 48.4425936, lng: 18.453113 },
    { lat: 48.4428133, lng: 18.4531494 },
    { lat: 48.4435826, lng: 18.4526243 },
    { lat: 48.4438612, lng: 18.4525402 },
    { lat: 48.4441073, lng: 18.4525619 },
    { lat: 48.4450138, lng: 18.4520082 },
    { lat: 48.4453269, lng: 18.4519557 },
    { lat: 48.4457049, lng: 18.4516957 },
    { lat: 48.446268, lng: 18.4514743 },
    { lat: 48.4466153, lng: 18.4517348 },
    { lat: 48.4467033, lng: 18.4520596 },
    { lat: 48.4468215, lng: 18.452076 },
    { lat: 48.4476111, lng: 18.452804 },
    { lat: 48.4479061, lng: 18.4527531 },
    { lat: 48.4485441, lng: 18.4524804 },
    { lat: 48.4501009, lng: 18.4520151 },
    { lat: 48.4503878, lng: 18.4526503 },
    { lat: 48.4505125, lng: 18.4533926 },
    { lat: 48.4517408, lng: 18.4554604 },
    { lat: 48.4524516, lng: 18.4569227 },
    { lat: 48.4532436, lng: 18.4590767 },
    { lat: 48.454097, lng: 18.4597966 },
    { lat: 48.4540217, lng: 18.4619358 },
    { lat: 48.4543746, lng: 18.4630675 },
    { lat: 48.454437, lng: 18.4636435 },
    { lat: 48.4545525, lng: 18.4646319 },
    { lat: 48.4544955, lng: 18.4650904 },
    { lat: 48.4544723, lng: 18.4652383 },
    { lat: 48.4544295, lng: 18.4653994 },
    { lat: 48.4543427, lng: 18.4657258 },
    { lat: 48.4543623, lng: 18.4658322 },
    { lat: 48.4545587, lng: 18.4668978 },
    { lat: 48.4545454, lng: 18.4676113 },
    { lat: 48.4545083, lng: 18.4678069 },
    { lat: 48.454472, lng: 18.4679986 },
    { lat: 48.4550517, lng: 18.4709833 },
    { lat: 48.455252, lng: 18.4714828 },
    { lat: 48.4552449, lng: 18.4720078 },
    { lat: 48.4554914, lng: 18.4733814 },
    { lat: 48.4555199, lng: 18.4736739 },
    { lat: 48.455536, lng: 18.4736563 },
    { lat: 48.4573173, lng: 18.4718279 },
    { lat: 48.4587657, lng: 18.4714794 },
    { lat: 48.4591455, lng: 18.4711667 },
    { lat: 48.4594584, lng: 18.4707682 },
    { lat: 48.4597748, lng: 18.4705739 },
    { lat: 48.4599037, lng: 18.4705585 },
    { lat: 48.4601938, lng: 18.4701976 },
    { lat: 48.4603506, lng: 18.4698485 },
    { lat: 48.4606402, lng: 18.4697642 },
    { lat: 48.4608724, lng: 18.4698627 },
    { lat: 48.4616515, lng: 18.4697509 },
    { lat: 48.4618465, lng: 18.4695462 },
    { lat: 48.4619792, lng: 18.4695612 },
    { lat: 48.4621386, lng: 18.4697879 },
    { lat: 48.4623798, lng: 18.4696827 },
    { lat: 48.4625944, lng: 18.4696864 },
    { lat: 48.4628611, lng: 18.4695343 },
    { lat: 48.4630512, lng: 18.4692193 },
    { lat: 48.4634145, lng: 18.4689157 },
    { lat: 48.4640877, lng: 18.4691108 },
    { lat: 48.4644724, lng: 18.469135 },
    { lat: 48.464605, lng: 18.4692443 },
    { lat: 48.4647357, lng: 18.4694657 },
    { lat: 48.4650416, lng: 18.4693303 },
    { lat: 48.4657635, lng: 18.4694101 },
    { lat: 48.4659413, lng: 18.469359 },
    { lat: 48.4660147, lng: 18.469401 },
    { lat: 48.466289, lng: 18.4693041 },
    { lat: 48.4667382, lng: 18.469428 },
    { lat: 48.4670275, lng: 18.4696629 },
    { lat: 48.4673059, lng: 18.4697809 },
    { lat: 48.4673497, lng: 18.4695508 },
    { lat: 48.4674557, lng: 18.4696135 },
    { lat: 48.4675911, lng: 18.4698976 },
    { lat: 48.4675937, lng: 18.4700896 },
    { lat: 48.4679997, lng: 18.4701262 },
    { lat: 48.4680142, lng: 18.4700416 },
    { lat: 48.4682803, lng: 18.4699592 },
    { lat: 48.4682529, lng: 18.4692815 },
    { lat: 48.4683694, lng: 18.468573 },
    { lat: 48.4686208, lng: 18.4687964 },
    { lat: 48.4692359, lng: 18.4691035 },
    { lat: 48.469618, lng: 18.4694865 },
    { lat: 48.4698168, lng: 18.4695924 },
    { lat: 48.4700754, lng: 18.4685173 },
    { lat: 48.470832, lng: 18.468656 },
    { lat: 48.4711643, lng: 18.468507 },
    { lat: 48.4713091, lng: 18.4687555 },
    { lat: 48.4720879, lng: 18.4691506 },
    { lat: 48.4722022, lng: 18.4689525 },
    { lat: 48.4723533, lng: 18.4689709 },
    { lat: 48.4724671, lng: 18.4693338 },
    { lat: 48.4725684, lng: 18.4693701 },
    { lat: 48.4727294, lng: 18.469244 },
    { lat: 48.4729367, lng: 18.4694864 },
    { lat: 48.4731526, lng: 18.4696371 },
    { lat: 48.4735145, lng: 18.4696327 },
    { lat: 48.4744995, lng: 18.4698786 },
    { lat: 48.4758184, lng: 18.4703703 },
    { lat: 48.4762693, lng: 18.4702028 },
    { lat: 48.4768499, lng: 18.4701137 },
    { lat: 48.4770377, lng: 18.4699785 },
    { lat: 48.4771145, lng: 18.4697769 },
    { lat: 48.4775262, lng: 18.4696734 },
    { lat: 48.4787607, lng: 18.4698244 },
    { lat: 48.4789122, lng: 18.4697503 },
    { lat: 48.4790635, lng: 18.4697932 },
    { lat: 48.4799731, lng: 18.469085 },
    { lat: 48.4803313, lng: 18.4688734 },
    { lat: 48.4804735, lng: 18.4692001 },
    { lat: 48.4806803, lng: 18.4693697 },
    { lat: 48.4811228, lng: 18.4693942 },
    { lat: 48.4815327, lng: 18.4691768 },
    { lat: 48.4818899, lng: 18.4685798 },
    { lat: 48.4818864, lng: 18.4684579 },
    { lat: 48.4820642, lng: 18.4678191 },
    { lat: 48.4820345, lng: 18.4670999 },
    { lat: 48.4821039, lng: 18.4667431 },
    { lat: 48.4820243, lng: 18.4666297 },
    { lat: 48.4820475, lng: 18.4660934 },
    { lat: 48.4820825, lng: 18.4656844 },
    { lat: 48.4821593, lng: 18.4655041 },
    { lat: 48.4821557, lng: 18.4650142 },
    { lat: 48.4822785, lng: 18.4638101 },
    { lat: 48.4821378, lng: 18.46365 },
    { lat: 48.4820373, lng: 18.4634073 },
    { lat: 48.4816661, lng: 18.4628562 },
    { lat: 48.4822684, lng: 18.4625701 },
    { lat: 48.4824624, lng: 18.4625685 },
    { lat: 48.4829271, lng: 18.4623245 },
    { lat: 48.483076, lng: 18.4618347 },
    { lat: 48.483349, lng: 18.4619363 },
    { lat: 48.4836678, lng: 18.4619474 },
    { lat: 48.4838963, lng: 18.4617961 },
    { lat: 48.4840857, lng: 18.4615941 },
    { lat: 48.4841328, lng: 18.4614453 },
    { lat: 48.484108, lng: 18.4612845 },
    { lat: 48.484371, lng: 18.4610221 },
    { lat: 48.4846232, lng: 18.4613197 },
    { lat: 48.4858232, lng: 18.4599917 },
    { lat: 48.4866084, lng: 18.4596242 },
    { lat: 48.4868418, lng: 18.459399 },
    { lat: 48.4871133, lng: 18.459069 },
    { lat: 48.4871247, lng: 18.4589687 },
    { lat: 48.4873657, lng: 18.4584936 },
    { lat: 48.4874402, lng: 18.4581496 },
    { lat: 48.4877393, lng: 18.4575836 },
    { lat: 48.4879589, lng: 18.4570268 },
    { lat: 48.4883902, lng: 18.455713 },
    { lat: 48.4885629, lng: 18.4553698 },
    { lat: 48.4887974, lng: 18.455085 },
    { lat: 48.489423, lng: 18.4535131 },
    { lat: 48.4909301, lng: 18.4469338 },
    { lat: 48.4897327, lng: 18.4461133 },
    { lat: 48.4889776, lng: 18.4425175 },
    { lat: 48.48922, lng: 18.4416435 },
    { lat: 48.4887899, lng: 18.4405845 },
    { lat: 48.488105, lng: 18.4397092 },
    { lat: 48.4882134, lng: 18.4391471 },
    { lat: 48.487226, lng: 18.4381289 },
    { lat: 48.4870445, lng: 18.4376251 },
    { lat: 48.4875558, lng: 18.4372207 },
    { lat: 48.4875228, lng: 18.4370958 },
    { lat: 48.4881363, lng: 18.435836 },
    { lat: 48.4880574, lng: 18.4351276 },
    { lat: 48.4890497, lng: 18.4343499 },
    { lat: 48.4897671, lng: 18.4331783 },
    { lat: 48.4899024, lng: 18.4331153 },
    { lat: 48.4902184, lng: 18.4327913 },
    { lat: 48.4904215, lng: 18.4327123 },
    { lat: 48.4904956, lng: 18.4325821 },
    { lat: 48.490894, lng: 18.4325887 },
    { lat: 48.4912807, lng: 18.4319759 },
    { lat: 48.4913858, lng: 18.4318983 },
    { lat: 48.4913291, lng: 18.4304568 },
    { lat: 48.4910039, lng: 18.4299239 },
    { lat: 48.4909058, lng: 18.4293604 },
    { lat: 48.4914124, lng: 18.428491 },
    { lat: 48.4914952, lng: 18.4282557 },
    { lat: 48.4913739, lng: 18.4278023 },
    { lat: 48.4914982, lng: 18.4274402 },
    { lat: 48.4916178, lng: 18.4274397 },
    { lat: 48.4915969, lng: 18.427065 },
    { lat: 48.4913861, lng: 18.4263415 },
    { lat: 48.4912993, lng: 18.426218 },
    { lat: 48.4912853, lng: 18.426069 },
    { lat: 48.4911784, lng: 18.4259548 },
    { lat: 48.4907951, lng: 18.4250885 },
    { lat: 48.4908183, lng: 18.4248383 },
    { lat: 48.4907553, lng: 18.42474 },
    { lat: 48.4907461, lng: 18.4244552 },
    { lat: 48.4905864, lng: 18.4245415 },
    { lat: 48.4906488, lng: 18.4243256 },
    { lat: 48.4906912, lng: 18.4238532 },
    { lat: 48.4906253, lng: 18.4237446 },
    { lat: 48.4905194, lng: 18.4237053 },
    { lat: 48.4905793, lng: 18.4234459 },
    { lat: 48.4904404, lng: 18.4230399 },
    { lat: 48.49049, lng: 18.4228146 },
    { lat: 48.4904222, lng: 18.4225532 },
    { lat: 48.4903001, lng: 18.4225679 },
    { lat: 48.4901932, lng: 18.422331 },
    { lat: 48.4901131, lng: 18.4213613 },
    { lat: 48.4898119, lng: 18.4208961 },
    { lat: 48.4887944, lng: 18.4201705 },
    { lat: 48.4869453, lng: 18.4196291 },
    { lat: 48.4864996, lng: 18.4195241 },
    { lat: 48.4845416, lng: 18.4189444 },
    { lat: 48.4825652, lng: 18.4178564 },
    { lat: 48.4812142, lng: 18.4171976 },
    { lat: 48.481196, lng: 18.4172575 },
    { lat: 48.4802079, lng: 18.4207309 },
    { lat: 48.47893, lng: 18.4258479 },
    { lat: 48.476432, lng: 18.4238255 },
    { lat: 48.4728536, lng: 18.4196647 },
    { lat: 48.4719923, lng: 18.4174761 },
    { lat: 48.4649536, lng: 18.4187814 },
    { lat: 48.4639753, lng: 18.4184753 },
    { lat: 48.4617811, lng: 18.4168618 },
    { lat: 48.4600191, lng: 18.4168275 },
    { lat: 48.4594957, lng: 18.4166246 },
    { lat: 48.4588016, lng: 18.4165975 },
    { lat: 48.4539567, lng: 18.4168894 },
    { lat: 48.4540367, lng: 18.4185234 },
    { lat: 48.4482873, lng: 18.4181739 },
    { lat: 48.4480165, lng: 18.418637 },
    { lat: 48.4479026, lng: 18.418596 },
    { lat: 48.4476219, lng: 18.418721 },
    { lat: 48.4476351, lng: 18.4188397 },
    { lat: 48.4474923, lng: 18.419168 },
    { lat: 48.4474969, lng: 18.4193522 },
    { lat: 48.4471016, lng: 18.4205581 },
    { lat: 48.4470386, lng: 18.4206396 },
    { lat: 48.4468653, lng: 18.420686 },
    { lat: 48.4468196, lng: 18.4208371 },
    { lat: 48.4465052, lng: 18.4210878 },
    { lat: 48.4464977, lng: 18.4212359 },
    { lat: 48.4462975, lng: 18.4213081 },
    { lat: 48.4462336, lng: 18.4214551 },
    { lat: 48.4460236, lng: 18.4216525 },
    { lat: 48.445913, lng: 18.4218775 },
    { lat: 48.445922, lng: 18.4222214 },
    { lat: 48.4457624, lng: 18.4223731 },
    { lat: 48.4455719, lng: 18.4222336 },
    { lat: 48.4453902, lng: 18.4221891 },
    { lat: 48.4448651, lng: 18.421798 },
    { lat: 48.4444036, lng: 18.4215955 },
    { lat: 48.4440679, lng: 18.4212982 },
    { lat: 48.4434019, lng: 18.4212938 },
    { lat: 48.4432145, lng: 18.4214336 },
    { lat: 48.4431484, lng: 18.4213176 },
    { lat: 48.4430002, lng: 18.4213722 },
    { lat: 48.4429096, lng: 18.421245 },
    { lat: 48.4426574, lng: 18.4212126 },
    { lat: 48.4425259, lng: 18.4212835 },
    { lat: 48.4424174, lng: 18.42146 },
    { lat: 48.4417519, lng: 18.4215027 },
    { lat: 48.4415368, lng: 18.4214301 },
    { lat: 48.4411561, lng: 18.4215602 },
    { lat: 48.4410174, lng: 18.4217433 },
    { lat: 48.440739, lng: 18.4218093 },
    { lat: 48.4406238, lng: 18.4219468 },
    { lat: 48.4404297, lng: 18.4217638 },
    { lat: 48.4403616, lng: 18.4218186 },
    { lat: 48.4403235, lng: 18.4219829 },
    { lat: 48.4399233, lng: 18.4221318 },
    { lat: 48.4397664, lng: 18.4220696 },
    { lat: 48.4395932, lng: 18.4221984 },
    { lat: 48.4392702, lng: 18.4222132 },
    { lat: 48.4391657, lng: 18.4221638 },
    { lat: 48.4388441, lng: 18.4223007 },
    { lat: 48.4384455, lng: 18.4221741 },
    { lat: 48.4375028, lng: 18.422222 },
    { lat: 48.4362372, lng: 18.4220844 },
    { lat: 48.4356602, lng: 18.4217721 },
    { lat: 48.4355564, lng: 18.4217926 },
    { lat: 48.4355031, lng: 18.4219479 },
    { lat: 48.4353742, lng: 18.4220227 },
    { lat: 48.4350042, lng: 18.4219632 },
    { lat: 48.4349447, lng: 18.4220318 },
    { lat: 48.4349412, lng: 18.4222696 },
    { lat: 48.4347935, lng: 18.4223934 },
    { lat: 48.4344919, lng: 18.4224069 },
    { lat: 48.4342188, lng: 18.4225654 },
    { lat: 48.4340808, lng: 18.4226822 },
    { lat: 48.4340482, lng: 18.4230459 },
    { lat: 48.4337509, lng: 18.4228944 },
    { lat: 48.4336554, lng: 18.4230226 },
    { lat: 48.4334973, lng: 18.4230184 },
    { lat: 48.4332946, lng: 18.423368 },
    { lat: 48.4330232, lng: 18.4235205 },
    { lat: 48.432931, lng: 18.4236472 },
    { lat: 48.4328147, lng: 18.4236438 },
    { lat: 48.4326386, lng: 18.4238845 },
    { lat: 48.4325667, lng: 18.4238669 },
    { lat: 48.4324844, lng: 18.4239728 },
    { lat: 48.4324219, lng: 18.423861 },
    { lat: 48.4323325, lng: 18.4238766 },
    { lat: 48.4322771, lng: 18.4240359 },
    { lat: 48.4322827, lng: 18.424297 },
    { lat: 48.4319772, lng: 18.4242201 },
    { lat: 48.4319035, lng: 18.4243779 },
    { lat: 48.4317197, lng: 18.424562 },
    { lat: 48.4312499, lng: 18.4247726 },
    { lat: 48.4312469, lng: 18.4249812 },
    { lat: 48.4314163, lng: 18.4250461 },
  ],
  ZlatéMoravce: [
    { lat: 48.3775258, lng: 18.3528161 },
    { lat: 48.375274, lng: 18.3551726 },
    { lat: 48.3747327, lng: 18.3557355 },
    { lat: 48.3739494, lng: 18.35655 },
    { lat: 48.3732016, lng: 18.3567914 },
    { lat: 48.3726672, lng: 18.3568197 },
    { lat: 48.371816, lng: 18.356492 },
    { lat: 48.3715695, lng: 18.3564595 },
    { lat: 48.3714061, lng: 18.3563476 },
    { lat: 48.370204, lng: 18.3572948 },
    { lat: 48.3694153, lng: 18.3580924 },
    { lat: 48.3689437, lng: 18.3591907 },
    { lat: 48.3688183, lng: 18.3592889 },
    { lat: 48.3686774, lng: 18.3591887 },
    { lat: 48.3686129, lng: 18.3592477 },
    { lat: 48.36859, lng: 18.3591601 },
    { lat: 48.3684678, lng: 18.3591157 },
    { lat: 48.3684249, lng: 18.3590339 },
    { lat: 48.3683703, lng: 18.3590674 },
    { lat: 48.3682742, lng: 18.3593028 },
    { lat: 48.3681184, lng: 18.3591406 },
    { lat: 48.3678961, lng: 18.3593985 },
    { lat: 48.367852, lng: 18.3595393 },
    { lat: 48.3678715, lng: 18.3596617 },
    { lat: 48.3677042, lng: 18.3596763 },
    { lat: 48.3676443, lng: 18.3598546 },
    { lat: 48.3676563, lng: 18.3599669 },
    { lat: 48.3674453, lng: 18.3600271 },
    { lat: 48.3674267, lng: 18.3600951 },
    { lat: 48.3672961, lng: 18.3600812 },
    { lat: 48.3672513, lng: 18.3602257 },
    { lat: 48.3671241, lng: 18.3603508 },
    { lat: 48.3670542, lng: 18.3603153 },
    { lat: 48.3669769, lng: 18.3604238 },
    { lat: 48.3669795, lng: 18.3605292 },
    { lat: 48.3668977, lng: 18.3606783 },
    { lat: 48.3668023, lng: 18.3606057 },
    { lat: 48.3665658, lng: 18.360793 },
    { lat: 48.3664993, lng: 18.3607745 },
    { lat: 48.3664778, lng: 18.3608552 },
    { lat: 48.3663588, lng: 18.3609247 },
    { lat: 48.3663086, lng: 18.361056 },
    { lat: 48.3661127, lng: 18.3610213 },
    { lat: 48.3659928, lng: 18.3611366 },
    { lat: 48.3659687, lng: 18.3612496 },
    { lat: 48.3658724, lng: 18.361363 },
    { lat: 48.3658973, lng: 18.361682 },
    { lat: 48.3657765, lng: 18.3617027 },
    { lat: 48.3655956, lng: 18.3618733 },
    { lat: 48.3656334, lng: 18.3621034 },
    { lat: 48.36551, lng: 18.3621748 },
    { lat: 48.365578, lng: 18.3624624 },
    { lat: 48.3655337, lng: 18.3627565 },
    { lat: 48.3655424, lng: 18.363129 },
    { lat: 48.3653789, lng: 18.3633115 },
    { lat: 48.364904, lng: 18.3633914 },
    { lat: 48.3647544, lng: 18.3632203 },
    { lat: 48.3645842, lng: 18.3634431 },
    { lat: 48.3645084, lng: 18.3634623 },
    { lat: 48.3643093, lng: 18.3634479 },
    { lat: 48.3638851, lng: 18.3635512 },
    { lat: 48.3638356, lng: 18.3634748 },
    { lat: 48.3636965, lng: 18.3635493 },
    { lat: 48.3635157, lng: 18.3634829 },
    { lat: 48.3627321, lng: 18.3635896 },
    { lat: 48.3624995, lng: 18.3633098 },
    { lat: 48.3622731, lng: 18.3631743 },
    { lat: 48.3621846, lng: 18.3630253 },
    { lat: 48.362038, lng: 18.3631464 },
    { lat: 48.3620736, lng: 18.3632203 },
    { lat: 48.3620434, lng: 18.3632635 },
    { lat: 48.3617708, lng: 18.3632294 },
    { lat: 48.3617225, lng: 18.3633536 },
    { lat: 48.3617477, lng: 18.363763 },
    { lat: 48.3614177, lng: 18.3639093 },
    { lat: 48.3613817, lng: 18.3638536 },
    { lat: 48.3614428, lng: 18.3637351 },
    { lat: 48.3613999, lng: 18.3636583 },
    { lat: 48.3613188, lng: 18.3636356 },
    { lat: 48.3613165, lng: 18.3637593 },
    { lat: 48.3610925, lng: 18.3638244 },
    { lat: 48.3610256, lng: 18.36372 },
    { lat: 48.3610474, lng: 18.3636282 },
    { lat: 48.3609909, lng: 18.3635865 },
    { lat: 48.3609265, lng: 18.3636053 },
    { lat: 48.3609143, lng: 18.3637668 },
    { lat: 48.360853, lng: 18.3637619 },
    { lat: 48.3608526, lng: 18.3638545 },
    { lat: 48.360745, lng: 18.3639047 },
    { lat: 48.3606992, lng: 18.3637552 },
    { lat: 48.3605843, lng: 18.3637169 },
    { lat: 48.3605525, lng: 18.3635723 },
    { lat: 48.3606561, lng: 18.3635521 },
    { lat: 48.3606855, lng: 18.3634532 },
    { lat: 48.3604823, lng: 18.3634301 },
    { lat: 48.3603504, lng: 18.3635892 },
    { lat: 48.3601819, lng: 18.3632647 },
    { lat: 48.3600695, lng: 18.3632506 },
    { lat: 48.3599513, lng: 18.3634345 },
    { lat: 48.359938, lng: 18.3635596 },
    { lat: 48.3599918, lng: 18.3636326 },
    { lat: 48.3599296, lng: 18.3637913 },
    { lat: 48.3599623, lng: 18.3638517 },
    { lat: 48.359888, lng: 18.3639545 },
    { lat: 48.3597932, lng: 18.363892 },
    { lat: 48.3597001, lng: 18.3637215 },
    { lat: 48.3594752, lng: 18.3638746 },
    { lat: 48.3595164, lng: 18.3639467 },
    { lat: 48.3594956, lng: 18.3640182 },
    { lat: 48.3596024, lng: 18.3641891 },
    { lat: 48.3595432, lng: 18.3643006 },
    { lat: 48.35943, lng: 18.364355 },
    { lat: 48.3594244, lng: 18.3644366 },
    { lat: 48.3592405, lng: 18.3643369 },
    { lat: 48.3591847, lng: 18.364488 },
    { lat: 48.3592211, lng: 18.364592 },
    { lat: 48.3591253, lng: 18.364591 },
    { lat: 48.3590454, lng: 18.3647544 },
    { lat: 48.3589468, lng: 18.3646705 },
    { lat: 48.3588191, lng: 18.3646654 },
    { lat: 48.3587411, lng: 18.3648201 },
    { lat: 48.3588064, lng: 18.364981 },
    { lat: 48.3587104, lng: 18.3650986 },
    { lat: 48.3585338, lng: 18.3649674 },
    { lat: 48.3583326, lng: 18.3650803 },
    { lat: 48.358178, lng: 18.3650808 },
    { lat: 48.3582244, lng: 18.3653416 },
    { lat: 48.3580683, lng: 18.3654172 },
    { lat: 48.3579925, lng: 18.3655304 },
    { lat: 48.357995, lng: 18.3656344 },
    { lat: 48.3578433, lng: 18.365648 },
    { lat: 48.3579254, lng: 18.3659007 },
    { lat: 48.3581437, lng: 18.3658716 },
    { lat: 48.3582487, lng: 18.3660111 },
    { lat: 48.3572707, lng: 18.3666746 },
    { lat: 48.3571656, lng: 18.3669736 },
    { lat: 48.3571009, lng: 18.366946 },
    { lat: 48.3570862, lng: 18.3668233 },
    { lat: 48.3569134, lng: 18.3669205 },
    { lat: 48.3569223, lng: 18.367057 },
    { lat: 48.3570857, lng: 18.3671749 },
    { lat: 48.3570872, lng: 18.3672595 },
    { lat: 48.3569791, lng: 18.3674049 },
    { lat: 48.3571018, lng: 18.3675386 },
    { lat: 48.3571213, lng: 18.3678462 },
    { lat: 48.3563795, lng: 18.3677553 },
    { lat: 48.3563199, lng: 18.3678597 },
    { lat: 48.3563097, lng: 18.3680192 },
    { lat: 48.3565637, lng: 18.3680791 },
    { lat: 48.3567445, lng: 18.369666 },
    { lat: 48.3563291, lng: 18.3721799 },
    { lat: 48.3562457, lng: 18.3734994 },
    { lat: 48.356268, lng: 18.3740964 },
    { lat: 48.3561553, lng: 18.3740976 },
    { lat: 48.3560839, lng: 18.3740141 },
    { lat: 48.3558938, lng: 18.3741446 },
    { lat: 48.3558115, lng: 18.3741058 },
    { lat: 48.3557051, lng: 18.3737986 },
    { lat: 48.3554724, lng: 18.3740115 },
    { lat: 48.3552156, lng: 18.3737589 },
    { lat: 48.3550925, lng: 18.3742804 },
    { lat: 48.3552126, lng: 18.3743485 },
    { lat: 48.3552315, lng: 18.374444 },
    { lat: 48.3553301, lng: 18.3753126 },
    { lat: 48.3552851, lng: 18.3756121 },
    { lat: 48.3553407, lng: 18.3757015 },
    { lat: 48.355652, lng: 18.3757656 },
    { lat: 48.3559332, lng: 18.3758235 },
    { lat: 48.3548227, lng: 18.3794212 },
    { lat: 48.3544041, lng: 18.3810222 },
    { lat: 48.3540691, lng: 18.3824236 },
    { lat: 48.353476, lng: 18.3856954 },
    { lat: 48.3529765, lng: 18.3879674 },
    { lat: 48.3523864, lng: 18.3878623 },
    { lat: 48.3519904, lng: 18.3877025 },
    { lat: 48.3517318, lng: 18.3886411 },
    { lat: 48.351644, lng: 18.3885682 },
    { lat: 48.3515506, lng: 18.3890127 },
    { lat: 48.3505499, lng: 18.3894541 },
    { lat: 48.3500232, lng: 18.3898169 },
    { lat: 48.3493589, lng: 18.3899397 },
    { lat: 48.3483021, lng: 18.3900267 },
    { lat: 48.347219, lng: 18.3899942 },
    { lat: 48.347071, lng: 18.3899524 },
    { lat: 48.3465703, lng: 18.3944978 },
    { lat: 48.3470043, lng: 18.3946518 },
    { lat: 48.3470131, lng: 18.3955108 },
    { lat: 48.3471954, lng: 18.3957477 },
    { lat: 48.3481501, lng: 18.3953698 },
    { lat: 48.3483368, lng: 18.3952962 },
    { lat: 48.3484675, lng: 18.3953938 },
    { lat: 48.3488432, lng: 18.3953349 },
    { lat: 48.3488581, lng: 18.3954192 },
    { lat: 48.3494305, lng: 18.3954451 },
    { lat: 48.3495996, lng: 18.3954035 },
    { lat: 48.3498277, lng: 18.3950671 },
    { lat: 48.3509894, lng: 18.3952426 },
    { lat: 48.351191, lng: 18.3952174 },
    { lat: 48.351425, lng: 18.3958259 },
    { lat: 48.3517357, lng: 18.3968943 },
    { lat: 48.3520052, lng: 18.3986024 },
    { lat: 48.3519689, lng: 18.3994412 },
    { lat: 48.3520782, lng: 18.4007646 },
    { lat: 48.3520824, lng: 18.4010835 },
    { lat: 48.3520901, lng: 18.4016789 },
    { lat: 48.3519101, lng: 18.4033374 },
    { lat: 48.3565293, lng: 18.4050285 },
    { lat: 48.3564419, lng: 18.4053627 },
    { lat: 48.3562865, lng: 18.4053863 },
    { lat: 48.356557, lng: 18.4060289 },
    { lat: 48.356682, lng: 18.4061035 },
    { lat: 48.3566355, lng: 18.4066223 },
    { lat: 48.3581961, lng: 18.4066278 },
    { lat: 48.3582711, lng: 18.4079432 },
    { lat: 48.358247, lng: 18.4091608 },
    { lat: 48.3581773, lng: 18.4099923 },
    { lat: 48.3581966, lng: 18.410557 },
    { lat: 48.3584963, lng: 18.4123006 },
    { lat: 48.3579499, lng: 18.4127169 },
    { lat: 48.3574073, lng: 18.4133036 },
    { lat: 48.3568109, lng: 18.4142145 },
    { lat: 48.3557421, lng: 18.4152563 },
    { lat: 48.354646, lng: 18.4154446 },
    { lat: 48.3546486, lng: 18.4180108 },
    { lat: 48.3547422, lng: 18.4208891 },
    { lat: 48.3552886, lng: 18.4223862 },
    { lat: 48.3556036, lng: 18.4229368 },
    { lat: 48.3557236, lng: 18.4232859 },
    { lat: 48.3557891, lng: 18.4238729 },
    { lat: 48.3560694, lng: 18.4243441 },
    { lat: 48.3562884, lng: 18.4248326 },
    { lat: 48.3564355, lng: 18.4249985 },
    { lat: 48.3568469, lng: 18.42576 },
    { lat: 48.3571344, lng: 18.4264616 },
    { lat: 48.3572004, lng: 18.4272188 },
    { lat: 48.3574048, lng: 18.427767 },
    { lat: 48.3575141, lng: 18.4279678 },
    { lat: 48.3577885, lng: 18.4280503 },
    { lat: 48.3581067, lng: 18.4285785 },
    { lat: 48.3584643, lng: 18.4290007 },
    { lat: 48.3586374, lng: 18.4290091 },
    { lat: 48.3587329, lng: 18.4291457 },
    { lat: 48.3587293, lng: 18.429482 },
    { lat: 48.3587893, lng: 18.429544 },
    { lat: 48.3588763, lng: 18.4294901 },
    { lat: 48.3590437, lng: 18.4295751 },
    { lat: 48.3591111, lng: 18.4298547 },
    { lat: 48.3592004, lng: 18.4298998 },
    { lat: 48.3592625, lng: 18.4300584 },
    { lat: 48.3590648, lng: 18.4311233 },
    { lat: 48.3590651, lng: 18.4314131 },
    { lat: 48.3591426, lng: 18.4316704 },
    { lat: 48.3590877, lng: 18.4318586 },
    { lat: 48.3591034, lng: 18.4319379 },
    { lat: 48.3592949, lng: 18.4321536 },
    { lat: 48.359355, lng: 18.4324213 },
    { lat: 48.3593459, lng: 18.4327647 },
    { lat: 48.3595545, lng: 18.4329836 },
    { lat: 48.3593642, lng: 18.4334137 },
    { lat: 48.3590736, lng: 18.4363302 },
    { lat: 48.3588704, lng: 18.4377413 },
    { lat: 48.3588563, lng: 18.4385816 },
    { lat: 48.358746, lng: 18.439981 },
    { lat: 48.3588568, lng: 18.4406112 },
    { lat: 48.3591948, lng: 18.4411097 },
    { lat: 48.359326, lng: 18.4414398 },
    { lat: 48.3592607, lng: 18.4415399 },
    { lat: 48.3589051, lng: 18.4417211 },
    { lat: 48.3588829, lng: 18.4417856 },
    { lat: 48.3588511, lng: 18.443157 },
    { lat: 48.3590108, lng: 18.4449684 },
    { lat: 48.358946, lng: 18.4451851 },
    { lat: 48.3586965, lng: 18.4455435 },
    { lat: 48.3586999, lng: 18.446698 },
    { lat: 48.3587496, lng: 18.4472675 },
    { lat: 48.3589595, lng: 18.448179 },
    { lat: 48.3591312, lng: 18.4486473 },
    { lat: 48.3597372, lng: 18.4498876 },
    { lat: 48.3597362, lng: 18.4502943 },
    { lat: 48.3600607, lng: 18.4523493 },
    { lat: 48.3604481, lng: 18.4542049 },
    { lat: 48.3604542, lng: 18.4545762 },
    { lat: 48.3611168, lng: 18.4564282 },
    { lat: 48.3612545, lng: 18.4567532 },
    { lat: 48.3613514, lng: 18.4568337 },
    { lat: 48.362972, lng: 18.4606928 },
    { lat: 48.3648281, lng: 18.4626297 },
    { lat: 48.3660721, lng: 18.4642857 },
    { lat: 48.3670678, lng: 18.4653137 },
    { lat: 48.3673769, lng: 18.4651721 },
    { lat: 48.3674501, lng: 18.46523 },
    { lat: 48.3676418, lng: 18.4652096 },
    { lat: 48.3677758, lng: 18.4650811 },
    { lat: 48.3681097, lng: 18.4649591 },
    { lat: 48.3684044, lng: 18.4650684 },
    { lat: 48.3686661, lng: 18.46523 },
    { lat: 48.3687477, lng: 18.4653578 },
    { lat: 48.3692181, lng: 18.4654593 },
    { lat: 48.3694911, lng: 18.4661067 },
    { lat: 48.369675, lng: 18.4658038 },
    { lat: 48.3698695, lng: 18.4660533 },
    { lat: 48.3701835, lng: 18.4661446 },
    { lat: 48.3703808, lng: 18.4660794 },
    { lat: 48.3704636, lng: 18.4661698 },
    { lat: 48.3705505, lng: 18.4660916 },
    { lat: 48.3710396, lng: 18.4663855 },
    { lat: 48.3712246, lng: 18.4664248 },
    { lat: 48.3714244, lng: 18.4669373 },
    { lat: 48.3716129, lng: 18.4668151 },
    { lat: 48.3720062, lng: 18.4671331 },
    { lat: 48.3721451, lng: 18.4671356 },
    { lat: 48.3729077, lng: 18.467522 },
    { lat: 48.372988, lng: 18.4674832 },
    { lat: 48.3730963, lng: 18.4675857 },
    { lat: 48.3737055, lng: 18.4677688 },
    { lat: 48.3742972, lng: 18.4683271 },
    { lat: 48.3747133, lng: 18.4685067 },
    { lat: 48.3748545, lng: 18.4686526 },
    { lat: 48.3747617, lng: 18.4683644 },
    { lat: 48.3744459, lng: 18.4677943 },
    { lat: 48.374319, lng: 18.4674217 },
    { lat: 48.3742617, lng: 18.4669559 },
    { lat: 48.3741514, lng: 18.4666979 },
    { lat: 48.3736553, lng: 18.4665201 },
    { lat: 48.3734665, lng: 18.4663695 },
    { lat: 48.3735224, lng: 18.4653676 },
    { lat: 48.3736393, lng: 18.4648502 },
    { lat: 48.3735328, lng: 18.4641333 },
    { lat: 48.3730526, lng: 18.4594939 },
    { lat: 48.3726185, lng: 18.4583196 },
    { lat: 48.3726513, lng: 18.4581016 },
    { lat: 48.3726193, lng: 18.4573818 },
    { lat: 48.372526, lng: 18.4568605 },
    { lat: 48.3725138, lng: 18.4561578 },
    { lat: 48.3724242, lng: 18.4554782 },
    { lat: 48.3724832, lng: 18.4552796 },
    { lat: 48.3724224, lng: 18.4546968 },
    { lat: 48.3724733, lng: 18.4543931 },
    { lat: 48.3724285, lng: 18.4543181 },
    { lat: 48.3724582, lng: 18.4538375 },
    { lat: 48.3723967, lng: 18.4536246 },
    { lat: 48.3724662, lng: 18.4534157 },
    { lat: 48.372288, lng: 18.4530088 },
    { lat: 48.3722112, lng: 18.4526668 },
    { lat: 48.3716156, lng: 18.4516533 },
    { lat: 48.3715675, lng: 18.4515199 },
    { lat: 48.3715782, lng: 18.4512241 },
    { lat: 48.3714445, lng: 18.4511326 },
    { lat: 48.3712382, lng: 18.4507902 },
    { lat: 48.3712017, lng: 18.4504187 },
    { lat: 48.3710176, lng: 18.450093 },
    { lat: 48.3709253, lng: 18.4498119 },
    { lat: 48.3709822, lng: 18.4496192 },
    { lat: 48.3708142, lng: 18.4494593 },
    { lat: 48.3708257, lng: 18.4493224 },
    { lat: 48.3709677, lng: 18.4493386 },
    { lat: 48.3708133, lng: 18.4487939 },
    { lat: 48.3726992, lng: 18.4477354 },
    { lat: 48.3741834, lng: 18.4464105 },
    { lat: 48.3749996, lng: 18.4458148 },
    { lat: 48.3759013, lng: 18.4453745 },
    { lat: 48.3770668, lng: 18.4454396 },
    { lat: 48.3774707, lng: 18.4450351 },
    { lat: 48.3777113, lng: 18.4440493 },
    { lat: 48.3778699, lng: 18.4412888 },
    { lat: 48.3779916, lng: 18.4401526 },
    { lat: 48.3771231, lng: 18.4400953 },
    { lat: 48.3751984, lng: 18.4411462 },
    { lat: 48.3742039, lng: 18.4364026 },
    { lat: 48.3725107, lng: 18.4321637 },
    { lat: 48.3724703, lng: 18.432068 },
    { lat: 48.3724075, lng: 18.4320567 },
    { lat: 48.3727008, lng: 18.4299353 },
    { lat: 48.3730462, lng: 18.4264465 },
    { lat: 48.3731634, lng: 18.4259209 },
    { lat: 48.3737191, lng: 18.4234292 },
    { lat: 48.373936, lng: 18.4206732 },
    { lat: 48.3740837, lng: 18.4197856 },
    { lat: 48.3743588, lng: 18.4187947 },
    { lat: 48.3744374, lng: 18.4183618 },
    { lat: 48.3745045, lng: 18.4168843 },
    { lat: 48.3745617, lng: 18.4168959 },
    { lat: 48.3754893, lng: 18.4168999 },
    { lat: 48.3778971, lng: 18.4167253 },
    { lat: 48.3811415, lng: 18.4167422 },
    { lat: 48.3816408, lng: 18.4168947 },
    { lat: 48.382272, lng: 18.4171866 },
    { lat: 48.3826914, lng: 18.4172615 },
    { lat: 48.3840009, lng: 18.4173184 },
    { lat: 48.3872992, lng: 18.4168867 },
    { lat: 48.3875333, lng: 18.4169562 },
    { lat: 48.3878065, lng: 18.4172826 },
    { lat: 48.3878317, lng: 18.416505 },
    { lat: 48.3881232, lng: 18.4157662 },
    { lat: 48.3886603, lng: 18.4148795 },
    { lat: 48.389697, lng: 18.4133687 },
    { lat: 48.3905271, lng: 18.4119736 },
    { lat: 48.3906874, lng: 18.4105637 },
    { lat: 48.3910005, lng: 18.4095917 },
    { lat: 48.3911353, lng: 18.4079309 },
    { lat: 48.3911339, lng: 18.407421 },
    { lat: 48.3915282, lng: 18.4061263 },
    { lat: 48.3917459, lng: 18.4051856 },
    { lat: 48.3918141, lng: 18.4048908 },
    { lat: 48.3919307, lng: 18.4043868 },
    { lat: 48.3920009, lng: 18.4045349 },
    { lat: 48.3920927, lng: 18.4045403 },
    { lat: 48.3921998, lng: 18.4045395 },
    { lat: 48.392274, lng: 18.4044552 },
    { lat: 48.3926365, lng: 18.4045028 },
    { lat: 48.3928807, lng: 18.4044314 },
    { lat: 48.3933809, lng: 18.4047803 },
    { lat: 48.3937277, lng: 18.4052096 },
    { lat: 48.3938817, lng: 18.4050599 },
    { lat: 48.3941359, lng: 18.405305 },
    { lat: 48.3942789, lng: 18.4050814 },
    { lat: 48.3943317, lng: 18.4050797 },
    { lat: 48.3945998, lng: 18.4053706 },
    { lat: 48.3945284, lng: 18.4056072 },
    { lat: 48.394548, lng: 18.4057751 },
    { lat: 48.3946684, lng: 18.4060531 },
    { lat: 48.3958711, lng: 18.4064942 },
    { lat: 48.3962789, lng: 18.4067689 },
    { lat: 48.3966632, lng: 18.4075042 },
    { lat: 48.3967743, lng: 18.4078533 },
    { lat: 48.3970022, lng: 18.4082807 },
    { lat: 48.3971252, lng: 18.4087025 },
    { lat: 48.3975085, lng: 18.4093413 },
    { lat: 48.3986364, lng: 18.4072612 },
    { lat: 48.3991557, lng: 18.4063034 },
    { lat: 48.3990075, lng: 18.4061141 },
    { lat: 48.3983103, lng: 18.4051684 },
    { lat: 48.3986186, lng: 18.404459 },
    { lat: 48.399056, lng: 18.403216 },
    { lat: 48.3991354, lng: 18.4028775 },
    { lat: 48.3992704, lng: 18.4022966 },
    { lat: 48.3994649, lng: 18.4012049 },
    { lat: 48.399643, lng: 18.4001894 },
    { lat: 48.3997452, lng: 18.3998686 },
    { lat: 48.401111, lng: 18.3963507 },
    { lat: 48.4011282, lng: 18.3962353 },
    { lat: 48.4013245, lng: 18.3958539 },
    { lat: 48.4020697, lng: 18.3936151 },
    { lat: 48.4023917, lng: 18.3929427 },
    { lat: 48.4026961, lng: 18.3920946 },
    { lat: 48.4032316, lng: 18.3901017 },
    { lat: 48.403543, lng: 18.3885369 },
    { lat: 48.4044217, lng: 18.3861076 },
    { lat: 48.4045481, lng: 18.3857657 },
    { lat: 48.4048092, lng: 18.3853498 },
    { lat: 48.4050666, lng: 18.3846727 },
    { lat: 48.4052565, lng: 18.3844015 },
    { lat: 48.4052056, lng: 18.3843575 },
    { lat: 48.4063465, lng: 18.3819165 },
    { lat: 48.4059571, lng: 18.3810289 },
    { lat: 48.4062926, lng: 18.3806387 },
    { lat: 48.4069469, lng: 18.3799942 },
    { lat: 48.4099702, lng: 18.3775259 },
    { lat: 48.4112915, lng: 18.375585 },
    { lat: 48.4112664, lng: 18.3753794 },
    { lat: 48.4112598, lng: 18.3752996 },
    { lat: 48.4112092, lng: 18.3746444 },
    { lat: 48.4116179, lng: 18.3744671 },
    { lat: 48.4119363, lng: 18.3738535 },
    { lat: 48.4114247, lng: 18.3695382 },
    { lat: 48.4111483, lng: 18.3678599 },
    { lat: 48.4108427, lng: 18.3678287 },
    { lat: 48.4096423, lng: 18.3678011 },
    { lat: 48.4096151, lng: 18.3675791 },
    { lat: 48.4087969, lng: 18.3676966 },
    { lat: 48.4086478, lng: 18.3669735 },
    { lat: 48.4064231, lng: 18.3679542 },
    { lat: 48.4060487, lng: 18.3672182 },
    { lat: 48.4053257, lng: 18.3662037 },
    { lat: 48.4051949, lng: 18.366143 },
    { lat: 48.4049535, lng: 18.3660845 },
    { lat: 48.4041581, lng: 18.366103 },
    { lat: 48.4007286, lng: 18.3670303 },
    { lat: 48.3989625, lng: 18.3676029 },
    { lat: 48.3983304, lng: 18.3676957 },
    { lat: 48.3978161, lng: 18.3676401 },
    { lat: 48.39641, lng: 18.3672157 },
    { lat: 48.391094, lng: 18.3659305 },
    { lat: 48.3899945, lng: 18.3656647 },
    { lat: 48.3900114, lng: 18.3652046 },
    { lat: 48.3882906, lng: 18.3658235 },
    { lat: 48.3882354, lng: 18.3654025 },
    { lat: 48.3855755, lng: 18.365311 },
    { lat: 48.3852391, lng: 18.3653495 },
    { lat: 48.3844865, lng: 18.3612702 },
    { lat: 48.3804023, lng: 18.3627081 },
    { lat: 48.3802982, lng: 18.3620828 },
    { lat: 48.380045, lng: 18.3613769 },
    { lat: 48.3798421, lng: 18.36138 },
    { lat: 48.3791221, lng: 18.3586465 },
    { lat: 48.3791105, lng: 18.3585942 },
    { lat: 48.3782829, lng: 18.3541725 },
    { lat: 48.3780773, lng: 18.3532707 },
    { lat: 48.3776992, lng: 18.3530484 },
    { lat: 48.3775675, lng: 18.3529357 },
    { lat: 48.3775258, lng: 18.3528161 },
  ],
  MaléVozokany: [
    { lat: 48.292235, lng: 18.421134 },
    { lat: 48.2931753, lng: 18.4202216 },
    { lat: 48.2956326, lng: 18.4191771 },
    { lat: 48.2956686, lng: 18.419877 },
    { lat: 48.2956482, lng: 18.4202395 },
    { lat: 48.2955421, lng: 18.4207414 },
    { lat: 48.2955794, lng: 18.4207626 },
    { lat: 48.2967643, lng: 18.420066 },
    { lat: 48.2981398, lng: 18.419506 },
    { lat: 48.2994575, lng: 18.4196242 },
    { lat: 48.3004667, lng: 18.4190758 },
    { lat: 48.3011519, lng: 18.4185165 },
    { lat: 48.3011857, lng: 18.4183766 },
    { lat: 48.305085, lng: 18.4160071 },
    { lat: 48.3057589, lng: 18.4154703 },
    { lat: 48.3063268, lng: 18.4169676 },
    { lat: 48.3070096, lng: 18.4164864 },
    { lat: 48.30719, lng: 18.4168071 },
    { lat: 48.3079484, lng: 18.4162299 },
    { lat: 48.3091143, lng: 18.4192569 },
    { lat: 48.309497, lng: 18.4194203 },
    { lat: 48.3098878, lng: 18.4204969 },
    { lat: 48.3104021, lng: 18.4222243 },
    { lat: 48.3105192, lng: 18.4221415 },
    { lat: 48.3105956, lng: 18.422336 },
    { lat: 48.3110066, lng: 18.4235715 },
    { lat: 48.3116111, lng: 18.4250785 },
    { lat: 48.311918, lng: 18.4261296 },
    { lat: 48.3121832, lng: 18.4259481 },
    { lat: 48.312423, lng: 18.4267263 },
    { lat: 48.3128928, lng: 18.428547 },
    { lat: 48.3132403, lng: 18.429536 },
    { lat: 48.3132563, lng: 18.4300465 },
    { lat: 48.3132216, lng: 18.4302121 },
    { lat: 48.3126738, lng: 18.4322352 },
    { lat: 48.3126613, lng: 18.4322729 },
    { lat: 48.3135243, lng: 18.43249 },
    { lat: 48.313646, lng: 18.4332804 },
    { lat: 48.3149385, lng: 18.4330336 },
    { lat: 48.3149676, lng: 18.4332702 },
    { lat: 48.315678, lng: 18.4330792 },
    { lat: 48.3159838, lng: 18.4328409 },
    { lat: 48.3169581, lng: 18.4338395 },
    { lat: 48.3173033, lng: 18.4341236 },
    { lat: 48.3176888, lng: 18.4346639 },
    { lat: 48.3180124, lng: 18.4352438 },
    { lat: 48.3182941, lng: 18.436511 },
    { lat: 48.3195781, lng: 18.4361307 },
    { lat: 48.3203054, lng: 18.4359007 },
    { lat: 48.3203121, lng: 18.4358988 },
    { lat: 48.3206702, lng: 18.4356635 },
    { lat: 48.3202877, lng: 18.4335667 },
    { lat: 48.3206405, lng: 18.4331378 },
    { lat: 48.3168576, lng: 18.4230126 },
    { lat: 48.3156054, lng: 18.4182245 },
    { lat: 48.3160804, lng: 18.4157287 },
    { lat: 48.3167538, lng: 18.4141361 },
    { lat: 48.3171584, lng: 18.4128027 },
    { lat: 48.3172076, lng: 18.4125122 },
    { lat: 48.3173006, lng: 18.4117179 },
    { lat: 48.3172838, lng: 18.4107304 },
    { lat: 48.3174797, lng: 18.4107458 },
    { lat: 48.3171591, lng: 18.4082899 },
    { lat: 48.3177539, lng: 18.4081135 },
    { lat: 48.3191674, lng: 18.4082725 },
    { lat: 48.3199164, lng: 18.4081486 },
    { lat: 48.3203457, lng: 18.4078392 },
    { lat: 48.3202762, lng: 18.4066184 },
    { lat: 48.3188574, lng: 18.4064873 },
    { lat: 48.3189351, lng: 18.4056814 },
    { lat: 48.3183462, lng: 18.4055803 },
    { lat: 48.3175222, lng: 18.4057172 },
    { lat: 48.3171546, lng: 18.4057183 },
    { lat: 48.3162477, lng: 18.4055609 },
    { lat: 48.3153452, lng: 18.4055639 },
    { lat: 48.3156649, lng: 18.4024378 },
    { lat: 48.3156716, lng: 18.4023937 },
    { lat: 48.31668, lng: 18.402752 },
    { lat: 48.317018, lng: 18.4015028 },
    { lat: 48.3184388, lng: 18.398175 },
    { lat: 48.3189822, lng: 18.3985509 },
    { lat: 48.3201423, lng: 18.3963476 },
    { lat: 48.3208676, lng: 18.3971287 },
    { lat: 48.3228688, lng: 18.3940735 },
    { lat: 48.3231614, lng: 18.393559 },
    { lat: 48.3240972, lng: 18.3910439 },
    { lat: 48.3243458, lng: 18.3910716 },
    { lat: 48.3245049, lng: 18.3902308 },
    { lat: 48.3252924, lng: 18.3898912 },
    { lat: 48.3259525, lng: 18.3889497 },
    { lat: 48.3241924, lng: 18.386523 },
    { lat: 48.3231967, lng: 18.3856107 },
    { lat: 48.3227105, lng: 18.385209 },
    { lat: 48.3214967, lng: 18.387056 },
    { lat: 48.3207959, lng: 18.3854096 },
    { lat: 48.3205135, lng: 18.3859225 },
    { lat: 48.3189252, lng: 18.3836484 },
    { lat: 48.3185062, lng: 18.3841002 },
    { lat: 48.3165932, lng: 18.3801465 },
    { lat: 48.3161949, lng: 18.3794644 },
    { lat: 48.3161054, lng: 18.3792981 },
    { lat: 48.315257, lng: 18.3815762 },
    { lat: 48.3151564, lng: 18.3831974 },
    { lat: 48.3150688, lng: 18.3835442 },
    { lat: 48.3148094, lng: 18.3842065 },
    { lat: 48.3142415, lng: 18.3853378 },
    { lat: 48.3134183, lng: 18.3874708 },
    { lat: 48.313202, lng: 18.3877976 },
    { lat: 48.3128496, lng: 18.3882271 },
    { lat: 48.3117865, lng: 18.3891644 },
    { lat: 48.3101066, lng: 18.3908509 },
    { lat: 48.3102031, lng: 18.3910397 },
    { lat: 48.3096991, lng: 18.3915872 },
    { lat: 48.3081289, lng: 18.3927724 },
    { lat: 48.3075006, lng: 18.393315 },
    { lat: 48.3069016, lng: 18.3939808 },
    { lat: 48.3067047, lng: 18.394309 },
    { lat: 48.3065139, lng: 18.3942874 },
    { lat: 48.3056659, lng: 18.3953968 },
    { lat: 48.3051011, lng: 18.3962286 },
    { lat: 48.3050962, lng: 18.3963019 },
    { lat: 48.303936, lng: 18.3978968 },
    { lat: 48.3031742, lng: 18.398695 },
    { lat: 48.3030368, lng: 18.3989409 },
    { lat: 48.3027056, lng: 18.4001148 },
    { lat: 48.3020737, lng: 18.4007549 },
    { lat: 48.3016031, lng: 18.4016137 },
    { lat: 48.3013761, lng: 18.4022103 },
    { lat: 48.301117, lng: 18.4026611 },
    { lat: 48.3009318, lng: 18.4032823 },
    { lat: 48.3005299, lng: 18.4038797 },
    { lat: 48.3000514, lng: 18.4041956 },
    { lat: 48.2997648, lng: 18.4042357 },
    { lat: 48.2994562, lng: 18.4045875 },
    { lat: 48.298856, lng: 18.4046048 },
    { lat: 48.2981336, lng: 18.4051626 },
    { lat: 48.2967049, lng: 18.4065996 },
    { lat: 48.2964186, lng: 18.4072695 },
    { lat: 48.2960599, lng: 18.4078919 },
    { lat: 48.2957734, lng: 18.4085785 },
    { lat: 48.2953888, lng: 18.4100063 },
    { lat: 48.2952975, lng: 18.4109318 },
    { lat: 48.2954783, lng: 18.4132902 },
    { lat: 48.293535, lng: 18.4142029 },
    { lat: 48.2934829, lng: 18.4143579 },
    { lat: 48.2912416, lng: 18.4164371 },
    { lat: 48.2903814, lng: 18.4174123 },
    { lat: 48.2897701, lng: 18.4183838 },
    { lat: 48.289467, lng: 18.419083 },
    { lat: 48.290832, lng: 18.419897 },
    { lat: 48.292235, lng: 18.421134 },
  ],
  Zlatno: [
    { lat: 48.4333375, lng: 18.3275429 },
    { lat: 48.4336128, lng: 18.3275027 },
    { lat: 48.4337859, lng: 18.3274958 },
    { lat: 48.4339753, lng: 18.3275703 },
    { lat: 48.4341416, lng: 18.3277391 },
    { lat: 48.43455, lng: 18.3277937 },
    { lat: 48.4353145, lng: 18.3281474 },
    { lat: 48.4359573, lng: 18.3286592 },
    { lat: 48.4361916, lng: 18.328729 },
    { lat: 48.4363368, lng: 18.3286472 },
    { lat: 48.4366111, lng: 18.3288064 },
    { lat: 48.4369319, lng: 18.3285137 },
    { lat: 48.4375079, lng: 18.3290824 },
    { lat: 48.4375287, lng: 18.3292489 },
    { lat: 48.4377218, lng: 18.329351 },
    { lat: 48.4377401, lng: 18.3295373 },
    { lat: 48.437806, lng: 18.3295818 },
    { lat: 48.4379101, lng: 18.329808 },
    { lat: 48.4380294, lng: 18.3298432 },
    { lat: 48.4381673, lng: 18.3301034 },
    { lat: 48.4382586, lng: 18.3300462 },
    { lat: 48.4384099, lng: 18.3300865 },
    { lat: 48.438457, lng: 18.3304013 },
    { lat: 48.4386385, lng: 18.330554 },
    { lat: 48.4390251, lng: 18.3306456 },
    { lat: 48.4391829, lng: 18.3307654 },
    { lat: 48.4394459, lng: 18.3305668 },
    { lat: 48.4394637, lng: 18.3304849 },
    { lat: 48.439566, lng: 18.3305376 },
    { lat: 48.4396418, lng: 18.330503 },
    { lat: 48.4397145, lng: 18.3306671 },
    { lat: 48.4399107, lng: 18.3304874 },
    { lat: 48.4402699, lng: 18.3306337 },
    { lat: 48.4407849, lng: 18.3305443 },
    { lat: 48.4411274, lng: 18.3307126 },
    { lat: 48.4414918, lng: 18.3307142 },
    { lat: 48.441716, lng: 18.3310274 },
    { lat: 48.4418519, lng: 18.3309766 },
    { lat: 48.4422944, lng: 18.3311392 },
    { lat: 48.4427839, lng: 18.330998 },
    { lat: 48.4429006, lng: 18.3308053 },
    { lat: 48.443009, lng: 18.3308867 },
    { lat: 48.4431257, lng: 18.3311138 },
    { lat: 48.4433005, lng: 18.331311 },
    { lat: 48.4434647, lng: 18.3312424 },
    { lat: 48.4438975, lng: 18.3313749 },
    { lat: 48.4441618, lng: 18.3312409 },
    { lat: 48.4445059, lng: 18.3312999 },
    { lat: 48.4448819, lng: 18.3309685 },
    { lat: 48.445094, lng: 18.3310856 },
    { lat: 48.4452041, lng: 18.3308168 },
    { lat: 48.4455054, lng: 18.3307988 },
    { lat: 48.4457426, lng: 18.3306874 },
    { lat: 48.4458969, lng: 18.3308027 },
    { lat: 48.4460822, lng: 18.3305053 },
    { lat: 48.44675, lng: 18.3303647 },
    { lat: 48.4470393, lng: 18.330134 },
    { lat: 48.4473671, lng: 18.330086 },
    { lat: 48.4474572, lng: 18.3302414 },
    { lat: 48.4476868, lng: 18.3301499 },
    { lat: 48.4482789, lng: 18.3294514 },
    { lat: 48.4486014, lng: 18.3292672 },
    { lat: 48.4486692, lng: 18.3288544 },
    { lat: 48.4492686, lng: 18.3285381 },
    { lat: 48.4494464, lng: 18.3285715 },
    { lat: 48.4496106, lng: 18.3285227 },
    { lat: 48.4497462, lng: 18.328226 },
    { lat: 48.4495457, lng: 18.3281134 },
    { lat: 48.450146, lng: 18.3276957 },
    { lat: 48.4505961, lng: 18.3276739 },
    { lat: 48.451125, lng: 18.3273535 },
    { lat: 48.451197, lng: 18.3273681 },
    { lat: 48.4514514, lng: 18.3279937 },
    { lat: 48.4518065, lng: 18.3285066 },
    { lat: 48.4520411, lng: 18.3289613 },
    { lat: 48.4526837, lng: 18.3298291 },
    { lat: 48.4529933, lng: 18.3304366 },
    { lat: 48.4542537, lng: 18.3324306 },
    { lat: 48.4561125, lng: 18.3321802 },
    { lat: 48.4568221, lng: 18.3319561 },
    { lat: 48.4572032, lng: 18.3318358 },
    { lat: 48.457742, lng: 18.3320437 },
    { lat: 48.4581771, lng: 18.3320203 },
    { lat: 48.4589061, lng: 18.3315839 },
    { lat: 48.4594816, lng: 18.3315429 },
    { lat: 48.4606667, lng: 18.3312959 },
    { lat: 48.4610138, lng: 18.3311871 },
    { lat: 48.4613198, lng: 18.3309922 },
    { lat: 48.4630394, lng: 18.3306977 },
    { lat: 48.4654162, lng: 18.3304305 },
    { lat: 48.4680315, lng: 18.3289368 },
    { lat: 48.4690322, lng: 18.3287553 },
    { lat: 48.4693028, lng: 18.3283956 },
    { lat: 48.4696864, lng: 18.328077 },
    { lat: 48.4715669, lng: 18.327613 },
    { lat: 48.4717416, lng: 18.3275117 },
    { lat: 48.4724894, lng: 18.3273874 },
    { lat: 48.4734623, lng: 18.3270536 },
    { lat: 48.4737829, lng: 18.327058 },
    { lat: 48.4739721, lng: 18.3271291 },
    { lat: 48.4746886, lng: 18.3269323 },
    { lat: 48.4753875, lng: 18.3261856 },
    { lat: 48.4761666, lng: 18.3257408 },
    { lat: 48.4766651, lng: 18.3253757 },
    { lat: 48.4773082, lng: 18.3250596 },
    { lat: 48.4781804, lng: 18.3247589 },
    { lat: 48.47887, lng: 18.3242868 },
    { lat: 48.4793431, lng: 18.3240543 },
    { lat: 48.4796246, lng: 18.3239645 },
    { lat: 48.4797868, lng: 18.3240031 },
    { lat: 48.4802164, lng: 18.3239562 },
    { lat: 48.4806149, lng: 18.3237422 },
    { lat: 48.4816662, lng: 18.3235579 },
    { lat: 48.4830156, lng: 18.3230007 },
    { lat: 48.483741, lng: 18.3225805 },
    { lat: 48.4839192, lng: 18.3223997 },
    { lat: 48.4846393, lng: 18.3214697 },
    { lat: 48.4849091, lng: 18.3209781 },
    { lat: 48.4852302, lng: 18.320886 },
    { lat: 48.4865971, lng: 18.3201849 },
    { lat: 48.4866706, lng: 18.3200887 },
    { lat: 48.4872352, lng: 18.3197747 },
    { lat: 48.4886471, lng: 18.3192292 },
    { lat: 48.4890418, lng: 18.3189637 },
    { lat: 48.4898171, lng: 18.3187098 },
    { lat: 48.4904688, lng: 18.3183839 },
    { lat: 48.4913309, lng: 18.3176671 },
    { lat: 48.4919876, lng: 18.3177126 },
    { lat: 48.4922005, lng: 18.3176853 },
    { lat: 48.4924291, lng: 18.3174759 },
    { lat: 48.4926945, lng: 18.3173831 },
    { lat: 48.4928446, lng: 18.3173824 },
    { lat: 48.4930188, lng: 18.3174731 },
    { lat: 48.4934916, lng: 18.3173334 },
    { lat: 48.4938428, lng: 18.3174576 },
    { lat: 48.49518, lng: 18.317168 },
    { lat: 48.49544, lng: 18.316262 },
    { lat: 48.495513, lng: 18.316009 },
    { lat: 48.495764, lng: 18.315173 },
    { lat: 48.496032, lng: 18.314855 },
    { lat: 48.496183, lng: 18.314676 },
    { lat: 48.496673, lng: 18.314102 },
    { lat: 48.496763, lng: 18.312913 },
    { lat: 48.496886, lng: 18.311372 },
    { lat: 48.496545, lng: 18.310391 },
    { lat: 48.495598, lng: 18.309492 },
    { lat: 48.495296, lng: 18.308286 },
    { lat: 48.49493, lng: 18.306448 },
    { lat: 48.494919, lng: 18.305055 },
    { lat: 48.494732, lng: 18.303319 },
    { lat: 48.494687, lng: 18.302807 },
    { lat: 48.495381, lng: 18.301608 },
    { lat: 48.49545, lng: 18.299486 },
    { lat: 48.495061, lng: 18.298654 },
    { lat: 48.494836, lng: 18.297781 },
    { lat: 48.494859, lng: 18.296882 },
    { lat: 48.494804, lng: 18.295824 },
    { lat: 48.494304, lng: 18.293678 },
    { lat: 48.493841, lng: 18.292836 },
    { lat: 48.49353, lng: 18.291932 },
    { lat: 48.493385, lng: 18.291439 },
    { lat: 48.493166, lng: 18.290817 },
    { lat: 48.492296, lng: 18.289816 },
    { lat: 48.491874, lng: 18.289541 },
    { lat: 48.49171, lng: 18.289401 },
    { lat: 48.491367, lng: 18.288739 },
    { lat: 48.490995, lng: 18.287983 },
    { lat: 48.490796, lng: 18.287549 },
    { lat: 48.490683, lng: 18.287455 },
    { lat: 48.490495, lng: 18.286587 },
    { lat: 48.490296, lng: 18.286241 },
    { lat: 48.489655, lng: 18.285439 },
    { lat: 48.489109, lng: 18.284941 },
    { lat: 48.488972, lng: 18.284754 },
    { lat: 48.488672, lng: 18.284139 },
    { lat: 48.488288, lng: 18.283347 },
    { lat: 48.487941, lng: 18.282849 },
    { lat: 48.487781, lng: 18.28255 },
    { lat: 48.487683, lng: 18.281822 },
    { lat: 48.487642, lng: 18.281048 },
    { lat: 48.4872964, lng: 18.2817022 },
    { lat: 48.4870342, lng: 18.2819939 },
    { lat: 48.4867439, lng: 18.2820275 },
    { lat: 48.4865393, lng: 18.2819806 },
    { lat: 48.4855855, lng: 18.2821127 },
    { lat: 48.4852997, lng: 18.2820642 },
    { lat: 48.4851416, lng: 18.2821437 },
    { lat: 48.4845149, lng: 18.2828872 },
    { lat: 48.4834457, lng: 18.2835875 },
    { lat: 48.4826903, lng: 18.2843882 },
    { lat: 48.4825579, lng: 18.284633 },
    { lat: 48.4824924, lng: 18.2847363 },
    { lat: 48.4815639, lng: 18.2855066 },
    { lat: 48.4809097, lng: 18.2863826 },
    { lat: 48.480497, lng: 18.2865675 },
    { lat: 48.4801986, lng: 18.2868151 },
    { lat: 48.4787843, lng: 18.2868048 },
    { lat: 48.4780982, lng: 18.2877706 },
    { lat: 48.4778563, lng: 18.2885166 },
    { lat: 48.477712, lng: 18.2891714 },
    { lat: 48.4773343, lng: 18.2899125 },
    { lat: 48.4767528, lng: 18.2913665 },
    { lat: 48.4763518, lng: 18.2915561 },
    { lat: 48.4757573, lng: 18.2916591 },
    { lat: 48.4756214, lng: 18.2918295 },
    { lat: 48.4749512, lng: 18.2919276 },
    { lat: 48.4740516, lng: 18.292396 },
    { lat: 48.4737973, lng: 18.2924479 },
    { lat: 48.4731987, lng: 18.2923992 },
    { lat: 48.472753, lng: 18.2925573 },
    { lat: 48.4724172, lng: 18.2929796 },
    { lat: 48.4720826, lng: 18.2935639 },
    { lat: 48.4714455, lng: 18.2940044 },
    { lat: 48.471001, lng: 18.2941253 },
    { lat: 48.4702918, lng: 18.2941566 },
    { lat: 48.4695819, lng: 18.2939924 },
    { lat: 48.4693004, lng: 18.2940816 },
    { lat: 48.4689088, lng: 18.2943163 },
    { lat: 48.4686992, lng: 18.2946579 },
    { lat: 48.4682069, lng: 18.2958036 },
    { lat: 48.4673254, lng: 18.2971647 },
    { lat: 48.4670889, lng: 18.2977278 },
    { lat: 48.4668101, lng: 18.2980211 },
    { lat: 48.466519, lng: 18.2981787 },
    { lat: 48.4663162, lng: 18.2983833 },
    { lat: 48.466113, lng: 18.2986964 },
    { lat: 48.4655171, lng: 18.2991311 },
    { lat: 48.4647076, lng: 18.29946 },
    { lat: 48.463353, lng: 18.2998822 },
    { lat: 48.4630054, lng: 18.300202 },
    { lat: 48.4620761, lng: 18.3006654 },
    { lat: 48.4609489, lng: 18.3014347 },
    { lat: 48.4607572, lng: 18.3013982 },
    { lat: 48.4602798, lng: 18.3016143 },
    { lat: 48.459998, lng: 18.301856 },
    { lat: 48.4582301, lng: 18.3029291 },
    { lat: 48.4549847, lng: 18.3039437 },
    { lat: 48.4507207, lng: 18.3052767 },
    { lat: 48.4410258, lng: 18.3057056 },
    { lat: 48.4411325, lng: 18.306151 },
    { lat: 48.4409921, lng: 18.3061431 },
    { lat: 48.4409098, lng: 18.3059436 },
    { lat: 48.4408501, lng: 18.3059293 },
    { lat: 48.4405485, lng: 18.3062371 },
    { lat: 48.4405002, lng: 18.3064192 },
    { lat: 48.4403998, lng: 18.3065205 },
    { lat: 48.4402355, lng: 18.3066252 },
    { lat: 48.4401423, lng: 18.3065742 },
    { lat: 48.4398601, lng: 18.3066133 },
    { lat: 48.4398481, lng: 18.3067179 },
    { lat: 48.4396971, lng: 18.3068397 },
    { lat: 48.4396861, lng: 18.3072252 },
    { lat: 48.439583, lng: 18.3073159 },
    { lat: 48.4394846, lng: 18.3072525 },
    { lat: 48.439323, lng: 18.3074833 },
    { lat: 48.4392623, lng: 18.307489 },
    { lat: 48.4392774, lng: 18.3076327 },
    { lat: 48.4391354, lng: 18.3078553 },
    { lat: 48.4391683, lng: 18.3081179 },
    { lat: 48.4390007, lng: 18.3083434 },
    { lat: 48.4389553, lng: 18.3082796 },
    { lat: 48.4389027, lng: 18.3083077 },
    { lat: 48.4387508, lng: 18.308513 },
    { lat: 48.4386161, lng: 18.3082493 },
    { lat: 48.4384788, lng: 18.3081745 },
    { lat: 48.4383815, lng: 18.3082307 },
    { lat: 48.4383471, lng: 18.3084569 },
    { lat: 48.4381943, lng: 18.308511 },
    { lat: 48.4381177, lng: 18.3084306 },
    { lat: 48.4380392, lng: 18.3084985 },
    { lat: 48.4378857, lng: 18.3086963 },
    { lat: 48.4378872, lng: 18.308781 },
    { lat: 48.4377783, lng: 18.3090107 },
    { lat: 48.4375305, lng: 18.3091779 },
    { lat: 48.4374171, lng: 18.3091868 },
    { lat: 48.4373566, lng: 18.309374 },
    { lat: 48.4371019, lng: 18.3094568 },
    { lat: 48.4370336, lng: 18.3096066 },
    { lat: 48.4368469, lng: 18.309674 },
    { lat: 48.4367371, lng: 18.3097893 },
    { lat: 48.4365236, lng: 18.3096437 },
    { lat: 48.4363936, lng: 18.3096405 },
    { lat: 48.4363498, lng: 18.3097801 },
    { lat: 48.4362257, lng: 18.309844 },
    { lat: 48.4359948, lng: 18.3102066 },
    { lat: 48.4360052, lng: 18.3104277 },
    { lat: 48.4358056, lng: 18.3105095 },
    { lat: 48.4357134, lng: 18.3106549 },
    { lat: 48.4353826, lng: 18.3106718 },
    { lat: 48.4351164, lng: 18.3108918 },
    { lat: 48.4348881, lng: 18.3109645 },
    { lat: 48.4346587, lng: 18.3112334 },
    { lat: 48.4345511, lng: 18.3112078 },
    { lat: 48.4344649, lng: 18.3113972 },
    { lat: 48.4343232, lng: 18.3113671 },
    { lat: 48.4342551, lng: 18.3114995 },
    { lat: 48.434048, lng: 18.3115868 },
    { lat: 48.4340056, lng: 18.3116939 },
    { lat: 48.4338002, lng: 18.3118734 },
    { lat: 48.433649, lng: 18.3118708 },
    { lat: 48.4335592, lng: 18.3120802 },
    { lat: 48.4331636, lng: 18.3120663 },
    { lat: 48.4330432, lng: 18.3125692 },
    { lat: 48.4330428, lng: 18.3128397 },
    { lat: 48.4329527, lng: 18.3129027 },
    { lat: 48.4329523, lng: 18.3132329 },
    { lat: 48.4328627, lng: 18.3132611 },
    { lat: 48.4328399, lng: 18.3133386 },
    { lat: 48.4328629, lng: 18.3134845 },
    { lat: 48.4327675, lng: 18.3135753 },
    { lat: 48.4326221, lng: 18.3135549 },
    { lat: 48.4325778, lng: 18.3137268 },
    { lat: 48.4325117, lng: 18.3137573 },
    { lat: 48.4323712, lng: 18.3137075 },
    { lat: 48.4322639, lng: 18.3138826 },
    { lat: 48.4319733, lng: 18.3141103 },
    { lat: 48.4318883, lng: 18.3142829 },
    { lat: 48.4313665, lng: 18.3147122 },
    { lat: 48.4312748, lng: 18.3145673 },
    { lat: 48.4313036, lng: 18.3143586 },
    { lat: 48.4312481, lng: 18.3142552 },
    { lat: 48.4310173, lng: 18.3145616 },
    { lat: 48.4309746, lng: 18.314485 },
    { lat: 48.4309929, lng: 18.3142912 },
    { lat: 48.430925, lng: 18.314307 },
    { lat: 48.4308514, lng: 18.3144227 },
    { lat: 48.430893, lng: 18.3145755 },
    { lat: 48.4308696, lng: 18.3146428 },
    { lat: 48.430708, lng: 18.3146354 },
    { lat: 48.4305766, lng: 18.3150072 },
    { lat: 48.4304249, lng: 18.3151755 },
    { lat: 48.4303685, lng: 18.3151565 },
    { lat: 48.4303365, lng: 18.3149091 },
    { lat: 48.4302693, lng: 18.314858 },
    { lat: 48.4301235, lng: 18.3150561 },
    { lat: 48.4300886, lng: 18.3153146 },
    { lat: 48.4300017, lng: 18.3153137 },
    { lat: 48.4299707, lng: 18.3154406 },
    { lat: 48.4298277, lng: 18.3154675 },
    { lat: 48.4297483, lng: 18.3156592 },
    { lat: 48.4298066, lng: 18.3157107 },
    { lat: 48.4297618, lng: 18.3158376 },
    { lat: 48.4296041, lng: 18.3159001 },
    { lat: 48.4295217, lng: 18.3157621 },
    { lat: 48.4295221, lng: 18.3157898 },
    { lat: 48.4297917, lng: 18.3181696 },
    { lat: 48.4299348, lng: 18.3190576 },
    { lat: 48.4302176, lng: 18.3199663 },
    { lat: 48.4309802, lng: 18.3217707 },
    { lat: 48.4312541, lng: 18.3226216 },
    { lat: 48.4312976, lng: 18.3230715 },
    { lat: 48.4312769, lng: 18.3236408 },
    { lat: 48.4308157, lng: 18.3271255 },
    { lat: 48.4311325, lng: 18.3270629 },
    { lat: 48.4311996, lng: 18.3271278 },
    { lat: 48.4315365, lng: 18.3271405 },
    { lat: 48.4317885, lng: 18.3270275 },
    { lat: 48.4319255, lng: 18.3271585 },
    { lat: 48.4320926, lng: 18.3269623 },
    { lat: 48.4320981, lng: 18.3267999 },
    { lat: 48.4321524, lng: 18.3267028 },
    { lat: 48.4322288, lng: 18.3267363 },
    { lat: 48.4323189, lng: 18.326632 },
    { lat: 48.4326997, lng: 18.3266017 },
    { lat: 48.4329384, lng: 18.326676 },
    { lat: 48.4330283, lng: 18.3267892 },
    { lat: 48.4332015, lng: 18.3268576 },
    { lat: 48.4332154, lng: 18.3269453 },
    { lat: 48.4332942, lng: 18.3268585 },
    { lat: 48.43336, lng: 18.3269265 },
    { lat: 48.4333375, lng: 18.3275429 },
  ],
  Slepčany: [
    { lat: 48.300612, lng: 18.308256 },
    { lat: 48.300488, lng: 18.308233 },
    { lat: 48.300376, lng: 18.308247 },
    { lat: 48.300335, lng: 18.308153 },
    { lat: 48.300261, lng: 18.307722 },
    { lat: 48.300194, lng: 18.30756 },
    { lat: 48.300164, lng: 18.307487 },
    { lat: 48.30007, lng: 18.307346 },
    { lat: 48.300027, lng: 18.307283 },
    { lat: 48.299946, lng: 18.307229 },
    { lat: 48.299826, lng: 18.307147 },
    { lat: 48.299795, lng: 18.307133 },
    { lat: 48.299474, lng: 18.307075 },
    { lat: 48.299132, lng: 18.307015 },
    { lat: 48.299021, lng: 18.306995 },
    { lat: 48.298912, lng: 18.306977 },
    { lat: 48.298857, lng: 18.306967 },
    { lat: 48.298805, lng: 18.306998 },
    { lat: 48.298787, lng: 18.307013 },
    { lat: 48.298468, lng: 18.307046 },
    { lat: 48.298144, lng: 18.307078 },
    { lat: 48.298173, lng: 18.308448 },
    { lat: 48.298175, lng: 18.308499 },
    { lat: 48.298175, lng: 18.308515 },
    { lat: 48.298176, lng: 18.308532 },
    { lat: 48.298179, lng: 18.30856 },
    { lat: 48.298183, lng: 18.308627 },
    { lat: 48.298186, lng: 18.308653 },
    { lat: 48.298187, lng: 18.30867 },
    { lat: 48.298188, lng: 18.308681 },
    { lat: 48.298189, lng: 18.308694 },
    { lat: 48.29822, lng: 18.30914 },
    { lat: 48.298251, lng: 18.309586 },
    { lat: 48.298227, lng: 18.309588 },
    { lat: 48.298283, lng: 18.311876 },
    { lat: 48.298289, lng: 18.312091 },
    { lat: 48.298372, lng: 18.313449 },
    { lat: 48.29846, lng: 18.314877 },
    { lat: 48.298484, lng: 18.315046 },
    { lat: 48.29848, lng: 18.317252 },
    { lat: 48.298485, lng: 18.319087 },
    { lat: 48.298482, lng: 18.321 },
    { lat: 48.298477, lng: 18.323111 },
    { lat: 48.298057, lng: 18.324668 },
    { lat: 48.297619, lng: 18.326273 },
    { lat: 48.297563, lng: 18.326457 },
    { lat: 48.296869, lng: 18.328968 },
    { lat: 48.2968569, lng: 18.3290256 },
    { lat: 48.296818, lng: 18.329134 },
    { lat: 48.296455, lng: 18.330461 },
    { lat: 48.296117, lng: 18.331684 },
    { lat: 48.295666, lng: 18.333321 },
    { lat: 48.295701, lng: 18.333337 },
    { lat: 48.295645, lng: 18.334477 },
    { lat: 48.295598, lng: 18.335427 },
    { lat: 48.295594, lng: 18.335548 },
    { lat: 48.29549, lng: 18.337391 },
    { lat: 48.295392, lng: 18.339501 },
    { lat: 48.295389, lng: 18.339547 },
    { lat: 48.295325, lng: 18.340655 },
    { lat: 48.295325, lng: 18.340806 },
    { lat: 48.295435, lng: 18.340934 },
    { lat: 48.295637, lng: 18.341249 },
    { lat: 48.295742, lng: 18.341445 },
    { lat: 48.295929, lng: 18.341749 },
    { lat: 48.295899, lng: 18.341849 },
    { lat: 48.295638, lng: 18.342853 },
    { lat: 48.295606, lng: 18.342973 },
    { lat: 48.295499, lng: 18.343383 },
    { lat: 48.295388, lng: 18.34381 },
    { lat: 48.295374, lng: 18.344364 },
    { lat: 48.295395, lng: 18.345033 },
    { lat: 48.295567, lng: 18.346859 },
    { lat: 48.295605, lng: 18.347309 },
    { lat: 48.295725, lng: 18.348616 },
    { lat: 48.295776, lng: 18.34961 },
    { lat: 48.295639, lng: 18.350548 },
    { lat: 48.295496, lng: 18.350854 },
    { lat: 48.295319, lng: 18.351227 },
    { lat: 48.294781, lng: 18.352384 },
    { lat: 48.294618, lng: 18.353802 },
    { lat: 48.294094, lng: 18.355387 },
    { lat: 48.293502, lng: 18.35595 },
    { lat: 48.293212, lng: 18.356299 },
    { lat: 48.292884, lng: 18.356933 },
    { lat: 48.292681, lng: 18.357254 },
    { lat: 48.291722, lng: 18.358863 },
    { lat: 48.2920785, lng: 18.3592883 },
    { lat: 48.2931251, lng: 18.3597761 },
    { lat: 48.2943738, lng: 18.3600925 },
    { lat: 48.2961041, lng: 18.3604243 },
    { lat: 48.2971823, lng: 18.3608152 },
    { lat: 48.2977282, lng: 18.3609532 },
    { lat: 48.2985993, lng: 18.3610519 },
    { lat: 48.2993975, lng: 18.3613027 },
    { lat: 48.2999148, lng: 18.3618291 },
    { lat: 48.3001082, lng: 18.3619597 },
    { lat: 48.3005864, lng: 18.3621115 },
    { lat: 48.3010902, lng: 18.3624639 },
    { lat: 48.301292, lng: 18.362541 },
    { lat: 48.3023347, lng: 18.3625049 },
    { lat: 48.3033322, lng: 18.3626256 },
    { lat: 48.3034711, lng: 18.3626497 },
    { lat: 48.3044303, lng: 18.3627122 },
    { lat: 48.3057057, lng: 18.3622429 },
    { lat: 48.3061224, lng: 18.3605346 },
    { lat: 48.3063949, lng: 18.359726 },
    { lat: 48.3068656, lng: 18.3590711 },
    { lat: 48.3076495, lng: 18.3584084 },
    { lat: 48.3087194, lng: 18.3571772 },
    { lat: 48.3089798, lng: 18.3567164 },
    { lat: 48.3104336, lng: 18.3531206 },
    { lat: 48.3107148, lng: 18.353307 },
    { lat: 48.3109726, lng: 18.3533951 },
    { lat: 48.3113952, lng: 18.3533267 },
    { lat: 48.3117597, lng: 18.3515348 },
    { lat: 48.3119468, lng: 18.3496786 },
    { lat: 48.3121623, lng: 18.3485833 },
    { lat: 48.3121875, lng: 18.3482724 },
    { lat: 48.3124557, lng: 18.3482659 },
    { lat: 48.3125349, lng: 18.3480325 },
    { lat: 48.3123784, lng: 18.3478001 },
    { lat: 48.3124167, lng: 18.3474564 },
    { lat: 48.3126537, lng: 18.3476591 },
    { lat: 48.3126522, lng: 18.3473941 },
    { lat: 48.3128354, lng: 18.3472241 },
    { lat: 48.3129644, lng: 18.3473094 },
    { lat: 48.3130231, lng: 18.3474688 },
    { lat: 48.3129637, lng: 18.3477135 },
    { lat: 48.313091, lng: 18.347749 },
    { lat: 48.3132206, lng: 18.3479037 },
    { lat: 48.313385, lng: 18.3477222 },
    { lat: 48.3133797, lng: 18.3479877 },
    { lat: 48.3134849, lng: 18.3482295 },
    { lat: 48.3139095, lng: 18.3480372 },
    { lat: 48.3140048, lng: 18.3481083 },
    { lat: 48.3139773, lng: 18.3484166 },
    { lat: 48.3140412, lng: 18.348551 },
    { lat: 48.3141601, lng: 18.3484579 },
    { lat: 48.3142162, lng: 18.3486719 },
    { lat: 48.3141652, lng: 18.349008 },
    { lat: 48.3142862, lng: 18.3490897 },
    { lat: 48.3142916, lng: 18.3494056 },
    { lat: 48.3146673, lng: 18.3495445 },
    { lat: 48.3146281, lng: 18.3497491 },
    { lat: 48.3146949, lng: 18.3499898 },
    { lat: 48.3146625, lng: 18.3502565 },
    { lat: 48.3147919, lng: 18.3503712 },
    { lat: 48.3152467, lng: 18.3501531 },
    { lat: 48.315783, lng: 18.349741 },
    { lat: 48.315861, lng: 18.3494234 },
    { lat: 48.3158792, lng: 18.3484509 },
    { lat: 48.3162641, lng: 18.346782 },
    { lat: 48.3166573, lng: 18.3461145 },
    { lat: 48.3165364, lng: 18.3459126 },
    { lat: 48.3171891, lng: 18.3424396 },
    { lat: 48.3172017, lng: 18.3423856 },
    { lat: 48.3173905, lng: 18.3425082 },
    { lat: 48.317401, lng: 18.3424559 },
    { lat: 48.317839, lng: 18.3394373 },
    { lat: 48.3178874, lng: 18.337786 },
    { lat: 48.3178537, lng: 18.336197 },
    { lat: 48.3188582, lng: 18.3359883 },
    { lat: 48.3186968, lng: 18.3324503 },
    { lat: 48.318883, lng: 18.3323698 },
    { lat: 48.3190772, lng: 18.3324506 },
    { lat: 48.3193905, lng: 18.3322458 },
    { lat: 48.3194158, lng: 18.3321337 },
    { lat: 48.3197736, lng: 18.3315613 },
    { lat: 48.3198904, lng: 18.3311745 },
    { lat: 48.3200195, lng: 18.3311041 },
    { lat: 48.3200986, lng: 18.3311851 },
    { lat: 48.3202374, lng: 18.3310277 },
    { lat: 48.3202954, lng: 18.3308367 },
    { lat: 48.3204765, lng: 18.3306668 },
    { lat: 48.3206111, lng: 18.3307127 },
    { lat: 48.3206449, lng: 18.3309505 },
    { lat: 48.3210047, lng: 18.3308879 },
    { lat: 48.3210692, lng: 18.3312317 },
    { lat: 48.3213437, lng: 18.3311158 },
    { lat: 48.3214588, lng: 18.3313773 },
    { lat: 48.3218431, lng: 18.3312502 },
    { lat: 48.3218707, lng: 18.3314948 },
    { lat: 48.3225182, lng: 18.3312923 },
    { lat: 48.3227613, lng: 18.3311436 },
    { lat: 48.3238952, lng: 18.3301706 },
    { lat: 48.3238124, lng: 18.327245 },
    { lat: 48.3237092, lng: 18.3266229 },
    { lat: 48.3234571, lng: 18.3251612 },
    { lat: 48.3232392, lng: 18.3243272 },
    { lat: 48.3229666, lng: 18.3238406 },
    { lat: 48.320714, lng: 18.3205552 },
    { lat: 48.3205355, lng: 18.3201374 },
    { lat: 48.3203501, lng: 18.3189228 },
    { lat: 48.3200271, lng: 18.318141 },
    { lat: 48.3193994, lng: 18.3173645 },
    { lat: 48.3186659, lng: 18.313956 },
    { lat: 48.3186824, lng: 18.3131333 },
    { lat: 48.3186022, lng: 18.3118667 },
    { lat: 48.3185924, lng: 18.3108807 },
    { lat: 48.3188151, lng: 18.3100964 },
    { lat: 48.318843, lng: 18.3098354 },
    { lat: 48.3180669, lng: 18.3061971 },
    { lat: 48.3185499, lng: 18.3058445 },
    { lat: 48.3177761, lng: 18.3023847 },
    { lat: 48.3175016, lng: 18.3025372 },
    { lat: 48.3167325, lng: 18.3024675 },
    { lat: 48.3165304, lng: 18.3025427 },
    { lat: 48.3163557, lng: 18.3025279 },
    { lat: 48.3162403, lng: 18.3026175 },
    { lat: 48.316194, lng: 18.3024979 },
    { lat: 48.316117, lng: 18.302455 },
    { lat: 48.315972, lng: 18.3026022 },
    { lat: 48.3157897, lng: 18.302632 },
    { lat: 48.3151771, lng: 18.3032855 },
    { lat: 48.3150275, lng: 18.304103 },
    { lat: 48.3149241, lng: 18.3042718 },
    { lat: 48.3148126, lng: 18.3044308 },
    { lat: 48.3146958, lng: 18.3044387 },
    { lat: 48.3146121, lng: 18.3045355 },
    { lat: 48.3143573, lng: 18.3046401 },
    { lat: 48.3144076, lng: 18.3049662 },
    { lat: 48.3141762, lng: 18.305104 },
    { lat: 48.314055, lng: 18.305274 },
    { lat: 48.314055, lng: 18.305277 },
    { lat: 48.313779, lng: 18.305384 },
    { lat: 48.313484, lng: 18.305558 },
    { lat: 48.313055, lng: 18.305811 },
    { lat: 48.312905, lng: 18.305912 },
    { lat: 48.312935, lng: 18.305966 },
    { lat: 48.312972, lng: 18.306047 },
    { lat: 48.313086, lng: 18.306315 },
    { lat: 48.313227, lng: 18.306645 },
    { lat: 48.313227, lng: 18.306647 },
    { lat: 48.313241, lng: 18.306677 },
    { lat: 48.313249, lng: 18.306694 },
    { lat: 48.313197, lng: 18.306774 },
    { lat: 48.313085, lng: 18.306925 },
    { lat: 48.31303, lng: 18.306982 },
    { lat: 48.312893, lng: 18.307119 },
    { lat: 48.312851, lng: 18.307161 },
    { lat: 48.312838, lng: 18.307175 },
    { lat: 48.312599, lng: 18.307417 },
    { lat: 48.312467, lng: 18.307551 },
    { lat: 48.31236, lng: 18.307659 },
    { lat: 48.312362, lng: 18.307555 },
    { lat: 48.312308, lng: 18.307581 },
    { lat: 48.312262, lng: 18.307603 },
    { lat: 48.312261, lng: 18.307604 },
    { lat: 48.312213, lng: 18.307631 },
    { lat: 48.312203, lng: 18.307736 },
    { lat: 48.312186, lng: 18.307749 },
    { lat: 48.312094, lng: 18.307822 },
    { lat: 48.312029, lng: 18.307872 },
    { lat: 48.311998, lng: 18.30788 },
    { lat: 48.311799, lng: 18.307932 },
    { lat: 48.311755, lng: 18.308014 },
    { lat: 48.31169, lng: 18.308135 },
    { lat: 48.311697, lng: 18.3083 },
    { lat: 48.3117, lng: 18.308378 },
    { lat: 48.311702, lng: 18.308409 },
    { lat: 48.311703, lng: 18.308436 },
    { lat: 48.311705, lng: 18.308457 },
    { lat: 48.311624, lng: 18.308498 },
    { lat: 48.311466, lng: 18.308577 },
    { lat: 48.311483, lng: 18.308623 },
    { lat: 48.31149, lng: 18.308646 },
    { lat: 48.311496, lng: 18.308665 },
    { lat: 48.311501, lng: 18.308677 },
    { lat: 48.311504, lng: 18.308686 },
    { lat: 48.311521, lng: 18.308698 },
    { lat: 48.311595, lng: 18.308747 },
    { lat: 48.311524, lng: 18.30887 },
    { lat: 48.311321, lng: 18.30892 },
    { lat: 48.3113, lng: 18.308925 },
    { lat: 48.311176, lng: 18.309006 },
    { lat: 48.311031, lng: 18.30898 },
    { lat: 48.311029, lng: 18.309092 },
    { lat: 48.311, lng: 18.309118 },
    { lat: 48.310976, lng: 18.30914 },
    { lat: 48.310848, lng: 18.309226 },
    { lat: 48.310767, lng: 18.309297 },
    { lat: 48.310692, lng: 18.309338 },
    { lat: 48.310669, lng: 18.30934 },
    { lat: 48.310593, lng: 18.309349 },
    { lat: 48.310588, lng: 18.309346 },
    { lat: 48.310484, lng: 18.309305 },
    { lat: 48.310398, lng: 18.309272 },
    { lat: 48.31034, lng: 18.309249 },
    { lat: 48.310252, lng: 18.309214 },
    { lat: 48.310162, lng: 18.309179 },
    { lat: 48.310125, lng: 18.309224 },
    { lat: 48.310053, lng: 18.309332 },
    { lat: 48.309875, lng: 18.309334 },
    { lat: 48.309801, lng: 18.309335 },
    { lat: 48.30976, lng: 18.309304 },
    { lat: 48.309562, lng: 18.309106 },
    { lat: 48.309398, lng: 18.309092 },
    { lat: 48.309259, lng: 18.30908 },
    { lat: 48.309307, lng: 18.309352 },
    { lat: 48.309269, lng: 18.30945 },
    { lat: 48.309245, lng: 18.309512 },
    { lat: 48.309186, lng: 18.30953 },
    { lat: 48.309092, lng: 18.30953 },
    { lat: 48.309017, lng: 18.309456 },
    { lat: 48.308854, lng: 18.309295 },
    { lat: 48.308718, lng: 18.309143 },
    { lat: 48.308534, lng: 18.309051 },
    { lat: 48.308389, lng: 18.309162 },
    { lat: 48.308271, lng: 18.309333 },
    { lat: 48.30812, lng: 18.309568 },
    { lat: 48.308121, lng: 18.309904 },
    { lat: 48.308043, lng: 18.30994 },
    { lat: 48.307973, lng: 18.309972 },
    { lat: 48.307907, lng: 18.310006 },
    { lat: 48.307823, lng: 18.31005 },
    { lat: 48.307778, lng: 18.310073 },
    { lat: 48.307705, lng: 18.310111 },
    { lat: 48.307604, lng: 18.310091 },
    { lat: 48.307522, lng: 18.310074 },
    { lat: 48.30744, lng: 18.310058 },
    { lat: 48.307353, lng: 18.31004 },
    { lat: 48.307316, lng: 18.310033 },
    { lat: 48.307274, lng: 18.310001 },
    { lat: 48.307154, lng: 18.309904 },
    { lat: 48.306979, lng: 18.309761 },
    { lat: 48.306947, lng: 18.309663 },
    { lat: 48.306934, lng: 18.309621 },
    { lat: 48.306908, lng: 18.309488 },
    { lat: 48.306723, lng: 18.308863 },
    { lat: 48.306716, lng: 18.308839 },
    { lat: 48.306714, lng: 18.308831 },
    { lat: 48.306708, lng: 18.308814 },
    { lat: 48.306704, lng: 18.308798 },
    { lat: 48.306698, lng: 18.308777 },
    { lat: 48.306683, lng: 18.30872 },
    { lat: 48.306625, lng: 18.308704 },
    { lat: 48.306576, lng: 18.308689 },
    { lat: 48.306507, lng: 18.308669 },
    { lat: 48.306372, lng: 18.30863 },
    { lat: 48.306344, lng: 18.308671 },
    { lat: 48.306329, lng: 18.308694 },
    { lat: 48.306318, lng: 18.30871 },
    { lat: 48.306309, lng: 18.308726 },
    { lat: 48.306273, lng: 18.308781 },
    { lat: 48.306257, lng: 18.308806 },
    { lat: 48.306247, lng: 18.308823 },
    { lat: 48.306237, lng: 18.308839 },
    { lat: 48.306181, lng: 18.308926 },
    { lat: 48.306175, lng: 18.308937 },
    { lat: 48.306137, lng: 18.30895 },
    { lat: 48.306124, lng: 18.308955 },
    { lat: 48.306074, lng: 18.308984 },
    { lat: 48.306042, lng: 18.309003 },
    { lat: 48.305948, lng: 18.309059 },
    { lat: 48.305882, lng: 18.309098 },
    { lat: 48.305777, lng: 18.309159 },
    { lat: 48.30576, lng: 18.30917 },
    { lat: 48.305655, lng: 18.309042 },
    { lat: 48.305635, lng: 18.30905 },
    { lat: 48.305537, lng: 18.309089 },
    { lat: 48.305518, lng: 18.309097 },
    { lat: 48.305435, lng: 18.30913 },
    { lat: 48.305336, lng: 18.309041 },
    { lat: 48.305178, lng: 18.308897 },
    { lat: 48.305167, lng: 18.308887 },
    { lat: 48.305155, lng: 18.308866 },
    { lat: 48.305144, lng: 18.308845 },
    { lat: 48.305135, lng: 18.308828 },
    { lat: 48.305125, lng: 18.308809 },
    { lat: 48.305085, lng: 18.308733 },
    { lat: 48.305076, lng: 18.308717 },
    { lat: 48.305061, lng: 18.308688 },
    { lat: 48.305043, lng: 18.308655 },
    { lat: 48.305047, lng: 18.308427 },
    { lat: 48.304993, lng: 18.308248 },
    { lat: 48.304817, lng: 18.308209 },
    { lat: 48.304498, lng: 18.308141 },
    { lat: 48.304317, lng: 18.308112 },
    { lat: 48.304316, lng: 18.308007 },
    { lat: 48.304307, lng: 18.307991 },
    { lat: 48.30427, lng: 18.307919 },
    { lat: 48.304228, lng: 18.307835 },
    { lat: 48.304139, lng: 18.30766 },
    { lat: 48.304043, lng: 18.307587 },
    { lat: 48.30397, lng: 18.307663 },
    { lat: 48.303909, lng: 18.307739 },
    { lat: 48.303853, lng: 18.308038 },
    { lat: 48.303785, lng: 18.308054 },
    { lat: 48.303709, lng: 18.308074 },
    { lat: 48.303458, lng: 18.308139 },
    { lat: 48.303401, lng: 18.308033 },
    { lat: 48.303207, lng: 18.30776 },
    { lat: 48.303187, lng: 18.307745 },
    { lat: 48.302995, lng: 18.307662 },
    { lat: 48.302957, lng: 18.307643 },
    { lat: 48.302909, lng: 18.307623 },
    { lat: 48.302712, lng: 18.307213 },
    { lat: 48.302514, lng: 18.30695 },
    { lat: 48.302426, lng: 18.306834 },
    { lat: 48.30237, lng: 18.306943 },
    { lat: 48.302191, lng: 18.307285 },
    { lat: 48.302225, lng: 18.30779 },
    { lat: 48.302102, lng: 18.308663 },
    { lat: 48.302049, lng: 18.308682 },
    { lat: 48.302009, lng: 18.308697 },
    { lat: 48.301963, lng: 18.308703 },
    { lat: 48.301914, lng: 18.308716 },
    { lat: 48.301828, lng: 18.308739 },
    { lat: 48.301815, lng: 18.308743 },
    { lat: 48.301772, lng: 18.308755 },
    { lat: 48.301759, lng: 18.308735 },
    { lat: 48.301738, lng: 18.308706 },
    { lat: 48.301719, lng: 18.308681 },
    { lat: 48.301708, lng: 18.308662 },
    { lat: 48.301678, lng: 18.308607 },
    { lat: 48.301652, lng: 18.308595 },
    { lat: 48.301575, lng: 18.308556 },
    { lat: 48.301468, lng: 18.308505 },
    { lat: 48.301343, lng: 18.308448 },
    { lat: 48.301067, lng: 18.308321 },
    { lat: 48.300945, lng: 18.308265 },
    { lat: 48.300864, lng: 18.308227 },
    { lat: 48.30084, lng: 18.308225 },
    { lat: 48.300826, lng: 18.308223 },
    { lat: 48.300772, lng: 18.308229 },
    { lat: 48.300612, lng: 18.308256 },
  ],
  Lovce: [
    { lat: 48.4309658, lng: 18.3733687 },
    { lat: 48.4337759, lng: 18.3711636 },
    { lat: 48.435577, lng: 18.3706698 },
    { lat: 48.4356312, lng: 18.371074 },
    { lat: 48.4361461, lng: 18.3709801 },
    { lat: 48.4364001, lng: 18.3730049 },
    { lat: 48.4380829, lng: 18.3729756 },
    { lat: 48.4384999, lng: 18.3730502 },
    { lat: 48.4453137, lng: 18.3729763 },
    { lat: 48.4453138, lng: 18.3727591 },
    { lat: 48.4482136, lng: 18.372365 },
    { lat: 48.451352, lng: 18.3716594 },
    { lat: 48.4515277, lng: 18.371814 },
    { lat: 48.4520239, lng: 18.3713102 },
    { lat: 48.4517931, lng: 18.3684715 },
    { lat: 48.4541862, lng: 18.3677143 },
    { lat: 48.4621174, lng: 18.3654726 },
    { lat: 48.4626221, lng: 18.3684434 },
    { lat: 48.4631021, lng: 18.3680922 },
    { lat: 48.4630695, lng: 18.3679705 },
    { lat: 48.463406, lng: 18.3677584 },
    { lat: 48.4633573, lng: 18.3675524 },
    { lat: 48.4636342, lng: 18.3674819 },
    { lat: 48.4640199, lng: 18.3670389 },
    { lat: 48.4642216, lng: 18.3674551 },
    { lat: 48.4644338, lng: 18.3673353 },
    { lat: 48.4645846, lng: 18.3673694 },
    { lat: 48.4648392, lng: 18.367284 },
    { lat: 48.4650976, lng: 18.3671416 },
    { lat: 48.4654023, lng: 18.366865 },
    { lat: 48.4655859, lng: 18.3665984 },
    { lat: 48.4657848, lng: 18.3666673 },
    { lat: 48.4660147, lng: 18.366622 },
    { lat: 48.4661215, lng: 18.3666557 },
    { lat: 48.4661964, lng: 18.3667798 },
    { lat: 48.4665387, lng: 18.3667288 },
    { lat: 48.4668502, lng: 18.3665935 },
    { lat: 48.4672315, lng: 18.3666306 },
    { lat: 48.4676033, lng: 18.3667634 },
    { lat: 48.4681985, lng: 18.3664338 },
    { lat: 48.4689175, lng: 18.3662793 },
    { lat: 48.4719072, lng: 18.3637751 },
    { lat: 48.472141, lng: 18.3620006 },
    { lat: 48.472835, lng: 18.3623004 },
    { lat: 48.4734278, lng: 18.363049 },
    { lat: 48.473887, lng: 18.3637936 },
    { lat: 48.4742511, lng: 18.3635883 },
    { lat: 48.4748467, lng: 18.3638448 },
    { lat: 48.4753695, lng: 18.3643017 },
    { lat: 48.4755658, lng: 18.3644732 },
    { lat: 48.4757639, lng: 18.3647036 },
    { lat: 48.4758435, lng: 18.3648784 },
    { lat: 48.476006, lng: 18.3649764 },
    { lat: 48.4762343, lng: 18.3649242 },
    { lat: 48.4767221, lng: 18.3646533 },
    { lat: 48.4770722, lng: 18.3642385 },
    { lat: 48.4777273, lng: 18.3637504 },
    { lat: 48.478064, lng: 18.3634205 },
    { lat: 48.4782898, lng: 18.3633032 },
    { lat: 48.4785709, lng: 18.3628036 },
    { lat: 48.4787483, lng: 18.3628479 },
    { lat: 48.4794803, lng: 18.3625839 },
    { lat: 48.4799457, lng: 18.3625533 },
    { lat: 48.4813377, lng: 18.3620501 },
    { lat: 48.4821415, lng: 18.3620995 },
    { lat: 48.4831025, lng: 18.361915 },
    { lat: 48.4837714, lng: 18.3616359 },
    { lat: 48.4839436, lng: 18.3614273 },
    { lat: 48.4840214, lng: 18.361083 },
    { lat: 48.484294, lng: 18.360879 },
    { lat: 48.4847769, lng: 18.3607384 },
    { lat: 48.485082, lng: 18.3604677 },
    { lat: 48.4855511, lng: 18.3596798 },
    { lat: 48.4856611, lng: 18.3594133 },
    { lat: 48.4855645, lng: 18.3591802 },
    { lat: 48.4864062, lng: 18.3582378 },
    { lat: 48.4865722, lng: 18.3577827 },
    { lat: 48.486483, lng: 18.3575625 },
    { lat: 48.4867735, lng: 18.3571296 },
    { lat: 48.4871616, lng: 18.3570321 },
    { lat: 48.4875879, lng: 18.3566263 },
    { lat: 48.4877161, lng: 18.3564225 },
    { lat: 48.4877561, lng: 18.3562093 },
    { lat: 48.48791, lng: 18.3562171 },
    { lat: 48.4880699, lng: 18.3568937 },
    { lat: 48.4882605, lng: 18.3570487 },
    { lat: 48.4882283, lng: 18.3573 },
    { lat: 48.4890206, lng: 18.3575449 },
    { lat: 48.4891378, lng: 18.3574848 },
    { lat: 48.4896956, lng: 18.3565521 },
    { lat: 48.4896846, lng: 18.3562356 },
    { lat: 48.4900637, lng: 18.355755 },
    { lat: 48.4905118, lng: 18.3549818 },
    { lat: 48.4905361, lng: 18.3547707 },
    { lat: 48.4906352, lng: 18.354567 },
    { lat: 48.4909491, lng: 18.3541496 },
    { lat: 48.4912334, lng: 18.3536324 },
    { lat: 48.493705, lng: 18.347384 },
    { lat: 48.493537, lng: 18.347162 },
    { lat: 48.493418, lng: 18.347165 },
    { lat: 48.493267, lng: 18.347107 },
    { lat: 48.493104, lng: 18.346893 },
    { lat: 48.492984, lng: 18.346635 },
    { lat: 48.492965, lng: 18.34655 },
    { lat: 48.492783, lng: 18.346253 },
    { lat: 48.492634, lng: 18.346028 },
    { lat: 48.492403, lng: 18.345995 },
    { lat: 48.492221, lng: 18.345802 },
    { lat: 48.492118, lng: 18.345562 },
    { lat: 48.491988, lng: 18.344895 },
    { lat: 48.491759, lng: 18.3446 },
    { lat: 48.491482, lng: 18.344463 },
    { lat: 48.491308, lng: 18.344385 },
    { lat: 48.490683, lng: 18.343548 },
    { lat: 48.490529, lng: 18.343312 },
    { lat: 48.490361, lng: 18.343087 },
    { lat: 48.490056, lng: 18.342674 },
    { lat: 48.489977, lng: 18.342567 },
    { lat: 48.48986, lng: 18.342265 },
    { lat: 48.489811, lng: 18.34198 },
    { lat: 48.489748, lng: 18.341563 },
    { lat: 48.489707, lng: 18.341284 },
    { lat: 48.489707, lng: 18.341087 },
    { lat: 48.489709, lng: 18.340761 },
    { lat: 48.489745, lng: 18.340526 },
    { lat: 48.489748, lng: 18.340481 },
    { lat: 48.489761, lng: 18.340297 },
    { lat: 48.489808, lng: 18.339701 },
    { lat: 48.489825, lng: 18.339209 },
    { lat: 48.489829, lng: 18.338925 },
    { lat: 48.489923, lng: 18.338014 },
    { lat: 48.489959, lng: 18.337703 },
    { lat: 48.489877, lng: 18.337453 },
    { lat: 48.4891322, lng: 18.3371904 },
    { lat: 48.4887906, lng: 18.3371747 },
    { lat: 48.4881236, lng: 18.3373494 },
    { lat: 48.4877033, lng: 18.3375583 },
    { lat: 48.4874432, lng: 18.3378676 },
    { lat: 48.4871374, lng: 18.338108 },
    { lat: 48.4861144, lng: 18.338329 },
    { lat: 48.4852485, lng: 18.3383811 },
    { lat: 48.4844975, lng: 18.3382279 },
    { lat: 48.483286, lng: 18.3385327 },
    { lat: 48.4823777, lng: 18.3384697 },
    { lat: 48.4819838, lng: 18.3382111 },
    { lat: 48.4812981, lng: 18.3378956 },
    { lat: 48.4808425, lng: 18.3372604 },
    { lat: 48.480609, lng: 18.3372829 },
    { lat: 48.4799694, lng: 18.3370845 },
    { lat: 48.4796003, lng: 18.3371012 },
    { lat: 48.4793718, lng: 18.3369316 },
    { lat: 48.4788841, lng: 18.3369877 },
    { lat: 48.4785218, lng: 18.3373395 },
    { lat: 48.4782947, lng: 18.3374756 },
    { lat: 48.4774698, lng: 18.3391682 },
    { lat: 48.4773057, lng: 18.3393592 },
    { lat: 48.4764704, lng: 18.3399486 },
    { lat: 48.4762823, lng: 18.3402134 },
    { lat: 48.4754517, lng: 18.3410909 },
    { lat: 48.4747235, lng: 18.3417174 },
    { lat: 48.4744854, lng: 18.3423349 },
    { lat: 48.4742403, lng: 18.3427503 },
    { lat: 48.4736508, lng: 18.3430781 },
    { lat: 48.4732623, lng: 18.343174 },
    { lat: 48.4723325, lng: 18.3438689 },
    { lat: 48.472005, lng: 18.3439648 },
    { lat: 48.4715462, lng: 18.344309 },
    { lat: 48.4711551, lng: 18.3447348 },
    { lat: 48.470392, lng: 18.3449066 },
    { lat: 48.4700671, lng: 18.3451067 },
    { lat: 48.4698234, lng: 18.3454092 },
    { lat: 48.4694007, lng: 18.3454131 },
    { lat: 48.4687616, lng: 18.345605 },
    { lat: 48.4685834, lng: 18.3458229 },
    { lat: 48.468095, lng: 18.3459654 },
    { lat: 48.4673739, lng: 18.3461027 },
    { lat: 48.4667028, lng: 18.3455492 },
    { lat: 48.4665696, lng: 18.345605 },
    { lat: 48.4661683, lng: 18.3455322 },
    { lat: 48.4659548, lng: 18.3456078 },
    { lat: 48.4658614, lng: 18.3454914 },
    { lat: 48.4653144, lng: 18.3451951 },
    { lat: 48.4651369, lng: 18.3452509 },
    { lat: 48.4646412, lng: 18.3450839 },
    { lat: 48.4645665, lng: 18.3448997 },
    { lat: 48.4643666, lng: 18.3450354 },
    { lat: 48.4642746, lng: 18.3450436 },
    { lat: 48.4639691, lng: 18.344868 },
    { lat: 48.4637671, lng: 18.3449713 },
    { lat: 48.4636277, lng: 18.344897 },
    { lat: 48.4635586, lng: 18.344753 },
    { lat: 48.4634158, lng: 18.3447609 },
    { lat: 48.4633913, lng: 18.3446293 },
    { lat: 48.4632348, lng: 18.3446124 },
    { lat: 48.4630875, lng: 18.3447432 },
    { lat: 48.4629375, lng: 18.3447609 },
    { lat: 48.4627531, lng: 18.3445726 },
    { lat: 48.4625843, lng: 18.3446233 },
    { lat: 48.4623336, lng: 18.3444375 },
    { lat: 48.4622513, lng: 18.3442411 },
    { lat: 48.4620044, lng: 18.3440057 },
    { lat: 48.461888, lng: 18.3440408 },
    { lat: 48.4616601, lng: 18.3439 },
    { lat: 48.4615882, lng: 18.3439878 },
    { lat: 48.46148, lng: 18.3438089 },
    { lat: 48.4605582, lng: 18.3437288 },
    { lat: 48.4603203, lng: 18.3438697 },
    { lat: 48.4599977, lng: 18.3442703 },
    { lat: 48.4598682, lng: 18.3442958 },
    { lat: 48.459753, lng: 18.3444111 },
    { lat: 48.4596461, lng: 18.3443768 },
    { lat: 48.4595149, lng: 18.3444522 },
    { lat: 48.4594382, lng: 18.3446515 },
    { lat: 48.4592802, lng: 18.3447726 },
    { lat: 48.4592724, lng: 18.3449517 },
    { lat: 48.4591796, lng: 18.345067 },
    { lat: 48.4585139, lng: 18.3450849 },
    { lat: 48.4584662, lng: 18.34522 },
    { lat: 48.4583763, lng: 18.3452475 },
    { lat: 48.4582462, lng: 18.3457767 },
    { lat: 48.4580952, lng: 18.3459417 },
    { lat: 48.4579429, lng: 18.3462804 },
    { lat: 48.4579714, lng: 18.3465878 },
    { lat: 48.4579169, lng: 18.3467398 },
    { lat: 48.4576822, lng: 18.3469788 },
    { lat: 48.4575194, lng: 18.3470529 },
    { lat: 48.4575046, lng: 18.3471504 },
    { lat: 48.4573657, lng: 18.3472058 },
    { lat: 48.4573606, lng: 18.3474549 },
    { lat: 48.4569336, lng: 18.3478478 },
    { lat: 48.4568979, lng: 18.3479949 },
    { lat: 48.4566977, lng: 18.3483223 },
    { lat: 48.4565175, lng: 18.3485294 },
    { lat: 48.4564504, lng: 18.3485177 },
    { lat: 48.456456, lng: 18.3486595 },
    { lat: 48.4563594, lng: 18.348824 },
    { lat: 48.4562692, lng: 18.3488469 },
    { lat: 48.4561988, lng: 18.3486803 },
    { lat: 48.4560162, lng: 18.3486041 },
    { lat: 48.455823, lng: 18.348879 },
    { lat: 48.4557404, lng: 18.3491906 },
    { lat: 48.4553803, lng: 18.3492497 },
    { lat: 48.4549779, lng: 18.3495325 },
    { lat: 48.4546414, lng: 18.3493103 },
    { lat: 48.4543409, lng: 18.3494436 },
    { lat: 48.4541964, lng: 18.3493796 },
    { lat: 48.4539235, lng: 18.3495054 },
    { lat: 48.4539109, lng: 18.3496421 },
    { lat: 48.4537363, lng: 18.349765 },
    { lat: 48.453614, lng: 18.3499768 },
    { lat: 48.4535189, lng: 18.3500099 },
    { lat: 48.4534077, lng: 18.3497771 },
    { lat: 48.4530528, lng: 18.3500083 },
    { lat: 48.4528635, lng: 18.3497915 },
    { lat: 48.4527417, lng: 18.3499748 },
    { lat: 48.4526789, lng: 18.3501971 },
    { lat: 48.4524316, lng: 18.3500186 },
    { lat: 48.4523443, lng: 18.3498485 },
    { lat: 48.452122, lng: 18.3497895 },
    { lat: 48.4519253, lng: 18.3496006 },
    { lat: 48.4517678, lng: 18.3496886 },
    { lat: 48.4516981, lng: 18.3498141 },
    { lat: 48.4512, lng: 18.3497234 },
    { lat: 48.4510446, lng: 18.3499263 },
    { lat: 48.450976, lng: 18.3496344 },
    { lat: 48.4506089, lng: 18.3495057 },
    { lat: 48.450445, lng: 18.3496791 },
    { lat: 48.4503693, lng: 18.3496792 },
    { lat: 48.4502198, lng: 18.3498696 },
    { lat: 48.4500653, lng: 18.3502703 },
    { lat: 48.449914, lng: 18.3503283 },
    { lat: 48.4498602, lng: 18.3504511 },
    { lat: 48.4493386, lng: 18.3508428 },
    { lat: 48.4489868, lng: 18.3513664 },
    { lat: 48.4488225, lng: 18.351295 },
    { lat: 48.4487841, lng: 18.3516322 },
    { lat: 48.4485692, lng: 18.3513281 },
    { lat: 48.4483297, lng: 18.3513192 },
    { lat: 48.4481685, lng: 18.3512026 },
    { lat: 48.4480117, lng: 18.3513655 },
    { lat: 48.4480227, lng: 18.3514806 },
    { lat: 48.4479616, lng: 18.3515957 },
    { lat: 48.4479872, lng: 18.351768 },
    { lat: 48.4478937, lng: 18.3521503 },
    { lat: 48.4476765, lng: 18.3524211 },
    { lat: 48.4474197, lng: 18.3526074 },
    { lat: 48.4473411, lng: 18.3527947 },
    { lat: 48.4473625, lng: 18.353072 },
    { lat: 48.4472014, lng: 18.3531551 },
    { lat: 48.4470799, lng: 18.3530036 },
    { lat: 48.4469854, lng: 18.3529883 },
    { lat: 48.4467829, lng: 18.3531997 },
    { lat: 48.4465575, lng: 18.3532706 },
    { lat: 48.4465542, lng: 18.3531656 },
    { lat: 48.4462413, lng: 18.3529791 },
    { lat: 48.4460587, lng: 18.3531197 },
    { lat: 48.4459502, lng: 18.3534178 },
    { lat: 48.4458492, lng: 18.3535032 },
    { lat: 48.4457367, lng: 18.3534508 },
    { lat: 48.4453401, lng: 18.3536023 },
    { lat: 48.4452298, lng: 18.3537255 },
    { lat: 48.4449017, lng: 18.3537488 },
    { lat: 48.4444469, lng: 18.3540251 },
    { lat: 48.4441918, lng: 18.3539467 },
    { lat: 48.4426793, lng: 18.3547523 },
    { lat: 48.44228, lng: 18.3546142 },
    { lat: 48.4420485, lng: 18.3547677 },
    { lat: 48.4417196, lng: 18.3548587 },
    { lat: 48.4413998, lng: 18.3555172 },
    { lat: 48.4412584, lng: 18.3552029 },
    { lat: 48.4410152, lng: 18.3556909 },
    { lat: 48.4406998, lng: 18.355762 },
    { lat: 48.4402507, lng: 18.3556793 },
    { lat: 48.4398336, lng: 18.3556863 },
    { lat: 48.4397434, lng: 18.3557472 },
    { lat: 48.4396011, lng: 18.3556424 },
    { lat: 48.4390281, lng: 18.356047 },
    { lat: 48.438712, lng: 18.3560041 },
    { lat: 48.4386486, lng: 18.3558409 },
    { lat: 48.4384634, lng: 18.355856 },
    { lat: 48.4383028, lng: 18.3559881 },
    { lat: 48.4381014, lng: 18.3560184 },
    { lat: 48.4380391, lng: 18.3558949 },
    { lat: 48.4378084, lng: 18.3561268 },
    { lat: 48.4376742, lng: 18.3560643 },
    { lat: 48.4375796, lng: 18.3562477 },
    { lat: 48.4376606, lng: 18.3570627 },
    { lat: 48.4375852, lng: 18.3571057 },
    { lat: 48.4366768, lng: 18.3574059 },
    { lat: 48.436619, lng: 18.3573415 },
    { lat: 48.4361491, lng: 18.3575105 },
    { lat: 48.435821, lng: 18.3575104 },
    { lat: 48.4354132, lng: 18.357604 },
    { lat: 48.4353407, lng: 18.3579188 },
    { lat: 48.4351863, lng: 18.3579406 },
    { lat: 48.4350467, lng: 18.3578084 },
    { lat: 48.4348013, lng: 18.3578376 },
    { lat: 48.4347611, lng: 18.3578452 },
    { lat: 48.4347629, lng: 18.3579739 },
    { lat: 48.4340392, lng: 18.3580871 },
    { lat: 48.4339915, lng: 18.3579834 },
    { lat: 48.433524, lng: 18.357973 },
    { lat: 48.433168, lng: 18.3582412 },
    { lat: 48.4332617, lng: 18.3589046 },
    { lat: 48.4317103, lng: 18.3588995 },
    { lat: 48.4318286, lng: 18.3602973 },
    { lat: 48.4297011, lng: 18.3614458 },
    { lat: 48.4292275, lng: 18.3618461 },
    { lat: 48.429228, lng: 18.3616969 },
    { lat: 48.4288651, lng: 18.3618049 },
    { lat: 48.4286369, lng: 18.3618006 },
    { lat: 48.4278581, lng: 18.3620348 },
    { lat: 48.4275297, lng: 18.3622734 },
    { lat: 48.4267588, lng: 18.3625041 },
    { lat: 48.4265852, lng: 18.3626491 },
    { lat: 48.4264589, lng: 18.3628492 },
    { lat: 48.4264569, lng: 18.3635524 },
    { lat: 48.4262069, lng: 18.3640816 },
    { lat: 48.4261415, lng: 18.3643439 },
    { lat: 48.4260125, lng: 18.3646168 },
    { lat: 48.4259, lng: 18.3647398 },
    { lat: 48.4256752, lng: 18.365335 },
    { lat: 48.4259144, lng: 18.3657575 },
    { lat: 48.4260728, lng: 18.3658254 },
    { lat: 48.4266478, lng: 18.3657593 },
    { lat: 48.4267676, lng: 18.3663021 },
    { lat: 48.4266599, lng: 18.3664931 },
    { lat: 48.4267655, lng: 18.3671457 },
    { lat: 48.4269111, lng: 18.3674046 },
    { lat: 48.427117, lng: 18.3676153 },
    { lat: 48.4275196, lng: 18.3679328 },
    { lat: 48.4277648, lng: 18.3679421 },
    { lat: 48.4280042, lng: 18.3682246 },
    { lat: 48.4278898, lng: 18.3685944 },
    { lat: 48.428191, lng: 18.3685578 },
    { lat: 48.4282675, lng: 18.3686316 },
    { lat: 48.4283556, lng: 18.3688936 },
    { lat: 48.4288322, lng: 18.3694844 },
    { lat: 48.4300127, lng: 18.3704934 },
    { lat: 48.4301356, lng: 18.3707307 },
    { lat: 48.4302091, lng: 18.3712347 },
    { lat: 48.43049, lng: 18.3717564 },
    { lat: 48.4309658, lng: 18.3733687 },
  ],
  Žikava: [
    { lat: 48.501088, lng: 18.349817 },
    { lat: 48.500618, lng: 18.349329 },
    { lat: 48.500434, lng: 18.349139 },
    { lat: 48.500184, lng: 18.348901 },
    { lat: 48.500086, lng: 18.348671 },
    { lat: 48.499948, lng: 18.348441 },
    { lat: 48.499795, lng: 18.348353 },
    { lat: 48.499415, lng: 18.348332 },
    { lat: 48.499285, lng: 18.348337 },
    { lat: 48.498822, lng: 18.348356 },
    { lat: 48.498307, lng: 18.348375 },
    { lat: 48.49813, lng: 18.34837 },
    { lat: 48.497802, lng: 18.348254 },
    { lat: 48.497452, lng: 18.348182 },
    { lat: 48.497179, lng: 18.348141 },
    { lat: 48.497059, lng: 18.348083 },
    { lat: 48.496933, lng: 18.347956 },
    { lat: 48.496836, lng: 18.347846 },
    { lat: 48.49673, lng: 18.347678 },
    { lat: 48.496666, lng: 18.347601 },
    { lat: 48.496638, lng: 18.347583 },
    { lat: 48.496484, lng: 18.347488 },
    { lat: 48.496246, lng: 18.347443 },
    { lat: 48.496096, lng: 18.347477 },
    { lat: 48.495945, lng: 18.347547 },
    { lat: 48.49578, lng: 18.347699 },
    { lat: 48.495651, lng: 18.347756 },
    { lat: 48.495557, lng: 18.347841 },
    { lat: 48.495487, lng: 18.347961 },
    { lat: 48.495376, lng: 18.348111 },
    { lat: 48.495165, lng: 18.348155 },
    { lat: 48.494679, lng: 18.348059 },
    { lat: 48.49442, lng: 18.347789 },
    { lat: 48.494112, lng: 18.347511 },
    { lat: 48.493961, lng: 18.347466 },
    { lat: 48.493813, lng: 18.347469 },
    { lat: 48.493705, lng: 18.347384 },
    { lat: 48.4912334, lng: 18.3536324 },
    { lat: 48.4909491, lng: 18.3541496 },
    { lat: 48.4906352, lng: 18.354567 },
    { lat: 48.4905361, lng: 18.3547707 },
    { lat: 48.4905118, lng: 18.3549818 },
    { lat: 48.4900637, lng: 18.355755 },
    { lat: 48.4896846, lng: 18.3562356 },
    { lat: 48.4896956, lng: 18.3565521 },
    { lat: 48.4891378, lng: 18.3574848 },
    { lat: 48.4890206, lng: 18.3575449 },
    { lat: 48.4882283, lng: 18.3573 },
    { lat: 48.4882605, lng: 18.3570487 },
    { lat: 48.4880699, lng: 18.3568937 },
    { lat: 48.48791, lng: 18.3562171 },
    { lat: 48.4877561, lng: 18.3562093 },
    { lat: 48.4877161, lng: 18.3564225 },
    { lat: 48.4875879, lng: 18.3566263 },
    { lat: 48.4871616, lng: 18.3570321 },
    { lat: 48.4867735, lng: 18.3571296 },
    { lat: 48.486483, lng: 18.3575625 },
    { lat: 48.4865722, lng: 18.3577827 },
    { lat: 48.4864062, lng: 18.3582378 },
    { lat: 48.4855645, lng: 18.3591802 },
    { lat: 48.4856611, lng: 18.3594133 },
    { lat: 48.4855511, lng: 18.3596798 },
    { lat: 48.485082, lng: 18.3604677 },
    { lat: 48.4847769, lng: 18.3607384 },
    { lat: 48.484294, lng: 18.360879 },
    { lat: 48.4840214, lng: 18.361083 },
    { lat: 48.4839436, lng: 18.3614273 },
    { lat: 48.4837714, lng: 18.3616359 },
    { lat: 48.4831025, lng: 18.361915 },
    { lat: 48.4821415, lng: 18.3620995 },
    { lat: 48.4813377, lng: 18.3620501 },
    { lat: 48.4799457, lng: 18.3625533 },
    { lat: 48.4794803, lng: 18.3625839 },
    { lat: 48.4787483, lng: 18.3628479 },
    { lat: 48.4785709, lng: 18.3628036 },
    { lat: 48.4782898, lng: 18.3633032 },
    { lat: 48.478064, lng: 18.3634205 },
    { lat: 48.4777273, lng: 18.3637504 },
    { lat: 48.4770722, lng: 18.3642385 },
    { lat: 48.4767221, lng: 18.3646533 },
    { lat: 48.4762343, lng: 18.3649242 },
    { lat: 48.476006, lng: 18.3649764 },
    { lat: 48.4758435, lng: 18.3648784 },
    { lat: 48.4757639, lng: 18.3647036 },
    { lat: 48.4755658, lng: 18.3644732 },
    { lat: 48.4753695, lng: 18.3643017 },
    { lat: 48.4748467, lng: 18.3638448 },
    { lat: 48.4742511, lng: 18.3635883 },
    { lat: 48.473887, lng: 18.3637936 },
    { lat: 48.4734278, lng: 18.363049 },
    { lat: 48.472835, lng: 18.3623004 },
    { lat: 48.472141, lng: 18.3620006 },
    { lat: 48.4719072, lng: 18.3637751 },
    { lat: 48.4689175, lng: 18.3662793 },
    { lat: 48.4681985, lng: 18.3664338 },
    { lat: 48.4676033, lng: 18.3667634 },
    { lat: 48.4672315, lng: 18.3666306 },
    { lat: 48.4668502, lng: 18.3665935 },
    { lat: 48.4665387, lng: 18.3667288 },
    { lat: 48.4661964, lng: 18.3667798 },
    { lat: 48.4661215, lng: 18.3666557 },
    { lat: 48.4660147, lng: 18.366622 },
    { lat: 48.4657848, lng: 18.3666673 },
    { lat: 48.4655859, lng: 18.3665984 },
    { lat: 48.4654023, lng: 18.366865 },
    { lat: 48.4650976, lng: 18.3671416 },
    { lat: 48.4648392, lng: 18.367284 },
    { lat: 48.4645846, lng: 18.3673694 },
    { lat: 48.4644338, lng: 18.3673353 },
    { lat: 48.4642216, lng: 18.3674551 },
    { lat: 48.4640199, lng: 18.3670389 },
    { lat: 48.4636342, lng: 18.3674819 },
    { lat: 48.4633573, lng: 18.3675524 },
    { lat: 48.463406, lng: 18.3677584 },
    { lat: 48.4630695, lng: 18.3679705 },
    { lat: 48.4631021, lng: 18.3680922 },
    { lat: 48.4626221, lng: 18.3684434 },
    { lat: 48.4621174, lng: 18.3654726 },
    { lat: 48.4541862, lng: 18.3677143 },
    { lat: 48.4517931, lng: 18.3684715 },
    { lat: 48.4520239, lng: 18.3713102 },
    { lat: 48.4515277, lng: 18.371814 },
    { lat: 48.451352, lng: 18.3716594 },
    { lat: 48.4482136, lng: 18.372365 },
    { lat: 48.4453138, lng: 18.3727591 },
    { lat: 48.4453137, lng: 18.3729763 },
    { lat: 48.4384999, lng: 18.3730502 },
    { lat: 48.4380829, lng: 18.3729756 },
    { lat: 48.4364001, lng: 18.3730049 },
    { lat: 48.4361461, lng: 18.3709801 },
    { lat: 48.4356312, lng: 18.371074 },
    { lat: 48.435577, lng: 18.3706698 },
    { lat: 48.4337759, lng: 18.3711636 },
    { lat: 48.4309658, lng: 18.3733687 },
    { lat: 48.4309766, lng: 18.3734022 },
    { lat: 48.4294939, lng: 18.3742603 },
    { lat: 48.4286237, lng: 18.3749948 },
    { lat: 48.4282763, lng: 18.3751576 },
    { lat: 48.4276476, lng: 18.3753323 },
    { lat: 48.4273573, lng: 18.3755049 },
    { lat: 48.4260541, lng: 18.3765664 },
    { lat: 48.426081, lng: 18.3766031 },
    { lat: 48.4270594, lng: 18.3787295 },
    { lat: 48.426492, lng: 18.3789142 },
    { lat: 48.4262265, lng: 18.3792666 },
    { lat: 48.4259596, lng: 18.3793555 },
    { lat: 48.4257058, lng: 18.3795391 },
    { lat: 48.4258311, lng: 18.3814047 },
    { lat: 48.4259553, lng: 18.3823683 },
    { lat: 48.4257648, lng: 18.3826926 },
    { lat: 48.4255685, lng: 18.3828713 },
    { lat: 48.4253739, lng: 18.3828634 },
    { lat: 48.4253675, lng: 18.3827484 },
    { lat: 48.4250534, lng: 18.3828007 },
    { lat: 48.4246345, lng: 18.3838783 },
    { lat: 48.4244554, lng: 18.385049 },
    { lat: 48.4243452, lng: 18.385312 },
    { lat: 48.4244396, lng: 18.38629 },
    { lat: 48.4248076, lng: 18.3866372 },
    { lat: 48.4252505, lng: 18.3864286 },
    { lat: 48.4254282, lng: 18.3864981 },
    { lat: 48.4257421, lng: 18.386481 },
    { lat: 48.4260686, lng: 18.3866108 },
    { lat: 48.4258525, lng: 18.3870612 },
    { lat: 48.425998, lng: 18.3871 },
    { lat: 48.4261941, lng: 18.3869177 },
    { lat: 48.4263831, lng: 18.3874074 },
    { lat: 48.4261997, lng: 18.3875774 },
    { lat: 48.4261188, lng: 18.3878445 },
    { lat: 48.425985, lng: 18.3880131 },
    { lat: 48.4258347, lng: 18.3880474 },
    { lat: 48.4257047, lng: 18.3879652 },
    { lat: 48.4255955, lng: 18.3881083 },
    { lat: 48.4265941, lng: 18.3905403 },
    { lat: 48.427652, lng: 18.3897895 },
    { lat: 48.4287579, lng: 18.3895963 },
    { lat: 48.4287995, lng: 18.3908368 },
    { lat: 48.428729, lng: 18.3917921 },
    { lat: 48.4288404, lng: 18.3917289 },
    { lat: 48.4290991, lng: 18.3917942 },
    { lat: 48.4293712, lng: 18.3920389 },
    { lat: 48.4296467, lng: 18.3920654 },
    { lat: 48.4298117, lng: 18.3922715 },
    { lat: 48.4298976, lng: 18.3928734 },
    { lat: 48.4300776, lng: 18.3932676 },
    { lat: 48.4306504, lng: 18.3936032 },
    { lat: 48.4310969, lng: 18.3933786 },
    { lat: 48.4312347, lng: 18.393198 },
    { lat: 48.4314978, lng: 18.3931039 },
    { lat: 48.4322199, lng: 18.3931865 },
    { lat: 48.4325498, lng: 18.3933155 },
    { lat: 48.4327761, lng: 18.3934837 },
    { lat: 48.4330833, lng: 18.3930482 },
    { lat: 48.4334566, lng: 18.3930489 },
    { lat: 48.4336033, lng: 18.3932271 },
    { lat: 48.4338265, lng: 18.3932279 },
    { lat: 48.4339314, lng: 18.3929826 },
    { lat: 48.4339094, lng: 18.3927343 },
    { lat: 48.4339988, lng: 18.3924558 },
    { lat: 48.4342615, lng: 18.3925123 },
    { lat: 48.4343064, lng: 18.3928523 },
    { lat: 48.4344551, lng: 18.3930303 },
    { lat: 48.434566, lng: 18.3928532 },
    { lat: 48.4348738, lng: 18.3926884 },
    { lat: 48.4349413, lng: 18.3925264 },
    { lat: 48.4349259, lng: 18.3920512 },
    { lat: 48.4349761, lng: 18.3919002 },
    { lat: 48.4351837, lng: 18.3917577 },
    { lat: 48.4353434, lng: 18.3924118 },
    { lat: 48.4353872, lng: 18.39243 },
    { lat: 48.4356487, lng: 18.3921443 },
    { lat: 48.4361837, lng: 18.3922672 },
    { lat: 48.4364433, lng: 18.3919099 },
    { lat: 48.4365161, lng: 18.392002 },
    { lat: 48.4365325, lng: 18.3921715 },
    { lat: 48.4366671, lng: 18.3923214 },
    { lat: 48.4367766, lng: 18.3925999 },
    { lat: 48.4368683, lng: 18.3926657 },
    { lat: 48.437045, lng: 18.3930434 },
    { lat: 48.4383722, lng: 18.3926673 },
    { lat: 48.4384642, lng: 18.3926769 },
    { lat: 48.4384955, lng: 18.393234 },
    { lat: 48.4397289, lng: 18.3928992 },
    { lat: 48.4402178, lng: 18.3928646 },
    { lat: 48.4402424, lng: 18.3939271 },
    { lat: 48.4402039, lng: 18.3945299 },
    { lat: 48.440246, lng: 18.3954992 },
    { lat: 48.4406006, lng: 18.3956877 },
    { lat: 48.440789, lng: 18.399644 },
    { lat: 48.4408095, lng: 18.3998919 },
    { lat: 48.4408715, lng: 18.3998945 },
    { lat: 48.4409202, lng: 18.3998778 },
    { lat: 48.4410418, lng: 18.3998363 },
    { lat: 48.4448798, lng: 18.3985242 },
    { lat: 48.4449611, lng: 18.3987677 },
    { lat: 48.4449825, lng: 18.3993307 },
    { lat: 48.4450794, lng: 18.3994065 },
    { lat: 48.4450392, lng: 18.400097 },
    { lat: 48.445486, lng: 18.4008219 },
    { lat: 48.4453081, lng: 18.4010306 },
    { lat: 48.4452302, lng: 18.4013105 },
    { lat: 48.4449447, lng: 18.4013712 },
    { lat: 48.4443153, lng: 18.4016534 },
    { lat: 48.4440452, lng: 18.4019502 },
    { lat: 48.4444383, lng: 18.4027835 },
    { lat: 48.4445177, lng: 18.4028089 },
    { lat: 48.4443687, lng: 18.4029291 },
    { lat: 48.4442286, lng: 18.4033068 },
    { lat: 48.4440941, lng: 18.403526 },
    { lat: 48.4438029, lng: 18.4038233 },
    { lat: 48.4434643, lng: 18.4039013 },
    { lat: 48.4432702, lng: 18.4040403 },
    { lat: 48.443354, lng: 18.4045698 },
    { lat: 48.4432506, lng: 18.4046198 },
    { lat: 48.4430074, lng: 18.4041055 },
    { lat: 48.4426628, lng: 18.404217 },
    { lat: 48.4419814, lng: 18.4048195 },
    { lat: 48.4429119, lng: 18.4072534 },
    { lat: 48.443243, lng: 18.4068807 },
    { lat: 48.4442595, lng: 18.4061963 },
    { lat: 48.4446525, lng: 18.4060407 },
    { lat: 48.4450845, lng: 18.4060006 },
    { lat: 48.4456101, lng: 18.4065797 },
    { lat: 48.4456552, lng: 18.4065623 },
    { lat: 48.4459998, lng: 18.4071732 },
    { lat: 48.4463846, lng: 18.4072358 },
    { lat: 48.4467469, lng: 18.4071406 },
    { lat: 48.4467574, lng: 18.4067618 },
    { lat: 48.4473553, lng: 18.4075046 },
    { lat: 48.4482493, lng: 18.407175 },
    { lat: 48.4489858, lng: 18.4067244 },
    { lat: 48.4489653, lng: 18.4063424 },
    { lat: 48.449616, lng: 18.4051663 },
    { lat: 48.4497458, lng: 18.4044452 },
    { lat: 48.4490632, lng: 18.4042416 },
    { lat: 48.4489759, lng: 18.404113 },
    { lat: 48.4490837, lng: 18.4019494 },
    { lat: 48.449755, lng: 18.4015886 },
    { lat: 48.4500365, lng: 18.4023216 },
    { lat: 48.4513992, lng: 18.4019984 },
    { lat: 48.4536451, lng: 18.4016898 },
    { lat: 48.4537764, lng: 18.402363 },
    { lat: 48.4542377, lng: 18.4024606 },
    { lat: 48.4544314, lng: 18.402698 },
    { lat: 48.4561866, lng: 18.4009208 },
    { lat: 48.4568639, lng: 18.3991197 },
    { lat: 48.4593159, lng: 18.3983814 },
    { lat: 48.4616883, lng: 18.395723 },
    { lat: 48.4627897, lng: 18.3948838 },
    { lat: 48.4637149, lng: 18.3939001 },
    { lat: 48.4655964, lng: 18.3928105 },
    { lat: 48.4659956, lng: 18.3920847 },
    { lat: 48.4665125, lng: 18.390861 },
    { lat: 48.4665262, lng: 18.3903427 },
    { lat: 48.4666938, lng: 18.3894867 },
    { lat: 48.4678848, lng: 18.3875914 },
    { lat: 48.4680804, lng: 18.3866174 },
    { lat: 48.4684149, lng: 18.3862845 },
    { lat: 48.469094, lng: 18.3847971 },
    { lat: 48.4693592, lng: 18.383752 },
    { lat: 48.4696455, lng: 18.3828482 },
    { lat: 48.4697925, lng: 18.3820473 },
    { lat: 48.469873, lng: 18.3818945 },
    { lat: 48.469964, lng: 18.3811246 },
    { lat: 48.4700847, lng: 18.3805842 },
    { lat: 48.4702222, lng: 18.3801575 },
    { lat: 48.4706902, lng: 18.3792344 },
    { lat: 48.4715413, lng: 18.378469 },
    { lat: 48.4766782, lng: 18.3738491 },
    { lat: 48.4768438, lng: 18.3735656 },
    { lat: 48.4775419, lng: 18.3717174 },
    { lat: 48.4782484, lng: 18.3704376 },
    { lat: 48.4786719, lng: 18.3702845 },
    { lat: 48.4791206, lng: 18.370221 },
    { lat: 48.4799307, lng: 18.3701612 },
    { lat: 48.4803449, lng: 18.370203 },
    { lat: 48.480911, lng: 18.3700993 },
    { lat: 48.4819842, lng: 18.3697414 },
    { lat: 48.4822365, lng: 18.3695524 },
    { lat: 48.4828774, lng: 18.3688766 },
    { lat: 48.4831881, lng: 18.3683235 },
    { lat: 48.483509, lng: 18.3681684 },
    { lat: 48.484455, lng: 18.3671179 },
    { lat: 48.4851706, lng: 18.3665637 },
    { lat: 48.485683, lng: 18.366031 },
    { lat: 48.485824, lng: 18.365866 },
    { lat: 48.4859821, lng: 18.3656946 },
    { lat: 48.486133, lng: 18.365531 },
    { lat: 48.486271, lng: 18.365381 },
    { lat: 48.486431, lng: 18.36513 },
    { lat: 48.486684, lng: 18.364918 },
    { lat: 48.486853, lng: 18.364805 },
    { lat: 48.487109, lng: 18.364659 },
    { lat: 48.487263, lng: 18.36444 },
    { lat: 48.487621, lng: 18.364321 },
    { lat: 48.48767, lng: 18.364291 },
    { lat: 48.487756, lng: 18.364256 },
    { lat: 48.487959, lng: 18.364109 },
    { lat: 48.488222, lng: 18.364005 },
    { lat: 48.488521, lng: 18.36392 },
    { lat: 48.488739, lng: 18.363802 },
    { lat: 48.488904, lng: 18.363687 },
    { lat: 48.489318, lng: 18.363516 },
    { lat: 48.489705, lng: 18.363351 },
    { lat: 48.489926, lng: 18.36319 },
    { lat: 48.490158, lng: 18.363068 },
    { lat: 48.490201, lng: 18.363037 },
    { lat: 48.490307, lng: 18.362928 },
    { lat: 48.490341, lng: 18.362817 },
    { lat: 48.490349, lng: 18.362693 },
    { lat: 48.490322, lng: 18.362463 },
    { lat: 48.490206, lng: 18.362031 },
    { lat: 48.490188, lng: 18.361818 },
    { lat: 48.490276, lng: 18.361203 },
    { lat: 48.49034, lng: 18.360808 },
    { lat: 48.490412, lng: 18.360461 },
    { lat: 48.490432, lng: 18.360381 },
    { lat: 48.490506, lng: 18.360154 },
    { lat: 48.490646, lng: 18.359744 },
    { lat: 48.490714, lng: 18.359483 },
    { lat: 48.490814, lng: 18.359263 },
    { lat: 48.490893, lng: 18.359136 },
    { lat: 48.490964, lng: 18.359039 },
    { lat: 48.491214, lng: 18.358726 },
    { lat: 48.491327, lng: 18.35856 },
    { lat: 48.491443, lng: 18.358083 },
    { lat: 48.49151, lng: 18.357611 },
    { lat: 48.4915979, lng: 18.3570497 },
    { lat: 48.491638, lng: 18.356753 },
    { lat: 48.491639, lng: 18.356478 },
    { lat: 48.491607, lng: 18.356194 },
    { lat: 48.491481, lng: 18.355797 },
    { lat: 48.49142, lng: 18.355699 },
    { lat: 48.491383, lng: 18.355462 },
    { lat: 48.491392, lng: 18.355324 },
    { lat: 48.491404, lng: 18.355262 },
    { lat: 48.491431, lng: 18.355142 },
    { lat: 48.491521, lng: 18.354834 },
    { lat: 48.491689, lng: 18.354392 },
    { lat: 48.491766, lng: 18.354134 },
    { lat: 48.491852, lng: 18.353842 },
    { lat: 48.492173, lng: 18.353432 },
    { lat: 48.492355, lng: 18.353216 },
    { lat: 48.492462, lng: 18.353098 },
    { lat: 48.492835, lng: 18.352773 },
    { lat: 48.492982, lng: 18.352738 },
    { lat: 48.493341, lng: 18.352649 },
    { lat: 48.493625, lng: 18.352409 },
    { lat: 48.494168, lng: 18.351931 },
    { lat: 48.49444, lng: 18.351832 },
    { lat: 48.494751, lng: 18.35171 },
    { lat: 48.494979, lng: 18.351516 },
    { lat: 48.495411, lng: 18.351336 },
    { lat: 48.495685, lng: 18.351105 },
    { lat: 48.495916, lng: 18.350905 },
    { lat: 48.496149, lng: 18.350798 },
    { lat: 48.496346, lng: 18.350823 },
    { lat: 48.496556, lng: 18.350749 },
    { lat: 48.496883, lng: 18.350689 },
    { lat: 48.496961, lng: 18.350704 },
    { lat: 48.497143, lng: 18.35077 },
    { lat: 48.497465, lng: 18.35089 },
    { lat: 48.497845, lng: 18.351098 },
    { lat: 48.497937, lng: 18.351141 },
    { lat: 48.498392, lng: 18.351344 },
    { lat: 48.498555, lng: 18.351364 },
    { lat: 48.49888, lng: 18.351327 },
    { lat: 48.499223, lng: 18.351225 },
    { lat: 48.499693, lng: 18.351088 },
    { lat: 48.500099, lng: 18.35095 },
    { lat: 48.50029, lng: 18.350875 },
    { lat: 48.500358, lng: 18.350835 },
    { lat: 48.500513, lng: 18.350666 },
    { lat: 48.500808, lng: 18.350256 },
    { lat: 48.501088, lng: 18.349817 },
  ],
  Beladice: [
    { lat: 48.3589446, lng: 18.2945725 },
    { lat: 48.3587232, lng: 18.2941518 },
    { lat: 48.3577927, lng: 18.2921431 },
    { lat: 48.3572241, lng: 18.2906825 },
    { lat: 48.3574603, lng: 18.2904755 },
    { lat: 48.3543495, lng: 18.2842548 },
    { lat: 48.3537962, lng: 18.2846017 },
    { lat: 48.3509601, lng: 18.2768413 },
    { lat: 48.3503084, lng: 18.2764354 },
    { lat: 48.3501417, lng: 18.2764615 },
    { lat: 48.3500377, lng: 18.2763419 },
    { lat: 48.3496293, lng: 18.2764403 },
    { lat: 48.3515587, lng: 18.2709263 },
    { lat: 48.3518672, lng: 18.2702231 },
    { lat: 48.3530038, lng: 18.2682229 },
    { lat: 48.352729, lng: 18.267898 },
    { lat: 48.352666, lng: 18.267963 },
    { lat: 48.352652, lng: 18.267951 },
    { lat: 48.351833, lng: 18.267298 },
    { lat: 48.351499, lng: 18.267073 },
    { lat: 48.351464, lng: 18.26705 },
    { lat: 48.351174, lng: 18.266855 },
    { lat: 48.35081, lng: 18.266609 },
    { lat: 48.35034, lng: 18.266358 },
    { lat: 48.350298, lng: 18.266328 },
    { lat: 48.349986, lng: 18.26611 },
    { lat: 48.3496, lng: 18.265822 },
    { lat: 48.349571, lng: 18.265783 },
    { lat: 48.350603, lng: 18.263726 },
    { lat: 48.351259, lng: 18.262418 },
    { lat: 48.351451, lng: 18.262018 },
    { lat: 48.352399, lng: 18.260106 },
    { lat: 48.3522, lng: 18.259781 },
    { lat: 48.351818, lng: 18.259489 },
    { lat: 48.351661, lng: 18.259447 },
    { lat: 48.351227, lng: 18.25939 },
    { lat: 48.351086, lng: 18.259361 },
    { lat: 48.350783, lng: 18.259258 },
    { lat: 48.350322, lng: 18.258985 },
    { lat: 48.350068, lng: 18.258755 },
    { lat: 48.349404, lng: 18.258143 },
    { lat: 48.348991, lng: 18.257779 },
    { lat: 48.348631, lng: 18.25746 },
    { lat: 48.348374, lng: 18.257245 },
    { lat: 48.348, lng: 18.257038 },
    { lat: 48.347799, lng: 18.256899 },
    { lat: 48.347642, lng: 18.256717 },
    { lat: 48.347493, lng: 18.25651 },
    { lat: 48.34726, lng: 18.256173 },
    { lat: 48.347095, lng: 18.255919 },
    { lat: 48.346844, lng: 18.255549 },
    { lat: 48.346637, lng: 18.255232 },
    { lat: 48.346394, lng: 18.254885 },
    { lat: 48.346274, lng: 18.254734 },
    { lat: 48.345832, lng: 18.254313 },
    { lat: 48.345368, lng: 18.253836 },
    { lat: 48.344612, lng: 18.253084 },
    { lat: 48.344246, lng: 18.25272 },
    { lat: 48.343976, lng: 18.252439 },
    { lat: 48.343783, lng: 18.25223 },
    { lat: 48.343424, lng: 18.251886 },
    { lat: 48.34307, lng: 18.251541 },
    { lat: 48.34283, lng: 18.251303 },
    { lat: 48.342625, lng: 18.251109 },
    { lat: 48.342612, lng: 18.251156 },
    { lat: 48.342607, lng: 18.251205 },
    { lat: 48.342624, lng: 18.251211 },
    { lat: 48.342638, lng: 18.251286 },
    { lat: 48.342629, lng: 18.251393 },
    { lat: 48.34258, lng: 18.251426 },
    { lat: 48.342574, lng: 18.251484 },
    { lat: 48.342571, lng: 18.251733 },
    { lat: 48.342524, lng: 18.251765 },
    { lat: 48.342495, lng: 18.251929 },
    { lat: 48.342409, lng: 18.252024 },
    { lat: 48.342417, lng: 18.25208 },
    { lat: 48.342379, lng: 18.252123 },
    { lat: 48.342361, lng: 18.252273 },
    { lat: 48.342342, lng: 18.252398 },
    { lat: 48.342293, lng: 18.252508 },
    { lat: 48.34218, lng: 18.252734 },
    { lat: 48.342111, lng: 18.252831 },
    { lat: 48.341732, lng: 18.252735 },
    { lat: 48.341628, lng: 18.252287 },
    { lat: 48.341615, lng: 18.252187 },
    { lat: 48.341499, lng: 18.251502 },
    { lat: 48.341336, lng: 18.251382 },
    { lat: 48.341038, lng: 18.25117 },
    { lat: 48.340523, lng: 18.250966 },
    { lat: 48.340625, lng: 18.250627 },
    { lat: 48.34065, lng: 18.250329 },
    { lat: 48.340634, lng: 18.249933 },
    { lat: 48.340624, lng: 18.249637 },
    { lat: 48.340624, lng: 18.249136 },
    { lat: 48.340645, lng: 18.248823 },
    { lat: 48.340664, lng: 18.248502 },
    { lat: 48.340666, lng: 18.248339 },
    { lat: 48.340662, lng: 18.247944 },
    { lat: 48.34074, lng: 18.247496 },
    { lat: 48.340755, lng: 18.247375 },
    { lat: 48.340889, lng: 18.246966 },
    { lat: 48.341178, lng: 18.246291 },
    { lat: 48.341261, lng: 18.245882 },
    { lat: 48.34082, lng: 18.245708 },
    { lat: 48.340319, lng: 18.245499 },
    { lat: 48.339886, lng: 18.245325 },
    { lat: 48.339841, lng: 18.245285 },
    { lat: 48.33972, lng: 18.245175 },
    { lat: 48.339324, lng: 18.244854 },
    { lat: 48.338795, lng: 18.244511 },
    { lat: 48.338357, lng: 18.244263 },
    { lat: 48.338036, lng: 18.24411 },
    { lat: 48.33771, lng: 18.243944 },
    { lat: 48.337396, lng: 18.243806 },
    { lat: 48.33709, lng: 18.244299 },
    { lat: 48.336819, lng: 18.244741 },
    { lat: 48.336773, lng: 18.24481 },
    { lat: 48.336625, lng: 18.245174 },
    { lat: 48.336521, lng: 18.245388 },
    { lat: 48.336506, lng: 18.245418 },
    { lat: 48.336469, lng: 18.24549 },
    { lat: 48.336452, lng: 18.245528 },
    { lat: 48.336423, lng: 18.245568 },
    { lat: 48.33623, lng: 18.245835 },
    { lat: 48.335885, lng: 18.246305 },
    { lat: 48.335457, lng: 18.246908 },
    { lat: 48.335195, lng: 18.247172 },
    { lat: 48.334915, lng: 18.247433 },
    { lat: 48.334627, lng: 18.24769 },
    { lat: 48.334492, lng: 18.247836 },
    { lat: 48.334224, lng: 18.248254 },
    { lat: 48.333919, lng: 18.248745 },
    { lat: 48.333471, lng: 18.249878 },
    { lat: 48.333029, lng: 18.250434 },
    { lat: 48.332794, lng: 18.250852 },
    { lat: 48.332563, lng: 18.251257 },
    { lat: 48.332305, lng: 18.251693 },
    { lat: 48.331988, lng: 18.252263 },
    { lat: 48.331677, lng: 18.252909 },
    { lat: 48.331324, lng: 18.253479 },
    { lat: 48.331016, lng: 18.254059 },
    { lat: 48.33098, lng: 18.25401 },
    { lat: 48.330732, lng: 18.254517 },
    { lat: 48.330359, lng: 18.255264 },
    { lat: 48.330122, lng: 18.255784 },
    { lat: 48.329964, lng: 18.256339 },
    { lat: 48.329815, lng: 18.256815 },
    { lat: 48.329672, lng: 18.257339 },
    { lat: 48.329614, lng: 18.257546 },
    { lat: 48.329526, lng: 18.257876 },
    { lat: 48.32948, lng: 18.258174 },
    { lat: 48.329416, lng: 18.258423 },
    { lat: 48.32926, lng: 18.258912 },
    { lat: 48.329157, lng: 18.25917 },
    { lat: 48.329017, lng: 18.259446 },
    { lat: 48.328924, lng: 18.259625 },
    { lat: 48.328758, lng: 18.259868 },
    { lat: 48.328615, lng: 18.260099 },
    { lat: 48.328478, lng: 18.260383 },
    { lat: 48.328383, lng: 18.260591 },
    { lat: 48.328253, lng: 18.261036 },
    { lat: 48.32817, lng: 18.261255 },
    { lat: 48.328044, lng: 18.261601 },
    { lat: 48.328003, lng: 18.261584 },
    { lat: 48.327602, lng: 18.261199 },
    { lat: 48.327481, lng: 18.261344 },
    { lat: 48.327435, lng: 18.261392 },
    { lat: 48.327274, lng: 18.261518 },
    { lat: 48.327051, lng: 18.261655 },
    { lat: 48.327031, lng: 18.261665 },
    { lat: 48.326785, lng: 18.261771 },
    { lat: 48.326767, lng: 18.261756 },
    { lat: 48.326673, lng: 18.261639 },
    { lat: 48.326367, lng: 18.26126 },
    { lat: 48.326238, lng: 18.261098 },
    { lat: 48.326131, lng: 18.260963 },
    { lat: 48.326033, lng: 18.260841 },
    { lat: 48.325946, lng: 18.260731 },
    { lat: 48.325823, lng: 18.260577 },
    { lat: 48.325786, lng: 18.26053 },
    { lat: 48.325674, lng: 18.260384 },
    { lat: 48.325545, lng: 18.260212 },
    { lat: 48.3254, lng: 18.260024 },
    { lat: 48.325266, lng: 18.259847 },
    { lat: 48.324836, lng: 18.259282 },
    { lat: 48.324795, lng: 18.259218 },
    { lat: 48.324654, lng: 18.259416 },
    { lat: 48.324435, lng: 18.259704 },
    { lat: 48.324325, lng: 18.259851 },
    { lat: 48.324217, lng: 18.259987 },
    { lat: 48.324019, lng: 18.26023 },
    { lat: 48.323756, lng: 18.260501 },
    { lat: 48.323473, lng: 18.260793 },
    { lat: 48.323365, lng: 18.260899 },
    { lat: 48.323181, lng: 18.261063 },
    { lat: 48.322979, lng: 18.261236 },
    { lat: 48.322943, lng: 18.261269 },
    { lat: 48.322834, lng: 18.261365 },
    { lat: 48.322771, lng: 18.261418 },
    { lat: 48.322453, lng: 18.261719 },
    { lat: 48.322223, lng: 18.261951 },
    { lat: 48.322049, lng: 18.262092 },
    { lat: 48.321802, lng: 18.262237 },
    { lat: 48.321775, lng: 18.262253 },
    { lat: 48.321584, lng: 18.262324 },
    { lat: 48.321559, lng: 18.262343 },
    { lat: 48.321294, lng: 18.262473 },
    { lat: 48.321139, lng: 18.262544 },
    { lat: 48.321008, lng: 18.262608 },
    { lat: 48.320934, lng: 18.262656 },
    { lat: 48.32082, lng: 18.262725 },
    { lat: 48.320598, lng: 18.262865 },
    { lat: 48.320491, lng: 18.262935 },
    { lat: 48.32026, lng: 18.263105 },
    { lat: 48.320148, lng: 18.263188 },
    { lat: 48.319877, lng: 18.263353 },
    { lat: 48.319595, lng: 18.263528 },
    { lat: 48.319239, lng: 18.263806 },
    { lat: 48.319063, lng: 18.263925 },
    { lat: 48.318902, lng: 18.264034 },
    { lat: 48.318793, lng: 18.264108 },
    { lat: 48.318672, lng: 18.264191 },
    { lat: 48.318514, lng: 18.264315 },
    { lat: 48.318356, lng: 18.264419 },
    { lat: 48.318232, lng: 18.264482 },
    { lat: 48.318086, lng: 18.264576 },
    { lat: 48.318058, lng: 18.264599 },
    { lat: 48.317769, lng: 18.264811 },
    { lat: 48.317609, lng: 18.264947 },
    { lat: 48.317387, lng: 18.265193 },
    { lat: 48.317181, lng: 18.265432 },
    { lat: 48.317104, lng: 18.265512 },
    { lat: 48.316883, lng: 18.2657 },
    { lat: 48.316685, lng: 18.265872 },
    { lat: 48.316525, lng: 18.265982 },
    { lat: 48.316292, lng: 18.266044 },
    { lat: 48.31621, lng: 18.266065 },
    { lat: 48.3161, lng: 18.266107 },
    { lat: 48.315823, lng: 18.266335 },
    { lat: 48.315671, lng: 18.266507 },
    { lat: 48.315576, lng: 18.26659 },
    { lat: 48.315473, lng: 18.266692 },
    { lat: 48.315409, lng: 18.26676 },
    { lat: 48.3155, lng: 18.266951 },
    { lat: 48.315514, lng: 18.266988 },
    { lat: 48.315617, lng: 18.267236 },
    { lat: 48.315901, lng: 18.267902 },
    { lat: 48.316184, lng: 18.268565 },
    { lat: 48.316211, lng: 18.268628 },
    { lat: 48.31636, lng: 18.268959 },
    { lat: 48.31647, lng: 18.269219 },
    { lat: 48.316496, lng: 18.269279 },
    { lat: 48.316538, lng: 18.269378 },
    { lat: 48.316627, lng: 18.269597 },
    { lat: 48.316759, lng: 18.269844 },
    { lat: 48.316913, lng: 18.2702 },
    { lat: 48.316929, lng: 18.270187 },
    { lat: 48.317096, lng: 18.270505 },
    { lat: 48.31715, lng: 18.270587 },
    { lat: 48.317197, lng: 18.270661 },
    { lat: 48.31733, lng: 18.270808 },
    { lat: 48.317664, lng: 18.271175 },
    { lat: 48.317928, lng: 18.271343 },
    { lat: 48.318332, lng: 18.271625 },
    { lat: 48.318893, lng: 18.272124 },
    { lat: 48.319109, lng: 18.272319 },
    { lat: 48.319135, lng: 18.272346 },
    { lat: 48.319501, lng: 18.272752 },
    { lat: 48.319673, lng: 18.272953 },
    { lat: 48.319902, lng: 18.27328 },
    { lat: 48.320178, lng: 18.273681 },
    { lat: 48.320191, lng: 18.2737 },
    { lat: 48.320307, lng: 18.273865 },
    { lat: 48.32061, lng: 18.274359 },
    { lat: 48.320844, lng: 18.274774 },
    { lat: 48.321007, lng: 18.275077 },
    { lat: 48.321224, lng: 18.275515 },
    { lat: 48.321335, lng: 18.275695 },
    { lat: 48.321358, lng: 18.275738 },
    { lat: 48.321288, lng: 18.275875 },
    { lat: 48.321245, lng: 18.275951 },
    { lat: 48.321268, lng: 18.275984 },
    { lat: 48.321595, lng: 18.276398 },
    { lat: 48.321757, lng: 18.276545 },
    { lat: 48.322002, lng: 18.276696 },
    { lat: 48.322197, lng: 18.276822 },
    { lat: 48.322499, lng: 18.277017 },
    { lat: 48.322524, lng: 18.277032 },
    { lat: 48.322462, lng: 18.277319 },
    { lat: 48.322439, lng: 18.277427 },
    { lat: 48.322355, lng: 18.277943 },
    { lat: 48.322181, lng: 18.278593 },
    { lat: 48.322103, lng: 18.278899 },
    { lat: 48.32203, lng: 18.279173 },
    { lat: 48.321903, lng: 18.279601 },
    { lat: 48.321861, lng: 18.279679 },
    { lat: 48.321784, lng: 18.279846 },
    { lat: 48.321584, lng: 18.280241 },
    { lat: 48.321542, lng: 18.280333 },
    { lat: 48.321519, lng: 18.280402 },
    { lat: 48.321349, lng: 18.281015 },
    { lat: 48.321165, lng: 18.281646 },
    { lat: 48.32094, lng: 18.282316 },
    { lat: 48.320783, lng: 18.283214 },
    { lat: 48.320629, lng: 18.28367 },
    { lat: 48.320412, lng: 18.284158 },
    { lat: 48.320309, lng: 18.284436 },
    { lat: 48.32011, lng: 18.284836 },
    { lat: 48.320037, lng: 18.28503 },
    { lat: 48.319925, lng: 18.285367 },
    { lat: 48.319859, lng: 18.285521 },
    { lat: 48.319811, lng: 18.28565 },
    { lat: 48.319725, lng: 18.285778 },
    { lat: 48.31959, lng: 18.28609 },
    { lat: 48.319317, lng: 18.286661 },
    { lat: 48.319307, lng: 18.286646 },
    { lat: 48.319247, lng: 18.286745 },
    { lat: 48.318969, lng: 18.287122 },
    { lat: 48.318935, lng: 18.287156 },
    { lat: 48.318893, lng: 18.287191 },
    { lat: 48.318311, lng: 18.287568 },
    { lat: 48.318165, lng: 18.287668 },
    { lat: 48.318065, lng: 18.287728 },
    { lat: 48.318062, lng: 18.287729 },
    { lat: 48.317699, lng: 18.287935 },
    { lat: 48.317474, lng: 18.288089 },
    { lat: 48.316915, lng: 18.288452 },
    { lat: 48.316321, lng: 18.288893 },
    { lat: 48.316313, lng: 18.2889 },
    { lat: 48.315969, lng: 18.289137 },
    { lat: 48.315582, lng: 18.289614 },
    { lat: 48.31554, lng: 18.289667 },
    { lat: 48.315339, lng: 18.28988 },
    { lat: 48.315106, lng: 18.290128 },
    { lat: 48.314882, lng: 18.290278 },
    { lat: 48.314611, lng: 18.290427 },
    { lat: 48.314569, lng: 18.290445 },
    { lat: 48.314567, lng: 18.290446 },
    { lat: 48.314499, lng: 18.290487 },
    { lat: 48.314492, lng: 18.290491 },
    { lat: 48.314471, lng: 18.290508 },
    { lat: 48.313816, lng: 18.290819 },
    { lat: 48.313257, lng: 18.291099 },
    { lat: 48.312974, lng: 18.29124 },
    { lat: 48.312742, lng: 18.291339 },
    { lat: 48.312203, lng: 18.291592 },
    { lat: 48.311718, lng: 18.291824 },
    { lat: 48.311281, lng: 18.292063 },
    { lat: 48.311214, lng: 18.292099 },
    { lat: 48.310962, lng: 18.292244 },
    { lat: 48.311093, lng: 18.292776 },
    { lat: 48.311165, lng: 18.293065 },
    { lat: 48.311308, lng: 18.293658 },
    { lat: 48.311346, lng: 18.293825 },
    { lat: 48.311638, lng: 18.295167 },
    { lat: 48.311678, lng: 18.295342 },
    { lat: 48.311762, lng: 18.295728 },
    { lat: 48.311782, lng: 18.295813 },
    { lat: 48.312056, lng: 18.297015 },
    { lat: 48.312056, lng: 18.297016 },
    { lat: 48.312446, lng: 18.298732 },
    { lat: 48.312632, lng: 18.299533 },
    { lat: 48.312837, lng: 18.300414 },
    { lat: 48.312868, lng: 18.300549 },
    { lat: 48.313308, lng: 18.302517 },
    { lat: 48.313308, lng: 18.302518 },
    { lat: 48.313464, lng: 18.30308 },
    { lat: 48.313727, lng: 18.304062 },
    { lat: 48.313849, lng: 18.304512 },
    { lat: 48.314055, lng: 18.305274 },
    { lat: 48.3141762, lng: 18.305104 },
    { lat: 48.3144076, lng: 18.3049662 },
    { lat: 48.3143573, lng: 18.3046401 },
    { lat: 48.3146121, lng: 18.3045355 },
    { lat: 48.3146958, lng: 18.3044387 },
    { lat: 48.3148126, lng: 18.3044308 },
    { lat: 48.3149241, lng: 18.3042718 },
    { lat: 48.3150275, lng: 18.304103 },
    { lat: 48.3151771, lng: 18.3032855 },
    { lat: 48.3157897, lng: 18.302632 },
    { lat: 48.315972, lng: 18.3026022 },
    { lat: 48.316117, lng: 18.302455 },
    { lat: 48.316194, lng: 18.3024979 },
    { lat: 48.3162403, lng: 18.3026175 },
    { lat: 48.3163557, lng: 18.3025279 },
    { lat: 48.3165304, lng: 18.3025427 },
    { lat: 48.3167325, lng: 18.3024675 },
    { lat: 48.3175016, lng: 18.3025372 },
    { lat: 48.3177761, lng: 18.3023847 },
    { lat: 48.3185499, lng: 18.3058445 },
    { lat: 48.3180669, lng: 18.3061971 },
    { lat: 48.318843, lng: 18.3098354 },
    { lat: 48.3188151, lng: 18.3100964 },
    { lat: 48.3185924, lng: 18.3108807 },
    { lat: 48.3186022, lng: 18.3118667 },
    { lat: 48.3186824, lng: 18.3131333 },
    { lat: 48.3186659, lng: 18.313956 },
    { lat: 48.3193994, lng: 18.3173645 },
    { lat: 48.3200271, lng: 18.318141 },
    { lat: 48.3203501, lng: 18.3189228 },
    { lat: 48.3205355, lng: 18.3201374 },
    { lat: 48.320714, lng: 18.3205552 },
    { lat: 48.3229666, lng: 18.3238406 },
    { lat: 48.3232392, lng: 18.3243272 },
    { lat: 48.3234571, lng: 18.3251612 },
    { lat: 48.325039, lng: 18.3240138 },
    { lat: 48.3255232, lng: 18.3243323 },
    { lat: 48.3258464, lng: 18.3244211 },
    { lat: 48.3267342, lng: 18.324242 },
    { lat: 48.3275766, lng: 18.3243115 },
    { lat: 48.3276484, lng: 18.3243229 },
    { lat: 48.3280051, lng: 18.3240678 },
    { lat: 48.3290188, lng: 18.323306 },
    { lat: 48.3290489, lng: 18.3233404 },
    { lat: 48.3292617, lng: 18.3235479 },
    { lat: 48.3320773, lng: 18.3235977 },
    { lat: 48.3321661, lng: 18.3241066 },
    { lat: 48.3331799, lng: 18.3239393 },
    { lat: 48.3348502, lng: 18.3238087 },
    { lat: 48.3362761, lng: 18.3234476 },
    { lat: 48.3363118, lng: 18.3236381 },
    { lat: 48.3380015, lng: 18.3231553 },
    { lat: 48.3392986, lng: 18.3226117 },
    { lat: 48.3407805, lng: 18.3221036 },
    { lat: 48.340743, lng: 18.3217598 },
    { lat: 48.3435246, lng: 18.3204201 },
    { lat: 48.3437031, lng: 18.3209778 },
    { lat: 48.3471984, lng: 18.3193512 },
    { lat: 48.3472128, lng: 18.3194256 },
    { lat: 48.3474005, lng: 18.3193533 },
    { lat: 48.3489252, lng: 18.3186261 },
    { lat: 48.3495462, lng: 18.318454 },
    { lat: 48.3502751, lng: 18.3184735 },
    { lat: 48.3504455, lng: 18.3184144 },
    { lat: 48.3502655, lng: 18.3180473 },
    { lat: 48.3498543, lng: 18.3174434 },
    { lat: 48.3523986, lng: 18.3163527 },
    { lat: 48.3509944, lng: 18.3138463 },
    { lat: 48.3507865, lng: 18.313244 },
    { lat: 48.3507546, lng: 18.3125873 },
    { lat: 48.3507359, lng: 18.3115823 },
    { lat: 48.350928, lng: 18.3116684 },
    { lat: 48.353015, lng: 18.312415 },
    { lat: 48.3534484, lng: 18.3124358 },
    { lat: 48.354156, lng: 18.3123231 },
    { lat: 48.3567044, lng: 18.3111049 },
    { lat: 48.3560814, lng: 18.3058922 },
    { lat: 48.356676, lng: 18.3054562 },
    { lat: 48.3566967, lng: 18.3055628 },
    { lat: 48.357154, lng: 18.3067366 },
    { lat: 48.3576209, lng: 18.3081383 },
    { lat: 48.3579356, lng: 18.3086539 },
    { lat: 48.358569, lng: 18.3093123 },
    { lat: 48.3586082, lng: 18.3092076 },
    { lat: 48.3598316, lng: 18.3100068 },
    { lat: 48.3607157, lng: 18.3103575 },
    { lat: 48.3607754, lng: 18.3100192 },
    { lat: 48.361741, lng: 18.3102133 },
    { lat: 48.3624032, lng: 18.3101096 },
    { lat: 48.3624107, lng: 18.309211 },
    { lat: 48.3625045, lng: 18.3085426 },
    { lat: 48.3626916, lng: 18.3077664 },
    { lat: 48.3629884, lng: 18.3068548 },
    { lat: 48.3645653, lng: 18.3075799 },
    { lat: 48.3652502, lng: 18.3083334 },
    { lat: 48.3662273, lng: 18.3109097 },
    { lat: 48.3662445, lng: 18.3109546 },
    { lat: 48.3680295, lng: 18.3093678 },
    { lat: 48.3689017, lng: 18.3086949 },
    { lat: 48.3709408, lng: 18.3073723 },
    { lat: 48.3715759, lng: 18.3067965 },
    { lat: 48.3723642, lng: 18.3058599 },
    { lat: 48.3735332, lng: 18.3046538 },
    { lat: 48.3750295, lng: 18.3033495 },
    { lat: 48.3755207, lng: 18.30284 },
    { lat: 48.3769498, lng: 18.3018639 },
    { lat: 48.3779756, lng: 18.3009365 },
    { lat: 48.378018, lng: 18.300909 },
    { lat: 48.3776623, lng: 18.3005877 },
    { lat: 48.377361, lng: 18.3002297 },
    { lat: 48.3771102, lng: 18.2998285 },
    { lat: 48.3761342, lng: 18.2979477 },
    { lat: 48.3759601, lng: 18.2978043 },
    { lat: 48.3755719, lng: 18.2980008 },
    { lat: 48.3754208, lng: 18.2977264 },
    { lat: 48.3749334, lng: 18.2966381 },
    { lat: 48.3744636, lng: 18.2958599 },
    { lat: 48.3740604, lng: 18.2944091 },
    { lat: 48.3731806, lng: 18.2919433 },
    { lat: 48.3727396, lng: 18.2910202 },
    { lat: 48.3719219, lng: 18.2896598 },
    { lat: 48.3716111, lng: 18.2888012 },
    { lat: 48.3635859, lng: 18.2920723 },
    { lat: 48.3627307, lng: 18.29247 },
    { lat: 48.3616596, lng: 18.2930942 },
    { lat: 48.3615582, lng: 18.2928216 },
    { lat: 48.3600854, lng: 18.2937439 },
    { lat: 48.3589446, lng: 18.2945725 },
  ],
  Machulince: [
    { lat: 48.4028405, lng: 18.4225065 },
    { lat: 48.4028225, lng: 18.4226078 },
    { lat: 48.4027102, lng: 18.4235458 },
    { lat: 48.4024418, lng: 18.4241739 },
    { lat: 48.4025706, lng: 18.4243977 },
    { lat: 48.4032329, lng: 18.4248436 },
    { lat: 48.4038791, lng: 18.4256288 },
    { lat: 48.4038632, lng: 18.4260682 },
    { lat: 48.4038967, lng: 18.4262067 },
    { lat: 48.4038255, lng: 18.4265267 },
    { lat: 48.4036507, lng: 18.4268675 },
    { lat: 48.4039016, lng: 18.4270798 },
    { lat: 48.4040267, lng: 18.4272966 },
    { lat: 48.4046172, lng: 18.4277707 },
    { lat: 48.4042438, lng: 18.4292283 },
    { lat: 48.4041507, lng: 18.4292962 },
    { lat: 48.4039381, lng: 18.4298548 },
    { lat: 48.4038768, lng: 18.4297468 },
    { lat: 48.4036752, lng: 18.4303569 },
    { lat: 48.4036708, lng: 18.4309259 },
    { lat: 48.4034962, lng: 18.4313562 },
    { lat: 48.4032002, lng: 18.431648 },
    { lat: 48.4030508, lng: 18.4319863 },
    { lat: 48.4029127, lng: 18.4321582 },
    { lat: 48.402525, lng: 18.4323444 },
    { lat: 48.4019815, lng: 18.4328363 },
    { lat: 48.4018242, lng: 18.4330507 },
    { lat: 48.4015391, lng: 18.4331984 },
    { lat: 48.4013131, lng: 18.4335755 },
    { lat: 48.4008498, lng: 18.4338646 },
    { lat: 48.4004771, lng: 18.4348437 },
    { lat: 48.4004906, lng: 18.435616 },
    { lat: 48.4000471, lng: 18.4364595 },
    { lat: 48.4000153, lng: 18.4372059 },
    { lat: 48.4000446, lng: 18.4378504 },
    { lat: 48.3999797, lng: 18.4383485 },
    { lat: 48.3999912, lng: 18.4383825 },
    { lat: 48.4001649, lng: 18.4388968 },
    { lat: 48.4003279, lng: 18.4391485 },
    { lat: 48.4003871, lng: 18.4395824 },
    { lat: 48.4005119, lng: 18.4399178 },
    { lat: 48.4006169, lng: 18.4404052 },
    { lat: 48.4006274, lng: 18.4407972 },
    { lat: 48.4007869, lng: 18.4412873 },
    { lat: 48.4007962, lng: 18.4419216 },
    { lat: 48.4009613, lng: 18.442313 },
    { lat: 48.4007522, lng: 18.4424894 },
    { lat: 48.3999854, lng: 18.4418247 },
    { lat: 48.3991953, lng: 18.4414171 },
    { lat: 48.3984075, lng: 18.4414281 },
    { lat: 48.398409, lng: 18.4416583 },
    { lat: 48.3984376, lng: 18.4418872 },
    { lat: 48.3988597, lng: 18.4430046 },
    { lat: 48.3989524, lng: 18.4433529 },
    { lat: 48.3990125, lng: 18.4438413 },
    { lat: 48.3993511, lng: 18.4449788 },
    { lat: 48.3993973, lng: 18.445465 },
    { lat: 48.3993934, lng: 18.4462049 },
    { lat: 48.3992214, lng: 18.4482621 },
    { lat: 48.3992476, lng: 18.4486294 },
    { lat: 48.3993633, lng: 18.4492464 },
    { lat: 48.399532, lng: 18.4498273 },
    { lat: 48.4000561, lng: 18.4527791 },
    { lat: 48.4017465, lng: 18.462972 },
    { lat: 48.4024601, lng: 18.4680605 },
    { lat: 48.4028605, lng: 18.4704753 },
    { lat: 48.4033094, lng: 18.4741358 },
    { lat: 48.403548, lng: 18.4756293 },
    { lat: 48.4039336, lng: 18.4773562 },
    { lat: 48.4042411, lng: 18.4793435 },
    { lat: 48.4056044, lng: 18.4870531 },
    { lat: 48.4063605, lng: 18.4908496 },
    { lat: 48.4068388, lng: 18.4929322 },
    { lat: 48.4070984, lng: 18.4929343 },
    { lat: 48.4072539, lng: 18.4928691 },
    { lat: 48.4074825, lng: 18.492946 },
    { lat: 48.4075462, lng: 18.4928497 },
    { lat: 48.407714, lng: 18.4928444 },
    { lat: 48.4079225, lng: 18.4929252 },
    { lat: 48.4079764, lng: 18.4928571 },
    { lat: 48.4080536, lng: 18.4929287 },
    { lat: 48.4080902, lng: 18.4928546 },
    { lat: 48.4081988, lng: 18.4928602 },
    { lat: 48.408297, lng: 18.4929651 },
    { lat: 48.408508, lng: 18.4928653 },
    { lat: 48.4086102, lng: 18.4926694 },
    { lat: 48.4089036, lng: 18.4926931 },
    { lat: 48.409053, lng: 18.4923983 },
    { lat: 48.4091986, lng: 18.4923759 },
    { lat: 48.4093218, lng: 18.4925646 },
    { lat: 48.4094039, lng: 18.4924387 },
    { lat: 48.4094882, lng: 18.4921494 },
    { lat: 48.4096448, lng: 18.4920621 },
    { lat: 48.4097832, lng: 18.4922607 },
    { lat: 48.4099274, lng: 18.4923173 },
    { lat: 48.41018, lng: 18.4921743 },
    { lat: 48.4102924, lng: 18.492189 },
    { lat: 48.4104366, lng: 18.4925307 },
    { lat: 48.4106288, lng: 18.4925184 },
    { lat: 48.4106729, lng: 18.4923748 },
    { lat: 48.4110412, lng: 18.4919994 },
    { lat: 48.4111192, lng: 18.4921018 },
    { lat: 48.4111068, lng: 18.4923903 },
    { lat: 48.4111786, lng: 18.4924619 },
    { lat: 48.4113211, lng: 18.4924704 },
    { lat: 48.4114322, lng: 18.4922773 },
    { lat: 48.4116518, lng: 18.4923117 },
    { lat: 48.411977, lng: 18.4922118 },
    { lat: 48.4122026, lng: 18.4920303 },
    { lat: 48.4124253, lng: 18.492081 },
    { lat: 48.4125252, lng: 18.492049 },
    { lat: 48.4125957, lng: 18.4919393 },
    { lat: 48.413037, lng: 18.4920622 },
    { lat: 48.4134427, lng: 18.491649 },
    { lat: 48.4136923, lng: 18.4916154 },
    { lat: 48.4140207, lng: 18.4913887 },
    { lat: 48.4141747, lng: 18.491383 },
    { lat: 48.4141394, lng: 18.4911928 },
    { lat: 48.4142185, lng: 18.4910935 },
    { lat: 48.4142389, lng: 18.4909492 },
    { lat: 48.4140941, lng: 18.4904769 },
    { lat: 48.4140793, lng: 18.4901872 },
    { lat: 48.4141439, lng: 18.4901508 },
    { lat: 48.4141799, lng: 18.4902113 },
    { lat: 48.4142905, lng: 18.4901293 },
    { lat: 48.4142868, lng: 18.4898584 },
    { lat: 48.4143624, lng: 18.4896949 },
    { lat: 48.4143653, lng: 18.4893196 },
    { lat: 48.4143173, lng: 18.4890631 },
    { lat: 48.4144055, lng: 18.4889275 },
    { lat: 48.4144378, lng: 18.4885712 },
    { lat: 48.4145279, lng: 18.4883441 },
    { lat: 48.4143584, lng: 18.4878706 },
    { lat: 48.4143751, lng: 18.4876643 },
    { lat: 48.4145804, lng: 18.4871923 },
    { lat: 48.4147694, lng: 18.4872018 },
    { lat: 48.4147974, lng: 18.4870981 },
    { lat: 48.4148122, lng: 18.4866936 },
    { lat: 48.4147307, lng: 18.4863018 },
    { lat: 48.4147648, lng: 18.4860813 },
    { lat: 48.4146604, lng: 18.4857843 },
    { lat: 48.4147089, lng: 18.4852971 },
    { lat: 48.4148639, lng: 18.4850786 },
    { lat: 48.4148574, lng: 18.4849018 },
    { lat: 48.4147545, lng: 18.484713 },
    { lat: 48.4147761, lng: 18.4844525 },
    { lat: 48.4149934, lng: 18.483468 },
    { lat: 48.4150696, lng: 18.483375 },
    { lat: 48.4151773, lng: 18.4828744 },
    { lat: 48.4151322, lng: 18.4826311 },
    { lat: 48.4156647, lng: 18.482246 },
    { lat: 48.4158222, lng: 18.48191 },
    { lat: 48.4159361, lng: 18.4814604 },
    { lat: 48.4159481, lng: 18.4813964 },
    { lat: 48.4157713, lng: 18.4812395 },
    { lat: 48.4159851, lng: 18.4808825 },
    { lat: 48.41597, lng: 18.4806302 },
    { lat: 48.4158066, lng: 18.4801222 },
    { lat: 48.4159051, lng: 18.4795793 },
    { lat: 48.415828, lng: 18.4792875 },
    { lat: 48.4157381, lng: 18.4791496 },
    { lat: 48.4157508, lng: 18.4790543 },
    { lat: 48.4159288, lng: 18.4788889 },
    { lat: 48.4160652, lng: 18.478619 },
    { lat: 48.4159567, lng: 18.4780864 },
    { lat: 48.4160478, lng: 18.4777388 },
    { lat: 48.4162232, lng: 18.4775414 },
    { lat: 48.4161984, lng: 18.4767107 },
    { lat: 48.416379, lng: 18.4765482 },
    { lat: 48.4164882, lng: 18.4762203 },
    { lat: 48.4164221, lng: 18.4758202 },
    { lat: 48.4165212, lng: 18.4756721 },
    { lat: 48.4164589, lng: 18.4753848 },
    { lat: 48.4165657, lng: 18.4749705 },
    { lat: 48.4167234, lng: 18.4747825 },
    { lat: 48.4167912, lng: 18.4743386 },
    { lat: 48.4169679, lng: 18.4739025 },
    { lat: 48.4171998, lng: 18.4736295 },
    { lat: 48.4172831, lng: 18.4728332 },
    { lat: 48.4174271, lng: 18.4725826 },
    { lat: 48.4172921, lng: 18.4716154 },
    { lat: 48.4173801, lng: 18.4713686 },
    { lat: 48.417158, lng: 18.4711056 },
    { lat: 48.4172065, lng: 18.4708431 },
    { lat: 48.4170696, lng: 18.4707949 },
    { lat: 48.4170162, lng: 18.4706471 },
    { lat: 48.4170725, lng: 18.4702764 },
    { lat: 48.4168514, lng: 18.4700545 },
    { lat: 48.416833, lng: 18.4696196 },
    { lat: 48.4166255, lng: 18.4695025 },
    { lat: 48.4165085, lng: 18.4689561 },
    { lat: 48.4165604, lng: 18.4687553 },
    { lat: 48.4164557, lng: 18.4684307 },
    { lat: 48.4163446, lng: 18.4683637 },
    { lat: 48.4163086, lng: 18.4682222 },
    { lat: 48.4164047, lng: 18.4679804 },
    { lat: 48.416151, lng: 18.4672508 },
    { lat: 48.4162308, lng: 18.4670036 },
    { lat: 48.416129, lng: 18.4662039 },
    { lat: 48.4159622, lng: 18.4659251 },
    { lat: 48.4159527, lng: 18.4655871 },
    { lat: 48.4157847, lng: 18.4653465 },
    { lat: 48.4157146, lng: 18.4649554 },
    { lat: 48.4156433, lng: 18.464851 },
    { lat: 48.4155375, lng: 18.4644708 },
    { lat: 48.4154563, lng: 18.4643709 },
    { lat: 48.4154493, lng: 18.4641207 },
    { lat: 48.4153184, lng: 18.4637352 },
    { lat: 48.4150781, lng: 18.4634304 },
    { lat: 48.4150534, lng: 18.4633298 },
    { lat: 48.415114, lng: 18.4625744 },
    { lat: 48.4150709, lng: 18.4623692 },
    { lat: 48.4148935, lng: 18.4620342 },
    { lat: 48.4149503, lng: 18.4615978 },
    { lat: 48.4148905, lng: 18.4613133 },
    { lat: 48.414903, lng: 18.4610099 },
    { lat: 48.4146254, lng: 18.4601375 },
    { lat: 48.4147036, lng: 18.4600375 },
    { lat: 48.4146389, lng: 18.4596695 },
    { lat: 48.4147204, lng: 18.4592041 },
    { lat: 48.4146729, lng: 18.4590215 },
    { lat: 48.4148013, lng: 18.4587131 },
    { lat: 48.4148386, lng: 18.4584911 },
    { lat: 48.4147414, lng: 18.4578602 },
    { lat: 48.4148069, lng: 18.4576776 },
    { lat: 48.4147499, lng: 18.4574223 },
    { lat: 48.4147936, lng: 18.4571527 },
    { lat: 48.4147255, lng: 18.4562344 },
    { lat: 48.4146064, lng: 18.4560782 },
    { lat: 48.414468, lng: 18.4557024 },
    { lat: 48.4143368, lng: 18.4556138 },
    { lat: 48.4141665, lng: 18.4552889 },
    { lat: 48.4139246, lng: 18.4551808 },
    { lat: 48.4137105, lng: 18.4548373 },
    { lat: 48.4136, lng: 18.4544545 },
    { lat: 48.4133299, lng: 18.4540225 },
    { lat: 48.4131303, lng: 18.4539829 },
    { lat: 48.4131318, lng: 18.453665 },
    { lat: 48.4126177, lng: 18.4527146 },
    { lat: 48.4125637, lng: 18.4521298 },
    { lat: 48.4126094, lng: 18.4516944 },
    { lat: 48.4124861, lng: 18.4512636 },
    { lat: 48.4124814, lng: 18.451098 },
    { lat: 48.4123611, lng: 18.4509245 },
    { lat: 48.4123793, lng: 18.4507621 },
    { lat: 48.4123091, lng: 18.4502745 },
    { lat: 48.4121714, lng: 18.4500251 },
    { lat: 48.4122118, lng: 18.4496809 },
    { lat: 48.4123071, lng: 18.4495301 },
    { lat: 48.4123526, lng: 18.4492716 },
    { lat: 48.4122486, lng: 18.4490226 },
    { lat: 48.412305, lng: 18.4489015 },
    { lat: 48.4123147, lng: 18.448732 },
    { lat: 48.4121723, lng: 18.4480812 },
    { lat: 48.4122164, lng: 18.4478367 },
    { lat: 48.4121825, lng: 18.4474905 },
    { lat: 48.4123952, lng: 18.4463687 },
    { lat: 48.4122597, lng: 18.445982 },
    { lat: 48.4122966, lng: 18.4456947 },
    { lat: 48.412224, lng: 18.4454822 },
    { lat: 48.4123312, lng: 18.4447959 },
    { lat: 48.4122243, lng: 18.4444947 },
    { lat: 48.4122439, lng: 18.4436601 },
    { lat: 48.4123753, lng: 18.4427102 },
    { lat: 48.4123435, lng: 18.4424464 },
    { lat: 48.4126706, lng: 18.4419178 },
    { lat: 48.4126863, lng: 18.4417329 },
    { lat: 48.4130095, lng: 18.4413574 },
    { lat: 48.4132415, lng: 18.4412704 },
    { lat: 48.4133235, lng: 18.4411635 },
    { lat: 48.4135766, lng: 18.4410901 },
    { lat: 48.4140578, lng: 18.4402512 },
    { lat: 48.4140698, lng: 18.4401194 },
    { lat: 48.4142513, lng: 18.4401406 },
    { lat: 48.4143218, lng: 18.4397848 },
    { lat: 48.41452, lng: 18.4395571 },
    { lat: 48.4147533, lng: 18.4389847 },
    { lat: 48.4149671, lng: 18.4384099 },
    { lat: 48.4152194, lng: 18.4379954 },
    { lat: 48.4153372, lng: 18.4383317 },
    { lat: 48.415377, lng: 18.4382745 },
    { lat: 48.4158166, lng: 18.4392807 },
    { lat: 48.4163701, lng: 18.436641 },
    { lat: 48.4164862, lng: 18.4366427 },
    { lat: 48.4179037, lng: 18.4358414 },
    { lat: 48.4193033, lng: 18.4354493 },
    { lat: 48.4193256, lng: 18.4352814 },
    { lat: 48.4199427, lng: 18.4351014 },
    { lat: 48.4199537, lng: 18.4350361 },
    { lat: 48.4210829, lng: 18.4355811 },
    { lat: 48.4219522, lng: 18.436464 },
    { lat: 48.422152, lng: 18.4367695 },
    { lat: 48.4224322, lng: 18.437343 },
    { lat: 48.4227329, lng: 18.438424 },
    { lat: 48.4233589, lng: 18.4377762 },
    { lat: 48.4236248, lng: 18.4375456 },
    { lat: 48.4240662, lng: 18.4373096 },
    { lat: 48.4253366, lng: 18.4360586 },
    { lat: 48.4255691, lng: 18.4359586 },
    { lat: 48.4263985, lng: 18.4349094 },
    { lat: 48.4268729, lng: 18.4358108 },
    { lat: 48.4279967, lng: 18.4365616 },
    { lat: 48.4281686, lng: 18.436448 },
    { lat: 48.4283607, lng: 18.4366785 },
    { lat: 48.4284763, lng: 18.4366451 },
    { lat: 48.4295974, lng: 18.4381174 },
    { lat: 48.429795, lng: 18.4374556 },
    { lat: 48.4305162, lng: 18.4383271 },
    { lat: 48.4309422, lng: 18.4386252 },
    { lat: 48.4312985, lng: 18.4390872 },
    { lat: 48.4326825, lng: 18.4396718 },
    { lat: 48.4326472, lng: 18.4401498 },
    { lat: 48.4330908, lng: 18.4402741 },
    { lat: 48.4332124, lng: 18.4422581 },
    { lat: 48.4338583, lng: 18.4414568 },
    { lat: 48.4346994, lng: 18.4429876 },
    { lat: 48.4351384, lng: 18.4424613 },
    { lat: 48.4353414, lng: 18.4421814 },
    { lat: 48.4353128, lng: 18.4415015 },
    { lat: 48.4353593, lng: 18.4412455 },
    { lat: 48.4354578, lng: 18.4410079 },
    { lat: 48.4357147, lng: 18.4407006 },
    { lat: 48.4359771, lng: 18.4393347 },
    { lat: 48.4361852, lng: 18.4388402 },
    { lat: 48.4362606, lng: 18.4384908 },
    { lat: 48.4360915, lng: 18.4372981 },
    { lat: 48.4362222, lng: 18.4362038 },
    { lat: 48.4359939, lng: 18.4344385 },
    { lat: 48.4358959, lng: 18.4339527 },
    { lat: 48.4359978, lng: 18.433817 },
    { lat: 48.4357475, lng: 18.4331142 },
    { lat: 48.4355915, lng: 18.432844 },
    { lat: 48.4352941, lng: 18.4325095 },
    { lat: 48.4350512, lng: 18.4323624 },
    { lat: 48.4344536, lng: 18.4321642 },
    { lat: 48.4342272, lng: 18.4319457 },
    { lat: 48.4339793, lng: 18.4316525 },
    { lat: 48.4338611, lng: 18.4314122 },
    { lat: 48.4337129, lng: 18.4311132 },
    { lat: 48.4335655, lng: 18.4308054 },
    { lat: 48.4334064, lng: 18.4305204 },
    { lat: 48.4330104, lng: 18.4295936 },
    { lat: 48.4328983, lng: 18.4289255 },
    { lat: 48.4327689, lng: 18.4281125 },
    { lat: 48.4327627, lng: 18.4275403 },
    { lat: 48.432779, lng: 18.4274689 },
    { lat: 48.4328612, lng: 18.4274852 },
    { lat: 48.43322, lng: 18.4270815 },
    { lat: 48.4332732, lng: 18.4271071 },
    { lat: 48.4336096, lng: 18.4268871 },
    { lat: 48.4339827, lng: 18.4270227 },
    { lat: 48.4341031, lng: 18.4272398 },
    { lat: 48.4341739, lng: 18.4276778 },
    { lat: 48.4342472, lng: 18.4278398 },
    { lat: 48.4345283, lng: 18.4279027 },
    { lat: 48.4348465, lng: 18.4276829 },
    { lat: 48.4349085, lng: 18.4275827 },
    { lat: 48.4354508, lng: 18.4279598 },
    { lat: 48.4360898, lng: 18.4281496 },
    { lat: 48.4362586, lng: 18.4281425 },
    { lat: 48.436774, lng: 18.4286048 },
    { lat: 48.4371515, lng: 18.4291842 },
    { lat: 48.4374064, lng: 18.4293628 },
    { lat: 48.4375326, lng: 18.4293625 },
    { lat: 48.4378935, lng: 18.4282043 },
    { lat: 48.4380684, lng: 18.4281453 },
    { lat: 48.4380374, lng: 18.4277548 },
    { lat: 48.4379811, lng: 18.4276141 },
    { lat: 48.4364058, lng: 18.4262517 },
    { lat: 48.436279, lng: 18.4263263 },
    { lat: 48.43619, lng: 18.4262871 },
    { lat: 48.4356241, lng: 18.4257879 },
    { lat: 48.4352815, lng: 18.4257966 },
    { lat: 48.4349826, lng: 18.4256326 },
    { lat: 48.4345005, lng: 18.4257719 },
    { lat: 48.4342812, lng: 18.4256795 },
    { lat: 48.433961, lng: 18.4254037 },
    { lat: 48.4330783, lng: 18.4256354 },
    { lat: 48.4325777, lng: 18.4252566 },
    { lat: 48.4323878, lng: 18.4252298 },
    { lat: 48.4322642, lng: 18.4257647 },
    { lat: 48.4314364, lng: 18.4250635 },
    { lat: 48.4314163, lng: 18.4250461 },
    { lat: 48.4313835, lng: 18.4257703 },
    { lat: 48.4314718, lng: 18.4258952 },
    { lat: 48.4315006, lng: 18.4262713 },
    { lat: 48.4309385, lng: 18.4258236 },
    { lat: 48.4305175, lng: 18.4270123 },
    { lat: 48.4301653, lng: 18.4267344 },
    { lat: 48.4298738, lng: 18.4266044 },
    { lat: 48.4296104, lng: 18.4265142 },
    { lat: 48.4292941, lng: 18.4264571 },
    { lat: 48.4291514, lng: 18.426431 },
    { lat: 48.4288926, lng: 18.4263275 },
    { lat: 48.4286351, lng: 18.4261457 },
    { lat: 48.4279563, lng: 18.4256842 },
    { lat: 48.4277095, lng: 18.4254528 },
    { lat: 48.4275009, lng: 18.4251986 },
    { lat: 48.4273849, lng: 18.425019 },
    { lat: 48.4273255, lng: 18.4249259 },
    { lat: 48.4272701, lng: 18.424854 },
    { lat: 48.4271822, lng: 18.4247837 },
    { lat: 48.4262192, lng: 18.424329 },
    { lat: 48.4257766, lng: 18.4241467 },
    { lat: 48.4252487, lng: 18.4239643 },
    { lat: 48.4231512, lng: 18.4232019 },
    { lat: 48.4208682, lng: 18.4235726 },
    { lat: 48.4192085, lng: 18.4232876 },
    { lat: 48.4165321, lng: 18.4230356 },
    { lat: 48.41524, lng: 18.4227085 },
    { lat: 48.4149088, lng: 18.423908 },
    { lat: 48.4087245, lng: 18.4189678 },
    { lat: 48.4086275, lng: 18.4188899 },
    { lat: 48.4073916, lng: 18.4214589 },
    { lat: 48.4061589, lng: 18.4241892 },
    { lat: 48.4061754, lng: 18.4238204 },
    { lat: 48.4060538, lng: 18.4236845 },
    { lat: 48.4057637, lng: 18.4237377 },
    { lat: 48.405544, lng: 18.4239863 },
    { lat: 48.4054403, lng: 18.4237082 },
    { lat: 48.4050938, lng: 18.4232989 },
    { lat: 48.4050167, lng: 18.423011 },
    { lat: 48.4050072, lng: 18.4226973 },
    { lat: 48.4048907, lng: 18.422614 },
    { lat: 48.404779, lng: 18.4225745 },
    { lat: 48.4045405, lng: 18.4226244 },
    { lat: 48.4043603, lng: 18.4228151 },
    { lat: 48.4041077, lng: 18.4228338 },
    { lat: 48.403949, lng: 18.4227612 },
    { lat: 48.4038074, lng: 18.4225877 },
    { lat: 48.4037285, lng: 18.4222451 },
    { lat: 48.4034526, lng: 18.4220927 },
    { lat: 48.4032382, lng: 18.4221138 },
    { lat: 48.4029787, lng: 18.4224965 },
    { lat: 48.4028735, lng: 18.422513 },
    { lat: 48.4028472, lng: 18.4224655 },
    { lat: 48.4028405, lng: 18.4225065 },
  ],
  Sľažany: [
    { lat: 48.3823603, lng: 18.2976951 },
    { lat: 48.3823017, lng: 18.2976736 },
    { lat: 48.3823138, lng: 18.2977288 },
    { lat: 48.381495, lng: 18.2978532 },
    { lat: 48.3812002, lng: 18.2979663 },
    { lat: 48.3798074, lng: 18.2988502 },
    { lat: 48.3788938, lng: 18.2996665 },
    { lat: 48.378018, lng: 18.300909 },
    { lat: 48.3779756, lng: 18.3009365 },
    { lat: 48.3769498, lng: 18.3018639 },
    { lat: 48.3755207, lng: 18.30284 },
    { lat: 48.3750295, lng: 18.3033495 },
    { lat: 48.3735332, lng: 18.3046538 },
    { lat: 48.3723642, lng: 18.3058599 },
    { lat: 48.3715759, lng: 18.3067965 },
    { lat: 48.3709408, lng: 18.3073723 },
    { lat: 48.3689017, lng: 18.3086949 },
    { lat: 48.3680295, lng: 18.3093678 },
    { lat: 48.3662445, lng: 18.3109546 },
    { lat: 48.3660964, lng: 18.3110648 },
    { lat: 48.3654528, lng: 18.3115135 },
    { lat: 48.3646368, lng: 18.3122433 },
    { lat: 48.3635663, lng: 18.3129631 },
    { lat: 48.3636655, lng: 18.3148852 },
    { lat: 48.3638841, lng: 18.3220189 },
    { lat: 48.3639477, lng: 18.3229599 },
    { lat: 48.3639637, lng: 18.3237572 },
    { lat: 48.3638578, lng: 18.3241944 },
    { lat: 48.3640463, lng: 18.3253691 },
    { lat: 48.3640599, lng: 18.3271971 },
    { lat: 48.3638144, lng: 18.3272459 },
    { lat: 48.363602, lng: 18.3309963 },
    { lat: 48.3636249, lng: 18.3316959 },
    { lat: 48.3639238, lng: 18.33428 },
    { lat: 48.3643535, lng: 18.3344146 },
    { lat: 48.364509, lng: 18.336085 },
    { lat: 48.364941, lng: 18.3393256 },
    { lat: 48.3645893, lng: 18.3393301 },
    { lat: 48.3639345, lng: 18.3391029 },
    { lat: 48.363097, lng: 18.3386176 },
    { lat: 48.3630524, lng: 18.3388706 },
    { lat: 48.3635036, lng: 18.3392434 },
    { lat: 48.3637984, lng: 18.3396088 },
    { lat: 48.3655142, lng: 18.3430691 },
    { lat: 48.3657542, lng: 18.343498 },
    { lat: 48.3663241, lng: 18.3442775 },
    { lat: 48.3666948, lng: 18.3450272 },
    { lat: 48.3672732, lng: 18.3460147 },
    { lat: 48.3675958, lng: 18.3467889 },
    { lat: 48.3678401, lng: 18.3476593 },
    { lat: 48.3686455, lng: 18.3501093 },
    { lat: 48.3690189, lng: 18.3519189 },
    { lat: 48.369177, lng: 18.3524618 },
    { lat: 48.3694795, lng: 18.3532202 },
    { lat: 48.3695508, lng: 18.3536245 },
    { lat: 48.3697646, lng: 18.3538139 },
    { lat: 48.3700496, lng: 18.3540663 },
    { lat: 48.3700993, lng: 18.3541369 },
    { lat: 48.3702522, lng: 18.3543541 },
    { lat: 48.3710203, lng: 18.3555078 },
    { lat: 48.3714108, lng: 18.3563087 },
    { lat: 48.3714061, lng: 18.3563476 },
    { lat: 48.3715695, lng: 18.3564595 },
    { lat: 48.371816, lng: 18.356492 },
    { lat: 48.3726672, lng: 18.3568197 },
    { lat: 48.3732016, lng: 18.3567914 },
    { lat: 48.3739494, lng: 18.35655 },
    { lat: 48.3747327, lng: 18.3557355 },
    { lat: 48.375274, lng: 18.3551726 },
    { lat: 48.3775258, lng: 18.3528161 },
    { lat: 48.3775083, lng: 18.3527665 },
    { lat: 48.3774614, lng: 18.3526344 },
    { lat: 48.3779386, lng: 18.3523152 },
    { lat: 48.3810436, lng: 18.3491445 },
    { lat: 48.3853173, lng: 18.3471123 },
    { lat: 48.3857233, lng: 18.3470285 },
    { lat: 48.3912441, lng: 18.3465543 },
    { lat: 48.3912946, lng: 18.3468875 },
    { lat: 48.3913149, lng: 18.3469691 },
    { lat: 48.3915844, lng: 18.3468442 },
    { lat: 48.3916447, lng: 18.3468936 },
    { lat: 48.3922232, lng: 18.3466519 },
    { lat: 48.3924798, lng: 18.3464392 },
    { lat: 48.3925908, lng: 18.3467048 },
    { lat: 48.3926724, lng: 18.3466936 },
    { lat: 48.3927614, lng: 18.3466482 },
    { lat: 48.3928303, lng: 18.3464126 },
    { lat: 48.3929273, lng: 18.3464517 },
    { lat: 48.392971, lng: 18.3463673 },
    { lat: 48.3933556, lng: 18.3462036 },
    { lat: 48.3934825, lng: 18.3464156 },
    { lat: 48.3937811, lng: 18.3463868 },
    { lat: 48.3941078, lng: 18.3459588 },
    { lat: 48.394381, lng: 18.3459609 },
    { lat: 48.3944698, lng: 18.3458548 },
    { lat: 48.394605, lng: 18.3458748 },
    { lat: 48.3947869, lng: 18.345777 },
    { lat: 48.3951182, lng: 18.3457915 },
    { lat: 48.3953404, lng: 18.3457107 },
    { lat: 48.3954665, lng: 18.3456253 },
    { lat: 48.3955659, lng: 18.3453499 },
    { lat: 48.3957199, lng: 18.3451602 },
    { lat: 48.3960451, lng: 18.344987 },
    { lat: 48.3962643, lng: 18.3452502 },
    { lat: 48.3966328, lng: 18.3450618 },
    { lat: 48.3968735, lng: 18.3450109 },
    { lat: 48.396999, lng: 18.3450733 },
    { lat: 48.3971559, lng: 18.3450334 },
    { lat: 48.3975061, lng: 18.3451222 },
    { lat: 48.3976092, lng: 18.3450892 },
    { lat: 48.3979147, lng: 18.3448265 },
    { lat: 48.3980325, lng: 18.344536 },
    { lat: 48.3980158, lng: 18.3442825 },
    { lat: 48.3981594, lng: 18.3441463 },
    { lat: 48.3985706, lng: 18.3444147 },
    { lat: 48.3990459, lng: 18.3445994 },
    { lat: 48.3991421, lng: 18.3445459 },
    { lat: 48.3991768, lng: 18.3443391 },
    { lat: 48.3995126, lng: 18.3443335 },
    { lat: 48.3996574, lng: 18.3442213 },
    { lat: 48.3998256, lng: 18.3443039 },
    { lat: 48.3999727, lng: 18.3441491 },
    { lat: 48.4000754, lng: 18.3437717 },
    { lat: 48.4002015, lng: 18.3437272 },
    { lat: 48.4002625, lng: 18.3435096 },
    { lat: 48.4002956, lng: 18.3434752 },
    { lat: 48.400516, lng: 18.3436644 },
    { lat: 48.4006259, lng: 18.3436095 },
    { lat: 48.4007677, lng: 18.3434671 },
    { lat: 48.400992, lng: 18.3430234 },
    { lat: 48.4013529, lng: 18.3427828 },
    { lat: 48.4014468, lng: 18.342529 },
    { lat: 48.4015149, lng: 18.3424951 },
    { lat: 48.401586, lng: 18.3426955 },
    { lat: 48.4016229, lng: 18.3430675 },
    { lat: 48.4017919, lng: 18.3431837 },
    { lat: 48.4018657, lng: 18.3431498 },
    { lat: 48.401908, lng: 18.3430002 },
    { lat: 48.4019327, lng: 18.3426011 },
    { lat: 48.4020813, lng: 18.3424952 },
    { lat: 48.4022343, lng: 18.3425868 },
    { lat: 48.4023294, lng: 18.3428132 },
    { lat: 48.4023202, lng: 18.3431482 },
    { lat: 48.4024499, lng: 18.3433045 },
    { lat: 48.4025416, lng: 18.3432715 },
    { lat: 48.4027048, lng: 18.3428843 },
    { lat: 48.40278, lng: 18.3428803 },
    { lat: 48.4030473, lng: 18.3431377 },
    { lat: 48.4033322, lng: 18.3430871 },
    { lat: 48.4034381, lng: 18.3428861 },
    { lat: 48.4034412, lng: 18.3425193 },
    { lat: 48.4035363, lng: 18.3423492 },
    { lat: 48.4036472, lng: 18.3423531 },
    { lat: 48.4038517, lng: 18.3426959 },
    { lat: 48.4040928, lng: 18.3426704 },
    { lat: 48.404226, lng: 18.3424539 },
    { lat: 48.4043465, lng: 18.3419893 },
    { lat: 48.4045111, lng: 18.3420081 },
    { lat: 48.4046341, lng: 18.3422679 },
    { lat: 48.4047321, lng: 18.3423236 },
    { lat: 48.4048152, lng: 18.3422174 },
    { lat: 48.4049558, lng: 18.3418313 },
    { lat: 48.4050883, lng: 18.3417206 },
    { lat: 48.4057389, lng: 18.341754 },
    { lat: 48.405795, lng: 18.341767 },
    { lat: 48.4059563, lng: 18.3418675 },
    { lat: 48.4059903, lng: 18.3421892 },
    { lat: 48.4060858, lng: 18.342243 },
    { lat: 48.4062089, lng: 18.3421627 },
    { lat: 48.4061981, lng: 18.3415533 },
    { lat: 48.4063653, lng: 18.3413593 },
    { lat: 48.4067211, lng: 18.3413459 },
    { lat: 48.4070513, lng: 18.3408598 },
    { lat: 48.4071743, lng: 18.3407436 },
    { lat: 48.4073025, lng: 18.3407361 },
    { lat: 48.4075231, lng: 18.3408045 },
    { lat: 48.4076322, lng: 18.3412199 },
    { lat: 48.4077755, lng: 18.3413568 },
    { lat: 48.407618, lng: 18.3415065 },
    { lat: 48.4075753, lng: 18.3416455 },
    { lat: 48.4077194, lng: 18.3418036 },
    { lat: 48.4081387, lng: 18.341734 },
    { lat: 48.4083896, lng: 18.3416214 },
    { lat: 48.4088247, lng: 18.3412178 },
    { lat: 48.4089137, lng: 18.3410743 },
    { lat: 48.4090929, lng: 18.3410109 },
    { lat: 48.4090407, lng: 18.3407871 },
    { lat: 48.4096945, lng: 18.3404792 },
    { lat: 48.4097587, lng: 18.3402767 },
    { lat: 48.4100506, lng: 18.3402132 },
    { lat: 48.4102116, lng: 18.3402462 },
    { lat: 48.4103946, lng: 18.3405478 },
    { lat: 48.4105087, lng: 18.3405705 },
    { lat: 48.4106836, lng: 18.3404114 },
    { lat: 48.4108733, lng: 18.3399749 },
    { lat: 48.4110577, lng: 18.3399037 },
    { lat: 48.4112844, lng: 18.3396443 },
    { lat: 48.4114186, lng: 18.3399222 },
    { lat: 48.4116736, lng: 18.340004 },
    { lat: 48.411653, lng: 18.3399367 },
    { lat: 48.410877, lng: 18.3376515 },
    { lat: 48.4103151, lng: 18.3354804 },
    { lat: 48.4101166, lng: 18.3351229 },
    { lat: 48.4095954, lng: 18.3334317 },
    { lat: 48.4090314, lng: 18.3318465 },
    { lat: 48.4088782, lng: 18.331549 },
    { lat: 48.4086113, lng: 18.3307625 },
    { lat: 48.4080824, lng: 18.3294541 },
    { lat: 48.4070959, lng: 18.3265442 },
    { lat: 48.4065216, lng: 18.3245563 },
    { lat: 48.4066242, lng: 18.3244573 },
    { lat: 48.4069223, lng: 18.3243818 },
    { lat: 48.4069191, lng: 18.3243666 },
    { lat: 48.4060623, lng: 18.3217733 },
    { lat: 48.4049695, lng: 18.3195597 },
    { lat: 48.4043324, lng: 18.3179384 },
    { lat: 48.403906, lng: 18.3170254 },
    { lat: 48.4039992, lng: 18.3167592 },
    { lat: 48.4036812, lng: 18.3159691 },
    { lat: 48.4035403, lng: 18.3154965 },
    { lat: 48.4029575, lng: 18.3128474 },
    { lat: 48.4028502, lng: 18.3128853 },
    { lat: 48.402562, lng: 18.3112118 },
    { lat: 48.4023502, lng: 18.3102853 },
    { lat: 48.4025515, lng: 18.3101128 },
    { lat: 48.4020447, lng: 18.3085651 },
    { lat: 48.4018986, lng: 18.3079802 },
    { lat: 48.4019594, lng: 18.3078385 },
    { lat: 48.4020442, lng: 18.3077012 },
    { lat: 48.4024458, lng: 18.3073464 },
    { lat: 48.40234, lng: 18.3058456 },
    { lat: 48.4022011, lng: 18.3054246 },
    { lat: 48.4022763, lng: 18.3052583 },
    { lat: 48.4023001, lng: 18.3045888 },
    { lat: 48.4021777, lng: 18.3042626 },
    { lat: 48.4024966, lng: 18.3038992 },
    { lat: 48.4025621, lng: 18.3036639 },
    { lat: 48.4025269, lng: 18.3032604 },
    { lat: 48.4023286, lng: 18.3027114 },
    { lat: 48.402201, lng: 18.3025339 },
    { lat: 48.4022279, lng: 18.3024296 },
    { lat: 48.4023479, lng: 18.3023399 },
    { lat: 48.4023415, lng: 18.302186 },
    { lat: 48.4021706, lng: 18.3021376 },
    { lat: 48.4021236, lng: 18.3017268 },
    { lat: 48.4019602, lng: 18.3018518 },
    { lat: 48.4019809, lng: 18.3014625 },
    { lat: 48.4018338, lng: 18.3012556 },
    { lat: 48.4017085, lng: 18.3012933 },
    { lat: 48.4016841, lng: 18.3012212 },
    { lat: 48.4018046, lng: 18.300884 },
    { lat: 48.4017589, lng: 18.300713 },
    { lat: 48.4016778, lng: 18.300716 },
    { lat: 48.4016444, lng: 18.3006244 },
    { lat: 48.4016733, lng: 18.3004386 },
    { lat: 48.4016345, lng: 18.300451 },
    { lat: 48.4015496, lng: 18.3003252 },
    { lat: 48.4015949, lng: 18.2999334 },
    { lat: 48.4014865, lng: 18.2994186 },
    { lat: 48.4014985, lng: 18.2990148 },
    { lat: 48.4013931, lng: 18.2989662 },
    { lat: 48.4014238, lng: 18.2985965 },
    { lat: 48.401496, lng: 18.2983781 },
    { lat: 48.4013995, lng: 18.2982698 },
    { lat: 48.4014155, lng: 18.298136 },
    { lat: 48.4014999, lng: 18.2980314 },
    { lat: 48.4015943, lng: 18.2967769 },
    { lat: 48.4016359, lng: 18.2967342 },
    { lat: 48.4016112, lng: 18.2964793 },
    { lat: 48.4016573, lng: 18.2962805 },
    { lat: 48.4015963, lng: 18.2961399 },
    { lat: 48.4015901, lng: 18.2959518 },
    { lat: 48.4017621, lng: 18.2958214 },
    { lat: 48.4018376, lng: 18.2956452 },
    { lat: 48.4018753, lng: 18.2953524 },
    { lat: 48.402019, lng: 18.2951638 },
    { lat: 48.4020554, lng: 18.295032 },
    { lat: 48.4020287, lng: 18.2948586 },
    { lat: 48.4021416, lng: 18.2946818 },
    { lat: 48.4020705, lng: 18.2943476 },
    { lat: 48.4021159, lng: 18.2943304 },
    { lat: 48.4022556, lng: 18.2944828 },
    { lat: 48.4023129, lng: 18.2943238 },
    { lat: 48.4023563, lng: 18.2940562 },
    { lat: 48.4023051, lng: 18.2939669 },
    { lat: 48.4023077, lng: 18.2937564 },
    { lat: 48.402384, lng: 18.2937288 },
    { lat: 48.40238, lng: 18.2932826 },
    { lat: 48.4025684, lng: 18.2930088 },
    { lat: 48.4024639, lng: 18.2929547 },
    { lat: 48.4025629, lng: 18.2927137 },
    { lat: 48.4025835, lng: 18.2925402 },
    { lat: 48.4022621, lng: 18.2920483 },
    { lat: 48.4018476, lng: 18.2915686 },
    { lat: 48.4011288, lng: 18.293445 },
    { lat: 48.4005654, lng: 18.2947631 },
    { lat: 48.399734, lng: 18.2959715 },
    { lat: 48.3990558, lng: 18.2974548 },
    { lat: 48.3962183, lng: 18.2971429 },
    { lat: 48.3950812, lng: 18.2969462 },
    { lat: 48.3936439, lng: 18.2963379 },
    { lat: 48.3916087, lng: 18.2969755 },
    { lat: 48.3886327, lng: 18.2974998 },
    { lat: 48.385025, lng: 18.2971322 },
    { lat: 48.3834829, lng: 18.2975906 },
    { lat: 48.3823603, lng: 18.2976951 },
  ],
  Volkovce: [
    { lat: 48.3501713, lng: 18.4460124 },
    { lat: 48.3497936, lng: 18.4421135 },
    { lat: 48.3479314, lng: 18.4425024 },
    { lat: 48.3477773, lng: 18.4411989 },
    { lat: 48.3460627, lng: 18.44191 },
    { lat: 48.3452256, lng: 18.4421095 },
    { lat: 48.3443656, lng: 18.442204 },
    { lat: 48.3441175, lng: 18.4406897 },
    { lat: 48.3437937, lng: 18.4396197 },
    { lat: 48.3437689, lng: 18.4392764 },
    { lat: 48.3436411, lng: 18.4391311 },
    { lat: 48.3430123, lng: 18.4387161 },
    { lat: 48.3427689, lng: 18.4386623 },
    { lat: 48.3423564, lng: 18.4375585 },
    { lat: 48.3416113, lng: 18.4352866 },
    { lat: 48.3413289, lng: 18.4341083 },
    { lat: 48.3395956, lng: 18.4346283 },
    { lat: 48.3382821, lng: 18.434743 },
    { lat: 48.3378619, lng: 18.4347104 },
    { lat: 48.3379088, lng: 18.4343014 },
    { lat: 48.3378389, lng: 18.434058 },
    { lat: 48.3377828, lng: 18.4334974 },
    { lat: 48.3377669, lng: 18.4334973 },
    { lat: 48.3365274, lng: 18.433506 },
    { lat: 48.3350101, lng: 18.4337669 },
    { lat: 48.3342337, lng: 18.4340227 },
    { lat: 48.3325851, lng: 18.4389247 },
    { lat: 48.3293461, lng: 18.445086 },
    { lat: 48.3290776, lng: 18.446565 },
    { lat: 48.3270267, lng: 18.4476351 },
    { lat: 48.3274114, lng: 18.4495398 },
    { lat: 48.3263393, lng: 18.4498962 },
    { lat: 48.3266863, lng: 18.4517112 },
    { lat: 48.3270781, lng: 18.452988 },
    { lat: 48.3270293, lng: 18.4531064 },
    { lat: 48.3279083, lng: 18.4556618 },
    { lat: 48.3282076, lng: 18.4564967 },
    { lat: 48.326635, lng: 18.4576937 },
    { lat: 48.3210491, lng: 18.4624431 },
    { lat: 48.3162059, lng: 18.4672091 },
    { lat: 48.3156019, lng: 18.4686202 },
    { lat: 48.315496, lng: 18.4688675 },
    { lat: 48.3153125, lng: 18.4691933 },
    { lat: 48.315101, lng: 18.469569 },
    { lat: 48.31597, lng: 18.470857 },
    { lat: 48.316182, lng: 18.471166 },
    { lat: 48.31622, lng: 18.471222 },
    { lat: 48.316182, lng: 18.471303 },
    { lat: 48.316162, lng: 18.471346 },
    { lat: 48.316016, lng: 18.471661 },
    { lat: 48.315522, lng: 18.473558 },
    { lat: 48.316021, lng: 18.47412 },
    { lat: 48.316148, lng: 18.47426 },
    { lat: 48.316239, lng: 18.474362 },
    { lat: 48.316445, lng: 18.474589 },
    { lat: 48.316449, lng: 18.474595 },
    { lat: 48.316499, lng: 18.474649 },
    { lat: 48.316859, lng: 18.47506 },
    { lat: 48.317042, lng: 18.47527 },
    { lat: 48.317058, lng: 18.475289 },
    { lat: 48.317079, lng: 18.47532 },
    { lat: 48.317367, lng: 18.475757 },
    { lat: 48.31745, lng: 18.475882 },
    { lat: 48.317672, lng: 18.476219 },
    { lat: 48.318191, lng: 18.476844 },
    { lat: 48.318279, lng: 18.476946 },
    { lat: 48.318838, lng: 18.477149 },
    { lat: 48.319233, lng: 18.477302 },
    { lat: 48.3192825, lng: 18.477337 },
    { lat: 48.319325, lng: 18.477367 },
    { lat: 48.319549, lng: 18.47753 },
    { lat: 48.320153, lng: 18.477991 },
    { lat: 48.320217, lng: 18.47804 },
    { lat: 48.320612, lng: 18.478342 },
    { lat: 48.320934, lng: 18.478749 },
    { lat: 48.321245, lng: 18.479066 },
    { lat: 48.32141, lng: 18.479145 },
    { lat: 48.3217013, lng: 18.4791735 },
    { lat: 48.321921, lng: 18.479195 },
    { lat: 48.322324, lng: 18.479232 },
    { lat: 48.322639, lng: 18.479269 },
    { lat: 48.322803, lng: 18.479311 },
    { lat: 48.323245, lng: 18.479323 },
    { lat: 48.3232678, lng: 18.4793232 },
    { lat: 48.3234473, lng: 18.4793244 },
    { lat: 48.323692, lng: 18.479326 },
    { lat: 48.323724, lng: 18.479309 },
    { lat: 48.323932, lng: 18.479202 },
    { lat: 48.324291, lng: 18.478943 },
    { lat: 48.324486, lng: 18.47879 },
    { lat: 48.32504, lng: 18.478477 },
    { lat: 48.325308, lng: 18.478203 },
    { lat: 48.325414, lng: 18.478079 },
    { lat: 48.325829, lng: 18.477408 },
    { lat: 48.32592, lng: 18.477241 },
    { lat: 48.326127, lng: 18.476926 },
    { lat: 48.326303, lng: 18.47682 },
    { lat: 48.327145, lng: 18.476488 },
    { lat: 48.327308, lng: 18.476393 },
    { lat: 48.327426, lng: 18.476311 },
    { lat: 48.327776, lng: 18.475932 },
    { lat: 48.328052, lng: 18.475631 },
    { lat: 48.3283, lng: 18.475364 },
    { lat: 48.328415, lng: 18.475239 },
    { lat: 48.328649, lng: 18.47493 },
    { lat: 48.328948, lng: 18.474509 },
    { lat: 48.32916, lng: 18.47413 },
    { lat: 48.329505, lng: 18.473419 },
    { lat: 48.3297, lng: 18.472933 },
    { lat: 48.329813, lng: 18.472665 },
    { lat: 48.330035, lng: 18.472188 },
    { lat: 48.330258, lng: 18.47177 },
    { lat: 48.330502, lng: 18.471336 },
    { lat: 48.330841, lng: 18.470836 },
    { lat: 48.331219, lng: 18.470276 },
    { lat: 48.331371, lng: 18.470073 },
    { lat: 48.331487, lng: 18.469903 },
    { lat: 48.33166, lng: 18.469649 },
    { lat: 48.331693, lng: 18.46971 },
    { lat: 48.331702, lng: 18.469736 },
    { lat: 48.331712, lng: 18.469762 },
    { lat: 48.331776, lng: 18.469945 },
    { lat: 48.33182, lng: 18.470072 },
    { lat: 48.331902, lng: 18.470621 },
    { lat: 48.331995, lng: 18.471349 },
    { lat: 48.332011, lng: 18.471741 },
    { lat: 48.332045, lng: 18.472214 },
    { lat: 48.332039, lng: 18.472306 },
    { lat: 48.332037, lng: 18.472332 },
    { lat: 48.332031, lng: 18.472414 },
    { lat: 48.332024, lng: 18.472504 },
    { lat: 48.33202, lng: 18.472553 },
    { lat: 48.332015, lng: 18.47263 },
    { lat: 48.332004, lng: 18.472687 },
    { lat: 48.331993, lng: 18.472741 },
    { lat: 48.331938, lng: 18.473017 },
    { lat: 48.331885, lng: 18.473279 },
    { lat: 48.331868, lng: 18.473361 },
    { lat: 48.331832, lng: 18.473544 },
    { lat: 48.331779, lng: 18.473799 },
    { lat: 48.33176, lng: 18.473897 },
    { lat: 48.331756, lng: 18.473916 },
    { lat: 48.331753, lng: 18.473944 },
    { lat: 48.331748, lng: 18.47399 },
    { lat: 48.331736, lng: 18.474117 },
    { lat: 48.331723, lng: 18.474246 },
    { lat: 48.331675, lng: 18.474723 },
    { lat: 48.331665, lng: 18.474819 },
    { lat: 48.331654, lng: 18.474932 },
    { lat: 48.33165, lng: 18.474981 },
    { lat: 48.331645, lng: 18.475056 },
    { lat: 48.331638, lng: 18.475141 },
    { lat: 48.331634, lng: 18.475193 },
    { lat: 48.33163, lng: 18.475242 },
    { lat: 48.331627, lng: 18.475291 },
    { lat: 48.331623, lng: 18.475346 },
    { lat: 48.331615, lng: 18.475455 },
    { lat: 48.331607, lng: 18.47556 },
    { lat: 48.3316, lng: 18.475657 },
    { lat: 48.331594, lng: 18.475731 },
    { lat: 48.331589, lng: 18.4758 },
    { lat: 48.33159, lng: 18.475879 },
    { lat: 48.331591, lng: 18.475929 },
    { lat: 48.331591, lng: 18.475976 },
    { lat: 48.331591, lng: 18.476003 },
    { lat: 48.331592, lng: 18.47604 },
    { lat: 48.331593, lng: 18.476138 },
    { lat: 48.331595, lng: 18.47628 },
    { lat: 48.331596, lng: 18.476342 },
    { lat: 48.331597, lng: 18.476404 },
    { lat: 48.331597, lng: 18.476469 },
    { lat: 48.331598, lng: 18.476537 },
    { lat: 48.331599, lng: 18.476605 },
    { lat: 48.331597, lng: 18.476727 },
    { lat: 48.331593, lng: 18.47685 },
    { lat: 48.331592, lng: 18.476928 },
    { lat: 48.33159, lng: 18.477014 },
    { lat: 48.331588, lng: 18.477099 },
    { lat: 48.33159, lng: 18.477233 },
    { lat: 48.33159, lng: 18.477348 },
    { lat: 48.331592, lng: 18.477463 },
    { lat: 48.331592, lng: 18.477509 },
    { lat: 48.331589, lng: 18.47757 },
    { lat: 48.331589, lng: 18.477603 },
    { lat: 48.331586, lng: 18.47769 },
    { lat: 48.331584, lng: 18.477722 },
    { lat: 48.331583, lng: 18.477769 },
    { lat: 48.331582, lng: 18.477783 },
    { lat: 48.331572, lng: 18.477875 },
    { lat: 48.331563, lng: 18.477967 },
    { lat: 48.331552, lng: 18.478065 },
    { lat: 48.33155, lng: 18.478163 },
    { lat: 48.331548, lng: 18.478243 },
    { lat: 48.331541, lng: 18.478473 },
    { lat: 48.331539, lng: 18.478524 },
    { lat: 48.331539, lng: 18.478576 },
    { lat: 48.331537, lng: 18.478637 },
    { lat: 48.331535, lng: 18.478696 },
    { lat: 48.331534, lng: 18.478765 },
    { lat: 48.331533, lng: 18.478777 },
    { lat: 48.331536, lng: 18.478846 },
    { lat: 48.331537, lng: 18.478913 },
    { lat: 48.331542, lng: 18.479033 },
    { lat: 48.331546, lng: 18.479187 },
    { lat: 48.33155, lng: 18.479345 },
    { lat: 48.331553, lng: 18.479418 },
    { lat: 48.331555, lng: 18.47949 },
    { lat: 48.331556, lng: 18.479534 },
    { lat: 48.331558, lng: 18.479586 },
    { lat: 48.331558, lng: 18.479638 },
    { lat: 48.33156, lng: 18.47969 },
    { lat: 48.331562, lng: 18.47974 },
    { lat: 48.331564, lng: 18.479814 },
    { lat: 48.331566, lng: 18.479879 },
    { lat: 48.331568, lng: 18.479931 },
    { lat: 48.33157, lng: 18.479983 },
    { lat: 48.331571, lng: 18.480035 },
    { lat: 48.331573, lng: 18.480086 },
    { lat: 48.331576, lng: 18.480196 },
    { lat: 48.331578, lng: 18.48023 },
    { lat: 48.331579, lng: 18.480244 },
    { lat: 48.331583, lng: 18.480294 },
    { lat: 48.331592, lng: 18.480434 },
    { lat: 48.331598, lng: 18.4805 },
    { lat: 48.331603, lng: 18.480568 },
    { lat: 48.33161, lng: 18.480655 },
    { lat: 48.331616, lng: 18.480741 },
    { lat: 48.331618, lng: 18.480793 },
    { lat: 48.331619, lng: 18.480835 },
    { lat: 48.331621, lng: 18.480891 },
    { lat: 48.331622, lng: 18.480929 },
    { lat: 48.331624, lng: 18.480975 },
    { lat: 48.331625, lng: 18.481023 },
    { lat: 48.331627, lng: 18.481084 },
    { lat: 48.331629, lng: 18.481123 },
    { lat: 48.331632, lng: 18.481207 },
    { lat: 48.331634, lng: 18.48129 },
    { lat: 48.331637, lng: 18.481374 },
    { lat: 48.33164, lng: 18.481455 },
    { lat: 48.331651, lng: 18.48157 },
    { lat: 48.331656, lng: 18.481613 },
    { lat: 48.331657, lng: 18.481633 },
    { lat: 48.331661, lng: 18.481674 },
    { lat: 48.331666, lng: 18.481718 },
    { lat: 48.331673, lng: 18.481786 },
    { lat: 48.331682, lng: 18.481849 },
    { lat: 48.33169, lng: 18.481905 },
    { lat: 48.331695, lng: 18.481936 },
    { lat: 48.331706, lng: 18.482014 },
    { lat: 48.331714, lng: 18.482069 },
    { lat: 48.331723, lng: 18.482134 },
    { lat: 48.331733, lng: 18.482199 },
    { lat: 48.331742, lng: 18.482263 },
    { lat: 48.331759, lng: 18.482374 },
    { lat: 48.331773, lng: 18.482442 },
    { lat: 48.331787, lng: 18.48251 },
    { lat: 48.3318, lng: 18.482578 },
    { lat: 48.331814, lng: 18.482643 },
    { lat: 48.331828, lng: 18.482714 },
    { lat: 48.331842, lng: 18.482784 },
    { lat: 48.331851, lng: 18.482828 },
    { lat: 48.331858, lng: 18.48289 },
    { lat: 48.331869, lng: 18.482959 },
    { lat: 48.331878, lng: 18.483029 },
    { lat: 48.331889, lng: 18.4831 },
    { lat: 48.3319, lng: 18.483175 },
    { lat: 48.33191, lng: 18.483247 },
    { lat: 48.331921, lng: 18.483319 },
    { lat: 48.331925, lng: 18.483409 },
    { lat: 48.331931, lng: 18.483495 },
    { lat: 48.331936, lng: 18.483577 },
    { lat: 48.331939, lng: 18.483617 },
    { lat: 48.331943, lng: 18.483678 },
    { lat: 48.331959, lng: 18.483789 },
    { lat: 48.331963, lng: 18.483818 },
    { lat: 48.33197, lng: 18.483874 },
    { lat: 48.331984, lng: 18.483973 },
    { lat: 48.331986, lng: 18.484001 },
    { lat: 48.33199, lng: 18.484042 },
    { lat: 48.331995, lng: 18.484115 },
    { lat: 48.331999, lng: 18.48416 },
    { lat: 48.332004, lng: 18.484223 },
    { lat: 48.332008, lng: 18.484294 },
    { lat: 48.332014, lng: 18.484369 },
    { lat: 48.332019, lng: 18.484438 },
    { lat: 48.332024, lng: 18.484503 },
    { lat: 48.332028, lng: 18.484564 },
    { lat: 48.332033, lng: 18.484633 },
    { lat: 48.332037, lng: 18.484685 },
    { lat: 48.332088, lng: 18.485157 },
    { lat: 48.332109, lng: 18.48535 },
    { lat: 48.332127, lng: 18.485522 },
    { lat: 48.332224, lng: 18.485689 },
    { lat: 48.332245, lng: 18.485726 },
    { lat: 48.332258, lng: 18.485751 },
    { lat: 48.33241, lng: 18.486117 },
    { lat: 48.332619, lng: 18.486644 },
    { lat: 48.332664, lng: 18.486777 },
    { lat: 48.332689, lng: 18.486853 },
    { lat: 48.332942, lng: 18.487797 },
    { lat: 48.33299, lng: 18.487935 },
    { lat: 48.333009, lng: 18.487993 },
    { lat: 48.333063, lng: 18.488157 },
    { lat: 48.33311, lng: 18.488252 },
    { lat: 48.333181, lng: 18.488399 },
    { lat: 48.333235, lng: 18.48851 },
    { lat: 48.33338, lng: 18.488697 },
    { lat: 48.333459, lng: 18.488865 },
    { lat: 48.333579, lng: 18.489233 },
    { lat: 48.333639, lng: 18.489332 },
    { lat: 48.333743, lng: 18.48947 },
    { lat: 48.333846, lng: 18.489607 },
    { lat: 48.333906, lng: 18.4897 },
    { lat: 48.334032, lng: 18.489961 },
    { lat: 48.334257, lng: 18.490495 },
    { lat: 48.334975, lng: 18.4896012 },
    { lat: 48.3349314, lng: 18.4895392 },
    { lat: 48.3354359, lng: 18.4887549 },
    { lat: 48.3363391, lng: 18.4876879 },
    { lat: 48.3368253, lng: 18.487383 },
    { lat: 48.3374238, lng: 18.485826 },
    { lat: 48.3384253, lng: 18.4842768 },
    { lat: 48.3392759, lng: 18.4819376 },
    { lat: 48.3410418, lng: 18.4814347 },
    { lat: 48.3417472, lng: 18.4820112 },
    { lat: 48.3428458, lng: 18.4825368 },
    { lat: 48.3439931, lng: 18.4826788 },
    { lat: 48.346155, lng: 18.4836279 },
    { lat: 48.3468841, lng: 18.4840157 },
    { lat: 48.3487145, lng: 18.4854754 },
    { lat: 48.3498643, lng: 18.4869477 },
    { lat: 48.3506962, lng: 18.4884201 },
    { lat: 48.3513931, lng: 18.4894539 },
    { lat: 48.3525004, lng: 18.4899285 },
    { lat: 48.3548402, lng: 18.4903631 },
    { lat: 48.356337, lng: 18.4904447 },
    { lat: 48.3574596, lng: 18.4911184 },
    { lat: 48.3592397, lng: 18.4919954 },
    { lat: 48.3603452, lng: 18.4919939 },
    { lat: 48.362917, lng: 18.4922948 },
    { lat: 48.3646167, lng: 18.4923523 },
    { lat: 48.3695424, lng: 18.4920538 },
    { lat: 48.3727818, lng: 18.4927194 },
    { lat: 48.3733224, lng: 18.4931941 },
    { lat: 48.3745678, lng: 18.4937045 },
    { lat: 48.3756828, lng: 18.493814 },
    { lat: 48.374606, lng: 18.4934975 },
    { lat: 48.3739594, lng: 18.4928 },
    { lat: 48.3730875, lng: 18.4916399 },
    { lat: 48.3721715, lng: 18.4898463 },
    { lat: 48.3713272, lng: 18.4884306 },
    { lat: 48.3695469, lng: 18.4866148 },
    { lat: 48.3688349, lng: 18.48559 },
    { lat: 48.3685517, lng: 18.4848405 },
    { lat: 48.3680776, lng: 18.484099 },
    { lat: 48.367774, lng: 18.4833049 },
    { lat: 48.3669095, lng: 18.4817518 },
    { lat: 48.3662325, lng: 18.4810573 },
    { lat: 48.3652189, lng: 18.4804949 },
    { lat: 48.364851, lng: 18.4790906 },
    { lat: 48.3646149, lng: 18.4773751 },
    { lat: 48.3645264, lng: 18.4761892 },
    { lat: 48.364656, lng: 18.4754099 },
    { lat: 48.3628128, lng: 18.4748254 },
    { lat: 48.3627856, lng: 18.4747603 },
    { lat: 48.3620964, lng: 18.4746389 },
    { lat: 48.3619378, lng: 18.4744874 },
    { lat: 48.3618594, lng: 18.4745181 },
    { lat: 48.3616273, lng: 18.4743989 },
    { lat: 48.3614093, lng: 18.4742108 },
    { lat: 48.361278, lng: 18.4742432 },
    { lat: 48.3612447, lng: 18.4743332 },
    { lat: 48.3611337, lng: 18.4743701 },
    { lat: 48.360474, lng: 18.4742092 },
    { lat: 48.3602683, lng: 18.4743871 },
    { lat: 48.3601773, lng: 18.4744336 },
    { lat: 48.3600622, lng: 18.4743926 },
    { lat: 48.3595983, lng: 18.4747138 },
    { lat: 48.359303, lng: 18.474739 },
    { lat: 48.3590233, lng: 18.4749843 },
    { lat: 48.3587997, lng: 18.4750409 },
    { lat: 48.3585255, lng: 18.4749993 },
    { lat: 48.3582296, lng: 18.4754978 },
    { lat: 48.356473, lng: 18.4769608 },
    { lat: 48.3550512, lng: 18.4771153 },
    { lat: 48.3548987, lng: 18.4769106 },
    { lat: 48.3530004, lng: 18.4762443 },
    { lat: 48.352741, lng: 18.4759192 },
    { lat: 48.3525033, lng: 18.4759042 },
    { lat: 48.3523134, lng: 18.475613 },
    { lat: 48.3522033, lng: 18.4756023 },
    { lat: 48.3520316, lng: 18.4757188 },
    { lat: 48.3520035, lng: 18.4756226 },
    { lat: 48.3516873, lng: 18.4756366 },
    { lat: 48.3515355, lng: 18.4758067 },
    { lat: 48.3510959, lng: 18.4755275 },
    { lat: 48.3510954, lng: 18.4751935 },
    { lat: 48.3509507, lng: 18.4748445 },
    { lat: 48.3503446, lng: 18.4740655 },
    { lat: 48.3499745, lng: 18.4736752 },
    { lat: 48.3497991, lng: 18.4733641 },
    { lat: 48.349624, lng: 18.4729742 },
    { lat: 48.3494991, lng: 18.4725128 },
    { lat: 48.3492273, lng: 18.4720034 },
    { lat: 48.3487255, lng: 18.4707483 },
    { lat: 48.3480202, lng: 18.4706021 },
    { lat: 48.3472816, lng: 18.4695312 },
    { lat: 48.3468944, lng: 18.4695662 },
    { lat: 48.3463027, lng: 18.469293 },
    { lat: 48.3458458, lng: 18.4693206 },
    { lat: 48.3454615, lng: 18.46944 },
    { lat: 48.3450016, lng: 18.4697145 },
    { lat: 48.3447344, lng: 18.4699823 },
    { lat: 48.3439768, lng: 18.4696629 },
    { lat: 48.3437652, lng: 18.4696922 },
    { lat: 48.3435264, lng: 18.4701263 },
    { lat: 48.3430915, lng: 18.4698718 },
    { lat: 48.3422664, lng: 18.4692154 },
    { lat: 48.3427194, lng: 18.468575 },
    { lat: 48.3447097, lng: 18.4663481 },
    { lat: 48.344841, lng: 18.4663363 },
    { lat: 48.3486667, lng: 18.4633358 },
    { lat: 48.3493563, lng: 18.4628782 },
    { lat: 48.3505774, lng: 18.4622756 },
    { lat: 48.3501708, lng: 18.4600592 },
    { lat: 48.3486525, lng: 18.46 },
    { lat: 48.3482011, lng: 18.4580911 },
    { lat: 48.3478728, lng: 18.4582752 },
    { lat: 48.3470508, lng: 18.4549357 },
    { lat: 48.3502964, lng: 18.45352 },
    { lat: 48.3499244, lng: 18.4495768 },
    { lat: 48.350537, lng: 18.4492556 },
    { lat: 48.3501713, lng: 18.4460124 },
  ],
  Ladice: [
    { lat: 48.373346, lng: 18.265312 },
    { lat: 48.3741727, lng: 18.2664494 },
    { lat: 48.3744151, lng: 18.2670764 },
    { lat: 48.3753279, lng: 18.2705995 },
    { lat: 48.3759426, lng: 18.2740047 },
    { lat: 48.3757216, lng: 18.2737908 },
    { lat: 48.3753053, lng: 18.2739492 },
    { lat: 48.3753633, lng: 18.2740737 },
    { lat: 48.3754701, lng: 18.2740678 },
    { lat: 48.3754995, lng: 18.2741683 },
    { lat: 48.3756272, lng: 18.2742115 },
    { lat: 48.3756208, lng: 18.274355 },
    { lat: 48.3757106, lng: 18.2745656 },
    { lat: 48.3759259, lng: 18.274679 },
    { lat: 48.3766024, lng: 18.2785551 },
    { lat: 48.3764121, lng: 18.2785221 },
    { lat: 48.3768838, lng: 18.2838091 },
    { lat: 48.3767368, lng: 18.2854234 },
    { lat: 48.3764859, lng: 18.2861261 },
    { lat: 48.3765013, lng: 18.2862178 },
    { lat: 48.3768376, lng: 18.2865922 },
    { lat: 48.3768336, lng: 18.2866414 },
    { lat: 48.377079, lng: 18.2867123 },
    { lat: 48.3794823, lng: 18.2896317 },
    { lat: 48.3817443, lng: 18.2957955 },
    { lat: 48.3823603, lng: 18.2976951 },
    { lat: 48.3834829, lng: 18.2975906 },
    { lat: 48.385025, lng: 18.2971322 },
    { lat: 48.3886327, lng: 18.2974998 },
    { lat: 48.3916087, lng: 18.2969755 },
    { lat: 48.3936439, lng: 18.2963379 },
    { lat: 48.3950812, lng: 18.2969462 },
    { lat: 48.3962183, lng: 18.2971429 },
    { lat: 48.3990558, lng: 18.2974548 },
    { lat: 48.399734, lng: 18.2959715 },
    { lat: 48.4005654, lng: 18.2947631 },
    { lat: 48.4011288, lng: 18.293445 },
    { lat: 48.4018476, lng: 18.2915686 },
    { lat: 48.4022861, lng: 18.2899179 },
    { lat: 48.4029975, lng: 18.2890781 },
    { lat: 48.4035096, lng: 18.2881077 },
    { lat: 48.4040947, lng: 18.2867374 },
    { lat: 48.405416, lng: 18.2836429 },
    { lat: 48.4054881, lng: 18.2834741 },
    { lat: 48.4065709, lng: 18.2815909 },
    { lat: 48.4066712, lng: 18.2814282 },
    { lat: 48.407592, lng: 18.2799351 },
    { lat: 48.4079481, lng: 18.2792254 },
    { lat: 48.4083689, lng: 18.278387 },
    { lat: 48.4084433, lng: 18.2782772 },
    { lat: 48.4094615, lng: 18.2767747 },
    { lat: 48.4113055, lng: 18.2735258 },
    { lat: 48.4110194, lng: 18.2729434 },
    { lat: 48.4108047, lng: 18.2725019 },
    { lat: 48.4099474, lng: 18.2700033 },
    { lat: 48.4076302, lng: 18.2640044 },
    { lat: 48.40512, lng: 18.2578299 },
    { lat: 48.40444, lng: 18.2559874 },
    { lat: 48.4036609, lng: 18.2541696 },
    { lat: 48.4028032, lng: 18.251219 },
    { lat: 48.4015348, lng: 18.2478589 },
    { lat: 48.4013624, lng: 18.2460365 },
    { lat: 48.401077, lng: 18.24453 },
    { lat: 48.400987, lng: 18.244196 },
    { lat: 48.400798, lng: 18.243559 },
    { lat: 48.399823, lng: 18.242354 },
    { lat: 48.399608, lng: 18.242231 },
    { lat: 48.398825, lng: 18.241782 },
    { lat: 48.396895, lng: 18.240683 },
    { lat: 48.395747, lng: 18.241061 },
    { lat: 48.395539, lng: 18.24113 },
    { lat: 48.394043, lng: 18.241498 },
    { lat: 48.393942, lng: 18.241525 },
    { lat: 48.39382, lng: 18.241553 },
    { lat: 48.393762, lng: 18.241567 },
    { lat: 48.393007, lng: 18.241927 },
    { lat: 48.391944, lng: 18.242676 },
    { lat: 48.39188, lng: 18.242721 },
    { lat: 48.391834, lng: 18.242753 },
    { lat: 48.391693, lng: 18.242852 },
    { lat: 48.391567, lng: 18.242941 },
    { lat: 48.391554, lng: 18.24295 },
    { lat: 48.391514, lng: 18.242978 },
    { lat: 48.390265, lng: 18.243851 },
    { lat: 48.39002, lng: 18.244022 },
    { lat: 48.38952, lng: 18.244556 },
    { lat: 48.388852, lng: 18.245268 },
    { lat: 48.387378, lng: 18.246821 },
    { lat: 48.387374, lng: 18.246825 },
    { lat: 48.387288, lng: 18.246916 },
    { lat: 48.387283, lng: 18.246922 },
    { lat: 48.387011, lng: 18.247306 },
    { lat: 48.386298, lng: 18.248369 },
    { lat: 48.385929, lng: 18.248924 },
    { lat: 48.385494, lng: 18.249568 },
    { lat: 48.385148, lng: 18.250086 },
    { lat: 48.384642, lng: 18.250842 },
    { lat: 48.384271, lng: 18.251396 },
    { lat: 48.383877, lng: 18.251991 },
    { lat: 48.383369, lng: 18.252755 },
    { lat: 48.382991, lng: 18.253318 },
    { lat: 48.382995, lng: 18.253324 },
    { lat: 48.382941, lng: 18.253405 },
    { lat: 48.382935, lng: 18.253399 },
    { lat: 48.382718, lng: 18.253732 },
    { lat: 48.382447, lng: 18.254135 },
    { lat: 48.382064, lng: 18.25471 },
    { lat: 48.381544, lng: 18.25549 },
    { lat: 48.381378, lng: 18.25574 },
    { lat: 48.3803, lng: 18.257647 },
    { lat: 48.379964, lng: 18.258249 },
    { lat: 48.37859, lng: 18.259736 },
    { lat: 48.377658, lng: 18.260753 },
    { lat: 48.376635, lng: 18.261865 },
    { lat: 48.375918, lng: 18.262632 },
    { lat: 48.375784, lng: 18.262774 },
    { lat: 48.375609, lng: 18.262959 },
    { lat: 48.375602, lng: 18.262966 },
    { lat: 48.375579, lng: 18.26299 },
    { lat: 48.37503, lng: 18.263564 },
    { lat: 48.374965, lng: 18.263631 },
    { lat: 48.374884, lng: 18.263715 },
    { lat: 48.374872, lng: 18.263728 },
    { lat: 48.374639, lng: 18.263971 },
    { lat: 48.373417, lng: 18.265244 },
    { lat: 48.373409, lng: 18.265252 },
    { lat: 48.373403, lng: 18.265258 },
    { lat: 48.373346, lng: 18.265312 },
  ],
  Skýcov: [
    { lat: 48.546598, lng: 18.46809 },
    { lat: 48.546456, lng: 18.468042 },
    { lat: 48.546147, lng: 18.468016 },
    { lat: 48.545477, lng: 18.46777 },
    { lat: 48.544535, lng: 18.467005 },
    { lat: 48.543679, lng: 18.466198 },
    { lat: 48.541864, lng: 18.464378 },
    { lat: 48.541826, lng: 18.464341 },
    { lat: 48.541694, lng: 18.464207 },
    { lat: 48.540943, lng: 18.463832 },
    { lat: 48.540528, lng: 18.463725 },
    { lat: 48.540481, lng: 18.463701 },
    { lat: 48.540465, lng: 18.463691 },
    { lat: 48.540205, lng: 18.463572 },
    { lat: 48.539865, lng: 18.463252 },
    { lat: 48.539638, lng: 18.462813 },
    { lat: 48.539124, lng: 18.461712 },
    { lat: 48.53912, lng: 18.461506 },
    { lat: 48.538893, lng: 18.460733 },
    { lat: 48.538733, lng: 18.460165 },
    { lat: 48.538495, lng: 18.458471 },
    { lat: 48.538311, lng: 18.457125 },
    { lat: 48.537378, lng: 18.455205 },
    { lat: 48.536744, lng: 18.453873 },
    { lat: 48.536578, lng: 18.453321 },
    { lat: 48.536099, lng: 18.451727 },
    { lat: 48.535617, lng: 18.450664 },
    { lat: 48.534446, lng: 18.450026 },
    { lat: 48.534128, lng: 18.449654 },
    { lat: 48.533441, lng: 18.449298 },
    { lat: 48.533075, lng: 18.448917 },
    { lat: 48.532638, lng: 18.44851 },
    { lat: 48.532267, lng: 18.448159 },
    { lat: 48.532179, lng: 18.44808 },
    { lat: 48.531906, lng: 18.447716 },
    { lat: 48.531456, lng: 18.446872 },
    { lat: 48.5312, lng: 18.446502 },
    { lat: 48.531068, lng: 18.446188 },
    { lat: 48.531037, lng: 18.446109 },
    { lat: 48.530868, lng: 18.445611 },
    { lat: 48.530809, lng: 18.445211 },
    { lat: 48.530702, lng: 18.4449 },
    { lat: 48.530607, lng: 18.444438 },
    { lat: 48.530526, lng: 18.444221 },
    { lat: 48.530374, lng: 18.444063 },
    { lat: 48.530098, lng: 18.443664 },
    { lat: 48.529763, lng: 18.443398 },
    { lat: 48.529351, lng: 18.442986 },
    { lat: 48.529243, lng: 18.442758 },
    { lat: 48.52902, lng: 18.442258 },
    { lat: 48.528623, lng: 18.44139 },
    { lat: 48.528577, lng: 18.441299 },
    { lat: 48.528419, lng: 18.440232 },
    { lat: 48.528398, lng: 18.440094 },
    { lat: 48.528368, lng: 18.439887 },
    { lat: 48.52791, lng: 18.437264 },
    { lat: 48.527424, lng: 18.434876 },
    { lat: 48.526434, lng: 18.432097 },
    { lat: 48.526265, lng: 18.430971 },
    { lat: 48.526074, lng: 18.429755 },
    { lat: 48.524149, lng: 18.424607 },
    { lat: 48.523908, lng: 18.423955 },
    { lat: 48.523813, lng: 18.423692 },
    { lat: 48.523722, lng: 18.423434 },
    { lat: 48.523601, lng: 18.423057 },
    { lat: 48.523558, lng: 18.422908 },
    { lat: 48.523443, lng: 18.422326 },
    { lat: 48.522849, lng: 18.419531 },
    { lat: 48.522429, lng: 18.417763 },
    { lat: 48.521921, lng: 18.416465 },
    { lat: 48.52167, lng: 18.415768 },
    { lat: 48.52123, lng: 18.414885 },
    { lat: 48.520987, lng: 18.414443 },
    { lat: 48.520581, lng: 18.414404 },
    { lat: 48.520289, lng: 18.414372 },
    { lat: 48.519721, lng: 18.413881 },
    { lat: 48.519446, lng: 18.413199 },
    { lat: 48.519169, lng: 18.412629 },
    { lat: 48.518147, lng: 18.412113 },
    { lat: 48.51695, lng: 18.411179 },
    { lat: 48.516439, lng: 18.4104 },
    { lat: 48.515986, lng: 18.409719 },
    { lat: 48.515585, lng: 18.409198 },
    { lat: 48.515507, lng: 18.408994 },
    { lat: 48.515111, lng: 18.406691 },
    { lat: 48.514523, lng: 18.404677 },
    { lat: 48.514043, lng: 18.403912 },
    { lat: 48.51307, lng: 18.402731 },
    { lat: 48.511541, lng: 18.401366 },
    { lat: 48.511335, lng: 18.40111 },
    { lat: 48.511254, lng: 18.401004 },
    { lat: 48.511246, lng: 18.400992 },
    { lat: 48.510528, lng: 18.399668 },
    { lat: 48.509483, lng: 18.397612 },
    { lat: 48.509043, lng: 18.396588 },
    { lat: 48.50835, lng: 18.395485 },
    { lat: 48.508164, lng: 18.395206 },
    { lat: 48.507373, lng: 18.394096 },
    { lat: 48.506592, lng: 18.393136 },
    { lat: 48.505886, lng: 18.392434 },
    { lat: 48.505368, lng: 18.392081 },
    { lat: 48.505232, lng: 18.391914 },
    { lat: 48.504505, lng: 18.391033 },
    { lat: 48.504487, lng: 18.390788 },
    { lat: 48.5039455, lng: 18.3917474 },
    { lat: 48.5033085, lng: 18.3939283 },
    { lat: 48.5029057, lng: 18.3945696 },
    { lat: 48.5005456, lng: 18.3966435 },
    { lat: 48.4996546, lng: 18.3972517 },
    { lat: 48.4985436, lng: 18.3979415 },
    { lat: 48.4974411, lng: 18.3984993 },
    { lat: 48.4973798, lng: 18.398528 },
    { lat: 48.4963545, lng: 18.3990074 },
    { lat: 48.4940859, lng: 18.400068 },
    { lat: 48.4916591, lng: 18.4015394 },
    { lat: 48.4912982, lng: 18.4012153 },
    { lat: 48.4890681, lng: 18.4018692 },
    { lat: 48.4826177, lng: 18.405887 },
    { lat: 48.4803585, lng: 18.4070632 },
    { lat: 48.4787627, lng: 18.4085022 },
    { lat: 48.4780287, lng: 18.408833 },
    { lat: 48.4774857, lng: 18.4091912 },
    { lat: 48.4772473, lng: 18.4095286 },
    { lat: 48.4770337, lng: 18.4100449 },
    { lat: 48.4768465, lng: 18.4102869 },
    { lat: 48.4767993, lng: 18.4102082 },
    { lat: 48.4763771, lng: 18.4108287 },
    { lat: 48.4760198, lng: 18.4110773 },
    { lat: 48.4757777, lng: 18.4113252 },
    { lat: 48.4761041, lng: 18.41184 },
    { lat: 48.4762776, lng: 18.4122506 },
    { lat: 48.4763998, lng: 18.412486 },
    { lat: 48.4766883, lng: 18.4128054 },
    { lat: 48.4772043, lng: 18.412951 },
    { lat: 48.4774686, lng: 18.4131796 },
    { lat: 48.4785985, lng: 18.413554 },
    { lat: 48.4788457, lng: 18.4138993 },
    { lat: 48.4792986, lng: 18.4141091 },
    { lat: 48.4795215, lng: 18.4143241 },
    { lat: 48.479639, lng: 18.4145492 },
    { lat: 48.4796523, lng: 18.4146862 },
    { lat: 48.4799202, lng: 18.414838 },
    { lat: 48.4794367, lng: 18.4159324 },
    { lat: 48.4803203, lng: 18.4167264 },
    { lat: 48.4812142, lng: 18.4171976 },
    { lat: 48.4825652, lng: 18.4178564 },
    { lat: 48.4845416, lng: 18.4189444 },
    { lat: 48.4864996, lng: 18.4195241 },
    { lat: 48.4869453, lng: 18.4196291 },
    { lat: 48.4887944, lng: 18.4201705 },
    { lat: 48.4898119, lng: 18.4208961 },
    { lat: 48.4901131, lng: 18.4213613 },
    { lat: 48.4901932, lng: 18.422331 },
    { lat: 48.4903001, lng: 18.4225679 },
    { lat: 48.4904222, lng: 18.4225532 },
    { lat: 48.49049, lng: 18.4228146 },
    { lat: 48.4904404, lng: 18.4230399 },
    { lat: 48.4905793, lng: 18.4234459 },
    { lat: 48.4905194, lng: 18.4237053 },
    { lat: 48.4906253, lng: 18.4237446 },
    { lat: 48.4906912, lng: 18.4238532 },
    { lat: 48.4906488, lng: 18.4243256 },
    { lat: 48.4905864, lng: 18.4245415 },
    { lat: 48.4907461, lng: 18.4244552 },
    { lat: 48.4907553, lng: 18.42474 },
    { lat: 48.4908183, lng: 18.4248383 },
    { lat: 48.4907951, lng: 18.4250885 },
    { lat: 48.4911784, lng: 18.4259548 },
    { lat: 48.4912853, lng: 18.426069 },
    { lat: 48.4912993, lng: 18.426218 },
    { lat: 48.4913861, lng: 18.4263415 },
    { lat: 48.4915969, lng: 18.427065 },
    { lat: 48.4916178, lng: 18.4274397 },
    { lat: 48.4914982, lng: 18.4274402 },
    { lat: 48.4913739, lng: 18.4278023 },
    { lat: 48.4914952, lng: 18.4282557 },
    { lat: 48.4914124, lng: 18.428491 },
    { lat: 48.4909058, lng: 18.4293604 },
    { lat: 48.4910039, lng: 18.4299239 },
    { lat: 48.4913291, lng: 18.4304568 },
    { lat: 48.4913858, lng: 18.4318983 },
    { lat: 48.4912807, lng: 18.4319759 },
    { lat: 48.490894, lng: 18.4325887 },
    { lat: 48.4904956, lng: 18.4325821 },
    { lat: 48.4904215, lng: 18.4327123 },
    { lat: 48.4902184, lng: 18.4327913 },
    { lat: 48.4899024, lng: 18.4331153 },
    { lat: 48.4897671, lng: 18.4331783 },
    { lat: 48.4890497, lng: 18.4343499 },
    { lat: 48.4880574, lng: 18.4351276 },
    { lat: 48.4881363, lng: 18.435836 },
    { lat: 48.4875228, lng: 18.4370958 },
    { lat: 48.4875558, lng: 18.4372207 },
    { lat: 48.4870445, lng: 18.4376251 },
    { lat: 48.487226, lng: 18.4381289 },
    { lat: 48.4882134, lng: 18.4391471 },
    { lat: 48.488105, lng: 18.4397092 },
    { lat: 48.4887899, lng: 18.4405845 },
    { lat: 48.48922, lng: 18.4416435 },
    { lat: 48.4889776, lng: 18.4425175 },
    { lat: 48.4897327, lng: 18.4461133 },
    { lat: 48.4909301, lng: 18.4469338 },
    { lat: 48.489423, lng: 18.4535131 },
    { lat: 48.4887974, lng: 18.455085 },
    { lat: 48.4885629, lng: 18.4553698 },
    { lat: 48.4883902, lng: 18.455713 },
    { lat: 48.4879589, lng: 18.4570268 },
    { lat: 48.4877393, lng: 18.4575836 },
    { lat: 48.4874402, lng: 18.4581496 },
    { lat: 48.4873657, lng: 18.4584936 },
    { lat: 48.4871247, lng: 18.4589687 },
    { lat: 48.4871133, lng: 18.459069 },
    { lat: 48.4868418, lng: 18.459399 },
    { lat: 48.4866084, lng: 18.4596242 },
    { lat: 48.4858232, lng: 18.4599917 },
    { lat: 48.4846232, lng: 18.4613197 },
    { lat: 48.484371, lng: 18.4610221 },
    { lat: 48.484108, lng: 18.4612845 },
    { lat: 48.4841328, lng: 18.4614453 },
    { lat: 48.4840857, lng: 18.4615941 },
    { lat: 48.4838963, lng: 18.4617961 },
    { lat: 48.4836678, lng: 18.4619474 },
    { lat: 48.483349, lng: 18.4619363 },
    { lat: 48.483076, lng: 18.4618347 },
    { lat: 48.4829271, lng: 18.4623245 },
    { lat: 48.4824624, lng: 18.4625685 },
    { lat: 48.4822684, lng: 18.4625701 },
    { lat: 48.4816661, lng: 18.4628562 },
    { lat: 48.4820373, lng: 18.4634073 },
    { lat: 48.4821378, lng: 18.46365 },
    { lat: 48.4822785, lng: 18.4638101 },
    { lat: 48.4821557, lng: 18.4650142 },
    { lat: 48.4821593, lng: 18.4655041 },
    { lat: 48.4820825, lng: 18.4656844 },
    { lat: 48.4820475, lng: 18.4660934 },
    { lat: 48.4820243, lng: 18.4666297 },
    { lat: 48.4821039, lng: 18.4667431 },
    { lat: 48.4820345, lng: 18.4670999 },
    { lat: 48.4820642, lng: 18.4678191 },
    { lat: 48.4818864, lng: 18.4684579 },
    { lat: 48.4818899, lng: 18.4685798 },
    { lat: 48.4815327, lng: 18.4691768 },
    { lat: 48.4811228, lng: 18.4693942 },
    { lat: 48.4806803, lng: 18.4693697 },
    { lat: 48.4804735, lng: 18.4692001 },
    { lat: 48.4803313, lng: 18.4688734 },
    { lat: 48.4799731, lng: 18.469085 },
    { lat: 48.4790635, lng: 18.4697932 },
    { lat: 48.4789122, lng: 18.4697503 },
    { lat: 48.4787607, lng: 18.4698244 },
    { lat: 48.4775262, lng: 18.4696734 },
    { lat: 48.4771145, lng: 18.4697769 },
    { lat: 48.4770377, lng: 18.4699785 },
    { lat: 48.4768499, lng: 18.4701137 },
    { lat: 48.4762693, lng: 18.4702028 },
    { lat: 48.4758184, lng: 18.4703703 },
    { lat: 48.4744995, lng: 18.4698786 },
    { lat: 48.4735145, lng: 18.4696327 },
    { lat: 48.4731526, lng: 18.4696371 },
    { lat: 48.4729367, lng: 18.4694864 },
    { lat: 48.4727294, lng: 18.469244 },
    { lat: 48.4725684, lng: 18.4693701 },
    { lat: 48.4724671, lng: 18.4693338 },
    { lat: 48.4723533, lng: 18.4689709 },
    { lat: 48.4722022, lng: 18.4689525 },
    { lat: 48.4720879, lng: 18.4691506 },
    { lat: 48.4713091, lng: 18.4687555 },
    { lat: 48.4711643, lng: 18.468507 },
    { lat: 48.470832, lng: 18.468656 },
    { lat: 48.4700754, lng: 18.4685173 },
    { lat: 48.4698168, lng: 18.4695924 },
    { lat: 48.4699713, lng: 18.4696338 },
    { lat: 48.4711243, lng: 18.4697769 },
    { lat: 48.4717798, lng: 18.4700616 },
    { lat: 48.4720561, lng: 18.470326 },
    { lat: 48.4723883, lng: 18.4703348 },
    { lat: 48.4725127, lng: 18.4702595 },
    { lat: 48.4730611, lng: 18.4704048 },
    { lat: 48.4733143, lng: 18.47064 },
    { lat: 48.4735493, lng: 18.4710749 },
    { lat: 48.4737568, lng: 18.4712963 },
    { lat: 48.474373, lng: 18.4716439 },
    { lat: 48.4753545, lng: 18.4717904 },
    { lat: 48.4755779, lng: 18.4716878 },
    { lat: 48.475853, lng: 18.471461 },
    { lat: 48.4768298, lng: 18.4710719 },
    { lat: 48.4772381, lng: 18.4710915 },
    { lat: 48.4776324, lng: 18.471228 },
    { lat: 48.4781569, lng: 18.47167 },
    { lat: 48.4787385, lng: 18.4718687 },
    { lat: 48.4791852, lng: 18.4719696 },
    { lat: 48.4797505, lng: 18.4719915 },
    { lat: 48.4817431, lng: 18.4725906 },
    { lat: 48.482486, lng: 18.4728843 },
    { lat: 48.4829107, lng: 18.4711848 },
    { lat: 48.4825925, lng: 18.4709566 },
    { lat: 48.4819887, lng: 18.4702397 },
    { lat: 48.4819842, lng: 18.4699149 },
    { lat: 48.4824536, lng: 18.4680035 },
    { lat: 48.4824848, lng: 18.4677937 },
    { lat: 48.4824173, lng: 18.467187 },
    { lat: 48.482441, lng: 18.4669828 },
    { lat: 48.4825499, lng: 18.4668106 },
    { lat: 48.4826914, lng: 18.4667789 },
    { lat: 48.4828017, lng: 18.4659636 },
    { lat: 48.4827653, lng: 18.4653459 },
    { lat: 48.4827854, lng: 18.4643865 },
    { lat: 48.4829371, lng: 18.4639291 },
    { lat: 48.4834792, lng: 18.4629621 },
    { lat: 48.4837526, lng: 18.4626839 },
    { lat: 48.4840071, lng: 18.4625158 },
    { lat: 48.484321, lng: 18.4620134 },
    { lat: 48.4844817, lng: 18.4618787 },
    { lat: 48.4845468, lng: 18.4619118 },
    { lat: 48.4846511, lng: 18.4618393 },
    { lat: 48.4851918, lng: 18.4611754 },
    { lat: 48.4853313, lng: 18.4611305 },
    { lat: 48.4860353, lng: 18.4604992 },
    { lat: 48.4864868, lng: 18.4602394 },
    { lat: 48.4867278, lng: 18.4601686 },
    { lat: 48.4871048, lng: 18.4598699 },
    { lat: 48.4877542, lng: 18.4595387 },
    { lat: 48.4886156, lng: 18.459471 },
    { lat: 48.4889844, lng: 18.4593643 },
    { lat: 48.4891478, lng: 18.4594038 },
    { lat: 48.4895022, lng: 18.4591403 },
    { lat: 48.4896759, lng: 18.4588588 },
    { lat: 48.4900621, lng: 18.4584188 },
    { lat: 48.4908718, lng: 18.4576997 },
    { lat: 48.4911318, lng: 18.4577289 },
    { lat: 48.4914587, lng: 18.4573628 },
    { lat: 48.4916034, lng: 18.4571263 },
    { lat: 48.491797, lng: 18.4567064 },
    { lat: 48.4919202, lng: 18.4567126 },
    { lat: 48.4919436, lng: 18.4564824 },
    { lat: 48.4921895, lng: 18.4562997 },
    { lat: 48.4923644, lng: 18.456282 },
    { lat: 48.4929175, lng: 18.4556377 },
    { lat: 48.4935035, lng: 18.4551362 },
    { lat: 48.4934258, lng: 18.45504 },
    { lat: 48.4934949, lng: 18.45482 },
    { lat: 48.4934609, lng: 18.4546532 },
    { lat: 48.4935809, lng: 18.4541968 },
    { lat: 48.4937671, lng: 18.453791 },
    { lat: 48.4944851, lng: 18.4527622 },
    { lat: 48.4950997, lng: 18.45223 },
    { lat: 48.4954036, lng: 18.4516323 },
    { lat: 48.4969197, lng: 18.4504738 },
    { lat: 48.4997163, lng: 18.4481603 },
    { lat: 48.5033571, lng: 18.4452835 },
    { lat: 48.5057712, lng: 18.4455252 },
    { lat: 48.5092213, lng: 18.4461994 },
    { lat: 48.5094177, lng: 18.4461825 },
    { lat: 48.5114281, lng: 18.4476471 },
    { lat: 48.5118948, lng: 18.4477997 },
    { lat: 48.5130479, lng: 18.4462011 },
    { lat: 48.5146122, lng: 18.4469033 },
    { lat: 48.5155659, lng: 18.4476453 },
    { lat: 48.5161483, lng: 18.4477506 },
    { lat: 48.5168884, lng: 18.4480766 },
    { lat: 48.5187532, lng: 18.4497812 },
    { lat: 48.5188947, lng: 18.4510896 },
    { lat: 48.5189752, lng: 18.4535785 },
    { lat: 48.5192172, lng: 18.4541769 },
    { lat: 48.5196709, lng: 18.4557841 },
    { lat: 48.51981, lng: 18.4565227 },
    { lat: 48.5194178, lng: 18.4571179 },
    { lat: 48.5190654, lng: 18.4573743 },
    { lat: 48.5185302, lng: 18.4580611 },
    { lat: 48.5183162, lng: 18.4585744 },
    { lat: 48.5178623, lng: 18.459321 },
    { lat: 48.5180327, lng: 18.4596644 },
    { lat: 48.5183318, lng: 18.4599513 },
    { lat: 48.518696, lng: 18.4594436 },
    { lat: 48.5192633, lng: 18.4592332 },
    { lat: 48.5193852, lng: 18.4588315 },
    { lat: 48.5195552, lng: 18.4588453 },
    { lat: 48.5196167, lng: 18.4582241 },
    { lat: 48.5199827, lng: 18.4575814 },
    { lat: 48.5198886, lng: 18.4605844 },
    { lat: 48.5198508, lng: 18.4668847 },
    { lat: 48.522337, lng: 18.4770508 },
    { lat: 48.5223, lng: 18.4778906 },
    { lat: 48.5222181, lng: 18.4779984 },
    { lat: 48.5220778, lng: 18.4784408 },
    { lat: 48.5220197, lng: 18.479062 },
    { lat: 48.5218088, lng: 18.4794506 },
    { lat: 48.5218436, lng: 18.4796947 },
    { lat: 48.5216976, lng: 18.4799923 },
    { lat: 48.521457, lng: 18.4803128 },
    { lat: 48.521491, lng: 18.4804377 },
    { lat: 48.5213969, lng: 18.4808174 },
    { lat: 48.5210899, lng: 18.4810584 },
    { lat: 48.5206631, lng: 18.4816242 },
    { lat: 48.520643, lng: 18.4817919 },
    { lat: 48.5207216, lng: 18.4821315 },
    { lat: 48.5205361, lng: 18.4821408 },
    { lat: 48.5202495, lng: 18.4825923 },
    { lat: 48.5199988, lng: 18.4827479 },
    { lat: 48.5197916, lng: 18.4827756 },
    { lat: 48.5195956, lng: 18.4830282 },
    { lat: 48.5194496, lng: 18.4830985 },
    { lat: 48.5192645, lng: 18.4830764 },
    { lat: 48.5189751, lng: 18.4831483 },
    { lat: 48.5188439, lng: 18.4831017 },
    { lat: 48.5188382, lng: 18.4826085 },
    { lat: 48.5187914, lng: 18.4823344 },
    { lat: 48.5187238, lng: 18.4822532 },
    { lat: 48.5186533, lng: 18.4824659 },
    { lat: 48.51842, lng: 18.4827185 },
    { lat: 48.5182345, lng: 18.4830762 },
    { lat: 48.518249, lng: 18.4835423 },
    { lat: 48.5180701, lng: 18.4835519 },
    { lat: 48.5179787, lng: 18.483653 },
    { lat: 48.5179649, lng: 18.4838312 },
    { lat: 48.517789, lng: 18.4838942 },
    { lat: 48.5176678, lng: 18.4842128 },
    { lat: 48.5176431, lng: 18.4844191 },
    { lat: 48.5174808, lng: 18.4845632 },
    { lat: 48.5174495, lng: 18.4847102 },
    { lat: 48.5173631, lng: 18.4848235 },
    { lat: 48.5171547, lng: 18.4849492 },
    { lat: 48.5170631, lng: 18.4851489 },
    { lat: 48.5167274, lng: 18.4853236 },
    { lat: 48.5165981, lng: 18.4855928 },
    { lat: 48.5164329, lng: 18.4855857 },
    { lat: 48.5159378, lng: 18.4859981 },
    { lat: 48.5157371, lng: 18.4862641 },
    { lat: 48.515248, lng: 18.4866402 },
    { lat: 48.5151732, lng: 18.4867752 },
    { lat: 48.5148903, lng: 18.4870062 },
    { lat: 48.5144185, lng: 18.487616 },
    { lat: 48.5141786, lng: 18.487804 },
    { lat: 48.5140448, lng: 18.4880573 },
    { lat: 48.5139639, lng: 18.4880863 },
    { lat: 48.5138671, lng: 18.4883325 },
    { lat: 48.5135671, lng: 18.4885206 },
    { lat: 48.5133993, lng: 18.4888497 },
    { lat: 48.5132936, lng: 18.4888753 },
    { lat: 48.5131724, lng: 18.4890737 },
    { lat: 48.5128789, lng: 18.489067 },
    { lat: 48.5127827, lng: 18.488856 },
    { lat: 48.5125011, lng: 18.4889488 },
    { lat: 48.5124052, lng: 18.4891309 },
    { lat: 48.5120487, lng: 18.4892338 },
    { lat: 48.511924, lng: 18.489202 },
    { lat: 48.511892, lng: 18.489215 },
    { lat: 48.511878, lng: 18.489194 },
    { lat: 48.511856, lng: 18.489214 },
    { lat: 48.51195, lng: 18.489481 },
    { lat: 48.512077, lng: 18.489778 },
    { lat: 48.512177, lng: 18.489997 },
    { lat: 48.51236, lng: 18.490217 },
    { lat: 48.512576, lng: 18.490624 },
    { lat: 48.512692, lng: 18.490674 },
    { lat: 48.512853, lng: 18.490907 },
    { lat: 48.513065, lng: 18.491182 },
    { lat: 48.513396, lng: 18.491688 },
    { lat: 48.513707, lng: 18.492097 },
    { lat: 48.513913, lng: 18.492336 },
    { lat: 48.514194, lng: 18.492637 },
    { lat: 48.514555, lng: 18.49277 },
    { lat: 48.514762, lng: 18.492964 },
    { lat: 48.514938, lng: 18.4934 },
    { lat: 48.51514, lng: 18.493635 },
    { lat: 48.515492, lng: 18.494039 },
    { lat: 48.51561, lng: 18.494399 },
    { lat: 48.515832, lng: 18.494679 },
    { lat: 48.515883, lng: 18.494731 },
    { lat: 48.516169, lng: 18.494993 },
    { lat: 48.516279, lng: 18.495059 },
    { lat: 48.516471, lng: 18.495108 },
    { lat: 48.51664, lng: 18.495187 },
    { lat: 48.516741, lng: 18.495332 },
    { lat: 48.516794, lng: 18.495506 },
    { lat: 48.516817, lng: 18.49576 },
    { lat: 48.516875, lng: 18.495953 },
    { lat: 48.517032, lng: 18.496079 },
    { lat: 48.517042, lng: 18.496251 },
    { lat: 48.517112, lng: 18.496374 },
    { lat: 48.517189, lng: 18.496425 },
    { lat: 48.517247, lng: 18.496649 },
    { lat: 48.517435, lng: 18.496851 },
    { lat: 48.517531, lng: 18.497159 },
    { lat: 48.517729, lng: 18.497471 },
    { lat: 48.517903, lng: 18.497891 },
    { lat: 48.518167, lng: 18.498123 },
    { lat: 48.5183, lng: 18.498314 },
    { lat: 48.51844, lng: 18.49831 },
    { lat: 48.518543, lng: 18.498374 },
    { lat: 48.518618, lng: 18.498527 },
    { lat: 48.518729, lng: 18.498601 },
    { lat: 48.518815, lng: 18.498853 },
    { lat: 48.518976, lng: 18.499059 },
    { lat: 48.519044, lng: 18.498915 },
    { lat: 48.519185, lng: 18.498955 },
    { lat: 48.519367, lng: 18.499064 },
    { lat: 48.519423, lng: 18.499056 },
    { lat: 48.51951, lng: 18.499114 },
    { lat: 48.519681, lng: 18.499129 },
    { lat: 48.519842, lng: 18.499199 },
    { lat: 48.519916, lng: 18.499292 },
    { lat: 48.520055, lng: 18.499258 },
    { lat: 48.520193, lng: 18.4995 },
    { lat: 48.520313, lng: 18.499535 },
    { lat: 48.520384, lng: 18.499583 },
    { lat: 48.520487, lng: 18.499765 },
    { lat: 48.520604, lng: 18.499747 },
    { lat: 48.52065, lng: 18.499742 },
    { lat: 48.520801, lng: 18.499884 },
    { lat: 48.520977, lng: 18.500034 },
    { lat: 48.521018, lng: 18.500186 },
    { lat: 48.521111, lng: 18.500376 },
    { lat: 48.521143, lng: 18.500415 },
    { lat: 48.521266, lng: 18.50056 },
    { lat: 48.521326, lng: 18.500663 },
    { lat: 48.52136, lng: 18.500757 },
    { lat: 48.521428, lng: 18.50096 },
    { lat: 48.521505, lng: 18.501169 },
    { lat: 48.521564, lng: 18.501384 },
    { lat: 48.521624, lng: 18.501526 },
    { lat: 48.521655, lng: 18.501661 },
    { lat: 48.521749, lng: 18.501719 },
    { lat: 48.521869, lng: 18.501881 },
    { lat: 48.521926, lng: 18.501973 },
    { lat: 48.522091, lng: 18.502267 },
    { lat: 48.522274, lng: 18.502165 },
    { lat: 48.52241, lng: 18.502349 },
    { lat: 48.522599, lng: 18.502402 },
    { lat: 48.522745, lng: 18.502569 },
    { lat: 48.52277, lng: 18.502677 },
    { lat: 48.522685, lng: 18.503001 },
    { lat: 48.52269, lng: 18.503114 },
    { lat: 48.522799, lng: 18.503211 },
    { lat: 48.522925, lng: 18.503415 },
    { lat: 48.523088, lng: 18.503555 },
    { lat: 48.523283, lng: 18.503646 },
    { lat: 48.523421, lng: 18.503913 },
    { lat: 48.523695, lng: 18.504098 },
    { lat: 48.523903, lng: 18.504378 },
    { lat: 48.523996, lng: 18.504529 },
    { lat: 48.524066, lng: 18.504698 },
    { lat: 48.524161, lng: 18.504991 },
    { lat: 48.524184, lng: 18.505281 },
    { lat: 48.524259, lng: 18.505591 },
    { lat: 48.524306, lng: 18.505702 },
    { lat: 48.524379, lng: 18.50598 },
    { lat: 48.524453, lng: 18.506156 },
    { lat: 48.524468, lng: 18.506204 },
    { lat: 48.524518, lng: 18.506369 },
    { lat: 48.524523, lng: 18.506546 },
    { lat: 48.524604, lng: 18.506725 },
    { lat: 48.524663, lng: 18.507038 },
    { lat: 48.524777, lng: 18.507174 },
    { lat: 48.524725, lng: 18.507343 },
    { lat: 48.524742, lng: 18.507535 },
    { lat: 48.524962, lng: 18.507846 },
    { lat: 48.524978, lng: 18.507871 },
    { lat: 48.525033, lng: 18.507999 },
    { lat: 48.525173, lng: 18.508034 },
    { lat: 48.525241, lng: 18.508128 },
    { lat: 48.525296, lng: 18.508213 },
    { lat: 48.525354, lng: 18.508274 },
    { lat: 48.525493, lng: 18.50831 },
    { lat: 48.525591, lng: 18.508497 },
    { lat: 48.525742, lng: 18.508658 },
    { lat: 48.525881, lng: 18.508908 },
    { lat: 48.525894, lng: 18.509165 },
    { lat: 48.526022, lng: 18.509415 },
    { lat: 48.526027, lng: 18.509531 },
    { lat: 48.525947, lng: 18.509737 },
    { lat: 48.526002, lng: 18.50988 },
    { lat: 48.526221, lng: 18.510127 },
    { lat: 48.526346, lng: 18.510212 },
    { lat: 48.52634, lng: 18.510346 },
    { lat: 48.526346, lng: 18.510452 },
    { lat: 48.526543, lng: 18.510872 },
    { lat: 48.52666, lng: 18.511067 },
    { lat: 48.526831, lng: 18.511158 },
    { lat: 48.526955, lng: 18.511386 },
    { lat: 48.527096, lng: 18.511525 },
    { lat: 48.527203, lng: 18.511501 },
    { lat: 48.527373, lng: 18.511687 },
    { lat: 48.527375, lng: 18.512384 },
    { lat: 48.527421, lng: 18.512439 },
    { lat: 48.527523, lng: 18.512953 },
    { lat: 48.527412, lng: 18.513093 },
    { lat: 48.527411, lng: 18.513213 },
    { lat: 48.52757, lng: 18.513441 },
    { lat: 48.527549, lng: 18.513772 },
    { lat: 48.527711, lng: 18.514166 },
    { lat: 48.527679, lng: 18.514419 },
    { lat: 48.527713, lng: 18.514558 },
    { lat: 48.527742, lng: 18.514774 },
    { lat: 48.527704, lng: 18.515084 },
    { lat: 48.527785, lng: 18.515211 },
    { lat: 48.527795, lng: 18.515381 },
    { lat: 48.527784, lng: 18.515649 },
    { lat: 48.527821, lng: 18.515763 },
    { lat: 48.527807, lng: 18.515923 },
    { lat: 48.527862, lng: 18.516141 },
    { lat: 48.527859, lng: 18.516357 },
    { lat: 48.527969, lng: 18.516748 },
    { lat: 48.528015, lng: 18.516956 },
    { lat: 48.528103, lng: 18.517184 },
    { lat: 48.528123, lng: 18.517444 },
    { lat: 48.528481, lng: 18.517888 },
    { lat: 48.528627, lng: 18.517909 },
    { lat: 48.52876, lng: 18.517903 },
    { lat: 48.52892, lng: 18.518023 },
    { lat: 48.528946, lng: 18.518049 },
    { lat: 48.528648, lng: 18.514031 },
    { lat: 48.528463, lng: 18.512881 },
    { lat: 48.527851, lng: 18.511064 },
    { lat: 48.526662, lng: 18.509459 },
    { lat: 48.526356, lng: 18.50814 },
    { lat: 48.526138, lng: 18.507897 },
    { lat: 48.525966, lng: 18.507622 },
    { lat: 48.525652, lng: 18.506744 },
    { lat: 48.525578, lng: 18.506664 },
    { lat: 48.525672, lng: 18.505958 },
    { lat: 48.525681, lng: 18.505888 },
    { lat: 48.525704, lng: 18.505719 },
    { lat: 48.525731, lng: 18.505418 },
    { lat: 48.526003, lng: 18.502519 },
    { lat: 48.526684, lng: 18.501769 },
    { lat: 48.527662, lng: 18.501222 },
    { lat: 48.530541, lng: 18.499004 },
    { lat: 48.531181, lng: 18.497702 },
    { lat: 48.532818, lng: 18.495137 },
    { lat: 48.534645, lng: 18.493225 },
    { lat: 48.535241, lng: 18.492726 },
    { lat: 48.536454, lng: 18.492229 },
    { lat: 48.537539, lng: 18.49176 },
    { lat: 48.537811, lng: 18.491756 },
    { lat: 48.538044, lng: 18.491756 },
    { lat: 48.538505, lng: 18.491934 },
    { lat: 48.538581, lng: 18.491963 },
    { lat: 48.539402, lng: 18.492278 },
    { lat: 48.54137, lng: 18.490667 },
    { lat: 48.542173, lng: 18.490058 },
    { lat: 48.543823, lng: 18.488809 },
    { lat: 48.544087, lng: 18.488177 },
    { lat: 48.54577, lng: 18.485737 },
    { lat: 48.545921, lng: 18.485318 },
    { lat: 48.546233, lng: 18.484316 },
    { lat: 48.546396, lng: 18.483793 },
    { lat: 48.546547, lng: 18.483398 },
    { lat: 48.546738, lng: 18.482895 },
    { lat: 48.546925, lng: 18.482448 },
    { lat: 48.547162, lng: 18.481864 },
    { lat: 48.547632, lng: 18.480819 },
    { lat: 48.548081, lng: 18.479733 },
    { lat: 48.548348, lng: 18.479153 },
    { lat: 48.548578, lng: 18.478683 },
    { lat: 48.548602, lng: 18.478673 },
    { lat: 48.547598, lng: 18.476534 },
    { lat: 48.547278, lng: 18.474731 },
    { lat: 48.547223, lng: 18.473955 },
    { lat: 48.546985, lng: 18.472465 },
    { lat: 48.546439, lng: 18.470218 },
    { lat: 48.546645, lng: 18.468876 },
    { lat: 48.546598, lng: 18.46809 },
  ],
  Čaradice: [
    { lat: 48.334257, lng: 18.490495 },
    { lat: 48.334372, lng: 18.491023 },
    { lat: 48.334468, lng: 18.491433 },
    { lat: 48.334552, lng: 18.491731 },
    { lat: 48.33457, lng: 18.491776 },
    { lat: 48.334611, lng: 18.491875 },
    { lat: 48.334624, lng: 18.491905 },
    { lat: 48.334639, lng: 18.491922 },
    { lat: 48.334702, lng: 18.492057 },
    { lat: 48.334877, lng: 18.492367 },
    { lat: 48.335028, lng: 18.492713 },
    { lat: 48.335058, lng: 18.492833 },
    { lat: 48.334911, lng: 18.493002 },
    { lat: 48.334737, lng: 18.49326 },
    { lat: 48.334576, lng: 18.493634 },
    { lat: 48.334475, lng: 18.493712 },
    { lat: 48.334396, lng: 18.493889 },
    { lat: 48.334254, lng: 18.494259 },
    { lat: 48.334191, lng: 18.49442 },
    { lat: 48.334098, lng: 18.494642 },
    { lat: 48.334017, lng: 18.494782 },
    { lat: 48.333991, lng: 18.494789 },
    { lat: 48.333931, lng: 18.494863 },
    { lat: 48.333843, lng: 18.494994 },
    { lat: 48.333792, lng: 18.495081 },
    { lat: 48.333706, lng: 18.495301 },
    { lat: 48.333675, lng: 18.49533 },
    { lat: 48.333603, lng: 18.49544 },
    { lat: 48.333392, lng: 18.496063 },
    { lat: 48.333357, lng: 18.496273 },
    { lat: 48.333294, lng: 18.496454 },
    { lat: 48.333153, lng: 18.497213 },
    { lat: 48.333058, lng: 18.497804 },
    { lat: 48.332952, lng: 18.498574 },
    { lat: 48.332859, lng: 18.498913 },
    { lat: 48.332804, lng: 18.499209 },
    { lat: 48.332791, lng: 18.499344 },
    { lat: 48.332836, lng: 18.499774 },
    { lat: 48.332852, lng: 18.500239 },
    { lat: 48.332857, lng: 18.50076 },
    { lat: 48.332847, lng: 18.501272 },
    { lat: 48.332809, lng: 18.501748 },
    { lat: 48.332755, lng: 18.502513 },
    { lat: 48.332728, lng: 18.502695 },
    { lat: 48.33251, lng: 18.503499 },
    { lat: 48.332294, lng: 18.504134 },
    { lat: 48.331963, lng: 18.505045 },
    { lat: 48.331922, lng: 18.505129 },
    { lat: 48.331838, lng: 18.505397 },
    { lat: 48.331809, lng: 18.505554 },
    { lat: 48.331795, lng: 18.505754 },
    { lat: 48.331777, lng: 18.505932 },
    { lat: 48.331623, lng: 18.50708 },
    { lat: 48.331559, lng: 18.507199 },
    { lat: 48.331408, lng: 18.507456 },
    { lat: 48.3314, lng: 18.50784 },
    { lat: 48.331415, lng: 18.507888 },
    { lat: 48.331421, lng: 18.507908 },
    { lat: 48.331431, lng: 18.50794 },
    { lat: 48.331468, lng: 18.508024 },
    { lat: 48.331509, lng: 18.508203 },
    { lat: 48.331566, lng: 18.508462 },
    { lat: 48.33166, lng: 18.50866 },
    { lat: 48.331722, lng: 18.508841 },
    { lat: 48.331568, lng: 18.508958 },
    { lat: 48.331368, lng: 18.509115 },
    { lat: 48.331574, lng: 18.509746 },
    { lat: 48.331679, lng: 18.510084 },
    { lat: 48.331728, lng: 18.510259 },
    { lat: 48.331934, lng: 18.510954 },
    { lat: 48.332046, lng: 18.511726 },
    { lat: 48.33207, lng: 18.51189 },
    { lat: 48.332078, lng: 18.512021 },
    { lat: 48.332129, lng: 18.512126 },
    { lat: 48.332237, lng: 18.512382 },
    { lat: 48.332388, lng: 18.512797 },
    { lat: 48.332502, lng: 18.513153 },
    { lat: 48.332663, lng: 18.513623 },
    { lat: 48.332778, lng: 18.514003 },
    { lat: 48.332944, lng: 18.514576 },
    { lat: 48.333194, lng: 18.51538 },
    { lat: 48.333234, lng: 18.515494 },
    { lat: 48.333344, lng: 18.515739 },
    { lat: 48.333649, lng: 18.516341 },
    { lat: 48.333776, lng: 18.516572 },
    { lat: 48.333758, lng: 18.516691 },
    { lat: 48.333898, lng: 18.516961 },
    { lat: 48.334044, lng: 18.517284 },
    { lat: 48.334189, lng: 18.517633 },
    { lat: 48.334299, lng: 18.517906 },
    { lat: 48.33443, lng: 18.518251 },
    { lat: 48.334439, lng: 18.518274 },
    { lat: 48.334894, lng: 18.519727 },
    { lat: 48.335105, lng: 18.519655 },
    { lat: 48.335121, lng: 18.519649 },
    { lat: 48.335533, lng: 18.520997 },
    { lat: 48.335659, lng: 18.521391 },
    { lat: 48.335789, lng: 18.521799 },
    { lat: 48.3361012, lng: 18.5221513 },
    { lat: 48.3366704, lng: 18.5223647 },
    { lat: 48.3381745, lng: 18.5217003 },
    { lat: 48.3381317, lng: 18.5215149 },
    { lat: 48.3397432, lng: 18.5205045 },
    { lat: 48.339904, lng: 18.520698 },
    { lat: 48.3399643, lng: 18.5209359 },
    { lat: 48.3399403, lng: 18.5210917 },
    { lat: 48.3403624, lng: 18.5205848 },
    { lat: 48.3411343, lng: 18.5204877 },
    { lat: 48.3420993, lng: 18.5193904 },
    { lat: 48.3445067, lng: 18.5173912 },
    { lat: 48.3458259, lng: 18.5166009 },
    { lat: 48.3478411, lng: 18.5163607 },
    { lat: 48.3520123, lng: 18.514844 },
    { lat: 48.3532836, lng: 18.5149645 },
    { lat: 48.3542962, lng: 18.5153052 },
    { lat: 48.3553623, lng: 18.5160046 },
    { lat: 48.3560279, lng: 18.516699 },
    { lat: 48.3583954, lng: 18.5173901 },
    { lat: 48.3594873, lng: 18.5180438 },
    { lat: 48.3607564, lng: 18.5185149 },
    { lat: 48.3668347, lng: 18.5178062 },
    { lat: 48.3676502, lng: 18.5175567 },
    { lat: 48.3690452, lng: 18.5172636 },
    { lat: 48.3704594, lng: 18.5176057 },
    { lat: 48.3710362, lng: 18.5178804 },
    { lat: 48.3723709, lng: 18.5174748 },
    { lat: 48.3732558, lng: 18.5171156 },
    { lat: 48.3774445, lng: 18.5163393 },
    { lat: 48.3785154, lng: 18.5163883 },
    { lat: 48.3788009, lng: 18.5163483 },
    { lat: 48.3794683, lng: 18.5167927 },
    { lat: 48.3802984, lng: 18.5168117 },
    { lat: 48.3807656, lng: 18.5167547 },
    { lat: 48.3807917, lng: 18.5168593 },
    { lat: 48.3812868, lng: 18.5167929 },
    { lat: 48.3824151, lng: 18.5165105 },
    { lat: 48.3827645, lng: 18.5161984 },
    { lat: 48.3838338, lng: 18.5149904 },
    { lat: 48.3840358, lng: 18.5149724 },
    { lat: 48.3853479, lng: 18.5151976 },
    { lat: 48.3865212, lng: 18.5156942 },
    { lat: 48.3875469, lng: 18.5156169 },
    { lat: 48.3878178, lng: 18.515969 },
    { lat: 48.3884958, lng: 18.5161109 },
    { lat: 48.3888474, lng: 18.5163916 },
    { lat: 48.3896824, lng: 18.5167251 },
    { lat: 48.3900541, lng: 18.5170441 },
    { lat: 48.3902684, lng: 18.5174494 },
    { lat: 48.3903603, lng: 18.5180144 },
    { lat: 48.3913301, lng: 18.5182755 },
    { lat: 48.3919314, lng: 18.5181497 },
    { lat: 48.3936208, lng: 18.5189217 },
    { lat: 48.3940787, lng: 18.5202324 },
    { lat: 48.3945429, lng: 18.5206128 },
    { lat: 48.3945751, lng: 18.5209105 },
    { lat: 48.3948927, lng: 18.5213723 },
    { lat: 48.3952939, lng: 18.5217949 },
    { lat: 48.3954233, lng: 18.5224437 },
    { lat: 48.3956543, lng: 18.5228274 },
    { lat: 48.3956892, lng: 18.5227656 },
    { lat: 48.3958338, lng: 18.5230998 },
    { lat: 48.3959081, lng: 18.5235871 },
    { lat: 48.3958171, lng: 18.5237148 },
    { lat: 48.3962452, lng: 18.5241792 },
    { lat: 48.3962794, lng: 18.524514 },
    { lat: 48.3970073, lng: 18.5253604 },
    { lat: 48.3973203, lng: 18.5254309 },
    { lat: 48.3977954, lng: 18.5261305 },
    { lat: 48.3979287, lng: 18.5261122 },
    { lat: 48.3982158, lng: 18.5263698 },
    { lat: 48.3986381, lng: 18.5264406 },
    { lat: 48.3989212, lng: 18.5269717 },
    { lat: 48.3993792, lng: 18.527486 },
    { lat: 48.3999947, lng: 18.5279113 },
    { lat: 48.4001901, lng: 18.5283648 },
    { lat: 48.4001996, lng: 18.5284779 },
    { lat: 48.4005405, lng: 18.5288901 },
    { lat: 48.4009296, lng: 18.5290324 },
    { lat: 48.401014, lng: 18.529132 },
    { lat: 48.4014298, lng: 18.5290634 },
    { lat: 48.4015991, lng: 18.5289008 },
    { lat: 48.4018226, lng: 18.5285334 },
    { lat: 48.4025667, lng: 18.528035 },
    { lat: 48.4025607, lng: 18.5280883 },
    { lat: 48.4029063, lng: 18.5281792 },
    { lat: 48.4033155, lng: 18.5286432 },
    { lat: 48.4058106, lng: 18.5291358 },
    { lat: 48.4071624, lng: 18.5294933 },
    { lat: 48.4077323, lng: 18.5300723 },
    { lat: 48.4092405, lng: 18.5306859 },
    { lat: 48.4110928, lng: 18.5304634 },
    { lat: 48.4117918, lng: 18.5314398 },
    { lat: 48.4119482, lng: 18.5313749 },
    { lat: 48.412626, lng: 18.530508 },
    { lat: 48.4130316, lng: 18.5297799 },
    { lat: 48.4131413, lng: 18.5293977 },
    { lat: 48.4131521, lng: 18.5290601 },
    { lat: 48.4128452, lng: 18.5271073 },
    { lat: 48.4130381, lng: 18.5265139 },
    { lat: 48.413076, lng: 18.5261548 },
    { lat: 48.4128031, lng: 18.5251732 },
    { lat: 48.4127723, lng: 18.5248194 },
    { lat: 48.4123711, lng: 18.523945 },
    { lat: 48.4110547, lng: 18.5219344 },
    { lat: 48.4107564, lng: 18.5212485 },
    { lat: 48.4108557, lng: 18.5205733 },
    { lat: 48.4102393, lng: 18.5187208 },
    { lat: 48.40996, lng: 18.517542 },
    { lat: 48.4096511, lng: 18.5169285 },
    { lat: 48.4089336, lng: 18.5152502 },
    { lat: 48.408195, lng: 18.514191 },
    { lat: 48.4078664, lng: 18.5128525 },
    { lat: 48.4072773, lng: 18.5121099 },
    { lat: 48.4070354, lng: 18.5114487 },
    { lat: 48.406833, lng: 18.5104354 },
    { lat: 48.4070413, lng: 18.5098801 },
    { lat: 48.4070609, lng: 18.5094751 },
    { lat: 48.4070614, lng: 18.5091385 },
    { lat: 48.4068931, lng: 18.5084387 },
    { lat: 48.4068677, lng: 18.5078337 },
    { lat: 48.4065728, lng: 18.5072739 },
    { lat: 48.4061922, lng: 18.5063865 },
    { lat: 48.4057987, lng: 18.5059814 },
    { lat: 48.4054354, lng: 18.5053648 },
    { lat: 48.40458, lng: 18.5041971 },
    { lat: 48.4027241, lng: 18.5022083 },
    { lat: 48.4024483, lng: 18.5007893 },
    { lat: 48.4023281, lng: 18.500636 },
    { lat: 48.4021071, lng: 18.5004526 },
    { lat: 48.4015865, lng: 18.5000968 },
    { lat: 48.4010357, lng: 18.4998431 },
    { lat: 48.4006444, lng: 18.4998247 },
    { lat: 48.4001856, lng: 18.5000572 },
    { lat: 48.3996215, lng: 18.5001197 },
    { lat: 48.3994713, lng: 18.5000326 },
    { lat: 48.3992685, lng: 18.499733 },
    { lat: 48.3978805, lng: 18.4983135 },
    { lat: 48.3978059, lng: 18.4982288 },
    { lat: 48.3970152, lng: 18.4974764 },
    { lat: 48.3961205, lng: 18.4964115 },
    { lat: 48.3942454, lng: 18.494469 },
    { lat: 48.392422, lng: 18.4927047 },
    { lat: 48.3919667, lng: 18.4919618 },
    { lat: 48.39165, lng: 18.4916173 },
    { lat: 48.3913846, lng: 18.4911223 },
    { lat: 48.3911289, lng: 18.4910668 },
    { lat: 48.3909773, lng: 18.4912252 },
    { lat: 48.3907893, lng: 18.4909449 },
    { lat: 48.3907985, lng: 18.4907652 },
    { lat: 48.3906202, lng: 18.4907221 },
    { lat: 48.3905162, lng: 18.4905984 },
    { lat: 48.3904147, lng: 18.4905972 },
    { lat: 48.3901177, lng: 18.4903255 },
    { lat: 48.3897459, lng: 18.4902959 },
    { lat: 48.3894346, lng: 18.4901129 },
    { lat: 48.3894471, lng: 18.4900525 },
    { lat: 48.3893345, lng: 18.489894 },
    { lat: 48.3891959, lng: 18.4898357 },
    { lat: 48.3890617, lng: 18.4896533 },
    { lat: 48.3889881, lng: 18.489687 },
    { lat: 48.3888766, lng: 18.4895291 },
    { lat: 48.388667, lng: 18.4894495 },
    { lat: 48.3883088, lng: 18.4890733 },
    { lat: 48.3880804, lng: 18.4891018 },
    { lat: 48.3877305, lng: 18.488837 },
    { lat: 48.3872155, lng: 18.4887231 },
    { lat: 48.387152, lng: 18.4886132 },
    { lat: 48.3868011, lng: 18.488351 },
    { lat: 48.3867064, lng: 18.4881854 },
    { lat: 48.3863683, lng: 18.4881122 },
    { lat: 48.3862883, lng: 18.4879902 },
    { lat: 48.3859079, lng: 18.48778 },
    { lat: 48.3857507, lng: 18.4874685 },
    { lat: 48.3855685, lng: 18.4874618 },
    { lat: 48.3853099, lng: 18.4872701 },
    { lat: 48.3850137, lng: 18.487219 },
    { lat: 48.384796, lng: 18.487078 },
    { lat: 48.3847616, lng: 18.486985 },
    { lat: 48.3845574, lng: 18.4869055 },
    { lat: 48.3844027, lng: 18.4862501 },
    { lat: 48.3844041, lng: 18.4859093 },
    { lat: 48.3842115, lng: 18.4856058 },
    { lat: 48.3841271, lng: 18.4850218 },
    { lat: 48.3840193, lng: 18.4849487 },
    { lat: 48.383858, lng: 18.4843755 },
    { lat: 48.3835159, lng: 18.4839863 },
    { lat: 48.3829965, lng: 18.4838364 },
    { lat: 48.3829538, lng: 18.4838588 },
    { lat: 48.3827796, lng: 18.4836673 },
    { lat: 48.3827051, lng: 18.4837057 },
    { lat: 48.3824395, lng: 18.4835745 },
    { lat: 48.3822059, lng: 18.4836761 },
    { lat: 48.382028, lng: 18.4833757 },
    { lat: 48.3818906, lng: 18.4833996 },
    { lat: 48.3818174, lng: 18.4832593 },
    { lat: 48.3817461, lng: 18.4833765 },
    { lat: 48.3816063, lng: 18.4833804 },
    { lat: 48.3815205, lng: 18.4836019 },
    { lat: 48.3813316, lng: 18.4837331 },
    { lat: 48.381133, lng: 18.4837972 },
    { lat: 48.3809253, lng: 18.4837305 },
    { lat: 48.3801814, lng: 18.484455 },
    { lat: 48.3801163, lng: 18.4847062 },
    { lat: 48.3799325, lng: 18.4849733 },
    { lat: 48.3798903, lng: 18.4851674 },
    { lat: 48.3796024, lng: 18.4854485 },
    { lat: 48.3795416, lng: 18.4858358 },
    { lat: 48.3794118, lng: 18.4860404 },
    { lat: 48.3794427, lng: 18.4861717 },
    { lat: 48.3793076, lng: 18.4863366 },
    { lat: 48.3794152, lng: 18.4866106 },
    { lat: 48.3792453, lng: 18.4869866 },
    { lat: 48.3792758, lng: 18.4871276 },
    { lat: 48.3791525, lng: 18.4872878 },
    { lat: 48.3790777, lng: 18.4877077 },
    { lat: 48.3791174, lng: 18.4879351 },
    { lat: 48.379026, lng: 18.4881371 },
    { lat: 48.3791984, lng: 18.4883806 },
    { lat: 48.3790994, lng: 18.489036 },
    { lat: 48.3788812, lng: 18.4893137 },
    { lat: 48.3788032, lng: 18.4895949 },
    { lat: 48.378657, lng: 18.4895844 },
    { lat: 48.3784592, lng: 18.489966 },
    { lat: 48.3780959, lng: 18.490166 },
    { lat: 48.3780337, lng: 18.4904101 },
    { lat: 48.3776347, lng: 18.4907395 },
    { lat: 48.3775293, lng: 18.4907533 },
    { lat: 48.3775257, lng: 18.4909514 },
    { lat: 48.3774591, lng: 18.490992 },
    { lat: 48.3774336, lng: 18.4911038 },
    { lat: 48.3770887, lng: 18.4914157 },
    { lat: 48.3768206, lng: 18.4919917 },
    { lat: 48.3766769, lng: 18.4920036 },
    { lat: 48.3765903, lng: 18.4920885 },
    { lat: 48.3762031, lng: 18.4929217 },
    { lat: 48.3759546, lng: 18.4932418 },
    { lat: 48.3757616, lng: 18.4934007 },
    { lat: 48.3756828, lng: 18.493814 },
    { lat: 48.3745678, lng: 18.4937045 },
    { lat: 48.3733224, lng: 18.4931941 },
    { lat: 48.3727818, lng: 18.4927194 },
    { lat: 48.3695424, lng: 18.4920538 },
    { lat: 48.3646167, lng: 18.4923523 },
    { lat: 48.362917, lng: 18.4922948 },
    { lat: 48.3603452, lng: 18.4919939 },
    { lat: 48.3592397, lng: 18.4919954 },
    { lat: 48.3574596, lng: 18.4911184 },
    { lat: 48.356337, lng: 18.4904447 },
    { lat: 48.3548402, lng: 18.4903631 },
    { lat: 48.3525004, lng: 18.4899285 },
    { lat: 48.3513931, lng: 18.4894539 },
    { lat: 48.3506962, lng: 18.4884201 },
    { lat: 48.3498643, lng: 18.4869477 },
    { lat: 48.3487145, lng: 18.4854754 },
    { lat: 48.3468841, lng: 18.4840157 },
    { lat: 48.346155, lng: 18.4836279 },
    { lat: 48.3439931, lng: 18.4826788 },
    { lat: 48.3428458, lng: 18.4825368 },
    { lat: 48.3417472, lng: 18.4820112 },
    { lat: 48.3410418, lng: 18.4814347 },
    { lat: 48.3392759, lng: 18.4819376 },
    { lat: 48.3384253, lng: 18.4842768 },
    { lat: 48.3374238, lng: 18.485826 },
    { lat: 48.3368253, lng: 18.487383 },
    { lat: 48.3363391, lng: 18.4876879 },
    { lat: 48.3354359, lng: 18.4887549 },
    { lat: 48.3349314, lng: 18.4895392 },
    { lat: 48.334975, lng: 18.4896012 },
    { lat: 48.334257, lng: 18.490495 },
  ],
  Obyce: [
    { lat: 48.4353414, lng: 18.4421814 },
    { lat: 48.4351384, lng: 18.4424613 },
    { lat: 48.4346994, lng: 18.4429876 },
    { lat: 48.4338583, lng: 18.4414568 },
    { lat: 48.4332124, lng: 18.4422581 },
    { lat: 48.4330908, lng: 18.4402741 },
    { lat: 48.4326472, lng: 18.4401498 },
    { lat: 48.4326825, lng: 18.4396718 },
    { lat: 48.4312985, lng: 18.4390872 },
    { lat: 48.4309422, lng: 18.4386252 },
    { lat: 48.4305162, lng: 18.4383271 },
    { lat: 48.429795, lng: 18.4374556 },
    { lat: 48.4295974, lng: 18.4381174 },
    { lat: 48.4284763, lng: 18.4366451 },
    { lat: 48.4283607, lng: 18.4366785 },
    { lat: 48.4281686, lng: 18.436448 },
    { lat: 48.4279967, lng: 18.4365616 },
    { lat: 48.4268729, lng: 18.4358108 },
    { lat: 48.4263985, lng: 18.4349094 },
    { lat: 48.4255691, lng: 18.4359586 },
    { lat: 48.4253366, lng: 18.4360586 },
    { lat: 48.4240662, lng: 18.4373096 },
    { lat: 48.4236248, lng: 18.4375456 },
    { lat: 48.4233589, lng: 18.4377762 },
    { lat: 48.4227329, lng: 18.438424 },
    { lat: 48.4224322, lng: 18.437343 },
    { lat: 48.422152, lng: 18.4367695 },
    { lat: 48.4219522, lng: 18.436464 },
    { lat: 48.4210829, lng: 18.4355811 },
    { lat: 48.4199537, lng: 18.4350361 },
    { lat: 48.4199427, lng: 18.4351014 },
    { lat: 48.4193256, lng: 18.4352814 },
    { lat: 48.4193033, lng: 18.4354493 },
    { lat: 48.4179037, lng: 18.4358414 },
    { lat: 48.4164862, lng: 18.4366427 },
    { lat: 48.4163701, lng: 18.436641 },
    { lat: 48.4158166, lng: 18.4392807 },
    { lat: 48.415377, lng: 18.4382745 },
    { lat: 48.4153372, lng: 18.4383317 },
    { lat: 48.4152194, lng: 18.4379954 },
    { lat: 48.4149671, lng: 18.4384099 },
    { lat: 48.4147533, lng: 18.4389847 },
    { lat: 48.41452, lng: 18.4395571 },
    { lat: 48.4143218, lng: 18.4397848 },
    { lat: 48.4142513, lng: 18.4401406 },
    { lat: 48.4140698, lng: 18.4401194 },
    { lat: 48.4140578, lng: 18.4402512 },
    { lat: 48.4135766, lng: 18.4410901 },
    { lat: 48.4133235, lng: 18.4411635 },
    { lat: 48.4132415, lng: 18.4412704 },
    { lat: 48.4130095, lng: 18.4413574 },
    { lat: 48.4126863, lng: 18.4417329 },
    { lat: 48.4126706, lng: 18.4419178 },
    { lat: 48.4123435, lng: 18.4424464 },
    { lat: 48.4123753, lng: 18.4427102 },
    { lat: 48.4122439, lng: 18.4436601 },
    { lat: 48.4122243, lng: 18.4444947 },
    { lat: 48.4123312, lng: 18.4447959 },
    { lat: 48.412224, lng: 18.4454822 },
    { lat: 48.4122966, lng: 18.4456947 },
    { lat: 48.4122597, lng: 18.445982 },
    { lat: 48.4123952, lng: 18.4463687 },
    { lat: 48.4121825, lng: 18.4474905 },
    { lat: 48.4122164, lng: 18.4478367 },
    { lat: 48.4121723, lng: 18.4480812 },
    { lat: 48.4123147, lng: 18.448732 },
    { lat: 48.412305, lng: 18.4489015 },
    { lat: 48.4122486, lng: 18.4490226 },
    { lat: 48.4123526, lng: 18.4492716 },
    { lat: 48.4123071, lng: 18.4495301 },
    { lat: 48.4122118, lng: 18.4496809 },
    { lat: 48.4121714, lng: 18.4500251 },
    { lat: 48.4123091, lng: 18.4502745 },
    { lat: 48.4123793, lng: 18.4507621 },
    { lat: 48.4123611, lng: 18.4509245 },
    { lat: 48.4124814, lng: 18.451098 },
    { lat: 48.4124861, lng: 18.4512636 },
    { lat: 48.4126094, lng: 18.4516944 },
    { lat: 48.4125637, lng: 18.4521298 },
    { lat: 48.4126177, lng: 18.4527146 },
    { lat: 48.4131318, lng: 18.453665 },
    { lat: 48.4131303, lng: 18.4539829 },
    { lat: 48.4133299, lng: 18.4540225 },
    { lat: 48.4136, lng: 18.4544545 },
    { lat: 48.4137105, lng: 18.4548373 },
    { lat: 48.4139246, lng: 18.4551808 },
    { lat: 48.4141665, lng: 18.4552889 },
    { lat: 48.4143368, lng: 18.4556138 },
    { lat: 48.414468, lng: 18.4557024 },
    { lat: 48.4146064, lng: 18.4560782 },
    { lat: 48.4147255, lng: 18.4562344 },
    { lat: 48.4147936, lng: 18.4571527 },
    { lat: 48.4147499, lng: 18.4574223 },
    { lat: 48.4148069, lng: 18.4576776 },
    { lat: 48.4147414, lng: 18.4578602 },
    { lat: 48.4148386, lng: 18.4584911 },
    { lat: 48.4148013, lng: 18.4587131 },
    { lat: 48.4146729, lng: 18.4590215 },
    { lat: 48.4147204, lng: 18.4592041 },
    { lat: 48.4146389, lng: 18.4596695 },
    { lat: 48.4147036, lng: 18.4600375 },
    { lat: 48.4146254, lng: 18.4601375 },
    { lat: 48.414903, lng: 18.4610099 },
    { lat: 48.4148905, lng: 18.4613133 },
    { lat: 48.4149503, lng: 18.4615978 },
    { lat: 48.4148935, lng: 18.4620342 },
    { lat: 48.4150709, lng: 18.4623692 },
    { lat: 48.415114, lng: 18.4625744 },
    { lat: 48.4150534, lng: 18.4633298 },
    { lat: 48.4150781, lng: 18.4634304 },
    { lat: 48.4153184, lng: 18.4637352 },
    { lat: 48.4154493, lng: 18.4641207 },
    { lat: 48.4154563, lng: 18.4643709 },
    { lat: 48.4155375, lng: 18.4644708 },
    { lat: 48.4156433, lng: 18.464851 },
    { lat: 48.4157146, lng: 18.4649554 },
    { lat: 48.4157847, lng: 18.4653465 },
    { lat: 48.4159527, lng: 18.4655871 },
    { lat: 48.4159622, lng: 18.4659251 },
    { lat: 48.416129, lng: 18.4662039 },
    { lat: 48.4162308, lng: 18.4670036 },
    { lat: 48.416151, lng: 18.4672508 },
    { lat: 48.4164047, lng: 18.4679804 },
    { lat: 48.4163086, lng: 18.4682222 },
    { lat: 48.4163446, lng: 18.4683637 },
    { lat: 48.4164557, lng: 18.4684307 },
    { lat: 48.4165604, lng: 18.4687553 },
    { lat: 48.4165085, lng: 18.4689561 },
    { lat: 48.4166255, lng: 18.4695025 },
    { lat: 48.416833, lng: 18.4696196 },
    { lat: 48.4168514, lng: 18.4700545 },
    { lat: 48.4170725, lng: 18.4702764 },
    { lat: 48.4170162, lng: 18.4706471 },
    { lat: 48.4170696, lng: 18.4707949 },
    { lat: 48.4172065, lng: 18.4708431 },
    { lat: 48.417158, lng: 18.4711056 },
    { lat: 48.4173801, lng: 18.4713686 },
    { lat: 48.4172921, lng: 18.4716154 },
    { lat: 48.4174271, lng: 18.4725826 },
    { lat: 48.4172831, lng: 18.4728332 },
    { lat: 48.4171998, lng: 18.4736295 },
    { lat: 48.4169679, lng: 18.4739025 },
    { lat: 48.4167912, lng: 18.4743386 },
    { lat: 48.4167234, lng: 18.4747825 },
    { lat: 48.4165657, lng: 18.4749705 },
    { lat: 48.4164589, lng: 18.4753848 },
    { lat: 48.4165212, lng: 18.4756721 },
    { lat: 48.4164221, lng: 18.4758202 },
    { lat: 48.4164882, lng: 18.4762203 },
    { lat: 48.416379, lng: 18.4765482 },
    { lat: 48.4161984, lng: 18.4767107 },
    { lat: 48.4162232, lng: 18.4775414 },
    { lat: 48.4160478, lng: 18.4777388 },
    { lat: 48.4159567, lng: 18.4780864 },
    { lat: 48.4160652, lng: 18.478619 },
    { lat: 48.4159288, lng: 18.4788889 },
    { lat: 48.4157508, lng: 18.4790543 },
    { lat: 48.4157381, lng: 18.4791496 },
    { lat: 48.415828, lng: 18.4792875 },
    { lat: 48.4159051, lng: 18.4795793 },
    { lat: 48.4158066, lng: 18.4801222 },
    { lat: 48.41597, lng: 18.4806302 },
    { lat: 48.4159851, lng: 18.4808825 },
    { lat: 48.4157713, lng: 18.4812395 },
    { lat: 48.4159481, lng: 18.4813964 },
    { lat: 48.4159361, lng: 18.4814604 },
    { lat: 48.4158222, lng: 18.48191 },
    { lat: 48.4156647, lng: 18.482246 },
    { lat: 48.4151322, lng: 18.4826311 },
    { lat: 48.4151773, lng: 18.4828744 },
    { lat: 48.4150696, lng: 18.483375 },
    { lat: 48.4149934, lng: 18.483468 },
    { lat: 48.4147761, lng: 18.4844525 },
    { lat: 48.4147545, lng: 18.484713 },
    { lat: 48.4148574, lng: 18.4849018 },
    { lat: 48.4148639, lng: 18.4850786 },
    { lat: 48.4147089, lng: 18.4852971 },
    { lat: 48.4146604, lng: 18.4857843 },
    { lat: 48.4147648, lng: 18.4860813 },
    { lat: 48.4147307, lng: 18.4863018 },
    { lat: 48.4148122, lng: 18.4866936 },
    { lat: 48.4147974, lng: 18.4870981 },
    { lat: 48.4147694, lng: 18.4872018 },
    { lat: 48.4145804, lng: 18.4871923 },
    { lat: 48.4143751, lng: 18.4876643 },
    { lat: 48.4143584, lng: 18.4878706 },
    { lat: 48.4145279, lng: 18.4883441 },
    { lat: 48.4144378, lng: 18.4885712 },
    { lat: 48.4144055, lng: 18.4889275 },
    { lat: 48.4143173, lng: 18.4890631 },
    { lat: 48.4143653, lng: 18.4893196 },
    { lat: 48.4143624, lng: 18.4896949 },
    { lat: 48.4142868, lng: 18.4898584 },
    { lat: 48.4142905, lng: 18.4901293 },
    { lat: 48.4141799, lng: 18.4902113 },
    { lat: 48.4141439, lng: 18.4901508 },
    { lat: 48.4140793, lng: 18.4901872 },
    { lat: 48.4140941, lng: 18.4904769 },
    { lat: 48.4142389, lng: 18.4909492 },
    { lat: 48.4142185, lng: 18.4910935 },
    { lat: 48.4141394, lng: 18.4911928 },
    { lat: 48.4141747, lng: 18.491383 },
    { lat: 48.4140207, lng: 18.4913887 },
    { lat: 48.4136923, lng: 18.4916154 },
    { lat: 48.4134427, lng: 18.491649 },
    { lat: 48.413037, lng: 18.4920622 },
    { lat: 48.4125957, lng: 18.4919393 },
    { lat: 48.4125252, lng: 18.492049 },
    { lat: 48.4124253, lng: 18.492081 },
    { lat: 48.4122026, lng: 18.4920303 },
    { lat: 48.411977, lng: 18.4922118 },
    { lat: 48.4116518, lng: 18.4923117 },
    { lat: 48.4114322, lng: 18.4922773 },
    { lat: 48.4113211, lng: 18.4924704 },
    { lat: 48.4111786, lng: 18.4924619 },
    { lat: 48.4111068, lng: 18.4923903 },
    { lat: 48.4111192, lng: 18.4921018 },
    { lat: 48.4110412, lng: 18.4919994 },
    { lat: 48.4106729, lng: 18.4923748 },
    { lat: 48.4106288, lng: 18.4925184 },
    { lat: 48.4104366, lng: 18.4925307 },
    { lat: 48.4102924, lng: 18.492189 },
    { lat: 48.41018, lng: 18.4921743 },
    { lat: 48.4099274, lng: 18.4923173 },
    { lat: 48.4097832, lng: 18.4922607 },
    { lat: 48.4096448, lng: 18.4920621 },
    { lat: 48.4094882, lng: 18.4921494 },
    { lat: 48.4094039, lng: 18.4924387 },
    { lat: 48.4093218, lng: 18.4925646 },
    { lat: 48.4091986, lng: 18.4923759 },
    { lat: 48.409053, lng: 18.4923983 },
    { lat: 48.4089036, lng: 18.4926931 },
    { lat: 48.4086102, lng: 18.4926694 },
    { lat: 48.408508, lng: 18.4928653 },
    { lat: 48.408297, lng: 18.4929651 },
    { lat: 48.4081988, lng: 18.4928602 },
    { lat: 48.4080902, lng: 18.4928546 },
    { lat: 48.4080536, lng: 18.4929287 },
    { lat: 48.4079764, lng: 18.4928571 },
    { lat: 48.4079225, lng: 18.4929252 },
    { lat: 48.407714, lng: 18.4928444 },
    { lat: 48.4075462, lng: 18.4928497 },
    { lat: 48.4074825, lng: 18.492946 },
    { lat: 48.4072539, lng: 18.4928691 },
    { lat: 48.4070984, lng: 18.4929343 },
    { lat: 48.4068388, lng: 18.4929322 },
    { lat: 48.406793, lng: 18.4929379 },
    { lat: 48.4065906, lng: 18.4929926 },
    { lat: 48.4062717, lng: 18.4926713 },
    { lat: 48.4059288, lng: 18.492652 },
    { lat: 48.4057452, lng: 18.4924732 },
    { lat: 48.4055812, lng: 18.492586 },
    { lat: 48.4050084, lng: 18.4934274 },
    { lat: 48.4043568, lng: 18.49407 },
    { lat: 48.4041048, lng: 18.4947759 },
    { lat: 48.4030936, lng: 18.4960952 },
    { lat: 48.4028496, lng: 18.4966372 },
    { lat: 48.4027056, lng: 18.4975263 },
    { lat: 48.4029014, lng: 18.4985975 },
    { lat: 48.4023281, lng: 18.500636 },
    { lat: 48.4024483, lng: 18.5007893 },
    { lat: 48.4027241, lng: 18.5022083 },
    { lat: 48.40458, lng: 18.5041971 },
    { lat: 48.4054354, lng: 18.5053648 },
    { lat: 48.4057987, lng: 18.5059814 },
    { lat: 48.4061922, lng: 18.5063865 },
    { lat: 48.4065728, lng: 18.5072739 },
    { lat: 48.4068677, lng: 18.5078337 },
    { lat: 48.4068931, lng: 18.5084387 },
    { lat: 48.4070614, lng: 18.5091385 },
    { lat: 48.4070609, lng: 18.5094751 },
    { lat: 48.4070413, lng: 18.5098801 },
    { lat: 48.406833, lng: 18.5104354 },
    { lat: 48.4070354, lng: 18.5114487 },
    { lat: 48.4072773, lng: 18.5121099 },
    { lat: 48.4078664, lng: 18.5128525 },
    { lat: 48.408195, lng: 18.514191 },
    { lat: 48.4089336, lng: 18.5152502 },
    { lat: 48.4096511, lng: 18.5169285 },
    { lat: 48.40996, lng: 18.517542 },
    { lat: 48.4102393, lng: 18.5187208 },
    { lat: 48.4108557, lng: 18.5205733 },
    { lat: 48.4107564, lng: 18.5212485 },
    { lat: 48.4110547, lng: 18.5219344 },
    { lat: 48.4123711, lng: 18.523945 },
    { lat: 48.4127723, lng: 18.5248194 },
    { lat: 48.4128031, lng: 18.5251732 },
    { lat: 48.413076, lng: 18.5261548 },
    { lat: 48.4130381, lng: 18.5265139 },
    { lat: 48.4128452, lng: 18.5271073 },
    { lat: 48.4131521, lng: 18.5290601 },
    { lat: 48.4131413, lng: 18.5293977 },
    { lat: 48.4130316, lng: 18.5297799 },
    { lat: 48.412626, lng: 18.530508 },
    { lat: 48.4119482, lng: 18.5313749 },
    { lat: 48.4125138, lng: 18.5325529 },
    { lat: 48.4135374, lng: 18.532868 },
    { lat: 48.4138851, lng: 18.5326474 },
    { lat: 48.4141653, lng: 18.5334372 },
    { lat: 48.4139465, lng: 18.5334994 },
    { lat: 48.4139365, lng: 18.5340138 },
    { lat: 48.4142045, lng: 18.5345206 },
    { lat: 48.4145754, lng: 18.5347189 },
    { lat: 48.4150046, lng: 18.5355322 },
    { lat: 48.4151547, lng: 18.5366214 },
    { lat: 48.4161278, lng: 18.5373566 },
    { lat: 48.4165849, lng: 18.5378731 },
    { lat: 48.4166915, lng: 18.5381298 },
    { lat: 48.417484, lng: 18.539171 },
    { lat: 48.417743, lng: 18.538855 },
    { lat: 48.417782, lng: 18.538781 },
    { lat: 48.418105, lng: 18.538508 },
    { lat: 48.418185, lng: 18.538392 },
    { lat: 48.418442, lng: 18.538262 },
    { lat: 48.41861, lng: 18.538104 },
    { lat: 48.418881, lng: 18.537914 },
    { lat: 48.419153, lng: 18.537706 },
    { lat: 48.419538, lng: 18.537262 },
    { lat: 48.419747, lng: 18.537331 },
    { lat: 48.419825, lng: 18.537336 },
    { lat: 48.419917, lng: 18.537394 },
    { lat: 48.419982, lng: 18.537511 },
    { lat: 48.420395, lng: 18.537852 },
    { lat: 48.420536, lng: 18.53793 },
    { lat: 48.420588, lng: 18.538121 },
    { lat: 48.420701, lng: 18.538231 },
    { lat: 48.420854, lng: 18.538259 },
    { lat: 48.421033, lng: 18.538452 },
    { lat: 48.421298, lng: 18.538334 },
    { lat: 48.421582, lng: 18.53847 },
    { lat: 48.421762, lng: 18.538363 },
    { lat: 48.422047, lng: 18.538529 },
    { lat: 48.422548, lng: 18.538592 },
    { lat: 48.422761, lng: 18.538745 },
    { lat: 48.422946, lng: 18.538773 },
    { lat: 48.423033, lng: 18.538907 },
    { lat: 48.423271, lng: 18.538922 },
    { lat: 48.423424, lng: 18.539052 },
    { lat: 48.423483, lng: 18.539159 },
    { lat: 48.423741, lng: 18.53935 },
    { lat: 48.423871, lng: 18.539523 },
    { lat: 48.423979, lng: 18.539584 },
    { lat: 48.424043, lng: 18.539677 },
    { lat: 48.424243, lng: 18.540395 },
    { lat: 48.424328, lng: 18.540633 },
    { lat: 48.424402, lng: 18.540708 },
    { lat: 48.424705, lng: 18.540819 },
    { lat: 48.425025, lng: 18.540807 },
    { lat: 48.425155, lng: 18.540822 },
    { lat: 48.425273, lng: 18.540934 },
    { lat: 48.425479, lng: 18.541323 },
    { lat: 48.425605, lng: 18.541476 },
    { lat: 48.425869, lng: 18.541475 },
    { lat: 48.426059, lng: 18.541474 },
    { lat: 48.42634, lng: 18.541557 },
    { lat: 48.426939, lng: 18.541497 },
    { lat: 48.427053, lng: 18.541397 },
    { lat: 48.427211, lng: 18.541346 },
    { lat: 48.427353, lng: 18.541358 },
    { lat: 48.427588, lng: 18.541706 },
    { lat: 48.427715, lng: 18.541846 },
    { lat: 48.427825, lng: 18.542025 },
    { lat: 48.427829, lng: 18.542075 },
    { lat: 48.427956, lng: 18.542452 },
    { lat: 48.427968, lng: 18.542475 },
    { lat: 48.428021, lng: 18.542544 },
    { lat: 48.428157, lng: 18.542693 },
    { lat: 48.428257, lng: 18.542733 },
    { lat: 48.428336, lng: 18.542926 },
    { lat: 48.428357, lng: 18.542974 },
    { lat: 48.428427, lng: 18.543127 },
    { lat: 48.428445, lng: 18.543147 },
    { lat: 48.428497, lng: 18.543173 },
    { lat: 48.428589, lng: 18.543194 },
    { lat: 48.428599, lng: 18.543206 },
    { lat: 48.42861, lng: 18.543289 },
    { lat: 48.428754, lng: 18.543412 },
    { lat: 48.428788, lng: 18.543405 },
    { lat: 48.428824, lng: 18.543454 },
    { lat: 48.428856, lng: 18.54354 },
    { lat: 48.428895, lng: 18.543623 },
    { lat: 48.429067, lng: 18.543686 },
    { lat: 48.429301, lng: 18.543711 },
    { lat: 48.429437, lng: 18.543866 },
    { lat: 48.429524, lng: 18.543949 },
    { lat: 48.429604, lng: 18.54402 },
    { lat: 48.429651, lng: 18.544048 },
    { lat: 48.429777, lng: 18.544124 },
    { lat: 48.429861, lng: 18.544079 },
    { lat: 48.430003, lng: 18.544055 },
    { lat: 48.430032, lng: 18.54405 },
    { lat: 48.430162, lng: 18.544031 },
    { lat: 48.4302, lng: 18.543981 },
    { lat: 48.430369, lng: 18.544079 },
    { lat: 48.43055, lng: 18.54394 },
    { lat: 48.430652, lng: 18.544013 },
    { lat: 48.430663, lng: 18.544151 },
    { lat: 48.430735, lng: 18.544306 },
    { lat: 48.430754, lng: 18.544331 },
    { lat: 48.430879, lng: 18.544352 },
    { lat: 48.43091, lng: 18.544344 },
    { lat: 48.431012, lng: 18.544507 },
    { lat: 48.431105, lng: 18.544629 },
    { lat: 48.431214, lng: 18.544918 },
    { lat: 48.431236, lng: 18.545002 },
    { lat: 48.431279, lng: 18.545166 },
    { lat: 48.431302, lng: 18.545202 },
    { lat: 48.431592, lng: 18.545519 },
    { lat: 48.431741, lng: 18.54571 },
    { lat: 48.431885, lng: 18.545829 },
    { lat: 48.432032, lng: 18.545963 },
    { lat: 48.432166, lng: 18.546158 },
    { lat: 48.432205, lng: 18.546191 },
    { lat: 48.432347, lng: 18.546194 },
    { lat: 48.432702, lng: 18.545936 },
    { lat: 48.433131, lng: 18.545834 },
    { lat: 48.433631, lng: 18.545896 },
    { lat: 48.433802, lng: 18.545838 },
    { lat: 48.434182, lng: 18.545875 },
    { lat: 48.434225, lng: 18.545823 },
    { lat: 48.43434, lng: 18.545857 },
    { lat: 48.434411, lng: 18.545827 },
    { lat: 48.434581, lng: 18.545998 },
    { lat: 48.434903, lng: 18.545988 },
    { lat: 48.435173, lng: 18.545869 },
    { lat: 48.435239, lng: 18.545902 },
    { lat: 48.435278, lng: 18.545965 },
    { lat: 48.435414, lng: 18.545941 },
    { lat: 48.435448, lng: 18.545908 },
    { lat: 48.435514, lng: 18.545901 },
    { lat: 48.435592, lng: 18.545988 },
    { lat: 48.435914, lng: 18.545825 },
    { lat: 48.436041, lng: 18.545805 },
    { lat: 48.436109, lng: 18.545731 },
    { lat: 48.436151, lng: 18.545636 },
    { lat: 48.436126, lng: 18.545452 },
    { lat: 48.436218, lng: 18.545259 },
    { lat: 48.436283, lng: 18.545048 },
    { lat: 48.436286, lng: 18.544806 },
    { lat: 48.436357, lng: 18.5446 },
    { lat: 48.436459, lng: 18.544588 },
    { lat: 48.436518, lng: 18.544551 },
    { lat: 48.436603, lng: 18.544339 },
    { lat: 48.436603, lng: 18.544229 },
    { lat: 48.43657, lng: 18.544071 },
    { lat: 48.4366, lng: 18.543918 },
    { lat: 48.436717, lng: 18.543726 },
    { lat: 48.436738, lng: 18.543526 },
    { lat: 48.436884, lng: 18.543336 },
    { lat: 48.436943, lng: 18.543194 },
    { lat: 48.436971, lng: 18.542925 },
    { lat: 48.437026, lng: 18.542802 },
    { lat: 48.437308, lng: 18.542485 },
    { lat: 48.437383, lng: 18.54236 },
    { lat: 48.437501, lng: 18.542261 },
    { lat: 48.437587, lng: 18.542007 },
    { lat: 48.437696, lng: 18.541852 },
    { lat: 48.437834, lng: 18.541705 },
    { lat: 48.438031, lng: 18.541284 },
    { lat: 48.438087, lng: 18.541113 },
    { lat: 48.438161, lng: 18.541022 },
    { lat: 48.438269, lng: 18.541041 },
    { lat: 48.438323, lng: 18.540974 },
    { lat: 48.438514, lng: 18.540747 },
    { lat: 48.438584, lng: 18.540511 },
    { lat: 48.438785, lng: 18.540359 },
    { lat: 48.438959, lng: 18.540346 },
    { lat: 48.439077, lng: 18.540219 },
    { lat: 48.439056, lng: 18.540064 },
    { lat: 48.439034, lng: 18.539999 },
    { lat: 48.439016, lng: 18.539905 },
    { lat: 48.439017, lng: 18.53975 },
    { lat: 48.439066, lng: 18.539675 },
    { lat: 48.439156, lng: 18.539403 },
    { lat: 48.439267, lng: 18.539043 },
    { lat: 48.439232, lng: 18.538874 },
    { lat: 48.439204, lng: 18.5384 },
    { lat: 48.439126, lng: 18.538052 },
    { lat: 48.439221, lng: 18.537787 },
    { lat: 48.439262, lng: 18.537353 },
    { lat: 48.43916, lng: 18.536864 },
    { lat: 48.439145, lng: 18.536822 },
    { lat: 48.439123, lng: 18.536687 },
    { lat: 48.439259, lng: 18.536224 },
    { lat: 48.439301, lng: 18.535963 },
    { lat: 48.439381, lng: 18.535731 },
    { lat: 48.439374, lng: 18.535321 },
    { lat: 48.439482, lng: 18.535108 },
    { lat: 48.439579, lng: 18.535101 },
    { lat: 48.439726, lng: 18.53519 },
    { lat: 48.439742, lng: 18.535181 },
    { lat: 48.439911, lng: 18.535093 },
    { lat: 48.439967, lng: 18.535009 },
    { lat: 48.440115, lng: 18.534988 },
    { lat: 48.440387, lng: 18.534806 },
    { lat: 48.440713, lng: 18.534839 },
    { lat: 48.440775, lng: 18.534792 },
    { lat: 48.440817, lng: 18.534761 },
    { lat: 48.440859, lng: 18.534785 },
    { lat: 48.440994, lng: 18.534923 },
    { lat: 48.441079, lng: 18.534903 },
    { lat: 48.4413, lng: 18.534768 },
    { lat: 48.441554, lng: 18.534571 },
    { lat: 48.441662, lng: 18.534583 },
    { lat: 48.441782, lng: 18.53452 },
    { lat: 48.442041, lng: 18.534623 },
    { lat: 48.442133, lng: 18.534702 },
    { lat: 48.442331, lng: 18.534644 },
    { lat: 48.442446, lng: 18.534526 },
    { lat: 48.442605, lng: 18.534422 },
    { lat: 48.442677, lng: 18.534414 },
    { lat: 48.442746, lng: 18.534451 },
    { lat: 48.442782, lng: 18.534503 },
    { lat: 48.442943, lng: 18.534529 },
    { lat: 48.442999, lng: 18.534607 },
    { lat: 48.443142, lng: 18.534704 },
    { lat: 48.443293, lng: 18.534626 },
    { lat: 48.44339, lng: 18.534523 },
    { lat: 48.443502, lng: 18.534484 },
    { lat: 48.443705, lng: 18.534546 },
    { lat: 48.443873, lng: 18.53468 },
    { lat: 48.443972, lng: 18.534668 },
    { lat: 48.444045, lng: 18.534685 },
    { lat: 48.444477, lng: 18.534574 },
    { lat: 48.444806, lng: 18.534609 },
    { lat: 48.445164, lng: 18.534576 },
    { lat: 48.445424, lng: 18.534604 },
    { lat: 48.445647, lng: 18.534418 },
    { lat: 48.44584, lng: 18.534334 },
    { lat: 48.446182, lng: 18.534534 },
    { lat: 48.446345, lng: 18.534518 },
    { lat: 48.446552, lng: 18.534551 },
    { lat: 48.446714, lng: 18.534517 },
    { lat: 48.446801, lng: 18.534485 },
    { lat: 48.446889, lng: 18.534453 },
    { lat: 48.447025, lng: 18.534467 },
    { lat: 48.447148, lng: 18.534448 },
    { lat: 48.447304, lng: 18.534338 },
    { lat: 48.447515, lng: 18.534288 },
    { lat: 48.447772, lng: 18.534374 },
    { lat: 48.44807, lng: 18.534239 },
    { lat: 48.448206, lng: 18.534343 },
    { lat: 48.44827, lng: 18.534298 },
    { lat: 48.448374, lng: 18.534395 },
    { lat: 48.448582, lng: 18.534413 },
    { lat: 48.448767, lng: 18.534498 },
    { lat: 48.449059, lng: 18.534442 },
    { lat: 48.449525, lng: 18.534118 },
    { lat: 48.449544, lng: 18.534144 },
    { lat: 48.449549, lng: 18.534228 },
    { lat: 48.44957, lng: 18.534235 },
    { lat: 48.449654, lng: 18.534233 },
    { lat: 48.449715, lng: 18.534198 },
    { lat: 48.449761, lng: 18.534111 },
    { lat: 48.449754, lng: 18.533861 },
    { lat: 48.449809, lng: 18.533812 },
    { lat: 48.449875, lng: 18.533786 },
    { lat: 48.449937, lng: 18.533643 },
    { lat: 48.450057, lng: 18.533521 },
    { lat: 48.450155, lng: 18.533468 },
    { lat: 48.450222, lng: 18.533344 },
    { lat: 48.450326, lng: 18.533297 },
    { lat: 48.450462, lng: 18.533429 },
    { lat: 48.45056, lng: 18.533456 },
    { lat: 48.450633, lng: 18.533559 },
    { lat: 48.450779, lng: 18.533552 },
    { lat: 48.450976, lng: 18.533503 },
    { lat: 48.451105, lng: 18.533409 },
    { lat: 48.451258, lng: 18.533258 },
    { lat: 48.451428, lng: 18.533021 },
    { lat: 48.451719, lng: 18.532969 },
    { lat: 48.451766, lng: 18.532853 },
    { lat: 48.451794, lng: 18.532746 },
    { lat: 48.451888, lng: 18.53271 },
    { lat: 48.452011, lng: 18.532638 },
    { lat: 48.452058, lng: 18.532512 },
    { lat: 48.452156, lng: 18.532058 },
    { lat: 48.452266, lng: 18.531844 },
    { lat: 48.45242, lng: 18.531177 },
    { lat: 48.452469, lng: 18.530917 },
    { lat: 48.452439, lng: 18.530684 },
    { lat: 48.452484, lng: 18.530312 },
    { lat: 48.452483, lng: 18.529968 },
    { lat: 48.452505, lng: 18.529603 },
    { lat: 48.452522, lng: 18.529194 },
    { lat: 48.452577, lng: 18.52893 },
    { lat: 48.452583, lng: 18.52874 },
    { lat: 48.452515, lng: 18.52862 },
    { lat: 48.452514, lng: 18.528505 },
    { lat: 48.452376, lng: 18.52823 },
    { lat: 48.452388, lng: 18.528076 },
    { lat: 48.452358, lng: 18.527911 },
    { lat: 48.452421, lng: 18.527714 },
    { lat: 48.452369, lng: 18.527481 },
    { lat: 48.45225, lng: 18.527215 },
    { lat: 48.452027, lng: 18.527059 },
    { lat: 48.45197, lng: 18.526957 },
    { lat: 48.4519, lng: 18.526545 },
    { lat: 48.451841, lng: 18.526434 },
    { lat: 48.451697, lng: 18.526255 },
    { lat: 48.451647, lng: 18.525975 },
    { lat: 48.451691, lng: 18.525649 },
    { lat: 48.451761, lng: 18.525302 },
    { lat: 48.451703, lng: 18.525158 },
    { lat: 48.451531, lng: 18.524907 },
    { lat: 48.451481, lng: 18.52476 },
    { lat: 48.451531, lng: 18.524472 },
    { lat: 48.451425, lng: 18.524131 },
    { lat: 48.45135, lng: 18.523736 },
    { lat: 48.451411, lng: 18.523398 },
    { lat: 48.451425, lng: 18.523033 },
    { lat: 48.451472, lng: 18.522931 },
    { lat: 48.451481, lng: 18.522763 },
    { lat: 48.45142, lng: 18.522446 },
    { lat: 48.451428, lng: 18.522331 },
    { lat: 48.451274, lng: 18.521914 },
    { lat: 48.451186, lng: 18.521463 },
    { lat: 48.451129, lng: 18.521434 },
    { lat: 48.451052, lng: 18.521255 },
    { lat: 48.451041, lng: 18.520971 },
    { lat: 48.4510177, lng: 18.5208943 },
    { lat: 48.4508271, lng: 18.5206469 },
    { lat: 48.4508174, lng: 18.5205312 },
    { lat: 48.4505702, lng: 18.5199571 },
    { lat: 48.449984, lng: 18.5190592 },
    { lat: 48.4497815, lng: 18.5190524 },
    { lat: 48.4496655, lng: 18.5189112 },
    { lat: 48.4496772, lng: 18.5187321 },
    { lat: 48.4496153, lng: 18.5186726 },
    { lat: 48.449578, lng: 18.5183866 },
    { lat: 48.4494517, lng: 18.5181397 },
    { lat: 48.4491707, lng: 18.5179526 },
    { lat: 48.4490447, lng: 18.517754 },
    { lat: 48.4488886, lng: 18.5177702 },
    { lat: 48.4488505, lng: 18.5176481 },
    { lat: 48.448495, lng: 18.5173615 },
    { lat: 48.4482506, lng: 18.5167946 },
    { lat: 48.448118, lng: 18.5162497 },
    { lat: 48.4481357, lng: 18.5159352 },
    { lat: 48.447976, lng: 18.515082 },
    { lat: 48.4479045, lng: 18.5140935 },
    { lat: 48.4478102, lng: 18.5137112 },
    { lat: 48.4478331, lng: 18.5134095 },
    { lat: 48.4477955, lng: 18.5132597 },
    { lat: 48.4478607, lng: 18.5129506 },
    { lat: 48.4478748, lng: 18.5120339 },
    { lat: 48.4479468, lng: 18.5118903 },
    { lat: 48.4479611, lng: 18.5117176 },
    { lat: 48.4479752, lng: 18.5114614 },
    { lat: 48.4479168, lng: 18.5110967 },
    { lat: 48.4479719, lng: 18.5107679 },
    { lat: 48.4478745, lng: 18.5105356 },
    { lat: 48.4478687, lng: 18.5103064 },
    { lat: 48.4476246, lng: 18.5100749 },
    { lat: 48.4473493, lng: 18.5096643 },
    { lat: 48.4471844, lng: 18.5096198 },
    { lat: 48.4469196, lng: 18.5092755 },
    { lat: 48.446953, lng: 18.5091267 },
    { lat: 48.4468935, lng: 18.5087799 },
    { lat: 48.4469188, lng: 18.5086083 },
    { lat: 48.4471026, lng: 18.5083797 },
    { lat: 48.4473857, lng: 18.5076242 },
    { lat: 48.4473108, lng: 18.5070021 },
    { lat: 48.447322, lng: 18.506854 },
    { lat: 48.4474394, lng: 18.5066507 },
    { lat: 48.4474745, lng: 18.5064306 },
    { lat: 48.4474656, lng: 18.506268 },
    { lat: 48.4473011, lng: 18.5059036 },
    { lat: 48.4472612, lng: 18.5049358 },
    { lat: 48.4472846, lng: 18.5047268 },
    { lat: 48.4474009, lng: 18.50446 },
    { lat: 48.4473463, lng: 18.5039637 },
    { lat: 48.4474508, lng: 18.5036458 },
    { lat: 48.4475896, lng: 18.5034242 },
    { lat: 48.4475969, lng: 18.5033128 },
    { lat: 48.4474814, lng: 18.5031818 },
    { lat: 48.4474354, lng: 18.5028568 },
    { lat: 48.4473046, lng: 18.5026332 },
    { lat: 48.4473681, lng: 18.5023531 },
    { lat: 48.4473757, lng: 18.5012447 },
    { lat: 48.4472474, lng: 18.5007771 },
    { lat: 48.4474275, lng: 18.500281 },
    { lat: 48.4473368, lng: 18.4999267 },
    { lat: 48.4474458, lng: 18.4993236 },
    { lat: 48.4475526, lng: 18.4979089 },
    { lat: 48.4478853, lng: 18.4974133 },
    { lat: 48.447995, lng: 18.4973804 },
    { lat: 48.4484164, lng: 18.4975162 },
    { lat: 48.4486634, lng: 18.497349 },
    { lat: 48.4487383, lng: 18.4970919 },
    { lat: 48.4488542, lng: 18.4970452 },
    { lat: 48.4492435, lng: 18.4970055 },
    { lat: 48.4493094, lng: 18.4971003 },
    { lat: 48.4496464, lng: 18.4972352 },
    { lat: 48.4499271, lng: 18.4970695 },
    { lat: 48.4503173, lng: 18.4972318 },
    { lat: 48.450494, lng: 18.4970801 },
    { lat: 48.4508258, lng: 18.4970234 },
    { lat: 48.4510319, lng: 18.497077 },
    { lat: 48.4511312, lng: 18.4969541 },
    { lat: 48.4516971, lng: 18.4966628 },
    { lat: 48.4518723, lng: 18.4964238 },
    { lat: 48.4520042, lng: 18.4963377 },
    { lat: 48.4520976, lng: 18.4963559 },
    { lat: 48.4521877, lng: 18.4964755 },
    { lat: 48.4524217, lng: 18.4965266 },
    { lat: 48.4526949, lng: 18.4964868 },
    { lat: 48.452707, lng: 18.4965443 },
    { lat: 48.4540479, lng: 18.4958436 },
    { lat: 48.45362, lng: 18.4943245 },
    { lat: 48.4530196, lng: 18.4930743 },
    { lat: 48.4528791, lng: 18.4924703 },
    { lat: 48.4533899, lng: 18.492102 },
    { lat: 48.4522589, lng: 18.4903701 },
    { lat: 48.4521956, lng: 18.4893493 },
    { lat: 48.4519628, lng: 18.4879949 },
    { lat: 48.4517817, lng: 18.4865567 },
    { lat: 48.4516213, lng: 18.4834528 },
    { lat: 48.4506528, lng: 18.4830764 },
    { lat: 48.4499858, lng: 18.4823564 },
    { lat: 48.4499999, lng: 18.4822221 },
    { lat: 48.4506932, lng: 18.4824175 },
    { lat: 48.4508234, lng: 18.4823874 },
    { lat: 48.4513071, lng: 18.482054 },
    { lat: 48.4513395, lng: 18.4818883 },
    { lat: 48.4515086, lng: 18.4817022 },
    { lat: 48.4515203, lng: 18.4814005 },
    { lat: 48.45158, lng: 18.48136 },
    { lat: 48.4519123, lng: 18.481188 },
    { lat: 48.452315, lng: 18.4810879 },
    { lat: 48.4525487, lng: 18.4810494 },
    { lat: 48.4528315, lng: 18.4811061 },
    { lat: 48.4531968, lng: 18.4809353 },
    { lat: 48.453382, lng: 18.4809214 },
    { lat: 48.4533943, lng: 18.4807766 },
    { lat: 48.4535801, lng: 18.4805658 },
    { lat: 48.4537166, lng: 18.4802175 },
    { lat: 48.4537023, lng: 18.4799609 },
    { lat: 48.4538165, lng: 18.4798237 },
    { lat: 48.4539375, lng: 18.479482 },
    { lat: 48.4543309, lng: 18.4782742 },
    { lat: 48.4543901, lng: 18.4778955 },
    { lat: 48.4544768, lng: 18.4777332 },
    { lat: 48.4544788, lng: 18.4776061 },
    { lat: 48.4546367, lng: 18.4771375 },
    { lat: 48.4554823, lng: 18.4762331 },
    { lat: 48.4556416, lng: 18.4759509 },
    { lat: 48.4557223, lng: 18.4743736 },
    { lat: 48.4555715, lng: 18.4740538 },
    { lat: 48.4555401, lng: 18.4738226 },
    { lat: 48.4555199, lng: 18.4736739 },
    { lat: 48.4554914, lng: 18.4733814 },
    { lat: 48.4552449, lng: 18.4720078 },
    { lat: 48.455252, lng: 18.4714828 },
    { lat: 48.4550517, lng: 18.4709833 },
    { lat: 48.454472, lng: 18.4679986 },
    { lat: 48.4545083, lng: 18.4678069 },
    { lat: 48.4545454, lng: 18.4676113 },
    { lat: 48.4545587, lng: 18.4668978 },
    { lat: 48.4543623, lng: 18.4658322 },
    { lat: 48.4543427, lng: 18.4657258 },
    { lat: 48.4544295, lng: 18.4653994 },
    { lat: 48.4544723, lng: 18.4652383 },
    { lat: 48.4544955, lng: 18.4650904 },
    { lat: 48.4545525, lng: 18.4646319 },
    { lat: 48.454437, lng: 18.4636435 },
    { lat: 48.4543746, lng: 18.4630675 },
    { lat: 48.4540217, lng: 18.4619358 },
    { lat: 48.454097, lng: 18.4597966 },
    { lat: 48.4532436, lng: 18.4590767 },
    { lat: 48.4524516, lng: 18.4569227 },
    { lat: 48.4517408, lng: 18.4554604 },
    { lat: 48.4505125, lng: 18.4533926 },
    { lat: 48.4503878, lng: 18.4526503 },
    { lat: 48.4501009, lng: 18.4520151 },
    { lat: 48.4485441, lng: 18.4524804 },
    { lat: 48.4479061, lng: 18.4527531 },
    { lat: 48.4476111, lng: 18.452804 },
    { lat: 48.4468215, lng: 18.452076 },
    { lat: 48.4467033, lng: 18.4520596 },
    { lat: 48.4466153, lng: 18.4517348 },
    { lat: 48.446268, lng: 18.4514743 },
    { lat: 48.4457049, lng: 18.4516957 },
    { lat: 48.4453269, lng: 18.4519557 },
    { lat: 48.4450138, lng: 18.4520082 },
    { lat: 48.4441073, lng: 18.4525619 },
    { lat: 48.4438612, lng: 18.4525402 },
    { lat: 48.4435826, lng: 18.4526243 },
    { lat: 48.4428133, lng: 18.4531494 },
    { lat: 48.4425936, lng: 18.453113 },
    { lat: 48.4423034, lng: 18.4532934 },
    { lat: 48.442086, lng: 18.4530947 },
    { lat: 48.4419326, lng: 18.4533215 },
    { lat: 48.4418487, lng: 18.4532912 },
    { lat: 48.4418308, lng: 18.453152 },
    { lat: 48.4416117, lng: 18.4528437 },
    { lat: 48.4414992, lng: 18.4524827 },
    { lat: 48.4413694, lng: 18.452402 },
    { lat: 48.4412807, lng: 18.4524512 },
    { lat: 48.4411696, lng: 18.4522985 },
    { lat: 48.4409638, lng: 18.4524526 },
    { lat: 48.4405981, lng: 18.4520232 },
    { lat: 48.4402709, lng: 18.4513938 },
    { lat: 48.44007, lng: 18.4507405 },
    { lat: 48.4393978, lng: 18.4493438 },
    { lat: 48.4390899, lng: 18.4490027 },
    { lat: 48.4390613, lng: 18.4487526 },
    { lat: 48.4388059, lng: 18.4483377 },
    { lat: 48.4385849, lng: 18.4484195 },
    { lat: 48.4382635, lng: 18.4475344 },
    { lat: 48.4379603, lng: 18.4471313 },
    { lat: 48.4365498, lng: 18.4463352 },
    { lat: 48.4360304, lng: 18.4458424 },
    { lat: 48.4359738, lng: 18.4456154 },
    { lat: 48.4357477, lng: 18.4453034 },
    { lat: 48.4354533, lng: 18.4443084 },
    { lat: 48.4355433, lng: 18.4439593 },
    { lat: 48.4355969, lng: 18.4427962 },
    { lat: 48.4354952, lng: 18.4424082 },
    { lat: 48.4353414, lng: 18.4421814 },
  ],
  JedľovéKostoľany: [
    { lat: 48.508918, lng: 18.486664 },
    { lat: 48.5089343, lng: 18.4866001 },
    { lat: 48.5086748, lng: 18.48619 },
    { lat: 48.5080915, lng: 18.4857425 },
    { lat: 48.5077481, lng: 18.4853621 },
    { lat: 48.5075852, lng: 18.4850289 },
    { lat: 48.5069775, lng: 18.4841177 },
    { lat: 48.5067478, lng: 18.4840209 },
    { lat: 48.5065197, lng: 18.4837143 },
    { lat: 48.5063879, lng: 18.4836554 },
    { lat: 48.5058301, lng: 18.4831343 },
    { lat: 48.5056227, lng: 18.4828124 },
    { lat: 48.5052158, lng: 18.4824525 },
    { lat: 48.5050335, lng: 18.4821082 },
    { lat: 48.5055438, lng: 18.4816071 },
    { lat: 48.5059548, lng: 18.4812906 },
    { lat: 48.5063882, lng: 18.4805178 },
    { lat: 48.506065, lng: 18.4805899 },
    { lat: 48.5057736, lng: 18.4811557 },
    { lat: 48.5055511, lng: 18.4813927 },
    { lat: 48.5052946, lng: 18.4815077 },
    { lat: 48.5048344, lng: 18.481899 },
    { lat: 48.5042674, lng: 18.4818444 },
    { lat: 48.5032595, lng: 18.4815676 },
    { lat: 48.5029191, lng: 18.4815725 },
    { lat: 48.5016315, lng: 18.4812588 },
    { lat: 48.5012015, lng: 18.4809096 },
    { lat: 48.5004752, lng: 18.4806882 },
    { lat: 48.4999134, lng: 18.4803612 },
    { lat: 48.4994991, lng: 18.4796014 },
    { lat: 48.4992713, lng: 18.4793771 },
    { lat: 48.4990371, lng: 18.4790404 },
    { lat: 48.4987912, lng: 18.4788556 },
    { lat: 48.4987371, lng: 18.4786515 },
    { lat: 48.4981259, lng: 18.4780632 },
    { lat: 48.497292, lng: 18.4778764 },
    { lat: 48.4971881, lng: 18.4779342 },
    { lat: 48.4966703, lng: 18.477812 },
    { lat: 48.4963882, lng: 18.4778743 },
    { lat: 48.4958936, lng: 18.4778221 },
    { lat: 48.4955667, lng: 18.4779715 },
    { lat: 48.4950726, lng: 18.4780969 },
    { lat: 48.4948006, lng: 18.4776603 },
    { lat: 48.4946091, lng: 18.4773186 },
    { lat: 48.4943921, lng: 18.4772939 },
    { lat: 48.4940528, lng: 18.4770952 },
    { lat: 48.4939739, lng: 18.4768103 },
    { lat: 48.4939843, lng: 18.4765287 },
    { lat: 48.4957411, lng: 18.4744994 },
    { lat: 48.496358, lng: 18.4736389 },
    { lat: 48.4963294, lng: 18.473592 },
    { lat: 48.4961529, lng: 18.473602 },
    { lat: 48.4957554, lng: 18.4740608 },
    { lat: 48.4954336, lng: 18.4743028 },
    { lat: 48.4946267, lng: 18.4753567 },
    { lat: 48.494288, lng: 18.4756339 },
    { lat: 48.4941447, lng: 18.475882 },
    { lat: 48.4939797, lng: 18.4764066 },
    { lat: 48.4938477, lng: 18.4763026 },
    { lat: 48.4937425, lng: 18.4764598 },
    { lat: 48.4933778, lng: 18.4764959 },
    { lat: 48.4931202, lng: 18.4762831 },
    { lat: 48.4928325, lng: 18.475876 },
    { lat: 48.4926898, lng: 18.475498 },
    { lat: 48.4924619, lng: 18.4750927 },
    { lat: 48.4920253, lng: 18.4751527 },
    { lat: 48.4915679, lng: 18.4749173 },
    { lat: 48.4914378, lng: 18.474706 },
    { lat: 48.4911761, lng: 18.4744632 },
    { lat: 48.4907739, lng: 18.4743283 },
    { lat: 48.4904705, lng: 18.4741266 },
    { lat: 48.4901942, lng: 18.474088 },
    { lat: 48.4894745, lng: 18.4743723 },
    { lat: 48.4889864, lng: 18.4740523 },
    { lat: 48.4885419, lng: 18.4739092 },
    { lat: 48.4882684, lng: 18.4737784 },
    { lat: 48.4881398, lng: 18.473637 },
    { lat: 48.4879731, lng: 18.4736184 },
    { lat: 48.4878039, lng: 18.4734786 },
    { lat: 48.4873755, lng: 18.4733192 },
    { lat: 48.4870828, lng: 18.4734334 },
    { lat: 48.4864494, lng: 18.4730999 },
    { lat: 48.4856105, lng: 18.4731474 },
    { lat: 48.4854284, lng: 18.4730949 },
    { lat: 48.4848017, lng: 18.4726162 },
    { lat: 48.484617, lng: 18.4726233 },
    { lat: 48.4843549, lng: 18.4724702 },
    { lat: 48.4840415, lng: 18.4721091 },
    { lat: 48.4836719, lng: 18.471936 },
    { lat: 48.4829608, lng: 18.4712731 },
    { lat: 48.4829107, lng: 18.4711848 },
    { lat: 48.482486, lng: 18.4728843 },
    { lat: 48.4817431, lng: 18.4725906 },
    { lat: 48.4797505, lng: 18.4719915 },
    { lat: 48.4791852, lng: 18.4719696 },
    { lat: 48.4787385, lng: 18.4718687 },
    { lat: 48.4781569, lng: 18.47167 },
    { lat: 48.4776324, lng: 18.471228 },
    { lat: 48.4772381, lng: 18.4710915 },
    { lat: 48.4768298, lng: 18.4710719 },
    { lat: 48.475853, lng: 18.471461 },
    { lat: 48.4755779, lng: 18.4716878 },
    { lat: 48.4753545, lng: 18.4717904 },
    { lat: 48.474373, lng: 18.4716439 },
    { lat: 48.4737568, lng: 18.4712963 },
    { lat: 48.4735493, lng: 18.4710749 },
    { lat: 48.4733143, lng: 18.47064 },
    { lat: 48.4730611, lng: 18.4704048 },
    { lat: 48.4725127, lng: 18.4702595 },
    { lat: 48.4723883, lng: 18.4703348 },
    { lat: 48.4720561, lng: 18.470326 },
    { lat: 48.4717798, lng: 18.4700616 },
    { lat: 48.4711243, lng: 18.4697769 },
    { lat: 48.4699713, lng: 18.4696338 },
    { lat: 48.4698168, lng: 18.4695924 },
    { lat: 48.469618, lng: 18.4694865 },
    { lat: 48.4692359, lng: 18.4691035 },
    { lat: 48.4686208, lng: 18.4687964 },
    { lat: 48.4683694, lng: 18.468573 },
    { lat: 48.4682529, lng: 18.4692815 },
    { lat: 48.4682803, lng: 18.4699592 },
    { lat: 48.4680142, lng: 18.4700416 },
    { lat: 48.4679997, lng: 18.4701262 },
    { lat: 48.4675937, lng: 18.4700896 },
    { lat: 48.4675911, lng: 18.4698976 },
    { lat: 48.4674557, lng: 18.4696135 },
    { lat: 48.4673497, lng: 18.4695508 },
    { lat: 48.4673059, lng: 18.4697809 },
    { lat: 48.4670275, lng: 18.4696629 },
    { lat: 48.4667382, lng: 18.469428 },
    { lat: 48.466289, lng: 18.4693041 },
    { lat: 48.4660147, lng: 18.469401 },
    { lat: 48.4659413, lng: 18.469359 },
    { lat: 48.4657635, lng: 18.4694101 },
    { lat: 48.4650416, lng: 18.4693303 },
    { lat: 48.4647357, lng: 18.4694657 },
    { lat: 48.464605, lng: 18.4692443 },
    { lat: 48.4644724, lng: 18.469135 },
    { lat: 48.4640877, lng: 18.4691108 },
    { lat: 48.4634145, lng: 18.4689157 },
    { lat: 48.4630512, lng: 18.4692193 },
    { lat: 48.4628611, lng: 18.4695343 },
    { lat: 48.4625944, lng: 18.4696864 },
    { lat: 48.4623798, lng: 18.4696827 },
    { lat: 48.4621386, lng: 18.4697879 },
    { lat: 48.4619792, lng: 18.4695612 },
    { lat: 48.4618465, lng: 18.4695462 },
    { lat: 48.4616515, lng: 18.4697509 },
    { lat: 48.4608724, lng: 18.4698627 },
    { lat: 48.4606402, lng: 18.4697642 },
    { lat: 48.4603506, lng: 18.4698485 },
    { lat: 48.4601938, lng: 18.4701976 },
    { lat: 48.4599037, lng: 18.4705585 },
    { lat: 48.4597748, lng: 18.4705739 },
    { lat: 48.4594584, lng: 18.4707682 },
    { lat: 48.4591455, lng: 18.4711667 },
    { lat: 48.4587657, lng: 18.4714794 },
    { lat: 48.4573173, lng: 18.4718279 },
    { lat: 48.455536, lng: 18.4736563 },
    { lat: 48.4555199, lng: 18.4736739 },
    { lat: 48.4555401, lng: 18.4738226 },
    { lat: 48.4555715, lng: 18.4740538 },
    { lat: 48.4557223, lng: 18.4743736 },
    { lat: 48.4556416, lng: 18.4759509 },
    { lat: 48.4554823, lng: 18.4762331 },
    { lat: 48.4546367, lng: 18.4771375 },
    { lat: 48.4544788, lng: 18.4776061 },
    { lat: 48.4544768, lng: 18.4777332 },
    { lat: 48.4543901, lng: 18.4778955 },
    { lat: 48.4543309, lng: 18.4782742 },
    { lat: 48.4539375, lng: 18.479482 },
    { lat: 48.4538165, lng: 18.4798237 },
    { lat: 48.4537023, lng: 18.4799609 },
    { lat: 48.4537166, lng: 18.4802175 },
    { lat: 48.4535801, lng: 18.4805658 },
    { lat: 48.4533943, lng: 18.4807766 },
    { lat: 48.453382, lng: 18.4809214 },
    { lat: 48.4531968, lng: 18.4809353 },
    { lat: 48.4528315, lng: 18.4811061 },
    { lat: 48.4525487, lng: 18.4810494 },
    { lat: 48.452315, lng: 18.4810879 },
    { lat: 48.4519123, lng: 18.481188 },
    { lat: 48.45158, lng: 18.48136 },
    { lat: 48.4515203, lng: 18.4814005 },
    { lat: 48.4515086, lng: 18.4817022 },
    { lat: 48.4513395, lng: 18.4818883 },
    { lat: 48.4513071, lng: 18.482054 },
    { lat: 48.4508234, lng: 18.4823874 },
    { lat: 48.4506932, lng: 18.4824175 },
    { lat: 48.4499999, lng: 18.4822221 },
    { lat: 48.4499858, lng: 18.4823564 },
    { lat: 48.4506528, lng: 18.4830764 },
    { lat: 48.4516213, lng: 18.4834528 },
    { lat: 48.4517817, lng: 18.4865567 },
    { lat: 48.4519628, lng: 18.4879949 },
    { lat: 48.4521956, lng: 18.4893493 },
    { lat: 48.4522589, lng: 18.4903701 },
    { lat: 48.4533899, lng: 18.492102 },
    { lat: 48.4528791, lng: 18.4924703 },
    { lat: 48.4530196, lng: 18.4930743 },
    { lat: 48.45362, lng: 18.4943245 },
    { lat: 48.4540479, lng: 18.4958436 },
    { lat: 48.452707, lng: 18.4965443 },
    { lat: 48.4526949, lng: 18.4964868 },
    { lat: 48.4524217, lng: 18.4965266 },
    { lat: 48.4521877, lng: 18.4964755 },
    { lat: 48.4520976, lng: 18.4963559 },
    { lat: 48.4520042, lng: 18.4963377 },
    { lat: 48.4518723, lng: 18.4964238 },
    { lat: 48.4516971, lng: 18.4966628 },
    { lat: 48.4511312, lng: 18.4969541 },
    { lat: 48.4510319, lng: 18.497077 },
    { lat: 48.4508258, lng: 18.4970234 },
    { lat: 48.450494, lng: 18.4970801 },
    { lat: 48.4503173, lng: 18.4972318 },
    { lat: 48.4499271, lng: 18.4970695 },
    { lat: 48.4496464, lng: 18.4972352 },
    { lat: 48.4493094, lng: 18.4971003 },
    { lat: 48.4492435, lng: 18.4970055 },
    { lat: 48.4488542, lng: 18.4970452 },
    { lat: 48.4487383, lng: 18.4970919 },
    { lat: 48.4486634, lng: 18.497349 },
    { lat: 48.4484164, lng: 18.4975162 },
    { lat: 48.447995, lng: 18.4973804 },
    { lat: 48.4478853, lng: 18.4974133 },
    { lat: 48.4475526, lng: 18.4979089 },
    { lat: 48.4474458, lng: 18.4993236 },
    { lat: 48.4473368, lng: 18.4999267 },
    { lat: 48.4474275, lng: 18.500281 },
    { lat: 48.4472474, lng: 18.5007771 },
    { lat: 48.4473757, lng: 18.5012447 },
    { lat: 48.4473681, lng: 18.5023531 },
    { lat: 48.4473046, lng: 18.5026332 },
    { lat: 48.4474354, lng: 18.5028568 },
    { lat: 48.4474814, lng: 18.5031818 },
    { lat: 48.4475969, lng: 18.5033128 },
    { lat: 48.4475896, lng: 18.5034242 },
    { lat: 48.4474508, lng: 18.5036458 },
    { lat: 48.4473463, lng: 18.5039637 },
    { lat: 48.4474009, lng: 18.50446 },
    { lat: 48.4472846, lng: 18.5047268 },
    { lat: 48.4472612, lng: 18.5049358 },
    { lat: 48.4473011, lng: 18.5059036 },
    { lat: 48.4474656, lng: 18.506268 },
    { lat: 48.4474745, lng: 18.5064306 },
    { lat: 48.4474394, lng: 18.5066507 },
    { lat: 48.447322, lng: 18.506854 },
    { lat: 48.4473108, lng: 18.5070021 },
    { lat: 48.4473857, lng: 18.5076242 },
    { lat: 48.4471026, lng: 18.5083797 },
    { lat: 48.4469188, lng: 18.5086083 },
    { lat: 48.4468935, lng: 18.5087799 },
    { lat: 48.446953, lng: 18.5091267 },
    { lat: 48.4469196, lng: 18.5092755 },
    { lat: 48.4471844, lng: 18.5096198 },
    { lat: 48.4473493, lng: 18.5096643 },
    { lat: 48.4476246, lng: 18.5100749 },
    { lat: 48.4478687, lng: 18.5103064 },
    { lat: 48.4478745, lng: 18.5105356 },
    { lat: 48.4479719, lng: 18.5107679 },
    { lat: 48.4479168, lng: 18.5110967 },
    { lat: 48.4479752, lng: 18.5114614 },
    { lat: 48.4479611, lng: 18.5117176 },
    { lat: 48.4479468, lng: 18.5118903 },
    { lat: 48.4478748, lng: 18.5120339 },
    { lat: 48.4478607, lng: 18.5129506 },
    { lat: 48.4477955, lng: 18.5132597 },
    { lat: 48.4478331, lng: 18.5134095 },
    { lat: 48.4478102, lng: 18.5137112 },
    { lat: 48.4479045, lng: 18.5140935 },
    { lat: 48.447976, lng: 18.515082 },
    { lat: 48.4481357, lng: 18.5159352 },
    { lat: 48.448118, lng: 18.5162497 },
    { lat: 48.4482506, lng: 18.5167946 },
    { lat: 48.448495, lng: 18.5173615 },
    { lat: 48.4488505, lng: 18.5176481 },
    { lat: 48.4488886, lng: 18.5177702 },
    { lat: 48.4490447, lng: 18.517754 },
    { lat: 48.4491707, lng: 18.5179526 },
    { lat: 48.4494517, lng: 18.5181397 },
    { lat: 48.449578, lng: 18.5183866 },
    { lat: 48.4496153, lng: 18.5186726 },
    { lat: 48.4496772, lng: 18.5187321 },
    { lat: 48.4496655, lng: 18.5189112 },
    { lat: 48.4497815, lng: 18.5190524 },
    { lat: 48.449984, lng: 18.5190592 },
    { lat: 48.4505702, lng: 18.5199571 },
    { lat: 48.4508174, lng: 18.5205312 },
    { lat: 48.4508271, lng: 18.5206469 },
    { lat: 48.4510177, lng: 18.5208943 },
    { lat: 48.451041, lng: 18.520971 },
    { lat: 48.451553, lng: 18.521272 },
    { lat: 48.452217, lng: 18.521672 },
    { lat: 48.452615, lng: 18.521909 },
    { lat: 48.453179, lng: 18.522241 },
    { lat: 48.453849, lng: 18.522635 },
    { lat: 48.455639, lng: 18.523701 },
    { lat: 48.455869, lng: 18.523834 },
    { lat: 48.456682, lng: 18.524753 },
    { lat: 48.457388, lng: 18.525548 },
    { lat: 48.457436, lng: 18.525602 },
    { lat: 48.458433, lng: 18.526712 },
    { lat: 48.459993, lng: 18.528458 },
    { lat: 48.461092, lng: 18.529688 },
    { lat: 48.461974, lng: 18.530663 },
    { lat: 48.462005, lng: 18.530697 },
    { lat: 48.462161, lng: 18.530869 },
    { lat: 48.462174, lng: 18.530882 },
    { lat: 48.462201, lng: 18.530909 },
    { lat: 48.46291, lng: 18.530305 },
    { lat: 48.46317, lng: 18.530081 },
    { lat: 48.463671, lng: 18.529652 },
    { lat: 48.464261, lng: 18.529699 },
    { lat: 48.464586, lng: 18.529994 },
    { lat: 48.465256, lng: 18.530594 },
    { lat: 48.465501, lng: 18.530706 },
    { lat: 48.466488, lng: 18.531148 },
    { lat: 48.466622, lng: 18.531414 },
    { lat: 48.467073, lng: 18.532295 },
    { lat: 48.467124, lng: 18.532394 },
    { lat: 48.467703, lng: 18.532892 },
    { lat: 48.468069, lng: 18.533505 },
    { lat: 48.468197, lng: 18.534618 },
    { lat: 48.468241, lng: 18.535012 },
    { lat: 48.468256, lng: 18.535142 },
    { lat: 48.468268, lng: 18.535214 },
    { lat: 48.468391, lng: 18.535956 },
    { lat: 48.468477, lng: 18.536454 },
    { lat: 48.468507, lng: 18.536648 },
    { lat: 48.468767, lng: 18.537074 },
    { lat: 48.469125, lng: 18.537397 },
    { lat: 48.469205, lng: 18.537564 },
    { lat: 48.469521, lng: 18.538215 },
    { lat: 48.469735, lng: 18.538664 },
    { lat: 48.469841, lng: 18.538805 },
    { lat: 48.470121, lng: 18.539185 },
    { lat: 48.470227, lng: 18.539333 },
    { lat: 48.470239, lng: 18.539346 },
    { lat: 48.470439, lng: 18.539617 },
    { lat: 48.470639, lng: 18.539894 },
    { lat: 48.47088, lng: 18.540229 },
    { lat: 48.471071, lng: 18.540504 },
    { lat: 48.471319, lng: 18.54087 },
    { lat: 48.471597, lng: 18.541267 },
    { lat: 48.471805, lng: 18.541574 },
    { lat: 48.471959, lng: 18.541788 },
    { lat: 48.472113, lng: 18.541926 },
    { lat: 48.472617, lng: 18.54239 },
    { lat: 48.472944, lng: 18.542687 },
    { lat: 48.473429, lng: 18.543129 },
    { lat: 48.473609, lng: 18.543453 },
    { lat: 48.473919, lng: 18.544001 },
    { lat: 48.474241, lng: 18.544218 },
    { lat: 48.474424, lng: 18.54434 },
    { lat: 48.474594, lng: 18.54458 },
    { lat: 48.47499, lng: 18.545146 },
    { lat: 48.475277, lng: 18.545384 },
    { lat: 48.475814, lng: 18.545832 },
    { lat: 48.475904, lng: 18.545903 },
    { lat: 48.476388, lng: 18.546281 },
    { lat: 48.476395, lng: 18.546288 },
    { lat: 48.476609, lng: 18.546404 },
    { lat: 48.476751, lng: 18.54648 },
    { lat: 48.47686, lng: 18.546538 },
    { lat: 48.476962, lng: 18.546591 },
    { lat: 48.477019, lng: 18.546433 },
    { lat: 48.477092, lng: 18.546262 },
    { lat: 48.477168, lng: 18.546093 },
    { lat: 48.477165, lng: 18.545984 },
    { lat: 48.47713, lng: 18.545812 },
    { lat: 48.47709, lng: 18.545505 },
    { lat: 48.477134, lng: 18.545308 },
    { lat: 48.477211, lng: 18.545149 },
    { lat: 48.477351, lng: 18.545032 },
    { lat: 48.477503, lng: 18.54501 },
    { lat: 48.477653, lng: 18.544925 },
    { lat: 48.477631, lng: 18.5448 },
    { lat: 48.477617, lng: 18.544711 },
    { lat: 48.477593, lng: 18.544567 },
    { lat: 48.477576, lng: 18.544457 },
    { lat: 48.477475, lng: 18.544323 },
    { lat: 48.477314, lng: 18.544001 },
    { lat: 48.477171, lng: 18.543709 },
    { lat: 48.476956, lng: 18.54342 },
    { lat: 48.476771, lng: 18.543303 },
    { lat: 48.476655, lng: 18.543207 },
    { lat: 48.476483, lng: 18.542972 },
    { lat: 48.476345, lng: 18.542725 },
    { lat: 48.476299, lng: 18.542573 },
    { lat: 48.476189, lng: 18.541701 },
    { lat: 48.476259, lng: 18.541697 },
    { lat: 48.476313, lng: 18.541449 },
    { lat: 48.476287, lng: 18.541355 },
    { lat: 48.476011, lng: 18.540777 },
    { lat: 48.475597, lng: 18.540386 },
    { lat: 48.47557, lng: 18.54036 },
    { lat: 48.475545, lng: 18.54034 },
    { lat: 48.475449, lng: 18.540182 },
    { lat: 48.475305, lng: 18.539789 },
    { lat: 48.475266, lng: 18.53968 },
    { lat: 48.475167, lng: 18.539431 },
    { lat: 48.475122, lng: 18.539323 },
    { lat: 48.475228, lng: 18.539346 },
    { lat: 48.47526, lng: 18.539343 },
    { lat: 48.475411, lng: 18.539326 },
    { lat: 48.475405, lng: 18.539306 },
    { lat: 48.475281, lng: 18.539111 },
    { lat: 48.475251, lng: 18.539029 },
    { lat: 48.475199, lng: 18.538969 },
    { lat: 48.475152, lng: 18.53885 },
    { lat: 48.475133, lng: 18.538786 },
    { lat: 48.475113, lng: 18.538714 },
    { lat: 48.475097, lng: 18.538664 },
    { lat: 48.47509, lng: 18.538641 },
    { lat: 48.475074, lng: 18.538589 },
    { lat: 48.47507, lng: 18.538567 },
    { lat: 48.47506, lng: 18.538542 },
    { lat: 48.475048, lng: 18.538495 },
    { lat: 48.475037, lng: 18.538465 },
    { lat: 48.475011, lng: 18.538383 },
    { lat: 48.475003, lng: 18.538363 },
    { lat: 48.474998, lng: 18.538346 },
    { lat: 48.474956, lng: 18.538104 },
    { lat: 48.474941, lng: 18.537812 },
    { lat: 48.474884, lng: 18.537651 },
    { lat: 48.474872, lng: 18.537474 },
    { lat: 48.474883, lng: 18.537396 },
    { lat: 48.474906, lng: 18.537151 },
    { lat: 48.474867, lng: 18.536835 },
    { lat: 48.474867, lng: 18.536522 },
    { lat: 48.474955, lng: 18.536139 },
    { lat: 48.474876, lng: 18.535963 },
    { lat: 48.474837, lng: 18.535836 },
    { lat: 48.474829, lng: 18.535554 },
    { lat: 48.474828, lng: 18.535149 },
    { lat: 48.474829, lng: 18.53494 },
    { lat: 48.474819, lng: 18.53473 },
    { lat: 48.474812, lng: 18.534652 },
    { lat: 48.474789, lng: 18.534536 },
    { lat: 48.474757, lng: 18.534389 },
    { lat: 48.474673, lng: 18.534206 },
    { lat: 48.474448, lng: 18.533778 },
    { lat: 48.47441, lng: 18.533689 },
    { lat: 48.474327, lng: 18.533558 },
    { lat: 48.474263, lng: 18.53332 },
    { lat: 48.474233, lng: 18.533098 },
    { lat: 48.474185, lng: 18.532925 },
    { lat: 48.474088, lng: 18.532674 },
    { lat: 48.474079, lng: 18.532045 },
    { lat: 48.474077, lng: 18.531832 },
    { lat: 48.474054, lng: 18.531683 },
    { lat: 48.474044, lng: 18.531394 },
    { lat: 48.474097, lng: 18.531326 },
    { lat: 48.474148, lng: 18.531311 },
    { lat: 48.474123, lng: 18.531114 },
    { lat: 48.474118, lng: 18.531065 },
    { lat: 48.474267, lng: 18.531202 },
    { lat: 48.474395, lng: 18.531317 },
    { lat: 48.474411, lng: 18.531325 },
    { lat: 48.474434, lng: 18.531339 },
    { lat: 48.474625, lng: 18.531377 },
    { lat: 48.474693, lng: 18.531468 },
    { lat: 48.474924, lng: 18.531584 },
    { lat: 48.475021, lng: 18.531635 },
    { lat: 48.475031, lng: 18.531646 },
    { lat: 48.475082, lng: 18.531699 },
    { lat: 48.475131, lng: 18.531748 },
    { lat: 48.475183, lng: 18.531803 },
    { lat: 48.475234, lng: 18.531857 },
    { lat: 48.475281, lng: 18.531909 },
    { lat: 48.475333, lng: 18.531948 },
    { lat: 48.475392, lng: 18.531994 },
    { lat: 48.475446, lng: 18.532036 },
    { lat: 48.47554, lng: 18.532114 },
    { lat: 48.475579, lng: 18.532134 },
    { lat: 48.475689, lng: 18.53208 },
    { lat: 48.475908, lng: 18.53199 },
    { lat: 48.476018, lng: 18.531995 },
    { lat: 48.476078, lng: 18.532003 },
    { lat: 48.476197, lng: 18.532018 },
    { lat: 48.476213, lng: 18.532023 },
    { lat: 48.476356, lng: 18.532114 },
    { lat: 48.476366, lng: 18.532121 },
    { lat: 48.476407, lng: 18.532128 },
    { lat: 48.476492, lng: 18.532154 },
    { lat: 48.476516, lng: 18.532158 },
    { lat: 48.476613, lng: 18.532174 },
    { lat: 48.476624, lng: 18.532177 },
    { lat: 48.476638, lng: 18.532184 },
    { lat: 48.476814, lng: 18.532341 },
    { lat: 48.476861, lng: 18.53238 },
    { lat: 48.476903, lng: 18.532419 },
    { lat: 48.476948, lng: 18.532459 },
    { lat: 48.476996, lng: 18.5325 },
    { lat: 48.477007, lng: 18.532513 },
    { lat: 48.477057, lng: 18.532502 },
    { lat: 48.477113, lng: 18.532494 },
    { lat: 48.477177, lng: 18.532483 },
    { lat: 48.477222, lng: 18.53248 },
    { lat: 48.477232, lng: 18.532497 },
    { lat: 48.477384, lng: 18.532746 },
    { lat: 48.477397, lng: 18.532766 },
    { lat: 48.477682, lng: 18.533066 },
    { lat: 48.477771, lng: 18.533162 },
    { lat: 48.478057, lng: 18.533292 },
    { lat: 48.478112, lng: 18.533316 },
    { lat: 48.478214, lng: 18.53336 },
    { lat: 48.4784631, lng: 18.5334063 },
    { lat: 48.478612, lng: 18.533434 },
    { lat: 48.478693, lng: 18.533375 },
    { lat: 48.478725, lng: 18.533357 },
    { lat: 48.478741, lng: 18.533345 },
    { lat: 48.478959, lng: 18.533287 },
    { lat: 48.478984, lng: 18.533286 },
    { lat: 48.479044, lng: 18.533327 },
    { lat: 48.479189, lng: 18.533303 },
    { lat: 48.479247, lng: 18.533285 },
    { lat: 48.47928, lng: 18.533272 },
    { lat: 48.479313, lng: 18.533259 },
    { lat: 48.479378, lng: 18.533238 },
    { lat: 48.479512, lng: 18.533236 },
    { lat: 48.479534, lng: 18.533248 },
    { lat: 48.479563, lng: 18.53325 },
    { lat: 48.479629, lng: 18.533259 },
    { lat: 48.479729, lng: 18.533273 },
    { lat: 48.479747, lng: 18.533282 },
    { lat: 48.479855, lng: 18.53333 },
    { lat: 48.47986, lng: 18.53333 },
    { lat: 48.479923, lng: 18.533277 },
    { lat: 48.479982, lng: 18.533231 },
    { lat: 48.480101, lng: 18.533165 },
    { lat: 48.480109, lng: 18.533175 },
    { lat: 48.480227, lng: 18.533277 },
    { lat: 48.480276, lng: 18.533314 },
    { lat: 48.480331, lng: 18.533356 },
    { lat: 48.480363, lng: 18.533377 },
    { lat: 48.48043, lng: 18.533399 },
    { lat: 48.480478, lng: 18.533415 },
    { lat: 48.480512, lng: 18.53339 },
    { lat: 48.480603, lng: 18.533326 },
    { lat: 48.480654, lng: 18.533291 },
    { lat: 48.480717, lng: 18.533255 },
    { lat: 48.480805, lng: 18.533256 },
    { lat: 48.480971, lng: 18.533269 },
    { lat: 48.481004, lng: 18.533278 },
    { lat: 48.481152, lng: 18.53332 },
    { lat: 48.481179, lng: 18.533328 },
    { lat: 48.481214, lng: 18.53331 },
    { lat: 48.481271, lng: 18.533284 },
    { lat: 48.481323, lng: 18.533273 },
    { lat: 48.481352, lng: 18.533266 },
    { lat: 48.481381, lng: 18.53326 },
    { lat: 48.481433, lng: 18.533284 },
    { lat: 48.481475, lng: 18.533303 },
    { lat: 48.481567, lng: 18.533349 },
    { lat: 48.481623, lng: 18.533313 },
    { lat: 48.481887, lng: 18.533172 },
    { lat: 48.481929, lng: 18.533175 },
    { lat: 48.481964, lng: 18.533176 },
    { lat: 48.482072, lng: 18.533175 },
    { lat: 48.4822, lng: 18.533161 },
    { lat: 48.482415, lng: 18.533153 },
    { lat: 48.482438, lng: 18.533159 },
    { lat: 48.482614, lng: 18.533216 },
    { lat: 48.48266, lng: 18.533237 },
    { lat: 48.482669, lng: 18.533241 },
    { lat: 48.482825, lng: 18.533306 },
    { lat: 48.4829, lng: 18.533343 },
    { lat: 48.482983, lng: 18.53339 },
    { lat: 48.483164, lng: 18.533411 },
    { lat: 48.483195, lng: 18.53341 },
    { lat: 48.483256, lng: 18.53338 },
    { lat: 48.483415, lng: 18.533499 },
    { lat: 48.483599, lng: 18.533553 },
    { lat: 48.483769, lng: 18.533509 },
    { lat: 48.484042, lng: 18.53352 },
    { lat: 48.484154, lng: 18.533728 },
    { lat: 48.484199, lng: 18.533743 },
    { lat: 48.48437, lng: 18.533817 },
    { lat: 48.484438, lng: 18.533952 },
    { lat: 48.484476, lng: 18.534038 },
    { lat: 48.484481, lng: 18.534107 },
    { lat: 48.484535, lng: 18.53413 },
    { lat: 48.484688, lng: 18.534201 },
    { lat: 48.484837, lng: 18.534264 },
    { lat: 48.484958, lng: 18.534322 },
    { lat: 48.485123, lng: 18.534424 },
    { lat: 48.485189, lng: 18.534461 },
    { lat: 48.485435, lng: 18.534654 },
    { lat: 48.485478, lng: 18.534687 },
    { lat: 48.485566, lng: 18.534749 },
    { lat: 48.485647, lng: 18.534815 },
    { lat: 48.485682, lng: 18.534848 },
    { lat: 48.485723, lng: 18.53487 },
    { lat: 48.485779, lng: 18.534908 },
    { lat: 48.485807, lng: 18.53492 },
    { lat: 48.485886, lng: 18.53496 },
    { lat: 48.485899, lng: 18.534967 },
    { lat: 48.485942, lng: 18.535051 },
    { lat: 48.486011, lng: 18.535173 },
    { lat: 48.486106, lng: 18.53535 },
    { lat: 48.486463, lng: 18.535789 },
    { lat: 48.486552, lng: 18.535949 },
    { lat: 48.486643, lng: 18.536109 },
    { lat: 48.486658, lng: 18.53614 },
    { lat: 48.486722, lng: 18.536167 },
    { lat: 48.48676, lng: 18.536172 },
    { lat: 48.486933, lng: 18.536196 },
    { lat: 48.486948, lng: 18.536204 },
    { lat: 48.487116, lng: 18.536311 },
    { lat: 48.487272, lng: 18.536455 },
    { lat: 48.487388, lng: 18.536562 },
    { lat: 48.487484, lng: 18.536629 },
    { lat: 48.487571, lng: 18.536587 },
    { lat: 48.487631, lng: 18.536626 },
    { lat: 48.487798, lng: 18.536487 },
    { lat: 48.48784, lng: 18.53645 },
    { lat: 48.487954, lng: 18.536437 },
    { lat: 48.488105, lng: 18.536457 },
    { lat: 48.488198, lng: 18.536469 },
    { lat: 48.488208, lng: 18.536467 },
    { lat: 48.488281, lng: 18.53644 },
    { lat: 48.488526, lng: 18.536486 },
    { lat: 48.488593, lng: 18.536516 },
    { lat: 48.488623, lng: 18.53653 },
    { lat: 48.488684, lng: 18.536558 },
    { lat: 48.488713, lng: 18.536541 },
    { lat: 48.488835, lng: 18.536474 },
    { lat: 48.488928, lng: 18.536422 },
    { lat: 48.488956, lng: 18.536392 },
    { lat: 48.489051, lng: 18.536295 },
    { lat: 48.489172, lng: 18.536225 },
    { lat: 48.489355, lng: 18.53612 },
    { lat: 48.489404, lng: 18.536093 },
    { lat: 48.489446, lng: 18.536071 },
    { lat: 48.489589, lng: 18.536001 },
    { lat: 48.489633, lng: 18.535981 },
    { lat: 48.489706, lng: 18.536056 },
    { lat: 48.489868, lng: 18.536058 },
    { lat: 48.490041, lng: 18.5361 },
    { lat: 48.490195, lng: 18.536046 },
    { lat: 48.490303, lng: 18.536172 },
    { lat: 48.490371, lng: 18.536257 },
    { lat: 48.490434, lng: 18.536254 },
    { lat: 48.490525, lng: 18.536254 },
    { lat: 48.490849, lng: 18.536217 },
    { lat: 48.491127, lng: 18.536313 },
    { lat: 48.491152, lng: 18.536353 },
    { lat: 48.491273, lng: 18.536552 },
    { lat: 48.491392, lng: 18.536748 },
    { lat: 48.491465, lng: 18.536818 },
    { lat: 48.491552, lng: 18.5369 },
    { lat: 48.491717, lng: 18.537061 },
    { lat: 48.491974, lng: 18.537335 },
    { lat: 48.492024, lng: 18.537337 },
    { lat: 48.492206, lng: 18.537374 },
    { lat: 48.492336, lng: 18.537304 },
    { lat: 48.492367, lng: 18.537322 },
    { lat: 48.492407, lng: 18.537352 },
    { lat: 48.49263, lng: 18.537613 },
    { lat: 48.492712, lng: 18.537712 },
    { lat: 48.492671, lng: 18.537389 },
    { lat: 48.492658, lng: 18.537277 },
    { lat: 48.492695, lng: 18.537257 },
    { lat: 48.492814, lng: 18.537205 },
    { lat: 48.492929, lng: 18.537207 },
    { lat: 48.492977, lng: 18.53722 },
    { lat: 48.493326, lng: 18.537306 },
    { lat: 48.493379, lng: 18.537317 },
    { lat: 48.493603, lng: 18.537413 },
    { lat: 48.493656, lng: 18.537413 },
    { lat: 48.493824, lng: 18.537413 },
    { lat: 48.493919, lng: 18.537443 },
    { lat: 48.493998, lng: 18.537467 },
    { lat: 48.494198, lng: 18.53753 },
    { lat: 48.494235, lng: 18.537542 },
    { lat: 48.494289, lng: 18.537522 },
    { lat: 48.494351, lng: 18.537619 },
    { lat: 48.494388, lng: 18.537624 },
    { lat: 48.494526, lng: 18.537649 },
    { lat: 48.494572, lng: 18.537654 },
    { lat: 48.49469, lng: 18.537669 },
    { lat: 48.494768, lng: 18.537679 },
    { lat: 48.494844, lng: 18.537686 },
    { lat: 48.494895, lng: 18.537689 },
    { lat: 48.49498, lng: 18.537703 },
    { lat: 48.49509, lng: 18.537733 },
    { lat: 48.49517, lng: 18.537785 },
    { lat: 48.495282, lng: 18.537862 },
    { lat: 48.495307, lng: 18.537899 },
    { lat: 48.495474, lng: 18.538168 },
    { lat: 48.495797, lng: 18.53829 },
    { lat: 48.495924, lng: 18.538327 },
    { lat: 48.496012, lng: 18.538403 },
    { lat: 48.496123, lng: 18.538474 },
    { lat: 48.496284, lng: 18.538321 },
    { lat: 48.496491, lng: 18.538207 },
    { lat: 48.496594, lng: 18.538263 },
    { lat: 48.496648, lng: 18.538284 },
    { lat: 48.496694, lng: 18.538353 },
    { lat: 48.496741, lng: 18.538377 },
    { lat: 48.49679, lng: 18.538437 },
    { lat: 48.496907, lng: 18.538464 },
    { lat: 48.496928, lng: 18.538464 },
    { lat: 48.497003, lng: 18.538415 },
    { lat: 48.497035, lng: 18.538402 },
    { lat: 48.497106, lng: 18.538413 },
    { lat: 48.497283, lng: 18.538462 },
    { lat: 48.497402, lng: 18.538572 },
    { lat: 48.49742, lng: 18.538587 },
    { lat: 48.497472, lng: 18.538591 },
    { lat: 48.497725, lng: 18.538619 },
    { lat: 48.497766, lng: 18.538621 },
    { lat: 48.497901, lng: 18.538596 },
    { lat: 48.498128, lng: 18.538627 },
    { lat: 48.49847, lng: 18.538726 },
    { lat: 48.498622, lng: 18.53877 },
    { lat: 48.498729, lng: 18.538746 },
    { lat: 48.498883, lng: 18.538773 },
    { lat: 48.498908, lng: 18.538778 },
    { lat: 48.499091, lng: 18.538822 },
    { lat: 48.499178, lng: 18.53881 },
    { lat: 48.499354, lng: 18.538657 },
    { lat: 48.499419, lng: 18.538626 },
    { lat: 48.49944, lng: 18.538606 },
    { lat: 48.499486, lng: 18.538567 },
    { lat: 48.499666, lng: 18.538522 },
    { lat: 48.499846, lng: 18.538516 },
    { lat: 48.500018, lng: 18.538468 },
    { lat: 48.500077, lng: 18.538502 },
    { lat: 48.500417, lng: 18.538705 },
    { lat: 48.50039, lng: 18.538637 },
    { lat: 48.500277, lng: 18.538348 },
    { lat: 48.500469, lng: 18.538087 },
    { lat: 48.500504, lng: 18.538056 },
    { lat: 48.501006, lng: 18.537665 },
    { lat: 48.501133, lng: 18.537363 },
    { lat: 48.501172, lng: 18.537315 },
    { lat: 48.501291, lng: 18.53718 },
    { lat: 48.501376, lng: 18.537084 },
    { lat: 48.501406, lng: 18.537081 },
    { lat: 48.501436, lng: 18.537072 },
    { lat: 48.501483, lng: 18.537039 },
    { lat: 48.501512, lng: 18.537024 },
    { lat: 48.50156, lng: 18.537023 },
    { lat: 48.501652, lng: 18.537021 },
    { lat: 48.50181, lng: 18.536978 },
    { lat: 48.501849, lng: 18.536973 },
    { lat: 48.501894, lng: 18.536969 },
    { lat: 48.501915, lng: 18.536962 },
    { lat: 48.501934, lng: 18.536958 },
    { lat: 48.501957, lng: 18.536958 },
    { lat: 48.5021, lng: 18.53699 },
    { lat: 48.502181, lng: 18.537052 },
    { lat: 48.502354, lng: 18.537186 },
    { lat: 48.502585, lng: 18.537189 },
    { lat: 48.502723, lng: 18.537354 },
    { lat: 48.502757, lng: 18.537366 },
    { lat: 48.50285, lng: 18.537391 },
    { lat: 48.503149, lng: 18.537481 },
    { lat: 48.503272, lng: 18.537554 },
    { lat: 48.503375, lng: 18.537694 },
    { lat: 48.503418, lng: 18.537734 },
    { lat: 48.503503, lng: 18.537815 },
    { lat: 48.50353, lng: 18.537766 },
    { lat: 48.503764, lng: 18.537352 },
    { lat: 48.503952, lng: 18.537034 },
    { lat: 48.503757, lng: 18.536798 },
    { lat: 48.503553, lng: 18.536454 },
    { lat: 48.50351, lng: 18.536383 },
    { lat: 48.503332, lng: 18.536253 },
    { lat: 48.503302, lng: 18.536229 },
    { lat: 48.503278, lng: 18.536219 },
    { lat: 48.503267, lng: 18.536212 },
    { lat: 48.503221, lng: 18.536195 },
    { lat: 48.503199, lng: 18.536185 },
    { lat: 48.503183, lng: 18.536178 },
    { lat: 48.503161, lng: 18.536169 },
    { lat: 48.503074, lng: 18.536133 },
    { lat: 48.503056, lng: 18.536118 },
    { lat: 48.502892, lng: 18.536019 },
    { lat: 48.50288, lng: 18.536007 },
    { lat: 48.502841, lng: 18.535958 },
    { lat: 48.502838, lng: 18.535842 },
    { lat: 48.502827, lng: 18.53575 },
    { lat: 48.502826, lng: 18.535736 },
    { lat: 48.502941, lng: 18.535446 },
    { lat: 48.503103, lng: 18.535111 },
    { lat: 48.503258, lng: 18.5351 },
    { lat: 48.503472, lng: 18.535079 },
    { lat: 48.503557, lng: 18.534982 },
    { lat: 48.503577, lng: 18.534959 },
    { lat: 48.503602, lng: 18.534931 },
    { lat: 48.503629, lng: 18.534901 },
    { lat: 48.503803, lng: 18.534696 },
    { lat: 48.503841, lng: 18.534651 },
    { lat: 48.503931, lng: 18.534531 },
    { lat: 48.503967, lng: 18.534483 },
    { lat: 48.504038, lng: 18.534394 },
    { lat: 48.504086, lng: 18.534337 },
    { lat: 48.504102, lng: 18.534316 },
    { lat: 48.50412, lng: 18.534296 },
    { lat: 48.504135, lng: 18.534273 },
    { lat: 48.504173, lng: 18.534226 },
    { lat: 48.50418, lng: 18.534219 },
    { lat: 48.504218, lng: 18.534174 },
    { lat: 48.504289, lng: 18.534076 },
    { lat: 48.504305, lng: 18.534053 },
    { lat: 48.504329, lng: 18.534033 },
    { lat: 48.504532, lng: 18.533864 },
    { lat: 48.504585, lng: 18.533829 },
    { lat: 48.5047, lng: 18.53378 },
    { lat: 48.504748, lng: 18.533765 },
    { lat: 48.504789, lng: 18.533752 },
    { lat: 48.504824, lng: 18.533741 },
    { lat: 48.504871, lng: 18.533726 },
    { lat: 48.504968, lng: 18.5337 },
    { lat: 48.505048, lng: 18.533683 },
    { lat: 48.505066, lng: 18.53368 },
    { lat: 48.505159, lng: 18.533659 },
    { lat: 48.505196, lng: 18.533639 },
    { lat: 48.505242, lng: 18.533633 },
    { lat: 48.505326, lng: 18.53362 },
    { lat: 48.505395, lng: 18.533604 },
    { lat: 48.505452, lng: 18.533589 },
    { lat: 48.505636, lng: 18.533547 },
    { lat: 48.505651, lng: 18.533547 },
    { lat: 48.505723, lng: 18.533564 },
    { lat: 48.50588, lng: 18.533605 },
    { lat: 48.50596, lng: 18.533599 },
    { lat: 48.506023, lng: 18.533591 },
    { lat: 48.506103, lng: 18.53358 },
    { lat: 48.50639, lng: 18.533543 },
    { lat: 48.506518, lng: 18.533527 },
    { lat: 48.506611, lng: 18.533506 },
    { lat: 48.506772, lng: 18.53347 },
    { lat: 48.50683, lng: 18.533442 },
    { lat: 48.507096, lng: 18.533315 },
    { lat: 48.506848, lng: 18.533052 },
    { lat: 48.50691, lng: 18.532989 },
    { lat: 48.50718, lng: 18.532725 },
    { lat: 48.507368, lng: 18.532548 },
    { lat: 48.507526, lng: 18.53249 },
    { lat: 48.507566, lng: 18.532469 },
    { lat: 48.507962, lng: 18.532086 },
    { lat: 48.507936, lng: 18.531982 },
    { lat: 48.507837, lng: 18.531972 },
    { lat: 48.507767, lng: 18.531898 },
    { lat: 48.507728, lng: 18.531907 },
    { lat: 48.507534, lng: 18.531954 },
    { lat: 48.507421, lng: 18.531981 },
    { lat: 48.507301, lng: 18.532019 },
    { lat: 48.507237, lng: 18.532199 },
    { lat: 48.507134, lng: 18.53222 },
    { lat: 48.507047, lng: 18.532101 },
    { lat: 48.506942, lng: 18.532099 },
    { lat: 48.506925, lng: 18.5321 },
    { lat: 48.506786, lng: 18.532191 },
    { lat: 48.506644, lng: 18.532234 },
    { lat: 48.506563, lng: 18.532258 },
    { lat: 48.506449, lng: 18.532308 },
    { lat: 48.506368, lng: 18.532342 },
    { lat: 48.506303, lng: 18.532392 },
    { lat: 48.506264, lng: 18.532423 },
    { lat: 48.506156, lng: 18.532371 },
    { lat: 48.506039, lng: 18.532328 },
    { lat: 48.505751, lng: 18.53222 },
    { lat: 48.505713, lng: 18.532165 },
    { lat: 48.505432, lng: 18.531718 },
    { lat: 48.505255, lng: 18.531249 },
    { lat: 48.505194, lng: 18.53106 },
    { lat: 48.505209, lng: 18.531015 },
    { lat: 48.505331, lng: 18.530617 },
    { lat: 48.505345, lng: 18.530604 },
    { lat: 48.505463, lng: 18.530461 },
    { lat: 48.505501, lng: 18.530336 },
    { lat: 48.505514, lng: 18.530203 },
    { lat: 48.505477, lng: 18.530039 },
    { lat: 48.505377, lng: 18.52985 },
    { lat: 48.505326, lng: 18.52966 },
    { lat: 48.505356, lng: 18.529546 },
    { lat: 48.505358, lng: 18.529435 },
    { lat: 48.505334, lng: 18.529333 },
    { lat: 48.50533, lng: 18.529139 },
    { lat: 48.505415, lng: 18.528793 },
    { lat: 48.50546, lng: 18.528628 },
    { lat: 48.505551, lng: 18.528367 },
    { lat: 48.505593, lng: 18.528043 },
    { lat: 48.505625, lng: 18.527825 },
    { lat: 48.505629, lng: 18.527792 },
    { lat: 48.505558, lng: 18.527721 },
    { lat: 48.505532, lng: 18.527571 },
    { lat: 48.50556, lng: 18.527336 },
    { lat: 48.505563, lng: 18.527311 },
    { lat: 48.505764, lng: 18.526972 },
    { lat: 48.50583, lng: 18.526688 },
    { lat: 48.505987, lng: 18.526367 },
    { lat: 48.506191, lng: 18.525758 },
    { lat: 48.506214, lng: 18.525586 },
    { lat: 48.505923, lng: 18.525663 },
    { lat: 48.505968, lng: 18.525555 },
    { lat: 48.506034, lng: 18.525399 },
    { lat: 48.506264, lng: 18.525092 },
    { lat: 48.506358, lng: 18.524904 },
    { lat: 48.506449, lng: 18.524666 },
    { lat: 48.506754, lng: 18.524221 },
    { lat: 48.506961, lng: 18.523885 },
    { lat: 48.506914, lng: 18.523758 },
    { lat: 48.506993, lng: 18.523593 },
    { lat: 48.507124, lng: 18.523529 },
    { lat: 48.507248, lng: 18.523512 },
    { lat: 48.507443, lng: 18.523685 },
    { lat: 48.507373, lng: 18.524364 },
    { lat: 48.507538, lng: 18.524426 },
    { lat: 48.507642, lng: 18.524027 },
    { lat: 48.507662, lng: 18.523946 },
    { lat: 48.507908, lng: 18.523104 },
    { lat: 48.508099, lng: 18.523045 },
    { lat: 48.508437, lng: 18.522645 },
    { lat: 48.50853, lng: 18.522193 },
    { lat: 48.508567, lng: 18.521736 },
    { lat: 48.508608, lng: 18.521399 },
    { lat: 48.50877, lng: 18.520075 },
    { lat: 48.508938, lng: 18.519106 },
    { lat: 48.508983, lng: 18.518632 },
    { lat: 48.509018, lng: 18.518146 },
    { lat: 48.509082, lng: 18.517417 },
    { lat: 48.509129, lng: 18.51685 },
    { lat: 48.509187, lng: 18.515942 },
    { lat: 48.509291, lng: 18.51473 },
    { lat: 48.509363, lng: 18.513777 },
    { lat: 48.509434, lng: 18.512895 },
    { lat: 48.509439, lng: 18.512837 },
    { lat: 48.509444, lng: 18.512787 },
    { lat: 48.509447, lng: 18.512752 },
    { lat: 48.509642, lng: 18.511726 },
    { lat: 48.509612, lng: 18.5105 },
    { lat: 48.509421, lng: 18.509141 },
    { lat: 48.509384, lng: 18.508609 },
    { lat: 48.509344, lng: 18.508358 },
    { lat: 48.509147, lng: 18.507346 },
    { lat: 48.509057, lng: 18.506874 },
    { lat: 48.508794, lng: 18.505805 },
    { lat: 48.508428, lng: 18.504135 },
    { lat: 48.508313, lng: 18.503481 },
    { lat: 48.508135, lng: 18.502707 },
    { lat: 48.507969, lng: 18.502005 },
    { lat: 48.507828, lng: 18.501385 },
    { lat: 48.507785, lng: 18.500902 },
    { lat: 48.507778, lng: 18.500782 },
    { lat: 48.507774, lng: 18.500717 },
    { lat: 48.507645, lng: 18.500149 },
    { lat: 48.507505, lng: 18.499531 },
    { lat: 48.507397, lng: 18.498935 },
    { lat: 48.507269, lng: 18.498344 },
    { lat: 48.507219, lng: 18.497968 },
    { lat: 48.506956, lng: 18.496334 },
    { lat: 48.506877, lng: 18.496064 },
    { lat: 48.506838, lng: 18.496063 },
    { lat: 48.505768, lng: 18.496092 },
    { lat: 48.5048, lng: 18.496123 },
    { lat: 48.504159, lng: 18.496139 },
    { lat: 48.503556, lng: 18.496163 },
    { lat: 48.502959, lng: 18.496183 },
    { lat: 48.50256, lng: 18.496297 },
    { lat: 48.502357, lng: 18.496307 },
    { lat: 48.501765, lng: 18.496219 },
    { lat: 48.50139, lng: 18.496214 },
    { lat: 48.50047, lng: 18.496221 },
    { lat: 48.50043, lng: 18.496197 },
    { lat: 48.499724, lng: 18.495674 },
    { lat: 48.499672, lng: 18.494656 },
    { lat: 48.499573, lng: 18.492767 },
    { lat: 48.499504, lng: 18.490938 },
    { lat: 48.499473, lng: 18.490117 },
    { lat: 48.499432, lng: 18.489267 },
    { lat: 48.499347, lng: 18.487428 },
    { lat: 48.499285, lng: 18.486006 },
    { lat: 48.499229, lng: 18.484609 },
    { lat: 48.499161, lng: 18.483314 },
    { lat: 48.499034, lng: 18.4828 },
    { lat: 48.498938, lng: 18.482473 },
    { lat: 48.499103, lng: 18.482127 },
    { lat: 48.499145, lng: 18.482009 },
    { lat: 48.499227, lng: 18.481935 },
    { lat: 48.499304, lng: 18.481788 },
    { lat: 48.499524, lng: 18.481481 },
    { lat: 48.499688, lng: 18.481346 },
    { lat: 48.499796, lng: 18.4811 },
    { lat: 48.499904, lng: 18.48117 },
    { lat: 48.500094, lng: 18.481294 },
    { lat: 48.500287, lng: 18.481401 },
    { lat: 48.500941, lng: 18.481538 },
    { lat: 48.501083, lng: 18.48168 },
    { lat: 48.501302, lng: 18.481638 },
    { lat: 48.5016, lng: 18.481693 },
    { lat: 48.50172, lng: 18.481855 },
    { lat: 48.501817, lng: 18.481817 },
    { lat: 48.502277, lng: 18.48184 },
    { lat: 48.502371, lng: 18.481845 },
    { lat: 48.50246, lng: 18.481842 },
    { lat: 48.50256, lng: 18.48184 },
    { lat: 48.502916, lng: 18.48182 },
    { lat: 48.502989, lng: 18.481822 },
    { lat: 48.503231, lng: 18.48184 },
    { lat: 48.503415, lng: 18.481892 },
    { lat: 48.503737, lng: 18.481982 },
    { lat: 48.503827, lng: 18.482049 },
    { lat: 48.503937, lng: 18.482134 },
    { lat: 48.504041, lng: 18.482214 },
    { lat: 48.504227, lng: 18.482385 },
    { lat: 48.504446, lng: 18.482817 },
    { lat: 48.50459, lng: 18.483036 },
    { lat: 48.504687, lng: 18.483099 },
    { lat: 48.504736, lng: 18.483139 },
    { lat: 48.504727, lng: 18.483168 },
    { lat: 48.504707, lng: 18.48326 },
    { lat: 48.504717, lng: 18.483345 },
    { lat: 48.504941, lng: 18.483519 },
    { lat: 48.505245, lng: 18.483772 },
    { lat: 48.505484, lng: 18.483916 },
    { lat: 48.505612, lng: 18.48408 },
    { lat: 48.506062, lng: 18.484366 },
    { lat: 48.506301, lng: 18.484662 },
    { lat: 48.506447, lng: 18.484716 },
    { lat: 48.50657, lng: 18.48497 },
    { lat: 48.506939, lng: 18.485293 },
    { lat: 48.507207, lng: 18.485603 },
    { lat: 48.507461, lng: 18.485994 },
    { lat: 48.507597, lng: 18.486148 },
    { lat: 48.50779, lng: 18.486516 },
    { lat: 48.508045, lng: 18.486589 },
    { lat: 48.508153, lng: 18.486568 },
    { lat: 48.508206, lng: 18.486557 },
    { lat: 48.508424, lng: 18.486564 },
    { lat: 48.508535, lng: 18.486621 },
    { lat: 48.50855, lng: 18.486628 },
    { lat: 48.508578, lng: 18.486638 },
    { lat: 48.508648, lng: 18.486668 },
    { lat: 48.508698, lng: 18.48669 },
    { lat: 48.508877, lng: 18.486743 },
    { lat: 48.508901, lng: 18.486696 },
    { lat: 48.508918, lng: 18.486664 },
  ],
  Nevidzany: [
    { lat: 48.301292, lng: 18.362541 },
    { lat: 48.3010902, lng: 18.3624639 },
    { lat: 48.3005864, lng: 18.3621115 },
    { lat: 48.3001082, lng: 18.3619597 },
    { lat: 48.2999148, lng: 18.3618291 },
    { lat: 48.2993975, lng: 18.3613027 },
    { lat: 48.2985993, lng: 18.3610519 },
    { lat: 48.2977282, lng: 18.3609532 },
    { lat: 48.2971823, lng: 18.3608152 },
    { lat: 48.2961041, lng: 18.3604243 },
    { lat: 48.2943738, lng: 18.3600925 },
    { lat: 48.2931251, lng: 18.3597761 },
    { lat: 48.2920785, lng: 18.3592883 },
    { lat: 48.291722, lng: 18.358863 },
    { lat: 48.291446, lng: 18.358386 },
    { lat: 48.291063, lng: 18.357744 },
    { lat: 48.290835, lng: 18.357357 },
    { lat: 48.290539, lng: 18.356854 },
    { lat: 48.290037, lng: 18.355992 },
    { lat: 48.289795, lng: 18.355703 },
    { lat: 48.289741, lng: 18.355631 },
    { lat: 48.289594, lng: 18.355428 },
    { lat: 48.28945, lng: 18.355207 },
    { lat: 48.289361, lng: 18.355077 },
    { lat: 48.289264, lng: 18.354934 },
    { lat: 48.28924, lng: 18.35489 },
    { lat: 48.289217, lng: 18.354861 },
    { lat: 48.289164, lng: 18.354777 },
    { lat: 48.288987, lng: 18.354492 },
    { lat: 48.288821, lng: 18.354212 },
    { lat: 48.288755, lng: 18.354101 },
    { lat: 48.288658, lng: 18.353942 },
    { lat: 48.288559, lng: 18.353777 },
    { lat: 48.288516, lng: 18.353706 },
    { lat: 48.288468, lng: 18.353565 },
    { lat: 48.288402, lng: 18.353343 },
    { lat: 48.288391, lng: 18.353297 },
    { lat: 48.288346, lng: 18.353161 },
    { lat: 48.28832, lng: 18.353087 },
    { lat: 48.288281, lng: 18.352996 },
    { lat: 48.288157, lng: 18.352727 },
    { lat: 48.288091, lng: 18.352602 },
    { lat: 48.288039, lng: 18.352499 },
    { lat: 48.288016, lng: 18.352459 },
    { lat: 48.287855, lng: 18.352152 },
    { lat: 48.287774, lng: 18.35201 },
    { lat: 48.287711, lng: 18.351939 },
    { lat: 48.287553, lng: 18.351772 },
    { lat: 48.287447, lng: 18.351655 },
    { lat: 48.28737, lng: 18.351579 },
    { lat: 48.287292, lng: 18.351498 },
    { lat: 48.28725, lng: 18.351452 },
    { lat: 48.287214, lng: 18.35141 },
    { lat: 48.287066, lng: 18.351246 },
    { lat: 48.287016, lng: 18.351202 },
    { lat: 48.286807, lng: 18.351022 },
    { lat: 48.286703, lng: 18.350936 },
    { lat: 48.286566, lng: 18.350822 },
    { lat: 48.286534, lng: 18.350804 },
    { lat: 48.286458, lng: 18.350754 },
    { lat: 48.286325, lng: 18.350671 },
    { lat: 48.286251, lng: 18.350625 },
    { lat: 48.286187, lng: 18.350579 },
    { lat: 48.286052, lng: 18.350491 },
    { lat: 48.28561, lng: 18.350286 },
    { lat: 48.285537, lng: 18.350255 },
    { lat: 48.285458, lng: 18.350227 },
    { lat: 48.285392, lng: 18.350203 },
    { lat: 48.285278, lng: 18.35015 },
    { lat: 48.285175, lng: 18.350107 },
    { lat: 48.284952, lng: 18.350057 },
    { lat: 48.284919, lng: 18.350043 },
    { lat: 48.284815, lng: 18.350285 },
    { lat: 48.284784, lng: 18.350258 },
    { lat: 48.284722, lng: 18.350217 },
    { lat: 48.284654, lng: 18.350177 },
    { lat: 48.284554, lng: 18.35011 },
    { lat: 48.284444, lng: 18.350039 },
    { lat: 48.284228, lng: 18.349891 },
    { lat: 48.284179, lng: 18.349855 },
    { lat: 48.284126, lng: 18.349813 },
    { lat: 48.284077, lng: 18.349777 },
    { lat: 48.283922, lng: 18.349667 },
    { lat: 48.283773, lng: 18.34955 },
    { lat: 48.28367, lng: 18.349489 },
    { lat: 48.283562, lng: 18.349427 },
    { lat: 48.283455, lng: 18.349379 },
    { lat: 48.283272, lng: 18.349297 },
    { lat: 48.283211, lng: 18.34925 },
    { lat: 48.283141, lng: 18.349194 },
    { lat: 48.283036, lng: 18.349112 },
    { lat: 48.282928, lng: 18.349029 },
    { lat: 48.282774, lng: 18.348892 },
    { lat: 48.282699, lng: 18.348829 },
    { lat: 48.282621, lng: 18.348763 },
    { lat: 48.282567, lng: 18.348724 },
    { lat: 48.282513, lng: 18.348693 },
    { lat: 48.282463, lng: 18.348656 },
    { lat: 48.282338, lng: 18.348605 },
    { lat: 48.282315, lng: 18.348594 },
    { lat: 48.282118, lng: 18.348579 },
    { lat: 48.281929, lng: 18.34864 },
    { lat: 48.281827, lng: 18.348708 },
    { lat: 48.281497, lng: 18.348938 },
    { lat: 48.281135, lng: 18.349188 },
    { lat: 48.280914, lng: 18.349386 },
    { lat: 48.280709, lng: 18.349511 },
    { lat: 48.280402, lng: 18.3497 },
    { lat: 48.280224, lng: 18.349809 },
    { lat: 48.279895, lng: 18.349952 },
    { lat: 48.27982, lng: 18.350128 },
    { lat: 48.279727, lng: 18.350469 },
    { lat: 48.279683, lng: 18.350642 },
    { lat: 48.279646, lng: 18.350648 },
    { lat: 48.279587, lng: 18.350656 },
    { lat: 48.27946, lng: 18.35067 },
    { lat: 48.279319, lng: 18.35068 },
    { lat: 48.279119, lng: 18.350679 },
    { lat: 48.278687, lng: 18.350683 },
    { lat: 48.278483, lng: 18.351042 },
    { lat: 48.278298, lng: 18.351361 },
    { lat: 48.278108, lng: 18.351745 },
    { lat: 48.278057, lng: 18.351775 },
    { lat: 48.278033, lng: 18.351788 },
    { lat: 48.277987, lng: 18.351808 },
    { lat: 48.277922, lng: 18.351847 },
    { lat: 48.27791, lng: 18.351852 },
    { lat: 48.277755, lng: 18.352242 },
    { lat: 48.277692, lng: 18.352379 },
    { lat: 48.277667, lng: 18.352442 },
    { lat: 48.277627, lng: 18.352527 },
    { lat: 48.277586, lng: 18.352616 },
    { lat: 48.277567, lng: 18.352658 },
    { lat: 48.277544, lng: 18.352708 },
    { lat: 48.277524, lng: 18.352751 },
    { lat: 48.277502, lng: 18.352798 },
    { lat: 48.277477, lng: 18.352854 },
    { lat: 48.27735, lng: 18.353143 },
    { lat: 48.27732, lng: 18.353215 },
    { lat: 48.27729, lng: 18.353286 },
    { lat: 48.27726, lng: 18.353358 },
    { lat: 48.277231, lng: 18.353429 },
    { lat: 48.277193, lng: 18.353514 },
    { lat: 48.276981, lng: 18.354007 },
    { lat: 48.276936, lng: 18.354102 },
    { lat: 48.276891, lng: 18.354197 },
    { lat: 48.2768, lng: 18.354388 },
    { lat: 48.276669, lng: 18.35471 },
    { lat: 48.27657, lng: 18.354953 },
    { lat: 48.27647, lng: 18.355196 },
    { lat: 48.27639, lng: 18.355403 },
    { lat: 48.276312, lng: 18.35561 },
    { lat: 48.276273, lng: 18.355709 },
    { lat: 48.276231, lng: 18.355813 },
    { lat: 48.276147, lng: 18.356014 },
    { lat: 48.276146, lng: 18.356017 },
    { lat: 48.276098, lng: 18.356121 },
    { lat: 48.275989, lng: 18.356432 },
    { lat: 48.275893, lng: 18.356696 },
    { lat: 48.275872, lng: 18.356751 },
    { lat: 48.275833, lng: 18.356858 },
    { lat: 48.275783, lng: 18.356994 },
    { lat: 48.275726, lng: 18.357149 },
    { lat: 48.275684, lng: 18.357264 },
    { lat: 48.275628, lng: 18.357421 },
    { lat: 48.275582, lng: 18.357547 },
    { lat: 48.275583, lng: 18.357599 },
    { lat: 48.275583, lng: 18.357628 },
    { lat: 48.275509, lng: 18.357949 },
    { lat: 48.275213, lng: 18.358938 },
    { lat: 48.275063, lng: 18.3595 },
    { lat: 48.275033, lng: 18.35961 },
    { lat: 48.274923, lng: 18.360025 },
    { lat: 48.274796, lng: 18.360585 },
    { lat: 48.274796, lng: 18.360589 },
    { lat: 48.27472, lng: 18.360924 },
    { lat: 48.274664, lng: 18.361214 },
    { lat: 48.274624, lng: 18.361478 },
    { lat: 48.274619, lng: 18.361521 },
    { lat: 48.274609, lng: 18.361531 },
    { lat: 48.274521, lng: 18.361624 },
    { lat: 48.274476, lng: 18.36169 },
    { lat: 48.274407, lng: 18.36198 },
    { lat: 48.274387, lng: 18.362062 },
    { lat: 48.274189, lng: 18.362874 },
    { lat: 48.274175, lng: 18.362933 },
    { lat: 48.274152, lng: 18.363035 },
    { lat: 48.274125, lng: 18.363152 },
    { lat: 48.274109, lng: 18.363206 },
    { lat: 48.274095, lng: 18.363256 },
    { lat: 48.273948, lng: 18.36378 },
    { lat: 48.273935, lng: 18.363824 },
    { lat: 48.273775, lng: 18.36439 },
    { lat: 48.273599, lng: 18.365012 },
    { lat: 48.273074, lng: 18.366715 },
    { lat: 48.273037, lng: 18.366831 },
    { lat: 48.272967, lng: 18.367058 },
    { lat: 48.273045, lng: 18.367143 },
    { lat: 48.273032, lng: 18.367182 },
    { lat: 48.272381, lng: 18.369133 },
    { lat: 48.271768, lng: 18.371005 },
    { lat: 48.271631, lng: 18.371437 },
    { lat: 48.271569, lng: 18.371648 },
    { lat: 48.271501, lng: 18.371869 },
    { lat: 48.271487, lng: 18.371918 },
    { lat: 48.271405, lng: 18.372192 },
    { lat: 48.271323, lng: 18.372468 },
    { lat: 48.271261, lng: 18.372676 },
    { lat: 48.271253, lng: 18.372754 },
    { lat: 48.271224, lng: 18.373062 },
    { lat: 48.271194, lng: 18.373368 },
    { lat: 48.271165, lng: 18.373673 },
    { lat: 48.271135, lng: 18.373976 },
    { lat: 48.271105, lng: 18.374279 },
    { lat: 48.271074, lng: 18.37458 },
    { lat: 48.271044, lng: 18.374881 },
    { lat: 48.271015, lng: 18.37518 },
    { lat: 48.270984, lng: 18.375479 },
    { lat: 48.270955, lng: 18.375777 },
    { lat: 48.270925, lng: 18.376074 },
    { lat: 48.270896, lng: 18.376369 },
    { lat: 48.270866, lng: 18.376664 },
    { lat: 48.270836, lng: 18.376958 },
    { lat: 48.270807, lng: 18.377251 },
    { lat: 48.270778, lng: 18.377543 },
    { lat: 48.270749, lng: 18.377834 },
    { lat: 48.270691, lng: 18.378418 },
    { lat: 48.270661, lng: 18.378707 },
    { lat: 48.270632, lng: 18.378995 },
    { lat: 48.270604, lng: 18.379282 },
    { lat: 48.270575, lng: 18.379568 },
    { lat: 48.270574, lng: 18.379585 },
    { lat: 48.270528, lng: 18.379844 },
    { lat: 48.270479, lng: 18.380119 },
    { lat: 48.270429, lng: 18.380395 },
    { lat: 48.27038, lng: 18.380671 },
    { lat: 48.270332, lng: 18.380948 },
    { lat: 48.270282, lng: 18.381224 },
    { lat: 48.270232, lng: 18.381501 },
    { lat: 48.270224, lng: 18.381553 },
    { lat: 48.269995, lng: 18.382738 },
    { lat: 48.269935, lng: 18.383079 },
    { lat: 48.269608, lng: 18.383816 },
    { lat: 48.26951, lng: 18.384038 },
    { lat: 48.269357, lng: 18.38445 },
    { lat: 48.269328, lng: 18.384529 },
    { lat: 48.269191, lng: 18.384896 },
    { lat: 48.269038, lng: 18.385034 },
    { lat: 48.268792, lng: 18.385255 },
    { lat: 48.268779, lng: 18.385391 },
    { lat: 48.268686, lng: 18.386405 },
    { lat: 48.268552, lng: 18.387307 },
    { lat: 48.268485, lng: 18.387908 },
    { lat: 48.268419, lng: 18.388391 },
    { lat: 48.268287, lng: 18.388825 },
    { lat: 48.268094, lng: 18.389374 },
    { lat: 48.267948, lng: 18.389812 },
    { lat: 48.267853, lng: 18.39016 },
    { lat: 48.267707, lng: 18.390967 },
    { lat: 48.267522, lng: 18.391886 },
    { lat: 48.267574, lng: 18.392727 },
    { lat: 48.267389, lng: 18.393691 },
    { lat: 48.267256, lng: 18.394459 },
    { lat: 48.267136, lng: 18.395647 },
    { lat: 48.266929, lng: 18.39754 },
    { lat: 48.266887, lng: 18.397909 },
    { lat: 48.26688, lng: 18.397968 },
    { lat: 48.266751, lng: 18.399099 },
    { lat: 48.266731, lng: 18.39931 },
    { lat: 48.266351, lng: 18.399711 },
    { lat: 48.265954, lng: 18.40013 },
    { lat: 48.265655, lng: 18.400577 },
    { lat: 48.265547, lng: 18.400752 },
    { lat: 48.265529, lng: 18.400783 },
    { lat: 48.265362, lng: 18.401099 },
    { lat: 48.263953, lng: 18.401574 },
    { lat: 48.264059, lng: 18.401572 },
    { lat: 48.264584, lng: 18.40207 },
    { lat: 48.264754, lng: 18.402282 },
    { lat: 48.264863, lng: 18.402439 },
    { lat: 48.265063, lng: 18.402781 },
    { lat: 48.265109, lng: 18.40286 },
    { lat: 48.265158, lng: 18.402955 },
    { lat: 48.265191, lng: 18.40302 },
    { lat: 48.265459, lng: 18.403569 },
    { lat: 48.265726, lng: 18.404117 },
    { lat: 48.266093, lng: 18.405082 },
    { lat: 48.266081, lng: 18.405088 },
    { lat: 48.26607, lng: 18.405117 },
    { lat: 48.266257, lng: 18.405646 },
    { lat: 48.266284, lng: 18.405625 },
    { lat: 48.266791, lng: 18.405515 },
    { lat: 48.267473, lng: 18.405398 },
    { lat: 48.268673, lng: 18.405302 },
    { lat: 48.269486, lng: 18.405582 },
    { lat: 48.270312, lng: 18.405839 },
    { lat: 48.270369, lng: 18.405856 },
    { lat: 48.270392, lng: 18.405863 },
    { lat: 48.271069, lng: 18.405527 },
    { lat: 48.27168, lng: 18.405324 },
    { lat: 48.272362, lng: 18.404838 },
    { lat: 48.273096, lng: 18.4039 },
    { lat: 48.273202, lng: 18.403658 },
    { lat: 48.273324, lng: 18.403511 },
    { lat: 48.273367, lng: 18.40346 },
    { lat: 48.27379, lng: 18.402949 },
    { lat: 48.274048, lng: 18.402948 },
    { lat: 48.274196, lng: 18.403264 },
    { lat: 48.274756, lng: 18.403197 },
    { lat: 48.275536, lng: 18.402833 },
    { lat: 48.27561, lng: 18.402529 },
    { lat: 48.275862, lng: 18.401967 },
    { lat: 48.276251, lng: 18.401353 },
    { lat: 48.276857, lng: 18.400486 },
    { lat: 48.278766, lng: 18.399272 },
    { lat: 48.279438, lng: 18.399136 },
    { lat: 48.279919, lng: 18.39874 },
    { lat: 48.28114, lng: 18.3973 },
    { lat: 48.281641, lng: 18.397087 },
    { lat: 48.282383, lng: 18.396738 },
    { lat: 48.282394, lng: 18.396728 },
    { lat: 48.282477, lng: 18.396908 },
    { lat: 48.282528, lng: 18.397032 },
    { lat: 48.282586, lng: 18.397303 },
    { lat: 48.282591, lng: 18.397315 },
    { lat: 48.282644, lng: 18.397403 },
    { lat: 48.282689, lng: 18.39761 },
    { lat: 48.2827, lng: 18.397714 },
    { lat: 48.282671, lng: 18.397789 },
    { lat: 48.282757, lng: 18.398046 },
    { lat: 48.282817, lng: 18.398136 },
    { lat: 48.282911, lng: 18.398249 },
    { lat: 48.282936, lng: 18.398284 },
    { lat: 48.283004, lng: 18.398284 },
    { lat: 48.283063, lng: 18.39837 },
    { lat: 48.283089, lng: 18.398393 },
    { lat: 48.283155, lng: 18.398433 },
    { lat: 48.283186, lng: 18.398588 },
    { lat: 48.283241, lng: 18.398658 },
    { lat: 48.283247, lng: 18.398891 },
    { lat: 48.283287, lng: 18.399156 },
    { lat: 48.283359, lng: 18.399256 },
    { lat: 48.283374, lng: 18.399457 },
    { lat: 48.283519, lng: 18.3996 },
    { lat: 48.283481, lng: 18.399668 },
    { lat: 48.283468, lng: 18.399817 },
    { lat: 48.283605, lng: 18.400213 },
    { lat: 48.283763, lng: 18.400455 },
    { lat: 48.283944, lng: 18.400867 },
    { lat: 48.28408, lng: 18.401212 },
    { lat: 48.28421, lng: 18.40156 },
    { lat: 48.284635, lng: 18.403403 },
    { lat: 48.284648, lng: 18.403658 },
    { lat: 48.284717, lng: 18.404261 },
    { lat: 48.284759, lng: 18.404659 },
    { lat: 48.284819, lng: 18.405071 },
    { lat: 48.284847, lng: 18.4053 },
    { lat: 48.284886, lng: 18.405658 },
    { lat: 48.2854545, lng: 18.4050143 },
    { lat: 48.2865023, lng: 18.404404 },
    { lat: 48.2870538, lng: 18.4042812 },
    { lat: 48.2873607, lng: 18.4042818 },
    { lat: 48.2884301, lng: 18.4045029 },
    { lat: 48.2892453, lng: 18.4044309 },
    { lat: 48.2905865, lng: 18.4040448 },
    { lat: 48.2913595, lng: 18.4037503 },
    { lat: 48.2921466, lng: 18.4034019 },
    { lat: 48.2923018, lng: 18.4032523 },
    { lat: 48.2920074, lng: 18.4015486 },
    { lat: 48.2919504, lng: 18.4006528 },
    { lat: 48.2919976, lng: 18.3999868 },
    { lat: 48.2921458, lng: 18.3995153 },
    { lat: 48.2922129, lng: 18.398082 },
    { lat: 48.2920767, lng: 18.3968628 },
    { lat: 48.2917981, lng: 18.3953813 },
    { lat: 48.2911533, lng: 18.3938257 },
    { lat: 48.2908951, lng: 18.3930652 },
    { lat: 48.2932676, lng: 18.3886963 },
    { lat: 48.2943716, lng: 18.3856026 },
    { lat: 48.2944655, lng: 18.3854685 },
    { lat: 48.2952824, lng: 18.383089 },
    { lat: 48.2953534, lng: 18.3830269 },
    { lat: 48.2963261, lng: 18.38003 },
    { lat: 48.301001, lng: 18.367969 },
    { lat: 48.3004248, lng: 18.3657818 },
    { lat: 48.300766, lng: 18.364331 },
    { lat: 48.3011954, lng: 18.3628896 },
    { lat: 48.301292, lng: 18.362541 },
  ],
  KostoľanypodTribečom: [
    { lat: 48.453709, lng: 18.222694 },
    { lat: 48.453428, lng: 18.222486 },
    { lat: 48.453167, lng: 18.222194 },
    { lat: 48.452863, lng: 18.221154 },
    { lat: 48.452876, lng: 18.22084 },
    { lat: 48.452859, lng: 18.220529 },
    { lat: 48.452651, lng: 18.21941 },
    { lat: 48.452573, lng: 18.219162 },
    { lat: 48.452392, lng: 18.218589 },
    { lat: 48.452289, lng: 18.218176 },
    { lat: 48.452262, lng: 18.217759 },
    { lat: 48.452182, lng: 18.217486 },
    { lat: 48.452156, lng: 18.217423 },
    { lat: 48.4521226, lng: 18.2173475 },
    { lat: 48.452118, lng: 18.217337 },
    { lat: 48.452026, lng: 18.216797 },
    { lat: 48.451953, lng: 18.216565 },
    { lat: 48.451759, lng: 18.216262 },
    { lat: 48.451685, lng: 18.216012 },
    { lat: 48.451329, lng: 18.215357 },
    { lat: 48.451248, lng: 18.21505 },
    { lat: 48.451122, lng: 18.214778 },
    { lat: 48.450762, lng: 18.214157 },
    { lat: 48.450579, lng: 18.213957 },
    { lat: 48.450381, lng: 18.213777 },
    { lat: 48.450287, lng: 18.213602 },
    { lat: 48.450275, lng: 18.21357 },
    { lat: 48.450014, lng: 18.212817 },
    { lat: 48.449826, lng: 18.212389 },
    { lat: 48.449298, lng: 18.211417 },
    { lat: 48.44907, lng: 18.210968 },
    { lat: 48.448966, lng: 18.2108 },
    { lat: 48.448835, lng: 18.210654 },
    { lat: 48.447941, lng: 18.209945 },
    { lat: 48.447701, lng: 18.209684 },
    { lat: 48.446886, lng: 18.208931 },
    { lat: 48.446509, lng: 18.208271 },
    { lat: 48.446281, lng: 18.207793 },
    { lat: 48.446048, lng: 18.207495 },
    { lat: 48.445884, lng: 18.207201 },
    { lat: 48.445661, lng: 18.206828 },
    { lat: 48.445581, lng: 18.206654 },
    { lat: 48.445536, lng: 18.206331 },
    { lat: 48.445469, lng: 18.206177 },
    { lat: 48.445342, lng: 18.205834 },
    { lat: 48.444988, lng: 18.205042 },
    { lat: 48.44475, lng: 18.204333 },
    { lat: 48.444733, lng: 18.203682 },
    { lat: 48.444668, lng: 18.203026 },
    { lat: 48.444558, lng: 18.202273 },
    { lat: 48.444521, lng: 18.202171 },
    { lat: 48.444394, lng: 18.201822 },
    { lat: 48.444251, lng: 18.201465 },
    { lat: 48.444193, lng: 18.201317 },
    { lat: 48.443768, lng: 18.200772 },
    { lat: 48.442939, lng: 18.199241 },
    { lat: 48.442406, lng: 18.19876 },
    { lat: 48.442269, lng: 18.198638 },
    { lat: 48.44226, lng: 18.198629 },
    { lat: 48.442228, lng: 18.198603 },
    { lat: 48.441294, lng: 18.197792 },
    { lat: 48.439922, lng: 18.196603 },
    { lat: 48.439584, lng: 18.195138 },
    { lat: 48.439575, lng: 18.195089 },
    { lat: 48.439565, lng: 18.195058 },
    { lat: 48.439368, lng: 18.1945 },
    { lat: 48.439172, lng: 18.193944 },
    { lat: 48.439144, lng: 18.193851 },
    { lat: 48.438964, lng: 18.193324 },
    { lat: 48.438801, lng: 18.193083 },
    { lat: 48.438521, lng: 18.192677 },
    { lat: 48.438493, lng: 18.192637 },
    { lat: 48.438313, lng: 18.192362 },
    { lat: 48.43811, lng: 18.1921 },
    { lat: 48.437989, lng: 18.191958 },
    { lat: 48.437405, lng: 18.191527 },
    { lat: 48.437342, lng: 18.191197 },
    { lat: 48.437129, lng: 18.190935 },
    { lat: 48.43703, lng: 18.19018 },
    { lat: 48.437026, lng: 18.190127 },
    { lat: 48.436508, lng: 18.190463 },
    { lat: 48.435361, lng: 18.192024 },
    { lat: 48.434729, lng: 18.192985 },
    { lat: 48.434357, lng: 18.193551 },
    { lat: 48.434252, lng: 18.19369 },
    { lat: 48.434041, lng: 18.193884 },
    { lat: 48.433943, lng: 18.193954 },
    { lat: 48.433621, lng: 18.19417 },
    { lat: 48.432965, lng: 18.19448 },
    { lat: 48.432826, lng: 18.194517 },
    { lat: 48.432584, lng: 18.194461 },
    { lat: 48.431666, lng: 18.1943 },
    { lat: 48.431534, lng: 18.194262 },
    { lat: 48.431532, lng: 18.194237 },
    { lat: 48.431498, lng: 18.194244 },
    { lat: 48.431043, lng: 18.195557 },
    { lat: 48.430891, lng: 18.195999 },
    { lat: 48.429916, lng: 18.197496 },
    { lat: 48.429794, lng: 18.197689 },
    { lat: 48.429623, lng: 18.197948 },
    { lat: 48.429524, lng: 18.198099 },
    { lat: 48.429377, lng: 18.198324 },
    { lat: 48.429332, lng: 18.198392 },
    { lat: 48.429046, lng: 18.198831 },
    { lat: 48.429022, lng: 18.19887 },
    { lat: 48.428443, lng: 18.199722 },
    { lat: 48.427696, lng: 18.200822 },
    { lat: 48.427569, lng: 18.201012 },
    { lat: 48.427197, lng: 18.201535 },
    { lat: 48.427145, lng: 18.201608 },
    { lat: 48.426888, lng: 18.201969 },
    { lat: 48.426735, lng: 18.20219 },
    { lat: 48.425368, lng: 18.204791 },
    { lat: 48.423864, lng: 18.206359 },
    { lat: 48.423858, lng: 18.206366 },
    { lat: 48.423819, lng: 18.206399 },
    { lat: 48.423811, lng: 18.206409 },
    { lat: 48.421431, lng: 18.209324 },
    { lat: 48.421375, lng: 18.209391 },
    { lat: 48.418963, lng: 18.212346 },
    { lat: 48.418912, lng: 18.212407 },
    { lat: 48.416376, lng: 18.215514 },
    { lat: 48.416363, lng: 18.215528 },
    { lat: 48.416359, lng: 18.215541 },
    { lat: 48.416338, lng: 18.215602 },
    { lat: 48.416319, lng: 18.21566 },
    { lat: 48.412606, lng: 18.227294 },
    { lat: 48.411746, lng: 18.227048 },
    { lat: 48.406195, lng: 18.225418 },
    { lat: 48.406173, lng: 18.225411 },
    { lat: 48.406168, lng: 18.225436 },
    { lat: 48.405708, lng: 18.227728 },
    { lat: 48.404875, lng: 18.231806 },
    { lat: 48.404839, lng: 18.231981 },
    { lat: 48.404675, lng: 18.232391 },
    { lat: 48.404577, lng: 18.232636 },
    { lat: 48.404541, lng: 18.232725 },
    { lat: 48.402203, lng: 18.238555 },
    { lat: 48.4022, lng: 18.238562 },
    { lat: 48.402186, lng: 18.238597 },
    { lat: 48.401528, lng: 18.242128 },
    { lat: 48.401519, lng: 18.242178 },
    { lat: 48.401077, lng: 18.24453 },
    { lat: 48.4013624, lng: 18.2460365 },
    { lat: 48.4015348, lng: 18.2478589 },
    { lat: 48.4028032, lng: 18.251219 },
    { lat: 48.4036609, lng: 18.2541696 },
    { lat: 48.40444, lng: 18.2559874 },
    { lat: 48.40512, lng: 18.2578299 },
    { lat: 48.4076302, lng: 18.2640044 },
    { lat: 48.4099474, lng: 18.2700033 },
    { lat: 48.4108047, lng: 18.2725019 },
    { lat: 48.4110194, lng: 18.2729434 },
    { lat: 48.4113055, lng: 18.2735258 },
    { lat: 48.4115209, lng: 18.2731869 },
    { lat: 48.4116983, lng: 18.2729576 },
    { lat: 48.4121771, lng: 18.2731185 },
    { lat: 48.4123684, lng: 18.2732877 },
    { lat: 48.4131807, lng: 18.2736613 },
    { lat: 48.4133959, lng: 18.2738533 },
    { lat: 48.4146498, lng: 18.2741131 },
    { lat: 48.4178357, lng: 18.2693929 },
    { lat: 48.4198112, lng: 18.2653517 },
    { lat: 48.4216585, lng: 18.262399 },
    { lat: 48.4286153, lng: 18.2526074 },
    { lat: 48.4314642, lng: 18.2535682 },
    { lat: 48.4317624, lng: 18.2544989 },
    { lat: 48.4321312, lng: 18.2559125 },
    { lat: 48.4322246, lng: 18.2573056 },
    { lat: 48.4398469, lng: 18.2550866 },
    { lat: 48.4464928, lng: 18.2524461 },
    { lat: 48.4577782, lng: 18.2428837 },
    { lat: 48.46515, lng: 18.238739 },
    { lat: 48.465136, lng: 18.238695 },
    { lat: 48.464695, lng: 18.237319 },
    { lat: 48.464555, lng: 18.236322 },
    { lat: 48.463878, lng: 18.233432 },
    { lat: 48.462446, lng: 18.229339 },
    { lat: 48.461683, lng: 18.228414 },
    { lat: 48.461396, lng: 18.227758 },
    { lat: 48.461376, lng: 18.22771 },
    { lat: 48.460994, lng: 18.226853 },
    { lat: 48.459884, lng: 18.224303 },
    { lat: 48.45987, lng: 18.224275 },
    { lat: 48.459624, lng: 18.224109 },
    { lat: 48.459327, lng: 18.224001 },
    { lat: 48.458755, lng: 18.223898 },
    { lat: 48.458613, lng: 18.223897 },
    { lat: 48.458449, lng: 18.223857 },
    { lat: 48.458195, lng: 18.223862 },
    { lat: 48.457999, lng: 18.223897 },
    { lat: 48.457852, lng: 18.223861 },
    { lat: 48.457798, lng: 18.223844 },
    { lat: 48.457676, lng: 18.223791 },
    { lat: 48.457468, lng: 18.223662 },
    { lat: 48.457161, lng: 18.2236 },
    { lat: 48.457033, lng: 18.223548 },
    { lat: 48.456796, lng: 18.223492 },
    { lat: 48.45677, lng: 18.223485 },
    { lat: 48.456543, lng: 18.223507 },
    { lat: 48.456163, lng: 18.223608 },
    { lat: 48.456048, lng: 18.223589 },
    { lat: 48.455815, lng: 18.223597 },
    { lat: 48.455543, lng: 18.223671 },
    { lat: 48.45526, lng: 18.223705 },
    { lat: 48.454796, lng: 18.223492 },
    { lat: 48.454424, lng: 18.223299 },
    { lat: 48.454235, lng: 18.223147 },
    { lat: 48.453979, lng: 18.222884 },
    { lat: 48.453738, lng: 18.22273 },
    { lat: 48.453725, lng: 18.222715 },
    { lat: 48.453709, lng: 18.222694 },
  ],
  Neverice: [
    { lat: 48.373346, lng: 18.265312 },
    { lat: 48.373081, lng: 18.265108 },
    { lat: 48.37305, lng: 18.265083 },
    { lat: 48.372964, lng: 18.265018 },
    { lat: 48.372656, lng: 18.264733 },
    { lat: 48.372055, lng: 18.264346 },
    { lat: 48.371342, lng: 18.2639 },
    { lat: 48.371087, lng: 18.263771 },
    { lat: 48.370915, lng: 18.263693 },
    { lat: 48.370856, lng: 18.263665 },
    { lat: 48.370519, lng: 18.263548 },
    { lat: 48.370278, lng: 18.263492 },
    { lat: 48.369673, lng: 18.263322 },
    { lat: 48.369656, lng: 18.263316 },
    { lat: 48.369652, lng: 18.263316 },
    { lat: 48.36963, lng: 18.263306 },
    { lat: 48.369556, lng: 18.263271 },
    { lat: 48.36955, lng: 18.263268 },
    { lat: 48.369534, lng: 18.26326 },
    { lat: 48.369503, lng: 18.263246 },
    { lat: 48.368661, lng: 18.262854 },
    { lat: 48.368008, lng: 18.262493 },
    { lat: 48.367573, lng: 18.262281 },
    { lat: 48.367309, lng: 18.262156 },
    { lat: 48.367034, lng: 18.262025 },
    { lat: 48.366883, lng: 18.261956 },
    { lat: 48.366516, lng: 18.261755 },
    { lat: 48.366514, lng: 18.261753 },
    { lat: 48.36648, lng: 18.261732 },
    { lat: 48.36642, lng: 18.26171 },
    { lat: 48.366412, lng: 18.261707 },
    { lat: 48.366147, lng: 18.261609 },
    { lat: 48.366047, lng: 18.261564 },
    { lat: 48.366017, lng: 18.261507 },
    { lat: 48.365816, lng: 18.261134 },
    { lat: 48.365751, lng: 18.260982 },
    { lat: 48.365686, lng: 18.260832 },
    { lat: 48.365521, lng: 18.260608 },
    { lat: 48.365488, lng: 18.260565 },
    { lat: 48.365486, lng: 18.260562 },
    { lat: 48.365466, lng: 18.260534 },
    { lat: 48.365391, lng: 18.260427 },
    { lat: 48.365383, lng: 18.26042 },
    { lat: 48.365337, lng: 18.260352 },
    { lat: 48.36532, lng: 18.260333 },
    { lat: 48.365153, lng: 18.26014 },
    { lat: 48.365002, lng: 18.260037 },
    { lat: 48.364775, lng: 18.259906 },
    { lat: 48.364422, lng: 18.259911 },
    { lat: 48.364365, lng: 18.259912 },
    { lat: 48.364173, lng: 18.259874 },
    { lat: 48.363792, lng: 18.259737 },
    { lat: 48.363571, lng: 18.259658 },
    { lat: 48.36308, lng: 18.259524 },
    { lat: 48.362961, lng: 18.259487 },
    { lat: 48.362668, lng: 18.259381 },
    { lat: 48.362125, lng: 18.259148 },
    { lat: 48.361902, lng: 18.259198 },
    { lat: 48.361593, lng: 18.259284 },
    { lat: 48.361541, lng: 18.259298 },
    { lat: 48.36109, lng: 18.259423 },
    { lat: 48.360824, lng: 18.259571 },
    { lat: 48.360433, lng: 18.259891 },
    { lat: 48.36029, lng: 18.260045 },
    { lat: 48.360226, lng: 18.260113 },
    { lat: 48.360222, lng: 18.260117 },
    { lat: 48.359608, lng: 18.26076 },
    { lat: 48.359127, lng: 18.26126 },
    { lat: 48.358875, lng: 18.261524 },
    { lat: 48.358628, lng: 18.261782 },
    { lat: 48.358479, lng: 18.261939 },
    { lat: 48.358096, lng: 18.262312 },
    { lat: 48.357903, lng: 18.262496 },
    { lat: 48.357329, lng: 18.263057 },
    { lat: 48.35722, lng: 18.263163 },
    { lat: 48.356801, lng: 18.26352 },
    { lat: 48.356578, lng: 18.263765 },
    { lat: 48.356545, lng: 18.2638 },
    { lat: 48.356385, lng: 18.263972 },
    { lat: 48.356092, lng: 18.264291 },
    { lat: 48.355941, lng: 18.264456 },
    { lat: 48.355825, lng: 18.264584 },
    { lat: 48.355788, lng: 18.264624 },
    { lat: 48.355666, lng: 18.264784 },
    { lat: 48.355558, lng: 18.264926 },
    { lat: 48.355443, lng: 18.265076 },
    { lat: 48.355331, lng: 18.265224 },
    { lat: 48.355288, lng: 18.26528 },
    { lat: 48.355198, lng: 18.26538 },
    { lat: 48.355071, lng: 18.26552 },
    { lat: 48.354932, lng: 18.265671 },
    { lat: 48.354847, lng: 18.265764 },
    { lat: 48.354618, lng: 18.266011 },
    { lat: 48.354513, lng: 18.266131 },
    { lat: 48.354371, lng: 18.266294 },
    { lat: 48.354228, lng: 18.266461 },
    { lat: 48.354089, lng: 18.266571 },
    { lat: 48.35402, lng: 18.266626 },
    { lat: 48.354002, lng: 18.266641 },
    { lat: 48.353946, lng: 18.266671 },
    { lat: 48.353836, lng: 18.266732 },
    { lat: 48.353799, lng: 18.266753 },
    { lat: 48.353762, lng: 18.266787 },
    { lat: 48.35369, lng: 18.266855 },
    { lat: 48.353616, lng: 18.266935 },
    { lat: 48.353481, lng: 18.267081 },
    { lat: 48.353339, lng: 18.267233 },
    { lat: 48.353053, lng: 18.267536 },
    { lat: 48.352808, lng: 18.267818 },
    { lat: 48.352729, lng: 18.267898 },
    { lat: 48.3530038, lng: 18.2682229 },
    { lat: 48.3518672, lng: 18.2702231 },
    { lat: 48.3515587, lng: 18.2709263 },
    { lat: 48.3496293, lng: 18.2764403 },
    { lat: 48.3500377, lng: 18.2763419 },
    { lat: 48.3501417, lng: 18.2764615 },
    { lat: 48.3503084, lng: 18.2764354 },
    { lat: 48.3509601, lng: 18.2768413 },
    { lat: 48.3537962, lng: 18.2846017 },
    { lat: 48.3543495, lng: 18.2842548 },
    { lat: 48.3574603, lng: 18.2904755 },
    { lat: 48.3572241, lng: 18.2906825 },
    { lat: 48.3577927, lng: 18.2921431 },
    { lat: 48.3587232, lng: 18.2941518 },
    { lat: 48.3589446, lng: 18.2945725 },
    { lat: 48.3600854, lng: 18.2937439 },
    { lat: 48.3615582, lng: 18.2928216 },
    { lat: 48.3616596, lng: 18.2930942 },
    { lat: 48.3627307, lng: 18.29247 },
    { lat: 48.3635859, lng: 18.2920723 },
    { lat: 48.3716111, lng: 18.2888012 },
    { lat: 48.3719219, lng: 18.2896598 },
    { lat: 48.3727396, lng: 18.2910202 },
    { lat: 48.3731806, lng: 18.2919433 },
    { lat: 48.3740604, lng: 18.2944091 },
    { lat: 48.3744636, lng: 18.2958599 },
    { lat: 48.3749334, lng: 18.2966381 },
    { lat: 48.3754208, lng: 18.2977264 },
    { lat: 48.3755719, lng: 18.2980008 },
    { lat: 48.3759601, lng: 18.2978043 },
    { lat: 48.3761342, lng: 18.2979477 },
    { lat: 48.3771102, lng: 18.2998285 },
    { lat: 48.377361, lng: 18.3002297 },
    { lat: 48.3776623, lng: 18.3005877 },
    { lat: 48.378018, lng: 18.300909 },
    { lat: 48.3788938, lng: 18.2996665 },
    { lat: 48.3798074, lng: 18.2988502 },
    { lat: 48.3812002, lng: 18.2979663 },
    { lat: 48.381495, lng: 18.2978532 },
    { lat: 48.3823138, lng: 18.2977288 },
    { lat: 48.3823017, lng: 18.2976736 },
    { lat: 48.3823603, lng: 18.2976951 },
    { lat: 48.3817443, lng: 18.2957955 },
    { lat: 48.3794823, lng: 18.2896317 },
    { lat: 48.377079, lng: 18.2867123 },
    { lat: 48.3768336, lng: 18.2866414 },
    { lat: 48.3768376, lng: 18.2865922 },
    { lat: 48.3765013, lng: 18.2862178 },
    { lat: 48.3764859, lng: 18.2861261 },
    { lat: 48.3767368, lng: 18.2854234 },
    { lat: 48.3768838, lng: 18.2838091 },
    { lat: 48.3764121, lng: 18.2785221 },
    { lat: 48.3766024, lng: 18.2785551 },
    { lat: 48.3759259, lng: 18.274679 },
    { lat: 48.3757106, lng: 18.2745656 },
    { lat: 48.3756208, lng: 18.274355 },
    { lat: 48.3756272, lng: 18.2742115 },
    { lat: 48.3754995, lng: 18.2741683 },
    { lat: 48.3754701, lng: 18.2740678 },
    { lat: 48.3753633, lng: 18.2740737 },
    { lat: 48.3753053, lng: 18.2739492 },
    { lat: 48.3757216, lng: 18.2737908 },
    { lat: 48.3759426, lng: 18.2740047 },
    { lat: 48.3753279, lng: 18.2705995 },
    { lat: 48.3744151, lng: 18.2670764 },
    { lat: 48.3741727, lng: 18.2664494 },
    { lat: 48.373346, lng: 18.265312 },
  ],
  VieskanadŽitavou: [
    { lat: 48.3227105, lng: 18.385209 },
    { lat: 48.3229078, lng: 18.384788 },
    { lat: 48.3233203, lng: 18.3838379 },
    { lat: 48.3227206, lng: 18.3832059 },
    { lat: 48.3222738, lng: 18.3821888 },
    { lat: 48.3218432, lng: 18.3814528 },
    { lat: 48.3213611, lng: 18.3795653 },
    { lat: 48.3207879, lng: 18.3769634 },
    { lat: 48.3196334, lng: 18.3752364 },
    { lat: 48.3198889, lng: 18.3737053 },
    { lat: 48.3199998, lng: 18.372575 },
    { lat: 48.3201008, lng: 18.3722024 },
    { lat: 48.3212466, lng: 18.3698113 },
    { lat: 48.3216523, lng: 18.3680926 },
    { lat: 48.3220934, lng: 18.365736 },
    { lat: 48.3226097, lng: 18.3619758 },
    { lat: 48.3227992, lng: 18.3602388 },
    { lat: 48.3226796, lng: 18.3600959 },
    { lat: 48.3226068, lng: 18.3597077 },
    { lat: 48.3231435, lng: 18.3588054 },
    { lat: 48.3234384, lng: 18.3566 },
    { lat: 48.3237545, lng: 18.3566013 },
    { lat: 48.3240324, lng: 18.3557704 },
    { lat: 48.3243497, lng: 18.3556553 },
    { lat: 48.3248153, lng: 18.355272 },
    { lat: 48.3252649, lng: 18.3545639 },
    { lat: 48.3255299, lng: 18.3542802 },
    { lat: 48.3256507, lng: 18.3539624 },
    { lat: 48.3260453, lng: 18.3535619 },
    { lat: 48.3269799, lng: 18.3503206 },
    { lat: 48.3279313, lng: 18.3458655 },
    { lat: 48.3280814, lng: 18.3442506 },
    { lat: 48.3287219, lng: 18.3406653 },
    { lat: 48.3288378, lng: 18.3398035 },
    { lat: 48.3289364, lng: 18.3379621 },
    { lat: 48.328945, lng: 18.3360837 },
    { lat: 48.3287569, lng: 18.3334294 },
    { lat: 48.3287213, lng: 18.330996 },
    { lat: 48.3283581, lng: 18.3308953 },
    { lat: 48.3281957, lng: 18.3309164 },
    { lat: 48.3280094, lng: 18.330675 },
    { lat: 48.3278597, lng: 18.3272663 },
    { lat: 48.327896, lng: 18.327194 },
    { lat: 48.3276484, lng: 18.3243229 },
    { lat: 48.3275766, lng: 18.3243115 },
    { lat: 48.3267342, lng: 18.324242 },
    { lat: 48.3258464, lng: 18.3244211 },
    { lat: 48.3255232, lng: 18.3243323 },
    { lat: 48.325039, lng: 18.3240138 },
    { lat: 48.3234571, lng: 18.3251612 },
    { lat: 48.3237092, lng: 18.3266229 },
    { lat: 48.3238124, lng: 18.327245 },
    { lat: 48.3238952, lng: 18.3301706 },
    { lat: 48.3227613, lng: 18.3311436 },
    { lat: 48.3225182, lng: 18.3312923 },
    { lat: 48.3218707, lng: 18.3314948 },
    { lat: 48.3218431, lng: 18.3312502 },
    { lat: 48.3214588, lng: 18.3313773 },
    { lat: 48.3213437, lng: 18.3311158 },
    { lat: 48.3210692, lng: 18.3312317 },
    { lat: 48.3210047, lng: 18.3308879 },
    { lat: 48.3206449, lng: 18.3309505 },
    { lat: 48.3206111, lng: 18.3307127 },
    { lat: 48.3204765, lng: 18.3306668 },
    { lat: 48.3202954, lng: 18.3308367 },
    { lat: 48.3202374, lng: 18.3310277 },
    { lat: 48.3200986, lng: 18.3311851 },
    { lat: 48.3200195, lng: 18.3311041 },
    { lat: 48.3198904, lng: 18.3311745 },
    { lat: 48.3197736, lng: 18.3315613 },
    { lat: 48.3194158, lng: 18.3321337 },
    { lat: 48.3193905, lng: 18.3322458 },
    { lat: 48.3190772, lng: 18.3324506 },
    { lat: 48.318883, lng: 18.3323698 },
    { lat: 48.3186968, lng: 18.3324503 },
    { lat: 48.3188582, lng: 18.3359883 },
    { lat: 48.3178537, lng: 18.336197 },
    { lat: 48.3178874, lng: 18.337786 },
    { lat: 48.317839, lng: 18.3394373 },
    { lat: 48.317401, lng: 18.3424559 },
    { lat: 48.3173905, lng: 18.3425082 },
    { lat: 48.3172017, lng: 18.3423856 },
    { lat: 48.3171891, lng: 18.3424396 },
    { lat: 48.3165364, lng: 18.3459126 },
    { lat: 48.3166573, lng: 18.3461145 },
    { lat: 48.3162641, lng: 18.346782 },
    { lat: 48.3158792, lng: 18.3484509 },
    { lat: 48.315861, lng: 18.3494234 },
    { lat: 48.315783, lng: 18.349741 },
    { lat: 48.3152467, lng: 18.3501531 },
    { lat: 48.3147919, lng: 18.3503712 },
    { lat: 48.3146625, lng: 18.3502565 },
    { lat: 48.3146949, lng: 18.3499898 },
    { lat: 48.3146281, lng: 18.3497491 },
    { lat: 48.3146673, lng: 18.3495445 },
    { lat: 48.3142916, lng: 18.3494056 },
    { lat: 48.3142862, lng: 18.3490897 },
    { lat: 48.3141652, lng: 18.349008 },
    { lat: 48.3142162, lng: 18.3486719 },
    { lat: 48.3141601, lng: 18.3484579 },
    { lat: 48.3140412, lng: 18.348551 },
    { lat: 48.3139773, lng: 18.3484166 },
    { lat: 48.3140048, lng: 18.3481083 },
    { lat: 48.3139095, lng: 18.3480372 },
    { lat: 48.3134849, lng: 18.3482295 },
    { lat: 48.3133797, lng: 18.3479877 },
    { lat: 48.313385, lng: 18.3477222 },
    { lat: 48.3132206, lng: 18.3479037 },
    { lat: 48.313091, lng: 18.347749 },
    { lat: 48.3129637, lng: 18.3477135 },
    { lat: 48.3130231, lng: 18.3474688 },
    { lat: 48.3129644, lng: 18.3473094 },
    { lat: 48.3128354, lng: 18.3472241 },
    { lat: 48.3126522, lng: 18.3473941 },
    { lat: 48.3126537, lng: 18.3476591 },
    { lat: 48.3124167, lng: 18.3474564 },
    { lat: 48.3123784, lng: 18.3478001 },
    { lat: 48.3125349, lng: 18.3480325 },
    { lat: 48.3124557, lng: 18.3482659 },
    { lat: 48.3121875, lng: 18.3482724 },
    { lat: 48.3121623, lng: 18.3485833 },
    { lat: 48.3119468, lng: 18.3496786 },
    { lat: 48.3117597, lng: 18.3515348 },
    { lat: 48.3113952, lng: 18.3533267 },
    { lat: 48.3109726, lng: 18.3533951 },
    { lat: 48.3107148, lng: 18.353307 },
    { lat: 48.3104336, lng: 18.3531206 },
    { lat: 48.3089798, lng: 18.3567164 },
    { lat: 48.3087194, lng: 18.3571772 },
    { lat: 48.3076495, lng: 18.3584084 },
    { lat: 48.3068656, lng: 18.3590711 },
    { lat: 48.3063949, lng: 18.359726 },
    { lat: 48.3061224, lng: 18.3605346 },
    { lat: 48.3057057, lng: 18.3622429 },
    { lat: 48.3044303, lng: 18.3627122 },
    { lat: 48.3034711, lng: 18.3626497 },
    { lat: 48.3034564, lng: 18.3626899 },
    { lat: 48.3027466, lng: 18.3643406 },
    { lat: 48.3074366, lng: 18.3697108 },
    { lat: 48.3074236, lng: 18.3699616 },
    { lat: 48.3078767, lng: 18.370374 },
    { lat: 48.3087535, lng: 18.3708542 },
    { lat: 48.3098091, lng: 18.3711668 },
    { lat: 48.3097216, lng: 18.3715557 },
    { lat: 48.3111766, lng: 18.372725 },
    { lat: 48.3113303, lng: 18.372872 },
    { lat: 48.3112705, lng: 18.372953 },
    { lat: 48.312525, lng: 18.3741095 },
    { lat: 48.3123034, lng: 18.3746182 },
    { lat: 48.3147021, lng: 18.3772997 },
    { lat: 48.3161071, lng: 18.3787882 },
    { lat: 48.3162246, lng: 18.3789509 },
    { lat: 48.3161054, lng: 18.3792981 },
    { lat: 48.3161949, lng: 18.3794644 },
    { lat: 48.3165932, lng: 18.3801465 },
    { lat: 48.3185062, lng: 18.3841002 },
    { lat: 48.3189252, lng: 18.3836484 },
    { lat: 48.3205135, lng: 18.3859225 },
    { lat: 48.3207959, lng: 18.3854096 },
    { lat: 48.3214967, lng: 18.387056 },
    { lat: 48.3227105, lng: 18.385209 },
  ],
  ČervenýHrádok: [
    { lat: 48.301292, lng: 18.362541 },
    { lat: 48.3011954, lng: 18.3628896 },
    { lat: 48.300766, lng: 18.364331 },
    { lat: 48.3004248, lng: 18.3657818 },
    { lat: 48.301001, lng: 18.367969 },
    { lat: 48.2963261, lng: 18.38003 },
    { lat: 48.2953534, lng: 18.3830269 },
    { lat: 48.2952824, lng: 18.383089 },
    { lat: 48.2944655, lng: 18.3854685 },
    { lat: 48.2943716, lng: 18.3856026 },
    { lat: 48.2932676, lng: 18.3886963 },
    { lat: 48.2908951, lng: 18.3930652 },
    { lat: 48.2911533, lng: 18.3938257 },
    { lat: 48.2917981, lng: 18.3953813 },
    { lat: 48.2920767, lng: 18.3968628 },
    { lat: 48.2922129, lng: 18.398082 },
    { lat: 48.2921458, lng: 18.3995153 },
    { lat: 48.2919976, lng: 18.3999868 },
    { lat: 48.2919504, lng: 18.4006528 },
    { lat: 48.2920074, lng: 18.4015486 },
    { lat: 48.2923018, lng: 18.4032523 },
    { lat: 48.2921466, lng: 18.4034019 },
    { lat: 48.2913595, lng: 18.4037503 },
    { lat: 48.2905865, lng: 18.4040448 },
    { lat: 48.2892453, lng: 18.4044309 },
    { lat: 48.2884301, lng: 18.4045029 },
    { lat: 48.2873607, lng: 18.4042818 },
    { lat: 48.2870538, lng: 18.4042812 },
    { lat: 48.2865023, lng: 18.404404 },
    { lat: 48.2854545, lng: 18.4050143 },
    { lat: 48.284886, lng: 18.405658 },
    { lat: 48.284881, lng: 18.405664 },
    { lat: 48.284874, lng: 18.405668 },
    { lat: 48.284886, lng: 18.405702 },
    { lat: 48.285029, lng: 18.405894 },
    { lat: 48.285349, lng: 18.406102 },
    { lat: 48.28544, lng: 18.406327 },
    { lat: 48.285563, lng: 18.406841 },
    { lat: 48.285551, lng: 18.407692 },
    { lat: 48.285436, lng: 18.408986 },
    { lat: 48.285556, lng: 18.410312 },
    { lat: 48.28557, lng: 18.410482 },
    { lat: 48.285861, lng: 18.412652 },
    { lat: 48.28643, lng: 18.413331 },
    { lat: 48.287438, lng: 18.413239 },
    { lat: 48.288529, lng: 18.413201 },
    { lat: 48.289316, lng: 18.413416 },
    { lat: 48.289509, lng: 18.414677 },
    { lat: 48.289489, lng: 18.414924 },
    { lat: 48.289471, lng: 18.415103 },
    { lat: 48.289375, lng: 18.415859 },
    { lat: 48.289278, lng: 18.416135 },
    { lat: 48.289159, lng: 18.416291 },
    { lat: 48.288967, lng: 18.416801 },
    { lat: 48.288828, lng: 18.416985 },
    { lat: 48.28853, lng: 18.417753 },
    { lat: 48.288829, lng: 18.41826 },
    { lat: 48.289467, lng: 18.419083 },
    { lat: 48.2897701, lng: 18.4183838 },
    { lat: 48.2903814, lng: 18.4174123 },
    { lat: 48.2912416, lng: 18.4164371 },
    { lat: 48.2934829, lng: 18.4143579 },
    { lat: 48.293535, lng: 18.4142029 },
    { lat: 48.2954783, lng: 18.4132902 },
    { lat: 48.2952975, lng: 18.4109318 },
    { lat: 48.2953888, lng: 18.4100063 },
    { lat: 48.2957734, lng: 18.4085785 },
    { lat: 48.2960599, lng: 18.4078919 },
    { lat: 48.2964186, lng: 18.4072695 },
    { lat: 48.2967049, lng: 18.4065996 },
    { lat: 48.2981336, lng: 18.4051626 },
    { lat: 48.298856, lng: 18.4046048 },
    { lat: 48.2994562, lng: 18.4045875 },
    { lat: 48.2997648, lng: 18.4042357 },
    { lat: 48.3000514, lng: 18.4041956 },
    { lat: 48.3005299, lng: 18.4038797 },
    { lat: 48.3009318, lng: 18.4032823 },
    { lat: 48.301117, lng: 18.4026611 },
    { lat: 48.3013761, lng: 18.4022103 },
    { lat: 48.3016031, lng: 18.4016137 },
    { lat: 48.3020737, lng: 18.4007549 },
    { lat: 48.3027056, lng: 18.4001148 },
    { lat: 48.3030368, lng: 18.3989409 },
    { lat: 48.3031742, lng: 18.398695 },
    { lat: 48.303936, lng: 18.3978968 },
    { lat: 48.3050962, lng: 18.3963019 },
    { lat: 48.3051011, lng: 18.3962286 },
    { lat: 48.3056659, lng: 18.3953968 },
    { lat: 48.3065139, lng: 18.3942874 },
    { lat: 48.3067047, lng: 18.394309 },
    { lat: 48.3069016, lng: 18.3939808 },
    { lat: 48.3075006, lng: 18.393315 },
    { lat: 48.3081289, lng: 18.3927724 },
    { lat: 48.3096991, lng: 18.3915872 },
    { lat: 48.3102031, lng: 18.3910397 },
    { lat: 48.3101066, lng: 18.3908509 },
    { lat: 48.3117865, lng: 18.3891644 },
    { lat: 48.3128496, lng: 18.3882271 },
    { lat: 48.313202, lng: 18.3877976 },
    { lat: 48.3134183, lng: 18.3874708 },
    { lat: 48.3142415, lng: 18.3853378 },
    { lat: 48.3148094, lng: 18.3842065 },
    { lat: 48.3150688, lng: 18.3835442 },
    { lat: 48.3151564, lng: 18.3831974 },
    { lat: 48.315257, lng: 18.3815762 },
    { lat: 48.3161054, lng: 18.3792981 },
    { lat: 48.3162246, lng: 18.3789509 },
    { lat: 48.3161071, lng: 18.3787882 },
    { lat: 48.3147021, lng: 18.3772997 },
    { lat: 48.3123034, lng: 18.3746182 },
    { lat: 48.312525, lng: 18.3741095 },
    { lat: 48.3112705, lng: 18.372953 },
    { lat: 48.3113303, lng: 18.372872 },
    { lat: 48.3111766, lng: 18.372725 },
    { lat: 48.3097216, lng: 18.3715557 },
    { lat: 48.3098091, lng: 18.3711668 },
    { lat: 48.3087535, lng: 18.3708542 },
    { lat: 48.3078767, lng: 18.370374 },
    { lat: 48.3074236, lng: 18.3699616 },
    { lat: 48.3074366, lng: 18.3697108 },
    { lat: 48.3027466, lng: 18.3643406 },
    { lat: 48.3034564, lng: 18.3626899 },
    { lat: 48.3034711, lng: 18.3626497 },
    { lat: 48.3033322, lng: 18.3626256 },
    { lat: 48.3023347, lng: 18.3625049 },
    { lat: 48.301292, lng: 18.362541 },
  ],
  VeľkéVozokany: [
    { lat: 48.3377828, lng: 18.4334974 },
    { lat: 48.337824, lng: 18.433127 },
    { lat: 48.3378924, lng: 18.4329968 },
    { lat: 48.3378794, lng: 18.4327226 },
    { lat: 48.3381372, lng: 18.4326157 },
    { lat: 48.3381182, lng: 18.4324146 },
    { lat: 48.3378707, lng: 18.4323691 },
    { lat: 48.3379268, lng: 18.4322004 },
    { lat: 48.3378909, lng: 18.4319429 },
    { lat: 48.33806, lng: 18.4319191 },
    { lat: 48.3382085, lng: 18.4317299 },
    { lat: 48.3383328, lng: 18.4318125 },
    { lat: 48.3384305, lng: 18.4315644 },
    { lat: 48.3383522, lng: 18.4314154 },
    { lat: 48.3385131, lng: 18.4314267 },
    { lat: 48.33859, lng: 18.4313695 },
    { lat: 48.3386267, lng: 18.4315004 },
    { lat: 48.338672, lng: 18.4314826 },
    { lat: 48.3386751, lng: 18.4312755 },
    { lat: 48.3385598, lng: 18.4311928 },
    { lat: 48.3386155, lng: 18.4311408 },
    { lat: 48.3385494, lng: 18.4310066 },
    { lat: 48.3385678, lng: 18.4307681 },
    { lat: 48.3386331, lng: 18.4306262 },
    { lat: 48.3385588, lng: 18.4303455 },
    { lat: 48.3386277, lng: 18.4301663 },
    { lat: 48.3387438, lng: 18.430145 },
    { lat: 48.3388019, lng: 18.4298941 },
    { lat: 48.3387489, lng: 18.429733 },
    { lat: 48.3386129, lng: 18.4297838 },
    { lat: 48.3385871, lng: 18.4297434 },
    { lat: 48.3386522, lng: 18.4296189 },
    { lat: 48.3386122, lng: 18.4294073 },
    { lat: 48.3386943, lng: 18.4290988 },
    { lat: 48.338681, lng: 18.4289001 },
    { lat: 48.3385591, lng: 18.428779 },
    { lat: 48.3385705, lng: 18.4285605 },
    { lat: 48.33886, lng: 18.4274043 },
    { lat: 48.338973, lng: 18.4270841 },
    { lat: 48.3391329, lng: 18.426915 },
    { lat: 48.3392726, lng: 18.4262273 },
    { lat: 48.3394981, lng: 18.4256592 },
    { lat: 48.3393394, lng: 18.4255278 },
    { lat: 48.3397441, lng: 18.4241298 },
    { lat: 48.3400141, lng: 18.4242557 },
    { lat: 48.3402768, lng: 18.4240538 },
    { lat: 48.34033, lng: 18.4238723 },
    { lat: 48.3403158, lng: 18.4236617 },
    { lat: 48.3404191, lng: 18.4236902 },
    { lat: 48.3404568, lng: 18.4235404 },
    { lat: 48.3403402, lng: 18.4234941 },
    { lat: 48.3404616, lng: 18.4232217 },
    { lat: 48.3405314, lng: 18.4232183 },
    { lat: 48.3405128, lng: 18.4230302 },
    { lat: 48.3405603, lng: 18.4228483 },
    { lat: 48.3405059, lng: 18.4226442 },
    { lat: 48.3405329, lng: 18.4225021 },
    { lat: 48.3404914, lng: 18.4224226 },
    { lat: 48.3409588, lng: 18.4217443 },
    { lat: 48.3409875, lng: 18.4215729 },
    { lat: 48.3410617, lng: 18.4215462 },
    { lat: 48.3410996, lng: 18.4212989 },
    { lat: 48.3409353, lng: 18.4208433 },
    { lat: 48.3412346, lng: 18.4196787 },
    { lat: 48.3415949, lng: 18.4172894 },
    { lat: 48.3417, lng: 18.4166091 },
    { lat: 48.3417028, lng: 18.4163378 },
    { lat: 48.3415377, lng: 18.4160714 },
    { lat: 48.3419744, lng: 18.4151642 },
    { lat: 48.342214, lng: 18.4153145 },
    { lat: 48.3424761, lng: 18.4155829 },
    { lat: 48.3426561, lng: 18.4156315 },
    { lat: 48.3427422, lng: 18.4155596 },
    { lat: 48.342821, lng: 18.4155947 },
    { lat: 48.3428268, lng: 18.4156986 },
    { lat: 48.3429505, lng: 18.4158344 },
    { lat: 48.3432039, lng: 18.415944 },
    { lat: 48.3432045, lng: 18.4160393 },
    { lat: 48.3433069, lng: 18.416192 },
    { lat: 48.3434116, lng: 18.4161703 },
    { lat: 48.3434072, lng: 18.4162935 },
    { lat: 48.3437244, lng: 18.416255 },
    { lat: 48.3436502, lng: 18.4163994 },
    { lat: 48.3436726, lng: 18.4164406 },
    { lat: 48.3440509, lng: 18.4165461 },
    { lat: 48.3441689, lng: 18.4167376 },
    { lat: 48.3443341, lng: 18.4168874 },
    { lat: 48.3443875, lng: 18.4168324 },
    { lat: 48.3445444, lng: 18.4170584 },
    { lat: 48.3447759, lng: 18.4169583 },
    { lat: 48.344893, lng: 18.417058 },
    { lat: 48.3450445, lng: 18.4168836 },
    { lat: 48.3454252, lng: 18.4169112 },
    { lat: 48.3455679, lng: 18.4168198 },
    { lat: 48.3456105, lng: 18.4161129 },
    { lat: 48.3456661, lng: 18.4161375 },
    { lat: 48.3457316, lng: 18.4148476 },
    { lat: 48.3459034, lng: 18.4136272 },
    { lat: 48.3460755, lng: 18.4135381 },
    { lat: 48.3463825, lng: 18.4114464 },
    { lat: 48.3469736, lng: 18.4088304 },
    { lat: 48.3472254, lng: 18.4079109 },
    { lat: 48.3494313, lng: 18.4026887 },
    { lat: 48.3495347, lng: 18.4023185 },
    { lat: 48.3495803, lng: 18.4019691 },
    { lat: 48.3495674, lng: 18.4014157 },
    { lat: 48.3494203, lng: 18.4008671 },
    { lat: 48.3490706, lng: 18.3998817 },
    { lat: 48.3485663, lng: 18.3992199 },
    { lat: 48.3478937, lng: 18.3987736 },
    { lat: 48.3475851, lng: 18.3981423 },
    { lat: 48.3475629, lng: 18.3973057 },
    { lat: 48.3472896, lng: 18.3959766 },
    { lat: 48.3471954, lng: 18.3957477 },
    { lat: 48.3470131, lng: 18.3955108 },
    { lat: 48.3461646, lng: 18.395262 },
    { lat: 48.3453027, lng: 18.3949309 },
    { lat: 48.3445753, lng: 18.394331 },
    { lat: 48.3441816, lng: 18.3939301 },
    { lat: 48.3439331, lng: 18.3935058 },
    { lat: 48.3431639, lng: 18.3930489 },
    { lat: 48.3427561, lng: 18.3929395 },
    { lat: 48.3422178, lng: 18.3929356 },
    { lat: 48.3418663, lng: 18.3935081 },
    { lat: 48.341496, lng: 18.3939021 },
    { lat: 48.3411506, lng: 18.3941639 },
    { lat: 48.3407269, lng: 18.3943732 },
    { lat: 48.3399648, lng: 18.3944419 },
    { lat: 48.3383827, lng: 18.3949959 },
    { lat: 48.3357198, lng: 18.393346 },
    { lat: 48.3349253, lng: 18.3925619 },
    { lat: 48.3340373, lng: 18.3921576 },
    { lat: 48.3325118, lng: 18.3905116 },
    { lat: 48.331693, lng: 18.3893346 },
    { lat: 48.331056, lng: 18.388799 },
    { lat: 48.3306409, lng: 18.3896447 },
    { lat: 48.329666, lng: 18.3890189 },
    { lat: 48.3290288, lng: 18.3887209 },
    { lat: 48.3286214, lng: 18.388619 },
    { lat: 48.3283325, lng: 18.3885765 },
    { lat: 48.3280136, lng: 18.3884265 },
    { lat: 48.3270298, lng: 18.3876023 },
    { lat: 48.3259619, lng: 18.3889365 },
    { lat: 48.3259525, lng: 18.3889497 },
    { lat: 48.3252924, lng: 18.3898912 },
    { lat: 48.3245049, lng: 18.3902308 },
    { lat: 48.3243458, lng: 18.3910716 },
    { lat: 48.3240972, lng: 18.3910439 },
    { lat: 48.3231614, lng: 18.393559 },
    { lat: 48.3228688, lng: 18.3940735 },
    { lat: 48.3208676, lng: 18.3971287 },
    { lat: 48.3201423, lng: 18.3963476 },
    { lat: 48.3189822, lng: 18.3985509 },
    { lat: 48.3184388, lng: 18.398175 },
    { lat: 48.317018, lng: 18.4015028 },
    { lat: 48.31668, lng: 18.402752 },
    { lat: 48.3156716, lng: 18.4023937 },
    { lat: 48.3156649, lng: 18.4024378 },
    { lat: 48.3153452, lng: 18.4055639 },
    { lat: 48.3162477, lng: 18.4055609 },
    { lat: 48.3171546, lng: 18.4057183 },
    { lat: 48.3175222, lng: 18.4057172 },
    { lat: 48.3183462, lng: 18.4055803 },
    { lat: 48.3189351, lng: 18.4056814 },
    { lat: 48.3188574, lng: 18.4064873 },
    { lat: 48.3202762, lng: 18.4066184 },
    { lat: 48.3203457, lng: 18.4078392 },
    { lat: 48.3199164, lng: 18.4081486 },
    { lat: 48.3191674, lng: 18.4082725 },
    { lat: 48.3177539, lng: 18.4081135 },
    { lat: 48.3171591, lng: 18.4082899 },
    { lat: 48.3174797, lng: 18.4107458 },
    { lat: 48.3172838, lng: 18.4107304 },
    { lat: 48.3173006, lng: 18.4117179 },
    { lat: 48.3172076, lng: 18.4125122 },
    { lat: 48.3171584, lng: 18.4128027 },
    { lat: 48.3167538, lng: 18.4141361 },
    { lat: 48.3160804, lng: 18.4157287 },
    { lat: 48.3156054, lng: 18.4182245 },
    { lat: 48.3168576, lng: 18.4230126 },
    { lat: 48.3206405, lng: 18.4331378 },
    { lat: 48.3202877, lng: 18.4335667 },
    { lat: 48.3206702, lng: 18.4356635 },
    { lat: 48.3203121, lng: 18.4358988 },
    { lat: 48.3203054, lng: 18.4359007 },
    { lat: 48.3209422, lng: 18.4388782 },
    { lat: 48.3232647, lng: 18.4373768 },
    { lat: 48.3236165, lng: 18.4384063 },
    { lat: 48.3243394, lng: 18.4379146 },
    { lat: 48.3250402, lng: 18.4404433 },
    { lat: 48.3251459, lng: 18.441083 },
    { lat: 48.3252251, lng: 18.4419554 },
    { lat: 48.3252504, lng: 18.442692 },
    { lat: 48.3251994, lng: 18.4440019 },
    { lat: 48.3252453, lng: 18.4449678 },
    { lat: 48.325362, lng: 18.445544 },
    { lat: 48.3260162, lng: 18.4479469 },
    { lat: 48.3262424, lng: 18.4494141 },
    { lat: 48.3263393, lng: 18.4498962 },
    { lat: 48.3274114, lng: 18.4495398 },
    { lat: 48.3270267, lng: 18.4476351 },
    { lat: 48.3290776, lng: 18.446565 },
    { lat: 48.3293461, lng: 18.445086 },
    { lat: 48.3325851, lng: 18.4389247 },
    { lat: 48.3342337, lng: 18.4340227 },
    { lat: 48.3350101, lng: 18.4337669 },
    { lat: 48.3365274, lng: 18.433506 },
    { lat: 48.3377669, lng: 18.4334973 },
    { lat: 48.3377828, lng: 18.4334974 },
  ],
  ČierneKľačany: [
    { lat: 48.3377828, lng: 18.4334974 },
    { lat: 48.3378389, lng: 18.434058 },
    { lat: 48.3379088, lng: 18.4343014 },
    { lat: 48.3378619, lng: 18.4347104 },
    { lat: 48.3382821, lng: 18.434743 },
    { lat: 48.3395956, lng: 18.4346283 },
    { lat: 48.3413289, lng: 18.4341083 },
    { lat: 48.3416113, lng: 18.4352866 },
    { lat: 48.3423564, lng: 18.4375585 },
    { lat: 48.3427689, lng: 18.4386623 },
    { lat: 48.3430123, lng: 18.4387161 },
    { lat: 48.3436411, lng: 18.4391311 },
    { lat: 48.3437689, lng: 18.4392764 },
    { lat: 48.3437937, lng: 18.4396197 },
    { lat: 48.3441175, lng: 18.4406897 },
    { lat: 48.3443656, lng: 18.442204 },
    { lat: 48.3452256, lng: 18.4421095 },
    { lat: 48.3460627, lng: 18.44191 },
    { lat: 48.3477773, lng: 18.4411989 },
    { lat: 48.3479314, lng: 18.4425024 },
    { lat: 48.3497936, lng: 18.4421135 },
    { lat: 48.3501713, lng: 18.4460124 },
    { lat: 48.350537, lng: 18.4492556 },
    { lat: 48.3499244, lng: 18.4495768 },
    { lat: 48.3502964, lng: 18.45352 },
    { lat: 48.3470508, lng: 18.4549357 },
    { lat: 48.3478728, lng: 18.4582752 },
    { lat: 48.3482011, lng: 18.4580911 },
    { lat: 48.3486525, lng: 18.46 },
    { lat: 48.3501708, lng: 18.4600592 },
    { lat: 48.3505774, lng: 18.4622756 },
    { lat: 48.3493563, lng: 18.4628782 },
    { lat: 48.3486667, lng: 18.4633358 },
    { lat: 48.344841, lng: 18.4663363 },
    { lat: 48.3447097, lng: 18.4663481 },
    { lat: 48.3427194, lng: 18.468575 },
    { lat: 48.3422664, lng: 18.4692154 },
    { lat: 48.3430915, lng: 18.4698718 },
    { lat: 48.3435264, lng: 18.4701263 },
    { lat: 48.3437652, lng: 18.4696922 },
    { lat: 48.3439768, lng: 18.4696629 },
    { lat: 48.3447344, lng: 18.4699823 },
    { lat: 48.3450016, lng: 18.4697145 },
    { lat: 48.3454615, lng: 18.46944 },
    { lat: 48.3458458, lng: 18.4693206 },
    { lat: 48.3463027, lng: 18.469293 },
    { lat: 48.3468944, lng: 18.4695662 },
    { lat: 48.3472816, lng: 18.4695312 },
    { lat: 48.3480202, lng: 18.4706021 },
    { lat: 48.3487255, lng: 18.4707483 },
    { lat: 48.3492273, lng: 18.4720034 },
    { lat: 48.3494991, lng: 18.4725128 },
    { lat: 48.349624, lng: 18.4729742 },
    { lat: 48.3497991, lng: 18.4733641 },
    { lat: 48.3499745, lng: 18.4736752 },
    { lat: 48.3503446, lng: 18.4740655 },
    { lat: 48.3509507, lng: 18.4748445 },
    { lat: 48.3510954, lng: 18.4751935 },
    { lat: 48.3510959, lng: 18.4755275 },
    { lat: 48.3515355, lng: 18.4758067 },
    { lat: 48.3516873, lng: 18.4756366 },
    { lat: 48.3520035, lng: 18.4756226 },
    { lat: 48.3520316, lng: 18.4757188 },
    { lat: 48.3522033, lng: 18.4756023 },
    { lat: 48.3523134, lng: 18.475613 },
    { lat: 48.3525033, lng: 18.4759042 },
    { lat: 48.352741, lng: 18.4759192 },
    { lat: 48.3530004, lng: 18.4762443 },
    { lat: 48.3548987, lng: 18.4769106 },
    { lat: 48.3550512, lng: 18.4771153 },
    { lat: 48.356473, lng: 18.4769608 },
    { lat: 48.3582296, lng: 18.4754978 },
    { lat: 48.3585255, lng: 18.4749993 },
    { lat: 48.3587997, lng: 18.4750409 },
    { lat: 48.3590233, lng: 18.4749843 },
    { lat: 48.359303, lng: 18.474739 },
    { lat: 48.3595983, lng: 18.4747138 },
    { lat: 48.3600622, lng: 18.4743926 },
    { lat: 48.3601773, lng: 18.4744336 },
    { lat: 48.3602683, lng: 18.4743871 },
    { lat: 48.360474, lng: 18.4742092 },
    { lat: 48.3611337, lng: 18.4743701 },
    { lat: 48.3612447, lng: 18.4743332 },
    { lat: 48.361278, lng: 18.4742432 },
    { lat: 48.3614093, lng: 18.4742108 },
    { lat: 48.3616273, lng: 18.4743989 },
    { lat: 48.3618594, lng: 18.4745181 },
    { lat: 48.3619378, lng: 18.4744874 },
    { lat: 48.3620964, lng: 18.4746389 },
    { lat: 48.3627856, lng: 18.4747603 },
    { lat: 48.3628128, lng: 18.4748254 },
    { lat: 48.364656, lng: 18.4754099 },
    { lat: 48.3645264, lng: 18.4761892 },
    { lat: 48.3646149, lng: 18.4773751 },
    { lat: 48.364851, lng: 18.4790906 },
    { lat: 48.3652189, lng: 18.4804949 },
    { lat: 48.3662325, lng: 18.4810573 },
    { lat: 48.3669095, lng: 18.4817518 },
    { lat: 48.367774, lng: 18.4833049 },
    { lat: 48.3680776, lng: 18.484099 },
    { lat: 48.3685517, lng: 18.4848405 },
    { lat: 48.3688349, lng: 18.48559 },
    { lat: 48.3695469, lng: 18.4866148 },
    { lat: 48.3713272, lng: 18.4884306 },
    { lat: 48.3721715, lng: 18.4898463 },
    { lat: 48.3730875, lng: 18.4916399 },
    { lat: 48.3739594, lng: 18.4928 },
    { lat: 48.374606, lng: 18.4934975 },
    { lat: 48.3756828, lng: 18.493814 },
    { lat: 48.3757616, lng: 18.4934007 },
    { lat: 48.3759546, lng: 18.4932418 },
    { lat: 48.3762031, lng: 18.4929217 },
    { lat: 48.3765903, lng: 18.4920885 },
    { lat: 48.3766769, lng: 18.4920036 },
    { lat: 48.3768206, lng: 18.4919917 },
    { lat: 48.3770887, lng: 18.4914157 },
    { lat: 48.3774336, lng: 18.4911038 },
    { lat: 48.3774591, lng: 18.490992 },
    { lat: 48.3775257, lng: 18.4909514 },
    { lat: 48.3775293, lng: 18.4907533 },
    { lat: 48.3776347, lng: 18.4907395 },
    { lat: 48.3780337, lng: 18.4904101 },
    { lat: 48.3780959, lng: 18.490166 },
    { lat: 48.3784592, lng: 18.489966 },
    { lat: 48.378657, lng: 18.4895844 },
    { lat: 48.3788032, lng: 18.4895949 },
    { lat: 48.3788812, lng: 18.4893137 },
    { lat: 48.3790994, lng: 18.489036 },
    { lat: 48.3791984, lng: 18.4883806 },
    { lat: 48.379026, lng: 18.4881371 },
    { lat: 48.3791174, lng: 18.4879351 },
    { lat: 48.3790777, lng: 18.4877077 },
    { lat: 48.3791525, lng: 18.4872878 },
    { lat: 48.3792758, lng: 18.4871276 },
    { lat: 48.3792453, lng: 18.4869866 },
    { lat: 48.3794152, lng: 18.4866106 },
    { lat: 48.3793076, lng: 18.4863366 },
    { lat: 48.3794427, lng: 18.4861717 },
    { lat: 48.3794118, lng: 18.4860404 },
    { lat: 48.3795416, lng: 18.4858358 },
    { lat: 48.3796024, lng: 18.4854485 },
    { lat: 48.3798903, lng: 18.4851674 },
    { lat: 48.3799325, lng: 18.4849733 },
    { lat: 48.3801163, lng: 18.4847062 },
    { lat: 48.3801814, lng: 18.484455 },
    { lat: 48.3809253, lng: 18.4837305 },
    { lat: 48.381133, lng: 18.4837972 },
    { lat: 48.3813316, lng: 18.4837331 },
    { lat: 48.3815205, lng: 18.4836019 },
    { lat: 48.3816063, lng: 18.4833804 },
    { lat: 48.3817461, lng: 18.4833765 },
    { lat: 48.3818174, lng: 18.4832593 },
    { lat: 48.3818906, lng: 18.4833996 },
    { lat: 48.382028, lng: 18.4833757 },
    { lat: 48.3822059, lng: 18.4836761 },
    { lat: 48.3824395, lng: 18.4835745 },
    { lat: 48.3827051, lng: 18.4837057 },
    { lat: 48.3827796, lng: 18.4836673 },
    { lat: 48.3829538, lng: 18.4838588 },
    { lat: 48.3829965, lng: 18.4838364 },
    { lat: 48.3829525, lng: 18.483772 },
    { lat: 48.3825617, lng: 18.4832537 },
    { lat: 48.3815449, lng: 18.4825364 },
    { lat: 48.3802074, lng: 18.4819735 },
    { lat: 48.3797205, lng: 18.4820417 },
    { lat: 48.3792891, lng: 18.4819906 },
    { lat: 48.3791064, lng: 18.4818725 },
    { lat: 48.3789432, lng: 18.4813074 },
    { lat: 48.3786798, lng: 18.4810763 },
    { lat: 48.3785385, lng: 18.4806202 },
    { lat: 48.3788254, lng: 18.4797104 },
    { lat: 48.3788126, lng: 18.4788103 },
    { lat: 48.3787023, lng: 18.4782024 },
    { lat: 48.378718, lng: 18.4780383 },
    { lat: 48.3786582, lng: 18.4778548 },
    { lat: 48.3786302, lng: 18.4773725 },
    { lat: 48.3785421, lng: 18.4772065 },
    { lat: 48.3784082, lng: 18.4766629 },
    { lat: 48.378034, lng: 18.4762207 },
    { lat: 48.3779384, lng: 18.4759207 },
    { lat: 48.3776701, lng: 18.47546 },
    { lat: 48.3771877, lng: 18.4747325 },
    { lat: 48.3767643, lng: 18.4744423 },
    { lat: 48.3765466, lng: 18.4738944 },
    { lat: 48.3765182, lng: 18.4733021 },
    { lat: 48.3762531, lng: 18.472324 },
    { lat: 48.3758593, lng: 18.4713712 },
    { lat: 48.3756045, lng: 18.471005 },
    { lat: 48.3753809, lng: 18.4699457 },
    { lat: 48.3748545, lng: 18.4686526 },
    { lat: 48.3747133, lng: 18.4685067 },
    { lat: 48.3742972, lng: 18.4683271 },
    { lat: 48.3737055, lng: 18.4677688 },
    { lat: 48.3730963, lng: 18.4675857 },
    { lat: 48.372988, lng: 18.4674832 },
    { lat: 48.3729077, lng: 18.467522 },
    { lat: 48.3721451, lng: 18.4671356 },
    { lat: 48.3720062, lng: 18.4671331 },
    { lat: 48.3716129, lng: 18.4668151 },
    { lat: 48.3714244, lng: 18.4669373 },
    { lat: 48.3712246, lng: 18.4664248 },
    { lat: 48.3710396, lng: 18.4663855 },
    { lat: 48.3705505, lng: 18.4660916 },
    { lat: 48.3704636, lng: 18.4661698 },
    { lat: 48.3703808, lng: 18.4660794 },
    { lat: 48.3701835, lng: 18.4661446 },
    { lat: 48.3698695, lng: 18.4660533 },
    { lat: 48.369675, lng: 18.4658038 },
    { lat: 48.3694911, lng: 18.4661067 },
    { lat: 48.3692181, lng: 18.4654593 },
    { lat: 48.3687477, lng: 18.4653578 },
    { lat: 48.3686661, lng: 18.46523 },
    { lat: 48.3684044, lng: 18.4650684 },
    { lat: 48.3681097, lng: 18.4649591 },
    { lat: 48.3677758, lng: 18.4650811 },
    { lat: 48.3676418, lng: 18.4652096 },
    { lat: 48.3674501, lng: 18.46523 },
    { lat: 48.3673769, lng: 18.4651721 },
    { lat: 48.3670678, lng: 18.4653137 },
    { lat: 48.3660721, lng: 18.4642857 },
    { lat: 48.3648281, lng: 18.4626297 },
    { lat: 48.362972, lng: 18.4606928 },
    { lat: 48.3613514, lng: 18.4568337 },
    { lat: 48.3612545, lng: 18.4567532 },
    { lat: 48.3611168, lng: 18.4564282 },
    { lat: 48.3604542, lng: 18.4545762 },
    { lat: 48.3604481, lng: 18.4542049 },
    { lat: 48.3600607, lng: 18.4523493 },
    { lat: 48.3597362, lng: 18.4502943 },
    { lat: 48.3597372, lng: 18.4498876 },
    { lat: 48.3591312, lng: 18.4486473 },
    { lat: 48.3589595, lng: 18.448179 },
    { lat: 48.3587496, lng: 18.4472675 },
    { lat: 48.3586999, lng: 18.446698 },
    { lat: 48.3586965, lng: 18.4455435 },
    { lat: 48.358946, lng: 18.4451851 },
    { lat: 48.3590108, lng: 18.4449684 },
    { lat: 48.3588511, lng: 18.443157 },
    { lat: 48.3588829, lng: 18.4417856 },
    { lat: 48.3589051, lng: 18.4417211 },
    { lat: 48.3592607, lng: 18.4415399 },
    { lat: 48.359326, lng: 18.4414398 },
    { lat: 48.3591948, lng: 18.4411097 },
    { lat: 48.3588568, lng: 18.4406112 },
    { lat: 48.358746, lng: 18.439981 },
    { lat: 48.3588563, lng: 18.4385816 },
    { lat: 48.3588704, lng: 18.4377413 },
    { lat: 48.3590736, lng: 18.4363302 },
    { lat: 48.3593642, lng: 18.4334137 },
    { lat: 48.3595545, lng: 18.4329836 },
    { lat: 48.3593459, lng: 18.4327647 },
    { lat: 48.359355, lng: 18.4324213 },
    { lat: 48.3592949, lng: 18.4321536 },
    { lat: 48.3591034, lng: 18.4319379 },
    { lat: 48.3590877, lng: 18.4318586 },
    { lat: 48.3591426, lng: 18.4316704 },
    { lat: 48.3590651, lng: 18.4314131 },
    { lat: 48.3590648, lng: 18.4311233 },
    { lat: 48.3592625, lng: 18.4300584 },
    { lat: 48.3592004, lng: 18.4298998 },
    { lat: 48.3591111, lng: 18.4298547 },
    { lat: 48.3590437, lng: 18.4295751 },
    { lat: 48.3588763, lng: 18.4294901 },
    { lat: 48.3587893, lng: 18.429544 },
    { lat: 48.3587293, lng: 18.429482 },
    { lat: 48.3587329, lng: 18.4291457 },
    { lat: 48.3586374, lng: 18.4290091 },
    { lat: 48.3584643, lng: 18.4290007 },
    { lat: 48.3581067, lng: 18.4285785 },
    { lat: 48.3577885, lng: 18.4280503 },
    { lat: 48.3575141, lng: 18.4279678 },
    { lat: 48.3574048, lng: 18.427767 },
    { lat: 48.3572004, lng: 18.4272188 },
    { lat: 48.3571344, lng: 18.4264616 },
    { lat: 48.3568469, lng: 18.42576 },
    { lat: 48.3564355, lng: 18.4249985 },
    { lat: 48.3562884, lng: 18.4248326 },
    { lat: 48.3560694, lng: 18.4243441 },
    { lat: 48.3557891, lng: 18.4238729 },
    { lat: 48.3557236, lng: 18.4232859 },
    { lat: 48.3556036, lng: 18.4229368 },
    { lat: 48.3552886, lng: 18.4223862 },
    { lat: 48.3547422, lng: 18.4208891 },
    { lat: 48.3546486, lng: 18.4180108 },
    { lat: 48.354646, lng: 18.4154446 },
    { lat: 48.3557421, lng: 18.4152563 },
    { lat: 48.3568109, lng: 18.4142145 },
    { lat: 48.3574073, lng: 18.4133036 },
    { lat: 48.3579499, lng: 18.4127169 },
    { lat: 48.3584963, lng: 18.4123006 },
    { lat: 48.3581966, lng: 18.410557 },
    { lat: 48.3581773, lng: 18.4099923 },
    { lat: 48.358247, lng: 18.4091608 },
    { lat: 48.3582711, lng: 18.4079432 },
    { lat: 48.3581961, lng: 18.4066278 },
    { lat: 48.3566355, lng: 18.4066223 },
    { lat: 48.356682, lng: 18.4061035 },
    { lat: 48.356557, lng: 18.4060289 },
    { lat: 48.3562865, lng: 18.4053863 },
    { lat: 48.3564419, lng: 18.4053627 },
    { lat: 48.3565293, lng: 18.4050285 },
    { lat: 48.3519101, lng: 18.4033374 },
    { lat: 48.3520901, lng: 18.4016789 },
    { lat: 48.3520824, lng: 18.4010835 },
    { lat: 48.3520782, lng: 18.4007646 },
    { lat: 48.3519689, lng: 18.3994412 },
    { lat: 48.3520052, lng: 18.3986024 },
    { lat: 48.3517357, lng: 18.3968943 },
    { lat: 48.351425, lng: 18.3958259 },
    { lat: 48.351191, lng: 18.3952174 },
    { lat: 48.3509894, lng: 18.3952426 },
    { lat: 48.3498277, lng: 18.3950671 },
    { lat: 48.3495996, lng: 18.3954035 },
    { lat: 48.3494305, lng: 18.3954451 },
    { lat: 48.3488581, lng: 18.3954192 },
    { lat: 48.3488432, lng: 18.3953349 },
    { lat: 48.3484675, lng: 18.3953938 },
    { lat: 48.3483368, lng: 18.3952962 },
    { lat: 48.3481501, lng: 18.3953698 },
    { lat: 48.3471954, lng: 18.3957477 },
    { lat: 48.3472896, lng: 18.3959766 },
    { lat: 48.3475629, lng: 18.3973057 },
    { lat: 48.3475851, lng: 18.3981423 },
    { lat: 48.3478937, lng: 18.3987736 },
    { lat: 48.3485663, lng: 18.3992199 },
    { lat: 48.3490706, lng: 18.3998817 },
    { lat: 48.3494203, lng: 18.4008671 },
    { lat: 48.3495674, lng: 18.4014157 },
    { lat: 48.3495803, lng: 18.4019691 },
    { lat: 48.3495347, lng: 18.4023185 },
    { lat: 48.3494313, lng: 18.4026887 },
    { lat: 48.3472254, lng: 18.4079109 },
    { lat: 48.3469736, lng: 18.4088304 },
    { lat: 48.3463825, lng: 18.4114464 },
    { lat: 48.3460755, lng: 18.4135381 },
    { lat: 48.3459034, lng: 18.4136272 },
    { lat: 48.3457316, lng: 18.4148476 },
    { lat: 48.3456661, lng: 18.4161375 },
    { lat: 48.3456105, lng: 18.4161129 },
    { lat: 48.3455679, lng: 18.4168198 },
    { lat: 48.3454252, lng: 18.4169112 },
    { lat: 48.3450445, lng: 18.4168836 },
    { lat: 48.344893, lng: 18.417058 },
    { lat: 48.3447759, lng: 18.4169583 },
    { lat: 48.3445444, lng: 18.4170584 },
    { lat: 48.3443875, lng: 18.4168324 },
    { lat: 48.3443341, lng: 18.4168874 },
    { lat: 48.3441689, lng: 18.4167376 },
    { lat: 48.3440509, lng: 18.4165461 },
    { lat: 48.3436726, lng: 18.4164406 },
    { lat: 48.3436502, lng: 18.4163994 },
    { lat: 48.3437244, lng: 18.416255 },
    { lat: 48.3434072, lng: 18.4162935 },
    { lat: 48.3434116, lng: 18.4161703 },
    { lat: 48.3433069, lng: 18.416192 },
    { lat: 48.3432045, lng: 18.4160393 },
    { lat: 48.3432039, lng: 18.415944 },
    { lat: 48.3429505, lng: 18.4158344 },
    { lat: 48.3428268, lng: 18.4156986 },
    { lat: 48.342821, lng: 18.4155947 },
    { lat: 48.3427422, lng: 18.4155596 },
    { lat: 48.3426561, lng: 18.4156315 },
    { lat: 48.3424761, lng: 18.4155829 },
    { lat: 48.342214, lng: 18.4153145 },
    { lat: 48.3419744, lng: 18.4151642 },
    { lat: 48.3415377, lng: 18.4160714 },
    { lat: 48.3417028, lng: 18.4163378 },
    { lat: 48.3417, lng: 18.4166091 },
    { lat: 48.3415949, lng: 18.4172894 },
    { lat: 48.3412346, lng: 18.4196787 },
    { lat: 48.3409353, lng: 18.4208433 },
    { lat: 48.3410996, lng: 18.4212989 },
    { lat: 48.3410617, lng: 18.4215462 },
    { lat: 48.3409875, lng: 18.4215729 },
    { lat: 48.3409588, lng: 18.4217443 },
    { lat: 48.3404914, lng: 18.4224226 },
    { lat: 48.3405329, lng: 18.4225021 },
    { lat: 48.3405059, lng: 18.4226442 },
    { lat: 48.3405603, lng: 18.4228483 },
    { lat: 48.3405128, lng: 18.4230302 },
    { lat: 48.3405314, lng: 18.4232183 },
    { lat: 48.3404616, lng: 18.4232217 },
    { lat: 48.3403402, lng: 18.4234941 },
    { lat: 48.3404568, lng: 18.4235404 },
    { lat: 48.3404191, lng: 18.4236902 },
    { lat: 48.3403158, lng: 18.4236617 },
    { lat: 48.34033, lng: 18.4238723 },
    { lat: 48.3402768, lng: 18.4240538 },
    { lat: 48.3400141, lng: 18.4242557 },
    { lat: 48.3397441, lng: 18.4241298 },
    { lat: 48.3393394, lng: 18.4255278 },
    { lat: 48.3394981, lng: 18.4256592 },
    { lat: 48.3392726, lng: 18.4262273 },
    { lat: 48.3391329, lng: 18.426915 },
    { lat: 48.338973, lng: 18.4270841 },
    { lat: 48.33886, lng: 18.4274043 },
    { lat: 48.3385705, lng: 18.4285605 },
    { lat: 48.3385591, lng: 18.428779 },
    { lat: 48.338681, lng: 18.4289001 },
    { lat: 48.3386943, lng: 18.4290988 },
    { lat: 48.3386122, lng: 18.4294073 },
    { lat: 48.3386522, lng: 18.4296189 },
    { lat: 48.3385871, lng: 18.4297434 },
    { lat: 48.3386129, lng: 18.4297838 },
    { lat: 48.3387489, lng: 18.429733 },
    { lat: 48.3388019, lng: 18.4298941 },
    { lat: 48.3387438, lng: 18.430145 },
    { lat: 48.3386277, lng: 18.4301663 },
    { lat: 48.3385588, lng: 18.4303455 },
    { lat: 48.3386331, lng: 18.4306262 },
    { lat: 48.3385678, lng: 18.4307681 },
    { lat: 48.3385494, lng: 18.4310066 },
    { lat: 48.3386155, lng: 18.4311408 },
    { lat: 48.3385598, lng: 18.4311928 },
    { lat: 48.3386751, lng: 18.4312755 },
    { lat: 48.338672, lng: 18.4314826 },
    { lat: 48.3386267, lng: 18.4315004 },
    { lat: 48.33859, lng: 18.4313695 },
    { lat: 48.3385131, lng: 18.4314267 },
    { lat: 48.3383522, lng: 18.4314154 },
    { lat: 48.3384305, lng: 18.4315644 },
    { lat: 48.3383328, lng: 18.4318125 },
    { lat: 48.3382085, lng: 18.4317299 },
    { lat: 48.33806, lng: 18.4319191 },
    { lat: 48.3378909, lng: 18.4319429 },
    { lat: 48.3379268, lng: 18.4322004 },
    { lat: 48.3378707, lng: 18.4323691 },
    { lat: 48.3381182, lng: 18.4324146 },
    { lat: 48.3381372, lng: 18.4326157 },
    { lat: 48.3378794, lng: 18.4327226 },
    { lat: 48.3378924, lng: 18.4329968 },
    { lat: 48.337824, lng: 18.433127 },
    { lat: 48.3377828, lng: 18.4334974 },
  ],
  Nemčiňany: [
    { lat: 48.3282076, lng: 18.4564967 },
    { lat: 48.3279083, lng: 18.4556618 },
    { lat: 48.3270293, lng: 18.4531064 },
    { lat: 48.3270781, lng: 18.452988 },
    { lat: 48.3266863, lng: 18.4517112 },
    { lat: 48.3263393, lng: 18.4498962 },
    { lat: 48.3262424, lng: 18.4494141 },
    { lat: 48.3260162, lng: 18.4479469 },
    { lat: 48.325362, lng: 18.445544 },
    { lat: 48.3252453, lng: 18.4449678 },
    { lat: 48.3251994, lng: 18.4440019 },
    { lat: 48.3252504, lng: 18.442692 },
    { lat: 48.3252251, lng: 18.4419554 },
    { lat: 48.3251459, lng: 18.441083 },
    { lat: 48.3250402, lng: 18.4404433 },
    { lat: 48.3243394, lng: 18.4379146 },
    { lat: 48.3236165, lng: 18.4384063 },
    { lat: 48.3232647, lng: 18.4373768 },
    { lat: 48.3209422, lng: 18.4388782 },
    { lat: 48.3203054, lng: 18.4359007 },
    { lat: 48.3195781, lng: 18.4361307 },
    { lat: 48.3182941, lng: 18.436511 },
    { lat: 48.3180124, lng: 18.4352438 },
    { lat: 48.3176888, lng: 18.4346639 },
    { lat: 48.3173033, lng: 18.4341236 },
    { lat: 48.3169581, lng: 18.4338395 },
    { lat: 48.3159838, lng: 18.4328409 },
    { lat: 48.315678, lng: 18.4330792 },
    { lat: 48.3149676, lng: 18.4332702 },
    { lat: 48.3149385, lng: 18.4330336 },
    { lat: 48.313646, lng: 18.4332804 },
    { lat: 48.3135243, lng: 18.43249 },
    { lat: 48.3126613, lng: 18.4322729 },
    { lat: 48.3126738, lng: 18.4322352 },
    { lat: 48.3132216, lng: 18.4302121 },
    { lat: 48.3132563, lng: 18.4300465 },
    { lat: 48.3132403, lng: 18.429536 },
    { lat: 48.3128928, lng: 18.428547 },
    { lat: 48.312423, lng: 18.4267263 },
    { lat: 48.3121832, lng: 18.4259481 },
    { lat: 48.311918, lng: 18.4261296 },
    { lat: 48.3116111, lng: 18.4250785 },
    { lat: 48.3110066, lng: 18.4235715 },
    { lat: 48.3105956, lng: 18.422336 },
    { lat: 48.3105192, lng: 18.4221415 },
    { lat: 48.3104021, lng: 18.4222243 },
    { lat: 48.3098878, lng: 18.4204969 },
    { lat: 48.309497, lng: 18.4194203 },
    { lat: 48.3091143, lng: 18.4192569 },
    { lat: 48.3079484, lng: 18.4162299 },
    { lat: 48.30719, lng: 18.4168071 },
    { lat: 48.3070096, lng: 18.4164864 },
    { lat: 48.3063268, lng: 18.4169676 },
    { lat: 48.3057589, lng: 18.4154703 },
    { lat: 48.305085, lng: 18.4160071 },
    { lat: 48.3011857, lng: 18.4183766 },
    { lat: 48.3011519, lng: 18.4185165 },
    { lat: 48.3004667, lng: 18.4190758 },
    { lat: 48.2994575, lng: 18.4196242 },
    { lat: 48.2981398, lng: 18.419506 },
    { lat: 48.2967643, lng: 18.420066 },
    { lat: 48.2955794, lng: 18.4207626 },
    { lat: 48.2955421, lng: 18.4207414 },
    { lat: 48.2956482, lng: 18.4202395 },
    { lat: 48.2956686, lng: 18.419877 },
    { lat: 48.2956326, lng: 18.4191771 },
    { lat: 48.2931753, lng: 18.4202216 },
    { lat: 48.292235, lng: 18.421134 },
    { lat: 48.292195, lng: 18.421175 },
    { lat: 48.292005, lng: 18.421826 },
    { lat: 48.291822, lng: 18.422199 },
    { lat: 48.291473, lng: 18.422703 },
    { lat: 48.291152, lng: 18.423076 },
    { lat: 48.290939, lng: 18.423351 },
    { lat: 48.290797, lng: 18.423625 },
    { lat: 48.290683, lng: 18.42392 },
    { lat: 48.290635, lng: 18.424332 },
    { lat: 48.290385, lng: 18.426128 },
    { lat: 48.290341, lng: 18.426596 },
    { lat: 48.290323, lng: 18.427247 },
    { lat: 48.290458, lng: 18.428025 },
    { lat: 48.290612, lng: 18.42864 },
    { lat: 48.290752, lng: 18.429045 },
    { lat: 48.290901, lng: 18.429474 },
    { lat: 48.291175, lng: 18.430086 },
    { lat: 48.291198, lng: 18.430145 },
    { lat: 48.291255, lng: 18.430282 },
    { lat: 48.291439, lng: 18.430741 },
    { lat: 48.291713, lng: 18.431751 },
    { lat: 48.291746, lng: 18.432847 },
    { lat: 48.291719, lng: 18.433426 },
    { lat: 48.291565, lng: 18.433766 },
    { lat: 48.291541, lng: 18.433816 },
    { lat: 48.291267, lng: 18.434417 },
    { lat: 48.290762, lng: 18.435084 },
    { lat: 48.29011, lng: 18.435661 },
    { lat: 48.289292, lng: 18.436185 },
    { lat: 48.288718, lng: 18.436502 },
    { lat: 48.288568, lng: 18.436732 },
    { lat: 48.288466, lng: 18.436886 },
    { lat: 48.288023, lng: 18.437144 },
    { lat: 48.28778, lng: 18.437363 },
    { lat: 48.287354, lng: 18.43819 },
    { lat: 48.287028, lng: 18.438875 },
    { lat: 48.286712, lng: 18.439434 },
    { lat: 48.286469, lng: 18.44023 },
    { lat: 48.286083, lng: 18.440943 },
    { lat: 48.285781, lng: 18.441563 },
    { lat: 48.285418, lng: 18.442077 },
    { lat: 48.282528, lng: 18.449081 },
    { lat: 48.282509, lng: 18.449124 },
    { lat: 48.282462, lng: 18.449234 },
    { lat: 48.282448, lng: 18.449267 },
    { lat: 48.281585, lng: 18.451341 },
    { lat: 48.281577, lng: 18.451365 },
    { lat: 48.281274, lng: 18.450851 },
    { lat: 48.281101, lng: 18.451892 },
    { lat: 48.280958, lng: 18.452185 },
    { lat: 48.280694, lng: 18.452326 },
    { lat: 48.280598, lng: 18.45253 },
    { lat: 48.280573, lng: 18.452613 },
    { lat: 48.280368, lng: 18.453342 },
    { lat: 48.280361, lng: 18.453377 },
    { lat: 48.28027, lng: 18.453823 },
    { lat: 48.280172, lng: 18.454311 },
    { lat: 48.280086, lng: 18.454731 },
    { lat: 48.280039, lng: 18.45496 },
    { lat: 48.280017, lng: 18.455064 },
    { lat: 48.279999, lng: 18.455149 },
    { lat: 48.279496, lng: 18.456052 },
    { lat: 48.279164, lng: 18.456645 },
    { lat: 48.27897, lng: 18.456874 },
    { lat: 48.278024, lng: 18.457991 },
    { lat: 48.277415, lng: 18.459051 },
    { lat: 48.276909, lng: 18.459786 },
    { lat: 48.276401, lng: 18.460925 },
    { lat: 48.275653, lng: 18.462599 },
    { lat: 48.275433, lng: 18.463082 },
    { lat: 48.275296, lng: 18.463365 },
    { lat: 48.275183, lng: 18.463502 },
    { lat: 48.274864, lng: 18.463905 },
    { lat: 48.274515, lng: 18.46433 },
    { lat: 48.274355, lng: 18.464526 },
    { lat: 48.274331, lng: 18.464556 },
    { lat: 48.27368, lng: 18.464879 },
    { lat: 48.273703, lng: 18.465571 },
    { lat: 48.273723, lng: 18.465856 },
    { lat: 48.273794, lng: 18.467078 },
    { lat: 48.273825, lng: 18.468459 },
    { lat: 48.273891, lng: 18.469099 },
    { lat: 48.2739, lng: 18.46924 },
    { lat: 48.273914, lng: 18.469666 },
    { lat: 48.273913, lng: 18.470124 },
    { lat: 48.273931, lng: 18.470648 },
    { lat: 48.273974, lng: 18.471652 },
    { lat: 48.274019, lng: 18.473166 },
    { lat: 48.274097, lng: 18.474595 },
    { lat: 48.274162, lng: 18.47594 },
    { lat: 48.274169, lng: 18.476092 },
    { lat: 48.274225, lng: 18.477111 },
    { lat: 48.274326, lng: 18.479143 },
    { lat: 48.27437, lng: 18.480185 },
    { lat: 48.27437, lng: 18.480287 },
    { lat: 48.274381, lng: 18.480524 },
    { lat: 48.274394, lng: 18.480587 },
    { lat: 48.274597, lng: 18.480461 },
    { lat: 48.274789, lng: 18.480331 },
    { lat: 48.275132, lng: 18.480112 },
    { lat: 48.275479, lng: 18.479898 },
    { lat: 48.275572, lng: 18.479831 },
    { lat: 48.276078, lng: 18.479496 },
    { lat: 48.276424, lng: 18.479191 },
    { lat: 48.276587, lng: 18.478964 },
    { lat: 48.27671, lng: 18.478765 },
    { lat: 48.276847, lng: 18.478534 },
    { lat: 48.277115, lng: 18.478106 },
    { lat: 48.277247, lng: 18.477877 },
    { lat: 48.277398, lng: 18.477642 },
    { lat: 48.277527, lng: 18.47751 },
    { lat: 48.277872, lng: 18.477195 },
    { lat: 48.27822, lng: 18.476655 },
    { lat: 48.278351, lng: 18.476502 },
    { lat: 48.278537, lng: 18.476309 },
    { lat: 48.278593, lng: 18.476238 },
    { lat: 48.278686, lng: 18.476103 },
    { lat: 48.278961, lng: 18.475751 },
    { lat: 48.27922, lng: 18.475489 },
    { lat: 48.27935, lng: 18.4754 },
    { lat: 48.279818, lng: 18.475107 },
    { lat: 48.280056, lng: 18.474988 },
    { lat: 48.280294, lng: 18.474881 },
    { lat: 48.280649, lng: 18.474804 },
    { lat: 48.281134, lng: 18.474833 },
    { lat: 48.281153, lng: 18.474798 },
    { lat: 48.281522, lng: 18.474218 },
    { lat: 48.281545, lng: 18.474179 },
    { lat: 48.281564, lng: 18.474158 },
    { lat: 48.281582, lng: 18.474138 },
    { lat: 48.2815337, lng: 18.4740326 },
    { lat: 48.2816303, lng: 18.4737766 },
    { lat: 48.281803, lng: 18.473075 },
    { lat: 48.281899, lng: 18.472508 },
    { lat: 48.281905, lng: 18.472316 },
    { lat: 48.281923, lng: 18.471883 },
    { lat: 48.282089, lng: 18.470536 },
    { lat: 48.28239, lng: 18.46994 },
    { lat: 48.282419, lng: 18.469884 },
    { lat: 48.282515, lng: 18.469702 },
    { lat: 48.28255, lng: 18.469634 },
    { lat: 48.283049, lng: 18.468655 },
    { lat: 48.283479, lng: 18.467806 },
    { lat: 48.284009, lng: 18.466792 },
    { lat: 48.284371, lng: 18.466055 },
    { lat: 48.284528, lng: 18.465747 },
    { lat: 48.285339, lng: 18.464162 },
    { lat: 48.285637, lng: 18.463578 },
    { lat: 48.285541, lng: 18.463105 },
    { lat: 48.285525, lng: 18.463022 },
    { lat: 48.285263, lng: 18.461704 },
    { lat: 48.288534, lng: 18.461243 },
    { lat: 48.28859, lng: 18.461238 },
    { lat: 48.289755, lng: 18.461063 },
    { lat: 48.290416, lng: 18.460967 },
    { lat: 48.292041, lng: 18.460734 },
    { lat: 48.29209, lng: 18.460728 },
    { lat: 48.292428, lng: 18.460679 },
    { lat: 48.295245, lng: 18.463452 },
    { lat: 48.295333, lng: 18.463538 },
    { lat: 48.295381, lng: 18.463605 },
    { lat: 48.295873, lng: 18.464248 },
    { lat: 48.29594, lng: 18.464335 },
    { lat: 48.296532, lng: 18.465091 },
    { lat: 48.29655, lng: 18.465121 },
    { lat: 48.296899, lng: 18.466028 },
    { lat: 48.29727, lng: 18.467799 },
    { lat: 48.297035, lng: 18.468562 },
    { lat: 48.297473, lng: 18.469981 },
    { lat: 48.2976, lng: 18.470595 },
    { lat: 48.297778, lng: 18.471459 },
    { lat: 48.2977446, lng: 18.4717025 },
    { lat: 48.297599, lng: 18.472762 },
    { lat: 48.297273, lng: 18.473505 },
    { lat: 48.297017, lng: 18.473733 },
    { lat: 48.29689, lng: 18.474316 },
    { lat: 48.297136, lng: 18.475333 },
    { lat: 48.297216, lng: 18.475448 },
    { lat: 48.297794, lng: 18.476278 },
    { lat: 48.297866, lng: 18.47629 },
    { lat: 48.298648, lng: 18.476427 },
    { lat: 48.299458, lng: 18.476638 },
    { lat: 48.300202, lng: 18.476996 },
    { lat: 48.300959, lng: 18.477565 },
    { lat: 48.301919, lng: 18.477985 },
    { lat: 48.3027, lng: 18.478548 },
    { lat: 48.303762, lng: 18.478962 },
    { lat: 48.304136, lng: 18.478982 },
    { lat: 48.30428, lng: 18.478989 },
    { lat: 48.305546, lng: 18.479529 },
    { lat: 48.305617, lng: 18.479559 },
    { lat: 48.305828, lng: 18.479021 },
    { lat: 48.306573, lng: 18.478559 },
    { lat: 48.307092, lng: 18.478064 },
    { lat: 48.307838, lng: 18.47709 },
    { lat: 48.308261, lng: 18.476536 },
    { lat: 48.308824, lng: 18.475757 },
    { lat: 48.309083, lng: 18.475519 },
    { lat: 48.309673, lng: 18.475151 },
    { lat: 48.310057, lng: 18.475029 },
    { lat: 48.31084, lng: 18.474781 },
    { lat: 48.310861, lng: 18.474774 },
    { lat: 48.310915, lng: 18.474726 },
    { lat: 48.311207, lng: 18.474466 },
    { lat: 48.311868, lng: 18.473654 },
    { lat: 48.312522, lng: 18.472818 },
    { lat: 48.312986, lng: 18.472311 },
    { lat: 48.312988, lng: 18.472309 },
    { lat: 48.313018, lng: 18.472276 },
    { lat: 48.313123, lng: 18.472161 },
    { lat: 48.313661, lng: 18.471517 },
    { lat: 48.313828, lng: 18.471041 },
    { lat: 48.314057, lng: 18.470667 },
    { lat: 48.314321, lng: 18.470251 },
    { lat: 48.314504, lng: 18.470032 },
    { lat: 48.315101, lng: 18.469569 },
    { lat: 48.3153125, lng: 18.4691933 },
    { lat: 48.315496, lng: 18.4688675 },
    { lat: 48.3156019, lng: 18.4686202 },
    { lat: 48.3162059, lng: 18.4672091 },
    { lat: 48.3210491, lng: 18.4624431 },
    { lat: 48.326635, lng: 18.4576937 },
    { lat: 48.3282076, lng: 18.4564967 },
  ],
  Velčice: [
    { lat: 48.487703, lng: 18.274596 },
    { lat: 48.487703, lng: 18.274572 },
    { lat: 48.487579, lng: 18.272056 },
    { lat: 48.486994, lng: 18.269361 },
    { lat: 48.486499, lng: 18.266447 },
    { lat: 48.48628, lng: 18.263744 },
    { lat: 48.486275, lng: 18.262045 },
    { lat: 48.486232, lng: 18.262016 },
    { lat: 48.485283, lng: 18.26141 },
    { lat: 48.483995, lng: 18.259418 },
    { lat: 48.482546, lng: 18.257727 },
    { lat: 48.48137, lng: 18.255764 },
    { lat: 48.480986, lng: 18.255372 },
    { lat: 48.478514, lng: 18.253387 },
    { lat: 48.477108, lng: 18.253095 },
    { lat: 48.476065, lng: 18.252506 },
    { lat: 48.475899, lng: 18.252254 },
    { lat: 48.475184, lng: 18.251143 },
    { lat: 48.474748, lng: 18.250493 },
    { lat: 48.47466, lng: 18.250255 },
    { lat: 48.474627, lng: 18.250174 },
    { lat: 48.47453, lng: 18.249883 },
    { lat: 48.474502, lng: 18.249819 },
    { lat: 48.474461, lng: 18.249645 },
    { lat: 48.474403, lng: 18.249388 },
    { lat: 48.47435, lng: 18.249184 },
    { lat: 48.474296, lng: 18.248934 },
    { lat: 48.474159, lng: 18.248574 },
    { lat: 48.474078, lng: 18.248318 },
    { lat: 48.473885, lng: 18.2481 },
    { lat: 48.473813, lng: 18.248014 },
    { lat: 48.473656, lng: 18.247825 },
    { lat: 48.473521, lng: 18.247659 },
    { lat: 48.473308, lng: 18.247407 },
    { lat: 48.473137, lng: 18.247206 },
    { lat: 48.473027, lng: 18.247069 },
    { lat: 48.472927, lng: 18.246884 },
    { lat: 48.4728, lng: 18.246648 },
    { lat: 48.472664, lng: 18.246393 },
    { lat: 48.472568, lng: 18.246214 },
    { lat: 48.472465, lng: 18.246006 },
    { lat: 48.472362, lng: 18.245633 },
    { lat: 48.472208, lng: 18.245115 },
    { lat: 48.472147, lng: 18.244918 },
    { lat: 48.472102, lng: 18.24476 },
    { lat: 48.471984, lng: 18.244359 },
    { lat: 48.471833, lng: 18.243853 },
    { lat: 48.471745, lng: 18.243551 },
    { lat: 48.471699, lng: 18.243382 },
    { lat: 48.47152, lng: 18.243076 },
    { lat: 48.47131, lng: 18.242748 },
    { lat: 48.47121, lng: 18.242574 },
    { lat: 48.471055, lng: 18.242327 },
    { lat: 48.470873, lng: 18.242015 },
    { lat: 48.470662, lng: 18.241681 },
    { lat: 48.470634, lng: 18.241623 },
    { lat: 48.47048, lng: 18.241463 },
    { lat: 48.470267, lng: 18.241252 },
    { lat: 48.470093, lng: 18.24107 },
    { lat: 48.469927, lng: 18.240898 },
    { lat: 48.469648, lng: 18.240607 },
    { lat: 48.469474, lng: 18.240431 },
    { lat: 48.46917, lng: 18.240105 },
    { lat: 48.469115, lng: 18.240054 },
    { lat: 48.468937, lng: 18.239871 },
    { lat: 48.468771, lng: 18.2397 },
    { lat: 48.468748, lng: 18.239677 },
    { lat: 48.468565, lng: 18.239776 },
    { lat: 48.468349, lng: 18.239882 },
    { lat: 48.468144, lng: 18.24 },
    { lat: 48.468074, lng: 18.240031 },
    { lat: 48.467818, lng: 18.240145 },
    { lat: 48.467528, lng: 18.240288 },
    { lat: 48.467312, lng: 18.240194 },
    { lat: 48.467065, lng: 18.240107 },
    { lat: 48.466803, lng: 18.239992 },
    { lat: 48.466641, lng: 18.239839 },
    { lat: 48.466429, lng: 18.239633 },
    { lat: 48.466223, lng: 18.239434 },
    { lat: 48.466061, lng: 18.2393 },
    { lat: 48.465947, lng: 18.239184 },
    { lat: 48.46592, lng: 18.239167 },
    { lat: 48.465728, lng: 18.239059 },
    { lat: 48.465528, lng: 18.238945 },
    { lat: 48.465427, lng: 18.238888 },
    { lat: 48.465235, lng: 18.238786 },
    { lat: 48.465168, lng: 18.238745 },
    { lat: 48.46515, lng: 18.238739 },
    { lat: 48.4577782, lng: 18.2428837 },
    { lat: 48.4464928, lng: 18.2524461 },
    { lat: 48.4398469, lng: 18.2550866 },
    { lat: 48.4322246, lng: 18.2573056 },
    { lat: 48.4321312, lng: 18.2559125 },
    { lat: 48.4317624, lng: 18.2544989 },
    { lat: 48.4314642, lng: 18.2535682 },
    { lat: 48.4286153, lng: 18.2526074 },
    { lat: 48.4216585, lng: 18.262399 },
    { lat: 48.4198112, lng: 18.2653517 },
    { lat: 48.4178357, lng: 18.2693929 },
    { lat: 48.4146498, lng: 18.2741131 },
    { lat: 48.4133959, lng: 18.2738533 },
    { lat: 48.4131807, lng: 18.2736613 },
    { lat: 48.4123684, lng: 18.2732877 },
    { lat: 48.4121771, lng: 18.2731185 },
    { lat: 48.4116983, lng: 18.2729576 },
    { lat: 48.4115209, lng: 18.2731869 },
    { lat: 48.4113055, lng: 18.2735258 },
    { lat: 48.4094615, lng: 18.2767747 },
    { lat: 48.4084433, lng: 18.2782772 },
    { lat: 48.4083689, lng: 18.278387 },
    { lat: 48.4079481, lng: 18.2792254 },
    { lat: 48.407592, lng: 18.2799351 },
    { lat: 48.4066712, lng: 18.2814282 },
    { lat: 48.4065709, lng: 18.2815909 },
    { lat: 48.4054881, lng: 18.2834741 },
    { lat: 48.405416, lng: 18.2836429 },
    { lat: 48.4040947, lng: 18.2867374 },
    { lat: 48.4035096, lng: 18.2881077 },
    { lat: 48.4029975, lng: 18.2890781 },
    { lat: 48.4022861, lng: 18.2899179 },
    { lat: 48.4018476, lng: 18.2915686 },
    { lat: 48.4022621, lng: 18.2920483 },
    { lat: 48.4025835, lng: 18.2925402 },
    { lat: 48.4025629, lng: 18.2927137 },
    { lat: 48.4024639, lng: 18.2929547 },
    { lat: 48.4025684, lng: 18.2930088 },
    { lat: 48.40238, lng: 18.2932826 },
    { lat: 48.402384, lng: 18.2937288 },
    { lat: 48.4023077, lng: 18.2937564 },
    { lat: 48.4023051, lng: 18.2939669 },
    { lat: 48.4023563, lng: 18.2940562 },
    { lat: 48.4023129, lng: 18.2943238 },
    { lat: 48.4022556, lng: 18.2944828 },
    { lat: 48.4021159, lng: 18.2943304 },
    { lat: 48.4020705, lng: 18.2943476 },
    { lat: 48.4021416, lng: 18.2946818 },
    { lat: 48.4020287, lng: 18.2948586 },
    { lat: 48.4020554, lng: 18.295032 },
    { lat: 48.402019, lng: 18.2951638 },
    { lat: 48.4018753, lng: 18.2953524 },
    { lat: 48.4018376, lng: 18.2956452 },
    { lat: 48.4017621, lng: 18.2958214 },
    { lat: 48.4015901, lng: 18.2959518 },
    { lat: 48.4015963, lng: 18.2961399 },
    { lat: 48.4016573, lng: 18.2962805 },
    { lat: 48.4016112, lng: 18.2964793 },
    { lat: 48.4016359, lng: 18.2967342 },
    { lat: 48.4015943, lng: 18.2967769 },
    { lat: 48.4014999, lng: 18.2980314 },
    { lat: 48.4014155, lng: 18.298136 },
    { lat: 48.4013995, lng: 18.2982698 },
    { lat: 48.401496, lng: 18.2983781 },
    { lat: 48.4014238, lng: 18.2985965 },
    { lat: 48.4013931, lng: 18.2989662 },
    { lat: 48.4014985, lng: 18.2990148 },
    { lat: 48.4014865, lng: 18.2994186 },
    { lat: 48.4015949, lng: 18.2999334 },
    { lat: 48.4015496, lng: 18.3003252 },
    { lat: 48.4016345, lng: 18.300451 },
    { lat: 48.4016733, lng: 18.3004386 },
    { lat: 48.4016444, lng: 18.3006244 },
    { lat: 48.4016778, lng: 18.300716 },
    { lat: 48.4017589, lng: 18.300713 },
    { lat: 48.4018046, lng: 18.300884 },
    { lat: 48.4016841, lng: 18.3012212 },
    { lat: 48.4017085, lng: 18.3012933 },
    { lat: 48.4018338, lng: 18.3012556 },
    { lat: 48.4019809, lng: 18.3014625 },
    { lat: 48.4019602, lng: 18.3018518 },
    { lat: 48.4021236, lng: 18.3017268 },
    { lat: 48.4021706, lng: 18.3021376 },
    { lat: 48.4023415, lng: 18.302186 },
    { lat: 48.4023479, lng: 18.3023399 },
    { lat: 48.4022279, lng: 18.3024296 },
    { lat: 48.402201, lng: 18.3025339 },
    { lat: 48.4023286, lng: 18.3027114 },
    { lat: 48.4025269, lng: 18.3032604 },
    { lat: 48.4025621, lng: 18.3036639 },
    { lat: 48.4024966, lng: 18.3038992 },
    { lat: 48.4021777, lng: 18.3042626 },
    { lat: 48.4023001, lng: 18.3045888 },
    { lat: 48.4022763, lng: 18.3052583 },
    { lat: 48.4022011, lng: 18.3054246 },
    { lat: 48.40234, lng: 18.3058456 },
    { lat: 48.4024458, lng: 18.3073464 },
    { lat: 48.4020442, lng: 18.3077012 },
    { lat: 48.4019594, lng: 18.3078385 },
    { lat: 48.4018986, lng: 18.3079802 },
    { lat: 48.4020447, lng: 18.3085651 },
    { lat: 48.4025515, lng: 18.3101128 },
    { lat: 48.4023502, lng: 18.3102853 },
    { lat: 48.402562, lng: 18.3112118 },
    { lat: 48.4028502, lng: 18.3128853 },
    { lat: 48.4029575, lng: 18.3128474 },
    { lat: 48.4035403, lng: 18.3154965 },
    { lat: 48.4036812, lng: 18.3159691 },
    { lat: 48.4039992, lng: 18.3167592 },
    { lat: 48.403906, lng: 18.3170254 },
    { lat: 48.4043324, lng: 18.3179384 },
    { lat: 48.4049695, lng: 18.3195597 },
    { lat: 48.4060623, lng: 18.3217733 },
    { lat: 48.4069191, lng: 18.3243666 },
    { lat: 48.4069223, lng: 18.3243818 },
    { lat: 48.4071977, lng: 18.3244211 },
    { lat: 48.407377, lng: 18.324341 },
    { lat: 48.4078894, lng: 18.3238077 },
    { lat: 48.408049, lng: 18.3237375 },
    { lat: 48.4081913, lng: 18.3239206 },
    { lat: 48.4083848, lng: 18.32391 },
    { lat: 48.408431, lng: 18.3243647 },
    { lat: 48.408487, lng: 18.3244563 },
    { lat: 48.4088688, lng: 18.3243481 },
    { lat: 48.4089438, lng: 18.3243946 },
    { lat: 48.4090971, lng: 18.3241342 },
    { lat: 48.4091446, lng: 18.3239583 },
    { lat: 48.4093254, lng: 18.3240406 },
    { lat: 48.4094691, lng: 18.3237683 },
    { lat: 48.4100846, lng: 18.323166 },
    { lat: 48.4102926, lng: 18.3231529 },
    { lat: 48.4104324, lng: 18.3229705 },
    { lat: 48.4105132, lng: 18.3229439 },
    { lat: 48.4105911, lng: 18.3230255 },
    { lat: 48.4106141, lng: 18.3232303 },
    { lat: 48.410784, lng: 18.3233627 },
    { lat: 48.4109843, lng: 18.3231346 },
    { lat: 48.411019, lng: 18.3232665 },
    { lat: 48.4115482, lng: 18.3229309 },
    { lat: 48.4119541, lng: 18.3228466 },
    { lat: 48.412478, lng: 18.3224759 },
    { lat: 48.4128498, lng: 18.3227021 },
    { lat: 48.4132133, lng: 18.3226303 },
    { lat: 48.4133944, lng: 18.3218048 },
    { lat: 48.4135926, lng: 18.3218987 },
    { lat: 48.414073, lng: 18.3219728 },
    { lat: 48.4142776, lng: 18.321899 },
    { lat: 48.4153746, lng: 18.3209916 },
    { lat: 48.4155537, lng: 18.3206057 },
    { lat: 48.4163652, lng: 18.3201582 },
    { lat: 48.416748, lng: 18.319646 },
    { lat: 48.417083, lng: 18.3190266 },
    { lat: 48.4172982, lng: 18.3189223 },
    { lat: 48.4176505, lng: 18.3182471 },
    { lat: 48.4183983, lng: 18.3184069 },
    { lat: 48.4195996, lng: 18.3179236 },
    { lat: 48.4197116, lng: 18.3178311 },
    { lat: 48.4198044, lng: 18.3175969 },
    { lat: 48.4201764, lng: 18.3170941 },
    { lat: 48.4201825, lng: 18.3170063 },
    { lat: 48.4204584, lng: 18.3168981 },
    { lat: 48.420597, lng: 18.3169532 },
    { lat: 48.4209363, lng: 18.3166486 },
    { lat: 48.4214193, lng: 18.3164541 },
    { lat: 48.4214093, lng: 18.3163141 },
    { lat: 48.4215951, lng: 18.3160899 },
    { lat: 48.4216588, lng: 18.316141 },
    { lat: 48.4216554, lng: 18.3163972 },
    { lat: 48.4218044, lng: 18.3164769 },
    { lat: 48.4218793, lng: 18.3163852 },
    { lat: 48.4218742, lng: 18.3162001 },
    { lat: 48.4220314, lng: 18.316024 },
    { lat: 48.4228729, lng: 18.3161629 },
    { lat: 48.4229665, lng: 18.3158831 },
    { lat: 48.4229109, lng: 18.3157786 },
    { lat: 48.4229321, lng: 18.3156729 },
    { lat: 48.4234054, lng: 18.3153859 },
    { lat: 48.4234394, lng: 18.3154675 },
    { lat: 48.4234948, lng: 18.3154689 },
    { lat: 48.4237432, lng: 18.3151561 },
    { lat: 48.4239337, lng: 18.3150343 },
    { lat: 48.4239614, lng: 18.3146482 },
    { lat: 48.4241252, lng: 18.3145518 },
    { lat: 48.4242407, lng: 18.3143996 },
    { lat: 48.4247878, lng: 18.3146569 },
    { lat: 48.4250205, lng: 18.3143638 },
    { lat: 48.4253078, lng: 18.3143354 },
    { lat: 48.4253975, lng: 18.3143662 },
    { lat: 48.425473, lng: 18.3144627 },
    { lat: 48.4254905, lng: 18.3146125 },
    { lat: 48.4255837, lng: 18.3146631 },
    { lat: 48.4259275, lng: 18.3147391 },
    { lat: 48.4259914, lng: 18.3146889 },
    { lat: 48.4264251, lng: 18.3151949 },
    { lat: 48.4265213, lng: 18.3152405 },
    { lat: 48.4266257, lng: 18.3151717 },
    { lat: 48.4268102, lng: 18.3154603 },
    { lat: 48.427047, lng: 18.3155571 },
    { lat: 48.427419, lng: 18.3155101 },
    { lat: 48.4274839, lng: 18.315441 },
    { lat: 48.4276632, lng: 18.3157374 },
    { lat: 48.4276558, lng: 18.3159443 },
    { lat: 48.4275728, lng: 18.3161109 },
    { lat: 48.4276547, lng: 18.3162042 },
    { lat: 48.4276459, lng: 18.3163066 },
    { lat: 48.4277342, lng: 18.3164498 },
    { lat: 48.4277863, lng: 18.3164154 },
    { lat: 48.4279552, lng: 18.3160118 },
    { lat: 48.428211, lng: 18.3158667 },
    { lat: 48.4282531, lng: 18.3156922 },
    { lat: 48.4285835, lng: 18.3155326 },
    { lat: 48.4287503, lng: 18.3156462 },
    { lat: 48.4292216, lng: 18.3155651 },
    { lat: 48.4294939, lng: 18.3157298 },
    { lat: 48.4295217, lng: 18.3157621 },
    { lat: 48.4296041, lng: 18.3159001 },
    { lat: 48.4297618, lng: 18.3158376 },
    { lat: 48.4298066, lng: 18.3157107 },
    { lat: 48.4297483, lng: 18.3156592 },
    { lat: 48.4298277, lng: 18.3154675 },
    { lat: 48.4299707, lng: 18.3154406 },
    { lat: 48.4300017, lng: 18.3153137 },
    { lat: 48.4300886, lng: 18.3153146 },
    { lat: 48.4301235, lng: 18.3150561 },
    { lat: 48.4302693, lng: 18.314858 },
    { lat: 48.4303365, lng: 18.3149091 },
    { lat: 48.4303685, lng: 18.3151565 },
    { lat: 48.4304249, lng: 18.3151755 },
    { lat: 48.4305766, lng: 18.3150072 },
    { lat: 48.430708, lng: 18.3146354 },
    { lat: 48.4308696, lng: 18.3146428 },
    { lat: 48.430893, lng: 18.3145755 },
    { lat: 48.4308514, lng: 18.3144227 },
    { lat: 48.430925, lng: 18.314307 },
    { lat: 48.4309929, lng: 18.3142912 },
    { lat: 48.4309746, lng: 18.314485 },
    { lat: 48.4310173, lng: 18.3145616 },
    { lat: 48.4312481, lng: 18.3142552 },
    { lat: 48.4313036, lng: 18.3143586 },
    { lat: 48.4312748, lng: 18.3145673 },
    { lat: 48.4313665, lng: 18.3147122 },
    { lat: 48.4318883, lng: 18.3142829 },
    { lat: 48.4319733, lng: 18.3141103 },
    { lat: 48.4322639, lng: 18.3138826 },
    { lat: 48.4323712, lng: 18.3137075 },
    { lat: 48.4325117, lng: 18.3137573 },
    { lat: 48.4325778, lng: 18.3137268 },
    { lat: 48.4326221, lng: 18.3135549 },
    { lat: 48.4327675, lng: 18.3135753 },
    { lat: 48.4328629, lng: 18.3134845 },
    { lat: 48.4328399, lng: 18.3133386 },
    { lat: 48.4328627, lng: 18.3132611 },
    { lat: 48.4329523, lng: 18.3132329 },
    { lat: 48.4329527, lng: 18.3129027 },
    { lat: 48.4330428, lng: 18.3128397 },
    { lat: 48.4330432, lng: 18.3125692 },
    { lat: 48.4331636, lng: 18.3120663 },
    { lat: 48.4335592, lng: 18.3120802 },
    { lat: 48.433649, lng: 18.3118708 },
    { lat: 48.4338002, lng: 18.3118734 },
    { lat: 48.4340056, lng: 18.3116939 },
    { lat: 48.434048, lng: 18.3115868 },
    { lat: 48.4342551, lng: 18.3114995 },
    { lat: 48.4343232, lng: 18.3113671 },
    { lat: 48.4344649, lng: 18.3113972 },
    { lat: 48.4345511, lng: 18.3112078 },
    { lat: 48.4346587, lng: 18.3112334 },
    { lat: 48.4348881, lng: 18.3109645 },
    { lat: 48.4351164, lng: 18.3108918 },
    { lat: 48.4353826, lng: 18.3106718 },
    { lat: 48.4357134, lng: 18.3106549 },
    { lat: 48.4358056, lng: 18.3105095 },
    { lat: 48.4360052, lng: 18.3104277 },
    { lat: 48.4359948, lng: 18.3102066 },
    { lat: 48.4362257, lng: 18.309844 },
    { lat: 48.4363498, lng: 18.3097801 },
    { lat: 48.4363936, lng: 18.3096405 },
    { lat: 48.4365236, lng: 18.3096437 },
    { lat: 48.4367371, lng: 18.3097893 },
    { lat: 48.4368469, lng: 18.309674 },
    { lat: 48.4370336, lng: 18.3096066 },
    { lat: 48.4371019, lng: 18.3094568 },
    { lat: 48.4373566, lng: 18.309374 },
    { lat: 48.4374171, lng: 18.3091868 },
    { lat: 48.4375305, lng: 18.3091779 },
    { lat: 48.4377783, lng: 18.3090107 },
    { lat: 48.4378872, lng: 18.308781 },
    { lat: 48.4378857, lng: 18.3086963 },
    { lat: 48.4380392, lng: 18.3084985 },
    { lat: 48.4381177, lng: 18.3084306 },
    { lat: 48.4381943, lng: 18.308511 },
    { lat: 48.4383471, lng: 18.3084569 },
    { lat: 48.4383815, lng: 18.3082307 },
    { lat: 48.4384788, lng: 18.3081745 },
    { lat: 48.4386161, lng: 18.3082493 },
    { lat: 48.4387508, lng: 18.308513 },
    { lat: 48.4389027, lng: 18.3083077 },
    { lat: 48.4389553, lng: 18.3082796 },
    { lat: 48.4390007, lng: 18.3083434 },
    { lat: 48.4391683, lng: 18.3081179 },
    { lat: 48.4391354, lng: 18.3078553 },
    { lat: 48.4392774, lng: 18.3076327 },
    { lat: 48.4392623, lng: 18.307489 },
    { lat: 48.439323, lng: 18.3074833 },
    { lat: 48.4394846, lng: 18.3072525 },
    { lat: 48.439583, lng: 18.3073159 },
    { lat: 48.4396861, lng: 18.3072252 },
    { lat: 48.4396971, lng: 18.3068397 },
    { lat: 48.4398481, lng: 18.3067179 },
    { lat: 48.4398601, lng: 18.3066133 },
    { lat: 48.4401423, lng: 18.3065742 },
    { lat: 48.4402355, lng: 18.3066252 },
    { lat: 48.4403998, lng: 18.3065205 },
    { lat: 48.4405002, lng: 18.3064192 },
    { lat: 48.4405485, lng: 18.3062371 },
    { lat: 48.4408501, lng: 18.3059293 },
    { lat: 48.4409098, lng: 18.3059436 },
    { lat: 48.4409921, lng: 18.3061431 },
    { lat: 48.4411325, lng: 18.306151 },
    { lat: 48.4410258, lng: 18.3057056 },
    { lat: 48.4507207, lng: 18.3052767 },
    { lat: 48.4549847, lng: 18.3039437 },
    { lat: 48.4582301, lng: 18.3029291 },
    { lat: 48.459998, lng: 18.301856 },
    { lat: 48.4602798, lng: 18.3016143 },
    { lat: 48.4607572, lng: 18.3013982 },
    { lat: 48.4609489, lng: 18.3014347 },
    { lat: 48.4620761, lng: 18.3006654 },
    { lat: 48.4630054, lng: 18.300202 },
    { lat: 48.463353, lng: 18.2998822 },
    { lat: 48.4647076, lng: 18.29946 },
    { lat: 48.4655171, lng: 18.2991311 },
    { lat: 48.466113, lng: 18.2986964 },
    { lat: 48.4663162, lng: 18.2983833 },
    { lat: 48.466519, lng: 18.2981787 },
    { lat: 48.4668101, lng: 18.2980211 },
    { lat: 48.4670889, lng: 18.2977278 },
    { lat: 48.4673254, lng: 18.2971647 },
    { lat: 48.4682069, lng: 18.2958036 },
    { lat: 48.4686992, lng: 18.2946579 },
    { lat: 48.4689088, lng: 18.2943163 },
    { lat: 48.4693004, lng: 18.2940816 },
    { lat: 48.4695819, lng: 18.2939924 },
    { lat: 48.4702918, lng: 18.2941566 },
    { lat: 48.471001, lng: 18.2941253 },
    { lat: 48.4714455, lng: 18.2940044 },
    { lat: 48.4720826, lng: 18.2935639 },
    { lat: 48.4724172, lng: 18.2929796 },
    { lat: 48.472753, lng: 18.2925573 },
    { lat: 48.4731987, lng: 18.2923992 },
    { lat: 48.4737973, lng: 18.2924479 },
    { lat: 48.4740516, lng: 18.292396 },
    { lat: 48.4749512, lng: 18.2919276 },
    { lat: 48.4756214, lng: 18.2918295 },
    { lat: 48.4757573, lng: 18.2916591 },
    { lat: 48.4763518, lng: 18.2915561 },
    { lat: 48.4767528, lng: 18.2913665 },
    { lat: 48.4773343, lng: 18.2899125 },
    { lat: 48.477712, lng: 18.2891714 },
    { lat: 48.4778563, lng: 18.2885166 },
    { lat: 48.4780982, lng: 18.2877706 },
    { lat: 48.4787843, lng: 18.2868048 },
    { lat: 48.4801986, lng: 18.2868151 },
    { lat: 48.480497, lng: 18.2865675 },
    { lat: 48.4809097, lng: 18.2863826 },
    { lat: 48.4815639, lng: 18.2855066 },
    { lat: 48.4824924, lng: 18.2847363 },
    { lat: 48.4825579, lng: 18.284633 },
    { lat: 48.4826903, lng: 18.2843882 },
    { lat: 48.4834457, lng: 18.2835875 },
    { lat: 48.4845149, lng: 18.2828872 },
    { lat: 48.4851416, lng: 18.2821437 },
    { lat: 48.4852997, lng: 18.2820642 },
    { lat: 48.4855855, lng: 18.2821127 },
    { lat: 48.4865393, lng: 18.2819806 },
    { lat: 48.4867439, lng: 18.2820275 },
    { lat: 48.4870342, lng: 18.2819939 },
    { lat: 48.4872964, lng: 18.2817022 },
    { lat: 48.487642, lng: 18.281048 },
    { lat: 48.487828, lng: 18.278987 },
    { lat: 48.487714, lng: 18.274632 },
    { lat: 48.487703, lng: 18.274596 },
  ],
  Topoľčianky: [
    { lat: 48.4812142, lng: 18.4171976 },
    { lat: 48.4803203, lng: 18.4167264 },
    { lat: 48.4794367, lng: 18.4159324 },
    { lat: 48.4799202, lng: 18.414838 },
    { lat: 48.4796523, lng: 18.4146862 },
    { lat: 48.479639, lng: 18.4145492 },
    { lat: 48.4795215, lng: 18.4143241 },
    { lat: 48.4792986, lng: 18.4141091 },
    { lat: 48.4788457, lng: 18.4138993 },
    { lat: 48.4785985, lng: 18.413554 },
    { lat: 48.4774686, lng: 18.4131796 },
    { lat: 48.4772043, lng: 18.412951 },
    { lat: 48.4766883, lng: 18.4128054 },
    { lat: 48.4763998, lng: 18.412486 },
    { lat: 48.4762776, lng: 18.4122506 },
    { lat: 48.4761041, lng: 18.41184 },
    { lat: 48.4757777, lng: 18.4113252 },
    { lat: 48.4760198, lng: 18.4110773 },
    { lat: 48.4763771, lng: 18.4108287 },
    { lat: 48.4767993, lng: 18.4102082 },
    { lat: 48.4768465, lng: 18.4102869 },
    { lat: 48.4770337, lng: 18.4100449 },
    { lat: 48.4772473, lng: 18.4095286 },
    { lat: 48.4774857, lng: 18.4091912 },
    { lat: 48.4780287, lng: 18.408833 },
    { lat: 48.4787627, lng: 18.4085022 },
    { lat: 48.4803585, lng: 18.4070632 },
    { lat: 48.4826177, lng: 18.405887 },
    { lat: 48.4890681, lng: 18.4018692 },
    { lat: 48.4912982, lng: 18.4012153 },
    { lat: 48.4916591, lng: 18.4015394 },
    { lat: 48.4940859, lng: 18.400068 },
    { lat: 48.4963545, lng: 18.3990074 },
    { lat: 48.4973798, lng: 18.398528 },
    { lat: 48.4974411, lng: 18.3984993 },
    { lat: 48.4985436, lng: 18.3979415 },
    { lat: 48.4996546, lng: 18.3972517 },
    { lat: 48.5005456, lng: 18.3966435 },
    { lat: 48.5029057, lng: 18.3945696 },
    { lat: 48.5033085, lng: 18.3939283 },
    { lat: 48.5039455, lng: 18.3917474 },
    { lat: 48.504487, lng: 18.390788 },
    { lat: 48.50445, lng: 18.390658 },
    { lat: 48.504119, lng: 18.390419 },
    { lat: 48.50349, lng: 18.389383 },
    { lat: 48.503385, lng: 18.389379 },
    { lat: 48.503038, lng: 18.389287 },
    { lat: 48.502419, lng: 18.38917 },
    { lat: 48.502403, lng: 18.389154 },
    { lat: 48.50235, lng: 18.389101 },
    { lat: 48.501607, lng: 18.388355 },
    { lat: 48.501554, lng: 18.388297 },
    { lat: 48.501277, lng: 18.387953 },
    { lat: 48.501083, lng: 18.387522 },
    { lat: 48.501003, lng: 18.387378 },
    { lat: 48.500713, lng: 18.387102 },
    { lat: 48.500598, lng: 18.386342 },
    { lat: 48.500505, lng: 18.385745 },
    { lat: 48.500373, lng: 18.384912 },
    { lat: 48.499876, lng: 18.382918 },
    { lat: 48.499699, lng: 18.382567 },
    { lat: 48.499416, lng: 18.382011 },
    { lat: 48.49901, lng: 18.381463 },
    { lat: 48.498783, lng: 18.380188 },
    { lat: 48.498794, lng: 18.379603 },
    { lat: 48.49879, lng: 18.379496 },
    { lat: 48.498639, lng: 18.378824 },
    { lat: 48.498629, lng: 18.378778 },
    { lat: 48.498535, lng: 18.378488 },
    { lat: 48.498394, lng: 18.378109 },
    { lat: 48.498173, lng: 18.377648 },
    { lat: 48.497628, lng: 18.376017 },
    { lat: 48.497415, lng: 18.375482 },
    { lat: 48.497052, lng: 18.374524 },
    { lat: 48.496793, lng: 18.374263 },
    { lat: 48.496466, lng: 18.373933 },
    { lat: 48.495771, lng: 18.373437 },
    { lat: 48.495538, lng: 18.373183 },
    { lat: 48.495233, lng: 18.372859 },
    { lat: 48.495187, lng: 18.372199 },
    { lat: 48.495116, lng: 18.371195 },
    { lat: 48.495087, lng: 18.370136 },
    { lat: 48.495032, lng: 18.369837 },
    { lat: 48.494762, lng: 18.369096 },
    { lat: 48.494542, lng: 18.368425 },
    { lat: 48.4942598, lng: 18.3675217 },
    { lat: 48.49426, lng: 18.367431 },
    { lat: 48.494267, lng: 18.366867 },
    { lat: 48.494246, lng: 18.366674 },
    { lat: 48.494206, lng: 18.366503 },
    { lat: 48.494123, lng: 18.366235 },
    { lat: 48.49402, lng: 18.365987 },
    { lat: 48.493914, lng: 18.365823 },
    { lat: 48.493863, lng: 18.365791 },
    { lat: 48.493635, lng: 18.365806 },
    { lat: 48.49341, lng: 18.365824 },
    { lat: 48.493143, lng: 18.365864 },
    { lat: 48.492669, lng: 18.365793 },
    { lat: 48.492449, lng: 18.365752 },
    { lat: 48.492144, lng: 18.365699 },
    { lat: 48.491997, lng: 18.365671 },
    { lat: 48.491894, lng: 18.365584 },
    { lat: 48.491669, lng: 18.365659 },
    { lat: 48.491305, lng: 18.365763 },
    { lat: 48.491078, lng: 18.365794 },
    { lat: 48.490713, lng: 18.365883 },
    { lat: 48.490327, lng: 18.366023 },
    { lat: 48.489852, lng: 18.366194 },
    { lat: 48.489766, lng: 18.366225 },
    { lat: 48.489584, lng: 18.366199 },
    { lat: 48.489174, lng: 18.366041 },
    { lat: 48.488868, lng: 18.365494 },
    { lat: 48.488762, lng: 18.365304 },
    { lat: 48.488684, lng: 18.365201 },
    { lat: 48.488104, lng: 18.365462 },
    { lat: 48.487304, lng: 18.365827 },
    { lat: 48.487135, lng: 18.365946 },
    { lat: 48.486627, lng: 18.366203 },
    { lat: 48.48632, lng: 18.366362 },
    { lat: 48.485697, lng: 18.366058 },
    { lat: 48.485683, lng: 18.366031 },
    { lat: 48.4851706, lng: 18.3665637 },
    { lat: 48.484455, lng: 18.3671179 },
    { lat: 48.483509, lng: 18.3681684 },
    { lat: 48.4831881, lng: 18.3683235 },
    { lat: 48.4828774, lng: 18.3688766 },
    { lat: 48.4822365, lng: 18.3695524 },
    { lat: 48.4819842, lng: 18.3697414 },
    { lat: 48.480911, lng: 18.3700993 },
    { lat: 48.4803449, lng: 18.370203 },
    { lat: 48.4799307, lng: 18.3701612 },
    { lat: 48.4791206, lng: 18.370221 },
    { lat: 48.4786719, lng: 18.3702845 },
    { lat: 48.4782484, lng: 18.3704376 },
    { lat: 48.4775419, lng: 18.3717174 },
    { lat: 48.4768438, lng: 18.3735656 },
    { lat: 48.4766782, lng: 18.3738491 },
    { lat: 48.4715413, lng: 18.378469 },
    { lat: 48.4706902, lng: 18.3792344 },
    { lat: 48.4702222, lng: 18.3801575 },
    { lat: 48.4700847, lng: 18.3805842 },
    { lat: 48.469964, lng: 18.3811246 },
    { lat: 48.469873, lng: 18.3818945 },
    { lat: 48.4697925, lng: 18.3820473 },
    { lat: 48.4696455, lng: 18.3828482 },
    { lat: 48.4693592, lng: 18.383752 },
    { lat: 48.469094, lng: 18.3847971 },
    { lat: 48.4684149, lng: 18.3862845 },
    { lat: 48.4680804, lng: 18.3866174 },
    { lat: 48.4678848, lng: 18.3875914 },
    { lat: 48.4666938, lng: 18.3894867 },
    { lat: 48.4665262, lng: 18.3903427 },
    { lat: 48.4665125, lng: 18.390861 },
    { lat: 48.4659956, lng: 18.3920847 },
    { lat: 48.4655964, lng: 18.3928105 },
    { lat: 48.4637149, lng: 18.3939001 },
    { lat: 48.4627897, lng: 18.3948838 },
    { lat: 48.4616883, lng: 18.395723 },
    { lat: 48.4593159, lng: 18.3983814 },
    { lat: 48.4568639, lng: 18.3991197 },
    { lat: 48.4561866, lng: 18.4009208 },
    { lat: 48.4544314, lng: 18.402698 },
    { lat: 48.4542377, lng: 18.4024606 },
    { lat: 48.4537764, lng: 18.402363 },
    { lat: 48.4536451, lng: 18.4016898 },
    { lat: 48.4513992, lng: 18.4019984 },
    { lat: 48.4500365, lng: 18.4023216 },
    { lat: 48.449755, lng: 18.4015886 },
    { lat: 48.4490837, lng: 18.4019494 },
    { lat: 48.4489759, lng: 18.404113 },
    { lat: 48.4490632, lng: 18.4042416 },
    { lat: 48.4497458, lng: 18.4044452 },
    { lat: 48.449616, lng: 18.4051663 },
    { lat: 48.4489653, lng: 18.4063424 },
    { lat: 48.4489858, lng: 18.4067244 },
    { lat: 48.4482493, lng: 18.407175 },
    { lat: 48.4473553, lng: 18.4075046 },
    { lat: 48.4467574, lng: 18.4067618 },
    { lat: 48.4467469, lng: 18.4071406 },
    { lat: 48.4463846, lng: 18.4072358 },
    { lat: 48.4459998, lng: 18.4071732 },
    { lat: 48.4456552, lng: 18.4065623 },
    { lat: 48.4456101, lng: 18.4065797 },
    { lat: 48.4450845, lng: 18.4060006 },
    { lat: 48.4446525, lng: 18.4060407 },
    { lat: 48.4442595, lng: 18.4061963 },
    { lat: 48.443243, lng: 18.4068807 },
    { lat: 48.4429119, lng: 18.4072534 },
    { lat: 48.4419814, lng: 18.4048195 },
    { lat: 48.4426628, lng: 18.404217 },
    { lat: 48.4430074, lng: 18.4041055 },
    { lat: 48.4432506, lng: 18.4046198 },
    { lat: 48.443354, lng: 18.4045698 },
    { lat: 48.4432702, lng: 18.4040403 },
    { lat: 48.4434643, lng: 18.4039013 },
    { lat: 48.4438029, lng: 18.4038233 },
    { lat: 48.4440941, lng: 18.403526 },
    { lat: 48.4442286, lng: 18.4033068 },
    { lat: 48.4443687, lng: 18.4029291 },
    { lat: 48.4445177, lng: 18.4028089 },
    { lat: 48.4444383, lng: 18.4027835 },
    { lat: 48.4440452, lng: 18.4019502 },
    { lat: 48.4443153, lng: 18.4016534 },
    { lat: 48.4449447, lng: 18.4013712 },
    { lat: 48.4452302, lng: 18.4013105 },
    { lat: 48.4453081, lng: 18.4010306 },
    { lat: 48.445486, lng: 18.4008219 },
    { lat: 48.4450392, lng: 18.400097 },
    { lat: 48.4450794, lng: 18.3994065 },
    { lat: 48.4449825, lng: 18.3993307 },
    { lat: 48.4449611, lng: 18.3987677 },
    { lat: 48.4448798, lng: 18.3985242 },
    { lat: 48.4410418, lng: 18.3998363 },
    { lat: 48.4409202, lng: 18.3998778 },
    { lat: 48.4408715, lng: 18.3998945 },
    { lat: 48.4408095, lng: 18.3998919 },
    { lat: 48.440789, lng: 18.399644 },
    { lat: 48.4406006, lng: 18.3956877 },
    { lat: 48.440246, lng: 18.3954992 },
    { lat: 48.4402039, lng: 18.3945299 },
    { lat: 48.4402424, lng: 18.3939271 },
    { lat: 48.4402178, lng: 18.3928646 },
    { lat: 48.4397289, lng: 18.3928992 },
    { lat: 48.4384955, lng: 18.393234 },
    { lat: 48.4384642, lng: 18.3926769 },
    { lat: 48.4383722, lng: 18.3926673 },
    { lat: 48.437045, lng: 18.3930434 },
    { lat: 48.4368683, lng: 18.3926657 },
    { lat: 48.4367766, lng: 18.3925999 },
    { lat: 48.4366671, lng: 18.3923214 },
    { lat: 48.4365325, lng: 18.3921715 },
    { lat: 48.4365161, lng: 18.392002 },
    { lat: 48.4364433, lng: 18.3919099 },
    { lat: 48.4361837, lng: 18.3922672 },
    { lat: 48.4356487, lng: 18.3921443 },
    { lat: 48.4353872, lng: 18.39243 },
    { lat: 48.4353434, lng: 18.3924118 },
    { lat: 48.4351837, lng: 18.3917577 },
    { lat: 48.4349761, lng: 18.3919002 },
    { lat: 48.4349259, lng: 18.3920512 },
    { lat: 48.4349413, lng: 18.3925264 },
    { lat: 48.4348738, lng: 18.3926884 },
    { lat: 48.434566, lng: 18.3928532 },
    { lat: 48.4344551, lng: 18.3930303 },
    { lat: 48.4343064, lng: 18.3928523 },
    { lat: 48.4342615, lng: 18.3925123 },
    { lat: 48.4339988, lng: 18.3924558 },
    { lat: 48.4339094, lng: 18.3927343 },
    { lat: 48.4339314, lng: 18.3929826 },
    { lat: 48.4338265, lng: 18.3932279 },
    { lat: 48.4336033, lng: 18.3932271 },
    { lat: 48.4334566, lng: 18.3930489 },
    { lat: 48.4330833, lng: 18.3930482 },
    { lat: 48.4327761, lng: 18.3934837 },
    { lat: 48.4325498, lng: 18.3933155 },
    { lat: 48.4322199, lng: 18.3931865 },
    { lat: 48.4314978, lng: 18.3931039 },
    { lat: 48.4312347, lng: 18.393198 },
    { lat: 48.4310969, lng: 18.3933786 },
    { lat: 48.4306504, lng: 18.3936032 },
    { lat: 48.4300776, lng: 18.3932676 },
    { lat: 48.4298976, lng: 18.3928734 },
    { lat: 48.4298117, lng: 18.3922715 },
    { lat: 48.4296467, lng: 18.3920654 },
    { lat: 48.4293712, lng: 18.3920389 },
    { lat: 48.4290991, lng: 18.3917942 },
    { lat: 48.4288404, lng: 18.3917289 },
    { lat: 48.428729, lng: 18.3917921 },
    { lat: 48.4287995, lng: 18.3908368 },
    { lat: 48.4287579, lng: 18.3895963 },
    { lat: 48.427652, lng: 18.3897895 },
    { lat: 48.4265941, lng: 18.3905403 },
    { lat: 48.4255955, lng: 18.3881083 },
    { lat: 48.4257047, lng: 18.3879652 },
    { lat: 48.4258347, lng: 18.3880474 },
    { lat: 48.425985, lng: 18.3880131 },
    { lat: 48.4261188, lng: 18.3878445 },
    { lat: 48.4261997, lng: 18.3875774 },
    { lat: 48.4263831, lng: 18.3874074 },
    { lat: 48.4261941, lng: 18.3869177 },
    { lat: 48.425998, lng: 18.3871 },
    { lat: 48.4258525, lng: 18.3870612 },
    { lat: 48.4260686, lng: 18.3866108 },
    { lat: 48.4257421, lng: 18.386481 },
    { lat: 48.4254282, lng: 18.3864981 },
    { lat: 48.4252505, lng: 18.3864286 },
    { lat: 48.4248076, lng: 18.3866372 },
    { lat: 48.4244396, lng: 18.38629 },
    { lat: 48.4243452, lng: 18.385312 },
    { lat: 48.4244554, lng: 18.385049 },
    { lat: 48.4246345, lng: 18.3838783 },
    { lat: 48.4250534, lng: 18.3828007 },
    { lat: 48.4253675, lng: 18.3827484 },
    { lat: 48.4253739, lng: 18.3828634 },
    { lat: 48.4255685, lng: 18.3828713 },
    { lat: 48.4257648, lng: 18.3826926 },
    { lat: 48.4259553, lng: 18.3823683 },
    { lat: 48.4258311, lng: 18.3814047 },
    { lat: 48.4257058, lng: 18.3795391 },
    { lat: 48.4259596, lng: 18.3793555 },
    { lat: 48.4262265, lng: 18.3792666 },
    { lat: 48.426492, lng: 18.3789142 },
    { lat: 48.4270594, lng: 18.3787295 },
    { lat: 48.426081, lng: 18.3766031 },
    { lat: 48.4260541, lng: 18.3765664 },
    { lat: 48.4257161, lng: 18.3771344 },
    { lat: 48.4253236, lng: 18.3772585 },
    { lat: 48.4245196, lng: 18.3777269 },
    { lat: 48.4230331, lng: 18.3780138 },
    { lat: 48.4224548, lng: 18.3784026 },
    { lat: 48.422191, lng: 18.3784464 },
    { lat: 48.4216891, lng: 18.3782694 },
    { lat: 48.4200036, lng: 18.3779106 },
    { lat: 48.416844, lng: 18.3766772 },
    { lat: 48.4160456, lng: 18.3759426 },
    { lat: 48.4156925, lng: 18.3759029 },
    { lat: 48.4153819, lng: 18.375757 },
    { lat: 48.4146753, lng: 18.3751692 },
    { lat: 48.4137295, lng: 18.3755035 },
    { lat: 48.413623, lng: 18.3744343 },
    { lat: 48.4121436, lng: 18.3750697 },
    { lat: 48.4112664, lng: 18.3753794 },
    { lat: 48.4112915, lng: 18.375585 },
    { lat: 48.4099702, lng: 18.3775259 },
    { lat: 48.4069469, lng: 18.3799942 },
    { lat: 48.4062926, lng: 18.3806387 },
    { lat: 48.4059571, lng: 18.3810289 },
    { lat: 48.4063465, lng: 18.3819165 },
    { lat: 48.4052056, lng: 18.3843575 },
    { lat: 48.4052565, lng: 18.3844015 },
    { lat: 48.4050666, lng: 18.3846727 },
    { lat: 48.4048092, lng: 18.3853498 },
    { lat: 48.4045481, lng: 18.3857657 },
    { lat: 48.4044217, lng: 18.3861076 },
    { lat: 48.403543, lng: 18.3885369 },
    { lat: 48.4032316, lng: 18.3901017 },
    { lat: 48.4026961, lng: 18.3920946 },
    { lat: 48.4023917, lng: 18.3929427 },
    { lat: 48.4020697, lng: 18.3936151 },
    { lat: 48.4013245, lng: 18.3958539 },
    { lat: 48.4011282, lng: 18.3962353 },
    { lat: 48.401111, lng: 18.3963507 },
    { lat: 48.3997452, lng: 18.3998686 },
    { lat: 48.399643, lng: 18.4001894 },
    { lat: 48.3994649, lng: 18.4012049 },
    { lat: 48.3992704, lng: 18.4022966 },
    { lat: 48.3991354, lng: 18.4028775 },
    { lat: 48.399056, lng: 18.403216 },
    { lat: 48.3986186, lng: 18.404459 },
    { lat: 48.3983103, lng: 18.4051684 },
    { lat: 48.3990075, lng: 18.4061141 },
    { lat: 48.3991557, lng: 18.4063034 },
    { lat: 48.3994006, lng: 18.4066271 },
    { lat: 48.40029, lng: 18.4078808 },
    { lat: 48.4010735, lng: 18.4092368 },
    { lat: 48.4022592, lng: 18.4114574 },
    { lat: 48.402359, lng: 18.4116199 },
    { lat: 48.4024941, lng: 18.4118249 },
    { lat: 48.4027327, lng: 18.4120909 },
    { lat: 48.402911, lng: 18.4122607 },
    { lat: 48.4030823, lng: 18.4123884 },
    { lat: 48.4031387, lng: 18.4122905 },
    { lat: 48.4032205, lng: 18.4122513 },
    { lat: 48.4033173, lng: 18.4122245 },
    { lat: 48.4034227, lng: 18.4122301 },
    { lat: 48.4035086, lng: 18.412262 },
    { lat: 48.4035187, lng: 18.4122772 },
    { lat: 48.4051571, lng: 18.4147486 },
    { lat: 48.4056202, lng: 18.4154472 },
    { lat: 48.4056681, lng: 18.4153783 },
    { lat: 48.4072015, lng: 18.416671 },
    { lat: 48.408868, lng: 18.4182662 },
    { lat: 48.4086275, lng: 18.4188899 },
    { lat: 48.4087245, lng: 18.4189678 },
    { lat: 48.4149088, lng: 18.423908 },
    { lat: 48.41524, lng: 18.4227085 },
    { lat: 48.4165321, lng: 18.4230356 },
    { lat: 48.4192085, lng: 18.4232876 },
    { lat: 48.4208682, lng: 18.4235726 },
    { lat: 48.4231512, lng: 18.4232019 },
    { lat: 48.4252487, lng: 18.4239643 },
    { lat: 48.4257766, lng: 18.4241467 },
    { lat: 48.4262192, lng: 18.424329 },
    { lat: 48.4271822, lng: 18.4247837 },
    { lat: 48.4272701, lng: 18.424854 },
    { lat: 48.4273255, lng: 18.4249259 },
    { lat: 48.4273849, lng: 18.425019 },
    { lat: 48.4275009, lng: 18.4251986 },
    { lat: 48.4277095, lng: 18.4254528 },
    { lat: 48.4279563, lng: 18.4256842 },
    { lat: 48.4286351, lng: 18.4261457 },
    { lat: 48.4288926, lng: 18.4263275 },
    { lat: 48.4291514, lng: 18.426431 },
    { lat: 48.4292941, lng: 18.4264571 },
    { lat: 48.4296104, lng: 18.4265142 },
    { lat: 48.4298738, lng: 18.4266044 },
    { lat: 48.4301653, lng: 18.4267344 },
    { lat: 48.4305175, lng: 18.4270123 },
    { lat: 48.4309385, lng: 18.4258236 },
    { lat: 48.4315006, lng: 18.4262713 },
    { lat: 48.4314718, lng: 18.4258952 },
    { lat: 48.4313835, lng: 18.4257703 },
    { lat: 48.4314163, lng: 18.4250461 },
    { lat: 48.4312469, lng: 18.4249812 },
    { lat: 48.4312499, lng: 18.4247726 },
    { lat: 48.4317197, lng: 18.424562 },
    { lat: 48.4319035, lng: 18.4243779 },
    { lat: 48.4319772, lng: 18.4242201 },
    { lat: 48.4322827, lng: 18.424297 },
    { lat: 48.4322771, lng: 18.4240359 },
    { lat: 48.4323325, lng: 18.4238766 },
    { lat: 48.4324219, lng: 18.423861 },
    { lat: 48.4324844, lng: 18.4239728 },
    { lat: 48.4325667, lng: 18.4238669 },
    { lat: 48.4326386, lng: 18.4238845 },
    { lat: 48.4328147, lng: 18.4236438 },
    { lat: 48.432931, lng: 18.4236472 },
    { lat: 48.4330232, lng: 18.4235205 },
    { lat: 48.4332946, lng: 18.423368 },
    { lat: 48.4334973, lng: 18.4230184 },
    { lat: 48.4336554, lng: 18.4230226 },
    { lat: 48.4337509, lng: 18.4228944 },
    { lat: 48.4340482, lng: 18.4230459 },
    { lat: 48.4340808, lng: 18.4226822 },
    { lat: 48.4342188, lng: 18.4225654 },
    { lat: 48.4344919, lng: 18.4224069 },
    { lat: 48.4347935, lng: 18.4223934 },
    { lat: 48.4349412, lng: 18.4222696 },
    { lat: 48.4349447, lng: 18.4220318 },
    { lat: 48.4350042, lng: 18.4219632 },
    { lat: 48.4353742, lng: 18.4220227 },
    { lat: 48.4355031, lng: 18.4219479 },
    { lat: 48.4355564, lng: 18.4217926 },
    { lat: 48.4356602, lng: 18.4217721 },
    { lat: 48.4362372, lng: 18.4220844 },
    { lat: 48.4375028, lng: 18.422222 },
    { lat: 48.4384455, lng: 18.4221741 },
    { lat: 48.4388441, lng: 18.4223007 },
    { lat: 48.4391657, lng: 18.4221638 },
    { lat: 48.4392702, lng: 18.4222132 },
    { lat: 48.4395932, lng: 18.4221984 },
    { lat: 48.4397664, lng: 18.4220696 },
    { lat: 48.4399233, lng: 18.4221318 },
    { lat: 48.4403235, lng: 18.4219829 },
    { lat: 48.4403616, lng: 18.4218186 },
    { lat: 48.4404297, lng: 18.4217638 },
    { lat: 48.4406238, lng: 18.4219468 },
    { lat: 48.440739, lng: 18.4218093 },
    { lat: 48.4410174, lng: 18.4217433 },
    { lat: 48.4411561, lng: 18.4215602 },
    { lat: 48.4415368, lng: 18.4214301 },
    { lat: 48.4417519, lng: 18.4215027 },
    { lat: 48.4424174, lng: 18.42146 },
    { lat: 48.4425259, lng: 18.4212835 },
    { lat: 48.4426574, lng: 18.4212126 },
    { lat: 48.4429096, lng: 18.421245 },
    { lat: 48.4430002, lng: 18.4213722 },
    { lat: 48.4431484, lng: 18.4213176 },
    { lat: 48.4432145, lng: 18.4214336 },
    { lat: 48.4434019, lng: 18.4212938 },
    { lat: 48.4440679, lng: 18.4212982 },
    { lat: 48.4444036, lng: 18.4215955 },
    { lat: 48.4448651, lng: 18.421798 },
    { lat: 48.4453902, lng: 18.4221891 },
    { lat: 48.4455719, lng: 18.4222336 },
    { lat: 48.4457624, lng: 18.4223731 },
    { lat: 48.445922, lng: 18.4222214 },
    { lat: 48.445913, lng: 18.4218775 },
    { lat: 48.4460236, lng: 18.4216525 },
    { lat: 48.4462336, lng: 18.4214551 },
    { lat: 48.4462975, lng: 18.4213081 },
    { lat: 48.4464977, lng: 18.4212359 },
    { lat: 48.4465052, lng: 18.4210878 },
    { lat: 48.4468196, lng: 18.4208371 },
    { lat: 48.4468653, lng: 18.420686 },
    { lat: 48.4470386, lng: 18.4206396 },
    { lat: 48.4471016, lng: 18.4205581 },
    { lat: 48.4474969, lng: 18.4193522 },
    { lat: 48.4474923, lng: 18.419168 },
    { lat: 48.4476351, lng: 18.4188397 },
    { lat: 48.4476219, lng: 18.418721 },
    { lat: 48.4479026, lng: 18.418596 },
    { lat: 48.4480165, lng: 18.418637 },
    { lat: 48.4482873, lng: 18.4181739 },
    { lat: 48.4540367, lng: 18.4185234 },
    { lat: 48.4539567, lng: 18.4168894 },
    { lat: 48.4588016, lng: 18.4165975 },
    { lat: 48.4594957, lng: 18.4166246 },
    { lat: 48.4600191, lng: 18.4168275 },
    { lat: 48.4617811, lng: 18.4168618 },
    { lat: 48.4639753, lng: 18.4184753 },
    { lat: 48.4649536, lng: 18.4187814 },
    { lat: 48.4719923, lng: 18.4174761 },
    { lat: 48.4728536, lng: 18.4196647 },
    { lat: 48.476432, lng: 18.4238255 },
    { lat: 48.47893, lng: 18.4258479 },
    { lat: 48.4802079, lng: 18.4207309 },
    { lat: 48.481196, lng: 18.4172575 },
    { lat: 48.4812142, lng: 18.4171976 },
  ],
  Žitavany: [
    { lat: 48.4028405, lng: 18.4225065 },
    { lat: 48.4028472, lng: 18.4224655 },
    { lat: 48.4028735, lng: 18.422513 },
    { lat: 48.4029787, lng: 18.4224965 },
    { lat: 48.4032382, lng: 18.4221138 },
    { lat: 48.4034526, lng: 18.4220927 },
    { lat: 48.4037285, lng: 18.4222451 },
    { lat: 48.4038074, lng: 18.4225877 },
    { lat: 48.403949, lng: 18.4227612 },
    { lat: 48.4041077, lng: 18.4228338 },
    { lat: 48.4043603, lng: 18.4228151 },
    { lat: 48.4045405, lng: 18.4226244 },
    { lat: 48.404779, lng: 18.4225745 },
    { lat: 48.4048907, lng: 18.422614 },
    { lat: 48.4050072, lng: 18.4226973 },
    { lat: 48.4050167, lng: 18.423011 },
    { lat: 48.4050938, lng: 18.4232989 },
    { lat: 48.4054403, lng: 18.4237082 },
    { lat: 48.405544, lng: 18.4239863 },
    { lat: 48.4057637, lng: 18.4237377 },
    { lat: 48.4060538, lng: 18.4236845 },
    { lat: 48.4061754, lng: 18.4238204 },
    { lat: 48.4061589, lng: 18.4241892 },
    { lat: 48.4073916, lng: 18.4214589 },
    { lat: 48.4086275, lng: 18.4188899 },
    { lat: 48.408868, lng: 18.4182662 },
    { lat: 48.4072015, lng: 18.416671 },
    { lat: 48.4056681, lng: 18.4153783 },
    { lat: 48.4056202, lng: 18.4154472 },
    { lat: 48.4051571, lng: 18.4147486 },
    { lat: 48.4035187, lng: 18.4122772 },
    { lat: 48.4035086, lng: 18.412262 },
    { lat: 48.4034227, lng: 18.4122301 },
    { lat: 48.4033173, lng: 18.4122245 },
    { lat: 48.4032205, lng: 18.4122513 },
    { lat: 48.4031387, lng: 18.4122905 },
    { lat: 48.4030823, lng: 18.4123884 },
    { lat: 48.402911, lng: 18.4122607 },
    { lat: 48.4027327, lng: 18.4120909 },
    { lat: 48.4024941, lng: 18.4118249 },
    { lat: 48.402359, lng: 18.4116199 },
    { lat: 48.4022592, lng: 18.4114574 },
    { lat: 48.4010735, lng: 18.4092368 },
    { lat: 48.40029, lng: 18.4078808 },
    { lat: 48.3994006, lng: 18.4066271 },
    { lat: 48.3991557, lng: 18.4063034 },
    { lat: 48.3986364, lng: 18.4072612 },
    { lat: 48.3975085, lng: 18.4093413 },
    { lat: 48.3971252, lng: 18.4087025 },
    { lat: 48.3970022, lng: 18.4082807 },
    { lat: 48.3967743, lng: 18.4078533 },
    { lat: 48.3966632, lng: 18.4075042 },
    { lat: 48.3962789, lng: 18.4067689 },
    { lat: 48.3958711, lng: 18.4064942 },
    { lat: 48.3946684, lng: 18.4060531 },
    { lat: 48.394548, lng: 18.4057751 },
    { lat: 48.3945284, lng: 18.4056072 },
    { lat: 48.3945998, lng: 18.4053706 },
    { lat: 48.3943317, lng: 18.4050797 },
    { lat: 48.3942789, lng: 18.4050814 },
    { lat: 48.3941359, lng: 18.405305 },
    { lat: 48.3938817, lng: 18.4050599 },
    { lat: 48.3937277, lng: 18.4052096 },
    { lat: 48.3933809, lng: 18.4047803 },
    { lat: 48.3928807, lng: 18.4044314 },
    { lat: 48.3926365, lng: 18.4045028 },
    { lat: 48.392274, lng: 18.4044552 },
    { lat: 48.3921998, lng: 18.4045395 },
    { lat: 48.3920927, lng: 18.4045403 },
    { lat: 48.3920009, lng: 18.4045349 },
    { lat: 48.3919307, lng: 18.4043868 },
    { lat: 48.3918141, lng: 18.4048908 },
    { lat: 48.3917459, lng: 18.4051856 },
    { lat: 48.3915282, lng: 18.4061263 },
    { lat: 48.3911339, lng: 18.407421 },
    { lat: 48.3911353, lng: 18.4079309 },
    { lat: 48.3910005, lng: 18.4095917 },
    { lat: 48.3906874, lng: 18.4105637 },
    { lat: 48.3905271, lng: 18.4119736 },
    { lat: 48.389697, lng: 18.4133687 },
    { lat: 48.3886603, lng: 18.4148795 },
    { lat: 48.3881232, lng: 18.4157662 },
    { lat: 48.3878317, lng: 18.416505 },
    { lat: 48.3878065, lng: 18.4172826 },
    { lat: 48.3875333, lng: 18.4169562 },
    { lat: 48.3872992, lng: 18.4168867 },
    { lat: 48.3840009, lng: 18.4173184 },
    { lat: 48.3826914, lng: 18.4172615 },
    { lat: 48.382272, lng: 18.4171866 },
    { lat: 48.3816408, lng: 18.4168947 },
    { lat: 48.3811415, lng: 18.4167422 },
    { lat: 48.3778971, lng: 18.4167253 },
    { lat: 48.3754893, lng: 18.4168999 },
    { lat: 48.3745617, lng: 18.4168959 },
    { lat: 48.3745045, lng: 18.4168843 },
    { lat: 48.3744374, lng: 18.4183618 },
    { lat: 48.3743588, lng: 18.4187947 },
    { lat: 48.3740837, lng: 18.4197856 },
    { lat: 48.373936, lng: 18.4206732 },
    { lat: 48.3737191, lng: 18.4234292 },
    { lat: 48.3731634, lng: 18.4259209 },
    { lat: 48.3730462, lng: 18.4264465 },
    { lat: 48.3727008, lng: 18.4299353 },
    { lat: 48.3724075, lng: 18.4320567 },
    { lat: 48.3724703, lng: 18.432068 },
    { lat: 48.3725107, lng: 18.4321637 },
    { lat: 48.3742039, lng: 18.4364026 },
    { lat: 48.3751984, lng: 18.4411462 },
    { lat: 48.3771231, lng: 18.4400953 },
    { lat: 48.3779916, lng: 18.4401526 },
    { lat: 48.3778699, lng: 18.4412888 },
    { lat: 48.3777113, lng: 18.4440493 },
    { lat: 48.3774707, lng: 18.4450351 },
    { lat: 48.3770668, lng: 18.4454396 },
    { lat: 48.3759013, lng: 18.4453745 },
    { lat: 48.3749996, lng: 18.4458148 },
    { lat: 48.3741834, lng: 18.4464105 },
    { lat: 48.3726992, lng: 18.4477354 },
    { lat: 48.3708133, lng: 18.4487939 },
    { lat: 48.3709677, lng: 18.4493386 },
    { lat: 48.3708257, lng: 18.4493224 },
    { lat: 48.3708142, lng: 18.4494593 },
    { lat: 48.3709822, lng: 18.4496192 },
    { lat: 48.3709253, lng: 18.4498119 },
    { lat: 48.3710176, lng: 18.450093 },
    { lat: 48.3712017, lng: 18.4504187 },
    { lat: 48.3712382, lng: 18.4507902 },
    { lat: 48.3714445, lng: 18.4511326 },
    { lat: 48.3715782, lng: 18.4512241 },
    { lat: 48.3715675, lng: 18.4515199 },
    { lat: 48.3716156, lng: 18.4516533 },
    { lat: 48.3722112, lng: 18.4526668 },
    { lat: 48.372288, lng: 18.4530088 },
    { lat: 48.3724662, lng: 18.4534157 },
    { lat: 48.3723967, lng: 18.4536246 },
    { lat: 48.3724582, lng: 18.4538375 },
    { lat: 48.3724285, lng: 18.4543181 },
    { lat: 48.3724733, lng: 18.4543931 },
    { lat: 48.3724224, lng: 18.4546968 },
    { lat: 48.3724832, lng: 18.4552796 },
    { lat: 48.3724242, lng: 18.4554782 },
    { lat: 48.3725138, lng: 18.4561578 },
    { lat: 48.372526, lng: 18.4568605 },
    { lat: 48.3726193, lng: 18.4573818 },
    { lat: 48.3726513, lng: 18.4581016 },
    { lat: 48.3726185, lng: 18.4583196 },
    { lat: 48.3730526, lng: 18.4594939 },
    { lat: 48.3735328, lng: 18.4641333 },
    { lat: 48.3736393, lng: 18.4648502 },
    { lat: 48.3735224, lng: 18.4653676 },
    { lat: 48.3734665, lng: 18.4663695 },
    { lat: 48.3736553, lng: 18.4665201 },
    { lat: 48.3741514, lng: 18.4666979 },
    { lat: 48.3742617, lng: 18.4669559 },
    { lat: 48.374319, lng: 18.4674217 },
    { lat: 48.3744459, lng: 18.4677943 },
    { lat: 48.3747617, lng: 18.4683644 },
    { lat: 48.3748545, lng: 18.4686526 },
    { lat: 48.3753809, lng: 18.4699457 },
    { lat: 48.3756045, lng: 18.471005 },
    { lat: 48.3758593, lng: 18.4713712 },
    { lat: 48.3762531, lng: 18.472324 },
    { lat: 48.3765182, lng: 18.4733021 },
    { lat: 48.3765466, lng: 18.4738944 },
    { lat: 48.3767643, lng: 18.4744423 },
    { lat: 48.3771877, lng: 18.4747325 },
    { lat: 48.3776701, lng: 18.47546 },
    { lat: 48.3779384, lng: 18.4759207 },
    { lat: 48.378034, lng: 18.4762207 },
    { lat: 48.3784082, lng: 18.4766629 },
    { lat: 48.3785421, lng: 18.4772065 },
    { lat: 48.3786302, lng: 18.4773725 },
    { lat: 48.3786582, lng: 18.4778548 },
    { lat: 48.378718, lng: 18.4780383 },
    { lat: 48.3787023, lng: 18.4782024 },
    { lat: 48.3788126, lng: 18.4788103 },
    { lat: 48.3788254, lng: 18.4797104 },
    { lat: 48.3785385, lng: 18.4806202 },
    { lat: 48.3786798, lng: 18.4810763 },
    { lat: 48.3789432, lng: 18.4813074 },
    { lat: 48.3791064, lng: 18.4818725 },
    { lat: 48.3792891, lng: 18.4819906 },
    { lat: 48.3797205, lng: 18.4820417 },
    { lat: 48.3802074, lng: 18.4819735 },
    { lat: 48.3815449, lng: 18.4825364 },
    { lat: 48.3825617, lng: 18.4832537 },
    { lat: 48.3829525, lng: 18.483772 },
    { lat: 48.3829965, lng: 18.4838364 },
    { lat: 48.3835159, lng: 18.4839863 },
    { lat: 48.383858, lng: 18.4843755 },
    { lat: 48.3840193, lng: 18.4849487 },
    { lat: 48.3841271, lng: 18.4850218 },
    { lat: 48.3842115, lng: 18.4856058 },
    { lat: 48.3844041, lng: 18.4859093 },
    { lat: 48.3844027, lng: 18.4862501 },
    { lat: 48.3845574, lng: 18.4869055 },
    { lat: 48.3847616, lng: 18.486985 },
    { lat: 48.384796, lng: 18.487078 },
    { lat: 48.3850137, lng: 18.487219 },
    { lat: 48.3853099, lng: 18.4872701 },
    { lat: 48.3855685, lng: 18.4874618 },
    { lat: 48.3857507, lng: 18.4874685 },
    { lat: 48.3859079, lng: 18.48778 },
    { lat: 48.3862883, lng: 18.4879902 },
    { lat: 48.3863683, lng: 18.4881122 },
    { lat: 48.3867064, lng: 18.4881854 },
    { lat: 48.3868011, lng: 18.488351 },
    { lat: 48.387152, lng: 18.4886132 },
    { lat: 48.3872155, lng: 18.4887231 },
    { lat: 48.3877305, lng: 18.488837 },
    { lat: 48.3880804, lng: 18.4891018 },
    { lat: 48.3883088, lng: 18.4890733 },
    { lat: 48.388667, lng: 18.4894495 },
    { lat: 48.3888766, lng: 18.4895291 },
    { lat: 48.3889881, lng: 18.489687 },
    { lat: 48.3890617, lng: 18.4896533 },
    { lat: 48.3891959, lng: 18.4898357 },
    { lat: 48.3893345, lng: 18.489894 },
    { lat: 48.3894471, lng: 18.4900525 },
    { lat: 48.3894346, lng: 18.4901129 },
    { lat: 48.3897459, lng: 18.4902959 },
    { lat: 48.3901177, lng: 18.4903255 },
    { lat: 48.3904147, lng: 18.4905972 },
    { lat: 48.3905162, lng: 18.4905984 },
    { lat: 48.3906202, lng: 18.4907221 },
    { lat: 48.3907985, lng: 18.4907652 },
    { lat: 48.3907893, lng: 18.4909449 },
    { lat: 48.3909773, lng: 18.4912252 },
    { lat: 48.3911289, lng: 18.4910668 },
    { lat: 48.3913846, lng: 18.4911223 },
    { lat: 48.39165, lng: 18.4916173 },
    { lat: 48.3919667, lng: 18.4919618 },
    { lat: 48.392422, lng: 18.4927047 },
    { lat: 48.3942454, lng: 18.494469 },
    { lat: 48.3961205, lng: 18.4964115 },
    { lat: 48.3970152, lng: 18.4974764 },
    { lat: 48.3978059, lng: 18.4982288 },
    { lat: 48.3978805, lng: 18.4983135 },
    { lat: 48.3992685, lng: 18.499733 },
    { lat: 48.3994713, lng: 18.5000326 },
    { lat: 48.3996215, lng: 18.5001197 },
    { lat: 48.4001856, lng: 18.5000572 },
    { lat: 48.4006444, lng: 18.4998247 },
    { lat: 48.4010357, lng: 18.4998431 },
    { lat: 48.4015865, lng: 18.5000968 },
    { lat: 48.4021071, lng: 18.5004526 },
    { lat: 48.4023281, lng: 18.500636 },
    { lat: 48.4029014, lng: 18.4985975 },
    { lat: 48.4027056, lng: 18.4975263 },
    { lat: 48.4028496, lng: 18.4966372 },
    { lat: 48.4030936, lng: 18.4960952 },
    { lat: 48.4041048, lng: 18.4947759 },
    { lat: 48.4043568, lng: 18.49407 },
    { lat: 48.4050084, lng: 18.4934274 },
    { lat: 48.4055812, lng: 18.492586 },
    { lat: 48.4057452, lng: 18.4924732 },
    { lat: 48.4059288, lng: 18.492652 },
    { lat: 48.4062717, lng: 18.4926713 },
    { lat: 48.4065906, lng: 18.4929926 },
    { lat: 48.406793, lng: 18.4929379 },
    { lat: 48.4068388, lng: 18.4929322 },
    { lat: 48.4063605, lng: 18.4908496 },
    { lat: 48.4056044, lng: 18.4870531 },
    { lat: 48.4042411, lng: 18.4793435 },
    { lat: 48.4039336, lng: 18.4773562 },
    { lat: 48.403548, lng: 18.4756293 },
    { lat: 48.4033094, lng: 18.4741358 },
    { lat: 48.4028605, lng: 18.4704753 },
    { lat: 48.4024601, lng: 18.4680605 },
    { lat: 48.4017465, lng: 18.462972 },
    { lat: 48.4000561, lng: 18.4527791 },
    { lat: 48.399532, lng: 18.4498273 },
    { lat: 48.3993633, lng: 18.4492464 },
    { lat: 48.3992476, lng: 18.4486294 },
    { lat: 48.3992214, lng: 18.4482621 },
    { lat: 48.3993934, lng: 18.4462049 },
    { lat: 48.3993973, lng: 18.445465 },
    { lat: 48.3993511, lng: 18.4449788 },
    { lat: 48.3990125, lng: 18.4438413 },
    { lat: 48.3989524, lng: 18.4433529 },
    { lat: 48.3988597, lng: 18.4430046 },
    { lat: 48.3984376, lng: 18.4418872 },
    { lat: 48.398409, lng: 18.4416583 },
    { lat: 48.3984075, lng: 18.4414281 },
    { lat: 48.3991953, lng: 18.4414171 },
    { lat: 48.3999854, lng: 18.4418247 },
    { lat: 48.4007522, lng: 18.4424894 },
    { lat: 48.4009613, lng: 18.442313 },
    { lat: 48.4007962, lng: 18.4419216 },
    { lat: 48.4007869, lng: 18.4412873 },
    { lat: 48.4006274, lng: 18.4407972 },
    { lat: 48.4006169, lng: 18.4404052 },
    { lat: 48.4005119, lng: 18.4399178 },
    { lat: 48.4003871, lng: 18.4395824 },
    { lat: 48.4003279, lng: 18.4391485 },
    { lat: 48.4001649, lng: 18.4388968 },
    { lat: 48.3999912, lng: 18.4383825 },
    { lat: 48.3999797, lng: 18.4383485 },
    { lat: 48.4000446, lng: 18.4378504 },
    { lat: 48.4000153, lng: 18.4372059 },
    { lat: 48.4000471, lng: 18.4364595 },
    { lat: 48.4004906, lng: 18.435616 },
    { lat: 48.4004771, lng: 18.4348437 },
    { lat: 48.4008498, lng: 18.4338646 },
    { lat: 48.4013131, lng: 18.4335755 },
    { lat: 48.4015391, lng: 18.4331984 },
    { lat: 48.4018242, lng: 18.4330507 },
    { lat: 48.4019815, lng: 18.4328363 },
    { lat: 48.402525, lng: 18.4323444 },
    { lat: 48.4029127, lng: 18.4321582 },
    { lat: 48.4030508, lng: 18.4319863 },
    { lat: 48.4032002, lng: 18.431648 },
    { lat: 48.4034962, lng: 18.4313562 },
    { lat: 48.4036708, lng: 18.4309259 },
    { lat: 48.4036752, lng: 18.4303569 },
    { lat: 48.4038768, lng: 18.4297468 },
    { lat: 48.4039381, lng: 18.4298548 },
    { lat: 48.4041507, lng: 18.4292962 },
    { lat: 48.4042438, lng: 18.4292283 },
    { lat: 48.4046172, lng: 18.4277707 },
    { lat: 48.4040267, lng: 18.4272966 },
    { lat: 48.4039016, lng: 18.4270798 },
    { lat: 48.4036507, lng: 18.4268675 },
    { lat: 48.4038255, lng: 18.4265267 },
    { lat: 48.4038967, lng: 18.4262067 },
    { lat: 48.4038632, lng: 18.4260682 },
    { lat: 48.4038791, lng: 18.4256288 },
    { lat: 48.4032329, lng: 18.4248436 },
    { lat: 48.4025706, lng: 18.4243977 },
    { lat: 48.4024418, lng: 18.4241739 },
    { lat: 48.4027102, lng: 18.4235458 },
    { lat: 48.4028225, lng: 18.4226078 },
    { lat: 48.4028405, lng: 18.4225065 },
  ],
  Choča: [
    { lat: 48.363097, lng: 18.3386176 },
    { lat: 48.3639345, lng: 18.3391029 },
    { lat: 48.3645893, lng: 18.3393301 },
    { lat: 48.364941, lng: 18.3393256 },
    { lat: 48.364509, lng: 18.336085 },
    { lat: 48.3643535, lng: 18.3344146 },
    { lat: 48.3639238, lng: 18.33428 },
    { lat: 48.3636249, lng: 18.3316959 },
    { lat: 48.363602, lng: 18.3309963 },
    { lat: 48.3638144, lng: 18.3272459 },
    { lat: 48.3640599, lng: 18.3271971 },
    { lat: 48.3640463, lng: 18.3253691 },
    { lat: 48.3638578, lng: 18.3241944 },
    { lat: 48.3639637, lng: 18.3237572 },
    { lat: 48.3639477, lng: 18.3229599 },
    { lat: 48.3638841, lng: 18.3220189 },
    { lat: 48.3636655, lng: 18.3148852 },
    { lat: 48.3635663, lng: 18.3129631 },
    { lat: 48.3646368, lng: 18.3122433 },
    { lat: 48.3654528, lng: 18.3115135 },
    { lat: 48.3660964, lng: 18.3110648 },
    { lat: 48.3662445, lng: 18.3109546 },
    { lat: 48.3662273, lng: 18.3109097 },
    { lat: 48.3652502, lng: 18.3083334 },
    { lat: 48.3645653, lng: 18.3075799 },
    { lat: 48.3629884, lng: 18.3068548 },
    { lat: 48.3626916, lng: 18.3077664 },
    { lat: 48.3625045, lng: 18.3085426 },
    { lat: 48.3624107, lng: 18.309211 },
    { lat: 48.3624032, lng: 18.3101096 },
    { lat: 48.361741, lng: 18.3102133 },
    { lat: 48.3607754, lng: 18.3100192 },
    { lat: 48.3607157, lng: 18.3103575 },
    { lat: 48.3598316, lng: 18.3100068 },
    { lat: 48.3586082, lng: 18.3092076 },
    { lat: 48.358569, lng: 18.3093123 },
    { lat: 48.3579356, lng: 18.3086539 },
    { lat: 48.3576209, lng: 18.3081383 },
    { lat: 48.357154, lng: 18.3067366 },
    { lat: 48.3566967, lng: 18.3055628 },
    { lat: 48.356676, lng: 18.3054562 },
    { lat: 48.3560814, lng: 18.3058922 },
    { lat: 48.3567044, lng: 18.3111049 },
    { lat: 48.354156, lng: 18.3123231 },
    { lat: 48.3534484, lng: 18.3124358 },
    { lat: 48.353015, lng: 18.312415 },
    { lat: 48.350928, lng: 18.3116684 },
    { lat: 48.3507359, lng: 18.3115823 },
    { lat: 48.3507546, lng: 18.3125873 },
    { lat: 48.3507865, lng: 18.313244 },
    { lat: 48.3509944, lng: 18.3138463 },
    { lat: 48.3523986, lng: 18.3163527 },
    { lat: 48.3498543, lng: 18.3174434 },
    { lat: 48.3502655, lng: 18.3180473 },
    { lat: 48.3504455, lng: 18.3184144 },
    { lat: 48.3502751, lng: 18.3184735 },
    { lat: 48.3495462, lng: 18.318454 },
    { lat: 48.3489252, lng: 18.3186261 },
    { lat: 48.3474005, lng: 18.3193533 },
    { lat: 48.3472128, lng: 18.3194256 },
    { lat: 48.3471984, lng: 18.3193512 },
    { lat: 48.3437031, lng: 18.3209778 },
    { lat: 48.3435246, lng: 18.3204201 },
    { lat: 48.340743, lng: 18.3217598 },
    { lat: 48.3407805, lng: 18.3221036 },
    { lat: 48.3410338, lng: 18.3236796 },
    { lat: 48.341523, lng: 18.3265084 },
    { lat: 48.341653, lng: 18.3270486 },
    { lat: 48.3416933, lng: 18.3270477 },
    { lat: 48.3420572, lng: 18.3281665 },
    { lat: 48.342198, lng: 18.3297345 },
    { lat: 48.3422525, lng: 18.3298978 },
    { lat: 48.3426275, lng: 18.3299031 },
    { lat: 48.3428371, lng: 18.3298426 },
    { lat: 48.3433658, lng: 18.3322364 },
    { lat: 48.3437949, lng: 18.3336324 },
    { lat: 48.3440079, lng: 18.3334903 },
    { lat: 48.3441719, lng: 18.3340141 },
    { lat: 48.3446045, lng: 18.3337454 },
    { lat: 48.3450211, lng: 18.335258 },
    { lat: 48.3452912, lng: 18.335785 },
    { lat: 48.3457688, lng: 18.3369845 },
    { lat: 48.3461912, lng: 18.338162 },
    { lat: 48.3465142, lng: 18.3392267 },
    { lat: 48.3484253, lng: 18.3382486 },
    { lat: 48.3490208, lng: 18.339397 },
    { lat: 48.3493723, lng: 18.3398831 },
    { lat: 48.3496789, lng: 18.3405959 },
    { lat: 48.3499139, lng: 18.3413374 },
    { lat: 48.3500981, lng: 18.3411263 },
    { lat: 48.3502491, lng: 18.3410623 },
    { lat: 48.350297, lng: 18.3409725 },
    { lat: 48.3507276, lng: 18.3406661 },
    { lat: 48.3508426, lng: 18.3404467 },
    { lat: 48.3511656, lng: 18.3401345 },
    { lat: 48.3512835, lng: 18.3401404 },
    { lat: 48.3516543, lng: 18.3395596 },
    { lat: 48.3521171, lng: 18.3391639 },
    { lat: 48.3521448, lng: 18.3392356 },
    { lat: 48.3525091, lng: 18.3390319 },
    { lat: 48.3538303, lng: 18.3385157 },
    { lat: 48.3543218, lng: 18.3384097 },
    { lat: 48.3543683, lng: 18.3384715 },
    { lat: 48.3547436, lng: 18.3384065 },
    { lat: 48.354892, lng: 18.3384543 },
    { lat: 48.3549212, lng: 18.3385531 },
    { lat: 48.355217, lng: 18.3385167 },
    { lat: 48.3557142, lng: 18.3386491 },
    { lat: 48.3560062, lng: 18.3388239 },
    { lat: 48.356449, lng: 18.3387163 },
    { lat: 48.3568213, lng: 18.3385351 },
    { lat: 48.3570774, lng: 18.3387947 },
    { lat: 48.3575292, lng: 18.338781 },
    { lat: 48.3583384, lng: 18.3383677 },
    { lat: 48.3586843, lng: 18.3383377 },
    { lat: 48.3589409, lng: 18.3381485 },
    { lat: 48.3593661, lng: 18.3380286 },
    { lat: 48.3597553, lng: 18.337788 },
    { lat: 48.3602513, lng: 18.3378208 },
    { lat: 48.3602912, lng: 18.3379867 },
    { lat: 48.3603906, lng: 18.3380099 },
    { lat: 48.3610433, lng: 18.3378996 },
    { lat: 48.3613365, lng: 18.3380541 },
    { lat: 48.3615671, lng: 18.3380856 },
    { lat: 48.3618478, lng: 18.3380771 },
    { lat: 48.3622429, lng: 18.3379451 },
    { lat: 48.3624537, lng: 18.338059 },
    { lat: 48.3628069, lng: 18.3384187 },
    { lat: 48.3629888, lng: 18.338503 },
    { lat: 48.363097, lng: 18.3386176 },
  ],
  Mankovce: [
    { lat: 48.4116736, lng: 18.340004 },
    { lat: 48.4117118, lng: 18.3400183 },
    { lat: 48.4118042, lng: 18.339917 },
    { lat: 48.4118144, lng: 18.3394789 },
    { lat: 48.4116806, lng: 18.3392882 },
    { lat: 48.4117774, lng: 18.3392066 },
    { lat: 48.4120757, lng: 18.3392548 },
    { lat: 48.412236, lng: 18.3389545 },
    { lat: 48.4123526, lng: 18.3389428 },
    { lat: 48.4123923, lng: 18.3385495 },
    { lat: 48.4126922, lng: 18.3383272 },
    { lat: 48.413083, lng: 18.3396283 },
    { lat: 48.4134042, lng: 18.3394228 },
    { lat: 48.4139774, lng: 18.3415163 },
    { lat: 48.4159313, lng: 18.3403105 },
    { lat: 48.4164449, lng: 18.340121 },
    { lat: 48.4165715, lng: 18.3407019 },
    { lat: 48.4188629, lng: 18.3395048 },
    { lat: 48.4189756, lng: 18.3406 },
    { lat: 48.4196927, lng: 18.3402707 },
    { lat: 48.4196414, lng: 18.340024 },
    { lat: 48.4197606, lng: 18.3399374 },
    { lat: 48.4210437, lng: 18.3393447 },
    { lat: 48.421141, lng: 18.3398658 },
    { lat: 48.4231318, lng: 18.3389397 },
    { lat: 48.4235145, lng: 18.3405588 },
    { lat: 48.4239825, lng: 18.3404159 },
    { lat: 48.4241407, lng: 18.3411381 },
    { lat: 48.4255633, lng: 18.340378 },
    { lat: 48.4260918, lng: 18.3419591 },
    { lat: 48.4267008, lng: 18.3416358 },
    { lat: 48.4265843, lng: 18.3412116 },
    { lat: 48.4280118, lng: 18.3404008 },
    { lat: 48.4310877, lng: 18.3388694 },
    { lat: 48.4312281, lng: 18.3388383 },
    { lat: 48.4313444, lng: 18.3390604 },
    { lat: 48.4350043, lng: 18.3367637 },
    { lat: 48.4354119, lng: 18.3356961 },
    { lat: 48.4364705, lng: 18.3334108 },
    { lat: 48.4355605, lng: 18.3319258 },
    { lat: 48.4344817, lng: 18.3310087 },
    { lat: 48.4337124, lng: 18.3299546 },
    { lat: 48.4334743, lng: 18.3287419 },
    { lat: 48.4333375, lng: 18.3275429 },
    { lat: 48.43336, lng: 18.3269265 },
    { lat: 48.4332942, lng: 18.3268585 },
    { lat: 48.4332154, lng: 18.3269453 },
    { lat: 48.4332015, lng: 18.3268576 },
    { lat: 48.4330283, lng: 18.3267892 },
    { lat: 48.4329384, lng: 18.326676 },
    { lat: 48.4326997, lng: 18.3266017 },
    { lat: 48.4323189, lng: 18.326632 },
    { lat: 48.4322288, lng: 18.3267363 },
    { lat: 48.4321524, lng: 18.3267028 },
    { lat: 48.4320981, lng: 18.3267999 },
    { lat: 48.4320926, lng: 18.3269623 },
    { lat: 48.4319255, lng: 18.3271585 },
    { lat: 48.4317885, lng: 18.3270275 },
    { lat: 48.4315365, lng: 18.3271405 },
    { lat: 48.4311996, lng: 18.3271278 },
    { lat: 48.4311325, lng: 18.3270629 },
    { lat: 48.4308157, lng: 18.3271255 },
    { lat: 48.4312769, lng: 18.3236408 },
    { lat: 48.4312976, lng: 18.3230715 },
    { lat: 48.4312541, lng: 18.3226216 },
    { lat: 48.4309802, lng: 18.3217707 },
    { lat: 48.4302176, lng: 18.3199663 },
    { lat: 48.4299348, lng: 18.3190576 },
    { lat: 48.4297917, lng: 18.3181696 },
    { lat: 48.4295221, lng: 18.3157898 },
    { lat: 48.4295217, lng: 18.3157621 },
    { lat: 48.4294939, lng: 18.3157298 },
    { lat: 48.4292216, lng: 18.3155651 },
    { lat: 48.4287503, lng: 18.3156462 },
    { lat: 48.4285835, lng: 18.3155326 },
    { lat: 48.4282531, lng: 18.3156922 },
    { lat: 48.428211, lng: 18.3158667 },
    { lat: 48.4279552, lng: 18.3160118 },
    { lat: 48.4277863, lng: 18.3164154 },
    { lat: 48.4277342, lng: 18.3164498 },
    { lat: 48.4276459, lng: 18.3163066 },
    { lat: 48.4276547, lng: 18.3162042 },
    { lat: 48.4275728, lng: 18.3161109 },
    { lat: 48.4276558, lng: 18.3159443 },
    { lat: 48.4276632, lng: 18.3157374 },
    { lat: 48.4274839, lng: 18.315441 },
    { lat: 48.427419, lng: 18.3155101 },
    { lat: 48.427047, lng: 18.3155571 },
    { lat: 48.4268102, lng: 18.3154603 },
    { lat: 48.4266257, lng: 18.3151717 },
    { lat: 48.4265213, lng: 18.3152405 },
    { lat: 48.4264251, lng: 18.3151949 },
    { lat: 48.4259914, lng: 18.3146889 },
    { lat: 48.4259275, lng: 18.3147391 },
    { lat: 48.4255837, lng: 18.3146631 },
    { lat: 48.4254905, lng: 18.3146125 },
    { lat: 48.425473, lng: 18.3144627 },
    { lat: 48.4253975, lng: 18.3143662 },
    { lat: 48.4253078, lng: 18.3143354 },
    { lat: 48.4250205, lng: 18.3143638 },
    { lat: 48.4247878, lng: 18.3146569 },
    { lat: 48.4242407, lng: 18.3143996 },
    { lat: 48.4241252, lng: 18.3145518 },
    { lat: 48.4239614, lng: 18.3146482 },
    { lat: 48.4239337, lng: 18.3150343 },
    { lat: 48.4237432, lng: 18.3151561 },
    { lat: 48.4234948, lng: 18.3154689 },
    { lat: 48.4234394, lng: 18.3154675 },
    { lat: 48.4234054, lng: 18.3153859 },
    { lat: 48.4229321, lng: 18.3156729 },
    { lat: 48.4229109, lng: 18.3157786 },
    { lat: 48.4229665, lng: 18.3158831 },
    { lat: 48.4228729, lng: 18.3161629 },
    { lat: 48.4220314, lng: 18.316024 },
    { lat: 48.4218742, lng: 18.3162001 },
    { lat: 48.4218793, lng: 18.3163852 },
    { lat: 48.4218044, lng: 18.3164769 },
    { lat: 48.4216554, lng: 18.3163972 },
    { lat: 48.4216588, lng: 18.316141 },
    { lat: 48.4215951, lng: 18.3160899 },
    { lat: 48.4214093, lng: 18.3163141 },
    { lat: 48.4214193, lng: 18.3164541 },
    { lat: 48.4209363, lng: 18.3166486 },
    { lat: 48.420597, lng: 18.3169532 },
    { lat: 48.4204584, lng: 18.3168981 },
    { lat: 48.4201825, lng: 18.3170063 },
    { lat: 48.4201764, lng: 18.3170941 },
    { lat: 48.4198044, lng: 18.3175969 },
    { lat: 48.4197116, lng: 18.3178311 },
    { lat: 48.4195996, lng: 18.3179236 },
    { lat: 48.4183983, lng: 18.3184069 },
    { lat: 48.4176505, lng: 18.3182471 },
    { lat: 48.4172982, lng: 18.3189223 },
    { lat: 48.417083, lng: 18.3190266 },
    { lat: 48.416748, lng: 18.319646 },
    { lat: 48.4163652, lng: 18.3201582 },
    { lat: 48.4155537, lng: 18.3206057 },
    { lat: 48.4153746, lng: 18.3209916 },
    { lat: 48.4142776, lng: 18.321899 },
    { lat: 48.414073, lng: 18.3219728 },
    { lat: 48.4135926, lng: 18.3218987 },
    { lat: 48.4133944, lng: 18.3218048 },
    { lat: 48.4132133, lng: 18.3226303 },
    { lat: 48.4128498, lng: 18.3227021 },
    { lat: 48.412478, lng: 18.3224759 },
    { lat: 48.4119541, lng: 18.3228466 },
    { lat: 48.4115482, lng: 18.3229309 },
    { lat: 48.411019, lng: 18.3232665 },
    { lat: 48.4109843, lng: 18.3231346 },
    { lat: 48.410784, lng: 18.3233627 },
    { lat: 48.4106141, lng: 18.3232303 },
    { lat: 48.4105911, lng: 18.3230255 },
    { lat: 48.4105132, lng: 18.3229439 },
    { lat: 48.4104324, lng: 18.3229705 },
    { lat: 48.4102926, lng: 18.3231529 },
    { lat: 48.4100846, lng: 18.323166 },
    { lat: 48.4094691, lng: 18.3237683 },
    { lat: 48.4093254, lng: 18.3240406 },
    { lat: 48.4091446, lng: 18.3239583 },
    { lat: 48.4090971, lng: 18.3241342 },
    { lat: 48.4089438, lng: 18.3243946 },
    { lat: 48.4088688, lng: 18.3243481 },
    { lat: 48.408487, lng: 18.3244563 },
    { lat: 48.408431, lng: 18.3243647 },
    { lat: 48.4083848, lng: 18.32391 },
    { lat: 48.4081913, lng: 18.3239206 },
    { lat: 48.408049, lng: 18.3237375 },
    { lat: 48.4078894, lng: 18.3238077 },
    { lat: 48.407377, lng: 18.324341 },
    { lat: 48.4071977, lng: 18.3244211 },
    { lat: 48.4069223, lng: 18.3243818 },
    { lat: 48.4066242, lng: 18.3244573 },
    { lat: 48.4065216, lng: 18.3245563 },
    { lat: 48.4070959, lng: 18.3265442 },
    { lat: 48.4080824, lng: 18.3294541 },
    { lat: 48.4086113, lng: 18.3307625 },
    { lat: 48.4088782, lng: 18.331549 },
    { lat: 48.4090314, lng: 18.3318465 },
    { lat: 48.4095954, lng: 18.3334317 },
    { lat: 48.4101166, lng: 18.3351229 },
    { lat: 48.4103151, lng: 18.3354804 },
    { lat: 48.410877, lng: 18.3376515 },
    { lat: 48.411653, lng: 18.3399367 },
    { lat: 48.4116736, lng: 18.340004 },
  ],
  TesárskeMlyňany: [
    { lat: 48.3227105, lng: 18.385209 },
    { lat: 48.3231967, lng: 18.3856107 },
    { lat: 48.3241924, lng: 18.386523 },
    { lat: 48.3259525, lng: 18.3889497 },
    { lat: 48.3259619, lng: 18.3889365 },
    { lat: 48.3270298, lng: 18.3876023 },
    { lat: 48.3280136, lng: 18.3884265 },
    { lat: 48.3283325, lng: 18.3885765 },
    { lat: 48.3286214, lng: 18.388619 },
    { lat: 48.3290288, lng: 18.3887209 },
    { lat: 48.329666, lng: 18.3890189 },
    { lat: 48.3306409, lng: 18.3896447 },
    { lat: 48.331056, lng: 18.388799 },
    { lat: 48.331693, lng: 18.3893346 },
    { lat: 48.3325118, lng: 18.3905116 },
    { lat: 48.3340373, lng: 18.3921576 },
    { lat: 48.3349253, lng: 18.3925619 },
    { lat: 48.3357198, lng: 18.393346 },
    { lat: 48.3383827, lng: 18.3949959 },
    { lat: 48.3399648, lng: 18.3944419 },
    { lat: 48.3407269, lng: 18.3943732 },
    { lat: 48.3411506, lng: 18.3941639 },
    { lat: 48.341496, lng: 18.3939021 },
    { lat: 48.3418663, lng: 18.3935081 },
    { lat: 48.3422178, lng: 18.3929356 },
    { lat: 48.3427561, lng: 18.3929395 },
    { lat: 48.3431639, lng: 18.3930489 },
    { lat: 48.3439331, lng: 18.3935058 },
    { lat: 48.3441816, lng: 18.3939301 },
    { lat: 48.3445753, lng: 18.394331 },
    { lat: 48.3453027, lng: 18.3949309 },
    { lat: 48.3461646, lng: 18.395262 },
    { lat: 48.3470131, lng: 18.3955108 },
    { lat: 48.3470043, lng: 18.3946518 },
    { lat: 48.3465703, lng: 18.3944978 },
    { lat: 48.347071, lng: 18.3899524 },
    { lat: 48.347219, lng: 18.3899942 },
    { lat: 48.3483021, lng: 18.3900267 },
    { lat: 48.3493589, lng: 18.3899397 },
    { lat: 48.3500232, lng: 18.3898169 },
    { lat: 48.3505499, lng: 18.3894541 },
    { lat: 48.3515506, lng: 18.3890127 },
    { lat: 48.351644, lng: 18.3885682 },
    { lat: 48.3517318, lng: 18.3886411 },
    { lat: 48.3519904, lng: 18.3877025 },
    { lat: 48.3523864, lng: 18.3878623 },
    { lat: 48.3529765, lng: 18.3879674 },
    { lat: 48.353476, lng: 18.3856954 },
    { lat: 48.3540691, lng: 18.3824236 },
    { lat: 48.3544041, lng: 18.3810222 },
    { lat: 48.3548227, lng: 18.3794212 },
    { lat: 48.3559332, lng: 18.3758235 },
    { lat: 48.355652, lng: 18.3757656 },
    { lat: 48.3553407, lng: 18.3757015 },
    { lat: 48.3552851, lng: 18.3756121 },
    { lat: 48.3553301, lng: 18.3753126 },
    { lat: 48.3552315, lng: 18.374444 },
    { lat: 48.3552126, lng: 18.3743485 },
    { lat: 48.3550925, lng: 18.3742804 },
    { lat: 48.3552156, lng: 18.3737589 },
    { lat: 48.3554724, lng: 18.3740115 },
    { lat: 48.3557051, lng: 18.3737986 },
    { lat: 48.3558115, lng: 18.3741058 },
    { lat: 48.3558938, lng: 18.3741446 },
    { lat: 48.3560839, lng: 18.3740141 },
    { lat: 48.3561553, lng: 18.3740976 },
    { lat: 48.356268, lng: 18.3740964 },
    { lat: 48.3562457, lng: 18.3734994 },
    { lat: 48.3563291, lng: 18.3721799 },
    { lat: 48.3567445, lng: 18.369666 },
    { lat: 48.3565637, lng: 18.3680791 },
    { lat: 48.3563097, lng: 18.3680192 },
    { lat: 48.3563199, lng: 18.3678597 },
    { lat: 48.3563795, lng: 18.3677553 },
    { lat: 48.3571213, lng: 18.3678462 },
    { lat: 48.3571018, lng: 18.3675386 },
    { lat: 48.3569791, lng: 18.3674049 },
    { lat: 48.3570872, lng: 18.3672595 },
    { lat: 48.3570857, lng: 18.3671749 },
    { lat: 48.3569223, lng: 18.367057 },
    { lat: 48.3569134, lng: 18.3669205 },
    { lat: 48.3570862, lng: 18.3668233 },
    { lat: 48.3571009, lng: 18.366946 },
    { lat: 48.3571656, lng: 18.3669736 },
    { lat: 48.3572707, lng: 18.3666746 },
    { lat: 48.3582487, lng: 18.3660111 },
    { lat: 48.3581437, lng: 18.3658716 },
    { lat: 48.3579254, lng: 18.3659007 },
    { lat: 48.3578433, lng: 18.365648 },
    { lat: 48.357995, lng: 18.3656344 },
    { lat: 48.3579925, lng: 18.3655304 },
    { lat: 48.3580683, lng: 18.3654172 },
    { lat: 48.3582244, lng: 18.3653416 },
    { lat: 48.358178, lng: 18.3650808 },
    { lat: 48.3583326, lng: 18.3650803 },
    { lat: 48.3585338, lng: 18.3649674 },
    { lat: 48.3587104, lng: 18.3650986 },
    { lat: 48.3588064, lng: 18.364981 },
    { lat: 48.3587411, lng: 18.3648201 },
    { lat: 48.3588191, lng: 18.3646654 },
    { lat: 48.3589468, lng: 18.3646705 },
    { lat: 48.3590454, lng: 18.3647544 },
    { lat: 48.3591253, lng: 18.364591 },
    { lat: 48.3592211, lng: 18.364592 },
    { lat: 48.3591847, lng: 18.364488 },
    { lat: 48.3592405, lng: 18.3643369 },
    { lat: 48.3594244, lng: 18.3644366 },
    { lat: 48.35943, lng: 18.364355 },
    { lat: 48.3595432, lng: 18.3643006 },
    { lat: 48.3596024, lng: 18.3641891 },
    { lat: 48.3594956, lng: 18.3640182 },
    { lat: 48.3595164, lng: 18.3639467 },
    { lat: 48.3594752, lng: 18.3638746 },
    { lat: 48.3597001, lng: 18.3637215 },
    { lat: 48.3597932, lng: 18.363892 },
    { lat: 48.359888, lng: 18.3639545 },
    { lat: 48.3599623, lng: 18.3638517 },
    { lat: 48.3599296, lng: 18.3637913 },
    { lat: 48.3599918, lng: 18.3636326 },
    { lat: 48.359938, lng: 18.3635596 },
    { lat: 48.3599513, lng: 18.3634345 },
    { lat: 48.3600695, lng: 18.3632506 },
    { lat: 48.3601819, lng: 18.3632647 },
    { lat: 48.3603504, lng: 18.3635892 },
    { lat: 48.3604823, lng: 18.3634301 },
    { lat: 48.3606855, lng: 18.3634532 },
    { lat: 48.3606561, lng: 18.3635521 },
    { lat: 48.3605525, lng: 18.3635723 },
    { lat: 48.3605843, lng: 18.3637169 },
    { lat: 48.3606992, lng: 18.3637552 },
    { lat: 48.360745, lng: 18.3639047 },
    { lat: 48.3608526, lng: 18.3638545 },
    { lat: 48.360853, lng: 18.3637619 },
    { lat: 48.3609143, lng: 18.3637668 },
    { lat: 48.3609265, lng: 18.3636053 },
    { lat: 48.3609909, lng: 18.3635865 },
    { lat: 48.3610474, lng: 18.3636282 },
    { lat: 48.3610256, lng: 18.36372 },
    { lat: 48.3610925, lng: 18.3638244 },
    { lat: 48.3613165, lng: 18.3637593 },
    { lat: 48.3613188, lng: 18.3636356 },
    { lat: 48.3613999, lng: 18.3636583 },
    { lat: 48.3614428, lng: 18.3637351 },
    { lat: 48.3613817, lng: 18.3638536 },
    { lat: 48.3614177, lng: 18.3639093 },
    { lat: 48.3617477, lng: 18.363763 },
    { lat: 48.3617225, lng: 18.3633536 },
    { lat: 48.3617708, lng: 18.3632294 },
    { lat: 48.3620434, lng: 18.3632635 },
    { lat: 48.3620736, lng: 18.3632203 },
    { lat: 48.362038, lng: 18.3631464 },
    { lat: 48.3621846, lng: 18.3630253 },
    { lat: 48.3622731, lng: 18.3631743 },
    { lat: 48.3624995, lng: 18.3633098 },
    { lat: 48.3627321, lng: 18.3635896 },
    { lat: 48.3635157, lng: 18.3634829 },
    { lat: 48.3636965, lng: 18.3635493 },
    { lat: 48.3638356, lng: 18.3634748 },
    { lat: 48.3638851, lng: 18.3635512 },
    { lat: 48.3643093, lng: 18.3634479 },
    { lat: 48.3645084, lng: 18.3634623 },
    { lat: 48.3645842, lng: 18.3634431 },
    { lat: 48.3647544, lng: 18.3632203 },
    { lat: 48.364904, lng: 18.3633914 },
    { lat: 48.3653789, lng: 18.3633115 },
    { lat: 48.3655424, lng: 18.363129 },
    { lat: 48.3655337, lng: 18.3627565 },
    { lat: 48.365578, lng: 18.3624624 },
    { lat: 48.36551, lng: 18.3621748 },
    { lat: 48.3656334, lng: 18.3621034 },
    { lat: 48.3655956, lng: 18.3618733 },
    { lat: 48.3657765, lng: 18.3617027 },
    { lat: 48.3658973, lng: 18.361682 },
    { lat: 48.3658724, lng: 18.361363 },
    { lat: 48.3659687, lng: 18.3612496 },
    { lat: 48.3659928, lng: 18.3611366 },
    { lat: 48.3661127, lng: 18.3610213 },
    { lat: 48.3663086, lng: 18.361056 },
    { lat: 48.3663588, lng: 18.3609247 },
    { lat: 48.3664778, lng: 18.3608552 },
    { lat: 48.3664993, lng: 18.3607745 },
    { lat: 48.3665658, lng: 18.360793 },
    { lat: 48.3668023, lng: 18.3606057 },
    { lat: 48.3668977, lng: 18.3606783 },
    { lat: 48.3669795, lng: 18.3605292 },
    { lat: 48.3669769, lng: 18.3604238 },
    { lat: 48.3670542, lng: 18.3603153 },
    { lat: 48.3671241, lng: 18.3603508 },
    { lat: 48.3672513, lng: 18.3602257 },
    { lat: 48.3672961, lng: 18.3600812 },
    { lat: 48.3674267, lng: 18.3600951 },
    { lat: 48.3674453, lng: 18.3600271 },
    { lat: 48.3676563, lng: 18.3599669 },
    { lat: 48.3676443, lng: 18.3598546 },
    { lat: 48.3677042, lng: 18.3596763 },
    { lat: 48.3678715, lng: 18.3596617 },
    { lat: 48.367852, lng: 18.3595393 },
    { lat: 48.3678961, lng: 18.3593985 },
    { lat: 48.3681184, lng: 18.3591406 },
    { lat: 48.3682742, lng: 18.3593028 },
    { lat: 48.3683703, lng: 18.3590674 },
    { lat: 48.3684249, lng: 18.3590339 },
    { lat: 48.3684678, lng: 18.3591157 },
    { lat: 48.36859, lng: 18.3591601 },
    { lat: 48.3686129, lng: 18.3592477 },
    { lat: 48.3686774, lng: 18.3591887 },
    { lat: 48.3688183, lng: 18.3592889 },
    { lat: 48.3689437, lng: 18.3591907 },
    { lat: 48.3694153, lng: 18.3580924 },
    { lat: 48.370204, lng: 18.3572948 },
    { lat: 48.3714061, lng: 18.3563476 },
    { lat: 48.3714108, lng: 18.3563087 },
    { lat: 48.3710203, lng: 18.3555078 },
    { lat: 48.3702522, lng: 18.3543541 },
    { lat: 48.3700993, lng: 18.3541369 },
    { lat: 48.3700496, lng: 18.3540663 },
    { lat: 48.3697646, lng: 18.3538139 },
    { lat: 48.3695508, lng: 18.3536245 },
    { lat: 48.3694795, lng: 18.3532202 },
    { lat: 48.369177, lng: 18.3524618 },
    { lat: 48.3690189, lng: 18.3519189 },
    { lat: 48.3686455, lng: 18.3501093 },
    { lat: 48.3678401, lng: 18.3476593 },
    { lat: 48.3675958, lng: 18.3467889 },
    { lat: 48.3672732, lng: 18.3460147 },
    { lat: 48.3666948, lng: 18.3450272 },
    { lat: 48.3663241, lng: 18.3442775 },
    { lat: 48.3657542, lng: 18.343498 },
    { lat: 48.3655142, lng: 18.3430691 },
    { lat: 48.3637984, lng: 18.3396088 },
    { lat: 48.3635036, lng: 18.3392434 },
    { lat: 48.3630524, lng: 18.3388706 },
    { lat: 48.363097, lng: 18.3386176 },
    { lat: 48.3629888, lng: 18.338503 },
    { lat: 48.3628069, lng: 18.3384187 },
    { lat: 48.3624537, lng: 18.338059 },
    { lat: 48.3622429, lng: 18.3379451 },
    { lat: 48.3618478, lng: 18.3380771 },
    { lat: 48.3615671, lng: 18.3380856 },
    { lat: 48.3613365, lng: 18.3380541 },
    { lat: 48.3610433, lng: 18.3378996 },
    { lat: 48.3603906, lng: 18.3380099 },
    { lat: 48.3602912, lng: 18.3379867 },
    { lat: 48.3602513, lng: 18.3378208 },
    { lat: 48.3597553, lng: 18.337788 },
    { lat: 48.3593661, lng: 18.3380286 },
    { lat: 48.3589409, lng: 18.3381485 },
    { lat: 48.3586843, lng: 18.3383377 },
    { lat: 48.3583384, lng: 18.3383677 },
    { lat: 48.3575292, lng: 18.338781 },
    { lat: 48.3570774, lng: 18.3387947 },
    { lat: 48.3568213, lng: 18.3385351 },
    { lat: 48.356449, lng: 18.3387163 },
    { lat: 48.3560062, lng: 18.3388239 },
    { lat: 48.3557142, lng: 18.3386491 },
    { lat: 48.355217, lng: 18.3385167 },
    { lat: 48.3549212, lng: 18.3385531 },
    { lat: 48.354892, lng: 18.3384543 },
    { lat: 48.3547436, lng: 18.3384065 },
    { lat: 48.3543683, lng: 18.3384715 },
    { lat: 48.3543218, lng: 18.3384097 },
    { lat: 48.3538303, lng: 18.3385157 },
    { lat: 48.3525091, lng: 18.3390319 },
    { lat: 48.3521448, lng: 18.3392356 },
    { lat: 48.3521171, lng: 18.3391639 },
    { lat: 48.3516543, lng: 18.3395596 },
    { lat: 48.3512835, lng: 18.3401404 },
    { lat: 48.3511656, lng: 18.3401345 },
    { lat: 48.3508426, lng: 18.3404467 },
    { lat: 48.3507276, lng: 18.3406661 },
    { lat: 48.350297, lng: 18.3409725 },
    { lat: 48.3502491, lng: 18.3410623 },
    { lat: 48.3500981, lng: 18.3411263 },
    { lat: 48.3499139, lng: 18.3413374 },
    { lat: 48.3496789, lng: 18.3405959 },
    { lat: 48.3493723, lng: 18.3398831 },
    { lat: 48.3490208, lng: 18.339397 },
    { lat: 48.3484253, lng: 18.3382486 },
    { lat: 48.3465142, lng: 18.3392267 },
    { lat: 48.3461912, lng: 18.338162 },
    { lat: 48.3457688, lng: 18.3369845 },
    { lat: 48.3452912, lng: 18.335785 },
    { lat: 48.3450211, lng: 18.335258 },
    { lat: 48.3446045, lng: 18.3337454 },
    { lat: 48.3441719, lng: 18.3340141 },
    { lat: 48.3440079, lng: 18.3334903 },
    { lat: 48.3437949, lng: 18.3336324 },
    { lat: 48.3433658, lng: 18.3322364 },
    { lat: 48.3428371, lng: 18.3298426 },
    { lat: 48.3426275, lng: 18.3299031 },
    { lat: 48.3422525, lng: 18.3298978 },
    { lat: 48.342198, lng: 18.3297345 },
    { lat: 48.3420572, lng: 18.3281665 },
    { lat: 48.3416933, lng: 18.3270477 },
    { lat: 48.341653, lng: 18.3270486 },
    { lat: 48.341523, lng: 18.3265084 },
    { lat: 48.3410338, lng: 18.3236796 },
    { lat: 48.3407805, lng: 18.3221036 },
    { lat: 48.3392986, lng: 18.3226117 },
    { lat: 48.3380015, lng: 18.3231553 },
    { lat: 48.3363118, lng: 18.3236381 },
    { lat: 48.3362761, lng: 18.3234476 },
    { lat: 48.3348502, lng: 18.3238087 },
    { lat: 48.3331799, lng: 18.3239393 },
    { lat: 48.3321661, lng: 18.3241066 },
    { lat: 48.3320773, lng: 18.3235977 },
    { lat: 48.3292617, lng: 18.3235479 },
    { lat: 48.3290489, lng: 18.3233404 },
    { lat: 48.3290188, lng: 18.323306 },
    { lat: 48.3280051, lng: 18.3240678 },
    { lat: 48.3276484, lng: 18.3243229 },
    { lat: 48.327896, lng: 18.327194 },
    { lat: 48.3278597, lng: 18.3272663 },
    { lat: 48.3280094, lng: 18.330675 },
    { lat: 48.3281957, lng: 18.3309164 },
    { lat: 48.3283581, lng: 18.3308953 },
    { lat: 48.3287213, lng: 18.330996 },
    { lat: 48.3287569, lng: 18.3334294 },
    { lat: 48.328945, lng: 18.3360837 },
    { lat: 48.3289364, lng: 18.3379621 },
    { lat: 48.3288378, lng: 18.3398035 },
    { lat: 48.3287219, lng: 18.3406653 },
    { lat: 48.3280814, lng: 18.3442506 },
    { lat: 48.3279313, lng: 18.3458655 },
    { lat: 48.3269799, lng: 18.3503206 },
    { lat: 48.3260453, lng: 18.3535619 },
    { lat: 48.3256507, lng: 18.3539624 },
    { lat: 48.3255299, lng: 18.3542802 },
    { lat: 48.3252649, lng: 18.3545639 },
    { lat: 48.3248153, lng: 18.355272 },
    { lat: 48.3243497, lng: 18.3556553 },
    { lat: 48.3240324, lng: 18.3557704 },
    { lat: 48.3237545, lng: 18.3566013 },
    { lat: 48.3234384, lng: 18.3566 },
    { lat: 48.3231435, lng: 18.3588054 },
    { lat: 48.3226068, lng: 18.3597077 },
    { lat: 48.3226796, lng: 18.3600959 },
    { lat: 48.3227992, lng: 18.3602388 },
    { lat: 48.3226097, lng: 18.3619758 },
    { lat: 48.3220934, lng: 18.365736 },
    { lat: 48.3216523, lng: 18.3680926 },
    { lat: 48.3212466, lng: 18.3698113 },
    { lat: 48.3201008, lng: 18.3722024 },
    { lat: 48.3199998, lng: 18.372575 },
    { lat: 48.3198889, lng: 18.3737053 },
    { lat: 48.3196334, lng: 18.3752364 },
    { lat: 48.3207879, lng: 18.3769634 },
    { lat: 48.3213611, lng: 18.3795653 },
    { lat: 48.3218432, lng: 18.3814528 },
    { lat: 48.3222738, lng: 18.3821888 },
    { lat: 48.3227206, lng: 18.3832059 },
    { lat: 48.3233203, lng: 18.3838379 },
    { lat: 48.3229078, lng: 18.384788 },
    { lat: 48.3227105, lng: 18.385209 },
  ],
  MartinnadŽitavou: [
    { lat: 48.4053257, lng: 18.3662037 },
    { lat: 48.4052603, lng: 18.3659028 },
    { lat: 48.4049319, lng: 18.3643808 },
    { lat: 48.4055866, lng: 18.3642645 },
    { lat: 48.405454, lng: 18.3634779 },
    { lat: 48.4053641, lng: 18.3629446 },
    { lat: 48.4054884, lng: 18.3609467 },
    { lat: 48.4056071, lng: 18.3603148 },
    { lat: 48.4055852, lng: 18.3593439 },
    { lat: 48.4058408, lng: 18.3585799 },
    { lat: 48.4061987, lng: 18.358524 },
    { lat: 48.4059856, lng: 18.354271 },
    { lat: 48.4060545, lng: 18.3535168 },
    { lat: 48.4061091, lng: 18.3500109 },
    { lat: 48.4060629, lng: 18.3463401 },
    { lat: 48.4069331, lng: 18.3458512 },
    { lat: 48.406773, lng: 18.3451139 },
    { lat: 48.4064728, lng: 18.3452115 },
    { lat: 48.4062681, lng: 18.3440271 },
    { lat: 48.4062152, lng: 18.3434711 },
    { lat: 48.4059692, lng: 18.3425844 },
    { lat: 48.4057389, lng: 18.341754 },
    { lat: 48.4050883, lng: 18.3417206 },
    { lat: 48.4049558, lng: 18.3418313 },
    { lat: 48.4048152, lng: 18.3422174 },
    { lat: 48.4047321, lng: 18.3423236 },
    { lat: 48.4046341, lng: 18.3422679 },
    { lat: 48.4045111, lng: 18.3420081 },
    { lat: 48.4043465, lng: 18.3419893 },
    { lat: 48.404226, lng: 18.3424539 },
    { lat: 48.4040928, lng: 18.3426704 },
    { lat: 48.4038517, lng: 18.3426959 },
    { lat: 48.4036472, lng: 18.3423531 },
    { lat: 48.4035363, lng: 18.3423492 },
    { lat: 48.4034412, lng: 18.3425193 },
    { lat: 48.4034381, lng: 18.3428861 },
    { lat: 48.4033322, lng: 18.3430871 },
    { lat: 48.4030473, lng: 18.3431377 },
    { lat: 48.40278, lng: 18.3428803 },
    { lat: 48.4027048, lng: 18.3428843 },
    { lat: 48.4025416, lng: 18.3432715 },
    { lat: 48.4024499, lng: 18.3433045 },
    { lat: 48.4023202, lng: 18.3431482 },
    { lat: 48.4023294, lng: 18.3428132 },
    { lat: 48.4022343, lng: 18.3425868 },
    { lat: 48.4020813, lng: 18.3424952 },
    { lat: 48.4019327, lng: 18.3426011 },
    { lat: 48.401908, lng: 18.3430002 },
    { lat: 48.4018657, lng: 18.3431498 },
    { lat: 48.4017919, lng: 18.3431837 },
    { lat: 48.4016229, lng: 18.3430675 },
    { lat: 48.401586, lng: 18.3426955 },
    { lat: 48.4015149, lng: 18.3424951 },
    { lat: 48.4014468, lng: 18.342529 },
    { lat: 48.4013529, lng: 18.3427828 },
    { lat: 48.400992, lng: 18.3430234 },
    { lat: 48.4007677, lng: 18.3434671 },
    { lat: 48.4006259, lng: 18.3436095 },
    { lat: 48.400516, lng: 18.3436644 },
    { lat: 48.4002956, lng: 18.3434752 },
    { lat: 48.4002625, lng: 18.3435096 },
    { lat: 48.4002015, lng: 18.3437272 },
    { lat: 48.4000754, lng: 18.3437717 },
    { lat: 48.3999727, lng: 18.3441491 },
    { lat: 48.3998256, lng: 18.3443039 },
    { lat: 48.3996574, lng: 18.3442213 },
    { lat: 48.3995126, lng: 18.3443335 },
    { lat: 48.3991768, lng: 18.3443391 },
    { lat: 48.3991421, lng: 18.3445459 },
    { lat: 48.3990459, lng: 18.3445994 },
    { lat: 48.3985706, lng: 18.3444147 },
    { lat: 48.3981594, lng: 18.3441463 },
    { lat: 48.3980158, lng: 18.3442825 },
    { lat: 48.3980325, lng: 18.344536 },
    { lat: 48.3979147, lng: 18.3448265 },
    { lat: 48.3976092, lng: 18.3450892 },
    { lat: 48.3975061, lng: 18.3451222 },
    { lat: 48.3971559, lng: 18.3450334 },
    { lat: 48.396999, lng: 18.3450733 },
    { lat: 48.3968735, lng: 18.3450109 },
    { lat: 48.3966328, lng: 18.3450618 },
    { lat: 48.3962643, lng: 18.3452502 },
    { lat: 48.3960451, lng: 18.344987 },
    { lat: 48.3957199, lng: 18.3451602 },
    { lat: 48.3955659, lng: 18.3453499 },
    { lat: 48.3954665, lng: 18.3456253 },
    { lat: 48.3953404, lng: 18.3457107 },
    { lat: 48.3951182, lng: 18.3457915 },
    { lat: 48.3947869, lng: 18.345777 },
    { lat: 48.394605, lng: 18.3458748 },
    { lat: 48.3944698, lng: 18.3458548 },
    { lat: 48.394381, lng: 18.3459609 },
    { lat: 48.3941078, lng: 18.3459588 },
    { lat: 48.3937811, lng: 18.3463868 },
    { lat: 48.3934825, lng: 18.3464156 },
    { lat: 48.3933556, lng: 18.3462036 },
    { lat: 48.392971, lng: 18.3463673 },
    { lat: 48.3929273, lng: 18.3464517 },
    { lat: 48.3928303, lng: 18.3464126 },
    { lat: 48.3927614, lng: 18.3466482 },
    { lat: 48.3926724, lng: 18.3466936 },
    { lat: 48.3925908, lng: 18.3467048 },
    { lat: 48.3924798, lng: 18.3464392 },
    { lat: 48.3922232, lng: 18.3466519 },
    { lat: 48.3916447, lng: 18.3468936 },
    { lat: 48.3915844, lng: 18.3468442 },
    { lat: 48.3913149, lng: 18.3469691 },
    { lat: 48.3912946, lng: 18.3468875 },
    { lat: 48.3912441, lng: 18.3465543 },
    { lat: 48.3857233, lng: 18.3470285 },
    { lat: 48.3853173, lng: 18.3471123 },
    { lat: 48.3810436, lng: 18.3491445 },
    { lat: 48.3779386, lng: 18.3523152 },
    { lat: 48.3774614, lng: 18.3526344 },
    { lat: 48.3775083, lng: 18.3527665 },
    { lat: 48.3775258, lng: 18.3528161 },
    { lat: 48.3775675, lng: 18.3529357 },
    { lat: 48.3776992, lng: 18.3530484 },
    { lat: 48.3780773, lng: 18.3532707 },
    { lat: 48.3782829, lng: 18.3541725 },
    { lat: 48.3791105, lng: 18.3585942 },
    { lat: 48.3791221, lng: 18.3586465 },
    { lat: 48.3798421, lng: 18.36138 },
    { lat: 48.380045, lng: 18.3613769 },
    { lat: 48.3802982, lng: 18.3620828 },
    { lat: 48.3804023, lng: 18.3627081 },
    { lat: 48.3844865, lng: 18.3612702 },
    { lat: 48.3852391, lng: 18.3653495 },
    { lat: 48.3855755, lng: 18.365311 },
    { lat: 48.3882354, lng: 18.3654025 },
    { lat: 48.3882906, lng: 18.3658235 },
    { lat: 48.3900114, lng: 18.3652046 },
    { lat: 48.3899945, lng: 18.3656647 },
    { lat: 48.391094, lng: 18.3659305 },
    { lat: 48.39641, lng: 18.3672157 },
    { lat: 48.3978161, lng: 18.3676401 },
    { lat: 48.3983304, lng: 18.3676957 },
    { lat: 48.3989625, lng: 18.3676029 },
    { lat: 48.4007286, lng: 18.3670303 },
    { lat: 48.4041581, lng: 18.366103 },
    { lat: 48.4049535, lng: 18.3660845 },
    { lat: 48.4051949, lng: 18.366143 },
    { lat: 48.4053257, lng: 18.3662037 },
  ],
  Hosťovce: [
    { lat: 48.489877, lng: 18.337453 },
    { lat: 48.489826, lng: 18.337036 },
    { lat: 48.489776, lng: 18.336443 },
    { lat: 48.489819, lng: 18.336313 },
    { lat: 48.489907, lng: 18.336185 },
    { lat: 48.490035, lng: 18.336073 },
    { lat: 48.490115, lng: 18.335904 },
    { lat: 48.490231, lng: 18.335644 },
    { lat: 48.49053, lng: 18.335149 },
    { lat: 48.490827, lng: 18.334859 },
    { lat: 48.49097, lng: 18.334598 },
    { lat: 48.49109, lng: 18.334407 },
    { lat: 48.491126, lng: 18.334308 },
    { lat: 48.491169, lng: 18.334273 },
    { lat: 48.491297, lng: 18.3342 },
    { lat: 48.491373, lng: 18.33412 },
    { lat: 48.491425, lng: 18.333995 },
    { lat: 48.491679, lng: 18.333711 },
    { lat: 48.491774, lng: 18.333632 },
    { lat: 48.491867, lng: 18.333359 },
    { lat: 48.491933, lng: 18.333218 },
    { lat: 48.492066, lng: 18.333057 },
    { lat: 48.492321, lng: 18.332559 },
    { lat: 48.492348, lng: 18.332497 },
    { lat: 48.492407, lng: 18.332369 },
    { lat: 48.492466, lng: 18.332186 },
    { lat: 48.492542, lng: 18.331708 },
    { lat: 48.492609, lng: 18.330785 },
    { lat: 48.49263, lng: 18.330595 },
    { lat: 48.492615, lng: 18.330387 },
    { lat: 48.492655, lng: 18.330167 },
    { lat: 48.492905, lng: 18.329441 },
    { lat: 48.493029, lng: 18.32921 },
    { lat: 48.493107, lng: 18.329004 },
    { lat: 48.493128, lng: 18.328867 },
    { lat: 48.493211, lng: 18.32849 },
    { lat: 48.493209, lng: 18.328293 },
    { lat: 48.493181, lng: 18.32811 },
    { lat: 48.493194, lng: 18.327774 },
    { lat: 48.493181, lng: 18.327605 },
    { lat: 48.493213, lng: 18.327488 },
    { lat: 48.493327, lng: 18.327321 },
    { lat: 48.493516, lng: 18.326822 },
    { lat: 48.493564, lng: 18.326695 },
    { lat: 48.49352, lng: 18.326049 },
    { lat: 48.493513, lng: 18.325783 },
    { lat: 48.493574, lng: 18.32563 },
    { lat: 48.493645, lng: 18.325531 },
    { lat: 48.493817, lng: 18.325396 },
    { lat: 48.493938, lng: 18.325261 },
    { lat: 48.49402, lng: 18.3251 },
    { lat: 48.494069, lng: 18.324756 },
    { lat: 48.494095, lng: 18.324639 },
    { lat: 48.494201, lng: 18.324373 },
    { lat: 48.494157, lng: 18.323934 },
    { lat: 48.49419, lng: 18.3234 },
    { lat: 48.494327, lng: 18.322939 },
    { lat: 48.494392, lng: 18.322691 },
    { lat: 48.494466, lng: 18.322038 },
    { lat: 48.49471, lng: 18.321555 },
    { lat: 48.494847, lng: 18.321022 },
    { lat: 48.494995, lng: 18.320887 },
    { lat: 48.495022, lng: 18.320762 },
    { lat: 48.495016, lng: 18.320276 },
    { lat: 48.495014, lng: 18.31988 },
    { lat: 48.495017, lng: 18.319482 },
    { lat: 48.495278, lng: 18.318289 },
    { lat: 48.495193, lng: 18.317213 },
    { lat: 48.49518, lng: 18.317168 },
    { lat: 48.4938428, lng: 18.3174576 },
    { lat: 48.4934916, lng: 18.3173334 },
    { lat: 48.4930188, lng: 18.3174731 },
    { lat: 48.4928446, lng: 18.3173824 },
    { lat: 48.4926945, lng: 18.3173831 },
    { lat: 48.4924291, lng: 18.3174759 },
    { lat: 48.4922005, lng: 18.3176853 },
    { lat: 48.4919876, lng: 18.3177126 },
    { lat: 48.4913309, lng: 18.3176671 },
    { lat: 48.4904688, lng: 18.3183839 },
    { lat: 48.4898171, lng: 18.3187098 },
    { lat: 48.4890418, lng: 18.3189637 },
    { lat: 48.4886471, lng: 18.3192292 },
    { lat: 48.4872352, lng: 18.3197747 },
    { lat: 48.4866706, lng: 18.3200887 },
    { lat: 48.4865971, lng: 18.3201849 },
    { lat: 48.4852302, lng: 18.320886 },
    { lat: 48.4849091, lng: 18.3209781 },
    { lat: 48.4846393, lng: 18.3214697 },
    { lat: 48.4839192, lng: 18.3223997 },
    { lat: 48.483741, lng: 18.3225805 },
    { lat: 48.4830156, lng: 18.3230007 },
    { lat: 48.4816662, lng: 18.3235579 },
    { lat: 48.4806149, lng: 18.3237422 },
    { lat: 48.4802164, lng: 18.3239562 },
    { lat: 48.4797868, lng: 18.3240031 },
    { lat: 48.4796246, lng: 18.3239645 },
    { lat: 48.4793431, lng: 18.3240543 },
    { lat: 48.47887, lng: 18.3242868 },
    { lat: 48.4781804, lng: 18.3247589 },
    { lat: 48.4773082, lng: 18.3250596 },
    { lat: 48.4766651, lng: 18.3253757 },
    { lat: 48.4761666, lng: 18.3257408 },
    { lat: 48.4753875, lng: 18.3261856 },
    { lat: 48.4746886, lng: 18.3269323 },
    { lat: 48.4739721, lng: 18.3271291 },
    { lat: 48.4737829, lng: 18.327058 },
    { lat: 48.4734623, lng: 18.3270536 },
    { lat: 48.4724894, lng: 18.3273874 },
    { lat: 48.4717416, lng: 18.3275117 },
    { lat: 48.4715669, lng: 18.327613 },
    { lat: 48.4696864, lng: 18.328077 },
    { lat: 48.4693028, lng: 18.3283956 },
    { lat: 48.4690322, lng: 18.3287553 },
    { lat: 48.4680315, lng: 18.3289368 },
    { lat: 48.4654162, lng: 18.3304305 },
    { lat: 48.4630394, lng: 18.3306977 },
    { lat: 48.4613198, lng: 18.3309922 },
    { lat: 48.4610138, lng: 18.3311871 },
    { lat: 48.4606667, lng: 18.3312959 },
    { lat: 48.4594816, lng: 18.3315429 },
    { lat: 48.4589061, lng: 18.3315839 },
    { lat: 48.4581771, lng: 18.3320203 },
    { lat: 48.457742, lng: 18.3320437 },
    { lat: 48.4572032, lng: 18.3318358 },
    { lat: 48.4568221, lng: 18.3319561 },
    { lat: 48.4561125, lng: 18.3321802 },
    { lat: 48.4542537, lng: 18.3324306 },
    { lat: 48.4529933, lng: 18.3304366 },
    { lat: 48.4526837, lng: 18.3298291 },
    { lat: 48.4520411, lng: 18.3289613 },
    { lat: 48.4518065, lng: 18.3285066 },
    { lat: 48.4514514, lng: 18.3279937 },
    { lat: 48.451197, lng: 18.3273681 },
    { lat: 48.451125, lng: 18.3273535 },
    { lat: 48.4505961, lng: 18.3276739 },
    { lat: 48.450146, lng: 18.3276957 },
    { lat: 48.4495457, lng: 18.3281134 },
    { lat: 48.4497462, lng: 18.328226 },
    { lat: 48.4496106, lng: 18.3285227 },
    { lat: 48.4494464, lng: 18.3285715 },
    { lat: 48.4492686, lng: 18.3285381 },
    { lat: 48.4486692, lng: 18.3288544 },
    { lat: 48.4486014, lng: 18.3292672 },
    { lat: 48.4482789, lng: 18.3294514 },
    { lat: 48.4476868, lng: 18.3301499 },
    { lat: 48.4474572, lng: 18.3302414 },
    { lat: 48.4473671, lng: 18.330086 },
    { lat: 48.4470393, lng: 18.330134 },
    { lat: 48.44675, lng: 18.3303647 },
    { lat: 48.4460822, lng: 18.3305053 },
    { lat: 48.4458969, lng: 18.3308027 },
    { lat: 48.4457426, lng: 18.3306874 },
    { lat: 48.4455054, lng: 18.3307988 },
    { lat: 48.4452041, lng: 18.3308168 },
    { lat: 48.445094, lng: 18.3310856 },
    { lat: 48.4448819, lng: 18.3309685 },
    { lat: 48.4445059, lng: 18.3312999 },
    { lat: 48.4441618, lng: 18.3312409 },
    { lat: 48.4438975, lng: 18.3313749 },
    { lat: 48.4434647, lng: 18.3312424 },
    { lat: 48.4433005, lng: 18.331311 },
    { lat: 48.4431257, lng: 18.3311138 },
    { lat: 48.443009, lng: 18.3308867 },
    { lat: 48.4429006, lng: 18.3308053 },
    { lat: 48.4427839, lng: 18.330998 },
    { lat: 48.4422944, lng: 18.3311392 },
    { lat: 48.4418519, lng: 18.3309766 },
    { lat: 48.441716, lng: 18.3310274 },
    { lat: 48.4414918, lng: 18.3307142 },
    { lat: 48.4411274, lng: 18.3307126 },
    { lat: 48.4407849, lng: 18.3305443 },
    { lat: 48.4402699, lng: 18.3306337 },
    { lat: 48.4399107, lng: 18.3304874 },
    { lat: 48.4397145, lng: 18.3306671 },
    { lat: 48.4396418, lng: 18.330503 },
    { lat: 48.439566, lng: 18.3305376 },
    { lat: 48.4394637, lng: 18.3304849 },
    { lat: 48.4394459, lng: 18.3305668 },
    { lat: 48.4391829, lng: 18.3307654 },
    { lat: 48.4390251, lng: 18.3306456 },
    { lat: 48.4386385, lng: 18.330554 },
    { lat: 48.438457, lng: 18.3304013 },
    { lat: 48.4384099, lng: 18.3300865 },
    { lat: 48.4382586, lng: 18.3300462 },
    { lat: 48.4381673, lng: 18.3301034 },
    { lat: 48.4380294, lng: 18.3298432 },
    { lat: 48.4379101, lng: 18.329808 },
    { lat: 48.437806, lng: 18.3295818 },
    { lat: 48.4377401, lng: 18.3295373 },
    { lat: 48.4377218, lng: 18.329351 },
    { lat: 48.4375287, lng: 18.3292489 },
    { lat: 48.4375079, lng: 18.3290824 },
    { lat: 48.4369319, lng: 18.3285137 },
    { lat: 48.4366111, lng: 18.3288064 },
    { lat: 48.4363368, lng: 18.3286472 },
    { lat: 48.4361916, lng: 18.328729 },
    { lat: 48.4359573, lng: 18.3286592 },
    { lat: 48.4353145, lng: 18.3281474 },
    { lat: 48.43455, lng: 18.3277937 },
    { lat: 48.4341416, lng: 18.3277391 },
    { lat: 48.4339753, lng: 18.3275703 },
    { lat: 48.4337859, lng: 18.3274958 },
    { lat: 48.4336128, lng: 18.3275027 },
    { lat: 48.4333375, lng: 18.3275429 },
    { lat: 48.4334743, lng: 18.3287419 },
    { lat: 48.4337124, lng: 18.3299546 },
    { lat: 48.4344817, lng: 18.3310087 },
    { lat: 48.4355605, lng: 18.3319258 },
    { lat: 48.4364705, lng: 18.3334108 },
    { lat: 48.4354119, lng: 18.3356961 },
    { lat: 48.4350043, lng: 18.3367637 },
    { lat: 48.4313444, lng: 18.3390604 },
    { lat: 48.4312281, lng: 18.3388383 },
    { lat: 48.4310877, lng: 18.3388694 },
    { lat: 48.4280118, lng: 18.3404008 },
    { lat: 48.4265843, lng: 18.3412116 },
    { lat: 48.4267008, lng: 18.3416358 },
    { lat: 48.4260918, lng: 18.3419591 },
    { lat: 48.4255633, lng: 18.340378 },
    { lat: 48.4241407, lng: 18.3411381 },
    { lat: 48.4239825, lng: 18.3404159 },
    { lat: 48.4235145, lng: 18.3405588 },
    { lat: 48.4231318, lng: 18.3389397 },
    { lat: 48.421141, lng: 18.3398658 },
    { lat: 48.4210437, lng: 18.3393447 },
    { lat: 48.4197606, lng: 18.3399374 },
    { lat: 48.4196414, lng: 18.340024 },
    { lat: 48.4196927, lng: 18.3402707 },
    { lat: 48.4189756, lng: 18.3406 },
    { lat: 48.4188629, lng: 18.3395048 },
    { lat: 48.4165715, lng: 18.3407019 },
    { lat: 48.4164449, lng: 18.340121 },
    { lat: 48.4159313, lng: 18.3403105 },
    { lat: 48.4139774, lng: 18.3415163 },
    { lat: 48.4134042, lng: 18.3394228 },
    { lat: 48.413083, lng: 18.3396283 },
    { lat: 48.4126922, lng: 18.3383272 },
    { lat: 48.4123923, lng: 18.3385495 },
    { lat: 48.4123526, lng: 18.3389428 },
    { lat: 48.412236, lng: 18.3389545 },
    { lat: 48.4120757, lng: 18.3392548 },
    { lat: 48.4117774, lng: 18.3392066 },
    { lat: 48.4116806, lng: 18.3392882 },
    { lat: 48.4118144, lng: 18.3394789 },
    { lat: 48.4118042, lng: 18.339917 },
    { lat: 48.4117118, lng: 18.3400183 },
    { lat: 48.4116736, lng: 18.340004 },
    { lat: 48.4114186, lng: 18.3399222 },
    { lat: 48.4112844, lng: 18.3396443 },
    { lat: 48.4110577, lng: 18.3399037 },
    { lat: 48.4108733, lng: 18.3399749 },
    { lat: 48.4106836, lng: 18.3404114 },
    { lat: 48.4105087, lng: 18.3405705 },
    { lat: 48.4103946, lng: 18.3405478 },
    { lat: 48.4102116, lng: 18.3402462 },
    { lat: 48.4100506, lng: 18.3402132 },
    { lat: 48.4097587, lng: 18.3402767 },
    { lat: 48.4096945, lng: 18.3404792 },
    { lat: 48.4090407, lng: 18.3407871 },
    { lat: 48.4090929, lng: 18.3410109 },
    { lat: 48.4089137, lng: 18.3410743 },
    { lat: 48.4088247, lng: 18.3412178 },
    { lat: 48.4083896, lng: 18.3416214 },
    { lat: 48.4081387, lng: 18.341734 },
    { lat: 48.4077194, lng: 18.3418036 },
    { lat: 48.4075753, lng: 18.3416455 },
    { lat: 48.407618, lng: 18.3415065 },
    { lat: 48.4077755, lng: 18.3413568 },
    { lat: 48.4076322, lng: 18.3412199 },
    { lat: 48.4075231, lng: 18.3408045 },
    { lat: 48.4073025, lng: 18.3407361 },
    { lat: 48.4071743, lng: 18.3407436 },
    { lat: 48.4070513, lng: 18.3408598 },
    { lat: 48.4067211, lng: 18.3413459 },
    { lat: 48.4063653, lng: 18.3413593 },
    { lat: 48.4061981, lng: 18.3415533 },
    { lat: 48.4062089, lng: 18.3421627 },
    { lat: 48.4060858, lng: 18.342243 },
    { lat: 48.4059903, lng: 18.3421892 },
    { lat: 48.4059563, lng: 18.3418675 },
    { lat: 48.405795, lng: 18.341767 },
    { lat: 48.4057389, lng: 18.341754 },
    { lat: 48.4059692, lng: 18.3425844 },
    { lat: 48.4062152, lng: 18.3434711 },
    { lat: 48.4062681, lng: 18.3440271 },
    { lat: 48.4064728, lng: 18.3452115 },
    { lat: 48.406773, lng: 18.3451139 },
    { lat: 48.4069331, lng: 18.3458512 },
    { lat: 48.4060629, lng: 18.3463401 },
    { lat: 48.4061091, lng: 18.3500109 },
    { lat: 48.4060545, lng: 18.3535168 },
    { lat: 48.4059856, lng: 18.354271 },
    { lat: 48.4061987, lng: 18.358524 },
    { lat: 48.4058408, lng: 18.3585799 },
    { lat: 48.4055852, lng: 18.3593439 },
    { lat: 48.4056071, lng: 18.3603148 },
    { lat: 48.4054884, lng: 18.3609467 },
    { lat: 48.4053641, lng: 18.3629446 },
    { lat: 48.405454, lng: 18.3634779 },
    { lat: 48.4055866, lng: 18.3642645 },
    { lat: 48.4049319, lng: 18.3643808 },
    { lat: 48.4052603, lng: 18.3659028 },
    { lat: 48.4053257, lng: 18.3662037 },
    { lat: 48.4060487, lng: 18.3672182 },
    { lat: 48.4064231, lng: 18.3679542 },
    { lat: 48.4086478, lng: 18.3669735 },
    { lat: 48.4087969, lng: 18.3676966 },
    { lat: 48.4096151, lng: 18.3675791 },
    { lat: 48.4096423, lng: 18.3678011 },
    { lat: 48.4108427, lng: 18.3678287 },
    { lat: 48.4111483, lng: 18.3678599 },
    { lat: 48.4114247, lng: 18.3695382 },
    { lat: 48.4119363, lng: 18.3738535 },
    { lat: 48.4116179, lng: 18.3744671 },
    { lat: 48.4112092, lng: 18.3746444 },
    { lat: 48.4112598, lng: 18.3752996 },
    { lat: 48.4112664, lng: 18.3753794 },
    { lat: 48.4121436, lng: 18.3750697 },
    { lat: 48.413623, lng: 18.3744343 },
    { lat: 48.4137295, lng: 18.3755035 },
    { lat: 48.4146753, lng: 18.3751692 },
    { lat: 48.4153819, lng: 18.375757 },
    { lat: 48.4156925, lng: 18.3759029 },
    { lat: 48.4160456, lng: 18.3759426 },
    { lat: 48.416844, lng: 18.3766772 },
    { lat: 48.4200036, lng: 18.3779106 },
    { lat: 48.4216891, lng: 18.3782694 },
    { lat: 48.422191, lng: 18.3784464 },
    { lat: 48.4224548, lng: 18.3784026 },
    { lat: 48.4230331, lng: 18.3780138 },
    { lat: 48.4245196, lng: 18.3777269 },
    { lat: 48.4253236, lng: 18.3772585 },
    { lat: 48.4257161, lng: 18.3771344 },
    { lat: 48.4260541, lng: 18.3765664 },
    { lat: 48.4273573, lng: 18.3755049 },
    { lat: 48.4276476, lng: 18.3753323 },
    { lat: 48.4282763, lng: 18.3751576 },
    { lat: 48.4286237, lng: 18.3749948 },
    { lat: 48.4294939, lng: 18.3742603 },
    { lat: 48.4309766, lng: 18.3734022 },
    { lat: 48.4309658, lng: 18.3733687 },
    { lat: 48.43049, lng: 18.3717564 },
    { lat: 48.4302091, lng: 18.3712347 },
    { lat: 48.4301356, lng: 18.3707307 },
    { lat: 48.4300127, lng: 18.3704934 },
    { lat: 48.4288322, lng: 18.3694844 },
    { lat: 48.4283556, lng: 18.3688936 },
    { lat: 48.4282675, lng: 18.3686316 },
    { lat: 48.428191, lng: 18.3685578 },
    { lat: 48.4278898, lng: 18.3685944 },
    { lat: 48.4280042, lng: 18.3682246 },
    { lat: 48.4277648, lng: 18.3679421 },
    { lat: 48.4275196, lng: 18.3679328 },
    { lat: 48.427117, lng: 18.3676153 },
    { lat: 48.4269111, lng: 18.3674046 },
    { lat: 48.4267655, lng: 18.3671457 },
    { lat: 48.4266599, lng: 18.3664931 },
    { lat: 48.4267676, lng: 18.3663021 },
    { lat: 48.4266478, lng: 18.3657593 },
    { lat: 48.4260728, lng: 18.3658254 },
    { lat: 48.4259144, lng: 18.3657575 },
    { lat: 48.4256752, lng: 18.365335 },
    { lat: 48.4259, lng: 18.3647398 },
    { lat: 48.4260125, lng: 18.3646168 },
    { lat: 48.4261415, lng: 18.3643439 },
    { lat: 48.4262069, lng: 18.3640816 },
    { lat: 48.4264569, lng: 18.3635524 },
    { lat: 48.4264589, lng: 18.3628492 },
    { lat: 48.4265852, lng: 18.3626491 },
    { lat: 48.4267588, lng: 18.3625041 },
    { lat: 48.4275297, lng: 18.3622734 },
    { lat: 48.4278581, lng: 18.3620348 },
    { lat: 48.4286369, lng: 18.3618006 },
    { lat: 48.4288651, lng: 18.3618049 },
    { lat: 48.429228, lng: 18.3616969 },
    { lat: 48.4292275, lng: 18.3618461 },
    { lat: 48.4297011, lng: 18.3614458 },
    { lat: 48.4318286, lng: 18.3602973 },
    { lat: 48.4317103, lng: 18.3588995 },
    { lat: 48.4332617, lng: 18.3589046 },
    { lat: 48.433168, lng: 18.3582412 },
    { lat: 48.433524, lng: 18.357973 },
    { lat: 48.4339915, lng: 18.3579834 },
    { lat: 48.4340392, lng: 18.3580871 },
    { lat: 48.4347629, lng: 18.3579739 },
    { lat: 48.4347611, lng: 18.3578452 },
    { lat: 48.4348013, lng: 18.3578376 },
    { lat: 48.4350467, lng: 18.3578084 },
    { lat: 48.4351863, lng: 18.3579406 },
    { lat: 48.4353407, lng: 18.3579188 },
    { lat: 48.4354132, lng: 18.357604 },
    { lat: 48.435821, lng: 18.3575104 },
    { lat: 48.4361491, lng: 18.3575105 },
    { lat: 48.436619, lng: 18.3573415 },
    { lat: 48.4366768, lng: 18.3574059 },
    { lat: 48.4375852, lng: 18.3571057 },
    { lat: 48.4376606, lng: 18.3570627 },
    { lat: 48.4375796, lng: 18.3562477 },
    { lat: 48.4376742, lng: 18.3560643 },
    { lat: 48.4378084, lng: 18.3561268 },
    { lat: 48.4380391, lng: 18.3558949 },
    { lat: 48.4381014, lng: 18.3560184 },
    { lat: 48.4383028, lng: 18.3559881 },
    { lat: 48.4384634, lng: 18.355856 },
    { lat: 48.4386486, lng: 18.3558409 },
    { lat: 48.438712, lng: 18.3560041 },
    { lat: 48.4390281, lng: 18.356047 },
    { lat: 48.4396011, lng: 18.3556424 },
    { lat: 48.4397434, lng: 18.3557472 },
    { lat: 48.4398336, lng: 18.3556863 },
    { lat: 48.4402507, lng: 18.3556793 },
    { lat: 48.4406998, lng: 18.355762 },
    { lat: 48.4410152, lng: 18.3556909 },
    { lat: 48.4412584, lng: 18.3552029 },
    { lat: 48.4413998, lng: 18.3555172 },
    { lat: 48.4417196, lng: 18.3548587 },
    { lat: 48.4420485, lng: 18.3547677 },
    { lat: 48.44228, lng: 18.3546142 },
    { lat: 48.4426793, lng: 18.3547523 },
    { lat: 48.4441918, lng: 18.3539467 },
    { lat: 48.4444469, lng: 18.3540251 },
    { lat: 48.4449017, lng: 18.3537488 },
    { lat: 48.4452298, lng: 18.3537255 },
    { lat: 48.4453401, lng: 18.3536023 },
    { lat: 48.4457367, lng: 18.3534508 },
    { lat: 48.4458492, lng: 18.3535032 },
    { lat: 48.4459502, lng: 18.3534178 },
    { lat: 48.4460587, lng: 18.3531197 },
    { lat: 48.4462413, lng: 18.3529791 },
    { lat: 48.4465542, lng: 18.3531656 },
    { lat: 48.4465575, lng: 18.3532706 },
    { lat: 48.4467829, lng: 18.3531997 },
    { lat: 48.4469854, lng: 18.3529883 },
    { lat: 48.4470799, lng: 18.3530036 },
    { lat: 48.4472014, lng: 18.3531551 },
    { lat: 48.4473625, lng: 18.353072 },
    { lat: 48.4473411, lng: 18.3527947 },
    { lat: 48.4474197, lng: 18.3526074 },
    { lat: 48.4476765, lng: 18.3524211 },
    { lat: 48.4478937, lng: 18.3521503 },
    { lat: 48.4479872, lng: 18.351768 },
    { lat: 48.4479616, lng: 18.3515957 },
    { lat: 48.4480227, lng: 18.3514806 },
    { lat: 48.4480117, lng: 18.3513655 },
    { lat: 48.4481685, lng: 18.3512026 },
    { lat: 48.4483297, lng: 18.3513192 },
    { lat: 48.4485692, lng: 18.3513281 },
    { lat: 48.4487841, lng: 18.3516322 },
    { lat: 48.4488225, lng: 18.351295 },
    { lat: 48.4489868, lng: 18.3513664 },
    { lat: 48.4493386, lng: 18.3508428 },
    { lat: 48.4498602, lng: 18.3504511 },
    { lat: 48.449914, lng: 18.3503283 },
    { lat: 48.4500653, lng: 18.3502703 },
    { lat: 48.4502198, lng: 18.3498696 },
    { lat: 48.4503693, lng: 18.3496792 },
    { lat: 48.450445, lng: 18.3496791 },
    { lat: 48.4506089, lng: 18.3495057 },
    { lat: 48.450976, lng: 18.3496344 },
    { lat: 48.4510446, lng: 18.3499263 },
    { lat: 48.4512, lng: 18.3497234 },
    { lat: 48.4516981, lng: 18.3498141 },
    { lat: 48.4517678, lng: 18.3496886 },
    { lat: 48.4519253, lng: 18.3496006 },
    { lat: 48.452122, lng: 18.3497895 },
    { lat: 48.4523443, lng: 18.3498485 },
    { lat: 48.4524316, lng: 18.3500186 },
    { lat: 48.4526789, lng: 18.3501971 },
    { lat: 48.4527417, lng: 18.3499748 },
    { lat: 48.4528635, lng: 18.3497915 },
    { lat: 48.4530528, lng: 18.3500083 },
    { lat: 48.4534077, lng: 18.3497771 },
    { lat: 48.4535189, lng: 18.3500099 },
    { lat: 48.453614, lng: 18.3499768 },
    { lat: 48.4537363, lng: 18.349765 },
    { lat: 48.4539109, lng: 18.3496421 },
    { lat: 48.4539235, lng: 18.3495054 },
    { lat: 48.4541964, lng: 18.3493796 },
    { lat: 48.4543409, lng: 18.3494436 },
    { lat: 48.4546414, lng: 18.3493103 },
    { lat: 48.4549779, lng: 18.3495325 },
    { lat: 48.4553803, lng: 18.3492497 },
    { lat: 48.4557404, lng: 18.3491906 },
    { lat: 48.455823, lng: 18.348879 },
    { lat: 48.4560162, lng: 18.3486041 },
    { lat: 48.4561988, lng: 18.3486803 },
    { lat: 48.4562692, lng: 18.3488469 },
    { lat: 48.4563594, lng: 18.348824 },
    { lat: 48.456456, lng: 18.3486595 },
    { lat: 48.4564504, lng: 18.3485177 },
    { lat: 48.4565175, lng: 18.3485294 },
    { lat: 48.4566977, lng: 18.3483223 },
    { lat: 48.4568979, lng: 18.3479949 },
    { lat: 48.4569336, lng: 18.3478478 },
    { lat: 48.4573606, lng: 18.3474549 },
    { lat: 48.4573657, lng: 18.3472058 },
    { lat: 48.4575046, lng: 18.3471504 },
    { lat: 48.4575194, lng: 18.3470529 },
    { lat: 48.4576822, lng: 18.3469788 },
    { lat: 48.4579169, lng: 18.3467398 },
    { lat: 48.4579714, lng: 18.3465878 },
    { lat: 48.4579429, lng: 18.3462804 },
    { lat: 48.4580952, lng: 18.3459417 },
    { lat: 48.4582462, lng: 18.3457767 },
    { lat: 48.4583763, lng: 18.3452475 },
    { lat: 48.4584662, lng: 18.34522 },
    { lat: 48.4585139, lng: 18.3450849 },
    { lat: 48.4591796, lng: 18.345067 },
    { lat: 48.4592724, lng: 18.3449517 },
    { lat: 48.4592802, lng: 18.3447726 },
    { lat: 48.4594382, lng: 18.3446515 },
    { lat: 48.4595149, lng: 18.3444522 },
    { lat: 48.4596461, lng: 18.3443768 },
    { lat: 48.459753, lng: 18.3444111 },
    { lat: 48.4598682, lng: 18.3442958 },
    { lat: 48.4599977, lng: 18.3442703 },
    { lat: 48.4603203, lng: 18.3438697 },
    { lat: 48.4605582, lng: 18.3437288 },
    { lat: 48.46148, lng: 18.3438089 },
    { lat: 48.4615882, lng: 18.3439878 },
    { lat: 48.4616601, lng: 18.3439 },
    { lat: 48.461888, lng: 18.3440408 },
    { lat: 48.4620044, lng: 18.3440057 },
    { lat: 48.4622513, lng: 18.3442411 },
    { lat: 48.4623336, lng: 18.3444375 },
    { lat: 48.4625843, lng: 18.3446233 },
    { lat: 48.4627531, lng: 18.3445726 },
    { lat: 48.4629375, lng: 18.3447609 },
    { lat: 48.4630875, lng: 18.3447432 },
    { lat: 48.4632348, lng: 18.3446124 },
    { lat: 48.4633913, lng: 18.3446293 },
    { lat: 48.4634158, lng: 18.3447609 },
    { lat: 48.4635586, lng: 18.344753 },
    { lat: 48.4636277, lng: 18.344897 },
    { lat: 48.4637671, lng: 18.3449713 },
    { lat: 48.4639691, lng: 18.344868 },
    { lat: 48.4642746, lng: 18.3450436 },
    { lat: 48.4643666, lng: 18.3450354 },
    { lat: 48.4645665, lng: 18.3448997 },
    { lat: 48.4646412, lng: 18.3450839 },
    { lat: 48.4651369, lng: 18.3452509 },
    { lat: 48.4653144, lng: 18.3451951 },
    { lat: 48.4658614, lng: 18.3454914 },
    { lat: 48.4659548, lng: 18.3456078 },
    { lat: 48.4661683, lng: 18.3455322 },
    { lat: 48.4665696, lng: 18.345605 },
    { lat: 48.4667028, lng: 18.3455492 },
    { lat: 48.4673739, lng: 18.3461027 },
    { lat: 48.468095, lng: 18.3459654 },
    { lat: 48.4685834, lng: 18.3458229 },
    { lat: 48.4687616, lng: 18.345605 },
    { lat: 48.4694007, lng: 18.3454131 },
    { lat: 48.4698234, lng: 18.3454092 },
    { lat: 48.4700671, lng: 18.3451067 },
    { lat: 48.470392, lng: 18.3449066 },
    { lat: 48.4711551, lng: 18.3447348 },
    { lat: 48.4715462, lng: 18.344309 },
    { lat: 48.472005, lng: 18.3439648 },
    { lat: 48.4723325, lng: 18.3438689 },
    { lat: 48.4732623, lng: 18.343174 },
    { lat: 48.4736508, lng: 18.3430781 },
    { lat: 48.4742403, lng: 18.3427503 },
    { lat: 48.4744854, lng: 18.3423349 },
    { lat: 48.4747235, lng: 18.3417174 },
    { lat: 48.4754517, lng: 18.3410909 },
    { lat: 48.4762823, lng: 18.3402134 },
    { lat: 48.4764704, lng: 18.3399486 },
    { lat: 48.4773057, lng: 18.3393592 },
    { lat: 48.4774698, lng: 18.3391682 },
    { lat: 48.4782947, lng: 18.3374756 },
    { lat: 48.4785218, lng: 18.3373395 },
    { lat: 48.4788841, lng: 18.3369877 },
    { lat: 48.4793718, lng: 18.3369316 },
    { lat: 48.4796003, lng: 18.3371012 },
    { lat: 48.4799694, lng: 18.3370845 },
    { lat: 48.480609, lng: 18.3372829 },
    { lat: 48.4808425, lng: 18.3372604 },
    { lat: 48.4812981, lng: 18.3378956 },
    { lat: 48.4819838, lng: 18.3382111 },
    { lat: 48.4823777, lng: 18.3384697 },
    { lat: 48.483286, lng: 18.3385327 },
    { lat: 48.4844975, lng: 18.3382279 },
    { lat: 48.4852485, lng: 18.3383811 },
    { lat: 48.4861144, lng: 18.338329 },
    { lat: 48.4871374, lng: 18.338108 },
    { lat: 48.4874432, lng: 18.3378676 },
    { lat: 48.4877033, lng: 18.3375583 },
    { lat: 48.4881236, lng: 18.3373494 },
    { lat: 48.4887906, lng: 18.3371747 },
    { lat: 48.4891322, lng: 18.3371904 },
    { lat: 48.489877, lng: 18.337453 },
  ],
  TekovskéNemce: [
    { lat: 48.4119482, lng: 18.5313749 },
    { lat: 48.4117918, lng: 18.5314398 },
    { lat: 48.4110928, lng: 18.5304634 },
    { lat: 48.4092405, lng: 18.5306859 },
    { lat: 48.4077323, lng: 18.5300723 },
    { lat: 48.4071624, lng: 18.5294933 },
    { lat: 48.4058106, lng: 18.5291358 },
    { lat: 48.4033155, lng: 18.5286432 },
    { lat: 48.4029063, lng: 18.5281792 },
    { lat: 48.4025607, lng: 18.5280883 },
    { lat: 48.4025667, lng: 18.528035 },
    { lat: 48.4018226, lng: 18.5285334 },
    { lat: 48.4015991, lng: 18.5289008 },
    { lat: 48.4014298, lng: 18.5290634 },
    { lat: 48.401014, lng: 18.529132 },
    { lat: 48.4009296, lng: 18.5290324 },
    { lat: 48.4005405, lng: 18.5288901 },
    { lat: 48.4001996, lng: 18.5284779 },
    { lat: 48.4001901, lng: 18.5283648 },
    { lat: 48.3999947, lng: 18.5279113 },
    { lat: 48.3993792, lng: 18.527486 },
    { lat: 48.3989212, lng: 18.5269717 },
    { lat: 48.3986381, lng: 18.5264406 },
    { lat: 48.3982158, lng: 18.5263698 },
    { lat: 48.3979287, lng: 18.5261122 },
    { lat: 48.3977954, lng: 18.5261305 },
    { lat: 48.3973203, lng: 18.5254309 },
    { lat: 48.3970073, lng: 18.5253604 },
    { lat: 48.3962794, lng: 18.524514 },
    { lat: 48.3962452, lng: 18.5241792 },
    { lat: 48.3958171, lng: 18.5237148 },
    { lat: 48.3959081, lng: 18.5235871 },
    { lat: 48.3958338, lng: 18.5230998 },
    { lat: 48.3956892, lng: 18.5227656 },
    { lat: 48.3956543, lng: 18.5228274 },
    { lat: 48.3954233, lng: 18.5224437 },
    { lat: 48.3952939, lng: 18.5217949 },
    { lat: 48.3948927, lng: 18.5213723 },
    { lat: 48.3945751, lng: 18.5209105 },
    { lat: 48.3945429, lng: 18.5206128 },
    { lat: 48.3940787, lng: 18.5202324 },
    { lat: 48.3936208, lng: 18.5189217 },
    { lat: 48.3919314, lng: 18.5181497 },
    { lat: 48.3913301, lng: 18.5182755 },
    { lat: 48.3903603, lng: 18.5180144 },
    { lat: 48.3902684, lng: 18.5174494 },
    { lat: 48.3900541, lng: 18.5170441 },
    { lat: 48.3896824, lng: 18.5167251 },
    { lat: 48.3888474, lng: 18.5163916 },
    { lat: 48.3884958, lng: 18.5161109 },
    { lat: 48.3878178, lng: 18.515969 },
    { lat: 48.3875469, lng: 18.5156169 },
    { lat: 48.3865212, lng: 18.5156942 },
    { lat: 48.3853479, lng: 18.5151976 },
    { lat: 48.3840358, lng: 18.5149724 },
    { lat: 48.3838338, lng: 18.5149904 },
    { lat: 48.3827645, lng: 18.5161984 },
    { lat: 48.3824151, lng: 18.5165105 },
    { lat: 48.3812868, lng: 18.5167929 },
    { lat: 48.3807917, lng: 18.5168593 },
    { lat: 48.3807656, lng: 18.5167547 },
    { lat: 48.3802984, lng: 18.5168117 },
    { lat: 48.3794683, lng: 18.5167927 },
    { lat: 48.3788009, lng: 18.5163483 },
    { lat: 48.3785154, lng: 18.5163883 },
    { lat: 48.3774445, lng: 18.5163393 },
    { lat: 48.3732558, lng: 18.5171156 },
    { lat: 48.3723709, lng: 18.5174748 },
    { lat: 48.3710362, lng: 18.5178804 },
    { lat: 48.3704594, lng: 18.5176057 },
    { lat: 48.3690452, lng: 18.5172636 },
    { lat: 48.3676502, lng: 18.5175567 },
    { lat: 48.3668347, lng: 18.5178062 },
    { lat: 48.3607564, lng: 18.5185149 },
    { lat: 48.3594873, lng: 18.5180438 },
    { lat: 48.3583954, lng: 18.5173901 },
    { lat: 48.3560279, lng: 18.516699 },
    { lat: 48.3553623, lng: 18.5160046 },
    { lat: 48.3542962, lng: 18.5153052 },
    { lat: 48.3532836, lng: 18.5149645 },
    { lat: 48.3520123, lng: 18.514844 },
    { lat: 48.3478411, lng: 18.5163607 },
    { lat: 48.3458259, lng: 18.5166009 },
    { lat: 48.3445067, lng: 18.5173912 },
    { lat: 48.3420993, lng: 18.5193904 },
    { lat: 48.3411343, lng: 18.5204877 },
    { lat: 48.3403624, lng: 18.5205848 },
    { lat: 48.3399403, lng: 18.5210917 },
    { lat: 48.3399643, lng: 18.5209359 },
    { lat: 48.339904, lng: 18.520698 },
    { lat: 48.3397432, lng: 18.5205045 },
    { lat: 48.3381317, lng: 18.5215149 },
    { lat: 48.3381745, lng: 18.5217003 },
    { lat: 48.3366704, lng: 18.5223647 },
    { lat: 48.3361012, lng: 18.5221513 },
    { lat: 48.335789, lng: 18.521799 },
    { lat: 48.335785, lng: 18.521805 },
    { lat: 48.335427, lng: 18.522339 },
    { lat: 48.335032, lng: 18.52293 },
    { lat: 48.334638, lng: 18.523374 },
    { lat: 48.333599, lng: 18.524543 },
    { lat: 48.333002, lng: 18.525216 },
    { lat: 48.335836, lng: 18.530982 },
    { lat: 48.331799, lng: 18.536377 },
    { lat: 48.331884, lng: 18.536376 },
    { lat: 48.331925, lng: 18.536448 },
    { lat: 48.336159, lng: 18.540943 },
    { lat: 48.338491, lng: 18.536931 },
    { lat: 48.338516, lng: 18.53702 },
    { lat: 48.339201, lng: 18.538302 },
    { lat: 48.339952, lng: 18.537563 },
    { lat: 48.34103, lng: 18.536892 },
    { lat: 48.341319, lng: 18.536835 },
    { lat: 48.341496, lng: 18.537241 },
    { lat: 48.341845, lng: 18.537751 },
    { lat: 48.342324, lng: 18.538519 },
    { lat: 48.342325, lng: 18.539968 },
    { lat: 48.342309, lng: 18.540897 },
    { lat: 48.342414, lng: 18.541606 },
    { lat: 48.342703, lng: 18.542556 },
    { lat: 48.343051, lng: 18.543129 },
    { lat: 48.343197, lng: 18.543465 },
    { lat: 48.343251, lng: 18.544167 },
    { lat: 48.343529, lng: 18.544241 },
    { lat: 48.343589, lng: 18.544384 },
    { lat: 48.343533, lng: 18.544632 },
    { lat: 48.343718, lng: 18.544941 },
    { lat: 48.343918, lng: 18.545558 },
    { lat: 48.343975, lng: 18.545652 },
    { lat: 48.343916, lng: 18.545996 },
    { lat: 48.344085, lng: 18.546241 },
    { lat: 48.344129, lng: 18.54632 },
    { lat: 48.344172, lng: 18.546651 },
    { lat: 48.344186, lng: 18.546724 },
    { lat: 48.344336, lng: 18.547045 },
    { lat: 48.344484, lng: 18.547213 },
    { lat: 48.344652, lng: 18.54735 },
    { lat: 48.344716, lng: 18.547416 },
    { lat: 48.344752, lng: 18.547863 },
    { lat: 48.344756, lng: 18.548205 },
    { lat: 48.34478, lng: 18.54818 },
    { lat: 48.345058, lng: 18.547886 },
    { lat: 48.344997, lng: 18.547574 },
    { lat: 48.344957, lng: 18.547006 },
    { lat: 48.344781, lng: 18.545851 },
    { lat: 48.344688, lng: 18.545309 },
    { lat: 48.344714, lng: 18.545294 },
    { lat: 48.344776, lng: 18.545266 },
    { lat: 48.344851, lng: 18.545235 },
    { lat: 48.34531, lng: 18.546596 },
    { lat: 48.345533, lng: 18.54721 },
    { lat: 48.345555, lng: 18.547265 },
    { lat: 48.345635, lng: 18.547113 },
    { lat: 48.345691, lng: 18.547181 },
    { lat: 48.345766, lng: 18.547274 },
    { lat: 48.345873, lng: 18.547408 },
    { lat: 48.346376, lng: 18.548037 },
    { lat: 48.346819, lng: 18.547879 },
    { lat: 48.346882, lng: 18.54768 },
    { lat: 48.347064, lng: 18.547346 },
    { lat: 48.347146, lng: 18.547298 },
    { lat: 48.347438, lng: 18.547399 },
    { lat: 48.347758, lng: 18.5476 },
    { lat: 48.348158, lng: 18.547314 },
    { lat: 48.348359, lng: 18.547122 },
    { lat: 48.348528, lng: 18.546762 },
    { lat: 48.348829, lng: 18.546124 },
    { lat: 48.349442, lng: 18.545456 },
    { lat: 48.349816, lng: 18.54518 },
    { lat: 48.350011, lng: 18.545057 },
    { lat: 48.350159, lng: 18.544932 },
    { lat: 48.35018, lng: 18.544966 },
    { lat: 48.35059, lng: 18.545652 },
    { lat: 48.35089, lng: 18.546016 },
    { lat: 48.351411, lng: 18.546404 },
    { lat: 48.352864, lng: 18.548898 },
    { lat: 48.353023, lng: 18.549124 },
    { lat: 48.353012, lng: 18.549366 },
    { lat: 48.352997, lng: 18.549659 },
    { lat: 48.35299, lng: 18.549698 },
    { lat: 48.352702, lng: 18.551114 },
    { lat: 48.35311, lng: 18.551709 },
    { lat: 48.353662, lng: 18.552444 },
    { lat: 48.354415, lng: 18.553548 },
    { lat: 48.354574, lng: 18.553777 },
    { lat: 48.354661, lng: 18.553956 },
    { lat: 48.355163, lng: 18.554977 },
    { lat: 48.355478, lng: 18.55562 },
    { lat: 48.355643, lng: 18.555955 },
    { lat: 48.355789, lng: 18.556254 },
    { lat: 48.355892, lng: 18.556465 },
    { lat: 48.356877, lng: 18.557702 },
    { lat: 48.357568, lng: 18.563576 },
    { lat: 48.358694, lng: 18.566959 },
    { lat: 48.359472, lng: 18.567038 },
    { lat: 48.361829, lng: 18.568455 },
    { lat: 48.362901, lng: 18.569094 },
    { lat: 48.36295, lng: 18.569093 },
    { lat: 48.363296, lng: 18.569776 },
    { lat: 48.363446, lng: 18.569846 },
    { lat: 48.363705, lng: 18.569477 },
    { lat: 48.363748, lng: 18.56897 },
    { lat: 48.363836, lng: 18.568688 },
    { lat: 48.364163, lng: 18.56856 },
    { lat: 48.364297, lng: 18.56843 },
    { lat: 48.364752, lng: 18.568076 },
    { lat: 48.365205, lng: 18.567726 },
    { lat: 48.365282, lng: 18.567738 },
    { lat: 48.365347, lng: 18.567747 },
    { lat: 48.365469, lng: 18.567765 },
    { lat: 48.365604, lng: 18.567633 },
    { lat: 48.365866, lng: 18.567245 },
    { lat: 48.366036, lng: 18.567115 },
    { lat: 48.366169, lng: 18.566945 },
    { lat: 48.366328, lng: 18.566844 },
    { lat: 48.366613, lng: 18.566795 },
    { lat: 48.366716, lng: 18.566623 },
    { lat: 48.366844, lng: 18.566467 },
    { lat: 48.367179, lng: 18.566404 },
    { lat: 48.367422, lng: 18.566109 },
    { lat: 48.367522, lng: 18.566007 },
    { lat: 48.3677221, lng: 18.5657758 },
    { lat: 48.36776, lng: 18.565867 },
    { lat: 48.368244, lng: 18.566963 },
    { lat: 48.368573, lng: 18.567658 },
    { lat: 48.369061, lng: 18.568442 },
    { lat: 48.369649, lng: 18.568426 },
    { lat: 48.370477, lng: 18.567273 },
    { lat: 48.37053, lng: 18.567201 },
    { lat: 48.370612, lng: 18.567087 },
    { lat: 48.371349, lng: 18.568528 },
    { lat: 48.371751, lng: 18.569357 },
    { lat: 48.372203, lng: 18.570336 },
    { lat: 48.37278, lng: 18.571267 },
    { lat: 48.373144, lng: 18.571813 },
    { lat: 48.373728, lng: 18.572502 },
    { lat: 48.373748, lng: 18.572528 },
    { lat: 48.373768, lng: 18.572547 },
    { lat: 48.374788, lng: 18.573529 },
    { lat: 48.375536, lng: 18.574364 },
    { lat: 48.375922, lng: 18.576243 },
    { lat: 48.376622, lng: 18.575823 },
    { lat: 48.377418, lng: 18.575075 },
    { lat: 48.37807, lng: 18.574528 },
    { lat: 48.378786, lng: 18.574116 },
    { lat: 48.379857, lng: 18.573647 },
    { lat: 48.380313, lng: 18.57346 },
    { lat: 48.381056, lng: 18.573131 },
    { lat: 48.382079, lng: 18.572491 },
    { lat: 48.382516, lng: 18.572245 },
    { lat: 48.383115, lng: 18.571985 },
    { lat: 48.383615, lng: 18.571804 },
    { lat: 48.384568, lng: 18.571871 },
    { lat: 48.385024, lng: 18.571946 },
    { lat: 48.38577, lng: 18.572698 },
    { lat: 48.386938, lng: 18.573748 },
    { lat: 48.386965, lng: 18.57377 },
    { lat: 48.387009, lng: 18.573742 },
    { lat: 48.387144, lng: 18.573921 },
    { lat: 48.387501, lng: 18.573767 },
    { lat: 48.387986, lng: 18.573007 },
    { lat: 48.388306, lng: 18.572868 },
    { lat: 48.388527, lng: 18.572652 },
    { lat: 48.388933, lng: 18.572198 },
    { lat: 48.389435, lng: 18.571454 },
    { lat: 48.389556, lng: 18.571117 },
    { lat: 48.389899, lng: 18.570511 },
    { lat: 48.390008, lng: 18.570393 },
    { lat: 48.390529, lng: 18.570047 },
    { lat: 48.390658, lng: 18.570005 },
    { lat: 48.390931, lng: 18.570304 },
    { lat: 48.391668, lng: 18.570124 },
    { lat: 48.392454, lng: 18.569614 },
    { lat: 48.392664, lng: 18.569479 },
    { lat: 48.392783, lng: 18.569157 },
    { lat: 48.392934, lng: 18.569095 },
    { lat: 48.393274, lng: 18.568862 },
    { lat: 48.393434, lng: 18.568675 },
    { lat: 48.39362, lng: 18.568805 },
    { lat: 48.393912, lng: 18.56863 },
    { lat: 48.394439, lng: 18.568596 },
    { lat: 48.394841, lng: 18.568699 },
    { lat: 48.395049, lng: 18.568703 },
    { lat: 48.395303, lng: 18.568652 },
    { lat: 48.395808, lng: 18.568783 },
    { lat: 48.396193, lng: 18.568677 },
    { lat: 48.396347, lng: 18.568796 },
    { lat: 48.396572, lng: 18.568551 },
    { lat: 48.396971, lng: 18.568526 },
    { lat: 48.397714, lng: 18.568375 },
    { lat: 48.397987, lng: 18.568747 },
    { lat: 48.398218, lng: 18.568748 },
    { lat: 48.398354, lng: 18.569139 },
    { lat: 48.398529, lng: 18.569301 },
    { lat: 48.398799, lng: 18.569228 },
    { lat: 48.399214, lng: 18.569618 },
    { lat: 48.399836, lng: 18.569533 },
    { lat: 48.399901, lng: 18.56952 },
    { lat: 48.400228, lng: 18.569577 },
    { lat: 48.400881, lng: 18.569669 },
    { lat: 48.400965, lng: 18.569922 },
    { lat: 48.401515, lng: 18.569815 },
    { lat: 48.402154, lng: 18.570273 },
    { lat: 48.402408, lng: 18.570178 },
    { lat: 48.402517, lng: 18.570241 },
    { lat: 48.402586, lng: 18.570435 },
    { lat: 48.402799, lng: 18.570349 },
    { lat: 48.403475, lng: 18.570527 },
    { lat: 48.403596, lng: 18.570793 },
    { lat: 48.404045, lng: 18.570748 },
    { lat: 48.404103, lng: 18.57063 },
    { lat: 48.404352, lng: 18.570435 },
    { lat: 48.404438, lng: 18.570263 },
    { lat: 48.40462, lng: 18.56994 },
    { lat: 48.404725, lng: 18.569819 },
    { lat: 48.404755, lng: 18.569785 },
    { lat: 48.404825, lng: 18.569632 },
    { lat: 48.404927, lng: 18.569506 },
    { lat: 48.404961, lng: 18.569307 },
    { lat: 48.404974, lng: 18.569058 },
    { lat: 48.404939, lng: 18.568864 },
    { lat: 48.405026, lng: 18.568724 },
    { lat: 48.405081, lng: 18.568706 },
    { lat: 48.405159, lng: 18.568504 },
    { lat: 48.405143, lng: 18.568294 },
    { lat: 48.405052, lng: 18.56797 },
    { lat: 48.405103, lng: 18.567813 },
    { lat: 48.405169, lng: 18.567386 },
    { lat: 48.405236, lng: 18.567237 },
    { lat: 48.405268, lng: 18.567084 },
    { lat: 48.405202, lng: 18.566851 },
    { lat: 48.405272, lng: 18.566586 },
    { lat: 48.40529, lng: 18.56626 },
    { lat: 48.405509, lng: 18.566126 },
    { lat: 48.405743, lng: 18.566035 },
    { lat: 48.405798, lng: 18.565926 },
    { lat: 48.406315, lng: 18.566464 },
    { lat: 48.40677, lng: 18.566462 },
    { lat: 48.407127, lng: 18.566434 },
    { lat: 48.407166, lng: 18.566431 },
    { lat: 48.407327, lng: 18.566419 },
    { lat: 48.407514, lng: 18.566355 },
    { lat: 48.407853, lng: 18.565952 },
    { lat: 48.40789, lng: 18.565909 },
    { lat: 48.408049, lng: 18.56572 },
    { lat: 48.408642, lng: 18.565119 },
    { lat: 48.408848, lng: 18.564894 },
    { lat: 48.409103, lng: 18.564606 },
    { lat: 48.410209, lng: 18.564154 },
    { lat: 48.411153, lng: 18.56375 },
    { lat: 48.411958, lng: 18.56339 },
    { lat: 48.412274, lng: 18.563247 },
    { lat: 48.412916, lng: 18.56374 },
    { lat: 48.413285, lng: 18.563232 },
    { lat: 48.413973, lng: 18.562697 },
    { lat: 48.414891, lng: 18.562365 },
    { lat: 48.415542, lng: 18.561897 },
    { lat: 48.415755, lng: 18.5618 },
    { lat: 48.416491, lng: 18.561465 },
    { lat: 48.416695, lng: 18.561372 },
    { lat: 48.417147, lng: 18.56115 },
    { lat: 48.417679, lng: 18.56089 },
    { lat: 48.417713, lng: 18.560875 },
    { lat: 48.418203, lng: 18.56065 },
    { lat: 48.418151, lng: 18.559091 },
    { lat: 48.418081, lng: 18.557931 },
    { lat: 48.41797, lng: 18.556814 },
    { lat: 48.417966, lng: 18.556751 },
    { lat: 48.417856, lng: 18.554173 },
    { lat: 48.417715, lng: 18.552141 },
    { lat: 48.417661, lng: 18.551621 },
    { lat: 48.417615, lng: 18.551172 },
    { lat: 48.417614, lng: 18.551163 },
    { lat: 48.417503, lng: 18.550076 },
    { lat: 48.417498, lng: 18.549817 },
    { lat: 48.41749, lng: 18.549209 },
    { lat: 48.417477, lng: 18.548394 },
    { lat: 48.417467, lng: 18.547783 },
    { lat: 48.41746, lng: 18.547398 },
    { lat: 48.417448, lng: 18.546623 },
    { lat: 48.417447, lng: 18.546509 },
    { lat: 48.41743, lng: 18.545533 },
    { lat: 48.417419, lng: 18.544767 },
    { lat: 48.417413, lng: 18.544385 },
    { lat: 48.417398, lng: 18.543464 },
    { lat: 48.417431, lng: 18.54294 },
    { lat: 48.417491, lng: 18.542027 },
    { lat: 48.417466, lng: 18.540744 },
    { lat: 48.417458, lng: 18.5404 },
    { lat: 48.417438, lng: 18.539336 },
    { lat: 48.417484, lng: 18.539171 },
    { lat: 48.4166915, lng: 18.5381298 },
    { lat: 48.4165849, lng: 18.5378731 },
    { lat: 48.4161278, lng: 18.5373566 },
    { lat: 48.4151547, lng: 18.5366214 },
    { lat: 48.4150046, lng: 18.5355322 },
    { lat: 48.4145754, lng: 18.5347189 },
    { lat: 48.4142045, lng: 18.5345206 },
    { lat: 48.4139365, lng: 18.5340138 },
    { lat: 48.4139465, lng: 18.5334994 },
    { lat: 48.4141653, lng: 18.5334372 },
    { lat: 48.4138851, lng: 18.5326474 },
    { lat: 48.4135374, lng: 18.532868 },
    { lat: 48.4125138, lng: 18.5325529 },
    { lat: 48.4119482, lng: 18.5313749 },
  ],
};

export default UnitsZlatéMoravce;
