import { useState, useEffect } from "react";
import { averagePriceByCodeService } from "../../../../services/averagePriceByCodeService";
import { averagePriceService } from "../../../../services/averagePriceService";

export default function FlatCalc({ PMResult, statuses, getAllStatuses, setPMResult, regDisUni }) {
  const [usableArea, setUsableArea] = useState({ area: "10", areaS: "" });
  const [condition, setCondition] = useState({ cond: "", conditionS: "" });
  const [response, setResponse] = useState();
  const [specify, setSpecify] = useState(false);
  const uMin = 10;
  const uMax = 1000;
  const prevObec = "";

  const getAveragePrice = async (parent) => {
    if (PMResult?.data?.obec && PMResult?.data?.parent && PMResult.region) {
      const request = await averagePriceByCodeService.getAveragePrice(
        decodeURIComponent("105"),
        decodeURIComponent(condition.cond),
        decodeURIComponent(usableArea.area),
        null,
        decodeURIComponent(PMResult?.data?.code),
      );
      const resp = await request.json();
      setResponse(resp);
      console.log("resp = ", resp);
      setUsableArea({ ...usableArea, areaS: usableArea.area });
      setCondition({ ...condition, conditionS: condition.cond });
      return;
    }
    // if (PMResult?.data?.parent && PMResult.region) {
    if (regDisUni.district) {
      const request = await averagePriceService.getDistrictAvgPrice(
        decodeURIComponent(parent),
        decodeURIComponent("105"),
        decodeURIComponent(condition.cond),
        decodeURIComponent(usableArea.area),
        null,
      );
      const response = await request.json();
      setPMResult({
        ...PMResult,
        active: true,
        data: {
          ...PMResult.data,
          avgBytM2: response.avgPrice,
          obec: "",
          parent: PMResult?.data?.obec,
        },
      });
      console.log("response district = ", response);
      return;
    }
    // if (PMResult.region) {
    if (regDisUni.region) {
      console.log("PMResult flat = ", PMResult);
      const request = await averagePriceService.getRegionAvgPrice(
        decodeURIComponent(parent),
        decodeURIComponent("105"),
        decodeURIComponent(condition.cond),
        decodeURIComponent(usableArea.area),
        null,
      );
      const response = await request.json();
      // setPMResult({ ...PMResult, active: true, data: response });
      setPMResult({
        ...PMResult,
        active: true,
        data: {
          ...PMResult.data,
          avgBytM2: response.avgPrice,
          obec: "",
          parent: "",
        },
      });
      console.log("response regions = ", response);
      return;
    } else {
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    if (PMResult?.data?.obec !== prevObec) {
      setResponse();
      setSpecify(false);
    }
  }, [PMResult?.data?.obec]);

  const calculation = () => {
    if (!specify || response?.avgPrice) {
      setSpecify(true);
      setResponse();
    } else {
      getAveragePrice();
    }
  };

  return PMResult.byt ? (
    <div className="flex flex-col">
      <div className="font-medium mb-1">
        Priemerná trhová cena za m²:{" "}
        {response?.avgPrice
          ? response?.avgPrice
          : PMResult?.data?.avgBytM2
            ? PMResult?.data?.avgBytM2
            : PMResult?.data?.avgPrice}{" "}
        €
      </div>
      {specify ? (
        <div>
          <p className="">Stav:</p>
          <select
            name="status"
            value={condition.cond}
            onClick={() => getAllStatuses()}
            onChange={(event) => setCondition({ ...condition, cond: event.target.value })}
            className="w-full mb-4 p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
          >
            <option value="">Stav</option>
            {statuses?.map((status) => (
              <option value={status.code} key={status.name}>
                {status.name_diacritics}
              </option>
            ))}
          </select>
          <div className="flex ">
            <p className="">Úžit. plocha: </p>
            <input
              name="usableArea"
              value={usableArea.area}
              onChange={(event) => setUsableArea({ ...usableArea, area: event.target.value })}
              min={uMin}
              max={uMax}
              className="h-8 flex-1 w-1/3 ml-2 p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-sky-500"
              type="number"
            />
          </div>
          <input
            className="w-full "
            onChange={(event) => setUsableArea({ ...usableArea, area: event.target.value })}
            type={"range"}
            min={uMin}
            max={uMax}
            step={1}
            value={usableArea.area}
            list={"tick-list"}
          />
          <div className="mb-2 flex justify-between">
            <span className="mb-2 -mt-1.5">{uMin} m²</span>
            <span className="mb-2 -mt-1.5">{uMax} m²</span>
          </div>
        </div>
      ) : null}
      {response?.avgPrice ? (
        <p className="w-full mb-2">
          {`Priemerná trhová cena bola vypočítaná na základe`} {response?.countOfObjects}
          {` podobných nehnuteľností s presnosťou`} {response?.relevancy} {`%.`}
        </p>
      ) : null}
      {!specify ? (
        <div className="flex justify-center mb-1">
          <button
            className="bg-sky-200 w-auto p-2 rounded-xl disabled:bg-gray-200"
            onClick={() => calculation()}
            disabled={PMResult.region === ""}
          >
            Upresniť kalkuláciu
          </button>
        </div>
      ) : (
        <div className="flex justify-end gap-2 mb-1">
          <button
            disabled={
              (usableArea.area === "10" && condition.cond === "") ||
              (usableArea.area === usableArea.areaS && condition.cond === condition.conditionS)
            }
            className="disabled:opacity-60 disabled:bg-slate-300 disabled:cursor-not-allowed bg-sky-200 w-20 p-2 rounded-xl "
            onClick={() => getAveragePrice(PMResult?.parent ? PMResult?.parent : "Region" + PMResult.region)}
          >
            Použiť
          </button>
          <button
            className="bg-sky-200 w-20 p-2 rounded-xl"
            onClick={() => {
              setResponse();
              setSpecify(false);
              setCondition({ ...condition, cond: "" });
              setUsableArea({ ...usableArea, area: "10" });
            }}
          >
            Zrušiť
          </button>
        </div>
      )}
    </div>
  ) : null;
}
