import React from "react";

const LandArea = (props) => {

    const setOpen = (event) => {
      props.changeArea(event.target.value)
      props.changeIsOpen(false)
      props.resetAvgPrice([])
    }

    return (
      <div className={`relative w-screen px-2 sm:max-w-sm  ${props.catVal.substring(0, 1)  === '1' || props.catVal === "" ? "hidden" : ""}`}>
        <div className="flex">
        <p className="text-white">Plocha pozemku: </p>
        <input name="landArea" value={props.landArea} onChange={(event) => setOpen(event)} min={10} className='flex-1 h-8 ml-2 p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-sky-500' type="number" />
        </div>
        <input
          className="w-full"
          onChange={(event) => setOpen(event)}
          type={"range"}
          min={props.min}
          max={props.max}
          step={1}
          value={props.landArea}
          list={"tick-list"}
        />
        <div className="mb-2 flex justify-between">
          <span className="mb-2 -mt-1.5">{props.min} m²</span>
          <span className="mb-2 -mt-1.5">{props.max} m²</span>
        </div>
      </div>
    );
}

export default LandArea