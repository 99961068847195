const UnitsPoprad = {
  Batizovce: [
    { lat: 49.0619399, lng: 20.2193941 },
    { lat: 49.0620096, lng: 20.2196679 },
    { lat: 49.064161, lng: 20.2198489 },
    { lat: 49.0653222, lng: 20.2201036 },
    { lat: 49.0666682, lng: 20.2204283 },
    { lat: 49.067, lng: 20.2205066 },
    { lat: 49.0674564, lng: 20.2207235 },
    { lat: 49.0684722, lng: 20.2210405 },
    { lat: 49.0691115, lng: 20.2215263 },
    { lat: 49.0695255, lng: 20.2216571 },
    { lat: 49.0700554, lng: 20.2219701 },
    { lat: 49.0703264, lng: 20.2219919 },
    { lat: 49.0706475, lng: 20.2216787 },
    { lat: 49.0710739, lng: 20.2213871 },
    { lat: 49.0717624, lng: 20.2213678 },
    { lat: 49.0722276, lng: 20.2214584 },
    { lat: 49.0732428, lng: 20.2218958 },
    { lat: 49.074909, lng: 20.2215429 },
    { lat: 49.0752859, lng: 20.2213355 },
    { lat: 49.0754989, lng: 20.2213174 },
    { lat: 49.0759653, lng: 20.2214927 },
    { lat: 49.0768295, lng: 20.2216422 },
    { lat: 49.0772683, lng: 20.2216925 },
    { lat: 49.077708, lng: 20.2216195 },
    { lat: 49.077959, lng: 20.2210745 },
    { lat: 49.078011, lng: 20.2208269 },
    { lat: 49.0783234, lng: 20.2205217 },
    { lat: 49.0783198, lng: 20.2203463 },
    { lat: 49.0784373, lng: 20.2200722 },
    { lat: 49.0788231, lng: 20.2197807 },
    { lat: 49.0790094, lng: 20.2197349 },
    { lat: 49.0792543, lng: 20.2198055 },
    { lat: 49.0793892, lng: 20.2196849 },
    { lat: 49.0794878, lng: 20.2194686 },
    { lat: 49.0795798, lng: 20.2194332 },
    { lat: 49.0798304, lng: 20.2195331 },
    { lat: 49.0799929, lng: 20.2194607 },
    { lat: 49.0800329, lng: 20.2193629 },
    { lat: 49.0801739, lng: 20.2192807 },
    { lat: 49.0803501, lng: 20.2190651 },
    { lat: 49.0803905, lng: 20.2190072 },
    { lat: 49.0804186, lng: 20.2186622 },
    { lat: 49.0806407, lng: 20.2182693 },
    { lat: 49.0807805, lng: 20.2182198 },
    { lat: 49.0820836, lng: 20.2158097 },
    { lat: 49.0840098, lng: 20.2141465 },
    { lat: 49.0853836, lng: 20.2116165 },
    { lat: 49.0873682, lng: 20.2092751 },
    { lat: 49.0897771, lng: 20.207179 },
    { lat: 49.0899124, lng: 20.2071802 },
    { lat: 49.0904989, lng: 20.2064687 },
    { lat: 49.092481, lng: 20.2070688 },
    { lat: 49.0932027, lng: 20.2044261 },
    { lat: 49.093393, lng: 20.203378 },
    { lat: 49.0936453, lng: 20.2023871 },
    { lat: 49.0929126, lng: 20.2019472 },
    { lat: 49.0921574, lng: 20.2015885 },
    { lat: 49.0925371, lng: 20.2004562 },
    { lat: 49.0933519, lng: 20.1975291 },
    { lat: 49.0934408, lng: 20.197047 },
    { lat: 49.0936694, lng: 20.1950356 },
    { lat: 49.0939169, lng: 20.1947734 },
    { lat: 49.0941857, lng: 20.1942297 },
    { lat: 49.0942585, lng: 20.1940491 },
    { lat: 49.0926828, lng: 20.193335 },
    { lat: 49.0914938, lng: 20.1919871 },
    { lat: 49.0905953, lng: 20.1902897 },
    { lat: 49.0911901, lng: 20.1889671 },
    { lat: 49.0926006, lng: 20.1866959 },
    { lat: 49.0910098, lng: 20.1854916 },
    { lat: 49.0899862, lng: 20.1851271 },
    { lat: 49.0901246, lng: 20.1848987 },
    { lat: 49.0902019, lng: 20.1849454 },
    { lat: 49.0903756, lng: 20.1846664 },
    { lat: 49.0904397, lng: 20.1843284 },
    { lat: 49.0906314, lng: 20.1840814 },
    { lat: 49.090798, lng: 20.1839687 },
    { lat: 49.0909141, lng: 20.1839683 },
    { lat: 49.0910537, lng: 20.1835702 },
    { lat: 49.091143, lng: 20.1834948 },
    { lat: 49.091253, lng: 20.1832363 },
    { lat: 49.0915605, lng: 20.1834012 },
    { lat: 49.0918381, lng: 20.183281 },
    { lat: 49.0922633, lng: 20.1827889 },
    { lat: 49.0925175, lng: 20.182838 },
    { lat: 49.0929482, lng: 20.1824539 },
    { lat: 49.0929534, lng: 20.1823007 },
    { lat: 49.0931733, lng: 20.1817435 },
    { lat: 49.0931299, lng: 20.1815014 },
    { lat: 49.0933553, lng: 20.180492 },
    { lat: 49.0936929, lng: 20.1802897 },
    { lat: 49.0938375, lng: 20.1802986 },
    { lat: 49.0940136, lng: 20.1801123 },
    { lat: 49.0942502, lng: 20.1793524 },
    { lat: 49.0936436, lng: 20.1790549 },
    { lat: 49.0936588, lng: 20.1786428 },
    { lat: 49.0927847, lng: 20.1781586 },
    { lat: 49.0932308, lng: 20.1776889 },
    { lat: 49.0934669, lng: 20.1772545 },
    { lat: 49.0942733, lng: 20.1762412 },
    { lat: 49.0945846, lng: 20.1762825 },
    { lat: 49.0948218, lng: 20.1760711 },
    { lat: 49.0951238, lng: 20.1761631 },
    { lat: 49.0954234, lng: 20.1758536 },
    { lat: 49.0934292, lng: 20.1751843 },
    { lat: 49.0930717, lng: 20.1753953 },
    { lat: 49.0926391, lng: 20.1759273 },
    { lat: 49.0920081, lng: 20.1765632 },
    { lat: 49.0916512, lng: 20.1767921 },
    { lat: 49.091199, lng: 20.1765778 },
    { lat: 49.0920069, lng: 20.17566 },
    { lat: 49.0927203, lng: 20.1752231 },
    { lat: 49.0932816, lng: 20.1744186 },
    { lat: 49.0933952, lng: 20.1741623 },
    { lat: 49.0935124, lng: 20.1736275 },
    { lat: 49.093499, lng: 20.1726514 },
    { lat: 49.0936512, lng: 20.1718895 },
    { lat: 49.0920987, lng: 20.1713105 },
    { lat: 49.0921144, lng: 20.1711431 },
    { lat: 49.0922776, lng: 20.1711933 },
    { lat: 49.0923089, lng: 20.171051 },
    { lat: 49.0923877, lng: 20.1710724 },
    { lat: 49.0927198, lng: 20.1707333 },
    { lat: 49.09276, lng: 20.1706114 },
    { lat: 49.0934119, lng: 20.1709217 },
    { lat: 49.0934913, lng: 20.1704828 },
    { lat: 49.0930671, lng: 20.1704068 },
    { lat: 49.092979, lng: 20.1701218 },
    { lat: 49.0932193, lng: 20.1699349 },
    { lat: 49.0934963, lng: 20.1698528 },
    { lat: 49.0935543, lng: 20.1700037 },
    { lat: 49.0936505, lng: 20.1699901 },
    { lat: 49.0939427, lng: 20.1695487 },
    { lat: 49.0945204, lng: 20.1693556 },
    { lat: 49.0971909, lng: 20.1639675 },
    { lat: 49.097615, lng: 20.16412 },
    { lat: 49.0977496, lng: 20.1641121 },
    { lat: 49.0980425, lng: 20.1633824 },
    { lat: 49.0981957, lng: 20.1633829 },
    { lat: 49.0982292, lng: 20.1631537 },
    { lat: 49.0984114, lng: 20.1631415 },
    { lat: 49.0984514, lng: 20.1629908 },
    { lat: 49.0988173, lng: 20.1626779 },
    { lat: 49.098959, lng: 20.1627046 },
    { lat: 49.0991481, lng: 20.1623334 },
    { lat: 49.0993443, lng: 20.1623134 },
    { lat: 49.0990738, lng: 20.1613808 },
    { lat: 49.0996008, lng: 20.1606238 },
    { lat: 49.099732, lng: 20.1602119 },
    { lat: 49.0994776, lng: 20.1596527 },
    { lat: 49.0991795, lng: 20.1593803 },
    { lat: 49.09917, lng: 20.1586959 },
    { lat: 49.0993231, lng: 20.1584128 },
    { lat: 49.0985817, lng: 20.157256 },
    { lat: 49.0983726, lng: 20.1568424 },
    { lat: 49.0971472, lng: 20.1575674 },
    { lat: 49.0970381, lng: 20.1579074 },
    { lat: 49.0966086, lng: 20.1584587 },
    { lat: 49.0958528, lng: 20.1586214 },
    { lat: 49.0953289, lng: 20.1585914 },
    { lat: 49.0939336, lng: 20.156964 },
    { lat: 49.0936903, lng: 20.1572397 },
    { lat: 49.0934943, lng: 20.157296 },
    { lat: 49.0933584, lng: 20.157806 },
    { lat: 49.0926582, lng: 20.1589117 },
    { lat: 49.091995, lng: 20.1591915 },
    { lat: 49.0917791, lng: 20.1588634 },
    { lat: 49.0914891, lng: 20.1590765 },
    { lat: 49.0911462, lng: 20.1589216 },
    { lat: 49.0891843, lng: 20.1553543 },
    { lat: 49.0890233, lng: 20.1554128 },
    { lat: 49.0888711, lng: 20.1556608 },
    { lat: 49.0885751, lng: 20.1555522 },
    { lat: 49.0883347, lng: 20.1558469 },
    { lat: 49.0882851, lng: 20.1558178 },
    { lat: 49.087981, lng: 20.1559549 },
    { lat: 49.087807, lng: 20.1561932 },
    { lat: 49.0875117, lng: 20.1563561 },
    { lat: 49.0872271, lng: 20.1560855 },
    { lat: 49.0871602, lng: 20.1561014 },
    { lat: 49.0867422, lng: 20.155453 },
    { lat: 49.0866488, lng: 20.1551706 },
    { lat: 49.0860784, lng: 20.1555758 },
    { lat: 49.0860921, lng: 20.1556403 },
    { lat: 49.0858176, lng: 20.1559285 },
    { lat: 49.0857345, lng: 20.1556283 },
    { lat: 49.0854257, lng: 20.1557009 },
    { lat: 49.0850775, lng: 20.1559797 },
    { lat: 49.084828, lng: 20.1560723 },
    { lat: 49.0847826, lng: 20.156235 },
    { lat: 49.0848124, lng: 20.1563299 },
    { lat: 49.0847253, lng: 20.1563772 },
    { lat: 49.0846481, lng: 20.1561113 },
    { lat: 49.0845704, lng: 20.1561401 },
    { lat: 49.0845369, lng: 20.1560322 },
    { lat: 49.0840025, lng: 20.1561599 },
    { lat: 49.0839439, lng: 20.1559882 },
    { lat: 49.0831627, lng: 20.1563092 },
    { lat: 49.0822519, lng: 20.1571584 },
    { lat: 49.0818112, lng: 20.1576481 },
    { lat: 49.0816336, lng: 20.157998 },
    { lat: 49.0809081, lng: 20.155997 },
    { lat: 49.0807991, lng: 20.1560035 },
    { lat: 49.0806987, lng: 20.1561418 },
    { lat: 49.08042, lng: 20.1562644 },
    { lat: 49.0803388, lng: 20.1561221 },
    { lat: 49.0805128, lng: 20.1557715 },
    { lat: 49.0804709, lng: 20.1556001 },
    { lat: 49.0806364, lng: 20.1552017 },
    { lat: 49.0807753, lng: 20.1550171 },
    { lat: 49.0807879, lng: 20.1548255 },
    { lat: 49.0807395, lng: 20.1546306 },
    { lat: 49.080765, lng: 20.1545072 },
    { lat: 49.0809757, lng: 20.1543726 },
    { lat: 49.0810875, lng: 20.1541517 },
    { lat: 49.0810888, lng: 20.1538498 },
    { lat: 49.0812008, lng: 20.153636 },
    { lat: 49.081355, lng: 20.1535513 },
    { lat: 49.0815677, lng: 20.1530713 },
    { lat: 49.0819547, lng: 20.1526113 },
    { lat: 49.0821138, lng: 20.1520605 },
    { lat: 49.0822316, lng: 20.151909 },
    { lat: 49.0825029, lng: 20.1511509 },
    { lat: 49.0826312, lng: 20.1510074 },
    { lat: 49.0828609, lng: 20.1509515 },
    { lat: 49.0829629, lng: 20.1506302 },
    { lat: 49.0831769, lng: 20.1504346 },
    { lat: 49.0833482, lng: 20.1501043 },
    { lat: 49.0833291, lng: 20.1499638 },
    { lat: 49.0834587, lng: 20.1497415 },
    { lat: 49.0835426, lng: 20.1494747 },
    { lat: 49.0836619, lng: 20.1494736 },
    { lat: 49.0836019, lng: 20.1493542 },
    { lat: 49.0836316, lng: 20.1491118 },
    { lat: 49.0836431, lng: 20.1490644 },
    { lat: 49.0837552, lng: 20.1490766 },
    { lat: 49.0837291, lng: 20.1489022 },
    { lat: 49.0837872, lng: 20.1486694 },
    { lat: 49.0840232, lng: 20.1487685 },
    { lat: 49.084005, lng: 20.1486286 },
    { lat: 49.0839342, lng: 20.1485443 },
    { lat: 49.0839625, lng: 20.1484634 },
    { lat: 49.0842957, lng: 20.1481521 },
    { lat: 49.0843037, lng: 20.1480446 },
    { lat: 49.0844233, lng: 20.1480791 },
    { lat: 49.0844269, lng: 20.1479986 },
    { lat: 49.0844998, lng: 20.1479336 },
    { lat: 49.0844487, lng: 20.1477537 },
    { lat: 49.0844708, lng: 20.147687 },
    { lat: 49.0846827, lng: 20.1476396 },
    { lat: 49.0847538, lng: 20.147361 },
    { lat: 49.0850481, lng: 20.1469502 },
    { lat: 49.0852168, lng: 20.1470017 },
    { lat: 49.0852404, lng: 20.1468317 },
    { lat: 49.0852037, lng: 20.1467068 },
    { lat: 49.085268, lng: 20.1465967 },
    { lat: 49.0858944, lng: 20.1461534 },
    { lat: 49.0848295, lng: 20.1442038 },
    { lat: 49.0851644, lng: 20.1439354 },
    { lat: 49.0850934, lng: 20.1438759 },
    { lat: 49.0847565, lng: 20.1440424 },
    { lat: 49.0846391, lng: 20.1438421 },
    { lat: 49.0847295, lng: 20.1436763 },
    { lat: 49.0847828, lng: 20.1436886 },
    { lat: 49.0846459, lng: 20.1435358 },
    { lat: 49.084446, lng: 20.1435763 },
    { lat: 49.0842859, lng: 20.1438485 },
    { lat: 49.0841421, lng: 20.1437171 },
    { lat: 49.0840674, lng: 20.143317 },
    { lat: 49.084155, lng: 20.1428655 },
    { lat: 49.0842612, lng: 20.1426501 },
    { lat: 49.0842909, lng: 20.1423786 },
    { lat: 49.0844858, lng: 20.1420202 },
    { lat: 49.0845151, lng: 20.1420444 },
    { lat: 49.0843319, lng: 20.140573 },
    { lat: 49.0842646, lng: 20.1407187 },
    { lat: 49.0842783, lng: 20.1405018 },
    { lat: 49.0843218, lng: 20.1403512 },
    { lat: 49.0845645, lng: 20.140003 },
    { lat: 49.084783, lng: 20.1399205 },
    { lat: 49.0846091, lng: 20.1395681 },
    { lat: 49.0848102, lng: 20.1387787 },
    { lat: 49.0851583, lng: 20.1384752 },
    { lat: 49.0854413, lng: 20.1384513 },
    { lat: 49.0856129, lng: 20.1381558 },
    { lat: 49.0856174, lng: 20.1380156 },
    { lat: 49.0860123, lng: 20.1373891 },
    { lat: 49.0860001, lng: 20.1371157 },
    { lat: 49.0861014, lng: 20.1368365 },
    { lat: 49.0854664, lng: 20.1371491 },
    { lat: 49.0854677, lng: 20.1372057 },
    { lat: 49.0853674, lng: 20.1372327 },
    { lat: 49.085035, lng: 20.1375343 },
    { lat: 49.0849624, lng: 20.1377182 },
    { lat: 49.0847597, lng: 20.1378313 },
    { lat: 49.0846067, lng: 20.1378348 },
    { lat: 49.0844628, lng: 20.1379808 },
    { lat: 49.0844515, lng: 20.1390417 },
    { lat: 49.083995, lng: 20.1395913 },
    { lat: 49.0836838, lng: 20.1398323 },
    { lat: 49.0835231, lng: 20.1400952 },
    { lat: 49.0835113, lng: 20.1403008 },
    { lat: 49.0836091, lng: 20.1408848 },
    { lat: 49.0835024, lng: 20.1413981 },
    { lat: 49.0832746, lng: 20.1418645 },
    { lat: 49.0831996, lng: 20.1418776 },
    { lat: 49.083039, lng: 20.1420812 },
    { lat: 49.0830293, lng: 20.142431 },
    { lat: 49.0829083, lng: 20.1426136 },
    { lat: 49.082779, lng: 20.1426745 },
    { lat: 49.0825133, lng: 20.1429857 },
    { lat: 49.0822476, lng: 20.1433791 },
    { lat: 49.0820105, lng: 20.1439782 },
    { lat: 49.0818527, lng: 20.1441438 },
    { lat: 49.0820101, lng: 20.1448347 },
    { lat: 49.0813863, lng: 20.1452266 },
    { lat: 49.0813063, lng: 20.1453961 },
    { lat: 49.0812887, lng: 20.1456269 },
    { lat: 49.0811098, lng: 20.1459432 },
    { lat: 49.0809979, lng: 20.1460431 },
    { lat: 49.0809491, lng: 20.146232 },
    { lat: 49.080992, lng: 20.1468462 },
    { lat: 49.0809527, lng: 20.14699 },
    { lat: 49.0801757, lng: 20.1478884 },
    { lat: 49.0800792, lng: 20.1479767 },
    { lat: 49.0798296, lng: 20.1479332 },
    { lat: 49.0795769, lng: 20.1483458 },
    { lat: 49.0794958, lng: 20.1483699 },
    { lat: 49.0794284, lng: 20.1485418 },
    { lat: 49.0794258, lng: 20.1487597 },
    { lat: 49.079325, lng: 20.1491628 },
    { lat: 49.0793233, lng: 20.1495729 },
    { lat: 49.079102, lng: 20.1497498 },
    { lat: 49.0789496, lng: 20.1497162 },
    { lat: 49.0787601, lng: 20.1495697 },
    { lat: 49.0785807, lng: 20.1496748 },
    { lat: 49.0784092, lng: 20.1495826 },
    { lat: 49.0782342, lng: 20.1496553 },
    { lat: 49.0781134, lng: 20.1497309 },
    { lat: 49.0778437, lng: 20.1501077 },
    { lat: 49.0777579, lng: 20.1501279 },
    { lat: 49.0777004, lng: 20.1503243 },
    { lat: 49.0774066, lng: 20.150383 },
    { lat: 49.0767077, lng: 20.1510965 },
    { lat: 49.0765788, lng: 20.1521753 },
    { lat: 49.0762905, lng: 20.1523994 },
    { lat: 49.0760634, lng: 20.1530786 },
    { lat: 49.0759043, lng: 20.1533532 },
    { lat: 49.0759272, lng: 20.1535586 },
    { lat: 49.0758608, lng: 20.1537868 },
    { lat: 49.0755423, lng: 20.1541301 },
    { lat: 49.075155, lng: 20.1544398 },
    { lat: 49.074799, lng: 20.1551935 },
    { lat: 49.0746709, lng: 20.1553653 },
    { lat: 49.0746568, lng: 20.1556895 },
    { lat: 49.0745772, lng: 20.1557005 },
    { lat: 49.0742132, lng: 20.1560846 },
    { lat: 49.0739701, lng: 20.1560903 },
    { lat: 49.0738011, lng: 20.1562253 },
    { lat: 49.0734269, lng: 20.1563557 },
    { lat: 49.0733784, lng: 20.1564942 },
    { lat: 49.0733445, lng: 20.1570253 },
    { lat: 49.0732134, lng: 20.1573791 },
    { lat: 49.0733053, lng: 20.1576475 },
    { lat: 49.0732444, lng: 20.1578166 },
    { lat: 49.0730421, lng: 20.1578814 },
    { lat: 49.0730059, lng: 20.1577378 },
    { lat: 49.0729031, lng: 20.157816 },
    { lat: 49.0728572, lng: 20.1579898 },
    { lat: 49.0727337, lng: 20.1581098 },
    { lat: 49.0725866, lng: 20.1581206 },
    { lat: 49.0723237, lng: 20.1583027 },
    { lat: 49.0722579, lng: 20.1586041 },
    { lat: 49.0718218, lng: 20.1594898 },
    { lat: 49.0714141, lng: 20.1595471 },
    { lat: 49.0712486, lng: 20.1597428 },
    { lat: 49.0711391, lng: 20.1603553 },
    { lat: 49.0712465, lng: 20.1605102 },
    { lat: 49.0713863, lng: 20.1605949 },
    { lat: 49.0714379, lng: 20.1607269 },
    { lat: 49.0714033, lng: 20.1608242 },
    { lat: 49.0710846, lng: 20.1609605 },
    { lat: 49.0705772, lng: 20.161003 },
    { lat: 49.0702726, lng: 20.1612985 },
    { lat: 49.0699115, lng: 20.1618669 },
    { lat: 49.0697664, lng: 20.1620154 },
    { lat: 49.0694613, lng: 20.1621282 },
    { lat: 49.0692352, lng: 20.1623029 },
    { lat: 49.0689755, lng: 20.1622999 },
    { lat: 49.0687241, lng: 20.1624466 },
    { lat: 49.068421, lng: 20.1626968 },
    { lat: 49.0683823, lng: 20.1626318 },
    { lat: 49.0673559, lng: 20.1632647 },
    { lat: 49.0670422, lng: 20.1638929 },
    { lat: 49.0669499, lng: 20.1640391 },
    { lat: 49.0669438, lng: 20.1643643 },
    { lat: 49.0669933, lng: 20.1644164 },
    { lat: 49.0669181, lng: 20.1647344 },
    { lat: 49.0670638, lng: 20.1652868 },
    { lat: 49.0670808, lng: 20.1654204 },
    { lat: 49.0669883, lng: 20.1659515 },
    { lat: 49.0664924, lng: 20.1665833 },
    { lat: 49.0663543, lng: 20.167049 },
    { lat: 49.0663758, lng: 20.1672736 },
    { lat: 49.0665898, lng: 20.1673888 },
    { lat: 49.0666517, lng: 20.1676672 },
    { lat: 49.0666852, lng: 20.1680799 },
    { lat: 49.0666198, lng: 20.1683022 },
    { lat: 49.0665662, lng: 20.1684268 },
    { lat: 49.06651, lng: 20.1684313 },
    { lat: 49.0664039, lng: 20.1681739 },
    { lat: 49.0662923, lng: 20.1680877 },
    { lat: 49.0661407, lng: 20.1681542 },
    { lat: 49.0659599, lng: 20.1683602 },
    { lat: 49.0659047, lng: 20.1689493 },
    { lat: 49.0661125, lng: 20.1698077 },
    { lat: 49.0661718, lng: 20.1705058 },
    { lat: 49.0660635, lng: 20.1719614 },
    { lat: 49.0657609, lng: 20.1727856 },
    { lat: 49.0656769, lng: 20.172908 },
    { lat: 49.0654919, lng: 20.1729337 },
    { lat: 49.0652998, lng: 20.1727447 },
    { lat: 49.0651535, lng: 20.1727493 },
    { lat: 49.0651357, lng: 20.1729775 },
    { lat: 49.0652503, lng: 20.1735354 },
    { lat: 49.0652227, lng: 20.1736306 },
    { lat: 49.0650263, lng: 20.1738463 },
    { lat: 49.0649667, lng: 20.1740451 },
    { lat: 49.065107, lng: 20.1743136 },
    { lat: 49.0651291, lng: 20.1745292 },
    { lat: 49.0650124, lng: 20.1748212 },
    { lat: 49.0647722, lng: 20.1749282 },
    { lat: 49.0645837, lng: 20.1751414 },
    { lat: 49.064468, lng: 20.17554 },
    { lat: 49.0647989, lng: 20.1769178 },
    { lat: 49.0647899, lng: 20.1771413 },
    { lat: 49.0642656, lng: 20.178027 },
    { lat: 49.0636334, lng: 20.1792839 },
    { lat: 49.0641553, lng: 20.1805927 },
    { lat: 49.0646876, lng: 20.1811475 },
    { lat: 49.0653979, lng: 20.1843265 },
    { lat: 49.0665526, lng: 20.1867104 },
    { lat: 49.0671282, lng: 20.1884189 },
    { lat: 49.0668476, lng: 20.1893023 },
    { lat: 49.0663599, lng: 20.1907426 },
    { lat: 49.0661141, lng: 20.1922596 },
    { lat: 49.0656775, lng: 20.1930882 },
    { lat: 49.0654517, lng: 20.194547 },
    { lat: 49.064766, lng: 20.1980567 },
    { lat: 49.0638399, lng: 20.2018065 },
    { lat: 49.0636312, lng: 20.2031041 },
    { lat: 49.0633917, lng: 20.2055211 },
    { lat: 49.0629566, lng: 20.2078318 },
    { lat: 49.0624182, lng: 20.2119711 },
    { lat: 49.0622059, lng: 20.2145078 },
    { lat: 49.0619095, lng: 20.2168886 },
    { lat: 49.0618041, lng: 20.2183476 },
    { lat: 49.0619399, lng: 20.2193941 },
  ],
  Šuňava: [
    { lat: 49.0091026, lng: 20.0531154 },
    { lat: 49.0089376, lng: 20.0531834 },
    { lat: 49.0087833, lng: 20.0531866 },
    { lat: 49.0086744, lng: 20.0533035 },
    { lat: 49.0086102, lng: 20.0535496 },
    { lat: 49.0087643, lng: 20.0547232 },
    { lat: 49.008761, lng: 20.055083 },
    { lat: 49.0084986, lng: 20.0559865 },
    { lat: 49.0084742, lng: 20.0562948 },
    { lat: 49.008483, lng: 20.0565946 },
    { lat: 49.0086212, lng: 20.0572994 },
    { lat: 49.0086493, lng: 20.0577879 },
    { lat: 49.008576, lng: 20.0581199 },
    { lat: 49.008461, lng: 20.0583371 },
    { lat: 49.00801, lng: 20.0586805 },
    { lat: 49.0078136, lng: 20.0591354 },
    { lat: 49.0075282, lng: 20.0592891 },
    { lat: 49.0074243, lng: 20.0594485 },
    { lat: 49.0069447, lng: 20.0607424 },
    { lat: 49.0068268, lng: 20.0608591 },
    { lat: 49.0058333, lng: 20.0617066 },
    { lat: 49.0054921, lng: 20.0617085 },
    { lat: 49.005109, lng: 20.0614313 },
    { lat: 49.0049127, lng: 20.0615083 },
    { lat: 49.0048063, lng: 20.0616573 },
    { lat: 49.004639, lng: 20.0621646 },
    { lat: 49.0044706, lng: 20.0631914 },
    { lat: 49.0043577, lng: 20.0635705 },
    { lat: 49.0040638, lng: 20.0642091 },
    { lat: 49.0037262, lng: 20.0647632 },
    { lat: 49.0033908, lng: 20.065671 },
    { lat: 49.0030978, lng: 20.0661913 },
    { lat: 49.0028604, lng: 20.0664171 },
    { lat: 49.0027017, lng: 20.0668013 },
    { lat: 49.0026801, lng: 20.0669934 },
    { lat: 49.0027566, lng: 20.0673754 },
    { lat: 49.0027726, lng: 20.0677369 },
    { lat: 49.0027489, lng: 20.0681488 },
    { lat: 49.0025624, lng: 20.0692297 },
    { lat: 49.0025789, lng: 20.0703753 },
    { lat: 49.002475, lng: 20.0708063 },
    { lat: 49.0020146, lng: 20.0716393 },
    { lat: 49.0018149, lng: 20.0721787 },
    { lat: 49.0018038, lng: 20.0723225 },
    { lat: 49.0014619, lng: 20.0731612 },
    { lat: 49.0012402, lng: 20.0734412 },
    { lat: 49.0006658, lng: 20.073852 },
    { lat: 49.0005287, lng: 20.0738879 },
    { lat: 49.0003889, lng: 20.0739657 },
    { lat: 48.9999197, lng: 20.074306 },
    { lat: 48.999646, lng: 20.0748312 },
    { lat: 48.998862, lng: 20.0757101 },
    { lat: 48.9984459, lng: 20.0762746 },
    { lat: 48.9982005, lng: 20.0764724 },
    { lat: 48.9977707, lng: 20.0765927 },
    { lat: 48.9974866, lng: 20.0767493 },
    { lat: 48.9972387, lng: 20.077215 },
    { lat: 48.9971954, lng: 20.077426 },
    { lat: 48.996598, lng: 20.0776611 },
    { lat: 48.9964577, lng: 20.0778074 },
    { lat: 48.9958232, lng: 20.0790111 },
    { lat: 48.9954352, lng: 20.0795477 },
    { lat: 48.9951954, lng: 20.0800732 },
    { lat: 48.9948587, lng: 20.0810106 },
    { lat: 48.9947188, lng: 20.0816414 },
    { lat: 48.9944308, lng: 20.0823891 },
    { lat: 48.9942809, lng: 20.0829369 },
    { lat: 48.9942723, lng: 20.0831131 },
    { lat: 48.9940171, lng: 20.0836508 },
    { lat: 48.9935805, lng: 20.08391 },
    { lat: 48.993115, lng: 20.0838732 },
    { lat: 48.9927657, lng: 20.0843918 },
    { lat: 48.9926795, lng: 20.0846509 },
    { lat: 48.9912797, lng: 20.0856494 },
    { lat: 48.9907818, lng: 20.0866112 },
    { lat: 48.990415, lng: 20.086403 },
    { lat: 48.9899943, lng: 20.0862705 },
    { lat: 48.9892231, lng: 20.0863658 },
    { lat: 48.9892263, lng: 20.086496 },
    { lat: 48.9890965, lng: 20.0875378 },
    { lat: 48.9891504, lng: 20.0881976 },
    { lat: 48.9891032, lng: 20.0888067 },
    { lat: 48.9891286, lng: 20.0895377 },
    { lat: 48.9890692, lng: 20.089852 },
    { lat: 48.9888579, lng: 20.0900301 },
    { lat: 48.9886494, lng: 20.090328 },
    { lat: 48.988406, lng: 20.0904352 },
    { lat: 48.9882453, lng: 20.09072 },
    { lat: 48.988151, lng: 20.0917222 },
    { lat: 48.9884943, lng: 20.0927406 },
    { lat: 48.988835, lng: 20.0940806 },
    { lat: 48.9899888, lng: 20.0948893 },
    { lat: 48.9904559, lng: 20.0955458 },
    { lat: 48.9906573, lng: 20.0956244 },
    { lat: 48.9908675, lng: 20.0958653 },
    { lat: 48.9914162, lng: 20.0960898 },
    { lat: 48.991945, lng: 20.0955272 },
    { lat: 48.9923976, lng: 20.0959663 },
    { lat: 48.9920497, lng: 20.0968806 },
    { lat: 48.9922998, lng: 20.0972655 },
    { lat: 48.9929356, lng: 20.0975052 },
    { lat: 48.9931154, lng: 20.0974391 },
    { lat: 48.9931814, lng: 20.0975947 },
    { lat: 48.9937617, lng: 20.0977951 },
    { lat: 48.9940893, lng: 20.0984002 },
    { lat: 48.994137, lng: 20.0985216 },
    { lat: 48.9941527, lng: 20.0988773 },
    { lat: 48.9944985, lng: 20.0995399 },
    { lat: 48.9949047, lng: 20.1005557 },
    { lat: 48.9948419, lng: 20.1016168 },
    { lat: 48.9949856, lng: 20.1021549 },
    { lat: 48.9947895, lng: 20.102655 },
    { lat: 48.9949338, lng: 20.1037662 },
    { lat: 48.9950798, lng: 20.103921 },
    { lat: 48.9950164, lng: 20.1043796 },
    { lat: 48.9952702, lng: 20.1049744 },
    { lat: 48.9961103, lng: 20.1060235 },
    { lat: 48.9965417, lng: 20.1063302 },
    { lat: 48.9987093, lng: 20.1067063 },
    { lat: 48.9993891, lng: 20.1067501 },
    { lat: 49.0001955, lng: 20.1067555 },
    { lat: 49.0003246, lng: 20.1064062 },
    { lat: 49.0017972, lng: 20.1075618 },
    { lat: 49.00197, lng: 20.1078526 },
    { lat: 49.00219, lng: 20.108469 },
    { lat: 49.0024811, lng: 20.1088456 },
    { lat: 49.0029622, lng: 20.1098471 },
    { lat: 49.0034374, lng: 20.1097642 },
    { lat: 49.0035878, lng: 20.110305 },
    { lat: 49.0036254, lng: 20.1103129 },
    { lat: 49.0037133, lng: 20.1108678 },
    { lat: 49.0038971, lng: 20.1115415 },
    { lat: 49.0040648, lng: 20.111981 },
    { lat: 49.0039086, lng: 20.1126036 },
    { lat: 49.0039243, lng: 20.1127101 },
    { lat: 49.0038204, lng: 20.1128713 },
    { lat: 49.0036807, lng: 20.1136175 },
    { lat: 49.0035844, lng: 20.1136861 },
    { lat: 49.0035031, lng: 20.1139864 },
    { lat: 49.0033792, lng: 20.1141842 },
    { lat: 49.0033975, lng: 20.1144376 },
    { lat: 49.0034418, lng: 20.1144757 },
    { lat: 49.0034684, lng: 20.1150737 },
    { lat: 49.003439, lng: 20.1151586 },
    { lat: 49.0034756, lng: 20.1153091 },
    { lat: 49.0034265, lng: 20.1157344 },
    { lat: 49.0035189, lng: 20.1159586 },
    { lat: 49.003512, lng: 20.1162598 },
    { lat: 49.0035868, lng: 20.1163572 },
    { lat: 49.0035753, lng: 20.1164881 },
    { lat: 49.0036116, lng: 20.116521 },
    { lat: 49.0035477, lng: 20.1166977 },
    { lat: 49.0036086, lng: 20.1170589 },
    { lat: 49.0035993, lng: 20.1173281 },
    { lat: 49.0037102, lng: 20.1176786 },
    { lat: 49.0037241, lng: 20.1183807 },
    { lat: 49.0037806, lng: 20.1184942 },
    { lat: 49.0038954, lng: 20.118497 },
    { lat: 49.0039361, lng: 20.1186618 },
    { lat: 49.003999, lng: 20.1186889 },
    { lat: 49.004153, lng: 20.1191003 },
    { lat: 49.0041652, lng: 20.1193201 },
    { lat: 49.0042801, lng: 20.1194288 },
    { lat: 49.0043606, lng: 20.1203324 },
    { lat: 49.0044221, lng: 20.1204049 },
    { lat: 49.0045187, lng: 20.1210085 },
    { lat: 49.0046714, lng: 20.1213271 },
    { lat: 49.004671, lng: 20.1215682 },
    { lat: 49.0048702, lng: 20.1218419 },
    { lat: 49.0048532, lng: 20.1219238 },
    { lat: 49.0050151, lng: 20.122059 },
    { lat: 49.004955, lng: 20.1221313 },
    { lat: 49.0049669, lng: 20.1222038 },
    { lat: 49.0050757, lng: 20.1222212 },
    { lat: 49.0050311, lng: 20.1224535 },
    { lat: 49.0051511, lng: 20.122386 },
    { lat: 49.0051752, lng: 20.12251 },
    { lat: 49.0052317, lng: 20.1224591 },
    { lat: 49.0052982, lng: 20.122709 },
    { lat: 49.0054373, lng: 20.1226419 },
    { lat: 49.0054341, lng: 20.122725 },
    { lat: 49.0055637, lng: 20.1229245 },
    { lat: 49.0055503, lng: 20.1230374 },
    { lat: 49.0056318, lng: 20.1230502 },
    { lat: 49.0056654, lng: 20.1231805 },
    { lat: 49.0058664, lng: 20.1231968 },
    { lat: 49.0059665, lng: 20.1233604 },
    { lat: 49.0060756, lng: 20.1234076 },
    { lat: 49.0062427, lng: 20.1237517 },
    { lat: 49.0066262, lng: 20.1237424 },
    { lat: 49.0069801, lng: 20.1235809 },
    { lat: 49.0071737, lng: 20.1235782 },
    { lat: 49.0073811, lng: 20.123972 },
    { lat: 49.0074783, lng: 20.123954 },
    { lat: 49.0075734, lng: 20.1241069 },
    { lat: 49.0079, lng: 20.1240473 },
    { lat: 49.0079826, lng: 20.1240883 },
    { lat: 49.0080876, lng: 20.1240358 },
    { lat: 49.0081652, lng: 20.1238933 },
    { lat: 49.0082883, lng: 20.1240142 },
    { lat: 49.0084404, lng: 20.1238475 },
    { lat: 49.0086677, lng: 20.1239015 },
    { lat: 49.0087558, lng: 20.1237997 },
    { lat: 49.0087343, lng: 20.1237116 },
    { lat: 49.0087944, lng: 20.1235284 },
    { lat: 49.0088863, lng: 20.1235718 },
    { lat: 49.008875, lng: 20.1234542 },
    { lat: 49.0089085, lng: 20.1234263 },
    { lat: 49.0092013, lng: 20.1233361 },
    { lat: 49.0092539, lng: 20.1231902 },
    { lat: 49.0093711, lng: 20.1231114 },
    { lat: 49.0093729, lng: 20.1230169 },
    { lat: 49.0094351, lng: 20.1229957 },
    { lat: 49.0094764, lng: 20.1230671 },
    { lat: 49.0095375, lng: 20.1229079 },
    { lat: 49.0096461, lng: 20.1229504 },
    { lat: 49.0096459, lng: 20.1228052 },
    { lat: 49.0098335, lng: 20.1229042 },
    { lat: 49.0098997, lng: 20.1228435 },
    { lat: 49.0101381, lng: 20.123002 },
    { lat: 49.0101966, lng: 20.122942 },
    { lat: 49.0102825, lng: 20.1231474 },
    { lat: 49.0104046, lng: 20.1231324 },
    { lat: 49.0105684, lng: 20.1232247 },
    { lat: 49.0106405, lng: 20.1234214 },
    { lat: 49.0108232, lng: 20.1235377 },
    { lat: 49.0109045, lng: 20.1237396 },
    { lat: 49.010898, lng: 20.1238532 },
    { lat: 49.0109624, lng: 20.1239168 },
    { lat: 49.0110432, lng: 20.1238547 },
    { lat: 49.0111027, lng: 20.1239302 },
    { lat: 49.011283, lng: 20.1238248 },
    { lat: 49.011309, lng: 20.1239357 },
    { lat: 49.0114327, lng: 20.123904 },
    { lat: 49.0114597, lng: 20.1240439 },
    { lat: 49.0115241, lng: 20.1240512 },
    { lat: 49.0116371, lng: 20.1241945 },
    { lat: 49.0118067, lng: 20.1241876 },
    { lat: 49.0119199, lng: 20.1240852 },
    { lat: 49.0120077, lng: 20.1241448 },
    { lat: 49.0121719, lng: 20.1239673 },
    { lat: 49.0124272, lng: 20.1239602 },
    { lat: 49.0124678, lng: 20.1238205 },
    { lat: 49.0125527, lng: 20.123861 },
    { lat: 49.0126751, lng: 20.1241039 },
    { lat: 49.0127957, lng: 20.1242149 },
    { lat: 49.0128395, lng: 20.1242119 },
    { lat: 49.0129077, lng: 20.1240595 },
    { lat: 49.0130039, lng: 20.1240459 },
    { lat: 49.0131592, lng: 20.124159 },
    { lat: 49.0132651, lng: 20.1243246 },
    { lat: 49.0132959, lng: 20.1246955 },
    { lat: 49.0133755, lng: 20.124739 },
    { lat: 49.013423, lng: 20.1250539 },
    { lat: 49.0135166, lng: 20.1251399 },
    { lat: 49.0135112, lng: 20.1252817 },
    { lat: 49.0136037, lng: 20.1252573 },
    { lat: 49.0135566, lng: 20.1256571 },
    { lat: 49.0136941, lng: 20.1256822 },
    { lat: 49.0137603, lng: 20.1258196 },
    { lat: 49.0138913, lng: 20.1268001 },
    { lat: 49.0140161, lng: 20.1270155 },
    { lat: 49.0140246, lng: 20.1271718 },
    { lat: 49.0142297, lng: 20.127425 },
    { lat: 49.0142245, lng: 20.1275173 },
    { lat: 49.0143542, lng: 20.127526 },
    { lat: 49.014379, lng: 20.127608 },
    { lat: 49.014473, lng: 20.1275935 },
    { lat: 49.0145245, lng: 20.1277802 },
    { lat: 49.0146854, lng: 20.1278628 },
    { lat: 49.0148907, lng: 20.1281482 },
    { lat: 49.0149507, lng: 20.128125 },
    { lat: 49.0149929, lng: 20.1283345 },
    { lat: 49.0151208, lng: 20.128544 },
    { lat: 49.0152085, lng: 20.1285706 },
    { lat: 49.0152678, lng: 20.1286705 },
    { lat: 49.0153273, lng: 20.128586 },
    { lat: 49.0154508, lng: 20.1289659 },
    { lat: 49.0159555, lng: 20.1295825 },
    { lat: 49.0160884, lng: 20.1298342 },
    { lat: 49.0163917, lng: 20.1300333 },
    { lat: 49.0163973, lng: 20.1301479 },
    { lat: 49.0167613, lng: 20.130321 },
    { lat: 49.0167854, lng: 20.1304672 },
    { lat: 49.0170266, lng: 20.1306715 },
    { lat: 49.0170588, lng: 20.1307682 },
    { lat: 49.0169874, lng: 20.1308987 },
    { lat: 49.0170741, lng: 20.1309824 },
    { lat: 49.0171356, lng: 20.1313086 },
    { lat: 49.0172496, lng: 20.131337 },
    { lat: 49.0173502, lng: 20.131765 },
    { lat: 49.0174305, lng: 20.1318598 },
    { lat: 49.0176773, lng: 20.1318356 },
    { lat: 49.0178658, lng: 20.1319859 },
    { lat: 49.0178558, lng: 20.1321236 },
    { lat: 49.0176666, lng: 20.1326561 },
    { lat: 49.0171134, lng: 20.1335664 },
    { lat: 49.0166162, lng: 20.1351467 },
    { lat: 49.0165533, lng: 20.1357342 },
    { lat: 49.0164789, lng: 20.1378831 },
    { lat: 49.0163477, lng: 20.1384802 },
    { lat: 49.0166046, lng: 20.1389345 },
    { lat: 49.0165334, lng: 20.1392619 },
    { lat: 49.0165484, lng: 20.1397987 },
    { lat: 49.0167072, lng: 20.141448 },
    { lat: 49.0168122, lng: 20.141704 },
    { lat: 49.0170283, lng: 20.1426803 },
    { lat: 49.0171624, lng: 20.1439394 },
    { lat: 49.0170817, lng: 20.1455399 },
    { lat: 49.017017, lng: 20.1460002 },
    { lat: 49.0169462, lng: 20.1461754 },
    { lat: 49.0169396, lng: 20.1474655 },
    { lat: 49.0170059, lng: 20.1478214 },
    { lat: 49.0168526, lng: 20.1485758 },
    { lat: 49.0169628, lng: 20.1497754 },
    { lat: 49.0170528, lng: 20.150217 },
    { lat: 49.0172829, lng: 20.1503718 },
    { lat: 49.0172921, lng: 20.1503763 },
    { lat: 49.0179936, lng: 20.1492239 },
    { lat: 49.0190685, lng: 20.1492049 },
    { lat: 49.0194732, lng: 20.1492756 },
    { lat: 49.0200153, lng: 20.1491487 },
    { lat: 49.0202466, lng: 20.1488792 },
    { lat: 49.0204378, lng: 20.1485037 },
    { lat: 49.021642, lng: 20.1478997 },
    { lat: 49.0224171, lng: 20.1478193 },
    { lat: 49.0247079, lng: 20.1466787 },
    { lat: 49.0250203, lng: 20.1453183 },
    { lat: 49.0248995, lng: 20.1438895 },
    { lat: 49.0249839, lng: 20.1431029 },
    { lat: 49.0251712, lng: 20.1423857 },
    { lat: 49.0262028, lng: 20.1421897 },
    { lat: 49.0273612, lng: 20.1416188 },
    { lat: 49.0275578, lng: 20.1412172 },
    { lat: 49.0280052, lng: 20.139858 },
    { lat: 49.0296894, lng: 20.1354876 },
    { lat: 49.0300457, lng: 20.1351644 },
    { lat: 49.0311104, lng: 20.1371832 },
    { lat: 49.0315106, lng: 20.1372485 },
    { lat: 49.0318301, lng: 20.1367962 },
    { lat: 49.0320619, lng: 20.1357865 },
    { lat: 49.0319824, lng: 20.1352674 },
    { lat: 49.0320305, lng: 20.1349213 },
    { lat: 49.0326166, lng: 20.134152 },
    { lat: 49.0327878, lng: 20.1334303 },
    { lat: 49.0330452, lng: 20.1337505 },
    { lat: 49.0336507, lng: 20.1342445 },
    { lat: 49.0354751, lng: 20.1320702 },
    { lat: 49.0370385, lng: 20.1298419 },
    { lat: 49.037763, lng: 20.1296328 },
    { lat: 49.0382958, lng: 20.1285732 },
    { lat: 49.0419544, lng: 20.120518 },
    { lat: 49.0435512, lng: 20.1153167 },
    { lat: 49.0441683, lng: 20.1127846 },
    { lat: 49.044443, lng: 20.1122832 },
    { lat: 49.0445081, lng: 20.1129972 },
    { lat: 49.0467696, lng: 20.1116986 },
    { lat: 49.0484048, lng: 20.1088359 },
    { lat: 49.0493074, lng: 20.1077645 },
    { lat: 49.0503316, lng: 20.1072808 },
    { lat: 49.0501088, lng: 20.106979 },
    { lat: 49.0481031, lng: 20.1048991 },
    { lat: 49.0477237, lng: 20.1031285 },
    { lat: 49.0468075, lng: 20.101178 },
    { lat: 49.0462942, lng: 20.0987982 },
    { lat: 49.0446113, lng: 20.0971321 },
    { lat: 49.0445905, lng: 20.0968706 },
    { lat: 49.0425825, lng: 20.0951294 },
    { lat: 49.0407519, lng: 20.0937327 },
    { lat: 49.0398491, lng: 20.093303 },
    { lat: 49.0391023, lng: 20.0930503 },
    { lat: 49.0369213, lng: 20.0926768 },
    { lat: 49.0374171, lng: 20.089641 },
    { lat: 49.039035, lng: 20.0804916 },
    { lat: 49.0390761, lng: 20.07932 },
    { lat: 49.0398131, lng: 20.0760497 },
    { lat: 49.0399077, lng: 20.0744778 },
    { lat: 49.0397576, lng: 20.072188 },
    { lat: 49.0395882, lng: 20.0706612 },
    { lat: 49.0396086, lng: 20.0693429 },
    { lat: 49.0397564, lng: 20.0666803 },
    { lat: 49.0397108, lng: 20.0663914 },
    { lat: 49.0394688, lng: 20.0660086 },
    { lat: 49.0394149, lng: 20.0656034 },
    { lat: 49.0394823, lng: 20.0649947 },
    { lat: 49.0394664, lng: 20.0643601 },
    { lat: 49.0388196, lng: 20.063609 },
    { lat: 49.0387065, lng: 20.0632378 },
    { lat: 49.038743, lng: 20.0629206 },
    { lat: 49.0385688, lng: 20.0624859 },
    { lat: 49.0383367, lng: 20.0606197 },
    { lat: 49.0379657, lng: 20.0589631 },
    { lat: 49.0379546, lng: 20.0587445 },
    { lat: 49.0378674, lng: 20.0586763 },
    { lat: 49.0377937, lng: 20.0583929 },
    { lat: 49.0369771, lng: 20.0569092 },
    { lat: 49.036719, lng: 20.0558307 },
    { lat: 49.0366823, lng: 20.0554328 },
    { lat: 49.0365988, lng: 20.0552078 },
    { lat: 49.0366484, lng: 20.0551858 },
    { lat: 49.0366196, lng: 20.0549226 },
    { lat: 49.0364951, lng: 20.054443 },
    { lat: 49.0364437, lng: 20.054477 },
    { lat: 49.0362178, lng: 20.053746 },
    { lat: 49.0359483, lng: 20.0528344 },
    { lat: 49.0359188, lng: 20.0525863 },
    { lat: 49.0339155, lng: 20.0529464 },
    { lat: 49.0331557, lng: 20.0533998 },
    { lat: 49.0325797, lng: 20.0534129 },
    { lat: 49.0317541, lng: 20.0532232 },
    { lat: 49.0315578, lng: 20.0532415 },
    { lat: 49.0313438, lng: 20.0533745 },
    { lat: 49.030606, lng: 20.0540063 },
    { lat: 49.030625, lng: 20.0540541 },
    { lat: 49.030523, lng: 20.0541807 },
    { lat: 49.0305541, lng: 20.0542471 },
    { lat: 49.0303329, lng: 20.0543783 },
    { lat: 49.0299701, lng: 20.0547289 },
    { lat: 49.0278596, lng: 20.0557476 },
    { lat: 49.0272005, lng: 20.0562915 },
    { lat: 49.026683, lng: 20.057156 },
    { lat: 49.0262473, lng: 20.0580917 },
    { lat: 49.0250546, lng: 20.0590246 },
    { lat: 49.0249364, lng: 20.0591944 },
    { lat: 49.0241127, lng: 20.0594868 },
    { lat: 49.0234223, lng: 20.0603927 },
    { lat: 49.0214848, lng: 20.0612874 },
    { lat: 49.0207459, lng: 20.0626367 },
    { lat: 49.0185395, lng: 20.0621358 },
    { lat: 49.0178828, lng: 20.0615589 },
    { lat: 49.0160096, lng: 20.0603207 },
    { lat: 49.0144403, lng: 20.0598416 },
    { lat: 49.0142273, lng: 20.0583849 },
    { lat: 49.0139814, lng: 20.0576085 },
    { lat: 49.0139892, lng: 20.0573049 },
    { lat: 49.0142436, lng: 20.0557634 },
    { lat: 49.0142734, lng: 20.0549551 },
    { lat: 49.0139262, lng: 20.05511 },
    { lat: 49.0131075, lng: 20.0548677 },
    { lat: 49.0123921, lng: 20.0552747 },
    { lat: 49.0119852, lng: 20.0551264 },
    { lat: 49.0111813, lng: 20.0546136 },
    { lat: 49.0105429, lng: 20.0544302 },
    { lat: 49.0102251, lng: 20.0540363 },
    { lat: 49.0093053, lng: 20.0534438 },
    { lat: 49.0091442, lng: 20.0532492 },
    { lat: 49.0091026, lng: 20.0531154 },
  ],
  Vernár: [
    { lat: 48.9128544, lng: 20.1865251 },
    { lat: 48.9125344, lng: 20.1855359 },
    { lat: 48.912146, lng: 20.1845288 },
    { lat: 48.9106192, lng: 20.1818389 },
    { lat: 48.9100012, lng: 20.1805497 },
    { lat: 48.9104164, lng: 20.17832 },
    { lat: 48.9105394, lng: 20.1770627 },
    { lat: 48.9106155, lng: 20.1756786 },
    { lat: 48.907974, lng: 20.175661 },
    { lat: 48.907242, lng: 20.176708 },
    { lat: 48.906132, lng: 20.178262 },
    { lat: 48.901094, lng: 20.185267 },
    { lat: 48.90067, lng: 20.185845 },
    { lat: 48.900313, lng: 20.186363 },
    { lat: 48.900127, lng: 20.186624 },
    { lat: 48.899572, lng: 20.188364 },
    { lat: 48.899081, lng: 20.191017 },
    { lat: 48.898558, lng: 20.193436 },
    { lat: 48.894736, lng: 20.202442 },
    { lat: 48.894868, lng: 20.203686 },
    { lat: 48.894561, lng: 20.206518 },
    { lat: 48.894417, lng: 20.208083 },
    { lat: 48.894413, lng: 20.208122 },
    { lat: 48.894356, lng: 20.208744 },
    { lat: 48.894359, lng: 20.210008 },
    { lat: 48.894352, lng: 20.210025 },
    { lat: 48.894516, lng: 20.210259 },
    { lat: 48.894662, lng: 20.21061 },
    { lat: 48.894832, lng: 20.211095 },
    { lat: 48.894884, lng: 20.211341 },
    { lat: 48.894911, lng: 20.211709 },
    { lat: 48.894991, lng: 20.212304 },
    { lat: 48.894729, lng: 20.213149 },
    { lat: 48.894301, lng: 20.213759 },
    { lat: 48.894219, lng: 20.213939 },
    { lat: 48.894154, lng: 20.214214 },
    { lat: 48.894138, lng: 20.214488 },
    { lat: 48.893969, lng: 20.214792 },
    { lat: 48.893715, lng: 20.215085 },
    { lat: 48.893599, lng: 20.215624 },
    { lat: 48.89351, lng: 20.215823 },
    { lat: 48.893385, lng: 20.215918 },
    { lat: 48.893238, lng: 20.216095 },
    { lat: 48.893097, lng: 20.216504 },
    { lat: 48.892949, lng: 20.217103 },
    { lat: 48.892887, lng: 20.217364 },
    { lat: 48.892655, lng: 20.217898 },
    { lat: 48.892656, lng: 20.218127 },
    { lat: 48.892638, lng: 20.218341 },
    { lat: 48.892721, lng: 20.219229 },
    { lat: 48.892677, lng: 20.21939 },
    { lat: 48.892584, lng: 20.219557 },
    { lat: 48.892504, lng: 20.219718 },
    { lat: 48.892494, lng: 20.22001 },
    { lat: 48.892415, lng: 20.220237 },
    { lat: 48.892355, lng: 20.220504 },
    { lat: 48.892247, lng: 20.220715 },
    { lat: 48.892192, lng: 20.220936 },
    { lat: 48.892164, lng: 20.221228 },
    { lat: 48.892103, lng: 20.221632 },
    { lat: 48.892034, lng: 20.221878 },
    { lat: 48.892005, lng: 20.222126 },
    { lat: 48.891757, lng: 20.222872 },
    { lat: 48.891623, lng: 20.223079 },
    { lat: 48.891398, lng: 20.223486 },
    { lat: 48.891294, lng: 20.223707 },
    { lat: 48.891204, lng: 20.223824 },
    { lat: 48.890939, lng: 20.224236 },
    { lat: 48.890876, lng: 20.224483 },
    { lat: 48.890796, lng: 20.224655 },
    { lat: 48.89047, lng: 20.225174 },
    { lat: 48.890318, lng: 20.225361 },
    { lat: 48.88998, lng: 20.225914 },
    { lat: 48.889722, lng: 20.226245 },
    { lat: 48.889434, lng: 20.226712 },
    { lat: 48.889336, lng: 20.226928 },
    { lat: 48.889231, lng: 20.227119 },
    { lat: 48.889105, lng: 20.227303 },
    { lat: 48.888783, lng: 20.227572 },
    { lat: 48.888497, lng: 20.228168 },
    { lat: 48.888348, lng: 20.228392 },
    { lat: 48.888, lng: 20.228392 },
    { lat: 48.887848, lng: 20.228561 },
    { lat: 48.887738, lng: 20.228796 },
    { lat: 48.887674, lng: 20.229041 },
    { lat: 48.887534, lng: 20.229415 },
    { lat: 48.887431, lng: 20.229647 },
    { lat: 48.887361, lng: 20.229933 },
    { lat: 48.887273, lng: 20.23036 },
    { lat: 48.887206, lng: 20.230555 },
    { lat: 48.887192, lng: 20.230699 },
    { lat: 48.886936, lng: 20.231026 },
    { lat: 48.886914, lng: 20.231233 },
    { lat: 48.886813, lng: 20.231468 },
    { lat: 48.886735, lng: 20.231696 },
    { lat: 48.88658, lng: 20.231852 },
    { lat: 48.886435, lng: 20.232202 },
    { lat: 48.886431, lng: 20.232348 },
    { lat: 48.886381, lng: 20.232485 },
    { lat: 48.886316, lng: 20.232722 },
    { lat: 48.88633, lng: 20.23289 },
    { lat: 48.886374, lng: 20.23297 },
    { lat: 48.88637, lng: 20.233033 },
    { lat: 48.886298, lng: 20.233108 },
    { lat: 48.886281, lng: 20.233221 },
    { lat: 48.886247, lng: 20.233339 },
    { lat: 48.88622, lng: 20.233458 },
    { lat: 48.886162, lng: 20.233558 },
    { lat: 48.886035, lng: 20.233587 },
    { lat: 48.885927, lng: 20.233677 },
    { lat: 48.885928, lng: 20.233858 },
    { lat: 48.885914, lng: 20.233946 },
    { lat: 48.885828, lng: 20.234013 },
    { lat: 48.885821, lng: 20.234072 },
    { lat: 48.885826, lng: 20.234183 },
    { lat: 48.885762, lng: 20.234265 },
    { lat: 48.885738, lng: 20.234393 },
    { lat: 48.885622, lng: 20.234453 },
    { lat: 48.885586, lng: 20.234704 },
    { lat: 48.885592, lng: 20.234872 },
    { lat: 48.88552, lng: 20.234976 },
    { lat: 48.885521, lng: 20.23511 },
    { lat: 48.885447, lng: 20.235216 },
    { lat: 48.885482, lng: 20.235396 },
    { lat: 48.885463, lng: 20.235491 },
    { lat: 48.88546, lng: 20.235615 },
    { lat: 48.885324, lng: 20.235952 },
    { lat: 48.885316, lng: 20.236092 },
    { lat: 48.88528, lng: 20.236194 },
    { lat: 48.885143, lng: 20.236206 },
    { lat: 48.885075, lng: 20.236324 },
    { lat: 48.884991, lng: 20.236671 },
    { lat: 48.884937, lng: 20.236786 },
    { lat: 48.884955, lng: 20.236979 },
    { lat: 48.884994, lng: 20.23717 },
    { lat: 48.884896, lng: 20.237243 },
    { lat: 48.884885, lng: 20.237324 },
    { lat: 48.8849, lng: 20.237442 },
    { lat: 48.884914, lng: 20.237585 },
    { lat: 48.884881, lng: 20.237642 },
    { lat: 48.884902, lng: 20.237749 },
    { lat: 48.884935, lng: 20.237886 },
    { lat: 48.884962, lng: 20.237982 },
    { lat: 48.885039, lng: 20.238217 },
    { lat: 48.885088, lng: 20.238444 },
    { lat: 48.885141, lng: 20.238809 },
    { lat: 48.88515, lng: 20.238976 },
    { lat: 48.88513, lng: 20.239103 },
    { lat: 48.885126, lng: 20.239222 },
    { lat: 48.885124, lng: 20.239347 },
    { lat: 48.885122, lng: 20.239481 },
    { lat: 48.885106, lng: 20.239599 },
    { lat: 48.885049, lng: 20.239739 },
    { lat: 48.884956, lng: 20.239911 },
    { lat: 48.884992, lng: 20.240058 },
    { lat: 48.88498, lng: 20.240237 },
    { lat: 48.884916, lng: 20.240491 },
    { lat: 48.884986, lng: 20.240868 },
    { lat: 48.885052, lng: 20.24126 },
    { lat: 48.885128, lng: 20.241666 },
    { lat: 48.885141, lng: 20.24219 },
    { lat: 48.885387, lng: 20.242804 },
    { lat: 48.885469, lng: 20.243045 },
    { lat: 48.885546, lng: 20.243338 },
    { lat: 48.885449, lng: 20.243679 },
    { lat: 48.88529, lng: 20.244034 },
    { lat: 48.885247, lng: 20.244281 },
    { lat: 48.88525, lng: 20.244585 },
    { lat: 48.885159, lng: 20.2451 },
    { lat: 48.884962, lng: 20.245457 },
    { lat: 48.884866, lng: 20.24552 },
    { lat: 48.884739, lng: 20.245529 },
    { lat: 48.884712, lng: 20.245715 },
    { lat: 48.884639, lng: 20.245801 },
    { lat: 48.884618, lng: 20.245901 },
    { lat: 48.884677, lng: 20.24608 },
    { lat: 48.884688, lng: 20.246485 },
    { lat: 48.88477, lng: 20.246575 },
    { lat: 48.884804, lng: 20.246783 },
    { lat: 48.884536, lng: 20.247216 },
    { lat: 48.884461, lng: 20.247592 },
    { lat: 48.884438, lng: 20.247656 },
    { lat: 48.884399, lng: 20.247729 },
    { lat: 48.884313, lng: 20.247834 },
    { lat: 48.884175, lng: 20.24812 },
    { lat: 48.883948, lng: 20.24821 },
    { lat: 48.883827, lng: 20.24838 },
    { lat: 48.88373, lng: 20.248738 },
    { lat: 48.883696, lng: 20.248992 },
    { lat: 48.883797, lng: 20.249327 },
    { lat: 48.883787, lng: 20.249415 },
    { lat: 48.8835, lng: 20.249852 },
    { lat: 48.883347, lng: 20.249972 },
    { lat: 48.88296, lng: 20.250167 },
    { lat: 48.882746, lng: 20.250902 },
    { lat: 48.882633, lng: 20.250949 },
    { lat: 48.88254, lng: 20.250987 },
    { lat: 48.882516, lng: 20.251208 },
    { lat: 48.882426, lng: 20.251252 },
    { lat: 48.882186, lng: 20.251249 },
    { lat: 48.882129, lng: 20.251441 },
    { lat: 48.882086, lng: 20.251493 },
    { lat: 48.881853, lng: 20.251689 },
    { lat: 48.881606, lng: 20.251707 },
    { lat: 48.881401, lng: 20.251761 },
    { lat: 48.88126, lng: 20.252009 },
    { lat: 48.881115, lng: 20.25221 },
    { lat: 48.881008, lng: 20.252461 },
    { lat: 48.880825, lng: 20.252608 },
    { lat: 48.88067, lng: 20.252776 },
    { lat: 48.88042, lng: 20.253073 },
    { lat: 48.880088, lng: 20.254028 },
    { lat: 48.879952, lng: 20.254162 },
    { lat: 48.879912, lng: 20.254254 },
    { lat: 48.87989, lng: 20.254391 },
    { lat: 48.87991, lng: 20.254479 },
    { lat: 48.88017, lng: 20.254859 },
    { lat: 48.880298, lng: 20.255062 },
    { lat: 48.880342, lng: 20.255162 },
    { lat: 48.880352, lng: 20.255287 },
    { lat: 48.880221, lng: 20.25563 },
    { lat: 48.880166, lng: 20.255926 },
    { lat: 48.879975, lng: 20.256569 },
    { lat: 48.879777, lng: 20.256777 },
    { lat: 48.879658, lng: 20.257014 },
    { lat: 48.879636, lng: 20.257454 },
    { lat: 48.879501, lng: 20.258226 },
    { lat: 48.87876, lng: 20.259122 },
    { lat: 48.878494, lng: 20.259491 },
    { lat: 48.878388, lng: 20.259663 },
    { lat: 48.878165, lng: 20.259891 },
    { lat: 48.877611, lng: 20.260381 },
    { lat: 48.877149, lng: 20.260989 },
    { lat: 48.876466, lng: 20.261795 },
    { lat: 48.875777, lng: 20.26277 },
    { lat: 48.87567, lng: 20.263138 },
    { lat: 48.875532, lng: 20.26351 },
    { lat: 48.875356, lng: 20.264056 },
    { lat: 48.875206, lng: 20.264437 },
    { lat: 48.87505, lng: 20.264924 },
    { lat: 48.874861, lng: 20.265726 },
    { lat: 48.874576, lng: 20.266623 },
    { lat: 48.874075, lng: 20.267459 },
    { lat: 48.873747, lng: 20.268067 },
    { lat: 48.873685, lng: 20.268474 },
    { lat: 48.873681, lng: 20.268526 },
    { lat: 48.873645, lng: 20.268598 },
    { lat: 48.873319, lng: 20.268774 },
    { lat: 48.873221, lng: 20.268921 },
    { lat: 48.873098, lng: 20.269257 },
    { lat: 48.873097, lng: 20.269496 },
    { lat: 48.872891, lng: 20.270041 },
    { lat: 48.872867, lng: 20.270085 },
    { lat: 48.872782, lng: 20.27019 },
    { lat: 48.872702, lng: 20.270442 },
    { lat: 48.872734, lng: 20.270633 },
    { lat: 48.872723, lng: 20.270678 },
    { lat: 48.87262, lng: 20.270794 },
    { lat: 48.872399, lng: 20.270888 },
    { lat: 48.872271, lng: 20.270957 },
    { lat: 48.872067, lng: 20.2712 },
    { lat: 48.87196, lng: 20.27156 },
    { lat: 48.871886, lng: 20.272011 },
    { lat: 48.871885, lng: 20.272439 },
    { lat: 48.871907, lng: 20.272554 },
    { lat: 48.871987, lng: 20.272599 },
    { lat: 48.872001, lng: 20.272638 },
    { lat: 48.872, lng: 20.272693 },
    { lat: 48.871974, lng: 20.27272 },
    { lat: 48.871869, lng: 20.272855 },
    { lat: 48.871807, lng: 20.272939 },
    { lat: 48.87174, lng: 20.273406 },
    { lat: 48.871731, lng: 20.273486 },
    { lat: 48.871764, lng: 20.273992 },
    { lat: 48.871701, lng: 20.274379 },
    { lat: 48.871725, lng: 20.274549 },
    { lat: 48.871819, lng: 20.274643 },
    { lat: 48.871862, lng: 20.275021 },
    { lat: 48.871848, lng: 20.27507 },
    { lat: 48.871697, lng: 20.275307 },
    { lat: 48.871522, lng: 20.275444 },
    { lat: 48.871425, lng: 20.275593 },
    { lat: 48.871347, lng: 20.27602 },
    { lat: 48.87108, lng: 20.276385 },
    { lat: 48.871008, lng: 20.276604 },
    { lat: 48.871015, lng: 20.276908 },
    { lat: 48.871102, lng: 20.277183 },
    { lat: 48.871303, lng: 20.277318 },
    { lat: 48.871463, lng: 20.277316 },
    { lat: 48.871589, lng: 20.277425 },
    { lat: 48.871727, lng: 20.277735 },
    { lat: 48.871792, lng: 20.278176 },
    { lat: 48.87189, lng: 20.278298 },
    { lat: 48.87202, lng: 20.278305 },
    { lat: 48.872282, lng: 20.278199 },
    { lat: 48.872448, lng: 20.278223 },
    { lat: 48.872535, lng: 20.278409 },
    { lat: 48.872468, lng: 20.279256 },
    { lat: 48.872363, lng: 20.279415 },
    { lat: 48.87219, lng: 20.279511 },
    { lat: 48.872128, lng: 20.279676 },
    { lat: 48.87215, lng: 20.2799 },
    { lat: 48.872363, lng: 20.280035 },
    { lat: 48.872407, lng: 20.28016 },
    { lat: 48.872411, lng: 20.280203 },
    { lat: 48.872365, lng: 20.280334 },
    { lat: 48.872281, lng: 20.280459 },
    { lat: 48.872292, lng: 20.280576 },
    { lat: 48.872293, lng: 20.280574 },
    { lat: 48.872306, lng: 20.280605 },
    { lat: 48.872475, lng: 20.28078 },
    { lat: 48.872747, lng: 20.280828 },
    { lat: 48.872812, lng: 20.281079 },
    { lat: 48.872851, lng: 20.281692 },
    { lat: 48.872916, lng: 20.282046 },
    { lat: 48.8729, lng: 20.282309 },
    { lat: 48.872979, lng: 20.282542 },
    { lat: 48.873049, lng: 20.28255 },
    { lat: 48.873177, lng: 20.282354 },
    { lat: 48.873329, lng: 20.28235 },
    { lat: 48.87336, lng: 20.282454 },
    { lat: 48.873351, lng: 20.282897 },
    { lat: 48.873272, lng: 20.283355 },
    { lat: 48.873319, lng: 20.283491 },
    { lat: 48.873448, lng: 20.283479 },
    { lat: 48.873704, lng: 20.283356 },
    { lat: 48.873734, lng: 20.283361 },
    { lat: 48.873806, lng: 20.283491 },
    { lat: 48.873956, lng: 20.283974 },
    { lat: 48.874138, lng: 20.284836 },
    { lat: 48.874143, lng: 20.284863 },
    { lat: 48.874152, lng: 20.284909 },
    { lat: 48.874178, lng: 20.284951 },
    { lat: 48.874481, lng: 20.285444 },
    { lat: 48.875238, lng: 20.287151 },
    { lat: 48.875446, lng: 20.287642 },
    { lat: 48.875536, lng: 20.287862 },
    { lat: 48.875565, lng: 20.287949 },
    { lat: 48.875623, lng: 20.288194 },
    { lat: 48.875629, lng: 20.288265 },
    { lat: 48.875643, lng: 20.288382 },
    { lat: 48.875652, lng: 20.288574 },
    { lat: 48.875684, lng: 20.288631 },
    { lat: 48.87566, lng: 20.288838 },
    { lat: 48.875699, lng: 20.288828 },
    { lat: 48.875766, lng: 20.288811 },
    { lat: 48.875859, lng: 20.288786 },
    { lat: 48.875962, lng: 20.28876 },
    { lat: 48.876092, lng: 20.288726 },
    { lat: 48.876185, lng: 20.28873 },
    { lat: 48.876647, lng: 20.28875 },
    { lat: 48.877318, lng: 20.288781 },
    { lat: 48.880566, lng: 20.288874 },
    { lat: 48.882464, lng: 20.289032 },
    { lat: 48.883056, lng: 20.289281 },
    { lat: 48.883772, lng: 20.289043 },
    { lat: 48.884351, lng: 20.288794 },
    { lat: 48.885494, lng: 20.289071 },
    { lat: 48.885619, lng: 20.289592 },
    { lat: 48.885814, lng: 20.290015 },
    { lat: 48.886741, lng: 20.291307 },
    { lat: 48.887234, lng: 20.292226 },
    { lat: 48.887644, lng: 20.29305 },
    { lat: 48.888266, lng: 20.293711 },
    { lat: 48.88844, lng: 20.293978 },
    { lat: 48.888553, lng: 20.294329 },
    { lat: 48.888865, lng: 20.2955 },
    { lat: 48.888864, lng: 20.295538 },
    { lat: 48.889245, lng: 20.295544 },
    { lat: 48.889523, lng: 20.295629 },
    { lat: 48.889697, lng: 20.295777 },
    { lat: 48.890034, lng: 20.296147 },
    { lat: 48.890273, lng: 20.296371 },
    { lat: 48.890459, lng: 20.296563 },
    { lat: 48.890577, lng: 20.296623 },
    { lat: 48.89066, lng: 20.296726 },
    { lat: 48.890801, lng: 20.296782 },
    { lat: 48.89095, lng: 20.296879 },
    { lat: 48.891368, lng: 20.297337 },
    { lat: 48.891578, lng: 20.297596 },
    { lat: 48.89165, lng: 20.297729 },
    { lat: 48.891659, lng: 20.297725 },
    { lat: 48.891782, lng: 20.297857 },
    { lat: 48.891803, lng: 20.297849 },
    { lat: 48.89191, lng: 20.297815 },
    { lat: 48.891834, lng: 20.29755 },
    { lat: 48.891831, lng: 20.296977 },
    { lat: 48.891766, lng: 20.296801 },
    { lat: 48.891844, lng: 20.296615 },
    { lat: 48.891801, lng: 20.296263 },
    { lat: 48.891759, lng: 20.296182 },
    { lat: 48.89176, lng: 20.296071 },
    { lat: 48.891812, lng: 20.295713 },
    { lat: 48.891978, lng: 20.295538 },
    { lat: 48.892153, lng: 20.295594 },
    { lat: 48.892287, lng: 20.295286 },
    { lat: 48.892492, lng: 20.295119 },
    { lat: 48.892522, lng: 20.295021 },
    { lat: 48.892681, lng: 20.294953 },
    { lat: 48.892651, lng: 20.294873 },
    { lat: 48.89293, lng: 20.294577 },
    { lat: 48.893055, lng: 20.294295 },
    { lat: 48.893179, lng: 20.29409 },
    { lat: 48.893227, lng: 20.293909 },
    { lat: 48.893322, lng: 20.293746 },
    { lat: 48.893579, lng: 20.293451 },
    { lat: 48.893725, lng: 20.293205 },
    { lat: 48.893838, lng: 20.292968 },
    { lat: 48.893893, lng: 20.29284 },
    { lat: 48.89391, lng: 20.292822 },
    { lat: 48.894066, lng: 20.293053 },
    { lat: 48.894878, lng: 20.29424 },
    { lat: 48.895274, lng: 20.295026 },
    { lat: 48.895514, lng: 20.29555 },
    { lat: 48.896713, lng: 20.294165 },
    { lat: 48.896916, lng: 20.293939 },
    { lat: 48.896996, lng: 20.294111 },
    { lat: 48.897123, lng: 20.294313 },
    { lat: 48.897849, lng: 20.294301 },
    { lat: 48.898881, lng: 20.294958 },
    { lat: 48.899204, lng: 20.295201 },
    { lat: 48.899287, lng: 20.295259 },
    { lat: 48.899958, lng: 20.296428 },
    { lat: 48.90007, lng: 20.296425 },
    { lat: 48.899889, lng: 20.298189 },
    { lat: 48.900086, lng: 20.298343 },
    { lat: 48.900482, lng: 20.298911 },
    { lat: 48.900699, lng: 20.299057 },
    { lat: 48.900965, lng: 20.298998 },
    { lat: 48.901459, lng: 20.298753 },
    { lat: 48.901613, lng: 20.298877 },
    { lat: 48.901919, lng: 20.299463 },
    { lat: 48.902092, lng: 20.299955 },
    { lat: 48.90226, lng: 20.300009 },
    { lat: 48.902817, lng: 20.300921 },
    { lat: 48.902892, lng: 20.301157 },
    { lat: 48.902981, lng: 20.301744 },
    { lat: 48.903028, lng: 20.302293 },
    { lat: 48.903108, lng: 20.302522 },
    { lat: 48.903287, lng: 20.302712 },
    { lat: 48.903431, lng: 20.303093 },
    { lat: 48.903566, lng: 20.303258 },
    { lat: 48.904294, lng: 20.303743 },
    { lat: 48.904951, lng: 20.304583 },
    { lat: 48.905081, lng: 20.304921 },
    { lat: 48.905389, lng: 20.304901 },
    { lat: 48.906438, lng: 20.304981 },
    { lat: 48.906592, lng: 20.304877 },
    { lat: 48.90695, lng: 20.305435 },
    { lat: 48.907395, lng: 20.306477 },
    { lat: 48.906844, lng: 20.307598 },
    { lat: 48.906676, lng: 20.308186 },
    { lat: 48.906245, lng: 20.309151 },
    { lat: 48.905987, lng: 20.309403 },
    { lat: 48.905602, lng: 20.3103 },
    { lat: 48.905592, lng: 20.310509 },
    { lat: 48.905583, lng: 20.310641 },
    { lat: 48.905608, lng: 20.311629 },
    { lat: 48.905679, lng: 20.312085 },
    { lat: 48.905736, lng: 20.312642 },
    { lat: 48.905705, lng: 20.312887 },
    { lat: 48.905707, lng: 20.312946 },
    { lat: 48.905636, lng: 20.313059 },
    { lat: 48.905519, lng: 20.313548 },
    { lat: 48.905392, lng: 20.313685 },
    { lat: 48.905362, lng: 20.313762 },
    { lat: 48.90523, lng: 20.31415 },
    { lat: 48.905023, lng: 20.315024 },
    { lat: 48.90519, lng: 20.315189 },
    { lat: 48.905206, lng: 20.315842 },
    { lat: 48.903421, lng: 20.32437 },
    { lat: 48.903515, lng: 20.324439 },
    { lat: 48.903543, lng: 20.324405 },
    { lat: 48.903647, lng: 20.324327 },
    { lat: 48.90384, lng: 20.324295 },
    { lat: 48.90399, lng: 20.324312 },
    { lat: 48.904088, lng: 20.32431 },
    { lat: 48.904246, lng: 20.324289 },
    { lat: 48.904347, lng: 20.324233 },
    { lat: 48.904417, lng: 20.324268 },
    { lat: 48.904484, lng: 20.32435 },
    { lat: 48.904558, lng: 20.324375 },
    { lat: 48.904902, lng: 20.324743 },
    { lat: 48.904993, lng: 20.324769 },
    { lat: 48.905066, lng: 20.324795 },
    { lat: 48.905064, lng: 20.324772 },
    { lat: 48.905642, lng: 20.324594 },
    { lat: 48.905819, lng: 20.324568 },
    { lat: 48.906035, lng: 20.324617 },
    { lat: 48.90619, lng: 20.324703 },
    { lat: 48.906481, lng: 20.324952 },
    { lat: 48.906601, lng: 20.325292 },
    { lat: 48.906667, lng: 20.325541 },
    { lat: 48.906727, lng: 20.325653 },
    { lat: 48.906844, lng: 20.325751 },
    { lat: 48.907118, lng: 20.326065 },
    { lat: 48.907189, lng: 20.326197 },
    { lat: 48.907233, lng: 20.326329 },
    { lat: 48.90722, lng: 20.326399 },
    { lat: 48.907201, lng: 20.326442 },
    { lat: 48.907185, lng: 20.326461 },
    { lat: 48.907054, lng: 20.326542 },
    { lat: 48.907116, lng: 20.326885 },
    { lat: 48.907315, lng: 20.326948 },
    { lat: 48.907463, lng: 20.327085 },
    { lat: 48.907524, lng: 20.32715 },
    { lat: 48.907544, lng: 20.327316 },
    { lat: 48.907598, lng: 20.327402 },
    { lat: 48.907979, lng: 20.327606 },
    { lat: 48.90802, lng: 20.327668 },
    { lat: 48.908071, lng: 20.327665 },
    { lat: 48.908561, lng: 20.327427 },
    { lat: 48.908727, lng: 20.327497 },
    { lat: 48.908761, lng: 20.327856 },
    { lat: 48.908845, lng: 20.328033 },
    { lat: 48.908972, lng: 20.328145 },
    { lat: 48.90925, lng: 20.328767 },
    { lat: 48.90937, lng: 20.328852 },
    { lat: 48.909477, lng: 20.329024 },
    { lat: 48.909636, lng: 20.329497 },
    { lat: 48.909777, lng: 20.329626 },
    { lat: 48.909852, lng: 20.329864 },
    { lat: 48.910048, lng: 20.330255 },
    { lat: 48.910087, lng: 20.330358 },
    { lat: 48.910109, lng: 20.3307 },
    { lat: 48.910098, lng: 20.330982 },
    { lat: 48.910164, lng: 20.331118 },
    { lat: 48.91019, lng: 20.331444 },
    { lat: 48.910474, lng: 20.331729 },
    { lat: 48.910668, lng: 20.331642 },
    { lat: 48.911142, lng: 20.332692 },
    { lat: 48.911261, lng: 20.332922 },
    { lat: 48.911303, lng: 20.333162 },
    { lat: 48.911437, lng: 20.333354 },
    { lat: 48.911596, lng: 20.33379 },
    { lat: 48.911765, lng: 20.333823 },
    { lat: 48.912019, lng: 20.33414 },
    { lat: 48.912267, lng: 20.334076 },
    { lat: 48.912396, lng: 20.334328 },
    { lat: 48.912703, lng: 20.334502 },
    { lat: 48.912798, lng: 20.334417 },
    { lat: 48.912912, lng: 20.334414 },
    { lat: 48.91288, lng: 20.334493 },
    { lat: 48.912893, lng: 20.334595 },
    { lat: 48.913077, lng: 20.335035 },
    { lat: 48.913321, lng: 20.335297 },
    { lat: 48.913528, lng: 20.335381 },
    { lat: 48.913953, lng: 20.335296 },
    { lat: 48.91417, lng: 20.335476 },
    { lat: 48.914349, lng: 20.335577 },
    { lat: 48.914464, lng: 20.335839 },
    { lat: 48.914436, lng: 20.336642 },
    { lat: 48.914479, lng: 20.336779 },
    { lat: 48.914704, lng: 20.336883 },
    { lat: 48.914809, lng: 20.336934 },
    { lat: 48.914951, lng: 20.336963 },
    { lat: 48.915294, lng: 20.33732 },
    { lat: 48.915308, lng: 20.337606 },
    { lat: 48.915495, lng: 20.337772 },
    { lat: 48.915562, lng: 20.338077 },
    { lat: 48.915499, lng: 20.338452 },
    { lat: 48.915714, lng: 20.338665 },
    { lat: 48.915782, lng: 20.339161 },
    { lat: 48.915918, lng: 20.339334 },
    { lat: 48.916225, lng: 20.339445 },
    { lat: 48.916531, lng: 20.339462 },
    { lat: 48.916751, lng: 20.33951 },
    { lat: 48.916873, lng: 20.339498 },
    { lat: 48.917003, lng: 20.33954 },
    { lat: 48.917145, lng: 20.339797 },
    { lat: 48.917402, lng: 20.339913 },
    { lat: 48.917766, lng: 20.339782 },
    { lat: 48.917865, lng: 20.339783 },
    { lat: 48.918113, lng: 20.339651 },
    { lat: 48.918203, lng: 20.339528 },
    { lat: 48.918503, lng: 20.339413 },
    { lat: 48.918578, lng: 20.33944 },
    { lat: 48.918889, lng: 20.339601 },
    { lat: 48.91915, lng: 20.339431 },
    { lat: 48.919192, lng: 20.33942 },
    { lat: 48.919367, lng: 20.339586 },
    { lat: 48.919598, lng: 20.3399 },
    { lat: 48.919831, lng: 20.340305 },
    { lat: 48.920313, lng: 20.340537 },
    { lat: 48.920527, lng: 20.340668 },
    { lat: 48.92073, lng: 20.340904 },
    { lat: 48.920976, lng: 20.341259 },
    { lat: 48.922608, lng: 20.34134 },
    { lat: 48.92311, lng: 20.34185 },
    { lat: 48.923449, lng: 20.341971 },
    { lat: 48.923589, lng: 20.342175 },
    { lat: 48.923662, lng: 20.342268 },
    { lat: 48.923746, lng: 20.342164 },
    { lat: 48.92391, lng: 20.342148 },
    { lat: 48.924292, lng: 20.34198 },
    { lat: 48.924345, lng: 20.341905 },
    { lat: 48.924523, lng: 20.341296 },
    { lat: 48.924543, lng: 20.341253 },
    { lat: 48.924621, lng: 20.341113 },
    { lat: 48.924702, lng: 20.341089 },
    { lat: 48.924769, lng: 20.341093 },
    { lat: 48.925144, lng: 20.341263 },
    { lat: 48.92519, lng: 20.34126 },
    { lat: 48.925242, lng: 20.341232 },
    { lat: 48.925272, lng: 20.341194 },
    { lat: 48.92529, lng: 20.341143 },
    { lat: 48.925364, lng: 20.340865 },
    { lat: 48.925416, lng: 20.340476 },
    { lat: 48.925422, lng: 20.340296 },
    { lat: 48.925542, lng: 20.33995 },
    { lat: 48.925639, lng: 20.339826 },
    { lat: 48.925881, lng: 20.339665 },
    { lat: 48.92619, lng: 20.339343 },
    { lat: 48.926374, lng: 20.338937 },
    { lat: 48.926514, lng: 20.338644 },
    { lat: 48.926591, lng: 20.338554 },
    { lat: 48.926708, lng: 20.338415 },
    { lat: 48.926829, lng: 20.338371 },
    { lat: 48.926875, lng: 20.338362 },
    { lat: 48.92699, lng: 20.338362 },
    { lat: 48.92715, lng: 20.33845 },
    { lat: 48.927509, lng: 20.338506 },
    { lat: 48.927743, lng: 20.3386 },
    { lat: 48.927837, lng: 20.338601 },
    { lat: 48.927932, lng: 20.338575 },
    { lat: 48.927957, lng: 20.338543 },
    { lat: 48.928102, lng: 20.338256 },
    { lat: 48.928232, lng: 20.337659 },
    { lat: 48.928284, lng: 20.337568 },
    { lat: 48.928339, lng: 20.337499 },
    { lat: 48.928378, lng: 20.337463 },
    { lat: 48.92855, lng: 20.337351 },
    { lat: 48.928763, lng: 20.337097 },
    { lat: 48.929051, lng: 20.336553 },
    { lat: 48.929173, lng: 20.336421 },
    { lat: 48.92931, lng: 20.336315 },
    { lat: 48.929447, lng: 20.336253 },
    { lat: 48.929587, lng: 20.336216 },
    { lat: 48.929829, lng: 20.33632 },
    { lat: 48.930036, lng: 20.336282 },
    { lat: 48.930236, lng: 20.336175 },
    { lat: 48.930583, lng: 20.335746 },
    { lat: 48.93061, lng: 20.335711 },
    { lat: 48.930844, lng: 20.33528 },
    { lat: 48.931149, lng: 20.335102 },
    { lat: 48.931186, lng: 20.335093 },
    { lat: 48.93122, lng: 20.335068 },
    { lat: 48.931736, lng: 20.334445 },
    { lat: 48.931938, lng: 20.33427 },
    { lat: 48.93199, lng: 20.334247 },
    { lat: 48.932196, lng: 20.334252 },
    { lat: 48.932586, lng: 20.334385 },
    { lat: 48.932597, lng: 20.334275 },
    { lat: 48.932781, lng: 20.334208 },
    { lat: 48.932812, lng: 20.334091 },
    { lat: 48.932853, lng: 20.333935 },
    { lat: 48.932934, lng: 20.333623 },
    { lat: 48.933029, lng: 20.333396 },
    { lat: 48.933058, lng: 20.333329 },
    { lat: 48.933088, lng: 20.333257 },
    { lat: 48.933121, lng: 20.333178 },
    { lat: 48.933129, lng: 20.333159 },
    { lat: 48.933149, lng: 20.333201 },
    { lat: 48.933187, lng: 20.333281 },
    { lat: 48.933209, lng: 20.333326 },
    { lat: 48.933222, lng: 20.333315 },
    { lat: 48.933252, lng: 20.333288 },
    { lat: 48.933262, lng: 20.33328 },
    { lat: 48.93329, lng: 20.333254 },
    { lat: 48.933357, lng: 20.333048 },
    { lat: 48.933351, lng: 20.332607 },
    { lat: 48.93331, lng: 20.332139 },
    { lat: 48.933292, lng: 20.331851 },
    { lat: 48.933557, lng: 20.330458 },
    { lat: 48.933648, lng: 20.330131 },
    { lat: 48.933722, lng: 20.329977 },
    { lat: 48.934007, lng: 20.329102 },
    { lat: 48.933979, lng: 20.329009 },
    { lat: 48.934188, lng: 20.328613 },
    { lat: 48.934355, lng: 20.328407 },
    { lat: 48.934472, lng: 20.328202 },
    { lat: 48.934574, lng: 20.32798 },
    { lat: 48.934687, lng: 20.327764 },
    { lat: 48.934719, lng: 20.327555 },
    { lat: 48.934781, lng: 20.326533 },
    { lat: 48.934896, lng: 20.326228 },
    { lat: 48.935075, lng: 20.326154 },
    { lat: 48.935212, lng: 20.326026 },
    { lat: 48.935223, lng: 20.326029 },
    { lat: 48.935279, lng: 20.325711 },
    { lat: 48.93522, lng: 20.325316 },
    { lat: 48.935249, lng: 20.324893 },
    { lat: 48.935365, lng: 20.324615 },
    { lat: 48.935549, lng: 20.32428 },
    { lat: 48.935788, lng: 20.323973 },
    { lat: 48.935899, lng: 20.323814 },
    { lat: 48.935947, lng: 20.323676 },
    { lat: 48.935988, lng: 20.323408 },
    { lat: 48.935992, lng: 20.323322 },
    { lat: 48.935939, lng: 20.323104 },
    { lat: 48.93583, lng: 20.32279 },
    { lat: 48.935686, lng: 20.322604 },
    { lat: 48.935449, lng: 20.322221 },
    { lat: 48.935406, lng: 20.322081 },
    { lat: 48.935224, lng: 20.321822 },
    { lat: 48.935178, lng: 20.321712 },
    { lat: 48.934887, lng: 20.321149 },
    { lat: 48.934799, lng: 20.321028 },
    { lat: 48.934616, lng: 20.320782 },
    { lat: 48.934538, lng: 20.320649 },
    { lat: 48.934482, lng: 20.320509 },
    { lat: 48.934439, lng: 20.320188 },
    { lat: 48.934452, lng: 20.320061 },
    { lat: 48.934477, lng: 20.319876 },
    { lat: 48.934485, lng: 20.31966 },
    { lat: 48.934554, lng: 20.318984 },
    { lat: 48.934418, lng: 20.318405 },
    { lat: 48.934371, lng: 20.318236 },
    { lat: 48.934312, lng: 20.318021 },
    { lat: 48.934206, lng: 20.317747 },
    { lat: 48.934087, lng: 20.317571 },
    { lat: 48.933996, lng: 20.317412 },
    { lat: 48.933901, lng: 20.317277 },
    { lat: 48.933718, lng: 20.317091 },
    { lat: 48.933571, lng: 20.316805 },
    { lat: 48.933454, lng: 20.316445 },
    { lat: 48.933327, lng: 20.316079 },
    { lat: 48.933237, lng: 20.315706 },
    { lat: 48.933092, lng: 20.314856 },
    { lat: 48.933071, lng: 20.314857 },
    { lat: 48.932496, lng: 20.314009 },
    { lat: 48.932197, lng: 20.313404 },
    { lat: 48.932138, lng: 20.313017 },
    { lat: 48.93216, lng: 20.312686 },
    { lat: 48.932079, lng: 20.312452 },
    { lat: 48.931977, lng: 20.312269 },
    { lat: 48.931842, lng: 20.312172 },
    { lat: 48.931545, lng: 20.311409 },
    { lat: 48.93146, lng: 20.310895 },
    { lat: 48.932049, lng: 20.310692 },
    { lat: 48.932364, lng: 20.310065 },
    { lat: 48.932293, lng: 20.309775 },
    { lat: 48.932495, lng: 20.308833 },
    { lat: 48.932844, lng: 20.308162 },
    { lat: 48.932884, lng: 20.30719 },
    { lat: 48.933276, lng: 20.306552 },
    { lat: 48.933799, lng: 20.306689 },
    { lat: 48.934295, lng: 20.306205 },
    { lat: 48.934664, lng: 20.305832 },
    { lat: 48.934818, lng: 20.305617 },
    { lat: 48.935029, lng: 20.305438 },
    { lat: 48.935179, lng: 20.304944 },
    { lat: 48.935365, lng: 20.304749 },
    { lat: 48.936109, lng: 20.304218 },
    { lat: 48.936093, lng: 20.304179 },
    { lat: 48.935945, lng: 20.304087 },
    { lat: 48.93596, lng: 20.303859 },
    { lat: 48.935897, lng: 20.302911 },
    { lat: 48.93588, lng: 20.302914 },
    { lat: 48.935783, lng: 20.302542 },
    { lat: 48.935724, lng: 20.302507 },
    { lat: 48.935453, lng: 20.30228 },
    { lat: 48.935346, lng: 20.302133 },
    { lat: 48.935298, lng: 20.302093 },
    { lat: 48.935215, lng: 20.30205 },
    { lat: 48.935065, lng: 20.301939 },
    { lat: 48.934959, lng: 20.301803 },
    { lat: 48.934807, lng: 20.301768 },
    { lat: 48.934744, lng: 20.301676 },
    { lat: 48.934702, lng: 20.301552 },
    { lat: 48.934624, lng: 20.301238 },
    { lat: 48.934506, lng: 20.300602 },
    { lat: 48.934443, lng: 20.300368 },
    { lat: 48.934414, lng: 20.300197 },
    { lat: 48.934455, lng: 20.29995 },
    { lat: 48.934498, lng: 20.299757 },
    { lat: 48.934466, lng: 20.299557 },
    { lat: 48.934443, lng: 20.299182 },
    { lat: 48.934465, lng: 20.299087 },
    { lat: 48.93455, lng: 20.298944 },
    { lat: 48.934605, lng: 20.298783 },
    { lat: 48.934642, lng: 20.298623 },
    { lat: 48.934695, lng: 20.298537 },
    { lat: 48.935074, lng: 20.298248 },
    { lat: 48.935413, lng: 20.2979 },
    { lat: 48.93547, lng: 20.297691 },
    { lat: 48.935753, lng: 20.297159 },
    { lat: 48.936073, lng: 20.29687 },
    { lat: 48.936628, lng: 20.296762 },
    { lat: 48.936689, lng: 20.296701 },
    { lat: 48.936953, lng: 20.296336 },
    { lat: 48.937065, lng: 20.296261 },
    { lat: 48.93714, lng: 20.296243 },
    { lat: 48.937307, lng: 20.296317 },
    { lat: 48.937544, lng: 20.296506 },
    { lat: 48.938047, lng: 20.296808 },
    { lat: 48.938623, lng: 20.297348 },
    { lat: 48.938894, lng: 20.297639 },
    { lat: 48.9389, lng: 20.297623 },
    { lat: 48.939505, lng: 20.297703 },
    { lat: 48.939765, lng: 20.297622 },
    { lat: 48.939961, lng: 20.297834 },
    { lat: 48.9397972, lng: 20.2975609 },
    { lat: 48.9396161, lng: 20.2963547 },
    { lat: 48.9395124, lng: 20.2959181 },
    { lat: 48.9394598, lng: 20.2950914 },
    { lat: 48.9392798, lng: 20.2946079 },
    { lat: 48.9390818, lng: 20.2936905 },
    { lat: 48.9388426, lng: 20.2929244 },
    { lat: 48.9386431, lng: 20.2920891 },
    { lat: 48.9383751, lng: 20.2915409 },
    { lat: 48.9383123, lng: 20.2903383 },
    { lat: 48.9382128, lng: 20.2897799 },
    { lat: 48.9383664, lng: 20.2892428 },
    { lat: 48.93829, lng: 20.2887869 },
    { lat: 48.9384173, lng: 20.288529 },
    { lat: 48.938511, lng: 20.2881612 },
    { lat: 48.9385142, lng: 20.2878995 },
    { lat: 48.9384529, lng: 20.28777 },
    { lat: 48.9384776, lng: 20.2872784 },
    { lat: 48.938607, lng: 20.2870461 },
    { lat: 48.9387792, lng: 20.2869883 },
    { lat: 48.9388789, lng: 20.2870291 },
    { lat: 48.9389521, lng: 20.2871436 },
    { lat: 48.9389607, lng: 20.2873653 },
    { lat: 48.9395975, lng: 20.2878688 },
    { lat: 48.9399009, lng: 20.2878551 },
    { lat: 48.9409145, lng: 20.2882849 },
    { lat: 48.9410859, lng: 20.2884378 },
    { lat: 48.9411644, lng: 20.2884006 },
    { lat: 48.9416718, lng: 20.2885906 },
    { lat: 48.9419246, lng: 20.2889522 },
    { lat: 48.9422339, lng: 20.2889386 },
    { lat: 48.9427177, lng: 20.2887299 },
    { lat: 48.9431277, lng: 20.2888785 },
    { lat: 48.9434023, lng: 20.2891586 },
    { lat: 48.9435295, lng: 20.2891836 },
    { lat: 48.943731, lng: 20.2889842 },
    { lat: 48.9438879, lng: 20.2891341 },
    { lat: 48.9443768, lng: 20.2892839 },
    { lat: 48.9444725, lng: 20.2893415 },
    { lat: 48.9446432, lng: 20.2895916 },
    { lat: 48.9447321, lng: 20.2898734 },
    { lat: 48.9449542, lng: 20.2899723 },
    { lat: 48.9450361, lng: 20.2902207 },
    { lat: 48.9458964, lng: 20.2886923 },
    { lat: 48.9455789, lng: 20.2882914 },
    { lat: 48.9452444, lng: 20.2871651 },
    { lat: 48.9449896, lng: 20.2866124 },
    { lat: 48.9441269, lng: 20.2840504 },
    { lat: 48.9437479, lng: 20.2830847 },
    { lat: 48.9425759, lng: 20.2814259 },
    { lat: 48.9419654, lng: 20.2802133 },
    { lat: 48.9416995, lng: 20.2788291 },
    { lat: 48.941548, lng: 20.2783832 },
    { lat: 48.9414111, lng: 20.2774811 },
    { lat: 48.9411573, lng: 20.2768947 },
    { lat: 48.9404705, lng: 20.2756872 },
    { lat: 48.9401881, lng: 20.274949 },
    { lat: 48.9401706, lng: 20.2746777 },
    { lat: 48.9400793, lng: 20.2745025 },
    { lat: 48.9400581, lng: 20.2741609 },
    { lat: 48.9400567, lng: 20.2730076 },
    { lat: 48.9401023, lng: 20.2727334 },
    { lat: 48.9399747, lng: 20.2720976 },
    { lat: 48.9397323, lng: 20.2713136 },
    { lat: 48.9397027, lng: 20.2709348 },
    { lat: 48.9393889, lng: 20.2701128 },
    { lat: 48.9391346, lng: 20.2697178 },
    { lat: 48.9390623, lng: 20.2694801 },
    { lat: 48.9388813, lng: 20.2691741 },
    { lat: 48.9377999, lng: 20.2683349 },
    { lat: 48.9377622, lng: 20.2676047 },
    { lat: 48.9376373, lng: 20.2666009 },
    { lat: 48.9378865, lng: 20.2634008 },
    { lat: 48.9381998, lng: 20.2619471 },
    { lat: 48.9387416, lng: 20.2600049 },
    { lat: 48.9387615, lng: 20.258912 },
    { lat: 48.9379551, lng: 20.2557946 },
    { lat: 48.9378104, lng: 20.2544334 },
    { lat: 48.9372557, lng: 20.2529628 },
    { lat: 48.9366795, lng: 20.2505186 },
    { lat: 48.9371018, lng: 20.2485173 },
    { lat: 48.9371272, lng: 20.2442535 },
    { lat: 48.9371968, lng: 20.2435369 },
    { lat: 48.9372002, lng: 20.243481 },
    { lat: 48.937203, lng: 20.2434378 },
    { lat: 48.9371347, lng: 20.2430539 },
    { lat: 48.9371402, lng: 20.2426788 },
    { lat: 48.9373509, lng: 20.2412809 },
    { lat: 48.9373424, lng: 20.2408389 },
    { lat: 48.9370864, lng: 20.2394069 },
    { lat: 48.936849, lng: 20.2388715 },
    { lat: 48.9365312, lng: 20.2379233 },
    { lat: 48.9362423, lng: 20.2365749 },
    { lat: 48.936449, lng: 20.2360184 },
    { lat: 48.9365576, lng: 20.2354364 },
    { lat: 48.9370065, lng: 20.2339717 },
    { lat: 48.9372598, lng: 20.2325483 },
    { lat: 48.9372477, lng: 20.2320716 },
    { lat: 48.937406, lng: 20.2313798 },
    { lat: 48.9375704, lng: 20.2309249 },
    { lat: 48.9379778, lng: 20.2304335 },
    { lat: 48.9383693, lng: 20.2283201 },
    { lat: 48.9385403, lng: 20.2278894 },
    { lat: 48.9386536, lng: 20.2272525 },
    { lat: 48.9385976, lng: 20.2267196 },
    { lat: 48.9385858, lng: 20.2259111 },
    { lat: 48.9385017, lng: 20.2252428 },
    { lat: 48.9384038, lng: 20.2250353 },
    { lat: 48.9383061, lng: 20.2249398 },
    { lat: 48.9379529, lng: 20.2248345 },
    { lat: 48.9373095, lng: 20.224256 },
    { lat: 48.9362406, lng: 20.2237155 },
    { lat: 48.9357644, lng: 20.2236046 },
    { lat: 48.9348143, lng: 20.2229303 },
    { lat: 48.9345712, lng: 20.2224246 },
    { lat: 48.9339702, lng: 20.2217245 },
    { lat: 48.9338112, lng: 20.2213805 },
    { lat: 48.9337417, lng: 20.2209631 },
    { lat: 48.9332038, lng: 20.2204314 },
    { lat: 48.9327098, lng: 20.2195922 },
    { lat: 48.9325094, lng: 20.2191149 },
    { lat: 48.9321021, lng: 20.2185819 },
    { lat: 48.9318931, lng: 20.217913 },
    { lat: 48.9313633, lng: 20.2170503 },
    { lat: 48.9306688, lng: 20.2156437 },
    { lat: 48.930459, lng: 20.2154945 },
    { lat: 48.9302878, lng: 20.2154637 },
    { lat: 48.9300167, lng: 20.2151006 },
    { lat: 48.9291354, lng: 20.2145775 },
    { lat: 48.9288144, lng: 20.2144919 },
    { lat: 48.9283977, lng: 20.2146056 },
    { lat: 48.9281779, lng: 20.2145155 },
    { lat: 48.9278618, lng: 20.2142156 },
    { lat: 48.9277456, lng: 20.2138689 },
    { lat: 48.9273634, lng: 20.2134623 },
    { lat: 48.9272939, lng: 20.2134414 },
    { lat: 48.9270386, lng: 20.21365 },
    { lat: 48.9267855, lng: 20.2137656 },
    { lat: 48.9266451, lng: 20.2137165 },
    { lat: 48.9264689, lng: 20.2135357 },
    { lat: 48.9261773, lng: 20.2130284 },
    { lat: 48.9258342, lng: 20.2127268 },
    { lat: 48.9256583, lng: 20.2127225 },
    { lat: 48.9255454, lng: 20.2126315 },
    { lat: 48.9250746, lng: 20.2120919 },
    { lat: 48.9248872, lng: 20.2119963 },
    { lat: 48.9247508, lng: 20.2118183 },
    { lat: 48.924426, lng: 20.2118939 },
    { lat: 48.9241849, lng: 20.2120309 },
    { lat: 48.9240443, lng: 20.2119514 },
    { lat: 48.9237982, lng: 20.2115425 },
    { lat: 48.923672, lng: 20.2114541 },
    { lat: 48.9234991, lng: 20.2114377 },
    { lat: 48.9229819, lng: 20.2112033 },
    { lat: 48.922665, lng: 20.2108012 },
    { lat: 48.9225316, lng: 20.210249 },
    { lat: 48.9223865, lng: 20.2099415 },
    { lat: 48.9219555, lng: 20.209726 },
    { lat: 48.9215598, lng: 20.2093179 },
    { lat: 48.9208828, lng: 20.2078731 },
    { lat: 48.9206518, lng: 20.2072759 },
    { lat: 48.9203559, lng: 20.2069671 },
    { lat: 48.9198552, lng: 20.2059621 },
    { lat: 48.9194678, lng: 20.2047515 },
    { lat: 48.9194624, lng: 20.2047298 },
    { lat: 48.9188698, lng: 20.2020352 },
    { lat: 48.9189814, lng: 20.2012773 },
    { lat: 48.919023, lng: 20.2004262 },
    { lat: 48.9189889, lng: 20.1994908 },
    { lat: 48.9188224, lng: 20.1972398 },
    { lat: 48.9185334, lng: 20.1958946 },
    { lat: 48.9184398, lng: 20.1950459 },
    { lat: 48.9182777, lng: 20.1943436 },
    { lat: 48.9178174, lng: 20.1930238 },
    { lat: 48.917478, lng: 20.1925086 },
    { lat: 48.9170797, lng: 20.1915001 },
    { lat: 48.9168771, lng: 20.1896736 },
    { lat: 48.9165533, lng: 20.1887019 },
    { lat: 48.916344, lng: 20.1884236 },
    { lat: 48.9157682, lng: 20.1880527 },
    { lat: 48.9153368, lng: 20.1874339 },
    { lat: 48.9147048, lng: 20.1868387 },
    { lat: 48.9133924, lng: 20.1866839 },
    { lat: 48.9128544, lng: 20.1865251 },
  ],
  TatranskáJavorina: [
    { lat: 49.3277346, lng: 20.1705837 },
    { lat: 49.3273481, lng: 20.1705475 },
    { lat: 49.3269747, lng: 20.1708016 },
    { lat: 49.326969, lng: 20.170806 },
    { lat: 49.326346, lng: 20.171598 },
    { lat: 49.325714, lng: 20.172389 },
    { lat: 49.3257019, lng: 20.1724077 },
    { lat: 49.3255213, lng: 20.1726785 },
    { lat: 49.3249795, lng: 20.1735748 },
    { lat: 49.324976, lng: 20.173579 },
    { lat: 49.324912, lng: 20.173082 },
    { lat: 49.3248385, lng: 20.1725484 },
    { lat: 49.3246483, lng: 20.172095 },
    { lat: 49.3247371, lng: 20.1719729 },
    { lat: 49.3241707, lng: 20.1703969 },
    { lat: 49.3246018, lng: 20.1702711 },
    { lat: 49.324569, lng: 20.170212 },
    { lat: 49.324452, lng: 20.170021 },
    { lat: 49.324329, lng: 20.169823 },
    { lat: 49.3241641, lng: 20.1695344 },
    { lat: 49.324158, lng: 20.169517 },
    { lat: 49.3240022, lng: 20.1689287 },
    { lat: 49.3239629, lng: 20.1687475 },
    { lat: 49.323868, lng: 20.168526 },
    { lat: 49.323821, lng: 20.168422 },
    { lat: 49.3238197, lng: 20.1684128 },
    { lat: 49.3237722, lng: 20.1682388 },
    { lat: 49.3237281, lng: 20.1681096 },
    { lat: 49.3235657, lng: 20.1677834 },
    { lat: 49.3234979, lng: 20.167718 },
    { lat: 49.323494, lng: 20.167715 },
    { lat: 49.32342, lng: 20.167666 },
    { lat: 49.3233577, lng: 20.1676245 },
    { lat: 49.3233279, lng: 20.1675899 },
    { lat: 49.32329, lng: 20.1674763 },
    { lat: 49.3231808, lng: 20.1674508 },
    { lat: 49.3231426, lng: 20.1674852 },
    { lat: 49.32314, lng: 20.1675928 },
    { lat: 49.3231982, lng: 20.1677339 },
    { lat: 49.3231638, lng: 20.1679004 },
    { lat: 49.3231292, lng: 20.1680291 },
    { lat: 49.3230628, lng: 20.1680877 },
    { lat: 49.3229886, lng: 20.1680279 },
    { lat: 49.3229409, lng: 20.16794 },
    { lat: 49.3228503, lng: 20.1680499 },
    { lat: 49.3228521, lng: 20.1681961 },
    { lat: 49.3228025, lng: 20.1683102 },
    { lat: 49.3227233, lng: 20.1683191 },
    { lat: 49.322593, lng: 20.1682251 },
    { lat: 49.3225204, lng: 20.168244 },
    { lat: 49.3224745, lng: 20.1684481 },
    { lat: 49.3223453, lng: 20.1686493 },
    { lat: 49.3222568, lng: 20.1687469 },
    { lat: 49.322204, lng: 20.1687538 },
    { lat: 49.322173, lng: 20.168683 },
    { lat: 49.3221256, lng: 20.1685869 },
    { lat: 49.321971, lng: 20.1686032 },
    { lat: 49.3219326, lng: 20.1686344 },
    { lat: 49.3219195, lng: 20.1686869 },
    { lat: 49.3219588, lng: 20.1688354 },
    { lat: 49.3219521, lng: 20.1688586 },
    { lat: 49.3219003, lng: 20.168877 },
    { lat: 49.3218543, lng: 20.1688476 },
    { lat: 49.3217783, lng: 20.16874 },
    { lat: 49.3216903, lng: 20.1687327 },
    { lat: 49.3215468, lng: 20.1687502 },
    { lat: 49.3214797, lng: 20.168851 },
    { lat: 49.3214129, lng: 20.1688129 },
    { lat: 49.3213592, lng: 20.1685826 },
    { lat: 49.3213241, lng: 20.1685735 },
    { lat: 49.3212451, lng: 20.1686386 },
    { lat: 49.3211712, lng: 20.1685812 },
    { lat: 49.3210724, lng: 20.1685551 },
    { lat: 49.3209913, lng: 20.1686021 },
    { lat: 49.3209318, lng: 20.1686637 },
    { lat: 49.3208039, lng: 20.1688598 },
    { lat: 49.3207606, lng: 20.1689002 },
    { lat: 49.3206588, lng: 20.1689638 },
    { lat: 49.3206315, lng: 20.1690239 },
    { lat: 49.3206376, lng: 20.1692038 },
    { lat: 49.3205696, lng: 20.1692606 },
    { lat: 49.3205069, lng: 20.1693972 },
    { lat: 49.3204321, lng: 20.1694939 },
    { lat: 49.3203375, lng: 20.1694748 },
    { lat: 49.3202463, lng: 20.1695218 },
    { lat: 49.3201117, lng: 20.169551 },
    { lat: 49.3200297, lng: 20.1696769 },
    { lat: 49.3199313, lng: 20.1697002 },
    { lat: 49.3198465, lng: 20.1698556 },
    { lat: 49.3197492, lng: 20.1699042 },
    { lat: 49.319683, lng: 20.1698468 },
    { lat: 49.3196172, lng: 20.1698751 },
    { lat: 49.319607, lng: 20.16999 },
    { lat: 49.3196026, lng: 20.1701227 },
    { lat: 49.3195247, lng: 20.1700456 },
    { lat: 49.319521, lng: 20.170042 },
    { lat: 49.319427, lng: 20.170224 },
    { lat: 49.319371, lng: 20.1703231 },
    { lat: 49.319354, lng: 20.17034 },
    { lat: 49.3192461, lng: 20.1704718 },
    { lat: 49.3191239, lng: 20.1706927 },
    { lat: 49.3190631, lng: 20.1707567 },
    { lat: 49.319029, lng: 20.170914 },
    { lat: 49.3190278, lng: 20.1709219 },
    { lat: 49.318964, lng: 20.1710657 },
    { lat: 49.3188359, lng: 20.1712415 },
    { lat: 49.3187779, lng: 20.1714196 },
    { lat: 49.3186755, lng: 20.171567 },
    { lat: 49.3185906, lng: 20.1716243 },
    { lat: 49.3185183, lng: 20.1718343 },
    { lat: 49.3184967, lng: 20.1719569 },
    { lat: 49.3183945, lng: 20.1720307 },
    { lat: 49.31835, lng: 20.17218 },
    { lat: 49.3183507, lng: 20.1721855 },
    { lat: 49.318248, lng: 20.172264 },
    { lat: 49.3181145, lng: 20.1723634 },
    { lat: 49.3180309, lng: 20.1723206 },
    { lat: 49.3179622, lng: 20.1722492 },
    { lat: 49.317957, lng: 20.172245 },
    { lat: 49.3178573, lng: 20.172184 },
    { lat: 49.3177953, lng: 20.1722044 },
    { lat: 49.317639, lng: 20.1723036 },
    { lat: 49.317584, lng: 20.172357 },
    { lat: 49.317467, lng: 20.1724756 },
    { lat: 49.3173573, lng: 20.1724839 },
    { lat: 49.3172526, lng: 20.1726241 },
    { lat: 49.3171823, lng: 20.1726726 },
    { lat: 49.317094, lng: 20.172638 },
    { lat: 49.317019, lng: 20.1726122 },
    { lat: 49.316969, lng: 20.172722 },
    { lat: 49.316918, lng: 20.172873 },
    { lat: 49.3169138, lng: 20.1728798 },
    { lat: 49.3168514, lng: 20.1729432 },
    { lat: 49.3167283, lng: 20.1729563 },
    { lat: 49.3166884, lng: 20.1729986 },
    { lat: 49.3166623, lng: 20.1729833 },
    { lat: 49.316658, lng: 20.172981 },
    { lat: 49.3166071, lng: 20.1729788 },
    { lat: 49.3165467, lng: 20.1730442 },
    { lat: 49.3165285, lng: 20.1731791 },
    { lat: 49.3164562, lng: 20.1733131 },
    { lat: 49.31645, lng: 20.17332 },
    { lat: 49.316394, lng: 20.17335 },
    { lat: 49.3161806, lng: 20.1734983 },
    { lat: 49.316176, lng: 20.1735 },
    { lat: 49.3161146, lng: 20.173558 },
    { lat: 49.3160459, lng: 20.173546 },
    { lat: 49.315955, lng: 20.17364 },
    { lat: 49.315886, lng: 20.173709 },
    { lat: 49.3158829, lng: 20.1737173 },
    { lat: 49.3158378, lng: 20.1737913 },
    { lat: 49.315833, lng: 20.173796 },
    { lat: 49.315738, lng: 20.173877 },
    { lat: 49.315566, lng: 20.174036 },
    { lat: 49.3155632, lng: 20.1740415 },
    { lat: 49.3154188, lng: 20.1741239 },
    { lat: 49.3153713, lng: 20.1744366 },
    { lat: 49.3153777, lng: 20.1745353 },
    { lat: 49.3153367, lng: 20.1745969 },
    { lat: 49.3153395, lng: 20.1746742 },
    { lat: 49.3152765, lng: 20.1748549 },
    { lat: 49.3152032, lng: 20.1749857 },
    { lat: 49.3151327, lng: 20.1750757 },
    { lat: 49.3150431, lng: 20.175157 },
    { lat: 49.315012, lng: 20.175222 },
    { lat: 49.3150112, lng: 20.1752289 },
    { lat: 49.314928, lng: 20.175301 },
    { lat: 49.314837, lng: 20.1753798 },
    { lat: 49.314831, lng: 20.17538 },
    { lat: 49.3147662, lng: 20.1754091 },
    { lat: 49.314732, lng: 20.175421 },
    { lat: 49.314655, lng: 20.17546 },
    { lat: 49.314518, lng: 20.17552 },
    { lat: 49.3145149, lng: 20.1755256 },
    { lat: 49.3144176, lng: 20.1756052 },
    { lat: 49.314355, lng: 20.1756327 },
    { lat: 49.3142618, lng: 20.1756881 },
    { lat: 49.3141897, lng: 20.1757562 },
    { lat: 49.3140828, lng: 20.1758037 },
    { lat: 49.313982, lng: 20.175623 },
    { lat: 49.31396, lng: 20.1755795 },
    { lat: 49.313514, lng: 20.174889 },
    { lat: 49.31291, lng: 20.173949 },
    { lat: 49.312159, lng: 20.172789 },
    { lat: 49.3119474, lng: 20.1724529 },
    { lat: 49.311214, lng: 20.17129 },
    { lat: 49.310488, lng: 20.170118 },
    { lat: 49.3102418, lng: 20.1697245 },
    { lat: 49.309847, lng: 20.169105 },
    { lat: 49.308923, lng: 20.167644 },
    { lat: 49.30878, lng: 20.1674149 },
    { lat: 49.308676, lng: 20.167257 },
    { lat: 49.308355, lng: 20.166726 },
    { lat: 49.3082866, lng: 20.1666163 },
    { lat: 49.307818, lng: 20.16589 },
    { lat: 49.3078175, lng: 20.1658846 },
    { lat: 49.3073878, lng: 20.1651834 },
    { lat: 49.307375, lng: 20.165167 },
    { lat: 49.306742, lng: 20.1641837 },
    { lat: 49.306717, lng: 20.164149 },
    { lat: 49.3061369, lng: 20.1632861 },
    { lat: 49.30572, lng: 20.162607 },
    { lat: 49.3057155, lng: 20.1625972 },
    { lat: 49.305402, lng: 20.1621327 },
    { lat: 49.3050767, lng: 20.1615085 },
    { lat: 49.305068, lng: 20.161497 },
    { lat: 49.304687, lng: 20.160699 },
    { lat: 49.3046862, lng: 20.160693 },
    { lat: 49.3046295, lng: 20.1605977 },
    { lat: 49.304584, lng: 20.1605782 },
    { lat: 49.3045575, lng: 20.160565 },
    { lat: 49.3044925, lng: 20.1604394 },
    { lat: 49.304488, lng: 20.160436 },
    { lat: 49.3037865, lng: 20.1592474 },
    { lat: 49.302891, lng: 20.157843 },
    { lat: 49.302877, lng: 20.157822 },
    { lat: 49.3028155, lng: 20.1577195 },
    { lat: 49.30292, lng: 20.157648 },
    { lat: 49.3029748, lng: 20.1576143 },
    { lat: 49.3031138, lng: 20.1575608 },
    { lat: 49.303221, lng: 20.157528 },
    { lat: 49.3032718, lng: 20.1575162 },
    { lat: 49.303307, lng: 20.157504 },
    { lat: 49.3036222, lng: 20.1574385 },
    { lat: 49.303626, lng: 20.157435 },
    { lat: 49.3037432, lng: 20.1574282 },
    { lat: 49.3039208, lng: 20.1573825 },
    { lat: 49.30393, lng: 20.157377 },
    { lat: 49.304052, lng: 20.157366 },
    { lat: 49.304152, lng: 20.157351 },
    { lat: 49.304248, lng: 20.157342 },
    { lat: 49.3043898, lng: 20.1573183 },
    { lat: 49.30456, lng: 20.157308 },
    { lat: 49.3045762, lng: 20.1573098 },
    { lat: 49.304663, lng: 20.157311 },
    { lat: 49.3046862, lng: 20.1573169 },
    { lat: 49.304847, lng: 20.157334 },
    { lat: 49.3049023, lng: 20.1573439 },
    { lat: 49.304913, lng: 20.157344 },
    { lat: 49.305184, lng: 20.157407 },
    { lat: 49.3052758, lng: 20.1574296 },
    { lat: 49.305293, lng: 20.15743 },
    { lat: 49.305336, lng: 20.157436 },
    { lat: 49.3053407, lng: 20.1574397 },
    { lat: 49.3054228, lng: 20.157465 },
    { lat: 49.3054843, lng: 20.1575025 },
    { lat: 49.3056655, lng: 20.1575597 },
    { lat: 49.305759, lng: 20.15763 },
    { lat: 49.3057749, lng: 20.1576443 },
    { lat: 49.3058668, lng: 20.1578385 },
    { lat: 49.3059441, lng: 20.1581473 },
    { lat: 49.3060271, lng: 20.1583669 },
    { lat: 49.306064, lng: 20.1584647 },
    { lat: 49.3061201, lng: 20.1585664 },
    { lat: 49.3061565, lng: 20.158619 },
    { lat: 49.3062475, lng: 20.1587259 },
    { lat: 49.3063238, lng: 20.1587937 },
    { lat: 49.3064101, lng: 20.158854 },
    { lat: 49.3065458, lng: 20.1589624 },
    { lat: 49.3066149, lng: 20.1589545 },
    { lat: 49.3067581, lng: 20.1589145 },
    { lat: 49.306812, lng: 20.158913 },
    { lat: 49.3068176, lng: 20.158916 },
    { lat: 49.306944, lng: 20.158834 },
    { lat: 49.307074, lng: 20.158751 },
    { lat: 49.3071788, lng: 20.1586889 },
    { lat: 49.307179, lng: 20.158682 },
    { lat: 49.3072443, lng: 20.1585992 },
    { lat: 49.307343, lng: 20.158513 },
    { lat: 49.307372, lng: 20.158493 },
    { lat: 49.307547, lng: 20.158353 },
    { lat: 49.30778, lng: 20.158157 },
    { lat: 49.307886, lng: 20.158053 },
    { lat: 49.307924, lng: 20.158012 },
    { lat: 49.308035, lng: 20.157882 },
    { lat: 49.308132, lng: 20.15778 },
    { lat: 49.3081471, lng: 20.1577621 },
    { lat: 49.308441, lng: 20.157576 },
    { lat: 49.308604, lng: 20.1574796 },
    { lat: 49.308607, lng: 20.157475 },
    { lat: 49.3087423, lng: 20.1574235 },
    { lat: 49.308759, lng: 20.157414 },
    { lat: 49.308881, lng: 20.157375 },
    { lat: 49.308891, lng: 20.1573742 },
    { lat: 49.309048, lng: 20.157343 },
    { lat: 49.309213, lng: 20.157319 },
    { lat: 49.3092818, lng: 20.1573074 },
    { lat: 49.309388, lng: 20.157274 },
    { lat: 49.3094664, lng: 20.1572533 },
    { lat: 49.309474, lng: 20.157247 },
    { lat: 49.309558, lng: 20.157209 },
    { lat: 49.3097525, lng: 20.157128 },
    { lat: 49.30979, lng: 20.157109 },
    { lat: 49.309891, lng: 20.15707 },
    { lat: 49.310071, lng: 20.156994 },
    { lat: 49.310433, lng: 20.156852 },
    { lat: 49.3106132, lng: 20.1567793 },
    { lat: 49.31065, lng: 20.156769 },
    { lat: 49.3108177, lng: 20.1567285 },
    { lat: 49.310961, lng: 20.156671 },
    { lat: 49.3109729, lng: 20.1566695 },
    { lat: 49.310985, lng: 20.156659 },
    { lat: 49.3111079, lng: 20.1565977 },
    { lat: 49.311119, lng: 20.156589 },
    { lat: 49.31136, lng: 20.156439 },
    { lat: 49.311429, lng: 20.156403 },
    { lat: 49.3115329, lng: 20.1563413 },
    { lat: 49.311547, lng: 20.156326 },
    { lat: 49.3116496, lng: 20.1562531 },
    { lat: 49.311653, lng: 20.156245 },
    { lat: 49.3117511, lng: 20.1561648 },
    { lat: 49.311789, lng: 20.156121 },
    { lat: 49.3118145, lng: 20.1560972 },
    { lat: 49.311825, lng: 20.156077 },
    { lat: 49.3118317, lng: 20.1560693 },
    { lat: 49.311881, lng: 20.155881 },
    { lat: 49.3119159, lng: 20.155749 },
    { lat: 49.311915, lng: 20.155734 },
    { lat: 49.3119346, lng: 20.155585 },
    { lat: 49.311937, lng: 20.155348 },
    { lat: 49.3119408, lng: 20.1552521 },
    { lat: 49.311938, lng: 20.155244 },
    { lat: 49.311925, lng: 20.155064 },
    { lat: 49.311914, lng: 20.154936 },
    { lat: 49.3119169, lng: 20.1549318 },
    { lat: 49.3119298, lng: 20.1547362 },
    { lat: 49.311928, lng: 20.154728 },
    { lat: 49.3119463, lng: 20.154637 },
    { lat: 49.311945, lng: 20.154632 },
    { lat: 49.3120154, lng: 20.1544219 },
    { lat: 49.312015, lng: 20.154415 },
    { lat: 49.3120763, lng: 20.1542911 },
    { lat: 49.3121646, lng: 20.1541375 },
    { lat: 49.3122531, lng: 20.1540664 },
    { lat: 49.3123368, lng: 20.1540394 },
    { lat: 49.312345, lng: 20.154033 },
    { lat: 49.3124635, lng: 20.1539826 },
    { lat: 49.312562, lng: 20.153974 },
    { lat: 49.312844, lng: 20.153953 },
    { lat: 49.313033, lng: 20.153936 },
    { lat: 49.313135, lng: 20.153932 },
    { lat: 49.3131662, lng: 20.1539309 },
    { lat: 49.313179, lng: 20.153929 },
    { lat: 49.313325, lng: 20.153939 },
    { lat: 49.313503, lng: 20.153949 },
    { lat: 49.313598, lng: 20.153958 },
    { lat: 49.3136242, lng: 20.1539608 },
    { lat: 49.313658, lng: 20.153955 },
    { lat: 49.3137779, lng: 20.1539436 },
    { lat: 49.3138627, lng: 20.1539033 },
    { lat: 49.3139001, lng: 20.1538642 },
    { lat: 49.3139476, lng: 20.1537978 },
    { lat: 49.313974, lng: 20.153764 },
    { lat: 49.314004, lng: 20.153731 },
    { lat: 49.3140857, lng: 20.1536435 },
    { lat: 49.314084, lng: 20.153638 },
    { lat: 49.3141577, lng: 20.1534617 },
    { lat: 49.3143251, lng: 20.1532369 },
    { lat: 49.314328, lng: 20.153228 },
    { lat: 49.314465, lng: 20.153067 },
    { lat: 49.3144767, lng: 20.1530583 },
    { lat: 49.314598, lng: 20.15288 },
    { lat: 49.3146549, lng: 20.1528035 },
    { lat: 49.314658, lng: 20.152791 },
    { lat: 49.3147668, lng: 20.1525857 },
    { lat: 49.3148271, lng: 20.1524927 },
    { lat: 49.314838, lng: 20.152469 },
    { lat: 49.314929, lng: 20.152261 },
    { lat: 49.3150624, lng: 20.1519855 },
    { lat: 49.315079, lng: 20.151949 },
    { lat: 49.3151291, lng: 20.1518681 },
    { lat: 49.3152298, lng: 20.1518203 },
    { lat: 49.315232, lng: 20.151815 },
    { lat: 49.3153208, lng: 20.1518153 },
    { lat: 49.3154583, lng: 20.1518616 },
    { lat: 49.315559, lng: 20.151914 },
    { lat: 49.3155648, lng: 20.1519195 },
    { lat: 49.315664, lng: 20.15198 },
    { lat: 49.3158386, lng: 20.152094 },
    { lat: 49.3159559, lng: 20.1522813 },
    { lat: 49.3160181, lng: 20.1523322 },
    { lat: 49.3161532, lng: 20.1524144 },
    { lat: 49.316371, lng: 20.1525286 },
    { lat: 49.3165502, lng: 20.1525703 },
    { lat: 49.3166652, lng: 20.1526408 },
    { lat: 49.3168027, lng: 20.1527007 },
    { lat: 49.3169632, lng: 20.1526475 },
    { lat: 49.3174169, lng: 20.1523663 },
    { lat: 49.3175177, lng: 20.1523127 },
    { lat: 49.3177241, lng: 20.1521676 },
    { lat: 49.317807, lng: 20.152126 },
    { lat: 49.3178318, lng: 20.1521177 },
    { lat: 49.317999, lng: 20.152052 },
    { lat: 49.318157, lng: 20.152002 },
    { lat: 49.3183138, lng: 20.1519468 },
    { lat: 49.3184472, lng: 20.1518647 },
    { lat: 49.3186342, lng: 20.1516865 },
    { lat: 49.318714, lng: 20.151574 },
    { lat: 49.3187821, lng: 20.151484 },
    { lat: 49.3187702, lng: 20.1514515 },
    { lat: 49.3187585, lng: 20.1512831 },
    { lat: 49.318774, lng: 20.151133 },
    { lat: 49.318798, lng: 20.150979 },
    { lat: 49.31886, lng: 20.150828 },
    { lat: 49.318886, lng: 20.15078 },
    { lat: 49.318957, lng: 20.150586 },
    { lat: 49.319089, lng: 20.150174 },
    { lat: 49.319154, lng: 20.149997 },
    { lat: 49.319194, lng: 20.149734 },
    { lat: 49.319238, lng: 20.149503 },
    { lat: 49.31922, lng: 20.149412 },
    { lat: 49.319207, lng: 20.149341 },
    { lat: 49.319167, lng: 20.149167 },
    { lat: 49.319109, lng: 20.148896 },
    { lat: 49.319051, lng: 20.148663 },
    { lat: 49.318969, lng: 20.148411 },
    { lat: 49.31886, lng: 20.148116 },
    { lat: 49.318765, lng: 20.147855 },
    { lat: 49.318698, lng: 20.147703 },
    { lat: 49.318593, lng: 20.14746 },
    { lat: 49.318516, lng: 20.147275 },
    { lat: 49.318432, lng: 20.147051 },
    { lat: 49.318371, lng: 20.146849 },
    { lat: 49.318288, lng: 20.146666 },
    { lat: 49.318235, lng: 20.146527 },
    { lat: 49.318053, lng: 20.146208 },
    { lat: 49.317997, lng: 20.146096 },
    { lat: 49.317978, lng: 20.14605 },
    { lat: 49.317779, lng: 20.145803 },
    { lat: 49.317649, lng: 20.145678 },
    { lat: 49.317602, lng: 20.145652 },
    { lat: 49.317513, lng: 20.145504 },
    { lat: 49.317376, lng: 20.145548 },
    { lat: 49.317308, lng: 20.145573 },
    { lat: 49.317219, lng: 20.145655 },
    { lat: 49.317173, lng: 20.145681 },
    { lat: 49.317004, lng: 20.145757 },
    { lat: 49.316801, lng: 20.145827 },
    { lat: 49.316605, lng: 20.145866 },
    { lat: 49.316465, lng: 20.145852 },
    { lat: 49.316305, lng: 20.145796 },
    { lat: 49.316137, lng: 20.14573 },
    { lat: 49.31611, lng: 20.145712 },
    { lat: 49.315781, lng: 20.145471 },
    { lat: 49.315661, lng: 20.145333 },
    { lat: 49.315517, lng: 20.145228 },
    { lat: 49.315331, lng: 20.145015 },
    { lat: 49.315241, lng: 20.144917 },
    { lat: 49.31509, lng: 20.144785 },
    { lat: 49.314984, lng: 20.144669 },
    { lat: 49.314935, lng: 20.144619 },
    { lat: 49.314818, lng: 20.14452 },
    { lat: 49.314779, lng: 20.144498 },
    { lat: 49.31462, lng: 20.144426 },
    { lat: 49.314433, lng: 20.144384 },
    { lat: 49.314268, lng: 20.144411 },
    { lat: 49.313993, lng: 20.144497 },
    { lat: 49.313917, lng: 20.144543 },
    { lat: 49.313879, lng: 20.144558 },
    { lat: 49.313799, lng: 20.144682 },
    { lat: 49.313643, lng: 20.144841 },
    { lat: 49.313549, lng: 20.144967 },
    { lat: 49.313344, lng: 20.145142 },
    { lat: 49.313184, lng: 20.145192 },
    { lat: 49.313081, lng: 20.145217 },
    { lat: 49.312949, lng: 20.14527 },
    { lat: 49.312784, lng: 20.145247 },
    { lat: 49.31262, lng: 20.145146 },
    { lat: 49.312512, lng: 20.144992 },
    { lat: 49.312441, lng: 20.144856 },
    { lat: 49.312277, lng: 20.144617 },
    { lat: 49.3122, lng: 20.144503 },
    { lat: 49.312122, lng: 20.144321 },
    { lat: 49.312061, lng: 20.144088 },
    { lat: 49.311984, lng: 20.143913 },
    { lat: 49.311957, lng: 20.14382 },
    { lat: 49.311932, lng: 20.143612 },
    { lat: 49.311933, lng: 20.143394 },
    { lat: 49.311993, lng: 20.14315 },
    { lat: 49.312093, lng: 20.142845 },
    { lat: 49.312151, lng: 20.142699 },
    { lat: 49.312205, lng: 20.142616 },
    { lat: 49.312299, lng: 20.142525 },
    { lat: 49.312389, lng: 20.14239 },
    { lat: 49.312424, lng: 20.142222 },
    { lat: 49.312425, lng: 20.142068 },
    { lat: 49.312403, lng: 20.141991 },
    { lat: 49.312357, lng: 20.141899 },
    { lat: 49.31228, lng: 20.141786 },
    { lat: 49.312171, lng: 20.141709 },
    { lat: 49.312024, lng: 20.141612 },
    { lat: 49.311872, lng: 20.141519 },
    { lat: 49.311715, lng: 20.141432 },
    { lat: 49.311542, lng: 20.141358 },
    { lat: 49.311413, lng: 20.1413 },
    { lat: 49.311321, lng: 20.141246 },
    { lat: 49.311218, lng: 20.141199 },
    { lat: 49.311002, lng: 20.141061 },
    { lat: 49.310805, lng: 20.14093 },
    { lat: 49.310642, lng: 20.140842 },
    { lat: 49.310486, lng: 20.140737 },
    { lat: 49.310239, lng: 20.140546 },
    { lat: 49.31017, lng: 20.140478 },
    { lat: 49.310013, lng: 20.140317 },
    { lat: 49.309879, lng: 20.140159 },
    { lat: 49.309778, lng: 20.139986 },
    { lat: 49.309712, lng: 20.139954 },
    { lat: 49.309518, lng: 20.139701 },
    { lat: 49.309366, lng: 20.139498 },
    { lat: 49.309192, lng: 20.139309 },
    { lat: 49.309026, lng: 20.139185 },
    { lat: 49.308844, lng: 20.139039 },
    { lat: 49.308649, lng: 20.138896 },
    { lat: 49.308491, lng: 20.138797 },
    { lat: 49.308036, lng: 20.13863 },
    { lat: 49.307777, lng: 20.138582 },
    { lat: 49.307591, lng: 20.138621 },
    { lat: 49.30752, lng: 20.138717 },
    { lat: 49.30749, lng: 20.138748 },
    { lat: 49.307409, lng: 20.138812 },
    { lat: 49.307319, lng: 20.138826 },
    { lat: 49.307219, lng: 20.138859 },
    { lat: 49.30711, lng: 20.138865 },
    { lat: 49.307, lng: 20.138771 },
    { lat: 49.306874, lng: 20.138578 },
    { lat: 49.30683, lng: 20.138483 },
    { lat: 49.306703, lng: 20.138329 },
    { lat: 49.306643, lng: 20.138202 },
    { lat: 49.306621, lng: 20.138136 },
    { lat: 49.306524, lng: 20.137961 },
    { lat: 49.306439, lng: 20.137739 },
    { lat: 49.306391, lng: 20.137667 },
    { lat: 49.306243, lng: 20.137661 },
    { lat: 49.306079, lng: 20.137484 },
    { lat: 49.30601, lng: 20.137342 },
    { lat: 49.30599, lng: 20.137193 },
    { lat: 49.305973, lng: 20.137 },
    { lat: 49.30598, lng: 20.136894 },
    { lat: 49.306009, lng: 20.136738 },
    { lat: 49.306025, lng: 20.13662 },
    { lat: 49.306025, lng: 20.136462 },
    { lat: 49.306001, lng: 20.136314 },
    { lat: 49.305941, lng: 20.135916 },
    { lat: 49.305979, lng: 20.135668 },
    { lat: 49.306016, lng: 20.13547 },
    { lat: 49.306209, lng: 20.135028 },
    { lat: 49.306341, lng: 20.134851 },
    { lat: 49.306373, lng: 20.134762 },
    { lat: 49.306372, lng: 20.134646 },
    { lat: 49.306347, lng: 20.134545 },
    { lat: 49.306283, lng: 20.134442 },
    { lat: 49.306233, lng: 20.134417 },
    { lat: 49.306139, lng: 20.134364 },
    { lat: 49.306052, lng: 20.13432 },
    { lat: 49.305921, lng: 20.134205 },
    { lat: 49.305729, lng: 20.134155 },
    { lat: 49.305589, lng: 20.134147 },
    { lat: 49.30546, lng: 20.134176 },
    { lat: 49.305289, lng: 20.134229 },
    { lat: 49.305198, lng: 20.134291 },
    { lat: 49.305106, lng: 20.134365 },
    { lat: 49.30504, lng: 20.134436 },
    { lat: 49.304986, lng: 20.134502 },
    { lat: 49.304818, lng: 20.134618 },
    { lat: 49.304797, lng: 20.13465 },
    { lat: 49.304711, lng: 20.134771 },
    { lat: 49.304684, lng: 20.134828 },
    { lat: 49.304593, lng: 20.135051 },
    { lat: 49.304548, lng: 20.135198 },
    { lat: 49.304528, lng: 20.135307 },
    { lat: 49.304483, lng: 20.135371 },
    { lat: 49.304419, lng: 20.13551 },
    { lat: 49.304336, lng: 20.135622 },
    { lat: 49.304238, lng: 20.135782 },
    { lat: 49.304162, lng: 20.135823 },
    { lat: 49.304082, lng: 20.135887 },
    { lat: 49.303999, lng: 20.135926 },
    { lat: 49.303928, lng: 20.135944 },
    { lat: 49.303776, lng: 20.135943 },
    { lat: 49.303638, lng: 20.135933 },
    { lat: 49.303438, lng: 20.135834 },
    { lat: 49.303314, lng: 20.135755 },
    { lat: 49.303029, lng: 20.135599 },
    { lat: 49.302866, lng: 20.135478 },
    { lat: 49.302781, lng: 20.135434 },
    { lat: 49.302617, lng: 20.135264 },
    { lat: 49.302483, lng: 20.135107 },
    { lat: 49.302426, lng: 20.135014 },
    { lat: 49.302374, lng: 20.1349 },
    { lat: 49.302312, lng: 20.134757 },
    { lat: 49.302232, lng: 20.134574 },
    { lat: 49.302143, lng: 20.134282 },
    { lat: 49.302087, lng: 20.134051 },
    { lat: 49.301979, lng: 20.133828 },
    { lat: 49.301897, lng: 20.133729 },
    { lat: 49.301749, lng: 20.133639 },
    { lat: 49.301592, lng: 20.133602 },
    { lat: 49.301478, lng: 20.13355 },
    { lat: 49.301426, lng: 20.133523 },
    { lat: 49.301328, lng: 20.133467 },
    { lat: 49.301214, lng: 20.133367 },
    { lat: 49.301153, lng: 20.133299 },
    { lat: 49.301061, lng: 20.133193 },
    { lat: 49.300861, lng: 20.133023 },
    { lat: 49.30073, lng: 20.132957 },
    { lat: 49.300577, lng: 20.132882 },
    { lat: 49.300364, lng: 20.132968 },
    { lat: 49.300302, lng: 20.133005 },
    { lat: 49.300202, lng: 20.133067 },
    { lat: 49.300139, lng: 20.133113 },
    { lat: 49.300046, lng: 20.133197 },
    { lat: 49.299926, lng: 20.133293 },
    { lat: 49.299834, lng: 20.133362 },
    { lat: 49.29974, lng: 20.133431 },
    { lat: 49.299681, lng: 20.13346 },
    { lat: 49.299595, lng: 20.133504 },
    { lat: 49.299451, lng: 20.13345 },
    { lat: 49.299345, lng: 20.133406 },
    { lat: 49.299242, lng: 20.13335 },
    { lat: 49.299218, lng: 20.133324 },
    { lat: 49.299091, lng: 20.133141 },
    { lat: 49.299057, lng: 20.13306 },
    { lat: 49.298965, lng: 20.132903 },
    { lat: 49.298891, lng: 20.132779 },
    { lat: 49.298812, lng: 20.13265 },
    { lat: 49.298718, lng: 20.132454 },
    { lat: 49.298513, lng: 20.13212 },
    { lat: 49.298465, lng: 20.132072 },
    { lat: 49.29839, lng: 20.131884 },
    { lat: 49.298369, lng: 20.131809 },
    { lat: 49.298352, lng: 20.131743 },
    { lat: 49.298253, lng: 20.131572 },
    { lat: 49.298121, lng: 20.13134 },
    { lat: 49.298018, lng: 20.131198 },
    { lat: 49.297903, lng: 20.131099 },
    { lat: 49.29783, lng: 20.13104 },
    { lat: 49.297731, lng: 20.13096 },
    { lat: 49.297499, lng: 20.130761 },
    { lat: 49.297368, lng: 20.130684 },
    { lat: 49.297326, lng: 20.130659 },
    { lat: 49.297208, lng: 20.130599 },
    { lat: 49.296992, lng: 20.130502 },
    { lat: 49.296832, lng: 20.130467 },
    { lat: 49.296742, lng: 20.130441 },
    { lat: 49.296591, lng: 20.130415 },
    { lat: 49.296499, lng: 20.130379 },
    { lat: 49.296422, lng: 20.130365 },
    { lat: 49.296374, lng: 20.130339 },
    { lat: 49.296328, lng: 20.130294 },
    { lat: 49.29619, lng: 20.130089 },
    { lat: 49.296, lng: 20.129984 },
    { lat: 49.295884, lng: 20.129993 },
    { lat: 49.2956076, lng: 20.1300489 },
    { lat: 49.295636, lng: 20.130224 },
    { lat: 49.295461, lng: 20.130587 },
    { lat: 49.295404, lng: 20.130777 },
    { lat: 49.295365, lng: 20.130848 },
    { lat: 49.2950589, lng: 20.1306388 },
    { lat: 49.295216, lng: 20.131176 },
    { lat: 49.295119, lng: 20.131344 },
    { lat: 49.294986, lng: 20.131526 },
    { lat: 49.294746, lng: 20.131859 },
    { lat: 49.294607, lng: 20.131976 },
    { lat: 49.294098, lng: 20.1320269 },
    { lat: 49.2941684, lng: 20.1321095 },
    { lat: 49.294104, lng: 20.132113 },
    { lat: 49.293876, lng: 20.132126 },
    { lat: 49.293724, lng: 20.132143 },
    { lat: 49.293587, lng: 20.132124 },
    { lat: 49.293444, lng: 20.132117 },
    { lat: 49.293388, lng: 20.132088 },
    { lat: 49.293158, lng: 20.131999 },
    { lat: 49.2930862, lng: 20.1319628 },
    { lat: 49.293021, lng: 20.13193 },
    { lat: 49.292944, lng: 20.131873 },
    { lat: 49.292868, lng: 20.131785 },
    { lat: 49.292697, lng: 20.131751 },
    { lat: 49.292566, lng: 20.131707 },
    { lat: 49.292383, lng: 20.13164 },
    { lat: 49.292149, lng: 20.131453 },
    { lat: 49.29204, lng: 20.13136 },
    { lat: 49.291957, lng: 20.131253 },
    { lat: 49.291877, lng: 20.131143 },
    { lat: 49.291798, lng: 20.130972 },
    { lat: 49.29165, lng: 20.130681 },
    { lat: 49.291467, lng: 20.130517 },
    { lat: 49.291356, lng: 20.13058 },
    { lat: 49.291201, lng: 20.130659 },
    { lat: 49.291062, lng: 20.130787 },
    { lat: 49.290995, lng: 20.13094 },
    { lat: 49.2908309, lng: 20.1311065 },
    { lat: 49.290703, lng: 20.131178 },
    { lat: 49.2905, lng: 20.131094 },
    { lat: 49.2902873, lng: 20.1309934 },
    { lat: 49.290191, lng: 20.131034 },
    { lat: 49.290011, lng: 20.130936 },
    { lat: 49.289874, lng: 20.130761 },
    { lat: 49.289823, lng: 20.130476 },
    { lat: 49.28979, lng: 20.130296 },
    { lat: 49.289783, lng: 20.130195 },
    { lat: 49.289816, lng: 20.130068 },
    { lat: 49.289831, lng: 20.12995 },
    { lat: 49.289822, lng: 20.129859 },
    { lat: 49.289723, lng: 20.129704 },
    { lat: 49.289428, lng: 20.129357 },
    { lat: 49.289232, lng: 20.129213 },
    { lat: 49.289035, lng: 20.12912 },
    { lat: 49.28866, lng: 20.128933 },
    { lat: 49.288465, lng: 20.128873 },
    { lat: 49.288277, lng: 20.128834 },
    { lat: 49.288101, lng: 20.128767 },
    { lat: 49.287944, lng: 20.128694 },
    { lat: 49.287827, lng: 20.128647 },
    { lat: 49.287652, lng: 20.128576 },
    { lat: 49.287571, lng: 20.128553 },
    { lat: 49.287329, lng: 20.128367 },
    { lat: 49.287157, lng: 20.128187 },
    { lat: 49.287014, lng: 20.128079 },
    { lat: 49.28686, lng: 20.128001 },
    { lat: 49.286609, lng: 20.127879 },
    { lat: 49.286393, lng: 20.127876 },
    { lat: 49.285976, lng: 20.127902 },
    { lat: 49.285803, lng: 20.127925 },
    { lat: 49.285579, lng: 20.127951 },
    { lat: 49.285453, lng: 20.127996 },
    { lat: 49.28532, lng: 20.128049 },
    { lat: 49.285203, lng: 20.128129 },
    { lat: 49.285057, lng: 20.128233 },
    { lat: 49.284651, lng: 20.128457 },
    { lat: 49.284487, lng: 20.128497 },
    { lat: 49.284288, lng: 20.128543 },
    { lat: 49.28406, lng: 20.128565 },
    { lat: 49.283791, lng: 20.128673 },
    { lat: 49.283517, lng: 20.128751 },
    { lat: 49.283259, lng: 20.128904 },
    { lat: 49.283179, lng: 20.128971 },
    { lat: 49.283065, lng: 20.129032 },
    { lat: 49.282935, lng: 20.129061 },
    { lat: 49.282821, lng: 20.12907 },
    { lat: 49.282658, lng: 20.129046 },
    { lat: 49.282496, lng: 20.128987 },
    { lat: 49.282346, lng: 20.128952 },
    { lat: 49.282106, lng: 20.128935 },
    { lat: 49.282024, lng: 20.128879 },
    { lat: 49.281901, lng: 20.12882 },
    { lat: 49.28187, lng: 20.128791 },
    { lat: 49.281737, lng: 20.128622 },
    { lat: 49.281512, lng: 20.12846 },
    { lat: 49.281493, lng: 20.128426 },
    { lat: 49.281343, lng: 20.128336 },
    { lat: 49.281311, lng: 20.128302 },
    { lat: 49.28114, lng: 20.128251 },
    { lat: 49.280961, lng: 20.128237 },
    { lat: 49.280806, lng: 20.128263 },
    { lat: 49.280536, lng: 20.128383 },
    { lat: 49.280336, lng: 20.128429 },
    { lat: 49.28016, lng: 20.128373 },
    { lat: 49.2801431, lng: 20.128367 },
    { lat: 49.280112, lng: 20.128356 },
    { lat: 49.280068, lng: 20.12834 },
    { lat: 49.279962, lng: 20.128296 },
    { lat: 49.279816, lng: 20.128113 },
    { lat: 49.279772, lng: 20.128053 },
    { lat: 49.2797159, lng: 20.1280176 },
    { lat: 49.279512, lng: 20.127886 },
    { lat: 49.279275, lng: 20.127756 },
    { lat: 49.279101, lng: 20.127675 },
    { lat: 49.278945, lng: 20.127699 },
    { lat: 49.278926, lng: 20.127713 },
    { lat: 49.278788, lng: 20.127744 },
    { lat: 49.27873, lng: 20.127779 },
    { lat: 49.278537, lng: 20.127903 },
    { lat: 49.278258, lng: 20.128118 },
    { lat: 49.278094, lng: 20.128217 },
    { lat: 49.277926, lng: 20.128268 },
    { lat: 49.2776949, lng: 20.1284365 },
    { lat: 49.277583, lng: 20.128508 },
    { lat: 49.277534, lng: 20.128523 },
    { lat: 49.277327, lng: 20.128615 },
    { lat: 49.277241, lng: 20.128645 },
    { lat: 49.277071, lng: 20.128666 },
    { lat: 49.276814, lng: 20.128594 },
    { lat: 49.2766709, lng: 20.1285355 },
    { lat: 49.276635, lng: 20.128508 },
    { lat: 49.27657, lng: 20.128503 },
    { lat: 49.276485, lng: 20.12849 },
    { lat: 49.276411, lng: 20.12842 },
    { lat: 49.276363, lng: 20.128262 },
    { lat: 49.276346, lng: 20.127737 },
    { lat: 49.276319, lng: 20.127467 },
    { lat: 49.276277, lng: 20.127398 },
    { lat: 49.276233, lng: 20.127253 },
    { lat: 49.276185, lng: 20.127125 },
    { lat: 49.276133, lng: 20.127071 },
    { lat: 49.2759833, lng: 20.126945 },
    { lat: 49.275918, lng: 20.126946 },
    { lat: 49.275714, lng: 20.126861 },
    { lat: 49.275576, lng: 20.126857 },
    { lat: 49.275408, lng: 20.126921 },
    { lat: 49.275257, lng: 20.126984 },
    { lat: 49.275106, lng: 20.127019 },
    { lat: 49.274895, lng: 20.127003 },
    { lat: 49.274756, lng: 20.126924 },
    { lat: 49.27464, lng: 20.126843 },
    { lat: 49.274486, lng: 20.126725 },
    { lat: 49.274311, lng: 20.126575 },
    { lat: 49.274212, lng: 20.126509 },
    { lat: 49.274104, lng: 20.126363 },
    { lat: 49.273903, lng: 20.126134 },
    { lat: 49.27367, lng: 20.125983 },
    { lat: 49.273475, lng: 20.125824 },
    { lat: 49.273277, lng: 20.125706 },
    { lat: 49.273142, lng: 20.125649 },
    { lat: 49.272958, lng: 20.125563 },
    { lat: 49.272605, lng: 20.125333 },
    { lat: 49.272458, lng: 20.125229 },
    { lat: 49.2722789, lng: 20.1251475 },
    { lat: 49.272213, lng: 20.125137 },
    { lat: 49.271844, lng: 20.125083 },
    { lat: 49.271603, lng: 20.125077 },
    { lat: 49.271453, lng: 20.125084 },
    { lat: 49.271276, lng: 20.125098 },
    { lat: 49.271081, lng: 20.125158 },
    { lat: 49.270926, lng: 20.125144 },
    { lat: 49.2708851, lng: 20.1250957 },
    { lat: 49.270822, lng: 20.125021 },
    { lat: 49.270733, lng: 20.124868 },
    { lat: 49.270506, lng: 20.124482 },
    { lat: 49.270417, lng: 20.124319 },
    { lat: 49.270289, lng: 20.124098 },
    { lat: 49.270221, lng: 20.123875 },
    { lat: 49.270199, lng: 20.12373 },
    { lat: 49.270209, lng: 20.123559 },
    { lat: 49.270188, lng: 20.12333 },
    { lat: 49.270098, lng: 20.12318 },
    { lat: 49.2699769, lng: 20.1230325 },
    { lat: 49.269794, lng: 20.12295 },
    { lat: 49.269631, lng: 20.122867 },
    { lat: 49.269477, lng: 20.122792 },
    { lat: 49.269297, lng: 20.122741 },
    { lat: 49.269253, lng: 20.122738 },
    { lat: 49.269064, lng: 20.122694 },
    { lat: 49.268943, lng: 20.122714 },
    { lat: 49.268918, lng: 20.122705 },
    { lat: 49.2686949, lng: 20.1226685 },
    { lat: 49.268443, lng: 20.122533 },
    { lat: 49.26823, lng: 20.12234 },
    { lat: 49.268094, lng: 20.122223 },
    { lat: 49.2679651, lng: 20.1220576 },
    { lat: 49.267924, lng: 20.122004 },
    { lat: 49.267893, lng: 20.121936 },
    { lat: 49.267753, lng: 20.121643 },
    { lat: 49.267615, lng: 20.121275 },
    { lat: 49.267487, lng: 20.121025 },
    { lat: 49.267345, lng: 20.120872 },
    { lat: 49.267275, lng: 20.120771 },
    { lat: 49.2671919, lng: 20.1206175 },
    { lat: 49.267105, lng: 20.120431 },
    { lat: 49.267037, lng: 20.120266 },
    { lat: 49.266957, lng: 20.120038 },
    { lat: 49.266887, lng: 20.119769 },
    { lat: 49.266829, lng: 20.119546 },
    { lat: 49.266722, lng: 20.119201 },
    { lat: 49.266631, lng: 20.118848 },
    { lat: 49.266568, lng: 20.1186 },
    { lat: 49.266445, lng: 20.118282 },
    { lat: 49.266364, lng: 20.118095 },
    { lat: 49.266356, lng: 20.118054 },
    { lat: 49.2662787, lng: 20.1180175 },
    { lat: 49.266239, lng: 20.117861 },
    { lat: 49.266167, lng: 20.117725 },
    { lat: 49.266001, lng: 20.117492 },
    { lat: 49.265949, lng: 20.117401 },
    { lat: 49.265812, lng: 20.117216 },
    { lat: 49.265773, lng: 20.117156 },
    { lat: 49.2656119, lng: 20.1170595 },
    { lat: 49.265223, lng: 20.117123 },
    { lat: 49.265083, lng: 20.117159 },
    { lat: 49.264882, lng: 20.117175 },
    { lat: 49.2646669, lng: 20.1171185 },
    { lat: 49.26451, lng: 20.117062 },
    { lat: 49.264342, lng: 20.116976 },
    { lat: 49.264198, lng: 20.116853 },
    { lat: 49.264049, lng: 20.116647 },
    { lat: 49.263948, lng: 20.116519 },
    { lat: 49.263923, lng: 20.1164698 },
    { lat: 49.263865, lng: 20.116356 },
    { lat: 49.263799, lng: 20.116223 },
    { lat: 49.263691, lng: 20.115884 },
    { lat: 49.263632, lng: 20.115538 },
    { lat: 49.263593, lng: 20.115315 },
    { lat: 49.263558, lng: 20.115044 },
    { lat: 49.2635096, lng: 20.1147949 },
    { lat: 49.263474, lng: 20.114616 },
    { lat: 49.2634369, lng: 20.1143995 },
    { lat: 49.263415, lng: 20.114331 },
    { lat: 49.263404, lng: 20.114181 },
    { lat: 49.26334, lng: 20.113981 },
    { lat: 49.263319, lng: 20.11393 },
    { lat: 49.263287, lng: 20.113914 },
    { lat: 49.263138, lng: 20.113855 },
    { lat: 49.263048, lng: 20.113843 },
    { lat: 49.262969, lng: 20.113836 },
    { lat: 49.262799, lng: 20.113882 },
    { lat: 49.262541, lng: 20.113873 },
    { lat: 49.26245, lng: 20.113789 },
    { lat: 49.262376, lng: 20.113691 },
    { lat: 49.2622769, lng: 20.1133915 },
    { lat: 49.262198, lng: 20.113162 },
    { lat: 49.262078, lng: 20.112961 },
    { lat: 49.26198, lng: 20.112825 },
    { lat: 49.2617949, lng: 20.1127035 },
    { lat: 49.261671, lng: 20.112665 },
    { lat: 49.26152, lng: 20.112703 },
    { lat: 49.261425, lng: 20.112713 },
    { lat: 49.2612649, lng: 20.1127505 },
    { lat: 49.261167, lng: 20.112679 },
    { lat: 49.261071, lng: 20.112601 },
    { lat: 49.261055, lng: 20.112579 },
    { lat: 49.260967, lng: 20.112536 },
    { lat: 49.260892, lng: 20.112524 },
    { lat: 49.260738, lng: 20.112507 },
    { lat: 49.260644, lng: 20.112493 },
    { lat: 49.26057, lng: 20.112474 },
    { lat: 49.260264, lng: 20.112441 },
    { lat: 49.260107, lng: 20.11235 },
    { lat: 49.25998, lng: 20.112195 },
    { lat: 49.259831, lng: 20.112046 },
    { lat: 49.259696, lng: 20.111915 },
    { lat: 49.259362, lng: 20.111845 },
    { lat: 49.259187, lng: 20.111782 },
    { lat: 49.259016, lng: 20.111693 },
    { lat: 49.25886, lng: 20.111591 },
    { lat: 49.258703, lng: 20.111476 },
    { lat: 49.258568, lng: 20.111342 },
    { lat: 49.258488, lng: 20.111253 },
    { lat: 49.258343, lng: 20.111067 },
    { lat: 49.258244, lng: 20.110938 },
    { lat: 49.258103, lng: 20.110792 },
    { lat: 49.257952, lng: 20.110576 },
    { lat: 49.257851, lng: 20.110439 },
    { lat: 49.257722, lng: 20.110265 },
    { lat: 49.257442, lng: 20.109859 },
    { lat: 49.257319, lng: 20.109668 },
    { lat: 49.257199, lng: 20.109481 },
    { lat: 49.257069, lng: 20.109308 },
    { lat: 49.256931, lng: 20.109017 },
    { lat: 49.256827, lng: 20.1088 },
    { lat: 49.25674, lng: 20.108623 },
    { lat: 49.2565495, lng: 20.1083388 },
    { lat: 49.2563409, lng: 20.1080275 },
    { lat: 49.256291, lng: 20.107686 },
    { lat: 49.25617, lng: 20.107386 },
    { lat: 49.256101, lng: 20.107218 },
    { lat: 49.256025, lng: 20.107162 },
    { lat: 49.255973, lng: 20.107053 },
    { lat: 49.25592, lng: 20.106959 },
    { lat: 49.2558529, lng: 20.1067865 },
    { lat: 49.255618, lng: 20.10658 },
    { lat: 49.2555109, lng: 20.1065315 },
    { lat: 49.255352, lng: 20.10642 },
    { lat: 49.255194, lng: 20.106263 },
    { lat: 49.25496, lng: 20.106026 },
    { lat: 49.254795, lng: 20.105801 },
    { lat: 49.254668, lng: 20.105631 },
    { lat: 49.2545629, lng: 20.1054975 },
    { lat: 49.254432, lng: 20.105323 },
    { lat: 49.254316, lng: 20.105107 },
    { lat: 49.254183, lng: 20.104876 },
    { lat: 49.254075, lng: 20.104651 },
    { lat: 49.2539889, lng: 20.1044265 },
    { lat: 49.253906, lng: 20.104163 },
    { lat: 49.253819, lng: 20.103941 },
    { lat: 49.25374, lng: 20.103722 },
    { lat: 49.253628, lng: 20.103424 },
    { lat: 49.253477, lng: 20.103058 },
    { lat: 49.253315, lng: 20.102734 },
    { lat: 49.2531229, lng: 20.1024235 },
    { lat: 49.252887, lng: 20.102194 },
    { lat: 49.25256, lng: 20.101965 },
    { lat: 49.252387, lng: 20.101878 },
    { lat: 49.2522059, lng: 20.1017975 },
    { lat: 49.252007, lng: 20.101733 },
    { lat: 49.251867, lng: 20.101652 },
    { lat: 49.251637, lng: 20.101539 },
    { lat: 49.251489, lng: 20.10147 },
    { lat: 49.251292, lng: 20.101475 },
    { lat: 49.251114, lng: 20.101377 },
    { lat: 49.250781, lng: 20.101208 },
    { lat: 49.250709, lng: 20.101145 },
    { lat: 49.250544, lng: 20.10103 },
    { lat: 49.25048, lng: 20.10097 },
    { lat: 49.25034, lng: 20.100887 },
    { lat: 49.250088, lng: 20.100767 },
    { lat: 49.249948, lng: 20.100815 },
    { lat: 49.249748, lng: 20.100811 },
    { lat: 49.249544, lng: 20.100799 },
    { lat: 49.249333, lng: 20.100835 },
    { lat: 49.249101, lng: 20.100882 },
    { lat: 49.248889, lng: 20.10089 },
    { lat: 49.248666, lng: 20.10096 },
    { lat: 49.248503, lng: 20.101078 },
    { lat: 49.248295, lng: 20.101225 },
    { lat: 49.248066, lng: 20.101363 },
    { lat: 49.247834, lng: 20.101486 },
    { lat: 49.247617, lng: 20.10158 },
    { lat: 49.247426, lng: 20.101638 },
    { lat: 49.247196, lng: 20.101694 },
    { lat: 49.246874, lng: 20.101812 },
    { lat: 49.246675, lng: 20.101811 },
    { lat: 49.246511, lng: 20.101727 },
    { lat: 49.246349, lng: 20.101571 },
    { lat: 49.246193, lng: 20.101375 },
    { lat: 49.246091, lng: 20.101236 },
    { lat: 49.246017, lng: 20.101161 },
    { lat: 49.24588, lng: 20.101053 },
    { lat: 49.245705, lng: 20.100932 },
    { lat: 49.2455979, lng: 20.1008865 },
    { lat: 49.245434, lng: 20.100913 },
    { lat: 49.245262, lng: 20.100943 },
    { lat: 49.245173, lng: 20.100981 },
    { lat: 49.244988, lng: 20.10101 },
    { lat: 49.244585, lng: 20.101028 },
    { lat: 49.244428, lng: 20.10095 },
    { lat: 49.244248, lng: 20.100897 },
    { lat: 49.244101, lng: 20.100851 },
    { lat: 49.243903, lng: 20.100822 },
    { lat: 49.243713, lng: 20.100797 },
    { lat: 49.2434669, lng: 20.1008055 },
    { lat: 49.243313, lng: 20.100836 },
    { lat: 49.242956, lng: 20.100877 },
    { lat: 49.242769, lng: 20.100899 },
    { lat: 49.242534, lng: 20.100923 },
    { lat: 49.242348, lng: 20.100942 },
    { lat: 49.242182, lng: 20.100973 },
    { lat: 49.2418399, lng: 20.1010235 },
    { lat: 49.241466, lng: 20.100958 },
    { lat: 49.2412669, lng: 20.1008695 },
    { lat: 49.241141, lng: 20.100766 },
    { lat: 49.240903, lng: 20.100607 },
    { lat: 49.240663, lng: 20.100374 },
    { lat: 49.24049, lng: 20.100175 },
    { lat: 49.240372, lng: 20.100015 },
    { lat: 49.240172, lng: 20.099788 },
    { lat: 49.240044, lng: 20.099588 },
    { lat: 49.239888, lng: 20.099315 },
    { lat: 49.239783, lng: 20.099155 },
    { lat: 49.239639, lng: 20.099011 },
    { lat: 49.239544, lng: 20.098989 },
    { lat: 49.23939, lng: 20.098887 },
    { lat: 49.239279, lng: 20.098729 },
    { lat: 49.239249, lng: 20.098723 },
    { lat: 49.23915, lng: 20.098775 },
    { lat: 49.2390469, lng: 20.0988125 },
    { lat: 49.238712, lng: 20.09875 },
    { lat: 49.2386929, lng: 20.0987322 },
    { lat: 49.238655, lng: 20.098695 },
    { lat: 49.23857, lng: 20.098618 },
    { lat: 49.2384069, lng: 20.0984045 },
    { lat: 49.238243, lng: 20.098243 },
    { lat: 49.2380739, lng: 20.0981395 },
    { lat: 49.237975, lng: 20.098143 },
    { lat: 49.2378409, lng: 20.0981675 },
    { lat: 49.2376159, lng: 20.0983695 },
    { lat: 49.2374139, lng: 20.0984315 },
    { lat: 49.2373525, lng: 20.0984004 },
    { lat: 49.237333, lng: 20.09839 },
    { lat: 49.237172, lng: 20.09829 },
    { lat: 49.236969, lng: 20.098189 },
    { lat: 49.2369316, lng: 20.0981606 },
    { lat: 49.2367241, lng: 20.0980001 },
    { lat: 49.236686, lng: 20.09797 },
    { lat: 49.2365945, lng: 20.0979686 },
    { lat: 49.236543, lng: 20.097967 },
    { lat: 49.236327, lng: 20.098091 },
    { lat: 49.236222, lng: 20.098163 },
    { lat: 49.236128, lng: 20.09823 },
    { lat: 49.2359959, lng: 20.0983095 },
    { lat: 49.235813, lng: 20.098396 },
    { lat: 49.235674, lng: 20.098428 },
    { lat: 49.2355765, lng: 20.0984263 },
    { lat: 49.235499, lng: 20.098424 },
    { lat: 49.235249, lng: 20.098324 },
    { lat: 49.2351512, lng: 20.0982497 },
    { lat: 49.235095, lng: 20.098206 },
    { lat: 49.234892, lng: 20.098039 },
    { lat: 49.234834, lng: 20.09796 },
    { lat: 49.2345519, lng: 20.0978335 },
    { lat: 49.234388, lng: 20.097847 },
    { lat: 49.234272, lng: 20.097881 },
    { lat: 49.23411, lng: 20.097983 },
    { lat: 49.233937, lng: 20.098145 },
    { lat: 49.2337007, lng: 20.0983951 },
    { lat: 49.233654, lng: 20.098444 },
    { lat: 49.23331, lng: 20.098706 },
    { lat: 49.233068, lng: 20.098851 },
    { lat: 49.232937, lng: 20.098908 },
    { lat: 49.232754, lng: 20.099036 },
    { lat: 49.232596, lng: 20.099116 },
    { lat: 49.232431, lng: 20.099251 },
    { lat: 49.232269, lng: 20.099391 },
    { lat: 49.23209, lng: 20.09956 },
    { lat: 49.231947, lng: 20.099634 },
    { lat: 49.2318109, lng: 20.0996915 },
    { lat: 49.23166, lng: 20.099725 },
    { lat: 49.231495, lng: 20.099745 },
    { lat: 49.2314309, lng: 20.0997295 },
    { lat: 49.231296, lng: 20.099706 },
    { lat: 49.2312, lng: 20.099649 },
    { lat: 49.231059, lng: 20.099593 },
    { lat: 49.230811, lng: 20.099494 },
    { lat: 49.2306999, lng: 20.0994655 },
    { lat: 49.230363, lng: 20.099348 },
    { lat: 49.230228, lng: 20.099335 },
    { lat: 49.23001, lng: 20.099383 },
    { lat: 49.229843, lng: 20.099405 },
    { lat: 49.229623, lng: 20.099479 },
    { lat: 49.229576, lng: 20.099561 },
    { lat: 49.229511, lng: 20.099614 },
    { lat: 49.22938, lng: 20.099672 },
    { lat: 49.229272, lng: 20.099748 },
    { lat: 49.22913, lng: 20.099826 },
    { lat: 49.2289619, lng: 20.0998625 },
    { lat: 49.228841, lng: 20.099825 },
    { lat: 49.228717, lng: 20.099805 },
    { lat: 49.228584, lng: 20.099764 },
    { lat: 49.2284259, lng: 20.0997655 },
    { lat: 49.228266, lng: 20.099799 },
    { lat: 49.228126, lng: 20.099824 },
    { lat: 49.228065, lng: 20.099839 },
    { lat: 49.227995, lng: 20.099811 },
    { lat: 49.227868, lng: 20.099826 },
    { lat: 49.227769, lng: 20.099863 },
    { lat: 49.2276309, lng: 20.0999245 },
    { lat: 49.227559, lng: 20.100018 },
    { lat: 49.227397, lng: 20.100324 },
    { lat: 49.227296, lng: 20.100525 },
    { lat: 49.227166, lng: 20.100858 },
    { lat: 49.2271119, lng: 20.1010235 },
    { lat: 49.226994, lng: 20.101195 },
    { lat: 49.226703, lng: 20.101416 },
    { lat: 49.226562, lng: 20.10154 },
    { lat: 49.226415, lng: 20.101723 },
    { lat: 49.2263541, lng: 20.1018166 },
    { lat: 49.226238, lng: 20.101886 },
    { lat: 49.225967, lng: 20.102032 },
    { lat: 49.225799, lng: 20.102128 },
    { lat: 49.225661, lng: 20.102177 },
    { lat: 49.22551, lng: 20.102195 },
    { lat: 49.225285, lng: 20.102198 },
    { lat: 49.22514, lng: 20.102191 },
    { lat: 49.224958, lng: 20.102094 },
    { lat: 49.224809, lng: 20.101983 },
    { lat: 49.224659, lng: 20.101899 },
    { lat: 49.224509, lng: 20.101788 },
    { lat: 49.224275, lng: 20.101724 },
    { lat: 49.224111, lng: 20.101601 },
    { lat: 49.223919, lng: 20.101539 },
    { lat: 49.22373, lng: 20.101521 },
    { lat: 49.223504, lng: 20.101487 },
    { lat: 49.223428, lng: 20.101443 },
    { lat: 49.2233089, lng: 20.1014405 },
    { lat: 49.223187, lng: 20.101432 },
    { lat: 49.223138, lng: 20.101483 },
    { lat: 49.222866, lng: 20.101641 },
    { lat: 49.222715, lng: 20.101623 },
    { lat: 49.2226589, lng: 20.1016245 },
    { lat: 49.22264, lng: 20.10154 },
    { lat: 49.222599, lng: 20.10137 },
    { lat: 49.222566, lng: 20.101267 },
    { lat: 49.222543, lng: 20.101025 },
    { lat: 49.222536, lng: 20.100921 },
    { lat: 49.2223996, lng: 20.1004802 },
    { lat: 49.222376, lng: 20.100403 },
    { lat: 49.222258, lng: 20.100313 },
    { lat: 49.222135, lng: 20.100229 },
    { lat: 49.221998, lng: 20.100138 },
    { lat: 49.221904, lng: 20.100104 },
    { lat: 49.221831, lng: 20.100034 },
    { lat: 49.221761, lng: 20.100049 },
    { lat: 49.221694, lng: 20.100065 },
    { lat: 49.221656, lng: 20.100008 },
    { lat: 49.221634, lng: 20.099889 },
    { lat: 49.22154, lng: 20.09979 },
    { lat: 49.221454, lng: 20.099723 },
    { lat: 49.221363, lng: 20.099716 },
    { lat: 49.2213, lng: 20.099676 },
    { lat: 49.221236, lng: 20.099617 },
    { lat: 49.221208, lng: 20.099479 },
    { lat: 49.221211, lng: 20.099303 },
    { lat: 49.221147, lng: 20.099176 },
    { lat: 49.221091, lng: 20.099049 },
    { lat: 49.221044, lng: 20.098945 },
    { lat: 49.220981, lng: 20.098727 },
    { lat: 49.220923, lng: 20.098642 },
    { lat: 49.220818, lng: 20.098598 },
    { lat: 49.220596, lng: 20.098673 },
    { lat: 49.220467, lng: 20.098605 },
    { lat: 49.220393, lng: 20.098543 },
    { lat: 49.220321, lng: 20.09843 },
    { lat: 49.220267, lng: 20.09836 },
    { lat: 49.220195, lng: 20.098252 },
    { lat: 49.220198, lng: 20.098113 },
    { lat: 49.220222, lng: 20.098021 },
    { lat: 49.220225, lng: 20.097967 },
    { lat: 49.220249, lng: 20.097856 },
    { lat: 49.22026, lng: 20.097747 },
    { lat: 49.220282, lng: 20.097668 },
    { lat: 49.220328, lng: 20.097577 },
    { lat: 49.220357, lng: 20.097532 },
    { lat: 49.220383, lng: 20.097535 },
    { lat: 49.220419, lng: 20.097452 },
    { lat: 49.2204269, lng: 20.0973105 },
    { lat: 49.220315, lng: 20.097068 },
    { lat: 49.22029, lng: 20.096947 },
    { lat: 49.220226, lng: 20.096771 },
    { lat: 49.220168, lng: 20.09668 },
    { lat: 49.220091, lng: 20.09655 },
    { lat: 49.22008, lng: 20.096407 },
    { lat: 49.220084, lng: 20.096341 },
    { lat: 49.2200329, lng: 20.0961645 },
    { lat: 49.219917, lng: 20.096238 },
    { lat: 49.219188, lng: 20.095592 },
    { lat: 49.218296, lng: 20.094998 },
    { lat: 49.218229, lng: 20.094954 },
    { lat: 49.217757, lng: 20.094635 },
    { lat: 49.216486, lng: 20.093772 },
    { lat: 49.215321, lng: 20.092991 },
    { lat: 49.213912, lng: 20.092092 },
    { lat: 49.213488, lng: 20.091803 },
    { lat: 49.212922, lng: 20.091502 },
    { lat: 49.21245, lng: 20.091141 },
    { lat: 49.211872, lng: 20.090813 },
    { lat: 49.2117237, lng: 20.0906829 },
    { lat: 49.211435, lng: 20.090632 },
    { lat: 49.210965, lng: 20.090124 },
    { lat: 49.2096033, lng: 20.0888787 },
    { lat: 49.2092752, lng: 20.0885025 },
    { lat: 49.208447, lng: 20.0879685 },
    { lat: 49.2076078, lng: 20.0871772 },
    { lat: 49.2058541, lng: 20.0869744 },
    { lat: 49.204024, lng: 20.0862581 },
    { lat: 49.2033235, lng: 20.08647 },
    { lat: 49.202711, lng: 20.086863 },
    { lat: 49.2012303, lng: 20.0867803 },
    { lat: 49.2007198, lng: 20.0871237 },
    { lat: 49.1997665, lng: 20.0870779 },
    { lat: 49.1995427, lng: 20.087095 },
    { lat: 49.1993859, lng: 20.0871947 },
    { lat: 49.1989555, lng: 20.0867888 },
    { lat: 49.1987995, lng: 20.086843 },
    { lat: 49.1985643, lng: 20.0865253 },
    { lat: 49.1983958, lng: 20.086374 },
    { lat: 49.198137, lng: 20.086301 },
    { lat: 49.198065, lng: 20.086316 },
    { lat: 49.197883, lng: 20.086243 },
    { lat: 49.197766, lng: 20.086139 },
    { lat: 49.197664, lng: 20.086205 },
    { lat: 49.19752, lng: 20.086146 },
    { lat: 49.197391, lng: 20.086208 },
    { lat: 49.197321, lng: 20.086206 },
    { lat: 49.1972829, lng: 20.0861795 },
    { lat: 49.197169, lng: 20.085958 },
    { lat: 49.197065, lng: 20.085933 },
    { lat: 49.196999, lng: 20.085789 },
    { lat: 49.196896, lng: 20.08565 },
    { lat: 49.196851, lng: 20.085617 },
    { lat: 49.196564, lng: 20.085266 },
    { lat: 49.196427, lng: 20.085079 },
    { lat: 49.196514, lng: 20.084916 },
    { lat: 49.196533, lng: 20.084774 },
    { lat: 49.196393, lng: 20.084652 },
    { lat: 49.196185, lng: 20.084375 },
    { lat: 49.195856, lng: 20.084397 },
    { lat: 49.195574, lng: 20.084336 },
    { lat: 49.195433, lng: 20.084498 },
    { lat: 49.1951339, lng: 20.0843865 },
    { lat: 49.194885, lng: 20.08435 },
    { lat: 49.194783, lng: 20.084602 },
    { lat: 49.194673, lng: 20.08468 },
    { lat: 49.194525, lng: 20.084724 },
    { lat: 49.194125, lng: 20.084676 },
    { lat: 49.193673, lng: 20.084667 },
    { lat: 49.193278, lng: 20.084576 },
    { lat: 49.192932, lng: 20.08455 },
    { lat: 49.19281, lng: 20.084786 },
    { lat: 49.192437, lng: 20.084915 },
    { lat: 49.192155, lng: 20.084952 },
    { lat: 49.192041, lng: 20.085137 },
    { lat: 49.191977, lng: 20.085364 },
    { lat: 49.191787, lng: 20.085689 },
    { lat: 49.1916329, lng: 20.0857095 },
    { lat: 49.191519, lng: 20.085714 },
    { lat: 49.191443, lng: 20.085789 },
    { lat: 49.191415, lng: 20.086006 },
    { lat: 49.191137, lng: 20.085903 },
    { lat: 49.190881, lng: 20.085903 },
    { lat: 49.190647, lng: 20.08591 },
    { lat: 49.190441, lng: 20.08587 },
    { lat: 49.189906, lng: 20.086112 },
    { lat: 49.189677, lng: 20.086146 },
    { lat: 49.189561, lng: 20.086149 },
    { lat: 49.189192, lng: 20.08587 },
    { lat: 49.189222, lng: 20.086187 },
    { lat: 49.189132, lng: 20.086539 },
    { lat: 49.188957, lng: 20.08658 },
    { lat: 49.188914, lng: 20.086819 },
    { lat: 49.188654, lng: 20.086706 },
    { lat: 49.188568, lng: 20.086798 },
    { lat: 49.188415, lng: 20.086988 },
    { lat: 49.188122, lng: 20.087053 },
    { lat: 49.1879917, lng: 20.0871435 },
    { lat: 49.1879878, lng: 20.0873414 },
    { lat: 49.187842, lng: 20.087472 },
    { lat: 49.1877659, lng: 20.0877315 },
    { lat: 49.187803, lng: 20.088157 },
    { lat: 49.187745, lng: 20.088579 },
    { lat: 49.187788, lng: 20.088762 },
    { lat: 49.187826, lng: 20.08903 },
    { lat: 49.187848, lng: 20.089253 },
    { lat: 49.1877404, lng: 20.0891546 },
    { lat: 49.1876649, lng: 20.0889425 },
    { lat: 49.187529, lng: 20.088732 },
    { lat: 49.187393, lng: 20.088497 },
    { lat: 49.187336, lng: 20.088416 },
    { lat: 49.1871675, lng: 20.0883744 },
    { lat: 49.186991, lng: 20.088187 },
    { lat: 49.186827, lng: 20.088065 },
    { lat: 49.186728, lng: 20.088039 },
    { lat: 49.186694, lng: 20.087977 },
    { lat: 49.186604, lng: 20.087848 },
    { lat: 49.18655, lng: 20.087673 },
    { lat: 49.1865868, lng: 20.0875238 },
    { lat: 49.186463, lng: 20.087321 },
    { lat: 49.186347, lng: 20.087173 },
    { lat: 49.186178, lng: 20.087126 },
    { lat: 49.1859799, lng: 20.0871375 },
    { lat: 49.1858149, lng: 20.0872064 },
    { lat: 49.185689, lng: 20.087212 },
    { lat: 49.185606, lng: 20.087079 },
    { lat: 49.18555, lng: 20.08718 },
    { lat: 49.1854367, lng: 20.0872259 },
    { lat: 49.185352, lng: 20.0871985 },
    { lat: 49.185287, lng: 20.08716 },
    { lat: 49.1852079, lng: 20.0871052 },
    { lat: 49.1851193, lng: 20.0870336 },
    { lat: 49.1850244, lng: 20.0869714 },
    { lat: 49.184987, lng: 20.086927 },
    { lat: 49.184843, lng: 20.086871 },
    { lat: 49.184822, lng: 20.086855 },
    { lat: 49.184767, lng: 20.086775 },
    { lat: 49.1846885, lng: 20.086826 },
    { lat: 49.184584, lng: 20.08682 },
    { lat: 49.1845672, lng: 20.0869619 },
    { lat: 49.1844659, lng: 20.0868925 },
    { lat: 49.18444, lng: 20.086883 },
    { lat: 49.184366, lng: 20.086792 },
    { lat: 49.184345, lng: 20.086882 },
    { lat: 49.1842938, lng: 20.0869543 },
    { lat: 49.184258, lng: 20.087005 },
    { lat: 49.1842037, lng: 20.0871005 },
    { lat: 49.1840795, lng: 20.0871519 },
    { lat: 49.184009, lng: 20.087224 },
    { lat: 49.183963, lng: 20.087328 },
    { lat: 49.1839009, lng: 20.0873832 },
    { lat: 49.1838, lng: 20.087495 },
    { lat: 49.183727, lng: 20.087609 },
    { lat: 49.183692, lng: 20.087732 },
    { lat: 49.183637, lng: 20.087767 },
    { lat: 49.183598, lng: 20.087748 },
    { lat: 49.183572, lng: 20.0878 },
    { lat: 49.183506, lng: 20.087942 },
    { lat: 49.183403, lng: 20.087995 },
    { lat: 49.183351, lng: 20.08802 },
    { lat: 49.183245, lng: 20.088154 },
    { lat: 49.1831849, lng: 20.0882035 },
    { lat: 49.183124, lng: 20.08813 },
    { lat: 49.183051, lng: 20.088113 },
    { lat: 49.182934, lng: 20.088072 },
    { lat: 49.182862, lng: 20.088065 },
    { lat: 49.182805, lng: 20.088114 },
    { lat: 49.1827509, lng: 20.088068 },
    { lat: 49.182567, lng: 20.087983 },
    { lat: 49.1825265, lng: 20.0880185 },
    { lat: 49.182492, lng: 20.088104 },
    { lat: 49.182391, lng: 20.088101 },
    { lat: 49.18231, lng: 20.088122 },
    { lat: 49.182206, lng: 20.088119 },
    { lat: 49.182125, lng: 20.088057 },
    { lat: 49.182064, lng: 20.088006 },
    { lat: 49.1819084, lng: 20.0880048 },
    { lat: 49.181706, lng: 20.087941 },
    { lat: 49.181612, lng: 20.08797 },
    { lat: 49.181521, lng: 20.08794 },
    { lat: 49.181486, lng: 20.088039 },
    { lat: 49.181362, lng: 20.087994 },
    { lat: 49.181377, lng: 20.08805 },
    { lat: 49.181336, lng: 20.088078 },
    { lat: 49.1812666, lng: 20.08818 },
    { lat: 49.181205, lng: 20.088222 },
    { lat: 49.181136, lng: 20.088263 },
    { lat: 49.1811371, lng: 20.0883437 },
    { lat: 49.1810917, lng: 20.0883509 },
    { lat: 49.181045, lng: 20.0884711 },
    { lat: 49.180949, lng: 20.088354 },
    { lat: 49.1808733, lng: 20.0882565 },
    { lat: 49.180715, lng: 20.088279 },
    { lat: 49.180671, lng: 20.088257 },
    { lat: 49.180609, lng: 20.088207 },
    { lat: 49.180533, lng: 20.088272 },
    { lat: 49.180471, lng: 20.088166 },
    { lat: 49.180442, lng: 20.088213 },
    { lat: 49.180365, lng: 20.0881486 },
    { lat: 49.180262, lng: 20.088052 },
    { lat: 49.180242, lng: 20.088138 },
    { lat: 49.180193, lng: 20.0881502 },
    { lat: 49.18014, lng: 20.08818 },
    { lat: 49.180072, lng: 20.088166 },
    { lat: 49.180008, lng: 20.088212 },
    { lat: 49.1799, lng: 20.088148 },
    { lat: 49.179659, lng: 20.088154 },
    { lat: 49.17962, lng: 20.08813 },
    { lat: 49.1795743, lng: 20.0881093 },
    { lat: 49.179313, lng: 20.0884942 },
    { lat: 49.1790129, lng: 20.0894492 },
    { lat: 49.1740238, lng: 20.0931884 },
    { lat: 49.1730658, lng: 20.0935582 },
    { lat: 49.1728343, lng: 20.0940397 },
    { lat: 49.172646, lng: 20.0941453 },
    { lat: 49.1722057, lng: 20.0978671 },
    { lat: 49.1714278, lng: 20.1018164 },
    { lat: 49.1701491, lng: 20.1077768 },
    { lat: 49.1696481, lng: 20.1095371 },
    { lat: 49.1693062, lng: 20.1103553 },
    { lat: 49.1687533, lng: 20.112024 },
    { lat: 49.1681834, lng: 20.1131079 },
    { lat: 49.1670586, lng: 20.116738 },
    { lat: 49.1653996, lng: 20.1248772 },
    { lat: 49.1659969, lng: 20.126673 },
    { lat: 49.1672595, lng: 20.1312894 },
    { lat: 49.1693604, lng: 20.1298229 },
    { lat: 49.1710976, lng: 20.129727 },
    { lat: 49.1723263, lng: 20.1306498 },
    { lat: 49.1739418, lng: 20.1347749 },
    { lat: 49.1736449, lng: 20.1369965 },
    { lat: 49.1732534, lng: 20.1384623 },
    { lat: 49.1731349, lng: 20.1395751 },
    { lat: 49.1737211, lng: 20.1406846 },
    { lat: 49.1739075, lng: 20.1416782 },
    { lat: 49.1745902, lng: 20.1433096 },
    { lat: 49.1752869, lng: 20.1454861 },
    { lat: 49.1757924, lng: 20.1453456 },
    { lat: 49.1769195, lng: 20.1450071 },
    { lat: 49.1773844, lng: 20.1446602 },
    { lat: 49.1775094, lng: 20.1444649 },
    { lat: 49.1781841, lng: 20.1440185 },
    { lat: 49.1828487, lng: 20.147421 },
    { lat: 49.1838081, lng: 20.1495255 },
    { lat: 49.1841606, lng: 20.1501701 },
    { lat: 49.184879, lng: 20.1525258 },
    { lat: 49.185514, lng: 20.1551636 },
    { lat: 49.1854457, lng: 20.1583776 },
    { lat: 49.1864858, lng: 20.1594056 },
    { lat: 49.1869235, lng: 20.1604366 },
    { lat: 49.1880838, lng: 20.1622776 },
    { lat: 49.1883746, lng: 20.1667331 },
    { lat: 49.187617, lng: 20.1695882 },
    { lat: 49.1875224, lng: 20.1724364 },
    { lat: 49.1883304, lng: 20.1764298 },
    { lat: 49.1900668, lng: 20.1830925 },
    { lat: 49.194875, lng: 20.1829517 },
    { lat: 49.1963921, lng: 20.1828178 },
    { lat: 49.1985537, lng: 20.1828018 },
    { lat: 49.2011277, lng: 20.1844107 },
    { lat: 49.2026182, lng: 20.1859536 },
    { lat: 49.2028047, lng: 20.1899659 },
    { lat: 49.2016424, lng: 20.19716 },
    { lat: 49.2052248, lng: 20.2010243 },
    { lat: 49.2066962, lng: 20.2000543 },
    { lat: 49.2078596, lng: 20.1995434 },
    { lat: 49.2107843, lng: 20.198958 },
    { lat: 49.2155203, lng: 20.2012527 },
    { lat: 49.2197245, lng: 20.2081733 },
    { lat: 49.2284654, lng: 20.2024156 },
    { lat: 49.2291673, lng: 20.2000974 },
    { lat: 49.2293681, lng: 20.1999603 },
    { lat: 49.2294244, lng: 20.1998517 },
    { lat: 49.2296223, lng: 20.1998143 },
    { lat: 49.2298592, lng: 20.199457 },
    { lat: 49.2299339, lng: 20.1992104 },
    { lat: 49.2302627, lng: 20.1990107 },
    { lat: 49.2309101, lng: 20.1983235 },
    { lat: 49.2310814, lng: 20.1980739 },
    { lat: 49.2312563, lng: 20.1975983 },
    { lat: 49.2314124, lng: 20.1973954 },
    { lat: 49.2316581, lng: 20.1968311 },
    { lat: 49.2317847, lng: 20.1967866 },
    { lat: 49.2324986, lng: 20.195761 },
    { lat: 49.2328145, lng: 20.195467 },
    { lat: 49.2329326, lng: 20.1954885 },
    { lat: 49.2330577, lng: 20.195323 },
    { lat: 49.2332657, lng: 20.1952018 },
    { lat: 49.2333679, lng: 20.1947675 },
    { lat: 49.2332681, lng: 20.1941263 },
    { lat: 49.2336396, lng: 20.1936704 },
    { lat: 49.2336867, lng: 20.1933828 },
    { lat: 49.2336169, lng: 20.1930995 },
    { lat: 49.2335393, lng: 20.1929878 },
    { lat: 49.2335529, lng: 20.1926767 },
    { lat: 49.2334824, lng: 20.1925157 },
    { lat: 49.2334816, lng: 20.1923608 },
    { lat: 49.2336013, lng: 20.1919087 },
    { lat: 49.2335544, lng: 20.1913516 },
    { lat: 49.2338675, lng: 20.1907651 },
    { lat: 49.234387, lng: 20.1900364 },
    { lat: 49.2347611, lng: 20.1902103 },
    { lat: 49.2379361, lng: 20.1908199 },
    { lat: 49.2385272, lng: 20.1908726 },
    { lat: 49.2389243, lng: 20.1907244 },
    { lat: 49.2392716, lng: 20.1909593 },
    { lat: 49.2404289, lng: 20.191237 },
    { lat: 49.2417852, lng: 20.1919316 },
    { lat: 49.2427307, lng: 20.1926862 },
    { lat: 49.2446219, lng: 20.1937776 },
    { lat: 49.2479954, lng: 20.1964986 },
    { lat: 49.2481151, lng: 20.1967096 },
    { lat: 49.2484692, lng: 20.1976871 },
    { lat: 49.2484735, lng: 20.1977087 },
    { lat: 49.2485178, lng: 20.1950766 },
    { lat: 49.2500978, lng: 20.1895488 },
    { lat: 49.250407, lng: 20.1887444 },
    { lat: 49.2503294, lng: 20.1874439 },
    { lat: 49.2513855, lng: 20.1870154 },
    { lat: 49.2515785, lng: 20.1866576 },
    { lat: 49.2523755, lng: 20.1867088 },
    { lat: 49.253914, lng: 20.1861522 },
    { lat: 49.2550855, lng: 20.1848558 },
    { lat: 49.2556923, lng: 20.1846581 },
    { lat: 49.2574951, lng: 20.1845698 },
    { lat: 49.2590762, lng: 20.1861326 },
    { lat: 49.2603208, lng: 20.186196 },
    { lat: 49.26125, lng: 20.1860885 },
    { lat: 49.2623039, lng: 20.1871585 },
    { lat: 49.2626085, lng: 20.1875146 },
    { lat: 49.2625325, lng: 20.1875862 },
    { lat: 49.2627533, lng: 20.1876731 },
    { lat: 49.2629948, lng: 20.1881393 },
    { lat: 49.2633544, lng: 20.188407 },
    { lat: 49.2636165, lng: 20.1884295 },
    { lat: 49.2642897, lng: 20.1881684 },
    { lat: 49.2646309, lng: 20.1882569 },
    { lat: 49.2649784, lng: 20.1881832 },
    { lat: 49.2651751, lng: 20.1880611 },
    { lat: 49.2653256, lng: 20.1877691 },
    { lat: 49.2655845, lng: 20.1875151 },
    { lat: 49.2658355, lng: 20.187424 },
    { lat: 49.266021, lng: 20.1871652 },
    { lat: 49.266429, lng: 20.1868609 },
    { lat: 49.2665997, lng: 20.1857421 },
    { lat: 49.2679539, lng: 20.1848527 },
    { lat: 49.2694194, lng: 20.184156 },
    { lat: 49.2696095, lng: 20.1842649 },
    { lat: 49.2697404, lng: 20.1845564 },
    { lat: 49.2702106, lng: 20.1850291 },
    { lat: 49.2706292, lng: 20.1851067 },
    { lat: 49.2708251, lng: 20.1854444 },
    { lat: 49.2731967, lng: 20.1872305 },
    { lat: 49.2739756, lng: 20.1881041 },
    { lat: 49.2740575, lng: 20.1879505 },
    { lat: 49.2740933, lng: 20.1879771 },
    { lat: 49.2741901, lng: 20.1876359 },
    { lat: 49.2742597, lng: 20.1866064 },
    { lat: 49.2745954, lng: 20.1862723 },
    { lat: 49.2747501, lng: 20.1856049 },
    { lat: 49.2750088, lng: 20.1854824 },
    { lat: 49.2753657, lng: 20.1851943 },
    { lat: 49.275769, lng: 20.1852023 },
    { lat: 49.2758683, lng: 20.1848097 },
    { lat: 49.2763882, lng: 20.1841512 },
    { lat: 49.2767925, lng: 20.1841483 },
    { lat: 49.2768607, lng: 20.1840818 },
    { lat: 49.2768985, lng: 20.1838164 },
    { lat: 49.2773195, lng: 20.1837517 },
    { lat: 49.2776706, lng: 20.1830689 },
    { lat: 49.2779191, lng: 20.1830058 },
    { lat: 49.2781663, lng: 20.1827888 },
    { lat: 49.2784546, lng: 20.182652 },
    { lat: 49.2786309, lng: 20.1824686 },
    { lat: 49.2788906, lng: 20.1824611 },
    { lat: 49.2789261, lng: 20.1824755 },
    { lat: 49.2789066, lng: 20.1830879 },
    { lat: 49.2789536, lng: 20.1833612 },
    { lat: 49.2790491, lng: 20.1836387 },
    { lat: 49.2791892, lng: 20.1837386 },
    { lat: 49.2793211, lng: 20.1841674 },
    { lat: 49.2793636, lng: 20.1844701 },
    { lat: 49.2794376, lng: 20.1867 },
    { lat: 49.2796592, lng: 20.1870924 },
    { lat: 49.2797467, lng: 20.1873646 },
    { lat: 49.2800254, lng: 20.1877228 },
    { lat: 49.2800715, lng: 20.1879735 },
    { lat: 49.2803805, lng: 20.1884415 },
    { lat: 49.2804525, lng: 20.1888348 },
    { lat: 49.2805701, lng: 20.1889905 },
    { lat: 49.280561, lng: 20.189356 },
    { lat: 49.2804495, lng: 20.1894717 },
    { lat: 49.2804648, lng: 20.1896549 },
    { lat: 49.280407, lng: 20.1898168 },
    { lat: 49.2804681, lng: 20.1900817 },
    { lat: 49.2802414, lng: 20.1906668 },
    { lat: 49.280242, lng: 20.1914494 },
    { lat: 49.2801731, lng: 20.1915564 },
    { lat: 49.2801507, lng: 20.1920684 },
    { lat: 49.280096, lng: 20.1921651 },
    { lat: 49.2797892, lng: 20.1935424 },
    { lat: 49.2796834, lng: 20.1937192 },
    { lat: 49.2793723, lng: 20.1955546 },
    { lat: 49.2791179, lng: 20.1957574 },
    { lat: 49.2791222, lng: 20.1961498 },
    { lat: 49.2792165, lng: 20.1963686 },
    { lat: 49.2793617, lng: 20.1971634 },
    { lat: 49.2795181, lng: 20.1973278 },
    { lat: 49.2795654, lng: 20.1974973 },
    { lat: 49.279507, lng: 20.1977321 },
    { lat: 49.2797225, lng: 20.1982831 },
    { lat: 49.2797503, lng: 20.1985319 },
    { lat: 49.2799604, lng: 20.1990275 },
    { lat: 49.2801184, lng: 20.1995787 },
    { lat: 49.2803515, lng: 20.1998605 },
    { lat: 49.2806286, lng: 20.2004043 },
    { lat: 49.280798, lng: 20.2003306 },
    { lat: 49.2811238, lng: 20.2005987 },
    { lat: 49.2817244, lng: 20.2007199 },
    { lat: 49.2829126, lng: 20.2007589 },
    { lat: 49.2840792, lng: 20.2034305 },
    { lat: 49.2844366, lng: 20.204894 },
    { lat: 49.2846863, lng: 20.2055107 },
    { lat: 49.2853216, lng: 20.2068218 },
    { lat: 49.2862202, lng: 20.2069561 },
    { lat: 49.286707, lng: 20.206276 },
    { lat: 49.286848, lng: 20.206137 },
    { lat: 49.28702, lng: 20.206003 },
    { lat: 49.287253, lng: 20.205844 },
    { lat: 49.287483, lng: 20.205393 },
    { lat: 49.28798, lng: 20.20466 },
    { lat: 49.288329, lng: 20.204068 },
    { lat: 49.288806, lng: 20.20343 },
    { lat: 49.289047, lng: 20.202973 },
    { lat: 49.290112, lng: 20.201727 },
    { lat: 49.290491, lng: 20.201097 },
    { lat: 49.290926, lng: 20.200438 },
    { lat: 49.291264, lng: 20.200092 },
    { lat: 49.291592, lng: 20.199699 },
    { lat: 49.291794, lng: 20.199628 },
    { lat: 49.292278, lng: 20.199633 },
    { lat: 49.292615, lng: 20.199673 },
    { lat: 49.292732, lng: 20.199665 },
    { lat: 49.293031, lng: 20.199921 },
    { lat: 49.293279, lng: 20.2 },
    { lat: 49.293502, lng: 20.200162 },
    { lat: 49.293804, lng: 20.200318 },
    { lat: 49.294125, lng: 20.200542 },
    { lat: 49.29431, lng: 20.200753 },
    { lat: 49.294735, lng: 20.200942 },
    { lat: 49.295026, lng: 20.201242 },
    { lat: 49.295554, lng: 20.201591 },
    { lat: 49.295987, lng: 20.201983 },
    { lat: 49.296235, lng: 20.20216 },
    { lat: 49.296373, lng: 20.202243 },
    { lat: 49.296543, lng: 20.202287 },
    { lat: 49.296708, lng: 20.202285 },
    { lat: 49.296963, lng: 20.202204 },
    { lat: 49.297343, lng: 20.201902 },
    { lat: 49.297532, lng: 20.201729 },
    { lat: 49.297676, lng: 20.201501 },
    { lat: 49.297849, lng: 20.20114 },
    { lat: 49.298027, lng: 20.200869 },
    { lat: 49.2982, lng: 20.200639 },
    { lat: 49.298413, lng: 20.200421 },
    { lat: 49.298627, lng: 20.200263 },
    { lat: 49.298904, lng: 20.200115 },
    { lat: 49.29936, lng: 20.199807 },
    { lat: 49.299707, lng: 20.199535 },
    { lat: 49.299812, lng: 20.199508 },
    { lat: 49.300084, lng: 20.199319 },
    { lat: 49.300391, lng: 20.199083 },
    { lat: 49.300565, lng: 20.198886 },
    { lat: 49.300858, lng: 20.198682 },
    { lat: 49.301013, lng: 20.19864 },
    { lat: 49.301131, lng: 20.19851 },
    { lat: 49.301448, lng: 20.197972 },
    { lat: 49.301859, lng: 20.197398 },
    { lat: 49.302122, lng: 20.197018 },
    { lat: 49.302222, lng: 20.196805 },
    { lat: 49.302525, lng: 20.196349 },
    { lat: 49.302601, lng: 20.1962 },
    { lat: 49.302792, lng: 20.195706 },
    { lat: 49.30289, lng: 20.195453 },
    { lat: 49.303103, lng: 20.194783 },
    { lat: 49.303313, lng: 20.194265 },
    { lat: 49.303379, lng: 20.194037 },
    { lat: 49.303514, lng: 20.193633 },
    { lat: 49.303807, lng: 20.192962 },
    { lat: 49.304, lng: 20.192236 },
    { lat: 49.304063, lng: 20.192005 },
    { lat: 49.304146, lng: 20.191658 },
    { lat: 49.30419, lng: 20.191382 },
    { lat: 49.304308, lng: 20.190779 },
    { lat: 49.304475, lng: 20.189729 },
    { lat: 49.304599, lng: 20.189394 },
    { lat: 49.304734, lng: 20.189062 },
    { lat: 49.305001, lng: 20.188225 },
    { lat: 49.305111, lng: 20.187838 },
    { lat: 49.305198, lng: 20.187717 },
    { lat: 49.305502, lng: 20.187699 },
    { lat: 49.305687, lng: 20.187422 },
    { lat: 49.305857, lng: 20.187256 },
    { lat: 49.305925, lng: 20.187194 },
    { lat: 49.30623, lng: 20.187056 },
    { lat: 49.306434, lng: 20.186889 },
    { lat: 49.306561, lng: 20.186798 },
    { lat: 49.306662, lng: 20.186753 },
    { lat: 49.306887, lng: 20.186675 },
    { lat: 49.307042, lng: 20.186721 },
    { lat: 49.307235, lng: 20.186654 },
    { lat: 49.307401, lng: 20.186631 },
    { lat: 49.307696, lng: 20.186338 },
    { lat: 49.308138, lng: 20.186043 },
    { lat: 49.308247, lng: 20.185906 },
    { lat: 49.308296, lng: 20.185796 },
    { lat: 49.308459, lng: 20.185646 },
    { lat: 49.308577, lng: 20.185492 },
    { lat: 49.30869, lng: 20.185288 },
    { lat: 49.308971, lng: 20.184932 },
    { lat: 49.309206, lng: 20.184782 },
    { lat: 49.309486, lng: 20.184671 },
    { lat: 49.309631, lng: 20.184548 },
    { lat: 49.310064, lng: 20.183701 },
    { lat: 49.310321, lng: 20.183009 },
    { lat: 49.310648, lng: 20.183128 },
    { lat: 49.310841, lng: 20.183256 },
    { lat: 49.311927, lng: 20.182923 },
    { lat: 49.31251, lng: 20.182901 },
    { lat: 49.312638, lng: 20.182843 },
    { lat: 49.312873, lng: 20.182743 },
    { lat: 49.313202, lng: 20.182548 },
    { lat: 49.313674, lng: 20.182251 },
    { lat: 49.313903, lng: 20.182251 },
    { lat: 49.314011, lng: 20.182319 },
    { lat: 49.314232, lng: 20.182521 },
    { lat: 49.314533, lng: 20.182906 },
    { lat: 49.314859, lng: 20.183247 },
    { lat: 49.31545, lng: 20.183882 },
    { lat: 49.31567, lng: 20.183943 },
    { lat: 49.316021, lng: 20.184285 },
    { lat: 49.316775, lng: 20.184499 },
    { lat: 49.317028, lng: 20.184656 },
    { lat: 49.317455, lng: 20.185046 },
    { lat: 49.318718, lng: 20.185745 },
    { lat: 49.319624, lng: 20.185886 },
    { lat: 49.319877, lng: 20.185769 },
    { lat: 49.320444, lng: 20.184962 },
    { lat: 49.320531, lng: 20.184803 },
    { lat: 49.320594, lng: 20.184635 },
    { lat: 49.320723, lng: 20.184443 },
    { lat: 49.320849, lng: 20.184362 },
    { lat: 49.32111, lng: 20.184354 },
    { lat: 49.321245, lng: 20.184337 },
    { lat: 49.321395, lng: 20.184288 },
    { lat: 49.321745, lng: 20.184169 },
    { lat: 49.322282, lng: 20.184238 },
    { lat: 49.322459, lng: 20.184312 },
    { lat: 49.322993, lng: 20.184294 },
    { lat: 49.323471, lng: 20.184141 },
    { lat: 49.323835, lng: 20.183908 },
    { lat: 49.323983, lng: 20.183937 },
    { lat: 49.324178, lng: 20.184126 },
    { lat: 49.324283, lng: 20.184184 },
    { lat: 49.324579, lng: 20.18417 },
    { lat: 49.325098, lng: 20.184037 },
    { lat: 49.325744, lng: 20.184072 },
    { lat: 49.326052, lng: 20.18409 },
    { lat: 49.326243, lng: 20.184078 },
    { lat: 49.326488, lng: 20.183992 },
    { lat: 49.327195, lng: 20.183982 },
    { lat: 49.3278079, lng: 20.1841559 },
    { lat: 49.3278956, lng: 20.183586 },
    { lat: 49.3280147, lng: 20.1825053 },
    { lat: 49.328028, lng: 20.181212 },
    { lat: 49.3280332, lng: 20.1808458 },
    { lat: 49.328028, lng: 20.180319 },
    { lat: 49.3280241, lng: 20.1796302 },
    { lat: 49.328021, lng: 20.179474 },
    { lat: 49.328025, lng: 20.178688 },
    { lat: 49.328025, lng: 20.1783957 },
    { lat: 49.327996, lng: 20.177598 },
    { lat: 49.327956, lng: 20.176546 },
    { lat: 49.327911, lng: 20.175762 },
    { lat: 49.3278765, lng: 20.174654 },
    { lat: 49.327874, lng: 20.174551 },
    { lat: 49.327805, lng: 20.173758 },
    { lat: 49.3277439, lng: 20.1727676 },
    { lat: 49.327743, lng: 20.172689 },
    { lat: 49.3277377, lng: 20.1716299 },
    { lat: 49.327737, lng: 20.171494 },
    { lat: 49.3277361, lng: 20.1706992 },
    { lat: 49.3277346, lng: 20.1705837 },
  ],
  Poprad: [
    { lat: 49.096054, lng: 20.335977 },
    { lat: 49.096134, lng: 20.3356801 },
    { lat: 49.096215, lng: 20.3350703 },
    { lat: 49.096245, lng: 20.3346487 },
    { lat: 49.0961994, lng: 20.3341106 },
    { lat: 49.0962999, lng: 20.3338512 },
    { lat: 49.0962932, lng: 20.3337098 },
    { lat: 49.0961706, lng: 20.333424 },
    { lat: 49.0961707, lng: 20.3332807 },
    { lat: 49.0962193, lng: 20.3331398 },
    { lat: 49.0965881, lng: 20.3326256 },
    { lat: 49.0967628, lng: 20.3320179 },
    { lat: 49.0964366, lng: 20.3309774 },
    { lat: 49.0964446, lng: 20.3298154 },
    { lat: 49.096274, lng: 20.328723 },
    { lat: 49.0959352, lng: 20.3275328 },
    { lat: 49.0947492, lng: 20.3241194 },
    { lat: 49.0936427, lng: 20.3215072 },
    { lat: 49.0929145, lng: 20.3193917 },
    { lat: 49.0921517, lng: 20.3178711 },
    { lat: 49.0921758, lng: 20.3178212 },
    { lat: 49.0923795, lng: 20.317994 },
    { lat: 49.0924435, lng: 20.3179347 },
    { lat: 49.0924596, lng: 20.3178262 },
    { lat: 49.0923803, lng: 20.3176375 },
    { lat: 49.0925572, lng: 20.317203 },
    { lat: 49.0927126, lng: 20.3172067 },
    { lat: 49.0928174, lng: 20.3171218 },
    { lat: 49.0935181, lng: 20.3155315 },
    { lat: 49.0942103, lng: 20.3141841 },
    { lat: 49.0943721, lng: 20.3137136 },
    { lat: 49.0946021, lng: 20.313288 },
    { lat: 49.0951094, lng: 20.311822 },
    { lat: 49.0952977, lng: 20.3108116 },
    { lat: 49.0959655, lng: 20.3046084 },
    { lat: 49.0960579, lng: 20.3042307 },
    { lat: 49.0963759, lng: 20.3018435 },
    { lat: 49.0963911, lng: 20.3017419 },
    { lat: 49.0941121, lng: 20.3014239 },
    { lat: 49.0924864, lng: 20.301085 },
    { lat: 49.0890824, lng: 20.2997487 },
    { lat: 49.0875856, lng: 20.2994231 },
    { lat: 49.0861869, lng: 20.2988962 },
    { lat: 49.0857483, lng: 20.2984192 },
    { lat: 49.0851499, lng: 20.2983776 },
    { lat: 49.0848086, lng: 20.2982284 },
    { lat: 49.0835981, lng: 20.2972147 },
    { lat: 49.0825637, lng: 20.2965379 },
    { lat: 49.0812834, lng: 20.2954346 },
    { lat: 49.0805327, lng: 20.2949535 },
    { lat: 49.0803562, lng: 20.2947288 },
    { lat: 49.0791373, lng: 20.2944829 },
    { lat: 49.0785634, lng: 20.2944395 },
    { lat: 49.0785287, lng: 20.2923327 },
    { lat: 49.0783702, lng: 20.2898586 },
    { lat: 49.0768496, lng: 20.2897844 },
    { lat: 49.0768301, lng: 20.2897787 },
    { lat: 49.0763829, lng: 20.2896036 },
    { lat: 49.0773474, lng: 20.2869335 },
    { lat: 49.0773426, lng: 20.2868104 },
    { lat: 49.077461, lng: 20.2868151 },
    { lat: 49.0781816, lng: 20.2862025 },
    { lat: 49.0795289, lng: 20.2852965 },
    { lat: 49.0811368, lng: 20.283938 },
    { lat: 49.0810347, lng: 20.2831426 },
    { lat: 49.081003, lng: 20.2822381 },
    { lat: 49.0811243, lng: 20.2818291 },
    { lat: 49.0813173, lng: 20.2814119 },
    { lat: 49.0814103, lng: 20.2807655 },
    { lat: 49.08266, lng: 20.2773331 },
    { lat: 49.0828742, lng: 20.2766423 },
    { lat: 49.0833542, lng: 20.274322 },
    { lat: 49.0836359, lng: 20.2734041 },
    { lat: 49.0839278, lng: 20.2730686 },
    { lat: 49.0845991, lng: 20.2714336 },
    { lat: 49.0854461, lng: 20.2703695 },
    { lat: 49.086011, lng: 20.2683218 },
    { lat: 49.0866114, lng: 20.2674094 },
    { lat: 49.0879296, lng: 20.2642945 },
    { lat: 49.0880573, lng: 20.264109 },
    { lat: 49.0893585, lng: 20.2628302 },
    { lat: 49.0897326, lng: 20.2616602 },
    { lat: 49.0903222, lng: 20.2610443 },
    { lat: 49.0922732, lng: 20.2572766 },
    { lat: 49.0945036, lng: 20.254226 },
    { lat: 49.0946144, lng: 20.2540132 },
    { lat: 49.0957815, lng: 20.2511322 },
    { lat: 49.0968929, lng: 20.2472618 },
    { lat: 49.0971782, lng: 20.2443244 },
    { lat: 49.097283, lng: 20.2437866 },
    { lat: 49.0984663, lng: 20.2410664 },
    { lat: 49.0999975, lng: 20.2384854 },
    { lat: 49.1005687, lng: 20.2369206 },
    { lat: 49.1011132, lng: 20.2358821 },
    { lat: 49.1017286, lng: 20.235244 },
    { lat: 49.1016775, lng: 20.2352031 },
    { lat: 49.1016988, lng: 20.2351145 },
    { lat: 49.1018187, lng: 20.2351535 },
    { lat: 49.1018738, lng: 20.2349828 },
    { lat: 49.1020218, lng: 20.2348826 },
    { lat: 49.1021162, lng: 20.2349584 },
    { lat: 49.1021706, lng: 20.2348837 },
    { lat: 49.1021775, lng: 20.2347426 },
    { lat: 49.1022812, lng: 20.2346609 },
    { lat: 49.1022335, lng: 20.2345941 },
    { lat: 49.1023093, lng: 20.2345188 },
    { lat: 49.1022699, lng: 20.2344064 },
    { lat: 49.1024482, lng: 20.2342707 },
    { lat: 49.1024088, lng: 20.2341291 },
    { lat: 49.1024595, lng: 20.2340735 },
    { lat: 49.1024693, lng: 20.2338609 },
    { lat: 49.1026867, lng: 20.2332358 },
    { lat: 49.1027587, lng: 20.2331753 },
    { lat: 49.1027452, lng: 20.2330684 },
    { lat: 49.1028152, lng: 20.2331015 },
    { lat: 49.1028659, lng: 20.2328744 },
    { lat: 49.1029489, lng: 20.2328894 },
    { lat: 49.1030403, lng: 20.2326942 },
    { lat: 49.1030136, lng: 20.2324789 },
    { lat: 49.103143, lng: 20.2322474 },
    { lat: 49.1032578, lng: 20.2322736 },
    { lat: 49.1033097, lng: 20.2322214 },
    { lat: 49.1033278, lng: 20.2321637 },
    { lat: 49.1032531, lng: 20.2320965 },
    { lat: 49.1032509, lng: 20.2320111 },
    { lat: 49.1033266, lng: 20.2320218 },
    { lat: 49.1033637, lng: 20.2318488 },
    { lat: 49.1035149, lng: 20.2318536 },
    { lat: 49.103626, lng: 20.2315585 },
    { lat: 49.103723, lng: 20.2314752 },
    { lat: 49.1037596, lng: 20.231093 },
    { lat: 49.1039042, lng: 20.2310464 },
    { lat: 49.1038676, lng: 20.2308592 },
    { lat: 49.1040117, lng: 20.2305776 },
    { lat: 49.1040873, lng: 20.2306087 },
    { lat: 49.1040978, lng: 20.2303605 },
    { lat: 49.1041853, lng: 20.230295 },
    { lat: 49.1042598, lng: 20.2301552 },
    { lat: 49.1043044, lng: 20.2299161 },
    { lat: 49.10446, lng: 20.2296959 },
    { lat: 49.1044008, lng: 20.2296488 },
    { lat: 49.104443, lng: 20.2295778 },
    { lat: 49.1044318, lng: 20.2294667 },
    { lat: 49.1045575, lng: 20.229256 },
    { lat: 49.1045602, lng: 20.2290675 },
    { lat: 49.1046067, lng: 20.229074 },
    { lat: 49.1045813, lng: 20.2290055 },
    { lat: 49.1046438, lng: 20.2289851 },
    { lat: 49.1046632, lng: 20.2287957 },
    { lat: 49.1046231, lng: 20.228749 },
    { lat: 49.1046861, lng: 20.2286656 },
    { lat: 49.1046437, lng: 20.228612 },
    { lat: 49.1047919, lng: 20.2284888 },
    { lat: 49.1047697, lng: 20.2282953 },
    { lat: 49.1048152, lng: 20.2282531 },
    { lat: 49.1047954, lng: 20.228151 },
    { lat: 49.104859, lng: 20.228078 },
    { lat: 49.1048179, lng: 20.2280055 },
    { lat: 49.1048838, lng: 20.2276541 },
    { lat: 49.1051072, lng: 20.2274389 },
    { lat: 49.1051697, lng: 20.2270773 },
    { lat: 49.1052385, lng: 20.2270516 },
    { lat: 49.1052016, lng: 20.2270032 },
    { lat: 49.1052686, lng: 20.2268469 },
    { lat: 49.1052571, lng: 20.2267512 },
    { lat: 49.1053579, lng: 20.2265993 },
    { lat: 49.1053083, lng: 20.2265137 },
    { lat: 49.105395, lng: 20.2264561 },
    { lat: 49.1054127, lng: 20.2263017 },
    { lat: 49.1054756, lng: 20.226269 },
    { lat: 49.1053503, lng: 20.2259754 },
    { lat: 49.1055875, lng: 20.2255726 },
    { lat: 49.1056406, lng: 20.2256016 },
    { lat: 49.1056395, lng: 20.2254299 },
    { lat: 49.1057696, lng: 20.2251351 },
    { lat: 49.1058365, lng: 20.225143 },
    { lat: 49.1057957, lng: 20.2249947 },
    { lat: 49.105849, lng: 20.2249513 },
    { lat: 49.1058579, lng: 20.2247766 },
    { lat: 49.1059318, lng: 20.224653 },
    { lat: 49.1058966, lng: 20.2245865 },
    { lat: 49.1059455, lng: 20.2245385 },
    { lat: 49.1059535, lng: 20.2245148 },
    { lat: 49.1059299, lng: 20.2244911 },
    { lat: 49.1060504, lng: 20.2242317 },
    { lat: 49.106015, lng: 20.2241602 },
    { lat: 49.1062796, lng: 20.2232489 },
    { lat: 49.1062668, lng: 20.2229568 },
    { lat: 49.1063727, lng: 20.2225326 },
    { lat: 49.1063299, lng: 20.2224456 },
    { lat: 49.1064653, lng: 20.2221414 },
    { lat: 49.1064306, lng: 20.2220328 },
    { lat: 49.1064963, lng: 20.2216407 },
    { lat: 49.1066714, lng: 20.2213425 },
    { lat: 49.1066721, lng: 20.2210758 },
    { lat: 49.1067243, lng: 20.2209747 },
    { lat: 49.1053626, lng: 20.2205361 },
    { lat: 49.1045846, lng: 20.2204289 },
    { lat: 49.1038989, lng: 20.2206328 },
    { lat: 49.103816, lng: 20.2207561 },
    { lat: 49.1030341, lng: 20.2206032 },
    { lat: 49.1027798, lng: 20.2208314 },
    { lat: 49.1020127, lng: 20.2207736 },
    { lat: 49.1008782, lng: 20.2208694 },
    { lat: 49.0990972, lng: 20.2210581 },
    { lat: 49.0985851, lng: 20.2212341 },
    { lat: 49.0985468, lng: 20.2210899 },
    { lat: 49.0949485, lng: 20.2219609 },
    { lat: 49.0943763, lng: 20.2222125 },
    { lat: 49.0938774, lng: 20.2225802 },
    { lat: 49.0918037, lng: 20.2226231 },
    { lat: 49.091482, lng: 20.2226929 },
    { lat: 49.0884473, lng: 20.2224104 },
    { lat: 49.0882862, lng: 20.2224942 },
    { lat: 49.0873968, lng: 20.222524 },
    { lat: 49.0862813, lng: 20.2223386 },
    { lat: 49.0857263, lng: 20.2223158 },
    { lat: 49.085396, lng: 20.2222211 },
    { lat: 49.0846489, lng: 20.2221786 },
    { lat: 49.0834334, lng: 20.221886 },
    { lat: 49.0804146, lng: 20.2218317 },
    { lat: 49.0778526, lng: 20.2215167 },
    { lat: 49.077708, lng: 20.2216195 },
    { lat: 49.0772683, lng: 20.2216925 },
    { lat: 49.0768295, lng: 20.2216422 },
    { lat: 49.0759653, lng: 20.2214927 },
    { lat: 49.0754989, lng: 20.2213174 },
    { lat: 49.0752859, lng: 20.2213355 },
    { lat: 49.074909, lng: 20.2215429 },
    { lat: 49.0732428, lng: 20.2218958 },
    { lat: 49.0722276, lng: 20.2214584 },
    { lat: 49.0717624, lng: 20.2213678 },
    { lat: 49.0710739, lng: 20.2213871 },
    { lat: 49.0706475, lng: 20.2216787 },
    { lat: 49.0703264, lng: 20.2219919 },
    { lat: 49.0700554, lng: 20.2219701 },
    { lat: 49.0695255, lng: 20.2216571 },
    { lat: 49.0691115, lng: 20.2215263 },
    { lat: 49.0684722, lng: 20.2210405 },
    { lat: 49.0674564, lng: 20.2207235 },
    { lat: 49.067, lng: 20.2205066 },
    { lat: 49.0666682, lng: 20.2204283 },
    { lat: 49.0653222, lng: 20.2201036 },
    { lat: 49.064161, lng: 20.2198489 },
    { lat: 49.0620096, lng: 20.2196679 },
    { lat: 49.0619399, lng: 20.2193941 },
    { lat: 49.0619431, lng: 20.2194153 },
    { lat: 49.062249, lng: 20.2223071 },
    { lat: 49.0623582, lng: 20.2247206 },
    { lat: 49.0621866, lng: 20.2247431 },
    { lat: 49.0621828, lng: 20.2246465 },
    { lat: 49.0607356, lng: 20.2248384 },
    { lat: 49.06072, lng: 20.2247299 },
    { lat: 49.0584563, lng: 20.2242106 },
    { lat: 49.0559961, lng: 20.2237345 },
    { lat: 49.0558273, lng: 20.2237406 },
    { lat: 49.0557389, lng: 20.2238669 },
    { lat: 49.0551073, lng: 20.2238386 },
    { lat: 49.05509, lng: 20.2237719 },
    { lat: 49.0548128, lng: 20.2237449 },
    { lat: 49.0543122, lng: 20.2240705 },
    { lat: 49.0536638, lng: 20.2243582 },
    { lat: 49.0543741, lng: 20.2259447 },
    { lat: 49.0557752, lng: 20.2290859 },
    { lat: 49.0566866, lng: 20.2310027 },
    { lat: 49.0570377, lng: 20.2314808 },
    { lat: 49.0583264, lng: 20.2327719 },
    { lat: 49.058734, lng: 20.2336276 },
    { lat: 49.058833, lng: 20.2342814 },
    { lat: 49.0588495, lng: 20.2354102 },
    { lat: 49.0584546, lng: 20.2372427 },
    { lat: 49.0584376, lng: 20.237929 },
    { lat: 49.0585597, lng: 20.2386263 },
    { lat: 49.0589524, lng: 20.2398425 },
    { lat: 49.0591357, lng: 20.2406645 },
    { lat: 49.0591792, lng: 20.2411115 },
    { lat: 49.0591372, lng: 20.2428755 },
    { lat: 49.0594871, lng: 20.24478 },
    { lat: 49.0595151, lng: 20.2451193 },
    { lat: 49.0594341, lng: 20.2462905 },
    { lat: 49.0586917, lng: 20.248542 },
    { lat: 49.0584918, lng: 20.2493878 },
    { lat: 49.0585148, lng: 20.2504259 },
    { lat: 49.0589774, lng: 20.252084 },
    { lat: 49.0590317, lng: 20.2525174 },
    { lat: 49.0590223, lng: 20.2531129 },
    { lat: 49.0589, lng: 20.2538375 },
    { lat: 49.0582942, lng: 20.2557317 },
    { lat: 49.0581745, lng: 20.2562331 },
    { lat: 49.0581496, lng: 20.2571808 },
    { lat: 49.0582929, lng: 20.2579318 },
    { lat: 49.0586368, lng: 20.2592017 },
    { lat: 49.0587719, lng: 20.2602085 },
    { lat: 49.0587622, lng: 20.2607616 },
    { lat: 49.0586358, lng: 20.2613576 },
    { lat: 49.0583333, lng: 20.2620839 },
    { lat: 49.0568378, lng: 20.2646049 },
    { lat: 49.0565454, lng: 20.2651937 },
    { lat: 49.0564294, lng: 20.2657694 },
    { lat: 49.0563356, lng: 20.2671681 },
    { lat: 49.0562097, lng: 20.2676032 },
    { lat: 49.0560547, lng: 20.2685314 },
    { lat: 49.0551707, lng: 20.270228 },
    { lat: 49.0548773, lng: 20.2709824 },
    { lat: 49.0547659, lng: 20.2716208 },
    { lat: 49.054747, lng: 20.2720249 },
    { lat: 49.055165, lng: 20.2757152 },
    { lat: 49.053505, lng: 20.2781583 },
    { lat: 49.0529368, lng: 20.2791478 },
    { lat: 49.0520675, lng: 20.2782536 },
    { lat: 49.0504806, lng: 20.2764074 },
    { lat: 49.0502374, lng: 20.2760295 },
    { lat: 49.0502265, lng: 20.2759264 },
    { lat: 49.0500611, lng: 20.275614 },
    { lat: 49.0499659, lng: 20.2755368 },
    { lat: 49.0499378, lng: 20.2754001 },
    { lat: 49.0495489, lng: 20.275072 },
    { lat: 49.0491657, lng: 20.2744283 },
    { lat: 49.0485491, lng: 20.2736619 },
    { lat: 49.0481619, lng: 20.2731806 },
    { lat: 49.0476965, lng: 20.2724793 },
    { lat: 49.0469411, lng: 20.2717663 },
    { lat: 49.0458769, lng: 20.2703882 },
    { lat: 49.0446856, lng: 20.2691502 },
    { lat: 49.0439, lng: 20.268444 },
    { lat: 49.0428527, lng: 20.2670364 },
    { lat: 49.0405463, lng: 20.2645624 },
    { lat: 49.03931, lng: 20.2647637 },
    { lat: 49.0384986, lng: 20.2647491 },
    { lat: 49.0378581, lng: 20.2641278 },
    { lat: 49.0374675, lng: 20.2636461 },
    { lat: 49.0369916, lng: 20.2632802 },
    { lat: 49.0363096, lng: 20.2623483 },
    { lat: 49.0360132, lng: 20.2621359 },
    { lat: 49.0351166, lng: 20.2615562 },
    { lat: 49.0342485, lng: 20.2615917 },
    { lat: 49.033899, lng: 20.2616643 },
    { lat: 49.0331861, lng: 20.2612735 },
    { lat: 49.0328586, lng: 20.2610939 },
    { lat: 49.0309994, lng: 20.2596336 },
    { lat: 49.0280418, lng: 20.2575577 },
    { lat: 49.0271348, lng: 20.2568281 },
    { lat: 49.0264053, lng: 20.256147 },
    { lat: 49.0259666, lng: 20.2555575 },
    { lat: 49.0257922, lng: 20.255216 },
    { lat: 49.0255298, lng: 20.2545319 },
    { lat: 49.0255034, lng: 20.2543466 },
    { lat: 49.0253298, lng: 20.2541389 },
    { lat: 49.0252262, lng: 20.2536517 },
    { lat: 49.0250974, lng: 20.2535285 },
    { lat: 49.0250727, lng: 20.2532405 },
    { lat: 49.0249667, lng: 20.2530133 },
    { lat: 49.0248098, lng: 20.2529472 },
    { lat: 49.0247307, lng: 20.2525672 },
    { lat: 49.0243342, lng: 20.252219 },
    { lat: 49.0241573, lng: 20.251933 },
    { lat: 49.0241622, lng: 20.2517711 },
    { lat: 49.023959, lng: 20.2513246 },
    { lat: 49.0237322, lng: 20.2511159 },
    { lat: 49.0236331, lng: 20.2511163 },
    { lat: 49.0235918, lng: 20.2509845 },
    { lat: 49.0235107, lng: 20.2509251 },
    { lat: 49.0235121, lng: 20.2508471 },
    { lat: 49.0235876, lng: 20.2508761 },
    { lat: 49.0236036, lng: 20.2507982 },
    { lat: 49.0235465, lng: 20.2506893 },
    { lat: 49.023477, lng: 20.250752 },
    { lat: 49.0234576, lng: 20.2505776 },
    { lat: 49.0234114, lng: 20.2506026 },
    { lat: 49.023377, lng: 20.2505028 },
    { lat: 49.0234226, lng: 20.2503188 },
    { lat: 49.0234084, lng: 20.2501009 },
    { lat: 49.0230839, lng: 20.2498954 },
    { lat: 49.022979, lng: 20.2494328 },
    { lat: 49.0228205, lng: 20.2492653 },
    { lat: 49.0228297, lng: 20.2490148 },
    { lat: 49.0226049, lng: 20.248567 },
    { lat: 49.0226084, lng: 20.2481716 },
    { lat: 49.0225201, lng: 20.2480753 },
    { lat: 49.0224829, lng: 20.2479032 },
    { lat: 49.0219222, lng: 20.2475316 },
    { lat: 49.02175, lng: 20.2472807 },
    { lat: 49.0214573, lng: 20.2472546 },
    { lat: 49.0213097, lng: 20.2470765 },
    { lat: 49.020703, lng: 20.2468264 },
    { lat: 49.0202653, lng: 20.246838 },
    { lat: 49.0201506, lng: 20.2465262 },
    { lat: 49.0196093, lng: 20.2462767 },
    { lat: 49.0193425, lng: 20.2457108 },
    { lat: 49.0190496, lng: 20.2455635 },
    { lat: 49.0185555, lng: 20.2456006 },
    { lat: 49.0180101, lng: 20.2457321 },
    { lat: 49.0167383, lng: 20.2466915 },
    { lat: 49.0164449, lng: 20.2469044 },
    { lat: 49.0161973, lng: 20.2470529 },
    { lat: 49.0147303, lng: 20.2469462 },
    { lat: 49.0146352, lng: 20.2476476 },
    { lat: 49.0146374, lng: 20.2482706 },
    { lat: 49.0145143, lng: 20.2501778 },
    { lat: 49.0142431, lng: 20.2530699 },
    { lat: 49.0142196, lng: 20.2538458 },
    { lat: 49.0142499, lng: 20.2550782 },
    { lat: 49.0145224, lng: 20.2564795 },
    { lat: 49.0149996, lng: 20.2571898 },
    { lat: 49.0158237, lng: 20.2578109 },
    { lat: 49.015898, lng: 20.2580129 },
    { lat: 49.0162814, lng: 20.2612044 },
    { lat: 49.0166267, lng: 20.2620541 },
    { lat: 49.0164981, lng: 20.262275 },
    { lat: 49.0162557, lng: 20.2629897 },
    { lat: 49.0161922, lng: 20.2636072 },
    { lat: 49.0161936, lng: 20.264213 },
    { lat: 49.0163134, lng: 20.2652342 },
    { lat: 49.0162849, lng: 20.2656271 },
    { lat: 49.015347, lng: 20.2663741 },
    { lat: 49.0139585, lng: 20.2672309 },
    { lat: 49.0139383, lng: 20.2679803 },
    { lat: 49.0137991, lng: 20.2687671 },
    { lat: 49.0136255, lng: 20.2691918 },
    { lat: 49.0136489, lng: 20.2693837 },
    { lat: 49.0136018, lng: 20.2702547 },
    { lat: 49.0136391, lng: 20.2705175 },
    { lat: 49.0135992, lng: 20.2711073 },
    { lat: 49.0130149, lng: 20.2728351 },
    { lat: 49.0128181, lng: 20.2732734 },
    { lat: 49.0125697, lng: 20.2737684 },
    { lat: 49.0121858, lng: 20.2741486 },
    { lat: 49.0123339, lng: 20.2789905 },
    { lat: 49.0124386, lng: 20.2810333 },
    { lat: 49.0124804, lng: 20.281498 },
    { lat: 49.0123659, lng: 20.2831793 },
    { lat: 49.012248, lng: 20.2879379 },
    { lat: 49.0110091, lng: 20.290154 },
    { lat: 49.0114042, lng: 20.2905265 },
    { lat: 49.0114889, lng: 20.2905849 },
    { lat: 49.0118759, lng: 20.2904051 },
    { lat: 49.0131892, lng: 20.2901854 },
    { lat: 49.0144477, lng: 20.2900844 },
    { lat: 49.0159382, lng: 20.2901074 },
    { lat: 49.018116, lng: 20.290454 },
    { lat: 49.0207595, lng: 20.2910442 },
    { lat: 49.021965, lng: 20.2916357 },
    { lat: 49.0249423, lng: 20.2928188 },
    { lat: 49.0249891, lng: 20.292834 },
    { lat: 49.0282571, lng: 20.2945734 },
    { lat: 49.0300191, lng: 20.2952574 },
    { lat: 49.0313169, lng: 20.2992044 },
    { lat: 49.0326047, lng: 20.3024336 },
    { lat: 49.0341506, lng: 20.3049458 },
    { lat: 49.0352321, lng: 20.3069271 },
    { lat: 49.035766, lng: 20.3076819 },
    { lat: 49.036922, lng: 20.3079329 },
    { lat: 49.0370127, lng: 20.3079463 },
    { lat: 49.0370284, lng: 20.3080909 },
    { lat: 49.0374355, lng: 20.3109753 },
    { lat: 49.0366958, lng: 20.3119372 },
    { lat: 49.0357832, lng: 20.3134871 },
    { lat: 49.0352302, lng: 20.3146141 },
    { lat: 49.0349847, lng: 20.3148496 },
    { lat: 49.0362149, lng: 20.3197377 },
    { lat: 49.0373477, lng: 20.323487 },
    { lat: 49.0386248, lng: 20.3248285 },
    { lat: 49.0393184, lng: 20.3257268 },
    { lat: 49.0398498, lng: 20.3261612 },
    { lat: 49.0423235, lng: 20.327731 },
    { lat: 49.0465372, lng: 20.3316054 },
    { lat: 49.0465558, lng: 20.3316235 },
    { lat: 49.0455627, lng: 20.3330774 },
    { lat: 49.0426496, lng: 20.3363992 },
    { lat: 49.039035, lng: 20.3388742 },
    { lat: 49.038818, lng: 20.3391136 },
    { lat: 49.0384152, lng: 20.3398835 },
    { lat: 49.0369212, lng: 20.3426909 },
    { lat: 49.0360184, lng: 20.344245 },
    { lat: 49.0358849, lng: 20.3449 },
    { lat: 49.0359663, lng: 20.345463 },
    { lat: 49.037827, lng: 20.3503006 },
    { lat: 49.0380262, lng: 20.3506447 },
    { lat: 49.0390908, lng: 20.3518888 },
    { lat: 49.0423417, lng: 20.3529172 },
    { lat: 49.0438499, lng: 20.3537456 },
    { lat: 49.0441235, lng: 20.3539814 },
    { lat: 49.0455353, lng: 20.3555291 },
    { lat: 49.0474736, lng: 20.3595549 },
    { lat: 49.0479776, lng: 20.3625597 },
    { lat: 49.0479906, lng: 20.3630464 },
    { lat: 49.0478981, lng: 20.3643932 },
    { lat: 49.0484524, lng: 20.3653659 },
    { lat: 49.0488439, lng: 20.3659137 },
    { lat: 49.0501452, lng: 20.3667264 },
    { lat: 49.0504155, lng: 20.3669641 },
    { lat: 49.0508217, lng: 20.3677806 },
    { lat: 49.0511126, lng: 20.3685248 },
    { lat: 49.0512394, lng: 20.3690423 },
    { lat: 49.0514967, lng: 20.3705846 },
    { lat: 49.0515364, lng: 20.3706787 },
    { lat: 49.0516957, lng: 20.3710196 },
    { lat: 49.0532918, lng: 20.3724763 },
    { lat: 49.0539765, lng: 20.3728561 },
    { lat: 49.0559909, lng: 20.3735631 },
    { lat: 49.0563454, lng: 20.3737675 },
    { lat: 49.0566277, lng: 20.3742279 },
    { lat: 49.056737, lng: 20.374614 },
    { lat: 49.056749, lng: 20.374614 },
    { lat: 49.056769, lng: 20.374604 },
    { lat: 49.05706, lng: 20.374457 },
    { lat: 49.057294, lng: 20.374335 },
    { lat: 49.057664, lng: 20.374019 },
    { lat: 49.058211, lng: 20.373558 },
    { lat: 49.058218, lng: 20.373533 },
    { lat: 49.058425, lng: 20.373023 },
    { lat: 49.058547, lng: 20.372752 },
    { lat: 49.05866, lng: 20.372494 },
    { lat: 49.058736, lng: 20.372286 },
    { lat: 49.058815, lng: 20.372097 },
    { lat: 49.058911, lng: 20.37183 },
    { lat: 49.058957, lng: 20.371684 },
    { lat: 49.058998, lng: 20.371578 },
    { lat: 49.059019, lng: 20.371516 },
    { lat: 49.059058, lng: 20.371382 },
    { lat: 49.059107, lng: 20.371211 },
    { lat: 49.059156, lng: 20.371093 },
    { lat: 49.059203, lng: 20.370982 },
    { lat: 49.059284, lng: 20.370775 },
    { lat: 49.059302, lng: 20.370722 },
    { lat: 49.059374, lng: 20.370545 },
    { lat: 49.059471, lng: 20.370363 },
    { lat: 49.059666, lng: 20.370014 },
    { lat: 49.059743, lng: 20.369881 },
    { lat: 49.059791, lng: 20.369787 },
    { lat: 49.059932, lng: 20.369545 },
    { lat: 49.060174, lng: 20.369154 },
    { lat: 49.060377, lng: 20.368819 },
    { lat: 49.060535, lng: 20.368536 },
    { lat: 49.060708, lng: 20.36828 },
    { lat: 49.060905, lng: 20.368112 },
    { lat: 49.060938, lng: 20.368088 },
    { lat: 49.06114, lng: 20.36796 },
    { lat: 49.061462, lng: 20.367782 },
    { lat: 49.06166, lng: 20.367652 },
    { lat: 49.061733, lng: 20.367594 },
    { lat: 49.061918, lng: 20.367428 },
    { lat: 49.06209, lng: 20.367264 },
    { lat: 49.062181, lng: 20.367198 },
    { lat: 49.062543, lng: 20.366894 },
    { lat: 49.062788, lng: 20.366685 },
    { lat: 49.062925, lng: 20.366557 },
    { lat: 49.063054, lng: 20.366416 },
    { lat: 49.063138, lng: 20.366302 },
    { lat: 49.063217, lng: 20.366183 },
    { lat: 49.063364, lng: 20.36597 },
    { lat: 49.0635, lng: 20.365767 },
    { lat: 49.063579, lng: 20.36564 },
    { lat: 49.063644, lng: 20.365553 },
    { lat: 49.063711, lng: 20.365447 },
    { lat: 49.063768, lng: 20.36535 },
    { lat: 49.063933, lng: 20.365179 },
    { lat: 49.064004, lng: 20.365106 },
    { lat: 49.064095, lng: 20.365034 },
    { lat: 49.064163, lng: 20.364948 },
    { lat: 49.064317, lng: 20.36479 },
    { lat: 49.06443, lng: 20.364664 },
    { lat: 49.06446, lng: 20.364637 },
    { lat: 49.064488, lng: 20.364598 },
    { lat: 49.064743, lng: 20.364286 },
    { lat: 49.064813, lng: 20.364205 },
    { lat: 49.064914, lng: 20.364088 },
    { lat: 49.065147, lng: 20.363859 },
    { lat: 49.065205, lng: 20.363795 },
    { lat: 49.065277, lng: 20.363724 },
    { lat: 49.065322, lng: 20.363637 },
    { lat: 49.065343, lng: 20.363639 },
    { lat: 49.066157, lng: 20.36308 },
    { lat: 49.066464, lng: 20.36286 },
    { lat: 49.066562, lng: 20.362779 },
    { lat: 49.066767, lng: 20.362595 },
    { lat: 49.066798, lng: 20.362558 },
    { lat: 49.067087, lng: 20.362272 },
    { lat: 49.067357, lng: 20.362021 },
    { lat: 49.067629, lng: 20.361783 },
    { lat: 49.067658, lng: 20.36175 },
    { lat: 49.067855, lng: 20.361588 },
    { lat: 49.067886, lng: 20.361569 },
    { lat: 49.067945, lng: 20.36155 },
    { lat: 49.068037, lng: 20.36162 },
    { lat: 49.068094, lng: 20.361646 },
    { lat: 49.068295, lng: 20.361592 },
    { lat: 49.068566, lng: 20.361477 },
    { lat: 49.068703, lng: 20.361407 },
    { lat: 49.068838, lng: 20.361334 },
    { lat: 49.06896, lng: 20.361243 },
    { lat: 49.069071, lng: 20.361155 },
    { lat: 49.069266, lng: 20.361006 },
    { lat: 49.069483, lng: 20.360831 },
    { lat: 49.069583, lng: 20.360756 },
    { lat: 49.069731, lng: 20.360635 },
    { lat: 49.069848, lng: 20.360525 },
    { lat: 49.069958, lng: 20.360698 },
    { lat: 49.06997, lng: 20.360688 },
    { lat: 49.070085, lng: 20.360567 },
    { lat: 49.070155, lng: 20.360488 },
    { lat: 49.070264, lng: 20.360362 },
    { lat: 49.070418, lng: 20.360197 },
    { lat: 49.070484, lng: 20.360123 },
    { lat: 49.070588, lng: 20.360017 },
    { lat: 49.070631, lng: 20.359967 },
    { lat: 49.070741, lng: 20.359854 },
    { lat: 49.070836, lng: 20.359758 },
    { lat: 49.070942, lng: 20.359656 },
    { lat: 49.071019, lng: 20.359573 },
    { lat: 49.071111, lng: 20.359487 },
    { lat: 49.071206, lng: 20.359406 },
    { lat: 49.071389, lng: 20.359253 },
    { lat: 49.071427, lng: 20.359216 },
    { lat: 49.071478, lng: 20.359175 },
    { lat: 49.071793, lng: 20.358962 },
    { lat: 49.07198, lng: 20.358853 },
    { lat: 49.072127, lng: 20.35877 },
    { lat: 49.072198, lng: 20.358734 },
    { lat: 49.072259, lng: 20.358692 },
    { lat: 49.072372, lng: 20.358642 },
    { lat: 49.072454, lng: 20.358596 },
    { lat: 49.072646, lng: 20.358499 },
    { lat: 49.072736, lng: 20.358448 },
    { lat: 49.072887, lng: 20.358378 },
    { lat: 49.072942, lng: 20.358357 },
    { lat: 49.073079, lng: 20.358289 },
    { lat: 49.073231, lng: 20.358209 },
    { lat: 49.073284, lng: 20.358185 },
    { lat: 49.073382, lng: 20.358122 },
    { lat: 49.073424, lng: 20.358087 },
    { lat: 49.073506, lng: 20.358028 },
    { lat: 49.073551, lng: 20.357987 },
    { lat: 49.073592, lng: 20.357952 },
    { lat: 49.073632, lng: 20.357923 },
    { lat: 49.07373, lng: 20.357847 },
    { lat: 49.073823, lng: 20.357761 },
    { lat: 49.074001, lng: 20.357606 },
    { lat: 49.074085, lng: 20.35753 },
    { lat: 49.074283, lng: 20.357355 },
    { lat: 49.074356, lng: 20.357296 },
    { lat: 49.074553, lng: 20.357134 },
    { lat: 49.074591, lng: 20.357102 },
    { lat: 49.074764, lng: 20.356942 },
    { lat: 49.07509, lng: 20.356654 },
    { lat: 49.075218, lng: 20.35653 },
    { lat: 49.075306, lng: 20.356829 },
    { lat: 49.075377, lng: 20.356788 },
    { lat: 49.075403, lng: 20.356773 },
    { lat: 49.075459, lng: 20.357009 },
    { lat: 49.075474, lng: 20.357135 },
    { lat: 49.075721, lng: 20.359102 },
    { lat: 49.075762, lng: 20.359224 },
    { lat: 49.0763, lng: 20.360134 },
    { lat: 49.0771, lng: 20.361489 },
    { lat: 49.077379, lng: 20.361913 },
    { lat: 49.077735, lng: 20.362333 },
    { lat: 49.079565, lng: 20.364214 },
    { lat: 49.079578, lng: 20.364227 },
    { lat: 49.079903, lng: 20.364569 },
    { lat: 49.081468, lng: 20.36636 },
    { lat: 49.081788, lng: 20.366809 },
    { lat: 49.081861, lng: 20.366769 },
    { lat: 49.081902, lng: 20.366866 },
    { lat: 49.081857, lng: 20.366907 },
    { lat: 49.08358, lng: 20.369436 },
    { lat: 49.083842, lng: 20.369752 },
    { lat: 49.084129, lng: 20.370036 },
    { lat: 49.084977, lng: 20.370813 },
    { lat: 49.085336, lng: 20.371141 },
    { lat: 49.085367, lng: 20.371169 },
    { lat: 49.085939, lng: 20.371693 },
    { lat: 49.085924, lng: 20.371733 },
    { lat: 49.086007, lng: 20.371815 },
    { lat: 49.086497, lng: 20.372277 },
    { lat: 49.086655, lng: 20.372429 },
    { lat: 49.086846, lng: 20.372612 },
    { lat: 49.087155, lng: 20.372906 },
    { lat: 49.087283, lng: 20.373027 },
    { lat: 49.087443, lng: 20.373178 },
    { lat: 49.087552, lng: 20.373284 },
    { lat: 49.087653, lng: 20.373379 },
    { lat: 49.087776, lng: 20.373492 },
    { lat: 49.088087, lng: 20.373778 },
    { lat: 49.088097, lng: 20.373759 },
    { lat: 49.088195, lng: 20.373584 },
    { lat: 49.088363, lng: 20.373187 },
    { lat: 49.088422, lng: 20.372961 },
    { lat: 49.088499, lng: 20.372557 },
    { lat: 49.088553, lng: 20.372191 },
    { lat: 49.088576, lng: 20.371884 },
    { lat: 49.08859, lng: 20.371463 },
    { lat: 49.088643, lng: 20.370901 },
    { lat: 49.088687, lng: 20.370521 },
    { lat: 49.08873, lng: 20.370284 },
    { lat: 49.08881, lng: 20.369856 },
    { lat: 49.088886, lng: 20.36961 },
    { lat: 49.089049, lng: 20.369214 },
    { lat: 49.089128, lng: 20.36895 },
    { lat: 49.089285, lng: 20.368405 },
    { lat: 49.08929, lng: 20.3682 },
    { lat: 49.089275, lng: 20.367713 },
    { lat: 49.089258, lng: 20.367475 },
    { lat: 49.089211, lng: 20.367053 },
    { lat: 49.089221, lng: 20.366902 },
    { lat: 49.089232, lng: 20.366725 },
    { lat: 49.089356, lng: 20.365429 },
    { lat: 49.089439, lng: 20.364485 },
    { lat: 49.089473, lng: 20.364167 },
    { lat: 49.089502, lng: 20.363995 },
    { lat: 49.089704, lng: 20.363166 },
    { lat: 49.089909, lng: 20.362326 },
    { lat: 49.090175, lng: 20.361321 },
    { lat: 49.090409, lng: 20.360707 },
    { lat: 49.090561, lng: 20.360301 },
    { lat: 49.090719, lng: 20.360097 },
    { lat: 49.091037, lng: 20.359795 },
    { lat: 49.091402, lng: 20.359517 },
    { lat: 49.091529, lng: 20.359401 },
    { lat: 49.091728, lng: 20.359217 },
    { lat: 49.092016, lng: 20.358896 },
    { lat: 49.092263, lng: 20.358557 },
    { lat: 49.092547, lng: 20.358143 },
    { lat: 49.092689, lng: 20.358015 },
    { lat: 49.092797, lng: 20.357902 },
    { lat: 49.093039, lng: 20.357683 },
    { lat: 49.093334, lng: 20.357425 },
    { lat: 49.093563, lng: 20.357052 },
    { lat: 49.09363, lng: 20.356922 },
    { lat: 49.093755, lng: 20.35667 },
    { lat: 49.093921, lng: 20.356332 },
    { lat: 49.094171, lng: 20.355728 },
    { lat: 49.094245, lng: 20.355476 },
    { lat: 49.094421, lng: 20.354891 },
    { lat: 49.094578, lng: 20.354106 },
    { lat: 49.094536, lng: 20.354109 },
    { lat: 49.09416, lng: 20.35359 },
    { lat: 49.093912, lng: 20.353248 },
    { lat: 49.093839, lng: 20.353134 },
    { lat: 49.093473, lng: 20.352533 },
    { lat: 49.093122, lng: 20.351971 },
    { lat: 49.092715, lng: 20.351314 },
    { lat: 49.093078, lng: 20.350151 },
    { lat: 49.093317, lng: 20.349142 },
    { lat: 49.093543, lng: 20.348203 },
    { lat: 49.093578, lng: 20.348101 },
    { lat: 49.094033, lng: 20.346709 },
    { lat: 49.094224, lng: 20.346137 },
    { lat: 49.094275, lng: 20.345999 },
    { lat: 49.094296, lng: 20.345937 },
    { lat: 49.094359, lng: 20.345752 },
    { lat: 49.09438, lng: 20.34569 },
    { lat: 49.094473, lng: 20.345414 },
    { lat: 49.094511, lng: 20.345387 },
    { lat: 49.094782, lng: 20.344545 },
    { lat: 49.094784, lng: 20.344269 },
    { lat: 49.094918, lng: 20.343992 },
    { lat: 49.094934, lng: 20.343959 },
    { lat: 49.095004, lng: 20.34381 },
    { lat: 49.095291, lng: 20.343104 },
    { lat: 49.095313, lng: 20.343052 },
    { lat: 49.09537, lng: 20.342897 },
    { lat: 49.095516, lng: 20.342405 },
    { lat: 49.095577, lng: 20.342203 },
    { lat: 49.095665, lng: 20.341915 },
    { lat: 49.095677, lng: 20.341871 },
    { lat: 49.095685, lng: 20.341845 },
    { lat: 49.09575, lng: 20.341617 },
    { lat: 49.095874, lng: 20.341197 },
    { lat: 49.095937, lng: 20.340998 },
    { lat: 49.09583, lng: 20.340629 },
    { lat: 49.095689, lng: 20.340296 },
    { lat: 49.095661, lng: 20.340211 },
    { lat: 49.095613, lng: 20.340066 },
    { lat: 49.095221, lng: 20.33945 },
    { lat: 49.094911, lng: 20.339498 },
    { lat: 49.094739, lng: 20.339513 },
    { lat: 49.094618, lng: 20.33953 },
    { lat: 49.09459, lng: 20.339534 },
    { lat: 49.094559, lng: 20.339538 },
    { lat: 49.094517, lng: 20.339544 },
    { lat: 49.094481, lng: 20.339549 },
    { lat: 49.094467, lng: 20.339551 },
    { lat: 49.094377, lng: 20.339563 },
    { lat: 49.094347, lng: 20.339567 },
    { lat: 49.094336, lng: 20.339569 },
    { lat: 49.094302, lng: 20.339574 },
    { lat: 49.09425, lng: 20.339581 },
    { lat: 49.094241, lng: 20.339582 },
    { lat: 49.094231, lng: 20.339584 },
    { lat: 49.094098, lng: 20.339602 },
    { lat: 49.094004, lng: 20.339619 },
    { lat: 49.093502, lng: 20.339239 },
    { lat: 49.093421, lng: 20.339177 },
    { lat: 49.093367, lng: 20.339137 },
    { lat: 49.09338, lng: 20.33902 },
    { lat: 49.093411, lng: 20.338926 },
    { lat: 49.093448, lng: 20.33881 },
    { lat: 49.093515, lng: 20.338615 },
    { lat: 49.093529, lng: 20.338589 },
    { lat: 49.09359, lng: 20.338476 },
    { lat: 49.093699, lng: 20.338304 },
    { lat: 49.093992, lng: 20.338038 },
    { lat: 49.094025, lng: 20.338013 },
    { lat: 49.094183, lng: 20.337888 },
    { lat: 49.094189, lng: 20.337898 },
    { lat: 49.094209, lng: 20.337935 },
    { lat: 49.094255, lng: 20.337953 },
    { lat: 49.094277, lng: 20.337962 },
    { lat: 49.094394, lng: 20.338009 },
    { lat: 49.094421, lng: 20.33802 },
    { lat: 49.094744, lng: 20.338156 },
    { lat: 49.095182, lng: 20.337493 },
    { lat: 49.095273, lng: 20.3373 },
    { lat: 49.095297, lng: 20.337256 },
    { lat: 49.095394, lng: 20.337043 },
    { lat: 49.09543, lng: 20.336973 },
    { lat: 49.095479, lng: 20.336851 },
    { lat: 49.09552, lng: 20.336765 },
    { lat: 49.095557, lng: 20.336684 },
    { lat: 49.095595, lng: 20.336598 },
    { lat: 49.09562, lng: 20.336547 },
    { lat: 49.095831, lng: 20.336089 },
    { lat: 49.095861, lng: 20.336022 },
    { lat: 49.095902, lng: 20.336042 },
    { lat: 49.095978, lng: 20.336023 },
    { lat: 49.096054, lng: 20.335977 },
  ],
  Mengusovce: [
    { lat: 49.0669499, lng: 20.1640391 },
    { lat: 49.0670422, lng: 20.1638929 },
    { lat: 49.0673559, lng: 20.1632647 },
    { lat: 49.0683823, lng: 20.1626318 },
    { lat: 49.068421, lng: 20.1626968 },
    { lat: 49.0687241, lng: 20.1624466 },
    { lat: 49.0689755, lng: 20.1622999 },
    { lat: 49.0692352, lng: 20.1623029 },
    { lat: 49.0694613, lng: 20.1621282 },
    { lat: 49.0697664, lng: 20.1620154 },
    { lat: 49.0699115, lng: 20.1618669 },
    { lat: 49.0702726, lng: 20.1612985 },
    { lat: 49.0705772, lng: 20.161003 },
    { lat: 49.0710846, lng: 20.1609605 },
    { lat: 49.0714033, lng: 20.1608242 },
    { lat: 49.0714379, lng: 20.1607269 },
    { lat: 49.0713863, lng: 20.1605949 },
    { lat: 49.0712465, lng: 20.1605102 },
    { lat: 49.0711391, lng: 20.1603553 },
    { lat: 49.0712486, lng: 20.1597428 },
    { lat: 49.0714141, lng: 20.1595471 },
    { lat: 49.0718218, lng: 20.1594898 },
    { lat: 49.0722579, lng: 20.1586041 },
    { lat: 49.0723237, lng: 20.1583027 },
    { lat: 49.0725866, lng: 20.1581206 },
    { lat: 49.0727337, lng: 20.1581098 },
    { lat: 49.0728572, lng: 20.1579898 },
    { lat: 49.0729031, lng: 20.157816 },
    { lat: 49.0730059, lng: 20.1577378 },
    { lat: 49.0730421, lng: 20.1578814 },
    { lat: 49.0732444, lng: 20.1578166 },
    { lat: 49.0733053, lng: 20.1576475 },
    { lat: 49.0732134, lng: 20.1573791 },
    { lat: 49.0733445, lng: 20.1570253 },
    { lat: 49.0733784, lng: 20.1564942 },
    { lat: 49.0734269, lng: 20.1563557 },
    { lat: 49.0738011, lng: 20.1562253 },
    { lat: 49.0739701, lng: 20.1560903 },
    { lat: 49.0742132, lng: 20.1560846 },
    { lat: 49.0745772, lng: 20.1557005 },
    { lat: 49.0746568, lng: 20.1556895 },
    { lat: 49.0746709, lng: 20.1553653 },
    { lat: 49.074799, lng: 20.1551935 },
    { lat: 49.075155, lng: 20.1544398 },
    { lat: 49.0755423, lng: 20.1541301 },
    { lat: 49.0758608, lng: 20.1537868 },
    { lat: 49.0759272, lng: 20.1535586 },
    { lat: 49.0759043, lng: 20.1533532 },
    { lat: 49.0760634, lng: 20.1530786 },
    { lat: 49.0762905, lng: 20.1523994 },
    { lat: 49.0765788, lng: 20.1521753 },
    { lat: 49.0767077, lng: 20.1510965 },
    { lat: 49.0774066, lng: 20.150383 },
    { lat: 49.0777004, lng: 20.1503243 },
    { lat: 49.0777579, lng: 20.1501279 },
    { lat: 49.0778437, lng: 20.1501077 },
    { lat: 49.0781134, lng: 20.1497309 },
    { lat: 49.0782342, lng: 20.1496553 },
    { lat: 49.0784092, lng: 20.1495826 },
    { lat: 49.0785807, lng: 20.1496748 },
    { lat: 49.0787601, lng: 20.1495697 },
    { lat: 49.0789496, lng: 20.1497162 },
    { lat: 49.079102, lng: 20.1497498 },
    { lat: 49.0793233, lng: 20.1495729 },
    { lat: 49.079325, lng: 20.1491628 },
    { lat: 49.0794258, lng: 20.1487597 },
    { lat: 49.0794284, lng: 20.1485418 },
    { lat: 49.0794958, lng: 20.1483699 },
    { lat: 49.0795769, lng: 20.1483458 },
    { lat: 49.0798296, lng: 20.1479332 },
    { lat: 49.0800792, lng: 20.1479767 },
    { lat: 49.0801757, lng: 20.1478884 },
    { lat: 49.0809527, lng: 20.14699 },
    { lat: 49.080992, lng: 20.1468462 },
    { lat: 49.0809491, lng: 20.146232 },
    { lat: 49.0809979, lng: 20.1460431 },
    { lat: 49.0811098, lng: 20.1459432 },
    { lat: 49.0812887, lng: 20.1456269 },
    { lat: 49.0813063, lng: 20.1453961 },
    { lat: 49.0813863, lng: 20.1452266 },
    { lat: 49.0820101, lng: 20.1448347 },
    { lat: 49.0818527, lng: 20.1441438 },
    { lat: 49.0820105, lng: 20.1439782 },
    { lat: 49.0822476, lng: 20.1433791 },
    { lat: 49.0825133, lng: 20.1429857 },
    { lat: 49.082779, lng: 20.1426745 },
    { lat: 49.0829083, lng: 20.1426136 },
    { lat: 49.0830293, lng: 20.142431 },
    { lat: 49.083039, lng: 20.1420812 },
    { lat: 49.0831996, lng: 20.1418776 },
    { lat: 49.0832746, lng: 20.1418645 },
    { lat: 49.0835024, lng: 20.1413981 },
    { lat: 49.0836091, lng: 20.1408848 },
    { lat: 49.0835113, lng: 20.1403008 },
    { lat: 49.0835231, lng: 20.1400952 },
    { lat: 49.0836838, lng: 20.1398323 },
    { lat: 49.083995, lng: 20.1395913 },
    { lat: 49.0844515, lng: 20.1390417 },
    { lat: 49.0844628, lng: 20.1379808 },
    { lat: 49.0846067, lng: 20.1378348 },
    { lat: 49.0847597, lng: 20.1378313 },
    { lat: 49.0849624, lng: 20.1377182 },
    { lat: 49.085035, lng: 20.1375343 },
    { lat: 49.0853674, lng: 20.1372327 },
    { lat: 49.0854677, lng: 20.1372057 },
    { lat: 49.0854664, lng: 20.1371491 },
    { lat: 49.0861014, lng: 20.1368365 },
    { lat: 49.0866684, lng: 20.1357588 },
    { lat: 49.0868926, lng: 20.1357867 },
    { lat: 49.0874952, lng: 20.1355687 },
    { lat: 49.0868506, lng: 20.1346049 },
    { lat: 49.0863885, lng: 20.1339037 },
    { lat: 49.0856942, lng: 20.1322131 },
    { lat: 49.0856444, lng: 20.131843 },
    { lat: 49.0857294, lng: 20.1314941 },
    { lat: 49.0857339, lng: 20.1310719 },
    { lat: 49.0854807, lng: 20.1296013 },
    { lat: 49.0852075, lng: 20.1286079 },
    { lat: 49.0842825, lng: 20.1289618 },
    { lat: 49.0837001, lng: 20.1290975 },
    { lat: 49.0835488, lng: 20.1292816 },
    { lat: 49.0828807, lng: 20.1296096 },
    { lat: 49.0827834, lng: 20.1301289 },
    { lat: 49.0826746, lng: 20.1297489 },
    { lat: 49.0826381, lng: 20.1291548 },
    { lat: 49.083303, lng: 20.1265159 },
    { lat: 49.083483, lng: 20.1256196 },
    { lat: 49.0849751, lng: 20.1216908 },
    { lat: 49.0854739, lng: 20.1201828 },
    { lat: 49.0862169, lng: 20.1192285 },
    { lat: 49.0867275, lng: 20.1184038 },
    { lat: 49.0872608, lng: 20.1171383 },
    { lat: 49.0885212, lng: 20.1151954 },
    { lat: 49.089846, lng: 20.1137609 },
    { lat: 49.090117, lng: 20.1133614 },
    { lat: 49.0903957, lng: 20.1131505 },
    { lat: 49.0906074, lng: 20.1128184 },
    { lat: 49.0913146, lng: 20.1122345 },
    { lat: 49.0918646, lng: 20.1118832 },
    { lat: 49.0927149, lng: 20.1118252 },
    { lat: 49.0933998, lng: 20.1112739 },
    { lat: 49.0932285, lng: 20.1106637 },
    { lat: 49.0929621, lng: 20.1107872 },
    { lat: 49.0927604, lng: 20.1105596 },
    { lat: 49.0921628, lng: 20.1100991 },
    { lat: 49.0921972, lng: 20.1100581 },
    { lat: 49.0916576, lng: 20.1096341 },
    { lat: 49.0914937, lng: 20.1097318 },
    { lat: 49.091079, lng: 20.1092825 },
    { lat: 49.0903028, lng: 20.1090831 },
    { lat: 49.0906642, lng: 20.1073559 },
    { lat: 49.0897544, lng: 20.1078906 },
    { lat: 49.089569, lng: 20.1074564 },
    { lat: 49.0897065, lng: 20.1068434 },
    { lat: 49.0901228, lng: 20.1061689 },
    { lat: 49.0901421, lng: 20.1059239 },
    { lat: 49.0905767, lng: 20.1049512 },
    { lat: 49.0907694, lng: 20.1043715 },
    { lat: 49.0909513, lng: 20.1034402 },
    { lat: 49.0906737, lng: 20.1033463 },
    { lat: 49.0907279, lng: 20.1031288 },
    { lat: 49.091007, lng: 20.1025391 },
    { lat: 49.0910765, lng: 20.1019505 },
    { lat: 49.0907659, lng: 20.1019265 },
    { lat: 49.0907119, lng: 20.101624 },
    { lat: 49.0909003, lng: 20.1014938 },
    { lat: 49.0909357, lng: 20.1013931 },
    { lat: 49.0910216, lng: 20.1013753 },
    { lat: 49.0911392, lng: 20.1011928 },
    { lat: 49.0911777, lng: 20.1012215 },
    { lat: 49.0912556, lng: 20.1010604 },
    { lat: 49.0913774, lng: 20.1010658 },
    { lat: 49.0914967, lng: 20.1007844 },
    { lat: 49.0917094, lng: 20.1005595 },
    { lat: 49.0917085, lng: 20.0994561 },
    { lat: 49.0919339, lng: 20.0983532 },
    { lat: 49.0929788, lng: 20.0966858 },
    { lat: 49.0929903, lng: 20.0964688 },
    { lat: 49.0929094, lng: 20.0963668 },
    { lat: 49.0926682, lng: 20.0962487 },
    { lat: 49.0917449, lng: 20.0959458 },
    { lat: 49.0908806, lng: 20.0958286 },
    { lat: 49.089375, lng: 20.0966295 },
    { lat: 49.0892675, lng: 20.096622 },
    { lat: 49.0891006, lng: 20.0969744 },
    { lat: 49.0885577, lng: 20.0962797 },
    { lat: 49.0881748, lng: 20.096057 },
    { lat: 49.0865569, lng: 20.0954324 },
    { lat: 49.0859712, lng: 20.0942972 },
    { lat: 49.0854777, lng: 20.0937901 },
    { lat: 49.0851228, lng: 20.0931243 },
    { lat: 49.0842932, lng: 20.0924147 },
    { lat: 49.0842504, lng: 20.0923023 },
    { lat: 49.0842428, lng: 20.092284 },
    { lat: 49.0838944, lng: 20.0922705 },
    { lat: 49.0839032, lng: 20.092214 },
    { lat: 49.0831247, lng: 20.0920143 },
    { lat: 49.0823103, lng: 20.0923161 },
    { lat: 49.0816581, lng: 20.0930609 },
    { lat: 49.0811053, lng: 20.0935341 },
    { lat: 49.0809544, lng: 20.0935023 },
    { lat: 49.08007, lng: 20.0936087 },
    { lat: 49.0797618, lng: 20.0935624 },
    { lat: 49.0795563, lng: 20.0934093 },
    { lat: 49.0792291, lng: 20.0936715 },
    { lat: 49.0792421, lng: 20.0937123 },
    { lat: 49.0746999, lng: 20.0973631 },
    { lat: 49.0729414, lng: 20.0996783 },
    { lat: 49.0729536, lng: 20.0997349 },
    { lat: 49.0748451, lng: 20.1034052 },
    { lat: 49.073959, lng: 20.1056564 },
    { lat: 49.0734337, lng: 20.1106144 },
    { lat: 49.073271, lng: 20.1109608 },
    { lat: 49.0725534, lng: 20.1118178 },
    { lat: 49.0724217, lng: 20.1120977 },
    { lat: 49.071855, lng: 20.1124566 },
    { lat: 49.0716365, lng: 20.1143148 },
    { lat: 49.0711752, lng: 20.1151885 },
    { lat: 49.0707271, lng: 20.1170276 },
    { lat: 49.0703739, lng: 20.118148 },
    { lat: 49.0696351, lng: 20.1195142 },
    { lat: 49.0683785, lng: 20.1208561 },
    { lat: 49.0669048, lng: 20.1238977 },
    { lat: 49.0666149, lng: 20.1240581 },
    { lat: 49.0666411, lng: 20.1243451 },
    { lat: 49.066588, lng: 20.1245609 },
    { lat: 49.0666485, lng: 20.1246906 },
    { lat: 49.0666141, lng: 20.1248163 },
    { lat: 49.0665258, lng: 20.1248037 },
    { lat: 49.0664789, lng: 20.1252305 },
    { lat: 49.0663774, lng: 20.1253984 },
    { lat: 49.0661585, lng: 20.1254731 },
    { lat: 49.0660905, lng: 20.1258516 },
    { lat: 49.0660365, lng: 20.125904 },
    { lat: 49.0660872, lng: 20.1261299 },
    { lat: 49.0659393, lng: 20.1263437 },
    { lat: 49.0659146, lng: 20.1264843 },
    { lat: 49.065861, lng: 20.126466 },
    { lat: 49.0657979, lng: 20.1267452 },
    { lat: 49.0656904, lng: 20.1267585 },
    { lat: 49.0655496, lng: 20.1270414 },
    { lat: 49.0655769, lng: 20.1271265 },
    { lat: 49.0655059, lng: 20.1272685 },
    { lat: 49.0654109, lng: 20.1273112 },
    { lat: 49.0654467, lng: 20.1275021 },
    { lat: 49.0653153, lng: 20.1277608 },
    { lat: 49.0652579, lng: 20.1277066 },
    { lat: 49.0651022, lng: 20.1277833 },
    { lat: 49.0650961, lng: 20.1279135 },
    { lat: 49.0651583, lng: 20.1279177 },
    { lat: 49.0650643, lng: 20.1282117 },
    { lat: 49.0647939, lng: 20.1285182 },
    { lat: 49.0646651, lng: 20.1285076 },
    { lat: 49.0646513, lng: 20.1287208 },
    { lat: 49.0647078, lng: 20.1287525 },
    { lat: 49.0646716, lng: 20.1288615 },
    { lat: 49.0645966, lng: 20.1288146 },
    { lat: 49.0642634, lng: 20.1294879 },
    { lat: 49.0644553, lng: 20.1296416 },
    { lat: 49.0648267, lng: 20.1297441 },
    { lat: 49.0653041, lng: 20.1305226 },
    { lat: 49.0656895, lng: 20.1307782 },
    { lat: 49.0658895, lng: 20.1311035 },
    { lat: 49.0655542, lng: 20.1317831 },
    { lat: 49.065489, lng: 20.1320101 },
    { lat: 49.0653436, lng: 20.1326163 },
    { lat: 49.0653565, lng: 20.1331043 },
    { lat: 49.0651433, lng: 20.1340454 },
    { lat: 49.0648447, lng: 20.1359146 },
    { lat: 49.0641841, lng: 20.1380183 },
    { lat: 49.0641696, lng: 20.1395568 },
    { lat: 49.0644074, lng: 20.1407894 },
    { lat: 49.0643753, lng: 20.1413932 },
    { lat: 49.064594, lng: 20.14299 },
    { lat: 49.0648623, lng: 20.1443671 },
    { lat: 49.0651893, lng: 20.1454962 },
    { lat: 49.0655968, lng: 20.15084 },
    { lat: 49.0655819, lng: 20.1519827 },
    { lat: 49.0652332, lng: 20.1523858 },
    { lat: 49.0649137, lng: 20.153707 },
    { lat: 49.0645974, lng: 20.1542453 },
    { lat: 49.0644192, lng: 20.1547719 },
    { lat: 49.0644424, lng: 20.1559379 },
    { lat: 49.0646038, lng: 20.1565378 },
    { lat: 49.0645137, lng: 20.1579356 },
    { lat: 49.0644377, lng: 20.1582311 },
    { lat: 49.0644746, lng: 20.1587843 },
    { lat: 49.0646316, lng: 20.1594161 },
    { lat: 49.0648958, lng: 20.1598243 },
    { lat: 49.0651228, lng: 20.1605405 },
    { lat: 49.0655265, lng: 20.1612048 },
    { lat: 49.0656526, lng: 20.1619882 },
    { lat: 49.0663504, lng: 20.161895 },
    { lat: 49.0667423, lng: 20.1611927 },
    { lat: 49.0670665, lng: 20.161975 },
    { lat: 49.0666265, lng: 20.1629058 },
    { lat: 49.0668467, lng: 20.1632316 },
    { lat: 49.0669499, lng: 20.1640391 },
  ],
  VeľkýSlavkov: [
    { lat: 49.1059535, lng: 20.2245148 },
    { lat: 49.1059455, lng: 20.2245385 },
    { lat: 49.1058966, lng: 20.2245865 },
    { lat: 49.1059318, lng: 20.224653 },
    { lat: 49.1058579, lng: 20.2247766 },
    { lat: 49.105849, lng: 20.2249513 },
    { lat: 49.1057957, lng: 20.2249947 },
    { lat: 49.1058365, lng: 20.225143 },
    { lat: 49.1057696, lng: 20.2251351 },
    { lat: 49.1056395, lng: 20.2254299 },
    { lat: 49.1056406, lng: 20.2256016 },
    { lat: 49.1055875, lng: 20.2255726 },
    { lat: 49.1053503, lng: 20.2259754 },
    { lat: 49.1054756, lng: 20.226269 },
    { lat: 49.1054127, lng: 20.2263017 },
    { lat: 49.105395, lng: 20.2264561 },
    { lat: 49.1053083, lng: 20.2265137 },
    { lat: 49.1053579, lng: 20.2265993 },
    { lat: 49.1052571, lng: 20.2267512 },
    { lat: 49.1052686, lng: 20.2268469 },
    { lat: 49.1052016, lng: 20.2270032 },
    { lat: 49.1052385, lng: 20.2270516 },
    { lat: 49.1051697, lng: 20.2270773 },
    { lat: 49.1051072, lng: 20.2274389 },
    { lat: 49.1048838, lng: 20.2276541 },
    { lat: 49.1048179, lng: 20.2280055 },
    { lat: 49.104859, lng: 20.228078 },
    { lat: 49.1047954, lng: 20.228151 },
    { lat: 49.1048152, lng: 20.2282531 },
    { lat: 49.1047697, lng: 20.2282953 },
    { lat: 49.1047919, lng: 20.2284888 },
    { lat: 49.1046437, lng: 20.228612 },
    { lat: 49.1046861, lng: 20.2286656 },
    { lat: 49.1046231, lng: 20.228749 },
    { lat: 49.1046632, lng: 20.2287957 },
    { lat: 49.1046438, lng: 20.2289851 },
    { lat: 49.1045813, lng: 20.2290055 },
    { lat: 49.1046067, lng: 20.229074 },
    { lat: 49.1045602, lng: 20.2290675 },
    { lat: 49.1045575, lng: 20.229256 },
    { lat: 49.1044318, lng: 20.2294667 },
    { lat: 49.104443, lng: 20.2295778 },
    { lat: 49.1044008, lng: 20.2296488 },
    { lat: 49.10446, lng: 20.2296959 },
    { lat: 49.1043044, lng: 20.2299161 },
    { lat: 49.1042598, lng: 20.2301552 },
    { lat: 49.1041853, lng: 20.230295 },
    { lat: 49.1040978, lng: 20.2303605 },
    { lat: 49.1040873, lng: 20.2306087 },
    { lat: 49.1040117, lng: 20.2305776 },
    { lat: 49.1038676, lng: 20.2308592 },
    { lat: 49.1039042, lng: 20.2310464 },
    { lat: 49.1037596, lng: 20.231093 },
    { lat: 49.103723, lng: 20.2314752 },
    { lat: 49.103626, lng: 20.2315585 },
    { lat: 49.1035149, lng: 20.2318536 },
    { lat: 49.1033637, lng: 20.2318488 },
    { lat: 49.1033266, lng: 20.2320218 },
    { lat: 49.1032509, lng: 20.2320111 },
    { lat: 49.1032531, lng: 20.2320965 },
    { lat: 49.1033278, lng: 20.2321637 },
    { lat: 49.1033097, lng: 20.2322214 },
    { lat: 49.1032578, lng: 20.2322736 },
    { lat: 49.103143, lng: 20.2322474 },
    { lat: 49.1030136, lng: 20.2324789 },
    { lat: 49.1030403, lng: 20.2326942 },
    { lat: 49.1029489, lng: 20.2328894 },
    { lat: 49.1028659, lng: 20.2328744 },
    { lat: 49.1028152, lng: 20.2331015 },
    { lat: 49.1027452, lng: 20.2330684 },
    { lat: 49.1027587, lng: 20.2331753 },
    { lat: 49.1026867, lng: 20.2332358 },
    { lat: 49.1024693, lng: 20.2338609 },
    { lat: 49.1024595, lng: 20.2340735 },
    { lat: 49.1024088, lng: 20.2341291 },
    { lat: 49.1024482, lng: 20.2342707 },
    { lat: 49.1022699, lng: 20.2344064 },
    { lat: 49.1023093, lng: 20.2345188 },
    { lat: 49.1022335, lng: 20.2345941 },
    { lat: 49.1022812, lng: 20.2346609 },
    { lat: 49.1021775, lng: 20.2347426 },
    { lat: 49.1021706, lng: 20.2348837 },
    { lat: 49.1021162, lng: 20.2349584 },
    { lat: 49.1020218, lng: 20.2348826 },
    { lat: 49.1018738, lng: 20.2349828 },
    { lat: 49.1018187, lng: 20.2351535 },
    { lat: 49.1016988, lng: 20.2351145 },
    { lat: 49.1016775, lng: 20.2352031 },
    { lat: 49.1017286, lng: 20.235244 },
    { lat: 49.1011132, lng: 20.2358821 },
    { lat: 49.1005687, lng: 20.2369206 },
    { lat: 49.0999975, lng: 20.2384854 },
    { lat: 49.0984663, lng: 20.2410664 },
    { lat: 49.097283, lng: 20.2437866 },
    { lat: 49.0971782, lng: 20.2443244 },
    { lat: 49.0968929, lng: 20.2472618 },
    { lat: 49.0957815, lng: 20.2511322 },
    { lat: 49.0946144, lng: 20.2540132 },
    { lat: 49.0945036, lng: 20.254226 },
    { lat: 49.0922732, lng: 20.2572766 },
    { lat: 49.0903222, lng: 20.2610443 },
    { lat: 49.0897326, lng: 20.2616602 },
    { lat: 49.0893585, lng: 20.2628302 },
    { lat: 49.0880573, lng: 20.264109 },
    { lat: 49.0879296, lng: 20.2642945 },
    { lat: 49.0866114, lng: 20.2674094 },
    { lat: 49.086011, lng: 20.2683218 },
    { lat: 49.0854461, lng: 20.2703695 },
    { lat: 49.0845991, lng: 20.2714336 },
    { lat: 49.0839278, lng: 20.2730686 },
    { lat: 49.0836359, lng: 20.2734041 },
    { lat: 49.0833542, lng: 20.274322 },
    { lat: 49.0828742, lng: 20.2766423 },
    { lat: 49.08266, lng: 20.2773331 },
    { lat: 49.0814103, lng: 20.2807655 },
    { lat: 49.0813173, lng: 20.2814119 },
    { lat: 49.0811243, lng: 20.2818291 },
    { lat: 49.081003, lng: 20.2822381 },
    { lat: 49.0810347, lng: 20.2831426 },
    { lat: 49.0811368, lng: 20.283938 },
    { lat: 49.0795289, lng: 20.2852965 },
    { lat: 49.0781816, lng: 20.2862025 },
    { lat: 49.077461, lng: 20.2868151 },
    { lat: 49.0773426, lng: 20.2868104 },
    { lat: 49.0773474, lng: 20.2869335 },
    { lat: 49.0763829, lng: 20.2896036 },
    { lat: 49.0768301, lng: 20.2897787 },
    { lat: 49.0768496, lng: 20.2897844 },
    { lat: 49.0783702, lng: 20.2898586 },
    { lat: 49.0785287, lng: 20.2923327 },
    { lat: 49.0785634, lng: 20.2944395 },
    { lat: 49.0791373, lng: 20.2944829 },
    { lat: 49.0803562, lng: 20.2947288 },
    { lat: 49.0805327, lng: 20.2949535 },
    { lat: 49.0812834, lng: 20.2954346 },
    { lat: 49.0825637, lng: 20.2965379 },
    { lat: 49.0835981, lng: 20.2972147 },
    { lat: 49.0848086, lng: 20.2982284 },
    { lat: 49.0851499, lng: 20.2983776 },
    { lat: 49.0857483, lng: 20.2984192 },
    { lat: 49.0861869, lng: 20.2988962 },
    { lat: 49.0875856, lng: 20.2994231 },
    { lat: 49.0890824, lng: 20.2997487 },
    { lat: 49.0924864, lng: 20.301085 },
    { lat: 49.0941121, lng: 20.3014239 },
    { lat: 49.0963911, lng: 20.3017419 },
    { lat: 49.0963938, lng: 20.3017208 },
    { lat: 49.0965145, lng: 20.3008853 },
    { lat: 49.0964755, lng: 20.3008665 },
    { lat: 49.0969433, lng: 20.2982178 },
    { lat: 49.0970777, lng: 20.297767 },
    { lat: 49.0974465, lng: 20.2970287 },
    { lat: 49.0976147, lng: 20.2955676 },
    { lat: 49.099244, lng: 20.2904792 },
    { lat: 49.0992731, lng: 20.2905294 },
    { lat: 49.0996607, lng: 20.2895212 },
    { lat: 49.1007535, lng: 20.2894523 },
    { lat: 49.1020019, lng: 20.2899877 },
    { lat: 49.1025606, lng: 20.2898236 },
    { lat: 49.1030643, lng: 20.2892519 },
    { lat: 49.1032966, lng: 20.2888317 },
    { lat: 49.1037031, lng: 20.2878192 },
    { lat: 49.1037334, lng: 20.2875861 },
    { lat: 49.1039415, lng: 20.2869998 },
    { lat: 49.1040015, lng: 20.286317 },
    { lat: 49.1041783, lng: 20.28557 },
    { lat: 49.1042857, lng: 20.2853166 },
    { lat: 49.104583, lng: 20.2851172 },
    { lat: 49.1047267, lng: 20.2849058 },
    { lat: 49.1050151, lng: 20.2842727 },
    { lat: 49.1052377, lng: 20.283628 },
    { lat: 49.1054493, lng: 20.2829619 },
    { lat: 49.1054911, lng: 20.2825625 },
    { lat: 49.1056753, lng: 20.2819438 },
    { lat: 49.1056841, lng: 20.2816792 },
    { lat: 49.1057899, lng: 20.2814461 },
    { lat: 49.1058774, lng: 20.2810353 },
    { lat: 49.1059307, lng: 20.2797237 },
    { lat: 49.1060997, lng: 20.2785415 },
    { lat: 49.1064266, lng: 20.2775706 },
    { lat: 49.1066764, lng: 20.2770742 },
    { lat: 49.1068562, lng: 20.2763736 },
    { lat: 49.1074521, lng: 20.2752849 },
    { lat: 49.1077538, lng: 20.2745092 },
    { lat: 49.1080721, lng: 20.2738934 },
    { lat: 49.10878, lng: 20.2734763 },
    { lat: 49.1090194, lng: 20.2732083 },
    { lat: 49.1092482, lng: 20.2722966 },
    { lat: 49.1098002, lng: 20.2710025 },
    { lat: 49.1101371, lng: 20.2704037 },
    { lat: 49.1104182, lng: 20.2694472 },
    { lat: 49.1107152, lng: 20.2690367 },
    { lat: 49.1109071, lng: 20.2689895 },
    { lat: 49.1110705, lng: 20.2687392 },
    { lat: 49.1112115, lng: 20.2690014 },
    { lat: 49.1112988, lng: 20.2689586 },
    { lat: 49.1113477, lng: 20.2687679 },
    { lat: 49.1112624, lng: 20.2688059 },
    { lat: 49.111243, lng: 20.2686861 },
    { lat: 49.1111594, lng: 20.2686459 },
    { lat: 49.1112087, lng: 20.2685563 },
    { lat: 49.1113061, lng: 20.2685974 },
    { lat: 49.1112732, lng: 20.2684796 },
    { lat: 49.1113503, lng: 20.2684367 },
    { lat: 49.1113564, lng: 20.2683585 },
    { lat: 49.1114071, lng: 20.2683319 },
    { lat: 49.1114502, lng: 20.2683967 },
    { lat: 49.111488, lng: 20.2682944 },
    { lat: 49.1115289, lng: 20.2683083 },
    { lat: 49.1115404, lng: 20.2683992 },
    { lat: 49.1117036, lng: 20.2684552 },
    { lat: 49.1117416, lng: 20.2683069 },
    { lat: 49.112197, lng: 20.2683482 },
    { lat: 49.1124138, lng: 20.2682131 },
    { lat: 49.112495, lng: 20.2680462 },
    { lat: 49.1124643, lng: 20.2679239 },
    { lat: 49.1125875, lng: 20.2678469 },
    { lat: 49.1127213, lng: 20.2679318 },
    { lat: 49.1130974, lng: 20.2676169 },
    { lat: 49.1131654, lng: 20.2676844 },
    { lat: 49.1132245, lng: 20.2674662 },
    { lat: 49.1133056, lng: 20.2673546 },
    { lat: 49.1133483, lng: 20.2673482 },
    { lat: 49.1133704, lng: 20.2674857 },
    { lat: 49.1134344, lng: 20.2675101 },
    { lat: 49.1136515, lng: 20.2673304 },
    { lat: 49.1137106, lng: 20.2672538 },
    { lat: 49.1140394, lng: 20.2669145 },
    { lat: 49.1142418, lng: 20.2671063 },
    { lat: 49.1145259, lng: 20.2670572 },
    { lat: 49.1147668, lng: 20.2673713 },
    { lat: 49.1149531, lng: 20.2673249 },
    { lat: 49.1152201, lng: 20.2671598 },
    { lat: 49.1153122, lng: 20.2668908 },
    { lat: 49.115443, lng: 20.2667552 },
    { lat: 49.1157079, lng: 20.266788 },
    { lat: 49.1161156, lng: 20.2663886 },
    { lat: 49.1165582, lng: 20.2663339 },
    { lat: 49.1167169, lng: 20.2661862 },
    { lat: 49.1168029, lng: 20.2662553 },
    { lat: 49.1169752, lng: 20.2661961 },
    { lat: 49.1171779, lng: 20.2659667 },
    { lat: 49.117212, lng: 20.2657479 },
    { lat: 49.1174875, lng: 20.2654503 },
    { lat: 49.1178701, lng: 20.2643497 },
    { lat: 49.1178742, lng: 20.2639668 },
    { lat: 49.1179246, lng: 20.263876 },
    { lat: 49.117867, lng: 20.2638133 },
    { lat: 49.1179427, lng: 20.2634451 },
    { lat: 49.118074, lng: 20.2632617 },
    { lat: 49.1180885, lng: 20.2630828 },
    { lat: 49.1179946, lng: 20.2627599 },
    { lat: 49.1180662, lng: 20.2625456 },
    { lat: 49.1180499, lng: 20.2623041 },
    { lat: 49.1183192, lng: 20.2619017 },
    { lat: 49.1182359, lng: 20.261589 },
    { lat: 49.1182469, lng: 20.2614679 },
    { lat: 49.1183066, lng: 20.2614126 },
    { lat: 49.1182957, lng: 20.2611881 },
    { lat: 49.1183793, lng: 20.2611658 },
    { lat: 49.1183974, lng: 20.2610854 },
    { lat: 49.1183801, lng: 20.2609292 },
    { lat: 49.1182991, lng: 20.2608481 },
    { lat: 49.118353, lng: 20.2604952 },
    { lat: 49.1182949, lng: 20.2602532 },
    { lat: 49.1184076, lng: 20.2603116 },
    { lat: 49.1185117, lng: 20.2602112 },
    { lat: 49.1185594, lng: 20.2600778 },
    { lat: 49.1185256, lng: 20.2595305 },
    { lat: 49.1185507, lng: 20.2591349 },
    { lat: 49.1186186, lng: 20.2590849 },
    { lat: 49.1186688, lng: 20.2589034 },
    { lat: 49.1187233, lng: 20.2589995 },
    { lat: 49.1187566, lng: 20.2588433 },
    { lat: 49.1189505, lng: 20.2586801 },
    { lat: 49.1189993, lng: 20.2584333 },
    { lat: 49.1190887, lng: 20.2583529 },
    { lat: 49.1190866, lng: 20.258131 },
    { lat: 49.1192082, lng: 20.2579826 },
    { lat: 49.1192496, lng: 20.2578066 },
    { lat: 49.1192179, lng: 20.2576905 },
    { lat: 49.1191735, lng: 20.2576776 },
    { lat: 49.1193034, lng: 20.257169 },
    { lat: 49.1192767, lng: 20.2570884 },
    { lat: 49.1196373, lng: 20.2563822 },
    { lat: 49.1196621, lng: 20.2561833 },
    { lat: 49.119596, lng: 20.2561041 },
    { lat: 49.1196863, lng: 20.2558805 },
    { lat: 49.1197316, lng: 20.2559152 },
    { lat: 49.119807, lng: 20.2556858 },
    { lat: 49.1198163, lng: 20.25558 },
    { lat: 49.1197312, lng: 20.2555328 },
    { lat: 49.1198368, lng: 20.2551212 },
    { lat: 49.1199009, lng: 20.2545797 },
    { lat: 49.1200107, lng: 20.2541912 },
    { lat: 49.1205219, lng: 20.2537311 },
    { lat: 49.1205837, lng: 20.2534106 },
    { lat: 49.1207722, lng: 20.2530511 },
    { lat: 49.1208786, lng: 20.2525504 },
    { lat: 49.1210272, lng: 20.2524052 },
    { lat: 49.1211663, lng: 20.2519874 },
    { lat: 49.1213442, lng: 20.2518198 },
    { lat: 49.1215574, lng: 20.2515106 },
    { lat: 49.1215981, lng: 20.2513742 },
    { lat: 49.1217858, lng: 20.251305 },
    { lat: 49.1220131, lng: 20.25096 },
    { lat: 49.1220742, lng: 20.2510256 },
    { lat: 49.1221553, lng: 20.2509085 },
    { lat: 49.1223025, lng: 20.2508426 },
    { lat: 49.1224951, lng: 20.2504173 },
    { lat: 49.1225685, lng: 20.2503923 },
    { lat: 49.1226586, lng: 20.2502489 },
    { lat: 49.1228794, lng: 20.2501921 },
    { lat: 49.1229544, lng: 20.2500946 },
    { lat: 49.1231161, lng: 20.2501166 },
    { lat: 49.1232624, lng: 20.2500554 },
    { lat: 49.1234326, lng: 20.2498288 },
    { lat: 49.1236367, lng: 20.2497221 },
    { lat: 49.1238601, lng: 20.2493893 },
    { lat: 49.1239104, lng: 20.2491809 },
    { lat: 49.1236376, lng: 20.2484547 },
    { lat: 49.1234524, lng: 20.247955 },
    { lat: 49.1233799, lng: 20.2479436 },
    { lat: 49.1231256, lng: 20.2473743 },
    { lat: 49.1230964, lng: 20.2474061 },
    { lat: 49.122883, lng: 20.2472778 },
    { lat: 49.1219246, lng: 20.2478507 },
    { lat: 49.1217662, lng: 20.2472008 },
    { lat: 49.120627, lng: 20.2453775 },
    { lat: 49.1200025, lng: 20.245789 },
    { lat: 49.1194679, lng: 20.2442201 },
    { lat: 49.1192485, lng: 20.2433683 },
    { lat: 49.1191124, lng: 20.2430583 },
    { lat: 49.1187727, lng: 20.2433546 },
    { lat: 49.1183608, lng: 20.2427039 },
    { lat: 49.1182306, lng: 20.2423994 },
    { lat: 49.1174654, lng: 20.2428378 },
    { lat: 49.1171812, lng: 20.2423698 },
    { lat: 49.1166176, lng: 20.2424724 },
    { lat: 49.1161295, lng: 20.2416553 },
    { lat: 49.1149914, lng: 20.2426365 },
    { lat: 49.114862, lng: 20.2426123 },
    { lat: 49.1146008, lng: 20.2429295 },
    { lat: 49.1142798, lng: 20.2431537 },
    { lat: 49.1140389, lng: 20.2432695 },
    { lat: 49.1137443, lng: 20.2431692 },
    { lat: 49.1135269, lng: 20.2433659 },
    { lat: 49.1132766, lng: 20.2427554 },
    { lat: 49.1134623, lng: 20.2425522 },
    { lat: 49.1135781, lng: 20.2426944 },
    { lat: 49.1138351, lng: 20.2423597 },
    { lat: 49.1140973, lng: 20.2421225 },
    { lat: 49.113726, lng: 20.2410152 },
    { lat: 49.1135943, lng: 20.2407343 },
    { lat: 49.1135435, lng: 20.2407856 },
    { lat: 49.1138179, lng: 20.2402936 },
    { lat: 49.113264, lng: 20.2393522 },
    { lat: 49.1133458, lng: 20.2389126 },
    { lat: 49.1125586, lng: 20.2385946 },
    { lat: 49.1122895, lng: 20.2389093 },
    { lat: 49.1122785, lng: 20.239002 },
    { lat: 49.1119882, lng: 20.2386342 },
    { lat: 49.1117514, lng: 20.2387989 },
    { lat: 49.111408, lng: 20.2392897 },
    { lat: 49.1105799, lng: 20.2382742 },
    { lat: 49.1096533, lng: 20.2379114 },
    { lat: 49.1072371, lng: 20.2406605 },
    { lat: 49.1071712, lng: 20.2406468 },
    { lat: 49.1071332, lng: 20.2401093 },
    { lat: 49.1070746, lng: 20.2400518 },
    { lat: 49.1070611, lng: 20.2398248 },
    { lat: 49.107002, lng: 20.2397242 },
    { lat: 49.1070267, lng: 20.2395796 },
    { lat: 49.1069496, lng: 20.2393347 },
    { lat: 49.1067888, lng: 20.2390881 },
    { lat: 49.1066749, lng: 20.2387118 },
    { lat: 49.1065823, lng: 20.2378483 },
    { lat: 49.1076146, lng: 20.2338963 },
    { lat: 49.1076438, lng: 20.2321853 },
    { lat: 49.1074024, lng: 20.2316902 },
    { lat: 49.1073971, lng: 20.2315261 },
    { lat: 49.1076619, lng: 20.2311011 },
    { lat: 49.1078228, lng: 20.2306177 },
    { lat: 49.1079371, lng: 20.2300577 },
    { lat: 49.1067697, lng: 20.2284441 },
    { lat: 49.1063077, lng: 20.2281813 },
    { lat: 49.106439, lng: 20.2280834 },
    { lat: 49.1063853, lng: 20.2280082 },
    { lat: 49.1063875, lng: 20.2278606 },
    { lat: 49.1064629, lng: 20.2277186 },
    { lat: 49.1064288, lng: 20.2271376 },
    { lat: 49.1066232, lng: 20.2270181 },
    { lat: 49.1067584, lng: 20.2268188 },
    { lat: 49.1067824, lng: 20.2263156 },
    { lat: 49.1071514, lng: 20.2257347 },
    { lat: 49.1059535, lng: 20.2245148 },
  ],
  Svit: [
    { lat: 49.0670638, lng: 20.1652868 },
    { lat: 49.0668327, lng: 20.1654859 },
    { lat: 49.066678, lng: 20.1659038 },
    { lat: 49.0663277, lng: 20.1664083 },
    { lat: 49.0656323, lng: 20.1669566 },
    { lat: 49.0652656, lng: 20.1675289 },
    { lat: 49.0646147, lng: 20.1690244 },
    { lat: 49.0643108, lng: 20.1695909 },
    { lat: 49.0636282, lng: 20.1704289 },
    { lat: 49.0634877, lng: 20.1710811 },
    { lat: 49.0632697, lng: 20.1711775 },
    { lat: 49.0630122, lng: 20.1715834 },
    { lat: 49.0627009, lng: 20.1721308 },
    { lat: 49.0626098, lng: 20.172502 },
    { lat: 49.0624859, lng: 20.1722488 },
    { lat: 49.0614336, lng: 20.1709972 },
    { lat: 49.0613931, lng: 20.1708271 },
    { lat: 49.0614854, lng: 20.1707697 },
    { lat: 49.0610676, lng: 20.1692819 },
    { lat: 49.0608356, lng: 20.1686348 },
    { lat: 49.0606309, lng: 20.168248 },
    { lat: 49.0602281, lng: 20.1676351 },
    { lat: 49.0600746, lng: 20.1674913 },
    { lat: 49.0595305, lng: 20.1672057 },
    { lat: 49.0589525, lng: 20.1675634 },
    { lat: 49.0581468, lng: 20.1686763 },
    { lat: 49.0581583, lng: 20.1688207 },
    { lat: 49.0582948, lng: 20.1690242 },
    { lat: 49.0584377, lng: 20.170653 },
    { lat: 49.057262, lng: 20.1709886 },
    { lat: 49.0572753, lng: 20.1711787 },
    { lat: 49.0571875, lng: 20.1711793 },
    { lat: 49.0569356, lng: 20.1707097 },
    { lat: 49.0567316, lng: 20.170484 },
    { lat: 49.0565197, lng: 20.170536 },
    { lat: 49.0564591, lng: 20.170598 },
    { lat: 49.0565462, lng: 20.1708043 },
    { lat: 49.0565318, lng: 20.170862 },
    { lat: 49.0559181, lng: 20.1714306 },
    { lat: 49.0559358, lng: 20.1715614 },
    { lat: 49.0561275, lng: 20.1718234 },
    { lat: 49.0560173, lng: 20.1721653 },
    { lat: 49.0560137, lng: 20.1723283 },
    { lat: 49.0558428, lng: 20.1727804 },
    { lat: 49.0557642, lng: 20.1728753 },
    { lat: 49.0554758, lng: 20.1736866 },
    { lat: 49.0555247, lng: 20.1744839 },
    { lat: 49.0555721, lng: 20.1744892 },
    { lat: 49.055378, lng: 20.1758023 },
    { lat: 49.0552501, lng: 20.1757024 },
    { lat: 49.0552108, lng: 20.1759578 },
    { lat: 49.0549544, lng: 20.1764156 },
    { lat: 49.0546964, lng: 20.1762715 },
    { lat: 49.0546901, lng: 20.1767636 },
    { lat: 49.0544956, lng: 20.1774168 },
    { lat: 49.0542194, lng: 20.1778337 },
    { lat: 49.0542384, lng: 20.1789296 },
    { lat: 49.053927, lng: 20.180153 },
    { lat: 49.053902, lng: 20.1808281 },
    { lat: 49.0539379, lng: 20.1810463 },
    { lat: 49.0540441, lng: 20.1814236 },
    { lat: 49.0544758, lng: 20.1824723 },
    { lat: 49.0547356, lng: 20.1837653 },
    { lat: 49.055012, lng: 20.1847907 },
    { lat: 49.0546629, lng: 20.1856398 },
    { lat: 49.0546725, lng: 20.1857082 },
    { lat: 49.0546358, lng: 20.1856922 },
    { lat: 49.0540123, lng: 20.186916 },
    { lat: 49.0535547, lng: 20.1878674 },
    { lat: 49.05342, lng: 20.1889217 },
    { lat: 49.0538604, lng: 20.1894326 },
    { lat: 49.0544985, lng: 20.1905503 },
    { lat: 49.0550892, lng: 20.1921186 },
    { lat: 49.0553501, lng: 20.1923897 },
    { lat: 49.0556808, lng: 20.1924917 },
    { lat: 49.0557752, lng: 20.1924622 },
    { lat: 49.0558847, lng: 20.1927547 },
    { lat: 49.0558782, lng: 20.193295 },
    { lat: 49.0558149, lng: 20.1936007 },
    { lat: 49.0555416, lng: 20.1943117 },
    { lat: 49.0553247, lng: 20.1946947 },
    { lat: 49.0551726, lng: 20.1951719 },
    { lat: 49.055192, lng: 20.1954882 },
    { lat: 49.0551269, lng: 20.1957488 },
    { lat: 49.054959, lng: 20.1961125 },
    { lat: 49.054843, lng: 20.1966259 },
    { lat: 49.0549099, lng: 20.1969425 },
    { lat: 49.0548651, lng: 20.1974874 },
    { lat: 49.0547932, lng: 20.1977227 },
    { lat: 49.0546834, lng: 20.1993453 },
    { lat: 49.0546632, lng: 20.2012968 },
    { lat: 49.0544718, lng: 20.2016706 },
    { lat: 49.0542219, lng: 20.201759 },
    { lat: 49.0539862, lng: 20.2027099 },
    { lat: 49.0536039, lng: 20.2028115 },
    { lat: 49.0541545, lng: 20.2036853 },
    { lat: 49.0539603, lng: 20.2037553 },
    { lat: 49.0537826, lng: 20.2037074 },
    { lat: 49.0533171, lng: 20.2052295 },
    { lat: 49.0535982, lng: 20.2054463 },
    { lat: 49.0534745, lng: 20.2056469 },
    { lat: 49.0533538, lng: 20.2056655 },
    { lat: 49.0530754, lng: 20.2054352 },
    { lat: 49.0529499, lng: 20.2058463 },
    { lat: 49.0528418, lng: 20.2065007 },
    { lat: 49.0522233, lng: 20.2067199 },
    { lat: 49.0524344, lng: 20.207644 },
    { lat: 49.0526104, lng: 20.2079323 },
    { lat: 49.0527732, lng: 20.2078365 },
    { lat: 49.0528699, lng: 20.2081447 },
    { lat: 49.0532057, lng: 20.2079799 },
    { lat: 49.0532051, lng: 20.2080463 },
    { lat: 49.0529236, lng: 20.2081893 },
    { lat: 49.0529205, lng: 20.2082549 },
    { lat: 49.0537385, lng: 20.2077833 },
    { lat: 49.0537367, lng: 20.2078793 },
    { lat: 49.0534526, lng: 20.208355 },
    { lat: 49.0529897, lng: 20.2094563 },
    { lat: 49.0534614, lng: 20.2098783 },
    { lat: 49.0528693, lng: 20.2112202 },
    { lat: 49.0529414, lng: 20.2113058 },
    { lat: 49.052715, lng: 20.2121549 },
    { lat: 49.0528172, lng: 20.2142179 },
    { lat: 49.0530469, lng: 20.2146736 },
    { lat: 49.0531018, lng: 20.2151169 },
    { lat: 49.0530717, lng: 20.2160363 },
    { lat: 49.0531066, lng: 20.2165175 },
    { lat: 49.0532083, lng: 20.2169519 },
    { lat: 49.0533432, lng: 20.217258 },
    { lat: 49.0532531, lng: 20.2180823 },
    { lat: 49.0531249, lng: 20.2185145 },
    { lat: 49.0530685, lng: 20.2201877 },
    { lat: 49.0529946, lng: 20.2205988 },
    { lat: 49.0530706, lng: 20.2210618 },
    { lat: 49.0530727, lng: 20.2215739 },
    { lat: 49.0528887, lng: 20.2224471 },
    { lat: 49.0526933, lng: 20.2230524 },
    { lat: 49.0531643, lng: 20.2230595 },
    { lat: 49.0532496, lng: 20.2237367 },
    { lat: 49.0534812, lng: 20.2244414 },
    { lat: 49.0536638, lng: 20.2243582 },
    { lat: 49.0543122, lng: 20.2240705 },
    { lat: 49.0548128, lng: 20.2237449 },
    { lat: 49.05509, lng: 20.2237719 },
    { lat: 49.0551073, lng: 20.2238386 },
    { lat: 49.0557389, lng: 20.2238669 },
    { lat: 49.0558273, lng: 20.2237406 },
    { lat: 49.0559961, lng: 20.2237345 },
    { lat: 49.0584563, lng: 20.2242106 },
    { lat: 49.06072, lng: 20.2247299 },
    { lat: 49.0607356, lng: 20.2248384 },
    { lat: 49.0621828, lng: 20.2246465 },
    { lat: 49.0621866, lng: 20.2247431 },
    { lat: 49.0623582, lng: 20.2247206 },
    { lat: 49.062249, lng: 20.2223071 },
    { lat: 49.0619431, lng: 20.2194153 },
    { lat: 49.0619399, lng: 20.2193941 },
    { lat: 49.0618041, lng: 20.2183476 },
    { lat: 49.0619095, lng: 20.2168886 },
    { lat: 49.0622059, lng: 20.2145078 },
    { lat: 49.0624182, lng: 20.2119711 },
    { lat: 49.0629566, lng: 20.2078318 },
    { lat: 49.0633917, lng: 20.2055211 },
    { lat: 49.0636312, lng: 20.2031041 },
    { lat: 49.0638399, lng: 20.2018065 },
    { lat: 49.064766, lng: 20.1980567 },
    { lat: 49.0654517, lng: 20.194547 },
    { lat: 49.0656775, lng: 20.1930882 },
    { lat: 49.0661141, lng: 20.1922596 },
    { lat: 49.0663599, lng: 20.1907426 },
    { lat: 49.0668476, lng: 20.1893023 },
    { lat: 49.0671282, lng: 20.1884189 },
    { lat: 49.0665526, lng: 20.1867104 },
    { lat: 49.0653979, lng: 20.1843265 },
    { lat: 49.0646876, lng: 20.1811475 },
    { lat: 49.0641553, lng: 20.1805927 },
    { lat: 49.0636334, lng: 20.1792839 },
    { lat: 49.0642656, lng: 20.178027 },
    { lat: 49.0647899, lng: 20.1771413 },
    { lat: 49.0647989, lng: 20.1769178 },
    { lat: 49.064468, lng: 20.17554 },
    { lat: 49.0645837, lng: 20.1751414 },
    { lat: 49.0647722, lng: 20.1749282 },
    { lat: 49.0650124, lng: 20.1748212 },
    { lat: 49.0651291, lng: 20.1745292 },
    { lat: 49.065107, lng: 20.1743136 },
    { lat: 49.0649667, lng: 20.1740451 },
    { lat: 49.0650263, lng: 20.1738463 },
    { lat: 49.0652227, lng: 20.1736306 },
    { lat: 49.0652503, lng: 20.1735354 },
    { lat: 49.0651357, lng: 20.1729775 },
    { lat: 49.0651535, lng: 20.1727493 },
    { lat: 49.0652998, lng: 20.1727447 },
    { lat: 49.0654919, lng: 20.1729337 },
    { lat: 49.0656769, lng: 20.172908 },
    { lat: 49.0657609, lng: 20.1727856 },
    { lat: 49.0660635, lng: 20.1719614 },
    { lat: 49.0661718, lng: 20.1705058 },
    { lat: 49.0661125, lng: 20.1698077 },
    { lat: 49.0659047, lng: 20.1689493 },
    { lat: 49.0659599, lng: 20.1683602 },
    { lat: 49.0661407, lng: 20.1681542 },
    { lat: 49.0662923, lng: 20.1680877 },
    { lat: 49.0664039, lng: 20.1681739 },
    { lat: 49.06651, lng: 20.1684313 },
    { lat: 49.0665662, lng: 20.1684268 },
    { lat: 49.0666198, lng: 20.1683022 },
    { lat: 49.0666852, lng: 20.1680799 },
    { lat: 49.0666517, lng: 20.1676672 },
    { lat: 49.0665898, lng: 20.1673888 },
    { lat: 49.0663758, lng: 20.1672736 },
    { lat: 49.0663543, lng: 20.167049 },
    { lat: 49.0664924, lng: 20.1665833 },
    { lat: 49.0669883, lng: 20.1659515 },
    { lat: 49.0670808, lng: 20.1654204 },
    { lat: 49.0670638, lng: 20.1652868 },
  ],
  Hozelec: [
    { lat: 49.0515364, lng: 20.3706787 },
    { lat: 49.0514967, lng: 20.3705846 },
    { lat: 49.0512394, lng: 20.3690423 },
    { lat: 49.0511126, lng: 20.3685248 },
    { lat: 49.0508217, lng: 20.3677806 },
    { lat: 49.0504155, lng: 20.3669641 },
    { lat: 49.0501452, lng: 20.3667264 },
    { lat: 49.0488439, lng: 20.3659137 },
    { lat: 49.0484524, lng: 20.3653659 },
    { lat: 49.0478981, lng: 20.3643932 },
    { lat: 49.0479906, lng: 20.3630464 },
    { lat: 49.0479776, lng: 20.3625597 },
    { lat: 49.0474736, lng: 20.3595549 },
    { lat: 49.0455353, lng: 20.3555291 },
    { lat: 49.0441235, lng: 20.3539814 },
    { lat: 49.0438499, lng: 20.3537456 },
    { lat: 49.0423417, lng: 20.3529172 },
    { lat: 49.0390908, lng: 20.3518888 },
    { lat: 49.0380262, lng: 20.3506447 },
    { lat: 49.037827, lng: 20.3503006 },
    { lat: 49.0359663, lng: 20.345463 },
    { lat: 49.0358849, lng: 20.3449 },
    { lat: 49.0360184, lng: 20.344245 },
    { lat: 49.0369212, lng: 20.3426909 },
    { lat: 49.0384152, lng: 20.3398835 },
    { lat: 49.036233, lng: 20.3372895 },
    { lat: 49.0350128, lng: 20.3360729 },
    { lat: 49.033611, lng: 20.3345349 },
    { lat: 49.0323411, lng: 20.3334304 },
    { lat: 49.030839, lng: 20.3323799 },
    { lat: 49.0301645, lng: 20.3315951 },
    { lat: 49.0287422, lng: 20.3310951 },
    { lat: 49.028725, lng: 20.3310869 },
    { lat: 49.0275891, lng: 20.330545 },
    { lat: 49.0272392, lng: 20.3303781 },
    { lat: 49.0273289, lng: 20.329989 },
    { lat: 49.0262977, lng: 20.3293841 },
    { lat: 49.0261632, lng: 20.3299182 },
    { lat: 49.0259115, lng: 20.3297323 },
    { lat: 49.0257484, lng: 20.3294143 },
    { lat: 49.0256168, lng: 20.3293055 },
    { lat: 49.0251686, lng: 20.3291249 },
    { lat: 49.0247928, lng: 20.3288471 },
    { lat: 49.0226199, lng: 20.3280065 },
    { lat: 49.0198658, lng: 20.3266472 },
    { lat: 49.0179799, lng: 20.3253412 },
    { lat: 49.0174256, lng: 20.3247264 },
    { lat: 49.0168226, lng: 20.3241616 },
    { lat: 49.0157123, lng: 20.3233739 },
    { lat: 49.0134483, lng: 20.3221404 },
    { lat: 49.0121491, lng: 20.321067 },
    { lat: 49.0107455, lng: 20.3197443 },
    { lat: 49.0090913, lng: 20.3194568 },
    { lat: 49.0090742, lng: 20.3194548 },
    { lat: 49.0089726, lng: 20.3199054 },
    { lat: 49.008925, lng: 20.320886 },
    { lat: 49.0088176, lng: 20.3216048 },
    { lat: 49.0086797, lng: 20.3220827 },
    { lat: 49.008424, lng: 20.3233861 },
    { lat: 49.0085107, lng: 20.3239684 },
    { lat: 49.0087387, lng: 20.324567 },
    { lat: 49.0088345, lng: 20.3250585 },
    { lat: 49.0089264, lng: 20.3253368 },
    { lat: 49.0097479, lng: 20.325236 },
    { lat: 49.0104433, lng: 20.3253696 },
    { lat: 49.0113129, lng: 20.3258183 },
    { lat: 49.0115049, lng: 20.3272836 },
    { lat: 49.0114861, lng: 20.3279321 },
    { lat: 49.010897, lng: 20.3295566 },
    { lat: 49.010439, lng: 20.3304936 },
    { lat: 49.0101856, lng: 20.3311899 },
    { lat: 49.0105933, lng: 20.3317732 },
    { lat: 49.0115659, lng: 20.3338345 },
    { lat: 49.0121147, lng: 20.3342242 },
    { lat: 49.0128185, lng: 20.3349246 },
    { lat: 49.0131519, lng: 20.3350484 },
    { lat: 49.0135174, lng: 20.335297 },
    { lat: 49.0146281, lng: 20.3355661 },
    { lat: 49.0150424, lng: 20.3368173 },
    { lat: 49.0156999, lng: 20.3377494 },
    { lat: 49.0171291, lng: 20.3380182 },
    { lat: 49.0174473, lng: 20.338149 },
    { lat: 49.0177874, lng: 20.3386231 },
    { lat: 49.0184133, lng: 20.3392868 },
    { lat: 49.0189399, lng: 20.3397435 },
    { lat: 49.0197766, lng: 20.3402371 },
    { lat: 49.0200729, lng: 20.3405467 },
    { lat: 49.0219014, lng: 20.3424569 },
    { lat: 49.0224695, lng: 20.3429082 },
    { lat: 49.0229984, lng: 20.3431914 },
    { lat: 49.0243185, lng: 20.344286 },
    { lat: 49.0247618, lng: 20.3447732 },
    { lat: 49.0252555, lng: 20.345192 },
    { lat: 49.0266569, lng: 20.3458223 },
    { lat: 49.0269879, lng: 20.3459076 },
    { lat: 49.028626, lng: 20.3460214 },
    { lat: 49.0290523, lng: 20.3462137 },
    { lat: 49.030164, lng: 20.3464566 },
    { lat: 49.0304794, lng: 20.3466628 },
    { lat: 49.0305979, lng: 20.3467875 },
    { lat: 49.0304361, lng: 20.3472049 },
    { lat: 49.0325232, lng: 20.3490941 },
    { lat: 49.0328681, lng: 20.3491581 },
    { lat: 49.0336422, lng: 20.3500501 },
    { lat: 49.0346349, lng: 20.3513828 },
    { lat: 49.0367046, lng: 20.3538997 },
    { lat: 49.0402933, lng: 20.3576261 },
    { lat: 49.0416626, lng: 20.3597328 },
    { lat: 49.0427984, lng: 20.361777 },
    { lat: 49.0439318, lng: 20.3635876 },
    { lat: 49.0453959, lng: 20.3655314 },
    { lat: 49.0458906, lng: 20.366359 },
    { lat: 49.0469006, lng: 20.3675052 },
    { lat: 49.049322, lng: 20.3706034 },
    { lat: 49.049917, lng: 20.3712313 },
    { lat: 49.0506932, lng: 20.3717411 },
    { lat: 49.0509154, lng: 20.3716948 },
    { lat: 49.0513916, lng: 20.3711906 },
    { lat: 49.0515152, lng: 20.3707477 },
    { lat: 49.0515364, lng: 20.3706787 },
  ],
  Gerlachov: [
    { lat: 49.0942585, lng: 20.1940491 },
    { lat: 49.0941857, lng: 20.1942297 },
    { lat: 49.0939169, lng: 20.1947734 },
    { lat: 49.0936694, lng: 20.1950356 },
    { lat: 49.0934408, lng: 20.197047 },
    { lat: 49.0933519, lng: 20.1975291 },
    { lat: 49.0925371, lng: 20.2004562 },
    { lat: 49.0921574, lng: 20.2015885 },
    { lat: 49.0929126, lng: 20.2019472 },
    { lat: 49.0936453, lng: 20.2023871 },
    { lat: 49.093393, lng: 20.203378 },
    { lat: 49.0932027, lng: 20.2044261 },
    { lat: 49.092481, lng: 20.2070688 },
    { lat: 49.0904989, lng: 20.2064687 },
    { lat: 49.0899124, lng: 20.2071802 },
    { lat: 49.0897771, lng: 20.207179 },
    { lat: 49.0873682, lng: 20.2092751 },
    { lat: 49.0853836, lng: 20.2116165 },
    { lat: 49.0840098, lng: 20.2141465 },
    { lat: 49.0820836, lng: 20.2158097 },
    { lat: 49.0807805, lng: 20.2182198 },
    { lat: 49.0806407, lng: 20.2182693 },
    { lat: 49.0804186, lng: 20.2186622 },
    { lat: 49.0803905, lng: 20.2190072 },
    { lat: 49.0803501, lng: 20.2190651 },
    { lat: 49.0801739, lng: 20.2192807 },
    { lat: 49.0800329, lng: 20.2193629 },
    { lat: 49.0799929, lng: 20.2194607 },
    { lat: 49.0798304, lng: 20.2195331 },
    { lat: 49.0795798, lng: 20.2194332 },
    { lat: 49.0794878, lng: 20.2194686 },
    { lat: 49.0793892, lng: 20.2196849 },
    { lat: 49.0792543, lng: 20.2198055 },
    { lat: 49.0790094, lng: 20.2197349 },
    { lat: 49.0788231, lng: 20.2197807 },
    { lat: 49.0784373, lng: 20.2200722 },
    { lat: 49.0783198, lng: 20.2203463 },
    { lat: 49.0783234, lng: 20.2205217 },
    { lat: 49.078011, lng: 20.2208269 },
    { lat: 49.077959, lng: 20.2210745 },
    { lat: 49.077708, lng: 20.2216195 },
    { lat: 49.0778526, lng: 20.2215167 },
    { lat: 49.0804146, lng: 20.2218317 },
    { lat: 49.0834334, lng: 20.221886 },
    { lat: 49.0846489, lng: 20.2221786 },
    { lat: 49.085396, lng: 20.2222211 },
    { lat: 49.0857263, lng: 20.2223158 },
    { lat: 49.0862813, lng: 20.2223386 },
    { lat: 49.0873968, lng: 20.222524 },
    { lat: 49.0882862, lng: 20.2224942 },
    { lat: 49.0884473, lng: 20.2224104 },
    { lat: 49.091482, lng: 20.2226929 },
    { lat: 49.0918037, lng: 20.2226231 },
    { lat: 49.0938774, lng: 20.2225802 },
    { lat: 49.0943763, lng: 20.2222125 },
    { lat: 49.0949485, lng: 20.2219609 },
    { lat: 49.0985468, lng: 20.2210899 },
    { lat: 49.0985851, lng: 20.2212341 },
    { lat: 49.0990972, lng: 20.2210581 },
    { lat: 49.1008782, lng: 20.2208694 },
    { lat: 49.1020127, lng: 20.2207736 },
    { lat: 49.1027798, lng: 20.2208314 },
    { lat: 49.1030341, lng: 20.2206032 },
    { lat: 49.103816, lng: 20.2207561 },
    { lat: 49.1038989, lng: 20.2206328 },
    { lat: 49.1045846, lng: 20.2204289 },
    { lat: 49.1053626, lng: 20.2205361 },
    { lat: 49.1067243, lng: 20.2209747 },
    { lat: 49.1067401, lng: 20.2209441 },
    { lat: 49.1067471, lng: 20.2208062 },
    { lat: 49.1069197, lng: 20.2204453 },
    { lat: 49.1069001, lng: 20.2196104 },
    { lat: 49.1069571, lng: 20.2195117 },
    { lat: 49.1069594, lng: 20.2188293 },
    { lat: 49.1070884, lng: 20.2183639 },
    { lat: 49.1072561, lng: 20.2179926 },
    { lat: 49.1073909, lng: 20.2173858 },
    { lat: 49.1073696, lng: 20.2171875 },
    { lat: 49.1074191, lng: 20.2170198 },
    { lat: 49.1073611, lng: 20.216693 },
    { lat: 49.107463, lng: 20.2164483 },
    { lat: 49.1074329, lng: 20.2162584 },
    { lat: 49.1074649, lng: 20.2159294 },
    { lat: 49.1074161, lng: 20.2157467 },
    { lat: 49.1075556, lng: 20.2155139 },
    { lat: 49.1075983, lng: 20.2153177 },
    { lat: 49.1075856, lng: 20.2151952 },
    { lat: 49.1076625, lng: 20.21492 },
    { lat: 49.1076645, lng: 20.2143994 },
    { lat: 49.1077665, lng: 20.2142455 },
    { lat: 49.1077853, lng: 20.2140712 },
    { lat: 49.1078755, lng: 20.2139296 },
    { lat: 49.1079758, lng: 20.2134165 },
    { lat: 49.1079952, lng: 20.2127983 },
    { lat: 49.1080389, lng: 20.2127655 },
    { lat: 49.1080138, lng: 20.212614 },
    { lat: 49.1080989, lng: 20.2125461 },
    { lat: 49.1081054, lng: 20.2121685 },
    { lat: 49.1080479, lng: 20.2116285 },
    { lat: 49.1081225, lng: 20.2114384 },
    { lat: 49.1080362, lng: 20.2109934 },
    { lat: 49.1080781, lng: 20.210885 },
    { lat: 49.1080922, lng: 20.210305 },
    { lat: 49.1082556, lng: 20.2097149 },
    { lat: 49.108208, lng: 20.209651 },
    { lat: 49.1081885, lng: 20.2093555 },
    { lat: 49.1082777, lng: 20.209164 },
    { lat: 49.1082281, lng: 20.2090751 },
    { lat: 49.1083381, lng: 20.208867 },
    { lat: 49.1083289, lng: 20.2086345 },
    { lat: 49.1084042, lng: 20.2081169 },
    { lat: 49.1085238, lng: 20.2077611 },
    { lat: 49.1084931, lng: 20.2076109 },
    { lat: 49.1085813, lng: 20.2075032 },
    { lat: 49.1085268, lng: 20.2074374 },
    { lat: 49.1085801, lng: 20.2071924 },
    { lat: 49.1086541, lng: 20.2070421 },
    { lat: 49.1086224, lng: 20.20692 },
    { lat: 49.1086638, lng: 20.206437 },
    { lat: 49.1087931, lng: 20.2062275 },
    { lat: 49.1089136, lng: 20.2058623 },
    { lat: 49.1090906, lng: 20.2056383 },
    { lat: 49.1093708, lng: 20.2054615 },
    { lat: 49.1094151, lng: 20.2053586 },
    { lat: 49.1095509, lng: 20.2053216 },
    { lat: 49.109752, lng: 20.204769 },
    { lat: 49.1097253, lng: 20.2046913 },
    { lat: 49.10979, lng: 20.2045365 },
    { lat: 49.1098035, lng: 20.2040806 },
    { lat: 49.1100329, lng: 20.2036475 },
    { lat: 49.1101054, lng: 20.2033763 },
    { lat: 49.110217, lng: 20.2033516 },
    { lat: 49.1103037, lng: 20.2032358 },
    { lat: 49.1103562, lng: 20.2032813 },
    { lat: 49.1105976, lng: 20.2030674 },
    { lat: 49.1106719, lng: 20.2029538 },
    { lat: 49.1106733, lng: 20.2027334 },
    { lat: 49.1107705, lng: 20.2025724 },
    { lat: 49.1108346, lng: 20.2025708 },
    { lat: 49.1109151, lng: 20.2022736 },
    { lat: 49.1110597, lng: 20.2019997 },
    { lat: 49.1111374, lng: 20.2018869 },
    { lat: 49.1112756, lng: 20.2018198 },
    { lat: 49.1114696, lng: 20.2015489 },
    { lat: 49.1116859, lng: 20.2010113 },
    { lat: 49.1119808, lng: 20.2007246 },
    { lat: 49.1120205, lng: 20.2005901 },
    { lat: 49.1122122, lng: 20.200424 },
    { lat: 49.1123361, lng: 20.2002228 },
    { lat: 49.112559, lng: 20.2000257 },
    { lat: 49.1129272, lng: 20.1998246 },
    { lat: 49.1130124, lng: 20.1998433 },
    { lat: 49.1131188, lng: 20.1996588 },
    { lat: 49.1132741, lng: 20.1996305 },
    { lat: 49.1134101, lng: 20.1994842 },
    { lat: 49.1133597, lng: 20.1993203 },
    { lat: 49.1135169, lng: 20.199391 },
    { lat: 49.1135875, lng: 20.1993291 },
    { lat: 49.1136172, lng: 20.1992548 },
    { lat: 49.1136832, lng: 20.1992433 },
    { lat: 49.1138018, lng: 20.198938 },
    { lat: 49.1140412, lng: 20.1988444 },
    { lat: 49.1143392, lng: 20.1985185 },
    { lat: 49.1145192, lng: 20.1984872 },
    { lat: 49.1146432, lng: 20.198378 },
    { lat: 49.1148947, lng: 20.1981017 },
    { lat: 49.1150074, lng: 20.1978806 },
    { lat: 49.1151025, lng: 20.1978931 },
    { lat: 49.1151209, lng: 20.1976185 },
    { lat: 49.1154694, lng: 20.197435 },
    { lat: 49.1154567, lng: 20.1971982 },
    { lat: 49.115635, lng: 20.196869 },
    { lat: 49.1158029, lng: 20.1966774 },
    { lat: 49.114993, lng: 20.1960479 },
    { lat: 49.1155005, lng: 20.1957555 },
    { lat: 49.1156597, lng: 20.1955377 },
    { lat: 49.1157284, lng: 20.1952553 },
    { lat: 49.1158089, lng: 20.1953507 },
    { lat: 49.115898, lng: 20.1952418 },
    { lat: 49.1159666, lng: 20.1948177 },
    { lat: 49.1161211, lng: 20.1948236 },
    { lat: 49.1164251, lng: 20.1945392 },
    { lat: 49.1164914, lng: 20.1945928 },
    { lat: 49.1165444, lng: 20.1945368 },
    { lat: 49.11611, lng: 20.1941168 },
    { lat: 49.1149066, lng: 20.193193 },
    { lat: 49.114617, lng: 20.1933887 },
    { lat: 49.1143147, lng: 20.1931495 },
    { lat: 49.1140956, lng: 20.1933908 },
    { lat: 49.1134006, lng: 20.1928428 },
    { lat: 49.1133489, lng: 20.192991 },
    { lat: 49.1128505, lng: 20.1925773 },
    { lat: 49.1121401, lng: 20.1925786 },
    { lat: 49.1119641, lng: 20.1926269 },
    { lat: 49.1118431, lng: 20.1928134 },
    { lat: 49.1116267, lng: 20.1928694 },
    { lat: 49.1115358, lng: 20.1928761 },
    { lat: 49.1108556, lng: 20.193015 },
    { lat: 49.1105627, lng: 20.1929245 },
    { lat: 49.1104909, lng: 20.1930473 },
    { lat: 49.1103055, lng: 20.1931151 },
    { lat: 49.1104104, lng: 20.192242 },
    { lat: 49.110367, lng: 20.191833 },
    { lat: 49.1105095, lng: 20.1913112 },
    { lat: 49.1104222, lng: 20.1912388 },
    { lat: 49.1101261, lng: 20.1918642 },
    { lat: 49.1099457, lng: 20.1918557 },
    { lat: 49.1097987, lng: 20.1917532 },
    { lat: 49.1093752, lng: 20.1910735 },
    { lat: 49.1089909, lng: 20.1908405 },
    { lat: 49.1086672, lng: 20.1907719 },
    { lat: 49.1079575, lng: 20.1903127 },
    { lat: 49.1074732, lng: 20.1902565 },
    { lat: 49.1070123, lng: 20.1905287 },
    { lat: 49.1068272, lng: 20.1908265 },
    { lat: 49.1061316, lng: 20.1915748 },
    { lat: 49.1058393, lng: 20.1920097 },
    { lat: 49.1054056, lng: 20.192402 },
    { lat: 49.1050772, lng: 20.1926401 },
    { lat: 49.1040518, lng: 20.1929641 },
    { lat: 49.1036417, lng: 20.1932377 },
    { lat: 49.1035687, lng: 20.1931582 },
    { lat: 49.1036332, lng: 20.192776 },
    { lat: 49.1030239, lng: 20.1927697 },
    { lat: 49.1011186, lng: 20.1915308 },
    { lat: 49.1009549, lng: 20.1915441 },
    { lat: 49.1008933, lng: 20.1918402 },
    { lat: 49.1006893, lng: 20.1919492 },
    { lat: 49.1006054, lng: 20.192325 },
    { lat: 49.0995855, lng: 20.191971 },
    { lat: 49.0995632, lng: 20.1918067 },
    { lat: 49.098252, lng: 20.1916568 },
    { lat: 49.0981091, lng: 20.1910656 },
    { lat: 49.098036, lng: 20.1904744 },
    { lat: 49.0974977, lng: 20.1904731 },
    { lat: 49.097169, lng: 20.1903913 },
    { lat: 49.0967431, lng: 20.1910099 },
    { lat: 49.0964291, lng: 20.192112 },
    { lat: 49.0961494, lng: 20.1922708 },
    { lat: 49.0959431, lng: 20.1922399 },
    { lat: 49.0956251, lng: 20.1925089 },
    { lat: 49.0955412, lng: 20.192636 },
    { lat: 49.095608, lng: 20.1927602 },
    { lat: 49.095411, lng: 20.1932136 },
    { lat: 49.0944535, lng: 20.1933749 },
    { lat: 49.0942585, lng: 20.1940491 },
  ],
  Vikartovce: [
    { lat: 48.9892231, lng: 20.0863658 },
    { lat: 48.9890313, lng: 20.0864151 },
    { lat: 48.9886078, lng: 20.0863833 },
    { lat: 48.9879989, lng: 20.0867516 },
    { lat: 48.9877942, lng: 20.0870925 },
    { lat: 48.9875336, lng: 20.0877945 },
    { lat: 48.9870327, lng: 20.0883025 },
    { lat: 48.9869195, lng: 20.0886158 },
    { lat: 48.9866395, lng: 20.0888218 },
    { lat: 48.9859169, lng: 20.0895522 },
    { lat: 48.9851826, lng: 20.089852 },
    { lat: 48.9844493, lng: 20.090654 },
    { lat: 48.9840525, lng: 20.0913877 },
    { lat: 48.9838524, lng: 20.0915335 },
    { lat: 48.9833205, lng: 20.0917184 },
    { lat: 48.9829781, lng: 20.0919399 },
    { lat: 48.9826754, lng: 20.0925281 },
    { lat: 48.9821816, lng: 20.0929023 },
    { lat: 48.9819799, lng: 20.0931228 },
    { lat: 48.9814454, lng: 20.0938472 },
    { lat: 48.9811046, lng: 20.0953526 },
    { lat: 48.9809216, lng: 20.0957782 },
    { lat: 48.9806235, lng: 20.0962067 },
    { lat: 48.980338, lng: 20.0964111 },
    { lat: 48.9800503, lng: 20.0964564 },
    { lat: 48.9798095, lng: 20.0967619 },
    { lat: 48.9797122, lng: 20.0970565 },
    { lat: 48.9796364, lng: 20.0979879 },
    { lat: 48.9795131, lng: 20.0984205 },
    { lat: 48.9794369, lng: 20.0985363 },
    { lat: 48.9790606, lng: 20.098344 },
    { lat: 48.978717, lng: 20.0983963 },
    { lat: 48.9775499, lng: 20.101484 },
    { lat: 48.9772488, lng: 20.1025283 },
    { lat: 48.9766766, lng: 20.103303 },
    { lat: 48.9759809, lng: 20.1034472 },
    { lat: 48.9755456, lng: 20.103624 },
    { lat: 48.975422, lng: 20.1037424 },
    { lat: 48.9748434, lng: 20.1045557 },
    { lat: 48.9742657, lng: 20.1056668 },
    { lat: 48.9741198, lng: 20.1058676 },
    { lat: 48.9738124, lng: 20.1060915 },
    { lat: 48.9730927, lng: 20.1063956 },
    { lat: 48.972779, lng: 20.1063835 },
    { lat: 48.97239, lng: 20.1065093 },
    { lat: 48.9713509, lng: 20.1069903 },
    { lat: 48.9703898, lng: 20.1080036 },
    { lat: 48.969821, lng: 20.1087534 },
    { lat: 48.9694822, lng: 20.109063 },
    { lat: 48.9689327, lng: 20.1093963 },
    { lat: 48.9683705, lng: 20.1095006 },
    { lat: 48.9680716, lng: 20.1093806 },
    { lat: 48.9676339, lng: 20.1090883 },
    { lat: 48.9671986, lng: 20.1087126 },
    { lat: 48.9671185, lng: 20.1085946 },
    { lat: 48.9668982, lng: 20.1079421 },
    { lat: 48.9665956, lng: 20.107483 },
    { lat: 48.966445, lng: 20.1070749 },
    { lat: 48.965992, lng: 20.1062887 },
    { lat: 48.9656667, lng: 20.1058231 },
    { lat: 48.9655672, lng: 20.1055113 },
    { lat: 48.9648604, lng: 20.1049686 },
    { lat: 48.9644234, lng: 20.1047214 },
    { lat: 48.9642054, lng: 20.104677 },
    { lat: 48.9639602, lng: 20.1047387 },
    { lat: 48.963516, lng: 20.1051457 },
    { lat: 48.9632451, lng: 20.105656 },
    { lat: 48.9631172, lng: 20.1058074 },
    { lat: 48.9615596, lng: 20.1068059 },
    { lat: 48.9612686, lng: 20.1067644 },
    { lat: 48.9609263, lng: 20.1061012 },
    { lat: 48.9607241, lng: 20.105975 },
    { lat: 48.9603436, lng: 20.106449 },
    { lat: 48.9600525, lng: 20.1065756 },
    { lat: 48.959317, lng: 20.1066019 },
    { lat: 48.958096, lng: 20.1069326 },
    { lat: 48.9574989, lng: 20.1068173 },
    { lat: 48.9573572, lng: 20.1068506 },
    { lat: 48.9570963, lng: 20.107055 },
    { lat: 48.9570283, lng: 20.107294 },
    { lat: 48.9571592, lng: 20.1077157 },
    { lat: 48.9571519, lng: 20.1080045 },
    { lat: 48.957059, lng: 20.1081874 },
    { lat: 48.9564994, lng: 20.1084348 },
    { lat: 48.9564233, lng: 20.1085609 },
    { lat: 48.9563983, lng: 20.1087475 },
    { lat: 48.9564527, lng: 20.1091147 },
    { lat: 48.9564321, lng: 20.1093571 },
    { lat: 48.9562623, lng: 20.1095067 },
    { lat: 48.9558946, lng: 20.1095992 },
    { lat: 48.9557443, lng: 20.1098373 },
    { lat: 48.9555928, lng: 20.110378 },
    { lat: 48.955197, lng: 20.1110863 },
    { lat: 48.955125, lng: 20.1116725 },
    { lat: 48.954936, lng: 20.1122005 },
    { lat: 48.9548125, lng: 20.112239 },
    { lat: 48.9546711, lng: 20.1119727 },
    { lat: 48.95454, lng: 20.1118818 },
    { lat: 48.9541315, lng: 20.1120268 },
    { lat: 48.953931, lng: 20.112333 },
    { lat: 48.9538209, lng: 20.1126418 },
    { lat: 48.9537752, lng: 20.1128489 },
    { lat: 48.9538463, lng: 20.1133203 },
    { lat: 48.953802, lng: 20.1134789 },
    { lat: 48.953721, lng: 20.113565 },
    { lat: 48.9533393, lng: 20.1134864 },
    { lat: 48.9530556, lng: 20.1135976 },
    { lat: 48.9529775, lng: 20.1137532 },
    { lat: 48.9528879, lng: 20.1141565 },
    { lat: 48.9528078, lng: 20.1142662 },
    { lat: 48.9521691, lng: 20.1147581 },
    { lat: 48.9519835, lng: 20.1152381 },
    { lat: 48.951598, lng: 20.1155078 },
    { lat: 48.9514071, lng: 20.1157407 },
    { lat: 48.951266, lng: 20.1161205 },
    { lat: 48.950952, lng: 20.1165415 },
    { lat: 48.9509279, lng: 20.1170877 },
    { lat: 48.9507635, lng: 20.1174776 },
    { lat: 48.9497797, lng: 20.1187664 },
    { lat: 48.9494994, lng: 20.1194079 },
    { lat: 48.9490768, lng: 20.119566 },
    { lat: 48.9487919, lng: 20.1197908 },
    { lat: 48.9485567, lng: 20.1197935 },
    { lat: 48.9480097, lng: 20.1196393 },
    { lat: 48.9473398, lng: 20.1201427 },
    { lat: 48.9466379, lng: 20.1203051 },
    { lat: 48.9462162, lng: 20.1206533 },
    { lat: 48.9460042, lng: 20.1207559 },
    { lat: 48.9456724, lng: 20.1204903 },
    { lat: 48.9450719, lng: 20.1206801 },
    { lat: 48.9448074, lng: 20.120598 },
    { lat: 48.9445738, lng: 20.1202576 },
    { lat: 48.9445035, lng: 20.1198271 },
    { lat: 48.9442985, lng: 20.1194095 },
    { lat: 48.9442265, lng: 20.1193278 },
    { lat: 48.9439918, lng: 20.1192906 },
    { lat: 48.9436537, lng: 20.1193688 },
    { lat: 48.9428815, lng: 20.1199039 },
    { lat: 48.9423127, lng: 20.119985 },
    { lat: 48.9417944, lng: 20.120285 },
    { lat: 48.9414339, lng: 20.1202008 },
    { lat: 48.9410317, lng: 20.1203337 },
    { lat: 48.9407538, lng: 20.1202315 },
    { lat: 48.9404326, lng: 20.1200046 },
    { lat: 48.9398711, lng: 20.1201252 },
    { lat: 48.938459, lng: 20.1207394 },
    { lat: 48.9382105, lng: 20.1206408 },
    { lat: 48.9378278, lng: 20.1203424 },
    { lat: 48.9375889, lng: 20.1203407 },
    { lat: 48.9374893, lng: 20.1203815 },
    { lat: 48.9372577, lng: 20.12078 },
    { lat: 48.9369426, lng: 20.1208958 },
    { lat: 48.9366881, lng: 20.1215383 },
    { lat: 48.9363455, lng: 20.1219479 },
    { lat: 48.9362103, lng: 20.1225025 },
    { lat: 48.9360686, lng: 20.1227038 },
    { lat: 48.9357927, lng: 20.1227321 },
    { lat: 48.9353571, lng: 20.1234613 },
    { lat: 48.9349876, lng: 20.1235954 },
    { lat: 48.9345788, lng: 20.1238457 },
    { lat: 48.9344286, lng: 20.1240284 },
    { lat: 48.9342298, lng: 20.1245166 },
    { lat: 48.9341068, lng: 20.1246775 },
    { lat: 48.9333409, lng: 20.1249797 },
    { lat: 48.933154, lng: 20.1251189 },
    { lat: 48.9330504, lng: 20.1252074 },
    { lat: 48.9329069, lng: 20.125637 },
    { lat: 48.9322556, lng: 20.1262086 },
    { lat: 48.9318266, lng: 20.1261298 },
    { lat: 48.9314345, lng: 20.1264579 },
    { lat: 48.9309346, lng: 20.1271561 },
    { lat: 48.930511, lng: 20.1276001 },
    { lat: 48.9304252, lng: 20.1281253 },
    { lat: 48.9302969, lng: 20.1283207 },
    { lat: 48.9301661, lng: 20.1287036 },
    { lat: 48.9297616, lng: 20.1287854 },
    { lat: 48.9296234, lng: 20.1289347 },
    { lat: 48.9294871, lng: 20.1294016 },
    { lat: 48.9294547, lng: 20.1300483 },
    { lat: 48.9295715, lng: 20.1304851 },
    { lat: 48.9294268, lng: 20.1319159 },
    { lat: 48.929143, lng: 20.1323389 },
    { lat: 48.9287712, lng: 20.1331124 },
    { lat: 48.9283943, lng: 20.1336254 },
    { lat: 48.9282107, lng: 20.1344308 },
    { lat: 48.9278314, lng: 20.1351578 },
    { lat: 48.9278162, lng: 20.1356757 },
    { lat: 48.9275013, lng: 20.1362673 },
    { lat: 48.9272825, lng: 20.1373475 },
    { lat: 48.9271224, lng: 20.1373749 },
    { lat: 48.927087, lng: 20.1376948 },
    { lat: 48.9269344, lng: 20.1380121 },
    { lat: 48.9268548, lng: 20.1380513 },
    { lat: 48.9266745, lng: 20.1384958 },
    { lat: 48.926463, lng: 20.1384997 },
    { lat: 48.9263967, lng: 20.138618 },
    { lat: 48.9262779, lng: 20.139018 },
    { lat: 48.9261176, lng: 20.1392611 },
    { lat: 48.9259514, lng: 20.1399441 },
    { lat: 48.925808, lng: 20.1401555 },
    { lat: 48.9257945, lng: 20.1403237 },
    { lat: 48.9254586, lng: 20.1408177 },
    { lat: 48.9253865, lng: 20.1414289 },
    { lat: 48.9252196, lng: 20.1419537 },
    { lat: 48.9253588, lng: 20.1421675 },
    { lat: 48.9250971, lng: 20.1427709 },
    { lat: 48.9252059, lng: 20.1430925 },
    { lat: 48.9251254, lng: 20.1431909 },
    { lat: 48.9251686, lng: 20.1435064 },
    { lat: 48.9253263, lng: 20.1440619 },
    { lat: 48.92538, lng: 20.1446152 },
    { lat: 48.9251739, lng: 20.1450588 },
    { lat: 48.9247397, lng: 20.1456897 },
    { lat: 48.9245805, lng: 20.146458 },
    { lat: 48.9244817, lng: 20.1465779 },
    { lat: 48.9244386, lng: 20.1468193 },
    { lat: 48.9244594, lng: 20.147505 },
    { lat: 48.9243939, lng: 20.1479466 },
    { lat: 48.9241323, lng: 20.1487215 },
    { lat: 48.9241066, lng: 20.1490305 },
    { lat: 48.9239928, lng: 20.1494214 },
    { lat: 48.9239078, lng: 20.1502165 },
    { lat: 48.9239907, lng: 20.1503989 },
    { lat: 48.9239653, lng: 20.1505256 },
    { lat: 48.9240847, lng: 20.1511971 },
    { lat: 48.9240218, lng: 20.1518436 },
    { lat: 48.9235474, lng: 20.152282 },
    { lat: 48.9231334, lng: 20.1528844 },
    { lat: 48.923062, lng: 20.1532904 },
    { lat: 48.9229621, lng: 20.1535761 },
    { lat: 48.9228164, lng: 20.1537375 },
    { lat: 48.922777, lng: 20.1539901 },
    { lat: 48.9227922, lng: 20.1543088 },
    { lat: 48.922581, lng: 20.1546003 },
    { lat: 48.9223952, lng: 20.1546286 },
    { lat: 48.9223747, lng: 20.1547927 },
    { lat: 48.922304, lng: 20.1548254 },
    { lat: 48.922248, lng: 20.1549467 },
    { lat: 48.9221004, lng: 20.1549743 },
    { lat: 48.9218846, lng: 20.155177 },
    { lat: 48.9217423, lng: 20.1552224 },
    { lat: 48.9213954, lng: 20.1557857 },
    { lat: 48.9212365, lng: 20.1558362 },
    { lat: 48.9211036, lng: 20.1558059 },
    { lat: 48.9210072, lng: 20.1556809 },
    { lat: 48.9208955, lng: 20.1556741 },
    { lat: 48.9204364, lng: 20.1552952 },
    { lat: 48.9202232, lng: 20.1552824 },
    { lat: 48.9200122, lng: 20.1549646 },
    { lat: 48.9197398, lng: 20.1551915 },
    { lat: 48.9194484, lng: 20.1553109 },
    { lat: 48.9190831, lng: 20.1556112 },
    { lat: 48.9187944, lng: 20.1556849 },
    { lat: 48.918398, lng: 20.1556275 },
    { lat: 48.9181718, lng: 20.1553545 },
    { lat: 48.9180907, lng: 20.1553276 },
    { lat: 48.9178672, lng: 20.1553169 },
    { lat: 48.9176239, lng: 20.1554519 },
    { lat: 48.9169635, lng: 20.1550795 },
    { lat: 48.9166782, lng: 20.1547593 },
    { lat: 48.916626, lng: 20.1542786 },
    { lat: 48.9161293, lng: 20.153466 },
    { lat: 48.9158097, lng: 20.1531662 },
    { lat: 48.9155624, lng: 20.15309 },
    { lat: 48.9154443, lng: 20.1528417 },
    { lat: 48.9152154, lng: 20.1528075 },
    { lat: 48.9151586, lng: 20.152715 },
    { lat: 48.9148914, lng: 20.1530431 },
    { lat: 48.9147895, lng: 20.1529723 },
    { lat: 48.9146354, lng: 20.1529791 },
    { lat: 48.9145375, lng: 20.1530627 },
    { lat: 48.9144105, lng: 20.1530146 },
    { lat: 48.9141746, lng: 20.1533377 },
    { lat: 48.9140293, lng: 20.1533934 },
    { lat: 48.913701, lng: 20.1532645 },
    { lat: 48.9136393, lng: 20.1533247 },
    { lat: 48.913665, lng: 20.1534064 },
    { lat: 48.9136366, lng: 20.1534578 },
    { lat: 48.9132926, lng: 20.1535541 },
    { lat: 48.9129903, lng: 20.1537952 },
    { lat: 48.9128053, lng: 20.1538407 },
    { lat: 48.9121391, lng: 20.1537199 },
    { lat: 48.9119172, lng: 20.1539116 },
    { lat: 48.9117342, lng: 20.1539848 },
    { lat: 48.9115229, lng: 20.1543266 },
    { lat: 48.9114599, lng: 20.1545509 },
    { lat: 48.9111951, lng: 20.1548307 },
    { lat: 48.9109367, lng: 20.1552077 },
    { lat: 48.9108386, lng: 20.1554553 },
    { lat: 48.9106914, lng: 20.1555194 },
    { lat: 48.9106035, lng: 20.1557689 },
    { lat: 48.9103828, lng: 20.155775 },
    { lat: 48.9103254, lng: 20.1558838 },
    { lat: 48.9101518, lng: 20.1559926 },
    { lat: 48.9099628, lng: 20.156027 },
    { lat: 48.9095667, lng: 20.1566469 },
    { lat: 48.9094691, lng: 20.1566839 },
    { lat: 48.9094032, lng: 20.1568945 },
    { lat: 48.9094182, lng: 20.1570445 },
    { lat: 48.9093157, lng: 20.1570991 },
    { lat: 48.9091814, lng: 20.1573468 },
    { lat: 48.9086455, lng: 20.1578802 },
    { lat: 48.9071331, lng: 20.1612646 },
    { lat: 48.9071831, lng: 20.1698493 },
    { lat: 48.9077289, lng: 20.1730628 },
    { lat: 48.9078408, lng: 20.1749517 },
    { lat: 48.907974, lng: 20.175661 },
    { lat: 48.9106155, lng: 20.1756786 },
    { lat: 48.9105394, lng: 20.1770627 },
    { lat: 48.9104164, lng: 20.17832 },
    { lat: 48.9100012, lng: 20.1805497 },
    { lat: 48.9106192, lng: 20.1818389 },
    { lat: 48.912146, lng: 20.1845288 },
    { lat: 48.9125344, lng: 20.1855359 },
    { lat: 48.9128544, lng: 20.1865251 },
    { lat: 48.9129135, lng: 20.1865408 },
    { lat: 48.9167392, lng: 20.1853565 },
    { lat: 48.9187758, lng: 20.1840767 },
    { lat: 48.9187908, lng: 20.1792211 },
    { lat: 48.9176316, lng: 20.1760946 },
    { lat: 48.9176057, lng: 20.1758667 },
    { lat: 48.9177737, lng: 20.1753657 },
    { lat: 48.9180018, lng: 20.1752171 },
    { lat: 48.9182152, lng: 20.1749768 },
    { lat: 48.9183283, lng: 20.1747906 },
    { lat: 48.9184101, lng: 20.1744475 },
    { lat: 48.9185523, lng: 20.1742263 },
    { lat: 48.9186843, lng: 20.1742055 },
    { lat: 48.918802, lng: 20.1739089 },
    { lat: 48.9187976, lng: 20.1738001 },
    { lat: 48.9188341, lng: 20.1737269 },
    { lat: 48.91891, lng: 20.1737414 },
    { lat: 48.9189594, lng: 20.1736272 },
    { lat: 48.9189253, lng: 20.1734187 },
    { lat: 48.9190401, lng: 20.1732216 },
    { lat: 48.9193692, lng: 20.1729176 },
    { lat: 48.9194611, lng: 20.1727082 },
    { lat: 48.9194484, lng: 20.1726213 },
    { lat: 48.9195589, lng: 20.1724535 },
    { lat: 48.9196578, lng: 20.1724465 },
    { lat: 48.9198963, lng: 20.1722225 },
    { lat: 48.9201092, lng: 20.1722225 },
    { lat: 48.9204518, lng: 20.1720276 },
    { lat: 48.920697, lng: 20.1717968 },
    { lat: 48.9209017, lng: 20.1717879 },
    { lat: 48.9212554, lng: 20.1720174 },
    { lat: 48.9214477, lng: 20.171981 },
    { lat: 48.92182, lng: 20.1723355 },
    { lat: 48.9219793, lng: 20.1724017 },
    { lat: 48.9220409, lng: 20.1723133 },
    { lat: 48.9223879, lng: 20.1721738 },
    { lat: 48.9226771, lng: 20.1721932 },
    { lat: 48.9228449, lng: 20.1725065 },
    { lat: 48.9230677, lng: 20.1727824 },
    { lat: 48.9231526, lng: 20.1727943 },
    { lat: 48.9233311, lng: 20.1730825 },
    { lat: 48.9239394, lng: 20.1730872 },
    { lat: 48.9241921, lng: 20.1734388 },
    { lat: 48.9242441, lng: 20.1733889 },
    { lat: 48.9245688, lng: 20.173534 },
    { lat: 48.9248778, lng: 20.1740537 },
    { lat: 48.9249802, lng: 20.1740482 },
    { lat: 48.9250347, lng: 20.1741187 },
    { lat: 48.9252577, lng: 20.1741705 },
    { lat: 48.9255293, lng: 20.1740634 },
    { lat: 48.9257776, lng: 20.174217 },
    { lat: 48.9259239, lng: 20.1741297 },
    { lat: 48.9263411, lng: 20.1743988 },
    { lat: 48.9265049, lng: 20.1743224 },
    { lat: 48.9267793, lng: 20.1745678 },
    { lat: 48.9268488, lng: 20.1745288 },
    { lat: 48.9272816, lng: 20.174736 },
    { lat: 48.9274075, lng: 20.1746205 },
    { lat: 48.9278893, lng: 20.1744176 },
    { lat: 48.9279315, lng: 20.1743461 },
    { lat: 48.9287166, lng: 20.1740973 },
    { lat: 48.9288473, lng: 20.1742091 },
    { lat: 48.9289417, lng: 20.1744322 },
    { lat: 48.9290527, lng: 20.174476 },
    { lat: 48.9291939, lng: 20.1744303 },
    { lat: 48.9293952, lng: 20.1746198 },
    { lat: 48.9296177, lng: 20.1747189 },
    { lat: 48.9297655, lng: 20.1746419 },
    { lat: 48.9298628, lng: 20.1747618 },
    { lat: 48.9299829, lng: 20.174779 },
    { lat: 48.9300539, lng: 20.1749265 },
    { lat: 48.9302291, lng: 20.1750514 },
    { lat: 48.9303526, lng: 20.1749297 },
    { lat: 48.9308803, lng: 20.1750596 },
    { lat: 48.9310532, lng: 20.175241 },
    { lat: 48.9312103, lng: 20.175286 },
    { lat: 48.9312129, lng: 20.1756038 },
    { lat: 48.9314334, lng: 20.1756225 },
    { lat: 48.9315577, lng: 20.1758334 },
    { lat: 48.9319081, lng: 20.1759504 },
    { lat: 48.9320677, lng: 20.1761914 },
    { lat: 48.9320826, lng: 20.1765052 },
    { lat: 48.9321946, lng: 20.1767452 },
    { lat: 48.9323106, lng: 20.1768305 },
    { lat: 48.9325249, lng: 20.1772606 },
    { lat: 48.9328337, lng: 20.1775752 },
    { lat: 48.9329152, lng: 20.177754 },
    { lat: 48.9330314, lng: 20.1777558 },
    { lat: 48.93326, lng: 20.1778948 },
    { lat: 48.933569, lng: 20.1778795 },
    { lat: 48.9337937, lng: 20.1780336 },
    { lat: 48.9339659, lng: 20.1780316 },
    { lat: 48.9340168, lng: 20.1779524 },
    { lat: 48.9341823, lng: 20.177978 },
    { lat: 48.9343336, lng: 20.178132 },
    { lat: 48.9344868, lng: 20.1781347 },
    { lat: 48.9345769, lng: 20.178044 },
    { lat: 48.9347777, lng: 20.1781379 },
    { lat: 48.9353546, lng: 20.1787348 },
    { lat: 48.9355195, lng: 20.1787224 },
    { lat: 48.935937, lng: 20.1791875 },
    { lat: 48.9361485, lng: 20.1794969 },
    { lat: 48.9361362, lng: 20.1798075 },
    { lat: 48.9363091, lng: 20.1800172 },
    { lat: 48.93648, lng: 20.1800132 },
    { lat: 48.9366066, lng: 20.1801631 },
    { lat: 48.9367834, lng: 20.1801335 },
    { lat: 48.9371362, lng: 20.1807286 },
    { lat: 48.9372094, lng: 20.180729 },
    { lat: 48.937302, lng: 20.1810744 },
    { lat: 48.9375552, lng: 20.1810736 },
    { lat: 48.9376972, lng: 20.1812722 },
    { lat: 48.9378815, lng: 20.1813419 },
    { lat: 48.9381431, lng: 20.1815756 },
    { lat: 48.9386045, lng: 20.1815997 },
    { lat: 48.9387371, lng: 20.1817576 },
    { lat: 48.9390081, lng: 20.1818078 },
    { lat: 48.939132, lng: 20.1819723 },
    { lat: 48.9393511, lng: 20.1818747 },
    { lat: 48.9394681, lng: 20.1820144 },
    { lat: 48.9396339, lng: 20.1820765 },
    { lat: 48.9399389, lng: 20.1820139 },
    { lat: 48.9403136, lng: 20.1821208 },
    { lat: 48.9406078, lng: 20.1820998 },
    { lat: 48.9409687, lng: 20.1819723 },
    { lat: 48.9416399, lng: 20.1823395 },
    { lat: 48.9418151, lng: 20.1827758 },
    { lat: 48.9420985, lng: 20.1829059 },
    { lat: 48.9422845, lng: 20.1826835 },
    { lat: 48.9425136, lng: 20.1825828 },
    { lat: 48.9426629, lng: 20.1823738 },
    { lat: 48.9429748, lng: 20.1823188 },
    { lat: 48.9431863, lng: 20.1821141 },
    { lat: 48.9432975, lng: 20.1821372 },
    { lat: 48.9434552, lng: 20.1820216 },
    { lat: 48.9435355, lng: 20.1816921 },
    { lat: 48.9438543, lng: 20.1810791 },
    { lat: 48.9441583, lng: 20.1809382 },
    { lat: 48.9443073, lng: 20.180971 },
    { lat: 48.9445217, lng: 20.1808434 },
    { lat: 48.9448954, lng: 20.1808347 },
    { lat: 48.9450841, lng: 20.1810229 },
    { lat: 48.9452327, lng: 20.1810507 },
    { lat: 48.9453202, lng: 20.1809843 },
    { lat: 48.9456183, lng: 20.1810897 },
    { lat: 48.9458121, lng: 20.1810091 },
    { lat: 48.9460152, lng: 20.1810717 },
    { lat: 48.9461974, lng: 20.1810136 },
    { lat: 48.9464118, lng: 20.180823 },
    { lat: 48.9467425, lng: 20.1808118 },
    { lat: 48.9470744, lng: 20.1809743 },
    { lat: 48.9474943, lng: 20.1809154 },
    { lat: 48.9477048, lng: 20.1811117 },
    { lat: 48.9477867, lng: 20.1810751 },
    { lat: 48.9481149, lng: 20.1813691 },
    { lat: 48.9484402, lng: 20.1813903 },
    { lat: 48.9486999, lng: 20.1817454 },
    { lat: 48.9488249, lng: 20.181802 },
    { lat: 48.9490459, lng: 20.1817522 },
    { lat: 48.9492256, lng: 20.1818499 },
    { lat: 48.9493732, lng: 20.1817693 },
    { lat: 48.9500292, lng: 20.1816435 },
    { lat: 48.9503533, lng: 20.1814939 },
    { lat: 48.9506263, lng: 20.181621 },
    { lat: 48.9507977, lng: 20.1816013 },
    { lat: 48.9510668, lng: 20.1816778 },
    { lat: 48.9517703, lng: 20.1813893 },
    { lat: 48.9521531, lng: 20.1813024 },
    { lat: 48.952265, lng: 20.1811724 },
    { lat: 48.9526443, lng: 20.1813352 },
    { lat: 48.9528612, lng: 20.1812703 },
    { lat: 48.953449, lng: 20.1813501 },
    { lat: 48.95361, lng: 20.1816886 },
    { lat: 48.9537377, lng: 20.181584 },
    { lat: 48.9538862, lng: 20.1817193 },
    { lat: 48.9544812, lng: 20.181339 },
    { lat: 48.9547354, lng: 20.1812464 },
    { lat: 48.9549157, lng: 20.1810811 },
    { lat: 48.9554549, lng: 20.1812692 },
    { lat: 48.9557503, lng: 20.1816146 },
    { lat: 48.9560014, lng: 20.1817255 },
    { lat: 48.9562034, lng: 20.1816843 },
    { lat: 48.9563778, lng: 20.1815091 },
    { lat: 48.9566176, lng: 20.1811146 },
    { lat: 48.9567705, lng: 20.1806823 },
    { lat: 48.9571054, lng: 20.1801887 },
    { lat: 48.9571605, lng: 20.1796787 },
    { lat: 48.9573149, lng: 20.1789255 },
    { lat: 48.9574823, lng: 20.1785216 },
    { lat: 48.9577377, lng: 20.1782891 },
    { lat: 48.9576998, lng: 20.1780202 },
    { lat: 48.9577804, lng: 20.1773326 },
    { lat: 48.9581802, lng: 20.1767151 },
    { lat: 48.958507, lng: 20.1763839 },
    { lat: 48.9587924, lng: 20.175863 },
    { lat: 48.9590065, lng: 20.1756109 },
    { lat: 48.9590309, lng: 20.175489 },
    { lat: 48.9589911, lng: 20.1752805 },
    { lat: 48.9591147, lng: 20.1748838 },
    { lat: 48.9594286, lng: 20.1743147 },
    { lat: 48.9596076, lng: 20.1741398 },
    { lat: 48.9596601, lng: 20.1737142 },
    { lat: 48.9596318, lng: 20.1733126 },
    { lat: 48.9596674, lng: 20.1732197 },
    { lat: 48.9598488, lng: 20.1731112 },
    { lat: 48.9601091, lng: 20.1725509 },
    { lat: 48.9605509, lng: 20.1719123 },
    { lat: 48.9609506, lng: 20.1711499 },
    { lat: 48.9612549, lng: 20.1710472 },
    { lat: 48.9613465, lng: 20.1709137 },
    { lat: 48.9616006, lng: 20.1708457 },
    { lat: 48.9616395, lng: 20.1706938 },
    { lat: 48.9617809, lng: 20.170628 },
    { lat: 48.9618919, lng: 20.1703891 },
    { lat: 48.96204, lng: 20.1702646 },
    { lat: 48.9622897, lng: 20.1698928 },
    { lat: 48.9624769, lng: 20.1698131 },
    { lat: 48.96274, lng: 20.1698017 },
    { lat: 48.9629103, lng: 20.1696103 },
    { lat: 48.9630314, lng: 20.1695697 },
    { lat: 48.9630754, lng: 20.1695731 },
    { lat: 48.9631805, lng: 20.1697792 },
    { lat: 48.9634959, lng: 20.1697235 },
    { lat: 48.9637504, lng: 20.1700852 },
    { lat: 48.9640934, lng: 20.1700459 },
    { lat: 48.9643553, lng: 20.1701468 },
    { lat: 48.9649889, lng: 20.170081 },
    { lat: 48.9655833, lng: 20.1704378 },
    { lat: 48.9662177, lng: 20.1711511 },
    { lat: 48.9665832, lng: 20.1713385 },
    { lat: 48.9667568, lng: 20.1720697 },
    { lat: 48.967126, lng: 20.1721499 },
    { lat: 48.9673542, lng: 20.1722793 },
    { lat: 48.9674803, lng: 20.1724756 },
    { lat: 48.9676342, lng: 20.1730836 },
    { lat: 48.9677647, lng: 20.1733579 },
    { lat: 48.9681339, lng: 20.1735786 },
    { lat: 48.9682307, lng: 20.1737456 },
    { lat: 48.9683282, lng: 20.1741295 },
    { lat: 48.9684256, lng: 20.17428 },
    { lat: 48.9685533, lng: 20.1744047 },
    { lat: 48.9688713, lng: 20.1745008 },
    { lat: 48.9689529, lng: 20.1746527 },
    { lat: 48.969195, lng: 20.1744597 },
    { lat: 48.9693547, lng: 20.1744224 },
    { lat: 48.9698364, lng: 20.1739638 },
    { lat: 48.9699846, lng: 20.1736153 },
    { lat: 48.9703885, lng: 20.1733532 },
    { lat: 48.9707004, lng: 20.1729577 },
    { lat: 48.9709479, lng: 20.1728154 },
    { lat: 48.9711775, lng: 20.1724149 },
    { lat: 48.9718378, lng: 20.1719731 },
    { lat: 48.9735559, lng: 20.1729771 },
    { lat: 48.9743492, lng: 20.1733264 },
    { lat: 48.9746847, lng: 20.1735488 },
    { lat: 48.9748669, lng: 20.1737954 },
    { lat: 48.9756378, lng: 20.1744841 },
    { lat: 48.9757139, lng: 20.1743322 },
    { lat: 48.9761458, lng: 20.1747099 },
    { lat: 48.976241, lng: 20.1746133 },
    { lat: 48.9764166, lng: 20.1753675 },
    { lat: 48.9768821, lng: 20.1759113 },
    { lat: 48.9776112, lng: 20.1758392 },
    { lat: 48.9779107, lng: 20.1756696 },
    { lat: 48.978004, lng: 20.1756412 },
    { lat: 48.9785526, lng: 20.1754743 },
    { lat: 48.978819, lng: 20.1755433 },
    { lat: 48.978971, lng: 20.1756579 },
    { lat: 48.9794326, lng: 20.1766051 },
    { lat: 48.9795404, lng: 20.1767404 },
    { lat: 48.9798806, lng: 20.1769151 },
    { lat: 48.9803811, lng: 20.1769258 },
    { lat: 48.9808954, lng: 20.1763247 },
    { lat: 48.9822459, lng: 20.1760485 },
    { lat: 48.9828303, lng: 20.1761616 },
    { lat: 48.9831167, lng: 20.1766195 },
    { lat: 48.9845307, lng: 20.1766358 },
    { lat: 48.9850777, lng: 20.1768243 },
    { lat: 48.9854907, lng: 20.1765868 },
    { lat: 48.9859221, lng: 20.1772945 },
    { lat: 48.9860272, lng: 20.1773035 },
    { lat: 48.9870719, lng: 20.1769025 },
    { lat: 48.9874565, lng: 20.17666 },
    { lat: 48.9890876, lng: 20.1752681 },
    { lat: 48.9897958, lng: 20.1744492 },
    { lat: 48.9902381, lng: 20.1742964 },
    { lat: 48.9904464, lng: 20.1744661 },
    { lat: 48.9918299, lng: 20.1732437 },
    { lat: 48.9926952, lng: 20.1728584 },
    { lat: 48.9933881, lng: 20.1727021 },
    { lat: 48.9935566, lng: 20.1728311 },
    { lat: 48.9952868, lng: 20.1728416 },
    { lat: 48.9965047, lng: 20.1734171 },
    { lat: 48.9973558, lng: 20.1734625 },
    { lat: 48.9975802, lng: 20.1731889 },
    { lat: 48.9977562, lng: 20.1731701 },
    { lat: 48.9980171, lng: 20.1725965 },
    { lat: 48.9984974, lng: 20.1724444 },
    { lat: 48.9990864, lng: 20.1725872 },
    { lat: 48.9994097, lng: 20.1721394 },
    { lat: 49.0002706, lng: 20.1714729 },
    { lat: 49.000284, lng: 20.171779 },
    { lat: 49.0004094, lng: 20.1717918 },
    { lat: 49.0004236, lng: 20.171722 },
    { lat: 49.000647, lng: 20.1715686 },
    { lat: 49.0013196, lng: 20.1714863 },
    { lat: 49.0023119, lng: 20.1712391 },
    { lat: 49.0037317, lng: 20.1707805 },
    { lat: 49.0039318, lng: 20.1708574 },
    { lat: 49.0042506, lng: 20.1707457 },
    { lat: 49.0047309, lng: 20.1702822 },
    { lat: 49.0050438, lng: 20.1698558 },
    { lat: 49.0053289, lng: 20.1698875 },
    { lat: 49.0060306, lng: 20.1694129 },
    { lat: 49.0060988, lng: 20.1691182 },
    { lat: 49.0062301, lng: 20.1688854 },
    { lat: 49.0076595, lng: 20.1676258 },
    { lat: 49.0080462, lng: 20.1675236 },
    { lat: 49.0087415, lng: 20.1670908 },
    { lat: 49.0097955, lng: 20.166804 },
    { lat: 49.0113903, lng: 20.166809 },
    { lat: 49.0123534, lng: 20.166112 },
    { lat: 49.0123654, lng: 20.1660456 },
    { lat: 49.0124295, lng: 20.1658786 },
    { lat: 49.0126619, lng: 20.1655761 },
    { lat: 49.0130208, lng: 20.1645304 },
    { lat: 49.0135868, lng: 20.1640427 },
    { lat: 49.014019, lng: 20.1639234 },
    { lat: 49.0144176, lng: 20.1633597 },
    { lat: 49.0149616, lng: 20.1628844 },
    { lat: 49.0151254, lng: 20.1628136 },
    { lat: 49.015358, lng: 20.1624953 },
    { lat: 49.0154014, lng: 20.1623126 },
    { lat: 49.0161634, lng: 20.1612643 },
    { lat: 49.0154594, lng: 20.1585986 },
    { lat: 49.015355, lng: 20.15782 },
    { lat: 49.0154137, lng: 20.157125 },
    { lat: 49.0151033, lng: 20.1565418 },
    { lat: 49.0149589, lng: 20.1559506 },
    { lat: 49.0150094, lng: 20.1550515 },
    { lat: 49.0151209, lng: 20.1545205 },
    { lat: 49.0153551, lng: 20.1538203 },
    { lat: 49.0154579, lng: 20.153711 },
    { lat: 49.0156438, lng: 20.153129 },
    { lat: 49.0156987, lng: 20.1525872 },
    { lat: 49.0161132, lng: 20.1520844 },
    { lat: 49.0164546, lng: 20.1514742 },
    { lat: 49.0167097, lng: 20.1511265 },
    { lat: 49.0168195, lng: 20.1510521 },
    { lat: 49.0169107, lng: 20.1508556 },
    { lat: 49.0172829, lng: 20.1503718 },
    { lat: 49.0170528, lng: 20.150217 },
    { lat: 49.0169628, lng: 20.1497754 },
    { lat: 49.0168526, lng: 20.1485758 },
    { lat: 49.0170059, lng: 20.1478214 },
    { lat: 49.0169396, lng: 20.1474655 },
    { lat: 49.0169462, lng: 20.1461754 },
    { lat: 49.017017, lng: 20.1460002 },
    { lat: 49.0170817, lng: 20.1455399 },
    { lat: 49.0171624, lng: 20.1439394 },
    { lat: 49.0170283, lng: 20.1426803 },
    { lat: 49.0168122, lng: 20.141704 },
    { lat: 49.0167072, lng: 20.141448 },
    { lat: 49.0165484, lng: 20.1397987 },
    { lat: 49.0165334, lng: 20.1392619 },
    { lat: 49.0166046, lng: 20.1389345 },
    { lat: 49.0163477, lng: 20.1384802 },
    { lat: 49.0164789, lng: 20.1378831 },
    { lat: 49.0165533, lng: 20.1357342 },
    { lat: 49.0166162, lng: 20.1351467 },
    { lat: 49.0171134, lng: 20.1335664 },
    { lat: 49.0176666, lng: 20.1326561 },
    { lat: 49.0178558, lng: 20.1321236 },
    { lat: 49.0178658, lng: 20.1319859 },
    { lat: 49.0176773, lng: 20.1318356 },
    { lat: 49.0174305, lng: 20.1318598 },
    { lat: 49.0173502, lng: 20.131765 },
    { lat: 49.0172496, lng: 20.131337 },
    { lat: 49.0171356, lng: 20.1313086 },
    { lat: 49.0170741, lng: 20.1309824 },
    { lat: 49.0169874, lng: 20.1308987 },
    { lat: 49.0170588, lng: 20.1307682 },
    { lat: 49.0170266, lng: 20.1306715 },
    { lat: 49.0167854, lng: 20.1304672 },
    { lat: 49.0167613, lng: 20.130321 },
    { lat: 49.0163973, lng: 20.1301479 },
    { lat: 49.0163917, lng: 20.1300333 },
    { lat: 49.0160884, lng: 20.1298342 },
    { lat: 49.0159555, lng: 20.1295825 },
    { lat: 49.0154508, lng: 20.1289659 },
    { lat: 49.0153273, lng: 20.128586 },
    { lat: 49.0152678, lng: 20.1286705 },
    { lat: 49.0152085, lng: 20.1285706 },
    { lat: 49.0151208, lng: 20.128544 },
    { lat: 49.0149929, lng: 20.1283345 },
    { lat: 49.0149507, lng: 20.128125 },
    { lat: 49.0148907, lng: 20.1281482 },
    { lat: 49.0146854, lng: 20.1278628 },
    { lat: 49.0145245, lng: 20.1277802 },
    { lat: 49.014473, lng: 20.1275935 },
    { lat: 49.014379, lng: 20.127608 },
    { lat: 49.0143542, lng: 20.127526 },
    { lat: 49.0142245, lng: 20.1275173 },
    { lat: 49.0142297, lng: 20.127425 },
    { lat: 49.0140246, lng: 20.1271718 },
    { lat: 49.0140161, lng: 20.1270155 },
    { lat: 49.0138913, lng: 20.1268001 },
    { lat: 49.0137603, lng: 20.1258196 },
    { lat: 49.0136941, lng: 20.1256822 },
    { lat: 49.0135566, lng: 20.1256571 },
    { lat: 49.0136037, lng: 20.1252573 },
    { lat: 49.0135112, lng: 20.1252817 },
    { lat: 49.0135166, lng: 20.1251399 },
    { lat: 49.013423, lng: 20.1250539 },
    { lat: 49.0133755, lng: 20.124739 },
    { lat: 49.0132959, lng: 20.1246955 },
    { lat: 49.0132651, lng: 20.1243246 },
    { lat: 49.0131592, lng: 20.124159 },
    { lat: 49.0130039, lng: 20.1240459 },
    { lat: 49.0129077, lng: 20.1240595 },
    { lat: 49.0128395, lng: 20.1242119 },
    { lat: 49.0127957, lng: 20.1242149 },
    { lat: 49.0126751, lng: 20.1241039 },
    { lat: 49.0125527, lng: 20.123861 },
    { lat: 49.0124678, lng: 20.1238205 },
    { lat: 49.0124272, lng: 20.1239602 },
    { lat: 49.0121719, lng: 20.1239673 },
    { lat: 49.0120077, lng: 20.1241448 },
    { lat: 49.0119199, lng: 20.1240852 },
    { lat: 49.0118067, lng: 20.1241876 },
    { lat: 49.0116371, lng: 20.1241945 },
    { lat: 49.0115241, lng: 20.1240512 },
    { lat: 49.0114597, lng: 20.1240439 },
    { lat: 49.0114327, lng: 20.123904 },
    { lat: 49.011309, lng: 20.1239357 },
    { lat: 49.011283, lng: 20.1238248 },
    { lat: 49.0111027, lng: 20.1239302 },
    { lat: 49.0110432, lng: 20.1238547 },
    { lat: 49.0109624, lng: 20.1239168 },
    { lat: 49.010898, lng: 20.1238532 },
    { lat: 49.0109045, lng: 20.1237396 },
    { lat: 49.0108232, lng: 20.1235377 },
    { lat: 49.0106405, lng: 20.1234214 },
    { lat: 49.0105684, lng: 20.1232247 },
    { lat: 49.0104046, lng: 20.1231324 },
    { lat: 49.0102825, lng: 20.1231474 },
    { lat: 49.0101966, lng: 20.122942 },
    { lat: 49.0101381, lng: 20.123002 },
    { lat: 49.0098997, lng: 20.1228435 },
    { lat: 49.0098335, lng: 20.1229042 },
    { lat: 49.0096459, lng: 20.1228052 },
    { lat: 49.0096461, lng: 20.1229504 },
    { lat: 49.0095375, lng: 20.1229079 },
    { lat: 49.0094764, lng: 20.1230671 },
    { lat: 49.0094351, lng: 20.1229957 },
    { lat: 49.0093729, lng: 20.1230169 },
    { lat: 49.0093711, lng: 20.1231114 },
    { lat: 49.0092539, lng: 20.1231902 },
    { lat: 49.0092013, lng: 20.1233361 },
    { lat: 49.0089085, lng: 20.1234263 },
    { lat: 49.008875, lng: 20.1234542 },
    { lat: 49.0088863, lng: 20.1235718 },
    { lat: 49.0087944, lng: 20.1235284 },
    { lat: 49.0087343, lng: 20.1237116 },
    { lat: 49.0087558, lng: 20.1237997 },
    { lat: 49.0086677, lng: 20.1239015 },
    { lat: 49.0084404, lng: 20.1238475 },
    { lat: 49.0082883, lng: 20.1240142 },
    { lat: 49.0081652, lng: 20.1238933 },
    { lat: 49.0080876, lng: 20.1240358 },
    { lat: 49.0079826, lng: 20.1240883 },
    { lat: 49.0079, lng: 20.1240473 },
    { lat: 49.0075734, lng: 20.1241069 },
    { lat: 49.0074783, lng: 20.123954 },
    { lat: 49.0073811, lng: 20.123972 },
    { lat: 49.0071737, lng: 20.1235782 },
    { lat: 49.0069801, lng: 20.1235809 },
    { lat: 49.0066262, lng: 20.1237424 },
    { lat: 49.0062427, lng: 20.1237517 },
    { lat: 49.0060756, lng: 20.1234076 },
    { lat: 49.0059665, lng: 20.1233604 },
    { lat: 49.0058664, lng: 20.1231968 },
    { lat: 49.0056654, lng: 20.1231805 },
    { lat: 49.0056318, lng: 20.1230502 },
    { lat: 49.0055503, lng: 20.1230374 },
    { lat: 49.0055637, lng: 20.1229245 },
    { lat: 49.0054341, lng: 20.122725 },
    { lat: 49.0054373, lng: 20.1226419 },
    { lat: 49.0052982, lng: 20.122709 },
    { lat: 49.0052317, lng: 20.1224591 },
    { lat: 49.0051752, lng: 20.12251 },
    { lat: 49.0051511, lng: 20.122386 },
    { lat: 49.0050311, lng: 20.1224535 },
    { lat: 49.0050757, lng: 20.1222212 },
    { lat: 49.0049669, lng: 20.1222038 },
    { lat: 49.004955, lng: 20.1221313 },
    { lat: 49.0050151, lng: 20.122059 },
    { lat: 49.0048532, lng: 20.1219238 },
    { lat: 49.0048702, lng: 20.1218419 },
    { lat: 49.004671, lng: 20.1215682 },
    { lat: 49.0046714, lng: 20.1213271 },
    { lat: 49.0045187, lng: 20.1210085 },
    { lat: 49.0044221, lng: 20.1204049 },
    { lat: 49.0043606, lng: 20.1203324 },
    { lat: 49.0042801, lng: 20.1194288 },
    { lat: 49.0041652, lng: 20.1193201 },
    { lat: 49.004153, lng: 20.1191003 },
    { lat: 49.003999, lng: 20.1186889 },
    { lat: 49.0039361, lng: 20.1186618 },
    { lat: 49.0038954, lng: 20.118497 },
    { lat: 49.0037806, lng: 20.1184942 },
    { lat: 49.0037241, lng: 20.1183807 },
    { lat: 49.0037102, lng: 20.1176786 },
    { lat: 49.0035993, lng: 20.1173281 },
    { lat: 49.0036086, lng: 20.1170589 },
    { lat: 49.0035477, lng: 20.1166977 },
    { lat: 49.0036116, lng: 20.116521 },
    { lat: 49.0035753, lng: 20.1164881 },
    { lat: 49.0035868, lng: 20.1163572 },
    { lat: 49.003512, lng: 20.1162598 },
    { lat: 49.0035189, lng: 20.1159586 },
    { lat: 49.0034265, lng: 20.1157344 },
    { lat: 49.0034756, lng: 20.1153091 },
    { lat: 49.003439, lng: 20.1151586 },
    { lat: 49.0034684, lng: 20.1150737 },
    { lat: 49.0034418, lng: 20.1144757 },
    { lat: 49.0033975, lng: 20.1144376 },
    { lat: 49.0033792, lng: 20.1141842 },
    { lat: 49.0035031, lng: 20.1139864 },
    { lat: 49.0035844, lng: 20.1136861 },
    { lat: 49.0036807, lng: 20.1136175 },
    { lat: 49.0038204, lng: 20.1128713 },
    { lat: 49.0039243, lng: 20.1127101 },
    { lat: 49.0039086, lng: 20.1126036 },
    { lat: 49.0040648, lng: 20.111981 },
    { lat: 49.0038971, lng: 20.1115415 },
    { lat: 49.0037133, lng: 20.1108678 },
    { lat: 49.0036254, lng: 20.1103129 },
    { lat: 49.0035878, lng: 20.110305 },
    { lat: 49.0034374, lng: 20.1097642 },
    { lat: 49.0029622, lng: 20.1098471 },
    { lat: 49.0024811, lng: 20.1088456 },
    { lat: 49.00219, lng: 20.108469 },
    { lat: 49.00197, lng: 20.1078526 },
    { lat: 49.0017972, lng: 20.1075618 },
    { lat: 49.0003246, lng: 20.1064062 },
    { lat: 49.0001955, lng: 20.1067555 },
    { lat: 48.9993891, lng: 20.1067501 },
    { lat: 48.9987093, lng: 20.1067063 },
    { lat: 48.9965417, lng: 20.1063302 },
    { lat: 48.9961103, lng: 20.1060235 },
    { lat: 48.9952702, lng: 20.1049744 },
    { lat: 48.9950164, lng: 20.1043796 },
    { lat: 48.9950798, lng: 20.103921 },
    { lat: 48.9949338, lng: 20.1037662 },
    { lat: 48.9947895, lng: 20.102655 },
    { lat: 48.9949856, lng: 20.1021549 },
    { lat: 48.9948419, lng: 20.1016168 },
    { lat: 48.9949047, lng: 20.1005557 },
    { lat: 48.9944985, lng: 20.0995399 },
    { lat: 48.9941527, lng: 20.0988773 },
    { lat: 48.994137, lng: 20.0985216 },
    { lat: 48.9940893, lng: 20.0984002 },
    { lat: 48.9937617, lng: 20.0977951 },
    { lat: 48.9931814, lng: 20.0975947 },
    { lat: 48.9931154, lng: 20.0974391 },
    { lat: 48.9929356, lng: 20.0975052 },
    { lat: 48.9922998, lng: 20.0972655 },
    { lat: 48.9920497, lng: 20.0968806 },
    { lat: 48.9923976, lng: 20.0959663 },
    { lat: 48.991945, lng: 20.0955272 },
    { lat: 48.9914162, lng: 20.0960898 },
    { lat: 48.9908675, lng: 20.0958653 },
    { lat: 48.9906573, lng: 20.0956244 },
    { lat: 48.9904559, lng: 20.0955458 },
    { lat: 48.9899888, lng: 20.0948893 },
    { lat: 48.988835, lng: 20.0940806 },
    { lat: 48.9884943, lng: 20.0927406 },
    { lat: 48.988151, lng: 20.0917222 },
    { lat: 48.9882453, lng: 20.09072 },
    { lat: 48.988406, lng: 20.0904352 },
    { lat: 48.9886494, lng: 20.090328 },
    { lat: 48.9888579, lng: 20.0900301 },
    { lat: 48.9890692, lng: 20.089852 },
    { lat: 48.9891286, lng: 20.0895377 },
    { lat: 48.9891032, lng: 20.0888067 },
    { lat: 48.9891504, lng: 20.0881976 },
    { lat: 48.9890965, lng: 20.0875378 },
    { lat: 48.9892263, lng: 20.086496 },
    { lat: 48.9892231, lng: 20.0863658 },
  ],
  Ždiar: [
    { lat: 49.2862202, lng: 20.2069561 },
    { lat: 49.2853216, lng: 20.2068218 },
    { lat: 49.2846863, lng: 20.2055107 },
    { lat: 49.2844366, lng: 20.204894 },
    { lat: 49.2840792, lng: 20.2034305 },
    { lat: 49.2829126, lng: 20.2007589 },
    { lat: 49.2817244, lng: 20.2007199 },
    { lat: 49.2811238, lng: 20.2005987 },
    { lat: 49.280798, lng: 20.2003306 },
    { lat: 49.2806286, lng: 20.2004043 },
    { lat: 49.2803515, lng: 20.1998605 },
    { lat: 49.2801184, lng: 20.1995787 },
    { lat: 49.2799604, lng: 20.1990275 },
    { lat: 49.2797503, lng: 20.1985319 },
    { lat: 49.2797225, lng: 20.1982831 },
    { lat: 49.279507, lng: 20.1977321 },
    { lat: 49.2795654, lng: 20.1974973 },
    { lat: 49.2795181, lng: 20.1973278 },
    { lat: 49.2793617, lng: 20.1971634 },
    { lat: 49.2792165, lng: 20.1963686 },
    { lat: 49.2791222, lng: 20.1961498 },
    { lat: 49.2791179, lng: 20.1957574 },
    { lat: 49.2793723, lng: 20.1955546 },
    { lat: 49.2796834, lng: 20.1937192 },
    { lat: 49.2797892, lng: 20.1935424 },
    { lat: 49.280096, lng: 20.1921651 },
    { lat: 49.2801507, lng: 20.1920684 },
    { lat: 49.2801731, lng: 20.1915564 },
    { lat: 49.280242, lng: 20.1914494 },
    { lat: 49.2802414, lng: 20.1906668 },
    { lat: 49.2804681, lng: 20.1900817 },
    { lat: 49.280407, lng: 20.1898168 },
    { lat: 49.2804648, lng: 20.1896549 },
    { lat: 49.2804495, lng: 20.1894717 },
    { lat: 49.280561, lng: 20.189356 },
    { lat: 49.2805701, lng: 20.1889905 },
    { lat: 49.2804525, lng: 20.1888348 },
    { lat: 49.2803805, lng: 20.1884415 },
    { lat: 49.2800715, lng: 20.1879735 },
    { lat: 49.2800254, lng: 20.1877228 },
    { lat: 49.2797467, lng: 20.1873646 },
    { lat: 49.2796592, lng: 20.1870924 },
    { lat: 49.2794376, lng: 20.1867 },
    { lat: 49.2793636, lng: 20.1844701 },
    { lat: 49.2793211, lng: 20.1841674 },
    { lat: 49.2791892, lng: 20.1837386 },
    { lat: 49.2790491, lng: 20.1836387 },
    { lat: 49.2789536, lng: 20.1833612 },
    { lat: 49.2789066, lng: 20.1830879 },
    { lat: 49.2789261, lng: 20.1824755 },
    { lat: 49.2788906, lng: 20.1824611 },
    { lat: 49.2786309, lng: 20.1824686 },
    { lat: 49.2784546, lng: 20.182652 },
    { lat: 49.2781663, lng: 20.1827888 },
    { lat: 49.2779191, lng: 20.1830058 },
    { lat: 49.2776706, lng: 20.1830689 },
    { lat: 49.2773195, lng: 20.1837517 },
    { lat: 49.2768985, lng: 20.1838164 },
    { lat: 49.2768607, lng: 20.1840818 },
    { lat: 49.2767925, lng: 20.1841483 },
    { lat: 49.2763882, lng: 20.1841512 },
    { lat: 49.2758683, lng: 20.1848097 },
    { lat: 49.275769, lng: 20.1852023 },
    { lat: 49.2753657, lng: 20.1851943 },
    { lat: 49.2750088, lng: 20.1854824 },
    { lat: 49.2747501, lng: 20.1856049 },
    { lat: 49.2745954, lng: 20.1862723 },
    { lat: 49.2742597, lng: 20.1866064 },
    { lat: 49.2741901, lng: 20.1876359 },
    { lat: 49.2740933, lng: 20.1879771 },
    { lat: 49.2740575, lng: 20.1879505 },
    { lat: 49.2739756, lng: 20.1881041 },
    { lat: 49.2731967, lng: 20.1872305 },
    { lat: 49.2708251, lng: 20.1854444 },
    { lat: 49.2706292, lng: 20.1851067 },
    { lat: 49.2702106, lng: 20.1850291 },
    { lat: 49.2697404, lng: 20.1845564 },
    { lat: 49.2696095, lng: 20.1842649 },
    { lat: 49.2694194, lng: 20.184156 },
    { lat: 49.2679539, lng: 20.1848527 },
    { lat: 49.2665997, lng: 20.1857421 },
    { lat: 49.266429, lng: 20.1868609 },
    { lat: 49.266021, lng: 20.1871652 },
    { lat: 49.2658355, lng: 20.187424 },
    { lat: 49.2655845, lng: 20.1875151 },
    { lat: 49.2653256, lng: 20.1877691 },
    { lat: 49.2651751, lng: 20.1880611 },
    { lat: 49.2649784, lng: 20.1881832 },
    { lat: 49.2646309, lng: 20.1882569 },
    { lat: 49.2642897, lng: 20.1881684 },
    { lat: 49.2636165, lng: 20.1884295 },
    { lat: 49.2633544, lng: 20.188407 },
    { lat: 49.2629948, lng: 20.1881393 },
    { lat: 49.2627533, lng: 20.1876731 },
    { lat: 49.2625325, lng: 20.1875862 },
    { lat: 49.2626085, lng: 20.1875146 },
    { lat: 49.2623039, lng: 20.1871585 },
    { lat: 49.26125, lng: 20.1860885 },
    { lat: 49.2603208, lng: 20.186196 },
    { lat: 49.2590762, lng: 20.1861326 },
    { lat: 49.2574951, lng: 20.1845698 },
    { lat: 49.2556923, lng: 20.1846581 },
    { lat: 49.2550855, lng: 20.1848558 },
    { lat: 49.253914, lng: 20.1861522 },
    { lat: 49.2523755, lng: 20.1867088 },
    { lat: 49.2515785, lng: 20.1866576 },
    { lat: 49.2513855, lng: 20.1870154 },
    { lat: 49.2503294, lng: 20.1874439 },
    { lat: 49.250407, lng: 20.1887444 },
    { lat: 49.2500978, lng: 20.1895488 },
    { lat: 49.2485178, lng: 20.1950766 },
    { lat: 49.2484735, lng: 20.1977087 },
    { lat: 49.2483797, lng: 20.1983827 },
    { lat: 49.2479329, lng: 20.1992437 },
    { lat: 49.2471484, lng: 20.1997643 },
    { lat: 49.2467845, lng: 20.2008641 },
    { lat: 49.2460902, lng: 20.201732 },
    { lat: 49.2454378, lng: 20.2028804 },
    { lat: 49.244992, lng: 20.2039945 },
    { lat: 49.2450882, lng: 20.2048921 },
    { lat: 49.2450731, lng: 20.204989 },
    { lat: 49.2446336, lng: 20.2079997 },
    { lat: 49.2446481, lng: 20.2112128 },
    { lat: 49.2451987, lng: 20.2137754 },
    { lat: 49.2461383, lng: 20.2168932 },
    { lat: 49.2464233, lng: 20.2169805 },
    { lat: 49.2473892, lng: 20.217577 },
    { lat: 49.2491805, lng: 20.2175428 },
    { lat: 49.2502347, lng: 20.2180325 },
    { lat: 49.250597, lng: 20.2193079 },
    { lat: 49.2502765, lng: 20.2221478 },
    { lat: 49.2502804, lng: 20.2231254 },
    { lat: 49.2502084, lng: 20.2233573 },
    { lat: 49.251623, lng: 20.2252211 },
    { lat: 49.251762, lng: 20.2254671 },
    { lat: 49.2519401, lng: 20.2262373 },
    { lat: 49.2528184, lng: 20.2274956 },
    { lat: 49.2529564, lng: 20.2280593 },
    { lat: 49.252964, lng: 20.2281913 },
    { lat: 49.2515367, lng: 20.2322672 },
    { lat: 49.2516149, lng: 20.2337397 },
    { lat: 49.252497, lng: 20.2349235 },
    { lat: 49.2529358, lng: 20.235172 },
    { lat: 49.253777, lng: 20.235948 },
    { lat: 49.2541935, lng: 20.2364577 },
    { lat: 49.2547334, lng: 20.236614 },
    { lat: 49.2552448, lng: 20.2370483 },
    { lat: 49.2556906, lng: 20.237219 },
    { lat: 49.2564344, lng: 20.2379766 },
    { lat: 49.2565049, lng: 20.2381133 },
    { lat: 49.25672, lng: 20.2389479 },
    { lat: 49.2568505, lng: 20.2391692 },
    { lat: 49.2576796, lng: 20.239865 },
    { lat: 49.2577511, lng: 20.2398227 },
    { lat: 49.2581682, lng: 20.2402109 },
    { lat: 49.2582, lng: 20.2403594 },
    { lat: 49.2583768, lng: 20.2406215 },
    { lat: 49.2585334, lng: 20.2406793 },
    { lat: 49.2590553, lng: 20.2404079 },
    { lat: 49.2591829, lng: 20.2402138 },
    { lat: 49.2597175, lng: 20.240177 },
    { lat: 49.2599505, lng: 20.2402635 },
    { lat: 49.2602727, lng: 20.2408684 },
    { lat: 49.2608103, lng: 20.2412546 },
    { lat: 49.2609752, lng: 20.2415225 },
    { lat: 49.261175, lng: 20.2423103 },
    { lat: 49.2615476, lng: 20.2429651 },
    { lat: 49.2620993, lng: 20.2432257 },
    { lat: 49.2624615, lng: 20.2436774 },
    { lat: 49.2630797, lng: 20.2438739 },
    { lat: 49.2632934, lng: 20.2440122 },
    { lat: 49.2637579, lng: 20.2436304 },
    { lat: 49.2642643, lng: 20.243421 },
    { lat: 49.2644747, lng: 20.2435027 },
    { lat: 49.2646452, lng: 20.2434824 },
    { lat: 49.2647663, lng: 20.2432683 },
    { lat: 49.2648552, lng: 20.2432111 },
    { lat: 49.2649322, lng: 20.2431951 },
    { lat: 49.2652698, lng: 20.2435326 },
    { lat: 49.2654668, lng: 20.243446 },
    { lat: 49.2657628, lng: 20.2434427 },
    { lat: 49.265845, lng: 20.2433548 },
    { lat: 49.2663185, lng: 20.2431682 },
    { lat: 49.2664744, lng: 20.2428111 },
    { lat: 49.2667365, lng: 20.2428588 },
    { lat: 49.2667802, lng: 20.2427697 },
    { lat: 49.2668388, lng: 20.2419372 },
    { lat: 49.2672349, lng: 20.2420184 },
    { lat: 49.2675173, lng: 20.2421851 },
    { lat: 49.2684832, lng: 20.2443909 },
    { lat: 49.2686203, lng: 20.244873 },
    { lat: 49.2686532, lng: 20.2453924 },
    { lat: 49.2681495, lng: 20.2460739 },
    { lat: 49.2679862, lng: 20.246589 },
    { lat: 49.2679702, lng: 20.2464956 },
    { lat: 49.2678265, lng: 20.2469937 },
    { lat: 49.2675363, lng: 20.247489 },
    { lat: 49.2673612, lng: 20.2476504 },
    { lat: 49.2670034, lng: 20.2477437 },
    { lat: 49.2669174, lng: 20.2478734 },
    { lat: 49.2666807, lng: 20.2494783 },
    { lat: 49.2668826, lng: 20.2520724 },
    { lat: 49.2667776, lng: 20.252524 },
    { lat: 49.2667303, lng: 20.2535285 },
    { lat: 49.2667883, lng: 20.253833 },
    { lat: 49.2666538, lng: 20.2541049 },
    { lat: 49.2662607, lng: 20.2552264 },
    { lat: 49.2664694, lng: 20.2554925 },
    { lat: 49.2668215, lng: 20.2556882 },
    { lat: 49.2669091, lng: 20.2558828 },
    { lat: 49.2669403, lng: 20.2561317 },
    { lat: 49.2668919, lng: 20.2570822 },
    { lat: 49.266572, lng: 20.2575668 },
    { lat: 49.2662862, lng: 20.2577757 },
    { lat: 49.2662285, lng: 20.2579418 },
    { lat: 49.2662444, lng: 20.2580885 },
    { lat: 49.2663513, lng: 20.2582893 },
    { lat: 49.2667901, lng: 20.2584547 },
    { lat: 49.2668818, lng: 20.2585495 },
    { lat: 49.2668182, lng: 20.2604958 },
    { lat: 49.2668951, lng: 20.2616 },
    { lat: 49.267575, lng: 20.2631175 },
    { lat: 49.2681144, lng: 20.2638442 },
    { lat: 49.2682468, lng: 20.264466 },
    { lat: 49.2682701, lng: 20.2647736 },
    { lat: 49.2685824, lng: 20.2654176 },
    { lat: 49.2686659, lng: 20.2658819 },
    { lat: 49.2689908, lng: 20.2669683 },
    { lat: 49.2691187, lng: 20.2670413 },
    { lat: 49.2693681, lng: 20.2676348 },
    { lat: 49.2696944, lng: 20.2693257 },
    { lat: 49.2695915, lng: 20.2699787 },
    { lat: 49.2694579, lng: 20.2702808 },
    { lat: 49.2695224, lng: 20.2706333 },
    { lat: 49.2696028, lng: 20.2707893 },
    { lat: 49.2699831, lng: 20.2712158 },
    { lat: 49.2704399, lng: 20.2715564 },
    { lat: 49.2707182, lng: 20.2720175 },
    { lat: 49.2709814, lng: 20.2727349 },
    { lat: 49.2707486, lng: 20.274506 },
    { lat: 49.2704386, lng: 20.274955 },
    { lat: 49.2701629, lng: 20.275222 },
    { lat: 49.2700706, lng: 20.2755369 },
    { lat: 49.2701504, lng: 20.2760521 },
    { lat: 49.2705219, lng: 20.2768624 },
    { lat: 49.2704166, lng: 20.2775142 },
    { lat: 49.2701716, lng: 20.2785363 },
    { lat: 49.2700745, lng: 20.2792825 },
    { lat: 49.2700629, lng: 20.2795309 },
    { lat: 49.2701585, lng: 20.2800223 },
    { lat: 49.2701511, lng: 20.2804694 },
    { lat: 49.2699965, lng: 20.2806929 },
    { lat: 49.2693566, lng: 20.2811466 },
    { lat: 49.2689133, lng: 20.2815893 },
    { lat: 49.268775, lng: 20.2822029 },
    { lat: 49.2684817, lng: 20.2823042 },
    { lat: 49.2683867, lng: 20.2824102 },
    { lat: 49.2681233, lng: 20.2828978 },
    { lat: 49.2680338, lng: 20.2829745 },
    { lat: 49.2678331, lng: 20.2834271 },
    { lat: 49.2674652, lng: 20.2837274 },
    { lat: 49.267298, lng: 20.2840591 },
    { lat: 49.2670975, lng: 20.2847202 },
    { lat: 49.2667558, lng: 20.2856046 },
    { lat: 49.2665276, lng: 20.2860533 },
    { lat: 49.2664795, lng: 20.2863764 },
    { lat: 49.2665363, lng: 20.2866521 },
    { lat: 49.2665866, lng: 20.2888454 },
    { lat: 49.2660768, lng: 20.2919508 },
    { lat: 49.2658768, lng: 20.2935283 },
    { lat: 49.2657769, lng: 20.2938304 },
    { lat: 49.2656172, lng: 20.2940064 },
    { lat: 49.2653276, lng: 20.294028 },
    { lat: 49.2652618, lng: 20.2949461 },
    { lat: 49.2648297, lng: 20.2956497 },
    { lat: 49.2644242, lng: 20.2966876 },
    { lat: 49.2635732, lng: 20.2982633 },
    { lat: 49.2635413, lng: 20.298772 },
    { lat: 49.263389, lng: 20.2995497 },
    { lat: 49.2632699, lng: 20.2997873 },
    { lat: 49.2641194, lng: 20.3010519 },
    { lat: 49.2644494, lng: 20.3014312 },
    { lat: 49.2645202, lng: 20.3016022 },
    { lat: 49.2646823, lng: 20.3017199 },
    { lat: 49.2648771, lng: 20.302009 },
    { lat: 49.2650763, lng: 20.3021803 },
    { lat: 49.2656396, lng: 20.3034392 },
    { lat: 49.2658029, lng: 20.3039082 },
    { lat: 49.2662486, lng: 20.3046881 },
    { lat: 49.2666808, lng: 20.305236 },
    { lat: 49.2671473, lng: 20.3056204 },
    { lat: 49.2675007, lng: 20.3056504 },
    { lat: 49.2678202, lng: 20.3059006 },
    { lat: 49.268344, lng: 20.3060552 },
    { lat: 49.2686721, lng: 20.3068818 },
    { lat: 49.268917, lng: 20.3071309 },
    { lat: 49.2691381, lng: 20.3071978 },
    { lat: 49.2696689, lng: 20.3077492 },
    { lat: 49.2701905, lng: 20.3083221 },
    { lat: 49.2704045, lng: 20.3087322 },
    { lat: 49.2707227, lng: 20.3088708 },
    { lat: 49.271163, lng: 20.3094807 },
    { lat: 49.271566, lng: 20.3095666 },
    { lat: 49.2717617, lng: 20.3099354 },
    { lat: 49.2722678, lng: 20.3104475 },
    { lat: 49.2724353, lng: 20.310471 },
    { lat: 49.2726186, lng: 20.3106975 },
    { lat: 49.2733294, lng: 20.3112315 },
    { lat: 49.2735326, lng: 20.3115319 },
    { lat: 49.2737133, lng: 20.3119292 },
    { lat: 49.2741306, lng: 20.3122074 },
    { lat: 49.2743313, lng: 20.3122426 },
    { lat: 49.2745396, lng: 20.312504 },
    { lat: 49.2746451, lng: 20.3125543 },
    { lat: 49.274862, lng: 20.3128662 },
    { lat: 49.2752595, lng: 20.3128656 },
    { lat: 49.2754035, lng: 20.3129525 },
    { lat: 49.2758867, lng: 20.3130131 },
    { lat: 49.2759814, lng: 20.3131315 },
    { lat: 49.2761223, lng: 20.3131703 },
    { lat: 49.2762007, lng: 20.3130973 },
    { lat: 49.2765345, lng: 20.3130848 },
    { lat: 49.2767743, lng: 20.3133131 },
    { lat: 49.2770676, lng: 20.3137096 },
    { lat: 49.2777847, lng: 20.3139684 },
    { lat: 49.2793507, lng: 20.3142277 },
    { lat: 49.2800616, lng: 20.3148206 },
    { lat: 49.2805365, lng: 20.315014 },
    { lat: 49.2810665, lng: 20.3146863 },
    { lat: 49.2815593, lng: 20.3146794 },
    { lat: 49.2826643, lng: 20.3141938 },
    { lat: 49.2829609, lng: 20.3141814 },
    { lat: 49.2831572, lng: 20.3142503 },
    { lat: 49.2834361, lng: 20.3141829 },
    { lat: 49.2834126, lng: 20.3130186 },
    { lat: 49.2834687, lng: 20.3119349 },
    { lat: 49.284923, lng: 20.311931 },
    { lat: 49.284787, lng: 20.310775 },
    { lat: 49.28466, lng: 20.309355 },
    { lat: 49.284684, lng: 20.307396 },
    { lat: 49.284652, lng: 20.305928 },
    { lat: 49.284751, lng: 20.304673 },
    { lat: 49.284923, lng: 20.303557 },
    { lat: 49.284927, lng: 20.302974 },
    { lat: 49.285061, lng: 20.302095 },
    { lat: 49.285725, lng: 20.300844 },
    { lat: 49.286709, lng: 20.299321 },
    { lat: 49.286808, lng: 20.299215 },
    { lat: 49.287083, lng: 20.297665 },
    { lat: 49.287128, lng: 20.296762 },
    { lat: 49.286973, lng: 20.296053 },
    { lat: 49.287002, lng: 20.295219 },
    { lat: 49.286815, lng: 20.294323 },
    { lat: 49.286766, lng: 20.292997 },
    { lat: 49.286747, lng: 20.291117 },
    { lat: 49.286849, lng: 20.289081 },
    { lat: 49.286908, lng: 20.288267 },
    { lat: 49.286792, lng: 20.287702 },
    { lat: 49.286362, lng: 20.286506 },
    { lat: 49.286051, lng: 20.285607 },
    { lat: 49.285946, lng: 20.284927 },
    { lat: 49.286049, lng: 20.284547 },
    { lat: 49.286212, lng: 20.283945 },
    { lat: 49.286565, lng: 20.282733 },
    { lat: 49.287102, lng: 20.280944 },
    { lat: 49.287503, lng: 20.279259 },
    { lat: 49.288103, lng: 20.277551 },
    { lat: 49.288349, lng: 20.276536 },
    { lat: 49.288642, lng: 20.275626 },
    { lat: 49.288671, lng: 20.275043 },
    { lat: 49.288872, lng: 20.274531 },
    { lat: 49.289108, lng: 20.273866 },
    { lat: 49.289093, lng: 20.272434 },
    { lat: 49.289471, lng: 20.271964 },
    { lat: 49.289704, lng: 20.271552 },
    { lat: 49.289824, lng: 20.270654 },
    { lat: 49.289946, lng: 20.269519 },
    { lat: 49.290455, lng: 20.269074 },
    { lat: 49.291152, lng: 20.267906 },
    { lat: 49.291561, lng: 20.267182 },
    { lat: 49.29116, lng: 20.265954 },
    { lat: 49.291089, lng: 20.264574 },
    { lat: 49.290981, lng: 20.262766 },
    { lat: 49.290924, lng: 20.260742 },
    { lat: 49.29098, lng: 20.258887 },
    { lat: 49.290944, lng: 20.258475 },
    { lat: 49.291137, lng: 20.2579 },
    { lat: 49.291317, lng: 20.256421 },
    { lat: 49.291252, lng: 20.255284 },
    { lat: 49.290919, lng: 20.253804 },
    { lat: 49.290695, lng: 20.252189 },
    { lat: 49.290623, lng: 20.251076 },
    { lat: 49.29074, lng: 20.248626 },
    { lat: 49.29096, lng: 20.24718 },
    { lat: 49.291187, lng: 20.245607 },
    { lat: 49.291408, lng: 20.243969 },
    { lat: 49.291478, lng: 20.242418 },
    { lat: 49.29146, lng: 20.241621 },
    { lat: 49.291322, lng: 20.240788 },
    { lat: 49.290676, lng: 20.240155 },
    { lat: 49.290226, lng: 20.239834 },
    { lat: 49.2898565, lng: 20.2394684 },
    { lat: 49.2895058, lng: 20.2391261 },
    { lat: 49.2893703, lng: 20.2389672 },
    { lat: 49.2889238, lng: 20.2384967 },
    { lat: 49.2885053, lng: 20.2381911 },
    { lat: 49.2882143, lng: 20.2379161 },
    { lat: 49.2878316, lng: 20.2376289 },
    { lat: 49.2875884, lng: 20.2372988 },
    { lat: 49.287212, lng: 20.236653 },
    { lat: 49.2867901, lng: 20.2357003 },
    { lat: 49.286733, lng: 20.235595 },
    { lat: 49.286451, lng: 20.235079 },
    { lat: 49.2860936, lng: 20.233962 },
    { lat: 49.2854307, lng: 20.2322203 },
    { lat: 49.2852354, lng: 20.2317121 },
    { lat: 49.2850163, lng: 20.2311857 },
    { lat: 49.2847855, lng: 20.2309588 },
    { lat: 49.2843, lng: 20.230496 },
    { lat: 49.2836252, lng: 20.2302418 },
    { lat: 49.2832641, lng: 20.2301511 },
    { lat: 49.283179, lng: 20.229955 },
    { lat: 49.282801, lng: 20.228977 },
    { lat: 49.2825536, lng: 20.2283088 },
    { lat: 49.2823895, lng: 20.2275167 },
    { lat: 49.2823106, lng: 20.2273206 },
    { lat: 49.2821705, lng: 20.2269311 },
    { lat: 49.2820898, lng: 20.2265899 },
    { lat: 49.2820478, lng: 20.2263482 },
    { lat: 49.2820566, lng: 20.2259828 },
    { lat: 49.2820057, lng: 20.225784 },
    { lat: 49.2819269, lng: 20.2256981 },
    { lat: 49.2817744, lng: 20.2255262 },
    { lat: 49.2817464, lng: 20.2252898 },
    { lat: 49.281734, lng: 20.22495 },
    { lat: 49.281562, lng: 20.2234 },
    { lat: 49.281906, lng: 20.222231 },
    { lat: 49.28196, lng: 20.22139 },
    { lat: 49.281927, lng: 20.221129 },
    { lat: 49.281929, lng: 20.221072 },
    { lat: 49.281909, lng: 20.220763 },
    { lat: 49.282064, lng: 20.220043 },
    { lat: 49.282293, lng: 20.21888 },
    { lat: 49.282346, lng: 20.21872 },
    { lat: 49.282409, lng: 20.218525 },
    { lat: 49.282657, lng: 20.217788 },
    { lat: 49.28286, lng: 20.21701 },
    { lat: 49.283042, lng: 20.216426 },
    { lat: 49.283095, lng: 20.216268 },
    { lat: 49.283272, lng: 20.215731 },
    { lat: 49.283649, lng: 20.215249 },
    { lat: 49.2839, lng: 20.215106 },
    { lat: 49.284341, lng: 20.214718 },
    { lat: 49.284756, lng: 20.213773 },
    { lat: 49.285061, lng: 20.212761 },
    { lat: 49.285229, lng: 20.212229 },
    { lat: 49.285816, lng: 20.211308 },
    { lat: 49.285984, lng: 20.210816 },
    { lat: 49.286097, lng: 20.209941 },
    { lat: 49.286224, lng: 20.208403 },
    { lat: 49.2862202, lng: 20.2069561 },
  ],
  SpišskýŠtiavnik: [
    { lat: 49.0078718, lng: 20.3586662 },
    { lat: 49.0078412, lng: 20.3585456 },
    { lat: 49.0080777, lng: 20.3571107 },
    { lat: 49.0084392, lng: 20.3562593 },
    { lat: 49.0085899, lng: 20.3550006 },
    { lat: 49.0086226, lng: 20.3531581 },
    { lat: 49.0087605, lng: 20.3510056 },
    { lat: 49.0087463, lng: 20.3506377 },
    { lat: 49.0082893, lng: 20.3492419 },
    { lat: 49.0082546, lng: 20.348806 },
    { lat: 49.0082383, lng: 20.34847 },
    { lat: 49.0083454, lng: 20.3469576 },
    { lat: 49.0082471, lng: 20.3460729 },
    { lat: 49.0082873, lng: 20.3450715 },
    { lat: 49.0083642, lng: 20.3446973 },
    { lat: 49.0083695, lng: 20.344425 },
    { lat: 49.0082833, lng: 20.343452 },
    { lat: 49.0083887, lng: 20.3425944 },
    { lat: 49.0086402, lng: 20.3415766 },
    { lat: 49.0090989, lng: 20.3391705 },
    { lat: 49.0092105, lng: 20.3366118 },
    { lat: 49.0092935, lng: 20.3359315 },
    { lat: 49.0093229, lng: 20.3352022 },
    { lat: 49.0092131, lng: 20.3335584 },
    { lat: 49.0092258, lng: 20.3327843 },
    { lat: 49.0091325, lng: 20.3319408 },
    { lat: 49.0044558, lng: 20.3317022 },
    { lat: 48.9984869, lng: 20.3317372 },
    { lat: 48.9974162, lng: 20.3315923 },
    { lat: 48.9928486, lng: 20.3312053 },
    { lat: 48.9923932, lng: 20.3311373 },
    { lat: 48.9914584, lng: 20.3308648 },
    { lat: 48.9904069, lng: 20.3307304 },
    { lat: 48.988005, lng: 20.3296802 },
    { lat: 48.9834889, lng: 20.3267864 },
    { lat: 48.9803685, lng: 20.3250681 },
    { lat: 48.9797687, lng: 20.3246715 },
    { lat: 48.9787825, lng: 20.3237751 },
    { lat: 48.9786827, lng: 20.3241041 },
    { lat: 48.9777879, lng: 20.3236225 },
    { lat: 48.9770698, lng: 20.3226716 },
    { lat: 48.9761089, lng: 20.3221103 },
    { lat: 48.9757622, lng: 20.3217906 },
    { lat: 48.9754854, lng: 20.3212202 },
    { lat: 48.9751838, lng: 20.3204098 },
    { lat: 48.9749961, lng: 20.3197574 },
    { lat: 48.9746132, lng: 20.3187695 },
    { lat: 48.9739997, lng: 20.3177617 },
    { lat: 48.9731773, lng: 20.3168475 },
    { lat: 48.9731648, lng: 20.3166716 },
    { lat: 48.9732611, lng: 20.3163984 },
    { lat: 48.9732366, lng: 20.3161752 },
    { lat: 48.9731093, lng: 20.3156798 },
    { lat: 48.9728757, lng: 20.3153172 },
    { lat: 48.9721847, lng: 20.3147798 },
    { lat: 48.9715898, lng: 20.3146257 },
    { lat: 48.9711347, lng: 20.3144163 },
    { lat: 48.9708729, lng: 20.3144004 },
    { lat: 48.9706249, lng: 20.3142187 },
    { lat: 48.9701848, lng: 20.3142542 },
    { lat: 48.9696058, lng: 20.3141043 },
    { lat: 48.969366, lng: 20.3142778 },
    { lat: 48.9690846, lng: 20.31405 },
    { lat: 48.9688706, lng: 20.31359 },
    { lat: 48.9686152, lng: 20.3135946 },
    { lat: 48.9678937, lng: 20.3134271 },
    { lat: 48.9676265, lng: 20.3126343 },
    { lat: 48.9672548, lng: 20.3122881 },
    { lat: 48.9662306, lng: 20.3128799 },
    { lat: 48.9659058, lng: 20.3128947 },
    { lat: 48.9656236, lng: 20.3125613 },
    { lat: 48.9654987, lng: 20.3121539 },
    { lat: 48.9653528, lng: 20.3118233 },
    { lat: 48.9652283, lng: 20.3116618 },
    { lat: 48.9647285, lng: 20.3114314 },
    { lat: 48.9644101, lng: 20.3105429 },
    { lat: 48.9643503, lng: 20.3095824 },
    { lat: 48.9642538, lng: 20.3093639 },
    { lat: 48.9638417, lng: 20.3089565 },
    { lat: 48.9627095, lng: 20.3084224 },
    { lat: 48.9624795, lng: 20.3084768 },
    { lat: 48.962007, lng: 20.3081411 },
    { lat: 48.9610585, lng: 20.3083323 },
    { lat: 48.9606638, lng: 20.3084918 },
    { lat: 48.9599728, lng: 20.3086473 },
    { lat: 48.958936, lng: 20.3092041 },
    { lat: 48.9585536, lng: 20.3096204 },
    { lat: 48.9584188, lng: 20.3096862 },
    { lat: 48.9576885, lng: 20.3094125 },
    { lat: 48.9573286, lng: 20.3094784 },
    { lat: 48.9571271, lng: 20.3097158 },
    { lat: 48.9567779, lng: 20.3097708 },
    { lat: 48.9565219, lng: 20.3099339 },
    { lat: 48.9559897, lng: 20.3107502 },
    { lat: 48.9555732, lng: 20.311133 },
    { lat: 48.9545179, lng: 20.3115616 },
    { lat: 48.9542144, lng: 20.311777 },
    { lat: 48.9539036, lng: 20.3117461 },
    { lat: 48.9529445, lng: 20.3119023 },
    { lat: 48.9527944, lng: 20.31201 },
    { lat: 48.9525364, lng: 20.3124384 },
    { lat: 48.9523626, lng: 20.3125443 },
    { lat: 48.952274, lng: 20.312791 },
    { lat: 48.952648, lng: 20.313102 },
    { lat: 48.953171, lng: 20.313372 },
    { lat: 48.953575, lng: 20.31363 },
    { lat: 48.953954, lng: 20.313967 },
    { lat: 48.954368, lng: 20.314457 },
    { lat: 48.954486, lng: 20.314524 },
    { lat: 48.954691, lng: 20.314617 },
    { lat: 48.954805, lng: 20.31465 },
    { lat: 48.954875, lng: 20.314658 },
    { lat: 48.955157, lng: 20.314565 },
    { lat: 48.955175, lng: 20.314559 },
    { lat: 48.95519, lng: 20.314564 },
    { lat: 48.955211, lng: 20.314571 },
    { lat: 48.955323, lng: 20.314678 },
    { lat: 48.955373, lng: 20.314864 },
    { lat: 48.955439, lng: 20.314996 },
    { lat: 48.955774, lng: 20.315278 },
    { lat: 48.95588, lng: 20.315441 },
    { lat: 48.955956, lng: 20.315773 },
    { lat: 48.955975, lng: 20.315822 },
    { lat: 48.956035, lng: 20.315961 },
    { lat: 48.956217, lng: 20.316144 },
    { lat: 48.956198, lng: 20.316147 },
    { lat: 48.956224, lng: 20.316177 },
    { lat: 48.956301, lng: 20.316267 },
    { lat: 48.956437, lng: 20.316466 },
    { lat: 48.956512, lng: 20.316711 },
    { lat: 48.956591, lng: 20.317118 },
    { lat: 48.956716, lng: 20.317337 },
    { lat: 48.956743, lng: 20.317465 },
    { lat: 48.956789, lng: 20.317877 },
    { lat: 48.957076, lng: 20.318509 },
    { lat: 48.957294, lng: 20.31897 },
    { lat: 48.957503, lng: 20.319184 },
    { lat: 48.957713, lng: 20.319575 },
    { lat: 48.957753, lng: 20.31975 },
    { lat: 48.957801, lng: 20.320321 },
    { lat: 48.95778, lng: 20.320579 },
    { lat: 48.958012, lng: 20.321577 },
    { lat: 48.958257, lng: 20.32139 },
    { lat: 48.958574, lng: 20.321096 },
    { lat: 48.958934, lng: 20.320859 },
    { lat: 48.959169, lng: 20.320707 },
    { lat: 48.959244, lng: 20.320632 },
    { lat: 48.959464, lng: 20.320103 },
    { lat: 48.959564, lng: 20.319982 },
    { lat: 48.95957, lng: 20.319998 },
    { lat: 48.959715, lng: 20.320628 },
    { lat: 48.959752, lng: 20.32084 },
    { lat: 48.959742, lng: 20.321027 },
    { lat: 48.959749, lng: 20.32123 },
    { lat: 48.959785, lng: 20.32131 },
    { lat: 48.959832, lng: 20.321483 },
    { lat: 48.959903, lng: 20.321628 },
    { lat: 48.959993, lng: 20.321929 },
    { lat: 48.960039, lng: 20.321973 },
    { lat: 48.96016, lng: 20.322358 },
    { lat: 48.960203, lng: 20.322427 },
    { lat: 48.96023, lng: 20.322544 },
    { lat: 48.96033, lng: 20.32266 },
    { lat: 48.960366, lng: 20.322811 },
    { lat: 48.960523, lng: 20.323102 },
    { lat: 48.960772, lng: 20.3235 },
    { lat: 48.960872, lng: 20.32356 },
    { lat: 48.960904, lng: 20.323663 },
    { lat: 48.961027, lng: 20.323697 },
    { lat: 48.961276, lng: 20.323934 },
    { lat: 48.961352, lng: 20.324089 },
    { lat: 48.961417, lng: 20.324192 },
    { lat: 48.961471, lng: 20.32426 },
    { lat: 48.961542, lng: 20.324321 },
    { lat: 48.961634, lng: 20.324446 },
    { lat: 48.961849, lng: 20.324826 },
    { lat: 48.96202, lng: 20.32497 },
    { lat: 48.962205, lng: 20.32511 },
    { lat: 48.962276, lng: 20.32535 },
    { lat: 48.962456, lng: 20.325407 },
    { lat: 48.962555, lng: 20.325513 },
    { lat: 48.962666, lng: 20.325685 },
    { lat: 48.962791, lng: 20.325781 },
    { lat: 48.962878, lng: 20.325873 },
    { lat: 48.963029, lng: 20.326079 },
    { lat: 48.963187, lng: 20.326175 },
    { lat: 48.963227, lng: 20.32612 },
    { lat: 48.96345, lng: 20.32616 },
    { lat: 48.963542, lng: 20.326552 },
    { lat: 48.963658, lng: 20.326976 },
    { lat: 48.963753, lng: 20.327177 },
    { lat: 48.9638019, lng: 20.3272742 },
    { lat: 48.963922, lng: 20.327513 },
    { lat: 48.964005, lng: 20.327581 },
    { lat: 48.964187, lng: 20.327669 },
    { lat: 48.964307, lng: 20.327744 },
    { lat: 48.964354, lng: 20.327823 },
    { lat: 48.964418, lng: 20.327858 },
    { lat: 48.964471, lng: 20.327939 },
    { lat: 48.964733, lng: 20.328254 },
    { lat: 48.964885, lng: 20.328512 },
    { lat: 48.964896, lng: 20.328631 },
    { lat: 48.964985, lng: 20.328659 },
    { lat: 48.965112, lng: 20.329035 },
    { lat: 48.96515, lng: 20.329105 },
    { lat: 48.965258, lng: 20.329204 },
    { lat: 48.965402, lng: 20.329461 },
    { lat: 48.965431, lng: 20.329928 },
    { lat: 48.965443, lng: 20.330041 },
    { lat: 48.965468, lng: 20.330025 },
    { lat: 48.965922, lng: 20.330672 },
    { lat: 48.966433, lng: 20.331414 },
    { lat: 48.966458, lng: 20.331393 },
    { lat: 48.966472, lng: 20.331393 },
    { lat: 48.966467, lng: 20.331745 },
    { lat: 48.966387, lng: 20.332633 },
    { lat: 48.966384, lng: 20.332812 },
    { lat: 48.966384, lng: 20.333006 },
    { lat: 48.966335, lng: 20.333592 },
    { lat: 48.966298, lng: 20.334338 },
    { lat: 48.966301, lng: 20.33437 },
    { lat: 48.966377, lng: 20.33478 },
    { lat: 48.966344, lng: 20.335198 },
    { lat: 48.966332, lng: 20.335675 },
    { lat: 48.966286, lng: 20.336072 },
    { lat: 48.966267, lng: 20.336208 },
    { lat: 48.96628, lng: 20.336691 },
    { lat: 48.966266, lng: 20.336846 },
    { lat: 48.966216, lng: 20.337255 },
    { lat: 48.966171, lng: 20.337747 },
    { lat: 48.96618, lng: 20.338028 },
    { lat: 48.966193, lng: 20.338154 },
    { lat: 48.966165, lng: 20.338308 },
    { lat: 48.966143, lng: 20.338471 },
    { lat: 48.966115, lng: 20.338744 },
    { lat: 48.966083, lng: 20.339105 },
    { lat: 48.966066, lng: 20.339266 },
    { lat: 48.966001, lng: 20.339639 },
    { lat: 48.965961, lng: 20.339852 },
    { lat: 48.965937, lng: 20.339998 },
    { lat: 48.965887, lng: 20.34027 },
    { lat: 48.965897, lng: 20.340393 },
    { lat: 48.965996, lng: 20.340704 },
    { lat: 48.966151, lng: 20.341213 },
    { lat: 48.966191, lng: 20.341274 },
    { lat: 48.966315, lng: 20.341427 },
    { lat: 48.966397, lng: 20.341496 },
    { lat: 48.966416, lng: 20.341506 },
    { lat: 48.966652, lng: 20.341529 },
    { lat: 48.966778, lng: 20.341537 },
    { lat: 48.967307, lng: 20.341827 },
    { lat: 48.967338, lng: 20.341838 },
    { lat: 48.967486, lng: 20.341879 },
    { lat: 48.967594, lng: 20.341955 },
    { lat: 48.967671, lng: 20.342039 },
    { lat: 48.967893, lng: 20.342477 },
    { lat: 48.968029, lng: 20.342757 },
    { lat: 48.968157, lng: 20.343154 },
    { lat: 48.968243, lng: 20.343255 },
    { lat: 48.968367, lng: 20.343427 },
    { lat: 48.968452, lng: 20.343577 },
    { lat: 48.968564, lng: 20.343887 },
    { lat: 48.968692, lng: 20.344058 },
    { lat: 48.969032, lng: 20.34439 },
    { lat: 48.969276, lng: 20.344684 },
    { lat: 48.969395, lng: 20.344893 },
    { lat: 48.969562, lng: 20.345328 },
    { lat: 48.969826, lng: 20.346389 },
    { lat: 48.96993, lng: 20.346636 },
    { lat: 48.96992, lng: 20.346654 },
    { lat: 48.969915, lng: 20.34669 },
    { lat: 48.969919, lng: 20.346707 },
    { lat: 48.969966, lng: 20.346874 },
    { lat: 48.970082, lng: 20.34714 },
    { lat: 48.970135, lng: 20.347402 },
    { lat: 48.97024, lng: 20.347601 },
    { lat: 48.97133, lng: 20.348004 },
    { lat: 48.97165, lng: 20.348043 },
    { lat: 48.972359, lng: 20.347949 },
    { lat: 48.972699, lng: 20.347948 },
    { lat: 48.972713, lng: 20.34796 },
    { lat: 48.972771, lng: 20.347947 },
    { lat: 48.973689, lng: 20.349121 },
    { lat: 48.974279, lng: 20.350611 },
    { lat: 48.974398, lng: 20.350973 },
    { lat: 48.974449, lng: 20.351022 },
    { lat: 48.97462, lng: 20.351188 },
    { lat: 48.974853, lng: 20.35158 },
    { lat: 48.975305, lng: 20.35246 },
    { lat: 48.975632, lng: 20.353109 },
    { lat: 48.975637, lng: 20.353118 },
    { lat: 48.975651, lng: 20.35315 },
    { lat: 48.975664, lng: 20.353173 },
    { lat: 48.975786, lng: 20.353462 },
    { lat: 48.975942, lng: 20.353866 },
    { lat: 48.976174, lng: 20.354323 },
    { lat: 48.976157, lng: 20.354404 },
    { lat: 48.9762, lng: 20.35456 },
    { lat: 48.976303, lng: 20.354821 },
    { lat: 48.976612, lng: 20.355457 },
    { lat: 48.976874, lng: 20.356154 },
    { lat: 48.977189, lng: 20.356999 },
    { lat: 48.977341, lng: 20.357307 },
    { lat: 48.97743, lng: 20.35765 },
    { lat: 48.977437, lng: 20.357635 },
    { lat: 48.977844, lng: 20.359167 },
    { lat: 48.978167, lng: 20.360134 },
    { lat: 48.978406, lng: 20.360963 },
    { lat: 48.978417, lng: 20.361001 },
    { lat: 48.978485, lng: 20.361239 },
    { lat: 48.97858, lng: 20.361539 },
    { lat: 48.978739, lng: 20.361988 },
    { lat: 48.979042, lng: 20.362635 },
    { lat: 48.979311, lng: 20.363375 },
    { lat: 48.979466, lng: 20.363651 },
    { lat: 48.979685, lng: 20.364183 },
    { lat: 48.97987, lng: 20.36431 },
    { lat: 48.980154, lng: 20.364831 },
    { lat: 48.980503, lng: 20.365593 },
    { lat: 48.980672, lng: 20.365956 },
    { lat: 48.980883, lng: 20.366383 },
    { lat: 48.980933, lng: 20.366479 },
    { lat: 48.981294, lng: 20.367111 },
    { lat: 48.981316, lng: 20.367109 },
    { lat: 48.981246, lng: 20.367301 },
    { lat: 48.981156, lng: 20.367566 },
    { lat: 48.981073, lng: 20.367692 },
    { lat: 48.981052, lng: 20.367801 },
    { lat: 48.981019, lng: 20.367912 },
    { lat: 48.980981, lng: 20.368016 },
    { lat: 48.980921, lng: 20.368102 },
    { lat: 48.98088, lng: 20.368211 },
    { lat: 48.980696, lng: 20.368714 },
    { lat: 48.980557, lng: 20.369136 },
    { lat: 48.980483, lng: 20.369843 },
    { lat: 48.980587, lng: 20.36998 },
    { lat: 48.980634, lng: 20.37011 },
    { lat: 48.980634, lng: 20.370126 },
    { lat: 48.980629, lng: 20.370219 },
    { lat: 48.980436, lng: 20.370418 },
    { lat: 48.980465, lng: 20.370681 },
    { lat: 48.980523, lng: 20.370906 },
    { lat: 48.980586, lng: 20.370958 },
    { lat: 48.980585, lng: 20.371097 },
    { lat: 48.980695, lng: 20.371156 },
    { lat: 48.980682, lng: 20.371317 },
    { lat: 48.980634, lng: 20.371369 },
    { lat: 48.980577, lng: 20.37136 },
    { lat: 48.980561, lng: 20.371395 },
    { lat: 48.980581, lng: 20.371628 },
    { lat: 48.980691, lng: 20.371641 },
    { lat: 48.980706, lng: 20.371546 },
    { lat: 48.980808, lng: 20.371616 },
    { lat: 48.980851, lng: 20.371616 },
    { lat: 48.980893, lng: 20.371766 },
    { lat: 48.980816, lng: 20.371877 },
    { lat: 48.980855, lng: 20.371936 },
    { lat: 48.980849, lng: 20.371971 },
    { lat: 48.980828, lng: 20.372084 },
    { lat: 48.980888, lng: 20.372162 },
    { lat: 48.980798, lng: 20.372286 },
    { lat: 48.980783, lng: 20.372432 },
    { lat: 48.980806, lng: 20.372452 },
    { lat: 48.980751, lng: 20.372505 },
    { lat: 48.980726, lng: 20.372555 },
    { lat: 48.980742, lng: 20.372599 },
    { lat: 48.980703, lng: 20.372707 },
    { lat: 48.980761, lng: 20.372808 },
    { lat: 48.980699, lng: 20.373011 },
    { lat: 48.980757, lng: 20.373075 },
    { lat: 48.980751, lng: 20.373214 },
    { lat: 48.980777, lng: 20.373264 },
    { lat: 48.980859, lng: 20.373226 },
    { lat: 48.980876, lng: 20.373289 },
    { lat: 48.980857, lng: 20.373384 },
    { lat: 48.980819, lng: 20.373479 },
    { lat: 48.980831, lng: 20.373537 },
    { lat: 48.980893, lng: 20.373598 },
    { lat: 48.980881, lng: 20.373698 },
    { lat: 48.980985, lng: 20.373698 },
    { lat: 48.981005, lng: 20.373783 },
    { lat: 48.980926, lng: 20.37386 },
    { lat: 48.980862, lng: 20.374073 },
    { lat: 48.980958, lng: 20.374172 },
    { lat: 48.980996, lng: 20.374165 },
    { lat: 48.981015, lng: 20.374106 },
    { lat: 48.981132, lng: 20.374212 },
    { lat: 48.981149, lng: 20.374313 },
    { lat: 48.981162, lng: 20.374322 },
    { lat: 48.98117, lng: 20.374363 },
    { lat: 48.981116, lng: 20.374356 },
    { lat: 48.981065, lng: 20.374306 },
    { lat: 48.981038, lng: 20.374352 },
    { lat: 48.981068, lng: 20.374598 },
    { lat: 48.981057, lng: 20.374894 },
    { lat: 48.98113, lng: 20.374929 },
    { lat: 48.981111, lng: 20.375047 },
    { lat: 48.981249, lng: 20.375285 },
    { lat: 48.981279, lng: 20.375385 },
    { lat: 48.98132, lng: 20.375384 },
    { lat: 48.981351, lng: 20.375383 },
    { lat: 48.981373, lng: 20.375481 },
    { lat: 48.981324, lng: 20.375579 },
    { lat: 48.981262, lng: 20.37562 },
    { lat: 48.981182, lng: 20.375561 },
    { lat: 48.981178, lng: 20.37542 },
    { lat: 48.981116, lng: 20.375446 },
    { lat: 48.981092, lng: 20.37553 },
    { lat: 48.981023, lng: 20.375525 },
    { lat: 48.981081, lng: 20.375676 },
    { lat: 48.981008, lng: 20.375736 },
    { lat: 48.981035, lng: 20.3758 },
    { lat: 48.980985, lng: 20.37587 },
    { lat: 48.980989, lng: 20.375952 },
    { lat: 48.981046, lng: 20.376003 },
    { lat: 48.981049, lng: 20.37617 },
    { lat: 48.980998, lng: 20.376238 },
    { lat: 48.980996, lng: 20.376418 },
    { lat: 48.981076, lng: 20.376504 },
    { lat: 48.980974, lng: 20.376574 },
    { lat: 48.98091, lng: 20.376519 },
    { lat: 48.980849, lng: 20.376609 },
    { lat: 48.980874, lng: 20.376721 },
    { lat: 48.980854, lng: 20.376806 },
    { lat: 48.980811, lng: 20.376831 },
    { lat: 48.980809, lng: 20.376865 },
    { lat: 48.980896, lng: 20.376918 },
    { lat: 48.980946, lng: 20.376838 },
    { lat: 48.981027, lng: 20.377007 },
    { lat: 48.980935, lng: 20.377132 },
    { lat: 48.981045, lng: 20.377303 },
    { lat: 48.981096, lng: 20.377285 },
    { lat: 48.981348, lng: 20.377578 },
    { lat: 48.981664, lng: 20.378094 },
    { lat: 48.981867, lng: 20.378506 },
    { lat: 48.982212, lng: 20.378975 },
    { lat: 48.982366, lng: 20.379291 },
    { lat: 48.98282, lng: 20.380574 },
    { lat: 48.982906, lng: 20.380961 },
    { lat: 48.983074, lng: 20.381932 },
    { lat: 48.983573, lng: 20.382702 },
    { lat: 48.983598, lng: 20.382698 },
    { lat: 48.98407, lng: 20.38255 },
    { lat: 48.98412, lng: 20.382559 },
    { lat: 48.98419, lng: 20.382675 },
    { lat: 48.984333, lng: 20.382684 },
    { lat: 48.98439, lng: 20.382709 },
    { lat: 48.984571, lng: 20.382862 },
    { lat: 48.984607, lng: 20.38305 },
    { lat: 48.984586, lng: 20.383091 },
    { lat: 48.984926, lng: 20.383615 },
    { lat: 48.984939, lng: 20.383778 },
    { lat: 48.985037, lng: 20.383957 },
    { lat: 48.98513, lng: 20.384074 },
    { lat: 48.9851964, lng: 20.3840984 },
    { lat: 48.985296, lng: 20.384135 },
    { lat: 48.985478, lng: 20.384528 },
    { lat: 48.985568, lng: 20.384671 },
    { lat: 48.985692, lng: 20.384817 },
    { lat: 48.985972, lng: 20.385227 },
    { lat: 48.98601, lng: 20.385303 },
    { lat: 48.986098, lng: 20.385593 },
    { lat: 48.986192, lng: 20.385674 },
    { lat: 48.986277, lng: 20.385652 },
    { lat: 48.986769, lng: 20.384956 },
    { lat: 48.986789, lng: 20.384985 },
    { lat: 48.986803, lng: 20.385033 },
    { lat: 48.986811, lng: 20.385057 },
    { lat: 48.986925, lng: 20.385071 },
    { lat: 48.987018, lng: 20.385165 },
    { lat: 48.987839, lng: 20.386364 },
    { lat: 48.987961, lng: 20.38659 },
    { lat: 48.988028, lng: 20.386716 },
    { lat: 48.988039, lng: 20.3867 },
    { lat: 48.98843, lng: 20.387532 },
    { lat: 48.988232, lng: 20.387765 },
    { lat: 48.987823, lng: 20.38825 },
    { lat: 48.988228, lng: 20.389508 },
    { lat: 48.988402, lng: 20.390146 },
    { lat: 48.989374, lng: 20.394344 },
    { lat: 48.989615, lng: 20.395274 },
    { lat: 48.989462, lng: 20.395442 },
    { lat: 48.98979, lng: 20.396725 },
    { lat: 48.989956, lng: 20.397342 },
    { lat: 48.98996, lng: 20.397359 },
    { lat: 48.989965, lng: 20.39738 },
    { lat: 48.9907277, lng: 20.3967428 },
    { lat: 48.9916054, lng: 20.3962607 },
    { lat: 48.9930381, lng: 20.3958279 },
    { lat: 48.9944709, lng: 20.3955457 },
    { lat: 48.9961934, lng: 20.3950952 },
    { lat: 48.9973223, lng: 20.3949083 },
    { lat: 48.9978557, lng: 20.3949301 },
    { lat: 48.9982385, lng: 20.394872 },
    { lat: 49.0000177, lng: 20.3943128 },
    { lat: 49.0024937, lng: 20.3941064 },
    { lat: 49.0030928, lng: 20.3942559 },
    { lat: 49.0033528, lng: 20.3941081 },
    { lat: 49.0034355, lng: 20.3941478 },
    { lat: 49.0036947, lng: 20.3939478 },
    { lat: 49.0040248, lng: 20.3934532 },
    { lat: 49.0044128, lng: 20.3930213 },
    { lat: 49.0051456, lng: 20.3926378 },
    { lat: 49.0057008, lng: 20.3928159 },
    { lat: 49.0056047, lng: 20.391355 },
    { lat: 49.0054061, lng: 20.388342 },
    { lat: 49.0053774, lng: 20.3860288 },
    { lat: 49.005156, lng: 20.3830645 },
    { lat: 49.005162, lng: 20.3826289 },
    { lat: 49.0052898, lng: 20.3809384 },
    { lat: 49.0057235, lng: 20.3787364 },
    { lat: 49.0060431, lng: 20.3766448 },
    { lat: 49.0059243, lng: 20.3749241 },
    { lat: 49.0060015, lng: 20.3741802 },
    { lat: 49.0063536, lng: 20.3729067 },
    { lat: 49.0065911, lng: 20.3716124 },
    { lat: 49.0066548, lng: 20.3710171 },
    { lat: 49.0070281, lng: 20.3697686 },
    { lat: 49.0070635, lng: 20.3685187 },
    { lat: 49.0069364, lng: 20.3671133 },
    { lat: 49.0069707, lng: 20.3650447 },
    { lat: 49.0067857, lng: 20.3640982 },
    { lat: 49.0075231, lng: 20.3627171 },
    { lat: 49.0078188, lng: 20.3614718 },
    { lat: 49.0078805, lng: 20.3599168 },
    { lat: 49.0078718, lng: 20.3586662 },
  ],
  VysokéTatry: [
    { lat: 49.0842504, lng: 20.0923023 },
    { lat: 49.0842932, lng: 20.0924147 },
    { lat: 49.0851228, lng: 20.0931243 },
    { lat: 49.0854777, lng: 20.0937901 },
    { lat: 49.0859712, lng: 20.0942972 },
    { lat: 49.0865569, lng: 20.0954324 },
    { lat: 49.0881748, lng: 20.096057 },
    { lat: 49.0885577, lng: 20.0962797 },
    { lat: 49.0891006, lng: 20.0969744 },
    { lat: 49.0892675, lng: 20.096622 },
    { lat: 49.089375, lng: 20.0966295 },
    { lat: 49.0908806, lng: 20.0958286 },
    { lat: 49.0917449, lng: 20.0959458 },
    { lat: 49.0926682, lng: 20.0962487 },
    { lat: 49.0929094, lng: 20.0963668 },
    { lat: 49.0929903, lng: 20.0964688 },
    { lat: 49.0929788, lng: 20.0966858 },
    { lat: 49.0919339, lng: 20.0983532 },
    { lat: 49.0917085, lng: 20.0994561 },
    { lat: 49.0917094, lng: 20.1005595 },
    { lat: 49.0914967, lng: 20.1007844 },
    { lat: 49.0913774, lng: 20.1010658 },
    { lat: 49.0912556, lng: 20.1010604 },
    { lat: 49.0911777, lng: 20.1012215 },
    { lat: 49.0911392, lng: 20.1011928 },
    { lat: 49.0910216, lng: 20.1013753 },
    { lat: 49.0909357, lng: 20.1013931 },
    { lat: 49.0909003, lng: 20.1014938 },
    { lat: 49.0907119, lng: 20.101624 },
    { lat: 49.0907659, lng: 20.1019265 },
    { lat: 49.0910765, lng: 20.1019505 },
    { lat: 49.091007, lng: 20.1025391 },
    { lat: 49.0907279, lng: 20.1031288 },
    { lat: 49.0906737, lng: 20.1033463 },
    { lat: 49.0909513, lng: 20.1034402 },
    { lat: 49.0907694, lng: 20.1043715 },
    { lat: 49.0905767, lng: 20.1049512 },
    { lat: 49.0901421, lng: 20.1059239 },
    { lat: 49.0901228, lng: 20.1061689 },
    { lat: 49.0897065, lng: 20.1068434 },
    { lat: 49.089569, lng: 20.1074564 },
    { lat: 49.0897544, lng: 20.1078906 },
    { lat: 49.0906642, lng: 20.1073559 },
    { lat: 49.0903028, lng: 20.1090831 },
    { lat: 49.091079, lng: 20.1092825 },
    { lat: 49.0914937, lng: 20.1097318 },
    { lat: 49.0916576, lng: 20.1096341 },
    { lat: 49.0921972, lng: 20.1100581 },
    { lat: 49.0921628, lng: 20.1100991 },
    { lat: 49.0927604, lng: 20.1105596 },
    { lat: 49.0929621, lng: 20.1107872 },
    { lat: 49.0932285, lng: 20.1106637 },
    { lat: 49.0933998, lng: 20.1112739 },
    { lat: 49.0927149, lng: 20.1118252 },
    { lat: 49.0918646, lng: 20.1118832 },
    { lat: 49.0913146, lng: 20.1122345 },
    { lat: 49.0906074, lng: 20.1128184 },
    { lat: 49.0903957, lng: 20.1131505 },
    { lat: 49.090117, lng: 20.1133614 },
    { lat: 49.089846, lng: 20.1137609 },
    { lat: 49.0885212, lng: 20.1151954 },
    { lat: 49.0872608, lng: 20.1171383 },
    { lat: 49.0867275, lng: 20.1184038 },
    { lat: 49.0862169, lng: 20.1192285 },
    { lat: 49.0854739, lng: 20.1201828 },
    { lat: 49.0849751, lng: 20.1216908 },
    { lat: 49.083483, lng: 20.1256196 },
    { lat: 49.083303, lng: 20.1265159 },
    { lat: 49.0826381, lng: 20.1291548 },
    { lat: 49.0826746, lng: 20.1297489 },
    { lat: 49.0827834, lng: 20.1301289 },
    { lat: 49.0828807, lng: 20.1296096 },
    { lat: 49.0835488, lng: 20.1292816 },
    { lat: 49.0837001, lng: 20.1290975 },
    { lat: 49.0842825, lng: 20.1289618 },
    { lat: 49.0852075, lng: 20.1286079 },
    { lat: 49.0854807, lng: 20.1296013 },
    { lat: 49.0857339, lng: 20.1310719 },
    { lat: 49.0857294, lng: 20.1314941 },
    { lat: 49.0856444, lng: 20.131843 },
    { lat: 49.0856942, lng: 20.1322131 },
    { lat: 49.0863885, lng: 20.1339037 },
    { lat: 49.0868506, lng: 20.1346049 },
    { lat: 49.0874952, lng: 20.1355687 },
    { lat: 49.0876569, lng: 20.1354749 },
    { lat: 49.0878191, lng: 20.1351083 },
    { lat: 49.0880275, lng: 20.1349156 },
    { lat: 49.0883828, lng: 20.1347908 },
    { lat: 49.0886208, lng: 20.1345781 },
    { lat: 49.0888935, lng: 20.1344703 },
    { lat: 49.0889066, lng: 20.134513 },
    { lat: 49.0893243, lng: 20.1344552 },
    { lat: 49.0895722, lng: 20.1342053 },
    { lat: 49.0902888, lng: 20.1340744 },
    { lat: 49.0905618, lng: 20.1336697 },
    { lat: 49.090654, lng: 20.1330779 },
    { lat: 49.0908446, lng: 20.1328359 },
    { lat: 49.0911168, lng: 20.1327461 },
    { lat: 49.0912818, lng: 20.1325647 },
    { lat: 49.0914072, lng: 20.1325447 },
    { lat: 49.0917322, lng: 20.1320292 },
    { lat: 49.0924756, lng: 20.1313649 },
    { lat: 49.0926559, lng: 20.1313419 },
    { lat: 49.0926683, lng: 20.1310336 },
    { lat: 49.092592, lng: 20.1303425 },
    { lat: 49.0927695, lng: 20.1297984 },
    { lat: 49.0927794, lng: 20.1295412 },
    { lat: 49.0928953, lng: 20.1292925 },
    { lat: 49.0930539, lng: 20.1290933 },
    { lat: 49.0933083, lng: 20.1289463 },
    { lat: 49.0933849, lng: 20.1287274 },
    { lat: 49.0934538, lng: 20.1287289 },
    { lat: 49.0936205, lng: 20.1285659 },
    { lat: 49.093679, lng: 20.1283966 },
    { lat: 49.0936714, lng: 20.1281795 },
    { lat: 49.0937571, lng: 20.128017 },
    { lat: 49.09386, lng: 20.1280505 },
    { lat: 49.0939396, lng: 20.1279015 },
    { lat: 49.0942259, lng: 20.1276871 },
    { lat: 49.0943635, lng: 20.1274701 },
    { lat: 49.0946901, lng: 20.1265752 },
    { lat: 49.0949269, lng: 20.1256392 },
    { lat: 49.0950492, lng: 20.1255408 },
    { lat: 49.0951861, lng: 20.1255283 },
    { lat: 49.0953674, lng: 20.1253604 },
    { lat: 49.0956435, lng: 20.1247247 },
    { lat: 49.0959743, lng: 20.1245255 },
    { lat: 49.0962811, lng: 20.1244493 },
    { lat: 49.0971684, lng: 20.1231932 },
    { lat: 49.0973909, lng: 20.1231289 },
    { lat: 49.0979035, lng: 20.1234391 },
    { lat: 49.0981994, lng: 20.1235134 },
    { lat: 49.0986597, lng: 20.1232831 },
    { lat: 49.0989855, lng: 20.1233496 },
    { lat: 49.099464, lng: 20.1231484 },
    { lat: 49.0997702, lng: 20.1225335 },
    { lat: 49.0998588, lng: 20.1224731 },
    { lat: 49.1007115, lng: 20.1224154 },
    { lat: 49.1009472, lng: 20.1222851 },
    { lat: 49.1013467, lng: 20.1222782 },
    { lat: 49.1017738, lng: 20.1236794 },
    { lat: 49.1019021, lng: 20.1249321 },
    { lat: 49.1022437, lng: 20.1246618 },
    { lat: 49.1024783, lng: 20.1246151 },
    { lat: 49.1028262, lng: 20.1248621 },
    { lat: 49.1037881, lng: 20.1246742 },
    { lat: 49.1042278, lng: 20.124822 },
    { lat: 49.1046112, lng: 20.1247271 },
    { lat: 49.1048584, lng: 20.1247938 },
    { lat: 49.1047806, lng: 20.1259941 },
    { lat: 49.1050426, lng: 20.1263171 },
    { lat: 49.1051481, lng: 20.125722 },
    { lat: 49.1061099, lng: 20.1263903 },
    { lat: 49.1070759, lng: 20.1262481 },
    { lat: 49.107129, lng: 20.1253887 },
    { lat: 49.1074898, lng: 20.1254232 },
    { lat: 49.1078102, lng: 20.1256613 },
    { lat: 49.1085166, lng: 20.1259477 },
    { lat: 49.1083578, lng: 20.1265856 },
    { lat: 49.107893, lng: 20.1269339 },
    { lat: 49.1073138, lng: 20.1276729 },
    { lat: 49.1070864, lng: 20.1281784 },
    { lat: 49.1070682, lng: 20.1287613 },
    { lat: 49.1072582, lng: 20.1295878 },
    { lat: 49.1068439, lng: 20.1294271 },
    { lat: 49.1063234, lng: 20.1304 },
    { lat: 49.1050687, lng: 20.1322891 },
    { lat: 49.1048687, lng: 20.1324684 },
    { lat: 49.1036388, lng: 20.134699 },
    { lat: 49.1025273, lng: 20.1378321 },
    { lat: 49.1020683, lng: 20.1400813 },
    { lat: 49.1020704, lng: 20.1401056 },
    { lat: 49.1021844, lng: 20.1408659 },
    { lat: 49.1021402, lng: 20.1410533 },
    { lat: 49.1018111, lng: 20.1413536 },
    { lat: 49.101025, lng: 20.1415822 },
    { lat: 49.1006979, lng: 20.1416221 },
    { lat: 49.1002445, lng: 20.1412152 },
    { lat: 49.0996146, lng: 20.141011 },
    { lat: 49.0991892, lng: 20.1409598 },
    { lat: 49.0965355, lng: 20.1411461 },
    { lat: 49.09595, lng: 20.1412054 },
    { lat: 49.0958463, lng: 20.1412827 },
    { lat: 49.0956309, lng: 20.1413039 },
    { lat: 49.0947353, lng: 20.1422446 },
    { lat: 49.0954333, lng: 20.1436704 },
    { lat: 49.0964223, lng: 20.1452182 },
    { lat: 49.0960097, lng: 20.1452024 },
    { lat: 49.0956095, lng: 20.1454741 },
    { lat: 49.0953886, lng: 20.1454681 },
    { lat: 49.0951671, lng: 20.1455919 },
    { lat: 49.0947688, lng: 20.1456811 },
    { lat: 49.0942065, lng: 20.1461224 },
    { lat: 49.0937993, lng: 20.1462498 },
    { lat: 49.0933377, lng: 20.1462778 },
    { lat: 49.0929737, lng: 20.1464914 },
    { lat: 49.0928059, lng: 20.1466623 },
    { lat: 49.0927017, lng: 20.1466476 },
    { lat: 49.0919684, lng: 20.1470915 },
    { lat: 49.0919116, lng: 20.1471927 },
    { lat: 49.0918975, lng: 20.1473755 },
    { lat: 49.091635, lng: 20.1473971 },
    { lat: 49.0913638, lng: 20.1475729 },
    { lat: 49.0910237, lng: 20.1475184 },
    { lat: 49.0906892, lng: 20.1477382 },
    { lat: 49.090416, lng: 20.1476645 },
    { lat: 49.0902499, lng: 20.1478751 },
    { lat: 49.0901258, lng: 20.1479011 },
    { lat: 49.0899009, lng: 20.1480769 },
    { lat: 49.0896831, lng: 20.1485115 },
    { lat: 49.0894782, lng: 20.1486571 },
    { lat: 49.0894605, lng: 20.1488032 },
    { lat: 49.0892852, lng: 20.1487573 },
    { lat: 49.0890057, lng: 20.1490884 },
    { lat: 49.0889392, lng: 20.1490307 },
    { lat: 49.0885223, lng: 20.1490217 },
    { lat: 49.0884814, lng: 20.1490956 },
    { lat: 49.0883155, lng: 20.1489196 },
    { lat: 49.0882427, lng: 20.1489594 },
    { lat: 49.0881905, lng: 20.1488939 },
    { lat: 49.0881676, lng: 20.1490221 },
    { lat: 49.0881152, lng: 20.1489806 },
    { lat: 49.0881005, lng: 20.1490381 },
    { lat: 49.0879864, lng: 20.1490279 },
    { lat: 49.0879611, lng: 20.149157 },
    { lat: 49.0859174, lng: 20.1461926 },
    { lat: 49.0858944, lng: 20.1461534 },
    { lat: 49.085268, lng: 20.1465967 },
    { lat: 49.0852037, lng: 20.1467068 },
    { lat: 49.0852404, lng: 20.1468317 },
    { lat: 49.0852168, lng: 20.1470017 },
    { lat: 49.0850481, lng: 20.1469502 },
    { lat: 49.0847538, lng: 20.147361 },
    { lat: 49.0846827, lng: 20.1476396 },
    { lat: 49.0844708, lng: 20.147687 },
    { lat: 49.0844487, lng: 20.1477537 },
    { lat: 49.0844998, lng: 20.1479336 },
    { lat: 49.0844269, lng: 20.1479986 },
    { lat: 49.0844233, lng: 20.1480791 },
    { lat: 49.0843037, lng: 20.1480446 },
    { lat: 49.0842957, lng: 20.1481521 },
    { lat: 49.0839625, lng: 20.1484634 },
    { lat: 49.0839342, lng: 20.1485443 },
    { lat: 49.084005, lng: 20.1486286 },
    { lat: 49.0840232, lng: 20.1487685 },
    { lat: 49.0837872, lng: 20.1486694 },
    { lat: 49.0837291, lng: 20.1489022 },
    { lat: 49.0837552, lng: 20.1490766 },
    { lat: 49.0836431, lng: 20.1490644 },
    { lat: 49.0836316, lng: 20.1491118 },
    { lat: 49.0836019, lng: 20.1493542 },
    { lat: 49.0836619, lng: 20.1494736 },
    { lat: 49.0835426, lng: 20.1494747 },
    { lat: 49.0834587, lng: 20.1497415 },
    { lat: 49.0833291, lng: 20.1499638 },
    { lat: 49.0833482, lng: 20.1501043 },
    { lat: 49.0831769, lng: 20.1504346 },
    { lat: 49.0829629, lng: 20.1506302 },
    { lat: 49.0828609, lng: 20.1509515 },
    { lat: 49.0826312, lng: 20.1510074 },
    { lat: 49.0825029, lng: 20.1511509 },
    { lat: 49.0822316, lng: 20.151909 },
    { lat: 49.0821138, lng: 20.1520605 },
    { lat: 49.0819547, lng: 20.1526113 },
    { lat: 49.0815677, lng: 20.1530713 },
    { lat: 49.081355, lng: 20.1535513 },
    { lat: 49.0812008, lng: 20.153636 },
    { lat: 49.0810888, lng: 20.1538498 },
    { lat: 49.0810875, lng: 20.1541517 },
    { lat: 49.0809757, lng: 20.1543726 },
    { lat: 49.080765, lng: 20.1545072 },
    { lat: 49.0807395, lng: 20.1546306 },
    { lat: 49.0807879, lng: 20.1548255 },
    { lat: 49.0807753, lng: 20.1550171 },
    { lat: 49.0806364, lng: 20.1552017 },
    { lat: 49.0804709, lng: 20.1556001 },
    { lat: 49.0805128, lng: 20.1557715 },
    { lat: 49.0803388, lng: 20.1561221 },
    { lat: 49.08042, lng: 20.1562644 },
    { lat: 49.0806987, lng: 20.1561418 },
    { lat: 49.0807991, lng: 20.1560035 },
    { lat: 49.0809081, lng: 20.155997 },
    { lat: 49.0816336, lng: 20.157998 },
    { lat: 49.0818112, lng: 20.1576481 },
    { lat: 49.0822519, lng: 20.1571584 },
    { lat: 49.0831627, lng: 20.1563092 },
    { lat: 49.0839439, lng: 20.1559882 },
    { lat: 49.0840025, lng: 20.1561599 },
    { lat: 49.0845369, lng: 20.1560322 },
    { lat: 49.0845704, lng: 20.1561401 },
    { lat: 49.0846481, lng: 20.1561113 },
    { lat: 49.0847253, lng: 20.1563772 },
    { lat: 49.0848124, lng: 20.1563299 },
    { lat: 49.0847826, lng: 20.156235 },
    { lat: 49.084828, lng: 20.1560723 },
    { lat: 49.0850775, lng: 20.1559797 },
    { lat: 49.0854257, lng: 20.1557009 },
    { lat: 49.0857345, lng: 20.1556283 },
    { lat: 49.0858176, lng: 20.1559285 },
    { lat: 49.0860921, lng: 20.1556403 },
    { lat: 49.0860784, lng: 20.1555758 },
    { lat: 49.0866488, lng: 20.1551706 },
    { lat: 49.0867422, lng: 20.155453 },
    { lat: 49.0871602, lng: 20.1561014 },
    { lat: 49.0872271, lng: 20.1560855 },
    { lat: 49.0875117, lng: 20.1563561 },
    { lat: 49.087807, lng: 20.1561932 },
    { lat: 49.087981, lng: 20.1559549 },
    { lat: 49.0882851, lng: 20.1558178 },
    { lat: 49.0883347, lng: 20.1558469 },
    { lat: 49.0885751, lng: 20.1555522 },
    { lat: 49.0888711, lng: 20.1556608 },
    { lat: 49.0890233, lng: 20.1554128 },
    { lat: 49.0891843, lng: 20.1553543 },
    { lat: 49.0911462, lng: 20.1589216 },
    { lat: 49.0914891, lng: 20.1590765 },
    { lat: 49.0917791, lng: 20.1588634 },
    { lat: 49.091995, lng: 20.1591915 },
    { lat: 49.0926582, lng: 20.1589117 },
    { lat: 49.0933584, lng: 20.157806 },
    { lat: 49.0934943, lng: 20.157296 },
    { lat: 49.0936903, lng: 20.1572397 },
    { lat: 49.0939336, lng: 20.156964 },
    { lat: 49.0953289, lng: 20.1585914 },
    { lat: 49.0958528, lng: 20.1586214 },
    { lat: 49.0966086, lng: 20.1584587 },
    { lat: 49.0970381, lng: 20.1579074 },
    { lat: 49.0971472, lng: 20.1575674 },
    { lat: 49.0983726, lng: 20.1568424 },
    { lat: 49.0985817, lng: 20.157256 },
    { lat: 49.0993231, lng: 20.1584128 },
    { lat: 49.09917, lng: 20.1586959 },
    { lat: 49.0991795, lng: 20.1593803 },
    { lat: 49.0994776, lng: 20.1596527 },
    { lat: 49.099732, lng: 20.1602119 },
    { lat: 49.0996008, lng: 20.1606238 },
    { lat: 49.0990738, lng: 20.1613808 },
    { lat: 49.0993443, lng: 20.1623134 },
    { lat: 49.0991481, lng: 20.1623334 },
    { lat: 49.098959, lng: 20.1627046 },
    { lat: 49.0988173, lng: 20.1626779 },
    { lat: 49.0984514, lng: 20.1629908 },
    { lat: 49.0984114, lng: 20.1631415 },
    { lat: 49.0982292, lng: 20.1631537 },
    { lat: 49.0981957, lng: 20.1633829 },
    { lat: 49.0980425, lng: 20.1633824 },
    { lat: 49.0977496, lng: 20.1641121 },
    { lat: 49.097615, lng: 20.16412 },
    { lat: 49.0971909, lng: 20.1639675 },
    { lat: 49.0945204, lng: 20.1693556 },
    { lat: 49.0939427, lng: 20.1695487 },
    { lat: 49.0936505, lng: 20.1699901 },
    { lat: 49.0935543, lng: 20.1700037 },
    { lat: 49.0934963, lng: 20.1698528 },
    { lat: 49.0932193, lng: 20.1699349 },
    { lat: 49.092979, lng: 20.1701218 },
    { lat: 49.0930671, lng: 20.1704068 },
    { lat: 49.0934913, lng: 20.1704828 },
    { lat: 49.0934119, lng: 20.1709217 },
    { lat: 49.09276, lng: 20.1706114 },
    { lat: 49.0927198, lng: 20.1707333 },
    { lat: 49.0923877, lng: 20.1710724 },
    { lat: 49.0923089, lng: 20.171051 },
    { lat: 49.0922776, lng: 20.1711933 },
    { lat: 49.0921144, lng: 20.1711431 },
    { lat: 49.0920987, lng: 20.1713105 },
    { lat: 49.0936512, lng: 20.1718895 },
    { lat: 49.093499, lng: 20.1726514 },
    { lat: 49.0935124, lng: 20.1736275 },
    { lat: 49.0933952, lng: 20.1741623 },
    { lat: 49.0932816, lng: 20.1744186 },
    { lat: 49.0927203, lng: 20.1752231 },
    { lat: 49.0920069, lng: 20.17566 },
    { lat: 49.091199, lng: 20.1765778 },
    { lat: 49.0916512, lng: 20.1767921 },
    { lat: 49.0920081, lng: 20.1765632 },
    { lat: 49.0926391, lng: 20.1759273 },
    { lat: 49.0930717, lng: 20.1753953 },
    { lat: 49.0934292, lng: 20.1751843 },
    { lat: 49.0954234, lng: 20.1758536 },
    { lat: 49.0951238, lng: 20.1761631 },
    { lat: 49.0948218, lng: 20.1760711 },
    { lat: 49.0945846, lng: 20.1762825 },
    { lat: 49.0942733, lng: 20.1762412 },
    { lat: 49.0934669, lng: 20.1772545 },
    { lat: 49.0932308, lng: 20.1776889 },
    { lat: 49.0927847, lng: 20.1781586 },
    { lat: 49.0936588, lng: 20.1786428 },
    { lat: 49.0936436, lng: 20.1790549 },
    { lat: 49.0942502, lng: 20.1793524 },
    { lat: 49.0940136, lng: 20.1801123 },
    { lat: 49.0938375, lng: 20.1802986 },
    { lat: 49.0936929, lng: 20.1802897 },
    { lat: 49.0933553, lng: 20.180492 },
    { lat: 49.0931299, lng: 20.1815014 },
    { lat: 49.0931733, lng: 20.1817435 },
    { lat: 49.0929534, lng: 20.1823007 },
    { lat: 49.0929482, lng: 20.1824539 },
    { lat: 49.0925175, lng: 20.182838 },
    { lat: 49.0922633, lng: 20.1827889 },
    { lat: 49.0918381, lng: 20.183281 },
    { lat: 49.0915605, lng: 20.1834012 },
    { lat: 49.091253, lng: 20.1832363 },
    { lat: 49.091143, lng: 20.1834948 },
    { lat: 49.0910537, lng: 20.1835702 },
    { lat: 49.0909141, lng: 20.1839683 },
    { lat: 49.090798, lng: 20.1839687 },
    { lat: 49.0906314, lng: 20.1840814 },
    { lat: 49.0904397, lng: 20.1843284 },
    { lat: 49.0903756, lng: 20.1846664 },
    { lat: 49.0902019, lng: 20.1849454 },
    { lat: 49.0901246, lng: 20.1848987 },
    { lat: 49.0899862, lng: 20.1851271 },
    { lat: 49.0910098, lng: 20.1854916 },
    { lat: 49.0926006, lng: 20.1866959 },
    { lat: 49.0911901, lng: 20.1889671 },
    { lat: 49.0905953, lng: 20.1902897 },
    { lat: 49.0914938, lng: 20.1919871 },
    { lat: 49.0926828, lng: 20.193335 },
    { lat: 49.0942585, lng: 20.1940491 },
    { lat: 49.0944535, lng: 20.1933749 },
    { lat: 49.095411, lng: 20.1932136 },
    { lat: 49.095608, lng: 20.1927602 },
    { lat: 49.0955412, lng: 20.192636 },
    { lat: 49.0956251, lng: 20.1925089 },
    { lat: 49.0959431, lng: 20.1922399 },
    { lat: 49.0961494, lng: 20.1922708 },
    { lat: 49.0964291, lng: 20.192112 },
    { lat: 49.0967431, lng: 20.1910099 },
    { lat: 49.097169, lng: 20.1903913 },
    { lat: 49.0974977, lng: 20.1904731 },
    { lat: 49.098036, lng: 20.1904744 },
    { lat: 49.0981091, lng: 20.1910656 },
    { lat: 49.098252, lng: 20.1916568 },
    { lat: 49.0995632, lng: 20.1918067 },
    { lat: 49.0995855, lng: 20.191971 },
    { lat: 49.1006054, lng: 20.192325 },
    { lat: 49.1006893, lng: 20.1919492 },
    { lat: 49.1008933, lng: 20.1918402 },
    { lat: 49.1009549, lng: 20.1915441 },
    { lat: 49.1011186, lng: 20.1915308 },
    { lat: 49.1030239, lng: 20.1927697 },
    { lat: 49.1036332, lng: 20.192776 },
    { lat: 49.1035687, lng: 20.1931582 },
    { lat: 49.1036417, lng: 20.1932377 },
    { lat: 49.1040518, lng: 20.1929641 },
    { lat: 49.1050772, lng: 20.1926401 },
    { lat: 49.1054056, lng: 20.192402 },
    { lat: 49.1058393, lng: 20.1920097 },
    { lat: 49.1061316, lng: 20.1915748 },
    { lat: 49.1068272, lng: 20.1908265 },
    { lat: 49.1070123, lng: 20.1905287 },
    { lat: 49.1074732, lng: 20.1902565 },
    { lat: 49.1079575, lng: 20.1903127 },
    { lat: 49.1086672, lng: 20.1907719 },
    { lat: 49.1089909, lng: 20.1908405 },
    { lat: 49.1093752, lng: 20.1910735 },
    { lat: 49.1097987, lng: 20.1917532 },
    { lat: 49.1099457, lng: 20.1918557 },
    { lat: 49.1101261, lng: 20.1918642 },
    { lat: 49.1104222, lng: 20.1912388 },
    { lat: 49.1105095, lng: 20.1913112 },
    { lat: 49.110367, lng: 20.191833 },
    { lat: 49.1104104, lng: 20.192242 },
    { lat: 49.1103055, lng: 20.1931151 },
    { lat: 49.1104909, lng: 20.1930473 },
    { lat: 49.1105627, lng: 20.1929245 },
    { lat: 49.1108556, lng: 20.193015 },
    { lat: 49.1115358, lng: 20.1928761 },
    { lat: 49.1116267, lng: 20.1928694 },
    { lat: 49.1118431, lng: 20.1928134 },
    { lat: 49.1119641, lng: 20.1926269 },
    { lat: 49.1121401, lng: 20.1925786 },
    { lat: 49.1128505, lng: 20.1925773 },
    { lat: 49.1133489, lng: 20.192991 },
    { lat: 49.1134006, lng: 20.1928428 },
    { lat: 49.1140956, lng: 20.1933908 },
    { lat: 49.1143147, lng: 20.1931495 },
    { lat: 49.114617, lng: 20.1933887 },
    { lat: 49.1149066, lng: 20.193193 },
    { lat: 49.11611, lng: 20.1941168 },
    { lat: 49.1165444, lng: 20.1945368 },
    { lat: 49.1164914, lng: 20.1945928 },
    { lat: 49.1164251, lng: 20.1945392 },
    { lat: 49.1161211, lng: 20.1948236 },
    { lat: 49.1159666, lng: 20.1948177 },
    { lat: 49.115898, lng: 20.1952418 },
    { lat: 49.1158089, lng: 20.1953507 },
    { lat: 49.1157284, lng: 20.1952553 },
    { lat: 49.1156597, lng: 20.1955377 },
    { lat: 49.1155005, lng: 20.1957555 },
    { lat: 49.114993, lng: 20.1960479 },
    { lat: 49.1158029, lng: 20.1966774 },
    { lat: 49.115635, lng: 20.196869 },
    { lat: 49.1154567, lng: 20.1971982 },
    { lat: 49.1154694, lng: 20.197435 },
    { lat: 49.1151209, lng: 20.1976185 },
    { lat: 49.1151025, lng: 20.1978931 },
    { lat: 49.1150074, lng: 20.1978806 },
    { lat: 49.1148947, lng: 20.1981017 },
    { lat: 49.1146432, lng: 20.198378 },
    { lat: 49.1145192, lng: 20.1984872 },
    { lat: 49.1143392, lng: 20.1985185 },
    { lat: 49.1140412, lng: 20.1988444 },
    { lat: 49.1138018, lng: 20.198938 },
    { lat: 49.1136832, lng: 20.1992433 },
    { lat: 49.1136172, lng: 20.1992548 },
    { lat: 49.1135875, lng: 20.1993291 },
    { lat: 49.1135169, lng: 20.199391 },
    { lat: 49.1133597, lng: 20.1993203 },
    { lat: 49.1134101, lng: 20.1994842 },
    { lat: 49.1132741, lng: 20.1996305 },
    { lat: 49.1131188, lng: 20.1996588 },
    { lat: 49.1130124, lng: 20.1998433 },
    { lat: 49.1129272, lng: 20.1998246 },
    { lat: 49.112559, lng: 20.2000257 },
    { lat: 49.1123361, lng: 20.2002228 },
    { lat: 49.1122122, lng: 20.200424 },
    { lat: 49.1120205, lng: 20.2005901 },
    { lat: 49.1119808, lng: 20.2007246 },
    { lat: 49.1116859, lng: 20.2010113 },
    { lat: 49.1114696, lng: 20.2015489 },
    { lat: 49.1112756, lng: 20.2018198 },
    { lat: 49.1111374, lng: 20.2018869 },
    { lat: 49.1110597, lng: 20.2019997 },
    { lat: 49.1109151, lng: 20.2022736 },
    { lat: 49.1108346, lng: 20.2025708 },
    { lat: 49.1107705, lng: 20.2025724 },
    { lat: 49.1106733, lng: 20.2027334 },
    { lat: 49.1106719, lng: 20.2029538 },
    { lat: 49.1105976, lng: 20.2030674 },
    { lat: 49.1103562, lng: 20.2032813 },
    { lat: 49.1103037, lng: 20.2032358 },
    { lat: 49.110217, lng: 20.2033516 },
    { lat: 49.1101054, lng: 20.2033763 },
    { lat: 49.1100329, lng: 20.2036475 },
    { lat: 49.1098035, lng: 20.2040806 },
    { lat: 49.10979, lng: 20.2045365 },
    { lat: 49.1097253, lng: 20.2046913 },
    { lat: 49.109752, lng: 20.204769 },
    { lat: 49.1095509, lng: 20.2053216 },
    { lat: 49.1094151, lng: 20.2053586 },
    { lat: 49.1093708, lng: 20.2054615 },
    { lat: 49.1090906, lng: 20.2056383 },
    { lat: 49.1089136, lng: 20.2058623 },
    { lat: 49.1087931, lng: 20.2062275 },
    { lat: 49.1086638, lng: 20.206437 },
    { lat: 49.1086224, lng: 20.20692 },
    { lat: 49.1086541, lng: 20.2070421 },
    { lat: 49.1085801, lng: 20.2071924 },
    { lat: 49.1085268, lng: 20.2074374 },
    { lat: 49.1085813, lng: 20.2075032 },
    { lat: 49.1084931, lng: 20.2076109 },
    { lat: 49.1085238, lng: 20.2077611 },
    { lat: 49.1084042, lng: 20.2081169 },
    { lat: 49.1083289, lng: 20.2086345 },
    { lat: 49.1083381, lng: 20.208867 },
    { lat: 49.1082281, lng: 20.2090751 },
    { lat: 49.1082777, lng: 20.209164 },
    { lat: 49.1081885, lng: 20.2093555 },
    { lat: 49.108208, lng: 20.209651 },
    { lat: 49.1082556, lng: 20.2097149 },
    { lat: 49.1080922, lng: 20.210305 },
    { lat: 49.1080781, lng: 20.210885 },
    { lat: 49.1080362, lng: 20.2109934 },
    { lat: 49.1081225, lng: 20.2114384 },
    { lat: 49.1080479, lng: 20.2116285 },
    { lat: 49.1081054, lng: 20.2121685 },
    { lat: 49.1080989, lng: 20.2125461 },
    { lat: 49.1080138, lng: 20.212614 },
    { lat: 49.1080389, lng: 20.2127655 },
    { lat: 49.1079952, lng: 20.2127983 },
    { lat: 49.1079758, lng: 20.2134165 },
    { lat: 49.1078755, lng: 20.2139296 },
    { lat: 49.1077853, lng: 20.2140712 },
    { lat: 49.1077665, lng: 20.2142455 },
    { lat: 49.1076645, lng: 20.2143994 },
    { lat: 49.1076625, lng: 20.21492 },
    { lat: 49.1075856, lng: 20.2151952 },
    { lat: 49.1075983, lng: 20.2153177 },
    { lat: 49.1075556, lng: 20.2155139 },
    { lat: 49.1074161, lng: 20.2157467 },
    { lat: 49.1074649, lng: 20.2159294 },
    { lat: 49.1074329, lng: 20.2162584 },
    { lat: 49.107463, lng: 20.2164483 },
    { lat: 49.1073611, lng: 20.216693 },
    { lat: 49.1074191, lng: 20.2170198 },
    { lat: 49.1073696, lng: 20.2171875 },
    { lat: 49.1073909, lng: 20.2173858 },
    { lat: 49.1072561, lng: 20.2179926 },
    { lat: 49.1070884, lng: 20.2183639 },
    { lat: 49.1069594, lng: 20.2188293 },
    { lat: 49.1069571, lng: 20.2195117 },
    { lat: 49.1069001, lng: 20.2196104 },
    { lat: 49.1069197, lng: 20.2204453 },
    { lat: 49.1067471, lng: 20.2208062 },
    { lat: 49.1067401, lng: 20.2209441 },
    { lat: 49.1067243, lng: 20.2209747 },
    { lat: 49.1066721, lng: 20.2210758 },
    { lat: 49.1066714, lng: 20.2213425 },
    { lat: 49.1064963, lng: 20.2216407 },
    { lat: 49.1064306, lng: 20.2220328 },
    { lat: 49.1064653, lng: 20.2221414 },
    { lat: 49.1063299, lng: 20.2224456 },
    { lat: 49.1063727, lng: 20.2225326 },
    { lat: 49.1062668, lng: 20.2229568 },
    { lat: 49.1062796, lng: 20.2232489 },
    { lat: 49.106015, lng: 20.2241602 },
    { lat: 49.1060504, lng: 20.2242317 },
    { lat: 49.1059299, lng: 20.2244911 },
    { lat: 49.1059535, lng: 20.2245148 },
    { lat: 49.1071514, lng: 20.2257347 },
    { lat: 49.1067824, lng: 20.2263156 },
    { lat: 49.1067584, lng: 20.2268188 },
    { lat: 49.1066232, lng: 20.2270181 },
    { lat: 49.1064288, lng: 20.2271376 },
    { lat: 49.1064629, lng: 20.2277186 },
    { lat: 49.1063875, lng: 20.2278606 },
    { lat: 49.1063853, lng: 20.2280082 },
    { lat: 49.106439, lng: 20.2280834 },
    { lat: 49.1063077, lng: 20.2281813 },
    { lat: 49.1067697, lng: 20.2284441 },
    { lat: 49.1079371, lng: 20.2300577 },
    { lat: 49.1078228, lng: 20.2306177 },
    { lat: 49.1076619, lng: 20.2311011 },
    { lat: 49.1073971, lng: 20.2315261 },
    { lat: 49.1074024, lng: 20.2316902 },
    { lat: 49.1076438, lng: 20.2321853 },
    { lat: 49.1076146, lng: 20.2338963 },
    { lat: 49.1065823, lng: 20.2378483 },
    { lat: 49.1066749, lng: 20.2387118 },
    { lat: 49.1067888, lng: 20.2390881 },
    { lat: 49.1069496, lng: 20.2393347 },
    { lat: 49.1070267, lng: 20.2395796 },
    { lat: 49.107002, lng: 20.2397242 },
    { lat: 49.1070611, lng: 20.2398248 },
    { lat: 49.1070746, lng: 20.2400518 },
    { lat: 49.1071332, lng: 20.2401093 },
    { lat: 49.1071712, lng: 20.2406468 },
    { lat: 49.1072371, lng: 20.2406605 },
    { lat: 49.1096533, lng: 20.2379114 },
    { lat: 49.1105799, lng: 20.2382742 },
    { lat: 49.111408, lng: 20.2392897 },
    { lat: 49.1117514, lng: 20.2387989 },
    { lat: 49.1119882, lng: 20.2386342 },
    { lat: 49.1122785, lng: 20.239002 },
    { lat: 49.1122895, lng: 20.2389093 },
    { lat: 49.1125586, lng: 20.2385946 },
    { lat: 49.1133458, lng: 20.2389126 },
    { lat: 49.113264, lng: 20.2393522 },
    { lat: 49.1138179, lng: 20.2402936 },
    { lat: 49.1135435, lng: 20.2407856 },
    { lat: 49.1135943, lng: 20.2407343 },
    { lat: 49.113726, lng: 20.2410152 },
    { lat: 49.1140973, lng: 20.2421225 },
    { lat: 49.1138351, lng: 20.2423597 },
    { lat: 49.1135781, lng: 20.2426944 },
    { lat: 49.1134623, lng: 20.2425522 },
    { lat: 49.1132766, lng: 20.2427554 },
    { lat: 49.1135269, lng: 20.2433659 },
    { lat: 49.1137443, lng: 20.2431692 },
    { lat: 49.1140389, lng: 20.2432695 },
    { lat: 49.1142798, lng: 20.2431537 },
    { lat: 49.1146008, lng: 20.2429295 },
    { lat: 49.114862, lng: 20.2426123 },
    { lat: 49.1149914, lng: 20.2426365 },
    { lat: 49.1161295, lng: 20.2416553 },
    { lat: 49.1166176, lng: 20.2424724 },
    { lat: 49.1171812, lng: 20.2423698 },
    { lat: 49.1174654, lng: 20.2428378 },
    { lat: 49.1182306, lng: 20.2423994 },
    { lat: 49.1183608, lng: 20.2427039 },
    { lat: 49.1187727, lng: 20.2433546 },
    { lat: 49.1191124, lng: 20.2430583 },
    { lat: 49.1192485, lng: 20.2433683 },
    { lat: 49.1194679, lng: 20.2442201 },
    { lat: 49.1200025, lng: 20.245789 },
    { lat: 49.120627, lng: 20.2453775 },
    { lat: 49.1217662, lng: 20.2472008 },
    { lat: 49.1219246, lng: 20.2478507 },
    { lat: 49.122883, lng: 20.2472778 },
    { lat: 49.1230964, lng: 20.2474061 },
    { lat: 49.1231256, lng: 20.2473743 },
    { lat: 49.1233799, lng: 20.2479436 },
    { lat: 49.1234524, lng: 20.247955 },
    { lat: 49.1236376, lng: 20.2484547 },
    { lat: 49.1239104, lng: 20.2491809 },
    { lat: 49.1245029, lng: 20.2486709 },
    { lat: 49.1245785, lng: 20.2485579 },
    { lat: 49.1260311, lng: 20.2513074 },
    { lat: 49.1261183, lng: 20.2514077 },
    { lat: 49.1262293, lng: 20.2514231 },
    { lat: 49.1261508, lng: 20.2516881 },
    { lat: 49.12668, lng: 20.2531524 },
    { lat: 49.1272701, lng: 20.2524913 },
    { lat: 49.1271988, lng: 20.2522555 },
    { lat: 49.1275555, lng: 20.251904 },
    { lat: 49.128161, lng: 20.2515507 },
    { lat: 49.1283235, lng: 20.251387 },
    { lat: 49.1282466, lng: 20.2510636 },
    { lat: 49.1291371, lng: 20.2505703 },
    { lat: 49.1302649, lng: 20.2536255 },
    { lat: 49.1303195, lng: 20.2536392 },
    { lat: 49.1303559, lng: 20.2534788 },
    { lat: 49.1303354, lng: 20.2533908 },
    { lat: 49.1303329, lng: 20.2533802 },
    { lat: 49.1304635, lng: 20.2532068 },
    { lat: 49.1306231, lng: 20.2525368 },
    { lat: 49.1307183, lng: 20.2523843 },
    { lat: 49.130754, lng: 20.2520923 },
    { lat: 49.1308036, lng: 20.2520036 },
    { lat: 49.1311888, lng: 20.2518063 },
    { lat: 49.131313, lng: 20.2518442 },
    { lat: 49.1314313, lng: 20.251672 },
    { lat: 49.1314366, lng: 20.2514349 },
    { lat: 49.1315483, lng: 20.2513226 },
    { lat: 49.1316185, lng: 20.2511349 },
    { lat: 49.1315128, lng: 20.2510274 },
    { lat: 49.1315744, lng: 20.2509322 },
    { lat: 49.1316402, lng: 20.2506245 },
    { lat: 49.1318924, lng: 20.2503408 },
    { lat: 49.1318607, lng: 20.2501341 },
    { lat: 49.131916, lng: 20.2498784 },
    { lat: 49.1322186, lng: 20.2498443 },
    { lat: 49.1322662, lng: 20.2496814 },
    { lat: 49.132406, lng: 20.2497671 },
    { lat: 49.1327043, lng: 20.2493674 },
    { lat: 49.1331264, lng: 20.2497618 },
    { lat: 49.1333437, lng: 20.2497305 },
    { lat: 49.1337182, lng: 20.2501217 },
    { lat: 49.1340204, lng: 20.2496752 },
    { lat: 49.1346951, lng: 20.2492525 },
    { lat: 49.1363617, lng: 20.2473016 },
    { lat: 49.1368686, lng: 20.2483654 },
    { lat: 49.1355599, lng: 20.2500198 },
    { lat: 49.1361807, lng: 20.2507215 },
    { lat: 49.1366374, lng: 20.2501908 },
    { lat: 49.1376368, lng: 20.2507224 },
    { lat: 49.1380844, lng: 20.2501321 },
    { lat: 49.1388968, lng: 20.2504934 },
    { lat: 49.1393253, lng: 20.2512804 },
    { lat: 49.1396248, lng: 20.2511362 },
    { lat: 49.1406823, lng: 20.2518004 },
    { lat: 49.1411102, lng: 20.2521476 },
    { lat: 49.1409093, lng: 20.2527636 },
    { lat: 49.1408026, lng: 20.2535186 },
    { lat: 49.140867, lng: 20.2538982 },
    { lat: 49.1407194, lng: 20.2551557 },
    { lat: 49.1404908, lng: 20.2553869 },
    { lat: 49.1407933, lng: 20.2558898 },
    { lat: 49.1407138, lng: 20.2559866 },
    { lat: 49.1407096, lng: 20.2561697 },
    { lat: 49.1405551, lng: 20.2562478 },
    { lat: 49.1404989, lng: 20.2561932 },
    { lat: 49.1404665, lng: 20.2564033 },
    { lat: 49.1400601, lng: 20.2565447 },
    { lat: 49.1397353, lng: 20.2568775 },
    { lat: 49.1395048, lng: 20.2569467 },
    { lat: 49.139474, lng: 20.257075 },
    { lat: 49.1393513, lng: 20.2571639 },
    { lat: 49.1391679, lng: 20.2574287 },
    { lat: 49.1390254, lng: 20.2574698 },
    { lat: 49.1389595, lng: 20.2576541 },
    { lat: 49.1388426, lng: 20.2578025 },
    { lat: 49.1387018, lng: 20.2577985 },
    { lat: 49.1386222, lng: 20.2578981 },
    { lat: 49.138527, lng: 20.2580525 },
    { lat: 49.1385215, lng: 20.2582022 },
    { lat: 49.1383444, lng: 20.2581622 },
    { lat: 49.1383477, lng: 20.2582752 },
    { lat: 49.1382773, lng: 20.2583453 },
    { lat: 49.1382164, lng: 20.2582854 },
    { lat: 49.1381723, lng: 20.2584002 },
    { lat: 49.1380501, lng: 20.2584678 },
    { lat: 49.1377426, lng: 20.2584958 },
    { lat: 49.1375601, lng: 20.2587217 },
    { lat: 49.1374878, lng: 20.2589185 },
    { lat: 49.137327, lng: 20.2589515 },
    { lat: 49.1372448, lng: 20.2591539 },
    { lat: 49.1372907, lng: 20.2592598 },
    { lat: 49.1372421, lng: 20.2593142 },
    { lat: 49.1372453, lng: 20.2595092 },
    { lat: 49.1371644, lng: 20.2595176 },
    { lat: 49.1370367, lng: 20.2597932 },
    { lat: 49.1369596, lng: 20.2598362 },
    { lat: 49.1367876, lng: 20.2597821 },
    { lat: 49.1367124, lng: 20.2599033 },
    { lat: 49.1366105, lng: 20.2603517 },
    { lat: 49.1366312, lng: 20.2604217 },
    { lat: 49.1363925, lng: 20.26082 },
    { lat: 49.136254, lng: 20.2608211 },
    { lat: 49.1361499, lng: 20.260983 },
    { lat: 49.1360507, lng: 20.26101 },
    { lat: 49.1357262, lng: 20.2614593 },
    { lat: 49.1355055, lng: 20.2615484 },
    { lat: 49.1354284, lng: 20.2616529 },
    { lat: 49.1353894, lng: 20.2615726 },
    { lat: 49.1353506, lng: 20.2615891 },
    { lat: 49.1354007, lng: 20.2617464 },
    { lat: 49.1352669, lng: 20.2618121 },
    { lat: 49.1353219, lng: 20.2619513 },
    { lat: 49.1350667, lng: 20.2621047 },
    { lat: 49.1351175, lng: 20.262167 },
    { lat: 49.1351066, lng: 20.2622852 },
    { lat: 49.1350086, lng: 20.262375 },
    { lat: 49.1350796, lng: 20.2624326 },
    { lat: 49.1350736, lng: 20.262509 },
    { lat: 49.1348486, lng: 20.2629459 },
    { lat: 49.1347426, lng: 20.2630836 },
    { lat: 49.1346566, lng: 20.2631047 },
    { lat: 49.1346274, lng: 20.263189 },
    { lat: 49.1345072, lng: 20.2631687 },
    { lat: 49.1343992, lng: 20.2633993 },
    { lat: 49.1342551, lng: 20.2634872 },
    { lat: 49.1343094, lng: 20.2635542 },
    { lat: 49.1342883, lng: 20.2636762 },
    { lat: 49.1341164, lng: 20.2636257 },
    { lat: 49.1340752, lng: 20.2637556 },
    { lat: 49.1340224, lng: 20.2637331 },
    { lat: 49.1339288, lng: 20.2639563 },
    { lat: 49.1337064, lng: 20.2642002 },
    { lat: 49.1335267, lng: 20.2642396 },
    { lat: 49.1334455, lng: 20.2641772 },
    { lat: 49.1333223, lng: 20.2642553 },
    { lat: 49.1331337, lng: 20.2646709 },
    { lat: 49.1331331, lng: 20.2647702 },
    { lat: 49.1329456, lng: 20.2650422 },
    { lat: 49.1329256, lng: 20.2653097 },
    { lat: 49.1328892, lng: 20.2653552 },
    { lat: 49.1328343, lng: 20.2654238 },
    { lat: 49.1328613, lng: 20.2656504 },
    { lat: 49.1328183, lng: 20.265847 },
    { lat: 49.1326106, lng: 20.2661532 },
    { lat: 49.1325966, lng: 20.2664821 },
    { lat: 49.1324885, lng: 20.2665951 },
    { lat: 49.1325477, lng: 20.2666429 },
    { lat: 49.1325511, lng: 20.2668447 },
    { lat: 49.1324482, lng: 20.2668932 },
    { lat: 49.1325149, lng: 20.2669879 },
    { lat: 49.1324973, lng: 20.2670564 },
    { lat: 49.1323431, lng: 20.2670819 },
    { lat: 49.1321595, lng: 20.2673406 },
    { lat: 49.132041, lng: 20.2673636 },
    { lat: 49.1320261, lng: 20.267471 },
    { lat: 49.1319172, lng: 20.2675661 },
    { lat: 49.1319027, lng: 20.2676296 },
    { lat: 49.1319454, lng: 20.2676826 },
    { lat: 49.1319, lng: 20.2677878 },
    { lat: 49.1318172, lng: 20.267741 },
    { lat: 49.1317873, lng: 20.2678692 },
    { lat: 49.1318464, lng: 20.2679742 },
    { lat: 49.1317401, lng: 20.2683021 },
    { lat: 49.1317491, lng: 20.268508 },
    { lat: 49.1316198, lng: 20.2687693 },
    { lat: 49.131663, lng: 20.2689265 },
    { lat: 49.1315477, lng: 20.2690855 },
    { lat: 49.1315205, lng: 20.2693429 },
    { lat: 49.1315723, lng: 20.269568 },
    { lat: 49.1313946, lng: 20.2696344 },
    { lat: 49.1314083, lng: 20.2697849 },
    { lat: 49.1310034, lng: 20.2705161 },
    { lat: 49.1310385, lng: 20.2706643 },
    { lat: 49.1309393, lng: 20.2707463 },
    { lat: 49.1307799, lng: 20.2712789 },
    { lat: 49.1306375, lng: 20.2715547 },
    { lat: 49.1305471, lng: 20.2715179 },
    { lat: 49.1304381, lng: 20.2716623 },
    { lat: 49.1303563, lng: 20.2720196 },
    { lat: 49.1302993, lng: 20.2720291 },
    { lat: 49.1302309, lng: 20.2721554 },
    { lat: 49.1302127, lng: 20.2722347 },
    { lat: 49.130272, lng: 20.272339 },
    { lat: 49.1299797, lng: 20.2727873 },
    { lat: 49.1300431, lng: 20.2729344 },
    { lat: 49.129985, lng: 20.2732068 },
    { lat: 49.1301301, lng: 20.2734655 },
    { lat: 49.1300558, lng: 20.2736099 },
    { lat: 49.1300596, lng: 20.2737077 },
    { lat: 49.1299141, lng: 20.273758 },
    { lat: 49.1298753, lng: 20.2738881 },
    { lat: 49.1299025, lng: 20.2740043 },
    { lat: 49.1296936, lng: 20.2743641 },
    { lat: 49.1296349, lng: 20.2743899 },
    { lat: 49.1297061, lng: 20.2745388 },
    { lat: 49.1295058, lng: 20.2749201 },
    { lat: 49.1294328, lng: 20.2748947 },
    { lat: 49.1292467, lng: 20.2750903 },
    { lat: 49.1291919, lng: 20.2750698 },
    { lat: 49.1291678, lng: 20.2752323 },
    { lat: 49.1289459, lng: 20.2756343 },
    { lat: 49.1289513, lng: 20.2757118 },
    { lat: 49.1287981, lng: 20.2757405 },
    { lat: 49.1288319, lng: 20.2759715 },
    { lat: 49.1286998, lng: 20.2763657 },
    { lat: 49.1285324, lng: 20.2765786 },
    { lat: 49.1284527, lng: 20.2765859 },
    { lat: 49.1283895, lng: 20.2768415 },
    { lat: 49.1284203, lng: 20.2769642 },
    { lat: 49.1283477, lng: 20.2769787 },
    { lat: 49.1281059, lng: 20.2772473 },
    { lat: 49.1281181, lng: 20.2774137 },
    { lat: 49.1280649, lng: 20.277434 },
    { lat: 49.1279525, lng: 20.2776757 },
    { lat: 49.1279366, lng: 20.277872 },
    { lat: 49.1277839, lng: 20.2783734 },
    { lat: 49.1278137, lng: 20.2787306 },
    { lat: 49.1278877, lng: 20.278958 },
    { lat: 49.1279873, lng: 20.2789957 },
    { lat: 49.1279658, lng: 20.2791997 },
    { lat: 49.1279171, lng: 20.2791919 },
    { lat: 49.1279807, lng: 20.2793749 },
    { lat: 49.1278776, lng: 20.2797312 },
    { lat: 49.1278909, lng: 20.2799576 },
    { lat: 49.1278505, lng: 20.2801076 },
    { lat: 49.1279278, lng: 20.2806198 },
    { lat: 49.1278207, lng: 20.280988 },
    { lat: 49.1278127, lng: 20.281098 },
    { lat: 49.1278518, lng: 20.2811516 },
    { lat: 49.1277835, lng: 20.2812193 },
    { lat: 49.1277759, lng: 20.2814242 },
    { lat: 49.1277124, lng: 20.2814411 },
    { lat: 49.1276457, lng: 20.2815831 },
    { lat: 49.1276286, lng: 20.281718 },
    { lat: 49.12768, lng: 20.2819107 },
    { lat: 49.1276353, lng: 20.2819518 },
    { lat: 49.127628, lng: 20.2821092 },
    { lat: 49.1275934, lng: 20.2821774 },
    { lat: 49.1274242, lng: 20.282141 },
    { lat: 49.1273756, lng: 20.282281 },
    { lat: 49.127438, lng: 20.2824353 },
    { lat: 49.1274412, lng: 20.2827446 },
    { lat: 49.1273439, lng: 20.2827926 },
    { lat: 49.1273698, lng: 20.2829966 },
    { lat: 49.1273227, lng: 20.2831731 },
    { lat: 49.1271397, lng: 20.2834478 },
    { lat: 49.1271574, lng: 20.2837277 },
    { lat: 49.1267685, lng: 20.2840355 },
    { lat: 49.1259572, lng: 20.2831868 },
    { lat: 49.1247318, lng: 20.2824827 },
    { lat: 49.1226905, lng: 20.2817074 },
    { lat: 49.1212653, lng: 20.281284 },
    { lat: 49.1203919, lng: 20.2806345 },
    { lat: 49.1199345, lng: 20.2808557 },
    { lat: 49.1197371, lng: 20.281832 },
    { lat: 49.1204857, lng: 20.2824019 },
    { lat: 49.1213384, lng: 20.2832417 },
    { lat: 49.1211704, lng: 20.2837868 },
    { lat: 49.1210909, lng: 20.2843498 },
    { lat: 49.1210502, lng: 20.2853565 },
    { lat: 49.1204102, lng: 20.2870174 },
    { lat: 49.1200689, lng: 20.2880815 },
    { lat: 49.1188559, lng: 20.2894311 },
    { lat: 49.1185028, lng: 20.2892423 },
    { lat: 49.1182979, lng: 20.2895905 },
    { lat: 49.1181666, lng: 20.2900955 },
    { lat: 49.1175697, lng: 20.2915651 },
    { lat: 49.1169866, lng: 20.2922343 },
    { lat: 49.1164079, lng: 20.2924074 },
    { lat: 49.1160444, lng: 20.2920718 },
    { lat: 49.1156342, lng: 20.2923238 },
    { lat: 49.1157567, lng: 20.2926334 },
    { lat: 49.1160834, lng: 20.2930963 },
    { lat: 49.1147852, lng: 20.2936497 },
    { lat: 49.1138943, lng: 20.2948869 },
    { lat: 49.1138727, lng: 20.2975163 },
    { lat: 49.1127311, lng: 20.3001289 },
    { lat: 49.1129029, lng: 20.3006325 },
    { lat: 49.1131097, lng: 20.3018136 },
    { lat: 49.1133926, lng: 20.3029796 },
    { lat: 49.1139139, lng: 20.3033258 },
    { lat: 49.1145713, lng: 20.3030901 },
    { lat: 49.1150264, lng: 20.3027726 },
    { lat: 49.1153942, lng: 20.3023279 },
    { lat: 49.1157309, lng: 20.3032267 },
    { lat: 49.1169755, lng: 20.3016471 },
    { lat: 49.1177383, lng: 20.301367 },
    { lat: 49.118899, lng: 20.3012475 },
    { lat: 49.1190343, lng: 20.3015707 },
    { lat: 49.1191093, lng: 20.3014108 },
    { lat: 49.120443, lng: 20.303206 },
    { lat: 49.1206, lng: 20.303036 },
    { lat: 49.121187, lng: 20.302229 },
    { lat: 49.121467, lng: 20.301767 },
    { lat: 49.121644, lng: 20.301476 },
    { lat: 49.122132, lng: 20.300873 },
    { lat: 49.122542, lng: 20.300308 },
    { lat: 49.12336, lng: 20.29929 },
    { lat: 49.123601, lng: 20.299066 },
    { lat: 49.123595, lng: 20.299024 },
    { lat: 49.123482, lng: 20.298311 },
    { lat: 49.123339, lng: 20.297542 },
    { lat: 49.123317, lng: 20.297483 },
    { lat: 49.123301, lng: 20.297449 },
    { lat: 49.123394, lng: 20.297454 },
    { lat: 49.123406, lng: 20.29735 },
    { lat: 49.123569, lng: 20.297241 },
    { lat: 49.123612, lng: 20.297214 },
    { lat: 49.123625, lng: 20.297251 },
    { lat: 49.123662, lng: 20.297299 },
    { lat: 49.123736, lng: 20.29718 },
    { lat: 49.123759, lng: 20.297104 },
    { lat: 49.123801, lng: 20.297104 },
    { lat: 49.123842, lng: 20.297006 },
    { lat: 49.123842, lng: 20.2969 },
    { lat: 49.123884, lng: 20.29684 },
    { lat: 49.123907, lng: 20.296778 },
    { lat: 49.123941, lng: 20.296731 },
    { lat: 49.123942, lng: 20.296631 },
    { lat: 49.124033, lng: 20.296622 },
    { lat: 49.124035, lng: 20.296427 },
    { lat: 49.124061, lng: 20.296393 },
    { lat: 49.124128, lng: 20.296319 },
    { lat: 49.124143, lng: 20.29612 },
    { lat: 49.124207, lng: 20.296027 },
    { lat: 49.124395, lng: 20.295987 },
    { lat: 49.124452, lng: 20.295974 },
    { lat: 49.124521, lng: 20.29601 },
    { lat: 49.124552, lng: 20.296067 },
    { lat: 49.124612, lng: 20.296097 },
    { lat: 49.124665, lng: 20.295939 },
    { lat: 49.124733, lng: 20.29593 },
    { lat: 49.124776, lng: 20.295911 },
    { lat: 49.124787, lng: 20.295946 },
    { lat: 49.124768, lng: 20.295995 },
    { lat: 49.124837, lng: 20.296012 },
    { lat: 49.124849, lng: 20.295982 },
    { lat: 49.12484, lng: 20.295953 },
    { lat: 49.124886, lng: 20.295868 },
    { lat: 49.124926, lng: 20.29587 },
    { lat: 49.124926, lng: 20.295826 },
    { lat: 49.125015, lng: 20.295698 },
    { lat: 49.124969, lng: 20.29563 },
    { lat: 49.124981, lng: 20.295581 },
    { lat: 49.125031, lng: 20.295567 },
    { lat: 49.125032, lng: 20.295394 },
    { lat: 49.125074, lng: 20.295262 },
    { lat: 49.125099, lng: 20.295262 },
    { lat: 49.125072, lng: 20.295203 },
    { lat: 49.125005, lng: 20.29522 },
    { lat: 49.125, lng: 20.295138 },
    { lat: 49.125, lng: 20.295093 },
    { lat: 49.124993, lng: 20.295027 },
    { lat: 49.125019, lng: 20.294982 },
    { lat: 49.12506, lng: 20.294983 },
    { lat: 49.125075, lng: 20.294923 },
    { lat: 49.125095, lng: 20.294891 },
    { lat: 49.125085, lng: 20.294746 },
    { lat: 49.125129, lng: 20.294694 },
    { lat: 49.125143, lng: 20.294636 },
    { lat: 49.125195, lng: 20.294589 },
    { lat: 49.125299, lng: 20.294556 },
    { lat: 49.125338, lng: 20.294485 },
    { lat: 49.125462, lng: 20.294268 },
    { lat: 49.125487, lng: 20.294224 },
    { lat: 49.125539, lng: 20.294159 },
    { lat: 49.125604, lng: 20.294112 },
    { lat: 49.125627, lng: 20.293996 },
    { lat: 49.125681, lng: 20.29402 },
    { lat: 49.125733, lng: 20.294021 },
    { lat: 49.125744, lng: 20.293944 },
    { lat: 49.125794, lng: 20.293946 },
    { lat: 49.125844, lng: 20.293917 },
    { lat: 49.125901, lng: 20.293961 },
    { lat: 49.125924, lng: 20.293928 },
    { lat: 49.126011, lng: 20.293985 },
    { lat: 49.126128, lng: 20.293926 },
    { lat: 49.12615, lng: 20.2939 },
    { lat: 49.126232, lng: 20.293857 },
    { lat: 49.126232, lng: 20.293773 },
    { lat: 49.126276, lng: 20.293709 },
    { lat: 49.126349, lng: 20.293723 },
    { lat: 49.126483, lng: 20.293651 },
    { lat: 49.126522, lng: 20.293609 },
    { lat: 49.1266, lng: 20.293664 },
    { lat: 49.126643, lng: 20.293714 },
    { lat: 49.12669, lng: 20.293758 },
    { lat: 49.126742, lng: 20.293784 },
    { lat: 49.126802, lng: 20.293799 },
    { lat: 49.126841, lng: 20.293758 },
    { lat: 49.126875, lng: 20.293784 },
    { lat: 49.126937, lng: 20.293785 },
    { lat: 49.12697, lng: 20.293808 },
    { lat: 49.127019, lng: 20.293809 },
    { lat: 49.127071, lng: 20.293757 },
    { lat: 49.12712, lng: 20.293758 },
    { lat: 49.127183, lng: 20.293687 },
    { lat: 49.127251, lng: 20.293702 },
    { lat: 49.127308, lng: 20.293662 },
    { lat: 49.127308, lng: 20.293632 },
    { lat: 49.127339, lng: 20.293588 },
    { lat: 49.127363, lng: 20.293509 },
    { lat: 49.127449, lng: 20.293527 },
    { lat: 49.12747, lng: 20.293486 },
    { lat: 49.127507, lng: 20.293479 },
    { lat: 49.127621, lng: 20.29343 },
    { lat: 49.127667, lng: 20.293273 },
    { lat: 49.127693, lng: 20.293203 },
    { lat: 49.127716, lng: 20.293132 },
    { lat: 49.127717, lng: 20.293015 },
    { lat: 49.127826, lng: 20.292964 },
    { lat: 49.127845, lng: 20.292883 },
    { lat: 49.127929, lng: 20.292811 },
    { lat: 49.127949, lng: 20.292746 },
    { lat: 49.127978, lng: 20.292732 },
    { lat: 49.12801, lng: 20.292671 },
    { lat: 49.128026, lng: 20.292524 },
    { lat: 49.128074, lng: 20.292464 },
    { lat: 49.128096, lng: 20.292354 },
    { lat: 49.128087, lng: 20.292312 },
    { lat: 49.128209, lng: 20.292255 },
    { lat: 49.128243, lng: 20.292197 },
    { lat: 49.128478, lng: 20.29213 },
    { lat: 49.12867, lng: 20.292342 },
    { lat: 49.128839, lng: 20.292284 },
    { lat: 49.128891, lng: 20.29222 },
    { lat: 49.128914, lng: 20.292246 },
    { lat: 49.128953, lng: 20.292229 },
    { lat: 49.128968, lng: 20.292216 },
    { lat: 49.128973, lng: 20.292183 },
    { lat: 49.129028, lng: 20.292132 },
    { lat: 49.129107, lng: 20.292109 },
    { lat: 49.129131, lng: 20.29212 },
    { lat: 49.129166, lng: 20.292121 },
    { lat: 49.129202, lng: 20.292102 },
    { lat: 49.12922, lng: 20.292069 },
    { lat: 49.129253, lng: 20.292057 },
    { lat: 49.129288, lng: 20.292014 },
    { lat: 49.129317, lng: 20.292015 },
    { lat: 49.129365, lng: 20.291943 },
    { lat: 49.129454, lng: 20.291944 },
    { lat: 49.129489, lng: 20.2919 },
    { lat: 49.129574, lng: 20.291875 },
    { lat: 49.129592, lng: 20.291819 },
    { lat: 49.129636, lng: 20.291825 },
    { lat: 49.129726, lng: 20.291732 },
    { lat: 49.129753, lng: 20.291669 },
    { lat: 49.129867, lng: 20.291647 },
    { lat: 49.129896, lng: 20.2916 },
    { lat: 49.12993, lng: 20.291554 },
    { lat: 49.129914, lng: 20.291428 },
    { lat: 49.129944, lng: 20.291404 },
    { lat: 49.129975, lng: 20.291406 },
    { lat: 49.130008, lng: 20.291431 },
    { lat: 49.130069, lng: 20.291432 },
    { lat: 49.130199, lng: 20.291343 },
    { lat: 49.13024, lng: 20.291289 },
    { lat: 49.130294, lng: 20.291203 },
    { lat: 49.130303, lng: 20.291061 },
    { lat: 49.130352, lng: 20.290946 },
    { lat: 49.130353, lng: 20.290784 },
    { lat: 49.130475, lng: 20.290738 },
    { lat: 49.1305, lng: 20.290848 },
    { lat: 49.130559, lng: 20.290865 },
    { lat: 49.130635, lng: 20.290824 },
    { lat: 49.130682, lng: 20.290758 },
    { lat: 49.13071, lng: 20.290767 },
    { lat: 49.130735, lng: 20.290765 },
    { lat: 49.130787, lng: 20.290654 },
    { lat: 49.130788, lng: 20.290565 },
    { lat: 49.130809, lng: 20.290482 },
    { lat: 49.130815, lng: 20.290412 },
    { lat: 49.130839, lng: 20.290366 },
    { lat: 49.130825, lng: 20.290221 },
    { lat: 49.130871, lng: 20.290105 },
    { lat: 49.130896, lng: 20.289869 },
    { lat: 49.131159, lng: 20.289662 },
    { lat: 49.131277, lng: 20.289837 },
    { lat: 49.131375, lng: 20.289903 },
    { lat: 49.131435, lng: 20.289949 },
    { lat: 49.131444, lng: 20.289876 },
    { lat: 49.131406, lng: 20.28931 },
    { lat: 49.131362, lng: 20.288543 },
    { lat: 49.131612, lng: 20.288479 },
    { lat: 49.131533, lng: 20.288193 },
    { lat: 49.131785, lng: 20.287597 },
    { lat: 49.131862, lng: 20.287507 },
    { lat: 49.132348, lng: 20.286927 },
    { lat: 49.132524, lng: 20.286558 },
    { lat: 49.132782, lng: 20.285939 },
    { lat: 49.132935, lng: 20.285317 },
    { lat: 49.132938, lng: 20.285198 },
    { lat: 49.132963, lng: 20.284163 },
    { lat: 49.132947, lng: 20.283767 },
    { lat: 49.132998, lng: 20.283606 },
    { lat: 49.133052, lng: 20.283117 },
    { lat: 49.133238, lng: 20.282339 },
    { lat: 49.133645, lng: 20.281395 },
    { lat: 49.133878, lng: 20.281026 },
    { lat: 49.134333, lng: 20.280598 },
    { lat: 49.134767, lng: 20.280016 },
    { lat: 49.134921, lng: 20.279753 },
    { lat: 49.135654, lng: 20.278643 },
    { lat: 49.135765, lng: 20.278004 },
    { lat: 49.135974, lng: 20.277619 },
    { lat: 49.136321, lng: 20.276914 },
    { lat: 49.13633, lng: 20.276579 },
    { lat: 49.136346, lng: 20.276448 },
    { lat: 49.136789, lng: 20.277358 },
    { lat: 49.136855, lng: 20.277593 },
    { lat: 49.137234, lng: 20.278439 },
    { lat: 49.137766, lng: 20.280477 },
    { lat: 49.13823, lng: 20.282744 },
    { lat: 49.138569, lng: 20.284221 },
    { lat: 49.1388, lng: 20.285312 },
    { lat: 49.140641, lng: 20.283495 },
    { lat: 49.141076, lng: 20.283719 },
    { lat: 49.141063, lng: 20.28561 },
    { lat: 49.141062, lng: 20.285678 },
    { lat: 49.141043, lng: 20.287091 },
    { lat: 49.141042, lng: 20.2889 },
    { lat: 49.141978, lng: 20.288123 },
    { lat: 49.143143, lng: 20.286623 },
    { lat: 49.144175, lng: 20.286013 },
    { lat: 49.145562, lng: 20.284669 },
    { lat: 49.145687, lng: 20.284644 },
    { lat: 49.14585, lng: 20.284475 },
    { lat: 49.146343, lng: 20.283975 },
    { lat: 49.146451, lng: 20.283879 },
    { lat: 49.146967, lng: 20.283315 },
    { lat: 49.148091, lng: 20.282369 },
    { lat: 49.148179, lng: 20.282466 },
    { lat: 49.148928, lng: 20.28263 },
    { lat: 49.14895, lng: 20.282717 },
    { lat: 49.149011, lng: 20.282744 },
    { lat: 49.149176, lng: 20.282865 },
    { lat: 49.149337, lng: 20.283022 },
    { lat: 49.150628, lng: 20.283891 },
    { lat: 49.150907, lng: 20.284172 },
    { lat: 49.152151, lng: 20.28495 },
    { lat: 49.153725, lng: 20.286032 },
    { lat: 49.155736, lng: 20.287868 },
    { lat: 49.155738, lng: 20.288093 },
    { lat: 49.156081, lng: 20.288421 },
    { lat: 49.156519, lng: 20.288896 },
    { lat: 49.156691, lng: 20.288666 },
    { lat: 49.157248, lng: 20.287922 },
    { lat: 49.157514, lng: 20.287596 },
    { lat: 49.157535, lng: 20.287584 },
    { lat: 49.15754, lng: 20.287612 },
    { lat: 49.15754, lng: 20.287615 },
    { lat: 49.157538, lng: 20.287654 },
    { lat: 49.157528, lng: 20.287736 },
    { lat: 49.157487, lng: 20.287843 },
    { lat: 49.157409, lng: 20.287932 },
    { lat: 49.157366, lng: 20.287992 },
    { lat: 49.157341, lng: 20.288012 },
    { lat: 49.157326, lng: 20.288055 },
    { lat: 49.157317, lng: 20.288118 },
    { lat: 49.157325, lng: 20.288196 },
    { lat: 49.157362, lng: 20.288261 },
    { lat: 49.157444, lng: 20.288365 },
    { lat: 49.157436, lng: 20.288442 },
    { lat: 49.157406, lng: 20.288604 },
    { lat: 49.157353, lng: 20.288709 },
    { lat: 49.157337, lng: 20.28876 },
    { lat: 49.157311, lng: 20.288844 },
    { lat: 49.157257, lng: 20.288971 },
    { lat: 49.157256, lng: 20.289013 },
    { lat: 49.157249, lng: 20.289036 },
    { lat: 49.157239, lng: 20.289057 },
    { lat: 49.157143, lng: 20.289293 },
    { lat: 49.157111, lng: 20.289386 },
    { lat: 49.157096, lng: 20.289465 },
    { lat: 49.157121, lng: 20.289577 },
    { lat: 49.157108, lng: 20.289772 },
    { lat: 49.157116, lng: 20.289844 },
    { lat: 49.1571, lng: 20.289921 },
    { lat: 49.157041, lng: 20.290087 },
    { lat: 49.156968, lng: 20.290265 },
    { lat: 49.156902, lng: 20.290348 },
    { lat: 49.156886, lng: 20.290401 },
    { lat: 49.156885, lng: 20.290458 },
    { lat: 49.156893, lng: 20.290601 },
    { lat: 49.156924, lng: 20.290731 },
    { lat: 49.15698, lng: 20.290861 },
    { lat: 49.156989, lng: 20.290916 },
    { lat: 49.156938, lng: 20.291121 },
    { lat: 49.156878, lng: 20.291231 },
    { lat: 49.15681, lng: 20.291319 },
    { lat: 49.156758, lng: 20.291404 },
    { lat: 49.15667, lng: 20.291478 },
    { lat: 49.156661, lng: 20.291521 },
    { lat: 49.156608, lng: 20.291668 },
    { lat: 49.156524, lng: 20.291755 },
    { lat: 49.156467, lng: 20.291871 },
    { lat: 49.156446, lng: 20.291884 },
    { lat: 49.156345, lng: 20.291866 },
    { lat: 49.156267, lng: 20.291904 },
    { lat: 49.156218, lng: 20.291925 },
    { lat: 49.156201, lng: 20.29197 },
    { lat: 49.156192, lng: 20.292028 },
    { lat: 49.156133, lng: 20.292042 },
    { lat: 49.156089, lng: 20.292109 },
    { lat: 49.156055, lng: 20.292181 },
    { lat: 49.156061, lng: 20.29237 },
    { lat: 49.156062, lng: 20.292472 },
    { lat: 49.156007, lng: 20.292524 },
    { lat: 49.15598, lng: 20.292564 },
    { lat: 49.155922, lng: 20.292631 },
    { lat: 49.155871, lng: 20.292718 },
    { lat: 49.155805, lng: 20.292764 },
    { lat: 49.155772, lng: 20.292804 },
    { lat: 49.155748, lng: 20.292954 },
    { lat: 49.155687, lng: 20.293024 },
    { lat: 49.155665, lng: 20.293062 },
    { lat: 49.1557, lng: 20.293082 },
    { lat: 49.155685, lng: 20.293152 },
    { lat: 49.155714, lng: 20.293238 },
    { lat: 49.155718, lng: 20.293382 },
    { lat: 49.155677, lng: 20.293539 },
    { lat: 49.155619, lng: 20.293607 },
    { lat: 49.155539, lng: 20.293627 },
    { lat: 49.155509, lng: 20.293631 },
    { lat: 49.155452, lng: 20.293619 },
    { lat: 49.155391, lng: 20.29358 },
    { lat: 49.155321, lng: 20.293609 },
    { lat: 49.155183, lng: 20.29373 },
    { lat: 49.155153, lng: 20.29378 },
    { lat: 49.155131, lng: 20.293914 },
    { lat: 49.154989, lng: 20.294108 },
    { lat: 49.154894, lng: 20.294198 },
    { lat: 49.154781, lng: 20.294217 },
    { lat: 49.15474, lng: 20.294241 },
    { lat: 49.154723, lng: 20.294355 },
    { lat: 49.154602, lng: 20.294292 },
    { lat: 49.154501, lng: 20.294328 },
    { lat: 49.154457, lng: 20.294491 },
    { lat: 49.154436, lng: 20.294558 },
    { lat: 49.154325, lng: 20.294785 },
    { lat: 49.154237, lng: 20.294939 },
    { lat: 49.154191, lng: 20.295027 },
    { lat: 49.154201, lng: 20.295238 },
    { lat: 49.154187, lng: 20.295478 },
    { lat: 49.154231, lng: 20.29569 },
    { lat: 49.15425, lng: 20.295972 },
    { lat: 49.154233, lng: 20.296179 },
    { lat: 49.154118, lng: 20.296286 },
    { lat: 49.153815, lng: 20.296437 },
    { lat: 49.153778, lng: 20.296856 },
    { lat: 49.153763, lng: 20.29726 },
    { lat: 49.153712, lng: 20.297443 },
    { lat: 49.153475, lng: 20.297454 },
    { lat: 49.153303, lng: 20.297396 },
    { lat: 49.153258, lng: 20.297401 },
    { lat: 49.153239, lng: 20.297511 },
    { lat: 49.153258, lng: 20.297688 },
    { lat: 49.153243, lng: 20.297851 },
    { lat: 49.153232, lng: 20.297863 },
    { lat: 49.153141, lng: 20.29792 },
    { lat: 49.15311, lng: 20.298087 },
    { lat: 49.153162, lng: 20.29822 },
    { lat: 49.153248, lng: 20.298224 },
    { lat: 49.153242, lng: 20.298407 },
    { lat: 49.153173, lng: 20.298581 },
    { lat: 49.153162, lng: 20.298777 },
    { lat: 49.153079, lng: 20.298937 },
    { lat: 49.153061, lng: 20.299026 },
    { lat: 49.153096, lng: 20.299102 },
    { lat: 49.153237, lng: 20.299311 },
    { lat: 49.153169, lng: 20.299429 },
    { lat: 49.153049, lng: 20.299657 },
    { lat: 49.153025, lng: 20.299661 },
    { lat: 49.152972, lng: 20.299668 },
    { lat: 49.152897, lng: 20.299688 },
    { lat: 49.152814, lng: 20.299853 },
    { lat: 49.15291, lng: 20.300056 },
    { lat: 49.152906, lng: 20.300234 },
    { lat: 49.152696, lng: 20.300311 },
    { lat: 49.152614, lng: 20.300462 },
    { lat: 49.152612, lng: 20.300641 },
    { lat: 49.152521, lng: 20.300822 },
    { lat: 49.152509, lng: 20.300904 },
    { lat: 49.152512, lng: 20.301032 },
    { lat: 49.152476, lng: 20.301172 },
    { lat: 49.152279, lng: 20.301452 },
    { lat: 49.152234, lng: 20.30144 },
    { lat: 49.152222, lng: 20.301404 },
    { lat: 49.152224, lng: 20.301386 },
    { lat: 49.15224, lng: 20.301369 },
    { lat: 49.152266, lng: 20.301329 },
    { lat: 49.152257, lng: 20.301261 },
    { lat: 49.152228, lng: 20.301237 },
    { lat: 49.152207, lng: 20.301184 },
    { lat: 49.15219, lng: 20.301188 },
    { lat: 49.152148, lng: 20.301236 },
    { lat: 49.152059, lng: 20.301297 },
    { lat: 49.152041, lng: 20.301358 },
    { lat: 49.15204, lng: 20.301443 },
    { lat: 49.152038, lng: 20.301466 },
    { lat: 49.15208, lng: 20.301596 },
    { lat: 49.152081, lng: 20.301715 },
    { lat: 49.151994, lng: 20.301879 },
    { lat: 49.151979, lng: 20.301872 },
    { lat: 49.151965, lng: 20.30182 },
    { lat: 49.151953, lng: 20.30183 },
    { lat: 49.151899, lng: 20.301942 },
    { lat: 49.15188, lng: 20.301964 },
    { lat: 49.151861, lng: 20.302022 },
    { lat: 49.151828, lng: 20.302034 },
    { lat: 49.151728, lng: 20.30219 },
    { lat: 49.151609, lng: 20.302234 },
    { lat: 49.151507, lng: 20.302405 },
    { lat: 49.151503, lng: 20.302657 },
    { lat: 49.151471, lng: 20.303345 },
    { lat: 49.151441, lng: 20.30346 },
    { lat: 49.151357, lng: 20.303514 },
    { lat: 49.151244, lng: 20.303363 },
    { lat: 49.151227, lng: 20.303352 },
    { lat: 49.151092, lng: 20.303429 },
    { lat: 49.150932, lng: 20.303587 },
    { lat: 49.150853, lng: 20.303745 },
    { lat: 49.150856, lng: 20.303924 },
    { lat: 49.150962, lng: 20.304061 },
    { lat: 49.150966, lng: 20.304082 },
    { lat: 49.15094, lng: 20.30425 },
    { lat: 49.150892, lng: 20.304393 },
    { lat: 49.150822, lng: 20.304508 },
    { lat: 49.150734, lng: 20.304601 },
    { lat: 49.150712, lng: 20.304675 },
    { lat: 49.1507, lng: 20.304719 },
    { lat: 49.150703, lng: 20.304758 },
    { lat: 49.150715, lng: 20.304918 },
    { lat: 49.150733, lng: 20.305152 },
    { lat: 49.150833, lng: 20.30558 },
    { lat: 49.150904, lng: 20.305702 },
    { lat: 49.150958, lng: 20.305866 },
    { lat: 49.150981, lng: 20.306069 },
    { lat: 49.15095, lng: 20.306246 },
    { lat: 49.150852, lng: 20.306357 },
    { lat: 49.150859, lng: 20.30642 },
    { lat: 49.150836, lng: 20.306581 },
    { lat: 49.150759, lng: 20.306675 },
    { lat: 49.15066, lng: 20.306732 },
    { lat: 49.150597, lng: 20.306871 },
    { lat: 49.150546, lng: 20.306919 },
    { lat: 49.150527, lng: 20.307008 },
    { lat: 49.15053, lng: 20.307173 },
    { lat: 49.150615, lng: 20.307216 },
    { lat: 49.150621, lng: 20.307231 },
    { lat: 49.15063, lng: 20.307566 },
    { lat: 49.150611, lng: 20.307653 },
    { lat: 49.150561, lng: 20.307746 },
    { lat: 49.150484, lng: 20.307815 },
    { lat: 49.150448, lng: 20.307858 },
    { lat: 49.150402, lng: 20.307937 },
    { lat: 49.150379, lng: 20.308139 },
    { lat: 49.150317, lng: 20.308322 },
    { lat: 49.150271, lng: 20.308433 },
    { lat: 49.150158, lng: 20.308623 },
    { lat: 49.150258, lng: 20.308968 },
    { lat: 49.15027, lng: 20.309036 },
    { lat: 49.150272, lng: 20.309065 },
    { lat: 49.150272, lng: 20.309157 },
    { lat: 49.150221, lng: 20.309352 },
    { lat: 49.150194, lng: 20.309528 },
    { lat: 49.150208, lng: 20.309691 },
    { lat: 49.150283, lng: 20.309722 },
    { lat: 49.150297, lng: 20.309741 },
    { lat: 49.150282, lng: 20.309917 },
    { lat: 49.15018, lng: 20.310081 },
    { lat: 49.150168, lng: 20.310183 },
    { lat: 49.150212, lng: 20.310271 },
    { lat: 49.150308, lng: 20.310322 },
    { lat: 49.150319, lng: 20.31034 },
    { lat: 49.150322, lng: 20.310468 },
    { lat: 49.150161, lng: 20.310712 },
    { lat: 49.150058, lng: 20.310749 },
    { lat: 49.149966, lng: 20.310717 },
    { lat: 49.149757, lng: 20.310869 },
    { lat: 49.149702, lng: 20.310936 },
    { lat: 49.149634, lng: 20.311035 },
    { lat: 49.149627, lng: 20.311136 },
    { lat: 49.149732, lng: 20.311309 },
    { lat: 49.149771, lng: 20.311477 },
    { lat: 49.149789, lng: 20.311788 },
    { lat: 49.149735, lng: 20.312076 },
    { lat: 49.149736, lng: 20.312187 },
    { lat: 49.14975, lng: 20.312321 },
    { lat: 49.149727, lng: 20.312521 },
    { lat: 49.149713, lng: 20.312829 },
    { lat: 49.14967, lng: 20.31291 },
    { lat: 49.14936, lng: 20.312925 },
    { lat: 49.149346, lng: 20.313154 },
    { lat: 49.149259, lng: 20.31337 },
    { lat: 49.149242, lng: 20.313716 },
    { lat: 49.149153, lng: 20.314173 },
    { lat: 49.149091, lng: 20.314316 },
    { lat: 49.149029, lng: 20.314447 },
    { lat: 49.148933, lng: 20.314578 },
    { lat: 49.148623, lng: 20.314916 },
    { lat: 49.148416, lng: 20.315048 },
    { lat: 49.148323, lng: 20.315159 },
    { lat: 49.148162, lng: 20.315584 },
    { lat: 49.148208, lng: 20.315815 },
    { lat: 49.148171, lng: 20.315857 },
    { lat: 49.148061, lng: 20.315913 },
    { lat: 49.147877, lng: 20.316256 },
    { lat: 49.147608, lng: 20.316638 },
    { lat: 49.147605, lng: 20.316649 },
    { lat: 49.147629, lng: 20.316711 },
    { lat: 49.147611, lng: 20.316717 },
    { lat: 49.147584, lng: 20.316728 },
    { lat: 49.147476, lng: 20.317112 },
    { lat: 49.147394, lng: 20.317052 },
    { lat: 49.14708, lng: 20.317864 },
    { lat: 49.146785, lng: 20.31835 },
    { lat: 49.146453, lng: 20.3184 },
    { lat: 49.146346, lng: 20.318617 },
    { lat: 49.146245, lng: 20.318463 },
    { lat: 49.145838, lng: 20.318813 },
    { lat: 49.145439, lng: 20.319308 },
    { lat: 49.145136, lng: 20.319014 },
    { lat: 49.144987, lng: 20.319234 },
    { lat: 49.144378, lng: 20.318628 },
    { lat: 49.14365, lng: 20.317866 },
    { lat: 49.14312, lng: 20.318985 },
    { lat: 49.142524, lng: 20.320082 },
    { lat: 49.143168, lng: 20.320934 },
    { lat: 49.143758, lng: 20.321782 },
    { lat: 49.143332, lng: 20.322814 },
    { lat: 49.14369, lng: 20.323464 },
    { lat: 49.144198, lng: 20.322403 },
    { lat: 49.144662, lng: 20.323056 },
    { lat: 49.144843, lng: 20.323275 },
    { lat: 49.144962, lng: 20.323361 },
    { lat: 49.145014, lng: 20.323399 },
    { lat: 49.145038, lng: 20.323418 },
    { lat: 49.144947, lng: 20.323666 },
    { lat: 49.144909, lng: 20.323772 },
    { lat: 49.144792, lng: 20.324073 },
    { lat: 49.144733, lng: 20.32435 },
    { lat: 49.144692, lng: 20.324702 },
    { lat: 49.144704, lng: 20.324754 },
    { lat: 49.144953, lng: 20.325085 },
    { lat: 49.144995, lng: 20.32531 },
    { lat: 49.145002, lng: 20.326119 },
    { lat: 49.144904, lng: 20.326643 },
    { lat: 49.144702, lng: 20.327026 },
    { lat: 49.144585, lng: 20.327206 },
    { lat: 49.144624, lng: 20.327246 },
    { lat: 49.145079, lng: 20.327721 },
    { lat: 49.14575, lng: 20.328477 },
    { lat: 49.145972, lng: 20.328728 },
    { lat: 49.146012, lng: 20.328772 },
    { lat: 49.146548, lng: 20.329658 },
    { lat: 49.147204, lng: 20.330742 },
    { lat: 49.147252, lng: 20.330821 },
    { lat: 49.147342, lng: 20.330971 },
    { lat: 49.147389, lng: 20.331048 },
    { lat: 49.147544, lng: 20.331305 },
    { lat: 49.147633, lng: 20.331452 },
    { lat: 49.148134, lng: 20.331094 },
    { lat: 49.148733, lng: 20.330813 },
    { lat: 49.14935, lng: 20.330478 },
    { lat: 49.149483, lng: 20.329255 },
    { lat: 49.149508, lng: 20.329031 },
    { lat: 49.149675, lng: 20.327874 },
    { lat: 49.1497, lng: 20.327682 },
    { lat: 49.149877, lng: 20.326444 },
    { lat: 49.150695, lng: 20.324108 },
    { lat: 49.15102, lng: 20.323099 },
    { lat: 49.152365, lng: 20.321735 },
    { lat: 49.152484, lng: 20.320994 },
    { lat: 49.152592, lng: 20.3212 },
    { lat: 49.152828, lng: 20.321619 },
    { lat: 49.153599, lng: 20.323013 },
    { lat: 49.154277, lng: 20.324247 },
    { lat: 49.15505, lng: 20.32565 },
    { lat: 49.155723, lng: 20.326869 },
    { lat: 49.156425, lng: 20.328139 },
    { lat: 49.157045, lng: 20.329258 },
    { lat: 49.158009, lng: 20.327929 },
    { lat: 49.159122, lng: 20.326404 },
    { lat: 49.159229, lng: 20.326257 },
    { lat: 49.160263, lng: 20.324839 },
    { lat: 49.161055, lng: 20.323754 },
    { lat: 49.161755, lng: 20.322801 },
    { lat: 49.16191, lng: 20.322456 },
    { lat: 49.16193, lng: 20.322518 },
    { lat: 49.162741, lng: 20.324344 },
    { lat: 49.163331, lng: 20.32582 },
    { lat: 49.163692, lng: 20.326933 },
    { lat: 49.164305, lng: 20.327915 },
    { lat: 49.164323, lng: 20.327942 },
    { lat: 49.16446, lng: 20.328163 },
    { lat: 49.164382, lng: 20.32831 },
    { lat: 49.164406, lng: 20.328346 },
    { lat: 49.164439, lng: 20.328398 },
    { lat: 49.164468, lng: 20.32844 },
    { lat: 49.164482, lng: 20.328462 },
    { lat: 49.164529, lng: 20.328533 },
    { lat: 49.16487, lng: 20.328939 },
    { lat: 49.16516, lng: 20.329439 },
    { lat: 49.165935, lng: 20.330346 },
    { lat: 49.165967, lng: 20.330393 },
    { lat: 49.165999, lng: 20.33044 },
    { lat: 49.166728, lng: 20.331509 },
    { lat: 49.167058, lng: 20.332013 },
    { lat: 49.166835, lng: 20.332831 },
    { lat: 49.166562, lng: 20.333458 },
    { lat: 49.166408, lng: 20.33373 },
    { lat: 49.165964, lng: 20.334988 },
    { lat: 49.165592, lng: 20.335645 },
    { lat: 49.165438, lng: 20.336007 },
    { lat: 49.164806, lng: 20.336826 },
    { lat: 49.164615, lng: 20.337073 },
    { lat: 49.164541, lng: 20.33717 },
    { lat: 49.164451, lng: 20.337406 },
    { lat: 49.164271, lng: 20.337835 },
    { lat: 49.164069, lng: 20.338375 },
    { lat: 49.163957, lng: 20.338587 },
    { lat: 49.163823, lng: 20.338925 },
    { lat: 49.162762, lng: 20.340311 },
    { lat: 49.162256, lng: 20.341174 },
    { lat: 49.161075, lng: 20.343161 },
    { lat: 49.161884, lng: 20.343266 },
    { lat: 49.162193, lng: 20.343104 },
    { lat: 49.163256, lng: 20.342351 },
    { lat: 49.163508, lng: 20.342363 },
    { lat: 49.163851, lng: 20.342379 },
    { lat: 49.16364, lng: 20.342568 },
    { lat: 49.163154, lng: 20.343292 },
    { lat: 49.163036, lng: 20.343472 },
    { lat: 49.162172, lng: 20.344907 },
    { lat: 49.161874, lng: 20.345579 },
    { lat: 49.160665, lng: 20.347619 },
    { lat: 49.161489, lng: 20.348639 },
    { lat: 49.161979, lng: 20.349266 },
    { lat: 49.16266, lng: 20.350171 },
    { lat: 49.162482, lng: 20.350477 },
    { lat: 49.162825, lng: 20.351068 },
    { lat: 49.163076, lng: 20.351504 },
    { lat: 49.163449, lng: 20.352155 },
    { lat: 49.163752, lng: 20.352492 },
    { lat: 49.163832, lng: 20.352643 },
    { lat: 49.163839, lng: 20.352666 },
    { lat: 49.163973, lng: 20.352686 },
    { lat: 49.164089, lng: 20.352481 },
    { lat: 49.164085, lng: 20.352391 },
    { lat: 49.164236, lng: 20.352266 },
    { lat: 49.164468, lng: 20.351844 },
    { lat: 49.164526, lng: 20.351718 },
    { lat: 49.164582, lng: 20.351577 },
    { lat: 49.164731, lng: 20.351377 },
    { lat: 49.164778, lng: 20.351221 },
    { lat: 49.16507, lng: 20.35081 },
    { lat: 49.16506, lng: 20.350598 },
    { lat: 49.165421, lng: 20.350171 },
    { lat: 49.165432, lng: 20.350183 },
    { lat: 49.165465, lng: 20.350195 },
    { lat: 49.165817, lng: 20.350555 },
    { lat: 49.166496, lng: 20.351277 },
    { lat: 49.166883, lng: 20.351195 },
    { lat: 49.167378, lng: 20.351684 },
    { lat: 49.167325, lng: 20.351816 },
    { lat: 49.16779, lng: 20.352459 },
    { lat: 49.167624, lng: 20.353008 },
    { lat: 49.16805, lng: 20.353436 },
    { lat: 49.167915, lng: 20.353735 },
    { lat: 49.168113, lng: 20.353847 },
    { lat: 49.167866, lng: 20.35441 },
    { lat: 49.1674, lng: 20.355471 },
    { lat: 49.167701, lng: 20.355742 },
    { lat: 49.168326, lng: 20.356305 },
    { lat: 49.169576, lng: 20.357428 },
    { lat: 49.169653, lng: 20.357498 },
    { lat: 49.170264, lng: 20.358049 },
    { lat: 49.17041, lng: 20.359236 },
    { lat: 49.171607, lng: 20.35963 },
    { lat: 49.171846, lng: 20.359823 },
    { lat: 49.172438, lng: 20.359951 },
    { lat: 49.173214, lng: 20.360008 },
    { lat: 49.173598, lng: 20.360052 },
    { lat: 49.173546, lng: 20.360349 },
    { lat: 49.173506, lng: 20.360588 },
    { lat: 49.173359, lng: 20.361374 },
    { lat: 49.173093, lng: 20.362672 },
    { lat: 49.172832, lng: 20.363985 },
    { lat: 49.172569, lng: 20.365296 },
    { lat: 49.17231, lng: 20.366605 },
    { lat: 49.172161, lng: 20.368038 },
    { lat: 49.1719, lng: 20.36867 },
    { lat: 49.171219, lng: 20.369945 },
    { lat: 49.171105, lng: 20.370208 },
    { lat: 49.171109, lng: 20.370399 },
    { lat: 49.170894, lng: 20.370955 },
    { lat: 49.170821, lng: 20.371201 },
    { lat: 49.170491, lng: 20.371655 },
    { lat: 49.170353, lng: 20.371725 },
    { lat: 49.170151, lng: 20.371754 },
    { lat: 49.169902, lng: 20.372029 },
    { lat: 49.169803, lng: 20.372361 },
    { lat: 49.169703, lng: 20.372635 },
    { lat: 49.169627, lng: 20.372805 },
    { lat: 49.169303, lng: 20.374014 },
    { lat: 49.169238, lng: 20.374239 },
    { lat: 49.169142, lng: 20.374386 },
    { lat: 49.16902, lng: 20.374611 },
    { lat: 49.168854, lng: 20.374891 },
    { lat: 49.168657, lng: 20.37529 },
    { lat: 49.168442, lng: 20.375731 },
    { lat: 49.168367, lng: 20.376084 },
    { lat: 49.168194, lng: 20.376666 },
    { lat: 49.168166, lng: 20.376798 },
    { lat: 49.168094, lng: 20.376966 },
    { lat: 49.168026, lng: 20.377033 },
    { lat: 49.168096, lng: 20.377179 },
    { lat: 49.168032, lng: 20.377464 },
    { lat: 49.167878, lng: 20.377846 },
    { lat: 49.167809, lng: 20.377916 },
    { lat: 49.167686, lng: 20.378222 },
    { lat: 49.167645, lng: 20.378325 },
    { lat: 49.167455, lng: 20.378589 },
    { lat: 49.167341, lng: 20.378802 },
    { lat: 49.1672, lng: 20.378991 },
    { lat: 49.167247, lng: 20.379079 },
    { lat: 49.16728, lng: 20.37914 },
    { lat: 49.167338, lng: 20.379247 },
    { lat: 49.167419, lng: 20.379033 },
    { lat: 49.16797, lng: 20.378586 },
    { lat: 49.168309, lng: 20.378084 },
    { lat: 49.168418, lng: 20.378016 },
    { lat: 49.168463, lng: 20.378068 },
    { lat: 49.1684941, lng: 20.378105 },
    { lat: 49.168569, lng: 20.378194 },
    { lat: 49.168782, lng: 20.378447 },
    { lat: 49.168853, lng: 20.378531 },
    { lat: 49.169547, lng: 20.379353 },
    { lat: 49.169577, lng: 20.379388 },
    { lat: 49.16959, lng: 20.379351 },
    { lat: 49.169614, lng: 20.379288 },
    { lat: 49.169662, lng: 20.379154 },
    { lat: 49.169926, lng: 20.37843 },
    { lat: 49.17017, lng: 20.377755 },
    { lat: 49.17062, lng: 20.376514 },
    { lat: 49.170633, lng: 20.376477 },
    { lat: 49.170674, lng: 20.376459 },
    { lat: 49.170728, lng: 20.376451 },
    { lat: 49.170995, lng: 20.376274 },
    { lat: 49.171006, lng: 20.37617 },
    { lat: 49.171119, lng: 20.37595 },
    { lat: 49.171145, lng: 20.375924 },
    { lat: 49.171171, lng: 20.375975 },
    { lat: 49.171229, lng: 20.376053 },
    { lat: 49.171298, lng: 20.376218 },
    { lat: 49.171361, lng: 20.376276 },
    { lat: 49.171362, lng: 20.376382 },
    { lat: 49.171386, lng: 20.37641 },
    { lat: 49.171417, lng: 20.376493 },
    { lat: 49.171417, lng: 20.376529 },
    { lat: 49.171385, lng: 20.376574 },
    { lat: 49.171387, lng: 20.376683 },
    { lat: 49.17137, lng: 20.37675 },
    { lat: 49.171395, lng: 20.376822 },
    { lat: 49.171403, lng: 20.376842 },
    { lat: 49.171331, lng: 20.377208 },
    { lat: 49.171294, lng: 20.377359 },
    { lat: 49.171219, lng: 20.377466 },
    { lat: 49.171182, lng: 20.377629 },
    { lat: 49.17116, lng: 20.377936 },
    { lat: 49.171078, lng: 20.378183 },
    { lat: 49.17104, lng: 20.378285 },
    { lat: 49.170886, lng: 20.378756 },
    { lat: 49.170868, lng: 20.378839 },
    { lat: 49.170863, lng: 20.37886 },
    { lat: 49.171035, lng: 20.379009 },
    { lat: 49.171055, lng: 20.379027 },
    { lat: 49.171074, lng: 20.379056 },
    { lat: 49.1711, lng: 20.379095 },
    { lat: 49.170685, lng: 20.38015 },
    { lat: 49.170603, lng: 20.380377 },
    { lat: 49.170613, lng: 20.380401 },
    { lat: 49.171022, lng: 20.381448 },
    { lat: 49.169881, lng: 20.383236 },
    { lat: 49.169745, lng: 20.383417 },
    { lat: 49.169771, lng: 20.384088 },
    { lat: 49.170222, lng: 20.384052 },
    { lat: 49.170198, lng: 20.384464 },
    { lat: 49.1702, lng: 20.384546 },
    { lat: 49.170216, lng: 20.385012 },
    { lat: 49.170931, lng: 20.384875 },
    { lat: 49.171827, lng: 20.384084 },
    { lat: 49.172686, lng: 20.38333 },
    { lat: 49.173565, lng: 20.382844 },
    { lat: 49.174148, lng: 20.381739 },
    { lat: 49.174691, lng: 20.380711 },
    { lat: 49.174052, lng: 20.380501 },
    { lat: 49.173505, lng: 20.379767 },
    { lat: 49.172903, lng: 20.379124 },
    { lat: 49.172078, lng: 20.378232 },
    { lat: 49.171843, lng: 20.377717 },
    { lat: 49.171802, lng: 20.377654 },
    { lat: 49.172488, lng: 20.376594 },
    { lat: 49.173069, lng: 20.375695 },
    { lat: 49.174166, lng: 20.374074 },
    { lat: 49.174829, lng: 20.373064 },
    { lat: 49.175614, lng: 20.371739 },
    { lat: 49.17596, lng: 20.371135 },
    { lat: 49.176235, lng: 20.370607 },
    { lat: 49.176527, lng: 20.369978 },
    { lat: 49.17712, lng: 20.368458 },
    { lat: 49.177485, lng: 20.367414 },
    { lat: 49.177538, lng: 20.367439 },
    { lat: 49.177548, lng: 20.367398 },
    { lat: 49.17755, lng: 20.367375 },
    { lat: 49.177671, lng: 20.367409 },
    { lat: 49.177919, lng: 20.367475 },
    { lat: 49.177975, lng: 20.367538 },
    { lat: 49.178072, lng: 20.367649 },
    { lat: 49.178151, lng: 20.367684 },
    { lat: 49.178218, lng: 20.367674 },
    { lat: 49.178246, lng: 20.367653 },
    { lat: 49.178336, lng: 20.367581 },
    { lat: 49.178377, lng: 20.367525 },
    { lat: 49.178516, lng: 20.367241 },
    { lat: 49.178827, lng: 20.366678 },
    { lat: 49.179074, lng: 20.366272 },
    { lat: 49.179551, lng: 20.365379 },
    { lat: 49.179916, lng: 20.364727 },
    { lat: 49.180235, lng: 20.364322 },
    { lat: 49.180436, lng: 20.364021 },
    { lat: 49.180841, lng: 20.363416 },
    { lat: 49.181272, lng: 20.362589 },
    { lat: 49.181429, lng: 20.362238 },
    { lat: 49.181534, lng: 20.362064 },
    { lat: 49.182093, lng: 20.361021 },
    { lat: 49.182407, lng: 20.36026 },
    { lat: 49.182802, lng: 20.359661 },
    { lat: 49.183358, lng: 20.35923 },
    { lat: 49.183473, lng: 20.359229 },
    { lat: 49.183712, lng: 20.35923 },
    { lat: 49.183848, lng: 20.359195 },
    { lat: 49.18399, lng: 20.359059 },
    { lat: 49.184207, lng: 20.358591 },
    { lat: 49.18433, lng: 20.358176 },
    { lat: 49.184359, lng: 20.358192 },
    { lat: 49.184827, lng: 20.357353 },
    { lat: 49.185434, lng: 20.356325 },
    { lat: 49.185682, lng: 20.355936 },
    { lat: 49.185704, lng: 20.357179 },
    { lat: 49.185678, lng: 20.357796 },
    { lat: 49.185724, lng: 20.357797 },
    { lat: 49.185705, lng: 20.358477 },
    { lat: 49.186243, lng: 20.358257 },
    { lat: 49.187253, lng: 20.358159 },
    { lat: 49.18826, lng: 20.358044 },
    { lat: 49.188751, lng: 20.357972 },
    { lat: 49.189317, lng: 20.357889 },
    { lat: 49.189785, lng: 20.357826 },
    { lat: 49.190319, lng: 20.357753 },
    { lat: 49.191326, lng: 20.35761 },
    { lat: 49.19237, lng: 20.357476 },
    { lat: 49.193035, lng: 20.357851 },
    { lat: 49.193403, lng: 20.358035 },
    { lat: 49.193545, lng: 20.358133 },
    { lat: 49.193663, lng: 20.358171 },
    { lat: 49.193732, lng: 20.358211 },
    { lat: 49.194164, lng: 20.358467 },
    { lat: 49.195617, lng: 20.359252 },
    { lat: 49.195684, lng: 20.359227 },
    { lat: 49.1957, lng: 20.359222 },
    { lat: 49.195705, lng: 20.35922 },
    { lat: 49.195717, lng: 20.359205 },
    { lat: 49.195701, lng: 20.358702 },
    { lat: 49.195533, lng: 20.358164 },
    { lat: 49.19542, lng: 20.357976 },
    { lat: 49.195452, lng: 20.357807 },
    { lat: 49.195599, lng: 20.357771 },
    { lat: 49.195722, lng: 20.357685 },
    { lat: 49.195702, lng: 20.357568 },
    { lat: 49.19583, lng: 20.356355 },
    { lat: 49.195691, lng: 20.356288 },
    { lat: 49.195727, lng: 20.35626 },
    { lat: 49.196025, lng: 20.356252 },
    { lat: 49.196105, lng: 20.355956 },
    { lat: 49.196051, lng: 20.355925 },
    { lat: 49.196196, lng: 20.355744 },
    { lat: 49.196261, lng: 20.355456 },
    { lat: 49.196287, lng: 20.355233 },
    { lat: 49.196127, lng: 20.355025 },
    { lat: 49.196047, lng: 20.354887 },
    { lat: 49.196111, lng: 20.354116 },
    { lat: 49.19598, lng: 20.353837 },
    { lat: 49.196068, lng: 20.353545 },
    { lat: 49.196134, lng: 20.353457 },
    { lat: 49.196195, lng: 20.353419 },
    { lat: 49.196246, lng: 20.353326 },
    { lat: 49.196307, lng: 20.353152 },
    { lat: 49.196352, lng: 20.352759 },
    { lat: 49.196302, lng: 20.352185 },
    { lat: 49.196333, lng: 20.352126 },
    { lat: 49.196387, lng: 20.352063 },
    { lat: 49.196415, lng: 20.351895 },
    { lat: 49.19658, lng: 20.351717 },
    { lat: 49.196648, lng: 20.351614 },
    { lat: 49.196807, lng: 20.351488 },
    { lat: 49.196967, lng: 20.351235 },
    { lat: 49.196991, lng: 20.35085 },
    { lat: 49.197025, lng: 20.350554 },
    { lat: 49.196915, lng: 20.350187 },
    { lat: 49.196928, lng: 20.350068 },
    { lat: 49.196943, lng: 20.350054 },
    { lat: 49.197035, lng: 20.350005 },
    { lat: 49.197086, lng: 20.349915 },
    { lat: 49.197179, lng: 20.349879 },
    { lat: 49.19725, lng: 20.349782 },
    { lat: 49.197213, lng: 20.349641 },
    { lat: 49.197164, lng: 20.349566 },
    { lat: 49.197079, lng: 20.349245 },
    { lat: 49.197056, lng: 20.349078 },
    { lat: 49.196962, lng: 20.348835 },
    { lat: 49.197158, lng: 20.348241 },
    { lat: 49.197213, lng: 20.348102 },
    { lat: 49.197322, lng: 20.348087 },
    { lat: 49.197327, lng: 20.348079 },
    { lat: 49.197341, lng: 20.348046 },
    { lat: 49.197402, lng: 20.347836 },
    { lat: 49.197469, lng: 20.347781 },
    { lat: 49.19779, lng: 20.347432 },
    { lat: 49.19791, lng: 20.347388 },
    { lat: 49.198032, lng: 20.347161 },
    { lat: 49.198276, lng: 20.347175 },
    { lat: 49.198344, lng: 20.346896 },
    { lat: 49.198389, lng: 20.346809 },
    { lat: 49.198506, lng: 20.34642 },
    { lat: 49.198437, lng: 20.346183 },
    { lat: 49.198355, lng: 20.346031 },
    { lat: 49.198333, lng: 20.345938 },
    { lat: 49.198403, lng: 20.345546 },
    { lat: 49.198614, lng: 20.345486 },
    { lat: 49.198656, lng: 20.345364 },
    { lat: 49.198502, lng: 20.344956 },
    { lat: 49.198535, lng: 20.344891 },
    { lat: 49.198588, lng: 20.344848 },
    { lat: 49.198655, lng: 20.344808 },
    { lat: 49.198606, lng: 20.34452 },
    { lat: 49.1986, lng: 20.344374 },
    { lat: 49.198741, lng: 20.343847 },
    { lat: 49.198717, lng: 20.343598 },
    { lat: 49.198812, lng: 20.343534 },
    { lat: 49.198732, lng: 20.343375 },
    { lat: 49.198781, lng: 20.343285 },
    { lat: 49.198787, lng: 20.34307 },
    { lat: 49.198736, lng: 20.342935 },
    { lat: 49.198824, lng: 20.342851 },
    { lat: 49.198921, lng: 20.342576 },
    { lat: 49.198915, lng: 20.342288 },
    { lat: 49.198943, lng: 20.342302 },
    { lat: 49.199041, lng: 20.342294 },
    { lat: 49.198863, lng: 20.341539 },
    { lat: 49.198961, lng: 20.341388 },
    { lat: 49.199086, lng: 20.340948 },
    { lat: 49.199103, lng: 20.340713 },
    { lat: 49.199159, lng: 20.340574 },
    { lat: 49.199531, lng: 20.340229 },
    { lat: 49.199644, lng: 20.340203 },
    { lat: 49.199752, lng: 20.340161 },
    { lat: 49.19982, lng: 20.340063 },
    { lat: 49.199955, lng: 20.339919 },
    { lat: 49.200106, lng: 20.339797 },
    { lat: 49.200196, lng: 20.339735 },
    { lat: 49.200284, lng: 20.339692 },
    { lat: 49.20042, lng: 20.339747 },
    { lat: 49.200471, lng: 20.339646 },
    { lat: 49.200789, lng: 20.339573 },
    { lat: 49.200833, lng: 20.339599 },
    { lat: 49.201544, lng: 20.340015 },
    { lat: 49.202195, lng: 20.340414 },
    { lat: 49.202294, lng: 20.340476 },
    { lat: 49.202371, lng: 20.34286 },
    { lat: 49.202414, lng: 20.345224 },
    { lat: 49.202343, lng: 20.347365 },
    { lat: 49.201778, lng: 20.349347 },
    { lat: 49.20117, lng: 20.351671 },
    { lat: 49.200803, lng: 20.353295 },
    { lat: 49.200798, lng: 20.354399 },
    { lat: 49.200873, lng: 20.355601 },
    { lat: 49.200898, lng: 20.3565 },
    { lat: 49.200997, lng: 20.358704 },
    { lat: 49.20089, lng: 20.360563 },
    { lat: 49.200937, lng: 20.360627 },
    { lat: 49.200874, lng: 20.361305 },
    { lat: 49.200769, lng: 20.36244 },
    { lat: 49.200941, lng: 20.367217 },
    { lat: 49.200952, lng: 20.367213 },
    { lat: 49.202008, lng: 20.366844 },
    { lat: 49.202562, lng: 20.366646 },
    { lat: 49.203069, lng: 20.366463 },
    { lat: 49.204275, lng: 20.36607 },
    { lat: 49.205214, lng: 20.365812 },
    { lat: 49.205349, lng: 20.365775 },
    { lat: 49.205764, lng: 20.365806 },
    { lat: 49.205779, lng: 20.365799 },
    { lat: 49.205798, lng: 20.36579 },
    { lat: 49.205833, lng: 20.365773 },
    { lat: 49.206473, lng: 20.365463 },
    { lat: 49.207841, lng: 20.365449 },
    { lat: 49.208094, lng: 20.365525 },
    { lat: 49.209244, lng: 20.365488 },
    { lat: 49.209282, lng: 20.365467 },
    { lat: 49.209303, lng: 20.365456 },
    { lat: 49.209341, lng: 20.365435 },
    { lat: 49.210429, lng: 20.365422 },
    { lat: 49.211468, lng: 20.36543 },
    { lat: 49.211479, lng: 20.365429 },
    { lat: 49.211536, lng: 20.36543 },
    { lat: 49.211562, lng: 20.365428 },
    { lat: 49.211601, lng: 20.365424 },
    { lat: 49.211619, lng: 20.365408 },
    { lat: 49.211852, lng: 20.365414 },
    { lat: 49.211899, lng: 20.365415 },
    { lat: 49.212025, lng: 20.366919 },
    { lat: 49.212104, lng: 20.367661 },
    { lat: 49.212172, lng: 20.367912 },
    { lat: 49.212101, lng: 20.36802 },
    { lat: 49.212098, lng: 20.368109 },
    { lat: 49.212086, lng: 20.368586 },
    { lat: 49.21206, lng: 20.369291 },
    { lat: 49.212096, lng: 20.369437 },
    { lat: 49.212138, lng: 20.369611 },
    { lat: 49.212307, lng: 20.369726 },
    { lat: 49.212414, lng: 20.369737 },
    { lat: 49.212932, lng: 20.369503 },
    { lat: 49.213557, lng: 20.369334 },
    { lat: 49.213818, lng: 20.369314 },
    { lat: 49.214154, lng: 20.369169 },
    { lat: 49.214406, lng: 20.369135 },
    { lat: 49.214605, lng: 20.369165 },
    { lat: 49.214729, lng: 20.369184 },
    { lat: 49.214935, lng: 20.369215 },
    { lat: 49.21519, lng: 20.369129 },
    { lat: 49.21564, lng: 20.368986 },
    { lat: 49.21626, lng: 20.368709 },
    { lat: 49.216552, lng: 20.368578 },
    { lat: 49.216752, lng: 20.368522 },
    { lat: 49.216826, lng: 20.368502 },
    { lat: 49.21717, lng: 20.368406 },
    { lat: 49.217275, lng: 20.368377 },
    { lat: 49.217439, lng: 20.368332 },
    { lat: 49.21772, lng: 20.368426 },
    { lat: 49.217805, lng: 20.368455 },
    { lat: 49.217912, lng: 20.368524 },
    { lat: 49.218135, lng: 20.368675 },
    { lat: 49.218165, lng: 20.368695 },
    { lat: 49.21833, lng: 20.368807 },
    { lat: 49.218366, lng: 20.368789 },
    { lat: 49.21843, lng: 20.368756 },
    { lat: 49.218487, lng: 20.368728 },
    { lat: 49.218806, lng: 20.368574 },
    { lat: 49.218955, lng: 20.368408 },
    { lat: 49.219834, lng: 20.36723 },
    { lat: 49.220328, lng: 20.366325 },
    { lat: 49.220437, lng: 20.366053 },
    { lat: 49.220585, lng: 20.365383 },
    { lat: 49.220721, lng: 20.365036 },
    { lat: 49.220828, lng: 20.364842 },
    { lat: 49.220984, lng: 20.364695 },
    { lat: 49.221758, lng: 20.364098 },
    { lat: 49.221924, lng: 20.363923 },
    { lat: 49.221959, lng: 20.363887 },
    { lat: 49.222411, lng: 20.363825 },
    { lat: 49.222547, lng: 20.363766 },
    { lat: 49.222701, lng: 20.36364 },
    { lat: 49.222806, lng: 20.363444 },
    { lat: 49.222869, lng: 20.363247 },
    { lat: 49.223092, lng: 20.362286 },
    { lat: 49.223189, lng: 20.362065 },
    { lat: 49.223695, lng: 20.360611 },
    { lat: 49.223767, lng: 20.360313 },
    { lat: 49.224169, lng: 20.357663 },
    { lat: 49.224202, lng: 20.35737 },
    { lat: 49.224205, lng: 20.35419 },
    { lat: 49.224244, lng: 20.353783 },
    { lat: 49.224349, lng: 20.35348 },
    { lat: 49.224413, lng: 20.353289 },
    { lat: 49.224494, lng: 20.353481 },
    { lat: 49.224514, lng: 20.353528 },
    { lat: 49.224913, lng: 20.352642 },
    { lat: 49.225085, lng: 20.352547 },
    { lat: 49.225203, lng: 20.352513 },
    { lat: 49.225261, lng: 20.352499 },
    { lat: 49.225235, lng: 20.351972 },
    { lat: 49.22519, lng: 20.350963 },
    { lat: 49.225268, lng: 20.350559 },
    { lat: 49.225196, lng: 20.350124 },
    { lat: 49.225005, lng: 20.349544 },
    { lat: 49.224978, lng: 20.349205 },
    { lat: 49.225007, lng: 20.34886 },
    { lat: 49.225038, lng: 20.348761 },
    { lat: 49.225089, lng: 20.348641 },
    { lat: 49.225119, lng: 20.347886 },
    { lat: 49.225044, lng: 20.347607 },
    { lat: 49.224894, lng: 20.347327 },
    { lat: 49.224846, lng: 20.347078 },
    { lat: 49.224835, lng: 20.346842 },
    { lat: 49.225039, lng: 20.346354 },
    { lat: 49.225143, lng: 20.346002 },
    { lat: 49.22537, lng: 20.346224 },
    { lat: 49.225405, lng: 20.346034 },
    { lat: 49.225525, lng: 20.345308 },
    { lat: 49.225712, lng: 20.344925 },
    { lat: 49.226188, lng: 20.344678 },
    { lat: 49.226742, lng: 20.344434 },
    { lat: 49.22678, lng: 20.344231 },
    { lat: 49.227108, lng: 20.344046 },
    { lat: 49.227143, lng: 20.343841 },
    { lat: 49.227106, lng: 20.343731 },
    { lat: 49.227078, lng: 20.343635 },
    { lat: 49.226911, lng: 20.34335 },
    { lat: 49.226175, lng: 20.342632 },
    { lat: 49.226096, lng: 20.342552 },
    { lat: 49.226048, lng: 20.342499 },
    { lat: 49.22605, lng: 20.342258 },
    { lat: 49.226079, lng: 20.342084 },
    { lat: 49.226167, lng: 20.34187 },
    { lat: 49.226285, lng: 20.341798 },
    { lat: 49.226413, lng: 20.341622 },
    { lat: 49.226517, lng: 20.341567 },
    { lat: 49.226621, lng: 20.341559 },
    { lat: 49.227047, lng: 20.341113 },
    { lat: 49.227087, lng: 20.340734 },
    { lat: 49.227294, lng: 20.340159 },
    { lat: 49.227685, lng: 20.339624 },
    { lat: 49.227807, lng: 20.339363 },
    { lat: 49.227871, lng: 20.339057 },
    { lat: 49.227848, lng: 20.338963 },
    { lat: 49.227727, lng: 20.338765 },
    { lat: 49.227347, lng: 20.338799 },
    { lat: 49.226991, lng: 20.339052 },
    { lat: 49.226683, lng: 20.339061 },
    { lat: 49.226491, lng: 20.338961 },
    { lat: 49.226122, lng: 20.338685 },
    { lat: 49.226044, lng: 20.338527 },
    { lat: 49.225989, lng: 20.338327 },
    { lat: 49.225981, lng: 20.337851 },
    { lat: 49.226054, lng: 20.33752 },
    { lat: 49.226185, lng: 20.337061 },
    { lat: 49.226581, lng: 20.336439 },
    { lat: 49.226735, lng: 20.336219 },
    { lat: 49.226969, lng: 20.335974 },
    { lat: 49.22706, lng: 20.335869 },
    { lat: 49.227517, lng: 20.335363 },
    { lat: 49.227837, lng: 20.334752 },
    { lat: 49.228083, lng: 20.334268 },
    { lat: 49.228341, lng: 20.334071 },
    { lat: 49.228604, lng: 20.334008 },
    { lat: 49.228971, lng: 20.33389 },
    { lat: 49.229099, lng: 20.333635 },
    { lat: 49.229241, lng: 20.33328 },
    { lat: 49.229391, lng: 20.332756 },
    { lat: 49.229441, lng: 20.332288 },
    { lat: 49.229322, lng: 20.331981 },
    { lat: 49.228925, lng: 20.331412 },
    { lat: 49.228833, lng: 20.331221 },
    { lat: 49.228501, lng: 20.330581 },
    { lat: 49.228324, lng: 20.330092 },
    { lat: 49.228351, lng: 20.330043 },
    { lat: 49.2286, lng: 20.329739 },
    { lat: 49.228875, lng: 20.329571 },
    { lat: 49.229114, lng: 20.329425 },
    { lat: 49.229368, lng: 20.329112 },
    { lat: 49.229359, lng: 20.329004 },
    { lat: 49.229436, lng: 20.329037 },
    { lat: 49.229607, lng: 20.329113 },
    { lat: 49.230011, lng: 20.328506 },
    { lat: 49.230229, lng: 20.328021 },
    { lat: 49.2306, lng: 20.327504 },
    { lat: 49.230761, lng: 20.327284 },
    { lat: 49.230864, lng: 20.328086 },
    { lat: 49.230436, lng: 20.329075 },
    { lat: 49.230526, lng: 20.329039 },
    { lat: 49.230576, lng: 20.32902 },
    { lat: 49.231072, lng: 20.328928 },
    { lat: 49.231631, lng: 20.329217 },
    { lat: 49.232005, lng: 20.329186 },
    { lat: 49.232945, lng: 20.328599 },
    { lat: 49.233531, lng: 20.328793 },
    { lat: 49.23403, lng: 20.328896 },
    { lat: 49.234975, lng: 20.329355 },
    { lat: 49.235512, lng: 20.329452 },
    { lat: 49.235919, lng: 20.329661 },
    { lat: 49.236911, lng: 20.329494 },
    { lat: 49.237753, lng: 20.329383 },
    { lat: 49.238191, lng: 20.329209 },
    { lat: 49.238942, lng: 20.328953 },
    { lat: 49.239689, lng: 20.328475 },
    { lat: 49.240195, lng: 20.327468 },
    { lat: 49.240237, lng: 20.32728 },
    { lat: 49.24093, lng: 20.326818 },
    { lat: 49.241296, lng: 20.326774 },
    { lat: 49.242425, lng: 20.326451 },
    { lat: 49.242618, lng: 20.326306 },
    { lat: 49.242904, lng: 20.326119 },
    { lat: 49.243564, lng: 20.326448 },
    { lat: 49.24391, lng: 20.326528 },
    { lat: 49.244728, lng: 20.325916 },
    { lat: 49.245604, lng: 20.324463 },
    { lat: 49.246152, lng: 20.324098 },
    { lat: 49.247056, lng: 20.323635 },
    { lat: 49.247825, lng: 20.323232 },
    { lat: 49.248664, lng: 20.322797 },
    { lat: 49.249641, lng: 20.32216 },
    { lat: 49.250996, lng: 20.322296 },
    { lat: 49.252462, lng: 20.322207 },
    { lat: 49.252666, lng: 20.322165 },
    { lat: 49.25312, lng: 20.322088 },
    { lat: 49.254845, lng: 20.322431 },
    { lat: 49.255347, lng: 20.322628 },
    { lat: 49.256551, lng: 20.323099 },
    { lat: 49.257533, lng: 20.323324 },
    { lat: 49.257769, lng: 20.322964 },
    { lat: 49.258155, lng: 20.323035 },
    { lat: 49.258231, lng: 20.323098 },
    { lat: 49.2586, lng: 20.322906 },
    { lat: 49.259392, lng: 20.322828 },
    { lat: 49.259792, lng: 20.321993 },
    { lat: 49.260134, lng: 20.321342 },
    { lat: 49.260293, lng: 20.320314 },
    { lat: 49.260403, lng: 20.319823 },
    { lat: 49.261462, lng: 20.31793 },
    { lat: 49.261783, lng: 20.317604 },
    { lat: 49.261963, lng: 20.316942 },
    { lat: 49.262475, lng: 20.316579 },
    { lat: 49.262639, lng: 20.316546 },
    { lat: 49.263052, lng: 20.316464 },
    { lat: 49.263974, lng: 20.317105 },
    { lat: 49.266098, lng: 20.317078 },
    { lat: 49.267942, lng: 20.318404 },
    { lat: 49.26858, lng: 20.319182 },
    { lat: 49.269485, lng: 20.318897 },
    { lat: 49.271399, lng: 20.318872 },
    { lat: 49.272813, lng: 20.31931 },
    { lat: 49.2745, lng: 20.320638 },
    { lat: 49.276112, lng: 20.321125 },
    { lat: 49.276396, lng: 20.321174 },
    { lat: 49.277592, lng: 20.319885 },
    { lat: 49.278322, lng: 20.319752 },
    { lat: 49.278398, lng: 20.319824 },
    { lat: 49.279481, lng: 20.319742 },
    { lat: 49.281253, lng: 20.32013 },
    { lat: 49.281356, lng: 20.320152 },
    { lat: 49.281532, lng: 20.32013 },
    { lat: 49.282924, lng: 20.319999 },
    { lat: 49.283455, lng: 20.319752 },
    { lat: 49.283766, lng: 20.319367 },
    { lat: 49.283894, lng: 20.319123 },
    { lat: 49.28418, lng: 20.318606 },
    { lat: 49.284391, lng: 20.318197 },
    { lat: 49.285362, lng: 20.316407 },
    { lat: 49.285382, lng: 20.316382 },
    { lat: 49.286091, lng: 20.315058 },
    { lat: 49.285848, lng: 20.314746 },
    { lat: 49.28542, lng: 20.313612 },
    { lat: 49.285172, lng: 20.31313 },
    { lat: 49.285037, lng: 20.312447 },
    { lat: 49.284923, lng: 20.311931 },
    { lat: 49.2834687, lng: 20.3119349 },
    { lat: 49.2834126, lng: 20.3130186 },
    { lat: 49.2834361, lng: 20.3141829 },
    { lat: 49.2831572, lng: 20.3142503 },
    { lat: 49.2829609, lng: 20.3141814 },
    { lat: 49.2826643, lng: 20.3141938 },
    { lat: 49.2815593, lng: 20.3146794 },
    { lat: 49.2810665, lng: 20.3146863 },
    { lat: 49.2805365, lng: 20.315014 },
    { lat: 49.2800616, lng: 20.3148206 },
    { lat: 49.2793507, lng: 20.3142277 },
    { lat: 49.2777847, lng: 20.3139684 },
    { lat: 49.2770676, lng: 20.3137096 },
    { lat: 49.2767743, lng: 20.3133131 },
    { lat: 49.2765345, lng: 20.3130848 },
    { lat: 49.2762007, lng: 20.3130973 },
    { lat: 49.2761223, lng: 20.3131703 },
    { lat: 49.2759814, lng: 20.3131315 },
    { lat: 49.2758867, lng: 20.3130131 },
    { lat: 49.2754035, lng: 20.3129525 },
    { lat: 49.2752595, lng: 20.3128656 },
    { lat: 49.274862, lng: 20.3128662 },
    { lat: 49.2746451, lng: 20.3125543 },
    { lat: 49.2745396, lng: 20.312504 },
    { lat: 49.2743313, lng: 20.3122426 },
    { lat: 49.2741306, lng: 20.3122074 },
    { lat: 49.2737133, lng: 20.3119292 },
    { lat: 49.2735326, lng: 20.3115319 },
    { lat: 49.2733294, lng: 20.3112315 },
    { lat: 49.2726186, lng: 20.3106975 },
    { lat: 49.2724353, lng: 20.310471 },
    { lat: 49.2722678, lng: 20.3104475 },
    { lat: 49.2717617, lng: 20.3099354 },
    { lat: 49.271566, lng: 20.3095666 },
    { lat: 49.271163, lng: 20.3094807 },
    { lat: 49.2707227, lng: 20.3088708 },
    { lat: 49.2704045, lng: 20.3087322 },
    { lat: 49.2701905, lng: 20.3083221 },
    { lat: 49.2696689, lng: 20.3077492 },
    { lat: 49.2691381, lng: 20.3071978 },
    { lat: 49.268917, lng: 20.3071309 },
    { lat: 49.2686721, lng: 20.3068818 },
    { lat: 49.268344, lng: 20.3060552 },
    { lat: 49.2678202, lng: 20.3059006 },
    { lat: 49.2675007, lng: 20.3056504 },
    { lat: 49.2671473, lng: 20.3056204 },
    { lat: 49.2666808, lng: 20.305236 },
    { lat: 49.2662486, lng: 20.3046881 },
    { lat: 49.2658029, lng: 20.3039082 },
    { lat: 49.2656396, lng: 20.3034392 },
    { lat: 49.2650763, lng: 20.3021803 },
    { lat: 49.2648771, lng: 20.302009 },
    { lat: 49.2646823, lng: 20.3017199 },
    { lat: 49.2645202, lng: 20.3016022 },
    { lat: 49.2644494, lng: 20.3014312 },
    { lat: 49.2641194, lng: 20.3010519 },
    { lat: 49.2632699, lng: 20.2997873 },
    { lat: 49.263389, lng: 20.2995497 },
    { lat: 49.2635413, lng: 20.298772 },
    { lat: 49.2635732, lng: 20.2982633 },
    { lat: 49.2644242, lng: 20.2966876 },
    { lat: 49.2648297, lng: 20.2956497 },
    { lat: 49.2652618, lng: 20.2949461 },
    { lat: 49.2653276, lng: 20.294028 },
    { lat: 49.2656172, lng: 20.2940064 },
    { lat: 49.2657769, lng: 20.2938304 },
    { lat: 49.2658768, lng: 20.2935283 },
    { lat: 49.2660768, lng: 20.2919508 },
    { lat: 49.2665866, lng: 20.2888454 },
    { lat: 49.2665363, lng: 20.2866521 },
    { lat: 49.2664795, lng: 20.2863764 },
    { lat: 49.2665276, lng: 20.2860533 },
    { lat: 49.2667558, lng: 20.2856046 },
    { lat: 49.2670975, lng: 20.2847202 },
    { lat: 49.267298, lng: 20.2840591 },
    { lat: 49.2674652, lng: 20.2837274 },
    { lat: 49.2678331, lng: 20.2834271 },
    { lat: 49.2680338, lng: 20.2829745 },
    { lat: 49.2681233, lng: 20.2828978 },
    { lat: 49.2683867, lng: 20.2824102 },
    { lat: 49.2684817, lng: 20.2823042 },
    { lat: 49.268775, lng: 20.2822029 },
    { lat: 49.2689133, lng: 20.2815893 },
    { lat: 49.2693566, lng: 20.2811466 },
    { lat: 49.2699965, lng: 20.2806929 },
    { lat: 49.2701511, lng: 20.2804694 },
    { lat: 49.2701585, lng: 20.2800223 },
    { lat: 49.2700629, lng: 20.2795309 },
    { lat: 49.2700745, lng: 20.2792825 },
    { lat: 49.2701716, lng: 20.2785363 },
    { lat: 49.2704166, lng: 20.2775142 },
    { lat: 49.2705219, lng: 20.2768624 },
    { lat: 49.2701504, lng: 20.2760521 },
    { lat: 49.2700706, lng: 20.2755369 },
    { lat: 49.2701629, lng: 20.275222 },
    { lat: 49.2704386, lng: 20.274955 },
    { lat: 49.2707486, lng: 20.274506 },
    { lat: 49.2709814, lng: 20.2727349 },
    { lat: 49.2707182, lng: 20.2720175 },
    { lat: 49.2704399, lng: 20.2715564 },
    { lat: 49.2699831, lng: 20.2712158 },
    { lat: 49.2696028, lng: 20.2707893 },
    { lat: 49.2695224, lng: 20.2706333 },
    { lat: 49.2694579, lng: 20.2702808 },
    { lat: 49.2695915, lng: 20.2699787 },
    { lat: 49.2696944, lng: 20.2693257 },
    { lat: 49.2693681, lng: 20.2676348 },
    { lat: 49.2691187, lng: 20.2670413 },
    { lat: 49.2689908, lng: 20.2669683 },
    { lat: 49.2686659, lng: 20.2658819 },
    { lat: 49.2685824, lng: 20.2654176 },
    { lat: 49.2682701, lng: 20.2647736 },
    { lat: 49.2682468, lng: 20.264466 },
    { lat: 49.2681144, lng: 20.2638442 },
    { lat: 49.267575, lng: 20.2631175 },
    { lat: 49.2668951, lng: 20.2616 },
    { lat: 49.2668182, lng: 20.2604958 },
    { lat: 49.2668818, lng: 20.2585495 },
    { lat: 49.2667901, lng: 20.2584547 },
    { lat: 49.2663513, lng: 20.2582893 },
    { lat: 49.2662444, lng: 20.2580885 },
    { lat: 49.2662285, lng: 20.2579418 },
    { lat: 49.2662862, lng: 20.2577757 },
    { lat: 49.266572, lng: 20.2575668 },
    { lat: 49.2668919, lng: 20.2570822 },
    { lat: 49.2669403, lng: 20.2561317 },
    { lat: 49.2669091, lng: 20.2558828 },
    { lat: 49.2668215, lng: 20.2556882 },
    { lat: 49.2664694, lng: 20.2554925 },
    { lat: 49.2662607, lng: 20.2552264 },
    { lat: 49.2666538, lng: 20.2541049 },
    { lat: 49.2667883, lng: 20.253833 },
    { lat: 49.2667303, lng: 20.2535285 },
    { lat: 49.2667776, lng: 20.252524 },
    { lat: 49.2668826, lng: 20.2520724 },
    { lat: 49.2666807, lng: 20.2494783 },
    { lat: 49.2669174, lng: 20.2478734 },
    { lat: 49.2670034, lng: 20.2477437 },
    { lat: 49.2673612, lng: 20.2476504 },
    { lat: 49.2675363, lng: 20.247489 },
    { lat: 49.2678265, lng: 20.2469937 },
    { lat: 49.2679702, lng: 20.2464956 },
    { lat: 49.2679862, lng: 20.246589 },
    { lat: 49.2681495, lng: 20.2460739 },
    { lat: 49.2686532, lng: 20.2453924 },
    { lat: 49.2686203, lng: 20.244873 },
    { lat: 49.2684832, lng: 20.2443909 },
    { lat: 49.2675173, lng: 20.2421851 },
    { lat: 49.2672349, lng: 20.2420184 },
    { lat: 49.2668388, lng: 20.2419372 },
    { lat: 49.2667802, lng: 20.2427697 },
    { lat: 49.2667365, lng: 20.2428588 },
    { lat: 49.2664744, lng: 20.2428111 },
    { lat: 49.2663185, lng: 20.2431682 },
    { lat: 49.265845, lng: 20.2433548 },
    { lat: 49.2657628, lng: 20.2434427 },
    { lat: 49.2654668, lng: 20.243446 },
    { lat: 49.2652698, lng: 20.2435326 },
    { lat: 49.2649322, lng: 20.2431951 },
    { lat: 49.2648552, lng: 20.2432111 },
    { lat: 49.2647663, lng: 20.2432683 },
    { lat: 49.2646452, lng: 20.2434824 },
    { lat: 49.2644747, lng: 20.2435027 },
    { lat: 49.2642643, lng: 20.243421 },
    { lat: 49.2637579, lng: 20.2436304 },
    { lat: 49.2632934, lng: 20.2440122 },
    { lat: 49.2630797, lng: 20.2438739 },
    { lat: 49.2624615, lng: 20.2436774 },
    { lat: 49.2620993, lng: 20.2432257 },
    { lat: 49.2615476, lng: 20.2429651 },
    { lat: 49.261175, lng: 20.2423103 },
    { lat: 49.2609752, lng: 20.2415225 },
    { lat: 49.2608103, lng: 20.2412546 },
    { lat: 49.2602727, lng: 20.2408684 },
    { lat: 49.2599505, lng: 20.2402635 },
    { lat: 49.2597175, lng: 20.240177 },
    { lat: 49.2591829, lng: 20.2402138 },
    { lat: 49.2590553, lng: 20.2404079 },
    { lat: 49.2585334, lng: 20.2406793 },
    { lat: 49.2583768, lng: 20.2406215 },
    { lat: 49.2582, lng: 20.2403594 },
    { lat: 49.2581682, lng: 20.2402109 },
    { lat: 49.2577511, lng: 20.2398227 },
    { lat: 49.2576796, lng: 20.239865 },
    { lat: 49.2568505, lng: 20.2391692 },
    { lat: 49.25672, lng: 20.2389479 },
    { lat: 49.2565049, lng: 20.2381133 },
    { lat: 49.2564344, lng: 20.2379766 },
    { lat: 49.2556906, lng: 20.237219 },
    { lat: 49.2552448, lng: 20.2370483 },
    { lat: 49.2547334, lng: 20.236614 },
    { lat: 49.2541935, lng: 20.2364577 },
    { lat: 49.253777, lng: 20.235948 },
    { lat: 49.2529358, lng: 20.235172 },
    { lat: 49.252497, lng: 20.2349235 },
    { lat: 49.2516149, lng: 20.2337397 },
    { lat: 49.2515367, lng: 20.2322672 },
    { lat: 49.252964, lng: 20.2281913 },
    { lat: 49.2529564, lng: 20.2280593 },
    { lat: 49.2528184, lng: 20.2274956 },
    { lat: 49.2519401, lng: 20.2262373 },
    { lat: 49.251762, lng: 20.2254671 },
    { lat: 49.251623, lng: 20.2252211 },
    { lat: 49.2502084, lng: 20.2233573 },
    { lat: 49.2502804, lng: 20.2231254 },
    { lat: 49.2502765, lng: 20.2221478 },
    { lat: 49.250597, lng: 20.2193079 },
    { lat: 49.2502347, lng: 20.2180325 },
    { lat: 49.2491805, lng: 20.2175428 },
    { lat: 49.2473892, lng: 20.217577 },
    { lat: 49.2464233, lng: 20.2169805 },
    { lat: 49.2461383, lng: 20.2168932 },
    { lat: 49.2451987, lng: 20.2137754 },
    { lat: 49.2446481, lng: 20.2112128 },
    { lat: 49.2446336, lng: 20.2079997 },
    { lat: 49.2450731, lng: 20.204989 },
    { lat: 49.2450882, lng: 20.2048921 },
    { lat: 49.244992, lng: 20.2039945 },
    { lat: 49.2454378, lng: 20.2028804 },
    { lat: 49.2460902, lng: 20.201732 },
    { lat: 49.2467845, lng: 20.2008641 },
    { lat: 49.2471484, lng: 20.1997643 },
    { lat: 49.2479329, lng: 20.1992437 },
    { lat: 49.2483797, lng: 20.1983827 },
    { lat: 49.2484735, lng: 20.1977087 },
    { lat: 49.2484692, lng: 20.1976871 },
    { lat: 49.2481151, lng: 20.1967096 },
    { lat: 49.2479954, lng: 20.1964986 },
    { lat: 49.2446219, lng: 20.1937776 },
    { lat: 49.2427307, lng: 20.1926862 },
    { lat: 49.2417852, lng: 20.1919316 },
    { lat: 49.2404289, lng: 20.191237 },
    { lat: 49.2392716, lng: 20.1909593 },
    { lat: 49.2389243, lng: 20.1907244 },
    { lat: 49.2385272, lng: 20.1908726 },
    { lat: 49.2379361, lng: 20.1908199 },
    { lat: 49.2347611, lng: 20.1902103 },
    { lat: 49.234387, lng: 20.1900364 },
    { lat: 49.2338675, lng: 20.1907651 },
    { lat: 49.2335544, lng: 20.1913516 },
    { lat: 49.2336013, lng: 20.1919087 },
    { lat: 49.2334816, lng: 20.1923608 },
    { lat: 49.2334824, lng: 20.1925157 },
    { lat: 49.2335529, lng: 20.1926767 },
    { lat: 49.2335393, lng: 20.1929878 },
    { lat: 49.2336169, lng: 20.1930995 },
    { lat: 49.2336867, lng: 20.1933828 },
    { lat: 49.2336396, lng: 20.1936704 },
    { lat: 49.2332681, lng: 20.1941263 },
    { lat: 49.2333679, lng: 20.1947675 },
    { lat: 49.2332657, lng: 20.1952018 },
    { lat: 49.2330577, lng: 20.195323 },
    { lat: 49.2329326, lng: 20.1954885 },
    { lat: 49.2328145, lng: 20.195467 },
    { lat: 49.2324986, lng: 20.195761 },
    { lat: 49.2317847, lng: 20.1967866 },
    { lat: 49.2316581, lng: 20.1968311 },
    { lat: 49.2314124, lng: 20.1973954 },
    { lat: 49.2312563, lng: 20.1975983 },
    { lat: 49.2310814, lng: 20.1980739 },
    { lat: 49.2309101, lng: 20.1983235 },
    { lat: 49.2302627, lng: 20.1990107 },
    { lat: 49.2299339, lng: 20.1992104 },
    { lat: 49.2298592, lng: 20.199457 },
    { lat: 49.2296223, lng: 20.1998143 },
    { lat: 49.2294244, lng: 20.1998517 },
    { lat: 49.2293681, lng: 20.1999603 },
    { lat: 49.2291673, lng: 20.2000974 },
    { lat: 49.2284654, lng: 20.2024156 },
    { lat: 49.2197245, lng: 20.2081733 },
    { lat: 49.2155203, lng: 20.2012527 },
    { lat: 49.2107843, lng: 20.198958 },
    { lat: 49.2078596, lng: 20.1995434 },
    { lat: 49.2066962, lng: 20.2000543 },
    { lat: 49.2052248, lng: 20.2010243 },
    { lat: 49.2016424, lng: 20.19716 },
    { lat: 49.2028047, lng: 20.1899659 },
    { lat: 49.2026182, lng: 20.1859536 },
    { lat: 49.2011277, lng: 20.1844107 },
    { lat: 49.1985537, lng: 20.1828018 },
    { lat: 49.1963921, lng: 20.1828178 },
    { lat: 49.194875, lng: 20.1829517 },
    { lat: 49.1900668, lng: 20.1830925 },
    { lat: 49.1883304, lng: 20.1764298 },
    { lat: 49.1875224, lng: 20.1724364 },
    { lat: 49.187617, lng: 20.1695882 },
    { lat: 49.1883746, lng: 20.1667331 },
    { lat: 49.1880838, lng: 20.1622776 },
    { lat: 49.1869235, lng: 20.1604366 },
    { lat: 49.1864858, lng: 20.1594056 },
    { lat: 49.1854457, lng: 20.1583776 },
    { lat: 49.185514, lng: 20.1551636 },
    { lat: 49.184879, lng: 20.1525258 },
    { lat: 49.1841606, lng: 20.1501701 },
    { lat: 49.1838081, lng: 20.1495255 },
    { lat: 49.1828487, lng: 20.147421 },
    { lat: 49.1781841, lng: 20.1440185 },
    { lat: 49.1775094, lng: 20.1444649 },
    { lat: 49.1773844, lng: 20.1446602 },
    { lat: 49.1769195, lng: 20.1450071 },
    { lat: 49.1757924, lng: 20.1453456 },
    { lat: 49.1752869, lng: 20.1454861 },
    { lat: 49.1745902, lng: 20.1433096 },
    { lat: 49.1739075, lng: 20.1416782 },
    { lat: 49.1737211, lng: 20.1406846 },
    { lat: 49.1731349, lng: 20.1395751 },
    { lat: 49.1732534, lng: 20.1384623 },
    { lat: 49.1736449, lng: 20.1369965 },
    { lat: 49.1739418, lng: 20.1347749 },
    { lat: 49.1723263, lng: 20.1306498 },
    { lat: 49.1710976, lng: 20.129727 },
    { lat: 49.1693604, lng: 20.1298229 },
    { lat: 49.1672595, lng: 20.1312894 },
    { lat: 49.1659969, lng: 20.126673 },
    { lat: 49.1653996, lng: 20.1248772 },
    { lat: 49.1670586, lng: 20.116738 },
    { lat: 49.1681834, lng: 20.1131079 },
    { lat: 49.1687533, lng: 20.112024 },
    { lat: 49.1693062, lng: 20.1103553 },
    { lat: 49.1696481, lng: 20.1095371 },
    { lat: 49.1701491, lng: 20.1077768 },
    { lat: 49.1714278, lng: 20.1018164 },
    { lat: 49.1722057, lng: 20.0978671 },
    { lat: 49.172646, lng: 20.0941453 },
    { lat: 49.1728343, lng: 20.0940397 },
    { lat: 49.1730658, lng: 20.0935582 },
    { lat: 49.1740238, lng: 20.0931884 },
    { lat: 49.1790129, lng: 20.0894492 },
    { lat: 49.179313, lng: 20.0884942 },
    { lat: 49.1795743, lng: 20.0881093 },
    { lat: 49.179559, lng: 20.088001 },
    { lat: 49.179539, lng: 20.087961 },
    { lat: 49.1795112, lng: 20.0879015 },
    { lat: 49.179504, lng: 20.087784 },
    { lat: 49.179487, lng: 20.087691 },
    { lat: 49.179492, lng: 20.087553 },
    { lat: 49.17949, lng: 20.087408 },
    { lat: 49.179514, lng: 20.087271 },
    { lat: 49.179531, lng: 20.087158 },
    { lat: 49.1794895, lng: 20.0869877 },
    { lat: 49.1794977, lng: 20.0869202 },
    { lat: 49.1795708, lng: 20.0869148 },
    { lat: 49.179646, lng: 20.086748 },
    { lat: 49.1796938, lng: 20.0866245 },
    { lat: 49.1796597, lng: 20.0865994 },
    { lat: 49.179656, lng: 20.086552 },
    { lat: 49.179696, lng: 20.086411 },
    { lat: 49.179665, lng: 20.086253 },
    { lat: 49.1796848, lng: 20.0861288 },
    { lat: 49.1796463, lng: 20.086053 },
    { lat: 49.179691, lng: 20.085941 },
    { lat: 49.179675, lng: 20.085786 },
    { lat: 49.179659, lng: 20.085653 },
    { lat: 49.179614, lng: 20.085551 },
    { lat: 49.179611, lng: 20.085377 },
    { lat: 49.1796089, lng: 20.0852445 },
    { lat: 49.179592, lng: 20.085157 },
    { lat: 49.179587, lng: 20.085033 },
    { lat: 49.17957, lng: 20.084921 },
    { lat: 49.179587, lng: 20.084826 },
    { lat: 49.179572, lng: 20.084634 },
    { lat: 49.179589, lng: 20.084461 },
    { lat: 49.179628, lng: 20.084317 },
    { lat: 49.179517, lng: 20.084318 },
    { lat: 49.1794918, lng: 20.0841933 },
    { lat: 49.179457, lng: 20.084077 },
    { lat: 49.179506, lng: 20.083937 },
    { lat: 49.179482, lng: 20.083799 },
    { lat: 49.179461, lng: 20.083676 },
    { lat: 49.179439, lng: 20.083592 },
    { lat: 49.179465, lng: 20.083406 },
    { lat: 49.179434, lng: 20.083227 },
    { lat: 49.179436, lng: 20.083049 },
    { lat: 49.179443, lng: 20.08286 },
    { lat: 49.179358, lng: 20.082623 },
    { lat: 49.179314, lng: 20.08246 },
    { lat: 49.179348, lng: 20.0824 },
    { lat: 49.179336, lng: 20.082186 },
    { lat: 49.179295, lng: 20.082159 },
    { lat: 49.179279, lng: 20.082092 },
    { lat: 49.179248, lng: 20.082052 },
    { lat: 49.179215, lng: 20.081975 },
    { lat: 49.179204, lng: 20.081827 },
    { lat: 49.179158, lng: 20.081687 },
    { lat: 49.17914, lng: 20.081582 },
    { lat: 49.179104, lng: 20.08139 },
    { lat: 49.179081, lng: 20.081189 },
    { lat: 49.179023, lng: 20.080941 },
    { lat: 49.179018, lng: 20.080765 },
    { lat: 49.178947, lng: 20.080779 },
    { lat: 49.17891, lng: 20.080488 },
    { lat: 49.178826, lng: 20.080127 },
    { lat: 49.178787, lng: 20.079974 },
    { lat: 49.178752, lng: 20.079878 },
    { lat: 49.1787249, lng: 20.0797875 },
    { lat: 49.178714, lng: 20.079658 },
    { lat: 49.178696, lng: 20.079566 },
    { lat: 49.178641, lng: 20.07942 },
    { lat: 49.178617, lng: 20.079323 },
    { lat: 49.178569, lng: 20.079333 },
    { lat: 49.17856, lng: 20.079245 },
    { lat: 49.178476, lng: 20.079021 },
    { lat: 49.178523, lng: 20.078868 },
    { lat: 49.178492, lng: 20.078624 },
    { lat: 49.178534, lng: 20.078599 },
    { lat: 49.178602, lng: 20.078595 },
    { lat: 49.1786181, lng: 20.0785249 },
    { lat: 49.178656, lng: 20.07836 },
    { lat: 49.178608, lng: 20.078145 },
    { lat: 49.178596, lng: 20.078033 },
    { lat: 49.17856, lng: 20.077815 },
    { lat: 49.17858, lng: 20.077761 },
    { lat: 49.17861, lng: 20.07763 },
    { lat: 49.178606, lng: 20.077483 },
    { lat: 49.178572, lng: 20.077253 },
    { lat: 49.178588, lng: 20.077095 },
    { lat: 49.178539, lng: 20.076926 },
    { lat: 49.178548, lng: 20.076758 },
    { lat: 49.178577, lng: 20.076716 },
    { lat: 49.178589, lng: 20.07655 },
    { lat: 49.1786, lng: 20.07638 },
    { lat: 49.178615, lng: 20.076276 },
    { lat: 49.178666, lng: 20.076169 },
    { lat: 49.17871, lng: 20.076063 },
    { lat: 49.178702, lng: 20.075955 },
    { lat: 49.178722, lng: 20.075848 },
    { lat: 49.178738, lng: 20.075752 },
    { lat: 49.178709, lng: 20.075632 },
    { lat: 49.178751, lng: 20.075605 },
    { lat: 49.178764, lng: 20.075502 },
    { lat: 49.178853, lng: 20.075395 },
    { lat: 49.178922, lng: 20.075336 },
    { lat: 49.178935, lng: 20.075254 },
    { lat: 49.178901, lng: 20.075123 },
    { lat: 49.178897, lng: 20.07509 },
    { lat: 49.178964, lng: 20.075071 },
    { lat: 49.178993, lng: 20.0749 },
    { lat: 49.179053, lng: 20.074847 },
    { lat: 49.179098, lng: 20.074752 },
    { lat: 49.179144, lng: 20.074653 },
    { lat: 49.179168, lng: 20.074562 },
    { lat: 49.179253, lng: 20.07445 },
    { lat: 49.179331, lng: 20.074116 },
    { lat: 49.179385, lng: 20.074097 },
    { lat: 49.179425, lng: 20.07406 },
    { lat: 49.179465, lng: 20.073915 },
    { lat: 49.179497, lng: 20.073888 },
    { lat: 49.179538, lng: 20.073767 },
    { lat: 49.179581, lng: 20.073611 },
    { lat: 49.179649, lng: 20.073548 },
    { lat: 49.179679, lng: 20.073486 },
    { lat: 49.179715, lng: 20.073433 },
    { lat: 49.179766, lng: 20.073372 },
    { lat: 49.179797, lng: 20.073253 },
    { lat: 49.179806, lng: 20.073203 },
    { lat: 49.179802, lng: 20.073099 },
    { lat: 49.179847, lng: 20.073048 },
    { lat: 49.179852, lng: 20.072938 },
    { lat: 49.179904, lng: 20.07279 },
    { lat: 49.180001, lng: 20.072586 },
    { lat: 49.180049, lng: 20.072485 },
    { lat: 49.1801089, lng: 20.0725225 },
    { lat: 49.180166, lng: 20.072416 },
    { lat: 49.180182, lng: 20.072387 },
    { lat: 49.180214, lng: 20.072341 },
    { lat: 49.180217, lng: 20.07211 },
    { lat: 49.180153, lng: 20.071865 },
    { lat: 49.18022, lng: 20.071655 },
    { lat: 49.180243, lng: 20.071501 },
    { lat: 49.180182, lng: 20.071416 },
    { lat: 49.180247, lng: 20.071392 },
    { lat: 49.180268, lng: 20.07123 },
    { lat: 49.180351, lng: 20.071143 },
    { lat: 49.180412, lng: 20.071078 },
    { lat: 49.1803941, lng: 20.0710107 },
    { lat: 49.180418, lng: 20.070901 },
    { lat: 49.18046, lng: 20.070761 },
    { lat: 49.180464, lng: 20.070498 },
    { lat: 49.180499, lng: 20.07046 },
    { lat: 49.180495, lng: 20.070285 },
    { lat: 49.18049, lng: 20.070248 },
    { lat: 49.180547, lng: 20.070088 },
    { lat: 49.180557, lng: 20.069898 },
    { lat: 49.180614, lng: 20.069797 },
    { lat: 49.180609, lng: 20.069734 },
    { lat: 49.180606, lng: 20.0697 },
    { lat: 49.180632, lng: 20.0695947 },
    { lat: 49.180697, lng: 20.069332 },
    { lat: 49.180781, lng: 20.069107 },
    { lat: 49.18087, lng: 20.069032 },
    { lat: 49.180946, lng: 20.069028 },
    { lat: 49.180968, lng: 20.068989 },
    { lat: 49.1809959, lng: 20.0689525 },
    { lat: 49.181033, lng: 20.068794 },
    { lat: 49.181111, lng: 20.068682 },
    { lat: 49.181178, lng: 20.068627 },
    { lat: 49.181262, lng: 20.0686 },
    { lat: 49.181369, lng: 20.068662 },
    { lat: 49.181413, lng: 20.068598 },
    { lat: 49.181498, lng: 20.068463 },
    { lat: 49.181531, lng: 20.068333 },
    { lat: 49.181573, lng: 20.068337 },
    { lat: 49.181631, lng: 20.068239 },
    { lat: 49.181758, lng: 20.068107 },
    { lat: 49.1818776, lng: 20.0679517 },
    { lat: 49.181892, lng: 20.067933 },
    { lat: 49.181981, lng: 20.06781 },
    { lat: 49.182109, lng: 20.067733 },
    { lat: 49.182361, lng: 20.0676 },
    { lat: 49.182647, lng: 20.067519 },
    { lat: 49.182834, lng: 20.067475 },
    { lat: 49.1829849, lng: 20.0673005 },
    { lat: 49.183182, lng: 20.067183 },
    { lat: 49.183186, lng: 20.067145 },
    { lat: 49.183241, lng: 20.06691 },
    { lat: 49.183362, lng: 20.066419 },
    { lat: 49.183529, lng: 20.066212 },
    { lat: 49.183544, lng: 20.066103 },
    { lat: 49.183505, lng: 20.065879 },
    { lat: 49.183605, lng: 20.065696 },
    { lat: 49.183639, lng: 20.065476 },
    { lat: 49.1836162, lng: 20.0652865 },
    { lat: 49.183774, lng: 20.064986 },
    { lat: 49.183773, lng: 20.064912 },
    { lat: 49.1839, lng: 20.064777 },
    { lat: 49.183977, lng: 20.064788 },
    { lat: 49.184025, lng: 20.064717 },
    { lat: 49.184035, lng: 20.064648 },
    { lat: 49.183988, lng: 20.064492 },
    { lat: 49.184039, lng: 20.064391 },
    { lat: 49.184058, lng: 20.064238 },
    { lat: 49.184131, lng: 20.064057 },
    { lat: 49.184276, lng: 20.064118 },
    { lat: 49.184339, lng: 20.063921 },
    { lat: 49.18439, lng: 20.063841 },
    { lat: 49.184403, lng: 20.063516 },
    { lat: 49.18441, lng: 20.063285 },
    { lat: 49.184526, lng: 20.063169 },
    { lat: 49.184595, lng: 20.063071 },
    { lat: 49.184569, lng: 20.062906 },
    { lat: 49.184564, lng: 20.062762 },
    { lat: 49.184635, lng: 20.062554 },
    { lat: 49.184731, lng: 20.062238 },
    { lat: 49.184798, lng: 20.062019 },
    { lat: 49.184966, lng: 20.061741 },
    { lat: 49.185051, lng: 20.061669 },
    { lat: 49.185145, lng: 20.061602 },
    { lat: 49.18525, lng: 20.06149 },
    { lat: 49.185367, lng: 20.061334 },
    { lat: 49.185497, lng: 20.061141 },
    { lat: 49.185679, lng: 20.060912 },
    { lat: 49.185776, lng: 20.060868 },
    { lat: 49.185843, lng: 20.06081 },
    { lat: 49.185995, lng: 20.060706 },
    { lat: 49.186194, lng: 20.060562 },
    { lat: 49.186353, lng: 20.060562 },
    { lat: 49.186487, lng: 20.060493 },
    { lat: 49.186588, lng: 20.06038 },
    { lat: 49.186729, lng: 20.060301 },
    { lat: 49.186851, lng: 20.06019 },
    { lat: 49.186916, lng: 20.060277 },
    { lat: 49.187041, lng: 20.060181 },
    { lat: 49.1870574, lng: 20.0600967 },
    { lat: 49.187078, lng: 20.059991 },
    { lat: 49.187108, lng: 20.059968 },
    { lat: 49.187107, lng: 20.059831 },
    { lat: 49.187091, lng: 20.059567 },
    { lat: 49.18703, lng: 20.0592982 },
    { lat: 49.187085, lng: 20.059122 },
    { lat: 49.187165, lng: 20.058953 },
    { lat: 49.187258, lng: 20.058788 },
    { lat: 49.1874, lng: 20.05865 },
    { lat: 49.187393, lng: 20.058426 },
    { lat: 49.187484, lng: 20.05802 },
    { lat: 49.187602, lng: 20.057697 },
    { lat: 49.187625, lng: 20.057221 },
    { lat: 49.187585, lng: 20.056747 },
    { lat: 49.187724, lng: 20.056265 },
    { lat: 49.187804, lng: 20.056015 },
    { lat: 49.187739, lng: 20.055569 },
    { lat: 49.187703, lng: 20.055228 },
    { lat: 49.187775, lng: 20.055123 },
    { lat: 49.187771, lng: 20.054985 },
    { lat: 49.187744, lng: 20.0548 },
    { lat: 49.187865, lng: 20.054624 },
    { lat: 49.187884, lng: 20.054274 },
    { lat: 49.187862, lng: 20.054049 },
    { lat: 49.187896, lng: 20.053946 },
    { lat: 49.187803, lng: 20.053678 },
    { lat: 49.187829, lng: 20.053512 },
    { lat: 49.1878619, lng: 20.0532455 },
    { lat: 49.187962, lng: 20.053176 },
    { lat: 49.188061, lng: 20.052875 },
    { lat: 49.188069, lng: 20.052733 },
    { lat: 49.188168, lng: 20.052487 },
    { lat: 49.188305, lng: 20.052156 },
    { lat: 49.1883429, lng: 20.0518471 },
    { lat: 49.188826, lng: 20.050967 },
    { lat: 49.189119, lng: 20.0509767 },
    { lat: 49.1894998, lng: 20.0504802 },
    { lat: 49.189604, lng: 20.050081 },
    { lat: 49.1894631, lng: 20.0495375 },
    { lat: 49.1894735, lng: 20.0493083 },
    { lat: 49.1895809, lng: 20.0490536 },
    { lat: 49.1895289, lng: 20.0488984 },
    { lat: 49.1897777, lng: 20.0482771 },
    { lat: 49.1899516, lng: 20.0480616 },
    { lat: 49.1899982, lng: 20.0478442 },
    { lat: 49.1901364, lng: 20.0477592 },
    { lat: 49.1903658, lng: 20.0471922 },
    { lat: 49.1911693, lng: 20.0465575 },
    { lat: 49.1917656, lng: 20.0449616 },
    { lat: 49.191958, lng: 20.044893 },
    { lat: 49.192165, lng: 20.044653 },
    { lat: 49.1924293, lng: 20.0444459 },
    { lat: 49.1928618, lng: 20.0438305 },
    { lat: 49.1929079, lng: 20.0434008 },
    { lat: 49.19304, lng: 20.042984 },
    { lat: 49.1931776, lng: 20.0426189 },
    { lat: 49.1935999, lng: 20.0422664 },
    { lat: 49.1941407, lng: 20.0418758 },
    { lat: 49.1945, lng: 20.041381 },
    { lat: 49.194624, lng: 20.041311 },
    { lat: 49.194749, lng: 20.041143 },
    { lat: 49.194969, lng: 20.0407321 },
    { lat: 49.1951495, lng: 20.04057 },
    { lat: 49.1954109, lng: 20.0405834 },
    { lat: 49.1955343, lng: 20.0405443 },
    { lat: 49.1956394, lng: 20.0403757 },
    { lat: 49.195792, lng: 20.040236 },
    { lat: 49.1959517, lng: 20.0400541 },
    { lat: 49.1960722, lng: 20.0400495 },
    { lat: 49.1962449, lng: 20.0399507 },
    { lat: 49.1964886, lng: 20.0401271 },
    { lat: 49.1967122, lng: 20.0401511 },
    { lat: 49.196944, lng: 20.040286 },
    { lat: 49.1970162, lng: 20.0405028 },
    { lat: 49.197069, lng: 20.040361 },
    { lat: 49.197289, lng: 20.040097 },
    { lat: 49.1974229, lng: 20.0397723 },
    { lat: 49.197409, lng: 20.0396681 },
    { lat: 49.1974034, lng: 20.039626 },
    { lat: 49.1974368, lng: 20.0395555 },
    { lat: 49.197467, lng: 20.039492 },
    { lat: 49.19757, lng: 20.039112 },
    { lat: 49.197662, lng: 20.038806 },
    { lat: 49.1977701, lng: 20.038157 },
    { lat: 49.197813, lng: 20.037322 },
    { lat: 49.1979735, lng: 20.0367569 },
    { lat: 49.198112, lng: 20.036413 },
    { lat: 49.198218, lng: 20.036168 },
    { lat: 49.198229, lng: 20.035932 },
    { lat: 49.1982869, lng: 20.0357529 },
    { lat: 49.198382, lng: 20.0354587 },
    { lat: 49.1985224, lng: 20.0352279 },
    { lat: 49.1985546, lng: 20.0351397 },
    { lat: 49.1986335, lng: 20.0349233 },
    { lat: 49.1985753, lng: 20.0345782 },
    { lat: 49.1986181, lng: 20.0343027 },
    { lat: 49.1988525, lng: 20.0340652 },
    { lat: 49.1990483, lng: 20.0337903 },
    { lat: 49.199132, lng: 20.033562 },
    { lat: 49.1992073, lng: 20.0333875 },
    { lat: 49.199233, lng: 20.033105 },
    { lat: 49.1993051, lng: 20.0328505 },
    { lat: 49.1997812, lng: 20.0325215 },
    { lat: 49.1999582, lng: 20.0322557 },
    { lat: 49.1999471, lng: 20.0322012 },
    { lat: 49.1998852, lng: 20.0318986 },
    { lat: 49.199846, lng: 20.031707 },
    { lat: 49.1997539, lng: 20.0312945 },
    { lat: 49.199766, lng: 20.030995 },
    { lat: 49.199712, lng: 20.030597 },
    { lat: 49.1996969, lng: 20.0302125 },
    { lat: 49.199757, lng: 20.029651 },
    { lat: 49.199805, lng: 20.029395 },
    { lat: 49.19986, lng: 20.02896 },
    { lat: 49.19995, lng: 20.028675 },
    { lat: 49.200068, lng: 20.028266 },
    { lat: 49.200055, lng: 20.0280368 },
    { lat: 49.200045, lng: 20.02786 },
    { lat: 49.200101, lng: 20.027566 },
    { lat: 49.200156, lng: 20.027248 },
    { lat: 49.200237, lng: 20.02702 },
    { lat: 49.200377, lng: 20.026677 },
    { lat: 49.200428, lng: 20.026444 },
    { lat: 49.200512, lng: 20.026272 },
    { lat: 49.200538, lng: 20.026144 },
    { lat: 49.200497, lng: 20.025935 },
    { lat: 49.2005199, lng: 20.0257715 },
    { lat: 49.200574, lng: 20.025568 },
    { lat: 49.200656, lng: 20.025483 },
    { lat: 49.200586, lng: 20.025294 },
    { lat: 49.200665, lng: 20.025126 },
    { lat: 49.2006597, lng: 20.0250858 },
    { lat: 49.200645, lng: 20.024973 },
    { lat: 49.200674, lng: 20.024712 },
    { lat: 49.20067, lng: 20.024409 },
    { lat: 49.200675, lng: 20.024059 },
    { lat: 49.200714, lng: 20.023798 },
    { lat: 49.2007399, lng: 20.0233195 },
    { lat: 49.200926, lng: 20.023166 },
    { lat: 49.201061, lng: 20.02299 },
    { lat: 49.201132, lng: 20.02277 },
    { lat: 49.201229, lng: 20.022525 },
    { lat: 49.20134, lng: 20.0220269 },
    { lat: 49.2013848, lng: 20.021899 },
    { lat: 49.201443, lng: 20.021547 },
    { lat: 49.201502, lng: 20.021089 },
    { lat: 49.201589, lng: 20.020902 },
    { lat: 49.201563, lng: 20.020728 },
    { lat: 49.201505, lng: 20.020529 },
    { lat: 49.2016249, lng: 20.0203855 },
    { lat: 49.201685, lng: 20.02025 },
    { lat: 49.201798, lng: 20.020109 },
    { lat: 49.20187, lng: 20.019937 },
    { lat: 49.202039, lng: 20.019772 },
    { lat: 49.2022, lng: 20.019621 },
    { lat: 49.2023006, lng: 20.0195255 },
    { lat: 49.202438, lng: 20.019395 },
    { lat: 49.202574, lng: 20.019336 },
    { lat: 49.202711, lng: 20.018993 },
    { lat: 49.202867, lng: 20.018845 },
    { lat: 49.202956, lng: 20.018735 },
    { lat: 49.203128, lng: 20.018789 },
    { lat: 49.203212, lng: 20.018628 },
    { lat: 49.203283, lng: 20.018461 },
    { lat: 49.203408, lng: 20.018263 },
    { lat: 49.203573, lng: 20.018051 },
    { lat: 49.203873, lng: 20.017623 },
    { lat: 49.204048, lng: 20.017398 },
    { lat: 49.204155, lng: 20.017212 },
    { lat: 49.204322, lng: 20.016976 },
    { lat: 49.204475, lng: 20.016829 },
    { lat: 49.204596, lng: 20.016731 },
    { lat: 49.204684, lng: 20.016632 },
    { lat: 49.204873, lng: 20.016524 },
    { lat: 49.205118, lng: 20.016323 },
    { lat: 49.205174, lng: 20.016222 },
    { lat: 49.20527, lng: 20.016141 },
    { lat: 49.205429, lng: 20.015946 },
    { lat: 49.2054833, lng: 20.0158583 },
    { lat: 49.205626, lng: 20.015628 },
    { lat: 49.20574, lng: 20.015443 },
    { lat: 49.205878, lng: 20.015294 },
    { lat: 49.2059397, lng: 20.0151602 },
    { lat: 49.206002, lng: 20.015025 },
    { lat: 49.206137, lng: 20.014639 },
    { lat: 49.2061836, lng: 20.0145096 },
    { lat: 49.206244, lng: 20.014342 },
    { lat: 49.2064019, lng: 20.0143985 },
    { lat: 49.206553, lng: 20.014173 },
    { lat: 49.20667, lng: 20.013792 },
    { lat: 49.206756, lng: 20.013395 },
    { lat: 49.20694, lng: 20.013165 },
    { lat: 49.20716, lng: 20.012887 },
    { lat: 49.207319, lng: 20.012851 },
    { lat: 49.207499, lng: 20.012755 },
    { lat: 49.207723, lng: 20.012666 },
    { lat: 49.207847, lng: 20.012591 },
    { lat: 49.207941, lng: 20.012563 },
    { lat: 49.208066, lng: 20.012525 },
    { lat: 49.208143, lng: 20.012474 },
    { lat: 49.208283, lng: 20.012413 },
    { lat: 49.208397, lng: 20.012277 },
    { lat: 49.208579, lng: 20.012163 },
    { lat: 49.208906, lng: 20.012249 },
    { lat: 49.209106, lng: 20.012185 },
    { lat: 49.209253, lng: 20.012012 },
    { lat: 49.209509, lng: 20.01199 },
    { lat: 49.209544, lng: 20.011901 },
    { lat: 49.209677, lng: 20.011781 },
    { lat: 49.209675, lng: 20.011628 },
    { lat: 49.209757, lng: 20.01147 },
    { lat: 49.209878, lng: 20.01131 },
    { lat: 49.210028, lng: 20.0113 },
    { lat: 49.210104, lng: 20.011206 },
    { lat: 49.210358, lng: 20.010637 },
    { lat: 49.21068, lng: 20.010138 },
    { lat: 49.210833, lng: 20.009929 },
    { lat: 49.210996, lng: 20.009668 },
    { lat: 49.211086, lng: 20.009386 },
    { lat: 49.211163, lng: 20.009158 },
    { lat: 49.211233, lng: 20.008976 },
    { lat: 49.211367, lng: 20.008729 },
    { lat: 49.211428, lng: 20.008597 },
    { lat: 49.211476, lng: 20.008371 },
    { lat: 49.211541, lng: 20.008245 },
    { lat: 49.211621, lng: 20.008048 },
    { lat: 49.211766, lng: 20.007916 },
    { lat: 49.21197, lng: 20.007709 },
    { lat: 49.212105, lng: 20.007598 },
    { lat: 49.212239, lng: 20.00742 },
    { lat: 49.212284, lng: 20.007188 },
    { lat: 49.212371, lng: 20.007082 },
    { lat: 49.2125409, lng: 20.0069725 },
    { lat: 49.212723, lng: 20.006852 },
    { lat: 49.212873, lng: 20.006809 },
    { lat: 49.213036, lng: 20.006757 },
    { lat: 49.213158, lng: 20.00647 },
    { lat: 49.213311, lng: 20.006501 },
    { lat: 49.213371, lng: 20.006617 },
    { lat: 49.213413, lng: 20.006742 },
    { lat: 49.213439, lng: 20.00682 },
    { lat: 49.213524, lng: 20.007006 },
    { lat: 49.213514, lng: 20.007164 },
    { lat: 49.213599, lng: 20.007323 },
    { lat: 49.213794, lng: 20.007224 },
    { lat: 49.213955, lng: 20.007333 },
    { lat: 49.21403, lng: 20.00746 },
    { lat: 49.214165, lng: 20.007275 },
    { lat: 49.214297, lng: 20.007358 },
    { lat: 49.214526, lng: 20.00761 },
    { lat: 49.214593, lng: 20.007782 },
    { lat: 49.214733, lng: 20.008015 },
    { lat: 49.214881, lng: 20.008185 },
    { lat: 49.215029, lng: 20.008376 },
    { lat: 49.215206, lng: 20.008491 },
    { lat: 49.215447, lng: 20.008738 },
    { lat: 49.215611, lng: 20.008853 },
    { lat: 49.215732, lng: 20.008925 },
    { lat: 49.215906, lng: 20.008953 },
    { lat: 49.2160609, lng: 20.0090015 },
    { lat: 49.216177, lng: 20.009076 },
    { lat: 49.216328, lng: 20.008998 },
    { lat: 49.216538, lng: 20.008938 },
    { lat: 49.216698, lng: 20.008932 },
    { lat: 49.216849, lng: 20.008878 },
    { lat: 49.217051, lng: 20.008936 },
    { lat: 49.217225, lng: 20.009018 },
    { lat: 49.217392, lng: 20.008897 },
    { lat: 49.217576, lng: 20.008859 },
    { lat: 49.217756, lng: 20.008754 },
    { lat: 49.217867, lng: 20.008735 },
    { lat: 49.217928, lng: 20.008798 },
    { lat: 49.218061, lng: 20.008848 },
    { lat: 49.218221, lng: 20.008857 },
    { lat: 49.218317, lng: 20.008941 },
    { lat: 49.218547, lng: 20.009018 },
    { lat: 49.218686, lng: 20.009239 },
    { lat: 49.218919, lng: 20.009407 },
    { lat: 49.219089, lng: 20.009216 },
    { lat: 49.219233, lng: 20.009249 },
    { lat: 49.2194211, lng: 20.0093063 },
    { lat: 49.22005, lng: 20.008629 },
    { lat: 49.219979, lng: 20.00768 },
    { lat: 49.220113, lng: 20.006832 },
    { lat: 49.220388, lng: 20.004995 },
    { lat: 49.220987, lng: 20.003761 },
    { lat: 49.221509, lng: 20.002705 },
    { lat: 49.222266, lng: 20.00122 },
    { lat: 49.222272, lng: 20.000306 },
    { lat: 49.2222977, lng: 19.9992188 },
    { lat: 49.223098, lng: 19.998194 },
    { lat: 49.2236677, lng: 19.9970114 },
    { lat: 49.224008, lng: 19.995849 },
    { lat: 49.22446, lng: 19.994388 },
    { lat: 49.2251479, lng: 19.9925263 },
    { lat: 49.2256403, lng: 19.9913524 },
    { lat: 49.226263, lng: 19.989752 },
    { lat: 49.227253, lng: 19.9891202 },
    { lat: 49.2273597, lng: 19.9889949 },
    { lat: 49.228242, lng: 19.987961 },
    { lat: 49.229096, lng: 19.987054 },
    { lat: 49.229528, lng: 19.98604 },
    { lat: 49.2301771, lng: 19.9843885 },
    { lat: 49.2302778, lng: 19.98422 },
    { lat: 49.2303982, lng: 19.9840188 },
    { lat: 49.2304687, lng: 19.983901 },
    { lat: 49.2307292, lng: 19.9834657 },
    { lat: 49.2308677, lng: 19.9832379 },
    { lat: 49.2309865, lng: 19.9830473 },
    { lat: 49.2313196, lng: 19.9823191 },
    { lat: 49.2318014, lng: 19.9815609 },
    { lat: 49.2316322, lng: 19.9813262 },
    { lat: 49.2312307, lng: 19.9803151 },
    { lat: 49.231146, lng: 19.9801017 },
    { lat: 49.2310867, lng: 19.9798483 },
    { lat: 49.2309623, lng: 19.9793165 },
    { lat: 49.2308334, lng: 19.9782338 },
    { lat: 49.2307737, lng: 19.9776331 },
    { lat: 49.2307211, lng: 19.9771009 },
    { lat: 49.2304773, lng: 19.9758948 },
    { lat: 49.2303272, lng: 19.975189 },
    { lat: 49.2302052, lng: 19.9746136 },
    { lat: 49.2300805, lng: 19.9740287 },
    { lat: 49.2300633, lng: 19.9733273 },
    { lat: 49.230103, lng: 19.972222 },
    { lat: 49.229955, lng: 19.971166 },
    { lat: 49.2301794, lng: 19.9704727 },
    { lat: 49.2303687, lng: 19.9692441 },
    { lat: 49.230657, lng: 19.967827 },
    { lat: 49.230806, lng: 19.967295 },
    { lat: 49.231123, lng: 19.966692 },
    { lat: 49.231291, lng: 19.966169 },
    { lat: 49.2312, lng: 19.964854 },
    { lat: 49.231366, lng: 19.962425 },
    { lat: 49.231543, lng: 19.9612 },
    { lat: 49.231736, lng: 19.960125 },
    { lat: 49.231807, lng: 19.959212 },
    { lat: 49.232059, lng: 19.958213 },
    { lat: 49.23228, lng: 19.957602 },
    { lat: 49.232404, lng: 19.957038 },
    { lat: 49.2323098, lng: 19.9567539 },
    { lat: 49.2324345, lng: 19.9557789 },
    { lat: 49.2323029, lng: 19.9540596 },
    { lat: 49.2321865, lng: 19.9531292 },
    { lat: 49.2323196, lng: 19.9513616 },
    { lat: 49.2323383, lng: 19.9511129 },
    { lat: 49.2327138, lng: 19.9504296 },
    { lat: 49.2326709, lng: 19.9498988 },
    { lat: 49.2325293, lng: 19.9491648 },
    { lat: 49.233029, lng: 19.948452 },
    { lat: 49.233325, lng: 19.946313 },
    { lat: 49.233986, lng: 19.945188 },
    { lat: 49.234119, lng: 19.943947 },
    { lat: 49.2344531, lng: 19.9434406 },
    { lat: 49.234047, lng: 19.942378 },
    { lat: 49.233821, lng: 19.941399 },
    { lat: 49.2336631, lng: 19.9403414 },
    { lat: 49.234027, lng: 19.939099 },
    { lat: 49.2342652, lng: 19.9379185 },
    { lat: 49.234654, lng: 19.93616 },
    { lat: 49.235026, lng: 19.935085 },
    { lat: 49.2352449, lng: 19.934431 },
    { lat: 49.235437, lng: 19.933857 },
    { lat: 49.236025, lng: 19.932932 },
    { lat: 49.2364897, lng: 19.9322171 },
    { lat: 49.236306, lng: 19.931644 },
    { lat: 49.236047, lng: 19.930655 },
    { lat: 49.23565, lng: 19.929279 },
    { lat: 49.235337, lng: 19.928177 },
    { lat: 49.235323, lng: 19.927837 },
    { lat: 49.2352646, lng: 19.9262262 },
    { lat: 49.2351548, lng: 19.9254153 },
    { lat: 49.235185, lng: 19.924998 },
    { lat: 49.235275, lng: 19.924495 },
    { lat: 49.235551, lng: 19.923957 },
    { lat: 49.235593, lng: 19.92375 },
    { lat: 49.235653, lng: 19.922492 },
    { lat: 49.235706, lng: 19.92143 },
    { lat: 49.235736, lng: 19.920544 },
    { lat: 49.23577, lng: 19.919689 },
    { lat: 49.235785, lng: 19.919267 },
    { lat: 49.235068, lng: 19.918404 },
    { lat: 49.234663, lng: 19.917699 },
    { lat: 49.234194, lng: 19.916573 },
    { lat: 49.233629, lng: 19.915198 },
    { lat: 49.233167, lng: 19.914047 },
    { lat: 49.232689, lng: 19.912914 },
    { lat: 49.232209, lng: 19.911824 },
    { lat: 49.232032, lng: 19.911392 },
    { lat: 49.231928, lng: 19.910855 },
    { lat: 49.231727, lng: 19.909843 },
    { lat: 49.231641, lng: 19.909405 },
    { lat: 49.231561, lng: 19.909095 },
    { lat: 49.231454, lng: 19.908338 },
    { lat: 49.2314, lng: 19.907584 },
    { lat: 49.231187, lng: 19.906829 },
    { lat: 49.23114, lng: 19.906427 },
    { lat: 49.2309407, lng: 19.9058724 },
    { lat: 49.231021, lng: 19.90558 },
    { lat: 49.231085, lng: 19.905089 },
    { lat: 49.23124, lng: 19.904691 },
    { lat: 49.2310716, lng: 19.9044412 },
    { lat: 49.2309027, lng: 19.9044466 },
    { lat: 49.2308458, lng: 19.904017 },
    { lat: 49.2308758, lng: 19.9038903 },
    { lat: 49.2307623, lng: 19.9036349 },
    { lat: 49.230572, lng: 19.903493 },
    { lat: 49.2304846, lng: 19.9034302 },
    { lat: 49.230259, lng: 19.903262 },
    { lat: 49.230175, lng: 19.903234 },
    { lat: 49.230109, lng: 19.903324 },
    { lat: 49.229926, lng: 19.903365 },
    { lat: 49.229773, lng: 19.903351 },
    { lat: 49.229613, lng: 19.903587 },
    { lat: 49.229514, lng: 19.903656 },
    { lat: 49.229444, lng: 19.903641 },
    { lat: 49.229376, lng: 19.903707 },
    { lat: 49.229273, lng: 19.903747 },
    { lat: 49.229158, lng: 19.903776 },
    { lat: 49.228846, lng: 19.903899 },
    { lat: 49.228604, lng: 19.904078 },
    { lat: 49.228511, lng: 19.904001 },
    { lat: 49.2283505, lng: 19.9040106 },
    { lat: 49.228176, lng: 19.903943 },
    { lat: 49.227844, lng: 19.903508 },
    { lat: 49.227512, lng: 19.903754 },
    { lat: 49.226912, lng: 19.90366 },
    { lat: 49.226371, lng: 19.903772 },
    { lat: 49.226199, lng: 19.903745 },
    { lat: 49.226073, lng: 19.903798 },
    { lat: 49.225916, lng: 19.903906 },
    { lat: 49.225583, lng: 19.903905 },
    { lat: 49.225407, lng: 19.90408 },
    { lat: 49.225364, lng: 19.904236 },
    { lat: 49.22515, lng: 19.904594 },
    { lat: 49.22497, lng: 19.904837 },
    { lat: 49.224885, lng: 19.905109 },
    { lat: 49.224704, lng: 19.905253 },
    { lat: 49.224585, lng: 19.905187 },
    { lat: 49.2243537, lng: 19.9049792 },
    { lat: 49.224274, lng: 19.905131 },
    { lat: 49.2236, lng: 19.90511 },
    { lat: 49.223146, lng: 19.905072 },
    { lat: 49.2222973, lng: 19.9049844 },
    { lat: 49.222264, lng: 19.904981 },
    { lat: 49.221377, lng: 19.904886 },
    { lat: 49.221096, lng: 19.904841 },
    { lat: 49.220797, lng: 19.904789 },
    { lat: 49.220246, lng: 19.90475 },
    { lat: 49.219646, lng: 19.904735 },
    { lat: 49.21949, lng: 19.905224 },
    { lat: 49.218783, lng: 19.904475 },
    { lat: 49.218106, lng: 19.903764 },
    { lat: 49.217545, lng: 19.903062 },
    { lat: 49.21711, lng: 19.902545 },
    { lat: 49.216691, lng: 19.902051 },
    { lat: 49.2166184, lng: 19.9019648 },
    { lat: 49.216584, lng: 19.901924 },
    { lat: 49.216371, lng: 19.901785 },
    { lat: 49.216251, lng: 19.901791 },
    { lat: 49.215809, lng: 19.901814 },
    { lat: 49.2157742, lng: 19.9018083 },
    { lat: 49.2152313, lng: 19.9015527 },
    { lat: 49.215204, lng: 19.901715 },
    { lat: 49.214989, lng: 19.901784 },
    { lat: 49.2145006, lng: 19.9016897 },
    { lat: 49.21441, lng: 19.901927 },
    { lat: 49.2143772, lng: 19.9019473 },
    { lat: 49.214104, lng: 19.902117 },
    { lat: 49.213794, lng: 19.90217 },
    { lat: 49.213429, lng: 19.902357 },
    { lat: 49.213313, lng: 19.902332 },
    { lat: 49.2132829, lng: 19.9022898 },
    { lat: 49.213216, lng: 19.902196 },
    { lat: 49.213048, lng: 19.901868 },
    { lat: 49.212881, lng: 19.901282 },
    { lat: 49.212802, lng: 19.900938 },
    { lat: 49.212771, lng: 19.900705 },
    { lat: 49.212645, lng: 19.899935 },
    { lat: 49.212432, lng: 19.898892 },
    { lat: 49.212143, lng: 19.897653 },
    { lat: 49.212041, lng: 19.897285 },
    { lat: 49.212006, lng: 19.896896 },
    { lat: 49.211992, lng: 19.896577 },
    { lat: 49.2116014, lng: 19.8958085 },
    { lat: 49.211564, lng: 19.895842 },
    { lat: 49.211131, lng: 19.894906 },
    { lat: 49.2106045, lng: 19.8933921 },
    { lat: 49.21062, lng: 19.893455 },
    { lat: 49.210253, lng: 19.892165 },
    { lat: 49.209973, lng: 19.891595 },
    { lat: 49.209925, lng: 19.89113 },
    { lat: 49.209857, lng: 19.890039 },
    { lat: 49.2093111, lng: 19.8886817 },
    { lat: 49.209188, lng: 19.88838 },
    { lat: 49.208752, lng: 19.88779 },
    { lat: 49.208165, lng: 19.886987 },
    { lat: 49.207146, lng: 19.88644 },
    { lat: 49.20637, lng: 19.886018 },
    { lat: 49.205366, lng: 19.885329 },
    { lat: 49.204932, lng: 19.884943 },
    { lat: 49.204705, lng: 19.884582 },
    { lat: 49.2044287, lng: 19.8841364 },
    { lat: 49.204178, lng: 19.883928 },
    { lat: 49.204178, lng: 19.883958 },
    { lat: 49.201303, lng: 19.887542 },
    { lat: 49.201227, lng: 19.887694 },
    { lat: 49.200947, lng: 19.88826 },
    { lat: 49.200612, lng: 19.888584 },
    { lat: 49.200569, lng: 19.889858 },
    { lat: 49.200547, lng: 19.890534 },
    { lat: 49.200543, lng: 19.890635 },
    { lat: 49.200524, lng: 19.891214 },
    { lat: 49.20048, lng: 19.892519 },
    { lat: 49.200422, lng: 19.894287 },
    { lat: 49.200403, lng: 19.894814 },
    { lat: 49.200345, lng: 19.896569 },
    { lat: 49.200314, lng: 19.897474 },
    { lat: 49.200219, lng: 19.900351 },
    { lat: 49.200204, lng: 19.900347 },
    { lat: 49.200131, lng: 19.900328 },
    { lat: 49.200092, lng: 19.900367 },
    { lat: 49.199837, lng: 19.900361 },
    { lat: 49.199729, lng: 19.900368 },
    { lat: 49.199622, lng: 19.900446 },
    { lat: 49.199475, lng: 19.900527 },
    { lat: 49.199346, lng: 19.900415 },
    { lat: 49.199316, lng: 19.900461 },
    { lat: 49.199107, lng: 19.900744 },
    { lat: 49.199049, lng: 19.900764 },
    { lat: 49.199017, lng: 19.90076 },
    { lat: 49.198959, lng: 19.900753 },
    { lat: 49.198888, lng: 19.900744 },
    { lat: 49.198589, lng: 19.901101 },
    { lat: 49.198548, lng: 19.901123 },
    { lat: 49.198498, lng: 19.901107 },
    { lat: 49.198398, lng: 19.901077 },
    { lat: 49.198337, lng: 19.90106 },
    { lat: 49.198274, lng: 19.901094 },
    { lat: 49.198205, lng: 19.901189 },
    { lat: 49.198114, lng: 19.901315 },
    { lat: 49.198015, lng: 19.901375 },
    { lat: 49.19793, lng: 19.901398 },
    { lat: 49.197858, lng: 19.901417 },
    { lat: 49.197714, lng: 19.901504 },
    { lat: 49.197645, lng: 19.901546 },
    { lat: 49.197505, lng: 19.901436 },
    { lat: 49.197402, lng: 19.901355 },
    { lat: 49.197229, lng: 19.90138 },
    { lat: 49.197075, lng: 19.9017 },
    { lat: 49.197037, lng: 19.901703 },
    { lat: 49.196869, lng: 19.901713 },
    { lat: 49.196755, lng: 19.901744 },
    { lat: 49.196644, lng: 19.901804 },
    { lat: 49.196543, lng: 19.901857 },
    { lat: 49.196503, lng: 19.901826 },
    { lat: 49.196419, lng: 19.901761 },
    { lat: 49.196255, lng: 19.901774 },
    { lat: 49.196189, lng: 19.901702 },
    { lat: 49.195957, lng: 19.901725 },
    { lat: 49.195944, lng: 19.901736 },
    { lat: 49.195844, lng: 19.901821 },
    { lat: 49.195596, lng: 19.901752 },
    { lat: 49.195246, lng: 19.901697 },
    { lat: 49.194841, lng: 19.901593 },
    { lat: 49.19459, lng: 19.901385 },
    { lat: 49.194487, lng: 19.901404 },
    { lat: 49.193984, lng: 19.900956 },
    { lat: 49.193842, lng: 19.900882 },
    { lat: 49.193741, lng: 19.900818 },
    { lat: 49.193458, lng: 19.900642 },
    { lat: 49.193398, lng: 19.900604 },
    { lat: 49.193168, lng: 19.900361 },
    { lat: 49.192821, lng: 19.899962 },
    { lat: 49.192729, lng: 19.899945 },
    { lat: 49.192492, lng: 19.899905 },
    { lat: 49.192419, lng: 19.899846 },
    { lat: 49.192194, lng: 19.899664 },
    { lat: 49.192136, lng: 19.899574 },
    { lat: 49.192075, lng: 19.899506 },
    { lat: 49.191518, lng: 19.89918 },
    { lat: 49.19143, lng: 19.89918 },
    { lat: 49.191267, lng: 19.899232 },
    { lat: 49.190845, lng: 19.899072 },
    { lat: 49.190526, lng: 19.899058 },
    { lat: 49.190414, lng: 19.899054 },
    { lat: 49.19008, lng: 19.89891 },
    { lat: 49.189757, lng: 19.898694 },
    { lat: 49.18953, lng: 19.898513 },
    { lat: 49.189005, lng: 19.898056 },
    { lat: 49.188766, lng: 19.897734 },
    { lat: 49.188576, lng: 19.89765 },
    { lat: 49.188209, lng: 19.897497 },
    { lat: 49.187901, lng: 19.897497 },
    { lat: 49.187388, lng: 19.897499 },
    { lat: 49.187094, lng: 19.897548 },
    { lat: 49.186892, lng: 19.89751 },
    { lat: 49.186846, lng: 19.897462 },
    { lat: 49.186673, lng: 19.897281 },
    { lat: 49.186419, lng: 19.897122 },
    { lat: 49.186395, lng: 19.897108 },
    { lat: 49.186378, lng: 19.897125 },
    { lat: 49.186164, lng: 19.897325 },
    { lat: 49.186021, lng: 19.897463 },
    { lat: 49.186005, lng: 19.897469 },
    { lat: 49.185815, lng: 19.897551 },
    { lat: 49.185732, lng: 19.897587 },
    { lat: 49.185525, lng: 19.897587 },
    { lat: 49.185451, lng: 19.897623 },
    { lat: 49.185227, lng: 19.89789 },
    { lat: 49.185003, lng: 19.898203 },
    { lat: 49.184921, lng: 19.898314 },
    { lat: 49.184589, lng: 19.898746 },
    { lat: 49.184381, lng: 19.899005 },
    { lat: 49.184323, lng: 19.899052 },
    { lat: 49.18403, lng: 19.899288 },
    { lat: 49.183476, lng: 19.89968 },
    { lat: 49.183179, lng: 19.899795 },
    { lat: 49.183135, lng: 19.89982 },
    { lat: 49.182559, lng: 19.900154 },
    { lat: 49.182377, lng: 19.900564 },
    { lat: 49.182209, lng: 19.900772 },
    { lat: 49.182094, lng: 19.900926 },
    { lat: 49.181991, lng: 19.901284 },
    { lat: 49.181926, lng: 19.901501 },
    { lat: 49.181879, lng: 19.901652 },
    { lat: 49.18166, lng: 19.902221 },
    { lat: 49.181558, lng: 19.902696 },
    { lat: 49.181562, lng: 19.902839 },
    { lat: 49.181567, lng: 19.903037 },
    { lat: 49.181544, lng: 19.903273 },
    { lat: 49.18147, lng: 19.903585 },
    { lat: 49.181373, lng: 19.904092 },
    { lat: 49.181345, lng: 19.904332 },
    { lat: 49.181363, lng: 19.904477 },
    { lat: 49.181434, lng: 19.904679 },
    { lat: 49.181677, lng: 19.905022 },
    { lat: 49.18176, lng: 19.905269 },
    { lat: 49.181951, lng: 19.905905 },
    { lat: 49.182119, lng: 19.906365 },
    { lat: 49.182266, lng: 19.906697 },
    { lat: 49.182427, lng: 19.907021 },
    { lat: 49.182565, lng: 19.907324 },
    { lat: 49.182673, lng: 19.907711 },
    { lat: 49.182764, lng: 19.908022 },
    { lat: 49.182837, lng: 19.908163 },
    { lat: 49.182934, lng: 19.908311 },
    { lat: 49.182986, lng: 19.908388 },
    { lat: 49.183236, lng: 19.90871 },
    { lat: 49.183372, lng: 19.909005 },
    { lat: 49.183397, lng: 19.909117 },
    { lat: 49.1834165, lng: 19.9092025 },
    { lat: 49.183436, lng: 19.909288 },
    { lat: 49.183454, lng: 19.909528 },
    { lat: 49.183434, lng: 19.909786 },
    { lat: 49.1834249, lng: 19.9098071 },
    { lat: 49.183339, lng: 19.910007 },
    { lat: 49.1832455, lng: 19.9101744 },
    { lat: 49.18321, lng: 19.910238 },
    { lat: 49.183161, lng: 19.910521 },
    { lat: 49.183128, lng: 19.911038 },
    { lat: 49.183065, lng: 19.911436 },
    { lat: 49.18295, lng: 19.911702 },
    { lat: 49.182791, lng: 19.911924 },
    { lat: 49.182561, lng: 19.91216 },
    { lat: 49.182514, lng: 19.912273 },
    { lat: 49.182486, lng: 19.912619 },
    { lat: 49.182428, lng: 19.912733 },
    { lat: 49.182243, lng: 19.913016 },
    { lat: 49.182208, lng: 19.913293 },
    { lat: 49.182123, lng: 19.913455 },
    { lat: 49.181956, lng: 19.91367 },
    { lat: 49.181891, lng: 19.913843 },
    { lat: 49.181824, lng: 19.91411 },
    { lat: 49.181748, lng: 19.914301 },
    { lat: 49.181753, lng: 19.914481 },
    { lat: 49.181949, lng: 19.914686 },
    { lat: 49.181981, lng: 19.914755 },
    { lat: 49.18199, lng: 19.914775 },
    { lat: 49.181993, lng: 19.914896 },
    { lat: 49.18196, lng: 19.915021 },
    { lat: 49.181891, lng: 19.915253 },
    { lat: 49.181536, lng: 19.915276 },
    { lat: 49.180909, lng: 19.915504 },
    { lat: 49.180779, lng: 19.915447 },
    { lat: 49.180232, lng: 19.915275 },
    { lat: 49.179816, lng: 19.915106 },
    { lat: 49.179571, lng: 19.914983 },
    { lat: 49.179213, lng: 19.914949 },
    { lat: 49.178581, lng: 19.91481 },
    { lat: 49.178108, lng: 19.914724 },
    { lat: 49.177986, lng: 19.914695 },
    { lat: 49.177619, lng: 19.914849 },
    { lat: 49.177481, lng: 19.914879 },
    { lat: 49.177085, lng: 19.914844 },
    { lat: 49.177046, lng: 19.914841 },
    { lat: 49.177027, lng: 19.914836 },
    { lat: 49.176745, lng: 19.914767 },
    { lat: 49.176549, lng: 19.914806 },
    { lat: 49.176372, lng: 19.91497 },
    { lat: 49.176165, lng: 19.915162 },
    { lat: 49.175895, lng: 19.915305 },
    { lat: 49.175546, lng: 19.915439 },
    { lat: 49.175308, lng: 19.915585 },
    { lat: 49.174944, lng: 19.915935 },
    { lat: 49.174341, lng: 19.916391 },
    { lat: 49.173949, lng: 19.916743 },
    { lat: 49.173395, lng: 19.917515 },
    { lat: 49.173046, lng: 19.918074 },
    { lat: 49.172903, lng: 19.918318 },
    { lat: 49.172804, lng: 19.918567 },
    { lat: 49.17267, lng: 19.919181 },
    { lat: 49.172605, lng: 19.919934 },
    { lat: 49.172581, lng: 19.920299 },
    { lat: 49.172469, lng: 19.920725 },
    { lat: 49.172308, lng: 19.920999 },
    { lat: 49.172144, lng: 19.921136 },
    { lat: 49.171952, lng: 19.921078 },
    { lat: 49.171859, lng: 19.921032 },
    { lat: 49.171568, lng: 19.92089 },
    { lat: 49.170872, lng: 19.92062 },
    { lat: 49.170555, lng: 19.920539 },
    { lat: 49.1703803, lng: 19.9205376 },
    { lat: 49.170189, lng: 19.920536 },
    { lat: 49.16937, lng: 19.921034 },
    { lat: 49.168809, lng: 19.921375 },
    { lat: 49.168681, lng: 19.921453 },
    { lat: 49.168446, lng: 19.921465 },
    { lat: 49.167938, lng: 19.92149 },
    { lat: 49.167693, lng: 19.921467 },
    { lat: 49.1674, lng: 19.921367 },
    { lat: 49.166551, lng: 19.921063 },
    { lat: 49.166117, lng: 19.920946 },
    { lat: 49.165995, lng: 19.920963 },
    { lat: 49.165707, lng: 19.921076 },
    { lat: 49.165526, lng: 19.921265 },
    { lat: 49.165422, lng: 19.921374 },
    { lat: 49.165112, lng: 19.921591 },
    { lat: 49.164868, lng: 19.921711 },
    { lat: 49.164667, lng: 19.921731 },
    { lat: 49.164443, lng: 19.921753 },
    { lat: 49.164078, lng: 19.921764 },
    { lat: 49.163803, lng: 19.92175 },
    { lat: 49.163602, lng: 19.921791 },
    { lat: 49.16335, lng: 19.921948 },
    { lat: 49.162974, lng: 19.922043 },
    { lat: 49.162633, lng: 19.922046 },
    { lat: 49.162387, lng: 19.921993 },
    { lat: 49.162385, lng: 19.921993 },
    { lat: 49.162365, lng: 19.921982 },
    { lat: 49.162325, lng: 19.92196 },
    { lat: 49.162322, lng: 19.92196 },
    { lat: 49.162158, lng: 19.921943 },
    { lat: 49.161806, lng: 19.92194 },
    { lat: 49.161565, lng: 19.921993 },
    { lat: 49.161226, lng: 19.922177 },
    { lat: 49.160687, lng: 19.922549 },
    { lat: 49.160585, lng: 19.92262 },
    { lat: 49.160289, lng: 19.922931 },
    { lat: 49.160025, lng: 19.923211 },
    { lat: 49.159414, lng: 19.922909 },
    { lat: 49.159257, lng: 19.922859 },
    { lat: 49.158888, lng: 19.92274 },
    { lat: 49.158884, lng: 19.92274 },
    { lat: 49.158402, lng: 19.922734 },
    { lat: 49.15834, lng: 19.922729 },
    { lat: 49.158103, lng: 19.922739 },
    { lat: 49.157637, lng: 19.92286 },
    { lat: 49.157368, lng: 19.923135 },
    { lat: 49.157141, lng: 19.923186 },
    { lat: 49.156724, lng: 19.923091 },
    { lat: 49.156133, lng: 19.923083 },
    { lat: 49.155793, lng: 19.923056 },
    { lat: 49.155739, lng: 19.923017 },
    { lat: 49.155392, lng: 19.922906 },
    { lat: 49.155328, lng: 19.922901 },
    { lat: 49.154949, lng: 19.922603 },
    { lat: 49.154329, lng: 19.921712 },
    { lat: 49.154015, lng: 19.921383 },
    { lat: 49.15349, lng: 19.920462 },
    { lat: 49.153044, lng: 19.920003 },
    { lat: 49.152956, lng: 19.919935 },
    { lat: 49.152929, lng: 19.919921 },
    { lat: 49.152782, lng: 19.919853 },
    { lat: 49.152572, lng: 19.919846 },
    { lat: 49.15207, lng: 19.919983 },
    { lat: 49.151778, lng: 19.920154 },
    { lat: 49.151744, lng: 19.920174 },
    { lat: 49.151572, lng: 19.920166 },
    { lat: 49.151412, lng: 19.920127 },
    { lat: 49.150794, lng: 19.919006 },
    { lat: 49.150755, lng: 19.918934 },
    { lat: 49.15074, lng: 19.918924 },
    { lat: 49.150622, lng: 19.918847 },
    { lat: 49.150189, lng: 19.918469 },
    { lat: 49.150073, lng: 19.918349 },
    { lat: 49.149903, lng: 19.918175 },
    { lat: 49.149658, lng: 19.917708 },
    { lat: 49.149581, lng: 19.917232 },
    { lat: 49.149501, lng: 19.916836 },
    { lat: 49.149475, lng: 19.916429 },
    { lat: 49.149481, lng: 19.916096 },
    { lat: 49.149527, lng: 19.915828 },
    { lat: 49.149605, lng: 19.91496 },
    { lat: 49.14963, lng: 19.914686 },
    { lat: 49.149611, lng: 19.914479 },
    { lat: 49.149577, lng: 19.914341 },
    { lat: 49.149513, lng: 19.914068 },
    { lat: 49.149524, lng: 19.913672 },
    { lat: 49.149541, lng: 19.913161 },
    { lat: 49.149473, lng: 19.912833 },
    { lat: 49.149289, lng: 19.912409 },
    { lat: 49.149132, lng: 19.912202 },
    { lat: 49.148926, lng: 19.912016 },
    { lat: 49.14869, lng: 19.911987 },
    { lat: 49.148362, lng: 19.911909 },
    { lat: 49.148022, lng: 19.911756 },
    { lat: 49.147447, lng: 19.911584 },
    { lat: 49.146874, lng: 19.911426 },
    { lat: 49.146687, lng: 19.911274 },
    { lat: 49.14661, lng: 19.911182 },
    { lat: 49.146565, lng: 19.911019 },
    { lat: 49.146458, lng: 19.910574 },
    { lat: 49.146173, lng: 19.90922 },
    { lat: 49.146083, lng: 19.908946 },
    { lat: 49.14604, lng: 19.908856 },
    { lat: 49.145291, lng: 19.908329 },
    { lat: 49.144957, lng: 19.907977 },
    { lat: 49.144252, lng: 19.90695 },
    { lat: 49.143869, lng: 19.906716 },
    { lat: 49.143755, lng: 19.906699 },
    { lat: 49.143416, lng: 19.906647 },
    { lat: 49.143327, lng: 19.90657 },
    { lat: 49.143181, lng: 19.906377 },
    { lat: 49.143083, lng: 19.906029 },
    { lat: 49.143023, lng: 19.905434 },
    { lat: 49.143021, lng: 19.905412 },
    { lat: 49.142942, lng: 19.905169 },
    { lat: 49.142771, lng: 19.904689 },
    { lat: 49.142664, lng: 19.90443 },
    { lat: 49.142578, lng: 19.904222 },
    { lat: 49.142451, lng: 19.904043 },
    { lat: 49.142424, lng: 19.904006 },
    { lat: 49.142363, lng: 19.903921 },
    { lat: 49.142171, lng: 19.903792 },
    { lat: 49.141953, lng: 19.903763 },
    { lat: 49.141769, lng: 19.903677 },
    { lat: 49.141642, lng: 19.903613 },
    { lat: 49.140926, lng: 19.902885 },
    { lat: 49.140848, lng: 19.902823 },
    { lat: 49.140647, lng: 19.902715 },
    { lat: 49.140584, lng: 19.902681 },
    { lat: 49.14054, lng: 19.902664 },
    { lat: 49.140341, lng: 19.902215 },
    { lat: 49.140331, lng: 19.902192 },
    { lat: 49.140279, lng: 19.902051 },
    { lat: 49.140046, lng: 19.901412 },
    { lat: 49.139971, lng: 19.901206 },
    { lat: 49.139898, lng: 19.90115 },
    { lat: 49.139836, lng: 19.901102 },
    { lat: 49.139623, lng: 19.900938 },
    { lat: 49.139065, lng: 19.900264 },
    { lat: 49.138706, lng: 19.899914 },
    { lat: 49.138373, lng: 19.899693 },
    { lat: 49.138278, lng: 19.899631 },
    { lat: 49.138246, lng: 19.89961 },
    { lat: 49.137998, lng: 19.89956 },
    { lat: 49.137756, lng: 19.899636 },
    { lat: 49.137728, lng: 19.899645 },
    { lat: 49.13746, lng: 19.899559 },
    { lat: 49.137455, lng: 19.899558 },
    { lat: 49.136792, lng: 19.899347 },
    { lat: 49.1367689, lng: 19.8993377 },
    { lat: 49.1367564, lng: 19.8993317 },
    { lat: 49.136746, lng: 19.899326 },
    { lat: 49.136649, lng: 19.899263 },
    { lat: 49.136481, lng: 19.899154 },
    { lat: 49.136243, lng: 19.898711 },
    { lat: 49.136125, lng: 19.898567 },
    { lat: 49.136051, lng: 19.898482 },
    { lat: 49.135492, lng: 19.897961 },
    { lat: 49.135412, lng: 19.897896 },
    { lat: 49.135187, lng: 19.897718 },
    { lat: 49.135171, lng: 19.897702 },
    { lat: 49.135107, lng: 19.897654 },
    { lat: 49.134936, lng: 19.897543 },
    { lat: 49.134825, lng: 19.897447 },
    { lat: 49.134666, lng: 19.897158 },
    { lat: 49.134574, lng: 19.896858 },
    { lat: 49.134527, lng: 19.896542 },
    { lat: 49.134454, lng: 19.896341 },
    { lat: 49.1343391, lng: 19.896223 },
    { lat: 49.1342932, lng: 19.8961979 },
    { lat: 49.13423, lng: 19.896174 },
    { lat: 49.134146, lng: 19.896151 },
    { lat: 49.133902, lng: 19.896207 },
    { lat: 49.133649, lng: 19.896328 },
    { lat: 49.133347, lng: 19.89645 },
    { lat: 49.133135, lng: 19.896526 },
    { lat: 49.132957, lng: 19.89655 },
    { lat: 49.132791, lng: 19.896547 },
    { lat: 49.132709, lng: 19.89654 },
    { lat: 49.132593, lng: 19.896519 },
    { lat: 49.132311, lng: 19.896381 },
    { lat: 49.13229, lng: 19.896367 },
    { lat: 49.132167, lng: 19.896282 },
    { lat: 49.131889, lng: 19.896077 },
    { lat: 49.131831, lng: 19.896029 },
    { lat: 49.13178, lng: 19.895986 },
    { lat: 49.131739, lng: 19.895929 },
    { lat: 49.131726, lng: 19.895903 },
    { lat: 49.131643, lng: 19.895728 },
    { lat: 49.131465, lng: 19.895314 },
    { lat: 49.131369, lng: 19.895088 },
    { lat: 49.131288, lng: 19.894896 },
    { lat: 49.131164, lng: 19.894954 },
    { lat: 49.130943, lng: 19.895048 },
    { lat: 49.130705, lng: 19.895853 },
    { lat: 49.130692, lng: 19.895895 },
    { lat: 49.130692, lng: 19.895905 },
    { lat: 49.130646, lng: 19.896826 },
    { lat: 49.130626, lng: 19.897256 },
    { lat: 49.130962, lng: 19.897676 },
    { lat: 49.13101, lng: 19.897735 },
    { lat: 49.131025, lng: 19.897755 },
    { lat: 49.131285, lng: 19.898084 },
    { lat: 49.131417, lng: 19.898252 },
    { lat: 49.131442, lng: 19.898284 },
    { lat: 49.13148, lng: 19.898333 },
    { lat: 49.131028, lng: 19.898898 },
    { lat: 49.130814, lng: 19.899075 },
    { lat: 49.130599, lng: 19.899485 },
    { lat: 49.12988, lng: 19.899309 },
    { lat: 49.129802, lng: 19.89924 },
    { lat: 49.129612, lng: 19.899073 },
    { lat: 49.129006, lng: 19.900314 },
    { lat: 49.128652, lng: 19.901154 },
    { lat: 49.12847, lng: 19.901585 },
    { lat: 49.128327, lng: 19.901909 },
    { lat: 49.127937, lng: 19.902794 },
    { lat: 49.127682, lng: 19.903537 },
    { lat: 49.127442, lng: 19.904233 },
    { lat: 49.127199, lng: 19.904699 },
    { lat: 49.126812, lng: 19.905439 },
    { lat: 49.12664, lng: 19.905498 },
    { lat: 49.125493, lng: 19.905893 },
    { lat: 49.125325, lng: 19.906196 },
    { lat: 49.124804, lng: 19.906991 },
    { lat: 49.124423, lng: 19.907622 },
    { lat: 49.12393, lng: 19.90844 },
    { lat: 49.123513, lng: 19.909139 },
    { lat: 49.123152, lng: 19.909746 },
    { lat: 49.12242, lng: 19.911098 },
    { lat: 49.121705, lng: 19.912468 },
    { lat: 49.121695, lng: 19.912487 },
    { lat: 49.121691, lng: 19.912492 },
    { lat: 49.121212, lng: 19.913312 },
    { lat: 49.121217, lng: 19.913315 },
    { lat: 49.121223, lng: 19.913318 },
    { lat: 49.122115, lng: 19.913884 },
    { lat: 49.122452, lng: 19.914098 },
    { lat: 49.122721, lng: 19.914269 },
    { lat: 49.12282, lng: 19.91433 },
    { lat: 49.122847, lng: 19.914347 },
    { lat: 49.122895, lng: 19.914376 },
    { lat: 49.124173, lng: 19.915164 },
    { lat: 49.124503, lng: 19.915057 },
    { lat: 49.124525, lng: 19.915049 },
    { lat: 49.124667, lng: 19.915003 },
    { lat: 49.124795, lng: 19.914961 },
    { lat: 49.125537, lng: 19.91472 },
    { lat: 49.125547, lng: 19.914717 },
    { lat: 49.125608, lng: 19.914697 },
    { lat: 49.12592, lng: 19.914632 },
    { lat: 49.126048, lng: 19.914605 },
    { lat: 49.126295, lng: 19.914554 },
    { lat: 49.126981, lng: 19.914411 },
    { lat: 49.128074, lng: 19.914706 },
    { lat: 49.129028, lng: 19.914748 },
    { lat: 49.129254, lng: 19.915386 },
    { lat: 49.129653, lng: 19.916513 },
    { lat: 49.130253, lng: 19.917025 },
    { lat: 49.130433, lng: 19.917179 },
    { lat: 49.131171, lng: 19.917602 },
    { lat: 49.131351, lng: 19.917705 },
    { lat: 49.131559, lng: 19.918036 },
    { lat: 49.131617, lng: 19.918128 },
    { lat: 49.131987, lng: 19.918717 },
    { lat: 49.132146, lng: 19.918972 },
    { lat: 49.132503, lng: 19.91917 },
    { lat: 49.132559, lng: 19.919206 },
    { lat: 49.132559, lng: 19.919207 },
    { lat: 49.132569, lng: 19.91928 },
    { lat: 49.132681, lng: 19.9192 },
    { lat: 49.133024, lng: 19.919397 },
    { lat: 49.133676, lng: 19.919982 },
    { lat: 49.133971, lng: 19.920582 },
    { lat: 49.134883, lng: 19.921904 },
    { lat: 49.13543, lng: 19.922496 },
    { lat: 49.136041, lng: 19.922711 },
    { lat: 49.13625, lng: 19.922756 },
    { lat: 49.137946, lng: 19.923269 },
    { lat: 49.138398, lng: 19.923264 },
    { lat: 49.13862, lng: 19.923318 },
    { lat: 49.138759, lng: 19.923405 },
    { lat: 49.13896, lng: 19.923507 },
    { lat: 49.139109, lng: 19.923606 },
    { lat: 49.139263, lng: 19.923848 },
    { lat: 49.1396, lng: 19.924594 },
    { lat: 49.139717, lng: 19.924716 },
    { lat: 49.139828, lng: 19.924735 },
    { lat: 49.140027, lng: 19.92473 },
    { lat: 49.140115, lng: 19.924781 },
    { lat: 49.140196, lng: 19.924863 },
    { lat: 49.140268, lng: 19.924935 },
    { lat: 49.140328, lng: 19.924995 },
    { lat: 49.140639, lng: 19.925451 },
    { lat: 49.1407206, lng: 19.9255419 },
    { lat: 49.140806, lng: 19.925637 },
    { lat: 49.141163, lng: 19.925935 },
    { lat: 49.1415232, lng: 19.9263632 },
    { lat: 49.14181, lng: 19.926704 },
    { lat: 49.1418678, lng: 19.9267925 },
    { lat: 49.14194, lng: 19.926903 },
    { lat: 49.142034, lng: 19.927125 },
    { lat: 49.142085, lng: 19.927339 },
    { lat: 49.1421165, lng: 19.9277039 },
    { lat: 49.142123, lng: 19.928798 },
    { lat: 49.1420762, lng: 19.9288774 },
    { lat: 49.141965, lng: 19.9296 },
    { lat: 49.142003, lng: 19.929781 },
    { lat: 49.142044, lng: 19.930262 },
    { lat: 49.1421621, lng: 19.9306208 },
    { lat: 49.142235, lng: 19.930949 },
    { lat: 49.1422148, lng: 19.9310178 },
    { lat: 49.142169, lng: 19.93178 },
    { lat: 49.142125, lng: 19.932484 },
    { lat: 49.142141, lng: 19.93383 },
    { lat: 49.142162, lng: 19.935218 },
    { lat: 49.141956, lng: 19.935879 },
    { lat: 49.141945, lng: 19.936202 },
    { lat: 49.141941, lng: 19.936573 },
    { lat: 49.141786, lng: 19.937129 },
    { lat: 49.141677, lng: 19.937618 },
    { lat: 49.141675, lng: 19.938258 },
    { lat: 49.14148, lng: 19.939198 },
    { lat: 49.141327, lng: 19.940353 },
    { lat: 49.141413, lng: 19.941198 },
    { lat: 49.141472, lng: 19.941635 },
    { lat: 49.141561, lng: 19.942534 },
    { lat: 49.141622, lng: 19.943011 },
    { lat: 49.141636, lng: 19.943249 },
    { lat: 49.141812, lng: 19.94379 },
    { lat: 49.141889, lng: 19.94457 },
    { lat: 49.142767, lng: 19.946947 },
    { lat: 49.143224, lng: 19.948771 },
    { lat: 49.143524, lng: 19.949569 },
    { lat: 49.143594, lng: 19.94988 },
    { lat: 49.144008, lng: 19.950417 },
    { lat: 49.144431, lng: 19.951524 },
    { lat: 49.144512, lng: 19.951806 },
    { lat: 49.144567, lng: 19.953111 },
    { lat: 49.144384, lng: 19.953721 },
    { lat: 49.143861, lng: 19.954723 },
    { lat: 49.143785, lng: 19.955373 },
    { lat: 49.143794, lng: 19.956071 },
    { lat: 49.143763, lng: 19.957036 },
    { lat: 49.143893, lng: 19.957998 },
    { lat: 49.144731, lng: 19.960057 },
    { lat: 49.144904, lng: 19.960392 },
    { lat: 49.1455, lng: 19.961283 },
    { lat: 49.146865, lng: 19.964424 },
    { lat: 49.146932, lng: 19.964561 },
    { lat: 49.149167, lng: 19.969888 },
    { lat: 49.149252, lng: 19.970102 },
    { lat: 49.149439, lng: 19.97009 },
    { lat: 49.149494, lng: 19.97035 },
    { lat: 49.149627, lng: 19.970346 },
    { lat: 49.149823, lng: 19.97005 },
    { lat: 49.149977, lng: 19.970021 },
    { lat: 49.150493, lng: 19.970738 },
    { lat: 49.150512, lng: 19.971017 },
    { lat: 49.150723, lng: 19.970919 },
    { lat: 49.151126, lng: 19.971215 },
    { lat: 49.151921, lng: 19.971317 },
    { lat: 49.152045, lng: 19.971787 },
    { lat: 49.152259, lng: 19.972247 },
    { lat: 49.152759, lng: 19.972996 },
    { lat: 49.152964, lng: 19.972945 },
    { lat: 49.153148, lng: 19.97347 },
    { lat: 49.153602, lng: 19.974572 },
    { lat: 49.153722, lng: 19.974863 },
    { lat: 49.154098, lng: 19.975698 },
    { lat: 49.154186, lng: 19.975898 },
    { lat: 49.154436, lng: 19.976353 },
    { lat: 49.155266, lng: 19.977453 },
    { lat: 49.155895, lng: 19.977922 },
    { lat: 49.155992, lng: 19.978088 },
    { lat: 49.156539, lng: 19.979014 },
    { lat: 49.156673, lng: 19.980114 },
    { lat: 49.156601, lng: 19.980304 },
    { lat: 49.156696, lng: 19.980668 },
    { lat: 49.156736, lng: 19.981028 },
    { lat: 49.156857, lng: 19.981235 },
    { lat: 49.156865, lng: 19.981435 },
    { lat: 49.156951, lng: 19.981764 },
    { lat: 49.1569, lng: 19.981937 },
    { lat: 49.156947, lng: 19.982583 },
    { lat: 49.157027, lng: 19.982823 },
    { lat: 49.157044, lng: 19.983234 },
    { lat: 49.157134, lng: 19.983727 },
    { lat: 49.157233, lng: 19.98486 },
    { lat: 49.157387, lng: 19.985479 },
    { lat: 49.157658, lng: 19.985829 },
    { lat: 49.157929, lng: 19.986008 },
    { lat: 49.157981, lng: 19.98617 },
    { lat: 49.158305, lng: 19.986539 },
    { lat: 49.158359, lng: 19.986705 },
    { lat: 49.158325, lng: 19.987192 },
    { lat: 49.158343, lng: 19.987576 },
    { lat: 49.158369, lng: 19.989186 },
    { lat: 49.158345, lng: 19.989918 },
    { lat: 49.15834, lng: 19.990197 },
    { lat: 49.158397, lng: 19.990606 },
    { lat: 49.15854, lng: 19.991068 },
    { lat: 49.158774, lng: 19.991724 },
    { lat: 49.15911, lng: 19.992411 },
    { lat: 49.15944, lng: 19.993049 },
    { lat: 49.159589, lng: 19.993318 },
    { lat: 49.159653, lng: 19.993583 },
    { lat: 49.159751, lng: 19.99432 },
    { lat: 49.159869, lng: 19.994634 },
    { lat: 49.159979, lng: 19.994804 },
    { lat: 49.160395, lng: 19.99545 },
    { lat: 49.160674, lng: 19.996026 },
    { lat: 49.160742, lng: 19.996475 },
    { lat: 49.161044, lng: 19.996887 },
    { lat: 49.162818, lng: 20.000131 },
    { lat: 49.157335, lng: 19.996509 },
    { lat: 49.156475, lng: 19.991934 },
    { lat: 49.156304, lng: 19.991591 },
    { lat: 49.156171, lng: 19.991176 },
    { lat: 49.156053, lng: 19.990656 },
    { lat: 49.155434, lng: 19.989811 },
    { lat: 49.155019, lng: 19.989239 },
    { lat: 49.154838, lng: 19.988929 },
    { lat: 49.154658, lng: 19.988451 },
    { lat: 49.154501, lng: 19.988218 },
    { lat: 49.154244, lng: 19.987804 },
    { lat: 49.153736, lng: 19.987342 },
    { lat: 49.153435, lng: 19.986979 },
    { lat: 49.153166, lng: 19.986518 },
    { lat: 49.152747, lng: 19.985921 },
    { lat: 49.152617, lng: 19.98585 },
    { lat: 49.152296, lng: 19.98563 },
    { lat: 49.152056, lng: 19.985639 },
    { lat: 49.151933, lng: 19.985664 },
    { lat: 49.151756, lng: 19.985784 },
    { lat: 49.151558, lng: 19.985811 },
    { lat: 49.151109, lng: 19.985913 },
    { lat: 49.150714, lng: 19.986107 },
    { lat: 49.150431, lng: 19.986135 },
    { lat: 49.150336, lng: 19.985977 },
    { lat: 49.150251, lng: 19.985872 },
    { lat: 49.150111, lng: 19.98591 },
    { lat: 49.149891, lng: 19.986052 },
    { lat: 49.149444, lng: 19.986057 },
    { lat: 49.148891, lng: 19.985907 },
    { lat: 49.148806, lng: 19.985854 },
    { lat: 49.148708, lng: 19.985649 },
    { lat: 49.148503, lng: 19.985381 },
    { lat: 49.148427, lng: 19.98514 },
    { lat: 49.148364, lng: 19.985058 },
    { lat: 49.14832, lng: 19.985067 },
    { lat: 49.148147, lng: 19.984918 },
    { lat: 49.148039, lng: 19.984876 },
    { lat: 49.147687, lng: 19.984417 },
    { lat: 49.147524, lng: 19.984068 },
    { lat: 49.147233, lng: 19.983769 },
    { lat: 49.147208, lng: 19.983544 },
    { lat: 49.147055, lng: 19.983419 },
    { lat: 49.146839, lng: 19.983196 },
    { lat: 49.146684, lng: 19.983009 },
    { lat: 49.14646, lng: 19.982996 },
    { lat: 49.14595, lng: 19.983018 },
    { lat: 49.145479, lng: 19.982798 },
    { lat: 49.1453, lng: 19.982766 },
    { lat: 49.145174, lng: 19.982729 },
    { lat: 49.144902, lng: 19.982412 },
    { lat: 49.144709, lng: 19.982132 },
    { lat: 49.144562, lng: 19.982032 },
    { lat: 49.144483, lng: 19.98189 },
    { lat: 49.144415, lng: 19.981707 },
    { lat: 49.144262, lng: 19.98155 },
    { lat: 49.144221, lng: 19.981542 },
    { lat: 49.143954, lng: 19.981371 },
    { lat: 49.14381, lng: 19.981269 },
    { lat: 49.143632, lng: 19.981306 },
    { lat: 49.143372, lng: 19.981364 },
    { lat: 49.143248, lng: 19.981375 },
    { lat: 49.143095, lng: 19.98143 },
    { lat: 49.142932, lng: 19.981483 },
    { lat: 49.142848, lng: 19.981554 },
    { lat: 49.142742, lng: 19.981499 },
    { lat: 49.142631, lng: 19.981513 },
    { lat: 49.142515, lng: 19.981622 },
    { lat: 49.142413, lng: 19.981629 },
    { lat: 49.142285, lng: 19.981555 },
    { lat: 49.142028, lng: 19.981673 },
    { lat: 49.141699, lng: 19.981797 },
    { lat: 49.141366, lng: 19.981808 },
    { lat: 49.141231, lng: 19.981677 },
    { lat: 49.140786, lng: 19.981261 },
    { lat: 49.140516, lng: 19.980937 },
    { lat: 49.140207, lng: 19.980318 },
    { lat: 49.139579, lng: 19.979571 },
    { lat: 49.139725, lng: 19.978507 },
    { lat: 49.139965, lng: 19.978177 },
    { lat: 49.139933, lng: 19.977692 },
    { lat: 49.140074, lng: 19.977468 },
    { lat: 49.140192, lng: 19.977088 },
    { lat: 49.140297, lng: 19.97579 },
    { lat: 49.14027, lng: 19.975254 },
    { lat: 49.139958, lng: 19.974131 },
    { lat: 49.139763, lng: 19.973123 },
    { lat: 49.13949, lng: 19.972688 },
    { lat: 49.139252, lng: 19.971878 },
    { lat: 49.139435, lng: 19.970794 },
    { lat: 49.139739, lng: 19.969984 },
    { lat: 49.13972, lng: 19.969083 },
    { lat: 49.139728, lng: 19.968526 },
    { lat: 49.139648, lng: 19.967521 },
    { lat: 49.139495, lng: 19.967149 },
    { lat: 49.139487, lng: 19.966887 },
    { lat: 49.139544, lng: 19.966503 },
    { lat: 49.139536, lng: 19.966298 },
    { lat: 49.139537, lng: 19.966045 },
    { lat: 49.139283, lng: 19.965268 },
    { lat: 49.139261, lng: 19.96517 },
    { lat: 49.139227, lng: 19.96501 },
    { lat: 49.139179, lng: 19.964793 },
    { lat: 49.139106, lng: 19.964286 },
    { lat: 49.138998, lng: 19.963982 },
    { lat: 49.138693, lng: 19.96385 },
    { lat: 49.138449, lng: 19.963734 },
    { lat: 49.138171, lng: 19.963514 },
    { lat: 49.137947, lng: 19.963207 },
    { lat: 49.137563, lng: 19.962158 },
    { lat: 49.137368, lng: 19.961739 },
    { lat: 49.137172, lng: 19.961528 },
    { lat: 49.136987, lng: 19.961244 },
    { lat: 49.136598, lng: 19.961207 },
    { lat: 49.136359, lng: 19.960943 },
    { lat: 49.135762, lng: 19.960441 },
    { lat: 49.135001, lng: 19.959678 },
    { lat: 49.134805, lng: 19.959224 },
    { lat: 49.134566, lng: 19.958989 },
    { lat: 49.13433, lng: 19.958878 },
    { lat: 49.134057, lng: 19.95864 },
    { lat: 49.133449, lng: 19.957825 },
    { lat: 49.132651, lng: 19.956808 },
    { lat: 49.132069, lng: 19.956229 },
    { lat: 49.131636, lng: 19.955877 },
    { lat: 49.131534, lng: 19.955826 },
    { lat: 49.131571, lng: 19.955799 },
    { lat: 49.131535, lng: 19.955549 },
    { lat: 49.13159, lng: 19.955386 },
    { lat: 49.131384, lng: 19.955244 },
    { lat: 49.13138, lng: 19.954933 },
    { lat: 49.131112, lng: 19.954476 },
    { lat: 49.130927, lng: 19.9545 },
    { lat: 49.13095, lng: 19.954021 },
    { lat: 49.130892, lng: 19.95391 },
    { lat: 49.130818, lng: 19.953815 },
    { lat: 49.130788, lng: 19.953759 },
    { lat: 49.130639, lng: 19.953432 },
    { lat: 49.1306, lng: 19.953345 },
    { lat: 49.130387, lng: 19.953067 },
    { lat: 49.130351, lng: 19.953063 },
    { lat: 49.130301, lng: 19.953071 },
    { lat: 49.130231, lng: 19.95308 },
    { lat: 49.130194, lng: 19.953074 },
    { lat: 49.13017, lng: 19.953051 },
    { lat: 49.130145, lng: 19.952994 },
    { lat: 49.130125, lng: 19.952893 },
    { lat: 49.129957, lng: 19.952651 },
    { lat: 49.129794, lng: 19.952592 },
    { lat: 49.129777, lng: 19.952151 },
    { lat: 49.129638, lng: 19.952104 },
    { lat: 49.129462, lng: 19.951707 },
    { lat: 49.129318, lng: 19.951458 },
    { lat: 49.129082, lng: 19.95109 },
    { lat: 49.128814, lng: 19.950711 },
    { lat: 49.128545, lng: 19.950402 },
    { lat: 49.128303, lng: 19.950216 },
    { lat: 49.128124, lng: 19.950001 },
    { lat: 49.127988, lng: 19.949763 },
    { lat: 49.127858, lng: 19.949435 },
    { lat: 49.127756, lng: 19.949168 },
    { lat: 49.127633, lng: 19.948937 },
    { lat: 49.127483, lng: 19.948699 },
    { lat: 49.127296, lng: 19.948574 },
    { lat: 49.126992, lng: 19.948483 },
    { lat: 49.126315, lng: 19.948222 },
    { lat: 49.126107, lng: 19.948007 },
    { lat: 49.125934, lng: 19.947862 },
    { lat: 49.125806, lng: 19.94771 },
    { lat: 49.12565, lng: 19.947625 },
    { lat: 49.125443, lng: 19.947587 },
    { lat: 49.125142, lng: 19.947585 },
    { lat: 49.124785, lng: 19.947632 },
    { lat: 49.124039, lng: 19.94788 },
    { lat: 49.123758, lng: 19.947948 },
    { lat: 49.123275, lng: 19.948042 },
    { lat: 49.122774, lng: 19.94837 },
    { lat: 49.122654, lng: 19.948406 },
    { lat: 49.122581, lng: 19.948394 },
    { lat: 49.122332, lng: 19.948233 },
    { lat: 49.122244, lng: 19.948206 },
    { lat: 49.122134, lng: 19.948232 },
    { lat: 49.121595, lng: 19.948297 },
    { lat: 49.121502, lng: 19.948295 },
    { lat: 49.121396, lng: 19.948171 },
    { lat: 49.121339, lng: 19.948115 },
    { lat: 49.121152, lng: 19.948027 },
    { lat: 49.121012, lng: 19.947906 },
    { lat: 49.120781, lng: 19.947667 },
    { lat: 49.12063, lng: 19.947593 },
    { lat: 49.120521, lng: 19.947499 },
    { lat: 49.12051, lng: 19.947407 },
    { lat: 49.12051, lng: 19.947382 },
    { lat: 49.120254, lng: 19.947376 },
    { lat: 49.120227, lng: 19.947614 },
    { lat: 49.119928, lng: 19.947752 },
    { lat: 49.119468, lng: 19.947518 },
    { lat: 49.119327, lng: 19.9478 },
    { lat: 49.119204, lng: 19.947904 },
    { lat: 49.118933, lng: 19.947967 },
    { lat: 49.118706, lng: 19.948303 },
    { lat: 49.118549, lng: 19.948271 },
    { lat: 49.118383, lng: 19.948104 },
    { lat: 49.11796, lng: 19.948387 },
    { lat: 49.117828, lng: 19.948516 },
    { lat: 49.117888, lng: 19.94874 },
    { lat: 49.117829, lng: 19.949452 },
    { lat: 49.117323, lng: 19.949942 },
    { lat: 49.117206, lng: 19.950264 },
    { lat: 49.116801, lng: 19.950636 },
    { lat: 49.116607, lng: 19.950845 },
    { lat: 49.116124, lng: 19.9506 },
    { lat: 49.115968, lng: 19.950552 },
    { lat: 49.115627, lng: 19.951061 },
    { lat: 49.115408, lng: 19.951004 },
    { lat: 49.115097, lng: 19.951203 },
    { lat: 49.114852, lng: 19.951364 },
    { lat: 49.11475, lng: 19.95148 },
    { lat: 49.114768, lng: 19.951527 },
    { lat: 49.114959, lng: 19.952081 },
    { lat: 49.114713, lng: 19.952044 },
    { lat: 49.114286, lng: 19.952035 },
    { lat: 49.113994, lng: 19.952073 },
    { lat: 49.113554, lng: 19.952107 },
    { lat: 49.1131733, lng: 19.9520814 },
    { lat: 49.113034, lng: 19.952072 },
    { lat: 49.11262, lng: 19.952128 },
    { lat: 49.112201, lng: 19.952124 },
    { lat: 49.112129, lng: 19.95203 },
    { lat: 49.111839, lng: 19.952237 },
    { lat: 49.111218, lng: 19.952317 },
    { lat: 49.111015, lng: 19.952403 },
    { lat: 49.110757, lng: 19.952463 },
    { lat: 49.110581, lng: 19.952443 },
    { lat: 49.11047, lng: 19.952481 },
    { lat: 49.110129, lng: 19.952418 },
    { lat: 49.110063, lng: 19.952431 },
    { lat: 49.110048, lng: 19.952446 },
    { lat: 49.109692, lng: 19.95282 },
    { lat: 49.109618, lng: 19.952896 },
    { lat: 49.109272, lng: 19.952719 },
    { lat: 49.10927, lng: 19.952787 },
    { lat: 49.10926, lng: 19.953165 },
    { lat: 49.109258, lng: 19.953225 },
    { lat: 49.109571, lng: 19.953951 },
    { lat: 49.109997, lng: 19.954746 },
    { lat: 49.110216, lng: 19.955153 },
    { lat: 49.110243, lng: 19.955449 },
    { lat: 49.11032, lng: 19.956295 },
    { lat: 49.110325, lng: 19.956781 },
    { lat: 49.110327, lng: 19.957072 },
    { lat: 49.110521, lng: 19.956894 },
    { lat: 49.110665, lng: 19.956761 },
    { lat: 49.110698, lng: 19.956731 },
    { lat: 49.110866, lng: 19.956531 },
    { lat: 49.110911, lng: 19.956518 },
    { lat: 49.111383, lng: 19.95639 },
    { lat: 49.111618, lng: 19.955513 },
    { lat: 49.111928, lng: 19.955692 },
    { lat: 49.112219, lng: 19.955858 },
    { lat: 49.112291, lng: 19.956034 },
    { lat: 49.112299, lng: 19.956055 },
    { lat: 49.112309, lng: 19.95608 },
    { lat: 49.112405, lng: 19.956314 },
    { lat: 49.112169, lng: 19.956354 },
    { lat: 49.111842, lng: 19.956409 },
    { lat: 49.111449, lng: 19.95758 },
    { lat: 49.111215, lng: 19.960076 },
    { lat: 49.111563, lng: 19.960336 },
    { lat: 49.111814, lng: 19.960525 },
    { lat: 49.112047, lng: 19.96176 },
    { lat: 49.112267, lng: 19.962927 },
    { lat: 49.112486, lng: 19.963192 },
    { lat: 49.112761, lng: 19.963524 },
    { lat: 49.112647, lng: 19.963865 },
    { lat: 49.112619, lng: 19.963949 },
    { lat: 49.113258, lng: 19.964618 },
    { lat: 49.113442, lng: 19.964704 },
    { lat: 49.113923, lng: 19.964929 },
    { lat: 49.113926, lng: 19.964931 },
    { lat: 49.114179, lng: 19.965567 },
    { lat: 49.114318, lng: 19.965916 },
    { lat: 49.114385, lng: 19.966085 },
    { lat: 49.114437, lng: 19.966217 },
    { lat: 49.114628, lng: 19.965814 },
    { lat: 49.114963, lng: 19.965262 },
    { lat: 49.114872, lng: 19.964711 },
    { lat: 49.11486, lng: 19.964637 },
    { lat: 49.114998, lng: 19.964424 },
    { lat: 49.115086, lng: 19.964505 },
    { lat: 49.115094, lng: 19.964512 },
    { lat: 49.115353, lng: 19.964748 },
    { lat: 49.115583, lng: 19.96463 },
    { lat: 49.115712, lng: 19.964699 },
    { lat: 49.11605, lng: 19.96488 },
    { lat: 49.116238, lng: 19.964982 },
    { lat: 49.116445, lng: 19.965305 },
    { lat: 49.117044, lng: 19.966245 },
    { lat: 49.117598, lng: 19.966566 },
    { lat: 49.117574, lng: 19.96679 },
    { lat: 49.117512, lng: 19.967392 },
    { lat: 49.117674, lng: 19.967838 },
    { lat: 49.117612, lng: 19.968008 },
    { lat: 49.11739, lng: 19.96861 },
    { lat: 49.117444, lng: 19.969016 },
    { lat: 49.117496, lng: 19.969152 },
    { lat: 49.117671, lng: 19.969608 },
    { lat: 49.117717, lng: 19.969988 },
    { lat: 49.117771, lng: 19.970432 },
    { lat: 49.117826, lng: 19.970884 },
    { lat: 49.117783, lng: 19.971173 },
    { lat: 49.117706, lng: 19.971693 },
    { lat: 49.117493, lng: 19.971679 },
    { lat: 49.116919, lng: 19.971641 },
    { lat: 49.116842, lng: 19.971636 },
    { lat: 49.1168, lng: 19.971633 },
    { lat: 49.116689, lng: 19.971626 },
    { lat: 49.116619, lng: 19.971621 },
    { lat: 49.116144, lng: 19.971898 },
    { lat: 49.115814, lng: 19.972091 },
    { lat: 49.114631, lng: 19.971793 },
    { lat: 49.114504, lng: 19.972021 },
    { lat: 49.114425, lng: 19.972163 },
    { lat: 49.114222, lng: 19.972596 },
    { lat: 49.114222, lng: 19.972597 },
    { lat: 49.112711, lng: 19.971343 },
    { lat: 49.112633, lng: 19.971909 },
    { lat: 49.11261, lng: 19.97207 },
    { lat: 49.112603, lng: 19.972082 },
    { lat: 49.112427, lng: 19.972347 },
    { lat: 49.112035, lng: 19.972358 },
    { lat: 49.111982, lng: 19.972399 },
    { lat: 49.111865, lng: 19.972489 },
    { lat: 49.111723, lng: 19.972693 },
    { lat: 49.111496, lng: 19.973016 },
    { lat: 49.111353, lng: 19.972936 },
    { lat: 49.110701, lng: 19.972569 },
    { lat: 49.11063, lng: 19.972756 },
    { lat: 49.110538, lng: 19.972861 },
    { lat: 49.110473, lng: 19.972937 },
    { lat: 49.11044, lng: 19.972965 },
    { lat: 49.110287, lng: 19.973095 },
    { lat: 49.109954, lng: 19.973903 },
    { lat: 49.109563, lng: 19.973831 },
    { lat: 49.109437, lng: 19.973948 },
    { lat: 49.108942, lng: 19.974859 },
    { lat: 49.108957, lng: 19.974943 },
    { lat: 49.109001, lng: 19.975197 },
    { lat: 49.108761, lng: 19.975478 },
    { lat: 49.108658, lng: 19.975311 },
    { lat: 49.10852, lng: 19.97509 },
    { lat: 49.108345, lng: 19.975405 },
    { lat: 49.108275, lng: 19.97553 },
    { lat: 49.108392, lng: 19.975746 },
    { lat: 49.108377, lng: 19.976248 },
    { lat: 49.10838, lng: 19.976286 },
    { lat: 49.108416, lng: 19.97672 },
    { lat: 49.108297, lng: 19.977 },
    { lat: 49.108292, lng: 19.977211 },
    { lat: 49.108291, lng: 19.977267 },
    { lat: 49.108053, lng: 19.977797 },
    { lat: 49.107953, lng: 19.977961 },
    { lat: 49.107883, lng: 19.978162 },
    { lat: 49.107877, lng: 19.978265 },
    { lat: 49.107839, lng: 19.978835 },
    { lat: 49.107548, lng: 19.978928 },
    { lat: 49.107591, lng: 19.979397 },
    { lat: 49.107624, lng: 19.980083 },
    { lat: 49.107645, lng: 19.980542 },
    { lat: 49.107636, lng: 19.981242 },
    { lat: 49.107634, lng: 19.981342 },
    { lat: 49.107662, lng: 19.981984 },
    { lat: 49.107716, lng: 19.982105 },
    { lat: 49.107748, lng: 19.982179 },
    { lat: 49.107896, lng: 19.982517 },
    { lat: 49.107903, lng: 19.982585 },
    { lat: 49.107982, lng: 19.983302 },
    { lat: 49.108019, lng: 19.983404 },
    { lat: 49.108118, lng: 19.983679 },
    { lat: 49.108099, lng: 19.984037 },
    { lat: 49.108016, lng: 19.984569 },
    { lat: 49.107952, lng: 19.984663 },
    { lat: 49.107941, lng: 19.984679 },
    { lat: 49.108031, lng: 19.984745 },
    { lat: 49.108245, lng: 19.984902 },
    { lat: 49.108222, lng: 19.985429 },
    { lat: 49.108224, lng: 19.985915 },
    { lat: 49.108197, lng: 19.986128 },
    { lat: 49.108142, lng: 19.986546 },
    { lat: 49.108198, lng: 19.986878 },
    { lat: 49.108201, lng: 19.986899 },
    { lat: 49.108205, lng: 19.986909 },
    { lat: 49.108407, lng: 19.987458 },
    { lat: 49.108514, lng: 19.987709 },
    { lat: 49.10863, lng: 19.987981 },
    { lat: 49.108809, lng: 19.98835 },
    { lat: 49.108903, lng: 19.988333 },
    { lat: 49.109096, lng: 19.988299 },
    { lat: 49.109182, lng: 19.988444 },
    { lat: 49.109343, lng: 19.988715 },
    { lat: 49.109354, lng: 19.988818 },
    { lat: 49.109475, lng: 19.989912 },
    { lat: 49.109542, lng: 19.990521 },
    { lat: 49.109492, lng: 19.990627 },
    { lat: 49.108902, lng: 19.991891 },
    { lat: 49.108876, lng: 19.991946 },
    { lat: 49.108578, lng: 19.992583 },
    { lat: 49.108323, lng: 19.992872 },
    { lat: 49.107837, lng: 19.992818 },
    { lat: 49.10749, lng: 19.993075 },
    { lat: 49.107391, lng: 19.993017 },
    { lat: 49.10653, lng: 19.992523 },
    { lat: 49.106327, lng: 19.991726 },
    { lat: 49.106312, lng: 19.991667 },
    { lat: 49.106245, lng: 19.991405 },
    { lat: 49.106166, lng: 19.991097 },
    { lat: 49.105359, lng: 19.988902 },
    { lat: 49.105359, lng: 19.988903 },
    { lat: 49.10533, lng: 19.989794 },
    { lat: 49.105248, lng: 19.991189 },
    { lat: 49.105248, lng: 19.991205 },
    { lat: 49.105245, lng: 19.99125 },
    { lat: 49.105072, lng: 19.994348 },
    { lat: 49.105066, lng: 19.994398 },
    { lat: 49.105034, lng: 19.994655 },
    { lat: 49.10499, lng: 19.995487 },
    { lat: 49.104964, lng: 19.995969 },
    { lat: 49.104922, lng: 19.996755 },
    { lat: 49.104919, lng: 19.996794 },
    { lat: 49.104916, lng: 19.996849 },
    { lat: 49.104909, lng: 19.996977 },
    { lat: 49.104901, lng: 19.997116 },
    { lat: 49.104713, lng: 19.998831 },
    { lat: 49.104553, lng: 20.000294 },
    { lat: 49.10455, lng: 20.000316 },
    { lat: 49.10442, lng: 20.002604 },
    { lat: 49.104417, lng: 20.00266 },
    { lat: 49.104331, lng: 20.00417 },
    { lat: 49.104156, lng: 20.004281 },
    { lat: 49.10366, lng: 20.004597 },
    { lat: 49.104399, lng: 20.005371 },
    { lat: 49.104469, lng: 20.005322 },
    { lat: 49.104695, lng: 20.005163 },
    { lat: 49.104695, lng: 20.005162 },
    { lat: 49.104882, lng: 20.005477 },
    { lat: 49.105259, lng: 20.006113 },
    { lat: 49.105367, lng: 20.006071 },
    { lat: 49.105993, lng: 20.005825 },
    { lat: 49.106107, lng: 20.005834 },
    { lat: 49.106352, lng: 20.005856 },
    { lat: 49.106409, lng: 20.005862 },
    { lat: 49.106403, lng: 20.006099 },
    { lat: 49.106397, lng: 20.006361 },
    { lat: 49.106293, lng: 20.006956 },
    { lat: 49.106022, lng: 20.007237 },
    { lat: 49.106002, lng: 20.007258 },
    { lat: 49.105896, lng: 20.00754 },
    { lat: 49.106091, lng: 20.007869 },
    { lat: 49.106257, lng: 20.008149 },
    { lat: 49.106366, lng: 20.008758 },
    { lat: 49.106431, lng: 20.008813 },
    { lat: 49.106747, lng: 20.00966 },
    { lat: 49.106942, lng: 20.010161 },
    { lat: 49.107171, lng: 20.010752 },
    { lat: 49.107342, lng: 20.01164 },
    { lat: 49.106699, lng: 20.011339 },
    { lat: 49.106239, lng: 20.011067 },
    { lat: 49.106087, lng: 20.011202 },
    { lat: 49.106262, lng: 20.012118 },
    { lat: 49.106437, lng: 20.012834 },
    { lat: 49.106509, lng: 20.01313 },
    { lat: 49.106259, lng: 20.015086 },
    { lat: 49.106115, lng: 20.015781 },
    { lat: 49.106113, lng: 20.016276 },
    { lat: 49.106113, lng: 20.016324 },
    { lat: 49.106113, lng: 20.016472 },
    { lat: 49.106111, lng: 20.016674 },
    { lat: 49.106002, lng: 20.016783 },
    { lat: 49.106019, lng: 20.01699 },
    { lat: 49.10607, lng: 20.017602 },
    { lat: 49.106368, lng: 20.017694 },
    { lat: 49.10736, lng: 20.017273 },
    { lat: 49.107608, lng: 20.016862 },
    { lat: 49.107626, lng: 20.016833 },
    { lat: 49.107818, lng: 20.016783 },
    { lat: 49.107908, lng: 20.017132 },
    { lat: 49.107951, lng: 20.017299 },
    { lat: 49.108037, lng: 20.018122 },
    { lat: 49.108491, lng: 20.018355 },
    { lat: 49.108605, lng: 20.019272 },
    { lat: 49.108628, lng: 20.019625 },
    { lat: 49.108637, lng: 20.019865 },
    { lat: 49.108652, lng: 20.020293 },
    { lat: 49.108875, lng: 20.020398 },
    { lat: 49.10905, lng: 20.020385 },
    { lat: 49.109272, lng: 20.020369 },
    { lat: 49.109743, lng: 20.020514 },
    { lat: 49.109777, lng: 20.020525 },
    { lat: 49.109814, lng: 20.0208 },
    { lat: 49.109632, lng: 20.02125 },
    { lat: 49.109339, lng: 20.021778 },
    { lat: 49.109376, lng: 20.02198 },
    { lat: 49.109429, lng: 20.022269 },
    { lat: 49.1097, lng: 20.022397 },
    { lat: 49.109848, lng: 20.022445 },
    { lat: 49.110073, lng: 20.022518 },
    { lat: 49.110079, lng: 20.022538 },
    { lat: 49.110099, lng: 20.022616 },
    { lat: 49.110128, lng: 20.022728 },
    { lat: 49.11014, lng: 20.022772 },
    { lat: 49.110164, lng: 20.02286 },
    { lat: 49.110191, lng: 20.022961 },
    { lat: 49.110297, lng: 20.02373 },
    { lat: 49.110192, lng: 20.024192 },
    { lat: 49.110407, lng: 20.025887 },
    { lat: 49.110985, lng: 20.027094 },
    { lat: 49.11098, lng: 20.027449 },
    { lat: 49.110969, lng: 20.028158 },
    { lat: 49.11079, lng: 20.029022 },
    { lat: 49.110596, lng: 20.02894 },
    { lat: 49.109775, lng: 20.028591 },
    { lat: 49.108798, lng: 20.028165 },
    { lat: 49.108581, lng: 20.028152 },
    { lat: 49.108932, lng: 20.02876 },
    { lat: 49.109108, lng: 20.028764 },
    { lat: 49.109462, lng: 20.028885 },
    { lat: 49.110222, lng: 20.029145 },
    { lat: 49.11005, lng: 20.029907 },
    { lat: 49.110012, lng: 20.030075 },
    { lat: 49.110017, lng: 20.030757 },
    { lat: 49.109858, lng: 20.031639 },
    { lat: 49.109773, lng: 20.032416 },
    { lat: 49.109829, lng: 20.032928 },
    { lat: 49.109994, lng: 20.033945 },
    { lat: 49.109995, lng: 20.033958 },
    { lat: 49.110056, lng: 20.034099 },
    { lat: 49.110354, lng: 20.034786 },
    { lat: 49.110413, lng: 20.03511 },
    { lat: 49.110195, lng: 20.034943 },
    { lat: 49.110195, lng: 20.034944 },
    { lat: 49.11014, lng: 20.035196 },
    { lat: 49.110097, lng: 20.035393 },
    { lat: 49.110036, lng: 20.035418 },
    { lat: 49.109941, lng: 20.035458 },
    { lat: 49.10974, lng: 20.035627 },
    { lat: 49.109573, lng: 20.035767 },
    { lat: 49.108856, lng: 20.036077 },
    { lat: 49.108948, lng: 20.036401 },
    { lat: 49.108873, lng: 20.036432 },
    { lat: 49.108695, lng: 20.036608 },
    { lat: 49.108669, lng: 20.036674 },
    { lat: 49.108487, lng: 20.037124 },
    { lat: 49.108439, lng: 20.03729 },
    { lat: 49.108391, lng: 20.037451 },
    { lat: 49.108486, lng: 20.037488 },
    { lat: 49.1084437, lng: 20.0378252 },
    { lat: 49.1090694, lng: 20.0381419 },
    { lat: 49.1099343, lng: 20.0384386 },
    { lat: 49.110758, lng: 20.0392716 },
    { lat: 49.1112336, lng: 20.0401264 },
    { lat: 49.1131677, lng: 20.0418048 },
    { lat: 49.1145518, lng: 20.0422157 },
    { lat: 49.1170354, lng: 20.0420007 },
    { lat: 49.1191138, lng: 20.0420965 },
    { lat: 49.1207337, lng: 20.0421011 },
    { lat: 49.1217821, lng: 20.0421041 },
    { lat: 49.1261304, lng: 20.0415792 },
    { lat: 49.1287672, lng: 20.0390349 },
    { lat: 49.1299864, lng: 20.0375702 },
    { lat: 49.1319729, lng: 20.0353222 },
    { lat: 49.132305, lng: 20.0348549 },
    { lat: 49.1331722, lng: 20.0330446 },
    { lat: 49.1333572, lng: 20.0332161 },
    { lat: 49.133718, lng: 20.033255 },
    { lat: 49.134003, lng: 20.0334464 },
    { lat: 49.1341593, lng: 20.0333631 },
    { lat: 49.134353, lng: 20.0334122 },
    { lat: 49.1347862, lng: 20.033624 },
    { lat: 49.1353762, lng: 20.0336453 },
    { lat: 49.1356841, lng: 20.0335749 },
    { lat: 49.135947, lng: 20.0338321 },
    { lat: 49.136086, lng: 20.0337055 },
    { lat: 49.1362912, lng: 20.0336337 },
    { lat: 49.1363568, lng: 20.033421 },
    { lat: 49.136429, lng: 20.0333706 },
    { lat: 49.1370825, lng: 20.0333757 },
    { lat: 49.1373829, lng: 20.033283 },
    { lat: 49.1378838, lng: 20.0336638 },
    { lat: 49.1389407, lng: 20.0346592 },
    { lat: 49.1390876, lng: 20.0348901 },
    { lat: 49.1393531, lng: 20.0350438 },
    { lat: 49.1399683, lng: 20.0350271 },
    { lat: 49.1401143, lng: 20.0352088 },
    { lat: 49.1404689, lng: 20.0354231 },
    { lat: 49.140951, lng: 20.0354227 },
    { lat: 49.1412352, lng: 20.035297 },
    { lat: 49.1422797, lng: 20.0351465 },
    { lat: 49.1425825, lng: 20.0353078 },
    { lat: 49.142769, lng: 20.0352688 },
    { lat: 49.1429433, lng: 20.0350409 },
    { lat: 49.1428823, lng: 20.0347061 },
    { lat: 49.1429667, lng: 20.0344858 },
    { lat: 49.1432839, lng: 20.033958 },
    { lat: 49.1438884, lng: 20.0336523 },
    { lat: 49.1440923, lng: 20.0337605 },
    { lat: 49.1442824, lng: 20.0335913 },
    { lat: 49.1443901, lng: 20.0335817 },
    { lat: 49.1446831, lng: 20.0332806 },
    { lat: 49.1448306, lng: 20.0332528 },
    { lat: 49.1452307, lng: 20.032903 },
    { lat: 49.1455785, lng: 20.0322712 },
    { lat: 49.1457287, lng: 20.0321985 },
    { lat: 49.1457604, lng: 20.0319019 },
    { lat: 49.1456912, lng: 20.03107 },
    { lat: 49.1456009, lng: 20.030625 },
    { lat: 49.1457885, lng: 20.0304764 },
    { lat: 49.145813, lng: 20.0301992 },
    { lat: 49.1459068, lng: 20.0302086 },
    { lat: 49.1459825, lng: 20.0299664 },
    { lat: 49.1461754, lng: 20.0297274 },
    { lat: 49.1461213, lng: 20.0293998 },
    { lat: 49.1466264, lng: 20.0289709 },
    { lat: 49.1468283, lng: 20.0285738 },
    { lat: 49.1473004, lng: 20.0283286 },
    { lat: 49.1477327, lng: 20.0279426 },
    { lat: 49.1479497, lng: 20.0280146 },
    { lat: 49.1484087, lng: 20.0277555 },
    { lat: 49.1486536, lng: 20.0277429 },
    { lat: 49.1490574, lng: 20.0274854 },
    { lat: 49.1493112, lng: 20.0272172 },
    { lat: 49.1503003, lng: 20.0268976 },
    { lat: 49.1516954, lng: 20.0260966 },
    { lat: 49.1525999, lng: 20.0255904 },
    { lat: 49.1551251, lng: 20.0253946 },
    { lat: 49.160108, lng: 20.0258959 },
    { lat: 49.1632035, lng: 20.0260996 },
    { lat: 49.1681948, lng: 20.0274694 },
    { lat: 49.1685106, lng: 20.0274168 },
    { lat: 49.1686752, lng: 20.0273898 },
    { lat: 49.1689426, lng: 20.027804 },
    { lat: 49.1690256, lng: 20.0277128 },
    { lat: 49.1692577, lng: 20.0277161 },
    { lat: 49.1693618, lng: 20.0275462 },
    { lat: 49.1699263, lng: 20.0274718 },
    { lat: 49.1704736, lng: 20.027576 },
    { lat: 49.1707496, lng: 20.0270107 },
    { lat: 49.171037, lng: 20.027777 },
    { lat: 49.171094, lng: 20.028736 },
    { lat: 49.17124, lng: 20.029374 },
    { lat: 49.171459, lng: 20.030134 },
    { lat: 49.171624, lng: 20.031018 },
    { lat: 49.171965, lng: 20.031819 },
    { lat: 49.172121, lng: 20.032299 },
    { lat: 49.172145, lng: 20.032871 },
    { lat: 49.172267, lng: 20.033354 },
    { lat: 49.172333, lng: 20.034104 },
    { lat: 49.1723884, lng: 20.0347726 },
    { lat: 49.1723674, lng: 20.035427 },
    { lat: 49.1721586, lng: 20.0358266 },
    { lat: 49.1719918, lng: 20.0365912 },
    { lat: 49.1720062, lng: 20.0369666 },
    { lat: 49.1717501, lng: 20.0369345 },
    { lat: 49.1717431, lng: 20.0372241 },
    { lat: 49.171524, lng: 20.038116 },
    { lat: 49.171425, lng: 20.039012 },
    { lat: 49.171466, lng: 20.0402175 },
    { lat: 49.171065, lng: 20.040854 },
    { lat: 49.1707684, lng: 20.0413246 },
    { lat: 49.1707365, lng: 20.0417517 },
    { lat: 49.170713, lng: 20.042089 },
    { lat: 49.170982, lng: 20.042653 },
    { lat: 49.1710007, lng: 20.0431192 },
    { lat: 49.170998, lng: 20.0435 },
    { lat: 49.171057, lng: 20.043796 },
    { lat: 49.170943, lng: 20.044224 },
    { lat: 49.1710033, lng: 20.0445683 },
    { lat: 49.1710452, lng: 20.0450723 },
    { lat: 49.171041, lng: 20.045655 },
    { lat: 49.1709959, lng: 20.0464021 },
    { lat: 49.170972, lng: 20.047695 },
    { lat: 49.1630775, lng: 20.05283 },
    { lat: 49.1603315, lng: 20.0549974 },
    { lat: 49.1554905, lng: 20.0574887 },
    { lat: 49.1531358, lng: 20.0584021 },
    { lat: 49.1522276, lng: 20.0583061 },
    { lat: 49.1516806, lng: 20.059444 },
    { lat: 49.1500338, lng: 20.0610358 },
    { lat: 49.1486509, lng: 20.063074 },
    { lat: 49.1470715, lng: 20.0636946 },
    { lat: 49.1454866, lng: 20.0644064 },
    { lat: 49.1408594, lng: 20.0650463 },
    { lat: 49.1370893, lng: 20.0670143 },
    { lat: 49.1327956, lng: 20.0673054 },
    { lat: 49.1301022, lng: 20.0677318 },
    { lat: 49.12944, lng: 20.0680907 },
    { lat: 49.1272433, lng: 20.0688993 },
    { lat: 49.1262323, lng: 20.0688535 },
    { lat: 49.1258598, lng: 20.0690831 },
    { lat: 49.1221049, lng: 20.069385 },
    { lat: 49.1218588, lng: 20.0687082 },
    { lat: 49.1208334, lng: 20.0673702 },
    { lat: 49.1207286, lng: 20.0675372 },
    { lat: 49.1202377, lng: 20.0678955 },
    { lat: 49.1201712, lng: 20.0682539 },
    { lat: 49.1196769, lng: 20.0686157 },
    { lat: 49.1191867, lng: 20.0699311 },
    { lat: 49.1191913, lng: 20.0703714 },
    { lat: 49.1192845, lng: 20.0707538 },
    { lat: 49.1192894, lng: 20.070982 },
    { lat: 49.1190756, lng: 20.0719842 },
    { lat: 49.1191732, lng: 20.0728242 },
    { lat: 49.1193011, lng: 20.0730328 },
    { lat: 49.119475, lng: 20.0731584 },
    { lat: 49.1195365, lng: 20.073342 },
    { lat: 49.1193361, lng: 20.0738094 },
    { lat: 49.1192491, lng: 20.0743016 },
    { lat: 49.1190426, lng: 20.0745397 },
    { lat: 49.1189537, lng: 20.0749516 },
    { lat: 49.1189878, lng: 20.0752363 },
    { lat: 49.1183086, lng: 20.0757864 },
    { lat: 49.1178134, lng: 20.0756852 },
    { lat: 49.1173677, lng: 20.0759963 },
    { lat: 49.117119, lng: 20.0760548 },
    { lat: 49.1169412, lng: 20.0762769 },
    { lat: 49.116819, lng: 20.0773128 },
    { lat: 49.1167448, lng: 20.0775959 },
    { lat: 49.1165467, lng: 20.077848 },
    { lat: 49.116199, lng: 20.0779313 },
    { lat: 49.1157659, lng: 20.0789379 },
    { lat: 49.1156456, lng: 20.0790255 },
    { lat: 49.1153534, lng: 20.0787652 },
    { lat: 49.1150822, lng: 20.078856 },
    { lat: 49.1147053, lng: 20.0790584 },
    { lat: 49.1143445, lng: 20.0794064 },
    { lat: 49.1135739, lng: 20.0796192 },
    { lat: 49.113366, lng: 20.0797985 },
    { lat: 49.1127517, lng: 20.0799494 },
    { lat: 49.1125239, lng: 20.0801917 },
    { lat: 49.1114685, lng: 20.0808797 },
    { lat: 49.1112543, lng: 20.0811246 },
    { lat: 49.1111686, lng: 20.0815932 },
    { lat: 49.1110736, lng: 20.0817467 },
    { lat: 49.1102971, lng: 20.0825884 },
    { lat: 49.1098, lng: 20.0832983 },
    { lat: 49.1095374, lng: 20.0834572 },
    { lat: 49.1091569, lng: 20.0830453 },
    { lat: 49.1085711, lng: 20.0829845 },
    { lat: 49.1079936, lng: 20.0832685 },
    { lat: 49.1076226, lng: 20.0837019 },
    { lat: 49.1070839, lng: 20.084105 },
    { lat: 49.1067136, lng: 20.0842199 },
    { lat: 49.1065061, lng: 20.0842168 },
    { lat: 49.1061345, lng: 20.0843817 },
    { lat: 49.1055124, lng: 20.0844581 },
    { lat: 49.1047871, lng: 20.0848664 },
    { lat: 49.1044596, lng: 20.0848754 },
    { lat: 49.1041464, lng: 20.0850635 },
    { lat: 49.1040837, lng: 20.0852969 },
    { lat: 49.1039078, lng: 20.0855914 },
    { lat: 49.1033118, lng: 20.08567 },
    { lat: 49.1031177, lng: 20.0858012 },
    { lat: 49.102904, lng: 20.085779 },
    { lat: 49.102598, lng: 20.0859269 },
    { lat: 49.102221, lng: 20.085878 },
    { lat: 49.1019275, lng: 20.0859723 },
    { lat: 49.1016392, lng: 20.0859168 },
    { lat: 49.1010968, lng: 20.0857348 },
    { lat: 49.1005507, lng: 20.0853472 },
    { lat: 49.1001866, lng: 20.085285 },
    { lat: 49.0995532, lng: 20.0853542 },
    { lat: 49.0995446, lng: 20.0855595 },
    { lat: 49.0991467, lng: 20.0856355 },
    { lat: 49.0988421, lng: 20.0853994 },
    { lat: 49.0980423, lng: 20.0871099 },
    { lat: 49.097407, lng: 20.0869129 },
    { lat: 49.0972094, lng: 20.0869844 },
    { lat: 49.0968371, lng: 20.0873829 },
    { lat: 49.0966546, lng: 20.087467 },
    { lat: 49.0961969, lng: 20.0880615 },
    { lat: 49.0959293, lng: 20.0881838 },
    { lat: 49.0957984, lng: 20.088314 },
    { lat: 49.0956346, lng: 20.0885786 },
    { lat: 49.0953434, lng: 20.0893676 },
    { lat: 49.0949376, lng: 20.0898292 },
    { lat: 49.0947951, lng: 20.0898937 },
    { lat: 49.0945908, lng: 20.0901634 },
    { lat: 49.0942883, lng: 20.0903954 },
    { lat: 49.0937235, lng: 20.0904056 },
    { lat: 49.0931031, lng: 20.0907725 },
    { lat: 49.0923145, lng: 20.0906805 },
    { lat: 49.0918854, lng: 20.0908486 },
    { lat: 49.0915245, lng: 20.0908636 },
    { lat: 49.0911208, lng: 20.0910684 },
    { lat: 49.0908162, lng: 20.0911126 },
    { lat: 49.090835, lng: 20.0912129 },
    { lat: 49.0905925, lng: 20.091234 },
    { lat: 49.0900122, lng: 20.0915 },
    { lat: 49.0894837, lng: 20.0916322 },
    { lat: 49.0889468, lng: 20.0915847 },
    { lat: 49.0887686, lng: 20.0916375 },
    { lat: 49.0881315, lng: 20.0916164 },
    { lat: 49.0879506, lng: 20.0915141 },
    { lat: 49.0875747, lng: 20.0911332 },
    { lat: 49.0873688, lng: 20.0911425 },
    { lat: 49.0872114, lng: 20.0911771 },
    { lat: 49.0862684, lng: 20.0918281 },
    { lat: 49.0851714, lng: 20.0921044 },
    { lat: 49.0847412, lng: 20.092132 },
    { lat: 49.0844949, lng: 20.0922792 },
    { lat: 49.0842504, lng: 20.0923023 },
  ],
  SpišskéBystré: [
    { lat: 48.9194678, lng: 20.2047515 },
    { lat: 48.9198552, lng: 20.2059621 },
    { lat: 48.9203559, lng: 20.2069671 },
    { lat: 48.9206518, lng: 20.2072759 },
    { lat: 48.9208828, lng: 20.2078731 },
    { lat: 48.9215598, lng: 20.2093179 },
    { lat: 48.9219555, lng: 20.209726 },
    { lat: 48.9223865, lng: 20.2099415 },
    { lat: 48.9225316, lng: 20.210249 },
    { lat: 48.922665, lng: 20.2108012 },
    { lat: 48.9229819, lng: 20.2112033 },
    { lat: 48.9234991, lng: 20.2114377 },
    { lat: 48.923672, lng: 20.2114541 },
    { lat: 48.9237982, lng: 20.2115425 },
    { lat: 48.9240443, lng: 20.2119514 },
    { lat: 48.9241849, lng: 20.2120309 },
    { lat: 48.924426, lng: 20.2118939 },
    { lat: 48.9247508, lng: 20.2118183 },
    { lat: 48.9248872, lng: 20.2119963 },
    { lat: 48.9250746, lng: 20.2120919 },
    { lat: 48.9255454, lng: 20.2126315 },
    { lat: 48.9256583, lng: 20.2127225 },
    { lat: 48.9258342, lng: 20.2127268 },
    { lat: 48.9261773, lng: 20.2130284 },
    { lat: 48.9264689, lng: 20.2135357 },
    { lat: 48.9266451, lng: 20.2137165 },
    { lat: 48.9267855, lng: 20.2137656 },
    { lat: 48.9270386, lng: 20.21365 },
    { lat: 48.9272939, lng: 20.2134414 },
    { lat: 48.9273634, lng: 20.2134623 },
    { lat: 48.9277456, lng: 20.2138689 },
    { lat: 48.9278618, lng: 20.2142156 },
    { lat: 48.9281779, lng: 20.2145155 },
    { lat: 48.9283977, lng: 20.2146056 },
    { lat: 48.9288144, lng: 20.2144919 },
    { lat: 48.9291354, lng: 20.2145775 },
    { lat: 48.9300167, lng: 20.2151006 },
    { lat: 48.9302878, lng: 20.2154637 },
    { lat: 48.930459, lng: 20.2154945 },
    { lat: 48.9306688, lng: 20.2156437 },
    { lat: 48.9313633, lng: 20.2170503 },
    { lat: 48.9318931, lng: 20.217913 },
    { lat: 48.9321021, lng: 20.2185819 },
    { lat: 48.9325094, lng: 20.2191149 },
    { lat: 48.9327098, lng: 20.2195922 },
    { lat: 48.9332038, lng: 20.2204314 },
    { lat: 48.9337417, lng: 20.2209631 },
    { lat: 48.9338112, lng: 20.2213805 },
    { lat: 48.9339702, lng: 20.2217245 },
    { lat: 48.9345712, lng: 20.2224246 },
    { lat: 48.9348143, lng: 20.2229303 },
    { lat: 48.9357644, lng: 20.2236046 },
    { lat: 48.9362406, lng: 20.2237155 },
    { lat: 48.9373095, lng: 20.224256 },
    { lat: 48.9379529, lng: 20.2248345 },
    { lat: 48.9383061, lng: 20.2249398 },
    { lat: 48.9384038, lng: 20.2250353 },
    { lat: 48.9385017, lng: 20.2252428 },
    { lat: 48.9385858, lng: 20.2259111 },
    { lat: 48.9385976, lng: 20.2267196 },
    { lat: 48.9386536, lng: 20.2272525 },
    { lat: 48.9385403, lng: 20.2278894 },
    { lat: 48.9383693, lng: 20.2283201 },
    { lat: 48.9379778, lng: 20.2304335 },
    { lat: 48.9375704, lng: 20.2309249 },
    { lat: 48.937406, lng: 20.2313798 },
    { lat: 48.9372477, lng: 20.2320716 },
    { lat: 48.9372598, lng: 20.2325483 },
    { lat: 48.9370065, lng: 20.2339717 },
    { lat: 48.9365576, lng: 20.2354364 },
    { lat: 48.936449, lng: 20.2360184 },
    { lat: 48.9362423, lng: 20.2365749 },
    { lat: 48.9365312, lng: 20.2379233 },
    { lat: 48.936849, lng: 20.2388715 },
    { lat: 48.9370864, lng: 20.2394069 },
    { lat: 48.9373424, lng: 20.2408389 },
    { lat: 48.9373509, lng: 20.2412809 },
    { lat: 48.9371402, lng: 20.2426788 },
    { lat: 48.9371347, lng: 20.2430539 },
    { lat: 48.937203, lng: 20.2434378 },
    { lat: 48.9372002, lng: 20.243481 },
    { lat: 48.9377326, lng: 20.2435877 },
    { lat: 48.9385541, lng: 20.2436315 },
    { lat: 48.9386465, lng: 20.2439766 },
    { lat: 48.9388861, lng: 20.2443947 },
    { lat: 48.9395637, lng: 20.2453836 },
    { lat: 48.9402754, lng: 20.2461553 },
    { lat: 48.9413286, lng: 20.2465313 },
    { lat: 48.9414976, lng: 20.2469325 },
    { lat: 48.9416604, lng: 20.2471767 },
    { lat: 48.9422246, lng: 20.247691 },
    { lat: 48.9423284, lng: 20.2478994 },
    { lat: 48.9423688, lng: 20.2481211 },
    { lat: 48.9422925, lng: 20.2488129 },
    { lat: 48.9422942, lng: 20.2490539 },
    { lat: 48.9423715, lng: 20.249303 },
    { lat: 48.9425699, lng: 20.2494512 },
    { lat: 48.9428416, lng: 20.2495422 },
    { lat: 48.9434417, lng: 20.2501109 },
    { lat: 48.9440825, lng: 20.2505997 },
    { lat: 48.9447784, lng: 20.2513694 },
    { lat: 48.9455227, lng: 20.2517127 },
    { lat: 48.9459893, lng: 20.2522897 },
    { lat: 48.9462495, lng: 20.2523234 },
    { lat: 48.9466895, lng: 20.2520566 },
    { lat: 48.9471882, lng: 20.2518497 },
    { lat: 48.9476423, lng: 20.2518865 },
    { lat: 48.9478313, lng: 20.2517977 },
    { lat: 48.9485988, lng: 20.2520977 },
    { lat: 48.9504037, lng: 20.2520895 },
    { lat: 48.9516994, lng: 20.2532491 },
    { lat: 48.9528005, lng: 20.2535313 },
    { lat: 48.9543574, lng: 20.2533461 },
    { lat: 48.9558751, lng: 20.2523364 },
    { lat: 48.9578166, lng: 20.2516504 },
    { lat: 48.9579675, lng: 20.2517671 },
    { lat: 48.9582621, lng: 20.2517894 },
    { lat: 48.9599327, lng: 20.2522285 },
    { lat: 48.9603998, lng: 20.2521946 },
    { lat: 48.9611959, lng: 20.2523914 },
    { lat: 48.9618362, lng: 20.2524407 },
    { lat: 48.9631831, lng: 20.2529232 },
    { lat: 48.9632784, lng: 20.2530303 },
    { lat: 48.9636208, lng: 20.2532049 },
    { lat: 48.9644399, lng: 20.2533852 },
    { lat: 48.964726, lng: 20.2535854 },
    { lat: 48.965507, lng: 20.2533435 },
    { lat: 48.9659118, lng: 20.2536167 },
    { lat: 48.9669232, lng: 20.2538204 },
    { lat: 48.9677037, lng: 20.2542244 },
    { lat: 48.9677354, lng: 20.2541663 },
    { lat: 48.9688177, lng: 20.2542823 },
    { lat: 48.9693942, lng: 20.2542529 },
    { lat: 48.9696181, lng: 20.2543658 },
    { lat: 48.9699946, lng: 20.2549746 },
    { lat: 48.9703651, lng: 20.2553754 },
    { lat: 48.9704347, lng: 20.2556268 },
    { lat: 48.9706907, lng: 20.2559512 },
    { lat: 48.9707913, lng: 20.2563303 },
    { lat: 48.9709515, lng: 20.2565096 },
    { lat: 48.9716147, lng: 20.2586276 },
    { lat: 48.9719082, lng: 20.259244 },
    { lat: 48.9720591, lng: 20.2597633 },
    { lat: 48.9729694, lng: 20.2601732 },
    { lat: 48.9732921, lng: 20.2602192 },
    { lat: 48.9742541, lng: 20.2606244 },
    { lat: 48.9744347, lng: 20.2605786 },
    { lat: 48.9747934, lng: 20.2606213 },
    { lat: 48.9751765, lng: 20.260859 },
    { lat: 48.9752746, lng: 20.2604303 },
    { lat: 48.9752545, lng: 20.2601195 },
    { lat: 48.9753863, lng: 20.2599771 },
    { lat: 48.9754261, lng: 20.2597301 },
    { lat: 48.9757557, lng: 20.2597231 },
    { lat: 48.9763066, lng: 20.2598703 },
    { lat: 48.976471, lng: 20.2599883 },
    { lat: 48.9768242, lng: 20.260718 },
    { lat: 48.9772791, lng: 20.261317 },
    { lat: 48.9774105, lng: 20.2615689 },
    { lat: 48.9775239, lng: 20.2619612 },
    { lat: 48.9776411, lng: 20.262104 },
    { lat: 48.9779623, lng: 20.2622544 },
    { lat: 48.9782046, lng: 20.2625164 },
    { lat: 48.9790799, lng: 20.2640738 },
    { lat: 48.9794645, lng: 20.2643723 },
    { lat: 48.9797639, lng: 20.2647743 },
    { lat: 48.9799476, lng: 20.2648903 },
    { lat: 48.9805728, lng: 20.2658126 },
    { lat: 48.9808356, lng: 20.2660527 },
    { lat: 48.9809811, lng: 20.2662886 },
    { lat: 48.9816571, lng: 20.2670154 },
    { lat: 48.9822717, lng: 20.267985 },
    { lat: 48.9826569, lng: 20.2684136 },
    { lat: 48.9839681, lng: 20.2696332 },
    { lat: 48.9850542, lng: 20.271027 },
    { lat: 48.9854419, lng: 20.2712615 },
    { lat: 48.9863962, lng: 20.2716759 },
    { lat: 48.9867831, lng: 20.2720095 },
    { lat: 48.9874429, lng: 20.272323 },
    { lat: 48.9883993, lng: 20.2724737 },
    { lat: 48.9888323, lng: 20.2726902 },
    { lat: 48.9895493, lng: 20.2728802 },
    { lat: 48.9904055, lng: 20.2732082 },
    { lat: 48.990801, lng: 20.2734732 },
    { lat: 48.9910615, lng: 20.2735687 },
    { lat: 48.9925418, lng: 20.2738718 },
    { lat: 48.9930664, lng: 20.2742711 },
    { lat: 48.9949219, lng: 20.2754705 },
    { lat: 48.9951092, lng: 20.2744136 },
    { lat: 49.0031962, lng: 20.277391 },
    { lat: 49.003026, lng: 20.2777629 },
    { lat: 49.0029443, lng: 20.278347 },
    { lat: 49.0058987, lng: 20.2791751 },
    { lat: 49.0059056, lng: 20.2795196 },
    { lat: 49.0057115, lng: 20.2800299 },
    { lat: 49.0056776, lng: 20.2802299 },
    { lat: 49.0058529, lng: 20.2816287 },
    { lat: 49.0058275, lng: 20.2825904 },
    { lat: 49.0057568, lng: 20.2830864 },
    { lat: 49.005478, lng: 20.2839871 },
    { lat: 49.0064736, lng: 20.2839058 },
    { lat: 49.0068598, lng: 20.2837346 },
    { lat: 49.0077678, lng: 20.2847774 },
    { lat: 49.0080217, lng: 20.2849572 },
    { lat: 49.0081077, lng: 20.2849376 },
    { lat: 49.0082748, lng: 20.2848157 },
    { lat: 49.0084105, lng: 20.2844266 },
    { lat: 49.0085451, lng: 20.2842724 },
    { lat: 49.0089712, lng: 20.2842515 },
    { lat: 49.0093778, lng: 20.2841129 },
    { lat: 49.0097172, lng: 20.283862 },
    { lat: 49.0099509, lng: 20.2837914 },
    { lat: 49.0102543, lng: 20.2835204 },
    { lat: 49.0108859, lng: 20.2832287 },
    { lat: 49.0112216, lng: 20.2829433 },
    { lat: 49.0112051, lng: 20.2828638 },
    { lat: 49.0115107, lng: 20.2826188 },
    { lat: 49.0116954, lng: 20.2824166 },
    { lat: 49.0116875, lng: 20.2823592 },
    { lat: 49.0123696, lng: 20.2816336 },
    { lat: 49.0124804, lng: 20.281498 },
    { lat: 49.0124386, lng: 20.2810333 },
    { lat: 49.0123339, lng: 20.2789905 },
    { lat: 49.0121858, lng: 20.2741486 },
    { lat: 49.0125697, lng: 20.2737684 },
    { lat: 49.0128181, lng: 20.2732734 },
    { lat: 49.0130149, lng: 20.2728351 },
    { lat: 49.0135992, lng: 20.2711073 },
    { lat: 49.0136391, lng: 20.2705175 },
    { lat: 49.0136018, lng: 20.2702547 },
    { lat: 49.0136489, lng: 20.2693837 },
    { lat: 49.0136255, lng: 20.2691918 },
    { lat: 49.0137991, lng: 20.2687671 },
    { lat: 49.0139383, lng: 20.2679803 },
    { lat: 49.0139585, lng: 20.2672309 },
    { lat: 49.015347, lng: 20.2663741 },
    { lat: 49.0162849, lng: 20.2656271 },
    { lat: 49.0163134, lng: 20.2652342 },
    { lat: 49.0161936, lng: 20.264213 },
    { lat: 49.0161922, lng: 20.2636072 },
    { lat: 49.0162557, lng: 20.2629897 },
    { lat: 49.0164981, lng: 20.262275 },
    { lat: 49.0166267, lng: 20.2620541 },
    { lat: 49.0162814, lng: 20.2612044 },
    { lat: 49.015898, lng: 20.2580129 },
    { lat: 49.0158237, lng: 20.2578109 },
    { lat: 49.0149996, lng: 20.2571898 },
    { lat: 49.0145224, lng: 20.2564795 },
    { lat: 49.0142499, lng: 20.2550782 },
    { lat: 49.0142196, lng: 20.2538458 },
    { lat: 49.0142431, lng: 20.2530699 },
    { lat: 49.0145143, lng: 20.2501778 },
    { lat: 49.0146374, lng: 20.2482706 },
    { lat: 49.0146352, lng: 20.2476476 },
    { lat: 49.0147303, lng: 20.2469462 },
    { lat: 49.0161973, lng: 20.2470529 },
    { lat: 49.0164449, lng: 20.2469044 },
    { lat: 49.016495, lng: 20.2467212 },
    { lat: 49.016471, lng: 20.2465978 },
    { lat: 49.0165113, lng: 20.2461888 },
    { lat: 49.0165809, lng: 20.2460995 },
    { lat: 49.0166075, lng: 20.2459468 },
    { lat: 49.0165478, lng: 20.2457732 },
    { lat: 49.0168148, lng: 20.2448907 },
    { lat: 49.0167735, lng: 20.2446455 },
    { lat: 49.0168516, lng: 20.2442254 },
    { lat: 49.0167965, lng: 20.244055 },
    { lat: 49.0167781, lng: 20.2435892 },
    { lat: 49.0168151, lng: 20.2431568 },
    { lat: 49.0168901, lng: 20.2428568 },
    { lat: 49.0169101, lng: 20.2422512 },
    { lat: 49.0170237, lng: 20.2419682 },
    { lat: 49.0169789, lng: 20.2416573 },
    { lat: 49.0170966, lng: 20.2407001 },
    { lat: 49.0170776, lng: 20.2404751 },
    { lat: 49.0171564, lng: 20.2396208 },
    { lat: 49.0172574, lng: 20.2394714 },
    { lat: 49.0171896, lng: 20.2388063 },
    { lat: 49.017226, lng: 20.2386157 },
    { lat: 49.0170489, lng: 20.2380987 },
    { lat: 49.0170641, lng: 20.2374555 },
    { lat: 49.0168849, lng: 20.2362825 },
    { lat: 49.0164492, lng: 20.2348901 },
    { lat: 49.0161721, lng: 20.2342908 },
    { lat: 49.016066, lng: 20.2335701 },
    { lat: 49.0161779, lng: 20.2327003 },
    { lat: 49.0158978, lng: 20.2293485 },
    { lat: 49.0165801, lng: 20.2287476 },
    { lat: 49.0168078, lng: 20.2279834 },
    { lat: 49.0168256, lng: 20.2276043 },
    { lat: 49.0167412, lng: 20.2267255 },
    { lat: 49.0164401, lng: 20.2258063 },
    { lat: 49.0163051, lng: 20.224615 },
    { lat: 49.0162698, lng: 20.2239215 },
    { lat: 49.0154594, lng: 20.2214908 },
    { lat: 49.0154959, lng: 20.2204761 },
    { lat: 49.0156421, lng: 20.219818 },
    { lat: 49.0160159, lng: 20.2187426 },
    { lat: 49.0162299, lng: 20.2166404 },
    { lat: 49.016409, lng: 20.2159042 },
    { lat: 49.0164793, lng: 20.2152082 },
    { lat: 49.0165651, lng: 20.2148434 },
    { lat: 49.01675, lng: 20.2143647 },
    { lat: 49.0168128, lng: 20.2137655 },
    { lat: 49.0171783, lng: 20.211739 },
    { lat: 49.0171795, lng: 20.2115957 },
    { lat: 49.0155524, lng: 20.2125405 },
    { lat: 49.0147064, lng: 20.2133529 },
    { lat: 49.0140388, lng: 20.2143824 },
    { lat: 49.0139852, lng: 20.2146187 },
    { lat: 49.0139448, lng: 20.2153593 },
    { lat: 49.0137578, lng: 20.2160961 },
    { lat: 49.0134252, lng: 20.2166501 },
    { lat: 49.0131349, lng: 20.217001 },
    { lat: 49.0123905, lng: 20.2162599 },
    { lat: 49.0115962, lng: 20.2156017 },
    { lat: 49.0113134, lng: 20.2154249 },
    { lat: 49.010736, lng: 20.2152899 },
    { lat: 49.0103216, lng: 20.2147776 },
    { lat: 49.0099398, lng: 20.214012 },
    { lat: 49.0096897, lng: 20.2137827 },
    { lat: 49.0095771, lng: 20.2131639 },
    { lat: 49.0096262, lng: 20.2128068 },
    { lat: 49.009629, lng: 20.2121698 },
    { lat: 49.0094063, lng: 20.2115255 },
    { lat: 49.0092163, lng: 20.2112215 },
    { lat: 49.0088685, lng: 20.2103488 },
    { lat: 49.0084254, lng: 20.2097976 },
    { lat: 49.0078783, lng: 20.2118455 },
    { lat: 49.0054898, lng: 20.2119306 },
    { lat: 49.0050319, lng: 20.211993 },
    { lat: 49.0043725, lng: 20.2122042 },
    { lat: 49.0042451, lng: 20.2121453 },
    { lat: 49.0039393, lng: 20.2122192 },
    { lat: 49.0037811, lng: 20.212115 },
    { lat: 49.0030714, lng: 20.2123373 },
    { lat: 49.0020031, lng: 20.2124619 },
    { lat: 49.0012866, lng: 20.2126499 },
    { lat: 49.0012686, lng: 20.2124823 },
    { lat: 49.0012082, lng: 20.2124656 },
    { lat: 48.9994583, lng: 20.2128087 },
    { lat: 48.9984838, lng: 20.2129408 },
    { lat: 48.9979387, lng: 20.2128822 },
    { lat: 48.9974094, lng: 20.2129953 },
    { lat: 48.9969415, lng: 20.2127811 },
    { lat: 48.9962154, lng: 20.2129302 },
    { lat: 48.9960585, lng: 20.2129191 },
    { lat: 48.996036, lng: 20.212834 },
    { lat: 48.9953326, lng: 20.2127046 },
    { lat: 48.9945412, lng: 20.2127721 },
    { lat: 48.9942345, lng: 20.2127042 },
    { lat: 48.9931424, lng: 20.2126814 },
    { lat: 48.9921205, lng: 20.212839 },
    { lat: 48.9913203, lng: 20.2125667 },
    { lat: 48.9911508, lng: 20.212385 },
    { lat: 48.9909378, lng: 20.2124953 },
    { lat: 48.9903418, lng: 20.2122592 },
    { lat: 48.9900911, lng: 20.2120426 },
    { lat: 48.989855, lng: 20.2119391 },
    { lat: 48.9896509, lng: 20.2119883 },
    { lat: 48.9893558, lng: 20.2119021 },
    { lat: 48.9889048, lng: 20.2119492 },
    { lat: 48.9887306, lng: 20.2118709 },
    { lat: 48.9883649, lng: 20.2119364 },
    { lat: 48.9879674, lng: 20.2121901 },
    { lat: 48.9877762, lng: 20.2121697 },
    { lat: 48.9877502, lng: 20.2121104 },
    { lat: 48.9876451, lng: 20.2122394 },
    { lat: 48.9875032, lng: 20.2122414 },
    { lat: 48.9874137, lng: 20.2121689 },
    { lat: 48.9871848, lng: 20.2122767 },
    { lat: 48.9867917, lng: 20.2123023 },
    { lat: 48.9861966, lng: 20.2120643 },
    { lat: 48.9859742, lng: 20.2121041 },
    { lat: 48.9857754, lng: 20.2122331 },
    { lat: 48.9856235, lng: 20.2122098 },
    { lat: 48.9855123, lng: 20.212329 },
    { lat: 48.9853792, lng: 20.2122387 },
    { lat: 48.9853268, lng: 20.2123388 },
    { lat: 48.9850995, lng: 20.2124264 },
    { lat: 48.985067, lng: 20.2125186 },
    { lat: 48.9846056, lng: 20.212666 },
    { lat: 48.9844287, lng: 20.2129465 },
    { lat: 48.9839821, lng: 20.2129014 },
    { lat: 48.9838256, lng: 20.2129907 },
    { lat: 48.9837427, lng: 20.2129711 },
    { lat: 48.9837072, lng: 20.2130973 },
    { lat: 48.983545, lng: 20.2130673 },
    { lat: 48.9834862, lng: 20.2132007 },
    { lat: 48.9831198, lng: 20.2132214 },
    { lat: 48.9830734, lng: 20.2133013 },
    { lat: 48.9829488, lng: 20.2132841 },
    { lat: 48.9828424, lng: 20.2133846 },
    { lat: 48.9827204, lng: 20.2133732 },
    { lat: 48.9825192, lng: 20.213606 },
    { lat: 48.9823245, lng: 20.2136673 },
    { lat: 48.9817882, lng: 20.2141725 },
    { lat: 48.9817431, lng: 20.2141418 },
    { lat: 48.9815402, lng: 20.2144225 },
    { lat: 48.9814226, lng: 20.214439 },
    { lat: 48.980768, lng: 20.2143453 },
    { lat: 48.9803707, lng: 20.2140365 },
    { lat: 48.9803409, lng: 20.2139101 },
    { lat: 48.9800167, lng: 20.2137459 },
    { lat: 48.9778422, lng: 20.2133204 },
    { lat: 48.9753867, lng: 20.2108187 },
    { lat: 48.9754538, lng: 20.2104648 },
    { lat: 48.9755291, lng: 20.2094143 },
    { lat: 48.9761221, lng: 20.2076177 },
    { lat: 48.9760428, lng: 20.2071546 },
    { lat: 48.975562, lng: 20.2062796 },
    { lat: 48.9755518, lng: 20.2061364 },
    { lat: 48.9758042, lng: 20.2055472 },
    { lat: 48.9757952, lng: 20.205262 },
    { lat: 48.9757349, lng: 20.2050761 },
    { lat: 48.9754826, lng: 20.2049329 },
    { lat: 48.9753929, lng: 20.2048021 },
    { lat: 48.9753787, lng: 20.2046686 },
    { lat: 48.9754572, lng: 20.204295 },
    { lat: 48.9753937, lng: 20.2037771 },
    { lat: 48.9754556, lng: 20.2029566 },
    { lat: 48.9753607, lng: 20.2024096 },
    { lat: 48.9754517, lng: 20.2016431 },
    { lat: 48.9755491, lng: 20.2013463 },
    { lat: 48.9755501, lng: 20.2008626 },
    { lat: 48.9754264, lng: 20.2007542 },
    { lat: 48.9751571, lng: 20.2001853 },
    { lat: 48.9748526, lng: 20.1997502 },
    { lat: 48.9747695, lng: 20.1990215 },
    { lat: 48.9746444, lng: 20.1988796 },
    { lat: 48.9745983, lng: 20.1987118 },
    { lat: 48.9746294, lng: 20.1984745 },
    { lat: 48.9744388, lng: 20.1980439 },
    { lat: 48.9745442, lng: 20.1978643 },
    { lat: 48.9745391, lng: 20.1976167 },
    { lat: 48.9744788, lng: 20.19749 },
    { lat: 48.9741453, lng: 20.1974292 },
    { lat: 48.9740038, lng: 20.1970185 },
    { lat: 48.9739843, lng: 20.196754 },
    { lat: 48.9737702, lng: 20.196692 },
    { lat: 48.9738235, lng: 20.1962841 },
    { lat: 48.9738021, lng: 20.1961133 },
    { lat: 48.9733331, lng: 20.1955905 },
    { lat: 48.9733489, lng: 20.1951649 },
    { lat: 48.97329, lng: 20.1950198 },
    { lat: 48.9730726, lng: 20.1949291 },
    { lat: 48.972984, lng: 20.1947723 },
    { lat: 48.9727706, lng: 20.1945884 },
    { lat: 48.9724728, lng: 20.1945466 },
    { lat: 48.9724678, lng: 20.1943922 },
    { lat: 48.9721631, lng: 20.1939313 },
    { lat: 48.9721208, lng: 20.1939696 },
    { lat: 48.9720104, lng: 20.1937393 },
    { lat: 48.9714117, lng: 20.1939249 },
    { lat: 48.9710268, lng: 20.1944922 },
    { lat: 48.9705861, lng: 20.1949074 },
    { lat: 48.9693039, lng: 20.1951423 },
    { lat: 48.9691225, lng: 20.1951694 },
    { lat: 48.9673713, lng: 20.1947736 },
    { lat: 48.9654927, lng: 20.1951075 },
    { lat: 48.9639684, lng: 20.1954614 },
    { lat: 48.9631823, lng: 20.1951524 },
    { lat: 48.9622864, lng: 20.1957004 },
    { lat: 48.960892, lng: 20.1956649 },
    { lat: 48.9607565, lng: 20.1962791 },
    { lat: 48.9607624, lng: 20.1967058 },
    { lat: 48.9606828, lng: 20.1968041 },
    { lat: 48.9605704, lng: 20.1973413 },
    { lat: 48.9605575, lng: 20.1978098 },
    { lat: 48.9606361, lng: 20.1980871 },
    { lat: 48.9605871, lng: 20.1983812 },
    { lat: 48.9606859, lng: 20.1986552 },
    { lat: 48.9605959, lng: 20.1990262 },
    { lat: 48.9605992, lng: 20.1995072 },
    { lat: 48.9606909, lng: 20.2001962 },
    { lat: 48.9605396, lng: 20.2007516 },
    { lat: 48.9604687, lng: 20.2007491 },
    { lat: 48.9603934, lng: 20.2008381 },
    { lat: 48.9602514, lng: 20.2012604 },
    { lat: 48.9600621, lng: 20.2014835 },
    { lat: 48.9596843, lng: 20.2017562 },
    { lat: 48.9593, lng: 20.2018901 },
    { lat: 48.9588926, lng: 20.2023726 },
    { lat: 48.9584095, lng: 20.2025192 },
    { lat: 48.9581752, lng: 20.2027174 },
    { lat: 48.9578437, lng: 20.2028177 },
    { lat: 48.9573093, lng: 20.2028267 },
    { lat: 48.9571883, lng: 20.2029001 },
    { lat: 48.9570126, lng: 20.2029027 },
    { lat: 48.9568652, lng: 20.2031588 },
    { lat: 48.9565696, lng: 20.2033995 },
    { lat: 48.9559628, lng: 20.2035148 },
    { lat: 48.9556113, lng: 20.2039373 },
    { lat: 48.9551685, lng: 20.2046943 },
    { lat: 48.9549352, lng: 20.2048332 },
    { lat: 48.9548655, lng: 20.2050016 },
    { lat: 48.9548768, lng: 20.2051493 },
    { lat: 48.9547464, lng: 20.205354 },
    { lat: 48.9513851, lng: 20.2043143 },
    { lat: 48.94983, lng: 20.2034084 },
    { lat: 48.9473175, lng: 20.2024508 },
    { lat: 48.9469667, lng: 20.2025185 },
    { lat: 48.9468467, lng: 20.2024206 },
    { lat: 48.9465564, lng: 20.2025683 },
    { lat: 48.946249, lng: 20.2023995 },
    { lat: 48.9459077, lng: 20.2023972 },
    { lat: 48.9457001, lng: 20.2025288 },
    { lat: 48.9455717, lng: 20.2023854 },
    { lat: 48.943627, lng: 20.2024487 },
    { lat: 48.9403042, lng: 20.2009363 },
    { lat: 48.93891, lng: 20.2007405 },
    { lat: 48.9378451, lng: 20.2025005 },
    { lat: 48.9361808, lng: 20.2028719 },
    { lat: 48.9349498, lng: 20.2038578 },
    { lat: 48.9319226, lng: 20.2048729 },
    { lat: 48.9300098, lng: 20.2053128 },
    { lat: 48.9266153, lng: 20.2037255 },
    { lat: 48.9249806, lng: 20.2027178 },
    { lat: 48.9232367, lng: 20.2025072 },
    { lat: 48.9213039, lng: 20.2028372 },
    { lat: 48.9197093, lng: 20.2039143 },
    { lat: 48.9194778, lng: 20.2047176 },
    { lat: 48.9194678, lng: 20.2047515 },
  ],
  SpišskáTeplica: [
    { lat: 49.0536638, lng: 20.2243582 },
    { lat: 49.0534812, lng: 20.2244414 },
    { lat: 49.0532496, lng: 20.2237367 },
    { lat: 49.0531643, lng: 20.2230595 },
    { lat: 49.0526933, lng: 20.2230524 },
    { lat: 49.0528887, lng: 20.2224471 },
    { lat: 49.0530727, lng: 20.2215739 },
    { lat: 49.0530706, lng: 20.2210618 },
    { lat: 49.0529946, lng: 20.2205988 },
    { lat: 49.0530685, lng: 20.2201877 },
    { lat: 49.0531249, lng: 20.2185145 },
    { lat: 49.0532531, lng: 20.2180823 },
    { lat: 49.0533432, lng: 20.217258 },
    { lat: 49.0532083, lng: 20.2169519 },
    { lat: 49.0531066, lng: 20.2165175 },
    { lat: 49.0530717, lng: 20.2160363 },
    { lat: 49.0531018, lng: 20.2151169 },
    { lat: 49.0530469, lng: 20.2146736 },
    { lat: 49.0528172, lng: 20.2142179 },
    { lat: 49.052715, lng: 20.2121549 },
    { lat: 49.0529414, lng: 20.2113058 },
    { lat: 49.0528693, lng: 20.2112202 },
    { lat: 49.0534614, lng: 20.2098783 },
    { lat: 49.0529897, lng: 20.2094563 },
    { lat: 49.0534526, lng: 20.208355 },
    { lat: 49.0537367, lng: 20.2078793 },
    { lat: 49.0537385, lng: 20.2077833 },
    { lat: 49.0529205, lng: 20.2082549 },
    { lat: 49.0529236, lng: 20.2081893 },
    { lat: 49.0532051, lng: 20.2080463 },
    { lat: 49.0532057, lng: 20.2079799 },
    { lat: 49.0528699, lng: 20.2081447 },
    { lat: 49.0527732, lng: 20.2078365 },
    { lat: 49.0526104, lng: 20.2079323 },
    { lat: 49.0524344, lng: 20.207644 },
    { lat: 49.0522233, lng: 20.2067199 },
    { lat: 49.0528418, lng: 20.2065007 },
    { lat: 49.0529499, lng: 20.2058463 },
    { lat: 49.0530754, lng: 20.2054352 },
    { lat: 49.0533538, lng: 20.2056655 },
    { lat: 49.0534745, lng: 20.2056469 },
    { lat: 49.0535982, lng: 20.2054463 },
    { lat: 49.0533171, lng: 20.2052295 },
    { lat: 49.0537826, lng: 20.2037074 },
    { lat: 49.0539603, lng: 20.2037553 },
    { lat: 49.0541545, lng: 20.2036853 },
    { lat: 49.0536039, lng: 20.2028115 },
    { lat: 49.0539862, lng: 20.2027099 },
    { lat: 49.0542219, lng: 20.201759 },
    { lat: 49.0544718, lng: 20.2016706 },
    { lat: 49.0546632, lng: 20.2012968 },
    { lat: 49.0546834, lng: 20.1993453 },
    { lat: 49.0547932, lng: 20.1977227 },
    { lat: 49.0548651, lng: 20.1974874 },
    { lat: 49.0549099, lng: 20.1969425 },
    { lat: 49.054843, lng: 20.1966259 },
    { lat: 49.054959, lng: 20.1961125 },
    { lat: 49.0551269, lng: 20.1957488 },
    { lat: 49.055192, lng: 20.1954882 },
    { lat: 49.0551726, lng: 20.1951719 },
    { lat: 49.0553247, lng: 20.1946947 },
    { lat: 49.0555416, lng: 20.1943117 },
    { lat: 49.0558149, lng: 20.1936007 },
    { lat: 49.0558782, lng: 20.193295 },
    { lat: 49.0558847, lng: 20.1927547 },
    { lat: 49.0557752, lng: 20.1924622 },
    { lat: 49.0556808, lng: 20.1924917 },
    { lat: 49.0553501, lng: 20.1923897 },
    { lat: 49.0550892, lng: 20.1921186 },
    { lat: 49.0544985, lng: 20.1905503 },
    { lat: 49.0538604, lng: 20.1894326 },
    { lat: 49.05342, lng: 20.1889217 },
    { lat: 49.0535547, lng: 20.1878674 },
    { lat: 49.0540123, lng: 20.186916 },
    { lat: 49.0546358, lng: 20.1856922 },
    { lat: 49.0544891, lng: 20.1856296 },
    { lat: 49.0542742, lng: 20.1853256 },
    { lat: 49.0541008, lng: 20.1849524 },
    { lat: 49.0539614, lng: 20.1844519 },
    { lat: 49.0533517, lng: 20.1832853 },
    { lat: 49.0532212, lng: 20.1830956 },
    { lat: 49.0527674, lng: 20.1827538 },
    { lat: 49.051866, lng: 20.1826902 },
    { lat: 49.050831, lng: 20.182489 },
    { lat: 49.0457308, lng: 20.1827459 },
    { lat: 49.0416041, lng: 20.1794904 },
    { lat: 49.0411671, lng: 20.179067 },
    { lat: 49.0410195, lng: 20.1775716 },
    { lat: 49.0406969, lng: 20.1772235 },
    { lat: 49.0400504, lng: 20.1762923 },
    { lat: 49.0400956, lng: 20.1760418 },
    { lat: 49.0400548, lng: 20.175248 },
    { lat: 49.0400731, lng: 20.1742718 },
    { lat: 49.0400055, lng: 20.1738437 },
    { lat: 49.0397049, lng: 20.1730029 },
    { lat: 49.0390566, lng: 20.1721713 },
    { lat: 49.0388949, lng: 20.1716737 },
    { lat: 49.0385012, lng: 20.1711844 },
    { lat: 49.0382141, lng: 20.1705681 },
    { lat: 49.0381136, lng: 20.1701076 },
    { lat: 49.0381063, lng: 20.1694776 },
    { lat: 49.0379692, lng: 20.1688658 },
    { lat: 49.0379646, lng: 20.1684986 },
    { lat: 49.0376308, lng: 20.1682072 },
    { lat: 49.0374267, lng: 20.1678888 },
    { lat: 49.0372557, lng: 20.1667155 },
    { lat: 49.0370249, lng: 20.1663209 },
    { lat: 49.036584, lng: 20.1662236 },
    { lat: 49.0362396, lng: 20.1663399 },
    { lat: 49.0361045, lng: 20.1661719 },
    { lat: 49.036006, lng: 20.1662158 },
    { lat: 49.0359075, lng: 20.1664547 },
    { lat: 49.0356835, lng: 20.1665131 },
    { lat: 49.0354064, lng: 20.1662876 },
    { lat: 49.0352015, lng: 20.1662632 },
    { lat: 49.0346204, lng: 20.1662381 },
    { lat: 49.0344446, lng: 20.166201 },
    { lat: 49.0342565, lng: 20.1663426 },
    { lat: 49.034153, lng: 20.1663464 },
    { lat: 49.0340111, lng: 20.1661503 },
    { lat: 49.0340033, lng: 20.1660366 },
    { lat: 49.0338102, lng: 20.1656353 },
    { lat: 49.0336928, lng: 20.1656566 },
    { lat: 49.0336317, lng: 20.1655047 },
    { lat: 49.0334883, lng: 20.1654412 },
    { lat: 49.033339, lng: 20.1648381 },
    { lat: 49.0332242, lng: 20.1637415 },
    { lat: 49.0330537, lng: 20.1635366 },
    { lat: 49.0330594, lng: 20.1632572 },
    { lat: 49.0329632, lng: 20.1623753 },
    { lat: 49.0329688, lng: 20.1619215 },
    { lat: 49.0327707, lng: 20.1617601 },
    { lat: 49.0327613, lng: 20.1616074 },
    { lat: 49.0328527, lng: 20.1614158 },
    { lat: 49.032602, lng: 20.1611208 },
    { lat: 49.0326161, lng: 20.1608539 },
    { lat: 49.0324797, lng: 20.160625 },
    { lat: 49.0322916, lng: 20.1605431 },
    { lat: 49.0322034, lng: 20.1604202 },
    { lat: 49.0320354, lng: 20.1604165 },
    { lat: 49.0320175, lng: 20.1603051 },
    { lat: 49.0318263, lng: 20.1601445 },
    { lat: 49.0313567, lng: 20.1600047 },
    { lat: 49.0313454, lng: 20.1598636 },
    { lat: 49.031011, lng: 20.1597508 },
    { lat: 49.0309102, lng: 20.1596521 },
    { lat: 49.030777, lng: 20.1593662 },
    { lat: 49.0305127, lng: 20.1591507 },
    { lat: 49.0297795, lng: 20.1587849 },
    { lat: 49.0296241, lng: 20.1588697 },
    { lat: 49.0293375, lng: 20.1586268 },
    { lat: 49.0290157, lng: 20.1586118 },
    { lat: 49.0289134, lng: 20.1586687 },
    { lat: 49.0288823, lng: 20.1584871 },
    { lat: 49.0286226, lng: 20.1582726 },
    { lat: 49.0285154, lng: 20.158266 },
    { lat: 49.0284454, lng: 20.1581509 },
    { lat: 49.0280261, lng: 20.1578579 },
    { lat: 49.027848, lng: 20.1574664 },
    { lat: 49.0274134, lng: 20.1568217 },
    { lat: 49.0267711, lng: 20.1564468 },
    { lat: 49.0265098, lng: 20.1558883 },
    { lat: 49.0264061, lng: 20.1551587 },
    { lat: 49.026314, lng: 20.1550208 },
    { lat: 49.0262827, lng: 20.1548601 },
    { lat: 49.0260614, lng: 20.1545373 },
    { lat: 49.0256744, lng: 20.1541275 },
    { lat: 49.0255673, lng: 20.1541809 },
    { lat: 49.0253956, lng: 20.1541133 },
    { lat: 49.0249719, lng: 20.1538828 },
    { lat: 49.0248058, lng: 20.1536725 },
    { lat: 49.0244856, lng: 20.1535785 },
    { lat: 49.0243555, lng: 20.1532023 },
    { lat: 49.0238798, lng: 20.1530736 },
    { lat: 49.0238655, lng: 20.1529716 },
    { lat: 49.0237363, lng: 20.1529262 },
    { lat: 49.0235967, lng: 20.1527587 },
    { lat: 49.0223894, lng: 20.1527834 },
    { lat: 49.0219622, lng: 20.1526355 },
    { lat: 49.0212479, lng: 20.1525716 },
    { lat: 49.0208033, lng: 20.1526367 },
    { lat: 49.0206843, lng: 20.1527812 },
    { lat: 49.0205437, lng: 20.1527014 },
    { lat: 49.020239, lng: 20.1527408 },
    { lat: 49.0199563, lng: 20.1525475 },
    { lat: 49.0196502, lng: 20.1525832 },
    { lat: 49.0194487, lng: 20.152162 },
    { lat: 49.0191334, lng: 20.1519674 },
    { lat: 49.0188159, lng: 20.1514074 },
    { lat: 49.0180905, lng: 20.149536 },
    { lat: 49.0179936, lng: 20.1492239 },
    { lat: 49.0172921, lng: 20.1503763 },
    { lat: 49.0172829, lng: 20.1503718 },
    { lat: 49.0169107, lng: 20.1508556 },
    { lat: 49.0168195, lng: 20.1510521 },
    { lat: 49.0167097, lng: 20.1511265 },
    { lat: 49.0164546, lng: 20.1514742 },
    { lat: 49.0161132, lng: 20.1520844 },
    { lat: 49.0156987, lng: 20.1525872 },
    { lat: 49.0156438, lng: 20.153129 },
    { lat: 49.0154579, lng: 20.153711 },
    { lat: 49.0153551, lng: 20.1538203 },
    { lat: 49.0151209, lng: 20.1545205 },
    { lat: 49.0150094, lng: 20.1550515 },
    { lat: 49.0149589, lng: 20.1559506 },
    { lat: 49.0151033, lng: 20.1565418 },
    { lat: 49.0154137, lng: 20.157125 },
    { lat: 49.015355, lng: 20.15782 },
    { lat: 49.0154594, lng: 20.1585986 },
    { lat: 49.0161634, lng: 20.1612643 },
    { lat: 49.0154014, lng: 20.1623126 },
    { lat: 49.015358, lng: 20.1624953 },
    { lat: 49.0151254, lng: 20.1628136 },
    { lat: 49.0149616, lng: 20.1628844 },
    { lat: 49.0144176, lng: 20.1633597 },
    { lat: 49.014019, lng: 20.1639234 },
    { lat: 49.0135868, lng: 20.1640427 },
    { lat: 49.0130208, lng: 20.1645304 },
    { lat: 49.0126619, lng: 20.1655761 },
    { lat: 49.0124295, lng: 20.1658786 },
    { lat: 49.0123654, lng: 20.1660456 },
    { lat: 49.0123534, lng: 20.166112 },
    { lat: 49.013307, lng: 20.1683505 },
    { lat: 49.0136211, lng: 20.1699244 },
    { lat: 49.0134463, lng: 20.1718861 },
    { lat: 49.0137403, lng: 20.1727558 },
    { lat: 49.0139275, lng: 20.1735543 },
    { lat: 49.0140126, lng: 20.1747171 },
    { lat: 49.014131, lng: 20.1754822 },
    { lat: 49.0141701, lng: 20.176459 },
    { lat: 49.0143462, lng: 20.1766078 },
    { lat: 49.0142416, lng: 20.1770907 },
    { lat: 49.014307, lng: 20.1777681 },
    { lat: 49.0137759, lng: 20.1785971 },
    { lat: 49.0134143, lng: 20.1789312 },
    { lat: 49.0132084, lng: 20.1794715 },
    { lat: 49.0130764, lng: 20.1800822 },
    { lat: 49.0130239, lng: 20.1806865 },
    { lat: 49.0126197, lng: 20.1820702 },
    { lat: 49.0117764, lng: 20.1853439 },
    { lat: 49.0120789, lng: 20.1879836 },
    { lat: 49.0129055, lng: 20.1893936 },
    { lat: 49.0131003, lng: 20.1907737 },
    { lat: 49.0131605, lng: 20.1916931 },
    { lat: 49.0129325, lng: 20.1927789 },
    { lat: 49.0129409, lng: 20.1933266 },
    { lat: 49.0132079, lng: 20.1935546 },
    { lat: 49.0150649, lng: 20.1962668 },
    { lat: 49.0155873, lng: 20.1969093 },
    { lat: 49.0163366, lng: 20.1975446 },
    { lat: 49.0170398, lng: 20.1982361 },
    { lat: 49.0175757, lng: 20.1993618 },
    { lat: 49.0176326, lng: 20.2003109 },
    { lat: 49.0177832, lng: 20.2011549 },
    { lat: 49.017814, lng: 20.2019809 },
    { lat: 49.0175684, lng: 20.2034522 },
    { lat: 49.0174151, lng: 20.2039833 },
    { lat: 49.0174399, lng: 20.2044105 },
    { lat: 49.017525, lng: 20.2045695 },
    { lat: 49.0176255, lng: 20.2049707 },
    { lat: 49.0178192, lng: 20.2065014 },
    { lat: 49.0177719, lng: 20.2075183 },
    { lat: 49.0175828, lng: 20.2080867 },
    { lat: 49.0170513, lng: 20.2090526 },
    { lat: 49.01712, lng: 20.2098421 },
    { lat: 49.017026, lng: 20.2099483 },
    { lat: 49.0169454, lng: 20.2102937 },
    { lat: 49.0169144, lng: 20.2107608 },
    { lat: 49.0171795, lng: 20.2115957 },
    { lat: 49.0171783, lng: 20.211739 },
    { lat: 49.0168128, lng: 20.2137655 },
    { lat: 49.01675, lng: 20.2143647 },
    { lat: 49.0165651, lng: 20.2148434 },
    { lat: 49.0164793, lng: 20.2152082 },
    { lat: 49.016409, lng: 20.2159042 },
    { lat: 49.0162299, lng: 20.2166404 },
    { lat: 49.0160159, lng: 20.2187426 },
    { lat: 49.0156421, lng: 20.219818 },
    { lat: 49.0154959, lng: 20.2204761 },
    { lat: 49.0154594, lng: 20.2214908 },
    { lat: 49.0162698, lng: 20.2239215 },
    { lat: 49.0163051, lng: 20.224615 },
    { lat: 49.0164401, lng: 20.2258063 },
    { lat: 49.0167412, lng: 20.2267255 },
    { lat: 49.0168256, lng: 20.2276043 },
    { lat: 49.0168078, lng: 20.2279834 },
    { lat: 49.0165801, lng: 20.2287476 },
    { lat: 49.0158978, lng: 20.2293485 },
    { lat: 49.0161779, lng: 20.2327003 },
    { lat: 49.016066, lng: 20.2335701 },
    { lat: 49.0161721, lng: 20.2342908 },
    { lat: 49.0164492, lng: 20.2348901 },
    { lat: 49.0168849, lng: 20.2362825 },
    { lat: 49.0170641, lng: 20.2374555 },
    { lat: 49.0170489, lng: 20.2380987 },
    { lat: 49.017226, lng: 20.2386157 },
    { lat: 49.0171896, lng: 20.2388063 },
    { lat: 49.0172574, lng: 20.2394714 },
    { lat: 49.0171564, lng: 20.2396208 },
    { lat: 49.0170776, lng: 20.2404751 },
    { lat: 49.0170966, lng: 20.2407001 },
    { lat: 49.0169789, lng: 20.2416573 },
    { lat: 49.0170237, lng: 20.2419682 },
    { lat: 49.0169101, lng: 20.2422512 },
    { lat: 49.0168901, lng: 20.2428568 },
    { lat: 49.0168151, lng: 20.2431568 },
    { lat: 49.0167781, lng: 20.2435892 },
    { lat: 49.0167965, lng: 20.244055 },
    { lat: 49.0168516, lng: 20.2442254 },
    { lat: 49.0167735, lng: 20.2446455 },
    { lat: 49.0168148, lng: 20.2448907 },
    { lat: 49.0165478, lng: 20.2457732 },
    { lat: 49.0166075, lng: 20.2459468 },
    { lat: 49.0165809, lng: 20.2460995 },
    { lat: 49.0165113, lng: 20.2461888 },
    { lat: 49.016471, lng: 20.2465978 },
    { lat: 49.016495, lng: 20.2467212 },
    { lat: 49.0164449, lng: 20.2469044 },
    { lat: 49.0167383, lng: 20.2466915 },
    { lat: 49.0180101, lng: 20.2457321 },
    { lat: 49.0185555, lng: 20.2456006 },
    { lat: 49.0190496, lng: 20.2455635 },
    { lat: 49.0193425, lng: 20.2457108 },
    { lat: 49.0196093, lng: 20.2462767 },
    { lat: 49.0201506, lng: 20.2465262 },
    { lat: 49.0202653, lng: 20.246838 },
    { lat: 49.020703, lng: 20.2468264 },
    { lat: 49.0213097, lng: 20.2470765 },
    { lat: 49.0214573, lng: 20.2472546 },
    { lat: 49.02175, lng: 20.2472807 },
    { lat: 49.0219222, lng: 20.2475316 },
    { lat: 49.0224829, lng: 20.2479032 },
    { lat: 49.0225201, lng: 20.2480753 },
    { lat: 49.0226084, lng: 20.2481716 },
    { lat: 49.0226049, lng: 20.248567 },
    { lat: 49.0228297, lng: 20.2490148 },
    { lat: 49.0228205, lng: 20.2492653 },
    { lat: 49.022979, lng: 20.2494328 },
    { lat: 49.0230839, lng: 20.2498954 },
    { lat: 49.0234084, lng: 20.2501009 },
    { lat: 49.0234226, lng: 20.2503188 },
    { lat: 49.023377, lng: 20.2505028 },
    { lat: 49.0234114, lng: 20.2506026 },
    { lat: 49.0234576, lng: 20.2505776 },
    { lat: 49.023477, lng: 20.250752 },
    { lat: 49.0235465, lng: 20.2506893 },
    { lat: 49.0236036, lng: 20.2507982 },
    { lat: 49.0235876, lng: 20.2508761 },
    { lat: 49.0235121, lng: 20.2508471 },
    { lat: 49.0235107, lng: 20.2509251 },
    { lat: 49.0235918, lng: 20.2509845 },
    { lat: 49.0236331, lng: 20.2511163 },
    { lat: 49.0237322, lng: 20.2511159 },
    { lat: 49.023959, lng: 20.2513246 },
    { lat: 49.0241622, lng: 20.2517711 },
    { lat: 49.0241573, lng: 20.251933 },
    { lat: 49.0243342, lng: 20.252219 },
    { lat: 49.0247307, lng: 20.2525672 },
    { lat: 49.0248098, lng: 20.2529472 },
    { lat: 49.0249667, lng: 20.2530133 },
    { lat: 49.0250727, lng: 20.2532405 },
    { lat: 49.0250974, lng: 20.2535285 },
    { lat: 49.0252262, lng: 20.2536517 },
    { lat: 49.0253298, lng: 20.2541389 },
    { lat: 49.0255034, lng: 20.2543466 },
    { lat: 49.0255298, lng: 20.2545319 },
    { lat: 49.0257922, lng: 20.255216 },
    { lat: 49.0259666, lng: 20.2555575 },
    { lat: 49.0264053, lng: 20.256147 },
    { lat: 49.0271348, lng: 20.2568281 },
    { lat: 49.0280418, lng: 20.2575577 },
    { lat: 49.0309994, lng: 20.2596336 },
    { lat: 49.0328586, lng: 20.2610939 },
    { lat: 49.0331861, lng: 20.2612735 },
    { lat: 49.033899, lng: 20.2616643 },
    { lat: 49.0342485, lng: 20.2615917 },
    { lat: 49.0351166, lng: 20.2615562 },
    { lat: 49.0360132, lng: 20.2621359 },
    { lat: 49.0363096, lng: 20.2623483 },
    { lat: 49.0369916, lng: 20.2632802 },
    { lat: 49.0374675, lng: 20.2636461 },
    { lat: 49.0378581, lng: 20.2641278 },
    { lat: 49.0384986, lng: 20.2647491 },
    { lat: 49.03931, lng: 20.2647637 },
    { lat: 49.0405463, lng: 20.2645624 },
    { lat: 49.0428527, lng: 20.2670364 },
    { lat: 49.0439, lng: 20.268444 },
    { lat: 49.0446856, lng: 20.2691502 },
    { lat: 49.0458769, lng: 20.2703882 },
    { lat: 49.0469411, lng: 20.2717663 },
    { lat: 49.0476965, lng: 20.2724793 },
    { lat: 49.0481619, lng: 20.2731806 },
    { lat: 49.0485491, lng: 20.2736619 },
    { lat: 49.0491657, lng: 20.2744283 },
    { lat: 49.0495489, lng: 20.275072 },
    { lat: 49.0499378, lng: 20.2754001 },
    { lat: 49.0499659, lng: 20.2755368 },
    { lat: 49.0500611, lng: 20.275614 },
    { lat: 49.0502265, lng: 20.2759264 },
    { lat: 49.0502374, lng: 20.2760295 },
    { lat: 49.0504806, lng: 20.2764074 },
    { lat: 49.0520675, lng: 20.2782536 },
    { lat: 49.0529368, lng: 20.2791478 },
    { lat: 49.053505, lng: 20.2781583 },
    { lat: 49.055165, lng: 20.2757152 },
    { lat: 49.054747, lng: 20.2720249 },
    { lat: 49.0547659, lng: 20.2716208 },
    { lat: 49.0548773, lng: 20.2709824 },
    { lat: 49.0551707, lng: 20.270228 },
    { lat: 49.0560547, lng: 20.2685314 },
    { lat: 49.0562097, lng: 20.2676032 },
    { lat: 49.0563356, lng: 20.2671681 },
    { lat: 49.0564294, lng: 20.2657694 },
    { lat: 49.0565454, lng: 20.2651937 },
    { lat: 49.0568378, lng: 20.2646049 },
    { lat: 49.0583333, lng: 20.2620839 },
    { lat: 49.0586358, lng: 20.2613576 },
    { lat: 49.0587622, lng: 20.2607616 },
    { lat: 49.0587719, lng: 20.2602085 },
    { lat: 49.0586368, lng: 20.2592017 },
    { lat: 49.0582929, lng: 20.2579318 },
    { lat: 49.0581496, lng: 20.2571808 },
    { lat: 49.0581745, lng: 20.2562331 },
    { lat: 49.0582942, lng: 20.2557317 },
    { lat: 49.0589, lng: 20.2538375 },
    { lat: 49.0590223, lng: 20.2531129 },
    { lat: 49.0590317, lng: 20.2525174 },
    { lat: 49.0589774, lng: 20.252084 },
    { lat: 49.0585148, lng: 20.2504259 },
    { lat: 49.0584918, lng: 20.2493878 },
    { lat: 49.0586917, lng: 20.248542 },
    { lat: 49.0594341, lng: 20.2462905 },
    { lat: 49.0595151, lng: 20.2451193 },
    { lat: 49.0594871, lng: 20.24478 },
    { lat: 49.0591372, lng: 20.2428755 },
    { lat: 49.0591792, lng: 20.2411115 },
    { lat: 49.0591357, lng: 20.2406645 },
    { lat: 49.0589524, lng: 20.2398425 },
    { lat: 49.0585597, lng: 20.2386263 },
    { lat: 49.0584376, lng: 20.237929 },
    { lat: 49.0584546, lng: 20.2372427 },
    { lat: 49.0588495, lng: 20.2354102 },
    { lat: 49.058833, lng: 20.2342814 },
    { lat: 49.058734, lng: 20.2336276 },
    { lat: 49.0583264, lng: 20.2327719 },
    { lat: 49.0570377, lng: 20.2314808 },
    { lat: 49.0566866, lng: 20.2310027 },
    { lat: 49.0557752, lng: 20.2290859 },
    { lat: 49.0543741, lng: 20.2259447 },
    { lat: 49.0536638, lng: 20.2243582 },
  ],
  Švábovce: [
    { lat: 49.0089264, lng: 20.3253368 },
    { lat: 49.0090596, lng: 20.3263066 },
    { lat: 49.0089472, lng: 20.3271524 },
    { lat: 49.0091323, lng: 20.3290272 },
    { lat: 49.0090793, lng: 20.3307716 },
    { lat: 49.0091209, lng: 20.3312607 },
    { lat: 49.0091325, lng: 20.3319408 },
    { lat: 49.0092258, lng: 20.3327843 },
    { lat: 49.0092131, lng: 20.3335584 },
    { lat: 49.0093229, lng: 20.3352022 },
    { lat: 49.0092935, lng: 20.3359315 },
    { lat: 49.0092105, lng: 20.3366118 },
    { lat: 49.0090989, lng: 20.3391705 },
    { lat: 49.0086402, lng: 20.3415766 },
    { lat: 49.0083887, lng: 20.3425944 },
    { lat: 49.0082833, lng: 20.343452 },
    { lat: 49.0083695, lng: 20.344425 },
    { lat: 49.0083642, lng: 20.3446973 },
    { lat: 49.0082873, lng: 20.3450715 },
    { lat: 49.0082471, lng: 20.3460729 },
    { lat: 49.0083454, lng: 20.3469576 },
    { lat: 49.0082383, lng: 20.34847 },
    { lat: 49.0082546, lng: 20.348806 },
    { lat: 49.0082893, lng: 20.3492419 },
    { lat: 49.0087463, lng: 20.3506377 },
    { lat: 49.0087605, lng: 20.3510056 },
    { lat: 49.0086226, lng: 20.3531581 },
    { lat: 49.0085899, lng: 20.3550006 },
    { lat: 49.0084392, lng: 20.3562593 },
    { lat: 49.0080777, lng: 20.3571107 },
    { lat: 49.0078412, lng: 20.3585456 },
    { lat: 49.0078718, lng: 20.3586662 },
    { lat: 49.0078868, lng: 20.3586739 },
    { lat: 49.0096065, lng: 20.3596473 },
    { lat: 49.0107197, lng: 20.3601915 },
    { lat: 49.0137667, lng: 20.3615393 },
    { lat: 49.0160313, lng: 20.3634227 },
    { lat: 49.0169515, lng: 20.3640434 },
    { lat: 49.0176889, lng: 20.3644493 },
    { lat: 49.0188927, lng: 20.3647762 },
    { lat: 49.0200006, lng: 20.3654778 },
    { lat: 49.0206698, lng: 20.366015 },
    { lat: 49.0210021, lng: 20.3661639 },
    { lat: 49.0217088, lng: 20.3668273 },
    { lat: 49.0219305, lng: 20.3675033 },
    { lat: 49.0222983, lng: 20.3683439 },
    { lat: 49.0234924, lng: 20.3705029 },
    { lat: 49.0238256, lng: 20.3702319 },
    { lat: 49.0250402, lng: 20.3698731 },
    { lat: 49.0255889, lng: 20.3695568 },
    { lat: 49.0256491, lng: 20.3695995 },
    { lat: 49.0261046, lng: 20.3699975 },
    { lat: 49.0248399, lng: 20.3721088 },
    { lat: 49.025098, lng: 20.3724383 },
    { lat: 49.0254384, lng: 20.3726844 },
    { lat: 49.0259041, lng: 20.3732 },
    { lat: 49.0262006, lng: 20.3736782 },
    { lat: 49.0263053, lng: 20.3737059 },
    { lat: 49.0265873, lng: 20.3742173 },
    { lat: 49.0274003, lng: 20.3750426 },
    { lat: 49.0281046, lng: 20.3756756 },
    { lat: 49.0302486, lng: 20.3765295 },
    { lat: 49.0304069, lng: 20.3767516 },
    { lat: 49.030584, lng: 20.3768529 },
    { lat: 49.0311725, lng: 20.3769605 },
    { lat: 49.0320043, lng: 20.3772144 },
    { lat: 49.0347537, lng: 20.3788582 },
    { lat: 49.0356632, lng: 20.3791689 },
    { lat: 49.0368901, lng: 20.3804581 },
    { lat: 49.0390793, lng: 20.3820279 },
    { lat: 49.0396071, lng: 20.3824905 },
    { lat: 49.0400477, lng: 20.3832938 },
    { lat: 49.0402016, lng: 20.3834008 },
    { lat: 49.0406605, lng: 20.3839761 },
    { lat: 49.0410452, lng: 20.3840831 },
    { lat: 49.0422605, lng: 20.3848349 },
    { lat: 49.0428703, lng: 20.3852935 },
    { lat: 49.0433405, lng: 20.3857512 },
    { lat: 49.0452565, lng: 20.3879529 },
    { lat: 49.046027, lng: 20.389106 },
    { lat: 49.046279, lng: 20.388879 },
    { lat: 49.046545, lng: 20.388677 },
    { lat: 49.046744, lng: 20.388512 },
    { lat: 49.047008, lng: 20.388262 },
    { lat: 49.047213, lng: 20.388083 },
    { lat: 49.047347, lng: 20.387966 },
    { lat: 49.047474, lng: 20.387845 },
    { lat: 49.04766, lng: 20.387696 },
    { lat: 49.047914, lng: 20.387508 },
    { lat: 49.04811, lng: 20.387341 },
    { lat: 49.048604, lng: 20.386846 },
    { lat: 49.049122, lng: 20.386341 },
    { lat: 49.049571, lng: 20.385948 },
    { lat: 49.049717, lng: 20.385798 },
    { lat: 49.049944, lng: 20.385456 },
    { lat: 49.050217, lng: 20.384925 },
    { lat: 49.050493, lng: 20.384382 },
    { lat: 49.050891, lng: 20.383593 },
    { lat: 49.051233, lng: 20.382811 },
    { lat: 49.051406, lng: 20.382372 },
    { lat: 49.051564, lng: 20.381953 },
    { lat: 49.051838, lng: 20.381186 },
    { lat: 49.05193, lng: 20.380888 },
    { lat: 49.052067, lng: 20.380339 },
    { lat: 49.052124, lng: 20.379914 },
    { lat: 49.052201, lng: 20.379549 },
    { lat: 49.05243, lng: 20.378911 },
    { lat: 49.052561, lng: 20.3786 },
    { lat: 49.052794, lng: 20.378099 },
    { lat: 49.052933, lng: 20.377752 },
    { lat: 49.053091, lng: 20.377316 },
    { lat: 49.053107, lng: 20.377332 },
    { lat: 49.053243, lng: 20.377204 },
    { lat: 49.053638, lng: 20.376766 },
    { lat: 49.053972, lng: 20.376443 },
    { lat: 49.054308, lng: 20.376138 },
    { lat: 49.055442, lng: 20.37529 },
    { lat: 49.055934, lng: 20.375038 },
    { lat: 49.056416, lng: 20.374786 },
    { lat: 49.05643, lng: 20.374778 },
    { lat: 49.056525, lng: 20.374723 },
    { lat: 49.056683, lng: 20.374642 },
    { lat: 49.056737, lng: 20.374614 },
    { lat: 49.0566277, lng: 20.3742279 },
    { lat: 49.0563454, lng: 20.3737675 },
    { lat: 49.0559909, lng: 20.3735631 },
    { lat: 49.0539765, lng: 20.3728561 },
    { lat: 49.0532918, lng: 20.3724763 },
    { lat: 49.0516957, lng: 20.3710196 },
    { lat: 49.0515364, lng: 20.3706787 },
    { lat: 49.0515152, lng: 20.3707477 },
    { lat: 49.0513916, lng: 20.3711906 },
    { lat: 49.0509154, lng: 20.3716948 },
    { lat: 49.0506932, lng: 20.3717411 },
    { lat: 49.049917, lng: 20.3712313 },
    { lat: 49.049322, lng: 20.3706034 },
    { lat: 49.0469006, lng: 20.3675052 },
    { lat: 49.0458906, lng: 20.366359 },
    { lat: 49.0453959, lng: 20.3655314 },
    { lat: 49.0439318, lng: 20.3635876 },
    { lat: 49.0427984, lng: 20.361777 },
    { lat: 49.0416626, lng: 20.3597328 },
    { lat: 49.0402933, lng: 20.3576261 },
    { lat: 49.0367046, lng: 20.3538997 },
    { lat: 49.0346349, lng: 20.3513828 },
    { lat: 49.0336422, lng: 20.3500501 },
    { lat: 49.0328681, lng: 20.3491581 },
    { lat: 49.0325232, lng: 20.3490941 },
    { lat: 49.0304361, lng: 20.3472049 },
    { lat: 49.0305979, lng: 20.3467875 },
    { lat: 49.0304794, lng: 20.3466628 },
    { lat: 49.030164, lng: 20.3464566 },
    { lat: 49.0290523, lng: 20.3462137 },
    { lat: 49.028626, lng: 20.3460214 },
    { lat: 49.0269879, lng: 20.3459076 },
    { lat: 49.0266569, lng: 20.3458223 },
    { lat: 49.0252555, lng: 20.345192 },
    { lat: 49.0247618, lng: 20.3447732 },
    { lat: 49.0243185, lng: 20.344286 },
    { lat: 49.0229984, lng: 20.3431914 },
    { lat: 49.0224695, lng: 20.3429082 },
    { lat: 49.0219014, lng: 20.3424569 },
    { lat: 49.0200729, lng: 20.3405467 },
    { lat: 49.0197766, lng: 20.3402371 },
    { lat: 49.0189399, lng: 20.3397435 },
    { lat: 49.0184133, lng: 20.3392868 },
    { lat: 49.0177874, lng: 20.3386231 },
    { lat: 49.0174473, lng: 20.338149 },
    { lat: 49.0171291, lng: 20.3380182 },
    { lat: 49.0156999, lng: 20.3377494 },
    { lat: 49.0150424, lng: 20.3368173 },
    { lat: 49.0146281, lng: 20.3355661 },
    { lat: 49.0135174, lng: 20.335297 },
    { lat: 49.0131519, lng: 20.3350484 },
    { lat: 49.0128185, lng: 20.3349246 },
    { lat: 49.0121147, lng: 20.3342242 },
    { lat: 49.0115659, lng: 20.3338345 },
    { lat: 49.0105933, lng: 20.3317732 },
    { lat: 49.0101856, lng: 20.3311899 },
    { lat: 49.010439, lng: 20.3304936 },
    { lat: 49.010897, lng: 20.3295566 },
    { lat: 49.0114861, lng: 20.3279321 },
    { lat: 49.0115049, lng: 20.3272836 },
    { lat: 49.0113129, lng: 20.3258183 },
    { lat: 49.0104433, lng: 20.3253696 },
    { lat: 49.0097479, lng: 20.325236 },
    { lat: 49.0089264, lng: 20.3253368 },
  ],
  Gánovce: [
    { lat: 49.0465558, lng: 20.3316235 },
    { lat: 49.0465372, lng: 20.3316054 },
    { lat: 49.0423235, lng: 20.327731 },
    { lat: 49.0398498, lng: 20.3261612 },
    { lat: 49.0393184, lng: 20.3257268 },
    { lat: 49.0386248, lng: 20.3248285 },
    { lat: 49.0373477, lng: 20.323487 },
    { lat: 49.0362149, lng: 20.3197377 },
    { lat: 49.0349847, lng: 20.3148496 },
    { lat: 49.0352302, lng: 20.3146141 },
    { lat: 49.0357832, lng: 20.3134871 },
    { lat: 49.0366958, lng: 20.3119372 },
    { lat: 49.0374355, lng: 20.3109753 },
    { lat: 49.0370284, lng: 20.3080909 },
    { lat: 49.0370127, lng: 20.3079463 },
    { lat: 49.036922, lng: 20.3079329 },
    { lat: 49.035766, lng: 20.3076819 },
    { lat: 49.0352321, lng: 20.3069271 },
    { lat: 49.0341506, lng: 20.3049458 },
    { lat: 49.0326047, lng: 20.3024336 },
    { lat: 49.0313169, lng: 20.2992044 },
    { lat: 49.0300191, lng: 20.2952574 },
    { lat: 49.0282571, lng: 20.2945734 },
    { lat: 49.0249891, lng: 20.292834 },
    { lat: 49.0249423, lng: 20.2928188 },
    { lat: 49.021965, lng: 20.2916357 },
    { lat: 49.0207595, lng: 20.2910442 },
    { lat: 49.018116, lng: 20.290454 },
    { lat: 49.0159382, lng: 20.2901074 },
    { lat: 49.0144477, lng: 20.2900844 },
    { lat: 49.0131892, lng: 20.2901854 },
    { lat: 49.0118759, lng: 20.2904051 },
    { lat: 49.0114889, lng: 20.2905849 },
    { lat: 49.0118436, lng: 20.2920853 },
    { lat: 49.0118268, lng: 20.2929844 },
    { lat: 49.0117564, lng: 20.2936066 },
    { lat: 49.0113731, lng: 20.2949821 },
    { lat: 49.0112972, lng: 20.2958624 },
    { lat: 49.011304, lng: 20.2964414 },
    { lat: 49.0114141, lng: 20.2972989 },
    { lat: 49.011408, lng: 20.2980391 },
    { lat: 49.0117557, lng: 20.2995369 },
    { lat: 49.0118788, lng: 20.3008735 },
    { lat: 49.0120621, lng: 20.3016781 },
    { lat: 49.0121401, lng: 20.3031914 },
    { lat: 49.0122502, lng: 20.3039025 },
    { lat: 49.0121846, lng: 20.3043636 },
    { lat: 49.0118422, lng: 20.3051986 },
    { lat: 49.0113928, lng: 20.3053657 },
    { lat: 49.0110367, lng: 20.3053884 },
    { lat: 49.0106604, lng: 20.3054993 },
    { lat: 49.010422, lng: 20.3056571 },
    { lat: 49.0103414, lng: 20.3058099 },
    { lat: 49.0102868, lng: 20.3080284 },
    { lat: 49.009719, lng: 20.3105688 },
    { lat: 49.0095733, lng: 20.311428 },
    { lat: 49.0095254, lng: 20.3121088 },
    { lat: 49.0095282, lng: 20.3124102 },
    { lat: 49.0094169, lng: 20.3136032 },
    { lat: 49.0094966, lng: 20.3150165 },
    { lat: 49.0094263, lng: 20.315498 },
    { lat: 49.0093978, lng: 20.3165308 },
    { lat: 49.0093361, lng: 20.3167117 },
    { lat: 49.0093352, lng: 20.3172703 },
    { lat: 49.0092567, lng: 20.3177418 },
    { lat: 49.0092402, lng: 20.3185317 },
    { lat: 49.0091362, lng: 20.3191368 },
    { lat: 49.0090742, lng: 20.3194548 },
    { lat: 49.0090913, lng: 20.3194568 },
    { lat: 49.0107455, lng: 20.3197443 },
    { lat: 49.0121491, lng: 20.321067 },
    { lat: 49.0134483, lng: 20.3221404 },
    { lat: 49.0157123, lng: 20.3233739 },
    { lat: 49.0168226, lng: 20.3241616 },
    { lat: 49.0174256, lng: 20.3247264 },
    { lat: 49.0179799, lng: 20.3253412 },
    { lat: 49.0198658, lng: 20.3266472 },
    { lat: 49.0226199, lng: 20.3280065 },
    { lat: 49.0247928, lng: 20.3288471 },
    { lat: 49.0251686, lng: 20.3291249 },
    { lat: 49.0256168, lng: 20.3293055 },
    { lat: 49.0257484, lng: 20.3294143 },
    { lat: 49.0259115, lng: 20.3297323 },
    { lat: 49.0261632, lng: 20.3299182 },
    { lat: 49.0262977, lng: 20.3293841 },
    { lat: 49.0273289, lng: 20.329989 },
    { lat: 49.0272392, lng: 20.3303781 },
    { lat: 49.0275891, lng: 20.330545 },
    { lat: 49.028725, lng: 20.3310869 },
    { lat: 49.0287422, lng: 20.3310951 },
    { lat: 49.0301645, lng: 20.3315951 },
    { lat: 49.030839, lng: 20.3323799 },
    { lat: 49.0323411, lng: 20.3334304 },
    { lat: 49.033611, lng: 20.3345349 },
    { lat: 49.0350128, lng: 20.3360729 },
    { lat: 49.036233, lng: 20.3372895 },
    { lat: 49.0384152, lng: 20.3398835 },
    { lat: 49.038818, lng: 20.3391136 },
    { lat: 49.039035, lng: 20.3388742 },
    { lat: 49.0426496, lng: 20.3363992 },
    { lat: 49.0455627, lng: 20.3330774 },
    { lat: 49.0465558, lng: 20.3316235 },
  ],
  Štrba: [
    { lat: 49.0842504, lng: 20.0923023 },
    { lat: 49.0844949, lng: 20.0922792 },
    { lat: 49.0847412, lng: 20.092132 },
    { lat: 49.0851714, lng: 20.0921044 },
    { lat: 49.0862684, lng: 20.0918281 },
    { lat: 49.0872114, lng: 20.0911771 },
    { lat: 49.0873688, lng: 20.0911425 },
    { lat: 49.0875747, lng: 20.0911332 },
    { lat: 49.0879506, lng: 20.0915141 },
    { lat: 49.0881315, lng: 20.0916164 },
    { lat: 49.0887686, lng: 20.0916375 },
    { lat: 49.0889468, lng: 20.0915847 },
    { lat: 49.0894837, lng: 20.0916322 },
    { lat: 49.0900122, lng: 20.0915 },
    { lat: 49.0905925, lng: 20.091234 },
    { lat: 49.090835, lng: 20.0912129 },
    { lat: 49.0908162, lng: 20.0911126 },
    { lat: 49.0911208, lng: 20.0910684 },
    { lat: 49.0915245, lng: 20.0908636 },
    { lat: 49.0918854, lng: 20.0908486 },
    { lat: 49.0923145, lng: 20.0906805 },
    { lat: 49.0931031, lng: 20.0907725 },
    { lat: 49.0937235, lng: 20.0904056 },
    { lat: 49.0942883, lng: 20.0903954 },
    { lat: 49.0945908, lng: 20.0901634 },
    { lat: 49.0947951, lng: 20.0898937 },
    { lat: 49.0949376, lng: 20.0898292 },
    { lat: 49.0953434, lng: 20.0893676 },
    { lat: 49.0956346, lng: 20.0885786 },
    { lat: 49.0957984, lng: 20.088314 },
    { lat: 49.0959293, lng: 20.0881838 },
    { lat: 49.0961969, lng: 20.0880615 },
    { lat: 49.0966546, lng: 20.087467 },
    { lat: 49.0968371, lng: 20.0873829 },
    { lat: 49.0972094, lng: 20.0869844 },
    { lat: 49.097407, lng: 20.0869129 },
    { lat: 49.0980423, lng: 20.0871099 },
    { lat: 49.0988421, lng: 20.0853994 },
    { lat: 49.0991467, lng: 20.0856355 },
    { lat: 49.0995446, lng: 20.0855595 },
    { lat: 49.0995532, lng: 20.0853542 },
    { lat: 49.1001866, lng: 20.085285 },
    { lat: 49.1005507, lng: 20.0853472 },
    { lat: 49.1010968, lng: 20.0857348 },
    { lat: 49.1016392, lng: 20.0859168 },
    { lat: 49.1019275, lng: 20.0859723 },
    { lat: 49.102221, lng: 20.085878 },
    { lat: 49.102598, lng: 20.0859269 },
    { lat: 49.102904, lng: 20.085779 },
    { lat: 49.1031177, lng: 20.0858012 },
    { lat: 49.1033118, lng: 20.08567 },
    { lat: 49.1039078, lng: 20.0855914 },
    { lat: 49.1040837, lng: 20.0852969 },
    { lat: 49.1041464, lng: 20.0850635 },
    { lat: 49.1044596, lng: 20.0848754 },
    { lat: 49.1047871, lng: 20.0848664 },
    { lat: 49.1055124, lng: 20.0844581 },
    { lat: 49.1061345, lng: 20.0843817 },
    { lat: 49.1065061, lng: 20.0842168 },
    { lat: 49.1067136, lng: 20.0842199 },
    { lat: 49.1070839, lng: 20.084105 },
    { lat: 49.1076226, lng: 20.0837019 },
    { lat: 49.1079936, lng: 20.0832685 },
    { lat: 49.1085711, lng: 20.0829845 },
    { lat: 49.1091569, lng: 20.0830453 },
    { lat: 49.1095374, lng: 20.0834572 },
    { lat: 49.1098, lng: 20.0832983 },
    { lat: 49.1102971, lng: 20.0825884 },
    { lat: 49.1110736, lng: 20.0817467 },
    { lat: 49.1111686, lng: 20.0815932 },
    { lat: 49.1112543, lng: 20.0811246 },
    { lat: 49.1114685, lng: 20.0808797 },
    { lat: 49.1125239, lng: 20.0801917 },
    { lat: 49.1127517, lng: 20.0799494 },
    { lat: 49.113366, lng: 20.0797985 },
    { lat: 49.1135739, lng: 20.0796192 },
    { lat: 49.1143445, lng: 20.0794064 },
    { lat: 49.1147053, lng: 20.0790584 },
    { lat: 49.1150822, lng: 20.078856 },
    { lat: 49.1153534, lng: 20.0787652 },
    { lat: 49.1156456, lng: 20.0790255 },
    { lat: 49.1157659, lng: 20.0789379 },
    { lat: 49.116199, lng: 20.0779313 },
    { lat: 49.1165467, lng: 20.077848 },
    { lat: 49.1167448, lng: 20.0775959 },
    { lat: 49.116819, lng: 20.0773128 },
    { lat: 49.1169412, lng: 20.0762769 },
    { lat: 49.117119, lng: 20.0760548 },
    { lat: 49.1173677, lng: 20.0759963 },
    { lat: 49.1178134, lng: 20.0756852 },
    { lat: 49.1183086, lng: 20.0757864 },
    { lat: 49.1189878, lng: 20.0752363 },
    { lat: 49.1189537, lng: 20.0749516 },
    { lat: 49.1190426, lng: 20.0745397 },
    { lat: 49.1192491, lng: 20.0743016 },
    { lat: 49.1193361, lng: 20.0738094 },
    { lat: 49.1195365, lng: 20.073342 },
    { lat: 49.119475, lng: 20.0731584 },
    { lat: 49.1193011, lng: 20.0730328 },
    { lat: 49.1191732, lng: 20.0728242 },
    { lat: 49.1190756, lng: 20.0719842 },
    { lat: 49.1192894, lng: 20.070982 },
    { lat: 49.1192845, lng: 20.0707538 },
    { lat: 49.1191913, lng: 20.0703714 },
    { lat: 49.1191867, lng: 20.0699311 },
    { lat: 49.1196769, lng: 20.0686157 },
    { lat: 49.1201712, lng: 20.0682539 },
    { lat: 49.1202377, lng: 20.0678955 },
    { lat: 49.1207286, lng: 20.0675372 },
    { lat: 49.1208334, lng: 20.0673702 },
    { lat: 49.1218588, lng: 20.0687082 },
    { lat: 49.1221049, lng: 20.069385 },
    { lat: 49.1258598, lng: 20.0690831 },
    { lat: 49.1262323, lng: 20.0688535 },
    { lat: 49.1272433, lng: 20.0688993 },
    { lat: 49.12944, lng: 20.0680907 },
    { lat: 49.1301022, lng: 20.0677318 },
    { lat: 49.1327956, lng: 20.0673054 },
    { lat: 49.1370893, lng: 20.0670143 },
    { lat: 49.1408594, lng: 20.0650463 },
    { lat: 49.1454866, lng: 20.0644064 },
    { lat: 49.1470715, lng: 20.0636946 },
    { lat: 49.1486509, lng: 20.063074 },
    { lat: 49.1500338, lng: 20.0610358 },
    { lat: 49.1516806, lng: 20.059444 },
    { lat: 49.1522276, lng: 20.0583061 },
    { lat: 49.1531358, lng: 20.0584021 },
    { lat: 49.1554905, lng: 20.0574887 },
    { lat: 49.1603315, lng: 20.0549974 },
    { lat: 49.1630775, lng: 20.05283 },
    { lat: 49.170972, lng: 20.047695 },
    { lat: 49.1709959, lng: 20.0464021 },
    { lat: 49.171041, lng: 20.045655 },
    { lat: 49.1710452, lng: 20.0450723 },
    { lat: 49.1710033, lng: 20.0445683 },
    { lat: 49.170943, lng: 20.044224 },
    { lat: 49.171057, lng: 20.043796 },
    { lat: 49.170998, lng: 20.0435 },
    { lat: 49.1710007, lng: 20.0431192 },
    { lat: 49.170982, lng: 20.042653 },
    { lat: 49.170713, lng: 20.042089 },
    { lat: 49.1707365, lng: 20.0417517 },
    { lat: 49.1707684, lng: 20.0413246 },
    { lat: 49.171065, lng: 20.040854 },
    { lat: 49.171466, lng: 20.0402175 },
    { lat: 49.171425, lng: 20.039012 },
    { lat: 49.171524, lng: 20.038116 },
    { lat: 49.1717431, lng: 20.0372241 },
    { lat: 49.1717501, lng: 20.0369345 },
    { lat: 49.1720062, lng: 20.0369666 },
    { lat: 49.1719918, lng: 20.0365912 },
    { lat: 49.1721586, lng: 20.0358266 },
    { lat: 49.1723674, lng: 20.035427 },
    { lat: 49.1723884, lng: 20.0347726 },
    { lat: 49.172333, lng: 20.034104 },
    { lat: 49.172267, lng: 20.033354 },
    { lat: 49.172145, lng: 20.032871 },
    { lat: 49.172121, lng: 20.032299 },
    { lat: 49.171965, lng: 20.031819 },
    { lat: 49.171624, lng: 20.031018 },
    { lat: 49.171459, lng: 20.030134 },
    { lat: 49.17124, lng: 20.029374 },
    { lat: 49.171094, lng: 20.028736 },
    { lat: 49.171037, lng: 20.027777 },
    { lat: 49.1707496, lng: 20.0270107 },
    { lat: 49.1704736, lng: 20.027576 },
    { lat: 49.1699263, lng: 20.0274718 },
    { lat: 49.1693618, lng: 20.0275462 },
    { lat: 49.1692577, lng: 20.0277161 },
    { lat: 49.1690256, lng: 20.0277128 },
    { lat: 49.1689426, lng: 20.027804 },
    { lat: 49.1686752, lng: 20.0273898 },
    { lat: 49.1685106, lng: 20.0274168 },
    { lat: 49.1681948, lng: 20.0274694 },
    { lat: 49.1632035, lng: 20.0260996 },
    { lat: 49.160108, lng: 20.0258959 },
    { lat: 49.1551251, lng: 20.0253946 },
    { lat: 49.1525999, lng: 20.0255904 },
    { lat: 49.1516954, lng: 20.0260966 },
    { lat: 49.1503003, lng: 20.0268976 },
    { lat: 49.1493112, lng: 20.0272172 },
    { lat: 49.1490574, lng: 20.0274854 },
    { lat: 49.1486536, lng: 20.0277429 },
    { lat: 49.1484087, lng: 20.0277555 },
    { lat: 49.1479497, lng: 20.0280146 },
    { lat: 49.1477327, lng: 20.0279426 },
    { lat: 49.1473004, lng: 20.0283286 },
    { lat: 49.1468283, lng: 20.0285738 },
    { lat: 49.1466264, lng: 20.0289709 },
    { lat: 49.1461213, lng: 20.0293998 },
    { lat: 49.1461754, lng: 20.0297274 },
    { lat: 49.1459825, lng: 20.0299664 },
    { lat: 49.1459068, lng: 20.0302086 },
    { lat: 49.145813, lng: 20.0301992 },
    { lat: 49.1457885, lng: 20.0304764 },
    { lat: 49.1456009, lng: 20.030625 },
    { lat: 49.1456912, lng: 20.03107 },
    { lat: 49.1457604, lng: 20.0319019 },
    { lat: 49.1457287, lng: 20.0321985 },
    { lat: 49.1455785, lng: 20.0322712 },
    { lat: 49.1452307, lng: 20.032903 },
    { lat: 49.1448306, lng: 20.0332528 },
    { lat: 49.1446831, lng: 20.0332806 },
    { lat: 49.1443901, lng: 20.0335817 },
    { lat: 49.1442824, lng: 20.0335913 },
    { lat: 49.1440923, lng: 20.0337605 },
    { lat: 49.1438884, lng: 20.0336523 },
    { lat: 49.1432839, lng: 20.033958 },
    { lat: 49.1429667, lng: 20.0344858 },
    { lat: 49.1428823, lng: 20.0347061 },
    { lat: 49.1429433, lng: 20.0350409 },
    { lat: 49.142769, lng: 20.0352688 },
    { lat: 49.1425825, lng: 20.0353078 },
    { lat: 49.1422797, lng: 20.0351465 },
    { lat: 49.1412352, lng: 20.035297 },
    { lat: 49.140951, lng: 20.0354227 },
    { lat: 49.1404689, lng: 20.0354231 },
    { lat: 49.1401143, lng: 20.0352088 },
    { lat: 49.1399683, lng: 20.0350271 },
    { lat: 49.1393531, lng: 20.0350438 },
    { lat: 49.1390876, lng: 20.0348901 },
    { lat: 49.1389407, lng: 20.0346592 },
    { lat: 49.1378838, lng: 20.0336638 },
    { lat: 49.1373829, lng: 20.033283 },
    { lat: 49.1370825, lng: 20.0333757 },
    { lat: 49.136429, lng: 20.0333706 },
    { lat: 49.1363568, lng: 20.033421 },
    { lat: 49.1362912, lng: 20.0336337 },
    { lat: 49.136086, lng: 20.0337055 },
    { lat: 49.135947, lng: 20.0338321 },
    { lat: 49.1356841, lng: 20.0335749 },
    { lat: 49.1353762, lng: 20.0336453 },
    { lat: 49.1347862, lng: 20.033624 },
    { lat: 49.134353, lng: 20.0334122 },
    { lat: 49.1341593, lng: 20.0333631 },
    { lat: 49.134003, lng: 20.0334464 },
    { lat: 49.133718, lng: 20.033255 },
    { lat: 49.1333572, lng: 20.0332161 },
    { lat: 49.1331722, lng: 20.0330446 },
    { lat: 49.132305, lng: 20.0348549 },
    { lat: 49.1319729, lng: 20.0353222 },
    { lat: 49.1299864, lng: 20.0375702 },
    { lat: 49.1287672, lng: 20.0390349 },
    { lat: 49.1261304, lng: 20.0415792 },
    { lat: 49.1217821, lng: 20.0421041 },
    { lat: 49.1207337, lng: 20.0421011 },
    { lat: 49.1191138, lng: 20.0420965 },
    { lat: 49.1170354, lng: 20.0420007 },
    { lat: 49.1145518, lng: 20.0422157 },
    { lat: 49.1131677, lng: 20.0418048 },
    { lat: 49.1112336, lng: 20.0401264 },
    { lat: 49.110758, lng: 20.0392716 },
    { lat: 49.1099343, lng: 20.0384386 },
    { lat: 49.1090694, lng: 20.0381419 },
    { lat: 49.1084437, lng: 20.0378252 },
    { lat: 49.108486, lng: 20.037488 },
    { lat: 49.108312, lng: 20.037754 },
    { lat: 49.108016, lng: 20.037832 },
    { lat: 49.107725, lng: 20.037332 },
    { lat: 49.107333, lng: 20.037177 },
    { lat: 49.107075, lng: 20.037022 },
    { lat: 49.106793, lng: 20.037006 },
    { lat: 49.106498, lng: 20.037174 },
    { lat: 49.106293, lng: 20.037338 },
    { lat: 49.106069, lng: 20.037531 },
    { lat: 49.105819, lng: 20.037421 },
    { lat: 49.105593, lng: 20.037357 },
    { lat: 49.105158, lng: 20.037124 },
    { lat: 49.104968, lng: 20.037049 },
    { lat: 49.104538, lng: 20.036915 },
    { lat: 49.104216, lng: 20.037121 },
    { lat: 49.104031, lng: 20.036957 },
    { lat: 49.103894, lng: 20.036912 },
    { lat: 49.103812, lng: 20.036772 },
    { lat: 49.10362, lng: 20.036804 },
    { lat: 49.10358, lng: 20.036873 },
    { lat: 49.103396, lng: 20.036778 },
    { lat: 49.103255, lng: 20.036808 },
    { lat: 49.103004, lng: 20.03663 },
    { lat: 49.102545, lng: 20.036595 },
    { lat: 49.102336, lng: 20.036213 },
    { lat: 49.102069, lng: 20.036129 },
    { lat: 49.101843, lng: 20.036013 },
    { lat: 49.101521, lng: 20.035732 },
    { lat: 49.101263, lng: 20.035492 },
    { lat: 49.101141, lng: 20.03548 },
    { lat: 49.100814, lng: 20.035357 },
    { lat: 49.100562, lng: 20.035353 },
    { lat: 49.100312, lng: 20.035189 },
    { lat: 49.099971, lng: 20.03528 },
    { lat: 49.099672, lng: 20.035136 },
    { lat: 49.099472, lng: 20.034936 },
    { lat: 49.098407, lng: 20.03387 },
    { lat: 49.098396, lng: 20.033888 },
    { lat: 49.098195, lng: 20.03373 },
    { lat: 49.097818, lng: 20.03338 },
    { lat: 49.097558, lng: 20.033261 },
    { lat: 49.097452, lng: 20.033298 },
    { lat: 49.097118, lng: 20.033282 },
    { lat: 49.096716, lng: 20.033216 },
    { lat: 49.09611, lng: 20.033161 },
    { lat: 49.095619, lng: 20.032963 },
    { lat: 49.09524, lng: 20.032776 },
    { lat: 49.094723, lng: 20.032718 },
    { lat: 49.094491, lng: 20.032698 },
    { lat: 49.09421, lng: 20.032666 },
    { lat: 49.093872, lng: 20.032287 },
    { lat: 49.093309, lng: 20.032224 },
    { lat: 49.092913, lng: 20.032028 },
    { lat: 49.092451, lng: 20.031743 },
    { lat: 49.092081, lng: 20.031189 },
    { lat: 49.091666, lng: 20.031012 },
    { lat: 49.091404, lng: 20.031011 },
    { lat: 49.091042, lng: 20.0309 },
    { lat: 49.090878, lng: 20.030823 },
    { lat: 49.090427, lng: 20.030882 },
    { lat: 49.090104, lng: 20.030725 },
    { lat: 49.090094, lng: 20.030699 },
    { lat: 49.089885, lng: 20.030453 },
    { lat: 49.089719, lng: 20.030324 },
    { lat: 49.089514, lng: 20.03036 },
    { lat: 49.089371, lng: 20.030596 },
    { lat: 49.089112, lng: 20.030439 },
    { lat: 49.088323, lng: 20.029728 },
    { lat: 49.08809, lng: 20.02973 },
    { lat: 49.087788, lng: 20.029543 },
    { lat: 49.087547, lng: 20.029186 },
    { lat: 49.087456, lng: 20.028832 },
    { lat: 49.087436, lng: 20.028862 },
    { lat: 49.08675, lng: 20.029949 },
    { lat: 49.086627, lng: 20.030043 },
    { lat: 49.08567, lng: 20.030102 },
    { lat: 49.085457, lng: 20.0302 },
    { lat: 49.084837, lng: 20.029948 },
    { lat: 49.084802, lng: 20.030025 },
    { lat: 49.084773, lng: 20.030057 },
    { lat: 49.084435, lng: 20.030739 },
    { lat: 49.084098, lng: 20.031324 },
    { lat: 49.08378, lng: 20.031661 },
    { lat: 49.082748, lng: 20.031395 },
    { lat: 49.082365, lng: 20.031077 },
    { lat: 49.081878, lng: 20.031218 },
    { lat: 49.081025, lng: 20.031037 },
    { lat: 49.080172, lng: 20.030675 },
    { lat: 49.079404, lng: 20.030486 },
    { lat: 49.078583, lng: 20.030166 },
    { lat: 49.078171, lng: 20.030123 },
    { lat: 49.078095, lng: 20.030134 },
    { lat: 49.078037, lng: 20.030153 },
    { lat: 49.077987, lng: 20.03016 },
    { lat: 49.077828, lng: 20.030163 },
    { lat: 49.077248, lng: 20.029474 },
    { lat: 49.076946, lng: 20.029218 },
    { lat: 49.076476, lng: 20.029028 },
    { lat: 49.076472, lng: 20.02899 },
    { lat: 49.076366, lng: 20.029042 },
    { lat: 49.076153, lng: 20.028754 },
    { lat: 49.075957, lng: 20.028789 },
    { lat: 49.075218, lng: 20.028113 },
    { lat: 49.074847, lng: 20.027499 },
    { lat: 49.074728, lng: 20.027465 },
    { lat: 49.074727, lng: 20.027465 },
    { lat: 49.074601, lng: 20.027429 },
    { lat: 49.074267, lng: 20.02711 },
    { lat: 49.073919, lng: 20.026819 },
    { lat: 49.073835, lng: 20.026754 },
    { lat: 49.073543, lng: 20.026695 },
    { lat: 49.073224, lng: 20.026606 },
    { lat: 49.072992, lng: 20.026746 },
    { lat: 49.072872, lng: 20.026687 },
    { lat: 49.072709, lng: 20.026697 },
    { lat: 49.072546, lng: 20.026706 },
    { lat: 49.072141, lng: 20.026712 },
    { lat: 49.072107, lng: 20.026715 },
    { lat: 49.072109, lng: 20.026188 },
    { lat: 49.071883, lng: 20.02576 },
    { lat: 49.071751, lng: 20.02577 },
    { lat: 49.071631, lng: 20.02582 },
    { lat: 49.071583, lng: 20.025788 },
    { lat: 49.071533, lng: 20.025656 },
    { lat: 49.071657, lng: 20.025396 },
    { lat: 49.071641, lng: 20.025148 },
    { lat: 49.071525, lng: 20.024976 },
    { lat: 49.071499, lng: 20.024773 },
    { lat: 49.07147, lng: 20.024582 },
    { lat: 49.071498, lng: 20.02452 },
    { lat: 49.071524, lng: 20.024499 },
    { lat: 49.071572, lng: 20.024539 },
    { lat: 49.07169, lng: 20.024688 },
    { lat: 49.071754, lng: 20.02462 },
    { lat: 49.071728, lng: 20.024203 },
    { lat: 49.07171, lng: 20.023902 },
    { lat: 49.071408, lng: 20.023507 },
    { lat: 49.071383, lng: 20.023438 },
    { lat: 49.071418, lng: 20.023132 },
    { lat: 49.071689, lng: 20.023241 },
    { lat: 49.071837, lng: 20.023335 },
    { lat: 49.071936, lng: 20.023332 },
    { lat: 49.071976, lng: 20.023206 },
    { lat: 49.071924, lng: 20.022999 },
    { lat: 49.071746, lng: 20.022592 },
    { lat: 49.071713, lng: 20.022257 },
    { lat: 49.071886, lng: 20.021938 },
    { lat: 49.071846, lng: 20.021819 },
    { lat: 49.071916, lng: 20.021642 },
    { lat: 49.071844, lng: 20.021389 },
    { lat: 49.071669, lng: 20.021447 },
    { lat: 49.071498, lng: 20.021621 },
    { lat: 49.071399, lng: 20.021454 },
    { lat: 49.071426, lng: 20.021119 },
    { lat: 49.071527, lng: 20.020697 },
    { lat: 49.071462, lng: 20.020467 },
    { lat: 49.071426, lng: 20.020338 },
    { lat: 49.07124, lng: 20.020328 },
    { lat: 49.071122, lng: 20.020157 },
    { lat: 49.071283, lng: 20.019669 },
    { lat: 49.071171, lng: 20.0195 },
    { lat: 49.070879, lng: 20.019377 },
    { lat: 49.070801, lng: 20.019412 },
    { lat: 49.070635, lng: 20.019541 },
    { lat: 49.06832, lng: 20.020192 },
    { lat: 49.068231, lng: 20.020175 },
    { lat: 49.067771, lng: 20.020087 },
    { lat: 49.066942, lng: 20.020553 },
    { lat: 49.066579, lng: 20.020935 },
    { lat: 49.065977, lng: 20.021567 },
    { lat: 49.065484, lng: 20.022857 },
    { lat: 49.064425, lng: 20.024156 },
    { lat: 49.06425, lng: 20.024765 },
    { lat: 49.064052, lng: 20.024843 },
    { lat: 49.062406, lng: 20.025264 },
    { lat: 49.061015, lng: 20.025652 },
    { lat: 49.060945, lng: 20.025693 },
    { lat: 49.061211, lng: 20.026907 },
    { lat: 49.059505, lng: 20.027067 },
    { lat: 49.058935, lng: 20.027121 },
    { lat: 49.058511, lng: 20.027102 },
    { lat: 49.057994, lng: 20.026979 },
    { lat: 49.057658, lng: 20.02706 },
    { lat: 49.056771, lng: 20.026862 },
    { lat: 49.056294, lng: 20.02706 },
    { lat: 49.055583, lng: 20.027025 },
    { lat: 49.055326, lng: 20.026576 },
    { lat: 49.054912, lng: 20.026401 },
    { lat: 49.054359, lng: 20.025661 },
    { lat: 49.05429, lng: 20.025274 },
    { lat: 49.053979, lng: 20.024914 },
    { lat: 49.053432, lng: 20.024141 },
    { lat: 49.053158, lng: 20.023908 },
    { lat: 49.053027, lng: 20.023841 },
    { lat: 49.052991, lng: 20.02365 },
    { lat: 49.052906, lng: 20.023786 },
    { lat: 49.052754, lng: 20.023793 },
    { lat: 49.052588, lng: 20.023708 },
    { lat: 49.05245, lng: 20.023478 },
    { lat: 49.052322, lng: 20.023508 },
    { lat: 49.052198, lng: 20.023376 },
    { lat: 49.051581, lng: 20.023382 },
    { lat: 49.051384, lng: 20.023336 },
    { lat: 49.051221, lng: 20.023159 },
    { lat: 49.051093, lng: 20.023168 },
    { lat: 49.051042, lng: 20.023011 },
    { lat: 49.050945, lng: 20.02298 },
    { lat: 49.05088, lng: 20.023031 },
    { lat: 49.050791, lng: 20.022806 },
    { lat: 49.050682, lng: 20.022838 },
    { lat: 49.050603, lng: 20.022592 },
    { lat: 49.050398, lng: 20.022669 },
    { lat: 49.050332, lng: 20.02257 },
    { lat: 49.050365, lng: 20.022274 },
    { lat: 49.050164, lng: 20.022369 },
    { lat: 49.050119, lng: 20.022345 },
    { lat: 49.050121, lng: 20.022134 },
    { lat: 49.050081, lng: 20.022072 },
    { lat: 49.049977, lng: 20.022134 },
    { lat: 49.049966, lng: 20.022027 },
    { lat: 49.049841, lng: 20.021925 },
    { lat: 49.049826, lng: 20.021667 },
    { lat: 49.049784, lng: 20.021554 },
    { lat: 49.049646, lng: 20.021496 },
    { lat: 49.049621, lng: 20.021267 },
    { lat: 49.049571, lng: 20.021268 },
    { lat: 49.049511, lng: 20.021511 },
    { lat: 49.049347, lng: 20.021487 },
    { lat: 49.0493124, lng: 20.0212945 },
    { lat: 49.04927, lng: 20.021059 },
    { lat: 49.049193, lng: 20.021186 },
    { lat: 49.049068, lng: 20.021104 },
    { lat: 49.04819, lng: 20.023296 },
    { lat: 49.047768, lng: 20.022734 },
    { lat: 49.047371, lng: 20.022204 },
    { lat: 49.046611, lng: 20.021919 },
    { lat: 49.046368, lng: 20.022425 },
    { lat: 49.046206, lng: 20.02237 },
    { lat: 49.046056, lng: 20.022231 },
    { lat: 49.046029, lng: 20.022232 },
    { lat: 49.044522, lng: 20.021373 },
    { lat: 49.044015, lng: 20.021036 },
    { lat: 49.042381, lng: 20.019433 },
    { lat: 49.042152, lng: 20.019031 },
    { lat: 49.041628, lng: 20.018172 },
    { lat: 49.041563, lng: 20.018065 },
    { lat: 49.041094, lng: 20.017612 },
    { lat: 49.040362, lng: 20.016905 },
    { lat: 49.040341, lng: 20.016894 },
    { lat: 49.040313, lng: 20.016879 },
    { lat: 49.039601, lng: 20.016491 },
    { lat: 49.039163, lng: 20.015821 },
    { lat: 49.038768, lng: 20.015194 },
    { lat: 49.038498, lng: 20.015151 },
    { lat: 49.037908, lng: 20.014283 },
    { lat: 49.037658, lng: 20.013615 },
    { lat: 49.037004, lng: 20.013716 },
    { lat: 49.036571, lng: 20.013328 },
    { lat: 49.036045, lng: 20.012858 },
    { lat: 49.035438, lng: 20.01295 },
    { lat: 49.034828, lng: 20.013063 },
    { lat: 49.033108, lng: 20.013516 },
    { lat: 49.031924, lng: 20.013759 },
    { lat: 49.030496, lng: 20.014114 },
    { lat: 49.030308, lng: 20.014146 },
    { lat: 49.02807, lng: 20.014523 },
    { lat: 49.026812, lng: 20.014729 },
    { lat: 49.026272, lng: 20.014822 },
    { lat: 49.025953, lng: 20.014877 },
    { lat: 49.025176, lng: 20.014988 },
    { lat: 49.023684, lng: 20.015342 },
    { lat: 49.022987, lng: 20.015542 },
    { lat: 49.0226, lng: 20.015653 },
    { lat: 49.021853, lng: 20.015799 },
    { lat: 49.021733, lng: 20.015784 },
    { lat: 49.021251, lng: 20.01572 },
    { lat: 49.02014, lng: 20.015978 },
    { lat: 49.019545, lng: 20.015952 },
    { lat: 49.019201, lng: 20.015889 },
    { lat: 49.018654, lng: 20.015791 },
    { lat: 49.016839, lng: 20.015519 },
    { lat: 49.016829, lng: 20.015517 },
    { lat: 49.016771, lng: 20.015503 },
    { lat: 49.016363, lng: 20.0154 },
    { lat: 49.01606, lng: 20.01532 },
    { lat: 49.015998, lng: 20.015299 },
    { lat: 49.015977, lng: 20.015293 },
    { lat: 49.015843, lng: 20.0162234 },
    { lat: 49.015853, lng: 20.0164352 },
    { lat: 49.0159848, lng: 20.0168431 },
    { lat: 49.0159849, lng: 20.0171488 },
    { lat: 49.0159042, lng: 20.0174551 },
    { lat: 49.0156225, lng: 20.0179424 },
    { lat: 49.0155156, lng: 20.0183538 },
    { lat: 49.0154682, lng: 20.0191356 },
    { lat: 49.0152847, lng: 20.0201212 },
    { lat: 49.0153238, lng: 20.0205452 },
    { lat: 49.0157427, lng: 20.0216641 },
    { lat: 49.0160395, lng: 20.0223279 },
    { lat: 49.0161829, lng: 20.022475 },
    { lat: 49.0163951, lng: 20.0228914 },
    { lat: 49.0164539, lng: 20.0231441 },
    { lat: 49.0162858, lng: 20.0237655 },
    { lat: 49.0162711, lng: 20.023979 },
    { lat: 49.0164348, lng: 20.0245077 },
    { lat: 49.0164763, lng: 20.0248044 },
    { lat: 49.0163343, lng: 20.025348 },
    { lat: 49.0163088, lng: 20.0256572 },
    { lat: 49.0163524, lng: 20.0261417 },
    { lat: 49.016323, lng: 20.0267083 },
    { lat: 49.0164173, lng: 20.0282261 },
    { lat: 49.0165801, lng: 20.0288991 },
    { lat: 49.0171173, lng: 20.0300704 },
    { lat: 49.0171878, lng: 20.0304935 },
    { lat: 49.0169407, lng: 20.0315735 },
    { lat: 49.0168552, lng: 20.0324751 },
    { lat: 49.0169249, lng: 20.0336501 },
    { lat: 49.0168738, lng: 20.0341548 },
    { lat: 49.0169851, lng: 20.0346451 },
    { lat: 49.0170395, lng: 20.0352252 },
    { lat: 49.0169028, lng: 20.03546 },
    { lat: 49.0164939, lng: 20.0357885 },
    { lat: 49.0159921, lng: 20.0365077 },
    { lat: 49.0156131, lng: 20.0373454 },
    { lat: 49.015598, lng: 20.0379575 },
    { lat: 49.0156453, lng: 20.0381787 },
    { lat: 49.0161591, lng: 20.0383731 },
    { lat: 49.0163857, lng: 20.0385958 },
    { lat: 49.016443, lng: 20.0389209 },
    { lat: 49.0163386, lng: 20.0392871 },
    { lat: 49.0161689, lng: 20.0394579 },
    { lat: 49.0156119, lng: 20.0396075 },
    { lat: 49.0153311, lng: 20.0399809 },
    { lat: 49.0150157, lng: 20.0409908 },
    { lat: 49.015033, lng: 20.041522 },
    { lat: 49.015141, lng: 20.0420912 },
    { lat: 49.0154718, lng: 20.0433446 },
    { lat: 49.0155109, lng: 20.0436344 },
    { lat: 49.0154878, lng: 20.04398 },
    { lat: 49.0153545, lng: 20.0441868 },
    { lat: 49.0151131, lng: 20.0443723 },
    { lat: 49.0142619, lng: 20.0445979 },
    { lat: 49.0140329, lng: 20.0447513 },
    { lat: 49.0138448, lng: 20.0450254 },
    { lat: 49.0137332, lng: 20.0452958 },
    { lat: 49.0136765, lng: 20.0455574 },
    { lat: 49.0136811, lng: 20.0459739 },
    { lat: 49.0141188, lng: 20.0468666 },
    { lat: 49.0141675, lng: 20.0472538 },
    { lat: 49.0140254, lng: 20.0476071 },
    { lat: 49.0137735, lng: 20.047697 },
    { lat: 49.0134028, lng: 20.0476663 },
    { lat: 49.0130426, lng: 20.0479463 },
    { lat: 49.0125605, lng: 20.0484628 },
    { lat: 49.0120457, lng: 20.0486797 },
    { lat: 49.0119578, lng: 20.0488735 },
    { lat: 49.0119423, lng: 20.0499478 },
    { lat: 49.011877, lng: 20.0503569 },
    { lat: 49.0117679, lng: 20.0506647 },
    { lat: 49.0115407, lng: 20.0509435 },
    { lat: 49.0109915, lng: 20.0511999 },
    { lat: 49.0105856, lng: 20.0514659 },
    { lat: 49.0096585, lng: 20.0518736 },
    { lat: 49.0095784, lng: 20.0521406 },
    { lat: 49.0095968, lng: 20.0525881 },
    { lat: 49.0095261, lng: 20.0528151 },
    { lat: 49.0093464, lng: 20.0529911 },
    { lat: 49.0091026, lng: 20.0531154 },
    { lat: 49.0091442, lng: 20.0532492 },
    { lat: 49.0093053, lng: 20.0534438 },
    { lat: 49.0102251, lng: 20.0540363 },
    { lat: 49.0105429, lng: 20.0544302 },
    { lat: 49.0111813, lng: 20.0546136 },
    { lat: 49.0119852, lng: 20.0551264 },
    { lat: 49.0123921, lng: 20.0552747 },
    { lat: 49.0131075, lng: 20.0548677 },
    { lat: 49.0139262, lng: 20.05511 },
    { lat: 49.0142734, lng: 20.0549551 },
    { lat: 49.0142436, lng: 20.0557634 },
    { lat: 49.0139892, lng: 20.0573049 },
    { lat: 49.0139814, lng: 20.0576085 },
    { lat: 49.0142273, lng: 20.0583849 },
    { lat: 49.0144403, lng: 20.0598416 },
    { lat: 49.0160096, lng: 20.0603207 },
    { lat: 49.0178828, lng: 20.0615589 },
    { lat: 49.0185395, lng: 20.0621358 },
    { lat: 49.0207459, lng: 20.0626367 },
    { lat: 49.0214848, lng: 20.0612874 },
    { lat: 49.0234223, lng: 20.0603927 },
    { lat: 49.0241127, lng: 20.0594868 },
    { lat: 49.0249364, lng: 20.0591944 },
    { lat: 49.0250546, lng: 20.0590246 },
    { lat: 49.0262473, lng: 20.0580917 },
    { lat: 49.026683, lng: 20.057156 },
    { lat: 49.0272005, lng: 20.0562915 },
    { lat: 49.0278596, lng: 20.0557476 },
    { lat: 49.0299701, lng: 20.0547289 },
    { lat: 49.0303329, lng: 20.0543783 },
    { lat: 49.0305541, lng: 20.0542471 },
    { lat: 49.030523, lng: 20.0541807 },
    { lat: 49.030625, lng: 20.0540541 },
    { lat: 49.030606, lng: 20.0540063 },
    { lat: 49.0313438, lng: 20.0533745 },
    { lat: 49.0315578, lng: 20.0532415 },
    { lat: 49.0317541, lng: 20.0532232 },
    { lat: 49.0325797, lng: 20.0534129 },
    { lat: 49.0331557, lng: 20.0533998 },
    { lat: 49.0339155, lng: 20.0529464 },
    { lat: 49.0359188, lng: 20.0525863 },
    { lat: 49.0359483, lng: 20.0528344 },
    { lat: 49.0362178, lng: 20.053746 },
    { lat: 49.0364437, lng: 20.054477 },
    { lat: 49.0364951, lng: 20.054443 },
    { lat: 49.0366196, lng: 20.0549226 },
    { lat: 49.0366484, lng: 20.0551858 },
    { lat: 49.0365988, lng: 20.0552078 },
    { lat: 49.0366823, lng: 20.0554328 },
    { lat: 49.036719, lng: 20.0558307 },
    { lat: 49.0369771, lng: 20.0569092 },
    { lat: 49.0377937, lng: 20.0583929 },
    { lat: 49.0378674, lng: 20.0586763 },
    { lat: 49.0379546, lng: 20.0587445 },
    { lat: 49.0379657, lng: 20.0589631 },
    { lat: 49.0383367, lng: 20.0606197 },
    { lat: 49.0385688, lng: 20.0624859 },
    { lat: 49.038743, lng: 20.0629206 },
    { lat: 49.0387065, lng: 20.0632378 },
    { lat: 49.0388196, lng: 20.063609 },
    { lat: 49.0394664, lng: 20.0643601 },
    { lat: 49.0394823, lng: 20.0649947 },
    { lat: 49.0394149, lng: 20.0656034 },
    { lat: 49.0394688, lng: 20.0660086 },
    { lat: 49.0397108, lng: 20.0663914 },
    { lat: 49.0397564, lng: 20.0666803 },
    { lat: 49.0396086, lng: 20.0693429 },
    { lat: 49.0395882, lng: 20.0706612 },
    { lat: 49.0397576, lng: 20.072188 },
    { lat: 49.0399077, lng: 20.0744778 },
    { lat: 49.0398131, lng: 20.0760497 },
    { lat: 49.0390761, lng: 20.07932 },
    { lat: 49.039035, lng: 20.0804916 },
    { lat: 49.0374171, lng: 20.089641 },
    { lat: 49.0369213, lng: 20.0926768 },
    { lat: 49.0391023, lng: 20.0930503 },
    { lat: 49.0398491, lng: 20.093303 },
    { lat: 49.0407519, lng: 20.0937327 },
    { lat: 49.0425825, lng: 20.0951294 },
    { lat: 49.0445905, lng: 20.0968706 },
    { lat: 49.0446113, lng: 20.0971321 },
    { lat: 49.0462942, lng: 20.0987982 },
    { lat: 49.0468075, lng: 20.101178 },
    { lat: 49.0477237, lng: 20.1031285 },
    { lat: 49.0481031, lng: 20.1048991 },
    { lat: 49.0501088, lng: 20.106979 },
    { lat: 49.0503316, lng: 20.1072808 },
    { lat: 49.0519417, lng: 20.1068825 },
    { lat: 49.0522675, lng: 20.108137 },
    { lat: 49.0531893, lng: 20.1108981 },
    { lat: 49.0538051, lng: 20.1112263 },
    { lat: 49.0541776, lng: 20.1101637 },
    { lat: 49.0544529, lng: 20.1096211 },
    { lat: 49.0549212, lng: 20.1093954 },
    { lat: 49.0549797, lng: 20.1092258 },
    { lat: 49.0552799, lng: 20.1091344 },
    { lat: 49.0554705, lng: 20.1092483 },
    { lat: 49.0557727, lng: 20.109588 },
    { lat: 49.0559096, lng: 20.1094968 },
    { lat: 49.0559496, lng: 20.1096223 },
    { lat: 49.0564763, lng: 20.1096659 },
    { lat: 49.0566162, lng: 20.1095371 },
    { lat: 49.056867, lng: 20.1095024 },
    { lat: 49.0569735, lng: 20.1092644 },
    { lat: 49.0570747, lng: 20.1092061 },
    { lat: 49.0572493, lng: 20.1092051 },
    { lat: 49.0574066, lng: 20.1093063 },
    { lat: 49.0575477, lng: 20.1091494 },
    { lat: 49.0575776, lng: 20.1089728 },
    { lat: 49.057621, lng: 20.1089594 },
    { lat: 49.0576067, lng: 20.1090782 },
    { lat: 49.0576599, lng: 20.1091109 },
    { lat: 49.0577656, lng: 20.1090216 },
    { lat: 49.057812, lng: 20.1088046 },
    { lat: 49.0578841, lng: 20.1088363 },
    { lat: 49.0579976, lng: 20.1086622 },
    { lat: 49.0581614, lng: 20.1086228 },
    { lat: 49.0582843, lng: 20.108511 },
    { lat: 49.0583366, lng: 20.1082454 },
    { lat: 49.0587276, lng: 20.1080288 },
    { lat: 49.0587837, lng: 20.1077786 },
    { lat: 49.0589679, lng: 20.107794 },
    { lat: 49.0590706, lng: 20.1077118 },
    { lat: 49.0592218, lng: 20.1077461 },
    { lat: 49.0593159, lng: 20.1076812 },
    { lat: 49.0592763, lng: 20.1072561 },
    { lat: 49.059326, lng: 20.1072371 },
    { lat: 49.0593169, lng: 20.1070645 },
    { lat: 49.0596056, lng: 20.10627 },
    { lat: 49.0595333, lng: 20.1060994 },
    { lat: 49.0595696, lng: 20.1060234 },
    { lat: 49.0596261, lng: 20.1060246 },
    { lat: 49.0596375, lng: 20.1059466 },
    { lat: 49.0595499, lng: 20.1057599 },
    { lat: 49.0595965, lng: 20.1053842 },
    { lat: 49.0598785, lng: 20.104977 },
    { lat: 49.0600182, lng: 20.1050044 },
    { lat: 49.0600692, lng: 20.1049005 },
    { lat: 49.060124, lng: 20.104979 },
    { lat: 49.0601058, lng: 20.10511 },
    { lat: 49.0601814, lng: 20.1051119 },
    { lat: 49.0602454, lng: 20.1050269 },
    { lat: 49.060348, lng: 20.1050837 },
    { lat: 49.0604211, lng: 20.1049705 },
    { lat: 49.0603107, lng: 20.1046642 },
    { lat: 49.060336, lng: 20.104428 },
    { lat: 49.0604693, lng: 20.1045207 },
    { lat: 49.0604991, lng: 20.1047542 },
    { lat: 49.0606647, lng: 20.1047296 },
    { lat: 49.060669, lng: 20.1046427 },
    { lat: 49.0608615, lng: 20.1046113 },
    { lat: 49.0608617, lng: 20.1046694 },
    { lat: 49.0609282, lng: 20.1046732 },
    { lat: 49.0609846, lng: 20.1045641 },
    { lat: 49.0609433, lng: 20.1045163 },
    { lat: 49.0609413, lng: 20.1043865 },
    { lat: 49.0610169, lng: 20.1042487 },
    { lat: 49.0609547, lng: 20.1041884 },
    { lat: 49.0609729, lng: 20.1040539 },
    { lat: 49.0610879, lng: 20.1039741 },
    { lat: 49.0611963, lng: 20.1040925 },
    { lat: 49.0613354, lng: 20.1041067 },
    { lat: 49.0613626, lng: 20.1039971 },
    { lat: 49.0612749, lng: 20.1037281 },
    { lat: 49.0615341, lng: 20.1031485 },
    { lat: 49.0618284, lng: 20.1032131 },
    { lat: 49.0618439, lng: 20.1030932 },
    { lat: 49.0620417, lng: 20.1032523 },
    { lat: 49.0621089, lng: 20.1031864 },
    { lat: 49.0621607, lng: 20.1032138 },
    { lat: 49.0622016, lng: 20.1031109 },
    { lat: 49.0624133, lng: 20.1030295 },
    { lat: 49.0623924, lng: 20.1029274 },
    { lat: 49.0623355, lng: 20.1029438 },
    { lat: 49.062293, lng: 20.1026692 },
    { lat: 49.0623587, lng: 20.1026249 },
    { lat: 49.0624901, lng: 20.1028101 },
    { lat: 49.0625653, lng: 20.1027513 },
    { lat: 49.062615, lng: 20.1028942 },
    { lat: 49.0627391, lng: 20.1028991 },
    { lat: 49.0627436, lng: 20.1027596 },
    { lat: 49.0626816, lng: 20.1027321 },
    { lat: 49.0627449, lng: 20.1024837 },
    { lat: 49.0628271, lng: 20.1024548 },
    { lat: 49.0629388, lng: 20.1025158 },
    { lat: 49.0630789, lng: 20.102393 },
    { lat: 49.0631164, lng: 20.1024254 },
    { lat: 49.0631717, lng: 20.1022888 },
    { lat: 49.0633763, lng: 20.1021711 },
    { lat: 49.0634472, lng: 20.1021407 },
    { lat: 49.0636098, lng: 20.1022321 },
    { lat: 49.0636275, lng: 20.1023087 },
    { lat: 49.0637317, lng: 20.1023004 },
    { lat: 49.0638148, lng: 20.1022082 },
    { lat: 49.0638309, lng: 20.1021032 },
    { lat: 49.0639354, lng: 20.1020729 },
    { lat: 49.064034, lng: 20.1021423 },
    { lat: 49.06419, lng: 20.1020446 },
    { lat: 49.0641916, lng: 20.1019486 },
    { lat: 49.0643826, lng: 20.1018821 },
    { lat: 49.0644138, lng: 20.1017912 },
    { lat: 49.0648198, lng: 20.1018572 },
    { lat: 49.0649658, lng: 20.1018194 },
    { lat: 49.0650061, lng: 20.1017592 },
    { lat: 49.0649845, lng: 20.1014782 },
    { lat: 49.0650692, lng: 20.101425 },
    { lat: 49.0650817, lng: 20.1013491 },
    { lat: 49.0651623, lng: 20.1013343 },
    { lat: 49.0652076, lng: 20.1013983 },
    { lat: 49.0652406, lng: 20.1013521 },
    { lat: 49.0652305, lng: 20.1011034 },
    { lat: 49.065412, lng: 20.1008529 },
    { lat: 49.0654591, lng: 20.1009088 },
    { lat: 49.0655165, lng: 20.1008528 },
    { lat: 49.0657354, lng: 20.1009999 },
    { lat: 49.0660248, lng: 20.1006376 },
    { lat: 49.0661335, lng: 20.1007107 },
    { lat: 49.0661833, lng: 20.100488 },
    { lat: 49.0664291, lng: 20.1004979 },
    { lat: 49.0665293, lng: 20.1003329 },
    { lat: 49.0666032, lng: 20.1004614 },
    { lat: 49.066707, lng: 20.1002167 },
    { lat: 49.0668211, lng: 20.1002789 },
    { lat: 49.0669202, lng: 20.1001956 },
    { lat: 49.0670375, lng: 20.0999529 },
    { lat: 49.0670358, lng: 20.0997127 },
    { lat: 49.0671736, lng: 20.0994448 },
    { lat: 49.0673297, lng: 20.099355 },
    { lat: 49.0673753, lng: 20.0992573 },
    { lat: 49.0674361, lng: 20.0992548 },
    { lat: 49.0678218, lng: 20.0988511 },
    { lat: 49.0679109, lng: 20.0988549 },
    { lat: 49.0679916, lng: 20.0987676 },
    { lat: 49.0681791, lng: 20.098862 },
    { lat: 49.0682106, lng: 20.0988076 },
    { lat: 49.0683162, lng: 20.0988505 },
    { lat: 49.0685234, lng: 20.0985778 },
    { lat: 49.0711717, lng: 20.1016139 },
    { lat: 49.0729414, lng: 20.0996783 },
    { lat: 49.0746999, lng: 20.0973631 },
    { lat: 49.0792421, lng: 20.0937123 },
    { lat: 49.0792291, lng: 20.0936715 },
    { lat: 49.0795563, lng: 20.0934093 },
    { lat: 49.0797618, lng: 20.0935624 },
    { lat: 49.08007, lng: 20.0936087 },
    { lat: 49.0809544, lng: 20.0935023 },
    { lat: 49.0811053, lng: 20.0935341 },
    { lat: 49.0816581, lng: 20.0930609 },
    { lat: 49.0823103, lng: 20.0923161 },
    { lat: 49.0831247, lng: 20.0920143 },
    { lat: 49.0839032, lng: 20.092214 },
    { lat: 49.0838944, lng: 20.0922705 },
    { lat: 49.0842428, lng: 20.092284 },
    { lat: 49.0842504, lng: 20.0923023 },
  ],
  Hranovnica: [
    { lat: 49.0089264, lng: 20.3253368 },
    { lat: 49.0088345, lng: 20.3250585 },
    { lat: 49.0087387, lng: 20.324567 },
    { lat: 49.0085107, lng: 20.3239684 },
    { lat: 49.008424, lng: 20.3233861 },
    { lat: 49.0086797, lng: 20.3220827 },
    { lat: 49.0088176, lng: 20.3216048 },
    { lat: 49.008925, lng: 20.320886 },
    { lat: 49.0089726, lng: 20.3199054 },
    { lat: 49.0090742, lng: 20.3194548 },
    { lat: 49.0091362, lng: 20.3191368 },
    { lat: 49.0092402, lng: 20.3185317 },
    { lat: 49.0092567, lng: 20.3177418 },
    { lat: 49.0093352, lng: 20.3172703 },
    { lat: 49.0093361, lng: 20.3167117 },
    { lat: 49.0093978, lng: 20.3165308 },
    { lat: 49.0094263, lng: 20.315498 },
    { lat: 49.0094966, lng: 20.3150165 },
    { lat: 49.0094169, lng: 20.3136032 },
    { lat: 49.0095282, lng: 20.3124102 },
    { lat: 49.0095254, lng: 20.3121088 },
    { lat: 49.0095733, lng: 20.311428 },
    { lat: 49.009719, lng: 20.3105688 },
    { lat: 49.0102868, lng: 20.3080284 },
    { lat: 49.0103414, lng: 20.3058099 },
    { lat: 49.010422, lng: 20.3056571 },
    { lat: 49.0106604, lng: 20.3054993 },
    { lat: 49.0110367, lng: 20.3053884 },
    { lat: 49.0113928, lng: 20.3053657 },
    { lat: 49.0118422, lng: 20.3051986 },
    { lat: 49.0121846, lng: 20.3043636 },
    { lat: 49.0122502, lng: 20.3039025 },
    { lat: 49.0121401, lng: 20.3031914 },
    { lat: 49.0120621, lng: 20.3016781 },
    { lat: 49.0118788, lng: 20.3008735 },
    { lat: 49.0117557, lng: 20.2995369 },
    { lat: 49.011408, lng: 20.2980391 },
    { lat: 49.0114141, lng: 20.2972989 },
    { lat: 49.011304, lng: 20.2964414 },
    { lat: 49.0112972, lng: 20.2958624 },
    { lat: 49.0113731, lng: 20.2949821 },
    { lat: 49.0117564, lng: 20.2936066 },
    { lat: 49.0118268, lng: 20.2929844 },
    { lat: 49.0118436, lng: 20.2920853 },
    { lat: 49.0114889, lng: 20.2905849 },
    { lat: 49.0114042, lng: 20.2905265 },
    { lat: 49.0110091, lng: 20.290154 },
    { lat: 49.012248, lng: 20.2879379 },
    { lat: 49.0123659, lng: 20.2831793 },
    { lat: 49.0124804, lng: 20.281498 },
    { lat: 49.0123696, lng: 20.2816336 },
    { lat: 49.0116875, lng: 20.2823592 },
    { lat: 49.0116954, lng: 20.2824166 },
    { lat: 49.0115107, lng: 20.2826188 },
    { lat: 49.0112051, lng: 20.2828638 },
    { lat: 49.0112216, lng: 20.2829433 },
    { lat: 49.0108859, lng: 20.2832287 },
    { lat: 49.0102543, lng: 20.2835204 },
    { lat: 49.0099509, lng: 20.2837914 },
    { lat: 49.0097172, lng: 20.283862 },
    { lat: 49.0093778, lng: 20.2841129 },
    { lat: 49.0089712, lng: 20.2842515 },
    { lat: 49.0085451, lng: 20.2842724 },
    { lat: 49.0084105, lng: 20.2844266 },
    { lat: 49.0082748, lng: 20.2848157 },
    { lat: 49.0081077, lng: 20.2849376 },
    { lat: 49.0080217, lng: 20.2849572 },
    { lat: 49.0077678, lng: 20.2847774 },
    { lat: 49.0068598, lng: 20.2837346 },
    { lat: 49.0064736, lng: 20.2839058 },
    { lat: 49.005478, lng: 20.2839871 },
    { lat: 49.0057568, lng: 20.2830864 },
    { lat: 49.0058275, lng: 20.2825904 },
    { lat: 49.0058529, lng: 20.2816287 },
    { lat: 49.0056776, lng: 20.2802299 },
    { lat: 49.0057115, lng: 20.2800299 },
    { lat: 49.0059056, lng: 20.2795196 },
    { lat: 49.0058987, lng: 20.2791751 },
    { lat: 49.0029443, lng: 20.278347 },
    { lat: 49.003026, lng: 20.2777629 },
    { lat: 49.0031962, lng: 20.277391 },
    { lat: 48.9951092, lng: 20.2744136 },
    { lat: 48.9949219, lng: 20.2754705 },
    { lat: 48.9930664, lng: 20.2742711 },
    { lat: 48.9925418, lng: 20.2738718 },
    { lat: 48.9910615, lng: 20.2735687 },
    { lat: 48.990801, lng: 20.2734732 },
    { lat: 48.9904055, lng: 20.2732082 },
    { lat: 48.9895493, lng: 20.2728802 },
    { lat: 48.9888323, lng: 20.2726902 },
    { lat: 48.9883993, lng: 20.2724737 },
    { lat: 48.9874429, lng: 20.272323 },
    { lat: 48.9867831, lng: 20.2720095 },
    { lat: 48.9863962, lng: 20.2716759 },
    { lat: 48.9854419, lng: 20.2712615 },
    { lat: 48.9850542, lng: 20.271027 },
    { lat: 48.9839681, lng: 20.2696332 },
    { lat: 48.9826569, lng: 20.2684136 },
    { lat: 48.9822717, lng: 20.267985 },
    { lat: 48.9816571, lng: 20.2670154 },
    { lat: 48.9809811, lng: 20.2662886 },
    { lat: 48.9808356, lng: 20.2660527 },
    { lat: 48.9805728, lng: 20.2658126 },
    { lat: 48.9799476, lng: 20.2648903 },
    { lat: 48.9797639, lng: 20.2647743 },
    { lat: 48.9794645, lng: 20.2643723 },
    { lat: 48.9790799, lng: 20.2640738 },
    { lat: 48.9782046, lng: 20.2625164 },
    { lat: 48.9779623, lng: 20.2622544 },
    { lat: 48.9776411, lng: 20.262104 },
    { lat: 48.9775239, lng: 20.2619612 },
    { lat: 48.9774105, lng: 20.2615689 },
    { lat: 48.9772791, lng: 20.261317 },
    { lat: 48.9768242, lng: 20.260718 },
    { lat: 48.976471, lng: 20.2599883 },
    { lat: 48.9763066, lng: 20.2598703 },
    { lat: 48.9757557, lng: 20.2597231 },
    { lat: 48.9754261, lng: 20.2597301 },
    { lat: 48.9753863, lng: 20.2599771 },
    { lat: 48.9752545, lng: 20.2601195 },
    { lat: 48.9752746, lng: 20.2604303 },
    { lat: 48.9751765, lng: 20.260859 },
    { lat: 48.9747934, lng: 20.2606213 },
    { lat: 48.9744347, lng: 20.2605786 },
    { lat: 48.9742541, lng: 20.2606244 },
    { lat: 48.9732921, lng: 20.2602192 },
    { lat: 48.9729694, lng: 20.2601732 },
    { lat: 48.9720591, lng: 20.2597633 },
    { lat: 48.9719082, lng: 20.259244 },
    { lat: 48.9716147, lng: 20.2586276 },
    { lat: 48.9709515, lng: 20.2565096 },
    { lat: 48.9707913, lng: 20.2563303 },
    { lat: 48.9706907, lng: 20.2559512 },
    { lat: 48.9704347, lng: 20.2556268 },
    { lat: 48.9703651, lng: 20.2553754 },
    { lat: 48.9699946, lng: 20.2549746 },
    { lat: 48.9696181, lng: 20.2543658 },
    { lat: 48.9693942, lng: 20.2542529 },
    { lat: 48.9688177, lng: 20.2542823 },
    { lat: 48.9677354, lng: 20.2541663 },
    { lat: 48.9677037, lng: 20.2542244 },
    { lat: 48.9669232, lng: 20.2538204 },
    { lat: 48.9659118, lng: 20.2536167 },
    { lat: 48.965507, lng: 20.2533435 },
    { lat: 48.964726, lng: 20.2535854 },
    { lat: 48.9644399, lng: 20.2533852 },
    { lat: 48.9636208, lng: 20.2532049 },
    { lat: 48.9632784, lng: 20.2530303 },
    { lat: 48.9631831, lng: 20.2529232 },
    { lat: 48.9618362, lng: 20.2524407 },
    { lat: 48.9611959, lng: 20.2523914 },
    { lat: 48.9603998, lng: 20.2521946 },
    { lat: 48.9599327, lng: 20.2522285 },
    { lat: 48.9582621, lng: 20.2517894 },
    { lat: 48.9579675, lng: 20.2517671 },
    { lat: 48.9578166, lng: 20.2516504 },
    { lat: 48.9558751, lng: 20.2523364 },
    { lat: 48.9543574, lng: 20.2533461 },
    { lat: 48.9528005, lng: 20.2535313 },
    { lat: 48.9516994, lng: 20.2532491 },
    { lat: 48.9504037, lng: 20.2520895 },
    { lat: 48.9485988, lng: 20.2520977 },
    { lat: 48.9478313, lng: 20.2517977 },
    { lat: 48.9476423, lng: 20.2518865 },
    { lat: 48.9471882, lng: 20.2518497 },
    { lat: 48.9466895, lng: 20.2520566 },
    { lat: 48.9462495, lng: 20.2523234 },
    { lat: 48.9459893, lng: 20.2522897 },
    { lat: 48.9455227, lng: 20.2517127 },
    { lat: 48.9447784, lng: 20.2513694 },
    { lat: 48.9440825, lng: 20.2505997 },
    { lat: 48.9434417, lng: 20.2501109 },
    { lat: 48.9428416, lng: 20.2495422 },
    { lat: 48.9425699, lng: 20.2494512 },
    { lat: 48.9423715, lng: 20.249303 },
    { lat: 48.9422942, lng: 20.2490539 },
    { lat: 48.9422925, lng: 20.2488129 },
    { lat: 48.9423688, lng: 20.2481211 },
    { lat: 48.9423284, lng: 20.2478994 },
    { lat: 48.9422246, lng: 20.247691 },
    { lat: 48.9416604, lng: 20.2471767 },
    { lat: 48.9414976, lng: 20.2469325 },
    { lat: 48.9413286, lng: 20.2465313 },
    { lat: 48.9402754, lng: 20.2461553 },
    { lat: 48.9395637, lng: 20.2453836 },
    { lat: 48.9388861, lng: 20.2443947 },
    { lat: 48.9386465, lng: 20.2439766 },
    { lat: 48.9385541, lng: 20.2436315 },
    { lat: 48.9377326, lng: 20.2435877 },
    { lat: 48.9372002, lng: 20.243481 },
    { lat: 48.9371968, lng: 20.2435369 },
    { lat: 48.9371272, lng: 20.2442535 },
    { lat: 48.9371018, lng: 20.2485173 },
    { lat: 48.9366795, lng: 20.2505186 },
    { lat: 48.9372557, lng: 20.2529628 },
    { lat: 48.9378104, lng: 20.2544334 },
    { lat: 48.9379551, lng: 20.2557946 },
    { lat: 48.9387615, lng: 20.258912 },
    { lat: 48.9387416, lng: 20.2600049 },
    { lat: 48.9381998, lng: 20.2619471 },
    { lat: 48.9378865, lng: 20.2634008 },
    { lat: 48.9376373, lng: 20.2666009 },
    { lat: 48.9377622, lng: 20.2676047 },
    { lat: 48.9377999, lng: 20.2683349 },
    { lat: 48.9388813, lng: 20.2691741 },
    { lat: 48.9390623, lng: 20.2694801 },
    { lat: 48.9391346, lng: 20.2697178 },
    { lat: 48.9393889, lng: 20.2701128 },
    { lat: 48.9397027, lng: 20.2709348 },
    { lat: 48.9397323, lng: 20.2713136 },
    { lat: 48.9399747, lng: 20.2720976 },
    { lat: 48.9401023, lng: 20.2727334 },
    { lat: 48.9400567, lng: 20.2730076 },
    { lat: 48.9400581, lng: 20.2741609 },
    { lat: 48.9400793, lng: 20.2745025 },
    { lat: 48.9401706, lng: 20.2746777 },
    { lat: 48.9401881, lng: 20.274949 },
    { lat: 48.9404705, lng: 20.2756872 },
    { lat: 48.9411573, lng: 20.2768947 },
    { lat: 48.9414111, lng: 20.2774811 },
    { lat: 48.941548, lng: 20.2783832 },
    { lat: 48.9416995, lng: 20.2788291 },
    { lat: 48.9419654, lng: 20.2802133 },
    { lat: 48.9425759, lng: 20.2814259 },
    { lat: 48.9437479, lng: 20.2830847 },
    { lat: 48.9441269, lng: 20.2840504 },
    { lat: 48.9449896, lng: 20.2866124 },
    { lat: 48.9452444, lng: 20.2871651 },
    { lat: 48.9455789, lng: 20.2882914 },
    { lat: 48.9458964, lng: 20.2886923 },
    { lat: 48.9450361, lng: 20.2902207 },
    { lat: 48.9449542, lng: 20.2899723 },
    { lat: 48.9447321, lng: 20.2898734 },
    { lat: 48.9446432, lng: 20.2895916 },
    { lat: 48.9444725, lng: 20.2893415 },
    { lat: 48.9443768, lng: 20.2892839 },
    { lat: 48.9438879, lng: 20.2891341 },
    { lat: 48.943731, lng: 20.2889842 },
    { lat: 48.9435295, lng: 20.2891836 },
    { lat: 48.9434023, lng: 20.2891586 },
    { lat: 48.9431277, lng: 20.2888785 },
    { lat: 48.9427177, lng: 20.2887299 },
    { lat: 48.9422339, lng: 20.2889386 },
    { lat: 48.9419246, lng: 20.2889522 },
    { lat: 48.9416718, lng: 20.2885906 },
    { lat: 48.9411644, lng: 20.2884006 },
    { lat: 48.9410859, lng: 20.2884378 },
    { lat: 48.9409145, lng: 20.2882849 },
    { lat: 48.9399009, lng: 20.2878551 },
    { lat: 48.9395975, lng: 20.2878688 },
    { lat: 48.9389607, lng: 20.2873653 },
    { lat: 48.9389521, lng: 20.2871436 },
    { lat: 48.9388789, lng: 20.2870291 },
    { lat: 48.9387792, lng: 20.2869883 },
    { lat: 48.938607, lng: 20.2870461 },
    { lat: 48.9384776, lng: 20.2872784 },
    { lat: 48.9384529, lng: 20.28777 },
    { lat: 48.9385142, lng: 20.2878995 },
    { lat: 48.938511, lng: 20.2881612 },
    { lat: 48.9384173, lng: 20.288529 },
    { lat: 48.93829, lng: 20.2887869 },
    { lat: 48.9383664, lng: 20.2892428 },
    { lat: 48.9382128, lng: 20.2897799 },
    { lat: 48.9383123, lng: 20.2903383 },
    { lat: 48.9383751, lng: 20.2915409 },
    { lat: 48.9386431, lng: 20.2920891 },
    { lat: 48.9388426, lng: 20.2929244 },
    { lat: 48.9390818, lng: 20.2936905 },
    { lat: 48.9392798, lng: 20.2946079 },
    { lat: 48.9394598, lng: 20.2950914 },
    { lat: 48.9395124, lng: 20.2959181 },
    { lat: 48.9396161, lng: 20.2963547 },
    { lat: 48.9397972, lng: 20.2975609 },
    { lat: 48.939961, lng: 20.297834 },
    { lat: 48.940841, lng: 20.296992 },
    { lat: 48.940903, lng: 20.297016 },
    { lat: 48.940935, lng: 20.297083 },
    { lat: 48.941003, lng: 20.297133 },
    { lat: 48.941048, lng: 20.297209 },
    { lat: 48.941321, lng: 20.297646 },
    { lat: 48.941441, lng: 20.297812 },
    { lat: 48.941732, lng: 20.298396 },
    { lat: 48.942239, lng: 20.299076 },
    { lat: 48.942833, lng: 20.299437 },
    { lat: 48.943126, lng: 20.299531 },
    { lat: 48.943223, lng: 20.299727 },
    { lat: 48.943429, lng: 20.299828 },
    { lat: 48.943531, lng: 20.299923 },
    { lat: 48.943732, lng: 20.300037 },
    { lat: 48.943947, lng: 20.300117 },
    { lat: 48.944383, lng: 20.300375 },
    { lat: 48.945014, lng: 20.3007 },
    { lat: 48.945071, lng: 20.300721 },
    { lat: 48.945178, lng: 20.300912 },
    { lat: 48.945204, lng: 20.301321 },
    { lat: 48.94543, lng: 20.302015 },
    { lat: 48.945582, lng: 20.302895 },
    { lat: 48.945769, lng: 20.303092 },
    { lat: 48.946051, lng: 20.303532 },
    { lat: 48.946064, lng: 20.3036 },
    { lat: 48.946203, lng: 20.304514 },
    { lat: 48.946315, lng: 20.304635 },
    { lat: 48.946533, lng: 20.30518 },
    { lat: 48.946544, lng: 20.305164 },
    { lat: 48.946717, lng: 20.305539 },
    { lat: 48.946781, lng: 20.30582 },
    { lat: 48.946985, lng: 20.306467 },
    { lat: 48.947354, lng: 20.307316 },
    { lat: 48.947519, lng: 20.30786 },
    { lat: 48.947592, lng: 20.308 },
    { lat: 48.947787, lng: 20.308225 },
    { lat: 48.947832, lng: 20.308435 },
    { lat: 48.948006, lng: 20.308878 },
    { lat: 48.948445, lng: 20.309648 },
    { lat: 48.948631, lng: 20.310171 },
    { lat: 48.94876, lng: 20.310549 },
    { lat: 48.948747, lng: 20.310566 },
    { lat: 48.949008, lng: 20.311454 },
    { lat: 48.94922, lng: 20.311773 },
    { lat: 48.949731, lng: 20.312405 },
    { lat: 48.950037, lng: 20.312626 },
    { lat: 48.950319, lng: 20.312938 },
    { lat: 48.950627, lng: 20.313196 },
    { lat: 48.950775, lng: 20.313176 },
    { lat: 48.951291, lng: 20.312879 },
    { lat: 48.951313, lng: 20.312866 },
    { lat: 48.951326, lng: 20.312859 },
    { lat: 48.951346, lng: 20.312756 },
    { lat: 48.951403, lng: 20.312613 },
    { lat: 48.95146, lng: 20.312501 },
    { lat: 48.95154, lng: 20.312428 },
    { lat: 48.951642, lng: 20.31245 },
    { lat: 48.951753, lng: 20.312572 },
    { lat: 48.952032, lng: 20.312671 },
    { lat: 48.952274, lng: 20.312791 },
    { lat: 48.9523626, lng: 20.3125443 },
    { lat: 48.9525364, lng: 20.3124384 },
    { lat: 48.9527944, lng: 20.31201 },
    { lat: 48.9529445, lng: 20.3119023 },
    { lat: 48.9539036, lng: 20.3117461 },
    { lat: 48.9542144, lng: 20.311777 },
    { lat: 48.9545179, lng: 20.3115616 },
    { lat: 48.9555732, lng: 20.311133 },
    { lat: 48.9559897, lng: 20.3107502 },
    { lat: 48.9565219, lng: 20.3099339 },
    { lat: 48.9567779, lng: 20.3097708 },
    { lat: 48.9571271, lng: 20.3097158 },
    { lat: 48.9573286, lng: 20.3094784 },
    { lat: 48.9576885, lng: 20.3094125 },
    { lat: 48.9584188, lng: 20.3096862 },
    { lat: 48.9585536, lng: 20.3096204 },
    { lat: 48.958936, lng: 20.3092041 },
    { lat: 48.9599728, lng: 20.3086473 },
    { lat: 48.9606638, lng: 20.3084918 },
    { lat: 48.9610585, lng: 20.3083323 },
    { lat: 48.962007, lng: 20.3081411 },
    { lat: 48.9624795, lng: 20.3084768 },
    { lat: 48.9627095, lng: 20.3084224 },
    { lat: 48.9638417, lng: 20.3089565 },
    { lat: 48.9642538, lng: 20.3093639 },
    { lat: 48.9643503, lng: 20.3095824 },
    { lat: 48.9644101, lng: 20.3105429 },
    { lat: 48.9647285, lng: 20.3114314 },
    { lat: 48.9652283, lng: 20.3116618 },
    { lat: 48.9653528, lng: 20.3118233 },
    { lat: 48.9654987, lng: 20.3121539 },
    { lat: 48.9656236, lng: 20.3125613 },
    { lat: 48.9659058, lng: 20.3128947 },
    { lat: 48.9662306, lng: 20.3128799 },
    { lat: 48.9672548, lng: 20.3122881 },
    { lat: 48.9676265, lng: 20.3126343 },
    { lat: 48.9678937, lng: 20.3134271 },
    { lat: 48.9686152, lng: 20.3135946 },
    { lat: 48.9688706, lng: 20.31359 },
    { lat: 48.9690846, lng: 20.31405 },
    { lat: 48.969366, lng: 20.3142778 },
    { lat: 48.9696058, lng: 20.3141043 },
    { lat: 48.9701848, lng: 20.3142542 },
    { lat: 48.9706249, lng: 20.3142187 },
    { lat: 48.9708729, lng: 20.3144004 },
    { lat: 48.9711347, lng: 20.3144163 },
    { lat: 48.9715898, lng: 20.3146257 },
    { lat: 48.9721847, lng: 20.3147798 },
    { lat: 48.9728757, lng: 20.3153172 },
    { lat: 48.9731093, lng: 20.3156798 },
    { lat: 48.9732366, lng: 20.3161752 },
    { lat: 48.9732611, lng: 20.3163984 },
    { lat: 48.9731648, lng: 20.3166716 },
    { lat: 48.9731773, lng: 20.3168475 },
    { lat: 48.9739997, lng: 20.3177617 },
    { lat: 48.9746132, lng: 20.3187695 },
    { lat: 48.9749961, lng: 20.3197574 },
    { lat: 48.9751838, lng: 20.3204098 },
    { lat: 48.9754854, lng: 20.3212202 },
    { lat: 48.9757622, lng: 20.3217906 },
    { lat: 48.9761089, lng: 20.3221103 },
    { lat: 48.9770698, lng: 20.3226716 },
    { lat: 48.9777879, lng: 20.3236225 },
    { lat: 48.9786827, lng: 20.3241041 },
    { lat: 48.9787825, lng: 20.3237751 },
    { lat: 48.9797687, lng: 20.3246715 },
    { lat: 48.9803685, lng: 20.3250681 },
    { lat: 48.9834889, lng: 20.3267864 },
    { lat: 48.988005, lng: 20.3296802 },
    { lat: 48.9904069, lng: 20.3307304 },
    { lat: 48.9914584, lng: 20.3308648 },
    { lat: 48.9923932, lng: 20.3311373 },
    { lat: 48.9928486, lng: 20.3312053 },
    { lat: 48.9974162, lng: 20.3315923 },
    { lat: 48.9984869, lng: 20.3317372 },
    { lat: 49.0044558, lng: 20.3317022 },
    { lat: 49.0091325, lng: 20.3319408 },
    { lat: 49.0091209, lng: 20.3312607 },
    { lat: 49.0090793, lng: 20.3307716 },
    { lat: 49.0091323, lng: 20.3290272 },
    { lat: 49.0089472, lng: 20.3271524 },
    { lat: 49.0090596, lng: 20.3263066 },
    { lat: 49.0089264, lng: 20.3253368 },
  ],
  NováLesná: [
    { lat: 49.1199345, lng: 20.2808557 },
    { lat: 49.1203919, lng: 20.2806345 },
    { lat: 49.1212653, lng: 20.281284 },
    { lat: 49.1226905, lng: 20.2817074 },
    { lat: 49.1247318, lng: 20.2824827 },
    { lat: 49.1259572, lng: 20.2831868 },
    { lat: 49.1267685, lng: 20.2840355 },
    { lat: 49.1271574, lng: 20.2837277 },
    { lat: 49.1271397, lng: 20.2834478 },
    { lat: 49.1273227, lng: 20.2831731 },
    { lat: 49.1273698, lng: 20.2829966 },
    { lat: 49.1273439, lng: 20.2827926 },
    { lat: 49.1274412, lng: 20.2827446 },
    { lat: 49.127438, lng: 20.2824353 },
    { lat: 49.1273756, lng: 20.282281 },
    { lat: 49.1274242, lng: 20.282141 },
    { lat: 49.1275934, lng: 20.2821774 },
    { lat: 49.127628, lng: 20.2821092 },
    { lat: 49.1276353, lng: 20.2819518 },
    { lat: 49.12768, lng: 20.2819107 },
    { lat: 49.1276286, lng: 20.281718 },
    { lat: 49.1276457, lng: 20.2815831 },
    { lat: 49.1277124, lng: 20.2814411 },
    { lat: 49.1277759, lng: 20.2814242 },
    { lat: 49.1277835, lng: 20.2812193 },
    { lat: 49.1278518, lng: 20.2811516 },
    { lat: 49.1278127, lng: 20.281098 },
    { lat: 49.1278207, lng: 20.280988 },
    { lat: 49.1279278, lng: 20.2806198 },
    { lat: 49.1278505, lng: 20.2801076 },
    { lat: 49.1278909, lng: 20.2799576 },
    { lat: 49.1278776, lng: 20.2797312 },
    { lat: 49.1279807, lng: 20.2793749 },
    { lat: 49.1279171, lng: 20.2791919 },
    { lat: 49.1279658, lng: 20.2791997 },
    { lat: 49.1279873, lng: 20.2789957 },
    { lat: 49.1278877, lng: 20.278958 },
    { lat: 49.1278137, lng: 20.2787306 },
    { lat: 49.1277839, lng: 20.2783734 },
    { lat: 49.1279366, lng: 20.277872 },
    { lat: 49.1279525, lng: 20.2776757 },
    { lat: 49.1280649, lng: 20.277434 },
    { lat: 49.1281181, lng: 20.2774137 },
    { lat: 49.1281059, lng: 20.2772473 },
    { lat: 49.1283477, lng: 20.2769787 },
    { lat: 49.1284203, lng: 20.2769642 },
    { lat: 49.1283895, lng: 20.2768415 },
    { lat: 49.1284527, lng: 20.2765859 },
    { lat: 49.1285324, lng: 20.2765786 },
    { lat: 49.1286998, lng: 20.2763657 },
    { lat: 49.1288319, lng: 20.2759715 },
    { lat: 49.1287981, lng: 20.2757405 },
    { lat: 49.1289513, lng: 20.2757118 },
    { lat: 49.1289459, lng: 20.2756343 },
    { lat: 49.1291678, lng: 20.2752323 },
    { lat: 49.1291919, lng: 20.2750698 },
    { lat: 49.1292467, lng: 20.2750903 },
    { lat: 49.1294328, lng: 20.2748947 },
    { lat: 49.1295058, lng: 20.2749201 },
    { lat: 49.1297061, lng: 20.2745388 },
    { lat: 49.1296349, lng: 20.2743899 },
    { lat: 49.1296936, lng: 20.2743641 },
    { lat: 49.1299025, lng: 20.2740043 },
    { lat: 49.1298753, lng: 20.2738881 },
    { lat: 49.1299141, lng: 20.273758 },
    { lat: 49.1300596, lng: 20.2737077 },
    { lat: 49.1300558, lng: 20.2736099 },
    { lat: 49.1301301, lng: 20.2734655 },
    { lat: 49.129985, lng: 20.2732068 },
    { lat: 49.1300431, lng: 20.2729344 },
    { lat: 49.1299797, lng: 20.2727873 },
    { lat: 49.130272, lng: 20.272339 },
    { lat: 49.1302127, lng: 20.2722347 },
    { lat: 49.1302309, lng: 20.2721554 },
    { lat: 49.1302993, lng: 20.2720291 },
    { lat: 49.1303563, lng: 20.2720196 },
    { lat: 49.1304381, lng: 20.2716623 },
    { lat: 49.1305471, lng: 20.2715179 },
    { lat: 49.1306375, lng: 20.2715547 },
    { lat: 49.1307799, lng: 20.2712789 },
    { lat: 49.1309393, lng: 20.2707463 },
    { lat: 49.1310385, lng: 20.2706643 },
    { lat: 49.1310034, lng: 20.2705161 },
    { lat: 49.1314083, lng: 20.2697849 },
    { lat: 49.1313946, lng: 20.2696344 },
    { lat: 49.1315723, lng: 20.269568 },
    { lat: 49.1315205, lng: 20.2693429 },
    { lat: 49.1315477, lng: 20.2690855 },
    { lat: 49.131663, lng: 20.2689265 },
    { lat: 49.1316198, lng: 20.2687693 },
    { lat: 49.1317491, lng: 20.268508 },
    { lat: 49.1317401, lng: 20.2683021 },
    { lat: 49.1318464, lng: 20.2679742 },
    { lat: 49.1317873, lng: 20.2678692 },
    { lat: 49.1318172, lng: 20.267741 },
    { lat: 49.1319, lng: 20.2677878 },
    { lat: 49.1319454, lng: 20.2676826 },
    { lat: 49.1319027, lng: 20.2676296 },
    { lat: 49.1319172, lng: 20.2675661 },
    { lat: 49.1320261, lng: 20.267471 },
    { lat: 49.132041, lng: 20.2673636 },
    { lat: 49.1321595, lng: 20.2673406 },
    { lat: 49.1323431, lng: 20.2670819 },
    { lat: 49.1324973, lng: 20.2670564 },
    { lat: 49.1325149, lng: 20.2669879 },
    { lat: 49.1324482, lng: 20.2668932 },
    { lat: 49.1325511, lng: 20.2668447 },
    { lat: 49.1325477, lng: 20.2666429 },
    { lat: 49.1324885, lng: 20.2665951 },
    { lat: 49.1325966, lng: 20.2664821 },
    { lat: 49.1326106, lng: 20.2661532 },
    { lat: 49.1328183, lng: 20.265847 },
    { lat: 49.1328613, lng: 20.2656504 },
    { lat: 49.1328343, lng: 20.2654238 },
    { lat: 49.1328892, lng: 20.2653552 },
    { lat: 49.1329256, lng: 20.2653097 },
    { lat: 49.1329456, lng: 20.2650422 },
    { lat: 49.1331331, lng: 20.2647702 },
    { lat: 49.1331337, lng: 20.2646709 },
    { lat: 49.1333223, lng: 20.2642553 },
    { lat: 49.1334455, lng: 20.2641772 },
    { lat: 49.1335267, lng: 20.2642396 },
    { lat: 49.1337064, lng: 20.2642002 },
    { lat: 49.1339288, lng: 20.2639563 },
    { lat: 49.1340224, lng: 20.2637331 },
    { lat: 49.1340752, lng: 20.2637556 },
    { lat: 49.1341164, lng: 20.2636257 },
    { lat: 49.1342883, lng: 20.2636762 },
    { lat: 49.1343094, lng: 20.2635542 },
    { lat: 49.1342551, lng: 20.2634872 },
    { lat: 49.1343992, lng: 20.2633993 },
    { lat: 49.1345072, lng: 20.2631687 },
    { lat: 49.1346274, lng: 20.263189 },
    { lat: 49.1346566, lng: 20.2631047 },
    { lat: 49.1347426, lng: 20.2630836 },
    { lat: 49.1348486, lng: 20.2629459 },
    { lat: 49.1350736, lng: 20.262509 },
    { lat: 49.1350796, lng: 20.2624326 },
    { lat: 49.1350086, lng: 20.262375 },
    { lat: 49.1351066, lng: 20.2622852 },
    { lat: 49.1351175, lng: 20.262167 },
    { lat: 49.1350667, lng: 20.2621047 },
    { lat: 49.1353219, lng: 20.2619513 },
    { lat: 49.1352669, lng: 20.2618121 },
    { lat: 49.1354007, lng: 20.2617464 },
    { lat: 49.1353506, lng: 20.2615891 },
    { lat: 49.1353894, lng: 20.2615726 },
    { lat: 49.1354284, lng: 20.2616529 },
    { lat: 49.1355055, lng: 20.2615484 },
    { lat: 49.1357262, lng: 20.2614593 },
    { lat: 49.1360507, lng: 20.26101 },
    { lat: 49.1361499, lng: 20.260983 },
    { lat: 49.136254, lng: 20.2608211 },
    { lat: 49.1363925, lng: 20.26082 },
    { lat: 49.1366312, lng: 20.2604217 },
    { lat: 49.1366105, lng: 20.2603517 },
    { lat: 49.1367124, lng: 20.2599033 },
    { lat: 49.1367876, lng: 20.2597821 },
    { lat: 49.1369596, lng: 20.2598362 },
    { lat: 49.1370367, lng: 20.2597932 },
    { lat: 49.1371644, lng: 20.2595176 },
    { lat: 49.1372453, lng: 20.2595092 },
    { lat: 49.1372421, lng: 20.2593142 },
    { lat: 49.1372907, lng: 20.2592598 },
    { lat: 49.1372448, lng: 20.2591539 },
    { lat: 49.137327, lng: 20.2589515 },
    { lat: 49.1374878, lng: 20.2589185 },
    { lat: 49.1375601, lng: 20.2587217 },
    { lat: 49.1377426, lng: 20.2584958 },
    { lat: 49.1380501, lng: 20.2584678 },
    { lat: 49.1381723, lng: 20.2584002 },
    { lat: 49.1382164, lng: 20.2582854 },
    { lat: 49.1382773, lng: 20.2583453 },
    { lat: 49.1383477, lng: 20.2582752 },
    { lat: 49.1383444, lng: 20.2581622 },
    { lat: 49.1385215, lng: 20.2582022 },
    { lat: 49.138527, lng: 20.2580525 },
    { lat: 49.1386222, lng: 20.2578981 },
    { lat: 49.1387018, lng: 20.2577985 },
    { lat: 49.1388426, lng: 20.2578025 },
    { lat: 49.1389595, lng: 20.2576541 },
    { lat: 49.1390254, lng: 20.2574698 },
    { lat: 49.1391679, lng: 20.2574287 },
    { lat: 49.1393513, lng: 20.2571639 },
    { lat: 49.139474, lng: 20.257075 },
    { lat: 49.1395048, lng: 20.2569467 },
    { lat: 49.1397353, lng: 20.2568775 },
    { lat: 49.1400601, lng: 20.2565447 },
    { lat: 49.1404665, lng: 20.2564033 },
    { lat: 49.1404989, lng: 20.2561932 },
    { lat: 49.1405551, lng: 20.2562478 },
    { lat: 49.1407096, lng: 20.2561697 },
    { lat: 49.1407138, lng: 20.2559866 },
    { lat: 49.1407933, lng: 20.2558898 },
    { lat: 49.1404908, lng: 20.2553869 },
    { lat: 49.1407194, lng: 20.2551557 },
    { lat: 49.140867, lng: 20.2538982 },
    { lat: 49.1408026, lng: 20.2535186 },
    { lat: 49.1409093, lng: 20.2527636 },
    { lat: 49.1411102, lng: 20.2521476 },
    { lat: 49.1406823, lng: 20.2518004 },
    { lat: 49.1396248, lng: 20.2511362 },
    { lat: 49.1393253, lng: 20.2512804 },
    { lat: 49.1388968, lng: 20.2504934 },
    { lat: 49.1380844, lng: 20.2501321 },
    { lat: 49.1376368, lng: 20.2507224 },
    { lat: 49.1366374, lng: 20.2501908 },
    { lat: 49.1361807, lng: 20.2507215 },
    { lat: 49.1355599, lng: 20.2500198 },
    { lat: 49.1368686, lng: 20.2483654 },
    { lat: 49.1363617, lng: 20.2473016 },
    { lat: 49.1346951, lng: 20.2492525 },
    { lat: 49.1340204, lng: 20.2496752 },
    { lat: 49.1337182, lng: 20.2501217 },
    { lat: 49.1333437, lng: 20.2497305 },
    { lat: 49.1331264, lng: 20.2497618 },
    { lat: 49.1327043, lng: 20.2493674 },
    { lat: 49.132406, lng: 20.2497671 },
    { lat: 49.1322662, lng: 20.2496814 },
    { lat: 49.1322186, lng: 20.2498443 },
    { lat: 49.131916, lng: 20.2498784 },
    { lat: 49.1318607, lng: 20.2501341 },
    { lat: 49.1318924, lng: 20.2503408 },
    { lat: 49.1316402, lng: 20.2506245 },
    { lat: 49.1315744, lng: 20.2509322 },
    { lat: 49.1315128, lng: 20.2510274 },
    { lat: 49.1316185, lng: 20.2511349 },
    { lat: 49.1315483, lng: 20.2513226 },
    { lat: 49.1314366, lng: 20.2514349 },
    { lat: 49.1314313, lng: 20.251672 },
    { lat: 49.131313, lng: 20.2518442 },
    { lat: 49.1311888, lng: 20.2518063 },
    { lat: 49.1308036, lng: 20.2520036 },
    { lat: 49.130754, lng: 20.2520923 },
    { lat: 49.1307183, lng: 20.2523843 },
    { lat: 49.1306231, lng: 20.2525368 },
    { lat: 49.1304635, lng: 20.2532068 },
    { lat: 49.1303329, lng: 20.2533802 },
    { lat: 49.1303354, lng: 20.2533908 },
    { lat: 49.1303559, lng: 20.2534788 },
    { lat: 49.1303195, lng: 20.2536392 },
    { lat: 49.1302649, lng: 20.2536255 },
    { lat: 49.1291371, lng: 20.2505703 },
    { lat: 49.1282466, lng: 20.2510636 },
    { lat: 49.1283235, lng: 20.251387 },
    { lat: 49.128161, lng: 20.2515507 },
    { lat: 49.1275555, lng: 20.251904 },
    { lat: 49.1271988, lng: 20.2522555 },
    { lat: 49.1272701, lng: 20.2524913 },
    { lat: 49.12668, lng: 20.2531524 },
    { lat: 49.1261508, lng: 20.2516881 },
    { lat: 49.1262293, lng: 20.2514231 },
    { lat: 49.1261183, lng: 20.2514077 },
    { lat: 49.1260311, lng: 20.2513074 },
    { lat: 49.1245785, lng: 20.2485579 },
    { lat: 49.1245029, lng: 20.2486709 },
    { lat: 49.1239104, lng: 20.2491809 },
    { lat: 49.1238601, lng: 20.2493893 },
    { lat: 49.1236367, lng: 20.2497221 },
    { lat: 49.1234326, lng: 20.2498288 },
    { lat: 49.1232624, lng: 20.2500554 },
    { lat: 49.1231161, lng: 20.2501166 },
    { lat: 49.1229544, lng: 20.2500946 },
    { lat: 49.1228794, lng: 20.2501921 },
    { lat: 49.1226586, lng: 20.2502489 },
    { lat: 49.1225685, lng: 20.2503923 },
    { lat: 49.1224951, lng: 20.2504173 },
    { lat: 49.1223025, lng: 20.2508426 },
    { lat: 49.1221553, lng: 20.2509085 },
    { lat: 49.1220742, lng: 20.2510256 },
    { lat: 49.1220131, lng: 20.25096 },
    { lat: 49.1217858, lng: 20.251305 },
    { lat: 49.1215981, lng: 20.2513742 },
    { lat: 49.1215574, lng: 20.2515106 },
    { lat: 49.1213442, lng: 20.2518198 },
    { lat: 49.1211663, lng: 20.2519874 },
    { lat: 49.1210272, lng: 20.2524052 },
    { lat: 49.1208786, lng: 20.2525504 },
    { lat: 49.1207722, lng: 20.2530511 },
    { lat: 49.1205837, lng: 20.2534106 },
    { lat: 49.1205219, lng: 20.2537311 },
    { lat: 49.1200107, lng: 20.2541912 },
    { lat: 49.1199009, lng: 20.2545797 },
    { lat: 49.1198368, lng: 20.2551212 },
    { lat: 49.1197312, lng: 20.2555328 },
    { lat: 49.1198163, lng: 20.25558 },
    { lat: 49.119807, lng: 20.2556858 },
    { lat: 49.1197316, lng: 20.2559152 },
    { lat: 49.1196863, lng: 20.2558805 },
    { lat: 49.119596, lng: 20.2561041 },
    { lat: 49.1196621, lng: 20.2561833 },
    { lat: 49.1196373, lng: 20.2563822 },
    { lat: 49.1192767, lng: 20.2570884 },
    { lat: 49.1193034, lng: 20.257169 },
    { lat: 49.1191735, lng: 20.2576776 },
    { lat: 49.1192179, lng: 20.2576905 },
    { lat: 49.1192496, lng: 20.2578066 },
    { lat: 49.1192082, lng: 20.2579826 },
    { lat: 49.1190866, lng: 20.258131 },
    { lat: 49.1190887, lng: 20.2583529 },
    { lat: 49.1189993, lng: 20.2584333 },
    { lat: 49.1189505, lng: 20.2586801 },
    { lat: 49.1187566, lng: 20.2588433 },
    { lat: 49.1187233, lng: 20.2589995 },
    { lat: 49.1186688, lng: 20.2589034 },
    { lat: 49.1186186, lng: 20.2590849 },
    { lat: 49.1185507, lng: 20.2591349 },
    { lat: 49.1185256, lng: 20.2595305 },
    { lat: 49.1185594, lng: 20.2600778 },
    { lat: 49.1185117, lng: 20.2602112 },
    { lat: 49.1184076, lng: 20.2603116 },
    { lat: 49.1182949, lng: 20.2602532 },
    { lat: 49.118353, lng: 20.2604952 },
    { lat: 49.1182991, lng: 20.2608481 },
    { lat: 49.1183801, lng: 20.2609292 },
    { lat: 49.1183974, lng: 20.2610854 },
    { lat: 49.1183793, lng: 20.2611658 },
    { lat: 49.1182957, lng: 20.2611881 },
    { lat: 49.1183066, lng: 20.2614126 },
    { lat: 49.1182469, lng: 20.2614679 },
    { lat: 49.1182359, lng: 20.261589 },
    { lat: 49.1183192, lng: 20.2619017 },
    { lat: 49.1180499, lng: 20.2623041 },
    { lat: 49.1180662, lng: 20.2625456 },
    { lat: 49.1179946, lng: 20.2627599 },
    { lat: 49.1180885, lng: 20.2630828 },
    { lat: 49.118074, lng: 20.2632617 },
    { lat: 49.1179427, lng: 20.2634451 },
    { lat: 49.117867, lng: 20.2638133 },
    { lat: 49.1179246, lng: 20.263876 },
    { lat: 49.1178742, lng: 20.2639668 },
    { lat: 49.1178701, lng: 20.2643497 },
    { lat: 49.1174875, lng: 20.2654503 },
    { lat: 49.117212, lng: 20.2657479 },
    { lat: 49.1171779, lng: 20.2659667 },
    { lat: 49.1169752, lng: 20.2661961 },
    { lat: 49.1168029, lng: 20.2662553 },
    { lat: 49.1167169, lng: 20.2661862 },
    { lat: 49.1165582, lng: 20.2663339 },
    { lat: 49.1161156, lng: 20.2663886 },
    { lat: 49.1157079, lng: 20.266788 },
    { lat: 49.115443, lng: 20.2667552 },
    { lat: 49.1153122, lng: 20.2668908 },
    { lat: 49.1152201, lng: 20.2671598 },
    { lat: 49.1149531, lng: 20.2673249 },
    { lat: 49.1147668, lng: 20.2673713 },
    { lat: 49.1145259, lng: 20.2670572 },
    { lat: 49.1142418, lng: 20.2671063 },
    { lat: 49.1140394, lng: 20.2669145 },
    { lat: 49.1137106, lng: 20.2672538 },
    { lat: 49.1136515, lng: 20.2673304 },
    { lat: 49.1142759, lng: 20.2689526 },
    { lat: 49.1140371, lng: 20.2695548 },
    { lat: 49.1136271, lng: 20.2708466 },
    { lat: 49.1135691, lng: 20.2711509 },
    { lat: 49.1153822, lng: 20.272506 },
    { lat: 49.1151705, lng: 20.2734293 },
    { lat: 49.1174659, lng: 20.2750827 },
    { lat: 49.1163709, lng: 20.2780671 },
    { lat: 49.1196355, lng: 20.2806365 },
    { lat: 49.1199345, lng: 20.2808557 },
  ],
  Lučivná: [
    { lat: 49.0179936, lng: 20.1492239 },
    { lat: 49.0180905, lng: 20.149536 },
    { lat: 49.0188159, lng: 20.1514074 },
    { lat: 49.0191334, lng: 20.1519674 },
    { lat: 49.0194487, lng: 20.152162 },
    { lat: 49.0196502, lng: 20.1525832 },
    { lat: 49.0199563, lng: 20.1525475 },
    { lat: 49.020239, lng: 20.1527408 },
    { lat: 49.0205437, lng: 20.1527014 },
    { lat: 49.0206843, lng: 20.1527812 },
    { lat: 49.0208033, lng: 20.1526367 },
    { lat: 49.0212479, lng: 20.1525716 },
    { lat: 49.0219622, lng: 20.1526355 },
    { lat: 49.0223894, lng: 20.1527834 },
    { lat: 49.0235967, lng: 20.1527587 },
    { lat: 49.0237363, lng: 20.1529262 },
    { lat: 49.0238655, lng: 20.1529716 },
    { lat: 49.0238798, lng: 20.1530736 },
    { lat: 49.0243555, lng: 20.1532023 },
    { lat: 49.0244856, lng: 20.1535785 },
    { lat: 49.0248058, lng: 20.1536725 },
    { lat: 49.0249719, lng: 20.1538828 },
    { lat: 49.0253956, lng: 20.1541133 },
    { lat: 49.0255673, lng: 20.1541809 },
    { lat: 49.0256744, lng: 20.1541275 },
    { lat: 49.0260614, lng: 20.1545373 },
    { lat: 49.0262827, lng: 20.1548601 },
    { lat: 49.026314, lng: 20.1550208 },
    { lat: 49.0264061, lng: 20.1551587 },
    { lat: 49.0265098, lng: 20.1558883 },
    { lat: 49.0267711, lng: 20.1564468 },
    { lat: 49.0274134, lng: 20.1568217 },
    { lat: 49.027848, lng: 20.1574664 },
    { lat: 49.0280261, lng: 20.1578579 },
    { lat: 49.0284454, lng: 20.1581509 },
    { lat: 49.0285154, lng: 20.158266 },
    { lat: 49.0286226, lng: 20.1582726 },
    { lat: 49.0288823, lng: 20.1584871 },
    { lat: 49.0289134, lng: 20.1586687 },
    { lat: 49.0290157, lng: 20.1586118 },
    { lat: 49.0293375, lng: 20.1586268 },
    { lat: 49.0296241, lng: 20.1588697 },
    { lat: 49.0297795, lng: 20.1587849 },
    { lat: 49.0305127, lng: 20.1591507 },
    { lat: 49.030777, lng: 20.1593662 },
    { lat: 49.0309102, lng: 20.1596521 },
    { lat: 49.031011, lng: 20.1597508 },
    { lat: 49.0313454, lng: 20.1598636 },
    { lat: 49.0313567, lng: 20.1600047 },
    { lat: 49.0318263, lng: 20.1601445 },
    { lat: 49.0320175, lng: 20.1603051 },
    { lat: 49.0320354, lng: 20.1604165 },
    { lat: 49.0322034, lng: 20.1604202 },
    { lat: 49.0322916, lng: 20.1605431 },
    { lat: 49.0324797, lng: 20.160625 },
    { lat: 49.0326161, lng: 20.1608539 },
    { lat: 49.032602, lng: 20.1611208 },
    { lat: 49.0328527, lng: 20.1614158 },
    { lat: 49.0327613, lng: 20.1616074 },
    { lat: 49.0327707, lng: 20.1617601 },
    { lat: 49.0329688, lng: 20.1619215 },
    { lat: 49.0329632, lng: 20.1623753 },
    { lat: 49.0330594, lng: 20.1632572 },
    { lat: 49.0330537, lng: 20.1635366 },
    { lat: 49.0332242, lng: 20.1637415 },
    { lat: 49.033339, lng: 20.1648381 },
    { lat: 49.0334883, lng: 20.1654412 },
    { lat: 49.0336317, lng: 20.1655047 },
    { lat: 49.0336928, lng: 20.1656566 },
    { lat: 49.0338102, lng: 20.1656353 },
    { lat: 49.0340033, lng: 20.1660366 },
    { lat: 49.0340111, lng: 20.1661503 },
    { lat: 49.034153, lng: 20.1663464 },
    { lat: 49.0342565, lng: 20.1663426 },
    { lat: 49.0344446, lng: 20.166201 },
    { lat: 49.0346204, lng: 20.1662381 },
    { lat: 49.0352015, lng: 20.1662632 },
    { lat: 49.0354064, lng: 20.1662876 },
    { lat: 49.0356835, lng: 20.1665131 },
    { lat: 49.0359075, lng: 20.1664547 },
    { lat: 49.036006, lng: 20.1662158 },
    { lat: 49.0361045, lng: 20.1661719 },
    { lat: 49.0362396, lng: 20.1663399 },
    { lat: 49.036584, lng: 20.1662236 },
    { lat: 49.0370249, lng: 20.1663209 },
    { lat: 49.0372557, lng: 20.1667155 },
    { lat: 49.0374267, lng: 20.1678888 },
    { lat: 49.0376308, lng: 20.1682072 },
    { lat: 49.0379646, lng: 20.1684986 },
    { lat: 49.0379692, lng: 20.1688658 },
    { lat: 49.0381063, lng: 20.1694776 },
    { lat: 49.0381136, lng: 20.1701076 },
    { lat: 49.0382141, lng: 20.1705681 },
    { lat: 49.0385012, lng: 20.1711844 },
    { lat: 49.0388949, lng: 20.1716737 },
    { lat: 49.0390566, lng: 20.1721713 },
    { lat: 49.0397049, lng: 20.1730029 },
    { lat: 49.0400055, lng: 20.1738437 },
    { lat: 49.0400731, lng: 20.1742718 },
    { lat: 49.0400548, lng: 20.175248 },
    { lat: 49.0400956, lng: 20.1760418 },
    { lat: 49.0400504, lng: 20.1762923 },
    { lat: 49.0406969, lng: 20.1772235 },
    { lat: 49.0410195, lng: 20.1775716 },
    { lat: 49.0411671, lng: 20.179067 },
    { lat: 49.0416041, lng: 20.1794904 },
    { lat: 49.0457308, lng: 20.1827459 },
    { lat: 49.050831, lng: 20.182489 },
    { lat: 49.051866, lng: 20.1826902 },
    { lat: 49.0527674, lng: 20.1827538 },
    { lat: 49.0532212, lng: 20.1830956 },
    { lat: 49.0533517, lng: 20.1832853 },
    { lat: 49.0539614, lng: 20.1844519 },
    { lat: 49.0541008, lng: 20.1849524 },
    { lat: 49.0542742, lng: 20.1853256 },
    { lat: 49.0544891, lng: 20.1856296 },
    { lat: 49.0546358, lng: 20.1856922 },
    { lat: 49.0546725, lng: 20.1857082 },
    { lat: 49.0546629, lng: 20.1856398 },
    { lat: 49.055012, lng: 20.1847907 },
    { lat: 49.0547356, lng: 20.1837653 },
    { lat: 49.0544758, lng: 20.1824723 },
    { lat: 49.0540441, lng: 20.1814236 },
    { lat: 49.0539379, lng: 20.1810463 },
    { lat: 49.053902, lng: 20.1808281 },
    { lat: 49.053927, lng: 20.180153 },
    { lat: 49.0542384, lng: 20.1789296 },
    { lat: 49.0542194, lng: 20.1778337 },
    { lat: 49.0544956, lng: 20.1774168 },
    { lat: 49.0546901, lng: 20.1767636 },
    { lat: 49.0546964, lng: 20.1762715 },
    { lat: 49.0549544, lng: 20.1764156 },
    { lat: 49.0552108, lng: 20.1759578 },
    { lat: 49.0552501, lng: 20.1757024 },
    { lat: 49.055378, lng: 20.1758023 },
    { lat: 49.0555721, lng: 20.1744892 },
    { lat: 49.0555247, lng: 20.1744839 },
    { lat: 49.0554758, lng: 20.1736866 },
    { lat: 49.0557642, lng: 20.1728753 },
    { lat: 49.0558428, lng: 20.1727804 },
    { lat: 49.0560137, lng: 20.1723283 },
    { lat: 49.0560173, lng: 20.1721653 },
    { lat: 49.0561275, lng: 20.1718234 },
    { lat: 49.0559358, lng: 20.1715614 },
    { lat: 49.0559181, lng: 20.1714306 },
    { lat: 49.0565318, lng: 20.170862 },
    { lat: 49.0565462, lng: 20.1708043 },
    { lat: 49.0564591, lng: 20.170598 },
    { lat: 49.0565197, lng: 20.170536 },
    { lat: 49.0567316, lng: 20.170484 },
    { lat: 49.0569356, lng: 20.1707097 },
    { lat: 49.0571875, lng: 20.1711793 },
    { lat: 49.0572753, lng: 20.1711787 },
    { lat: 49.057262, lng: 20.1709886 },
    { lat: 49.0584377, lng: 20.170653 },
    { lat: 49.0582948, lng: 20.1690242 },
    { lat: 49.0581583, lng: 20.1688207 },
    { lat: 49.0581468, lng: 20.1686763 },
    { lat: 49.0589525, lng: 20.1675634 },
    { lat: 49.0595305, lng: 20.1672057 },
    { lat: 49.0600746, lng: 20.1674913 },
    { lat: 49.0602281, lng: 20.1676351 },
    { lat: 49.0606309, lng: 20.168248 },
    { lat: 49.0608356, lng: 20.1686348 },
    { lat: 49.0610676, lng: 20.1692819 },
    { lat: 49.0614854, lng: 20.1707697 },
    { lat: 49.0613931, lng: 20.1708271 },
    { lat: 49.0614336, lng: 20.1709972 },
    { lat: 49.0624859, lng: 20.1722488 },
    { lat: 49.0626098, lng: 20.172502 },
    { lat: 49.0627009, lng: 20.1721308 },
    { lat: 49.0630122, lng: 20.1715834 },
    { lat: 49.0632697, lng: 20.1711775 },
    { lat: 49.0634877, lng: 20.1710811 },
    { lat: 49.0636282, lng: 20.1704289 },
    { lat: 49.0643108, lng: 20.1695909 },
    { lat: 49.0646147, lng: 20.1690244 },
    { lat: 49.0652656, lng: 20.1675289 },
    { lat: 49.0656323, lng: 20.1669566 },
    { lat: 49.0663277, lng: 20.1664083 },
    { lat: 49.066678, lng: 20.1659038 },
    { lat: 49.0668327, lng: 20.1654859 },
    { lat: 49.0670638, lng: 20.1652868 },
    { lat: 49.0669181, lng: 20.1647344 },
    { lat: 49.0669933, lng: 20.1644164 },
    { lat: 49.0669438, lng: 20.1643643 },
    { lat: 49.0669499, lng: 20.1640391 },
    { lat: 49.0668467, lng: 20.1632316 },
    { lat: 49.0666265, lng: 20.1629058 },
    { lat: 49.0670665, lng: 20.161975 },
    { lat: 49.0667423, lng: 20.1611927 },
    { lat: 49.0663504, lng: 20.161895 },
    { lat: 49.0656526, lng: 20.1619882 },
    { lat: 49.0655265, lng: 20.1612048 },
    { lat: 49.0651228, lng: 20.1605405 },
    { lat: 49.0648958, lng: 20.1598243 },
    { lat: 49.0646316, lng: 20.1594161 },
    { lat: 49.0644746, lng: 20.1587843 },
    { lat: 49.0644377, lng: 20.1582311 },
    { lat: 49.0645137, lng: 20.1579356 },
    { lat: 49.0646038, lng: 20.1565378 },
    { lat: 49.0644424, lng: 20.1559379 },
    { lat: 49.0644192, lng: 20.1547719 },
    { lat: 49.0645974, lng: 20.1542453 },
    { lat: 49.0649137, lng: 20.153707 },
    { lat: 49.0652332, lng: 20.1523858 },
    { lat: 49.0655819, lng: 20.1519827 },
    { lat: 49.0655968, lng: 20.15084 },
    { lat: 49.0651893, lng: 20.1454962 },
    { lat: 49.0648623, lng: 20.1443671 },
    { lat: 49.064594, lng: 20.14299 },
    { lat: 49.0643753, lng: 20.1413932 },
    { lat: 49.0644074, lng: 20.1407894 },
    { lat: 49.0641696, lng: 20.1395568 },
    { lat: 49.0641841, lng: 20.1380183 },
    { lat: 49.0648447, lng: 20.1359146 },
    { lat: 49.0651433, lng: 20.1340454 },
    { lat: 49.0653565, lng: 20.1331043 },
    { lat: 49.0653436, lng: 20.1326163 },
    { lat: 49.065489, lng: 20.1320101 },
    { lat: 49.0655542, lng: 20.1317831 },
    { lat: 49.0658895, lng: 20.1311035 },
    { lat: 49.0656895, lng: 20.1307782 },
    { lat: 49.0653041, lng: 20.1305226 },
    { lat: 49.0648267, lng: 20.1297441 },
    { lat: 49.0644553, lng: 20.1296416 },
    { lat: 49.0642634, lng: 20.1294879 },
    { lat: 49.0645966, lng: 20.1288146 },
    { lat: 49.0646716, lng: 20.1288615 },
    { lat: 49.0647078, lng: 20.1287525 },
    { lat: 49.0646513, lng: 20.1287208 },
    { lat: 49.0646651, lng: 20.1285076 },
    { lat: 49.0647939, lng: 20.1285182 },
    { lat: 49.0650643, lng: 20.1282117 },
    { lat: 49.0651583, lng: 20.1279177 },
    { lat: 49.0650961, lng: 20.1279135 },
    { lat: 49.0651022, lng: 20.1277833 },
    { lat: 49.0652579, lng: 20.1277066 },
    { lat: 49.0653153, lng: 20.1277608 },
    { lat: 49.0654467, lng: 20.1275021 },
    { lat: 49.0654109, lng: 20.1273112 },
    { lat: 49.0655059, lng: 20.1272685 },
    { lat: 49.0655769, lng: 20.1271265 },
    { lat: 49.0655496, lng: 20.1270414 },
    { lat: 49.0656904, lng: 20.1267585 },
    { lat: 49.0657979, lng: 20.1267452 },
    { lat: 49.065861, lng: 20.126466 },
    { lat: 49.0659146, lng: 20.1264843 },
    { lat: 49.0659393, lng: 20.1263437 },
    { lat: 49.0660872, lng: 20.1261299 },
    { lat: 49.0660365, lng: 20.125904 },
    { lat: 49.0660905, lng: 20.1258516 },
    { lat: 49.0661585, lng: 20.1254731 },
    { lat: 49.0663774, lng: 20.1253984 },
    { lat: 49.0664789, lng: 20.1252305 },
    { lat: 49.0665258, lng: 20.1248037 },
    { lat: 49.0666141, lng: 20.1248163 },
    { lat: 49.0666485, lng: 20.1246906 },
    { lat: 49.066588, lng: 20.1245609 },
    { lat: 49.0666411, lng: 20.1243451 },
    { lat: 49.0666149, lng: 20.1240581 },
    { lat: 49.0669048, lng: 20.1238977 },
    { lat: 49.0683785, lng: 20.1208561 },
    { lat: 49.0696351, lng: 20.1195142 },
    { lat: 49.0703739, lng: 20.118148 },
    { lat: 49.0707271, lng: 20.1170276 },
    { lat: 49.0711752, lng: 20.1151885 },
    { lat: 49.0716365, lng: 20.1143148 },
    { lat: 49.071855, lng: 20.1124566 },
    { lat: 49.0724217, lng: 20.1120977 },
    { lat: 49.0725534, lng: 20.1118178 },
    { lat: 49.073271, lng: 20.1109608 },
    { lat: 49.0734337, lng: 20.1106144 },
    { lat: 49.073959, lng: 20.1056564 },
    { lat: 49.0748451, lng: 20.1034052 },
    { lat: 49.0729536, lng: 20.0997349 },
    { lat: 49.0729414, lng: 20.0996783 },
    { lat: 49.0711717, lng: 20.1016139 },
    { lat: 49.0685234, lng: 20.0985778 },
    { lat: 49.0683162, lng: 20.0988505 },
    { lat: 49.0682106, lng: 20.0988076 },
    { lat: 49.0681791, lng: 20.098862 },
    { lat: 49.0679916, lng: 20.0987676 },
    { lat: 49.0679109, lng: 20.0988549 },
    { lat: 49.0678218, lng: 20.0988511 },
    { lat: 49.0674361, lng: 20.0992548 },
    { lat: 49.0673753, lng: 20.0992573 },
    { lat: 49.0673297, lng: 20.099355 },
    { lat: 49.0671736, lng: 20.0994448 },
    { lat: 49.0670358, lng: 20.0997127 },
    { lat: 49.0670375, lng: 20.0999529 },
    { lat: 49.0669202, lng: 20.1001956 },
    { lat: 49.0668211, lng: 20.1002789 },
    { lat: 49.066707, lng: 20.1002167 },
    { lat: 49.0666032, lng: 20.1004614 },
    { lat: 49.0665293, lng: 20.1003329 },
    { lat: 49.0664291, lng: 20.1004979 },
    { lat: 49.0661833, lng: 20.100488 },
    { lat: 49.0661335, lng: 20.1007107 },
    { lat: 49.0660248, lng: 20.1006376 },
    { lat: 49.0657354, lng: 20.1009999 },
    { lat: 49.0655165, lng: 20.1008528 },
    { lat: 49.0654591, lng: 20.1009088 },
    { lat: 49.065412, lng: 20.1008529 },
    { lat: 49.0652305, lng: 20.1011034 },
    { lat: 49.0652406, lng: 20.1013521 },
    { lat: 49.0652076, lng: 20.1013983 },
    { lat: 49.0651623, lng: 20.1013343 },
    { lat: 49.0650817, lng: 20.1013491 },
    { lat: 49.0650692, lng: 20.101425 },
    { lat: 49.0649845, lng: 20.1014782 },
    { lat: 49.0650061, lng: 20.1017592 },
    { lat: 49.0649658, lng: 20.1018194 },
    { lat: 49.0648198, lng: 20.1018572 },
    { lat: 49.0644138, lng: 20.1017912 },
    { lat: 49.0643826, lng: 20.1018821 },
    { lat: 49.0641916, lng: 20.1019486 },
    { lat: 49.06419, lng: 20.1020446 },
    { lat: 49.064034, lng: 20.1021423 },
    { lat: 49.0639354, lng: 20.1020729 },
    { lat: 49.0638309, lng: 20.1021032 },
    { lat: 49.0638148, lng: 20.1022082 },
    { lat: 49.0637317, lng: 20.1023004 },
    { lat: 49.0636275, lng: 20.1023087 },
    { lat: 49.0636098, lng: 20.1022321 },
    { lat: 49.0634472, lng: 20.1021407 },
    { lat: 49.0633763, lng: 20.1021711 },
    { lat: 49.0631717, lng: 20.1022888 },
    { lat: 49.0631164, lng: 20.1024254 },
    { lat: 49.0630789, lng: 20.102393 },
    { lat: 49.0629388, lng: 20.1025158 },
    { lat: 49.0628271, lng: 20.1024548 },
    { lat: 49.0627449, lng: 20.1024837 },
    { lat: 49.0626816, lng: 20.1027321 },
    { lat: 49.0627436, lng: 20.1027596 },
    { lat: 49.0627391, lng: 20.1028991 },
    { lat: 49.062615, lng: 20.1028942 },
    { lat: 49.0625653, lng: 20.1027513 },
    { lat: 49.0624901, lng: 20.1028101 },
    { lat: 49.0623587, lng: 20.1026249 },
    { lat: 49.062293, lng: 20.1026692 },
    { lat: 49.0623355, lng: 20.1029438 },
    { lat: 49.0623924, lng: 20.1029274 },
    { lat: 49.0624133, lng: 20.1030295 },
    { lat: 49.0622016, lng: 20.1031109 },
    { lat: 49.0621607, lng: 20.1032138 },
    { lat: 49.0621089, lng: 20.1031864 },
    { lat: 49.0620417, lng: 20.1032523 },
    { lat: 49.0618439, lng: 20.1030932 },
    { lat: 49.0618284, lng: 20.1032131 },
    { lat: 49.0615341, lng: 20.1031485 },
    { lat: 49.0612749, lng: 20.1037281 },
    { lat: 49.0613626, lng: 20.1039971 },
    { lat: 49.0613354, lng: 20.1041067 },
    { lat: 49.0611963, lng: 20.1040925 },
    { lat: 49.0610879, lng: 20.1039741 },
    { lat: 49.0609729, lng: 20.1040539 },
    { lat: 49.0609547, lng: 20.1041884 },
    { lat: 49.0610169, lng: 20.1042487 },
    { lat: 49.0609413, lng: 20.1043865 },
    { lat: 49.0609433, lng: 20.1045163 },
    { lat: 49.0609846, lng: 20.1045641 },
    { lat: 49.0609282, lng: 20.1046732 },
    { lat: 49.0608617, lng: 20.1046694 },
    { lat: 49.0608615, lng: 20.1046113 },
    { lat: 49.060669, lng: 20.1046427 },
    { lat: 49.0606647, lng: 20.1047296 },
    { lat: 49.0604991, lng: 20.1047542 },
    { lat: 49.0604693, lng: 20.1045207 },
    { lat: 49.060336, lng: 20.104428 },
    { lat: 49.0603107, lng: 20.1046642 },
    { lat: 49.0604211, lng: 20.1049705 },
    { lat: 49.060348, lng: 20.1050837 },
    { lat: 49.0602454, lng: 20.1050269 },
    { lat: 49.0601814, lng: 20.1051119 },
    { lat: 49.0601058, lng: 20.10511 },
    { lat: 49.060124, lng: 20.104979 },
    { lat: 49.0600692, lng: 20.1049005 },
    { lat: 49.0600182, lng: 20.1050044 },
    { lat: 49.0598785, lng: 20.104977 },
    { lat: 49.0595965, lng: 20.1053842 },
    { lat: 49.0595499, lng: 20.1057599 },
    { lat: 49.0596375, lng: 20.1059466 },
    { lat: 49.0596261, lng: 20.1060246 },
    { lat: 49.0595696, lng: 20.1060234 },
    { lat: 49.0595333, lng: 20.1060994 },
    { lat: 49.0596056, lng: 20.10627 },
    { lat: 49.0593169, lng: 20.1070645 },
    { lat: 49.059326, lng: 20.1072371 },
    { lat: 49.0592763, lng: 20.1072561 },
    { lat: 49.0593159, lng: 20.1076812 },
    { lat: 49.0592218, lng: 20.1077461 },
    { lat: 49.0590706, lng: 20.1077118 },
    { lat: 49.0589679, lng: 20.107794 },
    { lat: 49.0587837, lng: 20.1077786 },
    { lat: 49.0587276, lng: 20.1080288 },
    { lat: 49.0583366, lng: 20.1082454 },
    { lat: 49.0582843, lng: 20.108511 },
    { lat: 49.0581614, lng: 20.1086228 },
    { lat: 49.0579976, lng: 20.1086622 },
    { lat: 49.0578841, lng: 20.1088363 },
    { lat: 49.057812, lng: 20.1088046 },
    { lat: 49.0577656, lng: 20.1090216 },
    { lat: 49.0576599, lng: 20.1091109 },
    { lat: 49.0576067, lng: 20.1090782 },
    { lat: 49.057621, lng: 20.1089594 },
    { lat: 49.0575776, lng: 20.1089728 },
    { lat: 49.0575477, lng: 20.1091494 },
    { lat: 49.0574066, lng: 20.1093063 },
    { lat: 49.0572493, lng: 20.1092051 },
    { lat: 49.0570747, lng: 20.1092061 },
    { lat: 49.0569735, lng: 20.1092644 },
    { lat: 49.056867, lng: 20.1095024 },
    { lat: 49.0566162, lng: 20.1095371 },
    { lat: 49.0564763, lng: 20.1096659 },
    { lat: 49.0559496, lng: 20.1096223 },
    { lat: 49.0559096, lng: 20.1094968 },
    { lat: 49.0557727, lng: 20.109588 },
    { lat: 49.0554705, lng: 20.1092483 },
    { lat: 49.0552799, lng: 20.1091344 },
    { lat: 49.0549797, lng: 20.1092258 },
    { lat: 49.0549212, lng: 20.1093954 },
    { lat: 49.0544529, lng: 20.1096211 },
    { lat: 49.0541776, lng: 20.1101637 },
    { lat: 49.0538051, lng: 20.1112263 },
    { lat: 49.0531893, lng: 20.1108981 },
    { lat: 49.0522675, lng: 20.108137 },
    { lat: 49.0519417, lng: 20.1068825 },
    { lat: 49.0503316, lng: 20.1072808 },
    { lat: 49.0493074, lng: 20.1077645 },
    { lat: 49.0484048, lng: 20.1088359 },
    { lat: 49.0467696, lng: 20.1116986 },
    { lat: 49.0445081, lng: 20.1129972 },
    { lat: 49.044443, lng: 20.1122832 },
    { lat: 49.0441683, lng: 20.1127846 },
    { lat: 49.0435512, lng: 20.1153167 },
    { lat: 49.0419544, lng: 20.120518 },
    { lat: 49.0382958, lng: 20.1285732 },
    { lat: 49.037763, lng: 20.1296328 },
    { lat: 49.0370385, lng: 20.1298419 },
    { lat: 49.0354751, lng: 20.1320702 },
    { lat: 49.0336507, lng: 20.1342445 },
    { lat: 49.0330452, lng: 20.1337505 },
    { lat: 49.0327878, lng: 20.1334303 },
    { lat: 49.0326166, lng: 20.134152 },
    { lat: 49.0320305, lng: 20.1349213 },
    { lat: 49.0319824, lng: 20.1352674 },
    { lat: 49.0320619, lng: 20.1357865 },
    { lat: 49.0318301, lng: 20.1367962 },
    { lat: 49.0315106, lng: 20.1372485 },
    { lat: 49.0311104, lng: 20.1371832 },
    { lat: 49.0300457, lng: 20.1351644 },
    { lat: 49.0296894, lng: 20.1354876 },
    { lat: 49.0280052, lng: 20.139858 },
    { lat: 49.0275578, lng: 20.1412172 },
    { lat: 49.0273612, lng: 20.1416188 },
    { lat: 49.0262028, lng: 20.1421897 },
    { lat: 49.0251712, lng: 20.1423857 },
    { lat: 49.0249839, lng: 20.1431029 },
    { lat: 49.0248995, lng: 20.1438895 },
    { lat: 49.0250203, lng: 20.1453183 },
    { lat: 49.0247079, lng: 20.1466787 },
    { lat: 49.0224171, lng: 20.1478193 },
    { lat: 49.021642, lng: 20.1478997 },
    { lat: 49.0204378, lng: 20.1485037 },
    { lat: 49.0202466, lng: 20.1488792 },
    { lat: 49.0200153, lng: 20.1491487 },
    { lat: 49.0194732, lng: 20.1492756 },
    { lat: 49.0190685, lng: 20.1492049 },
    { lat: 49.0179936, lng: 20.1492239 },
  ],
  Jánovce: [
    { lat: 48.996473, lng: 20.433186 },
    { lat: 48.996477, lng: 20.433213 },
    { lat: 48.996589, lng: 20.433901 },
    { lat: 48.996718, lng: 20.434493 },
    { lat: 48.996908, lng: 20.435207 },
    { lat: 48.997003, lng: 20.435589 },
    { lat: 48.997192, lng: 20.436134 },
    { lat: 48.997286, lng: 20.436469 },
    { lat: 48.997661, lng: 20.438042 },
    { lat: 48.997716, lng: 20.438457 },
    { lat: 48.997718, lng: 20.43868 },
    { lat: 48.997738, lng: 20.438927 },
    { lat: 48.997849, lng: 20.440131 },
    { lat: 48.99793, lng: 20.440475 },
    { lat: 48.998024, lng: 20.440775 },
    { lat: 48.998091, lng: 20.440924 },
    { lat: 48.998318, lng: 20.441412 },
    { lat: 48.998474, lng: 20.44172 },
    { lat: 48.998737, lng: 20.442185 },
    { lat: 48.998746, lng: 20.442201 },
    { lat: 48.998756, lng: 20.442216 },
    { lat: 48.999123, lng: 20.442738 },
    { lat: 49.00008, lng: 20.444443 },
    { lat: 49.000614, lng: 20.444019 },
    { lat: 49.000898, lng: 20.443897 },
    { lat: 49.001212, lng: 20.443796 },
    { lat: 49.001445, lng: 20.443844 },
    { lat: 49.002277, lng: 20.443924 },
    { lat: 49.002599, lng: 20.444001 },
    { lat: 49.003427, lng: 20.444169 },
    { lat: 49.003445, lng: 20.444173 },
    { lat: 49.003409, lng: 20.44319 },
    { lat: 49.003407, lng: 20.443104 },
    { lat: 49.003402, lng: 20.442726 },
    { lat: 49.003355, lng: 20.442153 },
    { lat: 49.003336, lng: 20.441284 },
    { lat: 49.003334, lng: 20.441196 },
    { lat: 49.003338, lng: 20.440973 },
    { lat: 49.003344, lng: 20.440422 },
    { lat: 49.003441, lng: 20.43986 },
    { lat: 49.003484, lng: 20.439392 },
    { lat: 49.003533, lng: 20.439242 },
    { lat: 49.003672, lng: 20.439 },
    { lat: 49.003742, lng: 20.438912 },
    { lat: 49.00395, lng: 20.438658 },
    { lat: 49.004386, lng: 20.438228 },
    { lat: 49.004472, lng: 20.438183 },
    { lat: 49.004663, lng: 20.438082 },
    { lat: 49.004704, lng: 20.438058 },
    { lat: 49.00506, lng: 20.437851 },
    { lat: 49.005415, lng: 20.438045 },
    { lat: 49.005651, lng: 20.438124 },
    { lat: 49.005868, lng: 20.438202 },
    { lat: 49.006147, lng: 20.438277 },
    { lat: 49.006354, lng: 20.438437 },
    { lat: 49.006617, lng: 20.438369 },
    { lat: 49.006743, lng: 20.43829 },
    { lat: 49.006822, lng: 20.438236 },
    { lat: 49.006856, lng: 20.438201 },
    { lat: 49.006954, lng: 20.4381 },
    { lat: 49.0069672, lng: 20.4380918 },
    { lat: 49.006991, lng: 20.438077 },
    { lat: 49.007175, lng: 20.437982 },
    { lat: 49.007251, lng: 20.437954 },
    { lat: 49.007539, lng: 20.437848 },
    { lat: 49.00778, lng: 20.437792 },
    { lat: 49.007994, lng: 20.437783 },
    { lat: 49.00816, lng: 20.437871 },
    { lat: 49.008203, lng: 20.437949 },
    { lat: 49.008602, lng: 20.438686 },
    { lat: 49.008676, lng: 20.438743 },
    { lat: 49.008742, lng: 20.438793 },
    { lat: 49.008895, lng: 20.438907 },
    { lat: 49.009313, lng: 20.43909 },
    { lat: 49.010066, lng: 20.439064 },
    { lat: 49.010393, lng: 20.439515 },
    { lat: 49.010468, lng: 20.439618 },
    { lat: 49.010482, lng: 20.43973 },
    { lat: 49.01047, lng: 20.439843 },
    { lat: 49.010461, lng: 20.439931 },
    { lat: 49.010643, lng: 20.44146 },
    { lat: 49.011078, lng: 20.441606 },
    { lat: 49.0115, lng: 20.441667 },
    { lat: 49.011979, lng: 20.441732 },
    { lat: 49.012224, lng: 20.441755 },
    { lat: 49.012345, lng: 20.441748 },
    { lat: 49.012561, lng: 20.441735 },
    { lat: 49.01272, lng: 20.441696 },
    { lat: 49.013108, lng: 20.4416 },
    { lat: 49.013269, lng: 20.441561 },
    { lat: 49.013385, lng: 20.441554 },
    { lat: 49.013512, lng: 20.441585 },
    { lat: 49.013703, lng: 20.441632 },
    { lat: 49.013894, lng: 20.441678 },
    { lat: 49.013978, lng: 20.441699 },
    { lat: 49.01415, lng: 20.441741 },
    { lat: 49.014177, lng: 20.441748 },
    { lat: 49.014214, lng: 20.441757 },
    { lat: 49.014231, lng: 20.441762 },
    { lat: 49.014396, lng: 20.441806 },
    { lat: 49.014574, lng: 20.441855 },
    { lat: 49.014738, lng: 20.441899 },
    { lat: 49.014763, lng: 20.441906 },
    { lat: 49.01487, lng: 20.44194 },
    { lat: 49.01492, lng: 20.441958 },
    { lat: 49.015019, lng: 20.441996 },
    { lat: 49.015062, lng: 20.442013 },
    { lat: 49.015148, lng: 20.442037 },
    { lat: 49.01515, lng: 20.44202 },
    { lat: 49.01516, lng: 20.441971 },
    { lat: 49.015174, lng: 20.4419 },
    { lat: 49.015178, lng: 20.441875 },
    { lat: 49.015193, lng: 20.441799 },
    { lat: 49.015213, lng: 20.44169 },
    { lat: 49.01522, lng: 20.441655 },
    { lat: 49.01524, lng: 20.441666 },
    { lat: 49.015337, lng: 20.441723 },
    { lat: 49.015351, lng: 20.441731 },
    { lat: 49.015398, lng: 20.441759 },
    { lat: 49.015394, lng: 20.441777 },
    { lat: 49.015371, lng: 20.441881 },
    { lat: 49.015445, lng: 20.441877 },
    { lat: 49.01553, lng: 20.441864 },
    { lat: 49.01576, lng: 20.441809 },
    { lat: 49.015956, lng: 20.441771 },
    { lat: 49.01639, lng: 20.441676 },
    { lat: 49.017164, lng: 20.441519 },
    { lat: 49.0172695, lng: 20.4415013 },
    { lat: 49.017601, lng: 20.441436 },
    { lat: 49.018155, lng: 20.441316 },
    { lat: 49.018909, lng: 20.441166 },
    { lat: 49.019484, lng: 20.441047 },
    { lat: 49.019629, lng: 20.441022 },
    { lat: 49.020294, lng: 20.440895 },
    { lat: 49.0203803, lng: 20.4408755 },
    { lat: 49.020427, lng: 20.440865 },
    { lat: 49.020656, lng: 20.440821 },
    { lat: 49.020863, lng: 20.440777 },
    { lat: 49.02138, lng: 20.440658 },
    { lat: 49.022439, lng: 20.440453 },
    { lat: 49.022877, lng: 20.440355 },
    { lat: 49.023685, lng: 20.440179 },
    { lat: 49.023976, lng: 20.44007 },
    { lat: 49.024655, lng: 20.439708 },
    { lat: 49.024671, lng: 20.43978 },
    { lat: 49.024674, lng: 20.439794 },
    { lat: 49.024909, lng: 20.439872 },
    { lat: 49.025047, lng: 20.439908 },
    { lat: 49.025128, lng: 20.43996 },
    { lat: 49.024964, lng: 20.440116 },
    { lat: 49.024394, lng: 20.440532 },
    { lat: 49.02382, lng: 20.440931 },
    { lat: 49.02344, lng: 20.441256 },
    { lat: 49.023284, lng: 20.441458 },
    { lat: 49.023177, lng: 20.441607 },
    { lat: 49.022979, lng: 20.441806 },
    { lat: 49.022699, lng: 20.44198 },
    { lat: 49.022462, lng: 20.442216 },
    { lat: 49.022139, lng: 20.442466 },
    { lat: 49.021932, lng: 20.442659 },
    { lat: 49.021789, lng: 20.442753 },
    { lat: 49.021473, lng: 20.443024 },
    { lat: 49.02097, lng: 20.443499 },
    { lat: 49.020815, lng: 20.443645 },
    { lat: 49.020759, lng: 20.443831 },
    { lat: 49.020538, lng: 20.444045 },
    { lat: 49.020148, lng: 20.444602 },
    { lat: 49.019843, lng: 20.445035 },
    { lat: 49.019686, lng: 20.445217 },
    { lat: 49.019224, lng: 20.446072 },
    { lat: 49.018843, lng: 20.446343 },
    { lat: 49.018342, lng: 20.446602 },
    { lat: 49.018006, lng: 20.446839 },
    { lat: 49.017857, lng: 20.446921 },
    { lat: 49.017738, lng: 20.446897 },
    { lat: 49.01748, lng: 20.446918 },
    { lat: 49.017457, lng: 20.446929 },
    { lat: 49.017409, lng: 20.446951 },
    { lat: 49.017384, lng: 20.446962 },
    { lat: 49.017374, lng: 20.446966 },
    { lat: 49.017305, lng: 20.446996 },
    { lat: 49.017284, lng: 20.447006 },
    { lat: 49.017185, lng: 20.447017 },
    { lat: 49.017177, lng: 20.447017 },
    { lat: 49.017091, lng: 20.447044 },
    { lat: 49.017063, lng: 20.447053 },
    { lat: 49.017015, lng: 20.447079 },
    { lat: 49.016539, lng: 20.447345 },
    { lat: 49.016311, lng: 20.44743 },
    { lat: 49.016071, lng: 20.447577 },
    { lat: 49.015724, lng: 20.447902 },
    { lat: 49.015424, lng: 20.448265 },
    { lat: 49.015409, lng: 20.448287 },
    { lat: 49.015488, lng: 20.44871 },
    { lat: 49.015575, lng: 20.449027 },
    { lat: 49.015647, lng: 20.449286 },
    { lat: 49.015257, lng: 20.449434 },
    { lat: 49.015073, lng: 20.449584 },
    { lat: 49.014843, lng: 20.449738 },
    { lat: 49.014762, lng: 20.449855 },
    { lat: 49.014671, lng: 20.449952 },
    { lat: 49.014323, lng: 20.450152 },
    { lat: 49.01433, lng: 20.450194 },
    { lat: 49.014318, lng: 20.450202 },
    { lat: 49.014318, lng: 20.450206 },
    { lat: 49.014328, lng: 20.450266 },
    { lat: 49.014331, lng: 20.450275 },
    { lat: 49.014604, lng: 20.451867 },
    { lat: 49.014618, lng: 20.451949 },
    { lat: 49.014756, lng: 20.452739 },
    { lat: 49.014815, lng: 20.453078 },
    { lat: 49.015171, lng: 20.452948 },
    { lat: 49.01534, lng: 20.452886 },
    { lat: 49.015654, lng: 20.452753 },
    { lat: 49.015878, lng: 20.45268 },
    { lat: 49.016448, lng: 20.452532 },
    { lat: 49.016791, lng: 20.452436 },
    { lat: 49.017463, lng: 20.452205 },
    { lat: 49.017802, lng: 20.452091 },
    { lat: 49.01835, lng: 20.451904 },
    { lat: 49.018748, lng: 20.451865 },
    { lat: 49.01884, lng: 20.451857 },
    { lat: 49.019112, lng: 20.45183 },
    { lat: 49.019166, lng: 20.451901 },
    { lat: 49.019387, lng: 20.452026 },
    { lat: 49.019798, lng: 20.452115 },
    { lat: 49.020136, lng: 20.452191 },
    { lat: 49.020468, lng: 20.452296 },
    { lat: 49.020548, lng: 20.452369 },
    { lat: 49.021016, lng: 20.452488 },
    { lat: 49.021521, lng: 20.452576 },
    { lat: 49.021719, lng: 20.452618 },
    { lat: 49.022189, lng: 20.45273 },
    { lat: 49.022553, lng: 20.452807 },
    { lat: 49.023037, lng: 20.452825 },
    { lat: 49.023716, lng: 20.452681 },
    { lat: 49.024124, lng: 20.452586 },
    { lat: 49.024324, lng: 20.452548 },
    { lat: 49.02518, lng: 20.452556 },
    { lat: 49.025542, lng: 20.452625 },
    { lat: 49.026089, lng: 20.452695 },
    { lat: 49.02632, lng: 20.45274 },
    { lat: 49.02673, lng: 20.45282 },
    { lat: 49.027513, lng: 20.452949 },
    { lat: 49.027641, lng: 20.453046 },
    { lat: 49.028001, lng: 20.453553 },
    { lat: 49.028767, lng: 20.454155 },
    { lat: 49.029043, lng: 20.45455 },
    { lat: 49.029335, lng: 20.454924 },
    { lat: 49.029704, lng: 20.455394 },
    { lat: 49.030048, lng: 20.455933 },
    { lat: 49.030404, lng: 20.456413 },
    { lat: 49.030881, lng: 20.457255 },
    { lat: 49.031219, lng: 20.456989 },
    { lat: 49.031468, lng: 20.456793 },
    { lat: 49.031746, lng: 20.456574 },
    { lat: 49.031988, lng: 20.456123 },
    { lat: 49.0321, lng: 20.455585 },
    { lat: 49.032129, lng: 20.455322 },
    { lat: 49.032132, lng: 20.454619 },
    { lat: 49.032156, lng: 20.45369 },
    { lat: 49.032062, lng: 20.45291 },
    { lat: 49.032002, lng: 20.451877 },
    { lat: 49.032422, lng: 20.451724 },
    { lat: 49.032525, lng: 20.451691 },
    { lat: 49.032859, lng: 20.451649 },
    { lat: 49.033392, lng: 20.451583 },
    { lat: 49.033559, lng: 20.451562 },
    { lat: 49.033628, lng: 20.451555 },
    { lat: 49.033635, lng: 20.45138 },
    { lat: 49.033849, lng: 20.451369 },
    { lat: 49.033863, lng: 20.45137 },
    { lat: 49.034078, lng: 20.451322 },
    { lat: 49.03403, lng: 20.450172 },
    { lat: 49.034024, lng: 20.449763 },
    { lat: 49.034013, lng: 20.449608 },
    { lat: 49.033933, lng: 20.448587 },
    { lat: 49.033913, lng: 20.448442 },
    { lat: 49.033894, lng: 20.448311 },
    { lat: 49.033887, lng: 20.448248 },
    { lat: 49.033853, lng: 20.447997 },
    { lat: 49.033786, lng: 20.447531 },
    { lat: 49.033705, lng: 20.447017 },
    { lat: 49.033548, lng: 20.445996 },
    { lat: 49.033536, lng: 20.445969 },
    { lat: 49.033921, lng: 20.445435 },
    { lat: 49.03426, lng: 20.444997 },
    { lat: 49.034692, lng: 20.444556 },
    { lat: 49.034702, lng: 20.44458 },
    { lat: 49.03477, lng: 20.444519 },
    { lat: 49.035228, lng: 20.444438 },
    { lat: 49.036694, lng: 20.444379 },
    { lat: 49.036762, lng: 20.444247 },
    { lat: 49.037159, lng: 20.443462 },
    { lat: 49.03737, lng: 20.443045 },
    { lat: 49.037529, lng: 20.442682 },
    { lat: 49.037047, lng: 20.442561 },
    { lat: 49.036471, lng: 20.442463 },
    { lat: 49.036458, lng: 20.441847 },
    { lat: 49.036486, lng: 20.441138 },
    { lat: 49.036527, lng: 20.440051 },
    { lat: 49.03654, lng: 20.439385 },
    { lat: 49.036514, lng: 20.438754 },
    { lat: 49.036465, lng: 20.437571 },
    { lat: 49.036597, lng: 20.436757 },
    { lat: 49.035901, lng: 20.436333 },
    { lat: 49.035906, lng: 20.436304 },
    { lat: 49.035911, lng: 20.436248 },
    { lat: 49.035903, lng: 20.436171 },
    { lat: 49.035902, lng: 20.436156 },
    { lat: 49.03591, lng: 20.436048 },
    { lat: 49.035916, lng: 20.435844 },
    { lat: 49.035905, lng: 20.43566 },
    { lat: 49.035891, lng: 20.435444 },
    { lat: 49.03588, lng: 20.435321 },
    { lat: 49.035855, lng: 20.435146 },
    { lat: 49.035831, lng: 20.435004 },
    { lat: 49.035764, lng: 20.434703 },
    { lat: 49.035699, lng: 20.434401 },
    { lat: 49.035836, lng: 20.434358 },
    { lat: 49.036166, lng: 20.434266 },
    { lat: 49.036154, lng: 20.43423 },
    { lat: 49.036027, lng: 20.433848 },
    { lat: 49.035938, lng: 20.433595 },
    { lat: 49.03592, lng: 20.433558 },
    { lat: 49.035861, lng: 20.433433 },
    { lat: 49.035674, lng: 20.433101 },
    { lat: 49.035489, lng: 20.432783 },
    { lat: 49.035423, lng: 20.432632 },
    { lat: 49.035353, lng: 20.432448 },
    { lat: 49.035283, lng: 20.43225 },
    { lat: 49.035259, lng: 20.432165 },
    { lat: 49.035198, lng: 20.431868 },
    { lat: 49.03518, lng: 20.431709 },
    { lat: 49.035167, lng: 20.431569 },
    { lat: 49.035164, lng: 20.431522 },
    { lat: 49.0351081, lng: 20.4308553 },
    { lat: 49.035194, lng: 20.43053 },
    { lat: 49.035103, lng: 20.430065 },
    { lat: 49.035093, lng: 20.429988 },
    { lat: 49.035057, lng: 20.429741 },
    { lat: 49.035115, lng: 20.429588 },
    { lat: 49.035133, lng: 20.429454 },
    { lat: 49.035138, lng: 20.429389 },
    { lat: 49.03514, lng: 20.429371 },
    { lat: 49.035184, lng: 20.429215 },
    { lat: 49.035195, lng: 20.429103 },
    { lat: 49.035192, lng: 20.428718 },
    { lat: 49.035195, lng: 20.428685 },
    { lat: 49.035221, lng: 20.428679 },
    { lat: 49.035249, lng: 20.428672 },
    { lat: 49.035286, lng: 20.428645 },
    { lat: 49.035315, lng: 20.428618 },
    { lat: 49.035319, lng: 20.428612 },
    { lat: 49.03535, lng: 20.428561 },
    { lat: 49.035381, lng: 20.428496 },
    { lat: 49.035408, lng: 20.428423 },
    { lat: 49.035415, lng: 20.42835 },
    { lat: 49.035425, lng: 20.428242 },
    { lat: 49.035434, lng: 20.428159 },
    { lat: 49.03544, lng: 20.428061 },
    { lat: 49.035457, lng: 20.427078 },
    { lat: 49.035456, lng: 20.426893 },
    { lat: 49.035455, lng: 20.426647 },
    { lat: 49.03548, lng: 20.426508 },
    { lat: 49.035511, lng: 20.426362 },
    { lat: 49.035599, lng: 20.425988 },
    { lat: 49.03569, lng: 20.425535 },
    { lat: 49.035766, lng: 20.425156 },
    { lat: 49.035933, lng: 20.424287 },
    { lat: 49.036167, lng: 20.42324 },
    { lat: 49.036177, lng: 20.423147 },
    { lat: 49.036215, lng: 20.422917 },
    { lat: 49.036254, lng: 20.422649 },
    { lat: 49.036291, lng: 20.422424 },
    { lat: 49.036356, lng: 20.421988 },
    { lat: 49.036381, lng: 20.421871 },
    { lat: 49.036403, lng: 20.421758 },
    { lat: 49.036415, lng: 20.421704 },
    { lat: 49.036454, lng: 20.421419 },
    { lat: 49.036583, lng: 20.42042 },
    { lat: 49.036632, lng: 20.418494 },
    { lat: 49.036663, lng: 20.417244 },
    { lat: 49.036673, lng: 20.417091 },
    { lat: 49.036749, lng: 20.416157 },
    { lat: 49.036763, lng: 20.415973 },
    { lat: 49.0368809, lng: 20.4151772 },
    { lat: 49.0331472, lng: 20.4140489 },
    { lat: 49.0318705, lng: 20.4131046 },
    { lat: 49.0313642, lng: 20.4125534 },
    { lat: 49.0309742, lng: 20.4122428 },
    { lat: 49.0295222, lng: 20.4117521 },
    { lat: 49.0289089, lng: 20.4114928 },
    { lat: 49.0284754, lng: 20.4112316 },
    { lat: 49.0276302, lng: 20.4110325 },
    { lat: 49.0243602, lng: 20.4099295 },
    { lat: 49.0230493, lng: 20.4097143 },
    { lat: 49.0224472, lng: 20.4093984 },
    { lat: 49.0216354, lng: 20.4085054 },
    { lat: 49.0212246, lng: 20.407805 },
    { lat: 49.0211265, lng: 20.4074714 },
    { lat: 49.0207018, lng: 20.4067876 },
    { lat: 49.0198687, lng: 20.4060752 },
    { lat: 49.0187259, lng: 20.4053413 },
    { lat: 49.0184665, lng: 20.4061559 },
    { lat: 49.018134, lng: 20.4067687 },
    { lat: 49.0180198, lng: 20.4071099 },
    { lat: 49.0180602, lng: 20.4077327 },
    { lat: 49.0180573, lng: 20.4080719 },
    { lat: 49.0180207, lng: 20.4080838 },
    { lat: 49.0157441, lng: 20.4086171 },
    { lat: 49.0156475, lng: 20.4104335 },
    { lat: 49.0155832, lng: 20.4116419 },
    { lat: 49.0135369, lng: 20.4108363 },
    { lat: 49.0117041, lng: 20.4096558 },
    { lat: 49.0110533, lng: 20.4079045 },
    { lat: 49.0100441, lng: 20.4114536 },
    { lat: 49.0088907, lng: 20.4140526 },
    { lat: 49.0085116, lng: 20.4151543 },
    { lat: 49.0084809, lng: 20.4157133 },
    { lat: 49.0083516, lng: 20.4178515 },
    { lat: 49.008467, lng: 20.4205718 },
    { lat: 49.007249, lng: 20.4230413 },
    { lat: 49.0057772, lng: 20.4250574 },
    { lat: 49.0050543, lng: 20.4253832 },
    { lat: 49.0050442, lng: 20.4256523 },
    { lat: 49.0039983, lng: 20.4263693 },
    { lat: 49.0034298, lng: 20.42664 },
    { lat: 49.002666, lng: 20.426836 },
    { lat: 49.002951, lng: 20.427547 },
    { lat: 49.003506, lng: 20.428889 },
    { lat: 49.003263, lng: 20.428985 },
    { lat: 49.003053, lng: 20.429022 },
    { lat: 49.003009, lng: 20.428997 },
    { lat: 49.002849, lng: 20.428822 },
    { lat: 49.002029, lng: 20.428925 },
    { lat: 49.00199, lng: 20.42893 },
    { lat: 49.001689, lng: 20.428979 },
    { lat: 49.00167, lng: 20.428983 },
    { lat: 49.001755, lng: 20.429481 },
    { lat: 49.001772, lng: 20.429711 },
    { lat: 49.001778, lng: 20.429904 },
    { lat: 49.001751, lng: 20.430422 },
    { lat: 49.001678, lng: 20.430908 },
    { lat: 49.0016, lng: 20.432115 },
    { lat: 49.001511, lng: 20.433266 },
    { lat: 49.001511, lng: 20.4333 },
    { lat: 49.000954, lng: 20.433311 },
    { lat: 49.000329, lng: 20.433287 },
    { lat: 48.999921, lng: 20.433287 },
    { lat: 48.999501, lng: 20.433269 },
    { lat: 48.999062, lng: 20.433269 },
    { lat: 48.99819, lng: 20.433175 },
    { lat: 48.99648, lng: 20.433159 },
    { lat: 48.996473, lng: 20.433186 },
  ],
  LiptovskáTeplička: [
    { lat: 48.896721, lng: 20.090055 },
    { lat: 48.89662, lng: 20.090491 },
    { lat: 48.896384, lng: 20.091594 },
    { lat: 48.896095, lng: 20.092942 },
    { lat: 48.895879, lng: 20.093956 },
    { lat: 48.895612, lng: 20.095204 },
    { lat: 48.895549, lng: 20.096483 },
    { lat: 48.895498, lng: 20.097528 },
    { lat: 48.895451, lng: 20.098492 },
    { lat: 48.895405, lng: 20.099417 },
    { lat: 48.895378, lng: 20.100571 },
    { lat: 48.895328, lng: 20.101802 },
    { lat: 48.895286, lng: 20.102868 },
    { lat: 48.895274, lng: 20.10391 },
    { lat: 48.895272, lng: 20.104284 },
    { lat: 48.895185, lng: 20.105044 },
    { lat: 48.89507, lng: 20.106035 },
    { lat: 48.895023, lng: 20.106464 },
    { lat: 48.894957, lng: 20.107029 },
    { lat: 48.894927, lng: 20.107312 },
    { lat: 48.894897, lng: 20.107538 },
    { lat: 48.894839, lng: 20.108139 },
    { lat: 48.894781, lng: 20.108644 },
    { lat: 48.894728, lng: 20.109109 },
    { lat: 48.894638, lng: 20.109899 },
    { lat: 48.894498, lng: 20.111157 },
    { lat: 48.894399, lng: 20.112026 },
    { lat: 48.894291, lng: 20.112726 },
    { lat: 48.894229, lng: 20.113122 },
    { lat: 48.894109, lng: 20.11399 },
    { lat: 48.893947, lng: 20.115124 },
    { lat: 48.893792, lng: 20.116231 },
    { lat: 48.893647, lng: 20.117249 },
    { lat: 48.893513, lng: 20.118207 },
    { lat: 48.893355, lng: 20.119329 },
    { lat: 48.893307, lng: 20.119653 },
    { lat: 48.893263, lng: 20.120127 },
    { lat: 48.893164, lng: 20.121237 },
    { lat: 48.893061, lng: 20.122443 },
    { lat: 48.892956, lng: 20.123636 },
    { lat: 48.892889, lng: 20.124409 },
    { lat: 48.892824, lng: 20.125148 },
    { lat: 48.892736, lng: 20.126134 },
    { lat: 48.892663, lng: 20.126973 },
    { lat: 48.892592, lng: 20.127812 },
    { lat: 48.892519, lng: 20.128646 },
    { lat: 48.892427, lng: 20.12968 },
    { lat: 48.892402, lng: 20.129981 },
    { lat: 48.89229, lng: 20.130885 },
    { lat: 48.892169, lng: 20.131896 },
    { lat: 48.892065, lng: 20.132764 },
    { lat: 48.891998, lng: 20.133351 },
    { lat: 48.89198, lng: 20.133484 },
    { lat: 48.891894, lng: 20.134166 },
    { lat: 48.891795, lng: 20.134956 },
    { lat: 48.891674, lng: 20.135925 },
    { lat: 48.891591, lng: 20.136585 },
    { lat: 48.891493, lng: 20.137524 },
    { lat: 48.891345, lng: 20.138889 },
    { lat: 48.891205, lng: 20.140193 },
    { lat: 48.891063, lng: 20.141513 },
    { lat: 48.89093, lng: 20.142761 },
    { lat: 48.890847, lng: 20.143542 },
    { lat: 48.890721, lng: 20.144715 },
    { lat: 48.890623, lng: 20.145616 },
    { lat: 48.890541, lng: 20.146317 },
    { lat: 48.890393, lng: 20.147615 },
    { lat: 48.890246, lng: 20.148924 },
    { lat: 48.890149, lng: 20.149763 },
    { lat: 48.890051, lng: 20.150632 },
    { lat: 48.889911, lng: 20.151862 },
    { lat: 48.889738, lng: 20.153357 },
    { lat: 48.889636, lng: 20.154258 },
    { lat: 48.889521, lng: 20.155268 },
    { lat: 48.889428, lng: 20.1561 },
    { lat: 48.889334, lng: 20.156927 },
    { lat: 48.889202, lng: 20.158067 },
    { lat: 48.891084, lng: 20.159897 },
    { lat: 48.891252, lng: 20.160064 },
    { lat: 48.891633, lng: 20.160429 },
    { lat: 48.891987, lng: 20.160771 },
    { lat: 48.892337, lng: 20.16111 },
    { lat: 48.892744, lng: 20.161505 },
    { lat: 48.89311, lng: 20.161861 },
    { lat: 48.89457, lng: 20.163279 },
    { lat: 48.894729, lng: 20.163471 },
    { lat: 48.894894, lng: 20.163675 },
    { lat: 48.895167, lng: 20.164014 },
    { lat: 48.895936, lng: 20.164957 },
    { lat: 48.896256, lng: 20.165355 },
    { lat: 48.896673, lng: 20.165875 },
    { lat: 48.896791, lng: 20.166021 },
    { lat: 48.897777, lng: 20.167221 },
    { lat: 48.901755, lng: 20.170172 },
    { lat: 48.903115, lng: 20.171174 },
    { lat: 48.904525, lng: 20.172406 },
    { lat: 48.905068, lng: 20.172905 },
    { lat: 48.905413, lng: 20.173227 },
    { lat: 48.905571, lng: 20.173378 },
    { lat: 48.905877, lng: 20.173664 },
    { lat: 48.906182, lng: 20.173951 },
    { lat: 48.906501, lng: 20.174253 },
    { lat: 48.906855, lng: 20.174586 },
    { lat: 48.907297, lng: 20.174996 },
    { lat: 48.90754, lng: 20.175227 },
    { lat: 48.907974, lng: 20.175661 },
    { lat: 48.9078408, lng: 20.1749517 },
    { lat: 48.9077289, lng: 20.1730628 },
    { lat: 48.9071831, lng: 20.1698493 },
    { lat: 48.9071331, lng: 20.1612646 },
    { lat: 48.9086455, lng: 20.1578802 },
    { lat: 48.9091814, lng: 20.1573468 },
    { lat: 48.9093157, lng: 20.1570991 },
    { lat: 48.9094182, lng: 20.1570445 },
    { lat: 48.9094032, lng: 20.1568945 },
    { lat: 48.9094691, lng: 20.1566839 },
    { lat: 48.9095667, lng: 20.1566469 },
    { lat: 48.9099628, lng: 20.156027 },
    { lat: 48.9101518, lng: 20.1559926 },
    { lat: 48.9103254, lng: 20.1558838 },
    { lat: 48.9103828, lng: 20.155775 },
    { lat: 48.9106035, lng: 20.1557689 },
    { lat: 48.9106914, lng: 20.1555194 },
    { lat: 48.9108386, lng: 20.1554553 },
    { lat: 48.9109367, lng: 20.1552077 },
    { lat: 48.9111951, lng: 20.1548307 },
    { lat: 48.9114599, lng: 20.1545509 },
    { lat: 48.9115229, lng: 20.1543266 },
    { lat: 48.9117342, lng: 20.1539848 },
    { lat: 48.9119172, lng: 20.1539116 },
    { lat: 48.9121391, lng: 20.1537199 },
    { lat: 48.9128053, lng: 20.1538407 },
    { lat: 48.9129903, lng: 20.1537952 },
    { lat: 48.9132926, lng: 20.1535541 },
    { lat: 48.9136366, lng: 20.1534578 },
    { lat: 48.913665, lng: 20.1534064 },
    { lat: 48.9136393, lng: 20.1533247 },
    { lat: 48.913701, lng: 20.1532645 },
    { lat: 48.9140293, lng: 20.1533934 },
    { lat: 48.9141746, lng: 20.1533377 },
    { lat: 48.9144105, lng: 20.1530146 },
    { lat: 48.9145375, lng: 20.1530627 },
    { lat: 48.9146354, lng: 20.1529791 },
    { lat: 48.9147895, lng: 20.1529723 },
    { lat: 48.9148914, lng: 20.1530431 },
    { lat: 48.9151586, lng: 20.152715 },
    { lat: 48.9152154, lng: 20.1528075 },
    { lat: 48.9154443, lng: 20.1528417 },
    { lat: 48.9155624, lng: 20.15309 },
    { lat: 48.9158097, lng: 20.1531662 },
    { lat: 48.9161293, lng: 20.153466 },
    { lat: 48.916626, lng: 20.1542786 },
    { lat: 48.9166782, lng: 20.1547593 },
    { lat: 48.9169635, lng: 20.1550795 },
    { lat: 48.9176239, lng: 20.1554519 },
    { lat: 48.9178672, lng: 20.1553169 },
    { lat: 48.9180907, lng: 20.1553276 },
    { lat: 48.9181718, lng: 20.1553545 },
    { lat: 48.918398, lng: 20.1556275 },
    { lat: 48.9187944, lng: 20.1556849 },
    { lat: 48.9190831, lng: 20.1556112 },
    { lat: 48.9194484, lng: 20.1553109 },
    { lat: 48.9197398, lng: 20.1551915 },
    { lat: 48.9200122, lng: 20.1549646 },
    { lat: 48.9202232, lng: 20.1552824 },
    { lat: 48.9204364, lng: 20.1552952 },
    { lat: 48.9208955, lng: 20.1556741 },
    { lat: 48.9210072, lng: 20.1556809 },
    { lat: 48.9211036, lng: 20.1558059 },
    { lat: 48.9212365, lng: 20.1558362 },
    { lat: 48.9213954, lng: 20.1557857 },
    { lat: 48.9217423, lng: 20.1552224 },
    { lat: 48.9218846, lng: 20.155177 },
    { lat: 48.9221004, lng: 20.1549743 },
    { lat: 48.922248, lng: 20.1549467 },
    { lat: 48.922304, lng: 20.1548254 },
    { lat: 48.9223747, lng: 20.1547927 },
    { lat: 48.9223952, lng: 20.1546286 },
    { lat: 48.922581, lng: 20.1546003 },
    { lat: 48.9227922, lng: 20.1543088 },
    { lat: 48.922777, lng: 20.1539901 },
    { lat: 48.9228164, lng: 20.1537375 },
    { lat: 48.9229621, lng: 20.1535761 },
    { lat: 48.923062, lng: 20.1532904 },
    { lat: 48.9231334, lng: 20.1528844 },
    { lat: 48.9235474, lng: 20.152282 },
    { lat: 48.9240218, lng: 20.1518436 },
    { lat: 48.9240847, lng: 20.1511971 },
    { lat: 48.9239653, lng: 20.1505256 },
    { lat: 48.9239907, lng: 20.1503989 },
    { lat: 48.9239078, lng: 20.1502165 },
    { lat: 48.9239928, lng: 20.1494214 },
    { lat: 48.9241066, lng: 20.1490305 },
    { lat: 48.9241323, lng: 20.1487215 },
    { lat: 48.9243939, lng: 20.1479466 },
    { lat: 48.9244594, lng: 20.147505 },
    { lat: 48.9244386, lng: 20.1468193 },
    { lat: 48.9244817, lng: 20.1465779 },
    { lat: 48.9245805, lng: 20.146458 },
    { lat: 48.9247397, lng: 20.1456897 },
    { lat: 48.9251739, lng: 20.1450588 },
    { lat: 48.92538, lng: 20.1446152 },
    { lat: 48.9253263, lng: 20.1440619 },
    { lat: 48.9251686, lng: 20.1435064 },
    { lat: 48.9251254, lng: 20.1431909 },
    { lat: 48.9252059, lng: 20.1430925 },
    { lat: 48.9250971, lng: 20.1427709 },
    { lat: 48.9253588, lng: 20.1421675 },
    { lat: 48.9252196, lng: 20.1419537 },
    { lat: 48.9253865, lng: 20.1414289 },
    { lat: 48.9254586, lng: 20.1408177 },
    { lat: 48.9257945, lng: 20.1403237 },
    { lat: 48.925808, lng: 20.1401555 },
    { lat: 48.9259514, lng: 20.1399441 },
    { lat: 48.9261176, lng: 20.1392611 },
    { lat: 48.9262779, lng: 20.139018 },
    { lat: 48.9263967, lng: 20.138618 },
    { lat: 48.926463, lng: 20.1384997 },
    { lat: 48.9266745, lng: 20.1384958 },
    { lat: 48.9268548, lng: 20.1380513 },
    { lat: 48.9269344, lng: 20.1380121 },
    { lat: 48.927087, lng: 20.1376948 },
    { lat: 48.9271224, lng: 20.1373749 },
    { lat: 48.9272825, lng: 20.1373475 },
    { lat: 48.9275013, lng: 20.1362673 },
    { lat: 48.9278162, lng: 20.1356757 },
    { lat: 48.9278314, lng: 20.1351578 },
    { lat: 48.9282107, lng: 20.1344308 },
    { lat: 48.9283943, lng: 20.1336254 },
    { lat: 48.9287712, lng: 20.1331124 },
    { lat: 48.929143, lng: 20.1323389 },
    { lat: 48.9294268, lng: 20.1319159 },
    { lat: 48.9295715, lng: 20.1304851 },
    { lat: 48.9294547, lng: 20.1300483 },
    { lat: 48.9294871, lng: 20.1294016 },
    { lat: 48.9296234, lng: 20.1289347 },
    { lat: 48.9297616, lng: 20.1287854 },
    { lat: 48.9301661, lng: 20.1287036 },
    { lat: 48.9302969, lng: 20.1283207 },
    { lat: 48.9304252, lng: 20.1281253 },
    { lat: 48.930511, lng: 20.1276001 },
    { lat: 48.9309346, lng: 20.1271561 },
    { lat: 48.9314345, lng: 20.1264579 },
    { lat: 48.9318266, lng: 20.1261298 },
    { lat: 48.9322556, lng: 20.1262086 },
    { lat: 48.9329069, lng: 20.125637 },
    { lat: 48.9330504, lng: 20.1252074 },
    { lat: 48.933154, lng: 20.1251189 },
    { lat: 48.9333409, lng: 20.1249797 },
    { lat: 48.9341068, lng: 20.1246775 },
    { lat: 48.9342298, lng: 20.1245166 },
    { lat: 48.9344286, lng: 20.1240284 },
    { lat: 48.9345788, lng: 20.1238457 },
    { lat: 48.9349876, lng: 20.1235954 },
    { lat: 48.9353571, lng: 20.1234613 },
    { lat: 48.9357927, lng: 20.1227321 },
    { lat: 48.9360686, lng: 20.1227038 },
    { lat: 48.9362103, lng: 20.1225025 },
    { lat: 48.9363455, lng: 20.1219479 },
    { lat: 48.9366881, lng: 20.1215383 },
    { lat: 48.9369426, lng: 20.1208958 },
    { lat: 48.9372577, lng: 20.12078 },
    { lat: 48.9374893, lng: 20.1203815 },
    { lat: 48.9375889, lng: 20.1203407 },
    { lat: 48.9378278, lng: 20.1203424 },
    { lat: 48.9382105, lng: 20.1206408 },
    { lat: 48.938459, lng: 20.1207394 },
    { lat: 48.9398711, lng: 20.1201252 },
    { lat: 48.9404326, lng: 20.1200046 },
    { lat: 48.9407538, lng: 20.1202315 },
    { lat: 48.9410317, lng: 20.1203337 },
    { lat: 48.9414339, lng: 20.1202008 },
    { lat: 48.9417944, lng: 20.120285 },
    { lat: 48.9423127, lng: 20.119985 },
    { lat: 48.9428815, lng: 20.1199039 },
    { lat: 48.9436537, lng: 20.1193688 },
    { lat: 48.9439918, lng: 20.1192906 },
    { lat: 48.9442265, lng: 20.1193278 },
    { lat: 48.9442985, lng: 20.1194095 },
    { lat: 48.9445035, lng: 20.1198271 },
    { lat: 48.9445738, lng: 20.1202576 },
    { lat: 48.9448074, lng: 20.120598 },
    { lat: 48.9450719, lng: 20.1206801 },
    { lat: 48.9456724, lng: 20.1204903 },
    { lat: 48.9460042, lng: 20.1207559 },
    { lat: 48.9462162, lng: 20.1206533 },
    { lat: 48.9466379, lng: 20.1203051 },
    { lat: 48.9473398, lng: 20.1201427 },
    { lat: 48.9480097, lng: 20.1196393 },
    { lat: 48.9485567, lng: 20.1197935 },
    { lat: 48.9487919, lng: 20.1197908 },
    { lat: 48.9490768, lng: 20.119566 },
    { lat: 48.9494994, lng: 20.1194079 },
    { lat: 48.9497797, lng: 20.1187664 },
    { lat: 48.9507635, lng: 20.1174776 },
    { lat: 48.9509279, lng: 20.1170877 },
    { lat: 48.950952, lng: 20.1165415 },
    { lat: 48.951266, lng: 20.1161205 },
    { lat: 48.9514071, lng: 20.1157407 },
    { lat: 48.951598, lng: 20.1155078 },
    { lat: 48.9519835, lng: 20.1152381 },
    { lat: 48.9521691, lng: 20.1147581 },
    { lat: 48.9528078, lng: 20.1142662 },
    { lat: 48.9528879, lng: 20.1141565 },
    { lat: 48.9529775, lng: 20.1137532 },
    { lat: 48.9530556, lng: 20.1135976 },
    { lat: 48.9533393, lng: 20.1134864 },
    { lat: 48.953721, lng: 20.113565 },
    { lat: 48.953802, lng: 20.1134789 },
    { lat: 48.9538463, lng: 20.1133203 },
    { lat: 48.9537752, lng: 20.1128489 },
    { lat: 48.9538209, lng: 20.1126418 },
    { lat: 48.953931, lng: 20.112333 },
    { lat: 48.9541315, lng: 20.1120268 },
    { lat: 48.95454, lng: 20.1118818 },
    { lat: 48.9546711, lng: 20.1119727 },
    { lat: 48.9548125, lng: 20.112239 },
    { lat: 48.954936, lng: 20.1122005 },
    { lat: 48.955125, lng: 20.1116725 },
    { lat: 48.955197, lng: 20.1110863 },
    { lat: 48.9555928, lng: 20.110378 },
    { lat: 48.9557443, lng: 20.1098373 },
    { lat: 48.9558946, lng: 20.1095992 },
    { lat: 48.9562623, lng: 20.1095067 },
    { lat: 48.9564321, lng: 20.1093571 },
    { lat: 48.9564527, lng: 20.1091147 },
    { lat: 48.9563983, lng: 20.1087475 },
    { lat: 48.9564233, lng: 20.1085609 },
    { lat: 48.9564994, lng: 20.1084348 },
    { lat: 48.957059, lng: 20.1081874 },
    { lat: 48.9571519, lng: 20.1080045 },
    { lat: 48.9571592, lng: 20.1077157 },
    { lat: 48.9570283, lng: 20.107294 },
    { lat: 48.9570963, lng: 20.107055 },
    { lat: 48.9573572, lng: 20.1068506 },
    { lat: 48.9574989, lng: 20.1068173 },
    { lat: 48.958096, lng: 20.1069326 },
    { lat: 48.959317, lng: 20.1066019 },
    { lat: 48.9600525, lng: 20.1065756 },
    { lat: 48.9603436, lng: 20.106449 },
    { lat: 48.9607241, lng: 20.105975 },
    { lat: 48.9609263, lng: 20.1061012 },
    { lat: 48.9612686, lng: 20.1067644 },
    { lat: 48.9615596, lng: 20.1068059 },
    { lat: 48.9631172, lng: 20.1058074 },
    { lat: 48.9632451, lng: 20.105656 },
    { lat: 48.963516, lng: 20.1051457 },
    { lat: 48.9639602, lng: 20.1047387 },
    { lat: 48.9642054, lng: 20.104677 },
    { lat: 48.9644234, lng: 20.1047214 },
    { lat: 48.9648604, lng: 20.1049686 },
    { lat: 48.9655672, lng: 20.1055113 },
    { lat: 48.9656667, lng: 20.1058231 },
    { lat: 48.965992, lng: 20.1062887 },
    { lat: 48.966445, lng: 20.1070749 },
    { lat: 48.9665956, lng: 20.107483 },
    { lat: 48.9668982, lng: 20.1079421 },
    { lat: 48.9671185, lng: 20.1085946 },
    { lat: 48.9671986, lng: 20.1087126 },
    { lat: 48.9676339, lng: 20.1090883 },
    { lat: 48.9680716, lng: 20.1093806 },
    { lat: 48.9683705, lng: 20.1095006 },
    { lat: 48.9689327, lng: 20.1093963 },
    { lat: 48.9694822, lng: 20.109063 },
    { lat: 48.969821, lng: 20.1087534 },
    { lat: 48.9703898, lng: 20.1080036 },
    { lat: 48.9713509, lng: 20.1069903 },
    { lat: 48.97239, lng: 20.1065093 },
    { lat: 48.972779, lng: 20.1063835 },
    { lat: 48.9730927, lng: 20.1063956 },
    { lat: 48.9738124, lng: 20.1060915 },
    { lat: 48.9741198, lng: 20.1058676 },
    { lat: 48.9742657, lng: 20.1056668 },
    { lat: 48.9748434, lng: 20.1045557 },
    { lat: 48.975422, lng: 20.1037424 },
    { lat: 48.9755456, lng: 20.103624 },
    { lat: 48.9759809, lng: 20.1034472 },
    { lat: 48.9766766, lng: 20.103303 },
    { lat: 48.9772488, lng: 20.1025283 },
    { lat: 48.9775499, lng: 20.101484 },
    { lat: 48.978717, lng: 20.0983963 },
    { lat: 48.9790606, lng: 20.098344 },
    { lat: 48.9794369, lng: 20.0985363 },
    { lat: 48.9795131, lng: 20.0984205 },
    { lat: 48.9796364, lng: 20.0979879 },
    { lat: 48.9797122, lng: 20.0970565 },
    { lat: 48.9798095, lng: 20.0967619 },
    { lat: 48.9800503, lng: 20.0964564 },
    { lat: 48.980338, lng: 20.0964111 },
    { lat: 48.9806235, lng: 20.0962067 },
    { lat: 48.9809216, lng: 20.0957782 },
    { lat: 48.9811046, lng: 20.0953526 },
    { lat: 48.9814454, lng: 20.0938472 },
    { lat: 48.9819799, lng: 20.0931228 },
    { lat: 48.9821816, lng: 20.0929023 },
    { lat: 48.9826754, lng: 20.0925281 },
    { lat: 48.9829781, lng: 20.0919399 },
    { lat: 48.9833205, lng: 20.0917184 },
    { lat: 48.9838524, lng: 20.0915335 },
    { lat: 48.9840525, lng: 20.0913877 },
    { lat: 48.9844493, lng: 20.090654 },
    { lat: 48.9851826, lng: 20.089852 },
    { lat: 48.9859169, lng: 20.0895522 },
    { lat: 48.9866395, lng: 20.0888218 },
    { lat: 48.9869195, lng: 20.0886158 },
    { lat: 48.9870327, lng: 20.0883025 },
    { lat: 48.9875336, lng: 20.0877945 },
    { lat: 48.9877942, lng: 20.0870925 },
    { lat: 48.9879989, lng: 20.0867516 },
    { lat: 48.9886078, lng: 20.0863833 },
    { lat: 48.9890313, lng: 20.0864151 },
    { lat: 48.9892231, lng: 20.0863658 },
    { lat: 48.9899943, lng: 20.0862705 },
    { lat: 48.990415, lng: 20.086403 },
    { lat: 48.9907818, lng: 20.0866112 },
    { lat: 48.9912797, lng: 20.0856494 },
    { lat: 48.9926795, lng: 20.0846509 },
    { lat: 48.9927657, lng: 20.0843918 },
    { lat: 48.993115, lng: 20.0838732 },
    { lat: 48.9935805, lng: 20.08391 },
    { lat: 48.9940171, lng: 20.0836508 },
    { lat: 48.9942723, lng: 20.0831131 },
    { lat: 48.9942809, lng: 20.0829369 },
    { lat: 48.9944308, lng: 20.0823891 },
    { lat: 48.9947188, lng: 20.0816414 },
    { lat: 48.9948587, lng: 20.0810106 },
    { lat: 48.9951954, lng: 20.0800732 },
    { lat: 48.9954352, lng: 20.0795477 },
    { lat: 48.9958232, lng: 20.0790111 },
    { lat: 48.9964577, lng: 20.0778074 },
    { lat: 48.996598, lng: 20.0776611 },
    { lat: 48.9971954, lng: 20.077426 },
    { lat: 48.9972387, lng: 20.077215 },
    { lat: 48.9974866, lng: 20.0767493 },
    { lat: 48.9977707, lng: 20.0765927 },
    { lat: 48.9982005, lng: 20.0764724 },
    { lat: 48.9984459, lng: 20.0762746 },
    { lat: 48.998862, lng: 20.0757101 },
    { lat: 48.999646, lng: 20.0748312 },
    { lat: 48.9999197, lng: 20.074306 },
    { lat: 49.0003889, lng: 20.0739657 },
    { lat: 49.0005287, lng: 20.0738879 },
    { lat: 49.0006658, lng: 20.073852 },
    { lat: 49.0012402, lng: 20.0734412 },
    { lat: 49.0014619, lng: 20.0731612 },
    { lat: 49.0018038, lng: 20.0723225 },
    { lat: 49.0018149, lng: 20.0721787 },
    { lat: 49.0020146, lng: 20.0716393 },
    { lat: 49.002475, lng: 20.0708063 },
    { lat: 49.0025789, lng: 20.0703753 },
    { lat: 49.0025624, lng: 20.0692297 },
    { lat: 49.0027489, lng: 20.0681488 },
    { lat: 49.0027726, lng: 20.0677369 },
    { lat: 49.0027566, lng: 20.0673754 },
    { lat: 49.0026801, lng: 20.0669934 },
    { lat: 49.0027017, lng: 20.0668013 },
    { lat: 49.0028604, lng: 20.0664171 },
    { lat: 49.0030978, lng: 20.0661913 },
    { lat: 49.0033908, lng: 20.065671 },
    { lat: 49.0037262, lng: 20.0647632 },
    { lat: 49.0040638, lng: 20.0642091 },
    { lat: 49.0043577, lng: 20.0635705 },
    { lat: 49.0044706, lng: 20.0631914 },
    { lat: 49.004639, lng: 20.0621646 },
    { lat: 49.0048063, lng: 20.0616573 },
    { lat: 49.0049127, lng: 20.0615083 },
    { lat: 49.005109, lng: 20.0614313 },
    { lat: 49.0054921, lng: 20.0617085 },
    { lat: 49.0058333, lng: 20.0617066 },
    { lat: 49.0068268, lng: 20.0608591 },
    { lat: 49.0069447, lng: 20.0607424 },
    { lat: 49.0074243, lng: 20.0594485 },
    { lat: 49.0075282, lng: 20.0592891 },
    { lat: 49.0078136, lng: 20.0591354 },
    { lat: 49.00801, lng: 20.0586805 },
    { lat: 49.008461, lng: 20.0583371 },
    { lat: 49.008576, lng: 20.0581199 },
    { lat: 49.0086493, lng: 20.0577879 },
    { lat: 49.0086212, lng: 20.0572994 },
    { lat: 49.008483, lng: 20.0565946 },
    { lat: 49.0084742, lng: 20.0562948 },
    { lat: 49.0084986, lng: 20.0559865 },
    { lat: 49.008761, lng: 20.055083 },
    { lat: 49.0087643, lng: 20.0547232 },
    { lat: 49.0086102, lng: 20.0535496 },
    { lat: 49.0086744, lng: 20.0533035 },
    { lat: 49.0087833, lng: 20.0531866 },
    { lat: 49.0089376, lng: 20.0531834 },
    { lat: 49.0091026, lng: 20.0531154 },
    { lat: 49.0093464, lng: 20.0529911 },
    { lat: 49.0095261, lng: 20.0528151 },
    { lat: 49.0095968, lng: 20.0525881 },
    { lat: 49.0095784, lng: 20.0521406 },
    { lat: 49.0096585, lng: 20.0518736 },
    { lat: 49.0105856, lng: 20.0514659 },
    { lat: 49.0109915, lng: 20.0511999 },
    { lat: 49.0115407, lng: 20.0509435 },
    { lat: 49.0117679, lng: 20.0506647 },
    { lat: 49.011877, lng: 20.0503569 },
    { lat: 49.0119423, lng: 20.0499478 },
    { lat: 49.0119578, lng: 20.0488735 },
    { lat: 49.0120457, lng: 20.0486797 },
    { lat: 49.0125605, lng: 20.0484628 },
    { lat: 49.0130426, lng: 20.0479463 },
    { lat: 49.0134028, lng: 20.0476663 },
    { lat: 49.0137735, lng: 20.047697 },
    { lat: 49.0140254, lng: 20.0476071 },
    { lat: 49.0141675, lng: 20.0472538 },
    { lat: 49.0141188, lng: 20.0468666 },
    { lat: 49.0136811, lng: 20.0459739 },
    { lat: 49.0136765, lng: 20.0455574 },
    { lat: 49.0137332, lng: 20.0452958 },
    { lat: 49.0138448, lng: 20.0450254 },
    { lat: 49.0140329, lng: 20.0447513 },
    { lat: 49.0142619, lng: 20.0445979 },
    { lat: 49.0151131, lng: 20.0443723 },
    { lat: 49.0153545, lng: 20.0441868 },
    { lat: 49.0154878, lng: 20.04398 },
    { lat: 49.0155109, lng: 20.0436344 },
    { lat: 49.0154718, lng: 20.0433446 },
    { lat: 49.015141, lng: 20.0420912 },
    { lat: 49.015033, lng: 20.041522 },
    { lat: 49.0150157, lng: 20.0409908 },
    { lat: 49.0153311, lng: 20.0399809 },
    { lat: 49.0156119, lng: 20.0396075 },
    { lat: 49.0161689, lng: 20.0394579 },
    { lat: 49.0163386, lng: 20.0392871 },
    { lat: 49.016443, lng: 20.0389209 },
    { lat: 49.0163857, lng: 20.0385958 },
    { lat: 49.0161591, lng: 20.0383731 },
    { lat: 49.0156453, lng: 20.0381787 },
    { lat: 49.015598, lng: 20.0379575 },
    { lat: 49.0156131, lng: 20.0373454 },
    { lat: 49.0159921, lng: 20.0365077 },
    { lat: 49.0164939, lng: 20.0357885 },
    { lat: 49.0169028, lng: 20.03546 },
    { lat: 49.0170395, lng: 20.0352252 },
    { lat: 49.0169851, lng: 20.0346451 },
    { lat: 49.0168738, lng: 20.0341548 },
    { lat: 49.0169249, lng: 20.0336501 },
    { lat: 49.0168552, lng: 20.0324751 },
    { lat: 49.0169407, lng: 20.0315735 },
    { lat: 49.0171878, lng: 20.0304935 },
    { lat: 49.0171173, lng: 20.0300704 },
    { lat: 49.0165801, lng: 20.0288991 },
    { lat: 49.0164173, lng: 20.0282261 },
    { lat: 49.016323, lng: 20.0267083 },
    { lat: 49.0163524, lng: 20.0261417 },
    { lat: 49.0163088, lng: 20.0256572 },
    { lat: 49.0163343, lng: 20.025348 },
    { lat: 49.0164763, lng: 20.0248044 },
    { lat: 49.0164348, lng: 20.0245077 },
    { lat: 49.0162711, lng: 20.023979 },
    { lat: 49.0162858, lng: 20.0237655 },
    { lat: 49.0164539, lng: 20.0231441 },
    { lat: 49.0163951, lng: 20.0228914 },
    { lat: 49.0161829, lng: 20.022475 },
    { lat: 49.0160395, lng: 20.0223279 },
    { lat: 49.0157427, lng: 20.0216641 },
    { lat: 49.0153238, lng: 20.0205452 },
    { lat: 49.0152847, lng: 20.0201212 },
    { lat: 49.0154682, lng: 20.0191356 },
    { lat: 49.0155156, lng: 20.0183538 },
    { lat: 49.0156225, lng: 20.0179424 },
    { lat: 49.0159042, lng: 20.0174551 },
    { lat: 49.0159849, lng: 20.0171488 },
    { lat: 49.0159848, lng: 20.0168431 },
    { lat: 49.015853, lng: 20.0164352 },
    { lat: 49.015843, lng: 20.0162234 },
    { lat: 49.015977, lng: 20.015293 },
    { lat: 49.015943, lng: 20.015054 },
    { lat: 49.015939, lng: 20.015023 },
    { lat: 49.015892, lng: 20.014766 },
    { lat: 49.015841, lng: 20.01454 },
    { lat: 49.015822, lng: 20.013894 },
    { lat: 49.015822, lng: 20.013642 },
    { lat: 49.015872, lng: 20.013204 },
    { lat: 49.015905, lng: 20.012593 },
    { lat: 49.015903, lng: 20.012299 },
    { lat: 49.015837, lng: 20.011915 },
    { lat: 49.015812, lng: 20.011833 },
    { lat: 49.015645, lng: 20.011463 },
    { lat: 49.015453, lng: 20.011301 },
    { lat: 49.015321, lng: 20.011075 },
    { lat: 49.015219, lng: 20.010943 },
    { lat: 49.015135, lng: 20.010759 },
    { lat: 49.014961, lng: 20.01041 },
    { lat: 49.014859, lng: 20.010192 },
    { lat: 49.014843, lng: 20.009863 },
    { lat: 49.014804, lng: 20.009732 },
    { lat: 49.014779, lng: 20.00959 },
    { lat: 49.014806, lng: 20.00934 },
    { lat: 49.014822, lng: 20.008964 },
    { lat: 49.01486, lng: 20.008784 },
    { lat: 49.014969, lng: 20.008149 },
    { lat: 49.015081, lng: 20.007702 },
    { lat: 49.015104, lng: 20.007455 },
    { lat: 49.015094, lng: 20.007128 },
    { lat: 49.015049, lng: 20.006861 },
    { lat: 49.015198, lng: 20.006348 },
    { lat: 49.015354, lng: 20.006033 },
    { lat: 49.01539, lng: 20.005917 },
    { lat: 49.015411, lng: 20.005824 },
    { lat: 49.015448, lng: 20.005801 },
    { lat: 49.015448, lng: 20.0058 },
    { lat: 49.01547, lng: 20.005787 },
    { lat: 49.015552, lng: 20.0057 },
    { lat: 49.015609, lng: 20.005632 },
    { lat: 49.015696, lng: 20.005426 },
    { lat: 49.015736, lng: 20.005318 },
    { lat: 49.015822, lng: 20.005085 },
    { lat: 49.015906, lng: 20.004801 },
    { lat: 49.01596, lng: 20.004614 },
    { lat: 49.016034, lng: 20.004418 },
    { lat: 49.016111, lng: 20.004209 },
    { lat: 49.016158, lng: 20.004031 },
    { lat: 49.016195, lng: 20.003822 },
    { lat: 49.016202, lng: 20.003718 },
    { lat: 49.016197, lng: 20.003671 },
    { lat: 49.016196, lng: 20.003668 },
    { lat: 49.016188, lng: 20.003599 },
    { lat: 49.01616, lng: 20.00348 },
    { lat: 49.016128, lng: 20.003378 },
    { lat: 49.016107, lng: 20.003313 },
    { lat: 49.016106, lng: 20.003311 },
    { lat: 49.016096, lng: 20.003278 },
    { lat: 49.016093, lng: 20.00322 },
    { lat: 49.016097, lng: 20.003199 },
    { lat: 49.016097, lng: 20.003197 },
    { lat: 49.016149, lng: 20.002925 },
    { lat: 49.016239, lng: 20.002597 },
    { lat: 49.016279, lng: 20.002447 },
    { lat: 49.01628, lng: 20.002445 },
    { lat: 49.016391, lng: 20.002026 },
    { lat: 49.016461, lng: 20.001697 },
    { lat: 49.016495, lng: 20.001493 },
    { lat: 49.016507, lng: 20.001341 },
    { lat: 49.016505, lng: 20.001193 },
    { lat: 49.01649, lng: 20.001098 },
    { lat: 49.016439, lng: 20.000909 },
    { lat: 49.016405, lng: 20.000829 },
    { lat: 49.016374, lng: 20.000764 },
    { lat: 49.016143, lng: 20.000493 },
    { lat: 49.015996, lng: 20.000131 },
    { lat: 49.015915, lng: 19.999803 },
    { lat: 49.015805, lng: 19.999357 },
    { lat: 49.015626, lng: 19.998766 },
    { lat: 49.01556, lng: 19.998585 },
    { lat: 49.015498, lng: 19.998472 },
    { lat: 49.015434, lng: 19.998386 },
    { lat: 49.015362, lng: 19.99831 },
    { lat: 49.015277, lng: 19.998242 },
    { lat: 49.015161, lng: 19.998147 },
    { lat: 49.015093, lng: 19.998068 },
    { lat: 49.015014, lng: 19.997949 },
    { lat: 49.014901, lng: 19.997667 },
    { lat: 49.014842, lng: 19.997478 },
    { lat: 49.014811, lng: 19.997318 },
    { lat: 49.014791, lng: 19.997144 },
    { lat: 49.014793, lng: 19.996856 },
    { lat: 49.014909, lng: 19.995966 },
    { lat: 49.014919, lng: 19.995792 },
    { lat: 49.014845, lng: 19.995357 },
    { lat: 49.0147, lng: 19.995043 },
    { lat: 49.014647, lng: 19.99474 },
    { lat: 49.014629, lng: 19.994567 },
    { lat: 49.014646, lng: 19.994431 },
    { lat: 49.01465, lng: 19.994277 },
    { lat: 49.014638, lng: 19.994129 },
    { lat: 49.014568, lng: 19.993766 },
    { lat: 49.014567, lng: 19.99376 },
    { lat: 49.01454, lng: 19.99362 },
    { lat: 49.01454, lng: 19.993617 },
    { lat: 49.014502, lng: 19.993423 },
    { lat: 49.014487, lng: 19.993276 },
    { lat: 49.014503, lng: 19.993205 },
    { lat: 49.014503, lng: 19.993204 },
    { lat: 49.014531, lng: 19.993084 },
    { lat: 49.014588, lng: 19.992942 },
    { lat: 49.014693, lng: 19.992736 },
    { lat: 49.014695, lng: 19.992735 },
    { lat: 49.014762, lng: 19.992603 },
    { lat: 49.014926, lng: 19.992179 },
    { lat: 49.014983, lng: 19.992005 },
    { lat: 49.015012, lng: 19.991805 },
    { lat: 49.015026, lng: 19.991601 },
    { lat: 49.015023, lng: 19.99149 },
    { lat: 49.014957, lng: 19.991073 },
    { lat: 49.014914, lng: 19.99087 },
    { lat: 49.014913, lng: 19.990869 },
    { lat: 49.014842, lng: 19.990541 },
    { lat: 49.014728, lng: 19.990199 },
    { lat: 49.014616, lng: 19.989853 },
    { lat: 49.014574, lng: 19.989721 },
    { lat: 49.014587, lng: 19.988952 },
    { lat: 49.014596, lng: 19.988844 },
    { lat: 49.014573, lng: 19.988629 },
    { lat: 49.014573, lng: 19.988626 },
    { lat: 49.014529, lng: 19.988202 },
    { lat: 49.014519, lng: 19.98811 },
    { lat: 49.014417, lng: 19.987738 },
    { lat: 49.01438, lng: 19.98759 },
    { lat: 49.014321, lng: 19.987256 },
    { lat: 49.014192, lng: 19.986656 },
    { lat: 49.01415, lng: 19.986528 },
    { lat: 49.01411, lng: 19.986426 },
    { lat: 49.01411, lng: 19.986424 },
    { lat: 49.014052, lng: 19.98628 },
    { lat: 49.013956, lng: 19.986097 },
    { lat: 49.013856, lng: 19.985985 },
    { lat: 49.013244, lng: 19.985716 },
    { lat: 49.013234, lng: 19.985596 },
    { lat: 49.0132, lng: 19.98548 },
    { lat: 49.013163, lng: 19.985372 },
    { lat: 49.013093, lng: 19.984883 },
    { lat: 49.013039, lng: 19.9846 },
    { lat: 49.012965, lng: 19.98428 },
    { lat: 49.012718, lng: 19.983478 },
    { lat: 49.012667, lng: 19.983353 },
    { lat: 49.012665, lng: 19.983346 },
    { lat: 49.012563, lng: 19.983095 },
    { lat: 49.01238, lng: 19.982537 },
    { lat: 49.012271, lng: 19.982042 },
    { lat: 49.012256, lng: 19.981902 },
    { lat: 49.012263, lng: 19.981777 },
    { lat: 49.012275, lng: 19.98155 },
    { lat: 49.012273, lng: 19.981521 },
    { lat: 49.012266, lng: 19.981453 },
    { lat: 49.012348, lng: 19.98135 },
    { lat: 49.012349, lng: 19.981349 },
    { lat: 49.012361, lng: 19.981334 },
    { lat: 49.012414, lng: 19.981275 },
    { lat: 49.012506, lng: 19.981219 },
    { lat: 49.012861, lng: 19.981096 },
    { lat: 49.01311, lng: 19.980929 },
    { lat: 49.013226, lng: 19.980833 },
    { lat: 49.013335, lng: 19.980763 },
    { lat: 49.013454, lng: 19.980725 },
    { lat: 49.013583, lng: 19.980657 },
    { lat: 49.013584, lng: 19.980657 },
    { lat: 49.013673, lng: 19.98061 },
    { lat: 49.01378, lng: 19.980528 },
    { lat: 49.013781, lng: 19.980528 },
    { lat: 49.013796, lng: 19.980516 },
    { lat: 49.013935, lng: 19.98031 },
    { lat: 49.014095, lng: 19.980081 },
    { lat: 49.014197, lng: 19.979954 },
    { lat: 49.014353, lng: 19.979793 },
    { lat: 49.014596, lng: 19.979586 },
    { lat: 49.014907, lng: 19.979332 },
    { lat: 49.015032, lng: 19.979253 },
    { lat: 49.015289, lng: 19.979127 },
    { lat: 49.015464, lng: 19.979031 },
    { lat: 49.015548, lng: 19.978895 },
    { lat: 49.015664, lng: 19.978633 },
    { lat: 49.015752, lng: 19.978409 },
    { lat: 49.015829, lng: 19.978154 },
    { lat: 49.015956, lng: 19.977607 },
    { lat: 49.01599, lng: 19.977204 },
    { lat: 49.016001, lng: 19.976823 },
    { lat: 49.015925, lng: 19.976153 },
    { lat: 49.01591, lng: 19.975938 },
    { lat: 49.016009, lng: 19.975417 },
    { lat: 49.015974, lng: 19.974946 },
    { lat: 49.015912, lng: 19.974562 },
    { lat: 49.01587, lng: 19.974321 },
    { lat: 49.015866, lng: 19.974097 },
    { lat: 49.015891, lng: 19.973816 },
    { lat: 49.015937, lng: 19.973591 },
    { lat: 49.015956, lng: 19.97353 },
    { lat: 49.015956, lng: 19.973528 },
    { lat: 49.015999, lng: 19.97338 },
    { lat: 49.016033, lng: 19.97329 },
    { lat: 49.016069, lng: 19.973244 },
    { lat: 49.01607, lng: 19.973244 },
    { lat: 49.016125, lng: 19.973174 },
    { lat: 49.016179, lng: 19.973142 },
    { lat: 49.0162, lng: 19.97313 },
    { lat: 49.016202, lng: 19.97313 },
    { lat: 49.016292, lng: 19.973083 },
    { lat: 49.01652, lng: 19.973058 },
    { lat: 49.01662, lng: 19.973024 },
    { lat: 49.016692, lng: 19.972973 },
    { lat: 49.016725, lng: 19.972913 },
    { lat: 49.016782, lng: 19.972734 },
    { lat: 49.016824, lng: 19.97244 },
    { lat: 49.016812, lng: 19.972244 },
    { lat: 49.016756, lng: 19.97206 },
    { lat: 49.016702, lng: 19.971872 },
    { lat: 49.016697, lng: 19.971818 },
    { lat: 49.016647, lng: 19.971559 },
    { lat: 49.016617, lng: 19.971241 },
    { lat: 49.016594, lng: 19.971064 },
    { lat: 49.016542, lng: 19.970791 },
    { lat: 49.016525, lng: 19.970668 },
    { lat: 49.01651, lng: 19.970592 },
    { lat: 49.016509, lng: 19.970587 },
    { lat: 49.016506, lng: 19.970569 },
    { lat: 49.016505, lng: 19.970555 },
    { lat: 49.016505, lng: 19.970549 },
    { lat: 49.016502, lng: 19.970447 },
    { lat: 49.016513, lng: 19.970374 },
    { lat: 49.016582, lng: 19.969999 },
    { lat: 49.01657, lng: 19.968958 },
    { lat: 49.016525, lng: 19.968954 },
    { lat: 49.016489, lng: 19.968954 },
    { lat: 49.016042, lng: 19.969042 },
    { lat: 49.01587, lng: 19.969108 },
    { lat: 49.015825, lng: 19.969123 },
    { lat: 49.015783, lng: 19.969142 },
    { lat: 49.015585, lng: 19.969214 },
    { lat: 49.01531, lng: 19.969256 },
    { lat: 49.015074, lng: 19.969326 },
    { lat: 49.015011, lng: 19.969366 },
    { lat: 49.014536, lng: 19.968788 },
    { lat: 49.014424, lng: 19.968718 },
    { lat: 49.01414, lng: 19.968884 },
    { lat: 49.01376, lng: 19.968641 },
    { lat: 49.013586, lng: 19.968496 },
    { lat: 49.01332, lng: 19.968457 },
    { lat: 49.013362, lng: 19.968404 },
    { lat: 49.0134, lng: 19.968341 },
    { lat: 49.011905, lng: 19.967868 },
    { lat: 49.010181, lng: 19.965292 },
    { lat: 49.0099, lng: 19.964789 },
    { lat: 49.009877, lng: 19.964747 },
    { lat: 49.009776, lng: 19.964281 },
    { lat: 49.009745, lng: 19.963892 },
    { lat: 49.009738, lng: 19.96379 },
    { lat: 49.009527, lng: 19.963568 },
    { lat: 49.008929, lng: 19.963551 },
    { lat: 49.007446, lng: 19.964294 },
    { lat: 49.006117, lng: 19.96439 },
    { lat: 49.00575, lng: 19.965214 },
    { lat: 49.005221, lng: 19.96815 },
    { lat: 49.004883, lng: 19.970943 },
    { lat: 49.003968, lng: 19.971521 },
    { lat: 49.003047, lng: 19.972415 },
    { lat: 49.002285, lng: 19.974517 },
    { lat: 49.001425, lng: 19.975618 },
    { lat: 48.99971, lng: 19.976971 },
    { lat: 48.999529, lng: 19.979476 },
    { lat: 48.999659, lng: 19.980466 },
    { lat: 48.999749, lng: 19.98115 },
    { lat: 48.999761, lng: 19.981921 },
    { lat: 48.998703, lng: 19.982988 },
    { lat: 48.997371, lng: 19.9863 },
    { lat: 48.996826, lng: 19.986946 },
    { lat: 48.996503, lng: 19.988665 },
    { lat: 48.994273, lng: 19.990318 },
    { lat: 48.994149, lng: 19.99047 },
    { lat: 48.993652, lng: 19.991044 },
    { lat: 48.993149, lng: 19.992038 },
    { lat: 48.992687, lng: 19.99329 },
    { lat: 48.990904, lng: 19.994489 },
    { lat: 48.990189, lng: 19.99462 },
    { lat: 48.989429, lng: 19.994328 },
    { lat: 48.988567, lng: 19.994773 },
    { lat: 48.987994, lng: 19.995552 },
    { lat: 48.986618, lng: 19.998574 },
    { lat: 48.984674, lng: 20.00034 },
    { lat: 48.984172, lng: 20.001462 },
    { lat: 48.983546, lng: 20.002003 },
    { lat: 48.98256, lng: 20.003195 },
    { lat: 48.981211, lng: 20.004824 },
    { lat: 48.980147, lng: 20.005195 },
    { lat: 48.977663, lng: 20.005543 },
    { lat: 48.977283, lng: 20.005599 },
    { lat: 48.976058, lng: 20.006834 },
    { lat: 48.973617, lng: 20.009402 },
    { lat: 48.97218, lng: 20.010461 },
    { lat: 48.972798, lng: 20.012172 },
    { lat: 48.971698, lng: 20.016221 },
    { lat: 48.97163, lng: 20.016544 },
    { lat: 48.971545, lng: 20.016619 },
    { lat: 48.971547, lng: 20.016414 },
    { lat: 48.971468, lng: 20.016356 },
    { lat: 48.971413, lng: 20.016315 },
    { lat: 48.971374, lng: 20.016339 },
    { lat: 48.971144, lng: 20.016484 },
    { lat: 48.970629, lng: 20.017222 },
    { lat: 48.969843, lng: 20.01843 },
    { lat: 48.967455, lng: 20.021858 },
    { lat: 48.96705, lng: 20.022295 },
    { lat: 48.966838, lng: 20.022446 },
    { lat: 48.966639, lng: 20.022873 },
    { lat: 48.966623, lng: 20.023183 },
    { lat: 48.966472, lng: 20.022841 },
    { lat: 48.966453, lng: 20.022798 },
    { lat: 48.966349, lng: 20.022741 },
    { lat: 48.966019, lng: 20.022559 },
    { lat: 48.966037, lng: 20.023336 },
    { lat: 48.964441, lng: 20.024535 },
    { lat: 48.964364, lng: 20.024253 },
    { lat: 48.964305, lng: 20.024041 },
    { lat: 48.964189, lng: 20.024016 },
    { lat: 48.963971, lng: 20.023968 },
    { lat: 48.963848, lng: 20.023768 },
    { lat: 48.963583, lng: 20.023826 },
    { lat: 48.96354, lng: 20.024245 },
    { lat: 48.963305, lng: 20.024268 },
    { lat: 48.961071, lng: 20.023777 },
    { lat: 48.960986, lng: 20.023588 },
    { lat: 48.960974, lng: 20.023559 },
    { lat: 48.960665, lng: 20.022549 },
    { lat: 48.960483, lng: 20.022344 },
    { lat: 48.959934, lng: 20.021725 },
    { lat: 48.959755, lng: 20.02186 },
    { lat: 48.95959, lng: 20.021639 },
    { lat: 48.959266, lng: 20.021204 },
    { lat: 48.959942, lng: 20.020342 },
    { lat: 48.959721, lng: 20.020185 },
    { lat: 48.959311, lng: 20.019892 },
    { lat: 48.958805, lng: 20.019833 },
    { lat: 48.958677, lng: 20.019623 },
    { lat: 48.958604, lng: 20.019501 },
    { lat: 48.958487, lng: 20.019371 },
    { lat: 48.957815, lng: 20.018629 },
    { lat: 48.957719, lng: 20.018489 },
    { lat: 48.957589, lng: 20.018299 },
    { lat: 48.957513, lng: 20.018231 },
    { lat: 48.957103, lng: 20.017863 },
    { lat: 48.95697, lng: 20.01863 },
    { lat: 48.956827, lng: 20.019414 },
    { lat: 48.956705, lng: 20.020189 },
    { lat: 48.95652, lng: 20.021239 },
    { lat: 48.956317, lng: 20.022269 },
    { lat: 48.955976, lng: 20.023006 },
    { lat: 48.955275, lng: 20.023266 },
    { lat: 48.95474, lng: 20.022882 },
    { lat: 48.952852, lng: 20.023259 },
    { lat: 48.952787, lng: 20.02309 },
    { lat: 48.952567, lng: 20.022516 },
    { lat: 48.952459, lng: 20.022511 },
    { lat: 48.951353, lng: 20.022458 },
    { lat: 48.95083, lng: 20.022044 },
    { lat: 48.950832, lng: 20.022042 },
    { lat: 48.950953, lng: 20.021809 },
    { lat: 48.950584, lng: 20.021474 },
    { lat: 48.950622, lng: 20.021303 },
    { lat: 48.950448, lng: 20.020996 },
    { lat: 48.950622, lng: 20.020542 },
    { lat: 48.950087, lng: 20.019676 },
    { lat: 48.95002, lng: 20.019717 },
    { lat: 48.949842, lng: 20.019823 },
    { lat: 48.948774, lng: 20.020787 },
    { lat: 48.947329, lng: 20.024821 },
    { lat: 48.94641, lng: 20.024236 },
    { lat: 48.945704, lng: 20.024818 },
    { lat: 48.945261, lng: 20.026045 },
    { lat: 48.943955, lng: 20.027004 },
    { lat: 48.943833, lng: 20.026832 },
    { lat: 48.943039, lng: 20.025706 },
    { lat: 48.943034, lng: 20.025722 },
    { lat: 48.94281, lng: 20.026444 },
    { lat: 48.942559, lng: 20.027443 },
    { lat: 48.941488, lng: 20.028803 },
    { lat: 48.940743, lng: 20.028553 },
    { lat: 48.940777, lng: 20.028643 },
    { lat: 48.940815, lng: 20.028741 },
    { lat: 48.940887, lng: 20.028932 },
    { lat: 48.940699, lng: 20.029258 },
    { lat: 48.940671, lng: 20.029587 },
    { lat: 48.939465, lng: 20.030148 },
    { lat: 48.939303, lng: 20.029737 },
    { lat: 48.939245, lng: 20.029767 },
    { lat: 48.939246, lng: 20.030031 },
    { lat: 48.939185, lng: 20.030192 },
    { lat: 48.938745, lng: 20.030804 },
    { lat: 48.937207, lng: 20.033573 },
    { lat: 48.93656, lng: 20.0334 },
    { lat: 48.936523, lng: 20.033764 },
    { lat: 48.936085, lng: 20.033629 },
    { lat: 48.935965, lng: 20.033826 },
    { lat: 48.935769, lng: 20.033767 },
    { lat: 48.935298, lng: 20.033629 },
    { lat: 48.935152, lng: 20.033486 },
    { lat: 48.934912, lng: 20.033191 },
    { lat: 48.934603, lng: 20.033006 },
    { lat: 48.934528, lng: 20.03283 },
    { lat: 48.934467, lng: 20.032689 },
    { lat: 48.934447, lng: 20.032681 },
    { lat: 48.934211, lng: 20.0326 },
    { lat: 48.934094, lng: 20.032777 },
    { lat: 48.933955, lng: 20.032745 },
    { lat: 48.933935, lng: 20.032757 },
    { lat: 48.933794, lng: 20.033482 },
    { lat: 48.933659, lng: 20.033501 },
    { lat: 48.933645, lng: 20.033581 },
    { lat: 48.933605, lng: 20.033603 },
    { lat: 48.933537, lng: 20.034046 },
    { lat: 48.933576, lng: 20.034864 },
    { lat: 48.933271, lng: 20.035967 },
    { lat: 48.933232, lng: 20.035658 },
    { lat: 48.933202, lng: 20.035769 },
    { lat: 48.932887, lng: 20.036977 },
    { lat: 48.932868, lng: 20.037052 },
    { lat: 48.932954, lng: 20.037133 },
    { lat: 48.933061, lng: 20.037235 },
    { lat: 48.932655, lng: 20.038778 },
    { lat: 48.932462, lng: 20.039816 },
    { lat: 48.932609, lng: 20.040171 },
    { lat: 48.932734, lng: 20.040752 },
    { lat: 48.932784, lng: 20.040841 },
    { lat: 48.93298, lng: 20.041137 },
    { lat: 48.933121, lng: 20.041334 },
    { lat: 48.933258, lng: 20.041683 },
    { lat: 48.933308, lng: 20.041843 },
    { lat: 48.933354, lng: 20.041985 },
    { lat: 48.933443, lng: 20.04215 },
    { lat: 48.933463, lng: 20.042265 },
    { lat: 48.933481, lng: 20.042378 },
    { lat: 48.933512, lng: 20.042401 },
    { lat: 48.933589, lng: 20.042459 },
    { lat: 48.933623, lng: 20.042774 },
    { lat: 48.933526, lng: 20.043084 },
    { lat: 48.933453, lng: 20.043682 },
    { lat: 48.933389, lng: 20.04395 },
    { lat: 48.933453, lng: 20.044653 },
    { lat: 48.933459, lng: 20.044721 },
    { lat: 48.933521, lng: 20.044792 },
    { lat: 48.933613, lng: 20.044894 },
    { lat: 48.933693, lng: 20.045659 },
    { lat: 48.933592, lng: 20.046059 },
    { lat: 48.933552, lng: 20.046479 },
    { lat: 48.933346, lng: 20.047193 },
    { lat: 48.933107, lng: 20.047758 },
    { lat: 48.933364, lng: 20.04854 },
    { lat: 48.933529, lng: 20.04889 },
    { lat: 48.933535, lng: 20.048905 },
    { lat: 48.933634, lng: 20.049157 },
    { lat: 48.933642, lng: 20.049175 },
    { lat: 48.93376, lng: 20.049676 },
    { lat: 48.933904, lng: 20.050018 },
    { lat: 48.933955, lng: 20.05014 },
    { lat: 48.934161, lng: 20.050125 },
    { lat: 48.934218, lng: 20.050121 },
    { lat: 48.934304, lng: 20.050657 },
    { lat: 48.934471, lng: 20.051711 },
    { lat: 48.934403, lng: 20.052209 },
    { lat: 48.934383, lng: 20.052322 },
    { lat: 48.933715, lng: 20.05262 },
    { lat: 48.93378, lng: 20.05366 },
    { lat: 48.933644, lng: 20.053901 },
    { lat: 48.933665, lng: 20.055135 },
    { lat: 48.933574, lng: 20.055321 },
    { lat: 48.933668, lng: 20.05609 },
    { lat: 48.933315, lng: 20.057501 },
    { lat: 48.933277, lng: 20.059379 },
    { lat: 48.932802, lng: 20.05984 },
    { lat: 48.932304, lng: 20.060438 },
    { lat: 48.932176, lng: 20.060254 },
    { lat: 48.93211, lng: 20.060159 },
    { lat: 48.931729, lng: 20.059597 },
    { lat: 48.931382, lng: 20.05925 },
    { lat: 48.930711, lng: 20.05857 },
    { lat: 48.930394, lng: 20.057947 },
    { lat: 48.929686, lng: 20.05718 },
    { lat: 48.929584, lng: 20.057056 },
    { lat: 48.929465, lng: 20.056908 },
    { lat: 48.929129, lng: 20.056496 },
    { lat: 48.928784, lng: 20.056083 },
    { lat: 48.928737, lng: 20.056069 },
    { lat: 48.92873, lng: 20.056063 },
    { lat: 48.928323, lng: 20.055712 },
    { lat: 48.92821, lng: 20.05559 },
    { lat: 48.927988, lng: 20.055373 },
    { lat: 48.927635, lng: 20.055426 },
    { lat: 48.927202, lng: 20.055396 },
    { lat: 48.926815, lng: 20.05558 },
    { lat: 48.926631, lng: 20.055721 },
    { lat: 48.926361, lng: 20.055779 },
    { lat: 48.925957, lng: 20.055652 },
    { lat: 48.925892, lng: 20.055584 },
    { lat: 48.925481, lng: 20.055155 },
    { lat: 48.925269, lng: 20.05447 },
    { lat: 48.925236, lng: 20.054042 },
    { lat: 48.92513, lng: 20.053566 },
    { lat: 48.92494, lng: 20.053033 },
    { lat: 48.924881, lng: 20.052657 },
    { lat: 48.924687, lng: 20.052221 },
    { lat: 48.92424, lng: 20.051612 },
    { lat: 48.924028, lng: 20.051323 },
    { lat: 48.923939, lng: 20.050608 },
    { lat: 48.923852, lng: 20.05035 },
    { lat: 48.92386, lng: 20.050103 },
    { lat: 48.92379, lng: 20.049791 },
    { lat: 48.923683, lng: 20.049672 },
    { lat: 48.923525, lng: 20.049498 },
    { lat: 48.923524, lng: 20.049496 },
    { lat: 48.923333, lng: 20.049059 },
    { lat: 48.923256, lng: 20.048977 },
    { lat: 48.923123, lng: 20.048836 },
    { lat: 48.922609, lng: 20.048292 },
    { lat: 48.922201, lng: 20.04786 },
    { lat: 48.921584, lng: 20.047227 },
    { lat: 48.921531, lng: 20.047146 },
    { lat: 48.921225, lng: 20.046676 },
    { lat: 48.921162, lng: 20.046617 },
    { lat: 48.920173, lng: 20.045696 },
    { lat: 48.919675, lng: 20.045469 },
    { lat: 48.91906, lng: 20.044676 },
    { lat: 48.919028, lng: 20.044599 },
    { lat: 48.918924, lng: 20.044342 },
    { lat: 48.918864, lng: 20.044194 },
    { lat: 48.917854, lng: 20.041697 },
    { lat: 48.917727, lng: 20.0414 },
    { lat: 48.917701, lng: 20.041364 },
    { lat: 48.916583, lng: 20.03982 },
    { lat: 48.916482, lng: 20.039671 },
    { lat: 48.916328, lng: 20.039396 },
    { lat: 48.916231, lng: 20.039177 },
    { lat: 48.916126, lng: 20.03894 },
    { lat: 48.916017, lng: 20.03869 },
    { lat: 48.91561, lng: 20.0378 },
    { lat: 48.915486, lng: 20.037424 },
    { lat: 48.915275, lng: 20.036785 },
    { lat: 48.915213, lng: 20.036641 },
    { lat: 48.915186, lng: 20.036601 },
    { lat: 48.915108, lng: 20.036486 },
    { lat: 48.915092, lng: 20.036465 },
    { lat: 48.91489, lng: 20.036197 },
    { lat: 48.914802, lng: 20.03608 },
    { lat: 48.914731, lng: 20.035961 },
    { lat: 48.914715, lng: 20.035933 },
    { lat: 48.914648, lng: 20.035822 },
    { lat: 48.914535, lng: 20.035632 },
    { lat: 48.914503, lng: 20.03558 },
    { lat: 48.914486, lng: 20.035535 },
    { lat: 48.914298, lng: 20.035042 },
    { lat: 48.91432, lng: 20.034711 },
    { lat: 48.914392, lng: 20.034371 },
    { lat: 48.914379, lng: 20.034073 },
    { lat: 48.914385, lng: 20.034034 },
    { lat: 48.914772, lng: 20.03326 },
    { lat: 48.914737, lng: 20.0332 },
    { lat: 48.914695, lng: 20.033111 },
    { lat: 48.914665, lng: 20.033049 },
    { lat: 48.914133, lng: 20.031912 },
    { lat: 48.913522, lng: 20.030637 },
    { lat: 48.913443, lng: 20.030481 },
    { lat: 48.913004, lng: 20.029626 },
    { lat: 48.911373, lng: 20.026586 },
    { lat: 48.91088, lng: 20.022518 },
    { lat: 48.90981, lng: 20.015286 },
    { lat: 48.909623, lng: 20.008318 },
    { lat: 48.909529, lng: 20.006114 },
    { lat: 48.909394, lng: 20.003559 },
    { lat: 48.90934, lng: 20.002138 },
    { lat: 48.909253, lng: 20.001896 },
    { lat: 48.909341, lng: 20.0016 },
    { lat: 48.909435, lng: 20.001422 },
    { lat: 48.909547, lng: 20.00123 },
    { lat: 48.909594, lng: 20.001162 },
    { lat: 48.909745, lng: 20.000893 },
    { lat: 48.90983, lng: 20.000781 },
    { lat: 48.90994, lng: 20.000654 },
    { lat: 48.908163, lng: 19.994524 },
    { lat: 48.907531, lng: 19.992348 },
    { lat: 48.907501, lng: 19.992261 },
    { lat: 48.904831, lng: 19.995546 },
    { lat: 48.904096, lng: 19.996381 },
    { lat: 48.904336, lng: 20.001219 },
    { lat: 48.904063, lng: 20.002915 },
    { lat: 48.903961, lng: 20.003552 },
    { lat: 48.904051, lng: 20.00389 },
    { lat: 48.903776, lng: 20.004597 },
    { lat: 48.903778, lng: 20.00515 },
    { lat: 48.903629, lng: 20.005743 },
    { lat: 48.902959, lng: 20.008759 },
    { lat: 48.902551, lng: 20.009832 },
    { lat: 48.902256, lng: 20.010718 },
    { lat: 48.901889, lng: 20.012841 },
    { lat: 48.901158, lng: 20.015253 },
    { lat: 48.900793, lng: 20.019167 },
    { lat: 48.900695, lng: 20.021153 },
    { lat: 48.900389, lng: 20.027409 },
    { lat: 48.900233, lng: 20.030241 },
    { lat: 48.90089, lng: 20.031868 },
    { lat: 48.901436, lng: 20.034099 },
    { lat: 48.900665, lng: 20.044158 },
    { lat: 48.900032, lng: 20.048365 },
    { lat: 48.899854, lng: 20.049705 },
    { lat: 48.897923, lng: 20.052019 },
    { lat: 48.897881, lng: 20.052086 },
    { lat: 48.897421, lng: 20.05353 },
    { lat: 48.897282, lng: 20.05441 },
    { lat: 48.897151, lng: 20.055569 },
    { lat: 48.897022, lng: 20.056032 },
    { lat: 48.89699, lng: 20.056517 },
    { lat: 48.896981, lng: 20.05723 },
    { lat: 48.896948, lng: 20.058179 },
    { lat: 48.89701, lng: 20.058921 },
    { lat: 48.897046, lng: 20.059146 },
    { lat: 48.8971, lng: 20.061712 },
    { lat: 48.897076, lng: 20.062693 },
    { lat: 48.897006, lng: 20.063426 },
    { lat: 48.897002, lng: 20.063692 },
    { lat: 48.896895, lng: 20.064184 },
    { lat: 48.896894, lng: 20.064448 },
    { lat: 48.896769, lng: 20.065181 },
    { lat: 48.896651, lng: 20.065623 },
    { lat: 48.896708, lng: 20.06585 },
    { lat: 48.897755, lng: 20.067861 },
    { lat: 48.898553, lng: 20.071265 },
    { lat: 48.898449, lng: 20.072348 },
    { lat: 48.898134, lng: 20.074984 },
    { lat: 48.89695, lng: 20.081345 },
    { lat: 48.896721, lng: 20.090055 },
  ],
  Kravany: [
    { lat: 49.0123534, lng: 20.166112 },
    { lat: 49.0113903, lng: 20.166809 },
    { lat: 49.0097955, lng: 20.166804 },
    { lat: 49.0087415, lng: 20.1670908 },
    { lat: 49.0080462, lng: 20.1675236 },
    { lat: 49.0076595, lng: 20.1676258 },
    { lat: 49.0062301, lng: 20.1688854 },
    { lat: 49.0060988, lng: 20.1691182 },
    { lat: 49.0060306, lng: 20.1694129 },
    { lat: 49.0053289, lng: 20.1698875 },
    { lat: 49.0050438, lng: 20.1698558 },
    { lat: 49.0047309, lng: 20.1702822 },
    { lat: 49.0042506, lng: 20.1707457 },
    { lat: 49.0039318, lng: 20.1708574 },
    { lat: 49.0037317, lng: 20.1707805 },
    { lat: 49.0023119, lng: 20.1712391 },
    { lat: 49.0013196, lng: 20.1714863 },
    { lat: 49.000647, lng: 20.1715686 },
    { lat: 49.0004236, lng: 20.171722 },
    { lat: 49.0004094, lng: 20.1717918 },
    { lat: 49.000284, lng: 20.171779 },
    { lat: 49.0002706, lng: 20.1714729 },
    { lat: 48.9994097, lng: 20.1721394 },
    { lat: 48.9990864, lng: 20.1725872 },
    { lat: 48.9984974, lng: 20.1724444 },
    { lat: 48.9980171, lng: 20.1725965 },
    { lat: 48.9977562, lng: 20.1731701 },
    { lat: 48.9975802, lng: 20.1731889 },
    { lat: 48.9973558, lng: 20.1734625 },
    { lat: 48.9965047, lng: 20.1734171 },
    { lat: 48.9952868, lng: 20.1728416 },
    { lat: 48.9935566, lng: 20.1728311 },
    { lat: 48.9933881, lng: 20.1727021 },
    { lat: 48.9926952, lng: 20.1728584 },
    { lat: 48.9918299, lng: 20.1732437 },
    { lat: 48.9904464, lng: 20.1744661 },
    { lat: 48.9902381, lng: 20.1742964 },
    { lat: 48.9897958, lng: 20.1744492 },
    { lat: 48.9890876, lng: 20.1752681 },
    { lat: 48.9874565, lng: 20.17666 },
    { lat: 48.9870719, lng: 20.1769025 },
    { lat: 48.9860272, lng: 20.1773035 },
    { lat: 48.9859221, lng: 20.1772945 },
    { lat: 48.9854907, lng: 20.1765868 },
    { lat: 48.9850777, lng: 20.1768243 },
    { lat: 48.9845307, lng: 20.1766358 },
    { lat: 48.9831167, lng: 20.1766195 },
    { lat: 48.9828303, lng: 20.1761616 },
    { lat: 48.9822459, lng: 20.1760485 },
    { lat: 48.9808954, lng: 20.1763247 },
    { lat: 48.9803811, lng: 20.1769258 },
    { lat: 48.9798806, lng: 20.1769151 },
    { lat: 48.9795404, lng: 20.1767404 },
    { lat: 48.9794326, lng: 20.1766051 },
    { lat: 48.978971, lng: 20.1756579 },
    { lat: 48.978819, lng: 20.1755433 },
    { lat: 48.9785526, lng: 20.1754743 },
    { lat: 48.978004, lng: 20.1756412 },
    { lat: 48.9779107, lng: 20.1756696 },
    { lat: 48.9776112, lng: 20.1758392 },
    { lat: 48.9768821, lng: 20.1759113 },
    { lat: 48.9764166, lng: 20.1753675 },
    { lat: 48.976241, lng: 20.1746133 },
    { lat: 48.9761458, lng: 20.1747099 },
    { lat: 48.9757139, lng: 20.1743322 },
    { lat: 48.9756378, lng: 20.1744841 },
    { lat: 48.9748669, lng: 20.1737954 },
    { lat: 48.9746847, lng: 20.1735488 },
    { lat: 48.9743492, lng: 20.1733264 },
    { lat: 48.9735559, lng: 20.1729771 },
    { lat: 48.9718378, lng: 20.1719731 },
    { lat: 48.9711775, lng: 20.1724149 },
    { lat: 48.9709479, lng: 20.1728154 },
    { lat: 48.9707004, lng: 20.1729577 },
    { lat: 48.9703885, lng: 20.1733532 },
    { lat: 48.9699846, lng: 20.1736153 },
    { lat: 48.9698364, lng: 20.1739638 },
    { lat: 48.9693547, lng: 20.1744224 },
    { lat: 48.969195, lng: 20.1744597 },
    { lat: 48.9689529, lng: 20.1746527 },
    { lat: 48.9688713, lng: 20.1745008 },
    { lat: 48.9685533, lng: 20.1744047 },
    { lat: 48.9684256, lng: 20.17428 },
    { lat: 48.9683282, lng: 20.1741295 },
    { lat: 48.9682307, lng: 20.1737456 },
    { lat: 48.9681339, lng: 20.1735786 },
    { lat: 48.9677647, lng: 20.1733579 },
    { lat: 48.9676342, lng: 20.1730836 },
    { lat: 48.9674803, lng: 20.1724756 },
    { lat: 48.9673542, lng: 20.1722793 },
    { lat: 48.967126, lng: 20.1721499 },
    { lat: 48.9667568, lng: 20.1720697 },
    { lat: 48.9665832, lng: 20.1713385 },
    { lat: 48.9662177, lng: 20.1711511 },
    { lat: 48.9655833, lng: 20.1704378 },
    { lat: 48.9649889, lng: 20.170081 },
    { lat: 48.9643553, lng: 20.1701468 },
    { lat: 48.9640934, lng: 20.1700459 },
    { lat: 48.9637504, lng: 20.1700852 },
    { lat: 48.9634959, lng: 20.1697235 },
    { lat: 48.9631805, lng: 20.1697792 },
    { lat: 48.9630754, lng: 20.1695731 },
    { lat: 48.9630314, lng: 20.1695697 },
    { lat: 48.9629103, lng: 20.1696103 },
    { lat: 48.96274, lng: 20.1698017 },
    { lat: 48.9624769, lng: 20.1698131 },
    { lat: 48.9622897, lng: 20.1698928 },
    { lat: 48.96204, lng: 20.1702646 },
    { lat: 48.9618919, lng: 20.1703891 },
    { lat: 48.9617809, lng: 20.170628 },
    { lat: 48.9616395, lng: 20.1706938 },
    { lat: 48.9616006, lng: 20.1708457 },
    { lat: 48.9613465, lng: 20.1709137 },
    { lat: 48.9612549, lng: 20.1710472 },
    { lat: 48.9609506, lng: 20.1711499 },
    { lat: 48.9605509, lng: 20.1719123 },
    { lat: 48.9601091, lng: 20.1725509 },
    { lat: 48.9598488, lng: 20.1731112 },
    { lat: 48.9596674, lng: 20.1732197 },
    { lat: 48.9596318, lng: 20.1733126 },
    { lat: 48.9596601, lng: 20.1737142 },
    { lat: 48.9596076, lng: 20.1741398 },
    { lat: 48.9594286, lng: 20.1743147 },
    { lat: 48.9591147, lng: 20.1748838 },
    { lat: 48.9589911, lng: 20.1752805 },
    { lat: 48.9590309, lng: 20.175489 },
    { lat: 48.9590065, lng: 20.1756109 },
    { lat: 48.9587924, lng: 20.175863 },
    { lat: 48.958507, lng: 20.1763839 },
    { lat: 48.9581802, lng: 20.1767151 },
    { lat: 48.9577804, lng: 20.1773326 },
    { lat: 48.9576998, lng: 20.1780202 },
    { lat: 48.9577377, lng: 20.1782891 },
    { lat: 48.9574823, lng: 20.1785216 },
    { lat: 48.9573149, lng: 20.1789255 },
    { lat: 48.9571605, lng: 20.1796787 },
    { lat: 48.9571054, lng: 20.1801887 },
    { lat: 48.9567705, lng: 20.1806823 },
    { lat: 48.9566176, lng: 20.1811146 },
    { lat: 48.9563778, lng: 20.1815091 },
    { lat: 48.9562034, lng: 20.1816843 },
    { lat: 48.9560014, lng: 20.1817255 },
    { lat: 48.9557503, lng: 20.1816146 },
    { lat: 48.9554549, lng: 20.1812692 },
    { lat: 48.9549157, lng: 20.1810811 },
    { lat: 48.9547354, lng: 20.1812464 },
    { lat: 48.9544812, lng: 20.181339 },
    { lat: 48.9538862, lng: 20.1817193 },
    { lat: 48.9537377, lng: 20.181584 },
    { lat: 48.95361, lng: 20.1816886 },
    { lat: 48.953449, lng: 20.1813501 },
    { lat: 48.9528612, lng: 20.1812703 },
    { lat: 48.9526443, lng: 20.1813352 },
    { lat: 48.952265, lng: 20.1811724 },
    { lat: 48.9521531, lng: 20.1813024 },
    { lat: 48.9517703, lng: 20.1813893 },
    { lat: 48.9510668, lng: 20.1816778 },
    { lat: 48.9507977, lng: 20.1816013 },
    { lat: 48.9506263, lng: 20.181621 },
    { lat: 48.9503533, lng: 20.1814939 },
    { lat: 48.9500292, lng: 20.1816435 },
    { lat: 48.9493732, lng: 20.1817693 },
    { lat: 48.9492256, lng: 20.1818499 },
    { lat: 48.9490459, lng: 20.1817522 },
    { lat: 48.9488249, lng: 20.181802 },
    { lat: 48.9486999, lng: 20.1817454 },
    { lat: 48.9484402, lng: 20.1813903 },
    { lat: 48.9481149, lng: 20.1813691 },
    { lat: 48.9477867, lng: 20.1810751 },
    { lat: 48.9477048, lng: 20.1811117 },
    { lat: 48.9474943, lng: 20.1809154 },
    { lat: 48.9470744, lng: 20.1809743 },
    { lat: 48.9467425, lng: 20.1808118 },
    { lat: 48.9464118, lng: 20.180823 },
    { lat: 48.9461974, lng: 20.1810136 },
    { lat: 48.9460152, lng: 20.1810717 },
    { lat: 48.9458121, lng: 20.1810091 },
    { lat: 48.9456183, lng: 20.1810897 },
    { lat: 48.9453202, lng: 20.1809843 },
    { lat: 48.9452327, lng: 20.1810507 },
    { lat: 48.9450841, lng: 20.1810229 },
    { lat: 48.9448954, lng: 20.1808347 },
    { lat: 48.9445217, lng: 20.1808434 },
    { lat: 48.9443073, lng: 20.180971 },
    { lat: 48.9441583, lng: 20.1809382 },
    { lat: 48.9438543, lng: 20.1810791 },
    { lat: 48.9435355, lng: 20.1816921 },
    { lat: 48.9434552, lng: 20.1820216 },
    { lat: 48.9432975, lng: 20.1821372 },
    { lat: 48.9431863, lng: 20.1821141 },
    { lat: 48.9429748, lng: 20.1823188 },
    { lat: 48.9426629, lng: 20.1823738 },
    { lat: 48.9425136, lng: 20.1825828 },
    { lat: 48.9422845, lng: 20.1826835 },
    { lat: 48.9420985, lng: 20.1829059 },
    { lat: 48.9418151, lng: 20.1827758 },
    { lat: 48.9416399, lng: 20.1823395 },
    { lat: 48.9409687, lng: 20.1819723 },
    { lat: 48.9406078, lng: 20.1820998 },
    { lat: 48.9403136, lng: 20.1821208 },
    { lat: 48.9399389, lng: 20.1820139 },
    { lat: 48.9396339, lng: 20.1820765 },
    { lat: 48.9394681, lng: 20.1820144 },
    { lat: 48.9393511, lng: 20.1818747 },
    { lat: 48.939132, lng: 20.1819723 },
    { lat: 48.9390081, lng: 20.1818078 },
    { lat: 48.9387371, lng: 20.1817576 },
    { lat: 48.9386045, lng: 20.1815997 },
    { lat: 48.9381431, lng: 20.1815756 },
    { lat: 48.9378815, lng: 20.1813419 },
    { lat: 48.9376972, lng: 20.1812722 },
    { lat: 48.9375552, lng: 20.1810736 },
    { lat: 48.937302, lng: 20.1810744 },
    { lat: 48.9372094, lng: 20.180729 },
    { lat: 48.9371362, lng: 20.1807286 },
    { lat: 48.9367834, lng: 20.1801335 },
    { lat: 48.9366066, lng: 20.1801631 },
    { lat: 48.93648, lng: 20.1800132 },
    { lat: 48.9363091, lng: 20.1800172 },
    { lat: 48.9361362, lng: 20.1798075 },
    { lat: 48.9361485, lng: 20.1794969 },
    { lat: 48.935937, lng: 20.1791875 },
    { lat: 48.9355195, lng: 20.1787224 },
    { lat: 48.9353546, lng: 20.1787348 },
    { lat: 48.9347777, lng: 20.1781379 },
    { lat: 48.9345769, lng: 20.178044 },
    { lat: 48.9344868, lng: 20.1781347 },
    { lat: 48.9343336, lng: 20.178132 },
    { lat: 48.9341823, lng: 20.177978 },
    { lat: 48.9340168, lng: 20.1779524 },
    { lat: 48.9339659, lng: 20.1780316 },
    { lat: 48.9337937, lng: 20.1780336 },
    { lat: 48.933569, lng: 20.1778795 },
    { lat: 48.93326, lng: 20.1778948 },
    { lat: 48.9330314, lng: 20.1777558 },
    { lat: 48.9329152, lng: 20.177754 },
    { lat: 48.9328337, lng: 20.1775752 },
    { lat: 48.9325249, lng: 20.1772606 },
    { lat: 48.9323106, lng: 20.1768305 },
    { lat: 48.9321946, lng: 20.1767452 },
    { lat: 48.9320826, lng: 20.1765052 },
    { lat: 48.9320677, lng: 20.1761914 },
    { lat: 48.9319081, lng: 20.1759504 },
    { lat: 48.9315577, lng: 20.1758334 },
    { lat: 48.9314334, lng: 20.1756225 },
    { lat: 48.9312129, lng: 20.1756038 },
    { lat: 48.9312103, lng: 20.175286 },
    { lat: 48.9310532, lng: 20.175241 },
    { lat: 48.9308803, lng: 20.1750596 },
    { lat: 48.9303526, lng: 20.1749297 },
    { lat: 48.9302291, lng: 20.1750514 },
    { lat: 48.9300539, lng: 20.1749265 },
    { lat: 48.9299829, lng: 20.174779 },
    { lat: 48.9298628, lng: 20.1747618 },
    { lat: 48.9297655, lng: 20.1746419 },
    { lat: 48.9296177, lng: 20.1747189 },
    { lat: 48.9293952, lng: 20.1746198 },
    { lat: 48.9291939, lng: 20.1744303 },
    { lat: 48.9290527, lng: 20.174476 },
    { lat: 48.9289417, lng: 20.1744322 },
    { lat: 48.9288473, lng: 20.1742091 },
    { lat: 48.9287166, lng: 20.1740973 },
    { lat: 48.9279315, lng: 20.1743461 },
    { lat: 48.9278893, lng: 20.1744176 },
    { lat: 48.9274075, lng: 20.1746205 },
    { lat: 48.9272816, lng: 20.174736 },
    { lat: 48.9268488, lng: 20.1745288 },
    { lat: 48.9267793, lng: 20.1745678 },
    { lat: 48.9265049, lng: 20.1743224 },
    { lat: 48.9263411, lng: 20.1743988 },
    { lat: 48.9259239, lng: 20.1741297 },
    { lat: 48.9257776, lng: 20.174217 },
    { lat: 48.9255293, lng: 20.1740634 },
    { lat: 48.9252577, lng: 20.1741705 },
    { lat: 48.9250347, lng: 20.1741187 },
    { lat: 48.9249802, lng: 20.1740482 },
    { lat: 48.9248778, lng: 20.1740537 },
    { lat: 48.9245688, lng: 20.173534 },
    { lat: 48.9242441, lng: 20.1733889 },
    { lat: 48.9241921, lng: 20.1734388 },
    { lat: 48.9239394, lng: 20.1730872 },
    { lat: 48.9233311, lng: 20.1730825 },
    { lat: 48.9231526, lng: 20.1727943 },
    { lat: 48.9230677, lng: 20.1727824 },
    { lat: 48.9228449, lng: 20.1725065 },
    { lat: 48.9226771, lng: 20.1721932 },
    { lat: 48.9223879, lng: 20.1721738 },
    { lat: 48.9220409, lng: 20.1723133 },
    { lat: 48.9219793, lng: 20.1724017 },
    { lat: 48.92182, lng: 20.1723355 },
    { lat: 48.9214477, lng: 20.171981 },
    { lat: 48.9212554, lng: 20.1720174 },
    { lat: 48.9209017, lng: 20.1717879 },
    { lat: 48.920697, lng: 20.1717968 },
    { lat: 48.9204518, lng: 20.1720276 },
    { lat: 48.9201092, lng: 20.1722225 },
    { lat: 48.9198963, lng: 20.1722225 },
    { lat: 48.9196578, lng: 20.1724465 },
    { lat: 48.9195589, lng: 20.1724535 },
    { lat: 48.9194484, lng: 20.1726213 },
    { lat: 48.9194611, lng: 20.1727082 },
    { lat: 48.9193692, lng: 20.1729176 },
    { lat: 48.9190401, lng: 20.1732216 },
    { lat: 48.9189253, lng: 20.1734187 },
    { lat: 48.9189594, lng: 20.1736272 },
    { lat: 48.91891, lng: 20.1737414 },
    { lat: 48.9188341, lng: 20.1737269 },
    { lat: 48.9187976, lng: 20.1738001 },
    { lat: 48.918802, lng: 20.1739089 },
    { lat: 48.9186843, lng: 20.1742055 },
    { lat: 48.9185523, lng: 20.1742263 },
    { lat: 48.9184101, lng: 20.1744475 },
    { lat: 48.9183283, lng: 20.1747906 },
    { lat: 48.9182152, lng: 20.1749768 },
    { lat: 48.9180018, lng: 20.1752171 },
    { lat: 48.9177737, lng: 20.1753657 },
    { lat: 48.9176057, lng: 20.1758667 },
    { lat: 48.9176316, lng: 20.1760946 },
    { lat: 48.9187908, lng: 20.1792211 },
    { lat: 48.9187758, lng: 20.1840767 },
    { lat: 48.9167392, lng: 20.1853565 },
    { lat: 48.9129135, lng: 20.1865408 },
    { lat: 48.9128544, lng: 20.1865251 },
    { lat: 48.9133924, lng: 20.1866839 },
    { lat: 48.9147048, lng: 20.1868387 },
    { lat: 48.9153368, lng: 20.1874339 },
    { lat: 48.9157682, lng: 20.1880527 },
    { lat: 48.916344, lng: 20.1884236 },
    { lat: 48.9165533, lng: 20.1887019 },
    { lat: 48.9168771, lng: 20.1896736 },
    { lat: 48.9170797, lng: 20.1915001 },
    { lat: 48.917478, lng: 20.1925086 },
    { lat: 48.9178174, lng: 20.1930238 },
    { lat: 48.9182777, lng: 20.1943436 },
    { lat: 48.9184398, lng: 20.1950459 },
    { lat: 48.9185334, lng: 20.1958946 },
    { lat: 48.9188224, lng: 20.1972398 },
    { lat: 48.9189889, lng: 20.1994908 },
    { lat: 48.919023, lng: 20.2004262 },
    { lat: 48.9189814, lng: 20.2012773 },
    { lat: 48.9188698, lng: 20.2020352 },
    { lat: 48.9194624, lng: 20.2047298 },
    { lat: 48.9194678, lng: 20.2047515 },
    { lat: 48.9194778, lng: 20.2047176 },
    { lat: 48.9197093, lng: 20.2039143 },
    { lat: 48.9213039, lng: 20.2028372 },
    { lat: 48.9232367, lng: 20.2025072 },
    { lat: 48.9249806, lng: 20.2027178 },
    { lat: 48.9266153, lng: 20.2037255 },
    { lat: 48.9300098, lng: 20.2053128 },
    { lat: 48.9319226, lng: 20.2048729 },
    { lat: 48.9349498, lng: 20.2038578 },
    { lat: 48.9361808, lng: 20.2028719 },
    { lat: 48.9378451, lng: 20.2025005 },
    { lat: 48.93891, lng: 20.2007405 },
    { lat: 48.9403042, lng: 20.2009363 },
    { lat: 48.943627, lng: 20.2024487 },
    { lat: 48.9455717, lng: 20.2023854 },
    { lat: 48.9457001, lng: 20.2025288 },
    { lat: 48.9459077, lng: 20.2023972 },
    { lat: 48.946249, lng: 20.2023995 },
    { lat: 48.9465564, lng: 20.2025683 },
    { lat: 48.9468467, lng: 20.2024206 },
    { lat: 48.9469667, lng: 20.2025185 },
    { lat: 48.9473175, lng: 20.2024508 },
    { lat: 48.94983, lng: 20.2034084 },
    { lat: 48.9513851, lng: 20.2043143 },
    { lat: 48.9547464, lng: 20.205354 },
    { lat: 48.9548768, lng: 20.2051493 },
    { lat: 48.9548655, lng: 20.2050016 },
    { lat: 48.9549352, lng: 20.2048332 },
    { lat: 48.9551685, lng: 20.2046943 },
    { lat: 48.9556113, lng: 20.2039373 },
    { lat: 48.9559628, lng: 20.2035148 },
    { lat: 48.9565696, lng: 20.2033995 },
    { lat: 48.9568652, lng: 20.2031588 },
    { lat: 48.9570126, lng: 20.2029027 },
    { lat: 48.9571883, lng: 20.2029001 },
    { lat: 48.9573093, lng: 20.2028267 },
    { lat: 48.9578437, lng: 20.2028177 },
    { lat: 48.9581752, lng: 20.2027174 },
    { lat: 48.9584095, lng: 20.2025192 },
    { lat: 48.9588926, lng: 20.2023726 },
    { lat: 48.9593, lng: 20.2018901 },
    { lat: 48.9596843, lng: 20.2017562 },
    { lat: 48.9600621, lng: 20.2014835 },
    { lat: 48.9602514, lng: 20.2012604 },
    { lat: 48.9603934, lng: 20.2008381 },
    { lat: 48.9604687, lng: 20.2007491 },
    { lat: 48.9605396, lng: 20.2007516 },
    { lat: 48.9606909, lng: 20.2001962 },
    { lat: 48.9605992, lng: 20.1995072 },
    { lat: 48.9605959, lng: 20.1990262 },
    { lat: 48.9606859, lng: 20.1986552 },
    { lat: 48.9605871, lng: 20.1983812 },
    { lat: 48.9606361, lng: 20.1980871 },
    { lat: 48.9605575, lng: 20.1978098 },
    { lat: 48.9605704, lng: 20.1973413 },
    { lat: 48.9606828, lng: 20.1968041 },
    { lat: 48.9607624, lng: 20.1967058 },
    { lat: 48.9607565, lng: 20.1962791 },
    { lat: 48.960892, lng: 20.1956649 },
    { lat: 48.9622864, lng: 20.1957004 },
    { lat: 48.9631823, lng: 20.1951524 },
    { lat: 48.9639684, lng: 20.1954614 },
    { lat: 48.9654927, lng: 20.1951075 },
    { lat: 48.9673713, lng: 20.1947736 },
    { lat: 48.9691225, lng: 20.1951694 },
    { lat: 48.9693039, lng: 20.1951423 },
    { lat: 48.9705861, lng: 20.1949074 },
    { lat: 48.9710268, lng: 20.1944922 },
    { lat: 48.9714117, lng: 20.1939249 },
    { lat: 48.9720104, lng: 20.1937393 },
    { lat: 48.9721208, lng: 20.1939696 },
    { lat: 48.9721631, lng: 20.1939313 },
    { lat: 48.9724678, lng: 20.1943922 },
    { lat: 48.9724728, lng: 20.1945466 },
    { lat: 48.9727706, lng: 20.1945884 },
    { lat: 48.972984, lng: 20.1947723 },
    { lat: 48.9730726, lng: 20.1949291 },
    { lat: 48.97329, lng: 20.1950198 },
    { lat: 48.9733489, lng: 20.1951649 },
    { lat: 48.9733331, lng: 20.1955905 },
    { lat: 48.9738021, lng: 20.1961133 },
    { lat: 48.9738235, lng: 20.1962841 },
    { lat: 48.9737702, lng: 20.196692 },
    { lat: 48.9739843, lng: 20.196754 },
    { lat: 48.9740038, lng: 20.1970185 },
    { lat: 48.9741453, lng: 20.1974292 },
    { lat: 48.9744788, lng: 20.19749 },
    { lat: 48.9745391, lng: 20.1976167 },
    { lat: 48.9745442, lng: 20.1978643 },
    { lat: 48.9744388, lng: 20.1980439 },
    { lat: 48.9746294, lng: 20.1984745 },
    { lat: 48.9745983, lng: 20.1987118 },
    { lat: 48.9746444, lng: 20.1988796 },
    { lat: 48.9747695, lng: 20.1990215 },
    { lat: 48.9748526, lng: 20.1997502 },
    { lat: 48.9751571, lng: 20.2001853 },
    { lat: 48.9754264, lng: 20.2007542 },
    { lat: 48.9755501, lng: 20.2008626 },
    { lat: 48.9755491, lng: 20.2013463 },
    { lat: 48.9754517, lng: 20.2016431 },
    { lat: 48.9753607, lng: 20.2024096 },
    { lat: 48.9754556, lng: 20.2029566 },
    { lat: 48.9753937, lng: 20.2037771 },
    { lat: 48.9754572, lng: 20.204295 },
    { lat: 48.9753787, lng: 20.2046686 },
    { lat: 48.9753929, lng: 20.2048021 },
    { lat: 48.9754826, lng: 20.2049329 },
    { lat: 48.9757349, lng: 20.2050761 },
    { lat: 48.9757952, lng: 20.205262 },
    { lat: 48.9758042, lng: 20.2055472 },
    { lat: 48.9755518, lng: 20.2061364 },
    { lat: 48.975562, lng: 20.2062796 },
    { lat: 48.9760428, lng: 20.2071546 },
    { lat: 48.9761221, lng: 20.2076177 },
    { lat: 48.9755291, lng: 20.2094143 },
    { lat: 48.9754538, lng: 20.2104648 },
    { lat: 48.9753867, lng: 20.2108187 },
    { lat: 48.9778422, lng: 20.2133204 },
    { lat: 48.9800167, lng: 20.2137459 },
    { lat: 48.9803409, lng: 20.2139101 },
    { lat: 48.9803707, lng: 20.2140365 },
    { lat: 48.980768, lng: 20.2143453 },
    { lat: 48.9814226, lng: 20.214439 },
    { lat: 48.9815402, lng: 20.2144225 },
    { lat: 48.9817431, lng: 20.2141418 },
    { lat: 48.9817882, lng: 20.2141725 },
    { lat: 48.9823245, lng: 20.2136673 },
    { lat: 48.9825192, lng: 20.213606 },
    { lat: 48.9827204, lng: 20.2133732 },
    { lat: 48.9828424, lng: 20.2133846 },
    { lat: 48.9829488, lng: 20.2132841 },
    { lat: 48.9830734, lng: 20.2133013 },
    { lat: 48.9831198, lng: 20.2132214 },
    { lat: 48.9834862, lng: 20.2132007 },
    { lat: 48.983545, lng: 20.2130673 },
    { lat: 48.9837072, lng: 20.2130973 },
    { lat: 48.9837427, lng: 20.2129711 },
    { lat: 48.9838256, lng: 20.2129907 },
    { lat: 48.9839821, lng: 20.2129014 },
    { lat: 48.9844287, lng: 20.2129465 },
    { lat: 48.9846056, lng: 20.212666 },
    { lat: 48.985067, lng: 20.2125186 },
    { lat: 48.9850995, lng: 20.2124264 },
    { lat: 48.9853268, lng: 20.2123388 },
    { lat: 48.9853792, lng: 20.2122387 },
    { lat: 48.9855123, lng: 20.212329 },
    { lat: 48.9856235, lng: 20.2122098 },
    { lat: 48.9857754, lng: 20.2122331 },
    { lat: 48.9859742, lng: 20.2121041 },
    { lat: 48.9861966, lng: 20.2120643 },
    { lat: 48.9867917, lng: 20.2123023 },
    { lat: 48.9871848, lng: 20.2122767 },
    { lat: 48.9874137, lng: 20.2121689 },
    { lat: 48.9875032, lng: 20.2122414 },
    { lat: 48.9876451, lng: 20.2122394 },
    { lat: 48.9877502, lng: 20.2121104 },
    { lat: 48.9877762, lng: 20.2121697 },
    { lat: 48.9879674, lng: 20.2121901 },
    { lat: 48.9883649, lng: 20.2119364 },
    { lat: 48.9887306, lng: 20.2118709 },
    { lat: 48.9889048, lng: 20.2119492 },
    { lat: 48.9893558, lng: 20.2119021 },
    { lat: 48.9896509, lng: 20.2119883 },
    { lat: 48.989855, lng: 20.2119391 },
    { lat: 48.9900911, lng: 20.2120426 },
    { lat: 48.9903418, lng: 20.2122592 },
    { lat: 48.9909378, lng: 20.2124953 },
    { lat: 48.9911508, lng: 20.212385 },
    { lat: 48.9913203, lng: 20.2125667 },
    { lat: 48.9921205, lng: 20.212839 },
    { lat: 48.9931424, lng: 20.2126814 },
    { lat: 48.9942345, lng: 20.2127042 },
    { lat: 48.9945412, lng: 20.2127721 },
    { lat: 48.9953326, lng: 20.2127046 },
    { lat: 48.996036, lng: 20.212834 },
    { lat: 48.9960585, lng: 20.2129191 },
    { lat: 48.9962154, lng: 20.2129302 },
    { lat: 48.9969415, lng: 20.2127811 },
    { lat: 48.9974094, lng: 20.2129953 },
    { lat: 48.9979387, lng: 20.2128822 },
    { lat: 48.9984838, lng: 20.2129408 },
    { lat: 48.9994583, lng: 20.2128087 },
    { lat: 49.0012082, lng: 20.2124656 },
    { lat: 49.0012686, lng: 20.2124823 },
    { lat: 49.0012866, lng: 20.2126499 },
    { lat: 49.0020031, lng: 20.2124619 },
    { lat: 49.0030714, lng: 20.2123373 },
    { lat: 49.0037811, lng: 20.212115 },
    { lat: 49.0039393, lng: 20.2122192 },
    { lat: 49.0042451, lng: 20.2121453 },
    { lat: 49.0043725, lng: 20.2122042 },
    { lat: 49.0050319, lng: 20.211993 },
    { lat: 49.0054898, lng: 20.2119306 },
    { lat: 49.0078783, lng: 20.2118455 },
    { lat: 49.0084254, lng: 20.2097976 },
    { lat: 49.0088685, lng: 20.2103488 },
    { lat: 49.0092163, lng: 20.2112215 },
    { lat: 49.0094063, lng: 20.2115255 },
    { lat: 49.009629, lng: 20.2121698 },
    { lat: 49.0096262, lng: 20.2128068 },
    { lat: 49.0095771, lng: 20.2131639 },
    { lat: 49.0096897, lng: 20.2137827 },
    { lat: 49.0099398, lng: 20.214012 },
    { lat: 49.0103216, lng: 20.2147776 },
    { lat: 49.010736, lng: 20.2152899 },
    { lat: 49.0113134, lng: 20.2154249 },
    { lat: 49.0115962, lng: 20.2156017 },
    { lat: 49.0123905, lng: 20.2162599 },
    { lat: 49.0131349, lng: 20.217001 },
    { lat: 49.0134252, lng: 20.2166501 },
    { lat: 49.0137578, lng: 20.2160961 },
    { lat: 49.0139448, lng: 20.2153593 },
    { lat: 49.0139852, lng: 20.2146187 },
    { lat: 49.0140388, lng: 20.2143824 },
    { lat: 49.0147064, lng: 20.2133529 },
    { lat: 49.0155524, lng: 20.2125405 },
    { lat: 49.0171795, lng: 20.2115957 },
    { lat: 49.0169144, lng: 20.2107608 },
    { lat: 49.0169454, lng: 20.2102937 },
    { lat: 49.017026, lng: 20.2099483 },
    { lat: 49.01712, lng: 20.2098421 },
    { lat: 49.0170513, lng: 20.2090526 },
    { lat: 49.0175828, lng: 20.2080867 },
    { lat: 49.0177719, lng: 20.2075183 },
    { lat: 49.0178192, lng: 20.2065014 },
    { lat: 49.0176255, lng: 20.2049707 },
    { lat: 49.017525, lng: 20.2045695 },
    { lat: 49.0174399, lng: 20.2044105 },
    { lat: 49.0174151, lng: 20.2039833 },
    { lat: 49.0175684, lng: 20.2034522 },
    { lat: 49.017814, lng: 20.2019809 },
    { lat: 49.0177832, lng: 20.2011549 },
    { lat: 49.0176326, lng: 20.2003109 },
    { lat: 49.0175757, lng: 20.1993618 },
    { lat: 49.0170398, lng: 20.1982361 },
    { lat: 49.0163366, lng: 20.1975446 },
    { lat: 49.0155873, lng: 20.1969093 },
    { lat: 49.0150649, lng: 20.1962668 },
    { lat: 49.0132079, lng: 20.1935546 },
    { lat: 49.0129409, lng: 20.1933266 },
    { lat: 49.0129325, lng: 20.1927789 },
    { lat: 49.0131605, lng: 20.1916931 },
    { lat: 49.0131003, lng: 20.1907737 },
    { lat: 49.0129055, lng: 20.1893936 },
    { lat: 49.0120789, lng: 20.1879836 },
    { lat: 49.0117764, lng: 20.1853439 },
    { lat: 49.0126197, lng: 20.1820702 },
    { lat: 49.0130239, lng: 20.1806865 },
    { lat: 49.0130764, lng: 20.1800822 },
    { lat: 49.0132084, lng: 20.1794715 },
    { lat: 49.0134143, lng: 20.1789312 },
    { lat: 49.0137759, lng: 20.1785971 },
    { lat: 49.014307, lng: 20.1777681 },
    { lat: 49.0142416, lng: 20.1770907 },
    { lat: 49.0143462, lng: 20.1766078 },
    { lat: 49.0141701, lng: 20.176459 },
    { lat: 49.014131, lng: 20.1754822 },
    { lat: 49.0140126, lng: 20.1747171 },
    { lat: 49.0139275, lng: 20.1735543 },
    { lat: 49.0137403, lng: 20.1727558 },
    { lat: 49.0134463, lng: 20.1718861 },
    { lat: 49.0136211, lng: 20.1699244 },
    { lat: 49.013307, lng: 20.1683505 },
    { lat: 49.0123534, lng: 20.166112 },
  ],
  Štôla: [
    { lat: 49.0861014, lng: 20.1368365 },
    { lat: 49.0860001, lng: 20.1371157 },
    { lat: 49.0860123, lng: 20.1373891 },
    { lat: 49.0856174, lng: 20.1380156 },
    { lat: 49.0856129, lng: 20.1381558 },
    { lat: 49.0854413, lng: 20.1384513 },
    { lat: 49.0851583, lng: 20.1384752 },
    { lat: 49.0848102, lng: 20.1387787 },
    { lat: 49.0846091, lng: 20.1395681 },
    { lat: 49.084783, lng: 20.1399205 },
    { lat: 49.0845645, lng: 20.140003 },
    { lat: 49.0843218, lng: 20.1403512 },
    { lat: 49.0842783, lng: 20.1405018 },
    { lat: 49.0842646, lng: 20.1407187 },
    { lat: 49.0843319, lng: 20.140573 },
    { lat: 49.0845151, lng: 20.1420444 },
    { lat: 49.0844858, lng: 20.1420202 },
    { lat: 49.0842909, lng: 20.1423786 },
    { lat: 49.0842612, lng: 20.1426501 },
    { lat: 49.084155, lng: 20.1428655 },
    { lat: 49.0840674, lng: 20.143317 },
    { lat: 49.0841421, lng: 20.1437171 },
    { lat: 49.0842859, lng: 20.1438485 },
    { lat: 49.084446, lng: 20.1435763 },
    { lat: 49.0846459, lng: 20.1435358 },
    { lat: 49.0847828, lng: 20.1436886 },
    { lat: 49.0847295, lng: 20.1436763 },
    { lat: 49.0846391, lng: 20.1438421 },
    { lat: 49.0847565, lng: 20.1440424 },
    { lat: 49.0850934, lng: 20.1438759 },
    { lat: 49.0851644, lng: 20.1439354 },
    { lat: 49.0848295, lng: 20.1442038 },
    { lat: 49.0858944, lng: 20.1461534 },
    { lat: 49.0859174, lng: 20.1461926 },
    { lat: 49.0879611, lng: 20.149157 },
    { lat: 49.0879864, lng: 20.1490279 },
    { lat: 49.0881005, lng: 20.1490381 },
    { lat: 49.0881152, lng: 20.1489806 },
    { lat: 49.0881676, lng: 20.1490221 },
    { lat: 49.0881905, lng: 20.1488939 },
    { lat: 49.0882427, lng: 20.1489594 },
    { lat: 49.0883155, lng: 20.1489196 },
    { lat: 49.0884814, lng: 20.1490956 },
    { lat: 49.0885223, lng: 20.1490217 },
    { lat: 49.0889392, lng: 20.1490307 },
    { lat: 49.0890057, lng: 20.1490884 },
    { lat: 49.0892852, lng: 20.1487573 },
    { lat: 49.0894605, lng: 20.1488032 },
    { lat: 49.0894782, lng: 20.1486571 },
    { lat: 49.0896831, lng: 20.1485115 },
    { lat: 49.0899009, lng: 20.1480769 },
    { lat: 49.0901258, lng: 20.1479011 },
    { lat: 49.0902499, lng: 20.1478751 },
    { lat: 49.090416, lng: 20.1476645 },
    { lat: 49.0906892, lng: 20.1477382 },
    { lat: 49.0910237, lng: 20.1475184 },
    { lat: 49.0913638, lng: 20.1475729 },
    { lat: 49.091635, lng: 20.1473971 },
    { lat: 49.0918975, lng: 20.1473755 },
    { lat: 49.0919116, lng: 20.1471927 },
    { lat: 49.0919684, lng: 20.1470915 },
    { lat: 49.0927017, lng: 20.1466476 },
    { lat: 49.0928059, lng: 20.1466623 },
    { lat: 49.0929737, lng: 20.1464914 },
    { lat: 49.0933377, lng: 20.1462778 },
    { lat: 49.0937993, lng: 20.1462498 },
    { lat: 49.0942065, lng: 20.1461224 },
    { lat: 49.0947688, lng: 20.1456811 },
    { lat: 49.0951671, lng: 20.1455919 },
    { lat: 49.0953886, lng: 20.1454681 },
    { lat: 49.0956095, lng: 20.1454741 },
    { lat: 49.0960097, lng: 20.1452024 },
    { lat: 49.0964223, lng: 20.1452182 },
    { lat: 49.0954333, lng: 20.1436704 },
    { lat: 49.0947353, lng: 20.1422446 },
    { lat: 49.0956309, lng: 20.1413039 },
    { lat: 49.0958463, lng: 20.1412827 },
    { lat: 49.09595, lng: 20.1412054 },
    { lat: 49.0965355, lng: 20.1411461 },
    { lat: 49.0991892, lng: 20.1409598 },
    { lat: 49.0996146, lng: 20.141011 },
    { lat: 49.1002445, lng: 20.1412152 },
    { lat: 49.1006979, lng: 20.1416221 },
    { lat: 49.101025, lng: 20.1415822 },
    { lat: 49.1018111, lng: 20.1413536 },
    { lat: 49.1021402, lng: 20.1410533 },
    { lat: 49.1021844, lng: 20.1408659 },
    { lat: 49.1020704, lng: 20.1401056 },
    { lat: 49.1020683, lng: 20.1400813 },
    { lat: 49.1025273, lng: 20.1378321 },
    { lat: 49.1036388, lng: 20.134699 },
    { lat: 49.1048687, lng: 20.1324684 },
    { lat: 49.1050687, lng: 20.1322891 },
    { lat: 49.1063234, lng: 20.1304 },
    { lat: 49.1068439, lng: 20.1294271 },
    { lat: 49.1072582, lng: 20.1295878 },
    { lat: 49.1070682, lng: 20.1287613 },
    { lat: 49.1070864, lng: 20.1281784 },
    { lat: 49.1073138, lng: 20.1276729 },
    { lat: 49.107893, lng: 20.1269339 },
    { lat: 49.1083578, lng: 20.1265856 },
    { lat: 49.1085166, lng: 20.1259477 },
    { lat: 49.1078102, lng: 20.1256613 },
    { lat: 49.1074898, lng: 20.1254232 },
    { lat: 49.107129, lng: 20.1253887 },
    { lat: 49.1070759, lng: 20.1262481 },
    { lat: 49.1061099, lng: 20.1263903 },
    { lat: 49.1051481, lng: 20.125722 },
    { lat: 49.1050426, lng: 20.1263171 },
    { lat: 49.1047806, lng: 20.1259941 },
    { lat: 49.1048584, lng: 20.1247938 },
    { lat: 49.1046112, lng: 20.1247271 },
    { lat: 49.1042278, lng: 20.124822 },
    { lat: 49.1037881, lng: 20.1246742 },
    { lat: 49.1028262, lng: 20.1248621 },
    { lat: 49.1024783, lng: 20.1246151 },
    { lat: 49.1022437, lng: 20.1246618 },
    { lat: 49.1019021, lng: 20.1249321 },
    { lat: 49.1017738, lng: 20.1236794 },
    { lat: 49.1013467, lng: 20.1222782 },
    { lat: 49.1009472, lng: 20.1222851 },
    { lat: 49.1007115, lng: 20.1224154 },
    { lat: 49.0998588, lng: 20.1224731 },
    { lat: 49.0997702, lng: 20.1225335 },
    { lat: 49.099464, lng: 20.1231484 },
    { lat: 49.0989855, lng: 20.1233496 },
    { lat: 49.0986597, lng: 20.1232831 },
    { lat: 49.0981994, lng: 20.1235134 },
    { lat: 49.0979035, lng: 20.1234391 },
    { lat: 49.0973909, lng: 20.1231289 },
    { lat: 49.0971684, lng: 20.1231932 },
    { lat: 49.0962811, lng: 20.1244493 },
    { lat: 49.0959743, lng: 20.1245255 },
    { lat: 49.0956435, lng: 20.1247247 },
    { lat: 49.0953674, lng: 20.1253604 },
    { lat: 49.0951861, lng: 20.1255283 },
    { lat: 49.0950492, lng: 20.1255408 },
    { lat: 49.0949269, lng: 20.1256392 },
    { lat: 49.0946901, lng: 20.1265752 },
    { lat: 49.0943635, lng: 20.1274701 },
    { lat: 49.0942259, lng: 20.1276871 },
    { lat: 49.0939396, lng: 20.1279015 },
    { lat: 49.09386, lng: 20.1280505 },
    { lat: 49.0937571, lng: 20.128017 },
    { lat: 49.0936714, lng: 20.1281795 },
    { lat: 49.093679, lng: 20.1283966 },
    { lat: 49.0936205, lng: 20.1285659 },
    { lat: 49.0934538, lng: 20.1287289 },
    { lat: 49.0933849, lng: 20.1287274 },
    { lat: 49.0933083, lng: 20.1289463 },
    { lat: 49.0930539, lng: 20.1290933 },
    { lat: 49.0928953, lng: 20.1292925 },
    { lat: 49.0927794, lng: 20.1295412 },
    { lat: 49.0927695, lng: 20.1297984 },
    { lat: 49.092592, lng: 20.1303425 },
    { lat: 49.0926683, lng: 20.1310336 },
    { lat: 49.0926559, lng: 20.1313419 },
    { lat: 49.0924756, lng: 20.1313649 },
    { lat: 49.0917322, lng: 20.1320292 },
    { lat: 49.0914072, lng: 20.1325447 },
    { lat: 49.0912818, lng: 20.1325647 },
    { lat: 49.0911168, lng: 20.1327461 },
    { lat: 49.0908446, lng: 20.1328359 },
    { lat: 49.090654, lng: 20.1330779 },
    { lat: 49.0905618, lng: 20.1336697 },
    { lat: 49.0902888, lng: 20.1340744 },
    { lat: 49.0895722, lng: 20.1342053 },
    { lat: 49.0893243, lng: 20.1344552 },
    { lat: 49.0889066, lng: 20.134513 },
    { lat: 49.0888935, lng: 20.1344703 },
    { lat: 49.0886208, lng: 20.1345781 },
    { lat: 49.0883828, lng: 20.1347908 },
    { lat: 49.0880275, lng: 20.1349156 },
    { lat: 49.0878191, lng: 20.1351083 },
    { lat: 49.0876569, lng: 20.1354749 },
    { lat: 49.0874952, lng: 20.1355687 },
    { lat: 49.0868926, lng: 20.1357867 },
    { lat: 49.0866684, lng: 20.1357588 },
    { lat: 49.0861014, lng: 20.1368365 },
  ],
  Mlynica: [
    { lat: 49.1136515, lng: 20.2673304 },
    { lat: 49.1134344, lng: 20.2675101 },
    { lat: 49.1133704, lng: 20.2674857 },
    { lat: 49.1133483, lng: 20.2673482 },
    { lat: 49.1133056, lng: 20.2673546 },
    { lat: 49.1132245, lng: 20.2674662 },
    { lat: 49.1131654, lng: 20.2676844 },
    { lat: 49.1130974, lng: 20.2676169 },
    { lat: 49.1127213, lng: 20.2679318 },
    { lat: 49.1125875, lng: 20.2678469 },
    { lat: 49.1124643, lng: 20.2679239 },
    { lat: 49.112495, lng: 20.2680462 },
    { lat: 49.1124138, lng: 20.2682131 },
    { lat: 49.112197, lng: 20.2683482 },
    { lat: 49.1117416, lng: 20.2683069 },
    { lat: 49.1117036, lng: 20.2684552 },
    { lat: 49.1115404, lng: 20.2683992 },
    { lat: 49.1115289, lng: 20.2683083 },
    { lat: 49.111488, lng: 20.2682944 },
    { lat: 49.1114502, lng: 20.2683967 },
    { lat: 49.1114071, lng: 20.2683319 },
    { lat: 49.1113564, lng: 20.2683585 },
    { lat: 49.1113503, lng: 20.2684367 },
    { lat: 49.1112732, lng: 20.2684796 },
    { lat: 49.1113061, lng: 20.2685974 },
    { lat: 49.1112087, lng: 20.2685563 },
    { lat: 49.1111594, lng: 20.2686459 },
    { lat: 49.111243, lng: 20.2686861 },
    { lat: 49.1112624, lng: 20.2688059 },
    { lat: 49.1113477, lng: 20.2687679 },
    { lat: 49.1112988, lng: 20.2689586 },
    { lat: 49.1112115, lng: 20.2690014 },
    { lat: 49.1110705, lng: 20.2687392 },
    { lat: 49.1109071, lng: 20.2689895 },
    { lat: 49.1107152, lng: 20.2690367 },
    { lat: 49.1104182, lng: 20.2694472 },
    { lat: 49.1101371, lng: 20.2704037 },
    { lat: 49.1098002, lng: 20.2710025 },
    { lat: 49.1092482, lng: 20.2722966 },
    { lat: 49.1090194, lng: 20.2732083 },
    { lat: 49.10878, lng: 20.2734763 },
    { lat: 49.1080721, lng: 20.2738934 },
    { lat: 49.1077538, lng: 20.2745092 },
    { lat: 49.1074521, lng: 20.2752849 },
    { lat: 49.1068562, lng: 20.2763736 },
    { lat: 49.1066764, lng: 20.2770742 },
    { lat: 49.1064266, lng: 20.2775706 },
    { lat: 49.1060997, lng: 20.2785415 },
    { lat: 49.1059307, lng: 20.2797237 },
    { lat: 49.1058774, lng: 20.2810353 },
    { lat: 49.1057899, lng: 20.2814461 },
    { lat: 49.1056841, lng: 20.2816792 },
    { lat: 49.1056753, lng: 20.2819438 },
    { lat: 49.1054911, lng: 20.2825625 },
    { lat: 49.1054493, lng: 20.2829619 },
    { lat: 49.1052377, lng: 20.283628 },
    { lat: 49.1050151, lng: 20.2842727 },
    { lat: 49.1047267, lng: 20.2849058 },
    { lat: 49.104583, lng: 20.2851172 },
    { lat: 49.1042857, lng: 20.2853166 },
    { lat: 49.1041783, lng: 20.28557 },
    { lat: 49.1040015, lng: 20.286317 },
    { lat: 49.1039415, lng: 20.2869998 },
    { lat: 49.1037334, lng: 20.2875861 },
    { lat: 49.1037031, lng: 20.2878192 },
    { lat: 49.1032966, lng: 20.2888317 },
    { lat: 49.1030643, lng: 20.2892519 },
    { lat: 49.1025606, lng: 20.2898236 },
    { lat: 49.1020019, lng: 20.2899877 },
    { lat: 49.1007535, lng: 20.2894523 },
    { lat: 49.0996607, lng: 20.2895212 },
    { lat: 49.0992731, lng: 20.2905294 },
    { lat: 49.099244, lng: 20.2904792 },
    { lat: 49.0976147, lng: 20.2955676 },
    { lat: 49.0974465, lng: 20.2970287 },
    { lat: 49.0970777, lng: 20.297767 },
    { lat: 49.0969433, lng: 20.2982178 },
    { lat: 49.0964755, lng: 20.3008665 },
    { lat: 49.0965145, lng: 20.3008853 },
    { lat: 49.0963938, lng: 20.3017208 },
    { lat: 49.0963911, lng: 20.3017419 },
    { lat: 49.0963759, lng: 20.3018435 },
    { lat: 49.0960579, lng: 20.3042307 },
    { lat: 49.0959655, lng: 20.3046084 },
    { lat: 49.0952977, lng: 20.3108116 },
    { lat: 49.0951094, lng: 20.311822 },
    { lat: 49.0946021, lng: 20.313288 },
    { lat: 49.0943721, lng: 20.3137136 },
    { lat: 49.0942103, lng: 20.3141841 },
    { lat: 49.0935181, lng: 20.3155315 },
    { lat: 49.0928174, lng: 20.3171218 },
    { lat: 49.0927126, lng: 20.3172067 },
    { lat: 49.0925572, lng: 20.317203 },
    { lat: 49.0923803, lng: 20.3176375 },
    { lat: 49.0924596, lng: 20.3178262 },
    { lat: 49.0924435, lng: 20.3179347 },
    { lat: 49.0923795, lng: 20.317994 },
    { lat: 49.0921758, lng: 20.3178212 },
    { lat: 49.0921517, lng: 20.3178711 },
    { lat: 49.0929145, lng: 20.3193917 },
    { lat: 49.0936427, lng: 20.3215072 },
    { lat: 49.0947492, lng: 20.3241194 },
    { lat: 49.0959352, lng: 20.3275328 },
    { lat: 49.096274, lng: 20.328723 },
    { lat: 49.0964446, lng: 20.3298154 },
    { lat: 49.0964366, lng: 20.3309774 },
    { lat: 49.0967628, lng: 20.3320179 },
    { lat: 49.0965881, lng: 20.3326256 },
    { lat: 49.0962193, lng: 20.3331398 },
    { lat: 49.0961707, lng: 20.3332807 },
    { lat: 49.0961706, lng: 20.333424 },
    { lat: 49.0962932, lng: 20.3337098 },
    { lat: 49.0962999, lng: 20.3338512 },
    { lat: 49.0961994, lng: 20.3341106 },
    { lat: 49.096245, lng: 20.3346487 },
    { lat: 49.096215, lng: 20.3350703 },
    { lat: 49.096134, lng: 20.3356801 },
    { lat: 49.096054, lng: 20.335977 },
    { lat: 49.096063, lng: 20.336026 },
    { lat: 49.096131, lng: 20.336057 },
    { lat: 49.096448, lng: 20.336178 },
    { lat: 49.097227, lng: 20.33646 },
    { lat: 49.098133, lng: 20.336728 },
    { lat: 49.09882, lng: 20.336929 },
    { lat: 49.099442, lng: 20.33712 },
    { lat: 49.099466, lng: 20.337136 },
    { lat: 49.09949, lng: 20.337152 },
    { lat: 49.099589, lng: 20.336865 },
    { lat: 49.099878, lng: 20.336008 },
    { lat: 49.100195, lng: 20.335079 },
    { lat: 49.100498, lng: 20.334168 },
    { lat: 49.10076, lng: 20.3334 },
    { lat: 49.100926, lng: 20.332947 },
    { lat: 49.101106, lng: 20.332408 },
    { lat: 49.101434, lng: 20.331449 },
    { lat: 49.101701, lng: 20.33068 },
    { lat: 49.102027, lng: 20.329733 },
    { lat: 49.102192, lng: 20.329277 },
    { lat: 49.102295, lng: 20.328976 },
    { lat: 49.102574, lng: 20.328184 },
    { lat: 49.102875, lng: 20.327313 },
    { lat: 49.102979, lng: 20.327003 },
    { lat: 49.103009, lng: 20.326878 },
    { lat: 49.10301, lng: 20.326682 },
    { lat: 49.102993, lng: 20.326549 },
    { lat: 49.103035, lng: 20.326574 },
    { lat: 49.103197, lng: 20.326171 },
    { lat: 49.103459, lng: 20.325628 },
    { lat: 49.103528, lng: 20.325403 },
    { lat: 49.103827, lng: 20.324986 },
    { lat: 49.104069, lng: 20.324636 },
    { lat: 49.104193, lng: 20.324476 },
    { lat: 49.10443, lng: 20.324188 },
    { lat: 49.104922, lng: 20.323557 },
    { lat: 49.105184, lng: 20.323228 },
    { lat: 49.105495, lng: 20.322919 },
    { lat: 49.105878, lng: 20.322467 },
    { lat: 49.106174, lng: 20.322112 },
    { lat: 49.106547, lng: 20.321761 },
    { lat: 49.10688, lng: 20.321367 },
    { lat: 49.107067, lng: 20.321246 },
    { lat: 49.107154, lng: 20.321152 },
    { lat: 49.107257, lng: 20.321037 },
    { lat: 49.107465, lng: 20.320703 },
    { lat: 49.107473, lng: 20.320735 },
    { lat: 49.107521, lng: 20.320645 },
    { lat: 49.107921, lng: 20.320181 },
    { lat: 49.108386, lng: 20.319706 },
    { lat: 49.108812, lng: 20.319214 },
    { lat: 49.109134, lng: 20.318852 },
    { lat: 49.109331, lng: 20.318608 },
    { lat: 49.10947, lng: 20.318436 },
    { lat: 49.11001, lng: 20.317731 },
    { lat: 49.110429, lng: 20.317241 },
    { lat: 49.110584, lng: 20.317097 },
    { lat: 49.110998, lng: 20.316629 },
    { lat: 49.11128, lng: 20.316318 },
    { lat: 49.111538, lng: 20.316068 },
    { lat: 49.111729, lng: 20.315878 },
    { lat: 49.112092, lng: 20.315501 },
    { lat: 49.112277, lng: 20.315305 },
    { lat: 49.112578, lng: 20.314896 },
    { lat: 49.113043, lng: 20.314304 },
    { lat: 49.113395, lng: 20.313847 },
    { lat: 49.113863, lng: 20.313204 },
    { lat: 49.114052, lng: 20.31292 },
    { lat: 49.114149, lng: 20.312801 },
    { lat: 49.11429, lng: 20.312648 },
    { lat: 49.114346, lng: 20.312587 },
    { lat: 49.114451, lng: 20.312445 },
    { lat: 49.114659, lng: 20.312199 },
    { lat: 49.114766, lng: 20.312082 },
    { lat: 49.114837, lng: 20.312009 },
    { lat: 49.114936, lng: 20.311902 },
    { lat: 49.115013, lng: 20.311779 },
    { lat: 49.11508, lng: 20.311671 },
    { lat: 49.115229, lng: 20.311495 },
    { lat: 49.115423, lng: 20.311218 },
    { lat: 49.115492, lng: 20.31112 },
    { lat: 49.115556, lng: 20.311037 },
    { lat: 49.115602, lng: 20.310953 },
    { lat: 49.11567, lng: 20.31088 },
    { lat: 49.115769, lng: 20.31073 },
    { lat: 49.115927, lng: 20.310454 },
    { lat: 49.116072, lng: 20.310202 },
    { lat: 49.116146, lng: 20.310077 },
    { lat: 49.116236, lng: 20.309889 },
    { lat: 49.116415, lng: 20.309568 },
    { lat: 49.116533, lng: 20.309381 },
    { lat: 49.116627, lng: 20.309225 },
    { lat: 49.116745, lng: 20.309031 },
    { lat: 49.116828, lng: 20.308895 },
    { lat: 49.116909, lng: 20.308752 },
    { lat: 49.116968, lng: 20.308646 },
    { lat: 49.117021, lng: 20.308544 },
    { lat: 49.117067, lng: 20.308473 },
    { lat: 49.117145, lng: 20.308355 },
    { lat: 49.117212, lng: 20.308241 },
    { lat: 49.117335, lng: 20.308053 },
    { lat: 49.117402, lng: 20.30796 },
    { lat: 49.117489, lng: 20.307867 },
    { lat: 49.117647, lng: 20.307695 },
    { lat: 49.117789, lng: 20.307506 },
    { lat: 49.117903, lng: 20.307336 },
    { lat: 49.117976, lng: 20.30722 },
    { lat: 49.117996, lng: 20.307125 },
    { lat: 49.118109, lng: 20.306977 },
    { lat: 49.118133, lng: 20.306922 },
    { lat: 49.1182, lng: 20.306769 },
    { lat: 49.118355, lng: 20.306539 },
    { lat: 49.118435, lng: 20.306391 },
    { lat: 49.118611, lng: 20.306076 },
    { lat: 49.118739, lng: 20.305785 },
    { lat: 49.118828, lng: 20.305626 },
    { lat: 49.118964, lng: 20.305401 },
    { lat: 49.119019, lng: 20.305315 },
    { lat: 49.119097, lng: 20.305146 },
    { lat: 49.119183, lng: 20.305003 },
    { lat: 49.119337, lng: 20.304766 },
    { lat: 49.119545, lng: 20.304462 },
    { lat: 49.119696, lng: 20.304266 },
    { lat: 49.119959, lng: 20.303913 },
    { lat: 49.120219, lng: 20.303534 },
    { lat: 49.120443, lng: 20.303206 },
    { lat: 49.1191093, lng: 20.3014108 },
    { lat: 49.1190343, lng: 20.3015707 },
    { lat: 49.118899, lng: 20.3012475 },
    { lat: 49.1177383, lng: 20.301367 },
    { lat: 49.1169755, lng: 20.3016471 },
    { lat: 49.1157309, lng: 20.3032267 },
    { lat: 49.1153942, lng: 20.3023279 },
    { lat: 49.1150264, lng: 20.3027726 },
    { lat: 49.1145713, lng: 20.3030901 },
    { lat: 49.1139139, lng: 20.3033258 },
    { lat: 49.1133926, lng: 20.3029796 },
    { lat: 49.1131097, lng: 20.3018136 },
    { lat: 49.1129029, lng: 20.3006325 },
    { lat: 49.1127311, lng: 20.3001289 },
    { lat: 49.1138727, lng: 20.2975163 },
    { lat: 49.1138943, lng: 20.2948869 },
    { lat: 49.1147852, lng: 20.2936497 },
    { lat: 49.1160834, lng: 20.2930963 },
    { lat: 49.1157567, lng: 20.2926334 },
    { lat: 49.1156342, lng: 20.2923238 },
    { lat: 49.1160444, lng: 20.2920718 },
    { lat: 49.1164079, lng: 20.2924074 },
    { lat: 49.1169866, lng: 20.2922343 },
    { lat: 49.1175697, lng: 20.2915651 },
    { lat: 49.1181666, lng: 20.2900955 },
    { lat: 49.1182979, lng: 20.2895905 },
    { lat: 49.1185028, lng: 20.2892423 },
    { lat: 49.1188559, lng: 20.2894311 },
    { lat: 49.1200689, lng: 20.2880815 },
    { lat: 49.1204102, lng: 20.2870174 },
    { lat: 49.1210502, lng: 20.2853565 },
    { lat: 49.1210909, lng: 20.2843498 },
    { lat: 49.1211704, lng: 20.2837868 },
    { lat: 49.1213384, lng: 20.2832417 },
    { lat: 49.1204857, lng: 20.2824019 },
    { lat: 49.1197371, lng: 20.281832 },
    { lat: 49.1199345, lng: 20.2808557 },
    { lat: 49.1196355, lng: 20.2806365 },
    { lat: 49.1163709, lng: 20.2780671 },
    { lat: 49.1174659, lng: 20.2750827 },
    { lat: 49.1151705, lng: 20.2734293 },
    { lat: 49.1153822, lng: 20.272506 },
    { lat: 49.1135691, lng: 20.2711509 },
    { lat: 49.1136271, lng: 20.2708466 },
    { lat: 49.1140371, lng: 20.2695548 },
    { lat: 49.1142759, lng: 20.2689526 },
    { lat: 49.1136515, lng: 20.2673304 },
  ],
  Vydrník: [
    { lat: 49.0085116, lng: 20.4151543 },
    { lat: 49.0088907, lng: 20.4140526 },
    { lat: 49.0100441, lng: 20.4114536 },
    { lat: 49.0110533, lng: 20.4079045 },
    { lat: 49.0117041, lng: 20.4096558 },
    { lat: 49.0135369, lng: 20.4108363 },
    { lat: 49.0155832, lng: 20.4116419 },
    { lat: 49.0156475, lng: 20.4104335 },
    { lat: 49.0157441, lng: 20.4086171 },
    { lat: 49.0158445, lng: 20.4057235 },
    { lat: 49.016001, lng: 20.4042135 },
    { lat: 49.0161848, lng: 20.4035112 },
    { lat: 49.016281, lng: 20.4017816 },
    { lat: 49.0162727, lng: 20.4012665 },
    { lat: 49.0160655, lng: 20.4009371 },
    { lat: 49.0153957, lng: 20.400529 },
    { lat: 49.0144415, lng: 20.4001324 },
    { lat: 49.0131519, lng: 20.4016588 },
    { lat: 49.012415, lng: 20.401973 },
    { lat: 49.0113921, lng: 20.4018969 },
    { lat: 49.0105803, lng: 20.4015774 },
    { lat: 49.0098491, lng: 20.4011192 },
    { lat: 49.0084484, lng: 20.4007945 },
    { lat: 49.0082813, lng: 20.3988032 },
    { lat: 49.0081709, lng: 20.3980318 },
    { lat: 49.0079896, lng: 20.3972042 },
    { lat: 49.0077928, lng: 20.397006 },
    { lat: 49.0074862, lng: 20.3964954 },
    { lat: 49.0066825, lng: 20.3944347 },
    { lat: 49.0057008, lng: 20.3928159 },
    { lat: 49.0051456, lng: 20.3926378 },
    { lat: 49.0044128, lng: 20.3930213 },
    { lat: 49.0040248, lng: 20.3934532 },
    { lat: 49.0036947, lng: 20.3939478 },
    { lat: 49.0034355, lng: 20.3941478 },
    { lat: 49.0033528, lng: 20.3941081 },
    { lat: 49.0030928, lng: 20.3942559 },
    { lat: 49.0024937, lng: 20.3941064 },
    { lat: 49.0000177, lng: 20.3943128 },
    { lat: 48.9982385, lng: 20.394872 },
    { lat: 48.9978557, lng: 20.3949301 },
    { lat: 48.9973223, lng: 20.3949083 },
    { lat: 48.9961934, lng: 20.3950952 },
    { lat: 48.9944709, lng: 20.3955457 },
    { lat: 48.9930381, lng: 20.3958279 },
    { lat: 48.9916054, lng: 20.3962607 },
    { lat: 48.9907277, lng: 20.3967428 },
    { lat: 48.989965, lng: 20.39738 },
    { lat: 48.990403, lng: 20.398475 },
    { lat: 48.991188, lng: 20.40049 },
    { lat: 48.992097, lng: 20.402861 },
    { lat: 48.991237, lng: 20.403356 },
    { lat: 48.990756, lng: 20.403644 },
    { lat: 48.990221, lng: 20.403897 },
    { lat: 48.989856, lng: 20.404058 },
    { lat: 48.989016, lng: 20.404167 },
    { lat: 48.988711, lng: 20.40418 },
    { lat: 48.988578, lng: 20.404234 },
    { lat: 48.988037, lng: 20.404321 },
    { lat: 48.987933, lng: 20.404333 },
    { lat: 48.987366, lng: 20.404384 },
    { lat: 48.987014, lng: 20.404389 },
    { lat: 48.986048, lng: 20.404248 },
    { lat: 48.985469, lng: 20.403885 },
    { lat: 48.9855, lng: 20.403937 },
    { lat: 48.985696, lng: 20.404306 },
    { lat: 48.986035, lng: 20.404577 },
    { lat: 48.985903, lng: 20.404692 },
    { lat: 48.986631, lng: 20.405429 },
    { lat: 48.987154, lng: 20.405895 },
    { lat: 48.987368, lng: 20.40618 },
    { lat: 48.98792, lng: 20.407224 },
    { lat: 48.987849, lng: 20.407339 },
    { lat: 48.988528, lng: 20.408744 },
    { lat: 48.988929, lng: 20.410199 },
    { lat: 48.989581, lng: 20.411802 },
    { lat: 48.988717, lng: 20.412646 },
    { lat: 48.987577, lng: 20.413792 },
    { lat: 48.987576, lng: 20.413881 },
    { lat: 48.987725, lng: 20.41404 },
    { lat: 48.987896, lng: 20.414324 },
    { lat: 48.988051, lng: 20.414665 },
    { lat: 48.988366, lng: 20.415412 },
    { lat: 48.98858, lng: 20.415805 },
    { lat: 48.988889, lng: 20.416208 },
    { lat: 48.989152, lng: 20.416459 },
    { lat: 48.989597, lng: 20.416899 },
    { lat: 48.989692, lng: 20.4169 },
    { lat: 48.989857, lng: 20.417012 },
    { lat: 48.990009, lng: 20.417035 },
    { lat: 48.990208, lng: 20.41722 },
    { lat: 48.990545, lng: 20.417443 },
    { lat: 48.991155, lng: 20.417995 },
    { lat: 48.991372, lng: 20.418178 },
    { lat: 48.991744, lng: 20.418501 },
    { lat: 48.991781, lng: 20.41854 },
    { lat: 48.99204, lng: 20.41884 },
    { lat: 48.992462, lng: 20.419319 },
    { lat: 48.992655, lng: 20.419535 },
    { lat: 48.993325, lng: 20.420279 },
    { lat: 48.993324, lng: 20.42029 },
    { lat: 48.994488, lng: 20.421286 },
    { lat: 48.995247, lng: 20.422014 },
    { lat: 48.995878, lng: 20.422207 },
    { lat: 48.996057, lng: 20.42229 },
    { lat: 48.996187, lng: 20.422303 },
    { lat: 48.996238, lng: 20.422331 },
    { lat: 48.996326, lng: 20.422362 },
    { lat: 48.996451, lng: 20.422388 },
    { lat: 48.996561, lng: 20.422407 },
    { lat: 48.996874, lng: 20.422433 },
    { lat: 48.99695, lng: 20.422445 },
    { lat: 48.997015, lng: 20.422475 },
    { lat: 48.997097, lng: 20.422519 },
    { lat: 48.997397, lng: 20.422688 },
    { lat: 48.997589, lng: 20.422792 },
    { lat: 48.997664, lng: 20.422848 },
    { lat: 48.997797, lng: 20.422956 },
    { lat: 48.997945, lng: 20.423075 },
    { lat: 48.998048, lng: 20.423167 },
    { lat: 48.998282, lng: 20.423397 },
    { lat: 48.998347, lng: 20.423469 },
    { lat: 48.998442, lng: 20.423559 },
    { lat: 48.998631, lng: 20.423777 },
    { lat: 48.998919, lng: 20.424149 },
    { lat: 48.999466, lng: 20.424854 },
    { lat: 48.999586, lng: 20.425002 },
    { lat: 48.999608, lng: 20.425029 },
    { lat: 49.000091, lng: 20.425475 },
    { lat: 49.000488, lng: 20.425774 },
    { lat: 49.000911, lng: 20.426152 },
    { lat: 49.001181, lng: 20.426377 },
    { lat: 49.00175, lng: 20.426725 },
    { lat: 49.001934, lng: 20.426888 },
    { lat: 49.002055, lng: 20.427035 },
    { lat: 49.002067, lng: 20.427022 },
    { lat: 49.002089, lng: 20.427005 },
    { lat: 49.002666, lng: 20.426836 },
    { lat: 49.0034298, lng: 20.42664 },
    { lat: 49.0039983, lng: 20.4263693 },
    { lat: 49.0050442, lng: 20.4256523 },
    { lat: 49.0050543, lng: 20.4253832 },
    { lat: 49.0057772, lng: 20.4250574 },
    { lat: 49.007249, lng: 20.4230413 },
    { lat: 49.008467, lng: 20.4205718 },
    { lat: 49.0083516, lng: 20.4178515 },
    { lat: 49.0084809, lng: 20.4157133 },
    { lat: 49.0085116, lng: 20.4151543 },
  ],
  Hôrka: [
    { lat: 49.0157441, lng: 20.4086171 },
    { lat: 49.0180207, lng: 20.4080838 },
    { lat: 49.0180573, lng: 20.4080719 },
    { lat: 49.0180602, lng: 20.4077327 },
    { lat: 49.0180198, lng: 20.4071099 },
    { lat: 49.018134, lng: 20.4067687 },
    { lat: 49.0184665, lng: 20.4061559 },
    { lat: 49.0187259, lng: 20.4053413 },
    { lat: 49.0198687, lng: 20.4060752 },
    { lat: 49.0207018, lng: 20.4067876 },
    { lat: 49.0211265, lng: 20.4074714 },
    { lat: 49.0212246, lng: 20.407805 },
    { lat: 49.0216354, lng: 20.4085054 },
    { lat: 49.0224472, lng: 20.4093984 },
    { lat: 49.0230493, lng: 20.4097143 },
    { lat: 49.0243602, lng: 20.4099295 },
    { lat: 49.0276302, lng: 20.4110325 },
    { lat: 49.0284754, lng: 20.4112316 },
    { lat: 49.0289089, lng: 20.4114928 },
    { lat: 49.0295222, lng: 20.4117521 },
    { lat: 49.0309742, lng: 20.4122428 },
    { lat: 49.0313642, lng: 20.4125534 },
    { lat: 49.0318705, lng: 20.4131046 },
    { lat: 49.0331472, lng: 20.4140489 },
    { lat: 49.0368809, lng: 20.4151772 },
    { lat: 49.036763, lng: 20.415973 },
    { lat: 49.03678, lng: 20.415976 },
    { lat: 49.037174, lng: 20.41605 },
    { lat: 49.03764, lng: 20.416112 },
    { lat: 49.037663, lng: 20.415841 },
    { lat: 49.037664, lng: 20.415814 },
    { lat: 49.037723, lng: 20.415814 },
    { lat: 49.03795, lng: 20.415813 },
    { lat: 49.037949, lng: 20.415804 },
    { lat: 49.037949, lng: 20.415793 },
    { lat: 49.037927, lng: 20.415183 },
    { lat: 49.037914, lng: 20.414846 },
    { lat: 49.037891, lng: 20.414329 },
    { lat: 49.037794, lng: 20.412092 },
    { lat: 49.037785, lng: 20.411991 },
    { lat: 49.037774, lng: 20.41142 },
    { lat: 49.03775, lng: 20.411204 },
    { lat: 49.037703, lng: 20.410324 },
    { lat: 49.037707, lng: 20.410073 },
    { lat: 49.037692, lng: 20.409952 },
    { lat: 49.037675, lng: 20.409735 },
    { lat: 49.03768, lng: 20.409408 },
    { lat: 49.037635, lng: 20.407948 },
    { lat: 49.037634, lng: 20.407907 },
    { lat: 49.03764, lng: 20.407908 },
    { lat: 49.038054, lng: 20.407942 },
    { lat: 49.038552, lng: 20.407984 },
    { lat: 49.039336, lng: 20.407362 },
    { lat: 49.039365, lng: 20.407339 },
    { lat: 49.04048, lng: 20.40648 },
    { lat: 49.040332, lng: 20.405857 },
    { lat: 49.040346, lng: 20.405849 },
    { lat: 49.040392, lng: 20.405821 },
    { lat: 49.040612, lng: 20.405692 },
    { lat: 49.040804, lng: 20.40561 },
    { lat: 49.040991, lng: 20.405557 },
    { lat: 49.04147, lng: 20.405523 },
    { lat: 49.041819, lng: 20.405511 },
    { lat: 49.042385, lng: 20.405511 },
    { lat: 49.042705, lng: 20.405472 },
    { lat: 49.042885, lng: 20.405483 },
    { lat: 49.043048, lng: 20.40549 },
    { lat: 49.043335, lng: 20.405558 },
    { lat: 49.043587, lng: 20.405653 },
    { lat: 49.043732, lng: 20.405719 },
    { lat: 49.04387, lng: 20.405806 },
    { lat: 49.043994, lng: 20.405917 },
    { lat: 49.044186, lng: 20.406112 },
    { lat: 49.044236, lng: 20.406163 },
    { lat: 49.044442, lng: 20.406446 },
    { lat: 49.044673, lng: 20.4068 },
    { lat: 49.045087, lng: 20.407568 },
    { lat: 49.045169, lng: 20.407455 },
    { lat: 49.045235, lng: 20.407364 },
    { lat: 49.045326, lng: 20.407231 },
    { lat: 49.045368, lng: 20.407157 },
    { lat: 49.045409, lng: 20.407081 },
    { lat: 49.045414, lng: 20.407072 },
    { lat: 49.045418, lng: 20.407054 },
    { lat: 49.045539, lng: 20.407095 },
    { lat: 49.045627, lng: 20.407229 },
    { lat: 49.04574, lng: 20.407184 },
    { lat: 49.045818, lng: 20.407193 },
    { lat: 49.045911, lng: 20.407267 },
    { lat: 49.045976, lng: 20.407345 },
    { lat: 49.046074, lng: 20.40736 },
    { lat: 49.046158, lng: 20.407404 },
    { lat: 49.046245, lng: 20.407426 },
    { lat: 49.04626, lng: 20.407408 },
    { lat: 49.046313, lng: 20.407236 },
    { lat: 49.046352, lng: 20.407215 },
    { lat: 49.046397, lng: 20.407264 },
    { lat: 49.046437, lng: 20.407242 },
    { lat: 49.04669, lng: 20.406916 },
    { lat: 49.0467, lng: 20.406904 },
    { lat: 49.046792, lng: 20.406741 },
    { lat: 49.046888, lng: 20.406595 },
    { lat: 49.047046, lng: 20.406439 },
    { lat: 49.047028, lng: 20.406354 },
    { lat: 49.046899, lng: 20.405686 },
    { lat: 49.046842, lng: 20.405233 },
    { lat: 49.046644, lng: 20.404363 },
    { lat: 49.047492, lng: 20.403627 },
    { lat: 49.048252, lng: 20.402983 },
    { lat: 49.04813, lng: 20.402751 },
    { lat: 49.047936, lng: 20.402211 },
    { lat: 49.04783, lng: 20.401995 },
    { lat: 49.047723, lng: 20.401763 },
    { lat: 49.047649, lng: 20.401659 },
    { lat: 49.047595, lng: 20.401607 },
    { lat: 49.047554, lng: 20.401579 },
    { lat: 49.047362, lng: 20.401349 },
    { lat: 49.047264, lng: 20.401241 },
    { lat: 49.047173, lng: 20.401108 },
    { lat: 49.047036, lng: 20.400864 },
    { lat: 49.046897, lng: 20.400645 },
    { lat: 49.04687, lng: 20.400647 },
    { lat: 49.04686, lng: 20.400628 },
    { lat: 49.046726, lng: 20.40039 },
    { lat: 49.046576, lng: 20.40009 },
    { lat: 49.046421, lng: 20.399742 },
    { lat: 49.046323, lng: 20.399501 },
    { lat: 49.046084, lng: 20.398846 },
    { lat: 49.045962, lng: 20.398496 },
    { lat: 49.045671, lng: 20.397624 },
    { lat: 49.045416, lng: 20.396838 },
    { lat: 49.044871, lng: 20.395409 },
    { lat: 49.04458, lng: 20.39465 },
    { lat: 49.044304, lng: 20.393954 },
    { lat: 49.044047, lng: 20.393317 },
    { lat: 49.043829, lng: 20.392802 },
    { lat: 49.043619, lng: 20.39238 },
    { lat: 49.043573, lng: 20.392298 },
    { lat: 49.043554, lng: 20.392263 },
    { lat: 49.043518, lng: 20.392207 },
    { lat: 49.043528, lng: 20.392187 },
    { lat: 49.043592, lng: 20.392066 },
    { lat: 49.043716, lng: 20.391822 },
    { lat: 49.043749, lng: 20.391705 },
    { lat: 49.043752, lng: 20.391697 },
    { lat: 49.043777, lng: 20.391646 },
    { lat: 49.043819, lng: 20.391563 },
    { lat: 49.043892, lng: 20.391448 },
    { lat: 49.043955, lng: 20.391349 },
    { lat: 49.044138, lng: 20.391078 },
    { lat: 49.044342, lng: 20.390775 },
    { lat: 49.044388, lng: 20.390708 },
    { lat: 49.044896, lng: 20.390057 },
    { lat: 49.045115, lng: 20.38983 },
    { lat: 49.045315, lng: 20.389684 },
    { lat: 49.045628, lng: 20.389521 },
    { lat: 49.046027, lng: 20.389106 },
    { lat: 49.0452565, lng: 20.3879529 },
    { lat: 49.0433405, lng: 20.3857512 },
    { lat: 49.0428703, lng: 20.3852935 },
    { lat: 49.0422605, lng: 20.3848349 },
    { lat: 49.0410452, lng: 20.3840831 },
    { lat: 49.0406605, lng: 20.3839761 },
    { lat: 49.0402016, lng: 20.3834008 },
    { lat: 49.0400477, lng: 20.3832938 },
    { lat: 49.0396071, lng: 20.3824905 },
    { lat: 49.0390793, lng: 20.3820279 },
    { lat: 49.0368901, lng: 20.3804581 },
    { lat: 49.0356632, lng: 20.3791689 },
    { lat: 49.0347537, lng: 20.3788582 },
    { lat: 49.0320043, lng: 20.3772144 },
    { lat: 49.0311725, lng: 20.3769605 },
    { lat: 49.030584, lng: 20.3768529 },
    { lat: 49.0304069, lng: 20.3767516 },
    { lat: 49.0302486, lng: 20.3765295 },
    { lat: 49.0281046, lng: 20.3756756 },
    { lat: 49.0274003, lng: 20.3750426 },
    { lat: 49.0265873, lng: 20.3742173 },
    { lat: 49.0263053, lng: 20.3737059 },
    { lat: 49.0262006, lng: 20.3736782 },
    { lat: 49.0259041, lng: 20.3732 },
    { lat: 49.0254384, lng: 20.3726844 },
    { lat: 49.025098, lng: 20.3724383 },
    { lat: 49.0248399, lng: 20.3721088 },
    { lat: 49.0261046, lng: 20.3699975 },
    { lat: 49.0256491, lng: 20.3695995 },
    { lat: 49.0255889, lng: 20.3695568 },
    { lat: 49.0250402, lng: 20.3698731 },
    { lat: 49.0238256, lng: 20.3702319 },
    { lat: 49.0234924, lng: 20.3705029 },
    { lat: 49.0222983, lng: 20.3683439 },
    { lat: 49.0219305, lng: 20.3675033 },
    { lat: 49.0217088, lng: 20.3668273 },
    { lat: 49.0210021, lng: 20.3661639 },
    { lat: 49.0206698, lng: 20.366015 },
    { lat: 49.0200006, lng: 20.3654778 },
    { lat: 49.0188927, lng: 20.3647762 },
    { lat: 49.0176889, lng: 20.3644493 },
    { lat: 49.0169515, lng: 20.3640434 },
    { lat: 49.0160313, lng: 20.3634227 },
    { lat: 49.0137667, lng: 20.3615393 },
    { lat: 49.0107197, lng: 20.3601915 },
    { lat: 49.0096065, lng: 20.3596473 },
    { lat: 49.0078868, lng: 20.3586739 },
    { lat: 49.0078718, lng: 20.3586662 },
    { lat: 49.0078805, lng: 20.3599168 },
    { lat: 49.0078188, lng: 20.3614718 },
    { lat: 49.0075231, lng: 20.3627171 },
    { lat: 49.0067857, lng: 20.3640982 },
    { lat: 49.0069707, lng: 20.3650447 },
    { lat: 49.0069364, lng: 20.3671133 },
    { lat: 49.0070635, lng: 20.3685187 },
    { lat: 49.0070281, lng: 20.3697686 },
    { lat: 49.0066548, lng: 20.3710171 },
    { lat: 49.0065911, lng: 20.3716124 },
    { lat: 49.0063536, lng: 20.3729067 },
    { lat: 49.0060015, lng: 20.3741802 },
    { lat: 49.0059243, lng: 20.3749241 },
    { lat: 49.0060431, lng: 20.3766448 },
    { lat: 49.0057235, lng: 20.3787364 },
    { lat: 49.0052898, lng: 20.3809384 },
    { lat: 49.005162, lng: 20.3826289 },
    { lat: 49.005156, lng: 20.3830645 },
    { lat: 49.0053774, lng: 20.3860288 },
    { lat: 49.0054061, lng: 20.388342 },
    { lat: 49.0056047, lng: 20.391355 },
    { lat: 49.0057008, lng: 20.3928159 },
    { lat: 49.0066825, lng: 20.3944347 },
    { lat: 49.0074862, lng: 20.3964954 },
    { lat: 49.0077928, lng: 20.397006 },
    { lat: 49.0079896, lng: 20.3972042 },
    { lat: 49.0081709, lng: 20.3980318 },
    { lat: 49.0082813, lng: 20.3988032 },
    { lat: 49.0084484, lng: 20.4007945 },
    { lat: 49.0098491, lng: 20.4011192 },
    { lat: 49.0105803, lng: 20.4015774 },
    { lat: 49.0113921, lng: 20.4018969 },
    { lat: 49.012415, lng: 20.401973 },
    { lat: 49.0131519, lng: 20.4016588 },
    { lat: 49.0144415, lng: 20.4001324 },
    { lat: 49.0153957, lng: 20.400529 },
    { lat: 49.0160655, lng: 20.4009371 },
    { lat: 49.0162727, lng: 20.4012665 },
    { lat: 49.016281, lng: 20.4017816 },
    { lat: 49.0161848, lng: 20.4035112 },
    { lat: 49.016001, lng: 20.4042135 },
    { lat: 49.0158445, lng: 20.4057235 },
    { lat: 49.0157441, lng: 20.4086171 },
  ],
};

export default UnitsPoprad;
