const UnitsLučenec = {
  Buzitka: [
    { lat: 48.328639, lng: 19.863274 },
    { lat: 48.330959, lng: 19.862682 },
    { lat: 48.332213, lng: 19.862202 },
    { lat: 48.332561, lng: 19.862085 },
    { lat: 48.333544, lng: 19.861993 },
    { lat: 48.3268954, lng: 19.8360616 },
    { lat: 48.3261078, lng: 19.8325678 },
    { lat: 48.3261139, lng: 19.8308498 },
    { lat: 48.3262297, lng: 19.8309433 },
    { lat: 48.3274018, lng: 19.8310822 },
    { lat: 48.3290946, lng: 19.8310976 },
    { lat: 48.3294945, lng: 19.831122 },
    { lat: 48.3317258, lng: 19.8312317 },
    { lat: 48.332244, lng: 19.8316874 },
    { lat: 48.3338648, lng: 19.8348176 },
    { lat: 48.3351758, lng: 19.8311395 },
    { lat: 48.3351808, lng: 19.8310501 },
    { lat: 48.3341828, lng: 19.8302256 },
    { lat: 48.336483, lng: 19.8242251 },
    { lat: 48.3354682, lng: 19.823044 },
    { lat: 48.335443, lng: 19.8187058 },
    { lat: 48.3358851, lng: 19.8174789 },
    { lat: 48.3392555, lng: 19.8155072 },
    { lat: 48.3397973, lng: 19.8146043 },
    { lat: 48.3397514, lng: 19.8064269 },
    { lat: 48.339758, lng: 19.8060594 },
    { lat: 48.339816, lng: 19.8059981 },
    { lat: 48.3451627, lng: 19.8134771 },
    { lat: 48.346333, lng: 19.8144474 },
    { lat: 48.3468926, lng: 19.815033 },
    { lat: 48.3470805, lng: 19.8150761 },
    { lat: 48.3473353, lng: 19.8148223 },
    { lat: 48.3474457, lng: 19.814589 },
    { lat: 48.3475795, lng: 19.8144586 },
    { lat: 48.3477953, lng: 19.8143934 },
    { lat: 48.3481151, lng: 19.8140556 },
    { lat: 48.3482067, lng: 19.8142676 },
    { lat: 48.3485808, lng: 19.8138057 },
    { lat: 48.348904, lng: 19.8138001 },
    { lat: 48.3492871, lng: 19.8133891 },
    { lat: 48.3493911, lng: 19.8134784 },
    { lat: 48.349815, lng: 19.8121956 },
    { lat: 48.3501404, lng: 19.8117279 },
    { lat: 48.3502348, lng: 19.8111278 },
    { lat: 48.3500158, lng: 19.8109149 },
    { lat: 48.350013, lng: 19.8105146 },
    { lat: 48.3502035, lng: 19.8104388 },
    { lat: 48.3503496, lng: 19.809841 },
    { lat: 48.3589046, lng: 19.80915 },
    { lat: 48.359203, lng: 19.8091235 },
    { lat: 48.3597311, lng: 19.8011388 },
    { lat: 48.3604134, lng: 19.7981239 },
    { lat: 48.3602377, lng: 19.795401 },
    { lat: 48.3602347, lng: 19.7952575 },
    { lat: 48.3594278, lng: 19.7945596 },
    { lat: 48.358705, lng: 19.7938028 },
    { lat: 48.3533453, lng: 19.7890833 },
    { lat: 48.3517165, lng: 19.7875524 },
    { lat: 48.3507504, lng: 19.7870537 },
    { lat: 48.3507011, lng: 19.7870061 },
    { lat: 48.3491946, lng: 19.7888755 },
    { lat: 48.3486699, lng: 19.7892165 },
    { lat: 48.3468098, lng: 19.7909369 },
    { lat: 48.3467362, lng: 19.7910798 },
    { lat: 48.3466491, lng: 19.7910215 },
    { lat: 48.3464926, lng: 19.7912041 },
    { lat: 48.3463311, lng: 19.7912187 },
    { lat: 48.3460024, lng: 19.7916107 },
    { lat: 48.3459852, lng: 19.7917353 },
    { lat: 48.3458907, lng: 19.79174 },
    { lat: 48.3452054, lng: 19.7921924 },
    { lat: 48.3450849, lng: 19.7921416 },
    { lat: 48.3449761, lng: 19.7922827 },
    { lat: 48.3448237, lng: 19.7923495 },
    { lat: 48.3446646, lng: 19.7922685 },
    { lat: 48.3444611, lng: 19.7926357 },
    { lat: 48.343963, lng: 19.792765 },
    { lat: 48.3435754, lng: 19.7927891 },
    { lat: 48.3409224, lng: 19.7944392 },
    { lat: 48.3403419, lng: 19.7947402 },
    { lat: 48.3385662, lng: 19.7962779 },
    { lat: 48.3377583, lng: 19.7967366 },
    { lat: 48.3368552, lng: 19.7967787 },
    { lat: 48.335916, lng: 19.7970945 },
    { lat: 48.3346037, lng: 19.7977924 },
    { lat: 48.332765, lng: 19.7979474 },
    { lat: 48.3314732, lng: 19.7975746 },
    { lat: 48.3305513, lng: 19.7872321 },
    { lat: 48.319533, lng: 19.7865306 },
    { lat: 48.3195825, lng: 19.7871459 },
    { lat: 48.3186122, lng: 19.7881874 },
    { lat: 48.3159353, lng: 19.7894764 },
    { lat: 48.3146475, lng: 19.7885001 },
    { lat: 48.3144951, lng: 19.7885679 },
    { lat: 48.3134039, lng: 19.7879198 },
    { lat: 48.3129244, lng: 19.7879314 },
    { lat: 48.311701, lng: 19.7883391 },
    { lat: 48.311591, lng: 19.7885804 },
    { lat: 48.3115267, lng: 19.7885035 },
    { lat: 48.3113818, lng: 19.7885122 },
    { lat: 48.311104, lng: 19.7888292 },
    { lat: 48.3108852, lng: 19.7887245 },
    { lat: 48.3106261, lng: 19.7888286 },
    { lat: 48.3105025, lng: 19.7886536 },
    { lat: 48.3104438, lng: 19.7886513 },
    { lat: 48.3102757, lng: 19.7889564 },
    { lat: 48.3099965, lng: 19.7891906 },
    { lat: 48.3098122, lng: 19.7892517 },
    { lat: 48.3096535, lng: 19.7894387 },
    { lat: 48.3095881, lng: 19.7894596 },
    { lat: 48.309572, lng: 19.789399 },
    { lat: 48.3094328, lng: 19.7893646 },
    { lat: 48.3092323, lng: 19.7893901 },
    { lat: 48.3090021, lng: 19.7896096 },
    { lat: 48.30864, lng: 19.789702 },
    { lat: 48.3085953, lng: 19.7898122 },
    { lat: 48.3080882, lng: 19.7901462 },
    { lat: 48.3078946, lng: 19.7902014 },
    { lat: 48.3077194, lng: 19.7901094 },
    { lat: 48.3076142, lng: 19.7902284 },
    { lat: 48.3074808, lng: 19.7902451 },
    { lat: 48.3071966, lng: 19.7905733 },
    { lat: 48.3068117, lng: 19.7908361 },
    { lat: 48.3066823, lng: 19.7908411 },
    { lat: 48.3066438, lng: 19.7907104 },
    { lat: 48.3063829, lng: 19.7906417 },
    { lat: 48.3056126, lng: 19.7951655 },
    { lat: 48.3063372, lng: 19.7961178 },
    { lat: 48.3050998, lng: 19.7982125 },
    { lat: 48.3047578, lng: 19.7999671 },
    { lat: 48.3044897, lng: 19.8016653 },
    { lat: 48.3047844, lng: 19.8017788 },
    { lat: 48.3023667, lng: 19.8062009 },
    { lat: 48.3018796, lng: 19.80655 },
    { lat: 48.3011555, lng: 19.8076146 },
    { lat: 48.3002985, lng: 19.8079552 },
    { lat: 48.2995314, lng: 19.8080629 },
    { lat: 48.2983381, lng: 19.8080332 },
    { lat: 48.2970967, lng: 19.8074721 },
    { lat: 48.2966258, lng: 19.8073186 },
    { lat: 48.2966013, lng: 19.8077353 },
    { lat: 48.2962391, lng: 19.8071844 },
    { lat: 48.2959404, lng: 19.8070546 },
    { lat: 48.2956608, lng: 19.8067944 },
    { lat: 48.293997, lng: 19.8085472 },
    { lat: 48.2939192, lng: 19.8088248 },
    { lat: 48.2940362, lng: 19.8088996 },
    { lat: 48.2940346, lng: 19.8091481 },
    { lat: 48.2940169, lng: 19.8145528 },
    { lat: 48.2943873, lng: 19.8145741 },
    { lat: 48.2945539, lng: 19.8143673 },
    { lat: 48.2949783, lng: 19.8132766 },
    { lat: 48.2953693, lng: 19.8126833 },
    { lat: 48.2966621, lng: 19.815218 },
    { lat: 48.2971516, lng: 19.8155651 },
    { lat: 48.2995081, lng: 19.815194 },
    { lat: 48.3003557, lng: 19.8148931 },
    { lat: 48.3008344, lng: 19.8144557 },
    { lat: 48.3008714, lng: 19.8145281 },
    { lat: 48.3009158, lng: 19.8144852 },
    { lat: 48.3010175, lng: 19.814701 },
    { lat: 48.3020707, lng: 19.8135624 },
    { lat: 48.3023405, lng: 19.8137559 },
    { lat: 48.3025337, lng: 19.8143084 },
    { lat: 48.3027185, lng: 19.8145153 },
    { lat: 48.3033054, lng: 19.815493 },
    { lat: 48.3034471, lng: 19.8159996 },
    { lat: 48.3036483, lng: 19.8163747 },
    { lat: 48.3068494, lng: 19.8131093 },
    { lat: 48.3095941, lng: 19.8113653 },
    { lat: 48.3106949, lng: 19.8121888 },
    { lat: 48.3132171, lng: 19.8124655 },
    { lat: 48.3158518, lng: 19.8144596 },
    { lat: 48.3162448, lng: 19.8147628 },
    { lat: 48.3163066, lng: 19.8149401 },
    { lat: 48.3165852, lng: 19.8150766 },
    { lat: 48.3170222, lng: 19.8154849 },
    { lat: 48.3177593, lng: 19.8163664 },
    { lat: 48.3181719, lng: 19.8164242 },
    { lat: 48.3191878, lng: 19.817806 },
    { lat: 48.3193753, lng: 19.8181519 },
    { lat: 48.3197094, lng: 19.8184762 },
    { lat: 48.3202494, lng: 19.8192065 },
    { lat: 48.3215249, lng: 19.8200323 },
    { lat: 48.3216222, lng: 19.8201944 },
    { lat: 48.3221417, lng: 19.820662 },
    { lat: 48.3226708, lng: 19.821273 },
    { lat: 48.3228025, lng: 19.8218594 },
    { lat: 48.322904, lng: 19.8228693 },
    { lat: 48.3228243, lng: 19.8229058 },
    { lat: 48.3228582, lng: 19.8237846 },
    { lat: 48.3230816, lng: 19.8246166 },
    { lat: 48.3234905, lng: 19.8256459 },
    { lat: 48.3228918, lng: 19.8273387 },
    { lat: 48.3226124, lng: 19.8279852 },
    { lat: 48.3225471, lng: 19.8285017 },
    { lat: 48.3223522, lng: 19.8290929 },
    { lat: 48.3214431, lng: 19.8310596 },
    { lat: 48.3219583, lng: 19.8325384 },
    { lat: 48.322106, lng: 19.8328014 },
    { lat: 48.3222021, lng: 19.8331212 },
    { lat: 48.3224801, lng: 19.8336069 },
    { lat: 48.3230515, lng: 19.8358837 },
    { lat: 48.3234653, lng: 19.8385548 },
    { lat: 48.3242272, lng: 19.8407615 },
    { lat: 48.3245843, lng: 19.84146 },
    { lat: 48.3247587, lng: 19.8421538 },
    { lat: 48.325032, lng: 19.8429207 },
    { lat: 48.3253445, lng: 19.8440425 },
    { lat: 48.325862, lng: 19.847366 },
    { lat: 48.3260089, lng: 19.8496476 },
    { lat: 48.3260175, lng: 19.8497814 },
    { lat: 48.3261564, lng: 19.8503694 },
    { lat: 48.3266348, lng: 19.852395 },
    { lat: 48.3272003, lng: 19.853821 },
    { lat: 48.3272343, lng: 19.8552287 },
    { lat: 48.3272472, lng: 19.85576 },
    { lat: 48.3271572, lng: 19.8597374 },
    { lat: 48.3278016, lng: 19.8618929 },
    { lat: 48.328639, lng: 19.863274 },
  ],
  GregorovaVieska: [
    { lat: 48.4043405, lng: 19.5548318 },
    { lat: 48.4038249, lng: 19.5551587 },
    { lat: 48.4036864, lng: 19.5551358 },
    { lat: 48.4035537, lng: 19.5552652 },
    { lat: 48.4029592, lng: 19.5554974 },
    { lat: 48.4026686, lng: 19.5554998 },
    { lat: 48.4024224, lng: 19.5551225 },
    { lat: 48.4004625, lng: 19.5550405 },
    { lat: 48.3994864, lng: 19.5548899 },
    { lat: 48.3990806, lng: 19.5547175 },
    { lat: 48.3988988, lng: 19.5547331 },
    { lat: 48.3987518, lng: 19.5545767 },
    { lat: 48.3982846, lng: 19.5544854 },
    { lat: 48.398159, lng: 19.554574 },
    { lat: 48.397928, lng: 19.5548915 },
    { lat: 48.3976041, lng: 19.5554936 },
    { lat: 48.3973843, lng: 19.5556908 },
    { lat: 48.3969628, lng: 19.5558611 },
    { lat: 48.3966273, lng: 19.555698 },
    { lat: 48.3963342, lng: 19.5558195 },
    { lat: 48.3960669, lng: 19.5557383 },
    { lat: 48.3954682, lng: 19.5551657 },
    { lat: 48.3949292, lng: 19.5548187 },
    { lat: 48.3947719, lng: 19.5548549 },
    { lat: 48.3940445, lng: 19.5553561 },
    { lat: 48.3937394, lng: 19.5558278 },
    { lat: 48.3928536, lng: 19.5562883 },
    { lat: 48.3920762, lng: 19.5563653 },
    { lat: 48.3913806, lng: 19.556803 },
    { lat: 48.3904084, lng: 19.5578631 },
    { lat: 48.3899234, lng: 19.5588603 },
    { lat: 48.3896959, lng: 19.559916 },
    { lat: 48.3886862, lng: 19.5615879 },
    { lat: 48.3883495, lng: 19.5623301 },
    { lat: 48.3878976, lng: 19.5627686 },
    { lat: 48.3874153, lng: 19.5638506 },
    { lat: 48.3868078, lng: 19.5643371 },
    { lat: 48.386362, lng: 19.5651553 },
    { lat: 48.3859116, lng: 19.5657046 },
    { lat: 48.385348, lng: 19.5666836 },
    { lat: 48.3850898, lng: 19.5670272 },
    { lat: 48.3846731, lng: 19.5673277 },
    { lat: 48.3845516, lng: 19.567504 },
    { lat: 48.3841912, lng: 19.5675296 },
    { lat: 48.3841215, lng: 19.5676112 },
    { lat: 48.3835636, lng: 19.5674846 },
    { lat: 48.383332, lng: 19.5673454 },
    { lat: 48.3831428, lng: 19.5673239 },
    { lat: 48.3829938, lng: 19.5673705 },
    { lat: 48.3829422, lng: 19.5674506 },
    { lat: 48.382851, lng: 19.5674228 },
    { lat: 48.3824663, lng: 19.5677628 },
    { lat: 48.3818464, lng: 19.5680027 },
    { lat: 48.3817531, lng: 19.5681772 },
    { lat: 48.3814361, lng: 19.5683929 },
    { lat: 48.3812021, lng: 19.568527 },
    { lat: 48.381024, lng: 19.5685 },
    { lat: 48.3808502, lng: 19.5686633 },
    { lat: 48.3805336, lng: 19.5692054 },
    { lat: 48.3805223, lng: 19.5693757 },
    { lat: 48.3804806, lng: 19.5694289 },
    { lat: 48.3804987, lng: 19.5696489 },
    { lat: 48.3802806, lng: 19.5700522 },
    { lat: 48.3803014, lng: 19.5707285 },
    { lat: 48.3803708, lng: 19.5709907 },
    { lat: 48.380097, lng: 19.5720243 },
    { lat: 48.3795636, lng: 19.5736876 },
    { lat: 48.3791368, lng: 19.5746746 },
    { lat: 48.3780993, lng: 19.576162 },
    { lat: 48.377922, lng: 19.5763025 },
    { lat: 48.3778082, lng: 19.5762778 },
    { lat: 48.3777879, lng: 19.5766173 },
    { lat: 48.3774914, lng: 19.576913 },
    { lat: 48.377434, lng: 19.5771093 },
    { lat: 48.3772623, lng: 19.5772259 },
    { lat: 48.3771796, lng: 19.5777044 },
    { lat: 48.377081, lng: 19.5776958 },
    { lat: 48.3765826, lng: 19.5783013 },
    { lat: 48.3765614, lng: 19.5786015 },
    { lat: 48.3764891, lng: 19.5786728 },
    { lat: 48.3764618, lng: 19.5787901 },
    { lat: 48.3763684, lng: 19.5787448 },
    { lat: 48.3763533, lng: 19.5788793 },
    { lat: 48.3763856, lng: 19.5789245 },
    { lat: 48.37628, lng: 19.5792235 },
    { lat: 48.3762963, lng: 19.5793626 },
    { lat: 48.376251, lng: 19.5794038 },
    { lat: 48.3762305, lng: 19.5796487 },
    { lat: 48.3761536, lng: 19.5796658 },
    { lat: 48.3760602, lng: 19.5797906 },
    { lat: 48.3760631, lng: 19.5799002 },
    { lat: 48.3760194, lng: 19.5799579 },
    { lat: 48.3759404, lng: 19.5800064 },
    { lat: 48.3758779, lng: 19.5799668 },
    { lat: 48.3758232, lng: 19.5800777 },
    { lat: 48.3757373, lng: 19.5801199 },
    { lat: 48.375698, lng: 19.5803969 },
    { lat: 48.3757742, lng: 19.5804624 },
    { lat: 48.3756995, lng: 19.5805264 },
    { lat: 48.3757156, lng: 19.5806095 },
    { lat: 48.3755877, lng: 19.580695 },
    { lat: 48.3756458, lng: 19.5809279 },
    { lat: 48.3756273, lng: 19.5810914 },
    { lat: 48.375441, lng: 19.5812808 },
    { lat: 48.3754344, lng: 19.5815047 },
    { lat: 48.3753815, lng: 19.5815081 },
    { lat: 48.3753623, lng: 19.5816057 },
    { lat: 48.375235, lng: 19.5814813 },
    { lat: 48.3750891, lng: 19.5817913 },
    { lat: 48.3751145, lng: 19.5818766 },
    { lat: 48.3748924, lng: 19.5820745 },
    { lat: 48.3748321, lng: 19.5819851 },
    { lat: 48.3747719, lng: 19.5821968 },
    { lat: 48.3746971, lng: 19.5822871 },
    { lat: 48.3746721, lng: 19.5836139 },
    { lat: 48.3743329, lng: 19.5899233 },
    { lat: 48.3752454, lng: 19.5906606 },
    { lat: 48.3756375, lng: 19.5909789 },
    { lat: 48.3783964, lng: 19.5940234 },
    { lat: 48.3804683, lng: 19.5959651 },
    { lat: 48.3811664, lng: 19.5966916 },
    { lat: 48.3818041, lng: 19.5975293 },
    { lat: 48.3834304, lng: 19.5999074 },
    { lat: 48.3840435, lng: 19.6010961 },
    { lat: 48.3864376, lng: 19.6049469 },
    { lat: 48.3863809, lng: 19.6055589 },
    { lat: 48.3871394, lng: 19.60514 },
    { lat: 48.3872661, lng: 19.6048771 },
    { lat: 48.3873769, lng: 19.6049363 },
    { lat: 48.3875465, lng: 19.6046998 },
    { lat: 48.3875837, lng: 19.6047272 },
    { lat: 48.3876804, lng: 19.6046467 },
    { lat: 48.3876865, lng: 19.6045074 },
    { lat: 48.3875091, lng: 19.6040999 },
    { lat: 48.387558, lng: 19.6039399 },
    { lat: 48.3875275, lng: 19.6037371 },
    { lat: 48.3877157, lng: 19.6030732 },
    { lat: 48.3875873, lng: 19.6028266 },
    { lat: 48.3875431, lng: 19.6026489 },
    { lat: 48.3875674, lng: 19.6025393 },
    { lat: 48.3875078, lng: 19.6024192 },
    { lat: 48.3875629, lng: 19.6023377 },
    { lat: 48.3875676, lng: 19.6018498 },
    { lat: 48.3875104, lng: 19.6017798 },
    { lat: 48.3875469, lng: 19.6017416 },
    { lat: 48.3875097, lng: 19.601542 },
    { lat: 48.3876057, lng: 19.6012751 },
    { lat: 48.3874803, lng: 19.6011919 },
    { lat: 48.3874673, lng: 19.6010847 },
    { lat: 48.3875742, lng: 19.6009326 },
    { lat: 48.3876152, lng: 19.6007437 },
    { lat: 48.3875741, lng: 19.6007334 },
    { lat: 48.387591, lng: 19.6005814 },
    { lat: 48.3876764, lng: 19.600501 },
    { lat: 48.3877598, lng: 19.6005537 },
    { lat: 48.3877395, lng: 19.6001552 },
    { lat: 48.387644, lng: 19.6000137 },
    { lat: 48.3876355, lng: 19.599877 },
    { lat: 48.387579, lng: 19.5998498 },
    { lat: 48.3874696, lng: 19.599575 },
    { lat: 48.3873591, lng: 19.5995491 },
    { lat: 48.3872961, lng: 19.5993783 },
    { lat: 48.3872098, lng: 19.5994379 },
    { lat: 48.3871355, lng: 19.5993168 },
    { lat: 48.3871775, lng: 19.5992476 },
    { lat: 48.3871425, lng: 19.5990498 },
    { lat: 48.3872882, lng: 19.5988587 },
    { lat: 48.3872511, lng: 19.5988118 },
    { lat: 48.3872895, lng: 19.5986905 },
    { lat: 48.3872016, lng: 19.598663 },
    { lat: 48.3872634, lng: 19.5985117 },
    { lat: 48.3873155, lng: 19.59854 },
    { lat: 48.3874292, lng: 19.5983936 },
    { lat: 48.3873716, lng: 19.5980932 },
    { lat: 48.3874527, lng: 19.5979452 },
    { lat: 48.3874037, lng: 19.5978591 },
    { lat: 48.3875317, lng: 19.5976279 },
    { lat: 48.3875085, lng: 19.5975116 },
    { lat: 48.3874294, lng: 19.5975066 },
    { lat: 48.3874286, lng: 19.5973659 },
    { lat: 48.3871159, lng: 19.5969588 },
    { lat: 48.3870853, lng: 19.5967333 },
    { lat: 48.3871297, lng: 19.5966419 },
    { lat: 48.3870999, lng: 19.5966088 },
    { lat: 48.3872222, lng: 19.5965466 },
    { lat: 48.3872291, lng: 19.5964491 },
    { lat: 48.3871886, lng: 19.5964522 },
    { lat: 48.3870738, lng: 19.5962594 },
    { lat: 48.3870783, lng: 19.5960377 },
    { lat: 48.3870034, lng: 19.5959994 },
    { lat: 48.3869766, lng: 19.5959039 },
    { lat: 48.3870113, lng: 19.5958255 },
    { lat: 48.3871033, lng: 19.5958395 },
    { lat: 48.387232, lng: 19.595727 },
    { lat: 48.3872381, lng: 19.5956374 },
    { lat: 48.3873002, lng: 19.5956618 },
    { lat: 48.387335, lng: 19.5957586 },
    { lat: 48.3873753, lng: 19.5957246 },
    { lat: 48.3873509, lng: 19.5954903 },
    { lat: 48.3872749, lng: 19.5954755 },
    { lat: 48.3872437, lng: 19.5953879 },
    { lat: 48.3872694, lng: 19.5953349 },
    { lat: 48.387421, lng: 19.5953183 },
    { lat: 48.3874431, lng: 19.5952401 },
    { lat: 48.387521, lng: 19.5952651 },
    { lat: 48.3874687, lng: 19.5951801 },
    { lat: 48.3875083, lng: 19.5950849 },
    { lat: 48.3874911, lng: 19.594981 },
    { lat: 48.3875676, lng: 19.5949554 },
    { lat: 48.3876191, lng: 19.5948228 },
    { lat: 48.3875673, lng: 19.5947466 },
    { lat: 48.3877489, lng: 19.5944837 },
    { lat: 48.3876762, lng: 19.5942983 },
    { lat: 48.3876767, lng: 19.5941855 },
    { lat: 48.3877649, lng: 19.5940417 },
    { lat: 48.3877032, lng: 19.5940261 },
    { lat: 48.3877295, lng: 19.5938564 },
    { lat: 48.387689, lng: 19.5937868 },
    { lat: 48.3877724, lng: 19.5936903 },
    { lat: 48.3878801, lng: 19.5937264 },
    { lat: 48.3880125, lng: 19.5935898 },
    { lat: 48.3879736, lng: 19.5934307 },
    { lat: 48.3881, lng: 19.5933868 },
    { lat: 48.3880683, lng: 19.5931118 },
    { lat: 48.3881656, lng: 19.5928721 },
    { lat: 48.388261, lng: 19.5928885 },
    { lat: 48.3883271, lng: 19.5930019 },
    { lat: 48.3884609, lng: 19.5927977 },
    { lat: 48.3887362, lng: 19.5919642 },
    { lat: 48.3888823, lng: 19.5916386 },
    { lat: 48.388951, lng: 19.5916121 },
    { lat: 48.3890375, lng: 19.5914589 },
    { lat: 48.3890022, lng: 19.5913526 },
    { lat: 48.389043, lng: 19.5911601 },
    { lat: 48.3889725, lng: 19.591021 },
    { lat: 48.3890909, lng: 19.5907284 },
    { lat: 48.3890109, lng: 19.5906022 },
    { lat: 48.3889761, lng: 19.590264 },
    { lat: 48.3890537, lng: 19.5901568 },
    { lat: 48.3891453, lng: 19.5901672 },
    { lat: 48.3891429, lng: 19.5899654 },
    { lat: 48.3890782, lng: 19.5899089 },
    { lat: 48.3890711, lng: 19.589726 },
    { lat: 48.3891611, lng: 19.5895529 },
    { lat: 48.3892805, lng: 19.5895758 },
    { lat: 48.3894347, lng: 19.5893957 },
    { lat: 48.3894877, lng: 19.5892002 },
    { lat: 48.3896152, lng: 19.5890308 },
    { lat: 48.3897017, lng: 19.5884585 },
    { lat: 48.3898517, lng: 19.5880141 },
    { lat: 48.3899092, lng: 19.5879904 },
    { lat: 48.3899874, lng: 19.5877547 },
    { lat: 48.3902205, lng: 19.5876308 },
    { lat: 48.3902996, lng: 19.5875113 },
    { lat: 48.390192, lng: 19.5870076 },
    { lat: 48.3904205, lng: 19.5864054 },
    { lat: 48.3905552, lng: 19.5857778 },
    { lat: 48.3907273, lng: 19.5854055 },
    { lat: 48.3906912, lng: 19.5851575 },
    { lat: 48.3908458, lng: 19.5848413 },
    { lat: 48.3908701, lng: 19.5844591 },
    { lat: 48.3908396, lng: 19.5843591 },
    { lat: 48.3908879, lng: 19.5841814 },
    { lat: 48.3910993, lng: 19.5838788 },
    { lat: 48.3911533, lng: 19.5837013 },
    { lat: 48.3911552, lng: 19.5834738 },
    { lat: 48.3910508, lng: 19.583085 },
    { lat: 48.3910423, lng: 19.5829508 },
    { lat: 48.3911061, lng: 19.5828187 },
    { lat: 48.3910273, lng: 19.5824024 },
    { lat: 48.3910703, lng: 19.5820094 },
    { lat: 48.3911363, lng: 19.5820278 },
    { lat: 48.3912112, lng: 19.5818385 },
    { lat: 48.3911366, lng: 19.581492 },
    { lat: 48.3912279, lng: 19.5811447 },
    { lat: 48.3911807, lng: 19.5809738 },
    { lat: 48.3912213, lng: 19.5801133 },
    { lat: 48.3911901, lng: 19.5795812 },
    { lat: 48.3912605, lng: 19.5793427 },
    { lat: 48.3912586, lng: 19.5791054 },
    { lat: 48.3911789, lng: 19.5790881 },
    { lat: 48.3911291, lng: 19.578912 },
    { lat: 48.3911254, lng: 19.5786823 },
    { lat: 48.390964, lng: 19.5780399 },
    { lat: 48.3910133, lng: 19.5777355 },
    { lat: 48.3909909, lng: 19.5774454 },
    { lat: 48.3911674, lng: 19.5767205 },
    { lat: 48.3914559, lng: 19.576055 },
    { lat: 48.3915928, lng: 19.5752336 },
    { lat: 48.3920653, lng: 19.574553 },
    { lat: 48.3922433, lng: 19.5741286 },
    { lat: 48.3922319, lng: 19.5736845 },
    { lat: 48.3926506, lng: 19.5735058 },
    { lat: 48.3928926, lng: 19.5732266 },
    { lat: 48.3928812, lng: 19.5730739 },
    { lat: 48.3931608, lng: 19.5726617 },
    { lat: 48.393212, lng: 19.5722295 },
    { lat: 48.3933431, lng: 19.5722599 },
    { lat: 48.3933902, lng: 19.5722068 },
    { lat: 48.393491, lng: 19.5717518 },
    { lat: 48.3935792, lng: 19.5717352 },
    { lat: 48.3936245, lng: 19.5715884 },
    { lat: 48.3939029, lng: 19.5714199 },
    { lat: 48.3940901, lng: 19.571424 },
    { lat: 48.3942338, lng: 19.571313 },
    { lat: 48.394631, lng: 19.5711791 },
    { lat: 48.3947657, lng: 19.5709446 },
    { lat: 48.3952877, lng: 19.5704322 },
    { lat: 48.3953615, lng: 19.5704441 },
    { lat: 48.3954952, lng: 19.5702147 },
    { lat: 48.3960177, lng: 19.5697898 },
    { lat: 48.3961622, lng: 19.5693529 },
    { lat: 48.3963966, lng: 19.5691797 },
    { lat: 48.3967469, lng: 19.5683634 },
    { lat: 48.3971758, lng: 19.5680364 },
    { lat: 48.3980063, lng: 19.5671293 },
    { lat: 48.3981716, lng: 19.5666757 },
    { lat: 48.3983602, lng: 19.566392 },
    { lat: 48.3984319, lng: 19.5660665 },
    { lat: 48.3993345, lng: 19.5649093 },
    { lat: 48.3994865, lng: 19.5647844 },
    { lat: 48.3995939, lng: 19.5645201 },
    { lat: 48.3999363, lng: 19.5639492 },
    { lat: 48.4005888, lng: 19.563754 },
    { lat: 48.4007184, lng: 19.5635845 },
    { lat: 48.4007168, lng: 19.5632553 },
    { lat: 48.4008715, lng: 19.5627211 },
    { lat: 48.4008695, lng: 19.5621852 },
    { lat: 48.400951, lng: 19.5619742 },
    { lat: 48.4009761, lng: 19.5617344 },
    { lat: 48.4009221, lng: 19.5611694 },
    { lat: 48.4010117, lng: 19.5606941 },
    { lat: 48.4012189, lng: 19.5602993 },
    { lat: 48.4014347, lng: 19.5588861 },
    { lat: 48.4019903, lng: 19.5578557 },
    { lat: 48.402503, lng: 19.5572664 },
    { lat: 48.4028489, lng: 19.5566795 },
    { lat: 48.4043405, lng: 19.5548318 },
  ],
  Belina: [
    { lat: 48.253524, lng: 19.8617087 },
    { lat: 48.2532323, lng: 19.860287 },
    { lat: 48.2530607, lng: 19.8589827 },
    { lat: 48.2525083, lng: 19.857129 },
    { lat: 48.252523, lng: 19.8562774 },
    { lat: 48.252477, lng: 19.8561009 },
    { lat: 48.2518123, lng: 19.854805 },
    { lat: 48.2516133, lng: 19.8545009 },
    { lat: 48.2513862, lng: 19.8543541 },
    { lat: 48.2511296, lng: 19.8528895 },
    { lat: 48.2507908, lng: 19.8508468 },
    { lat: 48.2507987, lng: 19.8507686 },
    { lat: 48.2505999, lng: 19.8504741 },
    { lat: 48.2499161, lng: 19.8495655 },
    { lat: 48.249825, lng: 19.8493111 },
    { lat: 48.2497646, lng: 19.8487531 },
    { lat: 48.2495742, lng: 19.8483924 },
    { lat: 48.2494038, lng: 19.8481496 },
    { lat: 48.2491247, lng: 19.8479485 },
    { lat: 48.2487784, lng: 19.8478893 },
    { lat: 48.2485049, lng: 19.8477145 },
    { lat: 48.2482449, lng: 19.8473313 },
    { lat: 48.2481757, lng: 19.8469078 },
    { lat: 48.2479627, lng: 19.8464896 },
    { lat: 48.2476157, lng: 19.8463114 },
    { lat: 48.247071, lng: 19.8464306 },
    { lat: 48.2461025, lng: 19.8464608 },
    { lat: 48.2451519, lng: 19.8467286 },
    { lat: 48.2449194, lng: 19.846664 },
    { lat: 48.2446897, lng: 19.8465142 },
    { lat: 48.2443697, lng: 19.8461259 },
    { lat: 48.2441618, lng: 19.8455418 },
    { lat: 48.244119, lng: 19.8455672 },
    { lat: 48.2441616, lng: 19.8454411 },
    { lat: 48.2443994, lng: 19.8447364 },
    { lat: 48.244147, lng: 19.8441189 },
    { lat: 48.2436416, lng: 19.84366 },
    { lat: 48.242851, lng: 19.843178 },
    { lat: 48.242241, lng: 19.8427251 },
    { lat: 48.2413789, lng: 19.8378372 },
    { lat: 48.2395166, lng: 19.8382264 },
    { lat: 48.237858, lng: 19.8375742 },
    { lat: 48.2375769, lng: 19.837408 },
    { lat: 48.2363511, lng: 19.8364012 },
    { lat: 48.2358585, lng: 19.8360837 },
    { lat: 48.2348227, lng: 19.8359188 },
    { lat: 48.2345616, lng: 19.8358235 },
    { lat: 48.2340421, lng: 19.8354859 },
    { lat: 48.2337757, lng: 19.8351105 },
    { lat: 48.2330936, lng: 19.8332697 },
    { lat: 48.2328132, lng: 19.8330116 },
    { lat: 48.2303375, lng: 19.8322323 },
    { lat: 48.2303338, lng: 19.8322977 },
    { lat: 48.2298623, lng: 19.8324664 },
    { lat: 48.2293164, lng: 19.8325297 },
    { lat: 48.2291904, lng: 19.8351674 },
    { lat: 48.2292001, lng: 19.8357753 },
    { lat: 48.2291303, lng: 19.8360083 },
    { lat: 48.2291447, lng: 19.8393856 },
    { lat: 48.2274642, lng: 19.8393183 },
    { lat: 48.2273652, lng: 19.838651 },
    { lat: 48.2269902, lng: 19.8361224 },
    { lat: 48.2263442, lng: 19.8358022 },
    { lat: 48.2260335, lng: 19.8373869 },
    { lat: 48.2261727, lng: 19.8374005 },
    { lat: 48.226093, lng: 19.8378206 },
    { lat: 48.2257984, lng: 19.8377014 },
    { lat: 48.2250428, lng: 19.8401124 },
    { lat: 48.2244672, lng: 19.8409406 },
    { lat: 48.2236557, lng: 19.843522 },
    { lat: 48.2231977, lng: 19.8436084 },
    { lat: 48.222429, lng: 19.8445345 },
    { lat: 48.2214357, lng: 19.846476 },
    { lat: 48.2197487, lng: 19.8471397 },
    { lat: 48.2188628, lng: 19.8515545 },
    { lat: 48.2207815, lng: 19.853189 },
    { lat: 48.2204995, lng: 19.858491 },
    { lat: 48.2200827, lng: 19.8598676 },
    { lat: 48.2202968, lng: 19.8611928 },
    { lat: 48.2199761, lng: 19.8632528 },
    { lat: 48.2199023, lng: 19.8640425 },
    { lat: 48.2199255, lng: 19.8652234 },
    { lat: 48.2201842, lng: 19.8668763 },
    { lat: 48.2205424, lng: 19.8679946 },
    { lat: 48.2206942, lng: 19.8682173 },
    { lat: 48.2210273, lng: 19.8683905 },
    { lat: 48.2213262, lng: 19.8683219 },
    { lat: 48.2222934, lng: 19.868104 },
    { lat: 48.2230415, lng: 19.867193 },
    { lat: 48.224978, lng: 19.8651669 },
    { lat: 48.2272741, lng: 19.8633503 },
    { lat: 48.2293848, lng: 19.861962 },
    { lat: 48.2309756, lng: 19.8615441 },
    { lat: 48.2322391, lng: 19.861431 },
    { lat: 48.2333793, lng: 19.8614293 },
    { lat: 48.2346441, lng: 19.8612409 },
    { lat: 48.2356004, lng: 19.861468 },
    { lat: 48.2367526, lng: 19.8619814 },
    { lat: 48.2376278, lng: 19.8647564 },
    { lat: 48.2380453, lng: 19.8656533 },
    { lat: 48.2388154, lng: 19.8662665 },
    { lat: 48.2395731, lng: 19.8666181 },
    { lat: 48.2401167, lng: 19.8670953 },
    { lat: 48.2411036, lng: 19.8675925 },
    { lat: 48.2416253, lng: 19.8680599 },
    { lat: 48.2425139, lng: 19.8685716 },
    { lat: 48.2441092, lng: 19.8686509 },
    { lat: 48.2451057, lng: 19.8685371 },
    { lat: 48.2455131, lng: 19.8686273 },
    { lat: 48.2459663, lng: 19.8686355 },
    { lat: 48.2472873, lng: 19.8684701 },
    { lat: 48.2480604, lng: 19.8679308 },
    { lat: 48.2493186, lng: 19.8666488 },
    { lat: 48.2502804, lng: 19.8658091 },
    { lat: 48.2508709, lng: 19.8652936 },
    { lat: 48.2525874, lng: 19.8629477 },
    { lat: 48.253524, lng: 19.8617087 },
  ],
  Biskupice: [
    { lat: 48.2332758, lng: 19.8083207 },
    { lat: 48.2328596, lng: 19.8088277 },
    { lat: 48.2323735, lng: 19.8091515 },
    { lat: 48.2317396, lng: 19.8093873 },
    { lat: 48.2302217, lng: 19.810387 },
    { lat: 48.2299847, lng: 19.8111467 },
    { lat: 48.2295753, lng: 19.8117021 },
    { lat: 48.22904, lng: 19.8121077 },
    { lat: 48.2271154, lng: 19.8130017 },
    { lat: 48.2272437, lng: 19.8149278 },
    { lat: 48.2273332, lng: 19.8153264 },
    { lat: 48.2275811, lng: 19.8159134 },
    { lat: 48.2276339, lng: 19.8161435 },
    { lat: 48.2275307, lng: 19.8166172 },
    { lat: 48.2275983, lng: 19.8167487 },
    { lat: 48.2276579, lng: 19.8170803 },
    { lat: 48.2277299, lng: 19.8171005 },
    { lat: 48.2279509, lng: 19.8178236 },
    { lat: 48.2279231, lng: 19.8182451 },
    { lat: 48.228103, lng: 19.8188804 },
    { lat: 48.2286419, lng: 19.8199416 },
    { lat: 48.2286527, lng: 19.8201653 },
    { lat: 48.2287064, lng: 19.8203084 },
    { lat: 48.2286948, lng: 19.8204799 },
    { lat: 48.2288686, lng: 19.8209014 },
    { lat: 48.2288718, lng: 19.8211268 },
    { lat: 48.2288291, lng: 19.8212066 },
    { lat: 48.2288288, lng: 19.8216381 },
    { lat: 48.2284277, lng: 19.8227163 },
    { lat: 48.2283211, lng: 19.8231398 },
    { lat: 48.2282662, lng: 19.824048 },
    { lat: 48.2283011, lng: 19.8245601 },
    { lat: 48.2282322, lng: 19.8255661 },
    { lat: 48.2282743, lng: 19.8266312 },
    { lat: 48.2280709, lng: 19.8270808 },
    { lat: 48.2279709, lng: 19.8276284 },
    { lat: 48.2276702, lng: 19.8283005 },
    { lat: 48.2276154, lng: 19.8285896 },
    { lat: 48.2274814, lng: 19.8288253 },
    { lat: 48.2269754, lng: 19.8306758 },
    { lat: 48.2268116, lng: 19.8342542 },
    { lat: 48.2269902, lng: 19.8361224 },
    { lat: 48.2273652, lng: 19.838651 },
    { lat: 48.2274642, lng: 19.8393183 },
    { lat: 48.2291447, lng: 19.8393856 },
    { lat: 48.2291303, lng: 19.8360083 },
    { lat: 48.2292001, lng: 19.8357753 },
    { lat: 48.2291904, lng: 19.8351674 },
    { lat: 48.2293164, lng: 19.8325297 },
    { lat: 48.2298623, lng: 19.8324664 },
    { lat: 48.2303338, lng: 19.8322977 },
    { lat: 48.2303375, lng: 19.8322323 },
    { lat: 48.2328132, lng: 19.8330116 },
    { lat: 48.2330936, lng: 19.8332697 },
    { lat: 48.2337757, lng: 19.8351105 },
    { lat: 48.2340421, lng: 19.8354859 },
    { lat: 48.2345616, lng: 19.8358235 },
    { lat: 48.2348227, lng: 19.8359188 },
    { lat: 48.2358585, lng: 19.8360837 },
    { lat: 48.2363511, lng: 19.8364012 },
    { lat: 48.2375769, lng: 19.837408 },
    { lat: 48.237858, lng: 19.8375742 },
    { lat: 48.2395166, lng: 19.8382264 },
    { lat: 48.2413789, lng: 19.8378372 },
    { lat: 48.242241, lng: 19.8427251 },
    { lat: 48.242851, lng: 19.843178 },
    { lat: 48.2436416, lng: 19.84366 },
    { lat: 48.244147, lng: 19.8441189 },
    { lat: 48.2443994, lng: 19.8447364 },
    { lat: 48.2441616, lng: 19.8454411 },
    { lat: 48.244119, lng: 19.8455672 },
    { lat: 48.2441618, lng: 19.8455418 },
    { lat: 48.2443697, lng: 19.8461259 },
    { lat: 48.2446897, lng: 19.8465142 },
    { lat: 48.2449194, lng: 19.846664 },
    { lat: 48.2451519, lng: 19.8467286 },
    { lat: 48.2461025, lng: 19.8464608 },
    { lat: 48.247071, lng: 19.8464306 },
    { lat: 48.2476157, lng: 19.8463114 },
    { lat: 48.2479627, lng: 19.8464896 },
    { lat: 48.2481757, lng: 19.8469078 },
    { lat: 48.2482449, lng: 19.8473313 },
    { lat: 48.2485049, lng: 19.8477145 },
    { lat: 48.2487784, lng: 19.8478893 },
    { lat: 48.2491247, lng: 19.8479485 },
    { lat: 48.2494038, lng: 19.8481496 },
    { lat: 48.2495742, lng: 19.8483924 },
    { lat: 48.2497646, lng: 19.8487531 },
    { lat: 48.249825, lng: 19.8493111 },
    { lat: 48.2499161, lng: 19.8495655 },
    { lat: 48.2505999, lng: 19.8504741 },
    { lat: 48.2507987, lng: 19.8507686 },
    { lat: 48.2507908, lng: 19.8508468 },
    { lat: 48.2512678, lng: 19.8511146 },
    { lat: 48.2520362, lng: 19.8508593 },
    { lat: 48.2524123, lng: 19.8509298 },
    { lat: 48.2528018, lng: 19.8507939 },
    { lat: 48.2536265, lng: 19.8508432 },
    { lat: 48.2538952, lng: 19.8507042 },
    { lat: 48.2541118, lng: 19.8502935 },
    { lat: 48.2558174, lng: 19.848404 },
    { lat: 48.2568064, lng: 19.8471069 },
    { lat: 48.2585979, lng: 19.8450653 },
    { lat: 48.2594178, lng: 19.8444645 },
    { lat: 48.2600594, lng: 19.8441635 },
    { lat: 48.2611679, lng: 19.8433351 },
    { lat: 48.2622866, lng: 19.8427654 },
    { lat: 48.2632677, lng: 19.842036 },
    { lat: 48.2638978, lng: 19.8412174 },
    { lat: 48.2644982, lng: 19.8403087 },
    { lat: 48.2638959, lng: 19.8393881 },
    { lat: 48.262679, lng: 19.8366724 },
    { lat: 48.2610006, lng: 19.8339699 },
    { lat: 48.2599832, lng: 19.8321623 },
    { lat: 48.2578618, lng: 19.8287575 },
    { lat: 48.2576409, lng: 19.8286519 },
    { lat: 48.2551576, lng: 19.8252725 },
    { lat: 48.2544706, lng: 19.8241449 },
    { lat: 48.2543446, lng: 19.8238119 },
    { lat: 48.2539916, lng: 19.8232112 },
    { lat: 48.2535399, lng: 19.8222321 },
    { lat: 48.2533259, lng: 19.8219003 },
    { lat: 48.2530627, lng: 19.8215514 },
    { lat: 48.2512256, lng: 19.8199142 },
    { lat: 48.2501343, lng: 19.8187416 },
    { lat: 48.2487642, lng: 19.8169623 },
    { lat: 48.2484663, lng: 19.816461 },
    { lat: 48.2466722, lng: 19.8139334 },
    { lat: 48.2463689, lng: 19.8134655 },
    { lat: 48.2458227, lng: 19.81244 },
    { lat: 48.242703, lng: 19.8058166 },
    { lat: 48.2435911, lng: 19.8032812 },
    { lat: 48.2441007, lng: 19.8022141 },
    { lat: 48.2442598, lng: 19.8013766 },
    { lat: 48.2441511, lng: 19.8003846 },
    { lat: 48.2440561, lng: 19.8003498 },
    { lat: 48.2430005, lng: 19.8007909 },
    { lat: 48.2409355, lng: 19.8023352 },
    { lat: 48.2399243, lng: 19.8033463 },
    { lat: 48.2392497, lng: 19.8043531 },
    { lat: 48.2382621, lng: 19.8052625 },
    { lat: 48.2370758, lng: 19.8058824 },
    { lat: 48.2364863, lng: 19.8063338 },
    { lat: 48.2356509, lng: 19.8073008 },
    { lat: 48.234945, lng: 19.8074141 },
    { lat: 48.2333448, lng: 19.8082266 },
    { lat: 48.2332758, lng: 19.8083207 },
  ],
  Ľuboreč: [
    { lat: 48.3473476, lng: 19.5128009 },
    { lat: 48.3475006, lng: 19.5128466 },
    { lat: 48.3477192, lng: 19.5124823 },
    { lat: 48.3476995, lng: 19.5123476 },
    { lat: 48.3477464, lng: 19.5122648 },
    { lat: 48.3482274, lng: 19.5119432 },
    { lat: 48.3483855, lng: 19.5116861 },
    { lat: 48.3485142, lng: 19.5115944 },
    { lat: 48.3488077, lng: 19.5116502 },
    { lat: 48.3489954, lng: 19.5113496 },
    { lat: 48.3490902, lng: 19.5108664 },
    { lat: 48.349298, lng: 19.5106849 },
    { lat: 48.3494014, lng: 19.5103826 },
    { lat: 48.3495572, lng: 19.5102696 },
    { lat: 48.3495237, lng: 19.5100559 },
    { lat: 48.3495719, lng: 19.5099308 },
    { lat: 48.3497133, lng: 19.509894 },
    { lat: 48.3500409, lng: 19.509497 },
    { lat: 48.3505256, lng: 19.5090889 },
    { lat: 48.3508193, lng: 19.5089789 },
    { lat: 48.3509796, lng: 19.5087194 },
    { lat: 48.3511868, lng: 19.5086648 },
    { lat: 48.3513563, lng: 19.5084353 },
    { lat: 48.3514348, lng: 19.5081124 },
    { lat: 48.351537, lng: 19.5079059 },
    { lat: 48.351641, lng: 19.5078691 },
    { lat: 48.3520689, lng: 19.5074212 },
    { lat: 48.3522132, lng: 19.5071826 },
    { lat: 48.3528587, lng: 19.5066903 },
    { lat: 48.352833, lng: 19.5066196 },
    { lat: 48.3532636, lng: 19.5061647 },
    { lat: 48.3535598, lng: 19.5056016 },
    { lat: 48.3536408, lng: 19.5055757 },
    { lat: 48.3537833, lng: 19.505293 },
    { lat: 48.3538066, lng: 19.5051137 },
    { lat: 48.3539119, lng: 19.504859 },
    { lat: 48.3539983, lng: 19.5047762 },
    { lat: 48.3542408, lng: 19.5041046 },
    { lat: 48.3543305, lng: 19.5040938 },
    { lat: 48.3543815, lng: 19.503906 },
    { lat: 48.3546965, lng: 19.5036502 },
    { lat: 48.3551997, lng: 19.5034225 },
    { lat: 48.355377, lng: 19.5033788 },
    { lat: 48.3561616, lng: 19.5034332 },
    { lat: 48.3567489, lng: 19.5029746 },
    { lat: 48.3568802, lng: 19.5030352 },
    { lat: 48.3580079, lng: 19.5019396 },
    { lat: 48.3582329, lng: 19.5014455 },
    { lat: 48.3588682, lng: 19.5005417 },
    { lat: 48.3599382, lng: 19.4994142 },
    { lat: 48.3604143, lng: 19.4991556 },
    { lat: 48.3604916, lng: 19.4992191 },
    { lat: 48.3607079, lng: 19.4991716 },
    { lat: 48.3616527, lng: 19.4985263 },
    { lat: 48.3625036, lng: 19.4980658 },
    { lat: 48.3630464, lng: 19.4976706 },
    { lat: 48.3636915, lng: 19.4969283 },
    { lat: 48.3643155, lng: 19.4960971 },
    { lat: 48.3643393, lng: 19.4956402 },
    { lat: 48.3656372, lng: 19.4944918 },
    { lat: 48.3664803, lng: 19.4934058 },
    { lat: 48.3666868, lng: 19.4929776 },
    { lat: 48.3673064, lng: 19.492559 },
    { lat: 48.3672506, lng: 19.4919386 },
    { lat: 48.3672716, lng: 19.4917613 },
    { lat: 48.3671286, lng: 19.4909686 },
    { lat: 48.367209, lng: 19.4908322 },
    { lat: 48.3671338, lng: 19.4901348 },
    { lat: 48.3669665, lng: 19.4896158 },
    { lat: 48.3669805, lng: 19.4894804 },
    { lat: 48.3668624, lng: 19.4890494 },
    { lat: 48.3661853, lng: 19.4879693 },
    { lat: 48.3658046, lng: 19.487198 },
    { lat: 48.3656234, lng: 19.4865231 },
    { lat: 48.3660205, lng: 19.4863415 },
    { lat: 48.3661137, lng: 19.4862346 },
    { lat: 48.3662141, lng: 19.4857785 },
    { lat: 48.366777, lng: 19.484798 },
    { lat: 48.3667531, lng: 19.4847675 },
    { lat: 48.3659625, lng: 19.4834269 },
    { lat: 48.3646706, lng: 19.4824508 },
    { lat: 48.363982, lng: 19.4817984 },
    { lat: 48.3631876, lng: 19.4809073 },
    { lat: 48.3614644, lng: 19.4792388 },
    { lat: 48.3600918, lng: 19.4776941 },
    { lat: 48.359553, lng: 19.4773323 },
    { lat: 48.3590991, lng: 19.4771447 },
    { lat: 48.3588619, lng: 19.4769861 },
    { lat: 48.358641, lng: 19.4767474 },
    { lat: 48.3581349, lng: 19.4760933 },
    { lat: 48.3565627, lng: 19.4738013 },
    { lat: 48.3563056, lng: 19.4732883 },
    { lat: 48.3557503, lng: 19.4699915 },
    { lat: 48.3553077, lng: 19.4684556 },
    { lat: 48.3551954, lng: 19.4677191 },
    { lat: 48.3549955, lng: 19.4662374 },
    { lat: 48.3549149, lng: 19.4643247 },
    { lat: 48.3549407, lng: 19.4634531 },
    { lat: 48.3548568, lng: 19.4628908 },
    { lat: 48.3546007, lng: 19.4626673 },
    { lat: 48.3543645, lng: 19.4623424 },
    { lat: 48.3539587, lng: 19.4614243 },
    { lat: 48.3536334, lng: 19.4610727 },
    { lat: 48.3529668, lng: 19.4608425 },
    { lat: 48.3527777, lng: 19.4606321 },
    { lat: 48.3525056, lng: 19.4606477 },
    { lat: 48.3522911, lng: 19.4608081 },
    { lat: 48.3517056, lng: 19.4600166 },
    { lat: 48.3517938, lng: 19.4586808 },
    { lat: 48.3519687, lng: 19.4584218 },
    { lat: 48.3522455, lng: 19.4581754 },
    { lat: 48.3525599, lng: 19.4574768 },
    { lat: 48.3526497, lng: 19.457129 },
    { lat: 48.352659, lng: 19.4569905 },
    { lat: 48.352529, lng: 19.4562878 },
    { lat: 48.3524426, lng: 19.4560326 },
    { lat: 48.3518337, lng: 19.4553507 },
    { lat: 48.3514057, lng: 19.4550405 },
    { lat: 48.351063, lng: 19.4549459 },
    { lat: 48.350639, lng: 19.4544857 },
    { lat: 48.3502789, lng: 19.4537765 },
    { lat: 48.3495541, lng: 19.4531972 },
    { lat: 48.3492554, lng: 19.4530944 },
    { lat: 48.3486356, lng: 19.4533722 },
    { lat: 48.347328, lng: 19.453594 },
    { lat: 48.348065, lng: 19.454 },
    { lat: 48.34854, lng: 19.454365 },
    { lat: 48.348588, lng: 19.455036 },
    { lat: 48.348644, lng: 19.455827 },
    { lat: 48.347754, lng: 19.456115 },
    { lat: 48.34772, lng: 19.456175 },
    { lat: 48.347469, lng: 19.456431 },
    { lat: 48.34724, lng: 19.457009 },
    { lat: 48.347162, lng: 19.457103 },
    { lat: 48.34712, lng: 19.457188 },
    { lat: 48.347007, lng: 19.457281 },
    { lat: 48.346973, lng: 19.457352 },
    { lat: 48.346965, lng: 19.457392 },
    { lat: 48.346848, lng: 19.457513 },
    { lat: 48.346745, lng: 19.457832 },
    { lat: 48.346597, lng: 19.458068 },
    { lat: 48.346233, lng: 19.458794 },
    { lat: 48.346008, lng: 19.45932 },
    { lat: 48.345431, lng: 19.459503 },
    { lat: 48.345098, lng: 19.459532 },
    { lat: 48.344761, lng: 19.459616 },
    { lat: 48.344467, lng: 19.459577 },
    { lat: 48.34426, lng: 19.45958 },
    { lat: 48.343738, lng: 19.459631 },
    { lat: 48.343525, lng: 19.459779 },
    { lat: 48.343224, lng: 19.460126 },
    { lat: 48.342962, lng: 19.460364 },
    { lat: 48.342782, lng: 19.460344 },
    { lat: 48.342668, lng: 19.460376 },
    { lat: 48.34259, lng: 19.460455 },
    { lat: 48.342446, lng: 19.460686 },
    { lat: 48.34222, lng: 19.461126 },
    { lat: 48.342099, lng: 19.461292 },
    { lat: 48.341927, lng: 19.461457 },
    { lat: 48.341756, lng: 19.461624 },
    { lat: 48.341748, lng: 19.461632 },
    { lat: 48.341149, lng: 19.462316 },
    { lat: 48.340798, lng: 19.462265 },
    { lat: 48.34072, lng: 19.462545 },
    { lat: 48.340454, lng: 19.46303 },
    { lat: 48.340356, lng: 19.463041 },
    { lat: 48.340204, lng: 19.463016 },
    { lat: 48.339831, lng: 19.463041 },
    { lat: 48.33975, lng: 19.462995 },
    { lat: 48.339543, lng: 19.463011 },
    { lat: 48.339377, lng: 19.462978 },
    { lat: 48.339114, lng: 19.463118 },
    { lat: 48.338953, lng: 19.463067 },
    { lat: 48.338783, lng: 19.463164 },
    { lat: 48.338644, lng: 19.463133 },
    { lat: 48.33845, lng: 19.463153 },
    { lat: 48.338349, lng: 19.463207 },
    { lat: 48.33821, lng: 19.463153 },
    { lat: 48.337998, lng: 19.463149 },
    { lat: 48.337849, lng: 19.463253 },
    { lat: 48.337552, lng: 19.463564 },
    { lat: 48.337382, lng: 19.463688 },
    { lat: 48.337195, lng: 19.463724 },
    { lat: 48.33703, lng: 19.463783 },
    { lat: 48.336741, lng: 19.463963 },
    { lat: 48.336553, lng: 19.463994 },
    { lat: 48.336488, lng: 19.46409 },
    { lat: 48.335894, lng: 19.464296 },
    { lat: 48.33579, lng: 19.464416 },
    { lat: 48.335658, lng: 19.464397 },
    { lat: 48.335483, lng: 19.46441 },
    { lat: 48.335279, lng: 19.464411 },
    { lat: 48.335176, lng: 19.464396 },
    { lat: 48.334883, lng: 19.464494 },
    { lat: 48.334736, lng: 19.46452 },
    { lat: 48.334658, lng: 19.46452 },
    { lat: 48.334409, lng: 19.46456 },
    { lat: 48.33416, lng: 19.4646 },
    { lat: 48.333889, lng: 19.464795 },
    { lat: 48.333759, lng: 19.464831 },
    { lat: 48.33361, lng: 19.464782 },
    { lat: 48.333496, lng: 19.464823 },
    { lat: 48.333223, lng: 19.464985 },
    { lat: 48.333204, lng: 19.464996 },
    { lat: 48.333077, lng: 19.465235 },
    { lat: 48.332771, lng: 19.465326 },
    { lat: 48.33256, lng: 19.465245 },
    { lat: 48.332419, lng: 19.465307 },
    { lat: 48.332345, lng: 19.465223 },
    { lat: 48.332199, lng: 19.465217 },
    { lat: 48.331951, lng: 19.465284 },
    { lat: 48.331832, lng: 19.465505 },
    { lat: 48.331692, lng: 19.46563 },
    { lat: 48.331372, lng: 19.465795 },
    { lat: 48.331259, lng: 19.465949 },
    { lat: 48.331111, lng: 19.465916 },
    { lat: 48.331037, lng: 19.46594 },
    { lat: 48.330906, lng: 19.466034 },
    { lat: 48.330837, lng: 19.465995 },
    { lat: 48.330416, lng: 19.465998 },
    { lat: 48.330133, lng: 19.466229 },
    { lat: 48.33001, lng: 19.466237 },
    { lat: 48.329861, lng: 19.46612 },
    { lat: 48.329549, lng: 19.466067 },
    { lat: 48.328951, lng: 19.466411 },
    { lat: 48.328819, lng: 19.466487 },
    { lat: 48.328152, lng: 19.466733 },
    { lat: 48.327741, lng: 19.467001 },
    { lat: 48.327264, lng: 19.467968 },
    { lat: 48.327194, lng: 19.467915 },
    { lat: 48.327111, lng: 19.467967 },
    { lat: 48.327033, lng: 19.468031 },
    { lat: 48.326889, lng: 19.468108 },
    { lat: 48.326764, lng: 19.468135 },
    { lat: 48.326507, lng: 19.468052 },
    { lat: 48.326425, lng: 19.468072 },
    { lat: 48.326333, lng: 19.468123 },
    { lat: 48.326268, lng: 19.468185 },
    { lat: 48.326171, lng: 19.468234 },
    { lat: 48.326118, lng: 19.46815 },
    { lat: 48.326072, lng: 19.468126 },
    { lat: 48.325955, lng: 19.468169 },
    { lat: 48.325852, lng: 19.468112 },
    { lat: 48.325783, lng: 19.467991 },
    { lat: 48.32574, lng: 19.468032 },
    { lat: 48.325687, lng: 19.468022 },
    { lat: 48.325699, lng: 19.468185 },
    { lat: 48.325789, lng: 19.468421 },
    { lat: 48.325931, lng: 19.468666 },
    { lat: 48.325938, lng: 19.468685 },
    { lat: 48.326148, lng: 19.469403 },
    { lat: 48.326391, lng: 19.470224 },
    { lat: 48.326374, lng: 19.470581 },
    { lat: 48.326373, lng: 19.471102 },
    { lat: 48.326372, lng: 19.471533 },
    { lat: 48.326429, lng: 19.471763 },
    { lat: 48.326238, lng: 19.472202 },
    { lat: 48.325891, lng: 19.472361 },
    { lat: 48.325886, lng: 19.472456 },
    { lat: 48.32583, lng: 19.472526 },
    { lat: 48.325748, lng: 19.472506 },
    { lat: 48.325696, lng: 19.472626 },
    { lat: 48.325629, lng: 19.47262 },
    { lat: 48.325461, lng: 19.472863 },
    { lat: 48.32548, lng: 19.472968 },
    { lat: 48.325444, lng: 19.473066 },
    { lat: 48.325294, lng: 19.473147 },
    { lat: 48.325274, lng: 19.473226 },
    { lat: 48.325189, lng: 19.47327 },
    { lat: 48.325021, lng: 19.473535 },
    { lat: 48.324906, lng: 19.47361 },
    { lat: 48.324866, lng: 19.473598 },
    { lat: 48.324832, lng: 19.47362 },
    { lat: 48.324824, lng: 19.473696 },
    { lat: 48.324775, lng: 19.473746 },
    { lat: 48.324743, lng: 19.4737 },
    { lat: 48.324648, lng: 19.473804 },
    { lat: 48.324615, lng: 19.473885 },
    { lat: 48.324504, lng: 19.473881 },
    { lat: 48.324358, lng: 19.474041 },
    { lat: 48.324193, lng: 19.474145 },
    { lat: 48.324146, lng: 19.474236 },
    { lat: 48.32403, lng: 19.474285 },
    { lat: 48.323979, lng: 19.474356 },
    { lat: 48.323888, lng: 19.474372 },
    { lat: 48.323816, lng: 19.474415 },
    { lat: 48.323794, lng: 19.474532 },
    { lat: 48.323842, lng: 19.474596 },
    { lat: 48.32389, lng: 19.474757 },
    { lat: 48.323868, lng: 19.474798 },
    { lat: 48.323751, lng: 19.474809 },
    { lat: 48.323736, lng: 19.474871 },
    { lat: 48.323829, lng: 19.474919 },
    { lat: 48.323822, lng: 19.474989 },
    { lat: 48.323716, lng: 19.475092 },
    { lat: 48.323664, lng: 19.475137 },
    { lat: 48.32362, lng: 19.47513 },
    { lat: 48.323628, lng: 19.475216 },
    { lat: 48.323644, lng: 19.475284 },
    { lat: 48.323543, lng: 19.475391 },
    { lat: 48.323414, lng: 19.47541 },
    { lat: 48.323339, lng: 19.475492 },
    { lat: 48.323219, lng: 19.475512 },
    { lat: 48.323126, lng: 19.475472 },
    { lat: 48.323098, lng: 19.475488 },
    { lat: 48.323079, lng: 19.475579 },
    { lat: 48.32303, lng: 19.475648 },
    { lat: 48.322967, lng: 19.475701 },
    { lat: 48.322916, lng: 19.475672 },
    { lat: 48.322886, lng: 19.47563 },
    { lat: 48.322867, lng: 19.475555 },
    { lat: 48.322824, lng: 19.475526 },
    { lat: 48.322758, lng: 19.475584 },
    { lat: 48.322576, lng: 19.475626 },
    { lat: 48.322442, lng: 19.475567 },
    { lat: 48.322291, lng: 19.475367 },
    { lat: 48.322223, lng: 19.475453 },
    { lat: 48.322103, lng: 19.475495 },
    { lat: 48.322012, lng: 19.475565 },
    { lat: 48.321918, lng: 19.475472 },
    { lat: 48.321844, lng: 19.475444 },
    { lat: 48.321808, lng: 19.475354 },
    { lat: 48.321743, lng: 19.475338 },
    { lat: 48.321672, lng: 19.475399 },
    { lat: 48.321541, lng: 19.475374 },
    { lat: 48.321472, lng: 19.475276 },
    { lat: 48.321415, lng: 19.475355 },
    { lat: 48.321189, lng: 19.475374 },
    { lat: 48.32105, lng: 19.475401 },
    { lat: 48.320927, lng: 19.475507 },
    { lat: 48.320804, lng: 19.475373 },
    { lat: 48.320613, lng: 19.47548 },
    { lat: 48.32054, lng: 19.475608 },
    { lat: 48.320495, lng: 19.475618 },
    { lat: 48.320383, lng: 19.475286 },
    { lat: 48.320303, lng: 19.475124 },
    { lat: 48.320215, lng: 19.475143 },
    { lat: 48.31992, lng: 19.475525 },
    { lat: 48.31981, lng: 19.475651 },
    { lat: 48.319691, lng: 19.475516 },
    { lat: 48.319366, lng: 19.475882 },
    { lat: 48.319256, lng: 19.475879 },
    { lat: 48.319141, lng: 19.475797 },
    { lat: 48.319085, lng: 19.475711 },
    { lat: 48.318923, lng: 19.475774 },
    { lat: 48.318862, lng: 19.475694 },
    { lat: 48.318726, lng: 19.475764 },
    { lat: 48.318676, lng: 19.47582 },
    { lat: 48.318591, lng: 19.475792 },
    { lat: 48.318528, lng: 19.475808 },
    { lat: 48.318237, lng: 19.47597 },
    { lat: 48.318191, lng: 19.476042 },
    { lat: 48.318124, lng: 19.476072 },
    { lat: 48.318171, lng: 19.476177 },
    { lat: 48.318155, lng: 19.476484 },
    { lat: 48.317974, lng: 19.476953 },
    { lat: 48.317469, lng: 19.477432 },
    { lat: 48.317162, lng: 19.47792 },
    { lat: 48.317087, lng: 19.478116 },
    { lat: 48.316944, lng: 19.478314 },
    { lat: 48.316854, lng: 19.478558 },
    { lat: 48.316678, lng: 19.478929 },
    { lat: 48.316555, lng: 19.479237 },
    { lat: 48.316462, lng: 19.479604 },
    { lat: 48.316331, lng: 19.479622 },
    { lat: 48.317225, lng: 19.482026 },
    { lat: 48.317318, lng: 19.482275 },
    { lat: 48.317286, lng: 19.482948 },
    { lat: 48.317248, lng: 19.483216 },
    { lat: 48.317224, lng: 19.483381 },
    { lat: 48.317159, lng: 19.483837 },
    { lat: 48.317149, lng: 19.483909 },
    { lat: 48.317111, lng: 19.484039 },
    { lat: 48.316803, lng: 19.485073 },
    { lat: 48.316395, lng: 19.485872 },
    { lat: 48.316166, lng: 19.486595 },
    { lat: 48.316168, lng: 19.486642 },
    { lat: 48.316169, lng: 19.486667 },
    { lat: 48.315921, lng: 19.486635 },
    { lat: 48.31576, lng: 19.486657 },
    { lat: 48.315129, lng: 19.48683 },
    { lat: 48.314923, lng: 19.486806 },
    { lat: 48.31441, lng: 19.486583 },
    { lat: 48.314309, lng: 19.48653 },
    { lat: 48.31399, lng: 19.486314 },
    { lat: 48.313778, lng: 19.486031 },
    { lat: 48.313703, lng: 19.48595 },
    { lat: 48.313559, lng: 19.485836 },
    { lat: 48.31344, lng: 19.48578 },
    { lat: 48.313197, lng: 19.485743 },
    { lat: 48.312881, lng: 19.485793 },
    { lat: 48.312866, lng: 19.485795 },
    { lat: 48.312654, lng: 19.485773 },
    { lat: 48.312409, lng: 19.485835 },
    { lat: 48.312343, lng: 19.485871 },
    { lat: 48.312293, lng: 19.485943 },
    { lat: 48.312284, lng: 19.485968 },
    { lat: 48.312159, lng: 19.486268 },
    { lat: 48.312081, lng: 19.48636 },
    { lat: 48.311802, lng: 19.48651 },
    { lat: 48.311702, lng: 19.486601 },
    { lat: 48.311533, lng: 19.486891 },
    { lat: 48.311036, lng: 19.487393 },
    { lat: 48.310801, lng: 19.487721 },
    { lat: 48.310471, lng: 19.488058 },
    { lat: 48.310297, lng: 19.488292 },
    { lat: 48.310166, lng: 19.48857 },
    { lat: 48.310054, lng: 19.488881 },
    { lat: 48.309847, lng: 19.489323 },
    { lat: 48.309862, lng: 19.489341 },
    { lat: 48.309893, lng: 19.489376 },
    { lat: 48.309581, lng: 19.490139 },
    { lat: 48.309521, lng: 19.490493 },
    { lat: 48.309502, lng: 19.490811 },
    { lat: 48.309464, lng: 19.491094 },
    { lat: 48.309423, lng: 19.491881 },
    { lat: 48.309411, lng: 19.492487 },
    { lat: 48.309008, lng: 19.493851 },
    { lat: 48.308872, lng: 19.494272 },
    { lat: 48.3087721, lng: 19.4944677 },
    { lat: 48.308753, lng: 19.494505 },
    { lat: 48.308485, lng: 19.494835 },
    { lat: 48.308179, lng: 19.495083 },
    { lat: 48.30767, lng: 19.495627 },
    { lat: 48.307246, lng: 19.496152 },
    { lat: 48.307128, lng: 19.496379 },
    { lat: 48.305818, lng: 19.497985 },
    { lat: 48.305653, lng: 19.498269 },
    { lat: 48.3053, lng: 19.498773 },
    { lat: 48.305007, lng: 19.499075 },
    { lat: 48.303844, lng: 19.499987 },
    { lat: 48.303091, lng: 19.499831 },
    { lat: 48.30073, lng: 19.499343 },
    { lat: 48.298962, lng: 19.498972 },
    { lat: 48.298076, lng: 19.498789 },
    { lat: 48.298026, lng: 19.498799 },
    { lat: 48.298009, lng: 19.498802 },
    { lat: 48.297918, lng: 19.498831 },
    { lat: 48.29772, lng: 19.498875 },
    { lat: 48.29743, lng: 19.498892 },
    { lat: 48.297161, lng: 19.498887 },
    { lat: 48.296607, lng: 19.499058 },
    { lat: 48.296322, lng: 19.499073 },
    { lat: 48.295833, lng: 19.499234 },
    { lat: 48.295367, lng: 19.499337 },
    { lat: 48.29489, lng: 19.499462 },
    { lat: 48.294514, lng: 19.499626 },
    { lat: 48.293781, lng: 19.500183 },
    { lat: 48.293387, lng: 19.500515 },
    { lat: 48.293229, lng: 19.500649 },
    { lat: 48.293112, lng: 19.500736 },
    { lat: 48.292854, lng: 19.500923 },
    { lat: 48.292694, lng: 19.500921 },
    { lat: 48.292481, lng: 19.500956 },
    { lat: 48.292059, lng: 19.501146 },
    { lat: 48.291709, lng: 19.501331 },
    { lat: 48.291499, lng: 19.501442 },
    { lat: 48.290714, lng: 19.501853 },
    { lat: 48.29066, lng: 19.501881 },
    { lat: 48.290619, lng: 19.501891 },
    { lat: 48.290561, lng: 19.501907 },
    { lat: 48.290478, lng: 19.501898 },
    { lat: 48.290275, lng: 19.501878 },
    { lat: 48.290174, lng: 19.501905 },
    { lat: 48.290133, lng: 19.501916 },
    { lat: 48.290048, lng: 19.501951 },
    { lat: 48.289793, lng: 19.50206 },
    { lat: 48.28977, lng: 19.50207 },
    { lat: 48.289446, lng: 19.502063 },
    { lat: 48.289229, lng: 19.502125 },
    { lat: 48.289023, lng: 19.502268 },
    { lat: 48.288758, lng: 19.50243 },
    { lat: 48.28856, lng: 19.502533 },
    { lat: 48.288393, lng: 19.502591 },
    { lat: 48.288192, lng: 19.502657 },
    { lat: 48.287828, lng: 19.502718 },
    { lat: 48.287668, lng: 19.502745 },
    { lat: 48.287478, lng: 19.502777 },
    { lat: 48.287358, lng: 19.502797 },
    { lat: 48.286469, lng: 19.503533 },
    { lat: 48.286423, lng: 19.503571 },
    { lat: 48.285865, lng: 19.504053 },
    { lat: 48.285791, lng: 19.504237 },
    { lat: 48.285725, lng: 19.5044 },
    { lat: 48.285655, lng: 19.504578 },
    { lat: 48.285374, lng: 19.505085 },
    { lat: 48.285105, lng: 19.505454 },
    { lat: 48.285052, lng: 19.505528 },
    { lat: 48.28494, lng: 19.505681 },
    { lat: 48.284857, lng: 19.505696 },
    { lat: 48.284144, lng: 19.505827 },
    { lat: 48.283791, lng: 19.505924 },
    { lat: 48.283606, lng: 19.506013 },
    { lat: 48.283204, lng: 19.506209 },
    { lat: 48.28305, lng: 19.50619 },
    { lat: 48.282853, lng: 19.506166 },
    { lat: 48.282672, lng: 19.506242 },
    { lat: 48.282511, lng: 19.50631 },
    { lat: 48.282409, lng: 19.506362 },
    { lat: 48.281529, lng: 19.506417 },
    { lat: 48.28142, lng: 19.506424 },
    { lat: 48.281144, lng: 19.506419 },
    { lat: 48.280414, lng: 19.506407 },
    { lat: 48.2802, lng: 19.50642 },
    { lat: 48.280132, lng: 19.506403 },
    { lat: 48.280103, lng: 19.506395 },
    { lat: 48.280053, lng: 19.506382 },
    { lat: 48.279775, lng: 19.506414 },
    { lat: 48.279358, lng: 19.506389 },
    { lat: 48.279026, lng: 19.506393 },
    { lat: 48.278552, lng: 19.506404 },
    { lat: 48.278345, lng: 19.506396 },
    { lat: 48.278135, lng: 19.506427 },
    { lat: 48.277948, lng: 19.506558 },
    { lat: 48.277916, lng: 19.506428 },
    { lat: 48.277879, lng: 19.506434 },
    { lat: 48.277758, lng: 19.506459 },
    { lat: 48.277455, lng: 19.506605 },
    { lat: 48.277438, lng: 19.506626 },
    { lat: 48.27742, lng: 19.506651 },
    { lat: 48.277399, lng: 19.506689 },
    { lat: 48.277201, lng: 19.507049 },
    { lat: 48.27715, lng: 19.507142 },
    { lat: 48.277082, lng: 19.507267 },
    { lat: 48.277007, lng: 19.507397 },
    { lat: 48.276964, lng: 19.50747 },
    { lat: 48.276949, lng: 19.507566 },
    { lat: 48.276936, lng: 19.507611 },
    { lat: 48.276845, lng: 19.507917 },
    { lat: 48.276753, lng: 19.508545 },
    { lat: 48.276754, lng: 19.508669 },
    { lat: 48.276749, lng: 19.508735 },
    { lat: 48.27674, lng: 19.508919 },
    { lat: 48.276712, lng: 19.509104 },
    { lat: 48.276701, lng: 19.50918 },
    { lat: 48.276691, lng: 19.509373 },
    { lat: 48.276686, lng: 19.509557 },
    { lat: 48.276726, lng: 19.509827 },
    { lat: 48.27674, lng: 19.50992 },
    { lat: 48.276787, lng: 19.510014 },
    { lat: 48.276842, lng: 19.510124 },
    { lat: 48.276862, lng: 19.510187 },
    { lat: 48.276901, lng: 19.510313 },
    { lat: 48.27692, lng: 19.510373 },
    { lat: 48.276996, lng: 19.510612 },
    { lat: 48.277017, lng: 19.51084 },
    { lat: 48.277081, lng: 19.510975 },
    { lat: 48.27718, lng: 19.511618 },
    { lat: 48.27719, lng: 19.511843 },
    { lat: 48.277204, lng: 19.511978 },
    { lat: 48.277312, lng: 19.512513 },
    { lat: 48.277293, lng: 19.512578 },
    { lat: 48.277141, lng: 19.513005 },
    { lat: 48.276756, lng: 19.513966 },
    { lat: 48.276619, lng: 19.514212 },
    { lat: 48.276476, lng: 19.514523 },
    { lat: 48.276455, lng: 19.514582 },
    { lat: 48.276317, lng: 19.514971 },
    { lat: 48.276229, lng: 19.515311 },
    { lat: 48.2762, lng: 19.51544 },
    { lat: 48.276142, lng: 19.515797 },
    { lat: 48.276094, lng: 19.516185 },
    { lat: 48.276081, lng: 19.516331 },
    { lat: 48.276082, lng: 19.516509 },
    { lat: 48.276061, lng: 19.51692 },
    { lat: 48.276002, lng: 19.517602 },
    { lat: 48.275981, lng: 19.517797 },
    { lat: 48.275899, lng: 19.518456 },
    { lat: 48.275822, lng: 19.518823 },
    { lat: 48.275685, lng: 19.519306 },
    { lat: 48.275636, lng: 19.519662 },
    { lat: 48.275603, lng: 19.519846 },
    { lat: 48.275522, lng: 19.520129 },
    { lat: 48.275512, lng: 19.520156 },
    { lat: 48.275467, lng: 19.520239 },
    { lat: 48.275512, lng: 19.520351 },
    { lat: 48.275529, lng: 19.520474 },
    { lat: 48.275479, lng: 19.520814 },
    { lat: 48.275483, lng: 19.521021 },
    { lat: 48.275574, lng: 19.521125 },
    { lat: 48.275646, lng: 19.5212 },
    { lat: 48.275704, lng: 19.52128 },
    { lat: 48.275862, lng: 19.521543 },
    { lat: 48.275889, lng: 19.521985 },
    { lat: 48.276023, lng: 19.522231 },
    { lat: 48.2761, lng: 19.522469 },
    { lat: 48.27607, lng: 19.523088 },
    { lat: 48.276031, lng: 19.523224 },
    { lat: 48.275945, lng: 19.523675 },
    { lat: 48.275977, lng: 19.524368 },
    { lat: 48.276043, lng: 19.52479 },
    { lat: 48.27601, lng: 19.524944 },
    { lat: 48.275949, lng: 19.524986 },
    { lat: 48.275944, lng: 19.524946 },
    { lat: 48.275916, lng: 19.524971 },
    { lat: 48.275919, lng: 19.524992 },
    { lat: 48.275933, lng: 19.525082 },
    { lat: 48.275986, lng: 19.525164 },
    { lat: 48.276052, lng: 19.525179 },
    { lat: 48.276135, lng: 19.525155 },
    { lat: 48.276181, lng: 19.525124 },
    { lat: 48.276207, lng: 19.524969 },
    { lat: 48.276339, lng: 19.524748 },
    { lat: 48.276452, lng: 19.524689 },
    { lat: 48.276554, lng: 19.524706 },
    { lat: 48.276658, lng: 19.524734 },
    { lat: 48.276833, lng: 19.52462 },
    { lat: 48.276964, lng: 19.524639 },
    { lat: 48.277162, lng: 19.524509 },
    { lat: 48.277247, lng: 19.524561 },
    { lat: 48.277323, lng: 19.524675 },
    { lat: 48.277329, lng: 19.524683 },
    { lat: 48.277377, lng: 19.524694 },
    { lat: 48.277433, lng: 19.524675 },
    { lat: 48.277499, lng: 19.524512 },
    { lat: 48.277559, lng: 19.524445 },
    { lat: 48.277699, lng: 19.524504 },
    { lat: 48.277805, lng: 19.524446 },
    { lat: 48.277855, lng: 19.524437 },
    { lat: 48.277911, lng: 19.524464 },
    { lat: 48.278039, lng: 19.524778 },
    { lat: 48.278084, lng: 19.524625 },
    { lat: 48.2781068, lng: 19.5244953 },
    { lat: 48.278291, lng: 19.52431 },
    { lat: 48.278341, lng: 19.524285 },
    { lat: 48.278432, lng: 19.524314 },
    { lat: 48.278579, lng: 19.52447 },
    { lat: 48.27866, lng: 19.524682 },
    { lat: 48.279143, lng: 19.524522 },
    { lat: 48.2793, lng: 19.524573 },
    { lat: 48.279416, lng: 19.524529 },
    { lat: 48.279566, lng: 19.524454 },
    { lat: 48.279649, lng: 19.524333 },
    { lat: 48.279893, lng: 19.524384 },
    { lat: 48.280075, lng: 19.524256 },
    { lat: 48.280253, lng: 19.524245 },
    { lat: 48.280402, lng: 19.524355 },
    { lat: 48.280798, lng: 19.524797 },
    { lat: 48.281094, lng: 19.525192 },
    { lat: 48.281184, lng: 19.5254 },
    { lat: 48.28147, lng: 19.52561 },
    { lat: 48.28146, lng: 19.526187 },
    { lat: 48.281506, lng: 19.526326 },
    { lat: 48.281557, lng: 19.526427 },
    { lat: 48.281978, lng: 19.526294 },
    { lat: 48.282333, lng: 19.526348 },
    { lat: 48.282441, lng: 19.526489 },
    { lat: 48.28256, lng: 19.526553 },
    { lat: 48.282943, lng: 19.526612 },
    { lat: 48.283345, lng: 19.526764 },
    { lat: 48.283375, lng: 19.526939 },
    { lat: 48.283321, lng: 19.526988 },
    { lat: 48.283319, lng: 19.526956 },
    { lat: 48.283282, lng: 19.527019 },
    { lat: 48.283209, lng: 19.527077 },
    { lat: 48.283186, lng: 19.527099 },
    { lat: 48.283136, lng: 19.527159 },
    { lat: 48.283091, lng: 19.527189 },
    { lat: 48.283131, lng: 19.527427 },
    { lat: 48.283118, lng: 19.527577 },
    { lat: 48.283066, lng: 19.527752 },
    { lat: 48.282919, lng: 19.528012 },
    { lat: 48.283031, lng: 19.528535 },
    { lat: 48.283019, lng: 19.528624 },
    { lat: 48.282834, lng: 19.529036 },
    { lat: 48.283081, lng: 19.529655 },
    { lat: 48.283116, lng: 19.529764 },
    { lat: 48.283282, lng: 19.529979 },
    { lat: 48.283284, lng: 19.530239 },
    { lat: 48.283446, lng: 19.530862 },
    { lat: 48.283271, lng: 19.531382 },
    { lat: 48.283233, lng: 19.531728 },
    { lat: 48.283182, lng: 19.53193 },
    { lat: 48.283203, lng: 19.532256 },
    { lat: 48.283333, lng: 19.532433 },
    { lat: 48.283293, lng: 19.532717 },
    { lat: 48.283362, lng: 19.532841 },
    { lat: 48.283506, lng: 19.533599 },
    { lat: 48.283597, lng: 19.533973 },
    { lat: 48.283696, lng: 19.535087 },
    { lat: 48.283823, lng: 19.535625 },
    { lat: 48.283787, lng: 19.535802 },
    { lat: 48.283731, lng: 19.536533 },
    { lat: 48.283884, lng: 19.536868 },
    { lat: 48.283686, lng: 19.537653 },
    { lat: 48.283685, lng: 19.537846 },
    { lat: 48.283691, lng: 19.538053 },
    { lat: 48.283679, lng: 19.538427 },
    { lat: 48.283644, lng: 19.538673 },
    { lat: 48.283587, lng: 19.539211 },
    { lat: 48.283574, lng: 19.539313 },
    { lat: 48.283569, lng: 19.539423 },
    { lat: 48.283668, lng: 19.540012 },
    { lat: 48.283668, lng: 19.540021 },
    { lat: 48.283681, lng: 19.540131 },
    { lat: 48.283685, lng: 19.540166 },
    { lat: 48.283696, lng: 19.54027 },
    { lat: 48.283767, lng: 19.541162 },
    { lat: 48.28377, lng: 19.541208 },
    { lat: 48.283779, lng: 19.541336 },
    { lat: 48.283784, lng: 19.541394 },
    { lat: 48.283842, lng: 19.541948 },
    { lat: 48.283986, lng: 19.543293 },
    { lat: 48.2845679, lng: 19.5429894 },
    { lat: 48.2849808, lng: 19.542925 },
    { lat: 48.2850252, lng: 19.5428326 },
    { lat: 48.2851624, lng: 19.5428786 },
    { lat: 48.2852972, lng: 19.5427719 },
    { lat: 48.2855987, lng: 19.5428825 },
    { lat: 48.2857379, lng: 19.5428691 },
    { lat: 48.2865731, lng: 19.5431187 },
    { lat: 48.2878444, lng: 19.5439051 },
    { lat: 48.2885565, lng: 19.5440719 },
    { lat: 48.2887405, lng: 19.5441746 },
    { lat: 48.289006, lng: 19.5441913 },
    { lat: 48.2893557, lng: 19.544396 },
    { lat: 48.2898754, lng: 19.5445421 },
    { lat: 48.2907579, lng: 19.5451569 },
    { lat: 48.2913137, lng: 19.5452826 },
    { lat: 48.2916062, lng: 19.5454445 },
    { lat: 48.2918981, lng: 19.5454188 },
    { lat: 48.2922243, lng: 19.5452583 },
    { lat: 48.2924331, lng: 19.5453874 },
    { lat: 48.2925418, lng: 19.5456186 },
    { lat: 48.2927698, lng: 19.5455037 },
    { lat: 48.2932069, lng: 19.5456463 },
    { lat: 48.2936006, lng: 19.5459505 },
    { lat: 48.2937192, lng: 19.5461771 },
    { lat: 48.2939751, lng: 19.5464708 },
    { lat: 48.2941508, lng: 19.5465935 },
    { lat: 48.2942385, lng: 19.5464881 },
    { lat: 48.2945044, lng: 19.5467557 },
    { lat: 48.2946583, lng: 19.546749 },
    { lat: 48.2947793, lng: 19.5470719 },
    { lat: 48.2950467, lng: 19.547253 },
    { lat: 48.2952057, lng: 19.5476439 },
    { lat: 48.2952997, lng: 19.5477265 },
    { lat: 48.2952485, lng: 19.5477888 },
    { lat: 48.2954555, lng: 19.54837 },
    { lat: 48.2955597, lng: 19.5485047 },
    { lat: 48.2955632, lng: 19.5486013 },
    { lat: 48.2955069, lng: 19.548651 },
    { lat: 48.2955589, lng: 19.5488505 },
    { lat: 48.2956205, lng: 19.5489687 },
    { lat: 48.2957025, lng: 19.5489598 },
    { lat: 48.2957297, lng: 19.5490377 },
    { lat: 48.2956147, lng: 19.5494775 },
    { lat: 48.2957991, lng: 19.5495639 },
    { lat: 48.2957153, lng: 19.5498988 },
    { lat: 48.2957776, lng: 19.5500767 },
    { lat: 48.2956496, lng: 19.5501831 },
    { lat: 48.2958152, lng: 19.5506214 },
    { lat: 48.295803, lng: 19.5510682 },
    { lat: 48.2958733, lng: 19.5512258 },
    { lat: 48.2958865, lng: 19.5512393 },
    { lat: 48.2959403, lng: 19.5509871 },
    { lat: 48.296084, lng: 19.55098 },
    { lat: 48.2961415, lng: 19.5509061 },
    { lat: 48.2962236, lng: 19.5506796 },
    { lat: 48.2961202, lng: 19.5506135 },
    { lat: 48.2961011, lng: 19.5504697 },
    { lat: 48.2962292, lng: 19.550289 },
    { lat: 48.2963422, lng: 19.5502713 },
    { lat: 48.2964241, lng: 19.5500448 },
    { lat: 48.2964887, lng: 19.5500003 },
    { lat: 48.2963819, lng: 19.5496925 },
    { lat: 48.2964128, lng: 19.549605 },
    { lat: 48.296317, lng: 19.5496259 },
    { lat: 48.2961684, lng: 19.5494619 },
    { lat: 48.2961778, lng: 19.5492967 },
    { lat: 48.2962363, lng: 19.5492694 },
    { lat: 48.2962311, lng: 19.5490842 },
    { lat: 48.2963367, lng: 19.5489791 },
    { lat: 48.2964272, lng: 19.5489853 },
    { lat: 48.2964978, lng: 19.5489016 },
    { lat: 48.2965051, lng: 19.5487688 },
    { lat: 48.2966367, lng: 19.5488373 },
    { lat: 48.2966585, lng: 19.5484287 },
    { lat: 48.2967428, lng: 19.5482091 },
    { lat: 48.296674, lng: 19.5481298 },
    { lat: 48.2966817, lng: 19.5480314 },
    { lat: 48.2966309, lng: 19.547928 },
    { lat: 48.2966726, lng: 19.5477814 },
    { lat: 48.2966594, lng: 19.5476182 },
    { lat: 48.2965546, lng: 19.5475477 },
    { lat: 48.2965892, lng: 19.5473466 },
    { lat: 48.2967638, lng: 19.5471236 },
    { lat: 48.2968831, lng: 19.5471197 },
    { lat: 48.2971442, lng: 19.5467696 },
    { lat: 48.2971805, lng: 19.5463111 },
    { lat: 48.297394, lng: 19.5461283 },
    { lat: 48.2974381, lng: 19.5458352 },
    { lat: 48.2976357, lng: 19.5455016 },
    { lat: 48.2977288, lng: 19.545517 },
    { lat: 48.2979243, lng: 19.5452621 },
    { lat: 48.2980788, lng: 19.545238 },
    { lat: 48.2982653, lng: 19.5449603 },
    { lat: 48.2982877, lng: 19.5448115 },
    { lat: 48.2982663, lng: 19.5445879 },
    { lat: 48.2981459, lng: 19.5442252 },
    { lat: 48.2981679, lng: 19.5438467 },
    { lat: 48.2983218, lng: 19.5434977 },
    { lat: 48.2985606, lng: 19.5432019 },
    { lat: 48.2986026, lng: 19.5429399 },
    { lat: 48.2989057, lng: 19.5427694 },
    { lat: 48.2991218, lng: 19.5423198 },
    { lat: 48.299385, lng: 19.5419686 },
    { lat: 48.2993787, lng: 19.5415163 },
    { lat: 48.2997382, lng: 19.5406646 },
    { lat: 48.2997501, lng: 19.5404825 },
    { lat: 48.2996184, lng: 19.5402371 },
    { lat: 48.2997838, lng: 19.5398929 },
    { lat: 48.2997737, lng: 19.5397 },
    { lat: 48.2998587, lng: 19.5396378 },
    { lat: 48.3001192, lng: 19.5397095 },
    { lat: 48.3004947, lng: 19.5395477 },
    { lat: 48.3007238, lng: 19.5388214 },
    { lat: 48.3007879, lng: 19.538768 },
    { lat: 48.3009529, lng: 19.5387824 },
    { lat: 48.3014554, lng: 19.5381933 },
    { lat: 48.3015501, lng: 19.5375591 },
    { lat: 48.3017081, lng: 19.537249 },
    { lat: 48.3014983, lng: 19.5368071 },
    { lat: 48.3015346, lng: 19.5366883 },
    { lat: 48.3016927, lng: 19.536502 },
    { lat: 48.3019076, lng: 19.5365618 },
    { lat: 48.3019457, lng: 19.5364155 },
    { lat: 48.3022355, lng: 19.5359774 },
    { lat: 48.3024394, lng: 19.5358581 },
    { lat: 48.3025359, lng: 19.5355547 },
    { lat: 48.3027718, lng: 19.5355129 },
    { lat: 48.3027189, lng: 19.535856 },
    { lat: 48.3028526, lng: 19.5359427 },
    { lat: 48.3030129, lng: 19.5359522 },
    { lat: 48.3031376, lng: 19.535723 },
    { lat: 48.3032262, lng: 19.5353977 },
    { lat: 48.3032053, lng: 19.5351132 },
    { lat: 48.3033999, lng: 19.5350831 },
    { lat: 48.3037391, lng: 19.5349119 },
    { lat: 48.3041988, lng: 19.5351558 },
    { lat: 48.3044764, lng: 19.5352141 },
    { lat: 48.3045965, lng: 19.5354462 },
    { lat: 48.3046008, lng: 19.5356577 },
    { lat: 48.3046944, lng: 19.5356601 },
    { lat: 48.3050104, lng: 19.5354244 },
    { lat: 48.3051295, lng: 19.5354414 },
    { lat: 48.3052896, lng: 19.5352273 },
    { lat: 48.3053375, lng: 19.534971 },
    { lat: 48.3055066, lng: 19.5346543 },
    { lat: 48.3056505, lng: 19.5344776 },
    { lat: 48.3059128, lng: 19.5346652 },
    { lat: 48.306071, lng: 19.5346506 },
    { lat: 48.3064103, lng: 19.5348224 },
    { lat: 48.3068994, lng: 19.5348481 },
    { lat: 48.3070861, lng: 19.5350612 },
    { lat: 48.3074051, lng: 19.5350352 },
    { lat: 48.307484, lng: 19.5352553 },
    { lat: 48.3076072, lng: 19.5353869 },
    { lat: 48.3078101, lng: 19.5353167 },
    { lat: 48.3080257, lng: 19.5354705 },
    { lat: 48.3080413, lng: 19.5355868 },
    { lat: 48.3081518, lng: 19.535684 },
    { lat: 48.3082057, lng: 19.5358547 },
    { lat: 48.3084358, lng: 19.535978 },
    { lat: 48.3085683, lng: 19.5361632 },
    { lat: 48.3086293, lng: 19.5360921 },
    { lat: 48.3090794, lng: 19.5363236 },
    { lat: 48.3092525, lng: 19.5363134 },
    { lat: 48.3093178, lng: 19.5362139 },
    { lat: 48.309376, lng: 19.5363027 },
    { lat: 48.3094384, lng: 19.5362892 },
    { lat: 48.310346, lng: 19.5358356 },
    { lat: 48.311119, lng: 19.5349786 },
    { lat: 48.3115047, lng: 19.5348384 },
    { lat: 48.3115944, lng: 19.5346832 },
    { lat: 48.3117646, lng: 19.53456 },
    { lat: 48.3121206, lng: 19.5344614 },
    { lat: 48.3121931, lng: 19.5343481 },
    { lat: 48.3123848, lng: 19.5343986 },
    { lat: 48.3125429, lng: 19.5342112 },
    { lat: 48.3130618, lng: 19.5339282 },
    { lat: 48.3134006, lng: 19.5338229 },
    { lat: 48.3139779, lng: 19.53349 },
    { lat: 48.3140986, lng: 19.5334902 },
    { lat: 48.314312, lng: 19.5333568 },
    { lat: 48.3144475, lng: 19.5331909 },
    { lat: 48.3146716, lng: 19.5327728 },
    { lat: 48.3150293, lng: 19.5325374 },
    { lat: 48.3152624, lng: 19.5324087 },
    { lat: 48.315527, lng: 19.5323547 },
    { lat: 48.316442, lng: 19.5317455 },
    { lat: 48.316884, lng: 19.5316783 },
    { lat: 48.3172213, lng: 19.5315162 },
    { lat: 48.3175394, lng: 19.5311343 },
    { lat: 48.3186518, lng: 19.5306214 },
    { lat: 48.3188752, lng: 19.5304842 },
    { lat: 48.3189588, lng: 19.530341 },
    { lat: 48.3189926, lng: 19.5303679 },
    { lat: 48.3190097, lng: 19.5303442 },
    { lat: 48.3191797, lng: 19.5302461 },
    { lat: 48.319224, lng: 19.5301572 },
    { lat: 48.3191626, lng: 19.5298761 },
    { lat: 48.3191781, lng: 19.5297471 },
    { lat: 48.3192709, lng: 19.5296592 },
    { lat: 48.3193457, lng: 19.5296691 },
    { lat: 48.3194401, lng: 19.5299078 },
    { lat: 48.3195462, lng: 19.530031 },
    { lat: 48.3196345, lng: 19.5299655 },
    { lat: 48.3196061, lng: 19.5297907 },
    { lat: 48.3197662, lng: 19.5295503 },
    { lat: 48.3198904, lng: 19.5295813 },
    { lat: 48.3201714, lng: 19.5293647 },
    { lat: 48.3204151, lng: 19.5295451 },
    { lat: 48.3205651, lng: 19.5294013 },
    { lat: 48.320676, lng: 19.5294351 },
    { lat: 48.3208317, lng: 19.529341 },
    { lat: 48.3208895, lng: 19.5291293 },
    { lat: 48.3211169, lng: 19.5289318 },
    { lat: 48.3209635, lng: 19.5286369 },
    { lat: 48.3210176, lng: 19.5285353 },
    { lat: 48.3212759, lng: 19.5284939 },
    { lat: 48.3211995, lng: 19.5283015 },
    { lat: 48.3213312, lng: 19.5281773 },
    { lat: 48.3212636, lng: 19.5278391 },
    { lat: 48.3215103, lng: 19.5277157 },
    { lat: 48.3216288, lng: 19.5277189 },
    { lat: 48.3217929, lng: 19.5275347 },
    { lat: 48.3220586, lng: 19.5274607 },
    { lat: 48.3221184, lng: 19.5273391 },
    { lat: 48.3221264, lng: 19.5270978 },
    { lat: 48.322249, lng: 19.5265334 },
    { lat: 48.3224437, lng: 19.5263318 },
    { lat: 48.3226488, lng: 19.5263802 },
    { lat: 48.3225642, lng: 19.5260357 },
    { lat: 48.3229567, lng: 19.5258532 },
    { lat: 48.3231032, lng: 19.525947 },
    { lat: 48.3232568, lng: 19.5259551 },
    { lat: 48.3234707, lng: 19.5256109 },
    { lat: 48.3236364, lng: 19.5257314 },
    { lat: 48.3235432, lng: 19.5258367 },
    { lat: 48.3235475, lng: 19.5259801 },
    { lat: 48.3238653, lng: 19.5259781 },
    { lat: 48.3238212, lng: 19.5257303 },
    { lat: 48.3238678, lng: 19.5256443 },
    { lat: 48.3239341, lng: 19.5256381 },
    { lat: 48.3240264, lng: 19.5257341 },
    { lat: 48.3241318, lng: 19.525676 },
    { lat: 48.3244955, lng: 19.5257872 },
    { lat: 48.3245008, lng: 19.5256383 },
    { lat: 48.3243962, lng: 19.5254948 },
    { lat: 48.324386, lng: 19.5253457 },
    { lat: 48.3244742, lng: 19.5253797 },
    { lat: 48.3247676, lng: 19.5252189 },
    { lat: 48.3247547, lng: 19.5251073 },
    { lat: 48.3246853, lng: 19.5250929 },
    { lat: 48.324525, lng: 19.5252317 },
    { lat: 48.3243784, lng: 19.5250381 },
    { lat: 48.3245915, lng: 19.5248672 },
    { lat: 48.3246151, lng: 19.5246993 },
    { lat: 48.3247338, lng: 19.5247288 },
    { lat: 48.3248281, lng: 19.5249643 },
    { lat: 48.3249036, lng: 19.5250146 },
    { lat: 48.3250598, lng: 19.5247345 },
    { lat: 48.3251357, lng: 19.5249628 },
    { lat: 48.325265, lng: 19.5250771 },
    { lat: 48.325426, lng: 19.5249064 },
    { lat: 48.3255231, lng: 19.524591 },
    { lat: 48.3256594, lng: 19.5246186 },
    { lat: 48.3258555, lng: 19.5247628 },
    { lat: 48.3259333, lng: 19.5246174 },
    { lat: 48.3263618, lng: 19.5246467 },
    { lat: 48.3265362, lng: 19.524568 },
    { lat: 48.3267001, lng: 19.5246742 },
    { lat: 48.3270064, lng: 19.5245973 },
    { lat: 48.3270685, lng: 19.5244552 },
    { lat: 48.3269779, lng: 19.5241502 },
    { lat: 48.3273443, lng: 19.5239594 },
    { lat: 48.3273348, lng: 19.5237554 },
    { lat: 48.3273761, lng: 19.5236024 },
    { lat: 48.3276669, lng: 19.5229449 },
    { lat: 48.3278154, lng: 19.5227963 },
    { lat: 48.3278521, lng: 19.522688 },
    { lat: 48.327932, lng: 19.5226376 },
    { lat: 48.3280839, lng: 19.522705 },
    { lat: 48.328214, lng: 19.5225937 },
    { lat: 48.3281032, lng: 19.5222451 },
    { lat: 48.3281565, lng: 19.5220794 },
    { lat: 48.3282761, lng: 19.5219611 },
    { lat: 48.3282379, lng: 19.5215989 },
    { lat: 48.3284605, lng: 19.5215414 },
    { lat: 48.3286449, lng: 19.5212863 },
    { lat: 48.3288821, lng: 19.5212014 },
    { lat: 48.3289792, lng: 19.5210588 },
    { lat: 48.3292604, lng: 19.5210476 },
    { lat: 48.329382, lng: 19.5208756 },
    { lat: 48.3295206, lng: 19.5208533 },
    { lat: 48.3296355, lng: 19.5209936 },
    { lat: 48.3297871, lng: 19.5209835 },
    { lat: 48.330102, lng: 19.5208037 },
    { lat: 48.3303442, lng: 19.5208008 },
    { lat: 48.3306173, lng: 19.5210479 },
    { lat: 48.3307714, lng: 19.5209957 },
    { lat: 48.331007, lng: 19.5210693 },
    { lat: 48.3311059, lng: 19.5208185 },
    { lat: 48.3314558, lng: 19.5209293 },
    { lat: 48.331875, lng: 19.5205892 },
    { lat: 48.3320758, lng: 19.5205435 },
    { lat: 48.3323388, lng: 19.520701 },
    { lat: 48.3324895, lng: 19.5205688 },
    { lat: 48.3326079, lng: 19.5205961 },
    { lat: 48.3326681, lng: 19.5207024 },
    { lat: 48.3328195, lng: 19.5207596 },
    { lat: 48.3329082, lng: 19.5207068 },
    { lat: 48.3330472, lng: 19.5204253 },
    { lat: 48.3331744, lng: 19.5204684 },
    { lat: 48.3333339, lng: 19.5204301 },
    { lat: 48.3338618, lng: 19.5201523 },
    { lat: 48.3339769, lng: 19.5199604 },
    { lat: 48.3341704, lng: 19.5198818 },
    { lat: 48.3343559, lng: 19.5199457 },
    { lat: 48.3346538, lng: 19.5198545 },
    { lat: 48.3349049, lng: 19.5196523 },
    { lat: 48.3350614, lng: 19.5194571 },
    { lat: 48.3352871, lng: 19.5192944 },
    { lat: 48.3354463, lng: 19.5189123 },
    { lat: 48.3355086, lng: 19.5185537 },
    { lat: 48.3355858, lng: 19.5184933 },
    { lat: 48.3357444, lng: 19.5185111 },
    { lat: 48.3358426, lng: 19.518418 },
    { lat: 48.3359072, lng: 19.5181814 },
    { lat: 48.3363162, lng: 19.517571 },
    { lat: 48.3368269, lng: 19.5174061 },
    { lat: 48.3374278, lng: 19.5169758 },
    { lat: 48.3376743, lng: 19.517016 },
    { lat: 48.3377865, lng: 19.5168842 },
    { lat: 48.3379649, lng: 19.5168203 },
    { lat: 48.3380615, lng: 19.5166678 },
    { lat: 48.3382565, lng: 19.516736 },
    { lat: 48.3388222, lng: 19.5160313 },
    { lat: 48.3389152, lng: 19.5160213 },
    { lat: 48.3390579, lng: 19.5161587 },
    { lat: 48.3391015, lng: 19.5161014 },
    { lat: 48.3391172, lng: 19.5158585 },
    { lat: 48.3390668, lng: 19.5156948 },
    { lat: 48.3390958, lng: 19.5155912 },
    { lat: 48.3392872, lng: 19.5156084 },
    { lat: 48.3393904, lng: 19.5155523 },
    { lat: 48.3394807, lng: 19.5152861 },
    { lat: 48.3397696, lng: 19.515324 },
    { lat: 48.3399827, lng: 19.5151271 },
    { lat: 48.3403781, lng: 19.515123 },
    { lat: 48.3404805, lng: 19.5151924 },
    { lat: 48.3405355, lng: 19.5153131 },
    { lat: 48.3406325, lng: 19.5153136 },
    { lat: 48.3408043, lng: 19.5151771 },
    { lat: 48.3411864, lng: 19.5151786 },
    { lat: 48.3414995, lng: 19.5148824 },
    { lat: 48.3415632, lng: 19.5147731 },
    { lat: 48.3415876, lng: 19.5145456 },
    { lat: 48.3417785, lng: 19.5147067 },
    { lat: 48.3418613, lng: 19.514714 },
    { lat: 48.3419306, lng: 19.514604 },
    { lat: 48.3419968, lng: 19.5146007 },
    { lat: 48.3421671, lng: 19.5147706 },
    { lat: 48.3423357, lng: 19.5147389 },
    { lat: 48.34245, lng: 19.5148725 },
    { lat: 48.3427253, lng: 19.5149979 },
    { lat: 48.3429084, lng: 19.5149377 },
    { lat: 48.3430859, lng: 19.5149985 },
    { lat: 48.3432189, lng: 19.514928 },
    { lat: 48.343346, lng: 19.5149693 },
    { lat: 48.3434057, lng: 19.5152135 },
    { lat: 48.3435247, lng: 19.5152475 },
    { lat: 48.3436786, lng: 19.5150476 },
    { lat: 48.3438298, lng: 19.5149985 },
    { lat: 48.3442307, lng: 19.5149963 },
    { lat: 48.3443495, lng: 19.5150527 },
    { lat: 48.3445805, lng: 19.5147834 },
    { lat: 48.3447301, lng: 19.5150013 },
    { lat: 48.3450206, lng: 19.5150684 },
    { lat: 48.3452115, lng: 19.5147325 },
    { lat: 48.3453772, lng: 19.5146861 },
    { lat: 48.3455364, lng: 19.5143553 },
    { lat: 48.3458344, lng: 19.5142686 },
    { lat: 48.3460368, lng: 19.5140151 },
    { lat: 48.3462029, lng: 19.5140013 },
    { lat: 48.3463522, lng: 19.5138907 },
    { lat: 48.3464588, lng: 19.5136366 },
    { lat: 48.3466635, lng: 19.5135292 },
    { lat: 48.3467609, lng: 19.5132972 },
    { lat: 48.3468707, lng: 19.5131862 },
    { lat: 48.3472445, lng: 19.5131082 },
    { lat: 48.3473476, lng: 19.5128009 },
  ],
  Lučenec: [
    { lat: 48.3468568, lng: 19.6131346 },
    { lat: 48.3459633, lng: 19.6131598 },
    { lat: 48.34493, lng: 19.6135795 },
    { lat: 48.3439039, lng: 19.6144609 },
    { lat: 48.3433824, lng: 19.6147839 },
    { lat: 48.3418136, lng: 19.6165153 },
    { lat: 48.3402936, lng: 19.6185746 },
    { lat: 48.3395704, lng: 19.6198181 },
    { lat: 48.3394525, lng: 19.6199265 },
    { lat: 48.3389271, lng: 19.6207406 },
    { lat: 48.3377454, lng: 19.6198028 },
    { lat: 48.3376556, lng: 19.6194447 },
    { lat: 48.3375496, lng: 19.61944 },
    { lat: 48.3371765, lng: 19.617429 },
    { lat: 48.3370189, lng: 19.616984 },
    { lat: 48.336571, lng: 19.6166285 },
    { lat: 48.3361677, lng: 19.6165292 },
    { lat: 48.3358627, lng: 19.6165887 },
    { lat: 48.3356745, lng: 19.6164897 },
    { lat: 48.3354835, lng: 19.6161306 },
    { lat: 48.3349444, lng: 19.615674 },
    { lat: 48.3344883, lng: 19.6157278 },
    { lat: 48.3343295, lng: 19.6156584 },
    { lat: 48.3341178, lng: 19.6155541 },
    { lat: 48.333342, lng: 19.6148937 },
    { lat: 48.3328677, lng: 19.6144764 },
    { lat: 48.3325211, lng: 19.6139789 },
    { lat: 48.3324682, lng: 19.6131135 },
    { lat: 48.3322004, lng: 19.6120803 },
    { lat: 48.3316474, lng: 19.6108712 },
    { lat: 48.3300577, lng: 19.6107299 },
    { lat: 48.3293745, lng: 19.610723 },
    { lat: 48.3286407, lng: 19.6108197 },
    { lat: 48.3269278, lng: 19.6106425 },
    { lat: 48.3252729, lng: 19.6109263 },
    { lat: 48.3245969, lng: 19.6109276 },
    { lat: 48.3231025, lng: 19.6116177 },
    { lat: 48.3214806, lng: 19.6119831 },
    { lat: 48.320359, lng: 19.6117761 },
    { lat: 48.3189862, lng: 19.6120891 },
    { lat: 48.3174526, lng: 19.6132784 },
    { lat: 48.3154065, lng: 19.6134326 },
    { lat: 48.3141038, lng: 19.6137281 },
    { lat: 48.3117721, lng: 19.6150845 },
    { lat: 48.3070708, lng: 19.6171865 },
    { lat: 48.3030671, lng: 19.6200606 },
    { lat: 48.3033736, lng: 19.6217437 },
    { lat: 48.303089, lng: 19.6228225 },
    { lat: 48.3029174, lng: 19.6230878 },
    { lat: 48.3028647, lng: 19.6232903 },
    { lat: 48.3028915, lng: 19.6237544 },
    { lat: 48.3031781, lng: 19.6247837 },
    { lat: 48.3031861, lng: 19.6250579 },
    { lat: 48.3032819, lng: 19.6253062 },
    { lat: 48.3032627, lng: 19.6254543 },
    { lat: 48.3031043, lng: 19.6257359 },
    { lat: 48.3032627, lng: 19.6259997 },
    { lat: 48.3035362, lng: 19.6261689 },
    { lat: 48.3036429, lng: 19.6263803 },
    { lat: 48.3036927, lng: 19.6267596 },
    { lat: 48.303826, lng: 19.6270642 },
    { lat: 48.3042198, lng: 19.6272767 },
    { lat: 48.3043306, lng: 19.6274059 },
    { lat: 48.3043722, lng: 19.6276271 },
    { lat: 48.3045099, lng: 19.6277313 },
    { lat: 48.3046587, lng: 19.6279826 },
    { lat: 48.3046723, lng: 19.6284052 },
    { lat: 48.3045275, lng: 19.6284681 },
    { lat: 48.3044258, lng: 19.6288328 },
    { lat: 48.3043504, lng: 19.6288373 },
    { lat: 48.3043034, lng: 19.6290644 },
    { lat: 48.3043671, lng: 19.6292306 },
    { lat: 48.3042675, lng: 19.6293922 },
    { lat: 48.3042569, lng: 19.6295069 },
    { lat: 48.3041306, lng: 19.6295018 },
    { lat: 48.3040831, lng: 19.6296007 },
    { lat: 48.3039119, lng: 19.6296741 },
    { lat: 48.3038492, lng: 19.6297849 },
    { lat: 48.3037055, lng: 19.6295745 },
    { lat: 48.3035412, lng: 19.629628 },
    { lat: 48.3035073, lng: 19.6295243 },
    { lat: 48.303476, lng: 19.6296524 },
    { lat: 48.3033194, lng: 19.6298032 },
    { lat: 48.3029958, lng: 19.6297276 },
    { lat: 48.3029446, lng: 19.6298379 },
    { lat: 48.3029668, lng: 19.6299577 },
    { lat: 48.3028638, lng: 19.629872 },
    { lat: 48.3028262, lng: 19.6299133 },
    { lat: 48.3028095, lng: 19.6300651 },
    { lat: 48.3029251, lng: 19.6301046 },
    { lat: 48.3029386, lng: 19.6302515 },
    { lat: 48.3027854, lng: 19.6305029 },
    { lat: 48.3027783, lng: 19.6307938 },
    { lat: 48.3028623, lng: 19.6308539 },
    { lat: 48.3027484, lng: 19.6309034 },
    { lat: 48.302794, lng: 19.631086 },
    { lat: 48.3027466, lng: 19.6311871 },
    { lat: 48.3026368, lng: 19.6311505 },
    { lat: 48.3026482, lng: 19.6313291 },
    { lat: 48.302535, lng: 19.6313644 },
    { lat: 48.3025236, lng: 19.6314607 },
    { lat: 48.3025702, lng: 19.6314946 },
    { lat: 48.302605, lng: 19.631668 },
    { lat: 48.3024528, lng: 19.6317642 },
    { lat: 48.3023769, lng: 19.631682 },
    { lat: 48.3023557, lng: 19.6317829 },
    { lat: 48.3022561, lng: 19.6317 },
    { lat: 48.3022599, lng: 19.6318111 },
    { lat: 48.3020805, lng: 19.6318269 },
    { lat: 48.3020562, lng: 19.6318899 },
    { lat: 48.3019883, lng: 19.6318084 },
    { lat: 48.3018669, lng: 19.6319127 },
    { lat: 48.3017992, lng: 19.6318156 },
    { lat: 48.3017791, lng: 19.631893 },
    { lat: 48.301685, lng: 19.6319518 },
    { lat: 48.301678, lng: 19.6321461 },
    { lat: 48.3015913, lng: 19.6320983 },
    { lat: 48.3015528, lng: 19.6322197 },
    { lat: 48.3014621, lng: 19.6323062 },
    { lat: 48.3014602, lng: 19.6325357 },
    { lat: 48.3015184, lng: 19.6325004 },
    { lat: 48.3015799, lng: 19.6326173 },
    { lat: 48.3014971, lng: 19.6328023 },
    { lat: 48.3014226, lng: 19.632898 },
    { lat: 48.3013465, lng: 19.6328403 },
    { lat: 48.301179, lng: 19.6328485 },
    { lat: 48.3011124, lng: 19.6329406 },
    { lat: 48.3010615, lng: 19.63311 },
    { lat: 48.3010723, lng: 19.6333276 },
    { lat: 48.3009721, lng: 19.6336482 },
    { lat: 48.3009176, lng: 19.6336923 },
    { lat: 48.3007426, lng: 19.6335612 },
    { lat: 48.3006798, lng: 19.6336178 },
    { lat: 48.3006625, lng: 19.6341798 },
    { lat: 48.3010714, lng: 19.6340992 },
    { lat: 48.3014896, lng: 19.6345535 },
    { lat: 48.3016709, lng: 19.6351448 },
    { lat: 48.3017879, lng: 19.6358616 },
    { lat: 48.3015195, lng: 19.6374249 },
    { lat: 48.3015784, lng: 19.6385214 },
    { lat: 48.3017751, lng: 19.6392364 },
    { lat: 48.301916, lng: 19.6399801 },
    { lat: 48.3018478, lng: 19.640392 },
    { lat: 48.3019799, lng: 19.6418635 },
    { lat: 48.3017941, lng: 19.6423404 },
    { lat: 48.3017845, lng: 19.6426486 },
    { lat: 48.3017188, lng: 19.6427671 },
    { lat: 48.3018514, lng: 19.6438553 },
    { lat: 48.3018619, lng: 19.6447351 },
    { lat: 48.3016803, lng: 19.6457771 },
    { lat: 48.3022919, lng: 19.6465933 },
    { lat: 48.3027813, lng: 19.6465234 },
    { lat: 48.3034435, lng: 19.6466929 },
    { lat: 48.3036859, lng: 19.6469206 },
    { lat: 48.304049, lng: 19.6474461 },
    { lat: 48.304065, lng: 19.6481027 },
    { lat: 48.3042023, lng: 19.6485949 },
    { lat: 48.3042986, lng: 19.6493507 },
    { lat: 48.3044604, lng: 19.6499164 },
    { lat: 48.30446, lng: 19.6502778 },
    { lat: 48.3042436, lng: 19.6509546 },
    { lat: 48.3041197, lng: 19.6519744 },
    { lat: 48.3041057, lng: 19.6528362 },
    { lat: 48.3042305, lng: 19.6533555 },
    { lat: 48.3041732, lng: 19.6537078 },
    { lat: 48.3035259, lng: 19.6549967 },
    { lat: 48.3029583, lng: 19.6559221 },
    { lat: 48.3025247, lng: 19.6564807 },
    { lat: 48.3024471, lng: 19.6566818 },
    { lat: 48.3022732, lng: 19.657547 },
    { lat: 48.3022441, lng: 19.6581247 },
    { lat: 48.3019465, lng: 19.6588709 },
    { lat: 48.3015094, lng: 19.6593218 },
    { lat: 48.3008222, lng: 19.6598264 },
    { lat: 48.3004753, lng: 19.6602017 },
    { lat: 48.3002318, lng: 19.6606504 },
    { lat: 48.2991389, lng: 19.6644469 },
    { lat: 48.2988642, lng: 19.6655774 },
    { lat: 48.2987476, lng: 19.6659183 },
    { lat: 48.3000083, lng: 19.6661608 },
    { lat: 48.3010281, lng: 19.6661128 },
    { lat: 48.3024376, lng: 19.6665618 },
    { lat: 48.3036451, lng: 19.6670259 },
    { lat: 48.3047715, lng: 19.6671911 },
    { lat: 48.3065857, lng: 19.667246 },
    { lat: 48.306701, lng: 19.6670757 },
    { lat: 48.307208, lng: 19.6672017 },
    { lat: 48.3097714, lng: 19.6709528 },
    { lat: 48.3097676, lng: 19.6719949 },
    { lat: 48.3096435, lng: 19.6737443 },
    { lat: 48.3095596, lng: 19.6741054 },
    { lat: 48.3083065, lng: 19.6773369 },
    { lat: 48.3074742, lng: 19.6788226 },
    { lat: 48.3068607, lng: 19.6807403 },
    { lat: 48.3074042, lng: 19.6823779 },
    { lat: 48.307436, lng: 19.6826858 },
    { lat: 48.307883, lng: 19.6838058 },
    { lat: 48.3073993, lng: 19.6878902 },
    { lat: 48.3071288, lng: 19.6894709 },
    { lat: 48.3069496, lng: 19.6901229 },
    { lat: 48.3059315, lng: 19.6920189 },
    { lat: 48.3057148, lng: 19.6923146 },
    { lat: 48.3054053, lng: 19.6931747 },
    { lat: 48.3051364, lng: 19.6933593 },
    { lat: 48.3050218, lng: 19.6936409 },
    { lat: 48.3048316, lng: 19.6938752 },
    { lat: 48.3047175, lng: 19.6942965 },
    { lat: 48.3044615, lng: 19.6946419 },
    { lat: 48.3044414, lng: 19.6948736 },
    { lat: 48.3046092, lng: 19.695119 },
    { lat: 48.3045094, lng: 19.6952575 },
    { lat: 48.3044505, lng: 19.6956025 },
    { lat: 48.3044772, lng: 19.6957459 },
    { lat: 48.3046246, lng: 19.6958674 },
    { lat: 48.3046358, lng: 19.6961186 },
    { lat: 48.3042908, lng: 19.6965656 },
    { lat: 48.3041913, lng: 19.6964567 },
    { lat: 48.303994, lng: 19.6965077 },
    { lat: 48.3037861, lng: 19.6968691 },
    { lat: 48.3035525, lng: 19.6967872 },
    { lat: 48.3033647, lng: 19.6969954 },
    { lat: 48.3031274, lng: 19.6968544 },
    { lat: 48.3030864, lng: 19.6965449 },
    { lat: 48.302831, lng: 19.6966786 },
    { lat: 48.30254, lng: 19.6965975 },
    { lat: 48.3024696, lng: 19.6966584 },
    { lat: 48.3022148, lng: 19.6972842 },
    { lat: 48.3021429, lng: 19.6970133 },
    { lat: 48.3020566, lng: 19.6968946 },
    { lat: 48.3017961, lng: 19.6970684 },
    { lat: 48.3015848, lng: 19.6970312 },
    { lat: 48.3012815, lng: 19.6972023 },
    { lat: 48.3012591, lng: 19.6973287 },
    { lat: 48.3014256, lng: 19.6975986 },
    { lat: 48.3013836, lng: 19.6978676 },
    { lat: 48.3013381, lng: 19.6979114 },
    { lat: 48.3011695, lng: 19.6978431 },
    { lat: 48.3011108, lng: 19.6980461 },
    { lat: 48.3009819, lng: 19.6981568 },
    { lat: 48.3009112, lng: 19.6981639 },
    { lat: 48.3007563, lng: 19.6980008 },
    { lat: 48.3006932, lng: 19.6980262 },
    { lat: 48.3005528, lng: 19.6984647 },
    { lat: 48.3002908, lng: 19.6984776 },
    { lat: 48.300207, lng: 19.6985426 },
    { lat: 48.3000924, lng: 19.6989512 },
    { lat: 48.2995843, lng: 19.6995576 },
    { lat: 48.2996493, lng: 19.6997549 },
    { lat: 48.2995699, lng: 19.7000159 },
    { lat: 48.2992313, lng: 19.7001052 },
    { lat: 48.2991987, lng: 19.7001842 },
    { lat: 48.2992645, lng: 19.7005679 },
    { lat: 48.2990727, lng: 19.7005143 },
    { lat: 48.2987788, lng: 19.7005941 },
    { lat: 48.2987276, lng: 19.7012581 },
    { lat: 48.2983755, lng: 19.7015267 },
    { lat: 48.2982724, lng: 19.7017646 },
    { lat: 48.2980307, lng: 19.7017525 },
    { lat: 48.29785, lng: 19.7018924 },
    { lat: 48.297781, lng: 19.7021923 },
    { lat: 48.2975874, lng: 19.7021449 },
    { lat: 48.2974117, lng: 19.7025497 },
    { lat: 48.2969449, lng: 19.7027184 },
    { lat: 48.2968448, lng: 19.7028466 },
    { lat: 48.2967926, lng: 19.703647 },
    { lat: 48.296875, lng: 19.7043277 },
    { lat: 48.2968578, lng: 19.7050778 },
    { lat: 48.2968851, lng: 19.7050605 },
    { lat: 48.2968956, lng: 19.7051879 },
    { lat: 48.2970595, lng: 19.7051272 },
    { lat: 48.2972997, lng: 19.7053066 },
    { lat: 48.2973607, lng: 19.7052874 },
    { lat: 48.2974624, lng: 19.7052191 },
    { lat: 48.2978037, lng: 19.7046345 },
    { lat: 48.2980433, lng: 19.7044495 },
    { lat: 48.2984146, lng: 19.7045413 },
    { lat: 48.29849, lng: 19.7047655 },
    { lat: 48.2984398, lng: 19.705553 },
    { lat: 48.2985503, lng: 19.7058327 },
    { lat: 48.2985351, lng: 19.706502 },
    { lat: 48.298645, lng: 19.7066672 },
    { lat: 48.2985977, lng: 19.7069936 },
    { lat: 48.3024098, lng: 19.7124725 },
    { lat: 48.3025017, lng: 19.7127861 },
    { lat: 48.3026038, lng: 19.7139415 },
    { lat: 48.3027106, lng: 19.7143874 },
    { lat: 48.3037021, lng: 19.7171651 },
    { lat: 48.3038951, lng: 19.7192947 },
    { lat: 48.3038965, lng: 19.7194014 },
    { lat: 48.3042108, lng: 19.7195759 },
    { lat: 48.3062108, lng: 19.7203586 },
    { lat: 48.3073528, lng: 19.7205737 },
    { lat: 48.3091725, lng: 19.7211027 },
    { lat: 48.310042, lng: 19.7214237 },
    { lat: 48.3102659, lng: 19.7216211 },
    { lat: 48.3128546, lng: 19.7207741 },
    { lat: 48.3131237, lng: 19.7207684 },
    { lat: 48.3138612, lng: 19.7210885 },
    { lat: 48.3141051, lng: 19.7217564 },
    { lat: 48.3144003, lng: 19.7218588 },
    { lat: 48.3153588, lng: 19.7223809 },
    { lat: 48.3165091, lng: 19.7220989 },
    { lat: 48.3181789, lng: 19.7251321 },
    { lat: 48.318908, lng: 19.7257674 },
    { lat: 48.3206924, lng: 19.723915 },
    { lat: 48.3215857, lng: 19.7231217 },
    { lat: 48.3218165, lng: 19.7228171 },
    { lat: 48.3219238, lng: 19.7225713 },
    { lat: 48.3222547, lng: 19.7213246 },
    { lat: 48.3226277, lng: 19.7201883 },
    { lat: 48.3235002, lng: 19.7182471 },
    { lat: 48.3241523, lng: 19.7171305 },
    { lat: 48.324518, lng: 19.7166424 },
    { lat: 48.3249559, lng: 19.7162285 },
    { lat: 48.3276402, lng: 19.7127046 },
    { lat: 48.3283808, lng: 19.7110698 },
    { lat: 48.3287516, lng: 19.7099619 },
    { lat: 48.3290957, lng: 19.7092181 },
    { lat: 48.3292399, lng: 19.7090899 },
    { lat: 48.3293676, lng: 19.7100041 },
    { lat: 48.3296092, lng: 19.7110258 },
    { lat: 48.331328, lng: 19.7171224 },
    { lat: 48.3332943, lng: 19.7161914 },
    { lat: 48.3347384, lng: 19.7157264 },
    { lat: 48.3352119, lng: 19.7145166 },
    { lat: 48.3356884, lng: 19.7131051 },
    { lat: 48.3360301, lng: 19.7116943 },
    { lat: 48.3365189, lng: 19.7118421 },
    { lat: 48.337952, lng: 19.7124705 },
    { lat: 48.3384998, lng: 19.7128047 },
    { lat: 48.3394861, lng: 19.713267 },
    { lat: 48.3409091, lng: 19.713848 },
    { lat: 48.3427996, lng: 19.7149265 },
    { lat: 48.3442401, lng: 19.7159255 },
    { lat: 48.3462677, lng: 19.7169249 },
    { lat: 48.3472688, lng: 19.7174921 },
    { lat: 48.3476389, lng: 19.7180341 },
    { lat: 48.3478652, lng: 19.7185597 },
    { lat: 48.3479111, lng: 19.7187797 },
    { lat: 48.3483403, lng: 19.7195637 },
    { lat: 48.3484919, lng: 19.7207397 },
    { lat: 48.3488668, lng: 19.7222482 },
    { lat: 48.3491103, lng: 19.7228869 },
    { lat: 48.3493084, lng: 19.7240689 },
    { lat: 48.3494957, lng: 19.7242301 },
    { lat: 48.3495389, lng: 19.7251999 },
    { lat: 48.350189, lng: 19.7288675 },
    { lat: 48.3505518, lng: 19.7305917 },
    { lat: 48.3509471, lng: 19.7332945 },
    { lat: 48.3511871, lng: 19.7344876 },
    { lat: 48.3515488, lng: 19.7360862 },
    { lat: 48.3521278, lng: 19.7380131 },
    { lat: 48.3522515, lng: 19.7378049 },
    { lat: 48.3523196, lng: 19.7376907 },
    { lat: 48.3527972, lng: 19.7366423 },
    { lat: 48.3542725, lng: 19.734625 },
    { lat: 48.3546658, lng: 19.7341734 },
    { lat: 48.3577694, lng: 19.7308902 },
    { lat: 48.3598654, lng: 19.7294754 },
    { lat: 48.3611093, lng: 19.7315061 },
    { lat: 48.3610525, lng: 19.7332177 },
    { lat: 48.3612189, lng: 19.7338175 },
    { lat: 48.3617873, lng: 19.7348179 },
    { lat: 48.3620017, lng: 19.7350529 },
    { lat: 48.3622291, lng: 19.735579 },
    { lat: 48.3625959, lng: 19.7361776 },
    { lat: 48.3638523, lng: 19.734606 },
    { lat: 48.364533, lng: 19.73357 },
    { lat: 48.365315, lng: 19.732136 },
    { lat: 48.365469, lng: 19.731849 },
    { lat: 48.365754, lng: 19.731317 },
    { lat: 48.366117, lng: 19.730322 },
    { lat: 48.366579, lng: 19.729208 },
    { lat: 48.366994, lng: 19.728645 },
    { lat: 48.367433, lng: 19.727709 },
    { lat: 48.367846, lng: 19.726995 },
    { lat: 48.368347, lng: 19.726207 },
    { lat: 48.368884, lng: 19.72565 },
    { lat: 48.368609, lng: 19.724812 },
    { lat: 48.368421, lng: 19.724241 },
    { lat: 48.368255, lng: 19.72335 },
    { lat: 48.368123, lng: 19.722639 },
    { lat: 48.367998, lng: 19.72187 },
    { lat: 48.367673, lng: 19.720156 },
    { lat: 48.367422, lng: 19.718798 },
    { lat: 48.366238, lng: 19.717756 },
    { lat: 48.365305, lng: 19.716539 },
    { lat: 48.364992, lng: 19.715708 },
    { lat: 48.364356, lng: 19.714019 },
    { lat: 48.364164, lng: 19.71296 },
    { lat: 48.364185, lng: 19.71238 },
    { lat: 48.364215, lng: 19.711558 },
    { lat: 48.364226, lng: 19.711269 },
    { lat: 48.364262, lng: 19.710801 },
    { lat: 48.36448, lng: 19.709903 },
    { lat: 48.364535, lng: 19.709657 },
    { lat: 48.364723, lng: 19.708517 },
    { lat: 48.364801, lng: 19.707893 },
    { lat: 48.364804, lng: 19.707865 },
    { lat: 48.364809, lng: 19.707845 },
    { lat: 48.364847, lng: 19.707713 },
    { lat: 48.364858, lng: 19.707673 },
    { lat: 48.364865, lng: 19.707658 },
    { lat: 48.364879, lng: 19.707325 },
    { lat: 48.364888, lng: 19.707119 },
    { lat: 48.3649, lng: 19.705913 },
    { lat: 48.365268, lng: 19.703856 },
    { lat: 48.365286, lng: 19.703764 },
    { lat: 48.365325, lng: 19.703539 },
    { lat: 48.365512, lng: 19.702623 },
    { lat: 48.365672, lng: 19.701513 },
    { lat: 48.36574, lng: 19.700846 },
    { lat: 48.365963, lng: 19.699936 },
    { lat: 48.365964, lng: 19.69963 },
    { lat: 48.365695, lng: 19.698423 },
    { lat: 48.365686, lng: 19.698235 },
    { lat: 48.365791, lng: 19.697319 },
    { lat: 48.366031, lng: 19.696654 },
    { lat: 48.366032, lng: 19.696531 },
    { lat: 48.366033, lng: 19.695653 },
    { lat: 48.366012, lng: 19.695342 },
    { lat: 48.366009, lng: 19.695297 },
    { lat: 48.365992, lng: 19.695063 },
    { lat: 48.365941, lng: 19.695073 },
    { lat: 48.365927, lng: 19.695006 },
    { lat: 48.365725, lng: 19.693866 },
    { lat: 48.365701, lng: 19.69373 },
    { lat: 48.365666, lng: 19.69277 },
    { lat: 48.365666, lng: 19.692698 },
    { lat: 48.365666, lng: 19.692627 },
    { lat: 48.365821, lng: 19.692087 },
    { lat: 48.365838, lng: 19.692029 },
    { lat: 48.365857, lng: 19.691961 },
    { lat: 48.365852, lng: 19.691958 },
    { lat: 48.365802, lng: 19.691927 },
    { lat: 48.365797, lng: 19.691925 },
    { lat: 48.36485, lng: 19.691381 },
    { lat: 48.364808, lng: 19.691346 },
    { lat: 48.36382, lng: 19.690788 },
    { lat: 48.362618, lng: 19.690085 },
    { lat: 48.362148, lng: 19.689674 },
    { lat: 48.361915, lng: 19.689277 },
    { lat: 48.361778, lng: 19.688855 },
    { lat: 48.361679, lng: 19.68839 },
    { lat: 48.361635, lng: 19.688068 },
    { lat: 48.361631, lng: 19.688036 },
    { lat: 48.361626, lng: 19.688004 },
    { lat: 48.36161, lng: 19.687884 },
    { lat: 48.361606, lng: 19.687856 },
    { lat: 48.361602, lng: 19.687827 },
    { lat: 48.361537, lng: 19.687348 },
    { lat: 48.361533, lng: 19.687314 },
    { lat: 48.361528, lng: 19.687281 },
    { lat: 48.361513, lng: 19.687167 },
    { lat: 48.36145, lng: 19.686872 },
    { lat: 48.361355, lng: 19.686632 },
    { lat: 48.361334, lng: 19.686584 },
    { lat: 48.361316, lng: 19.686536 },
    { lat: 48.361273, lng: 19.68643 },
    { lat: 48.361074, lng: 19.686189 },
    { lat: 48.360808, lng: 19.68602 },
    { lat: 48.360785, lng: 19.686014 },
    { lat: 48.360758, lng: 19.686007 },
    { lat: 48.360731, lng: 19.685999 },
    { lat: 48.360694, lng: 19.685988 },
    { lat: 48.360651, lng: 19.685977 },
    { lat: 48.360387, lng: 19.685902 },
    { lat: 48.360382, lng: 19.685901 },
    { lat: 48.360323, lng: 19.685896 },
    { lat: 48.360319, lng: 19.685895 },
    { lat: 48.360293, lng: 19.685898 },
    { lat: 48.360292, lng: 19.68583 },
    { lat: 48.360259, lng: 19.685571 },
    { lat: 48.360109, lng: 19.684853 },
    { lat: 48.360061, lng: 19.684512 },
    { lat: 48.360057, lng: 19.684455 },
    { lat: 48.360057, lng: 19.684449 },
    { lat: 48.360056, lng: 19.684427 },
    { lat: 48.360055, lng: 19.684401 },
    { lat: 48.360054, lng: 19.684395 },
    { lat: 48.360101, lng: 19.684145 },
    { lat: 48.360679, lng: 19.681039 },
    { lat: 48.36094, lng: 19.680379 },
    { lat: 48.361137, lng: 19.680094 },
    { lat: 48.361435, lng: 19.679663 },
    { lat: 48.361784, lng: 19.677946 },
    { lat: 48.36211, lng: 19.675999 },
    { lat: 48.362197, lng: 19.675474 },
    { lat: 48.362163, lng: 19.67388 },
    { lat: 48.3608564, lng: 19.6743046 },
    { lat: 48.3607336, lng: 19.6742522 },
    { lat: 48.3606437, lng: 19.6739605 },
    { lat: 48.3602855, lng: 19.6736591 },
    { lat: 48.3601045, lng: 19.6731165 },
    { lat: 48.3593378, lng: 19.6719545 },
    { lat: 48.359006, lng: 19.6713188 },
    { lat: 48.3585743, lng: 19.6707857 },
    { lat: 48.358278, lng: 19.6706609 },
    { lat: 48.3579407, lng: 19.670328 },
    { lat: 48.357732, lng: 19.6704225 },
    { lat: 48.3576023, lng: 19.6702227 },
    { lat: 48.3575061, lng: 19.6706335 },
    { lat: 48.3573987, lng: 19.6705502 },
    { lat: 48.3573711, lng: 19.6707939 },
    { lat: 48.3569014, lng: 19.6710955 },
    { lat: 48.356321, lng: 19.6707433 },
    { lat: 48.3562493, lng: 19.6705769 },
    { lat: 48.355928, lng: 19.6703739 },
    { lat: 48.3552754, lng: 19.6690417 },
    { lat: 48.3550965, lng: 19.6679215 },
    { lat: 48.3540908, lng: 19.6666297 },
    { lat: 48.3528522, lng: 19.6683785 },
    { lat: 48.3522861, lng: 19.6690668 },
    { lat: 48.3518381, lng: 19.6694285 },
    { lat: 48.3514794, lng: 19.6693696 },
    { lat: 48.3511738, lng: 19.6691168 },
    { lat: 48.3514446, lng: 19.6684956 },
    { lat: 48.3507201, lng: 19.6685478 },
    { lat: 48.3502082, lng: 19.6684625 },
    { lat: 48.3496579, lng: 19.6686543 },
    { lat: 48.3494193, lng: 19.6686615 },
    { lat: 48.3483833, lng: 19.6692783 },
    { lat: 48.3481029, lng: 19.6686819 },
    { lat: 48.3473858, lng: 19.6674166 },
    { lat: 48.3459896, lng: 19.6643858 },
    { lat: 48.3457842, lng: 19.6640822 },
    { lat: 48.3453547, lng: 19.6645288 },
    { lat: 48.3453059, lng: 19.6640223 },
    { lat: 48.3457475, lng: 19.6636923 },
    { lat: 48.3459609, lng: 19.663326 },
    { lat: 48.3457214, lng: 19.6629876 },
    { lat: 48.3453419, lng: 19.6626925 },
    { lat: 48.3454441, lng: 19.6620126 },
    { lat: 48.3456077, lng: 19.6620169 },
    { lat: 48.3467625, lng: 19.661638 },
    { lat: 48.3464398, lng: 19.6594296 },
    { lat: 48.346653, lng: 19.6594346 },
    { lat: 48.3470091, lng: 19.6592351 },
    { lat: 48.3472647, lng: 19.6592081 },
    { lat: 48.3471671, lng: 19.6587653 },
    { lat: 48.3472897, lng: 19.6587823 },
    { lat: 48.3474236, lng: 19.6586568 },
    { lat: 48.3475557, lng: 19.6582626 },
    { lat: 48.3476239, lng: 19.6582503 },
    { lat: 48.3477854, lng: 19.6584805 },
    { lat: 48.3479468, lng: 19.6584163 },
    { lat: 48.3479756, lng: 19.6583514 },
    { lat: 48.3479464, lng: 19.6581287 },
    { lat: 48.3478354, lng: 19.6577407 },
    { lat: 48.3479707, lng: 19.6576951 },
    { lat: 48.3480863, lng: 19.6577872 },
    { lat: 48.3484641, lng: 19.6583414 },
    { lat: 48.348596, lng: 19.6583652 },
    { lat: 48.3487175, lng: 19.6582644 },
    { lat: 48.3487859, lng: 19.6580785 },
    { lat: 48.3487076, lng: 19.657745 },
    { lat: 48.3487963, lng: 19.6576403 },
    { lat: 48.3488167, lng: 19.657515 },
    { lat: 48.3487782, lng: 19.657436 },
    { lat: 48.3491445, lng: 19.6572603 },
    { lat: 48.3491018, lng: 19.6570684 },
    { lat: 48.3491413, lng: 19.6567445 },
    { lat: 48.3493824, lng: 19.6565663 },
    { lat: 48.3495803, lng: 19.6567088 },
    { lat: 48.3498703, lng: 19.6566166 },
    { lat: 48.3498908, lng: 19.656396 },
    { lat: 48.3498021, lng: 19.6562297 },
    { lat: 48.3498364, lng: 19.656037 },
    { lat: 48.3499568, lng: 19.6560361 },
    { lat: 48.350367, lng: 19.6564366 },
    { lat: 48.350595, lng: 19.6564708 },
    { lat: 48.3506577, lng: 19.6564109 },
    { lat: 48.3507071, lng: 19.6564849 },
    { lat: 48.3511787, lng: 19.6558213 },
    { lat: 48.3512501, lng: 19.6556313 },
    { lat: 48.350851, lng: 19.6554518 },
    { lat: 48.3510095, lng: 19.6548934 },
    { lat: 48.3514275, lng: 19.6546437 },
    { lat: 48.351659, lng: 19.6547555 },
    { lat: 48.3520747, lng: 19.6553502 },
    { lat: 48.3524312, lng: 19.6547628 },
    { lat: 48.3526993, lng: 19.654461 },
    { lat: 48.3534562, lng: 19.6540298 },
    { lat: 48.3547925, lng: 19.6531004 },
    { lat: 48.356006, lng: 19.6523546 },
    { lat: 48.3562501, lng: 19.6521183 },
    { lat: 48.3561628, lng: 19.6516326 },
    { lat: 48.3563256, lng: 19.6511711 },
    { lat: 48.3562287, lng: 19.6509949 },
    { lat: 48.356531, lng: 19.6506301 },
    { lat: 48.3567734, lng: 19.6503312 },
    { lat: 48.3563425, lng: 19.6495999 },
    { lat: 48.3554541, lng: 19.648441 },
    { lat: 48.3557622, lng: 19.6477305 },
    { lat: 48.355771, lng: 19.6473505 },
    { lat: 48.3559459, lng: 19.646435 },
    { lat: 48.355514, lng: 19.6460991 },
    { lat: 48.355171, lng: 19.6462641 },
    { lat: 48.3550876, lng: 19.6461673 },
    { lat: 48.3550005, lng: 19.6463094 },
    { lat: 48.3548556, lng: 19.6463688 },
    { lat: 48.3538726, lng: 19.645288 },
    { lat: 48.3532517, lng: 19.6444117 },
    { lat: 48.3529842, lng: 19.6448254 },
    { lat: 48.352475, lng: 19.6452556 },
    { lat: 48.3522969, lng: 19.6448529 },
    { lat: 48.352406, lng: 19.6446487 },
    { lat: 48.3523648, lng: 19.6445105 },
    { lat: 48.3521163, lng: 19.6447221 },
    { lat: 48.3521149, lng: 19.6444672 },
    { lat: 48.3521792, lng: 19.6443186 },
    { lat: 48.352068, lng: 19.6430082 },
    { lat: 48.3521117, lng: 19.6425028 },
    { lat: 48.3520143, lng: 19.6422913 },
    { lat: 48.3520423, lng: 19.6419175 },
    { lat: 48.3522052, lng: 19.6412339 },
    { lat: 48.3522472, lng: 19.6406443 },
    { lat: 48.3523549, lng: 19.6401838 },
    { lat: 48.3523405, lng: 19.6400652 },
    { lat: 48.3525081, lng: 19.639809 },
    { lat: 48.3525348, lng: 19.6396515 },
    { lat: 48.3524734, lng: 19.639465 },
    { lat: 48.3525194, lng: 19.6392382 },
    { lat: 48.3524818, lng: 19.6388782 },
    { lat: 48.3525965, lng: 19.6386256 },
    { lat: 48.3525656, lng: 19.6384913 },
    { lat: 48.3526096, lng: 19.6381442 },
    { lat: 48.3527371, lng: 19.6378726 },
    { lat: 48.3530507, lng: 19.6369072 },
    { lat: 48.3532079, lng: 19.6365733 },
    { lat: 48.3533537, lng: 19.63646 },
    { lat: 48.3535043, lng: 19.6360801 },
    { lat: 48.3538078, lng: 19.6355649 },
    { lat: 48.3538213, lng: 19.6353878 },
    { lat: 48.3539664, lng: 19.635058 },
    { lat: 48.3540641, lng: 19.6350519 },
    { lat: 48.3541558, lng: 19.6349377 },
    { lat: 48.3541528, lng: 19.6348711 },
    { lat: 48.3543634, lng: 19.6345949 },
    { lat: 48.3543938, lng: 19.6344217 },
    { lat: 48.3544879, lng: 19.6344341 },
    { lat: 48.3546763, lng: 19.6341162 },
    { lat: 48.3548179, lng: 19.6340061 },
    { lat: 48.3548478, lng: 19.633821 },
    { lat: 48.3549999, lng: 19.6337686 },
    { lat: 48.3552465, lng: 19.6334168 },
    { lat: 48.3537112, lng: 19.6307955 },
    { lat: 48.3519669, lng: 19.6279637 },
    { lat: 48.3507396, lng: 19.6238855 },
    { lat: 48.3495042, lng: 19.620974 },
    { lat: 48.3486613, lng: 19.6185182 },
    { lat: 48.3478394, lng: 19.6153422 },
    { lat: 48.347176, lng: 19.6138599 },
    { lat: 48.3468568, lng: 19.6131346 },
  ],
  Rapovce: [
    { lat: 48.2530513, lng: 19.6755887 },
    { lat: 48.2529308, lng: 19.6755139 },
    { lat: 48.2528669, lng: 19.6758237 },
    { lat: 48.2527637, lng: 19.6760604 },
    { lat: 48.2524346, lng: 19.6767322 },
    { lat: 48.2520475, lng: 19.6773695 },
    { lat: 48.2519317, lng: 19.6777248 },
    { lat: 48.2519477, lng: 19.6778279 },
    { lat: 48.2526556, lng: 19.6786368 },
    { lat: 48.2527472, lng: 19.6790444 },
    { lat: 48.2529169, lng: 19.6793642 },
    { lat: 48.2530177, lng: 19.6794 },
    { lat: 48.2531629, lng: 19.6797198 },
    { lat: 48.253187, lng: 19.6799291 },
    { lat: 48.2529826, lng: 19.6801927 },
    { lat: 48.2534381, lng: 19.6814531 },
    { lat: 48.2542895, lng: 19.6821713 },
    { lat: 48.2553178, lng: 19.6832386 },
    { lat: 48.255501, lng: 19.6833269 },
    { lat: 48.2557006, lng: 19.6836331 },
    { lat: 48.2557479, lng: 19.6842549 },
    { lat: 48.2554983, lng: 19.68552 },
    { lat: 48.2550693, lng: 19.6862828 },
    { lat: 48.2555232, lng: 19.6892103 },
    { lat: 48.2556, lng: 19.6899443 },
    { lat: 48.2565663, lng: 19.6933443 },
    { lat: 48.2563213, lng: 19.6934867 },
    { lat: 48.256545, lng: 19.6957112 },
    { lat: 48.2565504, lng: 19.6966841 },
    { lat: 48.2566138, lng: 19.6975436 },
    { lat: 48.2564721, lng: 19.6991079 },
    { lat: 48.2564714, lng: 19.7001249 },
    { lat: 48.2568725, lng: 19.7020827 },
    { lat: 48.2561592, lng: 19.7031111 },
    { lat: 48.2554827, lng: 19.7036051 },
    { lat: 48.2551208, lng: 19.7041555 },
    { lat: 48.2532632, lng: 19.7075396 },
    { lat: 48.2531028, lng: 19.7076791 },
    { lat: 48.2531489, lng: 19.7079025 },
    { lat: 48.2530846, lng: 19.7080271 },
    { lat: 48.2529337, lng: 19.7081313 },
    { lat: 48.2528624, lng: 19.7084764 },
    { lat: 48.2530792, lng: 19.7093948 },
    { lat: 48.2530893, lng: 19.7101228 },
    { lat: 48.2532816, lng: 19.7103139 },
    { lat: 48.2533867, lng: 19.7109277 },
    { lat: 48.2535801, lng: 19.7110695 },
    { lat: 48.253647, lng: 19.711532 },
    { lat: 48.2540403, lng: 19.7122371 },
    { lat: 48.2541, lng: 19.7128693 },
    { lat: 48.2544302, lng: 19.7134277 },
    { lat: 48.2544236, lng: 19.7135016 },
    { lat: 48.2545233, lng: 19.7134672 },
    { lat: 48.2545285, lng: 19.7133822 },
    { lat: 48.2547293, lng: 19.7133104 },
    { lat: 48.2547887, lng: 19.7136039 },
    { lat: 48.2549842, lng: 19.7134673 },
    { lat: 48.2550558, lng: 19.7135043 },
    { lat: 48.2550955, lng: 19.7136375 },
    { lat: 48.2553088, lng: 19.7135716 },
    { lat: 48.255622, lng: 19.7138743 },
    { lat: 48.2557227, lng: 19.7136556 },
    { lat: 48.2560644, lng: 19.7138883 },
    { lat: 48.2563861, lng: 19.7136478 },
    { lat: 48.2570732, lng: 19.7141235 },
    { lat: 48.2572512, lng: 19.7141456 },
    { lat: 48.2573566, lng: 19.714437 },
    { lat: 48.2575459, lng: 19.714488 },
    { lat: 48.2577108, lng: 19.7146486 },
    { lat: 48.2581761, lng: 19.7149237 },
    { lat: 48.2587357, lng: 19.7153938 },
    { lat: 48.2590874, lng: 19.7121483 },
    { lat: 48.2604528, lng: 19.7119668 },
    { lat: 48.2609569, lng: 19.7119721 },
    { lat: 48.2625286, lng: 19.7115967 },
    { lat: 48.2635161, lng: 19.7112763 },
    { lat: 48.2641858, lng: 19.7113357 },
    { lat: 48.2646403, lng: 19.7110921 },
    { lat: 48.2651108, lng: 19.7106534 },
    { lat: 48.265653, lng: 19.7098794 },
    { lat: 48.2664064, lng: 19.7083323 },
    { lat: 48.2673002, lng: 19.7068559 },
    { lat: 48.26776, lng: 19.7067528 },
    { lat: 48.2688007, lng: 19.7069213 },
    { lat: 48.2697498, lng: 19.7073092 },
    { lat: 48.2700131, lng: 19.7064449 },
    { lat: 48.2700823, lng: 19.7057019 },
    { lat: 48.2701739, lng: 19.705332 },
    { lat: 48.2702921, lng: 19.7053039 },
    { lat: 48.2704726, lng: 19.7030898 },
    { lat: 48.2713652, lng: 19.7029187 },
    { lat: 48.2719591, lng: 19.7026472 },
    { lat: 48.2724953, lng: 19.7025933 },
    { lat: 48.2740899, lng: 19.6987748 },
    { lat: 48.2743332, lng: 19.6987729 },
    { lat: 48.2747982, lng: 19.6961261 },
    { lat: 48.2749127, lng: 19.6961658 },
    { lat: 48.2757945, lng: 19.6943948 },
    { lat: 48.2760099, lng: 19.6938245 },
    { lat: 48.2773701, lng: 19.6918175 },
    { lat: 48.2775582, lng: 19.69179 },
    { lat: 48.2776848, lng: 19.6916011 },
    { lat: 48.2777081, lng: 19.6914145 },
    { lat: 48.2782332, lng: 19.6910415 },
    { lat: 48.2786021, lng: 19.6906481 },
    { lat: 48.280249, lng: 19.689897 },
    { lat: 48.2805024, lng: 19.6897814 },
    { lat: 48.284047, lng: 19.6883933 },
    { lat: 48.2851559, lng: 19.6874972 },
    { lat: 48.2851807, lng: 19.6874696 },
    { lat: 48.2857424, lng: 19.6868292 },
    { lat: 48.2866342, lng: 19.686239 },
    { lat: 48.2866298, lng: 19.6855418 },
    { lat: 48.2867427, lng: 19.6846434 },
    { lat: 48.2881495, lng: 19.6792666 },
    { lat: 48.2881605, lng: 19.6784617 },
    { lat: 48.2880732, lng: 19.6776437 },
    { lat: 48.2880274, lng: 19.6776012 },
    { lat: 48.2886796, lng: 19.677471 },
    { lat: 48.2886203, lng: 19.6770818 },
    { lat: 48.2886083, lng: 19.6769881 },
    { lat: 48.2886081, lng: 19.6765855 },
    { lat: 48.2879099, lng: 19.6766964 },
    { lat: 48.2876733, lng: 19.6768682 },
    { lat: 48.2871586, lng: 19.6770539 },
    { lat: 48.2871769, lng: 19.6771805 },
    { lat: 48.2871048, lng: 19.6771857 },
    { lat: 48.28698, lng: 19.6768618 },
    { lat: 48.2866756, lng: 19.6770613 },
    { lat: 48.2865947, lng: 19.6772666 },
    { lat: 48.2845871, lng: 19.6777729 },
    { lat: 48.2823858, lng: 19.6781834 },
    { lat: 48.28203, lng: 19.6781408 },
    { lat: 48.2820534, lng: 19.6775325 },
    { lat: 48.2819368, lng: 19.6775241 },
    { lat: 48.2818871, lng: 19.6780982 },
    { lat: 48.2803734, lng: 19.6778854 },
    { lat: 48.2807853, lng: 19.6761196 },
    { lat: 48.2808427, lng: 19.6755938 },
    { lat: 48.2808344, lng: 19.6751117 },
    { lat: 48.2807745, lng: 19.6745773 },
    { lat: 48.280665, lng: 19.6741751 },
    { lat: 48.2798192, lng: 19.6718569 },
    { lat: 48.2796601, lng: 19.6711765 },
    { lat: 48.2796027, lng: 19.6707259 },
    { lat: 48.2795232, lng: 19.6700413 },
    { lat: 48.2795218, lng: 19.669606 },
    { lat: 48.2795873, lng: 19.6687851 },
    { lat: 48.2796914, lng: 19.6682462 },
    { lat: 48.279782, lng: 19.6677038 },
    { lat: 48.279847, lng: 19.6670258 },
    { lat: 48.2797516, lng: 19.6661553 },
    { lat: 48.2795656, lng: 19.6656297 },
    { lat: 48.2793258, lng: 19.6651853 },
    { lat: 48.2787247, lng: 19.664424 },
    { lat: 48.2783472, lng: 19.6638014 },
    { lat: 48.2780165, lng: 19.6629601 },
    { lat: 48.2778514, lng: 19.6621275 },
    { lat: 48.2778126, lng: 19.6616652 },
    { lat: 48.2778199, lng: 19.6610787 },
    { lat: 48.2779985, lng: 19.6588427 },
    { lat: 48.2779811, lng: 19.6578893 },
    { lat: 48.2777364, lng: 19.656883 },
    { lat: 48.2774601, lng: 19.6562283 },
    { lat: 48.2769418, lng: 19.6554347 },
    { lat: 48.2764276, lng: 19.6549775 },
    { lat: 48.2761804, lng: 19.6548197 },
    { lat: 48.2761565, lng: 19.6548872 },
    { lat: 48.2760552, lng: 19.6553633 },
    { lat: 48.2748413, lng: 19.6570294 },
    { lat: 48.272998, lng: 19.6604721 },
    { lat: 48.2729411, lng: 19.6604864 },
    { lat: 48.2724664, lng: 19.6616246 },
    { lat: 48.2721565, lng: 19.6621766 },
    { lat: 48.2720417, lng: 19.6622548 },
    { lat: 48.271884, lng: 19.6622548 },
    { lat: 48.2714879, lng: 19.6625924 },
    { lat: 48.2711703, lng: 19.6626447 },
    { lat: 48.2706041, lng: 19.6630582 },
    { lat: 48.2702646, lng: 19.6630746 },
    { lat: 48.2701692, lng: 19.6631854 },
    { lat: 48.2697946, lng: 19.6632711 },
    { lat: 48.2697728, lng: 19.6633893 },
    { lat: 48.2678758, lng: 19.664248 },
    { lat: 48.2668467, lng: 19.6649152 },
    { lat: 48.2664893, lng: 19.6649337 },
    { lat: 48.2663549, lng: 19.66478 },
    { lat: 48.2662495, lng: 19.6650153 },
    { lat: 48.2662329, lng: 19.6652953 },
    { lat: 48.2662786, lng: 19.6664871 },
    { lat: 48.2660168, lng: 19.6668276 },
    { lat: 48.2660143, lng: 19.6669191 },
    { lat: 48.2658445, lng: 19.6672535 },
    { lat: 48.2655749, lng: 19.6676723 },
    { lat: 48.2654359, lng: 19.6676856 },
    { lat: 48.2644936, lng: 19.6666281 },
    { lat: 48.2643912, lng: 19.6667095 },
    { lat: 48.2642986, lng: 19.666656 },
    { lat: 48.2641619, lng: 19.6667723 },
    { lat: 48.2628575, lng: 19.6688794 },
    { lat: 48.2626437, lng: 19.6686626 },
    { lat: 48.2624183, lng: 19.6681875 },
    { lat: 48.2612818, lng: 19.6697484 },
    { lat: 48.2610161, lng: 19.6700246 },
    { lat: 48.2601762, lng: 19.6714426 },
    { lat: 48.2599749, lng: 19.671702 },
    { lat: 48.259271, lng: 19.6729599 },
    { lat: 48.258465, lng: 19.6739264 },
    { lat: 48.2572193, lng: 19.6750122 },
    { lat: 48.2556895, lng: 19.6759991 },
    { lat: 48.2549282, lng: 19.6761339 },
    { lat: 48.2537296, lng: 19.6761114 },
    { lat: 48.2533876, lng: 19.6758995 },
    { lat: 48.2532215, lng: 19.6756932 },
    { lat: 48.2530513, lng: 19.6755887 },
  ],
  Divín: [
    { lat: 48.474569, lng: 19.4944615 },
    { lat: 48.474792, lng: 19.4941689 },
    { lat: 48.4754482, lng: 19.4936375 },
    { lat: 48.4756715, lng: 19.4932547 },
    { lat: 48.47556, lng: 19.4929435 },
    { lat: 48.4756388, lng: 19.4925506 },
    { lat: 48.4759318, lng: 19.4919704 },
    { lat: 48.4757651, lng: 19.4917773 },
    { lat: 48.4761998, lng: 19.4905563 },
    { lat: 48.476202, lng: 19.4901462 },
    { lat: 48.4761584, lng: 19.4900048 },
    { lat: 48.4764086, lng: 19.4900794 },
    { lat: 48.4764528, lng: 19.4897185 },
    { lat: 48.4769241, lng: 19.4886613 },
    { lat: 48.4770176, lng: 19.4881269 },
    { lat: 48.4773173, lng: 19.4869588 },
    { lat: 48.4775439, lng: 19.4854329 },
    { lat: 48.4779849, lng: 19.4836973 },
    { lat: 48.4781017, lng: 19.4837614 },
    { lat: 48.4785696, lng: 19.4835704 },
    { lat: 48.4789633, lng: 19.4823167 },
    { lat: 48.4795205, lng: 19.4798853 },
    { lat: 48.4800439, lng: 19.4792874 },
    { lat: 48.4804925, lng: 19.4786353 },
    { lat: 48.4808706, lng: 19.478241 },
    { lat: 48.480978, lng: 19.4785138 },
    { lat: 48.4812471, lng: 19.478262 },
    { lat: 48.4813232, lng: 19.4780089 },
    { lat: 48.4813937, lng: 19.4774445 },
    { lat: 48.481801, lng: 19.4776453 },
    { lat: 48.4821196, lng: 19.4775904 },
    { lat: 48.4819961, lng: 19.4769722 },
    { lat: 48.4834354, lng: 19.4759822 },
    { lat: 48.4835584, lng: 19.4756261 },
    { lat: 48.4837845, lng: 19.4758552 },
    { lat: 48.4841344, lng: 19.4759661 },
    { lat: 48.4845008, lng: 19.4759175 },
    { lat: 48.4857294, lng: 19.4753414 },
    { lat: 48.4857784, lng: 19.475548 },
    { lat: 48.4861022, lng: 19.4752176 },
    { lat: 48.4862954, lng: 19.4745741 },
    { lat: 48.4865634, lng: 19.4744983 },
    { lat: 48.4867642, lng: 19.4743325 },
    { lat: 48.4868207, lng: 19.4740189 },
    { lat: 48.4867045, lng: 19.4735832 },
    { lat: 48.4865543, lng: 19.4734552 },
    { lat: 48.4861618, lng: 19.4733761 },
    { lat: 48.4860786, lng: 19.4734552 },
    { lat: 48.4857319, lng: 19.4732204 },
    { lat: 48.4854584, lng: 19.4731803 },
    { lat: 48.4854176, lng: 19.473126 },
    { lat: 48.4853582, lng: 19.4729866 },
    { lat: 48.4853293, lng: 19.4722703 },
    { lat: 48.4854621, lng: 19.4718051 },
    { lat: 48.4859707, lng: 19.4705056 },
    { lat: 48.4864191, lng: 19.4704352 },
    { lat: 48.486513, lng: 19.4703468 },
    { lat: 48.486674, lng: 19.4700012 },
    { lat: 48.4869912, lng: 19.4696263 },
    { lat: 48.4872382, lng: 19.4695598 },
    { lat: 48.4879168, lng: 19.4695379 },
    { lat: 48.4882643, lng: 19.469255 },
    { lat: 48.4885578, lng: 19.4687078 },
    { lat: 48.4889427, lng: 19.4683119 },
    { lat: 48.4889937, lng: 19.467569 },
    { lat: 48.4889272, lng: 19.4674744 },
    { lat: 48.4889162, lng: 19.4668283 },
    { lat: 48.4888684, lng: 19.466623 },
    { lat: 48.4888947, lng: 19.4665339 },
    { lat: 48.4893337, lng: 19.4663565 },
    { lat: 48.4896399, lng: 19.4664204 },
    { lat: 48.4902239, lng: 19.4666675 },
    { lat: 48.4904164, lng: 19.4668301 },
    { lat: 48.4906602, lng: 19.4664018 },
    { lat: 48.4910889, lng: 19.4666763 },
    { lat: 48.491583, lng: 19.467131 },
    { lat: 48.491637, lng: 19.466534 },
    { lat: 48.491103, lng: 19.46617 },
    { lat: 48.491207, lng: 19.465796 },
    { lat: 48.491064, lng: 19.465576 },
    { lat: 48.490845, lng: 19.464602 },
    { lat: 48.490297, lng: 19.463802 },
    { lat: 48.490504, lng: 19.46353 },
    { lat: 48.489935, lng: 19.461974 },
    { lat: 48.489938, lng: 19.461229 },
    { lat: 48.489955, lng: 19.460351 },
    { lat: 48.490001, lng: 19.458993 },
    { lat: 48.490181, lng: 19.458142 },
    { lat: 48.490215, lng: 19.457768 },
    { lat: 48.490478, lng: 19.45718 },
    { lat: 48.490632, lng: 19.456657 },
    { lat: 48.490786, lng: 19.456164 },
    { lat: 48.491126, lng: 19.455633 },
    { lat: 48.491376, lng: 19.455623 },
    { lat: 48.49182, lng: 19.4556 },
    { lat: 48.492193, lng: 19.455692 },
    { lat: 48.492243, lng: 19.455689 },
    { lat: 48.492628, lng: 19.4556 },
    { lat: 48.4923315, lng: 19.454173 },
    { lat: 48.4919522, lng: 19.4532166 },
    { lat: 48.4910601, lng: 19.4518327 },
    { lat: 48.4902817, lng: 19.4508727 },
    { lat: 48.4899472, lng: 19.4505923 },
    { lat: 48.4897421, lng: 19.4502278 },
    { lat: 48.4895754, lng: 19.4501371 },
    { lat: 48.4893161, lng: 19.4501571 },
    { lat: 48.4878091, lng: 19.4495526 },
    { lat: 48.4870638, lng: 19.4490638 },
    { lat: 48.4866811, lng: 19.4486621 },
    { lat: 48.4863713, lng: 19.448496 },
    { lat: 48.4851512, lng: 19.4483645 },
    { lat: 48.4831838, lng: 19.4483953 },
    { lat: 48.4817527, lng: 19.4499671 },
    { lat: 48.4804517, lng: 19.4518852 },
    { lat: 48.4794847, lng: 19.4547223 },
    { lat: 48.4784118, lng: 19.4570601 },
    { lat: 48.4773054, lng: 19.4590223 },
    { lat: 48.4771156, lng: 19.4592313 },
    { lat: 48.4766608, lng: 19.4593121 },
    { lat: 48.4753191, lng: 19.4610842 },
    { lat: 48.4731916, lng: 19.4631896 },
    { lat: 48.4726638, lng: 19.4637617 },
    { lat: 48.4721847, lng: 19.4644346 },
    { lat: 48.4714105, lng: 19.4655665 },
    { lat: 48.4684672, lng: 19.4706732 },
    { lat: 48.4672849, lng: 19.4733298 },
    { lat: 48.4661972, lng: 19.4743187 },
    { lat: 48.4643265, lng: 19.4763986 },
    { lat: 48.4626753, lng: 19.4791771 },
    { lat: 48.4618461, lng: 19.4809513 },
    { lat: 48.4609046, lng: 19.4812532 },
    { lat: 48.4600912, lng: 19.4825897 },
    { lat: 48.4597366, lng: 19.4829178 },
    { lat: 48.4581993, lng: 19.4847496 },
    { lat: 48.4572817, lng: 19.4855441 },
    { lat: 48.4562724, lng: 19.486916 },
    { lat: 48.4551993, lng: 19.4872972 },
    { lat: 48.4543673, lng: 19.4874844 },
    { lat: 48.4534313, lng: 19.4874709 },
    { lat: 48.4527099, lng: 19.4878545 },
    { lat: 48.4525723, lng: 19.4876123 },
    { lat: 48.4506314, lng: 19.4901728 },
    { lat: 48.4504015, lng: 19.4903421 },
    { lat: 48.4501856, lng: 19.4914436 },
    { lat: 48.44944, lng: 19.4914786 },
    { lat: 48.4482162, lng: 19.4917019 },
    { lat: 48.4475571, lng: 19.4916057 },
    { lat: 48.4473408, lng: 19.4924331 },
    { lat: 48.4470304, lng: 19.493077 },
    { lat: 48.4461917, lng: 19.4943097 },
    { lat: 48.4457174, lng: 19.4953094 },
    { lat: 48.4452508, lng: 19.4961542 },
    { lat: 48.4448731, lng: 19.4964415 },
    { lat: 48.4446151, lng: 19.4965417 },
    { lat: 48.443979, lng: 19.4963142 },
    { lat: 48.4437971, lng: 19.4963528 },
    { lat: 48.4434687, lng: 19.4965133 },
    { lat: 48.4424605, lng: 19.4972228 },
    { lat: 48.4420017, lng: 19.497245 },
    { lat: 48.4416968, lng: 19.4975698 },
    { lat: 48.4416522, lng: 19.4977995 },
    { lat: 48.4410206, lng: 19.4984475 },
    { lat: 48.4402232, lng: 19.5000569 },
    { lat: 48.4398377, lng: 19.5006403 },
    { lat: 48.4393923, lng: 19.5010479 },
    { lat: 48.4385887, lng: 19.5013831 },
    { lat: 48.4382312, lng: 19.5014056 },
    { lat: 48.4377952, lng: 19.5013583 },
    { lat: 48.4373219, lng: 19.5010917 },
    { lat: 48.4364454, lng: 19.5009266 },
    { lat: 48.4357029, lng: 19.5017348 },
    { lat: 48.4343928, lng: 19.5040265 },
    { lat: 48.4341757, lng: 19.5045244 },
    { lat: 48.434097, lng: 19.5050366 },
    { lat: 48.4342406, lng: 19.5053647 },
    { lat: 48.434517, lng: 19.5057577 },
    { lat: 48.4355538, lng: 19.5075322 },
    { lat: 48.4349216, lng: 19.5083113 },
    { lat: 48.4344733, lng: 19.5086322 },
    { lat: 48.4343969, lng: 19.5100497 },
    { lat: 48.4341106, lng: 19.5114346 },
    { lat: 48.4341249, lng: 19.5119388 },
    { lat: 48.4343017, lng: 19.5121548 },
    { lat: 48.4343108, lng: 19.5125719 },
    { lat: 48.4343899, lng: 19.513036 },
    { lat: 48.4344957, lng: 19.5161733 },
    { lat: 48.4340117, lng: 19.5162108 },
    { lat: 48.4338486, lng: 19.5162892 },
    { lat: 48.4329698, lng: 19.518584 },
    { lat: 48.4326658, lng: 19.5197112 },
    { lat: 48.43233, lng: 19.5204198 },
    { lat: 48.4322085, lng: 19.5208122 },
    { lat: 48.4310946, lng: 19.522559 },
    { lat: 48.4334422, lng: 19.5235365 },
    { lat: 48.4347879, lng: 19.5261254 },
    { lat: 48.4368517, lng: 19.5277448 },
    { lat: 48.4371312, lng: 19.5281355 },
    { lat: 48.4375477, lng: 19.5283585 },
    { lat: 48.4387256, lng: 19.5289893 },
    { lat: 48.4396531, lng: 19.529816 },
    { lat: 48.4403544, lng: 19.5325623 },
    { lat: 48.4415228, lng: 19.5350254 },
    { lat: 48.4430144, lng: 19.5368261 },
    { lat: 48.443377, lng: 19.5369416 },
    { lat: 48.4435668, lng: 19.5371441 },
    { lat: 48.4445713, lng: 19.5388199 },
    { lat: 48.444471, lng: 19.5390869 },
    { lat: 48.4448959, lng: 19.5396521 },
    { lat: 48.4451114, lng: 19.5400507 },
    { lat: 48.4450074, lng: 19.5402372 },
    { lat: 48.4450016, lng: 19.5405789 },
    { lat: 48.4450547, lng: 19.5408744 },
    { lat: 48.4449056, lng: 19.5410631 },
    { lat: 48.4449073, lng: 19.5413919 },
    { lat: 48.4445184, lng: 19.5421728 },
    { lat: 48.4444706, lng: 19.5430161 },
    { lat: 48.4453725, lng: 19.5437113 },
    { lat: 48.4452878, lng: 19.5439792 },
    { lat: 48.4460729, lng: 19.5445575 },
    { lat: 48.4458377, lng: 19.5452765 },
    { lat: 48.4455097, lng: 19.5456411 },
    { lat: 48.4450555, lng: 19.5462743 },
    { lat: 48.4451529, lng: 19.5465343 },
    { lat: 48.4451337, lng: 19.5473391 },
    { lat: 48.4447198, lng: 19.5482901 },
    { lat: 48.4441959, lng: 19.550078 },
    { lat: 48.4439174, lng: 19.5508363 },
    { lat: 48.443547, lng: 19.5515548 },
    { lat: 48.4431903, lng: 19.5521054 },
    { lat: 48.4430001, lng: 19.5523086 },
    { lat: 48.4428016, lng: 19.5527945 },
    { lat: 48.442942, lng: 19.553055 },
    { lat: 48.4432856, lng: 19.5530282 },
    { lat: 48.4432695, lng: 19.5534125 },
    { lat: 48.4427241, lng: 19.5542939 },
    { lat: 48.4395241, lng: 19.5594113 },
    { lat: 48.4378248, lng: 19.5578141 },
    { lat: 48.4376797, lng: 19.5588775 },
    { lat: 48.4367925, lng: 19.5698563 },
    { lat: 48.4362982, lng: 19.570702 },
    { lat: 48.4352442, lng: 19.5723181 },
    { lat: 48.4346023, lng: 19.5734607 },
    { lat: 48.4337215, lng: 19.5738112 },
    { lat: 48.4333305, lng: 19.5742089 },
    { lat: 48.4333091, lng: 19.5743566 },
    { lat: 48.4332202, lng: 19.5742877 },
    { lat: 48.4327487, lng: 19.5747676 },
    { lat: 48.4323562, lng: 19.5756445 },
    { lat: 48.4323196, lng: 19.575902 },
    { lat: 48.4322117, lng: 19.5759603 },
    { lat: 48.4321497, lng: 19.5762307 },
    { lat: 48.4318079, lng: 19.576986 },
    { lat: 48.4315133, lng: 19.5778609 },
    { lat: 48.4313804, lng: 19.5784569 },
    { lat: 48.4312759, lng: 19.5787321 },
    { lat: 48.4312605, lng: 19.578964 },
    { lat: 48.4312078, lng: 19.5790187 },
    { lat: 48.4304695, lng: 19.5819715 },
    { lat: 48.4302355, lng: 19.5827199 },
    { lat: 48.4301562, lng: 19.5828355 },
    { lat: 48.4296777, lng: 19.5828956 },
    { lat: 48.4296839, lng: 19.582632 },
    { lat: 48.4294659, lng: 19.5829674 },
    { lat: 48.4294514, lng: 19.5829952 },
    { lat: 48.429208, lng: 19.5836632 },
    { lat: 48.4296763, lng: 19.5844154 },
    { lat: 48.4303555, lng: 19.5848304 },
    { lat: 48.43039, lng: 19.5850692 },
    { lat: 48.4310705, lng: 19.5852389 },
    { lat: 48.4312427, lng: 19.5851817 },
    { lat: 48.4317083, lng: 19.5852841 },
    { lat: 48.4318893, lng: 19.5855 },
    { lat: 48.4320103, lng: 19.5855056 },
    { lat: 48.432176, lng: 19.5854109 },
    { lat: 48.4324274, lng: 19.5849918 },
    { lat: 48.4331112, lng: 19.5850107 },
    { lat: 48.4338085, lng: 19.5852286 },
    { lat: 48.433783, lng: 19.5851149 },
    { lat: 48.434187, lng: 19.5851751 },
    { lat: 48.4342259, lng: 19.5849202 },
    { lat: 48.4349021, lng: 19.5845985 },
    { lat: 48.435084, lng: 19.5843374 },
    { lat: 48.4353496, lng: 19.5841595 },
    { lat: 48.436671, lng: 19.5820774 },
    { lat: 48.4373081, lng: 19.5813736 },
    { lat: 48.4377499, lng: 19.5807077 },
    { lat: 48.4380266, lng: 19.5801074 },
    { lat: 48.4385615, lng: 19.5799468 },
    { lat: 48.4389726, lng: 19.5795761 },
    { lat: 48.439945, lng: 19.5788827 },
    { lat: 48.4402747, lng: 19.578477 },
    { lat: 48.4411104, lng: 19.5779247 },
    { lat: 48.4414092, lng: 19.5776107 },
    { lat: 48.4414115, lng: 19.5774397 },
    { lat: 48.4415612, lng: 19.5773103 },
    { lat: 48.4416247, lng: 19.5773201 },
    { lat: 48.442483, lng: 19.5766473 },
    { lat: 48.4465791, lng: 19.5732249 },
    { lat: 48.4466986, lng: 19.5731251 },
    { lat: 48.4476937, lng: 19.5724586 },
    { lat: 48.448202, lng: 19.5721891 },
    { lat: 48.4484012, lng: 19.5722321 },
    { lat: 48.4490884, lng: 19.5719349 },
    { lat: 48.4502251, lng: 19.5712359 },
    { lat: 48.4513887, lng: 19.5703417 },
    { lat: 48.4528084, lng: 19.5689357 },
    { lat: 48.4527297, lng: 19.5687635 },
    { lat: 48.4531663, lng: 19.5683825 },
    { lat: 48.4535337, lng: 19.5682372 },
    { lat: 48.4534889, lng: 19.5680942 },
    { lat: 48.4537536, lng: 19.5678715 },
    { lat: 48.4538427, lng: 19.5680686 },
    { lat: 48.4548424, lng: 19.5671841 },
    { lat: 48.4548563, lng: 19.5673655 },
    { lat: 48.4545831, lng: 19.5675496 },
    { lat: 48.4549454, lng: 19.5675426 },
    { lat: 48.4552513, lng: 19.5675024 },
    { lat: 48.4560367, lng: 19.5670328 },
    { lat: 48.4560925, lng: 19.5670717 },
    { lat: 48.4569514, lng: 19.566413 },
    { lat: 48.458286, lng: 19.565803 },
    { lat: 48.4592766, lng: 19.5651444 },
    { lat: 48.459864, lng: 19.5644086 },
    { lat: 48.4601354, lng: 19.5642108 },
    { lat: 48.4603192, lng: 19.5635733 },
    { lat: 48.4609026, lng: 19.5628745 },
    { lat: 48.4624903, lng: 19.5602466 },
    { lat: 48.4624179, lng: 19.5595734 },
    { lat: 48.4622898, lng: 19.5595366 },
    { lat: 48.4621409, lng: 19.5580346 },
    { lat: 48.4616545, lng: 19.5559576 },
    { lat: 48.4615206, lng: 19.5548307 },
    { lat: 48.4613012, lng: 19.5544242 },
    { lat: 48.4613762, lng: 19.5541391 },
    { lat: 48.4604354, lng: 19.5528433 },
    { lat: 48.4579551, lng: 19.5528675 },
    { lat: 48.4573736, lng: 19.5528175 },
    { lat: 48.4569021, lng: 19.5521965 },
    { lat: 48.4563496, lng: 19.5509147 },
    { lat: 48.4567864, lng: 19.5503163 },
    { lat: 48.4575035, lng: 19.5496627 },
    { lat: 48.4581078, lng: 19.5488359 },
    { lat: 48.4588236, lng: 19.5482003 },
    { lat: 48.4591539, lng: 19.5480076 },
    { lat: 48.4592966, lng: 19.5475586 },
    { lat: 48.4596197, lng: 19.5468664 },
    { lat: 48.460632, lng: 19.5450829 },
    { lat: 48.4605479, lng: 19.5436238 },
    { lat: 48.4605597, lng: 19.5429218 },
    { lat: 48.4607127, lng: 19.542331 },
    { lat: 48.4611364, lng: 19.5412026 },
    { lat: 48.4612591, lng: 19.5410528 },
    { lat: 48.4619552, lng: 19.5392619 },
    { lat: 48.4648648, lng: 19.5335568 },
    { lat: 48.4652001, lng: 19.5321541 },
    { lat: 48.4662774, lng: 19.5261175 },
    { lat: 48.4667896, lng: 19.5225695 },
    { lat: 48.4681419, lng: 19.5204962 },
    { lat: 48.467499, lng: 19.5191905 },
    { lat: 48.4681963, lng: 19.5153073 },
    { lat: 48.4689588, lng: 19.5131775 },
    { lat: 48.470153, lng: 19.5115664 },
    { lat: 48.4700453, lng: 19.5108905 },
    { lat: 48.4703151, lng: 19.5094655 },
    { lat: 48.4709196, lng: 19.5093788 },
    { lat: 48.4709897, lng: 19.5089702 },
    { lat: 48.4705756, lng: 19.50862 },
    { lat: 48.4704409, lng: 19.5082918 },
    { lat: 48.4701626, lng: 19.5082183 },
    { lat: 48.4700415, lng: 19.5076227 },
    { lat: 48.4704156, lng: 19.5074755 },
    { lat: 48.47076, lng: 19.5070545 },
    { lat: 48.4712543, lng: 19.5058514 },
    { lat: 48.4706798, lng: 19.5032275 },
    { lat: 48.4709771, lng: 19.5031047 },
    { lat: 48.4713285, lng: 19.5023924 },
    { lat: 48.4717973, lng: 19.5003772 },
    { lat: 48.4714311, lng: 19.5001596 },
    { lat: 48.4713751, lng: 19.5000458 },
    { lat: 48.4714102, lng: 19.4984458 },
    { lat: 48.4718464, lng: 19.4971854 },
    { lat: 48.4714506, lng: 19.496619 },
    { lat: 48.4726094, lng: 19.494977 },
    { lat: 48.4733773, lng: 19.4943281 },
    { lat: 48.4737731, lng: 19.4948935 },
    { lat: 48.4740517, lng: 19.4946337 },
    { lat: 48.4743325, lng: 19.4944647 },
    { lat: 48.4744126, lng: 19.4946125 },
    { lat: 48.474569, lng: 19.4944615 },
  ],
  Polichno: [
    { lat: 48.400855, lng: 19.4644307 },
    { lat: 48.4007096, lng: 19.464816 },
    { lat: 48.4005881, lng: 19.4651118 },
    { lat: 48.4003973, lng: 19.4652957 },
    { lat: 48.4001744, lng: 19.4659771 },
    { lat: 48.4001583, lng: 19.4665721 },
    { lat: 48.4001926, lng: 19.4668976 },
    { lat: 48.4002914, lng: 19.4672022 },
    { lat: 48.4002921, lng: 19.4673383 },
    { lat: 48.4002361, lng: 19.4674435 },
    { lat: 48.3998756, lng: 19.4673708 },
    { lat: 48.3995951, lng: 19.4674217 },
    { lat: 48.3992986, lng: 19.4677575 },
    { lat: 48.3992621, lng: 19.4680135 },
    { lat: 48.3989682, lng: 19.4683084 },
    { lat: 48.3987991, lng: 19.4683794 },
    { lat: 48.3982654, lng: 19.4683455 },
    { lat: 48.3979634, lng: 19.4685635 },
    { lat: 48.3977182, lng: 19.4689298 },
    { lat: 48.3974166, lng: 19.4692541 },
    { lat: 48.397146, lng: 19.4697341 },
    { lat: 48.397061, lng: 19.469776 },
    { lat: 48.3966714, lng: 19.4697571 },
    { lat: 48.396474, lng: 19.4696811 },
    { lat: 48.3960271, lng: 19.4696903 },
    { lat: 48.3958254, lng: 19.4694963 },
    { lat: 48.3953003, lng: 19.4692831 },
    { lat: 48.3947686, lng: 19.4691941 },
    { lat: 48.3942368, lng: 19.468953 },
    { lat: 48.3940795, lng: 19.4689422 },
    { lat: 48.3936813, lng: 19.4691742 },
    { lat: 48.3935535, lng: 19.4690916 },
    { lat: 48.3932766, lng: 19.4686899 },
    { lat: 48.3926058, lng: 19.4682993 },
    { lat: 48.3919451, lng: 19.4682686 },
    { lat: 48.3917554, lng: 19.4683538 },
    { lat: 48.3916167, lng: 19.4683333 },
    { lat: 48.3913684, lng: 19.4685172 },
    { lat: 48.3911118, lng: 19.4689786 },
    { lat: 48.3908473, lng: 19.4688941 },
    { lat: 48.3904234, lng: 19.4689391 },
    { lat: 48.3899685, lng: 19.4687753 },
    { lat: 48.3893567, lng: 19.4688533 },
    { lat: 48.3889032, lng: 19.4694237 },
    { lat: 48.3888057, lng: 19.4694084 },
    { lat: 48.3886459, lng: 19.4695571 },
    { lat: 48.3885715, lng: 19.4698692 },
    { lat: 48.3879414, lng: 19.4701834 },
    { lat: 48.3876791, lng: 19.4704319 },
    { lat: 48.3870853, lng: 19.4706019 },
    { lat: 48.3869956, lng: 19.4709017 },
    { lat: 48.3870475, lng: 19.4711423 },
    { lat: 48.3870277, lng: 19.4712493 },
    { lat: 48.3867083, lng: 19.4716272 },
    { lat: 48.3864272, lng: 19.4717618 },
    { lat: 48.3861659, lng: 19.4717127 },
    { lat: 48.3860611, lng: 19.4719063 },
    { lat: 48.3856364, lng: 19.4720826 },
    { lat: 48.3856482, lng: 19.4721427 },
    { lat: 48.3860289, lng: 19.4723295 },
    { lat: 48.3862646, lng: 19.4725986 },
    { lat: 48.3864336, lng: 19.4726212 },
    { lat: 48.3867637, lng: 19.4730556 },
    { lat: 48.3868813, lng: 19.4731098 },
    { lat: 48.3870684, lng: 19.4733291 },
    { lat: 48.3872462, lng: 19.4736132 },
    { lat: 48.3875173, lng: 19.4737243 },
    { lat: 48.3877949, lng: 19.4741405 },
    { lat: 48.3878266, lng: 19.4743392 },
    { lat: 48.3880087, lng: 19.4745207 },
    { lat: 48.3880742, lng: 19.474716 },
    { lat: 48.3882348, lng: 19.4747515 },
    { lat: 48.388569, lng: 19.4751018 },
    { lat: 48.3887734, lng: 19.4754469 },
    { lat: 48.3889827, lng: 19.4756628 },
    { lat: 48.3890133, lng: 19.4757351 },
    { lat: 48.3889678, lng: 19.4759955 },
    { lat: 48.389084, lng: 19.4762382 },
    { lat: 48.3891662, lng: 19.4761636 },
    { lat: 48.3892294, lng: 19.4764094 },
    { lat: 48.3893874, lng: 19.4764591 },
    { lat: 48.3894147, lng: 19.4767052 },
    { lat: 48.3896471, lng: 19.4768587 },
    { lat: 48.3896522, lng: 19.4771096 },
    { lat: 48.3897855, lng: 19.4771409 },
    { lat: 48.3898595, lng: 19.4776186 },
    { lat: 48.3904177, lng: 19.4782513 },
    { lat: 48.3905415, lng: 19.4782971 },
    { lat: 48.3905862, lng: 19.4785317 },
    { lat: 48.3909676, lng: 19.4790983 },
    { lat: 48.3911085, lng: 19.479097 },
    { lat: 48.3912384, lng: 19.4791979 },
    { lat: 48.3915664, lng: 19.4799974 },
    { lat: 48.3917789, lng: 19.4806913 },
    { lat: 48.3918842, lng: 19.481956 },
    { lat: 48.3920225, lng: 19.4823609 },
    { lat: 48.3921735, lng: 19.4842921 },
    { lat: 48.3917582, lng: 19.4851307 },
    { lat: 48.3909021, lng: 19.4863482 },
    { lat: 48.3906422, lng: 19.4870336 },
    { lat: 48.3906077, lng: 19.4874549 },
    { lat: 48.3910334, lng: 19.4881739 },
    { lat: 48.3923175, lng: 19.4900025 },
    { lat: 48.3919882, lng: 19.4904062 },
    { lat: 48.3925373, lng: 19.4923764 },
    { lat: 48.3929, lng: 19.4933139 },
    { lat: 48.3931116, lng: 19.4937207 },
    { lat: 48.3934017, lng: 19.4941194 },
    { lat: 48.3943549, lng: 19.4950339 },
    { lat: 48.3942671, lng: 19.4954027 },
    { lat: 48.3944648, lng: 19.4964344 },
    { lat: 48.3935192, lng: 19.4974235 },
    { lat: 48.3930603, lng: 19.4991155 },
    { lat: 48.3930007, lng: 19.4996253 },
    { lat: 48.3924422, lng: 19.5009993 },
    { lat: 48.3913533, lng: 19.5026156 },
    { lat: 48.3909561, lng: 19.5033575 },
    { lat: 48.3906753, lng: 19.5040091 },
    { lat: 48.3905421, lng: 19.504517 },
    { lat: 48.3900626, lng: 19.5056454 },
    { lat: 48.3899022, lng: 19.5061479 },
    { lat: 48.3897552, lng: 19.5063533 },
    { lat: 48.3896718, lng: 19.5066681 },
    { lat: 48.3909407, lng: 19.5078816 },
    { lat: 48.3917697, lng: 19.5087232 },
    { lat: 48.3914621, lng: 19.5090674 },
    { lat: 48.39138, lng: 19.5094089 },
    { lat: 48.3912359, lng: 19.5096779 },
    { lat: 48.3909789, lng: 19.5098249 },
    { lat: 48.3903187, lng: 19.5109984 },
    { lat: 48.3899724, lng: 19.5110619 },
    { lat: 48.389771, lng: 19.5111656 },
    { lat: 48.3896601, lng: 19.5114002 },
    { lat: 48.3895695, lng: 19.5119486 },
    { lat: 48.3884575, lng: 19.5129529 },
    { lat: 48.3880673, lng: 19.5130419 },
    { lat: 48.3877587, lng: 19.5132422 },
    { lat: 48.3872238, lng: 19.5133495 },
    { lat: 48.3868223, lng: 19.5140704 },
    { lat: 48.3865125, lng: 19.5140719 },
    { lat: 48.3860223, lng: 19.5145109 },
    { lat: 48.3858041, lng: 19.5145203 },
    { lat: 48.3864889, lng: 19.5223967 },
    { lat: 48.3865493, lng: 19.5229002 },
    { lat: 48.386779, lng: 19.5230149 },
    { lat: 48.3868077, lng: 19.5230296 },
    { lat: 48.3873486, lng: 19.5232257 },
    { lat: 48.3901312, lng: 19.5238858 },
    { lat: 48.3909363, lng: 19.5239178 },
    { lat: 48.3920474, lng: 19.5241639 },
    { lat: 48.3921493, lng: 19.5241516 },
    { lat: 48.3922029, lng: 19.5239928 },
    { lat: 48.3925271, lng: 19.524421 },
    { lat: 48.3927167, lng: 19.5245275 },
    { lat: 48.3928821, lng: 19.5245194 },
    { lat: 48.3929707, lng: 19.5246074 },
    { lat: 48.3930944, lng: 19.5244583 },
    { lat: 48.3935087, lng: 19.5244953 },
    { lat: 48.3939162, lng: 19.5248517 },
    { lat: 48.3946601, lng: 19.5265375 },
    { lat: 48.3951703, lng: 19.5263358 },
    { lat: 48.3952618, lng: 19.5260723 },
    { lat: 48.3957176, lng: 19.5252763 },
    { lat: 48.3962025, lng: 19.524702 },
    { lat: 48.3966496, lng: 19.524499 },
    { lat: 48.3975037, lng: 19.523716 },
    { lat: 48.3984078, lng: 19.5233362 },
    { lat: 48.3988139, lng: 19.5225119 },
    { lat: 48.3992476, lng: 19.521877 },
    { lat: 48.3996722, lng: 19.5210908 },
    { lat: 48.3996971, lng: 19.5210481 },
    { lat: 48.3998774, lng: 19.5207282 },
    { lat: 48.4002961, lng: 19.5204033 },
    { lat: 48.4009722, lng: 19.5203253 },
    { lat: 48.4014229, lng: 19.5200318 },
    { lat: 48.4017402, lng: 19.5166388 },
    { lat: 48.4020823, lng: 19.5149746 },
    { lat: 48.4019771, lng: 19.5141362 },
    { lat: 48.4028412, lng: 19.5142275 },
    { lat: 48.4033858, lng: 19.5130414 },
    { lat: 48.4036394, lng: 19.5127489 },
    { lat: 48.4039789, lng: 19.5126879 },
    { lat: 48.4043103, lng: 19.5127352 },
    { lat: 48.4048264, lng: 19.512976 },
    { lat: 48.4049773, lng: 19.5114177 },
    { lat: 48.405187, lng: 19.5110092 },
    { lat: 48.4058161, lng: 19.51021 },
    { lat: 48.4060205, lng: 19.5100244 },
    { lat: 48.4065136, lng: 19.5093358 },
    { lat: 48.4078116, lng: 19.5069709 },
    { lat: 48.4081372, lng: 19.5066527 },
    { lat: 48.4083711, lng: 19.5061824 },
    { lat: 48.4097953, lng: 19.5041098 },
    { lat: 48.4103166, lng: 19.50393 },
    { lat: 48.4108438, lng: 19.5035144 },
    { lat: 48.4110667, lng: 19.5032655 },
    { lat: 48.4115021, lng: 19.5025201 },
    { lat: 48.4118754, lng: 19.5019907 },
    { lat: 48.4121564, lng: 19.5014413 },
    { lat: 48.412451, lng: 19.5010859 },
    { lat: 48.4130338, lng: 19.5005832 },
    { lat: 48.4133624, lng: 19.4999656 },
    { lat: 48.4135729, lng: 19.4996711 },
    { lat: 48.4137362, lng: 19.4995681 },
    { lat: 48.4139784, lng: 19.4990568 },
    { lat: 48.4143131, lng: 19.4988145 },
    { lat: 48.4147291, lng: 19.4983591 },
    { lat: 48.4150075, lng: 19.4978719 },
    { lat: 48.4152351, lng: 19.4969038 },
    { lat: 48.4151813, lng: 19.4965192 },
    { lat: 48.4152115, lng: 19.49598 },
    { lat: 48.4152936, lng: 19.4956127 },
    { lat: 48.4151811, lng: 19.4953759 },
    { lat: 48.4152533, lng: 19.4943342 },
    { lat: 48.4152285, lng: 19.493826 },
    { lat: 48.4154722, lng: 19.4930782 },
    { lat: 48.415933, lng: 19.4924707 },
    { lat: 48.4161024, lng: 19.4921624 },
    { lat: 48.4165336, lng: 19.4906031 },
    { lat: 48.4167729, lng: 19.4901022 },
    { lat: 48.4173996, lng: 19.4880246 },
    { lat: 48.4169566, lng: 19.487512 },
    { lat: 48.4166484, lng: 19.4870403 },
    { lat: 48.4170371, lng: 19.4855839 },
    { lat: 48.417231, lng: 19.4845252 },
    { lat: 48.4175724, lng: 19.4831189 },
    { lat: 48.417757, lng: 19.4825794 },
    { lat: 48.417987, lng: 19.4822451 },
    { lat: 48.4185014, lng: 19.4808266 },
    { lat: 48.4191853, lng: 19.479878 },
    { lat: 48.419138, lng: 19.4798326 },
    { lat: 48.4198914, lng: 19.4786124 },
    { lat: 48.4201722, lng: 19.4778924 },
    { lat: 48.4202999, lng: 19.4774401 },
    { lat: 48.4206522, lng: 19.4753989 },
    { lat: 48.4206808, lng: 19.4749992 },
    { lat: 48.4206382, lng: 19.4743505 },
    { lat: 48.4207479, lng: 19.4734421 },
    { lat: 48.4207426, lng: 19.4730637 },
    { lat: 48.4210675, lng: 19.4715706 },
    { lat: 48.421189, lng: 19.4712793 },
    { lat: 48.4213534, lng: 19.4710586 },
    { lat: 48.4215365, lng: 19.4706097 },
    { lat: 48.4216652, lng: 19.4700838 },
    { lat: 48.4218428, lng: 19.4696191 },
    { lat: 48.4218725, lng: 19.4693847 },
    { lat: 48.422377, lng: 19.4682167 },
    { lat: 48.422927, lng: 19.4667217 },
    { lat: 48.4231133, lng: 19.466078 },
    { lat: 48.4232463, lng: 19.4653565 },
    { lat: 48.4233926, lng: 19.4650359 },
    { lat: 48.424357, lng: 19.4638719 },
    { lat: 48.4243412, lng: 19.4638462 },
    { lat: 48.4234367, lng: 19.4625078 },
    { lat: 48.4217608, lng: 19.4605314 },
    { lat: 48.4197675, lng: 19.463407 },
    { lat: 48.4191753, lng: 19.4659249 },
    { lat: 48.4189246, lng: 19.4672428 },
    { lat: 48.417819, lng: 19.4675789 },
    { lat: 48.4165414, lng: 19.4671545 },
    { lat: 48.415058, lng: 19.4674358 },
    { lat: 48.4142931, lng: 19.4673954 },
    { lat: 48.4140158, lng: 19.4669837 },
    { lat: 48.4136938, lng: 19.4670631 },
    { lat: 48.4129929, lng: 19.4666091 },
    { lat: 48.4125419, lng: 19.4665538 },
    { lat: 48.4121495, lng: 19.4664051 },
    { lat: 48.4119337, lng: 19.4664675 },
    { lat: 48.4110812, lng: 19.4659278 },
    { lat: 48.4099204, lng: 19.4661431 },
    { lat: 48.4094272, lng: 19.4658869 },
    { lat: 48.4092286, lng: 19.4656141 },
    { lat: 48.4088636, lng: 19.4654259 },
    { lat: 48.4085844, lng: 19.4650695 },
    { lat: 48.4081071, lng: 19.4646718 },
    { lat: 48.4080026, lng: 19.4644867 },
    { lat: 48.4076944, lng: 19.4643996 },
    { lat: 48.407438, lng: 19.4639322 },
    { lat: 48.4072837, lng: 19.4638595 },
    { lat: 48.4071947, lng: 19.4637387 },
    { lat: 48.4071142, lng: 19.4631985 },
    { lat: 48.4071585, lng: 19.4629448 },
    { lat: 48.4071692, lng: 19.461992 },
    { lat: 48.4070067, lng: 19.4617949 },
    { lat: 48.4070264, lng: 19.4614428 },
    { lat: 48.4073616, lng: 19.4602493 },
    { lat: 48.4073119, lng: 19.4594281 },
    { lat: 48.4071113, lng: 19.4584182 },
    { lat: 48.4064668, lng: 19.4573381 },
    { lat: 48.4061202, lng: 19.4573243 },
    { lat: 48.4059704, lng: 19.4574689 },
    { lat: 48.4055292, lng: 19.4576502 },
    { lat: 48.40522, lng: 19.4581216 },
    { lat: 48.4049168, lng: 19.4593496 },
    { lat: 48.404705, lng: 19.4597845 },
    { lat: 48.4029378, lng: 19.4613996 },
    { lat: 48.4021283, lng: 19.4623079 },
    { lat: 48.4013558, lng: 19.4634742 },
    { lat: 48.4011921, lng: 19.4639295 },
    { lat: 48.4009713, lng: 19.4641266 },
    { lat: 48.400855, lng: 19.4644307 },
  ],
  Jelšovec: [
    { lat: 48.284102, lng: 19.6208458 },
    { lat: 48.2852516, lng: 19.6199561 },
    { lat: 48.286099, lng: 19.6194163 },
    { lat: 48.2866256, lng: 19.6191247 },
    { lat: 48.287245, lng: 19.6190689 },
    { lat: 48.2874582, lng: 19.61912 },
    { lat: 48.2882143, lng: 19.6196651 },
    { lat: 48.2899643, lng: 19.6205333 },
    { lat: 48.2915614, lng: 19.6220056 },
    { lat: 48.2919686, lng: 19.6222638 },
    { lat: 48.2927539, lng: 19.6226054 },
    { lat: 48.2930228, lng: 19.6225004 },
    { lat: 48.2932156, lng: 19.6225742 },
    { lat: 48.2934101, lng: 19.6227602 },
    { lat: 48.2944942, lng: 19.6228859 },
    { lat: 48.2945424, lng: 19.6228548 },
    { lat: 48.2945232, lng: 19.6228324 },
    { lat: 48.2939655, lng: 19.622185 },
    { lat: 48.293719, lng: 19.6218989 },
    { lat: 48.2929833, lng: 19.6207847 },
    { lat: 48.2923545, lng: 19.6200687 },
    { lat: 48.2904689, lng: 19.617455 },
    { lat: 48.2898951, lng: 19.6160647 },
    { lat: 48.2896742, lng: 19.6153206 },
    { lat: 48.2892269, lng: 19.6143607 },
    { lat: 48.2896958, lng: 19.6142147 },
    { lat: 48.2903744, lng: 19.6131808 },
    { lat: 48.2905914, lng: 19.612948 },
    { lat: 48.2922492, lng: 19.6105251 },
    { lat: 48.2926115, lng: 19.6100715 },
    { lat: 48.2933718, lng: 19.6089247 },
    { lat: 48.2935991, lng: 19.6084481 },
    { lat: 48.2955236, lng: 19.6060967 },
    { lat: 48.2949222, lng: 19.6042753 },
    { lat: 48.2951849, lng: 19.6038376 },
    { lat: 48.2954036, lng: 19.6032429 },
    { lat: 48.2955749, lng: 19.6024539 },
    { lat: 48.2955591, lng: 19.6021809 },
    { lat: 48.2949913, lng: 19.600238 },
    { lat: 48.2955614, lng: 19.599665 },
    { lat: 48.2963989, lng: 19.5990542 },
    { lat: 48.2967714, lng: 19.5984516 },
    { lat: 48.2971509, lng: 19.5983744 },
    { lat: 48.2977207, lng: 19.598018 },
    { lat: 48.2965443, lng: 19.592646 },
    { lat: 48.2995256, lng: 19.5900695 },
    { lat: 48.2995493, lng: 19.589385 },
    { lat: 48.299794, lng: 19.5891192 },
    { lat: 48.3000231, lng: 19.5892482 },
    { lat: 48.3001798, lng: 19.5888807 },
    { lat: 48.3011157, lng: 19.5883771 },
    { lat: 48.3016714, lng: 19.5878882 },
    { lat: 48.302456, lng: 19.587844 },
    { lat: 48.3017459, lng: 19.5865848 },
    { lat: 48.301696, lng: 19.5859844 },
    { lat: 48.3022534, lng: 19.5859515 },
    { lat: 48.3024055, lng: 19.5858795 },
    { lat: 48.3024574, lng: 19.5856307 },
    { lat: 48.3027564, lng: 19.5849271 },
    { lat: 48.3028797, lng: 19.584741 },
    { lat: 48.303238, lng: 19.5844182 },
    { lat: 48.3034763, lng: 19.5839395 },
    { lat: 48.3033861, lng: 19.5815789 },
    { lat: 48.3034809, lng: 19.5807448 },
    { lat: 48.3037136, lng: 19.5799181 },
    { lat: 48.3033846, lng: 19.579506 },
    { lat: 48.3035443, lng: 19.5791246 },
    { lat: 48.3036454, lng: 19.5786523 },
    { lat: 48.3038765, lng: 19.5780881 },
    { lat: 48.3034704, lng: 19.5774408 },
    { lat: 48.3030212, lng: 19.5765912 },
    { lat: 48.3032126, lng: 19.5754357 },
    { lat: 48.3039639, lng: 19.5731171 },
    { lat: 48.3041811, lng: 19.5725698 },
    { lat: 48.3039535, lng: 19.5718093 },
    { lat: 48.3040316, lng: 19.5717422 },
    { lat: 48.3029686, lng: 19.5691566 },
    { lat: 48.3028946, lng: 19.5691908 },
    { lat: 48.3027412, lng: 19.5689925 },
    { lat: 48.3017258, lng: 19.570978 },
    { lat: 48.301166, lng: 19.5698062 },
    { lat: 48.2961497, lng: 19.5737727 },
    { lat: 48.294098, lng: 19.5682818 },
    { lat: 48.2948687, lng: 19.5675211 },
    { lat: 48.2953214, lng: 19.5676336 },
    { lat: 48.2954795, lng: 19.5675961 },
    { lat: 48.2951437, lng: 19.5665174 },
    { lat: 48.2941524, lng: 19.5638503 },
    { lat: 48.2941703, lng: 19.5635767 },
    { lat: 48.2941424, lng: 19.5635622 },
    { lat: 48.2931633, lng: 19.5630267 },
    { lat: 48.2929654, lng: 19.5633536 },
    { lat: 48.2918254, lng: 19.5629961 },
    { lat: 48.2915216, lng: 19.5630045 },
    { lat: 48.291318, lng: 19.5636242 },
    { lat: 48.2916548, lng: 19.5640162 },
    { lat: 48.2919207, lng: 19.5650436 },
    { lat: 48.2919526, lng: 19.565322 },
    { lat: 48.2918882, lng: 19.566099 },
    { lat: 48.2915747, lng: 19.5677867 },
    { lat: 48.2915844, lng: 19.5687121 },
    { lat: 48.2918978, lng: 19.5708206 },
    { lat: 48.2916081, lng: 19.5713349 },
    { lat: 48.2907467, lng: 19.5714754 },
    { lat: 48.2889258, lng: 19.5715981 },
    { lat: 48.2883567, lng: 19.5717219 },
    { lat: 48.2878759, lng: 19.5717307 },
    { lat: 48.2853235, lng: 19.5720777 },
    { lat: 48.2834044, lng: 19.5722459 },
    { lat: 48.2832922, lng: 19.5732134 },
    { lat: 48.2833763, lng: 19.5735996 },
    { lat: 48.283335, lng: 19.5739251 },
    { lat: 48.2834311, lng: 19.5745934 },
    { lat: 48.2834072, lng: 19.575106 },
    { lat: 48.2834984, lng: 19.5752778 },
    { lat: 48.2835164, lng: 19.575473 },
    { lat: 48.2834992, lng: 19.5765203 },
    { lat: 48.2835379, lng: 19.5775646 },
    { lat: 48.2835094, lng: 19.5779457 },
    { lat: 48.2833606, lng: 19.5783421 },
    { lat: 48.2831209, lng: 19.5784963 },
    { lat: 48.2829922, lng: 19.5787122 },
    { lat: 48.2819906, lng: 19.5791313 },
    { lat: 48.2807891, lng: 19.5822993 },
    { lat: 48.2798998, lng: 19.5858348 },
    { lat: 48.277393, lng: 19.5867834 },
    { lat: 48.2773272, lng: 19.5872143 },
    { lat: 48.2765553, lng: 19.5897989 },
    { lat: 48.276407, lng: 19.5906235 },
    { lat: 48.2762235, lng: 19.5911453 },
    { lat: 48.2761421, lng: 19.5917547 },
    { lat: 48.2761693, lng: 19.5922511 },
    { lat: 48.2754635, lng: 19.5946062 },
    { lat: 48.2750208, lng: 19.595594 },
    { lat: 48.2741159, lng: 19.5971018 },
    { lat: 48.2737912, lng: 19.5979575 },
    { lat: 48.2734146, lng: 19.5985688 },
    { lat: 48.2731053, lng: 19.5992266 },
    { lat: 48.2729992, lng: 19.599663 },
    { lat: 48.2729743, lng: 19.5999811 },
    { lat: 48.2730737, lng: 19.6011955 },
    { lat: 48.2730585, lng: 19.6015044 },
    { lat: 48.2727812, lng: 19.6026608 },
    { lat: 48.272664, lng: 19.602907 },
    { lat: 48.2723729, lng: 19.60327 },
    { lat: 48.2723878, lng: 19.6033286 },
    { lat: 48.2733679, lng: 19.6060637 },
    { lat: 48.2749068, lng: 19.6079542 },
    { lat: 48.276333, lng: 19.6101217 },
    { lat: 48.2763415, lng: 19.6103097 },
    { lat: 48.2762377, lng: 19.6104042 },
    { lat: 48.2759344, lng: 19.6100758 },
    { lat: 48.2758037, lng: 19.6101759 },
    { lat: 48.2755019, lng: 19.6102336 },
    { lat: 48.2755036, lng: 19.6105143 },
    { lat: 48.2757115, lng: 19.6108471 },
    { lat: 48.2754492, lng: 19.6113706 },
    { lat: 48.2755555, lng: 19.6116516 },
    { lat: 48.2753519, lng: 19.6118588 },
    { lat: 48.2752138, lng: 19.6118701 },
    { lat: 48.2750983, lng: 19.6120305 },
    { lat: 48.2751593, lng: 19.6123051 },
    { lat: 48.275463, lng: 19.6125199 },
    { lat: 48.2754561, lng: 19.6127099 },
    { lat: 48.275581, lng: 19.6127346 },
    { lat: 48.275578, lng: 19.6128134 },
    { lat: 48.276006, lng: 19.612637 },
    { lat: 48.2771974, lng: 19.6133645 },
    { lat: 48.2770902, lng: 19.6141003 },
    { lat: 48.2770426, lng: 19.6141706 },
    { lat: 48.2770403, lng: 19.6143891 },
    { lat: 48.2775914, lng: 19.6154824 },
    { lat: 48.2775932, lng: 19.6157182 },
    { lat: 48.2776991, lng: 19.615892 },
    { lat: 48.2778029, lng: 19.6162175 },
    { lat: 48.2781351, lng: 19.6162366 },
    { lat: 48.2784406, lng: 19.6163858 },
    { lat: 48.2786641, lng: 19.6166898 },
    { lat: 48.2788929, lng: 19.6171872 },
    { lat: 48.2790975, lng: 19.6178692 },
    { lat: 48.2793256, lng: 19.6183779 },
    { lat: 48.2797777, lng: 19.6184541 },
    { lat: 48.2814414, lng: 19.6149771 },
    { lat: 48.2826006, lng: 19.6162068 },
    { lat: 48.2833916, lng: 19.6171972 },
    { lat: 48.2834209, lng: 19.617564 },
    { lat: 48.2830108, lng: 19.618583 },
    { lat: 48.2830131, lng: 19.6188824 },
    { lat: 48.2833029, lng: 19.6189614 },
    { lat: 48.2838069, lng: 19.618819 },
    { lat: 48.2840165, lng: 19.6203281 },
    { lat: 48.284102, lng: 19.6208458 },
  ],
  Lentvora: [
    { lat: 48.397368, lng: 19.4537375 },
    { lat: 48.3973629, lng: 19.4537043 },
    { lat: 48.3972705, lng: 19.4534176 },
    { lat: 48.3965341, lng: 19.4519652 },
    { lat: 48.396009, lng: 19.4522285 },
    { lat: 48.3959745, lng: 19.4520471 },
    { lat: 48.3956464, lng: 19.4522367 },
    { lat: 48.3953967, lng: 19.4518124 },
    { lat: 48.3950158, lng: 19.452292 },
    { lat: 48.394759, lng: 19.4530213 },
    { lat: 48.3947836, lng: 19.4530623 },
    { lat: 48.3946055, lng: 19.4536403 },
    { lat: 48.3944371, lng: 19.4539166 },
    { lat: 48.3939911, lng: 19.4545003 },
    { lat: 48.393751, lng: 19.4541384 },
    { lat: 48.3934518, lng: 19.4545112 },
    { lat: 48.3933275, lng: 19.4542619 },
    { lat: 48.3934093, lng: 19.454133 },
    { lat: 48.3930452, lng: 19.4536768 },
    { lat: 48.3925898, lng: 19.452954 },
    { lat: 48.3917646, lng: 19.4535274 },
    { lat: 48.3915494, lng: 19.4535973 },
    { lat: 48.3906259, lng: 19.4535133 },
    { lat: 48.3890958, lng: 19.4522229 },
    { lat: 48.3888969, lng: 19.4521177 },
    { lat: 48.3880581, lng: 19.4520131 },
    { lat: 48.3874796, lng: 19.4520792 },
    { lat: 48.3868384, lng: 19.4519137 },
    { lat: 48.3866398, lng: 19.4517382 },
    { lat: 48.3858917, lng: 19.450877 },
    { lat: 48.3851037, lng: 19.4494343 },
    { lat: 48.3847034, lng: 19.4485381 },
    { lat: 48.384249, lng: 19.4479328 },
    { lat: 48.3834755, lng: 19.4466287 },
    { lat: 48.3832391, lng: 19.4463913 },
    { lat: 48.3832633, lng: 19.4463335 },
    { lat: 48.3835155, lng: 19.4462766 },
    { lat: 48.3831434, lng: 19.445581 },
    { lat: 48.383256, lng: 19.445288 },
    { lat: 48.3831421, lng: 19.4451448 },
    { lat: 48.3830581, lng: 19.4448441 },
    { lat: 48.3829399, lng: 19.4446315 },
    { lat: 48.382532, lng: 19.4440312 },
    { lat: 48.3826592, lng: 19.4436224 },
    { lat: 48.3825163, lng: 19.4433348 },
    { lat: 48.3823954, lng: 19.4432086 },
    { lat: 48.3824591, lng: 19.4425499 },
    { lat: 48.3822033, lng: 19.4416892 },
    { lat: 48.3816188, lng: 19.4411654 },
    { lat: 48.3816472, lng: 19.4411018 },
    { lat: 48.3815261, lng: 19.4409728 },
    { lat: 48.3813939, lng: 19.4409386 },
    { lat: 48.3813741, lng: 19.440784 },
    { lat: 48.3810003, lng: 19.4406438 },
    { lat: 48.3807953, lng: 19.4399292 },
    { lat: 48.3807046, lng: 19.4398686 },
    { lat: 48.3807466, lng: 19.4395167 },
    { lat: 48.3806461, lng: 19.4396539 },
    { lat: 48.3805644, lng: 19.4396459 },
    { lat: 48.3805585, lng: 19.43971 },
    { lat: 48.3803662, lng: 19.4394318 },
    { lat: 48.3798731, lng: 19.4380057 },
    { lat: 48.3797192, lng: 19.437776 },
    { lat: 48.3802393, lng: 19.4371661 },
    { lat: 48.3804099, lng: 19.4368849 },
    { lat: 48.3798578, lng: 19.4366967 },
    { lat: 48.3792899, lng: 19.4362403 },
    { lat: 48.3790655, lng: 19.4371032 },
    { lat: 48.3784959, lng: 19.4374763 },
    { lat: 48.3779357, lng: 19.4380486 },
    { lat: 48.377583, lng: 19.438243 },
    { lat: 48.3771252, lng: 19.4383103 },
    { lat: 48.3768412, lng: 19.4382163 },
    { lat: 48.3761241, lng: 19.4382291 },
    { lat: 48.3760055, lng: 19.4380332 },
    { lat: 48.375924, lng: 19.4380036 },
    { lat: 48.3757884, lng: 19.4375897 },
    { lat: 48.3757041, lng: 19.4376901 },
    { lat: 48.3753703, lng: 19.4375429 },
    { lat: 48.3751898, lng: 19.4373203 },
    { lat: 48.3748021, lng: 19.4379696 },
    { lat: 48.3744431, lng: 19.438913 },
    { lat: 48.3742712, lng: 19.4389245 },
    { lat: 48.3737311, lng: 19.4397845 },
    { lat: 48.3732217, lng: 19.4390661 },
    { lat: 48.3729286, lng: 19.438558 },
    { lat: 48.3725219, lng: 19.4381048 },
    { lat: 48.3723952, lng: 19.4375443 },
    { lat: 48.3721239, lng: 19.4375508 },
    { lat: 48.3720922, lng: 19.4372145 },
    { lat: 48.3719363, lng: 19.4373041 },
    { lat: 48.3719441, lng: 19.4371576 },
    { lat: 48.371816, lng: 19.4368737 },
    { lat: 48.371339, lng: 19.4371834 },
    { lat: 48.3710631, lng: 19.437644 },
    { lat: 48.3708571, lng: 19.4382439 },
    { lat: 48.3707429, lng: 19.4388391 },
    { lat: 48.3707079, lng: 19.4396523 },
    { lat: 48.3704847, lng: 19.4398174 },
    { lat: 48.3702457, lng: 19.4398884 },
    { lat: 48.3702576, lng: 19.4392768 },
    { lat: 48.3700684, lng: 19.4387294 },
    { lat: 48.3700421, lng: 19.4383844 },
    { lat: 48.3700776, lng: 19.437849 },
    { lat: 48.3697856, lng: 19.4377268 },
    { lat: 48.3695597, lng: 19.438102 },
    { lat: 48.3693023, lng: 19.4382129 },
    { lat: 48.3691048, lng: 19.4383969 },
    { lat: 48.368945, lng: 19.43864 },
    { lat: 48.368815, lng: 19.438793 },
    { lat: 48.3679, lng: 19.43951 },
    { lat: 48.367068, lng: 19.439993 },
    { lat: 48.36685, lng: 19.439838 },
    { lat: 48.366695, lng: 19.439728 },
    { lat: 48.366673, lng: 19.439714 },
    { lat: 48.366374, lng: 19.439532 },
    { lat: 48.366015, lng: 19.439417 },
    { lat: 48.365693, lng: 19.439301 },
    { lat: 48.36524, lng: 19.439061 },
    { lat: 48.364857, lng: 19.438772 },
    { lat: 48.364352, lng: 19.438282 },
    { lat: 48.364098, lng: 19.438067 },
    { lat: 48.363806, lng: 19.437871 },
    { lat: 48.363571, lng: 19.437636 },
    { lat: 48.363353, lng: 19.437453 },
    { lat: 48.363379, lng: 19.437148 },
    { lat: 48.362868, lng: 19.436744 },
    { lat: 48.362766, lng: 19.4367 },
    { lat: 48.362627, lng: 19.43664 },
    { lat: 48.362475, lng: 19.436506 },
    { lat: 48.362354, lng: 19.436336 },
    { lat: 48.361861, lng: 19.43573 },
    { lat: 48.361697, lng: 19.43546 },
    { lat: 48.361534, lng: 19.435312 },
    { lat: 48.361514, lng: 19.435295 },
    { lat: 48.361333, lng: 19.435165 },
    { lat: 48.36101, lng: 19.435087 },
    { lat: 48.360701, lng: 19.435125 },
    { lat: 48.360503, lng: 19.434981 },
    { lat: 48.360442, lng: 19.434904 },
    { lat: 48.36042, lng: 19.434877 },
    { lat: 48.360409, lng: 19.434871 },
    { lat: 48.360279, lng: 19.434801 },
    { lat: 48.360254, lng: 19.434775 },
    { lat: 48.360094, lng: 19.434686 },
    { lat: 48.359964, lng: 19.434578 },
    { lat: 48.359639, lng: 19.434368 },
    { lat: 48.359582, lng: 19.434214 },
    { lat: 48.359366, lng: 19.434048 },
    { lat: 48.359016, lng: 19.433899 },
    { lat: 48.358791, lng: 19.43388 },
    { lat: 48.358451, lng: 19.433828 },
    { lat: 48.358086, lng: 19.433661 },
    { lat: 48.357392, lng: 19.433388 },
    { lat: 48.357231, lng: 19.433276 },
    { lat: 48.356721, lng: 19.43233 },
    { lat: 48.35663, lng: 19.432107 },
    { lat: 48.356341, lng: 19.431592 },
    { lat: 48.356113, lng: 19.431201 },
    { lat: 48.355841, lng: 19.430648 },
    { lat: 48.35561, lng: 19.43057 },
    { lat: 48.355273, lng: 19.430182 },
    { lat: 48.354921, lng: 19.430063 },
    { lat: 48.354576, lng: 19.430006 },
    { lat: 48.354539, lng: 19.430018 },
    { lat: 48.354379, lng: 19.430069 },
    { lat: 48.35432, lng: 19.430096 },
    { lat: 48.354273, lng: 19.430117 },
    { lat: 48.354098, lng: 19.430166 },
    { lat: 48.353922, lng: 19.430215 },
    { lat: 48.353825, lng: 19.430242 },
    { lat: 48.353654, lng: 19.43029 },
    { lat: 48.353621, lng: 19.430299 },
    { lat: 48.35354, lng: 19.430289 },
    { lat: 48.353502, lng: 19.430284 },
    { lat: 48.353329, lng: 19.430261 },
    { lat: 48.353277, lng: 19.430254 },
    { lat: 48.353083, lng: 19.43026 },
    { lat: 48.352992, lng: 19.430263 },
    { lat: 48.352798, lng: 19.430238 },
    { lat: 48.352618, lng: 19.430203 },
    { lat: 48.352277, lng: 19.430123 },
    { lat: 48.352276, lng: 19.430092 },
    { lat: 48.352252, lng: 19.430085 },
    { lat: 48.352246, lng: 19.430083 },
    { lat: 48.352147, lng: 19.430057 },
    { lat: 48.352123, lng: 19.430051 },
    { lat: 48.352113, lng: 19.430048 },
    { lat: 48.351803, lng: 19.430035 },
    { lat: 48.351481, lng: 19.430118 },
    { lat: 48.351328, lng: 19.430194 },
    { lat: 48.351176, lng: 19.430269 },
    { lat: 48.35057, lng: 19.43069 },
    { lat: 48.350471, lng: 19.430734 },
    { lat: 48.350387, lng: 19.430771 },
    { lat: 48.350246, lng: 19.430833 },
    { lat: 48.350017, lng: 19.430946 },
    { lat: 48.349833, lng: 19.431038 },
    { lat: 48.349567, lng: 19.431024 },
    { lat: 48.349266, lng: 19.431041 },
    { lat: 48.348967, lng: 19.431039 },
    { lat: 48.348696, lng: 19.431 },
    { lat: 48.348461, lng: 19.430905 },
    { lat: 48.348006, lng: 19.430691 },
    { lat: 48.347638, lng: 19.430341 },
    { lat: 48.347613, lng: 19.430317 },
    { lat: 48.347459, lng: 19.429989 },
    { lat: 48.347454, lng: 19.429979 },
    { lat: 48.347444, lng: 19.429958 },
    { lat: 48.347358, lng: 19.429901 },
    { lat: 48.347061, lng: 19.429693 },
    { lat: 48.347013, lng: 19.42974 },
    { lat: 48.346992, lng: 19.429762 },
    { lat: 48.34695, lng: 19.429804 },
    { lat: 48.346367, lng: 19.430001 },
    { lat: 48.346288, lng: 19.43003 },
    { lat: 48.345606, lng: 19.430673 },
    { lat: 48.344756, lng: 19.430979 },
    { lat: 48.344123, lng: 19.431288 },
    { lat: 48.34376, lng: 19.431252 },
    { lat: 48.343524, lng: 19.431203 },
    { lat: 48.342687, lng: 19.431447 },
    { lat: 48.342648, lng: 19.431456 },
    { lat: 48.342662, lng: 19.431507 },
    { lat: 48.342656, lng: 19.431544 },
    { lat: 48.34268, lng: 19.431846 },
    { lat: 48.342751, lng: 19.432364 },
    { lat: 48.342836, lng: 19.432719 },
    { lat: 48.343027, lng: 19.432907 },
    { lat: 48.343175, lng: 19.433357 },
    { lat: 48.343318, lng: 19.433713 },
    { lat: 48.343619, lng: 19.434383 },
    { lat: 48.343635, lng: 19.434421 },
    { lat: 48.343808, lng: 19.434944 },
    { lat: 48.34386, lng: 19.435094 },
    { lat: 48.343894, lng: 19.435195 },
    { lat: 48.343939, lng: 19.435294 },
    { lat: 48.344125, lng: 19.435464 },
    { lat: 48.344311, lng: 19.435493 },
    { lat: 48.344546, lng: 19.435708 },
    { lat: 48.34501, lng: 19.436097 },
    { lat: 48.344995, lng: 19.436158 },
    { lat: 48.345457, lng: 19.437255 },
    { lat: 48.345495, lng: 19.437345 },
    { lat: 48.345517, lng: 19.437397 },
    { lat: 48.345686, lng: 19.437608 },
    { lat: 48.345908, lng: 19.437885 },
    { lat: 48.346148, lng: 19.438922 },
    { lat: 48.346091, lng: 19.439746 },
    { lat: 48.346201, lng: 19.440663 },
    { lat: 48.34616, lng: 19.441576 },
    { lat: 48.346113, lng: 19.44257 },
    { lat: 48.34593, lng: 19.443763 },
    { lat: 48.345807, lng: 19.444974 },
    { lat: 48.346018, lng: 19.445924 },
    { lat: 48.34617, lng: 19.446905 },
    { lat: 48.346569, lng: 19.448075 },
    { lat: 48.346795, lng: 19.449217 },
    { lat: 48.346861, lng: 19.449627 },
    { lat: 48.347012, lng: 19.450723 },
    { lat: 48.347013, lng: 19.450815 },
    { lat: 48.347018, lng: 19.450862 },
    { lat: 48.347163, lng: 19.452053 },
    { lat: 48.347245, lng: 19.452681 },
    { lat: 48.347289, lng: 19.453031 },
    { lat: 48.347328, lng: 19.453594 },
    { lat: 48.3486356, lng: 19.4533722 },
    { lat: 48.3492554, lng: 19.4530944 },
    { lat: 48.3495541, lng: 19.4531972 },
    { lat: 48.3502789, lng: 19.4537765 },
    { lat: 48.350639, lng: 19.4544857 },
    { lat: 48.351063, lng: 19.4549459 },
    { lat: 48.3514057, lng: 19.4550405 },
    { lat: 48.3518337, lng: 19.4553507 },
    { lat: 48.3524426, lng: 19.4560326 },
    { lat: 48.352529, lng: 19.4562878 },
    { lat: 48.352659, lng: 19.4569905 },
    { lat: 48.3526497, lng: 19.457129 },
    { lat: 48.3525599, lng: 19.4574768 },
    { lat: 48.3522455, lng: 19.4581754 },
    { lat: 48.3519687, lng: 19.4584218 },
    { lat: 48.3517938, lng: 19.4586808 },
    { lat: 48.3517056, lng: 19.4600166 },
    { lat: 48.3522911, lng: 19.4608081 },
    { lat: 48.3525056, lng: 19.4606477 },
    { lat: 48.3527777, lng: 19.4606321 },
    { lat: 48.3529668, lng: 19.4608425 },
    { lat: 48.3536334, lng: 19.4610727 },
    { lat: 48.3539587, lng: 19.4614243 },
    { lat: 48.3543645, lng: 19.4623424 },
    { lat: 48.3546007, lng: 19.4626673 },
    { lat: 48.3548568, lng: 19.4628908 },
    { lat: 48.3549407, lng: 19.4634531 },
    { lat: 48.3549149, lng: 19.4643247 },
    { lat: 48.3549955, lng: 19.4662374 },
    { lat: 48.3551954, lng: 19.4677191 },
    { lat: 48.3553077, lng: 19.4684556 },
    { lat: 48.3557503, lng: 19.4699915 },
    { lat: 48.3563056, lng: 19.4732883 },
    { lat: 48.3565627, lng: 19.4738013 },
    { lat: 48.3581349, lng: 19.4760933 },
    { lat: 48.358641, lng: 19.4767474 },
    { lat: 48.3588619, lng: 19.4769861 },
    { lat: 48.3590991, lng: 19.4771447 },
    { lat: 48.359553, lng: 19.4773323 },
    { lat: 48.3600918, lng: 19.4776941 },
    { lat: 48.3614644, lng: 19.4792388 },
    { lat: 48.3631876, lng: 19.4809073 },
    { lat: 48.363982, lng: 19.4817984 },
    { lat: 48.3646706, lng: 19.4824508 },
    { lat: 48.3659625, lng: 19.4834269 },
    { lat: 48.3667531, lng: 19.4847675 },
    { lat: 48.3668176, lng: 19.4845106 },
    { lat: 48.3673555, lng: 19.4837438 },
    { lat: 48.3677248, lng: 19.4834938 },
    { lat: 48.3684648, lng: 19.4826605 },
    { lat: 48.3687117, lng: 19.4824967 },
    { lat: 48.3693084, lng: 19.4818322 },
    { lat: 48.3696889, lng: 19.4814839 },
    { lat: 48.3700016, lng: 19.4813015 },
    { lat: 48.3703086, lng: 19.480902 },
    { lat: 48.3707039, lng: 19.480724 },
    { lat: 48.371059, lng: 19.4806825 },
    { lat: 48.3713914, lng: 19.4807248 },
    { lat: 48.3715742, lng: 19.4804667 },
    { lat: 48.3717984, lng: 19.4798863 },
    { lat: 48.3723397, lng: 19.4796235 },
    { lat: 48.3726316, lng: 19.479674 },
    { lat: 48.373157, lng: 19.479538 },
    { lat: 48.3734043, lng: 19.4796431 },
    { lat: 48.3736195, lng: 19.4795702 },
    { lat: 48.3740063, lng: 19.4790872 },
    { lat: 48.374149, lng: 19.4790332 },
    { lat: 48.3744872, lng: 19.4791305 },
    { lat: 48.374703, lng: 19.4788757 },
    { lat: 48.3749665, lng: 19.4787275 },
    { lat: 48.3754128, lng: 19.4787025 },
    { lat: 48.375766, lng: 19.4789086 },
    { lat: 48.3758993, lng: 19.4788641 },
    { lat: 48.3761697, lng: 19.4783327 },
    { lat: 48.3763991, lng: 19.4780565 },
    { lat: 48.3766866, lng: 19.4779887 },
    { lat: 48.3770186, lng: 19.4778109 },
    { lat: 48.3771768, lng: 19.4774558 },
    { lat: 48.3776439, lng: 19.4769331 },
    { lat: 48.3782148, lng: 19.4765621 },
    { lat: 48.3787113, lng: 19.4765985 },
    { lat: 48.3789047, lng: 19.476446 },
    { lat: 48.3791676, lng: 19.4758015 },
    { lat: 48.3795104, lng: 19.47549 },
    { lat: 48.3799866, lng: 19.4756732 },
    { lat: 48.3803065, lng: 19.4756466 },
    { lat: 48.3804846, lng: 19.4752376 },
    { lat: 48.3806432, lng: 19.4751347 },
    { lat: 48.3808328, lng: 19.4751137 },
    { lat: 48.3810274, lng: 19.4752244 },
    { lat: 48.3812574, lng: 19.4752034 },
    { lat: 48.3814101, lng: 19.47507 },
    { lat: 48.3814207, lng: 19.4747927 },
    { lat: 48.3816158, lng: 19.4745801 },
    { lat: 48.3819806, lng: 19.4743311 },
    { lat: 48.381994, lng: 19.4744009 },
    { lat: 48.3827325, lng: 19.4744055 },
    { lat: 48.3828752, lng: 19.4744936 },
    { lat: 48.3833447, lng: 19.4744588 },
    { lat: 48.3836177, lng: 19.4742248 },
    { lat: 48.3841873, lng: 19.4738957 },
    { lat: 48.3841825, lng: 19.4738385 },
    { lat: 48.3847246, lng: 19.473696 },
    { lat: 48.3850935, lng: 19.4733225 },
    { lat: 48.3851788, lng: 19.4731412 },
    { lat: 48.385158, lng: 19.4726236 },
    { lat: 48.385188, lng: 19.4724474 },
    { lat: 48.3853267, lng: 19.4722052 },
    { lat: 48.3856364, lng: 19.4720826 },
    { lat: 48.3860611, lng: 19.4719063 },
    { lat: 48.3861659, lng: 19.4717127 },
    { lat: 48.3864272, lng: 19.4717618 },
    { lat: 48.3867083, lng: 19.4716272 },
    { lat: 48.3870277, lng: 19.4712493 },
    { lat: 48.3870475, lng: 19.4711423 },
    { lat: 48.3869956, lng: 19.4709017 },
    { lat: 48.3870853, lng: 19.4706019 },
    { lat: 48.3876791, lng: 19.4704319 },
    { lat: 48.3879414, lng: 19.4701834 },
    { lat: 48.3885715, lng: 19.4698692 },
    { lat: 48.3886459, lng: 19.4695571 },
    { lat: 48.3888057, lng: 19.4694084 },
    { lat: 48.3889032, lng: 19.4694237 },
    { lat: 48.3893567, lng: 19.4688533 },
    { lat: 48.3899685, lng: 19.4687753 },
    { lat: 48.3904234, lng: 19.4689391 },
    { lat: 48.3908473, lng: 19.4688941 },
    { lat: 48.3911118, lng: 19.4689786 },
    { lat: 48.3913684, lng: 19.4685172 },
    { lat: 48.3916167, lng: 19.4683333 },
    { lat: 48.3917554, lng: 19.4683538 },
    { lat: 48.3919451, lng: 19.4682686 },
    { lat: 48.3926058, lng: 19.4682993 },
    { lat: 48.3932766, lng: 19.4686899 },
    { lat: 48.3935535, lng: 19.4690916 },
    { lat: 48.3936813, lng: 19.4691742 },
    { lat: 48.3940795, lng: 19.4689422 },
    { lat: 48.3942368, lng: 19.468953 },
    { lat: 48.3947686, lng: 19.4691941 },
    { lat: 48.3953003, lng: 19.4692831 },
    { lat: 48.3958254, lng: 19.4694963 },
    { lat: 48.3960271, lng: 19.4696903 },
    { lat: 48.396474, lng: 19.4696811 },
    { lat: 48.3966714, lng: 19.4697571 },
    { lat: 48.397061, lng: 19.469776 },
    { lat: 48.397146, lng: 19.4697341 },
    { lat: 48.3974166, lng: 19.4692541 },
    { lat: 48.3977182, lng: 19.4689298 },
    { lat: 48.3979634, lng: 19.4685635 },
    { lat: 48.3982654, lng: 19.4683455 },
    { lat: 48.3987991, lng: 19.4683794 },
    { lat: 48.3989682, lng: 19.4683084 },
    { lat: 48.3992621, lng: 19.4680135 },
    { lat: 48.3992986, lng: 19.4677575 },
    { lat: 48.3995951, lng: 19.4674217 },
    { lat: 48.3998756, lng: 19.4673708 },
    { lat: 48.4002361, lng: 19.4674435 },
    { lat: 48.4002921, lng: 19.4673383 },
    { lat: 48.4002914, lng: 19.4672022 },
    { lat: 48.4001926, lng: 19.4668976 },
    { lat: 48.4001583, lng: 19.4665721 },
    { lat: 48.4001744, lng: 19.4659771 },
    { lat: 48.4003973, lng: 19.4652957 },
    { lat: 48.4005881, lng: 19.4651118 },
    { lat: 48.4007096, lng: 19.464816 },
    { lat: 48.400855, lng: 19.4644307 },
    { lat: 48.4008436, lng: 19.4644046 },
    { lat: 48.4000388, lng: 19.462235 },
    { lat: 48.3987148, lng: 19.461975 },
    { lat: 48.3986337, lng: 19.4618768 },
    { lat: 48.3984459, lng: 19.4611902 },
    { lat: 48.3977827, lng: 19.4603615 },
    { lat: 48.3973697, lng: 19.4592164 },
    { lat: 48.3977389, lng: 19.4585054 },
    { lat: 48.3976857, lng: 19.4583078 },
    { lat: 48.3981984, lng: 19.4577526 },
    { lat: 48.3973303, lng: 19.45722 },
    { lat: 48.3972741, lng: 19.4567153 },
    { lat: 48.3972102, lng: 19.4549425 },
    { lat: 48.3969519, lng: 19.4544794 },
    { lat: 48.397368, lng: 19.4537375 },
  ],
  Radzovce: [
    { lat: 48.2064859, lng: 19.8868602 },
    { lat: 48.2064552, lng: 19.8861999 },
    { lat: 48.2067679, lng: 19.8848872 },
    { lat: 48.2071111, lng: 19.8843285 },
    { lat: 48.2080534, lng: 19.8832727 },
    { lat: 48.208583, lng: 19.8828598 },
    { lat: 48.2093491, lng: 19.8814792 },
    { lat: 48.2102973, lng: 19.8804005 },
    { lat: 48.2110637, lng: 19.8798636 },
    { lat: 48.2116062, lng: 19.8793571 },
    { lat: 48.2125151, lng: 19.8790338 },
    { lat: 48.213418, lng: 19.8784765 },
    { lat: 48.2150298, lng: 19.8771426 },
    { lat: 48.216448, lng: 19.8762503 },
    { lat: 48.2173242, lng: 19.8757774 },
    { lat: 48.2187953, lng: 19.8745753 },
    { lat: 48.220059, lng: 19.8729295 },
    { lat: 48.2215818, lng: 19.8700332 },
    { lat: 48.2222934, lng: 19.868104 },
    { lat: 48.2213262, lng: 19.8683219 },
    { lat: 48.2210273, lng: 19.8683905 },
    { lat: 48.2206942, lng: 19.8682173 },
    { lat: 48.2205424, lng: 19.8679946 },
    { lat: 48.2201842, lng: 19.8668763 },
    { lat: 48.2199255, lng: 19.8652234 },
    { lat: 48.2199023, lng: 19.8640425 },
    { lat: 48.2199761, lng: 19.8632528 },
    { lat: 48.2202968, lng: 19.8611928 },
    { lat: 48.2200827, lng: 19.8598676 },
    { lat: 48.2204995, lng: 19.858491 },
    { lat: 48.2207815, lng: 19.853189 },
    { lat: 48.2188628, lng: 19.8515545 },
    { lat: 48.2197487, lng: 19.8471397 },
    { lat: 48.2214357, lng: 19.846476 },
    { lat: 48.222429, lng: 19.8445345 },
    { lat: 48.2231977, lng: 19.8436084 },
    { lat: 48.2236557, lng: 19.843522 },
    { lat: 48.2244672, lng: 19.8409406 },
    { lat: 48.2250428, lng: 19.8401124 },
    { lat: 48.2257984, lng: 19.8377014 },
    { lat: 48.226093, lng: 19.8378206 },
    { lat: 48.2261727, lng: 19.8374005 },
    { lat: 48.2260335, lng: 19.8373869 },
    { lat: 48.2263442, lng: 19.8358022 },
    { lat: 48.2269902, lng: 19.8361224 },
    { lat: 48.2268116, lng: 19.8342542 },
    { lat: 48.2269754, lng: 19.8306758 },
    { lat: 48.2274814, lng: 19.8288253 },
    { lat: 48.2276154, lng: 19.8285896 },
    { lat: 48.2276702, lng: 19.8283005 },
    { lat: 48.2279709, lng: 19.8276284 },
    { lat: 48.2280709, lng: 19.8270808 },
    { lat: 48.2282743, lng: 19.8266312 },
    { lat: 48.2282322, lng: 19.8255661 },
    { lat: 48.2283011, lng: 19.8245601 },
    { lat: 48.2282662, lng: 19.824048 },
    { lat: 48.2283211, lng: 19.8231398 },
    { lat: 48.2284277, lng: 19.8227163 },
    { lat: 48.2288288, lng: 19.8216381 },
    { lat: 48.2288291, lng: 19.8212066 },
    { lat: 48.2288718, lng: 19.8211268 },
    { lat: 48.2288686, lng: 19.8209014 },
    { lat: 48.2286948, lng: 19.8204799 },
    { lat: 48.2287064, lng: 19.8203084 },
    { lat: 48.2286527, lng: 19.8201653 },
    { lat: 48.2286419, lng: 19.8199416 },
    { lat: 48.228103, lng: 19.8188804 },
    { lat: 48.2279231, lng: 19.8182451 },
    { lat: 48.2279509, lng: 19.8178236 },
    { lat: 48.2277299, lng: 19.8171005 },
    { lat: 48.2276579, lng: 19.8170803 },
    { lat: 48.2275983, lng: 19.8167487 },
    { lat: 48.2275307, lng: 19.8166172 },
    { lat: 48.2276339, lng: 19.8161435 },
    { lat: 48.2275811, lng: 19.8159134 },
    { lat: 48.2273332, lng: 19.8153264 },
    { lat: 48.2272437, lng: 19.8149278 },
    { lat: 48.2271154, lng: 19.8130017 },
    { lat: 48.22904, lng: 19.8121077 },
    { lat: 48.2295753, lng: 19.8117021 },
    { lat: 48.2299847, lng: 19.8111467 },
    { lat: 48.2302217, lng: 19.810387 },
    { lat: 48.2317396, lng: 19.8093873 },
    { lat: 48.2323735, lng: 19.8091515 },
    { lat: 48.2328596, lng: 19.8088277 },
    { lat: 48.2332758, lng: 19.8083207 },
    { lat: 48.2336204, lng: 19.8067807 },
    { lat: 48.2336749, lng: 19.805431 },
    { lat: 48.2333057, lng: 19.804362 },
    { lat: 48.232843, lng: 19.8038301 },
    { lat: 48.2324636, lng: 19.8037063 },
    { lat: 48.2319618, lng: 19.8031076 },
    { lat: 48.2317956, lng: 19.8025063 },
    { lat: 48.2315128, lng: 19.8019655 },
    { lat: 48.2312718, lng: 19.8013055 },
    { lat: 48.2310618, lng: 19.7998049 },
    { lat: 48.2308657, lng: 19.7992157 },
    { lat: 48.2305419, lng: 19.7986649 },
    { lat: 48.23024, lng: 19.7978494 },
    { lat: 48.2298208, lng: 19.7959519 },
    { lat: 48.230186, lng: 19.7931625 },
    { lat: 48.2299892, lng: 19.7924575 },
    { lat: 48.2296754, lng: 19.7921347 },
    { lat: 48.2293992, lng: 19.791315 },
    { lat: 48.2285406, lng: 19.7901357 },
    { lat: 48.2284983, lng: 19.7906565 },
    { lat: 48.2282015, lng: 19.7918981 },
    { lat: 48.2280715, lng: 19.7928614 },
    { lat: 48.2276953, lng: 19.7942752 },
    { lat: 48.2274537, lng: 19.7949816 },
    { lat: 48.2264827, lng: 19.7966465 },
    { lat: 48.2257817, lng: 19.797106 },
    { lat: 48.2254083, lng: 19.7972485 },
    { lat: 48.2246726, lng: 19.7973506 },
    { lat: 48.2238591, lng: 19.7973732 },
    { lat: 48.2229461, lng: 19.7969812 },
    { lat: 48.2223354, lng: 19.7975939 },
    { lat: 48.2220317, lng: 19.7980923 },
    { lat: 48.2222492, lng: 19.7981193 },
    { lat: 48.2227151, lng: 19.7986207 },
    { lat: 48.2228409, lng: 19.7988401 },
    { lat: 48.2231208, lng: 19.8005943 },
    { lat: 48.223125, lng: 19.8011033 },
    { lat: 48.2230296, lng: 19.8015211 },
    { lat: 48.2226003, lng: 19.8020308 },
    { lat: 48.2222334, lng: 19.8033452 },
    { lat: 48.2217805, lng: 19.8046546 },
    { lat: 48.2217045, lng: 19.8062851 },
    { lat: 48.2217971, lng: 19.8068077 },
    { lat: 48.2215262, lng: 19.8074336 },
    { lat: 48.2214746, lng: 19.8086738 },
    { lat: 48.2212378, lng: 19.809411 },
    { lat: 48.2210879, lng: 19.8096703 },
    { lat: 48.2207187, lng: 19.8098532 },
    { lat: 48.2203347, lng: 19.810324 },
    { lat: 48.2196944, lng: 19.8107483 },
    { lat: 48.2193613, lng: 19.8112704 },
    { lat: 48.2191374, lng: 19.8118463 },
    { lat: 48.2191656, lng: 19.8124618 },
    { lat: 48.2191119, lng: 19.8127772 },
    { lat: 48.2186178, lng: 19.8137937 },
    { lat: 48.2183345, lng: 19.8139343 },
    { lat: 48.2182677, lng: 19.81457 },
    { lat: 48.2180289, lng: 19.8154448 },
    { lat: 48.2179257, lng: 19.8160212 },
    { lat: 48.2173283, lng: 19.8174032 },
    { lat: 48.2167556, lng: 19.8188873 },
    { lat: 48.2166212, lng: 19.8187998 },
    { lat: 48.2163569, lng: 19.8192973 },
    { lat: 48.2163182, lng: 19.8192651 },
    { lat: 48.2151614, lng: 19.8208682 },
    { lat: 48.2140257, lng: 19.8223311 },
    { lat: 48.2140494, lng: 19.8227209 },
    { lat: 48.2139726, lng: 19.8227677 },
    { lat: 48.2106255, lng: 19.8221798 },
    { lat: 48.2093846, lng: 19.821423 },
    { lat: 48.2095019, lng: 19.8207068 },
    { lat: 48.2075479, lng: 19.8188982 },
    { lat: 48.2059747, lng: 19.8176016 },
    { lat: 48.2059586, lng: 19.8176438 },
    { lat: 48.2057254, lng: 19.8182858 },
    { lat: 48.2052843, lng: 19.8188669 },
    { lat: 48.2044415, lng: 19.8196598 },
    { lat: 48.2035169, lng: 19.8211604 },
    { lat: 48.2029904, lng: 19.8224929 },
    { lat: 48.2028408, lng: 19.8234742 },
    { lat: 48.2028446, lng: 19.8236903 },
    { lat: 48.2032402, lng: 19.8239751 },
    { lat: 48.2036378, lng: 19.8245694 },
    { lat: 48.2031473, lng: 19.8243762 },
    { lat: 48.2035679, lng: 19.8248224 },
    { lat: 48.206431, lng: 19.8289243 },
    { lat: 48.2072162, lng: 19.8299021 },
    { lat: 48.2076724, lng: 19.8303142 },
    { lat: 48.2085738, lng: 19.8308045 },
    { lat: 48.2092038, lng: 19.8310399 },
    { lat: 48.2095206, lng: 19.8310927 },
    { lat: 48.2104935, lng: 19.831006 },
    { lat: 48.2112255, lng: 19.8308471 },
    { lat: 48.2120742, lng: 19.8308248 },
    { lat: 48.2118304, lng: 19.8338589 },
    { lat: 48.2117144, lng: 19.8340136 },
    { lat: 48.2117398, lng: 19.8344173 },
    { lat: 48.2116413, lng: 19.8345619 },
    { lat: 48.2110686, lng: 19.8342926 },
    { lat: 48.2109446, lng: 19.8344209 },
    { lat: 48.2108838, lng: 19.8347027 },
    { lat: 48.210231, lng: 19.8343356 },
    { lat: 48.2102577, lng: 19.8340176 },
    { lat: 48.2100117, lng: 19.833775 },
    { lat: 48.2096524, lng: 19.8335964 },
    { lat: 48.2094001, lng: 19.8332912 },
    { lat: 48.2091839, lng: 19.8332653 },
    { lat: 48.2079706, lng: 19.8355654 },
    { lat: 48.2072401, lng: 19.8346245 },
    { lat: 48.2069752, lng: 19.835342 },
    { lat: 48.2071469, lng: 19.8358949 },
    { lat: 48.2073826, lng: 19.8359241 },
    { lat: 48.2079649, lng: 19.8364896 },
    { lat: 48.2055855, lng: 19.837059 },
    { lat: 48.2055878, lng: 19.8384807 },
    { lat: 48.2041814, lng: 19.8396578 },
    { lat: 48.2044882, lng: 19.8401802 },
    { lat: 48.2034773, lng: 19.8404841 },
    { lat: 48.2020577, lng: 19.8415943 },
    { lat: 48.2013407, lng: 19.841998 },
    { lat: 48.1996741, lng: 19.8434648 },
    { lat: 48.1992394, lng: 19.8431047 },
    { lat: 48.1983752, lng: 19.8426632 },
    { lat: 48.197953, lng: 19.8422838 },
    { lat: 48.1967367, lng: 19.8418011 },
    { lat: 48.1957279, lng: 19.8417156 },
    { lat: 48.194833, lng: 19.8415 },
    { lat: 48.1938836, lng: 19.8411474 },
    { lat: 48.1936526, lng: 19.8438337 },
    { lat: 48.1931027, lng: 19.8450946 },
    { lat: 48.1926038, lng: 19.8457485 },
    { lat: 48.1925244, lng: 19.8463801 },
    { lat: 48.1923761, lng: 19.8469578 },
    { lat: 48.1916909, lng: 19.8476488 },
    { lat: 48.190868, lng: 19.8489351 },
    { lat: 48.1906894, lng: 19.8502925 },
    { lat: 48.1908654, lng: 19.8511259 },
    { lat: 48.1908552, lng: 19.8514327 },
    { lat: 48.1906951, lng: 19.8520778 },
    { lat: 48.190515, lng: 19.8526528 },
    { lat: 48.1897699, lng: 19.8535208 },
    { lat: 48.189415, lng: 19.8544239 },
    { lat: 48.1893935, lng: 19.8550478 },
    { lat: 48.1894548, lng: 19.8555513 },
    { lat: 48.1894093, lng: 19.8557213 },
    { lat: 48.1888934, lng: 19.8563968 },
    { lat: 48.1882992, lng: 19.8581182 },
    { lat: 48.1880236, lng: 19.8591424 },
    { lat: 48.1879192, lng: 19.859358 },
    { lat: 48.1877477, lng: 19.8592748 },
    { lat: 48.1876423, lng: 19.8593366 },
    { lat: 48.1875194, lng: 19.8595406 },
    { lat: 48.1871272, lng: 19.859799 },
    { lat: 48.1869981, lng: 19.8597297 },
    { lat: 48.1867211, lng: 19.859835 },
    { lat: 48.1854396, lng: 19.8605523 },
    { lat: 48.1848371, lng: 19.8611002 },
    { lat: 48.1840625, lng: 19.8639891 },
    { lat: 48.1836627, lng: 19.8648738 },
    { lat: 48.1832642, lng: 19.8655571 },
    { lat: 48.1829993, lng: 19.8658882 },
    { lat: 48.182296, lng: 19.8669972 },
    { lat: 48.1819113, lng: 19.8682831 },
    { lat: 48.181237, lng: 19.8695184 },
    { lat: 48.1808824, lng: 19.8703545 },
    { lat: 48.1807432, lng: 19.8714298 },
    { lat: 48.1801417, lng: 19.8728888 },
    { lat: 48.1806642, lng: 19.8743119 },
    { lat: 48.1807581, lng: 19.8747342 },
    { lat: 48.1811129, lng: 19.8755901 },
    { lat: 48.1810418, lng: 19.8760029 },
    { lat: 48.1809682, lng: 19.8777641 },
    { lat: 48.1807693, lng: 19.878818 },
    { lat: 48.1807184, lng: 19.8793821 },
    { lat: 48.1807276, lng: 19.880401 },
    { lat: 48.1803175, lng: 19.8813656 },
    { lat: 48.1801841, lng: 19.8820573 },
    { lat: 48.180179, lng: 19.8824358 },
    { lat: 48.1803406, lng: 19.8831209 },
    { lat: 48.1803127, lng: 19.8840915 },
    { lat: 48.1804221, lng: 19.8852945 },
    { lat: 48.1807373, lng: 19.8859375 },
    { lat: 48.1810797, lng: 19.8864035 },
    { lat: 48.1818604, lng: 19.8872402 },
    { lat: 48.1823987, lng: 19.8876778 },
    { lat: 48.1830388, lng: 19.8883089 },
    { lat: 48.183597, lng: 19.8885776 },
    { lat: 48.1839255, lng: 19.8889581 },
    { lat: 48.1841854, lng: 19.8897326 },
    { lat: 48.1848328, lng: 19.8910292 },
    { lat: 48.1851971, lng: 19.8926271 },
    { lat: 48.185623, lng: 19.892999 },
    { lat: 48.185646, lng: 19.893049 },
    { lat: 48.185993, lng: 19.893775 },
    { lat: 48.186309, lng: 19.894178 },
    { lat: 48.186735, lng: 19.894482 },
    { lat: 48.188065, lng: 19.895153 },
    { lat: 48.188496, lng: 19.895251 },
    { lat: 48.188781, lng: 19.894963 },
    { lat: 48.188915, lng: 19.894464 },
    { lat: 48.189615, lng: 19.893349 },
    { lat: 48.189665, lng: 19.892705 },
    { lat: 48.190118, lng: 19.892812 },
    { lat: 48.190784, lng: 19.89283 },
    { lat: 48.191307, lng: 19.892677 },
    { lat: 48.192368, lng: 19.892944 },
    { lat: 48.192618, lng: 19.893056 },
    { lat: 48.192763, lng: 19.893109 },
    { lat: 48.193375, lng: 19.893335 },
    { lat: 48.193578, lng: 19.893405 },
    { lat: 48.194197, lng: 19.893527 },
    { lat: 48.194649, lng: 19.893351 },
    { lat: 48.195046, lng: 19.893282 },
    { lat: 48.195224, lng: 19.893335 },
    { lat: 48.195688, lng: 19.892671 },
    { lat: 48.198063, lng: 19.890491 },
    { lat: 48.198126, lng: 19.890468 },
    { lat: 48.1988266, lng: 19.8901844 },
    { lat: 48.200191, lng: 19.8896322 },
    { lat: 48.2006426, lng: 19.8896532 },
    { lat: 48.2009981, lng: 19.8895387 },
    { lat: 48.2021362, lng: 19.8894386 },
    { lat: 48.202766, lng: 19.8892845 },
    { lat: 48.2030862, lng: 19.8891069 },
    { lat: 48.2039072, lng: 19.8892306 },
    { lat: 48.2049339, lng: 19.8900771 },
    { lat: 48.2055811, lng: 19.8893326 },
    { lat: 48.2061276, lng: 19.8878724 },
    { lat: 48.2064859, lng: 19.8868602 },
  ],
  Halič: [
    { lat: 48.2941424, lng: 19.5635622 },
    { lat: 48.2941703, lng: 19.5635767 },
    { lat: 48.2941524, lng: 19.5638503 },
    { lat: 48.2951437, lng: 19.5665174 },
    { lat: 48.2954795, lng: 19.5675961 },
    { lat: 48.2953214, lng: 19.5676336 },
    { lat: 48.2948687, lng: 19.5675211 },
    { lat: 48.294098, lng: 19.5682818 },
    { lat: 48.2961497, lng: 19.5737727 },
    { lat: 48.301166, lng: 19.5698062 },
    { lat: 48.3017258, lng: 19.570978 },
    { lat: 48.3027412, lng: 19.5689925 },
    { lat: 48.3028946, lng: 19.5691908 },
    { lat: 48.3029686, lng: 19.5691566 },
    { lat: 48.3040316, lng: 19.5717422 },
    { lat: 48.3039535, lng: 19.5718093 },
    { lat: 48.3041811, lng: 19.5725698 },
    { lat: 48.3039639, lng: 19.5731171 },
    { lat: 48.3032126, lng: 19.5754357 },
    { lat: 48.3030212, lng: 19.5765912 },
    { lat: 48.3034704, lng: 19.5774408 },
    { lat: 48.3038765, lng: 19.5780881 },
    { lat: 48.3036454, lng: 19.5786523 },
    { lat: 48.3035443, lng: 19.5791246 },
    { lat: 48.3033846, lng: 19.579506 },
    { lat: 48.3037136, lng: 19.5799181 },
    { lat: 48.3034809, lng: 19.5807448 },
    { lat: 48.3033861, lng: 19.5815789 },
    { lat: 48.3034763, lng: 19.5839395 },
    { lat: 48.303238, lng: 19.5844182 },
    { lat: 48.3028797, lng: 19.584741 },
    { lat: 48.3027564, lng: 19.5849271 },
    { lat: 48.3024574, lng: 19.5856307 },
    { lat: 48.3024055, lng: 19.5858795 },
    { lat: 48.3022534, lng: 19.5859515 },
    { lat: 48.301696, lng: 19.5859844 },
    { lat: 48.3017459, lng: 19.5865848 },
    { lat: 48.302456, lng: 19.587844 },
    { lat: 48.3016714, lng: 19.5878882 },
    { lat: 48.3011157, lng: 19.5883771 },
    { lat: 48.3001798, lng: 19.5888807 },
    { lat: 48.3000231, lng: 19.5892482 },
    { lat: 48.299794, lng: 19.5891192 },
    { lat: 48.2995493, lng: 19.589385 },
    { lat: 48.2995256, lng: 19.5900695 },
    { lat: 48.2965443, lng: 19.592646 },
    { lat: 48.2977207, lng: 19.598018 },
    { lat: 48.2971509, lng: 19.5983744 },
    { lat: 48.2967714, lng: 19.5984516 },
    { lat: 48.2963989, lng: 19.5990542 },
    { lat: 48.2955614, lng: 19.599665 },
    { lat: 48.2949913, lng: 19.600238 },
    { lat: 48.2955591, lng: 19.6021809 },
    { lat: 48.2955749, lng: 19.6024539 },
    { lat: 48.2954036, lng: 19.6032429 },
    { lat: 48.2951849, lng: 19.6038376 },
    { lat: 48.2949222, lng: 19.6042753 },
    { lat: 48.2955236, lng: 19.6060967 },
    { lat: 48.2935991, lng: 19.6084481 },
    { lat: 48.2933718, lng: 19.6089247 },
    { lat: 48.2926115, lng: 19.6100715 },
    { lat: 48.2922492, lng: 19.6105251 },
    { lat: 48.2905914, lng: 19.612948 },
    { lat: 48.2903744, lng: 19.6131808 },
    { lat: 48.2896958, lng: 19.6142147 },
    { lat: 48.2892269, lng: 19.6143607 },
    { lat: 48.2896742, lng: 19.6153206 },
    { lat: 48.2898951, lng: 19.6160647 },
    { lat: 48.2904689, lng: 19.617455 },
    { lat: 48.2923545, lng: 19.6200687 },
    { lat: 48.2929833, lng: 19.6207847 },
    { lat: 48.293719, lng: 19.6218989 },
    { lat: 48.2939655, lng: 19.622185 },
    { lat: 48.2945232, lng: 19.6228324 },
    { lat: 48.2945424, lng: 19.6228548 },
    { lat: 48.2959011, lng: 19.6225282 },
    { lat: 48.2967943, lng: 19.6218506 },
    { lat: 48.2975037, lng: 19.6216665 },
    { lat: 48.2979191, lng: 19.6215587 },
    { lat: 48.2980074, lng: 19.6215209 },
    { lat: 48.2982462, lng: 19.6214188 },
    { lat: 48.2986093, lng: 19.6214224 },
    { lat: 48.2989633, lng: 19.6216014 },
    { lat: 48.2992919, lng: 19.6214893 },
    { lat: 48.2995192, lng: 19.621534 },
    { lat: 48.2997575, lng: 19.621716 },
    { lat: 48.3008856, lng: 19.6216981 },
    { lat: 48.3017513, lng: 19.6207383 },
    { lat: 48.3021734, lng: 19.6204567 },
    { lat: 48.3030597, lng: 19.6200214 },
    { lat: 48.3030671, lng: 19.6200606 },
    { lat: 48.3070708, lng: 19.6171865 },
    { lat: 48.3117721, lng: 19.6150845 },
    { lat: 48.3141038, lng: 19.6137281 },
    { lat: 48.3154065, lng: 19.6134326 },
    { lat: 48.3174526, lng: 19.6132784 },
    { lat: 48.3189862, lng: 19.6120891 },
    { lat: 48.320359, lng: 19.6117761 },
    { lat: 48.3214806, lng: 19.6119831 },
    { lat: 48.3231025, lng: 19.6116177 },
    { lat: 48.3245969, lng: 19.6109276 },
    { lat: 48.3252729, lng: 19.6109263 },
    { lat: 48.3269278, lng: 19.6106425 },
    { lat: 48.3286407, lng: 19.6108197 },
    { lat: 48.3293745, lng: 19.610723 },
    { lat: 48.3300577, lng: 19.6107299 },
    { lat: 48.3316474, lng: 19.6108712 },
    { lat: 48.3322004, lng: 19.6120803 },
    { lat: 48.3324682, lng: 19.6131135 },
    { lat: 48.3325211, lng: 19.6139789 },
    { lat: 48.3328677, lng: 19.6144764 },
    { lat: 48.333342, lng: 19.6148937 },
    { lat: 48.3341178, lng: 19.6155541 },
    { lat: 48.3343295, lng: 19.6156584 },
    { lat: 48.3344883, lng: 19.6157278 },
    { lat: 48.3349444, lng: 19.615674 },
    { lat: 48.3354835, lng: 19.6161306 },
    { lat: 48.3356745, lng: 19.6164897 },
    { lat: 48.3358627, lng: 19.6165887 },
    { lat: 48.3361677, lng: 19.6165292 },
    { lat: 48.336571, lng: 19.6166285 },
    { lat: 48.3370189, lng: 19.616984 },
    { lat: 48.3371765, lng: 19.617429 },
    { lat: 48.3372317, lng: 19.6174276 },
    { lat: 48.3372913, lng: 19.6174239 },
    { lat: 48.3371868, lng: 19.6166888 },
    { lat: 48.3371887, lng: 19.6160879 },
    { lat: 48.3373375, lng: 19.6153916 },
    { lat: 48.337507, lng: 19.6150073 },
    { lat: 48.3426862, lng: 19.6048928 },
    { lat: 48.343231, lng: 19.6037636 },
    { lat: 48.3441503, lng: 19.6016613 },
    { lat: 48.3451616, lng: 19.60004 },
    { lat: 48.3455312, lng: 19.5993485 },
    { lat: 48.3465272, lng: 19.5967283 },
    { lat: 48.3476926, lng: 19.5933996 },
    { lat: 48.3490167, lng: 19.5906101 },
    { lat: 48.3495618, lng: 19.5909742 },
    { lat: 48.349599, lng: 19.5907826 },
    { lat: 48.3497935, lng: 19.5907431 },
    { lat: 48.3497538, lng: 19.5903703 },
    { lat: 48.3498312, lng: 19.5901168 },
    { lat: 48.3500029, lng: 19.5900506 },
    { lat: 48.3500695, lng: 19.589906 },
    { lat: 48.3499946, lng: 19.5897185 },
    { lat: 48.3497832, lng: 19.5894589 },
    { lat: 48.3497633, lng: 19.5892984 },
    { lat: 48.3498992, lng: 19.5889648 },
    { lat: 48.3500434, lng: 19.5888381 },
    { lat: 48.3501014, lng: 19.5886305 },
    { lat: 48.3501662, lng: 19.5885449 },
    { lat: 48.3503241, lng: 19.5886455 },
    { lat: 48.3504597, lng: 19.5883315 },
    { lat: 48.3503805, lng: 19.5882277 },
    { lat: 48.3504011, lng: 19.5881601 },
    { lat: 48.3505889, lng: 19.5880026 },
    { lat: 48.3507699, lng: 19.5874054 },
    { lat: 48.3507806, lng: 19.58732 },
    { lat: 48.3506551, lng: 19.5871841 },
    { lat: 48.3506549, lng: 19.5870807 },
    { lat: 48.3507856, lng: 19.5868345 },
    { lat: 48.3508535, lng: 19.5867446 },
    { lat: 48.3509563, lng: 19.5867734 },
    { lat: 48.3510648, lng: 19.586657 },
    { lat: 48.3513476, lng: 19.5865301 },
    { lat: 48.3515981, lng: 19.5865394 },
    { lat: 48.3517927, lng: 19.5866331 },
    { lat: 48.3520173, lng: 19.5859007 },
    { lat: 48.3521054, lng: 19.5857836 },
    { lat: 48.3520652, lng: 19.585668 },
    { lat: 48.3521501, lng: 19.5856241 },
    { lat: 48.3522679, lng: 19.5854427 },
    { lat: 48.3523014, lng: 19.5852624 },
    { lat: 48.3524639, lng: 19.585313 },
    { lat: 48.352616, lng: 19.58502 },
    { lat: 48.3528197, lng: 19.5849419 },
    { lat: 48.3529563, lng: 19.58502 },
    { lat: 48.3531351, lng: 19.5849385 },
    { lat: 48.3532445, lng: 19.5851115 },
    { lat: 48.3536917, lng: 19.584889 },
    { lat: 48.3538861, lng: 19.5848279 },
    { lat: 48.3540039, lng: 19.584864 },
    { lat: 48.3541674, lng: 19.5843961 },
    { lat: 48.3548013, lng: 19.5834986 },
    { lat: 48.3549182, lng: 19.5829261 },
    { lat: 48.3554063, lng: 19.5821884 },
    { lat: 48.3554509, lng: 19.5819574 },
    { lat: 48.3555803, lng: 19.5818779 },
    { lat: 48.355872, lng: 19.5820512 },
    { lat: 48.356288, lng: 19.5813635 },
    { lat: 48.3565586, lng: 19.5810734 },
    { lat: 48.3566425, lng: 19.5810867 },
    { lat: 48.3568782, lng: 19.580942 },
    { lat: 48.3570746, lng: 19.5810691 },
    { lat: 48.3571698, lng: 19.5814002 },
    { lat: 48.3574439, lng: 19.581306 },
    { lat: 48.3575395, lng: 19.5810329 },
    { lat: 48.3576217, lng: 19.5810585 },
    { lat: 48.3576882, lng: 19.5808861 },
    { lat: 48.3578138, lng: 19.5808502 },
    { lat: 48.3577129, lng: 19.5803177 },
    { lat: 48.3576465, lng: 19.5802233 },
    { lat: 48.3576745, lng: 19.5800928 },
    { lat: 48.357626, lng: 19.5798944 },
    { lat: 48.3576616, lng: 19.5797646 },
    { lat: 48.3576049, lng: 19.5795094 },
    { lat: 48.3579566, lng: 19.5791932 },
    { lat: 48.3581185, lng: 19.5793806 },
    { lat: 48.358161, lng: 19.5793248 },
    { lat: 48.3581687, lng: 19.5791001 },
    { lat: 48.3582382, lng: 19.5790143 },
    { lat: 48.3584388, lng: 19.579141 },
    { lat: 48.3584519, lng: 19.5792775 },
    { lat: 48.3585095, lng: 19.57933 },
    { lat: 48.3586722, lng: 19.5790188 },
    { lat: 48.3592321, lng: 19.5791648 },
    { lat: 48.3592647, lng: 19.5792826 },
    { lat: 48.3596259, lng: 19.5790757 },
    { lat: 48.3596953, lng: 19.5791674 },
    { lat: 48.3598544, lng: 19.5787995 },
    { lat: 48.3597438, lng: 19.5784778 },
    { lat: 48.3597391, lng: 19.578299 },
    { lat: 48.3597547, lng: 19.5780508 },
    { lat: 48.3598812, lng: 19.5777146 },
    { lat: 48.3599061, lng: 19.5775154 },
    { lat: 48.3604167, lng: 19.5771771 },
    { lat: 48.3605065, lng: 19.5772196 },
    { lat: 48.360564, lng: 19.577119 },
    { lat: 48.3605383, lng: 19.5770278 },
    { lat: 48.3603863, lng: 19.5769304 },
    { lat: 48.3604186, lng: 19.5763322 },
    { lat: 48.36032, lng: 19.5759731 },
    { lat: 48.3599069, lng: 19.5751001 },
    { lat: 48.3597782, lng: 19.5749465 },
    { lat: 48.3596908, lng: 19.574936 },
    { lat: 48.3597316, lng: 19.5748146 },
    { lat: 48.3599635, lng: 19.5745171 },
    { lat: 48.3599409, lng: 19.5743692 },
    { lat: 48.3600198, lng: 19.5742714 },
    { lat: 48.3599217, lng: 19.5739509 },
    { lat: 48.3599393, lng: 19.5734484 },
    { lat: 48.3598538, lng: 19.5731795 },
    { lat: 48.3597613, lng: 19.5729555 },
    { lat: 48.3596088, lng: 19.572776 },
    { lat: 48.3588863, lng: 19.5723287 },
    { lat: 48.3590873, lng: 19.5719947 },
    { lat: 48.3591895, lng: 19.5716702 },
    { lat: 48.3600954, lng: 19.5721351 },
    { lat: 48.3602998, lng: 19.5713086 },
    { lat: 48.3603933, lng: 19.5700067 },
    { lat: 48.3591673, lng: 19.5695121 },
    { lat: 48.3595068, lng: 19.5676069 },
    { lat: 48.3589695, lng: 19.5689841 },
    { lat: 48.3583859, lng: 19.5684204 },
    { lat: 48.3578095, lng: 19.5677008 },
    { lat: 48.3577813, lng: 19.5676268 },
    { lat: 48.3577606, lng: 19.5637608 },
    { lat: 48.357832, lng: 19.5635001 },
    { lat: 48.3551665, lng: 19.562171 },
    { lat: 48.3549309, lng: 19.563247 },
    { lat: 48.3548306, lng: 19.5654533 },
    { lat: 48.3545886, lng: 19.5653107 },
    { lat: 48.3543106, lng: 19.5650266 },
    { lat: 48.3535014, lng: 19.563799 },
    { lat: 48.3531621, lng: 19.563404 },
    { lat: 48.3529513, lng: 19.5636217 },
    { lat: 48.3523465, lng: 19.5645866 },
    { lat: 48.3511205, lng: 19.5667947 },
    { lat: 48.3506944, lng: 19.5673319 },
    { lat: 48.3496511, lng: 19.5690342 },
    { lat: 48.3496166, lng: 19.5690636 },
    { lat: 48.3490787, lng: 19.5683699 },
    { lat: 48.3478959, lng: 19.5703923 },
    { lat: 48.3445274, lng: 19.5689884 },
    { lat: 48.3440579, lng: 19.5690701 },
    { lat: 48.344048, lng: 19.5691016 },
    { lat: 48.3437032, lng: 19.5703557 },
    { lat: 48.3435176, lng: 19.571247 },
    { lat: 48.343421, lng: 19.5720125 },
    { lat: 48.3429655, lng: 19.5739234 },
    { lat: 48.3428627, lng: 19.5745351 },
    { lat: 48.3429115, lng: 19.5746151 },
    { lat: 48.3425713, lng: 19.575796 },
    { lat: 48.3421482, lng: 19.5769933 },
    { lat: 48.3417093, lng: 19.5777944 },
    { lat: 48.3410329, lng: 19.5787702 },
    { lat: 48.3409287, lng: 19.5787345 },
    { lat: 48.3407754, lng: 19.5789533 },
    { lat: 48.3403715, lng: 19.5792864 },
    { lat: 48.340218, lng: 19.5795764 },
    { lat: 48.3399883, lng: 19.5797785 },
    { lat: 48.3397259, lng: 19.5801732 },
    { lat: 48.3395496, lng: 19.5805321 },
    { lat: 48.3390129, lng: 19.5809969 },
    { lat: 48.3388383, lng: 19.5808718 },
    { lat: 48.3386955, lng: 19.5810005 },
    { lat: 48.3386063, lng: 19.5809506 },
    { lat: 48.3384286, lng: 19.581226 },
    { lat: 48.3382491, lng: 19.5813709 },
    { lat: 48.3382192, lng: 19.5816761 },
    { lat: 48.3381247, lng: 19.5817273 },
    { lat: 48.3380839, lng: 19.5816242 },
    { lat: 48.3379468, lng: 19.5816316 },
    { lat: 48.3379777, lng: 19.581792 },
    { lat: 48.3378715, lng: 19.5821076 },
    { lat: 48.3377458, lng: 19.582044 },
    { lat: 48.3375588, lng: 19.5821678 },
    { lat: 48.3374953, lng: 19.5824513 },
    { lat: 48.3374803, lng: 19.5828619 },
    { lat: 48.337385, lng: 19.5829255 },
    { lat: 48.3373588, lng: 19.5830667 },
    { lat: 48.3374368, lng: 19.5833151 },
    { lat: 48.3373693, lng: 19.5835169 },
    { lat: 48.337394, lng: 19.5837102 },
    { lat: 48.3374353, lng: 19.5837509 },
    { lat: 48.3373954, lng: 19.5839169 },
    { lat: 48.337268, lng: 19.5840125 },
    { lat: 48.3372686, lng: 19.5840978 },
    { lat: 48.3371343, lng: 19.5841187 },
    { lat: 48.3371005, lng: 19.5844657 },
    { lat: 48.3369918, lng: 19.5845035 },
    { lat: 48.3370342, lng: 19.5847127 },
    { lat: 48.3369741, lng: 19.5848304 },
    { lat: 48.3369209, lng: 19.5846992 },
    { lat: 48.3367751, lng: 19.5846405 },
    { lat: 48.3367028, lng: 19.5848616 },
    { lat: 48.3366211, lng: 19.5848965 },
    { lat: 48.3366302, lng: 19.5850245 },
    { lat: 48.336545, lng: 19.5850088 },
    { lat: 48.335954, lng: 19.5838386 },
    { lat: 48.3347334, lng: 19.5828019 },
    { lat: 48.3333682, lng: 19.5817788 },
    { lat: 48.3330924, lng: 19.5817838 },
    { lat: 48.3327067, lng: 19.5816791 },
    { lat: 48.3324799, lng: 19.5815268 },
    { lat: 48.3320739, lng: 19.581076 },
    { lat: 48.3315293, lng: 19.5806016 },
    { lat: 48.3301443, lng: 19.578611 },
    { lat: 48.3299179, lng: 19.5789782 },
    { lat: 48.3297054, lng: 19.5790653 },
    { lat: 48.3296473, lng: 19.5791467 },
    { lat: 48.3295393, lng: 19.5794705 },
    { lat: 48.3293268, lng: 19.5794095 },
    { lat: 48.3291503, lng: 19.5791958 },
    { lat: 48.3288591, lng: 19.5792095 },
    { lat: 48.3286961, lng: 19.5792914 },
    { lat: 48.3283155, lng: 19.5792765 },
    { lat: 48.3282558, lng: 19.5795192 },
    { lat: 48.328097, lng: 19.579498 },
    { lat: 48.3277882, lng: 19.5790043 },
    { lat: 48.3274141, lng: 19.5786098 },
    { lat: 48.3272653, lng: 19.5785635 },
    { lat: 48.3270458, lng: 19.5781485 },
    { lat: 48.3268988, lng: 19.5780177 },
    { lat: 48.3268229, lng: 19.5777628 },
    { lat: 48.3260365, lng: 19.5771265 },
    { lat: 48.3260125, lng: 19.5769232 },
    { lat: 48.3258762, lng: 19.5768232 },
    { lat: 48.3255925, lng: 19.5763631 },
    { lat: 48.3254584, lng: 19.5762661 },
    { lat: 48.3248948, lng: 19.5762102 },
    { lat: 48.3246622, lng: 19.5759594 },
    { lat: 48.3246164, lng: 19.5759374 },
    { lat: 48.3244798, lng: 19.5758717 },
    { lat: 48.3239894, lng: 19.5756359 },
    { lat: 48.3234888, lng: 19.5753952 },
    { lat: 48.3232864, lng: 19.5752979 },
    { lat: 48.3227904, lng: 19.5749241 },
    { lat: 48.3226273, lng: 19.5752518 },
    { lat: 48.3225405, lng: 19.5752746 },
    { lat: 48.3221788, lng: 19.5751519 },
    { lat: 48.3221433, lng: 19.5751399 },
    { lat: 48.3219359, lng: 19.5749881 },
    { lat: 48.3217318, lng: 19.5749398 },
    { lat: 48.321533, lng: 19.5749776 },
    { lat: 48.3212722, lng: 19.5745736 },
    { lat: 48.3210972, lng: 19.5744713 },
    { lat: 48.3209955, lng: 19.5745122 },
    { lat: 48.3210441, lng: 19.5740739 },
    { lat: 48.3212384, lng: 19.5737646 },
    { lat: 48.3222721, lng: 19.57247 },
    { lat: 48.3212581, lng: 19.5707055 },
    { lat: 48.3200389, lng: 19.5670479 },
    { lat: 48.3200231, lng: 19.5652842 },
    { lat: 48.3196326, lng: 19.5625474 },
    { lat: 48.3191861, lng: 19.5626679 },
    { lat: 48.3186767, lng: 19.5608026 },
    { lat: 48.318243, lng: 19.5597269 },
    { lat: 48.3173408, lng: 19.5578294 },
    { lat: 48.3173203, lng: 19.5570406 },
    { lat: 48.3168473, lng: 19.5561878 },
    { lat: 48.316579, lng: 19.5555766 },
    { lat: 48.3158823, lng: 19.5544414 },
    { lat: 48.3157751, lng: 19.5547803 },
    { lat: 48.3153139, lng: 19.5553641 },
    { lat: 48.3146594, lng: 19.5555858 },
    { lat: 48.3140286, lng: 19.5547398 },
    { lat: 48.2981553, lng: 19.5452831 },
    { lat: 48.2980788, lng: 19.545238 },
    { lat: 48.2979243, lng: 19.5452621 },
    { lat: 48.2977288, lng: 19.545517 },
    { lat: 48.2976357, lng: 19.5455016 },
    { lat: 48.2974381, lng: 19.5458352 },
    { lat: 48.297394, lng: 19.5461283 },
    { lat: 48.2971805, lng: 19.5463111 },
    { lat: 48.2971442, lng: 19.5467696 },
    { lat: 48.2968831, lng: 19.5471197 },
    { lat: 48.2967638, lng: 19.5471236 },
    { lat: 48.2965892, lng: 19.5473466 },
    { lat: 48.2965546, lng: 19.5475477 },
    { lat: 48.2966594, lng: 19.5476182 },
    { lat: 48.2966726, lng: 19.5477814 },
    { lat: 48.2966309, lng: 19.547928 },
    { lat: 48.2966817, lng: 19.5480314 },
    { lat: 48.296674, lng: 19.5481298 },
    { lat: 48.2967428, lng: 19.5482091 },
    { lat: 48.2966585, lng: 19.5484287 },
    { lat: 48.2966367, lng: 19.5488373 },
    { lat: 48.2965051, lng: 19.5487688 },
    { lat: 48.2964978, lng: 19.5489016 },
    { lat: 48.2964272, lng: 19.5489853 },
    { lat: 48.2963367, lng: 19.5489791 },
    { lat: 48.2962311, lng: 19.5490842 },
    { lat: 48.2962363, lng: 19.5492694 },
    { lat: 48.2961778, lng: 19.5492967 },
    { lat: 48.2961684, lng: 19.5494619 },
    { lat: 48.296317, lng: 19.5496259 },
    { lat: 48.2964128, lng: 19.549605 },
    { lat: 48.2963819, lng: 19.5496925 },
    { lat: 48.2964887, lng: 19.5500003 },
    { lat: 48.2964241, lng: 19.5500448 },
    { lat: 48.2963422, lng: 19.5502713 },
    { lat: 48.2962292, lng: 19.550289 },
    { lat: 48.2961011, lng: 19.5504697 },
    { lat: 48.2961202, lng: 19.5506135 },
    { lat: 48.2962236, lng: 19.5506796 },
    { lat: 48.2961415, lng: 19.5509061 },
    { lat: 48.296084, lng: 19.55098 },
    { lat: 48.2959403, lng: 19.5509871 },
    { lat: 48.2958865, lng: 19.5512393 },
    { lat: 48.2958999, lng: 19.5513094 },
    { lat: 48.2961899, lng: 19.5513219 },
    { lat: 48.2962617, lng: 19.5515063 },
    { lat: 48.2961225, lng: 19.5516417 },
    { lat: 48.2960643, lng: 19.5518962 },
    { lat: 48.2961612, lng: 19.5520156 },
    { lat: 48.2961225, lng: 19.552134 },
    { lat: 48.2959668, lng: 19.5522499 },
    { lat: 48.2958994, lng: 19.5526674 },
    { lat: 48.2959528, lng: 19.5527507 },
    { lat: 48.2960403, lng: 19.5526967 },
    { lat: 48.296624, lng: 19.5530125 },
    { lat: 48.296284, lng: 19.5542194 },
    { lat: 48.2962023, lng: 19.5544034 },
    { lat: 48.296131, lng: 19.5543683 },
    { lat: 48.296062, lng: 19.5544643 },
    { lat: 48.2960457, lng: 19.5548439 },
    { lat: 48.2955884, lng: 19.5558977 },
    { lat: 48.2954714, lng: 19.5559774 },
    { lat: 48.2953489, lng: 19.5563276 },
    { lat: 48.2951935, lng: 19.5571375 },
    { lat: 48.2952895, lng: 19.5572872 },
    { lat: 48.2952758, lng: 19.5574574 },
    { lat: 48.2948858, lng: 19.5584603 },
    { lat: 48.294697, lng: 19.5592024 },
    { lat: 48.2945906, lng: 19.5593886 },
    { lat: 48.2945049, lng: 19.560538 },
    { lat: 48.2945096, lng: 19.5609335 },
    { lat: 48.2945941, lng: 19.5615731 },
    { lat: 48.2945421, lng: 19.5617168 },
    { lat: 48.2944063, lng: 19.5617259 },
    { lat: 48.2943556, lng: 19.5618511 },
    { lat: 48.2943954, lng: 19.5621524 },
    { lat: 48.2945016, lng: 19.5621563 },
    { lat: 48.2945763, lng: 19.5625831 },
    { lat: 48.2944153, lng: 19.5626815 },
    { lat: 48.2944199, lng: 19.5630547 },
    { lat: 48.2941778, lng: 19.5633239 },
    { lat: 48.2941424, lng: 19.5635622 },
  ],
  Dobroč: [
    { lat: 48.469217, lng: 19.5878947 },
    { lat: 48.4695323, lng: 19.587963 },
    { lat: 48.4699562, lng: 19.5882179 },
    { lat: 48.4705036, lng: 19.5883834 },
    { lat: 48.4718808, lng: 19.5893028 },
    { lat: 48.4730958, lng: 19.5885645 },
    { lat: 48.4735152, lng: 19.5882482 },
    { lat: 48.473702, lng: 19.588046 },
    { lat: 48.4738723, lng: 19.5876027 },
    { lat: 48.4741104, lng: 19.587315 },
    { lat: 48.4745042, lng: 19.587008 },
    { lat: 48.474882, lng: 19.5865418 },
    { lat: 48.4755378, lng: 19.5860455 },
    { lat: 48.4755141, lng: 19.5859513 },
    { lat: 48.4808058, lng: 19.5755823 },
    { lat: 48.479781, lng: 19.5752763 },
    { lat: 48.4806297, lng: 19.5740744 },
    { lat: 48.4806692, lng: 19.5741491 },
    { lat: 48.4816362, lng: 19.5733849 },
    { lat: 48.4819033, lng: 19.5734365 },
    { lat: 48.4830127, lng: 19.5750998 },
    { lat: 48.4836065, lng: 19.5758116 },
    { lat: 48.4855646, lng: 19.571391 },
    { lat: 48.4859975, lng: 19.5702372 },
    { lat: 48.48622, lng: 19.5698039 },
    { lat: 48.4865472, lng: 19.5699636 },
    { lat: 48.4871041, lng: 19.5692331 },
    { lat: 48.4876638, lng: 19.5686807 },
    { lat: 48.4881191, lng: 19.5680886 },
    { lat: 48.4897056, lng: 19.5654557 },
    { lat: 48.4914278, lng: 19.564771 },
    { lat: 48.4917005, lng: 19.5643253 },
    { lat: 48.4931663, lng: 19.5629935 },
    { lat: 48.4940577, lng: 19.5624303 },
    { lat: 48.4947192, lng: 19.5625042 },
    { lat: 48.4953077, lng: 19.5630521 },
    { lat: 48.4958515, lng: 19.5630866 },
    { lat: 48.4962239, lng: 19.5634165 },
    { lat: 48.4964523, lng: 19.5647898 },
    { lat: 48.4966042, lng: 19.5651062 },
    { lat: 48.4969026, lng: 19.565027 },
    { lat: 48.4971629, lng: 19.5652238 },
    { lat: 48.4974419, lng: 19.5649171 },
    { lat: 48.4976159, lng: 19.5651222 },
    { lat: 48.4975416, lng: 19.5652745 },
    { lat: 48.4982136, lng: 19.5657228 },
    { lat: 48.4989435, lng: 19.5653246 },
    { lat: 48.499247, lng: 19.5658222 },
    { lat: 48.4996115, lng: 19.5668915 },
    { lat: 48.4995337, lng: 19.5669412 },
    { lat: 48.5000402, lng: 19.5681838 },
    { lat: 48.5005155, lng: 19.56889 },
    { lat: 48.500845, lng: 19.5690996 },
    { lat: 48.5013065, lng: 19.569508 },
    { lat: 48.5016945, lng: 19.5697365 },
    { lat: 48.5022081, lng: 19.5699274 },
    { lat: 48.5025628, lng: 19.5690094 },
    { lat: 48.5033967, lng: 19.5688878 },
    { lat: 48.5035332, lng: 19.5694813 },
    { lat: 48.5043975, lng: 19.5689394 },
    { lat: 48.5043788, lng: 19.5684791 },
    { lat: 48.5054981, lng: 19.5681897 },
    { lat: 48.5061947, lng: 19.5680969 },
    { lat: 48.5061739, lng: 19.568459 },
    { lat: 48.5058912, lng: 19.5687307 },
    { lat: 48.5054543, lng: 19.5694277 },
    { lat: 48.5068542, lng: 19.5700737 },
    { lat: 48.5070456, lng: 19.5700713 },
    { lat: 48.5071974, lng: 19.5690527 },
    { lat: 48.5081542, lng: 19.5684386 },
    { lat: 48.5082521, lng: 19.5687313 },
    { lat: 48.5089388, lng: 19.5681747 },
    { lat: 48.5091911, lng: 19.5688358 },
    { lat: 48.5089089, lng: 19.5690214 },
    { lat: 48.509087, lng: 19.5704071 },
    { lat: 48.5095867, lng: 19.5699491 },
    { lat: 48.5097667, lng: 19.569671 },
    { lat: 48.5099326, lng: 19.5690852 },
    { lat: 48.5098264, lng: 19.5687092 },
    { lat: 48.5099786, lng: 19.5684141 },
    { lat: 48.5098607, lng: 19.568082 },
    { lat: 48.5102774, lng: 19.5674592 },
    { lat: 48.5108605, lng: 19.5669243 },
    { lat: 48.5109104, lng: 19.567277 },
    { lat: 48.5123108, lng: 19.5666289 },
    { lat: 48.5125893, lng: 19.5673482 },
    { lat: 48.5132119, lng: 19.5683229 },
    { lat: 48.5129589, lng: 19.5691008 },
    { lat: 48.5125757, lng: 19.5694671 },
    { lat: 48.5123158, lng: 19.5698928 },
    { lat: 48.5124665, lng: 19.5700591 },
    { lat: 48.5132336, lng: 19.5695869 },
    { lat: 48.5138342, lng: 19.5694337 },
    { lat: 48.514249, lng: 19.569437 },
    { lat: 48.5149428, lng: 19.5696291 },
    { lat: 48.5149664, lng: 19.5694529 },
    { lat: 48.5164562, lng: 19.5696746 },
    { lat: 48.5164915, lng: 19.5693891 },
    { lat: 48.516923, lng: 19.5697563 },
    { lat: 48.5173591, lng: 19.5698803 },
    { lat: 48.5183461, lng: 19.5698275 },
    { lat: 48.5183495, lng: 19.5704438 },
    { lat: 48.5182695, lng: 19.5705424 },
    { lat: 48.5176709, lng: 19.5705631 },
    { lat: 48.5174345, lng: 19.5709146 },
    { lat: 48.5172554, lng: 19.5713089 },
    { lat: 48.5172244, lng: 19.5716185 },
    { lat: 48.517764, lng: 19.5718359 },
    { lat: 48.5181539, lng: 19.5726479 },
    { lat: 48.5178686, lng: 19.5731882 },
    { lat: 48.5179416, lng: 19.5736751 },
    { lat: 48.5184241, lng: 19.5746182 },
    { lat: 48.519212, lng: 19.5736345 },
    { lat: 48.5193693, lng: 19.5741159 },
    { lat: 48.5195878, lng: 19.574582 },
    { lat: 48.5203655, lng: 19.5737728 },
    { lat: 48.5215095, lng: 19.5713644 },
    { lat: 48.5217747, lng: 19.5705581 },
    { lat: 48.5222132, lng: 19.5698462 },
    { lat: 48.5224953, lng: 19.5699773 },
    { lat: 48.5230415, lng: 19.5691834 },
    { lat: 48.5230348, lng: 19.5688358 },
    { lat: 48.5235479, lng: 19.5682302 },
    { lat: 48.5236336, lng: 19.5683027 },
    { lat: 48.523686, lng: 19.5680698 },
    { lat: 48.523949, lng: 19.5678832 },
    { lat: 48.5240639, lng: 19.5686178 },
    { lat: 48.5251098, lng: 19.5676396 },
    { lat: 48.5256876, lng: 19.5669159 },
    { lat: 48.5261127, lng: 19.5677389 },
    { lat: 48.5259755, lng: 19.5681357 },
    { lat: 48.5257012, lng: 19.5686433 },
    { lat: 48.5259659, lng: 19.5690105 },
    { lat: 48.5263699, lng: 19.5684098 },
    { lat: 48.5272469, lng: 19.5674041 },
    { lat: 48.5275058, lng: 19.5676436 },
    { lat: 48.5279923, lng: 19.5669212 },
    { lat: 48.5288045, lng: 19.5666187 },
    { lat: 48.5295878, lng: 19.5658367 },
    { lat: 48.5298067, lng: 19.5651762 },
    { lat: 48.5310894, lng: 19.5659245 },
    { lat: 48.5311458, lng: 19.5665633 },
    { lat: 48.5320258, lng: 19.5675998 },
    { lat: 48.5329425, lng: 19.567733 },
    { lat: 48.5332121, lng: 19.567469 },
    { lat: 48.5350177, lng: 19.568091 },
    { lat: 48.5356386, lng: 19.5681649 },
    { lat: 48.5357284, lng: 19.5685535 },
    { lat: 48.536442, lng: 19.569108 },
    { lat: 48.536723, lng: 19.569086 },
    { lat: 48.537179, lng: 19.568792 },
    { lat: 48.537784, lng: 19.568549 },
    { lat: 48.537995, lng: 19.568389 },
    { lat: 48.538201, lng: 19.568146 },
    { lat: 48.538391, lng: 19.567924 },
    { lat: 48.538588, lng: 19.567796 },
    { lat: 48.539118, lng: 19.567452 },
    { lat: 48.53918, lng: 19.567474 },
    { lat: 48.539315, lng: 19.56748 },
    { lat: 48.539977, lng: 19.567567 },
    { lat: 48.540124, lng: 19.567528 },
    { lat: 48.540383, lng: 19.567597 },
    { lat: 48.540593, lng: 19.567651 },
    { lat: 48.540883, lng: 19.56774 },
    { lat: 48.54145, lng: 19.567659 },
    { lat: 48.541874, lng: 19.567437 },
    { lat: 48.541888, lng: 19.567382 },
    { lat: 48.541907, lng: 19.567375 },
    { lat: 48.541995, lng: 19.567388 },
    { lat: 48.542324, lng: 19.567396 },
    { lat: 48.542708, lng: 19.567595 },
    { lat: 48.543175, lng: 19.567687 },
    { lat: 48.544122, lng: 19.567807 },
    { lat: 48.544146, lng: 19.56781 },
    { lat: 48.544776, lng: 19.56789 },
    { lat: 48.545073, lng: 19.566932 },
    { lat: 48.544744, lng: 19.566846 },
    { lat: 48.544542, lng: 19.565828 },
    { lat: 48.545417, lng: 19.565012 },
    { lat: 48.545276, lng: 19.564499 },
    { lat: 48.5451, lng: 19.564514 },
    { lat: 48.544969, lng: 19.564077 },
    { lat: 48.544882, lng: 19.564132 },
    { lat: 48.544663, lng: 19.563397 },
    { lat: 48.544551, lng: 19.563021 },
    { lat: 48.544383, lng: 19.562569 },
    { lat: 48.543866, lng: 19.562758 },
    { lat: 48.543375, lng: 19.563076 },
    { lat: 48.543307, lng: 19.562346 },
    { lat: 48.542974, lng: 19.562176 },
    { lat: 48.542775, lng: 19.561579 },
    { lat: 48.542107, lng: 19.56209 },
    { lat: 48.541594, lng: 19.56231 },
    { lat: 48.54155, lng: 19.562344 },
    { lat: 48.541462, lng: 19.56238 },
    { lat: 48.540844, lng: 19.563048 },
    { lat: 48.540681, lng: 19.562706 },
    { lat: 48.540651, lng: 19.56265 },
    { lat: 48.540565, lng: 19.560979 },
    { lat: 48.54121, lng: 19.559931 },
    { lat: 48.541334, lng: 19.55973 },
    { lat: 48.541568, lng: 19.559635 },
    { lat: 48.541768, lng: 19.559506 },
    { lat: 48.541793, lng: 19.55949 },
    { lat: 48.542009, lng: 19.559351 },
    { lat: 48.542036, lng: 19.55932 },
    { lat: 48.542369, lng: 19.558942 },
    { lat: 48.542506, lng: 19.558786 },
    { lat: 48.542834, lng: 19.558197 },
    { lat: 48.54296, lng: 19.557754 },
    { lat: 48.542964, lng: 19.557551 },
    { lat: 48.54297, lng: 19.557294 },
    { lat: 48.542972, lng: 19.557222 },
    { lat: 48.542947, lng: 19.556935 },
    { lat: 48.54273, lng: 19.556214 },
    { lat: 48.541979, lng: 19.554879 },
    { lat: 48.541716, lng: 19.554585 },
    { lat: 48.541468, lng: 19.553638 },
    { lat: 48.541411, lng: 19.553467 },
    { lat: 48.541036, lng: 19.55243 },
    { lat: 48.540968, lng: 19.552338 },
    { lat: 48.540666, lng: 19.552003 },
    { lat: 48.540563, lng: 19.551676 },
    { lat: 48.540334, lng: 19.551709 },
    { lat: 48.540213, lng: 19.551296 },
    { lat: 48.540606, lng: 19.550903 },
    { lat: 48.541099, lng: 19.550612 },
    { lat: 48.541518, lng: 19.550681 },
    { lat: 48.541492, lng: 19.550222 },
    { lat: 48.541459, lng: 19.549939 },
    { lat: 48.541405, lng: 19.549719 },
    { lat: 48.541333, lng: 19.549354 },
    { lat: 48.540935, lng: 19.548896 },
    { lat: 48.540939, lng: 19.548838 },
    { lat: 48.540615, lng: 19.545764 },
    { lat: 48.540675, lng: 19.544993 },
    { lat: 48.540518, lng: 19.543679 },
    { lat: 48.54039, lng: 19.543121 },
    { lat: 48.540356, lng: 19.543017 },
    { lat: 48.539789, lng: 19.541655 },
    { lat: 48.539256, lng: 19.541134 },
    { lat: 48.538975, lng: 19.540364 },
    { lat: 48.537362, lng: 19.538985 },
    { lat: 48.535684, lng: 19.537966 },
    { lat: 48.535651, lng: 19.537946 },
    { lat: 48.535126, lng: 19.537579 },
    { lat: 48.53484, lng: 19.537217 },
    { lat: 48.534881, lng: 19.536735 },
    { lat: 48.534809, lng: 19.536691 },
    { lat: 48.534757, lng: 19.536458 },
    { lat: 48.534647, lng: 19.536494 },
    { lat: 48.534407, lng: 19.536431 },
    { lat: 48.534305, lng: 19.536714 },
    { lat: 48.533709, lng: 19.536269 },
    { lat: 48.533636, lng: 19.535976 },
    { lat: 48.53342, lng: 19.535842 },
    { lat: 48.533368, lng: 19.535806 },
    { lat: 48.533078, lng: 19.535667 },
    { lat: 48.5328678, lng: 19.5355699 },
    { lat: 48.53251, lng: 19.5355343 },
    { lat: 48.5322129, lng: 19.535667 },
    { lat: 48.5322344, lng: 19.5358411 },
    { lat: 48.5322926, lng: 19.5358276 },
    { lat: 48.5324126, lng: 19.5363068 },
    { lat: 48.5327831, lng: 19.5365216 },
    { lat: 48.5321684, lng: 19.5371994 },
    { lat: 48.5317913, lng: 19.5371587 },
    { lat: 48.5314193, lng: 19.5373974 },
    { lat: 48.5314362, lng: 19.5375453 },
    { lat: 48.5316545, lng: 19.537659 },
    { lat: 48.5319645, lng: 19.5376633 },
    { lat: 48.5323408, lng: 19.5384461 },
    { lat: 48.5317575, lng: 19.538945 },
    { lat: 48.5321234, lng: 19.540899 },
    { lat: 48.5308477, lng: 19.5416317 },
    { lat: 48.530916, lng: 19.5420647 },
    { lat: 48.5306887, lng: 19.542807 },
    { lat: 48.5292717, lng: 19.5409597 },
    { lat: 48.5294035, lng: 19.5406879 },
    { lat: 48.5300411, lng: 19.5401169 },
    { lat: 48.5300186, lng: 19.538893 },
    { lat: 48.5303122, lng: 19.5384429 },
    { lat: 48.5297552, lng: 19.537701 },
    { lat: 48.5289747, lng: 19.5375598 },
    { lat: 48.5289903, lng: 19.5377299 },
    { lat: 48.5286397, lng: 19.5376289 },
    { lat: 48.5282947, lng: 19.5392203 },
    { lat: 48.5286485, lng: 19.5395098 },
    { lat: 48.5282418, lng: 19.5406433 },
    { lat: 48.5285441, lng: 19.5410661 },
    { lat: 48.5284088, lng: 19.5424376 },
    { lat: 48.5280296, lng: 19.5423985 },
    { lat: 48.527827, lng: 19.5433575 },
    { lat: 48.527582, lng: 19.5436944 },
    { lat: 48.5272379, lng: 19.5438071 },
    { lat: 48.5268617, lng: 19.543175 },
    { lat: 48.5264508, lng: 19.5431599 },
    { lat: 48.5264019, lng: 19.5425357 },
    { lat: 48.5260491, lng: 19.5425103 },
    { lat: 48.5253833, lng: 19.5429803 },
    { lat: 48.5253658, lng: 19.5431392 },
    { lat: 48.5251842, lng: 19.5432324 },
    { lat: 48.5248014, lng: 19.5432161 },
    { lat: 48.524822, lng: 19.5431262 },
    { lat: 48.523912, lng: 19.5425 },
    { lat: 48.5233976, lng: 19.5426371 },
    { lat: 48.5228998, lng: 19.5432542 },
    { lat: 48.5224628, lng: 19.5418881 },
    { lat: 48.5219102, lng: 19.5421455 },
    { lat: 48.5213529, lng: 19.5418153 },
    { lat: 48.5211185, lng: 19.5424302 },
    { lat: 48.520248, lng: 19.5428326 },
    { lat: 48.5206305, lng: 19.5412255 },
    { lat: 48.5208112, lng: 19.5413332 },
    { lat: 48.520997, lng: 19.5409118 },
    { lat: 48.5221623, lng: 19.5399806 },
    { lat: 48.5226379, lng: 19.5404732 },
    { lat: 48.5229281, lng: 19.5396786 },
    { lat: 48.5225666, lng: 19.5391433 },
    { lat: 48.5217549, lng: 19.5388454 },
    { lat: 48.5204252, lng: 19.5385731 },
    { lat: 48.5202943, lng: 19.5386688 },
    { lat: 48.5202752, lng: 19.5386232 },
    { lat: 48.5198958, lng: 19.5389482 },
    { lat: 48.519503, lng: 19.5382227 },
    { lat: 48.5191445, lng: 19.5377543 },
    { lat: 48.5185585, lng: 19.538538 },
    { lat: 48.5182009, lng: 19.5382134 },
    { lat: 48.5182566, lng: 19.5381255 },
    { lat: 48.518212, lng: 19.5380388 },
    { lat: 48.5188764, lng: 19.5369011 },
    { lat: 48.519194, lng: 19.5361115 },
    { lat: 48.5184014, lng: 19.5365962 },
    { lat: 48.5178065, lng: 19.5366934 },
    { lat: 48.516988, lng: 19.5383281 },
    { lat: 48.5168814, lng: 19.5382148 },
    { lat: 48.5164618, lng: 19.5388913 },
    { lat: 48.5162558, lng: 19.5394597 },
    { lat: 48.5160773, lng: 19.5393262 },
    { lat: 48.5157272, lng: 19.5402431 },
    { lat: 48.5149402, lng: 19.5400848 },
    { lat: 48.514301, lng: 19.5397111 },
    { lat: 48.5142532, lng: 19.539114 },
    { lat: 48.5131501, lng: 19.5385568 },
    { lat: 48.5124737, lng: 19.5393833 },
    { lat: 48.5118391, lng: 19.5406016 },
    { lat: 48.511887, lng: 19.5408131 },
    { lat: 48.5114355, lng: 19.5418034 },
    { lat: 48.5105337, lng: 19.5432687 },
    { lat: 48.510413, lng: 19.5427237 },
    { lat: 48.5104089, lng: 19.5418989 },
    { lat: 48.5105543, lng: 19.5415852 },
    { lat: 48.5111304, lng: 19.5407046 },
    { lat: 48.5110739, lng: 19.5403842 },
    { lat: 48.5107873, lng: 19.5397132 },
    { lat: 48.5109642, lng: 19.5393188 },
    { lat: 48.5113027, lng: 19.5388393 },
    { lat: 48.5109413, lng: 19.5383339 },
    { lat: 48.5115904, lng: 19.5372348 },
    { lat: 48.5115646, lng: 19.5370385 },
    { lat: 48.511804, lng: 19.5362675 },
    { lat: 48.5111006, lng: 19.5366529 },
    { lat: 48.510742, lng: 19.5346908 },
    { lat: 48.5104962, lng: 19.5340259 },
    { lat: 48.5099041, lng: 19.5344331 },
    { lat: 48.5092792, lng: 19.5346628 },
    { lat: 48.509144, lng: 19.53405 },
    { lat: 48.5102388, lng: 19.5329134 },
    { lat: 48.5097021, lng: 19.5328116 },
    { lat: 48.5083782, lng: 19.5332557 },
    { lat: 48.5084124, lng: 19.5334856 },
    { lat: 48.5078466, lng: 19.5337777 },
    { lat: 48.5073164, lng: 19.5338373 },
    { lat: 48.5071916, lng: 19.533549 },
    { lat: 48.5064727, lng: 19.5335269 },
    { lat: 48.5059656, lng: 19.5325244 },
    { lat: 48.5049829, lng: 19.5329641 },
    { lat: 48.5043388, lng: 19.5329236 },
    { lat: 48.5038117, lng: 19.532786 },
    { lat: 48.5039729, lng: 19.5331793 },
    { lat: 48.5034804, lng: 19.533279 },
    { lat: 48.503079, lng: 19.5328081 },
    { lat: 48.502751, lng: 19.5332225 },
    { lat: 48.5026196, lng: 19.5347223 },
    { lat: 48.5022985, lng: 19.5351395 },
    { lat: 48.5008989, lng: 19.5351152 },
    { lat: 48.500827, lng: 19.5346558 },
    { lat: 48.5006788, lng: 19.5347182 },
    { lat: 48.5006913, lng: 19.5327153 },
    { lat: 48.4989027, lng: 19.5327079 },
    { lat: 48.4986818, lng: 19.532291 },
    { lat: 48.4981983, lng: 19.5323653 },
    { lat: 48.4971902, lng: 19.5331867 },
    { lat: 48.4968505, lng: 19.53364 },
    { lat: 48.4965599, lng: 19.5345429 },
    { lat: 48.4963658, lng: 19.5349543 },
    { lat: 48.4952561, lng: 19.5340105 },
    { lat: 48.4918691, lng: 19.5370555 },
    { lat: 48.4916056, lng: 19.5370112 },
    { lat: 48.4909462, lng: 19.5372242 },
    { lat: 48.4891509, lng: 19.537591 },
    { lat: 48.4877102, lng: 19.5382178 },
    { lat: 48.4873103, lng: 19.5391493 },
    { lat: 48.486906, lng: 19.5395225 },
    { lat: 48.4861782, lng: 19.5410715 },
    { lat: 48.4859272, lng: 19.5421807 },
    { lat: 48.4857438, lng: 19.5424289 },
    { lat: 48.4855771, lng: 19.5428974 },
    { lat: 48.4850776, lng: 19.5433848 },
    { lat: 48.4849278, lng: 19.5437972 },
    { lat: 48.4844975, lng: 19.5443215 },
    { lat: 48.4841943, lng: 19.5444912 },
    { lat: 48.4837175, lng: 19.5450026 },
    { lat: 48.4826596, lng: 19.5457176 },
    { lat: 48.481843, lng: 19.5465086 },
    { lat: 48.4812623, lng: 19.5467239 },
    { lat: 48.4797089, lng: 19.5487 },
    { lat: 48.4786121, lng: 19.5497453 },
    { lat: 48.4782881, lng: 19.5505422 },
    { lat: 48.4781177, lng: 19.5506107 },
    { lat: 48.4777231, lng: 19.5510491 },
    { lat: 48.4776337, lng: 19.5510197 },
    { lat: 48.4775543, lng: 19.5511771 },
    { lat: 48.4773905, lng: 19.5511697 },
    { lat: 48.4773035, lng: 19.551286 },
    { lat: 48.4765997, lng: 19.5513967 },
    { lat: 48.4763814, lng: 19.5516712 },
    { lat: 48.4761979, lng: 19.551702 },
    { lat: 48.4760346, lng: 19.5518276 },
    { lat: 48.4758844, lng: 19.5518162 },
    { lat: 48.475777, lng: 19.5519105 },
    { lat: 48.4756349, lng: 19.5519068 },
    { lat: 48.4752624, lng: 19.5522854 },
    { lat: 48.4751599, lng: 19.5524587 },
    { lat: 48.4750135, lng: 19.5524849 },
    { lat: 48.4742984, lng: 19.5529629 },
    { lat: 48.4741232, lng: 19.5522641 },
    { lat: 48.4737611, lng: 19.5515478 },
    { lat: 48.4726853, lng: 19.5508644 },
    { lat: 48.4726659, lng: 19.5509616 },
    { lat: 48.4723806, lng: 19.5509301 },
    { lat: 48.4722328, lng: 19.5506578 },
    { lat: 48.4714085, lng: 19.5504995 },
    { lat: 48.4713368, lng: 19.5505318 },
    { lat: 48.470892, lng: 19.5516199 },
    { lat: 48.4709928, lng: 19.5547185 },
    { lat: 48.4719162, lng: 19.5547835 },
    { lat: 48.4725066, lng: 19.5549754 },
    { lat: 48.4717665, lng: 19.5560853 },
    { lat: 48.4713441, lng: 19.5575638 },
    { lat: 48.4706651, lng: 19.5576946 },
    { lat: 48.4695215, lng: 19.5582671 },
    { lat: 48.4690942, lng: 19.5588515 },
    { lat: 48.469979, lng: 19.560133 },
    { lat: 48.4693303, lng: 19.5613476 },
    { lat: 48.4676711, lng: 19.5626381 },
    { lat: 48.4665708, lng: 19.5626765 },
    { lat: 48.4664442, lng: 19.5629386 },
    { lat: 48.4651955, lng: 19.56309 },
    { lat: 48.4642825, lng: 19.5654169 },
    { lat: 48.4646842, lng: 19.5660925 },
    { lat: 48.4646303, lng: 19.5662717 },
    { lat: 48.4648916, lng: 19.5663406 },
    { lat: 48.4651488, lng: 19.5664957 },
    { lat: 48.4656815, lng: 19.566563 },
    { lat: 48.4657393, lng: 19.5668397 },
    { lat: 48.4655926, lng: 19.5679425 },
    { lat: 48.4644993, lng: 19.5679389 },
    { lat: 48.4636686, lng: 19.5680582 },
    { lat: 48.4640904, lng: 19.568631 },
    { lat: 48.4643644, lng: 19.5687574 },
    { lat: 48.4648652, lng: 19.5701933 },
    { lat: 48.4654907, lng: 19.573125 },
    { lat: 48.4658118, lng: 19.5744287 },
    { lat: 48.4663134, lng: 19.5769451 },
    { lat: 48.4662674, lng: 19.5773248 },
    { lat: 48.4663284, lng: 19.5781628 },
    { lat: 48.4667303, lng: 19.5788183 },
    { lat: 48.4669708, lng: 19.5797668 },
    { lat: 48.4672582, lng: 19.5805317 },
    { lat: 48.467061, lng: 19.5822128 },
    { lat: 48.4675232, lng: 19.5841383 },
    { lat: 48.4677988, lng: 19.5848939 },
    { lat: 48.4692045, lng: 19.5878694 },
    { lat: 48.469217, lng: 19.5878947 },
  ],
  FiľakovskéKováče: [
    { lat: 48.2938369, lng: 19.8086992 },
    { lat: 48.2938196, lng: 19.8083043 },
    { lat: 48.2938068, lng: 19.8025152 },
    { lat: 48.2940474, lng: 19.800784 },
    { lat: 48.2926276, lng: 19.8004952 },
    { lat: 48.2911973, lng: 19.7999735 },
    { lat: 48.2911095, lng: 19.7995584 },
    { lat: 48.2927285, lng: 19.7960203 },
    { lat: 48.2929084, lng: 19.7953965 },
    { lat: 48.292962, lng: 19.7949783 },
    { lat: 48.2930681, lng: 19.7873738 },
    { lat: 48.2936036, lng: 19.7846674 },
    { lat: 48.2956113, lng: 19.7797307 },
    { lat: 48.2960789, lng: 19.7784273 },
    { lat: 48.2969023, lng: 19.7756676 },
    { lat: 48.2976621, lng: 19.7737325 },
    { lat: 48.298586, lng: 19.7727365 },
    { lat: 48.298627, lng: 19.7725959 },
    { lat: 48.2990496, lng: 19.7702005 },
    { lat: 48.3001982, lng: 19.7653919 },
    { lat: 48.3005898, lng: 19.7622443 },
    { lat: 48.3007036, lng: 19.7609471 },
    { lat: 48.3005935, lng: 19.7600596 },
    { lat: 48.3004193, lng: 19.7535612 },
    { lat: 48.2994734, lng: 19.753443 },
    { lat: 48.2970895, lng: 19.7531442 },
    { lat: 48.2976694, lng: 19.7494015 },
    { lat: 48.29496, lng: 19.7500174 },
    { lat: 48.2948453, lng: 19.7493364 },
    { lat: 48.2943115, lng: 19.7493963 },
    { lat: 48.2926099, lng: 19.7497947 },
    { lat: 48.2919567, lng: 19.7487591 },
    { lat: 48.2893171, lng: 19.7464825 },
    { lat: 48.288601, lng: 19.7453008 },
    { lat: 48.2887899, lng: 19.7450092 },
    { lat: 48.2880147, lng: 19.7439709 },
    { lat: 48.2877785, lng: 19.7431663 },
    { lat: 48.2872836, lng: 19.7427547 },
    { lat: 48.2870631, lng: 19.7419007 },
    { lat: 48.2865241, lng: 19.7417638 },
    { lat: 48.2851714, lng: 19.7416981 },
    { lat: 48.2842739, lng: 19.7422164 },
    { lat: 48.2829465, lng: 19.742492 },
    { lat: 48.2828573, lng: 19.744189 },
    { lat: 48.2824571, lng: 19.7454546 },
    { lat: 48.2823284, lng: 19.7463854 },
    { lat: 48.2822264, lng: 19.7467323 },
    { lat: 48.2812872, lng: 19.7484931 },
    { lat: 48.2803428, lng: 19.7496539 },
    { lat: 48.2785097, lng: 19.7526018 },
    { lat: 48.2772977, lng: 19.7560211 },
    { lat: 48.2760099, lng: 19.75752 },
    { lat: 48.2754986, lng: 19.758569 },
    { lat: 48.2753524, lng: 19.7609122 },
    { lat: 48.2751804, lng: 19.7621689 },
    { lat: 48.2743916, lng: 19.7646611 },
    { lat: 48.2743695, lng: 19.7659974 },
    { lat: 48.2745513, lng: 19.7668745 },
    { lat: 48.274634, lng: 19.768744 },
    { lat: 48.2744849, lng: 19.7702637 },
    { lat: 48.273799, lng: 19.7721075 },
    { lat: 48.2730184, lng: 19.7735918 },
    { lat: 48.2711756, lng: 19.7760041 },
    { lat: 48.2699099, lng: 19.7787277 },
    { lat: 48.268858, lng: 19.7807273 },
    { lat: 48.2684992, lng: 19.7817353 },
    { lat: 48.2681249, lng: 19.7819102 },
    { lat: 48.2684475, lng: 19.7823529 },
    { lat: 48.2687115, lng: 19.7844592 },
    { lat: 48.2687832, lng: 19.7854476 },
    { lat: 48.2687991, lng: 19.7869083 },
    { lat: 48.2685904, lng: 19.788179 },
    { lat: 48.2683156, lng: 19.7913587 },
    { lat: 48.2683343, lng: 19.7916023 },
    { lat: 48.2758707, lng: 19.7995499 },
    { lat: 48.2762993, lng: 19.8003809 },
    { lat: 48.2766509, lng: 19.8008711 },
    { lat: 48.2769719, lng: 19.8003259 },
    { lat: 48.277128, lng: 19.8005199 },
    { lat: 48.2773527, lng: 19.801045 },
    { lat: 48.2776424, lng: 19.8013043 },
    { lat: 48.2792574, lng: 19.8032157 },
    { lat: 48.2804874, lng: 19.8049791 },
    { lat: 48.2812945, lng: 19.8058388 },
    { lat: 48.2818748, lng: 19.8062737 },
    { lat: 48.2823219, lng: 19.8069626 },
    { lat: 48.2825905, lng: 19.8072835 },
    { lat: 48.2834808, lng: 19.8081608 },
    { lat: 48.2846326, lng: 19.8089403 },
    { lat: 48.284826, lng: 19.8091406 },
    { lat: 48.2855969, lng: 19.8103538 },
    { lat: 48.2858261, lng: 19.8106223 },
    { lat: 48.2863859, lng: 19.8110526 },
    { lat: 48.2870912, lng: 19.8114169 },
    { lat: 48.2877679, lng: 19.8115846 },
    { lat: 48.2886648, lng: 19.8119456 },
    { lat: 48.2891105, lng: 19.8109869 },
    { lat: 48.2895565, lng: 19.8103891 },
    { lat: 48.2896746, lng: 19.8104599 },
    { lat: 48.2898509, lng: 19.8102706 },
    { lat: 48.2902354, lng: 19.8096579 },
    { lat: 48.2907536, lng: 19.8104022 },
    { lat: 48.2909705, lng: 19.810879 },
    { lat: 48.2911781, lng: 19.8108327 },
    { lat: 48.2914933, lng: 19.8112376 },
    { lat: 48.2917583, lng: 19.8117358 },
    { lat: 48.2923992, lng: 19.8114973 },
    { lat: 48.2927112, lng: 19.8108535 },
    { lat: 48.2928244, lng: 19.8104298 },
    { lat: 48.2934946, lng: 19.809421 },
    { lat: 48.2935576, lng: 19.8089573 },
    { lat: 48.2938369, lng: 19.8086992 },
  ],
  Lovinobaňa: [
    { lat: 48.4072339, lng: 19.6188602 },
    { lat: 48.4080971, lng: 19.6205497 },
    { lat: 48.4088916, lng: 19.6214699 },
    { lat: 48.4092837, lng: 19.6220189 },
    { lat: 48.4102908, lng: 19.6229553 },
    { lat: 48.4112139, lng: 19.6242487 },
    { lat: 48.4127456, lng: 19.6254256 },
    { lat: 48.4144244, lng: 19.6250031 },
    { lat: 48.4155164, lng: 19.62463 },
    { lat: 48.4170665, lng: 19.6244721 },
    { lat: 48.4182386, lng: 19.6247477 },
    { lat: 48.4191425, lng: 19.624707 },
    { lat: 48.4201385, lng: 19.624952 },
    { lat: 48.4206808, lng: 19.624882 },
    { lat: 48.4213369, lng: 19.6251139 },
    { lat: 48.4217031, lng: 19.6257334 },
    { lat: 48.4225759, lng: 19.6255476 },
    { lat: 48.4240009, lng: 19.6260837 },
    { lat: 48.4249859, lng: 19.6267588 },
    { lat: 48.4265825, lng: 19.6285234 },
    { lat: 48.4270264, lng: 19.629988 },
    { lat: 48.4278952, lng: 19.6311122 },
    { lat: 48.4292078, lng: 19.6312324 },
    { lat: 48.43066, lng: 19.6316747 },
    { lat: 48.4312697, lng: 19.6316227 },
    { lat: 48.431656, lng: 19.631236 },
    { lat: 48.431906, lng: 19.630621 },
    { lat: 48.432468, lng: 19.628781 },
    { lat: 48.432886, lng: 19.628195 },
    { lat: 48.433118, lng: 19.627888 },
    { lat: 48.433404, lng: 19.627505 },
    { lat: 48.434092, lng: 19.62721 },
    { lat: 48.434535, lng: 19.626734 },
    { lat: 48.434869, lng: 19.625935 },
    { lat: 48.435297, lng: 19.624646 },
    { lat: 48.435305, lng: 19.624556 },
    { lat: 48.435326, lng: 19.624261 },
    { lat: 48.435495, lng: 19.623624 },
    { lat: 48.435977, lng: 19.623172 },
    { lat: 48.436311, lng: 19.622861 },
    { lat: 48.436477, lng: 19.622704 },
    { lat: 48.436961, lng: 19.622217 },
    { lat: 48.437294, lng: 19.621877 },
    { lat: 48.437389, lng: 19.621782 },
    { lat: 48.438996, lng: 19.620501 },
    { lat: 48.439276, lng: 19.621003 },
    { lat: 48.439522, lng: 19.621425 },
    { lat: 48.439692, lng: 19.621703 },
    { lat: 48.439828, lng: 19.621927 },
    { lat: 48.439886, lng: 19.62202 },
    { lat: 48.43998, lng: 19.622147 },
    { lat: 48.440016, lng: 19.622214 },
    { lat: 48.440141, lng: 19.622435 },
    { lat: 48.440373, lng: 19.623661 },
    { lat: 48.440372, lng: 19.624415 },
    { lat: 48.440663, lng: 19.624777 },
    { lat: 48.441632, lng: 19.625223 },
    { lat: 48.441657, lng: 19.625234 },
    { lat: 48.4417, lng: 19.625255 },
    { lat: 48.442453, lng: 19.624747 },
    { lat: 48.442914, lng: 19.623703 },
    { lat: 48.442986, lng: 19.623281 },
    { lat: 48.443357, lng: 19.622454 },
    { lat: 48.443535, lng: 19.621351 },
    { lat: 48.443757, lng: 19.620536 },
    { lat: 48.443743, lng: 19.620254 },
    { lat: 48.443755, lng: 19.620012 },
    { lat: 48.443804, lng: 19.61899 },
    { lat: 48.443911, lng: 19.618182 },
    { lat: 48.443945, lng: 19.617873 },
    { lat: 48.444107, lng: 19.617117 },
    { lat: 48.444225, lng: 19.616616 },
    { lat: 48.444233, lng: 19.616586 },
    { lat: 48.444333, lng: 19.616635 },
    { lat: 48.44445, lng: 19.616601 },
    { lat: 48.444492, lng: 19.616735 },
    { lat: 48.444492, lng: 19.617065 },
    { lat: 48.444439, lng: 19.617067 },
    { lat: 48.444446, lng: 19.617128 },
    { lat: 48.444489, lng: 19.617209 },
    { lat: 48.444597, lng: 19.6172 },
    { lat: 48.444709, lng: 19.617341 },
    { lat: 48.444862, lng: 19.617361 },
    { lat: 48.444963, lng: 19.617156 },
    { lat: 48.445295, lng: 19.617152 },
    { lat: 48.445311, lng: 19.617462 },
    { lat: 48.445546, lng: 19.617452 },
    { lat: 48.445662, lng: 19.617708 },
    { lat: 48.445774, lng: 19.617777 },
    { lat: 48.445833, lng: 19.61801 },
    { lat: 48.446128, lng: 19.618113 },
    { lat: 48.446337, lng: 19.61828 },
    { lat: 48.446465, lng: 19.618191 },
    { lat: 48.446639, lng: 19.61838 },
    { lat: 48.446777, lng: 19.618505 },
    { lat: 48.446743, lng: 19.618567 },
    { lat: 48.446928, lng: 19.618613 },
    { lat: 48.447155, lng: 19.618926 },
    { lat: 48.447358, lng: 19.619019 },
    { lat: 48.447456, lng: 19.619147 },
    { lat: 48.447542, lng: 19.619488 },
    { lat: 48.447533, lng: 19.619769 },
    { lat: 48.447785, lng: 19.619952 },
    { lat: 48.447931, lng: 19.619793 },
    { lat: 48.448159, lng: 19.619897 },
    { lat: 48.448285, lng: 19.619961 },
    { lat: 48.448334, lng: 19.619976 },
    { lat: 48.448479, lng: 19.620033 },
    { lat: 48.448536, lng: 19.620129 },
    { lat: 48.44853, lng: 19.620153 },
    { lat: 48.448516, lng: 19.620222 },
    { lat: 48.448686, lng: 19.62046 },
    { lat: 48.448719, lng: 19.620725 },
    { lat: 48.449332, lng: 19.621107 },
    { lat: 48.44945, lng: 19.621236 },
    { lat: 48.449671, lng: 19.621288 },
    { lat: 48.449741, lng: 19.621148 },
    { lat: 48.449926, lng: 19.621033 },
    { lat: 48.450046, lng: 19.621062 },
    { lat: 48.450197, lng: 19.620934 },
    { lat: 48.450287, lng: 19.620962 },
    { lat: 48.450495, lng: 19.62061 },
    { lat: 48.450789, lng: 19.620361 },
    { lat: 48.450952, lng: 19.620358 },
    { lat: 48.451602, lng: 19.619821 },
    { lat: 48.45192, lng: 19.619643 },
    { lat: 48.452024, lng: 19.619452 },
    { lat: 48.452119, lng: 19.619398 },
    { lat: 48.452182, lng: 19.619372 },
    { lat: 48.45229, lng: 19.61932 },
    { lat: 48.452532, lng: 19.619205 },
    { lat: 48.452693, lng: 19.618944 },
    { lat: 48.452895, lng: 19.618718 },
    { lat: 48.453189, lng: 19.618542 },
    { lat: 48.453337, lng: 19.618059 },
    { lat: 48.453274, lng: 19.617859 },
    { lat: 48.453328, lng: 19.617615 },
    { lat: 48.453307, lng: 19.617268 },
    { lat: 48.453521, lng: 19.616816 },
    { lat: 48.453395, lng: 19.616699 },
    { lat: 48.453452, lng: 19.616495 },
    { lat: 48.453651, lng: 19.616295 },
    { lat: 48.453671, lng: 19.616142 },
    { lat: 48.453823, lng: 19.61571 },
    { lat: 48.453811, lng: 19.615546 },
    { lat: 48.453699, lng: 19.615475 },
    { lat: 48.45375, lng: 19.615174 },
    { lat: 48.453842, lng: 19.614876 },
    { lat: 48.453883, lng: 19.614522 },
    { lat: 48.453924, lng: 19.614442 },
    { lat: 48.453904, lng: 19.614191 },
    { lat: 48.453816, lng: 19.613966 },
    { lat: 48.454018, lng: 19.613643 },
    { lat: 48.454159, lng: 19.612977 },
    { lat: 48.453956, lng: 19.61249 },
    { lat: 48.454, lng: 19.612163 },
    { lat: 48.454007, lng: 19.611821 },
    { lat: 48.454163, lng: 19.611641 },
    { lat: 48.454117, lng: 19.611169 },
    { lat: 48.454195, lng: 19.61097 },
    { lat: 48.454289, lng: 19.610641 },
    { lat: 48.454355, lng: 19.610331 },
    { lat: 48.454294, lng: 19.610151 },
    { lat: 48.454525, lng: 19.609798 },
    { lat: 48.454707, lng: 19.609336 },
    { lat: 48.454722, lng: 19.609058 },
    { lat: 48.454655, lng: 19.608859 },
    { lat: 48.454792, lng: 19.608492 },
    { lat: 48.454801, lng: 19.608164 },
    { lat: 48.454862, lng: 19.607864 },
    { lat: 48.454839, lng: 19.607529 },
    { lat: 48.454889, lng: 19.607283 },
    { lat: 48.455158, lng: 19.606844 },
    { lat: 48.455317, lng: 19.606694 },
    { lat: 48.45534, lng: 19.606357 },
    { lat: 48.45548, lng: 19.606213 },
    { lat: 48.4556, lng: 19.606248 },
    { lat: 48.455664, lng: 19.606028 },
    { lat: 48.455763, lng: 19.605928 },
    { lat: 48.455859, lng: 19.605806 },
    { lat: 48.456012, lng: 19.605709 },
    { lat: 48.456053, lng: 19.605494 },
    { lat: 48.456285, lng: 19.605266 },
    { lat: 48.456473, lng: 19.604791 },
    { lat: 48.45668, lng: 19.604256 },
    { lat: 48.456847, lng: 19.60418 },
    { lat: 48.457004, lng: 19.603831 },
    { lat: 48.45701, lng: 19.603615 },
    { lat: 48.457142, lng: 19.603334 },
    { lat: 48.457161, lng: 19.603144 },
    { lat: 48.457396, lng: 19.602961 },
    { lat: 48.457382, lng: 19.60265 },
    { lat: 48.457436, lng: 19.602433 },
    { lat: 48.457499, lng: 19.602392 },
    { lat: 48.457668, lng: 19.602116 },
    { lat: 48.457941, lng: 19.601869 },
    { lat: 48.457929, lng: 19.601614 },
    { lat: 48.457942, lng: 19.601275 },
    { lat: 48.458014, lng: 19.601056 },
    { lat: 48.458302, lng: 19.600903 },
    { lat: 48.458467, lng: 19.600503 },
    { lat: 48.458547, lng: 19.600275 },
    { lat: 48.458707, lng: 19.600267 },
    { lat: 48.458766, lng: 19.600221 },
    { lat: 48.458898, lng: 19.59986 },
    { lat: 48.459024, lng: 19.599829 },
    { lat: 48.4592, lng: 19.599825 },
    { lat: 48.459337, lng: 19.599571 },
    { lat: 48.459565, lng: 19.599006 },
    { lat: 48.459641, lng: 19.59892 },
    { lat: 48.459774, lng: 19.598921 },
    { lat: 48.459824, lng: 19.598793 },
    { lat: 48.45989, lng: 19.598546 },
    { lat: 48.460003, lng: 19.598169 },
    { lat: 48.460183, lng: 19.598236 },
    { lat: 48.460292, lng: 19.598157 },
    { lat: 48.460331, lng: 19.598086 },
    { lat: 48.4604802, lng: 19.5978547 },
    { lat: 48.4612258, lng: 19.5979477 },
    { lat: 48.4616641, lng: 19.5973771 },
    { lat: 48.4618102, lng: 19.5973187 },
    { lat: 48.4620456, lng: 19.5973378 },
    { lat: 48.4622911, lng: 19.596917 },
    { lat: 48.4624119, lng: 19.5969192 },
    { lat: 48.4626172, lng: 19.5970727 },
    { lat: 48.462759, lng: 19.5968469 },
    { lat: 48.462879, lng: 19.596761 },
    { lat: 48.4631662, lng: 19.5967091 },
    { lat: 48.4632539, lng: 19.596825 },
    { lat: 48.4633061, lng: 19.5970327 },
    { lat: 48.4636918, lng: 19.5970416 },
    { lat: 48.463732, lng: 19.597156 },
    { lat: 48.4638133, lng: 19.5971832 },
    { lat: 48.4639452, lng: 19.5970617 },
    { lat: 48.4640378, lng: 19.5970681 },
    { lat: 48.464216, lng: 19.597217 },
    { lat: 48.4643867, lng: 19.5968316 },
    { lat: 48.4645645, lng: 19.5968012 },
    { lat: 48.4648545, lng: 19.5965643 },
    { lat: 48.4651104, lng: 19.5964435 },
    { lat: 48.465195, lng: 19.5962719 },
    { lat: 48.4654087, lng: 19.5962882 },
    { lat: 48.4656584, lng: 19.5959791 },
    { lat: 48.4657906, lng: 19.5958917 },
    { lat: 48.4659606, lng: 19.5959363 },
    { lat: 48.4660029, lng: 19.5957001 },
    { lat: 48.4661893, lng: 19.5954629 },
    { lat: 48.4668873, lng: 19.5952046 },
    { lat: 48.4672559, lng: 19.5953282 },
    { lat: 48.4676114, lng: 19.5952163 },
    { lat: 48.4677214, lng: 19.595086 },
    { lat: 48.4680744, lng: 19.5949662 },
    { lat: 48.4686568, lng: 19.5949634 },
    { lat: 48.4693577, lng: 19.594492 },
    { lat: 48.469711, lng: 19.5943789 },
    { lat: 48.4697486, lng: 19.5943179 },
    { lat: 48.4651881, lng: 19.5899858 },
    { lat: 48.4649831, lng: 19.5895468 },
    { lat: 48.4655928, lng: 19.5894466 },
    { lat: 48.4663155, lng: 19.588668 },
    { lat: 48.4670556, lng: 19.5879919 },
    { lat: 48.4673743, lng: 19.5879378 },
    { lat: 48.4680761, lng: 19.5876385 },
    { lat: 48.469061, lng: 19.5879854 },
    { lat: 48.4691931, lng: 19.587965 },
    { lat: 48.469217, lng: 19.5878947 },
    { lat: 48.4692045, lng: 19.5878694 },
    { lat: 48.4677988, lng: 19.5848939 },
    { lat: 48.4675232, lng: 19.5841383 },
    { lat: 48.467061, lng: 19.5822128 },
    { lat: 48.4672582, lng: 19.5805317 },
    { lat: 48.4669708, lng: 19.5797668 },
    { lat: 48.4667303, lng: 19.5788183 },
    { lat: 48.4663284, lng: 19.5781628 },
    { lat: 48.4662674, lng: 19.5773248 },
    { lat: 48.4663134, lng: 19.5769451 },
    { lat: 48.4658118, lng: 19.5744287 },
    { lat: 48.4654907, lng: 19.573125 },
    { lat: 48.4648652, lng: 19.5701933 },
    { lat: 48.4643644, lng: 19.5687574 },
    { lat: 48.4640904, lng: 19.568631 },
    { lat: 48.4636686, lng: 19.5680582 },
    { lat: 48.4618937, lng: 19.5685722 },
    { lat: 48.4617341, lng: 19.5683856 },
    { lat: 48.461891, lng: 19.5669598 },
    { lat: 48.4613981, lng: 19.5671749 },
    { lat: 48.4613823, lng: 19.5670768 },
    { lat: 48.4609863, lng: 19.5671405 },
    { lat: 48.4594266, lng: 19.5670631 },
    { lat: 48.4584875, lng: 19.5670783 },
    { lat: 48.4577657, lng: 19.5672858 },
    { lat: 48.4573833, lng: 19.5671557 },
    { lat: 48.4560784, lng: 19.5671811 },
    { lat: 48.4560925, lng: 19.5670717 },
    { lat: 48.4560367, lng: 19.5670328 },
    { lat: 48.4552513, lng: 19.5675024 },
    { lat: 48.4549454, lng: 19.5675426 },
    { lat: 48.4545831, lng: 19.5675496 },
    { lat: 48.4548563, lng: 19.5673655 },
    { lat: 48.4548424, lng: 19.5671841 },
    { lat: 48.4538427, lng: 19.5680686 },
    { lat: 48.4537536, lng: 19.5678715 },
    { lat: 48.4534889, lng: 19.5680942 },
    { lat: 48.4535337, lng: 19.5682372 },
    { lat: 48.4531663, lng: 19.5683825 },
    { lat: 48.4527297, lng: 19.5687635 },
    { lat: 48.4528084, lng: 19.5689357 },
    { lat: 48.4513887, lng: 19.5703417 },
    { lat: 48.4502251, lng: 19.5712359 },
    { lat: 48.4490884, lng: 19.5719349 },
    { lat: 48.4484012, lng: 19.5722321 },
    { lat: 48.448202, lng: 19.5721891 },
    { lat: 48.4476937, lng: 19.5724586 },
    { lat: 48.4466986, lng: 19.5731251 },
    { lat: 48.4465791, lng: 19.5732249 },
    { lat: 48.442483, lng: 19.5766473 },
    { lat: 48.4416247, lng: 19.5773201 },
    { lat: 48.4415612, lng: 19.5773103 },
    { lat: 48.4414115, lng: 19.5774397 },
    { lat: 48.4414092, lng: 19.5776107 },
    { lat: 48.4411104, lng: 19.5779247 },
    { lat: 48.4402747, lng: 19.578477 },
    { lat: 48.439945, lng: 19.5788827 },
    { lat: 48.4389726, lng: 19.5795761 },
    { lat: 48.4385615, lng: 19.5799468 },
    { lat: 48.4380266, lng: 19.5801074 },
    { lat: 48.4377499, lng: 19.5807077 },
    { lat: 48.4373081, lng: 19.5813736 },
    { lat: 48.436671, lng: 19.5820774 },
    { lat: 48.4353496, lng: 19.5841595 },
    { lat: 48.435084, lng: 19.5843374 },
    { lat: 48.4349021, lng: 19.5845985 },
    { lat: 48.4342259, lng: 19.5849202 },
    { lat: 48.434187, lng: 19.5851751 },
    { lat: 48.433783, lng: 19.5851149 },
    { lat: 48.4338085, lng: 19.5852286 },
    { lat: 48.4331112, lng: 19.5850107 },
    { lat: 48.4324274, lng: 19.5849918 },
    { lat: 48.432176, lng: 19.5854109 },
    { lat: 48.4320103, lng: 19.5855056 },
    { lat: 48.4318893, lng: 19.5855 },
    { lat: 48.4317083, lng: 19.5852841 },
    { lat: 48.4312427, lng: 19.5851817 },
    { lat: 48.4310705, lng: 19.5852389 },
    { lat: 48.43039, lng: 19.5850692 },
    { lat: 48.4303555, lng: 19.5848304 },
    { lat: 48.4296763, lng: 19.5844154 },
    { lat: 48.429208, lng: 19.5836632 },
    { lat: 48.4294514, lng: 19.5829952 },
    { lat: 48.4289935, lng: 19.5822714 },
    { lat: 48.4285651, lng: 19.5818019 },
    { lat: 48.4282312, lng: 19.5815691 },
    { lat: 48.4280411, lng: 19.5812574 },
    { lat: 48.4279339, lng: 19.5812794 },
    { lat: 48.4278009, lng: 19.581081 },
    { lat: 48.4276967, lng: 19.5807955 },
    { lat: 48.4275567, lng: 19.5806208 },
    { lat: 48.4275801, lng: 19.5805134 },
    { lat: 48.4272518, lng: 19.5801385 },
    { lat: 48.4271631, lng: 19.5799447 },
    { lat: 48.4272155, lng: 19.5797644 },
    { lat: 48.4271271, lng: 19.5796011 },
    { lat: 48.4270347, lng: 19.5796046 },
    { lat: 48.427029, lng: 19.5794544 },
    { lat: 48.4268764, lng: 19.5793187 },
    { lat: 48.4265853, lng: 19.5787675 },
    { lat: 48.4262914, lng: 19.5785016 },
    { lat: 48.4260867, lng: 19.5781924 },
    { lat: 48.4258676, lng: 19.5776141 },
    { lat: 48.4252717, lng: 19.5769309 },
    { lat: 48.4250344, lng: 19.5759297 },
    { lat: 48.4240642, lng: 19.5749457 },
    { lat: 48.4231786, lng: 19.5729337 },
    { lat: 48.4231538, lng: 19.5729533 },
    { lat: 48.4229719, lng: 19.5726007 },
    { lat: 48.4228936, lng: 19.5722221 },
    { lat: 48.4227379, lng: 19.5720913 },
    { lat: 48.4225989, lng: 19.571417 },
    { lat: 48.42279, lng: 19.5709921 },
    { lat: 48.4225234, lng: 19.5704606 },
    { lat: 48.4223845, lng: 19.5697157 },
    { lat: 48.4221262, lng: 19.5689967 },
    { lat: 48.4221482, lng: 19.5688114 },
    { lat: 48.4220966, lng: 19.5686496 },
    { lat: 48.4218705, lng: 19.5682852 },
    { lat: 48.4217527, lng: 19.5677553 },
    { lat: 48.4215513, lng: 19.5674927 },
    { lat: 48.42154, lng: 19.5671494 },
    { lat: 48.421294, lng: 19.566476 },
    { lat: 48.4210731, lng: 19.5662565 },
    { lat: 48.4210005, lng: 19.5658539 },
    { lat: 48.4207817, lng: 19.5655051 },
    { lat: 48.4208215, lng: 19.5651171 },
    { lat: 48.4207127, lng: 19.5646647 },
    { lat: 48.4198343, lng: 19.5633228 },
    { lat: 48.4193213, lng: 19.5629236 },
    { lat: 48.4188564, lng: 19.5623939 },
    { lat: 48.4186674, lng: 19.5618636 },
    { lat: 48.4183319, lng: 19.5615762 },
    { lat: 48.4178292, lng: 19.5615974 },
    { lat: 48.4175754, lng: 19.5614697 },
    { lat: 48.4173543, lng: 19.5614369 },
    { lat: 48.4173055, lng: 19.5611153 },
    { lat: 48.4172003, lng: 19.5608864 },
    { lat: 48.4163834, lng: 19.5603229 },
    { lat: 48.4161176, lng: 19.5599794 },
    { lat: 48.415638, lng: 19.5597428 },
    { lat: 48.4154111, lng: 19.5594662 },
    { lat: 48.4152175, lng: 19.5590035 },
    { lat: 48.4148573, lng: 19.5586226 },
    { lat: 48.4145035, lng: 19.5583531 },
    { lat: 48.4142753, lng: 19.5580951 },
    { lat: 48.4138783, lng: 19.5579905 },
    { lat: 48.4137696, lng: 19.5577839 },
    { lat: 48.413463, lng: 19.5578019 },
    { lat: 48.4131246, lng: 19.5573286 },
    { lat: 48.4130327, lng: 19.5572917 },
    { lat: 48.4128337, lng: 19.5569321 },
    { lat: 48.4126961, lng: 19.5565388 },
    { lat: 48.4114403, lng: 19.5557471 },
    { lat: 48.4109987, lng: 19.5556233 },
    { lat: 48.410474, lng: 19.5556334 },
    { lat: 48.4095173, lng: 19.5555134 },
    { lat: 48.4089937, lng: 19.5553268 },
    { lat: 48.4082184, lng: 19.5553778 },
    { lat: 48.4074916, lng: 19.5552027 },
    { lat: 48.4061082, lng: 19.5554114 },
    { lat: 48.4048508, lng: 19.5552183 },
    { lat: 48.4043405, lng: 19.5548318 },
    { lat: 48.4045188, lng: 19.5558918 },
    { lat: 48.404612, lng: 19.5567163 },
    { lat: 48.4044181, lng: 19.5581344 },
    { lat: 48.4050868, lng: 19.558807 },
    { lat: 48.4053007, lng: 19.5589512 },
    { lat: 48.4054234, lng: 19.5591683 },
    { lat: 48.4066437, lng: 19.5598055 },
    { lat: 48.4068996, lng: 19.5601539 },
    { lat: 48.4071695, lng: 19.5603845 },
    { lat: 48.4074407, lng: 19.5607217 },
    { lat: 48.407807, lng: 19.5609636 },
    { lat: 48.4082384, lng: 19.5609845 },
    { lat: 48.4085709, lng: 19.5608405 },
    { lat: 48.4089018, lng: 19.5610283 },
    { lat: 48.4095964, lng: 19.5627784 },
    { lat: 48.4101232, lng: 19.5633807 },
    { lat: 48.4103839, lng: 19.563879 },
    { lat: 48.4112602, lng: 19.5645598 },
    { lat: 48.4122852, lng: 19.5656809 },
    { lat: 48.4129297, lng: 19.5659754 },
    { lat: 48.4135423, lng: 19.5661103 },
    { lat: 48.4143395, lng: 19.5664921 },
    { lat: 48.4147646, lng: 19.567038 },
    { lat: 48.4148537, lng: 19.5672363 },
    { lat: 48.4148938, lng: 19.5679604 },
    { lat: 48.4148516, lng: 19.5681744 },
    { lat: 48.4150679, lng: 19.5692754 },
    { lat: 48.4154923, lng: 19.57003 },
    { lat: 48.4162075, lng: 19.5706893 },
    { lat: 48.4165336, lng: 19.5717042 },
    { lat: 48.4164619, lng: 19.5731915 },
    { lat: 48.4161807, lng: 19.5739655 },
    { lat: 48.4162304, lng: 19.5746585 },
    { lat: 48.4163267, lng: 19.5749883 },
    { lat: 48.4163867, lng: 19.5764936 },
    { lat: 48.4160958, lng: 19.5780692 },
    { lat: 48.4159853, lng: 19.5782917 },
    { lat: 48.415928, lng: 19.5785395 },
    { lat: 48.4158205, lng: 19.5786794 },
    { lat: 48.4156718, lng: 19.5795163 },
    { lat: 48.4157371, lng: 19.5797096 },
    { lat: 48.4155875, lng: 19.5805083 },
    { lat: 48.4156851, lng: 19.5806695 },
    { lat: 48.4156562, lng: 19.5809456 },
    { lat: 48.415713, lng: 19.5824115 },
    { lat: 48.4159588, lng: 19.5838476 },
    { lat: 48.4158786, lng: 19.5846086 },
    { lat: 48.4157497, lng: 19.5846198 },
    { lat: 48.4155207, lng: 19.5848903 },
    { lat: 48.4155442, lng: 19.5850326 },
    { lat: 48.4154355, lng: 19.5857387 },
    { lat: 48.4153079, lng: 19.5861232 },
    { lat: 48.4152869, lng: 19.5863826 },
    { lat: 48.4153425, lng: 19.5869798 },
    { lat: 48.4156326, lng: 19.5881039 },
    { lat: 48.4154966, lng: 19.5881611 },
    { lat: 48.4153667, lng: 19.5891638 },
    { lat: 48.4153979, lng: 19.5896977 },
    { lat: 48.41555, lng: 19.5901953 },
    { lat: 48.4156591, lng: 19.5902628 },
    { lat: 48.4156913, lng: 19.5903999 },
    { lat: 48.4156515, lng: 19.5906144 },
    { lat: 48.4156259, lng: 19.5905489 },
    { lat: 48.4155608, lng: 19.5907724 },
    { lat: 48.4155864, lng: 19.5909666 },
    { lat: 48.4152793, lng: 19.591077 },
    { lat: 48.4152812, lng: 19.5911917 },
    { lat: 48.4151914, lng: 19.5912988 },
    { lat: 48.4152, lng: 19.5914352 },
    { lat: 48.4151225, lng: 19.5916156 },
    { lat: 48.4146646, lng: 19.5919781 },
    { lat: 48.4150129, lng: 19.5921743 },
    { lat: 48.4153657, lng: 19.5923511 },
    { lat: 48.4146467, lng: 19.5929465 },
    { lat: 48.4145158, lng: 19.5928922 },
    { lat: 48.4142767, lng: 19.5930821 },
    { lat: 48.4139694, lng: 19.5932127 },
    { lat: 48.4139464, lng: 19.5933261 },
    { lat: 48.4134883, lng: 19.5938337 },
    { lat: 48.4130826, lng: 19.5939308 },
    { lat: 48.4129888, lng: 19.5940011 },
    { lat: 48.4128272, lng: 19.5943096 },
    { lat: 48.412655, lng: 19.5944125 },
    { lat: 48.4126316, lng: 19.594693 },
    { lat: 48.4122799, lng: 19.5955038 },
    { lat: 48.4122268, lng: 19.5957987 },
    { lat: 48.4123661, lng: 19.5958394 },
    { lat: 48.4125756, lng: 19.5963541 },
    { lat: 48.4134448, lng: 19.5979445 },
    { lat: 48.4127896, lng: 19.5986029 },
    { lat: 48.4115189, lng: 19.6000476 },
    { lat: 48.4119051, lng: 19.6007304 },
    { lat: 48.411431, lng: 19.6013832 },
    { lat: 48.4115994, lng: 19.6016376 },
    { lat: 48.4102728, lng: 19.6033912 },
    { lat: 48.4107259, lng: 19.6042094 },
    { lat: 48.4099753, lng: 19.6054204 },
    { lat: 48.4087948, lng: 19.6071624 },
    { lat: 48.4075287, lng: 19.6098501 },
    { lat: 48.4064759, lng: 19.6118264 },
    { lat: 48.4060254, lng: 19.6130185 },
    { lat: 48.4058643, lng: 19.6136386 },
    { lat: 48.4066642, lng: 19.6143344 },
    { lat: 48.4059244, lng: 19.6154348 },
    { lat: 48.4061421, lng: 19.6160132 },
    { lat: 48.4064388, lng: 19.6170329 },
    { lat: 48.407307, lng: 19.6187591 },
    { lat: 48.4072339, lng: 19.6188602 },
  ],
  Pleš: [
    { lat: 48.226971, lng: 19.7783533 },
    { lat: 48.2294284, lng: 19.7796598 },
    { lat: 48.2296765, lng: 19.7797921 },
    { lat: 48.2299729, lng: 19.7792268 },
    { lat: 48.2324571, lng: 19.7783072 },
    { lat: 48.2322639, lng: 19.7761763 },
    { lat: 48.2326826, lng: 19.7737432 },
    { lat: 48.233089, lng: 19.772793 },
    { lat: 48.2334371, lng: 19.7721814 },
    { lat: 48.2345313, lng: 19.7718031 },
    { lat: 48.2357824, lng: 19.7718145 },
    { lat: 48.2362929, lng: 19.7718922 },
    { lat: 48.2372737, lng: 19.7716244 },
    { lat: 48.2375913, lng: 19.7713901 },
    { lat: 48.238528, lng: 19.7695938 },
    { lat: 48.2413453, lng: 19.7710542 },
    { lat: 48.2420631, lng: 19.7697591 },
    { lat: 48.2424484, lng: 19.7698114 },
    { lat: 48.243219, lng: 19.7702645 },
    { lat: 48.2434826, lng: 19.7703434 },
    { lat: 48.243918, lng: 19.7707113 },
    { lat: 48.2440973, lng: 19.7707883 },
    { lat: 48.2442723, lng: 19.7710519 },
    { lat: 48.2444462, lng: 19.7711401 },
    { lat: 48.2447424, lng: 19.7715699 },
    { lat: 48.2452408, lng: 19.7712437 },
    { lat: 48.2455888, lng: 19.7679541 },
    { lat: 48.2448012, lng: 19.76676 },
    { lat: 48.2445015, lng: 19.7653589 },
    { lat: 48.2439333, lng: 19.7638989 },
    { lat: 48.2436367, lng: 19.7599745 },
    { lat: 48.2432994, lng: 19.75892 },
    { lat: 48.2435428, lng: 19.7581582 },
    { lat: 48.2442674, lng: 19.7574026 },
    { lat: 48.2446097, lng: 19.7569638 },
    { lat: 48.2458929, lng: 19.754621 },
    { lat: 48.247261, lng: 19.7537392 },
    { lat: 48.2477734, lng: 19.7529561 },
    { lat: 48.2479708, lng: 19.7520609 },
    { lat: 48.2478916, lng: 19.7504024 },
    { lat: 48.2479425, lng: 19.7497451 },
    { lat: 48.2482005, lng: 19.7489622 },
    { lat: 48.2483782, lng: 19.7479395 },
    { lat: 48.248228, lng: 19.7464348 },
    { lat: 48.2482665, lng: 19.744054 },
    { lat: 48.2482653, lng: 19.7440541 },
    { lat: 48.2475923, lng: 19.7435622 },
    { lat: 48.2471012, lng: 19.7426019 },
    { lat: 48.2469081, lng: 19.7412995 },
    { lat: 48.2466457, lng: 19.7400605 },
    { lat: 48.2466102, lng: 19.7389866 },
    { lat: 48.2471059, lng: 19.7374408 },
    { lat: 48.2473795, lng: 19.7371589 },
    { lat: 48.2479772, lng: 19.7368165 },
    { lat: 48.2475566, lng: 19.7362731 },
    { lat: 48.2467427, lng: 19.7359852 },
    { lat: 48.2462984, lng: 19.7356994 },
    { lat: 48.2460811, lng: 19.7354761 },
    { lat: 48.2459102, lng: 19.734421 },
    { lat: 48.2448948, lng: 19.7341155 },
    { lat: 48.2441901, lng: 19.7336231 },
    { lat: 48.243621, lng: 19.7334685 },
    { lat: 48.2419904, lng: 19.7335889 },
    { lat: 48.2417467, lng: 19.7333029 },
    { lat: 48.2404899, lng: 19.7335725 },
    { lat: 48.2400926, lng: 19.7337589 },
    { lat: 48.2394324, lng: 19.7341867 },
    { lat: 48.2393619, lng: 19.734503 },
    { lat: 48.2388611, lng: 19.7346919 },
    { lat: 48.2380509, lng: 19.7347688 },
    { lat: 48.2379111, lng: 19.734335 },
    { lat: 48.2378753, lng: 19.733632 },
    { lat: 48.2379025, lng: 19.7334572 },
    { lat: 48.238102, lng: 19.7333627 },
    { lat: 48.2382683, lng: 19.7331464 },
    { lat: 48.2382803, lng: 19.7328586 },
    { lat: 48.2383507, lng: 19.7326222 },
    { lat: 48.2382997, lng: 19.7325113 },
    { lat: 48.2383584, lng: 19.7322118 },
    { lat: 48.2384912, lng: 19.7319059 },
    { lat: 48.2386376, lng: 19.7312179 },
    { lat: 48.2387489, lng: 19.7310131 },
    { lat: 48.2388927, lng: 19.7304742 },
    { lat: 48.2392909, lng: 19.7283848 },
    { lat: 48.2394183, lng: 19.7267466 },
    { lat: 48.2394342, lng: 19.7254828 },
    { lat: 48.2395437, lng: 19.7253853 },
    { lat: 48.2394528, lng: 19.725089 },
    { lat: 48.2394129, lng: 19.7247303 },
    { lat: 48.2395046, lng: 19.7243557 },
    { lat: 48.2397409, lng: 19.7240221 },
    { lat: 48.2397677, lng: 19.7238911 },
    { lat: 48.239687, lng: 19.7233216 },
    { lat: 48.239781, lng: 19.7226499 },
    { lat: 48.2397168, lng: 19.7223502 },
    { lat: 48.2397728, lng: 19.7222647 },
    { lat: 48.2397587, lng: 19.7219243 },
    { lat: 48.2398198, lng: 19.7218528 },
    { lat: 48.2398761, lng: 19.7216486 },
    { lat: 48.2398696, lng: 19.7214566 },
    { lat: 48.2399179, lng: 19.7213754 },
    { lat: 48.2399042, lng: 19.7210145 },
    { lat: 48.2399449, lng: 19.7207686 },
    { lat: 48.2398812, lng: 19.7205534 },
    { lat: 48.2398558, lng: 19.7202866 },
    { lat: 48.2398985, lng: 19.7202116 },
    { lat: 48.2397805, lng: 19.7196098 },
    { lat: 48.2398231, lng: 19.7194798 },
    { lat: 48.2399858, lng: 19.7193627 },
    { lat: 48.2401115, lng: 19.7191225 },
    { lat: 48.2400649, lng: 19.7189402 },
    { lat: 48.2400693, lng: 19.7184831 },
    { lat: 48.2400076, lng: 19.7183405 },
    { lat: 48.2400233, lng: 19.7182102 },
    { lat: 48.2390759, lng: 19.7146836 },
    { lat: 48.2388872, lng: 19.7147457 },
    { lat: 48.2387089, lng: 19.7147183 },
    { lat: 48.2383487, lng: 19.7144999 },
    { lat: 48.2384156, lng: 19.7141051 },
    { lat: 48.2383313, lng: 19.7138566 },
    { lat: 48.2379394, lng: 19.7143164 },
    { lat: 48.2376677, lng: 19.7147667 },
    { lat: 48.2373127, lng: 19.715646 },
    { lat: 48.2374419, lng: 19.7165718 },
    { lat: 48.2379801, lng: 19.7181487 },
    { lat: 48.2380728, lng: 19.7188381 },
    { lat: 48.2380473, lng: 19.7206157 },
    { lat: 48.2382081, lng: 19.7210131 },
    { lat: 48.2383522, lng: 19.7219926 },
    { lat: 48.2383073, lng: 19.7225516 },
    { lat: 48.2381729, lng: 19.722922 },
    { lat: 48.2377418, lng: 19.7236897 },
    { lat: 48.2376314, lng: 19.7239938 },
    { lat: 48.2375798, lng: 19.7243029 },
    { lat: 48.2375319, lng: 19.7254514 },
    { lat: 48.2365333, lng: 19.7238233 },
    { lat: 48.2361363, lng: 19.7229073 },
    { lat: 48.2357247, lng: 19.7229997 },
    { lat: 48.2344751, lng: 19.7236336 },
    { lat: 48.2334229, lng: 19.7236625 },
    { lat: 48.2332112, lng: 19.7240664 },
    { lat: 48.2326982, lng: 19.7246916 },
    { lat: 48.232202, lng: 19.7250392 },
    { lat: 48.231365, lng: 19.725419 },
    { lat: 48.2303104, lng: 19.7255968 },
    { lat: 48.2299302, lng: 19.7257652 },
    { lat: 48.229268, lng: 19.7262749 },
    { lat: 48.2290714, lng: 19.7265677 },
    { lat: 48.2281213, lng: 19.7272659 },
    { lat: 48.2277231, lng: 19.7277866 },
    { lat: 48.2271448, lng: 19.728208 },
    { lat: 48.2266301, lng: 19.728823 },
    { lat: 48.2256705, lng: 19.72878 },
    { lat: 48.2245684, lng: 19.727918 },
    { lat: 48.2238642, lng: 19.7278683 },
    { lat: 48.2237885, lng: 19.727863 },
    { lat: 48.2234646, lng: 19.7280763 },
    { lat: 48.22267, lng: 19.7285306 },
    { lat: 48.2225202, lng: 19.7287073 },
    { lat: 48.2221036, lng: 19.7294669 },
    { lat: 48.2216895, lng: 19.7298337 },
    { lat: 48.2214852, lng: 19.7301064 },
    { lat: 48.2211202, lng: 19.7303629 },
    { lat: 48.2199276, lng: 19.7305519 },
    { lat: 48.2196918, lng: 19.7304953 },
    { lat: 48.2192239, lng: 19.730083 },
    { lat: 48.2188897, lng: 19.7296094 },
    { lat: 48.2186967, lng: 19.7295562 },
    { lat: 48.2178716, lng: 19.7298478 },
    { lat: 48.2171829, lng: 19.7304274 },
    { lat: 48.2167796, lng: 19.73068 },
    { lat: 48.2158591, lng: 19.7328792 },
    { lat: 48.2154075, lng: 19.7340746 },
    { lat: 48.2153317, lng: 19.7347251 },
    { lat: 48.215354, lng: 19.735201 },
    { lat: 48.215572, lng: 19.735567 },
    { lat: 48.215725, lng: 19.736161 },
    { lat: 48.215721, lng: 19.736498 },
    { lat: 48.21568, lng: 19.736649 },
    { lat: 48.215684, lng: 19.736916 },
    { lat: 48.215847, lng: 19.737399 },
    { lat: 48.215935, lng: 19.737902 },
    { lat: 48.216077, lng: 19.738619 },
    { lat: 48.216222, lng: 19.739371 },
    { lat: 48.216273, lng: 19.73989 },
    { lat: 48.216233, lng: 19.740278 },
    { lat: 48.216271, lng: 19.740505 },
    { lat: 48.216133, lng: 19.741161 },
    { lat: 48.21611, lng: 19.741622 },
    { lat: 48.216119, lng: 19.741998 },
    { lat: 48.216145, lng: 19.742547 },
    { lat: 48.216316, lng: 19.743172 },
    { lat: 48.216407, lng: 19.743746 },
    { lat: 48.216523, lng: 19.744512 },
    { lat: 48.216499, lng: 19.745169 },
    { lat: 48.216474, lng: 19.745641 },
    { lat: 48.216512, lng: 19.74618 },
    { lat: 48.216495, lng: 19.746181 },
    { lat: 48.216396, lng: 19.74673 },
    { lat: 48.216044, lng: 19.747473 },
    { lat: 48.215781, lng: 19.747773 },
    { lat: 48.215747, lng: 19.747884 },
    { lat: 48.215584, lng: 19.748386 },
    { lat: 48.215535, lng: 19.748965 },
    { lat: 48.215508, lng: 19.749556 },
    { lat: 48.21517, lng: 19.750917 },
    { lat: 48.214888, lng: 19.751185 },
    { lat: 48.214989, lng: 19.751502 },
    { lat: 48.214997, lng: 19.751527 },
    { lat: 48.2151351, lng: 19.7514814 },
    { lat: 48.2157782, lng: 19.7514747 },
    { lat: 48.2170658, lng: 19.7520046 },
    { lat: 48.21747, lng: 19.7519732 },
    { lat: 48.2180669, lng: 19.7517885 },
    { lat: 48.2195364, lng: 19.7516445 },
    { lat: 48.2195772, lng: 19.7518235 },
    { lat: 48.220644, lng: 19.7523036 },
    { lat: 48.2209785, lng: 19.7527063 },
    { lat: 48.221231, lng: 19.7531408 },
    { lat: 48.221383, lng: 19.7537236 },
    { lat: 48.2214452, lng: 19.7551264 },
    { lat: 48.2214363, lng: 19.755936 },
    { lat: 48.2216066, lng: 19.7567328 },
    { lat: 48.2217003, lng: 19.7575979 },
    { lat: 48.2219973, lng: 19.75812 },
    { lat: 48.2221138, lng: 19.7602428 },
    { lat: 48.2224538, lng: 19.7609467 },
    { lat: 48.2226778, lng: 19.7617078 },
    { lat: 48.2229132, lng: 19.7628479 },
    { lat: 48.2226659, lng: 19.7637512 },
    { lat: 48.2225574, lng: 19.7644046 },
    { lat: 48.2225713, lng: 19.7654063 },
    { lat: 48.2234045, lng: 19.7657752 },
    { lat: 48.2241073, lng: 19.766179 },
    { lat: 48.2247883, lng: 19.7663401 },
    { lat: 48.2250235, lng: 19.7662836 },
    { lat: 48.225376, lng: 19.766331 },
    { lat: 48.2265118, lng: 19.7667678 },
    { lat: 48.2270802, lng: 19.7681291 },
    { lat: 48.2275294, lng: 19.7688581 },
    { lat: 48.2277682, lng: 19.7694955 },
    { lat: 48.228614, lng: 19.7701979 },
    { lat: 48.2290706, lng: 19.7704379 },
    { lat: 48.2298881, lng: 19.7712894 },
    { lat: 48.2295173, lng: 19.7724894 },
    { lat: 48.2293965, lng: 19.773095 },
    { lat: 48.2291412, lng: 19.7739199 },
    { lat: 48.2286878, lng: 19.7751044 },
    { lat: 48.2283993, lng: 19.7763301 },
    { lat: 48.2279342, lng: 19.7767189 },
    { lat: 48.2275959, lng: 19.7773506 },
    { lat: 48.2271161, lng: 19.7779641 },
    { lat: 48.226971, lng: 19.7783533 },
  ],
  Točnica: [
    { lat: 48.3961936, lng: 19.6260226 },
    { lat: 48.3958803, lng: 19.6262947 },
    { lat: 48.395273, lng: 19.6268205 },
    { lat: 48.3939857, lng: 19.628276 },
    { lat: 48.3935519, lng: 19.6290451 },
    { lat: 48.3934493, lng: 19.6295175 },
    { lat: 48.3934991, lng: 19.6302673 },
    { lat: 48.3934589, lng: 19.6305987 },
    { lat: 48.393297, lng: 19.6312059 },
    { lat: 48.3931626, lng: 19.6314703 },
    { lat: 48.3927563, lng: 19.6318316 },
    { lat: 48.3921823, lng: 19.6326404 },
    { lat: 48.3918643, lng: 19.6328601 },
    { lat: 48.3895761, lng: 19.6340151 },
    { lat: 48.3885321, lng: 19.6344971 },
    { lat: 48.3883363, lng: 19.6344816 },
    { lat: 48.3872619, lng: 19.6350185 },
    { lat: 48.3869442, lng: 19.6354646 },
    { lat: 48.3857715, lng: 19.6362897 },
    { lat: 48.3843651, lng: 19.6380071 },
    { lat: 48.3835923, lng: 19.6393837 },
    { lat: 48.3829273, lng: 19.640997 },
    { lat: 48.3824753, lng: 19.6422638 },
    { lat: 48.3823345, lng: 19.6429411 },
    { lat: 48.382607, lng: 19.643337 },
    { lat: 48.382649, lng: 19.643337 },
    { lat: 48.382688, lng: 19.643327 },
    { lat: 48.382701, lng: 19.643323 },
    { lat: 48.382773, lng: 19.643311 },
    { lat: 48.382915, lng: 19.643466 },
    { lat: 48.384073, lng: 19.644757 },
    { lat: 48.384067, lng: 19.644799 },
    { lat: 48.38415, lng: 19.644872 },
    { lat: 48.384448, lng: 19.645364 },
    { lat: 48.384894, lng: 19.64612 },
    { lat: 48.385166, lng: 19.646001 },
    { lat: 48.385208, lng: 19.646013 },
    { lat: 48.385668, lng: 19.646237 },
    { lat: 48.385697, lng: 19.646238 },
    { lat: 48.386065, lng: 19.646222 },
    { lat: 48.386107, lng: 19.646232 },
    { lat: 48.386474, lng: 19.646361 },
    { lat: 48.386524, lng: 19.646211 },
    { lat: 48.386873, lng: 19.646158 },
    { lat: 48.387046, lng: 19.646352 },
    { lat: 48.387324, lng: 19.646088 },
    { lat: 48.387585, lng: 19.646096 },
    { lat: 48.387644, lng: 19.64612 },
    { lat: 48.387774, lng: 19.646216 },
    { lat: 48.388314, lng: 19.64599 },
    { lat: 48.388797, lng: 19.646334 },
    { lat: 48.388912, lng: 19.646409 },
    { lat: 48.389512, lng: 19.646836 },
    { lat: 48.38963, lng: 19.6469 },
    { lat: 48.390146, lng: 19.647018 },
    { lat: 48.390221, lng: 19.647029 },
    { lat: 48.39119, lng: 19.646981 },
    { lat: 48.391533, lng: 19.647024 },
    { lat: 48.391553, lng: 19.646986 },
    { lat: 48.391961, lng: 19.646232 },
    { lat: 48.392112, lng: 19.645956 },
    { lat: 48.392477, lng: 19.645257 },
    { lat: 48.392511, lng: 19.645191 },
    { lat: 48.393396, lng: 19.645076 },
    { lat: 48.393725, lng: 19.644875 },
    { lat: 48.394067, lng: 19.644838 },
    { lat: 48.395157, lng: 19.644556 },
    { lat: 48.39612, lng: 19.644484 },
    { lat: 48.396217, lng: 19.644513 },
    { lat: 48.396356, lng: 19.644587 },
    { lat: 48.396829, lng: 19.644802 },
    { lat: 48.397752, lng: 19.645568 },
    { lat: 48.398757, lng: 19.646181 },
    { lat: 48.399279, lng: 19.646387 },
    { lat: 48.40025, lng: 19.647654 },
    { lat: 48.401497, lng: 19.648205 },
    { lat: 48.402079, lng: 19.648859 },
    { lat: 48.402294, lng: 19.64929 },
    { lat: 48.402425, lng: 19.649954 },
    { lat: 48.40264, lng: 19.650322 },
    { lat: 48.403487, lng: 19.651297 },
    { lat: 48.403515, lng: 19.651312 },
    { lat: 48.404121, lng: 19.651633 },
    { lat: 48.405048, lng: 19.652095 },
    { lat: 48.405606, lng: 19.652402 },
    { lat: 48.406406, lng: 19.652956 },
    { lat: 48.407356, lng: 19.653523 },
    { lat: 48.407562, lng: 19.65448 },
    { lat: 48.407819, lng: 19.654906 },
    { lat: 48.407419, lng: 19.655861 },
    { lat: 48.407211, lng: 19.656902 },
    { lat: 48.407222, lng: 19.657339 },
    { lat: 48.407219, lng: 19.657372 },
    { lat: 48.407114, lng: 19.65796 },
    { lat: 48.407112, lng: 19.65797 },
    { lat: 48.407062, lng: 19.658556 },
    { lat: 48.407016, lng: 19.659952 },
    { lat: 48.406955, lng: 19.66086 },
    { lat: 48.406775, lng: 19.661316 },
    { lat: 48.406595, lng: 19.661936 },
    { lat: 48.406386, lng: 19.662898 },
    { lat: 48.406096, lng: 19.663787 },
    { lat: 48.405891, lng: 19.664548 },
    { lat: 48.405584, lng: 19.665384 },
    { lat: 48.40501, lng: 19.665393 },
    { lat: 48.404611, lng: 19.665471 },
    { lat: 48.403466, lng: 19.666267 },
    { lat: 48.403236, lng: 19.666998 },
    { lat: 48.402943, lng: 19.667547 },
    { lat: 48.402475, lng: 19.66804 },
    { lat: 48.402378, lng: 19.668556 },
    { lat: 48.401918, lng: 19.669271 },
    { lat: 48.401912, lng: 19.66928 },
    { lat: 48.401435, lng: 19.669987 },
    { lat: 48.401182, lng: 19.670565 },
    { lat: 48.401177, lng: 19.670572 },
    { lat: 48.400664, lng: 19.671404 },
    { lat: 48.399794, lng: 19.671618 },
    { lat: 48.400642, lng: 19.673043 },
    { lat: 48.401511, lng: 19.674496 },
    { lat: 48.401533, lng: 19.674531 },
    { lat: 48.400879, lng: 19.67522 },
    { lat: 48.400903, lng: 19.67525 },
    { lat: 48.401838, lng: 19.676378 },
    { lat: 48.402606, lng: 19.677307 },
    { lat: 48.402616, lng: 19.677295 },
    { lat: 48.402824, lng: 19.677729 },
    { lat: 48.402832, lng: 19.67773 },
    { lat: 48.402841, lng: 19.67773 },
    { lat: 48.40322, lng: 19.677659 },
    { lat: 48.40337, lng: 19.677526 },
    { lat: 48.403542, lng: 19.677559 },
    { lat: 48.40387, lng: 19.677427 },
    { lat: 48.403917, lng: 19.677577 },
    { lat: 48.404049, lng: 19.677593 },
    { lat: 48.404192, lng: 19.67774 },
    { lat: 48.404633, lng: 19.677716 },
    { lat: 48.405101, lng: 19.678104 },
    { lat: 48.405456, lng: 19.678257 },
    { lat: 48.405696, lng: 19.679202 },
    { lat: 48.405962, lng: 19.679367 },
    { lat: 48.406235, lng: 19.679368 },
    { lat: 48.406489, lng: 19.67922 },
    { lat: 48.407219, lng: 19.678701 },
    { lat: 48.407491, lng: 19.678837 },
    { lat: 48.407696, lng: 19.678702 },
    { lat: 48.408014, lng: 19.678623 },
    { lat: 48.408205, lng: 19.678665 },
    { lat: 48.40872, lng: 19.678617 },
    { lat: 48.409285, lng: 19.678712 },
    { lat: 48.40949, lng: 19.678733 },
    { lat: 48.40967, lng: 19.678674 },
    { lat: 48.409988, lng: 19.678466 },
    { lat: 48.41008, lng: 19.678389 },
    { lat: 48.410419, lng: 19.678103 },
    { lat: 48.410855, lng: 19.677548 },
    { lat: 48.411037, lng: 19.677327 },
    { lat: 48.411219, lng: 19.677108 },
    { lat: 48.411406, lng: 19.676881 },
    { lat: 48.411971, lng: 19.675808 },
    { lat: 48.412479, lng: 19.675009 },
    { lat: 48.412854, lng: 19.674848 },
    { lat: 48.413319, lng: 19.674378 },
    { lat: 48.414141, lng: 19.673958 },
    { lat: 48.414407, lng: 19.673416 },
    { lat: 48.414769, lng: 19.673204 },
    { lat: 48.415291, lng: 19.673037 },
    { lat: 48.415771, lng: 19.67272 },
    { lat: 48.416889, lng: 19.671877 },
    { lat: 48.417182, lng: 19.671495 },
    { lat: 48.418389, lng: 19.669733 },
    { lat: 48.418993, lng: 19.668346 },
    { lat: 48.419459, lng: 19.667224 },
    { lat: 48.419881, lng: 19.666135 },
    { lat: 48.419972, lng: 19.665345 },
    { lat: 48.420076, lng: 19.6649 },
    { lat: 48.420428, lng: 19.663997 },
    { lat: 48.421038, lng: 19.663129 },
    { lat: 48.421797, lng: 19.662172 },
    { lat: 48.422413, lng: 19.661734 },
    { lat: 48.422784, lng: 19.661026 },
    { lat: 48.423432, lng: 19.659535 },
    { lat: 48.423788, lng: 19.657857 },
    { lat: 48.423688, lng: 19.656244 },
    { lat: 48.424139, lng: 19.655058 },
    { lat: 48.424478, lng: 19.654134 },
    { lat: 48.4247477, lng: 19.6532939 },
    { lat: 48.424791, lng: 19.653159 },
    { lat: 48.425131, lng: 19.652546 },
    { lat: 48.425457, lng: 19.652022 },
    { lat: 48.4255996, lng: 19.6516685 },
    { lat: 48.42571, lng: 19.651459 },
    { lat: 48.426069, lng: 19.651209 },
    { lat: 48.426249, lng: 19.650718 },
    { lat: 48.426439, lng: 19.650433 },
    { lat: 48.42679, lng: 19.650017 },
    { lat: 48.42701, lng: 19.649864 },
    { lat: 48.427252, lng: 19.649741 },
    { lat: 48.427631, lng: 19.648839 },
    { lat: 48.427645, lng: 19.648855 },
    { lat: 48.428377, lng: 19.647835 },
    { lat: 48.428373, lng: 19.64781 },
    { lat: 48.428703, lng: 19.647177 },
    { lat: 48.428997, lng: 19.646332 },
    { lat: 48.429235, lng: 19.645838 },
    { lat: 48.429628, lng: 19.644936 },
    { lat: 48.429757, lng: 19.644026 },
    { lat: 48.429956, lng: 19.643592 },
    { lat: 48.430421, lng: 19.642566 },
    { lat: 48.430812, lng: 19.6415 },
    { lat: 48.4308337, lng: 19.6412144 },
    { lat: 48.4308556, lng: 19.6409403 },
    { lat: 48.430877, lng: 19.640679 },
    { lat: 48.43093, lng: 19.639981 },
    { lat: 48.430944, lng: 19.639824 },
    { lat: 48.431003, lng: 19.639159 },
    { lat: 48.431278, lng: 19.638654 },
    { lat: 48.432517, lng: 19.637041 },
    { lat: 48.43274, lng: 19.636472 },
    { lat: 48.433121, lng: 19.635093 },
    { lat: 48.432912, lng: 19.634306 },
    { lat: 48.433028, lng: 19.63371 },
    { lat: 48.433013, lng: 19.63371 },
    { lat: 48.433027, lng: 19.632811 },
    { lat: 48.432903, lng: 19.632393 },
    { lat: 48.432672, lng: 19.631833 },
    { lat: 48.431656, lng: 19.631236 },
    { lat: 48.4312697, lng: 19.6316227 },
    { lat: 48.43066, lng: 19.6316747 },
    { lat: 48.4292078, lng: 19.6312324 },
    { lat: 48.4278952, lng: 19.6311122 },
    { lat: 48.4270264, lng: 19.629988 },
    { lat: 48.4265825, lng: 19.6285234 },
    { lat: 48.4249859, lng: 19.6267588 },
    { lat: 48.4240009, lng: 19.6260837 },
    { lat: 48.4225759, lng: 19.6255476 },
    { lat: 48.4217031, lng: 19.6257334 },
    { lat: 48.4213369, lng: 19.6251139 },
    { lat: 48.4206808, lng: 19.624882 },
    { lat: 48.4201385, lng: 19.624952 },
    { lat: 48.4191425, lng: 19.624707 },
    { lat: 48.4182386, lng: 19.6247477 },
    { lat: 48.4170665, lng: 19.6244721 },
    { lat: 48.4155164, lng: 19.62463 },
    { lat: 48.4144244, lng: 19.6250031 },
    { lat: 48.4127456, lng: 19.6254256 },
    { lat: 48.4112139, lng: 19.6242487 },
    { lat: 48.4102908, lng: 19.6229553 },
    { lat: 48.4092837, lng: 19.6220189 },
    { lat: 48.4088916, lng: 19.6214699 },
    { lat: 48.4080971, lng: 19.6205497 },
    { lat: 48.4072339, lng: 19.6188602 },
    { lat: 48.407187, lng: 19.6187692 },
    { lat: 48.4064061, lng: 19.6200128 },
    { lat: 48.4052188, lng: 19.6214373 },
    { lat: 48.4047313, lng: 19.6218695 },
    { lat: 48.4040709, lng: 19.6223137 },
    { lat: 48.4030287, lng: 19.6231377 },
    { lat: 48.4015632, lng: 19.6253075 },
    { lat: 48.4007605, lng: 19.6246007 },
    { lat: 48.3996178, lng: 19.6257721 },
    { lat: 48.3992466, lng: 19.6251867 },
    { lat: 48.3979141, lng: 19.6262445 },
    { lat: 48.3975362, lng: 19.6260328 },
    { lat: 48.3973535, lng: 19.6258565 },
    { lat: 48.3969215, lng: 19.6252766 },
    { lat: 48.3961936, lng: 19.6260226 },
  ],
  Pinciná: [
    { lat: 48.3602347, lng: 19.7952575 },
    { lat: 48.3603191, lng: 19.794744 },
    { lat: 48.3609569, lng: 19.7910528 },
    { lat: 48.3609356, lng: 19.7908473 },
    { lat: 48.3611623, lng: 19.7908004 },
    { lat: 48.3620057, lng: 19.7899475 },
    { lat: 48.3627834, lng: 19.7888998 },
    { lat: 48.3630208, lng: 19.7884579 },
    { lat: 48.3633387, lng: 19.7875888 },
    { lat: 48.3638112, lng: 19.7865493 },
    { lat: 48.3643245, lng: 19.7856914 },
    { lat: 48.3649291, lng: 19.7844294 },
    { lat: 48.3662071, lng: 19.7852825 },
    { lat: 48.3673209, lng: 19.7865526 },
    { lat: 48.3683385, lng: 19.7874287 },
    { lat: 48.3688832, lng: 19.7879771 },
    { lat: 48.369666, lng: 19.7885888 },
    { lat: 48.3705038, lng: 19.7887575 },
    { lat: 48.3712001, lng: 19.7887321 },
    { lat: 48.373195, lng: 19.7895432 },
    { lat: 48.3732716, lng: 19.7894169 },
    { lat: 48.3732702, lng: 19.7891541 },
    { lat: 48.3733432, lng: 19.789144 },
    { lat: 48.3734102, lng: 19.7890017 },
    { lat: 48.3736197, lng: 19.7890256 },
    { lat: 48.3736236, lng: 19.7888346 },
    { lat: 48.3737433, lng: 19.7887933 },
    { lat: 48.3737868, lng: 19.788547 },
    { lat: 48.3738861, lng: 19.7886296 },
    { lat: 48.3753508, lng: 19.7869923 },
    { lat: 48.3761662, lng: 19.7857768 },
    { lat: 48.3777229, lng: 19.7847437 },
    { lat: 48.378255, lng: 19.7843179 },
    { lat: 48.3787125, lng: 19.7838051 },
    { lat: 48.3793184, lng: 19.7828526 },
    { lat: 48.3794254, lng: 19.7825681 },
    { lat: 48.3797621, lng: 19.7814381 },
    { lat: 48.379988, lng: 19.780013 },
    { lat: 48.379981, lng: 19.779991 },
    { lat: 48.38002, lng: 19.77926 },
    { lat: 48.380071, lng: 19.77892 },
    { lat: 48.38022, lng: 19.777524 },
    { lat: 48.380564, lng: 19.776353 },
    { lat: 48.380706, lng: 19.775779 },
    { lat: 48.38078, lng: 19.775448 },
    { lat: 48.381057, lng: 19.774597 },
    { lat: 48.381127, lng: 19.774269 },
    { lat: 48.381297, lng: 19.773244 },
    { lat: 48.381346, lng: 19.772825 },
    { lat: 48.381581, lng: 19.772023 },
    { lat: 48.381748, lng: 19.77126 },
    { lat: 48.381871, lng: 19.769777 },
    { lat: 48.381899, lng: 19.769268 },
    { lat: 48.38206, lng: 19.76821 },
    { lat: 48.382161, lng: 19.766321 },
    { lat: 48.382077, lng: 19.764948 },
    { lat: 48.381992, lng: 19.764233 },
    { lat: 48.382005, lng: 19.764231 },
    { lat: 48.382001, lng: 19.764171 },
    { lat: 48.382042, lng: 19.76333 },
    { lat: 48.382025, lng: 19.763327 },
    { lat: 48.382133, lng: 19.762467 },
    { lat: 48.382575, lng: 19.760785 },
    { lat: 48.382605, lng: 19.760288 },
    { lat: 48.382562, lng: 19.759546 },
    { lat: 48.382254, lng: 19.758568 },
    { lat: 48.381578, lng: 19.757571 },
    { lat: 48.381515, lng: 19.757403 },
    { lat: 48.381229, lng: 19.75662 },
    { lat: 48.381175, lng: 19.756359 },
    { lat: 48.381043, lng: 19.755844 },
    { lat: 48.381, lng: 19.755147 },
    { lat: 48.380737, lng: 19.754412 },
    { lat: 48.380729, lng: 19.754385 },
    { lat: 48.380717, lng: 19.754343 },
    { lat: 48.380554, lng: 19.753748 },
    { lat: 48.38023, lng: 19.752897 },
    { lat: 48.38045, lng: 19.752818 },
    { lat: 48.380479, lng: 19.752808 },
    { lat: 48.380573, lng: 19.752136 },
    { lat: 48.380581, lng: 19.752078 },
    { lat: 48.380672, lng: 19.75143 },
    { lat: 48.380816, lng: 19.750136 },
    { lat: 48.380835, lng: 19.749851 },
    { lat: 48.380893, lng: 19.748968 },
    { lat: 48.38097, lng: 19.748681 },
    { lat: 48.380997, lng: 19.748345 },
    { lat: 48.380622, lng: 19.748089 },
    { lat: 48.379643, lng: 19.748097 },
    { lat: 48.378815, lng: 19.748169 },
    { lat: 48.378551, lng: 19.748193 },
    { lat: 48.37829, lng: 19.748228 },
    { lat: 48.378052, lng: 19.748265 },
    { lat: 48.377241, lng: 19.748617 },
    { lat: 48.377113, lng: 19.74868 },
    { lat: 48.376188, lng: 19.749095 },
    { lat: 48.375759, lng: 19.749289 },
    { lat: 48.375538, lng: 19.749377 },
    { lat: 48.375329, lng: 19.749454 },
    { lat: 48.374933, lng: 19.749622 },
    { lat: 48.374595, lng: 19.749761 },
    { lat: 48.374374, lng: 19.749871 },
    { lat: 48.374266, lng: 19.749926 },
    { lat: 48.374169, lng: 19.749979 },
    { lat: 48.373997, lng: 19.750069 },
    { lat: 48.37386, lng: 19.750142 },
    { lat: 48.373758, lng: 19.750198 },
    { lat: 48.373662, lng: 19.750243 },
    { lat: 48.373536, lng: 19.750303 },
    { lat: 48.373482, lng: 19.750333 },
    { lat: 48.37339, lng: 19.750384 },
    { lat: 48.373244, lng: 19.750461 },
    { lat: 48.373055, lng: 19.750563 },
    { lat: 48.372905, lng: 19.750639 },
    { lat: 48.372811, lng: 19.750684 },
    { lat: 48.372465, lng: 19.750865 },
    { lat: 48.372157, lng: 19.751011 },
    { lat: 48.37205, lng: 19.751065 },
    { lat: 48.371944, lng: 19.751114 },
    { lat: 48.371117, lng: 19.751569 },
    { lat: 48.371051, lng: 19.751366 },
    { lat: 48.370625, lng: 19.750088 },
    { lat: 48.370387, lng: 19.749338 },
    { lat: 48.369984, lng: 19.74808 },
    { lat: 48.369968, lng: 19.74805 },
    { lat: 48.369942, lng: 19.748004 },
    { lat: 48.369929, lng: 19.74798 },
    { lat: 48.369868, lng: 19.74788 },
    { lat: 48.369805, lng: 19.747774 },
    { lat: 48.369764, lng: 19.747711 },
    { lat: 48.369734, lng: 19.747658 },
    { lat: 48.369665, lng: 19.747547 },
    { lat: 48.369594, lng: 19.747432 },
    { lat: 48.369516, lng: 19.747305 },
    { lat: 48.369471, lng: 19.74724 },
    { lat: 48.369399, lng: 19.747115 },
    { lat: 48.369325, lng: 19.746993 },
    { lat: 48.369284, lng: 19.746923 },
    { lat: 48.369263, lng: 19.746886 },
    { lat: 48.369239, lng: 19.746861 },
    { lat: 48.369057, lng: 19.746551 },
    { lat: 48.368858, lng: 19.746269 },
    { lat: 48.368839, lng: 19.746241 },
    { lat: 48.368827, lng: 19.746226 },
    { lat: 48.368517, lng: 19.745784 },
    { lat: 48.367315, lng: 19.744137 },
    { lat: 48.36617, lng: 19.742532 },
    { lat: 48.366157, lng: 19.742516 },
    { lat: 48.366127, lng: 19.742482 },
    { lat: 48.366108, lng: 19.74246 },
    { lat: 48.366052, lng: 19.742387 },
    { lat: 48.365915, lng: 19.742194 },
    { lat: 48.365647, lng: 19.741816 },
    { lat: 48.365528, lng: 19.741649 },
    { lat: 48.365266, lng: 19.74128 },
    { lat: 48.365211, lng: 19.741201 },
    { lat: 48.365193, lng: 19.741178 },
    { lat: 48.365096, lng: 19.741049 },
    { lat: 48.365143, lng: 19.740861 },
    { lat: 48.365163, lng: 19.740759 },
    { lat: 48.365175, lng: 19.740611 },
    { lat: 48.365208, lng: 19.74027 },
    { lat: 48.365235, lng: 19.740179 },
    { lat: 48.36531, lng: 19.739807 },
    { lat: 48.365336, lng: 19.739669 },
    { lat: 48.365532, lng: 19.739169 },
    { lat: 48.365785, lng: 19.738879 },
    { lat: 48.365804, lng: 19.738879 },
    { lat: 48.365825, lng: 19.738873 },
    { lat: 48.365895, lng: 19.738904 },
    { lat: 48.36602, lng: 19.738968 },
    { lat: 48.366161, lng: 19.739109 },
    { lat: 48.366412, lng: 19.739239 },
    { lat: 48.366502, lng: 19.739289 },
    { lat: 48.366595, lng: 19.739324 },
    { lat: 48.366734, lng: 19.739307 },
    { lat: 48.366751, lng: 19.739298 },
    { lat: 48.366989, lng: 19.738998 },
    { lat: 48.367099, lng: 19.738968 },
    { lat: 48.367182, lng: 19.738934 },
    { lat: 48.36727, lng: 19.738951 },
    { lat: 48.367412, lng: 19.73906 },
    { lat: 48.367468, lng: 19.739214 },
    { lat: 48.367698, lng: 19.739338 },
    { lat: 48.367757, lng: 19.739345 },
    { lat: 48.367864, lng: 19.739273 },
    { lat: 48.367921, lng: 19.739183 },
    { lat: 48.367945, lng: 19.739109 },
    { lat: 48.367988, lng: 19.738878 },
    { lat: 48.368042, lng: 19.738509 },
    { lat: 48.36819, lng: 19.738109 },
    { lat: 48.368197, lng: 19.737968 },
    { lat: 48.368197, lng: 19.737761 },
    { lat: 48.368128, lng: 19.737707 },
    { lat: 48.367256, lng: 19.73702 },
    { lat: 48.367124, lng: 19.73691 },
    { lat: 48.367072, lng: 19.736867 },
    { lat: 48.366962, lng: 19.736776 },
    { lat: 48.366936, lng: 19.736626 },
    { lat: 48.366621, lng: 19.736272 },
    { lat: 48.366514, lng: 19.736154 },
    { lat: 48.366272, lng: 19.735963 },
    { lat: 48.366227, lng: 19.735826 },
    { lat: 48.366069, lng: 19.73562 },
    { lat: 48.365733, lng: 19.735182 },
    { lat: 48.365584, lng: 19.735038 },
    { lat: 48.365389, lng: 19.734726 },
    { lat: 48.365326, lng: 19.734628 },
    { lat: 48.364976, lng: 19.734159 },
    { lat: 48.364533, lng: 19.73357 },
    { lat: 48.3638523, lng: 19.734606 },
    { lat: 48.3625959, lng: 19.7361776 },
    { lat: 48.3622291, lng: 19.735579 },
    { lat: 48.3620017, lng: 19.7350529 },
    { lat: 48.3617873, lng: 19.7348179 },
    { lat: 48.3612189, lng: 19.7338175 },
    { lat: 48.3610525, lng: 19.7332177 },
    { lat: 48.3611093, lng: 19.7315061 },
    { lat: 48.3598654, lng: 19.7294754 },
    { lat: 48.3577694, lng: 19.7308902 },
    { lat: 48.3546658, lng: 19.7341734 },
    { lat: 48.3542725, lng: 19.734625 },
    { lat: 48.3527972, lng: 19.7366423 },
    { lat: 48.3523196, lng: 19.7376907 },
    { lat: 48.3524467, lng: 19.7389518 },
    { lat: 48.3524275, lng: 19.7391787 },
    { lat: 48.3521695, lng: 19.7400964 },
    { lat: 48.3516366, lng: 19.7415166 },
    { lat: 48.3504666, lng: 19.7433872 },
    { lat: 48.3501979, lng: 19.7448175 },
    { lat: 48.3499944, lng: 19.7465596 },
    { lat: 48.3500136, lng: 19.7467915 },
    { lat: 48.3502989, lng: 19.7476549 },
    { lat: 48.3510742, lng: 19.7496686 },
    { lat: 48.3511882, lng: 19.750055 },
    { lat: 48.3514858, lng: 19.7516087 },
    { lat: 48.3530813, lng: 19.7538737 },
    { lat: 48.3531214, lng: 19.7540178 },
    { lat: 48.3511991, lng: 19.7559758 },
    { lat: 48.3525089, lng: 19.7592502 },
    { lat: 48.3519696, lng: 19.7597948 },
    { lat: 48.3531386, lng: 19.762382 },
    { lat: 48.3542576, lng: 19.7655481 },
    { lat: 48.3539752, lng: 19.7662701 },
    { lat: 48.3539786, lng: 19.7668861 },
    { lat: 48.3537343, lng: 19.7675027 },
    { lat: 48.3536205, lng: 19.7676529 },
    { lat: 48.3522461, lng: 19.768399 },
    { lat: 48.3517562, lng: 19.7685285 },
    { lat: 48.3511867, lng: 19.7685177 },
    { lat: 48.3496119, lng: 19.7683228 },
    { lat: 48.3490508, lng: 19.7683474 },
    { lat: 48.3489224, lng: 19.7685039 },
    { lat: 48.3472542, lng: 19.771727 },
    { lat: 48.3471483, lng: 19.7718827 },
    { lat: 48.3472528, lng: 19.7721819 },
    { lat: 48.3472531, lng: 19.7723692 },
    { lat: 48.3470417, lng: 19.7729888 },
    { lat: 48.3471954, lng: 19.773536 },
    { lat: 48.3472086, lng: 19.7739888 },
    { lat: 48.3473532, lng: 19.7748446 },
    { lat: 48.349186, lng: 19.780641 },
    { lat: 48.3494706, lng: 19.7827518 },
    { lat: 48.3493742, lng: 19.7840425 },
    { lat: 48.3497171, lng: 19.7853116 },
    { lat: 48.3497595, lng: 19.7856046 },
    { lat: 48.3499203, lng: 19.7860066 },
    { lat: 48.3501173, lng: 19.7863633 },
    { lat: 48.3506593, lng: 19.7869522 },
    { lat: 48.3507011, lng: 19.7870061 },
    { lat: 48.3507504, lng: 19.7870537 },
    { lat: 48.3517165, lng: 19.7875524 },
    { lat: 48.3533453, lng: 19.7890833 },
    { lat: 48.358705, lng: 19.7938028 },
    { lat: 48.3594278, lng: 19.7945596 },
    { lat: 48.3602347, lng: 19.7952575 },
  ],
  Vidiná: [
    { lat: 48.3567734, lng: 19.6503312 },
    { lat: 48.356531, lng: 19.6506301 },
    { lat: 48.3562287, lng: 19.6509949 },
    { lat: 48.3563256, lng: 19.6511711 },
    { lat: 48.3561628, lng: 19.6516326 },
    { lat: 48.3562501, lng: 19.6521183 },
    { lat: 48.356006, lng: 19.6523546 },
    { lat: 48.3547925, lng: 19.6531004 },
    { lat: 48.3534562, lng: 19.6540298 },
    { lat: 48.3526993, lng: 19.654461 },
    { lat: 48.3524312, lng: 19.6547628 },
    { lat: 48.3520747, lng: 19.6553502 },
    { lat: 48.351659, lng: 19.6547555 },
    { lat: 48.3514275, lng: 19.6546437 },
    { lat: 48.3510095, lng: 19.6548934 },
    { lat: 48.350851, lng: 19.6554518 },
    { lat: 48.3512501, lng: 19.6556313 },
    { lat: 48.3511787, lng: 19.6558213 },
    { lat: 48.3507071, lng: 19.6564849 },
    { lat: 48.3506577, lng: 19.6564109 },
    { lat: 48.350595, lng: 19.6564708 },
    { lat: 48.350367, lng: 19.6564366 },
    { lat: 48.3499568, lng: 19.6560361 },
    { lat: 48.3498364, lng: 19.656037 },
    { lat: 48.3498021, lng: 19.6562297 },
    { lat: 48.3498908, lng: 19.656396 },
    { lat: 48.3498703, lng: 19.6566166 },
    { lat: 48.3495803, lng: 19.6567088 },
    { lat: 48.3493824, lng: 19.6565663 },
    { lat: 48.3491413, lng: 19.6567445 },
    { lat: 48.3491018, lng: 19.6570684 },
    { lat: 48.3491445, lng: 19.6572603 },
    { lat: 48.3487782, lng: 19.657436 },
    { lat: 48.3488167, lng: 19.657515 },
    { lat: 48.3487963, lng: 19.6576403 },
    { lat: 48.3487076, lng: 19.657745 },
    { lat: 48.3487859, lng: 19.6580785 },
    { lat: 48.3487175, lng: 19.6582644 },
    { lat: 48.348596, lng: 19.6583652 },
    { lat: 48.3484641, lng: 19.6583414 },
    { lat: 48.3480863, lng: 19.6577872 },
    { lat: 48.3479707, lng: 19.6576951 },
    { lat: 48.3478354, lng: 19.6577407 },
    { lat: 48.3479464, lng: 19.6581287 },
    { lat: 48.3479756, lng: 19.6583514 },
    { lat: 48.3479468, lng: 19.6584163 },
    { lat: 48.3477854, lng: 19.6584805 },
    { lat: 48.3476239, lng: 19.6582503 },
    { lat: 48.3475557, lng: 19.6582626 },
    { lat: 48.3474236, lng: 19.6586568 },
    { lat: 48.3472897, lng: 19.6587823 },
    { lat: 48.3471671, lng: 19.6587653 },
    { lat: 48.3472647, lng: 19.6592081 },
    { lat: 48.3470091, lng: 19.6592351 },
    { lat: 48.346653, lng: 19.6594346 },
    { lat: 48.3464398, lng: 19.6594296 },
    { lat: 48.3467625, lng: 19.661638 },
    { lat: 48.3456077, lng: 19.6620169 },
    { lat: 48.3454441, lng: 19.6620126 },
    { lat: 48.3453419, lng: 19.6626925 },
    { lat: 48.3457214, lng: 19.6629876 },
    { lat: 48.3459609, lng: 19.663326 },
    { lat: 48.3457475, lng: 19.6636923 },
    { lat: 48.3453059, lng: 19.6640223 },
    { lat: 48.3453547, lng: 19.6645288 },
    { lat: 48.3457842, lng: 19.6640822 },
    { lat: 48.3459896, lng: 19.6643858 },
    { lat: 48.3473858, lng: 19.6674166 },
    { lat: 48.3481029, lng: 19.6686819 },
    { lat: 48.3483833, lng: 19.6692783 },
    { lat: 48.3494193, lng: 19.6686615 },
    { lat: 48.3496579, lng: 19.6686543 },
    { lat: 48.3502082, lng: 19.6684625 },
    { lat: 48.3507201, lng: 19.6685478 },
    { lat: 48.3514446, lng: 19.6684956 },
    { lat: 48.3511738, lng: 19.6691168 },
    { lat: 48.3514794, lng: 19.6693696 },
    { lat: 48.3518381, lng: 19.6694285 },
    { lat: 48.3522861, lng: 19.6690668 },
    { lat: 48.3528522, lng: 19.6683785 },
    { lat: 48.3540908, lng: 19.6666297 },
    { lat: 48.3550965, lng: 19.6679215 },
    { lat: 48.3552754, lng: 19.6690417 },
    { lat: 48.355928, lng: 19.6703739 },
    { lat: 48.3562493, lng: 19.6705769 },
    { lat: 48.356321, lng: 19.6707433 },
    { lat: 48.3569014, lng: 19.6710955 },
    { lat: 48.3573711, lng: 19.6707939 },
    { lat: 48.3573987, lng: 19.6705502 },
    { lat: 48.3575061, lng: 19.6706335 },
    { lat: 48.3576023, lng: 19.6702227 },
    { lat: 48.357732, lng: 19.6704225 },
    { lat: 48.3579407, lng: 19.670328 },
    { lat: 48.358278, lng: 19.6706609 },
    { lat: 48.3585743, lng: 19.6707857 },
    { lat: 48.359006, lng: 19.6713188 },
    { lat: 48.3593378, lng: 19.6719545 },
    { lat: 48.3601045, lng: 19.6731165 },
    { lat: 48.3602855, lng: 19.6736591 },
    { lat: 48.3606437, lng: 19.6739605 },
    { lat: 48.3607336, lng: 19.6742522 },
    { lat: 48.3608564, lng: 19.6743046 },
    { lat: 48.362163, lng: 19.67388 },
    { lat: 48.363099, lng: 19.673016 },
    { lat: 48.363972, lng: 19.671886 },
    { lat: 48.364538, lng: 19.671788 },
    { lat: 48.365195, lng: 19.671861 },
    { lat: 48.365636, lng: 19.671534 },
    { lat: 48.366465, lng: 19.671328 },
    { lat: 48.366983, lng: 19.671279 },
    { lat: 48.368061, lng: 19.669952 },
    { lat: 48.36873, lng: 19.668881 },
    { lat: 48.36875, lng: 19.668827 },
    { lat: 48.369221, lng: 19.668107 },
    { lat: 48.369432, lng: 19.667326 },
    { lat: 48.369455, lng: 19.667242 },
    { lat: 48.369784, lng: 19.66642 },
    { lat: 48.370054, lng: 19.665955 },
    { lat: 48.370127, lng: 19.66587 },
    { lat: 48.37043, lng: 19.665514 },
    { lat: 48.370467, lng: 19.665471 },
    { lat: 48.371426, lng: 19.664937 },
    { lat: 48.372798, lng: 19.66395 },
    { lat: 48.373821, lng: 19.663038 },
    { lat: 48.374666, lng: 19.662176 },
    { lat: 48.375649, lng: 19.660923 },
    { lat: 48.375714, lng: 19.660839 },
    { lat: 48.375878, lng: 19.660631 },
    { lat: 48.376484, lng: 19.659833 },
    { lat: 48.376718, lng: 19.659527 },
    { lat: 48.377172, lng: 19.65901 },
    { lat: 48.377226, lng: 19.65894 },
    { lat: 48.37767, lng: 19.658152 },
    { lat: 48.378393, lng: 19.656958 },
    { lat: 48.379106, lng: 19.655802 },
    { lat: 48.379149, lng: 19.655675 },
    { lat: 48.379365, lng: 19.65504 },
    { lat: 48.379109, lng: 19.653383 },
    { lat: 48.379459, lng: 19.65263 },
    { lat: 48.379687, lng: 19.65215 },
    { lat: 48.380063, lng: 19.651277 },
    { lat: 48.380119, lng: 19.649775 },
    { lat: 48.379835, lng: 19.648196 },
    { lat: 48.379222, lng: 19.647321 },
    { lat: 48.379198, lng: 19.647286 },
    { lat: 48.379103, lng: 19.64715 },
    { lat: 48.378735, lng: 19.64679 },
    { lat: 48.378222, lng: 19.646287 },
    { lat: 48.37822, lng: 19.646285 },
    { lat: 48.3780567, lng: 19.6462957 },
    { lat: 48.3772743, lng: 19.6467634 },
    { lat: 48.3761402, lng: 19.6467998 },
    { lat: 48.3752946, lng: 19.6465368 },
    { lat: 48.3749879, lng: 19.6462597 },
    { lat: 48.3745906, lng: 19.6456466 },
    { lat: 48.3733663, lng: 19.6445588 },
    { lat: 48.3719015, lng: 19.6429952 },
    { lat: 48.3705909, lng: 19.6449347 },
    { lat: 48.3697274, lng: 19.643801 },
    { lat: 48.3690707, lng: 19.6448297 },
    { lat: 48.3671827, lng: 19.6425747 },
    { lat: 48.367703, lng: 19.6415234 },
    { lat: 48.364751, lng: 19.6384911 },
    { lat: 48.3637591, lng: 19.6371412 },
    { lat: 48.3637545, lng: 19.6369606 },
    { lat: 48.363834, lng: 19.6365532 },
    { lat: 48.3636114, lng: 19.6364922 },
    { lat: 48.363435, lng: 19.6366987 },
    { lat: 48.3629833, lng: 19.6369755 },
    { lat: 48.3627305, lng: 19.6365969 },
    { lat: 48.3625596, lng: 19.6371778 },
    { lat: 48.362648, lng: 19.6375858 },
    { lat: 48.3624355, lng: 19.637498 },
    { lat: 48.3624021, lng: 19.6379025 },
    { lat: 48.3621157, lng: 19.6376532 },
    { lat: 48.3620895, lng: 19.6374712 },
    { lat: 48.3619424, lng: 19.6372821 },
    { lat: 48.3618034, lng: 19.6374253 },
    { lat: 48.3617483, lng: 19.6377732 },
    { lat: 48.3618223, lng: 19.63789 },
    { lat: 48.3619033, lng: 19.6378633 },
    { lat: 48.3619603, lng: 19.6380273 },
    { lat: 48.3618564, lng: 19.6390661 },
    { lat: 48.3618127, lng: 19.639123 },
    { lat: 48.3616932, lng: 19.6390431 },
    { lat: 48.3615701, lng: 19.6388391 },
    { lat: 48.3615166, lng: 19.6390285 },
    { lat: 48.361299, lng: 19.6389741 },
    { lat: 48.361317, lng: 19.6390736 },
    { lat: 48.3612434, lng: 19.6391906 },
    { lat: 48.3612868, lng: 19.6393297 },
    { lat: 48.3614968, lng: 19.6395623 },
    { lat: 48.3613785, lng: 19.6398383 },
    { lat: 48.3613568, lng: 19.6400262 },
    { lat: 48.3613989, lng: 19.6405084 },
    { lat: 48.3613256, lng: 19.6422011 },
    { lat: 48.3614309, lng: 19.6423895 },
    { lat: 48.3616599, lng: 19.6422942 },
    { lat: 48.3617905, lng: 19.6425417 },
    { lat: 48.361799, lng: 19.6426812 },
    { lat: 48.3615944, lng: 19.6427899 },
    { lat: 48.3615882, lng: 19.6429276 },
    { lat: 48.3615029, lng: 19.6431368 },
    { lat: 48.361515, lng: 19.6432263 },
    { lat: 48.3615734, lng: 19.6432686 },
    { lat: 48.3618297, lng: 19.6430316 },
    { lat: 48.3619631, lng: 19.6430212 },
    { lat: 48.362081, lng: 19.6429068 },
    { lat: 48.3620125, lng: 19.6432161 },
    { lat: 48.3620388, lng: 19.6433739 },
    { lat: 48.361911, lng: 19.6436137 },
    { lat: 48.3618853, lng: 19.6438654 },
    { lat: 48.3620058, lng: 19.6439658 },
    { lat: 48.3620502, lng: 19.6439237 },
    { lat: 48.3620677, lng: 19.6441118 },
    { lat: 48.3619262, lng: 19.6441943 },
    { lat: 48.3618588, lng: 19.6441232 },
    { lat: 48.3618374, lng: 19.6439744 },
    { lat: 48.3615925, lng: 19.6439154 },
    { lat: 48.3612896, lng: 19.6441464 },
    { lat: 48.361166, lng: 19.6439308 },
    { lat: 48.36096, lng: 19.64386 },
    { lat: 48.3608273, lng: 19.6443154 },
    { lat: 48.3608519, lng: 19.6444362 },
    { lat: 48.3605026, lng: 19.6451333 },
    { lat: 48.3605532, lng: 19.6453552 },
    { lat: 48.3606404, lng: 19.6454409 },
    { lat: 48.3607398, lng: 19.6454447 },
    { lat: 48.3608502, lng: 19.6456928 },
    { lat: 48.360976, lng: 19.6458334 },
    { lat: 48.3610445, lng: 19.6458016 },
    { lat: 48.3610672, lng: 19.6456842 },
    { lat: 48.360986, lng: 19.6454334 },
    { lat: 48.3610625, lng: 19.6453537 },
    { lat: 48.3612072, lng: 19.6457394 },
    { lat: 48.3613448, lng: 19.6457434 },
    { lat: 48.3613955, lng: 19.6460401 },
    { lat: 48.3612813, lng: 19.6463547 },
    { lat: 48.3611477, lng: 19.6462163 },
    { lat: 48.3610301, lng: 19.6462109 },
    { lat: 48.3610241, lng: 19.6464748 },
    { lat: 48.3611757, lng: 19.6468619 },
    { lat: 48.3612648, lng: 19.6468886 },
    { lat: 48.3612403, lng: 19.6470208 },
    { lat: 48.361123, lng: 19.6470164 },
    { lat: 48.3609714, lng: 19.6471271 },
    { lat: 48.3608754, lng: 19.6470239 },
    { lat: 48.3608335, lng: 19.6471688 },
    { lat: 48.3609881, lng: 19.6475216 },
    { lat: 48.360816, lng: 19.647706 },
    { lat: 48.3607485, lng: 19.6477589 },
    { lat: 48.3605676, lng: 19.647519 },
    { lat: 48.3604933, lng: 19.6474936 },
    { lat: 48.3604594, lng: 19.6475683 },
    { lat: 48.3605363, lng: 19.6477497 },
    { lat: 48.3604509, lng: 19.647904 },
    { lat: 48.3603553, lng: 19.6478315 },
    { lat: 48.3602869, lng: 19.6478636 },
    { lat: 48.3602288, lng: 19.6480527 },
    { lat: 48.3601489, lng: 19.6481271 },
    { lat: 48.3599182, lng: 19.6480592 },
    { lat: 48.3598464, lng: 19.6481164 },
    { lat: 48.359746, lng: 19.6482878 },
    { lat: 48.3598202, lng: 19.6484093 },
    { lat: 48.3597385, lng: 19.6487705 },
    { lat: 48.3596207, lng: 19.6487548 },
    { lat: 48.3595958, lng: 19.648555 },
    { lat: 48.3592455, lng: 19.6484837 },
    { lat: 48.3591671, lng: 19.6486444 },
    { lat: 48.3591902, lng: 19.648682 },
    { lat: 48.3592648, lng: 19.6487386 },
    { lat: 48.3593668, lng: 19.6486226 },
    { lat: 48.3594294, lng: 19.6487651 },
    { lat: 48.3591864, lng: 19.6490474 },
    { lat: 48.359131, lng: 19.6492156 },
    { lat: 48.3591505, lng: 19.6494489 },
    { lat: 48.3592276, lng: 19.6495622 },
    { lat: 48.3593374, lng: 19.6493728 },
    { lat: 48.3594519, lng: 19.6496124 },
    { lat: 48.359274, lng: 19.6500872 },
    { lat: 48.3584064, lng: 19.6497655 },
    { lat: 48.3570812, lng: 19.6500151 },
    { lat: 48.3570281, lng: 19.6502614 },
    { lat: 48.3568932, lng: 19.6503435 },
    { lat: 48.3568727, lng: 19.6504848 },
    { lat: 48.3567734, lng: 19.6503312 },
  ],
  Lipovany: [
    { lat: 48.2234646, lng: 19.7280763 },
    { lat: 48.2236378, lng: 19.7272821 },
    { lat: 48.2237065, lng: 19.7268182 },
    { lat: 48.2236494, lng: 19.7264611 },
    { lat: 48.2235585, lng: 19.7261638 },
    { lat: 48.2235077, lng: 19.7253075 },
    { lat: 48.2235988, lng: 19.7247228 },
    { lat: 48.2241362, lng: 19.7235864 },
    { lat: 48.2247054, lng: 19.721597 },
    { lat: 48.2246989, lng: 19.7212722 },
    { lat: 48.224539, lng: 19.7206946 },
    { lat: 48.224067, lng: 19.7201645 },
    { lat: 48.2236128, lng: 19.7181753 },
    { lat: 48.2234876, lng: 19.7167575 },
    { lat: 48.2240012, lng: 19.7153917 },
    { lat: 48.2246547, lng: 19.7147153 },
    { lat: 48.2253895, lng: 19.7142127 },
    { lat: 48.2265078, lng: 19.7137194 },
    { lat: 48.2270757, lng: 19.7135922 },
    { lat: 48.2274077, lng: 19.7124539 },
    { lat: 48.2276441, lng: 19.7120698 },
    { lat: 48.2274833, lng: 19.711446 },
    { lat: 48.2273382, lng: 19.7106216 },
    { lat: 48.2273522, lng: 19.7096989 },
    { lat: 48.2275566, lng: 19.7088328 },
    { lat: 48.2275617, lng: 19.7084676 },
    { lat: 48.227681, lng: 19.7079609 },
    { lat: 48.2282977, lng: 19.7060325 },
    { lat: 48.2282224, lng: 19.7054835 },
    { lat: 48.2281342, lng: 19.7052754 },
    { lat: 48.2278714, lng: 19.7049159 },
    { lat: 48.2277069, lng: 19.7042599 },
    { lat: 48.2276766, lng: 19.7037887 },
    { lat: 48.2277426, lng: 19.7019898 },
    { lat: 48.2280991, lng: 19.6992608 },
    { lat: 48.2285871, lng: 19.6979813 },
    { lat: 48.2288847, lng: 19.6963796 },
    { lat: 48.2294969, lng: 19.6949843 },
    { lat: 48.2309987, lng: 19.6940982 },
    { lat: 48.2328898, lng: 19.6914966 },
    { lat: 48.2329988, lng: 19.6907895 },
    { lat: 48.233037, lng: 19.6900579 },
    { lat: 48.2329927, lng: 19.6891718 },
    { lat: 48.2323794, lng: 19.6863058 },
    { lat: 48.2310517, lng: 19.6844202 },
    { lat: 48.2311579, lng: 19.6821487 },
    { lat: 48.23153, lng: 19.6795239 },
    { lat: 48.2314117, lng: 19.6772758 },
    { lat: 48.231564, lng: 19.6758535 },
    { lat: 48.232225, lng: 19.673768 },
    { lat: 48.231961, lng: 19.673574 },
    { lat: 48.231438, lng: 19.673222 },
    { lat: 48.231204, lng: 19.673018 },
    { lat: 48.230862, lng: 19.67272 },
    { lat: 48.230437, lng: 19.672463 },
    { lat: 48.230169, lng: 19.67236 },
    { lat: 48.229859, lng: 19.672307 },
    { lat: 48.228816, lng: 19.672656 },
    { lat: 48.227761, lng: 19.673226 },
    { lat: 48.227347, lng: 19.673452 },
    { lat: 48.226578, lng: 19.674065 },
    { lat: 48.226082, lng: 19.674206 },
    { lat: 48.225382, lng: 19.674401 },
    { lat: 48.224902, lng: 19.675096 },
    { lat: 48.224398, lng: 19.675822 },
    { lat: 48.22386, lng: 19.675902 },
    { lat: 48.223629, lng: 19.67562 },
    { lat: 48.223132, lng: 19.675014 },
    { lat: 48.222654, lng: 19.674581 },
    { lat: 48.221906, lng: 19.674214 },
    { lat: 48.221482, lng: 19.67417 },
    { lat: 48.22126, lng: 19.674308 },
    { lat: 48.220841, lng: 19.674567 },
    { lat: 48.220154, lng: 19.674628 },
    { lat: 48.219739, lng: 19.674662 },
    { lat: 48.219567, lng: 19.6749 },
    { lat: 48.21927, lng: 19.675311 },
    { lat: 48.218556, lng: 19.676636 },
    { lat: 48.218388, lng: 19.677476 },
    { lat: 48.218262, lng: 19.678098 },
    { lat: 48.218107, lng: 19.678208 },
    { lat: 48.217607, lng: 19.678564 },
    { lat: 48.217164, lng: 19.679214 },
    { lat: 48.216727, lng: 19.67986 },
    { lat: 48.216553, lng: 19.679964 },
    { lat: 48.21636, lng: 19.680081 },
    { lat: 48.215663, lng: 19.680043 },
    { lat: 48.214855, lng: 19.680002 },
    { lat: 48.214133, lng: 19.679962 },
    { lat: 48.213608, lng: 19.679914 },
    { lat: 48.213026, lng: 19.679859 },
    { lat: 48.212856, lng: 19.680105 },
    { lat: 48.2125496, lng: 19.680545 },
    { lat: 48.211975, lng: 19.68173 },
    { lat: 48.211723, lng: 19.682172 },
    { lat: 48.211271, lng: 19.68297 },
    { lat: 48.210861, lng: 19.683389 },
    { lat: 48.210758, lng: 19.683887 },
    { lat: 48.210694, lng: 19.684311 },
    { lat: 48.210651, lng: 19.684637 },
    { lat: 48.210564, lng: 19.685239 },
    { lat: 48.210428, lng: 19.68537 },
    { lat: 48.210348, lng: 19.68545 },
    { lat: 48.209816, lng: 19.685948 },
    { lat: 48.209523, lng: 19.686093 },
    { lat: 48.209335, lng: 19.686339 },
    { lat: 48.2090721, lng: 19.6867156 },
    { lat: 48.208889, lng: 19.686978 },
    { lat: 48.208608, lng: 19.68739 },
    { lat: 48.208382, lng: 19.687722 },
    { lat: 48.2083697, lng: 19.6877479 },
    { lat: 48.208286, lng: 19.687925 },
    { lat: 48.207785, lng: 19.688966 },
    { lat: 48.207538, lng: 19.689038 },
    { lat: 48.207397, lng: 19.689657 },
    { lat: 48.207363, lng: 19.689834 },
    { lat: 48.207167, lng: 19.690748 },
    { lat: 48.207048, lng: 19.691297 },
    { lat: 48.206739, lng: 19.691479 },
    { lat: 48.206334, lng: 19.691482 },
    { lat: 48.205873, lng: 19.691245 },
    { lat: 48.205139, lng: 19.69118 },
    { lat: 48.204735, lng: 19.691192 },
    { lat: 48.204574, lng: 19.691196 },
    { lat: 48.203894, lng: 19.691219 },
    { lat: 48.20418, lng: 19.691841 },
    { lat: 48.204379, lng: 19.692273 },
    { lat: 48.204621, lng: 19.6928 },
    { lat: 48.204699, lng: 19.693313 },
    { lat: 48.204731, lng: 19.69366 },
    { lat: 48.20476, lng: 19.69393 },
    { lat: 48.204767, lng: 19.693974 },
    { lat: 48.204796, lng: 19.694296 },
    { lat: 48.204693, lng: 19.694859 },
    { lat: 48.204546, lng: 19.695659 },
    { lat: 48.204477, lng: 19.696418 },
    { lat: 48.204462, lng: 19.696601 },
    { lat: 48.204342, lng: 19.697329 },
    { lat: 48.204332, lng: 19.697386 },
    { lat: 48.203597, lng: 19.698503 },
    { lat: 48.203467, lng: 19.69934 },
    { lat: 48.203551, lng: 19.699967 },
    { lat: 48.203615, lng: 19.700124 },
    { lat: 48.203677, lng: 19.700311 },
    { lat: 48.203876, lng: 19.70142 },
    { lat: 48.203795, lng: 19.70186 },
    { lat: 48.20354, lng: 19.701959 },
    { lat: 48.203148, lng: 19.702105 },
    { lat: 48.203043, lng: 19.702152 },
    { lat: 48.202469, lng: 19.702422 },
    { lat: 48.2024002, lng: 19.7025685 },
    { lat: 48.202289, lng: 19.702805 },
    { lat: 48.202025, lng: 19.703374 },
    { lat: 48.201655, lng: 19.704158 },
    { lat: 48.201457, lng: 19.704623 },
    { lat: 48.201138, lng: 19.705373 },
    { lat: 48.201098, lng: 19.705739 },
    { lat: 48.20101, lng: 19.706593 },
    { lat: 48.20109, lng: 19.70713 },
    { lat: 48.201152, lng: 19.70797 },
    { lat: 48.201451, lng: 19.708528 },
    { lat: 48.201878, lng: 19.709191 },
    { lat: 48.202014, lng: 19.709444 },
    { lat: 48.202258, lng: 19.709898 },
    { lat: 48.20248, lng: 19.710312 },
    { lat: 48.20227, lng: 19.711101 },
    { lat: 48.202066, lng: 19.711896 },
    { lat: 48.202229, lng: 19.71281 },
    { lat: 48.202398, lng: 19.713963 },
    { lat: 48.202353, lng: 19.714571 },
    { lat: 48.202304, lng: 19.715072 },
    { lat: 48.202298, lng: 19.71542 },
    { lat: 48.202237, lng: 19.716239 },
    { lat: 48.202073, lng: 19.717169 },
    { lat: 48.201923, lng: 19.71812 },
    { lat: 48.201796, lng: 19.719025 },
    { lat: 48.201755, lng: 19.719898 },
    { lat: 48.201648, lng: 19.720398 },
    { lat: 48.201554, lng: 19.720808 },
    { lat: 48.201473, lng: 19.721125 },
    { lat: 48.201829, lng: 19.721193 },
    { lat: 48.202201, lng: 19.721744 },
    { lat: 48.202665, lng: 19.722438 },
    { lat: 48.202752, lng: 19.722808 },
    { lat: 48.202798, lng: 19.722823 },
    { lat: 48.202824, lng: 19.722831 },
    { lat: 48.202845, lng: 19.723032 },
    { lat: 48.203097, lng: 19.723533 },
    { lat: 48.203339, lng: 19.724008 },
    { lat: 48.203628, lng: 19.724761 },
    { lat: 48.203742, lng: 19.724985 },
    { lat: 48.204027, lng: 19.725307 },
    { lat: 48.204129, lng: 19.725369 },
    { lat: 48.2046, lng: 19.725609 },
    { lat: 48.204983, lng: 19.725848 },
    { lat: 48.205117, lng: 19.725833 },
    { lat: 48.20524, lng: 19.725826 },
    { lat: 48.205282, lng: 19.72587 },
    { lat: 48.205274, lng: 19.72606 },
    { lat: 48.205152, lng: 19.726305 },
    { lat: 48.20517, lng: 19.726319 },
    { lat: 48.205187, lng: 19.726333 },
    { lat: 48.205212, lng: 19.726353 },
    { lat: 48.205239, lng: 19.726375 },
    { lat: 48.205979, lng: 19.726882 },
    { lat: 48.206257, lng: 19.727017 },
    { lat: 48.206755, lng: 19.727332 },
    { lat: 48.206992, lng: 19.727599 },
    { lat: 48.207272, lng: 19.728025 },
    { lat: 48.20769, lng: 19.728733 },
    { lat: 48.20793, lng: 19.729066 },
    { lat: 48.208239, lng: 19.729364 },
    { lat: 48.209189, lng: 19.729884 },
    { lat: 48.210085, lng: 19.730083 },
    { lat: 48.210757, lng: 19.730253 },
    { lat: 48.211083, lng: 19.730659 },
    { lat: 48.211461, lng: 19.731056 },
    { lat: 48.211673, lng: 19.731338 },
    { lat: 48.212015, lng: 19.731704 },
    { lat: 48.212353, lng: 19.732105 },
    { lat: 48.212721, lng: 19.732376 },
    { lat: 48.213094, lng: 19.732679 },
    { lat: 48.21338, lng: 19.732916 },
    { lat: 48.213827, lng: 19.733265 },
    { lat: 48.214181, lng: 19.733588 },
    { lat: 48.214385, lng: 19.733904 },
    { lat: 48.214556, lng: 19.734142 },
    { lat: 48.214903, lng: 19.734585 },
    { lat: 48.215166, lng: 19.734887 },
    { lat: 48.215354, lng: 19.735201 },
    { lat: 48.2153317, lng: 19.7347251 },
    { lat: 48.2154075, lng: 19.7340746 },
    { lat: 48.2158591, lng: 19.7328792 },
    { lat: 48.2167796, lng: 19.73068 },
    { lat: 48.2171829, lng: 19.7304274 },
    { lat: 48.2178716, lng: 19.7298478 },
    { lat: 48.2186967, lng: 19.7295562 },
    { lat: 48.2188897, lng: 19.7296094 },
    { lat: 48.2192239, lng: 19.730083 },
    { lat: 48.2196918, lng: 19.7304953 },
    { lat: 48.2199276, lng: 19.7305519 },
    { lat: 48.2211202, lng: 19.7303629 },
    { lat: 48.2214852, lng: 19.7301064 },
    { lat: 48.2216895, lng: 19.7298337 },
    { lat: 48.2221036, lng: 19.7294669 },
    { lat: 48.2225202, lng: 19.7287073 },
    { lat: 48.22267, lng: 19.7285306 },
    { lat: 48.2234646, lng: 19.7280763 },
  ],
  Šíd: [
    { lat: 48.253524, lng: 19.8617087 },
    { lat: 48.2525874, lng: 19.8629477 },
    { lat: 48.2508709, lng: 19.8652936 },
    { lat: 48.2509703, lng: 19.8659262 },
    { lat: 48.2520784, lng: 19.8719976 },
    { lat: 48.2522674, lng: 19.8744106 },
    { lat: 48.2527028, lng: 19.875438 },
    { lat: 48.2535777, lng: 19.876496 },
    { lat: 48.2536252, lng: 19.8766823 },
    { lat: 48.253515, lng: 19.8790787 },
    { lat: 48.2535295, lng: 19.8793372 },
    { lat: 48.2536253, lng: 19.8797305 },
    { lat: 48.2535789, lng: 19.8800097 },
    { lat: 48.2536276, lng: 19.8801492 },
    { lat: 48.2536308, lng: 19.8808174 },
    { lat: 48.2538021, lng: 19.8813946 },
    { lat: 48.2556568, lng: 19.8842145 },
    { lat: 48.2558015, lng: 19.8844118 },
    { lat: 48.2559628, lng: 19.8845214 },
    { lat: 48.2559442, lng: 19.8846119 },
    { lat: 48.2567852, lng: 19.8859576 },
    { lat: 48.2571868, lng: 19.8863302 },
    { lat: 48.2574742, lng: 19.886698 },
    { lat: 48.2570743, lng: 19.887798 },
    { lat: 48.2569909, lng: 19.8879266 },
    { lat: 48.256843, lng: 19.8885967 },
    { lat: 48.2565316, lng: 19.8889797 },
    { lat: 48.2562085, lng: 19.8901577 },
    { lat: 48.2562677, lng: 19.8901453 },
    { lat: 48.2562354, lng: 19.8904705 },
    { lat: 48.2558156, lng: 19.8914203 },
    { lat: 48.2558727, lng: 19.8918518 },
    { lat: 48.2556437, lng: 19.8925792 },
    { lat: 48.2556125, lng: 19.8929233 },
    { lat: 48.2555006, lng: 19.8933891 },
    { lat: 48.2555138, lng: 19.8945322 },
    { lat: 48.2554398, lng: 19.8956861 },
    { lat: 48.2554953, lng: 19.8963987 },
    { lat: 48.2555772, lng: 19.89689 },
    { lat: 48.2556479, lng: 19.8969369 },
    { lat: 48.2555386, lng: 19.8974314 },
    { lat: 48.2556392, lng: 19.8988505 },
    { lat: 48.2571336, lng: 19.8996742 },
    { lat: 48.2569405, lng: 19.901576 },
    { lat: 48.2570184, lng: 19.9026506 },
    { lat: 48.2570884, lng: 19.9030755 },
    { lat: 48.2574543, lng: 19.9037992 },
    { lat: 48.2578861, lng: 19.9049853 },
    { lat: 48.2580149, lng: 19.9061201 },
    { lat: 48.257661, lng: 19.908861 },
    { lat: 48.258585, lng: 19.909484 },
    { lat: 48.259589, lng: 19.909324 },
    { lat: 48.259922, lng: 19.909269 },
    { lat: 48.260235, lng: 19.909217 },
    { lat: 48.26054, lng: 19.909262 },
    { lat: 48.260993, lng: 19.909114 },
    { lat: 48.26123, lng: 19.909155 },
    { lat: 48.261845, lng: 19.908878 },
    { lat: 48.261886, lng: 19.908882 },
    { lat: 48.262076, lng: 19.908901 },
    { lat: 48.262106, lng: 19.908905 },
    { lat: 48.262662, lng: 19.908961 },
    { lat: 48.263226, lng: 19.909011 },
    { lat: 48.263383, lng: 19.909025 },
    { lat: 48.264548, lng: 19.909227 },
    { lat: 48.265035, lng: 19.909307 },
    { lat: 48.265434, lng: 19.909373 },
    { lat: 48.266173, lng: 19.909494 },
    { lat: 48.266528, lng: 19.909568 },
    { lat: 48.266887, lng: 19.909649 },
    { lat: 48.26701, lng: 19.909677 },
    { lat: 48.267519, lng: 19.909821 },
    { lat: 48.267811, lng: 19.909477 },
    { lat: 48.268082, lng: 19.909571 },
    { lat: 48.268195, lng: 19.909748 },
    { lat: 48.268733, lng: 19.910035 },
    { lat: 48.26912, lng: 19.910545 },
    { lat: 48.269273, lng: 19.910903 },
    { lat: 48.26933, lng: 19.911259 },
    { lat: 48.26935, lng: 19.912719 },
    { lat: 48.270203, lng: 19.912357 },
    { lat: 48.270993, lng: 19.911951 },
    { lat: 48.271402, lng: 19.911741 },
    { lat: 48.272198, lng: 19.91169 },
    { lat: 48.272691, lng: 19.911452 },
    { lat: 48.272874, lng: 19.911364 },
    { lat: 48.273324, lng: 19.911052 },
    { lat: 48.273918, lng: 19.9107 },
    { lat: 48.27394, lng: 19.910676 },
    { lat: 48.274163, lng: 19.910425 },
    { lat: 48.274413, lng: 19.910314 },
    { lat: 48.274809, lng: 19.909939 },
    { lat: 48.27508, lng: 19.909549 },
    { lat: 48.275479, lng: 19.909315 },
    { lat: 48.275683, lng: 19.908695 },
    { lat: 48.276247, lng: 19.908509 },
    { lat: 48.27622, lng: 19.907992 },
    { lat: 48.276433, lng: 19.907634 },
    { lat: 48.276637, lng: 19.907291 },
    { lat: 48.276842, lng: 19.907094 },
    { lat: 48.27684, lng: 19.90704 },
    { lat: 48.276837, lng: 19.906927 },
    { lat: 48.277518, lng: 19.905846 },
    { lat: 48.277591, lng: 19.905796 },
    { lat: 48.277631, lng: 19.905768 },
    { lat: 48.277836, lng: 19.905627 },
    { lat: 48.278382, lng: 19.905271 },
    { lat: 48.278858, lng: 19.905016 },
    { lat: 48.279328, lng: 19.904479 },
    { lat: 48.279489, lng: 19.904323 },
    { lat: 48.280085, lng: 19.90397 },
    { lat: 48.280953, lng: 19.903499 },
    { lat: 48.281472, lng: 19.903284 },
    { lat: 48.282281, lng: 19.903304 },
    { lat: 48.283218, lng: 19.903661 },
    { lat: 48.283518, lng: 19.9035605 },
    { lat: 48.283928, lng: 19.903495 },
    { lat: 48.284838, lng: 19.903844 },
    { lat: 48.285239, lng: 19.904478 },
    { lat: 48.285677, lng: 19.905553 },
    { lat: 48.285956, lng: 19.90489 },
    { lat: 48.286162, lng: 19.904043 },
    { lat: 48.286252, lng: 19.903463 },
    { lat: 48.286279, lng: 19.902811 },
    { lat: 48.286244, lng: 19.902071 },
    { lat: 48.286057, lng: 19.900846 },
    { lat: 48.286167, lng: 19.90032 },
    { lat: 48.286421, lng: 19.899104 },
    { lat: 48.286837, lng: 19.8983 },
    { lat: 48.287408, lng: 19.897623 },
    { lat: 48.288012, lng: 19.897012 },
    { lat: 48.288492, lng: 19.89659 },
    { lat: 48.288747, lng: 19.896027 },
    { lat: 48.28879, lng: 19.895931 },
    { lat: 48.288879, lng: 19.895737 },
    { lat: 48.288913, lng: 19.895659 },
    { lat: 48.288944, lng: 19.895588 },
    { lat: 48.28896, lng: 19.895552 },
    { lat: 48.289039, lng: 19.895373 },
    { lat: 48.289534, lng: 19.893771 },
    { lat: 48.289641, lng: 19.892738 },
    { lat: 48.289978, lng: 19.892078 },
    { lat: 48.290166, lng: 19.891522 },
    { lat: 48.290294, lng: 19.891138 },
    { lat: 48.290322, lng: 19.890564 },
    { lat: 48.2902, lng: 19.89039 },
    { lat: 48.290415, lng: 19.890024 },
    { lat: 48.290768, lng: 19.889036 },
    { lat: 48.290843, lng: 19.888827 },
    { lat: 48.291176, lng: 19.888105 },
    { lat: 48.291537, lng: 19.887948 },
    { lat: 48.291871, lng: 19.887939 },
    { lat: 48.292149, lng: 19.888148 },
    { lat: 48.292394, lng: 19.888332 },
    { lat: 48.293013, lng: 19.888653 },
    { lat: 48.293484, lng: 19.888712 },
    { lat: 48.293841, lng: 19.888534 },
    { lat: 48.294532, lng: 19.888025 },
    { lat: 48.294575, lng: 19.887915 },
    { lat: 48.294835, lng: 19.887237 },
    { lat: 48.295079, lng: 19.886439 },
    { lat: 48.295083, lng: 19.886392 },
    { lat: 48.295136, lng: 19.885771 },
    { lat: 48.29475, lng: 19.884489 },
    { lat: 48.295405, lng: 19.883878 },
    { lat: 48.295786, lng: 19.883425 },
    { lat: 48.296578, lng: 19.882335 },
    { lat: 48.296556, lng: 19.881879 },
    { lat: 48.29674, lng: 19.880911 },
    { lat: 48.296545, lng: 19.880535 },
    { lat: 48.2955515, lng: 19.8805601 },
    { lat: 48.2949303, lng: 19.8809965 },
    { lat: 48.2941657, lng: 19.8804285 },
    { lat: 48.29362, lng: 19.8799236 },
    { lat: 48.2921688, lng: 19.8793979 },
    { lat: 48.2917244, lng: 19.8788941 },
    { lat: 48.2907703, lng: 19.8773084 },
    { lat: 48.2903869, lng: 19.8765691 },
    { lat: 48.2889913, lng: 19.8751909 },
    { lat: 48.2879963, lng: 19.8745107 },
    { lat: 48.2875892, lng: 19.8744749 },
    { lat: 48.2867535, lng: 19.8738546 },
    { lat: 48.2860474, lng: 19.8724039 },
    { lat: 48.2854122, lng: 19.8703819 },
    { lat: 48.2849714, lng: 19.8675352 },
    { lat: 48.2846065, lng: 19.8657451 },
    { lat: 48.2845651, lng: 19.8644793 },
    { lat: 48.2844006, lng: 19.8632063 },
    { lat: 48.2838674, lng: 19.8620287 },
    { lat: 48.2829254, lng: 19.8614554 },
    { lat: 48.2816459, lng: 19.8600853 },
    { lat: 48.2813383, lng: 19.858346 },
    { lat: 48.2814327, lng: 19.8565768 },
    { lat: 48.2813043, lng: 19.8557192 },
    { lat: 48.2818317, lng: 19.853078 },
    { lat: 48.2818545, lng: 19.8526969 },
    { lat: 48.281683, lng: 19.8519387 },
    { lat: 48.2802429, lng: 19.8493423 },
    { lat: 48.2793045, lng: 19.8481008 },
    { lat: 48.2786039, lng: 19.8478204 },
    { lat: 48.2772715, lng: 19.8478271 },
    { lat: 48.2770162, lng: 19.8478365 },
    { lat: 48.2763783, lng: 19.8447012 },
    { lat: 48.2760919, lng: 19.844668 },
    { lat: 48.2759046, lng: 19.8445363 },
    { lat: 48.2757247, lng: 19.8445408 },
    { lat: 48.275313, lng: 19.8446603 },
    { lat: 48.2748931, lng: 19.8445927 },
    { lat: 48.2743652, lng: 19.8448586 },
    { lat: 48.2737339, lng: 19.8448248 },
    { lat: 48.2736405, lng: 19.8462255 },
    { lat: 48.273273, lng: 19.8462455 },
    { lat: 48.2727129, lng: 19.8461672 },
    { lat: 48.2721343, lng: 19.8464327 },
    { lat: 48.2715617, lng: 19.846583 },
    { lat: 48.2714409, lng: 19.8472243 },
    { lat: 48.2714232, lng: 19.8479291 },
    { lat: 48.2708326, lng: 19.8484422 },
    { lat: 48.2691683, lng: 19.8490583 },
    { lat: 48.268662, lng: 19.8490211 },
    { lat: 48.2684228, lng: 19.848857 },
    { lat: 48.2680591, lng: 19.8487613 },
    { lat: 48.2657532, lng: 19.8514095 },
    { lat: 48.2648557, lng: 19.8521982 },
    { lat: 48.2645094, lng: 19.853198 },
    { lat: 48.2644852, lng: 19.8539358 },
    { lat: 48.2643968, lng: 19.8543394 },
    { lat: 48.2636209, lng: 19.8551512 },
    { lat: 48.2634086, lng: 19.855634 },
    { lat: 48.2634263, lng: 19.8560148 },
    { lat: 48.2632746, lng: 19.8564408 },
    { lat: 48.263615, lng: 19.8567269 },
    { lat: 48.2632278, lng: 19.8572819 },
    { lat: 48.2618612, lng: 19.8570445 },
    { lat: 48.2617912, lng: 19.856889 },
    { lat: 48.2616, lng: 19.8569211 },
    { lat: 48.2615371, lng: 19.8578815 },
    { lat: 48.2611049, lng: 19.8585402 },
    { lat: 48.2605056, lng: 19.8588309 },
    { lat: 48.2599621, lng: 19.8591847 },
    { lat: 48.2598701, lng: 19.859632 },
    { lat: 48.2594171, lng: 19.8601767 },
    { lat: 48.2590678, lng: 19.8601729 },
    { lat: 48.2586505, lng: 19.8597469 },
    { lat: 48.2580373, lng: 19.8595584 },
    { lat: 48.2568496, lng: 19.8596302 },
    { lat: 48.256781, lng: 19.8599975 },
    { lat: 48.2562206, lng: 19.8598021 },
    { lat: 48.255792, lng: 19.8595051 },
    { lat: 48.2554134, lng: 19.8601012 },
    { lat: 48.2546795, lng: 19.861054 },
    { lat: 48.2543769, lng: 19.8613482 },
    { lat: 48.2538186, lng: 19.8616712 },
    { lat: 48.253524, lng: 19.8617087 },
  ],
  NovéHony: [
    { lat: 48.373875, lng: 19.850291 },
    { lat: 48.373906, lng: 19.85024 },
    { lat: 48.373956, lng: 19.850156 },
    { lat: 48.373962, lng: 19.850147 },
    { lat: 48.374296, lng: 19.848757 },
    { lat: 48.374452, lng: 19.84809 },
    { lat: 48.374543, lng: 19.847703 },
    { lat: 48.374558, lng: 19.847638 },
    { lat: 48.374714, lng: 19.846078 },
    { lat: 48.374829, lng: 19.844894 },
    { lat: 48.374908, lng: 19.844115 },
    { lat: 48.374923, lng: 19.844093 },
    { lat: 48.374944, lng: 19.844076 },
    { lat: 48.375182, lng: 19.843764 },
    { lat: 48.3755, lng: 19.843468 },
    { lat: 48.376031, lng: 19.842976 },
    { lat: 48.376528, lng: 19.842512 },
    { lat: 48.377088, lng: 19.841994 },
    { lat: 48.377192, lng: 19.841899 },
    { lat: 48.377558, lng: 19.841674 },
    { lat: 48.378124, lng: 19.841332 },
    { lat: 48.378248, lng: 19.841256 },
    { lat: 48.3783, lng: 19.841226 },
    { lat: 48.37867, lng: 19.840936 },
    { lat: 48.37875, lng: 19.840878 },
    { lat: 48.379253, lng: 19.840512 },
    { lat: 48.379479, lng: 19.840366 },
    { lat: 48.379849, lng: 19.840127 },
    { lat: 48.380089, lng: 19.839963 },
    { lat: 48.380154, lng: 19.839895 },
    { lat: 48.380606, lng: 19.839413 },
    { lat: 48.380907, lng: 19.839093 },
    { lat: 48.381157, lng: 19.838818 },
    { lat: 48.38185, lng: 19.838203 },
    { lat: 48.381984, lng: 19.838006 },
    { lat: 48.382282, lng: 19.837564 },
    { lat: 48.382449, lng: 19.837204 },
    { lat: 48.382654, lng: 19.836764 },
    { lat: 48.382824, lng: 19.836389 },
    { lat: 48.382899, lng: 19.83625 },
    { lat: 48.382979, lng: 19.836105 },
    { lat: 48.383604, lng: 19.834956 },
    { lat: 48.383832, lng: 19.834528 },
    { lat: 48.383939, lng: 19.834581 },
    { lat: 48.384471, lng: 19.834804 },
    { lat: 48.384939, lng: 19.83499 },
    { lat: 48.385125, lng: 19.835065 },
    { lat: 48.385457, lng: 19.835241 },
    { lat: 48.385796, lng: 19.835417 },
    { lat: 48.386091, lng: 19.835404 },
    { lat: 48.386416, lng: 19.835403 },
    { lat: 48.386699, lng: 19.835392 },
    { lat: 48.387278, lng: 19.834847 },
    { lat: 48.3873, lng: 19.834827 },
    { lat: 48.387844, lng: 19.834311 },
    { lat: 48.388109, lng: 19.834056 },
    { lat: 48.388314, lng: 19.833866 },
    { lat: 48.388439, lng: 19.833744 },
    { lat: 48.388793, lng: 19.833642 },
    { lat: 48.389225, lng: 19.833525 },
    { lat: 48.389746, lng: 19.833374 },
    { lat: 48.390296, lng: 19.833223 },
    { lat: 48.390602, lng: 19.833222 },
    { lat: 48.390643, lng: 19.833225 },
    { lat: 48.391241, lng: 19.829579 },
    { lat: 48.3913, lng: 19.829229 },
    { lat: 48.391467, lng: 19.828176 },
    { lat: 48.391503, lng: 19.82796 },
    { lat: 48.391645, lng: 19.827062 },
    { lat: 48.39152, lng: 19.825799 },
    { lat: 48.391427, lng: 19.822864 },
    { lat: 48.391532, lng: 19.821491 },
    { lat: 48.391614, lng: 19.820905 },
    { lat: 48.391618, lng: 19.820702 },
    { lat: 48.391621, lng: 19.820589 },
    { lat: 48.391898, lng: 19.819059 },
    { lat: 48.391766, lng: 19.818173 },
    { lat: 48.39176, lng: 19.818131 },
    { lat: 48.391404, lng: 19.81825 },
    { lat: 48.391257, lng: 19.81825 },
    { lat: 48.391106, lng: 19.818325 },
    { lat: 48.390584, lng: 19.818345 },
    { lat: 48.390214, lng: 19.818406 },
    { lat: 48.390062, lng: 19.818401 },
    { lat: 48.389655, lng: 19.818509 },
    { lat: 48.389534, lng: 19.818638 },
    { lat: 48.388719, lng: 19.818946 },
    { lat: 48.388544, lng: 19.818965 },
    { lat: 48.388334, lng: 19.818949 },
    { lat: 48.388195, lng: 19.818977 },
    { lat: 48.387927, lng: 19.818927 },
    { lat: 48.387794, lng: 19.818706 },
    { lat: 48.387757, lng: 19.818618 },
    { lat: 48.387771, lng: 19.818351 },
    { lat: 48.387903, lng: 19.818059 },
    { lat: 48.387375, lng: 19.816091 },
    { lat: 48.387166, lng: 19.812354 },
    { lat: 48.38716, lng: 19.81226 },
    { lat: 48.387158, lng: 19.812204 },
    { lat: 48.386985, lng: 19.809131 },
    { lat: 48.38697, lng: 19.808864 },
    { lat: 48.386954, lng: 19.808574 },
    { lat: 48.386878, lng: 19.80818 },
    { lat: 48.386804, lng: 19.807745 },
    { lat: 48.386755, lng: 19.807366 },
    { lat: 48.386742, lng: 19.807067 },
    { lat: 48.386859, lng: 19.806704 },
    { lat: 48.387075, lng: 19.806419 },
    { lat: 48.387343, lng: 19.806177 },
    { lat: 48.387734, lng: 19.805916 },
    { lat: 48.387999, lng: 19.80573 },
    { lat: 48.38824, lng: 19.805535 },
    { lat: 48.388815, lng: 19.805089 },
    { lat: 48.38905, lng: 19.804881 },
    { lat: 48.389357, lng: 19.804669 },
    { lat: 48.389586, lng: 19.804425 },
    { lat: 48.389795, lng: 19.804253 },
    { lat: 48.390059, lng: 19.804012 },
    { lat: 48.390396, lng: 19.803716 },
    { lat: 48.390736, lng: 19.803282 },
    { lat: 48.391196, lng: 19.80267 },
    { lat: 48.391505, lng: 19.8022 },
    { lat: 48.391737, lng: 19.801836 },
    { lat: 48.391923, lng: 19.801406 },
    { lat: 48.392111, lng: 19.800913 },
    { lat: 48.392298, lng: 19.800469 },
    { lat: 48.392489, lng: 19.800272 },
    { lat: 48.392495, lng: 19.799987 },
    { lat: 48.392465, lng: 19.79978 },
    { lat: 48.39239, lng: 19.79958 },
    { lat: 48.392252, lng: 19.799221 },
    { lat: 48.392101, lng: 19.798849 },
    { lat: 48.391966, lng: 19.798525 },
    { lat: 48.391842, lng: 19.798263 },
    { lat: 48.391722, lng: 19.798019 },
    { lat: 48.391595, lng: 19.797827 },
    { lat: 48.391423, lng: 19.797625 },
    { lat: 48.391263, lng: 19.797455 },
    { lat: 48.391129, lng: 19.797322 },
    { lat: 48.390929, lng: 19.797121 },
    { lat: 48.390913, lng: 19.797097 },
    { lat: 48.390625, lng: 19.796832 },
    { lat: 48.390337, lng: 19.796552 },
    { lat: 48.389912, lng: 19.796104 },
    { lat: 48.389824, lng: 19.796001 },
    { lat: 48.389765, lng: 19.795916 },
    { lat: 48.389694, lng: 19.795762 },
    { lat: 48.389632, lng: 19.795587 },
    { lat: 48.389564, lng: 19.795279 },
    { lat: 48.389517, lng: 19.795059 },
    { lat: 48.389393, lng: 19.79448 },
    { lat: 48.389308, lng: 19.794121 },
    { lat: 48.389231, lng: 19.793764 },
    { lat: 48.389156, lng: 19.793418 },
    { lat: 48.389086, lng: 19.793069 },
    { lat: 48.388989, lng: 19.792632 },
    { lat: 48.388928, lng: 19.792293 },
    { lat: 48.388858, lng: 19.791998 },
    { lat: 48.388801, lng: 19.791739 },
    { lat: 48.38873, lng: 19.791468 },
    { lat: 48.388662, lng: 19.791169 },
    { lat: 48.388623, lng: 19.791003 },
    { lat: 48.388577, lng: 19.790781 },
    { lat: 48.388539, lng: 19.790581 },
    { lat: 48.388516, lng: 19.790434 },
    { lat: 48.388451, lng: 19.790218 },
    { lat: 48.388364, lng: 19.79005 },
    { lat: 48.388257, lng: 19.789852 },
    { lat: 48.38809, lng: 19.789614 },
    { lat: 48.387918, lng: 19.789316 },
    { lat: 48.387791, lng: 19.788991 },
    { lat: 48.387709, lng: 19.788763 },
    { lat: 48.387661, lng: 19.788585 },
    { lat: 48.387575, lng: 19.78831 },
    { lat: 48.387463, lng: 19.787967 },
    { lat: 48.387336, lng: 19.787587 },
    { lat: 48.387199, lng: 19.787259 },
    { lat: 48.387165, lng: 19.786973 },
    { lat: 48.387139, lng: 19.78659 },
    { lat: 48.387146, lng: 19.786238 },
    { lat: 48.387148, lng: 19.78604 },
    { lat: 48.387148, lng: 19.785946 },
    { lat: 48.387123, lng: 19.785341 },
    { lat: 48.387111, lng: 19.785165 },
    { lat: 48.387024, lng: 19.785026 },
    { lat: 48.387014, lng: 19.784959 },
    { lat: 48.386819, lng: 19.785123 },
    { lat: 48.386673, lng: 19.785253 },
    { lat: 48.386344, lng: 19.785696 },
    { lat: 48.385917, lng: 19.786052 },
    { lat: 48.385384, lng: 19.786333 },
    { lat: 48.384857, lng: 19.786482 },
    { lat: 48.384566, lng: 19.786482 },
    { lat: 48.384366, lng: 19.786339 },
    { lat: 48.38419, lng: 19.786046 },
    { lat: 48.384036, lng: 19.78551 },
    { lat: 48.383929, lng: 19.784778 },
    { lat: 48.383843, lng: 19.784399 },
    { lat: 48.383775, lng: 19.783742 },
    { lat: 48.383729, lng: 19.78356 },
    { lat: 48.383664, lng: 19.783404 },
    { lat: 48.383583, lng: 19.783267 },
    { lat: 48.383511, lng: 19.783202 },
    { lat: 48.383212, lng: 19.783051 },
    { lat: 48.383127, lng: 19.782998 },
    { lat: 48.383044, lng: 19.782941 },
    { lat: 48.382749, lng: 19.782548 },
    { lat: 48.38247, lng: 19.782335 },
    { lat: 48.382478, lng: 19.782314 },
    { lat: 48.382508, lng: 19.782224 },
    { lat: 48.38196, lng: 19.78174 },
    { lat: 48.381841, lng: 19.781637 },
    { lat: 48.380003, lng: 19.780023 },
    { lat: 48.379988, lng: 19.780013 },
    { lat: 48.3797621, lng: 19.7814381 },
    { lat: 48.3794254, lng: 19.7825681 },
    { lat: 48.3793184, lng: 19.7828526 },
    { lat: 48.3787125, lng: 19.7838051 },
    { lat: 48.378255, lng: 19.7843179 },
    { lat: 48.3777229, lng: 19.7847437 },
    { lat: 48.3761662, lng: 19.7857768 },
    { lat: 48.3753508, lng: 19.7869923 },
    { lat: 48.3738861, lng: 19.7886296 },
    { lat: 48.3737868, lng: 19.788547 },
    { lat: 48.3737433, lng: 19.7887933 },
    { lat: 48.3736236, lng: 19.7888346 },
    { lat: 48.3736197, lng: 19.7890256 },
    { lat: 48.3734102, lng: 19.7890017 },
    { lat: 48.3733432, lng: 19.789144 },
    { lat: 48.3732702, lng: 19.7891541 },
    { lat: 48.3732716, lng: 19.7894169 },
    { lat: 48.373195, lng: 19.7895432 },
    { lat: 48.3712001, lng: 19.7887321 },
    { lat: 48.3705038, lng: 19.7887575 },
    { lat: 48.369666, lng: 19.7885888 },
    { lat: 48.3688832, lng: 19.7879771 },
    { lat: 48.3683385, lng: 19.7874287 },
    { lat: 48.3673209, lng: 19.7865526 },
    { lat: 48.3662071, lng: 19.7852825 },
    { lat: 48.3649291, lng: 19.7844294 },
    { lat: 48.3643245, lng: 19.7856914 },
    { lat: 48.3638112, lng: 19.7865493 },
    { lat: 48.3633387, lng: 19.7875888 },
    { lat: 48.3630208, lng: 19.7884579 },
    { lat: 48.3627834, lng: 19.7888998 },
    { lat: 48.3620057, lng: 19.7899475 },
    { lat: 48.3611623, lng: 19.7908004 },
    { lat: 48.3609356, lng: 19.7908473 },
    { lat: 48.3609569, lng: 19.7910528 },
    { lat: 48.3603191, lng: 19.794744 },
    { lat: 48.3602347, lng: 19.7952575 },
    { lat: 48.3602377, lng: 19.795401 },
    { lat: 48.3604134, lng: 19.7981239 },
    { lat: 48.3597311, lng: 19.8011388 },
    { lat: 48.359203, lng: 19.8091235 },
    { lat: 48.3590268, lng: 19.8116826 },
    { lat: 48.3589689, lng: 19.8132754 },
    { lat: 48.3590412, lng: 19.8142789 },
    { lat: 48.3591984, lng: 19.8151424 },
    { lat: 48.3593703, lng: 19.8182004 },
    { lat: 48.3592661, lng: 19.8207457 },
    { lat: 48.3572353, lng: 19.827621 },
    { lat: 48.3563627, lng: 19.8300141 },
    { lat: 48.3553383, lng: 19.8324086 },
    { lat: 48.3541788, lng: 19.8360811 },
    { lat: 48.3536638, lng: 19.8357635 },
    { lat: 48.3519464, lng: 19.8397434 },
    { lat: 48.3528605, lng: 19.8426515 },
    { lat: 48.3505901, lng: 19.8452371 },
    { lat: 48.3503554, lng: 19.8450242 },
    { lat: 48.3502569, lng: 19.844855 },
    { lat: 48.3500899, lng: 19.8448472 },
    { lat: 48.3499679, lng: 19.8451255 },
    { lat: 48.3497912, lng: 19.8451828 },
    { lat: 48.3496596, lng: 19.845343 },
    { lat: 48.3494312, lng: 19.8453254 },
    { lat: 48.3487639, lng: 19.8456042 },
    { lat: 48.3485687, lng: 19.8455946 },
    { lat: 48.3488775, lng: 19.8461151 },
    { lat: 48.3488602, lng: 19.8462599 },
    { lat: 48.347648, lng: 19.8539933 },
    { lat: 48.347512, lng: 19.85412 },
    { lat: 48.347525, lng: 19.854131 },
    { lat: 48.347933, lng: 19.854515 },
    { lat: 48.347959, lng: 19.855093 },
    { lat: 48.348037, lng: 19.856837 },
    { lat: 48.348063, lng: 19.857431 },
    { lat: 48.348218, lng: 19.857579 },
    { lat: 48.3488764, lng: 19.8582106 },
    { lat: 48.349792, lng: 19.859089 },
    { lat: 48.351397, lng: 19.860628 },
    { lat: 48.355459, lng: 19.864526 },
    { lat: 48.355519, lng: 19.864583 },
    { lat: 48.356031, lng: 19.865075 },
    { lat: 48.356069, lng: 19.865111 },
    { lat: 48.356158, lng: 19.865007 },
    { lat: 48.356239, lng: 19.864536 },
    { lat: 48.356234, lng: 19.864152 },
    { lat: 48.356301, lng: 19.864007 },
    { lat: 48.356272, lng: 19.863866 },
    { lat: 48.356501, lng: 19.86341 },
    { lat: 48.356652, lng: 19.863271 },
    { lat: 48.356768, lng: 19.86304 },
    { lat: 48.356802, lng: 19.862839 },
    { lat: 48.356916, lng: 19.862561 },
    { lat: 48.357095, lng: 19.862259 },
    { lat: 48.357329, lng: 19.862021 },
    { lat: 48.35755, lng: 19.861772 },
    { lat: 48.357982, lng: 19.861161 },
    { lat: 48.358502, lng: 19.860415 },
    { lat: 48.358948, lng: 19.85977 },
    { lat: 48.359252, lng: 19.859345 },
    { lat: 48.35926, lng: 19.859365 },
    { lat: 48.359301, lng: 19.859303 },
    { lat: 48.359295, lng: 19.859285 },
    { lat: 48.359713, lng: 19.858728 },
    { lat: 48.359721, lng: 19.858734 },
    { lat: 48.359737, lng: 19.858711 },
    { lat: 48.3598427, lng: 19.8584437 },
    { lat: 48.3598416, lng: 19.8578193 },
    { lat: 48.3596485, lng: 19.8571619 },
    { lat: 48.3607342, lng: 19.8551731 },
    { lat: 48.3604667, lng: 19.8548244 },
    { lat: 48.36188, lng: 19.851177 },
    { lat: 48.3625042, lng: 19.8505301 },
    { lat: 48.3631606, lng: 19.8503129 },
    { lat: 48.3658996, lng: 19.8484204 },
    { lat: 48.3666441, lng: 19.8477435 },
    { lat: 48.3672886, lng: 19.8469676 },
    { lat: 48.3682221, lng: 19.8477082 },
    { lat: 48.368894, lng: 19.8481598 },
    { lat: 48.3693619, lng: 19.8483479 },
    { lat: 48.3713587, lng: 19.8487741 },
    { lat: 48.3724354, lng: 19.8490809 },
    { lat: 48.3729711, lng: 19.8495603 },
    { lat: 48.37351, lng: 19.8497213 },
    { lat: 48.373875, lng: 19.850291 },
  ],
  Šávoľ: [
    { lat: 48.2924335, lng: 19.8285883 },
    { lat: 48.2928172, lng: 19.8292494 },
    { lat: 48.2935404, lng: 19.830565 },
    { lat: 48.2935758, lng: 19.8305233 },
    { lat: 48.2944439, lng: 19.8313129 },
    { lat: 48.2949167, lng: 19.8316129 },
    { lat: 48.2983498, lng: 19.8343283 },
    { lat: 48.29935, lng: 19.8350208 },
    { lat: 48.300003, lng: 19.8355569 },
    { lat: 48.3013063, lng: 19.834567 },
    { lat: 48.3031497, lng: 19.840182 },
    { lat: 48.3033195, lng: 19.8414191 },
    { lat: 48.3035166, lng: 19.8416464 },
    { lat: 48.3039726, lng: 19.842598 },
    { lat: 48.3049053, lng: 19.8441749 },
    { lat: 48.3050881, lng: 19.8442366 },
    { lat: 48.3068894, lng: 19.8478007 },
    { lat: 48.306956, lng: 19.8483236 },
    { lat: 48.3068901, lng: 19.8489579 },
    { lat: 48.3070547, lng: 19.8495561 },
    { lat: 48.307093, lng: 19.8498385 },
    { lat: 48.307255, lng: 19.8510337 },
    { lat: 48.3072952, lng: 19.8513304 },
    { lat: 48.3073911, lng: 19.8526084 },
    { lat: 48.3073578, lng: 19.8548765 },
    { lat: 48.3076529, lng: 19.856637 },
    { lat: 48.307788, lng: 19.8578298 },
    { lat: 48.3076687, lng: 19.8588454 },
    { lat: 48.3080425, lng: 19.8597723 },
    { lat: 48.3083505, lng: 19.8607724 },
    { lat: 48.308293, lng: 19.8617056 },
    { lat: 48.3094687, lng: 19.8645284 },
    { lat: 48.3099568, lng: 19.8662249 },
    { lat: 48.3108567, lng: 19.8676258 },
    { lat: 48.3110642, lng: 19.8684599 },
    { lat: 48.3109656, lng: 19.8712876 },
    { lat: 48.311534, lng: 19.8727298 },
    { lat: 48.3114126, lng: 19.874011 },
    { lat: 48.311444, lng: 19.874248 },
    { lat: 48.311479, lng: 19.874239 },
    { lat: 48.312529, lng: 19.873972 },
    { lat: 48.313083, lng: 19.874334 },
    { lat: 48.313331, lng: 19.874383 },
    { lat: 48.313585, lng: 19.874433 },
    { lat: 48.314101, lng: 19.874535 },
    { lat: 48.314577, lng: 19.874628 },
    { lat: 48.315547, lng: 19.874651 },
    { lat: 48.315939, lng: 19.874471 },
    { lat: 48.316048, lng: 19.874422 },
    { lat: 48.316269, lng: 19.87432 },
    { lat: 48.316319, lng: 19.874297 },
    { lat: 48.316389, lng: 19.874265 },
    { lat: 48.316597, lng: 19.874085 },
    { lat: 48.316848, lng: 19.873867 },
    { lat: 48.317287, lng: 19.87348 },
    { lat: 48.317475, lng: 19.87328 },
    { lat: 48.317556, lng: 19.873193 },
    { lat: 48.317666, lng: 19.873076 },
    { lat: 48.317843, lng: 19.872887 },
    { lat: 48.318095, lng: 19.872618 },
    { lat: 48.318283, lng: 19.872313 },
    { lat: 48.31838, lng: 19.872158 },
    { lat: 48.318507, lng: 19.871953 },
    { lat: 48.318998, lng: 19.871605 },
    { lat: 48.319663, lng: 19.871211 },
    { lat: 48.320482, lng: 19.871418 },
    { lat: 48.321394, lng: 19.871214 },
    { lat: 48.322367, lng: 19.871497 },
    { lat: 48.322664, lng: 19.871588 },
    { lat: 48.322791, lng: 19.871627 },
    { lat: 48.323535, lng: 19.871233 },
    { lat: 48.324578, lng: 19.870165 },
    { lat: 48.325014, lng: 19.86972 },
    { lat: 48.325097, lng: 19.869636 },
    { lat: 48.32537, lng: 19.869356 },
    { lat: 48.325397, lng: 19.869329 },
    { lat: 48.325469, lng: 19.869254 },
    { lat: 48.326109, lng: 19.868868 },
    { lat: 48.327548, lng: 19.867506 },
    { lat: 48.328347, lng: 19.865355 },
    { lat: 48.328759, lng: 19.864047 },
    { lat: 48.328639, lng: 19.863274 },
    { lat: 48.3278016, lng: 19.8618929 },
    { lat: 48.3271572, lng: 19.8597374 },
    { lat: 48.3272472, lng: 19.85576 },
    { lat: 48.3272343, lng: 19.8552287 },
    { lat: 48.3272003, lng: 19.853821 },
    { lat: 48.3266348, lng: 19.852395 },
    { lat: 48.3261564, lng: 19.8503694 },
    { lat: 48.3260175, lng: 19.8497814 },
    { lat: 48.3260089, lng: 19.8496476 },
    { lat: 48.325862, lng: 19.847366 },
    { lat: 48.3253445, lng: 19.8440425 },
    { lat: 48.325032, lng: 19.8429207 },
    { lat: 48.3247587, lng: 19.8421538 },
    { lat: 48.3245843, lng: 19.84146 },
    { lat: 48.3242272, lng: 19.8407615 },
    { lat: 48.3234653, lng: 19.8385548 },
    { lat: 48.3230515, lng: 19.8358837 },
    { lat: 48.3224801, lng: 19.8336069 },
    { lat: 48.3222021, lng: 19.8331212 },
    { lat: 48.322106, lng: 19.8328014 },
    { lat: 48.3219583, lng: 19.8325384 },
    { lat: 48.3214431, lng: 19.8310596 },
    { lat: 48.3223522, lng: 19.8290929 },
    { lat: 48.3225471, lng: 19.8285017 },
    { lat: 48.3226124, lng: 19.8279852 },
    { lat: 48.3228918, lng: 19.8273387 },
    { lat: 48.3234905, lng: 19.8256459 },
    { lat: 48.3230816, lng: 19.8246166 },
    { lat: 48.3228582, lng: 19.8237846 },
    { lat: 48.3228243, lng: 19.8229058 },
    { lat: 48.322904, lng: 19.8228693 },
    { lat: 48.3228025, lng: 19.8218594 },
    { lat: 48.3226708, lng: 19.821273 },
    { lat: 48.3221417, lng: 19.820662 },
    { lat: 48.3216222, lng: 19.8201944 },
    { lat: 48.3215249, lng: 19.8200323 },
    { lat: 48.3202494, lng: 19.8192065 },
    { lat: 48.3197094, lng: 19.8184762 },
    { lat: 48.3193753, lng: 19.8181519 },
    { lat: 48.3191878, lng: 19.817806 },
    { lat: 48.3181719, lng: 19.8164242 },
    { lat: 48.3177593, lng: 19.8163664 },
    { lat: 48.3170222, lng: 19.8154849 },
    { lat: 48.3165852, lng: 19.8150766 },
    { lat: 48.3163066, lng: 19.8149401 },
    { lat: 48.3162448, lng: 19.8147628 },
    { lat: 48.3158518, lng: 19.8144596 },
    { lat: 48.3132171, lng: 19.8124655 },
    { lat: 48.3106949, lng: 19.8121888 },
    { lat: 48.3095941, lng: 19.8113653 },
    { lat: 48.3068494, lng: 19.8131093 },
    { lat: 48.3036483, lng: 19.8163747 },
    { lat: 48.3034471, lng: 19.8159996 },
    { lat: 48.3033054, lng: 19.815493 },
    { lat: 48.3027185, lng: 19.8145153 },
    { lat: 48.3025337, lng: 19.8143084 },
    { lat: 48.3023405, lng: 19.8137559 },
    { lat: 48.3020707, lng: 19.8135624 },
    { lat: 48.3010175, lng: 19.814701 },
    { lat: 48.3009158, lng: 19.8144852 },
    { lat: 48.3008714, lng: 19.8145281 },
    { lat: 48.3008344, lng: 19.8144557 },
    { lat: 48.3003557, lng: 19.8148931 },
    { lat: 48.2995081, lng: 19.815194 },
    { lat: 48.2971516, lng: 19.8155651 },
    { lat: 48.2966621, lng: 19.815218 },
    { lat: 48.2953693, lng: 19.8126833 },
    { lat: 48.2949783, lng: 19.8132766 },
    { lat: 48.2945539, lng: 19.8143673 },
    { lat: 48.2943873, lng: 19.8145741 },
    { lat: 48.2940169, lng: 19.8145528 },
    { lat: 48.2939874, lng: 19.8146289 },
    { lat: 48.2926798, lng: 19.8179604 },
    { lat: 48.291454, lng: 19.8198377 },
    { lat: 48.2915612, lng: 19.8207983 },
    { lat: 48.2916143, lng: 19.820901 },
    { lat: 48.2915899, lng: 19.8210657 },
    { lat: 48.2914859, lng: 19.8211881 },
    { lat: 48.2915634, lng: 19.8214373 },
    { lat: 48.2916746, lng: 19.8216118 },
    { lat: 48.2916007, lng: 19.8218812 },
    { lat: 48.2916384, lng: 19.822122 },
    { lat: 48.2915183, lng: 19.8223945 },
    { lat: 48.2912938, lng: 19.822362 },
    { lat: 48.2911178, lng: 19.8224299 },
    { lat: 48.2911032, lng: 19.8225385 },
    { lat: 48.2914411, lng: 19.8226363 },
    { lat: 48.2915189, lng: 19.8228437 },
    { lat: 48.2915204, lng: 19.8229823 },
    { lat: 48.2912075, lng: 19.8230653 },
    { lat: 48.2908145, lng: 19.8234846 },
    { lat: 48.2909679, lng: 19.8237214 },
    { lat: 48.2910058, lng: 19.8240683 },
    { lat: 48.2910945, lng: 19.8241922 },
    { lat: 48.2912238, lng: 19.824083 },
    { lat: 48.2913671, lng: 19.8242918 },
    { lat: 48.2916752, lng: 19.8252108 },
    { lat: 48.2921137, lng: 19.8261148 },
    { lat: 48.2921823, lng: 19.826372 },
    { lat: 48.2921638, lng: 19.8272144 },
    { lat: 48.2921944, lng: 19.8275259 },
    { lat: 48.2924284, lng: 19.8276983 },
    { lat: 48.2924168, lng: 19.8280012 },
    { lat: 48.2924952, lng: 19.8282238 },
    { lat: 48.2924335, lng: 19.8285883 },
  ],
  Ratka: [
    { lat: 48.2743916, lng: 19.7646611 },
    { lat: 48.2732813, lng: 19.7627692 },
    { lat: 48.2707455, lng: 19.7618115 },
    { lat: 48.2705455, lng: 19.7611619 },
    { lat: 48.2707916, lng: 19.7592369 },
    { lat: 48.2684909, lng: 19.7561597 },
    { lat: 48.2682783, lng: 19.7557904 },
    { lat: 48.2687922, lng: 19.7544672 },
    { lat: 48.2698822, lng: 19.753411 },
    { lat: 48.2695303, lng: 19.7525135 },
    { lat: 48.2677818, lng: 19.7518072 },
    { lat: 48.2656116, lng: 19.7532782 },
    { lat: 48.2649507, lng: 19.753414 },
    { lat: 48.2634213, lng: 19.7520576 },
    { lat: 48.2624768, lng: 19.7526102 },
    { lat: 48.2620024, lng: 19.7523328 },
    { lat: 48.2592789, lng: 19.7531602 },
    { lat: 48.2568027, lng: 19.751597 },
    { lat: 48.2561493, lng: 19.7513929 },
    { lat: 48.255699, lng: 19.7503845 },
    { lat: 48.2548039, lng: 19.7494108 },
    { lat: 48.2545872, lng: 19.7492531 },
    { lat: 48.2535443, lng: 19.7494375 },
    { lat: 48.2526881, lng: 19.7486531 },
    { lat: 48.2515973, lng: 19.7483466 },
    { lat: 48.2510252, lng: 19.7469297 },
    { lat: 48.2496247, lng: 19.7442163 },
    { lat: 48.2490201, lng: 19.7439262 },
    { lat: 48.2482665, lng: 19.744054 },
    { lat: 48.248228, lng: 19.7464348 },
    { lat: 48.2483782, lng: 19.7479395 },
    { lat: 48.2482005, lng: 19.7489622 },
    { lat: 48.2479425, lng: 19.7497451 },
    { lat: 48.2478916, lng: 19.7504024 },
    { lat: 48.2479708, lng: 19.7520609 },
    { lat: 48.2477734, lng: 19.7529561 },
    { lat: 48.247261, lng: 19.7537392 },
    { lat: 48.2458929, lng: 19.754621 },
    { lat: 48.2446097, lng: 19.7569638 },
    { lat: 48.2442674, lng: 19.7574026 },
    { lat: 48.2435428, lng: 19.7581582 },
    { lat: 48.2432994, lng: 19.75892 },
    { lat: 48.2436367, lng: 19.7599745 },
    { lat: 48.2439333, lng: 19.7638989 },
    { lat: 48.2445015, lng: 19.7653589 },
    { lat: 48.2448012, lng: 19.76676 },
    { lat: 48.2455888, lng: 19.7679541 },
    { lat: 48.2452408, lng: 19.7712437 },
    { lat: 48.2447424, lng: 19.7715699 },
    { lat: 48.2444462, lng: 19.7711401 },
    { lat: 48.2442723, lng: 19.7710519 },
    { lat: 48.2440973, lng: 19.7707883 },
    { lat: 48.243918, lng: 19.7707113 },
    { lat: 48.2434826, lng: 19.7703434 },
    { lat: 48.243219, lng: 19.7702645 },
    { lat: 48.2424484, lng: 19.7698114 },
    { lat: 48.2420631, lng: 19.7697591 },
    { lat: 48.2413453, lng: 19.7710542 },
    { lat: 48.238528, lng: 19.7695938 },
    { lat: 48.2375913, lng: 19.7713901 },
    { lat: 48.2372737, lng: 19.7716244 },
    { lat: 48.2362929, lng: 19.7718922 },
    { lat: 48.2357824, lng: 19.7718145 },
    { lat: 48.2345313, lng: 19.7718031 },
    { lat: 48.2334371, lng: 19.7721814 },
    { lat: 48.233089, lng: 19.772793 },
    { lat: 48.2326826, lng: 19.7737432 },
    { lat: 48.2322639, lng: 19.7761763 },
    { lat: 48.2324571, lng: 19.7783072 },
    { lat: 48.2299729, lng: 19.7792268 },
    { lat: 48.2296765, lng: 19.7797921 },
    { lat: 48.2294284, lng: 19.7796598 },
    { lat: 48.226971, lng: 19.7783533 },
    { lat: 48.2268132, lng: 19.7790141 },
    { lat: 48.2265052, lng: 19.7797219 },
    { lat: 48.2262411, lng: 19.7798894 },
    { lat: 48.2262296, lng: 19.7797856 },
    { lat: 48.2257822, lng: 19.7799079 },
    { lat: 48.2257932, lng: 19.7800294 },
    { lat: 48.2255967, lng: 19.7804004 },
    { lat: 48.2254668, lng: 19.7809295 },
    { lat: 48.2256056, lng: 19.7816737 },
    { lat: 48.2263861, lng: 19.7830473 },
    { lat: 48.2264, lng: 19.7841544 },
    { lat: 48.2263335, lng: 19.7843828 },
    { lat: 48.2258973, lng: 19.7847906 },
    { lat: 48.2261211, lng: 19.7856211 },
    { lat: 48.2262883, lng: 19.7871691 },
    { lat: 48.2268908, lng: 19.7884915 },
    { lat: 48.2281188, lng: 19.7897162 },
    { lat: 48.2285406, lng: 19.7901357 },
    { lat: 48.2293992, lng: 19.791315 },
    { lat: 48.2296754, lng: 19.7921347 },
    { lat: 48.2299892, lng: 19.7924575 },
    { lat: 48.230186, lng: 19.7931625 },
    { lat: 48.2298208, lng: 19.7959519 },
    { lat: 48.23024, lng: 19.7978494 },
    { lat: 48.2305419, lng: 19.7986649 },
    { lat: 48.2308657, lng: 19.7992157 },
    { lat: 48.2310618, lng: 19.7998049 },
    { lat: 48.2312718, lng: 19.8013055 },
    { lat: 48.2315128, lng: 19.8019655 },
    { lat: 48.2317956, lng: 19.8025063 },
    { lat: 48.2319618, lng: 19.8031076 },
    { lat: 48.2324636, lng: 19.8037063 },
    { lat: 48.232843, lng: 19.8038301 },
    { lat: 48.2333057, lng: 19.804362 },
    { lat: 48.2336749, lng: 19.805431 },
    { lat: 48.2336204, lng: 19.8067807 },
    { lat: 48.2332758, lng: 19.8083207 },
    { lat: 48.2333448, lng: 19.8082266 },
    { lat: 48.234945, lng: 19.8074141 },
    { lat: 48.2356509, lng: 19.8073008 },
    { lat: 48.2364863, lng: 19.8063338 },
    { lat: 48.2370758, lng: 19.8058824 },
    { lat: 48.2382621, lng: 19.8052625 },
    { lat: 48.2392497, lng: 19.8043531 },
    { lat: 48.2399243, lng: 19.8033463 },
    { lat: 48.2409355, lng: 19.8023352 },
    { lat: 48.2430005, lng: 19.8007909 },
    { lat: 48.2440561, lng: 19.8003498 },
    { lat: 48.2441511, lng: 19.8003846 },
    { lat: 48.2451681, lng: 19.799481 },
    { lat: 48.2466767, lng: 19.7969174 },
    { lat: 48.2470748, lng: 19.7958994 },
    { lat: 48.2476598, lng: 19.795344 },
    { lat: 48.2481542, lng: 19.7954137 },
    { lat: 48.2491004, lng: 19.795027 },
    { lat: 48.2503275, lng: 19.7932368 },
    { lat: 48.2514235, lng: 19.793105 },
    { lat: 48.2523597, lng: 19.7933308 },
    { lat: 48.2531806, lng: 19.7929882 },
    { lat: 48.2542116, lng: 19.7924495 },
    { lat: 48.2554129, lng: 19.7925113 },
    { lat: 48.2568918, lng: 19.7924513 },
    { lat: 48.2571622, lng: 19.7918373 },
    { lat: 48.2599683, lng: 19.7876768 },
    { lat: 48.2603953, lng: 19.7869076 },
    { lat: 48.2613236, lng: 19.786602 },
    { lat: 48.2616589, lng: 19.7862061 },
    { lat: 48.262119, lng: 19.7860401 },
    { lat: 48.2627114, lng: 19.7852411 },
    { lat: 48.2637382, lng: 19.7834841 },
    { lat: 48.2648265, lng: 19.7840444 },
    { lat: 48.2652476, lng: 19.7837326 },
    { lat: 48.2664235, lng: 19.7826266 },
    { lat: 48.2673461, lng: 19.7821117 },
    { lat: 48.2681249, lng: 19.7819102 },
    { lat: 48.2684992, lng: 19.7817353 },
    { lat: 48.268858, lng: 19.7807273 },
    { lat: 48.2699099, lng: 19.7787277 },
    { lat: 48.2711756, lng: 19.7760041 },
    { lat: 48.2730184, lng: 19.7735918 },
    { lat: 48.273799, lng: 19.7721075 },
    { lat: 48.2744849, lng: 19.7702637 },
    { lat: 48.274634, lng: 19.768744 },
    { lat: 48.2745513, lng: 19.7668745 },
    { lat: 48.2743695, lng: 19.7659974 },
    { lat: 48.2743916, lng: 19.7646611 },
  ],
  Lehôtka: [
    { lat: 48.3526184, lng: 19.5217059 },
    { lat: 48.3526452, lng: 19.5216733 },
    { lat: 48.3507531, lng: 19.5185374 },
    { lat: 48.3502674, lng: 19.5189055 },
    { lat: 48.3499236, lng: 19.5189251 },
    { lat: 48.3496586, lng: 19.5190678 },
    { lat: 48.3495406, lng: 19.5190497 },
    { lat: 48.3473476, lng: 19.5128009 },
    { lat: 48.3472445, lng: 19.5131082 },
    { lat: 48.3468707, lng: 19.5131862 },
    { lat: 48.3467609, lng: 19.5132972 },
    { lat: 48.3466635, lng: 19.5135292 },
    { lat: 48.3464588, lng: 19.5136366 },
    { lat: 48.3463522, lng: 19.5138907 },
    { lat: 48.3462029, lng: 19.5140013 },
    { lat: 48.3460368, lng: 19.5140151 },
    { lat: 48.3458344, lng: 19.5142686 },
    { lat: 48.3455364, lng: 19.5143553 },
    { lat: 48.3453772, lng: 19.5146861 },
    { lat: 48.3452115, lng: 19.5147325 },
    { lat: 48.3450206, lng: 19.5150684 },
    { lat: 48.3447301, lng: 19.5150013 },
    { lat: 48.3445805, lng: 19.5147834 },
    { lat: 48.3443495, lng: 19.5150527 },
    { lat: 48.3442307, lng: 19.5149963 },
    { lat: 48.3438298, lng: 19.5149985 },
    { lat: 48.3436786, lng: 19.5150476 },
    { lat: 48.3435247, lng: 19.5152475 },
    { lat: 48.3434057, lng: 19.5152135 },
    { lat: 48.343346, lng: 19.5149693 },
    { lat: 48.3432189, lng: 19.514928 },
    { lat: 48.3430859, lng: 19.5149985 },
    { lat: 48.3429084, lng: 19.5149377 },
    { lat: 48.3427253, lng: 19.5149979 },
    { lat: 48.34245, lng: 19.5148725 },
    { lat: 48.3423357, lng: 19.5147389 },
    { lat: 48.3421671, lng: 19.5147706 },
    { lat: 48.3419968, lng: 19.5146007 },
    { lat: 48.3419306, lng: 19.514604 },
    { lat: 48.3418613, lng: 19.514714 },
    { lat: 48.3417785, lng: 19.5147067 },
    { lat: 48.3415876, lng: 19.5145456 },
    { lat: 48.3415632, lng: 19.5147731 },
    { lat: 48.3414995, lng: 19.5148824 },
    { lat: 48.3411864, lng: 19.5151786 },
    { lat: 48.3408043, lng: 19.5151771 },
    { lat: 48.3406325, lng: 19.5153136 },
    { lat: 48.3405355, lng: 19.5153131 },
    { lat: 48.3404805, lng: 19.5151924 },
    { lat: 48.3403781, lng: 19.515123 },
    { lat: 48.3399827, lng: 19.5151271 },
    { lat: 48.3397696, lng: 19.515324 },
    { lat: 48.3394807, lng: 19.5152861 },
    { lat: 48.3393904, lng: 19.5155523 },
    { lat: 48.3392872, lng: 19.5156084 },
    { lat: 48.3390958, lng: 19.5155912 },
    { lat: 48.3390668, lng: 19.5156948 },
    { lat: 48.3391172, lng: 19.5158585 },
    { lat: 48.3391015, lng: 19.5161014 },
    { lat: 48.3390579, lng: 19.5161587 },
    { lat: 48.3389152, lng: 19.5160213 },
    { lat: 48.3388222, lng: 19.5160313 },
    { lat: 48.3382565, lng: 19.516736 },
    { lat: 48.3380615, lng: 19.5166678 },
    { lat: 48.3379649, lng: 19.5168203 },
    { lat: 48.3377865, lng: 19.5168842 },
    { lat: 48.3376743, lng: 19.517016 },
    { lat: 48.3374278, lng: 19.5169758 },
    { lat: 48.3368269, lng: 19.5174061 },
    { lat: 48.3363162, lng: 19.517571 },
    { lat: 48.3359072, lng: 19.5181814 },
    { lat: 48.3358426, lng: 19.518418 },
    { lat: 48.3357444, lng: 19.5185111 },
    { lat: 48.3355858, lng: 19.5184933 },
    { lat: 48.3355086, lng: 19.5185537 },
    { lat: 48.3354463, lng: 19.5189123 },
    { lat: 48.3352871, lng: 19.5192944 },
    { lat: 48.3350614, lng: 19.5194571 },
    { lat: 48.3349049, lng: 19.5196523 },
    { lat: 48.3346538, lng: 19.5198545 },
    { lat: 48.3343559, lng: 19.5199457 },
    { lat: 48.3341704, lng: 19.5198818 },
    { lat: 48.3339769, lng: 19.5199604 },
    { lat: 48.3338618, lng: 19.5201523 },
    { lat: 48.3333339, lng: 19.5204301 },
    { lat: 48.3331744, lng: 19.5204684 },
    { lat: 48.3330472, lng: 19.5204253 },
    { lat: 48.3329082, lng: 19.5207068 },
    { lat: 48.3328195, lng: 19.5207596 },
    { lat: 48.3326681, lng: 19.5207024 },
    { lat: 48.3326079, lng: 19.5205961 },
    { lat: 48.3324895, lng: 19.5205688 },
    { lat: 48.3323388, lng: 19.520701 },
    { lat: 48.3320758, lng: 19.5205435 },
    { lat: 48.331875, lng: 19.5205892 },
    { lat: 48.3314558, lng: 19.5209293 },
    { lat: 48.3311059, lng: 19.5208185 },
    { lat: 48.331007, lng: 19.5210693 },
    { lat: 48.3307714, lng: 19.5209957 },
    { lat: 48.3306173, lng: 19.5210479 },
    { lat: 48.3303442, lng: 19.5208008 },
    { lat: 48.330102, lng: 19.5208037 },
    { lat: 48.3297871, lng: 19.5209835 },
    { lat: 48.3296355, lng: 19.5209936 },
    { lat: 48.3295206, lng: 19.5208533 },
    { lat: 48.329382, lng: 19.5208756 },
    { lat: 48.3292604, lng: 19.5210476 },
    { lat: 48.3289792, lng: 19.5210588 },
    { lat: 48.3288821, lng: 19.5212014 },
    { lat: 48.3286449, lng: 19.5212863 },
    { lat: 48.3284605, lng: 19.5215414 },
    { lat: 48.3282379, lng: 19.5215989 },
    { lat: 48.3282761, lng: 19.5219611 },
    { lat: 48.3281565, lng: 19.5220794 },
    { lat: 48.3281032, lng: 19.5222451 },
    { lat: 48.328214, lng: 19.5225937 },
    { lat: 48.3280839, lng: 19.522705 },
    { lat: 48.327932, lng: 19.5226376 },
    { lat: 48.3278521, lng: 19.522688 },
    { lat: 48.3278154, lng: 19.5227963 },
    { lat: 48.3276669, lng: 19.5229449 },
    { lat: 48.3273761, lng: 19.5236024 },
    { lat: 48.3273348, lng: 19.5237554 },
    { lat: 48.3273443, lng: 19.5239594 },
    { lat: 48.3269779, lng: 19.5241502 },
    { lat: 48.3270685, lng: 19.5244552 },
    { lat: 48.3270064, lng: 19.5245973 },
    { lat: 48.3267001, lng: 19.5246742 },
    { lat: 48.3265362, lng: 19.524568 },
    { lat: 48.3263618, lng: 19.5246467 },
    { lat: 48.3259333, lng: 19.5246174 },
    { lat: 48.3258555, lng: 19.5247628 },
    { lat: 48.3256594, lng: 19.5246186 },
    { lat: 48.3255231, lng: 19.524591 },
    { lat: 48.325426, lng: 19.5249064 },
    { lat: 48.325265, lng: 19.5250771 },
    { lat: 48.3251357, lng: 19.5249628 },
    { lat: 48.3250598, lng: 19.5247345 },
    { lat: 48.3249036, lng: 19.5250146 },
    { lat: 48.3248281, lng: 19.5249643 },
    { lat: 48.3247338, lng: 19.5247288 },
    { lat: 48.3246151, lng: 19.5246993 },
    { lat: 48.3245915, lng: 19.5248672 },
    { lat: 48.3243784, lng: 19.5250381 },
    { lat: 48.324525, lng: 19.5252317 },
    { lat: 48.3246853, lng: 19.5250929 },
    { lat: 48.3247547, lng: 19.5251073 },
    { lat: 48.3247676, lng: 19.5252189 },
    { lat: 48.3244742, lng: 19.5253797 },
    { lat: 48.324386, lng: 19.5253457 },
    { lat: 48.3243962, lng: 19.5254948 },
    { lat: 48.3245008, lng: 19.5256383 },
    { lat: 48.3244955, lng: 19.5257872 },
    { lat: 48.3241318, lng: 19.525676 },
    { lat: 48.3240264, lng: 19.5257341 },
    { lat: 48.3239341, lng: 19.5256381 },
    { lat: 48.3238678, lng: 19.5256443 },
    { lat: 48.3238212, lng: 19.5257303 },
    { lat: 48.3238653, lng: 19.5259781 },
    { lat: 48.3235475, lng: 19.5259801 },
    { lat: 48.3235432, lng: 19.5258367 },
    { lat: 48.3236364, lng: 19.5257314 },
    { lat: 48.3234707, lng: 19.5256109 },
    { lat: 48.3232568, lng: 19.5259551 },
    { lat: 48.3231032, lng: 19.525947 },
    { lat: 48.3229567, lng: 19.5258532 },
    { lat: 48.3225642, lng: 19.5260357 },
    { lat: 48.3226488, lng: 19.5263802 },
    { lat: 48.3224437, lng: 19.5263318 },
    { lat: 48.322249, lng: 19.5265334 },
    { lat: 48.3221264, lng: 19.5270978 },
    { lat: 48.3221184, lng: 19.5273391 },
    { lat: 48.3220586, lng: 19.5274607 },
    { lat: 48.3217929, lng: 19.5275347 },
    { lat: 48.3216288, lng: 19.5277189 },
    { lat: 48.3215103, lng: 19.5277157 },
    { lat: 48.3212636, lng: 19.5278391 },
    { lat: 48.3213312, lng: 19.5281773 },
    { lat: 48.3211995, lng: 19.5283015 },
    { lat: 48.3212759, lng: 19.5284939 },
    { lat: 48.3210176, lng: 19.5285353 },
    { lat: 48.3209635, lng: 19.5286369 },
    { lat: 48.3211169, lng: 19.5289318 },
    { lat: 48.3208895, lng: 19.5291293 },
    { lat: 48.3208317, lng: 19.529341 },
    { lat: 48.320676, lng: 19.5294351 },
    { lat: 48.3205651, lng: 19.5294013 },
    { lat: 48.3204151, lng: 19.5295451 },
    { lat: 48.3201714, lng: 19.5293647 },
    { lat: 48.3198904, lng: 19.5295813 },
    { lat: 48.3197662, lng: 19.5295503 },
    { lat: 48.3196061, lng: 19.5297907 },
    { lat: 48.3196345, lng: 19.5299655 },
    { lat: 48.3195462, lng: 19.530031 },
    { lat: 48.3194401, lng: 19.5299078 },
    { lat: 48.3193457, lng: 19.5296691 },
    { lat: 48.3192709, lng: 19.5296592 },
    { lat: 48.3191781, lng: 19.5297471 },
    { lat: 48.3191626, lng: 19.5298761 },
    { lat: 48.319224, lng: 19.5301572 },
    { lat: 48.3191797, lng: 19.5302461 },
    { lat: 48.3190097, lng: 19.5303442 },
    { lat: 48.3189926, lng: 19.5303679 },
    { lat: 48.3222669, lng: 19.5332294 },
    { lat: 48.3224091, lng: 19.5334037 },
    { lat: 48.3235049, lng: 19.5360988 },
    { lat: 48.3233265, lng: 19.5377469 },
    { lat: 48.3233491, lng: 19.5379913 },
    { lat: 48.3234752, lng: 19.5382834 },
    { lat: 48.3236299, lng: 19.5380935 },
    { lat: 48.3237443, lng: 19.5381574 },
    { lat: 48.3238484, lng: 19.5380907 },
    { lat: 48.3240654, lng: 19.5382034 },
    { lat: 48.324186, lng: 19.5380581 },
    { lat: 48.3244332, lng: 19.5380406 },
    { lat: 48.324468, lng: 19.5378942 },
    { lat: 48.3247461, lng: 19.5379139 },
    { lat: 48.3248215, lng: 19.5380325 },
    { lat: 48.3250281, lng: 19.53792 },
    { lat: 48.3252933, lng: 19.5379973 },
    { lat: 48.3253845, lng: 19.5379258 },
    { lat: 48.3254466, lng: 19.538095 },
    { lat: 48.3256217, lng: 19.538057 },
    { lat: 48.3259414, lng: 19.5382448 },
    { lat: 48.3262277, lng: 19.5382721 },
    { lat: 48.3264467, lng: 19.5384267 },
    { lat: 48.3266633, lng: 19.5384287 },
    { lat: 48.326759, lng: 19.5385731 },
    { lat: 48.3269593, lng: 19.5385941 },
    { lat: 48.3271641, lng: 19.5388317 },
    { lat: 48.3273163, lng: 19.5388347 },
    { lat: 48.3274385, lng: 19.5387189 },
    { lat: 48.3277124, lng: 19.5387946 },
    { lat: 48.3279468, lng: 19.5386247 },
    { lat: 48.3282581, lng: 19.5387018 },
    { lat: 48.3284362, lng: 19.5389486 },
    { lat: 48.3283115, lng: 19.5394711 },
    { lat: 48.328226, lng: 19.540769 },
    { lat: 48.3284223, lng: 19.5411123 },
    { lat: 48.3284298, lng: 19.5413275 },
    { lat: 48.3283218, lng: 19.5416943 },
    { lat: 48.3287673, lng: 19.5433393 },
    { lat: 48.3286187, lng: 19.5447107 },
    { lat: 48.3288308, lng: 19.5447885 },
    { lat: 48.328858, lng: 19.5452085 },
    { lat: 48.3291837, lng: 19.5466993 },
    { lat: 48.3295647, lng: 19.5477069 },
    { lat: 48.3299774, lng: 19.5481961 },
    { lat: 48.3300656, lng: 19.5484507 },
    { lat: 48.3302437, lng: 19.5484964 },
    { lat: 48.3303932, lng: 19.5487837 },
    { lat: 48.3305634, lng: 19.5489824 },
    { lat: 48.3306347, lng: 19.5491818 },
    { lat: 48.3311314, lng: 19.5494707 },
    { lat: 48.3315502, lng: 19.5500024 },
    { lat: 48.3318274, lng: 19.5501709 },
    { lat: 48.3319084, lng: 19.550436 },
    { lat: 48.3320731, lng: 19.550613 },
    { lat: 48.3320843, lng: 19.5509284 },
    { lat: 48.3321603, lng: 19.551132 },
    { lat: 48.3328269, lng: 19.5521264 },
    { lat: 48.334659, lng: 19.5512875 },
    { lat: 48.334902, lng: 19.5519656 },
    { lat: 48.3348552, lng: 19.5521221 },
    { lat: 48.3348339, lng: 19.5533745 },
    { lat: 48.3348894, lng: 19.5539687 },
    { lat: 48.3350319, lng: 19.5547352 },
    { lat: 48.3354505, lng: 19.5542106 },
    { lat: 48.3363498, lng: 19.5559251 },
    { lat: 48.3359146, lng: 19.5568747 },
    { lat: 48.3360365, lng: 19.5575184 },
    { lat: 48.336154, lng: 19.5576688 },
    { lat: 48.3362162, lng: 19.5576981 },
    { lat: 48.3364319, lng: 19.5574162 },
    { lat: 48.3365833, lng: 19.5576173 },
    { lat: 48.3366763, lng: 19.5573867 },
    { lat: 48.3371035, lng: 19.5568725 },
    { lat: 48.3377706, lng: 19.5557954 },
    { lat: 48.3378691, lng: 19.5557101 },
    { lat: 48.3385202, lng: 19.5561928 },
    { lat: 48.3390492, lng: 19.5564759 },
    { lat: 48.3392997, lng: 19.5563561 },
    { lat: 48.3396864, lng: 19.5558468 },
    { lat: 48.3397806, lng: 19.5558144 },
    { lat: 48.339909, lng: 19.5563016 },
    { lat: 48.3400268, lng: 19.5562391 },
    { lat: 48.3400486, lng: 19.5563491 },
    { lat: 48.3401446, lng: 19.556279 },
    { lat: 48.3407488, lng: 19.5556199 },
    { lat: 48.3410432, lng: 19.5552136 },
    { lat: 48.341556, lng: 19.5543076 },
    { lat: 48.3420199, lng: 19.5533213 },
    { lat: 48.3423445, lng: 19.5547906 },
    { lat: 48.3425591, lng: 19.5568069 },
    { lat: 48.3430177, lng: 19.5590353 },
    { lat: 48.3429746, lng: 19.5591772 },
    { lat: 48.3429742, lng: 19.5614233 },
    { lat: 48.3425299, lng: 19.5658813 },
    { lat: 48.3433794, lng: 19.567571 },
    { lat: 48.3432364, lng: 19.5690714 },
    { lat: 48.3440073, lng: 19.5690991 },
    { lat: 48.344048, lng: 19.5691016 },
    { lat: 48.3440579, lng: 19.5690701 },
    { lat: 48.3445274, lng: 19.5689884 },
    { lat: 48.3478959, lng: 19.5703923 },
    { lat: 48.3490787, lng: 19.5683699 },
    { lat: 48.3496166, lng: 19.5690636 },
    { lat: 48.3496511, lng: 19.5690342 },
    { lat: 48.3506944, lng: 19.5673319 },
    { lat: 48.3511205, lng: 19.5667947 },
    { lat: 48.3523465, lng: 19.5645866 },
    { lat: 48.3529513, lng: 19.5636217 },
    { lat: 48.3531621, lng: 19.563404 },
    { lat: 48.3535014, lng: 19.563799 },
    { lat: 48.3543106, lng: 19.5650266 },
    { lat: 48.3545886, lng: 19.5653107 },
    { lat: 48.3548306, lng: 19.5654533 },
    { lat: 48.3549309, lng: 19.563247 },
    { lat: 48.3537722, lng: 19.5623323 },
    { lat: 48.3529899, lng: 19.5614963 },
    { lat: 48.3530512, lng: 19.5613344 },
    { lat: 48.3527786, lng: 19.5609413 },
    { lat: 48.3521684, lng: 19.5596766 },
    { lat: 48.3518354, lng: 19.5593224 },
    { lat: 48.3517978, lng: 19.5589717 },
    { lat: 48.3519153, lng: 19.5585128 },
    { lat: 48.3519325, lng: 19.5582754 },
    { lat: 48.3513931, lng: 19.5574483 },
    { lat: 48.3511638, lng: 19.5569963 },
    { lat: 48.351571, lng: 19.5560993 },
    { lat: 48.352015, lng: 19.5548487 },
    { lat: 48.3524927, lng: 19.5540361 },
    { lat: 48.3528066, lng: 19.5533414 },
    { lat: 48.3514605, lng: 19.5517375 },
    { lat: 48.3518283, lng: 19.5515038 },
    { lat: 48.3509182, lng: 19.5506068 },
    { lat: 48.351375, lng: 19.549564 },
    { lat: 48.3514281, lng: 19.549347 },
    { lat: 48.3506693, lng: 19.5484599 },
    { lat: 48.3503912, lng: 19.5482205 },
    { lat: 48.3503228, lng: 19.5481295 },
    { lat: 48.3502995, lng: 19.5479873 },
    { lat: 48.3503355, lng: 19.5479124 },
    { lat: 48.3507283, lng: 19.5478323 },
    { lat: 48.3507841, lng: 19.547767 },
    { lat: 48.3507986, lng: 19.547401 },
    { lat: 48.3511096, lng: 19.5471282 },
    { lat: 48.3511404, lng: 19.5468947 },
    { lat: 48.3510076, lng: 19.5468227 },
    { lat: 48.3511853, lng: 19.5465946 },
    { lat: 48.351074, lng: 19.5463819 },
    { lat: 48.3509974, lng: 19.5464061 },
    { lat: 48.3508028, lng: 19.5461195 },
    { lat: 48.3505096, lng: 19.5460907 },
    { lat: 48.3504476, lng: 19.5459941 },
    { lat: 48.3504531, lng: 19.5448184 },
    { lat: 48.3501828, lng: 19.5442562 },
    { lat: 48.3499661, lng: 19.5441302 },
    { lat: 48.3498497, lng: 19.5437834 },
    { lat: 48.3495779, lng: 19.5436278 },
    { lat: 48.3495031, lng: 19.543011 },
    { lat: 48.349288, lng: 19.5421313 },
    { lat: 48.3491958, lng: 19.5414065 },
    { lat: 48.3493289, lng: 19.5413849 },
    { lat: 48.3493592, lng: 19.5393287 },
    { lat: 48.3489452, lng: 19.5390249 },
    { lat: 48.3484313, lng: 19.5383177 },
    { lat: 48.3475643, lng: 19.5378445 },
    { lat: 48.3476988, lng: 19.5377313 },
    { lat: 48.3477202, lng: 19.5375856 },
    { lat: 48.347822, lng: 19.5373962 },
    { lat: 48.3476736, lng: 19.5371848 },
    { lat: 48.347713, lng: 19.5370164 },
    { lat: 48.347672, lng: 19.5368339 },
    { lat: 48.3477393, lng: 19.5365117 },
    { lat: 48.3479778, lng: 19.5362796 },
    { lat: 48.3480915, lng: 19.5359146 },
    { lat: 48.3482248, lng: 19.5356981 },
    { lat: 48.3484872, lng: 19.5355242 },
    { lat: 48.3485631, lng: 19.5350455 },
    { lat: 48.3487914, lng: 19.5348411 },
    { lat: 48.3487319, lng: 19.5345553 },
    { lat: 48.3488583, lng: 19.5345103 },
    { lat: 48.3489043, lng: 19.5344322 },
    { lat: 48.3488769, lng: 19.5339812 },
    { lat: 48.3490045, lng: 19.5336721 },
    { lat: 48.3491903, lng: 19.5334261 },
    { lat: 48.3490747, lng: 19.5331937 },
    { lat: 48.3490683, lng: 19.5329053 },
    { lat: 48.3491674, lng: 19.5325885 },
    { lat: 48.3493265, lng: 19.5324217 },
    { lat: 48.3492954, lng: 19.5320402 },
    { lat: 48.3493088, lng: 19.530773 },
    { lat: 48.349196, lng: 19.5305237 },
    { lat: 48.3493708, lng: 19.530311 },
    { lat: 48.3492968, lng: 19.529782 },
    { lat: 48.3492571, lng: 19.5289433 },
    { lat: 48.3491844, lng: 19.5286192 },
    { lat: 48.3492875, lng: 19.528289 },
    { lat: 48.3491852, lng: 19.5280994 },
    { lat: 48.3492629, lng: 19.5278543 },
    { lat: 48.3492354, lng: 19.5273828 },
    { lat: 48.3493618, lng: 19.5272875 },
    { lat: 48.3493728, lng: 19.5270844 },
    { lat: 48.3495397, lng: 19.5264045 },
    { lat: 48.3496072, lng: 19.526281 },
    { lat: 48.3495351, lng: 19.5260865 },
    { lat: 48.3496456, lng: 19.5258724 },
    { lat: 48.3495307, lng: 19.5256994 },
    { lat: 48.3495178, lng: 19.5255895 },
    { lat: 48.3496617, lng: 19.5253428 },
    { lat: 48.3498672, lng: 19.5252052 },
    { lat: 48.3498805, lng: 19.5249307 },
    { lat: 48.3501636, lng: 19.5241802 },
    { lat: 48.3503454, lng: 19.5241174 },
    { lat: 48.3505729, lng: 19.5235803 },
    { lat: 48.3507706, lng: 19.5234885 },
    { lat: 48.3508823, lng: 19.5231254 },
    { lat: 48.351057, lng: 19.5230789 },
    { lat: 48.3511627, lng: 19.5227819 },
    { lat: 48.3512711, lng: 19.5228121 },
    { lat: 48.3513725, lng: 19.5226906 },
    { lat: 48.3513362, lng: 19.5224167 },
    { lat: 48.3513892, lng: 19.5223431 },
    { lat: 48.3514556, lng: 19.5222933 },
    { lat: 48.3515627, lng: 19.5224391 },
    { lat: 48.3516425, lng: 19.5224329 },
    { lat: 48.3520478, lng: 19.5219903 },
    { lat: 48.3522819, lng: 19.5220535 },
    { lat: 48.3523639, lng: 19.5219542 },
    { lat: 48.3524283, lng: 19.5217374 },
    { lat: 48.3525683, lng: 19.5217667 },
    { lat: 48.3526184, lng: 19.5217059 },
  ],
  Tomášovce: [
    { lat: 48.3961936, lng: 19.6260226 },
    { lat: 48.3961712, lng: 19.6260235 },
    { lat: 48.3957066, lng: 19.6260681 },
    { lat: 48.3941146, lng: 19.6257756 },
    { lat: 48.3935761, lng: 19.6258041 },
    { lat: 48.3924802, lng: 19.6253177 },
    { lat: 48.3921272, lng: 19.6250634 },
    { lat: 48.3911247, lng: 19.6245501 },
    { lat: 48.3899411, lng: 19.623693 },
    { lat: 48.3880046, lng: 19.6217668 },
    { lat: 48.3865509, lng: 19.6208701 },
    { lat: 48.3853651, lng: 19.619996 },
    { lat: 48.3849125, lng: 19.6191341 },
    { lat: 48.3846569, lng: 19.6184938 },
    { lat: 48.3845645, lng: 19.6180956 },
    { lat: 48.3843644, lng: 19.617584 },
    { lat: 48.3842851, lng: 19.6171532 },
    { lat: 48.3841111, lng: 19.6167747 },
    { lat: 48.3836446, lng: 19.6152862 },
    { lat: 48.3828126, lng: 19.6132463 },
    { lat: 48.3826938, lng: 19.6127377 },
    { lat: 48.3830112, lng: 19.612333 },
    { lat: 48.3831656, lng: 19.6123118 },
    { lat: 48.3832285, lng: 19.6123816 },
    { lat: 48.3834184, lng: 19.6124196 },
    { lat: 48.3834401, lng: 19.6125226 },
    { lat: 48.3835332, lng: 19.6125131 },
    { lat: 48.3835884, lng: 19.6124135 },
    { lat: 48.3835444, lng: 19.6122407 },
    { lat: 48.383787, lng: 19.6120713 },
    { lat: 48.3838602, lng: 19.6120971 },
    { lat: 48.3839001, lng: 19.6125021 },
    { lat: 48.3841434, lng: 19.6128724 },
    { lat: 48.3842169, lng: 19.6128783 },
    { lat: 48.3842794, lng: 19.6127879 },
    { lat: 48.3842436, lng: 19.6125268 },
    { lat: 48.3843679, lng: 19.612285 },
    { lat: 48.3844508, lng: 19.6123992 },
    { lat: 48.3846228, lng: 19.612931 },
    { lat: 48.3847491, lng: 19.6130609 },
    { lat: 48.3849528, lng: 19.613027 },
    { lat: 48.3848904, lng: 19.6125729 },
    { lat: 48.3851562, lng: 19.6124219 },
    { lat: 48.3852772, lng: 19.6127519 },
    { lat: 48.3855855, lng: 19.6129146 },
    { lat: 48.3857357, lng: 19.6134455 },
    { lat: 48.3858148, lng: 19.6135746 },
    { lat: 48.385943, lng: 19.6135182 },
    { lat: 48.3860464, lng: 19.6133413 },
    { lat: 48.3862459, lng: 19.6132149 },
    { lat: 48.3867241, lng: 19.6127015 },
    { lat: 48.3868921, lng: 19.6126829 },
    { lat: 48.387122, lng: 19.6137473 },
    { lat: 48.3871982, lng: 19.6137129 },
    { lat: 48.3872955, lng: 19.6135225 },
    { lat: 48.3874037, lng: 19.6135483 },
    { lat: 48.3876345, lng: 19.6133239 },
    { lat: 48.3879877, lng: 19.6124915 },
    { lat: 48.387355, lng: 19.6108164 },
    { lat: 48.3872535, lng: 19.6106928 },
    { lat: 48.3872948, lng: 19.6106095 },
    { lat: 48.3871443, lng: 19.6104193 },
    { lat: 48.387111, lng: 19.6100349 },
    { lat: 48.3869703, lng: 19.6096682 },
    { lat: 48.3871035, lng: 19.609628 },
    { lat: 48.3871556, lng: 19.609433 },
    { lat: 48.3868197, lng: 19.6090367 },
    { lat: 48.3866833, lng: 19.6084154 },
    { lat: 48.3866819, lng: 19.6081362 },
    { lat: 48.3868112, lng: 19.6079577 },
    { lat: 48.3867403, lng: 19.607514 },
    { lat: 48.3868182, lng: 19.6072916 },
    { lat: 48.3868079, lng: 19.6070186 },
    { lat: 48.3870606, lng: 19.6062046 },
    { lat: 48.3869685, lng: 19.6060375 },
    { lat: 48.3871394, lng: 19.60514 },
    { lat: 48.3863809, lng: 19.6055589 },
    { lat: 48.3864376, lng: 19.6049469 },
    { lat: 48.3840435, lng: 19.6010961 },
    { lat: 48.3834304, lng: 19.5999074 },
    { lat: 48.3818041, lng: 19.5975293 },
    { lat: 48.3811664, lng: 19.5966916 },
    { lat: 48.3804683, lng: 19.5959651 },
    { lat: 48.3783964, lng: 19.5940234 },
    { lat: 48.3756375, lng: 19.5909789 },
    { lat: 48.3752454, lng: 19.5906606 },
    { lat: 48.3743329, lng: 19.5899233 },
    { lat: 48.3740863, lng: 19.5901493 },
    { lat: 48.3738368, lng: 19.5903844 },
    { lat: 48.3734801, lng: 19.5909652 },
    { lat: 48.3733958, lng: 19.5912152 },
    { lat: 48.3733859, lng: 19.5913949 },
    { lat: 48.3730945, lng: 19.5922425 },
    { lat: 48.3730092, lng: 19.5926982 },
    { lat: 48.3727902, lng: 19.5931545 },
    { lat: 48.37276, lng: 19.5933309 },
    { lat: 48.3725715, lng: 19.5937239 },
    { lat: 48.372453, lng: 19.5941132 },
    { lat: 48.372024, lng: 19.5948385 },
    { lat: 48.3718946, lng: 19.5951842 },
    { lat: 48.3687182, lng: 19.5967479 },
    { lat: 48.3666551, lng: 19.5974922 },
    { lat: 48.3660348, lng: 19.5976271 },
    { lat: 48.3652868, lng: 19.5975835 },
    { lat: 48.3636878, lng: 19.5982524 },
    { lat: 48.3593024, lng: 19.5993194 },
    { lat: 48.356419, lng: 19.598926 },
    { lat: 48.3541015, lng: 19.599706 },
    { lat: 48.3526934, lng: 19.6016163 },
    { lat: 48.3518127, lng: 19.602914 },
    { lat: 48.3500168, lng: 19.6067036 },
    { lat: 48.3488677, lng: 19.6082214 },
    { lat: 48.3483416, lng: 19.6098351 },
    { lat: 48.3482041, lng: 19.6101531 },
    { lat: 48.3475625, lng: 19.6111866 },
    { lat: 48.347346, lng: 19.612099 },
    { lat: 48.3468568, lng: 19.6131346 },
    { lat: 48.347176, lng: 19.6138599 },
    { lat: 48.3478394, lng: 19.6153422 },
    { lat: 48.3486613, lng: 19.6185182 },
    { lat: 48.3495042, lng: 19.620974 },
    { lat: 48.3507396, lng: 19.6238855 },
    { lat: 48.3519669, lng: 19.6279637 },
    { lat: 48.3537112, lng: 19.6307955 },
    { lat: 48.3552465, lng: 19.6334168 },
    { lat: 48.3549999, lng: 19.6337686 },
    { lat: 48.3548478, lng: 19.633821 },
    { lat: 48.3548179, lng: 19.6340061 },
    { lat: 48.3546763, lng: 19.6341162 },
    { lat: 48.3544879, lng: 19.6344341 },
    { lat: 48.3543938, lng: 19.6344217 },
    { lat: 48.3543634, lng: 19.6345949 },
    { lat: 48.3541528, lng: 19.6348711 },
    { lat: 48.3541558, lng: 19.6349377 },
    { lat: 48.3540641, lng: 19.6350519 },
    { lat: 48.3539664, lng: 19.635058 },
    { lat: 48.3538213, lng: 19.6353878 },
    { lat: 48.3538078, lng: 19.6355649 },
    { lat: 48.3535043, lng: 19.6360801 },
    { lat: 48.3533537, lng: 19.63646 },
    { lat: 48.3532079, lng: 19.6365733 },
    { lat: 48.3530507, lng: 19.6369072 },
    { lat: 48.3527371, lng: 19.6378726 },
    { lat: 48.3526096, lng: 19.6381442 },
    { lat: 48.3525656, lng: 19.6384913 },
    { lat: 48.3525965, lng: 19.6386256 },
    { lat: 48.3524818, lng: 19.6388782 },
    { lat: 48.3525194, lng: 19.6392382 },
    { lat: 48.3524734, lng: 19.639465 },
    { lat: 48.3525348, lng: 19.6396515 },
    { lat: 48.3525081, lng: 19.639809 },
    { lat: 48.3523405, lng: 19.6400652 },
    { lat: 48.3523549, lng: 19.6401838 },
    { lat: 48.3522472, lng: 19.6406443 },
    { lat: 48.3522052, lng: 19.6412339 },
    { lat: 48.3520423, lng: 19.6419175 },
    { lat: 48.3520143, lng: 19.6422913 },
    { lat: 48.3521117, lng: 19.6425028 },
    { lat: 48.352068, lng: 19.6430082 },
    { lat: 48.3521792, lng: 19.6443186 },
    { lat: 48.3521149, lng: 19.6444672 },
    { lat: 48.3521163, lng: 19.6447221 },
    { lat: 48.3523648, lng: 19.6445105 },
    { lat: 48.352406, lng: 19.6446487 },
    { lat: 48.3522969, lng: 19.6448529 },
    { lat: 48.352475, lng: 19.6452556 },
    { lat: 48.3529842, lng: 19.6448254 },
    { lat: 48.3532517, lng: 19.6444117 },
    { lat: 48.3538726, lng: 19.645288 },
    { lat: 48.3548556, lng: 19.6463688 },
    { lat: 48.3550005, lng: 19.6463094 },
    { lat: 48.3550876, lng: 19.6461673 },
    { lat: 48.355171, lng: 19.6462641 },
    { lat: 48.355514, lng: 19.6460991 },
    { lat: 48.3559459, lng: 19.646435 },
    { lat: 48.355771, lng: 19.6473505 },
    { lat: 48.3557622, lng: 19.6477305 },
    { lat: 48.3554541, lng: 19.648441 },
    { lat: 48.3563425, lng: 19.6495999 },
    { lat: 48.3567734, lng: 19.6503312 },
    { lat: 48.3568727, lng: 19.6504848 },
    { lat: 48.3568932, lng: 19.6503435 },
    { lat: 48.3570281, lng: 19.6502614 },
    { lat: 48.3570812, lng: 19.6500151 },
    { lat: 48.3584064, lng: 19.6497655 },
    { lat: 48.359274, lng: 19.6500872 },
    { lat: 48.3594519, lng: 19.6496124 },
    { lat: 48.3593374, lng: 19.6493728 },
    { lat: 48.3592276, lng: 19.6495622 },
    { lat: 48.3591505, lng: 19.6494489 },
    { lat: 48.359131, lng: 19.6492156 },
    { lat: 48.3591864, lng: 19.6490474 },
    { lat: 48.3594294, lng: 19.6487651 },
    { lat: 48.3593668, lng: 19.6486226 },
    { lat: 48.3592648, lng: 19.6487386 },
    { lat: 48.3591902, lng: 19.648682 },
    { lat: 48.3591671, lng: 19.6486444 },
    { lat: 48.3592455, lng: 19.6484837 },
    { lat: 48.3595958, lng: 19.648555 },
    { lat: 48.3596207, lng: 19.6487548 },
    { lat: 48.3597385, lng: 19.6487705 },
    { lat: 48.3598202, lng: 19.6484093 },
    { lat: 48.359746, lng: 19.6482878 },
    { lat: 48.3598464, lng: 19.6481164 },
    { lat: 48.3599182, lng: 19.6480592 },
    { lat: 48.3601489, lng: 19.6481271 },
    { lat: 48.3602288, lng: 19.6480527 },
    { lat: 48.3602869, lng: 19.6478636 },
    { lat: 48.3603553, lng: 19.6478315 },
    { lat: 48.3604509, lng: 19.647904 },
    { lat: 48.3605363, lng: 19.6477497 },
    { lat: 48.3604594, lng: 19.6475683 },
    { lat: 48.3604933, lng: 19.6474936 },
    { lat: 48.3605676, lng: 19.647519 },
    { lat: 48.3607485, lng: 19.6477589 },
    { lat: 48.360816, lng: 19.647706 },
    { lat: 48.3609881, lng: 19.6475216 },
    { lat: 48.3608335, lng: 19.6471688 },
    { lat: 48.3608754, lng: 19.6470239 },
    { lat: 48.3609714, lng: 19.6471271 },
    { lat: 48.361123, lng: 19.6470164 },
    { lat: 48.3612403, lng: 19.6470208 },
    { lat: 48.3612648, lng: 19.6468886 },
    { lat: 48.3611757, lng: 19.6468619 },
    { lat: 48.3610241, lng: 19.6464748 },
    { lat: 48.3610301, lng: 19.6462109 },
    { lat: 48.3611477, lng: 19.6462163 },
    { lat: 48.3612813, lng: 19.6463547 },
    { lat: 48.3613955, lng: 19.6460401 },
    { lat: 48.3613448, lng: 19.6457434 },
    { lat: 48.3612072, lng: 19.6457394 },
    { lat: 48.3610625, lng: 19.6453537 },
    { lat: 48.360986, lng: 19.6454334 },
    { lat: 48.3610672, lng: 19.6456842 },
    { lat: 48.3610445, lng: 19.6458016 },
    { lat: 48.360976, lng: 19.6458334 },
    { lat: 48.3608502, lng: 19.6456928 },
    { lat: 48.3607398, lng: 19.6454447 },
    { lat: 48.3606404, lng: 19.6454409 },
    { lat: 48.3605532, lng: 19.6453552 },
    { lat: 48.3605026, lng: 19.6451333 },
    { lat: 48.3608519, lng: 19.6444362 },
    { lat: 48.3608273, lng: 19.6443154 },
    { lat: 48.36096, lng: 19.64386 },
    { lat: 48.361166, lng: 19.6439308 },
    { lat: 48.3612896, lng: 19.6441464 },
    { lat: 48.3615925, lng: 19.6439154 },
    { lat: 48.3618374, lng: 19.6439744 },
    { lat: 48.3618588, lng: 19.6441232 },
    { lat: 48.3619262, lng: 19.6441943 },
    { lat: 48.3620677, lng: 19.6441118 },
    { lat: 48.3620502, lng: 19.6439237 },
    { lat: 48.3620058, lng: 19.6439658 },
    { lat: 48.3618853, lng: 19.6438654 },
    { lat: 48.361911, lng: 19.6436137 },
    { lat: 48.3620388, lng: 19.6433739 },
    { lat: 48.3620125, lng: 19.6432161 },
    { lat: 48.362081, lng: 19.6429068 },
    { lat: 48.3619631, lng: 19.6430212 },
    { lat: 48.3618297, lng: 19.6430316 },
    { lat: 48.3615734, lng: 19.6432686 },
    { lat: 48.361515, lng: 19.6432263 },
    { lat: 48.3615029, lng: 19.6431368 },
    { lat: 48.3615882, lng: 19.6429276 },
    { lat: 48.3615944, lng: 19.6427899 },
    { lat: 48.361799, lng: 19.6426812 },
    { lat: 48.3617905, lng: 19.6425417 },
    { lat: 48.3616599, lng: 19.6422942 },
    { lat: 48.3614309, lng: 19.6423895 },
    { lat: 48.3613256, lng: 19.6422011 },
    { lat: 48.3613989, lng: 19.6405084 },
    { lat: 48.3613568, lng: 19.6400262 },
    { lat: 48.3613785, lng: 19.6398383 },
    { lat: 48.3614968, lng: 19.6395623 },
    { lat: 48.3612868, lng: 19.6393297 },
    { lat: 48.3612434, lng: 19.6391906 },
    { lat: 48.361317, lng: 19.6390736 },
    { lat: 48.361299, lng: 19.6389741 },
    { lat: 48.3615166, lng: 19.6390285 },
    { lat: 48.3615701, lng: 19.6388391 },
    { lat: 48.3616932, lng: 19.6390431 },
    { lat: 48.3618127, lng: 19.639123 },
    { lat: 48.3618564, lng: 19.6390661 },
    { lat: 48.3619603, lng: 19.6380273 },
    { lat: 48.3619033, lng: 19.6378633 },
    { lat: 48.3618223, lng: 19.63789 },
    { lat: 48.3617483, lng: 19.6377732 },
    { lat: 48.3618034, lng: 19.6374253 },
    { lat: 48.3619424, lng: 19.6372821 },
    { lat: 48.3620895, lng: 19.6374712 },
    { lat: 48.3621157, lng: 19.6376532 },
    { lat: 48.3624021, lng: 19.6379025 },
    { lat: 48.3624355, lng: 19.637498 },
    { lat: 48.362648, lng: 19.6375858 },
    { lat: 48.3625596, lng: 19.6371778 },
    { lat: 48.3627305, lng: 19.6365969 },
    { lat: 48.3629833, lng: 19.6369755 },
    { lat: 48.363435, lng: 19.6366987 },
    { lat: 48.3636114, lng: 19.6364922 },
    { lat: 48.363834, lng: 19.6365532 },
    { lat: 48.3637545, lng: 19.6369606 },
    { lat: 48.3637591, lng: 19.6371412 },
    { lat: 48.364751, lng: 19.6384911 },
    { lat: 48.367703, lng: 19.6415234 },
    { lat: 48.3671827, lng: 19.6425747 },
    { lat: 48.3690707, lng: 19.6448297 },
    { lat: 48.3697274, lng: 19.643801 },
    { lat: 48.3705909, lng: 19.6449347 },
    { lat: 48.3719015, lng: 19.6429952 },
    { lat: 48.3733663, lng: 19.6445588 },
    { lat: 48.3745906, lng: 19.6456466 },
    { lat: 48.3749879, lng: 19.6462597 },
    { lat: 48.3752946, lng: 19.6465368 },
    { lat: 48.3761402, lng: 19.6467998 },
    { lat: 48.3772743, lng: 19.6467634 },
    { lat: 48.3780567, lng: 19.6462957 },
    { lat: 48.37822, lng: 19.646285 },
    { lat: 48.378348, lng: 19.646006 },
    { lat: 48.378348, lng: 19.645883 },
    { lat: 48.378443, lng: 19.645799 },
    { lat: 48.378877, lng: 19.645697 },
    { lat: 48.37901, lng: 19.645517 },
    { lat: 48.379281, lng: 19.64523 },
    { lat: 48.379445, lng: 19.64505 },
    { lat: 48.379634, lng: 19.644921 },
    { lat: 48.37978, lng: 19.64484 },
    { lat: 48.380148, lng: 19.644702 },
    { lat: 48.380529, lng: 19.644536 },
    { lat: 48.380838, lng: 19.644294 },
    { lat: 48.380982, lng: 19.644287 },
    { lat: 48.381066, lng: 19.644317 },
    { lat: 48.381304, lng: 19.644192 },
    { lat: 48.381489, lng: 19.644151 },
    { lat: 48.381595, lng: 19.644094 },
    { lat: 48.381722, lng: 19.644043 },
    { lat: 48.381813, lng: 19.644088 },
    { lat: 48.381906, lng: 19.644059 },
    { lat: 48.382123, lng: 19.643811 },
    { lat: 48.382325, lng: 19.643769 },
    { lat: 48.382607, lng: 19.643337 },
    { lat: 48.3823345, lng: 19.6429411 },
    { lat: 48.3824753, lng: 19.6422638 },
    { lat: 48.3829273, lng: 19.640997 },
    { lat: 48.3835923, lng: 19.6393837 },
    { lat: 48.3843651, lng: 19.6380071 },
    { lat: 48.3857715, lng: 19.6362897 },
    { lat: 48.3869442, lng: 19.6354646 },
    { lat: 48.3872619, lng: 19.6350185 },
    { lat: 48.3883363, lng: 19.6344816 },
    { lat: 48.3885321, lng: 19.6344971 },
    { lat: 48.3895761, lng: 19.6340151 },
    { lat: 48.3918643, lng: 19.6328601 },
    { lat: 48.3921823, lng: 19.6326404 },
    { lat: 48.3927563, lng: 19.6318316 },
    { lat: 48.3931626, lng: 19.6314703 },
    { lat: 48.393297, lng: 19.6312059 },
    { lat: 48.3934589, lng: 19.6305987 },
    { lat: 48.3934991, lng: 19.6302673 },
    { lat: 48.3934493, lng: 19.6295175 },
    { lat: 48.3935519, lng: 19.6290451 },
    { lat: 48.3939857, lng: 19.628276 },
    { lat: 48.395273, lng: 19.6268205 },
    { lat: 48.3958803, lng: 19.6262947 },
    { lat: 48.3961936, lng: 19.6260226 },
  ],
  Bulhary: [
    { lat: 48.2924335, lng: 19.8285883 },
    { lat: 48.2923028, lng: 19.8287426 },
    { lat: 48.2915249, lng: 19.8296071 },
    { lat: 48.2906356, lng: 19.830712 },
    { lat: 48.2897722, lng: 19.8315781 },
    { lat: 48.2896273, lng: 19.8317193 },
    { lat: 48.2886478, lng: 19.8323049 },
    { lat: 48.2879939, lng: 19.8326311 },
    { lat: 48.2874796, lng: 19.8327462 },
    { lat: 48.286564, lng: 19.833347 },
    { lat: 48.2864427, lng: 19.8330455 },
    { lat: 48.2862629, lng: 19.8329753 },
    { lat: 48.2861916, lng: 19.8326636 },
    { lat: 48.2857523, lng: 19.8324031 },
    { lat: 48.2854856, lng: 19.8324123 },
    { lat: 48.2853148, lng: 19.8325273 },
    { lat: 48.2851449, lng: 19.8323519 },
    { lat: 48.2849641, lng: 19.8324177 },
    { lat: 48.2848098, lng: 19.8326465 },
    { lat: 48.28474, lng: 19.8330102 },
    { lat: 48.2850161, lng: 19.8337388 },
    { lat: 48.2849761, lng: 19.8338283 },
    { lat: 48.2848171, lng: 19.8338269 },
    { lat: 48.2846659, lng: 19.8337426 },
    { lat: 48.2845429, lng: 19.8337865 },
    { lat: 48.2843178, lng: 19.8339228 },
    { lat: 48.2842207, lng: 19.8341506 },
    { lat: 48.2840145, lng: 19.8343045 },
    { lat: 48.2835809, lng: 19.8350321 },
    { lat: 48.2832121, lng: 19.8357735 },
    { lat: 48.281839, lng: 19.8378193 },
    { lat: 48.2801128, lng: 19.8414894 },
    { lat: 48.27954, lng: 19.8419158 },
    { lat: 48.2794433, lng: 19.8423348 },
    { lat: 48.2788258, lng: 19.8428272 },
    { lat: 48.2786856, lng: 19.842939 },
    { lat: 48.2784154, lng: 19.8441344 },
    { lat: 48.2775566, lng: 19.8460185 },
    { lat: 48.2772707, lng: 19.8473967 },
    { lat: 48.2773347, lng: 19.847626 },
    { lat: 48.2772715, lng: 19.8478271 },
    { lat: 48.2786039, lng: 19.8478204 },
    { lat: 48.2793045, lng: 19.8481008 },
    { lat: 48.2802429, lng: 19.8493423 },
    { lat: 48.281683, lng: 19.8519387 },
    { lat: 48.2818545, lng: 19.8526969 },
    { lat: 48.2818317, lng: 19.853078 },
    { lat: 48.2813043, lng: 19.8557192 },
    { lat: 48.2814327, lng: 19.8565768 },
    { lat: 48.2813383, lng: 19.858346 },
    { lat: 48.2816459, lng: 19.8600853 },
    { lat: 48.2829254, lng: 19.8614554 },
    { lat: 48.2838674, lng: 19.8620287 },
    { lat: 48.2844006, lng: 19.8632063 },
    { lat: 48.2845651, lng: 19.8644793 },
    { lat: 48.2846065, lng: 19.8657451 },
    { lat: 48.2849714, lng: 19.8675352 },
    { lat: 48.2854122, lng: 19.8703819 },
    { lat: 48.2860474, lng: 19.8724039 },
    { lat: 48.2867535, lng: 19.8738546 },
    { lat: 48.2875892, lng: 19.8744749 },
    { lat: 48.2879963, lng: 19.8745107 },
    { lat: 48.2889913, lng: 19.8751909 },
    { lat: 48.2903869, lng: 19.8765691 },
    { lat: 48.2907703, lng: 19.8773084 },
    { lat: 48.2917244, lng: 19.8788941 },
    { lat: 48.2921688, lng: 19.8793979 },
    { lat: 48.29362, lng: 19.8799236 },
    { lat: 48.2941657, lng: 19.8804285 },
    { lat: 48.2949303, lng: 19.8809965 },
    { lat: 48.2955515, lng: 19.8805601 },
    { lat: 48.296545, lng: 19.880535 },
    { lat: 48.296984, lng: 19.880393 },
    { lat: 48.298096, lng: 19.880533 },
    { lat: 48.300656, lng: 19.880241 },
    { lat: 48.301832, lng: 19.880364 },
    { lat: 48.302841, lng: 19.88029 },
    { lat: 48.304228, lng: 19.880319 },
    { lat: 48.306421, lng: 19.880216 },
    { lat: 48.307499, lng: 19.880253 },
    { lat: 48.310077, lng: 19.880755 },
    { lat: 48.310947, lng: 19.880661 },
    { lat: 48.311052, lng: 19.879339 },
    { lat: 48.311114, lng: 19.878416 },
    { lat: 48.311135, lng: 19.878307 },
    { lat: 48.311231, lng: 19.877809 },
    { lat: 48.311268, lng: 19.877617 },
    { lat: 48.31136, lng: 19.877131 },
    { lat: 48.311393, lng: 19.876964 },
    { lat: 48.31146, lng: 19.876709 },
    { lat: 48.311169, lng: 19.875655 },
    { lat: 48.310762, lng: 19.875491 },
    { lat: 48.310759, lng: 19.875184 },
    { lat: 48.31139, lng: 19.87463 },
    { lat: 48.311444, lng: 19.874248 },
    { lat: 48.3114126, lng: 19.874011 },
    { lat: 48.311534, lng: 19.8727298 },
    { lat: 48.3109656, lng: 19.8712876 },
    { lat: 48.3110642, lng: 19.8684599 },
    { lat: 48.3108567, lng: 19.8676258 },
    { lat: 48.3099568, lng: 19.8662249 },
    { lat: 48.3094687, lng: 19.8645284 },
    { lat: 48.308293, lng: 19.8617056 },
    { lat: 48.3083505, lng: 19.8607724 },
    { lat: 48.3080425, lng: 19.8597723 },
    { lat: 48.3076687, lng: 19.8588454 },
    { lat: 48.307788, lng: 19.8578298 },
    { lat: 48.3076529, lng: 19.856637 },
    { lat: 48.3073578, lng: 19.8548765 },
    { lat: 48.3073911, lng: 19.8526084 },
    { lat: 48.3072952, lng: 19.8513304 },
    { lat: 48.307255, lng: 19.8510337 },
    { lat: 48.307093, lng: 19.8498385 },
    { lat: 48.3070547, lng: 19.8495561 },
    { lat: 48.3068901, lng: 19.8489579 },
    { lat: 48.306956, lng: 19.8483236 },
    { lat: 48.3068894, lng: 19.8478007 },
    { lat: 48.3050881, lng: 19.8442366 },
    { lat: 48.3049053, lng: 19.8441749 },
    { lat: 48.3039726, lng: 19.842598 },
    { lat: 48.3035166, lng: 19.8416464 },
    { lat: 48.3033195, lng: 19.8414191 },
    { lat: 48.3031497, lng: 19.840182 },
    { lat: 48.3013063, lng: 19.834567 },
    { lat: 48.300003, lng: 19.8355569 },
    { lat: 48.29935, lng: 19.8350208 },
    { lat: 48.2983498, lng: 19.8343283 },
    { lat: 48.2949167, lng: 19.8316129 },
    { lat: 48.2944439, lng: 19.8313129 },
    { lat: 48.2935758, lng: 19.8305233 },
    { lat: 48.2935404, lng: 19.830565 },
    { lat: 48.2928172, lng: 19.8292494 },
    { lat: 48.2924335, lng: 19.8285883 },
  ],
  StaráHalič: [
    { lat: 48.3996722, lng: 19.5210908 },
    { lat: 48.3992476, lng: 19.521877 },
    { lat: 48.3988139, lng: 19.5225119 },
    { lat: 48.3984078, lng: 19.5233362 },
    { lat: 48.3975037, lng: 19.523716 },
    { lat: 48.3966496, lng: 19.524499 },
    { lat: 48.3962025, lng: 19.524702 },
    { lat: 48.3957176, lng: 19.5252763 },
    { lat: 48.3952618, lng: 19.5260723 },
    { lat: 48.3951703, lng: 19.5263358 },
    { lat: 48.3946601, lng: 19.5265375 },
    { lat: 48.3939162, lng: 19.5248517 },
    { lat: 48.3935087, lng: 19.5244953 },
    { lat: 48.3930944, lng: 19.5244583 },
    { lat: 48.3929707, lng: 19.5246074 },
    { lat: 48.3928821, lng: 19.5245194 },
    { lat: 48.3927167, lng: 19.5245275 },
    { lat: 48.3925271, lng: 19.524421 },
    { lat: 48.3922029, lng: 19.5239928 },
    { lat: 48.3921493, lng: 19.5241516 },
    { lat: 48.3920474, lng: 19.5241639 },
    { lat: 48.3909363, lng: 19.5239178 },
    { lat: 48.3901312, lng: 19.5238858 },
    { lat: 48.3873486, lng: 19.5232257 },
    { lat: 48.3868077, lng: 19.5230296 },
    { lat: 48.386779, lng: 19.5230149 },
    { lat: 48.386206, lng: 19.5237848 },
    { lat: 48.3856744, lng: 19.5250101 },
    { lat: 48.3852715, lng: 19.5256577 },
    { lat: 48.3838044, lng: 19.5268532 },
    { lat: 48.3828048, lng: 19.5282697 },
    { lat: 48.3808828, lng: 19.5300116 },
    { lat: 48.3798354, lng: 19.5307296 },
    { lat: 48.3785816, lng: 19.5313266 },
    { lat: 48.3780862, lng: 19.5316543 },
    { lat: 48.3778548, lng: 19.5315994 },
    { lat: 48.3776271, lng: 19.5316695 },
    { lat: 48.3770147, lng: 19.5316833 },
    { lat: 48.3767053, lng: 19.5318906 },
    { lat: 48.3757681, lng: 19.5331404 },
    { lat: 48.3748959, lng: 19.5347025 },
    { lat: 48.3742593, lng: 19.5355127 },
    { lat: 48.3736038, lng: 19.5361058 },
    { lat: 48.3734428, lng: 19.5363267 },
    { lat: 48.3710333, lng: 19.539795 },
    { lat: 48.3678889, lng: 19.5452626 },
    { lat: 48.3634962, lng: 19.5533635 },
    { lat: 48.3634728, lng: 19.5532713 },
    { lat: 48.363401, lng: 19.5533993 },
    { lat: 48.3578999, lng: 19.5635338 },
    { lat: 48.357832, lng: 19.5635001 },
    { lat: 48.3577606, lng: 19.5637608 },
    { lat: 48.3577813, lng: 19.5676268 },
    { lat: 48.3578095, lng: 19.5677008 },
    { lat: 48.3583859, lng: 19.5684204 },
    { lat: 48.3589695, lng: 19.5689841 },
    { lat: 48.3595068, lng: 19.5676069 },
    { lat: 48.3591673, lng: 19.5695121 },
    { lat: 48.3603933, lng: 19.5700067 },
    { lat: 48.3602998, lng: 19.5713086 },
    { lat: 48.3600954, lng: 19.5721351 },
    { lat: 48.3591895, lng: 19.5716702 },
    { lat: 48.3590873, lng: 19.5719947 },
    { lat: 48.3588863, lng: 19.5723287 },
    { lat: 48.3596088, lng: 19.572776 },
    { lat: 48.3597613, lng: 19.5729555 },
    { lat: 48.3598538, lng: 19.5731795 },
    { lat: 48.3599393, lng: 19.5734484 },
    { lat: 48.3599217, lng: 19.5739509 },
    { lat: 48.3600198, lng: 19.5742714 },
    { lat: 48.3599409, lng: 19.5743692 },
    { lat: 48.3599635, lng: 19.5745171 },
    { lat: 48.3597316, lng: 19.5748146 },
    { lat: 48.3596908, lng: 19.574936 },
    { lat: 48.3597782, lng: 19.5749465 },
    { lat: 48.3599069, lng: 19.5751001 },
    { lat: 48.36032, lng: 19.5759731 },
    { lat: 48.3604186, lng: 19.5763322 },
    { lat: 48.3603863, lng: 19.5769304 },
    { lat: 48.3605383, lng: 19.5770278 },
    { lat: 48.360564, lng: 19.577119 },
    { lat: 48.3605065, lng: 19.5772196 },
    { lat: 48.3604167, lng: 19.5771771 },
    { lat: 48.3599061, lng: 19.5775154 },
    { lat: 48.3598812, lng: 19.5777146 },
    { lat: 48.3597547, lng: 19.5780508 },
    { lat: 48.3597391, lng: 19.578299 },
    { lat: 48.3597438, lng: 19.5784778 },
    { lat: 48.3598544, lng: 19.5787995 },
    { lat: 48.3596953, lng: 19.5791674 },
    { lat: 48.3596259, lng: 19.5790757 },
    { lat: 48.3592647, lng: 19.5792826 },
    { lat: 48.3592321, lng: 19.5791648 },
    { lat: 48.3586722, lng: 19.5790188 },
    { lat: 48.3585095, lng: 19.57933 },
    { lat: 48.3584519, lng: 19.5792775 },
    { lat: 48.3584388, lng: 19.579141 },
    { lat: 48.3582382, lng: 19.5790143 },
    { lat: 48.3581687, lng: 19.5791001 },
    { lat: 48.358161, lng: 19.5793248 },
    { lat: 48.3581185, lng: 19.5793806 },
    { lat: 48.3579566, lng: 19.5791932 },
    { lat: 48.3576049, lng: 19.5795094 },
    { lat: 48.3576616, lng: 19.5797646 },
    { lat: 48.357626, lng: 19.5798944 },
    { lat: 48.3576745, lng: 19.5800928 },
    { lat: 48.3576465, lng: 19.5802233 },
    { lat: 48.3577129, lng: 19.5803177 },
    { lat: 48.3578138, lng: 19.5808502 },
    { lat: 48.3576882, lng: 19.5808861 },
    { lat: 48.3576217, lng: 19.5810585 },
    { lat: 48.3575395, lng: 19.5810329 },
    { lat: 48.3574439, lng: 19.581306 },
    { lat: 48.3571698, lng: 19.5814002 },
    { lat: 48.3570746, lng: 19.5810691 },
    { lat: 48.3568782, lng: 19.580942 },
    { lat: 48.3566425, lng: 19.5810867 },
    { lat: 48.3565586, lng: 19.5810734 },
    { lat: 48.356288, lng: 19.5813635 },
    { lat: 48.355872, lng: 19.5820512 },
    { lat: 48.3555803, lng: 19.5818779 },
    { lat: 48.3554509, lng: 19.5819574 },
    { lat: 48.3554063, lng: 19.5821884 },
    { lat: 48.3549182, lng: 19.5829261 },
    { lat: 48.3548013, lng: 19.5834986 },
    { lat: 48.3541674, lng: 19.5843961 },
    { lat: 48.3540039, lng: 19.584864 },
    { lat: 48.3538861, lng: 19.5848279 },
    { lat: 48.3536917, lng: 19.584889 },
    { lat: 48.3532445, lng: 19.5851115 },
    { lat: 48.3531351, lng: 19.5849385 },
    { lat: 48.3529563, lng: 19.58502 },
    { lat: 48.3528197, lng: 19.5849419 },
    { lat: 48.352616, lng: 19.58502 },
    { lat: 48.3524639, lng: 19.585313 },
    { lat: 48.3523014, lng: 19.5852624 },
    { lat: 48.3522679, lng: 19.5854427 },
    { lat: 48.3521501, lng: 19.5856241 },
    { lat: 48.3520652, lng: 19.585668 },
    { lat: 48.3521054, lng: 19.5857836 },
    { lat: 48.3520173, lng: 19.5859007 },
    { lat: 48.3517927, lng: 19.5866331 },
    { lat: 48.3515981, lng: 19.5865394 },
    { lat: 48.3513476, lng: 19.5865301 },
    { lat: 48.3510648, lng: 19.586657 },
    { lat: 48.3509563, lng: 19.5867734 },
    { lat: 48.3508535, lng: 19.5867446 },
    { lat: 48.3507856, lng: 19.5868345 },
    { lat: 48.3506549, lng: 19.5870807 },
    { lat: 48.3506551, lng: 19.5871841 },
    { lat: 48.3507806, lng: 19.58732 },
    { lat: 48.3507699, lng: 19.5874054 },
    { lat: 48.3505889, lng: 19.5880026 },
    { lat: 48.3504011, lng: 19.5881601 },
    { lat: 48.3503805, lng: 19.5882277 },
    { lat: 48.3504597, lng: 19.5883315 },
    { lat: 48.3503241, lng: 19.5886455 },
    { lat: 48.3501662, lng: 19.5885449 },
    { lat: 48.3501014, lng: 19.5886305 },
    { lat: 48.3500434, lng: 19.5888381 },
    { lat: 48.3498992, lng: 19.5889648 },
    { lat: 48.3497633, lng: 19.5892984 },
    { lat: 48.3497832, lng: 19.5894589 },
    { lat: 48.3499946, lng: 19.5897185 },
    { lat: 48.3500695, lng: 19.589906 },
    { lat: 48.3500029, lng: 19.5900506 },
    { lat: 48.3498312, lng: 19.5901168 },
    { lat: 48.3497538, lng: 19.5903703 },
    { lat: 48.3497935, lng: 19.5907431 },
    { lat: 48.349599, lng: 19.5907826 },
    { lat: 48.3495618, lng: 19.5909742 },
    { lat: 48.3490167, lng: 19.5906101 },
    { lat: 48.3476926, lng: 19.5933996 },
    { lat: 48.3465272, lng: 19.5967283 },
    { lat: 48.3455312, lng: 19.5993485 },
    { lat: 48.3451616, lng: 19.60004 },
    { lat: 48.3441503, lng: 19.6016613 },
    { lat: 48.343231, lng: 19.6037636 },
    { lat: 48.3426862, lng: 19.6048928 },
    { lat: 48.337507, lng: 19.6150073 },
    { lat: 48.3373375, lng: 19.6153916 },
    { lat: 48.3371887, lng: 19.6160879 },
    { lat: 48.3371868, lng: 19.6166888 },
    { lat: 48.3372913, lng: 19.6174239 },
    { lat: 48.3372317, lng: 19.6174276 },
    { lat: 48.3371765, lng: 19.617429 },
    { lat: 48.3375496, lng: 19.61944 },
    { lat: 48.3376556, lng: 19.6194447 },
    { lat: 48.3377454, lng: 19.6198028 },
    { lat: 48.3389271, lng: 19.6207406 },
    { lat: 48.3394525, lng: 19.6199265 },
    { lat: 48.3395704, lng: 19.6198181 },
    { lat: 48.3402936, lng: 19.6185746 },
    { lat: 48.3418136, lng: 19.6165153 },
    { lat: 48.3433824, lng: 19.6147839 },
    { lat: 48.3439039, lng: 19.6144609 },
    { lat: 48.34493, lng: 19.6135795 },
    { lat: 48.3459633, lng: 19.6131598 },
    { lat: 48.3468568, lng: 19.6131346 },
    { lat: 48.347346, lng: 19.612099 },
    { lat: 48.3475625, lng: 19.6111866 },
    { lat: 48.3482041, lng: 19.6101531 },
    { lat: 48.3483416, lng: 19.6098351 },
    { lat: 48.3488677, lng: 19.6082214 },
    { lat: 48.3500168, lng: 19.6067036 },
    { lat: 48.3518127, lng: 19.602914 },
    { lat: 48.3526934, lng: 19.6016163 },
    { lat: 48.3541015, lng: 19.599706 },
    { lat: 48.356419, lng: 19.598926 },
    { lat: 48.3593024, lng: 19.5993194 },
    { lat: 48.3636878, lng: 19.5982524 },
    { lat: 48.3652868, lng: 19.5975835 },
    { lat: 48.3660348, lng: 19.5976271 },
    { lat: 48.3666551, lng: 19.5974922 },
    { lat: 48.3687182, lng: 19.5967479 },
    { lat: 48.3718946, lng: 19.5951842 },
    { lat: 48.372024, lng: 19.5948385 },
    { lat: 48.372453, lng: 19.5941132 },
    { lat: 48.3725715, lng: 19.5937239 },
    { lat: 48.37276, lng: 19.5933309 },
    { lat: 48.3727902, lng: 19.5931545 },
    { lat: 48.3730092, lng: 19.5926982 },
    { lat: 48.3730945, lng: 19.5922425 },
    { lat: 48.3733859, lng: 19.5913949 },
    { lat: 48.3733958, lng: 19.5912152 },
    { lat: 48.3734801, lng: 19.5909652 },
    { lat: 48.3738368, lng: 19.5903844 },
    { lat: 48.3740863, lng: 19.5901493 },
    { lat: 48.3743329, lng: 19.5899233 },
    { lat: 48.3746721, lng: 19.5836139 },
    { lat: 48.3746971, lng: 19.5822871 },
    { lat: 48.3747719, lng: 19.5821968 },
    { lat: 48.3748321, lng: 19.5819851 },
    { lat: 48.3748924, lng: 19.5820745 },
    { lat: 48.3751145, lng: 19.5818766 },
    { lat: 48.3750891, lng: 19.5817913 },
    { lat: 48.375235, lng: 19.5814813 },
    { lat: 48.3753623, lng: 19.5816057 },
    { lat: 48.3753815, lng: 19.5815081 },
    { lat: 48.3754344, lng: 19.5815047 },
    { lat: 48.375441, lng: 19.5812808 },
    { lat: 48.3756273, lng: 19.5810914 },
    { lat: 48.3756458, lng: 19.5809279 },
    { lat: 48.3755877, lng: 19.580695 },
    { lat: 48.3757156, lng: 19.5806095 },
    { lat: 48.3756995, lng: 19.5805264 },
    { lat: 48.3757742, lng: 19.5804624 },
    { lat: 48.375698, lng: 19.5803969 },
    { lat: 48.3757373, lng: 19.5801199 },
    { lat: 48.3758232, lng: 19.5800777 },
    { lat: 48.3758779, lng: 19.5799668 },
    { lat: 48.3759404, lng: 19.5800064 },
    { lat: 48.3760194, lng: 19.5799579 },
    { lat: 48.3760631, lng: 19.5799002 },
    { lat: 48.3760602, lng: 19.5797906 },
    { lat: 48.3761536, lng: 19.5796658 },
    { lat: 48.3762305, lng: 19.5796487 },
    { lat: 48.376251, lng: 19.5794038 },
    { lat: 48.3762963, lng: 19.5793626 },
    { lat: 48.37628, lng: 19.5792235 },
    { lat: 48.3763856, lng: 19.5789245 },
    { lat: 48.3763533, lng: 19.5788793 },
    { lat: 48.3763684, lng: 19.5787448 },
    { lat: 48.3764618, lng: 19.5787901 },
    { lat: 48.3764891, lng: 19.5786728 },
    { lat: 48.3765614, lng: 19.5786015 },
    { lat: 48.3765826, lng: 19.5783013 },
    { lat: 48.377081, lng: 19.5776958 },
    { lat: 48.3771796, lng: 19.5777044 },
    { lat: 48.3772623, lng: 19.5772259 },
    { lat: 48.377434, lng: 19.5771093 },
    { lat: 48.3774914, lng: 19.576913 },
    { lat: 48.3777879, lng: 19.5766173 },
    { lat: 48.3778082, lng: 19.5762778 },
    { lat: 48.377922, lng: 19.5763025 },
    { lat: 48.3780993, lng: 19.576162 },
    { lat: 48.3791368, lng: 19.5746746 },
    { lat: 48.3795636, lng: 19.5736876 },
    { lat: 48.380097, lng: 19.5720243 },
    { lat: 48.3803708, lng: 19.5709907 },
    { lat: 48.3803014, lng: 19.5707285 },
    { lat: 48.3802806, lng: 19.5700522 },
    { lat: 48.3804987, lng: 19.5696489 },
    { lat: 48.3804806, lng: 19.5694289 },
    { lat: 48.3805223, lng: 19.5693757 },
    { lat: 48.3805336, lng: 19.5692054 },
    { lat: 48.3808502, lng: 19.5686633 },
    { lat: 48.381024, lng: 19.5685 },
    { lat: 48.3812021, lng: 19.568527 },
    { lat: 48.3814361, lng: 19.5683929 },
    { lat: 48.3817531, lng: 19.5681772 },
    { lat: 48.3818464, lng: 19.5680027 },
    { lat: 48.3824663, lng: 19.5677628 },
    { lat: 48.382851, lng: 19.5674228 },
    { lat: 48.3829422, lng: 19.5674506 },
    { lat: 48.3829938, lng: 19.5673705 },
    { lat: 48.3831428, lng: 19.5673239 },
    { lat: 48.383332, lng: 19.5673454 },
    { lat: 48.3835636, lng: 19.5674846 },
    { lat: 48.3841215, lng: 19.5676112 },
    { lat: 48.3841912, lng: 19.5675296 },
    { lat: 48.3845516, lng: 19.567504 },
    { lat: 48.3846731, lng: 19.5673277 },
    { lat: 48.3850898, lng: 19.5670272 },
    { lat: 48.385348, lng: 19.5666836 },
    { lat: 48.3859116, lng: 19.5657046 },
    { lat: 48.386362, lng: 19.5651553 },
    { lat: 48.3868078, lng: 19.5643371 },
    { lat: 48.3874153, lng: 19.5638506 },
    { lat: 48.3878976, lng: 19.5627686 },
    { lat: 48.3883495, lng: 19.5623301 },
    { lat: 48.3886862, lng: 19.5615879 },
    { lat: 48.3896959, lng: 19.559916 },
    { lat: 48.3899234, lng: 19.5588603 },
    { lat: 48.3904084, lng: 19.5578631 },
    { lat: 48.3913806, lng: 19.556803 },
    { lat: 48.3920762, lng: 19.5563653 },
    { lat: 48.3928536, lng: 19.5562883 },
    { lat: 48.3937394, lng: 19.5558278 },
    { lat: 48.3940445, lng: 19.5553561 },
    { lat: 48.3947719, lng: 19.5548549 },
    { lat: 48.3949292, lng: 19.5548187 },
    { lat: 48.3954682, lng: 19.5551657 },
    { lat: 48.3960669, lng: 19.5557383 },
    { lat: 48.3963342, lng: 19.5558195 },
    { lat: 48.3966273, lng: 19.555698 },
    { lat: 48.3969628, lng: 19.5558611 },
    { lat: 48.3973843, lng: 19.5556908 },
    { lat: 48.3976041, lng: 19.5554936 },
    { lat: 48.397928, lng: 19.5548915 },
    { lat: 48.398159, lng: 19.554574 },
    { lat: 48.3982846, lng: 19.5544854 },
    { lat: 48.3987518, lng: 19.5545767 },
    { lat: 48.3988988, lng: 19.5547331 },
    { lat: 48.3990806, lng: 19.5547175 },
    { lat: 48.3994864, lng: 19.5548899 },
    { lat: 48.4004625, lng: 19.5550405 },
    { lat: 48.4024224, lng: 19.5551225 },
    { lat: 48.4026686, lng: 19.5554998 },
    { lat: 48.4029592, lng: 19.5554974 },
    { lat: 48.4035537, lng: 19.5552652 },
    { lat: 48.4036864, lng: 19.5551358 },
    { lat: 48.4038249, lng: 19.5551587 },
    { lat: 48.4043405, lng: 19.5548318 },
    { lat: 48.4045486, lng: 19.5543307 },
    { lat: 48.4046182, lng: 19.5539852 },
    { lat: 48.4050005, lng: 19.553178 },
    { lat: 48.4052161, lng: 19.5528698 },
    { lat: 48.4052493, lng: 19.5522929 },
    { lat: 48.4054213, lng: 19.5512061 },
    { lat: 48.4054482, lng: 19.5507377 },
    { lat: 48.4056102, lng: 19.5498707 },
    { lat: 48.4062398, lng: 19.5477828 },
    { lat: 48.4063121, lng: 19.5460502 },
    { lat: 48.4063228, lng: 19.5451347 },
    { lat: 48.4062732, lng: 19.5444206 },
    { lat: 48.4063054, lng: 19.5442633 },
    { lat: 48.4071667, lng: 19.5421699 },
    { lat: 48.4075355, lng: 19.5416872 },
    { lat: 48.4075874, lng: 19.5414438 },
    { lat: 48.408023, lng: 19.5404987 },
    { lat: 48.4082517, lng: 19.5401589 },
    { lat: 48.408523, lng: 19.5385658 },
    { lat: 48.4084273, lng: 19.5375852 },
    { lat: 48.408377, lng: 19.5374014 },
    { lat: 48.4079557, lng: 19.5372568 },
    { lat: 48.4076109, lng: 19.5369909 },
    { lat: 48.4069977, lng: 19.5361559 },
    { lat: 48.4068645, lng: 19.5358605 },
    { lat: 48.406159, lng: 19.5353902 },
    { lat: 48.4058701, lng: 19.5350656 },
    { lat: 48.4056032, lng: 19.534754 },
    { lat: 48.4055309, lng: 19.534526 },
    { lat: 48.4051334, lng: 19.5339006 },
    { lat: 48.4050577, lng: 19.5336528 },
    { lat: 48.4047844, lng: 19.5334404 },
    { lat: 48.404514, lng: 19.5330526 },
    { lat: 48.4044013, lng: 19.5330551 },
    { lat: 48.404003, lng: 19.5334565 },
    { lat: 48.4035572, lng: 19.5335192 },
    { lat: 48.4032137, lng: 19.5334245 },
    { lat: 48.402795, lng: 19.5334844 },
    { lat: 48.4025136, lng: 19.5336346 },
    { lat: 48.4022467, lng: 19.5339348 },
    { lat: 48.4021038, lng: 19.5339407 },
    { lat: 48.4013174, lng: 19.5347482 },
    { lat: 48.401265, lng: 19.5348611 },
    { lat: 48.400972, lng: 19.5350572 },
    { lat: 48.4007718, lng: 19.5351131 },
    { lat: 48.4000808, lng: 19.5356198 },
    { lat: 48.3997605, lng: 19.5362024 },
    { lat: 48.3993944, lng: 19.5366448 },
    { lat: 48.399268, lng: 19.5367143 },
    { lat: 48.3987378, lng: 19.537534 },
    { lat: 48.3986392, lng: 19.5375464 },
    { lat: 48.3983658, lng: 19.5377472 },
    { lat: 48.3981798, lng: 19.5378981 },
    { lat: 48.3979755, lng: 19.5381839 },
    { lat: 48.3973277, lng: 19.5386473 },
    { lat: 48.3971052, lng: 19.5379769 },
    { lat: 48.3966566, lng: 19.5377306 },
    { lat: 48.3968, lng: 19.5373944 },
    { lat: 48.3969133, lng: 19.5367483 },
    { lat: 48.3971981, lng: 19.5361293 },
    { lat: 48.397375, lng: 19.5354488 },
    { lat: 48.3973889, lng: 19.535013 },
    { lat: 48.3974923, lng: 19.5347161 },
    { lat: 48.3975779, lng: 19.5342281 },
    { lat: 48.3975846, lng: 19.5339334 },
    { lat: 48.3975145, lng: 19.5333904 },
    { lat: 48.3975868, lng: 19.5325169 },
    { lat: 48.397724, lng: 19.5315547 },
    { lat: 48.3975557, lng: 19.5310354 },
    { lat: 48.397389, lng: 19.5307698 },
    { lat: 48.3971765, lng: 19.5296603 },
    { lat: 48.397199, lng: 19.5293658 },
    { lat: 48.3974856, lng: 19.5286186 },
    { lat: 48.3975342, lng: 19.5273763 },
    { lat: 48.3980236, lng: 19.5260192 },
    { lat: 48.3986762, lng: 19.5251884 },
    { lat: 48.3994141, lng: 19.5247225 },
    { lat: 48.3995681, lng: 19.5244926 },
    { lat: 48.399655, lng: 19.5242534 },
    { lat: 48.3996549, lng: 19.5225451 },
    { lat: 48.3995896, lng: 19.5219597 },
    { lat: 48.3996607, lng: 19.5214041 },
    { lat: 48.3996722, lng: 19.5210908 },
  ],
  Trenč: [
    { lat: 48.213452, lng: 19.5628695 },
    { lat: 48.213524, lng: 19.563186 },
    { lat: 48.213604, lng: 19.563537 },
    { lat: 48.213671, lng: 19.563892 },
    { lat: 48.213777, lng: 19.564272 },
    { lat: 48.213866, lng: 19.564509 },
    { lat: 48.21397, lng: 19.564737 },
    { lat: 48.214116, lng: 19.565016 },
    { lat: 48.214261, lng: 19.565253 },
    { lat: 48.214429, lng: 19.565508 },
    { lat: 48.214582, lng: 19.565753 },
    { lat: 48.214741, lng: 19.565995 },
    { lat: 48.214904, lng: 19.566228 },
    { lat: 48.215066, lng: 19.56646 },
    { lat: 48.215118, lng: 19.566545 },
    { lat: 48.215217, lng: 19.566731 },
    { lat: 48.215284, lng: 19.566881 },
    { lat: 48.215362, lng: 19.567093 },
    { lat: 48.21542, lng: 19.567312 },
    { lat: 48.215481, lng: 19.567601 },
    { lat: 48.215531, lng: 19.567947 },
    { lat: 48.215543, lng: 19.568315 },
    { lat: 48.215544, lng: 19.568641 },
    { lat: 48.215553, lng: 19.568897 },
    { lat: 48.215559, lng: 19.5692 },
    { lat: 48.21557, lng: 19.569549 },
    { lat: 48.215576, lng: 19.569928 },
    { lat: 48.215584, lng: 19.570241 },
    { lat: 48.215594, lng: 19.570575 },
    { lat: 48.215598, lng: 19.570916 },
    { lat: 48.215601, lng: 19.571264 },
    { lat: 48.215612, lng: 19.571545 },
    { lat: 48.215619, lng: 19.571812 },
    { lat: 48.215629, lng: 19.572162 },
    { lat: 48.215639, lng: 19.572555 },
    { lat: 48.215647, lng: 19.572886 },
    { lat: 48.215653, lng: 19.573246 },
    { lat: 48.215662, lng: 19.573606 },
    { lat: 48.21567, lng: 19.573952 },
    { lat: 48.215681, lng: 19.574304 },
    { lat: 48.215685, lng: 19.574558 },
    { lat: 48.215688, lng: 19.57476 },
    { lat: 48.215691, lng: 19.574929 },
    { lat: 48.215694, lng: 19.575305 },
    { lat: 48.215699, lng: 19.575633 },
    { lat: 48.215716, lng: 19.575965 },
    { lat: 48.215744, lng: 19.576291 },
    { lat: 48.215778, lng: 19.576608 },
    { lat: 48.215836, lng: 19.576902 },
    { lat: 48.215907, lng: 19.577205 },
    { lat: 48.216015, lng: 19.577502 },
    { lat: 48.216151, lng: 19.577775 },
    { lat: 48.216283, lng: 19.577991 },
    { lat: 48.216502, lng: 19.578262 },
    { lat: 48.216687, lng: 19.578462 },
    { lat: 48.216879, lng: 19.578657 },
    { lat: 48.217073, lng: 19.578871 },
    { lat: 48.217262, lng: 19.579062 },
    { lat: 48.217445, lng: 19.579271 },
    { lat: 48.217636, lng: 19.579487 },
    { lat: 48.217784, lng: 19.579646 },
    { lat: 48.21792, lng: 19.579796 },
    { lat: 48.218127, lng: 19.580051 },
    { lat: 48.218318, lng: 19.580257 },
    { lat: 48.218488, lng: 19.580462 },
    { lat: 48.218647, lng: 19.580662 },
    { lat: 48.218808, lng: 19.580837 },
    { lat: 48.218935, lng: 19.580994 },
    { lat: 48.219101, lng: 19.581227 },
    { lat: 48.219247, lng: 19.581473 },
    { lat: 48.219391, lng: 19.581729 },
    { lat: 48.219537, lng: 19.581994 },
    { lat: 48.219666, lng: 19.582216 },
    { lat: 48.219792, lng: 19.582423 },
    { lat: 48.219821, lng: 19.58245 },
    { lat: 48.219868, lng: 19.582526 },
    { lat: 48.220004, lng: 19.582836 },
    { lat: 48.220113, lng: 19.583061 },
    { lat: 48.220217, lng: 19.58334 },
    { lat: 48.220349, lng: 19.583646 },
    { lat: 48.220461, lng: 19.583866 },
    { lat: 48.220599, lng: 19.584176 },
    { lat: 48.220726, lng: 19.584408 },
    { lat: 48.220864, lng: 19.584647 },
    { lat: 48.22092, lng: 19.584749 },
    { lat: 48.221004, lng: 19.584884 },
    { lat: 48.221117, lng: 19.585085 },
    { lat: 48.221276, lng: 19.585352 },
    { lat: 48.221415, lng: 19.585609 },
    { lat: 48.221572, lng: 19.58584 },
    { lat: 48.221732, lng: 19.586058 },
    { lat: 48.221924, lng: 19.586256 },
    { lat: 48.222074, lng: 19.586419 },
    { lat: 48.222267, lng: 19.586612 },
    { lat: 48.222452, lng: 19.586771 },
    { lat: 48.222647, lng: 19.58697 },
    { lat: 48.222786, lng: 19.587076 },
    { lat: 48.222896, lng: 19.587201 },
    { lat: 48.222986, lng: 19.587297 },
    { lat: 48.223065, lng: 19.587365 },
    { lat: 48.223176, lng: 19.587479 },
    { lat: 48.223246, lng: 19.587552 },
    { lat: 48.223423, lng: 19.58774 },
    { lat: 48.223603, lng: 19.587953 },
    { lat: 48.223705, lng: 19.588085 },
    { lat: 48.223834, lng: 19.588278 },
    { lat: 48.224002, lng: 19.588506 },
    { lat: 48.224128, lng: 19.588684 },
    { lat: 48.224263, lng: 19.588891 },
    { lat: 48.224344, lng: 19.589029 },
    { lat: 48.224456, lng: 19.58921 },
    { lat: 48.224579, lng: 19.589426 },
    { lat: 48.224649, lng: 19.589513 },
    { lat: 48.224742, lng: 19.589657 },
    { lat: 48.224888, lng: 19.589858 },
    { lat: 48.225006, lng: 19.590042 },
    { lat: 48.225083, lng: 19.590167 },
    { lat: 48.225217, lng: 19.590346 },
    { lat: 48.225327, lng: 19.59052 },
    { lat: 48.22539, lng: 19.590626 },
    { lat: 48.225544, lng: 19.590793 },
    { lat: 48.225729, lng: 19.590923 },
    { lat: 48.225903, lng: 19.591066 },
    { lat: 48.226103, lng: 19.5912 },
    { lat: 48.226325, lng: 19.591373 },
    { lat: 48.226529, lng: 19.591499 },
    { lat: 48.226775, lng: 19.591685 },
    { lat: 48.226979, lng: 19.591823 },
    { lat: 48.227243, lng: 19.592005 },
    { lat: 48.22735, lng: 19.592089 },
    { lat: 48.227536, lng: 19.592269 },
    { lat: 48.227714, lng: 19.592461 },
    { lat: 48.227836, lng: 19.592604 },
    { lat: 48.227994, lng: 19.592857 },
    { lat: 48.228098, lng: 19.593078 },
    { lat: 48.228208, lng: 19.593306 },
    { lat: 48.228325, lng: 19.593587 },
    { lat: 48.228422, lng: 19.593809 },
    { lat: 48.228504, lng: 19.593995 },
    { lat: 48.2286, lng: 19.594235 },
    { lat: 48.228724, lng: 19.594521 },
    { lat: 48.228841, lng: 19.594786 },
    { lat: 48.228971, lng: 19.595073 },
    { lat: 48.229081, lng: 19.595303 },
    { lat: 48.229223, lng: 19.59564 },
    { lat: 48.229345, lng: 19.595919 },
    { lat: 48.229466, lng: 19.596197 },
    { lat: 48.229576, lng: 19.596457 },
    { lat: 48.229695, lng: 19.596712 },
    { lat: 48.229824, lng: 19.596992 },
    { lat: 48.229955, lng: 19.597278 },
    { lat: 48.230086, lng: 19.597578 },
    { lat: 48.230224, lng: 19.597863 },
    { lat: 48.230357, lng: 19.598152 },
    { lat: 48.230484, lng: 19.598408 },
    { lat: 48.230615, lng: 19.59869 },
    { lat: 48.230741, lng: 19.598975 },
    { lat: 48.230865, lng: 19.599209 },
    { lat: 48.230961, lng: 19.599412 },
    { lat: 48.231019, lng: 19.599557 },
    { lat: 48.231102, lng: 19.599822 },
    { lat: 48.23115, lng: 19.600012 },
    { lat: 48.231181, lng: 19.60016 },
    { lat: 48.231252, lng: 19.600541 },
    { lat: 48.231256, lng: 19.600605 },
    { lat: 48.231282, lng: 19.600773 },
    { lat: 48.231285, lng: 19.600802 },
    { lat: 48.231349, lng: 19.601119 },
    { lat: 48.231425, lng: 19.60157 },
    { lat: 48.231437, lng: 19.601752 },
    { lat: 48.231458, lng: 19.601918 },
    { lat: 48.231488, lng: 19.602035 },
    { lat: 48.231522, lng: 19.602132 },
    { lat: 48.231566, lng: 19.602213 },
    { lat: 48.231635, lng: 19.602326 },
    { lat: 48.231776, lng: 19.602477 },
    { lat: 48.231812, lng: 19.602518 },
    { lat: 48.231964, lng: 19.602685 },
    { lat: 48.232094, lng: 19.602819 },
    { lat: 48.232214, lng: 19.602943 },
    { lat: 48.232549, lng: 19.60321 },
    { lat: 48.232729, lng: 19.603344 },
    { lat: 48.232808, lng: 19.603417 },
    { lat: 48.232944, lng: 19.60352 },
    { lat: 48.233238, lng: 19.603746 },
    { lat: 48.233618, lng: 19.60404 },
    { lat: 48.233746, lng: 19.604138 },
    { lat: 48.234018, lng: 19.604312 },
    { lat: 48.234204, lng: 19.604431 },
    { lat: 48.234389, lng: 19.604545 },
    { lat: 48.234516, lng: 19.604637 },
    { lat: 48.23454, lng: 19.604664 },
    { lat: 48.234617, lng: 19.604719 },
    { lat: 48.234837, lng: 19.604872 },
    { lat: 48.234897, lng: 19.604912 },
    { lat: 48.234994, lng: 19.604989 },
    { lat: 48.235276, lng: 19.605185 },
    { lat: 48.235472, lng: 19.605334 },
    { lat: 48.235785, lng: 19.605549 },
    { lat: 48.235992, lng: 19.605695 },
    { lat: 48.236394, lng: 19.605986 },
    { lat: 48.236584, lng: 19.606125 },
    { lat: 48.236711, lng: 19.606211 },
    { lat: 48.236914, lng: 19.60634 },
    { lat: 48.2368929, lng: 19.6062181 },
    { lat: 48.2368497, lng: 19.6062083 },
    { lat: 48.2367374, lng: 19.6053058 },
    { lat: 48.2365624, lng: 19.6043838 },
    { lat: 48.2366434, lng: 19.6044572 },
    { lat: 48.236633, lng: 19.6043744 },
    { lat: 48.2365449, lng: 19.6042955 },
    { lat: 48.2360458, lng: 19.6014612 },
    { lat: 48.2358852, lng: 19.6002144 },
    { lat: 48.2362555, lng: 19.5991726 },
    { lat: 48.2375507, lng: 19.595017 },
    { lat: 48.237918, lng: 19.5942292 },
    { lat: 48.2384651, lng: 19.592072 },
    { lat: 48.2391, lng: 19.5892556 },
    { lat: 48.2391002, lng: 19.5886674 },
    { lat: 48.2384815, lng: 19.5845863 },
    { lat: 48.238467, lng: 19.5842233 },
    { lat: 48.2385076, lng: 19.5840979 },
    { lat: 48.2391682, lng: 19.584374 },
    { lat: 48.2399707, lng: 19.5844754 },
    { lat: 48.2401486, lng: 19.5855526 },
    { lat: 48.2400504, lng: 19.5869307 },
    { lat: 48.2401971, lng: 19.5876474 },
    { lat: 48.2402151, lng: 19.5886279 },
    { lat: 48.2402634, lng: 19.5889966 },
    { lat: 48.2402878, lng: 19.5891323 },
    { lat: 48.2403924, lng: 19.5893029 },
    { lat: 48.2404826, lng: 19.5898953 },
    { lat: 48.2401705, lng: 19.5912473 },
    { lat: 48.2415236, lng: 19.5919805 },
    { lat: 48.2421375, lng: 19.5923916 },
    { lat: 48.2421593, lng: 19.592178 },
    { lat: 48.2422295, lng: 19.5920127 },
    { lat: 48.2422942, lng: 19.5921737 },
    { lat: 48.2425095, lng: 19.592224 },
    { lat: 48.2427012, lng: 19.5916865 },
    { lat: 48.242524, lng: 19.5916021 },
    { lat: 48.2421925, lng: 19.5916496 },
    { lat: 48.2422682, lng: 19.5911628 },
    { lat: 48.2425759, lng: 19.591214 },
    { lat: 48.2426529, lng: 19.5911205 },
    { lat: 48.2426037, lng: 19.5909089 },
    { lat: 48.2422597, lng: 19.5908781 },
    { lat: 48.2421223, lng: 19.5907094 },
    { lat: 48.2421232, lng: 19.5906252 },
    { lat: 48.2423284, lng: 19.5906773 },
    { lat: 48.2423526, lng: 19.5905921 },
    { lat: 48.242345, lng: 19.5904978 },
    { lat: 48.2422221, lng: 19.590352 },
    { lat: 48.2425696, lng: 19.5903337 },
    { lat: 48.2489685, lng: 19.5808088 },
    { lat: 48.2494446, lng: 19.5799852 },
    { lat: 48.2496455, lng: 19.5799893 },
    { lat: 48.2504674, lng: 19.5789165 },
    { lat: 48.2520692, lng: 19.5770863 },
    { lat: 48.2530147, lng: 19.5763515 },
    { lat: 48.2532223, lng: 19.576259 },
    { lat: 48.2547006, lng: 19.5747854 },
    { lat: 48.2552668, lng: 19.5737147 },
    { lat: 48.2555423, lng: 19.57304 },
    { lat: 48.2559005, lng: 19.5723963 },
    { lat: 48.2562439, lng: 19.5713839 },
    { lat: 48.2564032, lng: 19.570657 },
    { lat: 48.2565995, lng: 19.5703458 },
    { lat: 48.2584394, lng: 19.5681083 },
    { lat: 48.2591937, lng: 19.5664466 },
    { lat: 48.259581, lng: 19.5661495 },
    { lat: 48.2597112, lng: 19.5659663 },
    { lat: 48.2606093, lng: 19.5652997 },
    { lat: 48.2610276, lng: 19.5655031 },
    { lat: 48.2613566, lng: 19.566042 },
    { lat: 48.2618537, lng: 19.5663617 },
    { lat: 48.2619964, lng: 19.5665479 },
    { lat: 48.2624808, lng: 19.5665184 },
    { lat: 48.2627703, lng: 19.5666417 },
    { lat: 48.2629773, lng: 19.5665111 },
    { lat: 48.2642666, lng: 19.5661199 },
    { lat: 48.2646343, lng: 19.5661513 },
    { lat: 48.2649488, lng: 19.5659618 },
    { lat: 48.2651104, lng: 19.5658245 },
    { lat: 48.2651689, lng: 19.5656997 },
    { lat: 48.2655499, lng: 19.5656498 },
    { lat: 48.2659618, lng: 19.5650767 },
    { lat: 48.2662638, lng: 19.5648811 },
    { lat: 48.2678096, lng: 19.5644567 },
    { lat: 48.268198, lng: 19.564044 },
    { lat: 48.268189, lng: 19.563994 },
    { lat: 48.267621, lng: 19.562919 },
    { lat: 48.267721, lng: 19.561596 },
    { lat: 48.26779, lng: 19.560988 },
    { lat: 48.268068, lng: 19.560442 },
    { lat: 48.268084, lng: 19.56001 },
    { lat: 48.267791, lng: 19.559228 },
    { lat: 48.267625, lng: 19.558785 },
    { lat: 48.267877, lng: 19.557296 },
    { lat: 48.267679, lng: 19.556086 },
    { lat: 48.26743, lng: 19.554939 },
    { lat: 48.267369, lng: 19.554657 },
    { lat: 48.267223, lng: 19.553854 },
    { lat: 48.267083, lng: 19.552883 },
    { lat: 48.267068, lng: 19.552763 },
    { lat: 48.267007, lng: 19.552287 },
    { lat: 48.266978, lng: 19.552055 },
    { lat: 48.266948, lng: 19.551811 },
    { lat: 48.266907, lng: 19.551496 },
    { lat: 48.266935, lng: 19.550905 },
    { lat: 48.267463, lng: 19.549359 },
    { lat: 48.26758, lng: 19.549014 },
    { lat: 48.267593, lng: 19.54898 },
    { lat: 48.267584, lng: 19.548646 },
    { lat: 48.267521, lng: 19.545994 },
    { lat: 48.267356, lng: 19.545783 },
    { lat: 48.267047, lng: 19.545376 },
    { lat: 48.266816, lng: 19.544946 },
    { lat: 48.266681, lng: 19.544697 },
    { lat: 48.266314, lng: 19.544008 },
    { lat: 48.265921, lng: 19.543504 },
    { lat: 48.265543, lng: 19.5432 },
    { lat: 48.265306, lng: 19.543194 },
    { lat: 48.264714, lng: 19.542523 },
    { lat: 48.264465, lng: 19.542232 },
    { lat: 48.264076, lng: 19.541966 },
    { lat: 48.26406, lng: 19.541967 },
    { lat: 48.263449, lng: 19.54199 },
    { lat: 48.262859, lng: 19.541672 },
    { lat: 48.262461, lng: 19.541458 },
    { lat: 48.262405, lng: 19.541428 },
    { lat: 48.261014, lng: 19.5422 },
    { lat: 48.26056, lng: 19.542536 },
    { lat: 48.260049, lng: 19.542695 },
    { lat: 48.259937, lng: 19.542729 },
    { lat: 48.258487, lng: 19.54284 },
    { lat: 48.257201, lng: 19.543061 },
    { lat: 48.256289, lng: 19.543317 },
    { lat: 48.255285, lng: 19.543668 },
    { lat: 48.254781, lng: 19.543991 },
    { lat: 48.254044, lng: 19.544376 },
    { lat: 48.253186, lng: 19.544486 },
    { lat: 48.252407, lng: 19.544841 },
    { lat: 48.251939, lng: 19.544997 },
    { lat: 48.250819, lng: 19.545971 },
    { lat: 48.250756, lng: 19.546025 },
    { lat: 48.250748, lng: 19.546032 },
    { lat: 48.250702, lng: 19.546087 },
    { lat: 48.250666, lng: 19.546129 },
    { lat: 48.249958, lng: 19.546961 },
    { lat: 48.249663, lng: 19.547295 },
    { lat: 48.249556, lng: 19.547416 },
    { lat: 48.249167, lng: 19.547422 },
    { lat: 48.249092, lng: 19.547424 },
    { lat: 48.249049, lng: 19.54744 },
    { lat: 48.248298, lng: 19.54707 },
    { lat: 48.247639, lng: 19.546634 },
    { lat: 48.247342, lng: 19.546399 },
    { lat: 48.24692, lng: 19.546214 },
    { lat: 48.246387, lng: 19.546193 },
    { lat: 48.246209, lng: 19.546177 },
    { lat: 48.24582, lng: 19.545991 },
    { lat: 48.24549, lng: 19.546089 },
    { lat: 48.244817, lng: 19.545731 },
    { lat: 48.244652, lng: 19.54564 },
    { lat: 48.244505, lng: 19.545559 },
    { lat: 48.243799, lng: 19.544601 },
    { lat: 48.243724, lng: 19.544609 },
    { lat: 48.243496, lng: 19.54463 },
    { lat: 48.24303, lng: 19.544462 },
    { lat: 48.243012, lng: 19.544455 },
    { lat: 48.242286, lng: 19.544343 },
    { lat: 48.241678, lng: 19.543899 },
    { lat: 48.241575, lng: 19.544002 },
    { lat: 48.240957, lng: 19.54372 },
    { lat: 48.240727, lng: 19.543449 },
    { lat: 48.240379, lng: 19.543283 },
    { lat: 48.239913, lng: 19.542977 },
    { lat: 48.239439, lng: 19.542775 },
    { lat: 48.239169, lng: 19.542562 },
    { lat: 48.238405, lng: 19.542334 },
    { lat: 48.2384, lng: 19.542392 },
    { lat: 48.23791, lng: 19.54219 },
    { lat: 48.237745, lng: 19.541981 },
    { lat: 48.237589, lng: 19.54184 },
    { lat: 48.237486, lng: 19.541849 },
    { lat: 48.237443, lng: 19.541886 },
    { lat: 48.237426, lng: 19.541915 },
    { lat: 48.23739, lng: 19.542076 },
    { lat: 48.237368, lng: 19.542237 },
    { lat: 48.237361, lng: 19.542299 },
    { lat: 48.23734, lng: 19.542335 },
    { lat: 48.237299, lng: 19.542374 },
    { lat: 48.23689, lng: 19.542587 },
    { lat: 48.236825, lng: 19.542603 },
    { lat: 48.236721, lng: 19.542607 },
    { lat: 48.236647, lng: 19.542601 },
    { lat: 48.236598, lng: 19.54259 },
    { lat: 48.23634, lng: 19.542488 },
    { lat: 48.236156, lng: 19.542457 },
    { lat: 48.236114, lng: 19.542464 },
    { lat: 48.236077, lng: 19.542485 },
    { lat: 48.23594, lng: 19.542607 },
    { lat: 48.23575, lng: 19.542732 },
    { lat: 48.23557, lng: 19.542855 },
    { lat: 48.235446, lng: 19.542947 },
    { lat: 48.235325, lng: 19.54306 },
    { lat: 48.235197, lng: 19.543196 },
    { lat: 48.235051, lng: 19.54323 },
    { lat: 48.23501, lng: 19.54322 },
    { lat: 48.234927, lng: 19.543184 },
    { lat: 48.234759, lng: 19.543101 },
    { lat: 48.234655, lng: 19.543045 },
    { lat: 48.234616, lng: 19.543034 },
    { lat: 48.234571, lng: 19.543038 },
    { lat: 48.234523, lng: 19.543052 },
    { lat: 48.234465, lng: 19.543147 },
    { lat: 48.234407, lng: 19.543265 },
    { lat: 48.234366, lng: 19.543362 },
    { lat: 48.234319, lng: 19.543456 },
    { lat: 48.234265, lng: 19.543553 },
    { lat: 48.234191, lng: 19.543614 },
    { lat: 48.234124, lng: 19.543653 },
    { lat: 48.234023, lng: 19.543666 },
    { lat: 48.233986, lng: 19.543665 },
    { lat: 48.233954, lng: 19.543674 },
    { lat: 48.233927, lng: 19.543708 },
    { lat: 48.233903, lng: 19.543851 },
    { lat: 48.233892, lng: 19.544006 },
    { lat: 48.233857, lng: 19.54409 },
    { lat: 48.233804, lng: 19.54421 },
    { lat: 48.233636, lng: 19.544459 },
    { lat: 48.233569, lng: 19.544533 },
    { lat: 48.233465, lng: 19.544632 },
    { lat: 48.233287, lng: 19.544769 },
    { lat: 48.233198, lng: 19.544892 },
    { lat: 48.23316, lng: 19.544975 },
    { lat: 48.233135, lng: 19.545029 },
    { lat: 48.233107, lng: 19.545069 },
    { lat: 48.232856, lng: 19.545242 },
    { lat: 48.232832, lng: 19.545262 },
    { lat: 48.232461, lng: 19.545648 },
    { lat: 48.232435, lng: 19.545678 },
    { lat: 48.232193, lng: 19.546352 },
    { lat: 48.232176, lng: 19.546397 },
    { lat: 48.232141, lng: 19.546423 },
    { lat: 48.231576, lng: 19.546749 },
    { lat: 48.23153, lng: 19.546752 },
    { lat: 48.231189, lng: 19.546755 },
    { lat: 48.231108, lng: 19.546755 },
    { lat: 48.231068, lng: 19.546769 },
    { lat: 48.23103, lng: 19.546788 },
    { lat: 48.230993, lng: 19.546823 },
    { lat: 48.2309, lng: 19.546976 },
    { lat: 48.230875, lng: 19.547018 },
    { lat: 48.230855, lng: 19.547081 },
    { lat: 48.230744, lng: 19.547482 },
    { lat: 48.230715, lng: 19.547554 },
    { lat: 48.230688, lng: 19.547605 },
    { lat: 48.23065, lng: 19.547644 },
    { lat: 48.230597, lng: 19.547656 },
    { lat: 48.230394, lng: 19.547637 },
    { lat: 48.230313, lng: 19.547638 },
    { lat: 48.230274, lng: 19.547649 },
    { lat: 48.230073, lng: 19.547857 },
    { lat: 48.229964, lng: 19.547906 },
    { lat: 48.229746, lng: 19.548 },
    { lat: 48.229553, lng: 19.548073 },
    { lat: 48.22924, lng: 19.548173 },
    { lat: 48.229075, lng: 19.548228 },
    { lat: 48.228881, lng: 19.54828 },
    { lat: 48.228756, lng: 19.548348 },
    { lat: 48.228472, lng: 19.548551 },
    { lat: 48.22831, lng: 19.548597 },
    { lat: 48.228277, lng: 19.548627 },
    { lat: 48.228194, lng: 19.548732 },
    { lat: 48.228084, lng: 19.548896 },
    { lat: 48.228022, lng: 19.548943 },
    { lat: 48.22795, lng: 19.548985 },
    { lat: 48.227875, lng: 19.548969 },
    { lat: 48.227561, lng: 19.548812 },
    { lat: 48.227517, lng: 19.548813 },
    { lat: 48.227434, lng: 19.548816 },
    { lat: 48.227394, lng: 19.548829 },
    { lat: 48.227138, lng: 19.549078 },
    { lat: 48.227012, lng: 19.54919 },
    { lat: 48.226914, lng: 19.549201 },
    { lat: 48.226672, lng: 19.549192 },
    { lat: 48.226554, lng: 19.549171 },
    { lat: 48.22643, lng: 19.549066 },
    { lat: 48.226275, lng: 19.548888 },
    { lat: 48.226218, lng: 19.54879 },
    { lat: 48.226106, lng: 19.54845 },
    { lat: 48.226081, lng: 19.548393 },
    { lat: 48.226061, lng: 19.548381 },
    { lat: 48.226039, lng: 19.548389 },
    { lat: 48.22602, lng: 19.548404 },
    { lat: 48.225523, lng: 19.548849 },
    { lat: 48.225483, lng: 19.548868 },
    { lat: 48.225436, lng: 19.548869 },
    { lat: 48.225394, lng: 19.548839 },
    { lat: 48.225348, lng: 19.548802 },
    { lat: 48.225315, lng: 19.548758 },
    { lat: 48.225269, lng: 19.548663 },
    { lat: 48.225234, lng: 19.548566 },
    { lat: 48.225205, lng: 19.548518 },
    { lat: 48.225183, lng: 19.548496 },
    { lat: 48.225166, lng: 19.548494 },
    { lat: 48.225144, lng: 19.548501 },
    { lat: 48.225123, lng: 19.548512 },
    { lat: 48.224862, lng: 19.548713 },
    { lat: 48.224763, lng: 19.54876 },
    { lat: 48.224733, lng: 19.548766 },
    { lat: 48.224708, lng: 19.548763 },
    { lat: 48.224515, lng: 19.548752 },
    { lat: 48.22449, lng: 19.548753 },
    { lat: 48.224476, lng: 19.548746 },
    { lat: 48.22444, lng: 19.548695 },
    { lat: 48.224219, lng: 19.54831 },
    { lat: 48.224187, lng: 19.54828 },
    { lat: 48.224162, lng: 19.548278 },
    { lat: 48.22412, lng: 19.548296 },
    { lat: 48.22397, lng: 19.548406 },
    { lat: 48.223901, lng: 19.548441 },
    { lat: 48.223777, lng: 19.54843 },
    { lat: 48.223745, lng: 19.548433 },
    { lat: 48.223717, lng: 19.548444 },
    { lat: 48.223646, lng: 19.548465 },
    { lat: 48.223617, lng: 19.548448 },
    { lat: 48.223536, lng: 19.548324 },
    { lat: 48.223478, lng: 19.548249 },
    { lat: 48.223419, lng: 19.548191 },
    { lat: 48.223195, lng: 19.548038 },
    { lat: 48.223155, lng: 19.548027 },
    { lat: 48.2228, lng: 19.547925 },
    { lat: 48.222719, lng: 19.547896 },
    { lat: 48.222669, lng: 19.54787 },
    { lat: 48.222222, lng: 19.547545 },
    { lat: 48.222201, lng: 19.547514 },
    { lat: 48.222192, lng: 19.547478 },
    { lat: 48.222118, lng: 19.547203 },
    { lat: 48.222105, lng: 19.547187 },
    { lat: 48.221773, lng: 19.546947 },
    { lat: 48.221711, lng: 19.546898 },
    { lat: 48.221675, lng: 19.546864 },
    { lat: 48.221632, lng: 19.546831 },
    { lat: 48.221604, lng: 19.54681 },
    { lat: 48.221584, lng: 19.546806 },
    { lat: 48.221258, lng: 19.546684 },
    { lat: 48.221228, lng: 19.546667 },
    { lat: 48.221198, lng: 19.546659 },
    { lat: 48.221011, lng: 19.54652 },
    { lat: 48.220989, lng: 19.54651 },
    { lat: 48.220692, lng: 19.546513 },
    { lat: 48.220655, lng: 19.546509 },
    { lat: 48.220616, lng: 19.546505 },
    { lat: 48.220473, lng: 19.546468 },
    { lat: 48.220426, lng: 19.546446 },
    { lat: 48.220407, lng: 19.54644 },
    { lat: 48.22039, lng: 19.546426 },
    { lat: 48.220371, lng: 19.546394 },
    { lat: 48.220355, lng: 19.546312 },
    { lat: 48.22034, lng: 19.54622 },
    { lat: 48.220317, lng: 19.54613 },
    { lat: 48.220277, lng: 19.546003 },
    { lat: 48.220247, lng: 19.545956 },
    { lat: 48.220216, lng: 19.545931 },
    { lat: 48.220175, lng: 19.545917 },
    { lat: 48.220136, lng: 19.545908 },
    { lat: 48.219783, lng: 19.545816 },
    { lat: 48.219297, lng: 19.54573 },
    { lat: 48.219278, lng: 19.545735 },
    { lat: 48.218865, lng: 19.545804 },
    { lat: 48.218845, lng: 19.545807 },
    { lat: 48.218826, lng: 19.54582 },
    { lat: 48.218603, lng: 19.545976 },
    { lat: 48.218584, lng: 19.545988 },
    { lat: 48.218428, lng: 19.545748 },
    { lat: 48.218247, lng: 19.545535 },
    { lat: 48.218222, lng: 19.545519 },
    { lat: 48.218073, lng: 19.545511 },
    { lat: 48.217983, lng: 19.545544 },
    { lat: 48.217733, lng: 19.545692 },
    { lat: 48.217671, lng: 19.545723 },
    { lat: 48.21761, lng: 19.545742 },
    { lat: 48.217496, lng: 19.545747 },
    { lat: 48.217477, lng: 19.54574 },
    { lat: 48.217373, lng: 19.545396 },
    { lat: 48.217367, lng: 19.545377 },
    { lat: 48.217349, lng: 19.545371 },
    { lat: 48.217218, lng: 19.545293 },
    { lat: 48.217192, lng: 19.545287 },
    { lat: 48.217165, lng: 19.545289 },
    { lat: 48.217007, lng: 19.545365 },
    { lat: 48.216731, lng: 19.545536 },
    { lat: 48.216587, lng: 19.54561 },
    { lat: 48.216293, lng: 19.545449 },
    { lat: 48.216246, lng: 19.545414 },
    { lat: 48.216222, lng: 19.545392 },
    { lat: 48.215875, lng: 19.545046 },
    { lat: 48.215849, lng: 19.545005 },
    { lat: 48.215821, lng: 19.545014 },
    { lat: 48.215805, lng: 19.545029 },
    { lat: 48.215617, lng: 19.545298 },
    { lat: 48.215559, lng: 19.545382 },
    { lat: 48.215544, lng: 19.54539 },
    { lat: 48.215368, lng: 19.54544 },
    { lat: 48.215126, lng: 19.545588 },
    { lat: 48.214929, lng: 19.54558 },
    { lat: 48.214909, lng: 19.545569 },
    { lat: 48.21477, lng: 19.545363 },
    { lat: 48.214757, lng: 19.545324 },
    { lat: 48.214715, lng: 19.545073 },
    { lat: 48.214448, lng: 19.544848 },
    { lat: 48.214244, lng: 19.544712 },
    { lat: 48.214133, lng: 19.544598 },
    { lat: 48.214026, lng: 19.544505 },
    { lat: 48.213687, lng: 19.544144 },
    { lat: 48.213562, lng: 19.544018 },
    { lat: 48.213455, lng: 19.543906 },
    { lat: 48.213239, lng: 19.543725 },
    { lat: 48.213006, lng: 19.543486 },
    { lat: 48.212693, lng: 19.543193 },
    { lat: 48.211996, lng: 19.542506 },
    { lat: 48.211594, lng: 19.542128 },
    { lat: 48.21154, lng: 19.542077 },
    { lat: 48.211308, lng: 19.541859 },
    { lat: 48.21104, lng: 19.541604 },
    { lat: 48.210282, lng: 19.540888 },
    { lat: 48.21025, lng: 19.540878 },
    { lat: 48.210326, lng: 19.540958 },
    { lat: 48.21022, lng: 19.540981 },
    { lat: 48.210152, lng: 19.540889 },
    { lat: 48.210097, lng: 19.540829 },
    { lat: 48.209793, lng: 19.540551 },
    { lat: 48.20974, lng: 19.540567 },
    { lat: 48.209716, lng: 19.540587 },
    { lat: 48.209681, lng: 19.540621 },
    { lat: 48.209668, lng: 19.540642 },
    { lat: 48.209625, lng: 19.540836 },
    { lat: 48.209614, lng: 19.540924 },
    { lat: 48.209611, lng: 19.541094 },
    { lat: 48.209616, lng: 19.54111 },
    { lat: 48.209641, lng: 19.541151 },
    { lat: 48.209658, lng: 19.541191 },
    { lat: 48.209673, lng: 19.541213 },
    { lat: 48.209716, lng: 19.541279 },
    { lat: 48.209738, lng: 19.541304 },
    { lat: 48.209735, lng: 19.541358 },
    { lat: 48.209711, lng: 19.541415 },
    { lat: 48.20968, lng: 19.54146 },
    { lat: 48.209651, lng: 19.54147 },
    { lat: 48.209609, lng: 19.541474 },
    { lat: 48.20958, lng: 19.541471 },
    { lat: 48.20943, lng: 19.541459 },
    { lat: 48.209391, lng: 19.54146 },
    { lat: 48.209367, lng: 19.541469 },
    { lat: 48.209325, lng: 19.541498 },
    { lat: 48.209329, lng: 19.541618 },
    { lat: 48.20932, lng: 19.54175 },
    { lat: 48.209299, lng: 19.541919 },
    { lat: 48.209277, lng: 19.542081 },
    { lat: 48.209272, lng: 19.542149 },
    { lat: 48.209251, lng: 19.54229 },
    { lat: 48.209224, lng: 19.542489 },
    { lat: 48.209204, lng: 19.542565 },
    { lat: 48.209192, lng: 19.542617 },
    { lat: 48.209171, lng: 19.542798 },
    { lat: 48.209155, lng: 19.54284 },
    { lat: 48.209113, lng: 19.542982 },
    { lat: 48.209065, lng: 19.543109 },
    { lat: 48.209033, lng: 19.543189 },
    { lat: 48.208965, lng: 19.543347 },
    { lat: 48.208909, lng: 19.543438 },
    { lat: 48.208849, lng: 19.543559 },
    { lat: 48.208792, lng: 19.543627 },
    { lat: 48.208738, lng: 19.543688 },
    { lat: 48.208716, lng: 19.543716 },
    { lat: 48.2087, lng: 19.543747 },
    { lat: 48.208609, lng: 19.543819 },
    { lat: 48.208521, lng: 19.543915 },
    { lat: 48.208419, lng: 19.544026 },
    { lat: 48.208345, lng: 19.544102 },
    { lat: 48.208312, lng: 19.544139 },
    { lat: 48.208243, lng: 19.544169 },
    { lat: 48.208189, lng: 19.544228 },
    { lat: 48.208094, lng: 19.544316 },
    { lat: 48.208074, lng: 19.544332 },
    { lat: 48.208023, lng: 19.544356 },
    { lat: 48.207922, lng: 19.54435 },
    { lat: 48.207687, lng: 19.544406 },
    { lat: 48.2076263, lng: 19.5444077 },
    { lat: 48.207263, lng: 19.544448 },
    { lat: 48.206993, lng: 19.54462 },
    { lat: 48.20669, lng: 19.544768 },
    { lat: 48.206609, lng: 19.544799 },
    { lat: 48.206468, lng: 19.544807 },
    { lat: 48.206425, lng: 19.54482 },
    { lat: 48.206217, lng: 19.545084 },
    { lat: 48.205955, lng: 19.545405 },
    { lat: 48.205829, lng: 19.545529 },
    { lat: 48.205673, lng: 19.545711 },
    { lat: 48.205498, lng: 19.545948 },
    { lat: 48.205478, lng: 19.546067 },
    { lat: 48.205486, lng: 19.546158 },
    { lat: 48.205576, lng: 19.546472 },
    { lat: 48.205641, lng: 19.546722 },
    { lat: 48.205674, lng: 19.546876 },
    { lat: 48.205694, lng: 19.547023 },
    { lat: 48.205804, lng: 19.547429 },
    { lat: 48.205891, lng: 19.547654 },
    { lat: 48.205988, lng: 19.547955 },
    { lat: 48.206082, lng: 19.548175 },
    { lat: 48.206343, lng: 19.54865 },
    { lat: 48.20674, lng: 19.548931 },
    { lat: 48.206849, lng: 19.54908 },
    { lat: 48.206927, lng: 19.549264 },
    { lat: 48.207001, lng: 19.549434 },
    { lat: 48.207085, lng: 19.549638 },
    { lat: 48.207193, lng: 19.549874 },
    { lat: 48.207277, lng: 19.550085 },
    { lat: 48.207413, lng: 19.550455 },
    { lat: 48.207555, lng: 19.550714 },
    { lat: 48.207769, lng: 19.551009 },
    { lat: 48.207869, lng: 19.551182 },
    { lat: 48.207964, lng: 19.55137 },
    { lat: 48.208049, lng: 19.55161 },
    { lat: 48.208085, lng: 19.55167 },
    { lat: 48.208131, lng: 19.551817 },
    { lat: 48.208144, lng: 19.551899 },
    { lat: 48.208175, lng: 19.552044 },
    { lat: 48.208209, lng: 19.552213 },
    { lat: 48.208232, lng: 19.552322 },
    { lat: 48.208332, lng: 19.552808 },
    { lat: 48.208365, lng: 19.552963 },
    { lat: 48.208433, lng: 19.553265 },
    { lat: 48.208449, lng: 19.553355 },
    { lat: 48.208449, lng: 19.553408 },
    { lat: 48.208495, lng: 19.553622 },
    { lat: 48.208486, lng: 19.553645 },
    { lat: 48.208533, lng: 19.553946 },
    { lat: 48.208566, lng: 19.55414 },
    { lat: 48.208622, lng: 19.554386 },
    { lat: 48.208645, lng: 19.554474 },
    { lat: 48.208734, lng: 19.554756 },
    { lat: 48.208771, lng: 19.55488 },
    { lat: 48.208819, lng: 19.555037 },
    { lat: 48.208871, lng: 19.555149 },
    { lat: 48.208947, lng: 19.555305 },
    { lat: 48.209041, lng: 19.555468 },
    { lat: 48.20913, lng: 19.555639 },
    { lat: 48.209304, lng: 19.555966 },
    { lat: 48.209372, lng: 19.556071 },
    { lat: 48.209448, lng: 19.556178 },
    { lat: 48.209477, lng: 19.556213 },
    { lat: 48.209577, lng: 19.556336 },
    { lat: 48.209725, lng: 19.556501 },
    { lat: 48.209907, lng: 19.55662 },
    { lat: 48.210061, lng: 19.556725 },
    { lat: 48.210121, lng: 19.556773 },
    { lat: 48.210334, lng: 19.556917 },
    { lat: 48.210409, lng: 19.556958 },
    { lat: 48.210508, lng: 19.557021 },
    { lat: 48.210559, lng: 19.55705 },
    { lat: 48.210695, lng: 19.557118 },
    { lat: 48.210827, lng: 19.557235 },
    { lat: 48.210952, lng: 19.557349 },
    { lat: 48.211048, lng: 19.557451 },
    { lat: 48.211208, lng: 19.55761 },
    { lat: 48.211249, lng: 19.557651 },
    { lat: 48.21141, lng: 19.557823 },
    { lat: 48.211492, lng: 19.557925 },
    { lat: 48.211507, lng: 19.557941 },
    { lat: 48.211574, lng: 19.558054 },
    { lat: 48.211635, lng: 19.558169 },
    { lat: 48.211726, lng: 19.558327 },
    { lat: 48.21177, lng: 19.558391 },
    { lat: 48.21182, lng: 19.558471 },
    { lat: 48.211865, lng: 19.558575 },
    { lat: 48.211921, lng: 19.558742 },
    { lat: 48.211945, lng: 19.558808 },
    { lat: 48.211989, lng: 19.559006 },
    { lat: 48.212042, lng: 19.55914 },
    { lat: 48.212074, lng: 19.559285 },
    { lat: 48.212116, lng: 19.559419 },
    { lat: 48.212145, lng: 19.559548 },
    { lat: 48.212162, lng: 19.559628 },
    { lat: 48.212192, lng: 19.55976 },
    { lat: 48.212236, lng: 19.559891 },
    { lat: 48.212273, lng: 19.560017 },
    { lat: 48.212304, lng: 19.56015 },
    { lat: 48.212336, lng: 19.56027 },
    { lat: 48.212364, lng: 19.560358 },
    { lat: 48.212406, lng: 19.560492 },
    { lat: 48.212424, lng: 19.560623 },
    { lat: 48.212477, lng: 19.560762 },
    { lat: 48.212526, lng: 19.560874 },
    { lat: 48.212578, lng: 19.560996 },
    { lat: 48.212614, lng: 19.561078 },
    { lat: 48.212666, lng: 19.561184 },
    { lat: 48.212714, lng: 19.561268 },
    { lat: 48.212733, lng: 19.561276 },
    { lat: 48.212779, lng: 19.561298 },
    { lat: 48.212822, lng: 19.561376 },
    { lat: 48.212865, lng: 19.561454 },
    { lat: 48.212934, lng: 19.561566 },
    { lat: 48.212983, lng: 19.561637 },
    { lat: 48.21301, lng: 19.561725 },
    { lat: 48.213132, lng: 19.561938 },
    { lat: 48.213265, lng: 19.562249 },
    { lat: 48.213367, lng: 19.562551 },
    { lat: 48.213452, lng: 19.5628695 },
  ],
  Čakanovce: [
    { lat: 48.2285406, lng: 19.7901357 },
    { lat: 48.2281188, lng: 19.7897162 },
    { lat: 48.2268908, lng: 19.7884915 },
    { lat: 48.2262883, lng: 19.7871691 },
    { lat: 48.2261211, lng: 19.7856211 },
    { lat: 48.2258973, lng: 19.7847906 },
    { lat: 48.2263335, lng: 19.7843828 },
    { lat: 48.2264, lng: 19.7841544 },
    { lat: 48.2263861, lng: 19.7830473 },
    { lat: 48.2256056, lng: 19.7816737 },
    { lat: 48.2254668, lng: 19.7809295 },
    { lat: 48.2255967, lng: 19.7804004 },
    { lat: 48.2257932, lng: 19.7800294 },
    { lat: 48.2257822, lng: 19.7799079 },
    { lat: 48.2262296, lng: 19.7797856 },
    { lat: 48.2262411, lng: 19.7798894 },
    { lat: 48.2265052, lng: 19.7797219 },
    { lat: 48.2268132, lng: 19.7790141 },
    { lat: 48.226971, lng: 19.7783533 },
    { lat: 48.2271161, lng: 19.7779641 },
    { lat: 48.2275959, lng: 19.7773506 },
    { lat: 48.2279342, lng: 19.7767189 },
    { lat: 48.2283993, lng: 19.7763301 },
    { lat: 48.2286878, lng: 19.7751044 },
    { lat: 48.2291412, lng: 19.7739199 },
    { lat: 48.2293965, lng: 19.773095 },
    { lat: 48.2295173, lng: 19.7724894 },
    { lat: 48.2298881, lng: 19.7712894 },
    { lat: 48.2290706, lng: 19.7704379 },
    { lat: 48.228614, lng: 19.7701979 },
    { lat: 48.2277682, lng: 19.7694955 },
    { lat: 48.2275294, lng: 19.7688581 },
    { lat: 48.2270802, lng: 19.7681291 },
    { lat: 48.2265118, lng: 19.7667678 },
    { lat: 48.225376, lng: 19.766331 },
    { lat: 48.2250235, lng: 19.7662836 },
    { lat: 48.2247883, lng: 19.7663401 },
    { lat: 48.2241073, lng: 19.766179 },
    { lat: 48.2234045, lng: 19.7657752 },
    { lat: 48.2225713, lng: 19.7654063 },
    { lat: 48.2225574, lng: 19.7644046 },
    { lat: 48.2226659, lng: 19.7637512 },
    { lat: 48.2229132, lng: 19.7628479 },
    { lat: 48.2226778, lng: 19.7617078 },
    { lat: 48.2224538, lng: 19.7609467 },
    { lat: 48.2221138, lng: 19.7602428 },
    { lat: 48.2219973, lng: 19.75812 },
    { lat: 48.2217003, lng: 19.7575979 },
    { lat: 48.2216066, lng: 19.7567328 },
    { lat: 48.2214363, lng: 19.755936 },
    { lat: 48.2214452, lng: 19.7551264 },
    { lat: 48.221383, lng: 19.7537236 },
    { lat: 48.221231, lng: 19.7531408 },
    { lat: 48.2209785, lng: 19.7527063 },
    { lat: 48.220644, lng: 19.7523036 },
    { lat: 48.2195772, lng: 19.7518235 },
    { lat: 48.2195364, lng: 19.7516445 },
    { lat: 48.2180669, lng: 19.7517885 },
    { lat: 48.21747, lng: 19.7519732 },
    { lat: 48.2170658, lng: 19.7520046 },
    { lat: 48.2157782, lng: 19.7514747 },
    { lat: 48.2151351, lng: 19.7514814 },
    { lat: 48.214997, lng: 19.751527 },
    { lat: 48.214837, lng: 19.751666 },
    { lat: 48.214725, lng: 19.751819 },
    { lat: 48.214539, lng: 19.752211 },
    { lat: 48.214268, lng: 19.752617 },
    { lat: 48.214225, lng: 19.752942 },
    { lat: 48.214246, lng: 19.753672 },
    { lat: 48.214317, lng: 19.753897 },
    { lat: 48.214391, lng: 19.754182 },
    { lat: 48.214457, lng: 19.754511 },
    { lat: 48.214559, lng: 19.75495 },
    { lat: 48.214533, lng: 19.755187 },
    { lat: 48.214441, lng: 19.75553 },
    { lat: 48.214252, lng: 19.755817 },
    { lat: 48.214097, lng: 19.756119 },
    { lat: 48.21401, lng: 19.756393 },
    { lat: 48.213966, lng: 19.756808 },
    { lat: 48.213934, lng: 19.757037 },
    { lat: 48.213951, lng: 19.757337 },
    { lat: 48.213911, lng: 19.757932 },
    { lat: 48.214066, lng: 19.75847 },
    { lat: 48.214024, lng: 19.758797 },
    { lat: 48.21408, lng: 19.75916 },
    { lat: 48.2141, lng: 19.759504 },
    { lat: 48.214104, lng: 19.759756 },
    { lat: 48.213993, lng: 19.760272 },
    { lat: 48.213792, lng: 19.760709 },
    { lat: 48.213618, lng: 19.760947 },
    { lat: 48.213489, lng: 19.761143 },
    { lat: 48.213152, lng: 19.7615 },
    { lat: 48.212858, lng: 19.762108 },
    { lat: 48.212628, lng: 19.762319 },
    { lat: 48.21246, lng: 19.762396 },
    { lat: 48.212244, lng: 19.762504 },
    { lat: 48.211971, lng: 19.762592 },
    { lat: 48.211715, lng: 19.762775 },
    { lat: 48.211368, lng: 19.762975 },
    { lat: 48.211256, lng: 19.763154 },
    { lat: 48.21116, lng: 19.763245 },
    { lat: 48.210994, lng: 19.763266 },
    { lat: 48.210769, lng: 19.763321 },
    { lat: 48.210295, lng: 19.763179 },
    { lat: 48.209838, lng: 19.763107 },
    { lat: 48.209837, lng: 19.763132 },
    { lat: 48.209278, lng: 19.763015 },
    { lat: 48.208869, lng: 19.763216 },
    { lat: 48.208329, lng: 19.763467 },
    { lat: 48.208012, lng: 19.763672 },
    { lat: 48.207465, lng: 19.764177 },
    { lat: 48.207171, lng: 19.764813 },
    { lat: 48.207065, lng: 19.765064 },
    { lat: 48.206861, lng: 19.765458 },
    { lat: 48.206312, lng: 19.766047 },
    { lat: 48.206191, lng: 19.766176 },
    { lat: 48.206158, lng: 19.766212 },
    { lat: 48.206022, lng: 19.766358 },
    { lat: 48.205685, lng: 19.766747 },
    { lat: 48.205403, lng: 19.767265 },
    { lat: 48.2052, lng: 19.767981 },
    { lat: 48.205185, lng: 19.768942 },
    { lat: 48.205337, lng: 19.770254 },
    { lat: 48.205445, lng: 19.771249 },
    { lat: 48.20543, lng: 19.771511 },
    { lat: 48.205415, lng: 19.771847 },
    { lat: 48.205362, lng: 19.772697 },
    { lat: 48.205148, lng: 19.772813 },
    { lat: 48.204535, lng: 19.773147 },
    { lat: 48.203691, lng: 19.773612 },
    { lat: 48.203175, lng: 19.774634 },
    { lat: 48.202835, lng: 19.77531 },
    { lat: 48.202764, lng: 19.775543 },
    { lat: 48.202729, lng: 19.775658 },
    { lat: 48.202716, lng: 19.775698 },
    { lat: 48.202584, lng: 19.776125 },
    { lat: 48.202607, lng: 19.776722 },
    { lat: 48.202606, lng: 19.77722 },
    { lat: 48.202324, lng: 19.7784 },
    { lat: 48.201996, lng: 19.779147 },
    { lat: 48.201787, lng: 19.779617 },
    { lat: 48.20138, lng: 19.780664 },
    { lat: 48.201011, lng: 19.781322 },
    { lat: 48.200176, lng: 19.781946 },
    { lat: 48.199937, lng: 19.782428 },
    { lat: 48.199759, lng: 19.782722 },
    { lat: 48.199481, lng: 19.783177 },
    { lat: 48.198941, lng: 19.783717 },
    { lat: 48.198763, lng: 19.783892 },
    { lat: 48.198553, lng: 19.784035 },
    { lat: 48.198377, lng: 19.784157 },
    { lat: 48.197697, lng: 19.784893 },
    { lat: 48.197074, lng: 19.785827 },
    { lat: 48.196882, lng: 19.786045 },
    { lat: 48.196648, lng: 19.78631 },
    { lat: 48.196278, lng: 19.786348 },
    { lat: 48.1954, lng: 19.786543 },
    { lat: 48.194636, lng: 19.786286 },
    { lat: 48.194211, lng: 19.786232 },
    { lat: 48.193976, lng: 19.786202 },
    { lat: 48.193702, lng: 19.786254 },
    { lat: 48.193371, lng: 19.786434 },
    { lat: 48.19294, lng: 19.786806 },
    { lat: 48.19253, lng: 19.787163 },
    { lat: 48.192566, lng: 19.787442 },
    { lat: 48.192662, lng: 19.788163 },
    { lat: 48.192751, lng: 19.788759 },
    { lat: 48.192988, lng: 19.789498 },
    { lat: 48.193251, lng: 19.790325 },
    { lat: 48.193429, lng: 19.790894 },
    { lat: 48.1937099, lng: 19.7917891 },
    { lat: 48.193719, lng: 19.791818 },
    { lat: 48.193756, lng: 19.793053 },
    { lat: 48.193778, lng: 19.79402 },
    { lat: 48.193881, lng: 19.795246 },
    { lat: 48.193936, lng: 19.795515 },
    { lat: 48.194137, lng: 19.795912 },
    { lat: 48.194352, lng: 19.796224 },
    { lat: 48.194811, lng: 19.797213 },
    { lat: 48.194846, lng: 19.797685 },
    { lat: 48.19485, lng: 19.797742 },
    { lat: 48.194838, lng: 19.797794 },
    { lat: 48.194789, lng: 19.798339 },
    { lat: 48.194815, lng: 19.798669 },
    { lat: 48.19482, lng: 19.798733 },
    { lat: 48.1948, lng: 19.79874 },
    { lat: 48.194326, lng: 19.799251 },
    { lat: 48.193766, lng: 19.79974 },
    { lat: 48.193478, lng: 19.799989 },
    { lat: 48.193395, lng: 19.800168 },
    { lat: 48.193001, lng: 19.80128 },
    { lat: 48.192912, lng: 19.801385 },
    { lat: 48.1926899, lng: 19.8032487 },
    { lat: 48.19311, lng: 19.8041908 },
    { lat: 48.1939401, lng: 19.8048231 },
    { lat: 48.195033, lng: 19.8050335 },
    { lat: 48.1960986, lng: 19.8053853 },
    { lat: 48.1971549, lng: 19.8054785 },
    { lat: 48.1984156, lng: 19.806837 },
    { lat: 48.1987869, lng: 19.8074641 },
    { lat: 48.200264, lng: 19.8090568 },
    { lat: 48.2011751, lng: 19.8107147 },
    { lat: 48.2015659, lng: 19.8116097 },
    { lat: 48.2018055, lng: 19.8120393 },
    { lat: 48.2018345, lng: 19.8131358 },
    { lat: 48.2019927, lng: 19.8142027 },
    { lat: 48.2022808, lng: 19.8152972 },
    { lat: 48.202861, lng: 19.8157827 },
    { lat: 48.2033427, lng: 19.8158495 },
    { lat: 48.2038966, lng: 19.816071 },
    { lat: 48.2040072, lng: 19.816182 },
    { lat: 48.2044164, lng: 19.8162379 },
    { lat: 48.2048213, lng: 19.8166043 },
    { lat: 48.2054142, lng: 19.8168686 },
    { lat: 48.2059586, lng: 19.8176438 },
    { lat: 48.2059747, lng: 19.8176016 },
    { lat: 48.2075479, lng: 19.8188982 },
    { lat: 48.2095019, lng: 19.8207068 },
    { lat: 48.2093846, lng: 19.821423 },
    { lat: 48.2106255, lng: 19.8221798 },
    { lat: 48.2139726, lng: 19.8227677 },
    { lat: 48.2140494, lng: 19.8227209 },
    { lat: 48.2140257, lng: 19.8223311 },
    { lat: 48.2151614, lng: 19.8208682 },
    { lat: 48.2163182, lng: 19.8192651 },
    { lat: 48.2163569, lng: 19.8192973 },
    { lat: 48.2166212, lng: 19.8187998 },
    { lat: 48.2167556, lng: 19.8188873 },
    { lat: 48.2173283, lng: 19.8174032 },
    { lat: 48.2179257, lng: 19.8160212 },
    { lat: 48.2180289, lng: 19.8154448 },
    { lat: 48.2182677, lng: 19.81457 },
    { lat: 48.2183345, lng: 19.8139343 },
    { lat: 48.2186178, lng: 19.8137937 },
    { lat: 48.2191119, lng: 19.8127772 },
    { lat: 48.2191656, lng: 19.8124618 },
    { lat: 48.2191374, lng: 19.8118463 },
    { lat: 48.2193613, lng: 19.8112704 },
    { lat: 48.2196944, lng: 19.8107483 },
    { lat: 48.2203347, lng: 19.810324 },
    { lat: 48.2207187, lng: 19.8098532 },
    { lat: 48.2210879, lng: 19.8096703 },
    { lat: 48.2212378, lng: 19.809411 },
    { lat: 48.2214746, lng: 19.8086738 },
    { lat: 48.2215262, lng: 19.8074336 },
    { lat: 48.2217971, lng: 19.8068077 },
    { lat: 48.2217045, lng: 19.8062851 },
    { lat: 48.2217805, lng: 19.8046546 },
    { lat: 48.2222334, lng: 19.8033452 },
    { lat: 48.2226003, lng: 19.8020308 },
    { lat: 48.2230296, lng: 19.8015211 },
    { lat: 48.223125, lng: 19.8011033 },
    { lat: 48.2231208, lng: 19.8005943 },
    { lat: 48.2228409, lng: 19.7988401 },
    { lat: 48.2227151, lng: 19.7986207 },
    { lat: 48.2222492, lng: 19.7981193 },
    { lat: 48.2220317, lng: 19.7980923 },
    { lat: 48.2223354, lng: 19.7975939 },
    { lat: 48.2229461, lng: 19.7969812 },
    { lat: 48.2238591, lng: 19.7973732 },
    { lat: 48.2246726, lng: 19.7973506 },
    { lat: 48.2254083, lng: 19.7972485 },
    { lat: 48.2257817, lng: 19.797106 },
    { lat: 48.2264827, lng: 19.7966465 },
    { lat: 48.2274537, lng: 19.7949816 },
    { lat: 48.2276953, lng: 19.7942752 },
    { lat: 48.2280715, lng: 19.7928614 },
    { lat: 48.2282015, lng: 19.7918981 },
    { lat: 48.2284983, lng: 19.7906565 },
    { lat: 48.2285406, lng: 19.7901357 },
  ],
  VeľkánadIpľom: [
    { lat: 48.284102, lng: 19.6208458 },
    { lat: 48.2840165, lng: 19.6203281 },
    { lat: 48.2838069, lng: 19.618819 },
    { lat: 48.2833029, lng: 19.6189614 },
    { lat: 48.2830131, lng: 19.6188824 },
    { lat: 48.2830108, lng: 19.618583 },
    { lat: 48.2834209, lng: 19.617564 },
    { lat: 48.2833916, lng: 19.6171972 },
    { lat: 48.2826006, lng: 19.6162068 },
    { lat: 48.2814414, lng: 19.6149771 },
    { lat: 48.2797777, lng: 19.6184541 },
    { lat: 48.2793256, lng: 19.6183779 },
    { lat: 48.2790975, lng: 19.6178692 },
    { lat: 48.2788929, lng: 19.6171872 },
    { lat: 48.2786641, lng: 19.6166898 },
    { lat: 48.2784406, lng: 19.6163858 },
    { lat: 48.2781351, lng: 19.6162366 },
    { lat: 48.2778029, lng: 19.6162175 },
    { lat: 48.2776991, lng: 19.615892 },
    { lat: 48.2775932, lng: 19.6157182 },
    { lat: 48.2775914, lng: 19.6154824 },
    { lat: 48.2770403, lng: 19.6143891 },
    { lat: 48.2770426, lng: 19.6141706 },
    { lat: 48.2770902, lng: 19.6141003 },
    { lat: 48.2771974, lng: 19.6133645 },
    { lat: 48.276006, lng: 19.612637 },
    { lat: 48.275578, lng: 19.6128134 },
    { lat: 48.275581, lng: 19.6127346 },
    { lat: 48.2754561, lng: 19.6127099 },
    { lat: 48.275463, lng: 19.6125199 },
    { lat: 48.2751593, lng: 19.6123051 },
    { lat: 48.2750983, lng: 19.6120305 },
    { lat: 48.2752138, lng: 19.6118701 },
    { lat: 48.2753519, lng: 19.6118588 },
    { lat: 48.2755555, lng: 19.6116516 },
    { lat: 48.2754492, lng: 19.6113706 },
    { lat: 48.2757115, lng: 19.6108471 },
    { lat: 48.2755036, lng: 19.6105143 },
    { lat: 48.2755019, lng: 19.6102336 },
    { lat: 48.2758037, lng: 19.6101759 },
    { lat: 48.2759344, lng: 19.6100758 },
    { lat: 48.2762377, lng: 19.6104042 },
    { lat: 48.2763415, lng: 19.6103097 },
    { lat: 48.276333, lng: 19.6101217 },
    { lat: 48.2749068, lng: 19.6079542 },
    { lat: 48.2733679, lng: 19.6060637 },
    { lat: 48.2723878, lng: 19.6033286 },
    { lat: 48.2723729, lng: 19.60327 },
    { lat: 48.272664, lng: 19.602907 },
    { lat: 48.2727812, lng: 19.6026608 },
    { lat: 48.2730585, lng: 19.6015044 },
    { lat: 48.2730737, lng: 19.6011955 },
    { lat: 48.2729743, lng: 19.5999811 },
    { lat: 48.2729992, lng: 19.599663 },
    { lat: 48.2731053, lng: 19.5992266 },
    { lat: 48.2734146, lng: 19.5985688 },
    { lat: 48.2737912, lng: 19.5979575 },
    { lat: 48.2741159, lng: 19.5971018 },
    { lat: 48.2750208, lng: 19.595594 },
    { lat: 48.2754635, lng: 19.5946062 },
    { lat: 48.2761693, lng: 19.5922511 },
    { lat: 48.2761421, lng: 19.5917547 },
    { lat: 48.2762235, lng: 19.5911453 },
    { lat: 48.276407, lng: 19.5906235 },
    { lat: 48.2765553, lng: 19.5897989 },
    { lat: 48.2773272, lng: 19.5872143 },
    { lat: 48.277393, lng: 19.5867834 },
    { lat: 48.2798998, lng: 19.5858348 },
    { lat: 48.2807891, lng: 19.5822993 },
    { lat: 48.2819906, lng: 19.5791313 },
    { lat: 48.2829922, lng: 19.5787122 },
    { lat: 48.2831209, lng: 19.5784963 },
    { lat: 48.2833606, lng: 19.5783421 },
    { lat: 48.2835094, lng: 19.5779457 },
    { lat: 48.2835379, lng: 19.5775646 },
    { lat: 48.2834992, lng: 19.5765203 },
    { lat: 48.2835164, lng: 19.575473 },
    { lat: 48.2834984, lng: 19.5752778 },
    { lat: 48.2834072, lng: 19.575106 },
    { lat: 48.2834311, lng: 19.5745934 },
    { lat: 48.283335, lng: 19.5739251 },
    { lat: 48.2833763, lng: 19.5735996 },
    { lat: 48.2832922, lng: 19.5732134 },
    { lat: 48.2834044, lng: 19.5722459 },
    { lat: 48.2853235, lng: 19.5720777 },
    { lat: 48.2878759, lng: 19.5717307 },
    { lat: 48.2883567, lng: 19.5717219 },
    { lat: 48.2889258, lng: 19.5715981 },
    { lat: 48.2907467, lng: 19.5714754 },
    { lat: 48.2916081, lng: 19.5713349 },
    { lat: 48.2918978, lng: 19.5708206 },
    { lat: 48.2915844, lng: 19.5687121 },
    { lat: 48.2915747, lng: 19.5677867 },
    { lat: 48.2918882, lng: 19.566099 },
    { lat: 48.2919526, lng: 19.565322 },
    { lat: 48.2919207, lng: 19.5650436 },
    { lat: 48.2916548, lng: 19.5640162 },
    { lat: 48.291318, lng: 19.5636242 },
    { lat: 48.2915216, lng: 19.5630045 },
    { lat: 48.2918254, lng: 19.5629961 },
    { lat: 48.2929654, lng: 19.5633536 },
    { lat: 48.2931633, lng: 19.5630267 },
    { lat: 48.2941424, lng: 19.5635622 },
    { lat: 48.2941778, lng: 19.5633239 },
    { lat: 48.2944199, lng: 19.5630547 },
    { lat: 48.2944153, lng: 19.5626815 },
    { lat: 48.2945763, lng: 19.5625831 },
    { lat: 48.2945016, lng: 19.5621563 },
    { lat: 48.2943954, lng: 19.5621524 },
    { lat: 48.2943556, lng: 19.5618511 },
    { lat: 48.2944063, lng: 19.5617259 },
    { lat: 48.2945421, lng: 19.5617168 },
    { lat: 48.2945941, lng: 19.5615731 },
    { lat: 48.2945096, lng: 19.5609335 },
    { lat: 48.2945049, lng: 19.560538 },
    { lat: 48.2945906, lng: 19.5593886 },
    { lat: 48.294697, lng: 19.5592024 },
    { lat: 48.2948858, lng: 19.5584603 },
    { lat: 48.2952758, lng: 19.5574574 },
    { lat: 48.2952895, lng: 19.5572872 },
    { lat: 48.2951935, lng: 19.5571375 },
    { lat: 48.2953489, lng: 19.5563276 },
    { lat: 48.2954714, lng: 19.5559774 },
    { lat: 48.2955884, lng: 19.5558977 },
    { lat: 48.2960457, lng: 19.5548439 },
    { lat: 48.296062, lng: 19.5544643 },
    { lat: 48.296131, lng: 19.5543683 },
    { lat: 48.2962023, lng: 19.5544034 },
    { lat: 48.296284, lng: 19.5542194 },
    { lat: 48.296624, lng: 19.5530125 },
    { lat: 48.2960403, lng: 19.5526967 },
    { lat: 48.2959528, lng: 19.5527507 },
    { lat: 48.2958994, lng: 19.5526674 },
    { lat: 48.2959668, lng: 19.5522499 },
    { lat: 48.2961225, lng: 19.552134 },
    { lat: 48.2961612, lng: 19.5520156 },
    { lat: 48.2960643, lng: 19.5518962 },
    { lat: 48.2961225, lng: 19.5516417 },
    { lat: 48.2962617, lng: 19.5515063 },
    { lat: 48.2961899, lng: 19.5513219 },
    { lat: 48.2958999, lng: 19.5513094 },
    { lat: 48.2958865, lng: 19.5512393 },
    { lat: 48.2958733, lng: 19.5512258 },
    { lat: 48.295803, lng: 19.5510682 },
    { lat: 48.2958152, lng: 19.5506214 },
    { lat: 48.2956496, lng: 19.5501831 },
    { lat: 48.2957776, lng: 19.5500767 },
    { lat: 48.2957153, lng: 19.5498988 },
    { lat: 48.2957991, lng: 19.5495639 },
    { lat: 48.2956147, lng: 19.5494775 },
    { lat: 48.2957297, lng: 19.5490377 },
    { lat: 48.2957025, lng: 19.5489598 },
    { lat: 48.2956205, lng: 19.5489687 },
    { lat: 48.2955589, lng: 19.5488505 },
    { lat: 48.2955069, lng: 19.548651 },
    { lat: 48.2955632, lng: 19.5486013 },
    { lat: 48.2955597, lng: 19.5485047 },
    { lat: 48.2954555, lng: 19.54837 },
    { lat: 48.2952485, lng: 19.5477888 },
    { lat: 48.2952997, lng: 19.5477265 },
    { lat: 48.2952057, lng: 19.5476439 },
    { lat: 48.2950467, lng: 19.547253 },
    { lat: 48.2947793, lng: 19.5470719 },
    { lat: 48.2946583, lng: 19.546749 },
    { lat: 48.2945044, lng: 19.5467557 },
    { lat: 48.2942385, lng: 19.5464881 },
    { lat: 48.2941508, lng: 19.5465935 },
    { lat: 48.2939751, lng: 19.5464708 },
    { lat: 48.2937192, lng: 19.5461771 },
    { lat: 48.2936006, lng: 19.5459505 },
    { lat: 48.2932069, lng: 19.5456463 },
    { lat: 48.2927698, lng: 19.5455037 },
    { lat: 48.2925418, lng: 19.5456186 },
    { lat: 48.2924331, lng: 19.5453874 },
    { lat: 48.2922243, lng: 19.5452583 },
    { lat: 48.2918981, lng: 19.5454188 },
    { lat: 48.2916062, lng: 19.5454445 },
    { lat: 48.2913137, lng: 19.5452826 },
    { lat: 48.2907579, lng: 19.5451569 },
    { lat: 48.2898754, lng: 19.5445421 },
    { lat: 48.2893557, lng: 19.544396 },
    { lat: 48.289006, lng: 19.5441913 },
    { lat: 48.2887405, lng: 19.5441746 },
    { lat: 48.2885565, lng: 19.5440719 },
    { lat: 48.2878444, lng: 19.5439051 },
    { lat: 48.2865731, lng: 19.5431187 },
    { lat: 48.2857379, lng: 19.5428691 },
    { lat: 48.2855987, lng: 19.5428825 },
    { lat: 48.2852972, lng: 19.5427719 },
    { lat: 48.2851624, lng: 19.5428786 },
    { lat: 48.2850252, lng: 19.5428326 },
    { lat: 48.2849808, lng: 19.542925 },
    { lat: 48.2845679, lng: 19.5429894 },
    { lat: 48.283986, lng: 19.543293 },
    { lat: 48.28396, lng: 19.543294 },
    { lat: 48.283859, lng: 19.543302 },
    { lat: 48.283751, lng: 19.543333 },
    { lat: 48.283414, lng: 19.54343 },
    { lat: 48.283277, lng: 19.543494 },
    { lat: 48.283251, lng: 19.543507 },
    { lat: 48.283169, lng: 19.543294 },
    { lat: 48.283066, lng: 19.543244 },
    { lat: 48.282913, lng: 19.543262 },
    { lat: 48.282815, lng: 19.543289 },
    { lat: 48.282756, lng: 19.543284 },
    { lat: 48.282573, lng: 19.543352 },
    { lat: 48.282417, lng: 19.543373 },
    { lat: 48.282147, lng: 19.543487 },
    { lat: 48.282059, lng: 19.543631 },
    { lat: 48.281955, lng: 19.543709 },
    { lat: 48.281908, lng: 19.543819 },
    { lat: 48.28179, lng: 19.543906 },
    { lat: 48.281637, lng: 19.543973 },
    { lat: 48.28157, lng: 19.543985 },
    { lat: 48.281424, lng: 19.544141 },
    { lat: 48.281367, lng: 19.544313 },
    { lat: 48.281291, lng: 19.544461 },
    { lat: 48.281266, lng: 19.544669 },
    { lat: 48.281194, lng: 19.544781 },
    { lat: 48.281133, lng: 19.544866 },
    { lat: 48.281119, lng: 19.544927 },
    { lat: 48.281086, lng: 19.544967 },
    { lat: 48.280999, lng: 19.544992 },
    { lat: 48.28097, lng: 19.545051 },
    { lat: 48.280925, lng: 19.54506 },
    { lat: 48.280807, lng: 19.545163 },
    { lat: 48.280694, lng: 19.545204 },
    { lat: 48.280675, lng: 19.545263 },
    { lat: 48.280667, lng: 19.545335 },
    { lat: 48.280627, lng: 19.545388 },
    { lat: 48.280608, lng: 19.545486 },
    { lat: 48.280526, lng: 19.545557 },
    { lat: 48.280472, lng: 19.545667 },
    { lat: 48.280459, lng: 19.545766 },
    { lat: 48.28047, lng: 19.545861 },
    { lat: 48.280447, lng: 19.546002 },
    { lat: 48.280439, lng: 19.546093 },
    { lat: 48.280458, lng: 19.546231 },
    { lat: 48.28043, lng: 19.546305 },
    { lat: 48.280459, lng: 19.546534 },
    { lat: 48.280455, lng: 19.546784 },
    { lat: 48.280446, lng: 19.546855 },
    { lat: 48.280452, lng: 19.547025 },
    { lat: 48.280428, lng: 19.54708 },
    { lat: 48.280439, lng: 19.547307 },
    { lat: 48.28034, lng: 19.547542 },
    { lat: 48.280203, lng: 19.547771 },
    { lat: 48.280053, lng: 19.547853 },
    { lat: 48.279971, lng: 19.547997 },
    { lat: 48.279884, lng: 19.548089 },
    { lat: 48.279885, lng: 19.548128 },
    { lat: 48.279853, lng: 19.548176 },
    { lat: 48.279819, lng: 19.548212 },
    { lat: 48.279845, lng: 19.548342 },
    { lat: 48.279849, lng: 19.548486 },
    { lat: 48.279781, lng: 19.548604 },
    { lat: 48.279864, lng: 19.548812 },
    { lat: 48.279895, lng: 19.54899 },
    { lat: 48.279874, lng: 19.54913 },
    { lat: 48.279873, lng: 19.549272 },
    { lat: 48.279841, lng: 19.549356 },
    { lat: 48.279885, lng: 19.54943 },
    { lat: 48.279965, lng: 19.54947 },
    { lat: 48.27993, lng: 19.549515 },
    { lat: 48.279903, lng: 19.549593 },
    { lat: 48.279831, lng: 19.54969 },
    { lat: 48.279752, lng: 19.549699 },
    { lat: 48.279671, lng: 19.5498 },
    { lat: 48.279571, lng: 19.549805 },
    { lat: 48.279397, lng: 19.549961 },
    { lat: 48.279349, lng: 19.549962 },
    { lat: 48.279187, lng: 19.549849 },
    { lat: 48.279141, lng: 19.549758 },
    { lat: 48.279118, lng: 19.549637 },
    { lat: 48.279035, lng: 19.549607 },
    { lat: 48.278997, lng: 19.549627 },
    { lat: 48.278954, lng: 19.549707 },
    { lat: 48.278879, lng: 19.549934 },
    { lat: 48.278869, lng: 19.550059 },
    { lat: 48.278918, lng: 19.550175 },
    { lat: 48.278916, lng: 19.550313 },
    { lat: 48.278843, lng: 19.550402 },
    { lat: 48.278693, lng: 19.550383 },
    { lat: 48.278549, lng: 19.550457 },
    { lat: 48.278384, lng: 19.55042 },
    { lat: 48.27827, lng: 19.550616 },
    { lat: 48.278096, lng: 19.550573 },
    { lat: 48.278, lng: 19.550468 },
    { lat: 48.27796, lng: 19.550398 },
    { lat: 48.277883, lng: 19.550466 },
    { lat: 48.277812, lng: 19.550446 },
    { lat: 48.277769, lng: 19.550349 },
    { lat: 48.277634, lng: 19.550493 },
    { lat: 48.277585, lng: 19.550469 },
    { lat: 48.277598, lng: 19.550397 },
    { lat: 48.277589, lng: 19.55034 },
    { lat: 48.277529, lng: 19.550316 },
    { lat: 48.277478, lng: 19.55037 },
    { lat: 48.277467, lng: 19.550512 },
    { lat: 48.277389, lng: 19.550606 },
    { lat: 48.277404, lng: 19.550704 },
    { lat: 48.277389, lng: 19.550771 },
    { lat: 48.277354, lng: 19.550846 },
    { lat: 48.277339, lng: 19.55093 },
    { lat: 48.277292, lng: 19.551038 },
    { lat: 48.277214, lng: 19.551058 },
    { lat: 48.277132, lng: 19.5511 },
    { lat: 48.276938, lng: 19.551014 },
    { lat: 48.276864, lng: 19.551081 },
    { lat: 48.276799, lng: 19.551304 },
    { lat: 48.276594, lng: 19.551327 },
    { lat: 48.276459, lng: 19.551297 },
    { lat: 48.276339, lng: 19.551531 },
    { lat: 48.276481, lng: 19.551639 },
    { lat: 48.276489, lng: 19.551683 },
    { lat: 48.276322, lng: 19.551805 },
    { lat: 48.27616, lng: 19.551886 },
    { lat: 48.275984, lng: 19.551899 },
    { lat: 48.275925, lng: 19.551812 },
    { lat: 48.275706, lng: 19.551818 },
    { lat: 48.275617, lng: 19.551898 },
    { lat: 48.275532, lng: 19.551973 },
    { lat: 48.275341, lng: 19.55215 },
    { lat: 48.275294, lng: 19.552235 },
    { lat: 48.275282, lng: 19.552315 },
    { lat: 48.275164, lng: 19.552356 },
    { lat: 48.275112, lng: 19.552547 },
    { lat: 48.274961, lng: 19.552565 },
    { lat: 48.274742, lng: 19.552505 },
    { lat: 48.274638, lng: 19.552398 },
    { lat: 48.274539, lng: 19.552365 },
    { lat: 48.274527, lng: 19.552624 },
    { lat: 48.274453, lng: 19.552613 },
    { lat: 48.27436, lng: 19.552802 },
    { lat: 48.274209, lng: 19.552798 },
    { lat: 48.274192, lng: 19.552872 },
    { lat: 48.274116, lng: 19.552933 },
    { lat: 48.274126, lng: 19.553089 },
    { lat: 48.274043, lng: 19.553174 },
    { lat: 48.273942, lng: 19.553204 },
    { lat: 48.273886, lng: 19.553125 },
    { lat: 48.273812, lng: 19.55304 },
    { lat: 48.273721, lng: 19.553104 },
    { lat: 48.273638, lng: 19.553269 },
    { lat: 48.273617, lng: 19.553354 },
    { lat: 48.273565, lng: 19.553446 },
    { lat: 48.273467, lng: 19.553539 },
    { lat: 48.273431, lng: 19.553742 },
    { lat: 48.273382, lng: 19.553839 },
    { lat: 48.273355, lng: 19.554035 },
    { lat: 48.273384, lng: 19.554098 },
    { lat: 48.273403, lng: 19.5542 },
    { lat: 48.27322, lng: 19.554448 },
    { lat: 48.273123, lng: 19.554458 },
    { lat: 48.273055, lng: 19.554414 },
    { lat: 48.273043, lng: 19.554502 },
    { lat: 48.272978, lng: 19.554525 },
    { lat: 48.27301, lng: 19.554665 },
    { lat: 48.273005, lng: 19.554797 },
    { lat: 48.272866, lng: 19.554854 },
    { lat: 48.272697, lng: 19.554861 },
    { lat: 48.272571, lng: 19.555007 },
    { lat: 48.272546, lng: 19.555134 },
    { lat: 48.272468, lng: 19.555143 },
    { lat: 48.27239, lng: 19.555234 },
    { lat: 48.272304, lng: 19.555203 },
    { lat: 48.272261, lng: 19.555482 },
    { lat: 48.272286, lng: 19.555607 },
    { lat: 48.272277, lng: 19.555784 },
    { lat: 48.272184, lng: 19.555877 },
    { lat: 48.272056, lng: 19.555914 },
    { lat: 48.272016, lng: 19.555963 },
    { lat: 48.272006, lng: 19.556198 },
    { lat: 48.271952, lng: 19.556414 },
    { lat: 48.271811, lng: 19.556393 },
    { lat: 48.271497, lng: 19.556722 },
    { lat: 48.271442, lng: 19.556809 },
    { lat: 48.27128, lng: 19.556827 },
    { lat: 48.271193, lng: 19.556794 },
    { lat: 48.271021, lng: 19.557079 },
    { lat: 48.270831, lng: 19.55746 },
    { lat: 48.270722, lng: 19.557553 },
    { lat: 48.270488, lng: 19.557581 },
    { lat: 48.270358, lng: 19.557621 },
    { lat: 48.270342, lng: 19.557626 },
    { lat: 48.270339, lng: 19.557747 },
    { lat: 48.270396, lng: 19.557949 },
    { lat: 48.270386, lng: 19.5581 },
    { lat: 48.270405, lng: 19.558157 },
    { lat: 48.270466, lng: 19.55821 },
    { lat: 48.270472, lng: 19.558234 },
    { lat: 48.270397, lng: 19.558367 },
    { lat: 48.270363, lng: 19.558427 },
    { lat: 48.270385, lng: 19.558617 },
    { lat: 48.270372, lng: 19.558848 },
    { lat: 48.270154, lng: 19.559082 },
    { lat: 48.270044, lng: 19.559116 },
    { lat: 48.270036, lng: 19.559078 },
    { lat: 48.27003, lng: 19.558859 },
    { lat: 48.269802, lng: 19.558949 },
    { lat: 48.269683, lng: 19.559153 },
    { lat: 48.269639, lng: 19.559357 },
    { lat: 48.269719, lng: 19.559394 },
    { lat: 48.269771, lng: 19.559476 },
    { lat: 48.269437, lng: 19.559714 },
    { lat: 48.26943, lng: 19.559866 },
    { lat: 48.269412, lng: 19.559935 },
    { lat: 48.26938, lng: 19.559987 },
    { lat: 48.269316, lng: 19.560017 },
    { lat: 48.269293, lng: 19.560079 },
    { lat: 48.269271, lng: 19.560234 },
    { lat: 48.269237, lng: 19.560312 },
    { lat: 48.269176, lng: 19.560361 },
    { lat: 48.269161, lng: 19.560401 },
    { lat: 48.269181, lng: 19.560463 },
    { lat: 48.269209, lng: 19.56051 },
    { lat: 48.269209, lng: 19.560564 },
    { lat: 48.269172, lng: 19.560782 },
    { lat: 48.269075, lng: 19.560924 },
    { lat: 48.269062, lng: 19.560983 },
    { lat: 48.269089, lng: 19.561056 },
    { lat: 48.268996, lng: 19.561193 },
    { lat: 48.269002, lng: 19.561248 },
    { lat: 48.269119, lng: 19.561367 },
    { lat: 48.269089, lng: 19.561473 },
    { lat: 48.268924, lng: 19.561586 },
    { lat: 48.268879, lng: 19.561684 },
    { lat: 48.268865, lng: 19.561846 },
    { lat: 48.268784, lng: 19.561966 },
    { lat: 48.268735, lng: 19.562303 },
    { lat: 48.268738, lng: 19.562404 },
    { lat: 48.26873, lng: 19.562444 },
    { lat: 48.26869, lng: 19.562481 },
    { lat: 48.268563, lng: 19.562458 },
    { lat: 48.268544, lng: 19.562484 },
    { lat: 48.2686, lng: 19.562634 },
    { lat: 48.268531, lng: 19.562727 },
    { lat: 48.268424, lng: 19.5628 },
    { lat: 48.268548, lng: 19.563017 },
    { lat: 48.268591, lng: 19.563159 },
    { lat: 48.268465, lng: 19.563265 },
    { lat: 48.268417, lng: 19.563402 },
    { lat: 48.268433, lng: 19.563506 },
    { lat: 48.268376, lng: 19.563534 },
    { lat: 48.268368, lng: 19.563557 },
    { lat: 48.268362, lng: 19.563574 },
    { lat: 48.26834, lng: 19.563638 },
    { lat: 48.268321, lng: 19.563697 },
    { lat: 48.268411, lng: 19.563918 },
    { lat: 48.268412, lng: 19.56398 },
    { lat: 48.268287, lng: 19.564142 },
    { lat: 48.2682, lng: 19.564056 },
    { lat: 48.268198, lng: 19.564044 },
    { lat: 48.2678096, lng: 19.5644567 },
    { lat: 48.2662638, lng: 19.5648811 },
    { lat: 48.2659618, lng: 19.5650767 },
    { lat: 48.2655499, lng: 19.5656498 },
    { lat: 48.2651689, lng: 19.5656997 },
    { lat: 48.2651104, lng: 19.5658245 },
    { lat: 48.2649488, lng: 19.5659618 },
    { lat: 48.2646343, lng: 19.5661513 },
    { lat: 48.2642666, lng: 19.5661199 },
    { lat: 48.2629773, lng: 19.5665111 },
    { lat: 48.2627703, lng: 19.5666417 },
    { lat: 48.2624808, lng: 19.5665184 },
    { lat: 48.2619964, lng: 19.5665479 },
    { lat: 48.2618537, lng: 19.5663617 },
    { lat: 48.2613566, lng: 19.566042 },
    { lat: 48.2610276, lng: 19.5655031 },
    { lat: 48.2606093, lng: 19.5652997 },
    { lat: 48.2597112, lng: 19.5659663 },
    { lat: 48.259581, lng: 19.5661495 },
    { lat: 48.2591937, lng: 19.5664466 },
    { lat: 48.2584394, lng: 19.5681083 },
    { lat: 48.2565995, lng: 19.5703458 },
    { lat: 48.2564032, lng: 19.570657 },
    { lat: 48.2562439, lng: 19.5713839 },
    { lat: 48.2559005, lng: 19.5723963 },
    { lat: 48.2555423, lng: 19.57304 },
    { lat: 48.2552668, lng: 19.5737147 },
    { lat: 48.2547006, lng: 19.5747854 },
    { lat: 48.2532223, lng: 19.576259 },
    { lat: 48.2530147, lng: 19.5763515 },
    { lat: 48.2520692, lng: 19.5770863 },
    { lat: 48.2504674, lng: 19.5789165 },
    { lat: 48.2496455, lng: 19.5799893 },
    { lat: 48.2494446, lng: 19.5799852 },
    { lat: 48.2489685, lng: 19.5808088 },
    { lat: 48.2425696, lng: 19.5903337 },
    { lat: 48.2422221, lng: 19.590352 },
    { lat: 48.242345, lng: 19.5904978 },
    { lat: 48.2423526, lng: 19.5905921 },
    { lat: 48.2423284, lng: 19.5906773 },
    { lat: 48.2421232, lng: 19.5906252 },
    { lat: 48.2421223, lng: 19.5907094 },
    { lat: 48.2422597, lng: 19.5908781 },
    { lat: 48.2426037, lng: 19.5909089 },
    { lat: 48.2426529, lng: 19.5911205 },
    { lat: 48.2425759, lng: 19.591214 },
    { lat: 48.2422682, lng: 19.5911628 },
    { lat: 48.2421925, lng: 19.5916496 },
    { lat: 48.242524, lng: 19.5916021 },
    { lat: 48.2427012, lng: 19.5916865 },
    { lat: 48.2425095, lng: 19.592224 },
    { lat: 48.2422942, lng: 19.5921737 },
    { lat: 48.2422295, lng: 19.5920127 },
    { lat: 48.2421593, lng: 19.592178 },
    { lat: 48.2421375, lng: 19.5923916 },
    { lat: 48.2415236, lng: 19.5919805 },
    { lat: 48.2401705, lng: 19.5912473 },
    { lat: 48.2404826, lng: 19.5898953 },
    { lat: 48.2403924, lng: 19.5893029 },
    { lat: 48.2402878, lng: 19.5891323 },
    { lat: 48.2402634, lng: 19.5889966 },
    { lat: 48.2402151, lng: 19.5886279 },
    { lat: 48.2401971, lng: 19.5876474 },
    { lat: 48.2400504, lng: 19.5869307 },
    { lat: 48.2401486, lng: 19.5855526 },
    { lat: 48.2399707, lng: 19.5844754 },
    { lat: 48.2391682, lng: 19.584374 },
    { lat: 48.2385076, lng: 19.5840979 },
    { lat: 48.238467, lng: 19.5842233 },
    { lat: 48.2384815, lng: 19.5845863 },
    { lat: 48.2391002, lng: 19.5886674 },
    { lat: 48.2391, lng: 19.5892556 },
    { lat: 48.2384651, lng: 19.592072 },
    { lat: 48.237918, lng: 19.5942292 },
    { lat: 48.2375507, lng: 19.595017 },
    { lat: 48.2362555, lng: 19.5991726 },
    { lat: 48.2358852, lng: 19.6002144 },
    { lat: 48.2360458, lng: 19.6014612 },
    { lat: 48.2365449, lng: 19.6042955 },
    { lat: 48.236633, lng: 19.6043744 },
    { lat: 48.2366434, lng: 19.6044572 },
    { lat: 48.2365624, lng: 19.6043838 },
    { lat: 48.2367374, lng: 19.6053058 },
    { lat: 48.2368497, lng: 19.6062083 },
    { lat: 48.2368929, lng: 19.6062181 },
    { lat: 48.236914, lng: 19.60634 },
    { lat: 48.236939, lng: 19.606357 },
    { lat: 48.237063, lng: 19.606434 },
    { lat: 48.237275, lng: 19.606602 },
    { lat: 48.23765, lng: 19.606865 },
    { lat: 48.237662, lng: 19.606881 },
    { lat: 48.237722, lng: 19.606931 },
    { lat: 48.237877, lng: 19.607046 },
    { lat: 48.237974, lng: 19.60712 },
    { lat: 48.238118, lng: 19.60723 },
    { lat: 48.238299, lng: 19.607359 },
    { lat: 48.238368, lng: 19.607423 },
    { lat: 48.238475, lng: 19.607534 },
    { lat: 48.238533, lng: 19.607593 },
    { lat: 48.23865, lng: 19.607737 },
    { lat: 48.238778, lng: 19.607913 },
    { lat: 48.238902, lng: 19.608086 },
    { lat: 48.238987, lng: 19.608217 },
    { lat: 48.239014, lng: 19.608272 },
    { lat: 48.239087, lng: 19.608434 },
    { lat: 48.23911, lng: 19.608481 },
    { lat: 48.239152, lng: 19.608629 },
    { lat: 48.239175, lng: 19.60868 },
    { lat: 48.239223, lng: 19.608847 },
    { lat: 48.23923, lng: 19.608889 },
    { lat: 48.239262, lng: 19.609055 },
    { lat: 48.239306, lng: 19.609275 },
    { lat: 48.239353, lng: 19.609542 },
    { lat: 48.239367, lng: 19.609627 },
    { lat: 48.239433, lng: 19.609943 },
    { lat: 48.239472, lng: 19.610189 },
    { lat: 48.239526, lng: 19.610474 },
    { lat: 48.239553, lng: 19.610602 },
    { lat: 48.239635, lng: 19.610995 },
    { lat: 48.239669, lng: 19.611147 },
    { lat: 48.239729, lng: 19.611466 },
    { lat: 48.239767, lng: 19.611643 },
    { lat: 48.239839, lng: 19.611939 },
    { lat: 48.239872, lng: 19.612059 },
    { lat: 48.239922, lng: 19.61226 },
    { lat: 48.239984, lng: 19.612449 },
    { lat: 48.240013, lng: 19.612551 },
    { lat: 48.240029, lng: 19.612625 },
    { lat: 48.240109, lng: 19.612863 },
    { lat: 48.240166, lng: 19.61297 },
    { lat: 48.240273, lng: 19.613165 },
    { lat: 48.240387, lng: 19.613347 },
    { lat: 48.240407, lng: 19.613365 },
    { lat: 48.240484, lng: 19.613509 },
    { lat: 48.240591, lng: 19.613734 },
    { lat: 48.240688, lng: 19.61394 },
    { lat: 48.240757, lng: 19.614115 },
    { lat: 48.240808, lng: 19.614233 },
    { lat: 48.240881, lng: 19.61442 },
    { lat: 48.240985, lng: 19.614737 },
    { lat: 48.241035, lng: 19.614869 },
    { lat: 48.24115, lng: 19.615209 },
    { lat: 48.241237, lng: 19.615433 },
    { lat: 48.241254, lng: 19.615483 },
    { lat: 48.24132, lng: 19.615704 },
    { lat: 48.241392, lng: 19.615927 },
    { lat: 48.241443, lng: 19.61612 },
    { lat: 48.241482, lng: 19.616257 },
    { lat: 48.241567, lng: 19.616619 },
    { lat: 48.241617, lng: 19.616854 },
    { lat: 48.241673, lng: 19.61714 },
    { lat: 48.241723, lng: 19.617382 },
    { lat: 48.241741, lng: 19.617465 },
    { lat: 48.241787, lng: 19.617698 },
    { lat: 48.24184, lng: 19.617918 },
    { lat: 48.241883, lng: 19.618086 },
    { lat: 48.241955, lng: 19.618272 },
    { lat: 48.242038, lng: 19.618571 },
    { lat: 48.242145, lng: 19.618761 },
    { lat: 48.242241, lng: 19.61898 },
    { lat: 48.242353, lng: 19.619207 },
    { lat: 48.242449, lng: 19.619435 },
    { lat: 48.242531, lng: 19.619524 },
    { lat: 48.242642, lng: 19.61969 },
    { lat: 48.242832, lng: 19.619912 },
    { lat: 48.242995, lng: 19.620105 },
    { lat: 48.24326, lng: 19.620405 },
    { lat: 48.243327, lng: 19.620501 },
    { lat: 48.243403, lng: 19.620594 },
    { lat: 48.243474, lng: 19.620686 },
    { lat: 48.243564, lng: 19.620781 },
    { lat: 48.243692, lng: 19.620959 },
    { lat: 48.243812, lng: 19.621184 },
    { lat: 48.243834, lng: 19.621234 },
    { lat: 48.243939, lng: 19.621473 },
    { lat: 48.243989, lng: 19.621599 },
    { lat: 48.244031, lng: 19.621713 },
    { lat: 48.244061, lng: 19.621803 },
    { lat: 48.24415, lng: 19.622037 },
    { lat: 48.244202, lng: 19.6222 },
    { lat: 48.24426, lng: 19.622384 },
    { lat: 48.24433, lng: 19.622525 },
    { lat: 48.24446, lng: 19.622767 },
    { lat: 48.244555, lng: 19.622944 },
    { lat: 48.244687, lng: 19.623127 },
    { lat: 48.244816, lng: 19.623264 },
    { lat: 48.244876, lng: 19.623315 },
    { lat: 48.244994, lng: 19.623404 },
    { lat: 48.245148, lng: 19.62352 },
    { lat: 48.245243, lng: 19.623574 },
    { lat: 48.245357, lng: 19.623647 },
    { lat: 48.245511, lng: 19.62371 },
    { lat: 48.245608, lng: 19.623766 },
    { lat: 48.245758, lng: 19.623803 },
    { lat: 48.24588, lng: 19.62383 },
    { lat: 48.246087, lng: 19.623905 },
    { lat: 48.246218, lng: 19.623976 },
    { lat: 48.246326, lng: 19.624045 },
    { lat: 48.246434, lng: 19.624161 },
    { lat: 48.246476, lng: 19.62421 },
    { lat: 48.246636, lng: 19.624394 },
    { lat: 48.246738, lng: 19.624533 },
    { lat: 48.246755, lng: 19.624572 },
    { lat: 48.246821, lng: 19.6247 },
    { lat: 48.246851, lng: 19.624753 },
    { lat: 48.246918, lng: 19.624951 },
    { lat: 48.246953, lng: 19.625105 },
    { lat: 48.246964, lng: 19.625126 },
    { lat: 48.246996, lng: 19.625345 },
    { lat: 48.247018, lng: 19.625617 },
    { lat: 48.247055, lng: 19.625813 },
    { lat: 48.247091, lng: 19.626078 },
    { lat: 48.247116, lng: 19.626309 },
    { lat: 48.247194, lng: 19.626826 },
    { lat: 48.247237, lng: 19.62713 },
    { lat: 48.247242, lng: 19.627227 },
    { lat: 48.24727, lng: 19.627413 },
    { lat: 48.247297, lng: 19.627565 },
    { lat: 48.24736, lng: 19.627712 },
    { lat: 48.247407, lng: 19.627846 },
    { lat: 48.247488, lng: 19.628 },
    { lat: 48.247568, lng: 19.628103 },
    { lat: 48.247694, lng: 19.628254 },
    { lat: 48.247831, lng: 19.628378 },
    { lat: 48.247962, lng: 19.628483 },
    { lat: 48.248048, lng: 19.628548 },
    { lat: 48.248136, lng: 19.628608 },
    { lat: 48.248239, lng: 19.628677 },
    { lat: 48.248399, lng: 19.628775 },
    { lat: 48.248583, lng: 19.628852 },
    { lat: 48.24869, lng: 19.628912 },
    { lat: 48.248859, lng: 19.628997 },
    { lat: 48.249024, lng: 19.629085 },
    { lat: 48.249128, lng: 19.629168 },
    { lat: 48.249155, lng: 19.629185 },
    { lat: 48.249233, lng: 19.629243 },
    { lat: 48.249337, lng: 19.629353 },
    { lat: 48.249435, lng: 19.629475 },
    { lat: 48.249535, lng: 19.62961 },
    { lat: 48.249589, lng: 19.629703 },
    { lat: 48.249642, lng: 19.629795 },
    { lat: 48.249717, lng: 19.629919 },
    { lat: 48.249745, lng: 19.629968 },
    { lat: 48.249789, lng: 19.63006 },
    { lat: 48.249823, lng: 19.630151 },
    { lat: 48.2499065, lng: 19.6303737 },
    { lat: 48.249972, lng: 19.630563 },
    { lat: 48.250057, lng: 19.630769 },
    { lat: 48.2500674, lng: 19.6307977 },
    { lat: 48.2500725, lng: 19.6308264 },
    { lat: 48.2501403, lng: 19.6307162 },
    { lat: 48.2501857, lng: 19.6309711 },
    { lat: 48.2501657, lng: 19.6313992 },
    { lat: 48.2503932, lng: 19.6320417 },
    { lat: 48.2505803, lng: 19.6320637 },
    { lat: 48.2508031, lng: 19.6319844 },
    { lat: 48.2511665, lng: 19.6316978 },
    { lat: 48.2513774, lng: 19.6317301 },
    { lat: 48.2515777, lng: 19.6319964 },
    { lat: 48.2518105, lng: 19.6326293 },
    { lat: 48.2522245, lng: 19.6330627 },
    { lat: 48.2522841, lng: 19.6332578 },
    { lat: 48.2522406, lng: 19.6336147 },
    { lat: 48.2519457, lng: 19.6337971 },
    { lat: 48.251843, lng: 19.6339448 },
    { lat: 48.2518079, lng: 19.6341838 },
    { lat: 48.251894, lng: 19.6343437 },
    { lat: 48.2527948, lng: 19.6347753 },
    { lat: 48.2533345, lng: 19.6348261 },
    { lat: 48.2535574, lng: 19.6347468 },
    { lat: 48.2540614, lng: 19.6343073 },
    { lat: 48.2542698, lng: 19.63443 },
    { lat: 48.2543333, lng: 19.6345391 },
    { lat: 48.2544896, lng: 19.6352254 },
    { lat: 48.2549258, lng: 19.6361686 },
    { lat: 48.2555014, lng: 19.6381029 },
    { lat: 48.2557163, lng: 19.6385937 },
    { lat: 48.2561605, lng: 19.6391461 },
    { lat: 48.2564435, lng: 19.6387259 },
    { lat: 48.2566329, lng: 19.6386285 },
    { lat: 48.2577294, lng: 19.639001 },
    { lat: 48.2579603, lng: 19.6387042 },
    { lat: 48.2582104, lng: 19.6386993 },
    { lat: 48.2585473, lng: 19.6390734 },
    { lat: 48.2585946, lng: 19.6393197 },
    { lat: 48.2587265, lng: 19.6396196 },
    { lat: 48.2589136, lng: 19.6396486 },
    { lat: 48.2590968, lng: 19.6392873 },
    { lat: 48.2592985, lng: 19.6393878 },
    { lat: 48.25942, lng: 19.6393347 },
    { lat: 48.2595156, lng: 19.6392083 },
    { lat: 48.2594981, lng: 19.6388943 },
    { lat: 48.259581, lng: 19.6386359 },
    { lat: 48.2598211, lng: 19.6383636 },
    { lat: 48.2600906, lng: 19.6381926 },
    { lat: 48.260176, lng: 19.6382398 },
    { lat: 48.2604433, lng: 19.6388438 },
    { lat: 48.2606762, lng: 19.6398491 },
    { lat: 48.2607368, lng: 19.6399466 },
    { lat: 48.2608914, lng: 19.6399754 },
    { lat: 48.2609678, lng: 19.6398886 },
    { lat: 48.2610939, lng: 19.6397455 },
    { lat: 48.2613502, lng: 19.6396073 },
    { lat: 48.2617672, lng: 19.6392378 },
    { lat: 48.2621, lng: 19.639541 },
    { lat: 48.2624269, lng: 19.640268 },
    { lat: 48.2627849, lng: 19.6406072 },
    { lat: 48.2630987, lng: 19.6405912 },
    { lat: 48.2634896, lng: 19.6402908 },
    { lat: 48.2636263, lng: 19.6402751 },
    { lat: 48.2637094, lng: 19.6403438 },
    { lat: 48.2639679, lng: 19.6408769 },
    { lat: 48.2641251, lng: 19.6409126 },
    { lat: 48.2642532, lng: 19.6407535 },
    { lat: 48.2644297, lng: 19.6406464 },
    { lat: 48.2645842, lng: 19.6406481 },
    { lat: 48.2647024, lng: 19.6407244 },
    { lat: 48.2647808, lng: 19.6409326 },
    { lat: 48.2649598, lng: 19.6420277 },
    { lat: 48.2650319, lng: 19.642102 },
    { lat: 48.2654219, lng: 19.6418767 },
    { lat: 48.2656838, lng: 19.6414409 },
    { lat: 48.2656449, lng: 19.6410569 },
    { lat: 48.265521, lng: 19.6406812 },
    { lat: 48.2654369, lng: 19.6405719 },
    { lat: 48.2655604, lng: 19.6403575 },
    { lat: 48.2655645, lng: 19.6400536 },
    { lat: 48.2658758, lng: 19.6402836 },
    { lat: 48.2660141, lng: 19.6404751 },
    { lat: 48.2668514, lng: 19.6400062 },
    { lat: 48.2669395, lng: 19.6401846 },
    { lat: 48.2668754, lng: 19.6405109 },
    { lat: 48.2668972, lng: 19.6406682 },
    { lat: 48.2670265, lng: 19.6406356 },
    { lat: 48.2670747, lng: 19.6407028 },
    { lat: 48.2671532, lng: 19.6414195 },
    { lat: 48.2672456, lng: 19.6414416 },
    { lat: 48.2673069, lng: 19.6416005 },
    { lat: 48.2672634, lng: 19.641913 },
    { lat: 48.2673068, lng: 19.6420203 },
    { lat: 48.2673105, lng: 19.6422792 },
    { lat: 48.2672358, lng: 19.642668 },
    { lat: 48.2670579, lng: 19.6427229 },
    { lat: 48.266717, lng: 19.6431063 },
    { lat: 48.2665873, lng: 19.6436517 },
    { lat: 48.2666872, lng: 19.6438393 },
    { lat: 48.2668398, lng: 19.643804 },
    { lat: 48.2670137, lng: 19.6438843 },
    { lat: 48.2673251, lng: 19.6438689 },
    { lat: 48.2677632, lng: 19.6441617 },
    { lat: 48.2678397, lng: 19.6443602 },
    { lat: 48.2677988, lng: 19.6446504 },
    { lat: 48.26757, lng: 19.644598 },
    { lat: 48.2672413, lng: 19.6446569 },
    { lat: 48.267184, lng: 19.644709 },
    { lat: 48.2670783, lng: 19.6450607 },
    { lat: 48.2674036, lng: 19.6456024 },
    { lat: 48.2680335, lng: 19.6455992 },
    { lat: 48.2682165, lng: 19.6457093 },
    { lat: 48.2682457, lng: 19.6458058 },
    { lat: 48.2680702, lng: 19.6459148 },
    { lat: 48.2679985, lng: 19.6465212 },
    { lat: 48.2681491, lng: 19.6467599 },
    { lat: 48.2681678, lng: 19.6470268 },
    { lat: 48.2682992, lng: 19.6467178 },
    { lat: 48.2695905, lng: 19.6457713 },
    { lat: 48.2718245, lng: 19.6443166 },
    { lat: 48.2723509, lng: 19.6438716 },
    { lat: 48.2738698, lng: 19.6428775 },
    { lat: 48.2741287, lng: 19.6424741 },
    { lat: 48.2748571, lng: 19.6422065 },
    { lat: 48.2762073, lng: 19.6414428 },
    { lat: 48.2772213, lng: 19.6409958 },
    { lat: 48.2778276, lng: 19.6421407 },
    { lat: 48.2779835, lng: 19.6419958 },
    { lat: 48.278002, lng: 19.6420335 },
    { lat: 48.2790389, lng: 19.6414685 },
    { lat: 48.2793321, lng: 19.6413733 },
    { lat: 48.2791867, lng: 19.6402805 },
    { lat: 48.2788383, lng: 19.6390318 },
    { lat: 48.2785782, lng: 19.6383621 },
    { lat: 48.2784276, lng: 19.6382967 },
    { lat: 48.2782834, lng: 19.637975 },
    { lat: 48.2783234, lng: 19.6377375 },
    { lat: 48.2779445, lng: 19.6371092 },
    { lat: 48.2768802, lng: 19.6344432 },
    { lat: 48.2768182, lng: 19.6343402 },
    { lat: 48.2763153, lng: 19.6341583 },
    { lat: 48.275629, lng: 19.6332151 },
    { lat: 48.2749521, lng: 19.6326028 },
    { lat: 48.2748499, lng: 19.6324363 },
    { lat: 48.274462, lng: 19.6320595 },
    { lat: 48.2742711, lng: 19.6316722 },
    { lat: 48.2741027, lng: 19.6311404 },
    { lat: 48.2745316, lng: 19.630611 },
    { lat: 48.2753372, lng: 19.6299389 },
    { lat: 48.275738, lng: 19.6292348 },
    { lat: 48.2769983, lng: 19.6288265 },
    { lat: 48.2771999, lng: 19.6286273 },
    { lat: 48.2774274, lng: 19.6277562 },
    { lat: 48.2774465, lng: 19.6274801 },
    { lat: 48.2775299, lng: 19.6272848 },
    { lat: 48.2776213, lng: 19.6267636 },
    { lat: 48.2781989, lng: 19.6251432 },
    { lat: 48.2789626, lng: 19.6243921 },
    { lat: 48.2795901, lng: 19.6235477 },
    { lat: 48.2799258, lng: 19.6227266 },
    { lat: 48.2802769, lng: 19.6221449 },
    { lat: 48.2808804, lng: 19.6216644 },
    { lat: 48.2812696, lng: 19.6212769 },
    { lat: 48.2824423, lng: 19.6208487 },
    { lat: 48.2830868, lng: 19.6207001 },
    { lat: 48.2838437, lng: 19.620914 },
    { lat: 48.284102, lng: 19.6208458 },
  ],
  Kalonda: [
    { lat: 48.2761804, lng: 19.6548197 },
    { lat: 48.2755898, lng: 19.6546405 },
    { lat: 48.2733256, lng: 19.6546827 },
    { lat: 48.2728308, lng: 19.654503 },
    { lat: 48.2723468, lng: 19.6542164 },
    { lat: 48.2717115, lng: 19.6535438 },
    { lat: 48.271172, lng: 19.6526573 },
    { lat: 48.270994, lng: 19.6522304 },
    { lat: 48.2704214, lng: 19.6502357 },
    { lat: 48.2699711, lng: 19.6493233 },
    { lat: 48.2695556, lng: 19.6488084 },
    { lat: 48.269151, lng: 19.6484343 },
    { lat: 48.2684739, lng: 19.6480627 },
    { lat: 48.2680454, lng: 19.6479528 },
    { lat: 48.2680005, lng: 19.6475874 },
    { lat: 48.2680193, lng: 19.6473556 },
    { lat: 48.2681678, lng: 19.6470268 },
    { lat: 48.2681491, lng: 19.6467599 },
    { lat: 48.2679985, lng: 19.6465212 },
    { lat: 48.2680702, lng: 19.6459148 },
    { lat: 48.2682457, lng: 19.6458058 },
    { lat: 48.2682165, lng: 19.6457093 },
    { lat: 48.2680335, lng: 19.6455992 },
    { lat: 48.2674036, lng: 19.6456024 },
    { lat: 48.2670783, lng: 19.6450607 },
    { lat: 48.267184, lng: 19.644709 },
    { lat: 48.2672413, lng: 19.6446569 },
    { lat: 48.26757, lng: 19.644598 },
    { lat: 48.2677988, lng: 19.6446504 },
    { lat: 48.2678397, lng: 19.6443602 },
    { lat: 48.2677632, lng: 19.6441617 },
    { lat: 48.2673251, lng: 19.6438689 },
    { lat: 48.2670137, lng: 19.6438843 },
    { lat: 48.2668398, lng: 19.643804 },
    { lat: 48.2666872, lng: 19.6438393 },
    { lat: 48.2665873, lng: 19.6436517 },
    { lat: 48.266717, lng: 19.6431063 },
    { lat: 48.2670579, lng: 19.6427229 },
    { lat: 48.2672358, lng: 19.642668 },
    { lat: 48.2673105, lng: 19.6422792 },
    { lat: 48.2673068, lng: 19.6420203 },
    { lat: 48.2672634, lng: 19.641913 },
    { lat: 48.2673069, lng: 19.6416005 },
    { lat: 48.2672456, lng: 19.6414416 },
    { lat: 48.2671532, lng: 19.6414195 },
    { lat: 48.2670747, lng: 19.6407028 },
    { lat: 48.2670265, lng: 19.6406356 },
    { lat: 48.2668972, lng: 19.6406682 },
    { lat: 48.2668754, lng: 19.6405109 },
    { lat: 48.2669395, lng: 19.6401846 },
    { lat: 48.2668514, lng: 19.6400062 },
    { lat: 48.2660141, lng: 19.6404751 },
    { lat: 48.2658758, lng: 19.6402836 },
    { lat: 48.2655645, lng: 19.6400536 },
    { lat: 48.2655604, lng: 19.6403575 },
    { lat: 48.2654369, lng: 19.6405719 },
    { lat: 48.265521, lng: 19.6406812 },
    { lat: 48.2656449, lng: 19.6410569 },
    { lat: 48.2656838, lng: 19.6414409 },
    { lat: 48.2654219, lng: 19.6418767 },
    { lat: 48.2650319, lng: 19.642102 },
    { lat: 48.2649598, lng: 19.6420277 },
    { lat: 48.2647808, lng: 19.6409326 },
    { lat: 48.2647024, lng: 19.6407244 },
    { lat: 48.2645842, lng: 19.6406481 },
    { lat: 48.2644297, lng: 19.6406464 },
    { lat: 48.2642532, lng: 19.6407535 },
    { lat: 48.2641251, lng: 19.6409126 },
    { lat: 48.2639679, lng: 19.6408769 },
    { lat: 48.2637094, lng: 19.6403438 },
    { lat: 48.2636263, lng: 19.6402751 },
    { lat: 48.2634896, lng: 19.6402908 },
    { lat: 48.2630987, lng: 19.6405912 },
    { lat: 48.2627849, lng: 19.6406072 },
    { lat: 48.2624269, lng: 19.640268 },
    { lat: 48.2621, lng: 19.639541 },
    { lat: 48.2617672, lng: 19.6392378 },
    { lat: 48.2613502, lng: 19.6396073 },
    { lat: 48.2610939, lng: 19.6397455 },
    { lat: 48.2609678, lng: 19.6398886 },
    { lat: 48.2608914, lng: 19.6399754 },
    { lat: 48.2607368, lng: 19.6399466 },
    { lat: 48.2606762, lng: 19.6398491 },
    { lat: 48.2604433, lng: 19.6388438 },
    { lat: 48.260176, lng: 19.6382398 },
    { lat: 48.2600906, lng: 19.6381926 },
    { lat: 48.2598211, lng: 19.6383636 },
    { lat: 48.259581, lng: 19.6386359 },
    { lat: 48.2594981, lng: 19.6388943 },
    { lat: 48.2595156, lng: 19.6392083 },
    { lat: 48.25942, lng: 19.6393347 },
    { lat: 48.2592985, lng: 19.6393878 },
    { lat: 48.2590968, lng: 19.6392873 },
    { lat: 48.2589136, lng: 19.6396486 },
    { lat: 48.2587265, lng: 19.6396196 },
    { lat: 48.2585946, lng: 19.6393197 },
    { lat: 48.2585473, lng: 19.6390734 },
    { lat: 48.2582104, lng: 19.6386993 },
    { lat: 48.2579603, lng: 19.6387042 },
    { lat: 48.2577294, lng: 19.639001 },
    { lat: 48.2566329, lng: 19.6386285 },
    { lat: 48.2564435, lng: 19.6387259 },
    { lat: 48.2561605, lng: 19.6391461 },
    { lat: 48.2557163, lng: 19.6385937 },
    { lat: 48.2555014, lng: 19.6381029 },
    { lat: 48.2549258, lng: 19.6361686 },
    { lat: 48.2544896, lng: 19.6352254 },
    { lat: 48.2543333, lng: 19.6345391 },
    { lat: 48.2542698, lng: 19.63443 },
    { lat: 48.2540614, lng: 19.6343073 },
    { lat: 48.2535574, lng: 19.6347468 },
    { lat: 48.2533345, lng: 19.6348261 },
    { lat: 48.2527948, lng: 19.6347753 },
    { lat: 48.251894, lng: 19.6343437 },
    { lat: 48.2518079, lng: 19.6341838 },
    { lat: 48.251843, lng: 19.6339448 },
    { lat: 48.2519457, lng: 19.6337971 },
    { lat: 48.2522406, lng: 19.6336147 },
    { lat: 48.2522841, lng: 19.6332578 },
    { lat: 48.2522245, lng: 19.6330627 },
    { lat: 48.2518105, lng: 19.6326293 },
    { lat: 48.2515777, lng: 19.6319964 },
    { lat: 48.2513774, lng: 19.6317301 },
    { lat: 48.2511665, lng: 19.6316978 },
    { lat: 48.2508031, lng: 19.6319844 },
    { lat: 48.2505803, lng: 19.6320637 },
    { lat: 48.2503932, lng: 19.6320417 },
    { lat: 48.2501657, lng: 19.6313992 },
    { lat: 48.2501857, lng: 19.6309711 },
    { lat: 48.2501403, lng: 19.6307162 },
    { lat: 48.2500725, lng: 19.6308264 },
    { lat: 48.249993, lng: 19.630949 },
    { lat: 48.24985, lng: 19.631163 },
    { lat: 48.249814, lng: 19.631215 },
    { lat: 48.248991, lng: 19.632441 },
    { lat: 48.248225, lng: 19.63358 },
    { lat: 48.248148, lng: 19.633695 },
    { lat: 48.247398, lng: 19.634916 },
    { lat: 48.246823, lng: 19.635848 },
    { lat: 48.246159, lng: 19.636936 },
    { lat: 48.246153, lng: 19.636946 },
    { lat: 48.2461147, lng: 19.6370021 },
    { lat: 48.246085, lng: 19.637057 },
    { lat: 48.246079, lng: 19.637067 },
    { lat: 48.246023, lng: 19.63716 },
    { lat: 48.246002, lng: 19.637191 },
    { lat: 48.2459935, lng: 19.6372046 },
    { lat: 48.24596, lng: 19.637262 },
    { lat: 48.2459256, lng: 19.6373192 },
    { lat: 48.245204, lng: 19.638519 },
    { lat: 48.245103, lng: 19.638691 },
    { lat: 48.245034, lng: 19.638809 },
    { lat: 48.243988, lng: 19.64053 },
    { lat: 48.243427, lng: 19.641442 },
    { lat: 48.242677, lng: 19.64265 },
    { lat: 48.242392, lng: 19.643102 },
    { lat: 48.242154, lng: 19.643487 },
    { lat: 48.241639, lng: 19.644304 },
    { lat: 48.241152, lng: 19.644303 },
    { lat: 48.240865, lng: 19.644338 },
    { lat: 48.240567, lng: 19.644378 },
    { lat: 48.240138, lng: 19.644405 },
    { lat: 48.239733, lng: 19.644455 },
    { lat: 48.239172, lng: 19.644524 },
    { lat: 48.239119, lng: 19.645479 },
    { lat: 48.239061, lng: 19.645859 },
    { lat: 48.238933, lng: 19.646387 },
    { lat: 48.238779, lng: 19.647025 },
    { lat: 48.238731, lng: 19.647503 },
    { lat: 48.238674, lng: 19.647783 },
    { lat: 48.238633, lng: 19.64799 },
    { lat: 48.238623, lng: 19.648229 },
    { lat: 48.238451, lng: 19.648782 },
    { lat: 48.238318, lng: 19.649113 },
    { lat: 48.238109, lng: 19.649665 },
    { lat: 48.237754, lng: 19.650579 },
    { lat: 48.237794, lng: 19.651191 },
    { lat: 48.237878, lng: 19.652279 },
    { lat: 48.238024, lng: 19.652708 },
    { lat: 48.238022, lng: 19.653467 },
    { lat: 48.238082, lng: 19.65395 },
    { lat: 48.238136, lng: 19.65439 },
    { lat: 48.238714, lng: 19.654946 },
    { lat: 48.23948, lng: 19.655785 },
    { lat: 48.240103, lng: 19.656464 },
    { lat: 48.240681, lng: 19.657461 },
    { lat: 48.241193, lng: 19.658323 },
    { lat: 48.241404, lng: 19.659134 },
    { lat: 48.24152, lng: 19.660211 },
    { lat: 48.241457, lng: 19.660935 },
    { lat: 48.241315, lng: 19.661284 },
    { lat: 48.241166, lng: 19.662149 },
    { lat: 48.241129, lng: 19.663037 },
    { lat: 48.241323, lng: 19.664786 },
    { lat: 48.241384, lng: 19.665293 },
    { lat: 48.241382, lng: 19.665777 },
    { lat: 48.2412028, lng: 19.6665075 },
    { lat: 48.241096, lng: 19.666943 },
    { lat: 48.240735, lng: 19.667549 },
    { lat: 48.240311, lng: 19.668411 },
    { lat: 48.239961, lng: 19.668835 },
    { lat: 48.239212, lng: 19.669857 },
    { lat: 48.238866, lng: 19.670238 },
    { lat: 48.238584, lng: 19.67055 },
    { lat: 48.238223, lng: 19.670853 },
    { lat: 48.238188, lng: 19.670884 },
    { lat: 48.237926, lng: 19.670976 },
    { lat: 48.237584, lng: 19.671028 },
    { lat: 48.236877, lng: 19.671032 },
    { lat: 48.236446, lng: 19.670942 },
    { lat: 48.236161, lng: 19.670852 },
    { lat: 48.235886, lng: 19.670745 },
    { lat: 48.235487, lng: 19.670523 },
    { lat: 48.235362, lng: 19.670507 },
    { lat: 48.2367098, lng: 19.6715242 },
    { lat: 48.2369074, lng: 19.6719574 },
    { lat: 48.2370931, lng: 19.672678 },
    { lat: 48.2371919, lng: 19.6735648 },
    { lat: 48.2373769, lng: 19.6744735 },
    { lat: 48.2378138, lng: 19.6761172 },
    { lat: 48.2383207, lng: 19.6777131 },
    { lat: 48.2390387, lng: 19.6786668 },
    { lat: 48.2397596, lng: 19.6784643 },
    { lat: 48.2400223, lng: 19.6782667 },
    { lat: 48.2412878, lng: 19.6768446 },
    { lat: 48.2420109, lng: 19.6763584 },
    { lat: 48.2424913, lng: 19.6755181 },
    { lat: 48.2426984, lng: 19.6750429 },
    { lat: 48.2428569, lng: 19.6748279 },
    { lat: 48.2433628, lng: 19.6746538 },
    { lat: 48.2440017, lng: 19.6747038 },
    { lat: 48.2445409, lng: 19.6748438 },
    { lat: 48.2452411, lng: 19.675157 },
    { lat: 48.2453655, lng: 19.6752137 },
    { lat: 48.2459135, lng: 19.6757721 },
    { lat: 48.246739, lng: 19.6762402 },
    { lat: 48.2474281, lng: 19.6765054 },
    { lat: 48.2480613, lng: 19.6761785 },
    { lat: 48.2485301, lng: 19.6762005 },
    { lat: 48.2499494, lng: 19.6748169 },
    { lat: 48.2506895, lng: 19.6747623 },
    { lat: 48.2515929, lng: 19.6746046 },
    { lat: 48.2523281, lng: 19.674367 },
    { lat: 48.2529857, lng: 19.6749077 },
    { lat: 48.2530513, lng: 19.6755887 },
    { lat: 48.2532215, lng: 19.6756932 },
    { lat: 48.2533876, lng: 19.6758995 },
    { lat: 48.2537296, lng: 19.6761114 },
    { lat: 48.2549282, lng: 19.6761339 },
    { lat: 48.2556895, lng: 19.6759991 },
    { lat: 48.2572193, lng: 19.6750122 },
    { lat: 48.258465, lng: 19.6739264 },
    { lat: 48.259271, lng: 19.6729599 },
    { lat: 48.2599749, lng: 19.671702 },
    { lat: 48.2601762, lng: 19.6714426 },
    { lat: 48.2610161, lng: 19.6700246 },
    { lat: 48.2612818, lng: 19.6697484 },
    { lat: 48.2624183, lng: 19.6681875 },
    { lat: 48.2626437, lng: 19.6686626 },
    { lat: 48.2628575, lng: 19.6688794 },
    { lat: 48.2641619, lng: 19.6667723 },
    { lat: 48.2642986, lng: 19.666656 },
    { lat: 48.2643912, lng: 19.6667095 },
    { lat: 48.2644936, lng: 19.6666281 },
    { lat: 48.2654359, lng: 19.6676856 },
    { lat: 48.2655749, lng: 19.6676723 },
    { lat: 48.2658445, lng: 19.6672535 },
    { lat: 48.2660143, lng: 19.6669191 },
    { lat: 48.2660168, lng: 19.6668276 },
    { lat: 48.2662786, lng: 19.6664871 },
    { lat: 48.2662329, lng: 19.6652953 },
    { lat: 48.2662495, lng: 19.6650153 },
    { lat: 48.2663549, lng: 19.66478 },
    { lat: 48.2664893, lng: 19.6649337 },
    { lat: 48.2668467, lng: 19.6649152 },
    { lat: 48.2678758, lng: 19.664248 },
    { lat: 48.2697728, lng: 19.6633893 },
    { lat: 48.2697946, lng: 19.6632711 },
    { lat: 48.2701692, lng: 19.6631854 },
    { lat: 48.2702646, lng: 19.6630746 },
    { lat: 48.2706041, lng: 19.6630582 },
    { lat: 48.2711703, lng: 19.6626447 },
    { lat: 48.2714879, lng: 19.6625924 },
    { lat: 48.271884, lng: 19.6622548 },
    { lat: 48.2720417, lng: 19.6622548 },
    { lat: 48.2721565, lng: 19.6621766 },
    { lat: 48.2724664, lng: 19.6616246 },
    { lat: 48.2729411, lng: 19.6604864 },
    { lat: 48.272998, lng: 19.6604721 },
    { lat: 48.2748413, lng: 19.6570294 },
    { lat: 48.2760552, lng: 19.6553633 },
    { lat: 48.2761565, lng: 19.6548872 },
    { lat: 48.2761804, lng: 19.6548197 },
  ],
  Mikušovce: [
    { lat: 48.2851807, lng: 19.6874696 },
    { lat: 48.2852008, lng: 19.6875655 },
    { lat: 48.2853954, lng: 19.6886862 },
    { lat: 48.2855468, lng: 19.6892497 },
    { lat: 48.2859891, lng: 19.6903942 },
    { lat: 48.2862277, lng: 19.690864 },
    { lat: 48.286468, lng: 19.6915746 },
    { lat: 48.2865631, lng: 19.6915085 },
    { lat: 48.2872362, lng: 19.6910911 },
    { lat: 48.287854, lng: 19.6910221 },
    { lat: 48.2888689, lng: 19.6988062 },
    { lat: 48.2901988, lng: 19.6984145 },
    { lat: 48.293384, lng: 19.7025099 },
    { lat: 48.2947192, lng: 19.7056295 },
    { lat: 48.2948623, lng: 19.7058296 },
    { lat: 48.2944043, lng: 19.7062733 },
    { lat: 48.2951268, lng: 19.7062046 },
    { lat: 48.298645, lng: 19.7066672 },
    { lat: 48.2985351, lng: 19.706502 },
    { lat: 48.2985503, lng: 19.7058327 },
    { lat: 48.2984398, lng: 19.705553 },
    { lat: 48.29849, lng: 19.7047655 },
    { lat: 48.2984146, lng: 19.7045413 },
    { lat: 48.2980433, lng: 19.7044495 },
    { lat: 48.2978037, lng: 19.7046345 },
    { lat: 48.2974624, lng: 19.7052191 },
    { lat: 48.2973607, lng: 19.7052874 },
    { lat: 48.2972997, lng: 19.7053066 },
    { lat: 48.2970595, lng: 19.7051272 },
    { lat: 48.2968956, lng: 19.7051879 },
    { lat: 48.2968851, lng: 19.7050605 },
    { lat: 48.2968578, lng: 19.7050778 },
    { lat: 48.296875, lng: 19.7043277 },
    { lat: 48.2967926, lng: 19.703647 },
    { lat: 48.2968448, lng: 19.7028466 },
    { lat: 48.2969449, lng: 19.7027184 },
    { lat: 48.2974117, lng: 19.7025497 },
    { lat: 48.2975874, lng: 19.7021449 },
    { lat: 48.297781, lng: 19.7021923 },
    { lat: 48.29785, lng: 19.7018924 },
    { lat: 48.2980307, lng: 19.7017525 },
    { lat: 48.2982724, lng: 19.7017646 },
    { lat: 48.2983755, lng: 19.7015267 },
    { lat: 48.2987276, lng: 19.7012581 },
    { lat: 48.2987788, lng: 19.7005941 },
    { lat: 48.2990727, lng: 19.7005143 },
    { lat: 48.2992645, lng: 19.7005679 },
    { lat: 48.2991987, lng: 19.7001842 },
    { lat: 48.2992313, lng: 19.7001052 },
    { lat: 48.2995699, lng: 19.7000159 },
    { lat: 48.2996493, lng: 19.6997549 },
    { lat: 48.2995843, lng: 19.6995576 },
    { lat: 48.3000924, lng: 19.6989512 },
    { lat: 48.300207, lng: 19.6985426 },
    { lat: 48.3002908, lng: 19.6984776 },
    { lat: 48.3005528, lng: 19.6984647 },
    { lat: 48.3006932, lng: 19.6980262 },
    { lat: 48.3007563, lng: 19.6980008 },
    { lat: 48.3009112, lng: 19.6981639 },
    { lat: 48.3009819, lng: 19.6981568 },
    { lat: 48.3011108, lng: 19.6980461 },
    { lat: 48.3011695, lng: 19.6978431 },
    { lat: 48.3013381, lng: 19.6979114 },
    { lat: 48.3013836, lng: 19.6978676 },
    { lat: 48.3014256, lng: 19.6975986 },
    { lat: 48.3012591, lng: 19.6973287 },
    { lat: 48.3012815, lng: 19.6972023 },
    { lat: 48.3015848, lng: 19.6970312 },
    { lat: 48.3017961, lng: 19.6970684 },
    { lat: 48.3020566, lng: 19.6968946 },
    { lat: 48.3021429, lng: 19.6970133 },
    { lat: 48.3022148, lng: 19.6972842 },
    { lat: 48.3024696, lng: 19.6966584 },
    { lat: 48.30254, lng: 19.6965975 },
    { lat: 48.302831, lng: 19.6966786 },
    { lat: 48.3030864, lng: 19.6965449 },
    { lat: 48.3031274, lng: 19.6968544 },
    { lat: 48.3033647, lng: 19.6969954 },
    { lat: 48.3035525, lng: 19.6967872 },
    { lat: 48.3037861, lng: 19.6968691 },
    { lat: 48.303994, lng: 19.6965077 },
    { lat: 48.3041913, lng: 19.6964567 },
    { lat: 48.3042908, lng: 19.6965656 },
    { lat: 48.3046358, lng: 19.6961186 },
    { lat: 48.3046246, lng: 19.6958674 },
    { lat: 48.3044772, lng: 19.6957459 },
    { lat: 48.3044505, lng: 19.6956025 },
    { lat: 48.3045094, lng: 19.6952575 },
    { lat: 48.3046092, lng: 19.695119 },
    { lat: 48.3044414, lng: 19.6948736 },
    { lat: 48.3044615, lng: 19.6946419 },
    { lat: 48.3047175, lng: 19.6942965 },
    { lat: 48.3048316, lng: 19.6938752 },
    { lat: 48.3050218, lng: 19.6936409 },
    { lat: 48.3051364, lng: 19.6933593 },
    { lat: 48.3054053, lng: 19.6931747 },
    { lat: 48.3057148, lng: 19.6923146 },
    { lat: 48.3059315, lng: 19.6920189 },
    { lat: 48.3069496, lng: 19.6901229 },
    { lat: 48.3071288, lng: 19.6894709 },
    { lat: 48.3073993, lng: 19.6878902 },
    { lat: 48.307883, lng: 19.6838058 },
    { lat: 48.307436, lng: 19.6826858 },
    { lat: 48.3074042, lng: 19.6823779 },
    { lat: 48.3068607, lng: 19.6807403 },
    { lat: 48.3074742, lng: 19.6788226 },
    { lat: 48.3083065, lng: 19.6773369 },
    { lat: 48.3095596, lng: 19.6741054 },
    { lat: 48.3096435, lng: 19.6737443 },
    { lat: 48.3097676, lng: 19.6719949 },
    { lat: 48.3097714, lng: 19.6709528 },
    { lat: 48.307208, lng: 19.6672017 },
    { lat: 48.306701, lng: 19.6670757 },
    { lat: 48.3065857, lng: 19.667246 },
    { lat: 48.3047715, lng: 19.6671911 },
    { lat: 48.3036451, lng: 19.6670259 },
    { lat: 48.3024376, lng: 19.6665618 },
    { lat: 48.3010281, lng: 19.6661128 },
    { lat: 48.3000083, lng: 19.6661608 },
    { lat: 48.2987476, lng: 19.6659183 },
    { lat: 48.298699, lng: 19.6660598 },
    { lat: 48.2987893, lng: 19.6662942 },
    { lat: 48.2985965, lng: 19.6665901 },
    { lat: 48.2954875, lng: 19.6704873 },
    { lat: 48.2886083, lng: 19.6769881 },
    { lat: 48.2886203, lng: 19.6770818 },
    { lat: 48.2886796, lng: 19.677471 },
    { lat: 48.2880274, lng: 19.6776012 },
    { lat: 48.2880732, lng: 19.6776437 },
    { lat: 48.2881605, lng: 19.6784617 },
    { lat: 48.2881495, lng: 19.6792666 },
    { lat: 48.2867427, lng: 19.6846434 },
    { lat: 48.2866298, lng: 19.6855418 },
    { lat: 48.2866342, lng: 19.686239 },
    { lat: 48.2857424, lng: 19.6868292 },
    { lat: 48.2851807, lng: 19.6874696 },
  ],
  Ružiná: [
    { lat: 48.4052161, lng: 19.5528698 },
    { lat: 48.4050005, lng: 19.553178 },
    { lat: 48.4046182, lng: 19.5539852 },
    { lat: 48.4045486, lng: 19.5543307 },
    { lat: 48.4043405, lng: 19.5548318 },
    { lat: 48.4048508, lng: 19.5552183 },
    { lat: 48.4061082, lng: 19.5554114 },
    { lat: 48.4074916, lng: 19.5552027 },
    { lat: 48.4082184, lng: 19.5553778 },
    { lat: 48.4089937, lng: 19.5553268 },
    { lat: 48.4095173, lng: 19.5555134 },
    { lat: 48.410474, lng: 19.5556334 },
    { lat: 48.4109987, lng: 19.5556233 },
    { lat: 48.4114403, lng: 19.5557471 },
    { lat: 48.4126961, lng: 19.5565388 },
    { lat: 48.4128337, lng: 19.5569321 },
    { lat: 48.4130327, lng: 19.5572917 },
    { lat: 48.4131246, lng: 19.5573286 },
    { lat: 48.413463, lng: 19.5578019 },
    { lat: 48.4137696, lng: 19.5577839 },
    { lat: 48.4138783, lng: 19.5579905 },
    { lat: 48.4142753, lng: 19.5580951 },
    { lat: 48.4145035, lng: 19.5583531 },
    { lat: 48.4148573, lng: 19.5586226 },
    { lat: 48.4152175, lng: 19.5590035 },
    { lat: 48.4154111, lng: 19.5594662 },
    { lat: 48.415638, lng: 19.5597428 },
    { lat: 48.4161176, lng: 19.5599794 },
    { lat: 48.4163834, lng: 19.5603229 },
    { lat: 48.4172003, lng: 19.5608864 },
    { lat: 48.4173055, lng: 19.5611153 },
    { lat: 48.4173543, lng: 19.5614369 },
    { lat: 48.4175754, lng: 19.5614697 },
    { lat: 48.4178292, lng: 19.5615974 },
    { lat: 48.4183319, lng: 19.5615762 },
    { lat: 48.4186674, lng: 19.5618636 },
    { lat: 48.4188564, lng: 19.5623939 },
    { lat: 48.4193213, lng: 19.5629236 },
    { lat: 48.4198343, lng: 19.5633228 },
    { lat: 48.4207127, lng: 19.5646647 },
    { lat: 48.4208215, lng: 19.5651171 },
    { lat: 48.4207817, lng: 19.5655051 },
    { lat: 48.4210005, lng: 19.5658539 },
    { lat: 48.4210731, lng: 19.5662565 },
    { lat: 48.421294, lng: 19.566476 },
    { lat: 48.42154, lng: 19.5671494 },
    { lat: 48.4215513, lng: 19.5674927 },
    { lat: 48.4217527, lng: 19.5677553 },
    { lat: 48.4218705, lng: 19.5682852 },
    { lat: 48.4220966, lng: 19.5686496 },
    { lat: 48.4221482, lng: 19.5688114 },
    { lat: 48.4221262, lng: 19.5689967 },
    { lat: 48.4223845, lng: 19.5697157 },
    { lat: 48.4225234, lng: 19.5704606 },
    { lat: 48.42279, lng: 19.5709921 },
    { lat: 48.4225989, lng: 19.571417 },
    { lat: 48.4227379, lng: 19.5720913 },
    { lat: 48.4228936, lng: 19.5722221 },
    { lat: 48.4229719, lng: 19.5726007 },
    { lat: 48.4231538, lng: 19.5729533 },
    { lat: 48.4231786, lng: 19.5729337 },
    { lat: 48.4240642, lng: 19.5749457 },
    { lat: 48.4250344, lng: 19.5759297 },
    { lat: 48.4252717, lng: 19.5769309 },
    { lat: 48.4258676, lng: 19.5776141 },
    { lat: 48.4260867, lng: 19.5781924 },
    { lat: 48.4262914, lng: 19.5785016 },
    { lat: 48.4265853, lng: 19.5787675 },
    { lat: 48.4268764, lng: 19.5793187 },
    { lat: 48.427029, lng: 19.5794544 },
    { lat: 48.4270347, lng: 19.5796046 },
    { lat: 48.4271271, lng: 19.5796011 },
    { lat: 48.4272155, lng: 19.5797644 },
    { lat: 48.4271631, lng: 19.5799447 },
    { lat: 48.4272518, lng: 19.5801385 },
    { lat: 48.4275801, lng: 19.5805134 },
    { lat: 48.4275567, lng: 19.5806208 },
    { lat: 48.4276967, lng: 19.5807955 },
    { lat: 48.4278009, lng: 19.581081 },
    { lat: 48.4279339, lng: 19.5812794 },
    { lat: 48.4280411, lng: 19.5812574 },
    { lat: 48.4282312, lng: 19.5815691 },
    { lat: 48.4285651, lng: 19.5818019 },
    { lat: 48.4289935, lng: 19.5822714 },
    { lat: 48.4294514, lng: 19.5829952 },
    { lat: 48.4294659, lng: 19.5829674 },
    { lat: 48.4296839, lng: 19.582632 },
    { lat: 48.4296777, lng: 19.5828956 },
    { lat: 48.4301562, lng: 19.5828355 },
    { lat: 48.4302355, lng: 19.5827199 },
    { lat: 48.4304695, lng: 19.5819715 },
    { lat: 48.4312078, lng: 19.5790187 },
    { lat: 48.4312605, lng: 19.578964 },
    { lat: 48.4312759, lng: 19.5787321 },
    { lat: 48.4313804, lng: 19.5784569 },
    { lat: 48.4315133, lng: 19.5778609 },
    { lat: 48.4318079, lng: 19.576986 },
    { lat: 48.4321497, lng: 19.5762307 },
    { lat: 48.4322117, lng: 19.5759603 },
    { lat: 48.4323196, lng: 19.575902 },
    { lat: 48.4323562, lng: 19.5756445 },
    { lat: 48.4327487, lng: 19.5747676 },
    { lat: 48.4332202, lng: 19.5742877 },
    { lat: 48.4333091, lng: 19.5743566 },
    { lat: 48.4333305, lng: 19.5742089 },
    { lat: 48.4337215, lng: 19.5738112 },
    { lat: 48.4346023, lng: 19.5734607 },
    { lat: 48.4352442, lng: 19.5723181 },
    { lat: 48.4362982, lng: 19.570702 },
    { lat: 48.4367925, lng: 19.5698563 },
    { lat: 48.4376797, lng: 19.5588775 },
    { lat: 48.4378248, lng: 19.5578141 },
    { lat: 48.4395241, lng: 19.5594113 },
    { lat: 48.4427241, lng: 19.5542939 },
    { lat: 48.4432695, lng: 19.5534125 },
    { lat: 48.4432856, lng: 19.5530282 },
    { lat: 48.442942, lng: 19.553055 },
    { lat: 48.4428016, lng: 19.5527945 },
    { lat: 48.4430001, lng: 19.5523086 },
    { lat: 48.4431903, lng: 19.5521054 },
    { lat: 48.443547, lng: 19.5515548 },
    { lat: 48.4439174, lng: 19.5508363 },
    { lat: 48.4441959, lng: 19.550078 },
    { lat: 48.4447198, lng: 19.5482901 },
    { lat: 48.4451337, lng: 19.5473391 },
    { lat: 48.4451529, lng: 19.5465343 },
    { lat: 48.4450555, lng: 19.5462743 },
    { lat: 48.4455097, lng: 19.5456411 },
    { lat: 48.4458377, lng: 19.5452765 },
    { lat: 48.4460729, lng: 19.5445575 },
    { lat: 48.4452878, lng: 19.5439792 },
    { lat: 48.4453725, lng: 19.5437113 },
    { lat: 48.4444706, lng: 19.5430161 },
    { lat: 48.4445184, lng: 19.5421728 },
    { lat: 48.4449073, lng: 19.5413919 },
    { lat: 48.4449056, lng: 19.5410631 },
    { lat: 48.4450547, lng: 19.5408744 },
    { lat: 48.4450016, lng: 19.5405789 },
    { lat: 48.4450074, lng: 19.5402372 },
    { lat: 48.4451114, lng: 19.5400507 },
    { lat: 48.4448959, lng: 19.5396521 },
    { lat: 48.444471, lng: 19.5390869 },
    { lat: 48.4445713, lng: 19.5388199 },
    { lat: 48.4435668, lng: 19.5371441 },
    { lat: 48.443377, lng: 19.5369416 },
    { lat: 48.4430144, lng: 19.5368261 },
    { lat: 48.4415228, lng: 19.5350254 },
    { lat: 48.4403544, lng: 19.5325623 },
    { lat: 48.4396531, lng: 19.529816 },
    { lat: 48.4387256, lng: 19.5289893 },
    { lat: 48.4375477, lng: 19.5283585 },
    { lat: 48.4371312, lng: 19.5281355 },
    { lat: 48.4368517, lng: 19.5277448 },
    { lat: 48.4347879, lng: 19.5261254 },
    { lat: 48.4334422, lng: 19.5235365 },
    { lat: 48.4310946, lng: 19.522559 },
    { lat: 48.4307536, lng: 19.522568 },
    { lat: 48.4304015, lng: 19.5223854 },
    { lat: 48.4302234, lng: 19.5220918 },
    { lat: 48.4295472, lng: 19.5207991 },
    { lat: 48.4295222, lng: 19.5205288 },
    { lat: 48.4282236, lng: 19.5222756 },
    { lat: 48.4280252, lng: 19.5224943 },
    { lat: 48.4279389, lng: 19.5224047 },
    { lat: 48.4276142, lng: 19.5230844 },
    { lat: 48.4266587, lng: 19.5240136 },
    { lat: 48.4264736, lng: 19.5243505 },
    { lat: 48.4262399, lng: 19.5251193 },
    { lat: 48.4260635, lng: 19.5253757 },
    { lat: 48.4259169, lng: 19.5257905 },
    { lat: 48.4257418, lng: 19.5260235 },
    { lat: 48.425602, lng: 19.5259965 },
    { lat: 48.4252364, lng: 19.5266719 },
    { lat: 48.4250176, lng: 19.5269601 },
    { lat: 48.4235668, lng: 19.5277805 },
    { lat: 48.4231185, lng: 19.5279773 },
    { lat: 48.4224649, lng: 19.5281263 },
    { lat: 48.4221143, lng: 19.5287827 },
    { lat: 48.4216706, lng: 19.5291777 },
    { lat: 48.4212645, lng: 19.5301254 },
    { lat: 48.4209445, lng: 19.5305383 },
    { lat: 48.4207835, lng: 19.5308832 },
    { lat: 48.4203484, lng: 19.531443 },
    { lat: 48.4201847, lng: 19.5314884 },
    { lat: 48.4198206, lng: 19.5307274 },
    { lat: 48.4194267, lng: 19.5305162 },
    { lat: 48.4191195, lng: 19.5304812 },
    { lat: 48.4181208, lng: 19.5312539 },
    { lat: 48.4173367, lng: 19.5321161 },
    { lat: 48.4163325, lng: 19.5330908 },
    { lat: 48.416111, lng: 19.5331994 },
    { lat: 48.4144116, lng: 19.5335906 },
    { lat: 48.4139495, lng: 19.5331719 },
    { lat: 48.4134255, lng: 19.5332218 },
    { lat: 48.4125857, lng: 19.5334362 },
    { lat: 48.4122357, lng: 19.5338352 },
    { lat: 48.4120518, lng: 19.5341991 },
    { lat: 48.4113797, lng: 19.5359238 },
    { lat: 48.4111927, lng: 19.536631 },
    { lat: 48.4109296, lng: 19.5372348 },
    { lat: 48.4108947, lng: 19.537968 },
    { lat: 48.410787, lng: 19.5387351 },
    { lat: 48.4107727, lng: 19.5395013 },
    { lat: 48.4106666, lng: 19.5404991 },
    { lat: 48.4106266, lng: 19.5414145 },
    { lat: 48.410486, lng: 19.5418895 },
    { lat: 48.4103624, lng: 19.5426053 },
    { lat: 48.4102925, lng: 19.5433632 },
    { lat: 48.4101696, lng: 19.5439456 },
    { lat: 48.4099286, lng: 19.544685 },
    { lat: 48.4098541, lng: 19.5451708 },
    { lat: 48.4097175, lng: 19.5456546 },
    { lat: 48.4095764, lng: 19.5458221 },
    { lat: 48.4094914, lng: 19.5460328 },
    { lat: 48.4093129, lng: 19.5467309 },
    { lat: 48.4090656, lng: 19.5469651 },
    { lat: 48.4088877, lng: 19.5469219 },
    { lat: 48.4085879, lng: 19.5470683 },
    { lat: 48.408334, lng: 19.5474263 },
    { lat: 48.4080842, lng: 19.5476274 },
    { lat: 48.4078707, lng: 19.5480582 },
    { lat: 48.4073758, lng: 19.5486891 },
    { lat: 48.4069656, lng: 19.5493268 },
    { lat: 48.4064358, lng: 19.5503554 },
    { lat: 48.4062952, lng: 19.5510201 },
    { lat: 48.4052161, lng: 19.5528698 },
  ],
  VeľkéDravce: [
    { lat: 48.333544, lng: 19.861993 },
    { lat: 48.333968, lng: 19.861953 },
    { lat: 48.334353, lng: 19.861888 },
    { lat: 48.334416, lng: 19.861895 },
    { lat: 48.334547, lng: 19.861899 },
    { lat: 48.334595, lng: 19.8619 },
    { lat: 48.335397, lng: 19.861677 },
    { lat: 48.335539, lng: 19.861357 },
    { lat: 48.335757, lng: 19.860866 },
    { lat: 48.336393, lng: 19.859422 },
    { lat: 48.336761, lng: 19.858574 },
    { lat: 48.336859, lng: 19.858346 },
    { lat: 48.337144, lng: 19.857388 },
    { lat: 48.337316, lng: 19.856811 },
    { lat: 48.337329, lng: 19.856798 },
    { lat: 48.338766, lng: 19.855535 },
    { lat: 48.339014, lng: 19.855306 },
    { lat: 48.33915, lng: 19.855181 },
    { lat: 48.339452, lng: 19.854902 },
    { lat: 48.339768, lng: 19.854668 },
    { lat: 48.340158, lng: 19.854436 },
    { lat: 48.340212, lng: 19.85442 },
    { lat: 48.341039, lng: 19.854162 },
    { lat: 48.34115, lng: 19.854045 },
    { lat: 48.341195, lng: 19.853998 },
    { lat: 48.341277, lng: 19.854022 },
    { lat: 48.342189, lng: 19.854303 },
    { lat: 48.342286, lng: 19.854324 },
    { lat: 48.343783, lng: 19.854656 },
    { lat: 48.343835, lng: 19.854668 },
    { lat: 48.344093, lng: 19.854993 },
    { lat: 48.344194, lng: 19.8549 },
    { lat: 48.344238, lng: 19.854858 },
    { lat: 48.344711, lng: 19.85442 },
    { lat: 48.344979, lng: 19.854004 },
    { lat: 48.345284, lng: 19.853955 },
    { lat: 48.345311, lng: 19.853951 },
    { lat: 48.34535, lng: 19.853944 },
    { lat: 48.345501, lng: 19.85392 },
    { lat: 48.345512, lng: 19.853918 },
    { lat: 48.345551, lng: 19.853931 },
    { lat: 48.345584, lng: 19.853943 },
    { lat: 48.345759, lng: 19.853943 },
    { lat: 48.345776, lng: 19.854013 },
    { lat: 48.345917, lng: 19.854157 },
    { lat: 48.346047, lng: 19.854188 },
    { lat: 48.346634, lng: 19.854329 },
    { lat: 48.347512, lng: 19.85412 },
    { lat: 48.347648, lng: 19.8539933 },
    { lat: 48.3488602, lng: 19.8462599 },
    { lat: 48.3488775, lng: 19.8461151 },
    { lat: 48.3485687, lng: 19.8455946 },
    { lat: 48.3487639, lng: 19.8456042 },
    { lat: 48.3494312, lng: 19.8453254 },
    { lat: 48.3496596, lng: 19.845343 },
    { lat: 48.3497912, lng: 19.8451828 },
    { lat: 48.3499679, lng: 19.8451255 },
    { lat: 48.3500899, lng: 19.8448472 },
    { lat: 48.3502569, lng: 19.844855 },
    { lat: 48.3503554, lng: 19.8450242 },
    { lat: 48.3505901, lng: 19.8452371 },
    { lat: 48.3528605, lng: 19.8426515 },
    { lat: 48.3519464, lng: 19.8397434 },
    { lat: 48.3536638, lng: 19.8357635 },
    { lat: 48.3541788, lng: 19.8360811 },
    { lat: 48.3553383, lng: 19.8324086 },
    { lat: 48.3563627, lng: 19.8300141 },
    { lat: 48.3572353, lng: 19.827621 },
    { lat: 48.3592661, lng: 19.8207457 },
    { lat: 48.3593703, lng: 19.8182004 },
    { lat: 48.3591984, lng: 19.8151424 },
    { lat: 48.3590412, lng: 19.8142789 },
    { lat: 48.3589689, lng: 19.8132754 },
    { lat: 48.3590268, lng: 19.8116826 },
    { lat: 48.359203, lng: 19.8091235 },
    { lat: 48.3589046, lng: 19.80915 },
    { lat: 48.3503496, lng: 19.809841 },
    { lat: 48.3502035, lng: 19.8104388 },
    { lat: 48.350013, lng: 19.8105146 },
    { lat: 48.3500158, lng: 19.8109149 },
    { lat: 48.3502348, lng: 19.8111278 },
    { lat: 48.3501404, lng: 19.8117279 },
    { lat: 48.349815, lng: 19.8121956 },
    { lat: 48.3493911, lng: 19.8134784 },
    { lat: 48.3492871, lng: 19.8133891 },
    { lat: 48.348904, lng: 19.8138001 },
    { lat: 48.3485808, lng: 19.8138057 },
    { lat: 48.3482067, lng: 19.8142676 },
    { lat: 48.3481151, lng: 19.8140556 },
    { lat: 48.3477953, lng: 19.8143934 },
    { lat: 48.3475795, lng: 19.8144586 },
    { lat: 48.3474457, lng: 19.814589 },
    { lat: 48.3473353, lng: 19.8148223 },
    { lat: 48.3470805, lng: 19.8150761 },
    { lat: 48.3468926, lng: 19.815033 },
    { lat: 48.346333, lng: 19.8144474 },
    { lat: 48.3451627, lng: 19.8134771 },
    { lat: 48.339816, lng: 19.8059981 },
    { lat: 48.339758, lng: 19.8060594 },
    { lat: 48.3397514, lng: 19.8064269 },
    { lat: 48.3397973, lng: 19.8146043 },
    { lat: 48.3392555, lng: 19.8155072 },
    { lat: 48.3358851, lng: 19.8174789 },
    { lat: 48.335443, lng: 19.8187058 },
    { lat: 48.3354682, lng: 19.823044 },
    { lat: 48.336483, lng: 19.8242251 },
    { lat: 48.3341828, lng: 19.8302256 },
    { lat: 48.3351808, lng: 19.8310501 },
    { lat: 48.3351758, lng: 19.8311395 },
    { lat: 48.3338648, lng: 19.8348176 },
    { lat: 48.332244, lng: 19.8316874 },
    { lat: 48.3317258, lng: 19.8312317 },
    { lat: 48.3294945, lng: 19.831122 },
    { lat: 48.3290946, lng: 19.8310976 },
    { lat: 48.3274018, lng: 19.8310822 },
    { lat: 48.3262297, lng: 19.8309433 },
    { lat: 48.3261139, lng: 19.8308498 },
    { lat: 48.3261078, lng: 19.8325678 },
    { lat: 48.3268954, lng: 19.8360616 },
    { lat: 48.333544, lng: 19.861993 },
  ],
  Holiša: [
    { lat: 48.3038965, lng: 19.7194014 },
    { lat: 48.303829, lng: 19.7194754 },
    { lat: 48.3036688, lng: 19.7197278 },
    { lat: 48.2998693, lng: 19.7215163 },
    { lat: 48.2994139, lng: 19.7216111 },
    { lat: 48.2982781, lng: 19.721412 },
    { lat: 48.29723, lng: 19.7213819 },
    { lat: 48.2969996, lng: 19.7215203 },
    { lat: 48.2986739, lng: 19.7242479 },
    { lat: 48.2988176, lng: 19.7246652 },
    { lat: 48.298955, lng: 19.7255761 },
    { lat: 48.2991694, lng: 19.7341359 },
    { lat: 48.298917, lng: 19.7357814 },
    { lat: 48.2976094, lng: 19.7390408 },
    { lat: 48.297491, lng: 19.7396181 },
    { lat: 48.2974956, lng: 19.7403327 },
    { lat: 48.297831, lng: 19.7419199 },
    { lat: 48.2980882, lng: 19.7426642 },
    { lat: 48.2990665, lng: 19.7444715 },
    { lat: 48.2997747, lng: 19.7454249 },
    { lat: 48.2996292, lng: 19.7456999 },
    { lat: 48.3004193, lng: 19.7535612 },
    { lat: 48.3005935, lng: 19.7600596 },
    { lat: 48.30089, lng: 19.7599904 },
    { lat: 48.3013079, lng: 19.7602946 },
    { lat: 48.3016567, lng: 19.7599748 },
    { lat: 48.3025163, lng: 19.7600702 },
    { lat: 48.3066806, lng: 19.7605324 },
    { lat: 48.3068478, lng: 19.7604712 },
    { lat: 48.3071453, lng: 19.7605177 },
    { lat: 48.3076148, lng: 19.7599295 },
    { lat: 48.3079774, lng: 19.7588565 },
    { lat: 48.3081938, lng: 19.7590562 },
    { lat: 48.3087317, lng: 19.759122 },
    { lat: 48.3092546, lng: 19.7587405 },
    { lat: 48.3093992, lng: 19.758748 },
    { lat: 48.3110392, lng: 19.760419 },
    { lat: 48.3124051, lng: 19.7610609 },
    { lat: 48.3134304, lng: 19.7609858 },
    { lat: 48.3149596, lng: 19.7621106 },
    { lat: 48.315288, lng: 19.7627349 },
    { lat: 48.3154196, lng: 19.7631915 },
    { lat: 48.315554, lng: 19.7650082 },
    { lat: 48.3156333, lng: 19.7653981 },
    { lat: 48.3160673, lng: 19.7664247 },
    { lat: 48.316147, lng: 19.7664915 },
    { lat: 48.3168857, lng: 19.7663045 },
    { lat: 48.3169868, lng: 19.7662247 },
    { lat: 48.3173489, lng: 19.7669711 },
    { lat: 48.3184929, lng: 19.7696632 },
    { lat: 48.3192361, lng: 19.7703406 },
    { lat: 48.3193886, lng: 19.7703244 },
    { lat: 48.3198116, lng: 19.7704605 },
    { lat: 48.319964, lng: 19.7704149 },
    { lat: 48.3201748, lng: 19.7702379 },
    { lat: 48.3204813, lng: 19.7696765 },
    { lat: 48.3252969, lng: 19.7694764 },
    { lat: 48.3267863, lng: 19.7697041 },
    { lat: 48.3287309, lng: 19.7692179 },
    { lat: 48.3305517, lng: 19.7695506 },
    { lat: 48.3306063, lng: 19.7695898 },
    { lat: 48.3306099, lng: 19.7695689 },
    { lat: 48.3307629, lng: 19.7686713 },
    { lat: 48.3309502, lng: 19.7682926 },
    { lat: 48.331472, lng: 19.7677108 },
    { lat: 48.3317059, lng: 19.7672375 },
    { lat: 48.3317787, lng: 19.76677 },
    { lat: 48.331741, lng: 19.7662741 },
    { lat: 48.331564, lng: 19.765762 },
    { lat: 48.330603, lng: 19.7642075 },
    { lat: 48.3304898, lng: 19.7638632 },
    { lat: 48.3304612, lng: 19.7633976 },
    { lat: 48.3305496, lng: 19.7628964 },
    { lat: 48.3316351, lng: 19.7610467 },
    { lat: 48.3319068, lng: 19.760212 },
    { lat: 48.3319273, lng: 19.759807 },
    { lat: 48.3318388, lng: 19.759209 },
    { lat: 48.3316833, lng: 19.7588013 },
    { lat: 48.330735, lng: 19.7573834 },
    { lat: 48.3297883, lng: 19.7549318 },
    { lat: 48.3291734, lng: 19.7533141 },
    { lat: 48.329051, lng: 19.7528369 },
    { lat: 48.3289821, lng: 19.7521714 },
    { lat: 48.3292304, lng: 19.7480908 },
    { lat: 48.3293159, lng: 19.747297 },
    { lat: 48.3294729, lng: 19.7467208 },
    { lat: 48.3297524, lng: 19.7461396 },
    { lat: 48.3300392, lng: 19.7457243 },
    { lat: 48.3304288, lng: 19.745491 },
    { lat: 48.3308165, lng: 19.7439422 },
    { lat: 48.3309066, lng: 19.7438118 },
    { lat: 48.3312815, lng: 19.7416722 },
    { lat: 48.3317126, lng: 19.7413848 },
    { lat: 48.3317007, lng: 19.7375392 },
    { lat: 48.3315763, lng: 19.7355517 },
    { lat: 48.3287698, lng: 19.7329203 },
    { lat: 48.3281968, lng: 19.7321204 },
    { lat: 48.3263641, lng: 19.7315772 },
    { lat: 48.3244809, lng: 19.7307268 },
    { lat: 48.3220612, lng: 19.7286879 },
    { lat: 48.318908, lng: 19.7257674 },
    { lat: 48.3181789, lng: 19.7251321 },
    { lat: 48.3165091, lng: 19.7220989 },
    { lat: 48.3153588, lng: 19.7223809 },
    { lat: 48.3144003, lng: 19.7218588 },
    { lat: 48.3141051, lng: 19.7217564 },
    { lat: 48.3138612, lng: 19.7210885 },
    { lat: 48.3131237, lng: 19.7207684 },
    { lat: 48.3128546, lng: 19.7207741 },
    { lat: 48.3102659, lng: 19.7216211 },
    { lat: 48.310042, lng: 19.7214237 },
    { lat: 48.3091725, lng: 19.7211027 },
    { lat: 48.3073528, lng: 19.7205737 },
    { lat: 48.3062108, lng: 19.7203586 },
    { lat: 48.3042108, lng: 19.7195759 },
    { lat: 48.3038965, lng: 19.7194014 },
  ],
  Tuhár: [
    { lat: 48.4603255, lng: 19.4308319 },
    { lat: 48.4583714, lng: 19.4304047 },
    { lat: 48.4573253, lng: 19.4302818 },
    { lat: 48.4569773, lng: 19.4303337 },
    { lat: 48.456428, lng: 19.4305401 },
    { lat: 48.455691, lng: 19.4306113 },
    { lat: 48.4552486, lng: 19.4307215 },
    { lat: 48.4548367, lng: 19.4309016 },
    { lat: 48.4543691, lng: 19.4309967 },
    { lat: 48.4529727, lng: 19.431477 },
    { lat: 48.4519998, lng: 19.4319258 },
    { lat: 48.4503676, lng: 19.4331712 },
    { lat: 48.4474694, lng: 19.4344033 },
    { lat: 48.4468799, lng: 19.4347861 },
    { lat: 48.4461965, lng: 19.4360244 },
    { lat: 48.4458058, lng: 19.4369682 },
    { lat: 48.4455631, lng: 19.4386197 },
    { lat: 48.4454203, lng: 19.4392944 },
    { lat: 48.4446819, lng: 19.4412835 },
    { lat: 48.4443679, lng: 19.4422521 },
    { lat: 48.4440215, lng: 19.4430154 },
    { lat: 48.4430561, lng: 19.4442983 },
    { lat: 48.4425896, lng: 19.4450228 },
    { lat: 48.4419866, lng: 19.4455957 },
    { lat: 48.4410958, lng: 19.4462618 },
    { lat: 48.4406268, lng: 19.4468354 },
    { lat: 48.4403055, lng: 19.4473879 },
    { lat: 48.4399841, lng: 19.447811 },
    { lat: 48.4382099, lng: 19.448649 },
    { lat: 48.4375917, lng: 19.4495027 },
    { lat: 48.4370601, lng: 19.4515543 },
    { lat: 48.4368596, lng: 19.4519879 },
    { lat: 48.4366025, lng: 19.4523242 },
    { lat: 48.4362072, lng: 19.452476 },
    { lat: 48.4358938, lng: 19.4523296 },
    { lat: 48.4355894, lng: 19.4522784 },
    { lat: 48.4345886, lng: 19.4524712 },
    { lat: 48.4342558, lng: 19.4526323 },
    { lat: 48.4337235, lng: 19.4533438 },
    { lat: 48.4334221, lng: 19.4535733 },
    { lat: 48.433069, lng: 19.4540478 },
    { lat: 48.4326035, lng: 19.4547685 },
    { lat: 48.4323104, lng: 19.4553683 },
    { lat: 48.4306036, lng: 19.4565881 },
    { lat: 48.4296106, lng: 19.4571136 },
    { lat: 48.4294027, lng: 19.4572941 },
    { lat: 48.4288721, lng: 19.4579013 },
    { lat: 48.4287579, lng: 19.4583264 },
    { lat: 48.4279657, lng: 19.4595566 },
    { lat: 48.4273863, lng: 19.4606901 },
    { lat: 48.4268565, lng: 19.4618655 },
    { lat: 48.4266643, lng: 19.4624312 },
    { lat: 48.4263252, lng: 19.4630645 },
    { lat: 48.4260016, lng: 19.4633953 },
    { lat: 48.4250561, lng: 19.4637356 },
    { lat: 48.4246029, lng: 19.4637058 },
    { lat: 48.424357, lng: 19.4638719 },
    { lat: 48.4233926, lng: 19.4650359 },
    { lat: 48.4232463, lng: 19.4653565 },
    { lat: 48.4231133, lng: 19.466078 },
    { lat: 48.422927, lng: 19.4667217 },
    { lat: 48.422377, lng: 19.4682167 },
    { lat: 48.4218725, lng: 19.4693847 },
    { lat: 48.4218428, lng: 19.4696191 },
    { lat: 48.4216652, lng: 19.4700838 },
    { lat: 48.4215365, lng: 19.4706097 },
    { lat: 48.4213534, lng: 19.4710586 },
    { lat: 48.421189, lng: 19.4712793 },
    { lat: 48.4210675, lng: 19.4715706 },
    { lat: 48.4207426, lng: 19.4730637 },
    { lat: 48.4207479, lng: 19.4734421 },
    { lat: 48.4206382, lng: 19.4743505 },
    { lat: 48.4206808, lng: 19.4749992 },
    { lat: 48.4206522, lng: 19.4753989 },
    { lat: 48.4202999, lng: 19.4774401 },
    { lat: 48.4201722, lng: 19.4778924 },
    { lat: 48.4198914, lng: 19.4786124 },
    { lat: 48.419138, lng: 19.4798326 },
    { lat: 48.4191853, lng: 19.479878 },
    { lat: 48.4185014, lng: 19.4808266 },
    { lat: 48.417987, lng: 19.4822451 },
    { lat: 48.417757, lng: 19.4825794 },
    { lat: 48.4175724, lng: 19.4831189 },
    { lat: 48.417231, lng: 19.4845252 },
    { lat: 48.4170371, lng: 19.4855839 },
    { lat: 48.4166484, lng: 19.4870403 },
    { lat: 48.4169566, lng: 19.487512 },
    { lat: 48.4173996, lng: 19.4880246 },
    { lat: 48.4167729, lng: 19.4901022 },
    { lat: 48.4165336, lng: 19.4906031 },
    { lat: 48.4161024, lng: 19.4921624 },
    { lat: 48.415933, lng: 19.4924707 },
    { lat: 48.4154722, lng: 19.4930782 },
    { lat: 48.4152285, lng: 19.493826 },
    { lat: 48.4152533, lng: 19.4943342 },
    { lat: 48.4151811, lng: 19.4953759 },
    { lat: 48.4152936, lng: 19.4956127 },
    { lat: 48.4152115, lng: 19.49598 },
    { lat: 48.4151813, lng: 19.4965192 },
    { lat: 48.4152351, lng: 19.4969038 },
    { lat: 48.4150075, lng: 19.4978719 },
    { lat: 48.4147291, lng: 19.4983591 },
    { lat: 48.4143131, lng: 19.4988145 },
    { lat: 48.4139784, lng: 19.4990568 },
    { lat: 48.4137362, lng: 19.4995681 },
    { lat: 48.4135729, lng: 19.4996711 },
    { lat: 48.4133624, lng: 19.4999656 },
    { lat: 48.4130338, lng: 19.5005832 },
    { lat: 48.412451, lng: 19.5010859 },
    { lat: 48.4121564, lng: 19.5014413 },
    { lat: 48.4118754, lng: 19.5019907 },
    { lat: 48.4115021, lng: 19.5025201 },
    { lat: 48.4110667, lng: 19.5032655 },
    { lat: 48.4108438, lng: 19.5035144 },
    { lat: 48.4103166, lng: 19.50393 },
    { lat: 48.4097953, lng: 19.5041098 },
    { lat: 48.4083711, lng: 19.5061824 },
    { lat: 48.4081372, lng: 19.5066527 },
    { lat: 48.4078116, lng: 19.5069709 },
    { lat: 48.4065136, lng: 19.5093358 },
    { lat: 48.4060205, lng: 19.5100244 },
    { lat: 48.4058161, lng: 19.51021 },
    { lat: 48.405187, lng: 19.5110092 },
    { lat: 48.4049773, lng: 19.5114177 },
    { lat: 48.4048264, lng: 19.512976 },
    { lat: 48.4043103, lng: 19.5127352 },
    { lat: 48.4039789, lng: 19.5126879 },
    { lat: 48.4036394, lng: 19.5127489 },
    { lat: 48.4033858, lng: 19.5130414 },
    { lat: 48.4028412, lng: 19.5142275 },
    { lat: 48.4019771, lng: 19.5141362 },
    { lat: 48.4020823, lng: 19.5149746 },
    { lat: 48.4017402, lng: 19.5166388 },
    { lat: 48.4014229, lng: 19.5200318 },
    { lat: 48.4009722, lng: 19.5203253 },
    { lat: 48.4002961, lng: 19.5204033 },
    { lat: 48.3998774, lng: 19.5207282 },
    { lat: 48.3996971, lng: 19.5210481 },
    { lat: 48.3996722, lng: 19.5210908 },
    { lat: 48.3996607, lng: 19.5214041 },
    { lat: 48.3995896, lng: 19.5219597 },
    { lat: 48.3996549, lng: 19.5225451 },
    { lat: 48.399655, lng: 19.5242534 },
    { lat: 48.3995681, lng: 19.5244926 },
    { lat: 48.3994141, lng: 19.5247225 },
    { lat: 48.3986762, lng: 19.5251884 },
    { lat: 48.3980236, lng: 19.5260192 },
    { lat: 48.3975342, lng: 19.5273763 },
    { lat: 48.3974856, lng: 19.5286186 },
    { lat: 48.397199, lng: 19.5293658 },
    { lat: 48.3971765, lng: 19.5296603 },
    { lat: 48.397389, lng: 19.5307698 },
    { lat: 48.3975557, lng: 19.5310354 },
    { lat: 48.397724, lng: 19.5315547 },
    { lat: 48.3975868, lng: 19.5325169 },
    { lat: 48.3975145, lng: 19.5333904 },
    { lat: 48.3975846, lng: 19.5339334 },
    { lat: 48.3975779, lng: 19.5342281 },
    { lat: 48.3974923, lng: 19.5347161 },
    { lat: 48.3973889, lng: 19.535013 },
    { lat: 48.397375, lng: 19.5354488 },
    { lat: 48.3971981, lng: 19.5361293 },
    { lat: 48.3969133, lng: 19.5367483 },
    { lat: 48.3968, lng: 19.5373944 },
    { lat: 48.3966566, lng: 19.5377306 },
    { lat: 48.3971052, lng: 19.5379769 },
    { lat: 48.3973277, lng: 19.5386473 },
    { lat: 48.3979755, lng: 19.5381839 },
    { lat: 48.3981798, lng: 19.5378981 },
    { lat: 48.3983658, lng: 19.5377472 },
    { lat: 48.3986392, lng: 19.5375464 },
    { lat: 48.3987378, lng: 19.537534 },
    { lat: 48.399268, lng: 19.5367143 },
    { lat: 48.3993944, lng: 19.5366448 },
    { lat: 48.3997605, lng: 19.5362024 },
    { lat: 48.4000808, lng: 19.5356198 },
    { lat: 48.4007718, lng: 19.5351131 },
    { lat: 48.400972, lng: 19.5350572 },
    { lat: 48.401265, lng: 19.5348611 },
    { lat: 48.4013174, lng: 19.5347482 },
    { lat: 48.4021038, lng: 19.5339407 },
    { lat: 48.4022467, lng: 19.5339348 },
    { lat: 48.4025136, lng: 19.5336346 },
    { lat: 48.402795, lng: 19.5334844 },
    { lat: 48.4032137, lng: 19.5334245 },
    { lat: 48.4035572, lng: 19.5335192 },
    { lat: 48.404003, lng: 19.5334565 },
    { lat: 48.4044013, lng: 19.5330551 },
    { lat: 48.404514, lng: 19.5330526 },
    { lat: 48.4047844, lng: 19.5334404 },
    { lat: 48.4050577, lng: 19.5336528 },
    { lat: 48.4051334, lng: 19.5339006 },
    { lat: 48.4055309, lng: 19.534526 },
    { lat: 48.4056032, lng: 19.534754 },
    { lat: 48.4058701, lng: 19.5350656 },
    { lat: 48.406159, lng: 19.5353902 },
    { lat: 48.4068645, lng: 19.5358605 },
    { lat: 48.4069977, lng: 19.5361559 },
    { lat: 48.4076109, lng: 19.5369909 },
    { lat: 48.4079557, lng: 19.5372568 },
    { lat: 48.408377, lng: 19.5374014 },
    { lat: 48.4084273, lng: 19.5375852 },
    { lat: 48.408523, lng: 19.5385658 },
    { lat: 48.4082517, lng: 19.5401589 },
    { lat: 48.408023, lng: 19.5404987 },
    { lat: 48.4075874, lng: 19.5414438 },
    { lat: 48.4075355, lng: 19.5416872 },
    { lat: 48.4071667, lng: 19.5421699 },
    { lat: 48.4063054, lng: 19.5442633 },
    { lat: 48.4062732, lng: 19.5444206 },
    { lat: 48.4063228, lng: 19.5451347 },
    { lat: 48.4063121, lng: 19.5460502 },
    { lat: 48.4062398, lng: 19.5477828 },
    { lat: 48.4056102, lng: 19.5498707 },
    { lat: 48.4054482, lng: 19.5507377 },
    { lat: 48.4054213, lng: 19.5512061 },
    { lat: 48.4052493, lng: 19.5522929 },
    { lat: 48.4052161, lng: 19.5528698 },
    { lat: 48.4062952, lng: 19.5510201 },
    { lat: 48.4064358, lng: 19.5503554 },
    { lat: 48.4069656, lng: 19.5493268 },
    { lat: 48.4073758, lng: 19.5486891 },
    { lat: 48.4078707, lng: 19.5480582 },
    { lat: 48.4080842, lng: 19.5476274 },
    { lat: 48.408334, lng: 19.5474263 },
    { lat: 48.4085879, lng: 19.5470683 },
    { lat: 48.4088877, lng: 19.5469219 },
    { lat: 48.4090656, lng: 19.5469651 },
    { lat: 48.4093129, lng: 19.5467309 },
    { lat: 48.4094914, lng: 19.5460328 },
    { lat: 48.4095764, lng: 19.5458221 },
    { lat: 48.4097175, lng: 19.5456546 },
    { lat: 48.4098541, lng: 19.5451708 },
    { lat: 48.4099286, lng: 19.544685 },
    { lat: 48.4101696, lng: 19.5439456 },
    { lat: 48.4102925, lng: 19.5433632 },
    { lat: 48.4103624, lng: 19.5426053 },
    { lat: 48.410486, lng: 19.5418895 },
    { lat: 48.4106266, lng: 19.5414145 },
    { lat: 48.4106666, lng: 19.5404991 },
    { lat: 48.4107727, lng: 19.5395013 },
    { lat: 48.410787, lng: 19.5387351 },
    { lat: 48.4108947, lng: 19.537968 },
    { lat: 48.4109296, lng: 19.5372348 },
    { lat: 48.4111927, lng: 19.536631 },
    { lat: 48.4113797, lng: 19.5359238 },
    { lat: 48.4120518, lng: 19.5341991 },
    { lat: 48.4122357, lng: 19.5338352 },
    { lat: 48.4125857, lng: 19.5334362 },
    { lat: 48.4134255, lng: 19.5332218 },
    { lat: 48.4139495, lng: 19.5331719 },
    { lat: 48.4144116, lng: 19.5335906 },
    { lat: 48.416111, lng: 19.5331994 },
    { lat: 48.4163325, lng: 19.5330908 },
    { lat: 48.4173367, lng: 19.5321161 },
    { lat: 48.4181208, lng: 19.5312539 },
    { lat: 48.4191195, lng: 19.5304812 },
    { lat: 48.4194267, lng: 19.5305162 },
    { lat: 48.4198206, lng: 19.5307274 },
    { lat: 48.4201847, lng: 19.5314884 },
    { lat: 48.4203484, lng: 19.531443 },
    { lat: 48.4207835, lng: 19.5308832 },
    { lat: 48.4209445, lng: 19.5305383 },
    { lat: 48.4212645, lng: 19.5301254 },
    { lat: 48.4216706, lng: 19.5291777 },
    { lat: 48.4221143, lng: 19.5287827 },
    { lat: 48.4224649, lng: 19.5281263 },
    { lat: 48.4231185, lng: 19.5279773 },
    { lat: 48.4235668, lng: 19.5277805 },
    { lat: 48.4250176, lng: 19.5269601 },
    { lat: 48.4252364, lng: 19.5266719 },
    { lat: 48.425602, lng: 19.5259965 },
    { lat: 48.4257418, lng: 19.5260235 },
    { lat: 48.4259169, lng: 19.5257905 },
    { lat: 48.4260635, lng: 19.5253757 },
    { lat: 48.4262399, lng: 19.5251193 },
    { lat: 48.4264736, lng: 19.5243505 },
    { lat: 48.4266587, lng: 19.5240136 },
    { lat: 48.4276142, lng: 19.5230844 },
    { lat: 48.4279389, lng: 19.5224047 },
    { lat: 48.4280252, lng: 19.5224943 },
    { lat: 48.4282236, lng: 19.5222756 },
    { lat: 48.4295222, lng: 19.5205288 },
    { lat: 48.4295472, lng: 19.5207991 },
    { lat: 48.4302234, lng: 19.5220918 },
    { lat: 48.4304015, lng: 19.5223854 },
    { lat: 48.4307536, lng: 19.522568 },
    { lat: 48.4310946, lng: 19.522559 },
    { lat: 48.4322085, lng: 19.5208122 },
    { lat: 48.43233, lng: 19.5204198 },
    { lat: 48.4326658, lng: 19.5197112 },
    { lat: 48.4329698, lng: 19.518584 },
    { lat: 48.4338486, lng: 19.5162892 },
    { lat: 48.4340117, lng: 19.5162108 },
    { lat: 48.4344957, lng: 19.5161733 },
    { lat: 48.4343899, lng: 19.513036 },
    { lat: 48.4343108, lng: 19.5125719 },
    { lat: 48.4343017, lng: 19.5121548 },
    { lat: 48.4341249, lng: 19.5119388 },
    { lat: 48.4341106, lng: 19.5114346 },
    { lat: 48.4343969, lng: 19.5100497 },
    { lat: 48.4344733, lng: 19.5086322 },
    { lat: 48.4349216, lng: 19.5083113 },
    { lat: 48.4355538, lng: 19.5075322 },
    { lat: 48.434517, lng: 19.5057577 },
    { lat: 48.4342406, lng: 19.5053647 },
    { lat: 48.434097, lng: 19.5050366 },
    { lat: 48.4341757, lng: 19.5045244 },
    { lat: 48.4343928, lng: 19.5040265 },
    { lat: 48.4357029, lng: 19.5017348 },
    { lat: 48.4364454, lng: 19.5009266 },
    { lat: 48.4373219, lng: 19.5010917 },
    { lat: 48.4377952, lng: 19.5013583 },
    { lat: 48.4382312, lng: 19.5014056 },
    { lat: 48.4385887, lng: 19.5013831 },
    { lat: 48.4393923, lng: 19.5010479 },
    { lat: 48.4398377, lng: 19.5006403 },
    { lat: 48.4402232, lng: 19.5000569 },
    { lat: 48.4410206, lng: 19.4984475 },
    { lat: 48.4416522, lng: 19.4977995 },
    { lat: 48.4413519, lng: 19.4976443 },
    { lat: 48.4410318, lng: 19.4973063 },
    { lat: 48.4406135, lng: 19.4967148 },
    { lat: 48.4403682, lng: 19.4965063 },
    { lat: 48.4396486, lng: 19.4950113 },
    { lat: 48.4393544, lng: 19.4947224 },
    { lat: 48.4391927, lng: 19.4943436 },
    { lat: 48.4393432, lng: 19.4935566 },
    { lat: 48.4393034, lng: 19.4934785 },
    { lat: 48.4384587, lng: 19.4927867 },
    { lat: 48.4379619, lng: 19.4922291 },
    { lat: 48.4374721, lng: 19.4915136 },
    { lat: 48.4372294, lng: 19.4913371 },
    { lat: 48.437153, lng: 19.4914154 },
    { lat: 48.4370605, lng: 19.4913875 },
    { lat: 48.4370301, lng: 19.491144 },
    { lat: 48.4371496, lng: 19.4904934 },
    { lat: 48.4373296, lng: 19.4901966 },
    { lat: 48.437624, lng: 19.489858 },
    { lat: 48.4379068, lng: 19.4888161 },
    { lat: 48.4381481, lng: 19.4883624 },
    { lat: 48.4381465, lng: 19.4880843 },
    { lat: 48.4383266, lng: 19.487763 },
    { lat: 48.4386469, lng: 19.4874042 },
    { lat: 48.4389422, lng: 19.4869438 },
    { lat: 48.4391092, lng: 19.4868794 },
    { lat: 48.4394051, lng: 19.4863113 },
    { lat: 48.4396639, lng: 19.4860814 },
    { lat: 48.4398336, lng: 19.4858056 },
    { lat: 48.440115, lng: 19.4848543 },
    { lat: 48.4403476, lng: 19.4843308 },
    { lat: 48.4405862, lng: 19.4838926 },
    { lat: 48.4408217, lng: 19.483625 },
    { lat: 48.4409654, lng: 19.4832717 },
    { lat: 48.4412182, lng: 19.4830162 },
    { lat: 48.4418397, lng: 19.4828575 },
    { lat: 48.442179, lng: 19.4826873 },
    { lat: 48.4423797, lng: 19.4824489 },
    { lat: 48.442642, lng: 19.4816926 },
    { lat: 48.4429861, lng: 19.4810474 },
    { lat: 48.4431843, lng: 19.4810183 },
    { lat: 48.4433942, lng: 19.480708 },
    { lat: 48.4435218, lng: 19.4803304 },
    { lat: 48.4435837, lng: 19.4799161 },
    { lat: 48.4437405, lng: 19.4795553 },
    { lat: 48.443807, lng: 19.4791217 },
    { lat: 48.4439479, lng: 19.478781 },
    { lat: 48.4442199, lng: 19.4784499 },
    { lat: 48.444345, lng: 19.4779668 },
    { lat: 48.4444911, lng: 19.4777168 },
    { lat: 48.4446381, lng: 19.4773187 },
    { lat: 48.4448527, lng: 19.4768918 },
    { lat: 48.4454715, lng: 19.476412 },
    { lat: 48.4457163, lng: 19.4760295 },
    { lat: 48.4458377, lng: 19.4756618 },
    { lat: 48.4461544, lng: 19.4741865 },
    { lat: 48.4467437, lng: 19.4725384 },
    { lat: 48.446912, lng: 19.4721359 },
    { lat: 48.4471892, lng: 19.4717762 },
    { lat: 48.4474052, lng: 19.4711621 },
    { lat: 48.4475254, lng: 19.4702108 },
    { lat: 48.4476375, lng: 19.4697429 },
    { lat: 48.4477535, lng: 19.4695915 },
    { lat: 48.4477459, lng: 19.4693584 },
    { lat: 48.4478779, lng: 19.4689742 },
    { lat: 48.4482476, lng: 19.4682029 },
    { lat: 48.4484535, lng: 19.4676199 },
    { lat: 48.4484817, lng: 19.4673997 },
    { lat: 48.4485694, lng: 19.4672488 },
    { lat: 48.4487306, lng: 19.467252 },
    { lat: 48.4488282, lng: 19.4670962 },
    { lat: 48.4488762, lng: 19.4668455 },
    { lat: 48.4490102, lng: 19.4666741 },
    { lat: 48.4490117, lng: 19.4664656 },
    { lat: 48.4491631, lng: 19.4663278 },
    { lat: 48.4494738, lng: 19.4656688 },
    { lat: 48.450086, lng: 19.4646599 },
    { lat: 48.450246, lng: 19.4641574 },
    { lat: 48.450537, lng: 19.4635569 },
    { lat: 48.450684, lng: 19.4629613 },
    { lat: 48.4506642, lng: 19.462564 },
    { lat: 48.4502461, lng: 19.460682 },
    { lat: 48.4501383, lng: 19.4596532 },
    { lat: 48.4502011, lng: 19.458998 },
    { lat: 48.4501418, lng: 19.4588395 },
    { lat: 48.4500808, lng: 19.4577977 },
    { lat: 48.449915, lng: 19.4562777 },
    { lat: 48.4499568, lng: 19.4560858 },
    { lat: 48.4498867, lng: 19.4558458 },
    { lat: 48.4498412, lng: 19.4554236 },
    { lat: 48.4498397, lng: 19.4545036 },
    { lat: 48.4497702, lng: 19.454053 },
    { lat: 48.4497691, lng: 19.4533317 },
    { lat: 48.4500129, lng: 19.4528551 },
    { lat: 48.4498939, lng: 19.4525265 },
    { lat: 48.4498435, lng: 19.4515727 },
    { lat: 48.449893, lng: 19.451162 },
    { lat: 48.4503121, lng: 19.45056 },
    { lat: 48.4503222, lng: 19.450413 },
    { lat: 48.4504343, lng: 19.4502794 },
    { lat: 48.4505585, lng: 19.4490088 },
    { lat: 48.450743, lng: 19.4484507 },
    { lat: 48.4512706, lng: 19.4478496 },
    { lat: 48.4512997, lng: 19.447604 },
    { lat: 48.4516097, lng: 19.4472446 },
    { lat: 48.4516278, lng: 19.4461194 },
    { lat: 48.4519178, lng: 19.4458111 },
    { lat: 48.4518264, lng: 19.4455207 },
    { lat: 48.4521634, lng: 19.4446071 },
    { lat: 48.4523463, lng: 19.4443271 },
    { lat: 48.4528082, lng: 19.4429064 },
    { lat: 48.4530798, lng: 19.4426401 },
    { lat: 48.4534233, lng: 19.4418414 },
    { lat: 48.4537398, lng: 19.4415501 },
    { lat: 48.45399, lng: 19.4409034 },
    { lat: 48.4545119, lng: 19.4401834 },
    { lat: 48.4547906, lng: 19.4402849 },
    { lat: 48.4549859, lng: 19.4402183 },
    { lat: 48.4552182, lng: 19.4400048 },
    { lat: 48.4552581, lng: 19.4397027 },
    { lat: 48.4554905, lng: 19.4392751 },
    { lat: 48.4557693, lng: 19.4390726 },
    { lat: 48.4559501, lng: 19.4390556 },
    { lat: 48.4561185, lng: 19.4392614 },
    { lat: 48.4562611, lng: 19.439105 },
    { lat: 48.4562224, lng: 19.4388083 },
    { lat: 48.45646, lng: 19.4383265 },
    { lat: 48.4566059, lng: 19.4383807 },
    { lat: 48.4567605, lng: 19.4382929 },
    { lat: 48.4568763, lng: 19.4380676 },
    { lat: 48.4570049, lng: 19.4380461 },
    { lat: 48.4571805, lng: 19.4376817 },
    { lat: 48.4571183, lng: 19.4373176 },
    { lat: 48.4573573, lng: 19.4368644 },
    { lat: 48.4575874, lng: 19.4366287 },
    { lat: 48.4577591, lng: 19.4365395 },
    { lat: 48.458482, lng: 19.4352058 },
    { lat: 48.4595414, lng: 19.4349428 },
    { lat: 48.45974, lng: 19.4343242 },
    { lat: 48.4600951, lng: 19.4339154 },
    { lat: 48.4603443, lng: 19.4331525 },
    { lat: 48.4603872, lng: 19.4326977 },
    { lat: 48.4603047, lng: 19.4314953 },
    { lat: 48.4603255, lng: 19.4308319 },
  ],
  Píla: [
    { lat: 48.491583, lng: 19.467131 },
    { lat: 48.4910889, lng: 19.4666763 },
    { lat: 48.4906602, lng: 19.4664018 },
    { lat: 48.4904164, lng: 19.4668301 },
    { lat: 48.4902239, lng: 19.4666675 },
    { lat: 48.4896399, lng: 19.4664204 },
    { lat: 48.4893337, lng: 19.4663565 },
    { lat: 48.4888947, lng: 19.4665339 },
    { lat: 48.4888684, lng: 19.466623 },
    { lat: 48.4889162, lng: 19.4668283 },
    { lat: 48.4889272, lng: 19.4674744 },
    { lat: 48.4889937, lng: 19.467569 },
    { lat: 48.4889427, lng: 19.4683119 },
    { lat: 48.4885578, lng: 19.4687078 },
    { lat: 48.4882643, lng: 19.469255 },
    { lat: 48.4879168, lng: 19.4695379 },
    { lat: 48.4872382, lng: 19.4695598 },
    { lat: 48.4869912, lng: 19.4696263 },
    { lat: 48.486674, lng: 19.4700012 },
    { lat: 48.486513, lng: 19.4703468 },
    { lat: 48.4864191, lng: 19.4704352 },
    { lat: 48.4859707, lng: 19.4705056 },
    { lat: 48.4854621, lng: 19.4718051 },
    { lat: 48.4853293, lng: 19.4722703 },
    { lat: 48.4853582, lng: 19.4729866 },
    { lat: 48.4854176, lng: 19.473126 },
    { lat: 48.4854584, lng: 19.4731803 },
    { lat: 48.4857319, lng: 19.4732204 },
    { lat: 48.4860786, lng: 19.4734552 },
    { lat: 48.4861618, lng: 19.4733761 },
    { lat: 48.4865543, lng: 19.4734552 },
    { lat: 48.4867045, lng: 19.4735832 },
    { lat: 48.4868207, lng: 19.4740189 },
    { lat: 48.4867642, lng: 19.4743325 },
    { lat: 48.4865634, lng: 19.4744983 },
    { lat: 48.4862954, lng: 19.4745741 },
    { lat: 48.4861022, lng: 19.4752176 },
    { lat: 48.4857784, lng: 19.475548 },
    { lat: 48.4857294, lng: 19.4753414 },
    { lat: 48.4845008, lng: 19.4759175 },
    { lat: 48.4841344, lng: 19.4759661 },
    { lat: 48.4837845, lng: 19.4758552 },
    { lat: 48.4835584, lng: 19.4756261 },
    { lat: 48.4834354, lng: 19.4759822 },
    { lat: 48.4819961, lng: 19.4769722 },
    { lat: 48.4821196, lng: 19.4775904 },
    { lat: 48.481801, lng: 19.4776453 },
    { lat: 48.4813937, lng: 19.4774445 },
    { lat: 48.4813232, lng: 19.4780089 },
    { lat: 48.4812471, lng: 19.478262 },
    { lat: 48.480978, lng: 19.4785138 },
    { lat: 48.4808706, lng: 19.478241 },
    { lat: 48.4804925, lng: 19.4786353 },
    { lat: 48.4800439, lng: 19.4792874 },
    { lat: 48.4795205, lng: 19.4798853 },
    { lat: 48.4789633, lng: 19.4823167 },
    { lat: 48.4785696, lng: 19.4835704 },
    { lat: 48.4781017, lng: 19.4837614 },
    { lat: 48.4779849, lng: 19.4836973 },
    { lat: 48.4775439, lng: 19.4854329 },
    { lat: 48.4773173, lng: 19.4869588 },
    { lat: 48.4770176, lng: 19.4881269 },
    { lat: 48.4769241, lng: 19.4886613 },
    { lat: 48.4764528, lng: 19.4897185 },
    { lat: 48.4764086, lng: 19.4900794 },
    { lat: 48.4761584, lng: 19.4900048 },
    { lat: 48.476202, lng: 19.4901462 },
    { lat: 48.4761998, lng: 19.4905563 },
    { lat: 48.4757651, lng: 19.4917773 },
    { lat: 48.4759318, lng: 19.4919704 },
    { lat: 48.4756388, lng: 19.4925506 },
    { lat: 48.47556, lng: 19.4929435 },
    { lat: 48.4756715, lng: 19.4932547 },
    { lat: 48.4754482, lng: 19.4936375 },
    { lat: 48.474792, lng: 19.4941689 },
    { lat: 48.474569, lng: 19.4944615 },
    { lat: 48.4746652, lng: 19.4946162 },
    { lat: 48.474233, lng: 19.4950629 },
    { lat: 48.4741934, lng: 19.4951828 },
    { lat: 48.4743353, lng: 19.4956446 },
    { lat: 48.474238, lng: 19.4958274 },
    { lat: 48.4743651, lng: 19.4960175 },
    { lat: 48.4743949, lng: 19.496317 },
    { lat: 48.4744837, lng: 19.4963141 },
    { lat: 48.4745433, lng: 19.4964581 },
    { lat: 48.4746329, lng: 19.4971721 },
    { lat: 48.474959, lng: 19.4976912 },
    { lat: 48.4750623, lng: 19.4984355 },
    { lat: 48.4752267, lng: 19.4986551 },
    { lat: 48.4757414, lng: 19.4989641 },
    { lat: 48.475777, lng: 19.4992214 },
    { lat: 48.4762127, lng: 19.4994307 },
    { lat: 48.4763802, lng: 19.4996603 },
    { lat: 48.4766303, lng: 19.5004609 },
    { lat: 48.4764643, lng: 19.5007007 },
    { lat: 48.4768817, lng: 19.5013392 },
    { lat: 48.4771115, lng: 19.5021867 },
    { lat: 48.4771538, lng: 19.5032009 },
    { lat: 48.477069, lng: 19.5033117 },
    { lat: 48.4771376, lng: 19.5034817 },
    { lat: 48.4777707, lng: 19.5044955 },
    { lat: 48.4781403, lng: 19.5048577 },
    { lat: 48.4783344, lng: 19.5051325 },
    { lat: 48.4784049, lng: 19.5053898 },
    { lat: 48.4784719, lng: 19.5053543 },
    { lat: 48.4786077, lng: 19.5056544 },
    { lat: 48.4791402, lng: 19.5064234 },
    { lat: 48.4798713, lng: 19.5061986 },
    { lat: 48.4801644, lng: 19.5061988 },
    { lat: 48.4802557, lng: 19.5070538 },
    { lat: 48.4805366, lng: 19.5083734 },
    { lat: 48.4803738, lng: 19.5083596 },
    { lat: 48.4803913, lng: 19.5088145 },
    { lat: 48.480443, lng: 19.5092744 },
    { lat: 48.4807048, lng: 19.5103744 },
    { lat: 48.4808857, lng: 19.5114612 },
    { lat: 48.4807696, lng: 19.5115547 },
    { lat: 48.4808719, lng: 19.5119649 },
    { lat: 48.4811, lng: 19.512754 },
    { lat: 48.4811702, lng: 19.512714 },
    { lat: 48.481387, lng: 19.5132158 },
    { lat: 48.4814273, lng: 19.5133012 },
    { lat: 48.4818811, lng: 19.5121494 },
    { lat: 48.4820978, lng: 19.5121086 },
    { lat: 48.4820205, lng: 19.513117 },
    { lat: 48.4820487, lng: 19.5137719 },
    { lat: 48.4821172, lng: 19.5140833 },
    { lat: 48.4822392, lng: 19.5143373 },
    { lat: 48.4821524, lng: 19.5145531 },
    { lat: 48.4826603, lng: 19.5154729 },
    { lat: 48.4826711, lng: 19.5158991 },
    { lat: 48.4827111, lng: 19.5159887 },
    { lat: 48.4829144, lng: 19.5157788 },
    { lat: 48.4835102, lng: 19.5155232 },
    { lat: 48.4840932, lng: 19.5155581 },
    { lat: 48.4851938, lng: 19.5158409 },
    { lat: 48.4854821, lng: 19.5158107 },
    { lat: 48.4860278, lng: 19.51509 },
    { lat: 48.4867301, lng: 19.51463 },
    { lat: 48.486929, lng: 19.5143964 },
    { lat: 48.4874372, lng: 19.512982 },
    { lat: 48.4875756, lng: 19.5127835 },
    { lat: 48.4888646, lng: 19.5116101 },
    { lat: 48.4897517, lng: 19.511102 },
    { lat: 48.4903456, lng: 19.5105665 },
    { lat: 48.4907011, lng: 19.5101147 },
    { lat: 48.4909232, lng: 19.5096776 },
    { lat: 48.4910372, lng: 19.5095601 },
    { lat: 48.4914357, lng: 19.5094029 },
    { lat: 48.4918668, lng: 19.5090995 },
    { lat: 48.4929439, lng: 19.5080227 },
    { lat: 48.49298, lng: 19.508074 },
    { lat: 48.4936019, lng: 19.5073175 },
    { lat: 48.4936163, lng: 19.5071134 },
    { lat: 48.4933956, lng: 19.5065585 },
    { lat: 48.4934395, lng: 19.5064353 },
    { lat: 48.4937717, lng: 19.5062093 },
    { lat: 48.4939819, lng: 19.5057868 },
    { lat: 48.4944729, lng: 19.5053893 },
    { lat: 48.4947774, lng: 19.5047605 },
    { lat: 48.4951937, lng: 19.5042333 },
    { lat: 48.4954087, lng: 19.5040576 },
    { lat: 48.4968219, lng: 19.5042075 },
    { lat: 48.4968366, lng: 19.5042543 },
    { lat: 48.4978472, lng: 19.5041558 },
    { lat: 48.4983241, lng: 19.5036225 },
    { lat: 48.4986032, lng: 19.5037332 },
    { lat: 48.4987763, lng: 19.5037236 },
    { lat: 48.4992994, lng: 19.5034847 },
    { lat: 48.4996439, lng: 19.5031153 },
    { lat: 48.4999774, lng: 19.5025064 },
    { lat: 48.5001746, lng: 19.5023581 },
    { lat: 48.500574, lng: 19.5018557 },
    { lat: 48.5009284, lng: 19.501186 },
    { lat: 48.5015128, lng: 19.5004247 },
    { lat: 48.5017759, lng: 19.500192 },
    { lat: 48.5021597, lng: 19.4999886 },
    { lat: 48.5025006, lng: 19.4999248 },
    { lat: 48.5025106, lng: 19.5000193 },
    { lat: 48.5034345, lng: 19.4992358 },
    { lat: 48.5043126, lng: 19.4981734 },
    { lat: 48.5050685, lng: 19.4973624 },
    { lat: 48.5057969, lng: 19.4969571 },
    { lat: 48.5056864, lng: 19.4969325 },
    { lat: 48.5052329, lng: 19.4970683 },
    { lat: 48.505208, lng: 19.496994 },
    { lat: 48.505203, lng: 19.496933 },
    { lat: 48.505194, lng: 19.496889 },
    { lat: 48.505201, lng: 19.495923 },
    { lat: 48.50521, lng: 19.495723 },
    { lat: 48.505211, lng: 19.495663 },
    { lat: 48.505236, lng: 19.495075 },
    { lat: 48.505289, lng: 19.49374 },
    { lat: 48.505304, lng: 19.49324 },
    { lat: 48.505388, lng: 19.492858 },
    { lat: 48.505565, lng: 19.492727 },
    { lat: 48.505541, lng: 19.491879 },
    { lat: 48.5054, lng: 19.491579 },
    { lat: 48.505297, lng: 19.490934 },
    { lat: 48.505107, lng: 19.49005 },
    { lat: 48.504997, lng: 19.489439 },
    { lat: 48.505158, lng: 19.489312 },
    { lat: 48.505107, lng: 19.48917 },
    { lat: 48.504815, lng: 19.488999 },
    { lat: 48.50477, lng: 19.489221 },
    { lat: 48.504208, lng: 19.489373 },
    { lat: 48.504161, lng: 19.4884 },
    { lat: 48.504044, lng: 19.487295 },
    { lat: 48.504267, lng: 19.486883 },
    { lat: 48.504071, lng: 19.486458 },
    { lat: 48.503272, lng: 19.4858 },
    { lat: 48.502463, lng: 19.485298 },
    { lat: 48.502075, lng: 19.485111 },
    { lat: 48.502034, lng: 19.484353 },
    { lat: 48.501973, lng: 19.484214 },
    { lat: 48.502082, lng: 19.483904 },
    { lat: 48.502686, lng: 19.482988 },
    { lat: 48.502543, lng: 19.482759 },
    { lat: 48.501978, lng: 19.482144 },
    { lat: 48.501638, lng: 19.481571 },
    { lat: 48.50147, lng: 19.480605 },
    { lat: 48.501438, lng: 19.480382 },
    { lat: 48.501947, lng: 19.479438 },
    { lat: 48.501989, lng: 19.478801 },
    { lat: 48.50202, lng: 19.478409 },
    { lat: 48.501326, lng: 19.47761 },
    { lat: 48.5013, lng: 19.47757 },
    { lat: 48.50114, lng: 19.477395 },
    { lat: 48.501019, lng: 19.477204 },
    { lat: 48.500745, lng: 19.476801 },
    { lat: 48.500218, lng: 19.476005 },
    { lat: 48.499364, lng: 19.474687 },
    { lat: 48.499234, lng: 19.474482 },
    { lat: 48.499192, lng: 19.474432 },
    { lat: 48.499088, lng: 19.474315 },
    { lat: 48.498591, lng: 19.473734 },
    { lat: 48.498199, lng: 19.473301 },
    { lat: 48.497892, lng: 19.472952 },
    { lat: 48.497265, lng: 19.47225 },
    { lat: 48.497143, lng: 19.472242 },
    { lat: 48.496392, lng: 19.472206 },
    { lat: 48.496391, lng: 19.472462 },
    { lat: 48.495586, lng: 19.472521 },
    { lat: 48.49543, lng: 19.472515 },
    { lat: 48.495165, lng: 19.472486 },
    { lat: 48.494925, lng: 19.472401 },
    { lat: 48.494642, lng: 19.472236 },
    { lat: 48.494592, lng: 19.472207 },
    { lat: 48.493831, lng: 19.471567 },
    { lat: 48.49373, lng: 19.470799 },
    { lat: 48.493392, lng: 19.471048 },
    { lat: 48.493284, lng: 19.470249 },
    { lat: 48.493015, lng: 19.46992 },
    { lat: 48.492797, lng: 19.469433 },
    { lat: 48.492887, lng: 19.468539 },
    { lat: 48.49239, lng: 19.46832 },
    { lat: 48.492255, lng: 19.467671 },
    { lat: 48.491917, lng: 19.467879 },
    { lat: 48.491772, lng: 19.467335 },
    { lat: 48.491583, lng: 19.467131 },
  ],
  Šurice: [
    { lat: 48.198126, lng: 19.890468 },
    { lat: 48.198134, lng: 19.891942 },
    { lat: 48.197964, lng: 19.893397 },
    { lat: 48.197765, lng: 19.89468 },
    { lat: 48.19757, lng: 19.895537 },
    { lat: 48.197115, lng: 19.89662 },
    { lat: 48.197066, lng: 19.896736 },
    { lat: 48.196873, lng: 19.897196 },
    { lat: 48.196405, lng: 19.898946 },
    { lat: 48.196222, lng: 19.899697 },
    { lat: 48.196106, lng: 19.900543 },
    { lat: 48.196086, lng: 19.900682 },
    { lat: 48.196073, lng: 19.900771 },
    { lat: 48.196013, lng: 19.901179 },
    { lat: 48.195871, lng: 19.901959 },
    { lat: 48.195649, lng: 19.902551 },
    { lat: 48.195509, lng: 19.903463 },
    { lat: 48.195472, lng: 19.903764 },
    { lat: 48.195406, lng: 19.90411 },
    { lat: 48.195101, lng: 19.905002 },
    { lat: 48.194992, lng: 19.905485 },
    { lat: 48.19477, lng: 19.906458 },
    { lat: 48.194751, lng: 19.907015 },
    { lat: 48.196095, lng: 19.908774 },
    { lat: 48.196898, lng: 19.909651 },
    { lat: 48.197734, lng: 19.910071 },
    { lat: 48.199672, lng: 19.911299 },
    { lat: 48.199945, lng: 19.91151 },
    { lat: 48.20043, lng: 19.912303 },
    { lat: 48.201071, lng: 19.913033 },
    { lat: 48.201238, lng: 19.91327 },
    { lat: 48.202216, lng: 19.91438 },
    { lat: 48.202517, lng: 19.914783 },
    { lat: 48.203028, lng: 19.915507 },
    { lat: 48.203001, lng: 19.915644 },
    { lat: 48.202986, lng: 19.915721 },
    { lat: 48.202889, lng: 19.916203 },
    { lat: 48.202794, lng: 19.916672 },
    { lat: 48.202591, lng: 19.917414 },
    { lat: 48.202577, lng: 19.917551 },
    { lat: 48.202431, lng: 19.918991 },
    { lat: 48.202261, lng: 19.921225 },
    { lat: 48.202146, lng: 19.922976 },
    { lat: 48.202172, lng: 19.924565 },
    { lat: 48.202746, lng: 19.924026 },
    { lat: 48.203875, lng: 19.924139 },
    { lat: 48.204012, lng: 19.923122 },
    { lat: 48.204256, lng: 19.922274 },
    { lat: 48.204619, lng: 19.921686 },
    { lat: 48.205138, lng: 19.921334 },
    { lat: 48.205522, lng: 19.921174 },
    { lat: 48.206219, lng: 19.92136 },
    { lat: 48.206611, lng: 19.921791 },
    { lat: 48.20664, lng: 19.921793 },
    { lat: 48.206661, lng: 19.921815 },
    { lat: 48.206801, lng: 19.921943 },
    { lat: 48.20706, lng: 19.922153 },
    { lat: 48.207533, lng: 19.922825 },
    { lat: 48.208, lng: 19.923797 },
    { lat: 48.208972, lng: 19.925303 },
    { lat: 48.209399, lng: 19.925642 },
    { lat: 48.209391, lng: 19.925665 },
    { lat: 48.2103, lng: 19.926056 },
    { lat: 48.210553, lng: 19.92627 },
    { lat: 48.210944, lng: 19.926392 },
    { lat: 48.211259, lng: 19.92649 },
    { lat: 48.211458, lng: 19.926804 },
    { lat: 48.211903, lng: 19.927066 },
    { lat: 48.212497, lng: 19.927052 },
    { lat: 48.212976, lng: 19.92714 },
    { lat: 48.213569, lng: 19.927166 },
    { lat: 48.21407, lng: 19.927409 },
    { lat: 48.214657, lng: 19.927603 },
    { lat: 48.215043, lng: 19.927953 },
    { lat: 48.215555, lng: 19.928646 },
    { lat: 48.215585, lng: 19.928596 },
    { lat: 48.216121, lng: 19.927949 },
    { lat: 48.21642, lng: 19.927493 },
    { lat: 48.217215, lng: 19.926859 },
    { lat: 48.21756, lng: 19.926664 },
    { lat: 48.217793, lng: 19.926644 },
    { lat: 48.218314, lng: 19.926459 },
    { lat: 48.21857, lng: 19.926436 },
    { lat: 48.21862, lng: 19.926431 },
    { lat: 48.219178, lng: 19.926381 },
    { lat: 48.219942, lng: 19.926087 },
    { lat: 48.220408, lng: 19.926435 },
    { lat: 48.220766, lng: 19.926745 },
    { lat: 48.221037, lng: 19.926846 },
    { lat: 48.221418, lng: 19.927158 },
    { lat: 48.221555, lng: 19.927337 },
    { lat: 48.221825, lng: 19.927424 },
    { lat: 48.22221, lng: 19.927673 },
    { lat: 48.22382, lng: 19.928712 },
    { lat: 48.224481, lng: 19.929067 },
    { lat: 48.224672, lng: 19.929117 },
    { lat: 48.225005, lng: 19.929103 },
    { lat: 48.22522, lng: 19.929124 },
    { lat: 48.225289, lng: 19.929155 },
    { lat: 48.225639, lng: 19.929309 },
    { lat: 48.225956, lng: 19.929385 },
    { lat: 48.226379, lng: 19.929296 },
    { lat: 48.226665, lng: 19.930651 },
    { lat: 48.227068, lng: 19.930592 },
    { lat: 48.227321, lng: 19.930605 },
    { lat: 48.22741, lng: 19.930645 },
    { lat: 48.227761, lng: 19.930802 },
    { lat: 48.228201, lng: 19.930886 },
    { lat: 48.228336, lng: 19.930949 },
    { lat: 48.228873, lng: 19.930975 },
    { lat: 48.229798, lng: 19.931115 },
    { lat: 48.230668, lng: 19.931255 },
    { lat: 48.231124, lng: 19.931266 },
    { lat: 48.232686, lng: 19.931222 },
    { lat: 48.233377, lng: 19.931329 },
    { lat: 48.233517, lng: 19.931088 },
    { lat: 48.233421, lng: 19.931718 },
    { lat: 48.233422, lng: 19.931816 },
    { lat: 48.233477, lng: 19.931911 },
    { lat: 48.23372, lng: 19.932139 },
    { lat: 48.23375, lng: 19.932059 },
    { lat: 48.233791, lng: 19.932084 },
    { lat: 48.2338, lng: 19.932023 },
    { lat: 48.233828, lng: 19.931984 },
    { lat: 48.233835, lng: 19.931973 },
    { lat: 48.233896, lng: 19.931885 },
    { lat: 48.233905, lng: 19.931745 },
    { lat: 48.233936, lng: 19.93133 },
    { lat: 48.233938, lng: 19.931304 },
    { lat: 48.233941, lng: 19.931303 },
    { lat: 48.234225, lng: 19.931494 },
    { lat: 48.234272, lng: 19.931504 },
    { lat: 48.234346, lng: 19.931518 },
    { lat: 48.234365, lng: 19.931522 },
    { lat: 48.234385, lng: 19.93153 },
    { lat: 48.234557, lng: 19.9316 },
    { lat: 48.234715, lng: 19.931664 },
    { lat: 48.234762, lng: 19.931684 },
    { lat: 48.234867, lng: 19.931728 },
    { lat: 48.234879, lng: 19.931769 },
    { lat: 48.235427, lng: 19.931856 },
    { lat: 48.235566, lng: 19.932015 },
    { lat: 48.23564, lng: 19.932062 },
    { lat: 48.236113, lng: 19.932377 },
    { lat: 48.236235, lng: 19.932459 },
    { lat: 48.236179, lng: 19.932794 },
    { lat: 48.236169, lng: 19.932847 },
    { lat: 48.236102, lng: 19.933242 },
    { lat: 48.236038, lng: 19.93363 },
    { lat: 48.235988, lng: 19.934034 },
    { lat: 48.235942, lng: 19.934435 },
    { lat: 48.235896, lng: 19.934826 },
    { lat: 48.235867, lng: 19.935067 },
    { lat: 48.235849, lng: 19.935213 },
    { lat: 48.235803, lng: 19.935656 },
    { lat: 48.235782, lng: 19.935869 },
    { lat: 48.235755, lng: 19.93608 },
    { lat: 48.235726, lng: 19.936292 },
    { lat: 48.2357, lng: 19.936503 },
    { lat: 48.235684, lng: 19.936717 },
    { lat: 48.235668, lng: 19.936931 },
    { lat: 48.235723, lng: 19.937153 },
    { lat: 48.235778, lng: 19.937376 },
    { lat: 48.235833, lng: 19.9376 },
    { lat: 48.235856, lng: 19.937693 },
    { lat: 48.235775, lng: 19.937812 },
    { lat: 48.235645, lng: 19.938001 },
    { lat: 48.235521, lng: 19.938182 },
    { lat: 48.235059, lng: 19.938859 },
    { lat: 48.235068, lng: 19.938918 },
    { lat: 48.235093, lng: 19.939061 },
    { lat: 48.235006, lng: 19.939764 },
    { lat: 48.234991, lng: 19.940984 },
    { lat: 48.235198, lng: 19.941372 },
    { lat: 48.235237, lng: 19.941702 },
    { lat: 48.235482, lng: 19.942089 },
    { lat: 48.235714, lng: 19.942406 },
    { lat: 48.236202, lng: 19.942645 },
    { lat: 48.236777, lng: 19.942726 },
    { lat: 48.237547, lng: 19.94269 },
    { lat: 48.237665, lng: 19.941958 },
    { lat: 48.237947, lng: 19.941831 },
    { lat: 48.238184, lng: 19.941676 },
    { lat: 48.238392, lng: 19.942113 },
    { lat: 48.238587, lng: 19.942254 },
    { lat: 48.238765, lng: 19.942197 },
    { lat: 48.239148, lng: 19.941699 },
    { lat: 48.23893, lng: 19.940762 },
    { lat: 48.238795, lng: 19.940522 },
    { lat: 48.238698, lng: 19.940026 },
    { lat: 48.238665, lng: 19.939408 },
    { lat: 48.239185, lng: 19.939146 },
    { lat: 48.23996, lng: 19.939798 },
    { lat: 48.240557, lng: 19.9392 },
    { lat: 48.24102, lng: 19.938699 },
    { lat: 48.241389, lng: 19.938051 },
    { lat: 48.243192, lng: 19.937505 },
    { lat: 48.243633, lng: 19.938353 },
    { lat: 48.2439, lng: 19.938865 },
    { lat: 48.244494, lng: 19.939485 },
    { lat: 48.244823, lng: 19.939539 },
    { lat: 48.244846, lng: 19.939542 },
    { lat: 48.244872, lng: 19.939547 },
    { lat: 48.245271, lng: 19.938442 },
    { lat: 48.245771, lng: 19.937797 },
    { lat: 48.246181, lng: 19.937462 },
    { lat: 48.246821, lng: 19.936518 },
    { lat: 48.248012, lng: 19.935571 },
    { lat: 48.248458, lng: 19.935432 },
    { lat: 48.248511, lng: 19.935416 },
    { lat: 48.249731, lng: 19.935035 },
    { lat: 48.249551, lng: 19.934695 },
    { lat: 48.249356, lng: 19.934327 },
    { lat: 48.249385, lng: 19.934076 },
    { lat: 48.249408, lng: 19.933865 },
    { lat: 48.249447, lng: 19.933515 },
    { lat: 48.249583, lng: 19.9322689 },
    { lat: 48.249521, lng: 19.931364 },
    { lat: 48.249538, lng: 19.930828 },
    { lat: 48.249521, lng: 19.930024 },
    { lat: 48.249648, lng: 19.928793 },
    { lat: 48.249892, lng: 19.928125 },
    { lat: 48.249971, lng: 19.927908 },
    { lat: 48.250197, lng: 19.92714 },
    { lat: 48.25024, lng: 19.926879 },
    { lat: 48.250393, lng: 19.925946 },
    { lat: 48.250441, lng: 19.925472 },
    { lat: 48.250482, lng: 19.925367 },
    { lat: 48.250615, lng: 19.925022 },
    { lat: 48.250746, lng: 19.924752 },
    { lat: 48.250901, lng: 19.924075 },
    { lat: 48.251003, lng: 19.923702 },
    { lat: 48.251097, lng: 19.92336 },
    { lat: 48.251157, lng: 19.923168 },
    { lat: 48.251178, lng: 19.923101 },
    { lat: 48.251373, lng: 19.922544 },
    { lat: 48.251473, lng: 19.922255 },
    { lat: 48.251576, lng: 19.921624 },
    { lat: 48.251557, lng: 19.920574 },
    { lat: 48.251557, lng: 19.92034 },
    { lat: 48.251557, lng: 19.919972 },
    { lat: 48.251377, lng: 19.91923 },
    { lat: 48.251177, lng: 19.918596 },
    { lat: 48.251217, lng: 19.918184 },
    { lat: 48.251322, lng: 19.917242 },
    { lat: 48.251444, lng: 19.91726 },
    { lat: 48.25161, lng: 19.916657 },
    { lat: 48.251752, lng: 19.916071 },
    { lat: 48.251828, lng: 19.915632 },
    { lat: 48.251854, lng: 19.915315 },
    { lat: 48.252035, lng: 19.91523 },
    { lat: 48.252601, lng: 19.914517 },
    { lat: 48.253365, lng: 19.913868 },
    { lat: 48.254212, lng: 19.913153 },
    { lat: 48.254347, lng: 19.912912 },
    { lat: 48.254654, lng: 19.912372 },
    { lat: 48.25476, lng: 19.912275 },
    { lat: 48.255357, lng: 19.911722 },
    { lat: 48.255786, lng: 19.911306 },
    { lat: 48.256349, lng: 19.910526 },
    { lat: 48.2560259, lng: 19.9102469 },
    { lat: 48.2534665, lng: 19.9077871 },
    { lat: 48.2508415, lng: 19.9110871 },
    { lat: 48.25054, lng: 19.9110193 },
    { lat: 48.2494641, lng: 19.9105716 },
    { lat: 48.2491166, lng: 19.9103244 },
    { lat: 48.2485162, lng: 19.9100648 },
    { lat: 48.2441075, lng: 19.9085369 },
    { lat: 48.2435043, lng: 19.9078938 },
    { lat: 48.2430385, lng: 19.9077775 },
    { lat: 48.2424189, lng: 19.9073559 },
    { lat: 48.2421462, lng: 19.9068075 },
    { lat: 48.2415864, lng: 19.9051588 },
    { lat: 48.2410325, lng: 19.9049044 },
    { lat: 48.2402548, lng: 19.9044266 },
    { lat: 48.2400841, lng: 19.9048036 },
    { lat: 48.239024, lng: 19.9045701 },
    { lat: 48.2386686, lng: 19.9042714 },
    { lat: 48.237957, lng: 19.9029986 },
    { lat: 48.2379865, lng: 19.902559 },
    { lat: 48.2378497, lng: 19.9022598 },
    { lat: 48.2373784, lng: 19.9022257 },
    { lat: 48.235978, lng: 19.9023245 },
    { lat: 48.2359484, lng: 19.9019764 },
    { lat: 48.2358559, lng: 19.9020058 },
    { lat: 48.2357721, lng: 19.902515 },
    { lat: 48.2355194, lng: 19.9031366 },
    { lat: 48.2352643, lng: 19.9033096 },
    { lat: 48.2350897, lng: 19.9029943 },
    { lat: 48.2347613, lng: 19.9030585 },
    { lat: 48.2343528, lng: 19.9039346 },
    { lat: 48.2337936, lng: 19.9038207 },
    { lat: 48.232961, lng: 19.9048349 },
    { lat: 48.2327874, lng: 19.9044177 },
    { lat: 48.2324422, lng: 19.9049312 },
    { lat: 48.2323482, lng: 19.9053078 },
    { lat: 48.2323565, lng: 19.9060762 },
    { lat: 48.2319943, lng: 19.9061694 },
    { lat: 48.2308163, lng: 19.9057841 },
    { lat: 48.2305602, lng: 19.905518 },
    { lat: 48.2298488, lng: 19.9052961 },
    { lat: 48.2292455, lng: 19.9047056 },
    { lat: 48.2289218, lng: 19.9045403 },
    { lat: 48.2287104, lng: 19.9032644 },
    { lat: 48.2290291, lng: 19.9026895 },
    { lat: 48.2291128, lng: 19.90186 },
    { lat: 48.2290736, lng: 19.901261 },
    { lat: 48.2283199, lng: 19.9012079 },
    { lat: 48.2278526, lng: 19.9010089 },
    { lat: 48.2266631, lng: 19.900276 },
    { lat: 48.2264745, lng: 19.899954 },
    { lat: 48.2259455, lng: 19.8997295 },
    { lat: 48.2255448, lng: 19.8999741 },
    { lat: 48.2245247, lng: 19.9000397 },
    { lat: 48.2242359, lng: 19.9006304 },
    { lat: 48.2238446, lng: 19.9011479 },
    { lat: 48.2228957, lng: 19.9008275 },
    { lat: 48.2227125, lng: 19.9006498 },
    { lat: 48.2224623, lng: 19.9000487 },
    { lat: 48.2217931, lng: 19.8992625 },
    { lat: 48.2215303, lng: 19.8987078 },
    { lat: 48.2213222, lng: 19.8985845 },
    { lat: 48.2213084, lng: 19.8982919 },
    { lat: 48.2212003, lng: 19.8982094 },
    { lat: 48.2213527, lng: 19.8979343 },
    { lat: 48.2213431, lng: 19.897865 },
    { lat: 48.2212642, lng: 19.897738 },
    { lat: 48.2211633, lng: 19.8977177 },
    { lat: 48.2208197, lng: 19.8958386 },
    { lat: 48.2205395, lng: 19.8946458 },
    { lat: 48.2203449, lng: 19.8943661 },
    { lat: 48.2201033, lng: 19.8943022 },
    { lat: 48.2199972, lng: 19.8940104 },
    { lat: 48.2198697, lng: 19.8940037 },
    { lat: 48.2197948, lng: 19.8939162 },
    { lat: 48.2197532, lng: 19.8936786 },
    { lat: 48.2193058, lng: 19.8932562 },
    { lat: 48.2190701, lng: 19.8933041 },
    { lat: 48.2190371, lng: 19.8931964 },
    { lat: 48.2190734, lng: 19.8929092 },
    { lat: 48.2189726, lng: 19.8927688 },
    { lat: 48.2189748, lng: 19.8925845 },
    { lat: 48.2187716, lng: 19.8922343 },
    { lat: 48.2182658, lng: 19.8920783 },
    { lat: 48.2182675, lng: 19.8918835 },
    { lat: 48.2181638, lng: 19.8917021 },
    { lat: 48.2180383, lng: 19.8916386 },
    { lat: 48.2178333, lng: 19.8914062 },
    { lat: 48.2176313, lng: 19.8912922 },
    { lat: 48.2176252, lng: 19.8913599 },
    { lat: 48.2175042, lng: 19.8912974 },
    { lat: 48.2174081, lng: 19.8911043 },
    { lat: 48.217138, lng: 19.8908517 },
    { lat: 48.216636, lng: 19.8906533 },
    { lat: 48.2163288, lng: 19.8906632 },
    { lat: 48.2162676, lng: 19.8904491 },
    { lat: 48.2161328, lng: 19.8902738 },
    { lat: 48.2152984, lng: 19.8898384 },
    { lat: 48.2142333, lng: 19.8896429 },
    { lat: 48.2134793, lng: 19.8894029 },
    { lat: 48.2122632, lng: 19.8891559 },
    { lat: 48.2113803, lng: 19.8888782 },
    { lat: 48.2082505, lng: 19.8881697 },
    { lat: 48.2077961, lng: 19.8882369 },
    { lat: 48.207059, lng: 19.8876329 },
    { lat: 48.2064859, lng: 19.8868602 },
    { lat: 48.2061276, lng: 19.8878724 },
    { lat: 48.2055811, lng: 19.8893326 },
    { lat: 48.2049339, lng: 19.8900771 },
    { lat: 48.2039072, lng: 19.8892306 },
    { lat: 48.2030862, lng: 19.8891069 },
    { lat: 48.202766, lng: 19.8892845 },
    { lat: 48.2021362, lng: 19.8894386 },
    { lat: 48.2009981, lng: 19.8895387 },
    { lat: 48.2006426, lng: 19.8896532 },
    { lat: 48.200191, lng: 19.8896322 },
    { lat: 48.1988266, lng: 19.8901844 },
    { lat: 48.198126, lng: 19.890468 },
  ],
  Lupoč: [
    { lat: 48.3549309, lng: 19.563247 },
    { lat: 48.3551665, lng: 19.562171 },
    { lat: 48.357832, lng: 19.5635001 },
    { lat: 48.3578999, lng: 19.5635338 },
    { lat: 48.363401, lng: 19.5533993 },
    { lat: 48.3634728, lng: 19.5532713 },
    { lat: 48.3634962, lng: 19.5533635 },
    { lat: 48.3678889, lng: 19.5452626 },
    { lat: 48.3710333, lng: 19.539795 },
    { lat: 48.3734428, lng: 19.5363267 },
    { lat: 48.3736038, lng: 19.5361058 },
    { lat: 48.3742593, lng: 19.5355127 },
    { lat: 48.3748959, lng: 19.5347025 },
    { lat: 48.3757681, lng: 19.5331404 },
    { lat: 48.3767053, lng: 19.5318906 },
    { lat: 48.3770147, lng: 19.5316833 },
    { lat: 48.3776271, lng: 19.5316695 },
    { lat: 48.3778548, lng: 19.5315994 },
    { lat: 48.3780862, lng: 19.5316543 },
    { lat: 48.3785816, lng: 19.5313266 },
    { lat: 48.3798354, lng: 19.5307296 },
    { lat: 48.3808828, lng: 19.5300116 },
    { lat: 48.3828048, lng: 19.5282697 },
    { lat: 48.3838044, lng: 19.5268532 },
    { lat: 48.3852715, lng: 19.5256577 },
    { lat: 48.3856744, lng: 19.5250101 },
    { lat: 48.386206, lng: 19.5237848 },
    { lat: 48.386779, lng: 19.5230149 },
    { lat: 48.3865493, lng: 19.5229002 },
    { lat: 48.3864889, lng: 19.5223967 },
    { lat: 48.3858041, lng: 19.5145203 },
    { lat: 48.3860223, lng: 19.5145109 },
    { lat: 48.3865125, lng: 19.5140719 },
    { lat: 48.3868223, lng: 19.5140704 },
    { lat: 48.3872238, lng: 19.5133495 },
    { lat: 48.3877587, lng: 19.5132422 },
    { lat: 48.3880673, lng: 19.5130419 },
    { lat: 48.3884575, lng: 19.5129529 },
    { lat: 48.3895695, lng: 19.5119486 },
    { lat: 48.3896601, lng: 19.5114002 },
    { lat: 48.389771, lng: 19.5111656 },
    { lat: 48.3899724, lng: 19.5110619 },
    { lat: 48.3903187, lng: 19.5109984 },
    { lat: 48.3909789, lng: 19.5098249 },
    { lat: 48.3912359, lng: 19.5096779 },
    { lat: 48.39138, lng: 19.5094089 },
    { lat: 48.3914621, lng: 19.5090674 },
    { lat: 48.3917697, lng: 19.5087232 },
    { lat: 48.3909407, lng: 19.5078816 },
    { lat: 48.3896718, lng: 19.5066681 },
    { lat: 48.3879368, lng: 19.5075533 },
    { lat: 48.3827804, lng: 19.5101639 },
    { lat: 48.3819393, lng: 19.5099884 },
    { lat: 48.380462, lng: 19.5108397 },
    { lat: 48.380284, lng: 19.5104035 },
    { lat: 48.3793399, lng: 19.51046 },
    { lat: 48.3786883, lng: 19.5105745 },
    { lat: 48.3781075, lng: 19.5110524 },
    { lat: 48.3772632, lng: 19.5115345 },
    { lat: 48.3765091, lng: 19.5132094 },
    { lat: 48.3749383, lng: 19.5134032 },
    { lat: 48.3741006, lng: 19.5137838 },
    { lat: 48.3735171, lng: 19.5141319 },
    { lat: 48.3600085, lng: 19.5138181 },
    { lat: 48.3593071, lng: 19.5147286 },
    { lat: 48.359051, lng: 19.5149463 },
    { lat: 48.3586442, lng: 19.5155731 },
    { lat: 48.3584281, lng: 19.5160935 },
    { lat: 48.3579587, lng: 19.5180509 },
    { lat: 48.3578212, lng: 19.5184629 },
    { lat: 48.357561, lng: 19.5189988 },
    { lat: 48.3559668, lng: 19.5208825 },
    { lat: 48.3554758, lng: 19.5211618 },
    { lat: 48.3545091, lng: 19.5211637 },
    { lat: 48.3537576, lng: 19.5214111 },
    { lat: 48.3531631, lng: 19.5216906 },
    { lat: 48.3526792, lng: 19.5218005 },
    { lat: 48.3526184, lng: 19.5217059 },
    { lat: 48.3525683, lng: 19.5217667 },
    { lat: 48.3524283, lng: 19.5217374 },
    { lat: 48.3523639, lng: 19.5219542 },
    { lat: 48.3522819, lng: 19.5220535 },
    { lat: 48.3520478, lng: 19.5219903 },
    { lat: 48.3516425, lng: 19.5224329 },
    { lat: 48.3515627, lng: 19.5224391 },
    { lat: 48.3514556, lng: 19.5222933 },
    { lat: 48.3513892, lng: 19.5223431 },
    { lat: 48.3513362, lng: 19.5224167 },
    { lat: 48.3513725, lng: 19.5226906 },
    { lat: 48.3512711, lng: 19.5228121 },
    { lat: 48.3511627, lng: 19.5227819 },
    { lat: 48.351057, lng: 19.5230789 },
    { lat: 48.3508823, lng: 19.5231254 },
    { lat: 48.3507706, lng: 19.5234885 },
    { lat: 48.3505729, lng: 19.5235803 },
    { lat: 48.3503454, lng: 19.5241174 },
    { lat: 48.3501636, lng: 19.5241802 },
    { lat: 48.3498805, lng: 19.5249307 },
    { lat: 48.3498672, lng: 19.5252052 },
    { lat: 48.3496617, lng: 19.5253428 },
    { lat: 48.3495178, lng: 19.5255895 },
    { lat: 48.3495307, lng: 19.5256994 },
    { lat: 48.3496456, lng: 19.5258724 },
    { lat: 48.3495351, lng: 19.5260865 },
    { lat: 48.3496072, lng: 19.526281 },
    { lat: 48.3495397, lng: 19.5264045 },
    { lat: 48.3493728, lng: 19.5270844 },
    { lat: 48.3493618, lng: 19.5272875 },
    { lat: 48.3492354, lng: 19.5273828 },
    { lat: 48.3492629, lng: 19.5278543 },
    { lat: 48.3491852, lng: 19.5280994 },
    { lat: 48.3492875, lng: 19.528289 },
    { lat: 48.3491844, lng: 19.5286192 },
    { lat: 48.3492571, lng: 19.5289433 },
    { lat: 48.3492968, lng: 19.529782 },
    { lat: 48.3493708, lng: 19.530311 },
    { lat: 48.349196, lng: 19.5305237 },
    { lat: 48.3493088, lng: 19.530773 },
    { lat: 48.3492954, lng: 19.5320402 },
    { lat: 48.3493265, lng: 19.5324217 },
    { lat: 48.3491674, lng: 19.5325885 },
    { lat: 48.3490683, lng: 19.5329053 },
    { lat: 48.3490747, lng: 19.5331937 },
    { lat: 48.3491903, lng: 19.5334261 },
    { lat: 48.3490045, lng: 19.5336721 },
    { lat: 48.3488769, lng: 19.5339812 },
    { lat: 48.3489043, lng: 19.5344322 },
    { lat: 48.3488583, lng: 19.5345103 },
    { lat: 48.3487319, lng: 19.5345553 },
    { lat: 48.3487914, lng: 19.5348411 },
    { lat: 48.3485631, lng: 19.5350455 },
    { lat: 48.3484872, lng: 19.5355242 },
    { lat: 48.3482248, lng: 19.5356981 },
    { lat: 48.3480915, lng: 19.5359146 },
    { lat: 48.3479778, lng: 19.5362796 },
    { lat: 48.3477393, lng: 19.5365117 },
    { lat: 48.347672, lng: 19.5368339 },
    { lat: 48.347713, lng: 19.5370164 },
    { lat: 48.3476736, lng: 19.5371848 },
    { lat: 48.347822, lng: 19.5373962 },
    { lat: 48.3477202, lng: 19.5375856 },
    { lat: 48.3476988, lng: 19.5377313 },
    { lat: 48.3475643, lng: 19.5378445 },
    { lat: 48.3484313, lng: 19.5383177 },
    { lat: 48.3489452, lng: 19.5390249 },
    { lat: 48.3493592, lng: 19.5393287 },
    { lat: 48.3493289, lng: 19.5413849 },
    { lat: 48.3491958, lng: 19.5414065 },
    { lat: 48.349288, lng: 19.5421313 },
    { lat: 48.3495031, lng: 19.543011 },
    { lat: 48.3495779, lng: 19.5436278 },
    { lat: 48.3498497, lng: 19.5437834 },
    { lat: 48.3499661, lng: 19.5441302 },
    { lat: 48.3501828, lng: 19.5442562 },
    { lat: 48.3504531, lng: 19.5448184 },
    { lat: 48.3504476, lng: 19.5459941 },
    { lat: 48.3505096, lng: 19.5460907 },
    { lat: 48.3508028, lng: 19.5461195 },
    { lat: 48.3509974, lng: 19.5464061 },
    { lat: 48.351074, lng: 19.5463819 },
    { lat: 48.3511853, lng: 19.5465946 },
    { lat: 48.3510076, lng: 19.5468227 },
    { lat: 48.3511404, lng: 19.5468947 },
    { lat: 48.3511096, lng: 19.5471282 },
    { lat: 48.3507986, lng: 19.547401 },
    { lat: 48.3507841, lng: 19.547767 },
    { lat: 48.3507283, lng: 19.5478323 },
    { lat: 48.3503355, lng: 19.5479124 },
    { lat: 48.3502995, lng: 19.5479873 },
    { lat: 48.3503228, lng: 19.5481295 },
    { lat: 48.3503912, lng: 19.5482205 },
    { lat: 48.3506693, lng: 19.5484599 },
    { lat: 48.3514281, lng: 19.549347 },
    { lat: 48.351375, lng: 19.549564 },
    { lat: 48.3509182, lng: 19.5506068 },
    { lat: 48.3518283, lng: 19.5515038 },
    { lat: 48.3514605, lng: 19.5517375 },
    { lat: 48.3528066, lng: 19.5533414 },
    { lat: 48.3524927, lng: 19.5540361 },
    { lat: 48.352015, lng: 19.5548487 },
    { lat: 48.351571, lng: 19.5560993 },
    { lat: 48.3511638, lng: 19.5569963 },
    { lat: 48.3513931, lng: 19.5574483 },
    { lat: 48.3519325, lng: 19.5582754 },
    { lat: 48.3519153, lng: 19.5585128 },
    { lat: 48.3517978, lng: 19.5589717 },
    { lat: 48.3518354, lng: 19.5593224 },
    { lat: 48.3521684, lng: 19.5596766 },
    { lat: 48.3527786, lng: 19.5609413 },
    { lat: 48.3530512, lng: 19.5613344 },
    { lat: 48.3529899, lng: 19.5614963 },
    { lat: 48.3537722, lng: 19.5623323 },
    { lat: 48.3549309, lng: 19.563247 },
  ],
  Ábelová: [
    { lat: 48.397368, lng: 19.4537375 },
    { lat: 48.3969519, lng: 19.4544794 },
    { lat: 48.3972102, lng: 19.4549425 },
    { lat: 48.3972741, lng: 19.4567153 },
    { lat: 48.3973303, lng: 19.45722 },
    { lat: 48.3981984, lng: 19.4577526 },
    { lat: 48.3976857, lng: 19.4583078 },
    { lat: 48.3977389, lng: 19.4585054 },
    { lat: 48.3973697, lng: 19.4592164 },
    { lat: 48.3977827, lng: 19.4603615 },
    { lat: 48.3984459, lng: 19.4611902 },
    { lat: 48.3986337, lng: 19.4618768 },
    { lat: 48.3987148, lng: 19.461975 },
    { lat: 48.4000388, lng: 19.462235 },
    { lat: 48.4008436, lng: 19.4644046 },
    { lat: 48.400855, lng: 19.4644307 },
    { lat: 48.4009713, lng: 19.4641266 },
    { lat: 48.4011921, lng: 19.4639295 },
    { lat: 48.4013558, lng: 19.4634742 },
    { lat: 48.4021283, lng: 19.4623079 },
    { lat: 48.4029378, lng: 19.4613996 },
    { lat: 48.404705, lng: 19.4597845 },
    { lat: 48.4049168, lng: 19.4593496 },
    { lat: 48.40522, lng: 19.4581216 },
    { lat: 48.4055292, lng: 19.4576502 },
    { lat: 48.4059704, lng: 19.4574689 },
    { lat: 48.4061202, lng: 19.4573243 },
    { lat: 48.4064668, lng: 19.4573381 },
    { lat: 48.4071113, lng: 19.4584182 },
    { lat: 48.4073119, lng: 19.4594281 },
    { lat: 48.4073616, lng: 19.4602493 },
    { lat: 48.4070264, lng: 19.4614428 },
    { lat: 48.4070067, lng: 19.4617949 },
    { lat: 48.4071692, lng: 19.461992 },
    { lat: 48.4071585, lng: 19.4629448 },
    { lat: 48.4071142, lng: 19.4631985 },
    { lat: 48.4071947, lng: 19.4637387 },
    { lat: 48.4072837, lng: 19.4638595 },
    { lat: 48.407438, lng: 19.4639322 },
    { lat: 48.4076944, lng: 19.4643996 },
    { lat: 48.4080026, lng: 19.4644867 },
    { lat: 48.4081071, lng: 19.4646718 },
    { lat: 48.4085844, lng: 19.4650695 },
    { lat: 48.4088636, lng: 19.4654259 },
    { lat: 48.4092286, lng: 19.4656141 },
    { lat: 48.4094272, lng: 19.4658869 },
    { lat: 48.4099204, lng: 19.4661431 },
    { lat: 48.4110812, lng: 19.4659278 },
    { lat: 48.4119337, lng: 19.4664675 },
    { lat: 48.4121495, lng: 19.4664051 },
    { lat: 48.4125419, lng: 19.4665538 },
    { lat: 48.4129929, lng: 19.4666091 },
    { lat: 48.4136938, lng: 19.4670631 },
    { lat: 48.4140158, lng: 19.4669837 },
    { lat: 48.4142931, lng: 19.4673954 },
    { lat: 48.415058, lng: 19.4674358 },
    { lat: 48.4165414, lng: 19.4671545 },
    { lat: 48.417819, lng: 19.4675789 },
    { lat: 48.4189246, lng: 19.4672428 },
    { lat: 48.4191753, lng: 19.4659249 },
    { lat: 48.4197675, lng: 19.463407 },
    { lat: 48.4217608, lng: 19.4605314 },
    { lat: 48.4234367, lng: 19.4625078 },
    { lat: 48.4243412, lng: 19.4638462 },
    { lat: 48.424357, lng: 19.4638719 },
    { lat: 48.4246029, lng: 19.4637058 },
    { lat: 48.4250561, lng: 19.4637356 },
    { lat: 48.4260016, lng: 19.4633953 },
    { lat: 48.4263252, lng: 19.4630645 },
    { lat: 48.4266643, lng: 19.4624312 },
    { lat: 48.4268565, lng: 19.4618655 },
    { lat: 48.4273863, lng: 19.4606901 },
    { lat: 48.4279657, lng: 19.4595566 },
    { lat: 48.4287579, lng: 19.4583264 },
    { lat: 48.4288721, lng: 19.4579013 },
    { lat: 48.4294027, lng: 19.4572941 },
    { lat: 48.4296106, lng: 19.4571136 },
    { lat: 48.4306036, lng: 19.4565881 },
    { lat: 48.4323104, lng: 19.4553683 },
    { lat: 48.4326035, lng: 19.4547685 },
    { lat: 48.433069, lng: 19.4540478 },
    { lat: 48.4334221, lng: 19.4535733 },
    { lat: 48.4337235, lng: 19.4533438 },
    { lat: 48.4342558, lng: 19.4526323 },
    { lat: 48.4345886, lng: 19.4524712 },
    { lat: 48.4355894, lng: 19.4522784 },
    { lat: 48.4358938, lng: 19.4523296 },
    { lat: 48.4362072, lng: 19.452476 },
    { lat: 48.4366025, lng: 19.4523242 },
    { lat: 48.4368596, lng: 19.4519879 },
    { lat: 48.4370601, lng: 19.4515543 },
    { lat: 48.4375917, lng: 19.4495027 },
    { lat: 48.4382099, lng: 19.448649 },
    { lat: 48.4399841, lng: 19.447811 },
    { lat: 48.4403055, lng: 19.4473879 },
    { lat: 48.4406268, lng: 19.4468354 },
    { lat: 48.4410958, lng: 19.4462618 },
    { lat: 48.4419866, lng: 19.4455957 },
    { lat: 48.4425896, lng: 19.4450228 },
    { lat: 48.4430561, lng: 19.4442983 },
    { lat: 48.4440215, lng: 19.4430154 },
    { lat: 48.4443679, lng: 19.4422521 },
    { lat: 48.4446819, lng: 19.4412835 },
    { lat: 48.4454203, lng: 19.4392944 },
    { lat: 48.4455631, lng: 19.4386197 },
    { lat: 48.4458058, lng: 19.4369682 },
    { lat: 48.4461965, lng: 19.4360244 },
    { lat: 48.4468799, lng: 19.4347861 },
    { lat: 48.4474694, lng: 19.4344033 },
    { lat: 48.4503676, lng: 19.4331712 },
    { lat: 48.4519998, lng: 19.4319258 },
    { lat: 48.4529727, lng: 19.431477 },
    { lat: 48.4543691, lng: 19.4309967 },
    { lat: 48.4548367, lng: 19.4309016 },
    { lat: 48.4552486, lng: 19.4307215 },
    { lat: 48.455691, lng: 19.4306113 },
    { lat: 48.456428, lng: 19.4305401 },
    { lat: 48.4569773, lng: 19.4303337 },
    { lat: 48.4573253, lng: 19.4302818 },
    { lat: 48.4583714, lng: 19.4304047 },
    { lat: 48.4603255, lng: 19.4308319 },
    { lat: 48.4601784, lng: 19.4277992 },
    { lat: 48.4613354, lng: 19.4256089 },
    { lat: 48.461767, lng: 19.4253468 },
    { lat: 48.4622346, lng: 19.4253659 },
    { lat: 48.4627856, lng: 19.4244785 },
    { lat: 48.4632131, lng: 19.4232143 },
    { lat: 48.464167, lng: 19.4225315 },
    { lat: 48.4641704, lng: 19.4220507 },
    { lat: 48.4642705, lng: 19.421518 },
    { lat: 48.4645329, lng: 19.4207259 },
    { lat: 48.4647606, lng: 19.4194833 },
    { lat: 48.4649455, lng: 19.4193627 },
    { lat: 48.4651532, lng: 19.4189894 },
    { lat: 48.4655511, lng: 19.4186317 },
    { lat: 48.4673278, lng: 19.4175884 },
    { lat: 48.4679793, lng: 19.4172757 },
    { lat: 48.4682761, lng: 19.4172391 },
    { lat: 48.4699505, lng: 19.4164868 },
    { lat: 48.4706293, lng: 19.4157067 },
    { lat: 48.4720409, lng: 19.4151453 },
    { lat: 48.472796, lng: 19.4149302 },
    { lat: 48.4743767, lng: 19.4142243 },
    { lat: 48.4744631, lng: 19.4144275 },
    { lat: 48.4759073, lng: 19.4142238 },
    { lat: 48.4773484, lng: 19.4135722 },
    { lat: 48.47854, lng: 19.4125514 },
    { lat: 48.4789727, lng: 19.4112549 },
    { lat: 48.4792408, lng: 19.4108711 },
    { lat: 48.4796989, lng: 19.4104994 },
    { lat: 48.4809045, lng: 19.4118542 },
    { lat: 48.4811552, lng: 19.4115063 },
    { lat: 48.481396, lng: 19.4108778 },
    { lat: 48.4815435, lng: 19.4102997 },
    { lat: 48.4817047, lng: 19.4092768 },
    { lat: 48.4817789, lng: 19.4090064 },
    { lat: 48.482043, lng: 19.408423 },
    { lat: 48.482076, lng: 19.408352 },
    { lat: 48.482517, lng: 19.407651 },
    { lat: 48.482604, lng: 19.407426 },
    { lat: 48.482741, lng: 19.406798 },
    { lat: 48.482896, lng: 19.406376 },
    { lat: 48.483012, lng: 19.405847 },
    { lat: 48.483207, lng: 19.40485 },
    { lat: 48.483297, lng: 19.404389 },
    { lat: 48.483372, lng: 19.404124 },
    { lat: 48.483491, lng: 19.403668 },
    { lat: 48.483493, lng: 19.403578 },
    { lat: 48.483477, lng: 19.403375 },
    { lat: 48.483377, lng: 19.402803 },
    { lat: 48.483341, lng: 19.402525 },
    { lat: 48.483336, lng: 19.402403 },
    { lat: 48.483409, lng: 19.402256 },
    { lat: 48.483687, lng: 19.401657 },
    { lat: 48.483798, lng: 19.400924 },
    { lat: 48.483811, lng: 19.400563 },
    { lat: 48.483811, lng: 19.400107 },
    { lat: 48.483821, lng: 19.399784 },
    { lat: 48.483834, lng: 19.39955 },
    { lat: 48.48395, lng: 19.399001 },
    { lat: 48.484174, lng: 19.398305 },
    { lat: 48.484312, lng: 19.397889 },
    { lat: 48.484342, lng: 19.397728 },
    { lat: 48.484378, lng: 19.397414 },
    { lat: 48.484544, lng: 19.396633 },
    { lat: 48.484708, lng: 19.395973 },
    { lat: 48.484806, lng: 19.395771 },
    { lat: 48.485018, lng: 19.395398 },
    { lat: 48.485112, lng: 19.395187 },
    { lat: 48.485197, lng: 19.395019 },
    { lat: 48.485288, lng: 19.394843 },
    { lat: 48.485321, lng: 19.394854 },
    { lat: 48.485335, lng: 19.394866 },
    { lat: 48.485887, lng: 19.393783 },
    { lat: 48.486078, lng: 19.39342 },
    { lat: 48.486098, lng: 19.393228 },
    { lat: 48.486247, lng: 19.392004 },
    { lat: 48.486302, lng: 19.391522 },
    { lat: 48.48638, lng: 19.390912 },
    { lat: 48.486477, lng: 19.3902 },
    { lat: 48.486654, lng: 19.388857 },
    { lat: 48.486871, lng: 19.387264 },
    { lat: 48.486887, lng: 19.387156 },
    { lat: 48.486963, lng: 19.386548 },
    { lat: 48.48697, lng: 19.386498 },
    { lat: 48.486988, lng: 19.386358 },
    { lat: 48.486994, lng: 19.386313 },
    { lat: 48.487066, lng: 19.385716 },
    { lat: 48.487207, lng: 19.384652 },
    { lat: 48.487356, lng: 19.383442 },
    { lat: 48.4876071, lng: 19.3815566 },
    { lat: 48.487629, lng: 19.381392 },
    { lat: 48.487894, lng: 19.379101 },
    { lat: 48.488179, lng: 19.376566 },
    { lat: 48.488369, lng: 19.374844 },
    { lat: 48.488461, lng: 19.373994 },
    { lat: 48.488824, lng: 19.370994 },
    { lat: 48.488723, lng: 19.369983 },
    { lat: 48.488569, lng: 19.368317 },
    { lat: 48.488575, lng: 19.367934 },
    { lat: 48.488511, lng: 19.367567 },
    { lat: 48.488441, lng: 19.366989 },
    { lat: 48.488425, lng: 19.36667 },
    { lat: 48.488313, lng: 19.364888 },
    { lat: 48.48815, lng: 19.363555 },
    { lat: 48.487935, lng: 19.363775 },
    { lat: 48.487458, lng: 19.364368 },
    { lat: 48.487073, lng: 19.364696 },
    { lat: 48.486371, lng: 19.365782 },
    { lat: 48.485667, lng: 19.366492 },
    { lat: 48.484983, lng: 19.36702 },
    { lat: 48.484462, lng: 19.368002 },
    { lat: 48.484266, lng: 19.368526 },
    { lat: 48.484258, lng: 19.368824 },
    { lat: 48.484304, lng: 19.36901 },
    { lat: 48.48431, lng: 19.369198 },
    { lat: 48.484035, lng: 19.370621 },
    { lat: 48.483993, lng: 19.370624 },
    { lat: 48.483872, lng: 19.370635 },
    { lat: 48.483817, lng: 19.370639 },
    { lat: 48.483728, lng: 19.370644 },
    { lat: 48.483685, lng: 19.370649 },
    { lat: 48.483536, lng: 19.370665 },
    { lat: 48.483093, lng: 19.370866 },
    { lat: 48.482785, lng: 19.371001 },
    { lat: 48.482491, lng: 19.371306 },
    { lat: 48.481724, lng: 19.371309 },
    { lat: 48.481708, lng: 19.371484 },
    { lat: 48.481699, lng: 19.371602 },
    { lat: 48.481541, lng: 19.373334 },
    { lat: 48.481361, lng: 19.375254 },
    { lat: 48.48128, lng: 19.376066 },
    { lat: 48.481244, lng: 19.376437 },
    { lat: 48.481213, lng: 19.376745 },
    { lat: 48.481189, lng: 19.37696 },
    { lat: 48.481109, lng: 19.377848 },
    { lat: 48.48106, lng: 19.37799 },
    { lat: 48.480495, lng: 19.379638 },
    { lat: 48.480053, lng: 19.380929 },
    { lat: 48.479675, lng: 19.382007 },
    { lat: 48.479384, lng: 19.382859 },
    { lat: 48.479332, lng: 19.383019 },
    { lat: 48.479041, lng: 19.383854 },
    { lat: 48.478882, lng: 19.384309 },
    { lat: 48.478743, lng: 19.384677 },
    { lat: 48.478457, lng: 19.385424 },
    { lat: 48.478389, lng: 19.385611 },
    { lat: 48.478363, lng: 19.385687 },
    { lat: 48.477927, lng: 19.386824 },
    { lat: 48.477511, lng: 19.387909 },
    { lat: 48.477426, lng: 19.388139 },
    { lat: 48.476916, lng: 19.38951 },
    { lat: 48.476753, lng: 19.389923 },
    { lat: 48.476723, lng: 19.390012 },
    { lat: 48.47659, lng: 19.390357 },
    { lat: 48.476553, lng: 19.390454 },
    { lat: 48.476315, lng: 19.39109 },
    { lat: 48.47623, lng: 19.391304 },
    { lat: 48.47605, lng: 19.391773 },
    { lat: 48.475922, lng: 19.39211 },
    { lat: 48.475752, lng: 19.392597 },
    { lat: 48.4755, lng: 19.393346 },
    { lat: 48.475259, lng: 19.394031 },
    { lat: 48.475009, lng: 19.394761 },
    { lat: 48.474979, lng: 19.394805 },
    { lat: 48.474365, lng: 19.393792 },
    { lat: 48.468622, lng: 19.384324 },
    { lat: 48.467653, lng: 19.382723 },
    { lat: 48.467635, lng: 19.382681 },
    { lat: 48.467499, lng: 19.382657 },
    { lat: 48.466986, lng: 19.383091 },
    { lat: 48.466753, lng: 19.38318 },
    { lat: 48.466347, lng: 19.383201 },
    { lat: 48.466059, lng: 19.383067 },
    { lat: 48.466039, lng: 19.383071 },
    { lat: 48.465838, lng: 19.383208 },
    { lat: 48.46534, lng: 19.383383 },
    { lat: 48.465314, lng: 19.383411 },
    { lat: 48.465205, lng: 19.383555 },
    { lat: 48.465106, lng: 19.383543 },
    { lat: 48.465094, lng: 19.383552 },
    { lat: 48.465025, lng: 19.383692 },
    { lat: 48.464484, lng: 19.383987 },
    { lat: 48.463883, lng: 19.384547 },
    { lat: 48.463856, lng: 19.385113 },
    { lat: 48.463392, lng: 19.385932 },
    { lat: 48.463188, lng: 19.386195 },
    { lat: 48.463111, lng: 19.386679 },
    { lat: 48.462924, lng: 19.386996 },
    { lat: 48.462699, lng: 19.387454 },
    { lat: 48.462593, lng: 19.387672 },
    { lat: 48.462458, lng: 19.388336 },
    { lat: 48.462382, lng: 19.388496 },
    { lat: 48.462219, lng: 19.388954 },
    { lat: 48.462211, lng: 19.389 },
    { lat: 48.462147, lng: 19.389893 },
    { lat: 48.461832, lng: 19.390487 },
    { lat: 48.46182, lng: 19.390503 },
    { lat: 48.46164, lng: 19.390612 },
    { lat: 48.461636, lng: 19.390736 },
    { lat: 48.461623, lng: 19.39077 },
    { lat: 48.460705, lng: 19.392436 },
    { lat: 48.45993, lng: 19.393076 },
    { lat: 48.459399, lng: 19.39394 },
    { lat: 48.459242, lng: 19.394053 },
    { lat: 48.458968, lng: 19.394382 },
    { lat: 48.458651, lng: 19.394593 },
    { lat: 48.45831, lng: 19.395042 },
    { lat: 48.458277, lng: 19.395046 },
    { lat: 48.458101, lng: 19.395011 },
    { lat: 48.458073, lng: 19.395018 },
    { lat: 48.457855, lng: 19.39553 },
    { lat: 48.45785, lng: 19.395566 },
    { lat: 48.457848, lng: 19.395788 },
    { lat: 48.457833, lng: 19.395809 },
    { lat: 48.457816, lng: 19.395819 },
    { lat: 48.457808, lng: 19.395754 },
    { lat: 48.457783, lng: 19.395239 },
    { lat: 48.457777, lng: 19.395169 },
    { lat: 48.4576, lng: 19.393811 },
    { lat: 48.457552, lng: 19.393067 },
    { lat: 48.457398, lng: 19.390275 },
    { lat: 48.457297, lng: 19.388221 },
    { lat: 48.45724, lng: 19.386969 },
    { lat: 48.457239, lng: 19.386564 },
    { lat: 48.457208, lng: 19.386298 },
    { lat: 48.457172, lng: 19.385566 },
    { lat: 48.457083, lng: 19.383737 },
    { lat: 48.457079, lng: 19.383675 },
    { lat: 48.456991, lng: 19.382033 },
    { lat: 48.456971, lng: 19.381316 },
    { lat: 48.456956, lng: 19.380617 },
    { lat: 48.456952, lng: 19.380302 },
    { lat: 48.456939, lng: 19.379976 },
    { lat: 48.456878, lng: 19.379228 },
    { lat: 48.456846, lng: 19.378477 },
    { lat: 48.456806, lng: 19.377632 },
    { lat: 48.45674, lng: 19.376693 },
    { lat: 48.456681, lng: 19.375555 },
    { lat: 48.456629, lng: 19.374301 },
    { lat: 48.456603, lng: 19.37351 },
    { lat: 48.456535, lng: 19.371732 },
    { lat: 48.456501, lng: 19.371383 },
    { lat: 48.456493, lng: 19.37109 },
    { lat: 48.456488, lng: 19.370953 },
    { lat: 48.456484, lng: 19.370645 },
    { lat: 48.456516, lng: 19.369958 },
    { lat: 48.456545, lng: 19.36896 },
    { lat: 48.456523, lng: 19.368606 },
    { lat: 48.456451, lng: 19.367863 },
    { lat: 48.456416, lng: 19.367094 },
    { lat: 48.456239, lng: 19.367179 },
    { lat: 48.456011, lng: 19.367514 },
    { lat: 48.455846, lng: 19.3679 },
    { lat: 48.455659, lng: 19.368156 },
    { lat: 48.455233, lng: 19.368364 },
    { lat: 48.455071, lng: 19.368416 },
    { lat: 48.455076, lng: 19.368992 },
    { lat: 48.455215, lng: 19.369328 },
    { lat: 48.455117, lng: 19.369495 },
    { lat: 48.455002, lng: 19.369592 },
    { lat: 48.454634, lng: 19.370167 },
    { lat: 48.454479, lng: 19.370296 },
    { lat: 48.454308, lng: 19.370561 },
    { lat: 48.454294, lng: 19.370689 },
    { lat: 48.454007, lng: 19.370777 },
    { lat: 48.453971, lng: 19.370836 },
    { lat: 48.453933, lng: 19.370988 },
    { lat: 48.453818, lng: 19.371037 },
    { lat: 48.453549, lng: 19.371449 },
    { lat: 48.45309, lng: 19.371797 },
    { lat: 48.452867, lng: 19.371972 },
    { lat: 48.452732, lng: 19.372102 },
    { lat: 48.452592, lng: 19.371922 },
    { lat: 48.452382, lng: 19.371527 },
    { lat: 48.452138, lng: 19.370843 },
    { lat: 48.451956, lng: 19.370338 },
    { lat: 48.45154, lng: 19.370339 },
    { lat: 48.451338, lng: 19.370334 },
    { lat: 48.451157, lng: 19.36987 },
    { lat: 48.45102, lng: 19.369486 },
    { lat: 48.450948, lng: 19.369235 },
    { lat: 48.450844, lng: 19.368788 },
    { lat: 48.45048, lng: 19.367788 },
    { lat: 48.450425, lng: 19.367533 },
    { lat: 48.450478, lng: 19.36706 },
    { lat: 48.450501, lng: 19.366783 },
    { lat: 48.450563, lng: 19.366277 },
    { lat: 48.450579, lng: 19.366009 },
    { lat: 48.450557, lng: 19.366008 },
    { lat: 48.4502, lng: 19.365915 },
    { lat: 48.449647, lng: 19.365824 },
    { lat: 48.449317, lng: 19.365801 },
    { lat: 48.449154, lng: 19.365435 },
    { lat: 48.449136, lng: 19.365396 },
    { lat: 48.448844, lng: 19.365474 },
    { lat: 48.448607, lng: 19.365604 },
    { lat: 48.448591, lng: 19.365551 },
    { lat: 48.448553, lng: 19.365448 },
    { lat: 48.448301, lng: 19.365741 },
    { lat: 48.447964, lng: 19.366153 },
    { lat: 48.44752, lng: 19.366475 },
    { lat: 48.447446, lng: 19.366524 },
    { lat: 48.447116, lng: 19.366897 },
    { lat: 48.446805, lng: 19.367215 },
    { lat: 48.446408, lng: 19.367585 },
    { lat: 48.446347, lng: 19.367649 },
    { lat: 48.446312, lng: 19.367666 },
    { lat: 48.445921, lng: 19.368243 },
    { lat: 48.445817, lng: 19.368495 },
    { lat: 48.445668, lng: 19.368927 },
    { lat: 48.445331, lng: 19.369751 },
    { lat: 48.445159, lng: 19.370181 },
    { lat: 48.445025, lng: 19.370541 },
    { lat: 48.444912, lng: 19.370803 },
    { lat: 48.444652, lng: 19.371368 },
    { lat: 48.444571, lng: 19.371357 },
    { lat: 48.444329, lng: 19.371339 },
    { lat: 48.444143, lng: 19.371913 },
    { lat: 48.444067, lng: 19.372319 },
    { lat: 48.44382, lng: 19.373023 },
    { lat: 48.44364, lng: 19.37347 },
    { lat: 48.442983, lng: 19.373578 },
    { lat: 48.44285, lng: 19.373858 },
    { lat: 48.442604, lng: 19.374308 },
    { lat: 48.442424, lng: 19.374489 },
    { lat: 48.442222, lng: 19.37465 },
    { lat: 48.441929, lng: 19.374755 },
    { lat: 48.441539, lng: 19.374658 },
    { lat: 48.441335, lng: 19.374775 },
    { lat: 48.440742, lng: 19.375395 },
    { lat: 48.44043, lng: 19.375663 },
    { lat: 48.440125, lng: 19.375821 },
    { lat: 48.439831, lng: 19.37548 },
    { lat: 48.43963, lng: 19.375372 },
    { lat: 48.439514, lng: 19.375669 },
    { lat: 48.438937, lng: 19.376494 },
    { lat: 48.437977, lng: 19.377915 },
    { lat: 48.437814, lng: 19.378196 },
    { lat: 48.437915, lng: 19.378725 },
    { lat: 48.437762, lng: 19.378962 },
    { lat: 48.437484, lng: 19.379249 },
    { lat: 48.437211, lng: 19.379494 },
    { lat: 48.437083, lng: 19.379333 },
    { lat: 48.436952, lng: 19.379235 },
    { lat: 48.436494, lng: 19.37975 },
    { lat: 48.43622, lng: 19.379982 },
    { lat: 48.436062, lng: 19.380059 },
    { lat: 48.435584, lng: 19.380106 },
    { lat: 48.435569, lng: 19.37997 },
    { lat: 48.435157, lng: 19.379859 },
    { lat: 48.434031, lng: 19.380163 },
    { lat: 48.433759, lng: 19.380345 },
    { lat: 48.433332, lng: 19.380464 },
    { lat: 48.433115, lng: 19.380545 },
    { lat: 48.432796, lng: 19.380678 },
    { lat: 48.432799, lng: 19.381458 },
    { lat: 48.432498, lng: 19.38168 },
    { lat: 48.432456, lng: 19.382122 },
    { lat: 48.432052, lng: 19.382287 },
    { lat: 48.431724, lng: 19.382229 },
    { lat: 48.431554, lng: 19.382006 },
    { lat: 48.431518, lng: 19.381725 },
    { lat: 48.431554, lng: 19.381339 },
    { lat: 48.4315, lng: 19.381226 },
    { lat: 48.431371, lng: 19.381502 },
    { lat: 48.431268, lng: 19.381644 },
    { lat: 48.431004, lng: 19.381978 },
    { lat: 48.430906, lng: 19.382408 },
    { lat: 48.430793, lng: 19.382495 },
    { lat: 48.430669, lng: 19.382652 },
    { lat: 48.43062, lng: 19.382964 },
    { lat: 48.430627, lng: 19.38321 },
    { lat: 48.430307, lng: 19.383739 },
    { lat: 48.430123, lng: 19.383998 },
    { lat: 48.430045, lng: 19.384141 },
    { lat: 48.430003, lng: 19.384214 },
    { lat: 48.429951, lng: 19.384318 },
    { lat: 48.429778, lng: 19.384476 },
    { lat: 48.429699, lng: 19.384406 },
    { lat: 48.429467, lng: 19.384531 },
    { lat: 48.429211, lng: 19.384312 },
    { lat: 48.428583, lng: 19.384393 },
    { lat: 48.428054, lng: 19.384662 },
    { lat: 48.427733, lng: 19.385075 },
    { lat: 48.427723, lng: 19.385003 },
    { lat: 48.427468, lng: 19.385229 },
    { lat: 48.427096, lng: 19.385724 },
    { lat: 48.426882, lng: 19.386197 },
    { lat: 48.426743, lng: 19.386445 },
    { lat: 48.42663, lng: 19.386554 },
    { lat: 48.42654, lng: 19.386648 },
    { lat: 48.426272, lng: 19.386696 },
    { lat: 48.425723, lng: 19.38678 },
    { lat: 48.425313, lng: 19.386839 },
    { lat: 48.42445, lng: 19.386884 },
    { lat: 48.424153, lng: 19.386963 },
    { lat: 48.424163, lng: 19.387025 },
    { lat: 48.423881, lng: 19.387171 },
    { lat: 48.423691, lng: 19.387308 },
    { lat: 48.423366, lng: 19.387576 },
    { lat: 48.422957, lng: 19.387933 },
    { lat: 48.42258, lng: 19.38836 },
    { lat: 48.422392, lng: 19.388537 },
    { lat: 48.422215, lng: 19.388757 },
    { lat: 48.421963, lng: 19.388985 },
    { lat: 48.421574, lng: 19.389344 },
    { lat: 48.421372, lng: 19.389617 },
    { lat: 48.420979, lng: 19.389961 },
    { lat: 48.420457, lng: 19.390438 },
    { lat: 48.420056, lng: 19.390419 },
    { lat: 48.419905, lng: 19.390468 },
    { lat: 48.419713, lng: 19.390595 },
    { lat: 48.419459, lng: 19.390754 },
    { lat: 48.419015, lng: 19.390904 },
    { lat: 48.418107, lng: 19.390966 },
    { lat: 48.417908, lng: 19.390985 },
    { lat: 48.417808, lng: 19.390833 },
    { lat: 48.417719, lng: 19.390782 },
    { lat: 48.417661, lng: 19.390788 },
    { lat: 48.417256, lng: 19.390837 },
    { lat: 48.416661, lng: 19.390908 },
    { lat: 48.416439, lng: 19.390826 },
    { lat: 48.415435, lng: 19.390134 },
    { lat: 48.415228, lng: 19.389973 },
    { lat: 48.414783, lng: 19.389626 },
    { lat: 48.414879, lng: 19.389317 },
    { lat: 48.414848, lng: 19.389284 },
    { lat: 48.414744, lng: 19.389192 },
    { lat: 48.414518, lng: 19.389172 },
    { lat: 48.414032, lng: 19.388951 },
    { lat: 48.412758, lng: 19.3888 },
    { lat: 48.412115, lng: 19.38871 },
    { lat: 48.411989, lng: 19.388761 },
    { lat: 48.411744, lng: 19.38886 },
    { lat: 48.411512, lng: 19.388933 },
    { lat: 48.410875, lng: 19.389137 },
    { lat: 48.410546, lng: 19.389036 },
    { lat: 48.410386, lng: 19.388988 },
    { lat: 48.410062, lng: 19.388986 },
    { lat: 48.409269, lng: 19.388691 },
    { lat: 48.408768, lng: 19.388487 },
    { lat: 48.407699, lng: 19.388764 },
    { lat: 48.407214, lng: 19.389041 },
    { lat: 48.406956, lng: 19.389203 },
    { lat: 48.406595, lng: 19.389459 },
    { lat: 48.406103, lng: 19.389699 },
    { lat: 48.405658, lng: 19.390285 },
    { lat: 48.405425, lng: 19.390461 },
    { lat: 48.405073, lng: 19.390527 },
    { lat: 48.404946, lng: 19.39036 },
    { lat: 48.404826, lng: 19.390195 },
    { lat: 48.404685, lng: 19.390169 },
    { lat: 48.404584, lng: 19.390155 },
    { lat: 48.404567, lng: 19.390166 },
    { lat: 48.40428, lng: 19.390243 },
    { lat: 48.403462, lng: 19.390613 },
    { lat: 48.402986, lng: 19.39072 },
    { lat: 48.402559, lng: 19.390814 },
    { lat: 48.402473, lng: 19.3915 },
    { lat: 48.402416, lng: 19.391783 },
    { lat: 48.402369, lng: 19.392039 },
    { lat: 48.402364, lng: 19.392648 },
    { lat: 48.402545, lng: 19.39273 },
    { lat: 48.402547, lng: 19.392778 },
    { lat: 48.402249, lng: 19.393114 },
    { lat: 48.401679, lng: 19.393756 },
    { lat: 48.401414, lng: 19.393855 },
    { lat: 48.400945, lng: 19.393919 },
    { lat: 48.400503, lng: 19.393985 },
    { lat: 48.400378, lng: 19.394005 },
    { lat: 48.40011, lng: 19.394118 },
    { lat: 48.399797, lng: 19.394375 },
    { lat: 48.399662, lng: 19.394601 },
    { lat: 48.399473, lng: 19.394702 },
    { lat: 48.39919, lng: 19.394079 },
    { lat: 48.398921, lng: 19.394185 },
    { lat: 48.398678, lng: 19.394236 },
    { lat: 48.398324, lng: 19.394336 },
    { lat: 48.398071, lng: 19.394366 },
    { lat: 48.397596, lng: 19.39447 },
    { lat: 48.397335, lng: 19.394215 },
    { lat: 48.39655, lng: 19.394799 },
    { lat: 48.396408, lng: 19.394853 },
    { lat: 48.39604, lng: 19.394883 },
    { lat: 48.395685, lng: 19.394815 },
    { lat: 48.395641, lng: 19.394803 },
    { lat: 48.395472, lng: 19.394736 },
    { lat: 48.395223, lng: 19.394732 },
    { lat: 48.395079, lng: 19.394715 },
    { lat: 48.394116, lng: 19.396441 },
    { lat: 48.393777, lng: 19.395747 },
    { lat: 48.393714, lng: 19.395999 },
    { lat: 48.393307, lng: 19.396333 },
    { lat: 48.392828, lng: 19.3968 },
    { lat: 48.392579, lng: 19.397023 },
    { lat: 48.392264, lng: 19.3975 },
    { lat: 48.392173, lng: 19.397586 },
    { lat: 48.391945, lng: 19.396902 },
    { lat: 48.391851, lng: 19.396675 },
    { lat: 48.391733, lng: 19.396429 },
    { lat: 48.391537, lng: 19.396251 },
    { lat: 48.391446, lng: 19.396443 },
    { lat: 48.391442, lng: 19.396451 },
    { lat: 48.391353, lng: 19.396692 },
    { lat: 48.391208, lng: 19.397093 },
    { lat: 48.391221, lng: 19.397268 },
    { lat: 48.391219, lng: 19.397472 },
    { lat: 48.391146, lng: 19.397679 },
    { lat: 48.391123, lng: 19.397877 },
    { lat: 48.390919, lng: 19.398298 },
    { lat: 48.390839, lng: 19.39837 },
    { lat: 48.390548, lng: 19.398148 },
    { lat: 48.390622, lng: 19.396667 },
    { lat: 48.390726, lng: 19.396129 },
    { lat: 48.390758, lng: 19.396036 },
    { lat: 48.390772, lng: 19.39586 },
    { lat: 48.390409, lng: 19.396406 },
    { lat: 48.39033, lng: 19.396657 },
    { lat: 48.390266, lng: 19.396905 },
    { lat: 48.390212, lng: 19.397041 },
    { lat: 48.390205, lng: 19.397467 },
    { lat: 48.390154, lng: 19.398152 },
    { lat: 48.39, lng: 19.398267 },
    { lat: 48.39, lng: 19.398421 },
    { lat: 48.389973, lng: 19.398621 },
    { lat: 48.389898, lng: 19.399016 },
    { lat: 48.389858, lng: 19.399143 },
    { lat: 48.389805, lng: 19.399265 },
    { lat: 48.389712, lng: 19.399106 },
    { lat: 48.389622, lng: 19.399192 },
    { lat: 48.389525, lng: 19.399372 },
    { lat: 48.389401, lng: 19.399466 },
    { lat: 48.389272, lng: 19.399479 },
    { lat: 48.388986, lng: 19.399474 },
    { lat: 48.38871, lng: 19.399415 },
    { lat: 48.38857, lng: 19.399341 },
    { lat: 48.388369, lng: 19.399167 },
    { lat: 48.388007, lng: 19.399552 },
    { lat: 48.387496, lng: 19.399919 },
    { lat: 48.387385, lng: 19.400034 },
    { lat: 48.387472, lng: 19.400396 },
    { lat: 48.387292, lng: 19.400565 },
    { lat: 48.387292, lng: 19.400694 },
    { lat: 48.387116, lng: 19.401137 },
    { lat: 48.386562, lng: 19.4013 },
    { lat: 48.386422, lng: 19.401301 },
    { lat: 48.386111, lng: 19.401351 },
    { lat: 48.385778, lng: 19.401455 },
    { lat: 48.385791, lng: 19.401656 },
    { lat: 48.385751, lng: 19.401964 },
    { lat: 48.385724, lng: 19.40208 },
    { lat: 48.385503, lng: 19.402572 },
    { lat: 48.385456, lng: 19.402773 },
    { lat: 48.385302, lng: 19.403498 },
    { lat: 48.385153, lng: 19.404007 },
    { lat: 48.385124, lng: 19.404088 },
    { lat: 48.385088, lng: 19.404139 },
    { lat: 48.385066, lng: 19.404364 },
    { lat: 48.384886, lng: 19.404526 },
    { lat: 48.384772, lng: 19.404653 },
    { lat: 48.384503, lng: 19.404966 },
    { lat: 48.384225, lng: 19.404972 },
    { lat: 48.384132, lng: 19.404946 },
    { lat: 48.383909, lng: 19.404826 },
    { lat: 48.383776, lng: 19.404727 },
    { lat: 48.38366, lng: 19.404615 },
    { lat: 48.383558, lng: 19.40446 },
    { lat: 48.38328, lng: 19.403999 },
    { lat: 48.382915, lng: 19.404424 },
    { lat: 48.382793, lng: 19.404566 },
    { lat: 48.382833, lng: 19.404747 },
    { lat: 48.382964, lng: 19.40508 },
    { lat: 48.383024, lng: 19.405395 },
    { lat: 48.382852, lng: 19.406152 },
    { lat: 48.382773, lng: 19.406315 },
    { lat: 48.382665, lng: 19.406471 },
    { lat: 48.382555, lng: 19.406723 },
    { lat: 48.382291, lng: 19.406516 },
    { lat: 48.381871, lng: 19.406186 },
    { lat: 48.380293, lng: 19.406939 },
    { lat: 48.380219, lng: 19.40679 },
    { lat: 48.380133, lng: 19.406737 },
    { lat: 48.380011, lng: 19.406702 },
    { lat: 48.379873, lng: 19.40671 },
    { lat: 48.379785, lng: 19.406734 },
    { lat: 48.379423, lng: 19.40704 },
    { lat: 48.379227, lng: 19.407272 },
    { lat: 48.378923, lng: 19.407699 },
    { lat: 48.378765, lng: 19.407887 },
    { lat: 48.378634, lng: 19.408082 },
    { lat: 48.378276, lng: 19.408401 },
    { lat: 48.376251, lng: 19.40917 },
    { lat: 48.376098, lng: 19.409229 },
    { lat: 48.375779, lng: 19.409099 },
    { lat: 48.37538, lng: 19.409317 },
    { lat: 48.37508, lng: 19.409584 },
    { lat: 48.374988, lng: 19.409835 },
    { lat: 48.374829, lng: 19.409616 },
    { lat: 48.374756, lng: 19.409388 },
    { lat: 48.374558, lng: 19.409573 },
    { lat: 48.374586, lng: 19.409788 },
    { lat: 48.374671, lng: 19.409971 },
    { lat: 48.374899, lng: 19.410347 },
    { lat: 48.374924, lng: 19.410388 },
    { lat: 48.375021, lng: 19.410512 },
    { lat: 48.375116, lng: 19.410595 },
    { lat: 48.37516, lng: 19.411483 },
    { lat: 48.375134, lng: 19.412132 },
    { lat: 48.375135, lng: 19.412695 },
    { lat: 48.375136, lng: 19.412837 },
    { lat: 48.374989, lng: 19.414287 },
    { lat: 48.374861, lng: 19.415554 },
    { lat: 48.374693, lng: 19.415999 },
    { lat: 48.374696, lng: 19.416436 },
    { lat: 48.374684, lng: 19.416787 },
    { lat: 48.374688, lng: 19.417216 },
    { lat: 48.374882, lng: 19.417267 },
    { lat: 48.374912, lng: 19.417483 },
    { lat: 48.374896, lng: 19.417551 },
    { lat: 48.374614, lng: 19.417635 },
    { lat: 48.374394, lng: 19.417684 },
    { lat: 48.374038, lng: 19.41772 },
    { lat: 48.373899, lng: 19.417725 },
    { lat: 48.373697, lng: 19.417749 },
    { lat: 48.373503, lng: 19.417755 },
    { lat: 48.373308, lng: 19.417734 },
    { lat: 48.373155, lng: 19.417646 },
    { lat: 48.373077, lng: 19.417562 },
    { lat: 48.372746, lng: 19.417289 },
    { lat: 48.37272, lng: 19.417412 },
    { lat: 48.372712, lng: 19.417452 },
    { lat: 48.37269, lng: 19.417555 },
    { lat: 48.372809, lng: 19.417705 },
    { lat: 48.372734, lng: 19.417727 },
    { lat: 48.372619, lng: 19.417807 },
    { lat: 48.372366, lng: 19.417767 },
    { lat: 48.372359, lng: 19.418019 },
    { lat: 48.372301, lng: 19.418211 },
    { lat: 48.372654, lng: 19.418302 },
    { lat: 48.372641, lng: 19.418479 },
    { lat: 48.372381, lng: 19.418446 },
    { lat: 48.372161, lng: 19.41838 },
    { lat: 48.371943, lng: 19.418335 },
    { lat: 48.371626, lng: 19.41831 },
    { lat: 48.371585, lng: 19.418307 },
    { lat: 48.371572, lng: 19.4183 },
    { lat: 48.371359, lng: 19.418722 },
    { lat: 48.371072, lng: 19.418966 },
    { lat: 48.370742, lng: 19.41918 },
    { lat: 48.370591, lng: 19.41936 },
    { lat: 48.370505, lng: 19.419524 },
    { lat: 48.370308, lng: 19.419745 },
    { lat: 48.370137, lng: 19.420092 },
    { lat: 48.369836, lng: 19.420494 },
    { lat: 48.369258, lng: 19.421658 },
    { lat: 48.369152, lng: 19.42238 },
    { lat: 48.369097, lng: 19.423019 },
    { lat: 48.369014, lng: 19.423589 },
    { lat: 48.368906, lng: 19.424095 },
    { lat: 48.368826, lng: 19.424528 },
    { lat: 48.368739, lng: 19.425006 },
    { lat: 48.368692, lng: 19.425166 },
    { lat: 48.368558, lng: 19.425557 },
    { lat: 48.368484, lng: 19.42588 },
    { lat: 48.368355, lng: 19.426304 },
    { lat: 48.368364, lng: 19.42632 },
    { lat: 48.36836, lng: 19.426412 },
    { lat: 48.368281, lng: 19.426719 },
    { lat: 48.368276, lng: 19.426741 },
    { lat: 48.368255, lng: 19.426879 },
    { lat: 48.368318, lng: 19.426968 },
    { lat: 48.368262, lng: 19.427223 },
    { lat: 48.368182, lng: 19.427428 },
    { lat: 48.368084, lng: 19.427777 },
    { lat: 48.368032, lng: 19.42798 },
    { lat: 48.367999, lng: 19.428104 },
    { lat: 48.367972, lng: 19.428208 },
    { lat: 48.367961, lng: 19.428305 },
    { lat: 48.367944, lng: 19.428479 },
    { lat: 48.367889, lng: 19.428812 },
    { lat: 48.367882, lng: 19.428846 },
    { lat: 48.367861, lng: 19.429113 },
    { lat: 48.367855, lng: 19.429195 },
    { lat: 48.367856, lng: 19.429542 },
    { lat: 48.367453, lng: 19.430661 },
    { lat: 48.367358, lng: 19.430956 },
    { lat: 48.367367, lng: 19.431376 },
    { lat: 48.36748, lng: 19.431436 },
    { lat: 48.367959, lng: 19.431516 },
    { lat: 48.367975, lng: 19.431358 },
    { lat: 48.368541, lng: 19.43148 },
    { lat: 48.368722, lng: 19.43155 },
    { lat: 48.368783, lng: 19.431677 },
    { lat: 48.368787, lng: 19.431705 },
    { lat: 48.368805, lng: 19.431859 },
    { lat: 48.368768, lng: 19.431902 },
    { lat: 48.368695, lng: 19.432072 },
    { lat: 48.368604, lng: 19.43232 },
    { lat: 48.368594, lng: 19.432588 },
    { lat: 48.369018, lng: 19.434041 },
    { lat: 48.368726, lng: 19.434416 },
    { lat: 48.368595, lng: 19.4373 },
    { lat: 48.368667, lng: 19.437553 },
    { lat: 48.368798, lng: 19.437907 },
    { lat: 48.36886, lng: 19.438272 },
    { lat: 48.368945, lng: 19.43864 },
    { lat: 48.3691048, lng: 19.4383969 },
    { lat: 48.3693023, lng: 19.4382129 },
    { lat: 48.3695597, lng: 19.438102 },
    { lat: 48.3697856, lng: 19.4377268 },
    { lat: 48.3700776, lng: 19.437849 },
    { lat: 48.3700421, lng: 19.4383844 },
    { lat: 48.3700684, lng: 19.4387294 },
    { lat: 48.3702576, lng: 19.4392768 },
    { lat: 48.3702457, lng: 19.4398884 },
    { lat: 48.3704847, lng: 19.4398174 },
    { lat: 48.3707079, lng: 19.4396523 },
    { lat: 48.3707429, lng: 19.4388391 },
    { lat: 48.3708571, lng: 19.4382439 },
    { lat: 48.3710631, lng: 19.437644 },
    { lat: 48.371339, lng: 19.4371834 },
    { lat: 48.371816, lng: 19.4368737 },
    { lat: 48.3719441, lng: 19.4371576 },
    { lat: 48.3719363, lng: 19.4373041 },
    { lat: 48.3720922, lng: 19.4372145 },
    { lat: 48.3721239, lng: 19.4375508 },
    { lat: 48.3723952, lng: 19.4375443 },
    { lat: 48.3725219, lng: 19.4381048 },
    { lat: 48.3729286, lng: 19.438558 },
    { lat: 48.3732217, lng: 19.4390661 },
    { lat: 48.3737311, lng: 19.4397845 },
    { lat: 48.3742712, lng: 19.4389245 },
    { lat: 48.3744431, lng: 19.438913 },
    { lat: 48.3748021, lng: 19.4379696 },
    { lat: 48.3751898, lng: 19.4373203 },
    { lat: 48.3753703, lng: 19.4375429 },
    { lat: 48.3757041, lng: 19.4376901 },
    { lat: 48.3757884, lng: 19.4375897 },
    { lat: 48.375924, lng: 19.4380036 },
    { lat: 48.3760055, lng: 19.4380332 },
    { lat: 48.3761241, lng: 19.4382291 },
    { lat: 48.3768412, lng: 19.4382163 },
    { lat: 48.3771252, lng: 19.4383103 },
    { lat: 48.377583, lng: 19.438243 },
    { lat: 48.3779357, lng: 19.4380486 },
    { lat: 48.3784959, lng: 19.4374763 },
    { lat: 48.3790655, lng: 19.4371032 },
    { lat: 48.3792899, lng: 19.4362403 },
    { lat: 48.3798578, lng: 19.4366967 },
    { lat: 48.3804099, lng: 19.4368849 },
    { lat: 48.3802393, lng: 19.4371661 },
    { lat: 48.3797192, lng: 19.437776 },
    { lat: 48.3798731, lng: 19.4380057 },
    { lat: 48.3803662, lng: 19.4394318 },
    { lat: 48.3805585, lng: 19.43971 },
    { lat: 48.3805644, lng: 19.4396459 },
    { lat: 48.3806461, lng: 19.4396539 },
    { lat: 48.3807466, lng: 19.4395167 },
    { lat: 48.3807046, lng: 19.4398686 },
    { lat: 48.3807953, lng: 19.4399292 },
    { lat: 48.3810003, lng: 19.4406438 },
    { lat: 48.3813741, lng: 19.440784 },
    { lat: 48.3813939, lng: 19.4409386 },
    { lat: 48.3815261, lng: 19.4409728 },
    { lat: 48.3816472, lng: 19.4411018 },
    { lat: 48.3816188, lng: 19.4411654 },
    { lat: 48.3822033, lng: 19.4416892 },
    { lat: 48.3824591, lng: 19.4425499 },
    { lat: 48.3823954, lng: 19.4432086 },
    { lat: 48.3825163, lng: 19.4433348 },
    { lat: 48.3826592, lng: 19.4436224 },
    { lat: 48.382532, lng: 19.4440312 },
    { lat: 48.3829399, lng: 19.4446315 },
    { lat: 48.3830581, lng: 19.4448441 },
    { lat: 48.3831421, lng: 19.4451448 },
    { lat: 48.383256, lng: 19.445288 },
    { lat: 48.3831434, lng: 19.445581 },
    { lat: 48.3835155, lng: 19.4462766 },
    { lat: 48.3832633, lng: 19.4463335 },
    { lat: 48.3832391, lng: 19.4463913 },
    { lat: 48.3834755, lng: 19.4466287 },
    { lat: 48.384249, lng: 19.4479328 },
    { lat: 48.3847034, lng: 19.4485381 },
    { lat: 48.3851037, lng: 19.4494343 },
    { lat: 48.3858917, lng: 19.450877 },
    { lat: 48.3866398, lng: 19.4517382 },
    { lat: 48.3868384, lng: 19.4519137 },
    { lat: 48.3874796, lng: 19.4520792 },
    { lat: 48.3880581, lng: 19.4520131 },
    { lat: 48.3888969, lng: 19.4521177 },
    { lat: 48.3890958, lng: 19.4522229 },
    { lat: 48.3906259, lng: 19.4535133 },
    { lat: 48.3915494, lng: 19.4535973 },
    { lat: 48.3917646, lng: 19.4535274 },
    { lat: 48.3925898, lng: 19.452954 },
    { lat: 48.3930452, lng: 19.4536768 },
    { lat: 48.3934093, lng: 19.454133 },
    { lat: 48.3933275, lng: 19.4542619 },
    { lat: 48.3934518, lng: 19.4545112 },
    { lat: 48.393751, lng: 19.4541384 },
    { lat: 48.3939911, lng: 19.4545003 },
    { lat: 48.3944371, lng: 19.4539166 },
    { lat: 48.3946055, lng: 19.4536403 },
    { lat: 48.3947836, lng: 19.4530623 },
    { lat: 48.394759, lng: 19.4530213 },
    { lat: 48.3950158, lng: 19.452292 },
    { lat: 48.3953967, lng: 19.4518124 },
    { lat: 48.3956464, lng: 19.4522367 },
    { lat: 48.3959745, lng: 19.4520471 },
    { lat: 48.396009, lng: 19.4522285 },
    { lat: 48.3965341, lng: 19.4519652 },
    { lat: 48.3972705, lng: 19.4534176 },
    { lat: 48.3973629, lng: 19.4537043 },
    { lat: 48.397368, lng: 19.4537375 },
  ],
  Budiná: [
    { lat: 48.4416522, lng: 19.4977995 },
    { lat: 48.4416968, lng: 19.4975698 },
    { lat: 48.4420017, lng: 19.497245 },
    { lat: 48.4424605, lng: 19.4972228 },
    { lat: 48.4434687, lng: 19.4965133 },
    { lat: 48.4437971, lng: 19.4963528 },
    { lat: 48.443979, lng: 19.4963142 },
    { lat: 48.4446151, lng: 19.4965417 },
    { lat: 48.4448731, lng: 19.4964415 },
    { lat: 48.4452508, lng: 19.4961542 },
    { lat: 48.4457174, lng: 19.4953094 },
    { lat: 48.4461917, lng: 19.4943097 },
    { lat: 48.4470304, lng: 19.493077 },
    { lat: 48.4473408, lng: 19.4924331 },
    { lat: 48.4475571, lng: 19.4916057 },
    { lat: 48.4482162, lng: 19.4917019 },
    { lat: 48.44944, lng: 19.4914786 },
    { lat: 48.4501856, lng: 19.4914436 },
    { lat: 48.4504015, lng: 19.4903421 },
    { lat: 48.4506314, lng: 19.4901728 },
    { lat: 48.4525723, lng: 19.4876123 },
    { lat: 48.4527099, lng: 19.4878545 },
    { lat: 48.4534313, lng: 19.4874709 },
    { lat: 48.4543673, lng: 19.4874844 },
    { lat: 48.4551993, lng: 19.4872972 },
    { lat: 48.4562724, lng: 19.486916 },
    { lat: 48.4572817, lng: 19.4855441 },
    { lat: 48.4581993, lng: 19.4847496 },
    { lat: 48.4597366, lng: 19.4829178 },
    { lat: 48.4600912, lng: 19.4825897 },
    { lat: 48.4609046, lng: 19.4812532 },
    { lat: 48.4618461, lng: 19.4809513 },
    { lat: 48.4626753, lng: 19.4791771 },
    { lat: 48.4643265, lng: 19.4763986 },
    { lat: 48.4661972, lng: 19.4743187 },
    { lat: 48.4672849, lng: 19.4733298 },
    { lat: 48.4684672, lng: 19.4706732 },
    { lat: 48.4714105, lng: 19.4655665 },
    { lat: 48.4721847, lng: 19.4644346 },
    { lat: 48.4726638, lng: 19.4637617 },
    { lat: 48.4731916, lng: 19.4631896 },
    { lat: 48.4753191, lng: 19.4610842 },
    { lat: 48.4766608, lng: 19.4593121 },
    { lat: 48.4771156, lng: 19.4592313 },
    { lat: 48.4773054, lng: 19.4590223 },
    { lat: 48.4784118, lng: 19.4570601 },
    { lat: 48.4794847, lng: 19.4547223 },
    { lat: 48.4804517, lng: 19.4518852 },
    { lat: 48.4817527, lng: 19.4499671 },
    { lat: 48.4831838, lng: 19.4483953 },
    { lat: 48.4851512, lng: 19.4483645 },
    { lat: 48.4863713, lng: 19.448496 },
    { lat: 48.4866811, lng: 19.4486621 },
    { lat: 48.4870638, lng: 19.4490638 },
    { lat: 48.4878091, lng: 19.4495526 },
    { lat: 48.4893161, lng: 19.4501571 },
    { lat: 48.4895754, lng: 19.4501371 },
    { lat: 48.4897421, lng: 19.4502278 },
    { lat: 48.4899472, lng: 19.4505923 },
    { lat: 48.4902817, lng: 19.4508727 },
    { lat: 48.4910601, lng: 19.4518327 },
    { lat: 48.4919522, lng: 19.4532166 },
    { lat: 48.4923315, lng: 19.454173 },
    { lat: 48.492628, lng: 19.4556 },
    { lat: 48.49291, lng: 19.455673 },
    { lat: 48.493771, lng: 19.455252 },
    { lat: 48.494277, lng: 19.455022 },
    { lat: 48.49491, lng: 19.455163 },
    { lat: 48.495911, lng: 19.45536 },
    { lat: 48.496647, lng: 19.455448 },
    { lat: 48.497091, lng: 19.455754 },
    { lat: 48.497514, lng: 19.456017 },
    { lat: 48.497866, lng: 19.456197 },
    { lat: 48.49861, lng: 19.456372 },
    { lat: 48.498424, lng: 19.45581 },
    { lat: 48.498563, lng: 19.454719 },
    { lat: 48.49857, lng: 19.454665 },
    { lat: 48.498576, lng: 19.454613 },
    { lat: 48.498585, lng: 19.454561 },
    { lat: 48.498791, lng: 19.453422 },
    { lat: 48.499133, lng: 19.452144 },
    { lat: 48.499151, lng: 19.452075 },
    { lat: 48.49917, lng: 19.452002 },
    { lat: 48.499251, lng: 19.451373 },
    { lat: 48.499517, lng: 19.450753 },
    { lat: 48.499613, lng: 19.450448 },
    { lat: 48.499634, lng: 19.450382 },
    { lat: 48.499827, lng: 19.450362 },
    { lat: 48.499844, lng: 19.45036 },
    { lat: 48.499967, lng: 19.450347 },
    { lat: 48.500052, lng: 19.450353 },
    { lat: 48.500077, lng: 19.450257 },
    { lat: 48.500183, lng: 19.45027 },
    { lat: 48.500249, lng: 19.450163 },
    { lat: 48.500375, lng: 19.450162 },
    { lat: 48.500756, lng: 19.449794 },
    { lat: 48.501194, lng: 19.449441 },
    { lat: 48.501668, lng: 19.448968 },
    { lat: 48.502619, lng: 19.448076 },
    { lat: 48.502874, lng: 19.447783 },
    { lat: 48.503172, lng: 19.447335 },
    { lat: 48.503982, lng: 19.446723 },
    { lat: 48.504144, lng: 19.445293 },
    { lat: 48.503933, lng: 19.444967 },
    { lat: 48.503836, lng: 19.444834 },
    { lat: 48.503502, lng: 19.444342 },
    { lat: 48.503073, lng: 19.443873 },
    { lat: 48.502625, lng: 19.443372 },
    { lat: 48.501932, lng: 19.442823 },
    { lat: 48.501426, lng: 19.44287 },
    { lat: 48.500958, lng: 19.442463 },
    { lat: 48.500406, lng: 19.441837 },
    { lat: 48.499055, lng: 19.441723 },
    { lat: 48.499025, lng: 19.441716 },
    { lat: 48.498158, lng: 19.441529 },
    { lat: 48.497578, lng: 19.441024 },
    { lat: 48.496591, lng: 19.440722 },
    { lat: 48.496019, lng: 19.440796 },
    { lat: 48.495039, lng: 19.440267 },
    { lat: 48.494294, lng: 19.440065 },
    { lat: 48.493616, lng: 19.439792 },
    { lat: 48.493021, lng: 19.439466 },
    { lat: 48.492093, lng: 19.43876 },
    { lat: 48.490875, lng: 19.438785 },
    { lat: 48.49031, lng: 19.438736 },
    { lat: 48.489885, lng: 19.438699 },
    { lat: 48.489267, lng: 19.43824 },
    { lat: 48.487539, lng: 19.435759 },
    { lat: 48.487567, lng: 19.43542 },
    { lat: 48.487532, lng: 19.43537 },
    { lat: 48.487332, lng: 19.435103 },
    { lat: 48.487096, lng: 19.434771 },
    { lat: 48.486859, lng: 19.434364 },
    { lat: 48.486157, lng: 19.434148 },
    { lat: 48.485584, lng: 19.434236 },
    { lat: 48.485533, lng: 19.433693 },
    { lat: 48.485206, lng: 19.433647 },
    { lat: 48.485132, lng: 19.433581 },
    { lat: 48.485059, lng: 19.433295 },
    { lat: 48.484694, lng: 19.433078 },
    { lat: 48.484326, lng: 19.433099 },
    { lat: 48.483954, lng: 19.432985 },
    { lat: 48.483739, lng: 19.432894 },
    { lat: 48.483099, lng: 19.432458 },
    { lat: 48.483077, lng: 19.432434 },
    { lat: 48.482955, lng: 19.431965 },
    { lat: 48.483012, lng: 19.431587 },
    { lat: 48.48306, lng: 19.431269 },
    { lat: 48.48251, lng: 19.431017 },
    { lat: 48.482518, lng: 19.430997 },
    { lat: 48.482526, lng: 19.430967 },
    { lat: 48.481787, lng: 19.430088 },
    { lat: 48.4821, lng: 19.429469 },
    { lat: 48.482404, lng: 19.428531 },
    { lat: 48.482757, lng: 19.427397 },
    { lat: 48.482903, lng: 19.427456 },
    { lat: 48.483294, lng: 19.427767 },
    { lat: 48.483969, lng: 19.427421 },
    { lat: 48.484397, lng: 19.426821 },
    { lat: 48.48488, lng: 19.426487 },
    { lat: 48.485096, lng: 19.42642 },
    { lat: 48.485658, lng: 19.425686 },
    { lat: 48.486083, lng: 19.424776 },
    { lat: 48.486087, lng: 19.424058 },
    { lat: 48.486282, lng: 19.423732 },
    { lat: 48.486307, lng: 19.423705 },
    { lat: 48.486342, lng: 19.423666 },
    { lat: 48.486454, lng: 19.423542 },
    { lat: 48.486479, lng: 19.423516 },
    { lat: 48.486513, lng: 19.423479 },
    { lat: 48.48653, lng: 19.42346 },
    { lat: 48.487629, lng: 19.422258 },
    { lat: 48.487646, lng: 19.422239 },
    { lat: 48.487689, lng: 19.422192 },
    { lat: 48.487716, lng: 19.422162 },
    { lat: 48.487488, lng: 19.421587 },
    { lat: 48.487466, lng: 19.421573 },
    { lat: 48.487224, lng: 19.421009 },
    { lat: 48.48715, lng: 19.420746 },
    { lat: 48.487084, lng: 19.420387 },
    { lat: 48.487049, lng: 19.42035 },
    { lat: 48.48698, lng: 19.420261 },
    { lat: 48.486915, lng: 19.420096 },
    { lat: 48.486898, lng: 19.419406 },
    { lat: 48.486756, lng: 19.418687 },
    { lat: 48.486729, lng: 19.4185 },
    { lat: 48.486561, lng: 19.418275 },
    { lat: 48.48645, lng: 19.418056 },
    { lat: 48.486197, lng: 19.417558 },
    { lat: 48.486109, lng: 19.417385 },
    { lat: 48.485787, lng: 19.416739 },
    { lat: 48.486068, lng: 19.41623 },
    { lat: 48.48603, lng: 19.415986 },
    { lat: 48.485857, lng: 19.415734 },
    { lat: 48.485701, lng: 19.415508 },
    { lat: 48.485682, lng: 19.41549 },
    { lat: 48.485065, lng: 19.414807 },
    { lat: 48.484902, lng: 19.414674 },
    { lat: 48.484646, lng: 19.414382 },
    { lat: 48.484622, lng: 19.414338 },
    { lat: 48.483773, lng: 19.413207 },
    { lat: 48.483492, lng: 19.413231 },
    { lat: 48.483177, lng: 19.412959 },
    { lat: 48.483299, lng: 19.412442 },
    { lat: 48.483613, lng: 19.411643 },
    { lat: 48.483639, lng: 19.411575 },
    { lat: 48.483486, lng: 19.41076 },
    { lat: 48.483415, lng: 19.41039 },
    { lat: 48.482947, lng: 19.40962 },
    { lat: 48.482581, lng: 19.409075 },
    { lat: 48.482298, lng: 19.408731 },
    { lat: 48.482282, lng: 19.408713 },
    { lat: 48.482043, lng: 19.408423 },
    { lat: 48.4817789, lng: 19.4090064 },
    { lat: 48.4817047, lng: 19.4092768 },
    { lat: 48.4815435, lng: 19.4102997 },
    { lat: 48.481396, lng: 19.4108778 },
    { lat: 48.4811552, lng: 19.4115063 },
    { lat: 48.4809045, lng: 19.4118542 },
    { lat: 48.4796989, lng: 19.4104994 },
    { lat: 48.4792408, lng: 19.4108711 },
    { lat: 48.4789727, lng: 19.4112549 },
    { lat: 48.47854, lng: 19.4125514 },
    { lat: 48.4773484, lng: 19.4135722 },
    { lat: 48.4759073, lng: 19.4142238 },
    { lat: 48.4744631, lng: 19.4144275 },
    { lat: 48.4743767, lng: 19.4142243 },
    { lat: 48.472796, lng: 19.4149302 },
    { lat: 48.4720409, lng: 19.4151453 },
    { lat: 48.4706293, lng: 19.4157067 },
    { lat: 48.4699505, lng: 19.4164868 },
    { lat: 48.4682761, lng: 19.4172391 },
    { lat: 48.4679793, lng: 19.4172757 },
    { lat: 48.4673278, lng: 19.4175884 },
    { lat: 48.4655511, lng: 19.4186317 },
    { lat: 48.4651532, lng: 19.4189894 },
    { lat: 48.4649455, lng: 19.4193627 },
    { lat: 48.4647606, lng: 19.4194833 },
    { lat: 48.4645329, lng: 19.4207259 },
    { lat: 48.4642705, lng: 19.421518 },
    { lat: 48.4641704, lng: 19.4220507 },
    { lat: 48.464167, lng: 19.4225315 },
    { lat: 48.4632131, lng: 19.4232143 },
    { lat: 48.4627856, lng: 19.4244785 },
    { lat: 48.4622346, lng: 19.4253659 },
    { lat: 48.461767, lng: 19.4253468 },
    { lat: 48.4613354, lng: 19.4256089 },
    { lat: 48.4601784, lng: 19.4277992 },
    { lat: 48.4603255, lng: 19.4308319 },
    { lat: 48.4603047, lng: 19.4314953 },
    { lat: 48.4603872, lng: 19.4326977 },
    { lat: 48.4603443, lng: 19.4331525 },
    { lat: 48.4600951, lng: 19.4339154 },
    { lat: 48.45974, lng: 19.4343242 },
    { lat: 48.4595414, lng: 19.4349428 },
    { lat: 48.458482, lng: 19.4352058 },
    { lat: 48.4577591, lng: 19.4365395 },
    { lat: 48.4575874, lng: 19.4366287 },
    { lat: 48.4573573, lng: 19.4368644 },
    { lat: 48.4571183, lng: 19.4373176 },
    { lat: 48.4571805, lng: 19.4376817 },
    { lat: 48.4570049, lng: 19.4380461 },
    { lat: 48.4568763, lng: 19.4380676 },
    { lat: 48.4567605, lng: 19.4382929 },
    { lat: 48.4566059, lng: 19.4383807 },
    { lat: 48.45646, lng: 19.4383265 },
    { lat: 48.4562224, lng: 19.4388083 },
    { lat: 48.4562611, lng: 19.439105 },
    { lat: 48.4561185, lng: 19.4392614 },
    { lat: 48.4559501, lng: 19.4390556 },
    { lat: 48.4557693, lng: 19.4390726 },
    { lat: 48.4554905, lng: 19.4392751 },
    { lat: 48.4552581, lng: 19.4397027 },
    { lat: 48.4552182, lng: 19.4400048 },
    { lat: 48.4549859, lng: 19.4402183 },
    { lat: 48.4547906, lng: 19.4402849 },
    { lat: 48.4545119, lng: 19.4401834 },
    { lat: 48.45399, lng: 19.4409034 },
    { lat: 48.4537398, lng: 19.4415501 },
    { lat: 48.4534233, lng: 19.4418414 },
    { lat: 48.4530798, lng: 19.4426401 },
    { lat: 48.4528082, lng: 19.4429064 },
    { lat: 48.4523463, lng: 19.4443271 },
    { lat: 48.4521634, lng: 19.4446071 },
    { lat: 48.4518264, lng: 19.4455207 },
    { lat: 48.4519178, lng: 19.4458111 },
    { lat: 48.4516278, lng: 19.4461194 },
    { lat: 48.4516097, lng: 19.4472446 },
    { lat: 48.4512997, lng: 19.447604 },
    { lat: 48.4512706, lng: 19.4478496 },
    { lat: 48.450743, lng: 19.4484507 },
    { lat: 48.4505585, lng: 19.4490088 },
    { lat: 48.4504343, lng: 19.4502794 },
    { lat: 48.4503222, lng: 19.450413 },
    { lat: 48.4503121, lng: 19.45056 },
    { lat: 48.449893, lng: 19.451162 },
    { lat: 48.4498435, lng: 19.4515727 },
    { lat: 48.4498939, lng: 19.4525265 },
    { lat: 48.4500129, lng: 19.4528551 },
    { lat: 48.4497691, lng: 19.4533317 },
    { lat: 48.4497702, lng: 19.454053 },
    { lat: 48.4498397, lng: 19.4545036 },
    { lat: 48.4498412, lng: 19.4554236 },
    { lat: 48.4498867, lng: 19.4558458 },
    { lat: 48.4499568, lng: 19.4560858 },
    { lat: 48.449915, lng: 19.4562777 },
    { lat: 48.4500808, lng: 19.4577977 },
    { lat: 48.4501418, lng: 19.4588395 },
    { lat: 48.4502011, lng: 19.458998 },
    { lat: 48.4501383, lng: 19.4596532 },
    { lat: 48.4502461, lng: 19.460682 },
    { lat: 48.4506642, lng: 19.462564 },
    { lat: 48.450684, lng: 19.4629613 },
    { lat: 48.450537, lng: 19.4635569 },
    { lat: 48.450246, lng: 19.4641574 },
    { lat: 48.450086, lng: 19.4646599 },
    { lat: 48.4494738, lng: 19.4656688 },
    { lat: 48.4491631, lng: 19.4663278 },
    { lat: 48.4490117, lng: 19.4664656 },
    { lat: 48.4490102, lng: 19.4666741 },
    { lat: 48.4488762, lng: 19.4668455 },
    { lat: 48.4488282, lng: 19.4670962 },
    { lat: 48.4487306, lng: 19.467252 },
    { lat: 48.4485694, lng: 19.4672488 },
    { lat: 48.4484817, lng: 19.4673997 },
    { lat: 48.4484535, lng: 19.4676199 },
    { lat: 48.4482476, lng: 19.4682029 },
    { lat: 48.4478779, lng: 19.4689742 },
    { lat: 48.4477459, lng: 19.4693584 },
    { lat: 48.4477535, lng: 19.4695915 },
    { lat: 48.4476375, lng: 19.4697429 },
    { lat: 48.4475254, lng: 19.4702108 },
    { lat: 48.4474052, lng: 19.4711621 },
    { lat: 48.4471892, lng: 19.4717762 },
    { lat: 48.446912, lng: 19.4721359 },
    { lat: 48.4467437, lng: 19.4725384 },
    { lat: 48.4461544, lng: 19.4741865 },
    { lat: 48.4458377, lng: 19.4756618 },
    { lat: 48.4457163, lng: 19.4760295 },
    { lat: 48.4454715, lng: 19.476412 },
    { lat: 48.4448527, lng: 19.4768918 },
    { lat: 48.4446381, lng: 19.4773187 },
    { lat: 48.4444911, lng: 19.4777168 },
    { lat: 48.444345, lng: 19.4779668 },
    { lat: 48.4442199, lng: 19.4784499 },
    { lat: 48.4439479, lng: 19.478781 },
    { lat: 48.443807, lng: 19.4791217 },
    { lat: 48.4437405, lng: 19.4795553 },
    { lat: 48.4435837, lng: 19.4799161 },
    { lat: 48.4435218, lng: 19.4803304 },
    { lat: 48.4433942, lng: 19.480708 },
    { lat: 48.4431843, lng: 19.4810183 },
    { lat: 48.4429861, lng: 19.4810474 },
    { lat: 48.442642, lng: 19.4816926 },
    { lat: 48.4423797, lng: 19.4824489 },
    { lat: 48.442179, lng: 19.4826873 },
    { lat: 48.4418397, lng: 19.4828575 },
    { lat: 48.4412182, lng: 19.4830162 },
    { lat: 48.4409654, lng: 19.4832717 },
    { lat: 48.4408217, lng: 19.483625 },
    { lat: 48.4405862, lng: 19.4838926 },
    { lat: 48.4403476, lng: 19.4843308 },
    { lat: 48.440115, lng: 19.4848543 },
    { lat: 48.4398336, lng: 19.4858056 },
    { lat: 48.4396639, lng: 19.4860814 },
    { lat: 48.4394051, lng: 19.4863113 },
    { lat: 48.4391092, lng: 19.4868794 },
    { lat: 48.4389422, lng: 19.4869438 },
    { lat: 48.4386469, lng: 19.4874042 },
    { lat: 48.4383266, lng: 19.487763 },
    { lat: 48.4381465, lng: 19.4880843 },
    { lat: 48.4381481, lng: 19.4883624 },
    { lat: 48.4379068, lng: 19.4888161 },
    { lat: 48.437624, lng: 19.489858 },
    { lat: 48.4373296, lng: 19.4901966 },
    { lat: 48.4371496, lng: 19.4904934 },
    { lat: 48.4370301, lng: 19.491144 },
    { lat: 48.4370605, lng: 19.4913875 },
    { lat: 48.437153, lng: 19.4914154 },
    { lat: 48.4372294, lng: 19.4913371 },
    { lat: 48.4374721, lng: 19.4915136 },
    { lat: 48.4379619, lng: 19.4922291 },
    { lat: 48.4384587, lng: 19.4927867 },
    { lat: 48.4393034, lng: 19.4934785 },
    { lat: 48.4393432, lng: 19.4935566 },
    { lat: 48.4391927, lng: 19.4943436 },
    { lat: 48.4393544, lng: 19.4947224 },
    { lat: 48.4396486, lng: 19.4950113 },
    { lat: 48.4403682, lng: 19.4965063 },
    { lat: 48.4406135, lng: 19.4967148 },
    { lat: 48.4410318, lng: 19.4973063 },
    { lat: 48.4413519, lng: 19.4976443 },
    { lat: 48.4416522, lng: 19.4977995 },
  ],
  Fiľakovo: [
    { lat: 48.2938369, lng: 19.8086992 },
    { lat: 48.2935576, lng: 19.8089573 },
    { lat: 48.2934946, lng: 19.809421 },
    { lat: 48.2928244, lng: 19.8104298 },
    { lat: 48.2927112, lng: 19.8108535 },
    { lat: 48.2923992, lng: 19.8114973 },
    { lat: 48.2917583, lng: 19.8117358 },
    { lat: 48.2914933, lng: 19.8112376 },
    { lat: 48.2911781, lng: 19.8108327 },
    { lat: 48.2909705, lng: 19.810879 },
    { lat: 48.2907536, lng: 19.8104022 },
    { lat: 48.2902354, lng: 19.8096579 },
    { lat: 48.2898509, lng: 19.8102706 },
    { lat: 48.2896746, lng: 19.8104599 },
    { lat: 48.2895565, lng: 19.8103891 },
    { lat: 48.2891105, lng: 19.8109869 },
    { lat: 48.2886648, lng: 19.8119456 },
    { lat: 48.2877679, lng: 19.8115846 },
    { lat: 48.2870912, lng: 19.8114169 },
    { lat: 48.2863859, lng: 19.8110526 },
    { lat: 48.2858261, lng: 19.8106223 },
    { lat: 48.2855969, lng: 19.8103538 },
    { lat: 48.284826, lng: 19.8091406 },
    { lat: 48.2846326, lng: 19.8089403 },
    { lat: 48.2834808, lng: 19.8081608 },
    { lat: 48.2825905, lng: 19.8072835 },
    { lat: 48.2823219, lng: 19.8069626 },
    { lat: 48.2818748, lng: 19.8062737 },
    { lat: 48.2812945, lng: 19.8058388 },
    { lat: 48.2804874, lng: 19.8049791 },
    { lat: 48.2792574, lng: 19.8032157 },
    { lat: 48.2776424, lng: 19.8013043 },
    { lat: 48.2773527, lng: 19.801045 },
    { lat: 48.277128, lng: 19.8005199 },
    { lat: 48.2769719, lng: 19.8003259 },
    { lat: 48.2766509, lng: 19.8008711 },
    { lat: 48.2762993, lng: 19.8003809 },
    { lat: 48.2758707, lng: 19.7995499 },
    { lat: 48.2683343, lng: 19.7916023 },
    { lat: 48.2683156, lng: 19.7913587 },
    { lat: 48.2685904, lng: 19.788179 },
    { lat: 48.2687991, lng: 19.7869083 },
    { lat: 48.2687832, lng: 19.7854476 },
    { lat: 48.2687115, lng: 19.7844592 },
    { lat: 48.2684475, lng: 19.7823529 },
    { lat: 48.2681249, lng: 19.7819102 },
    { lat: 48.2673461, lng: 19.7821117 },
    { lat: 48.2664235, lng: 19.7826266 },
    { lat: 48.2652476, lng: 19.7837326 },
    { lat: 48.2648265, lng: 19.7840444 },
    { lat: 48.2637382, lng: 19.7834841 },
    { lat: 48.2627114, lng: 19.7852411 },
    { lat: 48.262119, lng: 19.7860401 },
    { lat: 48.2616589, lng: 19.7862061 },
    { lat: 48.2613236, lng: 19.786602 },
    { lat: 48.2603953, lng: 19.7869076 },
    { lat: 48.2599683, lng: 19.7876768 },
    { lat: 48.2571622, lng: 19.7918373 },
    { lat: 48.2568918, lng: 19.7924513 },
    { lat: 48.2554129, lng: 19.7925113 },
    { lat: 48.2542116, lng: 19.7924495 },
    { lat: 48.2531806, lng: 19.7929882 },
    { lat: 48.2523597, lng: 19.7933308 },
    { lat: 48.2514235, lng: 19.793105 },
    { lat: 48.2503275, lng: 19.7932368 },
    { lat: 48.2491004, lng: 19.795027 },
    { lat: 48.2481542, lng: 19.7954137 },
    { lat: 48.2476598, lng: 19.795344 },
    { lat: 48.2470748, lng: 19.7958994 },
    { lat: 48.2466767, lng: 19.7969174 },
    { lat: 48.2451681, lng: 19.799481 },
    { lat: 48.2441511, lng: 19.8003846 },
    { lat: 48.2442598, lng: 19.8013766 },
    { lat: 48.2441007, lng: 19.8022141 },
    { lat: 48.2435911, lng: 19.8032812 },
    { lat: 48.242703, lng: 19.8058166 },
    { lat: 48.2458227, lng: 19.81244 },
    { lat: 48.2463689, lng: 19.8134655 },
    { lat: 48.2466722, lng: 19.8139334 },
    { lat: 48.2484663, lng: 19.816461 },
    { lat: 48.2487642, lng: 19.8169623 },
    { lat: 48.2501343, lng: 19.8187416 },
    { lat: 48.2512256, lng: 19.8199142 },
    { lat: 48.2530627, lng: 19.8215514 },
    { lat: 48.2533259, lng: 19.8219003 },
    { lat: 48.2535399, lng: 19.8222321 },
    { lat: 48.2539916, lng: 19.8232112 },
    { lat: 48.2543446, lng: 19.8238119 },
    { lat: 48.2544706, lng: 19.8241449 },
    { lat: 48.2551576, lng: 19.8252725 },
    { lat: 48.2576409, lng: 19.8286519 },
    { lat: 48.2578618, lng: 19.8287575 },
    { lat: 48.2599832, lng: 19.8321623 },
    { lat: 48.2610006, lng: 19.8339699 },
    { lat: 48.262679, lng: 19.8366724 },
    { lat: 48.2638959, lng: 19.8393881 },
    { lat: 48.2644982, lng: 19.8403087 },
    { lat: 48.2638978, lng: 19.8412174 },
    { lat: 48.2632677, lng: 19.842036 },
    { lat: 48.2622866, lng: 19.8427654 },
    { lat: 48.2611679, lng: 19.8433351 },
    { lat: 48.2600594, lng: 19.8441635 },
    { lat: 48.2594178, lng: 19.8444645 },
    { lat: 48.2585979, lng: 19.8450653 },
    { lat: 48.2568064, lng: 19.8471069 },
    { lat: 48.2558174, lng: 19.848404 },
    { lat: 48.2541118, lng: 19.8502935 },
    { lat: 48.2538952, lng: 19.8507042 },
    { lat: 48.2536265, lng: 19.8508432 },
    { lat: 48.2528018, lng: 19.8507939 },
    { lat: 48.2524123, lng: 19.8509298 },
    { lat: 48.2520362, lng: 19.8508593 },
    { lat: 48.2512678, lng: 19.8511146 },
    { lat: 48.2507908, lng: 19.8508468 },
    { lat: 48.2511296, lng: 19.8528895 },
    { lat: 48.2513862, lng: 19.8543541 },
    { lat: 48.2516133, lng: 19.8545009 },
    { lat: 48.2518123, lng: 19.854805 },
    { lat: 48.252477, lng: 19.8561009 },
    { lat: 48.252523, lng: 19.8562774 },
    { lat: 48.2525083, lng: 19.857129 },
    { lat: 48.2530607, lng: 19.8589827 },
    { lat: 48.2532323, lng: 19.860287 },
    { lat: 48.253524, lng: 19.8617087 },
    { lat: 48.2538186, lng: 19.8616712 },
    { lat: 48.2543769, lng: 19.8613482 },
    { lat: 48.2546795, lng: 19.861054 },
    { lat: 48.2554134, lng: 19.8601012 },
    { lat: 48.255792, lng: 19.8595051 },
    { lat: 48.2562206, lng: 19.8598021 },
    { lat: 48.256781, lng: 19.8599975 },
    { lat: 48.2568496, lng: 19.8596302 },
    { lat: 48.2580373, lng: 19.8595584 },
    { lat: 48.2586505, lng: 19.8597469 },
    { lat: 48.2590678, lng: 19.8601729 },
    { lat: 48.2594171, lng: 19.8601767 },
    { lat: 48.2598701, lng: 19.859632 },
    { lat: 48.2599621, lng: 19.8591847 },
    { lat: 48.2605056, lng: 19.8588309 },
    { lat: 48.2611049, lng: 19.8585402 },
    { lat: 48.2615371, lng: 19.8578815 },
    { lat: 48.2616, lng: 19.8569211 },
    { lat: 48.2617912, lng: 19.856889 },
    { lat: 48.2618612, lng: 19.8570445 },
    { lat: 48.2632278, lng: 19.8572819 },
    { lat: 48.263615, lng: 19.8567269 },
    { lat: 48.2632746, lng: 19.8564408 },
    { lat: 48.2634263, lng: 19.8560148 },
    { lat: 48.2634086, lng: 19.855634 },
    { lat: 48.2636209, lng: 19.8551512 },
    { lat: 48.2643968, lng: 19.8543394 },
    { lat: 48.2644852, lng: 19.8539358 },
    { lat: 48.2645094, lng: 19.853198 },
    { lat: 48.2648557, lng: 19.8521982 },
    { lat: 48.2657532, lng: 19.8514095 },
    { lat: 48.2680591, lng: 19.8487613 },
    { lat: 48.2684228, lng: 19.848857 },
    { lat: 48.268662, lng: 19.8490211 },
    { lat: 48.2691683, lng: 19.8490583 },
    { lat: 48.2708326, lng: 19.8484422 },
    { lat: 48.2714232, lng: 19.8479291 },
    { lat: 48.2714409, lng: 19.8472243 },
    { lat: 48.2715617, lng: 19.846583 },
    { lat: 48.2721343, lng: 19.8464327 },
    { lat: 48.2727129, lng: 19.8461672 },
    { lat: 48.273273, lng: 19.8462455 },
    { lat: 48.2736405, lng: 19.8462255 },
    { lat: 48.2737339, lng: 19.8448248 },
    { lat: 48.2743652, lng: 19.8448586 },
    { lat: 48.2748931, lng: 19.8445927 },
    { lat: 48.275313, lng: 19.8446603 },
    { lat: 48.2757247, lng: 19.8445408 },
    { lat: 48.2759046, lng: 19.8445363 },
    { lat: 48.2760919, lng: 19.844668 },
    { lat: 48.2763783, lng: 19.8447012 },
    { lat: 48.2770162, lng: 19.8478365 },
    { lat: 48.2772715, lng: 19.8478271 },
    { lat: 48.2773347, lng: 19.847626 },
    { lat: 48.2772707, lng: 19.8473967 },
    { lat: 48.2775566, lng: 19.8460185 },
    { lat: 48.2784154, lng: 19.8441344 },
    { lat: 48.2786856, lng: 19.842939 },
    { lat: 48.2788258, lng: 19.8428272 },
    { lat: 48.2794433, lng: 19.8423348 },
    { lat: 48.27954, lng: 19.8419158 },
    { lat: 48.2801128, lng: 19.8414894 },
    { lat: 48.281839, lng: 19.8378193 },
    { lat: 48.2832121, lng: 19.8357735 },
    { lat: 48.2835809, lng: 19.8350321 },
    { lat: 48.2840145, lng: 19.8343045 },
    { lat: 48.2842207, lng: 19.8341506 },
    { lat: 48.2843178, lng: 19.8339228 },
    { lat: 48.2845429, lng: 19.8337865 },
    { lat: 48.2846659, lng: 19.8337426 },
    { lat: 48.2848171, lng: 19.8338269 },
    { lat: 48.2849761, lng: 19.8338283 },
    { lat: 48.2850161, lng: 19.8337388 },
    { lat: 48.28474, lng: 19.8330102 },
    { lat: 48.2848098, lng: 19.8326465 },
    { lat: 48.2849641, lng: 19.8324177 },
    { lat: 48.2851449, lng: 19.8323519 },
    { lat: 48.2853148, lng: 19.8325273 },
    { lat: 48.2854856, lng: 19.8324123 },
    { lat: 48.2857523, lng: 19.8324031 },
    { lat: 48.2861916, lng: 19.8326636 },
    { lat: 48.2862629, lng: 19.8329753 },
    { lat: 48.2864427, lng: 19.8330455 },
    { lat: 48.286564, lng: 19.833347 },
    { lat: 48.2874796, lng: 19.8327462 },
    { lat: 48.2879939, lng: 19.8326311 },
    { lat: 48.2886478, lng: 19.8323049 },
    { lat: 48.2896273, lng: 19.8317193 },
    { lat: 48.2897722, lng: 19.8315781 },
    { lat: 48.2906356, lng: 19.830712 },
    { lat: 48.2915249, lng: 19.8296071 },
    { lat: 48.2923028, lng: 19.8287426 },
    { lat: 48.2924335, lng: 19.8285883 },
    { lat: 48.2924952, lng: 19.8282238 },
    { lat: 48.2924168, lng: 19.8280012 },
    { lat: 48.2924284, lng: 19.8276983 },
    { lat: 48.2921944, lng: 19.8275259 },
    { lat: 48.2921638, lng: 19.8272144 },
    { lat: 48.2921823, lng: 19.826372 },
    { lat: 48.2921137, lng: 19.8261148 },
    { lat: 48.2916752, lng: 19.8252108 },
    { lat: 48.2913671, lng: 19.8242918 },
    { lat: 48.2912238, lng: 19.824083 },
    { lat: 48.2910945, lng: 19.8241922 },
    { lat: 48.2910058, lng: 19.8240683 },
    { lat: 48.2909679, lng: 19.8237214 },
    { lat: 48.2908145, lng: 19.8234846 },
    { lat: 48.2912075, lng: 19.8230653 },
    { lat: 48.2915204, lng: 19.8229823 },
    { lat: 48.2915189, lng: 19.8228437 },
    { lat: 48.2914411, lng: 19.8226363 },
    { lat: 48.2911032, lng: 19.8225385 },
    { lat: 48.2911178, lng: 19.8224299 },
    { lat: 48.2912938, lng: 19.822362 },
    { lat: 48.2915183, lng: 19.8223945 },
    { lat: 48.2916384, lng: 19.822122 },
    { lat: 48.2916007, lng: 19.8218812 },
    { lat: 48.2916746, lng: 19.8216118 },
    { lat: 48.2915634, lng: 19.8214373 },
    { lat: 48.2914859, lng: 19.8211881 },
    { lat: 48.2915899, lng: 19.8210657 },
    { lat: 48.2916143, lng: 19.820901 },
    { lat: 48.2915612, lng: 19.8207983 },
    { lat: 48.291454, lng: 19.8198377 },
    { lat: 48.2926798, lng: 19.8179604 },
    { lat: 48.2939874, lng: 19.8146289 },
    { lat: 48.2940169, lng: 19.8145528 },
    { lat: 48.2940346, lng: 19.8091481 },
    { lat: 48.2940362, lng: 19.8088996 },
    { lat: 48.2939192, lng: 19.8088248 },
    { lat: 48.293997, lng: 19.8085472 },
    { lat: 48.2939064, lng: 19.8086347 },
    { lat: 48.2938369, lng: 19.8086992 },
  ],
  Boľkovce: [
    { lat: 48.3360301, lng: 19.7116943 },
    { lat: 48.3356884, lng: 19.7131051 },
    { lat: 48.3352119, lng: 19.7145166 },
    { lat: 48.3347384, lng: 19.7157264 },
    { lat: 48.3332943, lng: 19.7161914 },
    { lat: 48.331328, lng: 19.7171224 },
    { lat: 48.3296092, lng: 19.7110258 },
    { lat: 48.3293676, lng: 19.7100041 },
    { lat: 48.3292399, lng: 19.7090899 },
    { lat: 48.3290957, lng: 19.7092181 },
    { lat: 48.3287516, lng: 19.7099619 },
    { lat: 48.3283808, lng: 19.7110698 },
    { lat: 48.3276402, lng: 19.7127046 },
    { lat: 48.3249559, lng: 19.7162285 },
    { lat: 48.324518, lng: 19.7166424 },
    { lat: 48.3241523, lng: 19.7171305 },
    { lat: 48.3235002, lng: 19.7182471 },
    { lat: 48.3226277, lng: 19.7201883 },
    { lat: 48.3222547, lng: 19.7213246 },
    { lat: 48.3219238, lng: 19.7225713 },
    { lat: 48.3218165, lng: 19.7228171 },
    { lat: 48.3215857, lng: 19.7231217 },
    { lat: 48.3206924, lng: 19.723915 },
    { lat: 48.318908, lng: 19.7257674 },
    { lat: 48.3220612, lng: 19.7286879 },
    { lat: 48.3244809, lng: 19.7307268 },
    { lat: 48.3263641, lng: 19.7315772 },
    { lat: 48.3281968, lng: 19.7321204 },
    { lat: 48.3287698, lng: 19.7329203 },
    { lat: 48.3315763, lng: 19.7355517 },
    { lat: 48.3317007, lng: 19.7375392 },
    { lat: 48.3317126, lng: 19.7413848 },
    { lat: 48.3312815, lng: 19.7416722 },
    { lat: 48.3309066, lng: 19.7438118 },
    { lat: 48.3308165, lng: 19.7439422 },
    { lat: 48.3304288, lng: 19.745491 },
    { lat: 48.3300392, lng: 19.7457243 },
    { lat: 48.3297524, lng: 19.7461396 },
    { lat: 48.3294729, lng: 19.7467208 },
    { lat: 48.3293159, lng: 19.747297 },
    { lat: 48.3292304, lng: 19.7480908 },
    { lat: 48.3289821, lng: 19.7521714 },
    { lat: 48.329051, lng: 19.7528369 },
    { lat: 48.3291734, lng: 19.7533141 },
    { lat: 48.3297883, lng: 19.7549318 },
    { lat: 48.330735, lng: 19.7573834 },
    { lat: 48.3316833, lng: 19.7588013 },
    { lat: 48.3318388, lng: 19.759209 },
    { lat: 48.3319273, lng: 19.759807 },
    { lat: 48.3319068, lng: 19.760212 },
    { lat: 48.3316351, lng: 19.7610467 },
    { lat: 48.3305496, lng: 19.7628964 },
    { lat: 48.3304612, lng: 19.7633976 },
    { lat: 48.3304898, lng: 19.7638632 },
    { lat: 48.330603, lng: 19.7642075 },
    { lat: 48.331564, lng: 19.765762 },
    { lat: 48.331741, lng: 19.7662741 },
    { lat: 48.3317787, lng: 19.76677 },
    { lat: 48.3317059, lng: 19.7672375 },
    { lat: 48.331472, lng: 19.7677108 },
    { lat: 48.3309502, lng: 19.7682926 },
    { lat: 48.3307629, lng: 19.7686713 },
    { lat: 48.3306099, lng: 19.7695689 },
    { lat: 48.3306063, lng: 19.7695898 },
    { lat: 48.3303887, lng: 19.7706276 },
    { lat: 48.3298029, lng: 19.7719125 },
    { lat: 48.3297276, lng: 19.7718102 },
    { lat: 48.3295569, lng: 19.7720039 },
    { lat: 48.3295246, lng: 19.7742083 },
    { lat: 48.3318793, lng: 19.7748971 },
    { lat: 48.3324732, lng: 19.7748463 },
    { lat: 48.3331207, lng: 19.774661 },
    { lat: 48.3354315, lng: 19.7732282 },
    { lat: 48.3361928, lng: 19.7729154 },
    { lat: 48.3367416, lng: 19.7730462 },
    { lat: 48.3385292, lng: 19.7739043 },
    { lat: 48.3395974, lng: 19.7747208 },
    { lat: 48.3401914, lng: 19.7750837 },
    { lat: 48.340637, lng: 19.7751227 },
    { lat: 48.3413996, lng: 19.7748402 },
    { lat: 48.3418078, lng: 19.7745667 },
    { lat: 48.3434629, lng: 19.7722655 },
    { lat: 48.3437117, lng: 19.7720071 },
    { lat: 48.3441618, lng: 19.7718388 },
    { lat: 48.3465474, lng: 19.772231 },
    { lat: 48.3467163, lng: 19.7722277 },
    { lat: 48.3471051, lng: 19.7719479 },
    { lat: 48.3471483, lng: 19.7718827 },
    { lat: 48.3472542, lng: 19.771727 },
    { lat: 48.3489224, lng: 19.7685039 },
    { lat: 48.3490508, lng: 19.7683474 },
    { lat: 48.3496119, lng: 19.7683228 },
    { lat: 48.3511867, lng: 19.7685177 },
    { lat: 48.3517562, lng: 19.7685285 },
    { lat: 48.3522461, lng: 19.768399 },
    { lat: 48.3536205, lng: 19.7676529 },
    { lat: 48.3537343, lng: 19.7675027 },
    { lat: 48.3539786, lng: 19.7668861 },
    { lat: 48.3539752, lng: 19.7662701 },
    { lat: 48.3542576, lng: 19.7655481 },
    { lat: 48.3531386, lng: 19.762382 },
    { lat: 48.3519696, lng: 19.7597948 },
    { lat: 48.3525089, lng: 19.7592502 },
    { lat: 48.3511991, lng: 19.7559758 },
    { lat: 48.3531214, lng: 19.7540178 },
    { lat: 48.3530813, lng: 19.7538737 },
    { lat: 48.3514858, lng: 19.7516087 },
    { lat: 48.3511882, lng: 19.750055 },
    { lat: 48.3510742, lng: 19.7496686 },
    { lat: 48.3502989, lng: 19.7476549 },
    { lat: 48.3500136, lng: 19.7467915 },
    { lat: 48.3499944, lng: 19.7465596 },
    { lat: 48.3501979, lng: 19.7448175 },
    { lat: 48.3504666, lng: 19.7433872 },
    { lat: 48.3516366, lng: 19.7415166 },
    { lat: 48.3521695, lng: 19.7400964 },
    { lat: 48.3524275, lng: 19.7391787 },
    { lat: 48.3524467, lng: 19.7389518 },
    { lat: 48.3523196, lng: 19.7376907 },
    { lat: 48.3522515, lng: 19.7378049 },
    { lat: 48.3521278, lng: 19.7380131 },
    { lat: 48.3515488, lng: 19.7360862 },
    { lat: 48.3511871, lng: 19.7344876 },
    { lat: 48.3509471, lng: 19.7332945 },
    { lat: 48.3505518, lng: 19.7305917 },
    { lat: 48.350189, lng: 19.7288675 },
    { lat: 48.3495389, lng: 19.7251999 },
    { lat: 48.3494957, lng: 19.7242301 },
    { lat: 48.3493084, lng: 19.7240689 },
    { lat: 48.3491103, lng: 19.7228869 },
    { lat: 48.3488668, lng: 19.7222482 },
    { lat: 48.3484919, lng: 19.7207397 },
    { lat: 48.3483403, lng: 19.7195637 },
    { lat: 48.3479111, lng: 19.7187797 },
    { lat: 48.3478652, lng: 19.7185597 },
    { lat: 48.3476389, lng: 19.7180341 },
    { lat: 48.3472688, lng: 19.7174921 },
    { lat: 48.3462677, lng: 19.7169249 },
    { lat: 48.3442401, lng: 19.7159255 },
    { lat: 48.3427996, lng: 19.7149265 },
    { lat: 48.3409091, lng: 19.713848 },
    { lat: 48.3394861, lng: 19.713267 },
    { lat: 48.3384998, lng: 19.7128047 },
    { lat: 48.337952, lng: 19.7124705 },
    { lat: 48.3365189, lng: 19.7118421 },
    { lat: 48.3360301, lng: 19.7116943 },
  ],
  Kotmanová: [
    { lat: 48.536962, lng: 19.576146 },
    { lat: 48.536688, lng: 19.575604 },
    { lat: 48.536453, lng: 19.57492 },
    { lat: 48.536182, lng: 19.57417 },
    { lat: 48.536, lng: 19.573631 },
    { lat: 48.535949, lng: 19.573026 },
    { lat: 48.536224, lng: 19.572143 },
    { lat: 48.536377, lng: 19.571752 },
    { lat: 48.53645, lng: 19.571005 },
    { lat: 48.536532, lng: 19.569639 },
    { lat: 48.536442, lng: 19.569108 },
    { lat: 48.5357284, lng: 19.5685535 },
    { lat: 48.5356386, lng: 19.5681649 },
    { lat: 48.5350177, lng: 19.568091 },
    { lat: 48.5332121, lng: 19.567469 },
    { lat: 48.5329425, lng: 19.567733 },
    { lat: 48.5320258, lng: 19.5675998 },
    { lat: 48.5311458, lng: 19.5665633 },
    { lat: 48.5310894, lng: 19.5659245 },
    { lat: 48.5298067, lng: 19.5651762 },
    { lat: 48.5295878, lng: 19.5658367 },
    { lat: 48.5288045, lng: 19.5666187 },
    { lat: 48.5279923, lng: 19.5669212 },
    { lat: 48.5275058, lng: 19.5676436 },
    { lat: 48.5272469, lng: 19.5674041 },
    { lat: 48.5263699, lng: 19.5684098 },
    { lat: 48.5259659, lng: 19.5690105 },
    { lat: 48.5257012, lng: 19.5686433 },
    { lat: 48.5259755, lng: 19.5681357 },
    { lat: 48.5261127, lng: 19.5677389 },
    { lat: 48.5256876, lng: 19.5669159 },
    { lat: 48.5251098, lng: 19.5676396 },
    { lat: 48.5240639, lng: 19.5686178 },
    { lat: 48.523949, lng: 19.5678832 },
    { lat: 48.523686, lng: 19.5680698 },
    { lat: 48.5236336, lng: 19.5683027 },
    { lat: 48.5235479, lng: 19.5682302 },
    { lat: 48.5230348, lng: 19.5688358 },
    { lat: 48.5230415, lng: 19.5691834 },
    { lat: 48.5224953, lng: 19.5699773 },
    { lat: 48.5222132, lng: 19.5698462 },
    { lat: 48.5217747, lng: 19.5705581 },
    { lat: 48.5215095, lng: 19.5713644 },
    { lat: 48.5203655, lng: 19.5737728 },
    { lat: 48.5195878, lng: 19.574582 },
    { lat: 48.5193693, lng: 19.5741159 },
    { lat: 48.519212, lng: 19.5736345 },
    { lat: 48.5184241, lng: 19.5746182 },
    { lat: 48.5179416, lng: 19.5736751 },
    { lat: 48.5178686, lng: 19.5731882 },
    { lat: 48.5181539, lng: 19.5726479 },
    { lat: 48.517764, lng: 19.5718359 },
    { lat: 48.5172244, lng: 19.5716185 },
    { lat: 48.5172554, lng: 19.5713089 },
    { lat: 48.5174345, lng: 19.5709146 },
    { lat: 48.5176709, lng: 19.5705631 },
    { lat: 48.5182695, lng: 19.5705424 },
    { lat: 48.5183495, lng: 19.5704438 },
    { lat: 48.5183461, lng: 19.5698275 },
    { lat: 48.5173591, lng: 19.5698803 },
    { lat: 48.516923, lng: 19.5697563 },
    { lat: 48.5164915, lng: 19.5693891 },
    { lat: 48.5164562, lng: 19.5696746 },
    { lat: 48.5149664, lng: 19.5694529 },
    { lat: 48.5149428, lng: 19.5696291 },
    { lat: 48.514249, lng: 19.569437 },
    { lat: 48.5138342, lng: 19.5694337 },
    { lat: 48.5132336, lng: 19.5695869 },
    { lat: 48.5124665, lng: 19.5700591 },
    { lat: 48.5123158, lng: 19.5698928 },
    { lat: 48.5125757, lng: 19.5694671 },
    { lat: 48.5129589, lng: 19.5691008 },
    { lat: 48.5132119, lng: 19.5683229 },
    { lat: 48.5125893, lng: 19.5673482 },
    { lat: 48.5123108, lng: 19.5666289 },
    { lat: 48.5109104, lng: 19.567277 },
    { lat: 48.5108605, lng: 19.5669243 },
    { lat: 48.5102774, lng: 19.5674592 },
    { lat: 48.5098607, lng: 19.568082 },
    { lat: 48.5099786, lng: 19.5684141 },
    { lat: 48.5098264, lng: 19.5687092 },
    { lat: 48.5099326, lng: 19.5690852 },
    { lat: 48.5097667, lng: 19.569671 },
    { lat: 48.5095867, lng: 19.5699491 },
    { lat: 48.509087, lng: 19.5704071 },
    { lat: 48.5089089, lng: 19.5690214 },
    { lat: 48.5091911, lng: 19.5688358 },
    { lat: 48.5089388, lng: 19.5681747 },
    { lat: 48.5082521, lng: 19.5687313 },
    { lat: 48.5081542, lng: 19.5684386 },
    { lat: 48.5071974, lng: 19.5690527 },
    { lat: 48.5070456, lng: 19.5700713 },
    { lat: 48.5068542, lng: 19.5700737 },
    { lat: 48.5054543, lng: 19.5694277 },
    { lat: 48.5058912, lng: 19.5687307 },
    { lat: 48.5061739, lng: 19.568459 },
    { lat: 48.5061947, lng: 19.5680969 },
    { lat: 48.5054981, lng: 19.5681897 },
    { lat: 48.5043788, lng: 19.5684791 },
    { lat: 48.5043975, lng: 19.5689394 },
    { lat: 48.5035332, lng: 19.5694813 },
    { lat: 48.5033967, lng: 19.5688878 },
    { lat: 48.5025628, lng: 19.5690094 },
    { lat: 48.5022081, lng: 19.5699274 },
    { lat: 48.5016945, lng: 19.5697365 },
    { lat: 48.5013065, lng: 19.569508 },
    { lat: 48.500845, lng: 19.5690996 },
    { lat: 48.5005155, lng: 19.56889 },
    { lat: 48.5000402, lng: 19.5681838 },
    { lat: 48.4995337, lng: 19.5669412 },
    { lat: 48.4996115, lng: 19.5668915 },
    { lat: 48.499247, lng: 19.5658222 },
    { lat: 48.4989435, lng: 19.5653246 },
    { lat: 48.4982136, lng: 19.5657228 },
    { lat: 48.4975416, lng: 19.5652745 },
    { lat: 48.4976159, lng: 19.5651222 },
    { lat: 48.4974419, lng: 19.5649171 },
    { lat: 48.4971629, lng: 19.5652238 },
    { lat: 48.4969026, lng: 19.565027 },
    { lat: 48.4966042, lng: 19.5651062 },
    { lat: 48.4964523, lng: 19.5647898 },
    { lat: 48.4962239, lng: 19.5634165 },
    { lat: 48.4958515, lng: 19.5630866 },
    { lat: 48.4953077, lng: 19.5630521 },
    { lat: 48.4947192, lng: 19.5625042 },
    { lat: 48.4940577, lng: 19.5624303 },
    { lat: 48.4931663, lng: 19.5629935 },
    { lat: 48.4917005, lng: 19.5643253 },
    { lat: 48.4914278, lng: 19.564771 },
    { lat: 48.4897056, lng: 19.5654557 },
    { lat: 48.4881191, lng: 19.5680886 },
    { lat: 48.4876638, lng: 19.5686807 },
    { lat: 48.4871041, lng: 19.5692331 },
    { lat: 48.4865472, lng: 19.5699636 },
    { lat: 48.48622, lng: 19.5698039 },
    { lat: 48.4859975, lng: 19.5702372 },
    { lat: 48.4855646, lng: 19.571391 },
    { lat: 48.4836065, lng: 19.5758116 },
    { lat: 48.4830127, lng: 19.5750998 },
    { lat: 48.4819033, lng: 19.5734365 },
    { lat: 48.4816362, lng: 19.5733849 },
    { lat: 48.4806692, lng: 19.5741491 },
    { lat: 48.4806297, lng: 19.5740744 },
    { lat: 48.479781, lng: 19.5752763 },
    { lat: 48.4808058, lng: 19.5755823 },
    { lat: 48.4755141, lng: 19.5859513 },
    { lat: 48.4755378, lng: 19.5860455 },
    { lat: 48.474882, lng: 19.5865418 },
    { lat: 48.4745042, lng: 19.587008 },
    { lat: 48.4741104, lng: 19.587315 },
    { lat: 48.4738723, lng: 19.5876027 },
    { lat: 48.473702, lng: 19.588046 },
    { lat: 48.4735152, lng: 19.5882482 },
    { lat: 48.4730958, lng: 19.5885645 },
    { lat: 48.4718808, lng: 19.5893028 },
    { lat: 48.4705036, lng: 19.5883834 },
    { lat: 48.4699562, lng: 19.5882179 },
    { lat: 48.4695323, lng: 19.587963 },
    { lat: 48.469217, lng: 19.5878947 },
    { lat: 48.4691931, lng: 19.587965 },
    { lat: 48.469061, lng: 19.5879854 },
    { lat: 48.4680761, lng: 19.5876385 },
    { lat: 48.4673743, lng: 19.5879378 },
    { lat: 48.4670556, lng: 19.5879919 },
    { lat: 48.4663155, lng: 19.588668 },
    { lat: 48.4655928, lng: 19.5894466 },
    { lat: 48.4649831, lng: 19.5895468 },
    { lat: 48.4651881, lng: 19.5899858 },
    { lat: 48.4697486, lng: 19.5943179 },
    { lat: 48.469711, lng: 19.5943789 },
    { lat: 48.4693577, lng: 19.594492 },
    { lat: 48.4686568, lng: 19.5949634 },
    { lat: 48.4680744, lng: 19.5949662 },
    { lat: 48.4677214, lng: 19.595086 },
    { lat: 48.4676114, lng: 19.5952163 },
    { lat: 48.4672559, lng: 19.5953282 },
    { lat: 48.4668873, lng: 19.5952046 },
    { lat: 48.4661893, lng: 19.5954629 },
    { lat: 48.4660029, lng: 19.5957001 },
    { lat: 48.4659606, lng: 19.5959363 },
    { lat: 48.4657906, lng: 19.5958917 },
    { lat: 48.4656584, lng: 19.5959791 },
    { lat: 48.4654087, lng: 19.5962882 },
    { lat: 48.465195, lng: 19.5962719 },
    { lat: 48.4651104, lng: 19.5964435 },
    { lat: 48.4648545, lng: 19.5965643 },
    { lat: 48.4645645, lng: 19.5968012 },
    { lat: 48.4643867, lng: 19.5968316 },
    { lat: 48.464216, lng: 19.597217 },
    { lat: 48.4640378, lng: 19.5970681 },
    { lat: 48.4639452, lng: 19.5970617 },
    { lat: 48.4638133, lng: 19.5971832 },
    { lat: 48.463732, lng: 19.597156 },
    { lat: 48.4636918, lng: 19.5970416 },
    { lat: 48.4633061, lng: 19.5970327 },
    { lat: 48.4632539, lng: 19.596825 },
    { lat: 48.4631662, lng: 19.5967091 },
    { lat: 48.462879, lng: 19.596761 },
    { lat: 48.462759, lng: 19.5968469 },
    { lat: 48.4626172, lng: 19.5970727 },
    { lat: 48.4624119, lng: 19.5969192 },
    { lat: 48.4622911, lng: 19.596917 },
    { lat: 48.4620456, lng: 19.5973378 },
    { lat: 48.4618102, lng: 19.5973187 },
    { lat: 48.4616641, lng: 19.5973771 },
    { lat: 48.4612258, lng: 19.5979477 },
    { lat: 48.4604802, lng: 19.5978547 },
    { lat: 48.460331, lng: 19.598086 },
    { lat: 48.460343, lng: 19.59811 },
    { lat: 48.460601, lng: 19.598631 },
    { lat: 48.460623, lng: 19.598682 },
    { lat: 48.460933, lng: 19.599519 },
    { lat: 48.461073, lng: 19.600605 },
    { lat: 48.461596, lng: 19.60176 },
    { lat: 48.46187, lng: 19.602079 },
    { lat: 48.461903, lng: 19.602128 },
    { lat: 48.463229, lng: 19.603979 },
    { lat: 48.463791, lng: 19.604545 },
    { lat: 48.464205, lng: 19.605055 },
    { lat: 48.464621, lng: 19.605842 },
    { lat: 48.464961, lng: 19.606674 },
    { lat: 48.465189, lng: 19.607042 },
    { lat: 48.465381, lng: 19.607287 },
    { lat: 48.465917, lng: 19.608353 },
    { lat: 48.466202, lng: 19.608662 },
    { lat: 48.466761, lng: 19.60921 },
    { lat: 48.466858, lng: 19.609372 },
    { lat: 48.467817, lng: 19.611004 },
    { lat: 48.468141, lng: 19.61165 },
    { lat: 48.468464, lng: 19.61236 },
    { lat: 48.469019, lng: 19.612329 },
    { lat: 48.469327, lng: 19.612122 },
    { lat: 48.469894, lng: 19.612294 },
    { lat: 48.470332, lng: 19.612182 },
    { lat: 48.470866, lng: 19.61176 },
    { lat: 48.471344, lng: 19.61143 },
    { lat: 48.47154, lng: 19.611045 },
    { lat: 48.471814, lng: 19.610797 },
    { lat: 48.472388, lng: 19.610683 },
    { lat: 48.473005, lng: 19.610535 },
    { lat: 48.473558, lng: 19.610636 },
    { lat: 48.474119, lng: 19.609857 },
    { lat: 48.474231, lng: 19.609477 },
    { lat: 48.474424, lng: 19.609036 },
    { lat: 48.47464, lng: 19.608849 },
    { lat: 48.475668, lng: 19.608714 },
    { lat: 48.476429, lng: 19.608461 },
    { lat: 48.476905, lng: 19.607968 },
    { lat: 48.478304, lng: 19.606363 },
    { lat: 48.478618, lng: 19.606269 },
    { lat: 48.479357, lng: 19.605844 },
    { lat: 48.479624, lng: 19.605635 },
    { lat: 48.480603, lng: 19.605106 },
    { lat: 48.480807, lng: 19.604903 },
    { lat: 48.481333, lng: 19.604407 },
    { lat: 48.482163, lng: 19.603938 },
    { lat: 48.482329, lng: 19.603887 },
    { lat: 48.482836, lng: 19.603728 },
    { lat: 48.483323, lng: 19.603546 },
    { lat: 48.483434, lng: 19.603041 },
    { lat: 48.483526, lng: 19.602935 },
    { lat: 48.483684, lng: 19.60272 },
    { lat: 48.483854, lng: 19.602385 },
    { lat: 48.484185, lng: 19.602098 },
    { lat: 48.484673, lng: 19.601636 },
    { lat: 48.484703, lng: 19.601609 },
    { lat: 48.485097, lng: 19.601254 },
    { lat: 48.485226, lng: 19.601137 },
    { lat: 48.485633, lng: 19.600655 },
    { lat: 48.48601, lng: 19.600262 },
    { lat: 48.486492, lng: 19.600474 },
    { lat: 48.487265, lng: 19.60059 },
    { lat: 48.487421, lng: 19.60062 },
    { lat: 48.487625, lng: 19.600597 },
    { lat: 48.487958, lng: 19.600801 },
    { lat: 48.488166, lng: 19.600868 },
    { lat: 48.489279, lng: 19.602086 },
    { lat: 48.489519, lng: 19.602285 },
    { lat: 48.489825, lng: 19.602356 },
    { lat: 48.48987, lng: 19.602296 },
    { lat: 48.490289, lng: 19.602447 },
    { lat: 48.490876, lng: 19.603055 },
    { lat: 48.490978, lng: 19.602902 },
    { lat: 48.491193, lng: 19.602935 },
    { lat: 48.491711, lng: 19.602807 },
    { lat: 48.492087, lng: 19.602709 },
    { lat: 48.492496, lng: 19.60278 },
    { lat: 48.492584, lng: 19.602893 },
    { lat: 48.492876, lng: 19.603138 },
    { lat: 48.492925, lng: 19.603278 },
    { lat: 48.492999, lng: 19.603503 },
    { lat: 48.493308, lng: 19.603712 },
    { lat: 48.493985, lng: 19.604556 },
    { lat: 48.494539, lng: 19.605166 },
    { lat: 48.495279, lng: 19.605684 },
    { lat: 48.496282, lng: 19.606019 },
    { lat: 48.496429, lng: 19.606143 },
    { lat: 48.496558, lng: 19.606373 },
    { lat: 48.496699, lng: 19.606429 },
    { lat: 48.496884, lng: 19.606551 },
    { lat: 48.497143, lng: 19.606742 },
    { lat: 48.497335, lng: 19.606965 },
    { lat: 48.49751, lng: 19.60693 },
    { lat: 48.498106, lng: 19.607876 },
    { lat: 48.498419, lng: 19.608408 },
    { lat: 48.498749, lng: 19.608856 },
    { lat: 48.49896, lng: 19.60928 },
    { lat: 48.499577, lng: 19.609502 },
    { lat: 48.500037, lng: 19.60903 },
    { lat: 48.50035, lng: 19.608849 },
    { lat: 48.500968, lng: 19.608241 },
    { lat: 48.501397, lng: 19.608129 },
    { lat: 48.50142, lng: 19.608122 },
    { lat: 48.501448, lng: 19.608115 },
    { lat: 48.50156, lng: 19.608145 },
    { lat: 48.502092, lng: 19.608053 },
    { lat: 48.502871, lng: 19.607846 },
    { lat: 48.503341, lng: 19.607864 },
    { lat: 48.503947, lng: 19.607928 },
    { lat: 48.504366, lng: 19.608209 },
    { lat: 48.504539, lng: 19.60838 },
    { lat: 48.504864, lng: 19.608275 },
    { lat: 48.505055, lng: 19.608329 },
    { lat: 48.505358, lng: 19.608216 },
    { lat: 48.505826, lng: 19.608042 },
    { lat: 48.506399, lng: 19.607877 },
    { lat: 48.506462, lng: 19.607738 },
    { lat: 48.50698, lng: 19.607944 },
    { lat: 48.507261, lng: 19.607829 },
    { lat: 48.507295, lng: 19.60777 },
    { lat: 48.507665, lng: 19.607157 },
    { lat: 48.50788, lng: 19.606218 },
    { lat: 48.507909, lng: 19.60536 },
    { lat: 48.508004, lng: 19.604501 },
    { lat: 48.508094, lng: 19.604142 },
    { lat: 48.508292, lng: 19.603596 },
    { lat: 48.509124, lng: 19.602283 },
    { lat: 48.509552, lng: 19.601381 },
    { lat: 48.509866, lng: 19.600945 },
    { lat: 48.510261, lng: 19.60049 },
    { lat: 48.510567, lng: 19.599889 },
    { lat: 48.510777, lng: 19.599562 },
    { lat: 48.511084, lng: 19.599266 },
    { lat: 48.511111, lng: 19.59924 },
    { lat: 48.511272, lng: 19.599084 },
    { lat: 48.511797, lng: 19.598621 },
    { lat: 48.512149, lng: 19.597943 },
    { lat: 48.51236, lng: 19.597444 },
    { lat: 48.512937, lng: 19.596946 },
    { lat: 48.514034, lng: 19.59549 },
    { lat: 48.514289, lng: 19.595167 },
    { lat: 48.514576, lng: 19.594883 },
    { lat: 48.514774, lng: 19.594637 },
    { lat: 48.515683, lng: 19.594668 },
    { lat: 48.516508, lng: 19.594219 },
    { lat: 48.516759, lng: 19.59407 },
    { lat: 48.517614, lng: 19.593696 },
    { lat: 48.518214, lng: 19.59343 },
    { lat: 48.518715, lng: 19.592899 },
    { lat: 48.519253, lng: 19.592565 },
    { lat: 48.520231, lng: 19.59213 },
    { lat: 48.521643, lng: 19.591198 },
    { lat: 48.521993, lng: 19.59097 },
    { lat: 48.522397, lng: 19.590934 },
    { lat: 48.52326, lng: 19.591283 },
    { lat: 48.524469, lng: 19.592267 },
    { lat: 48.525093, lng: 19.592376 },
    { lat: 48.525278, lng: 19.592179 },
    { lat: 48.525315, lng: 19.592142 },
    { lat: 48.525819, lng: 19.592049 },
    { lat: 48.526862, lng: 19.591881 },
    { lat: 48.527248, lng: 19.591692 },
    { lat: 48.527759, lng: 19.5899 },
    { lat: 48.528268, lng: 19.590021 },
    { lat: 48.528496, lng: 19.589262 },
    { lat: 48.528885, lng: 19.588409 },
    { lat: 48.529394, lng: 19.587972 },
    { lat: 48.529436, lng: 19.587936 },
    { lat: 48.529754, lng: 19.587663 },
    { lat: 48.530797, lng: 19.586569 },
    { lat: 48.531685, lng: 19.585207 },
    { lat: 48.531959, lng: 19.584792 },
    { lat: 48.532417, lng: 19.58391 },
    { lat: 48.532853, lng: 19.583625 },
    { lat: 48.532898, lng: 19.583596 },
    { lat: 48.533667, lng: 19.583095 },
    { lat: 48.534206, lng: 19.582113 },
    { lat: 48.534345, lng: 19.581761 },
    { lat: 48.53559, lng: 19.581432 },
    { lat: 48.537111, lng: 19.580469 },
    { lat: 48.537089, lng: 19.580384 },
    { lat: 48.537135, lng: 19.579481 },
    { lat: 48.537135, lng: 19.578946 },
    { lat: 48.537076, lng: 19.578318 },
    { lat: 48.537072, lng: 19.578236 },
    { lat: 48.537029, lng: 19.577199 },
    { lat: 48.536962, lng: 19.576146 },
  ],
  Mučín: [
    { lat: 48.2544236, lng: 19.7135016 },
    { lat: 48.2544302, lng: 19.7134277 },
    { lat: 48.2541, lng: 19.7128693 },
    { lat: 48.2540403, lng: 19.7122371 },
    { lat: 48.253647, lng: 19.711532 },
    { lat: 48.2535801, lng: 19.7110695 },
    { lat: 48.2533867, lng: 19.7109277 },
    { lat: 48.2532816, lng: 19.7103139 },
    { lat: 48.2530893, lng: 19.7101228 },
    { lat: 48.2530792, lng: 19.7093948 },
    { lat: 48.2528624, lng: 19.7084764 },
    { lat: 48.2529337, lng: 19.7081313 },
    { lat: 48.2530846, lng: 19.7080271 },
    { lat: 48.2531489, lng: 19.7079025 },
    { lat: 48.2531028, lng: 19.7076791 },
    { lat: 48.2532632, lng: 19.7075396 },
    { lat: 48.2551208, lng: 19.7041555 },
    { lat: 48.2554827, lng: 19.7036051 },
    { lat: 48.2561592, lng: 19.7031111 },
    { lat: 48.2568725, lng: 19.7020827 },
    { lat: 48.2564714, lng: 19.7001249 },
    { lat: 48.2564721, lng: 19.6991079 },
    { lat: 48.2566138, lng: 19.6975436 },
    { lat: 48.2565504, lng: 19.6966841 },
    { lat: 48.256545, lng: 19.6957112 },
    { lat: 48.2563213, lng: 19.6934867 },
    { lat: 48.2565663, lng: 19.6933443 },
    { lat: 48.2556, lng: 19.6899443 },
    { lat: 48.2555232, lng: 19.6892103 },
    { lat: 48.2550693, lng: 19.6862828 },
    { lat: 48.2554983, lng: 19.68552 },
    { lat: 48.2557479, lng: 19.6842549 },
    { lat: 48.2557006, lng: 19.6836331 },
    { lat: 48.255501, lng: 19.6833269 },
    { lat: 48.2553178, lng: 19.6832386 },
    { lat: 48.2542895, lng: 19.6821713 },
    { lat: 48.2534381, lng: 19.6814531 },
    { lat: 48.2529826, lng: 19.6801927 },
    { lat: 48.253187, lng: 19.6799291 },
    { lat: 48.2531629, lng: 19.6797198 },
    { lat: 48.2530177, lng: 19.6794 },
    { lat: 48.2529169, lng: 19.6793642 },
    { lat: 48.2527472, lng: 19.6790444 },
    { lat: 48.2526556, lng: 19.6786368 },
    { lat: 48.2519477, lng: 19.6778279 },
    { lat: 48.2519317, lng: 19.6777248 },
    { lat: 48.2520475, lng: 19.6773695 },
    { lat: 48.2524346, lng: 19.6767322 },
    { lat: 48.2527637, lng: 19.6760604 },
    { lat: 48.2528669, lng: 19.6758237 },
    { lat: 48.2529308, lng: 19.6755139 },
    { lat: 48.2530513, lng: 19.6755887 },
    { lat: 48.2529857, lng: 19.6749077 },
    { lat: 48.2523281, lng: 19.674367 },
    { lat: 48.2515929, lng: 19.6746046 },
    { lat: 48.2506895, lng: 19.6747623 },
    { lat: 48.2499494, lng: 19.6748169 },
    { lat: 48.2485301, lng: 19.6762005 },
    { lat: 48.2480613, lng: 19.6761785 },
    { lat: 48.2474281, lng: 19.6765054 },
    { lat: 48.246739, lng: 19.6762402 },
    { lat: 48.2459135, lng: 19.6757721 },
    { lat: 48.2453655, lng: 19.6752137 },
    { lat: 48.2452411, lng: 19.675157 },
    { lat: 48.2445409, lng: 19.6748438 },
    { lat: 48.2440017, lng: 19.6747038 },
    { lat: 48.2433628, lng: 19.6746538 },
    { lat: 48.2428569, lng: 19.6748279 },
    { lat: 48.2426984, lng: 19.6750429 },
    { lat: 48.2424913, lng: 19.6755181 },
    { lat: 48.2420109, lng: 19.6763584 },
    { lat: 48.2412878, lng: 19.6768446 },
    { lat: 48.2400223, lng: 19.6782667 },
    { lat: 48.2397596, lng: 19.6784643 },
    { lat: 48.2390387, lng: 19.6786668 },
    { lat: 48.2383207, lng: 19.6777131 },
    { lat: 48.2378138, lng: 19.6761172 },
    { lat: 48.2373769, lng: 19.6744735 },
    { lat: 48.2371919, lng: 19.6735648 },
    { lat: 48.2370931, lng: 19.672678 },
    { lat: 48.2369074, lng: 19.6719574 },
    { lat: 48.2367098, lng: 19.6715242 },
    { lat: 48.235362, lng: 19.670507 },
    { lat: 48.235255, lng: 19.67049 },
    { lat: 48.23483, lng: 19.67048 },
    { lat: 48.234376, lng: 19.670623 },
    { lat: 48.234008, lng: 19.671206 },
    { lat: 48.233545, lng: 19.671854 },
    { lat: 48.232948, lng: 19.672739 },
    { lat: 48.232225, lng: 19.673768 },
    { lat: 48.231564, lng: 19.6758535 },
    { lat: 48.2314117, lng: 19.6772758 },
    { lat: 48.23153, lng: 19.6795239 },
    { lat: 48.2311579, lng: 19.6821487 },
    { lat: 48.2310517, lng: 19.6844202 },
    { lat: 48.2323794, lng: 19.6863058 },
    { lat: 48.2329927, lng: 19.6891718 },
    { lat: 48.233037, lng: 19.6900579 },
    { lat: 48.2329988, lng: 19.6907895 },
    { lat: 48.2328898, lng: 19.6914966 },
    { lat: 48.2309987, lng: 19.6940982 },
    { lat: 48.2294969, lng: 19.6949843 },
    { lat: 48.2288847, lng: 19.6963796 },
    { lat: 48.2285871, lng: 19.6979813 },
    { lat: 48.2280991, lng: 19.6992608 },
    { lat: 48.2277426, lng: 19.7019898 },
    { lat: 48.2276766, lng: 19.7037887 },
    { lat: 48.2277069, lng: 19.7042599 },
    { lat: 48.2278714, lng: 19.7049159 },
    { lat: 48.2281342, lng: 19.7052754 },
    { lat: 48.2282224, lng: 19.7054835 },
    { lat: 48.2282977, lng: 19.7060325 },
    { lat: 48.227681, lng: 19.7079609 },
    { lat: 48.2275617, lng: 19.7084676 },
    { lat: 48.2275566, lng: 19.7088328 },
    { lat: 48.2273522, lng: 19.7096989 },
    { lat: 48.2273382, lng: 19.7106216 },
    { lat: 48.2274833, lng: 19.711446 },
    { lat: 48.2276441, lng: 19.7120698 },
    { lat: 48.2274077, lng: 19.7124539 },
    { lat: 48.2270757, lng: 19.7135922 },
    { lat: 48.2265078, lng: 19.7137194 },
    { lat: 48.2253895, lng: 19.7142127 },
    { lat: 48.2246547, lng: 19.7147153 },
    { lat: 48.2240012, lng: 19.7153917 },
    { lat: 48.2234876, lng: 19.7167575 },
    { lat: 48.2236128, lng: 19.7181753 },
    { lat: 48.224067, lng: 19.7201645 },
    { lat: 48.224539, lng: 19.7206946 },
    { lat: 48.2246989, lng: 19.7212722 },
    { lat: 48.2247054, lng: 19.721597 },
    { lat: 48.2241362, lng: 19.7235864 },
    { lat: 48.2235988, lng: 19.7247228 },
    { lat: 48.2235077, lng: 19.7253075 },
    { lat: 48.2235585, lng: 19.7261638 },
    { lat: 48.2236494, lng: 19.7264611 },
    { lat: 48.2237065, lng: 19.7268182 },
    { lat: 48.2236378, lng: 19.7272821 },
    { lat: 48.2234646, lng: 19.7280763 },
    { lat: 48.2237885, lng: 19.727863 },
    { lat: 48.2238642, lng: 19.7278683 },
    { lat: 48.2245684, lng: 19.727918 },
    { lat: 48.2256705, lng: 19.72878 },
    { lat: 48.2266301, lng: 19.728823 },
    { lat: 48.2271448, lng: 19.728208 },
    { lat: 48.2277231, lng: 19.7277866 },
    { lat: 48.2281213, lng: 19.7272659 },
    { lat: 48.2290714, lng: 19.7265677 },
    { lat: 48.229268, lng: 19.7262749 },
    { lat: 48.2299302, lng: 19.7257652 },
    { lat: 48.2303104, lng: 19.7255968 },
    { lat: 48.231365, lng: 19.725419 },
    { lat: 48.232202, lng: 19.7250392 },
    { lat: 48.2326982, lng: 19.7246916 },
    { lat: 48.2332112, lng: 19.7240664 },
    { lat: 48.2334229, lng: 19.7236625 },
    { lat: 48.2344751, lng: 19.7236336 },
    { lat: 48.2357247, lng: 19.7229997 },
    { lat: 48.2361363, lng: 19.7229073 },
    { lat: 48.2365333, lng: 19.7238233 },
    { lat: 48.2375319, lng: 19.7254514 },
    { lat: 48.2375798, lng: 19.7243029 },
    { lat: 48.2376314, lng: 19.7239938 },
    { lat: 48.2377418, lng: 19.7236897 },
    { lat: 48.2381729, lng: 19.722922 },
    { lat: 48.2383073, lng: 19.7225516 },
    { lat: 48.2383522, lng: 19.7219926 },
    { lat: 48.2382081, lng: 19.7210131 },
    { lat: 48.2380473, lng: 19.7206157 },
    { lat: 48.2380728, lng: 19.7188381 },
    { lat: 48.2379801, lng: 19.7181487 },
    { lat: 48.2374419, lng: 19.7165718 },
    { lat: 48.2373127, lng: 19.715646 },
    { lat: 48.2376677, lng: 19.7147667 },
    { lat: 48.2379394, lng: 19.7143164 },
    { lat: 48.2383313, lng: 19.7138566 },
    { lat: 48.2384156, lng: 19.7141051 },
    { lat: 48.2383487, lng: 19.7144999 },
    { lat: 48.2387089, lng: 19.7147183 },
    { lat: 48.2388872, lng: 19.7147457 },
    { lat: 48.2390759, lng: 19.7146836 },
    { lat: 48.2400233, lng: 19.7182102 },
    { lat: 48.2400076, lng: 19.7183405 },
    { lat: 48.2400693, lng: 19.7184831 },
    { lat: 48.2400649, lng: 19.7189402 },
    { lat: 48.2401115, lng: 19.7191225 },
    { lat: 48.2399858, lng: 19.7193627 },
    { lat: 48.2398231, lng: 19.7194798 },
    { lat: 48.2397805, lng: 19.7196098 },
    { lat: 48.2398985, lng: 19.7202116 },
    { lat: 48.2398558, lng: 19.7202866 },
    { lat: 48.2398812, lng: 19.7205534 },
    { lat: 48.2399449, lng: 19.7207686 },
    { lat: 48.2399042, lng: 19.7210145 },
    { lat: 48.2399179, lng: 19.7213754 },
    { lat: 48.2398696, lng: 19.7214566 },
    { lat: 48.2398761, lng: 19.7216486 },
    { lat: 48.2398198, lng: 19.7218528 },
    { lat: 48.2397587, lng: 19.7219243 },
    { lat: 48.2397728, lng: 19.7222647 },
    { lat: 48.2397168, lng: 19.7223502 },
    { lat: 48.239781, lng: 19.7226499 },
    { lat: 48.239687, lng: 19.7233216 },
    { lat: 48.2397677, lng: 19.7238911 },
    { lat: 48.2397409, lng: 19.7240221 },
    { lat: 48.2395046, lng: 19.7243557 },
    { lat: 48.2394129, lng: 19.7247303 },
    { lat: 48.2394528, lng: 19.725089 },
    { lat: 48.2395437, lng: 19.7253853 },
    { lat: 48.2394342, lng: 19.7254828 },
    { lat: 48.2394183, lng: 19.7267466 },
    { lat: 48.2392909, lng: 19.7283848 },
    { lat: 48.2388927, lng: 19.7304742 },
    { lat: 48.2387489, lng: 19.7310131 },
    { lat: 48.2386376, lng: 19.7312179 },
    { lat: 48.2384912, lng: 19.7319059 },
    { lat: 48.2383584, lng: 19.7322118 },
    { lat: 48.2382997, lng: 19.7325113 },
    { lat: 48.2383507, lng: 19.7326222 },
    { lat: 48.2382803, lng: 19.7328586 },
    { lat: 48.2382683, lng: 19.7331464 },
    { lat: 48.238102, lng: 19.7333627 },
    { lat: 48.2379025, lng: 19.7334572 },
    { lat: 48.2378753, lng: 19.733632 },
    { lat: 48.2379111, lng: 19.734335 },
    { lat: 48.2380509, lng: 19.7347688 },
    { lat: 48.2388611, lng: 19.7346919 },
    { lat: 48.2393619, lng: 19.734503 },
    { lat: 48.2394324, lng: 19.7341867 },
    { lat: 48.2400926, lng: 19.7337589 },
    { lat: 48.2404899, lng: 19.7335725 },
    { lat: 48.2417467, lng: 19.7333029 },
    { lat: 48.2419904, lng: 19.7335889 },
    { lat: 48.243621, lng: 19.7334685 },
    { lat: 48.2441901, lng: 19.7336231 },
    { lat: 48.2448948, lng: 19.7341155 },
    { lat: 48.2459102, lng: 19.734421 },
    { lat: 48.2460811, lng: 19.7354761 },
    { lat: 48.2462984, lng: 19.7356994 },
    { lat: 48.2467427, lng: 19.7359852 },
    { lat: 48.2475566, lng: 19.7362731 },
    { lat: 48.2479772, lng: 19.7368165 },
    { lat: 48.2483072, lng: 19.7364053 },
    { lat: 48.2486384, lng: 19.7361273 },
    { lat: 48.2499758, lng: 19.7352921 },
    { lat: 48.2502507, lng: 19.7348864 },
    { lat: 48.2507547, lng: 19.7337543 },
    { lat: 48.2514563, lng: 19.7326064 },
    { lat: 48.2521142, lng: 19.7318184 },
    { lat: 48.2525544, lng: 19.7314566 },
    { lat: 48.2522383, lng: 19.7298244 },
    { lat: 48.251881, lng: 19.7283823 },
    { lat: 48.2517647, lng: 19.7276682 },
    { lat: 48.2519331, lng: 19.727226 },
    { lat: 48.252209, lng: 19.7267929 },
    { lat: 48.2523607, lng: 19.7264058 },
    { lat: 48.2526581, lng: 19.7260003 },
    { lat: 48.2531241, lng: 19.7249298 },
    { lat: 48.2533852, lng: 19.7244671 },
    { lat: 48.2535435, lng: 19.7239725 },
    { lat: 48.2534571, lng: 19.7231998 },
    { lat: 48.2534364, lng: 19.7223035 },
    { lat: 48.2533491, lng: 19.721684 },
    { lat: 48.2534031, lng: 19.7205982 },
    { lat: 48.2538267, lng: 19.7193102 },
    { lat: 48.2539261, lng: 19.7187862 },
    { lat: 48.2538367, lng: 19.718297 },
    { lat: 48.253893, lng: 19.7181195 },
    { lat: 48.2540333, lng: 19.7180082 },
    { lat: 48.254137, lng: 19.7176546 },
    { lat: 48.254152, lng: 19.7167281 },
    { lat: 48.2543847, lng: 19.7156314 },
    { lat: 48.2542832, lng: 19.7153061 },
    { lat: 48.254446, lng: 19.7148648 },
    { lat: 48.2543647, lng: 19.7146827 },
    { lat: 48.2544437, lng: 19.7144326 },
    { lat: 48.2545358, lng: 19.7143276 },
    { lat: 48.2543898, lng: 19.7141347 },
    { lat: 48.2544236, lng: 19.7135016 },
  ],
  Podrečany: [
    { lat: 48.4043405, lng: 19.5548318 },
    { lat: 48.4028489, lng: 19.5566795 },
    { lat: 48.402503, lng: 19.5572664 },
    { lat: 48.4019903, lng: 19.5578557 },
    { lat: 48.4014347, lng: 19.5588861 },
    { lat: 48.4012189, lng: 19.5602993 },
    { lat: 48.4010117, lng: 19.5606941 },
    { lat: 48.4009221, lng: 19.5611694 },
    { lat: 48.4009761, lng: 19.5617344 },
    { lat: 48.400951, lng: 19.5619742 },
    { lat: 48.4008695, lng: 19.5621852 },
    { lat: 48.4008715, lng: 19.5627211 },
    { lat: 48.4007168, lng: 19.5632553 },
    { lat: 48.4007184, lng: 19.5635845 },
    { lat: 48.4005888, lng: 19.563754 },
    { lat: 48.3999363, lng: 19.5639492 },
    { lat: 48.3995939, lng: 19.5645201 },
    { lat: 48.3994865, lng: 19.5647844 },
    { lat: 48.3993345, lng: 19.5649093 },
    { lat: 48.3984319, lng: 19.5660665 },
    { lat: 48.3983602, lng: 19.566392 },
    { lat: 48.3981716, lng: 19.5666757 },
    { lat: 48.3980063, lng: 19.5671293 },
    { lat: 48.3971758, lng: 19.5680364 },
    { lat: 48.3967469, lng: 19.5683634 },
    { lat: 48.3963966, lng: 19.5691797 },
    { lat: 48.3961622, lng: 19.5693529 },
    { lat: 48.3960177, lng: 19.5697898 },
    { lat: 48.3954952, lng: 19.5702147 },
    { lat: 48.3953615, lng: 19.5704441 },
    { lat: 48.3952877, lng: 19.5704322 },
    { lat: 48.3947657, lng: 19.5709446 },
    { lat: 48.394631, lng: 19.5711791 },
    { lat: 48.3942338, lng: 19.571313 },
    { lat: 48.3940901, lng: 19.571424 },
    { lat: 48.3939029, lng: 19.5714199 },
    { lat: 48.3936245, lng: 19.5715884 },
    { lat: 48.3935792, lng: 19.5717352 },
    { lat: 48.393491, lng: 19.5717518 },
    { lat: 48.3933902, lng: 19.5722068 },
    { lat: 48.3933431, lng: 19.5722599 },
    { lat: 48.393212, lng: 19.5722295 },
    { lat: 48.3931608, lng: 19.5726617 },
    { lat: 48.3928812, lng: 19.5730739 },
    { lat: 48.3928926, lng: 19.5732266 },
    { lat: 48.3926506, lng: 19.5735058 },
    { lat: 48.3922319, lng: 19.5736845 },
    { lat: 48.3922433, lng: 19.5741286 },
    { lat: 48.3920653, lng: 19.574553 },
    { lat: 48.3915928, lng: 19.5752336 },
    { lat: 48.3914559, lng: 19.576055 },
    { lat: 48.3911674, lng: 19.5767205 },
    { lat: 48.3909909, lng: 19.5774454 },
    { lat: 48.3910133, lng: 19.5777355 },
    { lat: 48.390964, lng: 19.5780399 },
    { lat: 48.3911254, lng: 19.5786823 },
    { lat: 48.3911291, lng: 19.578912 },
    { lat: 48.3911789, lng: 19.5790881 },
    { lat: 48.3912586, lng: 19.5791054 },
    { lat: 48.3912605, lng: 19.5793427 },
    { lat: 48.3911901, lng: 19.5795812 },
    { lat: 48.3912213, lng: 19.5801133 },
    { lat: 48.3911807, lng: 19.5809738 },
    { lat: 48.3912279, lng: 19.5811447 },
    { lat: 48.3911366, lng: 19.581492 },
    { lat: 48.3912112, lng: 19.5818385 },
    { lat: 48.3911363, lng: 19.5820278 },
    { lat: 48.3910703, lng: 19.5820094 },
    { lat: 48.3910273, lng: 19.5824024 },
    { lat: 48.3911061, lng: 19.5828187 },
    { lat: 48.3910423, lng: 19.5829508 },
    { lat: 48.3910508, lng: 19.583085 },
    { lat: 48.3911552, lng: 19.5834738 },
    { lat: 48.3911533, lng: 19.5837013 },
    { lat: 48.3910993, lng: 19.5838788 },
    { lat: 48.3908879, lng: 19.5841814 },
    { lat: 48.3908396, lng: 19.5843591 },
    { lat: 48.3908701, lng: 19.5844591 },
    { lat: 48.3908458, lng: 19.5848413 },
    { lat: 48.3906912, lng: 19.5851575 },
    { lat: 48.3907273, lng: 19.5854055 },
    { lat: 48.3905552, lng: 19.5857778 },
    { lat: 48.3904205, lng: 19.5864054 },
    { lat: 48.390192, lng: 19.5870076 },
    { lat: 48.3902996, lng: 19.5875113 },
    { lat: 48.3902205, lng: 19.5876308 },
    { lat: 48.3899874, lng: 19.5877547 },
    { lat: 48.3899092, lng: 19.5879904 },
    { lat: 48.3898517, lng: 19.5880141 },
    { lat: 48.3897017, lng: 19.5884585 },
    { lat: 48.3896152, lng: 19.5890308 },
    { lat: 48.3894877, lng: 19.5892002 },
    { lat: 48.3894347, lng: 19.5893957 },
    { lat: 48.3892805, lng: 19.5895758 },
    { lat: 48.3891611, lng: 19.5895529 },
    { lat: 48.3890711, lng: 19.589726 },
    { lat: 48.3890782, lng: 19.5899089 },
    { lat: 48.3891429, lng: 19.5899654 },
    { lat: 48.3891453, lng: 19.5901672 },
    { lat: 48.3890537, lng: 19.5901568 },
    { lat: 48.3889761, lng: 19.590264 },
    { lat: 48.3890109, lng: 19.5906022 },
    { lat: 48.3890909, lng: 19.5907284 },
    { lat: 48.3889725, lng: 19.591021 },
    { lat: 48.389043, lng: 19.5911601 },
    { lat: 48.3890022, lng: 19.5913526 },
    { lat: 48.3890375, lng: 19.5914589 },
    { lat: 48.388951, lng: 19.5916121 },
    { lat: 48.3888823, lng: 19.5916386 },
    { lat: 48.3887362, lng: 19.5919642 },
    { lat: 48.3884609, lng: 19.5927977 },
    { lat: 48.3883271, lng: 19.5930019 },
    { lat: 48.388261, lng: 19.5928885 },
    { lat: 48.3881656, lng: 19.5928721 },
    { lat: 48.3880683, lng: 19.5931118 },
    { lat: 48.3881, lng: 19.5933868 },
    { lat: 48.3879736, lng: 19.5934307 },
    { lat: 48.3880125, lng: 19.5935898 },
    { lat: 48.3878801, lng: 19.5937264 },
    { lat: 48.3877724, lng: 19.5936903 },
    { lat: 48.387689, lng: 19.5937868 },
    { lat: 48.3877295, lng: 19.5938564 },
    { lat: 48.3877032, lng: 19.5940261 },
    { lat: 48.3877649, lng: 19.5940417 },
    { lat: 48.3876767, lng: 19.5941855 },
    { lat: 48.3876762, lng: 19.5942983 },
    { lat: 48.3877489, lng: 19.5944837 },
    { lat: 48.3875673, lng: 19.5947466 },
    { lat: 48.3876191, lng: 19.5948228 },
    { lat: 48.3875676, lng: 19.5949554 },
    { lat: 48.3874911, lng: 19.594981 },
    { lat: 48.3875083, lng: 19.5950849 },
    { lat: 48.3874687, lng: 19.5951801 },
    { lat: 48.387521, lng: 19.5952651 },
    { lat: 48.3874431, lng: 19.5952401 },
    { lat: 48.387421, lng: 19.5953183 },
    { lat: 48.3872694, lng: 19.5953349 },
    { lat: 48.3872437, lng: 19.5953879 },
    { lat: 48.3872749, lng: 19.5954755 },
    { lat: 48.3873509, lng: 19.5954903 },
    { lat: 48.3873753, lng: 19.5957246 },
    { lat: 48.387335, lng: 19.5957586 },
    { lat: 48.3873002, lng: 19.5956618 },
    { lat: 48.3872381, lng: 19.5956374 },
    { lat: 48.387232, lng: 19.595727 },
    { lat: 48.3871033, lng: 19.5958395 },
    { lat: 48.3870113, lng: 19.5958255 },
    { lat: 48.3869766, lng: 19.5959039 },
    { lat: 48.3870034, lng: 19.5959994 },
    { lat: 48.3870783, lng: 19.5960377 },
    { lat: 48.3870738, lng: 19.5962594 },
    { lat: 48.3871886, lng: 19.5964522 },
    { lat: 48.3872291, lng: 19.5964491 },
    { lat: 48.3872222, lng: 19.5965466 },
    { lat: 48.3870999, lng: 19.5966088 },
    { lat: 48.3871297, lng: 19.5966419 },
    { lat: 48.3870853, lng: 19.5967333 },
    { lat: 48.3871159, lng: 19.5969588 },
    { lat: 48.3874286, lng: 19.5973659 },
    { lat: 48.3874294, lng: 19.5975066 },
    { lat: 48.3875085, lng: 19.5975116 },
    { lat: 48.3875317, lng: 19.5976279 },
    { lat: 48.3874037, lng: 19.5978591 },
    { lat: 48.3874527, lng: 19.5979452 },
    { lat: 48.3873716, lng: 19.5980932 },
    { lat: 48.3874292, lng: 19.5983936 },
    { lat: 48.3873155, lng: 19.59854 },
    { lat: 48.3872634, lng: 19.5985117 },
    { lat: 48.3872016, lng: 19.598663 },
    { lat: 48.3872895, lng: 19.5986905 },
    { lat: 48.3872511, lng: 19.5988118 },
    { lat: 48.3872882, lng: 19.5988587 },
    { lat: 48.3871425, lng: 19.5990498 },
    { lat: 48.3871775, lng: 19.5992476 },
    { lat: 48.3871355, lng: 19.5993168 },
    { lat: 48.3872098, lng: 19.5994379 },
    { lat: 48.3872961, lng: 19.5993783 },
    { lat: 48.3873591, lng: 19.5995491 },
    { lat: 48.3874696, lng: 19.599575 },
    { lat: 48.387579, lng: 19.5998498 },
    { lat: 48.3876355, lng: 19.599877 },
    { lat: 48.387644, lng: 19.6000137 },
    { lat: 48.3877395, lng: 19.6001552 },
    { lat: 48.3877598, lng: 19.6005537 },
    { lat: 48.3876764, lng: 19.600501 },
    { lat: 48.387591, lng: 19.6005814 },
    { lat: 48.3875741, lng: 19.6007334 },
    { lat: 48.3876152, lng: 19.6007437 },
    { lat: 48.3875742, lng: 19.6009326 },
    { lat: 48.3874673, lng: 19.6010847 },
    { lat: 48.3874803, lng: 19.6011919 },
    { lat: 48.3876057, lng: 19.6012751 },
    { lat: 48.3875097, lng: 19.601542 },
    { lat: 48.3875469, lng: 19.6017416 },
    { lat: 48.3875104, lng: 19.6017798 },
    { lat: 48.3875676, lng: 19.6018498 },
    { lat: 48.3875629, lng: 19.6023377 },
    { lat: 48.3875078, lng: 19.6024192 },
    { lat: 48.3875674, lng: 19.6025393 },
    { lat: 48.3875431, lng: 19.6026489 },
    { lat: 48.3875873, lng: 19.6028266 },
    { lat: 48.3877157, lng: 19.6030732 },
    { lat: 48.3875275, lng: 19.6037371 },
    { lat: 48.387558, lng: 19.6039399 },
    { lat: 48.3875091, lng: 19.6040999 },
    { lat: 48.3876865, lng: 19.6045074 },
    { lat: 48.3876804, lng: 19.6046467 },
    { lat: 48.3875837, lng: 19.6047272 },
    { lat: 48.3875465, lng: 19.6046998 },
    { lat: 48.3873769, lng: 19.6049363 },
    { lat: 48.3872661, lng: 19.6048771 },
    { lat: 48.3871394, lng: 19.60514 },
    { lat: 48.3869685, lng: 19.6060375 },
    { lat: 48.3870606, lng: 19.6062046 },
    { lat: 48.3868079, lng: 19.6070186 },
    { lat: 48.3868182, lng: 19.6072916 },
    { lat: 48.3867403, lng: 19.607514 },
    { lat: 48.3868112, lng: 19.6079577 },
    { lat: 48.3866819, lng: 19.6081362 },
    { lat: 48.3866833, lng: 19.6084154 },
    { lat: 48.3868197, lng: 19.6090367 },
    { lat: 48.3871556, lng: 19.609433 },
    { lat: 48.3871035, lng: 19.609628 },
    { lat: 48.3869703, lng: 19.6096682 },
    { lat: 48.387111, lng: 19.6100349 },
    { lat: 48.3871443, lng: 19.6104193 },
    { lat: 48.3872948, lng: 19.6106095 },
    { lat: 48.3872535, lng: 19.6106928 },
    { lat: 48.387355, lng: 19.6108164 },
    { lat: 48.3879877, lng: 19.6124915 },
    { lat: 48.3876345, lng: 19.6133239 },
    { lat: 48.3874037, lng: 19.6135483 },
    { lat: 48.3872955, lng: 19.6135225 },
    { lat: 48.3871982, lng: 19.6137129 },
    { lat: 48.387122, lng: 19.6137473 },
    { lat: 48.3868921, lng: 19.6126829 },
    { lat: 48.3867241, lng: 19.6127015 },
    { lat: 48.3862459, lng: 19.6132149 },
    { lat: 48.3860464, lng: 19.6133413 },
    { lat: 48.385943, lng: 19.6135182 },
    { lat: 48.3858148, lng: 19.6135746 },
    { lat: 48.3857357, lng: 19.6134455 },
    { lat: 48.3855855, lng: 19.6129146 },
    { lat: 48.3852772, lng: 19.6127519 },
    { lat: 48.3851562, lng: 19.6124219 },
    { lat: 48.3848904, lng: 19.6125729 },
    { lat: 48.3849528, lng: 19.613027 },
    { lat: 48.3847491, lng: 19.6130609 },
    { lat: 48.3846228, lng: 19.612931 },
    { lat: 48.3844508, lng: 19.6123992 },
    { lat: 48.3843679, lng: 19.612285 },
    { lat: 48.3842436, lng: 19.6125268 },
    { lat: 48.3842794, lng: 19.6127879 },
    { lat: 48.3842169, lng: 19.6128783 },
    { lat: 48.3841434, lng: 19.6128724 },
    { lat: 48.3839001, lng: 19.6125021 },
    { lat: 48.3838602, lng: 19.6120971 },
    { lat: 48.383787, lng: 19.6120713 },
    { lat: 48.3835444, lng: 19.6122407 },
    { lat: 48.3835884, lng: 19.6124135 },
    { lat: 48.3835332, lng: 19.6125131 },
    { lat: 48.3834401, lng: 19.6125226 },
    { lat: 48.3834184, lng: 19.6124196 },
    { lat: 48.3832285, lng: 19.6123816 },
    { lat: 48.3831656, lng: 19.6123118 },
    { lat: 48.3830112, lng: 19.612333 },
    { lat: 48.3826938, lng: 19.6127377 },
    { lat: 48.3828126, lng: 19.6132463 },
    { lat: 48.3836446, lng: 19.6152862 },
    { lat: 48.3841111, lng: 19.6167747 },
    { lat: 48.3842851, lng: 19.6171532 },
    { lat: 48.3843644, lng: 19.617584 },
    { lat: 48.3845645, lng: 19.6180956 },
    { lat: 48.3846569, lng: 19.6184938 },
    { lat: 48.3849125, lng: 19.6191341 },
    { lat: 48.3853651, lng: 19.619996 },
    { lat: 48.3865509, lng: 19.6208701 },
    { lat: 48.3880046, lng: 19.6217668 },
    { lat: 48.3899411, lng: 19.623693 },
    { lat: 48.3911247, lng: 19.6245501 },
    { lat: 48.3921272, lng: 19.6250634 },
    { lat: 48.3924802, lng: 19.6253177 },
    { lat: 48.3935761, lng: 19.6258041 },
    { lat: 48.3941146, lng: 19.6257756 },
    { lat: 48.3957066, lng: 19.6260681 },
    { lat: 48.3961712, lng: 19.6260235 },
    { lat: 48.3961936, lng: 19.6260226 },
    { lat: 48.3969215, lng: 19.6252766 },
    { lat: 48.3973535, lng: 19.6258565 },
    { lat: 48.3975362, lng: 19.6260328 },
    { lat: 48.3979141, lng: 19.6262445 },
    { lat: 48.3992466, lng: 19.6251867 },
    { lat: 48.3996178, lng: 19.6257721 },
    { lat: 48.4007605, lng: 19.6246007 },
    { lat: 48.4015632, lng: 19.6253075 },
    { lat: 48.4030287, lng: 19.6231377 },
    { lat: 48.4040709, lng: 19.6223137 },
    { lat: 48.4047313, lng: 19.6218695 },
    { lat: 48.4052188, lng: 19.6214373 },
    { lat: 48.4064061, lng: 19.6200128 },
    { lat: 48.407187, lng: 19.6187692 },
    { lat: 48.4072339, lng: 19.6188602 },
    { lat: 48.407307, lng: 19.6187591 },
    { lat: 48.4064388, lng: 19.6170329 },
    { lat: 48.4061421, lng: 19.6160132 },
    { lat: 48.4059244, lng: 19.6154348 },
    { lat: 48.4066642, lng: 19.6143344 },
    { lat: 48.4058643, lng: 19.6136386 },
    { lat: 48.4060254, lng: 19.6130185 },
    { lat: 48.4064759, lng: 19.6118264 },
    { lat: 48.4075287, lng: 19.6098501 },
    { lat: 48.4087948, lng: 19.6071624 },
    { lat: 48.4099753, lng: 19.6054204 },
    { lat: 48.4107259, lng: 19.6042094 },
    { lat: 48.4102728, lng: 19.6033912 },
    { lat: 48.4115994, lng: 19.6016376 },
    { lat: 48.411431, lng: 19.6013832 },
    { lat: 48.4119051, lng: 19.6007304 },
    { lat: 48.4115189, lng: 19.6000476 },
    { lat: 48.4127896, lng: 19.5986029 },
    { lat: 48.4134448, lng: 19.5979445 },
    { lat: 48.4125756, lng: 19.5963541 },
    { lat: 48.4123661, lng: 19.5958394 },
    { lat: 48.4122268, lng: 19.5957987 },
    { lat: 48.4122799, lng: 19.5955038 },
    { lat: 48.4126316, lng: 19.594693 },
    { lat: 48.412655, lng: 19.5944125 },
    { lat: 48.4128272, lng: 19.5943096 },
    { lat: 48.4129888, lng: 19.5940011 },
    { lat: 48.4130826, lng: 19.5939308 },
    { lat: 48.4134883, lng: 19.5938337 },
    { lat: 48.4139464, lng: 19.5933261 },
    { lat: 48.4139694, lng: 19.5932127 },
    { lat: 48.4142767, lng: 19.5930821 },
    { lat: 48.4145158, lng: 19.5928922 },
    { lat: 48.4146467, lng: 19.5929465 },
    { lat: 48.4153657, lng: 19.5923511 },
    { lat: 48.4150129, lng: 19.5921743 },
    { lat: 48.4146646, lng: 19.5919781 },
    { lat: 48.4151225, lng: 19.5916156 },
    { lat: 48.4152, lng: 19.5914352 },
    { lat: 48.4151914, lng: 19.5912988 },
    { lat: 48.4152812, lng: 19.5911917 },
    { lat: 48.4152793, lng: 19.591077 },
    { lat: 48.4155864, lng: 19.5909666 },
    { lat: 48.4155608, lng: 19.5907724 },
    { lat: 48.4156259, lng: 19.5905489 },
    { lat: 48.4156515, lng: 19.5906144 },
    { lat: 48.4156913, lng: 19.5903999 },
    { lat: 48.4156591, lng: 19.5902628 },
    { lat: 48.41555, lng: 19.5901953 },
    { lat: 48.4153979, lng: 19.5896977 },
    { lat: 48.4153667, lng: 19.5891638 },
    { lat: 48.4154966, lng: 19.5881611 },
    { lat: 48.4156326, lng: 19.5881039 },
    { lat: 48.4153425, lng: 19.5869798 },
    { lat: 48.4152869, lng: 19.5863826 },
    { lat: 48.4153079, lng: 19.5861232 },
    { lat: 48.4154355, lng: 19.5857387 },
    { lat: 48.4155442, lng: 19.5850326 },
    { lat: 48.4155207, lng: 19.5848903 },
    { lat: 48.4157497, lng: 19.5846198 },
    { lat: 48.4158786, lng: 19.5846086 },
    { lat: 48.4159588, lng: 19.5838476 },
    { lat: 48.415713, lng: 19.5824115 },
    { lat: 48.4156562, lng: 19.5809456 },
    { lat: 48.4156851, lng: 19.5806695 },
    { lat: 48.4155875, lng: 19.5805083 },
    { lat: 48.4157371, lng: 19.5797096 },
    { lat: 48.4156718, lng: 19.5795163 },
    { lat: 48.4158205, lng: 19.5786794 },
    { lat: 48.415928, lng: 19.5785395 },
    { lat: 48.4159853, lng: 19.5782917 },
    { lat: 48.4160958, lng: 19.5780692 },
    { lat: 48.4163867, lng: 19.5764936 },
    { lat: 48.4163267, lng: 19.5749883 },
    { lat: 48.4162304, lng: 19.5746585 },
    { lat: 48.4161807, lng: 19.5739655 },
    { lat: 48.4164619, lng: 19.5731915 },
    { lat: 48.4165336, lng: 19.5717042 },
    { lat: 48.4162075, lng: 19.5706893 },
    { lat: 48.4154923, lng: 19.57003 },
    { lat: 48.4150679, lng: 19.5692754 },
    { lat: 48.4148516, lng: 19.5681744 },
    { lat: 48.4148938, lng: 19.5679604 },
    { lat: 48.4148537, lng: 19.5672363 },
    { lat: 48.4147646, lng: 19.567038 },
    { lat: 48.4143395, lng: 19.5664921 },
    { lat: 48.4135423, lng: 19.5661103 },
    { lat: 48.4129297, lng: 19.5659754 },
    { lat: 48.4122852, lng: 19.5656809 },
    { lat: 48.4112602, lng: 19.5645598 },
    { lat: 48.4103839, lng: 19.563879 },
    { lat: 48.4101232, lng: 19.5633807 },
    { lat: 48.4095964, lng: 19.5627784 },
    { lat: 48.4089018, lng: 19.5610283 },
    { lat: 48.4085709, lng: 19.5608405 },
    { lat: 48.4082384, lng: 19.5609845 },
    { lat: 48.407807, lng: 19.5609636 },
    { lat: 48.4074407, lng: 19.5607217 },
    { lat: 48.4071695, lng: 19.5603845 },
    { lat: 48.4068996, lng: 19.5601539 },
    { lat: 48.4066437, lng: 19.5598055 },
    { lat: 48.4054234, lng: 19.5591683 },
    { lat: 48.4053007, lng: 19.5589512 },
    { lat: 48.4050868, lng: 19.558807 },
    { lat: 48.4044181, lng: 19.5581344 },
    { lat: 48.404612, lng: 19.5567163 },
    { lat: 48.4045188, lng: 19.5558918 },
    { lat: 48.4043405, lng: 19.5548318 },
  ],
  Praha: [
    { lat: 48.3856364, lng: 19.4720826 },
    { lat: 48.3853267, lng: 19.4722052 },
    { lat: 48.385188, lng: 19.4724474 },
    { lat: 48.385158, lng: 19.4726236 },
    { lat: 48.3851788, lng: 19.4731412 },
    { lat: 48.3850935, lng: 19.4733225 },
    { lat: 48.3847246, lng: 19.473696 },
    { lat: 48.3841825, lng: 19.4738385 },
    { lat: 48.3841873, lng: 19.4738957 },
    { lat: 48.3836177, lng: 19.4742248 },
    { lat: 48.3833447, lng: 19.4744588 },
    { lat: 48.3828752, lng: 19.4744936 },
    { lat: 48.3827325, lng: 19.4744055 },
    { lat: 48.381994, lng: 19.4744009 },
    { lat: 48.3819806, lng: 19.4743311 },
    { lat: 48.3816158, lng: 19.4745801 },
    { lat: 48.3814207, lng: 19.4747927 },
    { lat: 48.3814101, lng: 19.47507 },
    { lat: 48.3812574, lng: 19.4752034 },
    { lat: 48.3810274, lng: 19.4752244 },
    { lat: 48.3808328, lng: 19.4751137 },
    { lat: 48.3806432, lng: 19.4751347 },
    { lat: 48.3804846, lng: 19.4752376 },
    { lat: 48.3803065, lng: 19.4756466 },
    { lat: 48.3799866, lng: 19.4756732 },
    { lat: 48.3795104, lng: 19.47549 },
    { lat: 48.3791676, lng: 19.4758015 },
    { lat: 48.3789047, lng: 19.476446 },
    { lat: 48.3787113, lng: 19.4765985 },
    { lat: 48.3782148, lng: 19.4765621 },
    { lat: 48.3776439, lng: 19.4769331 },
    { lat: 48.3771768, lng: 19.4774558 },
    { lat: 48.3770186, lng: 19.4778109 },
    { lat: 48.3766866, lng: 19.4779887 },
    { lat: 48.3763991, lng: 19.4780565 },
    { lat: 48.3761697, lng: 19.4783327 },
    { lat: 48.3758993, lng: 19.4788641 },
    { lat: 48.375766, lng: 19.4789086 },
    { lat: 48.3754128, lng: 19.4787025 },
    { lat: 48.3749665, lng: 19.4787275 },
    { lat: 48.374703, lng: 19.4788757 },
    { lat: 48.3744872, lng: 19.4791305 },
    { lat: 48.374149, lng: 19.4790332 },
    { lat: 48.3740063, lng: 19.4790872 },
    { lat: 48.3736195, lng: 19.4795702 },
    { lat: 48.3734043, lng: 19.4796431 },
    { lat: 48.373157, lng: 19.479538 },
    { lat: 48.3726316, lng: 19.479674 },
    { lat: 48.3723397, lng: 19.4796235 },
    { lat: 48.3717984, lng: 19.4798863 },
    { lat: 48.3715742, lng: 19.4804667 },
    { lat: 48.3713914, lng: 19.4807248 },
    { lat: 48.371059, lng: 19.4806825 },
    { lat: 48.3707039, lng: 19.480724 },
    { lat: 48.3703086, lng: 19.480902 },
    { lat: 48.3700016, lng: 19.4813015 },
    { lat: 48.3696889, lng: 19.4814839 },
    { lat: 48.3693084, lng: 19.4818322 },
    { lat: 48.3687117, lng: 19.4824967 },
    { lat: 48.3684648, lng: 19.4826605 },
    { lat: 48.3677248, lng: 19.4834938 },
    { lat: 48.3673555, lng: 19.4837438 },
    { lat: 48.3668176, lng: 19.4845106 },
    { lat: 48.3667531, lng: 19.4847675 },
    { lat: 48.366777, lng: 19.484798 },
    { lat: 48.3662141, lng: 19.4857785 },
    { lat: 48.3661137, lng: 19.4862346 },
    { lat: 48.3660205, lng: 19.4863415 },
    { lat: 48.3656234, lng: 19.4865231 },
    { lat: 48.3658046, lng: 19.487198 },
    { lat: 48.3661853, lng: 19.4879693 },
    { lat: 48.3668624, lng: 19.4890494 },
    { lat: 48.3669805, lng: 19.4894804 },
    { lat: 48.3669665, lng: 19.4896158 },
    { lat: 48.3671338, lng: 19.4901348 },
    { lat: 48.367209, lng: 19.4908322 },
    { lat: 48.3671286, lng: 19.4909686 },
    { lat: 48.3672716, lng: 19.4917613 },
    { lat: 48.3672506, lng: 19.4919386 },
    { lat: 48.3673064, lng: 19.492559 },
    { lat: 48.3666868, lng: 19.4929776 },
    { lat: 48.3664803, lng: 19.4934058 },
    { lat: 48.3656372, lng: 19.4944918 },
    { lat: 48.3643393, lng: 19.4956402 },
    { lat: 48.3643155, lng: 19.4960971 },
    { lat: 48.3636915, lng: 19.4969283 },
    { lat: 48.3630464, lng: 19.4976706 },
    { lat: 48.3625036, lng: 19.4980658 },
    { lat: 48.3616527, lng: 19.4985263 },
    { lat: 48.3607079, lng: 19.4991716 },
    { lat: 48.3604916, lng: 19.4992191 },
    { lat: 48.3604143, lng: 19.4991556 },
    { lat: 48.3599382, lng: 19.4994142 },
    { lat: 48.3588682, lng: 19.5005417 },
    { lat: 48.3582329, lng: 19.5014455 },
    { lat: 48.3580079, lng: 19.5019396 },
    { lat: 48.3568802, lng: 19.5030352 },
    { lat: 48.3567489, lng: 19.5029746 },
    { lat: 48.3561616, lng: 19.5034332 },
    { lat: 48.355377, lng: 19.5033788 },
    { lat: 48.3551997, lng: 19.5034225 },
    { lat: 48.3546965, lng: 19.5036502 },
    { lat: 48.3543815, lng: 19.503906 },
    { lat: 48.3543305, lng: 19.5040938 },
    { lat: 48.3542408, lng: 19.5041046 },
    { lat: 48.3539983, lng: 19.5047762 },
    { lat: 48.3539119, lng: 19.504859 },
    { lat: 48.3538066, lng: 19.5051137 },
    { lat: 48.3537833, lng: 19.505293 },
    { lat: 48.3536408, lng: 19.5055757 },
    { lat: 48.3535598, lng: 19.5056016 },
    { lat: 48.3532636, lng: 19.5061647 },
    { lat: 48.352833, lng: 19.5066196 },
    { lat: 48.3528587, lng: 19.5066903 },
    { lat: 48.3522132, lng: 19.5071826 },
    { lat: 48.3520689, lng: 19.5074212 },
    { lat: 48.351641, lng: 19.5078691 },
    { lat: 48.351537, lng: 19.5079059 },
    { lat: 48.3514348, lng: 19.5081124 },
    { lat: 48.3513563, lng: 19.5084353 },
    { lat: 48.3511868, lng: 19.5086648 },
    { lat: 48.3509796, lng: 19.5087194 },
    { lat: 48.3508193, lng: 19.5089789 },
    { lat: 48.3505256, lng: 19.5090889 },
    { lat: 48.3500409, lng: 19.509497 },
    { lat: 48.3497133, lng: 19.509894 },
    { lat: 48.3495719, lng: 19.5099308 },
    { lat: 48.3495237, lng: 19.5100559 },
    { lat: 48.3495572, lng: 19.5102696 },
    { lat: 48.3494014, lng: 19.5103826 },
    { lat: 48.349298, lng: 19.5106849 },
    { lat: 48.3490902, lng: 19.5108664 },
    { lat: 48.3489954, lng: 19.5113496 },
    { lat: 48.3488077, lng: 19.5116502 },
    { lat: 48.3485142, lng: 19.5115944 },
    { lat: 48.3483855, lng: 19.5116861 },
    { lat: 48.3482274, lng: 19.5119432 },
    { lat: 48.3477464, lng: 19.5122648 },
    { lat: 48.3476995, lng: 19.5123476 },
    { lat: 48.3477192, lng: 19.5124823 },
    { lat: 48.3475006, lng: 19.5128466 },
    { lat: 48.3473476, lng: 19.5128009 },
    { lat: 48.3495406, lng: 19.5190497 },
    { lat: 48.3496586, lng: 19.5190678 },
    { lat: 48.3499236, lng: 19.5189251 },
    { lat: 48.3502674, lng: 19.5189055 },
    { lat: 48.3507531, lng: 19.5185374 },
    { lat: 48.3526452, lng: 19.5216733 },
    { lat: 48.3526184, lng: 19.5217059 },
    { lat: 48.3526792, lng: 19.5218005 },
    { lat: 48.3531631, lng: 19.5216906 },
    { lat: 48.3537576, lng: 19.5214111 },
    { lat: 48.3545091, lng: 19.5211637 },
    { lat: 48.3554758, lng: 19.5211618 },
    { lat: 48.3559668, lng: 19.5208825 },
    { lat: 48.357561, lng: 19.5189988 },
    { lat: 48.3578212, lng: 19.5184629 },
    { lat: 48.3579587, lng: 19.5180509 },
    { lat: 48.3584281, lng: 19.5160935 },
    { lat: 48.3586442, lng: 19.5155731 },
    { lat: 48.359051, lng: 19.5149463 },
    { lat: 48.3593071, lng: 19.5147286 },
    { lat: 48.3600085, lng: 19.5138181 },
    { lat: 48.3735171, lng: 19.5141319 },
    { lat: 48.3741006, lng: 19.5137838 },
    { lat: 48.3749383, lng: 19.5134032 },
    { lat: 48.3765091, lng: 19.5132094 },
    { lat: 48.3772632, lng: 19.5115345 },
    { lat: 48.3781075, lng: 19.5110524 },
    { lat: 48.3786883, lng: 19.5105745 },
    { lat: 48.3793399, lng: 19.51046 },
    { lat: 48.380284, lng: 19.5104035 },
    { lat: 48.380462, lng: 19.5108397 },
    { lat: 48.3819393, lng: 19.5099884 },
    { lat: 48.3827804, lng: 19.5101639 },
    { lat: 48.3879368, lng: 19.5075533 },
    { lat: 48.3896718, lng: 19.5066681 },
    { lat: 48.3897552, lng: 19.5063533 },
    { lat: 48.3899022, lng: 19.5061479 },
    { lat: 48.3900626, lng: 19.5056454 },
    { lat: 48.3905421, lng: 19.504517 },
    { lat: 48.3906753, lng: 19.5040091 },
    { lat: 48.3909561, lng: 19.5033575 },
    { lat: 48.3913533, lng: 19.5026156 },
    { lat: 48.3924422, lng: 19.5009993 },
    { lat: 48.3930007, lng: 19.4996253 },
    { lat: 48.3930603, lng: 19.4991155 },
    { lat: 48.3935192, lng: 19.4974235 },
    { lat: 48.3944648, lng: 19.4964344 },
    { lat: 48.3942671, lng: 19.4954027 },
    { lat: 48.3943549, lng: 19.4950339 },
    { lat: 48.3934017, lng: 19.4941194 },
    { lat: 48.3931116, lng: 19.4937207 },
    { lat: 48.3929, lng: 19.4933139 },
    { lat: 48.3925373, lng: 19.4923764 },
    { lat: 48.3919882, lng: 19.4904062 },
    { lat: 48.3923175, lng: 19.4900025 },
    { lat: 48.3910334, lng: 19.4881739 },
    { lat: 48.3906077, lng: 19.4874549 },
    { lat: 48.3906422, lng: 19.4870336 },
    { lat: 48.3909021, lng: 19.4863482 },
    { lat: 48.3917582, lng: 19.4851307 },
    { lat: 48.3921735, lng: 19.4842921 },
    { lat: 48.3920225, lng: 19.4823609 },
    { lat: 48.3918842, lng: 19.481956 },
    { lat: 48.3917789, lng: 19.4806913 },
    { lat: 48.3915664, lng: 19.4799974 },
    { lat: 48.3912384, lng: 19.4791979 },
    { lat: 48.3911085, lng: 19.479097 },
    { lat: 48.3909676, lng: 19.4790983 },
    { lat: 48.3905862, lng: 19.4785317 },
    { lat: 48.3905415, lng: 19.4782971 },
    { lat: 48.3904177, lng: 19.4782513 },
    { lat: 48.3898595, lng: 19.4776186 },
    { lat: 48.3897855, lng: 19.4771409 },
    { lat: 48.3896522, lng: 19.4771096 },
    { lat: 48.3896471, lng: 19.4768587 },
    { lat: 48.3894147, lng: 19.4767052 },
    { lat: 48.3893874, lng: 19.4764591 },
    { lat: 48.3892294, lng: 19.4764094 },
    { lat: 48.3891662, lng: 19.4761636 },
    { lat: 48.389084, lng: 19.4762382 },
    { lat: 48.3889678, lng: 19.4759955 },
    { lat: 48.3890133, lng: 19.4757351 },
    { lat: 48.3889827, lng: 19.4756628 },
    { lat: 48.3887734, lng: 19.4754469 },
    { lat: 48.388569, lng: 19.4751018 },
    { lat: 48.3882348, lng: 19.4747515 },
    { lat: 48.3880742, lng: 19.474716 },
    { lat: 48.3880087, lng: 19.4745207 },
    { lat: 48.3878266, lng: 19.4743392 },
    { lat: 48.3877949, lng: 19.4741405 },
    { lat: 48.3875173, lng: 19.4737243 },
    { lat: 48.3872462, lng: 19.4736132 },
    { lat: 48.3870684, lng: 19.4733291 },
    { lat: 48.3868813, lng: 19.4731098 },
    { lat: 48.3867637, lng: 19.4730556 },
    { lat: 48.3864336, lng: 19.4726212 },
    { lat: 48.3862646, lng: 19.4725986 },
    { lat: 48.3860289, lng: 19.4723295 },
    { lat: 48.3856482, lng: 19.4721427 },
    { lat: 48.3856364, lng: 19.4720826 },
  ],
  NitranadIpľom: [
    { lat: 48.3306063, lng: 19.7695898 },
    { lat: 48.3305517, lng: 19.7695506 },
    { lat: 48.3287309, lng: 19.7692179 },
    { lat: 48.3267863, lng: 19.7697041 },
    { lat: 48.3252969, lng: 19.7694764 },
    { lat: 48.3204813, lng: 19.7696765 },
    { lat: 48.3201748, lng: 19.7702379 },
    { lat: 48.319964, lng: 19.7704149 },
    { lat: 48.3198116, lng: 19.7704605 },
    { lat: 48.3193886, lng: 19.7703244 },
    { lat: 48.3192361, lng: 19.7703406 },
    { lat: 48.3184929, lng: 19.7696632 },
    { lat: 48.3173489, lng: 19.7669711 },
    { lat: 48.3169868, lng: 19.7662247 },
    { lat: 48.3168857, lng: 19.7663045 },
    { lat: 48.316147, lng: 19.7664915 },
    { lat: 48.3160673, lng: 19.7664247 },
    { lat: 48.3156333, lng: 19.7653981 },
    { lat: 48.315554, lng: 19.7650082 },
    { lat: 48.3154196, lng: 19.7631915 },
    { lat: 48.315288, lng: 19.7627349 },
    { lat: 48.3149596, lng: 19.7621106 },
    { lat: 48.3134304, lng: 19.7609858 },
    { lat: 48.3124051, lng: 19.7610609 },
    { lat: 48.3110392, lng: 19.760419 },
    { lat: 48.3093992, lng: 19.758748 },
    { lat: 48.3092546, lng: 19.7587405 },
    { lat: 48.3087317, lng: 19.759122 },
    { lat: 48.3081938, lng: 19.7590562 },
    { lat: 48.3079774, lng: 19.7588565 },
    { lat: 48.3076148, lng: 19.7599295 },
    { lat: 48.3071453, lng: 19.7605177 },
    { lat: 48.3068478, lng: 19.7604712 },
    { lat: 48.3066806, lng: 19.7605324 },
    { lat: 48.3025163, lng: 19.7600702 },
    { lat: 48.3016567, lng: 19.7599748 },
    { lat: 48.3013079, lng: 19.7602946 },
    { lat: 48.30089, lng: 19.7599904 },
    { lat: 48.3005935, lng: 19.7600596 },
    { lat: 48.3007036, lng: 19.7609471 },
    { lat: 48.3005898, lng: 19.7622443 },
    { lat: 48.3001982, lng: 19.7653919 },
    { lat: 48.2990496, lng: 19.7702005 },
    { lat: 48.298627, lng: 19.7725959 },
    { lat: 48.2986815, lng: 19.7725533 },
    { lat: 48.2988039, lng: 19.7725185 },
    { lat: 48.2986295, lng: 19.7736752 },
    { lat: 48.3008013, lng: 19.7732773 },
    { lat: 48.3009085, lng: 19.7736676 },
    { lat: 48.3019964, lng: 19.7743192 },
    { lat: 48.3032031, lng: 19.7753627 },
    { lat: 48.3042542, lng: 19.7764398 },
    { lat: 48.3046324, lng: 19.7767109 },
    { lat: 48.3045688, lng: 19.7771327 },
    { lat: 48.3045959, lng: 19.7772125 },
    { lat: 48.3057364, lng: 19.7772417 },
    { lat: 48.3075001, lng: 19.7771247 },
    { lat: 48.307716, lng: 19.778085 },
    { lat: 48.3082845, lng: 19.779633 },
    { lat: 48.308819, lng: 19.7804104 },
    { lat: 48.3087644, lng: 19.7812917 },
    { lat: 48.3093712, lng: 19.7812334 },
    { lat: 48.3094704, lng: 19.7845844 },
    { lat: 48.3095197, lng: 19.7849865 },
    { lat: 48.3105025, lng: 19.7886536 },
    { lat: 48.3106261, lng: 19.7888286 },
    { lat: 48.3108852, lng: 19.7887245 },
    { lat: 48.311104, lng: 19.7888292 },
    { lat: 48.3113818, lng: 19.7885122 },
    { lat: 48.3115267, lng: 19.7885035 },
    { lat: 48.311591, lng: 19.7885804 },
    { lat: 48.311701, lng: 19.7883391 },
    { lat: 48.3129244, lng: 19.7879314 },
    { lat: 48.3134039, lng: 19.7879198 },
    { lat: 48.3144951, lng: 19.7885679 },
    { lat: 48.3146475, lng: 19.7885001 },
    { lat: 48.3159353, lng: 19.7894764 },
    { lat: 48.3186122, lng: 19.7881874 },
    { lat: 48.3195825, lng: 19.7871459 },
    { lat: 48.319533, lng: 19.7865306 },
    { lat: 48.3305513, lng: 19.7872321 },
    { lat: 48.3314732, lng: 19.7975746 },
    { lat: 48.332765, lng: 19.7979474 },
    { lat: 48.3346037, lng: 19.7977924 },
    { lat: 48.335916, lng: 19.7970945 },
    { lat: 48.3368552, lng: 19.7967787 },
    { lat: 48.3377583, lng: 19.7967366 },
    { lat: 48.3385662, lng: 19.7962779 },
    { lat: 48.3403419, lng: 19.7947402 },
    { lat: 48.3409224, lng: 19.7944392 },
    { lat: 48.3435754, lng: 19.7927891 },
    { lat: 48.343963, lng: 19.792765 },
    { lat: 48.3444611, lng: 19.7926357 },
    { lat: 48.3446646, lng: 19.7922685 },
    { lat: 48.3448237, lng: 19.7923495 },
    { lat: 48.3449761, lng: 19.7922827 },
    { lat: 48.3450849, lng: 19.7921416 },
    { lat: 48.3452054, lng: 19.7921924 },
    { lat: 48.3458907, lng: 19.79174 },
    { lat: 48.3459852, lng: 19.7917353 },
    { lat: 48.3460024, lng: 19.7916107 },
    { lat: 48.3463311, lng: 19.7912187 },
    { lat: 48.3464926, lng: 19.7912041 },
    { lat: 48.3466491, lng: 19.7910215 },
    { lat: 48.3467362, lng: 19.7910798 },
    { lat: 48.3468098, lng: 19.7909369 },
    { lat: 48.3486699, lng: 19.7892165 },
    { lat: 48.3491946, lng: 19.7888755 },
    { lat: 48.3507011, lng: 19.7870061 },
    { lat: 48.3506593, lng: 19.7869522 },
    { lat: 48.3501173, lng: 19.7863633 },
    { lat: 48.3499203, lng: 19.7860066 },
    { lat: 48.3497595, lng: 19.7856046 },
    { lat: 48.3497171, lng: 19.7853116 },
    { lat: 48.3493742, lng: 19.7840425 },
    { lat: 48.3494706, lng: 19.7827518 },
    { lat: 48.349186, lng: 19.780641 },
    { lat: 48.3473532, lng: 19.7748446 },
    { lat: 48.3472086, lng: 19.7739888 },
    { lat: 48.3471954, lng: 19.773536 },
    { lat: 48.3470417, lng: 19.7729888 },
    { lat: 48.3472531, lng: 19.7723692 },
    { lat: 48.3472528, lng: 19.7721819 },
    { lat: 48.3471483, lng: 19.7718827 },
    { lat: 48.3471051, lng: 19.7719479 },
    { lat: 48.3467163, lng: 19.7722277 },
    { lat: 48.3465474, lng: 19.772231 },
    { lat: 48.3441618, lng: 19.7718388 },
    { lat: 48.3437117, lng: 19.7720071 },
    { lat: 48.3434629, lng: 19.7722655 },
    { lat: 48.3418078, lng: 19.7745667 },
    { lat: 48.3413996, lng: 19.7748402 },
    { lat: 48.340637, lng: 19.7751227 },
    { lat: 48.3401914, lng: 19.7750837 },
    { lat: 48.3395974, lng: 19.7747208 },
    { lat: 48.3385292, lng: 19.7739043 },
    { lat: 48.3367416, lng: 19.7730462 },
    { lat: 48.3361928, lng: 19.7729154 },
    { lat: 48.3354315, lng: 19.7732282 },
    { lat: 48.3331207, lng: 19.774661 },
    { lat: 48.3324732, lng: 19.7748463 },
    { lat: 48.3318793, lng: 19.7748971 },
    { lat: 48.3295246, lng: 19.7742083 },
    { lat: 48.3295569, lng: 19.7720039 },
    { lat: 48.3297276, lng: 19.7718102 },
    { lat: 48.3298029, lng: 19.7719125 },
    { lat: 48.3303887, lng: 19.7706276 },
    { lat: 48.3306063, lng: 19.7695898 },
  ],
  Mýtna: [
    { lat: 48.4636686, lng: 19.5680582 },
    { lat: 48.4644993, lng: 19.5679389 },
    { lat: 48.4655926, lng: 19.5679425 },
    { lat: 48.4657393, lng: 19.5668397 },
    { lat: 48.4656815, lng: 19.566563 },
    { lat: 48.4651488, lng: 19.5664957 },
    { lat: 48.4648916, lng: 19.5663406 },
    { lat: 48.4646303, lng: 19.5662717 },
    { lat: 48.4646842, lng: 19.5660925 },
    { lat: 48.4642825, lng: 19.5654169 },
    { lat: 48.4651955, lng: 19.56309 },
    { lat: 48.4664442, lng: 19.5629386 },
    { lat: 48.4665708, lng: 19.5626765 },
    { lat: 48.4676711, lng: 19.5626381 },
    { lat: 48.4693303, lng: 19.5613476 },
    { lat: 48.469979, lng: 19.560133 },
    { lat: 48.4690942, lng: 19.5588515 },
    { lat: 48.4695215, lng: 19.5582671 },
    { lat: 48.4706651, lng: 19.5576946 },
    { lat: 48.4713441, lng: 19.5575638 },
    { lat: 48.4717665, lng: 19.5560853 },
    { lat: 48.4725066, lng: 19.5549754 },
    { lat: 48.4719162, lng: 19.5547835 },
    { lat: 48.4709928, lng: 19.5547185 },
    { lat: 48.470892, lng: 19.5516199 },
    { lat: 48.4713368, lng: 19.5505318 },
    { lat: 48.4714085, lng: 19.5504995 },
    { lat: 48.4722328, lng: 19.5506578 },
    { lat: 48.4723806, lng: 19.5509301 },
    { lat: 48.4726659, lng: 19.5509616 },
    { lat: 48.4726853, lng: 19.5508644 },
    { lat: 48.4737611, lng: 19.5515478 },
    { lat: 48.4741232, lng: 19.5522641 },
    { lat: 48.4742984, lng: 19.5529629 },
    { lat: 48.4750135, lng: 19.5524849 },
    { lat: 48.4751599, lng: 19.5524587 },
    { lat: 48.4752624, lng: 19.5522854 },
    { lat: 48.4756349, lng: 19.5519068 },
    { lat: 48.475777, lng: 19.5519105 },
    { lat: 48.4758844, lng: 19.5518162 },
    { lat: 48.4760346, lng: 19.5518276 },
    { lat: 48.4761979, lng: 19.551702 },
    { lat: 48.4763814, lng: 19.5516712 },
    { lat: 48.4765997, lng: 19.5513967 },
    { lat: 48.4773035, lng: 19.551286 },
    { lat: 48.4773905, lng: 19.5511697 },
    { lat: 48.4775543, lng: 19.5511771 },
    { lat: 48.4776337, lng: 19.5510197 },
    { lat: 48.4777231, lng: 19.5510491 },
    { lat: 48.4781177, lng: 19.5506107 },
    { lat: 48.4782881, lng: 19.5505422 },
    { lat: 48.4786121, lng: 19.5497453 },
    { lat: 48.4797089, lng: 19.5487 },
    { lat: 48.4812623, lng: 19.5467239 },
    { lat: 48.481843, lng: 19.5465086 },
    { lat: 48.4826596, lng: 19.5457176 },
    { lat: 48.4837175, lng: 19.5450026 },
    { lat: 48.4841943, lng: 19.5444912 },
    { lat: 48.4844975, lng: 19.5443215 },
    { lat: 48.4849278, lng: 19.5437972 },
    { lat: 48.4850776, lng: 19.5433848 },
    { lat: 48.4855771, lng: 19.5428974 },
    { lat: 48.4857438, lng: 19.5424289 },
    { lat: 48.4859272, lng: 19.5421807 },
    { lat: 48.4861782, lng: 19.5410715 },
    { lat: 48.486906, lng: 19.5395225 },
    { lat: 48.4873103, lng: 19.5391493 },
    { lat: 48.4877102, lng: 19.5382178 },
    { lat: 48.4891509, lng: 19.537591 },
    { lat: 48.4909462, lng: 19.5372242 },
    { lat: 48.4916056, lng: 19.5370112 },
    { lat: 48.4918691, lng: 19.5370555 },
    { lat: 48.4952561, lng: 19.5340105 },
    { lat: 48.4963658, lng: 19.5349543 },
    { lat: 48.4965599, lng: 19.5345429 },
    { lat: 48.4968505, lng: 19.53364 },
    { lat: 48.4971902, lng: 19.5331867 },
    { lat: 48.4981983, lng: 19.5323653 },
    { lat: 48.4986818, lng: 19.532291 },
    { lat: 48.4989027, lng: 19.5327079 },
    { lat: 48.5006913, lng: 19.5327153 },
    { lat: 48.5006788, lng: 19.5347182 },
    { lat: 48.500827, lng: 19.5346558 },
    { lat: 48.5008989, lng: 19.5351152 },
    { lat: 48.5022985, lng: 19.5351395 },
    { lat: 48.5026196, lng: 19.5347223 },
    { lat: 48.502751, lng: 19.5332225 },
    { lat: 48.503079, lng: 19.5328081 },
    { lat: 48.5034804, lng: 19.533279 },
    { lat: 48.5039729, lng: 19.5331793 },
    { lat: 48.5038117, lng: 19.532786 },
    { lat: 48.5043388, lng: 19.5329236 },
    { lat: 48.5049829, lng: 19.5329641 },
    { lat: 48.5059656, lng: 19.5325244 },
    { lat: 48.5064727, lng: 19.5335269 },
    { lat: 48.5071916, lng: 19.533549 },
    { lat: 48.5073164, lng: 19.5338373 },
    { lat: 48.5078466, lng: 19.5337777 },
    { lat: 48.5084124, lng: 19.5334856 },
    { lat: 48.5083782, lng: 19.5332557 },
    { lat: 48.5097021, lng: 19.5328116 },
    { lat: 48.5102388, lng: 19.5329134 },
    { lat: 48.509144, lng: 19.53405 },
    { lat: 48.5092792, lng: 19.5346628 },
    { lat: 48.5099041, lng: 19.5344331 },
    { lat: 48.5104962, lng: 19.5340259 },
    { lat: 48.510742, lng: 19.5346908 },
    { lat: 48.5111006, lng: 19.5366529 },
    { lat: 48.511804, lng: 19.5362675 },
    { lat: 48.5115646, lng: 19.5370385 },
    { lat: 48.5115904, lng: 19.5372348 },
    { lat: 48.5109413, lng: 19.5383339 },
    { lat: 48.5113027, lng: 19.5388393 },
    { lat: 48.5109642, lng: 19.5393188 },
    { lat: 48.5107873, lng: 19.5397132 },
    { lat: 48.5110739, lng: 19.5403842 },
    { lat: 48.5111304, lng: 19.5407046 },
    { lat: 48.5105543, lng: 19.5415852 },
    { lat: 48.5104089, lng: 19.5418989 },
    { lat: 48.510413, lng: 19.5427237 },
    { lat: 48.5105337, lng: 19.5432687 },
    { lat: 48.5114355, lng: 19.5418034 },
    { lat: 48.511887, lng: 19.5408131 },
    { lat: 48.5118391, lng: 19.5406016 },
    { lat: 48.5124737, lng: 19.5393833 },
    { lat: 48.5131501, lng: 19.5385568 },
    { lat: 48.5142532, lng: 19.539114 },
    { lat: 48.514301, lng: 19.5397111 },
    { lat: 48.5149402, lng: 19.5400848 },
    { lat: 48.5157272, lng: 19.5402431 },
    { lat: 48.5160773, lng: 19.5393262 },
    { lat: 48.5162558, lng: 19.5394597 },
    { lat: 48.5164618, lng: 19.5388913 },
    { lat: 48.5168814, lng: 19.5382148 },
    { lat: 48.516988, lng: 19.5383281 },
    { lat: 48.5178065, lng: 19.5366934 },
    { lat: 48.5184014, lng: 19.5365962 },
    { lat: 48.519194, lng: 19.5361115 },
    { lat: 48.5188764, lng: 19.5369011 },
    { lat: 48.518212, lng: 19.5380388 },
    { lat: 48.5182566, lng: 19.5381255 },
    { lat: 48.5182009, lng: 19.5382134 },
    { lat: 48.5185585, lng: 19.538538 },
    { lat: 48.5191445, lng: 19.5377543 },
    { lat: 48.519503, lng: 19.5382227 },
    { lat: 48.5198958, lng: 19.5389482 },
    { lat: 48.5202752, lng: 19.5386232 },
    { lat: 48.5202943, lng: 19.5386688 },
    { lat: 48.5204252, lng: 19.5385731 },
    { lat: 48.5217549, lng: 19.5388454 },
    { lat: 48.5225666, lng: 19.5391433 },
    { lat: 48.5229281, lng: 19.5396786 },
    { lat: 48.5226379, lng: 19.5404732 },
    { lat: 48.5221623, lng: 19.5399806 },
    { lat: 48.520997, lng: 19.5409118 },
    { lat: 48.5208112, lng: 19.5413332 },
    { lat: 48.5206305, lng: 19.5412255 },
    { lat: 48.520248, lng: 19.5428326 },
    { lat: 48.5211185, lng: 19.5424302 },
    { lat: 48.5213529, lng: 19.5418153 },
    { lat: 48.5219102, lng: 19.5421455 },
    { lat: 48.5224628, lng: 19.5418881 },
    { lat: 48.5228998, lng: 19.5432542 },
    { lat: 48.5233976, lng: 19.5426371 },
    { lat: 48.523912, lng: 19.5425 },
    { lat: 48.524822, lng: 19.5431262 },
    { lat: 48.5248014, lng: 19.5432161 },
    { lat: 48.5251842, lng: 19.5432324 },
    { lat: 48.5253658, lng: 19.5431392 },
    { lat: 48.5253833, lng: 19.5429803 },
    { lat: 48.5260491, lng: 19.5425103 },
    { lat: 48.5264019, lng: 19.5425357 },
    { lat: 48.5264508, lng: 19.5431599 },
    { lat: 48.5268617, lng: 19.543175 },
    { lat: 48.5272379, lng: 19.5438071 },
    { lat: 48.527582, lng: 19.5436944 },
    { lat: 48.527827, lng: 19.5433575 },
    { lat: 48.5280296, lng: 19.5423985 },
    { lat: 48.5284088, lng: 19.5424376 },
    { lat: 48.5285441, lng: 19.5410661 },
    { lat: 48.5282418, lng: 19.5406433 },
    { lat: 48.5286485, lng: 19.5395098 },
    { lat: 48.5282947, lng: 19.5392203 },
    { lat: 48.5286397, lng: 19.5376289 },
    { lat: 48.5289903, lng: 19.5377299 },
    { lat: 48.5289747, lng: 19.5375598 },
    { lat: 48.5297552, lng: 19.537701 },
    { lat: 48.5303122, lng: 19.5384429 },
    { lat: 48.5300186, lng: 19.538893 },
    { lat: 48.5300411, lng: 19.5401169 },
    { lat: 48.5294035, lng: 19.5406879 },
    { lat: 48.5292717, lng: 19.5409597 },
    { lat: 48.5306887, lng: 19.542807 },
    { lat: 48.530916, lng: 19.5420647 },
    { lat: 48.5308477, lng: 19.5416317 },
    { lat: 48.5321234, lng: 19.540899 },
    { lat: 48.5317575, lng: 19.538945 },
    { lat: 48.5323408, lng: 19.5384461 },
    { lat: 48.5319645, lng: 19.5376633 },
    { lat: 48.5316545, lng: 19.537659 },
    { lat: 48.5314362, lng: 19.5375453 },
    { lat: 48.5314193, lng: 19.5373974 },
    { lat: 48.5317913, lng: 19.5371587 },
    { lat: 48.5321684, lng: 19.5371994 },
    { lat: 48.5327831, lng: 19.5365216 },
    { lat: 48.5324126, lng: 19.5363068 },
    { lat: 48.5322926, lng: 19.5358276 },
    { lat: 48.5322344, lng: 19.5358411 },
    { lat: 48.5322129, lng: 19.535667 },
    { lat: 48.53251, lng: 19.5355343 },
    { lat: 48.5328678, lng: 19.5355699 },
    { lat: 48.533078, lng: 19.535667 },
    { lat: 48.53289, lng: 19.535096 },
    { lat: 48.532485, lng: 19.532688 },
    { lat: 48.532508, lng: 19.532621 },
    { lat: 48.532313, lng: 19.531567 },
    { lat: 48.532189, lng: 19.531199 },
    { lat: 48.53209, lng: 19.53109 },
    { lat: 48.531919, lng: 19.531345 },
    { lat: 48.530989, lng: 19.531571 },
    { lat: 48.53087, lng: 19.531058 },
    { lat: 48.530888, lng: 19.530922 },
    { lat: 48.530603, lng: 19.529039 },
    { lat: 48.530229, lng: 19.528952 },
    { lat: 48.530055, lng: 19.529099 },
    { lat: 48.529917, lng: 19.528955 },
    { lat: 48.52986, lng: 19.528861 },
    { lat: 48.529477, lng: 19.527986 },
    { lat: 48.529016, lng: 19.528089 },
    { lat: 48.52835, lng: 19.52794 },
    { lat: 48.528338, lng: 19.528027 },
    { lat: 48.528114, lng: 19.527957 },
    { lat: 48.527613, lng: 19.527636 },
    { lat: 48.527466, lng: 19.528052 },
    { lat: 48.527195, lng: 19.528053 },
    { lat: 48.527081, lng: 19.528431 },
    { lat: 48.526856, lng: 19.528521 },
    { lat: 48.526787, lng: 19.528031 },
    { lat: 48.52687, lng: 19.527134 },
    { lat: 48.526703, lng: 19.526793 },
    { lat: 48.526601, lng: 19.526185 },
    { lat: 48.526478, lng: 19.525755 },
    { lat: 48.526326, lng: 19.525623 },
    { lat: 48.526332, lng: 19.525818 },
    { lat: 48.525975, lng: 19.525688 },
    { lat: 48.525972, lng: 19.525583 },
    { lat: 48.525587, lng: 19.52526 },
    { lat: 48.525443, lng: 19.525364 },
    { lat: 48.525297, lng: 19.525249 },
    { lat: 48.525305, lng: 19.52488 },
    { lat: 48.525244, lng: 19.524831 },
    { lat: 48.525237, lng: 19.5247 },
    { lat: 48.525148, lng: 19.524684 },
    { lat: 48.525114, lng: 19.524482 },
    { lat: 48.524874, lng: 19.524054 },
    { lat: 48.524866, lng: 19.523681 },
    { lat: 48.524741, lng: 19.523282 },
    { lat: 48.524828, lng: 19.522973 },
    { lat: 48.525033, lng: 19.522988 },
    { lat: 48.525077, lng: 19.52282 },
    { lat: 48.525054, lng: 19.522743 },
    { lat: 48.525106, lng: 19.522636 },
    { lat: 48.525489, lng: 19.522657 },
    { lat: 48.52587, lng: 19.52303 },
    { lat: 48.526339, lng: 19.522906 },
    { lat: 48.526422, lng: 19.52307 },
    { lat: 48.526959, lng: 19.522759 },
    { lat: 48.527217, lng: 19.522896 },
    { lat: 48.527298, lng: 19.522889 },
    { lat: 48.527565, lng: 19.521075 },
    { lat: 48.527071, lng: 19.519109 },
    { lat: 48.526915, lng: 19.518482 },
    { lat: 48.526574, lng: 19.516416 },
    { lat: 48.526204, lng: 19.515869 },
    { lat: 48.525847, lng: 19.514414 },
    { lat: 48.526046, lng: 19.512621 },
    { lat: 48.525515, lng: 19.51108 },
    { lat: 48.524265, lng: 19.508297 },
    { lat: 48.523966, lng: 19.508003 },
    { lat: 48.523156, lng: 19.506598 },
    { lat: 48.522966, lng: 19.505965 },
    { lat: 48.522759, lng: 19.505191 },
    { lat: 48.522772, lng: 19.504868 },
    { lat: 48.522785, lng: 19.504844 },
    { lat: 48.522773, lng: 19.504821 },
    { lat: 48.52268, lng: 19.504696 },
    { lat: 48.522507, lng: 19.504506 },
    { lat: 48.521948, lng: 19.503821 },
    { lat: 48.521785, lng: 19.503084 },
    { lat: 48.521624, lng: 19.502896 },
    { lat: 48.521555, lng: 19.502814 },
    { lat: 48.521326, lng: 19.502625 },
    { lat: 48.521187, lng: 19.501916 },
    { lat: 48.521231, lng: 19.501552 },
    { lat: 48.521105, lng: 19.501336 },
    { lat: 48.520941, lng: 19.501076 },
    { lat: 48.520801, lng: 19.500945 },
    { lat: 48.520242, lng: 19.500672 },
    { lat: 48.520085, lng: 19.500645 },
    { lat: 48.519606, lng: 19.500381 },
    { lat: 48.519464, lng: 19.500332 },
    { lat: 48.519269, lng: 19.500324 },
    { lat: 48.519105, lng: 19.500374 },
    { lat: 48.518941, lng: 19.500468 },
    { lat: 48.518599, lng: 19.500712 },
    { lat: 48.518291, lng: 19.500649 },
    { lat: 48.518125, lng: 19.500761 },
    { lat: 48.518058, lng: 19.500781 },
    { lat: 48.517919, lng: 19.500815 },
    { lat: 48.517129, lng: 19.500751 },
    { lat: 48.516935, lng: 19.500736 },
    { lat: 48.516639, lng: 19.500735 },
    { lat: 48.516213, lng: 19.501088 },
    { lat: 48.516066, lng: 19.501183 },
    { lat: 48.516029, lng: 19.501211 },
    { lat: 48.515985, lng: 19.501227 },
    { lat: 48.515733, lng: 19.501296 },
    { lat: 48.515495, lng: 19.501389 },
    { lat: 48.515255, lng: 19.501421 },
    { lat: 48.514945, lng: 19.501327 },
    { lat: 48.514615, lng: 19.500887 },
    { lat: 48.514506, lng: 19.500679 },
    { lat: 48.514357, lng: 19.500571 },
    { lat: 48.514104, lng: 19.500483 },
    { lat: 48.513811, lng: 19.500468 },
    { lat: 48.513575, lng: 19.500503 },
    { lat: 48.513141, lng: 19.500674 },
    { lat: 48.512726, lng: 19.500711 },
    { lat: 48.512296, lng: 19.500583 },
    { lat: 48.512024, lng: 19.500455 },
    { lat: 48.5116, lng: 19.500036 },
    { lat: 48.511128, lng: 19.499662 },
    { lat: 48.510852, lng: 19.499455 },
    { lat: 48.510546, lng: 19.499249 },
    { lat: 48.510497, lng: 19.499211 },
    { lat: 48.510418, lng: 19.499182 },
    { lat: 48.510326, lng: 19.499168 },
    { lat: 48.510212, lng: 19.499177 },
    { lat: 48.510037, lng: 19.499237 },
    { lat: 48.50994, lng: 19.499304 },
    { lat: 48.509632, lng: 19.499478 },
    { lat: 48.50941, lng: 19.499679 },
    { lat: 48.509202, lng: 19.499889 },
    { lat: 48.509031, lng: 19.499952 },
    { lat: 48.508859, lng: 19.499934 },
    { lat: 48.508803, lng: 19.499911 },
    { lat: 48.508011, lng: 19.49954 },
    { lat: 48.50759, lng: 19.499257 },
    { lat: 48.507426, lng: 19.499064 },
    { lat: 48.507265, lng: 19.498737 },
    { lat: 48.507184, lng: 19.498389 },
    { lat: 48.507017, lng: 19.498087 },
    { lat: 48.507046, lng: 19.497713 },
    { lat: 48.506971, lng: 19.497563 },
    { lat: 48.506863, lng: 19.497493 },
    { lat: 48.506477, lng: 19.497417 },
    { lat: 48.506331, lng: 19.497377 },
    { lat: 48.506105, lng: 19.497261 },
    { lat: 48.506002, lng: 19.497088 },
    { lat: 48.505931, lng: 19.49698 },
    { lat: 48.505907, lng: 19.496952 },
    { lat: 48.505871, lng: 19.49691 },
    { lat: 48.505826, lng: 19.496867 },
    { lat: 48.505808, lng: 19.496855 },
    { lat: 48.505762, lng: 19.496831 },
    { lat: 48.505725, lng: 19.496831 },
    { lat: 48.505602, lng: 19.496886 },
    { lat: 48.505447, lng: 19.49693 },
    { lat: 48.505343, lng: 19.496963 },
    { lat: 48.50527, lng: 19.496987 },
    { lat: 48.505208, lng: 19.496994 },
    { lat: 48.5052329, lng: 19.4970683 },
    { lat: 48.5056864, lng: 19.4969325 },
    { lat: 48.5057969, lng: 19.4969571 },
    { lat: 48.5050685, lng: 19.4973624 },
    { lat: 48.5043126, lng: 19.4981734 },
    { lat: 48.5034345, lng: 19.4992358 },
    { lat: 48.5025106, lng: 19.5000193 },
    { lat: 48.5025006, lng: 19.4999248 },
    { lat: 48.5021597, lng: 19.4999886 },
    { lat: 48.5017759, lng: 19.500192 },
    { lat: 48.5015128, lng: 19.5004247 },
    { lat: 48.5009284, lng: 19.501186 },
    { lat: 48.500574, lng: 19.5018557 },
    { lat: 48.5001746, lng: 19.5023581 },
    { lat: 48.4999774, lng: 19.5025064 },
    { lat: 48.4996439, lng: 19.5031153 },
    { lat: 48.4992994, lng: 19.5034847 },
    { lat: 48.4987763, lng: 19.5037236 },
    { lat: 48.4986032, lng: 19.5037332 },
    { lat: 48.4983241, lng: 19.5036225 },
    { lat: 48.4978472, lng: 19.5041558 },
    { lat: 48.4968366, lng: 19.5042543 },
    { lat: 48.4968219, lng: 19.5042075 },
    { lat: 48.4954087, lng: 19.5040576 },
    { lat: 48.4951937, lng: 19.5042333 },
    { lat: 48.4947774, lng: 19.5047605 },
    { lat: 48.4944729, lng: 19.5053893 },
    { lat: 48.4939819, lng: 19.5057868 },
    { lat: 48.4937717, lng: 19.5062093 },
    { lat: 48.4934395, lng: 19.5064353 },
    { lat: 48.4933956, lng: 19.5065585 },
    { lat: 48.4936163, lng: 19.5071134 },
    { lat: 48.4936019, lng: 19.5073175 },
    { lat: 48.49298, lng: 19.508074 },
    { lat: 48.4929439, lng: 19.5080227 },
    { lat: 48.4918668, lng: 19.5090995 },
    { lat: 48.4914357, lng: 19.5094029 },
    { lat: 48.4910372, lng: 19.5095601 },
    { lat: 48.4909232, lng: 19.5096776 },
    { lat: 48.4907011, lng: 19.5101147 },
    { lat: 48.4903456, lng: 19.5105665 },
    { lat: 48.4897517, lng: 19.511102 },
    { lat: 48.4888646, lng: 19.5116101 },
    { lat: 48.4875756, lng: 19.5127835 },
    { lat: 48.4874372, lng: 19.512982 },
    { lat: 48.486929, lng: 19.5143964 },
    { lat: 48.4867301, lng: 19.51463 },
    { lat: 48.4860278, lng: 19.51509 },
    { lat: 48.4854821, lng: 19.5158107 },
    { lat: 48.4851938, lng: 19.5158409 },
    { lat: 48.4840932, lng: 19.5155581 },
    { lat: 48.4835102, lng: 19.5155232 },
    { lat: 48.4829144, lng: 19.5157788 },
    { lat: 48.4827111, lng: 19.5159887 },
    { lat: 48.4826711, lng: 19.5158991 },
    { lat: 48.4826603, lng: 19.5154729 },
    { lat: 48.4821524, lng: 19.5145531 },
    { lat: 48.4822392, lng: 19.5143373 },
    { lat: 48.4821172, lng: 19.5140833 },
    { lat: 48.4820487, lng: 19.5137719 },
    { lat: 48.4820205, lng: 19.513117 },
    { lat: 48.4820978, lng: 19.5121086 },
    { lat: 48.4818811, lng: 19.5121494 },
    { lat: 48.4814273, lng: 19.5133012 },
    { lat: 48.481387, lng: 19.5132158 },
    { lat: 48.4811702, lng: 19.512714 },
    { lat: 48.4811, lng: 19.512754 },
    { lat: 48.4808719, lng: 19.5119649 },
    { lat: 48.4807696, lng: 19.5115547 },
    { lat: 48.4808857, lng: 19.5114612 },
    { lat: 48.4807048, lng: 19.5103744 },
    { lat: 48.480443, lng: 19.5092744 },
    { lat: 48.4803913, lng: 19.5088145 },
    { lat: 48.4803738, lng: 19.5083596 },
    { lat: 48.4805366, lng: 19.5083734 },
    { lat: 48.4802557, lng: 19.5070538 },
    { lat: 48.4801644, lng: 19.5061988 },
    { lat: 48.4798713, lng: 19.5061986 },
    { lat: 48.4791402, lng: 19.5064234 },
    { lat: 48.4786077, lng: 19.5056544 },
    { lat: 48.4784719, lng: 19.5053543 },
    { lat: 48.4784049, lng: 19.5053898 },
    { lat: 48.4783344, lng: 19.5051325 },
    { lat: 48.4781403, lng: 19.5048577 },
    { lat: 48.4777707, lng: 19.5044955 },
    { lat: 48.4771376, lng: 19.5034817 },
    { lat: 48.477069, lng: 19.5033117 },
    { lat: 48.4771538, lng: 19.5032009 },
    { lat: 48.4771115, lng: 19.5021867 },
    { lat: 48.4768817, lng: 19.5013392 },
    { lat: 48.4764643, lng: 19.5007007 },
    { lat: 48.4766303, lng: 19.5004609 },
    { lat: 48.4763802, lng: 19.4996603 },
    { lat: 48.4762127, lng: 19.4994307 },
    { lat: 48.475777, lng: 19.4992214 },
    { lat: 48.4757414, lng: 19.4989641 },
    { lat: 48.4752267, lng: 19.4986551 },
    { lat: 48.4750623, lng: 19.4984355 },
    { lat: 48.474959, lng: 19.4976912 },
    { lat: 48.4746329, lng: 19.4971721 },
    { lat: 48.4745433, lng: 19.4964581 },
    { lat: 48.4744837, lng: 19.4963141 },
    { lat: 48.4743949, lng: 19.496317 },
    { lat: 48.4743651, lng: 19.4960175 },
    { lat: 48.474238, lng: 19.4958274 },
    { lat: 48.4743353, lng: 19.4956446 },
    { lat: 48.4741934, lng: 19.4951828 },
    { lat: 48.474233, lng: 19.4950629 },
    { lat: 48.4746652, lng: 19.4946162 },
    { lat: 48.474569, lng: 19.4944615 },
    { lat: 48.4744126, lng: 19.4946125 },
    { lat: 48.4743325, lng: 19.4944647 },
    { lat: 48.4740517, lng: 19.4946337 },
    { lat: 48.4737731, lng: 19.4948935 },
    { lat: 48.4733773, lng: 19.4943281 },
    { lat: 48.4726094, lng: 19.494977 },
    { lat: 48.4714506, lng: 19.496619 },
    { lat: 48.4718464, lng: 19.4971854 },
    { lat: 48.4714102, lng: 19.4984458 },
    { lat: 48.4713751, lng: 19.5000458 },
    { lat: 48.4714311, lng: 19.5001596 },
    { lat: 48.4717973, lng: 19.5003772 },
    { lat: 48.4713285, lng: 19.5023924 },
    { lat: 48.4709771, lng: 19.5031047 },
    { lat: 48.4706798, lng: 19.5032275 },
    { lat: 48.4712543, lng: 19.5058514 },
    { lat: 48.47076, lng: 19.5070545 },
    { lat: 48.4704156, lng: 19.5074755 },
    { lat: 48.4700415, lng: 19.5076227 },
    { lat: 48.4701626, lng: 19.5082183 },
    { lat: 48.4704409, lng: 19.5082918 },
    { lat: 48.4705756, lng: 19.50862 },
    { lat: 48.4709897, lng: 19.5089702 },
    { lat: 48.4709196, lng: 19.5093788 },
    { lat: 48.4703151, lng: 19.5094655 },
    { lat: 48.4700453, lng: 19.5108905 },
    { lat: 48.470153, lng: 19.5115664 },
    { lat: 48.4689588, lng: 19.5131775 },
    { lat: 48.4681963, lng: 19.5153073 },
    { lat: 48.467499, lng: 19.5191905 },
    { lat: 48.4681419, lng: 19.5204962 },
    { lat: 48.4667896, lng: 19.5225695 },
    { lat: 48.4662774, lng: 19.5261175 },
    { lat: 48.4652001, lng: 19.5321541 },
    { lat: 48.4648648, lng: 19.5335568 },
    { lat: 48.4619552, lng: 19.5392619 },
    { lat: 48.4612591, lng: 19.5410528 },
    { lat: 48.4611364, lng: 19.5412026 },
    { lat: 48.4607127, lng: 19.542331 },
    { lat: 48.4605597, lng: 19.5429218 },
    { lat: 48.4605479, lng: 19.5436238 },
    { lat: 48.460632, lng: 19.5450829 },
    { lat: 48.4596197, lng: 19.5468664 },
    { lat: 48.4592966, lng: 19.5475586 },
    { lat: 48.4591539, lng: 19.5480076 },
    { lat: 48.4588236, lng: 19.5482003 },
    { lat: 48.4581078, lng: 19.5488359 },
    { lat: 48.4575035, lng: 19.5496627 },
    { lat: 48.4567864, lng: 19.5503163 },
    { lat: 48.4563496, lng: 19.5509147 },
    { lat: 48.4569021, lng: 19.5521965 },
    { lat: 48.4573736, lng: 19.5528175 },
    { lat: 48.4579551, lng: 19.5528675 },
    { lat: 48.4604354, lng: 19.5528433 },
    { lat: 48.4613762, lng: 19.5541391 },
    { lat: 48.4613012, lng: 19.5544242 },
    { lat: 48.4615206, lng: 19.5548307 },
    { lat: 48.4616545, lng: 19.5559576 },
    { lat: 48.4621409, lng: 19.5580346 },
    { lat: 48.4622898, lng: 19.5595366 },
    { lat: 48.4624179, lng: 19.5595734 },
    { lat: 48.4624903, lng: 19.5602466 },
    { lat: 48.4609026, lng: 19.5628745 },
    { lat: 48.4603192, lng: 19.5635733 },
    { lat: 48.4601354, lng: 19.5642108 },
    { lat: 48.459864, lng: 19.5644086 },
    { lat: 48.4592766, lng: 19.5651444 },
    { lat: 48.458286, lng: 19.565803 },
    { lat: 48.4569514, lng: 19.566413 },
    { lat: 48.4560925, lng: 19.5670717 },
    { lat: 48.4560784, lng: 19.5671811 },
    { lat: 48.4573833, lng: 19.5671557 },
    { lat: 48.4577657, lng: 19.5672858 },
    { lat: 48.4584875, lng: 19.5670783 },
    { lat: 48.4594266, lng: 19.5670631 },
    { lat: 48.4609863, lng: 19.5671405 },
    { lat: 48.4613823, lng: 19.5670768 },
    { lat: 48.4613981, lng: 19.5671749 },
    { lat: 48.461891, lng: 19.5669598 },
    { lat: 48.4617341, lng: 19.5683856 },
    { lat: 48.4618937, lng: 19.5685722 },
    { lat: 48.4636686, lng: 19.5680582 },
  ],
  ŠiatorskáBukovinka: [
    { lat: 48.185623, lng: 19.892999 },
    { lat: 48.1851971, lng: 19.8926271 },
    { lat: 48.1848328, lng: 19.8910292 },
    { lat: 48.1841854, lng: 19.8897326 },
    { lat: 48.1839255, lng: 19.8889581 },
    { lat: 48.183597, lng: 19.8885776 },
    { lat: 48.1830388, lng: 19.8883089 },
    { lat: 48.1823987, lng: 19.8876778 },
    { lat: 48.1818604, lng: 19.8872402 },
    { lat: 48.1810797, lng: 19.8864035 },
    { lat: 48.1807373, lng: 19.8859375 },
    { lat: 48.1804221, lng: 19.8852945 },
    { lat: 48.1803127, lng: 19.8840915 },
    { lat: 48.1803406, lng: 19.8831209 },
    { lat: 48.180179, lng: 19.8824358 },
    { lat: 48.1801841, lng: 19.8820573 },
    { lat: 48.1803175, lng: 19.8813656 },
    { lat: 48.1807276, lng: 19.880401 },
    { lat: 48.1807184, lng: 19.8793821 },
    { lat: 48.1807693, lng: 19.878818 },
    { lat: 48.1809682, lng: 19.8777641 },
    { lat: 48.1810418, lng: 19.8760029 },
    { lat: 48.1811129, lng: 19.8755901 },
    { lat: 48.1807581, lng: 19.8747342 },
    { lat: 48.1806642, lng: 19.8743119 },
    { lat: 48.1801417, lng: 19.8728888 },
    { lat: 48.1807432, lng: 19.8714298 },
    { lat: 48.1808824, lng: 19.8703545 },
    { lat: 48.181237, lng: 19.8695184 },
    { lat: 48.1819113, lng: 19.8682831 },
    { lat: 48.182296, lng: 19.8669972 },
    { lat: 48.1829993, lng: 19.8658882 },
    { lat: 48.1832642, lng: 19.8655571 },
    { lat: 48.1836627, lng: 19.8648738 },
    { lat: 48.1840625, lng: 19.8639891 },
    { lat: 48.1848371, lng: 19.8611002 },
    { lat: 48.1854396, lng: 19.8605523 },
    { lat: 48.1867211, lng: 19.859835 },
    { lat: 48.1869981, lng: 19.8597297 },
    { lat: 48.1871272, lng: 19.859799 },
    { lat: 48.1875194, lng: 19.8595406 },
    { lat: 48.1876423, lng: 19.8593366 },
    { lat: 48.1877477, lng: 19.8592748 },
    { lat: 48.1879192, lng: 19.859358 },
    { lat: 48.1880236, lng: 19.8591424 },
    { lat: 48.1882992, lng: 19.8581182 },
    { lat: 48.1888934, lng: 19.8563968 },
    { lat: 48.1894093, lng: 19.8557213 },
    { lat: 48.1894548, lng: 19.8555513 },
    { lat: 48.1893935, lng: 19.8550478 },
    { lat: 48.189415, lng: 19.8544239 },
    { lat: 48.1897699, lng: 19.8535208 },
    { lat: 48.190515, lng: 19.8526528 },
    { lat: 48.1906951, lng: 19.8520778 },
    { lat: 48.1908552, lng: 19.8514327 },
    { lat: 48.1908654, lng: 19.8511259 },
    { lat: 48.1906894, lng: 19.8502925 },
    { lat: 48.190868, lng: 19.8489351 },
    { lat: 48.1916909, lng: 19.8476488 },
    { lat: 48.1923761, lng: 19.8469578 },
    { lat: 48.1925244, lng: 19.8463801 },
    { lat: 48.1926038, lng: 19.8457485 },
    { lat: 48.1931027, lng: 19.8450946 },
    { lat: 48.1936526, lng: 19.8438337 },
    { lat: 48.1938836, lng: 19.8411474 },
    { lat: 48.194833, lng: 19.8415 },
    { lat: 48.1957279, lng: 19.8417156 },
    { lat: 48.1967367, lng: 19.8418011 },
    { lat: 48.197953, lng: 19.8422838 },
    { lat: 48.1983752, lng: 19.8426632 },
    { lat: 48.1992394, lng: 19.8431047 },
    { lat: 48.1996741, lng: 19.8434648 },
    { lat: 48.2013407, lng: 19.841998 },
    { lat: 48.2020577, lng: 19.8415943 },
    { lat: 48.2034773, lng: 19.8404841 },
    { lat: 48.2044882, lng: 19.8401802 },
    { lat: 48.2041814, lng: 19.8396578 },
    { lat: 48.2055878, lng: 19.8384807 },
    { lat: 48.2055855, lng: 19.837059 },
    { lat: 48.2079649, lng: 19.8364896 },
    { lat: 48.2073826, lng: 19.8359241 },
    { lat: 48.2071469, lng: 19.8358949 },
    { lat: 48.2069752, lng: 19.835342 },
    { lat: 48.2072401, lng: 19.8346245 },
    { lat: 48.2079706, lng: 19.8355654 },
    { lat: 48.2091839, lng: 19.8332653 },
    { lat: 48.2094001, lng: 19.8332912 },
    { lat: 48.2096524, lng: 19.8335964 },
    { lat: 48.2100117, lng: 19.833775 },
    { lat: 48.2102577, lng: 19.8340176 },
    { lat: 48.210231, lng: 19.8343356 },
    { lat: 48.2108838, lng: 19.8347027 },
    { lat: 48.2109446, lng: 19.8344209 },
    { lat: 48.2110686, lng: 19.8342926 },
    { lat: 48.2116413, lng: 19.8345619 },
    { lat: 48.2117398, lng: 19.8344173 },
    { lat: 48.2117144, lng: 19.8340136 },
    { lat: 48.2118304, lng: 19.8338589 },
    { lat: 48.2120742, lng: 19.8308248 },
    { lat: 48.2112255, lng: 19.8308471 },
    { lat: 48.2104935, lng: 19.831006 },
    { lat: 48.2095206, lng: 19.8310927 },
    { lat: 48.2092038, lng: 19.8310399 },
    { lat: 48.2085738, lng: 19.8308045 },
    { lat: 48.2076724, lng: 19.8303142 },
    { lat: 48.2072162, lng: 19.8299021 },
    { lat: 48.206431, lng: 19.8289243 },
    { lat: 48.2035679, lng: 19.8248224 },
    { lat: 48.2031473, lng: 19.8243762 },
    { lat: 48.2036378, lng: 19.8245694 },
    { lat: 48.2032402, lng: 19.8239751 },
    { lat: 48.2028446, lng: 19.8236903 },
    { lat: 48.2028408, lng: 19.8234742 },
    { lat: 48.2029904, lng: 19.8224929 },
    { lat: 48.2035169, lng: 19.8211604 },
    { lat: 48.2044415, lng: 19.8196598 },
    { lat: 48.2052843, lng: 19.8188669 },
    { lat: 48.2057254, lng: 19.8182858 },
    { lat: 48.2059586, lng: 19.8176438 },
    { lat: 48.2054142, lng: 19.8168686 },
    { lat: 48.2048213, lng: 19.8166043 },
    { lat: 48.2044164, lng: 19.8162379 },
    { lat: 48.2040072, lng: 19.816182 },
    { lat: 48.2038966, lng: 19.816071 },
    { lat: 48.2033427, lng: 19.8158495 },
    { lat: 48.202861, lng: 19.8157827 },
    { lat: 48.2022808, lng: 19.8152972 },
    { lat: 48.2019927, lng: 19.8142027 },
    { lat: 48.2018345, lng: 19.8131358 },
    { lat: 48.2018055, lng: 19.8120393 },
    { lat: 48.2015659, lng: 19.8116097 },
    { lat: 48.2011751, lng: 19.8107147 },
    { lat: 48.200264, lng: 19.8090568 },
    { lat: 48.1987869, lng: 19.8074641 },
    { lat: 48.1984156, lng: 19.806837 },
    { lat: 48.1971549, lng: 19.8054785 },
    { lat: 48.1960986, lng: 19.8053853 },
    { lat: 48.195033, lng: 19.8050335 },
    { lat: 48.1939401, lng: 19.8048231 },
    { lat: 48.19311, lng: 19.8041908 },
    { lat: 48.1926899, lng: 19.8032487 },
    { lat: 48.192912, lng: 19.801385 },
    { lat: 48.192394, lng: 19.802002 },
    { lat: 48.191494, lng: 19.802086 },
    { lat: 48.190937, lng: 19.802024 },
    { lat: 48.190064, lng: 19.801929 },
    { lat: 48.189379, lng: 19.801936 },
    { lat: 48.188756, lng: 19.80263 },
    { lat: 48.188353, lng: 19.803081 },
    { lat: 48.187675, lng: 19.803805 },
    { lat: 48.187107, lng: 19.80441 },
    { lat: 48.186775, lng: 19.804743 },
    { lat: 48.186092, lng: 19.805428 },
    { lat: 48.185475, lng: 19.805601 },
    { lat: 48.184945, lng: 19.80575 },
    { lat: 48.184929, lng: 19.805755 },
    { lat: 48.184822, lng: 19.805786 },
    { lat: 48.183733, lng: 19.805283 },
    { lat: 48.183109, lng: 19.804849 },
    { lat: 48.182647, lng: 19.803986 },
    { lat: 48.181815, lng: 19.802648 },
    { lat: 48.181312, lng: 19.801874 },
    { lat: 48.180674, lng: 19.80086 },
    { lat: 48.180405, lng: 19.800433 },
    { lat: 48.180058, lng: 19.800107 },
    { lat: 48.179819, lng: 19.799883 },
    { lat: 48.179169, lng: 19.799039 },
    { lat: 48.178817, lng: 19.798351 },
    { lat: 48.178684, lng: 19.798118 },
    { lat: 48.178459, lng: 19.797721 },
    { lat: 48.178303, lng: 19.796839 },
    { lat: 48.178163, lng: 19.796319 },
    { lat: 48.17801, lng: 19.795751 },
    { lat: 48.177502, lng: 19.795358 },
    { lat: 48.177041, lng: 19.795123 },
    { lat: 48.176537, lng: 19.794501 },
    { lat: 48.176034, lng: 19.793882 },
    { lat: 48.175942, lng: 19.793235 },
    { lat: 48.175582, lng: 19.792433 },
    { lat: 48.175224, lng: 19.791634 },
    { lat: 48.174459, lng: 19.790671 },
    { lat: 48.174032, lng: 19.790031 },
    { lat: 48.173778, lng: 19.789654 },
    { lat: 48.173337, lng: 19.788708 },
    { lat: 48.173104, lng: 19.788428 },
    { lat: 48.172023, lng: 19.789034 },
    { lat: 48.17142, lng: 19.789315 },
    { lat: 48.171042, lng: 19.789481 },
    { lat: 48.170433, lng: 19.789459 },
    { lat: 48.170115, lng: 19.789438 },
    { lat: 48.169541, lng: 19.789488 },
    { lat: 48.168914, lng: 19.789542 },
    { lat: 48.168274, lng: 19.789588 },
    { lat: 48.167844, lng: 19.789227 },
    { lat: 48.167904, lng: 19.788205 },
    { lat: 48.167587, lng: 19.78741 },
    { lat: 48.167297, lng: 19.786834 },
    { lat: 48.167012, lng: 19.786975 },
    { lat: 48.166739, lng: 19.786513 },
    { lat: 48.166606, lng: 19.785988 },
    { lat: 48.166241, lng: 19.785673 },
    { lat: 48.165895, lng: 19.784605 },
    { lat: 48.165757, lng: 19.784209 },
    { lat: 48.165695, lng: 19.783277 },
    { lat: 48.1652821, lng: 19.7825358 },
    { lat: 48.165039, lng: 19.782415 },
    { lat: 48.164916, lng: 19.782466 },
    { lat: 48.164663, lng: 19.782656 },
    { lat: 48.164382, lng: 19.783105 },
    { lat: 48.164206, lng: 19.783308 },
    { lat: 48.164083, lng: 19.783586 },
    { lat: 48.163724, lng: 19.783973 },
    { lat: 48.162889, lng: 19.784896 },
    { lat: 48.162434, lng: 19.785196 },
    { lat: 48.161731, lng: 19.785788 },
    { lat: 48.161422, lng: 19.786188 },
    { lat: 48.160977, lng: 19.786388 },
    { lat: 48.160684, lng: 19.786413 },
    { lat: 48.160609, lng: 19.786506 },
    { lat: 48.160338, lng: 19.786846 },
    { lat: 48.159957, lng: 19.787349 },
    { lat: 48.159723, lng: 19.787981 },
    { lat: 48.159723, lng: 19.788667 },
    { lat: 48.15959, lng: 19.789144 },
    { lat: 48.159186, lng: 19.789531 },
    { lat: 48.1582517, lng: 19.7896834 },
    { lat: 48.1579622, lng: 19.7899382 },
    { lat: 48.1576242, lng: 19.7902382 },
    { lat: 48.1571062, lng: 19.7906992 },
    { lat: 48.1566987, lng: 19.7910637 },
    { lat: 48.1560212, lng: 19.7916642 },
    { lat: 48.1558052, lng: 19.7918562 },
    { lat: 48.1555202, lng: 19.7921102 },
    { lat: 48.1550932, lng: 19.7924902 },
    { lat: 48.1547314, lng: 19.793086 },
    { lat: 48.1543182, lng: 19.7936602 },
    { lat: 48.1539582, lng: 19.7941812 },
    { lat: 48.1537902, lng: 19.7944302 },
    { lat: 48.1535782, lng: 19.7947442 },
    { lat: 48.1535187, lng: 19.7948001 },
    { lat: 48.153623, lng: 19.795617 },
    { lat: 48.153634, lng: 19.795887 },
    { lat: 48.153637, lng: 19.796461 },
    { lat: 48.153576, lng: 19.796783 },
    { lat: 48.153685, lng: 19.797447 },
    { lat: 48.153745, lng: 19.797992 },
    { lat: 48.153869, lng: 19.798646 },
    { lat: 48.154044, lng: 19.799241 },
    { lat: 48.153923, lng: 19.800445 },
    { lat: 48.153986, lng: 19.801009 },
    { lat: 48.154219, lng: 19.801702 },
    { lat: 48.1546105, lng: 19.802215 },
    { lat: 48.1548898, lng: 19.8029104 },
    { lat: 48.154858, lng: 19.803559 },
    { lat: 48.154863, lng: 19.803887 },
    { lat: 48.1548256, lng: 19.8041935 },
    { lat: 48.154755, lng: 19.804497 },
    { lat: 48.1546387, lng: 19.8047087 },
    { lat: 48.154445, lng: 19.805023 },
    { lat: 48.154279, lng: 19.806058 },
    { lat: 48.1541217, lng: 19.806499 },
    { lat: 48.15451, lng: 19.8065 },
    { lat: 48.155134, lng: 19.806508 },
    { lat: 48.155694, lng: 19.806562 },
    { lat: 48.1561487, lng: 19.8066375 },
    { lat: 48.156418, lng: 19.806842 },
    { lat: 48.156723, lng: 19.807211 },
    { lat: 48.156901, lng: 19.807362 },
    { lat: 48.1574218, lng: 19.807834 },
    { lat: 48.158067, lng: 19.807652 },
    { lat: 48.1584165, lng: 19.8076513 },
    { lat: 48.158684, lng: 19.807944 },
    { lat: 48.1593056, lng: 19.8082647 },
    { lat: 48.159597, lng: 19.808197 },
    { lat: 48.1600514, lng: 19.8078858 },
    { lat: 48.160691, lng: 19.808059 },
    { lat: 48.160941, lng: 19.807646 },
    { lat: 48.161022, lng: 19.807535 },
    { lat: 48.161262, lng: 19.807157 },
    { lat: 48.161685, lng: 19.806475 },
    { lat: 48.16179, lng: 19.806395 },
    { lat: 48.161835, lng: 19.806391 },
    { lat: 48.161909, lng: 19.806527 },
    { lat: 48.16192, lng: 19.806622 },
    { lat: 48.162013, lng: 19.80663 },
    { lat: 48.162061, lng: 19.806584 },
    { lat: 48.162121, lng: 19.806652 },
    { lat: 48.16211, lng: 19.806802 },
    { lat: 48.162172, lng: 19.806937 },
    { lat: 48.162242, lng: 19.806966 },
    { lat: 48.162303, lng: 19.80711 },
    { lat: 48.162298, lng: 19.807218 },
    { lat: 48.162238, lng: 19.807319 },
    { lat: 48.162249, lng: 19.807461 },
    { lat: 48.162285, lng: 19.807587 },
    { lat: 48.162341, lng: 19.807693 },
    { lat: 48.16237, lng: 19.807763 },
    { lat: 48.162395, lng: 19.807939 },
    { lat: 48.162397, lng: 19.808044 },
    { lat: 48.162407, lng: 19.80815 },
    { lat: 48.162452, lng: 19.808186 },
    { lat: 48.16249, lng: 19.808177 },
    { lat: 48.162527, lng: 19.808209 },
    { lat: 48.162526, lng: 19.808267 },
    { lat: 48.162473, lng: 19.808308 },
    { lat: 48.162444, lng: 19.808407 },
    { lat: 48.162464, lng: 19.808482 },
    { lat: 48.162492, lng: 19.808526 },
    { lat: 48.16253, lng: 19.808562 },
    { lat: 48.162601, lng: 19.808661 },
    { lat: 48.162711, lng: 19.808657 },
    { lat: 48.162838, lng: 19.808593 },
    { lat: 48.162854, lng: 19.80862 },
    { lat: 48.162861, lng: 19.808685 },
    { lat: 48.162841, lng: 19.808754 },
    { lat: 48.162838, lng: 19.80884 },
    { lat: 48.162872, lng: 19.808948 },
    { lat: 48.162963, lng: 19.809025 },
    { lat: 48.163079, lng: 19.809118 },
    { lat: 48.163175, lng: 19.809247 },
    { lat: 48.1633312, lng: 19.8095578 },
    { lat: 48.1633651, lng: 19.8096601 },
    { lat: 48.163386, lng: 19.809807 },
    { lat: 48.16331, lng: 19.810071 },
    { lat: 48.163284, lng: 19.810411 },
    { lat: 48.163326, lng: 19.810842 },
    { lat: 48.163295, lng: 19.811174 },
    { lat: 48.16333, lng: 19.811534 },
    { lat: 48.163325, lng: 19.811777 },
    { lat: 48.163383, lng: 19.812008 },
    { lat: 48.163446, lng: 19.812159 },
    { lat: 48.163533, lng: 19.812281 },
    { lat: 48.163629, lng: 19.812383 },
    { lat: 48.163934, lng: 19.812639 },
    { lat: 48.164094, lng: 19.81283 },
    { lat: 48.164277, lng: 19.812922 },
    { lat: 48.164516, lng: 19.813153 },
    { lat: 48.164909, lng: 19.813332 },
    { lat: 48.164995, lng: 19.813399 },
    { lat: 48.165083, lng: 19.813427 },
    { lat: 48.165184, lng: 19.813411 },
    { lat: 48.165347, lng: 19.813406 },
    { lat: 48.165346, lng: 19.813382 },
    { lat: 48.16539, lng: 19.813363 },
    { lat: 48.165459, lng: 19.813366 },
    { lat: 48.165866, lng: 19.813508 },
    { lat: 48.166053, lng: 19.813607 },
    { lat: 48.166253, lng: 19.813735 },
    { lat: 48.166517, lng: 19.81372 },
    { lat: 48.166834, lng: 19.813759 },
    { lat: 48.166901, lng: 19.813778 },
    { lat: 48.167015, lng: 19.813846 },
    { lat: 48.167069, lng: 19.813979 },
    { lat: 48.16711, lng: 19.814076 },
    { lat: 48.167147, lng: 19.814199 },
    { lat: 48.167204, lng: 19.814473 },
    { lat: 48.16728, lng: 19.814888 },
    { lat: 48.167367, lng: 19.815222 },
    { lat: 48.167396, lng: 19.815293 },
    { lat: 48.167501, lng: 19.815347 },
    { lat: 48.167617, lng: 19.815383 },
    { lat: 48.167667, lng: 19.81542 },
    { lat: 48.167669, lng: 19.815454 },
    { lat: 48.167711, lng: 19.815544 },
    { lat: 48.167709, lng: 19.815594 },
    { lat: 48.167741, lng: 19.815706 },
    { lat: 48.16776, lng: 19.815984 },
    { lat: 48.167731, lng: 19.816097 },
    { lat: 48.167719, lng: 19.816222 },
    { lat: 48.167712, lng: 19.816319 },
    { lat: 48.167773, lng: 19.816439 },
    { lat: 48.167777, lng: 19.81653 },
    { lat: 48.167862, lng: 19.816716 },
    { lat: 48.167909, lng: 19.816819 },
    { lat: 48.167964, lng: 19.816988 },
    { lat: 48.167998, lng: 19.81706 },
    { lat: 48.168024, lng: 19.817122 },
    { lat: 48.168022, lng: 19.817267 },
    { lat: 48.168052, lng: 19.817353 },
    { lat: 48.168082, lng: 19.817553 },
    { lat: 48.168082, lng: 19.817737 },
    { lat: 48.168082, lng: 19.817866 },
    { lat: 48.16814, lng: 19.818078 },
    { lat: 48.168219, lng: 19.818354 },
    { lat: 48.168287, lng: 19.818543 },
    { lat: 48.168317, lng: 19.818682 },
    { lat: 48.16831, lng: 19.818843 },
    { lat: 48.168331, lng: 19.819034 },
    { lat: 48.168365, lng: 19.819176 },
    { lat: 48.168396, lng: 19.819318 },
    { lat: 48.168432, lng: 19.819533 },
    { lat: 48.168404, lng: 19.819546 },
    { lat: 48.168058, lng: 19.819711 },
    { lat: 48.168089, lng: 19.819977 },
    { lat: 48.1680954, lng: 19.8200252 },
    { lat: 48.1681, lng: 19.8200595 },
    { lat: 48.168123, lng: 19.82027 },
    { lat: 48.168245, lng: 19.820263 },
    { lat: 48.168234, lng: 19.820363 },
    { lat: 48.16829, lng: 19.820378 },
    { lat: 48.168545, lng: 19.820307 },
    { lat: 48.168571, lng: 19.820287 },
    { lat: 48.168625, lng: 19.820261 },
    { lat: 48.168687, lng: 19.820417 },
    { lat: 48.168658, lng: 19.82045 },
    { lat: 48.168804, lng: 19.820653 },
    { lat: 48.168963, lng: 19.82099 },
    { lat: 48.169081, lng: 19.821331 },
    { lat: 48.169047, lng: 19.821737 },
    { lat: 48.169085, lng: 19.82182 },
    { lat: 48.168887, lng: 19.822098 },
    { lat: 48.168847, lng: 19.8222223 },
    { lat: 48.16883, lng: 19.822916 },
    { lat: 48.168824, lng: 19.823576 },
    { lat: 48.168823, lng: 19.823708 },
    { lat: 48.168534, lng: 19.82362 },
    { lat: 48.16835, lng: 19.824537 },
    { lat: 48.168186, lng: 19.825344 },
    { lat: 48.16809, lng: 19.826072 },
    { lat: 48.167942, lng: 19.826701 },
    { lat: 48.167355, lng: 19.827761 },
    { lat: 48.167349, lng: 19.827982 },
    { lat: 48.167409, lng: 19.828297 },
    { lat: 48.167443, lng: 19.828816 },
    { lat: 48.167437, lng: 19.828994 },
    { lat: 48.167411, lng: 19.829141 },
    { lat: 48.167374, lng: 19.829246 },
    { lat: 48.167309, lng: 19.829388 },
    { lat: 48.167233, lng: 19.829531 },
    { lat: 48.167184, lng: 19.829602 },
    { lat: 48.166942, lng: 19.829897 },
    { lat: 48.166851, lng: 19.82998 },
    { lat: 48.16668, lng: 19.830047 },
    { lat: 48.166357, lng: 19.83036 },
    { lat: 48.165949, lng: 19.830467 },
    { lat: 48.16571, lng: 19.83053 },
    { lat: 48.165423, lng: 19.830606 },
    { lat: 48.164883, lng: 19.830747 },
    { lat: 48.16487, lng: 19.83075 },
    { lat: 48.164835, lng: 19.830642 },
    { lat: 48.164774, lng: 19.830347 },
    { lat: 48.164603, lng: 19.829644 },
    { lat: 48.164492, lng: 19.829145 },
    { lat: 48.164448, lng: 19.828913 },
    { lat: 48.164416, lng: 19.828695 },
    { lat: 48.164376, lng: 19.828486 },
    { lat: 48.164309, lng: 19.828275 },
    { lat: 48.164222, lng: 19.828085 },
    { lat: 48.1640846, lng: 19.8276664 },
    { lat: 48.1640002, lng: 19.8275435 },
    { lat: 48.1638793, lng: 19.827466 },
    { lat: 48.1637416, lng: 19.8273854 },
    { lat: 48.1636998, lng: 19.8273656 },
    { lat: 48.1636676, lng: 19.8273584 },
    { lat: 48.163565, lng: 19.828364 },
    { lat: 48.163458, lng: 19.829383 },
    { lat: 48.163351, lng: 19.830403 },
    { lat: 48.163296, lng: 19.830927 },
    { lat: 48.163282, lng: 19.831066 },
    { lat: 48.163218, lng: 19.831671 },
    { lat: 48.163115, lng: 19.832648 },
    { lat: 48.162578, lng: 19.832451 },
    { lat: 48.162575, lng: 19.832587 },
    { lat: 48.162574, lng: 19.832675 },
    { lat: 48.162573, lng: 19.83271 },
    { lat: 48.162562, lng: 19.833227 },
    { lat: 48.16258, lng: 19.8338 },
    { lat: 48.162532, lng: 19.834394 },
    { lat: 48.162469, lng: 19.834625 },
    { lat: 48.162427, lng: 19.835324 },
    { lat: 48.162387, lng: 19.836164 },
    { lat: 48.162374, lng: 19.836533 },
    { lat: 48.162361, lng: 19.836862 },
    { lat: 48.162351, lng: 19.83709 },
    { lat: 48.162332, lng: 19.837614 },
    { lat: 48.162327, lng: 19.837826 },
    { lat: 48.162217, lng: 19.838317 },
    { lat: 48.162174, lng: 19.838594 },
    { lat: 48.162168, lng: 19.838867 },
    { lat: 48.162173, lng: 19.839598 },
    { lat: 48.162179, lng: 19.840023 },
    { lat: 48.162178, lng: 19.84059 },
    { lat: 48.162177, lng: 19.840687 },
    { lat: 48.162215, lng: 19.840877 },
    { lat: 48.162284, lng: 19.84125 },
    { lat: 48.162303, lng: 19.841522 },
    { lat: 48.162371, lng: 19.842011 },
    { lat: 48.162375, lng: 19.842245 },
    { lat: 48.162407, lng: 19.842669 },
    { lat: 48.162329, lng: 19.842977 },
    { lat: 48.16248, lng: 19.843444 },
    { lat: 48.162558, lng: 19.843871 },
    { lat: 48.162679, lng: 19.844784 },
    { lat: 48.162742, lng: 19.845282 },
    { lat: 48.16293, lng: 19.84534 },
    { lat: 48.16306, lng: 19.845381 },
    { lat: 48.163288, lng: 19.845373 },
    { lat: 48.16372, lng: 19.845168 },
    { lat: 48.164304, lng: 19.845104 },
    { lat: 48.164755, lng: 19.84506 },
    { lat: 48.16494, lng: 19.845042 },
    { lat: 48.164896, lng: 19.844727 },
    { lat: 48.164974, lng: 19.844485 },
    { lat: 48.165116, lng: 19.844246 },
    { lat: 48.165226, lng: 19.844561 },
    { lat: 48.16556, lng: 19.844373 },
    { lat: 48.166024, lng: 19.84412 },
    { lat: 48.166215, lng: 19.843929 },
    { lat: 48.166608, lng: 19.844109 },
    { lat: 48.16681, lng: 19.844201 },
    { lat: 48.166887, lng: 19.844419 },
    { lat: 48.167076, lng: 19.844386 },
    { lat: 48.167091, lng: 19.844137 },
    { lat: 48.167505, lng: 19.844046 },
    { lat: 48.167689, lng: 19.844005 },
    { lat: 48.167938, lng: 19.843949 },
    { lat: 48.16796, lng: 19.843943 },
    { lat: 48.167991, lng: 19.84409 },
    { lat: 48.168041, lng: 19.844357 },
    { lat: 48.168113, lng: 19.844726 },
    { lat: 48.168166, lng: 19.844972 },
    { lat: 48.168336, lng: 19.845232 },
    { lat: 48.168419, lng: 19.845497 },
    { lat: 48.168491, lng: 19.845684 },
    { lat: 48.168664, lng: 19.846019 },
    { lat: 48.168822, lng: 19.846323 },
    { lat: 48.169055, lng: 19.846691 },
    { lat: 48.169337, lng: 19.847096 },
    { lat: 48.169594, lng: 19.847397 },
    { lat: 48.169958, lng: 19.847861 },
    { lat: 48.17005, lng: 19.847908 },
    { lat: 48.170115, lng: 19.847903 },
    { lat: 48.170199, lng: 19.847854 },
    { lat: 48.170256, lng: 19.847837 },
    { lat: 48.170321, lng: 19.847914 },
    { lat: 48.170415, lng: 19.848187 },
    { lat: 48.170495, lng: 19.848548 },
    { lat: 48.170571, lng: 19.848796 },
    { lat: 48.17071, lng: 19.849025 },
    { lat: 48.1708, lng: 19.849254 },
    { lat: 48.170886, lng: 19.849405 },
    { lat: 48.171114, lng: 19.849702 },
    { lat: 48.171245, lng: 19.849852 },
    { lat: 48.171354, lng: 19.850029 },
    { lat: 48.17146, lng: 19.850193 },
    { lat: 48.171488, lng: 19.850261 },
    { lat: 48.171527, lng: 19.8504 },
    { lat: 48.171611, lng: 19.850342 },
    { lat: 48.171702, lng: 19.850319 },
    { lat: 48.171932, lng: 19.850345 },
    { lat: 48.172095, lng: 19.850355 },
    { lat: 48.172506, lng: 19.850444 },
    { lat: 48.172617, lng: 19.850501 },
    { lat: 48.172703, lng: 19.850602 },
    { lat: 48.172912, lng: 19.85089 },
    { lat: 48.173109, lng: 19.851094 },
    { lat: 48.173264, lng: 19.851248 },
    { lat: 48.173343, lng: 19.851301 },
    { lat: 48.173507, lng: 19.851403 },
    { lat: 48.173653, lng: 19.851493 },
    { lat: 48.173844, lng: 19.851527 },
    { lat: 48.174383, lng: 19.851674 },
    { lat: 48.174563, lng: 19.851689 },
    { lat: 48.174728, lng: 19.851685 },
    { lat: 48.174857, lng: 19.851637 },
    { lat: 48.174953, lng: 19.851629 },
    { lat: 48.175048, lng: 19.851656 },
    { lat: 48.175224, lng: 19.851733 },
    { lat: 48.175468, lng: 19.851758 },
    { lat: 48.175863, lng: 19.851774 },
    { lat: 48.176103, lng: 19.851794 },
    { lat: 48.176268, lng: 19.851813 },
    { lat: 48.176423, lng: 19.85185 },
    { lat: 48.176592, lng: 19.851933 },
    { lat: 48.176797, lng: 19.852045 },
    { lat: 48.177002, lng: 19.852059 },
    { lat: 48.177271, lng: 19.852063 },
    { lat: 48.1775, lng: 19.852012 },
    { lat: 48.177504, lng: 19.852048 },
    { lat: 48.177543, lng: 19.852389 },
    { lat: 48.177653, lng: 19.852882 },
    { lat: 48.178069, lng: 19.853823 },
    { lat: 48.178264, lng: 19.854435 },
    { lat: 48.178265, lng: 19.854439 },
    { lat: 48.178419, lng: 19.855127 },
    { lat: 48.1784432, lng: 19.8552187 },
    { lat: 48.178251, lng: 19.855342 },
    { lat: 48.178088, lng: 19.855623 },
    { lat: 48.177949, lng: 19.855713 },
    { lat: 48.177795, lng: 19.855895 },
    { lat: 48.177634, lng: 19.855938 },
    { lat: 48.177507, lng: 19.856126 },
    { lat: 48.17736, lng: 19.85623 },
    { lat: 48.177176, lng: 19.856276 },
    { lat: 48.177073, lng: 19.856407 },
    { lat: 48.176998, lng: 19.85661 },
    { lat: 48.176999, lng: 19.856833 },
    { lat: 48.176901, lng: 19.856867 },
    { lat: 48.176769, lng: 19.856841 },
    { lat: 48.176675, lng: 19.856931 },
    { lat: 48.176554, lng: 19.857157 },
    { lat: 48.176336, lng: 19.857207 },
    { lat: 48.176186, lng: 19.857372 },
    { lat: 48.176022, lng: 19.85728 },
    { lat: 48.175799, lng: 19.857348 },
    { lat: 48.175766, lng: 19.857474 },
    { lat: 48.175724, lng: 19.857867 },
    { lat: 48.175606, lng: 19.858076 },
    { lat: 48.175454, lng: 19.858243 },
    { lat: 48.175348, lng: 19.858291 },
    { lat: 48.175206, lng: 19.858267 },
    { lat: 48.175068, lng: 19.858431 },
    { lat: 48.174742, lng: 19.858401 },
    { lat: 48.174675, lng: 19.858363 },
    { lat: 48.174667, lng: 19.85831 },
    { lat: 48.173901, lng: 19.85764 },
    { lat: 48.173638, lng: 19.857407 },
    { lat: 48.173374, lng: 19.857176 },
    { lat: 48.172845, lng: 19.856712 },
    { lat: 48.172603, lng: 19.856534 },
    { lat: 48.172488, lng: 19.856442 },
    { lat: 48.172321, lng: 19.856512 },
    { lat: 48.172095, lng: 19.856199 },
    { lat: 48.171982, lng: 19.855899 },
    { lat: 48.171708, lng: 19.856074 },
    { lat: 48.171672, lng: 19.856243 },
    { lat: 48.171556, lng: 19.856367 },
    { lat: 48.171437, lng: 19.856278 },
    { lat: 48.171226, lng: 19.856114 },
    { lat: 48.171106, lng: 19.856339 },
    { lat: 48.170998, lng: 19.856568 },
    { lat: 48.170805, lng: 19.856927 },
    { lat: 48.170793, lng: 19.857347 },
    { lat: 48.170653, lng: 19.857584 },
    { lat: 48.170452, lng: 19.857787 },
    { lat: 48.170533, lng: 19.858036 },
    { lat: 48.170571, lng: 19.858294 },
    { lat: 48.17024, lng: 19.858455 },
    { lat: 48.17023, lng: 19.858573 },
    { lat: 48.170106, lng: 19.858686 },
    { lat: 48.170081, lng: 19.858878 },
    { lat: 48.170157, lng: 19.859137 },
    { lat: 48.170243, lng: 19.859217 },
    { lat: 48.170252, lng: 19.859314 },
    { lat: 48.170255, lng: 19.859367 },
    { lat: 48.169947, lng: 19.859369 },
    { lat: 48.169943, lng: 19.859402 },
    { lat: 48.169927, lng: 19.859513 },
    { lat: 48.169881, lng: 19.859656 },
    { lat: 48.169772, lng: 19.859823 },
    { lat: 48.169462, lng: 19.86004 },
    { lat: 48.169409, lng: 19.860131 },
    { lat: 48.169464, lng: 19.860218 },
    { lat: 48.169511, lng: 19.860492 },
    { lat: 48.169572, lng: 19.860535 },
    { lat: 48.16963, lng: 19.860656 },
    { lat: 48.169604, lng: 19.860927 },
    { lat: 48.169661, lng: 19.861064 },
    { lat: 48.16966, lng: 19.861147 },
    { lat: 48.169605, lng: 19.861223 },
    { lat: 48.169564, lng: 19.861577 },
    { lat: 48.169518, lng: 19.861672 },
    { lat: 48.169471, lng: 19.861948 },
    { lat: 48.169423, lng: 19.862014 },
    { lat: 48.169403, lng: 19.862188 },
    { lat: 48.169641, lng: 19.862518 },
    { lat: 48.169729, lng: 19.862641 },
    { lat: 48.169759, lng: 19.862683 },
    { lat: 48.169963, lng: 19.862968 },
    { lat: 48.170156, lng: 19.863929 },
    { lat: 48.170232, lng: 19.86435 },
    { lat: 48.170547, lng: 19.864901 },
    { lat: 48.170687, lng: 19.865366 },
    { lat: 48.1705568, lng: 19.8659203 },
    { lat: 48.171032, lng: 19.867009 },
    { lat: 48.171508, lng: 19.868086 },
    { lat: 48.171439, lng: 19.868306 },
    { lat: 48.171291, lng: 19.868779 },
    { lat: 48.171077, lng: 19.869459 },
    { lat: 48.170989, lng: 19.869792 },
    { lat: 48.170807, lng: 19.870481 },
    { lat: 48.170758, lng: 19.870483 },
    { lat: 48.170715, lng: 19.870484 },
    { lat: 48.170477, lng: 19.870494 },
    { lat: 48.170464, lng: 19.870749 },
    { lat: 48.170417, lng: 19.871004 },
    { lat: 48.170322, lng: 19.871377 },
    { lat: 48.17018, lng: 19.872178 },
    { lat: 48.170031, lng: 19.872756 },
    { lat: 48.169748, lng: 19.874231 },
    { lat: 48.169599, lng: 19.875352 },
    { lat: 48.169546, lng: 19.876069 },
    { lat: 48.169533, lng: 19.876254 },
    { lat: 48.169613, lng: 19.8763 },
    { lat: 48.169835, lng: 19.876333 },
    { lat: 48.169979, lng: 19.876296 },
    { lat: 48.169995, lng: 19.876291 },
    { lat: 48.170109, lng: 19.876398 },
    { lat: 48.170115, lng: 19.876476 },
    { lat: 48.170192, lng: 19.876613 },
    { lat: 48.170252, lng: 19.876892 },
    { lat: 48.1703, lng: 19.876972 },
    { lat: 48.170324, lng: 19.877223 },
    { lat: 48.170371, lng: 19.877444 },
    { lat: 48.17051, lng: 19.877713 },
    { lat: 48.170597, lng: 19.878222 },
    { lat: 48.170447, lng: 19.879156 },
    { lat: 48.170225, lng: 19.880056 },
    { lat: 48.168867, lng: 19.88057 },
    { lat: 48.168193, lng: 19.880824 },
    { lat: 48.167307, lng: 19.881168 },
    { lat: 48.1670238, lng: 19.881271 },
    { lat: 48.166876, lng: 19.881336 },
    { lat: 48.166481, lng: 19.881489 },
    { lat: 48.165927, lng: 19.881705 },
    { lat: 48.165002, lng: 19.882066 },
    { lat: 48.164141, lng: 19.8824 },
    { lat: 48.1643, lng: 19.883588 },
    { lat: 48.164458, lng: 19.884772 },
    { lat: 48.164404, lng: 19.88544 },
    { lat: 48.164291, lng: 19.886736 },
    { lat: 48.164206, lng: 19.887773 },
    { lat: 48.164139, lng: 19.888503 },
    { lat: 48.164088, lng: 19.889074 },
    { lat: 48.164689, lng: 19.888394 },
    { lat: 48.164969, lng: 19.888339 },
    { lat: 48.165257, lng: 19.887876 },
    { lat: 48.165503, lng: 19.887482 },
    { lat: 48.16536, lng: 19.886883 },
    { lat: 48.165228, lng: 19.885677 },
    { lat: 48.165278, lng: 19.885369 },
    { lat: 48.16537, lng: 19.884968 },
    { lat: 48.165598, lng: 19.884476 },
    { lat: 48.165768, lng: 19.884196 },
    { lat: 48.166187, lng: 19.883746 },
    { lat: 48.166556, lng: 19.883557 },
    { lat: 48.166731, lng: 19.883368 },
    { lat: 48.167104, lng: 19.883299 },
    { lat: 48.167601, lng: 19.883747 },
    { lat: 48.168286, lng: 19.884354 },
    { lat: 48.168975, lng: 19.884758 },
    { lat: 48.169691, lng: 19.885306 },
    { lat: 48.170953, lng: 19.885537 },
    { lat: 48.171281, lng: 19.885713 },
    { lat: 48.172451, lng: 19.886613 },
    { lat: 48.172867, lng: 19.886934 },
    { lat: 48.172943, lng: 19.886991 },
    { lat: 48.174406, lng: 19.888205 },
    { lat: 48.174678, lng: 19.888447 },
    { lat: 48.174725, lng: 19.888489 },
    { lat: 48.175094, lng: 19.888826 },
    { lat: 48.175789, lng: 19.889553 },
    { lat: 48.176218, lng: 19.889719 },
    { lat: 48.176253, lng: 19.889736 },
    { lat: 48.177496, lng: 19.890345 },
    { lat: 48.178748, lng: 19.89147 },
    { lat: 48.179437, lng: 19.891934 },
    { lat: 48.179735, lng: 19.892135 },
    { lat: 48.179919, lng: 19.892262 },
    { lat: 48.180468, lng: 19.892641 },
    { lat: 48.18088, lng: 19.892925 },
    { lat: 48.183583, lng: 19.894638 },
    { lat: 48.184348, lng: 19.895128 },
    { lat: 48.185412, lng: 19.89529 },
    { lat: 48.185391, lng: 19.894695 },
    { lat: 48.185411, lng: 19.894211 },
    { lat: 48.185535, lng: 19.893409 },
    { lat: 48.185623, lng: 19.892999 },
  ],
  Prša: [
    { lat: 48.3105025, lng: 19.7886536 },
    { lat: 48.3095197, lng: 19.7849865 },
    { lat: 48.3094704, lng: 19.7845844 },
    { lat: 48.3093712, lng: 19.7812334 },
    { lat: 48.3087644, lng: 19.7812917 },
    { lat: 48.308819, lng: 19.7804104 },
    { lat: 48.3082845, lng: 19.779633 },
    { lat: 48.307716, lng: 19.778085 },
    { lat: 48.3075001, lng: 19.7771247 },
    { lat: 48.3057364, lng: 19.7772417 },
    { lat: 48.3045959, lng: 19.7772125 },
    { lat: 48.3045688, lng: 19.7771327 },
    { lat: 48.3046324, lng: 19.7767109 },
    { lat: 48.3042542, lng: 19.7764398 },
    { lat: 48.3032031, lng: 19.7753627 },
    { lat: 48.3019964, lng: 19.7743192 },
    { lat: 48.3009085, lng: 19.7736676 },
    { lat: 48.3008013, lng: 19.7732773 },
    { lat: 48.2986295, lng: 19.7736752 },
    { lat: 48.2988039, lng: 19.7725185 },
    { lat: 48.2986815, lng: 19.7725533 },
    { lat: 48.298627, lng: 19.7725959 },
    { lat: 48.298586, lng: 19.7727365 },
    { lat: 48.2976621, lng: 19.7737325 },
    { lat: 48.2969023, lng: 19.7756676 },
    { lat: 48.2960789, lng: 19.7784273 },
    { lat: 48.2956113, lng: 19.7797307 },
    { lat: 48.2936036, lng: 19.7846674 },
    { lat: 48.2930681, lng: 19.7873738 },
    { lat: 48.292962, lng: 19.7949783 },
    { lat: 48.2929084, lng: 19.7953965 },
    { lat: 48.2927285, lng: 19.7960203 },
    { lat: 48.2911095, lng: 19.7995584 },
    { lat: 48.2911973, lng: 19.7999735 },
    { lat: 48.2926276, lng: 19.8004952 },
    { lat: 48.2940474, lng: 19.800784 },
    { lat: 48.2938068, lng: 19.8025152 },
    { lat: 48.2938196, lng: 19.8083043 },
    { lat: 48.2938369, lng: 19.8086992 },
    { lat: 48.2939064, lng: 19.8086347 },
    { lat: 48.293997, lng: 19.8085472 },
    { lat: 48.2956608, lng: 19.8067944 },
    { lat: 48.2959404, lng: 19.8070546 },
    { lat: 48.2962391, lng: 19.8071844 },
    { lat: 48.2966013, lng: 19.8077353 },
    { lat: 48.2966258, lng: 19.8073186 },
    { lat: 48.2970967, lng: 19.8074721 },
    { lat: 48.2983381, lng: 19.8080332 },
    { lat: 48.2995314, lng: 19.8080629 },
    { lat: 48.3002985, lng: 19.8079552 },
    { lat: 48.3011555, lng: 19.8076146 },
    { lat: 48.3018796, lng: 19.80655 },
    { lat: 48.3023667, lng: 19.8062009 },
    { lat: 48.3047844, lng: 19.8017788 },
    { lat: 48.3044897, lng: 19.8016653 },
    { lat: 48.3047578, lng: 19.7999671 },
    { lat: 48.3050998, lng: 19.7982125 },
    { lat: 48.3063372, lng: 19.7961178 },
    { lat: 48.3056126, lng: 19.7951655 },
    { lat: 48.3063829, lng: 19.7906417 },
    { lat: 48.3066438, lng: 19.7907104 },
    { lat: 48.3066823, lng: 19.7908411 },
    { lat: 48.3068117, lng: 19.7908361 },
    { lat: 48.3071966, lng: 19.7905733 },
    { lat: 48.3074808, lng: 19.7902451 },
    { lat: 48.3076142, lng: 19.7902284 },
    { lat: 48.3077194, lng: 19.7901094 },
    { lat: 48.3078946, lng: 19.7902014 },
    { lat: 48.3080882, lng: 19.7901462 },
    { lat: 48.3085953, lng: 19.7898122 },
    { lat: 48.30864, lng: 19.789702 },
    { lat: 48.3090021, lng: 19.7896096 },
    { lat: 48.3092323, lng: 19.7893901 },
    { lat: 48.3094328, lng: 19.7893646 },
    { lat: 48.309572, lng: 19.789399 },
    { lat: 48.3095881, lng: 19.7894596 },
    { lat: 48.3096535, lng: 19.7894387 },
    { lat: 48.3098122, lng: 19.7892517 },
    { lat: 48.3099965, lng: 19.7891906 },
    { lat: 48.3102757, lng: 19.7889564 },
    { lat: 48.3104438, lng: 19.7886513 },
    { lat: 48.3105025, lng: 19.7886536 },
  ],
  Čamovce: [
    { lat: 48.2508709, lng: 19.8652936 },
    { lat: 48.2502804, lng: 19.8658091 },
    { lat: 48.2493186, lng: 19.8666488 },
    { lat: 48.2480604, lng: 19.8679308 },
    { lat: 48.2472873, lng: 19.8684701 },
    { lat: 48.2459663, lng: 19.8686355 },
    { lat: 48.2455131, lng: 19.8686273 },
    { lat: 48.2451057, lng: 19.8685371 },
    { lat: 48.2441092, lng: 19.8686509 },
    { lat: 48.2425139, lng: 19.8685716 },
    { lat: 48.2416253, lng: 19.8680599 },
    { lat: 48.2411036, lng: 19.8675925 },
    { lat: 48.2401167, lng: 19.8670953 },
    { lat: 48.2395731, lng: 19.8666181 },
    { lat: 48.2388154, lng: 19.8662665 },
    { lat: 48.2380453, lng: 19.8656533 },
    { lat: 48.2376278, lng: 19.8647564 },
    { lat: 48.2367526, lng: 19.8619814 },
    { lat: 48.2356004, lng: 19.861468 },
    { lat: 48.2346441, lng: 19.8612409 },
    { lat: 48.2333793, lng: 19.8614293 },
    { lat: 48.2322391, lng: 19.861431 },
    { lat: 48.2309756, lng: 19.8615441 },
    { lat: 48.2293848, lng: 19.861962 },
    { lat: 48.2272741, lng: 19.8633503 },
    { lat: 48.224978, lng: 19.8651669 },
    { lat: 48.2230415, lng: 19.867193 },
    { lat: 48.2222934, lng: 19.868104 },
    { lat: 48.2215818, lng: 19.8700332 },
    { lat: 48.220059, lng: 19.8729295 },
    { lat: 48.2187953, lng: 19.8745753 },
    { lat: 48.2173242, lng: 19.8757774 },
    { lat: 48.216448, lng: 19.8762503 },
    { lat: 48.2150298, lng: 19.8771426 },
    { lat: 48.213418, lng: 19.8784765 },
    { lat: 48.2125151, lng: 19.8790338 },
    { lat: 48.2116062, lng: 19.8793571 },
    { lat: 48.2110637, lng: 19.8798636 },
    { lat: 48.2102973, lng: 19.8804005 },
    { lat: 48.2093491, lng: 19.8814792 },
    { lat: 48.208583, lng: 19.8828598 },
    { lat: 48.2080534, lng: 19.8832727 },
    { lat: 48.2071111, lng: 19.8843285 },
    { lat: 48.2067679, lng: 19.8848872 },
    { lat: 48.2064552, lng: 19.8861999 },
    { lat: 48.2064859, lng: 19.8868602 },
    { lat: 48.207059, lng: 19.8876329 },
    { lat: 48.2077961, lng: 19.8882369 },
    { lat: 48.2082505, lng: 19.8881697 },
    { lat: 48.2113803, lng: 19.8888782 },
    { lat: 48.2122632, lng: 19.8891559 },
    { lat: 48.2134793, lng: 19.8894029 },
    { lat: 48.2142333, lng: 19.8896429 },
    { lat: 48.2152984, lng: 19.8898384 },
    { lat: 48.2161328, lng: 19.8902738 },
    { lat: 48.2162676, lng: 19.8904491 },
    { lat: 48.2163288, lng: 19.8906632 },
    { lat: 48.216636, lng: 19.8906533 },
    { lat: 48.217138, lng: 19.8908517 },
    { lat: 48.2174081, lng: 19.8911043 },
    { lat: 48.2175042, lng: 19.8912974 },
    { lat: 48.2176252, lng: 19.8913599 },
    { lat: 48.2176313, lng: 19.8912922 },
    { lat: 48.2178333, lng: 19.8914062 },
    { lat: 48.2180383, lng: 19.8916386 },
    { lat: 48.2181638, lng: 19.8917021 },
    { lat: 48.2182675, lng: 19.8918835 },
    { lat: 48.2182658, lng: 19.8920783 },
    { lat: 48.2187716, lng: 19.8922343 },
    { lat: 48.2189748, lng: 19.8925845 },
    { lat: 48.2189726, lng: 19.8927688 },
    { lat: 48.2190734, lng: 19.8929092 },
    { lat: 48.2190371, lng: 19.8931964 },
    { lat: 48.2190701, lng: 19.8933041 },
    { lat: 48.2193058, lng: 19.8932562 },
    { lat: 48.2197532, lng: 19.8936786 },
    { lat: 48.2197948, lng: 19.8939162 },
    { lat: 48.2198697, lng: 19.8940037 },
    { lat: 48.2199972, lng: 19.8940104 },
    { lat: 48.2201033, lng: 19.8943022 },
    { lat: 48.2203449, lng: 19.8943661 },
    { lat: 48.2205395, lng: 19.8946458 },
    { lat: 48.2208197, lng: 19.8958386 },
    { lat: 48.2211633, lng: 19.8977177 },
    { lat: 48.2212642, lng: 19.897738 },
    { lat: 48.2213431, lng: 19.897865 },
    { lat: 48.2213527, lng: 19.8979343 },
    { lat: 48.2212003, lng: 19.8982094 },
    { lat: 48.2213084, lng: 19.8982919 },
    { lat: 48.2213222, lng: 19.8985845 },
    { lat: 48.2215303, lng: 19.8987078 },
    { lat: 48.2217931, lng: 19.8992625 },
    { lat: 48.2224623, lng: 19.9000487 },
    { lat: 48.2227125, lng: 19.9006498 },
    { lat: 48.2228957, lng: 19.9008275 },
    { lat: 48.2238446, lng: 19.9011479 },
    { lat: 48.2242359, lng: 19.9006304 },
    { lat: 48.2245247, lng: 19.9000397 },
    { lat: 48.2255448, lng: 19.8999741 },
    { lat: 48.2259455, lng: 19.8997295 },
    { lat: 48.2264745, lng: 19.899954 },
    { lat: 48.2266631, lng: 19.900276 },
    { lat: 48.2278526, lng: 19.9010089 },
    { lat: 48.2283199, lng: 19.9012079 },
    { lat: 48.2290736, lng: 19.901261 },
    { lat: 48.2291128, lng: 19.90186 },
    { lat: 48.2290291, lng: 19.9026895 },
    { lat: 48.2287104, lng: 19.9032644 },
    { lat: 48.2289218, lng: 19.9045403 },
    { lat: 48.2292455, lng: 19.9047056 },
    { lat: 48.2298488, lng: 19.9052961 },
    { lat: 48.2305602, lng: 19.905518 },
    { lat: 48.2308163, lng: 19.9057841 },
    { lat: 48.2319943, lng: 19.9061694 },
    { lat: 48.2323565, lng: 19.9060762 },
    { lat: 48.2323482, lng: 19.9053078 },
    { lat: 48.2324422, lng: 19.9049312 },
    { lat: 48.2327874, lng: 19.9044177 },
    { lat: 48.232961, lng: 19.9048349 },
    { lat: 48.2337936, lng: 19.9038207 },
    { lat: 48.2343528, lng: 19.9039346 },
    { lat: 48.2347613, lng: 19.9030585 },
    { lat: 48.2350897, lng: 19.9029943 },
    { lat: 48.2352643, lng: 19.9033096 },
    { lat: 48.2355194, lng: 19.9031366 },
    { lat: 48.2357721, lng: 19.902515 },
    { lat: 48.2358559, lng: 19.9020058 },
    { lat: 48.2359484, lng: 19.9019764 },
    { lat: 48.235978, lng: 19.9023245 },
    { lat: 48.2373784, lng: 19.9022257 },
    { lat: 48.2378497, lng: 19.9022598 },
    { lat: 48.2379865, lng: 19.902559 },
    { lat: 48.237957, lng: 19.9029986 },
    { lat: 48.2386686, lng: 19.9042714 },
    { lat: 48.239024, lng: 19.9045701 },
    { lat: 48.2400841, lng: 19.9048036 },
    { lat: 48.2402548, lng: 19.9044266 },
    { lat: 48.2410325, lng: 19.9049044 },
    { lat: 48.2415864, lng: 19.9051588 },
    { lat: 48.2421462, lng: 19.9068075 },
    { lat: 48.2424189, lng: 19.9073559 },
    { lat: 48.2430385, lng: 19.9077775 },
    { lat: 48.2435043, lng: 19.9078938 },
    { lat: 48.2441075, lng: 19.9085369 },
    { lat: 48.2485162, lng: 19.9100648 },
    { lat: 48.2491166, lng: 19.9103244 },
    { lat: 48.2494641, lng: 19.9105716 },
    { lat: 48.25054, lng: 19.9110193 },
    { lat: 48.2508415, lng: 19.9110871 },
    { lat: 48.2534665, lng: 19.9077871 },
    { lat: 48.2560259, lng: 19.9102469 },
    { lat: 48.256349, lng: 19.910526 },
    { lat: 48.256849, lng: 19.909894 },
    { lat: 48.25729, lng: 19.909327 },
    { lat: 48.257661, lng: 19.908861 },
    { lat: 48.2580149, lng: 19.9061201 },
    { lat: 48.2578861, lng: 19.9049853 },
    { lat: 48.2574543, lng: 19.9037992 },
    { lat: 48.2570884, lng: 19.9030755 },
    { lat: 48.2570184, lng: 19.9026506 },
    { lat: 48.2569405, lng: 19.901576 },
    { lat: 48.2571336, lng: 19.8996742 },
    { lat: 48.2556392, lng: 19.8988505 },
    { lat: 48.2555386, lng: 19.8974314 },
    { lat: 48.2556479, lng: 19.8969369 },
    { lat: 48.2555772, lng: 19.89689 },
    { lat: 48.2554953, lng: 19.8963987 },
    { lat: 48.2554398, lng: 19.8956861 },
    { lat: 48.2555138, lng: 19.8945322 },
    { lat: 48.2555006, lng: 19.8933891 },
    { lat: 48.2556125, lng: 19.8929233 },
    { lat: 48.2556437, lng: 19.8925792 },
    { lat: 48.2558727, lng: 19.8918518 },
    { lat: 48.2558156, lng: 19.8914203 },
    { lat: 48.2562354, lng: 19.8904705 },
    { lat: 48.2562677, lng: 19.8901453 },
    { lat: 48.2562085, lng: 19.8901577 },
    { lat: 48.2565316, lng: 19.8889797 },
    { lat: 48.256843, lng: 19.8885967 },
    { lat: 48.2569909, lng: 19.8879266 },
    { lat: 48.2570743, lng: 19.887798 },
    { lat: 48.2574742, lng: 19.886698 },
    { lat: 48.2571868, lng: 19.8863302 },
    { lat: 48.2567852, lng: 19.8859576 },
    { lat: 48.2559442, lng: 19.8846119 },
    { lat: 48.2559628, lng: 19.8845214 },
    { lat: 48.2558015, lng: 19.8844118 },
    { lat: 48.2556568, lng: 19.8842145 },
    { lat: 48.2538021, lng: 19.8813946 },
    { lat: 48.2536308, lng: 19.8808174 },
    { lat: 48.2536276, lng: 19.8801492 },
    { lat: 48.2535789, lng: 19.8800097 },
    { lat: 48.2536253, lng: 19.8797305 },
    { lat: 48.2535295, lng: 19.8793372 },
    { lat: 48.253515, lng: 19.8790787 },
    { lat: 48.2536252, lng: 19.8766823 },
    { lat: 48.2535777, lng: 19.876496 },
    { lat: 48.2527028, lng: 19.875438 },
    { lat: 48.2522674, lng: 19.8744106 },
    { lat: 48.2520784, lng: 19.8719976 },
    { lat: 48.2509703, lng: 19.8659262 },
    { lat: 48.2508709, lng: 19.8652936 },
  ],
  Mašková: [
    { lat: 48.3189926, lng: 19.5303679 },
    { lat: 48.3189588, lng: 19.530341 },
    { lat: 48.3188752, lng: 19.5304842 },
    { lat: 48.3186518, lng: 19.5306214 },
    { lat: 48.3175394, lng: 19.5311343 },
    { lat: 48.3172213, lng: 19.5315162 },
    { lat: 48.316884, lng: 19.5316783 },
    { lat: 48.316442, lng: 19.5317455 },
    { lat: 48.315527, lng: 19.5323547 },
    { lat: 48.3152624, lng: 19.5324087 },
    { lat: 48.3150293, lng: 19.5325374 },
    { lat: 48.3146716, lng: 19.5327728 },
    { lat: 48.3144475, lng: 19.5331909 },
    { lat: 48.314312, lng: 19.5333568 },
    { lat: 48.3140986, lng: 19.5334902 },
    { lat: 48.3139779, lng: 19.53349 },
    { lat: 48.3134006, lng: 19.5338229 },
    { lat: 48.3130618, lng: 19.5339282 },
    { lat: 48.3125429, lng: 19.5342112 },
    { lat: 48.3123848, lng: 19.5343986 },
    { lat: 48.3121931, lng: 19.5343481 },
    { lat: 48.3121206, lng: 19.5344614 },
    { lat: 48.3117646, lng: 19.53456 },
    { lat: 48.3115944, lng: 19.5346832 },
    { lat: 48.3115047, lng: 19.5348384 },
    { lat: 48.311119, lng: 19.5349786 },
    { lat: 48.310346, lng: 19.5358356 },
    { lat: 48.3094384, lng: 19.5362892 },
    { lat: 48.309376, lng: 19.5363027 },
    { lat: 48.3093178, lng: 19.5362139 },
    { lat: 48.3092525, lng: 19.5363134 },
    { lat: 48.3090794, lng: 19.5363236 },
    { lat: 48.3086293, lng: 19.5360921 },
    { lat: 48.3085683, lng: 19.5361632 },
    { lat: 48.3084358, lng: 19.535978 },
    { lat: 48.3082057, lng: 19.5358547 },
    { lat: 48.3081518, lng: 19.535684 },
    { lat: 48.3080413, lng: 19.5355868 },
    { lat: 48.3080257, lng: 19.5354705 },
    { lat: 48.3078101, lng: 19.5353167 },
    { lat: 48.3076072, lng: 19.5353869 },
    { lat: 48.307484, lng: 19.5352553 },
    { lat: 48.3074051, lng: 19.5350352 },
    { lat: 48.3070861, lng: 19.5350612 },
    { lat: 48.3068994, lng: 19.5348481 },
    { lat: 48.3064103, lng: 19.5348224 },
    { lat: 48.306071, lng: 19.5346506 },
    { lat: 48.3059128, lng: 19.5346652 },
    { lat: 48.3056505, lng: 19.5344776 },
    { lat: 48.3055066, lng: 19.5346543 },
    { lat: 48.3053375, lng: 19.534971 },
    { lat: 48.3052896, lng: 19.5352273 },
    { lat: 48.3051295, lng: 19.5354414 },
    { lat: 48.3050104, lng: 19.5354244 },
    { lat: 48.3046944, lng: 19.5356601 },
    { lat: 48.3046008, lng: 19.5356577 },
    { lat: 48.3045965, lng: 19.5354462 },
    { lat: 48.3044764, lng: 19.5352141 },
    { lat: 48.3041988, lng: 19.5351558 },
    { lat: 48.3037391, lng: 19.5349119 },
    { lat: 48.3033999, lng: 19.5350831 },
    { lat: 48.3032053, lng: 19.5351132 },
    { lat: 48.3032262, lng: 19.5353977 },
    { lat: 48.3031376, lng: 19.535723 },
    { lat: 48.3030129, lng: 19.5359522 },
    { lat: 48.3028526, lng: 19.5359427 },
    { lat: 48.3027189, lng: 19.535856 },
    { lat: 48.3027718, lng: 19.5355129 },
    { lat: 48.3025359, lng: 19.5355547 },
    { lat: 48.3024394, lng: 19.5358581 },
    { lat: 48.3022355, lng: 19.5359774 },
    { lat: 48.3019457, lng: 19.5364155 },
    { lat: 48.3019076, lng: 19.5365618 },
    { lat: 48.3016927, lng: 19.536502 },
    { lat: 48.3015346, lng: 19.5366883 },
    { lat: 48.3014983, lng: 19.5368071 },
    { lat: 48.3017081, lng: 19.537249 },
    { lat: 48.3015501, lng: 19.5375591 },
    { lat: 48.3014554, lng: 19.5381933 },
    { lat: 48.3009529, lng: 19.5387824 },
    { lat: 48.3007879, lng: 19.538768 },
    { lat: 48.3007238, lng: 19.5388214 },
    { lat: 48.3004947, lng: 19.5395477 },
    { lat: 48.3001192, lng: 19.5397095 },
    { lat: 48.2998587, lng: 19.5396378 },
    { lat: 48.2997737, lng: 19.5397 },
    { lat: 48.2997838, lng: 19.5398929 },
    { lat: 48.2996184, lng: 19.5402371 },
    { lat: 48.2997501, lng: 19.5404825 },
    { lat: 48.2997382, lng: 19.5406646 },
    { lat: 48.2993787, lng: 19.5415163 },
    { lat: 48.299385, lng: 19.5419686 },
    { lat: 48.2991218, lng: 19.5423198 },
    { lat: 48.2989057, lng: 19.5427694 },
    { lat: 48.2986026, lng: 19.5429399 },
    { lat: 48.2985606, lng: 19.5432019 },
    { lat: 48.2983218, lng: 19.5434977 },
    { lat: 48.2981679, lng: 19.5438467 },
    { lat: 48.2981459, lng: 19.5442252 },
    { lat: 48.2982663, lng: 19.5445879 },
    { lat: 48.2982877, lng: 19.5448115 },
    { lat: 48.2982653, lng: 19.5449603 },
    { lat: 48.2980788, lng: 19.545238 },
    { lat: 48.2981553, lng: 19.5452831 },
    { lat: 48.3140286, lng: 19.5547398 },
    { lat: 48.3146594, lng: 19.5555858 },
    { lat: 48.3153139, lng: 19.5553641 },
    { lat: 48.3157751, lng: 19.5547803 },
    { lat: 48.3158823, lng: 19.5544414 },
    { lat: 48.316579, lng: 19.5555766 },
    { lat: 48.3168473, lng: 19.5561878 },
    { lat: 48.3173203, lng: 19.5570406 },
    { lat: 48.3173408, lng: 19.5578294 },
    { lat: 48.318243, lng: 19.5597269 },
    { lat: 48.3186767, lng: 19.5608026 },
    { lat: 48.3191861, lng: 19.5626679 },
    { lat: 48.3196326, lng: 19.5625474 },
    { lat: 48.3200231, lng: 19.5652842 },
    { lat: 48.3200389, lng: 19.5670479 },
    { lat: 48.3212581, lng: 19.5707055 },
    { lat: 48.3222721, lng: 19.57247 },
    { lat: 48.3212384, lng: 19.5737646 },
    { lat: 48.3210441, lng: 19.5740739 },
    { lat: 48.3209955, lng: 19.5745122 },
    { lat: 48.3210972, lng: 19.5744713 },
    { lat: 48.3212722, lng: 19.5745736 },
    { lat: 48.321533, lng: 19.5749776 },
    { lat: 48.3217318, lng: 19.5749398 },
    { lat: 48.3219359, lng: 19.5749881 },
    { lat: 48.3221433, lng: 19.5751399 },
    { lat: 48.3221788, lng: 19.5751519 },
    { lat: 48.3225405, lng: 19.5752746 },
    { lat: 48.3226273, lng: 19.5752518 },
    { lat: 48.3227904, lng: 19.5749241 },
    { lat: 48.3232864, lng: 19.5752979 },
    { lat: 48.3234888, lng: 19.5753952 },
    { lat: 48.3239894, lng: 19.5756359 },
    { lat: 48.3244798, lng: 19.5758717 },
    { lat: 48.3246164, lng: 19.5759374 },
    { lat: 48.3246622, lng: 19.5759594 },
    { lat: 48.3248948, lng: 19.5762102 },
    { lat: 48.3254584, lng: 19.5762661 },
    { lat: 48.3255925, lng: 19.5763631 },
    { lat: 48.3258762, lng: 19.5768232 },
    { lat: 48.3260125, lng: 19.5769232 },
    { lat: 48.3260365, lng: 19.5771265 },
    { lat: 48.3268229, lng: 19.5777628 },
    { lat: 48.3268988, lng: 19.5780177 },
    { lat: 48.3270458, lng: 19.5781485 },
    { lat: 48.3272653, lng: 19.5785635 },
    { lat: 48.3274141, lng: 19.5786098 },
    { lat: 48.3277882, lng: 19.5790043 },
    { lat: 48.328097, lng: 19.579498 },
    { lat: 48.3282558, lng: 19.5795192 },
    { lat: 48.3283155, lng: 19.5792765 },
    { lat: 48.3286961, lng: 19.5792914 },
    { lat: 48.3288591, lng: 19.5792095 },
    { lat: 48.3291503, lng: 19.5791958 },
    { lat: 48.3293268, lng: 19.5794095 },
    { lat: 48.3295393, lng: 19.5794705 },
    { lat: 48.3296473, lng: 19.5791467 },
    { lat: 48.3297054, lng: 19.5790653 },
    { lat: 48.3299179, lng: 19.5789782 },
    { lat: 48.3301443, lng: 19.578611 },
    { lat: 48.3315293, lng: 19.5806016 },
    { lat: 48.3320739, lng: 19.581076 },
    { lat: 48.3324799, lng: 19.5815268 },
    { lat: 48.3327067, lng: 19.5816791 },
    { lat: 48.3330924, lng: 19.5817838 },
    { lat: 48.3333682, lng: 19.5817788 },
    { lat: 48.3347334, lng: 19.5828019 },
    { lat: 48.335954, lng: 19.5838386 },
    { lat: 48.336545, lng: 19.5850088 },
    { lat: 48.3366302, lng: 19.5850245 },
    { lat: 48.3366211, lng: 19.5848965 },
    { lat: 48.3367028, lng: 19.5848616 },
    { lat: 48.3367751, lng: 19.5846405 },
    { lat: 48.3369209, lng: 19.5846992 },
    { lat: 48.3369741, lng: 19.5848304 },
    { lat: 48.3370342, lng: 19.5847127 },
    { lat: 48.3369918, lng: 19.5845035 },
    { lat: 48.3371005, lng: 19.5844657 },
    { lat: 48.3371343, lng: 19.5841187 },
    { lat: 48.3372686, lng: 19.5840978 },
    { lat: 48.337268, lng: 19.5840125 },
    { lat: 48.3373954, lng: 19.5839169 },
    { lat: 48.3374353, lng: 19.5837509 },
    { lat: 48.337394, lng: 19.5837102 },
    { lat: 48.3373693, lng: 19.5835169 },
    { lat: 48.3374368, lng: 19.5833151 },
    { lat: 48.3373588, lng: 19.5830667 },
    { lat: 48.337385, lng: 19.5829255 },
    { lat: 48.3374803, lng: 19.5828619 },
    { lat: 48.3374953, lng: 19.5824513 },
    { lat: 48.3375588, lng: 19.5821678 },
    { lat: 48.3377458, lng: 19.582044 },
    { lat: 48.3378715, lng: 19.5821076 },
    { lat: 48.3379777, lng: 19.581792 },
    { lat: 48.3379468, lng: 19.5816316 },
    { lat: 48.3380839, lng: 19.5816242 },
    { lat: 48.3381247, lng: 19.5817273 },
    { lat: 48.3382192, lng: 19.5816761 },
    { lat: 48.3382491, lng: 19.5813709 },
    { lat: 48.3384286, lng: 19.581226 },
    { lat: 48.3386063, lng: 19.5809506 },
    { lat: 48.3386955, lng: 19.5810005 },
    { lat: 48.3388383, lng: 19.5808718 },
    { lat: 48.3390129, lng: 19.5809969 },
    { lat: 48.3395496, lng: 19.5805321 },
    { lat: 48.3397259, lng: 19.5801732 },
    { lat: 48.3399883, lng: 19.5797785 },
    { lat: 48.340218, lng: 19.5795764 },
    { lat: 48.3403715, lng: 19.5792864 },
    { lat: 48.3407754, lng: 19.5789533 },
    { lat: 48.3409287, lng: 19.5787345 },
    { lat: 48.3410329, lng: 19.5787702 },
    { lat: 48.3417093, lng: 19.5777944 },
    { lat: 48.3421482, lng: 19.5769933 },
    { lat: 48.3425713, lng: 19.575796 },
    { lat: 48.3429115, lng: 19.5746151 },
    { lat: 48.3428627, lng: 19.5745351 },
    { lat: 48.3429655, lng: 19.5739234 },
    { lat: 48.343421, lng: 19.5720125 },
    { lat: 48.3435176, lng: 19.571247 },
    { lat: 48.3437032, lng: 19.5703557 },
    { lat: 48.344048, lng: 19.5691016 },
    { lat: 48.3440073, lng: 19.5690991 },
    { lat: 48.3432364, lng: 19.5690714 },
    { lat: 48.3433794, lng: 19.567571 },
    { lat: 48.3425299, lng: 19.5658813 },
    { lat: 48.3429742, lng: 19.5614233 },
    { lat: 48.3429746, lng: 19.5591772 },
    { lat: 48.3430177, lng: 19.5590353 },
    { lat: 48.3425591, lng: 19.5568069 },
    { lat: 48.3423445, lng: 19.5547906 },
    { lat: 48.3420199, lng: 19.5533213 },
    { lat: 48.341556, lng: 19.5543076 },
    { lat: 48.3410432, lng: 19.5552136 },
    { lat: 48.3407488, lng: 19.5556199 },
    { lat: 48.3401446, lng: 19.556279 },
    { lat: 48.3400486, lng: 19.5563491 },
    { lat: 48.3400268, lng: 19.5562391 },
    { lat: 48.339909, lng: 19.5563016 },
    { lat: 48.3397806, lng: 19.5558144 },
    { lat: 48.3396864, lng: 19.5558468 },
    { lat: 48.3392997, lng: 19.5563561 },
    { lat: 48.3390492, lng: 19.5564759 },
    { lat: 48.3385202, lng: 19.5561928 },
    { lat: 48.3378691, lng: 19.5557101 },
    { lat: 48.3377706, lng: 19.5557954 },
    { lat: 48.3371035, lng: 19.5568725 },
    { lat: 48.3366763, lng: 19.5573867 },
    { lat: 48.3365833, lng: 19.5576173 },
    { lat: 48.3364319, lng: 19.5574162 },
    { lat: 48.3362162, lng: 19.5576981 },
    { lat: 48.336154, lng: 19.5576688 },
    { lat: 48.3360365, lng: 19.5575184 },
    { lat: 48.3359146, lng: 19.5568747 },
    { lat: 48.3363498, lng: 19.5559251 },
    { lat: 48.3354505, lng: 19.5542106 },
    { lat: 48.3350319, lng: 19.5547352 },
    { lat: 48.3348894, lng: 19.5539687 },
    { lat: 48.3348339, lng: 19.5533745 },
    { lat: 48.3348552, lng: 19.5521221 },
    { lat: 48.334902, lng: 19.5519656 },
    { lat: 48.334659, lng: 19.5512875 },
    { lat: 48.3328269, lng: 19.5521264 },
    { lat: 48.3321603, lng: 19.551132 },
    { lat: 48.3320843, lng: 19.5509284 },
    { lat: 48.3320731, lng: 19.550613 },
    { lat: 48.3319084, lng: 19.550436 },
    { lat: 48.3318274, lng: 19.5501709 },
    { lat: 48.3315502, lng: 19.5500024 },
    { lat: 48.3311314, lng: 19.5494707 },
    { lat: 48.3306347, lng: 19.5491818 },
    { lat: 48.3305634, lng: 19.5489824 },
    { lat: 48.3303932, lng: 19.5487837 },
    { lat: 48.3302437, lng: 19.5484964 },
    { lat: 48.3300656, lng: 19.5484507 },
    { lat: 48.3299774, lng: 19.5481961 },
    { lat: 48.3295647, lng: 19.5477069 },
    { lat: 48.3291837, lng: 19.5466993 },
    { lat: 48.328858, lng: 19.5452085 },
    { lat: 48.3288308, lng: 19.5447885 },
    { lat: 48.3286187, lng: 19.5447107 },
    { lat: 48.3287673, lng: 19.5433393 },
    { lat: 48.3283218, lng: 19.5416943 },
    { lat: 48.3284298, lng: 19.5413275 },
    { lat: 48.3284223, lng: 19.5411123 },
    { lat: 48.328226, lng: 19.540769 },
    { lat: 48.3283115, lng: 19.5394711 },
    { lat: 48.3284362, lng: 19.5389486 },
    { lat: 48.3282581, lng: 19.5387018 },
    { lat: 48.3279468, lng: 19.5386247 },
    { lat: 48.3277124, lng: 19.5387946 },
    { lat: 48.3274385, lng: 19.5387189 },
    { lat: 48.3273163, lng: 19.5388347 },
    { lat: 48.3271641, lng: 19.5388317 },
    { lat: 48.3269593, lng: 19.5385941 },
    { lat: 48.326759, lng: 19.5385731 },
    { lat: 48.3266633, lng: 19.5384287 },
    { lat: 48.3264467, lng: 19.5384267 },
    { lat: 48.3262277, lng: 19.5382721 },
    { lat: 48.3259414, lng: 19.5382448 },
    { lat: 48.3256217, lng: 19.538057 },
    { lat: 48.3254466, lng: 19.538095 },
    { lat: 48.3253845, lng: 19.5379258 },
    { lat: 48.3252933, lng: 19.5379973 },
    { lat: 48.3250281, lng: 19.53792 },
    { lat: 48.3248215, lng: 19.5380325 },
    { lat: 48.3247461, lng: 19.5379139 },
    { lat: 48.324468, lng: 19.5378942 },
    { lat: 48.3244332, lng: 19.5380406 },
    { lat: 48.324186, lng: 19.5380581 },
    { lat: 48.3240654, lng: 19.5382034 },
    { lat: 48.3238484, lng: 19.5380907 },
    { lat: 48.3237443, lng: 19.5381574 },
    { lat: 48.3236299, lng: 19.5380935 },
    { lat: 48.3234752, lng: 19.5382834 },
    { lat: 48.3233491, lng: 19.5379913 },
    { lat: 48.3233265, lng: 19.5377469 },
    { lat: 48.3235049, lng: 19.5360988 },
    { lat: 48.3224091, lng: 19.5334037 },
    { lat: 48.3222669, lng: 19.5332294 },
    { lat: 48.3189926, lng: 19.5303679 },
  ],
  PanickéDravce: [
    { lat: 48.2945424, lng: 19.6228548 },
    { lat: 48.2944942, lng: 19.6228859 },
    { lat: 48.2934101, lng: 19.6227602 },
    { lat: 48.2932156, lng: 19.6225742 },
    { lat: 48.2930228, lng: 19.6225004 },
    { lat: 48.2927539, lng: 19.6226054 },
    { lat: 48.2919686, lng: 19.6222638 },
    { lat: 48.2915614, lng: 19.6220056 },
    { lat: 48.2899643, lng: 19.6205333 },
    { lat: 48.2882143, lng: 19.6196651 },
    { lat: 48.2874582, lng: 19.61912 },
    { lat: 48.287245, lng: 19.6190689 },
    { lat: 48.2866256, lng: 19.6191247 },
    { lat: 48.286099, lng: 19.6194163 },
    { lat: 48.2852516, lng: 19.6199561 },
    { lat: 48.284102, lng: 19.6208458 },
    { lat: 48.2838437, lng: 19.620914 },
    { lat: 48.2830868, lng: 19.6207001 },
    { lat: 48.2824423, lng: 19.6208487 },
    { lat: 48.2812696, lng: 19.6212769 },
    { lat: 48.2808804, lng: 19.6216644 },
    { lat: 48.2802769, lng: 19.6221449 },
    { lat: 48.2799258, lng: 19.6227266 },
    { lat: 48.2795901, lng: 19.6235477 },
    { lat: 48.2789626, lng: 19.6243921 },
    { lat: 48.2781989, lng: 19.6251432 },
    { lat: 48.2776213, lng: 19.6267636 },
    { lat: 48.2775299, lng: 19.6272848 },
    { lat: 48.2774465, lng: 19.6274801 },
    { lat: 48.2774274, lng: 19.6277562 },
    { lat: 48.2771999, lng: 19.6286273 },
    { lat: 48.2769983, lng: 19.6288265 },
    { lat: 48.275738, lng: 19.6292348 },
    { lat: 48.2753372, lng: 19.6299389 },
    { lat: 48.2745316, lng: 19.630611 },
    { lat: 48.2741027, lng: 19.6311404 },
    { lat: 48.2742711, lng: 19.6316722 },
    { lat: 48.274462, lng: 19.6320595 },
    { lat: 48.2748499, lng: 19.6324363 },
    { lat: 48.2749521, lng: 19.6326028 },
    { lat: 48.275629, lng: 19.6332151 },
    { lat: 48.2763153, lng: 19.6341583 },
    { lat: 48.2768182, lng: 19.6343402 },
    { lat: 48.2768802, lng: 19.6344432 },
    { lat: 48.2779445, lng: 19.6371092 },
    { lat: 48.2783234, lng: 19.6377375 },
    { lat: 48.2782834, lng: 19.637975 },
    { lat: 48.2784276, lng: 19.6382967 },
    { lat: 48.2785782, lng: 19.6383621 },
    { lat: 48.2788383, lng: 19.6390318 },
    { lat: 48.2791867, lng: 19.6402805 },
    { lat: 48.2793321, lng: 19.6413733 },
    { lat: 48.2790389, lng: 19.6414685 },
    { lat: 48.278002, lng: 19.6420335 },
    { lat: 48.2779835, lng: 19.6419958 },
    { lat: 48.2778276, lng: 19.6421407 },
    { lat: 48.2772213, lng: 19.6409958 },
    { lat: 48.2762073, lng: 19.6414428 },
    { lat: 48.2748571, lng: 19.6422065 },
    { lat: 48.2741287, lng: 19.6424741 },
    { lat: 48.2738698, lng: 19.6428775 },
    { lat: 48.2723509, lng: 19.6438716 },
    { lat: 48.2718245, lng: 19.6443166 },
    { lat: 48.2695905, lng: 19.6457713 },
    { lat: 48.2682992, lng: 19.6467178 },
    { lat: 48.2681678, lng: 19.6470268 },
    { lat: 48.2680193, lng: 19.6473556 },
    { lat: 48.2680005, lng: 19.6475874 },
    { lat: 48.2680454, lng: 19.6479528 },
    { lat: 48.2684739, lng: 19.6480627 },
    { lat: 48.269151, lng: 19.6484343 },
    { lat: 48.2695556, lng: 19.6488084 },
    { lat: 48.2699711, lng: 19.6493233 },
    { lat: 48.2704214, lng: 19.6502357 },
    { lat: 48.270994, lng: 19.6522304 },
    { lat: 48.271172, lng: 19.6526573 },
    { lat: 48.2717115, lng: 19.6535438 },
    { lat: 48.2723468, lng: 19.6542164 },
    { lat: 48.2728308, lng: 19.654503 },
    { lat: 48.2733256, lng: 19.6546827 },
    { lat: 48.2755898, lng: 19.6546405 },
    { lat: 48.2761804, lng: 19.6548197 },
    { lat: 48.2764276, lng: 19.6549775 },
    { lat: 48.2769418, lng: 19.6554347 },
    { lat: 48.2774601, lng: 19.6562283 },
    { lat: 48.2777364, lng: 19.656883 },
    { lat: 48.2779811, lng: 19.6578893 },
    { lat: 48.2779985, lng: 19.6588427 },
    { lat: 48.2778199, lng: 19.6610787 },
    { lat: 48.2778126, lng: 19.6616652 },
    { lat: 48.2778514, lng: 19.6621275 },
    { lat: 48.2780165, lng: 19.6629601 },
    { lat: 48.2783472, lng: 19.6638014 },
    { lat: 48.2787247, lng: 19.664424 },
    { lat: 48.2793258, lng: 19.6651853 },
    { lat: 48.2795656, lng: 19.6656297 },
    { lat: 48.2797516, lng: 19.6661553 },
    { lat: 48.279847, lng: 19.6670258 },
    { lat: 48.279782, lng: 19.6677038 },
    { lat: 48.2796914, lng: 19.6682462 },
    { lat: 48.2795873, lng: 19.6687851 },
    { lat: 48.2795218, lng: 19.669606 },
    { lat: 48.2795232, lng: 19.6700413 },
    { lat: 48.2796027, lng: 19.6707259 },
    { lat: 48.2796601, lng: 19.6711765 },
    { lat: 48.2798192, lng: 19.6718569 },
    { lat: 48.280665, lng: 19.6741751 },
    { lat: 48.2807745, lng: 19.6745773 },
    { lat: 48.2808344, lng: 19.6751117 },
    { lat: 48.2808427, lng: 19.6755938 },
    { lat: 48.2807853, lng: 19.6761196 },
    { lat: 48.2803734, lng: 19.6778854 },
    { lat: 48.2818871, lng: 19.6780982 },
    { lat: 48.2819368, lng: 19.6775241 },
    { lat: 48.2820534, lng: 19.6775325 },
    { lat: 48.28203, lng: 19.6781408 },
    { lat: 48.2823858, lng: 19.6781834 },
    { lat: 48.2845871, lng: 19.6777729 },
    { lat: 48.2865947, lng: 19.6772666 },
    { lat: 48.2866756, lng: 19.6770613 },
    { lat: 48.28698, lng: 19.6768618 },
    { lat: 48.2871048, lng: 19.6771857 },
    { lat: 48.2871769, lng: 19.6771805 },
    { lat: 48.2871586, lng: 19.6770539 },
    { lat: 48.2876733, lng: 19.6768682 },
    { lat: 48.2879099, lng: 19.6766964 },
    { lat: 48.2886081, lng: 19.6765855 },
    { lat: 48.2886083, lng: 19.6769881 },
    { lat: 48.2954875, lng: 19.6704873 },
    { lat: 48.2985965, lng: 19.6665901 },
    { lat: 48.2987893, lng: 19.6662942 },
    { lat: 48.298699, lng: 19.6660598 },
    { lat: 48.2987476, lng: 19.6659183 },
    { lat: 48.2988642, lng: 19.6655774 },
    { lat: 48.2991389, lng: 19.6644469 },
    { lat: 48.3002318, lng: 19.6606504 },
    { lat: 48.3004753, lng: 19.6602017 },
    { lat: 48.3008222, lng: 19.6598264 },
    { lat: 48.3015094, lng: 19.6593218 },
    { lat: 48.3019465, lng: 19.6588709 },
    { lat: 48.3022441, lng: 19.6581247 },
    { lat: 48.3022732, lng: 19.657547 },
    { lat: 48.3024471, lng: 19.6566818 },
    { lat: 48.3025247, lng: 19.6564807 },
    { lat: 48.3029583, lng: 19.6559221 },
    { lat: 48.3035259, lng: 19.6549967 },
    { lat: 48.3041732, lng: 19.6537078 },
    { lat: 48.3042305, lng: 19.6533555 },
    { lat: 48.3041057, lng: 19.6528362 },
    { lat: 48.3041197, lng: 19.6519744 },
    { lat: 48.3042436, lng: 19.6509546 },
    { lat: 48.30446, lng: 19.6502778 },
    { lat: 48.3044604, lng: 19.6499164 },
    { lat: 48.3042986, lng: 19.6493507 },
    { lat: 48.3042023, lng: 19.6485949 },
    { lat: 48.304065, lng: 19.6481027 },
    { lat: 48.304049, lng: 19.6474461 },
    { lat: 48.3036859, lng: 19.6469206 },
    { lat: 48.3034435, lng: 19.6466929 },
    { lat: 48.3027813, lng: 19.6465234 },
    { lat: 48.3022919, lng: 19.6465933 },
    { lat: 48.3016803, lng: 19.6457771 },
    { lat: 48.3018619, lng: 19.6447351 },
    { lat: 48.3018514, lng: 19.6438553 },
    { lat: 48.3017188, lng: 19.6427671 },
    { lat: 48.3017845, lng: 19.6426486 },
    { lat: 48.3017941, lng: 19.6423404 },
    { lat: 48.3019799, lng: 19.6418635 },
    { lat: 48.3018478, lng: 19.640392 },
    { lat: 48.301916, lng: 19.6399801 },
    { lat: 48.3017751, lng: 19.6392364 },
    { lat: 48.3015784, lng: 19.6385214 },
    { lat: 48.3015195, lng: 19.6374249 },
    { lat: 48.3017879, lng: 19.6358616 },
    { lat: 48.3016709, lng: 19.6351448 },
    { lat: 48.3014896, lng: 19.6345535 },
    { lat: 48.3010714, lng: 19.6340992 },
    { lat: 48.3006625, lng: 19.6341798 },
    { lat: 48.3006798, lng: 19.6336178 },
    { lat: 48.3007426, lng: 19.6335612 },
    { lat: 48.3009176, lng: 19.6336923 },
    { lat: 48.3009721, lng: 19.6336482 },
    { lat: 48.3010723, lng: 19.6333276 },
    { lat: 48.3010615, lng: 19.63311 },
    { lat: 48.3011124, lng: 19.6329406 },
    { lat: 48.301179, lng: 19.6328485 },
    { lat: 48.3013465, lng: 19.6328403 },
    { lat: 48.3014226, lng: 19.632898 },
    { lat: 48.3014971, lng: 19.6328023 },
    { lat: 48.3015799, lng: 19.6326173 },
    { lat: 48.3015184, lng: 19.6325004 },
    { lat: 48.3014602, lng: 19.6325357 },
    { lat: 48.3014621, lng: 19.6323062 },
    { lat: 48.3015528, lng: 19.6322197 },
    { lat: 48.3015913, lng: 19.6320983 },
    { lat: 48.301678, lng: 19.6321461 },
    { lat: 48.301685, lng: 19.6319518 },
    { lat: 48.3017791, lng: 19.631893 },
    { lat: 48.3017992, lng: 19.6318156 },
    { lat: 48.3018669, lng: 19.6319127 },
    { lat: 48.3019883, lng: 19.6318084 },
    { lat: 48.3020562, lng: 19.6318899 },
    { lat: 48.3020805, lng: 19.6318269 },
    { lat: 48.3022599, lng: 19.6318111 },
    { lat: 48.3022561, lng: 19.6317 },
    { lat: 48.3023557, lng: 19.6317829 },
    { lat: 48.3023769, lng: 19.631682 },
    { lat: 48.3024528, lng: 19.6317642 },
    { lat: 48.302605, lng: 19.631668 },
    { lat: 48.3025702, lng: 19.6314946 },
    { lat: 48.3025236, lng: 19.6314607 },
    { lat: 48.302535, lng: 19.6313644 },
    { lat: 48.3026482, lng: 19.6313291 },
    { lat: 48.3026368, lng: 19.6311505 },
    { lat: 48.3027466, lng: 19.6311871 },
    { lat: 48.302794, lng: 19.631086 },
    { lat: 48.3027484, lng: 19.6309034 },
    { lat: 48.3028623, lng: 19.6308539 },
    { lat: 48.3027783, lng: 19.6307938 },
    { lat: 48.3027854, lng: 19.6305029 },
    { lat: 48.3029386, lng: 19.6302515 },
    { lat: 48.3029251, lng: 19.6301046 },
    { lat: 48.3028095, lng: 19.6300651 },
    { lat: 48.3028262, lng: 19.6299133 },
    { lat: 48.3028638, lng: 19.629872 },
    { lat: 48.3029668, lng: 19.6299577 },
    { lat: 48.3029446, lng: 19.6298379 },
    { lat: 48.3029958, lng: 19.6297276 },
    { lat: 48.3033194, lng: 19.6298032 },
    { lat: 48.303476, lng: 19.6296524 },
    { lat: 48.3035073, lng: 19.6295243 },
    { lat: 48.3035412, lng: 19.629628 },
    { lat: 48.3037055, lng: 19.6295745 },
    { lat: 48.3038492, lng: 19.6297849 },
    { lat: 48.3039119, lng: 19.6296741 },
    { lat: 48.3040831, lng: 19.6296007 },
    { lat: 48.3041306, lng: 19.6295018 },
    { lat: 48.3042569, lng: 19.6295069 },
    { lat: 48.3042675, lng: 19.6293922 },
    { lat: 48.3043671, lng: 19.6292306 },
    { lat: 48.3043034, lng: 19.6290644 },
    { lat: 48.3043504, lng: 19.6288373 },
    { lat: 48.3044258, lng: 19.6288328 },
    { lat: 48.3045275, lng: 19.6284681 },
    { lat: 48.3046723, lng: 19.6284052 },
    { lat: 48.3046587, lng: 19.6279826 },
    { lat: 48.3045099, lng: 19.6277313 },
    { lat: 48.3043722, lng: 19.6276271 },
    { lat: 48.3043306, lng: 19.6274059 },
    { lat: 48.3042198, lng: 19.6272767 },
    { lat: 48.303826, lng: 19.6270642 },
    { lat: 48.3036927, lng: 19.6267596 },
    { lat: 48.3036429, lng: 19.6263803 },
    { lat: 48.3035362, lng: 19.6261689 },
    { lat: 48.3032627, lng: 19.6259997 },
    { lat: 48.3031043, lng: 19.6257359 },
    { lat: 48.3032627, lng: 19.6254543 },
    { lat: 48.3032819, lng: 19.6253062 },
    { lat: 48.3031861, lng: 19.6250579 },
    { lat: 48.3031781, lng: 19.6247837 },
    { lat: 48.3028915, lng: 19.6237544 },
    { lat: 48.3028647, lng: 19.6232903 },
    { lat: 48.3029174, lng: 19.6230878 },
    { lat: 48.303089, lng: 19.6228225 },
    { lat: 48.3033736, lng: 19.6217437 },
    { lat: 48.3030671, lng: 19.6200606 },
    { lat: 48.3030597, lng: 19.6200214 },
    { lat: 48.3021734, lng: 19.6204567 },
    { lat: 48.3017513, lng: 19.6207383 },
    { lat: 48.3008856, lng: 19.6216981 },
    { lat: 48.2997575, lng: 19.621716 },
    { lat: 48.2995192, lng: 19.621534 },
    { lat: 48.2992919, lng: 19.6214893 },
    { lat: 48.2989633, lng: 19.6216014 },
    { lat: 48.2986093, lng: 19.6214224 },
    { lat: 48.2982462, lng: 19.6214188 },
    { lat: 48.2980074, lng: 19.6215209 },
    { lat: 48.2979191, lng: 19.6215587 },
    { lat: 48.2975037, lng: 19.6216665 },
    { lat: 48.2967943, lng: 19.6218506 },
    { lat: 48.2959011, lng: 19.6225282 },
    { lat: 48.2945424, lng: 19.6228548 },
  ],
  Trebeľovce: [
    { lat: 48.2743916, lng: 19.7646611 },
    { lat: 48.2751804, lng: 19.7621689 },
    { lat: 48.2753524, lng: 19.7609122 },
    { lat: 48.2754986, lng: 19.758569 },
    { lat: 48.2760099, lng: 19.75752 },
    { lat: 48.2772977, lng: 19.7560211 },
    { lat: 48.2785097, lng: 19.7526018 },
    { lat: 48.2803428, lng: 19.7496539 },
    { lat: 48.2812872, lng: 19.7484931 },
    { lat: 48.2822264, lng: 19.7467323 },
    { lat: 48.2823284, lng: 19.7463854 },
    { lat: 48.2824571, lng: 19.7454546 },
    { lat: 48.2828573, lng: 19.744189 },
    { lat: 48.2829465, lng: 19.742492 },
    { lat: 48.2842739, lng: 19.7422164 },
    { lat: 48.2851714, lng: 19.7416981 },
    { lat: 48.2865241, lng: 19.7417638 },
    { lat: 48.2870631, lng: 19.7419007 },
    { lat: 48.2872836, lng: 19.7427547 },
    { lat: 48.2877785, lng: 19.7431663 },
    { lat: 48.2880147, lng: 19.7439709 },
    { lat: 48.2887899, lng: 19.7450092 },
    { lat: 48.288601, lng: 19.7453008 },
    { lat: 48.2893171, lng: 19.7464825 },
    { lat: 48.2919567, lng: 19.7487591 },
    { lat: 48.2926099, lng: 19.7497947 },
    { lat: 48.2943115, lng: 19.7493963 },
    { lat: 48.2948453, lng: 19.7493364 },
    { lat: 48.29496, lng: 19.7500174 },
    { lat: 48.2976694, lng: 19.7494015 },
    { lat: 48.2970895, lng: 19.7531442 },
    { lat: 48.2994734, lng: 19.753443 },
    { lat: 48.3004193, lng: 19.7535612 },
    { lat: 48.2996292, lng: 19.7456999 },
    { lat: 48.2997747, lng: 19.7454249 },
    { lat: 48.2990665, lng: 19.7444715 },
    { lat: 48.2980882, lng: 19.7426642 },
    { lat: 48.297831, lng: 19.7419199 },
    { lat: 48.2974956, lng: 19.7403327 },
    { lat: 48.297491, lng: 19.7396181 },
    { lat: 48.2976094, lng: 19.7390408 },
    { lat: 48.298917, lng: 19.7357814 },
    { lat: 48.2991694, lng: 19.7341359 },
    { lat: 48.298955, lng: 19.7255761 },
    { lat: 48.2988176, lng: 19.7246652 },
    { lat: 48.2986739, lng: 19.7242479 },
    { lat: 48.2969996, lng: 19.7215203 },
    { lat: 48.29723, lng: 19.7213819 },
    { lat: 48.2982781, lng: 19.721412 },
    { lat: 48.2994139, lng: 19.7216111 },
    { lat: 48.2998693, lng: 19.7215163 },
    { lat: 48.3036688, lng: 19.7197278 },
    { lat: 48.303829, lng: 19.7194754 },
    { lat: 48.3038965, lng: 19.7194014 },
    { lat: 48.3038951, lng: 19.7192947 },
    { lat: 48.3037021, lng: 19.7171651 },
    { lat: 48.3027106, lng: 19.7143874 },
    { lat: 48.3026038, lng: 19.7139415 },
    { lat: 48.3025017, lng: 19.7127861 },
    { lat: 48.3024098, lng: 19.7124725 },
    { lat: 48.2985977, lng: 19.7069936 },
    { lat: 48.298645, lng: 19.7066672 },
    { lat: 48.2951268, lng: 19.7062046 },
    { lat: 48.2944043, lng: 19.7062733 },
    { lat: 48.2948623, lng: 19.7058296 },
    { lat: 48.2947192, lng: 19.7056295 },
    { lat: 48.293384, lng: 19.7025099 },
    { lat: 48.2901988, lng: 19.6984145 },
    { lat: 48.2888689, lng: 19.6988062 },
    { lat: 48.287854, lng: 19.6910221 },
    { lat: 48.2872362, lng: 19.6910911 },
    { lat: 48.2865631, lng: 19.6915085 },
    { lat: 48.286468, lng: 19.6915746 },
    { lat: 48.2862277, lng: 19.690864 },
    { lat: 48.2859891, lng: 19.6903942 },
    { lat: 48.2855468, lng: 19.6892497 },
    { lat: 48.2853954, lng: 19.6886862 },
    { lat: 48.2852008, lng: 19.6875655 },
    { lat: 48.2851807, lng: 19.6874696 },
    { lat: 48.2851559, lng: 19.6874972 },
    { lat: 48.284047, lng: 19.6883933 },
    { lat: 48.2805024, lng: 19.6897814 },
    { lat: 48.280249, lng: 19.689897 },
    { lat: 48.2786021, lng: 19.6906481 },
    { lat: 48.2782332, lng: 19.6910415 },
    { lat: 48.2777081, lng: 19.6914145 },
    { lat: 48.2776848, lng: 19.6916011 },
    { lat: 48.2775582, lng: 19.69179 },
    { lat: 48.2773701, lng: 19.6918175 },
    { lat: 48.2760099, lng: 19.6938245 },
    { lat: 48.2757945, lng: 19.6943948 },
    { lat: 48.2749127, lng: 19.6961658 },
    { lat: 48.2747982, lng: 19.6961261 },
    { lat: 48.2743332, lng: 19.6987729 },
    { lat: 48.2740899, lng: 19.6987748 },
    { lat: 48.2724953, lng: 19.7025933 },
    { lat: 48.2719591, lng: 19.7026472 },
    { lat: 48.2713652, lng: 19.7029187 },
    { lat: 48.2704726, lng: 19.7030898 },
    { lat: 48.2702921, lng: 19.7053039 },
    { lat: 48.2701739, lng: 19.705332 },
    { lat: 48.2700823, lng: 19.7057019 },
    { lat: 48.2700131, lng: 19.7064449 },
    { lat: 48.2697498, lng: 19.7073092 },
    { lat: 48.2688007, lng: 19.7069213 },
    { lat: 48.26776, lng: 19.7067528 },
    { lat: 48.2673002, lng: 19.7068559 },
    { lat: 48.2664064, lng: 19.7083323 },
    { lat: 48.265653, lng: 19.7098794 },
    { lat: 48.2651108, lng: 19.7106534 },
    { lat: 48.2646403, lng: 19.7110921 },
    { lat: 48.2641858, lng: 19.7113357 },
    { lat: 48.2635161, lng: 19.7112763 },
    { lat: 48.2625286, lng: 19.7115967 },
    { lat: 48.2609569, lng: 19.7119721 },
    { lat: 48.2604528, lng: 19.7119668 },
    { lat: 48.2590874, lng: 19.7121483 },
    { lat: 48.2587357, lng: 19.7153938 },
    { lat: 48.2581761, lng: 19.7149237 },
    { lat: 48.2577108, lng: 19.7146486 },
    { lat: 48.2575459, lng: 19.714488 },
    { lat: 48.2573566, lng: 19.714437 },
    { lat: 48.2572512, lng: 19.7141456 },
    { lat: 48.2570732, lng: 19.7141235 },
    { lat: 48.2563861, lng: 19.7136478 },
    { lat: 48.2560644, lng: 19.7138883 },
    { lat: 48.2557227, lng: 19.7136556 },
    { lat: 48.255622, lng: 19.7138743 },
    { lat: 48.2553088, lng: 19.7135716 },
    { lat: 48.2550955, lng: 19.7136375 },
    { lat: 48.2550558, lng: 19.7135043 },
    { lat: 48.2549842, lng: 19.7134673 },
    { lat: 48.2547887, lng: 19.7136039 },
    { lat: 48.2547293, lng: 19.7133104 },
    { lat: 48.2545285, lng: 19.7133822 },
    { lat: 48.2545233, lng: 19.7134672 },
    { lat: 48.2544236, lng: 19.7135016 },
    { lat: 48.2543898, lng: 19.7141347 },
    { lat: 48.2545358, lng: 19.7143276 },
    { lat: 48.2544437, lng: 19.7144326 },
    { lat: 48.2543647, lng: 19.7146827 },
    { lat: 48.254446, lng: 19.7148648 },
    { lat: 48.2542832, lng: 19.7153061 },
    { lat: 48.2543847, lng: 19.7156314 },
    { lat: 48.254152, lng: 19.7167281 },
    { lat: 48.254137, lng: 19.7176546 },
    { lat: 48.2540333, lng: 19.7180082 },
    { lat: 48.253893, lng: 19.7181195 },
    { lat: 48.2538367, lng: 19.718297 },
    { lat: 48.2539261, lng: 19.7187862 },
    { lat: 48.2538267, lng: 19.7193102 },
    { lat: 48.2534031, lng: 19.7205982 },
    { lat: 48.2533491, lng: 19.721684 },
    { lat: 48.2534364, lng: 19.7223035 },
    { lat: 48.2534571, lng: 19.7231998 },
    { lat: 48.2535435, lng: 19.7239725 },
    { lat: 48.2533852, lng: 19.7244671 },
    { lat: 48.2531241, lng: 19.7249298 },
    { lat: 48.2526581, lng: 19.7260003 },
    { lat: 48.2523607, lng: 19.7264058 },
    { lat: 48.252209, lng: 19.7267929 },
    { lat: 48.2519331, lng: 19.727226 },
    { lat: 48.2517647, lng: 19.7276682 },
    { lat: 48.251881, lng: 19.7283823 },
    { lat: 48.2522383, lng: 19.7298244 },
    { lat: 48.2525544, lng: 19.7314566 },
    { lat: 48.2521142, lng: 19.7318184 },
    { lat: 48.2514563, lng: 19.7326064 },
    { lat: 48.2507547, lng: 19.7337543 },
    { lat: 48.2502507, lng: 19.7348864 },
    { lat: 48.2499758, lng: 19.7352921 },
    { lat: 48.2486384, lng: 19.7361273 },
    { lat: 48.2483072, lng: 19.7364053 },
    { lat: 48.2479772, lng: 19.7368165 },
    { lat: 48.2473795, lng: 19.7371589 },
    { lat: 48.2471059, lng: 19.7374408 },
    { lat: 48.2466102, lng: 19.7389866 },
    { lat: 48.2466457, lng: 19.7400605 },
    { lat: 48.2469081, lng: 19.7412995 },
    { lat: 48.2471012, lng: 19.7426019 },
    { lat: 48.2475923, lng: 19.7435622 },
    { lat: 48.2482653, lng: 19.7440541 },
    { lat: 48.2482665, lng: 19.744054 },
    { lat: 48.2490201, lng: 19.7439262 },
    { lat: 48.2496247, lng: 19.7442163 },
    { lat: 48.2510252, lng: 19.7469297 },
    { lat: 48.2515973, lng: 19.7483466 },
    { lat: 48.2526881, lng: 19.7486531 },
    { lat: 48.2535443, lng: 19.7494375 },
    { lat: 48.2545872, lng: 19.7492531 },
    { lat: 48.2548039, lng: 19.7494108 },
    { lat: 48.255699, lng: 19.7503845 },
    { lat: 48.2561493, lng: 19.7513929 },
    { lat: 48.2568027, lng: 19.751597 },
    { lat: 48.2592789, lng: 19.7531602 },
    { lat: 48.2620024, lng: 19.7523328 },
    { lat: 48.2624768, lng: 19.7526102 },
    { lat: 48.2634213, lng: 19.7520576 },
    { lat: 48.2649507, lng: 19.753414 },
    { lat: 48.2656116, lng: 19.7532782 },
    { lat: 48.2677818, lng: 19.7518072 },
    { lat: 48.2695303, lng: 19.7525135 },
    { lat: 48.2698822, lng: 19.753411 },
    { lat: 48.2687922, lng: 19.7544672 },
    { lat: 48.2682783, lng: 19.7557904 },
    { lat: 48.2684909, lng: 19.7561597 },
    { lat: 48.2707916, lng: 19.7592369 },
    { lat: 48.2705455, lng: 19.7611619 },
    { lat: 48.2707455, lng: 19.7618115 },
    { lat: 48.2732813, lng: 19.7627692 },
    { lat: 48.2743916, lng: 19.7646611 },
  ],
};

export default UnitsLučenec;
