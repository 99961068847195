import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";

export default function RealEstatePanel({ openLeftBar }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return (
    <div
      className={clsx(
        "transition-all duration-200 ease-linear overflow-auto absolute top-16 left-0 sm:top-0 sm:left-16 bg-sky-100 w-screen sm:w-0 h-0 sm:h-screen flex flex-col items-center gap-4",
        {
          "h-44 p-3 sm:w-96": openLeftBar.realEstate && !isSmallDevice,
        },
      )}
    >
      {openLeftBar.realEstate ? <h1>Realitky</h1> : null}
    </div>
  );
}
