import Switch from "./Switch";
import clsx from "clsx";

export default function Navbar({
  navbarOpen,
  // rightBarOpen,
  historyItems,
  map,
  setCurrentIndex,
  setVisibilityOfMarkers,
  setResponseForSale,
  setViewSale,
  setCurrentItem,
  setGallery,
  openLeftBar,
  setLeftBar,
  setNavSmall,
  navSmall,
  isLogged,
  infoUser,
  setForSale,
  forSale,
  setHistory,
  setHistoryMenu,
  viewSale,
  setSubMenu,
  subMenu,
  subjects,
  setSubjects,
  setFilterSale,
  optSubMenu,
  setOptSubMenu,
  setMapActive,
  setPriceMap,
  priceMap,
  setGotResponse,
  PMResult,
  setPMResult,
  setZoom,
}) {
  return (
    <nav
      className={clsx(
        "no-scrollbar select-none transition-all duration-200 ease-linear relative z-20 flex sm:w-16 h-16 sm:h-screen items-center sm:items-start justify-start px-2 sm:pb-3 bg-sky-100 drop-shadow-2xl overflow-scroll md:overflow-visible",
        {
          "w-80": navbarOpen,
          // "brightness-50 pointer-events-none": rightBarOpen,
        },
      )}
    >
      <Switch
        setVisibilityOfMarkers={setVisibilityOfMarkers}
        historyItems={historyItems}
        map={map}
        setCurrentIndex={setCurrentIndex}
        setResponseForSale={setResponseForSale}
        setViewSale={setViewSale}
        setCurrentItem={setCurrentItem}
        setGallery={setGallery}
        openLeftBar={openLeftBar}
        setLeftBar={setLeftBar}
        setNavSmall={setNavSmall}
        navSmall={navSmall}
        isLogged={isLogged}
        infoUser={infoUser}
        setForSale={setForSale}
        forSale={forSale}
        setHistory={setHistory}
        setHistoryMenu={setHistoryMenu}
        viewSale={viewSale}
        setSubMenu={setSubMenu}
        subMenu={subMenu}
        subjects={subjects}
        setSubjects={setSubjects}
        setFilterSale={setFilterSale}
        optSubMenu={optSubMenu}
        setOptSubMenu={setOptSubMenu}
        setMapActive={setMapActive}
        setPriceMap={setPriceMap}
        priceMap={priceMap}
        setGotResponse={setGotResponse}
        PMResult={PMResult}
        setPMResult={setPMResult}
        setZoom={setZoom}
      />
    </nav>
  );
}
